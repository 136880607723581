import http from 'network/httpClient'
import moment from 'moment'
import { reduceData } from 'pages/views/CompanyOverview/dataSource/queries'

export const fetchCompanyData = async ({ queryKey }) => {
  const [, ticker] = queryKey
  let data = await http.get(`papi/data/api/v4/company-outlook?symbol=${ticker}`)
  return data.data.message
}

export const fetchCompanyQuote = async ({ queryKey }) => {
  const [, ticker] = queryKey
  let data = await http.get(`papi/data/api/v3/quote/${ticker}`)
  return data.data.message[0]
}

export const fetchHistoricalEmployees = async ({ queryKey }) => {
  const [, ticker] = queryKey
  let data = await http.get(
    `papi/data/api/v4/historical/employee_count?symbol=${ticker}`
  )
  return data.data.message.reverse()
}

export const fetchIncome = async ({ queryKey }) => {
  const [, ticker] = queryKey
  const data = await http.get(
    `papi/data/api/v3/income-statement/${ticker}?limit=100&`
  )
  return data.data.message
}

export const fetchPriceData = async ({ queryKey }) => {
  const [, ticker, { label, from, to }] = queryKey
  let data

  const time = label => {
    if (label === '5D') {
      return '15min'
    } else if (label === '1D') {
      return '1min'
    } else if (label === '1M') {
      return '30min'
    } else if (label === '3M') {
      return '1hour'
    }
  }

  switch (label) {
    case '1D':
    case '5D':
    case '1M':
    case '3M':
      data = await http.get(
        `papi/data/api/v3/historical-chart/${time(label)}/${ticker}`
      )
      data = data.data.message
      break
    default:
      let startDate = from ? moment(from).format('YYYY-MM-DD') : null
      let endDate = to ? moment(to).format('YYYY-MM-DD') : null
      data = await http.get(
        `papi/data/api/v3/historical-price-full/${ticker}?from=${startDate}&to=${endDate}`
      )
      data = data.data.message.historical
  }

  let startDate = from ? moment(from) : null
  let endDate = to ? moment(to) : null
  let historicalData = data.filter(item => {
    let date = moment(item.date)
    return (
      (!startDate || date.isAfter(startDate)) &&
      (!endDate || date.isBefore(endDate))
    )
  })

  if (from && moment(to).diff(moment(from), 'years') > 10) {
    historicalData = reduceData(historicalData, from, false, true)
  } else if (from && moment(to).diff(moment(from), 'years') >= 3) {
    historicalData = reduceData(historicalData, from, true, false)
  }

  return historicalData.reverse()
}

const queries = {
  fetchCompanyData,
  fetchCompanyQuote,
  fetchPriceData,
  fetchHistoricalEmployees,
  fetchIncome,
}

export default queries
