import { useMutation, useQueryClient } from 'react-query'
import http from '../../network/httpClient'

const Chat_URL = 'papi/chatFeedback/'

async function getFeedbackFn(feedback) {
  let data = await http.post(`${Chat_URL}feedback`, feedback)
  return data.data
}

export const useFeedback = () => {
  const queryClient = useQueryClient()

  const {
    mutate: getFeedback,
    error: isGetFeedbackError,
    isLoading: isGetFeedbackLoading,
  } = useMutation(getFeedbackFn, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [Chat_URL] })
    },
    onError: error => {
      console.error('Error logging feedback:', error)
    },
  })

  return {
    getFeedback,
    isGetFeedbackError,
    isGetFeedbackLoading,
  }
}

export default useFeedback
