/* eslint-disable no-console */
import React, { useState, createContext } from 'react'
import { useSelector } from 'react-redux'
import { useRecoilState } from 'recoil'
import Modal from '@mui/material/Modal'
import '../styles.scss'
import { Link } from 'react-router-dom'
import { CatalogModal } from '../ItemCatalog'
import MyDashboards from 'dashboard/DashboardCRUDComponents/DashboardUpdateModal'
import Box from '@mui/material/Box'
import { Auth } from 'aws-amplify'
import { useNavigate, useLocation } from 'react-router-dom'
import { Sidebar, Menu, useProSidebar } from 'react-pro-sidebar'
import { ToolMenuExpandableItem, ToolMenuItem } from './ToolMenuItem'
import { MarketViews, TechnicalViews, ETFViews } from '../../constants'
import { styles } from './ToolmenuStyles'
import stylesV from './ToolMenu.module.scss'
import { showComponentCatalog } from 'recoil/atoms/componentCatalog'
import { media } from '../assets/assets'
import { globalViewTickerStyles } from './globalTickerSearchStyles'
import TickerSearch from 'dashboard/Title/components/TickerSearch'
import useIsAuthenticated from 'core/AuthComponents/useIsAuthenticated'
import { ProtectedButton } from 'core/ProtectedButton/ProtectedButton'
import { useURLSyncedGlobalTicker } from 'pages/views/hooks'
import './feedbackstyles.css'
import {
  UilListUl,
  UilFilter,
  UilShoppingBasket,
  UilAnalysis,
  UilBuilding,
  UilChartBarAlt,
  UilCreateDashboard,
  UilUser,
  UilSignOutAlt,
  UilCommentsAlt,
} from '@iconscout/react-unicons'
import Logo from 'assets/Logo'
import { useUserGroup } from 'hooks/userGroupHook'

export const CatalogContext = createContext()
export const ToolMenuContext = createContext('')

const GlobalViewTicker = () => {
  const { ticker, handleSetTickerSideBar } = useURLSyncedGlobalTicker()

  return (
    <div style={{ margin: '0 8px', marginBottom: 8 }}>
      <TickerSearch
        updateTicker={handleSetTickerSideBar}
        ticker={ticker}
        styles={globalViewTickerStyles}
      />
    </div>
  )
}

const ToolMenu = props => {
  const navigate = useNavigate()
  const { isAuthenticated, isDemoUser } = useIsAuthenticated()
  const location = useLocation()
  const [showCatalog, setShowComponentCatalog] =
    useRecoilState(showComponentCatalog)
  const [openDashboard, setOpenDashboard] = useState(false)
  const names = useSelector(state => state.dashboardNames)

  const handleLogout = async () => {
    try {
      await Auth.signOut()
      navigate('/')
    } catch (error) {
      console.error('Error signing out: ', error.message)
    }
  }

  const handleOpenDashboard = () => setOpenDashboard(true)
  const handleCloseDashboardToolMenu = () => setOpenDashboard(false)

  const { collapseSidebar, collapsed } = useProSidebar()

  // items that if selected navigate to the login page
  const AuthenticatedMenuItem = ItemProps => {
    if (isAuthenticated) {
      return <ToolMenuItem {...ItemProps} />
    } else {
      return (
        <div className={stylesV.userContent}>
          <ToolMenuItem {...ItemProps} navLinkTo={'/login'} />
        </div>
      )
    }
  }

  // these also need to consider the authentication
  const UserAdminItems = () => {
    const { userGroup } = useUserGroup()
    const labelForAccount = isDemoUser ? 'Demo Account' : 'Account'
    const labelForLogout = isDemoUser ? 'Create Account' : 'Logout'
    const logoutIcon = isDemoUser ? (
      <UilUser {...iconProps} />
    ) : (
      <UilSignOutAlt {...iconProps} />
    )

    return (
      <div className={stylesV.userContent}>
        {isDemoUser ? null : (
          <AuthenticatedMenuItem
            label={labelForAccount}
            iconSrc={<UilUser {...iconProps} />}
            collapsed={collapsed}
            navLinkTo="/terminal/account"
            chip={
              <span
                className={stylesV.subscriptBlueText}
                style={{
                  backgroundColor:
                    userGroup === 'ProUsers'
                      ? 'var(--primary-color)'
                      : 'var(--background-tertiary)',
                }}
              >
                {userGroup?.replace('Users', '')}
              </span>
            }
          />
        )}
        <ProtectedButton
          onClick={handleLogout}
          redirectTo="/terminal/login"
          content={
            <AuthenticatedMenuItem
              label={labelForLogout}
              iconSrc={logoutIcon}
              collapsed={collapsed}
              navLinkTo="/terminal/login"
            />
          }
        />
      </div>
    )
  }

  const iconProps = {
    color: 'var(--white)',
    size: 20,
  }

  return (
    <>
      <CatalogContext.Provider value={{ showCatalog, setShowComponentCatalog }}>
        <Sidebar
          transitionDuration={0}
          width="220px"
          backgroundColor="var(--background-primary)"
          collapsedWidth="60px"
          rootStyles={{ zIndex: 1000, borderRight: 'none' }}
        >
          <Menu rootStyles={{ overflow: 'hidden', maxHeight: '100vh' }}>
            <div style={styles.TopMenu}>
              <div className={stylesV.dashboardContent}>
                <div className={stylesV.logoDiv}>
                  {collapsed ? null : (
                    <Link to={'/'}>
                      {collapsed ? null : <Logo width={124} height={25} />}
                    </Link>
                  )}
                  <div
                    onClick={() => collapseSidebar()}
                    className={stylesV.swapIcon}
                  >
                    <img
                      style={styles.icon}
                      src={collapsed ? media.sidebarOpen : media.sidebarClosed}
                      alt="Close Sidebar Icon"
                    />
                  </div>
                </div>
                <div>{collapsed ? null : <GlobalViewTicker />}</div>
                <div className={stylesV.ToolMenuContentWrapper}>
                  <ToolMenuItem
                    label={'AI Screener'}
                    iconSrc={<UilFilter {...iconProps} />}
                    navLinkTo="/terminal/screener"
                    collapsed={collapsed}
                    // chip={
                    //   <span className={stylesV.subscriptBlueText}>Popular</span>
                    // }
                  />
                  <AuthenticatedMenuItem
                    label={'Copilot'}
                    iconSrc={<UilCommentsAlt {...iconProps} />}
                    navLinkTo="/terminal/copilot"
                    collapsed={collapsed}
                    chip={
                      <span className={stylesV.subscriptBlueText}>New</span>
                    }
                  />
                  <AuthenticatedMenuItem
                    label={'Watchlist'}
                    navLinkTo="/terminal/watchlist"
                    iconSrc={<UilListUl {...iconProps} />}
                    collapsed={collapsed}
                  />
                  {/* <ProtectedButton
                    onClick={handleOpenDashboard}
                    redirectTo="/terminal/login"
                    content={
                      <AuthenticatedMenuItem
                        label={'Dashboards'}
                        iconSrc={<UilCreateDashboard {...iconProps} />}
                        collapsed={collapsed}
                      />
                    }
                  /> */}
                  <ToolMenuExpandableItem
                    label={'Markets'}
                    iconSrc={<UilChartBarAlt {...iconProps} />}
                    config={MarketViews}
                    collapsed={collapsed}
                    search={location.search}
                  />
                  <ToolMenuExpandableItem
                    label={'ETFs'}
                    iconSrc={<UilShoppingBasket {...iconProps} />}
                    config={ETFViews}
                    collapsed={collapsed}
                    search={location.search}
                  />
                  <AuthenticatedMenuItem
                    label={'Institutional Funds'}
                    navLinkTo={`/terminal/institutionalFunds` + location.search}
                    iconSrc={<UilBuilding {...iconProps} />}
                    collapsed={collapsed}
                  />
                  <ToolMenuExpandableItem
                    label={'Company Insights'}
                    iconSrc={<UilAnalysis {...iconProps} />}
                    collapsed={collapsed}
                    config={TechnicalViews}
                    search={location.search}
                  />
                </div>
              </div>
              <UserAdminItems />
            </div>
          </Menu>
        </Sidebar>
        <ToolMenuContext.Provider
          value={{ handleCloseDashboardToolMenu: handleCloseDashboardToolMenu }}
        />
        <Modal
          open={openDashboard}
          onClose={handleCloseDashboardToolMenu}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          disableAutoFocus={true}
        >
          <div>
            {names && (
              <Box
                style={styles._box}
                justifyContent="center"
                alignItems="center"
              >
                <MyDashboards
                  names={names}
                  parentClose={handleCloseDashboardToolMenu}
                />
              </Box>
            )}
          </div>
        </Modal>
        <CatalogModal />
        <ToolMenuContext.Provider />
      </CatalogContext.Provider>
    </>
  )
}

export default ToolMenu
