import React, { useState } from 'react'
import CoreButton from 'core/Button/CoreButton'
import Input from 'core/Input/Input'
import styles from './styles.module.scss'
import { displayFilters } from './utils'
import { Error } from 'core/Error/Error'
import { UilTimes, UilLock } from '@iconscout/react-unicons'
import { NoData } from 'core/NoData/NoData'
import { useNavigate } from 'react-router'

export const CreateScreen = ({
  createScreener,
  filters,
  setOpen,
  onScreenCreated,
  canCreateScreener,
}) => {
  const navigate = useNavigate()
  const [screenerDetails, setScreenerDetails] = useState({
    screenerName: '',
    screenerFilters: filters,
    screenerDescription: '',
  })
  const [error, setError] = useState('')

  const validateAndCreateScreener = () => {
    if (!screenerDetails.screenerName.trim()) {
      setError('Screener name cannot be empty.')
      return
    }
    if (!screenerDetails.screenerFilters.length) {
      setError('Filters cannot be empty.')
      return
    }
    setError('')

    const screenerBody = {
      name: screenerDetails.screenerName,
      filters: screenerDetails.screenerFilters,
      description: screenerDetails.screenerDescription,
    }

    createScreener(
      {
        body: screenerBody,
      },
      {
        onSuccess: data => {
          if (onScreenCreated) onScreenCreated(data)
        },
        onError: error => {
          console.error('Error creating screener', error)
        },
      }
    )
    setOpen(false)
  }

  return (
    <div className={styles.createScreenWrapper}>
      <div className={styles.modalTitle}>
        <div className={styles.title}>Create New Screen</div>
        <div className={styles.closeButton}>
          <UilTimes
            size={24}
            style={{ cursor: 'pointer' }}
            onClick={() => setOpen(false)}
          />
        </div>
      </div>
      <div className={styles.inputWrapper}>
        <div>Screener Name:</div>
        <Input
          Placeholder="Saved Screen 1"
          value={screenerDetails.screenerName}
          onChange={e => {
            setError('')
            setScreenerDetails({
              ...screenerDetails,
              screenerName: e.target.value,
            })
          }}
        />
      </div>
      <div className={styles.inputWrapper}>
        <div>Screener Description:</div>
        <Input
          Placeholder="This screen contains securities that..."
          value={screenerDetails.screenerDescription}
          onChange={e => {
            setError('')
            setScreenerDetails({
              ...screenerDetails,
              screenerDescription: e.target.value,
            })
          }}
        />
      </div>
      <div className={styles.filterWrapper}>
        <div>Filters:</div>
        <div className={styles.displayFilters}>
          {filters?.length === 0 ? (
            <NoData label={'Select filters to save screen.'} />
          ) : (
            filters?.map(filter => (
              <div key={filter.id}>{displayFilters(filter?.type, filter)}</div>
            ))
          )}
        </div>
      </div>
      {error && <Error err={error} />}
      {canCreateScreener ? (
        <CoreButton
          onClick={validateAndCreateScreener}
          width="100%"
          color="var(--primary-color)"
        >
          Save Screen
        </CoreButton>
      ) : (
        <CoreButton
          width="100%"
          color="var(--grey-accent)"
          onClick={() => {
            navigate('/terminal/upgrade')
          }}
        >
          <UilLock color={'var(--white)'} size={20} />
          <div>Upgrade to save screen more than 1 screen</div>
        </CoreButton>
      )}
    </div>
  )
}
