import useEarningsTranscript from './data/hooks'
import { EarningsTranscript } from './components/EarningsTranscript/EarningsTranscript'
import Spinners from 'core/ScrollableContainer/Spinner/spinner'
import { CardWrapper } from 'core/CardWrapper/CardWrapper'
import SimpleScatterChart from './components/Earnings/ScatterChart'
import { useState, useEffect } from 'react'
import { SeasonChart } from './components/Earnings/SeasonalityChart'
import styles from './earnings.module.scss'
import { QuoteBar } from '../../../core/QuoteBar/QuoteBar'
import { TableKeyValue } from '../InstitutionalOwnership/components/TableKeyValue/TableKeyValue'
import { labels } from './constants'
import { Helmet } from 'react-helmet-async'
import { useCallback } from 'react'
import EarningsSummary from 'pages/views/Earnings/EarningsSummary/EarningsSummary'
import dayjs from 'dayjs'

export const EarningsView = () => {
  const {
    Earnings_UI,
    setSelectedTranscriptDate,
    selectedTranscriptDate,
    ticker,
    setTicker,
  } = useEarningsTranscript()

  const [selectedDate, setSelectedDate] = useState(null)
  const [selectedMetrics, setSelectedMetrics] = useState({
    metric1: 'eps',
    metric2: 'epsEstimated',
  })

  const removeAfterSpace = str => {
    return str.split(' ')[0]
  }

  useEffect(() => {
    if (selectedDate && Earnings_UI?.years?.finalData) {
      const matchingData = Earnings_UI?.years?.finalData?.find(data => {
        const [quarter, year, dateString] = data
        return removeAfterSpace(dateString) === selectedDate
      })

      if (matchingData) {
        const [quarter, year] = matchingData
        setSelectedTranscriptDate({ year, quarter })
      }
    }
  }, [selectedDate, Earnings_UI?.years?.finalData, setSelectedTranscriptDate])

  const swapMetrics = useCallback(() => {
    setSelectedMetrics(prevMetrics => ({
      metric1: prevMetrics.metric1 === 'eps' ? 'revenue' : 'eps',
      metric2:
        prevMetrics.metric1 === 'eps' ? 'revenueEstimated' : 'epsEstimated',
    }))
  }, [])

  const extractText = (text, maxWords, stopPhrases) => {
    const words = text?.split(/\s+/)

    let earliestStopIndex = maxWords
    for (const phrase of stopPhrases) {
      const phraseIndex = text?.indexOf(phrase)
      if (phraseIndex !== -1) {
        const indexInWords = text
          .substring(0, phraseIndex)
          ?.split(/\s+/)?.length
        earliestStopIndex = Math.min(earliestStopIndex, indexInWords)
      }
    }

    const limit = Math.min(earliestStopIndex, maxWords)

    const limitedWords = words?.slice(0, limit)
    const shortenedText = limitedWords?.join(' ')
    return shortenedText
  }

  const stopPhrases = ['first question']
  const firstWordsOrUntilPhrase = extractText(
    Earnings_UI?.content,
    5000,
    stopPhrases
  )

  console.log('Earnings_UI', JSON.stringify(Earnings_UI?.chartData))

  return (
    <div className={styles.earningsWrapper}>
      <Helmet>
        <title>{`Outseek | ${ticker} Earnings & Transcripts`}</title>
        <meta
          name="description"
          content={
            "This page provides a company's Earnings surprise history and transcripts."
          }
        />
      </Helmet>
      <QuoteBar
        updateTicker={tickerIn => setTicker(tickerIn)}
        ticker={ticker}
      />
      <div className={styles.earningsContentWrapper}>
        <div className={styles.bottomWrapper}>
          <CardWrapper
            height={'calc(100vh - 404px'}
            width={'calc(100% - 308px)'}
            label={'Earnings Transcript'}
            content={
              Earnings_UI?.transcriptLoading ? (
                <Spinners />
              ) : Earnings_UI?.content?.length > 0 ? (
                <EarningsTranscript
                  setSelectedTranscriptDate={setSelectedTranscriptDate}
                  selectedTranscriptDate={selectedTranscriptDate}
                  data={Earnings_UI}
                />
              ) : (
                <div className={styles.noData}>No Transcript Data</div>
              )
            }
          />
          <CardWrapper
            height={'calc(100vh - 404px'}
            width={'300px'}
            label={`${Earnings_UI?.selectedYear} Q${Earnings_UI?.selectedQuarter} Transcript Summary`}
            content={
              Earnings_UI?.transcriptLoading ? (
                <Spinners />
              ) : Earnings_UI?.content?.length > 0 ? (
                <EarningsSummary
                  text={firstWordsOrUntilPhrase}
                  label={`${Earnings_UI?.selectedYear} Q${Earnings_UI?.selectedQuarter} Earnings Transcript`}
                />
              ) : (
                <div className={styles.noData}>No Transcript Data</div>
              )
            }
          />
        </div>
        <div className={styles.bottomWrapper}>
          {/* <CardWrapper
            height={270}
            width={220}
            label={
              selectedMetrics.metric1 === 'eps'
                ? 'EPS Seasonality'
                : 'Revenue Seasonality'
            }
            content={
              Earnings_UI?.companyEarningsIsLoading ? (
                <Spinners />
              ) : Earnings_UI?.seasonality(selectedMetrics.metric1)?.length ? (
                <SeasonChart
                  data={Earnings_UI?.seasonality(selectedMetrics.metric1)}
                  xAxisKey={'quarter'}
                />
              ) : (
                <div className={styles.noData}>No Seasonality Data</div>
              )
            }
          /> */}
          <CardWrapper
            height={270}
            width={'100%'}
            // width={'calc(100% - 228px)'}
            label={
              selectedMetrics.metric1 === 'eps' ? 'EPS Chart' : 'Revenue Chart'
            }
            endButton={
              <div onClick={swapMetrics} className={styles.swapButton}>
                {selectedMetrics.metric1 === 'eps'
                  ? 'View Revenue'
                  : 'View EPS'}
              </div>
            }
            content={
              Earnings_UI?.companyEarningsIsLoading ? (
                <Spinners />
              ) : Earnings_UI?.chartData?.length ? (
                <div className={styles.chartContainer}>
                  <div className={styles.tableWrapper}>
                    <TableKeyValue
                      data={Earnings_UI?.statistics}
                      labels={labels}
                      chunkEnabled={false}
                    />
                  </div>
                  <SimpleScatterChart
                    data={Earnings_UI?.chartData}
                    xAxisKey={'date'}
                    setSelectedDate={setSelectedDate}
                    metric1={selectedMetrics?.metric1}
                    metric2={selectedMetrics?.metric2}
                  />
                </div>
              ) : (
                <div className={styles.noData}>No Chart Data</div>
              )
            }
          />
        </div>
      </div>
    </div>
  )
}
