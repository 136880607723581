import React, { useState, memo, useMemo, useCallback } from 'react'
import { TimeRange } from '../../../NewsView/component'
import numeral from 'numeral'
import moment from 'moment'
import Spinner from 'core/ScrollableContainer/Spinner/spinner'
import { TickerOverlayChart } from 'core/TickerOverlay/TickerOverlayChart'
import Fuse from 'fuse.js'
import { OverviewIntervals } from './utils'
import { useDataSource } from './data'
import styles from './IndexCharts.module.scss'

export const IndexChart = ({ title, data, setInterval, interval }) => {
  const handleTimeRangeChange = useCallback(
    newInterval => {
      setInterval(newInterval)
    },
    [setInterval]
  )

  return (
    <div className={styles.indexChartWrapper}>
      <div className={styles.indexChartTitle}>
        <span className={styles.indexChartTitleText}>{title}</span>
        <TimeRange
          intervals={OverviewIntervals}
          onChange={handleTimeRangeChange}
          interval={interval}
          spaceBetween={'flex-end'}
          gap={'8px'}
          fitContent={true}
        />
      </div>
      <div className={styles.indexChart}>
        <TickerOverlayChart
          data={data}
          xAxisKey={'date'}
          view={true}
          lineDataKey={'close'}
          interval={interval}
          YTickFormat={tick => numeral(tick).format('0.00a')}
          barDomain={[0, dataMax => dataMax * 4]}
        />
      </div>
    </div>
  )
}

export const IndexCharts = () => {
  const {
    Index_UI,
    indexList,
    selectedIndex,
    setSelectedIndex,
    interval,
    setInterval,
  } = useDataSource()

  const [searchQuery, setSearchQuery] = useState('')

  const getFilteredIndexes = useCallback(() => {
    if (searchQuery?.trim() && Index_UI?.indexData) {
      const fuse = new Fuse(Index_UI.indexData, {
        keys: ['symbol', 'name'],
        threshold: 0.6,
      })
      return fuse.search(searchQuery).map(result => result.item.symbol)
    }
    return indexList
  }, [searchQuery, Index_UI, indexList])

  const filterDataByDate = useCallback(
    data => {
      const { from, to } = interval
      return Array.isArray(data)
        ? data.filter(entry => {
            const entryDate = moment(entry.date)
            return (
              (!from || entryDate.isSameOrAfter(from)) &&
              (!to || entryDate.isSameOrBefore(to))
            )
          })
        : []
    },
    [interval]
  )

  const filteredData = useMemo(
    () => filterDataByDate(Index_UI.historicalData),
    [Index_UI.historicalData, filterDataByDate]
  )

  const selectedIndexDetails = useMemo(
    () => Index_UI.indexData?.find(item => item.symbol === selectedIndex) || {},
    [Index_UI.indexData, selectedIndex]
  )

  const title = useCallback(
    () => (
      <div>
        {selectedIndexDetails.symbol}{' '}
        <span style={{ color: 'var(--light-grey-accent)' }}>-</span>{' '}
        {selectedIndexDetails.name}{' '}
        <span style={{ color: 'var(--light-grey-accent)' }}>-</span>{' '}
        {numeral(selectedIndexDetails.price).format('0,0.00')}{' '}
        <span
          style={{
            color:
              selectedIndexDetails.changesPercentage < 0
                ? 'var(--red)'
                : 'var(--green)',
          }}
        >
          {selectedIndexDetails?.changesPercentage > 0 && '+'}
          {numeral(selectedIndexDetails?.change).format('0,0.00')} (
          {numeral(selectedIndexDetails?.changesPercentage).format('0.00')}%)
        </span>
      </div>
    ),
    [selectedIndexDetails]
  )

  const filteredIndexes = useMemo(getFilteredIndexes, [getFilteredIndexes])

  if (Index_UI.historicalDataIsError || Index_UI.indexIsError) {
    return <div>Error occurred</div>
  }

  return (
    <div className={styles.indexChartsWrapper}>
      {Index_UI.indexIsLoading ? (
        <Spinner />
      ) : (
        <div className={styles.indexSelectorWrapper}>
          <input
            type="text"
            placeholder="Search indexes..."
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
            className={styles.searchInput}
          />
          <div className={styles.indexSelector}>
            {filteredIndexes.map((indexSymbol, i) => {
              const indexDetails =
                Index_UI.indexData.find(item => item.symbol === indexSymbol) ||
                {}
              const isSelected = indexSymbol === selectedIndex
              return (
                <div
                  key={i}
                  onClick={() => setSelectedIndex(indexSymbol)}
                  className={styles.index}
                  style={{
                    border: isSelected
                      ? '2px solid var(--primary-color)'
                      : '2px solid var(--background-tertiary)',
                  }}
                >
                  <div className={styles.title}>
                    {indexDetails.symbol}{' '}
                    <span style={{ color: 'var(--light-grey-accent)' }}>-</span>{' '}
                    {indexDetails.name}
                  </div>
                  <div className={styles.indexValue}>
                    {numeral(indexDetails.price).format('0,0.00')}
                    <span
                      style={{
                        color:
                          indexDetails.changesPercentage < 0
                            ? 'var(--red)'
                            : 'var(--green)',
                      }}
                    >
                      {indexDetails.changesPercentage > 0 && '+'}
                      {numeral(indexDetails.change).format('0,0.00')} (
                      {numeral(indexDetails.changesPercentage).format('0.00')}%)
                    </span>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      )}
      {Index_UI.historicalDataIsLoading ? (
        <Spinner />
      ) : (
        <div className={styles.chartWrapper}>
          <IndexChart
            data={filteredData}
            title={title()}
            setInterval={setInterval}
            interval={interval}
          />
        </div>
      )}
    </div>
  )
}

export default memo(IndexCharts)
