import numeral from 'numeral'
import TooltipTicker from 'core/TickerOverlay/TickerOverlay'
import styles from './Watchlist.module.scss'
import trash from './assets/trash.svg'

export const getOverviewColumns = handleDeleteTicker => [
  {
    Header: 'Symbol',
    accessor: 'symbol',
    width: 80,
    Cell: Cell => {
      return (
        <div>
          <TooltipTicker ticker={Cell.value} />
        </div>
      )
    },
  },
  {
    Header: 'Company Name',
    accessor: 'companyName',
    width: 260,
  },
  {
    Header: 'Price',
    accessor: 'price',
    isNumeric: true,
    Cell: Cell => {
      return <div>{numeral(Cell.value).format('0,0.00a')}</div>
    },
  },
  {
    Header: 'Change',
    accessor: 'changes',
    isNumeric: true,
    Cell: Cell => {
      const formattedValue = numeral(Cell.value).format('0.00')
      const valueWithSign =
        Cell.value > 0 ? `+${formattedValue}` : formattedValue
      return (
        <div style={{ color: Cell.value < 0 ? 'var(--red)' : 'var(--green)' }}>
          {valueWithSign}
        </div>
      )
    },
  },
  {
    Header: 'Beta',
    accessor: 'beta',
    isNumeric: true,
    Cell: Cell => {
      return <div>{numeral(Cell.value).format('0.00a')}</div>
    },
  },
  {
    Header: 'Sector',
    accessor: 'sector',
    width: 200,
  },
  {
    Header: 'Industry',
    accessor: 'industry',
    width: 200,
  },
  {
    Header: 'Market Cap',
    accessor: 'mktCap',
    isNumeric: true,
    width: 100,
    Cell: Cell => {
      return <div>{numeral(Cell.value).format('0.000a')}</div>
    },
  },
  {
    Header: 'Delete',
    id: 'delete',
    width: 60,
    accessor: str => 'delete',
    Cell: tableProps => (
      <div className={styles.deleteTickerButtonWrapper}>
        <img
          onClick={() => handleDeleteTicker(tableProps.row.original.symbol)}
          alt="delete ticker"
          src={trash}
          className={styles.deleteTicker}
        />
      </div>
    ),
  },
]

export const getQuoteColumns = handleDeleteTicker => [
  {
    Header: 'Symbol',
    accessor: 'symbol',
    width: 80,
    Cell: Cell => {
      return (
        <div>
          {' '}
          <TooltipTicker ticker={Cell.value} />{' '}
        </div>
      )
    },
  },
  {
    Header: 'Company Name',
    accessor: 'name',
    width: 260,
  },
  {
    Header: 'Price',
    accessor: 'price',
    isNumeric: true,
    Cell: Cell => {
      return <div>{numeral(Cell.value).format('0,0.00a')}</div>
    },
  },
  {
    Header: 'Change',
    accessor: 'change',
    isNumeric: true,
    Cell: Cell => {
      const formattedValue = numeral(Cell.value).format('0.00')
      const valueWithSign =
        Cell.value > 0 ? `+${formattedValue}` : formattedValue
      return (
        <div style={{ color: Cell.value < 0 ? 'var(--red)' : 'var(--green)' }}>
          {valueWithSign}
        </div>
      )
    },
  },
  {
    Header: 'Change %',
    accessor: 'changesPercentage',
    isNumeric: true,
    Cell: Cell => {
      const formattedValue = numeral(Cell.value).format('0.00')
      const valueWithSign =
        Cell.value > 0 ? `+${formattedValue}` : formattedValue
      return (
        <div style={{ color: Cell.value < 0 ? 'var(--red)' : 'var(--green)' }}>
          {valueWithSign}%
        </div>
      )
    },
  },
  {
    Header: 'Volume',
    accessor: 'volume',
    isNumeric: true,
    Cell: Cell => {
      return <div>{numeral(Cell.value).format('0.00a')}</div>
    },
  },
  {
    Header: 'Avg Volume',
    accessor: 'avgVolume',
    isNumeric: true,
    Cell: Cell => {
      return <div>{numeral(Cell.value).format('0.00a')}</div>
    },
  },
  {
    Header: 'Market Cap',
    accessor: 'marketCap',
    isNumeric: true,
    width: 100,
    Cell: Cell => {
      return <div>{numeral(Cell.value).format('0.000a')}</div>
    },
  },
  {
    Header: 'EPS',
    accessor: 'eps',
    isNumeric: true,
    Cell: Cell => {
      return <div>{numeral(Cell.value).format('0.00a')}</div>
    },
  },
  {
    Header: 'PE Ratio',
    accessor: 'pe',
    isNumeric: true,
    Cell: Cell => {
      return <div>{numeral(Cell.value).format('0.00a')}</div>
    },
  },
  {
    Header: 'Exchange',
    accessor: 'exchange',
    width: 100,
  },
  {
    Header: 'Delete',
    id: 'delete',
    width: 60,
    accessor: str => 'delete',
    Cell: value => (
      <div className={styles.deleteTickerButtonWrapper}>
        <img
          onClick={() => handleDeleteTicker(value?.row?.original?.symbol)}
          alt="delete ticker"
          src={trash}
          className={styles.deleteTicker}
        />
      </div>
    ),
  },
]

export const getColumns = () => [
  {
    Header: 'Ticker',
    accessor: 'ticker',
  },
  {
    Header: 'Delete',
    id: 'delete',
    accessor: str => 'delete',
  },
]

export const getReturnsColumns = handleDeleteTicker => [
  {
    Header: 'Symbol',
    accessor: 'symbol',
    Cell: Cell => {
      return (
        <div>
          {' '}
          <TooltipTicker ticker={Cell.value} />{' '}
        </div>
      )
    },
  },
  {
    Header: '1 Day',
    accessor: '1D',
    isNumeric: true,
    Cell: Cell => {
      return (
        <div style={{ color: Cell.value < 0 ? 'var(--red)' : 'var(--green)' }}>
          {numeral(Cell.value).format('0,0.00')}%
        </div>
      )
    },
  },
  {
    Header: '5 Days',
    accessor: '5D',
    isNumeric: true,
    Cell: Cell => {
      return (
        <div style={{ color: Cell.value < 0 ? 'var(--red)' : 'var(--green)' }}>
          {numeral(Cell.value).format('0,0.00')}%
        </div>
      )
    },
  },
  {
    Header: '1 Month',
    accessor: '1M',
    isNumeric: true,
    Cell: Cell => {
      return (
        <div style={{ color: Cell.value < 0 ? 'var(--red)' : 'var(--green)' }}>
          {numeral(Cell.value).format('0,0.00')}%
        </div>
      )
    },
  },
  {
    Header: '3 Months',
    accessor: '3M',
    isNumeric: true,
    Cell: Cell => {
      return (
        <div style={{ color: Cell.value < 0 ? 'var(--red)' : 'var(--green)' }}>
          {numeral(Cell.value).format('0,0.00')}%
        </div>
      )
    },
  },
  {
    Header: '6 Months',
    accessor: '6M',
    isNumeric: true,
    Cell: Cell => {
      return (
        <div style={{ color: Cell.value < 0 ? 'var(--red)' : 'var(--green)' }}>
          {numeral(Cell.value).format('0,0.00')}%
        </div>
      )
    },
  },
  {
    Header: '1 Year',
    accessor: '1Y',
    isNumeric: true,
    Cell: Cell => {
      return (
        <div style={{ color: Cell.value < 0 ? 'var(--red)' : 'var(--green)' }}>
          {numeral(Cell.value).format('0,0.00')}%
        </div>
      )
    },
  },
  {
    Header: '3 Years',
    accessor: '3Y',
    isNumeric: true,
    Cell: Cell => {
      return (
        <div style={{ color: Cell.value < 0 ? 'var(--red)' : 'var(--green)' }}>
          {numeral(Cell.value).format('0,0.00')}%
        </div>
      )
    },
  },
  {
    Header: '5 Years',
    accessor: '5Y',
    isNumeric: true,
    Cell: Cell => {
      return (
        <div style={{ color: Cell.value < 0 ? 'var(--red)' : 'var(--green)' }}>
          {numeral(Cell.value).format('0,0.00')}%
        </div>
      )
    },
  },
  {
    Header: '10 Years',
    accessor: '10Y',
    isNumeric: true,
    Cell: Cell => {
      return (
        <div style={{ color: Cell.value < 0 ? 'var(--red)' : 'var(--green)' }}>
          {numeral(Cell.value).format('0,0.00')}%
        </div>
      )
    },
  },
  {
    Header: 'Max',
    accessor: 'max',
    isNumeric: true,
    Cell: Cell => {
      return (
        <div style={{ color: Cell.value < 0 ? 'var(--red)' : 'var(--green)' }}>
          {numeral(Cell.value).format('0,0.00')}%
        </div>
      )
    },
  },
  {
    Header: 'Delete',
    id: 'delete',
    width: 60,
    accessor: str => 'delete',
    Cell: tableProps => (
      <div className={styles.deleteTickerButtonWrapper}>
        <img
          onClick={() => handleDeleteTicker(tableProps.row.original.symbol)}
          alt="delete ticker"
          src={trash}
          className={styles.deleteTicker}
        />
      </div>
    ),
  },
]
