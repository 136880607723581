import React, { useState, useRef } from 'react'
import Select from 'react-select'
import { tickerList, topTickerList } from '../../../constants/tickerList'
import { matchSorter } from 'match-sorter'
import { defaultTheme } from 'react-select'

export const multiSelectStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? '#1976d2'
        : isFocused
        ? '#e3f2fd'
        : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? defaultTheme.colors.textLight
        : data.color,
      cursor: 'default',
      display: 'block',
      fontSize: '13px',
      zIndex: 100,
      padding: '8px 12px',
      width: '',
      boxSizing: 'border-box',

      '&:hover': {
        backgroundColor: 'var(--button-hover)',
      },
    }
  },
  option: () => ({
    backgroundColor: 'transparent',
    color: 'var(--off-white)',
    cursor: 'default',
    display: 'block',
    fontSize: '13px',
    zIndex: 100,
    padding: '8px 12px',
    width: '',
    boxSizing: 'border-box',
    '&:hover': {
      backgroundColor: 'var(--button-hover)',
    },
  }),
  multiValue: styles => ({
    ...styles,
    backgroundColor: 'var(--background-tertiary)',
  }),
  multiValueLabel: styles => ({
    ...styles,
    color: defaultTheme.colors.text,
    fontSize: '13px',
  }),
  multiValueRemove: styles => ({
    ...styles,
    color: 'var(--red)',
    ':hover': {
      backgroundColor: 'var(--button-hover)',
      color: 'var(--red)',
    },
  }),
  placeholder: base => ({
    ...base,
    color: '#a9a9a9',
    fontSize: 'var(--large-text)',
    whiteSpace: 'nowrap',
  }),
  singleValue: () => ({
    color: 'var(--white)',
    gridArea: '1/1/2/3',
    marginLeft: '1px',
    marginRight: '1px',
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    fontSize: '16px',
    cursor: 'text',
  }),
  indicatorSeparator: () => ({
    backgroundColor: 'var(--off-white)',
    alignSelf: 'stretch',
    marginBottom: '8px',
    marginTop: '8px',
    width: '1px',
    boxSizing: 'border-box',
  }),
  menu: () => ({
    position: 'absolute',
    backgroundColor: 'var(--background-primary)',
    borderRadius: '4px',
    border: '2px solid var(--grey-accent)',
    boxShadow:
      '0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 12px hsl(0deg 0% 0% / 10%)',
    marginBottom: '8px',
    marginTop: '8px',
    zIndex: 100,
    boxSizing: 'border-box',
    width: '400px',
  }),
  menuPortal: provided => ({ ...provided, zIndex: 9999 }),
  MenuList: () => ({
    paddingBottom: '4px',
    maxHeight: '100px',
    overflowY: 'auto',
    paddingTop: '4px',
    zIndex: 100,
    position: 'relative',
    boxSizing: 'border-box',
  }),
  control: () => ({
    backgroundColor: 'var(--background-secondary)',
    display: 'flex',
    color: 'var(--white)',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: '14px',
    margin: '0px',
    borderRadius: '4px',
    cursor: 'pointer',
    width: '400px',
    border: '1.5px solid var(--grey-accent)',
  }),
  input: (provided, state) => ({
    ...provided,
    color: 'var(--white)',
    fontSize: '12px',
  }),
}

export const MultiSelectTickerSearch = ({ updateTicker, clearError }) => {
  const [options, setOptions] = useState(topTickerList)
  const [selectedTickers, setSelectedTickers] = useState([])
  const selectRef = useRef(0)

  const handleOnSelect = items => {
    setSelectedTickers(items)
    const tickers = items?.map(item => item.value) || []
    updateTicker(tickers)
    clearError('')
  }

  const handleChangeInput = value => {
    if (value) {
      return setOptions(
        matchSorter(tickerList, value, { keys: ['name', 'symbol'] })?.slice(
          0,
          10
        )
      )
    }
    setOptions(topTickerList)
  }

  return (
    <Select
      maxMenuHeight={360}
      ref={selectRef}
      pageSize={1}
      isMulti
      value={selectedTickers}
      onChange={e => handleOnSelect(e)}
      onInputChange={e => handleChangeInput(e)}
      options={options}
      closeMenuOnSelect={true}
      styles={multiSelectStyles}
      placeholder="Select or Search Symbols..."
      formatOptionLabel={option => `${option.name} (${option.value})`}
    />
  )
}
