import numeral from 'numeral'
import styles from './ScoreCard.module.scss'

export const ScoreCard = ({ score, symbol, name }) => {
  return (
    <div className={styles.scoreCardWrapper}>
      <div className={styles.scoreCardScore}>{numeral(score).format('0')}</div>
      <div className={styles.scoreCardName}>{name}</div>
    </div>
  )
}
