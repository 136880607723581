import { useState, useMemo, useCallback } from 'react'
import { useQuery } from 'react-query'
import { useURLSyncedGlobalTicker } from 'pages/views/hooks'
import queries from './queries'
import {
  calculateSeasonality,
  formatQuarterData,
  combineDataWithEarnings,
  calculateStatistics,
  predictChangePercent,
  filterData,
} from './utils'

const useEarningsTranscript = () => {
  const { handleSetTicker, ticker } = useURLSyncedGlobalTicker()

  const [selectedTranscriptDate, setSelectedTranscriptDate] = useState({
    year: null,
    quarter: null,
  })

  const { data: years, isLoading: yearsLoading } = useQuery(
    ['years', ticker],
    queries?.fetchYearsAndQuarters,
    {
      onSuccess: data => {
        setSelectedTranscriptDate(prev => ({
          ...prev,
          year: Math.max(...data?.uniqueYears),
        }))
      },
    }
  )

  const { data: quarters, isLoading: quartersLoading } = useQuery(
    ['quarters', ticker, selectedTranscriptDate.year],
    queries?.fetchQuarters,
    {
      enabled: !!selectedTranscriptDate.year,
      onSuccess: data => {
        setSelectedTranscriptDate(prev => ({
          ...prev,
          quarter: Math.max(...data),
        }))
      },
    }
  )

  const { data: historicalData, isLoading: historicalDataIsLoading } = useQuery(
    ['historical-data', ticker],
    queries?.fetchPriceData
  )

  const {
    data: { content = '', date = '' } = {},
    isLoading: contentDateLoading,
  } = useQuery(
    [
      'content',
      ticker,
      selectedTranscriptDate.year,
      selectedTranscriptDate.quarter,
    ],
    queries?.fetchContent,
    {
      enabled:
        !!selectedTranscriptDate.year && !!selectedTranscriptDate.quarter,
    }
  )

  const { data: companyEarnings, isLoading: companyEarningsIsLoading } =
    useQuery(['companyEarnings', ticker], queries?.fetchCompanyEarnings)

  const formattedQuarterData = useMemo(
    () => formatQuarterData(years?.finalData),
    [years?.finalData]
  )

  const combinedData = useMemo(
    () =>
      combineDataWithEarnings(
        companyEarnings,
        formattedQuarterData,
        historicalData
      ),
    [companyEarnings, formattedQuarterData, historicalData]
  )

  const chartData = useMemo(
    () => combinedData?.slice(0, 30)?.reverse(),
    [combinedData]
  )

  const slicedData = useMemo(() => combinedData?.slice(0, 30), [combinedData])

  const testData = useMemo(
    () => filterData(combinedData, 'epsEstimated'),
    [combinedData]
  )

  const futureDate = useMemo(() => testData?.[0]?.date, [testData])

  const prediction = useMemo(
    () => predictChangePercent(combinedData, futureDate),
    [combinedData, futureDate]
  )

  const statistics = useMemo(
    () => calculateStatistics(slicedData, prediction),
    [slicedData, prediction]
  )

  const transcriptLoading =
    contentDateLoading || quartersLoading || yearsLoading

  const Earnings_UI = useMemo(() => {
    const seasonality = key => calculateSeasonality(slicedData, key)?.reverse()
    return {
      statistics,
      seasonality,
      chartData,
      years,
      quarters,
      selectedYear: selectedTranscriptDate.year,
      selectedQuarter: selectedTranscriptDate.quarter,
      content,
      yearsLoading,
      quartersLoading,
      contentDateLoading,
      date,
      companyEarnings,
      companyEarningsIsLoading,
      transcriptLoading,
    }
  }, [
    statistics,
    chartData,
    years,
    quarters,
    selectedTranscriptDate.year,
    selectedTranscriptDate.quarter,
    content,
    yearsLoading,
    quartersLoading,
    contentDateLoading,
    date,
    companyEarnings,
    companyEarningsIsLoading,
    transcriptLoading,
    slicedData,
  ])

  return {
    Earnings_UI,
    setSelectedTranscriptDate,
    selectedTranscriptDate,
    ticker,
    setTicker: handleSetTicker,
  }
}

export default useEarningsTranscript
