import styles from './Watchlist.module.scss'
import CoreButton from 'core/Button/CoreButton'
import { useDataSource } from './Data/hook'
import {
  getOverviewColumns,
  getReturnsColumns,
  getQuoteColumns,
} from './column'
import React, { useMemo, useEffect, useCallback, useState } from 'react'
import Spinner from 'core/ScrollableContainer/Spinner/spinner'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { MultiSelectTickerSearch } from 'dashboard/Title/components/MultiSelectTickerSearch'
import { WatchlistNews } from './Components/WatchlistNews/WatchlistNews'
import WatchlistInformationModal from './Components/WatchlistInformationModal/WatchlistInformationModal'
import CreateWatchlistModal from './Components/CreateWatchlistModal/CreateWatchlistModal'
import AddTickerModal from './Components/AddTickersWatchlistModal/AddTickersWatchlistModal'
import { useWatchlists } from 'hooks/useWatchlist'
import DeleteWatchlistModal from './Components/DeleteWatchlistModal/DeleteWatchlistModal/DeleteWatchlistModal'
import { watchlistTabs } from './constant'
import { WatchlistOverview } from './Components/Overview'
import { WatchlistQuote } from './Components/Quote'
import { WatchlistReturns } from './Components/Returns'
import ProtectedButton from 'core/ProtectedButton/ProtectedButton'
import { Helmet } from 'react-helmet-async'
import { UilPlusCircle } from '@iconscout/react-unicons'
import { CreateWatchListButton } from './Components/ToolBar'

const Watchlist = () => {
  const [state, setState] = useState({
    watchlistTickers: ['AAPL'],
    isInfoModalOpen: false,
    isDeleteModalOpen: false,
    selectedTabIndex: 0,
    selectedWatchlistId: null,
    openAddTickerModal: false,
    selectedWatchlistName: '',
    selectedWatchlistDescription: '',
    duplicateTicker: '',
    newTicker: '',
    activeTab: 0,
    showStats: false,
    selectedTab: '',
  })

  const {
    watchlistTickers,
    isInfoModalOpen,
    isDeleteModalOpen,
    selectedTabIndex,
    selectedWatchlistId,
    openAddTickerModal,
    selectedWatchlistName,
    selectedWatchlistDescription,
    duplicateTicker,
    newTicker,
    activeTab,
    showStats,
    selectedTab,
  } = state

  const updateState = newState => {
    setState(prevState => ({
      ...prevState,
      ...newState,
    }))
  }

  const {
    readAll = {},
    isLoading,
    isError,
    updateWatchlist,
    createWatchlist,
    deleteWatchlist,
  } = useWatchlists()

  const { WATCH_UI } = useDataSource(watchlistTickers)

  const [openWatchlistModal, setOpenWatchlistModal] = useState(false)

  const dataProfiles = useMemo(
    () => WATCH_UI.companyData?.flat().map(data => data.profile),
    [WATCH_UI.companyData]
  )

  const profileData = useMemo(() => {
    if (!watchlistTickers || watchlistTickers.length === 0) {
      return []
    }

    return watchlistTickers.map(ticker => {
      const profile = dataProfiles?.find(profile => profile.symbol === ticker)
      return profile || { symbol: ticker }
    })
  }, [watchlistTickers, dataProfiles])

  const handleUpdateWatchlistInfo = updatedInfo => {
    updateState({
      selectedWatchlistName: updatedInfo.name,
      selectedWatchlistDescription: updatedInfo.description,
    })

    if (selectedWatchlistId !== null) {
      updateWatchlist({
        watchlist_id: selectedWatchlistId,
        body: {
          name: updatedInfo.name,
          description: updatedInfo.description,
          tickers: watchlistTickers,
        },
      })
    }
  }

  const watchlists = readAll?.item?.Items

  useEffect(() => {
    if (watchlists?.length && selectedWatchlistId === null) {
      updateState({
        selectedWatchlistId: watchlists[0]?.watchlistId,
        selectedWatchlistName: watchlists[0]?.name,
        selectedWatchlistDescription: watchlists[0]?.description,
        watchlistTickers: watchlists[0]?.tickers,
      })
    }
  }, [watchlists, selectedWatchlistId])

  const handleAddTicker = () => {
    if (selectedWatchlistId !== null && newTicker.length > 0) {
      const existingTickersSet = new Set(watchlistTickers)
      const duplicateTickers = newTicker.filter(ticker =>
        existingTickersSet.has(ticker)
      )
      const newTickersWithoutDuplicates = newTicker.filter(
        ticker => !existingTickersSet.has(ticker)
      )

      if (newTickersWithoutDuplicates.length > 0) {
        updateState({ duplicateTicker: '' })
        const updatedTickers = [
          ...watchlistTickers,
          ...newTickersWithoutDuplicates,
        ]
        updateState({ watchlistTickers: updatedTickers })
        updateWatchlist({
          watchlist_id: selectedWatchlistId,
          body: {
            tickers: updatedTickers,
            name: selectedWatchlistName,
            description: selectedWatchlistDescription,
          },
        })
        updateState({ openAddTickerModal: false })
      } else if (duplicateTickers.length > 0) {
        updateState({ duplicateTicker: duplicateTickers.join(', ') })
      }
      updateState({ newTicker: [] })
    }
  }

  const handleDeleteTicker = useCallback(
    deleteTicker => {
      const newTickers = watchlistTickers.filter(
        ticker => ticker !== deleteTicker
      )
      updateState({ watchlistTickers: newTickers })

      if (selectedWatchlistId !== null) {
        updateWatchlist({
          watchlist_id: selectedWatchlistId,
          body: {
            tickers: newTickers,
            name: selectedWatchlistName,
            description: selectedWatchlistDescription,
          },
        })
      }
    },
    [
      selectedWatchlistDescription,
      selectedWatchlistId,
      selectedWatchlistName,
      updateWatchlist,
      watchlistTickers,
    ]
  )

  const handleDeleteWatchlist = () => {
    deleteWatchlist({
      watchlist_id: selectedWatchlistId,
    })

    const updatedWatchlists = watchlists.filter(
      w => w.watchlistId !== selectedWatchlistId
    )

    let nextIndex = updatedWatchlists.length - 1
    if (activeTab < updatedWatchlists.length) {
      nextIndex = activeTab
    }

    if (updatedWatchlists[nextIndex]) {
      updateState({
        selectedWatchlistId: updatedWatchlists[nextIndex].watchlistId,
        selectedWatchlistName: updatedWatchlists[nextIndex].name,
        selectedWatchlistDescription: updatedWatchlists[nextIndex].description,
        watchlistTickers: updatedWatchlists[nextIndex].tickers,
        activeTab: nextIndex,
      })
    } else {
      updateState({
        selectedWatchlistId: null,
        selectedWatchlistName: '',
        selectedWatchlistDescription: '',
        watchlistTickers: [],
        activeTab: 0,
      })
    }

    updateState({ isDeleteModalOpen: false })
  }

  const handleSelectWatchlist = selectedOption => {
    const selectedWatchlist = watchlists.find(
      watchlist => watchlist.watchlistId === selectedOption.value
    )

    if (selectedWatchlist) {
      updateState({
        selectedWatchlistId: selectedWatchlist.watchlistId,
        selectedWatchlistName: selectedWatchlist.name,
        selectedWatchlistDescription: selectedWatchlist.description,
        watchlistTickers: selectedWatchlist.tickers.filter(
          ticker => ticker !== ''
        ),
      })
    }
  }

  const handleCloseInfoModal = () => {
    updateState({ isInfoModalOpen: false })
  }

  const handleOpenInfoModal = () => {
    updateState({ isInfoModalOpen: true })
  }

  const handleCloseDeleteModal = () => {
    updateState({ isDeleteModalOpen: false })
  }

  const handleOpenDeleteModal = () => {
    updateState({ isDeleteModalOpen: true })
  }

  const handleAddTickerModalClose = () => {
    updateState({ openAddTickerModal: false })
  }

  const setNewTicker = ticker => {
    updateState({ newTicker: ticker })
  }

  const setDuplicateTicker = ticker => {
    updateState({ duplicateTicker: ticker })
  }

  const overviewColumns = useMemo(
    () => getOverviewColumns(handleDeleteTicker),
    [handleDeleteTicker]
  )

  const returnsColumns = useMemo(
    () => getReturnsColumns(handleDeleteTicker),
    [handleDeleteTicker]
  )

  const quoteColumns = useMemo(
    () => getQuoteColumns(handleDeleteTicker),
    [handleDeleteTicker]
  )

  if (isLoading) {
    return <Spinner />
  }

  if (isError) {
    return (
      <div className={styles.watchlist}>
        There was an error loading the watchlists. Please try again.
      </div>
    )
  }
  return (
    <div className={styles.watchlist}>
      <Helmet>
        <title>{'Outseek | Watchlist'}</title>
        <meta
          name="description"
          content={
            'This is a watchlist where a user can create a portfolio to keep track of companies that they are interested in.'
          }
        />
      </Helmet>
      {!watchlists || watchlists.length === 0 ? (
        <div className={styles.noWatchlistsAvailable}>
          <div className={styles.noWatchlistsAvailableText}>
            You have no watchlists.
          </div>
          <ProtectedButton
            width={'fit-content'}
            padding={'4px 8px'}
            color={'var(--primary-color)'}
            onClick={() => setOpenWatchlistModal(true)}
            modal={true}
          >
            Create New Watchlist
          </ProtectedButton>
          <CreateWatchlistModal
            open={openWatchlistModal}
            handleClose={() => setOpenWatchlistModal(false)}
            createWatchlist={createWatchlist}
            watchlists={watchlists}
            updateState={updateState}
          />
        </div>
      ) : (
        <>
          <div className={styles.watchlistToolBar}>
            <div className={styles.watchlistDisplayTabs}>
              {watchlists.map((watchlist, index) => (
                <>
                  <div
                    key={watchlist.watchlistId}
                    className={styles.watchlistDisplayTab}
                    onClick={() => {
                      handleSelectWatchlist({ value: watchlist.watchlistId })
                      updateState({ activeTab: index })
                    }}
                    style={{
                      backgroundColor:
                        activeTab === index ? 'var(--grey-accent)' : undefined,
                    }}
                  >
                    {watchlist.name}
                  </div>
                  {index !== watchlists.length - 1 && (
                    <>
                      {activeTab !== index && activeTab !== index + 1 && (
                        <div className={styles.tabDivider} />
                      )}
                      {activeTab === index + 1 && (
                        <div className={styles.invisibleTabDivider} />
                      )}
                    </>
                  )}
                </>
              ))}
            </div>
            <CreateWatchListButton openWatchlistModal={setOpenWatchlistModal} />
            <CreateWatchlistModal
              open={openWatchlistModal}
              handleClose={() => setOpenWatchlistModal(false)}
              createWatchlist={createWatchlist}
              watchlists={watchlists}
              updateState={updateState}
            />
          </div>
          <div className={styles.watchlistContent}>
            {WATCH_UI.companyDataIsLoading || WATCH_UI.returnsDataIsLoading ? (
              <Spinner />
            ) : (
              <div className={styles.insideWrapper}>
                {!watchlistTickers || watchlistTickers.length === 0 ? (
                  <div className={styles.noSymbolContainer}>
                    Add symbols to {selectedWatchlistName}.
                    <MultiSelectTickerSearch updateTicker={setNewTicker} />
                    <CoreButton
                      color={'var(--primary-color)'}
                      onClick={handleAddTicker}
                    >
                      <UilPlusCircle size={16} color={'var(--white)'} />
                      Add Symbols
                    </CoreButton>
                    <CoreButton
                      color={'var(--red)'}
                      onClick={handleOpenDeleteModal}
                    >
                      Delete Watchlist
                    </CoreButton>
                    <DeleteWatchlistModal
                      isOpen={isDeleteModalOpen}
                      handleClose={handleCloseDeleteModal}
                      handleDelete={handleDeleteWatchlist}
                      watchlistName={selectedWatchlistName}
                    />
                  </div>
                ) : (
                  <>
                    <div className={styles.watchlistContentToolBar}>
                      <div className={styles.watchlistNameTitle}>
                        {selectedWatchlistName}
                      </div>
                      <div className={styles.watchlistContentToolBarButtons}>
                        <CoreButton
                          onClick={() =>
                            updateState({ openAddTickerModal: true })
                          }
                        >
                          <UilPlusCircle size={16} color={'var(--white)'} />
                          Add Symbols
                        </CoreButton>
                        <AddTickerModal
                          open={openAddTickerModal}
                          handleClose={handleAddTickerModalClose}
                          updateTicker={setNewTicker}
                          handleAddTicker={handleAddTicker}
                          selectedWatchlistName={selectedWatchlistName}
                          duplicateTicker={duplicateTicker}
                          setDuplicateTicker={setDuplicateTicker}
                        />
                        <WatchlistInformationModal
                          isOpen={isInfoModalOpen}
                          handleClose={handleCloseInfoModal}
                          watchlistInfo={{
                            name: selectedWatchlistName,
                            description: selectedWatchlistDescription,
                          }}
                          updateWatchlistInfo={handleUpdateWatchlistInfo}
                          watchlists={watchlists}
                        />
                        <CoreButton onClick={handleOpenInfoModal}>
                          Edit Watchlist Info
                        </CoreButton>
                        <CoreButton
                          color={'var(--red)'}
                          onClick={handleOpenDeleteModal}
                        >
                          Delete Watchlist
                        </CoreButton>
                        <DeleteWatchlistModal
                          isOpen={isDeleteModalOpen}
                          handleClose={handleCloseDeleteModal}
                          handleDelete={handleDeleteWatchlist}
                          watchlistName={selectedWatchlistName}
                        />
                      </div>
                    </div>
                    <Tabs
                      selectedIndex={selectedTabIndex}
                      onSelect={index =>
                        updateState({ selectedTabIndex: index })
                      }
                      className={styles.watchlistTabs}
                      selectedTabClassName={styles.watchlistSelectedTab}
                    >
                      <TabList className={styles.watchlistTabList}>
                        {watchlistTabs.map((tabName, index) => (
                          <React.Fragment key={index}>
                            <Tab
                              className={styles.watchlistTab}
                              onClick={() =>
                                updateState({ selectedTab: tabName })
                              }
                            >
                              {tabName}
                            </Tab>
                            {index !== watchlistTabs.length - 1 &&
                              tabName !== selectedTab &&
                              watchlistTabs[index + 1] !== selectedTab && (
                                <div className={styles.tabDivider} />
                              )}
                          </React.Fragment>
                        ))}
                      </TabList>
                      <TabPanel className={styles.watchlistTabPanel}>
                        <WatchlistOverview
                          profileData={profileData}
                          overviewColumns={overviewColumns}
                          selectedWatchlistName={selectedWatchlistName}
                          updateState={updateState}
                          showStats={showStats}
                        />
                      </TabPanel>
                      <TabPanel className={styles.watchlistTabPanel}>
                        <WatchlistQuote
                          quoteData={WATCH_UI?.quoteData}
                          quoteColumns={quoteColumns}
                          profileData={profileData}
                          overviewColumns={overviewColumns}
                          selectedWatchlistName={selectedWatchlistName}
                          updateState={updateState}
                          showStats={showStats}
                        />
                      </TabPanel>
                      <TabPanel className={styles.watchlistTabPanel}>
                        <WatchlistReturns
                          returnsData={WATCH_UI?.returnsData}
                          returnsColumns={returnsColumns}
                          updateState={updateState}
                          showStats={showStats}
                        />
                      </TabPanel>
                      <TabPanel className={styles.watchlistTabPanel}>
                        <WatchlistNews
                          data={WATCH_UI?.newsData}
                          height={'calc(100vh - 200px)'}
                        />
                      </TabPanel>
                    </Tabs>
                  </>
                )}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  )
}
export default Watchlist
