//widgets
export const RANGE = 'Range'
export const EMPLOYEE_COUNT = 'Employee Count'
export const ESG_SCORE = 'ESG Score'
export const MARKET_CAP = 'Market Cap'
export const BALANCE_SHEET_BREAKDOWN = 'Balance Sheet Breakdown'
export const DCF = 'Discounted Cash Flow'
export const ENTERPRISE_VALUE = 'Enterprise Value'
export const INCOME_STATEMENT_GROWTH = 'Income Statement Growth'
export const BALANCE_SHEET_GROWTH = 'Balance Sheet Growth'
export const VALUE_RATIO = 'Value Ratios'
export const RISK_ANALYSIS_RATIO = 'Risk Analysis Ratios'
export const PRICE_RATIOS = 'Price Ratios'
export const OPERATING_PERFORMANCE_RATIOS = 'Operating Performance Ratios'
export const LIQUIDITY_RATIOS = 'Liquidity Ratios'
export const LAST_REPORTED_STATEMENTS = 'Last Reported Statements'
export const INSTITUTIONAL_OWNERSHIP = 'Institutional Ownership'
export const NUMBER_OF_INST_INVESTORS = 'Number of Institutional Holders'
export const NYSE_HOLIDAY = 'NYSE Holidays'
export const MAJOR_MARKET_INDEX = 'Major Market Indexes'
export const AVERAGE_INDUSTRY_PE = 'Average Industry P/E Ratio'
export const AVERAGE_SECTOR_PE = 'Average Sector P/E Ratio'
export const PRICE_TARGETS = 'Price Target'
export const UPGRADES_AND_DOWNGRADES = 'Upgrades and Downgrades Consensus'
export const SOCIAL_SENTIMENT = 'Social Sentiment'
export const BETA = 'beta'

// not present
// charts
export const ASSET_BREAKDOWN = 'Asset Breakdown'
export const LIABILITIES_BREAKDOWN = 'Liabilities Breakdown'
export const EQUITY_BREAKDOWN = 'Equity Breakdown'
export const INCOME_STATEMENT_BREAKDOWN = 'Income Statement Breakdown'
export const REVENUE_BREAKDOWN = 'Revenue Breakdown'
export const EXPENSE_BREAKDOWN = 'Expense Breakdown'
export const INCOME_BREAKDOWN = 'Income Breakdown'
export const CASHFLOW_BREAKDOWN = 'Cash Flow Statement Breakdown'

//widgets

// not present
// charts
export const CURRENT_ASSET_BREAKDOWN = 'Current Asset Breakdown'
export const NON_CURRENT_ASSET_BREAKDOWN = 'Non Current Asset Breakdown'
export const CURRENT_LIABILITIES_BREAKDOWN = 'Current Liabilities Breakdown'
export const NON_CURRENT_LIABILITIES_BREAKDOWN =
  'Non Current Liabilities Breakdown'

export const CASHFLOW_STATEMENT_BREAKDOWN = 'Cash Flow Statement Breakdown'
export const FINANCING_ACTIVITIES_BREAKDOWN = 'Financing Activities Breakdown'
export const INVESTING_ACTIVITIES_BREAKDOWN = 'Investing Activities Breakdown'
export const OPERATING_ACTIVITIES_BREAKDOWN = 'Operating Activities Breakdown'
export const INSTITUTIONAL_POSITION_SUMMARY = 'Institutional Position Summary'

// growth

export const DEBT_BREAKDOWN = 'Debt Breakdown'

export const REVENUE_GROWTH_BREAKDOWN = 'Revenue Growth Breakdown'
export const EXPENSE_GROWTH_BREAKDOWN = 'Revenue Growth Breakdown'
export const INCOME_GROWTH_BREAKDOWN = 'Income Growth Breakdown'
export const BALANCE_SHEET_GROWTH_BREAKDOWN = 'Balance Sheet Growth Breakdown'
export const CURRENT_ASSET_GROWTH_BREAKDOWN = 'Current Asset Growth Breakdown'
export const NON_CURRENT_ASSET_GROWTH_BREAKDOWN =
  'Non Current Asset Growth Breakdown'
export const ASSET_GROWTH_BREAKDOWN = 'Asset Growth Breakdown'
export const LIABILITIES_GROWTH_BREAKDOWN = 'Liabilities Growth Breakdown'
export const NON_CURRENT_LIABILITIES_GROWTH_BREAKDOWN =
  'Non Current Liabilities Growth Breakdown'
export const DEBT_GROWTH_BREAKDOWN = 'Debt Growth Breakdown'
export const CURRENT_LIABILITIES_GROWTH_BREAKDOWN =
  'Current Liabilities Growth Breakdown'
export const EQUITY_GROWTH_BREAKDOWN = 'Equity Growth Breakdown'
export const CASHFLOW_STATEMENT_GROWTH_BREAKDOWN =
  'Cash Flow Statement Growth Breakdown'
export const OPERATING_ACTIVITIES_GROWTH_BREAKDOWN =
  'Operating Activities Growth Breakdown'
export const INVESTING_ACTIVITIES_GROWTH_BREAKDOWN =
  'Investing Activities Growth Breakdown'
export const FINANCING_ACTIVITIES_GROWTH_BREAKDOWN =
  'Financing Activities Growth Breakdown'
export const CASHFLOW_GROWTH_BREAKDOWN = 'Cash Flow Growth Breakdown'
export const TWITTER_SENTIMENT = 'Twitter Sentiment'
export const STOCKTWITS_SENTIMENT = 'Stocktwits Sentiment'

const widgetConfig = {
  //Financial Statements Start Here *
  [INCOME_STATEMENT_BREAKDOWN]: {
    call: 'papi/data/api/v3/income-statement/AAPL?limit=120',
    keys: [
      {
        key: 'revenue',
        labels: 'revenue',
        color: '',
      },
      {
        key: 'grossProfit',
        labels: 'grossProfit',
        color: '',
      },
      {
        key: 'operatingExpenses',
        labels: 'operatingExpenses',
        color: '',
      },
      {
        key: 'ebitdaratio',
        labels: 'ebitdaRatio',
        color: '',
      },
      {
        key: 'netIncome',
        labels: 'netIncome',
        color: '',
      },
    ],
  },
  [REVENUE_BREAKDOWN]: {
    call: 'papi/data/api/v3/income-statement/AAPL?limit=120',
    keys: [
      {
        key: 'revenue',
        labels: 'revenue',
        color: '',
      },
      {
        key: 'costOfRevenue',
        labels: 'costOfRevenue',
        color: '',
      },
      {
        key: 'grossProfit',
        labels: 'grossProfit',
        color: '',
      },
      {
        key: 'grossProfitRatio',
        labels: 'grossProfitRatio',
        color: '',
      },
      {
        key: 'netIncome',
        labels: 'netIncome',
        color: '',
      },
    ],
  },
  [EXPENSE_BREAKDOWN]: {
    call: 'papi/data/api/v3/income-statement/AAPL?limit=120',
    keys: [
      {
        key: 'researchAndDevelopmentExpenses',
        labels: 'RAndDExpenses',
        color: '',
      },
      {
        key: 'sellingGeneralAndAdministrativeExpenses',
        labels: 'SGAExpenses',
        color: '',
      },
      {
        key: 'operatingExpenses',
        labels: 'operatingExpenses',
        color: '',
      },
      {
        key: 'interestExpense',
        labels: 'interestExpense',
        color: '',
      },
      {
        key: 'otherExpenses',
        labels: 'otherExpenses',
        color: '',
      },
    ],
  },
  [INCOME_BREAKDOWN]: {
    call: 'papi/data/api/v3/income-statement/AAPL?limit=120',
    keys: [
      {
        key: 'netIncome',
        labels: 'netIncome',
        color: '',
      },
      {
        key: 'operatingIncome',
        labels: 'operatingIncome',
        color: '',
      },
      {
        key: 'operatingIncomeRatio',
        labels: 'operatingIncomeRatio',
        color: '',
      },
      {
        key: 'interestIncome',
        labels: 'interestIncome',
        color: '',
      },
      {
        key: 'incomeBeforeTax',
        labels: 'incomeBeforeTax',
        color: '',
      },
    ],
  },
  [BALANCE_SHEET_BREAKDOWN]: {
    call: 'papi/data/api/v3/balance-sheet-statement/AAPL?limit=120',
    keys: [
      {
        key: 'totalAssets',
        labels: 'totalAssets',
        color: '',
      },
      {
        key: 'totalLiabilities',
        labels: 'totalLiabilities',
        color: '',
      },
      {
        key: 'totalStockholdersEquity',
        labels: 'StockholdersEquity',
        color: '',
      },
      {
        key: 'retainedEarnings',
        labels: 'retainedEarnings',
        color: '',
      },
      {
        key: 'netDebt',
        labels: 'netDebt',
        color: '',
      },
    ],
  },
  [CURRENT_ASSET_BREAKDOWN]: {
    call: 'papi/data/api/v3/balance-sheet-statement/AAPL?limit=120',
    keys: [
      {
        key: 'cashAndShortTermInvestments',
        labels: 'cashAndShortTermInvestments',
        color: '',
      },
      {
        key: 'netReceivables',
        labels: 'netReceivables',
        color: '',
      },
      {
        key: 'inventory',
        labels: 'inventory',
        color: '',
      },
      {
        key: 'otherCurrentAssets',
        labels: 'otherCurrentAssets',
        color: '',
      },
      {
        key: 'totalCurrentAssets',
        labels: 'totalCurrentAssets',
        color: '',
      },
    ],
  },
  [NON_CURRENT_ASSET_BREAKDOWN]: {
    call: 'papi/data/api/v3/balance-sheet-statement/AAPL?limit=120',
    keys: [
      {
        key: 'propertyPlantEquipmentNet',
        labels: 'netPropertyPlantEquipment',
        color: '',
      },
      {
        key: 'goodwillAndIntangibleAssets',
        labels: 'goodwillAndIntangibles',
        color: '',
      },
      {
        key: 'longTermInvestments',
        labels: 'longTermInvestments',
        color: '',
      },
      {
        key: 'otherNonCurrentAssets',
        labels: 'otherNonCurrentAssets',
        color: '',
      },
      {
        key: 'totalNonCurrentAssets',
        labels: 'totalNonCurrentAssets',
        color: '',
      },
    ],
  },
  [ASSET_BREAKDOWN]: {
    call: 'papi/data/api/v3/balance-sheet-statement/AAPL?limit=120',
    keys: [
      {
        key: 'cashAndCashEquivalents',
        labels: 'cashAndCashEquivalents',
        color: '',
      },
      {
        key: 'propertyPlantEquipmentNet',
        labels: 'propertyPlantAndEquipment',
        color: '',
      },
      {
        key: 'totalCurrentAssets',
        labels: 'totalCurrentAssets',
        color: '',
      },
      {
        key: 'totalNonCurrentAssets',
        labels: 'totalNonCurrentAssets',
        color: '',
      },
      {
        key: 'totalAssets',
        labels: 'totalAssets',
        color: '',
      },
    ],
  },

  [LIABILITIES_BREAKDOWN]: {
    call: 'papi/data/api/v3/balance-sheet-statement/AAPL?limit=120',
    keys: [
      {
        key: 'shortTermDebt',
        labels: 'shortTermDebt',
        color: '',
      },
      {
        key: 'longTermDebt',
        labels: 'longTermDebt',
        color: '',
      },
      {
        key: 'totalCurrentLiabilities',
        labels: 'totalCurrentLiabilities',
        color: '',
      },
      {
        key: 'totalNonCurrentLiabilities',
        labels: 'totalNonCurrentLiabilities',
        color: '',
      },
      {
        key: 'totalLiabilities',
        labels: 'totalLiabilities',
        color: '',
      },
    ],
  },
  [NON_CURRENT_LIABILITIES_BREAKDOWN]: {
    call: 'papi/data/api/v3/balance-sheet-statement/AAPL?limit=120',
    keys: [
      {
        key: 'longTermDebt',
        labels: 'longTermDebt',
        color: '',
      },
      {
        key: 'deferredRevenueNonCurrent',
        labels: 'deferredRevenue',
        color: '',
      },
      {
        key: 'deferredTaxLiabilitiesNonCurrent',
        labels: 'deferredTaxLiabilities',
        color: '',
      },
      {
        key: 'otherNonCurrentLiabilities',
        labels: 'otherNonCurrentLiabilities',
        color: '',
      },
      {
        key: 'totalNonCurrentLiabilities',
        labels: 'totalNonCurrentLiabilities',
        color: '',
      },
    ],
  },
  [DEBT_BREAKDOWN]: {
    call: 'papi/data/api/v3/balance-sheet-statement/AAPL?limit=120',
    keys: [
      {
        key: 'shortTermDebt',
        labels: 'shortTermDebt',
        color: '',
      },
      {
        key: 'longTermDebt',
        labels: 'longTermDebt',
        color: '',
      },
      {
        key: 'totalDebt',
        labels: 'totalDebt',
        color: '',
      },
      {
        key: 'netDebt',
        labels: 'netDebt',
        color: '',
      },
      {
        key: 'capitalLeaseObligations',
        labels: 'capitalLeaseObligations',
        color: '',
      },
    ],
  },
  [CURRENT_LIABILITIES_BREAKDOWN]: {
    call: 'papi/data/api/v3/balance-sheet-statement/AAPL?limit=120',
    keys: [
      {
        key: 'accountPayables',
        labels: 'accountPayables',
        color: '',
      },
      {
        key: 'shortTermDebt',
        labels: 'shortTermDebt',
        color: '',
      },
      {
        key: 'taxPayables',
        labels: 'taxPayables',
        color: '',
      },
      {
        key: 'otherCurrentLiabilities',
        labels: 'otherCurrentLiabilities',
        color: '',
      },
      {
        key: 'totalCurrentLiabilities',
        labels: 'totalCurrentLiabilities',
        color: '',
      },
    ],
  },
  [EQUITY_BREAKDOWN]: {
    call: 'papi/data/api/v3/balance-sheet-statement/AAPL?limit=120',
    keys: [
      {
        key: 'totalStockholdersEquity',
        labels: 'totalStockholdersEquity',
        color: '',
      },
      {
        key: 'commonStock',
        labels: 'commonStock',
        color: '',
      },
      {
        key: 'retainedEarnings',
        labels: 'retainedEarnings',
        color: '',
      },
      {
        key: 'accumulatedOtherComprehensiveIncomeLoss',
        labels: 'accumulatedOtherComprehensiveIncomeLoss',
        color: '',
      },
      {
        key: 'totalEquity',
        labels: 'totalEquity',
        color: '',
      },
    ],
  },
  [CASHFLOW_STATEMENT_BREAKDOWN]: {
    call: 'papi/data/api/v3/cash-flow-statement/AAPL?limit=120',
    keys: [
      {
        key: 'netCashProvidedByOperatingActivities',
        labels: 'cashFromOperatingActivities',
        color: '',
      },
      {
        key: 'netCashUsedForInvestingActivites',
        labels: 'cashFromInvestingActivites',
        color: '',
      },
      {
        key: 'netCashUsedProvidedByFinancingActivities',
        labels: 'cashFromFinancingActivities',
        color: '',
      },
      {
        key: 'capitalExpenditure',
        labels: 'capitalExpenditure',
        color: '',
      },
      {
        key: 'freeCashFlow',
        labels: 'freeCashFlow',
        color: '',
      },
    ],
  },
  [OPERATING_ACTIVITIES_BREAKDOWN]: {
    call: 'papi/data/api/v3/cash-flow-statement/AAPL?limit=120',
    keys: [
      {
        key: 'depreciationAndAmortization',
        labels: 'depreciationAndAmortization',
        color: '',
      },
      {
        key: 'stockBasedCompensation',
        labels: 'stockBasedCompensation',
        color: '',
      },
      {
        key: 'accountsReceivables',
        labels: 'accountsReceivables',
        color: '',
      },
      {
        key: 'inventory',
        labels: 'inventory',
        color: '',
      },
      {
        key: 'netCashProvidedByOperatingActivities',
        labels: 'cashFromOperatingActivities',
        color: '',
      },
    ],
  },
  [INVESTING_ACTIVITIES_BREAKDOWN]: {
    call: 'papi/data/api/v3/cash-flow-statement/AAPL?limit=120',
    keys: [
      {
        key: 'investmentsInPropertyPlantAndEquipment',
        labels: 'investmentsInPPE',
        color: '',
      },
      {
        key: 'acquisitionsNet',
        labels: 'netAcquisitions',
        color: '',
      },
      {
        key: 'purchasesOfInvestments',
        labels: 'purchasesOfInvestments',
        color: '',
      },
      {
        key: 'salesMaturitiesOfInvestments',
        labels: 'salesMaturitiesOfInvestments',
        color: '',
      },
      {
        key: 'netCashUsedForInvestingActivites',
        labels: 'cashFromInvestingActivites',
        color: '',
      },
    ],
  },
  [FINANCING_ACTIVITIES_BREAKDOWN]: {
    call: 'papi/data/api/v3/cash-flow-statement/AAPL?limit=120',
    keys: [
      {
        key: 'debtRepayment',
        labels: 'debtRepayment',
        color: '',
      },
      {
        key: 'commonStockIssued',
        labels: 'commonStockIssued',
        color: '',
      },
      {
        key: 'commonStockRepurchased',
        labels: 'commonStockRepurchased',
        color: '',
      },
      {
        key: 'dividendsPaid',
        labels: 'dividendsPaid',
        color: '',
      },
      {
        key: 'netCashUsedProvidedByFinancingActivities',
        labels: 'cashFromFinancingActivities',
        color: '',
      },
    ],
  },
  [CASHFLOW_BREAKDOWN]: {
    call: 'papi/data/api/v3/cash-flow-statement/AAPL?limit=120',
    keys: [
      {
        key: 'netChangeInCash',
        labels: 'netChangeInCash',
        color: '',
      },
      {
        key: 'cashAtEndOfPeriod',
        labels: 'cashAtEndOfPeriod',
        color: '',
      },
      {
        key: 'operatingCashFlow',
        labels: 'operatingCashFlow',
        color: '',
      },
      {
        key: 'capitalExpenditure',
        labels: 'capitalExpenditure',
        color: '',
      },
      {
        key: 'freeCashFlow',
        labels: 'freeCashFlow',
        color: '',
      },
    ],
  },
  //Institution Info Starts Here *
  [INSTITUTIONAL_OWNERSHIP]: {
    call: 'papi/data/api/v4/institutional-ownership/symbol-ownership?symbol=AAPL&includeCurrentQuarter=false',
    keys: [
      {
        key: 'investorsHolding',
        labels: 'investorsHolding',
        color: '',
      },
      {
        key: 'ownershipPercent',
        labels: 'ownershipPercent',
        color: '',
      },
      {
        key: 'totalInvested',
        labels: 'totalInvested',
        color: '',
      },
      {
        key: 'numberOf13Fshares',
        labels: 'numberOf13Fshares',
        color: '',
      },
      {
        key: 'putCallRatio',
        labels: 'putCallRatio',
        color: '',
      },
    ],
  },
  [INSTITUTIONAL_POSITION_SUMMARY]: {
    call: 'papi/data/api/v4/institutional-ownership/symbol-ownership?symbol=AAPL&includeCurrentQuarter=false',
    keys: [
      {
        key: 'newPositions',
        labels: 'newPositions',
        color: '',
      },
      {
        key: 'closedPositions',
        labels: 'closedPositions',
        color: '',
      },
      {
        key: 'increasedPositions',
        labels: 'increasedPositions',
        color: '',
      },
      {
        key: 'reducedPositions',
        labels: 'reducedPositions',
        color: '',
      },
      {
        key: 'putCallRatio',
        labels: 'putCallRatio',
        color: '',
      },
    ],
  },
  [ESG_SCORE]: {
    call: 'papi/data/api/v4/esg-environmental-social-governance-data?symbol=AAPL',
    keys: [
      {
        key: 'ESGScore',
        labels: 'ESGScore',
        color: '',
      },
      {
        key: 'environmentalScore',
        labels: 'environmentalScore',
        color: '',
      },
      {
        key: 'socialScore',
        labels: 'socialScore',
        color: '',
      },
      {
        key: 'governanceScore',
        labels: 'governanceScore',
        color: '',
      },
    ],
  },
  // Financial Statement Growth starts here *
  [INCOME_STATEMENT_GROWTH]: {
    call: 'papi/data/api/v3/income-statement-growth/AAPL?limit=40',
    keys: [
      {
        key: 'growthRevenue',
        labels: 'growthRevenue',
        color: '',
      },
      {
        key: 'growthGrossProfit',
        labels: 'growthGrossProfit',
        color: '',
      },
      {
        key: 'growthOperatingExpenses',
        labels: 'growthOperatingExpenses',
        color: '',
      },
      {
        key: 'growthEBITDA',
        labels: 'growthEBITDA',
        color: '',
      },
      {
        key: 'growthNetIncome',
        labels: 'growthNetIncome',
        color: '',
      },
    ],
  },
  [REVENUE_GROWTH_BREAKDOWN]: {
    call: 'papi/data/api/v3/income-statement-growth/AAPL?limit=40',
    keys: [
      {
        key: 'growthRevenue',
        labels: 'revenueGrowth',
        color: '',
      },
      {
        key: 'growthCostOfRevenue',
        labels: 'costOfRevenueGrowth',
        color: '',
      },
      {
        key: 'growthGrossProfit',
        labels: 'grossProfitGrowth',
        color: '',
      },
      {
        key: 'growthGrossProfitRatio',
        labels: 'grossProfitRatioGrowth',
        color: '',
      },
      {
        key: 'growthNetIncome',
        labels: 'netIncomeGrowth',
        color: '',
      },
    ],
  },
  [EXPENSE_GROWTH_BREAKDOWN]: {
    call: 'papi/data/api/v3/income-statement-growth/AAPL?limit=40',
    keys: [
      {
        key: 'growthResearchAndDevelopmentExpenses',
        labels: 'researchAndDevelopmentExpensesGrowth',
        color: '',
      },
      {
        key: 'growthGeneralAndAdministrativeExpenses',
        labels: 'generalAndAdministrativeExpensesGrowth',
        color: '',
      },
      {
        key: 'growthOperatingExpenses',
        labels: 'operatingExpensesGrowth',
        color: '',
      },
      {
        key: 'growthInterestExpense',
        labels: 'interestExpenseGrowth',
        color: '',
      },
      {
        key: 'growthOtherExpenses',
        labels: 'otherExpensesGrowth',
        color: '',
      },
    ],
  },
  [INCOME_GROWTH_BREAKDOWN]: {
    call: 'papi/data/api/v3/income-statement-growth/AAPL?limit=40',
    keys: [
      {
        key: 'growthNetIncome',
        labels: 'netIncomeGrowth',
        color: '',
      },
      {
        key: 'growthOperatingIncome',
        labels: 'operatingIncomeGrowth',
        color: '',
      },
      {
        key: 'growthOperatingIncomeRatio',
        labels: 'operatingIncomeRatioGrowth',
        color: '',
      },
      {
        key: 'growthIncomeBeforeTax',
        labels: 'incomeBeforeTaxGrowth',
        color: '',
      },
      {
        key: 'growthIncomeBeforeTaxRatio',
        labels: 'incomeBeforeTaxRatioGrowth',
        color: '',
      },
    ],
  },
  [BALANCE_SHEET_GROWTH_BREAKDOWN]: {
    call: 'papi/data/api/v3/balance-sheet-statement-growth/AAPL?limit=40',
    keys: [
      {
        key: 'growthTotalAssets',
        labels: 'totalAssetsGrowth',
        color: '',
      },
      {
        key: 'growthTotalLiabilities',
        labels: 'totalLiabilitiesGrowth',
        color: '',
      },
      {
        key: 'growthTotalStockholdersEquity',
        labels: 'totalStockholdersEquityGrowth',
        color: '',
      },
      {
        key: 'growthRetainedEarnings',
        labels: 'retainedEarningsGrowth',
        color: '',
      },
      {
        key: 'growthNetDebt',
        labels: 'netDebtGrowth',
        color: '',
      },
    ],
  },
  [CURRENT_ASSET_GROWTH_BREAKDOWN]: {
    call: 'papi/data/api/v3/balance-sheet-statement-growth/AAPL?limit=40',
    keys: [
      {
        key: 'growthCashAndShortTermInvestments',
        labels: 'cashAndShortTermInvestmentsGrowth',
        color: '',
      },
      {
        key: 'growthNetReceivables',
        labels: 'netReceivablesGrowth',
        color: '',
      },
      {
        key: 'growthInventory',
        labels: 'inventoryGrowth',
        color: '',
      },
      {
        key: 'growthOtherCurrentAssets',
        labels: 'otherCurrentAssetsGrowth',
        color: '',
      },
      {
        key: 'growthTotalCurrentAssets',
        labels: 'totalCurrentAssetsGrowth',
        color: '',
      },
    ],
  },
  [NON_CURRENT_ASSET_GROWTH_BREAKDOWN]: {
    call: 'papi/data/api/v3/balance-sheet-statement-growth/AAPL?limit=40',
    keys: [
      {
        key: 'growthPropertyPlantEquipmentNet',
        labels: 'propertyPlantEquipmentNetGrowth',
        color: '',
      },
      {
        key: 'growthGoodwillAndIntangibleAssets',
        labels: 'goodwillAndIntangibleAssetsGrowth',
        color: '',
      },
      {
        key: 'growthLongTermInvestments',
        labels: 'longTermInvestmentsGrowth',
        color: '',
      },
      {
        key: 'growthOtherNonCurrentAssets',
        labels: 'otherNonCurrentAssetsGrowth',
        color: '',
      },
      {
        key: 'growthTotalNonCurrentAssets',
        labels: 'totalNonCurrentAssetsGrowth',
        color: '',
      },
    ],
  },
  [ASSET_GROWTH_BREAKDOWN]: {
    call: 'papi/data/api/v3/balance-sheet-statement-growth/AAPL?limit=40',
    keys: [
      {
        key: 'growthCashAndCashEquivalents',
        labels: 'cashAndCashEquivalentsGrowth',
        color: '',
      },
      {
        key: 'growthPropertyPlantEquipmentNet',
        labels: 'propertyPlantEquipmentNetGrowth',
        color: '',
      },
      {
        key: 'growthTotalCurrentAssets',
        labels: 'totalCurrentAssetsGrowth',
        color: '',
      },
      {
        key: 'growthTotalNonCurrentAssets',
        labels: 'totalNonCurrentAssetsGrowth',
        color: '',
      },
      {
        key: 'growthTotalAssets',
        labels: 'totalAssetsGrowth',
        color: '',
      },
    ],
  },
  [LIABILITIES_GROWTH_BREAKDOWN]: {
    call: 'papi/data/api/v3/balance-sheet-statement-growth/AAPL?limit=40',
    keys: [
      {
        key: 'growthShortTermDebt',
        labels: 'shortTermDebtGrowth',
        color: '',
      },
      {
        key: 'growthLongTermDebt',
        labels: 'longTermDebtGrowth',
        color: '',
      },
      {
        key: 'growthTotalCurrentLiabilities',
        labels: 'totalCurrentLiabilitiesGrowth',
        color: '',
      },
      {
        key: 'growthTotalNonCurrentLiabilities',
        labels: 'totalNonCurrentLiabilitiesGrowth',
        color: '',
      },
      {
        key: 'growthTotalLiabilities',
        labels: 'totalLiabilitiesGrowth',
        color: '',
      },
    ],
  },
  [NON_CURRENT_LIABILITIES_GROWTH_BREAKDOWN]: {
    call: 'papi/data/api/v3/balance-sheet-statement-growth/AAPL?limit=40',
    keys: [
      {
        key: 'growthLongTermDebt',
        labels: 'longTermDebtGrowth',
        color: '',
      },
      {
        key: 'growthDeferredRevenueNonCurrent',
        labels: 'deferredRevenueNonCurrentGrowth',
        color: '',
      },
      {
        key: 'growthDeferrredTaxLiabilitiesNonCurrent',
        labels: 'deferrredTaxLiabilitiesNonCurrentGrowth',
        color: '',
      },
      {
        key: 'growthOtherNonCurrentLiabilities',
        labels: 'otherNonCurrentLiabilitiesGrowth',
        color: '',
      },
      {
        key: 'growthTotalNonCurrentLiabilities',
        labels: 'totalNonCurrentLiabilitiesGrowth',
        color: '',
      },
    ],
  },
  [DEBT_GROWTH_BREAKDOWN]: {
    call: 'papi/data/api/v3/balance-sheet-statement-growth/AAPL?limit=40',
    keys: [
      {
        key: 'growthShortTermDebt',
        labels: 'shortTermDebtGrowth',
        color: '',
      },
      {
        key: 'growthLongTermDebt',
        labels: 'longTermDebtGrowth',
        color: '',
      },
      {
        key: 'growthTotalDebt',
        labels: 'totalDebtGrowth',
        color: '',
      },
      {
        key: 'growthNetDebt',
        labels: 'netDebtGrowth',
        color: '',
      },
    ],
  },
  [CURRENT_LIABILITIES_GROWTH_BREAKDOWN]: {
    call: 'papi/data/api/v3/balance-sheet-statement-growth/AAPL?limit=40',
    keys: [
      {
        key: 'growthAccountPayables',
        labels: 'accountPayablesGrowth',
        color: '',
      },
      {
        key: 'growthShortTermDebt',
        labels: 'shortTermDebtGrowth',
        color: '',
      },
      {
        key: 'growthTaxPayables',
        labels: 'taxPayablesGrowth',
        color: '',
      },
      {
        key: 'growthOtherCurrentLiabilities',
        labels: 'otherCurrentLiabilitiesGrowth',
        color: '',
      },
      {
        key: 'growthTotalCurrentLiabilities',
        labels: 'totalCurrentLiabilitiesGrowth',
        color: '',
      },
    ],
  },
  [EQUITY_GROWTH_BREAKDOWN]: {
    call: 'papi/data/api/v3/balance-sheet-statement-growth/AAPL?limit=40',
    keys: [
      {
        key: 'growthTotalStockholdersEquity',
        labels: 'totalStockholdersEquityGrowth',
        color: '',
      },
      {
        key: 'growthCommonStock',
        labels: 'commonStockGrowth',
        color: '',
      },
      {
        key: 'growthRetainedEarnings',
        labels: 'retainedEarningsGrowth',
        color: '',
      },
      {
        key: 'growthAccumulatedOtherComprehensiveIncomeLoss',
        labels: 'accumulatedOtherComprehensiveIncomeLossGrowth',
        color: '',
      },
      {
        key: 'growthTotalLiabilitiesAndStockholdersEquity',
        labels: 'liabilitiesAndStockholdersEquityGrowth',
        color: '',
      },
    ],
  },
  [CASHFLOW_STATEMENT_GROWTH_BREAKDOWN]: {
    call: 'papi/data/api/v3/cash-flow-statement-growth/AAPL?limit=40',
    keys: [
      {
        key: 'growthNetCashProvidedByOperatingActivites',
        labels: 'cashFromOperatingActivitesGrowth',
        color: '',
      },
      {
        key: 'growthNetCashUsedForInvestingActivites',
        labels: 'cashFromInvestingActivitesGrowth',
        color: '',
      },
      {
        key: 'growthNetCashUsedProvidedByFinancingActivities',
        labels: 'cashFromFinancingActivitiesGrowth',
        color: '',
      },
      {
        key: 'growthCapitalExpenditure',
        labels: 'capitalExpenditureGrowth',
        color: '',
      },
      {
        key: 'growthFreeCashFlow',
        labels: 'freeCashFlowGrowth',
        color: '',
      },
    ],
  },
  [OPERATING_ACTIVITIES_GROWTH_BREAKDOWN]: {
    call: 'papi/data/api/v3/cash-flow-statement-growth/AAPL?limit=40',
    keys: [
      {
        key: 'growthDepreciationAndAmortization',
        labels: 'depreciationAndAmortizationGrowth',
        color: '',
      },
      {
        key: 'growthStockBasedCompensation',
        labels: 'stockBasedCompensationGrowth',
        color: '',
      },
      {
        key: 'growthAccountsReceivables',
        labels: 'accountsReceivablesGrowth',
        color: '',
      },
      {
        key: 'growthInventory',
        labels: 'inventoryGrowth',
        color: '',
      },
      {
        key: 'growthNetCashProvidedByOperatingActivites',
        labels: 'cashFromOperatingActivitesGrowth',
        color: '',
      },
    ],
  },
  [INVESTING_ACTIVITIES_GROWTH_BREAKDOWN]: {
    call: 'papi/data/api/v3/cash-flow-statement-growth/AAPL?limit=40',
    keys: [
      {
        key: 'growthInvestmentsInPropertyPlantAndEquipment',
        labels: 'investmentsInPPEGrowth',
        color: '',
      },
      {
        key: 'growthAcquisitionsNet',
        labels: 'netAcquisitionsGrowth',
        color: '',
      },
      {
        key: 'growthPurchasesOfInvestments',
        labels: 'purchasesOfInvestmentsGrowth',
        color: '',
      },
      {
        key: 'growthSalesMaturitiesOfInvestments',
        labels: 'salesMaturitiesOfInvestmentsGrowth',
        color: '',
      },
      {
        key: 'growthNetCashUsedForInvestingActivites',
        labels: 'cashFromInvestingActivitesGrowth',
        color: '',
      },
    ],
  },
  [FINANCING_ACTIVITIES_GROWTH_BREAKDOWN]: {
    call: 'papi/data/api/v3/cash-flow-statement-growth/AAPL?limit=40',
    keys: [
      {
        key: 'growthDebtRepayment',
        labels: 'debtRepaymentGrowth',
        color: '',
      },
      {
        key: 'growthCommonStockIssued',
        labels: 'commonStockIssuedGrowth',
        color: '',
      },
      {
        key: 'growthCommonStockRepurchased',
        labels: 'commonStockRepurchasedGrowth',
        color: '',
      },
      {
        key: 'growthDividendsPaid',
        labels: 'dividendsPaidGrowth',
        color: '',
      },
      {
        key: 'growthNetCashUsedProvidedByFinancingActivities',
        labels: 'cashFromFinancingActivitiesGrowth',
        color: '',
      },
    ],
  },
  [CASHFLOW_GROWTH_BREAKDOWN]: {
    call: 'papi/data/api/v3/cash-flow-statement-growth/AAPL?limit=40',
    keys: [
      {
        key: 'growthNetChangeInCash',
        labels: 'netChangeInCashGrowth',
        color: '',
      },
      {
        key: 'growthCashAtEndOfPeriod',
        labels: 'cashAtEndOfPeriodGrowth',
        color: '',
      },
      {
        key: 'growthOperatingCashFlow',
        labels: 'operatingCashFlowGrowth',
        color: '',
      },
      {
        key: 'growthCapitalExpenditure',
        labels: 'capitalExpenditureGrowth',
        color: '',
      },
      {
        key: 'growthFreeCashFlow',
        labels: 'freeCashFlowGrowth',
        color: '',
      },
    ],
  },
  // Ratio's Start here *
  [PRICE_RATIOS]: {
    call: 'papi/data/api/v3/ratios/AAPL?limit=40',
    keys: [
      {
        key: 'priceEarningsRatio',
        labels: 'priceToEarnings',
        color: '',
      },
      {
        key: 'priceToBookRatio',
        labels: 'priceToBook',
        color: '',
      },
      {
        key: 'priceToSalesRatio',
        labels: 'priceToSales',
        color: '',
      },
      {
        key: 'priceEarningsToGrowthRatio',
        labels: 'priceEarningsGrowth',
        color: '',
      },
      {
        key: 'priceCashFlowRatio',
        labels: 'priceToCashFlow',
        color: '',
      },
    ],
  },
  [LIQUIDITY_RATIOS]: {
    call: 'papi/data/api/v3/ratios/AAPL?limit=40',
    keys: [
      {
        key: 'cashRatio',
        labels: 'cashRatio',
        color: '',
      },
      {
        key: 'currentRatio',
        labels: 'currentRatio',
        color: '',
      },
      {
        key: 'quickRatio',
        labels: 'quickRatio',
        color: '',
      },
      {
        key: 'cashConversionCycle',
        labels: 'cashConversionCycle',
        color: '',
      },
    ],
  },
  [RISK_ANALYSIS_RATIO]: {
    call: 'papi/data/api/v3/ratios/AAPL?limit=40',
    keys: [
      {
        key: 'debtRatio',
        labels: 'debtRatio',
        color: '',
      },
      {
        key: 'debtEquityRatio',
        labels: 'debtToEquity',
        color: '',
      },
      {
        key: 'longTermDebtToCapitalization',
        labels: 'longTermDebtToMarketCap',
        color: '',
      },
      {
        key: 'totalDebtToCapitalization',
        labels: 'totalDebtToMarketCap',
        color: '',
      },
      {
        key: 'cashFlowToDebtRatio',
        labels: 'cashFlowToDebt',
        color: '',
      },
    ],
  },
  [OPERATING_PERFORMANCE_RATIOS]: {
    call: 'papi/data/api/v3/ratios/AAPL?limit=40',
    keys: [
      {
        key: 'grossProfitMargin',
        labels: 'grossProfitMargin',
        color: '',
      },
      {
        key: 'netProfitMargin',
        labels: 'netProfitMargin',
        color: '',
      },
      {
        key: 'returnOnEquity',
        labels: 'returnOnEquity',
        color: '',
      },
      {
        key: 'returnOnAssets',
        labels: 'returnOnAssets',
        color: '',
      },
      {
        key: 'returnOnCapitalEmployed',
        labels: 'returnOnCapitalEmployed',
        color: '',
      },
    ],
  },
  [VALUE_RATIO]: {
    call: 'papi/data/api/v3/ratios/AAPL?limit=40',
    keys: [
      {
        key: 'dividendPayoutRatio',
        labels: 'dividendPayoutRatio',
        color: '',
      },
      {
        key: 'priceEarningsRatio',
        labels: 'priceToEarnings',
        color: '',
      },
      {
        key: 'pbRatioTTM',
        labels: 'priceToBook',
        color: '',
      },
      {
        key: 'priceToFreeCashFlowsRatio',
        labels: 'priceToFreeCashFlows',
        color: '',
      },
      {
        key: 'enterpriseValueMultiple',
        labels: 'enterpriseValueMultiple',
        color: '',
      },
    ],
  },
  // Enterprise *
  [ENTERPRISE_VALUE]: {
    call: 'papi/data/api/v3/enterprise-values/AAPL?limit=40',
    keys: [
      {
        key: 'enterpriseValue',
        labels: 'enterpriseValue',
        color: '',
      },
      {
        key: 'marketCapitalization',
        labels: 'marketCapitalization',
        color: '',
      },
      {
        key: 'minusCashAndCashEquivalents',
        labels: 'cashAndCashEquivalents',
        color: '',
      },
      {
        key: 'addTotalDebt',
        labels: 'totalDebt',
        color: '',
      },
      {
        key: 'numberOfShares',
        labels: 'numberOfShares',
        color: '',
      },
    ],
  },
  // Technicals *
  [TWITTER_SENTIMENT]: {
    call: 'papi/data/api/v4/historical/social-sentiment?symbol=AAPL&page=0',
    keys: [
      {
        key: 'twitterPosts',
        labels: 'twitterPosts',
        color: '',
      },
      {
        key: 'twitterComments',
        labels: 'twitterComments',
        color: '',
      },
      {
        key: 'twitterLikes',
        labels: 'twitterLikes',
        color: '',
      },
      {
        key: 'twitterImpressions',
        labels: 'twitterImpressions',
        color: '',
      },
      {
        key: 'twitterSentiment',
        labels: 'twitterSentiment',
        color: '',
      },
    ],
  },
  [STOCKTWITS_SENTIMENT]: {
    call: 'papi/data/api/v4/historical/social-sentiment?symbol=AAPL&page=0',
    keys: [
      {
        key: 'stocktwitsPosts',
        labels: 'stocktwitsPosts',
        color: '',
      },
      {
        key: 'stocktwitsComments',
        labels: 'stocktwitsComments',
        color: '',
      },
      {
        key: 'stocktwitsLikes',
        labels: 'stocktwitsLikes',
        color: '',
      },
      {
        key: 'stocktwitsImpressions',
        labels: 'stocktwitsImpressions',
        color: '',
      },
      {
        key: 'stocktwitsSentiment',
        labels: 'stocktwitsSentiment',
        color: '',
      },
    ],
  },
}

export default widgetConfig
