const valuationColumns = [
  { Header: 'Price to Book Value Ratio', accessor: 'priceToBookRatioTTM' },
  { Header: 'Price to Sales Ratio', accessor: 'priceToSalesRatioTTM' },
  { Header: 'Price to Earnings Ratio', accessor: 'peRatioTTM' },
  { Header: 'Price to Free Cash Flows Ratio', accessor: 'pfcfRatioTTM' },
  { Header: 'Price to Operating Cash Flows Ratio', accessor: 'pocfratioTTM' },
  { Header: 'Price Earnings to Growth Ratio', accessor: 'pegRatioTTM' },
  {
    Header: 'Enterprise Value Multiple',
    accessor: 'enterpriseValueOverEBITDATTM',
  },
  { Header: 'Price Fair Value', accessor: 'priceFairValueTTM' },
  { Header: 'EV to Free Cash Flow', accessor: 'evToFreeCashFlowTTM' },
  { Header: 'EV to Operating Cash Flow', accessor: 'evToOperatingCashFlowTTM' },
  { Header: 'EV to Sales', accessor: 'evToSalesTTM' },
  { Header: 'Earnings Yield', accessor: 'earningsYieldTTM', percentage: true },
  { Header: 'Invested Capital', accessor: 'investedCapitalTTM' },
  { Header: 'Net Current Asset Value', accessor: 'netCurrentAssetValueTTM' },
  { Header: 'Tangible Asset Value', accessor: 'tangibleAssetValueTTM' },
  {
    Header: 'Tangible Book Value Per Share',
    accessor: 'tangibleBookValuePerShareTTM',
  },
  { Header: 'Graham Number', accessor: 'grahamNumberTTM' },
  { Header: 'Graham Net Net', accessor: 'grahamNetNetTTM' },
]

const profitabilityColumns = [
  {
    Header: 'Gross Profit Margin',
    accessor: 'grossProfitMarginTTM',
    percentage: true,
  },
  {
    Header: 'Operating Profit Margin',
    accessor: 'operatingProfitMarginTTM',
    percentage: true,
  },
  {
    Header: 'Pretax Profit Margin',
    accessor: 'pretaxProfitMarginTTM',
    percentage: true,
  },
  {
    Header: 'Net Profit Margin',
    accessor: 'netProfitMarginTTM',
    percentage: true,
  },
  {
    Header: 'Return on Assets',
    accessor: 'returnOnTangibleAssetsTTM',
    percentage: true,
  },
  { Header: 'Return on Equity', accessor: 'roeTTM', percentage: true },
  {
    Header: 'Return on Capital Employed',
    accessor: 'roicTTM',
    percentage: true,
  },
  {
    Header: 'Effective Tax Rate',
    accessor: 'effectiveTaxRateTTM',
    percentage: true,
  },
  { Header: 'Income Quality', accessor: 'incomeQualityTTM' },
  { Header: 'Net Income Per Share', accessor: 'netIncomePerShareTTM' },
  { Header: 'Revenue Per Share', accessor: 'revenuePerShareTTM' },
]

const financialHealthColumns = [
  { Header: 'Current Ratio', accessor: 'currentRatioTTM' },
  { Header: 'Quick Ratio', accessor: 'quickRatioTTM' },
  { Header: 'Cash Ratio', accessor: 'cashRatioTTM' },
  { Header: 'Debt Ratio', accessor: 'debtToAssetsTTM' },
  { Header: 'Debt Equity Ratio', accessor: 'debtToEquityTTM' },
  {
    Header: 'Long Term Debt to Capitalization',
    accessor: 'longTermDebtToCapitalizationTTM',
  },
  {
    Header: 'Total Debt to Capitalization',
    accessor: 'totalDebtToCapitalizationTTM',
  },
  { Header: 'Cash Flow to Debt Ratio', accessor: 'cashFlowToDebtRatioTTM' },
  {
    Header: 'Company Equity Multiplier',
    accessor: 'companyEquityMultiplierTTM',
  },
  { Header: 'Book Value Per Share', accessor: 'bookValuePerShareTTM' },
  { Header: 'Debt to Market Cap', accessor: 'debtToMarketCapTTM' },
  { Header: 'Interest Coverage', accessor: 'interestCoverageTTM' },
  { Header: 'Net Debt To EBITDA', accessor: 'netDebtToEBITDATTM' },
  {
    Header: 'Shareholders Equity Per Share',
    accessor: 'shareholdersEquityPerShareTTM',
  },
  { Header: 'Working Capital', accessor: 'workingCapitalTTM' },
  { Header: 'Interest Debt Per Share', accessor: 'interestDebtPerShareTTM' },
]

const efficiencyColumns = [
  { Header: 'Days of Sales Outstanding', accessor: 'daysSalesOutstandingTTM' },
  {
    Header: 'Days of Inventory Outstanding',
    accessor: 'daysOfInventoryOnHandTTM',
  },
  { Header: 'Operating Cycle', accessor: 'operatingCycleTTM' },
  {
    Header: 'Days of Payables Outstanding',
    accessor: 'daysPayablesOutstandingTTM',
  },
  { Header: 'Cash Conversion Cycle', accessor: 'cashConversionCycleTTM' },
  { Header: 'Receivables Turnover', accessor: 'receivablesTurnoverTTM' },
  { Header: 'Payables Turnover', accessor: 'payablesTurnoverTTM' },
  { Header: 'Inventory Turnover', accessor: 'inventoryTurnoverTTM' },
  { Header: 'Fixed Asset Turnover', accessor: 'fixedAssetTurnoverTTM' },
  { Header: 'Asset Turnover', accessor: 'assetTurnoverTTM' },
  { Header: 'Capex Per Share', accessor: 'capexPerShareTTM' },
  { Header: 'Capex To Depreciation', accessor: 'capexToDepreciationTTM' },
  {
    Header: 'Capex To Operating Cash Flow',
    accessor: 'capexToOperatingCashFlowTTM',
  },
  { Header: 'Capex To Revenue', accessor: 'capexToRevenueTTM' },
  { Header: 'Average Inventory', accessor: 'averageInventoryTTM' },
  { Header: 'Average Payables', accessor: 'averagePayablesTTM' },
  { Header: 'Average Receivables', accessor: 'averageReceivablesTTM' },
]

const managementEffectivenessColumns = [
  { Header: 'Net Income Per EBT', accessor: 'netIncomePerEBTTTM' },
  { Header: 'EBT Per EBIT', accessor: 'ebtPerEbitTTM' },
  { Header: 'EBIT Per Revenue', accessor: 'ebitPerRevenueTTM' },
  {
    Header: 'Operating Cash Flow Per Share',
    accessor: 'operatingCashFlowPerShareTTM',
  },
  { Header: 'Free Cash Flow Per Share', accessor: 'freeCashFlowPerShareTTM' },
  { Header: 'Cash Per Share', accessor: 'cashPerShareTTM' },
  {
    Header: 'Operating Cash Flow Sales Ratio',
    accessor: 'operatingCashFlowSalesRatioTTM',
  },
  {
    Header: 'Free Cash Flow Operating Cash Flow Ratio',
    accessor: 'freeCashFlowOperatingCashFlowRatioTTM',
  },
  {
    Header: 'Cash Flow Coverage Ratios',
    accessor: 'cashFlowCoverageRatiosTTM',
  },
  {
    Header: 'Short Term Coverage Ratios',
    accessor: 'shortTermCoverageRatiosTTM',
  },
  {
    Header: 'Capex Coverage Ratio',
    accessor: 'capitalExpenditureCoverageRatioTTM',
  },
  {
    Header: 'Research And Development To Revenue',
    accessor: 'researchAndDevelopementToRevenueTTM',
  },
  {
    Header: 'Stock Based Compensation To Revenue',
    accessor: 'stockBasedCompensationToRevenueTTM',
  },
]

const dividendColumns = [
  {
    Header: 'Dividend Yield Percentage',
    accessor: 'dividendYieldPercentageTTM',
  },
  { Header: 'Dividend Yield', accessor: 'dividendYieldTTM' },
  {
    Header: 'Dividend Paid And Capex Coverage Ratio',
    accessor: 'dividendPaidAndCapexCoverageRatioTTM',
  },
  { Header: 'Dividend Per Share', accessor: 'dividendPerShareTTM' },
  { Header: 'Payout Ratio', accessor: 'payoutRatioTTM' },
]

export const columnKeysTTM = {
  valuationColumns,
  profitabilityColumns,
  financialHealthColumns,
  efficiencyColumns,
  managementEffectivenessColumns,
  dividendColumns,
}
