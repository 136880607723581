import styles from './styles.module.scss'
import CountUp from 'react-countup'
import InfiniteScrollCarousel from './InfiniteScrollBox'
import { useEffect, useState, useRef } from 'react'
import map from './worldMap.svg'
import { UilGlobe, UilCoins } from '@iconscout/react-unicons'
import { Testimonials } from '../Testimonials/Testimonials'
import { userTestimonials } from '../Testimonials/config'

const useIntersectionObserver = options => {
  const [isIntersecting, setIsIntersecting] = useState(false)
  const ref = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsIntersecting(entry.isIntersecting)
    }, options)

    if (ref.current) {
      observer.observe(ref.current)
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current)
      }
    }
  }, [options])

  return [ref, isIntersecting]
}

export default useIntersectionObserver

export const featureSectionConstants = [
  {
    renderComponent: () => {
      const stats = [
        {
          value: 96,
          label: 'Average Time Saved Using Outseek Copilot',
          type: '%',
          time: 2,
        },
        {
          value: 1.89,
          label: 'Average Copilot Response Time',
          decimals: 2,
          type: 's',
          time: 3,
        },
      ]
      return (
        <div
          className={`${styles.featureWrapper} ${styles.box2}`}
          style={{
            gridColumn: 'auto / span 2',
          }}
        >
          <div className={styles.headerWrapper}>
            <div className={styles.featureTitle}>
              Save time and make better decisions
            </div>
            <div className={styles.featureSubtitle}>
              Our platform leverages advanced algorithms and real-time data to
              streamline your decision-making process. By reducing the time
              spent on research and analysis, you can focus on what truly
              matters – making informed investment choices.
            </div>
          </div>
          <div className={styles.statsWrapper}>
            {stats.map((stat, index) => (
              <div
                className={styles.statBox}
                style={{
                  borderBottom:
                    index === 0 && '1px solid rgba(255, 255, 255, 0.12)',
                }}
              >
                <StatItem key={index} stat={stat} />
              </div>
            ))}
          </div>
        </div>
      )
    },
  },
  {
    renderComponent: () => {
      return (
        <div
          className={`${styles.featureWrapper} ${styles.box1}`}
          style={{
            gridColumn: 'auto / span 1',
          }}
        >
          <InfiniteScrollCarousel items={coveredData} />
        </div>
      )
    },
  },
  {
    renderComponent: () => {
      return (
        <div
          className={`${styles.featureWrapper} ${styles.box3}`}
          style={{
            gridColumn: 'auto / span 1',
          }}
        >
          <div className={styles.headerWrapper}>
            <div className={styles.featureTitle}>
              Trusted by Thousands of Investors Globally
            </div>
          </div>
          <StatItem
            stat={{
              value: 14388,
              label: 'Total Messages Sent',
              type: '+',
              duration: 4,
            }}
          />
          <InfiniteScrollCarousel
            items={exampleQuestions}
            isHorizontal={true}
          />
        </div>
      )
    },
  },
  {
    renderComponent: () => {
      return (
        <div
          className={`${styles.featureWrapper} ${styles.box4}`}
          style={{
            gridColumn: 'auto / span 2',
          }}
        >
          <div className={styles.headerWrapper}>
            <div className={styles.featureTitle}>Institutional-Grade Data</div>
            <div className={styles.globalFeatures}>
              <div className={styles.featureSubtitle}>
                <UilGlobe size="20px" color="var(--primary-color)" />
                Global data coverage
              </div>
              <div className={styles.featureSubtitle}>
                <UilCoins size="20px" color="var(--primary-color)" />
                80,000+ securities
              </div>
            </div>
          </div>
          <img src={map} alt="world map" className={styles.worldMap} />
        </div>
      )
    },
  },
  // {
  //   renderComponent: () => {
  //     return (
  //       <div
  //         className={`${styles.featureWrapper} ${styles.box5}`}
  //         style={{
  //           gridColumn: 'auto / span 3',
  //         }}
  //       >
  //         {/* <div className={styles.headerWrapper}>
  //           <div className={styles.featureTitle}>Testimonials</div>
  //         </div> */}
  //         <Testimonials config={userTestimonials} />
  //       </div>
  //     )
  //   },
  // },
]

const StatItem = ({ stat }) => {
  const [ref, isIntersecting] = useIntersectionObserver({
    threshold: 0.1,
  })

  return (
    <div ref={ref} className={styles.statsItem}>
      <div className={styles.statValue}>
        {isIntersecting && (
          <CountUp
            end={stat.value}
            decimals={stat?.decimals}
            redraw={true}
            duration={stat?.time ? stat.time : 2}
          />
        )}
        {stat?.type}
      </div>
      <div className={styles.statLabel}>{stat.label}</div>
    </div>
  )
}

const coveredData = [
  'Real-time Stock Quotes',
  'Portfolio Management',
  'Financial News Updates',
  'Investment Insights',
  'Market Analysis',
  'Economic Indicators',
  'Financial Education',
]

const exampleQuestions = [
  'What is the current price of AAPL?',
  'Can you provide the latest quote for Tesla?',
  'What are the key financial metrics for Alphabet?',
  'Show me the historical prices for Amazon over the last year.',
  'What is the market cap of MSFT?',
  'What is the P/E ratio of Netflix?',
  'What is the debt-to-equity ratio for Boeing?',
  'Show me the return on equity (ROE) for NKE.',
  'What is the earnings per share (EPS) of DIS?',
  'Give me a profile overview of Intel.',
  'What is the latest news on ORCL?',
  'Can you provide a summary of recent insider trading for NVDA?',
  'Show me the recent SEC filings for Adobe.',
  'What are the institutional holdings for IBM?',
  'How did CRM perform today?',
  'What is the year-to-date performance of Shopify?',
  'How has Uber stock performed in the last month?',
]
