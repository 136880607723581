const rootImageUrl = 'https://homepage-imgs.s3.amazonaws.com/homepageimgs/'

const instCompany = rootImageUrl + 'instCompany.webp'
const companyProfile = 'companyProfile.webp'
const companyNewsWebP = rootImageUrl + 'companyNews.webp'
const marketOverviewWebP = rootImageUrl + 'marketOverview.webp'
const companyOverviewWebP = rootImageUrl + 'companyOverview.webp'
const sankeyChartWebP = rootImageUrl + 'sankeyChart.webp'
const screenerWebP = rootImageUrl + 'screener.webp'
const earnings = rootImageUrl + 'earnings.webp'
const insiderTradingWebP = rootImageUrl + 'insiderTrading.webp'

export const media = {
  ////////// WebP's
  instCompanyWebP: instCompany,
  companyProfileWebP: companyProfile,
  companyNewsWebP: companyNewsWebP,
  marketOverviewWebP: marketOverviewWebP,
  companyOverviewWebP: companyOverviewWebP,
  sankeyChartWebP: sankeyChartWebP,
  screenerWebP: screenerWebP,
  insiderTradingWebP: insiderTradingWebP,
  earningsWebP: earnings,
}
