import ComparisonCharts from 'dashboard/ComparisonCharts'
import styles from './CompChart.module.scss'
import { useURLSyncedGlobalTicker } from 'pages/views/hooks'
import { QuoteBar } from 'core/QuoteBar/QuoteBar'
import { Helmet } from 'react-helmet-async'

export const CompChart = () => {
  const { handleSetTicker, ticker } = useURLSyncedGlobalTicker()

  const initialState = {
    metrics: [],
    metricsData: [],
    chartTypes: {},
    frequency: 'quarter',
    lineType: {
      label: 'Monotone',
      value: 'monotone',
    },
    showLabels: false,
    showPercentageChange: false,
    showSingleAxis: false,
  }

  const comparisonChartsProps = {
    root: 'ComparisonCharts',
    ticker: ticker,
    width: '100%',
    height: 'calc(100vh - 126px)',
    initialState,
    view: true,
  }

  return (
    <div className={styles.compChartView}>
      <Helmet>
        <title>{'Outseek | Comparison Chart'}</title>
        <meta
          name="description"
          content={
            'This page gives the user the ability to compare financial metrics for companies. Contains over 300+ metrics.'
          }
        />
      </Helmet>
      <QuoteBar updateTicker={handleSetTicker} ticker={ticker} />
      <div className={styles.compChartWrapper}>
        <ComparisonCharts {...comparisonChartsProps} />
      </div>
    </div>
  )
}
