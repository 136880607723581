const INVESTOR_HOLDINGS_KEY = 'investorsHolding';
const OWNERSHIP_PERCENT_KEY = 'ownershipPercent';
const TOTAL_INVESTED_KEY = 'totalInvested';
const NUMBER_OF_13F_SHARES_KEY = 'numberOf13Fshares';
const NEW_POSITIONS_KEY = 'newPositions';
const CLOSED_POSITIONS_KEY = 'closedPositions';
const INCREASED_POSITIONS_KEY = 'increasedPositions';
const REDUCED_POSITIONS_KEY = 'reducedPositions';
const TOTAL_CALLS_KEY = 'totalCalls';
const TOTAL_PUTS_KEY = 'totalPuts';
const PUT_CALL_RATIOS_KEY = 'putCallRatio';

const INVESTOR_HOLDINGS_LABEL = 'Investors Holding';
const OWNERSHIP_PERCENT_LABEL = 'Ownership Precent';
const TOTAL_INVESTED_LABEL = 'Total Invested';
const NUMBER_OF_13F_SHARES_LABEL = 'Number Of 13F Shares';
const NEW_POSITIONS_LABEL = 'New Positions';
const CLOSED_POSITIONS_LABEL = 'Closed Positions';
const INCREASED_POSITIONS_LABEL = 'Increased Positions';
const REDUCED_POSITIONS_LABEL = 'Reduced Positions';
const TOTAL_CALLS_LABEL = 'Total Calls';
const TOTAL_PUTS_LABEL = 'Total Calls';
const PUT_CALL_RATIOS_LABEL = 'Put Call Ratio';

export const CHART_CONSTANTS = {
  INVESTOR_HOLDINGS_KEY,
  OWNERSHIP_PERCENT_KEY,
  TOTAL_INVESTED_KEY,
  NUMBER_OF_13F_SHARES_KEY,
  NEW_POSITIONS_KEY,
  CLOSED_POSITIONS_KEY,
  INCREASED_POSITIONS_KEY,
  REDUCED_POSITIONS_KEY,
  TOTAL_CALLS_KEY,
  TOTAL_PUTS_KEY,
  PUT_CALL_RATIOS_KEY,
  INVESTOR_HOLDINGS_LABEL,
  OWNERSHIP_PERCENT_LABEL,
  TOTAL_INVESTED_LABEL,
  NUMBER_OF_13F_SHARES_LABEL,
  NEW_POSITIONS_LABEL,
  CLOSED_POSITIONS_LABEL,
  INCREASED_POSITIONS_LABEL,
  REDUCED_POSITIONS_LABEL,
  TOTAL_CALLS_LABEL,
  TOTAL_PUTS_LABEL,
  PUT_CALL_RATIOS_LABEL,
};

export const CHART_COLOR_PALLETTE = {
  [INVESTOR_HOLDINGS_KEY]: '#2ECC71',
  [OWNERSHIP_PERCENT_KEY]: '#E7903C',
  [TOTAL_INVESTED_KEY]: '#CCCA2E',
  [NUMBER_OF_13F_SHARES_KEY]: '#903CE7',
  [NEW_POSITIONS_KEY]: 'var(--red)',
  [CLOSED_POSITIONS_KEY]: '#3F51BE',
  [INCREASED_POSITIONS_KEY]: 'var(--green)',
  [REDUCED_POSITIONS_KEY]: '#E73CB5',
  [TOTAL_CALLS_KEY]: '#CC532E',
  [TOTAL_PUTS_KEY]: '#3CDDE7',
  [PUT_CALL_RATIOS_KEY]: '#3CDDE7',
};
