import React from 'react'
import times from '../../../assets/times.svg'
import { startCase } from 'lodash'
import MetricInfo from 'core/MetricInfoHover/MetricInfoTooltip'

export const ComparsionChartPill = ({
  label,
  onDelete,
  color,
  metricChartTypes,
  handleChartTypeChange,
  isLoading,
}) => {
  return (
    <div
      className="comparison-chart-metric-tag"
      style={{ backgroundColor: `${color}` }}
    >
      <img
        style={{
          cursor: 'pointer',
          width: '22px',
          height: '22px',
          userSelect: 'none',
        }}
        onClick={() => handleChartTypeChange(label)}
        alt="chart-type"
        src={metricChartTypes?.[label]?.icon}
      />
      <div className="metric-name" style={{ color: 'var(--white)' }}>
        <MetricInfo metricKey={label} displayMetric={startCase(label)} />
      </div>
      {onDelete && (
        <img
          className="comparison-chart-metric-tag-close-icon"
          src={times}
          onClick={() => onDelete()}
          alt="close-tag"
        ></img>
      )}
    </div>
  )
}

export default ComparsionChartPill
