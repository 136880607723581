import React, { useState, useEffect, useRef, useCallback } from 'react'
import * as d3 from 'd3'
import './radar.scss'
import { Tooltip } from './RadarTooltip'
import { UilInfoCircle } from '@iconscout/react-unicons'
import Modal from '@mui/material/Modal'
import { UilTimes } from '@iconscout/react-unicons'

const FinRadarChartD3 = ({
  data,
  dataKey,
  category,
  domain,
  color,
  noDots = false,
  renderTooltip,
  renderModal,
}) => {
  const ref = useRef(null)

  const [modalIsOpen, setModalIsOpen] = useState(false)

  const [tooltip, setTooltip] = useState({
    visible: false,
    content: null,
    position: { x: 0, y: 0 },
  })

  const [elements, setElements] = useState({
    circles: [],
    lines: [],
    radarArea: '',
    dots: [],
    labels: [],
  })

  const handleMouseOver = useCallback(
    (event, data) => {
      let content
      if (renderTooltip) {
        content = renderTooltip(data)
      } else {
        content = (
          <div>
            <span>Category: {data?.name}</span>
            <br />
            <span>Value: {data?.score}</span>
          </div>
        )
      }
      setTooltip({
        visible: true,
        content: content,
        position: { x: event.clientX, y: event.clientY },
      })
    },
    [renderTooltip]
  )

  const handleMouseMove = useCallback(event => {
    setTooltip(prevState => ({
      ...prevState,
      position: { x: event.clientX, y: event.clientY },
    }))
  }, [])

  const handleMouseOut = useCallback(() => {
    setTooltip({ visible: false, content: null, position: { x: 0, y: 0 } })
  }, [])

  const [divHeight, setDivHeight] = useState(0)

  const gridCircles = 5

  const padding = 25
  const margin = {
    top: padding,
    right: padding,
    bottom: padding,
    left: padding,
  }

  const chartDimension = divHeight - margin.top - margin.bottom
  const radius = chartDimension / 2

  const calculateElements = useCallback(() => {
    const angleScale = d3
      .scaleBand()
      .range([0, 2 * Math.PI])
      .domain(data?.map(d => d[category]))

    const radiusScale = d3
      .scaleLinear()
      .range([0, radius])
      .domain(domain ? domain : [0, d3?.max(data, d => d[dataKey])])

    // Calculate circles for grid
    const circles = Array.from({ length: gridCircles }, (_, i) => ({
      r: radius * ((i + 1) / gridCircles),
      class: 'grid-circle',
    }))

    // Calculate lines for axis
    const lines = data?.map(d => ({
      x1: 0,
      y1: 0,
      x2:
        radiusScale(d[dataKey]) *
        Math.cos(angleScale(d[category]) - Math.PI / 2),
      y2:
        radiusScale(d[dataKey]) *
        Math.sin(angleScale(d[category]) - Math.PI / 2),
      class: 'axis-line',
    }))

    // Calculate radar area
    const radarArea = d3
      .lineRadial()
      .curve(d3.curveCatmullRomClosed)
      .radius(d => radiusScale(d[dataKey]))
      .angle(d => angleScale(d[category]))(data)

    // Calculate dots
    const dots = noDots
      ? []
      : data?.map(d => ({
          cx:
            radiusScale(d[dataKey]) *
            Math.cos(angleScale(d[category]) - Math.PI / 2),
          cy:
            radiusScale(d[dataKey]) *
            Math.sin(angleScale(d[category]) - Math.PI / 2),
          r: 3,
          class: 'radar-dot',
        }))

    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    context.font = '13px sans-serif'

    const labelPaths = data.map(d => {
      const angle = angleScale(d[category])
      const adjustedAngle = (angle + 2 * Math.PI) % (2 * Math.PI)
      const flipText = adjustedAngle > Math.PI / 2 && angle < (3 * Math.PI) / 2

      const text = d[category]
      const textWidth = context.measureText(text).width

      let startAngle =
        adjustedAngle - (Math.PI * textWidth) / (2 * Math.PI * radius)
      let endAngle = startAngle + (Math.PI * textWidth) / (Math.PI * radius)

      if (flipText) {
        ;[startAngle, endAngle] = [endAngle, startAngle]
      }

      const pathId = `path-${text}-${Math.random()}`

      const radiusForText = flipText ? radius + 17 : radius + 10

      const pathData = d3.arc()({
        outerRadius: radiusForText,
        innerRadius: radiusForText,
        startAngle,
        endAngle,
      })

      return {
        id: pathId,
        d: pathData,
        text: text,
        flipText: flipText,
      }
    })

    // Calculate label positions
    const labels = data?.map(d => {
      const angle = angleScale(d[category])
      const x = (radius + 10) * Math.cos(angle - Math.PI / 2)
      const y = (radius + 10) * Math.sin(angle - Math.PI / 2)
      return { x, y, text: d[category], class: 'radar-label' }
    })

    // Calculate slices for each category
    const slices = data?.map(d => {
      const startAngle = angleScale(d[category]) - angleScale.bandwidth() / 2
      const endAngle = angleScale(d[category]) + angleScale.bandwidth() / 2

      return {
        startAngle: startAngle,
        endAngle: endAngle,
        fill: 'transparent',
      }
    })

    setElements({ circles, lines, radarArea, dots, labels, labelPaths, slices })
  }, [data, radius, domain, noDots, category, dataKey])

  useEffect(() => {
    const currentRef = ref.current
    const resizeObserver = new ResizeObserver(() => {
      const parentDiv = currentRef.getBoundingClientRect()
      if (parentDiv.width > 0 && parentDiv.height > 0) {
        const newDimension = Math.min(parentDiv.width, parentDiv.height)
        currentRef.style.width = `${newDimension}px`
        currentRef.style.height = `${newDimension}px`
        setDivHeight(newDimension)
        calculateElements()
      }
    })

    resizeObserver.observe(currentRef)
    return () => resizeObserver.unobserve(currentRef)
  }, [data, dataKey, category, domain, color, noDots, calculateElements])

  useEffect(() => {
    if (ref.current) {
      const parentDiv = ref.current.getBoundingClientRect()
      calculateElements(parentDiv.width, parentDiv.height)
    }
  }, [])

  const handleSliceHover = (index, isHovering, e) => {
    setElements(prevElements => {
      const newSlices = prevElements.slices.map((slice, i) => {
        if (i === index) {
          return {
            ...slice,
            fill: isHovering ? 'rgba(255, 255, 255, 0.2)' : 'transparent',
          }
        }
        return slice
      })
      return { ...prevElements, slices: newSlices }
    })
  }

  return (
    <div
      ref={ref}
      style={{ width: '100%', height: '100%', position: 'relative' }}
    >
      <svg
        viewBox={`0 0 ${ref.current?.offsetWidth} ${ref.current?.offsetHeight}`}
      >
        <g
          transform={`translate(${ref.current?.offsetWidth / 2}, ${
            ref.current?.offsetHeight / 2
          })`}
        >
          {elements.circles?.map((circle, index) => {
            const levelFactor = (index / gridCircles) * radius
            const nextLevelFactor = ((index + 1) / gridCircles) * radius
            const strokeWidth = nextLevelFactor - levelFactor
            const strokePos = levelFactor + strokeWidth / 2

            return (
              <circle
                key={index}
                cx={0}
                cy={0}
                r={index % 2 === 0 ? strokePos : levelFactor}
                stroke="var(--background-secondary)"
                strokeWidth={index % 2 === 0 ? strokeWidth : 'none'}
                fill="none"
              />
            )
          })}
          {elements.lines?.map((line, index) => (
            <line
              key={index}
              {...line}
              style={{ stroke: color, strokeOpacity: 0.6, strokeWidth: '2px' }}
            />
          ))}
          <path
            d={elements.radarArea}
            style={{
              fill: color,
              fillOpacity: 0.7,
              stroke: color,
              strokeWidth: '2px',
            }}
            className="radar-area"
          />
          {elements.dots?.map((dot, index) => (
            <circle
              key={index}
              {...dot}
              style={{
                fill: 'var(--off-white)',
                stroke: color,
                strokeWidth: '2px',
              }}
            />
          ))}
          {elements.labelPaths?.map((labelPath, index) => (
            <path
              key={index}
              id={labelPath.id}
              d={labelPath.d}
              style={{ fill: 'none', stroke: 'none' }}
            />
          ))}
          {elements.labelPaths?.map((labelPath, index) => (
            <text key={index} style={{ fill: '#e7e7e7', fontSize: '13px' }}>
              <textPath
                xlinkHref={`#${labelPath.id}`}
                startOffset={null}
                textAnchor={'start'}
              >
                {labelPath.text}
              </textPath>
            </text>
          ))}
          {elements.slices?.map((slice, index) => {
            const arcGenerator = d3
              .arc()
              .innerRadius(0)
              .outerRadius(radius)
              .startAngle(slice.startAngle)
              .endAngle(slice.endAngle)

            return (
              <path
                key={index}
                d={arcGenerator()}
                fill={slice.fill}
                className="slice-path"
                onMouseOver={e => {
                  handleMouseOver(e, data[index])
                  handleSliceHover(index, true)
                }}
                onMouseMove={e => handleMouseMove(e)}
                onMouseLeave={e => {
                  handleSliceHover(index, false)
                  handleMouseOut()
                }}
              />
            )
          })}
        </g>
      </svg>
      {tooltip.visible && (
        <Tooltip
          position={{ x: tooltip.position.x + 10, y: tooltip.position.y + 10 }}
        >
          {tooltip.content}
        </Tooltip>
      )}
      {renderModal && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            cursor: 'pointer',
          }}
          onClick={() => setModalIsOpen(true)}
        >
          <UilInfoCircle size="20" color="#e7e7e7" />
        </div>
      )}
      {renderModal && (
        <Modal open={modalIsOpen} onClose={() => setModalIsOpen(false)} disableAutoFocus={true}>
          <div className="radarChartBoxedModal">
            <div className="radarChartTitle">
              <span>Radar Chart Info</span>
              <div className="radarChartCloseModal">
                <UilTimes
                  size={22}
                  color="#e7e7e7"
                  onClick={() => setModalIsOpen(false)}
                />
              </div>
            </div>
            {renderModal}
          </div>
        </Modal>
      )}
    </div>
  )
}

export default FinRadarChartD3
