import dayjs from 'dayjs'
import TooltipTicker from 'core/TickerOverlay/TickerOverlay'

export const dividendColumns = [
  {
    Header: '',
    accessor: 'date',
    width: 'fit-content',
    disableGroupBy: true,
    Cell: Cell => {
      return ''
    },
  },
  {
    Header: 'Symbol',
    accessor: 'symbol',
    width: 'fit-content',
    disableGroupBy: true,
    Search: true,
    Cell: Cell => {
      return <TooltipTicker ticker={Cell.value} />
    },
  },
  {
    Header: 'Dividend',
    accessor: 'dividend',
    disableGroupBy: true,
    width: 'fit-content',
    Cell: Cell => {
      const value = Cell.value
      if (value === null || value === undefined || value === '') return '-'

      return value
    },
  },
  {
    Header: 'Adj Dividend',
    accessor: 'adjDividend',
    disableGroupBy: true,
    width: 'fit-content',
    Cell: Cell => {
      const value = Cell.value
      if (value === null || value === undefined || value === '') return '-'

      return value
    },
  },
  {
    Header: 'Record Date',
    accessor: 'recordDate',
    disableGroupBy: true,
    width: 'fit-content',
    Cell: Cell => {
      const value = Cell.value
      if (value === null || value === undefined || value === '') return '-'

      return dayjs(value).format('MMMM D, YYYY')
    },
  },
  {
    Header: 'Payment Date',
    accessor: 'paymentDate',
    disableGroupBy: true,
    width: 'fit-content',
    Cell: Cell => {
      const value = Cell.value
      if (value === null || value === undefined || value === '') return '-'

      return dayjs(value).format('MMMM D, YYYY')
    },
  },
  {
    Header: 'Declaration Date',
    accessor: 'declarationDate',
    disableGroupBy: true,
    width: 'fit-content',
    Cell: Cell => {
      const value = Cell.value
      if (value === null || value === undefined || value === '') return '-'

      return dayjs(value).format('MMMM D, YYYY')
    },
  },
]
