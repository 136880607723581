import times from '../../assets/times.svg'
import { startCase } from 'lodash'

export const ChartPill = ({ label, onDelete, color, rawLabel }) => {
  return (
    <div
      className="comparison-chart-metric-tag"
      style={{ backgroundColor: `${color}` }}
    >
      <div className="metric-name" style={{ color: 'var(--white)' }}>
        {rawLabel ? label : startCase(label)}
      </div>
      {onDelete && (
        <img
          className="comparison-chart-metric-tag-close-icon"
          src={times}
          onClick={() => onDelete()}
          alt="close-tag"
        ></img>
      )}
    </div>
  )
}

export default ChartPill
