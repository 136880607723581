import styles from './calComp.module.scss'
import TooltipTicker from 'core/TickerOverlay/TickerOverlay'
import numeral from 'numeral'
import dayjs from 'dayjs'

export const CalComp = ({ event }) => {
  const {
    symbol,
    dividend,
    adjDividend,
    recordDate,
    paymentDate,
    declarationDate,
  } = event

  const filterValue = value => {
    if (value === null || value === 0) {
      return '-'
    }
    return numeral(value).format('0.000')
  }

  const filterDate = value => {
    if (value === null || value === 0) {
      return '-'
    }
    return dayjs(value).format('MMM, DD')
  }

  const infoMap = [
    { info: 'Dividend', value: filterValue(dividend) },
    { info: 'Adj. Dividend', value: filterValue(adjDividend) },
    { info: 'Record Date', value: filterDate(recordDate) },
    { info: 'Declaration Date', value: filterDate(declarationDate) },
    { info: 'Payment Date', value: filterDate(paymentDate) },
  ]

  return (
    <div className={styles.splitEventWrapper}>
      <div className={styles.topBar}>
        <TooltipTicker ticker={symbol} />
      </div>
      {infoMap.map((item, index) => (
        <div className={styles.info}>
          <div className={styles.label}>{item.info}</div>
          <div className={styles.value}>{item.value}</div>
        </div>
      ))}
    </div>
  )
}
