import React from 'react'

export const FormattedSpanPrecentage = ({ value, sign, changesPercentage }) => {
  if (value > 0) {
    return (
      <span style={{ color: value < 0 ? 'var(--var(--red))' : 'var(--green)' }}>
        {`+${value} (${changesPercentage}${sign})`}
      </span>
    )
  } else {
    return (
      <span style={{ color: value < 0 ? 'var(--red)' : 'var(--green)' }}>
        {`${value} (${changesPercentage}${sign})`}
      </span>
    )
  }
}

export const FormattedSpan = ({ value, sign, noSign }) => {
  if (value > 0) {
    if (noSign) {
      return (
        <span
          style={{ color: value < 0 ? 'var(--red)' : 'var(--green)' }}
        >{`+${value}`}</span>
      )
    } else {
      return (
        <span
          style={{ color: value < 0 ? 'var(--red)' : 'var(--green)' }}
        >{`+${value}${sign}`}</span>
      )
    }
  } else {
    if (noSign) {
      return (
        <span style={{ color: value < 0 ? 'var(--red)' : 'var(--green)' }}>
          {value}
        </span>
      )
    } else {
      return (
        <span style={{ color: value < 0 ? 'var(--red)' : 'var(--green)' }}>
          {value}
          {sign}
        </span>
      )
    }
  }
}
