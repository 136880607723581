import React from 'react'
import CustomAuth from '../AuthComponents/CustomAuth'
import Modal from '@mui/material/Modal'
import styles from '../ProtectedButton/ProtectedButton.module.scss'
import times from '../../assets/times.svg'

export const AuthModal = ({ showModal, setShowModal, restrictedLogin }) => {
  return (
    <Modal
      open={showModal}
      onClose={() => setShowModal(false)}
      autoFocus={false}
      disableEnforceFocus={true}
      className={styles.modalFocused}
    >
      <div className={styles.authWrapper}>
        <div className={styles.authModalWrapper}>
          {!restrictedLogin && (
            <div className={styles.authModalHeader}>
              <div className={styles.authModalHeaderText}></div>
              <img
                onClick={() => setShowModal(false)}
                className={styles.closeButton}
                alt="close modal"
                src={times}
              />
            </div>
          )}
          <CustomAuth modal={true} />
        </div>
      </div>
    </Modal>
  )
}
