import styles from '../Watchlist.module.scss'
import CoreButton from 'core/Button/CoreButton'
import CreateWatchlistModal from './CreateWatchlistModal/CreateWatchlistModal'
import { useContext } from 'react'
import { WatchlistContext } from '../WatchlistContext'
import usePaidFeature from 'hooks/usePaidFeature'
import { useNavigate } from 'react-router'
import { UilLock } from '@iconscout/react-unicons'

export const CreateWatchListButton = ({ openWatchlistModal }) => {
  const navigate = useNavigate()
  const { canCreateWatchlist } = usePaidFeature()
  const handleOpenWatchlistModal = () => {
    openWatchlistModal(true)
  }

  const handleUpgrade = () => {
    navigate('/terminal/upgrade')
  }

  if (!canCreateWatchlist) {
    return (
      <CoreButton
        width={'fit-content'}
        padding={'4px 8px'}
        color={'var(--primary-color)'}
        onClick={handleUpgrade}
      >
        <UilLock color={'var(--white)'} size={20} />
        Upgrade to Create Watchlist
      </CoreButton>
    )
  } else
    return (
      <CoreButton
        width={'fit-content'}
        padding={'4px 8px'}
        color={'var(--primary-color)'}
        onClick={handleOpenWatchlistModal}
      >
        Create New Watchlist
      </CoreButton>
    )
}

export const WatchlistToolbar = ({
  createWatchlist,
  watchlists,
  handleSelectWatchlist,
}) => {
  const { state, updateLittleState } = useContext(WatchlistContext)

  const { activeTab } = state

  return (
    <div className={styles.watchlistToolBar}>
      <div className={styles.watchlistDisplayTabs}>
        {watchlists.map((watchlist, index) => (
          <>
            <div
              key={index}
              className={styles.watchlistDisplayTab}
              onClick={() => {
                handleSelectWatchlist({ value: watchlist.watchlistId })
                updateLittleState({ activeTab: index })
              }}
              style={{
                backgroundColor:
                  activeTab === index ? 'var(--grey-accent)' : null,
              }}
            >
              {watchlist.name}
            </div>
            {index !== watchlists.length - 1 && (
              <>
                {activeTab !== index && activeTab !== index + 1 && (
                  <div className={styles.tabDivider} />
                )}
                {activeTab === index + 1 && (
                  <div className={styles.invisibleTabDivider} />
                )}
              </>
            )}
          </>
        ))}
      </div>
      {/* <CoreButton
        color={'var(--primary-color)'}
        onClick={handleOpenWatchlistModal}
      >
        Create New Watchlist
      </CoreButton> */}
      <CreateWatchListButton />
      <CreateWatchlistModal
        createWatchlist={createWatchlist}
        watchlists={watchlists}
      />
    </div>
  )
}
