import React, { useState, useEffect } from 'react'
import styles from './styles.module.scss'
import dayjs from 'dayjs'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { UtilsButtons } from './UtilsButtons'
import useFeedback from 'hooks/copilot/useFeedback'
import Spinner from 'core/ScrollableContainer/Spinner/spinner'
import { markDownComponents } from './Components'
import ComponentConfig from './ComponentConfig'

const Message = ({
  messages,
  text,
  from,
  timestamp,
  isLoading,
  isLastMessage,
  timeToFirstChunk,
  isBotTyping,
}) => {
  const messageClass = from === 'user' ? styles.userMessage : styles.botMessage
  const fromName = from === 'user' ? 'You' : 'Outseek'
  const headerClass = from === 'user' ? styles.userHeader : styles.botHeader

  const [isCopied, setIsCopied] = useState(false)
  const [feedbackStatus, setFeedbackStatus] = useState(null)

  const { getFeedback } = useFeedback()

  const handleCopy = () => {
    navigator.clipboard.writeText(text).then(() => {
      setIsCopied(true)
      setTimeout(() => setIsCopied(false), 1500)
    })
  }

  const handleFeedback = async (type, timestamp) => {
    try {
      const botMessage = messages?.find(
        msg => msg.from === 'bot' && msg.timestamp === timestamp
      )

      const botMessageIndex = messages?.findIndex(
        msg => msg.from === 'bot' && msg.timestamp === timestamp
      )
      const userMessage =
        botMessageIndex > 0 ? messages[botMessageIndex - 1] : null

      if (botMessage && userMessage && userMessage.from === 'user') {
        const feedback = {
          type,
          timestamp: Date.now(),
          userPrompt: userMessage.text,
          botResponse: botMessage.text,
        }

        getFeedback(feedback)
        setFeedbackStatus(type)
      } else {
        console.error('Could not find corresponding messages for feedback')
      }
    } catch (error) {
      console.error('Error submitting feedback:', error)
    }
  }

  useEffect(() => {
    setFeedbackStatus(null)
  }, [messages, timestamp])

  let extractedSymbol = null
  let extractedDataType = null
  let messageText = text

  const dataSymbolMatch = text.match(/DATASYMBOL:\s*({[^}]+})/)
  if (dataSymbolMatch) {
    const dataSymbol = JSON.parse(dataSymbolMatch[1])
    extractedSymbol = dataSymbol.symbol
    extractedDataType = dataSymbol.dataType
    messageText = text.replace(dataSymbolMatch[0], '').trim()
  }

  const { component: ConfiguredComponent, navigate: navigatePath } =
    ComponentConfig({
      extractedDataType,
      symbol: extractedSymbol,
    })

  return (
    <div className={`${styles.message} ${messageClass}`}>
      <div className={styles.responseDuration}>
        {timeToFirstChunk && <span>{timeToFirstChunk.toFixed(2)}s</span>}
      </div>
      <div className={styles.messageHeader}>
        <div className={headerClass}>{fromName}</div>
        <div className={styles.infoBar}>
          <div className={styles.timestamp}>
            {dayjs(timestamp).format('hh:mm a')}
          </div>
        </div>
      </div>
      <div className={styles.text}>
        <ReactMarkdown
          style={{ marginBottom: 0 }}
          components={markDownComponents}
          remarkPlugins={[remarkGfm]}
        >
          {messageText}
        </ReactMarkdown>
        {from === 'bot' && isLastMessage && isLoading && (
          <div className={styles.loader}>
            <Spinner />
          </div>
        )}
      </div>
      {ConfiguredComponent && !isBotTyping && (
        <div className={styles.configuredComponent}>{ConfiguredComponent}</div>
      )}
      {from === 'bot' && (
        <UtilsButtons
          handleFeedback={handleFeedback}
          timestamp={timestamp}
          handleCopy={handleCopy}
          isCopied={isCopied}
          feedbackStatus={feedbackStatus}
          path={navigatePath}
          dataType={extractedDataType}
        />
      )}
    </div>
  )
}

export default Message
