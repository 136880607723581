import React from 'react'
import dayjs from 'dayjs'
import numeral from 'numeral'
import moment from 'moment'
import _ from 'lodash'
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  XAxis,
  YAxis,
  Tooltip,
  Cell,
  Area,
  ResponsiveContainer,
} from 'recharts'
import { renderTooltipContentGeneric } from '../Charts/GenericChart.js'

export const renderTooltipContentGenericView = o => {
  const { payload, label } = o

  return (
    <div className="customized-tooltip-content-core">
      <div
        className="list"
        style={{
          fontSize: '10.5px',
          color: 'var(--white)',
        }}
      >
        {`${moment(label).format('lll')}`}
        {payload?.map((entry, index) => (
          <div
            className="listItem"
            key={`item-${index}`}
            style={{
              color: entry.color,
              display: 'flex',
              gap: '4px',
              fontSize: '10.5px',
              flexDirection: 'row',
              // justifyContent: 'space-between',
            }}
          >
            <div style={{ color: 'var(--white)' }}>
              {`${_.startCase(entry.name)}: `}
            </div>
            {`${numeral(entry.value).format('0.00 a')} `}
          </div>
        ))}
      </div>
    </div>
  )
}

export const TickerOverlayChart = ({
  data,
  xAxisKey,
  barDataKey,
  lineDataKey,
  XTickFormat,
  YTickFormat,
  view,
  barDomain,
  interval,
}) => {
  const handleTickFormat = tick => {
    if (interval?.label === '1D') {
      return dayjs(tick).format('h:mma')
    } else if (interval?.label === '5D') {
      return dayjs(tick).format('MMM DD, ha')
    } else if (
      interval?.label === '1M' ||
      interval?.label === '3M' ||
      interval?.label === '6M' ||
      interval?.label === '1Y'
    ) {
      return dayjs(tick).format('MMM D')
    } else {
      return dayjs(tick).format('MMM YY') + "'"
    }
  }

  return (
    <ResponsiveContainer width={'100%'} height={'100%'}>
      <ComposedChart width={'100%'} height={'100%'} data={data}>
        <XAxis
          dataKey={xAxisKey}
          tickFormatter={XTickFormat ? XTickFormat : handleTickFormat}
          height={14}
          style={{ fontSize: '11px' }}
        />
        <YAxis
          yAxisId="left"
          domain={barDomain ? barDomain : [0, dataMax => dataMax * 2]}
          tickFormatter={
            YTickFormat
              ? YTickFormat
              : tick => {
                  return numeral(tick).format('0.00a')
                }
          }
          hide={true}
          width={0}
          style={{ fontSize: 13 }}
        />
        <YAxis
          yAxisId="right"
          orientation="right"
          domain={
            interval?.label === '1D'
              ? [dataMin => dataMin * 0.998, dataMax => dataMax * 1.002]
              : [dataMin => dataMin * 0.98, dataMax => dataMax * 1.02]
          }
          tickFormatter={tick => {
            return numeral(tick).format('0.0a')
          }}
          width={40}
          style={{ fontSize: 13 }}
        />
        <Tooltip
          content={
            view ? renderTooltipContentGenericView : renderTooltipContentGeneric
          }
        />
        <CartesianGrid
          strokeDasharray="0"
          vertical={false}
          stroke="var(--button-hover)"
        />
        <Bar yAxisId="left" dataKey={barDataKey} barSize={20}>
          {data?.map((entry, index) => {
            if (index === 0) {
              return <Cell key={`cell-${index}`} fill="var(--button-hover)" />
            }

            const currentValue = entry[lineDataKey]
            const previousValue = data[index - 1][lineDataKey]
            const fillColor =
              previousValue > currentValue ? 'var(--red)' : 'var(--green)'

            return <Cell key={`cell-${index}`} fill={fillColor} opacity={0.4} />
          })}
        </Bar>
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop
              offset="5%"
              stopColor="var(--primary-color)"
              stopOpacity={0.6}
            />
            <stop
              offset="95%"
              stopColor="var(--primary-color)"
              stopOpacity={0}
            />
          </linearGradient>
        </defs>
        <Area
          type="monotone"
          dataKey={lineDataKey}
          stroke="var(--primary-color)"
          dot={false}
          fill="url(#colorUv)"
          strokeWidth={1}
          yAxisId="right"
        />
        {/* <Line
          yAxisId="right"
          type="monotone"
          dataKey={lineDataKey}
          stroke="#ff7300"
          dot={false}
        /> */}
      </ComposedChart>
    </ResponsiveContainer>
  )
}
