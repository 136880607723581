import http from 'network/httpClient'
import moment from 'moment'
import { reduceData } from 'pages/views/CompanyOverview/dataSource/queries'

const fetchIndexQuotes = async ({ queryKey }) => {
  const data = await http.get('papi/data/api/v3/quotes/index?')
  return data.data.message
}

const fetchPriceData = async ({ queryKey }) => {
  const [, ticker, { label, from, to }] = queryKey

  let data

  // const time = label => {
  //   if (label === '5D') {
  //     return '15min'
  //   } else if (label === '1D') {
  //     return '1min'
  //   } else if (label === '1M') {
  //     return '30min'
  //   } else if (label === '3M') {
  //     return '1hour'
  //   }
  // }

  switch (label) {
    // case '1D':
    // case '5D':
    // case '1M':
    // case '3M':
    //   data = await http.get(
    //     `papi/data/api/v3/historical-chart/${time(label)}/${ticker}`
    //   )
    //   data = data.data.message
    //   break
    default:
      let startDate = from ? moment(from).format('YYYY-MM-DD') : null
      let endDate = to ? moment(to).format('YYYY-MM-DD') : null
      data = await http.get(
        `papi/data/api/v3/historical-price-full/${ticker}?from=${startDate}&to=${endDate}`
      )
      data = data.data.message.historical
  }

  let startDate = from ? moment(from) : null
  let endDate = to ? moment(to) : null
  let historicalData = data.filter(item => {
    let date = moment(item.date)
    return (
      (!startDate || date.isAfter(startDate)) &&
      (!endDate || date.isBefore(endDate))
    )
  })

  if (from && moment(to).diff(moment(from), 'years') > 10) {
    historicalData = reduceData(historicalData, from, false, true)
  } else if (from && moment(to).diff(moment(from), 'years') >= 3) {
    historicalData = reduceData(historicalData, from, true, false)
  }

  return historicalData.reverse()
}

const queries = {
  fetchPriceData,
  fetchIndexQuotes,
}

export default queries
