import React from 'react'
import './radar.scss'
import ReactDOM from 'react-dom'

const createPortalContainer = () => {
  const div = document.createElement('div')
  div.id = 'tooltip-portal-container'
  document.body.appendChild(div)
  return div
}

export const Tooltip = ({ children, position }) => {
  const portalContainer =
    document.getElementById('tooltip-portal-container') ||
    createPortalContainer()

  return ReactDOM.createPortal(
    <div
      style={{
        position: 'absolute',
        top: position.y,
        left: position.x,
        zIndex: 1000,
      }}
      className="customized-tooltip-content-core-radar"
    >
      {children}
    </div>,
    portalContainer
  )
}
