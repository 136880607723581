import { useState, useEffect } from 'react'

export const useColumnWidths = (tableId, dependencies) => {
  const [columnWidths, setColumnWidths] = useState({
    first: 0,
    second: 0,
    third: 0,
  })

  useEffect(() => {
    const firstColumn = document.getElementById(`firstColumn-${tableId}`)
    const secondColumn = document.getElementById(`secondColumn-${tableId}`)
    const thirdColumn = document.getElementById(`thirdColumn-${tableId}`)

    setColumnWidths({
      first: firstColumn?.getBoundingClientRect().width || 0,
      second: secondColumn?.getBoundingClientRect().width || 0,
      third: thirdColumn?.getBoundingClientRect().width || 0,
    })
  }, [tableId, ...dependencies])

  return columnWidths
}
