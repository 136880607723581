export const viewInitialState = {
  queryPageIndex: 0,
  queryPageSize: 50,
  totalCount: 0,
  queryPageFilter: [],
  queryPageSortBy: [],
}

export const initialState = {
  queryPageIndex: 0,
  queryPageSize: 10,
  totalCount: 0,
  queryPageFilter: [],
  queryPageSortBy: [],
}

export const PAGE_CHANGED = 'PAGE_CHANGED'
export const PAGE_SIZE_CHANGED = 'PAGE_SIZE_CHANGED'
export const PAGE_SORT_CHANGED = 'PAGE_SORT_CHANGED'
export const PAGE_FILTER_CHANGED = 'PAGE_FILTER_CHANGED'
export const TOTAL_COUNT_CHANGED = 'TOTAL_COUNT_CHANGED'
export const VISIBLE_COLUMNS_CHANGED = 'VISIBLE_COLUMNS_CHANGED'
export const ORDERBY_DESC = 'DESC'
export const ORDERBY_ASC = 'ASC'

export const presetsList = {}
