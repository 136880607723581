export const styles = {
  logo: {
    width: '130px',
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '10px 12px',
  },

  TopMenu: {
    position: 'sticky',
    top: 0,
    left: 0,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    zIndex: 10,
    backgroundColor: 'var(--background-primary)',
    justifyContent: 'space-between',
  },

  AddComponentDropdownContainer: {
    backgroundColor: 'var(--white)',
    width: '1080px',
    height: '768px',
    display: 'grid',
    gridTemplateColumns: 'auto auto auto auto',
  },

  icon: {
    width: '20px',
    height: '20px',
  },

  AddComponentDropdown: [],
  _box: {
    position: 'absolute',
    top: '20%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '560px',
    height: '168px',

    boxShadow: 24,
    alignItems: 'center',
    p: 4,
    borderRadius: '20px',
  },

  newDashboardBox: {
    position: 'absolute',
    top: '20%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '320px',
    height: '94px',

    boxShadow: 24,
    alignItems: 'center',
    p: 4,
    borderRadius: '20px',
  },

  ToolMenuItem: {
    marginLeft: '5px',
    color: 'var(--white)',
    fontSize: 'var(--default-text)',
    fontWeight: 'var(--bold-text)',
  },

  MenuItemTooltip: {
    width: '100%',
    whiteSpace: 'nowrap',
    height: '38px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 'var(--button-padding)',
    cursor: 'pointer',
    borderRadius: 'var(--border-radius)',
    color: 'var(--white)',
    fontSize: 'var(--default-text)',
    fontWeight: 500,
    gap: '4px',
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
    msUserSelect: 'none',
    userSelect: 'none',
    '&:hover': {
      backgroundColor: 'var(--button-hover)',
      transition: 'ease 0.1s',
    },
  },

  ToolMenuSubItem: {
    marginLeft: '5px',
    color: 'var(--white)',
    fontSize: 'var(--default-text)',
    fontWeight: 'var(--bold-text)',
  },

  menuItem: {
    color: 'var(--white)',
    width: '130px',
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '10px 12px',
  },
}
