import { cloneDeep, findIndex } from 'lodash'
import { CHART_TYPES, QUARTERLY, ANNUAL } from '../constants'
import { blendData } from '../utils'
import {
  ACTION_CHANGE_FREQUENCY,
  ACTION_CHANGE_LINE_TYPE,
  ACTION_PUSH_METRIC,
  ACTION_REMOVE_METRIC,
  ACTION_SET_BULK_DATA,
  ACTION_TOGGLE_LABELS,
  ACTION_TOGGLE_METRIC_VIZ_TYPE,
  ACTION_TOGGLE_PERCENT_CHANGE,
  ACTION_TOGGLE_SINGLE_AXIS,
} from './actions'

export const INITIAL_STATE = {
  metrics: [],
  metricsData: [],
  chartTypes: {},
  showLabels: false,
  showPercentageChange: false,
  showSingleAxis: false,
  lineType: { value: 'monotone', label: 'Monotone' },
  frequency: ANNUAL,
}

export const DEFAULT_VIZ_TYPE = CHART_TYPES[0]
export const comparisonChartReducer = (state, { payload, action }) => {
  switch (action) {
    case ACTION_CHANGE_FREQUENCY:
      return {
        ...state,
        metricsData: [],
        frequency: payload,
      }
    case ACTION_CHANGE_LINE_TYPE:
      return {
        ...state,
        lineType: payload,
      }
    case ACTION_PUSH_METRIC:
      return {
        ...state,
        metrics: [...state.metrics, payload.newMetric],
        chartTypes: {
          ...state.chartTypes,
          [payload.newMetric.APIProperty]: DEFAULT_VIZ_TYPE,
        },
        metricsData: blendData(
          state.metricsData,
          payload.newData,
          payload.newMetric.APIProperty
        ),
      }
    case ACTION_REMOVE_METRIC:
      const tempChartType = cloneDeep(state.chartTypes)
      delete tempChartType[payload.APIProperty]
      return {
        ...state,
        metrics: [
          ...state.metrics.filter(metric => {
            return metric.APIProperty !== payload.APIProperty
          }),
        ],
        chartTypes: tempChartType,
        metricsData: [
          ...state.metricsData.map(entry => {
            delete entry[payload.APIProperty]
            return entry
          }),
        ],
      }
    case ACTION_TOGGLE_LABELS:
      return {
        ...state,
        showLabels: !state.showLabels,
      }
    case ACTION_TOGGLE_METRIC_VIZ_TYPE:
      const currChartType = state.chartTypes[payload.APIProperty]
      const nextChartType =
        CHART_TYPES[
          (findIndex(CHART_TYPES, type => type.value === currChartType.value) +
            1) %
            CHART_TYPES.length
        ]
      return {
        ...state,
        chartTypes: {
          ...state.chartTypes,
          [payload.APIProperty]: nextChartType,
        },
      }
    case ACTION_TOGGLE_SINGLE_AXIS:
      return {
        ...state,
        showSingleAxis: !state.showSingleAxis,
      }
    case ACTION_SET_BULK_DATA:
      return {
        ...state,
        metricsData: [...payload.metricsData],
      }
    case ACTION_TOGGLE_PERCENT_CHANGE:
      return {
        ...state,
        showPercentageChange: !state.showPercentageChange,
      }
    default:
      return state
  }
}

export default comparisonChartReducer
