import styles from './FourOFour.module.scss'
import React from 'react'
import { Link } from 'react-router-dom'
import {
  UilDollarAlt,
  UilBriefcaseAlt,
  UilChartBar,
  UilNewspaper,
  UilUniversity,
  UilFileInfoAlt,
  UilAnalytics,
  UilUser,
  UilFilter,
} from '@iconscout/react-unicons'
import { useLocation } from 'react-router'

const routes = [
  {
    path: '/companyOverview',
    name: 'Company Overview',
    icon: <UilBriefcaseAlt />,
  },
  {
    path: '/companyProfile',
    name: 'Company Profile',
    icon: <UilUser />,
  },
  {
    path: '/screener',
    name: 'Screener',
    icon: <UilFilter />,
  },
  {
    path: '/ratios',
    name: 'Ratios',
    icon: <UilAnalytics />,
  },
  {
    path: '/stockNews',
    name: 'Stock News',
    icon: <UilNewspaper />,
  },
  {
    path: '/institutionalOwnership',
    name: 'Institutional Ownership',
    icon: <UilUniversity />,
  },
  {
    path: '/financialStatements',
    name: 'Financial Statements',
    icon: <UilFileInfoAlt />,
  },
  {
    path: '/insiderTrading',
    name: 'Insider Trading',
    icon: <UilChartBar />,
  },
  {
    path: '/earnings',
    name: 'Earnings',
    icon: <UilDollarAlt />,
  },
]

const FourOFour = () => {
  const location = useLocation()
  return (
    <div className={styles.errorBoundaryContainer}>
      <div className={styles.errorBoundaryInfo}>
        <div className={styles.title}>
          <div className={styles.errorBoundaryInfoTitle}>
            This page doesn't exist:{' '}
            <code className={styles.code}>{location.pathname}</code>
          </div>
          <div className={styles.errorBoundaryInfoText}>
            Try these pages instead:
          </div>
        </div>
        <div className={styles.linksWrapper}>
          {routes.map(route => (
            <div key={route.path} className={styles.linkWrapper}>
              <Link to={`/terminal${route.path}`} className={styles.link}>
                {route.icon &&
                  React.cloneElement(route.icon, {
                    color: 'var(--white)',
                    size: 38,
                  })}{' '}
                <span className={styles.linkText}>{route.name}</span>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default FourOFour
