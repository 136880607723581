import { formatAxisTickValue } from './utils'
import areachart from '../../assets/areachart.svg'
import barchart from '../../assets/barchart.svg'
import linechart from '../../assets/LineChart.svg'
export const TOOLBAR_MENU_ITEMS = [
  {
    KeyMap: 'KeyMetricsKeyMap',
    Label: 'Key Metrics',
    Type: 'Company',
    APIKey: 'key-metrics',
  },
  {
    KeyMap: 'GrowthKeyMap',
    Label: 'Growth Metrics',
    Type: 'Company',
    APIKey: 'financial-growth',
  },
  {
    KeyMap: 'FinancialRatiosKeyMap',
    Label: 'Financial Ratios',
    Type: 'Company',
    APIKey: 'ratios',
  },
  {
    KeyMap: 'CashFlowKeyMap',
    Label: 'Cash Flow',
    Type: 'Company',
    APIKey: 'cash-flow-statement',
  },
  {
    KeyMap: 'IncomeStatementKeyMap',
    Label: 'Income Statement',
    Type: 'Company',
    APIKey: 'income-statement',
  },
  {
    KeyMap: 'BalanceSheetKeyMap',
    Label: 'Balance Sheet',
    APIKey: 'balance-sheet-statement',
    Type: 'Company',
    APICall: '',
  },
]

export const LINE_OPTIONS = [
  { value: 'monotone', label: 'Monotone' },
  { value: 'monotoneX', label: 'Monotone X' },
  { value: 'monotoneY', label: 'Monotone Y' },
  { value: 'linear', label: 'Linear' },
  { value: 'linearClosed', label: 'Linear Closed' },
  { value: 'basis', label: 'Basis' },
  { value: 'basisClosed', label: 'Basis Closed' },
  { value: 'basisOpen', label: 'Basis Open' },
  { value: 'step', label: 'Step' },
  { value: 'stepBefore', label: 'Step Before' },
  { value: 'stepAfter', label: 'Step After' },
  { value: 'natural', label: 'Natural' },
]
export const ANNUAL = 'annual'
export const QUARTERLY = 'quarter'

export const ComparisonChartsStyles = {
  _formControl: {
    color: 'var(--white)',
    width: '16%',
    marginLeft: '0.5rem',
  },
  _InputLabel: {
    color: 'var(--white)',
  },
  _Select: {},
}

export const CHART_TYPES = [
  { value: 'line', label: 'Line', icon: linechart },
  { value: 'area', label: 'Area', icon: areachart },
  { value: 'bar', label: 'Bar', icon: barchart },
]

export const COMPARISON_CHART_BASE_PROPS = showPercentageChange => {
  return {
    yAxis: {
      yAxisId: 'single_axis',
      orientation: 'left',
      domain: ['auto', 'auto'],
      tick: {
        stroke: 'var(--grey-accent)',
        fontWeight: 300,
      },
      tickFormatter: tick => formatAxisTickValue(tick, showPercentageChange),
      axisLine: {
        stroke: 'var(--grey-accent)',
        strokeWidth: 1,
      },
    },

    cartesianGrid: {
      style: { stroke: 'rgba(215, 215, 215, 0.2)' },
      vertical: false,
    },
    brush: {
      dataKey: 'date',
      height: 20,
      stroke: 'var(--grey-accent)',
      fill: 'var(--background-primary)',
      borderRadius: 5,
      travellerWidth: 8,
    },
  }
}

export const dataFrequencyOptions = [
  { label: 'Annual', value: 'annual' },
  { label: 'Quarterly', value: 'quarter' },
]

export const labelOptions = [
  { label: 'Show Labels', value: true },
  { label: 'Hide Labels', value: false },
]

export const axisOptions = [
  { label: 'Single Axis', value: true },
  { label: 'Multiple Axes', value: false },
]
