import Select from 'react-select'
import { getSelectStyles } from 'utils/getSelectStyles'

import dayjs from 'dayjs'
import styles from '../earningsTranscript.module.scss'
import { UilPauseCircle } from '@iconscout/react-unicons'
import { UilPlayCircle } from '@iconscout/react-unicons'
import { UilStopCircle } from '@iconscout/react-unicons'
import { useMemo } from 'react'
import {
  getFeatureLimit,
  PREMIUM_FEATURES,
} from 'pages/Pricing/PriceTable/pricingConfig'
import { useUserGroup } from 'hooks/userGroupHook'

export const EarningsToolBar = ({
  data,
  yearOptions = [],
  handleYearChange,
  quarterOptions,
  handleQuarterChange,
  isSpeaking,
  startSpeaking,
  stopSpeaking,
  pauseSpeaking,
  resumeSpeaking,
  isInMiddleOfSpeaking,
}) => {
  return (
    <div className={styles.transcriptToolBarContainer}>
      <div className={styles.transcriptToolBarItemWrapper}>
        <div className={styles.buttonWrapper}>
          <Select
            value={yearOptions?.find(
              option => option?.value === data?.selectedYear
            )}
            onChange={handleYearChange}
            options={yearOptions}
            styles={getSelectStyles(`fit-content`)}
            isSearchable={false}
          />
          <Select
            value={quarterOptions?.find(
              option => option?.value === data?.selectedQuarter
            )}
            onChange={handleQuarterChange}
            options={quarterOptions}
            styles={getSelectStyles(`fit-content`)}
            isSearchable={false}
          />
        </div>
        <div className={styles.transcriptToolBarItem}>
          {dayjs(data?.date).format('dddd, MMMM D, YYYY')}
        </div>
      </div>
      <div className={styles.transcriptToolBarItemWrapper}>
        <div className={styles.buttonWrapper}>
          {isInMiddleOfSpeaking ? (
            <div
              className={styles.pausePlay}
              onClick={isSpeaking ? pauseSpeaking : resumeSpeaking}
            >
              {isSpeaking ? 'Pause' : 'Play'}
              {isSpeaking ? (
                <UilPauseCircle size={20} color={'var(--white)'} />
              ) : (
                <UilPlayCircle size={20} color={'var(--white)'} />
              )}
            </div>
          ) : null}
          <div
            className={styles.pausePlay}
            onClick={isSpeaking ? stopSpeaking : startSpeaking}
          >
            {isInMiddleOfSpeaking ? 'Stop' : 'Start'}
            {isInMiddleOfSpeaking ? (
              <UilStopCircle size={20} color={'var(--white)'} />
            ) : (
              <UilPlayCircle size={20} color={'var(--white)'} />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
