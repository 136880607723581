import TickerSearchView from 'dashboard/Title/components/TickerSearchView'
import styles from './styles.module.scss'
import { customStyles } from './constants'
import { UilTimes } from '@iconscout/react-unicons'

export const TempTicker = ({ ticker, setTicker }) => {
  const updateTicker = newTicker => {
    setTicker(newTicker)
  }

  const removeTicker = () => {
    setTicker(null)
  }

  return (
    <div className={styles.toolBar}>
      {/* <div></div> */}
      <div className={styles.symbolWrapper}>
        {ticker === null ? null : (
          <img
            src={`https://financialmodelingprep.com/image-stock/${ticker}.png`}
            alt={`${ticker} logo`}
            className={styles.logo}
          />
        )}
        <TickerSearchView
          updateTicker={updateTicker}
          customPlaceholder={'Set chat symbol'}
          ticker={ticker}
          styles={customStyles}
          isClearable={true}
        />
        {ticker === null ? null : (
          <div onClick={removeTicker} className={styles.resetTicker}>
            <UilTimes size={20} color={'var(--red)'} />
          </div>
        )}
      </div>
      <div></div>
    </div>
  )
}
