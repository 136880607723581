import {
  CommodityColumns,
  SectorColumns,
  CountryColumns,
  CorpBondsColumns,
  GovBondsColumns,
  GrowthColumns,
  ValueColumns,
  SmallColumns,
  MidColumns,
  LargeColumns,
  IndexColumns,
} from './columns'

export const buildSectorUrl = interval => {
  const { limit } = interval

  return 'papi/data/api/v3/historical-sectors-performance?limit=' + limit
}

const buildHistoricalUrl = symbol => {
  return 'papi/data/api/v3/historical-price-full/' + symbol
}

const MAJOR_INDEXES_QUOTES =
  'papi/data/api/v3/quote/^GSPC,^DJI,^IXIC,^FTSE,^GSPTSE,^N225,^HSI,^SSEC,^GDAXI,^FCHI,^AXJO,^BSESN,^KS11,^TWII,^MERV,^BVSP,^IPC,^MXX,^AEX,^OMXSPI,^OSEAX,^SSMI,^IBEX,^ATX,^HSCEI,^JKSE,^TASI,^EGX30,^XU100,^IMOEX,^JALSH'
const CORP_BONDS_QUOTES =
  'papi/data/api/v3/quote/LQD,HYG,BOND,JNK,IGIB,SLQD,IGSB'
const GOV_BONDS_QUOTES =
  'papi/data/api/v3/quote/GOVT,BND,AGG,TIP,SHY,SCHZ,IEF,TLT,MBB'
const SECTORS_QUOTES =
  'papi/data/api/v3/quote/XLC,XLY,XLP,XLE,XLF,XLV,XLI,XLB,XLRE,XLK,XLU'
const COUNTRIES_QUOTES =
  'papi/data/api/v3/quote/EWJ,FXI,EWZ,EWT,EWG,EWH,EWI,EWW,EWU,EWY,EWA,EWM,EWS,EWC,EWP,EWL,EZA'
const COMMODITIES_QUOTES =
  'papi/data/api/v3/quote/CLUSD,ZGUSD,SIUSD,HGUSD,CUSX,CTUSX,LBSUSD,ZRUSD,OUSX,OJUSX,KCUSX,NGUSD,PAUSD,PLUSD,ZCUSD,ZLUSD,'
const GROWTH_QUOTES =
  'papi/data/api/v3/quote/VUG,IWF,IVW,QQQ,SCHG,SPYG,FTC,IWO,VBK'
const VALUE_QUOTES =
  'papi/data/api/v3/quote/VTV,IWD,IVE,SCHV,SPYV,FTA,IWS,IWN,VBR'
const SMALL_CAP_QUOTES =
  'papi/data/api/v3/quote/IWM,VB,IJR,SCHA,SLY,RZV,IJS,IJT'
const MID_CAP_QUOTES = 'papi/data/api/v3/quote/IWR,VO,IJH,SCHM,MDY,RFV,IJJ,IJK'
const LARGE_CAP_QUOTES =
  'papi/data/api/v3/quote/IWB,VV,IVV,SCHX,SPY,RPV,IVE,IVW'

export const BottomPanelTabStyles = {
  _Tabs: {
    height: '100%',
    margin: '0',
    display: 'flex',
    flexDirection: 'column',
    border: 'none',
    borderRadius: '4px',
  },
  _TabPanel: {
    width: '100%',
    padding: '0 !important',
    margin: '0 !important',
  },
}

const BottomPanelConstants = [
  // {
  //   name: 'Major Indexes',
  //   url: MAJOR_INDEXES_QUOTES,
  //   columns: IndexColumns,
  //   title: 'Major Indexes',
  //   historicalURL: buildHistoricalUrl,
  // },
  {
    name: 'Sectors',
    url: SECTORS_QUOTES,
    columns: SectorColumns,
    title: 'Sectors',
    historicalURL: buildHistoricalUrl,
  },
  {
    name: 'Commodities',
    url: COMMODITIES_QUOTES,
    columns: CommodityColumns,
    title: 'Commodities',
    historicalURL: buildHistoricalUrl,
  },
  {
    name: 'Countries',
    url: COUNTRIES_QUOTES,
    columns: CountryColumns,
    title: 'Countries',
    historicalURL: buildHistoricalUrl,
  },
  {
    name: 'Corporate Bonds',
    url: CORP_BONDS_QUOTES,
    columns: CorpBondsColumns,
    title: 'Corporate Bonds',
    historicalURL: buildHistoricalUrl,
  },
  {
    name: 'Government Bonds',
    url: GOV_BONDS_QUOTES,
    columns: GovBondsColumns,
    title: 'Government Bonds',
    historicalURL: buildHistoricalUrl,
  },
  {
    name: 'Growth',
    url: GROWTH_QUOTES,
    columns: GrowthColumns,
    title: 'Growth',
    historicalURL: buildHistoricalUrl,
  },
  {
    name: 'Value',
    url: VALUE_QUOTES,
    columns: ValueColumns,
    title: 'Value',
    historicalURL: buildHistoricalUrl,
  },
  {
    name: 'Small Cap',
    url: SMALL_CAP_QUOTES,
    columns: SmallColumns,
    title: 'Small Cap',
    historicalURL: buildHistoricalUrl,
  },
  {
    name: 'Mid Cap',
    url: MID_CAP_QUOTES,
    columns: MidColumns,
    title: 'Mid Cap',
    historicalURL: buildHistoricalUrl,
  },
  {
    name: 'Large Cap',
    url: LARGE_CAP_QUOTES,
    columns: LargeColumns,
    title: 'Large Cap',
    historicalURL: buildHistoricalUrl,
  },
]

export default BottomPanelConstants
