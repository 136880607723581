import React from 'react'
import { startCase } from 'lodash'
import Select from 'react-select'
import { CustomFilter } from '../../StringSelectStyles'
import MetricInfo from 'core/MetricInfoHover/MetricInfoTooltip'

export const StringFilter = ({
  label,
  options,
  value,
  handleSelectChange,
  filters,
  metric,
}) => {
  const isActive = filters?.some(filter => filter?.column === metric)

  const stringStyles = {
    ...CustomFilter(isActive),
    option: () => ({
      backgroundColor: 'transparent',
      color: 'var(--white)',
      cursor: 'pointer',
      display: 'block',
      fontSize: '12px',
      padding: '4px 12px',
      width: '',
      whiteSpace: 'wrap',
      boxSizing: 'border-box',
      '&:hover': {
        backgroundColor: 'var(--button-hover)',
      },
    }),
  }

  return (
    <div className="filter-input-container">
      <div className="filter-input-label">
        <div>
          {' '}
          <MetricInfo metricKey={label} displayMetric={startCase(label)} />
        </div>
      </div>
      <div className="filter-input-string">
        <Select
          value={options?.find(option => option?.value === value?.[0])}
          options={options}
          styles={{
            ...stringStyles,
            menuPortal: base => ({ ...base, zIndex: 9999 }),
          }}
          onChange={handleSelectChange}
          placeholder={`Any`}
          menuPortalTarget={document.body}
          menuPosition={'fixed'}
        />
      </div>
    </div>
  )
}
