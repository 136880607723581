/* eslint-disable jsx-a11y/alt-text */
import styles from './DashboardUpdateModal.module.scss'
import { useState, createContext, useMemo } from 'react'
import times from './assets/times.svg'
import Box from '@mui/material/Box'
import edit from './assets/edit.svg'
import Modal from '@mui/material/Modal'
import SubModal from './SubModal'
import { fetchDashboard, fetchNames } from '../../redux/actions/app-actions'
import { connect, useSelector } from 'react-redux'
import moment from 'moment'
import { useNavigate } from 'react-router'
import usePaidFeature from 'hooks/usePaidFeature'
import { UilLock } from '@iconscout/react-unicons'
import { CoreButton } from 'core/Button/CoreButton'

export const DashboardUpdateContext = createContext('')

const style = {
  position: 'absolute',
  top: '20%',
  left: '50%',
  transform: 'translate(-50%, -50%)',

  '&:focus': {
    outline: 'none',
  },
}

export const CreateDashboardButton = ({ onClick }) => {
  const navigate = useNavigate()
  const { canCreateDashboard } = usePaidFeature()

  if (canCreateDashboard) {
    return (
      <div className={styles.buttonWrapper}>
        <CoreButton width="100%" color="var(--primary-color)" onClick={onClick}>
          New Dashboard
        </CoreButton>
      </div>
    )
  } else
    return (
      <div className={styles.buttonWrapper}>
        <CoreButton
          width="100%"
          color="var(--grey-accent)"
          onClick={() => {
            navigate('/terminal/upgrade')
          }}
        >
          <UilLock color={'var(--white)'} size={20} />
          <div>Upgrade to create another dashboard</div>
        </CoreButton>
      </div>
    )
}

const MyDashboards = props => {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [operation, setOperation] = useState('')
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const names = useSelector(state => state.dashboardNames)
  const handleModalAction = config => {
    setOperation({
      ...config,
      parentClose: props.parentClose,
      childClose: handleClose,
    })
    handleOpen()
  }

  const handleLoadDashboard = id => {
    ;(async () => {
      await props.fetchDashboard(id)
      navigate('./dashboard/')
    })()
  }

  return (
    <div className={styles.dashboardWrapper}>
      <div className={styles.myDashboardsTopPanel}>
        <div className={styles.topPanelTitle}>
          <p className={styles.titleMyDashboards}>Dashboards</p>
        </div>
        <img
          className={styles.modalCloseButton}
          onClick={() => props.parentClose()}
          src={times}
        ></img>
      </div>
      <div className={styles.myDashboardsMiddlePanel}>
        <div className={styles.headerTitle}>
          <div className={styles.test}>
            <div>Name</div>
          </div>
          <div className="">
            <div>Last Opened</div>
          </div>
        </div>
        <div className={styles.dashboardEntryContainer}>
          {names.map(d => {
            return (
              <Entry
                dashboard={d}
                handleModalAction={handleModalAction}
                handleLoadDashboard={handleLoadDashboard}
              />
            )
          })}
        </div>
        <DashboardUpdateContext.Provider
          value={{
            fetchNames: props.fetchNames,
          }}
        />
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          disableAutoFocus={true}
        >
          <Box sx={style}>
            <SubModal {...operation} />
          </Box>
        </Modal>
        <DashboardUpdateContext.Provider />
      </div>
      <div className={styles.myDashboardBottomPanel}>
        <CreateDashboardButton
          onClick={() => {
            handleModalAction({
              Title: 'Create New Dashboard',
              Disabled: false,
              InputValue: '',
              Type: 'Create',
            })
            handleOpen()
          }}
        />
      </div>
    </div>
  )
}

const Entry = ({ dashboard, handleModalAction, handleLoadDashboard }) => {
  return (
    <div className={styles.entryContainer}>
      <div
        className={styles.entryNamePanel}
        onClick={() => handleLoadDashboard(dashboard.id)}
      >
        <p className={styles.entryName}> {dashboard.name} </p>
      </div>
      <div className={styles.entryDatePanel}>
        <div className={styles.dateLastUpdated}>
          {moment(dashboard.lastUpdated).format('lll')}{' '}
        </div>
      </div>
      <div className={styles.entryEditPanel}>
        <div className={styles.editIconBackground}>
          <img
            className={styles.editIcon}
            onClick={() => {
              handleModalAction({
                Title: 'Rename',
                Disabled: false,
                InputValue: dashboard,
                Type: 'OK',
                Gap: '115px',
              })
            }}
            src={edit}
          ></img>
        </div>
        <div className={styles.deleteButton}>
          <p
            className={styles.deleteText}
            onClick={() => {
              handleModalAction({
                Title: 'Delete Dashboard',
                Disabled: true,
                InputValue: dashboard,
                Type: 'Delete',
                Gap: '25px',
              })
            }}
          >
            Delete
          </p>
        </div>
      </div>
    </div>
  )
}

export default connect(null, {
  fetchNames,
  fetchDashboard,
})(MyDashboards)
