import { Route, Routes, NavLink, Navigate } from 'react-router-dom'
import styles from './style.module.scss'
import { IPO } from './Calenders/IPO/IPO'
import { IPOConfirmed } from './Calenders/IPOConfirmed/IPOConfirmed'
import { Dividend } from './Calenders/Dividend/Dividend'
import { Economic } from './Calenders/Economic/Economic'
import { Splits } from './Calenders/Splits/Splits'
import { Earnings } from './Calenders/Earnings/Earnings'
import { CalProvider } from './CalContext'

export const Calendars = () => {
  const links = [
    {
      label: 'Economic',
      path: 'economic',
      component: <Economic />,
    },
    {
      label: 'Earnings',
      path: 'earnings',
      component: <Earnings />,
    },
    {
      label: 'Dividend',
      path: 'dividend',
      component: <Dividend />,
    },
    {
      label: 'Splits',
      path: 'splits',
      component: <Splits />,
    },
    {
      label: 'IPO Confirmed',
      path: 'ipo-confirmed',
      component: <IPOConfirmed />,
    },
    {
      label: 'IPO Prospectus',
      path: 'ipo-prospectus',
      component: <IPO />,
    },
  ]

  return (
    <CalProvider>
      <div className={styles.corporateEvents}>
        <div className={styles.eventsLinks}>
          {links.map((link, index) => {
            return (
              <div className={styles.navLink}>
                <NavLink
                  to={link.path}
                  style={({ isActive }) => ({
                    color: isActive ? 'var(--primary-color)' : 'inherit',
                  })}
                >
                  {link.label}
                </NavLink>
                {index !== links.length - 1 && (
                  <div className={styles.divider} />
                )}
              </div>
            )
          })}
        </div>
        <div className={styles.contentWrapper}>
          <Routes>
            <Route path="/" element={<Navigate replace to={links[0].path} />} />
            {links.map(link => {
              return <Route path={link.path} element={link.component} />
            })}
          </Routes>
        </div>
      </div>
    </CalProvider>
  )
}
