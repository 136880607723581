import { Route, Routes, NavLink, Navigate } from 'react-router-dom'
import { Mergers } from './Events/Mergers/Mergers'
import { CrowdFunding } from './Events/CrowFunding/CrowFunding'
import { EquityFunding } from './Events/EquityFunding/EquityFunding'
import styles from './style.module.scss'

export const Events = () => {
  const links = [
    {
      label: 'Mergers & Acquisitions',
      path: 'mergers',
      component: <Mergers />,
    },
    {
      label: 'Crowdfunding',
      path: 'crowdfunding',
      component: <CrowdFunding />,
    },
    {
      label: 'Equity Fundraising',
      path: 'equityFundraising',
      component: <EquityFunding />,
    },
  ]

  return (
    <div className={styles.corporateEvents}>
      <div className={styles.eventsLinks}>
        {links.map((link, index) => {
          return (
            <div className={styles.navLink}>
              <NavLink
                to={link.path}
                style={({ isActive }) => ({
                  color: isActive ? 'var(--primary-color)' : 'inherit',
                })}
              >
                {link.label}
              </NavLink>
              {index !== links.length - 1 && <div className={styles.divider} />}
            </div>
          )
        })}
      </div>
      <div className={styles.contentWrapper}>
        <Routes>
          <Route path="/" element={<Navigate replace to={links[0].path} />} />
          {links.map(link => {
            return <Route path={link.path} element={link.component} />
          })}
        </Routes>
      </div>
    </div>
  )
}
