import React from 'react'
import numeral from 'numeral'
import TooltipTicker from 'core/TickerOverlay/TickerOverlay'
import dayjs from 'dayjs'
import { UilSun, UilMoon } from '@iconscout/react-unicons'
import { upperCase } from 'lodash'

export const earningsColumns = [
  {
    Header: 'Symbol',
    accessor: 'symbol',
    Cell: Cell => {
      return <TooltipTicker ticker={Cell.value} />
    },
  },
  {
    Header: 'Est. Rev',
    accessor: 'revenueEstimated',
    Cell: Cell => {
      return (
        <>
          <span>{numeral(Cell.value).format('0.00a')}</span>
        </>
      )
    },
  },
  {
    Header: 'Est. EPS',
    accessor: 'epsEstimated',
    Cell: Cell => {
      return (
        <>
          <span>{numeral(Cell.value).format('0.00a')}</span>
        </>
      )
    },
  },
  {
    Header: 'Report Time',
    accessor: 'time',
    Cell: Cell => {
      return (
        <>
          <span>
            {/* {upperCase(Cell.value)}{' '} */}
            {Cell.value}{' '}
            {Cell.value === 'bmo' && (
              <UilSun color="#fff466" size="16" style={{ marginBottom: 3 }} />
            )}
            {Cell.value === 'amc' && (
              <UilMoon color="#729CDA" size="16" style={{ marginBottom: 3 }} />
            )}
          </span>
        </>
      )
    },
  },
  {
    Header: 'Year End',
    accessor: 'fiscalDateEnding',
    Cell: Cell => {
      return (
        <>
          <span> {dayjs(Cell.value).format('MMM D')}</span>
        </>
      )
    },
  },
]
