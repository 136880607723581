import http from '../../../../network/httpClient'

export const fetchIncome = async ({ queryKey }) => {
  const [, ticker] = queryKey
  const data = await http.get(
    `papi/data/api/v3/income-statement/${ticker}?limit=120&`
  )
  return data.data.message
}

export const fetchBalance = async ({ queryKey }) => {
  const [, ticker] = queryKey
  const data = await http.get(
    `papi/data/api/v3/balance-sheet-statement/${ticker}?limit=120&`
  )
  return data.data.message
}

export const fetchCash = async ({ queryKey }) => {
  const [, ticker] = queryKey
  const data = await http.get(
    `papi/data/api/v3/cash-flow-statement/${ticker}?limit=120&`
  )
  return data.data.message
}

export const fetchIncomeQuarterly = async ({ queryKey }) => {
  const [, ticker] = queryKey
  const data = await http.get(
    `papi/data/api/v3/income-statement/${ticker}?period=quarter&limit=400&`
  )
  return data.data.message
}

export const fetchBalanceQuarterly = async ({ queryKey }) => {
  const [, ticker] = queryKey
  const data = await http.get(
    `papi/data/api/v3/balance-sheet-statement/${ticker}?period=quarter&limit=400&`
  )
  return data.data.message
}

export const fetchCashQuarterly = async ({ queryKey }) => {
  const [, ticker] = queryKey
  const data = await http.get(
    `papi/data/api/v3/cash-flow-statement/${ticker}?period=quarter&limit=400&`
  )
  return data.data.message
}

export const fetchRevSegs = async ({ queryKey }) => {
  const [, ticker] = queryKey
  const data = await http.get(
    `papi/data//api/v4/revenue-product-segmentation?symbol=${ticker}&structure=flat`
  )
  return data.data.message
}

// export const fetchIncomeGrowth = async ({ queryKey }) => {
//   const [, ticker] = queryKey
//   const data = await http.get(
//     `papi/data/api/v3/income-statement-growth/${ticker}?limit=40&`
//   )
//   return data.data.message
// }

// export const fetchBalanceGrowth = async ({ queryKey }) => {
//   const [, ticker] = queryKey
//   const data = await http.get(
//     `papi/data/api/v3/balance-sheet-statement-growth/${ticker}?limit=40&`
//   )
//   return data.data.message
// }

// export const fetchCashGrowth = async ({ queryKey }) => {
//   const [, ticker] = queryKey
//   const data = await http.get(
//     `papi/data/api/v3/cash-flow-statement-growth/${ticker}?limit=40&`
//   )
//   return data.data.message
// }
