import dayjs from 'dayjs'

const isDataThisYear = data => {
  const currentYear = new Date().getFullYear()
  const dataYear = new Date(data).getFullYear()
  return currentYear === dataYear
}

export const columns = [
  {
    Header: 'Type',
    accessor: 'type',
    width: 'fit-content',
  },
  {
    Header: 'Type Interpreted',
    accessor: 'typeInterpreted',
    width: '100%',
  },
  {
    Header: 'Filling Date',
    accessor: 'fillingDate',
    Cell: ({ value }) => {
      if (isDataThisYear(value)) return dayjs(value).format('MMM DD')
      else return dayjs(value).format('MMM DD, YYYY')
    },
    width: 'fit-content',
  },
  {
    Header: 'Accepted Date',
    accessor: 'acceptedDate',
    Cell: ({ value }) => {
      if (isDataThisYear(value))
        return dayjs(value).format('MMM DD, hh:mm:ss A')
      else return dayjs(value).format('MMM DD, YYYY hh:mm:ss A')
    },
    width: 'fit-content',
  },
  {
    Header: 'Link',
    accessor: 'link',
    width: 'fit-content',

    Cell: ({ value }) => {
      return (
        <a
          style={{
            color: 'var(--primary-color)',
          }}
          href={value}
          target={'_blank'}
          rel="noreferrer"
        >
          <span>Link</span>
        </a>
      )
    },
  },
  {
    Header: 'Final Link',
    accessor: 'finalLink',
    width: 'fit-content',

    Cell: ({ value }) => {
      return (
        <a
          style={{
            color: 'var(--primary-color)',
          }}
          href={value}
          target={'_blank'}
          rel="noreferrer"
        >
          <span>Final Link</span>
        </a>
      )
    },
  },
]
