/* eslint-disable no-console */
import { Auth } from 'aws-amplify'
import deepCopy from 'deepcopy'
import { APP_CONFIG } from '../../config'
import axios from 'axios'
import { configComponentDimensionsMapper } from '../../constants/componentMappers'
export const addComponentToDashboard = (state, component) => {
  state.ComponentCount += 1
  let idNew = 'component' + state.ComponentCount

  let config = Object.keys(configComponentDimensionsMapper).includes(
    component?.type
  )
    ? {
        ...configComponentDimensionsMapper[component?.type],
        x: 0,
        y: 0,
        i: 'component' + state.ComponentCount,
      }
    : {
        w: 1260,
        h: 35,
        x: 0,
        y: 0,
        i: 'component' + state.ComponentCount,
      }

  state.data[idNew] = component
  state.layouts.lg.push(config)

  return state
}

export const removeComponentFromDashboard = (dashboard, id) => {
  delete dashboard.data[id]

  let lg = dashboard.layouts.lg.filter(item => {
    return item.i !== id
  })
  dashboard.layouts.lg = lg

  return dashboard
}

export const renameDashboard = (state, { oldName, newName }) => {
  const { id } = oldName

  Auth.currentSession().then(res => {
    let accessToken = res.getAccessToken()
    let jwt = accessToken.getJwtToken()
    //You can print them to see the full objects

    var configGetDB = {
      method: 'get',
      url: APP_CONFIG.IN_HOUSE_ORIGIN + 'dashboard/' + id,
      headers: {
        Authorization: 'Bearer ' + jwt,
      },
    }

    axios(configGetDB)
      .then(function (response) {
        const db_to_update = response.data.item.Item
        db_to_update.name = newName
        db_to_update.state.name = newName

        var configUpdateDB = {
          method: 'put',
          url: APP_CONFIG.IN_HOUSE_ORIGIN + 'papi/dashboards/' + id,
          headers: {
            Authorization: 'Bearer ' + jwt,
          },
          data: db_to_update,
        }

        axios(configUpdateDB)
          .then(resp => {})
          .catch(err => {
            console.log(err)
          })
      })
      .catch(function (error) {
        console.error(error)
      })
  })
}

export const saveDashboard = clientState => {
  const current = clientState.currentDashboard

  const db_to_update = deepCopy(
    clientState.dashboards.filter(db => db.name === current)[0]
  )

  var currentTime = new Date().toLocaleDateString()
  db_to_update.lastUpdated = currentTime
  db_to_update.layouts = { ...clientState.layouts }
  db_to_update.data = { ...clientState.data }

  const body = {
    userId: clientState.userId,
    name: db_to_update.name,
    state: {
      ...db_to_update,
    },
  }
  Auth.currentSession().then(res => {
    let accessToken = res.getAccessToken()
    let jwt = accessToken.getJwtToken()
    //You can print them to see the full objects

    var config = {
      method: 'put',
      url: APP_CONFIG.IN_HOUSE_ORIGIN + 'papi/dashboards/' + db_to_update?.id,
      headers: {
        Authorization: 'Bearer ' + jwt,
      },
      data: body,
    }

    axios(config)
      .then(function (response) {})
      .catch(function (error) {
        console.log(error)
      })
  })
}

export const updateTableState = (globalState, { id, state }) => {
  globalState.data[id].spec.state = state
  return globalState
}

export const createDashboard = (newDashboard, state) => {
  Auth.currentSession().then(res => {
    let accessToken = res.getAccessToken()
    let jwt = accessToken.getJwtToken()
    //You can print them to see the full objects

    var config = {
      method: 'post',
      url: APP_CONFIG.IN_HOUSE_ORIGIN + 'create/dashboard',
      headers: {
        Authorization: 'Bearer ' + jwt,
      },
      data: newDashboard,
    }

    axios(config)
      .then(function (response) {})
      .catch(function (error) {
        // eslint-disable-next-line no-console
        console.log(error)
      })
  })
}
