import styles from './Roster.module.scss'

export const RosterItem = ({ name, data, centered }) => {
  return (
    <div className={centered ? styles.rosterItemCentered : styles.rosterItem}>
      <div
        className={styles.rosterItemTitle}
        style={{ textAlign: centered ? 'center' : null }}
      >
        {name}
      </div>
      <div
        className={styles.rosterItemData}
        style={{ textAlign: centered ? 'center' : null }}
      >
        {data}
      </div>
    </div>
  )
}
