import styles from './WatchlistNews.module.scss'
import TooltipTicker from 'core/TickerOverlay/TickerOverlay'
import dayjs from 'dayjs'

export const WatchlistNews = ({ data, height }) => {
  const CurrentDate = value => {
    const currentDate = dayjs()
    return dayjs(value).isSame(currentDate, 'day')
      ? dayjs(value).format('h:mm a')
      : dayjs(value).isSame(currentDate, 'year')
      ? dayjs(value).format('MMMM DD')
      : dayjs(value).format('MMMM DD, YYYY')
  }

  return (
    <div className={styles.watchlistNews} style={{ height: height }}>
      {data.map((item, index) => {
        return (
          <div key={index} className={styles.newsItem}>
            <a href={item.url} target="_blank" alt rel="noreferrer">
              <img className={styles.newsItemImg} src={item.image} alt="news" />
            </a>
            <div className={styles.newsItemContent}>
              <div className={styles.newsItemSymbolWrapper}>
                <div className={styles.newsItemSymbol}>
                  <TooltipTicker ticker={item.symbol} />
                </div>
                •{' '}
                <div className={styles.newsItemDate}>
                  {CurrentDate(item.publishedDate)}
                </div>
                •{' '}
                <a href={item.url} target="_blank" alt rel="noreferrer">
                  <div className={styles.newsItemSource}>{item.site}</div>
                </a>
              </div>
              <a href={item.url} target="_blank" alt rel="noreferrer">
                <div className={styles.newsItemTitle}>{item.title}</div>
                <div className={styles.newsItemText}>{item.text}</div>
              </a>
            </div>
          </div>
        )
      })}
    </div>
  )
}
