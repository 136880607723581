import React from 'react'
import styles from './Auth.module.scss'
import SignIn from './SignIn/SignIn'
import SignUp from './SignUp/SignUp'
import { useContext } from 'react'
import { AuthContext } from './AuthContext'

const CustomAuth = ({ modal, view }) => {
  const { onSignIn, onSignUp, showSignUp } = useContext(AuthContext)

  return (
    <div
      className={
        modal
          ? styles.authPageWrapperModal
          : view
          ? styles.authViewWrapper
          : styles.authPageWrapper
      }
    >
      {showSignUp ? (
        <SignIn onSignIn={onSignIn} modal={modal} />
      ) : (
        <SignUp onSignUp={onSignUp} modal={modal} />
      )}
    </div>
  )
}

export default CustomAuth
