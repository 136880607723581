import numeral from 'numeral'
import dayjs from 'dayjs'
import TooltipTicker from 'core/TickerOverlay/TickerOverlay'

export const prospectusColumns = [
  {
    Header: '',
    accessor: 'filingDate',
    width: 'fit-content',
    disableGroupBy: true,
    Cell: Cell => {
      return ''
    },
  },
  {
    Header: 'Symbol',
    accessor: 'symbol',
    width: 'fit-content',
    disableGroupBy: true,
    Cell: Cell => {
      return <TooltipTicker ticker={Cell.value} />
    },
  },
  {
    Header: 'IPO Date',
    accessor: 'ipoDate',
    width: 'fit-content',
    disableGroupBy: true,
    Cell: ({ value }) => dayjs(value).format('MMMM D, YYYY'),
  },
  {
    Header: 'Public Price/Share',
    accessor: 'pricePublicPerShare',
    disableGroupBy: true,
    width: 'fit-content',
  },
  {
    Header: 'Public Price Total',
    accessor: 'pricePublicTotal',
    disableGroupBy: true,
    width: 'fit-content',
  },
  {
    Header: 'Discounts & Commissions/Share',
    accessor: 'discountsAndCommissionsPerShare',
    disableGroupBy: true,
    width: 'fit-content',
  },
  {
    Header: 'Discounts & Commissions Total',
    accessor: 'discountsAndCommissionsTotal',
    disableGroupBy: true,
    width: 'fit-content',
  },
  {
    Header: 'Proceeds Before Expenses/Share',
    accessor: 'proceedsBeforeExpensesPerShare',
    disableGroupBy: true,
    width: 'fit-content',
  },
  {
    Header: 'Proceeds Before Expenses Total',
    accessor: 'proceedsBeforeExpensesTotal',
    disableGroupBy: true,
    width: 'fit-content',
  },
]
