export const planFeatures = [
  {
    section: 'Copilot',
    features: [
      {
        label: 'Daily Prompts',
        free: 3,
        plus: 5,
        pro: 100,
      },
    ],
  },
  {
    section: 'Data Coverage',
    features: [
      {
        label: 'Global Securities Coverage',
        free: true,
        plus: true,
        pro: true,
      },
      {
        label: 'Financial History',
        free: '5Y',
        plus: '10Y',
        pro: 'Full History',
      },
      {
        label: 'SEC Filings',
        free: '1Y',
        plus: '3Y',
        pro: 'Full History',
      },
      {
        label: 'Earnings & Transcripts',
        free: '1Y',
        plus: '5Y',
        pro: 'Full History',
      },
      {
        label: 'Institutional Funds',
        free: true,
        plus: true,
        pro: true,
      },
    ],
  },
  {
    section: 'Features',
    features: [
      {
        label: 'Advanced charting',
        free: true,
        plus: true,
        pro: true,
      },
      {
        label: '# of saved dashboards',
        free: '1',
        plus: '3',
        pro: 'Unlimited',
      },
      {
        label: 'Screener',
        free: true,
        plus: true,
        pro: true,
      },
      {
        label: '# of saved screens',
        free: 1,
        plus: true,
        pro: 'Unlimited',
      },
      {
        label: '# of watchlists',
        free: 1,
        plus: true,
        pro: 'Unlimited',
      },
    ],
  },
]

export const plans = [
  {
    name: 'Free',
    monthly: '$0',
    annual: '$0',
  },
  // {
  //   name: 'Plus',
  //   monthly: '$8.99',
  //   annual: '$7.99',
  // },
  {
    name: 'Pro',
    monthly: '$18.99',
    annual: '$14.15',
  },
]
