import startCase from 'lodash/startCase'
import styles from '../AnalyticalPanel/AnalyticalPanel.module.scss'
import numeral from 'numeral'
import { calculateDifference } from '../../../utils'
import { financialMetricsOrganized } from '../../../constants'
import { useState, useEffect } from 'react'
import Fuse from 'fuse.js'
import { UilAngleRight } from '@iconscout/react-unicons'
import { UilAngleDown } from '@iconscout/react-unicons'
import MetricInfo from 'core/MetricInfoHover/MetricInfoTooltip'

export const RenderMetricRows = ({
  selectedFinancialMetrics,
  finData,
  recentAnalyticsData,
  globalDataKey,
  toggleChart,
  symbol,
  expandedPanel,
  state,
}) => {
  const [expandedCategories, setExpandedCategories] = useState({
    valuation: true,
  })

  const [searchMetrics, setSearchMetric] = useState('')
  const [fuzzyResults, setFuzzyResults] = useState([])

  useEffect(() => {
    const options = {
      keys: ['metric'],
      includeScore: true,
      shouldSort: true,
      threshold: 0.3,
    }

    const fuse = new Fuse(
      selectedFinancialMetrics.map(metric => ({ metric })),
      options
    )
    setFuzzyResults(
      fuse.search(searchMetrics).map(result => result.item.metric)
    )
  }, [searchMetrics, selectedFinancialMetrics])

  const toggleCategory = category => {
    setExpandedCategories({
      ...expandedCategories,
      [category]: !expandedCategories[category],
    })
  }

  const isMetricInFuzzyResults = metric => {
    return searchMetrics === '' || fuzzyResults.includes(metric)
  }

  useEffect(() => {
    if (searchMetrics !== '') {
      const allCategoriesExpanded = Object.keys(
        financialMetricsOrganized
      ).reduce((acc, category) => ({ ...acc, [category]: true }), {})
      setExpandedCategories(allCategoriesExpanded)
    } else {
      setExpandedCategories({ valuation: true })
    }
  }, [searchMetrics])

  return (
    <div className={styles.comparisonTable}>
      <div className={styles.searchBar}>
        <input
          type="text"
          placeholder="Search Metrics..."
          value={searchMetrics}
          onChange={e => setSearchMetric(e.target.value)}
          className={styles.searchMetricsInput}
        />
      </div>
      <div className={styles.comparisonRowHeader}>
        <div className={styles.metricHeader}>
          <div>Metric</div>
        </div>
        <div className={styles.dataWrapperHeader}>
          <div className={styles.analyticValue}>Analytical Avg</div>
          <div className={styles.companyValue}>{symbol}</div>
          <div className={styles.difference}>Diff</div>
        </div>
      </div>
      <div className={styles.listWrapper}>
        {Object.keys(financialMetricsOrganized).map(category => (
          <div key={category}>
            <div
              className={styles.categoryHeader}
              onClick={() => toggleCategory(category)}
            >
              <span>{startCase(category)}</span>
              {expandedCategories[category] ? (
                <UilAngleDown size={20} color={'var(--white)'} />
              ) : (
                <UilAngleRight size={20} color={'var(--white)'} />
              )}
            </div>
            <div
              className={`${styles.metricContainer} ${
                !expandedCategories[category] && styles.metricContainerClosed
              }`}
            >
              {expandedCategories[category] &&
                financialMetricsOrganized[category].map(metric => {
                  if (
                    !selectedFinancialMetrics.includes(metric) ||
                    !isMetricInFuzzyResults(metric)
                  )
                    return null
                  const difference = calculateDifference(
                    finData?.[metric],
                    recentAnalyticsData?.[metric]?.AVG
                  )
                  const color = difference > 0 ? 'var(--green)' : 'var(--red)'
                  const isSelected = metric === globalDataKey

                  return (
                    <div className={styles.metricBarWrapper}>
                      <div
                        key={metric}
                        className={styles.comparisonRow}
                        onClick={() => toggleChart(metric)}
                        style={{
                          borderLeft: expandedPanel
                            ? isSelected
                              ? `3px solid var(--primary-color)`
                              : '3px solid transparent'
                            : null,
                          background: isSelected
                            ? 'var(--background-secondary)'
                            : null,
                        }}
                      >
                        <div className={styles.metricHeader}>
                          <MetricInfo
                            metricKey={metric}
                            displayMetric={startCase(metric)}
                          />
                        </div>
                        <div className={styles.dataWrapper}>
                          <div className={styles.analyticValue}>
                            {state === 'Ratios'
                              ? numeral(
                                  recentAnalyticsData?.[metric]?.AVG
                                ).format('0.00a')
                              : numeral(
                                  recentAnalyticsData?.[metric]?.AVG
                                ).format('0.00a')}
                          </div>
                          <div className={styles.companyValue}>
                            {state === 'Ratios'
                              ? numeral(finData?.[metric]).format('0.00a')
                              : numeral(finData?.[metric]).format('0.00a')}
                          </div>
                          <div
                            className={styles.difference}
                            style={{
                              color:
                                difference === 0 ? 'var(--off-white)' : color,
                            }}
                          >
                            {difference === 0
                              ? '-'
                              : numeral(difference).format('0.0a%')}
                          </div>
                        </div>
                      </div>
                      <div className={styles.borderline} />
                    </div>
                  )
                })}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
