import React from 'react'
import { ProSidebarProvider } from 'react-pro-sidebar'
import ToolMenu from './components/ToolMenu/ToolMenu'
import styles from './styles.module.scss'
import { Route, Routes } from 'react-router-dom'
import { NewsView, TrendingNewsView } from 'pages/views/NewsView/index'
import { Screener } from 'pages/views/Screener/Screener'
import { PriceChart } from 'pages/views/PriceCharts/PriceChart'
import { CompChart } from 'pages/views/CompChart/CompChart'
import { CompanyOverview } from 'pages/views/CompanyOverview/CompanyOverview'
import { FinancialStatementsView } from 'pages/views/FinancialStatements/FinancialStatements'
import { InsiderTrading } from 'pages/views/InsiderTrading/InsiderTrading'
import Watchlist from 'pages/views/Watchlist/Watchlist'
import { Analytics } from 'pages/views/CompanyAnalytics/CompanyAnalytics'
import {
  InstitutionalView,
  StockView,
} from 'pages/views/InstitutionalOwnership/index'
import { MarketOverview } from 'pages/views/MarketOverview/MarketOverview'
import { Ratios } from 'pages/views/Ratios/Ratios'
import { CompanyProfile } from 'pages/views/CompanyProfile/CompanyProfile'
import { EarningsView } from 'pages/views/Earnings/Earnings'
import useIsAuthenticated from 'core/AuthComponents/useIsAuthenticated'
import { AccountPage } from 'pages/AccountPage/AccountPage'
import CustomAuth from 'core/AuthComponents/CustomAuth'
import { TopBar } from './components/TopBar.js/TopBar'
import FourOFour from 'pages/ErrorBoundary/404/404terminal'
import DashboardContainer from './components/DashboardContainer'
import { useLoadDashboards } from './hooks/useDashboard'
import { DemoUserProtectedRoute } from 'core/ProtectedRoute/ProtectedRoute'
import useSetupUser from './hooks/useSetupUser'
import { TerminalPricing } from 'pages/Pricing/TerminalPricing'
import { ETF } from 'pages/views/ETF/ETFInfo/ETF'
import { ETFHoldings } from 'pages/views/ETF/ETFHoldings/ETFHoldings'
import { Indexes } from 'pages/views/Indexes/Indexes'
import { SecFillings } from 'pages/views/SecFillings/SecFillings'
import { CorporateEvents } from 'pages/views/CorporateEvents/Events/Mergers/Mergers'
import { Events } from 'pages/views/CorporateEvents/rootEvents'
import { Calendars } from 'pages/views/Calendars/rootCalendars'
import CoPilot from 'pages/views/CoPilot/CoPilot'

const Terminal = () => {
  useSetupUser() // This is jank
  const { isDemoUser } = useIsAuthenticated()
  useLoadDashboards() // this just loads the dashboard names for the modal list

  return (
    <ProSidebarProvider>
      <div className="dashboard">
        <div className={styles.outseekWrapper}>
          <div className={styles.toolTabGridWrapper}>
            <ToolMenu />
            <div className={styles.tabContentWrapper}>
              <TopBar />
              <div className={styles.contentWrapper}>
                <Routes>
                  <Route
                    path="/companyOverview"
                    element={<CompanyOverview />}
                  />
                  <Route path="*" element={<FourOFour />} />
                  <Route path="/companyProfile" element={<CompanyProfile />} />
                  <Route path="/screener" element={<Screener />} />
                  <Route path="/priceChart" element={<PriceChart />} />
                  <Route path="/comparisonChart" element={<CompChart />} />
                  <Route path="/ratios" element={<Ratios />} />
                  <Route path="/stockNews" element={<NewsView />} />
                  <Route path="/marketNews" element={<TrendingNewsView />} />
                  <Route path="/ETFInfo" element={<ETF />} />
                  <Route path="/ETFHoldings" element={<ETFHoldings />} />
                  <Route
                    path="/institutionalOwnership"
                    element={<StockView />}
                  />
                  <Route
                    path="/institutionalFunds"
                    element={<InstitutionalView />}
                  />
                  <Route
                    path="/financialStatements"
                    element={<FinancialStatementsView />}
                  />
                  <Route path="/worldIndexes" element={<Indexes />} />
                  <Route path="/insiderTrading" element={<InsiderTrading />} />
                  <Route path="/companyAnalytics" element={<Analytics />} />
                  <Route
                    path="/earningsAndTranscripts"
                    element={<EarningsView />}
                  />
                  <Route path="/marketOverview" element={<MarketOverview />} />
                  <Route path="/secFillings" element={<SecFillings />} />
                  <Route path="/corporateEvents/*" element={<Events />} />
                  <Route path="/calendars/*" element={<Calendars />} />
                  <Route path="/upgrade" element={<TerminalPricing />} />
                  <Route path="/login" element={<CustomAuth view />} />
                  <Route element={<DemoUserProtectedRoute />}>
                    <Route
                      exact
                      path="/account"
                      element={<AccountPage />}
                    ></Route>
                    <Route
                      exact
                      path="/dashboard"
                      element={<DashboardContainer />}
                    ></Route>
                    <Route path="/watchlist" element={<Watchlist />} />
                    <Route path="/copilot" element={<CoPilot />} />
                  </Route>
                </Routes>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ProSidebarProvider>
  )
}

export default Terminal
