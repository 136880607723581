import { useMutation, useQuery, useQueryClient } from 'react-query'
import http from '../../network/httpClient'

const Chat_URL = 'papi/copilot/'

async function createChatFn({ body }) {
  let data = await http.post(`${Chat_URL}create`, body)
  return data.data
}

async function readAllWatchlistsFn() {
  let data = await http.get(`${Chat_URL}summary`)
  return data.data
}

async function updateChatFn({ Chat_id, body }) {
  let data = await http.put(`${Chat_URL}${Chat_id}`, body)

  return data.data
}

async function deleteChatsFn({ Chat_id }) {
  return http.delete(`${Chat_URL}${Chat_id}`)
}

export const useChats = () => {
  const queryClient = useQueryClient()

  const {
    data: readAll,
    isLoading: isReadAllLoading,
    isError: isReadAllError,
  } = useQuery([Chat_URL], () => readAllWatchlistsFn())

  const {
    mutate: createChat,
    error: isCreateError,
    isLoading: isCreateChatLoading,
  } = useMutation(createChatFn, {
    onSuccess: data => {
      queryClient.invalidateQueries({ queryKey: [Chat_URL] })
      return data
    },
  })

  const { mutate: updateChat } = useMutation({
    mutationFn: updateChatFn,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [Chat_URL],
      }),
  })

  const {
    error: isDeleteError,
    isLoading: isDeleteChatLoading,
    mutate: deleteChat,
  } = useMutation({
    mutationFn: deleteChatsFn,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [Chat_URL],
      }),
  })

  const getChatMessages = chatId => {
    const chat = readAll?.item?.Items?.find(item => item.chatId === chatId)
    return chat?.messages
  }

  const numberOfChats = readAll?.item?.Items?.length

  const isLoadings =
    isCreateChatLoading || isDeleteChatLoading || isReadAllLoading

  const isError = isReadAllError || isCreateError || isDeleteError

  return {
    readAll,
    isChatsLoading: isLoadings,
    isError,
    createChat,
    deleteChat,
    updateChat,
    numberOfChats,
    getChatMessages,
  }
}

export default useChats
