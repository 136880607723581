import Close from '@mui/icons-material/Close'
import React from 'react'
import { Modal, ModalHeader } from 'reactstrap'

const Alerts = ({ content, onClearAlert }) => {
  return (
    <Modal
      open={true}
      onClose={() => onClearAlert()}
      autoFocus={false}
      disableEnforceFocus={true}
    >
      <ModalHeader>
        Close
        <Close onClick={onClearAlert} />
      </ModalHeader>
      {content}
    </Modal>
  )
}

export default Alerts
