import React, { useState, useRef, useMemo } from 'react'

import Select from 'react-select'
import { tickerList, topTickerList } from '../../../constants/tickerList'
import { matchSorter } from 'match-sorter'

const TickerSearchDashboard = ({ updateTicker, width, ticker }) => {
  const [options, setOptions] = useState(topTickerList)
  const selectRef = useRef(0)

  const placeholder = useMemo(
    () => options.filter(op => op.value === ticker)[0]?.label,
    [options, ticker]
  )
  const handleOnSelect = item => {
    // the item selected
    updateTicker(item?.value)
  }

  const handleChangeInput = value => {
    if (value) {
      return setOptions(
        matchSorter(tickerList, value, { keys: ['symbol', 'name'] })?.slice(
          0,
          10
        )
      )
    }
    setOptions(topTickerList)
  }

  return (
    <Select
      maxMenuHeight={210}
      ref={selectRef}
      pageSize={1}
      onChange={e => handleOnSelect(e)}
      styles={customStyles}
      onInputChange={e => handleChangeInput(e)}
      options={options}
      closeMenuOnSelect={true}
      placeholder={placeholder}
    />
  )
}

export default TickerSearchDashboard

const customStyles = {
  control: () => ({
    backgroundColor: 'var(--button-hover)',
    display: 'flex',
    flexDirection: 'row',
    height: 24,
    width: 'fit-content',
    maxWidth: '200px',
    alignItems: 'center',
    fontSize: '12px',
    margin: '0px',
    borderRadius: '4px',
  }),
  placeholder: () => ({
    color: 'var(--off-white)',
    gridArea: '1/1/2/3',
    marginLeft: '1px',
    marginRight: '1px',
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    fontSize: '12px',
  }),
  singleValue: () => ({
    color: 'var(--off-white)',
    gridArea: '1/1/2/3',
    marginLeft: '1px',
    marginRight: '1px',
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    fontSize: '12px',
  }),
  indicatorSeparator: () => ({
    backgroundColor: 'var(--off-white)',
    alignSelf: 'stretch',
    marginBottom: '8px',
    marginTop: '8px',
    width: '1px',
    boxSizing: 'border-box',
  }),
  menu: () => ({
    position: 'absolute',
    backgroundColor: 'var(--background-primary)',
    borderRadius: '4px',
    border: '2px solid var(--grey-accent)',
    boxShadow:
      '0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 12px hsl(0deg 0% 0% / 10%)',
    marginBottom: '8px',
    marginTop: '8px',
    zIndex: 100,
    boxSizing: 'border-box',
    width: '400px',
  }),
  menuPortal: provided => ({ ...provided, zIndex: 9999 }),
  MenuList: () => ({
    paddingBottom: '4px',
    maxHeight: '100px',
    overflowY: 'auto',
    paddingTop: '4px',
    zIndex: 100,
    position: 'relative',
    boxSizing: 'border-box',
  }),
  option: () => ({
    backgroundColor: 'transparent',
    color: 'var(--off-white)',
    display: 'block',
    fontSize: '12px',
    zIndex: 100,
    padding: '8px 12px',
    width: '',
    cursor: 'pointer',
    boxSizing: 'border-box',
    '&:hover': {
      backgroundColor: 'var(--button-hover)',
    },
  }),
  input: (provided, state) => ({
    ...provided,
    color: 'var(--white)',
    fontSize: '12px',
  }),
}

const customStyles2 = {
  control: () => ({
    backgroundColor: 'var(--button-hover)',
    display: 'flex',
    flexDirection: 'row',
    height: 24,
    width: 'fit-content',
    alignItems: 'center',
    fontSize: '12px',
    margin: '0px',
    borderRadius: '4px',
    '&:focus-within': {
      transition: 'all 0.2s ease-in-out',
      width: '280px',
    },
  }),
  singleValue: () => ({
    color: 'var(--off-white)',
    gridArea: '1/1/2/3',
    marginLeft: '1px',
    marginRight: '1px',
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    fontSize: '12px',
  }),
  indicatorSeparator: () => ({
    backgroundColor: 'var(--off-white)',
    alignSelf: 'stretch',
    marginBottom: '8px',
    marginTop: '8px',
    width: '1px',
    boxSizing: 'border-box',
  }),
  menu: () => ({
    position: 'absolute',
    backgroundColor: 'var(--background-primary)',
    border: '2px solid var(--grey-accent)',
    borderRadius: '4px',
    boxShadow:
      '0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 12px hsl(0deg 0% 0% / 10%)',
    marginBottom: '8px',
    marginTop: '8px',
    zIndex: 100,
    boxSizing: 'border-box',
    width: '280px',
  }),
  menuPortal: provided => ({ ...provided, zIndex: 9999 }),
  MenuList: () => ({
    paddingBottom: '4px',
    maxHeight: '100px',
    overflowY: 'auto',
    paddingTop: '4px',
    zIndex: 100,
    position: 'relative',
    boxSizing: 'border-box',
  }),
  option: () => ({
    backgroundColor: 'transparent',
    color: 'var(--off-white)',
    display: 'block',
    fontSize: '12px',
    zIndex: 100,
    padding: '8px 12px',
    width: '',
    cursor: 'pointer',
    boxSizing: 'border-box',
    '&:hover': {
      backgroundColor: 'var(--button-hover)',
    },
  }),
  input: (provided, state) => ({
    ...provided,
    color: 'var(--white)',
    fontSize: '12px',
  }),
}
