import React, { useState, useMemo, useCallback, useEffect } from 'react'
import { useTable, useGroupBy, useExpanded, useFilters } from 'react-table'
import '../../styles.scss'
import { UilAngleRight, UilAngleDown } from '@iconscout/react-unicons'
import Select from 'react-select'
import { siteFilter } from 'pages/views/NewsView/component/NewsTable/constants'
import CoreButton from 'core/Button/CoreButton'
import { DefaultColumnFilter } from './filters'
import { styles } from './BasicTable'
import { startCase } from 'lodash'
import dayjs from 'dayjs'

const CalendarTable = ({
  data,
  columns,
  overflowXScroll,
  filterColumnNames = [],
  groupByKey = 'date',
}) => {
  const [filterInputs, setFilterInputs] = useState({})

  const handleFilterChange = useCallback(
    columnName => selectedOption => {
      setFilterInputs(inputs => ({
        ...inputs,
        [columnName]: selectedOption,
      }))
    },
    []
  )

  const filterOptions = useMemo(() => {
    return filterColumnNames.reduce((acc, columnName) => {
      const options = new Set()
      data.forEach(item => {
        const value = item[columnName]
        if (value != null) {
          options.add(value.toString())
        }
      })
      acc[columnName] = Array.from(options).map(option => ({
        value: option,
        label: option,
      }))
      return acc
    }, {})
  }, [data, filterColumnNames])

  const filteredData = useMemo(() => {
    return data.filter(item => {
      return filterColumnNames.every(columnName => {
        const filter = filterInputs[columnName]
        return filter
          ? item[columnName] && item[columnName].toString() === filter.value
          : true
      })
    })
  }, [data, filterInputs, filterColumnNames])

  const defaultColumn = React.useMemo(
    () => ({
      SearchFilter: DefaultColumnFilter,
    }),
    []
  )

  const today = dayjs().format('dddd, MMMM D, YYYY')
  const yesterday = dayjs().subtract(1, 'day').format('dddd, MMMM D, YYYY')
  const tomorrow = dayjs().add(1, 'day').format('dddd, MMMM D, YYYY')

  const initialExpanded = useMemo(() => {
    return {
      [`${groupByKey}:${today}`]: true,
    }
  }, [groupByKey, today])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { expanded },
    toggleRowExpanded,
  } = useTable(
    {
      columns,
      data: filteredData,
      defaultColumn: defaultColumn,
      initialState: {
        groupBy: [groupByKey],
        expanded: initialExpanded,
      },
      autResetExpanded: false,
    },
    useFilters,
    useGroupBy,
    useExpanded
  )

  const toggleAllRowsExpanded = useCallback(() => {
    const isAnyRowExpanded = rows.some(row => row.isGrouped && expanded[row.id])
    rows.forEach(row => {
      if (row.isGrouped) toggleRowExpanded(row.id, !isAnyRowExpanded)
    })
  }, [toggleRowExpanded, expanded, rows])

  return (
    <div
      className="table-div"
      style={{
        width: '100%',
        maxWidth: '100%',
        height: '100%',
        background: 'var(--background-primary)',
        overflowX: overflowXScroll ? 'scroll' : 'hidden',
        overflowY: 'scroll',
        borderRadius: '4px',
      }}
    >
      <table
        style={{
          ...styles._table,
          width: '100%',
        }}
        {...getTableProps()}
      >
        <thead style={styles._tr_Head}>
          {headerGroups.map(headerGroup => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              style={{ borderRadius: '4px' }}
            >
              {headerGroup.headers.map(column => (
                <th
                  style={{
                    ...styles._th,
                    width: column.width,
                    cursor: 'default',
                  }}
                >
                  <div
                    style={{
                      height: '100%',
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '8px',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        gap: '8px',
                      }}
                      {...column.getHeaderProps()}
                    >
                      {column.render('Header')}

                      {column.Search === true
                        ? column.render('SearchFilter')
                        : null}
                      {filterColumnNames.includes(column.id) && (
                        <Select
                          options={filterOptions[column.id]}
                          value={filterInputs[column.id] || null}
                          onChange={handleFilterChange(column.id)}
                          placeholder="Filter"
                          isClearable
                          styles={siteFilter(true)}
                        />
                      )}
                    </div>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody
          style={{
            width: '100%',
            height: '100%',
            background: 'var(--background-primary)',
            overflowX: 'scroll',
            borderRadius: '4px',
          }}
          {...getTableBodyProps()}
        >
          {rows.map((row, i) => {
            prepareRow(row)
            const rowProps = row.getRowProps()
            return (
              <React.Fragment key={rowProps.key}>
                {row.isGrouped ? (
                  <tr
                    className="table-row"
                    {...row.getRowProps()}
                    style={{ background: 'var(--background-tertiary)' }}
                  >
                    <td
                      colSpan={columns.length}
                      {...row.getToggleRowExpandedProps({
                        style: {
                          ...styles._td,
                          padding: '4px 8px',
                        },
                      })}
                    >
                      {row.isExpanded ? <UilAngleDown /> : <UilAngleRight />}{' '}
                      {row.values[groupByKey]}
                      {row.values[groupByKey] === today && (
                        <span
                          style={{
                            color: 'var(--green)',
                            marginLeft: '8px',
                            border: '2px solid var(--green)',
                            padding: '2px 4px',
                            borderRadius: '4px',
                            fontWeight: '500',
                          }}
                        >
                          Today
                        </span>
                      )}
                      {row.values[groupByKey] === yesterday && (
                        <span
                          style={{
                            color: 'var(--red)',
                            marginLeft: '8px',
                            border: '2px solid var(--red)',
                            padding: '2px 4px',
                            borderRadius: '4px',
                            fontWeight: '500',
                          }}
                        >
                          Yesterday
                        </span>
                      )}
                      {row.values[groupByKey] === tomorrow && (
                        <span
                          style={{
                            color: 'var(--primary-color)',
                            marginLeft: '8px',
                            border: '2px solid var(--primary-color)',
                            padding: '2px 4px',
                            borderRadius: '4px',
                            fontWeight: '500',
                          }}
                        >
                          Tomorrow
                        </span>
                      )}
                    </td>
                  </tr>
                ) : row.isAggregated ? null : (
                  <tr className="table-row" {...row.getRowProps()}>
                    {row.cells.map(cell => (
                      <td
                        {...cell.getCellProps({
                          style: {
                            maxWidth: cell.column.width,
                            width: cell.column.width,
                            ...styles._td,
                          },
                        })}
                      >
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                )}
              </React.Fragment>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default CalendarTable
