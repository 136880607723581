import React from 'react'
import { startCase } from 'lodash'
import exchange from '../exchange.svg'
import '../screenerMetricCatalog.module.scss'
import styles from './betweenFilter.module.scss'
import MetricInfo from 'core/MetricInfoHover/MetricInfoTooltip'

const factors = [
  {
    value: 'TH',
    label: 'Th',
  },
  {
    value: 'M',
    label: 'M',
  },
  {
    value: 'B',
    label: 'B',
  },
]

export const BetweenFilter = ({
  label,
  hasFactor,
  selectedUnit,
  setSelectedUnit,
  value,
  handlePushFilter,
  type,
  metric,
  handleValueChange,
  onSwitchToConstantFilter,
}) => {
  return (
    <div className="filter-input-container">
      <div className="filter-input-label">
        <div>
          <MetricInfo metricKey={metric} displayMetric={startCase(label)} />
        </div>
      </div>
      <div className="filter-input-min-max">
        <div
          style={{
            display: 'flex',
          }}
        >
          {hasFactor && (
            <select
              value={selectedUnit}
              onChange={e => setSelectedUnit(e.target.value)}
              className="filter-input-unit"
            >
              {factors.map(factor => (
                <option
                  className="filter-input-unit-option"
                  value={factor.value}
                >
                  {factor.label}
                </option>
              ))}
            </select>
          )}
          <input
            value={
              value[0] !== undefined && value[0] !== null
                ? hasFactor
                  ? value[0] /
                    (selectedUnit === 'M'
                      ? 1000000
                      : selectedUnit === 'B'
                      ? 1000000000
                      : 1000)
                  : value[0]
                : ''
            }
            type="number"
            className={
              value[0] !== undefined && value[0] !== null
                ? 'filter-input-active-min'
                : 'filter-input-min'
            }
            onChange={e => handleValueChange(e, 0)}
            placeholder={`Min`}
          />
          -
          <input
            value={
              value[1] !== undefined && value[1] !== null
                ? hasFactor
                  ? value[1] /
                    (selectedUnit === 'M'
                      ? 1000000
                      : selectedUnit === 'B'
                      ? 1000000000
                      : 1000)
                  : value[1]
                : ''
            }
            type="number"
            className={
              value[1] !== undefined && value[1] !== null
                ? 'filter-input-active-max'
                : 'filter-input-max'
            }
            onChange={e => handleValueChange(e, 1)}
            placeholder={`Max`}
          />
        </div>
        <div
          className="filter-input-button"
          onClick={() => {
            if (
              value[0] !== undefined &&
              value[0] !== null &&
              value[1] !== undefined &&
              value[1] !== null
            ) {
              handlePushFilter({
                column: metric,
                type: type,
                config: {
                  min: value[0],
                  max: value[1],
                  unit: selectedUnit,
                },
              })
            }
          }}
        >
          Add
        </div>
        <div className={styles.swapWrapper}>
          <img
            alt="swap filter"
            onClick={onSwitchToConstantFilter}
            src={exchange}
            className={styles.swapIcon}
          />
        </div>
      </div>
    </div>
  )
}
