import TradingViewWidget, { Themes } from 'react-tradingview-widget'
import { useSelector } from 'react-redux'
import ComparisonCharts from '../ComparisonCharts'
import { connect } from 'react-redux'
import { Profile } from '../Profile/Profile'
import { EarningsTranscript } from '../EarningsTranscript/EarningsTranscript'

import ESGScore from '../ESGScore/ESGScore'
import { GeographicalRevenueSegments } from '../RevenueSegments/GeographicalRevenueSegments'
import { SalesRevenueSegments } from '../RevenueSegments/SalesRevenueBySegment'
import { InstStockOwnerShip } from '../InsitutionalStockOwnership/InstitutionalStockOwnership'
import { Notes } from '../Notes/Notes'
import { CompanyQuote } from '../CompanyQuote/CompanyQuote'
import { updateTableState } from '../../redux/actions/app-actions'
import ServerSideScreener from '../ServerSideScreener/ServerSideScreener'
import _ from 'lodash'
import {
  COMPANY_PROFILE,
  COMPARISON_CHARTS,
  ESG_SCORE_BREAKDOWN,
  INSTITUTIONAL_STOCK_OWNERSHIP,
  NOTES,
  SCREENER,
  EARNINGS_TRANSCRIPT,
  GEO_REVENUE_SEGMENTS,
  PRICE_CHARTS,
  PRODUCT_REVENUE_SEGMENTS,
  COMPANY_QUOTE,
} from '../../constants/uniqueConstants'

const UniqueBlock = ({ root, ticker, type, data, width, height, ...props }) => {
  const state = useSelector(state => state.data[root].spec.state)
  const handleUpdateState = newState => {
    props.updateTableState(newState, root)
  }

  const tradingViewSymbol = 'NASDAQ:' + ticker
  switch (type) {
    case SCREENER:
      return (
        <ServerSideScreener
          {...{ update: handleUpdateState, persistedState: state }}
        />
      )
    case PRICE_CHARTS:
      return (
        <div style={{ width: '100%', height: height + 25 }}>
          <TradingViewWidget
            symbol={tradingViewSymbol}
            theme={Themes.DARK}
            locale="en"
            autosize
          />
        </div>
      )
    case COMPARISON_CHARTS:
      return (
        <>
          <div style={{ width: '100%', height: height + 28 }}>
            <ComparisonCharts
              root={root}
              initialState={!_.isEmpty(state) ? { ...state } : null}
              width={width}
              height={height}
              ticker={ticker}
            />
          </div>
        </>
      )
    case COMPANY_PROFILE:
      return <Profile ticker={ticker} width={width} height={height} />
    case NOTES:
      return (
        <Notes
          handleUpdateState={handleUpdateState}
          savedNote={state}
          height={height + 12}
        />
      )
    case INSTITUTIONAL_STOCK_OWNERSHIP:
      return <InstStockOwnerShip ticker={ticker} />
    case ESG_SCORE_BREAKDOWN:
      return <ESGScore ticker={ticker} />
    case EARNINGS_TRANSCRIPT:
      return <EarningsTranscript ticker={ticker} />
    case GEO_REVENUE_SEGMENTS:
      return <GeographicalRevenueSegments ticker={ticker} />
    case PRODUCT_REVENUE_SEGMENTS:
      return <SalesRevenueSegments ticker={ticker} />
    case COMPANY_QUOTE:
      return <CompanyQuote ticker={ticker} />
    default:
      return
  }
}

export default connect(null, {
  updateTableState,
})(UniqueBlock)
