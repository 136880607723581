export const analyticsCategories = [
  'sector',
  'industry',
  'country',
  'exchange',
  'currency',
]

const BALANCE_SHEET_METRICS = ['']
const CASH_FLOW_METRICS = ['']
const INCOME_STATEMENT_METRICS = ['']
const RATIOS_METRICS = ['']

export const ratioMetrics = [
  'currentRatio',
  'quickRatio',
  'cashRatio',
  'daysOfSalesOutstanding',
  'daysOfInventoryOutstanding',
  'operatingCycle',
  'daysOfPayablesOutstanding',
  'cashConversionCycle',
  'grossProfitMargin',
  'operatingProfitMargin',
  'pretaxProfitMargin',
  'netProfitMargin',
  'effectiveTaxRate',
  'returnOnAssets',
  'returnOnEquity',
  'returnOnCapitalEmployed',
  'netIncomePerEBT',
  'ebtPerEbit',
  'ebitPerRevenue',
  'debtRatio',
  'debtEquityRatio',
  'longTermDebtToCapitalization',
  'totalDebtToCapitalization',
  'interestCoverage',
  'cashFlowToDebtRatio',
  'companyEquityMultiplier',
  'receivablesTurnover',
  'payablesTurnover',
  'inventoryTurnover',
  'fixedAssetTurnover',
  'assetTurnover',
  'operatingCashFlowPerShare',
  'freeCashFlowPerShare',
  'cashPerShare',
  'payoutRatio',
  'operatingCashFlowSalesRatio',
  'freeCashFlowOperatingCashFlowRatio',
  'cashFlowCoverageRatios',
  'shortTermCoverageRatios',
  'capitalExpenditureCoverageRatio',
  'dividendPaidAndCapexCoverageRatio',
  'dividendPayoutRatio',
  'priceBookValueRatio',
  'priceToBookRatio',
  'priceToSalesRatio',
  'priceEarningsRatio',
  'priceToFreeCashFlowsRatio',
  'priceToOperatingCashFlowsRatio',
  'priceCashFlowRatio',
  'priceEarningsToGrowthRatio',
  'priceSalesRatio',
  'dividendYield',
  'enterpriseValueMultiple',
  'priceFairValue',
]

export const financialMetricsOrganized = {
  valuation: [
    'priceToBookRatio',
    'priceToSalesRatio',
    'priceEarningsRatio',
    'priceToFreeCashFlowsRatio',
    'priceToOperatingCashFlowsRatio',
    'priceCashFlowRatio',
    'priceEarningsToGrowthRatio',
    'enterpriseValueMultiple',
    'priceFairValue',
  ],
  profitability: [
    'grossProfitMargin',
    'operatingProfitMargin',
    'pretaxProfitMargin',
    'netProfitMargin',
    'returnOnAssets',
    'returnOnEquity',
    'returnOnCapitalEmployed',
    'netIncomePerEBT',
    'ebtPerEbit',
    'ebitPerRevenue',
  ],
  efficiency: [
    'daysOfSalesOutstanding',
    'daysOfInventoryOutstanding',
    'operatingCycle',
    'daysOfPayablesOutstanding',
    'cashConversionCycle',
    'receivablesTurnover',
    'payablesTurnover',
    'inventoryTurnover',
    'fixedAssetTurnover',
    'assetTurnover',
  ],
  management: [
    'effectiveTaxRate',
    'interestCoverage',
    'cashFlowToDebtRatio',
    'companyEquityMultiplier',
  ],
  health: [
    'currentRatio',
    'quickRatio',
    'cashRatio',
    'debtRatio',
    'debtEquityRatio',
    'longTermDebtToCapitalization',
    'totalDebtToCapitalization',
    'cashFlowCoverageRatios',
    'shortTermCoverageRatios',
    // 'capitalExpenditureCoverageRatio',
  ],
  // cashFlow: [
  //   'operatingCashFlowPerShare',
  //   'freeCashFlowPerShare',
  //   'cashPerShare',
  //   'operatingCashFlowSalesRatio',
  //   // 'freeCashFlowOperatingCashFlowRatio',
  // ],
  // dividends: [
  //   'payoutRatio',
  //   'dividendYield',
  //   // 'dividendPaidAndCapexCoverageRatio',
  //   'dividendPayoutRatio',
  // ],
}

export const financialMetrics = [
  {
    label: 'Current Ratio',
    metric: 'currentRatio',
  },
  {
    label: 'Quick Ratio',
    metric: 'quickRatio',
  },
  {
    label: 'Cash Ratio',
    metric: 'cashRatio',
  },
  {
    label: 'Days of Sales Outstanding',
    metric: 'daysOfSalesOutstanding',
  },
  {
    label: 'Days of Inventory Outstanding',
    metric: 'daysOfInventoryOutstanding',
  },
  {
    label: 'Operating Cycle',
    metric: 'operatingCycle',
  },
  {
    label: 'Days of Payables Outstanding',
    metric: 'daysOfPayablesOutstanding',
  },
  {
    label: 'Cash Conversion Cycle',
    metric: 'cashConversionCycle',
  },
  {
    label: 'Gross Profit Margin',
    metric: 'grossProfitMargin',
  },
  {
    label: 'Operating Profit Margin',
    metric: 'operatingProfitMargin',
  },
  {
    label: 'Pretax Profit Margin',
    metric: 'pretaxProfitMargin',
  },
  {
    label: 'Net Profit Margin',
    metric: 'netProfitMargin',
  },
  {
    label: 'Effective Tax Rate',
    metric: 'effectiveTaxRate',
  },
  {
    label: 'Return on Assets',
    metric: 'returnOnAssets',
  },
  {
    label: 'Return on Equity',
    metric: 'returnOnEquity',
  },
  {
    label: 'Return on Capital Employed',
    metric: 'returnOnCapitalEmployed',
  },
  {
    label: 'Net Income per EBT',
    metric: 'netIncomePerEBT',
  },
  {
    label: 'EBT per EBIT',
    metric: 'ebtPerEbit',
  },
  {
    label: 'EBIT per Revenue',
    metric: 'ebitPerRevenue',
  },
  {
    label: 'Debt Ratio',
    metric: 'debtRatio',
  },
  {
    label: 'Debt Equity Ratio',
    metric: 'debtEquityRatio',
  },
  {
    label: 'Long Term Debt to Capitalization',
    metric: 'longTermDebtToCapitalization',
  },
  {
    label: 'Total Debt to Capitalization',
    metric: 'totalDebtToCapitalization',
  },
  {
    label: 'Interest Coverage',
    metric: 'interestCoverage',
  },
  {
    label: 'Cash Flow to Debt Ratio',
    metric: 'cashFlowToDebtRatio',
  },
  {
    label: 'Company Equity Multiplier',
    metric: 'companyEquityMultiplier',
  },
  {
    label: 'Receivables Turnover',
    metric: 'receivablesTurnover',
  },
  {
    label: 'Payables Turnover',
    metric: 'payablesTurnover',
  },
  {
    label: 'Inventory Turnover',
    metric: 'inventoryTurnover',
  },
  {
    label: 'Fixed Asset Turnover',
    metric: 'fixedAssetTurnover',
  },
  {
    label: 'Asset Turnover',
    metric: 'assetTurnover',
  },
  {
    label: 'Operating Cash Flow per Share',
    metric: 'operatingCashFlowPerShare',
  },
  {
    label: 'Free Cash Flow per Share',
    metric: 'freeCashFlowPerShare',
  },
  {
    label: 'Cash per Share',
    metric: 'cashPerShare',
  },
  {
    label: 'Payout Ratio',
    metric: 'payoutRatio',
  },
  {
    label: 'Operating Cash Flow Sales Ratio',
    metric: 'operatingCashFlowSalesRatio',
  },
  {
    label: 'Free Cash Flow Operating Cash Flow Ratio',
    metric: 'freeCashFlowOperatingCashFlowRatio',
  },
  {
    label: 'Cash Flow Coverage Ratios',
    metric: 'cashFlowCoverageRatios',
  },
  {
    label: 'Short Term Coverage Ratios',
    metric: 'shortTermCoverageRatios',
  },
  {
    label: 'Capital Expenditure Coverage Ratio',
    metric: 'capitalExpenditureCoverageRatio',
  },
  {
    label: 'Dividend Paid and Capex Coverage Ratio',
    metric: 'dividendPaidAndCapexCoverageRatio',
  },
  {
    label: 'Dividend Payout Ratio',
    metric: 'dividendPayoutRatio',
  },
  {
    label: 'Price Book Value Ratio',
    metric: 'priceBookValueRatio',
  },
  {
    label: 'Price to Book Ratio',
    metric: 'priceToBookRatio',
  },
  {
    label: 'Price to Sales Ratio',
    metric: 'priceToSalesRatio',
  },
  {
    label: 'Price Earnings Ratio',
    metric: 'priceEarningsRatio',
  },
  {
    label: 'Price to Free Cash Flows Ratio',
    metric: 'priceToFreeCashFlowsRatio',
  },
  {
    label: 'Price to Operating Cash Flows Ratio',
    metric: 'priceToOperatingCashFlowsRatio',
  },
  {
    label: 'Price Cash Flow Ratio',
    metric: 'priceCashFlowRatio',
  },
  {
    label: 'Price Earnings to Growth Ratio',
    metric: 'priceEarningsToGrowthRatio',
  },
  {
    label: 'Price Sales Ratio',
    metric: 'priceSalesRatio',
  },
  {
    label: 'Dividend Yield',
    metric: 'dividendYield',
  },
  {
    label: 'Enterprise Value Multiple',
    metric: 'enterpriseValueMultiple',
  },
  {
    label: 'Price Fair Value',
    metric: 'priceFairValue',
  },
]
