import axios from 'axios'
import { Auth } from 'aws-amplify'
import {
  PAGE_FILTER_CHANGED,
  PAGE_SIZE_CHANGED,
  PAGE_SORT_CHANGED,
  TOTAL_COUNT_CHANGED,
  VISIBLE_COLUMNS_CHANGED,
  PAGE_CHANGED,
  ORDERBY_ASC,
  ORDERBY_DESC,
} from './constants'
import { APP_CONFIG } from '../../config'
import numeral from 'numeral'
import { ImageError } from 'core/ImageError/ImageError'
import { countryCodes } from './components/MetricCatalog/countryCodes'

export const reducer = (state, { type, payload }) => {
  switch (type) {
    case PAGE_CHANGED:
      return {
        ...state,
        queryPageIndex: payload,
      }
    case PAGE_SIZE_CHANGED:
      return {
        ...state,
        queryPageSize: payload,
      }
    case PAGE_SORT_CHANGED:
      return {
        ...state,
        queryPageSortBy: payload,
      }
    case PAGE_FILTER_CHANGED:
      return {
        ...state,
        queryPageFilter: payload,
      }
    case TOTAL_COUNT_CHANGED:
      return {
        ...state,
        totalCount: payload,
      }
    case VISIBLE_COLUMNS_CHANGED:
      return {
        ...state,
        queryVisibleColumns: payload,
      }
    default:
      throw new Error(`Unhandled action type: ${type}`)
  }
}

export const transformSortBy = config => {
  if (config?.id) {
    return {
      column: config?.id,
      direction: config?.desc ? ORDERBY_DESC : ORDERBY_ASC,
    }
  }
}

export const fetchScreenerData = async (
  page,
  pageSize,
  pageFilter,
  pageSortBy,
  visibleColumns
) => {
  if (pageFilter.length > 1) {
  }
  if (pageSortBy.length > 0) {
  }
  try {
    if (visibleColumns) {
      const body = {
        filters: pageFilter,
        orderBy: transformSortBy(pageSortBy[0]),
        activeColumns: visibleColumns,
        pageIndex: page,
        pageSize: pageSize,
      }

      let authResp = await Auth.currentSession()
      let accessToken = authResp.getAccessToken()
      let jwt = accessToken.getJwtToken()

      let createUserConfig = {
        method: 'post',
        url: APP_CONFIG.IN_HOUSE_ORIGIN + 'papi/screener/',
        headers: {
          Authorization: 'Bearer ' + jwt,
          'Content-Type': 'application/json',
        },
        data: {
          ...body,
        },
      }

      let resp = await axios(createUserConfig)

      const results = resp?.data?.items
      const totalRowCount = resp?.data?.totalRowCount
      const data = {
        results: results,
        count: totalRowCount,
      }
      return data
    }

    return
  } catch (e) {
    throw new Error(`API error:${e?.message}`)
  }
}

export const fetchDropdownOptions = async () => {
  try {
    let authResp = await Auth.currentSession()
    let accessToken = authResp.getAccessToken()
    let jwt = accessToken.getJwtToken()

    let createUserConfig = {
      method: 'get',
      url: APP_CONFIG.IN_HOUSE_ORIGIN + 'papi/screener/options',
      headers: {
        Authorization: 'Bearer ' + jwt,
        'Content-Type': 'application/json',
      },
      data: {},
    }

    let resp = await axios(createUserConfig)
    return resp.data.message
  } catch (e) {
    throw new Error(`API error:${e?.message}`)
  }
}

export const presetPanelWidth = () => {
  const div = document.createElement('div')
  div.style.visibility = 'hidden'
  div.style.position = 'absolute'
  div.style.top = '-9999px'
  div.style.overflow = 'scroll'
  div.style.width = '50px'
  div.style.height = '50px'
  document.body.appendChild(div)
  const scrollbarWidth = div.offsetWidth - div.clientWidth
  document.body.removeChild(div)
  return scrollbarWidth
}

export const formatCell = cell => {
  if (cell?.column?.Header === 'line') {
    return null
  }

  const format =
    typeof cell?.value === 'number' ? (
      numeral(cell.value).format('0.00 a')
    ) : cell?.value?.includes('image') ? (
      <ImageError src={cell.value} size={20} />
    ) : cell?.column?.Header === 'Country' ? (
      countryCodes[cell?.value]
    ) : (
      cell.value
    )

  if (cell.value === 0 || cell.value === null || cell.value === undefined) {
    return '-'
  } else {
    return format
  }
}

const fixedWidthClass = j => {
  if (j === 0) {
    return 'fixed-width-column0'
  } else if (j === 1) {
    return 'fixed-width-column1'
  } else if (j === 2) {
    return 'fixed-width-column2'
  } else if (j === 3) {
    return 'fixed-width-column3'
  } else {
    return ''
  }
}

const getHeaderClassName = index => {
  const baseClass = 'screenerHeader'
  if (index === 0) return `${baseClass} firstColumnHeader`
  if (index === 1) return `${baseClass} secondColumnHeader`
  if (index === 2) return `${baseClass} thirdColumnHeader`

  return baseClass
}

const getCellStyle = (isRowHovered, isSticky, i) => ({
  height: '24px',
  fontSize: '12px',
  position: isSticky ? 'sticky' : 'static',
  left: i === 1 ? 36 : i === 2 ? 146 : 0,
  overflow: i !== 2 && 'hidden',
  color: 'var(--white)',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  zIndex: isSticky ? 10 : 'auto',
  backgroundColor: isRowHovered
    ? 'var(--background-secondary)'
    : 'var(--background-primary)',
  padding: i === 2 && 0,
})

const tableHeadStyles = {
  position: 'sticky',
  top: '0px',
  margin: '0 0 0 0',
  borderBottom: '2px solid var(--background-secondary)',
  backgroundColor: 'var(--background-secondary)',
  padding: '8px 8px 8px 0px',
  width: 'fit-content',
  color: 'var(--white)',
  zIndex: 19,
  fontSize: '13px',
}

const tableHeight = (isView, isDropdownVisible, filters) => {
  if (isView) {
    const baseHeight =
      filters.length > 0
        ? isDropdownVisible
          ? 'calc(100vh - 140px)'
          : 'calc(100vh - 200px)'
        : 'calc(100vh - 166px)'
    let heightAdjustment = 0

    if (isDropdownVisible) {
      heightAdjustment += filters.length > 0 ? 300 : 240
    }

    return `calc(${baseHeight} - ${heightAdjustment}px)`
  }
  const baseHeight = '460px'
  let heightAdjustment = 0

  if (isDropdownVisible) {
    heightAdjustment += 240
  }

  if (filters.length > 0) {
    heightAdjustment += 30
  }

  return `calc(${baseHeight} - ${heightAdjustment}px)`
}

const metricCatalogHeight = (isView, isDropdownVisible, filters) => {
  return isDropdownVisible
    ? isView
      ? filters?.length > 0
        ? 304
        : 270
      : filters?.length > 0
      ? 304
      : 270
    : filters?.length > 0
    ? 64
    : 30
}

export const utils = {
  formatCell,
  fixedWidthClass,
  getHeaderClassName,
  getCellStyle,
  tableHeadStyles,
  tableHeight,
  metricCatalogHeight,
}
