export const organized = {
  NorthAmerica: [
    'S&P 500',
    'Dow Jones Industrial Average',
    'NASDAQ',
    'S&P/TSX Composite Index',
    'IPC Mexico',
  ],
  Europe: [
    'IBEX 35',
    'FTSE 100',
    'MOEX Russia Index',
    'FTSE Italia All-Share Index',
    'CAC 40',
    'EURONEXT 100',
    'BEL 20',
    'FTSE MIB Index',
    'ATX',
    'DAX Index',
    'Swiss Market Index',
    'OMX Helsinki 25',
    'OMX Copenhagen 20',
    'Oslo Bors All-Share Index_GI',
  ],
  Asia: [
    'Nikkei 225',
    'Hang Seng',
    'SSE Composite Index',
    'KOSPI Composite Index',
    'Jakarta Composite Index',
    'Nifty 50',
    'Tadawul All Shares Index',
    'TA-125',
    'KOSPI 200 Index',
    'Nikkei 300',
    'TSEC weighted index',
    'SET Index',
    'S&P BSE SENSEX',
  ],
  Africa: ['EGX 30 Price Return Index'],
  SouthAmerica: ['MERVAL'],
  Oceania: ['S&P/NZX 50', 'S&P/ASX 200', 'FTSE/JSE Top 40 Index'],
}
