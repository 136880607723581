import React, { useState, useMemo, useEffect, useRef, useCallback } from 'react'
import { useTable } from 'react-table'
import styles from './fnTable.module.scss'
import Spinner from 'core/ScrollableContainer/Spinner/spinner'
import { ToolBar } from './ToolBar'
import {
  formatNumericalValues,
  staySameValue,
  formatStaySameValues,
  calculateCAGR,
  handleExportTable,
} from './utils'
import { BarChart, LineChart } from './DthreeMiniChart'
import { useFinancialStatementsContext } from '../../FinancialStatementsContext'
import { Table } from './Table'
import { CoreLineChart, CoreBarChart } from 'core/Charts/GenericChart'
import { ChartPills } from 'pages/views/MarketOverview/components/SelectableTableWithChart/utils'
import { RANDOMCHARTCOLORS } from 'constants/theme'
import Fuse from 'fuse.js'
import { UilLock } from '@iconscout/react-unicons'
import { displayPeriod } from '../FNTable/columns'
import { useUserGroup } from 'hooks/userGroupHook'

const FNtable = ({
  annualData,
  quarterlyData,
  height,
  columnKeys,
  ticker,
  type,
  ratios,
  showChart,
  tableId,
}) => {
  const { UpgradeTable } = useUserGroup()
  const tableRef = useRef(0)
  const [selectedRows, setSelectedRows] = useState([])
  const [searchText, setSearchText] = useState('')
  const { state, updateToolbarState } = useFinancialStatementsContext()
  const {
    isReversed,
    unit,
    decimals,
    showZeroValues,
    chartType,
    range,
    dataType,
    cagrActive,
  } = state.toolbarState || {}

  const data = useMemo(
    () => (dataType === 'quarterly' ? quarterlyData : annualData),
    [dataType, quarterlyData, annualData]
  )

  const handleReverse = useCallback(() => {
    updateToolbarState({ isLoading: true })
    setTimeout(() => {
      updateToolbarState({
        isReversed: !isReversed,
        isLoading: false,
      })
    }, 0)
  }, [isReversed, updateToolbarState])

  let dataInRange = useMemo(
    () => data?.slice(range?.[0] || 0, range?.[1] || data?.length),
    [data, range]
  )

  let transformedData = useMemo(() => {
    if (!columnKeys) return []
    return columnKeys.map(keyObj => {
      const newObj = { property: keyObj?.Header, accessor: keyObj?.accessor }
      let chartData = []

      dataInRange?.forEach((item, index) => {
        let value = staySameValue?.includes(keyObj?.accessor)
          ? formatStaySameValues({
              value: item[keyObj?.accessor],
              decimals,
            })
          : isNaN(item[keyObj?.accessor])
          ? item[keyObj?.accessor]
          : formatNumericalValues({
              value: item[keyObj?.accessor],
              decimals,
              unit,
              showZeroValues,
            })
        newObj[`item${index}`] = {
          value: value,
          rowOriginal: item,
          accessor: keyObj?.accessor,
        }
        chartData?.push({
          name: `item${index}`,
          value: item[keyObj?.accessor],
        })
      })
      newObj.chartData = chartData?.reverse()
      let firstYearValue =
        dataInRange?.[dataInRange?.length - 1]?.[keyObj?.accessor]
      let lastYearValue = dataInRange?.[0]?.[keyObj?.accessor]
      let years = dataInRange?.length
      newObj.CAGR = calculateCAGR(firstYearValue, lastYearValue, years)

      return newObj
    })
  }, [columnKeys, dataInRange, decimals, unit, showZeroValues])

  const columns = useMemo(() => {
    const cols = [
      {
        Header: '',
        accessor: 'property',
        Cell: ({ value }) => {
          return <div className={styles.firstColumn}>{value}</div>
        },
      },
      {
        Header: '',
        id: 'miniLineChart',
        Cell: ({ row }) => {
          let nthRate = 1
          if (row?.original?.chartData.length > 90) {
            nthRate = 8
          } else if (row?.original?.chartData.length > 30) {
            nthRate = 2
          }
          const filteredData = row?.original?.chartData.filter(
            (_, index) => index % nthRate === 0
          )
          const allZero =
            filteredData.length > 0 &&
            filteredData.every(item => item.value === 0)
          if (allZero) {
            return null
          }
          return chartType === 'bar' ? (
            <BarChart data={filteredData} key={chartType} />
          ) : (
            <LineChart data={filteredData} key={chartType} />
          )
        },
      },
    ]

    if (cagrActive) {
      cols.push({
        Header: 'CAGR',
        accessor: 'CAGR',
        Cell: ({ value }) => {
          const numericValue = parseFloat(value)
          const textColor =
            numericValue > 0
              ? 'var(--green)'
              : numericValue < 0
              ? 'var(--red)'
              : 'var(--off-white)'
          const displayValue =
            !numericValue ||
            numericValue === 0 ||
            isNaN(numericValue) ||
            numericValue === Infinity
              ? '-'
              : `${value}%`

          return (
            <span
              style={{
                color: textColor,
                padding: '0 8px',
              }}
            >
              {displayValue}
            </span>
          )
        },
      })
    }
    cols.push({
      Header: '',
      id: 'stick',
      Cell: ({ value }) => {
        return <div className={styles.lemon}>{value}</div>
      },
    })

    for (let i = 0; i < dataInRange?.length; i++) {
      const index = isReversed ? dataInRange?.length - 1 - i : i
      cols.push({
        Header: ``,
        accessor: `item${index}`,
        Cell: ({ value }) => {
          const isPeriodData = value?.accessor === 'period'
          if (isPeriodData) {
            return displayPeriod(value, value?.rowOriginal)
          } else if (value?.rowOriginal?.isLocked) {
            return <UpgradeTable />
          } else {
            return (
              <span>
                {value && value?.Cell
                  ? value.Cell(value)
                  : value
                  ? value?.value
                  : ''}
              </span>
            )
          }
        },
      })
    }

    return cols
  }, [cagrActive, chartType, dataInRange?.length, isReversed])

  const fuse = new Fuse(transformedData.slice(1), {
    keys: ['property'],
    includeScore: true,
  })

  const filteredData = useMemo(() => {
    if (searchText.trim() === '') {
      return transformedData
    } else {
      return [
        transformedData[0],
        ...fuse.search(searchText).map(({ item }) => item),
      ]
    }
  }, [searchText, transformedData])

  const { getTableProps, getTableBodyProps, rows, prepareRow } = useTable({
    columns,
    data: filteredData,
  })

  useEffect(() => {
    const table = tableRef?.current
    const tdElements = table?.getElementsByTagName('td')

    for (let i = 0; i < tdElements?.length; i++) {
      const cell = tdElements[i]
      cell?.addEventListener('mouseover', e => {
        const cellIndex = cell?.cellIndex

        if (cellIndex >= 3) {
          for (let j = 0; j < tdElements?.length; j++) {
            const currentCell = tdElements[j]
            if (currentCell?.cellIndex === cellIndex) {
              currentCell?.classList?.add('highlight')
            }
          }
        }
      })

      cell.addEventListener('mouseout', e => {
        for (let j = 0; j < tdElements?.length; j++) {
          tdElements[j]?.classList?.remove('highlight')
        }
      })
    }
  }, [rows])

  return (
    <div
      className={styles.BlockTable}
      style={{
        height: '100%',
        maxHeight: height,
      }}
    >
      <ToolBar
        data={data}
        handleReverse={handleReverse}
        handleExportToExcel={() =>
          handleExportTable(dataInRange, columnKeys, ticker, type)
        }
        ratios={ratios}
        setSearchText={setSearchText}
        searchValue={searchText}
      />
      {state.isLoading ? (
        <Spinner />
      ) : (
        <>
          <Table
            getTableProps={getTableProps}
            rows={rows}
            prepareRow={prepareRow}
            tableRef={tableRef}
            getTableBodyProps={getTableBodyProps}
            dataInRange={dataInRange}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            canSelectRows={showChart}
            tableId={tableId}
            columnKeys={columnKeys}
          />
          {showChart && (
            <>
              <div className={styles.metricRow}>
                <ChartPills
                  noPadding={true}
                  metrics={selectedRows.map((metric, index) => ({
                    key: metric,
                    color: RANDOMCHARTCOLORS[index % RANDOMCHARTCOLORS.length],
                  }))}
                  handleDeleteTrend={({ key }) => {
                    setSelectedRows(
                      selectedRows.filter(
                        selectedMetric => selectedMetric !== key
                      )
                    )
                  }}
                />
              </div>
              <div
                className={styles.chartContainer}
                style={{ height: 'calc(100vh - 500px)' }}
              >
                {selectedRows.length === 0 ? (
                  <div className={styles.noRows}>
                    <span className={styles.noDataText}>
                      Select a row to visualize a metric.
                    </span>
                  </div>
                ) : chartType === 'bar' ? (
                  <CoreBarChart
                    data={dataInRange.reverse()}
                    rightYAxis={true}
                    XAxisKey={'date'}
                    axisWidth={60}
                    metrics={selectedRows.map((metric, index) => ({
                      key: metric,
                      color:
                        RANDOMCHARTCOLORS[index % RANDOMCHARTCOLORS.length],
                    }))}
                    onlyYear={true}
                    showYAxis={true}
                    tickFormat={true}
                  />
                ) : (
                  <CoreLineChart
                    data={dataInRange.reverse()}
                    showSingleYAxis={'right'}
                    XAxisKey={'date'}
                    axisWidth={60}
                    metrics={selectedRows.map((metric, index) => ({
                      type: 'monotone',
                      key: metric,
                      color:
                        RANDOMCHARTCOLORS[index % RANDOMCHARTCOLORS.length],
                    }))}
                    yearOnly={true}
                    decimalCount={2}
                    customTickFormatter
                  />
                )}
              </div>
            </>
          )}
        </>
      )}
    </div>
  )
}

export default FNtable
