import http from 'network/httpClient'
import dayjs from 'dayjs'

const today = dayjs().add(15, 'day').format('YYYY-MM-DD')
const oneMonthAgo = dayjs().subtract(15, 'day').format('YYYY-MM-DD')

const fetchEconomicCalendar = async ({ queryKey }) => {
  const [,] = queryKey
  const data = await http.get(
    `papi/data/api/v3/economic_calendar?from=${oneMonthAgo}&to=${today}`
  )

  return data.data.message
}

const fetchSplitCalendar = async ({ queryKey }) => {
  const [,] = queryKey
  const data = await http.get(
    `papi/data/api/v3/stock_split_calendar?from=${oneMonthAgo}&to=${today}`
  )

  return data.data.message
}

const fetchEarningsCalendar = async ({ queryKey }) => {
  const [,] = queryKey
  const data = await http.get(
    `papi/data/api/v3/earning_calendar?from=${oneMonthAgo}&to=${today}`
  )

  return data.data.message
}

const fetchDividendCalendar = async ({ queryKey }) => {
  const [,] = queryKey
  const data = await http.get(
    `papi/data/api/v3/stock_dividend_calendar?from=${oneMonthAgo}&to=${today}`
  )

  return data.data.message
}

const fetchConfirmedIPOCalendar = async ({ queryKey }) => {
  const [,] = queryKey
  const data = await http.get(
    `papi/data/api/v4/ipo-calendar-confirmed?from=${oneMonthAgo}&to=${today}`
  )

  return data.data.message
}

const fetchProspectusIPOCalendar = async ({ queryKey }) => {
  const [,] = queryKey
  const data = await http.get(`papi/data/api/v4/ipo-calendar-prospectus?`)

  return data.data.message
}

const fetchSP500 = async ({ queryKey }) => {
  const [,] = queryKey
  const data = await http.get(`papi/data/api/v3/sp500_constituent?`)

  return data.data.message
}

export const fetches = {
  fetchEconomicCalendar,
  fetchSplitCalendar,
  fetchEarningsCalendar,
  fetchDividendCalendar,
  fetchConfirmedIPOCalendar,
  fetchProspectusIPOCalendar,
  fetchSP500,
}
