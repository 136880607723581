import styles from './style.module.scss'
import { CardWrapper } from 'core/CardWrapper/CardWrapper'
import Spinner from 'core/ScrollableContainer/Spinner/spinner'
import { Helmet } from 'react-helmet-async'
import { useConfirmedIPOCalendar } from '../../data/hooks'
import CalendarTable from 'core/Tables/CalendarTable'
import { confirmedColumns } from './columns'

export const IPOConfirmed = () => {
  const { CAL_UI } = useConfirmedIPOCalendar()
  return (
    <>
      <Helmet>
        <title>{`Outseek | IPO Confirmed`}</title>
        <meta
          name="description"
          content={
            'IPO calendar provides a list of companies that are expected to release their IPO reports.'
          }
        />
      </Helmet>
      <CardWrapper
        label={'IPO Confirmed'}
        height="100%"
        width="100%"
        content={
          CAL_UI?.confirmedIPOCalIsLoading ? (
            <Spinner />
          ) : (
            <CalendarTable
              data={CAL_UI?.confirmedIPOCal}
              columns={confirmedColumns}
              overflowXScroll={true}
              filterColumnNames={[]}
              groupByKey="filingDate"
            />
          )
        }
      />
    </>
  )
}
