import dayjs from 'dayjs'

const createHeaderWithLink = (Header, accessor) => ({
  Header,
  accessor,
  Cell: ({ value, rowOriginal }) => {
    return (
      <a
        style={{
          color: 'var(--primary-color)',
        }}
        href={rowOriginal?.finalLink}
        target={'_blank'}
        rel="noreferrer"
      >
        <span>{`${value} ${dayjs(rowOriginal?.date).format('YYYY')}`}</span>
      </a>
    )
  },
})

const percentageCell = value => {
  return <span>{value}%</span>
}

const valuationColumns = [
  createHeaderWithLink('Period', 'period'),
  { Header: 'Price to Book Value Ratio', accessor: 'priceToBookRatio' },
  { Header: 'Price to Sales Ratio', accessor: 'priceToSalesRatio' },
  { Header: 'Price to Earnings Ratio', accessor: 'peRatio' },
  { Header: 'Price to Free Cash Flows Ratio', accessor: 'pfcfRatio' },
  { Header: 'Price to Operating Cash Flows Ratio', accessor: 'pocfratio' },
  {
    Header: 'Price Earnings to Growth Ratio',
    accessor: 'priceEarningsToGrowthRatio',
  },
  {
    Header: 'Enterprise Value Multiple',
    accessor: 'enterpriseValueOverEBITDA',
  },
  { Header: 'Price Fair Value', accessor: 'priceFairValue' },
  { Header: 'EV to Free Cash Flow', accessor: 'evToFreeCashFlow' },
  { Header: 'EV to Operating Cash Flow', accessor: 'evToOperatingCashFlow' },
  { Header: 'EV to Sales', accessor: 'evToSales' },
  {
    Header: 'Earnings Yield',
    accessor: 'earningsYield',
    Cell: ({ value }) => percentageCell(value),
  },
  { Header: 'Invested Capital', accessor: 'investedCapital' },
  { Header: 'Net Current Asset Value', accessor: 'netCurrentAssetValue' },
  { Header: 'Tangible Asset Value', accessor: 'tangibleAssetValue' },
  {
    Header: 'Tangible Book Value Per Share',
    accessor: 'tangibleBookValuePerShare',
  },
  { Header: 'Graham Number', accessor: 'grahamNumber' },
  { Header: 'Graham Net Net', accessor: 'grahamNetNet' },
]

const profitabilityColumns = [
  createHeaderWithLink('Period', 'period'),

  {
    Header: 'Gross Profit Margin',
    accessor: 'grossProfitMargin',
    percentage: true,
    Cell: ({ value }) => percentageCell(value),
  },
  {
    Header: 'Operating Profit Margin',
    accessor: 'operatingProfitMargin',
    percentage: true,
    Cell: ({ value }) => percentageCell(value),
  },
  {
    Header: 'Pretax Profit Margin',
    accessor: 'pretaxProfitMargin',
    percentage: true,
    Cell: ({ value }) => percentageCell(value),
  },
  {
    Header: 'Net Profit Margin',
    accessor: 'netProfitMargin',
    percentage: true,
    Cell: ({ value }) => percentageCell(value),
  },
  {
    Header: 'Return on Assets',
    accessor: 'returnOnTangibleAssets',
    percentage: true,
    Cell: ({ value }) => percentageCell(value),
  },
  {
    Header: 'Return on Equity',
    accessor: 'roe',
    percentage: true,
    Cell: ({ value }) => percentageCell(value),
  },
  {
    Header: 'Return on Capital Employed',
    accessor: 'roic',
    percentage: true,
    Cell: ({ value }) => percentageCell(value),
  },
  {
    Header: 'Effective Tax Rate',
    accessor: 'effectiveTaxRate',
    percentage: true,
    Cell: ({ value }) => percentageCell(value),
  },
  { Header: 'Income Quality', accessor: 'incomeQuality' },
  { Header: 'Net Income Per Share', accessor: 'netIncomePerShare' },
  { Header: 'Revenue Per Share', accessor: 'revenuePerShare' },
]

const financialHealthColumns = [
  createHeaderWithLink('Period', 'period'),
  { Header: 'Current Ratio', accessor: 'currentRatio' },
  { Header: 'Quick Ratio', accessor: 'quickRatio' },
  { Header: 'Cash Ratio', accessor: 'cashRatio' },
  { Header: 'Debt To Assets Ratio', accessor: 'debtToAssets' },
  { Header: 'Debt Equity Ratio', accessor: 'debtToEquity' },
  {
    Header: 'Long Term Debt to Capitalization',
    accessor: 'longTermDebtToCapitalization',
  },
  {
    Header: 'Total Debt to Capitalization',
    accessor: 'totalDebtToCapitalization',
  },
  { Header: 'Cash Flow to Debt Ratio', accessor: 'cashFlowToDebtRatio' },
  {
    Header: 'Company Equity Multiplier',
    accessor: 'companyEquityMultiplier',
  },
  { Header: 'Book Value Per Share', accessor: 'bookValuePerShare' },
  { Header: 'Interest Coverage', accessor: 'interestCoverage' },
  { Header: 'Net Debt To EBITDA', accessor: 'netDebtToEBITDA' },
  {
    Header: 'Shareholders Equity Per Share',
    accessor: 'shareholdersEquityPerShare',
  },
  { Header: 'Working Capital', accessor: 'workingCapital' },
  { Header: 'Interest Debt Per Share', accessor: 'interestDebtPerShare' },
]

const efficiencyColumns = [
  createHeaderWithLink('Period', 'period'),
  { Header: 'Days of Sales Outstanding', accessor: 'daysSalesOutstanding' },
  {
    Header: 'Days of Inventory Outstanding',
    accessor: 'daysOfInventoryOnHand',
  },
  { Header: 'Operating Cycle', accessor: 'operatingCycle' },
  {
    Header: 'Days of Payables Outstanding',
    accessor: 'daysPayablesOutstanding',
  },
  { Header: 'Cash Conversion Cycle', accessor: 'cashConversionCycle' },
  { Header: 'Receivables Turnover', accessor: 'receivablesTurnover' },
  { Header: 'Payables Turnover', accessor: 'payablesTurnover' },
  { Header: 'Inventory Turnover', accessor: 'inventoryTurnover' },
  { Header: 'Fixed Asset Turnover', accessor: 'fixedAssetTurnover' },
  { Header: 'Asset Turnover', accessor: 'assetTurnover' },
  { Header: 'Capex Per Share', accessor: 'capexPerShare' },
  { Header: 'Capex To Depreciation', accessor: 'capexToDepreciation' },
  {
    Header: 'Capex To Operating Cash Flow',
    accessor: 'capexToOperatingCashFlow',
  },
  { Header: 'Capex To Revenue', accessor: 'capexToRevenue' },
  { Header: 'Average Inventory', accessor: 'averageInventory' },
  { Header: 'Average Payables', accessor: 'averagePayables' },
  { Header: 'Average Receivables', accessor: 'averageReceivables' },
]

const dividendsColumns = [
  createHeaderWithLink('Period', 'period'),
  { Header: 'Dividend Yield', accessor: 'dividendYield' },
  {
    Header: 'Dividend Paid And Capex Coverage Ratio',
    accessor: 'dividendPaidAndCapexCoverageRatio',
  },
  { Header: 'Dividend Payout Ratio', accessor: 'dividendPayoutRatio' },
  { Header: 'Payout Ratio', accessor: 'payoutRatio' },
]

const managementEffectivenessColumns = [
  createHeaderWithLink('Period', 'period'),
  { Header: 'Net Income Per EBT', accessor: 'netIncomePerEBT' },
  { Header: 'EBT Per EBIT', accessor: 'ebtPerEbit' },
  { Header: 'EBIT Per Revenue', accessor: 'ebitPerRevenue' },
  {
    Header: 'Operating Cash Flow Per Share',
    accessor: 'operatingCashFlowPerShare',
  },
  { Header: 'Free Cash Flow Per Share', accessor: 'freeCashFlowPerShare' },
  { Header: 'Cash Per Share', accessor: 'cashPerShare' },
  {
    Header: 'Operating Cash Flow Sales Ratio',
    accessor: 'operatingCashFlowSalesRatio',
  },
  {
    Header: 'Free Cash Flow Operating Cash Flow Ratio',
    accessor: 'freeCashFlowOperatingCashFlowRatio',
  },
  {
    Header: 'Cash Flow Coverage Ratios',
    accessor: 'cashFlowCoverageRatios',
  },
  {
    Header: 'Short Term Coverage Ratios',
    accessor: 'shortTermCoverageRatios',
  },
  {
    Header: 'Capex Coverage Ratio',
    accessor: 'capitalExpenditureCoverageRatio',
  },
  {
    Header: 'Research And Development To Revenue',
    accessor: 'researchAndDdevelopementToRevenue',
  },
  {
    Header: 'Stock Based Compensation To Revenue',
    accessor: 'stockBasedCompensationToRevenue',
  },
]

const growthColumns = [
  createHeaderWithLink('Period', 'period'),
  {
    Header: 'Revenue Growth',
    accessor: 'revenueGrowth',
    Cell: ({ value }) => percentageCell(value),
  },
  {
    Header: 'Gross Profit Growth',
    accessor: 'grossProfitGrowth',
    Cell: ({ value }) => percentageCell(value),
  },
  {
    Header: 'EBIT Growth',
    accessor: 'ebitgrowth',
    Cell: ({ value }) => percentageCell(value),
  },
  {
    Header: 'Operating Income Growth',
    accessor: 'operatingIncomeGrowth',
    Cell: ({ value }) => percentageCell(value),
  },
  {
    Header: 'Net Income Growth',
    accessor: 'netIncomeGrowth',
    Cell: ({ value }) => percentageCell(value),
  },
  {
    Header: 'EPS Growth',
    accessor: 'epsgrowth',
    Cell: ({ value }) => percentageCell(value),
  },
  {
    Header: 'Diluted EPS Growth',
    accessor: 'epsdilutedGrowth',
    Cell: ({ value }) => percentageCell(value),
  },
  {
    Header: 'Weighted Average Shares Growth',
    accessor: 'weightedAverageSharesGrowth',
    Cell: ({ value }) => percentageCell(value),
  },
  {
    Header: 'Weighted Average Shares Diluted Growth',
    accessor: 'weightedAverageSharesDilutedGrowth',
    Cell: ({ value }) => percentageCell(value),
  },
  {
    Header: 'Dividends per Share Growth',
    accessor: 'dividendsperShareGrowth',
    Cell: ({ value }) => percentageCell(value),
  },
  {
    Header: 'Operating Cash Flow Growth',
    accessor: 'operatingCashFlowGrowth',
    Cell: ({ value }) => percentageCell(value),
  },
  {
    Header: 'Free Cash Flow Growth',
    accessor: 'freeCashFlowGrowth',
    Cell: ({ value }) => percentageCell(value),
  },
  {
    Header: '10 Year Revenue Growth per Share',
    accessor: 'tenYRevenueGrowthPerShare',
    Cell: ({ value }) => percentageCell(value),
  },
  {
    Header: '5 Year Revenue Growth per Share',
    accessor: 'fiveYRevenueGrowthPerShare',
    Cell: ({ value }) => percentageCell(value),
  },
  {
    Header: '3 Year Revenue Growth per Share',
    accessor: 'threeYRevenueGrowthPerShare',
    Cell: ({ value }) => percentageCell(value),
  },
  {
    Header: '10 Year Operating Cash Flow Growth per Share',
    accessor: 'tenYOperatingCFGrowthPerShare',
    Cell: ({ value }) => percentageCell(value),
  },
  {
    Header: '5 Year Operating Cash Flow Growth per Share',
    accessor: 'fiveYOperatingCFGrowthPerShare',
    Cell: ({ value }) => percentageCell(value),
  },
  {
    Header: '3 Year Operating Cash Flow Growth per Share',
    accessor: 'threeYOperatingCFGrowthPerShare',
    Cell: ({ value }) => percentageCell(value),
  },
  {
    Header: '10 Year Net Income Growth per Share',
    accessor: 'tenYNetIncomeGrowthPerShare',
    Cell: ({ value }) => percentageCell(value),
  },
  {
    Header: '5 Year Net Income Growth per Share',
    accessor: 'fiveYNetIncomeGrowthPerShare',
    Cell: ({ value }) => percentageCell(value),
  },
  {
    Header: '3 Year Net Income Growth per Share',
    accessor: 'threeYNetIncomeGrowthPerShare',
    Cell: ({ value }) => percentageCell(value),
  },
  {
    Header: "10 Year Shareholders' Equity Growth per Share",
    accessor: 'tenYShareholdersEquityGrowthPerShare',
    Cell: ({ value }) => percentageCell(value),
  },
  {
    Header: "5 Year Shareholders' Equity Growth per Share",
    accessor: 'fiveYShareholdersEquityGrowthPerShare',
    Cell: ({ value }) => percentageCell(value),
  },
  {
    Header: "3 Year Shareholders' Equity Growth per Share",
    accessor: 'threeYShareholdersEquityGrowthPerShare',
    Cell: ({ value }) => percentageCell(value),
  },
  {
    Header: '10 Year Dividend per Share Growth per Share',
    accessor: 'tenYDividendperShareGrowthPerShare',
    Cell: ({ value }) => percentageCell(value),
  },
  {
    Header: '5 Year Dividend per Share Growth per Share',
    accessor: 'fiveYDividendperShareGrowthPerShare',
    Cell: ({ value }) => percentageCell(value),
  },
  {
    Header: '3 Year Dividend per Share Growth per Share',
    accessor: 'threeYDividendperShareGrowthPerShare',
    Cell: ({ value }) => percentageCell(value),
  },
  {
    Header: 'Receivables Growth',
    accessor: 'receivablesGrowth',
    Cell: ({ value }) => percentageCell(value),
  },
  {
    Header: 'Inventory Growth',
    accessor: 'inventoryGrowth',
    Cell: ({ value }) => percentageCell(value),
  },
  {
    Header: 'Asset Growth',
    accessor: 'assetGrowth',
    Cell: ({ value }) => percentageCell(value),
  },
  {
    Header: 'Book Value per Share Growth',
    accessor: 'bookValueperShareGrowth',
    Cell: ({ value }) => percentageCell(value),
  },
  {
    Header: 'Debt Growth',
    accessor: 'debtGrowth',
    Cell: ({ value }) => percentageCell(value),
  },
  {
    Header: 'R&D Expense Growth',
    accessor: 'rdexpenseGrowth',
    Cell: ({ value }) => percentageCell(value),
  },
  {
    Header: 'SG&A Expenses Growth',
    accessor: 'sgaexpensesGrowth',
    Cell: ({ value }) => percentageCell(value),
  },
]

export const columnKeys = {
  valuationColumns,
  profitabilityColumns,
  financialHealthColumns,
  efficiencyColumns,
  dividendsColumns,
  managementEffectivenessColumns,
  growthColumns,
}
