import React, { useCallback, useMemo, useState, useEffect } from 'react'
import styles from './styles.module.scss'
import { useDataSource } from './data/hooks'
import { CardWrapper } from 'core/CardWrapper/CardWrapper'
import { globeData } from './constants'
import { WorldIndexCharts } from './components/WorldIndexCharts'
import { Map } from './components/Map/Map'
import { Accordion } from './components/Accordion/Accordion'
import { organized } from './organizedIndexes'
import { RANDOMCHARTCOLORS } from 'constants/theme'
import { NoData } from 'core/NoData/NoData'
import Spinner from 'core/ScrollableContainer/Spinner/spinner'

export const Indexes = () => {
  const [metrics, setMetrics] = useState(() => {
    const savedMetrics = localStorage.getItem('worldIndexMetrics')
    return savedMetrics
      ? JSON.parse(savedMetrics)
      : [
          { key: '^GSPC', name: 'S&P 500', color: 'var(--primary-color)' },
          {
            key: '^DJI',
            name: 'Dow Jones Industrial Average',
            color: 'var(--primary-color)',
          },
          { key: '^IXIC', name: 'NASDAQ', color: 'var(--primary-color)' },
        ]
  })

  useEffect(() => {
    localStorage.setItem('worldIndexMetrics', JSON.stringify(metrics))
  }, [metrics])

  const { Index_UI, interval, setInterval } = useDataSource({ metrics })

  const [position, setPosition] = useState({ coordinates: [0, 0], zoom: 1 })

  const handleZoomIn = () => {
    if (position.zoom >= 5) return
    setPosition(pos => ({ ...pos, zoom: pos.zoom * 2 }))
  }

  const handleZoomOut = () => {
    if (position.zoom <= 1) return
    setPosition(pos => ({ ...pos, zoom: pos.zoom / 2 }))
  }

  const handleMoveEnd = position => {
    setPosition(position)
  }

  const zoomOnCoordinates = coordinates => {
    setPosition({ coordinates, zoom: 5 })
  }

  const handleResetZoom = () => {
    setPosition({ coordinates: [0, 0], zoom: 1 })
  }

  const enrichedGlobeData = useMemo(() => {
    return globeData?.map(globeEntry => {
      const indexDataEntry = Index_UI?.indexData?.find(
        ({ symbol }) => symbol === globeEntry.type
      )
      return {
        ...globeEntry,
        change: indexDataEntry ? indexDataEntry?.changesPercentage : null,
        price: indexDataEntry ? indexDataEntry?.price : null,
        changeValue: indexDataEntry ? indexDataEntry?.change : null,
      }
    })
  }, [Index_UI.indexData])

  const handleMetricToggle = useCallback(
    metricSymbol => {
      setMetrics(prevMetrics => {
        const isMetricSelected = prevMetrics.some(
          metric => metric.key === metricSymbol
        )
        if (isMetricSelected) {
          return prevMetrics.filter(metric => metric.key !== metricSymbol)
        } else {
          return [
            ...prevMetrics,
            {
              key: metricSymbol,
              name: globeData.find(entry => entry.type === metricSymbol).name,
              color:
                RANDOMCHARTCOLORS[metrics.length % RANDOMCHARTCOLORS.length],
            },
          ]
        }
      })
    },
    [metrics.length]
  )

  const mapProps = {
    data: enrichedGlobeData,
    handleZoomIn,
    handleZoomOut,
    handleResetZoom,
    position,
    handleMoveEnd,
    zoomOnCoordinates,
    metrics,
  }

  return (
    <div className={styles.indexWrapper}>
      <div className={styles.topWrapper}>
        <CardWrapper
          label={'World Map'}
          height={'100%'}
          width={'calc(100% - 408px)'}
          content={
            Index_UI?.indexIsLoading ? (
              <Spinner />
            ) : enrichedGlobeData.length === 0 ? (
              <NoData label="No Map Data Available" />
            ) : (
              <Map {...mapProps} />
            )
          }
        />
        <CardWrapper
          label={'World Indexes'}
          height={'100%'}
          width={'400px'}
          content={
            Index_UI?.indexIsLoading ? (
              <Spinner />
            ) : enrichedGlobeData.length === 0 ? (
              <NoData label="No Index Data Available" />
            ) : (
              <Accordion
                data={enrichedGlobeData}
                organizedMetrics={organized}
                onClick={zoomOnCoordinates}
                onMetricToggle={handleMetricToggle}
                metrics={metrics}
              />
            )
          }
        />
      </div>
      <CardWrapper
        label={'Index Performance Chart'}
        height={'260px'}
        width={'100%'}
        content={
          <WorldIndexCharts
            data={Index_UI?.historicalData}
            isLoading={Index_UI?.historicalDataIsLoading}
            setInterval={setInterval}
            interval={interval}
            metrics={metrics}
            setMetrics={setMetrics}
          />
        }
      />
    </div>
  )
}
