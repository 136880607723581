import React, { createContext, useContext, useState, useEffect } from 'react'
import { FinancialStatements } from './constant'

const FinancialStatementsContext = createContext()

export const useFinancialStatementsContext = () =>
  useContext(FinancialStatementsContext)

export const FinancialStatementsProvider = ({ children }) => {
  const initialState = JSON.parse(
    localStorage.getItem('financialStatementsContext')
  ) || {
    lotsOfChartType: 'bar',
    showChart: false,
    redGreen: false,
    selectedStatement: FinancialStatements?.[0],
    chartType: 'sankey',
    chartView: 'sankey',
    toolbarState: {
      isReversed: false,
      isLoading: false,
      unit: 'default',
      decimals: 2,
      showZeroValues: true,
      chartType: 'line',
      range: [0, 5],
      dataType: 'annual',
      cagrActive: true,
      growth: false,
    },
    sankeyState: {
      color: !'default',
      padding: 20,
    },
  }

  const [state, setState] = useState(initialState)

  const updateState = changes => {
    setState(prevState => ({ ...prevState, ...changes }))
  }

  const updateToolbarState = changes => {
    setState(prevState => ({
      ...prevState,
      toolbarState: { ...prevState.toolbarState, ...changes },
    }))
  }

  const updateSankeyState = changes => {
    setState(prevState => ({
      ...prevState,
      sankeyState: { ...prevState.sankeyState, ...changes },
    }))
  }

  useEffect(() => {
    localStorage.setItem('financialStatementsContext', JSON.stringify(state))
  }, [state])

  return (
    <FinancialStatementsContext.Provider
      value={{ state, updateState, updateToolbarState, updateSankeyState }}
    >
      {children}
    </FinancialStatementsContext.Provider>
  )
}
