export const buildESGScoreData = (data) => {
  // chart data
  const chartData = data;

  // build current data
  const currentData = {
    ESGScore: data[CURRENT_QUARTER].ESGScore,
    E_Score: data[CURRENT_QUARTER].environmentalScore,
    G_Score: data[CURRENT_QUARTER].governanceScore,
    S_Score: data[CURRENT_QUARTER].socialScore,
  };

  // build down from last year data
  const yearChange = {
    value: (
      data[CURRENT_QUARTER].ESGScore - data[FOURTH_QUARTER].ESGScore
    ).toFixed(2),
    percent: (
      (data[CURRENT_QUARTER].ESGScore - data[FOURTH_QUARTER].ESGScore) /
      data[FOURTH_QUARTER].ESGScore
    ).toFixed(2),
  };

  // build pie chart data
  // const pieEnvironmental = data.slice(0,5).reduce((acc, obj) => acc + obj.environmentalScore, 0);
  // const pieSocial = data.slice(0,5).reduce((acc, obj) => acc + obj.socialScore, 0);
  // const pieGovernance = data.slice(0,5).reduce((acc, obj) => acc + obj.governanceScore, 0);
  const pieEnvironmental = currentData.E_Score;
  const pieSocial = currentData.S_Score;
  const pieGovernance = currentData.G_Score;
  const pieChartData = [
    {
      name: 'Environmental Score',
      value: pieEnvironmental / data?.length,
    },
    {
      name: 'Social Score',
      value: pieSocial / data?.length,
    },
    {
      name: 'Governance Score',
      value: pieGovernance / data?.length,
    },
  ];

  return {
    chartData,
    currentData,
    yearChange,
    pieChartData,
  };
};

const CURRENT_QUARTER = 0;
const FOURTH_QUARTER = 3;
