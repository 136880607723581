import styles from './ttmRatios.module.scss'
import numeral from 'numeral'
import { startCase } from 'lodash'
import { useState } from 'react'
import Fuse from 'fuse.js'

export const TTMRatios = ({ columnKeysTTM, data }) => {
  const [searchTerm, setSearchTerm] = useState('')

  const fuse = new Fuse([], {
    keys: ['Header'],
    includeScore: true,
    threshold: 0.2,
    location: 0,
    distance: 100,
    shouldSort: false,
    ignoreLocation: false,
  })

  const doesMatchSearch = ratio => {
    if (!searchTerm) return false
    fuse.setCollection([ratio])
    return fuse.search(searchTerm).length > 0
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.searchWrapper}>
        <input
          type="text"
          placeholder="Search metrics..."
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          className={styles.searchInput}
        />
      </div>
      <div className={styles.ttmRatiosWrapper}>
        {Object.entries(columnKeysTTM).map(([category, ratios]) => (
          <div key={category} className={styles.categoryWrapper}>
            <div className={styles.categoryHeader}>
              {startCase(category.replace('Columns', ''))}
            </div>
            <div className={styles.ratiosWrapper}>
              {ratios.map((ratio, index) => {
                const key = ratio.accessor
                const value = data[key]
                const isHighlighted = doesMatchSearch(ratio)
                const metricWrapperClass = isHighlighted
                  ? `${styles.metricWrapper} ${styles.highlight}`
                  : styles.metricWrapper
                return (
                  <>
                    <div key={index} className={metricWrapperClass}>
                      <div className={styles.metricHeader}>{ratio.Header}:</div>
                      <div>
                        {value
                          ? ratio.percentage === true
                            ? numeral(value).format('0.00%')
                            : numeral(value).format('0.00a')
                          : '-'}
                      </div>
                    </div>
                    <div className={styles.metricFooter} />
                  </>
                )
              })}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
