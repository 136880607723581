import styles from './Roster.module.scss'
import {
  reformatName,
  findMostCommon,
  calculateAverage,
  countAcquisitionsAndDispositions,
  formatString,
} from '../../utils'
import numeral from 'numeral'
import SimpleTwoBarChart from '../../charts/chart'
import { RosterItem } from './RosterComponent'
import { UilArrowCircleLeft } from '@iconscout/react-unicons'
import { useMemo, useCallback } from 'react'

export const Roster = ({
  data,
  selectedOwner,
  setSelectedOwner,
  ownerData,
  stockPrice,
  ticker,
}) => {
  const currentYear = 2024

  const handleOwnerClick = useCallback(
    owner => {
      setSelectedOwner(selectedOwner === owner ? null : owner)
    },
    [selectedOwner, setSelectedOwner]
  )

  const filteredData = useMemo(() => {
    return selectedOwner
      ? ownerData.filter(item => item.reportingName === selectedOwner)
      : []
  }, [selectedOwner, ownerData])

  const computedData = useMemo(() => {
    if (!selectedOwner) {
      return {
        mostCommonTransactionType: null,
        mostCommonSecurityName: null,
        averageTransactionPrice: 0,
        acquisitions: 0,
        dispositions: 0,
        acqVsDispRatio: 0,
        averageSecurityTransacted: 0,
        marketValue: 0,
        typeOfOwner: null,
        chartData: [],
      }
    }

    const mostCommonTransactionType = findMostCommon(
      filteredData,
      'transactionType'
    )
    const mostCommonSecurityName = findMostCommon(filteredData, 'securityName')
    const averageTransactionPrice = calculateAverage(filteredData, 'price')
    const { acquisitions, dispositions } =
      countAcquisitionsAndDispositions(filteredData)
    const acqVsDispRatio = acquisitions / dispositions
    const averageSecurityTransacted = calculateAverage(
      filteredData,
      'securitiesTransacted'
    )
    const marketValue = ownerData?.[0]?.securitiesOwned * stockPrice
    const typeOfOwner = ownerData?.[0]?.typeOfOwner

    const chartData = [
      { name: 'Acquisitions', value: acquisitions },
      { name: 'Dispositions', value: dispositions },
    ]

    return {
      mostCommonTransactionType,
      mostCommonSecurityName,
      averageTransactionPrice,
      acquisitions,
      dispositions,
      acqVsDispRatio,
      averageSecurityTransacted,
      marketValue,
      typeOfOwner,
      chartData,
    }
  }, [selectedOwner, filteredData, ownerData, stockPrice])

  const rosterItemData = [
    {
      name: 'Most Common Transaction Type',
      data: computedData?.mostCommonTransactionType,
    },
    {
      name: 'Most Common Security',
      data: computedData?.mostCommonSecurityName,
    },
    {
      name: 'Average Transaction Price',
      data: numeral(computedData?.averageTransactionPrice).format('$0.00'),
    },
    {
      name: 'Securities Owned',
      data: numeral(computedData?.ownerData?.[0]?.securitiesOwned).format(
        '0,0'
      ),
    },
    {
      name: 'Current Market Value',
      data: numeral(computedData?.marketValue).format('$0.0a'),
    },
    {
      name: 'Average Securities Transacted',
      data: numeral(computedData?.averageSecurityTransacted).format('0,0'),
    },
    {
      name: 'Acquisitions to Dispositions Ratio',
      data: numeral(computedData?.acqVsDispRatio).format('0.00a'),
    },
  ]

  const currentYearOwners = useMemo(() => {
    return (
      data?.filter(
        item => new Date(item.transactionDate).getFullYear() === currentYear
      ) ?? []
    )
  }, [data, currentYear])

  return (
    <div className={styles.roster}>
      {selectedOwner ? (
        <div
          className={styles.selectedOwnerWrapper}
          style={{
            height: selectedOwner
              ? 'calc(100vh - 330px)'
              : 'calc(100vh - 360px)',
          }}
        >
          <div
            className={styles.owner}
            onClick={() => handleOwnerClick(selectedOwner)}
          >
            <UilArrowCircleLeft size={20} />
            <div className={styles.ownerSection}>
              <div>{reformatName(selectedOwner)}</div>
              <div className={styles.typeOfOwner}>
                {' '}
                {formatString(computedData?.typeOfOwner)}
              </div>
            </div>
          </div>
          <div className={styles.rosterItemWrapper}>
            {rosterItemData.map(item => (
              <RosterItem name={item.name} data={item.data} />
            ))}
            <div className={styles.rosterAcqDisp}>
              <RosterItem
                name={'Acquisitions'}
                data={computedData?.acquisitions}
                centered={true}
              />
              <RosterItem
                name={'Dispositions'}
                data={computedData?.dispositions}
                centered={true}
              />
            </div>
            <div
              className={styles.chart}
              style={{ height: 150, width: 'auto' }}
            >
              <SimpleTwoBarChart data={computedData?.chartData} height={150} />
            </div>
          </div>
        </div>
      ) : (
        <div
          className={styles.ownerWrapper}
          style={{
            height: selectedOwner
              ? 'calc(100vh - 330px)'
              : 'calc(100vh - 360px)',
          }}
        >
          {currentYearOwners?.map(item => {
            return (
              <div
                className={styles.ownerLine}
                onClick={() => handleOwnerClick(item?.owner)}
                key={item.id}
              >
                <div>{reformatName(item?.owner)}</div>
                <div className={styles.ownerType}>
                  {formatString(item?.typeOfOwner)}
                </div>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}
