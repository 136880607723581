import { useState } from 'react'

const useHoverState = (initialValue = null) => {
  const [hoveredItem, setHoveredItem] = useState(initialValue)

  const handleHover = item => setHoveredItem(item)
  const handleLeave = () => setHoveredItem(null)

  return { hoveredItem, handleHover, handleLeave }
}

export default useHoverState
