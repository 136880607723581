import initialState from './initialState'
import deepCopy from 'deepcopy'

import {
  addComponentToDashboard,
  removeComponentFromDashboard,
  updateTableState,
  saveDashboard,
  renameDashboard,
  createDashboard,
} from './utils'

export default function rootReducer(state = initialState, action) {
  let copy
  let serverStateCopy
  let component

  switch (action.type) {
    case 'SET_LAYOUT': {
      const { layout, breakpoint } = action.payload
      const newLayout = deepCopy(layout)
      const oldLayout = deepCopy(state.layouts)
      return {
        ...state,
        layouts: {
          ...oldLayout,
          [breakpoint]: [...newLayout],
        },
      }
    }

    case 'SET_BREAKPOINT':
      return {
        ...state,
        breakpoint: action.payload,
      }

    case 'ADD_COMPONENT':
      copy = deepCopy(state)
      component = action.payload
      return {
        ...addComponentToDashboard(copy, component),
      }
    case 'REMOVE_COMPONENT':
      let copyRem = deepCopy(state)

      return {
        ...removeComponentFromDashboard(copyRem, action.payload),
      }
    case 'LOAD_DATA':
      let loadUP = deepCopy(action.payload)
      const currentDashboard = loadUP.name
      const currentDashboardState = loadUP.state
      const pushedDashboard = { id: loadUP?.id, name: loadUP?.name }
      const isloaded = state?.loadedDashboards?.filter(
        db => db.id === loadUP?.id
      )

      let loadedDashboards
      if (isloaded?.length) {
        loadedDashboards = state?.loadedDashboards
      } else if (state?.loadedDashboards) {
        loadedDashboards = [
          ...state?.loadedDashboards.filter(db => db.id !== loadUP?.id),
          pushedDashboard,
        ]
      } else {
        loadedDashboards = [pushedDashboard] // inital state
      }

      return {
        ...state,
        currentDashboard,
        loadedDashboards: loadedDashboards,
        dashboards: [{ ...currentDashboardState, id: loadUP?.id }],
        data: { ...currentDashboardState?.data },
      }
    case 'UPDATE_TICKER':
      let { id, ticker } = action.payload
      copy = deepCopy(state)
      copy.data[id].spec.ticker = ticker
      return {
        ...copy,
      }
    case 'UPDATE_TABLE_STATE':
      copy = deepCopy(state)

      return {
        ...updateTableState(copy, action.payload),
      }
    case 'UPDATE_DATA':
      copy = deepCopy(state)
      copy.data[action.payload.id].data = [...action.payload.data]
      return {
        ...copy,
      }
    case 'NEW_DASHBOARD':
      createDashboard(action.payload, state)
      return {
        ...state,
      }
    case 'FETCH_DASHBOARDS':
      return {
        ...state,
        numberDashboards: action.payload.count,
        dashboardNames: action.payload.names,
        userId: action.payload.userId,
      }
    case 'SAVE_DASHBOARD':
      copy = deepCopy(state)

      saveDashboard(copy)
      return {
        ...state,
      }
    case 'RENAME_DASHBOARD':
      serverStateCopy = deepCopy(state)

      renameDashboard(serverStateCopy, action.payload)
      return {
        ...state,
      }
    case 'CLOSE_DASHBOARD':
      serverStateCopy = deepCopy(state)
      if (serverStateCopy?.currentDashboard === action.payload?.name) {
        // if we clsoed a active dashboard
        if (serverStateCopy?.loadedDashboards?.length === 1) {
          // last dashboard closed
          return {
            ...state,
            loadedDashboards: state?.loadedDashboards?.filter(
              db => db?.id !== action.payload?.id
            ),
          }
        } else {
          // when closing a current dashboard have to replace with the one next in line
          return {
            ...state,

            loadedDashboards: state?.loadedDashboards?.filter(
              db => db?.id !== action.payload?.id
            ),
          }
        }
      } else {
        return {
          ...state,
          loadedDashboards: state?.loadedDashboards?.filter(
            db => db?.id !== action.payload?.id
          ),
        }
      }

    default:
      return state
  }
}
