import { fetches } from './queries'
import { useMemo } from 'react'
import { useQuery } from 'react-query'
import { useURLSyncedGlobalTicker } from 'pages/views/hooks'

export const useDataSource = () => {
  const { ticker, handleSetTicker } = useURLSyncedGlobalTicker()

  const { data: annualRatiosData, isLoading: annualRatiosDataDataIsLoading } =
    useQuery(['annual-ratios', ticker], fetches.fetchAnnualRatios)

  const { data: profileData, isLoading: profileDataIsLoading } = useQuery(
    ['profile', ticker],
    fetches.fetchProfile
  )

  const companyName = profileData?.[0]?.companyName

  const CompanyData_UI = useMemo(() => {
    return {
      annualRatiosData: annualRatiosData,
      annualRatiosDataDataIsLoading: annualRatiosDataDataIsLoading,
      profileData: profileData,
      profileDataIsLoading: profileDataIsLoading,
    }
  }, [
    annualRatiosData,
    annualRatiosDataDataIsLoading,
    profileData,
    profileDataIsLoading,
  ])

  return {
    CompanyData_UI,
    setTicker: handleSetTicker,
    ticker,
    companyName,
  }
}
