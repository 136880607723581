import { useState, useMemo, useEffect } from 'react'
import {
  fetchCompanyData,
  fetchReturns,
  fetchNews,
  fetchQuotes,
} from './queries'
import { useQuery } from 'react-query'

export const useDataSource = tickerList => {
  const [tickers, setTickers] = useState(tickerList || ['AAPL'])

  useEffect(() => {
    setTickers(tickerList)
  }, [tickerList])

  const { data: returnsData, isLoading: returnsDataIsLoading } = useQuery(
    ['stock-returns', tickers],
    fetchReturns
  )

  const { data: newsData, isLoading: newsDataIsLoading } = useQuery(
    ['stock-news', tickers],
    fetchNews
  )

  const { data: quoteData, isLoading: quoteDataIsLoading } = useQuery(
    ['stock-quote', tickers],
    fetchQuotes
  )

  const { data: companyData, isLoading: companyDataIsLoading } = useQuery(
    ['company-data', tickers],
    () =>
      Promise.all(
        tickers.map(ticker =>
          fetchCompanyData({ queryKey: ['company-data', ticker] })
        )
      )
  )

  const WATCH_UI = useMemo(() => {
    return {
      companyData: companyData,
      companyDataIsLoading: companyDataIsLoading,
      returnsData: returnsData,
      returnsDataIsLoading: returnsDataIsLoading,
      newsData: newsData,
      newsDataIsLoading: newsDataIsLoading,
      quoteData: quoteData,
      quoteDataIsLoading: quoteDataIsLoading,
    }
  }, [
    companyData,
    companyDataIsLoading,
    newsData,
    newsDataIsLoading,
    returnsData,
    returnsDataIsLoading,
    quoteData,
    quoteDataIsLoading,
  ])

  return {
    WATCH_UI,
    setTickers,
    tickers,
  }
}
