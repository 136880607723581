import './Tablet.scss'
import { EnterTerminalButton } from '../../HomePageComponents/EnterTerminalButton/EnterTerminalButton'
import { Footer } from '../../HomePageComponents/Footer/HomePageFooter'
import { NavBar } from '../../HomePageComponents/NavBar/NavBar'
import Image from '../../HomePageComponents/Image/Image'
import { ThreeBenefits } from '../../HomePageComponents/ThreeBenefits/ThreeBenefits'
import { featureSectionConstants, tabletViewConstants } from '../constants'
import { FeatureItem } from '../../HomePageComponents/FeatureItem/FeatureItem'
import { config } from '../DesktopLargeView/constants'
import { FeatureTabs } from 'pages/HomePage/HomePageComponents/FeatureTabs/FeatureTabs'
import { HomePageThemesChange } from 'theme/homepageThemesChange'

//// Media

import { media } from '../../assets/asset'

//// Text Vars

import { texts } from '../../TextVars/HomePageText'

function EnterTerminalButtonWarning() {
  const handleClick = () => {
    alert(texts.NotOptimizedForMobile_Warning)
  }

  return (
    <button className="enter-terminal-btn" onClick={handleClick}>
      <EnterTerminalButton />
    </button>
  )
}

export const TabletView = () => {
  return (
    <div className="tablet-view-homepage-wrapper">
      <NavBar />
      <div className="tablet-view-homepage-content-1">
        <div className="tablet-view-homepage-content-1-content-row-1">
          <div className="tablet-view-homepage-content-1-col">
            <div className="tablet-view-homepage-content-1-title-container">
              <div className="tablet-view-homepage-content-1-title-font">
                Financial data you need, in the way you want.
              </div>
            </div>
            <div className="tablet-view-homepage-content-1-description-font">
              Get started today.
            </div>
            <div className="tablet-view-homepage-content-1-enter-terminal">
              <EnterTerminalButtonWarning />
            </div>
          </div>
        </div>
        <Image
          webpSource={media.marketOverviewWebP}
          alt="Market Overview"
          className="tablet-view-homepage-content-1-dashboard-img-top"
        />
      </div>
      <section className="tablet-view-homepage-panel-two">
        <div className="tablet-view-homepage-panel-two-wrapper">
          <div className="tablet-view-homepage-panel-two-content-wrapper">
            {featureSectionConstants.map((feature, index) => (
              <FeatureItem
                key={index}
                title={feature.title}
                description={feature.description}
                webpSource={feature.webP}
                index={index}
                renderComponent={feature.renderComponent}
              />
            ))}
          </div>
        </div>
      </section>
      <FeatureTabs config={config} />
      <HomePageThemesChange />
      {/* <div className="tablet-view-homepage-content-6">
        <div className="tablet-view-homepage-content-6-col-title-2">
          Outseek has you covered where others don't.
        </div>
        <ThreeBenefits isTablet={true} />
      </div> */}
      <Footer />
    </div>
  )
}
