import styles from './CompanyOverview.module.scss'
import { TickerOverlayChart } from 'core/TickerOverlay/TickerOverlayChart'
import { QuoteBar } from 'core/QuoteBar/QuoteBar'
import RangeMeter from 'core/RangeMeter/RangeMeter'
import QuickTable from 'core/QuickTable/QuickTable'
import Spinner from 'core/ScrollableContainer/Spinner/spinner'
import { BasicTable } from 'core/Tables/BasicTable'
import { PriceReturns } from './components/Returns/StockReturns'
import TimeRange from '../NewsView/component/TimeRange'
import { useDataSource } from './dataSource/hooks'
import numeral from 'numeral'
import { useState, useMemo, useCallback, useEffect } from 'react'
import { colorScale, combineData, TooltipContent } from './utils'
import {
  QuoteMetrics,
  RatioMetrics,
  OverviewIntervals,
  CompanyNewsColumns,
} from './constant'
import { Helmet } from 'react-helmet-async'
import { CardWrapper } from 'core/CardWrapper/CardWrapper'
import FinRadarChartD3 from '../CompanyAnalytics/components/Comparison/RadarChart/RadarDThree'
import { AnalAndDiv } from './components/AnalAndDiv'
import { dataFunction } from './Score'
import { useAnalyticsData } from './dataSource/useAnalyticsData'
import ChecksTabs from '../CompanyAnalytics/components/Comparison/RadarChart/ChecksTabs/ChecksTabs'

export const CompanyOverview = () => {
  const { Profile_UI, ticker, setTicker, setInterval, interval } =
    useDataSource()

  const ratioSummary = useAnalyticsData(['sector', 'exchange'])

  const [showCharts, setShowCharts] = useState(true)

  const [quoteModal, setQuoteModal] = useState(false)
  const [quickFinancialsModal, setQuickFinancialsModal] = useState(false)

  const ratioData = Profile_UI?.companyData?.ratios?.[0]

  const quickTableData = useMemo(
    () => combineData(ratioData, Profile_UI?.financialAnnual),
    [ratioData, Profile_UI?.financialAnnual]
  )

  const { scores, checkResults } = useMemo(
    () => dataFunction(Profile_UI, ratioSummary),
    [Profile_UI, ratioSummary]
  )

  const overAllScore = useMemo(
    () => scores.reduce((acc, curr) => acc + curr.score, 0) / scores.length,
    [scores]
  )

  const scoreColor = useMemo(
    () => colorScale(overAllScore).hex(),
    [overAllScore]
  )

  const toggleShowCharts = useCallback(() => {
    setShowCharts(prevShowCharts => !prevShowCharts)
  }, [])

  const handleTimeRangeChange = useCallback(
    newInterval => {
      setInterval(newInterval)
    },
    [setInterval]
  )

  const isEmptyObject = obj => {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object
  }

  return (
    <div className={styles.companyOverviewWrapper}>
      <Helmet>
        <title>{`Outseek | ${ticker} Overview`}</title>
        <meta
          name="description"
          content={
            "This page provides retail financial analysts with a summary of a company's financials."
          }
        />
      </Helmet>
      <QuoteBar
        updateTicker={tickerIn => setTicker(tickerIn)}
        ticker={ticker}
      />
      <div className={styles.companyOverviewContentWrapper}>
        <CardWrapper
          label={'Price Chart and Company Radar Chart'}
          height={'100%'}
          width={'100%'}
          content={
            Profile_UI?.priceDataIsLoading ? (
              <Spinner />
            ) : (
              <div className={styles.companyOverviewChartQuoteWrapper}>
                {Profile_UI?.priceDataIsLoading?.length === 0 ? (
                  <div className={styles.noDataContainer}>
                    No data available for this ticker
                  </div>
                ) : (
                  <div className={styles.companyOverviewChartTimeWrapper}>
                    <div className={styles.timeRange}>
                      <TimeRange
                        intervals={OverviewIntervals}
                        onChange={handleTimeRangeChange}
                        interval={interval}
                        fitContent
                        gap={'8px'}
                        background={'transparent'}
                      />
                    </div>
                    <TickerOverlayChart
                      ticker={ticker}
                      data={Profile_UI.priceData}
                      xAxisKey={'date'}
                      view={true}
                      interval={interval}
                      barDataKey={'volume'}
                      lineDataKey={'close'}
                      YTickFormat={tick => numeral(tick).format('0.00a')}
                      barDomain={[0, dataMax => dataMax * 4.5]}
                    />
                  </div>
                )}
                <div className={styles.companyOverviewRadarWrapper}>
                  {Profile_UI?.companyData?.ratios?.[0] === undefined ||
                  isEmptyObject(Profile_UI?.companyData?.ratios?.[0]) ? (
                    <div className={styles.noDataContainer}>
                      No data available.
                    </div>
                  ) : (
                    <FinRadarChartD3
                      data={scores}
                      dataKey={'score'}
                      category={'name'}
                      color={scoreColor}
                      domain={[0, 5]}
                      noDots={true}
                      renderTooltip={data => <TooltipContent data={data} />}
                      renderModal={<ChecksTabs checks={checkResults} />}
                    />
                  )}
                </div>
              </div>
            )
          }
        />
        <div className={styles.companyOverviewBottomWrapper}>
          <div className={styles.bottomRightWrapper}>
            <CardWrapper
              label={'Quote'}
              height={'calc(50% - 4px)'}
              width={'100%'}
              endButton={
                <div className={styles.endButtonWrapper}>
                  {!showCharts ? null : (
                    <div
                      onClick={() => setQuoteModal(true)}
                      className={styles.editButton}
                    >
                      Edit Metrics
                    </div>
                  )}
                  {/* <div onClick={toggleShowCharts} className={styles.editButton}>
                    Swap View
                  </div> */}
                </div>
              }
              content={
                Profile_UI?.quoteDataIsLoading ||
                Profile_UI?.returnsDataIsLoading ||
                Profile_UI?.companyDataIsLoading ? (
                  <Spinner />
                ) : (
                  <div className={styles.companyOverviewQuote}>
                    {/* {showCharts ? ( */}
                    <QuickTable
                      data={Profile_UI?.ProfileQuoteData}
                      metrics={QuoteMetrics}
                      label="Quote"
                      height={'100%'}
                      modalIsOpen={quoteModal}
                      setIsOpen={setQuoteModal}
                    />
                    {/* ) : (
                      <>
                        <div className={styles.ranges}>
                          <RangeMeter
                            min={Profile_UI?.quoteData?.dayLow}
                            max={Profile_UI?.quoteData?.dayHigh}
                            value={Profile_UI?.quoteData?.price}
                            label={'Day Range'}
                          />
                          <RangeMeter
                            min={Profile_UI?.quoteData?.yearLow}
                            max={Profile_UI?.quoteData?.yearHigh}
                            value={Profile_UI?.quoteData?.price}
                            label={'Year Range'}
                          />
                        </div>
                        <PriceReturns data={Profile_UI?.returnsData} />
                      </>
                    )} */}
                  </div>
                )
              }
            />
            <CardWrapper
              label={'Dividends and Analyst Ratings'}
              height={'calc(50% - 4px)'}
              width={'100%'}
              content={
                <AnalAndDiv data={Profile_UI} divData={Profile_UI?.divData} />
              }
            />
          </div>
          <div className={styles.bottomRightWrapper}>
            <CardWrapper
              label={'Quick Financials'}
              height={'100%'}
              width={'100%'}
              endButton={
                <div
                  onClick={() => setQuickFinancialsModal(true)}
                  className={styles.editButton}
                >
                  Edit Metrics
                </div>
              }
              content={
                Profile_UI?.companyDataIsLoading ? (
                  <Spinner />
                ) : (
                  <div className={styles.companyOverviewProfile}>
                    {Object.keys(quickTableData).length === 0 ? (
                      <div className={styles.noDataContainer}>
                        No data available.
                      </div>
                    ) : (
                      <QuickTable
                        data={quickTableData}
                        metrics={RatioMetrics}
                        label="Ratios"
                        height={'100%'}
                        modalIsOpen={quickFinancialsModal}
                        setIsOpen={setQuickFinancialsModal}
                      />
                    )}
                  </div>
                )
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}
