/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from 'react'
import { useTable, usePagination, useSortBy } from 'react-table'
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
import { COLUMNS, availibleColumns } from './columns'
import SortIcon from 'mdi-react/SortIcon'
import ReactTablePagination from './components/ReactTablePagination'
import './styles.scss'
import { PresetPanel } from './components/PresetPanel'
import MetricCatalog from './components/MetricCatalog/MetricCatalog'
import Spinner from 'core/ScrollableContainer/Spinner/spinner'
import TooltipTicker from 'core/TickerOverlay/TickerOverlay'
import {
  PAGE_FILTER_CHANGED,
  PAGE_SIZE_CHANGED,
  PAGE_SORT_CHANGED,
  TOTAL_COUNT_CHANGED,
  VISIBLE_COLUMNS_CHANGED,
  initialState,
  viewInitialState,
  PAGE_CHANGED,
} from './constants'
import { fetchScreenerData, reducer } from './utils'
import FilterContext from './context'
import { panelConfig } from './components/PresetPanel'
import { utils } from './utils'
import { ImageError } from 'core/ImageError/ImageError'
import { SavedScreens } from './components/SavedScreens/SavedScreens'
import { useScreeners } from 'hooks/useScreener'
import Chat from './ScreenerChat/chat'
import { FilterPill } from './components/FilterPill'
import styles from './components/MetricCatalog/screenerMetricCatalog.module.scss'

const queryClient = new QueryClient()

const DataTable = ({ update, persistedState, isView }) => {
  // HOOKS and handlers for updating the filters
  // for metric catalog
  const loadedState =
    Object.keys(persistedState).length === 0
      ? isView
        ? viewInitialState
        : initialState
      : persistedState

  const { updateScreener } = useScreeners()

  const [checked, setChecked] = useState(false)
  const [applyFilters, setApplyFilters] = useState(false)
  const [filters, setFilters] = useState([])
  const [rowHover, setRowHover] = useState(false)
  const [currentPanel, setCurrentPanel] = useState(panelConfig.Overview)
  const [selected, setSelected] = useState('Overview')
  const [selectedScreen, setSelectedScreen] = useState(null)

  const handleScreenSelection = selectedScreen => {
    setSelectedScreen(selectedScreen)
    setFilters(selectedScreen?.filters)
  }

  useEffect(() => {
    if (!selectedScreen || !selectedScreen.filters) return

    const body = {
      name: selectedScreen.name,
      filters: filters,
      description: selectedScreen.description,
    }

    updateScreener({
      screener_id: selectedScreen.screenerId,
      body: body,
    })
  }, [selectedScreen, filters])

  const handlePushFilter = newFilter => {
    // filter out exis ting filters of same type
    const tempFilters = filters.filter(
      filt => filt?.column !== newFilter?.column
    )
    setFilters([...tempFilters, newFilter])
  }

  const handleDeleteFilter = columnToRemove => {
    const tempFilters = filters.filter(filt => filt.column !== columnToRemove)
    setFilters(tempFilters)

    setApplyFilters(!applyFilters)
  }

  let columns = useMemo(
    () => COLUMNS.filter(col => availibleColumns.includes(col.accessor)),
    []
  )

  const [state, dispatch] = React.useReducer(reducer, loadedState)

  const {
    queryPageIndex,
    queryPageSize,
    totalCount,
    queryPageFilter,
    queryPageSortBy,
    queryVisibleColumns,
  } = state

  const { isLoading, error, data, isSuccess } = useQuery(
    [
      'screener-data',
      queryPageIndex,
      queryPageSize,
      queryPageFilter,
      queryPageSortBy,
      queryVisibleColumns,
    ],

    () =>
      fetchScreenerData(
        queryPageIndex,
        queryPageSize,
        queryPageFilter,
        queryPageSortBy,
        queryVisibleColumns
      ),
    {
      keepPreviousData: false,
      staleTime: Infinity,
    }
  )

  const totalPageCount = data && Math.ceil(data.count / queryPageSize)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    pageOptions,
    gotoPage,
    previousPage,
    canPreviousPage,
    setHiddenColumns,
    nextPage,
    canNextPage,
    setPageSize,
    allColumns,
    visibleColumns,
    state: { pageIndex, pageSize, sortBy },
  } = useTable(
    {
      columns,
      data: data?.results || [],
      initialState: {
        pageIndex: loadedState.queryPageIndex,
        pageSize: loadedState.queryPageSize,
        sortBy: loadedState.queryPageSortBy,
        hiddenColumns: columns.map(column => {
          if (
            !loadedState?.queryVisibleColumns?.includes(column.accessor) &&
            !panelConfig.Overview?.includes(column.accessor)
            // !DEFAULT_COLUMNS_SHOWN.includes(column.accessor)
          ) {
            return column.accessor || column.id
          }
          return null
        }),
      },
      manualPagination: true,
      pageCount: data ? totalPageCount : null,
      autoResetSortBy: false,
      autoResetExpanded: false,
      autoResetPage: false,
    },
    useSortBy,
    usePagination
  )
  const manualPageSize = []

  useEffect(() => {
    dispatch({ type: PAGE_CHANGED, payload: pageIndex })
  }, [pageIndex])

  useEffect(() => {
    dispatch({ type: PAGE_SIZE_CHANGED, payload: pageSize })
    gotoPage(0)
  }, [pageSize, gotoPage])

  useEffect(() => {
    dispatch({ type: PAGE_SORT_CHANGED, payload: sortBy })
    gotoPage(0)
  }, [sortBy, gotoPage])

  useEffect(() => {
    dispatch({ type: PAGE_FILTER_CHANGED, payload: filters })
    gotoPage(0)
  }, [checked, applyFilters, filters, gotoPage])

  useEffect(() => {
    dispatch({
      type: VISIBLE_COLUMNS_CHANGED,
      payload: visibleColumns.map(col => col?.id),
    })
    gotoPage(0)
  }, [gotoPage, visibleColumns])

  useEffect(() => {
    if (data?.count) {
      dispatch({
        type: TOTAL_COUNT_CHANGED,
        payload: data.count,
      })
    }
  }, [data?.count])

  useEffect(() => {
    update(state)
  }, [state])

  const handleRowHover = row => {
    setRowHover(row)
  }

  const [isDropdownVisible, setIsDropdownVisible] = useState(true)

  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible)
  }

  const [activeTab, setActiveTab] = useState(1)

  const [useCustomFilter, setUseCustomFilter] = useState(false)

  const [aiView, setAiView] = useState(() => {
    const savedAiView = localStorage.getItem('aiView')
    return savedAiView ? JSON.parse(savedAiView) : false
  })

  useEffect(() => {
    localStorage.setItem('aiView', JSON.stringify(aiView))
  }, [aiView])

  if (error) {
    return (
      <div style={{ width: '100%', height: '100%' }}>
        <p>Error</p>
      </div>
    )
  }

  if (isSuccess)
    return (
      <FilterContext.Provider value={{ filters, setFilters }}>
        <div className="screenerfinalwrapper">
          <div
            style={{
              heigh: '100%',
              width: isDropdownVisible ? '350px' : '40px',
            }}
          >
            <MetricCatalog
              handleClose={toggleDropdown}
              handlePushFilter={handlePushFilter}
              height={'100%'}
              isDropdownVisible={isDropdownVisible}
              filters={filters}
              handleDeleteFilter={handleDeleteFilter}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              useCustomFilter={useCustomFilter}
              setUseCustomFilter={setUseCustomFilter}
              isView={isView}
              aiView={aiView}
              setAiView={setAiView}
              chat={
                <Chat
                  setFilters={setFilters}
                  filters={filters}
                  aiView={aiView}
                  setAiView={setAiView}
                />
              }
            />
          </div>
          <div className={'screener-wrapper'}>
            <div
              className={styles.filterPillContainer}
              style={{ display: filters?.length > 0 ? null : 'none' }}
            >
              {filters?.map(filter => {
                return (
                  <FilterPill
                    handleDeleteFilter={handleDeleteFilter}
                    {...filter}
                  />
                )
              })}
            </div>
            <PresetPanel
              update={cols =>
                setHiddenColumns(hiddenCols => {
                  return allColumns
                    .filter(col => !cols.includes(col.id))
                    .map(col => col.id)
                })
              }
              setCurrentPanel={setCurrentPanel}
              selected={selected}
              setSelected={setSelected}
            />
            <div
              className="table react-table"
              style={{
                margin: 0,
              }}
            >
              {isLoading ? (
                <Spinner />
              ) : data?.count > 0 ? (
                <>
                  <div
                    className={'table-wrapper'}
                    style={{
                      // height: utils.tableHeight(
                      //   isView,
                      //   isDropdownVisible,
                      //   filters
                      // ),
                      height:
                        filters.length > 0
                          ? 'calc(100vh - 166px)'
                          : 'calc(100vh - 130px)',
                      flexGrow: 1,
                    }}
                  >
                    <table {...getTableProps()} className="table">
                      <thead style={utils?.tableHeadStyles}>
                        {headerGroups.map(headerGroup => (
                          <tr
                            {...headerGroup.getHeaderGroupProps()}
                            style={{ zIndex: 100 }}
                          >
                            {headerGroup.headers.map((column, i) => {
                              return (
                                <th
                                  style={{ zIndex: 11 }}
                                  className={utils?.getHeaderClassName(i)}
                                  {...column.getHeaderProps(
                                    column.getSortByToggleProps()
                                  )}
                                  title={column.render('Header')}
                                >
                                  {column.render('Header') === 'line'
                                    ? null
                                    : column.render('Header')}
                                  {column.isSorted ? (
                                    <Sorting column={column} />
                                  ) : (
                                    ''
                                  )}
                                </th>
                              )
                            })}
                          </tr>
                        ))}
                      </thead>
                      <tbody
                        className="table table--bordered"
                        {...getTableBodyProps()}
                      >
                        {page.map((row, i) => {
                          prepareRow(row)
                          return (
                            <tr
                              onMouseEnter={() => handleRowHover(i)}
                              onMouseLeave={() => setRowHover(null)}
                              {...row.getRowProps()}
                              className="ScreenerRow"
                              style={{
                                borderRight:
                                  rowHover === i
                                    ? '2px solid var(--primary-color)'
                                    : '2px solid transparent',
                                backgroundColor:
                                  rowHover === i
                                    ? 'var(--background-secondary)'
                                    : '',
                                borderBottom:
                                  '2px solid var(--background-secondary)',
                              }}
                            >
                              {row.cells.map((cell, j) => {
                                const isRowHovered = rowHover === i
                                return (
                                  <td
                                    style={utils?.getCellStyle(
                                      isRowHovered,
                                      j === 0 || j === 1 || j === 2,
                                      j
                                    )}
                                    className={`${utils?.fixedWidthClass(j)}`}
                                    {...cell.getCellProps()}
                                  >
                                    {j === 0 ? (
                                      <ImageError src={cell.value} size={20} />
                                    ) : j === 1 ? (
                                      <TooltipTicker ticker={cell.value} />
                                    ) : j === 2 ? (
                                      <div
                                        style={{
                                          height: '120%',
                                          width: '2px',
                                          maxWidth: '2px',
                                          background: 'var(--grey-accent)',
                                          padding: '0',
                                        }}
                                      />
                                    ) : (
                                      utils?.formatCell(cell)
                                    )}
                                  </td>
                                )
                              })}
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                </>
              ) : (
                <div>
                  <div
                    className="table-wrapper-no-data"
                    style={{
                      height: 'calc(100vh - 165px)',
                    }}
                  >
                    No results found
                  </div>
                </div>
              )}
            </div>
            <div className="paginationWrapper1">
              <SavedScreens
                onScreenSelected={handleScreenSelection}
                filters={filters}
                selectedScreen={selectedScreen}
                setSelectedScreen={setSelectedScreen}
              />
              <div className="paginationWrapper">
                <ReactTablePagination
                  page={page}
                  gotoPage={gotoPage}
                  previousPage={previousPage}
                  nextPage={nextPage}
                  canPreviousPage={canPreviousPage}
                  canNextPage={canNextPage}
                  pageOptions={pageOptions}
                  pageSize={pageSize}
                  pageIndex={pageIndex}
                  pageCount={pageCount}
                  setPageSize={setPageSize}
                  manualPageSize={manualPageSize}
                  dataLength={totalCount}
                />
                <div className="selectWrapper">
                  <select
                    className="pageSelect"
                    value={pageSize}
                    onChange={e => {
                      setPageSize(Number(e.target.value))
                    }}
                  >
                    {[10, 20, 30, 40, 50, 75, 100].map(pageSize => (
                      <option
                        className="pageOption"
                        key={pageSize}
                        value={pageSize}
                      >
                        Show {pageSize} rows
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              {/* <div className="dataWarning">Data normalized to USD*</div> */}
            </div>
          </div>
        </div>
      </FilterContext.Provider>
    )
}

const Sorting = ({ column }) => (
  <span className="react-table__column-header sortable">
    {column.isSortedDesc === undefined ? (
      <SortIcon />
    ) : (
      <span>{column.isSortedDesc ? ' ▲' : ' ▼'}</span>
    )}
  </span>
)

const TableWrapper = props => {
  return (
    <QueryClientProvider client={queryClient}>
      <DataTable {...props} />
    </QueryClientProvider>
  )
}

export default TableWrapper
