import { useState, useEffect } from 'react'
import { Auth } from 'aws-amplify'
import { demoUserConfig } from 'network/httpClient'

export const useIsAuthenticated = () => {
  const [isAuthenticated, setLoggedIn] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isDemoUser, setIsDemoUser] = useState(false)

  // useEffect(() => {
  //   ;(async () => {
  //     let user = null
  //     try {
  //       user = await Auth.currentAuthenticatedUser()
  //       console.log('Authenticated user:', user)
  //       if (user) {
  //         setLoggedIn(true)
  //         console.log('user set as authenticated')
  //         if (user?.attributes?.email === demoUserConfig.email) {
  //           setIsDemoUser(true)
  //         } else {
  //           setIsDemoUser(false)
  //         }
  //       } else {
  //         setLoggedIn(false)
  //         console.log('user NOT SET as authenticated')
  //       }
  //     } catch (e) {
  //       setLoggedIn(false)
  //       console.error('Error checking authenticated user:', e)
  //       setLoggedIn(false)
  //     }
  //   })()
  // }, [])

  useEffect(() => {
    setIsLoading(true)
    Auth.currentAuthenticatedUser()
      .then(user => {
        setLoggedIn(true)
        if (user?.attributes?.email === demoUserConfig.email) {
          setIsDemoUser(true)
        } else {
          setIsDemoUser(false)
        }
      })
      .catch(err => {
        setLoggedIn(false)
        setIsDemoUser(false)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  return { isAuthenticated, isDemoUser, isLoading }
}
export default useIsAuthenticated

export const useIsGoogleUser = () => {
  const [isGoogleUser, setIsGoogleUser] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLoading(true)
    Auth.currentAuthenticatedUser()
      .then(user => {
        const identities =
          user.signInUserSession?.idToken?.payload?.['identities']
        const providerName =
          identities && Array.isArray(identities)
            ? identities[0]?.providerName
            : null
        setIsGoogleUser(providerName === 'Google')
      })
      .catch(error => {
        console.error('err checking for Google user:', error)
        setIsGoogleUser(false)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  return { isGoogleUser, isLoading }
}
