export const countryCodes = {
  CR: 'Costa Rica',
  MA: 'Morocco',
  KH: 'Cambodia',
  MK: 'North Macedonia',
  GR: 'Greece',
  CO: 'Colombia',
  BE: 'Belgium',
  AN: 'Netherlands Antilles',
  AZ: 'Azerbaijan',
  GF: 'French Guiana',
  GL: 'Greenland',
  FK: 'Falkland Islands',
  RU: 'Russia',
  MM: 'Myanmar',
  PE: 'Peru',
  DK: 'Denmark',
  SN: 'Senegal',
  SI: 'Slovenia',
  CZ: 'Czech Republic',
  SG: 'Singapore',
  US: 'United States',
  KR: 'South Korea',
  JP: 'Japan',
  NZ: 'New Zealand',
  BS: 'Bahamas',
  KZ: 'Kazakhstan',
  AE: 'United Arab Emirates',
  UY: 'Uruguay',
  AU: 'Australia',
  CL: 'Chile',
  NL: 'Netherlands',
  IN: 'India',
  CN: 'China',
  GE: 'Georgia',
  EE: 'Estonia',
  AT: 'Austria',
  BM: 'Bermuda',
  CI: "Côte d'Ivoire",
  LI: 'Liechtenstein',
  VN: 'Vietnam',
  ME: 'Montenegro',
  AR: 'Argentina',
  ZM: 'Zambia',
  TC: 'Turks and Caicos Islands',
  BR: 'Brazil',
  PL: 'Poland',
  MY: 'Malaysia',
  HK: 'Hong Kong',
  MC: 'Monaco',
  MT: 'Malta',
  MQ: 'Martinique',
  VG: 'Virgin Islands, British',
  GI: 'Gibraltar',
  AI: 'Anguilla',
  EG: 'Egypt',
  SE: 'Sweden',
  CH: 'Switzerland',
  DE: 'Germany',
  TW: 'Taiwan',
  LU: 'Luxembourg',
  CA: 'Canada',
  SK: 'Slovakia',
  LT: 'Lithuania',
  PR: 'Puerto Rico',
  FR: 'France',
  SR: 'Suriname',
  MU: 'Mauritius',
  KY: 'Cayman Islands',
  LV: 'Latvia',
  CY: 'Cyprus',
  PG: 'Papua New Guinea',
  BB: 'Barbados',
  MX: 'Mexico',
  RO: 'Romania',
  GG: 'Guernsey',
  CW: 'Curaçao',
  TZ: 'Tanzania',
  UK: 'United Kingdom',
  HU: 'Hungary',
  MO: 'Macau',
  PH: 'Philippines',
  FI: 'Finland',
  GA: 'Gabon',
  IM: 'Isle of Man',
  ID: 'Indonesia',
  IE: 'Ireland',
  MN: 'Mongolia',
  JE: 'Jersey',
  RE: 'Réunion',
  PA: 'Panama',
  UA: 'Ukraine',
  ZA: 'South Africa',
  DO: 'Dominican Republic',
  PT: 'Portugal',
  ES: 'Spain',
  NG: 'Nigeria',
  NO: 'Norway',
  TH: 'Thailand',
  IL: 'Israel',
  BG: 'Bulgaria',
  JO: 'Jordan',
  IT: 'Italy',
  GB: 'United Kingdom',
  IS: 'Iceland',
  TR: 'Turkey',
}
