import { useCallback, useMemo, useState } from 'react'
import { useQuery, useQueries } from 'react-query'
import queries from './queries'
import { IndexIntervals } from './constants'

export const useDataSource = ({ metrics }) => {
  const [selectedIndex, setSelectedIndex] = useState()
  const [interval, setInterval] = useState(IndexIntervals[4])

  const {
    data: indexData,
    isLoading: indexIsLoading,
    isError: indexIsError,
  } = useQuery('fetchIndexQuotes', queries.fetchIndexQuotes, {
    retry: true,
    retryDelay: attemptIndex => Math.min(1000 * 2 ** attemptIndex, 30000),
  })

  const metricQueries = useQueries(
    metrics?.map(metric => ({
      queryKey: ['fetchHistorical', metric.key, interval],
      queryFn: () =>
        queries.fetchPriceData({
          queryKey: ['fetchHistorical', metric.key, interval],
        }),
      meta: { metricKey: metric.key },
      retry: true,
      retryDelay: attemptIndex => Math.min(1000 * 2 ** attemptIndex, 30000),
    }))
  )

  const historicalData = useMemo(
    () =>
      metricQueries?.map((query, index) => ({
        metric: metrics[index].key,
        data: query.data,
        isLoading: query.isLoading,
        isError: query.isError,
      })),
    [metricQueries, metrics]
  )

  const combinedHistoricalDataByDate = useMemo(() => {
    const combined = {}
    historicalData?.forEach(({ metric, data }) => {
      data?.forEach(point => {
        if (!combined[point.date]) {
          combined[point.date] = { date: point.date }
        }
        combined[point.date][metric] = point.close
      })
    })
    return Object.values(combined).sort(
      (a, b) => new Date(a.date) - new Date(b.date)
    )
  }, [historicalData])

  const calculateCumulativePercentageChange = useCallback(data => {
    if (!data || data.length === 0) return []
    const initialValues = {}
    const metrics = Object.keys(data[0]).filter(key => key !== 'date')
    metrics.forEach(metric => {
      initialValues[metric] = data[0][metric]
    })
    return data.map(entry => {
      const transformedEntry = { date: entry.date }
      metrics.forEach(metric => {
        const percentageChange =
          ((entry[metric] - initialValues[metric]) / initialValues[metric]) *
          100
        transformedEntry[metric] = percentageChange
      })
      return transformedEntry
    })
  }, [])

  const cumulativePercentageChangeData = useMemo(
    () => calculateCumulativePercentageChange(combinedHistoricalDataByDate),
    [calculateCumulativePercentageChange, combinedHistoricalDataByDate]
  )

  const Index_UI = useMemo(
    () => ({
      indexData,
      indexIsLoading,
      indexIsError,
      historicalData: cumulativePercentageChangeData,
      historicalDataIsLoading: metricQueries?.some(query => query?.isLoading),
      historicalDataIsError: metricQueries?.some(query => query?.isError),
    }),
    [
      cumulativePercentageChangeData,
      indexData,
      indexIsError,
      indexIsLoading,
      metricQueries,
    ]
  )

  return {
    Index_UI,
    selectedIndex,
    setSelectedIndex,
    interval,
    setInterval,
  }
}
