import startCase from 'lodash/startCase'
import { useState } from 'react'
import { UilAngleRight } from '@iconscout/react-unicons'
import { UilAngleDown } from '@iconscout/react-unicons'
import styles from './styles.module.scss'
import numeral from 'numeral'

export const Accordion = ({
  data,
  organizedMetrics,
  onClick,
  metrics,
  onMetricToggle,
}) => {
  const [expandedCategories, setExpandedCategories] = useState({
    NorthAmerica: true,
  })

  const toggleCategory = category => {
    setExpandedCategories({
      ...expandedCategories,
      [category]: !expandedCategories[category],
    })
  }

  const transformData = rawData => {
    const transformed = rawData?.reduce((acc, item) => {
      Object.keys(organizedMetrics)?.forEach(region => {
        if (organizedMetrics[region]?.includes(item?.name?.trim())) {
          if (!acc[region]) acc[region] = []
          acc?.[region]?.push(item)
        }
      })
      return acc
    }, {})

    return transformed
  }

  const transformedData = transformData(data)

  const isSelected = metricSymbol =>
    metrics?.some(metric => metric?.key === metricSymbol)

  const isSelectedColor = metricSymbol => {
    if (isSelected(metricSymbol)) {
      return metrics?.find(metric => metric?.key === metricSymbol)?.color
    }
    return 'var(--background-tertiary)'
  }

  return (
    <div className={styles.comparisonTable}>
      <div className={styles.listWrapper}>
        {Object.keys(organizedMetrics)?.map(category => (
          <div key={category}>
            <div
              className={styles.categoryHeader}
              onClick={() => toggleCategory(category)}
            >
              <span>{startCase(category)}</span>
              {expandedCategories[category] ? (
                <UilAngleDown size={20} color={'var(--white)'} />
              ) : (
                <UilAngleRight size={20} color={'var(--white)'} />
              )}
            </div>
            <div
              className={`${styles.metricContainer} ${
                !expandedCategories[category] && styles.metricContainerClosed
              }`}
            >
              {expandedCategories[category] &&
                transformedData[category]?.map(metric => {
                  const color =
                    metric?.change === null
                      ? 'var(--white)'
                      : metric?.change > 0
                      ? 'var(--green)'
                      : 'var(--red)'

                  const formatNumber = value => {
                    if (value > 0) {
                      return `+${numeral(value).format('0.00')}%`
                    } else {
                      return `${numeral(value).format('0.00')}%`
                    }
                  }

                  return (
                    <>
                      <div
                        className={styles.comparisonRow}
                        onClick={() =>
                          onClick && onClick([metric.lng, metric.lat])
                        }
                      >
                        <div className={styles.metricName}>
                          <div
                            className={styles.metricSelect}
                            style={{
                              backgroundColor: isSelectedColor(metric.type),
                            }}
                            onClick={e => {
                              e.stopPropagation()
                              onMetricToggle(metric.type)
                            }}
                          />
                          {metric?.name}
                        </div>
                        <div className={styles.otherMetrics}>
                          <span style={{ width: '100px', textAlign: 'right' }}>
                            {metric?.price === null
                              ? '-'
                              : numeral(metric?.price).format('0,0.00')}
                          </span>
                          <span
                            style={{
                              width: '50px',
                              textAlign: 'right',
                              color: color,
                            }}
                          >
                            {metric?.change === null
                              ? '-'
                              : formatNumber(metric?.change)}
                          </span>
                        </div>
                      </div>
                      <div className={styles.borderline} />
                    </>
                  )
                })}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
