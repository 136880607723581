import http from '../../../network/httpClient'
import _ from 'lodash'

/* 
Stock Ownership View Calls
*/

const asOfDate = '2023-06-30'

const fetchStockOwnershipbyHolders = async ticker => {
  let data = await http.get(
    'papi/data/api/v4/institutional-ownership/institutional-holders/symbol-ownership-percent?date=' +
      asOfDate +
      '&symbol=' +
      ticker +
      '&page=0'
  )
  return data.data.message
}

const fetchInstitutionalStockOwnership = async ticker => {
  let data = await http.get(
    'papi/data/api/v4/institutional-ownership/symbol-ownership?symbol=' +
      ticker +
      '&includeCurrentQuarter=false'
  )

  return data.data.message.reverse()
}

const fetchHistoricalPrice = async (ticker, { from, to }) => {
  let data = await http.get(
    'papi/data/api/v3/historical-price-full/' +
      ticker +
      '?from=' +
      from +
      '&to=' +
      to +
      ''
  )

  return data.data.message.historical
}

/* Institutional View */

const fetchInstitutionalHoldingsPortfolioPositionsSummary = async cik => {
  let data = await http.get(
    'papi/data/api/v4/institutional-ownership/portfolio-holdings-summary?cik=' +
      cik +
      ''
  )
  // let modifiedData = _.tail(data.data.message)
  let modifiedData = data.data.message
  return modifiedData
}

const fetchInstitutionalHoldingsPortfolioComposition = async cik => {
  let data = await http.get(
    'papi/data/api/v4/institutional-ownership/portfolio-holdings?cik=' +
      cik +
      '&date=2023-06-30&page=0'
  )
  let modifiedData = data.data.message
  return modifiedData
}

const fetchInstitutionalHoldingsPortfolioIndustrySummary = async cik => {
  let data = await http.get(
    `papi/data/api/v4/institutional-ownership/industry/portfolio-holdings-summary?cik=` +
      cik +
      `&date=${asOfDate}&page=0`
  )
  return data.data.message
}

const fetchIndexHistorical = async index => {
  let data = await http.get('papi/data/api/v3/historical-price-full/' + index)

  return data.data.message
}

const queries = {
  fetchInstitutionalStockOwnership,
  fetchStockOwnershipbyHolders,
  fetchHistoricalPrice,
  fetchInstitutionalHoldingsPortfolioComposition,
  fetchInstitutionalHoldingsPortfolioIndustrySummary,
  fetchInstitutionalHoldingsPortfolioPositionsSummary,
  fetchIndexHistorical,
}
export default queries
