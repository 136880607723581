import React, { useEffect, useRef, useState } from 'react'
import http from 'network/httpClient'
import { useQuery } from 'react-query'
import createForexHeatMap from './DThreeHeatmap'
import styles from './HeatMap.module.scss'
import { CoreLineChart } from 'core/Charts/GenericChart'
import Spinner from 'core/ScrollableContainer/Spinner/spinner'
import numeral from 'numeral'
import { getRandomColor } from 'constants/theme'

const fetchGeneralForexData = async () => {
  const data = await http.get('papi/data/api/v3/quotes/forex')
  return data.data.message
}

const fetchDrillDownData = async country => {
  const data = await http.get(
    `papi/data/api/v3/historical-price-full/${country}`
  )
  return data.data.message.historical.reverse()
}

// const useFlagImage = country => {
//   const countryCode = country || 'Unknown'

//   return useQuery(
//     ['flagImage', countryCode],
//     async () => {
//       const response = await fetch(
//         `https://www.countryflagicons.com/FLAT/16/${countryCode}.png`
//       )
//       const blob = await response.blob()
//       return URL.createObjectURL(blob)
//     },
//     {
//       enabled: countryCode !== 'Unknown',
//     }
//   )
// }

export const ForexHeatMap = () => {
  const [showChart, setShowChart] = useState(false)
  const [dataKey, setDataKey] = useState('changesPercentage')
  const [country, setCountry] = useState('EURJPY')
  const svgRef = useRef(null)

  const { data, isLoading } = useQuery(['forex-quotes'], fetchGeneralForexData)

  const { data: chartData, isLoading: chartDataLoading } = useQuery(
    ['forex drilldown', country],
    () => fetchDrillDownData(country)
  )

  const selectedCountryData = data?.find(item => item.symbol === country)

  const resizeObserver = useRef(null)

  const toggleDataKey = () => {
    setDataKey(prevDataKey =>
      prevDataKey === 'changesPercentage' ? 'price' : 'changesPercentage'
    )
  }

  useEffect(() => {
    const setCountryCallback = currencyPair => {
      setCountry(currencyPair)
      setShowChart(true)
    }

    if (!showChart) {
      resizeObserver.current = new ResizeObserver(() =>
        createForexHeatMap(
          svgRef,
          data,
          isLoading,
          dataKey,
          ['USD', 'EUR', 'GBP', 'CAD', 'AUD', 'NZD', 'CHF'],
          setCountryCallback,
          () => toggleDataKey()
        )
      )

      resizeObserver.current.observe(svgRef.current.parentElement)
    } else {
      if (resizeObserver.current) {
        resizeObserver.current.disconnect()
      }
    }

    return () => {
      if (resizeObserver.current) {
        resizeObserver.current.disconnect()
      }
    }
  }, [isLoading, data, showChart, dataKey])

  return (
    <div className={styles.heatMap}>
      {showChart ? (
        chartDataLoading ? (
          <Spinner />
        ) : (
          <div className={styles.chartView}>
            <div className={styles.topWrapper}>
              <div
                className={styles.backButton}
                onClick={() => setShowChart(false)}
              >
                Back
              </div>
              <div className={styles.info}>
                <div className={styles.heatmapText}>
                  Name: {selectedCountryData?.name}
                </div>
                <div className={styles.heatmapText}>
                  Price: {selectedCountryData?.price}
                </div>
                <div className={styles.heatmapText}>
                  % Chg:{' '}
                  <span
                    style={{
                      color:
                        selectedCountryData?.changesPercentage < 0
                          ? 'var(--red)'
                          : 'var(--green)',
                    }}
                  >
                    {`${numeral(selectedCountryData?.changesPercentage).format(
                      '0.00'
                    )}%`}
                  </span>
                </div>
                {/* <div className={styles.heatmapText}>
                  200 day Avg: {selectedCountryData?.priceAvg200}
                </div> */}
              </div>
            </div>
            <div className={styles.chartWrapper}>
              <CoreLineChart
                data={chartData}
                customDomain={[
                  dataMin => dataMin * 0.96,
                  dataMax => dataMax * 1.04,
                ]}
                XAxisKey={'date'}
                showSingleYAxis
                metrics={[
                  {
                    key: 'close',
                    color: getRandomColor(),
                  },
                ]}
              />
            </div>
          </div>
        )
      ) : (
        <svg ref={svgRef}></svg>
      )}
    </div>
  )
}
