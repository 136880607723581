import {
  BALANCE_SHEET,
  CASH_FLOW_STATEMENT,
  COMPANY_GROWTH_TABLE,
  COMPANY_RATIOS_TABLE,
  INCOME_STATEMENT,
} from 'constants/tableConstants'

export const ComparisonChartPlottingConfig = {
  [COMPANY_GROWTH_TABLE]: {
    KeyMap: 'GrowthKeyMap',
    Label: 'Growth Metrics',
    Type: 'Company',
    APIKey: 'financial-growth',
    APICall: '',
  },
  [COMPANY_RATIOS_TABLE]: {
    KeyMap: 'FinancialRatiosKeyMap',
    Label: 'Financial Ratios',
    Type: 'Company',
    APIKey: 'ratios',
    APICall: '',
  },
  [BALANCE_SHEET]: {
    KeyMap: 'BalanceSheetKeyMap',
    Label: 'Balance Sheet',
    APIKey: 'balance-sheet-statement',
    Type: 'Company',
    APICall: '',
  },
  [INCOME_STATEMENT]: {
    KeyMap: 'IncomeStatementKeyMap',
    Label: 'Income Statement',
    Type: 'Company',
    APIKey: 'income-statement',
    APICall: '',
  },
  [CASH_FLOW_STATEMENT]: {
    KeyMap: 'CashFlowKeyMap',
    Label: 'Cash Flow',
    Type: 'Company',
    APIKey: 'cash-flow-statement',
    APICall: '',
  },
}

export const blockTableCollapseConfig = {
  [BALANCE_SHEET]: {
    calendarYear: {},
    period: {},
    totalAssets: {},
    totalCurrentAssets: {
      cashAndCashEquivalents: {},
      shortTermInvestments: {},
      cashAndShortTermInvestments: {},
      netReceivables: {},
      inventory: {},
      otherCurrentAssets: {},
    },
    totalNonCurrentAssets: {
      propertyPlantEquipmentNet: {},
      goodwill: {},
      intangibleAssets: {},
      longTermInvestments: {},
      taxAssets: {},
      otherNonCurrentAssets: {},
      otherAssets: {},
    },
    totalLiabilities: {},
    totalCurrentLiabilities: {
      accountPayables: {},
      shortTermDebt: {},
      taxPayables: {},
      deferredRevenue: {},
      otherCurrentLiabilities: {},
    },
    totalNonCurrentLiabilities: {
      longTermDebt: {},
      deferredRevenueNonCurrent: {},
      deferredTaxLiabilitiesNonCurrent: {},
      otherNonCurrentLiabilities: {},
      otherLiabilities: {},
    },
    capitalLeaseObligations: {},
    totalStockholdersEquity: {
      preferredStock: {},
      commonStock: {},
      retainedEarnings: {},
      accumulatedOtherComprehensiveIncomeLoss: {},
      othertotalStockholdersEquity: {},
    },
    totalLiabilitiesAndStockholdersEquity: {},
    minorityInterest: {},
    totalLiabilitiesAndTotalEquity: {},
    totalInvestments: {},
    totalDebt: {},
    netDebt: {},
  },
  [CASH_FLOW_STATEMENT]: {
    calendarYear: {},
    period: {},
    netCashProvidedByOperatingActivities: {
      netIncome: {},
      depreciationAndAmortization: {},
      deferredIncomeTax: {},
      stockBasedCompensation: {},
      changeInWorkingCapital: {},
      accountsReceivables: {},
      inventory: {},
      accountsPayables: {},
      otherWorkingCapital: {},
      otherNonCashItems: {},
    },

    netCashUsedForInvestingActivites: {
      investmentsInPropertyPlantAndEquipment: {},
      acquisitionsNet: {},
      purchasesOfInvestments: {},
      salesMaturitiesOfInvestments: {},
      otherInvestingActivites: {},
    },

    netCashUsedProvidedByFinancingActivities: {
      debtRepayment: {},
      commonStockIssued: {},
      commonStockRepurchased: {},
      dividendsPaid: {},
      otherFinancingActivites: {},
    },

    effectOfForexChangesOnCash: {},

    netChangeInCash: {
      cashAtBeginningOfPeriod: {},
      cashAtEndOfPeriod: {},
    },

    operatingCashFlow: {},

    capitalExpenditure: {},

    freeCashFlow: {},
  },
  [INCOME_STATEMENT]: {
    calendarYear: {},
    period: {},
    revenue: {
      costOfRevenue: {},
      grossProfit: {},
      grossProfitRatio: {},
    },

    operatingExpenses: {
      researchAndDevelopmentExpenses: {},
      generalAndAdministrativeExpenses: {},
      sellingAndMarketingExpenses: {},
      sellingGeneralAndAdministrativeExpenses: {},
      otherExpenses: {},
    },
    costAndExpenses: {},
    interestIncome: {},
    interestExpense: {},
    ebitda: {},
    ebitdaratio: {},
    operatingIncome: {},
    operatingIncomeRatio: {},
    totalOtherIncomeExpensesNet: {},
    incomeBeforeTax: {},
    incomeBeforeTaxRatio: {},
    incomeTaxExpense: {},
    netIncome: {},
    netIncomeRatio: {},
    eps: {},
    epsdiluted: {},
    weightedAverageShsOut: {},
    weightedAverageShsOutDil: {},
  },
  [COMPANY_RATIOS_TABLE]: {
    date: {},
    period: {},
    Liquidity: {
      currentRatio: {},
      quickRatio: {},
      cashRatio: {},
    },
    Solvency: {
      debtRatio: {},
      debtEquityRatio: {},
      longTermDebtToCapitalization: {},
      totalDebtToCapitalization: {},
      interestCoverage: {},
      cashFlowToDebtRatio: {},
      companyEquityMultiplier: {},
    },
    Coverage: {
      interestCoverage: {},
      cashFlowCoverageRatios: {},
      shortTermCoverageRatios: {},
      capitalExpenditureCoverageRatio: {},
      dividendPaidAndCapexCoverageRatio: {},
    },
    Efficiency: {
      cashConversionCycle: {},
      receivablesTurnover: {},
      payablesTurnover: {},
      inventoryTurnover: {},
      fixedAssetTurnover: {},
      assetTurnover: {},
      daysOfPayablesOutstanding: {},
      daysOfSalesOutstanding: {},
      daysOfInventoryOutstanding: {},
      operatingCycle: {},
    },
    Profitability: {
      grossProfitMargin: {},
      operatingProfitMargin: {},
      pretaxProfitMargin: {},
      netProfitMargin: {},
      returnOnAssets: {},
      returnOnEquity: {},
      returnOnCapitalEmployed: {},
      netIncomePerEBT: {},
      ebtPerEbit: {},
      ebitPerRevenue: {},
    },
    Tax: {
      effectiveTaxRate: {},
    },
    'Market Value': {
      dividendPayoutRatio: {},
      priceBookValueRatio: {},
      priceToBookRatio: {},
      priceToSalesRatio: {},
      priceEarningsRatio: {},
      priceToFreeCashFlowsRatio: {},
      priceToOperatingCashFlowsRatio: {},
      priceCashFlowRatio: {},
      priceEarningsToGrowthRatio: {},
      priceSalesRatio: {},
      dividendYield: {},
      operatingCashFlowPerShare: {},
      freeCashFlowPerShare: {},
      cashPerShare: {},
      payoutRatio: {},
      priceFairValue: {},
      enterpriseValueMultiple: {},
    },
  },
  [COMPANY_GROWTH_TABLE]: {
    date: {},
    period: {},
    'Balance Sheet Growth': {
      receivablesGrowth: {},
      inventoryGrowth: {},
      assetGrowth: {},
      debtGrowth: {},
      rdexpenseGrowth: {},
      sgaexpensesGrowth: {},
      bookValueperShareGrowth: {},
    },
    'Income Statement Growth': {
      revenueGrowth: {},
      grossProfitGrowth: {},
      operatingIncomeGrowth: {},
      netIncomeGrowth: {},
      epsgrowth: {},
      epsdilutedGrowth: {},
      ebitgrowth: {},
      weightedAverageSharesGrowth: {},
      weightedAverageSharesDilutedGrowth: {},
      dividendsperShareGrowth: {},
    },
    'Cash Flow Growth': {
      operatingCashFlowGrowth: {},
      freeCashFlowGrowth: {},
    },
    'Growth Per Share Metrics': {
      threeYShareholdersEquityGrowthPerShare: {},
      fiveYShareholdersEquityGrowthPerShare: {},
      tenYShareholdersEquityGrowthPerShare: {},
      threeYDividendperShareGrowthPerShare: {},
      fiveYDividendperShareGrowthPerShare: {},
      tenYDividendperShareGrowthPerShare: {},
      threeYRevenueGrowthPerShare: {},
      fiveYRevenueGrowthPerShare: {},
      tenYRevenueGrowthPerShare: {},
      threeYNetIncomeGrowthPerShare: {},
      fiveYNetIncomeGrowthPerShare: {},
      tenYNetIncomeGrowthPerShare: {},
      threeYOperatingCFGrowthPerShare: {},
      fiveYOperatingCFGrowthPerShare: {},
      tenYOperatingCFGrowthPerShare: {},
    },
  },
}

export const nonMetricRows = [
  'Liquidity',
  'Solvency',
  'Coverage',
  'Efficiency',
  'Profitability',
  'Tax',
  'Revenues',
  'Growth Per Share Metrics',
  'Cash Flow Growth',
  'Income Statement Growth',
  'Balance Sheet Growth',
  'Market Value',
]
