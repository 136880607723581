import React, { useEffect, useState } from 'react'
import styles from './account.module.scss'
import { CognitoPage } from './CognitoInfo/CognitoPage'
import { Clock } from './Clock'
import { ManageStripe } from './ManageStripe/ManageStripe'
import { Auth } from 'aws-amplify'
import { useIsGoogleUser } from 'core/AuthComponents/useIsAuthenticated'

export const AccountPage = () => {
  const [userAttributes, setUserAttributes] = useState({})
  const { isGoogleUser, isLoading } = useIsGoogleUser()

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userData = await Auth.currentAuthenticatedUser()
        const attributes = isGoogleUser
          ? userData?.signInUserSession?.idToken?.payload
          : userData?.attributes
        setUserAttributes(attributes)
      } catch (error) {
        console.error('err getting data: ', error)
      }
    }

    if (!isLoading) {
      fetchUserData()
    }
  }, [isGoogleUser, isLoading])

  const userName = userAttributes
    ? `${userAttributes.given_name} ${userAttributes.family_name}.`.trim()
    : 'User.'

  return (
    <div className={styles.accountPage}>
      <div className={styles.title}>Welcome, {userName || 'User'}</div>
      <Clock />
      <ManageStripe />
      <CognitoPage
        userAttributes={userAttributes}
        userAttributesIsLoading={isLoading}
      />
    </div>
  )
}
