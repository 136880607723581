import React from 'react'
import styles from './categoryPill.module.scss'
import { startCase } from 'lodash'
import { UilCheckSquare } from '@iconscout/react-unicons'

export const CategoryPill = ({ category, onClick, isSelected, dataMetric }) => {
  return (
    <div
      onClick={() => onClick(category)}
      className={styles.categoryPillWrapper}
      style={{
        border: isSelected ? '1.5px solid var(--primary-color)' : null,
      }}
    >
      <div
        className={styles.circle}
        style={{
          backgroundColor: isSelected ? 'var(--primary-color)' : 'transparent',
          border: isSelected
            ? '1px solid var(--primary-color)'
            : ' 1.5px solid var(--off-white)',
        }}
      />
      {/* <UilCheckSquare
        color={isSelected ? 'var(--primary-color)' : 'var(--white)'}
        size={20}
      /> */}
      <span className={styles.categoryPillText}>
        {startCase(category)} of {dataMetric}
      </span>
    </div>
  )
}
