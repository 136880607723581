import http from 'network/httpClient'
import dayjs from 'dayjs'

export const fetchYearsAndQuarters = async ({ queryKey }) => {
  const [, ticker] = queryKey
  const data = await http.get(
    'papi/data/api/v4/earning_call_transcript?symbol=' + ticker
  )
  const uniqueYears = Array.from(
    new Set(data.data.message.map(item => item[1]))
  )

  const finalData = data.data.message

  return { finalData, uniqueYears }
}

export const fetchQuarters = async ({ queryKey }) => {
  const [, ticker, selectedYear] = queryKey
  const data = await http.get(
    'papi/data/api/v4/batch_earning_call_transcript/' +
      ticker +
      '?year=' +
      selectedYear
  )

  return data.data?.message?.map(item => item?.quarter)
}

export const fetchContent = async ({ queryKey }) => {
  const [, ticker, selectedYear, selectedQuarter] = queryKey
  const response = await http.get(
    'papi/data/api/v3/earning_call_transcript/' +
      ticker +
      '?quarter=' +
      selectedQuarter +
      '&year=' +
      selectedYear
  )

  const data = response.data.message[0]
    ? response.data.message[0]
    : { content: '', date: '' }
  return { content: data.content, date: data.date }
}

export const fetchCompanyEarnings = async ({ queryKey }) => {
  const [, ticker] = queryKey
  const data = await http.get(
    'papi/data/api/v3/historical/earning_calendar/' + ticker
  )
  return data.data.message
}

export const fetchPriceData = async ({ queryKey }) => {
  const [, ticker] = queryKey
  const startDate = '2015-01-01'
  const endDate = dayjs().format('YYYY-MM-DD')
  const response = await http.get(
    `papi/data/api/v3/historical-price-full/${ticker}?from=${startDate}&to=${endDate}`
  )
  const data = response.data.message.historical

  return data.reverse()
}

const queries = {
  fetchContent,
  fetchQuarters,
  fetchYearsAndQuarters,
  fetchCompanyEarnings,
  fetchPriceData,
}

export default queries
