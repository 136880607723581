import { Line, LineChart, XAxis, YAxis, Tooltip } from 'recharts'
import numeral from 'numeral'

import _ from 'lodash'

export const ConfigurableChart = ({
  width,
  height,
  margin,
  CartesianGrid,
  apiKey,
  label,
  data,
}) => {
  const ColorCode = data => {
    const current = _.last(data)
    const previous = _.first(data)

    const change = current[apiKey] - previous[apiKey]

    if (change < 0) {
      return 'var(--red)'
    } else if (change > 0) {
      return 'var(--green)'
    } else {
      return 'var(--white)'
    }
  }

  const RenderTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label" style={{ color: 'var(--white)' }}>
            {`${label} : ${numeral(payload[0].value).format('0.0 a')} `}
          </p>
        </div>
      )
    }

    return null
  }

  return (
    <>
      <LineChart
        width={width}
        height={height}
        data={data}
        margin={margin || { top: 5, right: 30, left: 20, margin: -10 }}
      >
        {CartesianGrid && <CartesianGrid strokeDasharray="1 1" />}

        <Tooltip content={RenderTooltip} />
        <XAxis
          fontSize={8}
          dataKey="quarter"
          fontFamily="var(--font-family)"
          height={16}
          tickLine={{ stroke: 'var(--grey-accent)' }}
          axisLine={{ stroke: 'var(--grey-accent)' }}
          tick={{ stroke: 'var(--grey-accent)' }}
        />
        <YAxis
          tick={{}}
          width={30}
          fontSize={10}
          allowDataOverflow={true}
          domain={['auto', 'auto']}
          tickFormatter={tick => {
            return numeral(tick).format('0 a')
          }}
        />
        <Line
          type="monotone"
          dataKey={apiKey}
          stroke={ColorCode(data)}
          dot={false}
        />
      </LineChart>
      {label && label()}
    </>
  )
}
