import React, { useState, useEffect } from 'react'
import { Auth } from 'aws-amplify'
import styles from './manageStripe.module.scss'
import { Error } from 'core/Error/Error'
import { useUserGroup } from 'hooks/userGroupHook'
import http from 'network/httpClient'

export const ManageStripe = () => {
  const { userGroup } = useUserGroup()
  const [error, setError] = useState(false)
  const [jwt, setJwt] = useState('')

  useEffect(() => {
    Auth.currentSession()
      .then(res => {
        let accessToken = res.getAccessToken()
        setJwt(accessToken.getJwtToken())
      })
      .catch(err => console.log('Not authenticated', err))
  }, [])

  const baseURL = process.env.REACT_APP_API_ORIGIN

  const handleManageBilling = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser()
      const username = user.username

      const returnUrl = `${baseURL}terminal/account`

      const response = await http.post(`api/create-billing-session`, {
        outseekUserId: username,
        returnUrl: returnUrl,
      })

      if (response.status === 200) {
        window.location.href = response.data.url
      } else {
        throw new Error(`Error: ${response.data.error}`)
      }
    } catch (error) {
      setError(true)
      console.error('Error redirecting to billing portal:', error)

      const timer = setTimeout(() => setError(''), 5000)
      return () => clearTimeout(timer)
    }
  }

  const currentSubscription =
    userGroup === 'ProUsers' ? 'Pro' : 'No subscription'

  return (
    <div className={styles.manageStripe}>
      <div>Manage Subscription</div>
      <div>Current subscription: {currentSubscription}</div>
      <div
        id="checkout-and-portal-button"
        onClick={handleManageBilling}
        className={styles.manageStripeButton}
      >
        Manage Subscription and Billing
      </div>
      {error && <Error err={'Error getting billing session for user.'} />}
    </div>
  )
}
