export const ACTION_TOGGLE_PERCENT_CHANGE = 'ACTION_TOGGLE_PERCENT_CHANGE'
export const ACTION_TOGGLE_METRIC_VIZ_TYPE = 'ACTION_TOGGLE_METRIC_VIZ_TYPE'
export const ACTION_CHANGE_LINE_TYPE = 'ACTION_CHANGE_LINE_TYPE'
export const ACTION_CHANGE_FREQUENCY = 'ACTION_CHANGE_FREQUENCY'
export const ACTION_TOGGLE_LABELS = 'ACTION_TOGGLE_LABELS'
export const ACTION_TOGGLE_SINGLE_AXIS = 'ACTION_TOGGLE_SINGLE_AXIS'
export const ACTION_PUSH_METRIC = 'ACTION_PUSH_METRIC'
export const ACTION_REMOVE_METRIC = 'ACTION_REMOVE_METRIC'
export const ACTION_SET_INITIAL_DATA = 'ACTION_SET_INITIAL_DATA'
export const ACTION_SET_BULK_DATA = 'ACTION_SET_BULK_DATA'

const COMPARISON_CHART_ACTIONS = {
  ACTION_CHANGE_FREQUENCY,
  ACTION_CHANGE_LINE_TYPE,
  ACTION_PUSH_METRIC,
  ACTION_REMOVE_METRIC,
  ACTION_TOGGLE_METRIC_VIZ_TYPE,
  ACTION_TOGGLE_PERCENT_CHANGE,
  ACTION_TOGGLE_SINGLE_AXIS,
  ACTION_SET_BULK_DATA,
  ACTION_TOGGLE_LABELS,
  ACTION_SET_INITIAL_DATA,
}

export default COMPARISON_CHART_ACTIONS
