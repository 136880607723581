import React, { useContext } from 'react'
import styles from '../Auth.module.scss'
import { Link } from 'react-router-dom'
import ClipLoader from 'react-spinners/ClipLoader'
import { AuthContext } from '../AuthContext'
import Select from 'react-select'
import { occupationSelectStyles, occupations } from './constants'

const SignUp = ({ modal }) => {
  const {
    setUsername,
    setPassword,
    setEmail,
    setConfirmationCode,
    confirmationSent,
    confirmationError,
    setConfirmationError,
    handleConfirmationCode,
    signUp,
    showSignUp,
    setShowSignUp,
    signUpSuccess,
    signUpError,
    setSignUpError,
    username,
    setConfirmationSent,
    setGivenName,
    setFamilyName,
    setOccupation,
    handleSignInDemo,
  } = useContext(AuthContext)

  const handleSelectChange = selectedOption => {
    setOccupation(selectedOption.value)
    setSignUpError('')
  }

  const modifiedHandleSignUp = async () => {
    try {
      const result = await handleConfirmationCode()
      if (result && modal) {
        window.location.reload()
      }
    } catch (error) {}
  }

  const handleSignInDemoHomepage = async () => {
    try {
      const result = await handleSignInDemo()
      if (result && modal) {
        window.location.reload()
      }
    } catch (error) {}
  }

  return (
    <div className={styles.signInUpWrapper}>
      {!confirmationSent ? (
        <>
          <div className={styles.tryDemo} onClick={handleSignInDemoHomepage}>
            Continue without signing up
          </div>
          <div className={styles.orWrapper}>
            <div className={styles.line} />
            or
            <div className={styles.line} />
          </div>
          <div className={styles.authHeader}>Sign Up For Outseek Terminal</div>
          <div className={styles.nameWrapper}>
            <input
              className={styles.authNameInput}
              placeholder="First Name"
              onChange={e => {
                setGivenName(e.target.value)
                setSignUpError('')
              }}
              autocomplete="off"
              style={{
                border: signUpError ? '2px solid var(--red)' : null,
              }}
            />
            <input
              className={styles.authNameInput}
              placeholder="Last Name"
              onChange={e => {
                setFamilyName(e.target.value)
                setSignUpError('')
              }}
              autocomplete="off"
              style={{
                border: signUpError ? '2px solid var(--red)' : null,
              }}
            />
          </div>
          <Select
            options={occupations}
            onChange={handleSelectChange}
            placeholder="Select Occupation"
            isSearchable={false}
            styles={occupationSelectStyles}
          />
          <input
            className={styles.authInput}
            placeholder="Email"
            onChange={e => {
              setUsername(e.target.value)
              setEmail(e.target.value)
              setSignUpError('')
            }}
            autocomplete="off"
            style={{
              border: signUpError ? '2px solid var(--red)' : null,
            }}
          />
          <input
            className={styles.authInput}
            placeholder="Password"
            type="password"
            onChange={e => {
              setPassword(e.target.value)
              setSignUpError('')
            }}
            autocomplete="off"
            style={{
              border: signUpError ? '2px solid var(--red)' : null,
            }}
          />
          {signUpError && <div className={styles.authError}>{signUpError}</div>}
          <button className={styles.authConfirmButton} onClick={signUp}>
            Sign Up
          </button>
          <div className={styles.transparentButtonSmall}>
            By clicking "Sign Up", you agree to the{' '}
            <Link to={'./TermsOfService'}>
              <span className={styles.tosPP}>Outseek TOS</span>
            </Link>{' '}
            and{' '}
            <Link to={'./PrivacyPolicy'}>
              <span className={styles.tosPP}>Privacy Policy</span>
            </Link>
            .
          </div>
          <div
            className={styles.transparentButton}
            onClick={() => setShowSignUp(!showSignUp)}
          >
            <div>
              Already have an account?{' '}
              <span className={styles.showSignIn}>Sign In</span>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={styles.authHeader}>
            We sent you an email to {username} with a confirmation code.
          </div>
          <input
            className={styles.authInput}
            placeholder="Confirmation Code"
            onChange={e => {
              setConfirmationCode(e.target.value)
              setConfirmationError('')
            }}
            autocomplete="off"
            style={{
              border: confirmationError ? '2px solid var(--red)' : null,
            }}
          />
          {confirmationError && (
            <div className={styles.authError}>{confirmationError}</div>
          )}
          <button
            className={styles.authConfirmButton}
            onClick={modifiedHandleSignUp}
          >
            {signUpSuccess && <ClipLoader size={16} color="var(--white)" />}
            Confirm and Enter Terminal
          </button>
          <div
            className={styles.forgotPassword}
            onClick={() => setConfirmationSent(false)}
          >
            Cancel
          </div>
        </>
      )}
    </div>
  )
}

export default SignUp
