export const keyFunction = key => {
  const dontInclude = [
    'date',
    'symbol',
    'reportedCurrency',
    'cik',
    'fillingDate',
    'acceptedDate',
    'calendarYear',
    'period',
    'link',
    'finalLink',
  ]

  if (dontInclude.includes(key)) {
    return null
  } else {
    return key
  }
}

export const renameKey = {
  //income
  sellingGeneralAndAdministrativeExpenses: 'SG&A Expenses',
  sellingAndMarketingExpenses: 'S&M Expenses',
  generalAndAdministrativeExpenses: 'G&A Expenses',
  researchAndDevelopmentExpenses: 'R&D Expenses',
  ebitda: 'EBITDA',
  ebitdaratio: 'EBITDA Ratio',
  totalOtherIncomeExpensesNet: 'Other Income Expenses Net',
  eps: 'EPS',
  epsdiluted: 'EPS Diluted',
  costAndExpenses: 'Cost & Expenses',
  weightedAverageShsOut: 'Weighted Avg Shrs Out',
  weightedAverageShsOutDil: 'Weighted Avg Shrs Out Dil',
  //balance
  cashAndShortTermInvestments: 'Cash & Short Term Investments',
  cashAndCashEquivalents: 'Cash & Cash Equivalents',
  accumulatedOtherComprehensiveIncomeLoss:
    'Accumulated Comprehensive Income Loss',
  totalLiabilitiesAndStockholdersEquity: 'Liabilities & Stockholders Equity',
  totalLiabilitiesAndTotalEquity: 'Liabilities & Total Equity',
  //Cash flow
  netCashProvidedByOperatingActivities: 'Cash From Operating Activities',
  netCashUsedProvidedByFinancingActivities:
    'Cash Used From Financing Activities',
  netCashUsedForInvestingActivites: 'Cash Used For Investing Activities',
  investmentsInPropertyPlantAndEquipment: 'Investments In PPE',
}

export const colorFunction = value => {
  if (value < 0) {
    return 'var(--red)'
  } else {
    return 'var(--green)'
  }
}
