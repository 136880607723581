import React from 'react'
import styles from './aboutUs.module.scss'
import data from './aboutUsConfig'
import { NavBar } from 'pages/HomePage/HomePageComponents/NavBar/NavBar'
import { Footer } from 'pages/HomePage/HomePageComponents/Footer/HomePageFooter'
import { FaLinkedin } from 'react-icons/fa'
import { Helmet } from 'react-helmet-async'

const Team = () => {
  return (
    <>
      <Helmet>
        <title>{`Outseek | About Us`}</title>
        <meta
          name="description"
          content={
            'Outseek is a financial research and analysis terminal for the next generation of investors, built by the next generation of investors.'
          }
        />
      </Helmet>
      <NavBar />
      <div className={styles.aboutUs}>
        <div className={styles.title}>
          <div className={styles.aboutUsTitle}>About Us</div>
          <div className={styles.pillar} />
          <div className={styles.titleText}>
            Outseek is a financial research and analysis terminal for the next
            generation of investors, built by the next generation of investors.
          </div>
        </div>
        <div className={styles.teamGrid}>
          {data?.map(member => (
            <TeamMember key={member.id} {...member} />
          ))}
        </div>
        <div className={styles.contactUs}>Contact Us @ business@outseek.ca</div>
      </div>
      <Footer />
    </>
  )
}

const TeamMember = ({ name, position, note, link, image }) => {
  return (
    <div className={styles.teamMember}>
      {/* <img src={image} alt={name} className={styles.memberImage} /> */}
      <div className={styles.nameText}>{name}</div>
      <div className={styles.item}>
        <div className={styles.positionText}>{position}</div>
        <a href={link} target="_blank" rel="noreferrer">
          <FaLinkedin color={'var(--light-grey-accent)'} size={24} />
        </a>
      </div>
    </div>
  )
}

export default Team
