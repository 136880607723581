import { useEffect, useMemo } from 'react'

import { useLocation, useNavigate } from 'react-router'

import { useSearchParams } from 'react-router-dom'

export const TICKER_CACHE_KEY = 'ticker'

export const useURLSyncedGlobalTicker = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [search, setSearchParams] = useSearchParams()
  useEffect(() => {
    if (search.get(TICKER_CACHE_KEY)) {
      localStorage.setItem(TICKER_CACHE_KEY, search.get(TICKER_CACHE_KEY))
    } else {
      setSearchParams({
        [TICKER_CACHE_KEY]: localStorage.getItem(TICKER_CACHE_KEY) || 'AAPL',
      })
    }
  }, [location.search])

  const handleSetTicker = tickerIn => {
    navigate({
      pathname: location.pathname,
      state: location.state,
      search: `?${TICKER_CACHE_KEY}=${tickerIn}`,
    })
  }

  const handleSetTickerSideBar = tickerIn => {
    navigate(`companyOverview/?${TICKER_CACHE_KEY}=${tickerIn}`)
  }

  const ticker = useMemo(() => {
    return (
      search.get(TICKER_CACHE_KEY) || localStorage.getItem(TICKER_CACHE_KEY)
    )
  }, [search])

  return {
    handleSetTicker,
    handleSetTickerSideBar,
    ticker,
  }
}
