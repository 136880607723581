import React from 'react'
import styles from '../style.module.scss'
import { interpretFilingType } from '../utils'
import dayjs from 'dayjs'
import { UilFileExport } from '@iconscout/react-unicons'

export const RecentFiling = ({ filing }) => {
  const { type, fillingDate, finalLink } = filing

  return (
    <div className={styles.recentFilings}>
      <a
        className={styles.recentFiling}
        href={finalLink}
        target="_blank"
        rel="noreferrer"
      >
        <div className={styles.filingTitle}>
          <div>Most Recent {type} Filing</div>
          <div>({interpretFilingType(type)})</div>
          <div className={styles.filingDate}>
            {dayjs(fillingDate).format('MMM DD, YYYY')}
          </div>
        </div>
        <UilFileExport size={50} />
      </a>
    </div>
  )
}
