import React, { useState } from 'react'
import { PricingTable } from './PriceTable/PricingTable'
import { PricingBreakdown } from './PricingBreakdown/PricingBreakdown'
import { PricingHeader } from './PricingComponent/PricingView'
import styles from './TerminalPricing.module.scss'
import { Helmet } from 'react-helmet-async'

export const TerminalPricing = ({ jwt }) => {
  const [isYearly, setIsYearly] = useState(true)
  return (
    <div className={styles.pricingWrapper}>
      <Helmet>
        <title>{`Outseek | Pricing`}</title>
        <meta
          name="description"
          content={
            "Most affordable option on the market. Outseek's pricing is designed to enable all investors with institutional grade investment data."
          }
        />
      </Helmet>
      <div className={styles.pricingTitle}>
        <PricingHeader />
        <PricingTable jwt={jwt} isYearly={isYearly} setIsYearly={setIsYearly} />
        <PricingBreakdown
          jwt={jwt}
          isYearly={isYearly}
          setIsYearly={setIsYearly}
        />
      </div>
    </div>
  )
}
