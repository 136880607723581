import { useState } from 'react'
import Modal from '@mui/material/Modal'
import CoreButton from 'core/Button/CoreButton'
import styles from './CreateWatchlistModal.module.scss'
import times from '../../assets/times.svg'
import Box from '@mui/material/Box'

const CreateWatchlistModal = ({
  open,
  handleClose,
  createWatchlist,
  watchlists,
  updateState,
}) => {
  const [watchlistName, setWatchlistName] = useState('')
  const [watchlistDescription, setWatchlistDescription] = useState('')
  const [createWatchlistError, setCreateWatchlistError] = useState(false)

  const handleCreateWatchlist = () => {
    if (watchlistName.trim().length < 2 || watchlistName.trim().length > 25) {
      setCreateWatchlistError(
        'Name must be greater than 2 and less than 25 characters.'
      )
      return
    }

    if (watchlists.some(watchlist => watchlist.name === watchlistName.trim())) {
      setCreateWatchlistError('Watchlist name already exists.')
      return
    }

    handleClose()

    const newWatchlist = {
      body: {
        name: watchlistName,
        description: watchlistDescription,
        tickers: [],
      },
    }

    createWatchlist(newWatchlist).then(createdWatchlist => {
      updateState({
        selectedWatchlistId: createdWatchlist.watchlistId,
        selectedWatchlistName: createdWatchlist.name,
        selectedWatchlistDescription: createdWatchlist.description,
        watchlistTickers: createdWatchlist.tickers,
        activeTab: watchlists.length,
      })
    })

    setWatchlistName('')
    setWatchlistDescription('')
  }

  return (
    <Modal open={open} onClose={handleClose} disableAutoFocus={true}>
      <Box
        sx={{
          '&:focus': {
            outline: 'none',
          },
        }}
      >
        <div className={styles.watchlistModal}>
          <div className={styles.watchlistModalHeader}>
            <div>Create New Watchlist</div>
            <img
              src={times}
              alt="close"
              className={styles.closeModal}
              onClick={handleClose}
            />
          </div>
          <input
            value={watchlistName}
            onChange={e => {
              setWatchlistName(e.target.value)
              setCreateWatchlistError(false)
            }}
            placeholder="Watchlist name"
            className={styles.watchlistInputTitle}
            style={
              createWatchlistError ? { border: '2px solid var(--red)' } : null
            }
          />
          {createWatchlistError && (
            <div className={styles.watchlistError}>
              Name must be greater than 2 and less than 25 characters.
            </div>
          )}
          <textarea
            value={watchlistDescription}
            onChange={e => setWatchlistDescription(e.target.value)}
            placeholder="Watchlist description (optional)"
            className={styles.watchlistInputDesc}
            style={{ resize: 'none' }}
          />
          <div className={styles.modalConfirmButton}>
            <CoreButton
              color={'var(--primary-color)'}
              onClick={handleCreateWatchlist}
            >
              Create New Watchlist
            </CoreButton>
          </div>
        </div>
      </Box>
    </Modal>
  )
}

export default CreateWatchlistModal
