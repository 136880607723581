import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import BlockTable from '../Tables/blockTable'
import { fmpAPI } from '../../network/fmpAPI'
import {
  fetchColumns,
  BlockTableArray,
  BasicTableArray,
} from '../Tables/BlockTableUtils'
import { TransformFinancialStatementData } from '../../utils/DataTransform'
import { useSelector } from 'react-redux'
import BasicTable from '../Tables/basicTable'
import { FetchAPICallType } from '../../utils/FetchAPICallType'
import ConfigurableBreakdownChart from '../BreakdownCharts/Breakdown'
import { breakdownConfig } from '../BreakdownCharts/constants'
import {
  marketCompanyMapper,
  componentBlockMapper,
} from '../../constants/componentMappers'
import UniqueBlock from './UniqueBlock'
import WidgetBlock from './WidgetBlock'
import { CreateSubRows } from '../Tables/utils'
import { blockTableCollapseConfig } from '../Tables/constants'

const API = new fmpAPI()

const ItemBlock = ({ root, ticker, type, data, width, height }) => {
  const [_data, setData] = useState([])
  const [_columns, setColumns] = useState([])
  const [periodLength, setPeriodLength] = useState('annual')
  const [periodCount, setPeriodCount] = useState(9)
  const state = useSelector(state => state?.data[root]?.spec?.state)

  useEffect(() => {
    if (marketCompanyMapper.Market.includes(type)) {
      // what is type?
      API.fetch_market_info(FetchAPICallType(type)).then(data_raw => {
        setData(data_raw)
        setColumns(fetchColumns(type))
      })
    } else if (marketCompanyMapper.Company.includes(type)) {
      API.fetch_company_metrics(
        FetchAPICallType(type),
        ticker,
        periodLength
      ).then(data_raw => {
        setData(
          CreateSubRows(
            TransformFinancialStatementData(data_raw),
            blockTableCollapseConfig[type]
          )
        )
        setColumns(
          fetchColumns(
            type,
            data_raw.slice(0, periodCount).reverse(),
            periodCount
          )
        )
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticker, periodLength, periodCount])

  const handleTogglePeriodLength = useCallback(
    newPeriod => {
      return setPeriodLength(newPeriod)
    },
    [setPeriodLength]
  )
  const handleTogglePeriodCount = useCallback(
    newCount => {
      setPeriodCount(newCount)
    },
    [setPeriodCount]
  )

  const breakdownChartConfig = breakdownConfig[type]
  //TO DO: create a financial  statement component that gets dropped in here
  if (BlockTableArray.includes(type)) {
    return (
      _data &&
      _columns && (
        <BlockTable
          root={root}
          ticker={ticker}
          type={type}
          data={_data}
          columns={_columns}
          initialState={state}
          width={width}
          height={height}
          handleTogglePeriodLength={handleTogglePeriodLength}
          handleTogglePeriodCount={handleTogglePeriodCount}
          component={true}
        ></BlockTable>
      )
    )
  } else if (BasicTableArray.includes(type)) {
    return (
      _data &&
      _columns && (
        <BasicTable
          root={root}
          data={_data}
          columns={_columns}
          initialState={state}
          width={width}
          height={height}
        ></BasicTable>
      )
    )
  } else if (componentBlockMapper.widget.includes(type)) {
    return (
      <WidgetBlock
        root={root}
        ticker={ticker}
        type={type}
        data={data}
        width={width}
        height={height}
        title={type}
      />
    )
  } else if (componentBlockMapper.presetCharts.includes(type)) {
    return (
      <ConfigurableBreakdownChart
        ticker={ticker}
        breakdownChartConfig={breakdownChartConfig}
      />
    )
  } else {
    return (
      <UniqueBlock
        root={root}
        ticker={ticker}
        type={type}
        data={data}
        width={width}
        height={height}
        title={type}
      />
    )
  }
}

ItemBlock.propTypes = {
  type: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
}

export default ItemBlock
