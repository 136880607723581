import moment from 'moment'

export const intervals = [
  {
    label: '1D',
    from: moment().subtract(1, 'days').format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
  },
  {
    label: '3D',
    from: moment().subtract(3, 'days').format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
  },
  {
    label: '1W',
    from: moment().subtract(7, 'days').format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
  },
  {
    label: '1M',
    from: moment().subtract(1, 'months').format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
    takeNth: 10,
  },
  {
    label: '3M',
    from: moment().subtract(3, 'months').format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
    takeNth: 30,
  },
  {
    label: '1Y',
    from: moment().subtract(1, 'years').format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
    takeNth: 70,
  },
  {
    label: '3Y',
    from: moment().subtract(3, 'years').format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
    takeNth: 100,
  },
]

export const ORIENTATION_VERTICAL = 'vertical'
export const ORIENTATION_HORIZANTAL = 'horizantal'
