import React, { useState } from 'react'
import Spinner from 'core/ScrollableContainer/Spinner/spinner'
import { useScreeners } from 'hooks/useScreener'
import { Modal } from '@mui/material'
import styles from './style.module.scss'
import { CreateScreen } from './components/CreateScreen'
import { AvailableScreens } from './components/AvailableScreens'
import { ProtectedButton } from 'core/ProtectedButton/ProtectedButton'
import { Error } from 'core/Error/Error'
import { usePaidFeature } from 'hooks/usePaidFeature'

export const SavedScreens = ({
  filters,
  onScreenSelected,
  selectedScreen,
  setSelectedScreen,
}) => {
  const {
    readAll = {},
    isScreensLoading,
    isError,
    createScreener,
    deleteScreener,
  } = useScreeners()
  const [newModalOpen, setNewModalOpen] = useState(false)
  const [screenerModalOpen, setScreenerModalOpen] = useState(false)

  const { canCreateScreener } = usePaidFeature()

  const deleteAScreener = screenerId => {
    if (selectedScreen?.screenerId === screenerId) setSelectedScreen(null)
    deleteScreener({ screener_id: screenerId })
  }

  const screeners = readAll?.item?.Items

  return (
    <div className={styles.savedScreensWrapper}>
      <ProtectedButton
        onClick={() => setNewModalOpen(true)}
        modal={true}
        color="var(--primary-color)"
        redirectTo="/screener"
      >
        Save New Screen
      </ProtectedButton>
      <ProtectedButton
        onClick={() => setScreenerModalOpen(true)}
        modal={true}
        redirectTo="/screener"
      >
        Saved Screens
      </ProtectedButton>
      {selectedScreen && (
        <div className={styles.selectedScreen}>
          Screen:{' '}
          <span style={{ color: 'var(--primary-color)' }}>
            {selectedScreen?.name}
          </span>
        </div>
      )}
      <Modal open={screenerModalOpen} disableAutoFocus={true}>
        <div className={styles.modal}>
          {isScreensLoading ? (
            <Spinner />
          ) : isError ? (
            <Error err={'Error'} />
          ) : (
            <AvailableScreens
              screeners={screeners}
              deleteAScreener={deleteAScreener}
              onScreenSelected={onScreenSelected}
              setOpen={setScreenerModalOpen}
            />
          )}
        </div>
      </Modal>
      <Modal open={newModalOpen} disableAutoFocus={true}>
        <div className={styles.modal}>
          <CreateScreen
            createScreener={createScreener}
            filters={filters}
            setOpen={setNewModalOpen}
            onScreenCreated={newScreener => setSelectedScreen(newScreener)}
            canCreateScreener={canCreateScreener}
          />
        </div>
      </Modal>
    </div>
  )
}
