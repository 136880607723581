import styles from './Tooltip.module.scss'
import numeral from 'numeral'

export const TooltipTable = ({ profile, metrics, ratios, stockPrice }) => {
  return (
    <div className={styles.metricsTable}>
      {constants.map(({ accessor, label, dataType, dataSource }) => {
        const source =
          dataSource === 'profile'
            ? profile
            : dataSource === 'metrics'
            ? metrics
            : dataSource === 'ratios'
            ? ratios
              ? ratios[0]
              : null
            : stockPrice

        const value = source ? source[accessor] : null

        return (
          <div className={styles.metricsTableRow}>
            <span className={styles.metricsTableLabel}>{label}</span>
            <span className={styles.metricsTableValue}>
              {!value || value === 0 || isNaN(value)
                ? 'n/a'
                : dataType === 'percent'
                ? numeral(value).format('0.0%')
                : dataType === 'dollar'
                ? numeral(value).format('$0.00')
                : numeral(value).format('0.00 a')}
            </span>
          </div>
        )
      })}
    </div>
  )
}

const constants = [
  {
    accessor: 'mktCap',
    label: 'Mkt Cap',
    dataType: 'number',
    dataSource: 'profile',
  },
  {
    accessor: 'volume',
    label: 'Vol',
    dataType: 'number',
    dataSource: 'stockPrice',
  },
  {
    accessor: 'avgVolume',
    label: 'Avg. Vol',
    dataType: 'number',
    dataSource: 'stockPrice',
  },
  {
    accessor: 'pe',
    label: 'P/E',
    dataType: 'number',
    dataSource: 'stockPrice',
  },
  {
    accessor: 'eps',
    label: 'EPS',
    dataType: 'number',
    dataSource: 'stockPrice',
  },
  {
    accessor: 'priceToBookRatioTTM',
    label: 'P/B',
    dataType: 'number',
    dataSource: 'ratios',
  },
  // {
  //   accessor: 'netProfitMarginTTM',
  //   label: 'Profit Margin',
  //   dataType: 'percent',
  //   dataSource: 'ratios',
  // },
  {
    accessor: 'lastDiv',
    label: 'Last Div',
    dataType: 'dollar',
    dataSource: 'profile',
  },
]
