import { Modal } from '@mui/material'
import React from 'react'
import styles from './styles.module.scss'
import { Link } from 'react-router-dom'
import { UilArrowRight } from '@iconscout/react-unicons'
import CoreButton from 'core/Button/CoreButton'

export const ConfirmModal = ({ isOpen, handleClose }) => {
  return (
    <Modal
      open={isOpen}
      disableAutoFocus={true}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    >
      <div className={styles.modalOverlay}>
        <div className={styles.modalHeader}>
          <div>Outseek Copilot is a BETA feature.</div>
        </div>
        <div>
          Copilot is a beta feature and may return wrong information. Please
          report any issues to the team and use the feedback buttons at the end
          of each chat.
          <br />
          <br />
          <Link to="/roadmap" className={styles.link}>
            Learn about the current limitations of Copilot.
            <UilArrowRight size={30} />
          </Link>
          <br />
        </div>
        <div className={styles.modalFooter}>
          <CoreButton onClick={handleClose} width="100%">
            Got it!
          </CoreButton>
        </div>
      </div>
    </Modal>
  )
}
