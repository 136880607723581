import { media } from '../../assets/asset'
import { texts } from '../../TextVars/HomePageText'

export const config = [
  {
    label: 'Insider Trading',
    image: media.insiderTradingWebP,
    paragraph:
      'Our Insider Trading view offers an in-depth glimpse into company trading activities. Explore a comprehensive roster of insider trades and gain personalized insights into the specifics and volumes of their transactions.',
  },
  {
    label: texts.Institutional_Title,
    image: media.instCompanyWebP,
    paragraph: texts.Institutional_Subtitle,
  },
  {
    label: texts.Screener_Title,
    image: media.screenerWebP,
    paragraph: texts.StockScreener_Subtitle,
  },
  {
    label: texts.Earnings_Title,
    image: media.earningsWebP,
    paragraph: texts.Earnings_Subtitle,
  },
  {
    label: texts.NewsAndSocialSentiment_Title,
    image: media.companyNewsWebP,
    paragraph: texts.MarketSentiment_Subtitle,
  },
  {
    label: texts.CompanyOverview_Title,
    image: media.companyOverviewWebP,
    paragraph: texts.InDepthCompanyInfo_Subtitle,
  },
]
