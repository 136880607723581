import Select from 'react-select'
import { useState } from 'react'
import { breakdownSelectStyles } from '../../dashboard/BreakdownCharts/constants'

export const OutseekPeriodSelects = ({
  options,
  onChange,
  width,
  placeholder,
}) => {
  const [localValue, setLocalValue] = useState()
  const handleOnChange = e => {
    onChange(e.value)
    setLocalValue(e)
  }

  return (
    <div style={{ width: width }}>
      <Select
        value={localValue}
        options={options}
        styles={breakdownSelectStyles}
        isSearchable={false}
        onChange={handleOnChange}
        placeholder={'Period'}
      />
    </div>
  )
}

// import Slider from 'rc-slider'
// import 'rc-slider/assets/index.css'

// export const OutseekPeriodSelects = ({
//   options,
//   minValue,
//   maxValue,
//   onChange,
//   width,
//   placeholder,
//   value,
// }) => {
//   const handleOnChange = value => {
//     onChange(value)
//   }

//   return (
//     <div style={{ width }}>
//       <Slider
//         min={minValue}
//         max={maxValue}
//         defaultValue={value}
//         onChange={handleOnChange}
//         marks={options.reduce(
//           (marks, option) => ({ ...marks, [option.value]: option.label }),
//           {}
//         )}
//       />
//     </div>
//   )
// }
