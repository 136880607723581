// styles
import styles from './CompanyProfile.module.scss'
// core
import { TickerOverlayChart } from 'core/TickerOverlay/TickerOverlayChart'
import { QuoteBar } from 'core/QuoteBar/QuoteBar'
import Spinner from 'core/ScrollableContainer/Spinner/spinner'
import { BasicTable } from 'core/Tables/BasicTable'
import { useDataSource } from './dataSource/hooks'
import numeral from 'numeral'
// constants
import { splitColumns, keyExecutivesColumns } from './columns'
import { CardWrapper } from 'core/CardWrapper/CardWrapper'
import { TableKeyValue } from '../InstitutionalOwnership/components/TableKeyValue/TableKeyValue'
import {
  descriptionLabels,
  headQuarterLabels,
  Identification,
} from './constant'
import { AddressLink } from './components/AddressLink'
import TimeRange from '../NewsView/component/TimeRange'
import { ProfileIntervals } from './constant'
import { CoreLineChart } from 'core/Charts/GenericChart'
import { Helmet } from 'react-helmet-async'
import { useCallback } from 'react'

export const CompanyProfile = () => {
  const {
    Profile_UI,
    name,
    ticker,
    setTicker,
    interval,
    setInterval,
    isLoading,
    historicalDataIsLoading,
  } = useDataSource()

  const handleTimeRangeChange = useCallback(
    newInterval => {
      setInterval(newInterval)
    },
    [setInterval]
  )

  const NoData = () => {
    return (
      <div className={styles.noDataWrapper}>
        <div className={styles.noData}>No data available.</div>
      </div>
    )
  }

  const arrayCompanyProfile = [Profile_UI?.companyProfile]

  return (
    <div className={styles.companyProfileWrapper}>
      <Helmet>
        <title>{`Outseek | ${ticker} Profile`}</title>
        <meta
          name="description"
          content={"This page provides a company's profile."}
        />
      </Helmet>
      <QuoteBar
        updateTicker={tickerIn => setTicker(tickerIn)}
        ticker={ticker}
      />
      <div className={styles.companyProfileContentWrapper}>
        <div className={styles.descRow}>
          <CardWrapper
            label={`Price Chart`}
            // width={'calc(50% - 108px)'}
            width={'50%'}
            height={'100%'}
            content={
              historicalDataIsLoading ? (
                <Spinner />
              ) : (
                <div className={styles.companyOverviewChartTimeWrapper}>
                  <div className={styles.timeRange}>
                    <TimeRange
                      intervals={ProfileIntervals}
                      onChange={handleTimeRangeChange}
                      interval={interval}
                      fitContent
                      gap={'8px'}
                      background={'transparent'}
                    />
                  </div>
                  <TickerOverlayChart
                    ticker={ticker}
                    data={Profile_UI.historicalData}
                    xAxisKey={'date'}
                    view={true}
                    interval={interval}
                    barDataKey={'volume'}
                    lineDataKey={'close'}
                    YTickFormat={tick => numeral(tick).format('0.00a')}
                    barDomain={[0, dataMax => dataMax * 4.5]}
                  />
                </div>
              )
            }
          />
          <CardWrapper
            label={`Historical Employees to Revenue`}
            // width={'calc(50% - 108px)'}
            width={'50%'}
            height={'100%'}
            content={
              isLoading ? (
                <Spinner />
              ) : Profile_UI?.mergedData?.length > 0 ? (
                <CoreLineChart
                  data={Profile_UI?.mergedData}
                  XAxisKey={'periodOfReport'}
                  metrics={[
                    {
                      key: 'employeeCount',
                      color: 'var(--yellow)',
                      type: 'monotoneX',
                    },
                    {
                      key: 'revenue',
                      color: 'var(--purple)',
                      type: 'monotoneX',
                    },
                  ]}
                  // showSingleYAxis={'right'}
                  axisWidth={52}
                  showLegend={true}
                  noColor
                  xAxisHeight={12}
                />
              ) : (
                <NoData />
              )
            }
          />
          {/* <CardWrapper
            label={`Splits History`}
            width={200}
            height={'100%'}
            content={
              <BasicTable
                columns={splitColumns}
                data={Profile_UI?.splitsHistory}
              />
            }
          /> */}
        </div>
        <div className={styles.profileRow}>
          <CardWrapper
            label={`Profile`}
            width={'50%'}
            height={'100%'}
            content={
              isLoading ? (
                <Spinner />
              ) : Profile_UI?.companyProfile ? (
                <TableKeyValue
                  labels={descriptionLabels}
                  data={arrayCompanyProfile}
                />
              ) : (
                <NoData />
              )
            }
          />
          {/* <CardWrapper
            label={`Identification`}
            width={'fit-content'}
            height={'100%'}
            content={
              isLoading ? (
                <Spinner />
              ) : Profile_UI?.companyProfile ? (
                <div className={styles.identifierWrapper}>
                  {Identification.map((item, index) => {
                    return (
                      <div className={styles.dataRowWrapper}>
                        <div className={styles.dataRow}>
                          <div className={styles.label}>{item.label}</div>
                          <div className={styles.value}>
                            {item.key === 'isActivelyTrading'
                              ? Profile_UI?.companyProfile?.[item.key] === true
                                ? 'True'
                                : 'False'
                              : Profile_UI?.companyProfile?.[item.key]}
                          </div>
                        </div>
                        {index === Identification?.length - 1 ? null : (
                          <div className={styles.divider} />
                        )}
                      </div>
                    )
                  })}
                </div>
              ) : (
                <NoData />
              )
            }
          /> */}
          <CardWrapper
            label={`Headquarters`}
            // width={'100%'}
            width={'50%'}
            height={'100%'}
            content={
              isLoading ? (
                <Spinner />
              ) : Profile_UI?.companyProfile ? (
                <div className={styles.headQuarterWrapper}>
                  <TableKeyValue
                    labels={headQuarterLabels}
                    data={arrayCompanyProfile}
                  />
                  <AddressLink data={Profile_UI?.companyProfile} name={name} />
                </div>
              ) : (
                <NoData />
              )
            }
          />
        </div>
        <div className={styles.descRow}>
          <CardWrapper
            label={`Description`}
            width={'50%'}
            height={'100%'}
            content={
              <div className={styles.descWrapper}>
                {isLoading ? (
                  <Spinner />
                ) : Profile_UI?.companyProfile?.description ? (
                  Profile_UI?.companyProfile?.description
                ) : (
                  <NoData />
                )}
              </div>
            }
          />
          <CardWrapper
            label={'Key Executives'}
            width={'50%'}
            height={'100%'}
            content={
              isLoading ? (
                <Spinner />
              ) : Profile_UI?.outlookData?.keyExecutives?.length > 0 ? (
                <BasicTable
                  columns={keyExecutivesColumns}
                  data={Profile_UI?.outlookData?.keyExecutives}
                  overflowXScroll
                />
              ) : (
                <NoData />
              )
            }
          />
        </div>
      </div>
    </div>
  )
}
