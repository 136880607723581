import React, { useCallback } from 'react'
import { ChartPills } from 'pages/views/MarketOverview/components/SelectableTableWithChart/utils'
import { CoreLineChart } from 'core/Charts/GenericChart'
import styles from './style.module.scss'
import { NoData } from 'core/NoData/NoData'
import Spinner from 'core/ScrollableContainer/Spinner/spinner'
import TimeRange from 'pages/views/NewsView/component/TimeRange'
import { IndexIntervals } from '../data/constants'

export const WorldIndexCharts = ({
  data,
  setInterval,
  interval,
  metrics,
  isLoading,
  setMetrics,
}) => {
  const handleTimeRangeChange = useCallback(
    newInterval => {
      setInterval(newInterval)
    },
    [setInterval]
  )

  const handleDeleteTrend = useCallback(({ key }) => {
    setMetrics(currentMetrics => currentMetrics?.filter(m => m?.key !== key))
  }, [])

  return (
    <div className={styles.worldIndexChart}>
      {metrics.length > 0 ? (
        isLoading ? (
          <Spinner />
        ) : (
          <div className={styles.chartWrapper}>
            <div className={styles.indexChartTitle}>
              <ChartPills
                metrics={metrics}
                noPadding={true}
                handleDeleteTrend={handleDeleteTrend}
                useName={true}
              />
              <TimeRange
                intervals={IndexIntervals}
                onChange={handleTimeRangeChange}
                interval={interval}
                spaceBetween={'flex-end'}
                gap={'8px'}
                fitContent={true}
              />
            </div>
            <CoreLineChart
              showYAxis
              showSingleYAxis={'right'}
              XAxisKey={'date'}
              data={data}
              percentTooltip
              metrics={metrics}
              showXAxis={true}
              axisWidth={60}
            />
          </div>
        )
      ) : (
        <NoData label="Select an index to view chart." />
      )}
    </div>
  )
}
