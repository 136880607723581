import React from 'react'
import { useMemo } from 'react'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Brush,
  Legend,
  ResponsiveContainer,
  LabelList,
} from 'recharts'
import './styles.scss'
import { BarChart, Bar } from 'recharts'
import numeral from 'numeral'
import _ from 'lodash'
import moment from 'moment'
import dayjs from 'dayjs'
import { startCase } from 'lodash'

export const renderLegendGeneric = props => {
  const { payload } = props

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        columnGap: '12px',
        height: '28px',
        width: '100%',
        flexWrap: 'wrap',
        lineHeight: '24px',
        marginLeft: '16px',
        justifyContent: 'left',
      }}
    >
      {payload.map((entry, index) => (
        <li
          key={`item-${index}`}
          style={{
            color: entry?.color,
            whiteSpace: 'nowrap',
            fontWeight: 'var(--bold-text)',
            listStyle: 'square',
          }}
        >
          {_.startCase(entry.value)}
        </li>
      ))}
    </div>
  )
}

export const renderTooltipContentGeneric = (
  o,
  percent,
  absolute,
  fontSize = 10.5
) => {
  const { payload, label } = o

  return (
    <div className="customized-tooltip-content-core">
      <div
        className="list"
        style={{
          fontSize: fontSize,
          color: 'var(--white)',
        }}
      >
        {`${moment(label).format('ll')}`}
        {payload?.map((entry, index) => (
          <div
            className="listItem"
            key={`item-${index}`}
            style={{
              color: entry.color,
              display: 'flex',
              gap: '4px',
              fontSize: fontSize,
              flexDirection: 'row',
              // justifyContent: 'space-between',
            }}
          >
            <div style={{ color: 'var(--white)' }}>
              {`${_.startCase(entry.name)}: `}
            </div>
            {percent
              ? `${numeral(entry.value).format('0.0')}%`
              : absolute
              ? Math.abs(entry.value)
              : `${numeral(entry.value).format('0.000 a')} `}
          </div>
        ))}
      </div>
    </div>
  )
}

export const renderWidgetTooltipContentGeneric = o => {
  const { payload } = o
  const dateValue = payload[0]?.payload?.date

  return (
    <div className="customized-tooltip-content-core">
      <div
        className="list"
        style={{
          fontSize: '10.5px',
          color: 'var(--white)',
        }}
      >
        {dateValue && `${dayjs(dateValue).format('MMM, YY')}`}
        {payload?.map((entry, index) => (
          <div
            className="listItem"
            key={`item-${index}`}
            style={{
              color: entry.color,
              display: 'flex',
              gap: '4px',
              fontSize: '10.5px',
              flexDirection: 'row',
            }}
          >
            {`${numeral(entry.value).format('0.000 a')} `}
          </div>
        ))}
      </div>
    </div>
  )
}

export const CoreLineChart = ({
  data = [],
  metrics = [],
  XAxisKey,
  dot = false,
  showYAxis = true,
  showXAxis = true,
  hideTicks = false,
  brush = false,
  showLegend = false,
  cartesianGrid = false,
  showSingleYAxis = false,
  standardTickFormatter,
  customTickFormatter,
  decimalCount,
  renderTooltip,
  percentTooltip,
  xAxisFontSize = 11,
  standardYAxis,
  customDomain,
  yearOnly,
  axisWidth,
  xAxisHeight,
  noColor,
}) => {
  // let yAxMetric // yAxMetric chg by paul
  return (
    <ResponsiveContainer width="100%" height="99%">
      <LineChart
        data={data}
        margin={{
          top: 5,
          bottom: 5,
        }}
      >
        {cartesianGrid && <CartesianGrid />}
        {showLegend && (
          <Legend
            // content={renderLegendGeneric}
            align={'center'}
            verticalAlign={'top'}
            height={28}
            formatter={(value, entry, index) => {
              return _.startCase(entry.value)
            }}
            iconType="line"
          />
        )}
        {showXAxis && (
          <XAxis
            dataKey={XAxisKey}
            tickLine={{ stroke: 'var(--grey-accent)' }}
            axisLine={{ stroke: 'var(--grey-accent)' }}
            // tick={{
            //   stroke: 'var(--grey-accent)',
            //   fontWeight: '300',
            // }}
            style={{ fontSize: xAxisFontSize }}
            height={xAxisHeight ? xAxisHeight : 19}
            tickFormatter={tick => {
              return yearOnly
                ? dayjs(tick).format('YY' + "'")
                : dayjs(tick).format('MMM D, YY') + "'"
            }}
          />
        )}
        {showSingleYAxis && (
          <YAxis
            style={{ fontSize: 13 }}
            orientation={showSingleYAxis === 'right' ? 'right' : 'left'}
            domain={customDomain ? customDomain : ['auto', 'auto']}
            tickFormatter={tick => {
              if (standardTickFormatter) {
                if (tick < 1000) {
                  return numeral(tick).format('0a')
                } else {
                  return numeral(tick).format('0.0a')
                }
              } else if (customTickFormatter) {
                if (decimalCount === 2) {
                  return numeral(tick).format('0.00a')
                } else if (decimalCount === 1) {
                  return numeral(tick).format('0.0a')
                } else if (decimalCount === 0) {
                  return numeral(tick).format('0a')
                } else {
                  return numeral(tick).format('0a')
                }
              } else if (percentTooltip) {
                return `${numeral(tick).format('0.0')}%`
              } else {
                return numeral(tick).format('0.0 a')
              }
            }}
            width={axisWidth ? axisWidth : 48}
          />
        )}
        {/* {showYAxis &&
          metrics?.map(metric => {
            return <YAxis connectNulls dataKey={metric} width={0} />
          })} */}
        <CartesianGrid
          strokeDasharray="0"
          vertical={false}
          stroke="var(--button-hover)"
        />
        {percentTooltip ? (
          <Tooltip
            content={content =>
              renderTooltipContentGeneric(content, true, false)
            }
            filterNull={false}
            cursor={{
              stroke: 'var(--grey-accent)',
              strokeWidth: 1,
              strokeDasharray: 4,
            }}
          />
        ) : renderTooltip ? (
          <Tooltip
            content={renderTooltip}
            filterNull={false}
            cursor={{
              stroke: 'var(--grey-accent)',
              strokeWidth: 1,
              strokeDasharray: 4,
            }}
          />
        ) : (
          <Tooltip
            content={content =>
              renderTooltipContentGeneric(content, false, false)
            }
            filterNull={false}
            cursor={{
              stroke: 'var(--grey-accent)',
              strokeWidth: 1,
              strokeDasharray: 4,
            }}
          />
        )}
        {/* <Tooltip content={renderTooltipContentPercent} filterNull={false} /> */}
        {metrics?.map((metric, i) => {
          //  if (i === 0) { // yAxMetric chg by paul
          //   yAxMetric = metric // yAxMetric chg by paul
          // } // yAxMetric chg by paul
          return (
            <>
              {!showSingleYAxis && showYAxis && (
                <YAxis
                  style={{ fontSize: 13 }}
                  domain={customDomain ? customDomain : ['auto', 'auto']}
                  yAxisId={metric.key}
                  // yAxisId={yAxMetric.key} // yAxMetric chg by paul
                  orientation={
                    standardYAxis ? 'left' : i % 2 === 0 ? 'right' : 'left'
                  }
                  tick={{
                    stroke: noColor ? null : metric.color,
                    // stroke: yAxMetric.color, // yAxMetric chg by paul
                    fontWeight: '300',
                  }}
                  tickLine={{ stroke: 'var(--grey-accent)' }}
                  hide={hideTicks}
                  tickFormatter={tick => {
                    if (standardTickFormatter) {
                      if (tick < 1000) {
                        return numeral(tick).format('0a')
                      } else {
                        return numeral(tick).format('0.0a')
                      }
                    } else if (customTickFormatter) {
                      if (decimalCount === 2) {
                        return numeral(tick).format('0.00a')
                      } else if (decimalCount === 1) {
                        return numeral(tick).format('0.0a')
                      } else if (decimalCount === 0) {
                        return numeral(tick).format('0a')
                      } else {
                        return numeral(tick).format('0a')
                      }
                    } else {
                      return numeral(tick).format('0.0a')
                    }
                  }}
                  axisLine={{
                    stroke: metric.color,
                    // stroke: yAxMetric.color, // yAxMetric chg by paul
                    strokeWidth: 1,
                  }}
                  width={axisWidth ? axisWidth : 48}
                ></YAxis>
              )}
              {showSingleYAxis ? (
                <Line
                  type={metric?.type}
                  dot={dot}
                  connectNulls
                  dataKey={metric?.key}
                  stroke={metric?.color}
                  strokeWidth={metric?.strokeWidth}
                />
              ) : (
                <Line
                  type={metric?.type}
                  dot={dot}
                  connectNulls
                  dataKey={metric?.key}
                  yAxisId={metric.key}
                  stroke={metric?.color}
                  strokeWidth={metric?.strokeWidth}
                />
              )}
            </>
          )
        })}

        {brush && <Brush dataKey={XAxisKey} height={30} stroke="#8884d8" />}
      </LineChart>
    </ResponsiveContainer>
  )
}

export const CoreBarChart = ({
  data = [],
  metrics = [],
  XAxisKey,
  showYAxis = true,
  showXAxis = true,
  widgetToolTip,
  size,
  mirrored = false,
  opacity,
  zeroTick = false,
  onlyYear = false,
  rightYAxis = false,
  axisWidth,
  tickFormat,
  showLegend,
  legendFormat,
  barSize,
  xAxisHeight,
}) => {
  const processedData = useMemo(
    () =>
      mirrored
        ? data.map(item => ({
            ...item,
            [metrics[1].key]: -Math.abs(item[metrics[1].key]),
          }))
        : data,
    [data, metrics, mirrored]
  )

  const processedMetrics = useMemo(
    () =>
      metrics.map((metric, index) => ({
        ...metric,
        stackId: 'stack',
      })),
    [metrics]
  )

  const tickFormatter = useMemo(
    () =>
      mirrored
        ? tick => dayjs(tick).format('YY') + "'"
        : onlyYear
        ? tick => dayjs(tick).format('YY' + "'")
        : tick => dayjs(tick).format('MMM DD, YY') + "'",
    [mirrored, onlyYear]
  )

  return (
    <ResponsiveContainer width="100%" height="99%">
      <BarChart
        width={200}
        height={200}
        data={processedData}
        stackOffset={mirrored ? 'sign' : null}
        pure
      >
        <CartesianGrid
          strokeDasharray="0"
          vertical={false}
          stroke="var(--button-hover)"
        />
        {showXAxis && (
          <XAxis
            dataKey={XAxisKey}
            tickFormatter={tickFormatter}
            tick={{
              stroke: 'var(--grey-accent)',
              fontWeight: '200',
            }}
            style={{ fontSize: 11 }}
            height={mirrored ? 14 : xAxisHeight ? xAxisHeight : 19}
          />
        )}
        {showYAxis && (
          <YAxis
            tickFormatter={tick => {
              if (zeroTick === true) {
                return numeral(tick).format('0a')
              } else if (tickFormat) {
                return numeral(tick).format('0.00a')
              } else {
                tick > 1000 || tick < -1000
                  ? numeral(Math.abs(tick)).format('0.0a')
                  : numeral(Math.abs(tick)).format('0a')
              }
            }}
            style={{ fontSize: 13 }}
            orientation={rightYAxis ? 'right' : 'left'}
            width={axisWidth ? axisWidth : 38}
            domain={
              mirrored
                ? [dataMin => dataMin * 1.2, dataMax => dataMax * 1.2]
                : ['auto', 'auto']
            }
          />
        )}
        {showLegend && (
          <Legend
            align={metrics.length > 2 ? 'center' : 'center'}
            verticalAlign={'top'}
            height={28}
            formatter={legendFormat}
            iconType="plainline"
          />
        )}
        {widgetToolTip ? (
          <Tooltip content={renderWidgetTooltipContentGeneric} />
        ) : mirrored ? (
          <Tooltip
            content={content =>
              renderTooltipContentGeneric(content, false, true)
            }
          />
        ) : (
          <Tooltip
            content={content =>
              renderTooltipContentGeneric(content, false, false)
            }
          />
        )}
        {processedMetrics?.map((metric, index) => (
          <Bar
            dataKey={metric.key}
            fill={metric.color}
            opacity={opacity ? opacity : 1}
            stackId={mirrored ? metric.stackId : null}
            key={metric.key}
            radius={[4, 4, 0, 0]}
            barSize={barSize ? barSize : null}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  )
}

const barChartTwoTooltip = o => {
  const { payload, label } = o
  return (
    <div className="customized-tooltip-content-core">
      <div
        className="list"
        style={{
          fontSize: '10.5px',
          color: 'var(--white)',
        }}
      >
        {payload?.map((entry, index) => (
          <div
            className="listItem"
            key={`item-${index}`}
            style={{
              color: entry.color,
              display: 'flex',
              gap: '4px',
              fontSize: '12px',
              flexDirection: 'row',
            }}
          >
            <div style={{ color: 'var(--white)' }}>{`${label}: `}</div>
            {entry.value}
          </div>
        ))}
      </div>
    </div>
  )
}

export const VerticalCurrentBarChart = ({
  data = [],
  showYAxis = true,
  showXAxis = true,
  opacity,
}) => {
  let symbolData = []

  if (data && data[0]) {
    symbolData = Object.keys(data[0])
      .filter(key => key !== 'symbol' && key !== 'consensus')
      .map(key => ({ key: startCase(key), value: data[0][key] }))
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        data={symbolData}
        layout="vertical"
        width={'100%'}
        height={'100%'}
      >
        {showXAxis && (
          <XAxis
            type="number"
            tick={{
              stroke: 'var(--grey-accent)',
              fontWeight: '300',
            }}
            domain={['auto', dataMax => dataMax * 1.1]}
            style={{ fontSize: 11 }}
            display={'none'}
            height={0}
          />
        )}
        {showYAxis && (
          <YAxis
            type="category"
            dataKey="key"
            width={84}
            style={{ fontSize: 12 }}
          />
        )}
        <Tooltip content={barChartTwoTooltip} />
        <Bar
          dataKey="value"
          fill={'var(--primary-color'}
          opacity={opacity ? opacity : 1}
          radius={[0, 4, 4, 0]}
        >
          <LabelList
            dataKey="value"
            position="right"
            fill="var(--light-grey-accent)"
            style={{ fontSize: 11 }}
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  )
}
