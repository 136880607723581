import { useQuery } from 'react-query'
import { useState, useEffect, useMemo, useCallback } from 'react'
import { Auth } from 'aws-amplify'
import { Link } from 'react-router-dom'
import { PLUS_USERS, PRO_USERS, FREE_USERS } from 'constants/appConstants'
import {
  getFeatureLimit as getLimit,
  canAccessFeature as canAccess,
} from 'pages/Pricing/PriceTable/pricingConfig'
import { UilLock } from '@iconscout/react-unicons'

const fetchUserGroup = async () => {
  const user = await Auth.currentAuthenticatedUser({ bypassCache: true })
  const groups =
    user.signInUserSession.accessToken.payload['cognito:groups'] || []

  if (groups.length > 0) {
    if (groups.includes(PRO_USERS)) return PRO_USERS
    if (groups.includes(PLUS_USERS)) return PLUS_USERS
  }
  return FREE_USERS
}

export const useUserGroup = () => {
  const [isUserInPlan, setIsUserInPlan] = useState(false)

  const {
    data: userGroup,
    error,
    isError,
  } = useQuery(['userGroup'], fetchUserGroup, {})

  const getFeatureLimit = useCallback(
    feature => getLimit(userGroup, feature),
    [userGroup]
  )

  const canAccessFeature = useCallback(
    (feature, limit) => canAccess(userGroup, feature, limit),
    [userGroup]
  )

  const groups = {
    PLUS_USERS,
    PRO_USERS,
    FREE_USERS,
  }

  const upgradeSelectOption = {
    value: 'upgrade',
    label: (
      <Link to="/terminal/upgrade">
        <div style={{ width: '100%' }}>
          <UilLock color={'var(--primary-color)'} size={20} />
        </div>
      </Link>
    ),
    isDisabled: true,
  }

  const UpgradeTable = () => {
    return (
      <Link to="/terminal/upgrade">
        <div style={{ width: '100%', cursor: 'pointer' }}>
          <UilLock color={'var(--light-grey-accent)'} size={20} />
        </div>
      </Link>
    )
  }

  return {
    userGroup,
    groups,
    isUserInPlan,
    error,
    getFeatureLimit,
    canAccessFeature,
    upgradeSelectOption,
    UpgradeTable,
  }
}
