import { startCase, camelCase } from 'lodash'

export const reformatName = name => {
  const nameToCamelCase = camelCase(name)
  const nameToStartCase = startCase(nameToCamelCase)
  return nameToStartCase
}

export const mergeDataByDate = (priceData, transactions) => {
  return priceData?.map(item => {
    const insiderTransactionsForDate = transactions?.filter(
      transaction => transaction?.transactionDate === item?.date
    )

    return {
      ...item,
      insiderTransactions: insiderTransactionsForDate,
    }
  })
}

export const findMostCommon = (data, key) => {
  const count = {}
  data.forEach(item => {
    if (count[item[key]]) {
      count[item[key]] += 1
    } else {
      count[item[key]] = 1
    }
  })

  const sortedEntries = Object.entries(count).sort((a, b) => b[1] - a[1])
  return sortedEntries.length ? sortedEntries[0][0] : null
}

export const calculateAverage = (data, key) => {
  let validCount = 0
  const sum = data.reduce((accum, item) => {
    const value = parseFloat(item[key] || 0)
    if (value > 0) {
      validCount++
      return accum + value
    }
    return accum
  }, 0)
  return validCount ? sum / validCount : 0
}

export const countAcquisitionsAndDispositions = data => {
  const counts = {
    acquisitions: 0,
    dispositions: 0,
  }

  data.forEach(item => {
    if (item.acquistionOrDisposition === 'A') {
      counts.acquisitions += 1
    } else {
      counts.dispositions += 1
    }
  })

  return counts
}

export const formatString = str => {
  const delimiters = [':', ',', '.']
  const processSegment = (segment, remainingDelimiters) => {
    if (remainingDelimiters?.length === 0) {
      return startCase(segment)
    }
    const [currentDelimiter, ...rest] = remainingDelimiters
    const segments = segment?.split(currentDelimiter)
    return segments
      ?.map(s => processSegment(s?.trim(), rest))
      ?.join(currentDelimiter + ' ')
  }

  return processSegment(str, delimiters)
}
