import * as d3 from 'd3'
import numeral from 'numeral'
import dayjs from 'dayjs'

const scores = [
  {
    name: 'Valuation',
    score: 3,
  },
  {
    name: 'Profitability',
    score: 4,
  },
  {
    name: 'Efficiency',
    score: 1,
  },
  {
    name: 'Growth',
    score: 4,
  },
  {
    name: 'Health',
    score: 5,
  },
]
const treeData = {
  children: [
    { value: 40, label: 'Energy' },
    { value: 18, label: 'Real Estate' },
    { value: 13, label: 'Utilities' },
    { value: 6, label: 'Healthcare' },
    { value: 5, label: 'Materials' },
    { value: 4, label: 'Financials' },
    { value: 3, label: 'Industrials' },
    { value: 11, label: 'Other' },
  ],
  name: 'Industry Weightings',
}

const overAllScore =
  scores.reduce((acc, curr) => acc + curr.score, 0) / scores.length

const scoreColorScale = d3
  .scaleLinear()
  .domain([0, 5])
  .range(['#f84f31', '#21ad3d'])
  .interpolate(d3.interpolateHcl)

const scoreColor = scoreColorScale(overAllScore)

const earningsData = [
  {
    date: '2017-11-02',
    eps: 0.52,
    epsEstimated: 0.47,
    changePercent: -0.85057,
  },
  {
    date: '2018-02-01',
    eps: 0.97,
    epsEstimated: 0.96,
    changePercent: -3.3,
  },
  {
    date: '2018-05-01',
    eps: 0.68,
    epsEstimated: 0.67,
    changePercent: 0.75325,
  },
  {
    date: '2018-07-31',
    eps: 0.59,
    epsEstimated: 0.55,
    changePercent: 1.21,
  },
  {
    date: '2018-11-01',
    eps: 0.73,
    epsEstimated: 0.7,
    changePercent: -0.99256,
  },
  {
    date: '2019-01-29',
    eps: 1.05,
    epsEstimated: 1.04,
    changePercent: 1.23,
  },
  {
    date: '2019-04-30',
    eps: 0.62,
    epsEstimated: 0.59,
    changePercent: 0.30494,
  },
  {
    date: '2019-07-30',
    eps: 0.55,
    epsEstimated: 0.52,
    changePercent: -1.57,
  },
  {
    date: '2019-10-30',
    eps: 0.76,
    epsEstimated: 0.71,
    changePercent: 0.61479,
  },
  {
    date: '2020-01-28',
    eps: 1.25,
    epsEstimated: 1.14,
    changePercent: -0.03698681,
  },
  {
    date: '2020-04-30',
    eps: 0.64,
    epsEstimated: 0.57,
    changePercent: 0.99217,
  },
  {
    date: '2020-07-30',
    eps: 0.65,
    epsEstimated: 0.51,
    changePercent: 3.28,
  },
  {
    date: '2020-10-29',
    eps: 0.73,
    epsEstimated: 0.7,
    changePercent: -1.98,
  },
  {
    date: '2021-01-27',
    eps: 1.68,
    epsEstimated: 1.41,
    changePercent: -1.74,
  },
  {
    date: '2021-04-28',
    eps: 1.4,
    epsEstimated: 0.99,
    changePercent: -0.41607,
  },
  {
    date: '2021-07-27',
    eps: 1.3,
    epsEstimated: 1.01,
    changePercent: 0.45673,
  },
  {
    date: '2021-10-28',
    eps: 1.24,
    epsEstimated: 1.24,
    changePercent: -0.58784,
  },
  {
    date: '2022-01-26',
    eps: 1.41,
    epsEstimated: 1.35,
    changePercent: 0.00143,
  },
  {
    date: '2022-05-02',
    eps: 1.02,
    epsEstimated: 1.0,
    changePercent: 0.25,
  },
  {
    date: '2022-07-26',
    eps: 0.88,
    epsEstimated: 0.85,
    changePercent: -0.45,
  },
  {
    date: '2022-10-27',
    eps: 1.15,
    epsEstimated: 1.1,
    changePercent: 0.6,
  },
  {
    date: '2023-01-31',
    eps: 0.97,
    epsEstimated: 0.94,
    changePercent: 0.15,
  },
  {
    date: '2023-05-01',
    eps: 1.06,
    epsEstimated: 1.03,
    changePercent: -0.34,
  },
  {
    date: '2023-07-25',
    eps: 0.81,
    epsEstimated: 0.78,
    changePercent: 1.8,
  },
  {
    date: '2023-10-26',
    eps: 0.93,
    epsEstimated: 0.9,
    changePercent: -1.2,
  },
  {
    date: '2024-01-30',
    eps: 1.28,
    epsEstimated: 1.25,
    changePercent: 2.1,
  },
  {
    date: '2024-05-01',
    eps: 1.1,
    epsEstimated: 1.05,
    changePercent: -0.75,
  },
  {
    date: '2024-07-30',
    eps: null,
    epsEstimated: 1.32,
    changePercent: null,
  },
]

const earnings = `
  - Revenue for Q1 Fiscal Year 2024 was $119.6 billion, up 2% from a year ago despite having one less week in the quarter

  - EPS was $2.18, up 16% from a year ago and an all time record
  
  - iPhone revenue was $69.7 billion, up 6% yoy with all time records in several countries and regions
  
  - Mac revenue was $7.8 billion, up 1% yoy driven by the latest M3 powered MacBook Pro models
  
  - iPad revenue was $7 billion, down 25% yoy due to a difficult compare with the launch of the M2 iPad Pro and 10th generation iPad last year
  
  - Wearables, Home and Accessories revenue was $12 billion, down 11% yoy due to a challenging launch compare and an extra week of sales last year
  
  - Services revenue set an all time record of $23.1 billion, up 11% yoy with double digit growth in paid subscriptions
  
  - Apple has set a new record for its installed base, surpassing 2.2 billion active devices
  
  - Apple Vision Pro, the most advanced personal electronics device ever, will be available in Apple stores in the U.S. with expansion to other countries later this year.`

const earningsArray = earnings
  .trim()
  .split('-')
  .filter(text => text.trim() !== '')

const earningsJSX = earningsArray.map((point, index) => (
  <p key={index} style={{ textAlign: 'left' }}>
    - {point.trim()}
  </p>
))

const indexData = [
  {
    symbol: 'GSPC',
    name: 'S&P 500',
    price: 5123.69,
    changesPercentage: -0.6529,
    change: -33.6699,
  },
  {
    symbol: 'DJI',
    name: 'Dow Jones Industrial Average',
    price: 38722.69,
    changesPercentage: -0.177,
    change: -68.6602,
  },
  {
    symbol: 'IXIC',
    name: 'NASDAQ Composite',
    price: 16085.1131,
    changesPercentage: -1.16,
    change: -188.26,
  },
]

export const columns = [
  {
    Header: 'Asset Name',
    accessor: 'asset',
    width: 80,
    Cell: Cell => {
      return (
        <span
          style={{ color: 'var(--primary-color)', textDecoration: 'underline' }}
        >
          {Cell.value}
        </span>
      )
    },
  },
  {
    Header: 'Holder Name',
    accessor: 'name',
    minWidth: 30,
    width: 340,
    maxWidth: 340,
  },
  {
    Header: 'Market Value',
    accessor: 'marketValue',
    minWidth: 70,
    width: 100,
    maxWidth: 300,
    Cell: e => (
      <div style={{ maxWidth: '220px' }}>
        {numeral(e.value).format('0.000 a')}
      </div>
    ),
  },
  {
    Header: '# Of Shares',
    accessor: 'sharesNumber',
    minWidth: 70,
    width: 100,
    maxWidth: 300,
    Cell: e => (
      <div style={{ maxWidth: '220px' }}>
        {numeral(e.value).format('0.000 a')}
      </div>
    ),
  },
  {
    Header: 'Weight',
    accessor: 'weightPercentage',
    minWidth: 70,
    width: 70,
    maxWidth: 300,
    Cell: e => (
      <div style={{ maxWidth: '220px' }}>
        {numeral(e.value).format('0.00 a')}%
      </div>
    ),
  },
  {
    Header: 'Updated',
    accessor: 'updated',
    minWidth: 30,
    width: 100,
    maxWidth: 300,
    Cell: e => {
      const date = () => {
        return dayjs(e.value).format('MMM DD, YYYY')
      }
      return <div style={{ maxWidth: '220px' }}>{date()} </div>
    },
  },
]

const tableData = [
  {
    asset: 'MSFT',
    name: 'Microsoft Corp',
    sharesNumber: 53912431,
    weightPercentage: 8.61,
    marketValue: 22057732019.34,
    updated: '2024-03-09',
  },
  {
    asset: 'AAPL',
    name: 'Apple Inc',
    sharesNumber: 112816825,
    weightPercentage: 7.442,
    marketValue: 19066043425,
    updated: '2024-03-09',
  },
  {
    asset: 'NVDA',
    name: 'NVIDIA Corp',
    sharesNumber: 17916948,
    weightPercentage: 6.481,
    marketValue: 16603456542.12,
    updated: '2024-03-09',
  },
  {
    asset: 'AMZN',
    name: 'Amazon.com Inc',
    sharesNumber: 74961200,
    weightPercentage: 5.174,
    marketValue: 13254639384,
    updated: '2024-03-09',
  },
  {
    asset: 'META',
    name: 'Meta Platforms Inc',
    sharesNumber: 25614258,
    weightPercentage: 5.121,
    marketValue: 13119366805.02,
    updated: '2024-03-09',
  },
  {
    asset: 'AVGO',
    name: 'Broadcom Inc',
    sharesNumber: 8751457,
    weightPercentage: 4.807,
    marketValue: 12313387513.57,
    updated: '2024-03-09',
  },
  {
    asset: 'COST',
    name: 'Costco Wholesale Corp',
    sharesNumber: 8295165,
    weightPercentage: 2.544,
    marketValue: 6516598672.35,
    updated: '2024-03-09',
  },
  {
    asset: 'AMD',
    name: 'Advanced Micro Devices Inc',
    sharesNumber: 30267423,
    weightPercentage: 2.497,
    marketValue: 6397927873.74,
    updated: '2024-03-09',
  },
  {
    asset: 'TSLA',
    name: 'Tesla Inc',
    sharesNumber: 34904074,
    weightPercentage: 2.434,
    marketValue: 6235612820.1,
    updated: '2024-03-09',
  },
  {
    asset: 'GOOGL',
    name: 'Alphabet Inc',
    sharesNumber: 42928074,
    weightPercentage: 2.252,
    marketValue: 5768674584.12,
    updated: '2024-03-09',
  },
  {
    asset: 'GOOG',
    name: 'Alphabet Inc',
    sharesNumber: 41528257,
    weightPercentage: 2.192,
    marketValue: 5616281476.68,
    updated: '2024-03-09',
  },
  {
    asset: 'NFLX',
    name: 'Netflix Inc',
    sharesNumber: 8200035,
    weightPercentage: 1.948,
    marketValue: 4989803297.85,
    updated: '2024-03-09',
  },
  {
    asset: 'ADBE',
    name: 'Adobe Inc',
    sharesNumber: 8530221,
    weightPercentage: 1.851,
    marketValue: 4743144084.84,
    updated: '2024-03-09',
  },
  {
    asset: 'PEP',
    name: 'PepsiCo Inc',
    sharesNumber: 25759027,
    weightPercentage: 1.64,
    marketValue: 4201554893.97,
    updated: '2024-03-09',
  },
  {
    asset: 'CSCO',
    name: 'Cisco Systems Inc',
    sharesNumber: 76131933,
    weightPercentage: 1.453,
    marketValue: 3722851523.7,
    updated: '2024-03-09',
  },
  {
    asset: 'QCOM',
    name: 'QUALCOMM Inc',
    sharesNumber: 20852956,
    weightPercentage: 1.43,
    marketValue: 3664281428.32,
    updated: '2024-03-09',
  },
  {
    asset: 'INTC',
    name: 'Intel Corp',
    sharesNumber: 78989650,
    weightPercentage: 1.423,
    marketValue: 3645372347.5,
    updated: '2024-03-09',
  },
  {
    asset: 'TMUS',
    name: 'T-Mobile US Inc',
    sharesNumber: 21667408,
    weightPercentage: 1.39,
    marketValue: 3561905201.12,
    updated: '2024-03-09',
  },
  {
    asset: 'INTU',
    name: 'Intuit Inc',
    sharesNumber: 5244849,
    weightPercentage: 1.329,
    marketValue: 3405690249.66,
    updated: '2024-03-09',
  },
  {
    asset: 'AMAT',
    name: 'Applied Materials Inc',
    sharesNumber: 15673188,
    weightPercentage: 1.301,
    marketValue: 3332276500.68,
    updated: '2024-03-09',
  },
  {
    asset: 'CMCSA',
    name: 'Comcast Corp',
    sharesNumber: 75235618,
    weightPercentage: 1.226,
    marketValue: 3140334695.32,
    updated: '2024-03-09',
  },
  {
    asset: 'TXN',
    name: 'Texas Instruments Inc',
    sharesNumber: 17015751,
    weightPercentage: 1.164,
    marketValue: 2982350677.77,
    updated: '2024-03-09',
  },
  {
    asset: 'AMGN',
    name: 'Amgen Inc',
    sharesNumber: 10026840,
    weightPercentage: 1.068,
    marketValue: 2735923562.4,
    updated: '2024-03-09',
  },
  {
    asset: 'ISRG',
    name: 'Intuitive Surgical Inc',
    sharesNumber: 6596275,
    weightPercentage: 1.011,
    marketValue: 2590225267,
    updated: '2024-03-09',
  },
  {
    asset: 'HON',
    name: 'Honeywell International Inc',
    sharesNumber: 12351484,
    weightPercentage: 0.977,
    marketValue: 2502534173.24,
    updated: '2024-03-09',
  },
  {
    asset: 'LRCX',
    name: 'Lam Research Corp',
    sharesNumber: 2469414,
    weightPercentage: 0.959,
    marketValue: 2456005081.98,
    updated: '2024-03-09',
  },
  {
    asset: 'BKNG',
    name: 'Booking Holdings Inc',
    sharesNumber: 653754,
    weightPercentage: 0.887,
    marketValue: 2273599511.04,
    updated: '2024-03-09',
  },
  {
    asset: 'MU',
    name: 'Micron Technology Inc',
    sharesNumber: 20679242,
    weightPercentage: 0.799,
    marketValue: 2046831373.16,
    updated: '2024-03-09',
  },
  {
    asset: 'VRTX',
    name: 'Vertex Pharmaceuticals Inc',
    sharesNumber: 4827795,
    weightPercentage: 0.774,
    marketValue: 1982002959.3,
    updated: '2024-03-09',
  },
  {
    asset: 'REGN',
    name: 'Regeneron Pharmaceuticals Inc',
    sharesNumber: 2006933,
    weightPercentage: 0.762,
    marketValue: 1953367958.23,
    updated: '2024-03-09',
  },
  {
    asset: 'SBUX',
    name: 'Starbucks Corp',
    sharesNumber: 21296744,
    weightPercentage: 0.756,
    marketValue: 1936938866.8,
    updated: '2024-03-09',
  },
  {
    asset: 'ADP',
    name: 'Automatic Data Processing Inc',
    sharesNumber: 7705970,
    weightPercentage: 0.735,
    marketValue: 1882183172.5,
    updated: '2024-03-09',
  },
  {
    asset: 'ADI',
    name: 'Analog Devices Inc',
    sharesNumber: 9297909,
    weightPercentage: 0.719,
    marketValue: 1843217480.16,
    updated: '2024-03-09',
  },
  {
    asset: 'KLAC',
    name: 'KLA Corp',
    sharesNumber: 2546628,
    weightPercentage: 0.719,
    marketValue: 1841874167.28,
    updated: '2024-03-09',
  },
  {
    asset: 'MDLZ',
    name: 'Mondelez International Inc',
    sharesNumber: 25497166,
    weightPercentage: 0.707,
    marketValue: 1810298786,
    updated: '2024-03-09',
  },
  {
    asset: 'ASML',
    name: 'ASML Holding NV',
    sharesNumber: 1644744,
    weightPercentage: 0.672,
    marketValue: 1722688418.16,
    updated: '2024-03-09',
  },
  {
    asset: 'GILD',
    name: 'Gilead Sciences Inc',
    sharesNumber: 23345386,
    weightPercentage: 0.671,
    marketValue: 1719621132.76,
    updated: '2024-03-09',
  },
  {
    asset: 'PANW',
    name: 'Palo Alto Networks Inc',
    sharesNumber: 5907280,
    weightPercentage: 0.661,
    marketValue: 1692347110.8,
    updated: '2024-03-09',
  },
  {
    asset: 'SNPS',
    name: 'Synopsys Inc',
    sharesNumber: 2849571,
    weightPercentage: 0.649,
    marketValue: 1662297242.85,
    updated: '2024-03-09',
  },
  {
    asset: 'CDNS',
    name: 'Cadence Design Systems Inc',
    sharesNumber: 5097273,
    weightPercentage: 0.633,
    marketValue: 1621136704.92,
    updated: '2024-03-09',
  },
  {
    asset: 'PDD',
    name: 'PDD Holdings Inc ADR',
    sharesNumber: 12514019,
    weightPercentage: 0.572,
    marketValue: 1465892185.66,
    updated: '2024-03-09',
  },
  {
    asset: 'MELI',
    name: 'MercadoLibre Inc',
    sharesNumber: 947208,
    weightPercentage: 0.566,
    marketValue: 1449171407.52,
    updated: '2024-03-09',
  },
  {
    asset: 'CRWD',
    name: 'Crowdstrike Holdings Inc',
    sharesNumber: 4264904,
    weightPercentage: 0.549,
    marketValue: 1406053550.72,
    updated: '2024-03-09',
  },
  {
    asset: 'CSX',
    name: 'CSX Corp',
    sharesNumber: 37024107,
    weightPercentage: 0.548,
    marketValue: 1403954137.44,
    updated: '2024-03-09',
  },
  {
    asset: 'MRVL',
    name: 'Marvell Technology Inc',
    sharesNumber: 16164982,
    weightPercentage: 0.537,
    marketValue: 1375478318.38,
    updated: '2024-03-09',
  },
  {
    asset: 'MAR',
    name: 'Marriott International Inc/MD',
    sharesNumber: 5502467,
    weightPercentage: 0.53,
    marketValue: 1358614126.97,
    updated: '2024-03-09',
  },
  {
    asset: 'ABNB',
    name: 'Airbnb Inc',
    sharesNumber: 8145363,
    weightPercentage: 0.52,
    marketValue: 1332092665.02,
    updated: '2024-03-09',
  },
  {
    asset: 'NXPI',
    name: 'NXP Semiconductors NV',
    sharesNumber: 4829584,
    weightPercentage: 0.488,
    marketValue: 1251055439.36,
    updated: '2024-03-09',
  },
  {
    asset: 'ORLY',
    name: "O'Reilly Automotive Inc",
    sharesNumber: 1108386,
    weightPercentage: 0.471,
    marketValue: 1207508959.98,
    updated: '2024-03-09',
  },
  {
    asset: 'CTAS',
    name: 'Cintas Corp',
    sharesNumber: 1908401,
    weightPercentage: 0.468,
    marketValue: 1199201020.38,
    updated: '2024-03-09',
  },
  {
    asset: 'PYPL',
    name: 'PayPal Holdings Inc',
    sharesNumber: 20199728,
    weightPercentage: 0.461,
    marketValue: 1181684088,
    updated: '2024-03-09',
  },
  {
    asset: 'MNST',
    name: 'Monster Beverage Corp',
    sharesNumber: 19493373,
    weightPercentage: 0.452,
    marketValue: 1157516488.74,
    updated: '2024-03-09',
  },
  {
    asset: 'PCAR',
    name: 'PACCAR Inc',
    sharesNumber: 9800023,
    weightPercentage: 0.437,
    marketValue: 1120436629.59,
    updated: '2024-03-09',
  },
  {
    asset: 'ROP',
    name: 'Roper Technologies Inc',
    sharesNumber: 2001458,
    weightPercentage: 0.427,
    marketValue: 1093436534.56,
    updated: '2024-03-09',
  },
  {
    asset: 'CEG',
    name: 'Constellation Energy Corp',
    sharesNumber: 5983851,
    weightPercentage: 0.419,
    marketValue: 1074579962.58,
    updated: '2024-03-09',
  },
  {
    asset: 'FTNT',
    name: 'Fortinet Inc',
    sharesNumber: 14387360,
    weightPercentage: 0.41,
    marketValue: 1051284395.2,
    updated: '2024-03-09',
  },
  {
    asset: 'LULU',
    name: 'Lululemon Athletica Inc',
    sharesNumber: 2275125,
    weightPercentage: 0.406,
    marketValue: 1040369160,
    updated: '2024-03-09',
  },
  {
    asset: 'WDAY',
    name: 'Workday Inc',
    sharesNumber: 3915646,
    weightPercentage: 0.405,
    marketValue: 1036628122.04,
    updated: '2024-03-09',
  },
  {
    asset: 'ADSK',
    name: 'Autodesk Inc',
    sharesNumber: 4004963,
    weightPercentage: 0.397,
    marketValue: 1016619807.92,
    updated: '2024-03-09',
  },
  {
    asset: 'CPRT',
    name: 'Copart Inc',
    sharesNumber: 17990562,
    weightPercentage: 0.388,
    marketValue: 993258928.02,
    updated: '2024-03-09',
  },
  {
    asset: 'DXCM',
    name: 'Dexcom Inc',
    sharesNumber: 7239010,
    weightPercentage: 0.377,
    marketValue: 964525692.4,
    updated: '2024-03-09',
  },
  {
    asset: 'MCHP',
    name: 'Microchip Technology Inc',
    sharesNumber: 10137005,
    weightPercentage: 0.369,
    marketValue: 946188046.7,
    updated: '2024-03-09',
  },
  {
    asset: 'ROST',
    name: 'Ross Stores Inc',
    sharesNumber: 6344438,
    weightPercentage: 0.362,
    marketValue: 926414836.76,
    updated: '2024-03-09',
  },
  {
    asset: 'DASH',
    name: 'DoorDash Inc',
    sharesNumber: 6945963,
    weightPercentage: 0.361,
    marketValue: 925202271.6,
    updated: '2024-03-09',
  },
  {
    asset: 'ODFL',
    name: 'Old Dominion Freight Line Inc',
    sharesNumber: 2044465,
    weightPercentage: 0.354,
    marketValue: 905841107.55,
    updated: '2024-03-09',
  },
  {
    asset: 'IDXX',
    name: 'IDEXX Laboratories Inc',
    sharesNumber: 1556012,
    weightPercentage: 0.342,
    marketValue: 876377078.64,
    updated: '2024-03-09',
  },
  {
    asset: 'AEP',
    name: 'American Electric Power Co Inc',
    sharesNumber: 9852565,
    weightPercentage: 0.323,
    marketValue: 828600716.5,
    updated: '2024-03-09',
  },
  {
    asset: 'PAYX',
    name: 'Paychex Inc',
    sharesNumber: 6767936,
    weightPercentage: 0.32,
    marketValue: 819800087.68,
    updated: '2024-03-09',
  },
  {
    asset: 'FAST',
    name: 'Fastenal Co',
    sharesNumber: 10705749,
    weightPercentage: 0.317,
    marketValue: 812994579.06,
    updated: '2024-03-09',
  },
  {
    asset: 'GEHC',
    name: 'GE HealthCare Technologies Inc',
    sharesNumber: 8529434,
    weightPercentage: 0.309,
    marketValue: 790337354.44,
    updated: '2024-03-09',
  },
  {
    asset: 'KHC',
    name: 'Kraft Heinz Co/The',
    sharesNumber: 22979838,
    weightPercentage: 0.309,
    marketValue: 792804411,
    updated: '2024-03-09',
  },
  {
    asset: 'CHTR',
    name: 'Charter Communications Inc',
    sharesNumber: 2771504,
    weightPercentage: 0.305,
    marketValue: 781564128,
    updated: '2024-03-09',
  },
  {
    asset: 'KDP',
    name: 'Keurig Dr Pepper Inc',
    sharesNumber: 26198669,
    weightPercentage: 0.296,
    marketValue: 758713454.24,
    updated: '2024-03-09',
  },
  {
    asset: 'CTSH',
    name: 'Cognizant Technology Solutions Corp',
    sharesNumber: 9394198,
    weightPercentage: 0.284,
    marketValue: 727674577.08,
    updated: '2024-03-09',
  },
  {
    asset: 'AZN',
    name: 'AstraZeneca PLC ADR',
    sharesNumber: 10909824,
    weightPercentage: 0.282,
    marketValue: 722121250.56,
    updated: '2024-03-09',
  },
  {
    asset: 'MRNA',
    name: 'Moderna Inc',
    sharesNumber: 7143720,
    weightPercentage: 0.277,
    marketValue: 710657265.6,
    updated: '2024-03-09',
  },
  {
    asset: 'DDOG',
    name: 'Datadog Inc',
    sharesNumber: 5661260,
    weightPercentage: 0.272,
    marketValue: 695712241.4,
    updated: '2024-03-09',
  },
  {
    asset: 'EXC',
    name: 'Exelon Corp',
    sharesNumber: 18649994,
    weightPercentage: 0.268,
    marketValue: 686879279.02,
    updated: '2024-03-09',
  },
  {
    asset: 'EA',
    name: 'Electronic Arts Inc',
    sharesNumber: 5042271,
    weightPercentage: 0.267,
    marketValue: 682723493.4,
    updated: '2024-03-09',
  },
  {
    asset: 'TTD',
    name: 'Trade Desk Inc/The',
    sharesNumber: 8360876,
    weightPercentage: 0.267,
    marketValue: 684170483.08,
    updated: '2024-03-09',
  },
  {
    asset: 'ON',
    name: 'ON Semiconductor Corp',
    sharesNumber: 8069422,
    weightPercentage: 0.261,
    marketValue: 669439249.12,
    updated: '2024-03-09',
  },
  {
    asset: 'CSGP',
    name: 'CoStar Group Inc',
    sharesNumber: 7651061,
    weightPercentage: 0.255,
    marketValue: 653018056.35,
    updated: '2024-03-09',
  },
  {
    asset: 'VRSK',
    name: 'Verisk Analytics Inc',
    sharesNumber: 2716477,
    weightPercentage: 0.25,
    marketValue: 641468878.78,
    updated: '2024-03-09',
  },
  {
    asset: 'CDW',
    name: 'CDW Corp/DE',
    sharesNumber: 2509876,
    weightPercentage: 0.243,
    marketValue: 623302605.84,
    updated: '2024-03-09',
  },
  {
    asset: 'DLTR',
    name: 'Dollar Tree Inc',
    sharesNumber: 4082159,
    weightPercentage: 0.239,
    marketValue: 612405493.18,
    updated: '2024-03-09',
  },
  {
    asset: 'FANG',
    name: 'Diamondback Energy Inc',
    sharesNumber: 3353241,
    weightPercentage: 0.239,
    marketValue: 613005987.21,
    updated: '2024-03-09',
  },
  {
    asset: 'CCEP',
    name: 'Coca-Cola Europacific Partners PLC',
    sharesNumber: 8558318,
    weightPercentage: 0.236,
    marketValue: 603874918.08,
    updated: '2024-03-09',
  },
  {
    asset: 'BIIB',
    name: 'Biogen Inc',
    sharesNumber: 2714858,
    weightPercentage: 0.232,
    marketValue: 595531250.88,
    updated: '2024-03-09',
  },
  {
    asset: 'TEAM',
    name: 'Atlassian Corp',
    sharesNumber: 2904081,
    weightPercentage: 0.23,
    marketValue: 589412279.76,
    updated: '2024-03-09',
  },
  {
    asset: 'BKR',
    name: 'Baker Hughes Co',
    sharesNumber: 18852499,
    weightPercentage: 0.227,
    marketValue: 581222544.17,
    updated: '2024-03-09',
  },
  {
    asset: 'ZS',
    name: 'Zscaler Inc',
    sharesNumber: 2778767,
    weightPercentage: 0.218,
    marketValue: 559282434.09,
    updated: '2024-03-09',
  },
  {
    asset: 'ANSS',
    name: 'ANSYS Inc',
    sharesNumber: 1627407,
    weightPercentage: 0.216,
    marketValue: 552293113.59,
    updated: '2024-03-09',
  },
  {
    asset: 'GFS',
    name: 'GLOBALFOUNDRIES Inc',
    sharesNumber: 10262763,
    weightPercentage: 0.216,
    marketValue: 552136649.4,
    updated: '2024-03-09',
  },
  {
    asset: 'MDB',
    name: 'MongoDB Inc',
    sharesNumber: 1336683,
    weightPercentage: 0.215,
    marketValue: 550726762.83,
    updated: '2024-03-09',
  },
  {
    asset: 'XEL',
    name: 'Xcel Energy Inc',
    sharesNumber: 10338554,
    weightPercentage: 0.202,
    marketValue: 517341242.16,
    updated: '2024-03-09',
  },
  {
    asset: 'SPLK',
    name: 'Splunk Inc',
    sharesNumber: 3156815,
    weightPercentage: 0.193,
    marketValue: 493536457.1,
    updated: '2024-03-09',
  },
  {
    asset: 'TTWO',
    name: 'Take-Two Interactive Software Inc',
    sharesNumber: 3186444,
    weightPercentage: 0.18,
    marketValue: 462066244.44,
    updated: '2024-03-09',
  },
  {
    asset: 'ILMN',
    name: 'Illumina Inc',
    sharesNumber: 2975052,
    weightPercentage: 0.158,
    marketValue: 404607072,
    updated: '2024-03-09',
  },
  {
    asset: 'WBD',
    name: 'Warner Bros Discovery Inc',
    sharesNumber: 45688230,
    weightPercentage: 0.156,
    marketValue: 399315130.2,
    updated: '2024-03-09',
  },
  {
    asset: 'WBA',
    name: 'Walgreens Boots Alliance Inc',
    sharesNumber: 16185933,
    weightPercentage: 0.132,
    marketValue: 338933437.02,
    updated: '2024-03-09',
  },
  {
    asset: 'SIRI',
    name: 'Sirius XM Holdings Inc',
    sharesNumber: 72082011,
    weightPercentage: 0.118,
    marketValue: 301302805.98,
    updated: '2024-03-09',
  },
]

const table = {
  columns: columns,
  data: tableData,
  overflowXScroll: true,
}

export const fakeData = {
  scores,
  treeData,
  scoreColor,
  earningsData,
  earnings: earningsJSX,
  indexData,
  table,
}
