import { useState, useMemo } from 'react'
import moment from 'moment'
import { useQuery, useQueries } from 'react-query'
import NewsViewAPI from '../queries'
import dataUtils from '../utils'
import { useURLSyncedGlobalTicker } from 'pages/views/hooks'

export const TYPE_BEARISH = 'bearish'
export const TYPE_BULLISH = 'bullish'
export const SOURCE_TWITTER = 'twitter'
export const SOURCE_STOCKTWITS = 'stocktwits'
export const TypeSourceMapping = [
  {
    Source: SOURCE_STOCKTWITS,
    Trend: TYPE_BEARISH,
  },
  {
    Source: SOURCE_TWITTER,
    Trend: TYPE_BULLISH,
  },
  {
    Source: SOURCE_STOCKTWITS,
    Trend: TYPE_BULLISH,
  },
  {
    Source: SOURCE_TWITTER,
    Trend: TYPE_BEARISH,
  },
]

export const PostTypes = ['Posts', 'Likes', 'Comments', 'Impressions']

export const areQueriesDone = queries => {
  const done = queries.filter(q => q.isLoading)
  return done.length
}
export const getLimitFromRangeHourlyData = ({ from, to }) => {
  let fromDate = moment(from)
  let toDate = moment(to)

  let difference = toDate.diff(fromDate)

  difference = moment.duration(difference, 'milliseconds')
  var hours = Math.floor(difference.asHours())

  return hours
}

export const useNewsDataSource = () => {
  const { ticker, handleSetTicker } = useURLSyncedGlobalTicker()
  // states
  const [newsRange, setNewRange] = useState({
    from: moment().subtract(40, 'days').format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
  })
  const [socialRange, setSocialRange] = useState({
    from: moment().subtract(3, 'days').format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
  })

  // parrell queries
  const TrendingSocialSentimentPerTypePerSource = useQueries(
    TypeSourceMapping.map(({ Source, Trend }) => {
      return {
        queryKey: ['TrendingSocialSentimentPerTypePerSource', Source, Trend],
        queryFn: async () =>
          NewsViewAPI.fetchTrendingSocialSentimentPerTypePerSource(
            Source,
            Trend
          ),
        retryDelay: attemptIndex => Math.min(1000 * 2 ** attemptIndex, 30000),
      }
    })
  )

  const ChangeSocialSentimentPerTypePerSource = useQueries(
    TypeSourceMapping.map(({ Source, Trend }) => {
      return {
        queryKey: ['ChangeSocialSentimentPerTypePerSource', Source, Trend],
        queryFn: async () =>
          NewsViewAPI.fetchChangeSocialSentimentPerTypePerSource(Source, Trend),
        retryDelay: attemptIndex => Math.min(1000 * 2 ** attemptIndex, 30000),
      }
    })
  )

  const { data: TrendingNews_UI, isLoading: TrendingNewsLoading } = useQuery(
    ['TrendingNews', newsRange.from, newsRange.to],
    async () => {
      const res = NewsViewAPI.fetchTrendingNews(newsRange.from, newsRange.to)
      return res
    }
  )
  // single calls
  const { data: SingleStockNews, isLoading: SingleStockNewsLoading } = useQuery(
    ['SingleStockNewsRange', ticker],
    async () => {
      const res = await NewsViewAPI.fetchSingleStockNewsRange(ticker)
      return res
    }
  )

  const { data: SingleStockSentiment, isLoading: SingleStockSentimentLoading } =
    useQuery(
      [
        'fetchSingleStockSentiment',
        ticker,
        getLimitFromRangeHourlyData(socialRange),
        socialRange?.takeNth,
      ],
      async () => {
        const res = await NewsViewAPI.fetchSingleStockSentiment(
          ticker,
          getLimitFromRangeHourlyData(socialRange),
          socialRange?.takeNth
        )
        return res
      }
    )

  const singleStockNewsData_UI = useMemo(() => {
    if (SingleStockNews) {
      return {
        tableData: SingleStockNews,
        chartData: dataUtils.newsArticleCountGroupedByDay(SingleStockNews),
        averageNewsPosts: dataUtils.averageNewsPosts(SingleStockNews),
        mostFrequentNewsSite: dataUtils.mostFrequentNewsSite(SingleStockNews),
        totalNewsArticles: dataUtils.totalNewsArticles(SingleStockNews),
        lastDay: dataUtils.getLastDay(SingleStockNews),
        percentChangeNewsVolume:
          dataUtils.percentChangeNewsVolume(SingleStockNews),
        newsArticlesPostedToday:
          dataUtils.newsArticlesPostedToday(SingleStockNews),
        mostCommonPostTimeOfDay:
          dataUtils.mostCommonPostTimeOfDay(SingleStockNews),
      }
    }
  }, [SingleStockNews])

  const singleStockSentiment_UI = useMemo(() => {
    if (!SingleStockSentimentLoading) {
      return {
        TotalsTrendData: SingleStockSentiment,
        AveragePosts: dataUtils.averageSocialPosts(SingleStockSentiment),
      }
    }
  }, [SingleStockSentiment, SingleStockSentimentLoading])

  const trendingSocial_UI = useMemo(() => {
    if (
      areQueriesDone(ChangeSocialSentimentPerTypePerSource) === 0 &&
      areQueriesDone(TrendingSocialSentimentPerTypePerSource) === 0
    ) {
      return {
        ...dataUtils.buildTrendingUI(
          TrendingSocialSentimentPerTypePerSource,
          ChangeSocialSentimentPerTypePerSource
        ),
        loading: false,
      }
    } else {
      return {
        loading: true,
      }
    }
  }, [
    TrendingSocialSentimentPerTypePerSource,
    ChangeSocialSentimentPerTypePerSource,
  ])

  return {
    trendingSocial_UI,
    SingleStockNewsLoading,
    singleStockNewsData_UI,
    singleStockSentiment_UI,
    SingleStockSentimentLoading,
    TrendingNews_UI,
    TrendingNewsLoading,
    newsRange,
    setNewRange,
    socialRange,
    setSocialRange,
    ticker,
    setTicker: handleSetTicker,
  }
}
