import {
  BALANCE_SHEET_BREAKDOWN_CHART,
  CURRENT_ASSET_BREAKDOWN_CHART,
  CURRENT_LIABILITIES_BREAKDOWN_CHART,
  NON_CURRENT_ASSET_BREAKDOWN_CHART,
  NON_CURRENT_LIABILITIES_BREAKDOWN_CHART,
  OPERATING_EXPENSE_BREAKDOWN_CHART,
} from '../../constants/chartsConstants'
import { APP_CONFIG } from '../../config'

export const STACKED_AREA_CHART = 'Area Chart'
export const STACKED_BAR_CHART = 'Stacked Bar Chart'
export const LINE_CHART = 'Line Chart'
export const AREA_PERCENT_CHART = 'Area Percent Chart'

export const colorScheme = [
  '#fd7f6f',
  '#7eb0d5',
  '#b2e061',
  '#bd7ebe',
  '#ffb55a',
  '#ffee65',
  '#beb9db',
  '#fdcce5',
  '#8bd3c7',
]

export const colorSchemeFill = [
  'rgba(253, 127, 111, 0.6)',
  'rgba(126, 176, 213, 0.6)',
  'rgba(178, 224, 97, 0.6)',
  'rgba(189, 126, 190, 0.6)',
  'rgba(255, 181, 90, 0.6)',
  'rgba(255, 238, 101, 0.6)',
  'rgba(190, 185, 219, 0.6)',
  'rgba(253, 204, 229, 0.6)',
  'rgba(139, 211, 199, 0.6)',
]

export const options = [
  { value: STACKED_BAR_CHART, label: STACKED_BAR_CHART },
  { value: STACKED_AREA_CHART, label: STACKED_AREA_CHART },
  { value: LINE_CHART, label: LINE_CHART },
  { value: AREA_PERCENT_CHART, label: AREA_PERCENT_CHART },
]

export const breakdownSelectStyles = {
  control: () => ({
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'row',
    height: 30,
    // borderBottom: '1px solid grey',
    width: '100%',
  }),
  singleValue: () => ({
    color: 'var(--off-white)',
    gridArea: '1/1/2/3',
    marginLeft: '1px',
    marginRight: '1px',
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    fontSize: '13.75px',
  }),
  indicatorSeparator: () => ({
    backgroundColor: 'var(--off-white)',
    alignSelf: 'stretch',
    marginBottom: '8px',
    marginTop: '8px',
    width: '1px',
    boxSizing: 'border-box',
  }),
  menu: () => ({
    top: '100%',
    backgroundColor: 'var(--background-primary)',
    borderRadius: '4px',
    border: '2px solid var(--grey-accent)',
    boxShadow:
      '0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 12px hsl(0deg 0% 0% / 10%)',
    marginBottom: '8px',
    marginTop: '8px',
    position: 'absolute',
    width: '100%',
    zIndex: 100,
    boxSizing: 'border-box',
  }),
  menuPortal: provided => ({ ...provided, zIndex: 9999 }),
  MenuList: () => ({
    maxHeight: '300px',
    overflowY: 'auto',
    paddingBottom: '4px',
    paddingTop: '4px',
    zIndex: 100,
    position: 'relative',
    boxSizing: 'border-box',
  }),
  option: () => ({
    backgroundColor: 'transparent',
    color: 'var(--off-white)',
    cursor: 'default',
    display: 'block',
    fontSize: '12px',
    zIndex: 100,
    padding: '8px 12px',
    width: '',
    boxSizing: 'border-box',
    '&:hover': {
      backgroundColor: 'var(--button-hover)',
    },
  }),
  input: (provided, state) => ({
    ...provided,
    color: 'var(--white)',
  }),
}
export const breakdownConfig = {
  [CURRENT_ASSET_BREAKDOWN_CHART]: {
    api_call_annual:
      APP_CONFIG.IN_HOUSE_ORIGIN +
      'papi/data/api/v3/balance-sheet-statement/AAPL?limit=120',
    api_call_quarter:
      APP_CONFIG.IN_HOUSE_ORIGIN +
      'papi/data/api/v3/balance-sheet-statement/AAPL?period=quarter&limit=400',
    metric_keys: [
      'cashAndCashEquivalents',
      'shortTermInvestments',
      'cashAndShortTermInvestments',
      'netReceivables',
      'inventory',
    ],
  },
  [NON_CURRENT_ASSET_BREAKDOWN_CHART]: {
    api_call_annual:
      APP_CONFIG.IN_HOUSE_ORIGIN +
      'papi/data/api/v3/balance-sheet-statement/AAPL?limit=120',
    api_call_quarter:
      APP_CONFIG.IN_HOUSE_ORIGIN +
      'papi/data/api/v3/balance-sheet-statement/AAPL?period=quarter&limit=400',

    metric_keys: [
      'propertyPlantEquipmentNet',
      'goodwill',
      'intangibleAssets',
      'longTermInvestments',
      'taxAssets',
    ],
  },

  [BALANCE_SHEET_BREAKDOWN_CHART]: {
    api_call_annual:
      APP_CONFIG.IN_HOUSE_ORIGIN +
      'papi/data/api/v3/balance-sheet-statement/AAPL?limit=120',
    api_call_quarter:
      APP_CONFIG.IN_HOUSE_ORIGIN +
      'papi/data/api/v3/balance-sheet-statement/AAPL?period=quarter&limit=400',

    metric_keys: ['totalAssets', 'totalLiabilities', 'totalStockholdersEquity'],
  },
  [CURRENT_LIABILITIES_BREAKDOWN_CHART]: {
    api_call_annual:
      APP_CONFIG.IN_HOUSE_ORIGIN +
      'papi/data/api/v3/balance-sheet-statement/AAPL?limit=120',
    api_call_quarter:
      APP_CONFIG.IN_HOUSE_ORIGIN +
      'papi/data/api/v3/balance-sheet-statement/AAPL?period=quarter&limit=400',

    metric_keys: [
      'accountPayables',
      'shortTermDebt',
      'taxPayables',
      'deferredRevenue',
    ],
  },
  [NON_CURRENT_LIABILITIES_BREAKDOWN_CHART]: {
    api_call_annual:
      APP_CONFIG.IN_HOUSE_ORIGIN +
      'papi/data/api/v3/balance-sheet-statement/AAPL?limit=120',
    api_call_quarter:
      APP_CONFIG.IN_HOUSE_ORIGIN +
      'papi/data/api/v3/balance-sheet-statement/AAPL?period=quarter&limit=400',

    metric_keys: [
      'longTermDebt',
      'deferredRevenueNonCurrent',
      'deferredTaxLiabilitiesNonCurrent',
    ],
  },
  IncomeStatement: {
    api_call_annual:
      APP_CONFIG.IN_HOUSE_ORIGIN +
      'papi/data/api/v3/income-statement/AAPL?period=quarter&limit=400',
    api_call_quarter:
      APP_CONFIG.IN_HOUSE_ORIGIN +
      'papi/data/api/v3/income-statement/AAPL?period=quarter&limit=400',

    metric_keys: ['revenue', 'operatingExpenses', 'netIncome'],
  },
  RevenueBreakdown: {
    api_call_annual:
      APP_CONFIG.IN_HOUSE_ORIGIN +
      'papi/data/api/v3/income-statement/AAPL?period=quarter&limit=400',
    api_call_quarter:
      APP_CONFIG.IN_HOUSE_ORIGIN +
      'papi/data/api/v3/income-statement/AAPL?period=quarter&limit=400',

    metric_keys: ['revenue', 'costOfRevenue', 'grossProfit'],
  },
  [OPERATING_EXPENSE_BREAKDOWN_CHART]: {
    api_call_annual:
      APP_CONFIG.IN_HOUSE_ORIGIN +
      'papi/data/api/v3/income-statement/AAPL?period=quarter&limit=400',
    api_call_quarter:
      APP_CONFIG.IN_HOUSE_ORIGIN +
      'papi/data/api/v3/income-statement/AAPL?period=quarter&limit=400',

    metric_keys: [
      'researchAndDevelopmentExpenses',
      'sellingAndMarketingExpenses',
      'sellingGeneralAndAdministrativeExpenses',
      'otherExpenses',
    ],
  },
  IncomeBreakdown: {
    api_call_annual:
      APP_CONFIG.IN_HOUSE_ORIGIN +
      'papi/data/api/v3/income-statement/AAPL?period=quarter&limit=400',
    api_call_quarter:
      APP_CONFIG.IN_HOUSE_ORIGIN +
      'papi/data/api/v3/income-statement/AAPL?period=quarter&limit=400',

    metric_keys: [
      'interestIncome',
      'incomeBeforeTax',
      'ebitda',
      'netIncome',
      'operatingIncome',
    ],
  },
  CashFlowStatement: {
    api_call_annual:
      APP_CONFIG.IN_HOUSE_ORIGIN +
      'papi/data/api/v3/income-statement/AAPL?limit=120',
    api_call_quarter:
      APP_CONFIG.IN_HOUSE_ORIGIN +
      'papi/data/api/v3/income-statement/AAPL?period=quarter&limit=400',

    metric_keys: [
      'netIncome',
      'netCashProvidedByOperatingActivities',
      'netCashUsedForInvestingActivites',
      'netCashUsedProvidedByFinancingActivities',
    ],
  },
}

export const incomeStatementConfig = {}

export const cashflowStatement = {}
