import React, { useRef, useState, useEffect } from 'react'
import styles from './styles.module.scss'
import { UilAngleRightB } from '@iconscout/react-unicons'
import { UilAngleLeftB } from '@iconscout/react-unicons'

const ScrollableContainer = ({ children }) => {
  const containerRef = useRef(null)
  const [scrollPosition, setScrollPosition] = useState({
    atStart: true,
    atEnd: false,
  })

  const updateScrollPosition = () => {
    const container = containerRef.current
    const atStart = container.scrollLeft === 0
    const atEnd =
      container.scrollLeft + container.clientWidth >= container.scrollWidth
    setScrollPosition({ atStart, atEnd })
  }

  useEffect(() => {
    updateScrollPosition()
    const container = containerRef.current
    container.addEventListener('scroll', updateScrollPosition)
    return () => {
      container.removeEventListener('scroll', updateScrollPosition)
    }
  }, [])

  const scrollContent = direction => {
    const container = containerRef.current
    const scrollAmount = 100

    if (direction === 'left') {
      container.scrollLeft -= scrollAmount
    } else {
      container.scrollLeft += scrollAmount
    }
  }

  return (
    <div className={styles.scrollContainerWrapper}>
      {!scrollPosition.atStart && (
        <div
          onClick={() => scrollContent('left')}
          className={styles.arrowButton}
        >
          <UilAngleLeftB size={22} />
        </div>
      )}
      <div ref={containerRef} className={styles.scrollContainer}>
        {children}
      </div>
      {!scrollPosition.atEnd && (
        <div
          onClick={() => scrollContent('right')}
          className={styles.arrowButton}
        >
          <UilAngleRightB size={22} />
        </div>
      )}
    </div>
  )
}

export default ScrollableContainer
