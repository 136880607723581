import React from 'react'
import styles from '../FinancialStatements.module.scss'

export const ChartTypeControl = ({ chartView, showChart, updateState }) => {
  const handleClick = type => {
    if (chartView === type && showChart) {
      updateState({ showChart: false })
    } else {
      updateState({ chartView: type, showChart: true })
    }
  }

  return (
    <div className={styles.changeChartTypeWrapper}>
      {/* <button
        className={styles.chartTypeButton}
        style={{
          color:
            chartView === 'waterfall' && showChart
              ? 'var(--primary-color)'
              : 'var(--white)',
        }}
        onClick={() => handleClick('waterfall')}
      >
        Waterfall Chart
      </button> */}
      <div className={styles.divider} />
      <button
        className={styles.chartTypeButton}
        style={{
          color:
            chartView === 'lots-of-charts' && showChart
              ? 'var(--primary-color)'
              : 'var(--white)',
        }}
        onClick={() => handleClick('lots-of-charts')}
      >
        Lots of Charts
      </button>
      <div className={styles.divider} />
      <button
        className={styles.chartTypeButton}
        style={{
          color:
            chartView === 'sankey' && showChart
              ? 'var(--primary-color)'
              : 'var(--white)',
        }}
        onClick={() => handleClick('sankey')}
      >
        Sankey Chart
      </button>
    </div>
  )
}
