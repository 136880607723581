import React, { useEffect } from 'react'
import styles from './insiderTrading.module.scss'
import { TransactionTable } from './components/TransactionTable/TransactionTable'
import { Roster } from './components/Roster/Roster'
import { RosterStats } from './components/RStats/RosterStats'
import { QuoteBar } from 'core/QuoteBar/QuoteBar'
import { useDataSource } from './data/hooks'
import Spinner from 'core/ScrollableContainer/Spinner/spinner'
import { CardWrapper } from 'core/CardWrapper/CardWrapper'
import { reformatName } from './utils'
import { Helmet } from 'react-helmet-async'
import { NoData } from 'core/NoData/NoData'

export const InsiderTrading = () => {
  const {
    handleSetTicker,
    ticker,
    insiderData,
    selectedOwner,
    setSelectedOwner,
    stockPrice,
  } = useDataSource()

  useEffect(() => {
    setSelectedOwner(null)
  }, [ticker])

  const currentYear = 2024

  return (
    <div className={styles.insiderTradingWrapper}>
      <Helmet>
        <title>{`Outseek | ${ticker} Insider Trading`}</title>
        <meta
          name="description"
          content={
            'This page provides retail financial analysts with data on company insider trades. Allows users to search for insider trades by company name, ticker symbol, or insider name. '
          }
        />
      </Helmet>
      <QuoteBar ticker={ticker} updateTicker={handleSetTicker} />
      <div className={styles.insiderTradingContent}>
        <CardWrapper
          height={230}
          width="100%"
          label={'Roster Stats'}
          content={
            insiderData?.insiderRosterIsLoading ||
            insiderData?.rawInsiderTransactionsIsLoading ? (
              <Spinner />
            ) : insiderData?.insiderTransactions?.length > 0 ? (
              <RosterStats
                data={insiderData?.insiderRosterStats}
                mergedData={insiderData?.mergedData}
                selectedOwner={selectedOwner}
                ticker={ticker}
              />
            ) : (
              <NoData />
            )
          }
        />
        <div className={styles.tableRosterWrapper}>
          <CardWrapper
            height={'calc(100vh - 366px)'}
            width={250}
            minWidth={250}
            label={`${ticker} ${currentYear} Insider Roster`}
            content={
              insiderData?.insiderRosterIsLoading ||
              insiderData?.rawInsiderTransactionsIsLoading ? (
                <Spinner />
              ) : insiderData?.insiderRoster?.length > 0 ? (
                <Roster
                  data={insiderData?.insiderRoster}
                  setSelectedOwner={setSelectedOwner}
                  selectedOwner={selectedOwner}
                  ownerData={insiderData?.filteredData}
                  stockPrice={stockPrice}
                  ticker={ticker}
                />
              ) : (
                <NoData />
              )
            }
          />
          <CardWrapper
            height={'calc(100vh - 366px)'}
            width={'calc(100% - 258px)'}
            label={
              selectedOwner
                ? `${reformatName(selectedOwner)} Insider Trades`
                : 'Recent Insider Trading'
            }
            content={
              insiderData?.rawInsiderTransactionsIsLoading ? (
                <Spinner />
              ) : insiderData?.filteredData?.length > 0 ? (
                <TransactionTable data={insiderData?.filteredData} />
              ) : (
                <NoData />
              )
            }
          />
        </div>
      </div>
    </div>
  )
}
