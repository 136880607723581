import React from 'react'
import styles from './Switch.module.scss'
import { UilArrowLeft } from '@iconscout/react-unicons'

import { useMediaQuery } from 'react-responsive'

export const ToggleSwitch = ({ isYearly, setIsYearly, tableMode = false }) => {
  const isTablet = useMediaQuery({ maxWidth: 1100 })
  return (
    <div
      className={tableMode ? styles.switchWrapperTable : styles.switchWrapper}
    >
      <div
        className={`${styles.toggleButtonGroup} ${
          isYearly ? styles.isYearly : ''
        }`}
        onClick={() => setIsYearly(!isYearly)}
      >
        <div className={styles.togglePill} />
        <div className={styles.toggleButton}>Monthly</div>
        <div className={styles.toggleButton}>Yearly</div>
      </div>
      <div
        className={
          tableMode ? styles.discountMessageTable : styles.discountMessage
        }
      >
        {!tableMode && !isTablet && (
          <UilArrowLeft size={24} color="var(--green)" />
        )}{' '}
        Save 25% with yearly billing
      </div>
    </div>
  )
}
