import { addMonths, addYears } from 'date-fns'
export const DAY_RANGE_1DAY = '1D'
export const DAY_RANGE_3DAYS = '3D'
export const DAY_RANGE_5DAYS = '5D'
export const DAY_RANGE_1MONTH = '1M'
export const DAY_RANGE_3MONTH = '3M'
export const DAY_RANGE_6MONTH = '6M'
export const DAY_RANGE_YTD = 'YTD'
export const DAY_RANGE_1Y = '1Y'
export const DAY_RANGE_5Y = '5Y'
export const DAY_RANGE_MAX = 'MAX'

export const ranges = {
  [DAY_RANGE_1DAY]: {
    rowCount: 13,
  },

  [DAY_RANGE_3DAYS]: {
    rowCount: 39,
  },
  [DAY_RANGE_5DAYS]: {
    rowCount: 65,
  },
  [DAY_RANGE_1MONTH]: {
    rowCount: 390,
  },
  [DAY_RANGE_3MONTH]: {
    start: new Date().toISOString().slice(0, 10),
    end: () => {
      let end = addMonths(new Date(), -3)
      return end.toISOString().slice(0, 10)
    },
  },
  [DAY_RANGE_6MONTH]: {
    start: new Date().toISOString().slice(0, 10),
    end: () => {
      let end = addMonths(new Date(), -6)
      return end.toISOString().slice(0, 10)
    },
  },
  [DAY_RANGE_YTD]: {
    start: new Date().toISOString().slice(0, 10),
    end: () => {
      let end = addMonths(new Date(), -12)
      return end.toISOString().slice(0, 10)
    },
  },
  [DAY_RANGE_1Y]: {
    start: new Date().toISOString().slice(0, 10),
    end: () => {
      let end = addMonths(new Date(), -12)
      return end.toISOString().slice(0, 10)
    },
  },
  [DAY_RANGE_5Y]: {
    start: new Date().toISOString().slice(0, 10),
    end: () => {
      let end = addYears(new Date(), -5)
      return end.toISOString().slice(0, 10)
    },
  },
  [DAY_RANGE_MAX]: {
    start: new Date().toISOString().slice(0, 10),
    end: () => {
      let end = addYears(new Date(), -20)
      return end.toISOString().slice(0, 10)
    },
  },
}

export const plotByDayRanges = [
  DAY_RANGE_3MONTH,
  DAY_RANGE_6MONTH,
  DAY_RANGE_YTD,
  DAY_RANGE_1Y,
  DAY_RANGE_5Y,
  DAY_RANGE_MAX,
]
