import { useFunding } from '../../data/hooks'
import BasicTable from 'core/Tables/BasicTable'
import { CardWrapper } from 'core/CardWrapper/CardWrapper'
import Spinner from 'core/ScrollableContainer/Spinner/spinner'
import { fundingColumns } from './columns'
import { Helmet } from 'react-helmet-async'

export const EquityFunding = () => {
  const { CE_UI } = useFunding()

  return (
    <>
      <Helmet>
        <title>{`Outseek | Equity Funding`}</title>
        <meta
          name="description"
          content={
            'This page provides information about the equity funding offerings.'
          }
        />
      </Helmet>
      <CardWrapper
        label={'Equity Funding'}
        height={'100%'}
        width={'100%'}
        content={
          CE_UI?.EFIsLoading ? (
            <Spinner />
          ) : (
            <BasicTable
              data={CE_UI?.EF}
              columns={fundingColumns}
              overflowXScroll={true}
            />
          )
        }
      />
    </>
  )
}
