import { media } from '../assets/asset'
import { texts } from '../TextVars/HomePageText'
import FinRadarChartD3 from '../../views/CompanyAnalytics/components/Comparison/RadarChart/RadarDThree'
import { TreeMap } from '../../views/ETF/ETFInfo/components/Treemap/Treemap'
import { TooltipContent } from '../../views/CompanyOverview/utils'
import SimpleScatterChart from '../../views/Earnings/components/Earnings/ScatterChart'
import { fakeData } from '../HomePageComponents/FakeData/fakeData'
import { useMediaQuery } from 'react-responsive'
import { CardWrapper } from 'core/CardWrapper/CardWrapper'
import styles from '../../../pages/views/Earnings/EarningsSummary/styles.module.scss'
import { IndexCharts } from '../HomePageComponents/FakeData/fakeIndexChart'
import {
  stockData,
  newsData,
} from '../HomePageComponents/FakeData/fakeStockData'
import { TotalsTrendWidget } from 'pages/views/NewsView/component'
import { startCase } from 'lodash'
import { BasicTable } from 'core/Tables/BasicTable'

const TabletQuery = () => {
  const isTablet = useMediaQuery({ maxWidth: 1079 })
  return { isTablet }
}

export const featureSectionConstants = [
  {
    title: texts.GoodData_Title,
    description: texts.GoodData_Subtitle,
    stretch: true,
    renderComponent: () => {
      const { isTablet } = TabletQuery()

      const PostTypes = isTablet
        ? ['Posts', 'Likes']
        : ['Posts', 'Likes', 'Comments', 'Impressions']

      const styles = {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        gap: '16px',
      }

      return (
        <div
          style={
            isTablet
              ? styles
              : {
                  display: 'grid',
                  gridTemplateColumns: 'repeat(2, 1fr)',
                  height: '100%',
                  width: '100%',
                  gap: '16px',
                }
          }
        >
          {PostTypes.map(entry => {
            return (
              <TotalsTrendWidget
                Label={startCase('stocktwits' + entry)}
                data={newsData}
                metrics={[
                  {
                    key: 'stocktwits' + entry,
                    color: 'var(--primary-color)',
                    type: 'stepAfter',
                  },
                ]}
              />
            )
          })}
        </div>
      )
    },
  },
  {
    title: texts.conclusiveInformation_Title,
    description: texts.conclusiveInformation_Subtitle,
    renderComponent: () => (
      <FinRadarChartD3
        data={fakeData.scores}
        dataKey={'score'}
        category={'name'}
        // color={fakeData.scoreColor}
        color={'var(--primary-color)'}
        domain={[0, 5]}
        noDots={true}
        renderTooltip={data => <TooltipContent data={data} />}
      />
    ),
  },
  {
    title: texts.InsightfulVisualizations_Title,
    description: texts.InsightfulVisualizations_Subtitle,
    renderComponent: () => (
      <TreeMap
        data={fakeData.treeData}
        startColor={'#8884d8'}
        endColor={'#ac6bcb'}
      />
    ),
  },
  {
    title: texts.InstitutionalGradeData_Title,
    description: texts.WealthOfFinancialData_Subtitle,
    stretch: true,
    renderComponent: () => (
      <div
        style={{
          height: '100%',
          width: '100%',
          textAlign: 'left',
        }}
      >
        <BasicTable {...fakeData.table} />
      </div>
    ),
  },
  {
    title: texts.InteractiveCharts_Title,
    description: texts.InteractiveCharts_Subtitle,
    webP: media.dashboardWebP,
    renderComponent: () => {
      const { isTablet } = TabletQuery()
      const data = fakeData.earningsData
      const dat1a = fakeData.earningsData.slice(0, 10)

      return (
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
          }}
        >
          <div style={{ height: '100%', width: '100%' }}>
            <SimpleScatterChart
              data={isTablet ? dat1a : data}
              xAxisKey={'date'}
              metric1={'eps'}
              metric2={'epsEstimated'}
            />
          </div>
          {/* <div style={{ height: 'calc(100% - 4px)', width: '100%' }}>
            <IndexCharts data={stockData} indexData={fakeData.indexData} />
          </div> */}
        </div>
      )
    },
    stretch: 'full',
  },
  // {
  //   title: texts.Powerful_Title,
  //   description: texts.AccessToPremiumData_Subtitle,
  //   webP: media.screenerWebP,
  //   stretch: true,
  // },
  // {
  //   renderComponent: () => (
  //     <CardWrapper
  //       height={'100%'}
  //       width={'100%'}
  //       label={'2024 Q3 Transcript Summary'}
  //       content={
  //         <div style={{ color: 'white' }} className={styles.summaryWrapper}>
  //           <button
  //             className={styles.summarizeButton}
  //             style={{
  //               cursor: 'not-allowed',
  //             }}
  //           >
  //             Summarize {'2024 Q3 Transcript Summary'}
  //           </button>
  //           <div className={styles.bulletPoints}>{fakeData.earnings}</div>
  //         </div>
  //       }
  //     />
  //   ),
  // },
]

export const tabletViewConstants = [
  {
    title: texts.InstitutionalGradeData_Title,
    description: texts.WealthOfFinancialData_Subtitle,
    webP: media.instCompanyWebP,
  },
  {
    title: texts.FromMacro_Title,
    description: texts.ExtensiveMarketCoverage_Subtitle,
    webP: media.insiderTradingWebP,
  },
  {
    title: texts.ToMicro_Title,
    description: texts.DetailedStockView_Subtitle,
    webP: media.companyProfileWebP,
  },
]
