import styles from './TransactionTable.module.scss'
import { columns } from './columns'
import BasicTable from 'core/Tables/BasicTable'

export const TransactionTable = ({ selectedOwner, data }) => {
  return (
    <div className={styles.transactionTable}>
      <BasicTable columns={columns} data={data} overflowXScroll />
    </div>
  )
}
