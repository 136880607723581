import React from 'react'
import styles from './styles.module.scss'

const InfiniteScrollCarousel = ({ items, isHorizontal }) => {
  const duplicatedItems = [...items, ...items, ...items, ...items]

  return (
    <div
      className={
        isHorizontal
          ? styles.carouselContainerHorizontal
          : styles.carouselContainer
      }
    >
      <ul className={isHorizontal ? styles.listHorizontal : styles.list}>
        {duplicatedItems.map((item, index) => (
          <li
            className={
              isHorizontal ? styles.listItemHorizontal : styles.listItem
            }
            key={index}
          >
            {item}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default InfiniteScrollCarousel
