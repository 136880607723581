import { defaultTheme } from 'react-select'

export const globalViewTickerStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? '#1976d2'
        : isFocused
        ? '#e3f2fd'
        : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? defaultTheme.colors.textLight
        : data.color,
      cursor: 'default',
      display: 'block',
      fontSize: '13px',
      zIndex: 100,
      padding: '8px 12px',
      width: '',
      boxSizing: 'border-box',

      '&:hover': {
        backgroundColor: 'var(--button-hover)',
      },
    }
  },
  option: () => ({
    backgroundColor: 'transparent',
    color: 'var(--off-white)',
    display: 'block',
    cursor: 'pointer',
    fontSize: '13px',
    zIndex: 100,
    padding: '8px 12px',
    width: '',
    boxSizing: 'border-box',
    '&:hover': {
      backgroundColor: 'var(--button-hover)',
    },
  }),
  placeholder: base => ({
    ...base,
    color: '#a9a9a9',
    fontSize: 'var(--large-text)',
    whiteSpace: 'nowrap',
  }),
  singleValue: provided => ({
    color: 'var(--white)',
    gridArea: '1/1/2/3',
    marginLeft: '1px',
    marginRight: '1px',
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    fontSize: '13px',
    cursor: 'text',
    margin: 0,
    padding: 0,
  }),
  menu: () => ({
    position: 'absolute',
    backgroundColor: 'var(--background-primary)',
    borderRadius: '4px',
    border: '2px solid var(--grey-accent)',
    boxShadow:
      '0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 12px hsl(0deg 0% 0% / 10%)',
    marginBottom: '8px',
    marginTop: '8px',
    zIndex: 100,
    boxSizing: 'border-box',
    width: '600px',
  }),
  menuPortal: provided => ({ ...provided, zIndex: 9999 }),
  MenuList: () => ({
    paddingBottom: '4px',
    maxHeight: '100px',
    overflowY: 'auto',
    paddingTop: '4px',
    zIndex: 100,
    position: 'relative',
    boxSizing: 'border-box',
  }),
  control: () => ({
    backgroundColor: 'var(--background-secondary)',
    display: 'flex',
    color: 'var(--white)',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    fontSize: '14px',
    margin: '0px',
    borderRadius: '4px',
    cursor: 'pointer',
    border: '1.5px solid var(--grey-accent)',
    height: 50,
  }),
}
