import styles from './swtich.module.scss'

export const Switch = ({
  options,
  selectedOption,
  onOptionChange,
  forCardWrapper = false,
  center = false,
}) => {
  return (
    <div
      className={
        forCardWrapper
          ? styles.changeDataFrequencyWrapperCARD
          : styles.changeDataFrequencyWrapper
      }
      style={{
        justifyContent: center ? 'center' : 'flex-start',
      }}
    >
      {options?.map((option, i) => (
        <>
          <div
            key={option.value}
            className={
              forCardWrapper
                ? styles.dataFrequencyButtonCARD
                : styles.dataFrequencyButton
            }
            style={{
              color:
                selectedOption === option.value ? 'var(--primary-color)' : '',
            }}
            onClick={() => onOptionChange(option.value)}
          >
            {option.label}
          </div>
          {i !== options.length - 1 && (
            <div
              className={forCardWrapper ? styles.dividerCARD : styles.divider}
            />
          )}
        </>
      ))}
    </div>
  )
}
