import { AssetExport } from './assets/AssetsExport'

import {
  COMPANY_PROFILE,
  COMPANY_QUOTE,
  COMPARISON_CHARTS,
  EARNINGS_TRANSCRIPT,
  ESG_SCORE_BREAKDOWN,
  GEO_REVENUE_SEGMENTS,
  INSTITUTIONAL_STOCK_OWNERSHIP,
  NOTES,
  PRICE_CHARTS,
  PRODUCT_REVENUE_SEGMENTS,
  SCREENER,
} from 'constants/uniqueConstants'

import {
  BALANCE_SHEET_BREAKDOWN_CHART,
  CURRENT_ASSET_BREAKDOWN_CHART,
  CURRENT_LIABILITIES_BREAKDOWN_CHART,
  NON_CURRENT_ASSET_BREAKDOWN_CHART,
  NON_CURRENT_LIABILITIES_BREAKDOWN_CHART,
  OPERATING_EXPENSE_BREAKDOWN_CHART,
} from '../../../constants/chartsConstants'

import {
  BALANCE_SHEET,
  CASH_FLOW_STATEMENT,
  COMPANY_GROWTH_TABLE,
  COMPANY_RATIOS_TABLE,
  EIGHT_K_RSS_FEED,
  INCOME_STATEMENT,
  INSIDER_TRADING_RSS_SEC_FEED,
  RSS_SEC_FEED,
} from '../../../constants/tableConstants'

import {
  BALANCE_SHEET_BREAKDOWN,
  BALANCE_SHEET_GROWTH,
  CASHFLOW_BREAKDOWN,
  ENTERPRISE_VALUE,
  ESG_SCORE,
  EXPENSE_BREAKDOWN,
  INCOME_BREAKDOWN,
  INCOME_STATEMENT_BREAKDOWN,
  INCOME_STATEMENT_GROWTH,
  LIABILITIES_BREAKDOWN,
  LIQUIDITY_RATIOS,
  OPERATING_PERFORMANCE_RATIOS,
  PRICE_RATIOS,
  REVENUE_BREAKDOWN,
  RISK_ANALYSIS_RATIO,
  VALUE_RATIO,
  ASSET_BREAKDOWN,
  EQUITY_BREAKDOWN,
  CURRENT_ASSET_BREAKDOWN,
  NON_CURRENT_ASSET_BREAKDOWN,
  CURRENT_LIABILITIES_BREAKDOWN,
  NON_CURRENT_LIABILITIES_BREAKDOWN,
  CASHFLOW_STATEMENT_BREAKDOWN,
  FINANCING_ACTIVITIES_BREAKDOWN,
  INVESTING_ACTIVITIES_BREAKDOWN,
  OPERATING_ACTIVITIES_BREAKDOWN,
  INSTITUTIONAL_POSITION_SUMMARY,
  DEBT_BREAKDOWN,
  REVENUE_GROWTH_BREAKDOWN,
  EXPENSE_GROWTH_BREAKDOWN,
  INCOME_GROWTH_BREAKDOWN,
  BALANCE_SHEET_GROWTH_BREAKDOWN,
  CURRENT_ASSET_GROWTH_BREAKDOWN,
  NON_CURRENT_ASSET_GROWTH_BREAKDOWN,
  ASSET_GROWTH_BREAKDOWN,
  LIABILITIES_GROWTH_BREAKDOWN,
  NON_CURRENT_LIABILITIES_GROWTH_BREAKDOWN,
  DEBT_GROWTH_BREAKDOWN,
  CURRENT_LIABILITIES_GROWTH_BREAKDOWN,
  CASHFLOW_STATEMENT_GROWTH_BREAKDOWN,
  EQUITY_GROWTH_BREAKDOWN,
  OPERATING_ACTIVITIES_GROWTH_BREAKDOWN,
  INVESTING_ACTIVITIES_GROWTH_BREAKDOWN,
  FINANCING_ACTIVITIES_GROWTH_BREAKDOWN,
  CASHFLOW_GROWTH_BREAKDOWN,
  TWITTER_SENTIMENT,
  STOCKTWITS_SENTIMENT,
  INSTITUTIONAL_OWNERSHIP,
} from 'constants/widgetConstants'

export const Favourites = ['Operating Performance Ratios']
export const InitialConfig = [
  /* TEMPLATE FOR ADDING COMPONENETS
  {
    title: "",
    type: "",
    category: "",
    description:
      "",
    icon: AssetExport.,
    layputConfig: {
      x: ,
      y: ,
      w: ,
      h: ,
      minW: ,
      maxW: ,
      minH: ,
      maxH: ,
      isResizable: ,
    }
  },  
  */

  {
    title: INCOME_STATEMENT,
    type: 'Component',
    category: 'Financial Statements',
    description:
      'A table that displays the income statement for a specific stock that can be switched between annual and quarterly.',
    icon: AssetExport.FinancialStatementsIcon,
    typeIcon: AssetExport.ComponentIcon,
  },
  {
    title: BALANCE_SHEET,
    type: 'Component',
    category: 'Financial Statements',
    description:
      'A table that displays the balance sheet for a specific stock that can be switched between annual and quarterly.',
    icon: AssetExport.FinancialStatementsIcon,
    typeIcon: AssetExport.ComponentIcon,
  },
  {
    title: CASH_FLOW_STATEMENT,
    type: 'Component',
    category: 'Financial Statements',
    description:
      'A table that displays the cash flow statement for a specific stock that can be switched between annual and quarterly.',
    icon: AssetExport.FinancialStatementsIcon,
    typeIcon: AssetExport.ComponentIcon,
  },
  {
    title: NOTES,
    type: 'Tool',
    category: '',
    description: 'Take notes within the dashboard',
    icon: AssetExport.ToolIcon,
    typeIcon: AssetExport.ToolIcon,
  },

  {
    title: COMPARISON_CHARTS,
    type: 'Tool',
    category: '',
    description:
      'Compare metrics from all across OutSeek in the form of a chart',
    icon: AssetExport.ComparisonChartIcon,
    typeIcon: AssetExport.ToolIcon,
  },
  {
    title: BALANCE_SHEET_BREAKDOWN_CHART,
    type: 'Component',
    category: 'Fundamental Analysis',
    description:
      'A breakdown chart that compares total assets, total liabilities, and total stockholders equity in a line chart, stacked bar chart, area stacked chart, and percentage area bar chart.',
    icon: AssetExport.FundementalAnalysisIcon,
    typeIcon: AssetExport.ComponentIcon,
  },
  {
    title: CURRENT_ASSET_BREAKDOWN_CHART,
    type: 'Component',
    category: 'Fundamental Analysis',
    description:
      'A breakdown chart that compares cash and cash equivalents, short term investments, cash and short term investments, net receivables, and inventory, in a line chart, stacked bar chart, area stacked chart, and percentage area bar chart.',
    icon: AssetExport.FundementalAnalysisIcon,
    typeIcon: AssetExport.ComponentIcon,
  },
  {
    title: NON_CURRENT_ASSET_BREAKDOWN_CHART,
    type: 'Component',
    category: 'Fundamental Analysis',
    description:
      'A breakdown chart that compares property plant equipmentnet, goodwill, intangible assets, long term investments, and tax assets in a line chart, stacked bar chart, area stacked chart, and percentage area bar chart.',
    icon: AssetExport.FundementalAnalysisIcon,
    typeIcon: AssetExport.ComponentIcon,
  },
  {
    title: CURRENT_LIABILITIES_BREAKDOWN_CHART,
    type: 'Component',
    category: 'Fundamental Analysis',
    description:
      'A breakdown chart that compares account payables, short term debt, tax payables, and deferred revenue in a line chart, stacked bar chart, area stacked chart, and percentage area bar chart.',
    icon: AssetExport.FundementalAnalysisIcon,
    typeIcon: AssetExport.ComponentIcon,
  },
  {
    title: NON_CURRENT_LIABILITIES_BREAKDOWN_CHART,
    type: 'Component',
    category: 'Fundamental Analysis',
    description:
      'A breakdown chart that compares long term debt, non current deferred revenue, and non current deferred tax liabilities in a line chart, stacked bar chart, area stacked chart, and percentage area bar chart.',
    icon: AssetExport.FundementalAnalysisIcon,
    typeIcon: AssetExport.ComponentIcon,
  },
  {
    title: OPERATING_EXPENSE_BREAKDOWN_CHART,
    type: 'Component',
    category: 'Fundamental Analysis',
    description:
      'A breakdown chart that compares research and development expenses, selling and marketing expenses, selling general and administrative expenses, and other expenses in a line chart, stacked bar chart, area stacked chart, and percentage area bar chart.',
    icon: AssetExport.FundementalAnalysisIcon,
    typeIcon: AssetExport.ComponentIcon,
  },
  // {
  //   title: MARKET_RISK_PREMIUM,
  //   type: 'Component',
  //   category: 'Market',
  //   description:
  //     'Country Risk Premiums and their total equity risk premium for 152 countries are displayed in a table and interactive world map.',
  //   icon: AssetExport.MarketIcon,
  //   typeIcon: AssetExport.ComponentIcon,
  // },
  {
    title: GEO_REVENUE_SEGMENTS,
    type: 'Component',
    category: 'Fundamental Analysis',
    description:
      'A table, stacked bar chart, and a radar chart displays revenue broken down by geographical segments for the past 5 years.',
    icon: AssetExport.FundementalAnalysisIcon,
    typeIcon: AssetExport.ComponentIcon,
  },
  {
    title: PRODUCT_REVENUE_SEGMENTS,
    type: 'Component',
    category: 'Fundamental Analysis',
    description:
      'A table, stacked bar chart, and a radar chart displays sales revenue broken down by indiviudal segments for the past 5 years.',
    icon: AssetExport.FundementalAnalysisIcon,
    typeIcon: AssetExport.ComponentIcon,
  },
  {
    title: COMPANY_GROWTH_TABLE,
    type: 'Component',
    category: 'Fundamental Analysis',
    description:
      'A table that displays Financial growth metrics for a specific stock.',
    icon: AssetExport.FundementalAnalysisIcon,
    typeIcon: AssetExport.ComponentIcon,
  },
  {
    title: COMPANY_RATIOS_TABLE,
    type: 'Component',
    category: 'Fundamental Analysis',
    description: 'A table that displays financial ratios for a specific stock.',
    icon: AssetExport.FundementalAnalysisIcon,
    typeIcon: AssetExport.ComponentIcon,
  },

  {
    title: RSS_SEC_FEED,
    type: 'Component',
    category: 'SEC',
    description:
      'An RSS feed that displays all recent financial statements posted by the SEC for all stocks and includes the following information: filling type, filling title, filling link, and filling time. A search stock feature is available.',
    icon: AssetExport.SECIcon,
    typeIcon: AssetExport.ComponentIcon,
  },
  {
    title: INSIDER_TRADING_RSS_SEC_FEED,
    type: 'Component',
    category: 'SEC',
    description:
      'An RSS feed that displays all recent Insider trading activity for all stocks and includes the following information: form type, filling title, tilling link, and filling time. A search stock feature is available.',
    icon: AssetExport.SECIcon,
    typeIcon: AssetExport.ComponentIcon,
  },

  {
    title: EIGHT_K_RSS_FEED,
    type: 'Component',
    category: 'SEC',
    description:
      'An RSS feed that displays recent current reports from all stocks and includes the following information: filling type, filling title, filling link, and filling time.',
    icon: AssetExport.SECIcon,
    typeIcon: AssetExport.ComponentIcon,
  },
  {
    title: INSTITUTIONAL_STOCK_OWNERSHIP,
    type: 'Component',
    category: 'Institutional',
    description:
      'Multiple charts that display historical institutional ownership data for a specific stock that includes the following information: investors holding, ownership percent, total invested, number of 13F shares, new positions, increased positions, closed positions, reduced positions, total calls, total puts, and put-call ratio.',
    icon: AssetExport.InstitutionalIcon,
    typeIcon: AssetExport.ComponentIcon,
  },
  {
    title: COMPANY_PROFILE,
    type: 'Component',
    category: 'Company Information',
    description:
      'Company information for a specific stock that includes the following information: company name, company website, sector, industry, CEO, number of employees, address, company logo, and company description.',
    icon: AssetExport.CompanyInformationIcon,
    typeIcon: AssetExport.ComponentIcon,
  },
  {
    title: COMPANY_QUOTE,
    type: 'Component',
    category: 'Price',
    description: 'A quote for a specific stock.',
    icon: AssetExport.PriceIcon,
    typeIcon: AssetExport.ComponentIcon,
  },
  // {
  //   title: SOCIAL_SENTIMENT_FEED,
  //   type: 'Component',
  //   category: 'External Statistics',
  //   description:
  //     'Displays the number of posts, likes, comments, and impressions for StockTwits and Twitter for a specific stock and can be filtered in time increments.',
  //   icon: AssetExport.ExternalStatisticsIcon,
  //   typeIcon: AssetExport.ComponentIcon,
  // },

  {
    title: EARNINGS_TRANSCRIPT,
    type: 'Component',
    category: 'Company Information',
    description:
      'Earnings call transcripts that can be filtered by year and quarter.',
    icon: AssetExport.CompanyInformationIcon,
    typeIcon: AssetExport.ComponentIcon,
  },
  {
    title: ESG_SCORE_BREAKDOWN,
    type: 'Component',
    category: 'Company Information',
    description: 'Information Included: ESG Score',
    icon: AssetExport.CompanyInformationIcon,
    typeIcon: AssetExport.ComponentIcon,
  },
  {
    title: ESG_SCORE,
    type: 'Widget',
    category: 'Company Information',
    description:
      'Information Included: ESG Score, Environmental Score, Social Score, and Governance Score',
    icon: AssetExport.CompanyInformationIcon,
    typeIcon: AssetExport.WidgetIcon,
  },
  {
    title: CURRENT_ASSET_BREAKDOWN,
    type: 'Widget',
    category: 'Financial Statements',
    description:
      'Information Included: Cash and Short Term Investments, Net Receivables, Inventory, Other Current Assets, and Total Current Assets',
    icon: AssetExport.FinancialStatementsIcon,
    typeIcon: AssetExport.WidgetIcon,
  },
  {
    title: CURRENT_ASSET_GROWTH_BREAKDOWN,
    type: 'Widget',
    category: 'Financial Statements',
    description:
      'Information Included: Cash and Short Term Investments Growth, Net Receivables Growth, Inventory Growth, Other Current Assets Growth, and Total Current Assets Growth',
    icon: AssetExport.FinancialStatementsIcon,
    typeIcon: AssetExport.WidgetIcon,
  },
  {
    title: NON_CURRENT_ASSET_BREAKDOWN,
    type: 'Widget',
    category: 'Financial Statements',
    description:
      'Information Included: Net Property Plant Equipment, Goodwill and Intangibles, Long Term Investments, Other Non Current Assets, and Total Non Current Assets',
    icon: AssetExport.FinancialStatementsIcon,
    typeIcon: AssetExport.WidgetIcon,
  },
  {
    title: NON_CURRENT_ASSET_GROWTH_BREAKDOWN,
    type: 'Widget',
    category: 'Financial Statements',
    description:
      'Information Included: Net Property Plant Equipment Growth, Goodwill and Intangibles Growth, Long Term Investments Growth, Other Non Current Assets Growth, and Total Non Current Assets Growth',
    icon: AssetExport.FinancialStatementsIcon,
    typeIcon: AssetExport.WidgetIcon,
  },
  {
    title: CURRENT_LIABILITIES_BREAKDOWN,
    type: 'Widget',
    category: 'Financial Statements',
    description:
      'Information Included: Account Payables, Tax Payables, Short Term Debt, Other Current Liabilities, and Total Current Liabilities',
    icon: AssetExport.FinancialStatementsIcon,
    typeIcon: AssetExport.WidgetIcon,
  },
  {
    title: CURRENT_LIABILITIES_GROWTH_BREAKDOWN,
    type: 'Widget',
    category: 'Financial Statements',
    description:
      'Information Included: Account Payables Growth, Tax Payables Growth, Short Term Debt Growth, Other Current Liabilities Growth, and Total Current Liabilities Growth',
    icon: AssetExport.FinancialStatementsIcon,
    typeIcon: AssetExport.WidgetIcon,
  },
  {
    title: NON_CURRENT_LIABILITIES_BREAKDOWN,
    type: 'Widget',
    category: 'Financial Statements',
    description:
      'Information Included: Long Term Debt, Deferred Revenue, Deferred Tax Liabilities, Other Non Current Liabilities, and Total Non Current Liabilities',
    icon: AssetExport.FinancialStatementsIcon,
    typeIcon: AssetExport.WidgetIcon,
  },
  {
    title: NON_CURRENT_LIABILITIES_GROWTH_BREAKDOWN,
    type: 'Widget',
    category: 'Financial Statements',
    description:
      'Information Included: Long Term Debt Growth, Deferred Revenue Growth, Deferred Tax Liabilities Growth, Other Non Current Liabilities Growth, and Total Non Current Liabilities Growth',
    icon: AssetExport.FinancialStatementsIcon,
    typeIcon: AssetExport.WidgetIcon,
  },
  {
    title: CASHFLOW_STATEMENT_BREAKDOWN,
    type: 'Widget',
    category: 'Financial Statements',
    description:
      'Information Included: Cash From Operating Activities, Cash Used For Investing Activities, Cash Used Provided By Financing Activities, Capital Expenditure, and Free Cash Flow',
    icon: AssetExport.FinancialStatementsIcon,
    typeIcon: AssetExport.WidgetIcon,
  },
  {
    title: CASHFLOW_STATEMENT_GROWTH_BREAKDOWN,
    type: 'Widget',
    category: 'Financial Statements',
    description:
      'Information Included: Cash From Operating Activities Growth, Cash Used For Investing Activities Growth, Cash Used Provided By Financing Activities Growth, Capital Expenditure Growth, and Free Cash Flow Growth',
    icon: AssetExport.FinancialStatementsIcon,
    typeIcon: AssetExport.WidgetIcon,
  },
  {
    title: FINANCING_ACTIVITIES_BREAKDOWN,
    type: 'Widget',
    category: 'Financial Statements',
    description:
      'Information Included: Change In Debt Repayment, Change In Common Stock Issued, Change In Common Stock Repurchased, Change In Dividends Paid, and Cash Used Provided By Financing Activities',
    icon: AssetExport.FinancialStatementsIcon,
    typeIcon: AssetExport.WidgetIcon,
  },
  {
    title: FINANCING_ACTIVITIES_GROWTH_BREAKDOWN,
    type: 'Widget',
    category: 'Financial Statements',
    description:
      'Information Included: Change In Debt Repayment Growth, Change In Common Stock Issued Growth, Change In Common Stock Repurchased Growth, Change In Dividends Paid Growth, and Cash Used Provided By Financing Activities Growth',
    icon: AssetExport.FinancialStatementsIcon,
    typeIcon: AssetExport.WidgetIcon,
  },
  {
    title: INVESTING_ACTIVITIES_BREAKDOWN,
    type: 'Widget',
    category: 'Financial Statements',
    description:
      'Information Included: Change In Investments In Property Plant And Equipment, Change In Acquisitions Net, Change In Purchases Of Investments, Change In Sales Maturities Of Investments, and Cash Used For Investing Activities',
    icon: AssetExport.FinancialStatementsIcon,
    typeIcon: AssetExport.WidgetIcon,
  },
  {
    title: INVESTING_ACTIVITIES_GROWTH_BREAKDOWN,
    type: 'Widget',
    category: 'Financial Statements',
    description:
      'Information Included: Change In Investments In Property Plant And Equipment Growth, Change In Acquisitions Net Growth, Change In Purchases Of Investments Growth, Change In Sales Maturities Of Investments Growth, and Cash Used For Investing Activities Growth',
    icon: AssetExport.FinancialStatementsIcon,
    typeIcon: AssetExport.WidgetIcon,
  },
  {
    title: OPERATING_ACTIVITIES_BREAKDOWN,
    type: 'Widget',
    category: 'Financial Statements',
    description:
      'Information Included: Change In Depreciation And Amortization, Change In Stock Based Compensation, Change In Accounts Receivables, Change in Inventory, and Cash From Operating Activities',
    icon: AssetExport.FinancialStatementsIcon,
    typeIcon: AssetExport.WidgetIcon,
  },
  {
    title: OPERATING_ACTIVITIES_GROWTH_BREAKDOWN,
    type: 'Widget',
    category: 'Financial Statements',
    description:
      'Information Included: Change In Depreciation And Amortization Growth, Change In Stock Based Compensation Growth, Change In Accounts Receivables Growth, Change in Inventory Growth, and Cash From Operating Activities Growth',
    icon: AssetExport.FinancialStatementsIcon,
    typeIcon: AssetExport.WidgetIcon,
  },
  {
    title: INSTITUTIONAL_POSITION_SUMMARY,
    type: 'Widget',
    category: 'Financial Statements',
    description:
      'Information Included: New Positions, Closed Positions, Increased Positions, Reduced Positions, and Put To Call Ratio.',
    icon: AssetExport.FinancialStatementsIcon,
    typeIcon: AssetExport.WidgetIcon,
  },
  {
    title: INSTITUTIONAL_OWNERSHIP,
    type: 'Widget',
    category: 'Financial Statements',
    description:
      'Information Included: Investors Holding, Ownership Percent, Total Invested, Number Of 13F shares, and Put To Call Ratio.',
    icon: AssetExport.FinancialStatementsIcon,
    typeIcon: AssetExport.WidgetIcon,
  },
  {
    title: DEBT_BREAKDOWN,
    type: 'Widget',
    category: 'Financial Statements',
    description:
      'Information Included: Short Term Debt, Long Term Debt, Total Debt, Net Debt, and Capital Lease Obligations.',
    icon: AssetExport.FinancialStatementsIcon,
    typeIcon: AssetExport.WidgetIcon,
  },
  {
    title: DEBT_GROWTH_BREAKDOWN,
    type: 'Widget',
    category: 'Financial Statements',
    description:
      'Information Included: Short Term Debt Growth, Long Term Debt Growth, Total Debt Growth, and Net Debt Growth.',
    icon: AssetExport.FinancialStatementsIcon,
    typeIcon: AssetExport.WidgetIcon,
  },
  {
    title: EXPENSE_GROWTH_BREAKDOWN,
    type: 'Widget',
    category: 'Financial Statements',
    description:
      'Information Included: Operating Expenses, SG&A Expense, R&D Expense.',
    icon: AssetExport.FinancialStatementsIcon,
    typeIcon: AssetExport.WidgetIcon,
  },
  {
    title: REVENUE_GROWTH_BREAKDOWN,
    type: 'Widget',
    category: 'Financial Statements',
    description:
      'Information Included: Revenue Growth, Cost Of Revenue Growth, Gross Profit Growth, Gross Profit Ratio Growth, and Net Income Growth.',
    icon: AssetExport.FinancialStatementsIcon,
    typeIcon: AssetExport.WidgetIcon,
  },
  {
    title: BALANCE_SHEET_BREAKDOWN,
    type: 'Widget',
    category: 'Financial Statements',
    description:
      'Information Included: Total Assets, Total Liabilities, Total Shareholders Equity, Retained Earnings, and Net Debt.',
    icon: AssetExport.FinancialStatementsIcon,
    typeIcon: AssetExport.WidgetIcon,
  },
  {
    title: BALANCE_SHEET_GROWTH_BREAKDOWN,
    type: 'Widget',
    category: 'Financial Statements',
    description:
      'Information Included: Total Assets Growth, Total Liabilities Growth, Total Shareholders Equity Growth, Retained Earnings Growth, and Net Debt Growth',
    icon: AssetExport.FinancialStatementsIcon,
    typeIcon: AssetExport.WidgetIcon,
  },
  {
    title: ASSET_BREAKDOWN,
    type: 'Widget',
    category: 'Financial Statements',
    description:
      'Information Included: Cash And Cash Equivalents, Property Plant And Equipment, Total Current Assets, Total Non Current Assets, and Total Assets',
    icon: AssetExport.FinancialStatementsIcon,
    typeIcon: AssetExport.WidgetIcon,
  },
  {
    title: ASSET_GROWTH_BREAKDOWN,
    type: 'Widget',
    category: 'Financial Statements',
    description:
      'Information Included: Cash And Cash Equivalents Growth, Property Plant And Equipment Growth, Total Current Assets Growth, Total Non Current Assets Growth, and Total Assets Growth',
    icon: AssetExport.FinancialStatementsIcon,
    typeIcon: AssetExport.WidgetIcon,
  },
  {
    title: LIABILITIES_BREAKDOWN,
    type: 'Widget',
    category: 'Financial Statements',
    description:
      'Information Included: Short Term Debt, Long Term Debt, Total Current Liabilities, Total Non Current Liabilities, and Total Liabilities',
    icon: AssetExport.FinancialStatementsIcon,
    typeIcon: AssetExport.WidgetIcon,
  },
  {
    title: LIABILITIES_GROWTH_BREAKDOWN,
    type: 'Widget',
    category: 'Financial Statements',
    description:
      'Information Included: Short Term Debt Growth, Long Term Debt Growth, Total Current Liabilities Growth, Total Non Current Liabilities Growth, and Total Liabilities Growth',
    icon: AssetExport.FinancialStatementsIcon,
    typeIcon: AssetExport.WidgetIcon,
  },
  {
    title: EQUITY_BREAKDOWN,
    type: 'Widget',
    category: 'Financial Statements',
    description:
      'Information Included: Shareholders Equity, Common Stock, Retained Earnings, Accumulated Other Comprehensive Income Loss, and Total Equity',
    icon: AssetExport.FinancialStatementsIcon,
    typeIcon: AssetExport.WidgetIcon,
  },
  {
    title: EQUITY_GROWTH_BREAKDOWN,
    type: 'Widget',
    category: 'Financial Statements',
    description:
      'Information Included: Shareholders Equity Growth, Common Stock Growth, Retained Earnings Growth, Accumulated Other Comprehensive Income Loss Growth, and Liabilities And Stockholders Equity Growth',
    icon: AssetExport.FinancialStatementsIcon,
    typeIcon: AssetExport.WidgetIcon,
  },
  {
    title: INCOME_STATEMENT_BREAKDOWN,
    type: 'Widget',
    category: 'Financial Statements',
    description:
      'Information Included: Revenue, Gross Profit, Operating Expenses, EBITDA ratio, and Net Income',
    icon: AssetExport.FinancialStatementsIcon,
    typeIcon: AssetExport.WidgetIcon,
  },
  {
    title: INCOME_STATEMENT_BREAKDOWN,
    type: 'Widget',
    category: 'Financial Statements',
    description:
      'Information Included: Revenue, Gross Profit, Operating Expenses, EBITDA ratio, and Net Income',
    icon: AssetExport.FinancialStatementsIcon,
    typeIcon: AssetExport.WidgetIcon,
  },
  {
    title: REVENUE_BREAKDOWN,
    type: 'Widget',
    category: 'Financial Statements',
    description:
      'Information Included: Revenue, Cost Of Revenue, Gross Profit, Gross Profit Ratio, and Net Income',
    icon: AssetExport.FinancialStatementsIcon,
    typeIcon: AssetExport.WidgetIcon,
  },
  {
    title: EXPENSE_BREAKDOWN,
    type: 'Widget',
    category: 'Financial Statements',
    description:
      'Information Included: R&D Expense, SG&A Expense, Operating Expenses, Interest Expense, and Other Expenses',
    icon: AssetExport.FinancialStatementsIcon,
    typeIcon: AssetExport.WidgetIcon,
  },
  {
    title: EXPENSE_GROWTH_BREAKDOWN,
    type: 'Widget',
    category: 'Financial Statements',
    description:
      'Information Included: R&D Expense Growth, SG&A Expense Growth, Operating Expenses Growth, Interest Expense Growth, and Other Expenses Growth',
    icon: AssetExport.FinancialStatementsIcon,
    typeIcon: AssetExport.WidgetIcon,
  },
  {
    title: INCOME_BREAKDOWN,
    type: 'Widget',
    category: 'Financial Statements',
    description:
      'Information Included: Net income, Operating Income, Operating Income Ratio, Interest Income, and Income Before Tax',
    icon: AssetExport.FinancialStatementsIcon,
    typeIcon: AssetExport.WidgetIcon,
  },
  {
    title: INCOME_GROWTH_BREAKDOWN,
    type: 'Widget',
    category: 'Financial Statements',
    description:
      'Information Included: Net income Growth, Operating Income Growth, Operating Income Ratio Growth, Income Before Tax Growth, and Income Before Tax Ratio Growth',
    icon: AssetExport.FinancialStatementsIcon,
    typeIcon: AssetExport.WidgetIcon,
  },
  {
    title: CASHFLOW_BREAKDOWN,
    type: 'Widget',
    category: 'Financial Statements',
    description:
      'Information Included: Net Change In Cash Growth, Cash At End Of Period Growth, Operating Cash Flow Growth, Capital Expenditure Growth, and Free Cash Flow Growth',
    icon: AssetExport.FinancialStatementsIcon,
    typeIcon: AssetExport.WidgetIcon,
  },
  {
    title: CASHFLOW_GROWTH_BREAKDOWN,
    type: 'Widget',
    category: 'Financial Statements',
    description:
      'Information Included: Net Income, Cash From Operations, Cash Used For Investing, Cash Used Provided By Financing',
    icon: AssetExport.FinancialStatementsIcon,
    typeIcon: AssetExport.WidgetIcon,
  },
  {
    title: ENTERPRISE_VALUE,
    type: 'Widget',
    category: 'Fundamental Analysis',
    description: 'Information Included: Enterprise Value',
    icon: AssetExport.FundementalAnalysisIcon,
    typeIcon: AssetExport.WidgetIcon,
  },
  {
    title: INCOME_STATEMENT_GROWTH,
    type: 'Widget',
    category: 'Financial Statements',
    description:
      'Information Included: Revenue Growth, Gross Profit Growth, Operating Expenses Growth, EBITDA Growth, and Net Income Growth',
    icon: AssetExport.FundementalAnalysisIcon,
    typeIcon: AssetExport.WidgetIcon,
  },
  {
    title: BALANCE_SHEET_GROWTH,
    type: 'Widget',
    category: 'Fundamental Analysis',
    description:
      'Information Included: Total Asset Growth, Total Liabilities Growth, Total Stockholders Equity Growth, Retained Earnings Growth, and Net Debt Growth',
    icon: AssetExport.FundementalAnalysisIcon,
    typeIcon: AssetExport.WidgetIcon,
  },
  {
    title: VALUE_RATIO,
    type: 'Widget',
    category: 'Fundamental Analysis',
    description:
      'Information Included: Dividend yield, Earnings-Per-Share TTM, Price-To-Earnings Ratio TTM, Price-to-Book Ratio TTM',
    icon: AssetExport.FundementalAnalysisIcon,
    typeIcon: AssetExport.WidgetIcon,
  },
  {
    title: RISK_ANALYSIS_RATIO,
    type: 'Widget',
    category: 'Fundamental Analysis',
    description:
      'Information Included: Debt Ratio, Debt To Equity Ratio, Long Term Debt To Market Cap, Total Debt To Market Cap, and Cash Flow To Debt',
    icon: AssetExport.FundementalAnalysisIcon,
    typeIcon: AssetExport.WidgetIcon,
  },
  {
    title: PRICE_RATIOS,
    type: 'Widget',
    category: 'Fundamental Analysis',
    description:
      'Information Included: Price To Earnings Ratio, Price To Book Ratio, Price To Sales Ratio, Price to Earnings Growth Ratio, and Price To Cash Flow Ratio',
    icon: AssetExport.FundementalAnalysisIcon,
    typeIcon: AssetExport.WidgetIcon,
  },
  {
    title: OPERATING_PERFORMANCE_RATIOS,
    type: 'Widget',
    category: 'Fundamental Analysis',
    description:
      'Information Included: Gross Profit Margin, Net Profit Margin, Return On Equity, Return on Assets, and Return On Capital Employed',
    icon: AssetExport.FundementalAnalysisIcon,
    typeIcon: AssetExport.WidgetIcon,
  },
  {
    title: LIQUIDITY_RATIOS,
    type: 'Widget',
    category: 'Fundamental Analysis',
    description:
      'Information Included: Cash Ratio, Current Ratio, Quick Ratio, and Cash Conversion Cycle',
    icon: AssetExport.FundementalAnalysisIcon,
    typeIcon: AssetExport.WidgetIcon,
  },
  {
    title: TWITTER_SENTIMENT,
    type: 'Widget',
    category: 'External Statistics',
    description:
      'Information Included: Twitter Posts, Twitter Comments, Twitter Likes, Twitter Impressions, and Twitter Sentiment',
    icon: AssetExport.ExternalStatisticsIcon,
    typeIcon: AssetExport.WidgetIcon,
  },
  {
    title: STOCKTWITS_SENTIMENT,
    type: 'Widget',
    category: 'External Statistics',
    description:
      'Information Included: Stocktwits Posts, Stocktwits Comments, Stocktwits Likes, Stocktwits Impressions, and Stocktwits Sentiment',
    icon: AssetExport.ExternalStatisticsIcon,
    typeIcon: AssetExport.WidgetIcon,
  },
  {
    title: PRICE_CHARTS,
    type: 'Tool',
    category: 'Price',
    description: 'Advanced charting for technical analysis.',
    icon: AssetExport.PriceIcon,
    typeIcon: AssetExport.ToolIcon,
  },

  {
    title: SCREENER,
    type: 'Tool',
    category: '',
    description: 'Filter through stocks using metrics from all across OutSeek.',
    icon: AssetExport.ScreenerIcon,
    typeIcon: AssetExport.ToolIcon,
  },

  // TODO: NEED PARAMS
  // {
  // {
  //   title: "Social Sentiment",
  //   type: "Widget",
  //   category: "External Statistics",
  //   description:
  //     "Information Included: Twitter And Stocktwits Posts, Comments, Likes, Impressions",
  //   icon: AssetExport.ExternalStatisticsIcon,
  // typeIcon: AssetExport.WidgetIcon
  // },
  // {
  //   title: "ETF Breakdown",
  //   type: "Component",
  //   category: "Fund Holdings",
  //   description:
  //     "Multiple tables and a sunburst chart displays a breakdown for a specific ETF and includes the following information; securities held by the ETF, percentage of each individual security held within the ETF, sector weightings, industry weightings, and country weightings.",
  //   icon: AssetExport.FundHoldingsIcon,
  //   typeIcon: AssetExport.ComponentIcon
  // },
  // {
  //   title: "IPO Calendar",
  //   type: "Component",
  //   category: "",
  //   description:
  //     "A calendar, and two tables display IPO information for all stocks.",
  //   icon: AssetExport.ToolIcon,
  // },
  // {
  //   title: "Earning Calendar",
  //   type: "Tool",
  //   category: "",
  //   description:
  //     "A calendar, and two tables display earnings information for all stocks.",
  //   icon: AssetExport.ToolIcon,
  // },
  // {
  //   title: "Stock Split Calendar",
  //   type: "Tool",
  //   category: "",
  //   description:
  //     "A calendar, and two tables display stock split information for all stocks.",
  //   icon: AssetExport.ToolIcon,
  // },
  // {
  //   title: "Dividend Calendar",
  //   type: "Tool",
  //   category: "",
  //   description:
  //     "A calendar, and two tables display dividend information for all stocks.",
  //   icon: AssetExport.ToolIcon,
  // },
  // {
  //   title: "Earnings Calendar",
  //   type: "Component",
  //   category: "Company Information",
  //   description:
  //     "A calendar, table, and quick view displays when earnings are for all stocks and includes the following information: earnings date, estimated EPS, current EPS, and earnings time. The quick view includes the following information: stock, company name, time and date of earnings, industry, market cap, current EPS and revenue, estimated EPS and revenue, estimated change in EPS and revenue, and fiscal year-end date.",
  //   icon: AssetExport.CompanyInformationIcon,
  // },
  // {
  //   title: "Historical Social Sentiment",
  //   type: "Component",
  //   category: "External Statistics",
  //   description:
  //     "Displays the number of posts, likes, comments, and impressions for StockTwits and Twitter for a specific stock and can be filtered in time increments.",
  //   icon: AssetExport.ExternalStatisticsIcon,
  // },
  // {
  //   title: "Bullish Social Sentiment Rankings",
  //   type: "Component",
  //   category: "External Statistics",
  //   description:
  //     "A table and treemap display the top 50 stocks with the most bullish sentiment score and their previous sentiment score.",
  //   icon: AssetExport.ExternalStatisticsIcon,
  // },
  // {
  //   title: "Bearish Social Sentiment Ranking",
  //   type: "Component",
  //   category: "External Statistics",
  //   description:
  //     "A table and treemap display the top 50 stocks with the most bearish sentiment score and their previous sentiment score.",
  //   icon: AssetExport.ExternalStatisticsIcon,
  // },
  // {
  //   title: "Earnings Transcript",
  //   type: "Component",
  //   category: "Company Information",
  //   description:
  //     "Earnings call transcripts that can be filtered by year and quarter.",
  //   icon: AssetExport.CompanyInformationIcon,
  // },
  // {
  //   title: "Range",
  //   type: "Widget",
  //   category: "Price",
  //   description: "Information Included: Stock Price",
  //   icon: AssetExport.PriceIcon,
  // },
  // {
  //   title: "Institutional Stock Ownership",
  //   type: "Component",
  //   category: "Company Information",
  //   description: "Information Included: Breakdown of institutional ownership of a stock.",
  //   icon: AssetExport.CompanyInformationIcon,
  // },
  // {
  //   title: "Insider trading",
  //   type: "Component",
  //   category: "Insider trading",
  //   description:
  //     "A table that displays detailed insider trading transactions for all stocks and includes the following information: stock, owner name, owner position, transaction type, price of security at transaction, number of securities transacted, transaction value, and filling date. Results can be filtered and a search stock feature is available.",
  //   icon: AssetExport.InsiderTradingIcon,
  // },
  // {
]
