import React from 'react'
import { ETFInfoColumns } from '../columns'
import { TableKeyValue } from '../../../InstitutionalOwnership/components/TableKeyValue/TableKeyValue'
import numeral from 'numeral'
import styles from './styles.module.scss'
import ScrollableContainer from 'core/ScrollableContainer/ScrollableContainer'

export const ETFInfo = ({ data }) => {
  const aumInMillions = data?.aum / 1000000

  const labelValueMap = {
    Name: data?.name,
    'Assets Under Management (AUM)': `${numeral(aumInMillions).format(
      '$0,0.00'
    )} M`,
    'Net Asset Value (NAV)': numeral(data?.nav).format('$0.00'),
    'Expense Ratio': data?.expenseRatio,
    'Holding Count': data?.holdingsCount,
    'Asset Class': data?.assetClass,
    'Fund Website': (
      <a
        href={data?.website}
        target="_blank"
        rel="noreferrer"
        className={styles.fundWebsite}
      >
        Link
      </a>
    ),
  }

  const labelValueMapArray = Object.entries(labelValueMap)

  return (
    <div className={styles.ETFInfoWrapper}>
      <ScrollableContainer>
        <div className={styles.labelValueGrid}>
          {labelValueMapArray?.map(([label, value], i) => (
            <>
              <LabelValue key={label} label={label} value={value} />
              {i !== labelValueMapArray?.length - 1 && (
                <div className={styles.divider} />
              )}
            </>
          ))}
        </div>
      </ScrollableContainer>
      <div className={styles.infoDivider} />
      <div className={styles.descriptionTableWrapper}>
        <div className={styles.keyValueWrapper}>
          <TableKeyValue labels={ETFInfoColumns} data={[data]} />
        </div>
        <div className={styles.descriptionWrapper}>
          <div className={styles.desc}>
            <span className={styles.label}>ETF Description: </span>
            {data?.description}
          </div>
        </div>
      </div>
    </div>
  )
}

const LabelValue = ({ label, value }) => {
  return (
    <div className={styles.labelValue}>
      <span className={styles.label}>{label}</span>
      <span className={styles.value}>{value}</span>
    </div>
  )
}
