export const tagBackgroundPicker = type => {
  let tagBackground

  switch (type) {
    case 'Tool':
      tagBackground = 'rgba(231, 60, 60, 0.15)'
      return tagBackground
    case 'Component':
      tagBackground = 'rgba(55, 152, 249, 0.15)'
      return tagBackground
    case 'Widget':
      tagBackground = 'rgba(68, 204, 46, 0.15)'
      return tagBackground
    default:
      return tagBackground
  }
}
