import numeral from 'numeral'
import { downloadExcel } from 'react-export-table-to-excel'
import styles from './fnTable.module.scss'

export const formatNumericalValues = ({
  value,
  decimals,
  unit,
  showZeroValues,
}) => {
  if (showZeroValues && value === 0) {
    return '-'
  }
  const decimalFormat = '0'.repeat(decimals)
  switch (unit) {
    case 'thousand':
      return `${numeral(value / 1e3).format(`0,0.${decimalFormat}`)} k`
    case 'million':
      return `${numeral(value / 1e6).format(`0,0.${decimalFormat}`)} m`
    case 'billion':
      return `${numeral(value / 1e9).format(`0,0.${decimalFormat}`)} b`
    case 'default':
      return numeral(value).format(`0.${decimalFormat} a`)
    default:
      return value
  }
}

export const createCell = accessor => {
  return {
    Cell: ({ value }) => {
      return (
        <span
        // style={{ color: value > 0 ? red : value === 0 ? white : green }}
        >
          {value}
        </span>
      )
    },
    accessor,
  }
}

export const calculateCAGR = (startValue, endValue, years) => {
  return ((Math.pow(endValue / startValue, 1 / years) - 1) * 100).toFixed(2)
}

export const formatStaySameValues = ({ value, decimals }) => {
  const decimalFormat = '0'.repeat(decimals)
  return numeral(value).format(`0.${decimalFormat}`)
}

export const staySameValue = [
  'eps',
  'epsdiluted',
  'ebitdaratio',
  'netIncomeRatio',
  'grossProfitRatio',
  'operatingIncomeRatio',
  'incomeBeforeTaxRatio',
]

export const handleExportTable = (dataInRange, columnKeys, ticker, type) => {
  const header = dataInRange.map((item, index) => item?.date)
  const formattedData = columnKeys.map(keyObj => {
    const rowData = {}
    header.forEach((headerItem, index) => {
      rowData[headerItem] = dataInRange[index][keyObj.accessor]
    })
    return { property: keyObj.Header, ...rowData }
  })

  downloadExcel({
    fileName: `${ticker}_${type}_Outseek`,
    sheet: `${ticker} ${type} from Outseek`,
    tablePayload: {
      header: ['', ...header],
      body: formattedData,
    },
  })
}

/// table  styles functions

export const getCellLeftStyle = (
  cellIndex,
  cagrActive,
  first,
  second,
  third
) => {
  switch (cellIndex) {
    case 1:
      return { left: `${first}px` }
    case 2:
      return { left: `${first + 140}px` }
    case 3:
      return cagrActive ? { left: `${first + second + third}px` } : {}
    default:
      return {}
  }
}

export const getColumnId = (cellIndex, tableId) => {
  switch (cellIndex) {
    case 0:
      return `firstColumn-${tableId}`
    case 1:
      return `secondColumn-${tableId}`
    case 2:
      return `thirdColumn-${tableId}`
    default:
      return ''
  }
}

export const getCellClassNameHeader = (cellIndex, cagrActive) => {
  let className = ''

  if (cellIndex === 2 || (cellIndex === 3 && cagrActive)) {
    className += ''
  } else {
    className += styles.tableData
  }

  if (cellIndex === 0) {
    className += ` ${styles.stickyColumnHeader}`
  } else if (cellIndex === 1) {
    className += ` ${styles.stickyColumnHeader15}`
  } else if (cellIndex === 2) {
    className += cagrActive
      ? ` ${styles.stickyColumn2Header}`
      : ` ${styles.stickyColumnHeader4}`
  } else if (cellIndex === 3 && cagrActive) {
    className += ` ${styles.stickyColumnHeader4}`
  }

  return className.trim()
}

export const getCellClassNameBody = (
  cellIndex,
  cagrActive,
  cellClass,
  isTotalRow
) => {
  let className = ''

  if (!(cellIndex === 2 || (cellIndex === 3 && cagrActive))) {
    className += ` ${styles.tableData}`
  }

  className += ` ${cellClass}`

  if (isTotalRow) {
    className += ` ${styles.totalRow}`
  }

  if (cellIndex === 0) {
    className += ` ${styles.stickyColumn}`
  } else if (cellIndex === 1) {
    className += ` ${styles.stickyColumn15}`
  } else if (cellIndex === 2) {
    className += cagrActive
      ? ` ${styles.stickyColumn2}`
      : ` ${styles.stickyColumn4}`
  } else if (cellIndex === 3 && cagrActive) {
    className += ` ${styles.stickyColumn4}`
  }

  return className.trim()
}
