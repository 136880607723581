import http from '../../network/httpClient'
import { useQuery } from 'react-query'
import { useMemo } from 'react'

export const fetchCompanyData = async ({ queryKey }) => {
  const [, ticker] = queryKey
  const data = await http.get(
    `papi/data/api/v4/company-outlook?symbol=${ticker}`
  )
  const { profile, metrics, ratios } = data.data.message
  return { profile, metrics, ratios }
}

export const fetchStockPrice = async ({ queryKey }) => {
  const [, ticker] = queryKey
  const data = await http.get(`papi/data/api/v3/quote/${ticker}`)
  return data.data.message[0]
}

export const useDataSource = ({ ticker }) => {
  const { data: companyData, isLoading: companyDataIsLoading } = useQuery(
    ['companyData', ticker],
    fetchCompanyData,
    {
      refetchInterval: 60000,
    }
  )

  const { data: stockPrice, isLoading: stockPriceIsLoading } = useQuery(
    ['stockPrice', ticker],
    fetchStockPrice
  )

  const data = useMemo(() => {
    return {
      companyData,
      stockPrice,
      isLoading: companyDataIsLoading || stockPriceIsLoading,
    }
  }, [companyData, companyDataIsLoading, stockPrice, stockPriceIsLoading])

  return { data }
}
