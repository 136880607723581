export const labels = [
  {
    key: 'CountEPSGreaterThanEstimated',
    label: '# of Earnings Beat',
  },
  {
    key: 'CountEPSLessThanEstimated',
    label: '# of Earnings Missed',
  },
  {
    key: 'CountEPSEqualToEstimated',
    label: '# of Earnings Met',
  },
  {
    key: 'AveragePercentageDifference',
    label: 'Avg % Difference',
    percent: true,
  },
  {
    key: 'AverageChangePercent',
    label: 'Avg Price Chg',
    percent: true,
  },
  {
    key: 'Prediction',
    label: 'Next Earnings Price Chg Prediction',
    percent: true,
  },
]
