import React, { useState } from 'react'
import { startCase } from 'lodash'
import styles from './styles.module.scss'
import { displayCheck } from './utils'
import { UilCheckCircle, UilTimesCircle } from '@iconscout/react-unicons'

const ChecksTabs = ({ checks }) => {
  const [activeTab, setActiveTab] = useState(Object.keys(checks)?.[0])

  const iconSize = 18

  return (
    <div className={styles.checksTabs}>
      <div className={styles.tabWrapper}>
        {Object.keys(checks)?.map((checkCategory, i) => (
          <>
            <div
              key={checkCategory}
              style={{
                color:
                  activeTab === checkCategory
                    ? 'var(--primary-color)'
                    : 'var(--white)',
              }}
              className={styles.tabButton}
              onClick={() => setActiveTab(checkCategory)}
            >
              {startCase(checkCategory)} Checks
            </div>
            {i < Object.keys(checks).length - 1 && (
              <div className={styles.divider} />
            )}
          </>
        ))}
      </div>
      <div className={styles.checkTabContentWrapper}>
        {Object.entries(checks)?.map(
          ([checkCategory, checkList]) =>
            activeTab === checkCategory && (
              <div key={checkCategory} className={styles.checkTabContent}>
                {checkList.map((check, index) => (
                  <div key={`${checkCategory}${index}`} cla>
                    {check.passed ? (
                      <UilCheckCircle color="var(--green)" size={iconSize} />
                    ) : (
                      <UilTimesCircle color="var(--red)" size={iconSize} />
                    )}{' '}
                    {displayCheck(check)}?
                  </div>
                ))}
              </div>
            )
        )}
      </div>
    </div>
  )
}

export default ChecksTabs
