import React from 'react'
import { PieChart, Pie, Cell, Tooltip } from 'recharts'
import { renderTooltipContentPieChart } from '../RevenueSegments/PieChart'

let colorSchemeFill = [
  'rgba(178, 224, 97, 0.6)',
  'rgba(126, 176, 213, 0.6)',
  'rgba(253, 127, 111, 0.6)',
]
const RADIAN = Math.PI / 180
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)

  return (
    <text
      x={x + 2}
      y={y}
      fill="var(--white)"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  )
}

export const PieChartWrapper = ({ data }) => {
  return (
    <PieChart width={190} height={200}>
      <Pie
        data={data}
        cx="50%"
        cy="50%"
        label={renderCustomizedLabel}
        labelLine={false}
        outerRadius={70}
        dataKey="value"
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={colorSchemeFill[index]} />
        ))}
      </Pie>
      <Tooltip
        itemStyle={{ fontSize: '12px' }}
        labelStyle={{
          fontSize: '10.5px',
          color: 'rgba(215, 215, 215)',
        }}
        contentStyle={{ background: 'transparent', border: 'transparent' }}
        content={content => renderTooltipContentPieChart(content, false)}
      />
    </PieChart>
  )
}
