import styles from './styles.module.scss'

const blue = 'var(--primary-color)'

const categoryTagColor = {
  Valuation: '#C75302', // Darker Soft Orange
  Growth: '#967110', // Darker Soft Yellow
  Profitability: '#BE5843', // Darker Soft Coral
  'Financial Health': '#537C92', // Darker Soft Sky Blue
  'Management Effectiveness': '#687D59', // Darker Soft Green
  Dividends: '#C04C8E', // Darker Soft Pink
  Efficiency: '#A64ADF', // Darker Soft Purple
  'Balance Sheet': blue,
  'Income Statement': blue,
  'Cash Flow Statement': blue,
}

export const InfoBlock = ({ title, category, description }) => {
  const tagColor = categoryTagColor?.[category] || 'none'

  return (
    <div className={styles.infoBlock}>
      <div className={styles.header}>
        <div >{title}</div>
        <div
          className={styles.categoryTag}
          style={{ backgroundColor: tagColor }}
        >
          {category}
        </div>
      </div>
      <div className={styles.description}>{description}</div>
    </div>
  )
}
