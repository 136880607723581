import { useState } from 'react'
import { useQuery } from 'react-query'
import http from 'network/httpClient'

const fetchYearsAndQuarters = async ticker => {
  const response = await http.get(
    'papi/data/api/v4/earning_call_transcript?symbol=' + ticker
  )

  const uniqueYears = Array.from(
    new Set(response.data.message.map(item => item[1]))
  )

  return uniqueYears
}

const fetchQuarters = async (ticker, selectedYear) => {
  const response = await http.get(
    'papi/data/api/v4/batch_earning_call_transcript/' +
      ticker +
      '?year=' +
      selectedYear
  )

  return response.data?.message?.map(item => item?.quarter)
}

const fetchContent = async (ticker, selectedYear, selectedQuarter) => {
  const response = await http.get(
    'papi/data/api/v3/earning_call_transcript/' +
      ticker +
      '?quarter=' +
      selectedQuarter +
      '&year=' +
      selectedYear
  )

  const data = response.data.message[0]
    ? response.data.message[0]
    : { content: '', date: '' }
  return { content: data.content, date: data.date }
}

const useEarningsTranscript = ticker => {
  const [selectedYear, setSelectedYear] = useState(null)
  const [selectedQuarter, setSelectedQuarter] = useState(null)

  const {
    data: years,
    isLoading: yearsLoading,
    isError: yearsError,
  } = useQuery('years', () => fetchYearsAndQuarters(ticker), {
    onSuccess: data => {
      setSelectedYear(Math.max(...data))
    },
  })

  const {
    data: quarters,
    isLoading: quartersLoading,
    isError: quartersError,
  } = useQuery(
    ['quarters', selectedYear],
    () => fetchQuarters(ticker, selectedYear),
    {
      enabled: !!selectedYear,
      onSuccess: data => {
        setSelectedQuarter(Math.max(...data))
      },
    }
  )

  const {
    data: { content = '', date = '' } = {},
    isLoading: contentDateLoading,
    isError: contentDateError,
  } = useQuery(
    ['content', ticker, selectedYear, selectedQuarter],
    () => fetchContent(ticker, selectedYear, selectedQuarter),
    {
      enabled: !!selectedYear && !!selectedQuarter,
    }
  )

  return {
    years,
    quarters,
    selectedYear,
    selectedQuarter,
    content,
    setSelectedYear,
    setSelectedQuarter,
    yearsLoading,
    quartersLoading,
    contentDateLoading,
    yearsError,
    quartersError,
    contentDateError,
    date,
  }
}

export default useEarningsTranscript
