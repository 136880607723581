import React, { useRef, useEffect } from 'react'
import * as d3 from 'd3'
import numeral from 'numeral'
import dayjs from 'dayjs'

const InsiderD3Chart = ({
  data = [],
  metrics = [],
  XAxisKey,
  lineDataKey,
  mirrored = false,
  customHeight,
}) => {
  const chartRef = useRef(null)
  const tooltipLineRef = useRef(null)
  const tooltipBarRef = useRef(null)

  useEffect(() => {
    drawChart()

    const resizeObserver = new ResizeObserver(() => {
      drawChart()
    })

    resizeObserver.observe(chartRef.current.parentNode)

    return () => resizeObserver.disconnect()
  }, [data, metrics])

  const drawChart = () => {
    const tooltipLine = d3
      .select(tooltipLineRef.current)
      .style('position', 'absolute')
      .style('background-color', 'rgba(33, 33, 33, 0.9)')
      .style('color', 'white')
      .style('padding', '4px')
      .style('border', '1px solid rgba(150, 150, 150)')
      .style('border-radius', '4px')
      .style('font-size', '11px')
      .style('pointer-events', 'none')
      .style('display', 'none')
      .style('z-index', '10000')

    const tooltipBars = d3
      .select(tooltipBarRef.current)
      .style('position', 'absolute')
      .style('background-color', 'rgba(33, 33, 33, 0.9)')
      .style('color', 'white')
      .style('padding', '4px')
      .style('border', '1px solid rgba(150, 150, 150)')
      .style('border-radius', '4px')
      .style('font-size', '11px')
      .style('pointer-events', 'none')
      .style('display', 'none')
      .style('z-index', '10000')

    d3.select(chartRef.current).selectAll('*').remove()

    const svg = d3.select(chartRef.current)

    const margin = { top: 8, right: 45, bottom: 16, left: 45 }

    const width =
      svg.node().parentNode.getBoundingClientRect().width -
      margin.left -
      margin.right
    const height =
      svg.node().parentNode.getBoundingClientRect().height -
      margin.top -
      margin.bottom

    svg.attr(
      'viewBox',
      `0 0 ${width + margin.left + margin.right} ${
        height + margin.top + margin.bottom
      }`
    )

    if (mirrored) {
      data = data.map(item => ({
        ...item,
        [metrics[1].key]: Math.abs(item[metrics[1].key]),
      }))
    }

    const xScale = d3
      .scaleTime()
      .domain(d3.extent(data, d => new Date(d[XAxisKey])))
      .range([0, width])

    const maxAbsoluteValue = Math.max(
      ...data.map(item =>
        Math.max(Math.abs(item[metrics[0].key]), Math.abs(item[metrics[1].key]))
      )
    )

    const hoverLine = svg
      .append('line')
      .attr('class', 'hover-line')
      .attr('y1', margin.top)
      .attr('y2', height + margin.top)
      .attr('stroke', 'grey')
      .style('pointer-events', 'none')
      .style('display', 'none')

    const domainMin = d3.min(data, d => d[lineDataKey]) * 0.9
    const domainMax = d3.max(data, d => d[lineDataKey]) * 1.1

    const tickInterval = (domainMax - domainMin) / 4
    const middleTicks = [
      domainMin + tickInterval,
      domainMin + 2 * tickInterval,
      domainMin + 3 * tickInterval,
    ]

    const customTicks = [domainMin, ...middleTicks, domainMax]

    const yScaleBars = d3
      .scaleLinear()
      .domain([-maxAbsoluteValue * 1.2, maxAbsoluteValue * 1.2])
      .range([height, 0])

    const yScale = d3
      .scaleLinear()
      .domain([domainMin, domainMax])
      .range([height, 0])

    const xAxis = d3
      .axisBottom(xScale)
      .tickSize(2)
      .tickSizeOuter(0)
      .tickFormat(d => dayjs(d).format('MMM YY' + "'"))

    const yAxis = d3
      .axisLeft(yScale)
      .tickValues(customTicks)
      .tickSize(2)
      .tickSizeOuter(0)
      .tickFormat(d => numeral(d).format('00a'))

    const yAxisBars = d3
      .axisRight(yScaleBars)
      .ticks(4)
      .tickSize(2)
      .tickSizeOuter(0)
      .tickFormat(d => numeral(d).format('0a'))

    const xAxisGroup = svg
      .append('g')
      .attr('transform', `translate(${margin.left},${height + margin.top})`)

    xAxisGroup.call(xAxis)
    xAxisGroup.select('.domain').attr('stroke', '#666666')
    xAxisGroup.selectAll('line').attr('stroke', '#666666')
    xAxisGroup
      .selectAll('text')
      .style('color', '#666666')
      .style('font-size', '11px')
      .style('font-weight', '400')

    const yAxisGroup = svg
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`)

    yAxisGroup.call(yAxis)
    yAxisGroup.select('.domain').attr('stroke', '#666666')
    yAxisGroup.selectAll('line').attr('stroke', '#666666')
    yAxisGroup
      .selectAll('text')
      .style('color', '#666666')
      .style('font-size', '13px')
      .style('font-weight', '400')

    const yAxisBarsGroup = svg
      .append('g')
      .attr('transform', `translate(${width + margin.left}, ${margin.top})`)

    yAxisBarsGroup.call(yAxisBars)
    yAxisBarsGroup.select('.domain').attr('stroke', '#666666')
    yAxisBarsGroup.selectAll('line').attr('stroke', '#666666')
    yAxisBarsGroup
      .selectAll('text')
      .style('color', '#666666')
      .style('font-size', '13px')
      .style('font-weight', '400')

    const line = d3
      .line()
      .curve(d3.curveMonotoneX)
      .x(d => xScale(new Date(d[XAxisKey])) + margin.left)
      .y(d => yScale(d[lineDataKey]) + margin.top)

    svg
      .append('path')
      .datum(data)
      .attr('d', line)
      .attr('fill', 'none')
      .attr('stroke', 'var(--primary-color)')
      .attr('stroke-width', 1)

    svg
      .selectAll('.horizontal-line')
      .data(customTicks)
      .enter()
      .append('line')
      .attr('class', 'horizontal-line')
      .attr('x1', margin.left)
      .attr('x2', width + margin.left)
      .attr('y1', d => yScale(d) + margin.top)
      .attr('y2', d => yScale(d) + margin.top)
      .attr('stroke', 'var(--button-hover)')
      .attr('stroke-width', 2)

    const barWidth = width / data.length + 4

    svg
      .selectAll('.bar-acquisition')
      .data(data)
      .enter()
      .append('rect')
      .attr('class', 'bar-acquisition')
      .attr('fill', 'var(--green)')
      .attr('x', d => xScale(new Date(d[XAxisKey])) + margin.left)
      .attr(
        'y',
        d => Math.min(yScaleBars(0), yScaleBars(d[metrics[0].key])) + margin.top
      )
      .attr('width', barWidth)
      .attr('height', d =>
        Math.abs(yScaleBars(0) - yScaleBars(d[metrics[0].key]))
      )
      .attr('opacity', '0.8')
      .attr('ry', d => (d[metrics[0].key] > 0 ? 3 : 0))
      .on('mouseover', (event, d) => {
        tooltipBarRef.current.style.display = 'inline-block'
        d3.select(tooltipBarRef.current).html(
          `Date: ${dayjs(new Date(d[XAxisKey])).format(
            'MMM DD, YYYY'
          )}'<br/>Acquisition: ${numeral(d[metrics[0].key]).format('0a')} 
          <br/>Disposition: ${numeral(d[metrics[1].key]).format('0a')}`
        )
      })
      .on('mousemove', event => {
        d3.select(tooltipBarRef.current)
          .style('left', `${event.pageX - 120}px`)
          .style('top', `${event.pageY - 28}px`)
      })
      .on('mouseout', () => {
        d3.select(tooltipBarRef.current).style('display', 'none')
        tooltipLineRef.current.style('display', 'none')
      })

    svg
      .selectAll('.bar-disposition')
      .data(data)
      .enter()
      .append('rect')
      .attr('class', 'bar-disposition')
      .attr('fill', 'var(--red)')
      .attr('x', d => xScale(new Date(d[XAxisKey])) + margin.left)
      .attr('y', d => yScaleBars(0) + margin.top)
      .attr('width', barWidth)
      .attr('height', d =>
        Math.abs(yScaleBars(0) - yScaleBars(d[metrics[1].key]))
      )
      .attr('opacity', '0.8')
      .attr('ry', d => (d[metrics[1].key] > 0 ? 3 : 0))
      .on('mouseover', (event, d) => {
        tooltipBarRef.current.style.display = 'inline-block'
        d3.select(tooltipBarRef.current).html(
          `Date: ${dayjs(new Date(d[XAxisKey])).format(
            'MMM DD, YYYY'
          )}'<br/>Acquisition: ${numeral(d[metrics[0].key]).format('0a')} 
          <br/>Disposition: ${numeral(d[metrics[1].key]).format('0a')}`
        )
      })
      .on('mousemove', event => {
        d3.select(tooltipBarRef.current)
          .style('left', `${event.pageX - 120}px`)
          .style('top', `${event.pageY - 28}px`)
      })
      .on('mouseout', () => {
        d3.select(tooltipBarRef.current).style('display', 'none')
        tooltipLineRef.current.style('display', 'none')
      })

    const circle = svg
      .append('circle')
      .attr('class', 'hover-circle')
      .attr('r', 10)
      .attr('fill', '#8884d8')
      .attr('stroke', 'white')
      .attr('stroke-width', 2)
      .style('pointer-events', 'none')
      .style('display', 'none')

    svg
      .on('mouseover', () => {
        hoverLine.style('display', null)
        tooltipLineRef.current.style('display', 'inline-block')
      })
      .on('mouseout', () => {
        hoverLine.style('display', 'none')
        tooltipLineRef.current.style('display', 'none')
        circle.style('display', 'none')
      })
    svg.on('mousemove', event => {
      const mouseX = d3.pointer(event)[0]
      const mouseY = d3.pointer(event)[1]

      const isInsideChart =
        mouseX > margin.left &&
        mouseX < width + margin.left &&
        mouseY > margin.top &&
        mouseY < height + margin.top

      if (!isInsideChart) {
        hoverLine.style('display', 'none')
        tooltipLineRef.current.style('display', 'none')
        circle.style('display', 'none')
        return
      }

      hoverLine.attr('x1', mouseX).attr('x2', mouseX)

      const date = xScale.invert(mouseX - margin.left)
      const closestData = data.reduce((prev, curr) => {
        return Math.abs(dayjs(prev[XAxisKey]).diff(dayjs(date))) <
          Math.abs(dayjs(curr[XAxisKey]).diff(dayjs(date)))
          ? prev
          : curr
      })

      tooltipLine
        .html(
          `Date: ${dayjs(new Date(closestData[XAxisKey])).format(
            'MMM DD, YY'
          )}'<br/>Price: ${numeral(closestData[lineDataKey]).format('0.00a')}`
        )
        .style('left', `${event.pageX + 10}px`)
        .style('top', `${event.pageY - 28}px`)

      circle
        .attr('cx', mouseX)
        .attr('cy', yScale(closestData[lineDataKey]) + margin.top)
        .attr('r', 4)
        .style('display', null)
    })
    svg.on('mouseover', () => {
      hoverLine.style('display', null)
      tooltipLineRef.current.style('display', 'inline-block')
    })

    svg.on('mouseout', () => {
      hoverLine.style('display', 'none')
      tooltipLineRef.current.style('display', 'none')
      tooltipBarRef.current.style('display', 'none')
      circle.style('display', 'none')
    })

    const handleMouseMove = event => {
      const mouseX = d3.pointer(event)[0]
      const mouseY = d3.pointer(event)[1]

      const isInsideChart =
        mouseX > margin.left &&
        mouseX < width + margin.left &&
        mouseY > margin.top &&
        mouseY < height + margin.top

      if (!isInsideChart) {
        hoverLine.style('display', 'none')
        tooltipLineRef.current.style.display = 'none'
        circle.style('display', 'none')
        return
      }

      hoverLine.attr('x1', mouseX).attr('x2', mouseX)

      const date = xScale.invert(mouseX - margin.left)
      const closestData = data.reduce((prev, curr) => {
        return Math.abs(dayjs(prev[XAxisKey]).diff(dayjs(date))) <
          Math.abs(dayjs(curr[XAxisKey]).diff(dayjs(date)))
          ? prev
          : curr
      })

      tooltipLine
        .html(
          `Date: ${dayjs(new Date(closestData[XAxisKey])).format(
            'MMM DD, YY'
          )}'<br/>Price: ${numeral(closestData[lineDataKey]).format('0.00a')}`
        )
        .style('left', `${event.pageX + 10}px`)
        .style('top', `${event.pageY - 28}px`)
        .style('display', 'inline-block')

      circle
        .attr('cx', mouseX)
        .attr('cy', yScale(closestData[lineDataKey]) + margin.top)
        .attr('r', 4)
        .style('display', null)
    }

    svg
      .on('mouseover', () => {
        hoverLine.style('display', null)
        tooltipLineRef.current.style('display', 'inline-block')
      })
      .on('mouseout', () => {
        hoverLine.style('display', 'none')
        tooltipLineRef.current.style.display = 'none'
        tooltipBarRef.current.style.display = 'none'
        circle.style('display', 'none')
      })
      .on('mousemove', handleMouseMove)
  }

  return (
    <div
      style={{ width: '100%', height: customHeight ? customHeight : '100%' }}
      onMouseLeave={() => {
        tooltipLineRef.current.style.display = 'none'
        tooltipBarRef.current.style.display = 'none'
      }}
    >
      <svg ref={chartRef} width="100%" height="100%"></svg>
      <div ref={tooltipLineRef}></div>
      <div ref={tooltipBarRef}></div>
    </div>
  )
}

export default InsiderD3Chart
