import React from 'react'
import ChartPill from '../../../../../core/ChartPill/ChartPill'
import styles from './TableTrendWidgetTypeB.module.scss'
import { RANDOMCHARTCOLORS } from '../../../../../constants/theme'

export const ChartPills = ({
  metrics,
  handleDeleteTrend,
  noPadding,
  useName,
}) => {
  return (
    <div
      className={styles.tableTrendTypeBWidgetChartPills}
      style={{
        padding: noPadding ? null : '8px 8px 0px 8px',
        height: noPadding ? 32 : 40,
      }}
    >
      {metrics?.map(({ key, name, color }) => (
        <ChartPill
          color={color}
          label={useName ? name : key}
          onDelete={() => handleDeleteTrend({ key })}
          key={key}
          rawLabel={true}
        />
      ))}
    </div>
  )
}

export const accumulatedPercentChange = (newData, oldData, newSymbol) => {
  let baseValue = newData[0]?.close || 0

  return newData.map((entry, i) => {
    let accumulatedPercentChange = ((entry.close - baseValue) / baseValue) * 100

    return {
      ...oldData?.[i],
      [newSymbol]: accumulatedPercentChange,
      date: entry.date,
    }
  })
}

export const handlePushMetric = (
  row,
  metrics,
  setMetrics,
  fetchData,
  buildUrl,
  chartData,
  setChartData
) => {
  const metric = row?.original?.symbol
  setMetrics([
    ...metrics,
    {
      key: metric,
      color: RANDOMCHARTCOLORS[metrics.length % RANDOMCHARTCOLORS.length],
      rowId: row.id,
    },
  ])
  fetchData(buildUrl(metric)).then(newData =>
    setChartData(accumulatedPercentChange(newData, chartData, metric))
  )
}

export const handlePopMetric = (
  row,
  metrics,
  setMetrics,
  chartData,
  setChartData
) => {
  const metric = row?.original?.symbol
  setMetrics([...metrics.filter(entry => entry.key !== metric)])
  setChartData([
    ...chartData.map(entry => {
      const newEntry = { ...entry }
      delete newEntry[metric]
      return newEntry
    }),
  ])
}

export const handleDeleteTrend = (
  metricToDelete,
  metrics,
  setMetrics,
  chartData,
  setChartData
) => {
  const { key } = metricToDelete
  setMetrics([...metrics.filter(entry => entry.key !== key)])
  setChartData([
    ...chartData.map(entry => {
      const newEntry = { ...entry }
      delete newEntry[key]
      return newEntry
    }),
  ])
}

export const handleUpdateCommodityTimeRange = (
  interval,
  setCommodityInterval
) => {
  setCommodityInterval(interval)
}
