import numeral from 'numeral'

export const getQuoteBarItems = data => {
  const recentData = data?.[0]
  return [
    {
      value: recentData?.cik ? recentData?.cik : '-',
      label: 'CIK',
    },
    {
      value: recentData?.marketValue
        ? numeral(recentData?.marketValue).format('0.00 a')
        : '-',
      label: 'Market Value',
    },
    {
      value: recentData?.portfolioSize
        ? numeral(recentData?.portfolioSize).format('0.00 a')
        : '-',
      label: 'Portfolio Size',
    },
    {
      value: recentData?.performancePercentage1year
        ? `${numeral(recentData?.performancePercentage1year).format('0.00')}%`
        : '-',
      label: '1 Y Perf.',
    },
    {
      value: recentData?.performancePercentage3year
        ? `${numeral(recentData?.performancePercentage3year).format('0.00')}%`
        : '-',
      label: '3 Y Perf.',
    },
    {
      value: recentData?.performancePercentage5year
        ? `${numeral(recentData?.performancePercentage5year).format('0.00')}%`
        : '-',
      label: '5 Y Perf.',
    },
    {
      value: recentData?.turnover
        ? `${numeral(recentData?.turnover).format('0.000')}`
        : '-',
      label: 'Turnover',
    },
    {
      value: recentData?.averageHoldingPeriod
        ? `${numeral(recentData?.averageHoldingPeriod).format('0')}`
        : '-',
      label: 'Avg Holding Period',
    },
  ]
}
