export const themes = {
  outseekDark: {
    '--outseek-background': '#121212',
    '--background-primary': '#202122',
    '--background-secondary': '#2d2d2d',
    '--background-tertiary': '#3d3d3d',
    '--primary-color': '#4079a8',
    '--white': '#FFF',
    '--off-white': '#d7d7d7',
    '--grey-accent': '#696969',
    '--light-grey-accent': '#b3b3b3',
  },
  purpleDeluxe: {
    '--outseek-background': '#121212', // Deep black
    '--background-primary': '#1E1E1E', // Dark grey
    '--background-secondary': '#252525', // Medium dark grey
    '--background-tertiary': '#2E2E2E', // Slightly lighter dark grey
    '--primary-color': '#805cad', // Vivid purple
    '--white': '#FFFFFF', // Bright white
    '--off-white': '#B3B3B3', // Light grey
    '--grey-accent': '#333333', // Dark shadow grey
    '--light-grey-accent': '#b0b0b0', // Medium shadow grey
  },
  blueDeluxe: {
    '--outseek-background': '#121212', // Deep black
    '--background-primary': '#1E1E1E', // Dark grey
    '--background-secondary': '#252525', // Medium dark grey
    '--background-tertiary': '#2E2E2E', // Slightly lighter dark grey
    '--primary-color': '#4079a8', // Vivid purple
    '--white': '#FFFFFF', // Bright white
    '--off-white': '#B3B3B3', // Light grey
    '--grey-accent': '#333333', // Dark shadow grey
    '--light-grey-accent': '#b0b0b0', // Medium shadow grey
  },
  orangeDeluxe: {
    '--outseek-background': '#121212',
    '--background-primary': '#1E1E1E',
    '--background-secondary': '#252525',
    '--background-tertiary': '#2E2E2E',
    '--primary-color': '#bc8c3d',
    '--white': '#FFFFFF',
    '--off-white': '#B3B3B3',
    '--grey-accent': '#333333',
    '--light-grey-accent': '#b0b0b0',
  },

  azureEclipse: {
    '--outseek-background': '#0E1621', // Deep space blue
    '--background-primary': '#121D2B', // Midnight blue
    '--background-secondary': '#172537', // Dark azure
    '--background-tertiary': '#1D2E44', // Navy blue
    '--primary-color': '#3B82F6', // Bright azure
    '--white': '#FFFFFF', // Pure white
    '--off-white': '#AAB4BE', // Light periwinkle
    '--grey-accent': '#273849', // Dark grey blue
    '--light-grey-accent': '#5A6D7B', // Slate grey
  },
  midnightNeon: {
    '--outseek-background': '#1a1a2e', // Midnight blue
    '--background-primary': '#16213e', // Deep twilight
    '--background-secondary': '#0f3460', // Dusk blue
    '--background-tertiary': '#53354a', // Twilight purple
    '--primary-color': '#e94560', // Neon pink
    '--white': '#f5f5f5', // Bright white
    '--off-white': '#e8e8e8', // Soft white
    '--grey-accent': '#1f4068', // Dark azure
    '--light-grey-accent': '#a6b1e1', // Soft neon blue
  },

  techInnovation: {
    '--outseek-background': '#263238', // Deep Space Blue
    '--background-primary': '#37474F', // Smoky Steel
    '--background-secondary': '#455A64', // Dark Slate
    '--background-tertiary': '#546E7A', // Cool Grey
    '--primary-color': '#00ACC1', // Sky Blue
    '--white': '#ECEFF1', // Cloud White
    '--off-white': '#CFD8DC', // Light Mist
    '--grey-accent': '#00838F', // Ocean Blue
    '--light-grey-accent': '#26C6DA', // Bright Turquoise
  },
  urbanTwilight: {
    '--outseek-background': '#2b2d42', // Dark slate gray
    '--background-primary': '#3f4551', // Smoky dark blue
    '--background-secondary': '#424b5e', // Deep twilight blue
    '--background-tertiary': '#63707d', // Soft night sky
    '--primary-color': '#23d997', // Neon green
    '--white': '#edf2f4', // Pale moonlight
    '--off-white': '#8d99ae', // Cloudy gray
    '--grey-accent': '#2b2d42', // Echoing the background for a seamless transition
    '--light-grey-accent': '#8d99ae', // Twilight haze
  },

  neonSunset: {
    '--outseek-background': '#1d2952', // Sunset navy
    '--background-primary': '#233460', // Deep twilight blue
    '--background-secondary': '#2a3f6e', // Dusk blue
    '--background-tertiary': '#32497c', // Evening sky
    '--primary-color': '#ff8e53', // Neon orange
    '--white': '#fafafa', // Bright off-white
    '--off-white': '#eaeaea', // Light grey
    '--grey-accent': '#2d4373', // Twilight grey
    '--light-grey-accent': '#9c89b8', // Soft lavender
  },

  cosmicLatte: {
    '--outseek-background': '#1F1B24', // Deep cosmic purple
    '--background-primary': '#2A2330', // Dark cosmic latte
    '--background-secondary': '#342B3D', // Medium cosmic purple
    '--background-tertiary': '#3F3449', // Light cosmic purple
    '--primary-color': '#F4EAE6', // Creamy latte
    '--white': '#FFFFFF', // Pure white
    '--off-white': '#CBC3C9', // Light lavender grey
    '--grey-accent': '#473C4F', // Dark lavender grey
    '--light-grey-accent': '#857D88', // Soft lavender
  },

  forestNight: {
    '--outseek-background': '#04170d', // Even darker deep forest green
    '--background-primary': '#072411', // Even darker moss green
    '--background-secondary': '#08301a', // Even darker medium green
    '--background-tertiary': '#093d22', // Even darker slightly lighter green
    '--primary-color': '#4db375', // Soft mint (unchanged for accent contrast)
    '--white': '#FFFFFF', // Pure white (unchanged)
    '--off-white': '#8a998c', // Even darker soft grey-green
    '--grey-accent': '#265033', // Even darker jungle green
    '--light-grey-accent': '#5d6f5e', // Even darker moss green
  },
  sapphireDepth: {
    '--outseek-background': '#031726', // Even darker deep navy
    '--background-primary': '#052034', // Even darker dark blue
    '--background-secondary': '#06293f', // Even darker medium dark blue
    '--background-tertiary': '#07334b', // Even darker slightly lighter navy
    '--primary-color': '#1d8aa5', // Bright cerulean (unchanged for vibrant accent)
    '--white': '#FFFFFF', // Crisp white (unchanged)
    '--off-white': '#95a6b1', // Even darker light blue-grey
    '--grey-accent': '#1e364e', // Even darker dark azure
    '--light-grey-accent': '#41677d', // Even darker soft sky blue
  },

  digitalSlate: {
    '--outseek-background': '#323F4B',
    '--background-primary': '#2A343F',
    '--background-secondary': '#202B36',
    '--background-tertiary': '#1B2631',
    '--primary-color': '#5BC0BE',
    '--white': '#E0E1E2',
    '--off-white': '#CBD2D9',
    '--grey-accent': '#5E6973',
    '--light-grey-accent': '#8493A8',
  },
  blackout: {
    '--outseek-background': '#000000', // Absolute Black
    '--background-primary': '#0e0e0e', // Slightly lighter black
    '--background-secondary': '#141414', // Dark gray
    '--background-tertiary': '#1f1f1f', // Even darker gray
    '--primary-color': '#575757', // Dim gray for primary accents
    '--white': '#e0e0e0', // Soft white for contrast against the dark background
    '--off-white': '#a9a9a9', // Dark gray as an alternative to white
    '--grey-accent': '#2e2e2e', // Medium dark gray for less prominent accents
    '--light-grey-accent': '#424242', // Lighter gray for borders or subtle highlights
  },
  neonDusk: {
    '--outseek-background': '#1e2139', // Deep indigo
    '--background-primary': '#282846', // Dark violet
    '--background-secondary': '#343651', // Nightfall blue
    '--background-tertiary': '#4a4e69', // Twilight mauve
    '--primary-color': '#ff5e57', // Neon red
    '--white': '#f7f7f2', // Soft ivory
    '--off-white': '#e0e1dd', // Misty morning
    '--grey-accent': '#4a4e69', // Muted twilight
    '--light-grey-accent': '#9a8c98', // Soft lavender
  },
  eyeDamage: {
    '--outseek-background': '#ff00ff', // Neon magenta
    '--background-primary': '#00ff00', // Lime green
    '--background-secondary': '#ff6700', // Bright orange
    '--background-tertiary': '#0ff', // Cyan
    '--primary-color': '#ff0000', // Bright red
    '--white': '#00fffb', // Electric cyan
    '--off-white': '#ffff01', // Neon yellow
    '--grey-accent': '#8e44ad', // Vivid purple
    '--light-grey-accent': '#f39c12', // Neon orange
  },
}

export const mapThemeName = {
  '--outseek-background': 'Main Background',
  '--background-primary': 'Background Primary',
  '--background-secondary': 'Background Secondary',
  '--background-tertiary': 'Background Tertiary',
  '--primary-color': 'Main Color',
  '--white': 'Text',
  '--off-white': 'Text-Secondary',
  '--grey-accent': 'Accent',
  '--light-grey-accent': 'Accent-Secondary',
}
