import React, { useEffect, useRef, useState } from 'react'
import { sankey, sankeyLinkHorizontal } from 'd3-sankey'
import { select, selectAll } from 'd3-selection'
import { sankeyCircular } from 'd3-sankey-circular'
import numeral from 'numeral'

const SankyChart = ({ data, padding, color }) => {
  const chartRef = useRef()
  const [dimensions, setDimensions] = useState({
    width: window?.innerWidth,
    height: window?.innerHeight,
  })

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window?.innerWidth,
        height: window?.innerHeight,
      })
    }
    window?.addEventListener('resize', handleResize)
    return () => {
      window?.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (data && chartRef?.current) {
      select(chartRef.current).selectAll('*').remove()
      drawChart(data)
    }
  }, [data, padding, color])

  // TODO: memoize
  const drawChart = originalData => {
    const margin = { top: 0, right: 50, bottom: 70, left: 0 }
    const innerWidth = dimensions.width - margin.left - margin.right
    const innerHeight = dimensions.height - margin.top - margin.bottom

    const filteredData = JSON.parse(JSON.stringify(originalData))

    filteredData?.nodes?.forEach(node => {
      node.value = Math.abs(node.value)
    })
    filteredData?.links?.forEach(link => {
      link.value = Math.abs(link.value)
    })

    const svg = select(chartRef.current)
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('viewBox', `0 0 ${dimensions.width} ${dimensions.height}`)

    const sankeyDiagram = sankey()
      .nodeWidth(20)
      .nodePadding(padding)
      .extent([
        [margin.left, margin.top],
        [innerWidth, innerHeight],
      ])

    const sankeyCircularDiagram = sankeyCircular()
      .nodeWidth(20)
      .nodePadding(20)
      .extent([
        [margin.left, margin.top],
        [innerWidth, innerHeight],
      ])

    const diagramData = sankeyDiagram(filteredData)

    const nonZeroNodes = diagramData.nodes.filter(d => d.value !== 0)
    const nonZeroLinks = diagramData.links.filter(d => d.value !== 0)

    // TODO: remove if not used
    const originalNodes = originalData.nodes.filter(node =>
      nonZeroNodes.find(d => d.index === node.index)
    )
    // TODO: remove if not used
    const node = svg
      .append('g')
      .selectAll('rect')
      .data(nonZeroNodes)
      .join('rect')
      .attr('x', d => d.x0)
      .attr('y', d => d.y0)
      .attr('height', d => Math.max(0, d.y1 - d.y0))
      .attr('width', d => d.x1 - d.x0)
      .attr('fill', color ? 'var(--primary-color)' : d => d.color)
      .on('mouseenter', function () {
        select(this).attr('opacity', 0.7)
      })
      .on('mouseleave', function () {
        select(this).attr('opacity', 1)
      })

    const labels = svg
      .append('g')
      .selectAll('text')
      .data(nonZeroNodes)
      .join('text')
      .attr('x', d => (d.x0 < dimensions.width / 2 ? d.x1 + 8 : d.x0 - 8))
      .attr('y', d => Math.max(d.y0, Math.min(d.y1, (d.y1 + d.y0) / 2)))
      .attr('dy', '0.35em')
      .attr('text-anchor', d => (d.x0 < dimensions.width / 2 ? 'start' : 'end'))
      .text(d => d.name)
      .style('font-size', '16px')
      .style('fill', 'white')

    labels.each(function (d, i) {
      const box = this.getBBox()
      const labelWidth = box.width

      svg
        .append('text')
        .attr(
          'x',
          d.x0 < dimensions.width / 2
            ? d.x1 + labelWidth + 12
            : d.x0 - labelWidth - 12
        )
        .attr('y', (d.y1 + d.y0) / 2)
        .attr('dy', '0.35em')
        .attr('text-anchor', d.x0 < dimensions.width / 2 ? 'start' : 'end')
        .text(`(${numeral(d.value).format('0,0.000 a')})`)
        .style('font-size', '16px')
        .style('fill', 'white')
    })
    // TODO: remove if not used
    const link = svg
      .append('g')
      .attr('fill', 'none')
      .attr('stroke-opacity', 0.2)
      .selectAll('g')
      .data(nonZeroLinks)
      .join('path')
      .attr('d', sankeyLinkHorizontal())
      .attr(
        'stroke',
        color ? 'var(--primary-color)' : d => (d.color ? d.color : 'white')
      )
      .attr('stroke-width', d => Math.max(1, d.width))
      .on('mouseenter', function (d) {
        select(this)
          .attr('opacity', 0.8)
          .transition()
          .duration(100)
          .attr('stroke-width', d => Math.max(1, d.width + 10))
      })
      .on('mouseleave', function (d) {
        select(this)
          .transition()
          .duration(325)
          .attr('opacity', 1)
          .attr('stroke-width', d => Math.max(1, d.width))
      })
  }

  return (
    <>
      <svg ref={chartRef} style={{ margin: '20px' }} />
    </>
  )
}

export default SankyChart
