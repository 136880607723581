import PriceIcon from '../assets/svgs/PriceIcon.svg'
import NewsIcon from '../assets/svgs/NewsIcon.svg'
import InstitutionalIcon from '../assets/svgs/InstitutionalIcon.svg'
import MarketIcon from '../assets/svgs/MarketIcon.svg'
import sidebarOpen from '../assets/svgs/sidebarOpen.svg'
import OutseekLogo from '../assets/svgs/OutseekLogo.svg'
import sidebarClosed from '../assets/svgs/sidebarClosed.svg'
import signout from '../assets/svgs/signout.svg'
import createdashboard from '../assets/svgs/createdashboard.svg'
import save from '../assets/svgs/save.svg'
import catalog from '../assets/svgs/catalog.svg'
import user from '../assets/svgs/user.svg'
import search from '../assets/svgs/search.svg'
import filter from '../assets/svgs/filter.svg'
import list from '../assets/svgs/list.svg'
import apps from '../assets/svgs/apps.svg'
import etf from '../assets/svgs/etf.svg'
export const media = {
  PriceIcon,
  NewsIcon,
  InstitutionalIcon,
  MarketIcon,
  sidebarOpen,
  OutseekLogo,
  sidebarClosed,
  signout,
  createdashboard,
  save,
  catalog,
  user,
  search,
  filter,
  list,
  apps,
  etf,
}
