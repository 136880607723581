import React, { useState, useEffect } from 'react'
import { Auth } from 'aws-amplify'
import styles from './styles.module.scss'
import Spinner from '../../../../core/ScrollableContainer/Spinner/spinner'
import { Error } from '../../../../core/Error/Error'
import http from '../../../../network/httpClient'

const EarningsSummary = ({ text, label }) => {
  const [prompt, setPrompt] = useState(
    `Provide bullet points of key statistics, specific content details, financial figures, performance metrics, strategic achievements, and future guidance from this earnings call transcript: ${text}. Exclude general information about the call's conduct, the speakers, and do not include an introductory statement.`
  )

  const [generatedText, setGeneratedText] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [error, setError] = useState('')

  useEffect(() => {
    // Disable the button if there is generated text
    setDisabled(!!generatedText)
  }, [generatedText])

  const handleSubmit = async event => {
    event.preventDefault()
    setIsLoading(true)
    setDisabled(true)
    setError('')
    try {
      const response = await http.post('/api/summarize-transcript', { prompt })
      setGeneratedText(response.data.response)
    } catch (error) {
      console.error('Error:', error)
      setError('Failed to summarize. Please try again later.')
    }
    setIsLoading(false)
    setDisabled(false)
  }

  const bulletPoints = generatedText
    ? generatedText.split('\n').map((point, index) => (
        <p key={index} className={styles.bulletPoint}>
          {point}
        </p>
      ))
    : null

  return (
    <div style={{ color: 'white' }} className={styles.summaryWrapper}>
      <form onSubmit={handleSubmit}>
        <button
          className={styles.summarizeButton}
          disabled={isLoading || disabled}
          style={{ cursor: isLoading || disabled ? 'not-allowed' : 'pointer' }}
        >
          Summarize {label}
        </button>
      </form>
      {error && <Error err={error} />}
      {isLoading ? (
        <Spinner />
      ) : (
        generatedText && (
          <div className={styles.bulletPoints}>
            <div>{bulletPoints}</div>
          </div>
        )
      )}
    </div>
  )
}

export default EarningsSummary
