import nathan from './nathan.jpeg'
import paul from './paul.jpeg'

const teamData = [
  {
    id: 'nathan',
    name: 'Nathan Dopko',
    position: 'CEO & Cofounder',
    note: 'UWaterloo Economics student',
    link: 'https://www.linkedin.com/in/nathan-dopko/',
    fact: '',
    image: nathan,
  },
  {
    id: 'paul',
    name: 'Paul Walsh',
    position: 'CTO & Cofounder',
    note: 'UNB software engineering graduate with 8+ years of experience in software development.',
    link: '  https://www.linkedin.com/in/paul-walsh-890a341a4/',
    fact: 'Avid candy crush player and honest guy.',
    image: paul,
  },
]

export default teamData
