export const FetchAPICallType = title => {
  switch (title) {
    case 'Cash Flow Statement':
      return 'cash-flow-statement'
    case 'Balance Sheet':
      return 'balance-sheet-statement'
    case 'Income Statement':
      return 'income-statement'
    case 'CompanyProfile':
      return 'profile'
    case 'KeyMetrics':
      return 'key-metrics'
    case 'StockPeers':
      return 'stock_peers'
    case 'EarningsCalendar':
      return 'earning_calendar'
    case 'RSS SEC Feed':
      return 'rss_feed?page=0&'
    case '8K RSS Feed':
      return 'rss_feed_8k?page=0&'
    case 'ComparisonCharts':
      return
    case 'Insider trading RSS SEC Feed':
      return 'insider-trading?transactionType=P-Purchase,S-Sale&page=0&'
    case 'PriceCharts':
      return
    case 'SocialSentiment':
      return 'social-sentiment/trending?'
    case 'Financial Ratios':
      return 'ratios'
    case 'Financial Growth':
      return 'financial-growth'
    case 'Screener':
      return
    case 'Indexes':
      return
    case 'InstituitionalStockOwnership':
      return 'symbol-ownership'
    case 'CompanyRating':
      return 'rating'
    case 'KeyExecutives':
      return 'key-executives'
    default:
      return
  }
}
