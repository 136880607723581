import React, { useMemo, useState, useEffect } from 'react'
import styles from './styles.module.scss'
import {
  UilTimes,
  UilBars,
  UilEdit,
  UilInfoCircle,
} from '@iconscout/react-unicons'
import { useChats } from 'hooks/copilot/useCopilotChats'
import CoreButton from 'core/Button/CoreButton'
import Spinner from 'core/ScrollableContainer/Spinner/spinner'
import {
  fromUnixTime,
  differenceInHours,
  isToday,
  isYesterday,
  isThisWeek,
} from 'date-fns'
import ChatListSection from './ChatsList'
import { ConfirmModal } from './ConfirmModal'

const Sidebar = ({
  toggleSidebar,
  isSidebarVisible,
  setCurrentChat,
  handleCreateChat,
  setMessages,
  currentChat,
}) => {
  const { readAll, isChatsLoading, deleteChat } = useChats()
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)

  const toggleConfirmModal = () => {
    setIsConfirmModalOpen(!isConfirmModalOpen)
  }

  const categorizeChats = chats => {
    const categories = {
      withinHour: [],
      today: [],
      yesterday: [],
      thisWeek: [],
      earlier: [],
    }
    const now = new Date()

    chats.forEach(chat => {
      const chatDate = fromUnixTime(chat.lastUpdated)
      const hoursDifference = differenceInHours(now, chatDate)

      if (hoursDifference < 1) {
        categories.withinHour.push(chat)
      } else if (isToday(chatDate)) {
        categories.today.push(chat)
      } else if (isYesterday(chatDate)) {
        categories.yesterday.push(chat)
      } else if (isThisWeek(chatDate)) {
        categories.thisWeek.push(chat)
      } else {
        categories.earlier.push(chat)
      }
    })

    return categories
  }

  const Chats = useMemo(() => {
    const items = readAll?.item?.Items
    if (!items) return []

    const sortedItems = items.sort((a, b) => b.lastUpdated - a.lastUpdated)
    return categorizeChats(sortedItems)
  }, [readAll?.item?.Items])

  const deleteChatHandler = chatId => {
    deleteChat({ Chat_id: chatId })
    setCurrentChat(null)
    setMessages([])
  }

  const categoriesConfig = [
    { title: 'Recent', key: 'withinHour' },
    { title: 'Today', key: 'today' },
    { title: 'Yesterday', key: 'yesterday' },
    { title: 'This Week', key: 'thisWeek' },
    { title: 'Earlier', key: 'earlier' },
  ]

  useEffect(() => {
    const isModalClosed = localStorage.getItem('betaModalClosed')
    if (isModalClosed !== 'true') {
      setIsConfirmModalOpen(true)
    }
  }, [])

  const handleModalClose = () => {
    localStorage.setItem('betaModalClosed', 'true')
    setIsConfirmModalOpen(false)
  }

  return (
    <div
      className={`${styles.sidebar} ${
        isSidebarVisible ? styles.open : styles.closed
      }`}
    >
      <div
        className={styles.header}
        style={{
          justifyContent: isSidebarVisible ? 'space-between' : 'center',
        }}
      >
        {isSidebarVisible && (
          <button onClick={toggleConfirmModal} className={styles.toggleButton}>
            <UilInfoCircle
              size="24"
              color="var(--light-grey-accent)"
              style={{ cursor: 'pointer' }}
            />
          </button>
        )}
        {isSidebarVisible && <div className={styles.chatTitle}>My Chats</div>}
        <button onClick={toggleSidebar} className={styles.toggleButton}>
          {isSidebarVisible ? (
            <UilTimes size={24} color="var(--light-grey-accent)" />
          ) : (
            <UilBars size={24} color="var(--light-grey-accent)" />
          )}
        </button>
      </div>
      <CoreButton
        onClick={handleCreateChat}
        width="100%"
        color="var(--primary-color)"
        height={32}
      >
        {isSidebarVisible && 'New Chat'}
        <UilEdit size={16} color="var(--white)" style={{ marginLeft: '2px' }} />
      </CoreButton>
      <div className={styles.displayChats}>
        {isChatsLoading ? (
          <Spinner />
        ) : (
          <>
            {categoriesConfig.map(({ title, key }) => (
              <ChatListSection
                key={key}
                title={title}
                chats={Chats[key]}
                isSidebarVisible={isSidebarVisible}
                currentChat={currentChat}
                setCurrentChat={setCurrentChat}
                deleteChatHandler={deleteChatHandler}
              />
            ))}
          </>
        )}
      </div>
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        handleClose={handleModalClose}
      />
    </div>
  )
}

export default Sidebar
