import React from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  Cell,
  Tooltip,
  ResponsiveContainer,
  LabelList,
} from 'recharts'

const SimpleTwoBarChart = ({ data, height }) => {
  const renderCustomBarLabel = ({ value, x, y, width, height }) => {
    return (
      <text
        x={x + width / 2}
        y={y + height / 2}
        fill="var(--white)"
        textAnchor="middle"
        dominantBaseline="middle"
        fontSize="15"
      >
        {value}
      </text>
    )
  }

  return (
    <ResponsiveContainer width="100%" height={height}>
      <BarChart data={data}>
        <XAxis dataKey="name" />
        <Bar dataKey="value" radius={[4, 4, 0, 0]}>
          {/* <LabelList dataKey="value" content={renderCustomBarLabel} /> */}
          {data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={index === 0 ? 'var(--green)' : 'var(--red)'}
            />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  )
}

export default SimpleTwoBarChart
