import React from 'react'
import {
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
  Bar,
  Cell,
} from 'recharts'
import dayjs from 'dayjs'
import { earningsTip } from './utils'
import numeral from 'numeral'

const SimpleScatterChart = ({
  data,
  xAxisKey,
  setSelectedDate,
  metric1,
  metric2,
}) => {
  const filterData = (data, key) => data?.filter(item => item[key] != null)

  const filteredDataForEPS = filterData(data, metric1)
  const filteredDataForEstimatedEPS = filterData(data, metric2)

  const getColor = (eps, epsEstimated) => {
    if (eps > epsEstimated) return 'var(--green)'
    if (eps < epsEstimated) return 'var(--red)'
    if (eps === epsEstimated) return 'var(--primary-color)'
  }

  const renderCustomShape = props => {
    const { cx, cy, payload } = props
    const color = getColor(payload?.[metric1], payload?.[metric2])

    return (
      <circle
        cx={cx}
        cy={cy}
        r={6}
        fill={color}
        style={{ cursor: 'pointer' }}
      />
    )
  }

  const renderCustomShapeEstimate = props => {
    const { cx, cy } = props

    return (
      <circle
        cx={cx}
        cy={cy}
        r={5}
        fill="transparent"
        stroke="var(--white)"
        strokeWidth={1.5}
        style={{ cursor: 'pointer' }}
      />
    )
  }

  return (
    <ResponsiveContainer width="100%" height={'100%'}>
      <ComposedChart width={'100%'} height={'100%'} data={data}>
        <CartesianGrid
          strokeDasharray="0"
          vertical={false}
          stroke="var(--button-hover)"
        />
        <Tooltip
          content={content => earningsTip(content, metric1, metric2)}
          cursor={{
            stroke: 'var(--grey-accent)',
            strokeWidth: 1,
            strokeDasharray: 4,
          }}
        />
        <XAxis
          dataKey={xAxisKey}
          style={{ fontSize: 12 }}
          tickFormatter={tick => dayjs(tick).format('MMM, YY' + "'")}
          allowDuplicatedCategory={false}
          height={18}
        />
        <YAxis
          style={{ fontSize: 12 }}
          width={40}
          orientation="right"
          tickFormatter={tick => {
            if (tick > 1000) {
              return numeral(tick).format('0a')
            } else {
              return numeral(tick).format('0.00')
            }
          }}
        />
        <YAxis
          yAxisId="left"
          orientation="left"
          style={{ fontSize: 12 }}
          width={35}
          tickFormatter={tick => {
            return `${tick}%`
          }}
        />
        <Bar
          yAxisId="left"
          dataKey={'changePercent'}
          barSize={18}
          fill={'red'}
          radius={[4, 4, 0, 0]}
        >
          {' '}
          {data?.map((entry, index) => {
            const color =
              entry?.changePercent > 0 ? 'var(--green)' : 'var(--red)'

            return <Cell key={`cell-${index}`} fill={color} opacity={0.4} />
          })}
        </Bar>
        <Scatter
          name={metric1}
          data={filteredDataForEPS}
          shape={renderCustomShape}
          dataKey={metric1}
          onClick={event => {
            setSelectedDate(event?.payload?.date)
          }}
        />
        <Scatter
          name={metric2}
          data={filteredDataForEstimatedEPS}
          shape={renderCustomShapeEstimate}
          dataKey={metric2}
          onClick={event => {
            setSelectedDate(event?.payload?.date)
          }}
        />
      </ComposedChart>
    </ResponsiveContainer>
  )
}

export default SimpleScatterChart
