export const Tooltip = ({ content, position, dimensions }) => {
  const adjustedX =
    position.x > dimensions.width - 100 ? position.x - 100 : position.x
  const adjustedY =
    position.y > dimensions.height - 50 ? position.y - 50 : position.y

  return (
    <div
      style={{
        position: 'absolute',
        left: `${adjustedX}px`,
        top: `${adjustedY}px`,
        background: 'rgba(33, 33, 33, 0.9)',
        border: '1px solid rgba(150, 150, 150)',
        color: 'white',
        padding: '4px',
        zIndex: 1000,
        fontSize: 12,
        borderRadius: '4px',
        pointerEvents: 'none',
        display: content ? 'block' : 'none',
      }}
    >
      {content}
    </div>
  )
}

const dataFormatEXample = {
  name: 'root',
  children: [
    {
      label: 'A',
      value: 10,
    },
    {
      label: 'B',
      value: 200,
    },
    {
      label: 'C',
      value: 300,
    },
    {
      label: 'D',
      value: 60,
    },
  ],
}

export const splitText = (text, maxLength) => {
  if (text?.length <= maxLength) return [text]
  let splitIndex = text?.lastIndexOf(' ', maxLength)
  if (splitIndex === -1) splitIndex = text?.indexOf(' ', maxLength)
  if (splitIndex === -1) return [text]
  const firstPart = text?.substring(0, splitIndex)
  const secondPart = text?.substring(splitIndex + 1)

  return [firstPart, secondPart]
}

export const calculateTextWidth = text => {
  const characterWidth = 7
  return text?.length * characterWidth
}
export const calculateValueTextWidth = text => {
  const characterWidth = 10
  return text?.length * characterWidth
}

export const calculateTextHeight = fontSize => {
  return fontSize * 1.4
}
