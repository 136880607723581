import './style.scss'
import React from 'react'
import PropTypes from 'prop-types'
import times from './assets/times.svg'
import { connect, useSelector } from 'react-redux'
import TickerSearchDashboard from './components/DashboardTickerSearch'
import {
  setType,
  removeComponent,
  changeTicker,
} from 'redux/actions/app-actions'
import {
  marketCompanyMapper,
  componentBlockMapper,
} from 'constants/componentMappers'

const isMarket = type => {
  return marketCompanyMapper.Company.includes(type) ? false : true
}

const isWidget = type => {
  return componentBlockMapper.widget.includes(type) ? true : false
}
function Title(props) {
  const { title, type, root, width } = props

  const handleDelete = () => {
    const { root } = props
    props.removeComponent(root)
  }

  const updateTicker = tickerIN => {
    props.changeTicker(tickerIN, root)
    props.handleSetTicker(tickerIN)
  }

  const ticker = useSelector(state => state.data[root].spec.ticker)

  return (
    <div className="titleContainer">
      <div className="dragHandle" style={{ width: width, height: 30 }}>
        {
          <p className="titleText" title={title} isDraggable={false}>
            {title}
          </p>
        }
        <div className="titleButtons">
          {!isMarket(type) && (
            <div className="tickerInputWrapper">
              <TickerSearchDashboard
                updateTicker={updateTicker}
                ticker={ticker}
                isWidget={false}
              />
            </div>
          )}

          {isWidget(type) && (
            <div className="tickerInputWrapper">
              <TickerSearchDashboard
                updateTicker={updateTicker}
                ticker={ticker}
                isWidget={true}
              />
            </div>
          )}

          <div
            title="Close item"
            className="closeButton"
            onClick={() => handleDelete()}
          >
            <img alt="icon" className="closeItemIcon" src={times}></img>
          </div>
        </div>
      </div>
    </div>
  )
}

Title.propTypes = {
  root: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
}

export default connect(null, { removeComponent, changeTicker })(Title)
