// ALL SETTINGS AND SWAPPED OUT AND REVERSED BECAUSE THE FMP API IS REVERSED. FMP NEEDS TO FIX ASAP

export const getBasicTableProps = config => {
  const { type, ETF_UI, hoverHandlers, hoveredItems, columns } = config
  return {
    columns:
      // type === 'holdings' ? columns.holdingsColumns : columns.holdersColumns,
      type === 'holdings' ? columns.holdersColumns : columns.holdingsColumns,
    overflowXScroll: true,
    // data: type === 'holdings' ? ETF_UI?.ETFHoldings : ETF_UI?.ETFHolders,
    data: type === 'holdings' ? ETF_UI?.ETFHolders : ETF_UI?.ETFHoldings,
    onMouseOverRow: rowData =>
      // type === 'holdings'
      //   ? hoverHandlers.handleHoldingHover(rowData.name)
      //   : hoverHandlers.handleHolderHover(rowData.asset),
      type === 'holdings'
        ? hoverHandlers.handleHolderHover(rowData.asset)
        : hoverHandlers.handleHoldingHover(rowData.name),
    onMouseLeaveRow:
      // type === 'holdings'
      //   ? hoverHandlers.handleLeaveHolding
      //   : hoverHandlers.handleLeaveHolder,
      type === 'holdings'
        ? hoverHandlers.handleLeaveHolder
        : hoverHandlers.handleLeaveHolding,
    hoveredSymbol:
      // type === 'holdings'
      //   ? hoveredItems.hoveredHolding
      //   : hoveredItems.hoveredHolder,
      type === 'holdings'
        ? hoveredItems.hoveredHolder
        : hoveredItems.hoveredHolding,
    hoverKey: type === 'holdings' ? 'name' : 'asset',
    // hoverKey: type === 'holdings' ? 'asset' : 'name',
    haveKey: true,
  }
}

export const getChartProps = config => {
  const { type, chartType, ETF_UI, hoverHandlers, hoveredItems } = config
  const isHolding = type === 'holdings'
  const isTreeMap = chartType === 'treeMap'
  const isDonut = chartType === 'donut'

  return {
    data: isHolding
      ? isDonut
        ? ETF_UI?.pieDataHolders
        : ETF_UI?.TreeMapHolders
      : isDonut
      ? ETF_UI?.pieDataHoldings
      : ETF_UI?.TreeMapHoldings,
    percentage: true,
    startColor: '#4079a8',
    endColor: '#a37236',
    otherKey: isHolding ? 'Other Holdings' : 'Other Holders',
    // setHoveredSymbol: isHolding
    //   ? hoverHandlers.handleHoldingHover
    //   : hoverHandlers.handleHolderHover,
    setHoveredSymbol: isHolding
      ? hoverHandlers.handleHolderHover
      : hoverHandlers.handleHoldingHover,
    // hoveredSymbol: isHolding
    //   ? hoveredItems.hoveredHolding
    //   : hoveredItems.hoveredHolder,
    hoveredSymbol: isHolding
      ? hoveredItems.hoveredHolder
      : hoveredItems.hoveredHolding,
    ...(isTreeMap
      ? {}
      : {
          colorStart: '#a83232',
          colorEnd: '#6d32a8',
        }),
  }
}
