export const RATIOS_HISTORICAL = 'ratios_bulk_historical'
export const CASH_FLOW_HISTORICAL = 'cash_flow_statement_bulk_historical'
export const BALANCE_SHEET_HISTORICAL =
  'balance_sheet_statement_bulk_historical'
export const INCOME_STATEMENT_HISTORICAL = 'income_statement_bulk_historical'
export const AGGREGATES = {
  MIN: 'MIN',
  MAX: 'MAX',
  AVG: 'AVG',
  STD_DEV: 'stddev',
}

export const SUMMARY = 'Summary'
export const GROUP_BY_COLUMNS = {
  SECTOR: 'sector',
  INDUSTRY: 'industry',
  COUNTRY: 'country',
  EXCHANGE: 'exchange',
  CURRENCY: 'currency',
}

export default {
  RATIOS_HISTORICAL,
  BALANCE_SHEET_HISTORICAL,
  INCOME_STATEMENT_HISTORICAL,
  CASH_FLOW_HISTORICAL,
}
