import { Auth } from 'aws-amplify'

import { fetchNames } from 'redux/actions/app-actions'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import http from 'network/httpClient'

// hook to make sure the user is created in our backend users table and if not create they/them/zee/zer
export const useSetupUser = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    http
      .get('papi/users/', {
        data: {
          userId: Auth.userAttributes.username,
        },
      })
      .then(function (response) {
        if (!response?.data?.item) {
          http
            .post('papi/users/')
            .then(response => {
              dispatch(fetchNames())
            })
            .catch(err => console.error(err))
        }
      })
      .catch(function (error) {
        console.error(error)
      })
      .catch(function (error) {
        console.error(error)
      })
  }, [dispatch])
}

export default useSetupUser
