import TradingViewWidget, { Themes } from 'react-tradingview-widget'

import { useURLSyncedGlobalTicker } from '../hooks'

export const PriceChart = () => {
  const { ticker } = useURLSyncedGlobalTicker()

  return (
    <div style={{ height: 'var(--view-height)' }}>
      <TradingViewWidget
        symbol={ticker}
        theme={Themes.DARK}
        locale="en"
        autosize
        withdateranges={true}
        hide_side_toolbar={false}
      />
    </div>
  )
}
