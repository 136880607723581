import { Modal } from '@mui/material'
import React, { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { UilTimes } from '@iconscout/react-unicons'
import { useNavigate } from 'react-router'
import { WavyButton } from 'core/WavyButton/WavyButton'

export const LimitsModal = ({ limitReachedError, setLimitReachedError }) => {
  const navigate = useNavigate()
  const [modalIsOpen, setModalIsOpen] = useState(limitReachedError)

  useEffect(() => {
    setModalIsOpen(limitReachedError)
  }, [limitReachedError])

  const handleClose = () => {
    setModalIsOpen(false)
    setLimitReachedError(false)
  }

  const handleUpgrade = () => {
    navigate('/terminal/upgrade')
  }

  return (
    <Modal open={modalIsOpen} onClose={handleClose} disableAutoFocus={true}>
      <div className={styles.modalOverlay}>
        <div className={styles.modalHeader}>
          <div>Message Limit Reached</div>
          <div onClick={handleClose} className={styles.closeButton}>
            <UilTimes size={24} color="var(--light-grey-accent)" />
          </div>
        </div>
        <div>
          You have reached the limit of messages you can send per day. Please
          upgrade your plan to send more messages.
        </div>
        <div className={styles.modalFooter}>
          <WavyButton handleClick={handleUpgrade}>Upgrade</WavyButton>
        </div>
      </div>
    </Modal>
  )
}
