import http from 'network/httpClient'

export const fetchCompanyData = async ({ queryKey }) => {
  const [, ticker] = queryKey
  let data = await http.get(`papi/data/api/v4/company-outlook?symbol=${ticker}`)
  return data.data.message
}

export const fetchETFInfo = async ({ queryKey }) => {
  const [, ticker] = queryKey
  let data = await http.get(`papi/data/api/v4/etf-info?symbol=${ticker}`)
  return data.data.message
}

export const fetchETFHoldings = async ({ queryKey }) => {
  const [, ticker] = queryKey
  const date = '2023-09-30'
  let data = await http.get(
    `papi/data/api/v4/etf-holdings?date=${date}&symbol=${ticker}`
  )
  return data.data.message
}

export const fetchETFHolders = async ({ queryKey }) => {
  const [, ticker] = queryKey
  let data = await http.get(`papi/data/api/v3/etf-holder/${ticker}`)
  return data.data.message
}

const queries = {
  fetchCompanyData,
  fetchETFInfo,
  fetchETFHoldings,
  fetchETFHolders,
}

export default queries
