import http from 'network/httpClient'
import { useMemo } from 'react'
import { useQuery, useQueries } from 'react-query'
import { areQueriesDone } from 'utils/areQueriesDone'
import { formatSummary } from './utils'
const SQL_PROXY_URL = '/papi/execute/'

async function executeQueryFn({ query }) {
  let data = await http.post(`${SQL_PROXY_URL}`, {
    query,
  })
  return data.data
}

export const useSQL = ({ query, queries, enabled }) => {
  const { data, isLoading, isError } = useQuery({
    queryKey: [query],
    queryFn: async () =>
      executeQueryFn({
        query,
      }),
    enabled: enabled,
  })

  return {
    data: data?.items,
    isLoading,
    isError,
  }
}

export const useSummary = ({
  table,
  queries,
  enabled,
  groupByColumns = [],
}) => {
  const results = useQueries(
    Object.keys(queries).map(q => {
      return {
        queryKey: [queries[q]],
        queryFn: async () =>
          executeQueryFn({
            query: queries[q],
          }),
        enabled: enabled,
      }
    })
  )

  const processSummaryData = useMemo(() => {
    if (areQueriesDone(results)) {
      return formatSummary(results, groupByColumns)
    }
    return null
  }, [results])

  return processSummaryData
}

export default { useSummary, useSQL }
