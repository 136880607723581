const InstitutionalGradeData_Title = <span>Institutional-Grade Data</span>
const WealthOfFinancialData_Subtitle = (
  <span>
    Access a wealth of institutional-grade financial data and information from
    over 35+ years, all from the comfort of your browser.
  </span>
)

const FromMacro_Title = <span>From Macro</span>
const ExtensiveMarketCoverage_Subtitle = (
  <span>
    See the big picture with our extensive coverage of entire financial markets.
    Everything you need in one place.
  </span>
)

const ToMicro_Title = <span>To Micro</span>
const DetailedStockView_Subtitle = (
  <span>
    The little things matter, that's why Outseek helps you view every little
    detail of over 100,000 stocks.
  </span>
)

const NewsAndSocialSentiment_Title = <span>News & Social Sentiment</span>
const MarketSentiment_Subtitle = (
  <span>
    Stay up-to-date with the latest news and social sentiment. Gain insights
    into the most bullish, bearish, and trending stocks. Our coverage allows you
    to track Twitter and StockTwits likes, comments, and impressions by the
    hour, giving you a more detailed picture of market sentiment.
  </span>
)

const CompanyOverview_Title = <span>Company Overview</span>
const InDepthCompanyInfo_Subtitle = (
  <span>
    Get an in-depth overview of any company. View the historical stock prices,
    key metrics, financials, analyst ratings, recent news, and more—all in one
    place. Saving you time and helping you make informed investment decisions.
  </span>
)

const PowerfulTools_Title = <span>Powerful Tools</span>
const PowerfulTools_Subtitle = (
  <span>
    Outseek is equipped with a variety of powerful tools to help you find,
    analyze, and track securities faster than ever before.
  </span>
)

const Screener_Title = <span>Stock Screener</span>
const StockScreener_Subtitle = (
  <span>
    Our powerful stock screener is a game-changer for your research. With all
    the fundamental and technical metrics you need, our stock screener allows
    you to truly take control of your research and find the stocks that meet
    your specific investment criteria.
  </span>
)

const InsightfulVisualizations_Title = <span>Insightful Visualizations</span>
const InsightfulVisualizations_Subtitle = (
  <span>
    Unlock a new level of understanding of financial data. Identify trends and
    see a bigger picture with our innovative visualizations.
  </span>
)

const InteractiveCharts_Title = <span>Next Level Interactive Charts</span>
const InteractiveCharts_Subtitle = <span>More information in less space.</span>

const EnterTerminal_Button = <span>Enter Terminal</span>

const Powerful_Title = <span>Powerful.</span>
const AccessToPremiumData_Subtitle = (
  <span>
    With access to over 35+ years of premium financial data for over 100,000
    stocks, Outseek is a game-changer.
  </span>
)

const Accurate_Title = <span>Accurate.</span>
const ReliableDataSources_Subtitle = (
  <span>
    We utilize reliable and powerful data sources to deliver the most accurate
    financial data and insights.
  </span>
)

const Innovative_Title = <span>Innovative.</span>
const InnovativeInsights_Subtitle = (
  <span>
    No more generic analysis. Outseek provides innovative insights and tools
    that you won't find anywhere else.
  </span>
)

const Earnings_Title = <span>Earnings & Transcripts</span>
const Earnings_Subtitle = (
  <span>
    Get over 20 years of earnings data and transcripts. Don't like to read? No
    problem, Outseek allows you to listen to the earnings transcripts as well as
    quickly summarize the key points in seconds.
  </span>
)

const Institutional_Title = <span>Institutional Ownership</span>
const Institutional_Subtitle = (
  <span>
    View a breakdown of the institutional ownership of a company along with
    historical metrics such as number of holders, ownership, and net new
    positions.
  </span>
)

const GoodData_Title = <span>Innovative Data</span>
const GoodData_Subtitle = (
  <span>
    Explore innovative data that helps you get ahead and stay ahead of the
    curve.
  </span>
)

const conclusiveInformation_Title = <span>Conclusive Insights</span>
const conclusiveInformation_Subtitle = (
  <span>
    Get conclusive insights to help you see the bigger picture quicker, and make
    more informed decisions.
  </span>
)

const NotOptimizedForMobile_Warning =
  'Outseek Terminal is not optimized for mobile or tablet devices. Please use a desktop or laptop computer to access the terminal. We are working on a mobile and tablet version. Thank you for your patience.'

export const texts = {
  InstitutionalGradeData_Title,
  WealthOfFinancialData_Subtitle,
  FromMacro_Title,
  ExtensiveMarketCoverage_Subtitle,
  ToMicro_Title,
  DetailedStockView_Subtitle,
  NewsAndSocialSentiment_Title,
  MarketSentiment_Subtitle,
  CompanyOverview_Title,
  InDepthCompanyInfo_Subtitle,
  Screener_Title,
  StockScreener_Subtitle,
  InsightfulVisualizations_Title,
  InsightfulVisualizations_Subtitle,
  InteractiveCharts_Title,
  InteractiveCharts_Subtitle,
  EnterTerminal_Button,
  Powerful_Title,
  AccessToPremiumData_Subtitle,
  Accurate_Title,
  ReliableDataSources_Subtitle,
  Innovative_Title,
  InnovativeInsights_Subtitle,
  NotOptimizedForMobile_Warning,
  PowerfulTools_Subtitle,
  PowerfulTools_Title,
  Earnings_Title,
  Earnings_Subtitle,
  Institutional_Title,
  Institutional_Subtitle,
  GoodData_Title,
  GoodData_Subtitle,
  conclusiveInformation_Title,
  conclusiveInformation_Subtitle,
}
