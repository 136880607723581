import React, { useState, useEffect, useRef } from 'react'
import MessageInput from './MessageInput/MessageInput'
import Message from './Message/Message'
import Sidebar from './Sidebar/Sidebar'
import styles from './styles.module.scss'
import { useChats } from 'hooks/copilot/useCopilotChats'
import { ExamplePrompts } from './ExamplePrompts/ExamplePrompts'
import useMessages from 'hooks/copilot/useMessages'
import { LimitsModal } from './LimitsModal/LimitsModal'
import { summarizeChat, cleanName } from './utils'
import { Helmet } from 'react-helmet-async'
import dayjs from 'dayjs'
import { TempTicker } from './TempTicker/TempTicker'

const CoPilot = () => {
  const { createChat, updateChat } = useChats()
  const { increaseMessageCount, refetchCheckMessageLimit } = useMessages()

  const [messages, setMessages] = useState([])
  const [currentChat, setCurrentChat] = useState(null)
  const [isSidebarVisible, setIsSidebarVisible] = useState(true)
  const [isBotTyping, setIsBotTyping] = useState(false)
  const [limitReachedError, setLimitReachedError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const aggregatedTextRef = useRef('')
  const messagesEndRef = useRef(null)
  const shouldScrollToBottom = useRef(false)
  const [responseStartTime, setResponseStartTime] = useState(null)
  const [firstChunkTime, setFirstChunkTime] = useState(null)
  const [userTicker, setUserTicker] = useState(null)

  useEffect(() => {
    if (currentChat) {
      setMessages(currentChat.messages)
    }
  }, [currentChat])

  useEffect(() => {
    if (shouldScrollToBottom.current) {
      scrollToBottom()
      shouldScrollToBottom.current = false
    }
  }, [messages])

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  const handleCreateChat = async () => {
    try {
      createChat(
        {
          body: {
            name: 'New Chat',
            messages: [],
            summarized: false,
            timeCreated: new Date().getTime(),
            lastUpdated: new Date().getTime(),
          },
        },
        {
          onSuccess: data => {
            setCurrentChat(data?.chat)
          },
        }
      )
    } catch (error) {
      console.error('Error creating chat:', error)
    }
  }

  const handleIncreaseChatCount = async () => {
    try {
      increaseMessageCount()
    } catch (error) {
      console.error('Error increasing chat count:', error)
    }
  }

  const checkMessageLimit = async () => {
    try {
      const { data } = await refetchCheckMessageLimit()
      return data.allowed
    } catch (error) {
      console.error('Error checking message limit:', error)
      return false
    }
  }

  const handleSendMessage = async (text, tickerValue) => {
    const isAllowed = await checkMessageLimit()
    if (!isAllowed) {
      setLimitReachedError(true)
      return
    }

    setResponseStartTime(dayjs())

    const newMessage = { text, from: 'user', timestamp: new Date().getTime() }
    const botResponse = {
      text: '',
      from: 'bot',
      timestamp: new Date().getTime(),
    }

    setMessages(msgs => [...msgs, newMessage, botResponse])
    setIsBotTyping(true)
    setIsLoading(true)

    shouldScrollToBottom.current = true

    const handleBotResponse = async chat => {
      aggregatedTextRef.current = ''

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_ORIGIN}api/run-replicate-model`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ prompt: text, userTicker: tickerValue }),
          }
        )

        const reader = response.body.getReader()
        const decoder = new TextDecoder()

        let aggregatedText = ''
        let isFirstChunk = true
        while (true) {
          const { done, value } = await reader.read()
          if (done) break
          const chunk = decoder.decode(value, { stream: true })
          aggregatedText += chunk

          if (isFirstChunk) {
            setIsLoading(false)
            setFirstChunkTime(dayjs())
            isFirstChunk = false
          }

          setMessages(msgs => {
            return msgs.map(msg => {
              if (
                msg.from === 'bot' &&
                msg.timestamp === botResponse.timestamp
              ) {
                return { ...msg, text: aggregatedText }
              }
              return msg
            })
          })
        }

        setMessages(msgs => {
          return msgs.map(msg => {
            if (msg.from === 'bot' && msg.timestamp === botResponse.timestamp) {
              return { ...msg, text: aggregatedText }
            }
            return msg
          })
        })

        const updatedMessages = [
          ...messages,
          newMessage,
          { ...botResponse, text: aggregatedText },
        ]

        let chatSummary
        if (chat && !chat.summarized) {
          chatSummary = await summarizeChat(aggregatedText)
        }

        updateChat({
          Chat_id: chat.chatId,
          body: {
            ...chat,
            name: chat.summarized === true ? chat.name : chatSummary,
            messages: updatedMessages,
            summarized: true,
            lastUpdated: new Date().getTime(),
          },
        })

        setIsBotTyping(false)
        handleIncreaseChatCount()
      } catch (error) {
        console.error('Error:', error)
        const errorMessage = {
          text: 'Error running the model.',
          from: 'bot',
          timestamp: new Date().getTime(),
        }
        setMessages(msgs => [...msgs, errorMessage])
        setIsBotTyping(false)
        setIsLoading(false)
      }
    }

    if (!currentChat) {
      createChat(
        {
          body: {
            name: 'New Chat',
            messages: [newMessage, botResponse],
            summarized: false,
            timeCreated: new Date().getTime(),
          },
        },
        {
          onSuccess: data => {
            setCurrentChat(data?.chat)
            handleBotResponse(data?.chat)
          },
        }
      )
    } else {
      handleBotResponse(currentChat)
    }
  }

  const handleExamplePromptClick = prompt => {
    handleSendMessage(prompt)
  }

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible)
  }

  const [pageTitle, setPageTitle] = useState('CoPilot')

  useEffect(() => {
    const cleanedName = cleanName(currentChat?.name)
    setPageTitle(currentChat ? cleanedName : 'CoPilot')
  }, [currentChat])

  useEffect(() => {
    if (responseStartTime && firstChunkTime) {
      const duration = firstChunkTime.diff(responseStartTime, 'second', true)
      console.log(`bot response time: ${duration.toFixed(1)}s`)
      setResponseStartTime(null)
      setFirstChunkTime(null)
    }
  }, [firstChunkTime])

  return (
    <div className={styles.copilotWrapper}>
      <Helmet>
        <title>{`Outseek | ${pageTitle}`}</title>
        <meta
          name="description"
          content={
            'Outseek is a financial research and analysis terminal for the next generation of investors, built by the next generation of investors.'
          }
        />
      </Helmet>
      <Sidebar
        toggleSidebar={toggleSidebar}
        isSidebarVisible={isSidebarVisible}
        setCurrentChat={setCurrentChat}
        setMessages={setMessages}
        handleCreateChat={handleCreateChat}
        currentChat={currentChat}
      />
      <div
        className={styles.chatWindow}
        style={{
          width: isSidebarVisible ? 'calc(100% - 248px)' : 'calc(100% - 68px)',
        }}
      >
        <TempTicker ticker={userTicker} setTicker={setUserTicker} />
        {messages.length === 0 ? (
          <ExamplePrompts
            handleExamplePromptClick={handleExamplePromptClick}
            userTicker={userTicker}
          />
        ) : (
          <div className={styles.messages}>
            {messages.map((msg, index) => (
              <Message
                key={index}
                text={msg.text}
                from={msg.from}
                timestamp={msg.timestamp}
                messages={messages}
                isLoading={isLoading}
                isLastMessage={index === messages.length - 1}
                isBotTyping={isBotTyping}
              />
            ))}
            <div ref={messagesEndRef} />
          </div>
        )}
        <MessageInput
          onSendMessage={text => handleSendMessage(text, userTicker)}
          isBotTyping={isBotTyping}
          userTicker={userTicker}
        />
      </div>
      <LimitsModal
        limitReachedError={limitReachedError}
        setLimitReachedError={setLimitReachedError}
      />
    </div>
  )
}

export default CoPilot
