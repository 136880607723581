import styles from './AddressLink.module.scss'

export const AddressLink = ({ data, name }) => {
  const query = encodeURIComponent(
    `${data?.address}, ${data?.city}, ${data?.state} ${data?.zip}, ${data?.country}`
  )

  const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${query}`

  return (
    <div className={styles.mapsButton}>
      <a
        href={googleMapsUrl}
        target="_blank"
        rel="noopener noreferrer"
        className={styles.mapsLink}
      >
        View {name}'s Headquarters on Google Maps
      </a>
    </div>
  )
}
