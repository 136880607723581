import { atom } from 'recoil'
import uuid from 'react-uuid'
export const addView = atom({
  key: uuid(),
  default: false,
})

export const currentView = atom({
  key: uuid(),
  default: null,
})

export const activeViews = atom({
  key: uuid(),
  default: [],
})



export const viewState = atom({
  key: uuid(),
  default: {}
})