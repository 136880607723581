const handleStyles = {
  width: '12px',
  height: '12px',
  borderRadius: '50%',
  background: 'var(--primary-color)',
  cursor: 'grab',
}

const trackStyles = {
  position: 'relative',
  bottom: '-10px',
  height: '2px',
  background: 'var(--light-grey-accent)',
  borderRadius: '2px',
}

const labelStyles = {
  position: 'absolute',
  transform: 'translateX(-50%)',
  bottom: '-23px',
  fontSize: '13px',
  color: 'var(--white)',
}

const selectedRangeStyle = {
  background: 'var(--primary-color)',
  height: '2px',
  position: 'absolute',
  borderRadius: '2px',
}

const segmentStyles = {
  position: 'absolute',
  height: '2px',
  borderRadius: '2px',
}

const handleLabelStyles = {
  position: 'absolute',
  top: '-18px',
  fontSize: 'var(--default-text)',
  transform: 'translateX(-30%)',
}

const markStyle = {
  position: 'absolute',
  width: '3px',
  height: '2px',
  backgroundColor: 'var(--background-primary)',
}

const rangeHighlightStyle = {
  position: 'absolute',
  height: '2px',
  background: 'var(--primary-color)',
  borderRadius: '2px',
}

export const sliderStyles = {
  handleStyles,
  trackStyles,
  labelStyles,
  selectedRangeStyle,
  segmentStyles,
  handleLabelStyles,
  markStyle,
  rangeHighlightStyle,
}
