import { TabletView } from './HomePageQueries/TabletView/HomePageTablet'
import { DesktopLargeView } from './HomePageQueries/DesktopLargeView/HomePageDesktopLarge'
import { useMediaQuery } from 'react-responsive'
import { Helmet } from 'react-helmet-async'

export default function App() {
  const DesktopLargeQuery = ({ children }) => {
    const isDesktopLarge = useMediaQuery({ minWidth: 1080 })
    return isDesktopLarge ? children : null
  }

  const TabletQuery = ({ children }) => {
    const isTablet = useMediaQuery({ maxWidth: 1079 })
    return isTablet ? children : null
  }

  return (
    <>
      <Helmet>
        <title>{`Outseek | Financial Terminal`}</title>
      </Helmet>
      <DesktopLargeQuery>
        <DesktopLargeView />
      </DesktopLargeQuery>
      <TabletQuery>
        <TabletView />
      </TabletQuery>
    </>
  )
}
