import styles from '../Watchlist.module.scss'
import BasicTable from 'core/Tables/BasicTable'
import CoreButton from 'core/Button/CoreButton'
import AverageReturns from './ReturnBlocks/ReturnBlocks'

export const WatchlistReturns = ({
  returnsData,
  returnsColumns,
  updateState,
  showStats,
}) => {
  const toggleStats = () => {
    updateState({ showStats: !showStats })
  }

  return (
    <div className={styles.wrapperContainer}>
      <BasicTable
        data={returnsData}
        columns={returnsColumns}
        overflowXScroll={true}
      />
      <CoreButton onClick={toggleStats} width={'100%'}>
        {showStats ? 'Hide Average Returns' : 'Show Average Returns'}
      </CoreButton>
      {showStats && (
        <AverageReturns data={returnsData} columns={returnsColumns} />
      )}
    </div>
  )
}
