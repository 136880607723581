export const buildChartData = data => {
  let temp = data.map((quater, i) => {
    return {
      quarter: 'Q' + i,
      ...quater,
    }
  })

  temp = temp.reverse()
  return temp
}
