import styles from './style.module.scss'
import {
  companyInfo,
  offeringInfo,
  financials,
  intermediaryInfo,
} from './constants'
import { TableKeyValue } from 'pages/views/InstitutionalOwnership/components/TableKeyValue/TableKeyValue'
import numeral from 'numeral'
export const SelectedFunding = ({ data }) => {
  if (!data) return null

  const cleanKey = key => {
    if (key === 0 || key === null || key === undefined || key === '') return '-'
    else return numeral(key).format('$0,0.0a')
  }

  return (
    <div className={styles.selectedFundDetails}>
      <div className={styles.sectionHeader}>COMPANY DETAILS</div>
      <TableKeyValue data={[data]} labels={companyInfo} chunkEnabled={false} />
      <div className={styles.sectionHeader}>INTERMEDIARY DETAILS</div>
      {intermediaryInfo?.map((item, index) => (
        <div key={index} className={styles.tableKeyValueRow}>
          <div className={styles.tableHeader}>{item.label}</div>
          <div className={styles.value}>{data?.[item?.key]}</div>
        </div>
      ))}
      <div className={styles.sectionHeader}>OFFERING DETAILS</div>
      <TableKeyValue data={[data]} labels={offeringInfo} chunkEnabled={false} />
      <div className={styles.sectionHeader}>FINANCIALS</div>
      <table>
        <thead>
          <tr className={styles.tableHeader}>
            <th>Type</th>
            <th>Current Year</th>
            <th>Prior Year</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(financials).map(([sectionKey, sectionValues]) => (
            <tr key={sectionKey} className={styles.row}>
              <td className={styles.keyValue}>{sectionKey}</td>
              {Object.entries(sectionValues).map(([label, value]) => (
                <td key={label} className={styles.keyValue}>
                  {cleanKey(data?.[value])}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
