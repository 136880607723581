
import http from 'network/httpClient'

const fetchMAData = async ({ queryKey }) => {
  const pagesToFetch = [1, 2]
  let allData = []

  for (const page of pagesToFetch) {
    const response = await http.get(
      `papi/data/api/v4/mergers-acquisitions-rss-feed?page=${page}`
    )
    allData?.push(...response.data.message)
  }

  return allData
}

const fetchCrowdData = async ({ queryKey }) => {
  const pagesToFetch = [1, 2]
  let allData = []

  for (const page of pagesToFetch) {
    const response = await http.get(
      `papi/data/api/v4/crowdfunding-offerings-rss-feed?page=${page}`
    )
    allData?.push(...response.data.message)
  }

  return allData
}

const fetchFundingData = async ({ queryKey }) => {
  const pagesToFetch = [1, 2, 3]
  let allData = []

  for (const page of pagesToFetch) {
    const response = await http.get(
      `papi/data/api/v4/fundraising-rss-feed?page=${page}`
    )
    allData?.push(...response.data.message)
  }

  return allData
}

export const fetches = {
  fetchMAData,
  fetchCrowdData,
  fetchFundingData,
}
