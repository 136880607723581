const financial = [
  10000000, 50000000, 100000000, 500000000, 1000000000, 5000000000, 10000000000,
  50000000000, 100000000000, 500000000000,
]

const marketCap = [
  300000000,
  { min: 300000000, max: 500000000 },
  { min: 500000000, max: 1000000000 },
  { min: 1000000000, max: 2000000000 },
  { min: 2000000000, max: 5000000000 },
  { min: 5000000000, max: 10000000000 },
  { min: 10000000000, max: 20000000000 },
  { min: 20000000000, max: 50000000000 },
  { min: 50000000000, max: 100000000000 },
  { min: 100000000000, max: 200000000000 },
  { min: 200000000000, max: 500000000000 },
  { min: 500000000000, max: 1000000000000 },
  { min: 1000000000000, max: 2000000000000 },
  2000000000000,
]

const volumeAverage = [
  1000,
  { min: 1000, max: 5000 },
  { min: 5000, max: 10000 },
  { min: 10000, max: 50000 },
  { min: 50000, max: 100000 },
  { min: 100000, max: 500000 },
  { min: 500000, max: 1000000 },
  { min: 1000000, max: 5000000 },
  { min: 5000000, max: 10000000 },
  { min: 10000000, max: 50000000 },
  { min: 50000000, max: 100000000 },
  { min: 100000000, max: 500000000 },
  500000000,
]

const returns = [
  -0.9, -0.5, -0.3, -0.2, -0.1, -0.05, 0, 0.05, 0.1, 0.15, 0.2, 0.25, 0.3, 0.4,
  0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.5, 2, 3, 4, 5,
]

const margins = [
  0, 0.05, 0.1, 0.15, 0.2, 0.25, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9,
]

const growth = [
  -0.9, -0.5, -0.3, -0.2, -0.1, -0.05, 0, 0.05, 0.1, 0.15, 0.2, 0.25, 0.3, 0.4,
  0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.5, 2, 3, 4, 5,
]

const price = [1, 5, 10, 20, 50, 100, 200, 500, 1000, 2000]

const peRatio = [
  5,
  10,
  15,
  20,
  25,
  30,
  40,
  50,
  { min: 0, max: 10 },
  { min: 10, max: 20 },
  { min: 20, max: 30 },
  { min: 30, max: 40 },
  { min: 40, max: 50 },
  { min: 50, max: 100 },
]

const priceToSalesRatio = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  12,
  15,
  20,
  50,
  { min: 0, max: 1 },
  { min: 1, max: 2 },
  { min: 2, max: 5 },
  { min: 5, max: 10 },
  { min: 10, max: 20 },
  { min: 20, max: 50 },
]

const priceToFreeCashFlowRatio = [
  5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 60, 70, 80, 90, 100,
]

const revenuePerShare = [1, 5, 10, 20, 50, 100, 200, 500, 1000]

const netIncomePerShare = [0.1, 0.5, 1, 2, 5, 10, 20, 50, 100, 200]

const operatingCashFlowPerShare = [0.1, 0.5, 1, 2, 5, 10, 20, 50, 100, 200]

const freeCashFlowPerShare = [0.1, 0.5, 1, 2, 5, 10, 20, 50, 100, 200]

const cashPerShare = [0.5, 1, 2, 3, 4, 5, 10, 15, 20, 25, 50, 100]

const bookValuePerShare = [0.5, 1, 2, 3, 4, 5, 10, 15, 20, 25, 50, 100]

const tangibleBookValuePerShare = [0.5, 1, 2, 3, 4, 5, 10, 15, 20, 25, 50, 100]

const shareholdersEquityPerShare = [0.5, 1, 2, 3, 4, 5, 10, 15, 20, 25, 50, 100]

const interestDebtPerShare = [0.5, 1, 2, 3, 4, 5, 10, 15, 20, 25, 50, 100]

const enterpriseValue = [
  10000000, 50000000, 100000000, 500000000, 1000000000, 5000000000, 10000000000,
  50000000000, 100000000000, 500000000000,
]

const pbRatio = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  { min: 0, max: 1 },
  { min: 1, max: 2 },
  { min: 2, max: 3 },
  { min: 3, max: 5 },
  { min: 5, max: 10 },
]

const evToSales = [0.1, 0.5, 1, 2, 3, 4, 5]

const evToEBITDA = [1, 2, 3, 5, 6, 7, 8, 9, 10, 15, 20]

const evToOCF = [1, 2, 3, 5, 10, 15, 20, 25, 30, 35, 40]

const evToFCF = [1, 2, 3, 5, 10, 15, 20, 25, 30, 35, 40]

const earningsYield = [1, 2, 3, 5, 6, 7, 8, 9, 10, 15, 20, 25]

const fcfYield = [0.1, 0.3, 0.5, 0.8, 1, 2, 3, 5]

const debtToEquity = [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.5, 2, 3]

const debtToAssets = [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.5, 2, 3]

const netDebtToEBITDA = [
  0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.5, 2, 3,
]

const currentRatio = [0.2, 0.5, 0.8, 1, 2, 3, 4, 5, 10]

const quickRatio = [0.2, 0.5, 0.8, 1, 2, 3, 4, 5, 10]

const cashRatio = [0.2, 0.5, 0.8, 1, 2, 3, 4, 5, 10]

const cashFlowToDebtRatio = [0.2, 0.5, 0.8, 1, 1.5, 2, 2.5, 3, 4, 5, 10]

const interestCoverage = [0.5, 1, 1.5, 2, 3, 4, 5, 10, 20]

const incomeQuality = [0.2, 0.5, 1, 1.2, 1.5, 2, 2.5, 3, 4, 5]

const dividendYield = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

const dividendYieldPercentage = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

const payoutRatio = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

const daysOfSalesOutstanding = [15, 30, 45, 60, 75, 90, 120]

const daysOfInventoryOutstanding = [30, 45, 60, 90, 120, 150, 180]

const operatingCycle = [45, 60, 75, 90, 120, 150, 180, 210]

const daysOfPayablesOutstanding = [15, 30, 45, 60, 75, 90]

const cashConversionCycle = [0, 15, 30, 45, 60, 75, 90, 120]

const effectiveTaxRate = [0, 0.05, 0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4]

const netIncomePerEBT = [0, 0.5, 1, 1.5, 2, 2.5, 3]

const ebtPerEbit = [0, 0.5, 1, 1.5, 2, 2.5, 3]

const ebitPerRevenue = [-1, -0.5, 0, 0.5, 1, 1.5, 2]

const debtRatio = [0, 0.2, 0.4, 0.6, 0.8, 1, 1.2, 1.5]

const longTermDebtToCapitalization = [0, 0.2, 0.4, 0.6, 0.8, 1, 1.5]

const companyEquityMultiplier = [1, 1.5, 2, 2.5, 3, 4, 5]

const fixedAssetTurnover = [0.5, 1, 2, 3, 4, 5, 6, 7, 8]

const assetTurnover = [0.1, 0.2, 0.5, 1, 1.5, 2]

const operatingCashFlowSalesRatio = [0, 0.05, 0.1, 0.2, 0.3, 0.5, 1, 1.5, 2, 3]

const freeCashFlowOperatingCashFlowRatio = [0, 0.2, 0.4, 0.6, 0.8, 1, 1.5, 2, 3]

const cashFlowCoverageRatio = [0, 0.5, 1, 1.5, 2, 3, 4, 5]

const shortTermCoverageRatio = [0, 0.5, 1, 1.5, 2, 3, 4, 5, 6, 7, 8, 9, 10]

const capitalExpenditureCoverageRatio = [0, 1, 2, 3, 4, 5, 10]

const dividendPaidAndCapexCoverageRatio = [0, 0.5, 1, 1.5, 2, 3, 4, 5]

const priceToOperatingCashFlowsRatio = [0, 5, 10, 15, 20, 25, 30, 40, 50, 100]

const priceCashFlowRatio = [0, 5, 10, 15, 20, 25, 30, 40, 50, 100]

const enterpriseValueMultiple = [0, 5, 10, 15, 20, 25, 30, 40, 50, 100]

const priceFairValue = [0.5, 0.75, 1, 1.25, 1.5, 2, 2.5, 3, 4, 5, 10, 20]

const debtToMarketCap = [
  0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.5, 2, 3, 4, 5, 10,
]

const capexPerShare = [-2, -1, -0.5, 0, 0.5, 1, 1.5, 2]

const dividendPerShare = [0.5, 1, 2, 3, 4, 5, 10, 20, 50]

const salesGeneralAndAdministrativeToRevenue = [
  0, 0.1, 0.2, 0.3, 0.4, 0.5, 1, 2, 3, 4, 5, 10,
]

const researchAndDevelopmentToRevenue = [
  0, 0.05, 0.1, 0.15, 0.2, 0.25, 0.3, 0.5, 1, 2, 5,
]

const intangiblesToTotalAssets = [0, 0.1, 0.2, 0.5, 1, 2, 3, 4, 5, 10]

const capexToOperatingCashFlow = [
  -5, -4, -3, -2, -1, -0.5, -0.2, 0, 0.2, 0.5, 1, 2, 3, 4, 5,
]

const capexToRevenue = [
  -5, -4, -3, -2, -1, -0.5, -0.2, 0, 0.2, 0.5, 1, 2, 3, 4, 5,
]

const capexToDepreciation = [
  -20, -10, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 10, 20,
]

const eps = [
  -100, -50, -20, -10, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 10, 20, 50, 100,
]

const stockBasedCompensationToRevenue = [
  -10, -5, -4, -3, -2, -1, -0.5, 0, 0.5, 1, 2, 3, 4, 5, 10,
]

const grahamNumber = [
  -1, 0, 0.2, 0.5, 1, 5, 10, 20, 30, 40, 50, 100, 200, 1000, 5000, 10000,
]

const grahamNetNet = [
  -500, -200, -150, -100, 50, -20, -15, -10, -5, 0, 5, 10, 20, 50, 100, 200,
  500,
]

const daysSalesOutstanding = [15, 30, 45, 60, 90, 120]

const daysPayablesOutstanding = [15, 30, 45, 60, 90, 120]

const daysOfInventoryOnHand = [15, 30, 45, 60, 90, 120]

const receivablesTurnover = [0, 2, 5, 8, 10, 15, 20, 25, 50]

const payablesTurnover = [0, 2, 5, 8, 10, 15, 20, 25, 50]

const inventoryTurnover = [0, 2, 5, 8, 10, 15, 20, 25, 50]

const beta = [
  -10, -5, -4, -3, -2, -1.5, -1, -0.5, 0, 0.5, 1, 1.5, 2, 3, 4, 5, 10,
]

const lastDividend = [0.5, 1, 1.5, 2, 2.5]

const range = [10, 20, 30, 40, 50]

const change = [
  -100, -50, -25, -20, -15, -10, -5, -1, 0, 1, 5, 10, 15, 20, 25, 50, 100,
]

const dcfDifference = [
  -100, -50, -25, -20, -15, -10, -5, 0, 5, 10, 15, 20, 25, 50, 100,
]

const dcf = [1, 2, 5, 10, 25, 50, 100, 200, 300, 400, 500, 1000]

const fullTimeEmployees = [
  10,
  { min: 50, max: 100 },
  { min: 100, max: 500 },
  { min: 500, max: 1000 },
  { min: 1000, max: 5000 },
  { min: 5000, max: 10000 },
  10000,
]

export const thresholds = {
  financial,
  marketCap,
  growth,
  margins,
  price,
  peRatio,
  priceToSalesRatio,
  pbRatio,
  priceToFreeCashFlowRatio,
  returns,
  revenuePerShare,
  netIncomePerShare,
  operatingCashFlowPerShare,
  freeCashFlowPerShare,
  cashPerShare,
  bookValuePerShare,
  tangibleBookValuePerShare,
  shareholdersEquityPerShare,
  interestDebtPerShare,
  enterpriseValue,
  evToSales,
  evToEBITDA,
  evToOCF,
  evToFCF,
  earningsYield,
  fcfYield,
  debtToEquity,
  debtToAssets,
  netDebtToEBITDA,
  currentRatio,
  quickRatio,
  cashRatio,
  cashFlowToDebtRatio,
  interestCoverage,
  incomeQuality,
  dividendYield,
  dividendYieldPercentage,
  payoutRatio,
  daysOfSalesOutstanding,
  daysOfInventoryOutstanding,
  operatingCycle,
  daysOfPayablesOutstanding,
  cashConversionCycle,
  effectiveTaxRate,
  netIncomePerEBT,
  ebtPerEbit,
  ebitPerRevenue,
  debtRatio,
  longTermDebtToCapitalization,
  companyEquityMultiplier,
  fixedAssetTurnover,
  assetTurnover,
  operatingCashFlowSalesRatio,
  freeCashFlowOperatingCashFlowRatio,
  cashFlowCoverageRatio,
  shortTermCoverageRatio,
  capitalExpenditureCoverageRatio,
  dividendPaidAndCapexCoverageRatio,
  priceToOperatingCashFlowsRatio,
  priceCashFlowRatio,
  enterpriseValueMultiple,
  priceFairValue,
  debtToMarketCap,
  capexPerShare,
  dividendPerShare,
  salesGeneralAndAdministrativeToRevenue,
  researchAndDevelopmentToRevenue,
  intangiblesToTotalAssets,
  capexToOperatingCashFlow,
  capexToRevenue,
  capexToDepreciation,
  stockBasedCompensationToRevenue,
  grahamNumber,
  grahamNetNet,
  daysSalesOutstanding,
  daysPayablesOutstanding,
  daysOfInventoryOnHand,
  receivablesTurnover,
  payablesTurnover,
  inventoryTurnover,
  volumeAverage,
  beta,
  lastDividend,
  range,
  change,
  dcfDifference,
  dcf,
  fullTimeEmployees,
  eps,
}
