import React, { useState } from 'react'
import styles from './styles.module.scss'
import { UilArrowUp, UilBan } from '@iconscout/react-unicons'

const MessageInput = ({ onSendMessage, isBotTyping, userTicker }) => {
  const [text, setText] = useState('')

  const handleSubmit = e => {
    e.preventDefault()
    if (text.trim() && !isBotTyping) {
      onSendMessage(text)
      setText('')
    }
  }

  return (
    <form
      onSubmit={handleSubmit}
      className={styles.messageForm}
      onKeyDown={e => {
        if (e.key === 'Enter' && !isBotTyping) {
          e.preventDefault()
          handleSubmit(e)
        }
      }}
    >
      {userTicker === null ? null : (
        <img
          src={`https://financialmodelingprep.com/image-stock/${userTicker}.png`}
          alt={`${userTicker} logo`}
          className={styles.logo}
        />
      )}
      <input
        type="text"
        value={text}
        onChange={e => setText(e.target.value)}
        placeholder={
          userTicker
            ? `Message Outseek about ${userTicker}...`
            : 'Message Outseek...'
        }
        className={styles.input}
      />
      <button
        type="submit"
        className={styles.sendButton}
        disabled={isBotTyping}
      >
        {isBotTyping ? (
          <UilBan size={24} color="var(--light-grey-accent)" />
        ) : (
          <UilArrowUp size={24} color="var(--light-grey-accent)" />
        )}
      </button>
    </form>
  )
}

export default MessageInput
