import React from 'react'
import './DesktopLarge.scss'
import { Footer } from '../../HomePageComponents/Footer/HomePageFooter'
import { EnterTerminalButton } from '../../HomePageComponents/EnterTerminalButton/EnterTerminalButton'
import { NavBar } from '../../HomePageComponents/NavBar/NavBar'
import Image from '../../HomePageComponents/Image/Image'
import { FeatureItem } from 'pages/HomePage/HomePageComponents/FeatureItem/FeatureItem'
import { ThreeBenefits } from 'pages/HomePage/HomePageComponents/ThreeBenefits/ThreeBenefits'
import { TypedText } from 'pages/HomePage/HomePageComponents/Typed/Typed'
import { featureSectionConstants } from '../constants'
import { FeatureTabs } from 'pages/HomePage/HomePageComponents/FeatureTabs/FeatureTabs'
import { config } from './constants'
import { HomePageThemesChange } from 'theme/homepageThemesChange'

//// Media
import { media } from '../../assets/asset'

// Texts
import { texts } from '../../TextVars/HomePageText'

export const DesktopLargeView = () => {
  return (
    <div className="desktop-large-view-homepage-wrapper">
      <NavBar />
      <div className="desktopContentWrapper">
        <div
          className="desktop-large-view-homepage-content-1"
          style={{
            height: 'fit-content',
          }}
        >
          <div className="desktop-large-view-homepage-content-1-content-row-1">
            <div className="desktop-large-view-homepage-content-1-col">
              <div className="desktop-large-view-homepage-content-1-title-container">
                <div className="desktop-large-view-homepage-content-1-title-font">
                  Financial data you need,<br></br> in the way you want.
                </div>
              </div>
              <TypedText />
              <div className="desktop-large-view-homepage-content-1-enter-terminal">
                <EnterTerminalButton />
              </div>
            </div>
          </div>
          <div className="desktop-large-view-homepage-content-1-content-row-2">
            <Image
              webpSource={media.marketOverviewWebP}
              alt="Market Overview"
              className="desktop-large-view-homepage-content-1-dashboard-img-top"
            />
          </div>
        </div>
        <div className="container1">
          <section className="desktop-large-view-homepage-panel-feature">
            {featureSectionConstants.map((feature, index) => (
              <FeatureItem
                title={feature.title}
                description={feature.description}
                webpSource={feature.webP}
                stretch={feature.stretch}
                renderComponent={feature.renderComponent}
              />
            ))}
          </section>
        </div>
        <div className="container1">
          <FeatureTabs config={config} />
        </div>
        <HomePageThemesChange />
      </div>
      <Footer />
    </div>
  )
}
