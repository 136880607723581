import { useState } from 'react'

import styles from './Item.module.scss'
import { connect } from 'react-redux'
import { addComponent } from 'redux/actions/app-actions'
import { TypeTag } from '../Tags/Tags'
import { CatagoryTag } from '../Tags/Tags'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import { CoreButton } from 'core/Button/CoreButton'

const Item = ({ item, openInfo, ...props }) => {
  const [open, setOpen] = useState(false)
  const handleOpen = event => event.stopPropagation(setOpen(true))
  const handleClose = () => setOpen(false)

  const { title, description } = item

  const handleAddComponent = () => {
    const component = {
      // config: item,
      title: title,
      type: title,
      spec: { ticker: 'AAPL', state: {} },
      data: [],
    }

    props.addComponent(component)
  }

  return (
    <div className={styles.item}>
      <div
        className={styles.containerTitle}
        onClick={() => handleAddComponent()}
      >
        <div className={styles.buttonTitleWrapper}>
          <div className={styles.itemTitle}>{title}</div>
          <CoreButton onClick={handleOpen}>Preview</CoreButton>
        </div>
        <div className={styles.itemIconsContainer}>
          <TypeTag entry={item}></TypeTag>
          {item?.category && <CatagoryTag entry={item}></CatagoryTag>}
        </div>
        <div className={styles.itemDesc}>{description}</div>
      </div>
      <Modal open={open} onClose={handleClose} disableAutoFocus={true}>
        <Box
          sx={{
            '&:focus': {
              outline: 'none',
            },
          }}
        >
          <div className={styles.itemModalBackground}>
            <div className={styles.itemModal}>
              <div className={styles.itemModalDesc}>
                <div className={styles.itemTitle}>{title}</div>
                <div className={styles.itemIconsContainer}>
                  <TypeTag entry={item} longV={true}></TypeTag>
                  {item?.category && <CatagoryTag entry={item}></CatagoryTag>}
                </div>
                <div>{description}</div>
                <CoreButton onClick={handleClose} width={'100%'}>
                  Close Preview
                </CoreButton>
              </div>
              <div className={styles.itemModalImage}>Coming Soon</div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  )
}

export default connect(null, {
  addComponent,
})(Item)
