import React from 'react'
import styles from './styles.module.scss'
import { NavBar } from 'pages/HomePage/HomePageComponents/NavBar/NavBar'
import { Footer } from 'pages/HomePage/HomePageComponents/Footer/HomePageFooter'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router'
import image from './copilot.png'
import { featureSectionConstants } from './featureGrid/constants'
import { WavyButton } from 'core/WavyButton/WavyButton'
import { FeatureTabs } from 'pages/HomePage/HomePageComponents/FeatureTabs/FeatureTabs'
import { featureConfig } from './featureConfig'
import { Testimonials } from './Testimonials/Testimonials'
import { userTestimonials } from './Testimonials/config'

const Copilot = () => {
  const navigate = useNavigate()

  const goToCopilot = () => {
    navigate('/terminal/copilot')
  }

  return (
    <>
      <Helmet>
        <title>{`Outseek | Copilot`}</title>
        <meta
          name="description"
          content={
            'Outseek Copilot is the next generation of financial research and analysis powered by AI. Save time and make better decisions.'
          }
        />
      </Helmet>
      <NavBar />
      <div className={styles.copilotFeatures}>
        <div className={styles.titleWrapper}>
          <div className={styles.copilotPill}>Outseek Copilot</div>
          <div className={styles.copilotTitle}>
            The next generation of financial research and analysis.
          </div>
        </div>
        <div>
          <WavyButton handleClick={goToCopilot}>Try it out</WavyButton>
        </div>
        <div className={styles.imageWrapper}>
          <img src={image} alt="copilot" className={styles.image} />
        </div>
        <div className={styles.wrapper}>
          {featureSectionConstants.map(feature => {
            return feature.renderComponent()
          })}
        </div>
        {/* <Testimonials config={userTestimonials} /> */}
        {/* <div className="container1">
          <FeatureTabs config={featureConfig} />
        </div> */}
      </div>
      <Footer />
    </>
  )
}

export default Copilot
