/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useReducer, useRef, useMemo, useState } from 'react'
import { fmpAPI } from 'network/fmpAPI'
import { ToolBar } from './components/ToolBar'
import { ChartWrapper } from './components/ChartWrapper'
import { updateTableState } from 'redux/actions/app-actions'
import { connect } from 'react-redux'
import { useQueries } from 'react-query'
import numeral from 'numeral'
import { cloneDeep, startCase } from 'lodash'
import { LINE_OPTIONS, ANNUAL, QUARTERLY } from './constants'
import { COLOR_PALLET } from 'constants/appConstants'
import actions from './state/actions'
import comparisonChartReducer, { INITIAL_STATE } from './state/reducer'

import './styles.scss'

const API = new fmpAPI()
export function useFirstRender() {
  const firstRender = useRef(true)

  useEffect(() => {
    firstRender.current = false
  }, [])

  return firstRender.current
}

const ComparisonCharts = ({
  root,
  ticker,
  width,
  height,
  initialState,
  view, // if the chart is in a view
  ...props
}) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)
  const [state, dispatch] = useReducer(
    comparisonChartReducer,
    initialState || INITIAL_STATE
  )
  const firstRender = useFirstRender()
  const chartQueries = useQueries(
    state.metrics.map(item => {
      return {
        queryKey: ['chart', item.APIKey, ticker, state.frequency],
        queryFn: async () => {
          const resp = await API.fetch_company_metrics(
            item.APIKey,
            ticker,
            state.frequency
          )
          return resp.reverse()
        },
        retry: {
          retryDelay: 200,
          retry: 200,
        },
      }
    })
  )

  const allFinished = chartQueries.every(query => query.isSuccess)

  useEffect(() => {
    if (allFinished && !firstRender && chartQueries.length > 0) {
      let chartData = chartQueries[0].data

      // merge the query responses
      chartQueries.forEach(c => {
        c.data.forEach(
          (entry, i) => (chartData[i] = { ...chartData[i], ...entry })
        )
      })

      // filter out the excess properties that were provided in the responses
      chartData = chartData.map(entry => {
        let obj = {}
        state.metrics.forEach(m => (obj[m.APIProperty] = entry[m.APIProperty]))
        return {
          date: entry?.date,
          ...obj,
        }
      })

      // dump to state
      dispatch({
        action: actions.ACTION_SET_BULK_DATA,
        payload: {
          metricsData: chartData,
        },
      })
    }
  }, [allFinished, state.frequency])

  useEffect(() => {
    if (!view) {
      props.updateTableState(cloneDeep(state), root)
    }
  }, [state, view, props.updateTableState, root])

  const formatTooltipValue = useMemo(
    () =>
      state.showPercentageChange
        ? entry => {
            return `${startCase(entry.name)}: ${numeral(entry.value).format(
              '0.00 a'
            )} %`
          }
        : entry => {
            return `${startCase(entry.name)}: ${numeral(entry.value).format(
              '0.00 a'
            )} `
          },
    [state.showPercentageChange]
  )

  const handleAddComparisonMetric = Item => {
    let NewChartData = []

    // Assign a color to the new metric
    const color = COLOR_PALLET[state.metrics.length % COLOR_PALLET.length]

    return API.fetch_company_metrics(Item.APIKey, ticker, state.frequency).then(
      data_raw => {
        data_raw.forEach((entry, index) => {
          NewChartData.push({
            ...state.metricsData[data_raw.length - index - 1],
            date: entry['date'],
            [Item.APIProperty]: entry[Item.APIProperty],
          })
        })

        NewChartData = NewChartData.reverse()

        // Include color information in the new metric
        const newMetricWithColor = { ...Item, color }

        dispatch({
          action: actions.ACTION_PUSH_METRIC,
          payload: {
            newData: NewChartData,
            newMetric: newMetricWithColor,
          },
        })
      }
    )
  }

  const handleDeleteMetric = metricIN => {
    // props.updateTableState([...metricTemp], root)
    dispatch({
      action: actions.ACTION_REMOVE_METRIC,
      payload: {
        APIProperty: metricIN.APIProperty,
      },
    })
  }

  const handleToggleSingleAxis = () => {
    dispatch({
      action: actions.ACTION_TOGGLE_SINGLE_AXIS,
    })
  }

  const handleLabelToggle = () => {
    dispatch({
      action: actions.ACTION_TOGGLE_LABELS,
    })
  }

  const handleTogglePercentageChanged = () => {
    dispatch({
      action: actions.ACTION_TOGGLE_PERCENT_CHANGE,
    })
  }
  const handleSetLineType = newType => {
    dispatch({
      action: actions.ACTION_CHANGE_LINE_TYPE,
      payload: newType,
    })
  }

  const handleChartTypeChange = metricAPIProperty => {
    dispatch({
      action: actions.ACTION_TOGGLE_METRIC_VIZ_TYPE,
      payload: {
        APIProperty: metricAPIProperty,
      },
    })
  }

  // console.log(state)
  // const handleDataFrequencyToggle = () => {
  //   dispatch({
  //     action: actions.ACTION_CHANGE_FREQUENCY,
  //     payload: state.frequency === ANNUAL ? QUARTERLY : ANNUAL,
  //   })
  // }

  const handleDataFrequencyChange = newFrequency => {
    dispatch({
      action: actions.ACTION_CHANGE_FREQUENCY,
      payload: newFrequency,
    })
  }

  const comparisonChartCallbacks = {
    // handleDataFrequencyToggle,
    handleDataFrequencyChange,
    handleToggleSingleAxis,
    handleTogglePercentageChanged,
    handleChartTypeChange,
    handleSetLineType,
    handleAddComparisonMetric,
    handleDeleteMetric,
    handleLabelToggle,
  }

  return (
    <div
      className="compChartContainer"
      style={{ width: '100%', height: height }}
    >
      <ToolBar
        isLoading={!state.metricsData.length}
        comparisonChartCallbacks={comparisonChartCallbacks}
        chartState={state}
        lineOptions={LINE_OPTIONS}
        isDropdownVisible={isDropdownVisible}
        setIsDropdownVisible={setIsDropdownVisible}
      ></ToolBar>
      <div
        style={{
          width: '100%',
          height: !isDropdownVisible
            ? 'calc(100% - 348px)'
            : 'calc(100% - 66px)',
        }}
      >
        <ChartWrapper
          width={'100%'}
          height={'100%'}
          chartState={state}
          comparisonChartCallbacks={comparisonChartCallbacks}
          formatTooltipValue={formatTooltipValue}
        />
      </div>
    </div>
  )
}

export default connect(null, { updateTableState })(ComparisonCharts)
