import React, { useState } from 'react'
import numeral from 'numeral'
import styles from './TableKeyValue.module.scss'
import { CoreLineChart } from 'core/Charts/GenericChart'
import { take, first } from 'lodash'
import { UilChartLine } from '@iconscout/react-unicons'
import { wordsToReplace, removeSubstrings, chunkPairs } from './utils'
import dayjs from 'dayjs'

export const TableKeyValue = ({
  labels,
  data,
  allowCharts = false,
  chunkEnabled = true,
}) => {
  const [chartMetric, setChartMetric] = useState('')
  const [chartView, setChartView] = useState(false)

  const handleKeyItemClick = label => {
    setChartMetric(label)
    setChartView(true)
  }

  const handleKeyItemRemove = () => {
    setChartMetric('')
    setChartView(false)
  }

  const chartData = take(data, 40)?.reverse()

  const renderDataValue = item => {
    const itemDataValue = data?.[0]?.[item?.key]

    if (
      itemDataValue === undefined ||
      itemDataValue === null ||
      itemDataValue === ''
    ) {
      return '-'
    }

    if (item?.text === true) {
      return itemDataValue
    } else if (item?.link === true) {
      return (
        <a
          href={itemDataValue}
          target="_blank"
          rel="noreferrer"
          className={styles.link}
        >
          {removeSubstrings(itemDataValue, wordsToReplace)}
        </a>
      )
    } else if (item?.percent === true) {
      return `${numeral(itemDataValue).format('0.00')} %`
    } else if (item?.dollar === true) {
      return `${numeral(itemDataValue).format('$0.00a')}`
    } else if (item?.format) {
      return numeral(itemDataValue).format('0.00 a')
    } else if (item?.date) {
      return dayjs(itemDataValue).format('MMM DD, YYYY')
    } else {
      return numeral(itemDataValue).format('0,0')
    }
  }

  const renderLabels = () => {
    const processedLabels = chunkEnabled ? chunkPairs(labels) : [labels]
    return processedLabels?.map((pair, pairIndex) => (
      <div
        className={styles.tableKeyValueRow}
        key={pairIndex}
        style={{ flexDirection: chunkEnabled === false ? 'column' : null }}
      >
        {pair?.map((item, index) => {
          const showDivider =
            chunkEnabled === false
              ? pairIndex * 2 + index < labels.length - 1
              : pairIndex * 2 + index < labels.length - 2
          return (
            <div
              className={styles.tableKeyItem}
              style={{
                cursor: item.canVisualize ? 'pointer' : 'default',
                width: chunkEnabled === false ? '100%' : '50%',
              }}
              key={index}
              onClick={
                item.canVisualize ? () => handleKeyItemClick(item.key) : null
              }
            >
              <div className={styles.tableKeyValueHorizontalFlex}>
                <div className={styles.tableKeyValueLabel}>{item.label}</div>
                <div className={styles.tableKeyValueData}>
                  {renderDataValue(item)}
                  {allowCharts ? (
                    item.canVisualize ? (
                      <UilChartLine size={16} color={'var(--primary-color)'} />
                    ) : (
                      <div className={styles.emptySpace} />
                    )
                  ) : null}
                </div>
              </div>
              {showDivider && <div className={styles.divider} />}
            </div>
          )
        })}
      </div>
    ))
  }

  return (
    <div className={styles.tableKeyValueContainer}>
      {chartView ? (
        <div className={styles.chartWrapper}>
          <div
            onClick={() => handleKeyItemRemove()}
            className={styles.backButton}
          >
            Return to Table
          </div>
          <CoreLineChart
            data={chartData}
            metrics={[
              { key: chartMetric, color: 'var(--purple)', type: 'monotone' },
            ]}
            XAxisKey={'date'}
            YAxisKey={chartMetric}
            zeroTick={true}
            showSingleYAxis={'right'}
            decimalCount={1}
            customTickFormatter
            xAxisHeight={14}
            axisWidth={55}
          />
        </div>
      ) : (
        renderLabels()
      )}
    </div>
  )
}

export default TableKeyValue
