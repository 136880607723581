import moment from 'moment'

export const Intervals = [
  {
    label: '7D',
    limit: 7,
  },
  {
    label: '1M',
    limit: 30,
  },
  {
    label: '3M',
    limit: 90,
  },
  {
    label: '6M',
    limit: 182,
  },
  {
    label: '1Y',
    limit: 365,
  },
  {
    label: '2Y',
    limit: 730,
  },
  {
    label: '3Y',
    limit: 1095,
  },
  {
    label: 'All',
    limit: -1,
  },
]

export const SectorIntervals = [
  {
    label: '3D',
    limit: 3,
  },
  {
    label: '1W',
    limit: 7,
  },
  {
    label: '2W',
    limit: 14,
  },
  {
    label: '3W',
    limit: 21,
  },
  {
    label: '2M',
    limit: 60,
  },
  {
    label: '3M',
    limit: 90,
  },
  {
    label: 'All',
    limit: -1,
  },
]
export const CommodityIntervals = [
  {
    label: '7D',
    from: moment().subtract(7, 'days').toDate(),
    to: moment().toDate(),
  },
  {
    label: '1M',
    from: moment().subtract(1, 'months').toDate(),
    to: moment().toDate(),
  },
  {
    label: '3M',
    from: moment().subtract(3, 'months').toDate(),
    to: moment().toDate(),
  },
  {
    label: '6M',
    from: moment().subtract(6, 'months').toDate(),
    to: moment().toDate(),
  },
  {
    label: '1Y',
    from: moment().subtract(1, 'years').toDate(),
    to: moment().toDate(),
  },
  {
    label: '2Y',
    from: moment().subtract(2, 'years').toDate(),
    to: moment().toDate(),
  },
  {
    label: '3Y',
    from: moment().subtract(3, 'years').toDate(),
    to: moment().toDate(),
  },
  {
    label: 'All',
    from: null,
    to: null,
  },
]
