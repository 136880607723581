import { EarningsContent } from './components/EarningsContent'
import { EarningsToolBar } from './components/EarningsToolBar'
import styles from './earningsTranscript.module.scss'
import { useMemo } from 'react'
import {
  grabSpeakers,
  grabParagraphs,
} from '../../../../../dashboard/EarningsTranscript/utils'
import Spinner from 'core/ScrollableContainer/Spinner/spinner'
import useSpeechSynthesis from './components/useSpeechSynthesis'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import './sliderStyles.css'
import { PREMIUM_FEATURES } from 'pages/Pricing/PriceTable/pricingConfig'
import { useUserGroup } from 'hooks/userGroupHook'
import { Link } from 'react-router-dom'

export const EarningsTranscript = ({
  setSelectedTranscriptDate,
  selectedTranscriptDate,
  data,
}) => {
  const { getFeatureLimit, upgradeSelectOption, groups, userGroup } =
    useUserGroup()

  const speakers = grabSpeakers(data?.content)
  const paragraphs = grabParagraphs(data?.content)

  const {
    isInMiddleOfSpeaking,
    isSpeaking,
    currentParagraph,
    startSpeaking,
    pauseSpeaking,
    stopSpeaking,
    resumeSpeaking,
    sliderValue,
    jumpToParagraph,
  } = useSpeechSynthesis(data, paragraphs, selectedTranscriptDate)

  const handleSliderChange = value => {
    jumpToParagraph(value)
  }

  const handleYearChange = selectedOption => {
    setSelectedTranscriptDate(prev => ({ ...prev, year: selectedOption.value }))
  }

  const handleQuarterChange = selectedOption => {
    setSelectedTranscriptDate(prev => ({
      ...prev,
      quarter: selectedOption.value,
    }))
  }

  const yearOptions = useMemo(() => {
    const limit = getFeatureLimit(PREMIUM_FEATURES.EARNINGS_EARNINGS_TRANSCRIPT)
    const uniqueYears = data?.years?.uniqueYears || []

    const yearOptions = uniqueYears?.slice(0, limit)?.map(year => ({
      value: year,
      label: year,
    }))

    if (userGroup === groups.FREE_USERS || userGroup === groups.PLUS_USERS) {
      yearOptions.push(upgradeSelectOption)
    }

    return yearOptions
  }, [data?.years?.uniqueYears, getFeatureLimit])

  const quarterOptions = useMemo(() => {
    return (
      data?.quarters?.map(quarter => ({
        value: quarter,
        label: `Q${quarter}`,
      })) || []
    )
  }, [data?.quarters])

  if (data?.yearsLoading || data?.quartersLoading || data?.contentDateLoading) {
    return <Spinner />
  }

  return (
    <div className={styles.erTranscriptWrapper}>
      <EarningsToolBar
        data={data}
        yearOptions={yearOptions}
        handleYearChange={handleYearChange}
        quarterOptions={quarterOptions}
        handleQuarterChange={handleQuarterChange}
        isSpeaking={isSpeaking}
        startSpeaking={startSpeaking}
        stopSpeaking={stopSpeaking}
        pauseSpeaking={pauseSpeaking}
        resumeSpeaking={resumeSpeaking}
        isInMiddleOfSpeaking={isInMiddleOfSpeaking}
      />
      <div className={styles.rangeContainer}>
        <Slider
          min={0}
          max={paragraphs.length - 1}
          value={sliderValue}
          onChange={handleSliderChange}
          marks={paragraphs.reduce((acc, _, index) => {
            acc[index] = '‎'
            return acc
          }, {})}
        />
      </div>
      <EarningsContent
        speakers={speakers}
        paragraphs={paragraphs}
        currentParagraph={currentParagraph}
      />
    </div>
  )
}
