import _, { last } from 'lodash'
import numeral from 'numeral'
import moment from 'moment'
import { areQueriesDone } from '../NewsView/hooks/useDataSouce'
export const splitInstitutionalTickerValue = value => {
  const parts = value?.split(',')
  return {
    cik: parts[0],
    name: parts?.slice(1).join(''),
  }
}
export const blendLargeChartData = (
  HistoricalPrice,
  InstitutionalStockOwnership
) => {
  let data = HistoricalPrice?.map(entry => {
    return {
      ...entry,
      ...InstitutionalStockOwnership?.filter(
        hist => hist?.date === entry?.date
      )?.[0],
    }
  })
  return data.reverse()
}

export const getNetNewPositions = data => {
  return data?.map(entry => {
    return {
      ...entry,
      netNewPositions: entry?.newPositions - entry?.closedPositions,
    }
  })
}

export const getAveragesByList = (dataIN, keyList = []) => {
  const len = dataIN?.length

  let data = {}
  // get totals
  keyList?.forEach(([key]) => {
    data[key] = dataIN?.reduce(
      (a, b) => a + b[key?.split('_')?.[1] || key?.split('_')?.[0]],
      0
    )
  })
  // divide by length
  let avgData = {}
  keyList?.forEach(([key]) => {
    avgData[key] = data[key] / len
  })

  return avgData
}

export const getTop8Institutions = dataIn => {
  return
}

export const getIndexedToPercentage = (
  IndexData,
  PortfolioPositionsSummary
) => {
  if (areQueriesDone(IndexData) === 0 && PortfolioPositionsSummary) {
    // Calculate percentage change in performance relative to starting point
    let startingMarketValue = PortfolioPositionsSummary[0]?.marketValue
    let performanceData = PortfolioPositionsSummary?.map(entry => ({
      date: entry?.date,
      performance:
        ((entry.marketValue - startingMarketValue) / startingMarketValue) * 100,
    }))

    let data = IndexData.map(index => {
      let indexData = index?.data?.historical
      if (indexData && indexData?.length > 0) {
        let baseValue = indexData[0]?.close
        return indexData?.map(entry => {
          return {
            date: entry?.date,
            [index?.data?.symbol]:
              ((entry?.close - baseValue) / baseValue) * 100,
          }
        })
      } else {
        return []
      }
    })

    if (data?.length > 0) {
      let formattedData = data[0]

      data.slice(1, data?.length)?.forEach(marketIndex => {
        marketIndex?.forEach((entry, i) => {
          formattedData[i] = {
            ...formattedData[i],
            ...entry,
          }
        })
      })

      let counter = 0
      // Merge in performance data
      formattedData = formattedData?.map((entry, i) => {
        if (
          entry?.date === performanceData[counter]?.date ||
          formattedData[i]?.date > performanceData[counter]?.date
        ) {
          counter += 1

          return {
            ...entry,
            performance: performanceData[counter]?.performance,
          }
        }
        return entry
      })

      return formattedData
    }
  }
}

export const buildPieChartData = dataIn => {
  let sorted = _.orderBy(dataIn, ['weight'], ['desc'])
  let top5 = sorted?.slice(0, 5)
  let rest = sorted?.slice(6, sorted.length)?.reduce((a, b) => a + b.weight, 0)
  let data = [...top5, { industryTitle: 'OTHER', weight: rest }]
  return data
}

export const renderTooltipContentGeneric = (o, stepData, dataKeys, percent) => {
  const { payload, label } = o

  const stepValue = last(stepData?.filter(entry => entry.date <= label))

  return (
    <div className="customized-tooltip-content-core">
      <div
        className="list"
        style={{
          fontSize: '10.5px',
          color: 'var(--white)',
        }}
      >
        {`${moment(label).format('ll')}`}
        {payload?.map((entry, index) => (
          <div
            className="listItem"
            key={`item-${index}`}
            style={{
              color: entry.color,
              display: 'flex',
              gap: '4px',
              fontSize: '10.5px',
              flexDirection: 'row',
              // justifyContent: 'space-between',
            }}
          >
            <div style={{ color: 'var(--white)' }}>
              {`${_.startCase(entry.name)}: `}
            </div>
            {dataKeys.includes(entry.name)
              ? `${numeral(stepValue?.[entry.name]).format('0.000 a')}`
              : `${numeral(entry.value).format('0.000 a')}`}
          </div>
        ))}
      </div>
    </div>
  )
}

const utils = {
  renderTooltipContentGeneric,
  blendLargeChartData,
  getAveragesByList,
  // getIndexedToPercentage,
  getTop8Institutions,
  buildPieChartData,
  splitInstitutionalTickerValue,
}

export default utils
