import { useCallback, useState } from 'react'

import React from 'react'
import TimeRange from 'pages/views/NewsView/component/TimeRange'
import { TickerOverlayChart } from 'core/TickerOverlay/TickerOverlayChart'
import { OverviewIntervals } from 'pages/views/CompanyOverview/constant'
import numeral from 'numeral'
import { useDataSource } from 'pages/views/CompanyOverview/dataSource/hooks'
import styles from './styles.module.scss'
import { useNewsDataSource } from 'pages/views/NewsView/hooks/useDataSouce'
import { NewsTable } from 'pages/views/NewsView/component/NewsTable/NewsTable'
import Spinner from 'core/ScrollableContainer/Spinner/spinner'
import { NoData } from 'core/NoData/NoData'

const ComponentConfig = ({ extractedDataType, symbol }) => {
  const { Profile_UI, setInterval, interval } = useDataSource()
  const { SingleStockNewsLoading, singleStockNewsData_UI } = useNewsDataSource()

  const [showNewsTable, setShowNewsTable] = useState(true)

  const handleTimeRangeChange = useCallback(
    newInterval => {
      setInterval(newInterval)
    },
    [setInterval]
  )

  const handleToggleShowNewsTable = useCallback(() => {
    setShowNewsTable(prev => !prev)
  }, [])

  const config = {
    QUOTE: {
      component:
        Profile_UI?.priceDataIsLoading?.length === 0 ? (
          <div className={styles.noDataContainer}>
            No data available for this ticker
          </div>
        ) : (
          <div className={styles.companyOverviewChartTimeWrapper}>
            <div className={styles.timeRange}>
              <TimeRange
                intervals={OverviewIntervals}
                onChange={handleTimeRangeChange}
                interval={interval}
                fitContent
                gap={'8px'}
                background={'transparent'}
              />
            </div>
            <TickerOverlayChart
              ticker={symbol}
              data={Profile_UI.priceData}
              xAxisKey={'date'}
              view={true}
              interval={interval}
              barDataKey={'volume'}
              lineDataKey={'close'}
              YTickFormat={tick => numeral(tick).format('0.00a')}
              barDomain={[0, dataMax => dataMax * 4.5]}
            />
          </div>
        ),
      navigate: '/companyOverview',
    },
    NEWS: {
      component: SingleStockNewsLoading ? (
        <Spinner />
      ) : singleStockNewsData_UI?.tableData.length > 0 ? (
        <NewsTable
          data={singleStockNewsData_UI?.tableData}
          height={'100%'}
          swap={showNewsTable}
          toggleSwap={handleToggleShowNewsTable}
        />
      ) : (
        <NoData />
      ),
      navigate: '/stockNews',
    },
  }

  return config[extractedDataType] || { component: null, navigate: null }
}

export default ComponentConfig
