import React from 'react'
import styles from '../styles.module.scss'
import GridItemContainer from '../components/GridItem/GridItemContainer'
import { Responsive, WidthProvider } from 'react-grid-layout'
import { setLayout } from 'redux/actions/app-actions'
import { connect, useDispatch } from 'react-redux'
import useDashboard from '../hooks/useDashboard'

const ResponsiveGridLayout = WidthProvider(Responsive)

export const DashboardContainer = props => {
  const { layouts } = props
  const { data } = useDashboard()

  const dispatch = useDispatch()
  const handleLayoutChange = layout => {
    dispatch(setLayout(layout))
  }

  return (
    <div className={styles.gridLayoutWrapper}>
      <ResponsiveGridLayout
        className={styles.layout}
        onLayoutChange={(l, layouts) => handleLayoutChange(l, layouts)}
        layouts={layouts}
        isRearrangeable
        isResizable
        isBounded={true}
        isDroppable={true}
        draggableHandle=".dragHandle"
        draggableCancel=".closeButton, .tickerInputWrapper"
        breakpoints={{
          lg: 1200,
          md: 900,
          sm: 480,
          xs: 200,
          xxs: 0,
        }}
        cols={{ lg: 300, md: 30, sm: 20, xs: 10, xxs: 5 }}
        rowHeight={1}
      >
        {Object.keys(data).map((item, index) => (
          <GridItemContainer key={item} item={item} />
        ))}
      </ResponsiveGridLayout>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    data: Object.keys(state.data),
    layouts: { ...state.layouts },
  }
}

export default connect(mapStateToProps, null, null, { pure: false })(
  DashboardContainer
)
