import React from 'react'
import SankyChart from './CustomSankeyChart'
import { useFinancialStatementsContext } from '../../FinancialStatementsContext'
import CoreButton from 'core/Button/CoreButton'
import styles from './sankeyToolbar.module.scss'
import { range } from 'lodash'

export const SankeyChart = ({ data }) => {
  const { state, updateSankeyState } = useFinancialStatementsContext()

  const { padding, color } = state.sankeyState

  return (
    <div className={styles.sankeyComponentWrapper}>
      <div className={styles.buttonWrapper}>
        <CoreButton
          onClick={() => {
            updateSankeyState({ color: !color })
          }}
          color={color ? 'var(--grey-accent)' : 'var(--primary-color)'}
        >
          Color Change
        </CoreButton>
        Padding:
        <input
          className={styles.paddingRange}
          type="range"
          min="0"
          max="80"
          value={padding}
          onChange={e => {
            updateSankeyState({ padding: parseInt(e.target.value) })
          }}
        />
        {padding}
      </div>
      <div className={styles.chartWrapper}>
        <SankyChart data={data} padding={padding} color={color} />
      </div>
    </div>
  )
}
