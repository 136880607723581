import styles from './QuoteBar.module.scss'
import React, { useState, useRef, useEffect } from 'react'
import TickerSearchView from 'dashboard/Title/components/TickerSearchView'
import { useDataSource } from './data'
import dayjs from 'dayjs'
import numeral from 'numeral'
import { FormattedSpanPrecentage } from '../PositiveNegativeFormattedElements/FormattedSpan'
import { getQuoteBarItems } from './constants.js'
import leftArrow from './leftArrow.svg'
import rightArrow from './rightArrow.svg'
import { BarLoader } from 'react-spinners'

export const QuoteBar = ({ updateTicker, ticker }) => {
  const { data } = useDataSource({ ticker })

  const [priceDirection, setPriceDirection] = useState(null)
  const [prevPrice, setPrevPrice] = useState(null)

  useEffect(() => {
    if (prevPrice && data?.stockPrice?.price) {
      if (prevPrice < data?.stockPrice?.price) {
        setPriceDirection('up')
      } else if (prevPrice > data?.stockPrice?.price) {
        setPriceDirection('down')
      }
    }
    setPrevPrice(data?.stockPrice?.price)
  }, [prevPrice, data?.stockPrice?.price])

  const timeStamp = data?.stockPrice?.timestamp
  const updatedTimeStamp = timeStamp
    ? dayjs(timeStamp * 1000).format('MMM DD, h:mm:ss A')
    : null

  const quoteBarItems = getQuoteBarItems(data?.stockPrice, data?.companyData)

  const containerRef = useRef(null)

  const [scrollPosition, setScrollPosition] = useState({
    atStart: true,
    atEnd: false,
  })

  useEffect(() => {
    const container = containerRef?.current
    if (!container) return

    const updateScrollPosition = () => {
      const atStart = container?.scrollLeft === 0
      const atEnd =
        container?.scrollLeft + container?.clientWidth >=
        container?.scrollWidth - 1
      setScrollPosition({ atStart, atEnd })
    }

    container?.addEventListener('scroll', updateScrollPosition)

    const resizeObserver = new ResizeObserver(updateScrollPosition)
    resizeObserver?.observe(container)

    return () => {
      container?.removeEventListener('scroll', updateScrollPosition)
      resizeObserver?.unobserve(container)
    }
  }, [])

  const scrollContent = direction => {
    const container = containerRef.current
    const scrollAmount = 150

    if (direction === 'left') {
      container.scrollTo({
        left: container.scrollLeft - scrollAmount,
        behavior: 'smooth',
      })
    } else {
      container.scrollTo({
        left: container.scrollLeft + scrollAmount,
        behavior: 'smooth',
      })
    }
  }

  if (data?.isLoading) {
    return (
      <div className={styles.loadingContainer}>
        <BarLoader
          width={'100%'}
          height={5}
          color={'var(--primary-color)'}
          cssOverride={{ borderRadius: 8 }}
        />
      </div>
    )
  } else
    return (
      <div className={styles.quoteBar}>
        {data?.companyData?.profile?.image &&
        !data?.companyData?.profile?.defaultImage ? (
          // eslint-disable-next-line jsx-a11y/alt-text
          <img
            src={data?.companyData?.profile?.image}
            className={styles.quoteBarCompanyLogo}
          />
        ) : null}
        <TickerSearchView updateTicker={updateTicker} ticker={ticker} />
        <div className={styles.quoteBarItemWrapperStockPrice}>
          <div className={styles.quoteBarPrice}>
            <span
              className={`${
                priceDirection === 'up'
                  ? styles.up
                  : priceDirection === 'down'
                  ? styles.down
                  : ''
              }`}
              style={{ padding: '0 1px' }}
            >
              {numeral(data?.stockPrice?.price).format('0,0.00')}
            </span>
            <span className={styles.quoteBarChange}>
              <FormattedSpanPrecentage
                value={numeral(data?.stockPrice?.change).format('0.00')}
                sign={'%'}
                changesPercentage={numeral(
                  data?.stockPrice?.changesPercentage
                ).format('0.00')}
              />
            </span>
          </div>
          <div className={styles.quoteBarTimeStamp}>
            {`(${data?.companyData?.profile?.currency}) Last Updated: ${updatedTimeStamp}`}
          </div>
        </div>
        {!scrollPosition.atStart && (
          <>
            <button
              className={styles.arrowButton}
              onClick={() => scrollContent('left')}
            >
              <img
                src={leftArrow}
                alt="LeftArrow"
                style={{ width: '20px', height: '20px' }}
              />
            </button>
          </>
        )}
        <div className={styles.content1Wrapper} ref={containerRef}>
          {quoteBarItems.map((metric, i) => (
            <div key={i} className={styles.dividerWrapper}>
              <div className={styles.quoteBarItemWrapper}>
                <div className={styles.quoteBarItemValue}>{metric.value}</div>
                <div className={styles.quoteBarItemLabel}>{metric.label}</div>
              </div>
              {i !== quoteBarItems?.length - 1 && (
                <div className={styles.divider} />
              )}
            </div>
          ))}
        </div>
        {!scrollPosition.atEnd && (
          <>
            <button
              className={styles.arrowButton}
              onClick={() => scrollContent('right')}
            >
              <img
                src={rightArrow}
                alt="RightArrow"
                style={{ width: '20px', height: '20px' }}
              />
            </button>
          </>
        )}
      </div>
    )
}
