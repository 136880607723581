/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import dayjs from 'dayjs'
import './styles.scss'
import {
  AreaChart,
  XAxis,
  YAxis,
  Tooltip,
  Area,
  Legend,
  CartesianGrid,
  ReferenceLine,
  Bar,
  BarChart,
  Line,
  LineChart,
} from 'recharts'
import numeral from 'numeral'
import { useState, useEffect } from 'react'
import Select from 'react-select'
import { AnnualOptions } from 'core/OutseekPeriodSelects/constants'
import { renderLegend, renderTooltipContent, toPercent } from './utils'
import {
  AREA_PERCENT_CHART,
  LINE_CHART,
  STACKED_AREA_CHART,
  STACKED_BAR_CHART,
  colorScheme,
  colorSchemeFill,
  breakdownSelectStyles,
  options,
} from './constants'
import http from 'network/httpClient'
import CoreButton from 'core/Button/CoreButton'
const INIT_TIMELINE = {
  label: '5Y',
  value: 5,
}

const QUARTER = 'Quarter'
const ANNUAL = 'Annual'

export const ConfigurableBreakdownChart = ({
  breakdownChartConfig,
  ticker,
}) => {
  const [data, setData] = useState([])
  const [type, setType] = useState(options[0])

  const { api_call_annual, api_call_quarter, metric_keys } =
    breakdownChartConfig
  const [timeline, setTimeline] = useState(INIT_TIMELINE)
  const [range, setRange] = useState(INIT_TIMELINE.value)
  const [call, setCall] = useState(api_call_annual)

  useEffect(() => {
    http.get(call.replace('AAPL', ticker)).then(data_raw => {
      setData(data_raw.data.message.slice(0, range).reverse())
    })
  }, [ticker, call, range])

  useEffect(() => {
    if (call === api_call_quarter) {
      setRange(timeline.value * 4)
    } else {
      setRange(timeline.value)
    }
  }, [timeline, call])

  const FrequencyButton = ({ type, label }) => {
    return (
      <CoreButton
        styles={{
          background: call === type ? 'var(--grey-accent)' : null,
          borderRadius: '4px',
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          height: 20,
          padding: 10,
        }}
        onClick={() => setCall(type)}
      >
        {`${label} View`}
      </CoreButton>
    )
  }

  const TopBar = () => {
    return (
      <div className="breakdownToolBar">
        <div className="DropDownWrapper">
          <Select
            placeholder={'Chart Type'}
            options={options}
            styles={breakdownSelectStyles}
            isSearchable={false}
            value={type}
            onChange={e => setType(e)}
          />
          <Select
            placeholder={'View'}
            options={AnnualOptions}
            styles={breakdownSelectStyles}
            isSearchable={false}
            value={timeline}
            onChange={e => setTimeline(e)}
          />
        </div>
        <div className="buttonWrapper">
          <FrequencyButton type={api_call_annual} label={ANNUAL} />
          <FrequencyButton type={api_call_quarter} label={QUARTER} />
        </div>
      </div>
    )
  }
  switch (type.value) {
    case STACKED_BAR_CHART:
      return (
        <>
          <div className="breakdownConatiner">
            <TopBar />
            <BarChart
              width={608}
              height={252}
              data={data}
              margin={{ top: 0, right: 0, left: -15, bottom: 0 }}
            >
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#8884d8" stopOpacity={0.5} />
                  <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.5} />
                  <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                </linearGradient>
              </defs>
              <XAxis
                dataKey="date"
                tickLine={{ stroke: 'var(--grey-accent)' }}
                axisLine={{ stroke: 'var(--grey-accent)' }}
                tick={{
                  stroke: 'var(--grey-accent)',
                  fontWeight: 300,
                  fontSize: '11px',
                }}
                style={{ fontSize: '11px' }}
                tickFormatter={tick => {
                  return dayjs(tick).format('MMM, YY')
                }}
              />
              <YAxis
                width={72}
                tick={{ stroke: 'var(--grey-accent)', fontWeight: 300 }}
                tickFormatter={tick => {
                  return numeral(tick).format('0.0 a')
                }}
              />
              <ReferenceLine y={0} stroke="#000" />
              <Legend
                margin={{ top: 0, left: 24, right: 0, bottom: 0 }}
                verticalAlign="top"
                height={46}
                content={renderLegend}
              />
              <CartesianGrid
                style={{ stroke: 'rgba(215, 215, 215, 0.2)' }}
                vertical={false}
              />
              <Tooltip
                itemStyle={{ fontSize: '12px' }}
                labelStyle={{ fontSize: '12px', color: '#000' }}
                contentStyle={{
                  background: 'rgba(215,215,215, 0.5)',
                  border: '0.5px solid var(--off-white)',
                  borderRadius: '4px',
                  padding: '4px',
                  margin: '0',
                }}
                content={renderTooltipContent}
              />
              {metric_keys.map((metric, i) => {
                return (
                  <Bar
                    stackId="a"
                    type="monotone"
                    dataKey={metric}
                    fillOpacity={1}
                    fill={colorSchemeFill[i % 8]}
                  />
                )
              })}
            </BarChart>
          </div>
        </>
      )
    case STACKED_AREA_CHART:
      return (
        <>
          <div className="breakdownConatiner">
            <TopBar />
            <AreaChart
              width={608}
              height={252}
              data={data}
              margin={{ top: 0, right: 0, left: -15, bottom: 0 }}
            >
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#8884d8" stopOpacity={0.5} />
                  <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.5} />
                  <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                </linearGradient>
              </defs>
              <XAxis
                dataKey="date"
                tickLine={{ stroke: 'var(--grey-accent)' }}
                axisLine={{ stroke: 'var(--grey-accent)' }}
                tick={{
                  stroke: 'var(--grey-accent)',
                  fontWeight: 300,
                  fontSize: '11px',
                }}
                style={{ fontSize: '11px' }}
                tickFormatter={tick => {
                  return dayjs(tick).format('MMM, YY')
                }}
              />
              <YAxis
                width={72}
                tick={{ stroke: 'var(--grey-accent)', fontWeight: 300 }}
                tickFormatter={tick => {
                  return numeral(tick).format('0.0 a')
                }}
              />
              <ReferenceLine y={0} stroke="#000" />
              <CartesianGrid strokeDasharray="4" vertical={false} />
              <Legend
                iconType="line"
                iconSize="12"
                verticalAlign="top"
                height={46}
                content={renderLegend}
              />
              <Tooltip
                itemStyle={{ fontSize: '12px' }}
                labelStyle={{ fontSize: '12px', color: '#000' }}
                contentStyle={{
                  background: 'rgba(215,215,215, 0.5)',
                  border: '0.5px solid var(--off-white)',
                  borderRadius: '4px',
                  padding: '4px',
                  margin: '0',
                }}
                content={renderTooltipContent}
              />
              {metric_keys.map((metric, i) => {
                return (
                  <Area
                    type="monotone"
                    dataKey={metric}
                    stroke={colorSchemeFill[i % 8]}
                    fillOpacity={1}
                    fill={colorSchemeFill[i % 8]}
                  />
                )
              })}
            </AreaChart>
          </div>
        </>
      )

    case LINE_CHART:
      return (
        <>
          <div className="breakdownConatiner">
            <TopBar />
            <LineChart
              width={608}
              height={252}
              data={data}
              margin={{ top: 0, right: 0, left: -15, bottom: 0 }}
            >
              <XAxis
                dataKey="date"
                tickLine={{ stroke: 'var(--grey-accent)' }}
                axisLine={{ stroke: 'var(--grey-accent)' }}
                tick={{
                  stroke: 'var(--grey-accent)',
                  fontWeight: 300,
                  fontSize: '11px',
                }}
                style={{ fontSize: '11px' }}
                tickFormatter={tick => {
                  return dayjs(tick).format('MMM, YY')
                }}
              />

              <YAxis
                width={72}
                tick={{ stroke: 'var(--grey-accent)', fontWeight: 300 }}
                tickFormatter={tick => {
                  return numeral(tick).format('0.0 a')
                }}
              />
              <ReferenceLine y={0} stroke="#000" />
              <CartesianGrid strokeDasharray="4" vertical={false} />
              <Legend
                iconType="line"
                iconSize="12"
                verticalAlign="top"
                height={46}
                content={renderLegend}
              />
              <Tooltip
                itemStyle={{ fontSize: '12px' }}
                labelStyle={{ fontSize: '12px', color: '#000' }}
                contentStyle={{
                  background: 'rgba(215,215,215, 0.5)',
                  border: '0.5px solid var(--off-white)',
                  borderRadius: '4px',
                  padding: '4px',
                  margin: '0',
                }}
                content={renderTooltipContent}
              />
              {metric_keys.map((metric, i) => {
                return (
                  <Line
                    type="monotone"
                    dot={false}
                    dataKey={metric}
                    stroke={colorScheme[i % 8]}
                    fillOpacity={1}
                    fill={colorScheme[i % 8]}
                  />
                )
              })}
            </LineChart>
          </div>
        </>
      )
    case AREA_PERCENT_CHART:
      return (
        <>
          <div className="breakdownConatiner">
            <TopBar />
            <AreaChart
              width={608}
              height={252}
              data={data}
              stackOffset="expand"
              margin={{ top: 0, right: 0, left: -15, bottom: 0 }}
            >
              <XAxis
                dataKey="date"
                tickLine={{ stroke: 'var(--grey-accent)' }}
                axisLine={{ stroke: 'var(--grey-accent)' }}
                tick={{
                  stroke: 'var(--grey-accent)',
                  fontWeight: 300,
                  fontSize: '11px',
                }}
                style={{ fontSize: '11px' }}
                tickFormatter={tick => {
                  return dayjs(tick).format('MMM, YY')
                }}
              />
              <YAxis
                width={72}
                tickFormatter={toPercent}
                tick={{ stroke: 'var(--grey-accent)', fontWeight: 300 }}
              />
              <ReferenceLine y={0} stroke="#000" />
              <Legend
                iconType="line"
                iconSize="12"
                verticalAlign="top"
                height={46}
                content={renderLegend}
              />
              <CartesianGrid strokeDasharray="4" vertical={false} />
              <Tooltip
                itemStyle={{ fontSize: '12px' }}
                labelStyle={{ fontSize: '12px', color: '#000' }}
                contentStyle={{
                  background: 'rgba(215,215,215, 0.5)',
                  border: '0.5px solid var(--off-white)',
                  borderRadius: '4px',
                  padding: '4px',
                  margin: '0',
                }}
                content={renderTooltipContent}
              />
              {metric_keys.map((metric, i) => {
                return (
                  <Area
                    type="monotone"
                    dataKey={metric}
                    stroke={colorSchemeFill[i % 8]}
                    stackId="a"
                    fillOpacity={1}
                    fill={colorSchemeFill[i % 8]}
                  />
                )
              })}
            </AreaChart>
          </div>
        </>
      )
    default:
      return
  }
}

export default React.memo(ConfigurableBreakdownChart)
