import { ViewCompanyQuote } from './components/ViewCompanyQuote/ViewCompanyQuote'
import { DashboardCompanyQuote } from './components/DashboardCompanyQuote/DashboardCompanyQuote'

export const CompanyQuote = ({ ticker, isView }) => {
  if (isView) {
    return <ViewCompanyQuote ticker={ticker} />
  } else {
    return <DashboardCompanyQuote ticker={ticker} />
  }
}
