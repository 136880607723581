import dayjs from 'dayjs'
import { UilArrowRight } from '@iconscout/react-unicons'
import TooltipTicker from 'core/TickerOverlay/TickerOverlay'

const isDataThisYear = data => {
  const currentYear = new Date().getFullYear()
  const dataYear = new Date(data).getFullYear()
  return currentYear === dataYear
}

export const mergerColumns = [
  {
    Header: 'Symbol',
    accessor: 'symbol',
    width: 'fit-content',
    Cell: Cell => {
      return <TooltipTicker ticker={Cell.value} />
    },
  },
  {
    Header: 'Company Name',
    accessor: 'companyName',
    width: 'fit-content',
  },
  {
    Header: '',
    accessor: 'targetedCik',
    width: 'fit-content',
    Cell: ({ value }) => {
      return <UilArrowRight style={{ color: 'var(--primary-color)' }} />
    },
  },
  {
    Header: 'Targeted Symbol',
    accessor: 'targetedSymbol',
    width: 'fit-content',
    Cell: Cell => {
      return <TooltipTicker ticker={Cell.value} />
    },
  },
  {
    Header: 'Targeted Company Name',
    accessor: 'targetedCompanyName',
    width: 'fit-content',
  },
  {
    Header: 'Transaction Date',
    accessor: 'transactionDate',
    Cell: ({ value }) => {
      if (isDataThisYear(value)) return dayjs(value).format('MMM DD')
      else return dayjs(value).format('MMM DD, YYYY')
    },
    width: 'fit-content',
  },
  // {
  //   Header: 'Acceptance Time',
  //   accessor: 'acceptanceTime',
  //   Cell: ({ value }) => {
  //     if (isDataThisYear(value))
  //       return dayjs(value).format('MMM DD, hh:mm:ss A')
  //     else return dayjs(value).format('MMM DD, YYYY hh:mm:ss A')
  //   },
  //   width: 'fit-content',
  // },
  {
    Header: 'Link',
    accessor: 'url',
    width: 'fit-content',
    Cell: ({ value }) => {
      return (
        <a
          style={{
            color: 'var(--primary-color)',
          }}
          href={value}
          target={'_blank'}
          rel="noreferrer"
        >
          <span>Link</span>
        </a>
      )
    },
  },
]
