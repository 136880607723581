import { groupBy, countBy, sortBy, head, sumBy, last } from 'lodash'
import dayjs from 'dayjs'

const mostFrequentNewsSite = data => {
  if (!data || data.length === 0) {
    return null
  }

  let countBySite = countBy(data, entry => entry?.site)
  let mostFreqSite = sortBy(
    Object.keys(countBySite).map(key => {
      return { site: key, count: countBySite[key] }
    }),
    'count'
  )
  return last(mostFreqSite)
}

const mostCommonPostTimeOfDay = data => {
  if (!data || data.length === 0) {
    return null
  }

  let hours = data.map(entry => dayjs(entry?.publishedDate)?.hour())
  let countByHour = countBy(hours)
  let arrayOfHours = Object.keys(countByHour).map(key => {
    return { hour: parseInt(key, 10), count: countByHour[key] }
  })
  let sortedByHour = sortBy(arrayOfHours, 'count')
  let mostCommonHour = last(sortedByHour)

  if (!mostCommonHour) {
    return null
  }

  let timeOfDay = dayjs().hour(mostCommonHour.hour).format('ha')
  return timeOfDay
}

const averageNewsPosts = data => {
  let getDay = data?.map(entry => entry?.publishedDate?.slice(0, 10))
  let avePostsPerDay = countBy(getDay)
  return (
    Object.values(avePostsPerDay)?.reduce((a, b) => a + b, 0) /
    Object.values(avePostsPerDay)?.length
  )
}

const totalNewsArticles = data => {
  return data?.length
}

const newsArticlesPostedToday = data => {
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  const todayStr = today.toISOString().slice(0, 10)

  return data.filter(entry => {
    if (!entry || !entry.publishedDate) return false

    const entryDate = new Date(entry.publishedDate)
    const entryDateLocal = new Date(
      entryDate.getTime() - entryDate.getTimezoneOffset() * 60000
    )
    return entryDateLocal.toISOString().slice(0, 10) === todayStr
  }).length
}

const newsArticleCountGroupedByDay = data => {
  let getDay = data?.map(entry => entry?.publishedDate?.slice(0, 10))
  let countPostsByDay = countBy(getDay)
  return Object.keys(countPostsByDay)
    ?.reverse()
    ?.map(key => {
      return { count: countPostsByDay[key], date: key }
    })
}

const percentChangeNewsVolume = data => {
  let getDay = data?.map(entry => entry?.publishedDate?.slice(0, 10))
  let countPostsByDay = countBy(getDay)
  let headValue = head(Object.values(countPostsByDay))
  let tailValue = last(Object.values(countPostsByDay))
  return (headValue - tailValue) / tailValue
}

const getLastDay = data => {
  let getDay = data?.map(entry => entry?.publishedDate?.slice(0, 10))
  let lastDay = last(getDay)
  let date1 = new Date(lastDay)
  let date2 = new Date()
  let differenceInTime = date2?.getTime() - date1?.getTime()
  return differenceInTime / (1000 * 3600 * 24)
}

const averageSocialPosts = data => {
  let getDay = data?.map(entry => {
    return {
      date: entry?.date?.slice(0, 10),
      postsCount: entry?.stocktwitsPosts + entry?.twitterPosts,
    }
  })
  let groupByDay = groupBy(getDay, entry => entry?.date)
  let sumByDay = Object.keys(groupByDay)?.map(entry => {
    return {
      date: entry,
      count: sumBy(groupByDay[entry], day => day?.postsCount),
    }
  })
  return sumByDay?.reduce((a, b) => a + b?.count, 0) / sumByDay?.length
}

const buildTrendingUI = (trending, change) => {
  const UI_change = change?.map(query => {
    return {
      data: query?.data?.data?.slice(0, 100),
      source: query?.data?.source,
      trend: query?.data?.trend,
    }
  })
  const UI_trend = trending?.map(query => {
    return {
      data: query?.data?.data?.slice(0, 100),
      source: query?.data?.source,
      trend: query?.data?.trend,
    }
  })
  return {
    change: UI_change,
    trend: UI_trend,
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  averageNewsPosts,
  totalNewsArticles,
  percentChangeNewsVolume,
  mostFrequentNewsSite,
  averageSocialPosts,
  newsArticleCountGroupedByDay,
  buildTrendingUI,
  getLastDay,
  newsArticlesPostedToday,
  mostCommonPostTimeOfDay,
}
