import { startCase } from 'lodash'

export const displayCheck = check => {
  if (check.compare) {
    return (
      <span>
        {startCase(check.type)} is {check.criteria} {check.compare}
      </span>
    )
  }
  if (check.analytics) {
    return (
      <span>
        {startCase(check.type)} is {check.criteria} the Analytical Average but
        not below {check.stopLoss}
      </span>
    )
  }
  switch (check.criteria) {
    case 'over':
      return (
        <span>
          {startCase(check.type)} is {check.criteria} {check.threshold}
        </span>
      )
    case 'under':
      return (
        <span>
          {startCase(check.type)} is {check.criteria} {check.threshold}
        </span>
      )
    case 'range':
      return (
        <span>
          {startCase(check.type)} is between {check.threshold[0]} and{' '}
          {check.threshold[1]}
        </span>
      )
    default:
      return (
        <span>
          {startCase(check.type)} is {check.criteria}, Threshold -{' '}
          {check.threshold}
        </span>
      )
  }
}
