import React from 'react'
import styles from './PricingBreakdown.module.scss'
import { planFeatures, plans } from './pricingBreakdownConfig'
import { renderFeatureValue } from './utils'
import { ToggleSwitch } from '../PriceTable/components/Switch'
import { useMediaQuery } from 'react-responsive'

export const PricingBreakdown = ({ isYearly, setIsYearly }) => {
  const isTablet = useMediaQuery({ maxWidth: 1100 })

  const isLastFeature = featureIndex => featureIndex === planFeatures.length - 1

  if (isTablet) {
    return (
      <table className={styles.pricingTable}>
        <thead>
          <tr>
            {plans.map(plan => (
              <th key={plan.name} className={styles.headerNames}>
                <div className={styles.planHeader}>
                  <span className={styles.planName}>{plan.name}</span>
                  <span className={styles.planPrice}>
                    {isYearly ? plan.annual : plan.monthly}
                  </span>
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {planFeatures.map((section, index) => (
            <React.Fragment key={section.section}>
              <tr className={styles.sectionHeader}>
                <td colSpan={3} className={styles.tableDataTablet}>
                  {section.section}
                </td>
              </tr>
              {section.features.map((feature, index) => (
                <>
                  <tr key={feature.label}>
                    <td
                      data-label="Feature"
                      colSpan={3}
                      className={styles.tableDataLabel}
                    >
                      {feature.label}
                    </td>
                  </tr>
                  <tr
                    key={feature.label}
                    className={styles.row}
                    style={{
                      border: index === section.features.length - 1 && 'none',
                    }}
                  >
                    {plans.map(plan => (
                      <td
                        key={plan.name}
                        data-label={plan.name}
                        className={styles.tableData}
                      >
                        {renderFeatureValue(feature[plan.name.toLowerCase()])}
                      </td>
                    ))}
                  </tr>
                </>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    )
  } else {
    return (
      <table className={styles.pricingTable}>
        <thead>
          <tr>
            <th>
              <ToggleSwitch
                isYearly={isYearly}
                setIsYearly={setIsYearly}
                tableMode={true}
              />
            </th>
            {plans.map(plan => (
              <th
                key={plan.name}
                className={
                  plan.name === 'Pro' ? styles.proHeader : styles.headerNames
                }
              >
                <div className={styles.planHeader}>
                  <span className={styles.planName}>{plan.name}</span>
                  <span className={styles.planPrice}>
                    {isYearly ? plan.annual : plan.monthly}
                  </span>
                  {/* <div
                    className={
                      plan.name === 'Pro'
                        ? styles.primeButton
                        : styles.getStartedButton
                    }
                  >
                    {plan.name === 'Free' ? 'Enter Terminal' : 'Select Plan'}
                  </div> */}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {planFeatures.map((section, index) => (
            <React.Fragment key={section.section}>
              <tr className={styles.sectionHeader}>
                <td>{section.section}</td>
                {plans?.map(plan => (
                  <td
                    key={plan.name}
                    className={
                      plan.name === 'Pro' ? styles.proSectionHeader : ''
                    }
                  ></td>
                ))}
              </tr>
              {section.features.map((feature, featureIndex) => (
                <tr key={feature.label}>
                  <td data-label="Feature" className={styles.tableDataLabel}>
                    {feature.label}
                  </td>
                  {plans.map(plan => (
                    <td
                      key={plan.name}
                      data-label={plan.name}
                      className={
                        plan.name === 'Pro'
                          ? `${styles.tableData} ${styles.proData} ${
                              isLastFeature(featureIndex)
                                ? styles.proLastCell
                                : ''
                            }`
                          : styles.tableData
                      }
                    >
                      {renderFeatureValue(feature[plan.name.toLowerCase()])}
                    </td>
                  ))}
                </tr>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    )
  }
}
