export const BlockTableStyles = {
  _table: {
    backgroundColor: 'var(--background-primary)',
    overflowY: 'scroll',
  },
  _th: {
    paddingLeft: '1.5rem',
    paddingRight: '12px',
    fontWeight: 700,
    fontSize: '12px',
    backgroundColor: 'var(--background-primary)',
    zIndex: 1000,
    color: 'var(--white)',
  },
  _tr_Head: {
    height: '4px',
    width: '100%',
    backgroundColor: 'var(--background-primary)',
  },
  _secondColumnTd: {
    paddingLeft: '1.5rem',
    paddingRight: '12px',
    fontWeight: 'var(--bold-text)',
    fontSize: '12px',
    width: '300px',
    overflowX: 'hidden',
    backgroundColor: '',
    height: '32px',
    whiteSpace: 'nowrap',
    color: 'var(--white)',
  },
  _td: {
    paddingLeft: '1.5rem',
    paddingRight: '12px',
    fontWeight: 'var(--bold-text)',
    fontSize: '12px',
    maxWidth: '300px',
    overflowX: 'hidden',
    backgroundColor: '',
    height: '32px',
    whiteSpace: 'nowrap',
    color: 'var(--white)',
  },
  _expandedTdDi: {
    width: '12px',
  },
}
