import React, { useEffect, useRef, useState } from 'react'
import * as d3 from 'd3'
import numeral from 'numeral'
import {
  drawSolidLines,
  drawDashedLines,
  calculateStartEndFirstPass,
  calculateStartEndSecondPass,
} from './utils'
import { majorCategories } from './utils'

const Waterfall = ({ data, dataChanged, labelPosition, barColor }) => {
  const ref = useRef()
  const containerRef = useRef()

  const [dimensions, setDimensions] = useState({
    width: window?.innerWidth,
    height: window?.innerHeight,
  })

  useEffect(() => {
    const ro = new ResizeObserver(([entry]) => {
      setDimensions({
        width: entry.contentRect.width,
        height: entry.contentRect.height,
      })
    })

    if (containerRef.current) {
      ro.observe(containerRef.current)
    }

    return () => {
      if (ro && containerRef.current) {
        ro.unobserve(containerRef.current)
      }
    }
  }, [])

  return {
    containerRef,
    dimensions,
  }

  //   // TODO: memoize
  //   const drawChart = (data, dimensions, chartId) => {
  //     const labelSpace = calculateLabelWidth(data)
  //     const margin = { top: 30, right: 30, bottom: 30, left: labelSpace }
  //     // const chartSpacing = dimensions.width * 0.08
  //     const chartSpacing = dimensions.width * 0.08

  //     const width1 =
  //       (dimensions.width - margin.left - margin.right - chartSpacing) * 1
  //     const width2 =
  //       (dimensions.width - margin.left - margin.right - chartSpacing) * 0.26
  //     const width = chartId === 'chart1' ? width1 : width2
  //     const height = dimensions.height - margin.top - margin.bottom

  //     const y = d3.scaleBand().rangeRound([0, height]).padding(0.2)

  //     const x = d3.scaleLinear().range([0, width])

  //     const yAxis = d3.axisLeft(y).tickFormat(i => data[i].category)
  //     const xAxis = d3.axisBottom(x).tickFormat(d => numeral(d).format('0.00 a'))

  //     const svg = d3
  //       .select(ref.current)
  //       .attr('width', '100%')
  //       .attr('height', '100%')
  //       .attr('viewBox', `0 0 ${dimensions.width} ${dimensions.height}`)
  //       .append('g')
  //       .attr(
  //         'transform',
  //         `translate(${
  //           margin.left + (chartId === 'chart1' ? 0 : width1 + chartSpacing)
  //         }, ${margin.top})`
  //       )

  //     calculateStartEndFirstPass(data)
  //     calculateStartEndSecondPass(data)

  //     const labels = svg.append('g')

  //     y.domain(Array.isArray(data) ? data.map((j, i) => i) : [])

  //     x.domain([
  //       Array.isArray(data) ? d3.min(data, d => Math.min(0, d.end * 1.04)) : 0,
  //       Array.isArray(data) ? d3.max(data, d => d.end * 1.04) : 0,
  //     ])

  //     if (chartId === 'chart1') {
  //       labels
  //         .call(yAxis)
  //         .selectAll('text')
  //         .style('font-size', '14px')
  //         .style('text-anchor', 'start')
  //         .attr('x', -labelSpace)
  //         .attr('font-weight', d =>
  //           majorCategories.includes(data[d]?.category) ? '600' : ''
  //         )
  //         .attr('fill', d =>
  //           majorCategories.includes(data[d]?.category)
  //             ? 'var(--white)'
  //             : 'var(--off-white)'
  //         )
  //     }

  //     svg
  //       .append('g')
  //       .call(d3.axisLeft(y).tickSize(0).tickFormat(''))
  //       .attr('transform', `translate(${x(0)}, 0)`)

  //     const bar = svg
  //       .selectAll('.bar')
  //       .data(data)
  //       .enter()
  //       .append('g')
  //       .attr('transform', (d, i) => `translate(0, ${y(i)})`)

  //     bar
  //       .append('rect')
  //       .attr('x', 0)
  //       .attr('y', -1)
  //       .attr('width', dimensions.width)
  //       .attr('height', y.bandwidth() + 2)
  //       .attr('fill', 'transparent')
  //       .attr('class', 'bar-background')

  //     bar
  //       .append('rect')
  //       .attr('x', d => x(Math.min(d.start, d.end)))
  //       .attr('width', d => Math.abs(x(d.start) - x(d.end)))
  //       .attr('height', y.bandwidth())
  //       .attr('fill', d => {
  //         if (barColor === 'blue') {
  //           return 'var(--primary-color)'
  //         } else if (barColor === 'default') {
  //           return d.color
  //             ? d.color
  //             : d.value >= 0
  //             ? 'var(--green)'
  //             : 'var(--red)'
  //         }
  //       })

  //     drawSolidLines(bar, data, x, y, svg)
  //     drawDashedLines(bar, data, x, y, svg)

  //     bar
  //       .on('mouseover', function (d, i) {
  //         if (chartId === 'chart1') {
  //           d3.select(this)
  //             .select('.bar-background')
  //             .attr('fill', 'var(--light-grey-accent)')
  //         }
  //       })
  //       .on('mouseout', function (d, i) {
  //         if (chartId === 'chart1') {
  //           d3.select(this).select('.bar-background').attr('fill', 'transparent')
  //         }
  //       })

  //     const labelGroup = bar.append('g')

  //     labelGroup
  //       .append('text')
  //       .attr('x', d => {
  //         if (labelPosition === 'left') {
  //           return d.value < 0 ? x(d.end) : x(d.start)
  //         } else {
  //           return d.value < 0 ? x(d.start) : x(d.end)
  //         }
  //       })
  //       .attr('y', y.bandwidth() / 2)
  //       .attr('dy', '.35em')
  //       .attr('dx', '.75em')
  //       .attr('fill', 'white')
  //       .attr('font-size', '13px')
  //       .text(d =>
  //         chartId === 'chart1'
  //           ? numeral(d.value).format('$0.00 a')
  //           : `${numeral(d.value).format('0.00 a')}%`
  //       )
  //   }

  //   return (
  //     <div ref={containerRef} style={{ width: '100%', height: '100%' }}>
  //       <svg ref={ref}></svg>
  //     </div>
  //   )
  // }
}

export default Waterfall
