export const summarizeChat = async aggregatedText => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_ORIGIN}api/summarize-chat`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ prompt: aggregatedText }),
      }
    )

    const { output } = await response.json()
    return output
  } catch (error) {
    console.error('Error summarizing chat:', error)
    return 'Chat Summary'
  }
}


  export const cleanName = name => {
    if (Array.isArray(name)) {
      return name.join('').replace(/,/g, '').trim()
    }
    return String(name).replace(/,/g, '').trim()
  }