import styles from './ErrorBoundary.module.scss'
import { ErrorChart } from './ChartingError.js'

const ExportBoundary = () => {
  const handleRefresh = () => {
    window.location.reload()
  }

  return (
    <div className={styles.errorBoundaryContainer}>
      <div className={styles.errorBoundaryInfo}>
        <div className={styles.errorBoundaryInfoTextWrapper}>
          <div className={styles.errorBoundaryInfoTitle}>An Error Occurred</div>
          <div className={styles.errorBoundaryInfoText}>
            Try
            <span
              className={styles.errorBoundaryInfoRefresh}
              onClick={handleRefresh}
            >
              refreshing
            </span>
            the page. If the error persists, please contact us.
          </div>
        </div>
        <div className={styles.errorBoundaryImage}>
          <ErrorChart />
        </div>
      </div>
    </div>
  )
}

export default ExportBoundary
