/* eslint-disable no-console */
import React, { useState } from 'react'
import { CoreButton } from '../../../../core/Button/CoreButton'
import './contact.scss'
import http from '../../../../network/httpClient'
import { Helmet } from 'react-helmet-async'

export const contactForm = async message => {
  try {
    const response = await http.post('/api/create/customer/message', {
      message,
    })
    console.log(response.data)
  } catch (error) {
    console.log('error', error)
  }
}

const ContactForm = ({ info, type }) => {
  const [message, setMessage] = useState('')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const handleSubmit = event => {
    if (!message || !name || !email) {
      alert('Please fill out all fields')
      return
    }
    const Name = '|| Name: ' + name + ' '
    const Email = '|| Email: ' + email + ' '
    const Message = '|| Message: ' + message + ' '
    event.preventDefault()
    contactForm(type + Name + Email + Message)
    setMessage('')
    setName('')
    setEmail('')
  }

  return (
    <div className="contact-form-wrapper">
      <Helmet>
        <title>{`Outseek | Contact Us`}</title>
        <meta
          name="description"
          content={
            'Contact Outseek for feedback, feature requests, or general inquiries. We are always looking to improve our platform and would love to hear from you.'
          }
        />
      </Helmet>
      <div className="contact-form">
        <div className="contact-form-title">{info.title}</div>
        <div className="contact-form-subtitle">{info.subtitle}</div>
        <textarea
          id="name"
          name="name"
          value={name}
          placeholder="Name"
          onChange={event => setName(event.target.value)}
        />
        <textarea
          id="email"
          name="email"
          value={email}
          placeholder="Email"
          onChange={event => setEmail(event.target.value)}
        />
        <textarea
          id="message"
          name="message"
          value={message}
          placeholder="Message"
          onChange={event => setMessage(event.target.value)}
        />
        <div className="button-wrapper">
          <CoreButton onClick={handleSubmit} color={'var(--primary-color)'}>
            Send Message
          </CoreButton>
        </div>
      </div>
    </div>
  )
}

export const Feedback = () => {
  return (
    <ContactForm
      info={{
        title: 'Feedback',
        subtitle: 'Let us know what you think:',
      }}
      type="Feedback "
    />
  )
}

export const FeatureRequest = () => {
  return (
    <ContactForm
      info={{
        title: 'Feature Request',
        subtitle: 'Tell us about your feature in detail:',
      }}
      type="Feature Request "
    />
  )
}

export const ContactUs = () => {
  return (
    <ContactForm
      info={{
        title: 'Contact Us',
        subtitle: 'Your Message:',
      }}
      type="Contact Us "
    />
  )
}
