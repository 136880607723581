// ChatListSection.js
import React from 'react'
import styles from './styles.module.scss'
import { UilTrashAlt } from '@iconscout/react-unicons'

const ChatListSection = ({
  title,
  chats,
  isSidebarVisible,
  currentChat,
  setCurrentChat,
  deleteChatHandler,
}) => (
  <>
    {chats?.length > 0 && (
      <>
        <div className={styles.divider}>{title}</div>
        <ul>
          {chats?.map((chat, index) => {
            const currentChatId = currentChat?.chatId
            const chatId = chat?.chatId
            const isCurrentChat = currentChatId === chatId

            return (
              <li
                key={index}
                className={`${styles.chatCard} ${
                  !isSidebarVisible && styles.chatCardClosed
                }`}
                onClick={() => setCurrentChat(chat)}
                style={{
                  backgroundColor: isCurrentChat
                    ? 'var(--background-tertiary)'
                    : 'transparent',
                }}
              >
                <div className={styles.chatName}>{chat?.name}</div>
                <div className={styles.chatActions}>
                  <UilTrashAlt
                    onClick={e => {
                      e.stopPropagation()
                      deleteChatHandler(chatId)
                    }}
                    size={20}
                    color="var(--red)"
                    data-tip="Delete Chat"
                  />
                </div>
              </li>
            )
          })}
        </ul>
      </>
    )}
  </>
)

export default ChatListSection
