export const ErrorChart = () => {
  const color = 'var(--primary-color)'
  return (
    <svg
      width="534"
      height="569"
      viewBox="0 0 534 569"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line x1="84.8512" y1="27" x2="84.8512" y2="169" stroke={color} />
      <line x1="58.7142" y1="47" x2="58.7142" y2="159" stroke={color} />
      <line x1="31.3892" y1="47" x2="31.3892" y2="141" stroke={color} />
      <line x1="6.44022" y1="59" x2="6.44022" y2="206" stroke={color} />
      <line
        x1="137.125"
        y1="-1.83964e-08"
        x2="137.125"
        y2="162"
        stroke={color}
      />
      <line x1="109.8" y1="59" x2="109.8" y2="148" stroke={color} />
      <line x1="162.074" y1="95" x2="162.074" y2="215" stroke={color} />
      <line x1="264.246" y1="87" x2="264.246" y2="234" stroke={color} />
      <line x1="368.794" y1="194" x2="368.794" y2="340" stroke={color} />
      <line x1="423.444" y1="206" x2="423.444" y2="352" stroke={color} />
      <line x1="345.033" y1="173" x2="345.033" y2="319" stroke={color} />
      <line x1="317.708" y1="205" x2="317.708" y2="305" stroke={color} />
      <line x1="291.571" y1="148" x2="291.571" y2="279" stroke={color} />
      <line x1="241.673" y1="71" x2="241.673" y2="216" stroke={color} />
      <line
        y1="-0.5"
        x2="108.006"
        y2="-0.5"
        transform="matrix(-0.0109997 0.99994 -0.99997 -0.00779346 213.848 107)"
        stroke={color}
      />
      <line
        y1="-0.5"
        x2="80.0083"
        y2="-0.5"
        transform="matrix(0.0148489 0.99989 -0.999945 0.0105209 186.523 112)"
        stroke={color}
      />
      <rect x="78.4109" y="63" width="13.0685" height="86" fill={color} />
      <rect x="52.274" y="83" width="13.0685" height="48" fill={color} />
      <rect x="24.9489" y="95" width="13.0685" height="34" fill={color} />
      <rect y="112" width="13.0685" height="72" fill={color} />
      <rect x="103.36" y="83" width="13.0685" height="60" fill={color} />
      <rect x="130.685" y="44" width="13.0685" height="78" fill={color} />
      <rect x="155.634" y="107" width="13.0685" height="87" fill={color} />
      <rect x="180.583" y="149" width="13.0685" height="24" fill={color} />
      <rect x="206.72" y="129" width="13.0685" height="50" fill={color} />
      <rect x="235.233" y="83" width="13.0685" height="109" fill={color} />
      <rect x="257.806" y="133" width="13.0685" height="73" fill={color} />
      <rect x="285.131" y="159" width="13.0685" height="91" fill={color} />
      <rect x="311.268" y="237" width="13.0685" height="42" fill={color} />
      <rect x="338.593" y="215" width="13.0685" height="72" fill={color} />
      <rect x="362.354" y="246" width="13.0685" height="55" fill={color} />
      <line x1="396.119" y1="236" x2="396.119" y2="382" stroke={color} />
      <line x1="447.205" y1="242" x2="447.205" y2="388" stroke={color} />
      <line x1="474.53" y1="242" x2="474.53" y2="328" stroke={color} />
      <line x1="500.667" y1="201" x2="500.667" y2="317" stroke={color} />
      <line x1="526.804" y1="153" x2="526.804" y2="569" stroke={color} />
      <rect x="389.679" y="258" width="13.0685" height="124" fill={color} />
      <rect x="417.004" y="246" width="13.0685" height="49" fill={color} />
      <rect x="440.765" y="279" width="13.0685" height="86" fill={color} />
      <rect x="468.09" y="245" width="13.0685" height="70" fill={color} />
      <rect x="494.227" y="201" width="13.0685" height="69" fill={color} />
      <rect x="520.364" y="179" width="13.0685" height="381" fill={color} />
    </svg>
  )
}
