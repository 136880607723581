import styles from './calComp.module.scss'
import TooltipTicker from 'core/TickerOverlay/TickerOverlay'
import { upperCase } from 'lodash'
import { UilSun, UilMoon } from '@iconscout/react-unicons'
import numeral from 'numeral'

export const CalComp = ({ event }) => {
  const { symbol, eps, epsEstimated, revenue, revenueEstimated, time } = event

  const logo = `https://financialmodelingprep.com/image-stock/${symbol}.png`

  const filterValue = value => {
    if (value === null || value === 0) {
      return '-'
    }
    return value
  }

  const infoMap = [
    { info: 'EPS', value: filterValue(eps) },
    { info: 'EPS Est.', value: filterValue(epsEstimated) },
    { info: 'Revenue', value: numeral(filterValue(revenue)).format('0.00a') },
    {
      info: 'Revenue Est.',
      value: numeral(filterValue(revenueEstimated)).format('0.00a'),
    },
  ]

  return (
    <div className={styles.splitEventWrapper}>
      <div className={styles.topBar}>
        <TooltipTicker ticker={symbol} />
        <div className={styles.value}>
          {upperCase(time)}{' '}
          {time === 'bmo' && (
            <UilSun color="#fff466" size="16" style={{ marginBottom: 3 }} />
          )}
          {time === 'amc' && (
            <UilMoon color="#729CDA" size="16" style={{ marginBottom: 3 }} />
          )}
        </div>
      </div>
      {infoMap.map((item, index) => {
        return (
          <div className={styles.info}>
            <div className={styles.label}>{item.info}</div>
            <div className={styles.value}>{item.value}</div>
          </div>
        )
      })}
      {/* <img src={logo} alt="logo" className={styles.logo} /> */}
    </div>
  )
}

export const CalCompLogos = ({ event }) => {
  const { symbol, time } = event

  const logo = `https://financialmodelingprep.com/image-stock/${symbol}.png`

  return (
    <div className={styles.eventWrapper}>
      <img src={logo} alt="logo" className={styles.logo} />
      <div className={styles.tickerWrapper}>
        <TooltipTicker ticker={symbol} />
        {time === 'bmo' && (
          <UilSun color="#fff466" size="16" style={{ marginBottom: 3 }} />
        )}
        {time === 'amc' && (
          <UilMoon color="#729CDA" size="16" style={{ marginBottom: 3 }} />
        )}
      </div>
    </div>
  )
}
