import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { rgba } from 'polished'
import Flag from 'react-world-flags'
import styles from './calComp.module.scss'
dayjs.extend(localizedFormat)

const getColor = impact => {
  const impactColors = {
    Low: '#21ad3d',
    Medium: '#fff466',
    High: '#f84f31',
  }
  return impactColors[impact] || '#ffffff'
}

export const EventComponent = ({ event }) => {
  const {
    event: eventName,
    impact,
    actual,
    estimate,
    previous,
    unit,
    time,
    countryCode,
  } = event
  const color = getColor(impact)
  const backgroundColor = rgba(color, 0.3)

  const filterValue = value => {
    if (value === null || value === 0) {
      return '-'
    }
    return value
  }

  const displayVale = (value, value2) => {
    if (value === null || value === 0) {
      return null
    }
    return value2
  }

  return (
    <div className={styles.econEventWrapper}>
      <div className={styles.eventName}>
        {eventName} - <span className={styles.time}>{time}</span>
      </div>
      <div className={styles.eventDetails}>
        <div className={styles.info}>
          <div className={styles.label}>Prev.</div>
          <div className={styles.value}>
            {filterValue(previous)}
            {displayVale(previous, unit)}
          </div>
        </div>
        <div className={styles.info}>
          <div className={styles.label}>Est. </div>
          <div className={styles.value}>
            {filterValue(estimate)}
            {displayVale(estimate, unit)}
          </div>
        </div>
        <div className={styles.info}>
          <div className={styles.label}> Actual </div>
          <div className={styles.value}>
            {filterValue(actual)}
            {displayVale(actual, unit)}
          </div>
        </div>
      </div>
      <div className={styles.bottomWrapper}>
        <div
          style={{
            background: backgroundColor,
            textAlign: 'center',
            padding: '2px 4px',
            borderRadius: '4px',
            width: '100%',
          }}
        >
          <span style={{ color: color }}>{impact} Impact</span>
        </div>
        <Flag code={countryCode} height={18} />
      </div>
    </div>
  )
}
