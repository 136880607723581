export const buildBarChartData = data => {
  let flattened = data
    .map(entry => {
      return Object?.keys(entry).map(date => {
        return {
          date: date,
          ...entry[date],
        }
      })
    })
    .flat()
  return flattened.slice(0, 6)
}

export const buildRadialChartData = data => {
  let flattened = buildBarChartData(data)
  let firstEntry = flattened[0]
  let RadialChartData = Object.keys(firstEntry)
    .map(key => {
      return {
        segment: key,
        value: firstEntry[key],
      }
    })
    .filter(entry => entry.segment !== 'date')

  return RadialChartData
}

export const buildTableData = data => {
  let flattened = buildBarChartData(data)
  let firstEntry = flattened[0]
  delete firstEntry.date

  return firstEntry
}

export const getChartKeys = data => {
  let keys = new Set()
  let flattened = buildBarChartData(data)

  flattened.forEach(entry => {
    Object.keys(entry).forEach(key => {
      if (key !== 'date') {
        keys.add(key)
      }
    })
  })

  return keys
}
