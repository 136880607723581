/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'us-east-1',
  aws_cognito_identity_pool_id:
    'us-east-1:cf3b3780-a970-4aeb-88b8-b10d911d0a5b',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_RnCCIVDGP',
  aws_user_pools_web_client_id: '22fr5i311up333cqs19maggg90',
  oauth: {
    domain: 'auth.outseek.ca',
    scope: ['email', 'profile', 'openid'],
    redirectSignIn: 'http://localhost:3000/terminal/marketOverview',
    redirectSignOut: 'http://localhost:3000/',
    responseType: 'code',
    options: {},
  },
  aws_cognito_username_attributes: [],
  aws_cognito_social_providers: ['Google'],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OFF',
  aws_cognito_mfa_types: ['SMS'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
  aws_dynamodb_all_tables_region: 'us-east-1',
  aws_dynamodb_table_schemas: [
    {
      tableName: 'RootDB-dev',
      region: 'us-east-1',
    },
  ],
  aws_cloud_logic_custom: [
    {
      name: 'RootAPI',
      endpoint: 'https://00h1qndu3i.execute-api.us-east-1.amazonaws.com/dev',
      region: 'us-east-1',
    },
  ],
}

export default awsmobile
