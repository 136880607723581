import React, { useState, useEffect } from 'react'
import styles from './RevenueSeg.module.scss'
import { RadialChartWrapper } from './PieChart'
import { BarChartWrapper } from './BarChart'
import { GeoRevTable } from './GeoRevSegTable'

import {
  buildBarChartData,
  buildRadialChartData,
  buildTableData,
  getChartKeys,
} from './utils'

import http from '../../network/httpClient'

const fetchSalesRevenueSegments = ticker => {
  return http.get(
    'papi/data/api/v4/revenue-product-segmentation?symbol=' +
      ticker +
      '&structure=flat'
  )
}
export const SalesRevenueSegments = ({ ticker }) => {
  const [data, setData] = useState()

  const [barChart, setBarChartData] = useState()
  const [tableData, setTableData] = useState()
  const [radialData, setRadialData] = useState()
  const [keys, setKeys] = useState()
  useEffect(() => {
    fetchSalesRevenueSegments(ticker).then(data_raw => {
      setData(data_raw?.data?.message)
    })
  }, [ticker])

  useEffect(() => {
    if (data) {
      setBarChartData(buildBarChartData(data))
      setTableData(buildTableData(data))
      setRadialData(buildRadialChartData(data))
      setKeys(getChartKeys(data))
    }
  }, [data])

  return (
    keys &&
    tableData && (
      <div className={styles.geoRevSegment}>
        <div className={styles.geoRevSegmentChartWrapper}>
          <div className={styles.geoRevSegmentLeftPanel}>
            <div className={styles.geoRevSegmentLeftPanelBarChart}>
              <BarChartWrapper data={barChart} keys={keys} />
            </div>
          </div>
          <div className={styles.geoRevSegmentRightPanel}>
            <div className={styles.geoRevSegmentRightPanelLabel}>
              Current Geo Rev Breakdown:
            </div>
            <RadialChartWrapper data={radialData} />
          </div>
        </div>
        <div className={styles.geoRevSegmentBottomPanel}>
          <GeoRevTable rows={tableData} />
        </div>
      </div>
    )
  )
}
