import numeral from 'numeral'
import { startCase } from 'lodash'
import { countryCodes } from '../../MetricCatalog/countryCodes'

export const cleanKey = key => {
  const newKey = key.replace('TTM', '')
  return startCase(newKey)
}

export const isNumberInfinity = (item, min, max) => {
  if (min < -999_999_999_999_999)
    return `${cleanKey(item?.column)} below ${numeral(max).format('0.00a')}`
  if (max > 999_999_999_999_999)
    return `${cleanKey(item?.column)} above ${numeral(min).format('0.00a')}`
  return `${cleanKey(item?.column)} between ${numeral(min).format(
    '0.00a'
  )} - ${numeral(max).format('0.00a')}`
}

export const displayFilters = (type, item) => {
  switch (type) {
    case 'between':
      return (
        <div>
          {isNumberInfinity(item, item?.config?.min, item?.config?.max)}
        </div>
      )
    case 'string':
      return (
        <div>
          <div>
            {cleanKey(item?.column)} ={' '}
            {item?.column === 'country'
              ? countryCodes[cleanKey(item?.config?.keyword)]
              : cleanKey(item?.config?.keyword)}
          </div>
        </div>
      )
    // Consider handling other types as well
    default:
      return null
  }
}
