import { useState } from 'react'
import { InitialConfig } from './config'
import styles from './ComponentCatalog.module.scss'
import TopNavBar from './TopNavBar/TopNavBar'
import Item from './Item/Item.js'
import times from './assets/times.svg'
import _ from 'lodash'
import { InfoModal } from './Modal/Modal'

import { matchSorter } from 'match-sorter'

const ComponentCatalog = ({ handleClose }) => {
  const [config, setConfig] = useState(_.chunk(InitialConfig, 1))
  const [openInfoModal, setOpen] = useState()
  const [entryInfo, setEntryInfo] = useState({})

  const setList = selection => {
    let newConf
    switch (selection) {
      case 0:
        return setConfig([..._.chunk(InitialConfig, 1)])
      case 1:
        newConf = InitialConfig.flat().filter(
          entry => entry.type === 'Component'
        )
        newConf = _.chunk(newConf, 1)
        return setConfig([...newConf])
      case 2:
        newConf = InitialConfig.flat().filter(entry => entry.type === 'Widget')
        newConf = _.chunk(newConf, 1)
        return setConfig([...newConf])
      case 3:
        newConf = InitialConfig.flat().filter(entry => entry.type === 'Tool')
        newConf = _.chunk(newConf, 1)
        return setConfig([...newConf])
      case 4:
        newConf = InitialConfig.flat().filter(
          entry => entry.isFavourite === true
        )
        newConf = _.chunk(newConf, 1)
        return setConfig([...newConf])
      default:
        return
    }
  }

  const handleFuzzyFilterItems = value => {
    let newConf = _.chunk(
      matchSorter(InitialConfig, value, { keys: ['title', 'description'] }),
      1
    )
    setConfig(newConf)
  }

  const openInfo = entry => {
    setEntryInfo({ ...entry })
    setOpen(true)
  }

  const closeInfo = () => {
    setOpen(false)
  }

  return (
    <div className={styles.catalog}>
      <div className={styles.catalogContainer}>
        <div className={styles.titleContainer}>
          <p className={styles.itemCatalogTitle}>Item Catalog</p>
          <div className={styles.itemCatalogToolBar}>
            <div className={styles.itemFilter}>
              <TopNavBar setSelection={setList} />
            </div>
            <input
              className={styles.catalogSearch}
              placeholder={'Search Catalog...'}
              onChange={e => handleFuzzyFilterItems(e.target.value)}
            />
            <div className={styles.closeDiv} onClick={() => handleClose()}>
              <img className={styles.closeIcon} src={times} alt="close icon" />
            </div>
          </div>
        </div>
        <div className={styles.itemContainer}>
          {config?.map((row, i) => {
            return (
              row.length &&
              row?.map(item => {
                return <Item item={item} openInfo={openInfo} />
              })
            )
          })}
        </div>
        <InfoModal
          open={openInfoModal}
          handleClose={closeInfo}
          entry={entryInfo}
        />
      </div>
    </div>
  )
}

export default ComponentCatalog
