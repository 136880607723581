export const occupationSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    height: 48,
    backgroundColor: 'transparent',
    color: 'white',
    borderColor: 'white',
    borderRadius: 4,
    borderWidth: 2,
    boxShadow: state.isFocused ? 0 : 0,
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1
    const transition = 'opacity 300ms'
    return { ...provided, opacity, transition, color: 'white' }
  },
  input: provided => ({
    ...provided,
    color: 'white',
  }),
  placeholder: provided => ({
    ...provided,
    color: '#9a9a9a',
  }),
  menu: (provided, state) => ({
    ...provided,
    fontSize: '14px',
  }),
}

export const occupations = [
  { value: 'individual_investor', label: 'Individual Investor' },
  { value: 'student', label: 'Student' },
  {
    value: 'financial_advisor',
    label: 'Financial Advisor',
  },
  { value: 'financial_analyst', label: 'Financial Analyst' },
  {
    value: 'wealth_management_professional',
    label: 'Wealth Management Professional',
  },
  {
    value: 'investment_banking_professional',
    label: 'Investment Banking Professional',
  },
  {
    value: 'sell_side_or_buy_side_firm_professional',
    label: 'Sell-Side / Buy-Side Firm Professional',
  },
  {
    value: 'private_equity_professional',
    label: 'Private Equity Professional',
  },
  { value: 'hedge_fund_professional', label: 'Hedge Fund Professional' },

  { value: 'venture_capital', label: 'Venture Capital Professional' },
  { value: 'other', label: 'Other' },
]
