import {
  BALANCE_SHEET_BREAKDOWN,
  BALANCE_SHEET_GROWTH,
  CASHFLOW_BREAKDOWN,
  ENTERPRISE_VALUE,
  ESG_SCORE,
  EXPENSE_BREAKDOWN,
  INCOME_BREAKDOWN,
  INCOME_STATEMENT_BREAKDOWN,
  INCOME_STATEMENT_GROWTH,
  LIABILITIES_BREAKDOWN,
  LIQUIDITY_RATIOS,
  OPERATING_PERFORMANCE_RATIOS,
  PRICE_RATIOS,
  REVENUE_BREAKDOWN,
  RISK_ANALYSIS_RATIO,
  VALUE_RATIO,
  ASSET_BREAKDOWN,
  EQUITY_BREAKDOWN,
  CURRENT_ASSET_BREAKDOWN,
  NON_CURRENT_ASSET_BREAKDOWN,
  CURRENT_LIABILITIES_BREAKDOWN,
  NON_CURRENT_LIABILITIES_BREAKDOWN,
  CASHFLOW_STATEMENT_BREAKDOWN,
  FINANCING_ACTIVITIES_BREAKDOWN,
  INVESTING_ACTIVITIES_BREAKDOWN,
  OPERATING_ACTIVITIES_BREAKDOWN,
  INSTITUTIONAL_POSITION_SUMMARY,
  DEBT_BREAKDOWN,
  REVENUE_GROWTH_BREAKDOWN,
  EXPENSE_GROWTH_BREAKDOWN,
  INCOME_GROWTH_BREAKDOWN,
  BALANCE_SHEET_GROWTH_BREAKDOWN,
  CURRENT_ASSET_GROWTH_BREAKDOWN,
  NON_CURRENT_ASSET_GROWTH_BREAKDOWN,
  ASSET_GROWTH_BREAKDOWN,
  LIABILITIES_GROWTH_BREAKDOWN,
  NON_CURRENT_LIABILITIES_GROWTH_BREAKDOWN,
  DEBT_GROWTH_BREAKDOWN,
  CURRENT_LIABILITIES_GROWTH_BREAKDOWN,
  CASHFLOW_STATEMENT_GROWTH_BREAKDOWN,
  EQUITY_GROWTH_BREAKDOWN,
  OPERATING_ACTIVITIES_GROWTH_BREAKDOWN,
  INVESTING_ACTIVITIES_GROWTH_BREAKDOWN,
  FINANCING_ACTIVITIES_GROWTH_BREAKDOWN,
  CASHFLOW_GROWTH_BREAKDOWN,
  TWITTER_SENTIMENT,
  STOCKTWITS_SENTIMENT,
} from './widgetConstants'
import {
  BALANCE_SHEET_BREAKDOWN_CHART,
  CURRENT_ASSET_BREAKDOWN_CHART,
  CURRENT_LIABILITIES_BREAKDOWN_CHART,
  NON_CURRENT_ASSET_BREAKDOWN_CHART,
  NON_CURRENT_LIABILITIES_BREAKDOWN_CHART,
  OPERATING_EXPENSE_BREAKDOWN_CHART,
} from './chartsConstants'

import {
  BALANCE_SHEET,
  CASH_FLOW_STATEMENT,
  COMPANY_GROWTH_TABLE,
  COMPANY_RATIOS_TABLE,
  EIGHT_K_RSS_FEED,
  INCOME_STATEMENT,
  INSIDER_TRADING_RSS_SEC_FEED,
  RSS_SEC_FEED,
} from './tableConstants'
import {
  COMPANY_PROFILE,
  COMPARISON_CHARTS,
  EARNINGS_CALENDAR,
  ETF_BREAKDOWN,
  INSTITUTIONAL_STOCK_OWNERSHIP,
  MARKET_RISK_PREMIUM,
  NOTES,
  SCREENER,
  GEO_REVENUE_SEGMENTS,
  COMPANY_QUOTE,
  EARNINGS_TRANSCRIPT,
  ESG_SCORE_BREAKDOWN,
  PRICE_CHARTS,
  PRODUCT_REVENUE_SEGMENTS,
} from './uniqueConstants'

export const componentBlockMapper = {
  unique: [
    ETF_BREAKDOWN,
    MARKET_RISK_PREMIUM,
    INSTITUTIONAL_STOCK_OWNERSHIP,
    COMPANY_PROFILE,
    EARNINGS_CALENDAR,

    SCREENER,
    COMPARISON_CHARTS,
    NOTES,
  ],
  presetCharts: [
    BALANCE_SHEET_BREAKDOWN_CHART,
    CURRENT_ASSET_BREAKDOWN_CHART,
    CURRENT_LIABILITIES_BREAKDOWN_CHART,
    NON_CURRENT_ASSET_BREAKDOWN_CHART,
    NON_CURRENT_LIABILITIES_BREAKDOWN_CHART,
    OPERATING_EXPENSE_BREAKDOWN_CHART,
  ],
  basicTable: [
    RSS_SEC_FEED,
    INSIDER_TRADING_RSS_SEC_FEED,
    EIGHT_K_RSS_FEED,
    '',
  ],
  widget: [
    BALANCE_SHEET_BREAKDOWN,
    BALANCE_SHEET_GROWTH,
    CASHFLOW_BREAKDOWN,
    ENTERPRISE_VALUE,
    ESG_SCORE,
    EXPENSE_BREAKDOWN,
    INCOME_BREAKDOWN,
    INCOME_STATEMENT_BREAKDOWN,
    INCOME_STATEMENT_GROWTH,
    LIABILITIES_BREAKDOWN,
    LIQUIDITY_RATIOS,
    OPERATING_PERFORMANCE_RATIOS,
    PRICE_RATIOS,
    REVENUE_BREAKDOWN,
    RISK_ANALYSIS_RATIO,
    VALUE_RATIO,
    ASSET_BREAKDOWN,
    EQUITY_BREAKDOWN,
    CURRENT_ASSET_BREAKDOWN,
    NON_CURRENT_ASSET_BREAKDOWN,
    CURRENT_LIABILITIES_BREAKDOWN,
    NON_CURRENT_LIABILITIES_BREAKDOWN,
    CASHFLOW_STATEMENT_BREAKDOWN,
    FINANCING_ACTIVITIES_BREAKDOWN,
    INVESTING_ACTIVITIES_BREAKDOWN,
    OPERATING_ACTIVITIES_BREAKDOWN,
    INSTITUTIONAL_POSITION_SUMMARY,
    DEBT_BREAKDOWN,
    REVENUE_GROWTH_BREAKDOWN,
    EXPENSE_GROWTH_BREAKDOWN,
    INCOME_GROWTH_BREAKDOWN,
    BALANCE_SHEET_GROWTH_BREAKDOWN,
    CURRENT_ASSET_GROWTH_BREAKDOWN,
    NON_CURRENT_ASSET_GROWTH_BREAKDOWN,
    ASSET_GROWTH_BREAKDOWN,
    LIABILITIES_GROWTH_BREAKDOWN,
    NON_CURRENT_LIABILITIES_GROWTH_BREAKDOWN,
    DEBT_GROWTH_BREAKDOWN,
    CURRENT_LIABILITIES_GROWTH_BREAKDOWN,
    CASHFLOW_STATEMENT_GROWTH_BREAKDOWN,
    EQUITY_GROWTH_BREAKDOWN,
    OPERATING_ACTIVITIES_GROWTH_BREAKDOWN,
    INVESTING_ACTIVITIES_GROWTH_BREAKDOWN,
    FINANCING_ACTIVITIES_GROWTH_BREAKDOWN,
    CASHFLOW_GROWTH_BREAKDOWN,
    TWITTER_SENTIMENT,
    STOCKTWITS_SENTIMENT,
  ],
  blockTable: [
    INCOME_BREAKDOWN,
    BALANCE_SHEET,
    CASH_FLOW_STATEMENT,
    COMPANY_GROWTH_TABLE,
    COMPANY_RATIOS_TABLE,
  ],
}

export const marketCompanyMapper = {
  Market: [
    RSS_SEC_FEED,
    INSIDER_TRADING_RSS_SEC_FEED,
    EIGHT_K_RSS_FEED,
    MARKET_RISK_PREMIUM,
    COMPANY_PROFILE,
  ],
  Company: [
    INCOME_STATEMENT,
    BALANCE_SHEET,
    CASH_FLOW_STATEMENT,
    COMPANY_GROWTH_TABLE,
    COMPANY_RATIOS_TABLE,
    INSTITUTIONAL_STOCK_OWNERSHIP,
    COMPANY_PROFILE,
    COMPARISON_CHARTS,
    BALANCE_SHEET_BREAKDOWN_CHART,
    CURRENT_ASSET_BREAKDOWN_CHART,
    CURRENT_LIABILITIES_BREAKDOWN_CHART,
    NON_CURRENT_ASSET_BREAKDOWN_CHART,
    NON_CURRENT_LIABILITIES_BREAKDOWN_CHART,
    OPERATING_EXPENSE_BREAKDOWN_CHART,
    GEO_REVENUE_SEGMENTS,
    COMPANY_QUOTE,
    EARNINGS_TRANSCRIPT,
    ESG_SCORE_BREAKDOWN,
    PRICE_CHARTS,
    PRODUCT_REVENUE_SEGMENTS,
  ],
}

export const configComponentDimensionsMapper = {
  [SCREENER]: {
    w: 82,
    h: 56,
    minH: 56,
    maxH: 56,
    minW: 40,
    isResizable: true,
  },
  // start of widgets
  [ESG_SCORE]: {
    w: 32,
    h: 24,
    isResizable: false,
  },
  [CURRENT_ASSET_BREAKDOWN]: {
    w: 32,
    h: 24,
    isResizable: false,
  },
  [NON_CURRENT_ASSET_BREAKDOWN]: {
    w: 32,
    h: 24,
    isResizable: false,
  },
  [CURRENT_LIABILITIES_BREAKDOWN]: {
    w: 32,
    h: 24,
    isResizable: false,
  },
  [NON_CURRENT_LIABILITIES_BREAKDOWN]: {
    w: 32,
    h: 24,
    isResizable: false,
  },
  [CASHFLOW_STATEMENT_BREAKDOWN]: {
    w: 32,
    h: 24,
    isResizable: false,
  },
  [FINANCING_ACTIVITIES_BREAKDOWN]: {
    w: 32,
    h: 24,
    isResizable: false,
  },
  [INVESTING_ACTIVITIES_BREAKDOWN]: {
    w: 32,
    h: 24,
    isResizable: false,
  },
  [OPERATING_ACTIVITIES_BREAKDOWN]: {
    w: 32,
    h: 24,
    isResizable: false,
  },
  [INSTITUTIONAL_POSITION_SUMMARY]: {
    w: 32,
    h: 24,
    isResizable: false,
  },
  [DEBT_BREAKDOWN]: {
    w: 32,
    h: 24,
    isResizable: false,
  },
  [REVENUE_GROWTH_BREAKDOWN]: {
    w: 32,
    h: 24,
    isResizable: false,
  },
  [EXPENSE_GROWTH_BREAKDOWN]: {
    w: 32,
    h: 24,
    isResizable: false,
  },
  [INCOME_GROWTH_BREAKDOWN]: {
    w: 32,
    h: 24,
    isResizable: false,
  },
  [BALANCE_SHEET_GROWTH_BREAKDOWN]: {
    w: 32,
    h: 24,
    isResizable: false,
  },
  [CURRENT_ASSET_GROWTH_BREAKDOWN]: {
    w: 32,
    h: 24,
    isResizable: false,
  },
  [NON_CURRENT_ASSET_GROWTH_BREAKDOWN]: {
    w: 32,
    h: 24,
    isResizable: false,
  },
  [ASSET_GROWTH_BREAKDOWN]: {
    w: 32,
    h: 24,
    isResizable: false,
  },
  [LIABILITIES_GROWTH_BREAKDOWN]: {
    w: 32,
    h: 24,
    isResizable: false,
  },
  [NON_CURRENT_LIABILITIES_GROWTH_BREAKDOWN]: {
    w: 32,
    h: 24,
    isResizable: false,
  },
  [DEBT_GROWTH_BREAKDOWN]: {
    w: 32,
    h: 24,
    isResizable: false,
  },
  [CURRENT_LIABILITIES_GROWTH_BREAKDOWN]: {
    w: 32,
    h: 24,
    isResizable: false,
  },
  [CASHFLOW_STATEMENT_GROWTH_BREAKDOWN]: {
    w: 32,
    h: 24,
    isResizable: false,
  },
  [EQUITY_GROWTH_BREAKDOWN]: {
    w: 32,
    h: 24,
    isResizable: false,
  },
  [OPERATING_ACTIVITIES_GROWTH_BREAKDOWN]: {
    w: 32,
    h: 24,
    isResizable: false,
  },
  [INVESTING_ACTIVITIES_GROWTH_BREAKDOWN]: {
    w: 32,
    h: 24,
    isResizable: false,
  },
  [FINANCING_ACTIVITIES_GROWTH_BREAKDOWN]: {
    w: 32,
    h: 24,
    isResizable: false,
  },
  [CASHFLOW_GROWTH_BREAKDOWN]: {
    w: 32,
    h: 24,
    isResizable: false,
  },
  [STOCKTWITS_SENTIMENT]: {
    w: 32,
    h: 24,
    isResizable: false,
  },
  [TWITTER_SENTIMENT]: {
    w: 32,
    h: 24,
    isResizable: false,
  },
  [BALANCE_SHEET_BREAKDOWN]: {
    w: 32,
    h: 24,
    isResizable: false,
  },
  [ASSET_BREAKDOWN]: {
    w: 32,
    h: 24,
    isResizable: false,
  },
  [LIABILITIES_BREAKDOWN]: {
    w: 32,
    h: 24,
    isResizable: false,
  },
  [EQUITY_BREAKDOWN]: {
    w: 32,
    h: 24,
    isResizable: false,
  },
  [INCOME_STATEMENT_BREAKDOWN]: {
    w: 32,
    h: 24,
    isResizable: false,
  },
  [REVENUE_BREAKDOWN]: {
    w: 32,
    h: 24,
    isResizable: false,
  },
  [EXPENSE_BREAKDOWN]: {
    w: 32,
    h: 24,
    isResizable: false,
  },
  [INCOME_BREAKDOWN]: {
    w: 32,
    h: 24,
    isResizable: false,
  },
  [CASHFLOW_BREAKDOWN]: {
    w: 32,
    h: 24,
    isResizable: false,
  },
  [ENTERPRISE_VALUE]: {
    w: 32,
    h: 24,
    isResizable: false,
  },
  [INCOME_STATEMENT_GROWTH]: {
    w: 32,
    h: 24,
    isResizable: false,
  },
  [BALANCE_SHEET_GROWTH]: {
    w: 32,
    h: 24,
    isResizable: false,
  },
  [VALUE_RATIO]: {
    w: 32,
    h: 24,
    isResizable: false,
  },
  [RISK_ANALYSIS_RATIO]: {
    w: 32,
    h: 24,
    isResizable: false,
  },
  [PRICE_RATIOS]: {
    w: 32,
    h: 24,
    isResizable: false,
  },
  [OPERATING_PERFORMANCE_RATIOS]: {
    w: 32,
    h: 24,
    isResizable: false,
  },
  [LIQUIDITY_RATIOS]: {
    w: 32,
    h: 24,
    isResizable: false,
  },
  // end of widgets
  [NOTES]: {
    w: 24,
    h: 32,
  },
  [BALANCE_SHEET_BREAKDOWN_CHART]: {
    w: 48,
    h: 32,
    isResizable: false,
  },
  [CURRENT_ASSET_BREAKDOWN_CHART]: {
    w: 48,
    h: 32,
    isResizable: false,
  },
  [NON_CURRENT_ASSET_BREAKDOWN_CHART]: {
    w: 48,
    h: 32,
    isResizable: false,
  },
  [OPERATING_EXPENSE_BREAKDOWN_CHART]: {
    w: 48,
    h: 32,
    isResizable: false,
  },
  [CURRENT_LIABILITIES_BREAKDOWN_CHART]: {
    w: 48,
    h: 32,
    isResizable: false,
  },
  [NON_CURRENT_LIABILITIES_BREAKDOWN_CHART]: {
    w: 48,
    h: 32,
    isResizable: false,
  },
  [GEO_REVENUE_SEGMENTS]: {
    w: 48,
    h: 32,
    isResizable: false,
  },
  [PRODUCT_REVENUE_SEGMENTS]: {
    w: 48,
    h: 32,
    isResizable: false,
  },
  [EARNINGS_TRANSCRIPT]: {
    w: 43,
    h: 40,
    isResizable: false,
  },
  [COMPANY_QUOTE]: {
    w: 60,
    h: 24,
    isResizable: false,
  },
  [COMPANY_PROFILE]: {
    w: 48,
    h: 32,
    isResizable: false,
  },
  [ESG_SCORE_BREAKDOWN]: {
    w: 48,
    h: 24,
    isResizable: false,
  },
  // [INSTITUTIONAL_STOCK_OWNERSHIP]: {
  //   w: 60,
  //   h: 48,
  //   isResizable: false,
  // },
  [COMPANY_GROWTH_TABLE]: {
    w: 60,
    h: 48,
    isResizable: true,
    minW: 36,
    minH: 24,
  },
  [INCOME_STATEMENT]: {
    w: 60,
    h: 48,
    isResizable: true,
    minW: 36,
    minH: 24,
  },
  [CASH_FLOW_STATEMENT]: {
    w: 60,
    h: 48,
    isResizable: true,
    minW: 36,
    minH: 24,
  },
  [BALANCE_SHEET]: {
    w: 60,
    h: 48,
    isResizable: true,
    minW: 36,
    minH: 24,
  },
  [COMPANY_RATIOS_TABLE]: {
    w: 60,
    h: 48,
    isResizable: true,
    minW: 36,
    minH: 24,
  },
  [PRICE_CHARTS]: {
    w: 60,
    h: 48,
    isResizable: true,
    minW: 36,
    minH: 24,
  },
  [COMPARISON_CHARTS]: {
    w: 60,
    h: 48,
    isResizable: true,
    minW: 60,
    minH: 36,
  },
  [EIGHT_K_RSS_FEED]: {
    w: 48,
    h: 48,
    isResizable: true,
    minW: 45,
    minH: 32,
    maxW: 48,
  },
  [INSIDER_TRADING_RSS_SEC_FEED]: {
    w: 68,
    h: 48,
    isResizable: true,
    minW: 45,
    minH: 36,
  },
  [RSS_SEC_FEED]: {
    w: 48,
    h: 48,
    isResizable: true,
    minH: 32,
    maxW: 48,
  },
}
