import { Link } from 'react-router-dom'
import './HomePageFooter.scss'
import { EnterTerminalButton } from '../EnterTerminalButton/EnterTerminalButton'

export const Footer = () => {
  return (
    <div>
      <div className="homepage-pre-footer">
        <div className="homepage-pre-footer-content">
          <div className="homepage-pre-footer-content-title">
            Financial data you need, in the way you want.
          </div>
          <div className="homepage-pre-footer-content-title-text">
            Join others in taking control of your investment research with
            Outseek.
          </div>
          <EnterTerminalButton />
        </div>
      </div>
      <div className="homepage-footer">
        <div className="homepage-footer-content">
          <div className="homepage-footer-content-title">SOCIALS</div>
          <div className="homepage-footer-content-items-wrapper">
            <div className="homepage-footer-content-item">
              <a
                className="homepage-footer-content-link"
                target={'_blank'}
                href="https://www.linkedin.com/company/outseek-ca/"
                rel="noreferrer"
              >
                LinkedIn
              </a>
            </div>
            <div className="homepage-footer-content-item">
              <a
                className="homepage-footer-content-link"
                target={'_blank'}
                href="https://www.instagram.com/outseek.ca/"
                rel="noreferrer"
              >
                Instagram
              </a>
            </div>
            <div className="homepage-footer-content-item">
              <a
                className="homepage-footer-content-link"
                target={'_blank'}
                href="https://twitter.com/outseekdotca/"
                rel="noreferrer"
              >
                Twitter
              </a>
            </div>
          </div>
        </div>
        <div className="homepage-footer-content">
          <div className="homepage-footer-content-title">COMPANY</div>
          <div className="homepage-footer-content-items-wrapper">
            <div className="homepage-footer-content-item">
              <Link to={'/AboutUs'}>
                <div className="homepage-footer-content-link">About Us</div>
              </Link>
            </div>
          </div>
        </div>
        <div className="homepage-footer-content">
          <div className="homepage-footer-content-title">SUPPORT</div>
          <div className="homepage-footer-content-items-wrapper">
            <div className="homepage-footer-content-item">
              <Link to={'/ContactUs'}>
                <div className="homepage-footer-content-link">Contact Us</div>
              </Link>
            </div>
          </div>
        </div>
        <div className="homepage-footer-content">
          <div className="homepage-footer-content-title">LEGAL</div>
          <div className="homepage-footer-content-items-wrapper">
            <div className="homepage-footer-content-item">
              <Link to={'/TermsOfService'}>
                <div className="homepage-footer-content-link">Terms of Use</div>
              </Link>
            </div>
            <div className="homepage-footer-content-item">
              <Link to={'/PrivacyPolicy'}>
                <div className="homepage-footer-content-link">
                  Privacy Policy
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="homepage-footer-content">
          <div className="homepage-footer-content-title">TERMINAL</div>
          <div className="homepage-footer-content-items-wrapper">
            <div className="homepage-footer-content-item">
              <Link to={'/terminal/marketOverview'}>
                <div className="homepage-footer-content-link">
                  Enter Terminal
                </div>
              </Link>
            </div>
            <div className="homepage-footer-content-item">
              <Link to={'/Feedback'}>
                <div className="homepage-footer-content-link">
                  Submit Feedback
                </div>
              </Link>
            </div>
            <div className="homepage-footer-content-item">
              <Link to={'/FeatureRequest'}>
                <div className="homepage-footer-content-link">
                  Feature Request
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="homepage-copyright">
        <div className="homepage-copyright-content">
          Outseek Finance Inc. © 2024
        </div>
      </div>
    </div>
  )
}
