import { cloneDeep } from 'lodash'

/**
 * data process function for formatting the
 * starting format:
 * {
 *
 * }
 *
 * end format:
 * {
 *    date: 2020,
 *    sector: Technology,
 *    grossProfit: {
 *        MIN: 300000,
 *        MAX: 3000000,
 *        AVG: 500000,
 *        stddev: 3923020
 *     }
 *
 * }
 * @param {Array} resp array of query response objects
 * @param {Array} groupByColumns  coplumns for each we want to group by
 * @returns  a formatted list of object
 */
export const formatSummary = (resp, groupByColumns) => {
  // console.log(resp)
  const minQueryResp = resp?.[0]?.data?.items || []
  const theRestResp = resp.slice(1, resp.length)
  // greb the first entry and just build on that

  const formattedResponse = minQueryResp.map((entry, i) => {
    const formattedEntry = {}
    // do MIN first that sets the structure for which we will build the objects on
    Object.keys(entry)
      .filter(entry => entry !== 'date' && !groupByColumns.includes(entry))
      .forEach(metric => {
        const metricsParts = metric.split('_')
        const metricLabel = metricsParts[1]
        const aggregate = metricsParts[0]
        formattedEntry[metricLabel] = { [aggregate]: entry[metric] }
      })

    // MAX, AVG, STD_DEV
    theRestResp.forEach(restEntry => {
      const item = restEntry.data.items[i]
      Object.keys(item)
        .filter(
          restEntry => restEntry !== 'date' && !groupByColumns.includes(entry)
        )
        .forEach(metric => {
          const metricsParts = metric.split('_')
          const metricLabel = metricsParts[1]
          const aggregate = metricsParts[0]
          formattedEntry[metricLabel] = {
            ...formattedEntry[metricLabel],
            [aggregate]: item[metric],
          }
        })
    })
    groupByColumns.forEach(col => (formattedEntry[col] = entry[col]))
    return {
      date: entry.date,
      ...formattedEntry,
    }
  })
  return formattedResponse
}
