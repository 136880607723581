import { useQuery } from 'react-query'
import DataQueries from './DataQueries'

const useChartData = ticker => {
  return useQuery(['chartData', ticker], () =>
    DataQueries.fetchChartData(ticker)
  )
}

const useCompanyData = ticker => {
  return useQuery(['quote-data', ticker], () =>
    DataQueries.fetchCompanyData(ticker)
  )
}

const useStockPrice = ticker => {
  return useQuery(
    ['stockPrice', ticker],
    () => DataQueries.fetchStockPrice(ticker),
    {
      staleTime: 1000 * 60 * 30,
    }
  )
}

// const useFlagImage = country => {
//   const countryCode = country || 'Unknown'

//   return useQuery(
//     ['flagImage', countryCode],
//     async () => {
//       const response = await fetch(
//         `https://www.countryflagicons.com/FLAT/24/${countryCode}.png`
//       )
//       const blob = await response.blob()
//       return URL.createObjectURL(blob)
//     },
//     {
//       enabled: countryCode !== 'Unknown',
//     }
//   )
// }

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  useChartData,
  useCompanyData,
  // useFlagImage,
  useStockPrice,
}
