import React from 'react'

import numeral from 'numeral'
import styles from './RevSegTable.module.scss'

export const GeoRevTable = ({ rows }) => {
  const totalRevenue = Object.values(rows).reduce(
    (acc, value) => acc + value,
    0
  )

  return Object.keys(rows).map((key, i) => {
    const value = rows[key]
    const [formattedValue, unit] = numeral(value).format('0.000 a').split(' ')
    const percentOfTotal = ((value / totalRevenue) * 100).toFixed(1)

    return (
      <div key={i} className={styles.geoRevTableRow}>
        <div className={styles.geoRevTableLabelCell}>{key}</div>
        <div className={styles.geoRevTableValueCell}>
          <div style={{ textAlign: 'left' }}>
            {formattedValue} {unit}
          </div>
          <div>{`(${percentOfTotal} %)`}</div>
        </div>
      </div>
    )
  })
}
