import React, { useState, useEffect } from 'react'
import { Modal } from '@mui/material'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import styles from './QuickTable.module.scss'
import numeral from 'numeral'
import CoreButton from '../Button/CoreButton'
import times from '../../assets/times.svg'
import reset from './reset.svg'
import {
  UilPlus,
  UilTimes,
  UilSetting,
  UilRedo,
} from '@iconscout/react-unicons'
import { onDragEnd, formatter } from './utils'
import Fuse from 'fuse.js'
import MetricInfo from 'core/MetricInfoHover/MetricInfoTooltip'

const QuickTable = ({
  data,
  metrics,
  label,
  splitMetrics = true,
  swap,
  height,
  cleanView = true,
  modalIsOpen,
  setIsOpen,
}) => {
  const [selectedMetrics, setSelectedMetrics] = useState(
    metrics?.filter(metric => metric?.selected)
  )

  const [availableMetrics, setAvailableMetrics] = useState(
    metrics?.filter(metric => !metric?.selected)
  )

  const [searchTerm, setSearchTerm] = useState('')

  const fuse = new Fuse(availableMetrics, {
    keys: ['header', 'key'],
    includeScore: true,
  })

  useEffect(() => {
    setSelectedMetrics(metrics?.filter(metric => metric?.selected))
    fuse.setCollection(metrics?.filter(metric => !metric?.selected))
  }, [metrics])

  const applyMetricSelection = () => {
    setIsOpen(false)
  }

  const resetMetrics = () => {
    const defaultSelectedMetrics = metrics?.filter(metric => metric?.selected)
    setSelectedMetrics(defaultSelectedMetrics)
    setAvailableMetrics(metrics?.filter(metric => !metric?.selected))
  }

  const onDraggingEnd = result => {
    onDragEnd(
      result,
      setAvailableMetrics,
      availableMetrics,
      setSelectedMetrics,
      selectedMetrics
    )
  }

  const selectMetric = metric => {
    setAvailableMetrics(prevSelected =>
      prevSelected?.filter(m => m?.key !== metric?.key)
    )
    setSelectedMetrics(prevAvailable => [...prevAvailable, metric])
  }

  const deselectMetric = metric => {
    setSelectedMetrics(prevSelected =>
      prevSelected?.filter(m => m?.key !== metric?.key)
    )
    setAvailableMetrics(prevAvailable => [...prevAvailable, metric])
  }

  const metricsClass = splitMetrics ? styles.twoColumns : styles.oneColumn

  const handleSearchChange = e => {
    const { value } = e.target
    setSearchTerm(value)

    if (value.length > 0) {
      const results = fuse.search(value)
      setAvailableMetrics(results?.map(result => result?.item))
    } else {
      setAvailableMetrics(metrics?.filter(metric => !metric?.selected))
    }
  }

  return (
    <div className={styles.profileInfo}>
      {cleanView ? null : (
        <div className={styles.titleWrapper}>
          <div className={styles.label}>
            {label} {swap}
          </div>
          <div
            className={styles.settingsWrapper}
            onClick={() => setIsOpen(true)}
          >
            <UilSetting
              onClick={() => setIsOpen(true)}
              color="var(--white)"
              size={17}
            />
          </div>
        </div>
      )}
      <Modal
        open={modalIsOpen}
        onClose={() => setIsOpen(false)}
        disableAutoFocus={true}
      >
        <div className={styles.modalOverlay}>
          <div className={styles.modalWrapper}>
            <div className={styles.modalTitle}>
              <div
                className={styles.modalLabel}
              >{`Select ${label} Metrics`}</div>
              <img
                onClick={() => setIsOpen(false)}
                className={styles.closeButton}
                alt="close modal"
                src={times}
              />
            </div>
            <input
              className={styles.searchMetricsInput}
              type="text"
              placeholder="Search metrics..."
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <DragDropContext onDragEnd={onDraggingEnd}>
              <div className={styles.BoxArray}>
                <div className={styles.metricItemContainerWrapper}>
                  <div
                    className={styles.metricsItemLabel}
                  >{`Available Metrics`}</div>
                  <div className={styles.metricItemContainer}>
                    <Droppable droppableId="availableMetrics">
                      {provided => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {availableMetrics.map((item, index) => (
                            <Draggable
                              key={index}
                              draggableId={String(index)}
                              index={index}
                            >
                              {provided => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className={styles.metricItemWrapper}
                                >
                                  <div className={styles.metricItem}>
                                    {item.header}
                                    <div
                                      className={styles.selectMetricButton}
                                      onClick={() => selectMetric(item)}
                                    >
                                      <UilPlus
                                        size={18}
                                        color={'var(--primary-color)'}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </div>
                </div>
                <div className={styles.metricItemContainerWrapper}>
                  <div
                    className={styles.metricsItemLabel}
                  >{`Selected Metrics`}</div>
                  <div className={styles.metricItemContainer}>
                    <Droppable droppableId="selectedMetrics">
                      {(provided, snapshot) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          className={styles.metricItemContainer}
                          style={{
                            backgroundColor: snapshot.isDraggingOver
                              ? 'var(--outseek-background)'
                              : 'var(--background-primary)',
                          }}
                        >
                          {selectedMetrics.map((item, index) => (
                            <Draggable
                              key={item.key}
                              draggableId={item.key}
                              index={index}
                            >
                              {provided => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className={styles.metricItemWrapper}
                                >
                                  <div className={styles.metricItem}>
                                    {item?.header}
                                    <div
                                      className={styles.selectMetricButton}
                                      onClick={() => deselectMetric(item)}
                                    >
                                      <UilTimes
                                        size={18}
                                        color={'var(--red)'}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </div>
                </div>
              </div>
            </DragDropContext>
            <div className={styles.buttonWrapper}>
              <CoreButton onClick={applyMetricSelection} width="100%">
                Apply
              </CoreButton>
              <CoreButton onClick={resetMetrics}>
                Reset
                <UilRedo size={16} color="var(--white)" />
              </CoreButton>
            </div>
          </div>
        </div>
      </Modal>
      <div className={metricsClass} style={{ height: height }}>
        {selectedMetrics.map((item, itemIndex) => (
          <div key={itemIndex} className={styles.profileDataContainerWrapper}>
            <div className={styles.profileDataContainer}>
              <div className={styles.header}>
                <MetricInfo
                  metricKey={item?.key}
                  displayMetric={item?.header}
                />
              </div>
              <div className={styles.data}>
                {data?.[item?.key] || data?.[item?.key] === 0
                  ? formatter[item.format]
                    ? formatter[item.format](data[item.key])
                    : typeof data[item.key] === 'number'
                    ? numeral(data[item.key]).format('0.00a')
                    : data[item.key]
                  : '-'}
              </div>
            </div>
            {splitMetrics ? (
              itemIndex === selectedMetrics.length - 1 ||
              itemIndex === selectedMetrics.length - 2 ? null : (
                <div className={styles.profileLineBreak} />
              )
            ) : itemIndex === selectedMetrics.length - 1 ? null : (
              <div className={styles.profileLineBreak} />
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default QuickTable
