import React, { useContext, useEffect } from 'react'
import { AuthContext } from '../AuthContext'
import styles from '../Auth.module.scss'
import ClipLoader from 'react-spinners/ClipLoader'
import ForgotPassword from '../ForgotPassword/ForgotPassword'
import CoreButton from 'core/Button/CoreButton'
import { useIsAuthenticated } from 'core/AuthComponents/useIsAuthenticated'

const SignIn = ({ modal }) => {
  const {
    handleSignIn,
    signInSuccess,
    setUsername,
    setPassword,
    showSignUp,
    setShowSignUp,
    setForgetPassword,
    forgetPassword,
    setSignInError,
    signInError,
    handleSignInDemo,
    handleSignInWithGoogle,
  } = useContext(AuthContext)

  const { isDemoUser, isAuthenticated } = useIsAuthenticated()

  // console.log('isAuthenticateds', isAuthenticated)

  const modifiedHandleSignIn = async () => {
    try {
      const result = await handleSignIn()
      if (result && modal) {
        window.location.reload()
      }
    } catch (error) {}
  }

  const modifiedHandleSignInDemo = async () => {
    try {
      const result = await handleSignInDemo()
      if (result && modal) {
        window.location.reload()
      }
    } catch (error) {}
  }

  return (
    <div className={styles.signInUpWrapper}>
      {forgetPassword === true ? (
        <ForgotPassword />
      ) : (
        <>
          {/* <div className={styles.tryDemo} onClick={handleSignInWithGoogle}>
            google
          </div> */}
          <div className={styles.tryDemo} onClick={modifiedHandleSignInDemo}>
            Continue without signing in
          </div>
          <div className={styles.orWrapper}>
            <div className={styles.line} />
            or
            <div className={styles.line} />
          </div>
          <div className={styles.authHeader}>Sign In To Outseek Terminal</div>
          <input
            className={styles.authInput}
            placeholder="Email"
            onChange={e => {
              setUsername(e.target.value)
              setSignInError('')
            }}
            autoComplete="off"
            style={{
              border: signInError ? '2px solid var(--red)' : null,
            }}
          />
          <input
            className={styles.authInput}
            placeholder="Password"
            type="password"
            onChange={e => {
              setPassword(e.target.value)
              setSignInError('')
            }}
            autoComplete="off"
            style={{
              border: signInError ? '2px solid var(--red)' : null,
            }}
          />
          {signInError && <div className={styles.authError}>{signInError}</div>}
          <button
            className={styles.authConfirmButton}
            style={{
              cursor: signInSuccess === true ? 'not-allowed' : 'pointer',
            }}
            onClick={modifiedHandleSignIn}
          >
            {signInSuccess && <ClipLoader size={16} color="var(--white)" />}
            Sign In
          </button>
          <div
            className={styles.forgotPassword}
            onClick={() => setForgetPassword(true)}
          >
            Forgot Password?
          </div>
          <div
            className={styles.transparentButton}
            onClick={() => setShowSignUp(!showSignUp)}
          >
            <div>
              Don't have an account?{' '}
              <span className={styles.showSignIn}>Sign Up</span>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default SignIn
