import DataTable from '../../../dashboard/ServerSideScreener/ServerSideScreener.js'
import { QueryClient, QueryClientProvider } from 'react-query'
import styles from './Screener.module.scss'
import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import Spinner from 'core/ScrollableContainer/Spinner/spinner.jsx'

const queryClient = new QueryClient()
export const Screener = () => {
  const [persistedState, setPersistedState] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false)
    }, 300)

    return () => clearTimeout(timer)
  }, [])

  const handleUpdate = state => {
    setPersistedState(state)
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Helmet>
        <title>{'Outseek | Screener'}</title>
        <meta
          name="description"
          content={
            'This page gives the user the ability to screen for companies. Contains over 20,000 companies that can be filtered on using 300+ filters.'
          }
        />
      </Helmet>
      <div className={styles.screenerViewWrapper}>
        {isLoading ? (
          <Spinner />
        ) : (
          <DataTable
            update={handleUpdate}
            persistedState={persistedState}
            isView={true}
          />
        )}
      </div>
    </QueryClientProvider>
  )
}
