import React, { memo, useState } from 'react'
import BasicTable from 'core/Tables/BasicTable'
import { PortfolioHoldingsComposition, industryColumns } from '../columns'
import Spinner from 'core/ScrollableContainer/Spinner/spinner'
import { useDataSource } from '../hooks/useInstitutionalViewDataSource'
import dataUtils from '../utils'
import { InstitutionalQuoteBar } from 'core/QuoteBarInstitutional/QuoteBar'
import styles from './InstitutionalView.module.scss'
import DonutChart from '../components/DthreePieChart/DonutChart'
import { CardWrapper } from 'core/CardWrapper/CardWrapper'
import { TableKeyValue } from '../components/TableKeyValue/TableKeyValue'
import { investorNamePortfolioSummaryKeyValue } from '../constants'
import { Helmet } from 'react-helmet-async'
import { TreeMap } from 'pages/views/ETF/ETFInfo/components/Treemap/Treemap'

export const InstitutionalView = () => {
  const { InstitutionalView_UI, setInstitution, institution } = useDataSource()

  const handleSetInstitution = tickerIn => {
    setInstitution(dataUtils.splitInstitutionalTickerValue(tickerIn))
  }

  const [hoveredSymbol, setHoveredSymbol] = useState(null)
  const [hoveredIndustry, setHoveredIndustry] = useState(null)

  const handleIndustryHover = rowData => {
    setHoveredIndustry(rowData.industryTitle)
  }

  const handleIndustryLeave = () => {
    setHoveredIndustry(null)
  }

  const handleRowHover = rowData => {
    setHoveredSymbol(rowData.symbol)
  }

  const handleRowLeave = () => {
    setHoveredSymbol(null)
  }

  return (
    <div className={styles.institutionalViewWrapper}>
      <Helmet>
        <title>{`Outseek | Institutional Funds`}</title>
        <meta
          name="description"
          content={
            'This page provides retail financial analysts with information about large institutions and their holdings.'
          }
        />
      </Helmet>
      <InstitutionalQuoteBar
        updateInstitution={handleSetInstitution}
        institution={institution}
      />
      <div className={styles.institutionalViewContentWrapper}>
        <div className={styles.institutionalViewLeft}>
          <div className={styles.rightWrapper}>
            <CardWrapper
              height={'calc(100vh - 402px)'}
              width={'100%'}
              label={'Portfolio Holdings'}
              content={
                !InstitutionalView_UI.isLoading ? (
                  <BasicTable
                    overflowXScroll
                    columns={PortfolioHoldingsComposition}
                    data={InstitutionalView_UI?.Table}
                    onMouseOverRow={handleRowHover}
                    onMouseLeaveRow={handleRowLeave}
                    hoveredSymbol={hoveredSymbol}
                    sliceRows={50}
                    haveKey={true}
                  />
                ) : (
                  <Spinner />
                )
              }
            />
            <CardWrapper
              height={272}
              width={'100%'}
              label={'Portfolio Stats'}
              content={
                !InstitutionalView_UI.isLoading ? (
                  <TableKeyValue
                    data={InstitutionalView_UI?.KeyValueTable}
                    labels={investorNamePortfolioSummaryKeyValue}
                    allowCharts={true}
                  />
                ) : (
                  <Spinner />
                )
              }
            />
          </div>
          <div className={styles.leftWrapper}>
            <CardWrapper
              height={'calc(100vh - 402px)'}
              width={'100%'}
              label={'Portfolio Holdings Donut Chart'}
              content={
                <div className={styles.quarterPanelPieChart}>
                  {!InstitutionalView_UI.isLoading ? (
                    <DonutChart
                      data={InstitutionalView_UI?.PieData}
                      hoveredSymbol={hoveredSymbol}
                      colorStart={'#4079a8'}
                      colorEnd={'#022763'}
                      setHoveredSymbol={setHoveredSymbol}
                    />
                  ) : (
                    <Spinner />
                  )}
                </div>
              }
            />
            <CardWrapper
              height={272}
              width={'100%'}
              label={'Portfolio Breakdown By Industry'}
              content={
                <div className={styles.industryPieWrapper}>
                  {!InstitutionalView_UI.isLoading ? (
                    <div className={styles.industryWrapper}>
                      <TreeMap
                        data={InstitutionalView_UI?.treeMapData}
                        hoveredSymbol={hoveredIndustry}
                        startColor={'#8884d8'}
                        endColor={'#ac6bcb'}
                        setHoveredSymbol={setHoveredIndustry}
                      />
                      {/* <DonutChart
                        data={InstitutionalView_UI?.PieChart}
                        hoveredSymbol={hoveredIndustry}
                        colorStart={'#8884d8'}
                        colorEnd={'#3c224d'}
                        showValue={false}
                        scaleIn={false}
                        normalPie={true}
                        noLabel={true}
                        setHoveredSymbol={setHoveredIndustry}
                        paddings={0}
                      /> */}
                      <BasicTable
                        overflowXScroll
                        data={InstitutionalView_UI?.IndustryData}
                        columns={industryColumns}
                        onMouseOverRow={handleIndustryHover}
                        onMouseLeaveRow={handleIndustryLeave}
                        hoveredSymbol={hoveredIndustry}
                        hoverKey="industryTitle"
                        haveKey={true}
                      />
                    </div>
                  ) : (
                    <Spinner />
                  )}
                </div>
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(InstitutionalView)
