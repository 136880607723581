import styles from './styles.module.scss'

const RenderMarkdownHeaders = ({ level, children }) => {
  const HeaderTag = `h${level}`
  return (
    <HeaderTag
      style={{
        fontSize: '16px',
        fontWeight: 'bold',
        marginTop: '10px',
      }}
    >
      {children}
    </HeaderTag>
  )
}

const Table = ({ children }) => (
  <div className={styles.tableContainer}>
    <table
      style={{
        width: '100%',
        borderCollapse: 'collapse',
        margin: '12px 0',
      }}
    >
      {children}
    </table>
  </div>
)

const Thead = ({ children }) => (
  <thead style={{ backgroundColor: 'var(--background-tertiary)' }}>
    {children}
  </thead>
)

const Tbody = ({ children }) => <tbody>{children}</tbody>

const Tr = ({ children }) => (
  <tr style={{ borderBottom: '1px solid var(--grey-accent)' }}>{children}</tr>
)

const Th = ({ children }) => (
  <th style={{ padding: '8px', textAlign: 'left' }}>{children}</th>
)

const Td = ({ children }) => (
  <td style={{ padding: '8px', textAlign: 'left' }}>{children}</td>
)

const A = ({ children, href }) => (
  <a
    target="_blank"
    rel="noreferrer noopener"
    href={href}
    style={{
      color: 'var(--primary-color)',
      textDecoration: 'underline',
    }}
  >
    {children}
  </a>
)

export const markDownComponents = {
  h1: RenderMarkdownHeaders,
  h2: RenderMarkdownHeaders,
  h3: RenderMarkdownHeaders,
  table: Table,
  thead: Thead,
  tbody: Tbody,
  tr: Tr,
  th: Th,
  td: Td,
  a: A,
}
