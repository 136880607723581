import {
  fetchIncome,
  fetchBalance,
  fetchCash,
  fetchRevSegs,
  fetchBalanceQuarterly,
  fetchCashQuarterly,
  fetchIncomeQuarterly,
  fetchBalanceGrowth,
  fetchCashGrowth,
  fetchIncomeGrowth,
} from './queries'
import { useMemo } from 'react'
import { useQuery } from 'react-query'
import { useURLSyncedGlobalTicker } from 'pages/views/hooks'

export const useDataSource = () => {
  const { ticker, handleSetTicker } = useURLSyncedGlobalTicker()
  const { data: IncomeData, isLoading: IncomeDataIsLoading } = useQuery(
    ['income-statement', ticker],
    fetchIncome
  )

  const { data: BalanceData, isLoading: balanceDataIsLoading } = useQuery(
    ['balance-sheet-statement', ticker],
    fetchBalance
  )

  const { data: CashData, isLoading: cashDataIsLoading } = useQuery(
    ['cash-flow-statement', ticker],
    fetchCash
  )

  const { data: IncomeDataQuarterly, isLoading: IncomeDataIsLoadingQuarterly } =
    useQuery(['income-statement-quarterly', ticker], fetchIncomeQuarterly)

  const {
    data: BalanceDataQuarterly,
    isLoading: balanceDataIsLoadingQuarterly,
  } = useQuery(
    ['balance-sheet-statement-quarterly', ticker],
    fetchBalanceQuarterly
  )

  const { data: CashDataQuarterly, isLoading: cashDataIsLoadingQuarterly } =
    useQuery(['cash-flow-statement-quarterly', ticker], fetchCashQuarterly)

  const { data: RevSegsData, isLoading: revSegsDataIsLoading } = useQuery(
    ['revenue-product-segmentation', ticker],
    fetchRevSegs
  )

  // const { data: IncomeGrowthData, isLoading: IncomeGrowthDataIsLoading } =
  //   useQuery(['income-statement-growth', ticker], fetchIncomeGrowth)

  // const { data: BalanceGrowthData, isLoading: BalanceGrowthDataIsLoading } =
  //   useQuery(['balance-sheet-statement-growth', ticker], fetchBalanceGrowth)

  // const { data: CashGrowthData, isLoading: CashGrowthDataIsLoading } = useQuery(
  //   ['cash-flow-statement-growth', ticker],
  //   fetchCashGrowth
  // )

  const dataIsLoading =
    IncomeDataIsLoading ||
    balanceDataIsLoading ||
    cashDataIsLoading ||
    revSegsDataIsLoading

  const tableDataLoading =
    IncomeDataIsLoadingQuarterly ||
    IncomeDataQuarterly.length === 0 ||
    IncomeDataQuarterly === undefined ||
    IncomeDataQuarterly === null

  const FinStat = useMemo(() => {
    return {
      dataIsLoading,
      tableDataLoading,
      IncomeData: IncomeData,
      IncomeDataIsLoading: IncomeDataIsLoading,
      BalanceData: BalanceData,
      balanceDataIsLoading: balanceDataIsLoading,
      CashData: CashData,
      cashDataIsLoading: cashDataIsLoading,
      RevSegsData: RevSegsData,
      revSegsDataIsLoading: revSegsDataIsLoading,
      IncomeDataQuarterly: IncomeDataQuarterly,
      IncomeDataIsLoadingQuarterly: IncomeDataIsLoadingQuarterly,
      BalanceDataQuarterly: BalanceDataQuarterly,
      balanceDataIsLoadingQuarterly: balanceDataIsLoadingQuarterly,
      CashDataQuarterly: CashDataQuarterly,
      cashDataIsLoadingQuarterly: cashDataIsLoadingQuarterly,
      // IncomeGrowthData: IncomeGrowthData,
      // IncomeGrowthDataIsLoading: IncomeGrowthDataIsLoading,
      // BalanceGrowthData: BalanceGrowthData,
      // BalanceGrowthDataIsLoading: BalanceGrowthDataIsLoading,
      // CashGrowthData: CashGrowthData,
      // CashGrowthDataIsLoading: CashGrowthDataIsLoading,
    }
  }, [
    dataIsLoading,
    tableDataLoading,
    IncomeData,
    IncomeDataIsLoading,
    BalanceData,
    balanceDataIsLoading,
    CashData,
    cashDataIsLoading,
    RevSegsData,
    revSegsDataIsLoading,
    IncomeDataQuarterly,
    IncomeDataIsLoadingQuarterly,
    BalanceDataQuarterly,
    balanceDataIsLoadingQuarterly,
    CashDataQuarterly,
    cashDataIsLoadingQuarterly,
    // IncomeGrowthData,
    // IncomeGrowthDataIsLoading,
    // BalanceGrowthData,
    // BalanceGrowthDataIsLoading,
    // CashGrowthData,
    // CashGrowthDataIsLoading,
  ])

  return {
    FinStat,
    setTicker: handleSetTicker,
    ticker,
  }
}
