import React, { useCallback } from 'react'
import times from './assets/times.svg'
import { connect } from 'react-redux'
import axios from 'axios'
import {
  newDashboard,
  renameDashboard,
  fetchNames,
} from 'redux/actions/app-actions'
import styles from './SubModal.module.scss'
import { Auth } from 'aws-amplify'
import { APP_CONFIG } from '../../config'
export const removeDashboard = ({ id }, fetchNames) => {
  Auth.currentSession().then(res => {
    let accessToken = res.getAccessToken()
    let jwt = accessToken.getJwtToken()
    //You can print them to see the full objects
    const body = {
      userId: Auth.user.username,
    }
    var config = {
      method: 'delete',
      url: APP_CONFIG.IN_HOUSE_ORIGIN + 'api/delete/dashboard/' + id,
      headers: {
        Authorization: 'Bearer ' + jwt,
      },
      data: body,
    }

    axios(config)
      .then(function (response) {
        fetchNames()
      })
      .catch(function (error) {
        // eslint-disable-next-line no-console
        console.error(error)
      })
  })
}

const SubModal = ({
  Title,
  Disabled,
  InputValue,
  Type,
  Gap,
  parent,
  ...props
}) => {
  const [DashboardName, setDashboardName] = React.useState(null)
  const handleSubmit = useCallback(() => {
    switch (Type) {
      case 'Create':
        props.newDashboard(DashboardName)
        setTimeout(() => props.fetchNames(), 1000)
        break
      case 'OK':
        // check for name validation
        props.renameDashboard(DashboardName, InputValue)
        setTimeout(() => props.fetchNames(), 1000)
        break
      case 'Delete':
        // delete  dashboard
        removeDashboard(InputValue, props.fetchNames)
        break
      default:
        break
    }
    props.parentClose()
  }, [DashboardName, InputValue, Type, props])

  return (
    <div className={styles.subModalContainer}>
      <div className={styles.subModalTitlePanel}>
        <p className={styles.subModalTitle}>{Title} </p>
        <img
          alt="icon"
          className={styles.modalCloseButton}
          onClick={() => props.childClose()}
          src={times}
        />
      </div>
      <div className={styles.subModalInputPanel}>
        {Disabled ? (
          <input
            className={styles.subModalPlaceholder}
            placeholder={InputValue.name}
            disabled
          />
        ) : (
          <input
            className={styles.subModalPlaceholder}
            placeholder={InputValue.name}
            onKeyDown={e => e.key === 'Enter' && handleSubmit()}
            onChange={e => setDashboardName(e.target.value)}
          />
        )}
      </div>
      <div className={styles.subModalBottomPanel}>
        <div>
          <button
            className={styles.subModalBtn}
            onClick={() => props.childClose()}
          >
            Cancel
          </button>
        </div>
        <div>
          <button
            className={styles.subModalBtn}
            style={{
              alignItems: 'center',

              background:
                Type === 'Delete' ? 'var(--red)' : 'var(--primary-color)',
            }}
            onClick={() => handleSubmit()}
          >
            {Type}{' '}
          </button>
        </div>
      </div>
    </div>
  )
}

export default connect(null, {
  newDashboard,
  renameDashboard,
  fetchNames,
})(SubModal)
