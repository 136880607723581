import React from 'react'
import styles from './ReturnBlocks.module.scss'
import numeral from 'numeral'

const AverageReturns = ({ data, columns }) => {
  const calculateAverageReturns = () => {
    const averages = columns
      .filter(column => {
        if (data.length > 0 && (column.accessor || column.Header)) {
          return typeof data[0][column.accessor || column.Header] === 'number'
        }
        return false
      })
      .map(column => {
        const columnKey = column.accessor || column.Header
        const columnReturns = data.map(item => item[columnKey] || 0)
        const columnTotal = columnReturns.reduce((acc, val) => acc + val, 0)

        return {
          interval: columnKey,
          averageReturn: columnTotal / data.length,
        }
      })

    return averages
  }

  const averageReturns = calculateAverageReturns()

  return (
    <div className={styles.container}>
      {/* <div className={styles.title}>Average Returns:</div> */}
      <div className={styles.rowWrapper}>
        {averageReturns.map(item => (
          <div
            key={item.interval}
            className={styles.row}
            style={{
              background:
                item.averageReturn > 0 ? 'var(--green)' : 'var(--red)',
            }}
          >
            <span className={styles.interval}>
              {item.interval === 'max' ? 'Max' : item.interval}:{' '}
            </span>
            <span className={styles.averageReturn}>{`${numeral(
              item.averageReturn
            ).format('0,0.00')}%`}</span>
          </div>
        ))}
      </div>
    </div>
  )
}

export default AverageReturns
