import styles from './CoreButton.module.scss'

export const CoreButton = ({
  color = 'var(--grey-accent)',
  onClick,
  width = 'fit-content',
  height = 28,
  padding = '4px 8px',
  children,
}) => {
  return (
    <div
      className={styles.coreButton}
      style={{
        backgroundColor: color,
        width: width,
        height: height,
        padding: padding,
        userSelect: 'none',
      }}
      onClick={onClick}
    >
      {children}
    </div>
  )
}

export default CoreButton
