export function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  return (
    <input
      style={{ width: '100%' }}
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined)
      }}
      placeholder={' 🔍'}
    />
  )
}

export const SortColumnFilter = ({ column }) => (
  <span className="react-table__column-header sortable">
    {column.isSortedDesc === undefined ? null : (
      <span>{column.isSortedDesc ? ' ▲' : ' ▼'}</span>
    )}
  </span>
)
