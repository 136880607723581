import moment from 'moment'
export const intervals = [
  {
    label: '1Y',
    from: moment().subtract(1, 'years').format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
    limit: 4,
    takeNth: 70,
  },
  {
    label: '2Y',
    from: moment().subtract(2, 'years').format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
    limit: 8,
    takeNth: 100,
  },
  {
    label: '3Y',
    from: moment().subtract(3, 'years').format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
    limit: 12,
    takeNth: 70,
  },
  {
    label: '4Y',
    from: moment().subtract(4, 'years').format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
    limit: 16,
    takeNth: 100,
  },
  {
    label: '5Y',
    from: moment().subtract(5, 'years').format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
    limit: 20,
    takeNth: 70,
  },
  {
    label: '6Y',
    from: moment().subtract(6, 'years').format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
    limit: 24,
    takeNth: 100,
  },
  {
    label: '8Y',
    from: moment().subtract(8, 'years').format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
    limit: 32,
    takeNth: 70,
  },
  {
    label: 'All',
    from: moment().subtract(100, 'years').format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
    limit: -1,
    takeNth: 100,
  },
]

export const InstitutionalOwnership = [
  {
    key: 'totalInvested',
    label: 'Total Invested',
    canVisualize: true,
    format: true,
  },
  {
    key: 'ownershipPercent',
    type: '%',
    label: 'Ownership',
    canVisualize: false,
    percent: true,
  },
  {
    key: 'investorsHolding',
    label: 'Investors Holding',
    canVisualize: true,
  },
  {
    key: 'numberOf13Fshares',
    label: '# Of 13F Shares',
    canVisualize: true,
    format: true,
  },
  {
    key: 'newPositions',
    label: 'New Positions',
    canVisualize: true,
  },
  {
    key: 'increasedPositions',
    label: 'Increased Positions',
    canVisualize: true,
  },
  {
    key: 'closedPositions',
    label: 'Closed Positions',
    canVisualize: true,
  },
  {
    key: 'reducedPositions',
    label: 'Reduced Positions',
    canVisualize: true,
  },
  {
    key: 'netNewPositions',
    label: 'Net New Positions',
    canVisualize: true,
  },
  {
    key: 'putCallRatio',
    label: 'Put Call Ratio',
    canVisualize: true,
    format: true,
  },
  {
    key: 'totalCalls',
    label: 'Total Calls',
    canVisualize: false,
    format: true,
  },
  {
    key: 'totalPuts',
    label: 'Total Puts',
    canVisualize: false,
    format: true,
  },
]

export const investorNamePortfolioSummaryKeyValue = [
  {
    key: 'marketValue',
    label: 'Market Value',
    canVisualize: true,
    format: true,
  },
  {
    key: 'portfolioSize',
    label: 'Portfolio Size',
    canVisualize: true,
  },
  {
    key: 'securitiesAdded',
    label: 'Securities Added',
    canVisualize: true,
  },
  {
    key: 'securitiesRemoved',
    label: 'Securities Removed',
    canVisualize: true,
  },
  {
    key: 'turnover',
    label: 'Turnover',
    canVisualize: true,
    format: true,
  },
  {
    key: 'averageHoldingPeriod',
    label: 'Average Holding Period',
    canVisualize: true,
  },
  {
    key: 'turnoverAlternateSell',
    label: 'Turnover Alternate Sell',
    format: true,
  },
  {
    key: 'turnoverAlternateBuy',
    label: 'Turnover Alternate Buy',
    format: true,
  },
  {
    key: 'performancePercentage1year',
    label: '1 Year Performance',
    percent: true,
  },
  {
    key: 'performance1yearRelativeToSP500Percentage',
    label: '1 Year Perf. Relative To S&P 500',
    percent: true,
  },
  {
    key: 'performancePercentage3year',
    label: '3 Year Performance',
    percent: true,
  },
  {
    key: 'performance3yearRelativeToSP500Percentage',
    label: '3 Year Perf. Relative To S&P 500',
    percent: true,
  },
  {
    key: 'performancePercentage5year',
    label: '5 Year Performance',
    percent: true,
  },
  {
    key: 'performance5yearRelativeToSP500Percentage',
    label: '5 Year Perf. Relative To S&P 500',
    percent: true,
  },
]

export const INSTITUTIONAL_STOCK_VIEW_BASE_URL = 'StockInstitutionalView'
