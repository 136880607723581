import './EnterTerminalButton.scss'
import { useContext } from 'react'
import { AuthContext } from '../../../../core/AuthComponents/AuthContext'
import { useNavigate } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import { demoUserConfig } from 'network/httpClient'

export const EnterTerminalButton = () => {
  const navigate = useNavigate()
  const { handleSignInDemoHomepage } = useContext(AuthContext)

  const handleClick = () => {
    Auth.currentAuthenticatedUser()
      .then(userInfo => {
        // console.log('User Info:', userInfo?.attributes?.email)
        const isDemoUser = userInfo?.attributes?.email === demoUserConfig.email
        // console.log('is Demo User:', isDemoUser)
        if (!isDemoUser) {
          // console.log('navigating to Terminal as auth user')
          navigate('/terminal/marketOverview')
        } else {
          // console.log('signing in as demo user')
          handleSignInDemoHomepage()
        }
      })
      .catch(error => {
        // console.log('no auth user. handle as demo', error)
        handleSignInDemoHomepage()
      })
  }

  return (
    <div onClick={handleClick}>
      <button className="enter-terminal-btn">Enter Terminal</button>
    </div>
  )
}
