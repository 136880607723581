import React, { useMemo, useState } from 'react'
import BasicTable from 'core/Tables/BasicTable'
import Spinner from 'core/ScrollableContainer/Spinner/spinner'
import { InstitutionalOwnership } from '../constants'
import { useDataSource } from '../hooks/useStockViewDataSource'
import TableKeyValue from '../components/TableKeyValue/TableKeyValue'
import styles from './StockView.module.scss'
import { Top50TableColumns } from '../columns'
import { getNetNewPositions } from '../utils'
import { CoreBarChart } from 'core/Charts/GenericChart'
import { take } from 'lodash'
import { QuoteBar } from 'core/QuoteBar/QuoteBar'
import { CardWrapper } from 'core/CardWrapper/CardWrapper'
import DonutChart from '../components/DthreePieChart/DonutChart'
import { Helmet } from 'react-helmet-async'
import { NoData } from 'core/NoData/NoData'

const StockView = () => {
  const { StockViewUI, setTicker, ticker } = useDataSource()

  const [hoveredSymbol, setHoveredSymbol] = useState(null)

  const handleRowHover = rowData => {
    setHoveredSymbol(rowData.investorName)
  }

  const handleRowLeave = () => {
    setHoveredSymbol(null)
  }

  const chartData = useMemo(() => {
    return StockViewUI?.InstitutionalStockOwnership?.slice(0, 20)?.reverse()
  }, [StockViewUI?.InstitutionalStockOwnership])

  return (
    <div className={styles.standardViewContainer}>
      <Helmet>
        <title>{`Outseek | ${ticker} Institutional Ownership`}</title>
        <meta
          name="description"
          content={
            'This page provides retail financial analysts with information about a company and what large institutions have equity. Provide user with a wider range of portfolio stats of these institutions.'
          }
        />
      </Helmet>
      <QuoteBar
        updateTicker={tickerIn => setTicker(tickerIn)}
        ticker={ticker}
      />
      <div className={styles.stockViewWrapper}>
        <div className={styles.stockViewPanel1}>
          <CardWrapper
            height={'calc(100vh - 378px)'}
            width={'calc(100% - 458px)'}
            label={'Institutional Holders'}
            content={
              StockViewUI?.isLoading ? (
                <Spinner />
              ) : StockViewUI.StockOwnershipByHolders?.length > 0 ? (
                <BasicTable
                  columns={Top50TableColumns}
                  data={StockViewUI.StockOwnershipByHolders}
                  overflowXScroll
                  onMouseOverRow={handleRowHover}
                  onMouseLeaveRow={handleRowLeave}
                  hoveredSymbol={hoveredSymbol}
                  hoverKey="investorName"
                  haveKey={true}
                />
              ) : (
                <NoData label="No Data Available" />
              )
            }
          />
          <CardWrapper
            height={'calc(100vh - 378px)'}
            width={450}
            label={'Ownership Breakdown'}
            content={
              StockViewUI?.isLoading ? (
                <Spinner />
              ) : StockViewUI?.pieData?.[0]?.value > 0 ? (
                <DonutChart
                  data={StockViewUI?.pieData}
                  hoveredSymbol={hoveredSymbol}
                  setHoveredSymbol={setHoveredSymbol}
                  colorStart="#a83232"
                  colorEnd="#6d32a8"
                  // noLabel={true}
                  removeValue={true}
                  startCasing={true}
                />
              ) : (
                <NoData label="No Data Available" />
              )
            }
          />
        </div>
        <CardWrapper
          height={244}
          width={'100%'}
          label={'Institutional Ownership Stats'}
          content={
            StockViewUI?.isLoading ? (
              <Spinner />
            ) : (
              <div className={styles.stockViewPanel3}>
                <div className={styles.tableKeyWrapper}>
                  {StockViewUI?.InstitutionalStockOwnership?.length > 0 ? (
                    <TableKeyValue
                      data={getNetNewPositions(
                        StockViewUI?.InstitutionalStockOwnership
                      )}
                      labels={InstitutionalOwnership}
                      allowCharts={true}
                    />
                  ) : (
                    <NoData label="No Data Available" />
                  )}
                </div>
                {chartData?.length > 0 ? (
                  <div className={styles.coreBarWrapper}>
                    <BarChartSection
                      label="New vs Closed Positions"
                      chartData={chartData}
                      metrics={[
                        { key: 'newPositions', color: 'var(--green)' },
                        { key: 'closedPositions', color: 'var(--red)' },
                      ]}
                    />
                    <BarChartSection
                      label="Increased vs Reduced Positions"
                      chartData={chartData}
                      metrics={[
                        { key: 'increasedPositions', color: 'var(--green)' },
                        { key: 'reducedPositions', color: 'var(--red)' },
                      ]}
                    />
                  </div>
                ) : (
                  <div className={styles.coreBarWrapper}>
                    <NoData label="No Data Available" />
                  </div>
                )}
              </div>
            )
          }
        />
      </div>
    </div>
  )
}

export default StockView

const BarChartSection = ({ label, chartData, metrics }) => {
  return (
    <div className={styles.coreBarWrapperItem}>
      <div className={styles.coreBarWrapperLabel}>{label}</div>
      <CoreBarChart
        opacity={0.65}
        data={chartData}
        mirrored={true}
        metrics={metrics}
        XAxisKey={'date'}
        zeroTick={true}
      />
    </div>
  )
}
