import regression from 'regression'

export const calculateSeasonality = (data, key) => {
  if (!data || data.length === 0 || !key) return []

  const filteredData = data?.filter(item => item?.[key] != null)

  const groupedByQuarter = filteredData?.reduce((acc, item) => {
    const quarterKey = `Q${item?.matchedQuarter}`
    acc[quarterKey] = acc?.[quarterKey] || []
    acc[quarterKey]?.push(item?.[key])
    return acc
  }, {})

  const averageByQuarter = Object.keys(groupedByQuarter)?.reduce(
    (acc, quarter) => {
      const total = groupedByQuarter[quarter]?.reduce(
        (sum, value) => sum + value,
        0
      )
      acc[quarter] = total / groupedByQuarter?.[quarter]?.length
      return acc
    },
    {}
  )

  if (Object.keys(averageByQuarter).length === 0) return []

  const total = filteredData?.reduce((sum, item) => sum + item?.[key], 0)
  const overallAverage = total / filteredData?.length

  return Object.entries(averageByQuarter)?.map(([quarter, avg]) => {
    return {
      quarter: quarter,
      value: avg / overallAverage,
    }
  })
}

const removeAfterSpace = str => {
  return str.split(' ')[0]
}

export const formatQuarterData = finalData => {
  return finalData?.map(([quarter, year, timestamp]) => ({
    quarter,
    year,
    date: removeAfterSpace(timestamp),
  }))
}

export const combineDataWithEarnings = (
  companyEarnings,
  formattedQuarterData,
  priceData
) => {
  // Convert string date to Date object
  const parseDate = dateString => new Date(dateString)

  return companyEarnings?.map(earningsItem => {
    const earningsDate = parseDate(earningsItem.date)

    const matchedQuarterData = formattedQuarterData?.reduce(
      (closest, current) => {
        const currentDiff = Math.abs(earningsDate - parseDate(current?.date))
        const closestDiff = closest
          ? Math.abs(earningsDate - parseDate(closest?.date))
          : Infinity
        return currentDiff < closestDiff ? current : closest
      },
      null
    )

    const newDate = new Date(earningsDate)
    newDate.setDate(newDate.getDate() + 1)
    const newDateString = newDate.toISOString().split('T')[0]

    const matchedPriceData = priceData?.find(priceItem =>
      earningsItem?.time === 'amc'
        ? priceItem?.date === newDateString
        : priceItem?.date === earningsDate.toISOString().split('T')[0]
    )

    return {
      ...earningsItem,
      matchedQuarterTimestamp: matchedQuarterData
        ? matchedQuarterData?.date
        : null,
      matchedQuarter: matchedQuarterData ? matchedQuarterData?.quarter : null,
      matchedYear: matchedQuarterData ? matchedQuarterData?.year : null,
      changePercent: matchedPriceData ? matchedPriceData.changePercent : null,
    }
  })
}

export const calculateStatistics = (combinedData, prediction) => {
  const filteredData = combinedData?.filter(
    item => item?.eps != null && item?.epsEstimated != null
  )

  const countGreaterThan = filteredData?.filter(
    item => item?.eps > item?.epsEstimated
  )?.length

  const countLessThan = filteredData?.filter(
    item => item?.eps < item?.epsEstimated
  )?.length

  const countEquals = filteredData?.filter(
    item => item?.eps === item?.epsEstimated
  )?.length

  const totalPercentageDifference = filteredData?.reduce((sum, item) => {
    return (
      sum +
      Math.abs(((item?.eps - item?.epsEstimated) / item?.epsEstimated) * 100)
    )
  }, 0)

  const avgPercentageDifference =
    filteredData?.length > 0
      ? totalPercentageDifference / filteredData?.length
      : 0

  const totalChangePercent = filteredData?.reduce((sum, item) => {
    return sum + (item?.changePercent || 0)
  }, 0)

  const avgChangePercent =
    filteredData?.length > 0 ? totalChangePercent / filteredData?.length : 0

  const stats = {
    CountEPSGreaterThanEstimated: countGreaterThan || 0,
    CountEPSLessThanEstimated: countLessThan || 0,
    CountEPSEqualToEstimated: countEquals || 0,
    AveragePercentageDifference: avgPercentageDifference || 0,
    AverageChangePercent: avgChangePercent || 0,
    Prediction: prediction?.predictedChangePercent,
  }

  return [stats]
}

const calculateEPSSurprise = (actualEPS, estimatedEPS) =>
  actualEPS - estimatedEPS

export const predictChangePercent = (data, futureDate) => {
  if (!data || data.length === 0) {
    return 'No data provided for analysis.'
  }
  const historicalData = data?.filter(
    d => d.eps != null && d.changePercent != null
  )

  if (historicalData.length < 2) {
    return 'histData.length < 2'
  }

  const preparedData = historicalData?.map(item => {
    const epsSurprise = calculateEPSSurprise(item?.eps, item?.epsEstimated)
    return [epsSurprise, item?.changePercent]
  })

  const result = regression?.linear(preparedData, { precision: 4 })
  const predict = x => {
    const [slope, intercept] = result?.equation
    return slope * x + intercept
  }

  const futureData = data?.find(d => d?.date === futureDate)
  if (!futureData || futureData?.epsEstimated == null) {
    return 'date error'
  }

  const futureEpsSurprise = calculateEPSSurprise(
    futureData?.epsEstimated,
    futureData?.epsEstimated
  )
  const predictedChangePercent = predict(futureEpsSurprise)

  const date = futureData?.date

  return {
    predictedChangePercent,
    date,
  }
}

export const filterData = (data, key) => data?.filter(item => item[key] != null)
