import React, { useState, useRef, useEffect } from 'react'
import styles from './FeatureTabs.module.scss'
import Image from '../Image/Image'
import { useInView } from 'framer-motion'

export const FeatureTabs = ({ config }) => {
  const [selectedTab, setSelectedTab] = useState(config[0].label || '')
  const contentRefs = useRef(config.map(() => React.createRef()))

  useEffect(() => {
    contentRefs.current = contentRefs.current.slice(0, config.length)
  }, [config])

  const handleInView = label => {
    setSelectedTab(label)
  }

  const scrollToContent = index => {
    const contentElement = contentRefs.current[index].current

    if (contentElement) {
      const topPos =
        contentElement.getBoundingClientRect().top + window.pageYOffset - 120

      window.scrollTo({
        top: topPos,
        behavior: 'smooth',
      })
    }
  }

  return (
    <div className={styles.featureTabsWrapper}>
      <div className={styles.tabsWrapperWithTitle}>
        <div className={styles.tabsTitle}>Key Features</div>
        <div className={styles.tabsWrapper}>
          {config?.map((tab, index) => {
            const isActive = tab.label === selectedTab

            return (
              <div
                key={tab.label}
                className={styles.tab}
                style={{
                  color: isActive ? 'var(--primary-color)' : 'var(--off-white)',
                }}
                onClick={() => scrollToContent(index)}
              >
                <div
                  className={styles.marker}
                  style={{ opacity: isActive ? 1 : 0 }}
                />
                {tab.label}
              </div>
            )
          })}
        </div>
      </div>
      <div className={styles.contentWrapper}>
        {config.map((tab, index) =>
          tab.renderComponent ? (
            tab.renderComponent()
          ) : (
            <TabContent
              key={tab.label}
              tab={tab}
              onInView={() => handleInView(tab.label)}
              ref={contentRefs.current[index]}
            />
          )
        )}
      </div>
    </div>
  )
}

const TabContent = React.forwardRef(({ tab, onInView }, ref) => {
  const isInView = useInView(ref, { amount: 0.5 })

  useEffect(() => {
    if (isInView) {
      onInView(tab.label)
    }
  }, [isInView, onInView, tab.label])

  return (
    <div ref={ref} className={styles.tabContent}>
      <div className={styles.title}>{tab.label}</div>
      <div>
        <div className={styles.subtitle}>{tab.paragraph}</div>
      </div>
      <Image webpSource={tab.image} alt={tab.label} className={styles.image} />
    </div>
  )
})
