import { fetches } from './queries'
import { useQuery } from 'react-query'
import { useMemo } from 'react'

export const useMergers = () => {
  const { data: MAndA, isLoading: MAndAIsLoading } = useQuery(
    ['mergers-acquisitions'],
    fetches.fetchMAData
  )

  const CE_UI = useMemo(() => {
    return {
      MAndA,
      MAndAIsLoading,
    }
  }, [MAndA, MAndAIsLoading])

  return {
    CE_UI,
  }
}

export const useCrowdFunding = () => {
  const { data: CF, isLoading: CFIsLoading } = useQuery(
    ['crowd-funding'],
    fetches.fetchCrowdData
  )

  const CE_UI = useMemo(() => {
    return {
      CF,
      CFIsLoading,
    }
  }, [CF, CFIsLoading])

  return {
    CE_UI,
  }
}

export const useFunding = () => {
  const { data: EF, isLoading: EFIsLoading } = useQuery(
    ['equity-funding'],
    fetches.fetchFundingData
  )

  const CE_UI = useMemo(() => {
    return {
      EF,
      EFIsLoading,
    }
  }, [EF, EFIsLoading])

  return {
    CE_UI,
  }
}
