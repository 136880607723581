import { useQuery } from 'react-query'
import { useMemo, useState } from 'react'
import queries from './queries'
import { useURLSyncedGlobalTicker } from 'pages/views/hooks'
import { VIEW_BASE_URL } from 'constants/ViewsConstants'
import { ProfileIntervals } from '../constant'

export const useDataSource = () => {
  const [interval, setInterval] = useState(ProfileIntervals[0])

  const { handleSetTicker, ticker } = useURLSyncedGlobalTicker()

  const { data: historicalData, isLoading: historicalDataIsLoading } = useQuery(
    ['historical-price', ticker, interval],
    queries?.fetchPriceData
  )

  const { data: IncomeData, isLoading: IncomeDataIsLoading } = useQuery(
    ['income-statement', ticker],
    queries?.fetchIncome
  )

  const { data: historicalEmployees, isLoading: historicalEmployeesIsLoading } =
    useQuery(
      ['historical-employees', ticker],
      queries?.fetchHistoricalEmployees
    )

  const { data: outlookData, isLoading: outlookDataIsLoading } = useQuery(
    ['outlook-data', ticker],
    queries?.fetchCompanyData
  )

  const { data: quoteData, isLoading: quoteDataIsLoading } = useQuery(
    ['quote-data', ticker],
    queries?.fetchCompanyQuote
  )

  const mergedData = useMemo(() => {
    return historicalEmployees?.map(employee => {
      const reportYear = new Date(employee?.periodOfReport)?.getFullYear()
      const incomeData = IncomeData?.find(income => {
        const incomeYear = new Date(income?.date)?.getFullYear()
        return incomeYear === reportYear
      })
      return {
        ...employee,
        revenue: incomeData ? incomeData?.revenue : null,
      }
    })
  }, [IncomeData, historicalEmployees])

  const companyProfile = outlookData?.profile

  // const splitsHistory = outlookData?.splitsHistory

  const name = companyProfile?.companyName

  const isLoading =
    outlookDataIsLoading ||
    quoteDataIsLoading ||
    historicalEmployeesIsLoading ||
    IncomeDataIsLoading

  const Profile_UI = useMemo(() => {
    return {
      // splitsHistory,
      historicalEmployees,
      historicalData,
      companyProfile,
      outlookData,
      quoteData,
      IncomeData,
      mergedData,
    }
  }, [
    // splitsHistory,
    historicalEmployees,
    historicalData,
    companyProfile,
    outlookData,
    quoteData,
    IncomeData,
    mergedData,
  ])

  return {
    Profile_UI,
    setTicker: handleSetTicker,
    name,
    interval,
    setInterval,
    ticker,
    isLoading,
    historicalDataIsLoading,
  }
}
