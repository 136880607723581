import React, { useEffect, useState, useContext } from 'react'
import FilterContext from '../../context'
import { BETWEEN, STRING } from '../constants'
import { BetweenFilter } from './BetweenFilter/BetweenFilter'
import { StringFilter } from './StringFilter/StringFilter'
import { ConstantBetweenFilter } from './ConstantBetweenFilter/ConstantBetweenFilter'

export const FilterInput = ({
  type,
  label,
  metric,
  handlePushFilter,
  options,
  hasFactor,
  thresholdToUse,
  thresholdType,
  useCustomFilter,
  setUseCustomFilter,
}) => {
  const { filters } = useContext(FilterContext)

  const filterConfig = filters.filter(filter => filter.column === metric)[0]

  const [value, setValue] = useState(
    filterConfig?.type === BETWEEN
      ? [filterConfig?.config?.min, filterConfig?.config?.max]
      : [filterConfig?.config?.keyword]
  )

  const [selectedUnit, setSelectedUnit] = useState(
    filterConfig?.config?.unit || 'M'
  )

  const handleSelectChange = selectedOption => {
    setValue([selectedOption.value])

    handlePushFilter({
      column: metric,
      type: type,
      config: {
        keyword: selectedOption.value,
      },
    })
  }

  useEffect(() => {
    if (!filterConfig) {
      setValue([])
      setSelectedUnit('M')
    }
  }, [filterConfig, filters])

  const handleValueChangeWithFactor = (e, index) => {
    const newValue = parseFloat(e.target.value)
    setValue(old => {
      const newArr = [...old]
      newArr[index] =
        newValue *
        (selectedUnit === 'M'
          ? 1000000
          : selectedUnit === 'B'
          ? 1000000000
          : 1000)
      return newArr
    })
  }

  const handleValueChangeWithoutFactor = (e, index) => {
    const newValue = parseFloat(e.target.value)
    setValue(old => {
      const newArr = [...old]
      newArr[index] = newValue
      return newArr
    })
  }

  const switchToCustomFilter = () => {
    setUseCustomFilter(true)
  }

  if (type === BETWEEN) {
    if (useCustomFilter) {
      return (
        <BetweenFilter
          label={label}
          hasFactor={hasFactor}
          selectedUnit={selectedUnit}
          setSelectedUnit={setSelectedUnit}
          value={value}
          handleValueChange={
            hasFactor
              ? handleValueChangeWithFactor
              : handleValueChangeWithoutFactor
          }
          handlePushFilter={handlePushFilter}
          type={type}
          metric={metric}
          onSwitchToConstantFilter={() => setUseCustomFilter(false)}
        />
      )
    } else {
      return (
        <ConstantBetweenFilter
          label={label}
          handlePushFilter={handlePushFilter}
          type={type}
          metric={metric}
          onSwitchToCustomFilter={switchToCustomFilter}
          thresholdToUse={thresholdToUse}
          thresholdType={thresholdType}
          filters={filters}
        />
      )
    }
  } else if (type === STRING) {
    return (
      <StringFilter
        label={label}
        options={options}
        metric={metric}
        value={value}
        handleSelectChange={handleSelectChange}
        filters={filters}
      />
    )
  }
}
