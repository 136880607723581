import { useSummary } from 'network/useSQL/useSQL'
import buildHistoricalQuery from 'network/useSQL/historicalQueries'
import { RATIOS_HISTORICAL, SUMMARY } from 'network/useSQL/constants'
import { useMemo } from 'react'
import { useDataSource } from './hooks'
import { ratioMetrics } from '../constants'

export const useAnalyticsData = (groupByColumns = []) => {
  const { CompanyData_UI } = useDataSource()
  const profileData = CompanyData_UI?.profileData?.[0] || null

  const dynamicFilters = useMemo(() => {
    return groupByColumns.reduce((data, column) => {
      if (profileData && profileData[column]) {
        data[column] = profileData[column]
      }
      return data
    }, {})
  }, [groupByColumns, profileData])

  const ratioSummary = useSummary({
    queries: buildHistoricalQuery({
      table: RATIOS_HISTORICAL,
      metrics: ratioMetrics,
      aggregate: SUMMARY,
      groupBy: groupByColumns,
      whereClause: dynamicFilters,
      orderByYear: `DESC`,
    }),
    groupByColumns: groupByColumns,
    enabled: !!profileData,
  })

  const AnalyticsData = useMemo(() => {
    return {
      ratios: ratioSummary,
    }
  }, [ratioSummary])

  return AnalyticsData
}
