export const roadmapItems = {
  v1: {
    title: 'V1: Initial Copilot Launch',
    date: 'July 8th, 2024',
    description:
      'Launch of the AI Copilot with basic functionalities in financial analysis.',
    status: 'Completed',
    capabilities: [
      'Responds to single entity queries only, meaning users can ask about one stock or financial question at a time.',
      'Provides real-time data on 80k+ stocks.',
    ],
    dataKnowledge: [
      'Stock Quote',
      'Company Profile',
      'Financial Ratios',
      'News',
      'Financial Statements',
      'Financial Statement Growth',
      'Market Data',
      'Insider Trading',
      'Upgrades, Downgrades and Price Targets',
      'Discounted Cash Flow',
      'Analyst Estimates & Recommendations',
      'Earnings',
    ],
  },
  v2: {
    title: 'V2: Introducing Financial Components ',
    date: 'August 1st, 2024',
    description:
      'Version 2 enhances the AI Copilot by introducing a screener functionality to allow users to filter stocks based on specific financial criteria.',
    status: 'In Progress',
    capabilities: [
      'Interactive financial components.',
      'Screener functionality',
      'Ability to compare multiple stocks at once.',
    ],
    dataKnowledge: [
      'Analytical Data (sector and industry averages)',
      'Mutual Fund Data',
      'Economic Data',
      'Crypto Data',
    ],
  },
  v3: {
    title: 'V3',
    date: 'TBD',
  },
}
