import React, { useLayoutEffect, useRef } from 'react'
import Typed from 'typed.js'
import styles from './typed.module.scss'

export const TypedText = () => {
  const typed = useRef()

  useLayoutEffect(() => {
    typed.current = new Typed('#typed', {
      strings: [
        'Enhance your investment research with <strong>powerful</strong> financial insights.',
        'Enhance your investment research with <strong>35+ years</strong> of data.',
        'Enhance your investment research with  <strong>innovative</strong> analytics.',
        'Get started today.',
      ],
      typeSpeed: 15,
      backSpeed: 20,
      smartBackspace: true,
    })
  }, [])

  return <div className={styles.typedFont} id="typed" ref={typed}></div>
}
