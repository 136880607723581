import Image from '../Image/Image'
import styles from './FeatureItem.module.scss'

export const FeatureItem = ({
  title,
  description,
  webpSource,
  renderComponent,
  stretch,
}) => {
  return (
    <div
      className={styles.desktopFeatureWrapper}
      style={{
        gridColumn:
          stretch === true
            ? 'auto / span 2'
            : stretch === 'full'
            ? 'auto /span 3'
            : null,
      }}
    >
      {title || description ? (
        <div className={styles.desktopFeatureLabelWrapper}>
          <div className={styles.desktopLabelTitle}>{title}</div>
          <div className={styles.desktopLabelSubTitle}>{description}</div>
        </div>
      ) : null}
      {webpSource && (
        <Image
          webpSource={webpSource}
          alt={webpSource}
          className={styles.imageWrapper}
        />
      )}
      {renderComponent && (
        <div className={styles.desktopFeatureComponent}>
          {typeof renderComponent === 'function' ? renderComponent() : null}
        </div>
      )}
    </div>
  )
}
