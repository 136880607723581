import numeral from 'numeral'
import dayjs from 'dayjs'

export const earningsTip = (o, metric1, metric2) => {
  const { payload } = o

  const newData = payload?.[0]?.payload

  const calculatePercentageDifference = (actual, estimated) => {
    if (estimated === 0) {
      return actual === 0 ? 0 : 100
    }
    return (actual - estimated) / estimated
  }

  const getMetricName = metric => {
    switch (metric) {
      case 'eps':
        return 'EPS'
      case 'epsEstimated':
        return 'Estimated EPS'
      case 'revenue':
        return 'Revenue'
      case 'revenueEstimated':
        return 'Estimated Revenue'
      default:
        return metric
    }
  }

  return (
    <div className="customized-tooltip-content-core">
      <div
        className="list"
        style={{ fontSize: '11.5px', color: 'var(--white)' }}
      >
        <div
          className="listItem"
          style={{
            display: 'flex',
            gap: '4px',
            flexDirection: 'row',
            color: 'var(--white)',
            fontSize: '11.5px',
          }}
        >
          {dayjs(newData?.date).format('MMM D, YYYY')}
        </div>
        <div
          className="listItem"
          style={{
            display: 'flex',
            gap: '4px',
            flexDirection: 'row',
            color: 'var(--white)',
            fontSize: '11.5px',
          }}
        >
          <span>{getMetricName(metric1)}:</span>{' '}
          {numeral(newData?.[metric1]).format('0.00 a')}
        </div>
        <div
          className="listItem"
          style={{
            display: 'flex',
            gap: '4px',
            flexDirection: 'row',
            color: 'var(--white)',
            fontSize: '11.5px',
          }}
        >
          <span>{getMetricName(metric2)}:</span>{' '}
          {numeral(newData?.[metric2]).format('0.00 a')}
        </div>
        {newData?.[metric1] && newData?.[metric2] ? (
          <div
            className="listItem"
            style={{
              display: 'flex',
              gap: '4px',
              flexDirection: 'row',
              fontSize: '11.5px',
              color:
                newData?.[metric1] > newData?.[metric2]
                  ? 'var(--green)'
                  : newData?.[metric1] < newData?.[metric2]
                  ? 'var(--red)'
                  : 'var(--primary-color)',
            }}
          >
            {newData?.[metric1] > newData?.[metric2]
              ? 'Beat'
              : newData?.[metric1] < newData?.[metric2]
              ? 'Miss'
              : 'Met'}
            {newData?.[metric1] > newData?.[metric2] ||
            newData?.[metric1] < newData?.[metric2] ? (
              <>
                :{' '}
                <span>
                  {numeral(
                    Math.abs(newData?.[metric1] - newData?.[metric2])
                  ).format('0.00 a')}
                </span>{' '}
                <span>
                  {Math.abs(
                    calculatePercentageDifference(
                      newData?.[metric1],
                      newData?.[metric2]
                    )
                  ) > 0 && '+'}
                  {numeral(
                    Math.abs(
                      calculatePercentageDifference(
                        newData?.[metric1],
                        newData?.[metric2]
                      )
                    )
                  ).format('0.00 %')}
                </span>
              </>
            ) : null}
          </div>
        ) : null}
        <div
          className="listItem"
          style={{
            display: 'flex',
            gap: '4px',
            flexDirection: 'row',
            color: 'var(--white)',
            fontSize: '11.5px',
          }}
        >
          <span>Stock Price</span>
          <span
            style={{
              color: newData?.changePercent > 0 ? 'var(--green)' : 'var(--red)',
            }}
          >
            {newData?.changePercent > 0 && '+'}
            {numeral(newData?.changePercent).format('0.00')}%
          </span>
        </div>
      </div>
    </div>
  )
}
