/* eslint-disable no-console */
import React, { useEffect, useState } from 'react'
import styles from './AccountPage.module.scss'
import { Input } from 'core/Input/Input'
import { Auth } from 'aws-amplify'
import CloseIcon from '@mui/icons-material/Close'
import Modal from '@mui/material/Modal'
import { CoreButton } from 'core/Button/CoreButton'
import Box from '@mui/material/Box'
import { occupations } from '../../../core/AuthComponents/SignUp/constants'

const occupationFunction = occupation => {
  const foundOccupation = occupations.find(item => item.value === occupation)
  return foundOccupation ? foundOccupation.label : 'None'
}

const PASSWORD_RESET_CONFIRM = 'Password successfully reset!'
const UPDATE_ERROR =
  'Error trying to update information. Please provide fill out all fields correctly.'
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxShadow: 24,
  p: 4,
  width: '100%',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}
const ConfirmTags = ({ message, handleClose, ...rest }) => {
  return (
    <div className={styles.modelFrameConfirmTagsContainer}>
      <div className={styles.modelFrameConfirmTags}>
        <div className={styles.titleConfirmTags}>
          <div
            className={styles.titleConfirmTagsMessage}
            style={{
              color: message === UPDATE_ERROR ? 'var(--red)' : 'var(--green)',
            }}
          >
            {message}
          </div>
          <div
            className={styles.titleConfirmTagsClose}
            onClick={() => handleClose()}
          >
            <CloseIcon />
          </div>
        </div>
      </div>
    </div>
  )
}

export const CognitoPage = ({ userAttributes, userAttributesIsLoading }) => {
  const [userState, setUserState] = useState({
    email: '',
    givenName: '',
    familyName: '',
    occupation: '',
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
    passwordNoMatch: false,
    error: '',
    confirmationMessage: null,
  })

  useEffect(() => {
    setUserState(prevState => ({
      ...prevState,
      email: userAttributes?.email || '',
      givenName: userAttributes?.given_name || '',
      familyName: userAttributes?.family_name || '',
      occupation: userAttributes?.['custom:occupation'] || '',
    }))
  }, [userAttributes])

  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (userState.confirmationMessage) {
      setOpen(true)
    }
  }, [userState.confirmationMessage])

  const handleInputChange = (name, value) => {
    setUserState(prevState => ({
      ...prevState,
      [name]: value,
      ...((name === 'newPassword' || name === 'confirmNewPassword') && {
        passwordNoMatch: false,
        error: '',
      }),
    }))
  }

  const handleConfirmPassword = newPasswordConfirmIn => {
    setUserState(prevState => ({
      ...prevState,
      confirmNewPassword: newPasswordConfirmIn,
      passwordNoMatch: newPasswordConfirmIn !== prevState.newPassword,
    }))
  }

  const handleUpdatePassword = () => {
    if (Auth) {
      Auth.currentAuthenticatedUser()
        .then(user => {
          return Auth.changePassword(
            user,
            userState.oldPassword,
            userState.newPassword
          )
        })
        .then(data => {
          setUserState(prevState => ({
            ...prevState,
            confirmationMessage: PASSWORD_RESET_CONFIRM,
          }))
        })
        .catch(err => {
          setUserState(prevState => ({ ...prevState, error: err.message }))
        })
    }
  }

  const handleClose = () => {
    setOpen(false)
    setUserState(prevState => ({ ...prevState, confirmationMessage: null }))
  }

  return (
    <div className={styles.cognitoWrapper}>
      <div className={styles.accountPageContainer}>
        <div className={styles.accountBlocks}>
          <AccountDetails userState={userState} />
          <ResetPasswordSection
            userState={userState}
            handleInputChange={handleInputChange}
            handleConfirmPassword={handleConfirmPassword}
            handleUpdatePassword={handleUpdatePassword}
          />
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={style}
        disableAutoFocus={true}
      >
        <Box
          sx={{
            '&:focus': {
              outline: 'none',
            },
          }}
        >
          <ConfirmTags
            message={userState.confirmationMessage}
            handleClose={handleClose}
          />
        </Box>
      </Modal>
    </div>
  )
}

const AccountDetails = ({ userState }) => (
  <div className={styles.accountBlock}>
    <div className={styles.inputSection}>
      <div className={styles.accountDetails}>Account Details</div>
      <div className={styles.accountActionWrapper}>
        <div className={styles.inputLabel}>Email</div>
        <div className={styles.authInputWrapper}>
          <div className={styles.authInfoStale}>{userState.email}</div>
        </div>
      </div>
      <div className={styles.accountActionWrapper}>
        <div className={styles.inputLabel}>First Name</div>
        <div className={styles.authInputWrapper}>
          <div className={styles.authInfoStale}>{userState.givenName}</div>
        </div>
      </div>
      <div className={styles.accountActionWrapper}>
        <div className={styles.inputLabel}>Last Name</div>
        <div className={styles.authInputWrapper}>
          <div className={styles.authInfoStale}>{userState.familyName}</div>
        </div>
      </div>
      <div className={styles.accountActionWrapper}>
        <div className={styles.inputLabel}>Occupation</div>
        <div className={styles.authInputWrapper}>
          <div className={styles.authInfoStale}>
            {occupationFunction(userState.occupation)}
          </div>
        </div>
      </div>
    </div>
  </div>
)

const ResetPasswordSection = ({
  userState,
  handleInputChange,
  handleConfirmPassword,
  handleUpdatePassword,
}) => (
  <div className={styles.accountBlock}>
    <div className={styles.inputSection}>
      <div className={styles.accountDetails}>Reset Password</div>

      <div className={styles.accountActionWrapper}>
        <div className={styles.inputLabel}>Old Password</div>
        <div className={styles.authInputWrapper}>
          <Input
            placeholder={userState.oldPassword}
            onChange={e => handleInputChange('oldPassword', e.target.value)}
          />
        </div>
      </div>
      <div className={styles.accountActionWrapper}>
        <div className={styles.inputLabel}>New Password</div>
        <div className={styles.authInputWrapper}>
          <Input
            placeholder={userState.newPassword}
            onChange={e => handleInputChange('newPassword', e.target.value)}
            style={{
              border: userState.passwordNoMatch
                ? '2px solid var(--red)'
                : '2px solid var(--button-hover)',
            }}
          />
        </div>
      </div>
      <div className={styles.accountActionWrapper}>
        <div className={styles.inputLabel}>Confirm New Password</div>
        <div className={styles.authInputWrapper}>
          <Input
            placeholder={userState.confirmNewPassword}
            onChange={e => handleConfirmPassword(e.target.value)}
            style={{
              border: userState.passwordNoMatch
                ? '2px solid var(--red)'
                : '2px solid var(--button-hover)',
            }}
          />
        </div>
      </div>
      {userState.error && (
        <div className={styles.inputLabel} style={{ color: 'var(--red)' }}>
          {userState.error}
        </div>
      )}
    </div>
    <div className={styles.confirmButtonWrapper}>
      <CoreButton onClick={handleUpdatePassword} color={'var(--primary-color)'}>
        Reset Password
      </CoreButton>
    </div>
  </div>
)
