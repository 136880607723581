/* eslint-disable no-console */
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  addComponent,
  fetchDashboard,
  newDashboard,
  saveDashboard,
  fetchNames,
} from 'redux/actions/app-actions'

export const useLoadDashboards = () => {
  const dispatch = useDispatch()
  useEffect(() => dispatch(fetchNames()), [dispatch])
}
export const useDashboard = () => {
  const dispatch = useDispatch()
  const [loaded, setLoaded] = useState(false)

  const name = useSelector(state => state.currentDashboard)
  const data = useSelector(state => state.data)
  const loadedDashboards = useSelector(state => state?.loadedDashboards)

  useEffect(() => {
    if (name?.length > 0) {
      setLoaded(true)
    } else {
      setLoaded(false)
    }
  }, [name])

  const handleAddComponent = () => {
    dispatch(addComponent())
  }

  const handleLoadDashboard = id => {
    dispatch(fetchDashboard(id))
    setTimeout(() => dispatch(fetchDashboard(id), 500))
  }
  const handleNewDashboard = name => {
    dispatch(newDashboard(name))
  }

  const handleSaveDashboard = () => {
    dispatch(saveDashboard())
  }

  const dashboardActions = {
    handleAddComponent,
    handleLoadDashboard,
    handleNewDashboard,
    handleSaveDashboard,
  }
  return {
    name,
    data,
    loadedDashboards,
    loaded,
    setLoaded,
    dashboardActions,
  }
}

export default useDashboard
