import React from 'react'
import Container from 'react-bootstrap/Container'
import { CoreBarChart } from 'core/Charts/GenericChart'
import { useQuery } from 'react-query'
import _ from 'lodash'
import numeral from 'numeral'
import style from './BitesizeComponent.module.scss'
import 'bootstrap/dist/css/bootstrap.min.css'
import http from 'network/httpClient'

const jwt = localStorage.getItem('jwt')
const config = {
  headers: { Authorization: `Bearer ${jwt}` },
}

const buildURL = (url, ticker) => {
  return url.replace('AAPL', ticker)
}

const fetchBitSizeData = async (url, ticker) => {
  let data = await http.get(buildURL(url, ticker), config)
  return data?.data.message
}

export const BitesizeComponent = ({ call, keys, label, ticker, labels }) => {
  const styles = {
    bootstrapContainer: { padding: '0' },
    bitesizeComponent: {
      width: '100%',
      height: 206,
    },
    chartWrapper: {
      width: '240px',
      height: '40px',
    },
  }
  const { data, isLoading } = useQuery(
    [label, call, ticker, labels],
    async () => {
      const res = fetchBitSizeData(call, ticker)
      return res
    }
  )

  const colorDataFunction = (data, key) => {
    if (data[0][key] > data[8][key]) {
      return 'var(--green)'
    } else {
      return 'var(--red)'
    }
  }

  return (
    <div style={styles.bitesizeComponent}>
      {!isLoading && (
        <Container fluid style={styles.bootstrapContainer}>
          {keys.map(({ key, labels }, i) => {
            return (
              <div className={style.dataRowBitsizeData}>
                <div className={style.dataRowLabelDataWrapper}>
                  <div
                    className={style.widgetMetricLabel}
                    title={_.startCase(labels)}
                  >
                    {`${_.startCase(labels)}`}
                  </div>
                  <div className={style.widgetDataLabel}>
                    {`${numeral(data[0][key]).format('0.00a')}`}
                  </div>
                </div>
                <div style={styles.chartWrapper}>
                  <CoreBarChart
                    data={data.slice(0, 8).reverse()}
                    XAxisKey={'date'}
                    showXAxis={false}
                    showYAxis={false}
                    widgetToolTip={true}
                    metrics={[
                      { key: key, color: colorDataFunction(data, key) },
                    ]}
                  />
                </div>
              </div>
            )
          })}
        </Container>
      )}
    </div>
  )
}

export default React.memo(BitesizeComponent)
