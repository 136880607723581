import http from './httpClient'

export class fmpAPI {
  currentData = null

  // needs ticker and type
  async fetch_company_metrics(type, ticker, periodLength) {
    try {
      const data = await http.get(
        'papi/data/api/v3/' +
          type +
          '/' +
          ticker +
          '?period=' +
          periodLength +
          '&limit=400'
      )
      this.currentData = data.data.message
      return data.data.message
    } catch {
      return null
    }
  }

  // needs only type
  async fetch_market_info(type) {
    try {
      const data = await http.get('papi/data/api/v4/' + type)
      return data.data.message
    } catch {
      return null
    }
  }
}
