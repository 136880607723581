import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { motion, useScroll } from 'framer-motion'
import { useMediaQuery } from 'react-responsive'
import { FaInstagram, FaLinkedin } from 'react-icons/fa'
import { FaSquareXTwitter } from 'react-icons/fa6'
import { UilBars } from '@iconscout/react-unicons'
import Logo from '../../../../assets/Logo'
import styles from './Navbar.module.scss'

export const NavBar = () => {
  const { scrollYProgress } = useScroll()
  const location = useLocation()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const isOnPage = path => location.pathname === path

  const socialIcons = [
    {
      icon: <FaLinkedin color={'var(--light-grey-accent)'} size={24} />,
      link: 'https://www.linkedin.com/company/outseek-ca/',
    },
    {
      icon: <FaInstagram color={'var(--light-grey-accent)'} size={24} />,
      link: 'https://www.instagram.com/outseek.ca/',
    },
    {
      icon: <FaSquareXTwitter color={'var(--light-grey-accent)'} size={24} />,
      link: 'https://twitter.com/outseekdotca/',
    },
  ]

  const isTablet = useMediaQuery({ maxWidth: 1079 })

  const renderDesktopNav = () => (
    <div className={styles.navbarLinks}>
      <Link
        to="/copilot"
        className={styles.navBarItem}
        style={{
          color: isOnPage('/copilot') ? 'var(--primary-color)' : undefined,
        }}
      >
        Copilot AI
      </Link>
      <Link
        to="/pricing"
        className={styles.navBarItem}
        style={{
          color: isOnPage('/pricing') ? 'var(--primary-color)' : undefined,
        }}
      >
        Pricing
      </Link>
      <Link
        to="/aboutUs"
        className={styles.navBarItem}
        style={{
          color: isOnPage('/aboutUs') ? 'var(--primary-color)' : undefined,
        }}
      >
        About Us
      </Link>
      <div className={styles.dropdown}>
        <button
          className={styles.dropBtn}
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        >
          Resources
        </button>
        {isDropdownOpen && (
          <div className={styles.dropdownContent}>
            <Link to="/roadmap">Copilot Roadmap</Link>
          </div>
        )}
      </div>
      <div className={styles.navbarIcons}>
        {socialIcons.map((socialIcon, index) => (
          <a
            key={index}
            href={socialIcon.link}
            target="_blank"
            rel="noreferrer"
          >
            {socialIcon.icon}
          </a>
        ))}
      </div>
    </div>
  )

  const renderMobileMenu = () =>
    isMenuOpen && (
      <div className={styles.mobileMenu}>
        <Link
          to="/copilot"
          className={styles.navBarItem}
          style={{
            color: isOnPage('/copilot') ? 'var(--primary-color)' : undefined,
          }}
        >
          Copilot AI
        </Link>
        <Link
          to="/pricing"
          className={styles.navBarItem}
          style={{
            color: isOnPage('/pricing') ? 'var(--primary-color)' : undefined,
          }}
        >
          Pricing
        </Link>
        <Link
          to="/aboutUs"
          className={styles.navBarItem}
          style={{
            color: isOnPage('/aboutUs') ? 'var(--primary-color)' : undefined,
          }}
        >
          About Us
        </Link>
        <Link
          to="/roadmap"
          className={styles.navBarItem}
          style={{
            color: isOnPage('/roadmap') ? 'var(--primary-color)' : undefined,
          }}
        >
          Copilot Roadmap
        </Link>
        {/* <div
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          onMouseEnter={() => setIsDropdownOpen(true)}
          className={styles.navBarItem}
        > */}
        {/* Resources */}
        {/* {isDropdownOpen && (
            <div className={styles.dropdownContent}>
              <Link to="/roadmap">Copilot Roadmap</Link>
            </div>
          )}
        </div> */}
        <div className={styles.navbarIcons}>
          {socialIcons.map((socialIcon, index) => (
            <a
              key={index}
              href={socialIcon.link}
              target="_blank"
              rel="noreferrer"
            >
              {socialIcon.icon}
            </a>
          ))}
        </div>
      </div>
    )

  return (
    <div className={styles.navbarWrapper}>
      <motion.div
        className={'progress-bar'}
        style={{ scaleX: scrollYProgress }}
      />
      <div className={styles.homepageNavbar}>
        <Link to="/">
          <Logo width={140} />
        </Link>
        {isTablet ? (
          <UilBars
            size={30}
            color={'var(--light-grey-accent)'}
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            style={{ cursor: 'pointer' }}
          />
        ) : (
          renderDesktopNav()
        )}
      </div>
      {isTablet && renderMobileMenu()}
    </div>
  )
}
