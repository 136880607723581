import styles from './FourOFour.module.scss'
import React from 'react'
import { useLocation } from 'react-router'
import { Helmet } from 'react-helmet-async'

const FourOFourIndex = () => {
  const location = useLocation()

  return (
    <div className={styles.errorBoundaryContainer}>
      <Helmet>
        <title>{`404 | ${location.pathname} not found`}</title>
      </Helmet>
      <div className={styles.errorBoundaryInfo}>
        <div className={styles.title}>
          <div className={styles.fourTitle}>404 Error.</div>
          <div className={styles.errorBoundaryInfoTitle}>
            <code className={styles.code}>{location.pathname}</code> could not
            be found.
          </div>
        </div>
      </div>
    </div>
  )
}

export default FourOFourIndex
