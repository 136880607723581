import numeral from 'numeral'

export const getQuoteBarItems = (stockPrice, companyData) => {
  const relativeVolume =
    stockPrice?.volume && stockPrice?.avgVolume
      ? (stockPrice?.volume / stockPrice?.avgVolume) * 100
      : '-'

  return [
    {
      value: stockPrice?.volume
        ? numeral(stockPrice?.volume).format('0.00 a')
        : '-',
      label: 'Volume',
    },
    {
      value: stockPrice?.avgVolume
        ? numeral(stockPrice?.avgVolume).format('0.00 a')
        : '-',
      label: 'Avg. Volume',
    },
    {
      value: relativeVolume !== '-' ? `${relativeVolume.toFixed(2)} %` : '-',
      label: 'Rel. Volume',
    },
    {
      value: stockPrice?.marketCap
        ? numeral(stockPrice?.marketCap).format('0.000 a')
        : '-',
      label: 'Market Cap',
    },
    {
      value: companyData?.profile?.industry || '-',
      label: 'Industry',
    },
    {
      value: companyData?.profile?.sector || '-',
      label: 'Sector',
    },
    {
      value: stockPrice?.eps || '-',
      label: 'EPS',
    },
    {
      value: stockPrice?.pe || '-',
      label: 'P/E',
    },
    {
      value: stockPrice?.exchange || '-',
      label: 'Exchange',
    },
    // {
    //   value: companyData?.profile?.currency || '-',
    //   label: 'Currency',
    // },
    // {
    //   value: companyData?.profile?.country || '-',
    //   label: 'Country',
    // },
  ]
}
