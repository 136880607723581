import React, { useState, useEffect, useCallback, memo } from 'react'
import SelectableTable from '../../../../../core/Tables/SelectableTable'
import { useQuery } from 'react-query'
import { CoreLineChart } from '../../../../../core/Charts/GenericChart'
import TimeRange from '../../../NewsView/component/TimeRange'
import { CommodityIntervals } from '../../constants'
import moment from 'moment'
import styles from './TableTrendWidgetTypeB.module.scss'
import http from '../../../../../network/httpClient'
import {
  accumulatedPercentChange,
  ChartPills,
  handlePushMetric,
  handleDeleteTrend,
  handlePopMetric,
  handleUpdateCommodityTimeRange,
} from './utils'
import { NoData } from 'core/NoData/NoData'
import Spinner from 'core/ScrollableContainer/Spinner/spinner'

// select row ->
export const TableTrendWidgetTypeB = props => {
  const { GeneralUrl, columns, title, buildUrl } = props
  const [isChartDataLoading, setChartDataLoading] = useState(false)

  const fetchData = async url => {
    let data = await http.get(url)
    return data?.data?.message?.historical?.reverse()
  }

  const { data, isLoading } = useQuery([title], async () => {
    let data = await http.get(GeneralUrl)
    return data.data.message
  })

  const [metrics, setMetrics] = useState([])
  const [chartData, setChartData] = useState([])
  const [commodityInterval, setCommodityInterval] = useState(
    CommodityIntervals[5]
  )

  const filterDataByInterval = (data, interval) => {
    const { from, to } = interval
    return data.filter(entry => {
      const entryDate = moment(entry.date)
      return (
        (!from || entryDate.isSameOrAfter(from)) &&
        (!to || entryDate.isSameOrBefore(to))
      )
    })
  }

  const updateChartData = useCallback(async () => {
    setChartDataLoading(true)
    let updatedChartData = []

    for (const metric of metrics) {
      const rawData = await fetchData(buildUrl(metric?.key))
      const filteredData = filterDataByInterval(rawData, commodityInterval)

      updatedChartData = accumulatedPercentChange(
        filteredData,
        updatedChartData,
        metric?.key
      )
    }

    setChartData(updatedChartData)
    setChartDataLoading(false)
  }, [metrics, commodityInterval, buildUrl, accumulatedPercentChange])

  useEffect(() => {
    if (!isLoading) {
      const initialMetric = data?.[0]
      if (initialMetric) {
        setMetrics([
          {
            key: initialMetric?.symbol,
            color: 'var(--primary-color)',
            rowId: '0',
          },
        ])
      }
    }
  }, [data, isLoading])

  useEffect(() => {
    updateChartData()
  }, [metrics, commodityInterval, updateChartData])

  const onHandlePushMetric = row =>
    handlePushMetric(
      row,
      metrics,
      setMetrics,
      fetchData,
      buildUrl,
      chartData,
      setChartData
    )

  const onHandlePopMetric = row =>
    handlePopMetric(row, metrics, setMetrics, chartData, setChartData)

  const onHandleDeleteTrend = metricToDelete =>
    handleDeleteTrend(
      metricToDelete,
      metrics,
      setMetrics,
      chartData,
      setChartData
    )

  const onHandleUpdateCommodityTimeRange = interval =>
    handleUpdateCommodityTimeRange(interval, setCommodityInterval)

  const filteredChartData = chartData.filter(entry => {
    const entryDate = moment(entry.date)
    const { from, to } = commodityInterval
    return (
      (!from || entryDate.isSameOrAfter(from)) &&
      (!to || entryDate.isSameOrBefore(to))
    )
  })

  return (
    data &&
    chartData && (
      <div className={styles.tableTrendTypeBWidget}>
        <div className={styles.tableTrendTypeBSelectableTableWrapper}>
          <SelectableTable
            columns={columns}
            data={data}
            overflowXScroll
            onSelectRow={onHandlePushMetric}
            onDeSelectRow={onHandlePopMetric}
            metrics={metrics}
          />
        </div>
        <div className={styles.tableTrendTypeBWidgetChartPanel}>
          {metrics.length > 0 ? (
            isChartDataLoading ? (
              <Spinner />
            ) : (
              <div
                className={
                  styles.tableTrendTypeBWidgetChartPanelChartAndPillWrapper
                }
              >
                <div className={styles.tableTrendTypeBWidgetChartPanelTitle}>
                  <ChartPills
                    metrics={metrics}
                    handleDeleteTrend={onHandleDeleteTrend}
                  />
                  <div
                    className={
                      styles.tableTrendTypeBWidgetChartTimeRangeWrapper
                    }
                  >
                    <TimeRange
                      intervals={CommodityIntervals}
                      interval={commodityInterval}
                      onChange={onHandleUpdateCommodityTimeRange}
                      spaceBetween={'flex-end'}
                      gap={'8px'}
                      fitContent={true}
                    />
                  </div>
                </div>
                <div className={styles.tableTrendTypeBWidgetChartWrapper}>
                  <CoreLineChart
                    showYAxis
                    showSingleYAxis={'right'}
                    XAxisKey={'date'}
                    data={filteredChartData}
                    percentTooltip
                    metrics={metrics}
                    showXAxis={true}
                    type={'step'}
                    axisWidth={60}
                  />
                </div>
              </div>
            )
          ) : (
            <NoData label="Select an index to view chart." />
          )}
        </div>
      </div>
    )
  )
}

export default memo(TableTrendWidgetTypeB)
