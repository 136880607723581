import React from 'react'
import { METRICS_INFO } from 'constants/metricInfoConstants'
import { InfoBlock } from './InfoBlock'
import styles from './styles.module.scss'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'

const MetricInfo = ({
  metricKey, // accessing key for the constants METRICS_INFO, ex. peRatioTTM
  displayMetric, // the header that the user sees, ex. P/E Ratio
}) => {
  
  const trimmedMetricKey = metricKey?.replace('TTM', '')
  const description = METRICS_INFO[trimmedMetricKey]?.description
  const title = METRICS_INFO[trimmedMetricKey]?.title
  const category = METRICS_INFO[trimmedMetricKey]?.category

  const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'transparent',
    },
  }))

  if (!description || !title || !category) return <span>{displayMetric}</span>

  return (
    <CustomTooltip
      title={
        <React.Fragment>
          <div className={styles.metricInfo}>
            <InfoBlock
              title={title}
              category={category}
              description={description}
            />
          </div>
        </React.Fragment>
      }
      placement="top"
      PopperProps={{
        popperOptions: {
          modifiers: [
            {
              name: 'preventOverflow',
              options: {
                rootBoundary: 'document',
              },
            },
          ],
        },
        disablePortal: false,
      }}
    >
      <span>{displayMetric ? displayMetric : title}</span>
    </CustomTooltip>
  )
}

export default MetricInfo
