import { fetches } from './queries'
import { useQuery } from 'react-query'
import { useMemo } from 'react'
import dayjs from 'dayjs'
import { countryCodes } from 'dashboard/ServerSideScreener/components/MetricCatalog/countryCodes'

export const useEconomicCalendar = () => {
  const { data: econCal, isLoading: econCalIsLoading } = useQuery(
    ['economic-calendar'],
    fetches.fetchEconomicCalendar
  )

  const econDataWithFormattedDate = useMemo(() => {
    if (econCal) {
      return econCal?.map(item => {
        return {
          ...item,
          date: dayjs(item.date).format('dddd, MMMM D, YYYY'),
          time: dayjs(item.date).format('h:mm a'),
          country: countryCodes?.[item.country],
          countryCode: item.country,
        }
      })
    }
  }, [econCal])

  const CAL_UI = useMemo(() => {
    return {
      econCal: econDataWithFormattedDate,
      econCalIsLoading,
    }
  }, [econCalIsLoading, econDataWithFormattedDate])

  return {
    CAL_UI,
  }
}

export const useSplitCalendar = () => {
  const { data: splitCal, isLoading: splitCalIsLoading } = useQuery(
    ['split-calendar'],
    fetches.fetchSplitCalendar
  )

  const splitDataWithFormattedDate = useMemo(() => {
    if (splitCal) {
      return splitCal?.map(item => {
        return {
          ...item,
          date: dayjs(item.date).format('dddd, MMMM D, YYYY'),
        }
      })
    }
  }, [splitCal])

  const CAL_UI = useMemo(() => {
    return {
      splitCal: splitDataWithFormattedDate,
      splitCalIsLoading,
    }
  }, [splitCalIsLoading, splitDataWithFormattedDate])
  return {
    CAL_UI,
  }
}

export const useEarningsCalendar = () => {
  const { data: earningCal, isLoading: earningCalIsLoading } = useQuery(
    ['earning-calendar'],
    fetches.fetchEarningsCalendar
  )

  const { data: sp500, isLoading: sp500IsLoading } = useQuery(
    ['sp500'],
    fetches.fetchSP500
  )

  const earningDataWithFormattedDate = useMemo(() => {
    if (earningCal) {
      return earningCal?.map(item => {
        return {
          ...item,
          date: dayjs(item.date).format('dddd, MMMM D, YYYY'),
        }
      })
    }
  }, [earningCal])

  const CAL_UI = useMemo(() => {
    return {
      earningCal: earningDataWithFormattedDate,
      earningCalIsLoading,
      sp500,
      sp500IsLoading,
    }
  }, [earningCalIsLoading, earningDataWithFormattedDate, sp500, sp500IsLoading])

  return {
    CAL_UI,
  }
}

export const useDividendCalendar = () => {
  const { data: dividendCal, isLoading: dividendCalIsLoading } = useQuery(
    ['dividend-calendar'],
    fetches.fetchDividendCalendar
  )
  const { data: sp500, isLoading: sp500IsLoading } = useQuery(
    ['sp500'],
    fetches.fetchSP500
  )

  const dividendDataWithFormattedDate = useMemo(() => {
    if (dividendCal) {
      return dividendCal?.map(item => {
        return {
          ...item,
          date: dayjs(item.date).format('dddd, MMMM D, YYYY'),
        }
      })
    }
  }, [dividendCal])

  const CAL_UI = useMemo(() => {
    return {
      dividendCal: dividendDataWithFormattedDate,
      dividendCalIsLoading,
      sp500,
      sp500IsLoading,
    }
  }, [
    dividendCalIsLoading,
    dividendDataWithFormattedDate,
    sp500,
    sp500IsLoading,
  ])

  return {
    CAL_UI,
  }
}

export const useConfirmedIPOCalendar = () => {
  const { data: confirmedIPOCal, isLoading: confirmedIPOCalIsLoading } =
    useQuery(['confirmed-ipo-calendar'], fetches.fetchConfirmedIPOCalendar)

  const confirmedIPODataWithFormattedDate = useMemo(() => {
    if (confirmedIPOCal) {
      return confirmedIPOCal?.map(item => {
        return {
          ...item,
          filingDate: dayjs(item.filingDate).format('dddd, MMMM D, YYYY'),
        }
      })
    }
  }, [confirmedIPOCal])

  const CAL_UI = useMemo(() => {
    return {
      confirmedIPOCal: confirmedIPODataWithFormattedDate,
      confirmedIPOCalIsLoading,
    }
  }, [confirmedIPOCalIsLoading, confirmedIPODataWithFormattedDate])

  return {
    CAL_UI,
  }
}

export const useProspectusIPOCalendar = () => {
  const { data: prospectusIPOCal, isLoading: prospectusIPOCalIsLoading } =
    useQuery(['prospectus-ipo-calendar'], fetches.fetchProspectusIPOCalendar)

  const prospectusIPODataWithFormattedDate = useMemo(() => {
    if (prospectusIPOCal) {
      return prospectusIPOCal?.map(item => {
        return {
          ...item,
          filingDate: dayjs(item.filingDate).format('dddd, MMMM D, YYYY'),
        }
      })
    }
  }, [prospectusIPOCal])

  const CAL_UI = useMemo(() => {
    return {
      prospectusIPOCal: prospectusIPODataWithFormattedDate,
      prospectusIPOCalIsLoading,
    }
  }, [prospectusIPOCalIsLoading, prospectusIPODataWithFormattedDate])

  return {
    CAL_UI,
  }
}
