import { createContext, useState, useContext, useEffect } from 'react'

export const CalContext = createContext()

export const useCalContext = () => useContext(CalContext)

export const CalProvider = ({ children }) => {
  const [calType, setCalType] = useState(() => {
    const savedCalType = localStorage.getItem('calType')
    return savedCalType || 'calendar'
  })

  const options = [
    { label: 'Table', value: 'table' },
    { label: 'Calendar', value: 'calendar' },
  ]

  useEffect(() => {
    localStorage.setItem('calType', calType)
  }, [calType])

  return (
    <CalContext.Provider value={{ calType, setCalType, options }}>
      {children}
    </CalContext.Provider>
  )
}
