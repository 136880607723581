import styles from './style.module.scss'
import { useCrowdFunding } from '../../data/hooks'
import { CardWrapper } from 'core/CardWrapper/CardWrapper'
import Spinner from 'core/ScrollableContainer/Spinner/spinner'
import { useState } from 'react'
import { crowdFundingKeys } from './constants'
import { SelectedFunding } from './SelectedFunding'
import BasicTable from 'core/Tables/BasicTable'
import { NoData } from 'core/NoData/NoData'
import { Helmet } from 'react-helmet-async'
export const CrowdFunding = () => {
  const { CE_UI } = useCrowdFunding()
  const [selectedFundraising, setSelectedFundraising] = useState(null)

  const handleDetailsClick = rowData => {
    if (selectedFundraising === rowData) {
      setSelectedFundraising(null)
    } else {
      setSelectedFundraising(rowData)
    }
  }

  return (
    <div className={styles.CrowdFunding}>
      <Helmet>
        <title>{`Outseek | Crowdfunding`}</title>
        <meta
          name="description"
          content={
            'This page provides information about the crowd funding offerings.'
          }
        />
      </Helmet>
      <CardWrapper
        label="Crowd Funding Offerings"
        height="100%"
        width="calc(100% - 358px)"
        content={
          CE_UI.CFIsLoading ? (
            <Spinner />
          ) : (
            <BasicTable
              data={CE_UI?.CF}
              columns={crowdFundingKeys}
              onClickRow={handleDetailsClick}
              overflowXScroll={true}
              hoverKey={'companyName'}
              selectedRow={selectedFundraising?.companyName}
              haveKey={true}
            />
          )
        }
      />
      <CardWrapper
        label={selectedFundraising?.companyName || 'Crowd Funding Details'}
        height="100%"
        width="350px"
        content={
          CE_UI.CFIsLoading ? (
            <Spinner />
          ) : selectedFundraising ? (
            <SelectedFunding data={selectedFundraising} />
          ) : (
            <NoData label="Select a row to view details." />
          )
        }
      />
    </div>
  )
}
