import styles from './RosterStats.module.scss'
import numeral from 'numeral'
import { InsiderChart } from './InsiderChart'
import { useState } from 'react'
import InsiderD3Chart from './DthreeChart'
import { reformatName } from '../../utils'
import { CoreLineChart } from 'core/Charts/GenericChart'
import { NoData } from 'core/NoData/NoData'
import { useMemo, useCallback } from 'react'

export const RosterStats = ({ data, mergedData, selectedOwner, ticker }) => {
  const ttmData = data?.ttmData

  const [chartType, setChartType] = useState('acquisitions')
  const [selectedMetric, setSelectedMetric] = useState(null)

  const handleSetChartType = useCallback(type => {
    setChartType(type)
  }, [])

  const handleSetSelectedMetric = useCallback(metric => {
    setSelectedMetric(metric)
  }, [])

  const dataPoints = [
    { title: 'Purchases', key: 'purchases' },
    { title: 'Total Bought', key: 'totalBought' },
    { title: 'Average Bought', key: 'averageBoughtTTM' },
    { title: 'Sales', key: 'sales' },
    { title: 'Total Sold', key: 'totalSold' },
    { title: 'Average Sold', key: 'averageSoldTTM' },
    { title: 'Buy/Sell Ratio', key: 'buySellRatio' },
    { title: 'Pref. Purchases', key: 'pPurchases' },
    { title: 'Sec. Sales', key: 'sSales' },
  ]

  const transformDataForChart = useMemo(() => {
    return (
      mergedData?.map(item => {
        const purchaseTransactions = item?.insiderTransactions?.filter(
          transaction => transaction?.transactionType === 'P-Purchase'
        )

        const salesTransactions = item?.insiderTransactions?.filter(
          transaction => transaction?.transactionType === 'S-Sale'
        )

        const acquisitions = item?.insiderTransactions?.filter(
          transaction => transaction?.acquistionOrDisposition === 'A'
        )

        const dispositions = item?.insiderTransactions?.filter(
          transaction => transaction?.acquistionOrDisposition === 'D'
        )

        return {
          ...item,
          totalInsiderTransactions:
            purchaseTransactions.length - salesTransactions.length,
          totalPurchases: purchaseTransactions.length,
          totalSales: salesTransactions.length,
          totalAcquisitions: acquisitions.length,
          totalDispositions: dispositions.length,
        }
      }) ?? []
    )
  }, [mergedData])

  const acquisitionsMetrics = [
    {
      key: 'totalAcquisitions',
      color: 'var(--green)',
    },
    {
      key: 'totalDispositions',
      color: 'var(--red)',
    },
  ]

  const purchasesMetrics = [
    {
      key: 'totalPurchases',
      color: 'var(--green)',
    },
    {
      key: 'totalSales',
      color: 'var(--red)',
    },
  ]

  const chartData = useMemo(() => {
    return transformDataForChart
  }, [transformDataForChart])

  return (
    <div className={styles.rosterStats}>
      <div className={styles.statsHeader}>
        <div className={styles.statsTitle}>
          Twelve Trailing Months Insider Stats
        </div>
        <div className={styles.statsWrapper}>
          {dataPoints?.map(point => (
            <div
              key={point.key}
              className={styles.dataItem}
              onClick={() => handleSetSelectedMetric(point.key)}
            >
              <span className={styles.dataTitle}>{point.title} </span>
              <span className={styles.dataValue}>
                {numeral(ttmData?.[point?.key]).format(
                  point.key === 'buySellRatio' ? '0.00' : '0,0'
                )}
              </span>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.chartWrapper}>
        <div className={styles.chartHeader}>
          <div className={styles.chartTitle}>
            {selectedOwner ? reformatName(selectedOwner) : 'All'} Insider
            Transactions
          </div>
          <div className={styles.chartTitleWrapper}>
            <div
              className={styles.dataFrequencyButton}
              onClick={() => handleSetChartType('acquisitions')}
              style={{
                color:
                  chartType === 'acquisitions' ? 'var(--primary-color' : null,
              }}
            >
              Acquisitions and Dispositions
            </div>
            <div className={styles.divider} />
            <div
              className={styles.dataFrequencyButton}
              onClick={() => handleSetChartType('purchases')}
              style={{
                color: chartType === 'purchases' ? 'var(--primary-color' : null,
              }}
            >
              Purchases and Sales
            </div>
          </div>
        </div>
        <InsiderD3Chart
          data={chartData}
          metrics={
            chartType === 'acquisitions'
              ? [...acquisitionsMetrics]
              : [...purchasesMetrics]
          }
          XAxisKey={'date'}
          lineDataKey={'close'}
          mirrored={true}
          customHeight={160}
        />
      </div>
    </div>
  )
}
