import { columnKeys } from './columns'

export const ratioCategories = {
  OVERVIEW: 'Overview',
  VALUATION: 'Valuation',
  PROFITABILITY: 'Profitability',
  GROWTH: 'Growth',
  FINANCIAL_HEALTH: 'Financial Health',
  EFFICIENCY: 'Efficiency',
  DIVIDENDS: 'Dividends',
  MANAGEMENT_EFFECTIVENESS: 'Management Effectiveness',
  TTM_RATIOS: 'TTM Ratios',
}

export const ratiosConfig = [
  {
    label: 'Valuation',
    type: ratioCategories.VALUATION,
    columns: columnKeys.valuationColumns,
  },
  {
    label: 'Profitability',
    type: ratioCategories.PROFITABILITY,
    columns: columnKeys.profitabilityColumns,
  },
  {
    label: 'Financial Health',
    type: ratioCategories.FINANCIAL_HEALTH,
    columns: columnKeys.financialHealthColumns,
  },
  {
    label: 'Efficiency',
    type: ratioCategories.EFFICIENCY,
    columns: columnKeys.efficiencyColumns,
  },
  {
    label: 'Dividends',
    type: ratioCategories.DIVIDENDS,
    columns: columnKeys.dividendsColumns,
  },
  {
    label: 'Management Effectiveness',
    type: ratioCategories.MANAGEMENT_EFFECTIVENESS,
    columns: columnKeys.managementEffectivenessColumns,
  },
  {
    label: 'Growth',
    type: ratioCategories.GROWTH,
    columns: columnKeys.growthColumns,
  },
  {
    label: 'TTM Ratios',
    type: ratioCategories.TTM_RATIOS,
  },
]
