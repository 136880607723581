import { fetches } from './queries'
import { useMemo, useState, useCallback } from 'react'
import { useQuery } from 'react-query'
import { metricsArray } from '../utils'
import { useURLSyncedGlobalTicker } from 'pages/views/hooks'

export const useDataSource = () => {
  const { handleSetTicker, ticker } = useURLSyncedGlobalTicker()

  const { data: annualRatiosData, isLoading: annualRatiosDataDataIsLoading } =
    useQuery(['annual-ratios', ticker], fetches.fetchAnnualRatios)

  const { data: quarterlyRatiosData, isLoading: quarterlyRatiosDataIsLoading } =
    useQuery(['quarterly-ratios', ticker], fetches.fetchQuarterlyRatios)

  const { data: ttmData, isLoading: ttmDataIsLoading } = useQuery(
    ['ttm-ratios', ticker],
    fetches.fetchTTMData
  )

  const {
    data: annualFinancialGrowthData,
    isLoading: annualFinancialGrowthDataIsLoading,
  } = useQuery(
    ['annual-financial-growth', ticker],
    fetches.fetchAnnualFinancialGrowth
  )

  const {
    data: quarterlyFinancialGrowthData,
    isLoading: quarterlyFinancialGrowthDataIsLoading,
  } = useQuery(
    ['quarterly-financial-growth', ticker],
    fetches.fetchQuarterlyFinancialGrowth
  )

  const {
    data: annualKeyMetricsData,
    isLoading: annualKeyMetricsDataIsLoading,
  } = useQuery(['annual-key-metrics', ticker], fetches.fetchAnnualKeyMetrics)

  const {
    data: quarterlyKeyMetricsData,
    isLoading: quarterlyKeyMetricsDataIsLoading,
  } = useQuery(
    ['quarterly-key-metrics', ticker],
    fetches.fetchQuarterlyKeyMetrics
  )

  const { data: keyMetricsTTM, isLoading: keyMetricsTTMIsLoading } = useQuery(
    ['key-metrics-ttm', ticker],
    fetches.fetchKeyMetricsTTM
  )

  const adjustPercentages = useCallback((item, metrics) => {
    const adjustedItem = { ...item }

    metrics?.forEach(metric => {
      if (adjustedItem[metric]) {
        adjustedItem[metric] = adjustedItem[metric] * 100
      }
    })

    return adjustedItem
  }, [])

  const processRatioData = useCallback(
    (keyMetricsData, ratiosData, metricsToAdjust) => {
      return keyMetricsData?.map(keyMetricItem => {
        const ratioItem = ratiosData?.find(
          ratio =>
            ratio.symbol === keyMetricItem.symbol &&
            ratio.date === keyMetricItem.date
        )

        const adjustedRatioItem = adjustPercentages(ratioItem, metricsToAdjust)

        return {
          ...keyMetricItem,
          ...adjustedRatioItem,
        }
      })
    },
    [adjustPercentages]
  )

  const metricsToAdjust = useMemo(
    () => [
      'grossProfitMargin',
      'grossProfitMargin',
      'operatingProfitMargin',
      'pretaxProfitMargin',
      'netProfitMargin',
      'returnOnTangibleAssets',
      'roe',
      'roic',
      'effectiveTaxRate',
      'earningsYield',
    ],
    []
  )

  const annualRatioData = useMemo(
    () =>
      processRatioData(annualKeyMetricsData, annualRatiosData, metricsToAdjust),
    [annualKeyMetricsData, annualRatiosData, metricsToAdjust, processRatioData]
  )

  const quarterlyRatioData = useMemo(
    () =>
      processRatioData(
        quarterlyKeyMetricsData,
        quarterlyRatiosData,
        metricsToAdjust
      ),
    [
      processRatioData,
      quarterlyKeyMetricsData,
      quarterlyRatiosData,
      metricsToAdjust,
    ]
  )

  const combinedData = useMemo(() => {
    return {
      ...(keyMetricsTTM ? keyMetricsTTM[0] : {}),
      ...(ttmData ? ttmData[0] : {}),
    }
  }, [keyMetricsTTM, ttmData])

  const processGrowthData = growthData => {
    return growthData?.map(growthItem => {
      return adjustGrowthPercentages(growthItem)
    })
  }

  const adjustGrowthPercentages = item => {
    const adjustedItem = { ...item }

    const excludedKeys = ['date', 'period', 'symbol']

    for (let key in adjustedItem) {
      if (adjustedItem.hasOwnProperty(key) && !excludedKeys.includes(key)) {
        adjustedItem[key] = adjustedItem[key] * 100
      }
    }

    return adjustedItem
  }

  const annualGrowthData = processGrowthData(annualFinancialGrowthData)
  const quarterlyGrowthData = processGrowthData(quarterlyFinancialGrowthData)

  const loadingData =
    annualRatiosDataDataIsLoading ||
    quarterlyRatiosDataIsLoading ||
    ttmDataIsLoading ||
    annualFinancialGrowthDataIsLoading ||
    quarterlyFinancialGrowthDataIsLoading ||
    annualKeyMetricsDataIsLoading ||
    quarterlyKeyMetricsDataIsLoading ||
    keyMetricsTTMIsLoading

  const RatioData = useMemo(() => {
    return {
      ...{
        annualRatioData,
        quarterlyRatioData,
        combinedData,
      },
      ...{
        annualRatiosData,
        annualRatiosDataDataIsLoading,
        quarterlyRatiosData,
        quarterlyRatiosDataIsLoading,
        ttmData,
        ttmDataIsLoading,
        annualGrowthData,
        annualFinancialGrowthDataIsLoading,
        quarterlyGrowthData,
        quarterlyFinancialGrowthDataIsLoading,
        annualKeyMetricsData,
        annualKeyMetricsDataIsLoading,
        quarterlyKeyMetricsData,
        quarterlyKeyMetricsDataIsLoading,
        keyMetricsTTM,
        keyMetricsTTMIsLoading,
      },
    }
  }, [
    annualRatioData,
    quarterlyRatioData,
    combinedData,
    annualRatiosData,
    annualRatiosDataDataIsLoading,
    quarterlyRatiosData,
    quarterlyRatiosDataIsLoading,
    ttmData,
    ttmDataIsLoading,
    annualGrowthData,
    annualFinancialGrowthDataIsLoading,
    quarterlyGrowthData,
    quarterlyFinancialGrowthDataIsLoading,
    annualKeyMetricsData,
    annualKeyMetricsDataIsLoading,
    quarterlyKeyMetricsData,
    quarterlyKeyMetricsDataIsLoading,
    keyMetricsTTM,
    keyMetricsTTMIsLoading,
  ])

  return { RatioData, setTicker: handleSetTicker, ticker, loadingData }
}
