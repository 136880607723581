import { thresholds } from './MetricCatalog/thresholds'

export const DROPDOWN_METRICS = [
  'country',
  'exchangeShortName',
  // 'exchange',
  'industry',
  'sector',
]

const percent = 'percent'
const overUnder = 'overUnder'
const betweenAndOverUnder = 'betweenAndOverUnder'
const ratios = 'ratios'
const mixed = 'mixed'

export const BETWEEN = 'between'
export const STRING = 'string'
export const SELECT = 'select'

export const CATEGORY_WIDTHS = {
  'All Metrics': 320,
  'Popular Metrics': 300,
  'Stock Info': 250,
  'Income Statement': 310,
  'Balance Sheet': 310,
  'Cash Flow Statement': 310,
  'Key Metrics': 340,
  'Financial Ratios': 320,
  'Income Growth': 320,
  'Balance Sheet Growth': 320,
  'Cash Flow Growth': 320,
  'Financial Growth': 320,
}

export const balanceSheet = [
  {
    label: 'Cash & Cash Equivalents',
    metric: 'cashAndCashEquivalents',
    hasFactor: true,
  },
  {
    label: 'Short Term Investments',
    metric: 'shortTermInvestments',
    hasFactor: true,
  },
  {
    label: 'Cash & Short Term Investments',
    metric: 'cashAndShortTermInvestments',
    hasFactor: true,
  },
  {
    label: 'Net Receivables',
    metric: 'netReceivables',
    hasFactor: true,
  },
  {
    label: 'Inventory',
    metric: 'inventory',
    hasFactor: true,
  },
  {
    label: 'Other Current Assets',
    metric: 'otherCurrentAssets',
    hasFactor: true,
  },
  {
    label: 'Current Assets',
    metric: 'totalCurrentAssets',
    hasFactor: true,
  },
  {
    label: 'Property Plant Equipment',
    metric: 'propertyPlantEquipmentNet',
    hasFactor: true,
  },
  {
    label: 'Goodwill',
    metric: 'goodwill',
    hasFactor: true,
  },
  {
    label: 'Intangible Assets',
    metric: 'intangibleAssets',
    hasFactor: true,
  },
  {
    label: 'Goodwill & Intangible Assets',
    metric: 'goodwillAndIntangibleAssets',
    hasFactor: true,
  },
  {
    label: 'Long Term Investments',
    metric: 'longTermInvestments',
    hasFactor: true,
  },
  {
    label: 'Tax Assets',
    metric: 'taxAssets',
    hasFactor: true,
  },
  {
    label: 'Other Non Current Assets',
    metric: 'otherNonCurrentAssets',
    hasFactor: true,
  },
  {
    label: 'Non Current Assets',
    metric: 'totalNonCurrentAssets',
    hasFactor: true,
  },
  {
    label: 'Other Assets',
    metric: 'otherAssets',
    hasFactor: true,
  },
  {
    label: 'Assets',
    metric: 'totalAssets',
    hasFactor: true,
  },
  {
    label: 'Account Payables',
    metric: 'accountPayables',
    hasFactor: true,
  },
  {
    label: 'Short Term Debt',
    metric: 'shortTermDebt',
    hasFactor: true,
  },
  {
    label: 'Tax Payables',
    metric: 'taxPayables',
    hasFactor: true,
  },
  {
    label: 'Deferred Revenue',
    metric: 'deferredRevenue',
    hasFactor: true,
  },
  {
    label: 'Other Current Liabilities',
    metric: 'otherCurrentLiabilities',
    hasFactor: true,
  },
  {
    label: 'Current Liabilities',
    metric: 'totalCurrentLiabilities',
    hasFactor: true,
  },
  {
    label: 'Long Term Debt',
    metric: 'longTermDebt',
    hasFactor: true,
  },
  {
    label: 'Deferred Revenue Non Current',
    metric: 'deferredRevenueNonCurrent',
    hasFactor: true,
  },
  {
    label: 'Deferrred Tax Liabilities Non Current',
    metric: 'deferrredTaxLiabilitiesNonCurrent',
    hasFactor: true,
  },
  {
    label: 'Other Non Current Liabilities',
    metric: 'otherNonCurrentLiabilities',
    hasFactor: true,
  },
  {
    label: 'Non Current Liabilities',
    metric: 'totalNonCurrentLiabilities',
    hasFactor: true,
  },
  {
    label: 'Other Liabilities',
    metric: 'otherLiabilities',
    hasFactor: true,
  },
  {
    label: 'Liabilities',
    metric: 'totalLiabilities',
    hasFactor: true,
  },
  {
    label: 'Preferred Stock',
    metric: 'preferredStock',
    hasFactor: true,
  },
  {
    label: 'Common Stock',
    metric: 'commonStock',
    hasFactor: true,
  },
  {
    label: 'Retained Earnings',
    metric: 'retainedEarnings',
    hasFactor: true,
  },
  {
    label: 'Accumulated Comprehensive Income Loss',
    metric: 'accumulatedOtherComprehensiveIncomeLoss',
    hasFactor: true,
  },
  {
    label: 'Other Stockholders Equity',
    metric: 'othertotalStockholdersEquity',
    hasFactor: true,
  },
  {
    label: 'Stockholders Equity',
    metric: 'totalStockholdersEquity',
    hasFactor: true,
  },
  {
    label: 'Liabilities & Stockholders Equity',
    metric: 'totalLiabilitiesAndStockholdersEquity',
    hasFactor: true,
  },
  {
    label: 'Investments',
    metric: 'totalInvestments',
    hasFactor: true,
  },
  {
    label: 'Debt',
    metric: 'totalDebt',
    hasFactor: true,
  },
  {
    label: 'Net Debt',
    metric: 'netDebt',
    hasFactor: true,
  },
]

export const cashflowStatement = [
  {
    label: 'Net Income',
    metric: 'netIncome',
    hasFactor: true,
  },
  {
    label: 'Depreciation & Amortization',
    metric: 'depreciationAndAmortization',
    hasFactor: true,
  },
  {
    label: 'Deferred Income Tax',
    metric: 'deferredIncomeTax',
    hasFactor: true,
  },
  {
    label: 'Stock Based Compensation',
    metric: 'stockBasedCompensation',
    hasFactor: true,
  },
  {
    label: 'Change in Working Capital',
    metric: 'changeInWorkingCapital',
    hasFactor: true,
  },
  {
    label: 'Accounts Receivables',
    metric: 'accountsReceivables',
    hasFactor: true,
  },
  {
    label: 'Inventory',
    metric: 'inventory',
    hasFactor: true,
  },
  {
    label: 'Accounts Payables',
    metric: 'accountsPayables',
    hasFactor: true,
  },
  {
    label: 'Other Working Capital',
    metric: 'otherWorkingCapital',
    hasFactor: true,
  },
  {
    label: 'Other Non-Cash Items',
    metric: 'otherNonCashItems',
    hasFactor: true,
  },
  {
    label: 'Cash Provided by Operating Activities',
    metric: 'netCashProvidedByOperatingActivites',
    hasFactor: true,
  },
  {
    label: 'Investments in Property Plant & Equipment',
    metric: 'investmentsInPropertyPlantAndEquipment',
    hasFactor: true,
  },
  {
    label: 'Acquisitions Net',
    metric: 'acquisitionsNet',
    hasFactor: true,
  },
  {
    label: 'Purchases of Investments',
    metric: 'purchasesOfInvestments',
    hasFactor: true,
  },
  {
    label: 'Sales Maturities of Investments',
    metric: 'salesMaturitiesOfInvestments',
    hasFactor: true,
  },
  {
    label: 'Other Investing Activities',
    metric: 'otherInvestingActivites',
    hasFactor: true,
  },
  {
    label: 'Cash Used for Investing Activities',
    metric: 'netCashUsedForInvestingActivites',
    hasFactor: true,
  },
  {
    label: 'Debt Repayment',
    metric: 'debtRepayment',
    hasFactor: true,
  },
  {
    label: 'Common Stock Issued',
    metric: 'commonStockIssued',
    hasFactor: true,
  },
  {
    label: 'Common Stock Repurchased',
    metric: 'commonStockRepurchased',
    hasFactor: true,
  },
  {
    label: 'Dividends Paid',
    metric: 'dividendsPaid',
    hasFactor: true,
  },
  {
    label: 'Other Financing Activities',
    metric: 'otherFinancingActivites',
    hasFactor: true,
  },
  {
    label: 'Cash Used Provided by Financing Activities',
    metric: 'netCashUsedProvidedByFinancingActivities',
    hasFactor: true,
  },
  {
    label: 'Effect of Forex Changes on Cash',
    metric: 'effectOfForexChangesOnCash',
    hasFactor: true,
  },
  {
    label: 'Net Change in Cash',
    metric: 'netChangeInCash',
    hasFactor: true,
  },
  {
    label: 'Cash at End of Period',
    metric: 'cashAtEndOfPeriod',
    hasFactor: true,
  },
  {
    label: 'Cash at Beginning of Period',
    metric: 'cashAtBeginningOfPeriod',
    hasFactor: true,
  },
  {
    label: 'Operating Cash Flow',
    metric: 'operatingCashFlow',
    hasFactor: true,
  },
  {
    label: 'Capital Expenditure',
    metric: 'capitalExpenditure',
    hasFactor: true,
  },
  {
    label: 'Free Cash Flow',
    metric: 'freeCashFlow',
    hasFactor: true,
  },
]

export const incomeStatement = [
  {
    label: 'Revenue',
    metric: 'revenue',
    hasFactor: true,
  },
  {
    label: 'Cost of Revenue',
    metric: 'costOfRevenue',
    hasFactor: true,
  },
  {
    label: 'Gross Profit',
    metric: 'grossProfit',
    hasFactor: true,
  },
  {
    label: 'Gross Profit Ratio',
    metric: 'grossProfitRatio',
  },
  {
    label: 'R&D Expenses',
    metric: 'ResearchAndDevelopmentExpenses',
    hasFactor: true,
  },
  {
    label: 'G&A Expenses',
    metric: 'GeneralAndAdministrativeExpenses',
    hasFactor: true,
  },
  {
    label: 'S&M Expenses',
    metric: 'SellingAndMarketingExpenses',
    hasFactor: true,
  },
  {
    label: 'SG&A Expenses',
    metric: 'SellingGeneralAndAdministrativeExpenses',
    hasFactor: true,
  },
  {
    label: 'Other Expenses',
    metric: 'otherExpenses',
    hasFactor: true,
  },
  {
    label: 'Operating Expenses',
    metric: 'operatingExpenses',
    hasFactor: true,
  },
  {
    label: 'Cost & Expenses',
    metric: 'costAndExpenses',
    hasFactor: true,
  },
  {
    label: 'Interest Expense',
    metric: 'interestExpense',
    hasFactor: true,
  },
  {
    label: 'Depreciation & Amortization',
    metric: 'depreciationAndAmortization',
    hasFactor: true,
  },
  {
    label: 'EBITDA',
    metric: 'EBITDA',
    hasFactor: true,
  },
  {
    label: 'EBITDA Ratio',
    metric: 'EBITDARatio',
  },
  {
    label: 'Operating Income',
    metric: 'operatingIncome',
    hasFactor: true,
  },
  {
    label: 'Operating Income Ratio',
    metric: 'operatingIncomeRatio',
  },
  {
    label: 'Other Income Expenses',
    metric: 'totalOtherIncomeExpensesNet',
    hasFactor: true,
  },
  {
    label: 'Income Before Tax',
    metric: 'incomeBeforeTax',
    hasFactor: true,
  },
  {
    label: 'Income Before Tax Ratio',
    metric: 'incomeBeforeTaxRatio',
  },
  {
    label: 'Income Tax Expense',
    metric: 'incomeTaxExpense',
    hasFactor: true,
  },
  {
    label: 'Net Income',
    metric: 'netIncome',
    hasFactor: true,
  },
  {
    label: 'Net Income Ratio',
    metric: 'netIncomeRatio',
  },
  {
    label: 'EPS',
    metric: 'EPS',
  },
  {
    label: 'Diluted EPS',
    metric: 'EPSDiluted',
  },
  {
    label: 'Weighted Avg Shrs Out',
    metric: 'weightedAverageShsOut',
    hasFactor: true,
  },
  {
    label: 'Weighted Avg Shrs Out Dil',
    metric: 'weightedAverageShsOutDil',
    hasFactor: true,
  },
]

export const incomeStatementGrowth = [
  {
    label: 'Revenue Growth',
    metric: 'growthRevenue',
  },
  {
    label: 'Cost of Revenue Growth',
    metric: 'growthCostOfRevenue',
  },
  {
    label: 'Gross Profit Growth',
    metric: 'growthGrossProfit',
  },
  {
    label: 'Gross Profit Ratio Growth',
    metric: 'growthGrossProfitRatio',
  },
  {
    label: 'R&D Expenses Growth',
    metric: 'growthResearchAndDevelopmentExpenses',
  },
  {
    label: 'G&A Expenses Growth',
    metric: 'growthGeneralAndAdministrativeExpenses',
  },
  {
    label: 'S&M Expenses Growth',
    metric: 'growthSellingAndMarketingExpenses',
  },
  {
    label: 'Other Expenses Growth',
    metric: 'growthOtherExpenses',
  },
  {
    label: 'Operating Expenses Growth',
    metric: 'growthOperatingExpenses',
  },
  {
    label: 'Cost & Expenses Growth',
    metric: 'growthCostAndExpenses',
  },
  {
    label: 'Interest Expense Growth',
    metric: 'growthInterestExpense',
  },
  {
    label: 'Depreciation & Amortization Growth',
    metric: 'growthDepreciationAndAmortization',
  },
  {
    label: 'EBITDA Growth',
    metric: 'growthEBITDA',
  },
  {
    label: 'EBITDA Ratio Growth',
    metric: 'growthEBITDARatio',
  },
  {
    label: 'Operating Income Growth',
    metric: 'growthOperatingIncome',
  },
  {
    label: 'Operating Income Ratio Growth',
    metric: 'growthOperatingIncomeRatio',
  },
  {
    label: 'Other Income/Expenses Net Growth',
    metric: 'growthTotalOtherIncomeExpensesNet',
  },
  {
    label: 'Income Before Tax Growth',
    metric: 'growthIncomeBeforeTax',
  },
  {
    label: 'Income Before Tax Ratio Growth',
    metric: 'growthIncomeBeforeTaxRatio',
  },
  {
    label: 'Income Tax Expense Growth',
    metric: 'growthIncomeTaxExpense',
  },
  {
    label: 'Net Income Growth',
    metric: 'growthNetIncome',
  },
  {
    label: 'Net Income Ratio Growth',
    metric: 'growthNetIncomeRatio',
  },
  {
    label: 'EPS Growth',
    metric: 'growthEPS',
  },
  {
    label: 'Dil EPS Growth',
    metric: 'growthEPSDiluted',
  },
  {
    label: 'Weighted Avg Shrs Out Growth',
    metric: 'growthWeightedAverageShsOut',
  },
  {
    label: 'Weighted Avg Shrs Out Dil Growth',
    metric: 'growthWeightedAverageShsOutDil',
  },
]

export const balanceSheetGrowth = [
  {
    label: 'Cash & Cash Equivalents Growth',
    metric: 'growthCashAndCashEquivalents',
  },
  {
    label: 'Short Term Investments Growth',
    metric: 'growthShortTermInvestments',
  },
  {
    label: 'Cash & Short Term Investments Growth',
    metric: 'growthCashAndShortTermInvestments',
  },
  {
    label: 'Net Receivables Growth',
    metric: 'growthNetReceivables',
  },
  {
    label: 'Inventory Growth',
    metric: 'growthInventory',
  },
  {
    label: 'Other Current Assets Growth',
    metric: 'growthOtherCurrentAssets',
  },
  {
    label: 'Current Assets Growth',
    metric: 'growthTotalCurrentAssets',
  },
  {
    label: 'Property Plant Equipment Net Growth',
    metric: 'growthPropertyPlantEquipmentNet',
  },
  {
    label: 'Goodwill Growth',
    metric: 'growthGoodwill',
  },
  {
    label: 'Intangible Assets Growth',
    metric: 'growthIntangibleAssets',
  },
  {
    label: 'Goodwill & Intangible Assets Growth',
    metric: 'growthGoodwillAndIntangibleAssets',
  },
  {
    label: 'Long Term Investments Growth',
    metric: 'growthLongTermInvestments',
  },
  {
    label: 'Tax Assets Growth',
    metric: 'growthTaxAssets',
  },
  {
    label: 'Other Non-Current Assets Growth',
    metric: 'growthOtherNonCurrentAssets',
  },
  {
    label: 'Non-Current Assets Growth',
    metric: 'growthTotalNonCurrentAssets',
  },
  {
    label: 'Other Assets Growth',
    metric: 'growthOtherAssets',
  },
  {
    label: 'Assets Growth',
    metric: 'growthTotalAssets',
  },
  {
    label: 'Account Payables Growth',
    metric: 'growthAccountPayables',
  },
  {
    label: 'Short Term Debt Growth',
    metric: 'growthShortTermDebt',
  },
  {
    label: 'Tax Payables Growth',
    metric: 'growthTaxPayables',
  },
  {
    label: 'Deferred Revenue Growth',
    metric: 'growthDeferredRevenue',
  },
  {
    label: 'Other Current Liabilities Growth',
    metric: 'growthOtherCurrentLiabilities',
  },
  {
    label: 'Current Liabilities Growth',
    metric: 'growthTotalCurrentLiabilities',
  },
  {
    label: 'Long Term Debt Growth',
    metric: 'growthLongTermDebt',
  },
  {
    label: 'Deferred Revenue Non-Current Growth',
    metric: 'growthDeferredRevenueNonCurrent',
  },
  {
    label: 'Deferred Tax Liabilities Non-Current Growth',
    metric: 'growthDeferrredTaxLiabilitiesNonCurrent',
  },
  {
    label: 'Other Non-Current Liabilities Growth',
    metric: 'growthOtherNonCurrentLiabilities',
  },
  {
    label: 'Non-Current Liabilities Growth',
    metric: 'growthTotalNonCurrentLiabilities',
  },
  {
    label: 'Other Liabilities Growth',
    metric: 'growthOtherLiabilities',
  },
  {
    label: 'Liabilities Growth',
    metric: 'growthTotalLiabilities',
  },
  {
    label: 'Common Stock Growth',
    metric: 'growthCommonStock',
  },
  {
    label: 'Retained Earnings Growth',
    metric: 'growthRetainedEarnings',
  },
  {
    label: 'Accumulated Comprehensive Income Loss Growth',
    metric: 'growthAccumulatedOtherComprehensiveIncomeLoss',
  },
  {
    label: 'Other Stockholders Equity Growth',
    metric: 'growthOthertotalStockholdersEquity',
  },
  {
    label: 'Stockholders Equity Growth',
    metric: 'growthTotalStockholdersEquity',
  },
  {
    label: 'Liabilities & Stockholders Equity Growth',
    metric: 'growthTotalLiabilitiesAndStockholdersEquity',
  },
  {
    label: 'Investments Growth',
    metric: 'growthTotalInvestments',
  },
  {
    label: 'Debt Growth',
    metric: 'growthTotalDebt',
  },
  {
    label: 'Net Debt Growth',
    metric: 'growthNetDebt',
  },
]

export const cashFlowStatementGrowth = [
  {
    label: 'Net Income Growth',
    metric: 'growthNetIncome',
  },
  {
    label: 'Depreciation & Amortization Growth',
    metric: 'growthDepreciationAndAmortization',
  },
  {
    label: 'Stock-Based Compensation Growth',
    metric: 'growthStockBasedCompensation',
  },
  {
    label: 'Change in Working Capital Growth',
    metric: 'growthChangeInWorkingCapital',
  },
  {
    label: 'Accounts Receivables Growth',
    metric: 'growthAccountsReceivables',
  },
  {
    label: 'Inventory Growth',
    metric: 'growthInventory',
  },
  {
    label: 'Accounts Payables Growth',
    metric: 'growthAccountsPayables',
  },
  {
    label: 'Other Working Capital Growth',
    metric: 'growthOtherWorkingCapital',
  },
  {
    label: 'Other Non-Cash Items Growth',
    metric: 'growthOtherNonCashItems',
  },
  {
    label: 'Cash Provided by Operating Activities Growth',
    metric: 'growthNetCashProvidedByOperatingActivites',
  },
  {
    label: 'Investments in PPE Growth',
    metric: 'growthInvestmentsInPropertyPlantAndEquipment',
  },
  {
    label: 'Acquisitions Net Growth',
    metric: 'growthAcquisitionsNet',
  },
  {
    label: 'Purchases of Investments Growth',
    metric: 'growthPurchasesOfInvestments',
  },
  {
    label: 'Sales Maturities of Investments Growth',
    metric: 'growthSalesMaturitiesOfInvestments',
  },
  {
    label: 'Other Investing Activities Growth',
    metric: 'growthOtherInvestingActivites',
  },
  {
    label: 'Cash Used for Investing Activities Growth',
    metric: 'growthNetCashUsedForInvestingActivites',
  },
  {
    label: 'Debt Repayment Growth',
    metric: 'growthDebtRepayment',
  },
  {
    label: 'Common Stock Issued Growth',
    metric: 'growthCommonStockIssued',
  },
  {
    label: 'Common Stock Repurchased Growth',
    metric: 'growthCommonStockRepurchased',
  },
  {
    label: 'Dividends Paid Growth',
    metric: 'growthDividendsPaid',
  },
  {
    label: 'Other Financing Activities Growth',
    metric: 'growthOtherFinancingActivites',
  },
  {
    label:
      'Cash Used Provided by Financing Activities Growth',
    metric:
      'growthNetCashUsedProvidedByFinancingActivities',
  },
  {
    label: 'Effect of Forex Changes on Cash Growth',
    metric: 'growthEffectOfForexChangesOnCash',
  },
  {
    label: 'Net Change in Cash Growth',
    metric: 'growthNetChangeInCash',
  },
  {
    label: 'Cash at End of Period Growth',
    metric: 'growthCashAtEndOfPeriod',
  },
  {
    label: 'Cash at Beginning of Period Growth',
    metric: 'growthCashAtBeginningOfPeriod',
  },
  {
    label: 'Operating Cash Flow Growth',
    metric: 'growthOperatingCashFlow',
  },
  {
    label: 'Capital Expenditure Growth',
    metric: 'growthCapitalExpenditure',
  },
  {
    label: 'Free Cash Flow Growth',
    metric: 'growthFreeCashFlow',
  },
]

export const keyMetrics = [
  {
    label: 'Revenue Per Share',
    metric: 'revenuePerShareTTM',
    thresholdToUse: thresholds.revenuePerShare,
    thresholdType: ratios,
  },
  {
    label: 'Net Income Per Share',
    metric: 'netIncomePerShareTTM',
    thresholdToUse: thresholds.netIncomePerShare,
    thresholdType: ratios,
  },
  {
    label: 'OCF Per Share',
    metric: 'operatingCashFlowPerShareTTM',
    thresholdToUse: thresholds.operatingCashFlowPerShare,
    thresholdType: ratios,
  },
  {
    label: 'FCF Per Share',
    metric: 'freeCashFlowPerShareTTM',
    thresholdToUse: thresholds.freeCashFlowPerShare,
    thresholdType: ratios,
  },
  {
    label: 'Cash Per Share',
    metric: 'cashPerShareTTM',
    thresholdToUse: thresholds.cashPerShare,
    thresholdType: ratios,
  },
  {
    label: 'Book Value Per Share',
    metric: 'bookValuePerShareTTM',
    thresholdToUse: thresholds.bookValuePerShare,
    thresholdType: ratios,
  },
  {
    label: 'Tangible Book Value Per Share',
    metric: 'tangibleBookValuePerShareTTM',
    thresholdToUse: thresholds.tangibleBookValuePerShare,
    thresholdType: ratios,
  },

  {
    label: 'Shareholders Equity Per Share',
    metric: 'shareholdersEquityPerShareTTM',
    thresholdToUse: thresholds.shareholdersEquityPerShare,
    thresholdType: ratios,
  },
  {
    label: 'Interest Debt Per Share',
    metric: 'interestDebtPerShareTTM',
    thresholdToUse: thresholds.interestDebtPerShare,
    thresholdType: ratios,
  },
  {
    label: 'Enterprise Value',
    metric: 'enterpriseValueTTM',
    thresholdToUse: thresholds.enterpriseValue,
    thresholdType: betweenAndOverUnder,
  },
  {
    label: 'Price to Earnings Ratio',
    metric: 'peRatioTTM',
    thresholdToUse: thresholds.peRatio,
    thresholdType: ratios,
  },
  {
    label: 'Price to Sales Ratio',
    metric: 'priceToSalesRatioTTM',
    thresholdToUse: thresholds.priceToSalesRatio,
    thresholdType: ratios,
  },
  {
    label: 'Price to OCF Ratio',
    metric: 'pocfratioTTM',
    thresholdToUse:
      thresholds.priceToOperatingCashFlowsRatio,
    thresholdType: ratios,
  },
  {
    label: 'Price to FCF',
    metric: 'pfcfRatioTTM',
    thresholdToUse: thresholds.priceToFreeCashFlowRatio,
    thresholdType: ratios,
  },
  {
    label: 'Price to Book Ratio',
    metric: 'pbRatioTTM',
    thresholdToUse: thresholds.pbRatio,
    thresholdType: ratios,
  },
  {
    label: 'EV to Sales',
    metric: 'evToSalesTTM',
    thresholdToUse: thresholds.evToSales,
    thresholdType: ratios,
  },
  {
    label: 'EV to EBITDA',
    metric: 'enterpriseValueOverEBITDATTM',
    thresholdToUse: thresholds.evToEBITDA,
  },
  {
    label: 'EV to OCF',
    metric: 'evToOperatingCashFlowTTM',
    thresholdToUse: thresholds.evToOCF,
    thresholdType: ratios,
  },
  {
    label: 'EV to FCF',
    metric: 'evToFreeCashFlowTTM',
    thresholdToUse: thresholds.evToFCF,
    thresholdType: ratios,
  },
  {
    label: 'Earnings Yield',
    metric: 'earningsYieldTTM',
    thresholdToUse: thresholds.earningsYield,
    thresholdType: ratios,
  },
  {
    label: 'FCF Yield',
    metric: 'freeCashFlowYieldTTM',
    thresholdToUse: thresholds.fcfYield,
    thresholdType: ratios,
  },
  {
    label: 'Debt to Equity',
    metric: 'debtToEquityTTM',
    thresholdToUse: thresholds.debtToEquity,
    thresholdType: ratios,
  },
  {
    label: 'Debt to Assets',
    metric: 'debtToAssetsTTM',
    thresholdToUse: thresholds.debtToAssets,
    thresholdType: ratios,
  },
  {
    label: 'Net Debt to EBITDA',
    metric: 'netDebtToEBITDATTM',
    thresholdToUse: thresholds.netDebtToEBITDA,
    thresholdType: ratios,
  },
  {
    label: 'Current Ratio',
    metric: 'currentRatioTTM',
    thresholdToUse: thresholds.currentRatio,
    thresholdType: ratios,
  },
  {
    label: 'Interest Coverage',
    metric: 'interestCoverageTTM',
    thresholdToUse: thresholds.interestCoverage,
    thresholdType: ratios,
  },
  {
    label: 'Income Quality',
    metric: 'incomeQualityTTM',
    thresholdToUse: thresholds.incomeQuality,
    thresholdType: ratios,
  },
  {
    label: 'Dividend Yield',
    metric: 'dividendYieldTTM',
    thresholdToUse: thresholds.dividendYield,
    thresholdType: ratios,
  },
  {
    label: 'Dividend Yield Percentage',
    metric: 'dividendYieldPercentageTTM',
    thresholdToUse: thresholds.dividendYieldPercentage,
    thresholdType: ratios,
  },
  {
    label: 'Payout Ratio',
    metric: 'payoutRatioTTM',
    thresholdToUse: thresholds.payoutRatio,
    thresholdType: ratios,
  },
  {
    label: 'Sales General & Administrative to Revenue',
    metric: 'salesGeneralAndAdministrativeToRevenueTTM',
    thresholdToUse:
      thresholds.salesGeneralAndAdministrativeToRevenue,
    thresholdType: ratios,
  },
  {
    label: 'Research & Development to Revenue',
    metric: 'researchAndDevelopementToRevenueTTM',
    thresholdToUse:
      thresholds.researchAndDevelopmentToRevenue,
    thresholdType: ratios,
  },
  {
    label: 'Intangibles to Assets',
    metric: 'intangiblesToTotalAssetsTTM',
    thresholdToUse: thresholds.intangiblesToTotalAssets,
    thresholdType: ratios,
  },
  {
    label: 'Capex to OCF',
    metric: 'capexToOperatingCashFlowTTM',
    thresholdToUse: thresholds.capexToOperatingCashFlow,
    thresholdType: ratios,
  },
  {
    label: 'Capex to Revenue',
    metric: 'capexToRevenueTTM',
    thresholdToUse: thresholds.capexToRevenue,
    thresholdType: ratios,
  },
  {
    label: 'Capex to Depreciation',
    metric: 'capexToDepreciationTTM',
    thresholdToUse: thresholds.capexToDepreciation,
    thresholdType: ratios,
  },
  {
    label: 'Stock Based Compensation to Revenue',
    metric: 'stockBasedCompensationToRevenueTTM',
    thresholdToUse:
      thresholds.stockBasedCompensationToRevenue,
    thresholdType: ratios,
  },
  {
    label: 'Graham Number',
    metric: 'grahamNumberTTM',
    thresholdToUse: thresholds.grahamNumber,
    thresholdType: ratios,
  },
  {
    label: 'Return on Invested Capital',
    metric: 'roicTTM',
    thresholdToUse: thresholds.returns,
    thresholdType: percent,
  },
  {
    label: 'Return On Tangible Assets',
    metric: 'returnOnTangibleAssetsTTM',
    thresholdToUse: thresholds.returns,
    thresholdType: percent,
  },
  {
    label: 'Graham Net Net',
    metric: 'grahamNetNetTTM',
    thresholdToUse: thresholds.grahamNetNet,
    thresholdType: ratios,
  },
  {
    label: 'Working Capital',
    metric: 'workingCapitalTTM',
    thresholdToUse: thresholds.financial,
    thresholdType: betweenAndOverUnder,
  },
  {
    label: 'Tangible Asset Value',
    metric: 'tangibleAssetValueTTM',
    thresholdToUse: thresholds.financial,
    thresholdType: betweenAndOverUnder,
  },
  {
    label: 'Net Current Asset Value',
    metric: 'netCurrentAssetValueTTM',
    thresholdToUse: thresholds.financial,
    thresholdType: betweenAndOverUnder,
  },
  {
    label: 'Invested Capital',
    metric: 'investedCapitalTTM',
    thresholdToUse: thresholds.financial,
    thresholdType: betweenAndOverUnder,
  },
  {
    label: 'Average Receivables',
    metric: 'averageReceivablesTTM',
    thresholdToUse: thresholds.financial,
    thresholdType: betweenAndOverUnder,
  },
  {
    label: 'Average Payables',
    metric: 'averagePayablesTTM',
    thresholdToUse: thresholds.financial,
    thresholdType: betweenAndOverUnder,
  },
  {
    label: 'Average Inventory',
    metric: 'averageInventoryTTM',
    thresholdToUse: thresholds.financial,
    thresholdType: betweenAndOverUnder,
  },
  {
    label: 'Days Sales Outstanding',
    metric: 'daysSalesOutstandingTTM',
    thresholdToUse: thresholds.daysSalesOutstanding,
    thresholdType: ratios,
  },
  {
    label: 'Days Payables Outstanding',
    metric: 'daysPayablesOutstandingTTM',
    thresholdToUse: thresholds.daysPayablesOutstanding,
    thresholdType: ratios,
  },
  {
    label: 'Days Of Inventory On Hand',
    metric: 'daysOfInventoryOnHandTTM',
    thresholdToUse: thresholds.daysOfInventoryOnHand,
    thresholdType: ratios,
  },
  {
    label: 'Receivables Turnover',
    metric: 'receivablesTurnoverTTM',
    thresholdToUse: thresholds.receivablesTurnover,
    thresholdType: ratios,
  },
  {
    label: 'Payables Turnover',
    metric: 'payablesTurnoverTTM',
    thresholdToUse: thresholds.payablesTurnover,
    thresholdType: ratios,
  },
  {
    label: 'Inventory Turnover',
    metric: 'inventoryTurnoverTTM',
    thresholdToUse: thresholds.inventoryTurnover,
    thresholdType: ratios,
  },
  {
    label: 'Return On Equity',
    metric: 'roeTTM',
    thresholdToUse: thresholds.returns,
    thresholdType: percent,
  },
  {
    label: 'Capex Per Share',
    metric: 'capexPerShareTTM',
    thresholdToUse: thresholds.capexPerShare,
    thresholdType: ratios,
  },
  {
    label: 'Dividend Per Share',
    metric: 'dividendPerShareTTM',
    thresholdToUse: thresholds.dividendPerShare,
    thresholdType: ratios,
  },
  {
    label: 'Debt to Market Cap',
    metric: 'debtToMarketCapTTM',
    thresholdToUse: thresholds.debtToMarketCap,
    thresholdType: ratios,
  },
  {
    label: 'Quick Ratio',
    metric: 'quickRatioTTM',
    thresholdToUse: thresholds.quickRatio,
    thresholdType: ratios,
  },
  {
    label: 'Cash Ratio',
    metric: 'cashRatioTTM',
    thresholdToUse: thresholds.cashRatio,
    thresholdType: ratios,
  },
  {
    label: 'Days of Sales Outstanding',
    metric: 'daysOfSalesOutstandingTTM',
    thresholdToUse: thresholds.daysOfSalesOutstanding,
    thresholdType: ratios,
  },
  {
    label: 'Days of Inventory Outstanding',
    metric: 'daysOfInventoryOutstandingTTM',
    thresholdToUse: thresholds.daysOfInventoryOutstanding,
    thresholdType: ratios,
  },
  {
    label: 'Operating Cycle',
    metric: 'operatingCycleTTM',
    thresholdToUse: thresholds.operatingCycle,
    thresholdType: ratios,
  },
  {
    label: 'Days of Payables Outstanding',
    metric: 'daysOfPayablesOutstandingTTM',
    thresholdToUse: thresholds.daysOfPayablesOutstanding,
    thresholdType: ratios,
  },
  {
    label: 'Cash Conversion Cycle',
    metric: 'cashConversionCycleTTM',
    thresholdToUse: thresholds.cashConversionCycle,
    thresholdType: ratios,
  },

  {
    label: 'Gross Profit Margin',
    metric: 'grossProfitMarginTTM',
    thresholdToUse: thresholds.margins,
    thresholdType: percent,
  },
  {
    label: 'Operating Profit Margin',
    metric: 'operatingProfitMarginTTM',
    thresholdToUse: thresholds.margins,
    thresholdType: percent,
  },
  {
    label: 'Pretax Profit Margin',
    metric: 'pretaxProfitMarginTTM',
    thresholdToUse: thresholds.margins,
    thresholdType: percent,
  },
  {
    label: 'Net Profit Margin',
    metric: 'netProfitMarginTTM',
    thresholdToUse: thresholds.margins,
    thresholdType: percent,
  },
  {
    label: 'Effective Tax Rate',
    metric: 'effectiveTaxRateTTM',
    thresholdToUse: thresholds.effectiveTaxRate,
    thresholdType: percent,
  },
  {
    label: 'Return on Assets',
    metric: 'returnOnAssetsTTM',
    thresholdToUse: thresholds.returns,
    thresholdType: percent,
  },
  {
    label: 'Return on Capital Employed',
    metric: 'returnOnCapitalEmployedTTM',
    thresholdToUse: thresholds.returns,
    thresholdType: percent,
  },
  {
    label: 'Net Income per EBT',
    metric: 'netIncomePerEBTTTM',
    thresholdToUse: thresholds.netIncomePerEBT,
    thresholdType: ratios,
  },
  {
    label: 'EBT per EBIT',
    metric: 'ebtPerEbitTTM',
    thresholdToUse: thresholds.ebtPerEbit,
    thresholdType: ratios,
  },
  {
    label: 'EBIT per Revenue',
    metric: 'ebitPerRevenueTTM',
    thresholdToUse: thresholds.ebitPerRevenue,
    thresholdType: ratios,
  },
  {
    label: 'Debt Ratio',
    metric: 'debtRatioTTM',
    thresholdToUse: thresholds.debtRatio,
    thresholdType: ratios,
  },
  {
    label: 'Long Term Debt to Market Cap',
    metric: 'longTermDebtToCapitalizationTTM',
    thresholdToUse: thresholds.longTermDebtToCapitalization,
    thresholdType: ratios,
  },
  {
    label: 'Cash Flow to Debt',
    metric: 'cashFlowToDebtRatioTTM',
    thresholdToUse: thresholds.cashFlowToDebtRatio,
    thresholdType: ratios,
  },
  {
    label: 'Company Equity Multiplier',
    metric: 'companyEquityMultiplierTTM',
    thresholdToUse: thresholds.companyEquityMultiplier,
    thresholdType: ratios,
  },
  {
    label: 'Fixed-Asset Turnover',
    metric: 'fixedAssetTurnoverTTM',
    thresholdToUse: thresholds.fixedAssetTurnover,
    thresholdType: ratios,
  },
  {
    label: 'Asset Turnover',
    metric: 'assetTurnoverTTM',
    thresholdToUse: thresholds.assetTurnover,
    thresholdType: ratios,
  },
  {
    label: 'OCF to Sales',
    metric: 'operatingCashFlowSalesRatioTTM',
    thresholdToUse: thresholds.operatingCashFlowSalesRatio,
    thresholdType: ratios,
  },
  {
    label: 'FCF to OCF Ratio',
    metric: 'freeCashFlowOperatingCashFlowRatioTTM',
    thresholdToUse:
      thresholds.freeCashFlowOperatingCashFlowRatio,
    thresholdType: ratios,
  },
  {
    label: 'Cash Flow Coverage Ratio',
    metric: 'cashFlowCoverageRatiosTTM',
    thresholdToUse: thresholds.cashFlowCoverageRatio,
    thresholdType: ratios,
  },
  {
    label: 'Short-Term Coverage Ratio',
    metric: 'shortTermCoverageRatiosTTM',
    thresholdToUse: thresholds.shortTermCoverageRatio,
    thresholdType: ratios,
  },
  {
    label: 'Capital Expenditure Coverage Ratio',
    metric: 'capitalExpenditureCoverageRatioTTM',
    thresholdToUse:
      thresholds.capitalExpenditureCoverageRatio,
    thresholdType: ratios,
  },
  {
    label: 'Dividend Paid & Capex Coverage Ratio',
    metric: 'dividendPaidAndCapexCoverageRatioTTM',
    thresholdToUse:
      thresholds.dividendPaidAndCapexCoverageRatio,
    thresholdType: ratios,
  },
  {
    label: 'Price to Cash Flow Ratio',
    metric: 'priceCashFlowRatioTTM',
    thresholdToUse: thresholds.priceCashFlowRatio,
    thresholdType: ratios,
  },
  {
    label: 'Price Earnings to Growth Ratio',
    metric: 'priceEarningsToGrowthRatioTTM',
    thresholdToUse: thresholds.growth,
    thresholdType: percent,
  },
  {
    label: 'Enterprise Value Multiple',
    metric: 'enterpriseValueMultipleTTM',
    thresholdToUse: thresholds.enterpriseValueMultiple,
    thresholdType: ratios,
  },
  {
    label: 'Price to Fair Value',
    metric: 'priceFairValueTTM',
    thresholdToUse: thresholds.priceFairValue,
    thresholdType: ratios,
  },
].sort((a, b) => a.label.localeCompare(b.label))

export const stockInfo = [
  {
    label: 'Price',
    metric: 'Price',
    thresholdToUse: thresholds.price,
    thresholdType: overUnder,
  },
  {
    label: 'Market Capitalization',
    metric: 'MktCap',
    hasFactor: true,
    thresholdToUse: thresholds.marketCap,
    thresholdType: mixed,
  },
  {
    label: 'Volume Average',
    metric: 'VolAvg',
    hasFactor: true,
    thresholdToUse: thresholds.volumeAverage,
    thresholdType: mixed,
  },
  {
    label: 'Beta',
    metric: 'Beta',
    thresholdToUse: thresholds.beta,
    thresholdType: overUnder,
  },
  {
    label: 'Last Dividend',
    metric: 'LastDiv',
    thresholdToUse: thresholds.lastDividend,
    thresholdType: overUnder,
  },
  {
    label: 'Change',
    metric: 'Changes',
    thresholdToUse: thresholds.change,
    thresholdType: overUnder,
  },
  {
    label: 'DCF Difference',
    metric: 'DCF_diff',
    thresholdToUse: thresholds.dcfDifference,
    thresholdType: overUnder,
  },
  {
    label: 'DCF',
    metric: 'DCF',
    thresholdToUse: thresholds.dcf,
    thresholdType: overUnder,
  },
  {
    label: 'Full-time Employees',
    metric: 'fullTimeEmployees',
    thresholdToUse: thresholds.fullTimeEmployees,
    thresholdType: overUnder,
  },
  // { label: 'Exchange', metric: 'exchange' },
  // { label: 'Exchange', metric: 'exchange' },
  {
    label: 'Exchange',
    metric: 'exchangeShortName',
  },

  {
    label: 'Industry',
    metric: 'industry',
  },
  { label: 'Sector', metric: 'sector' },
  {
    label: 'Country',
    metric: 'country',
  },
  // {
  //   label: 'Range',
  //   metric: 'Range',
  //   thresholdToUse: thresholds.range,
  //   thresholdType: overUnder,
  // },
  // { label: 'Company Name', metric: 'companyName' },
  // { label: 'Currency', metric: 'currency' },
  // { label: 'CIK', metric: 'cik' },
  // { label: 'Exchange Short Name', metric: 'exchangeShortName' },
  // { label: 'Phone', metric: 'phone' },
  // { label: 'City', metric: 'city' },
  // { label: 'State', metric: 'state' },
  // { label: 'Zip', metric: 'zip' },
  // { label: 'IPO Date', metric: 'ipoDate' },
  // { label: 'Is ETF', metric: 'isEtf' },
  // { label: 'Is Actively Trading', metric: 'isActivelyTrading' },
  // { label: 'Is Fund', metric: 'isFund' },
  // { label: 'Is ADR', metric: 'isAdr' },
]

export const perShareGrowth = [
  {
    label: '10 Year Revenue Growth Per Share',
    metric: 'tenYRevenueGrowthPerShare',
  },
  {
    label: '5 Year Revenue Growth Per Share',
    metric: 'fiveYRevenueGrowthPerShare',
  },
  {
    label: '3 Year Revenue Growth Per Share',
    metric: 'threeYRevenueGrowthPerShare',
  },
  {
    label: '10 Year Operating CF Growth Per Share',
    metric: 'tenYOperatingCFGrowthPerShare',
  },
  {
    label: '5 Year Operating CF Growth Per Share',
    metric: 'fiveYOperatingCFGrowthPerShare',
  },
  {
    label: '3 Year Operating CF Growth Per Share',
    metric: 'threeYOperatingCFGrowthPerShare',
  },
  {
    label: '10 Year Net Income Growth Per Share',
    metric: 'tenYNetIncomeGrowthPerShare',
  },
  {
    label: '5 Year Net Income Growth Per Share',
    metric: 'fiveYNetIncomeGrowthPerShare',
  },
  {
    label: '3 Year Net Income Growth Per Share',
    metric: 'threeYNetIncomeGrowthPerShare',
  },
  {
    label: '10 Year Shareholders Equity Growth Per Share',
    metric: 'tenYShareholdersEquityGrowthPerShare',
  },
  {
    label: '5 Year Shareholders Equity Growth Per Share',
    metric: 'fiveYShareholdersEquityGrowthPerShare',
  },
  {
    label: '3 Year Shareholders Equity Growth Per Share',
    metric: 'threeYShareholdersEquityGrowthPerShare',
  },
  {
    label: '10 Year Dividend per Share Growth Per Share',
    metric: 'tenYDividendperShareGrowthPerShare',
  },
  {
    label: '5 Year Dividend per Share Growth Per Share',
    metric: 'fiveYDividendperShareGrowthPerShare',
  },
  {
    label: '3 Year Dividend per Share Growth Per Share',
    metric: 'threeYDividendperShareGrowthPerShare',
  },
]

export const popular = [
  {
    label: 'Market Capitalization',
    metric: 'MktCap',
    hasFactor: true,
    thresholdToUse: thresholds.marketCap,
    thresholdType: mixed,
  },
  // { label: 'Exchange', metric: 'exchange' },
  {
    label: 'Exchange',
    metric: 'exchangeShortName',
  },
  {
    label: 'Industry',
    metric: 'industry',
  },
  { label: 'Sector', metric: 'sector' },
  {
    label: 'Country',
    metric: 'country',
  },
  {
    label: 'Price to Earnings Ratio',
    metric: 'peRatioTTM',
    thresholdToUse: thresholds.peRatio,
    thresholdType: ratios,
  },
  {
    label: 'Price to Sales Ratio',
    metric: 'priceToSalesRatioTTM',
    thresholdToUse: thresholds.priceToSalesRatio,
    thresholdType: ratios,
  },
  {
    label: 'Price to Book Ratio',
    metric: 'pbRatioTTM',
    thresholdToUse: thresholds.pbRatio,
    thresholdType: ratios,
  },
  {
    label: 'Debt to Equity',
    metric: 'debtToEquityTTM',
    thresholdToUse: thresholds.debtToEquity,
    thresholdType: ratios,
  },
  {
    label: 'Current Ratio',
    metric: 'currentRatioTTM',
    thresholdToUse: thresholds.currentRatio,
    thresholdType: ratios,
  },
  {
    label: 'Return On Equity',
    metric: 'roeTTM',
    thresholdToUse: thresholds.returns,
    thresholdType: percent,
  },
  {
    label: 'Net Profit Margin',
    metric: 'netProfitMarginTTM',
    thresholdToUse: thresholds.margins,
    thresholdType: percent,
  },
  {
    label: 'Revenue',
    metric: 'revenue',
    hasFactor: true,
    thresholdToUse: thresholds.financial,
    thresholdType: overUnder,
  },
  {
    label: 'Free Cash Flow',
    metric: 'freeCashFlow',
    hasFactor: true,
    thresholdToUse: thresholds.financial,
    thresholdType: overUnder,
  },
  {
    label: 'Revenue Growth',
    metric: 'growthRevenue',
    thresholdToUse: thresholds.growth,
    thresholdType: percent,
  },
  {
    label: 'Assets Growth',
    metric: 'growthTotalAssets',
    thresholdToUse: thresholds.growth,
    thresholdType: percent,
  },
  {
    label: 'EBITDA Ratio',
    metric: 'EBITDARatio',
    thresholdToUse: thresholds.margins,
    thresholdType: percent,
  },
]

const growth = [
  ...balanceSheetGrowth,
  ...incomeStatementGrowth,
  ...cashFlowStatementGrowth,
]

const growthWithThreshold = growth.map(item => {
  return {
    ...item,
    thresholdToUse: thresholds.growth,
    thresholdType: percent,
  }
})

const balanceSheetWithThreshold = balanceSheet.map(item => {
  return {
    ...item,
    thresholdToUse: thresholds.financial,
    thresholdType: betweenAndOverUnder,
  }
})

const incomeStatementWithThreshold = incomeStatement.map(
  item => {
    if (
      item.metric === 'netIncomeRatio' ||
      item.metric === 'grossProfitRatio' ||
      item.metric === 'operatingIncomeRatio' ||
      item.metric === 'ebitdaratio' ||
      item.metric === 'incomeBeforeTaxRatio'
    ) {
      return {
        ...item,
        thresholdToUse: thresholds.margins,
        thresholdType: percent,
      }
    } else if (
      item.metric === 'eps' ||
      item.metric === 'EPSDiluted'
    ) {
      return {
        ...item,
        thresholdToUse: thresholds.eps,
        thresholdType: ratios,
      }
    } else {
      return {
        ...item,
        thresholdToUse: thresholds.financial,
        thresholdType: overUnder,
      }
    }
  }
)

const cashflowStatementWithThreshold =
  cashflowStatement.map(item => {
    return {
      ...item,
      thresholdToUse: thresholds.financial,
      thresholdType: overUnder,
    }
  })

export const allMetrics = [
  ...stockInfo,
  ...balanceSheetWithThreshold,
  ...incomeStatementWithThreshold,
  ...cashflowStatementWithThreshold,
  ...keyMetrics,
  ...growthWithThreshold,
]

export const PresetList = () => [
  {
    category: 'All Metrics',
    metrics: allMetrics,
  },
  {
    category: 'Popular Metrics',
    metrics: popular,
  },
  {
    category: 'Stock Info',
    metrics: stockInfo,
  },
  {
    category: 'Income Statement',
    metrics: incomeStatementWithThreshold,
  },
  {
    category: 'Balance Sheet',
    metrics: balanceSheetWithThreshold,
  },
  {
    category: 'Cash Flow Statement',
    metrics: cashflowStatementWithThreshold,
  },
  {
    category: 'Key Metrics',
    metrics: keyMetrics,
  },
  {
    category: 'Financial Growth',
    metrics: growthWithThreshold,
  },
]
