import React, { useEffect, useRef } from 'react'
import styles from '../earningsTranscript.module.scss'

export const EarningsContent = ({ speakers, paragraphs, currentParagraph }) => {
  const paragraphRefs = useRef([])
  useEffect(() => {
    paragraphRefs.current = paragraphRefs.current.slice(0, speakers.length)
  }, [speakers])

  useEffect(() => {
    if (currentParagraph !== null && paragraphRefs.current[currentParagraph]) {
      paragraphRefs.current[currentParagraph].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      })
    } else {
      if (paragraphRefs.current[0]) {
        paragraphRefs.current[0].scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
      }
    }
  }, [currentParagraph])

  return (
    <div className={styles.contentContainerWrapper}>
      <div className={styles.contentContainer}>
        {speakers?.map((entry, i) => {
          const isCurrentParagraph = i === currentParagraph
          const setRef = el => {
            paragraphRefs.current[i] = el
          }

          return (
            <div key={i} className={styles.earningsContent} ref={setRef}>
              <div className={styles.operatorText}>{`${entry}: `}</div>
              <div
                className={styles.paragraphText}
                style={
                  isCurrentParagraph
                    ? { backgroundColor: 'rgba(0, 156, 255, 0.4)' }
                    : {}
                }
              >
                {`  ${paragraphs[i]?.text}`}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
