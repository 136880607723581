import dayjs from 'dayjs'

export const mergeByDatePart = data => {
  return data.reduce((acc, cur) => {
    const datePart = cur.date_part
    if (!acc[datePart]) {
      acc[datePart] = { date_part: datePart }
    }
    for (const key in cur) {
      if (
        key !== 'date_part' &&
        key !== 'sector' &&
        key !== 'industry' &&
        key !== 'country' &&
        key !== 'currency' &&
        key !== 'exchange'
      ) {
        acc[datePart][key] = cur[key]
      }
    }
    return acc
  }, {})
}

const standardizeKey = key => key.toLowerCase().replace(/[^a-z0-9]/g, '')

export const mergeData = (data1, data2) => {
  const data2Map = data2.reduce((acc, item) => {
    const year = item.date.split('-')[0]
    acc[year] = item
    return acc
  }, {})

  const formattedData = JSON.parse(JSON.stringify(data1))

  formattedData?.forEach(item1 => {
    const matchingData2 = data2Map[item1.date]

    if (matchingData2) {
      Object.keys(item1).forEach(originalKey1 => {
        const standardizedKey1 = standardizeKey(originalKey1)

        Object.keys(matchingData2).forEach(originalKey2 => {
          const standardizedKey2 = standardizeKey(originalKey2)

          if (standardizedKey1 === standardizedKey2) {
            if (
              item1[originalKey1] !== null &&
              typeof item1[originalKey1] === 'object'
            ) {
              item1[originalKey1].companyValue = matchingData2[originalKey2]
              item1[originalKey1].date = matchingData2.date
            } else {
            }
          }
        })
      })
    }
  })

  return formattedData
}

const calculateGrowth = (current, previous) => {
  if (previous === 0 || !previous) return null
  return ((current - previous) / previous) * 100
}

export const mergeDataWithGrowth = (data1, data2) => {
  const data2Map = data2.reduce((acc, item) => {
    const year = item.date.split('-')[0]
    acc[year] = item
    return acc
  }, {})

  const formattedData = JSON.parse(JSON.stringify(data1))
  const mergedData = []

  formattedData.forEach(item1 => {
    const matchingData2 = data2Map[item1.date]
    const mergedItem = { ...item1 }

    if (matchingData2) {
      Object.keys(item1).forEach(originalKey1 => {
        const standardizedKey1 = standardizeKey(originalKey1)

        Object.keys(matchingData2).forEach(originalKey2 => {
          const standardizedKey2 = standardizeKey(originalKey2)

          if (standardizedKey1 === standardizedKey2) {
            if (
              item1[originalKey1] !== null &&
              typeof item1[originalKey1] === 'object'
            ) {
              mergedItem[originalKey1].companyValue =
                matchingData2[originalKey2]
              mergedItem[originalKey1].date = matchingData2.date
            }
          }
        })
      })
    }

    mergedData.push(mergedItem)
  })

  mergedData.sort((a, b) => new Date(a.date) - new Date(b.date))

  const formattedDataWithGrowth = []
  mergedData.forEach((item, index) => {
    const previousItem = mergedData[index - 1]

    if (previousItem) {
      Object.keys(item).forEach(originalKey => {
        if (
          item[originalKey] !== null &&
          typeof item[originalKey] === 'object'
        ) {
          item[originalKey].companyGrowth = calculateGrowth(
            item[originalKey].companyValue,
            previousItem[originalKey]?.companyValue
          )

          item[originalKey].analyticalGrowth = calculateGrowth(
            item[originalKey].AVG,
            previousItem[originalKey]?.AVG
          )
        }
      })
    }

    formattedDataWithGrowth.push(item)
  })

  return formattedDataWithGrowth
}

export const percentageDifference = (A, B) => ((A - B) / B) * 100

export const calculateSingleCompositeScore = (
  finData,
  analyticsData,
  metrics
) => {
  let totalScore = 0
  let totalWeight = 0

  metrics.forEach(metric => {
    let min = Number.POSITIVE_INFINITY
    let max = Number.NEGATIVE_INFINITY
    const A = finData[metric]
    const B = analyticsData[metric.AVG]
    if (A != null && B != null && !isNaN(A) && !isNaN(B) && B !== 0) {
      const percentageDiff = percentageDifference(A, B)
      min = Math.min(min, percentageDiff)
      max = Math.max(max, percentageDiff)
    }
  })

  metrics.forEach(metric => {
    const A = finData[metric]
    const B = analyticsData[metric.AVG]
    let min = Number.POSITIVE_INFINITY
    let max = Number.NEGATIVE_INFINITY
    if (A != null && B != null && !isNaN(A) && !isNaN(B) && B !== 0) {
      const percentageDiff = percentageDifference(A, B)
      const score = (percentageDiff - min) / (max - min)

      const weight = 1
      totalScore += score * weight
      totalWeight += weight
    }
  })

  return totalWeight > 0 ? (totalScore / totalWeight).toFixed(2) : 'N/A'
}

const isValidValue = value => {
  return value !== null && value !== 0 && !isNaN(value)
}

const calculateZScore = (value, mean, stddev) => {
  return (value - mean) / stddev
}

const normalizeZScore = (zScore, zMin = -3, zMax = 3) => {
  const cappedZScore = Math.min(Math.max(zScore, zMin), zMax)
  return (cappedZScore - zMin) / (zMax - zMin)
}

export const calculateCollectiveScore = (realData, analyticalData, metrics) => {
  let collectiveScore = 0
  let validMetricsCount = 0

  metrics.forEach(metric => {
    const realValue = realData[metric]
    const analytical = analyticalData[metric]

    if (
      isValidValue(realValue) &&
      isValidValue(analytical?.AVG) &&
      isValidValue(analytical?.stddev)
    ) {
      const zScore = calculateZScore(
        realValue,
        analytical.AVG,
        analytical.stddev
      )

      const normalizedZScore = normalizeZScore(zScore)

      collectiveScore += normalizedZScore
      validMetricsCount += 1
    }
  })

  const averageNormalizedZScore = collectiveScore / validMetricsCount

  const scaledCollectiveScore = averageNormalizedZScore * 100

  return scaledCollectiveScore
}

export const calculateDifference = (companyValue, averageValue) => {
  if (averageValue === 0 || companyValue === 0) return 0
  if (Math.sign(companyValue) === Math.sign(averageValue)) {
    return parseFloat(
      ((companyValue - averageValue) / Math.abs(averageValue)).toFixed(2)
    )
  } else {
    return parseFloat(
      (
        (companyValue + Math.abs(averageValue)) /
        Math.abs(averageValue)
      ).toFixed(2)
    )
  }
}

export const mergeDatasets = (combinedData, analyticsData, filterKey) => {
  const analyticsDataByDate = {}

  analyticsData?.forEach(item => {
    analyticsDataByDate[item.date_part] = item
  })

  return combinedData
    ?.map(item => {
      const analyticsItem = analyticsDataByDate[item.calendarYear] || {}
      return {
        ...item,
        ...analyticsItem,
      }
    })
    ?.filter(item => (filterKey ? item[filterKey] !== undefined : true))
}

export const mergeDatasetsRatios = (combinedData, analyticsData, filterKey) => {
  const analyticsDataByDate = {}

  analyticsData?.forEach(item => {
    analyticsDataByDate[item.date_part] = item
  })

  return combinedData
    ?.map(item => {
      const analyticsItem =
        analyticsDataByDate[dayjs(item.date).format('YYYY')] || {}
      return {
        ...item,
        ...analyticsItem,
      }
    })
    ?.filter(item => (filterKey ? item[filterKey] !== undefined : true))
}

export const combineFinancialData = datasets => {
  return datasets.reduce((acc, data) => ({ ...acc, ...data[0] }), {})
}

export const createDataByDate = data => {
  return data.reduce((acc, item) => ({ ...acc, [item.date]: item }), {})
}

export const mergeFinancialDataByDate = (
  balanceData,
  incomeData,
  cashFlowData
) => {
  return balanceData.map(balanceItem => {
    const date = balanceItem.date
    return {
      ...balanceItem,
      ...incomeData[date],
      ...cashFlowData[date],
    }
  })
}

export const calculateCompositeScoresForMappedCategories = (
  finData,
  analyticsData,
  categories,
  financialMetricsOrganized
) => {
  return categories.map(category =>
    calculateCollectiveScore(
      finData,
      analyticsData,
      financialMetricsOrganized[category]
    )
  )
}

export const mergeByDate = (array1, array2, array3) => {
  const resultMap = new Map()
  const mergeArrayIntoMap = array => {
    array.forEach(item => {
      if (resultMap.has(item.date)) {
        resultMap.set(item.date, { ...resultMap.get(item.date), ...item })
      } else {
        resultMap.set(item.date, { ...item })
      }
    })
  }

  mergeArrayIntoMap(array1)
  mergeArrayIntoMap(array2)
  mergeArrayIntoMap(array3)

  return Array.from(resultMap.values()).sort((a, b) => b.date - a.date)
}
