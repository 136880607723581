import styles from './Spinner.module.scss'
import RingLoader from 'react-spinners/RingLoader'

const Spinner = ({ size }) => (
  <div className={styles.spinnerWrapper}>
    <RingLoader size={size ? size : 36} color="var(--primary-color)" />
  </div>
)

export default Spinner
