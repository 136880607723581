import styles from './filters.module.scss'

export function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  return (
    <input
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined)
      }}
      className={styles.searchInput}
      placeholder={'Search...'}
    />
  )
}

export const SortColumnFilter = ({ column }) => (
  <span className="react-table__column-header sortable">
    {column.isSortedDesc === undefined ? null : (
      <span>{column.isSortedDesc ? ' ▲' : ' ▼'}</span>
    )}
  </span>
)
