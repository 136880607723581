import React, { useState, useRef, useEffect, useContext } from 'react'
import MarketMovers from './components/MarketMovers/MarketMover'
import IndexCharts from './components/IndexCharts/IndexCharts'
import TableTrendWidgetTypeB from './components/SelectableTableWithChart/SelectableTableWithChart'
import http from 'network/httpClient'
import EarningsCalendarWidget from './components/EarningsCalanderWidget/EarningsCalenderWidget'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.scss'
import BottomPanelConstants from './TabConstants.js'
import { BottomPanelTabStyles } from './TabConstants.js'
import styles from './MarketOverview.module.scss'
import 'core/Styles/TabStyles.scss'
import { ForexHeatMap } from './components/HeatMap/HeatMap'
import { AuthContext } from 'core/AuthComponents/AuthContext'
import { AuthModal } from 'core/AuthComponents/AuthModal'
import { Helmet } from 'react-helmet-async'
import { useIsGoogleUser } from 'core/AuthComponents/useIsAuthenticated'
import { Auth } from 'aws-amplify'

const TopPanel = () => {
  return (
    <div className={styles.marketOverviewTopPanel}>
      <IndexCharts />
    </div>
  )
}

const MiddlePanel = () => {
  const [panelWidth, setPanelWidth] = useState(0)
  const panelRef = useRef(null)

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      entries.forEach(entry => {
        setPanelWidth(entry.contentRect.width)
      })
    })

    if (panelRef.current) {
      resizeObserver.observe(panelRef.current)
    }

    return () => {
      if (panelRef.current) {
        resizeObserver.unobserve(panelRef.current)
      }
    }
  }, [])

  const getWidth = () => {
    const width = (panelWidth - 306) / 2
    return width
  }

  return (
    <div ref={panelRef} className={styles.marketOverviewMiddlePanel}>
      <div className={styles.forexWrapper} style={{ width: getWidth() }}>
        <ForexHeatMap />
      </div>
      <div className={styles.marketMovers}>
        <MarketMovers />
      </div>
      <div className={styles.forexWrapper} style={{ width: getWidth() }}>
        <EarningsCalendarWidget />
      </div>
    </div>
  )
}

const BottomPanel = () => {
  const [activeTab, setActiveTab] = useState(0)

  const handleTabChange = index => {
    setActiveTab(index)
  }

  return (
    <div className={styles.marketOverviewBottomPanel}>
      <Helmet>
        <title>{'Outseek | Market Overview'}</title>
        <meta
          name="description"
          content={
            'This page gives the user an overview of the current state of the financial markets. Contains a nice elegant forex heat map, along with todays markets movers, gainers and losers.'
          }
        />
      </Helmet>
      <Tabs
        selectedTabClassName={styles.selectedTab}
        style={BottomPanelTabStyles._Tabs}
        selectedIndex={activeTab}
        onSelect={handleTabChange}
      >
        <div className={styles.marketMoverTabsContainer}>
          <TabList className={styles.tabListHorizontal}>
            {BottomPanelConstants.map((tab, index) => (
              <React.Fragment key={index}>
                <Tab className={styles.MarketOverviewTabs}>{tab.name}</Tab>
                {index !== BottomPanelConstants.length - 1 && (
                  <>
                    {activeTab !== index && activeTab !== index + 1 && (
                      <div className={styles.tabDivider} />
                    )}
                    {activeTab === index + 1 && (
                      <div className={styles.invisibleTabDivider} />
                    )}
                  </>
                )}
              </React.Fragment>
            ))}
          </TabList>
        </div>
        {BottomPanelConstants.map((tab, index) => (
          <TabPanel key={index} style={BottomPanelTabStyles._TabPanel}>
            <TableTrendWidgetTypeB
              GeneralUrl={tab.url}
              columns={tab.columns}
              buildUrl={tab.historicalURL}
              title={tab.title}
            />
          </TabPanel>
        ))}
      </Tabs>
    </div>
  )
}

export const MarketOverview = () => {
  const { showAuthModal, setShowAuthModal } = useContext(AuthContext)

  return (
    <div className={styles.marketOverviewContainer}>
      <TopPanel />
      <MiddlePanel />
      <BottomPanel />
      <AuthModal
        showModal={showAuthModal}
        setShowModal={setShowAuthModal}
        restrictedLogin={true}
      />
    </div>
  )
}

export default React.memo(MarketOverview)
