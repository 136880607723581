import { FREE_USERS, PLUS_USERS, PRO_USERS } from 'constants/appConstants'

export const AnnualOptions = [
  {
    label: '1Y',
    value: 1,
  },
  {
    label: '3Y',
    value: 3,
  },
  {
    label: '5Y',
    value: 5,
  },
  {
    label: '10Y',
    value: 10,
  },
  {
    label: '20Y',
    value: 20,
  },
  {
    label: '30Y',
    value: 30,
  },
]

export const QuarterlyOptions = [
  {
    label: '1Y',
    value: 4,
    plan: FREE_USERS,
  },
  {
    label: '3Y',
    value: 12,
    plan: FREE_USERS,
  },
  {
    label: '5Y',
    value: 20,
    plan: FREE_USERS,
  },
  {
    label: '10Y',
    value: 40,
    plan: PLUS_USERS,
  },
  {
    label: '20Y',
    value: 80,
  },
  {
    label: '30Y',
    value: 120,
  },
]
