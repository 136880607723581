import moment from 'moment'
import numeral from 'numeral'
import { ExtractValue } from '../DataFunctions/ExtractValue'
import styles from './QuickTable.module.scss'

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export const onDragEnd = (
  result,
  setAvailableMetrics,
  availableMetrics,
  setSelectedMetrics,
  selectedMetrics
) => {
  const { source, destination } = result

  if (!destination) {
    return
  }

  if (source.droppableId === destination.droppableId) {
    const items = reorder(
      source.droppableId === 'availableMetrics'
        ? availableMetrics
        : selectedMetrics,
      source.index,
      destination.index
    )

    if (source.droppableId === 'availableMetrics') {
      setAvailableMetrics(items)
    } else {
      setSelectedMetrics(items)
    }
  } else {
    const sourceList =
      source.droppableId === 'availableMetrics'
        ? availableMetrics
        : selectedMetrics
    const destList =
      destination.droppableId === 'availableMetrics'
        ? availableMetrics
        : selectedMetrics
    const [removed] = sourceList.splice(source.index, 1)
    destList.splice(destination.index, 0, removed)

    if (source.droppableId === 'availableMetrics') {
      setAvailableMetrics(sourceList)
      setSelectedMetrics(destList)
    } else {
      setAvailableMetrics(destList)
      setSelectedMetrics(sourceList)
    }
  }
}

const ReplaceWords = ['http://', 'https://']
export const formatter = {
  date: date => moment(date).format('ll'),
  number: number => numeral(number).format('0,0'),
  percent: percent => `${numeral(percent).format('0.00')}%`,
  link: link => (
    <a
      className={styles.websiteLinkText}
      href={link}
      target="_blank"
      rel="noreferrer"
    >
      {ExtractValue(link || '', ReplaceWords)}
    </a>
  ),
}
