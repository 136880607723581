import { CardWrapper } from 'core/CardWrapper/CardWrapper'
import Spinner from 'core/ScrollableContainer/Spinner/spinner'
import { Helmet } from 'react-helmet-async'
import { Calendar } from '../../Componenets/Calendar'
import CalendarTable from 'core/Tables/CalendarTable'
import { splitColumns } from './columns'
import { useSplitCalendar } from '../../data/hooks'
import { Switch } from 'core/Switch/Switch'
import { useCalContext } from 'pages/views/Calendars/CalContext'
import { SplitComponent } from './CalComp'

export const Splits = () => {
  const { CAL_UI } = useSplitCalendar()
  const { calType, setCalType, options } = useCalContext()
  return (
    <>
      <Helmet>
        <title>{`Outseek | Economics`}</title>
        <meta
          name="description"
          content={
            'Economic calendar provides a list of companies that are expected to release their economic reports.'
          }
        />
      </Helmet>
      <CardWrapper
        label={'Splits'}
        height={'100%'}
        width={'100%'}
        endButton={
          <Switch
            options={options}
            selectedOption={calType}
            onOptionChange={setCalType}
            forCardWrapper={true}
          />
        }
        content={
          CAL_UI?.splitCalIsLoading ? (
            <Spinner />
          ) : calType === 'table' ? (
            <CalendarTable
              data={CAL_UI?.splitCal}
              columns={splitColumns}
              overflowXScroll={true}
              filterColumnNames={['impact', 'country']}
            />
          ) : (
            <Calendar
              data={CAL_UI?.splitCal}
              component={SplitComponent}
              filterKeys={['numerator', 'denominator']}
              searchKeys={['symbol']}
            />
          )
        }
      />
    </>
  )
}
