import React, { useEffect, useState, useMemo } from 'react'
import styles from './style.module.scss'
import { CardWrapper } from 'core/CardWrapper/CardWrapper'
import { QuoteBar } from 'core/QuoteBar/QuoteBar'
import { useDataSource } from './data/hooks'
import BasicTable from 'core/Tables/BasicTable'
import { columns } from './columns'
import Spinner from 'core/ScrollableContainer/Spinner/spinner'
import { NoData } from 'core/NoData/NoData'
import Select from 'react-select'
import { siteFilter } from '../NewsView/component/NewsTable/constants'
import { interpretFilingType } from './utils'
import { RecentFiling } from './RecentCard/Cards'
import { StatsCard } from './RecentCard/StatsCard'
import { useUserGroup } from 'hooks/userGroupHook'
import { PREMIUM_FEATURES } from 'pages/Pricing/PriceTable/pricingConfig'
import { Helmet } from 'react-helmet-async'

export const SecFillings = () => {
  const { getFeatureLimit } = useUserGroup()

  const { SEC_UI, ticker, handleSetTicker } = useDataSource()

  const secData = SEC_UI?.secFilings

  const getFeatureLimitAnnual = useMemo(() => {
    return getFeatureLimit(PREMIUM_FEATURES?.SEC)
  }, [getFeatureLimit])

  const restrictedDataAnnual = secData?.map(item => {
    const itemDate = new Date(item?.fillingDate)
    const limitDate = new Date(getFeatureLimitAnnual)

    const isLocked = itemDate < limitDate

    return {
      ...item,
      isLocked,
    }
  })

  const [filteredData, setFilteredData] = useState([])
  const [selectedForm, setSelectedForm] = useState(null)
  const [forms, setForms] = useState([])

  useEffect(() => {
    if (!SEC_UI?.secFilingsIsLoading && SEC_UI?.secFilings) {
      setFilteredData(restrictedDataAnnual)
    }
  }, [SEC_UI?.secFilings, SEC_UI?.secFilingsIsLoading, restrictedDataAnnual])

  useEffect(() => {
    const uniqueForms = [
      ...new Set(restrictedDataAnnual?.map(item => item?.type)),
    ]?.map(type => ({
      value: type,
      label: `(${type}) ${interpretFilingType(type)}`,
    }))
    setForms(uniqueForms)
  }, [restrictedDataAnnual])

  const handleFormChange = form => {
    setSelectedForm(form)
    if (form) {
      setFilteredData(
        restrictedDataAnnual?.filter(item => item?.type === form?.value)
      )
    } else {
      setFilteredData(restrictedDataAnnual)
    }
  }

  return (
    <div className={styles.secFillings}>
      <Helmet>
        <title>{`Outseek | ${ticker} SEC Filings`}</title>
        <meta
          name="description"
          content={
            'This page provides information about the SEC filings for the selected ticker.'
          }
        />
      </Helmet>
      <QuoteBar ticker={ticker} updateTicker={handleSetTicker} />
      <div className={styles.contentWrapper}>
        <CardWrapper
          label={`${ticker} Sec Fillings`}
          width={'calc(100% - 308px)'}
          height={'100%'}
          content={
            SEC_UI?.secFilingsIsLoading ? (
              <Spinner />
            ) : SEC_UI?.secFilings?.length === 0 ? (
              <NoData />
            ) : (
              <div className={styles.tableWrapper}>
                <Select
                  className={styles.siteSelect}
                  value={selectedForm}
                  onChange={handleFormChange}
                  options={forms}
                  styles={siteFilter(true)}
                  placeholder="Filter by form type..."
                  isClearable
                />
                <BasicTable
                  data={filteredData}
                  columns={columns}
                  overflowXScroll={true}
                  sliceRows={300}
                />
              </div>
            )
          }
        />
        <CardWrapper
          label="Recent Fillings & Statistics"
          width={300}
          height={'100%'}
          content={
            SEC_UI?.secFilingsIsLoading ? (
              <Spinner />
            ) : SEC_UI?.secFilings?.length === 0 ? (
              <NoData />
            ) : (
              <div className={styles.recentFilingsWrapper}>
                {Object.keys(SEC_UI?.statistics || {}).map(itemKey => (
                  <StatsCard
                    key={itemKey}
                    statKey={itemKey}
                    statValue={SEC_UI?.statistics[itemKey]}
                  />
                ))}
                <div className={styles.recentFilingsWrapper}>
                  {Object?.keys(SEC_UI?.recentKeyFilings)?.map(item => (
                    <RecentFiling filing={SEC_UI?.recentKeyFilings[item]} />
                  ))}
                </div>
              </div>
            )
          }
        />
      </div>
    </div>
  )
}
