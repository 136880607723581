import axios from 'axios'
import { Auth } from 'aws-amplify'

export const demoUserConfig = {
  email: 'jerrythecow1225@gmail.com',
  password: '12dej12nd!!)kdfSJ23232',
}

// export const outseekBackend = 'https://outseek.ca/' // FOR PRODUCTION (BACKEND)
// export const outseekBackend = 'http://localhost:5000/' // FOR LOCAL TESTING (BACKEND)
export const outseekBackend = process.env.REACT_APP_API_ORIGIN // swap to outseek.ca in local if you don't want to run the backend locally

const http = axios.create({
  baseURL: outseekBackend,
  headers: {
    'Content-Type': 'application/json',
  },
})

export const axiosRequestInterceptor = async config => {
  try {
    const session = await Auth.currentSession()
    const token = session.getAccessToken().getJwtToken()
    config.headers['Authorization'] = `Bearer ${token}`
  } catch (error) {
    console.error('Error getting the user token:', error)
  }
  return config
}

http.interceptors.request.use(axiosRequestInterceptor, e => Promise.reject(e))

export default http
