import { SUMMARY, AGGREGATES } from './constants'

const buildSummaryQueries = args => {
  const queries = {}
  Object.keys(AGGREGATES).forEach(agg => {
    queries[agg] = buildHistoricalQuery({
      ...args,
      aggregate: AGGREGATES[agg],
    })
  })
  return queries
}

/*
    This builds an sql query for selecting and aggregating on various tables
    across years, providing us with trend data for industry, countries, currencies etc.
*/
export const buildHistoricalQuery = args => {
  const {
    table,
    metrics,
    aggregate,
    groupBy,
    whereClause,
    orderBy,
    orderByYear,
  } = args

  if (aggregate === SUMMARY) {
    return buildSummaryQueries(args)
  }
  const EXTRACT_YEAR = `extract ('year' from to_date(${table}."date", 'yyyy-mm-dd'))`

  let qs = `
    select ${EXTRACT_YEAR} as date, ${groupBy.map(
    group => `profile.${group} as ${group}`
  )}, ${metrics.map(
    metric => `${aggregate}("${metric}") as "${aggregate}_${metric}"`
  )} 
        from ${table}
        inner join profile  on ${table}.symbol = profile."Symbol" 
    `

  if (whereClause) {
    qs =
      qs +
      `
    where ${Object.keys(whereClause)
      .map(
        (clause, i) =>
          `${i > 0 ? `and` : ``} ${clause}='${whereClause[clause]}'`
      )
      .join('')}
    `
  }

  if (groupBy) {
    qs = qs + `group by ${groupBy.map(group => `${group}`)}, ${EXTRACT_YEAR}`
  }

  if (orderBy) {
    qs =
      qs +
      `order by ${Object.keys(orderBy).map(ord => `${ord} ${orderBy[ord]}`)}; `
  }

  if (orderByYear) {
    qs = qs + `order by ${EXTRACT_YEAR} ${orderByYear}`
  }

  return qs
}

export default buildHistoricalQuery
