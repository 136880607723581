import { useState, useMemo } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'
import API from '../queries'
import utils from '../utils'
import { useURLSyncedGlobalTicker } from 'pages/views/hooks'
import { startCase } from 'lodash'

export const useDataSource = () => {
  const [interval, setInterval] = useState({
    label: '2Y',
    from: moment().subtract(2, 'years').format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
    limit: 8,
    takeNth: 100,
  })
  const { handleSetTicker, ticker } = useURLSyncedGlobalTicker()

  const {
    data: InstitutionalStockOwnership,
    isLoading: InstitutionalStockOwnershipIsLoading,
  } = useQuery(['fetchInstitutionalStockOwnership', ticker], async () => {
    const res = await API.fetchInstitutionalStockOwnership(ticker)
    return res.reverse()
  })

  const {
    data: StockOwnershipByHolders,
    isLoading: StockOwnershipByHoldersIsLoading,
  } = useQuery(['fetchStockOwnershipbyHolders', ticker], async () => {
    const res = await API.fetchStockOwnershipbyHolders(ticker)
    return res
  })

  const { data: HistoricalPrice, isLoading: HistoricalPriceIsLoading } =
    useQuery(['fetchHistoricalPrice', ticker, interval], async () => {
      const res = await API.fetchHistoricalPrice(ticker, interval)
      return res
    })

  const stockOwnershipByHoldersWithNameStartCase = useMemo(() => {
    return StockOwnershipByHolders?.map(item => {
      return {
        ...item,
        investorName: startCase(item.investorName.toLowerCase()),
      }
    })
  }, [StockOwnershipByHolders])

  const threshold = 30

  const nonInstOwned = 100 - InstitutionalStockOwnership?.[0]?.ownershipPercent

  const currentMarketValue = 100

  const pieData = useMemo(() => {
    if (StockOwnershipByHolders && currentMarketValue) {
      const topList = StockOwnershipByHolders.slice(0, threshold).map(
        value => ({
          value: value?.ownership,
          label: value?.investorName,
        })
      )

      let otherInstitutionalValue = StockOwnershipByHolders.slice(
        threshold
      ).reduce((acc, value) => {
        return acc + (value?.ownership || 0)
      }, 0)

      const topListReduce = topList.reduce(
        (acc, item) => acc + (item?.value || 0),
        0
      )

      let totalInstitutionalValue = topListReduce + otherInstitutionalValue
      const nonInstValue = (nonInstOwned / 100) * currentMarketValue

      const discrepancy =
        currentMarketValue - totalInstitutionalValue - nonInstValue
      if (discrepancy > 0) {
        otherInstitutionalValue += discrepancy
      }

      const othersAggregate = {
        value: otherInstitutionalValue,
        label: 'Other',
      }
      const nonInstitutionalAggregate = {
        value: nonInstValue,
        label: 'Non-Institutional',
      }

      return [...topList, othersAggregate, nonInstitutionalAggregate]
    }
    return []
  }, [StockOwnershipByHolders, currentMarketValue, nonInstOwned])

  const StockViewUI = useMemo(() => {
    if (
      !InstitutionalStockOwnershipIsLoading &&
      !StockOwnershipByHoldersIsLoading &&
      !HistoricalPriceIsLoading
    ) {
      return {
        isLoading: false,
        top50Table: StockOwnershipByHolders,
        HistoricalPriceBlendedData: utils.blendLargeChartData(
          HistoricalPrice,
          InstitutionalStockOwnership
        ),
        HistoricalPrice: HistoricalPrice,
        InstitutionalStockOwnership: InstitutionalStockOwnership,
        pieData: pieData,
        StockOwnershipByHolders: stockOwnershipByHoldersWithNameStartCase,
        getStockOwnershipbyHoldersAverageData: utils.getAveragesByList,
      }
    }
    return {
      isLoading: true,
    }
  }, [
    InstitutionalStockOwnershipIsLoading,
    StockOwnershipByHoldersIsLoading,
    HistoricalPriceIsLoading,
    StockOwnershipByHolders,
    HistoricalPrice,
    InstitutionalStockOwnership,
    pieData,
    stockOwnershipByHoldersWithNameStartCase,
  ])
  return {
    StockViewUI,
    ticker,
    setTicker: handleSetTicker,
    interval,
    setInterval,
  }
}
