import React from 'react'

import { XAxis, YAxis, Tooltip, ResponsiveContainer, LabelList } from 'recharts'
import { BarChart, Bar } from 'recharts'
import numeral from 'numeral'

import dayjs from 'dayjs'

export const DividendChart = ({
  data = [],
  metrics = [],
  XAxisKey,
  showYAxis = true,
  showXAxis = true,
  opacity,
}) => {
  return (
    <ResponsiveContainer width="100%" height="99%">
      <BarChart width={'100%'} height={'100%'} data={data} pure>
        {showXAxis && (
          <XAxis
            dataKey={XAxisKey}
            tickFormatter={tick => dayjs(tick).format('MMM DD, YY') + "'"}
            tick={{
              stroke: 'var(--grey-accent)',
              fontWeight: '200',
            }}
            style={{ fontSize: 11 }}
            height={16}
          />
        )}
        {showYAxis && (
          <YAxis
            tickFormatter={tick => numeral(Math.abs(tick)).format('$0.00')}
            width={38}
            domain={[dataMin => dataMin * 0.95, dataMax => dataMax * 1.05]}
            style={{ fontSize: 12 }}
          />
        )}
        <Tooltip content={divToolTip} />
        {metrics?.map((metric, index) => (
          <Bar
            dataKey={metric.key}
            fill={metric.color}
            opacity={opacity ? opacity : 1}
            key={metric.key}
            radius={[4, 4, 0, 0]}
          >
            <LabelList
              dataKey={metric.key}
              position="top"
              fill="var(--light-grey-accent)"
              formatter={tick => numeral(tick).format('$0.00')}
              style={{ fontSize: 11 }}
            />
          </Bar>
        ))}
      </BarChart>
    </ResponsiveContainer>
  )
}

const divToolTip = o => {
  const { payload, label } = o
  return (
    <div className="customized-tooltip-content-core">
      <div
        className="list"
        style={{
          fontSize: '10.5px',
          color: 'var(--white)',
        }}
      >
        {payload?.map((entry, index) => (
          <div
            className="listItem"
            key={`item-${index}`}
            style={{
              color: entry.color,
              display: 'flex',
              gap: '4px',
              fontSize: '12px',
              flexDirection: 'row',
            }}
          >
            <div style={{ color: 'var(--white)' }}>{`${dayjs(label).format(
              'MMM DD, YYYY'
            )}: `}</div>
            {numeral(entry.value).format('$0.00')}
          </div>
        ))}
      </div>
    </div>
  )
}
