import React, { useState } from 'react'
import { useTheme } from 'theme/ThemeContext'
import { themes, mapThemeName } from 'theme/constants'
import styles from './styles.module.scss'

export const CustomThemeCreator = () => {
  const { toggleTheme, addCustomTheme } = useTheme()
  const [themeName, setThemeName] = useState('')
  const [themeColors, setThemeColors] = useState(themes['outseekDark'])

  const handleColorChange = colorName => event => {
    setThemeColors({
      ...themeColors,
      [colorName]: event.target.value,
    })
  }

  const handleSave = () => {
    if (themeName) {
      addCustomTheme(themeName, themeColors)
      toggleTheme(themeName)
    } else {
      alert('Please provide a name for your theme.')
    }
  }

  return (
    <div className={styles.themeEditorWrapper}>
      <div className={styles.colorsSection}>
        {Object.keys(themeColors).map(color => (
          <div key={color} className={styles.colorInputContainer}>
            <label className={styles.colorLabel}>
              <input
                type="color"
                value={themeColors[color]}
                onChange={handleColorChange(color)}
                className={styles.colorInput}
              />
              {mapThemeName[color]}
            </label>
          </div>
        ))}
      </div>
      <div className={styles.confirmTheme}>
        <input
          type="text"
          placeholder="Theme Name"
          value={themeName}
          onChange={e => setThemeName(e.target.value)}
          className={styles.nameInput}
        />
        <div onClick={handleSave} className={styles.saveThemeButton}>
          Save Theme
        </div>
      </div>
    </div>
  )
}
