import * as React from 'react'
import Modal from '@mui/material/Modal'
import { useSpring, animated } from '@react-spring/web'
import ComponentCatalog from 'dashboard/Catalog/ComponentCatalog/ComponentCatalog'
import { showComponentCatalog } from 'recoil/atoms/componentCatalog'
import { useRecoilState } from 'recoil'
import Box from '@mui/material/Box'

const windowHeight = window.innerHeight

const SlideUp = React.forwardRef(function SlideUp(props, ref) {
  const { children, in: open, onClick, ...other } = props
  const style = useSpring({
    from: { y: windowHeight },
    to: { y: 0 },
  })

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  )
})

export const CatalogModal = props => {
  const [open, setOpen] = useRecoilState(showComponentCatalog)

  const handleClose = () => setOpen(false)

  return (
    <div>
      <Modal open={open} onClose={handleClose} disableAutoFocus={true}>
        <Box
          sx={{
            position: 'fixed',
            bottom: 0,
            width: '100%',
            '&:focus': {
              outline: 'none',
            },
          }}
        >
          <SlideUp in={open}>
            <ComponentCatalog
              handleAddComponent={props.handleAddComponent}
              handleClose={handleClose}
            />
          </SlideUp>
        </Box>
      </Modal>
    </div>
  )
}
