import React, { useCallback, useState } from 'react'
import { QuoteBar } from 'core/QuoteBar/QuoteBar'
import styles from './styles.module.scss'
import { CardWrapper } from 'core/CardWrapper/CardWrapper'
import { useDataSource } from './data/hooks'
import { NoData } from 'core/NoData/NoData'
import { BasicTable } from 'core/Tables/BasicTable'
import DonutChart from '../../InstitutionalOwnership/components/DthreePieChart/DonutChart'
import Spinner from 'core/ScrollableContainer/Spinner/spinner'
import { Helmet } from 'react-helmet-async'
import { ETFInfo } from '../ETFInfo/components/ETFinfo'
import { holdersColumns, holdingsColumns } from './columns'
import { TreeMap } from '../ETFInfo/components/Treemap/Treemap'
import useHoverState from './hoverHook'
import { getBasicTableProps, getChartProps } from './utils'
import { Switch } from 'core/Switch/Switch'
import { NotETF } from '../ETFInfo/components/NotETF/NotETF'

export const ETFHoldings = () => {
  const { ETF_UI, handleSetTicker, ticker } = useDataSource()

  const [viewMode, setViewMode] = useState({
    type: 'holdings',
    chart: 'treeMap',
  })

  const viewOptions = [
    { label: `View ${ticker} Holdings`, value: 'holdings' },
    { label: `View ${ticker} Institutional Holders`, value: 'holders' },
  ]

  const handleSetView = useCallback(
    newLabel => {
      setViewMode(prevMode => ({
        ...prevMode,
        type: newLabel,
      }))
    },
    [setViewMode]
  )

  const chartOptions = [
    { label: 'Donut Chart', value: 'donut' },
    { label: 'TreeMap', value: 'treeMap' },
  ]

  const handleSetChart = useCallback(
    newLabel => {
      setViewMode(prevMode => ({
        ...prevMode,
        chart: newLabel,
      }))
    },
    [setViewMode]
  )

  const {
    hoveredItem: hoveredHolding,
    handleHover: handleHoldingHover,
    handleLeave: handleLeaveHolding,
  } = useHoverState()
  const {
    hoveredItem: hoveredHolder,
    handleHover: handleHolderHover,
    handleLeave: handleLeaveHolder,
  } = useHoverState()

  // console.log('hoveredHolder', hoveredHolder)
  // console.log('hoveredHolding', hoveredHolding)

  const isETF = ETF_UI?.outlookData?.profile?.isEtf

  const basicTablePropsConfig = {
    type: viewMode.type,
    ETF_UI,
    hoverHandlers: {
      handleHoldingHover,
      handleHolderHover,
      handleLeaveHolding,
      handleLeaveHolder,
    },
    hoveredItems: { hoveredHolding, hoveredHolder },
    columns: { holdingsColumns, holdersColumns },
  }

  const chartPropsConfig = {
    type: viewMode.type,
    chartType: viewMode.chart,
    ETF_UI,
    hoverHandlers: { handleHoldingHover, handleHolderHover },
    hoveredItems: { hoveredHolding, hoveredHolder },
  }

  const dataLoadingState =
    viewMode.type === 'holdings'
      ? ETF_UI?.ETFHoldingsIsLoading
      : ETF_UI?.ETFHoldersIsLoading

  const noDataState =
    viewMode.type === 'holdings'
      ? ETF_UI?.ETFHoldings?.length
      : ETF_UI?.ETFHolders?.length

  if (isETF !== true) {
    return <NotETF ticker={ticker} handleSetTicker={handleSetTicker} />
  }

  return (
    <div className={styles.etfWrapper}>
      <Helmet title={`Outseek | ${ticker} (ETF) Overview`} />
      <QuoteBar
        updateTicker={tickerIn => handleSetTicker(tickerIn)}
        ticker={ticker}
      />
      <div className={styles.etfViewWrapper}>
        <CardWrapper
          label={`${ticker} ETF Info`}
          height={'200px'}
          width={'100%'}
          content={<ETFInfo data={ETF_UI?.ETFInfo?.[0]} />}
        />
        <div className={styles.visualizations}>
          <CardWrapper
            height={'100%'}
            width={'calc(100% - 448px)'}
            label={`${
              viewMode.type === 'holdings'
                ? `Holdings Breakdown`
                : `Institutional Holders Breakdown`
            }`}
            noPadding={true}
            endButton={
              <Switch
                options={viewOptions}
                selectedOption={viewMode?.type}
                onOptionChange={handleSetView}
                forCardWrapper={true}
              />
            }
            content={
              dataLoadingState ? (
                <Spinner />
              ) : noDataState > 0 ? (
                <BasicTable {...getBasicTableProps(basicTablePropsConfig)} />
              ) : (
                <NoData label="No Data Available" />
              )
            }
          />
          <CardWrapper
            height={'100%'}
            width={440}
            label={`${
              viewMode.type === 'holdings'
                ? `${ticker} Holdings`
                : `${ticker} Institutional Holders`
            }`}
            noPadding={true}
            endButton={
              <Switch
                options={chartOptions}
                selectedOption={viewMode?.chart}
                onOptionChange={handleSetChart}
                forCardWrapper={true}
              />
            }
            content={
              dataLoadingState ? (
                <Spinner />
              ) : noDataState > 0 ? (
                viewMode.chart === 'donut' ? (
                  <DonutChart {...getChartProps(chartPropsConfig)} />
                ) : (
                  <TreeMap {...getChartProps(chartPropsConfig)} />
                )
              ) : (
                <NoData label="No Data Available" />
              )
            }
          />
        </div>
      </div>
    </div>
  )
}
