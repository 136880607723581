import Spinner from 'core/ScrollableContainer/Spinner/spinner'
import styles from './AnalAndDiv.module.scss'
import { DividendChart } from './DivChart/DivChart'
import { getDateKeyWithData, getConsensusColor } from '../utils'
import { VerticalCurrentBarChart } from 'core/Charts/GenericChart'
import numeral from 'numeral'

export const AnalAndDiv = ({ data, divData }) => {
  const dateKeyWithData = getDateKeyWithData(data?.companyData?.stockDividend, [
    'paymentDate',
    'declarationDate',
    'label',
    'recordDate',
    'date',
  ])

  const analystData =
    data?.analystConsensus?.length > 0
      ? data.analystConsensus[0].consensus
      : undefined

  return (
    <div className={styles.companyOverviewDividendBarChartWrapper}>
      {data?.companyDataIsLoading || data?.analystConsensusIsLoading ? (
        <Spinner />
      ) : (
        <>
          <div className={styles.companyOverviewAnalyst}>
            {data?.companyData?.stockDividend?.length === 0 ||
            !data?.companyData?.stockDividend ? (
              <div className={styles.noDataContainer}>
                <div className={styles.noData}>No Dividend Data Available</div>
              </div>
            ) : (
              <>
                <div className={styles.companyOverviewAnalystTitleWrapper}>
                  <div className={styles.title}>Recent Dividends</div>
                  {data?.companyData?.stockDividend?.length > 0 ? (
                    <div className={styles.title}>
                      Last:{' '}
                      <span
                        style={{
                          color: 'var(--primary-color)',
                        }}
                      >
                        {numeral(
                          data?.companyData?.stockDividend[0]?.dividend
                        ).format('$0.00')}
                      </span>
                    </div>
                  ) : null}
                </div>
                <div className={styles.chartWrapper}>
                  <DividendChart
                    data={divData}
                    XAxisKey={dateKeyWithData}
                    metrics={[
                      { key: 'dividend', color: 'var(--primary-color)' },
                    ]}
                  />
                </div>
              </>
            )}
          </div>
          <div className={styles.companyOverviewAnalyst}>
            {data?.analystConsensus?.length === 0 ||
            analystData === undefined ? (
              <div className={styles.noDataContainer}>
                <div className={styles.noData}>No Analyst Data Available</div>
              </div>
            ) : (
              <>
                <div className={styles.companyOverviewAnalystTitleWrapper}>
                  <div className={styles.title}>Analyst Ratings</div>
                  {data?.analystConsensus?.length > 0 &&
                  analystData !== undefined ? (
                    <div className={styles.title}>
                      Consensus:
                      <span
                        style={{
                          color: getConsensusColor(analystData),
                        }}
                      >
                        {` ${data?.analystConsensus[0]?.consensus}`}
                      </span>
                    </div>
                  ) : null}
                </div>
                <div className={styles.chartWrapper}>
                  <VerticalCurrentBarChart
                    data={data?.analystConsensus ? data.analystConsensus : []}
                    metrics={[
                      { key: 'strongBuy' },
                      { key: 'buy' },
                      { key: 'hold' },
                      { key: 'sell' },
                      { key: 'strongSell' },
                    ]}
                  />
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  )
}
