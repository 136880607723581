import React, { useCallback, useMemo } from 'react'
import styles from './fnTableToolBar.module.scss'
import CoreButton from 'core/Button/CoreButton'
import { useFinancialStatementsContext } from '../../FinancialStatementsContext'
import {
  UilChartBar,
  UilChartLine,
  UilAlign,
  UilFileExport,
} from '@iconscout/react-unicons'
import Icon from '@mdi/react'
import { mdiDecimalDecrease, mdiDecimalIncrease } from '@mdi/js'
import { sliderStyles } from 'core/RangeSlider/styles'
import RangeSlider from 'core/RangeSlider'

export const ToolBar = ({
  data,
  handleExportToExcel,
  handleReverse,
  ratios,
  setSearchText,
  searchValue,
}) => {
  const { state, updateToolbarState } = useFinancialStatementsContext()

  const { isReversed, unit, decimals, range, chartType, dataType, cagrActive } =
    state.toolbarState || {}

  const handleIncrement = useCallback(() => {
    if (decimals < 7) {
      updateToolbarState({ decimals: decimals + 1 })
    }
  }, [decimals, updateToolbarState])

  const handleDecrement = useCallback(() => {
    if (decimals > 0) {
      updateToolbarState({ decimals: decimals - 1 })
    }
  }, [decimals, updateToolbarState])

  const handleSliderChange = useCallback(
    values => {
      updateToolbarState({ range: values })
    },
    [updateToolbarState]
  )

  const { min, max } = useMemo(() => {
    return {
      min: 0,
      max: data?.length - 1,
    }
  }, [data])

  const renderHandleLabels = useCallback(
    ({ value }) => {
      return (
        <div
          style={{
            ...sliderStyles.handleLabelStyles,
          }}
        >
          {data?.[value]?.calendarYear}
        </div>
      )
    },
    [data]
  )

  return (
    <div className={styles.toolbar}>
      <div className={styles.rangeContainer}>
        <RangeSlider
          min={min}
          max={max}
          range={range}
          data={data}
          onChange={handleSliderChange}
          renderHandleLabels={renderHandleLabels}
        />
      </div>
      <div className={styles.topBar}>
        <div className={styles.leftWrapper}>
          <CoreButton onClick={handleExportToExcel}>
            <UilFileExport size={16} color={'var(--white)'} />
            .xls
          </CoreButton>
          <CoreButton onClick={handleReverse}>
            <UilAlign
              size={20}
              color={'var(--white)'}
              style={{ transform: isReversed ? 'none' : 'scaleX(-1)' }}
            />
            Data
          </CoreButton>
          <div className={styles.changeDataFrequencyWrapper}>
            <button
              className={styles.dataFrequencyButton}
              style={{
                color:
                  dataType === 'annual'
                    ? 'var(--primary-color)'
                    : 'var(--white)',
              }}
              onClick={() => updateToolbarState({ dataType: 'annual' })}
            >
              Annual
            </button>
            <div className={styles.divider} />
            <button
              className={styles.dataFrequencyButton}
              style={{
                color:
                  dataType === 'quarterly'
                    ? 'var(--primary-color)'
                    : 'var(--white)',
              }}
              onClick={() => updateToolbarState({ dataType: 'quarterly' })}
            >
              Quarterly
            </button>
          </div>

          {ratios === true ? null : (
            <div className={styles.changeUnitWrapper}>
              <button
                className={styles.unitButton}
                style={{
                  color:
                    unit === 'thousand'
                      ? 'var(--primary-color)'
                      : 'var(--white)',
                }}
                onClick={() => updateToolbarState({ unit: 'thousand' })}
              >
                Th
              </button>
              <div className={styles.divider} />
              <button
                className={styles.unitButton}
                style={{
                  color:
                    unit === 'million'
                      ? 'var(--primary-color)'
                      : 'var(--white)',
                }}
                onClick={() => updateToolbarState({ unit: 'million' })}
              >
                M
              </button>
              <div className={styles.divider} />
              <button
                className={styles.unitButton}
                style={{
                  color:
                    unit === 'billion'
                      ? 'var(--primary-color)'
                      : 'var(--white)',
                }}
                onClick={() => updateToolbarState({ unit: 'billion' })}
              >
                B
              </button>
              <div className={styles.divider} />
              <button
                className={styles.unitButton}
                style={{
                  color:
                    unit === 'default'
                      ? 'var(--primary-color)'
                      : 'var(--white)',
                }}
                onClick={() => updateToolbarState({ unit: 'default' })}
              >
                -
              </button>
            </div>
          )}
          <input
            type="text"
            placeholder="Search metrics..."
            value={searchValue}
            onChange={e => setSearchText(e.target.value)}
            className={styles.searchMetricsInput}
          />
        </div>
        <div className={styles.rightWrapper}>
          <CoreButton
            onClick={() => updateToolbarState({ cagrActive: !cagrActive })}
          >
            {cagrActive ? 'Hide CAGR' : 'Show CAGR'}
          </CoreButton>
          <div
            className={styles.iconButton}
            onClick={() => {
              updateToolbarState({
                chartType: chartType === 'line' ? 'bar' : 'line',
              })
            }}
          >
            {chartType === 'line' ? (
              <UilChartLine size={20} color={'var(--white)'} />
            ) : (
              <UilChartBar size={20} color={'var(--white)'} />
            )}
          </div>
          <div className={styles.decimals}>
            <div className={styles.decimalsButtonWrapper}>
              <div className={styles.decimalsButton} onClick={handleDecrement}>
                <Icon
                  path={mdiDecimalDecrease}
                  size={16}
                  color="var(--white)"
                />
              </div>
              <div className={styles.divider} />
              <span className={styles.decimalsName}>{decimals}</span>
              <div className={styles.divider} />
              <div className={styles.decimalsButton} onClick={handleIncrement}>
                <Icon path={mdiDecimalIncrease} size={2} color="var(--white)" />
              </div>
            </div>
          </div>
          {/* <CoreButton
            onClick={() =>
              updateToolbarState({ showZeroValues: !showZeroValues })
            }
          >
            {showZeroValues ? "Show 0's" : "Hide 0's"}
          </CoreButton> */}
          {/* <CoreButton
            text={showZeroValues ? "Show 0's" : "Hide 0's"}
            onClick={() =>
              updateToolbarState({ showZeroValues: !showZeroValues })
            }
            color={'var(--grey-accent)'}
            height={28}
            padding={'0 8px'}
          /> */}
        </div>
      </div>
    </div>
  )
}
