import styles from './ESGScore.module.scss'
import { ConfigurableChart1 } from './Chart'
import { PieChartWrapper } from './PieChart'

import React, { useEffect, useState } from 'react'
import numeral from 'numeral'
import envoirment from './assets/envoirment.svg'
import social from './assets/social.svg'
import gov from './assets/gov.svg'

import { buildESGScoreData } from './utils'
import http from 'network/httpClient'

const fetchQuoteData = ticker => {
  return http.get(
    'papi/data/api/v4/esg-environmental-social-governance-data?symbol=' + ticker
  )
}

const ESGScore = ({ ticker = 'AAPL' }) => {
  const [data, setData] = useState()
  const total =
    data?.currentData?.S_Score +
    data?.currentData?.G_Score +
    data?.currentData?.E_Score

  useEffect(() => {
    fetchQuoteData(ticker).then(data_raw => {
      setData(buildESGScoreData(data_raw.data.message.slice(0, 100)))
    })
  }, [ticker])

  return (
    data && (
      <div className={styles.ESGScore}>
        <div className={styles.ESGScoreWrapper}>
          <div className={styles.esgScoreContainer}>
            <div className={styles.esgScoreContainerChartPanel1}>
              <ConfigurableChart1 data={data.chartData} />
            </div>
            <div className={styles.esgScoreContainerChartPanel2}>
              <div className={styles.esgScoreWrapperTitle}>{'ESG Score'}</div>
              <div className={styles.esgScoreWrapperBottomMessage}>
                {'Down ' +
                  data?.yearChange?.value +
                  ' points or  ' +
                  data?.yearChange?.percent +
                  '% since last year.'}
              </div>
              <div className={styles.esgScoreWrapperBigNumber}>
                {numeral(data?.currentData?.ESGScore).format('0.0')}
              </div>
            </div>
            <div className={styles.esgScoreContainerChartPanel3}>
              <div className={styles.esgPieChartLabel}>
                Current ESG score breakdown:{' '}
              </div>
              <PieChartWrapper data={data?.pieChartData} />
            </div>
          </div>
          <div
            title={
              numeral((data?.currentData?.E_Score / total) * 100).format(
                '0.0'
              ) + '% of total current score'
            }
            className={styles.esgScoreWrapperRight}
          >
            <div className={styles.scoreWrapper}>
              <div className={styles.valueWrapper}>
                <img alt="icon" src={social} className={styles.esgIcons} />
                <text className={styles.sScoreValueText}>
                  {data?.currentData?.E_Score}
                </text>
              </div>
              <div className={styles.nameWrapper}>{'Environmental Score'}</div>
            </div>
            <div
              title={
                numeral((data?.currentData?.S_Score / total) * 100).format(
                  '0.0'
                ) + '% of total current score'
              }
              className={styles.scoreWrapper}
            >
              <div className={styles.valueWrapper}>
                <img alt="icon" src={envoirment} className={styles.esgIcons} />
                <text className={styles.eScoreValueText}>
                  {data?.currentData?.S_Score}
                </text>
              </div>
              <div className={styles.nameWrapper}>{'Social Score'}</div>
            </div>
            <div
              title={
                numeral((data?.currentData?.G_Score / total) * 100).format(
                  '0.0'
                ) + '% of total current score'
              }
              className={styles.scoreWrapper}
            >
              <div className={styles.valueWrapper}>
                <img alt="icon" src={gov} className={styles.esgIcons} />
                <text className={styles.gScoreValueText}>
                  {data?.currentData?.G_Score}
                </text>
              </div>
              <div className={styles.nameWrapper}>{'Governance Score'}</div>
            </div>
          </div>
        </div>
      </div>
    )
  )
}

export default React.memo(ESGScore)
