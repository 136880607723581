import { basicTableColumns } from './column'
import {
  BALANCE_SHEET,
  CASH_FLOW_STATEMENT,
  COMPANY_GROWTH_TABLE,
  COMPANY_RATIOS_TABLE,
  EIGHT_K_RSS_FEED,
  INCOME_STATEMENT,
  INSIDER_TRADING_RSS_SEC_FEED,
  RSS_SEC_FEED,
  numeralExclude,
} from 'constants/tableConstants'

import { nonMetricRows } from './constants'
import _ from 'lodash'
import { MiniLineChart } from 'core/Charts/MiniLineChart'
import numeral from 'numeral'
export const fetchColumns = (title, data, length) => {
  switch (title) {
    case BALANCE_SHEET:
      return configureFinancialStatementColumns(title, data, length)
    case INCOME_STATEMENT:
      return configureFinancialStatementColumns(title, data, length)
    case CASH_FLOW_STATEMENT:
      return configureFinancialStatementColumns(title, data, length)
    case COMPANY_GROWTH_TABLE:
      return configureFinancialStatementColumns(title, data, length)
    case COMPANY_RATIOS_TABLE:
      return configureFinancialStatementColumns(title, data, length)
    case RSS_SEC_FEED:
      return basicTableColumns.columns_SEC_RSS_Feed
    case EIGHT_K_RSS_FEED:
      return basicTableColumns.columns_8K_RSS_Feed
    case INSIDER_TRADING_RSS_SEC_FEED:
      return basicTableColumns.columns_Insider_Trading
    default:
      return
  }
}

export const BlockTableArray = [
  BALANCE_SHEET,
  INCOME_STATEMENT,
  CASH_FLOW_STATEMENT,
  COMPANY_GROWTH_TABLE,
  COMPANY_RATIOS_TABLE,
]
export const BasicTableArray = [
  RSS_SEC_FEED,
  EIGHT_K_RSS_FEED,
  INSIDER_TRADING_RSS_SEC_FEED,
]

const renderCell = e => {
  const key = e?.row?.original?.key
  if (Object.keys(e.row.original).length > 1) {
    const isNumeral =
      !numeralExclude.includes(key) && !nonMetricRows.includes(key)
    if (isNumeral) {
      return (
        <span title={numeral(e.value).format('0,0.00')}>
          {numeral(e.value).format('0.000 a')}
        </span>
      )
    } else {
      return <span>{e.value}</span>
    }
  }
}

export const getChartColor = (data, dataKey) => {
  const current = _.last(data)
  const previous = _.first(data)

  if (current[dataKey] < previous[dataKey]) {
    return 'var(--red)'
  } else {
    return 'var(--green)'
  }
}

export const getChange = (data, dataKey) => {
  const current = _.last(data)
  const previous = _.first(data)

  const change = current[dataKey] - previous[dataKey]
  const percentChange =
    (current[dataKey] - previous[dataKey]) / Math.abs(previous[dataKey])
  return {
    change,
    percentChange,
  }
}

export const getChangeFromStart = (data, dataKey) => {
  const current = data
  const previous = _.first(data)

  const percentChange =
    (current[dataKey] - previous[dataKey]) / Math.abs(previous[dataKey])
  return {
    percentChange,
  }
}

const RenderChart = ({ e, data }) => {
  const key = e?.row?.original?.key
  const { percentChange } = getChange(data, key)

  return (
    <div style={{ width: 100, height: '100%', display: 'flex', gap: '4px' }}>
      <div style={{ color: percentChange < 0 ? 'red' : 'green' }}>
        {numeral(percentChange).format('0.0 %') && !nonMetricRows.includes(key)}
      </div>
      <MiniLineChart data={data} dataKey={e?.row?.original?.key} />
    </div>
  )
}

const configureFinancialStatementColumns = (type, data, length) => {
  return [
    {
      Header: '',
      accessor: 'key',
      Cell: e => (
        <span
          {...e.row.getToggleRowExpandedProps()}
          title={_.startCase(e.value)}
        >
          {_.startCase(e.value)}
        </span>
      ),
    },
    {
      Header: '',
      accessor: 'Chart',
      disableFilters: true,
      Cell: e => (
        <div
          style={{
            height: '100%',
            width: '100%',
          }}
        >
          <RenderChart {...{ e, data }} />
        </div>
      ),
    },

    ...mapColumn(length),
  ]
}

const mapColumn = length => {
  let num = Array.from(Array(length).keys()).reverse()

  return num.map(n => {
    return {
      Header: 'Q' + n,
      accessor: 'Q' + n,
      disableFilters: true,
      Cell: renderCell,
    }
  })
}
