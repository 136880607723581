import styles from './styles.module.scss'
import {
  UilCopy,
  UilCheck,
  UilThumbsUp,
  UilThumbsDown,
  UilExternalLinkAlt,
} from '@iconscout/react-unicons'
import { useNavigate } from 'react-router'
import { startCase } from 'lodash'

export const UtilsButtons = ({
  handleFeedback,
  timestamp,
  handleCopy,
  isCopied,
  feedbackStatus,
  path,
  dataType,
}) => {
  const navigate = useNavigate()

  const handleNavigate = () => {
    navigate(`/terminal${path}`)
  }

  return (
    <div className={styles.utilsButtonsContainer}>
      <div className={styles.utilsButtons}>
        <button
          className={styles.sendButton}
          onClick={() => handleFeedback('positive', timestamp)}
          style={{
            color:
              feedbackStatus === 'positive'
                ? 'var(--green)'
                : 'var(--light-grey-accent)',
          }}
        >
          <UilThumbsUp size={18} />
        </button>
        <button
          className={styles.sendButton}
          onClick={() => handleFeedback('negative', timestamp)}
          style={{
            color:
              feedbackStatus === 'negative'
                ? 'var(--red)'
                : 'var(--light-grey-accent)',
          }}
        >
          <UilThumbsDown size={18} />
        </button>
        <button className={styles.sendButton} onClick={handleCopy}>
          {isCopied ? (
            <UilCheck size={18} color="var(--green)" />
          ) : (
            <UilCopy size={18} color="var(--light-grey-accent)" />
          )}
        </button>
      </div>
      {path && (
        <div className={styles.navigate} onClick={handleNavigate}>
          <div className={styles.navigateText}>
            {startCase(dataType.toLowerCase())}
          </div>
          <UilExternalLinkAlt size={18} />
        </div>
      )}
    </div>
  )
}
