import React from 'react'
import styles from '../style.module.scss'

export const StatsCard = ({ statKey, statValue }) => {
  return (
    <div className={styles.statsCard}>
      <span className={styles.label}>{statKey}:</span>
      <span className={styles.value}> {statValue}</span>
    </div>
  )
}
