import { useState, useRef, useEffect } from 'react'
import Speak from 'speak-tts'

const useSpeechSynthesis = (data, paragraphs, selectedTranscriptDate) => {
  const [isSpeaking, setIsSpeaking] = useState(false)
  const [segments, setSegments] = useState([])
  const [isInMiddleOfSpeaking, setIsInMiddleOfSpeaking] = useState(false)
  const [currentParagraph, setCurrentParagraph] = useState(null)
  const speakInstanceRef = useRef(null)
  const [sliderValue, setSliderValue] = useState(0)

  useEffect(() => {
    const speak = new Speak()
    if (speakInstanceRef.current) {
      speakInstanceRef.current.cancel()
    }

    speak.init().then(() => {
      speakInstanceRef.current = speak
    })

    return () => {
      if (speakInstanceRef.current) {
        speakInstanceRef.current.cancel()
      }
    }
  }, [])

  useEffect(() => {
    if (!data || !paragraphs) {
      if (speakInstanceRef.current) {
        speakInstanceRef.current.cancel()
      }
      setIsSpeaking(false)
      setIsInMiddleOfSpeaking(false)
      setSegments([])
      return
    }

    const splitIntoSentences = paragraph => {
      return paragraph.match(/[^\.!\?]+[\.!\?]+/g) || []
    }

    const newSegments = paragraphs
      .map((paragraph, paragraphIndex) => {
        const sentences = splitIntoSentences(paragraph.text.trim())
        return sentences.map(sentence => ({ text: sentence, paragraphIndex }))
      })
      .flat()

    setSegments(newSegments)
  }, [data, paragraphs])

  useEffect(() => {
    resetSpeech()
  }, [selectedTranscriptDate])

  const resetSpeech = () => {
    if (speakInstanceRef.current) {
      speakInstanceRef.current.cancel()
    }
    setIsSpeaking(false)
    setIsInMiddleOfSpeaking(false)
    setSegments([])
    setCurrentParagraph(null)
  }

  const speakSegment = segmentIndex => {
    if (segmentIndex >= 0 && segmentIndex < segments.length) {
      const segment = segments[segmentIndex]
      setCurrentParagraph(segment.paragraphIndex)
      speakInstanceRef.current.speak({
        text: segment.text,
        queue: false,
        listeners: {
          onstart: () => {
            setIsSpeaking(true)
          },
          onend: () => {
            if (segmentIndex < segments.length - 1) {
              speakSegment(segmentIndex + 1)
            } else {
              setIsSpeaking(false)
            }
          },
        },
      })
    }
    setSliderValue(segments[segmentIndex].paragraphIndex)
  }

  const jumpToParagraph = paragraphIndex => {
    const segmentIndex = segments?.findIndex(
      segment => segment?.paragraphIndex === paragraphIndex
    )
    if (segmentIndex !== -1) {
      speakSegment(segmentIndex)
      setIsInMiddleOfSpeaking(true)
      setIsSpeaking(true)
    }
  }

  const startSpeaking = () => {
    if (segments.length > 0) {
      speakSegment(0)
      setIsInMiddleOfSpeaking(true)
    }
  }

  const pauseSpeaking = () => {
    if (speakInstanceRef.current) {
      speakInstanceRef.current.pause()
      setIsSpeaking(false)
    }
  }

  const stopSpeaking = () => {
    if (speakInstanceRef.current) {
      speakInstanceRef.current.cancel()
      setIsSpeaking(false)
      setIsInMiddleOfSpeaking(false)
      setCurrentParagraph(null)
    }
  }

  const resumeSpeaking = () => {
    if (speakInstanceRef.current) {
      speakInstanceRef.current.resume()
      setIsSpeaking(true)
    }
  }

  return {
    isInMiddleOfSpeaking,
    isSpeaking,
    segments,
    startSpeaking,
    pauseSpeaking,
    stopSpeaking,
    resumeSpeaking,
    currentParagraph,
    sliderValue,
    jumpToParagraph,
  }
}

export default useSpeechSynthesis
