import ComparisonChartIcon from './ComparisonChartIcon.svg';
import ComponentIcon from './ComponentIcon.svg';
import ComponentColourGuideSquare from './ComponentColourGuideSquare.svg';
import EconomicsIcon from './EconomicsIcon.svg';
import ExternalStatisticsIcon from './ExternalStatisticsIcon.svg';
import FavouriteSelected from './FavouriteSelected.svg';
import FinancialStatementsIcon from './FinancialStatementsIcon.svg';
import FundementalAnalysisIcon from './FundementalAnalysisIcon.svg';
import FundHoldingsIcon from './FundHoldingsIcon.svg';
import GeneralIcon from './GeneralIcon.svg';
import InfoPrompt from './InfoPrompt.svg';
import InsiderTradingIcon from './InsiderTradingIcon.svg';
import InstitutionalIcon from './InstitutionalIcon.svg';
import MarketIcon from './MarketIcon.svg';
import NewsIcon from './NewsIcon.svg';
import PriceIcon from './PriceIcon.svg';
import ScreenerIcon from './ScreenerIcon.svg';
import SearchIcon from './SearchIcon.svg';
import SECIcon from './SECIcon.svg';
import SortIcon from './SortIcon.svg';
import ToolColourGuideSquare from './ToolColourGuideSquare.svg';
import ToolsBackground from './ToolsBackground.svg';
import ToolIcon from './ToolIcon.svg';
import WidgetBackground from './WidgetBackground.svg';
import WidgetColourGuideSquare from './WidgetColourGuideSquare.svg';
import BlueStar from './BlueStar.svg';
import Star from './Star.svg';
import ToInfoIcon from './ToInfoIcon.svg';
import WidgetColorSquare from './WidgetColorSquare.svg';
import ComponentColorSquare from './ComponentColorSquare.svg';
import ToolColorSquare from './ToolColorSquare.svg';
import WidgetImage from './WidgetImage.svg';
import ToolImage from './ToolImage.svg';
import ComponentImage from './ComponentImage.svg';
import WidgetIcon from './WidgetIcon.svg';
import CompanyInformationIcon from './CompanyInformationIcon.svg';
import BottomIcon from './BottomIcon.svg';
import ComponentBackground from './ComponentBackground.svg';
import ToolBackground from './ToolBackground.svg';
import WdigetBackground from './WdigetBackground.svg';
import Plus from './Plus.svg';

export const AssetExport = {
  Plus,
  ToolImage,
  ComponentImage,
  WidgetImage,
  WidgetColorSquare,
  ComponentColorSquare,
  ToolColorSquare,
  CompanyInformationIcon,
  ComparisonChartIcon,
  ComponentIcon,
  ComponentBackground,
  ComponentColourGuideSquare,
  EconomicsIcon,
  ExternalStatisticsIcon,
  FavouriteSelected,
  FinancialStatementsIcon,
  FundementalAnalysisIcon,
  FundHoldingsIcon,
  GeneralIcon,
  InfoPrompt,
  InsiderTradingIcon,
  InstitutionalIcon,
  MarketIcon,
  NewsIcon,
  PriceIcon,
  ScreenerIcon,
  SearchIcon,
  SECIcon,
  SortIcon,
  ToolColourGuideSquare,
  ToolsBackground,
  ToolIcon,
  WidgetBackground,
  WidgetColourGuideSquare,
  Star,
  BlueStar,
  ToInfoIcon,
  WidgetIcon,
  ToolBackground,
  WdigetBackground,
  BottomIcon,
};
