import React, { createContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Auth } from 'aws-amplify'
import { useLocation, useNavigate } from 'react-router-dom'
import { demoUserConfig } from 'network/httpClient'
import { Hub } from 'aws-amplify'

export const AuthContext = createContext()

const AuthProvider = ({ children }) => {
  const navigate = useNavigate()
  const location = useLocation()
  // generl
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [givenName, setGivenName] = useState('')
  const [familyName, setFamilyName] = useState('')
  const [occupation, setOccupation] = useState('')

  // swap signIn to sign and vice versa
  const [showSignUp, setShowSignUp] = useState(true)
  const [isDemoUser, setIsDemoUser] = useState(false)

  // Sign in
  const [signInSuccess, setSignInSuccess] = useState(false)
  const [signInError, setSignInError] = useState('')

  //forgot password
  const [resetPassword, setResetPassword] = useState(false)
  const [resetCode, setResetCode] = useState('')
  const [forgetPassword, setForgetPassword] = useState(false)
  const [forgotPasswordError, setForgotPasswordError] = useState('')
  const [resetPasswordError, setResetPasswordError] = useState('')

  // Sign up
  const [confirmationCode, setConfirmationCode] = useState('')
  const [confirmationSent, setConfirmationSent] = useState(false)
  const [confirmationError, setConfirmationError] = useState('')
  const [signUpSuccess, setSignUpSuccess] = useState(false)
  const [signUpError, setSignUpError] = useState('')

  const [showAuthModal, setShowAuthModal] = useState(false)

  const handleRedirectAfterLogin = () => {
    // see if there is a 'from' state passed to the login page
    const redirectPath = location.state?.from || './Terminal/marketOverview'
    navigate(redirectPath)
  }

  const forgotPassword = async () => {
    try {
      await Auth.forgotPassword(username)
      setResetPassword(true)
    } catch (error) {
      setForgotPasswordError(
        error.message === 'Username/client id combination not found.'
          ? 'Username not Found.'
          : error.message
      )
    }
  }

  const submitNewPassword = async () => {
    try {
      await Auth.forgotPasswordSubmit(username, resetCode, password)
      setResetPassword(false)
      setForgetPassword(false)
      setResetCode('')
      setPassword('')
    } catch (error) {
      setResetPasswordError(error.message)
    }
  }

  const handleSignIn = async () => {
    setSignInSuccess(true)
    try {
      await Auth.signIn(username, password).then(() => {
        setIsAuthenticated(true)
        handleRedirectAfterLogin()
      })
      return true
    } catch (error) {
      setSignInError(
        error.message ===
          'Custom auth lambda trigger is not configured for the user pool.'
          ? 'Incorrect username or password'
          : error.message
      )
      return false
    } finally {
      setSignInSuccess(false)
    }
  }

  const handleSignInDemo = () => {
    setSignInSuccess(true)
    Auth.signIn(demoUserConfig.email, demoUserConfig.password)
      .then(() => {
        setIsAuthenticated(true)
        setIsDemoUser(true)
        handleRedirectAfterLogin()
      })
      .catch(error => {
        setSignInError(
          error.message ===
            'Custom auth lambda trigger is not configured for the user pool.'
            ? 'Incorrect username or password'
            : error.message
        )
        return false
      })
      .finally(() => {
        setSignInSuccess(false)
      })
    return true
  }

  const handlePushTerminalPageHomepage = () => {
    navigate('./terminal/marketOverview')
    setShowAuthModal(true)
  }

  // const handleSignInDemoHomepage = () => {
  //   setSignInSuccess(true)
  //   try {
  //     // await Auth.signOut()
  //     Auth.signIn(demoUserConfig.email, demoUserConfig.password).then(() => {
  //       setIsAuthenticated(true)
  //       handlePushTerminalPageHomepage()
  //     })
  //     return true
  //   } catch (error) {
  //     setSignInError(
  //       error.message ===
  //         'Custom auth lambda trigger is not configured for the user pool.'
  //         ? 'Incorrect username or password'
  //         : error.message
  //     )
  //     return false
  //   } finally {
  //     setSignInSuccess(false)
  //   }
  // }

  const handleSignInDemoHomepage = () => {
    setSignInSuccess(true)
    Auth.signIn(demoUserConfig.email, demoUserConfig.password)
      .then(() => {
        setIsAuthenticated(true)
        handlePushTerminalPageHomepage()
      })
      .catch(error => {
        setSignInError(
          error.message ===
            'Custom auth lambda trigger is not configured for the user pool.'
            ? 'Incorrect username or password'
            : error.message
        )
        return false
      })
      .finally(() => {
        setSignInSuccess(false)
      })
    return true
  }

  const handleConfirmationCode = async () => {
    setSignUpSuccess(true)
    try {
      await Auth.confirmSignUp(username, confirmationCode)
        .then(() => Auth.signIn(username, password))
        .then(() => handleRedirectAfterLogin())
        .catch(error => {
          // Handle confirmSignUp or signIn error
          throw error
        })
      return true
    } catch (error) {
      setConfirmationError(error.message)
      return false
    } finally {
      setSignUpSuccess(false)
    }
  }

  const signUp = async () => {
    if (!username || !email || !password) {
      setSignUpError('Email and password are required.')
      return
    }

    if (!givenName || !familyName || !occupation) {
      setSignUpError('First Name, Last Name, and Occupation are required.')
      return
    }

    try {
      await Auth.signUp({
        username,
        password,
        attributes: {
          email,
          given_name: givenName,
          family_name: familyName,
          'custom:occupation': occupation,
        },
      })
        .then(() => {
          setConfirmationSent(true)
        })
        .catch(error => {
          throw error
        })
    } catch (error) {
      setSignUpError(error.message)
    }
  }

  const handleSignInWithGoogle = async () => {
    setSignInSuccess(true)
    try {
      await Auth.federatedSignIn({ provider: 'Google' })
    } catch (error) {
      setSignInError('Error signing in with Google: ' + error.message)
    } finally {
      setSignInSuccess(false)
    }
  }

  const value = {
    handleSignInWithGoogle,
    //// States, please take me on some dates, im trying to be one of the greats
    username,
    setUsername,
    password,
    setPassword,
    email,
    setEmail,
    showSignUp,
    setShowSignUp,
    signInSuccess,
    setSignInSuccess,
    confirmationCode,
    setConfirmationCode,
    confirmationSent,
    setConfirmationSent,
    confirmationError,
    setConfirmationError,
    resetPassword,
    setResetPassword,
    resetCode,
    setResetCode,
    forgetPassword,
    setForgetPassword,
    forgotPasswordError,
    setForgotPasswordError,
    signInError,
    setSignInError,
    signUpSuccess,
    setSignUpSuccess,
    signUpError,
    setSignUpError,
    resetPasswordError,
    setResetPasswordError,
    isAuthenticated,
    setIsAuthenticated,
    givenName,
    setGivenName,
    familyName,
    setFamilyName,
    occupation,
    setOccupation,
    showAuthModal,
    setShowAuthModal,
    isDemoUser,
    setIsDemoUser,
    //// Functions
    handleSignInDemoHomepage,
    handleSignInDemo,
    handleSignIn,
    handleConfirmationCode,
    signUp,
    forgotPassword,
    submitNewPassword,
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export default AuthProvider

AuthProvider.propTypes = {
  onSignIn: PropTypes.func.isRequired,
  onSignUp: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
}
