import styles from './FinancialStatements.module.scss'
import { QuoteBar } from '../../../core/QuoteBar/QuoteBar'
import React, { useState, useMemo } from 'react'
import { useDataSource } from './data/hooks'
import {
  getDataIncomeStatement,
  getDataBalanceSheet,
  getWaterfallIncome,
  getWaterfallCashFlow,
  getWaterfallBalance,
  getWaterfallCashFlowPercentageChange,
  getWaterfallIncomePercentageChange,
  getWaterfallBalancePercentageChange,
} from './Schemas'
import { FinancialStatements } from './constant'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import CoreButton from 'core/Button/CoreButton'
import {
  BALANCE_SHEET,
  INCOME_STATEMENT,
  CASH_FLOW_STATEMENT,
} from 'constants/tableConstants'
import { SankeyChart } from './components/DThreeSankey/SankeyToolbar'
import { WaterfallChart } from './components/DThreeWaterfall/WaterfallToolBar'
import Spinner from 'core/ScrollableContainer/Spinner/spinner'
import LotsOfChartsWrapper from './components/LotsOfCharts/LotsOfCharts'
import FNtable from './components/FNTable/FNTable'
import { useFinancialStatementsContext } from './FinancialStatementsContext'
import { ChartTypeControl } from './components/ChartTypeControl'
import { Helmet } from 'react-helmet-async'
import { NoData } from 'core/NoData/NoData'
import { useUserGroup } from 'hooks/userGroupHook'
import { PREMIUM_FEATURES } from 'pages/Pricing/PriceTable/pricingConfig'

export const FinancialStatementsView = () => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)
  const { FinStat, ticker, setTicker } = useDataSource()
  const { getFeatureLimit } = useUserGroup()

  const { state, updateState } = useFinancialStatementsContext()

  const { lotsOfChartType, showChart, redGreen, selectedStatement, chartView } =
    state

  const annualDataToUse = useMemo(() => {
    switch (selectedStatement.type) {
      case INCOME_STATEMENT:
        return FinStat.IncomeData || []
      case BALANCE_SHEET:
        return FinStat.BalanceData || []
      case CASH_FLOW_STATEMENT:
        return FinStat.CashData || []
      default:
        return []
    }
  }, [selectedStatement.type, FinStat])

  const quarterlyDataToUse = useMemo(() => {
    switch (selectedStatement.type) {
      case INCOME_STATEMENT:
        return FinStat.IncomeDataQuarterly || []
      case BALANCE_SHEET:
        return FinStat.BalanceDataQuarterly || []
      case CASH_FLOW_STATEMENT:
        return FinStat.CashDataQuarterly || []
      default:
        return []
    }
  }, [selectedStatement.type, FinStat])

  const sankeyData = useMemo(() => {
    return selectedStatement.type === INCOME_STATEMENT
      ? getDataIncomeStatement(FinStat)
      : getDataBalanceSheet(FinStat)
  }, [selectedStatement.type, FinStat])

  const waterfallConfig = useMemo(() => {
    return {
      getData:
        selectedStatement.type === INCOME_STATEMENT
          ? getWaterfallIncome
          : selectedStatement.type === BALANCE_SHEET
          ? getWaterfallBalance
          : getWaterfallCashFlow,
      getPercentageData:
        selectedStatement.type === INCOME_STATEMENT
          ? getWaterfallIncomePercentageChange
          : selectedStatement.type === BALANCE_SHEET
          ? getWaterfallBalancePercentageChange
          : getWaterfallCashFlowPercentageChange,
    }
  }, [selectedStatement.type])

  const getFeatureLimitAnnual = useMemo(() => {
    return getFeatureLimit(PREMIUM_FEATURES?.FINANCIALS_ANNUAL)
  }, [getFeatureLimit])

  const getFeatureLimitQuarterly = useMemo(() => {
    return getFeatureLimit(PREMIUM_FEATURES?.FINANCIALS_QUARTERLY)
  }, [getFeatureLimit])

  const restrictedDataAnnual = annualDataToUse?.map((item, index) => {
    const isLocked = index > getFeatureLimitAnnual

    return {
      ...item,
      isLocked,
    }
  })

  const restrictedDataQuarterly = quarterlyDataToUse?.map((item, index) => {
    const isLocked = index > getFeatureLimitQuarterly

    return {
      ...item,
      isLocked,
    }
  })

  return (
    <div className={styles.financialStatementsViewWrapper}>
      <Helmet>
        <title>{`Outseek | ${ticker} Financial Statements`}</title>
        <meta
          name="description"
          content={
            "This page provides retail financial analysts with 35+ years of a company's 3 financial statements; balance sheet statement, income statement and cashflow statement. The data is display to the user in a variety of formats, tabular, sankey chart, waterfall chart and line charts."
          }
        />
      </Helmet>
      <QuoteBar ticker={ticker} updateTicker={ticker => setTicker(ticker)} />
      <div className={styles.financialStatementsContentWrapper}>
        {FinStat?.IncomeData?.length > 0 ? (
          <Tabs
            className={styles.financialStatementsTabs}
            selectedTabClassName={styles.financialStatementsSelectedTab}
          >
            <TabList className={styles.financialStatementsTabList}>
              {FinancialStatements.map((statement, index) => (
                <React.Fragment key={index}>
                  <Tab
                    className={styles.financialStatementsTab}
                    onClick={() => {
                      updateState({ selectedStatement: statement })
                      setSelectedTabIndex(index)
                    }}
                  >
                    {statement.label}
                  </Tab>
                  {index < FinancialStatements.length - 1 && (
                    <div
                      className={
                        index === selectedTabIndex ||
                        index === selectedTabIndex - 1
                          ? styles.tabSelectedDivider
                          : styles.tabDivider
                      }
                    />
                  )}
                </React.Fragment>
              ))}
              <div className={styles.buttonWrapper}>
                <ChartTypeControl
                  chartView={chartView}
                  showChart={showChart}
                  updateState={updateState}
                />
                <CoreButton
                  onClick={() => updateState({ showChart: !showChart })}
                  color={'var(--primary-color)'}
                >
                  {showChart ? 'Financial Statement' : 'Visualization'}
                </CoreButton>
              </div>
            </TabList>
            {FinStat?.dataIsLoading ? (
              <Spinner />
            ) : (
              FinancialStatements.map((statement, index) => (
                <TabPanel
                  key={index}
                  className={styles.financialStatementsTabPanel}
                >
                  {showChart ? (
                    chartView === 'sankey' ? (
                      selectedStatement.type === CASH_FLOW_STATEMENT ? (
                        <div className={styles.chartNotAvailable}>
                          Sankey chart is currently not available for cash flow
                          statement.
                        </div>
                      ) : (
                        <SankeyChart data={sankeyData} />
                      )
                    ) : chartView === 'waterfall' ? (
                      <WaterfallChart
                        data={FinStat}
                        getData={waterfallConfig.getData}
                        getPercentageData={waterfallConfig.getPercentageData}
                      />
                    ) : (
                      <LotsOfChartsWrapper
                        data={restrictedDataAnnual}
                        chartType={lotsOfChartType}
                        redGreen={redGreen}
                        setChartType={value =>
                          updateState({ lotsOfChartType: value })
                        }
                        setRedGreen={value => updateState({ redGreen: value })}
                      />
                    )
                  ) : (
                    <>
                      {FinStat?.tableDataLoading ? (
                        <Spinner />
                      ) : (
                        <FNtable
                          annualData={restrictedDataAnnual}
                          quarterlyData={restrictedDataQuarterly}
                          height={'calc(100vh - 162px)'}
                          columnKeys={statement.columns}
                          ticker={ticker}
                          type={selectedStatement.type}
                        />
                      )}
                    </>
                  )}
                </TabPanel>
              ))
            )}
          </Tabs>
        ) : (
          <NoData
            label={`No Financial Statement Data Available for ${ticker}.`}
          />
        )}
      </div>
    </div>
  )
}
