import useIsAuthenticated from 'core/AuthComponents/useIsAuthenticated'
import CoreButton from 'core/Button/CoreButton'
import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import { AuthModal } from '../AuthComponents/AuthModal'

export const ProtectedButton = ({
  onClick,
  content,
  modal = false,
  redirectTo = '/login',
  ...props
}) => {
  const { isDemoUser } = useIsAuthenticated()
  const navigate = useNavigate()
  const [showModal, setShowModal] = useState(false)

  const handleNavSignIn = () => {
    if (modal) {
      setShowModal(true)
    } else {
      navigate(redirectTo)
    }
  }

  return (
    <>
      {modal && <AuthModal showModal={showModal} setShowModal={setShowModal} />}
      {content ? (
        <div {...props} onClick={isDemoUser ? handleNavSignIn : onClick}>
          {content}
        </div>
      ) : (
        <CoreButton
          {...props}
          onClick={isDemoUser ? handleNavSignIn : onClick}
        />
      )}
    </>
  )
}

export default ProtectedButton
