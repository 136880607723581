import { comparisonChartsKeyMap } from '../../constants/fmpKeyMaps'
import numeral from 'numeral'
import dayjs from 'dayjs'
import { Line, Area, Bar } from 'recharts'

export const calculateAccumulatedPercentageChange = data => {
  if (data.length === 0) {
    return []
  }

  let accumulatedPercentageChangedData = [{ date: data[0].date }]

  for (const key in data[0]) {
    if (key !== 'date') {
      accumulatedPercentageChangedData[0][key] = 0
    }
  }

  for (let i = 1; i < data.length; i++) {
    const prevEntry = data[i - 1]
    const currentEntry = data[i]
    let newEntry = { date: currentEntry.date }

    for (const key in currentEntry) {
      if (key !== 'date') {
        const prevValue = prevEntry[key]
        const currentValue = currentEntry[key]

        const growthFactor = currentValue / prevValue

        const previousGrowthFactor =
          accumulatedPercentageChangedData[i - 1][key] / 100 + 1

        newEntry[key] = (previousGrowthFactor * growthFactor - 1) * 100
      }
    }

    accumulatedPercentageChangedData.push(newEntry)
  }

  return accumulatedPercentageChangedData
}

export const METRICS = Object.keys(comparisonChartsKeyMap)
  .map(category => Object.values(comparisonChartsKeyMap[category]))
  .flat()

export const formatAxisTickValue = (tick, showPercentageChange) => {
  if (showPercentageChange) {
    if (tick < 1000 || tick > -1000) {
      return `${numeral(tick).format('0a')}%`
    }
    return `${numeral(tick).format('0.0a')} %`
  } else if (tick < 1000 || tick > -1000) {
    return numeral(tick).format('0a')
  } else {
    return numeral(tick).format('0.00a')
  }
}

export const formatBrushTickValue = (tick, dataFrequency) => {
  if (dataFrequency === 'annual') {
    return dayjs(tick).format('YYYY')
  } else {
    return dayjs(tick).format('MMM YYYY')
  }
}

export const blendData = (existingData, newData, newMetric) => {
  if (existingData.length > 0) {
    return existingData.map((item, i) => {
      if (newData[i]) {
        return {
          ...item,
          date: newData[i]['date'],
          [newMetric]: newData[i][newMetric],
        }
      } else {
        return item
      }
    })
  } else {
    return newData.map(entry => {
      return { date: entry.date, [newMetric]: entry?.[newMetric] }
    })
  }
}

const CHART_TYPE_BAR = 'bar'
const CHART_TYPE_LINE = 'line'
const CHART_TYPE_AREA = 'area'

export const getViz = type => {
  switch (type) {
    case CHART_TYPE_AREA:
      return Area
    case CHART_TYPE_BAR:
      return Bar
    case CHART_TYPE_LINE:
      return Line
    default:
      return
  }
}
