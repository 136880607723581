export const movers = [
  {
    url: 'papi/data/api/v3/stock_market/gainers',
    label: 'Gainers',
  },
  {
    url: 'papi/data/api/v3/stock_market/actives',
    label: 'Most Active',
  },
  {
    url: 'papi/data/api/v3/stock_market/losers',
    label: 'Losers',
  },
]

export const TabStyles = {
  _TabList: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    padding: '0',
    margin: '0 ',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    alignItems: 'center',
  },
  _Tabs: {
    height: '100%',
    width: '100%',
    margin: '0',
    display: 'flex',
    flexDirection: 'column',
    border: 'none',
  },
  _TabPanel: {
    width: '100%',
    padding: '0 !important',
    margin: '0 !important',
  },
}
