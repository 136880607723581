import React from 'react'
import PropTypes from 'prop-types'
import { FormGroup, Input } from 'reactstrap'
import doubleLeftArrow from '../assets/doubleLeftArrow.svg'
import leftArrow from '../assets/leftArrow.svg'
import rightArrow from '../assets/rightArrow.svg'
import doubleRightArrow from '../assets/doubleRightArrow.svg'
import '../styles.scss'

import 'bootstrap/dist/css/bootstrap.min.css'
const ReactTablePagination = ({
  page,
  gotoPage,
  canPreviousPage,
  pageOptions,
  pageSize,
  pageCount,
  pageIndex,
  previousPage,
  nextPage,
  canNextPage,
  setPageSize,
  manualPageSize,
}) => {
  return (
    <div className="pagination">
      <div className="pagination__item" onClick={() => gotoPage(0)}>
        <img
          src={doubleLeftArrow}
          alt="doubleLeftArrow"
          style={{ width: '24px', height: '24px' }}
        />
      </div>
      <div className="pagination__item" onClick={previousPage}>
        <img
          src={leftArrow}
          alt="doubleLeftArrow"
          style={{ width: '20px', height: '20px' }}
        />
      </div>
      <div className="pagination__info">
        Rows {pageSize * pageIndex + 1} - {pageSize * pageIndex + page.length}
      </div>
      <div className="pagination__item" onClick={nextPage}>
        <img
          src={rightArrow}
          alt="doubleLeftArrow"
          style={{ width: '20px', height: '20px' }}
        />
      </div>
      <div
        className="pagination__item"
        onClick={() => gotoPage(pageOptions.length - 1)}
      >
        <img
          src={doubleRightArrow}
          alt="doubleLeftArrow"
          style={{ width: '24px', height: '24px' }}
        />
      </div>
      <div className="pagination__info">
        page {pageIndex + 1} of {pageCount}
      </div>
      {manualPageSize.length > 1 && (
        <FormGroup className="pagination__select-form ">
          <Input
            className="pagination__item pagination-info"
            type="select"
            name="select"
            id="exampleSelect"
            value={pageSize}
            onChange={event => {
              setPageSize(Number(event.target.value))
            }}
          >
            {manualPageSize.map(item => (
              <option
                className="pagination__item pagination__item-option"
                key={item}
                value={item}
              >
                Show {item}
              </option>
            ))}
          </Input>
        </FormGroup>
      )}
    </div>
  )
}

ReactTablePagination.propTypes = {
  dataLength: PropTypes.number.isRequired,
  page: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  gotoPage: PropTypes.func.isRequired,
  canNextPage: PropTypes.bool.isRequired,
  canPreviousPage: PropTypes.bool.isRequired,
  pageOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  pageSize: PropTypes.number.isRequired,
  pageIndex: PropTypes.number.isRequired,
  previousPage: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
  setPageSize: PropTypes.func.isRequired,
  manualPageSize: PropTypes.arrayOf(PropTypes.number),
}

ReactTablePagination.defaultProps = {
  manualPageSize: [10, 20, 30, 40, 50, 75, 100],
}

export default ReactTablePagination
