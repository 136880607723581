import React from 'react'
import _ from 'lodash'
import numeral from 'numeral'
import { Pie, Cell, Tooltip, PieChart } from 'recharts'

import { RANDOMCHARTCOLORS } from '../../constants/theme'

const RADIAN = Math.PI / 180
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)

  return (
    <text
      x={x}
      y={y}
      fill="var(--white)"
      textAnchor="middle"
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  )
}
export const renderTooltipContentPieChart = (o, nameOnly) => {
  const { payload } = o

  return (
    <div className="customized-tooltip-content">
      <div>
        {payload.map((entry, index) => (
          <div
            className="listItem"
            key={`item-${index}`}
            style={{ color: entry.color }}
          >
            {nameOnly
              ? `${_.startCase(entry.payload?.payload?.segment)}: ${numeral(
                  entry.value
                ).format('0.000 a')}`
              : `${entry?.name} ${_.startCase(
                  entry.payload?.payload?.segment
                )}`}
          </div>
        ))}
      </div>
    </div>
  )
}

export const RadialChartWrapper = ({ data }) => {
  return (
    <PieChart width={200} height={170}>
      <Pie
        data={data}
        outerRadius={80}
        fill="#8884d8"
        stroke="#dedede"
        paddingAngle={0}
        dataKey="value"
        label={renderCustomizedLabel}
        labelLine={false}
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={RANDOMCHARTCOLORS[index % 10]} />
        ))}
      </Pie>
      <Tooltip
        itemStyle={{ fontSize: '12px' }}
        labelStyle={{
          fontSize: '10.5px',
          color: 'rgba(215, 215, 215)',
        }}
        contentStyle={{ background: 'transparent', border: 'transparent' }}
        content={content => renderTooltipContentPieChart(content, true)}
      />
    </PieChart>
  )
}
