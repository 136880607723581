import { XAxis, YAxis, AreaChart, Area, Tooltip } from 'recharts'

import dayjs from 'dayjs'
export const ConfigurableChart1 = ({
  width,
  height,
  margin,
  CartesianGrid,
  apiKey,
  label,
  data,
}) => {
  return (
    <>
      <AreaChart
        width={width || 270}
        height={height || 180}
        data={data}
        margin={margin || { right: 20, left: 20 }}
      >
        <CartesianGrid
          strokeDasharray="0"
          vertical={false}
          stroke="var(--button-hover)"
        />
        <Tooltip
          contentStyle={{
            padding: '6px',
            backgroundColor: 'rgba(33, 33, 33, 0.9)',
            border: '1px solid rgba(150, 150, 150)',
            height: 'fit-content',
            width: 'auto',
            borderRadius: 'var(--border-radius)',
          }}
          labelStyle={{ color: 'var(--white)', fontSize: '10.5px' }}
          itemStyle={{ fontSize: '11px' }}
          labelFormatter={label => {
            return dayjs(label).format('MMM D, YYYY')
          }}
        />
        <XAxis
          dataKey="date"
          reversed={true}
          width={40}
          tickLine={{ stroke: 'var(--grey-accent)' }}
          axisLine={{ stroke: 'var(--grey-accent)' }}
          tick={{ stroke: 'var(--grey-accent)', fontWeight: '300' }}
          tickFormatter={tick => {
            return dayjs(tick).format('MMM D, YY`')
          }}
        />
        <YAxis
          tick={{
            zIndex: 1000,
            stroke: 'var(--grey-accent)',
            fontWeight: '300',
          }}
          width={10}
          allowDataOverflow={true}
          domain={[0, 100]}
        />
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop
              offset="5%"
              stopColor="var(--primary-color)"
              stopOpacity={0.7}
            />
            <stop
              offset="95%"
              stopColor="var(--primary-color)"
              stopOpacity={0}
            />
          </linearGradient>
        </defs>
        <Area
          type="monotone"
          dataKey={'ESGScore'}
          stroke="var(--primary-color)"
          fill="url(#colorUv)"
          strokeWidth={2}
          dot={false}
        />
      </AreaChart>
    </>
  )
}
