import React, { useState, useEffect } from 'react'
import Fuse from 'fuse.js'
import { TOOLBAR_MENU_ITEMS } from '../constants'
import { comparisonChartsKeyMap } from '../../../constants/fmpKeyMaps'
import deepcopy from 'deepcopy'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'

import styles from './metricCatalog.module.scss'
import {
  UilAngleDoubleUp,
  UilAngleDoubleDown,
  UilPlusSquare,
} from '@iconscout/react-unicons'
import MetricInfo from 'core/MetricInfoHover/MetricInfoTooltip'

export const MetricCatalog = ({
  comparisonChartCallbacks,
  selectedMetrics,
  isDropdownVisible,
  setIsDropdownVisible,
}) => {
  const [expanded, setExpanded] = useState(false)
  const [fuse, setFuse] = useState(null)
  const [isSearching, setIsSearching] = useState(false)
  const [searchResults, setSearchResults] = useState([])
  const handleToggleCloseCatalog = () => {
    setIsDropdownVisible(!isDropdownVisible)
    setExpanded(!expanded)
  }

  useEffect(() => {
    const options = {
      keys: ['metric'],
      includeScore: true,
      threshold: 0.3,
      location: 0,
      distance: 100,
      shouldSort: false,
      ignoreLocation: false,
    }
    const metricsList = TOOLBAR_MENU_ITEMS.flatMap(category =>
      Object.keys(comparisonChartsKeyMap[category.KeyMap]).map(metricKey => ({
        metricKey,
        metric: comparisonChartsKeyMap[category.KeyMap][metricKey],
      }))
    )
    setFuse(new Fuse(metricsList, options))
  }, [])

  const handleSearch = searchTerm => {
    if (fuse && searchTerm.trim()) {
      setIsSearching(true)
      const results = fuse.search(searchTerm).map(result => result.item)
      setSearchResults(results)
    } else {
      setIsSearching(false)
      setSearchResults([])
    }
  }

  const handlePushMetric = (category, metricKey) => {
    if (isMetricInMetrics(selectedMetrics, metricKey)) {
      return
    }
    let newItem = deepcopy(category)
    newItem.APIProperty = metricKey
    comparisonChartCallbacks.handleAddComparisonMetric(newItem)
  }

  const isMetricInMetrics = (metricsArray, metricToCheck) => {
    return metricsArray.some(metric => metric.APIProperty === metricToCheck)
  }

  const getTabMetrics = () => {
    return TOOLBAR_MENU_ITEMS.map(category => ({
      category,
      metrics: Object.keys(comparisonChartsKeyMap[category.KeyMap]).map(
        metricKey => ({
          metricKey,
          metric: comparisonChartsKeyMap[category.KeyMap][metricKey],
        })
      ),
    }))
  }

  return (
    <div
      className={styles.comparisonChartsMetricCatalogContainer}
      style={{
        height: !expanded ? '270px' : '30px',
        transition: 'all 0.3s ease-in-out',
      }}
    >
      <div
        className={styles.metricCatalogContainerTitle}
        onClick={() => handleToggleCloseCatalog()}
      >
        <div className={styles.metricCatalogExpandArrow}>
          {isDropdownVisible ? (
            <UilAngleDoubleUp className={styles.icon} />
          ) : (
            <UilAngleDoubleDown className={styles.icon} />
          )}
          Add Metrics to Chart
        </div>
      </div>
      <input
        className={styles.metricCatalogInput}
        placeholder={'Search metrics...'}
        onChange={e => handleSearch(e.target.value)}
        style={{ display: isDropdownVisible ? 'none' : null }}
      />
      {isSearching ? (
        <div
          className={styles.tab}
          style={{ display: isDropdownVisible ? 'none' : null }}
        >
          <div className={styles.metricCatalogCategoryListWrapper}>
            {searchResults.map(({ metricKey, metric }) => (
              <div
                className={styles.metricCatalogCategoryListItem}
                onClick={() => handlePushMetric(null, metricKey)}
              >
                <UilPlusSquare
                  size={15}
                  color={
                    isMetricInMetrics(selectedMetrics, metricKey)
                      ? 'var(--primary-color)'
                      : 'var(--light-grey-accent)'
                  }
                />
                <MetricInfo metricKey={metricKey} displayMetric={metric} />
              </div>
            ))}
          </div>
        </div>
      ) : (
        <Tabs
          selectedTabClassName={styles.selectedTab}
          className={styles.tab}
          style={{ display: isDropdownVisible ? 'none' : null }}
        >
          <TabList className={styles.tabList}>
            {TOOLBAR_MENU_ITEMS.map((category, index) => {
              return (
                <>
                  <Tab
                    className={styles.catalogTabs}
                    selectedClassName={styles.selectedTab}
                  >
                    {category?.Label}
                  </Tab>
                  {index !== TOOLBAR_MENU_ITEMS.length - 1 && (
                    <div className={styles.tabDivider} />
                  )}
                </>
              )
            })}
          </TabList>
          {getTabMetrics().map(({ category, metrics }) => (
            <TabPanel style={{ width: '100%' }}>
              <div className={styles.metricCatalogCategoryListWrapper}>
                {metrics.map(({ metricKey, metric }) => {
                  return (
                    <div
                      className={styles.metricCatalogCategoryListItem}
                      onClick={() => handlePushMetric(category, metricKey)}
                    >
                      <UilPlusSquare
                        size={15}
                        color={
                          isMetricInMetrics(selectedMetrics, metricKey)
                            ? 'var(--primary-color)'
                            : 'var(--light-grey-accent)'
                        }
                      />
                      <MetricInfo
                        metricKey={metricKey}
                        displayMetric={metric}
                      />
                    </div>
                  )
                })}
              </div>
            </TabPanel>
          ))}
        </Tabs>
      )}
    </div>
  )
}

export default MetricCatalog
