import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import { Desc } from '../Desc/Desc'
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '440px',
  background: '#343A40',
  borderRadius: '10px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  boxShadow: 24,
  p: 4,

  '&:focus': {
    outline: 'none',
  },
}
export const InfoModal = ({ open, handleClose, entry }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableAutoFocus={true}
    >
      <Box sx={style}>
        <Desc entry={entry}></Desc>
      </Box>
    </Modal>
  )
}
