import React, { useState, useMemo } from 'react'
import styles from './analytics.module.scss'
import { QuoteBar } from 'core/QuoteBar/QuoteBar'
import { CategoryPill } from './components/CategoryPill/CategoryPill'
import { analyticsCategories } from './constants'
import { useDataSource } from './data/hooks'
import { countryCodes } from 'dashboard/ServerSideScreener/components/MetricCatalog/countryCodes'
import { useRatioData } from 'pages/views/CompanyAnalytics/data/dataTransform'
import { AnalyticalPanel } from 'pages/views/CompanyAnalytics/components/Comparison/AnalyticalPanel/AnalyticalPanel'
import { CardWrapper } from '../../../core/CardWrapper/CardWrapper'
import { UilInfoCircle } from '@iconscout/react-unicons'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { Helmet } from 'react-helmet-async'
import { NoData } from 'core/NoData/NoData'

export const Analytics = () => {
  const { CompanyData_UI, setTicker, ticker, companyName } = useDataSource()

  const [state, setState] = useState({
    selectedCategories: {
      sector: true,
    },
    isLoading: false,
  })

  const selectedGroupByColumns = useMemo(() => {
    return Object.keys(state.selectedCategories).filter(
      key => state.selectedCategories[key]
    )
  }, [state.selectedCategories])

  const toggleCategorySelection = category => {
    setState(prevState => ({
      ...prevState,
      selectedCategories: {
        ...prevState.selectedCategories,
        [category]: !prevState.selectedCategories[category],
      },
    }))
  }

  const getDataMetric = category => {
    if (category === 'country') {
      return countryCodes[CompanyData_UI?.profileData?.[0]?.[category]]
    } else {
      return CompanyData_UI?.profileData?.[0]?.[category]
    }
  }

  const {
    scores,
    selectedMetrics,
    overallScore,
    analyticsData,
    finData,
    allScores,
    growthData,
  } = useRatioData({ selectedGroupByColumns })

  const isFinDataAvailable = finData && Object.keys(finData).length > 0

  return (
    <div className={styles.analyticsView}>
      <Helmet>
        <title>{`Outseek | ${ticker} Analytics`}</title>
        <meta
          name="description"
          content={
            "This page provides retail financial analysts with a summary of a company's financials."
          }
        />
      </Helmet>
      <QuoteBar ticker={ticker} updateTicker={setTicker} />

      <div className={styles.analyticsContentWrapper}>
        {isFinDataAvailable ? (
          <>
            <div className={styles.overviewWrapper}>
              <CardWrapper
                width={'100%'}
                content={
                  <div className={styles.pills}>
                    <div className={styles.pillsWrapper}>
                      {analyticsCategories.map(category => (
                        <CategoryPill
                          key={category}
                          category={category}
                          onClick={toggleCategorySelection}
                          isSelected={state.selectedCategories[category]}
                          dataMetric={getDataMetric(category)}
                        />
                      ))}
                    </div>
                    <UilInfoCircle
                      data-tooltip-id={'info'}
                      color={'var(--off-white)'}
                      style={{ userSelect: 'none' }}
                    />
                    <ReactTooltip
                      id="info"
                      content="Select analytical categories to filter the analytical data."
                      place="left"
                      className={styles.customTooltip}
                    />
                  </div>
                }
                label={'Analytical Categories'}
              />
            </div>
            <div className={styles.contentWrapper}>
              <AnalyticalPanel
                selectedFinancialMetrics={selectedMetrics}
                selectedGroupByColumns={selectedGroupByColumns}
                companyData={CompanyData_UI}
                finData={finData}
                recentAnalyticsData={analyticsData}
                symbol={ticker}
                scores={scores}
                allScores={allScores}
                overallScore={overallScore}
                state={state.selectedCategories}
                companyName={companyName}
                growthData={growthData}
              />
            </div>
          </>
        ) : (
          <NoData label={`No Analytics data available for ${ticker}.`} />
        )}
      </div>
    </div>
  )
}
