import React, { useState, useRef, useMemo } from 'react'
import { FiSearch } from 'react-icons/fi'
import Fuse from 'fuse.js'
import Select from 'react-select'
import { tickerList, topTickerList } from 'constants/tickerList'
import {
  institutionalList,
  topInstitutionalList,
} from 'constants/institutionalList'
import { matchSorter } from 'match-sorter'

const TickerSearchView = ({
  updateTicker,
  ticker,
  customPlaceholder,
  styles,
  isClearable = false,
}) => {
  const [options, setOptions] = useState(topTickerList)
  const selectRef = useRef(0)
  const placeholder = useMemo(() => {
    if (customPlaceholder && ticker === null) {
      return customPlaceholder
    }
    let place =
      tickerList.find(t => t.value === ticker) ||
      tickerList.find(t => t.value === 'AAPL')
    place = `${place.name} (${place.value})`
    return place
  }, [ticker, customPlaceholder])

  const handleOnSelect = item => {
    updateTicker(item?.value)
  }

  const fuse = new Fuse(tickerList, {
    keys: ['name', 'symbol'],
    includeScore: true,
    shouldSort: true,
    threshold: 0.3,
  })

  const handleChangeInput = value => {
    if (value) {
      const results = fuse.search(value).map(({ item }) => item)
      return setOptions(results.slice(0, 10))
    }
    setOptions(topTickerList)
  }

  return (
    <Select
      maxMenuHeight={360}
      ref={selectRef}
      pageSize={1}
      onChange={e => handleOnSelect(e)}
      styles={styles ? styles : customStyles}
      components={{
        DropdownIndicator: () => (
          <FiSearch
            color={styles ? 'var(--light-grey-accent)' : 'var(--white)'}
          />
        ),
        IndicatorSeparator: null,
      }}
      onInputChange={e => handleChangeInput(e)}
      options={options}
      closeMenuOnSelect={true}
      value={ticker}
      placeholder={placeholder}
      formatOptionLabel={option => `${option.name} (${option.value})`}
      isClearable={isClearable}
    />
  )
}

export const InstitutionalTickerSearch = ({ updateTicker }) => {
  const [options, setOptions] = useState(topInstitutionalList)
  const selectRef = useRef(0)

  const handleOnSelect = item => {
    updateTicker(item?.value)
  }

  const handleChangeInput = value => {
    if (value) {
      return setOptions(
        matchSorter(institutionalList, value, {
          keys: ['name'],
        })?.slice(0, 10)
      )
    }
    setOptions(topInstitutionalList)
  }

  return (
    <Select
      maxMenuHeight={360}
      ref={selectRef}
      pageSize={1}
      onChange={e => handleOnSelect(e)}
      styles={customStyles}
      components={{
        DropdownIndicator: () => <FiSearch />,
        IndicatorSeparator: null,
      }}
      onInputChange={e => handleChangeInput(e)}
      options={options}
      closeMenuOnSelect={true}
      placeholder="BERKSHIRE HATHAWAY INC"
    />
  )
}
export default TickerSearchView

const customStyles = {
  control: () => ({
    backgroundColor: 'var(--background-primary)',
    display: 'flex',
    color: 'var(--white)',
    flexDirection: 'row',
    height: 36,
    width: 'fit-content',
    alignItems: 'center',
    fontSize: '14px',
    margin: '0px',
    borderRadius: '4px',
    cursor: 'pointer',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: '8px',
  }),
  placeholder: base => ({
    ...base,
    color: 'var(--white)',
    fontSize: '16px',
    whiteSpace: 'nowrap',
  }),
  singleValue: () => ({
    color: 'var(--white)',
    gridArea: '1/1/2/3',
    marginLeft: '1px',
    marginRight: '1px',
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    fontSize: '16px',
    cursor: 'text',
  }),
  indicatorSeparator: () => ({
    backgroundColor: 'var(--off-white)',
    alignSelf: 'stretch',
    marginBottom: '8px',
    marginTop: '8px',
    width: '1px',
    boxSizing: 'border-box',
  }),
  menu: () => ({
    position: 'absolute',
    backgroundColor: 'var(--background-primary)',
    borderRadius: '4px',
    border: '2px solid var(--grey-accent)',
    boxShadow:
      '0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 12px hsl(0deg 0% 0% / 10%)',
    marginBottom: '8px',
    marginTop: '8px',
    zIndex: 100,
    boxSizing: 'border-box',
    width: '600px',
  }),
  menuPortal: provided => ({ ...provided, zIndex: 9999 }),
  MenuList: () => ({
    paddingBottom: '4px',
    maxHeight: '100px',
    overflowY: 'auto',
    paddingTop: '4px',
    zIndex: 100,
    position: 'relative',
    boxSizing: 'border-box',
  }),
  option: () => ({
    backgroundColor: 'transparent',
    color: 'var(--off-white)',
    display: 'block',
    fontSize: '13px',
    zIndex: 100,
    padding: '8px 12px',
    width: '',
    cursor: 'pointer',
    boxSizing: 'border-box',
    '&:hover': {
      backgroundColor: 'var(--button-hover)',
    },
  }),
  input: (provided, state) => ({
    ...provided,
    color: 'var(--white)',
    fontSize: '16px',
  }),
}
