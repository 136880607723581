import dayjs from 'dayjs'
import { createCell } from './utils'

export const displayPeriod = ({ value, rowOriginal }) => {
  return (
    <a
      style={{
        color: 'var(--primary-color)',
      }}
      href={rowOriginal?.finalLink}
      target={'_blank'}
      rel="noreferrer"
    >
      <span>{`${value} ${rowOriginal?.calendarYear}`}</span>
    </a>
  )
}

export const incomeColumnKeys = [
  {
    Header: 'Period',
    accessor: 'period',
    Cell: ({ value, rowOriginal }) => {
      return displayPeriod({ value, rowOriginal })
    },
  },
  {
    Header: 'Revenue',
    accessor: 'revenue',
    total: true,
  },
  { Header: 'Cost of Revenue', accessor: 'costOfRevenue' },
  { Header: 'Gross Profit', accessor: 'grossProfit', total: true },
  { Header: 'Gross Profit Ratio', accessor: 'grossProfitRatio' },
  { Header: 'Operating Expenses', accessor: 'operatingExpenses', total: true },
  {
    Header: '‎ ‎ ‎ ‎ Research & Development Expenses',
    accessor: 'researchAndDevelopmentExpenses',
  },
  {
    Header: '‎ ‎ ‎ ‎ General & Administrative Expenses',
    accessor: 'generalAndAdministrativeExpenses',
  },
  {
    Header: '‎ ‎ ‎ ‎ Selling & Marketing Expenses',
    accessor: 'sellingAndMarketingExpenses',
  },
  {
    Header: '‎ ‎ ‎ ‎ Selling General & Administrative Expenses',
    accessor: 'sellingGeneralAndAdministrativeExpenses',
  },
  { Header: '‎ ‎ ‎ ‎ Other Expenses', accessor: 'otherExpenses' },
  { Header: 'Cost & Expenses', accessor: 'costAndExpenses', total: true },
  { Header: 'Interest Income', accessor: 'interestIncome' },
  { Header: 'Interest Expense', accessor: 'interestExpense' },
  {
    Header: 'Depreciation & Amortization',
    accessor: 'depreciationAndAmortization',
  },
  { Header: 'EBITDA', accessor: 'ebitda', total: true },
  { Header: 'EBITDA Ratio', accessor: 'ebitdaratio' },
  { Header: 'Operating Income', accessor: 'operatingIncome', total: true },
  { Header: 'Operating Income Ratio', accessor: 'operatingIncomeRatio' },
  {
    Header: 'Total Other Income Expenses Net',
    accessor: 'totalOtherIncomeExpensesNet',
    total: true,
  },
  { Header: 'Income Before Tax', accessor: 'incomeBeforeTax' },
  { Header: 'Income Before Tax Ratio', accessor: 'incomeBeforeTaxRatio' },
  { Header: 'Income Tax Expense', accessor: 'incomeTaxExpense' },
  { Header: 'Net Income', accessor: 'netIncome', total: true },
  { Header: 'Net Income Ratio', accessor: 'netIncomeRatio' },
  { Header: 'EPS', accessor: 'eps' },
  { Header: 'EPS Diluted ', accessor: 'epsdiluted' },
  {
    Header: 'Weighted Average Shares Outstanding',
    accessor: 'weightedAverageShsOut',
  },
  {
    Header: 'Weighted Average Shares Outstanding Diluted',
    accessor: 'weightedAverageShsOutDil',
  },
]

export const balanceColumnKeys = [
  {
    Header: 'Period',
    accessor: 'period',
    Cell: ({ value, rowOriginal }) => {
      return displayPeriod({ value, rowOriginal })
    },
  },
  {
    Header: '‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎Cash & Cash Equivalents',
    accessor: 'cashAndCashEquivalents',
  },
  {
    Header: '‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎Short-Term Investments',
    accessor: 'shortTermInvestments',
  },
  {
    Header: '‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎Cash & Short-Term Investments',
    accessor: 'cashAndShortTermInvestments',
  },
  { Header: '‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎Net Receivables', accessor: 'netReceivables' },
  { Header: '‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎Inventory', accessor: 'inventory' },
  {
    Header: '‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎Other Current Assets',
    accessor: 'otherCurrentAssets',
  },
  {
    Header: '‎ ‎ ‎ ‎Total Current Assets',
    accessor: 'totalCurrentAssets',
    total: true,
  },
  {
    Header: '‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎Property Plant Equipment Net',
    accessor: 'propertyPlantEquipmentNet',
  },
  { Header: '‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎Goodwill', accessor: 'goodwill' },
  { Header: '‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎Intangible Assets', accessor: 'intangibleAssets' },
  {
    Header: '‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎Goodwill & Intangible Assets',
    accessor: 'goodwillAndIntangibleAssets',
  },
  {
    Header: '‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎Long-Term Investments',
    accessor: 'longTermInvestments',
  },
  { Header: '‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎Tax Assets', accessor: 'taxAssets' },
  {
    Header: '‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎Other Non-Current Assets',
    accessor: 'otherNonCurrentAssets',
  },
  {
    Header: '‎ ‎ ‎ ‎Total Non-Current Assets',
    accessor: 'totalNonCurrentAssets',
    total: true,
  },
  { Header: 'Other Assets', accessor: 'otherAssets' },
  { Header: 'Total Assets', accessor: 'totalAssets', total: true },
  { Header: '‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎Account Payables', accessor: 'accountPayables' },
  { Header: '‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎Short-Term Debt', accessor: 'shortTermDebt' },
  { Header: '‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎Tax Payables', accessor: 'taxPayables' },
  { Header: '‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎Deferred Revenue', accessor: 'deferredRevenue' },
  {
    Header: '‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎Other Current Liabilities',
    accessor: 'otherCurrentLiabilities',
  },
  {
    Header: '‎ ‎ ‎ ‎Total Current Liabilities',
    accessor: 'totalCurrentLiabilities',
    total: true,
  },
  { Header: '‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎Long-Term Debt', accessor: 'longTermDebt' },
  {
    Header: '‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎Deferred Revenue Non-Current',
    accessor: 'deferredRevenueNonCurrent',
  },
  {
    Header: '‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎Deferred Tax Liabilities Non-Current',
    accessor: 'deferredTaxLiabilitiesNonCurrent',
  },
  {
    Header: '‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎Other Non-Current Liabilities',
    accessor: 'otherNonCurrentLiabilities',
  },
  {
    Header: '‎ ‎ ‎ ‎Total Non-Current Liabilities',
    accessor: 'totalNonCurrentLiabilities',
    total: true,
  },
  { Header: 'Other Liabilities', accessor: 'otherLiabilities' },
  { Header: 'Capital Lease Obligations', accessor: 'capitalLeaseObligations' },
  { Header: 'Total Liabilities', accessor: 'totalLiabilities', total: true },
  { Header: '‎ ‎ ‎ ‎ Preferred Stock', accessor: 'preferredStock' },
  { Header: '‎ ‎ ‎ ‎ Common Stock', accessor: 'commonStock' },
  { Header: '‎ ‎ ‎ ‎ Retained Earnings', accessor: 'retainedEarnings' },
  {
    Header: '‎ ‎ ‎ ‎ Accumulated Comprehensive Income Loss',
    accessor: 'accumulatedOtherComprehensiveIncomeLoss',
  },
  {
    Header: '‎ ‎ ‎ ‎ Other Total Stockholders Equity',
    accessor: 'othertotalStockholdersEquity',
  },
  { Header: 'Total Stockholders Equity', accessor: 'totalStockholdersEquity' },
  { Header: 'Total Equity', accessor: 'totalEquity' },
  {
    Header: 'Total Liabilities & Stockholders Equity',
    accessor: 'totalLiabilitiesAndStockholdersEquity',
  },
  { Header: 'Minority Interest', accessor: 'minorityInterest' },
  {
    Header: 'Total Liabilities & Total Equity',
    accessor: 'totalLiabilitiesAndTotalEquity',
    total: true,
  },
  { Header: 'Total Investments', accessor: 'totalInvestments' },
  { Header: 'Total Debt', accessor: 'totalDebt' },
  { Header: 'Net Debt', accessor: 'netDebt' },
]

export const cashColumnKeys = [
  {
    Header: 'Period',
    accessor: 'period',
    Cell: ({ value, rowOriginal }) => {
      return displayPeriod({ value, rowOriginal })
    },
  },
  { Header: '‎ ‎ ‎ ‎Net Income', accessor: 'netIncome' },
  {
    Header: '‎ ‎ ‎ ‎Depreciation & Amortization',
    accessor: 'depreciationAndAmortization',
  },
  { Header: '‎ ‎ ‎ ‎Deferred Income Tax', accessor: 'deferredIncomeTax' },
  {
    Header: '‎ ‎ ‎ ‎Stock-Based Compensation',
    accessor: 'stockBasedCompensation',
  },
  {
    Header: '‎ ‎ ‎ ‎Change in Working Capital',
    accessor: 'changeInWorkingCapital',
  },
  {
    Header: '‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎Accounts Receivables',
    accessor: 'accountsReceivables',
  },
  { Header: '‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎Inventory', accessor: 'inventory' },
  { Header: '‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎Accounts Payables', accessor: 'accountsPayables' },
  {
    Header: '‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎Other Working Capital',
    accessor: 'otherWorkingCapital',
  },
  { Header: '‎ ‎ ‎ ‎Other Non-Cash Items', accessor: 'otherNonCashItems' },
  {
    Header: 'Net Cash Provided by Operating Activities',
    accessor: 'netCashProvidedByOperatingActivities',
    total: true,
  },
  {
    Header: '‎ ‎ ‎ ‎Investments in Property, Plant, & Equipment',
    accessor: 'investmentsInPropertyPlantAndEquipment',
  },
  { Header: '‎ ‎ ‎ ‎Acquisitions Net', accessor: 'acquisitionsNet' },
  {
    Header: '‎ ‎ ‎ ‎Purchases of Investments',
    accessor: 'purchasesOfInvestments',
  },
  {
    Header: '‎ ‎ ‎ ‎Sales/Maturities of Investments',
    accessor: 'salesMaturitiesOfInvestments',
  },
  {
    Header: '‎ ‎ ‎ ‎Other Investing Activities',
    accessor: 'otherInvestingActivites',
  },
  {
    Header: 'Net Cash Used for Investing Activities',
    accessor: 'netCashUsedForInvestingActivites',
    total: true,
  },
  { Header: '‎ ‎ ‎ ‎Debt Repayment', accessor: 'debtRepayment' },
  { Header: '‎ ‎ ‎ ‎Common Stock Issued', accessor: 'commonStockIssued' },
  {
    Header: '‎ ‎ ‎ ‎Common Stock Repurchased',
    accessor: 'commonStockRepurchased',
  },
  { Header: '‎ ‎ ‎ ‎Dividends Paid', accessor: 'dividendsPaid' },
  {
    Header: '‎ ‎ ‎ ‎Other Financing Activities',
    accessor: 'otherFinancingActivites',
  },
  {
    Header: 'Net Cash Used/Provided by Financing Activities',
    accessor: 'netCashUsedProvidedByFinancingActivities',
    total: true,
  },
  {
    Header: 'Effect of Forex Changes on Cash',
    accessor: 'effectOfForexChangesOnCash',
  },
  { Header: 'Net Change in Cash', accessor: 'netChangeInCash' },
  { Header: 'Cash at End of Period', accessor: 'cashAtEndOfPeriod' },
  {
    Header: 'Cash at Beginning of Period',
    accessor: 'cashAtBeginningOfPeriod',
  },
  { Header: '‎ ‎ ‎ ‎Operating Cash Flow', accessor: 'operatingCashFlow' },
  { Header: '‎ ‎ ‎ ‎Capital Expenditure', accessor: 'capitalExpenditure' },
  { Header: 'Free Cash Flow', accessor: 'freeCashFlow', total: true },
]

export const incomeGrowthColumnKeys = [
  {
    Header: 'Period',
    accessor: 'period',
    Cell: ({ value, rowOriginal }) => {
      return displayPeriod({ value, rowOriginal })
    },
  },
  {
    Header: `Revenue Growth`,
    ...createCell('growthRevenue'),
  },
  {
    Header: 'Cost of Revenue Growth',
    ...createCell('growthCostOfRevenue'),
  },
  {
    Header: 'Gross Profit Growth',
    ...createCell('growthGrossProfit'),
  },
  {
    Header: 'Gross Profit Ratio Growth',
    ...createCell('growthGrossProfitRatio'),
  },
  {
    Header: 'Operating Expenses Growth',
    ...createCell('growthOperatingExpenses'),
  },
  {
    Header: 'Research & Development Expenses Growth',
    ...createCell('growthResearchAndDevelopmentExpenses'),
  },
  {
    Header: 'General & Administrative Expenses Growth',
    ...createCell('growthGeneralAndAdministrativeExpenses'),
  },
  {
    Header: 'Selling & Marketing Expenses Growth',
    ...createCell('growthSellingAndMarketingExpenses'),
  },
  {
    Header: 'Other Expenses Growth',
    ...createCell('growthOtherExpenses'),
  },
  {
    Header: 'Cost & Expenses Growth',
    ...createCell('growthCostAndExpenses'),
  },
  {
    Header: 'Interest Expense Growth',
    ...createCell('growthInterestExpense'),
  },
  {
    Header: 'Depreciation & Amortization Growth',
    ...createCell('growthDepreciationAndAmortization'),
  },
  {
    Header: 'EBITDA Growth',
    ...createCell('growthEBITDA'),
  },
  {
    Header: 'EBITDA Ratio Growth',
    ...createCell('growthEBITDARatio'),
  },
  {
    Header: 'Operating Income Growth',
    ...createCell('growthOperatingIncome'),
  },
  {
    Header: 'Operating Income Ratio Growth',
    ...createCell('growthOperatingIncomeRatio'),
  },
  {
    Header: 'Total Other Income Expenses Net Growth',
    ...createCell('growthTotalOtherIncomeExpensesNet'),
  },
  {
    Header: 'Income Before Tax Growth',
    ...createCell('growthIncomeBeforeTax'),
  },
  {
    Header: 'Income Before Tax Ratio Growth',
    ...createCell('growthIncomeBeforeTaxRatio'),
  },
  {
    Header: 'Income Tax Expense Growth',
    ...createCell('growthIncomeTaxExpense'),
  },
  {
    Header: 'Net Income Growth',
    ...createCell('growthNetIncome'),
  },
  {
    Header: 'Net Income Ratio Growth',
    ...createCell('growthNetIncomeRatio'),
  },
  {
    Header: 'EPS Growth',
    ...createCell('growthEPS'),
  },
  {
    Header: 'EPS Diluted Growth',
    ...createCell('growthEPSDiluted'),
  },
  {
    Header: 'Weighted Average Shares Outstanding Growth',
    ...createCell('growthWeightedAverageShsOut'),
  },
  {
    Header: 'Weighted Average Shares Outstanding Diluted Growth',
    ...createCell('growthWeightedAverageShsOutDil'),
  },
]

export const balanceGrowthKeys = [
  { Header: 'Period', accessor: 'period' },
  {
    Header: 'Cash and Cash Equivalents Growth',
    accessor: 'growthCashAndCashEquivalents',
  },
  {
    Header: 'Short Term Investments Growth',
    accessor: 'growthShortTermInvestments',
  },
  {
    Header: 'Cash and Short Term Investments Growth',
    accessor: 'growthCashAndShortTermInvestments',
  },
  { Header: 'Net Receivables Growth', accessor: 'growthNetReceivables' },
  { Header: 'Inventory Growth', accessor: 'growthInventory' },
  {
    Header: 'Other Current Assets Growth',
    accessor: 'growthOtherCurrentAssets',
  },
  {
    Header: 'Total Current Assets Growth',
    accessor: 'growthTotalCurrentAssets',
  },
  {
    Header: 'Property Plant Equipment Net Growth',
    accessor: 'growthPropertyPlantEquipmentNet',
  },
  { Header: 'Goodwill Growth', accessor: 'growthGoodwill' },
  { Header: 'Intangible Assets Growth', accessor: 'growthIntangibleAssets' },
  {
    Header: 'Goodwill and Intangible Assets Growth',
    accessor: 'growthGoodwillAndIntangibleAssets',
  },
  {
    Header: 'Long Term Investments Growth',
    accessor: 'growthLongTermInvestments',
  },
  { Header: 'Tax Assets Growth', accessor: 'growthTaxAssets' },
  {
    Header: 'Other Non-Current Assets Growth',
    accessor: 'growthOtherNonCurrentAssets',
  },
  {
    Header: 'Total Non-Current Assets Growth',
    accessor: 'growthTotalNonCurrentAssets',
  },
  { Header: 'Other Assets Growth', accessor: 'growthOtherAssets' },
  { Header: 'Total Assets Growth', accessor: 'growthTotalAssets' },
  { Header: 'Account Payables Growth', accessor: 'growthAccountPayables' },
  { Header: 'Short Term Debt Growth', accessor: 'growthShortTermDebt' },
  { Header: 'Tax Payables Growth', accessor: 'growthTaxPayables' },
  { Header: 'Deferred Revenue Growth', accessor: 'growthDeferredRevenue' },
  {
    Header: 'Other Current Liabilities Growth',
    accessor: 'growthOtherCurrentLiabilities',
  },
  {
    Header: 'Total Current Liabilities Growth',
    accessor: 'growthTotalCurrentLiabilities',
  },
  { Header: 'Long Term Debt Growth', accessor: 'growthLongTermDebt' },
  {
    Header: 'Deferred Revenue Non-Current Growth',
    accessor: 'growthDeferredRevenueNonCurrent',
  },
  {
    Header: 'Deferred Tax Liabilities Non-Current Growth',
    accessor: 'growthDeferrredTaxLiabilitiesNonCurrent',
  },
  {
    Header: 'Other Non-Current Liabilities Growth',
    accessor: 'growthOtherNonCurrentLiabilities',
  },
  {
    Header: 'Total Non-Current Liabilities Growth',
    accessor: 'growthTotalNonCurrentLiabilities',
  },
  { Header: 'Other Liabilities Growth', accessor: 'growthOtherLiabilities' },
  { Header: 'Total Liabilities Growth', accessor: 'growthTotalLiabilities' },
  { Header: 'Common Stock Growth', accessor: 'growthCommonStock' },
  { Header: 'Retained Earnings Growth', accessor: 'growthRetainedEarnings' },
  {
    Header: 'Accumulated Other Comprehensive Income Loss Growth',
    accessor: 'growthAccumulatedOtherComprehensiveIncomeLoss',
  },
  {
    Header: 'Other Total Stockholders Equity Growth',
    accessor: 'growthOthertotalStockholdersEquity',
  },
  {
    Header: 'Total Stockholders Equity Growth',
    accessor: 'growthTotalStockholdersEquity',
  },
  {
    Header: 'Total Liabilities and Stockholders Equity Growth',
    accessor: 'growthTotalLiabilitiesAndStockholdersEquity',
  },
  { Header: 'Total Investments Growth', accessor: 'growthTotalInvestments' },
  { Header: 'Total Debt Growth', accessor: 'growthTotalDebt' },
  { Header: 'Net Debt Growth', accessor: 'growthNetDebt' },
]

export const cashGrowthKeys = [
  { Header: 'Period', accessor: 'period' },
  { Header: 'Net Income Growth', accessor: 'growthNetIncome' },
  {
    Header: 'Depreciation and Amortization Growth',
    accessor: 'growthDepreciationAndAmortization',
  },
  { Header: 'Deferred Income Tax Growth', accessor: 'growthDeferredIncomeTax' },
  {
    Header: 'Stock-based Compensation Growth',
    accessor: 'growthStockBasedCompensation',
  },
  {
    Header: 'Change in Working Capital Growth',
    accessor: 'growthChangeInWorkingCapital',
  },
  {
    Header: 'Accounts Receivables Growth',
    accessor: 'growthAccountsReceivables',
  },
  { Header: 'Inventory Growth', accessor: 'growthInventory' },
  { Header: 'Accounts Payables Growth', accessor: 'growthAccountsPayables' },
  {
    Header: 'Other Working Capital Growth',
    accessor: 'growthOtherWorkingCapital',
  },
  {
    Header: 'Other Non-Cash Items Growth',
    accessor: 'growthOtherNonCashItems',
  },
  {
    Header: 'Net Cash Provided by Operating Activities Growth',
    accessor: 'growthNetCashProvidedByOperatingActivites',
  },
  {
    Header: 'Investments in Property, Plant, and Equipment Growth',
    accessor: 'growthInvestmentsInPropertyPlantAndEquipment',
  },
  { Header: 'Acquisitions Net Growth', accessor: 'growthAcquisitionsNet' },
  {
    Header: 'Purchases of Investments Growth',
    accessor: 'growthPurchasesOfInvestments',
  },
  {
    Header: 'Sales/Maturities of Investments Growth',
    accessor: 'growthSalesMaturitiesOfInvestments',
  },
  {
    Header: 'Other Investing Activities Growth',
    accessor: 'growthOtherInvestingActivites',
  },
  {
    Header: 'Net Cash Used for Investing Activities Growth',
    accessor: 'growthNetCashUsedForInvestingActivites',
  },
  { Header: 'Debt Repayment Growth', accessor: 'growthDebtRepayment' },
  { Header: 'Common Stock Issued Growth', accessor: 'growthCommonStockIssued' },
  {
    Header: 'Common Stock Repurchased Growth',
    accessor: 'growthCommonStockRepurchased',
  },
  { Header: 'Dividends Paid Growth', accessor: 'growthDividendsPaid' },
  {
    Header: 'Other Financing Activities Growth',
    accessor: 'growthOtherFinancingActivites',
  },
  {
    Header: 'Net Cash Used/Provided by Financing Activities Growth',
    accessor: 'growthNetCashUsedProvidedByFinancingActivities',
  },
  {
    Header: 'Effect of Forex Changes on Cash Growth',
    accessor: 'growthEffectOfForexChangesOnCash',
  },
  { Header: 'Net Change in Cash Growth', accessor: 'growthNetChangeInCash' },
  {
    Header: 'Cash at End of Period Growth',
    accessor: 'growthCashAtEndOfPeriod',
  },
  {
    Header: 'Cash at Beginning of Period Growth',
    accessor: 'growthCashAtBeginningOfPeriod',
  },
  { Header: 'Operating Cash Flow Growth', accessor: 'growthOperatingCashFlow' },
  {
    Header: 'Capital Expenditure Growth',
    accessor: 'growthCapitalExpenditure',
  },
  { Header: 'Free Cash Flow Growth', accessor: 'growthFreeCashFlow' },
]
