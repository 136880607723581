import {
  CartesianGrid,
  YAxis,
  XAxis,
  Tooltip,
  ResponsiveContainer,
  Brush,
  LabelList,
  ComposedChart,
} from 'recharts'
import numeral from 'numeral'
import { COMPARISON_CHART_BASE_PROPS } from '../constants'
import dayjs from 'dayjs'
import { formatBrushTickValue, getViz } from '../utils'
import { renderTooltipContentGeneric } from 'core/Charts/GenericChart'
import { NoData } from 'core/NoData/NoData'

export const ChartWrapper = ({ width, height, chartState }) => {
  const {
    metrics,
    metricsData,
    lineType,
    dataFrequency,
    chartTypes,
    showSingleAxis,
    showLabels,
    showPercentageChange,
  } = chartState

  const CustomLabel = props => {
    const { x, y, value, color } = props
    return (
      <text
        x={x}
        y={y}
        dy={value < 0 ? +18 : -10}
        fill={color}
        fontSize={12}
        textAnchor="middle"
      >
        {numeral(value).format('0.0a')}
      </text>
    )
  }

  const baseProps = COMPARISON_CHART_BASE_PROPS(showPercentageChange)

  return (
    <>
      {!metrics || metrics.length === 0 ? (
        <NoData label={'0 metrics selected. Add metrics to chart.'} />
      ) : metricsData?.length > 0 ? (
        <ResponsiveContainer width={width} height={height}>
          <ComposedChart data={metricsData}>
            <CartesianGrid {...baseProps.cartesianGrid} />

            <Brush
              {...baseProps.brush}
              tickFormatter={tick => formatBrushTickValue(tick, dataFrequency)}
              style={{ fontSize: 13 }}
            />
            <Tooltip
              content={content =>
                renderTooltipContentGeneric(content, false, false, 12)
              }
            />
            {metrics.map((metric, index) => {
              const Viz = getViz(chartTypes[metric.APIProperty]?.value)
              let orientation =
                !showSingleAxis && index % 2 === 0 ? 'left' : 'right'
              return (
                <>
                  {!showSingleAxis && (
                    <YAxis
                      {...baseProps.yAxis}
                      key={`yAxis-${index}`}
                      style={{ fontSize: 13 }}
                      yAxisId={metric.APIProperty + '_axis'}
                      orientation={orientation}
                      axisLine={{
                        stroke: metric.color,
                        strokeWidth: 1,
                      }}
                      width={48}
                    ></YAxis>
                  )}
                  <XAxis
                    dataKey="date"
                    tickFormatter={tick => dayjs(tick).format('MMM YY' + "'")}
                    style={{ fontSize: 13 }}
                  />
                  <Viz
                    key={`viz-${metric.APIProperty}`}
                    dot={false}
                    yAxisId={
                      showSingleAxis
                        ? 'single_axis'
                        : metric.APIProperty + '_axis'
                    }
                    dataKey={metric.APIProperty}
                    type={lineType.value}
                    fill={metric.color}
                    stroke={metric.color}
                    strokeWidth={
                      chartTypes[metric.APIProperty]?.value === 'line' && 2
                    }
                  >
                    {showLabels && (
                      <LabelList
                        dataKey={metric.APIProperty}
                        content={<CustomLabel color={metric.color} />}
                        position="top"
                      />
                    )}
                  </Viz>
                </>
              )
            })}
            {showSingleAxis && (
              <YAxis {...baseProps.yAxis} style={{ fontSize: 13 }} width={40} />
            )}
          </ComposedChart>
        </ResponsiveContainer>
      ) : (
        <NoData label={'No data available for selected metrics.'} />
      )}
    </>
  )
}

export default ChartWrapper
