import {
  FormattedSpanPrecentage,
  FormattedSpan,
} from '../../../core/PositiveNegativeFormattedElements/FormattedSpan'
import numeral from 'numeral'
import _ from 'lodash'
import TooltipTicker from '../../../core/TickerOverlay/TickerOverlay'
import { ExtractValue } from '../../../core/DataFunctions/ExtractValue'

export const MarketMoverColumns = [
  {
    Header: 'Symbol',
    accessor: 'symbol',
    width: 90,
    Cell: Cell => {
      return <TooltipTicker ticker={Cell?.value} />
    },
  },
  {
    Header: 'Price',
    accessor: 'price',
    width: 70,
    Cell: Cell => {
      return (
        <>
          <span>{numeral(Cell?.value).format('0.00')}</span>
        </>
      )
    },
  },
  {
    Header: 'Change',
    accessor: 'change',
    width: 70,
    Cell: Cell => {
      return (
        <>
          <FormattedSpan value={Cell?.value?.toFixed(2)} noSign />
        </>
      )
    },
  },
  {
    Header: '% Chg.',
    accessor: 'changesPercentage',
    width: 80,
    Cell: Cell => {
      return (
        <>
          <FormattedSpan value={Cell?.value?.toFixed(2)} sign={'%'} />
        </>
      )
    },
  },
]

export const RateColumns = [
  {
    Header: 'Rate',
    accessor: 'rate',
    width: 60,
    Cell: Cell => {
      const words = _.startCase(Cell?.value).split(' ').reverse().join(' ')
      return (
        <>
          <span>{words}</span>
        </>
      )
    },
  },

  {
    Header: 'Price',
    accessor: 'value',
    width: 60,
  },
]

export const forexColumns = [
  {
    Header: 'Symbol',
    accessor: 'ticker',
    minWidth: 70,
    width: 110,
  },
  {
    Header: 'Rate',
    accessor: 'ask',
    minWidth: 70,
    width: 80,
    Cell: Cell => {
      return (
        <>
          <span>{numeral(Cell?.value).format('0.000 a')}</span>
        </>
      )
    },
  },
  {
    Header: 'High',
    accessor: 'high',
    minWidth: 70,
    width: 80,
    maxWidth: 100,
    Cell: Cell => {
      return (
        <>
          <span>{numeral(Cell?.value).format('0.000 a')}</span>
        </>
      )
    },
  },
  {
    Header: 'Low',
    accessor: 'low',
    minWidth: 70,
    width: 80,
    maxWidth: 100,
    Cell: Cell => {
      return (
        <>
          <span>{numeral(Cell?.value).format('0.000 a ')}</span>
        </>
      )
    },
  },
  {
    Header: 'Chg.',
    accessor: 'changes',
    minWidth: 50,
    width: 60,
    Cell: Cell => {
      return (
        <>
          <FormattedSpan value={Cell?.value?.toFixed(2)} noSign />
        </>
      )
    },
  },
]

export const CommodityColumns = [
  {
    Header: 'Commodity',
    accessor: 'name',
    width: 260,
    Cell: Cell => {
      return (
        <>
          <span>{Cell?.value}</span>
        </>
      )
    },
  },
  {
    Header: 'Price',
    accessor: 'price',
    width: 100,
    Cell: Cell => {
      return (
        <>
          <span>{numeral(Cell?.value).format('0.00')}</span>
        </>
      )
    },
  },
  {
    Header: 'Change',
    accessor: 'change',
    width: 100,
    Cell: Cell => {
      const changePercent = Cell?.row?.original?.changesPercentage
      return (
        <>
          <FormattedSpanPrecentage
            value={Cell?.value?.toFixed(2)}
            changesPercentage={changePercent?.toFixed(2)}
            sign={'%'}
          />
        </>
      )
    },
  },
  {
    Header: 'Symbol',
    accessor: 'symbol',
    width: 80,
    Cell: Cell => {
      return <TooltipTicker ticker={Cell?.value} />
    },
  },
]

export const SectorColumns = [
  {
    Header: 'Sector ETF',
    accessor: 'name',
    width: 300,
    Cell: Cell => {
      const words = ['Sector', 'Fund', 'Select', 'SPDR', 'The']
      return (
        <>
          <span>{ExtractValue(Cell?.value, words)}</span>
        </>
      )
    },
  },
  {
    Header: 'Price',
    accessor: 'price',
    width: 100,
    Cell: Cell => {
      return (
        <>
          <span>{numeral(Cell?.value).format('0.00')}</span>
        </>
      )
    },
  },
  {
    Header: 'Change',
    accessor: 'change',
    width: 100,
    Cell: Cell => {
      const changePercent = Cell?.row?.original?.changesPercentage
      return (
        <>
          <FormattedSpanPrecentage
            value={Cell?.value?.toFixed(2)}
            changesPercentage={changePercent?.toFixed(2)}
            sign={'%'}
          />
        </>
      )
    },
  },
  {
    Header: 'Symbol',
    accessor: 'symbol',
    width: 80,
    Cell: Cell => {
      return <TooltipTicker ticker={Cell?.value} />
    },
  },
]

export const CountryColumns = [
  {
    Header: 'Country ETF',
    accessor: 'name',
    width: 260,
    Cell: Cell => {
      const ReplaceWords = ['ETF', 'iShares', 'MSCI']
      return (
        <>
          <span>{ExtractValue(Cell?.value, ReplaceWords)}</span>
        </>
      )
    },
  },
  {
    Header: 'Price',
    accessor: 'price',
    width: 100,
    Cell: Cell => {
      return (
        <>
          <span>{numeral(Cell?.value).format('0.00')}</span>
        </>
      )
    },
  },
  {
    Header: 'Change',
    accessor: 'change',
    width: 100,
    Cell: Cell => {
      const changePercent = Cell?.row?.original?.changesPercentage
      return (
        <>
          <FormattedSpanPrecentage
            value={Cell?.value?.toFixed(2)}
            changesPercentage={changePercent?.toFixed(2)}
            sign={'%'}
          />
        </>
      )
    },
  },
  {
    Header: 'Symbol',
    accessor: 'symbol',
    width: 80,
    Cell: Cell => {
      return <TooltipTicker ticker={Cell?.value} />
    },
  },
]

export const CorpBondsColumns = [
  {
    Header: 'Corporate Bonds ETF',
    accessor: 'name',
    width: 260,
    Cell: Cell => {
      const words = [
        'iBoxx',
        '$',
        'iShares',
        'MSCI',
        'Vanguard',
        // 'PIMCO',
        'SPDR',
        'The',
        'Invesco',
        'Index',
        'ETF',
        'Investment',
        'Grade',
        'Fund',
        'Exchange-Traded',
      ]
      return (
        <>
          <span>{ExtractValue(Cell?.value, words)}</span>
        </>
      )
    },
  },
  {
    Header: 'Price',
    accessor: 'price',
    width: 100,
    Cell: Cell => {
      return (
        <>
          <span>{numeral(Cell?.value).format('0.00')}</span>
        </>
      )
    },
  },
  {
    Header: 'Change',
    accessor: 'change',
    width: 100,
    Cell: Cell => {
      const changePercent = Cell?.row?.original?.changesPercentage
      return (
        <>
          <FormattedSpanPrecentage
            value={Cell?.value?.toFixed(2)}
            changesPercentage={changePercent?.toFixed(2)}
            sign={'%'}
          />
        </>
      )
    },
  },
  {
    Header: 'Symbol',
    accessor: 'symbol',
    width: 80,
    Cell: Cell => {
      return <TooltipTicker ticker={Cell?.value} />
    },
  },
]

export const GovBondsColumns = [
  {
    Header: 'Government Bonds ETF',
    accessor: 'name',
    width: 260,
    Cell: Cell => {
      const words = [
        'iBoxx',
        '$',
        'iShares',
        'MSCI',
        'Vanguard',
        'PIMCO',
        'SPDR',
        'The',
        'Invesco',
        'amental',
        'Index',
        'Schwab',
        'Fund',
        'Core',
        'ETF',
      ]
      return (
        <>
          <span>{ExtractValue(Cell?.value, words)}</span>
        </>
      )
    },
  },
  {
    Header: 'Price',
    accessor: 'price',
    width: 100,
    Cell: Cell => {
      return (
        <>
          <span>{numeral(Cell?.value).format('0.00')}</span>
        </>
      )
    },
  },
  {
    Header: 'Change',
    accessor: 'change',
    width: 100,
    Cell: Cell => {
      const changePercent = Cell?.row?.original?.changesPercentage
      return (
        <>
          <FormattedSpanPrecentage
            value={Cell?.value?.toFixed(2)}
            changesPercentage={changePercent?.toFixed(2)}
            sign={'%'}
          />
        </>
      )
    },
  },
  {
    Header: 'Symbol',
    accessor: 'symbol',
    width: 80,
  },
]

export const GrowthColumns = [
  {
    Header: 'Growth ETF',
    accessor: 'name',
    width: 260,
    Cell: Cell => {
      const words = [
        // 'iBoxx',
        // '$',
        // 'iShares',
        // 'MSCI',
        // 'Vanguard',
        // 'PIMCO',
        // 'SPDR',
        'The',
        // 'Invesco',
        'amental',
        'Index',
        // 'Schwab',
        'Fund',
        // 'Core',
        'AlphaDEX',
        'ETF',
      ]
      return (
        <>
          <span>{ExtractValue(Cell?.value, words)}</span>
        </>
      )
    },
  },
  {
    Header: 'Price',
    accessor: 'price',
    width: 100,
    Cell: Cell => {
      return (
        <>
          <span>{numeral(Cell?.value).format('0.00')}</span>
        </>
      )
    },
  },
  {
    Header: 'Change',
    accessor: 'change',
    width: 100,
    Cell: Cell => {
      const changePercent = Cell?.row?.original?.changesPercentage
      return (
        <>
          <FormattedSpanPrecentage
            value={Cell?.value?.toFixed(2)}
            changesPercentage={changePercent?.toFixed(2)}
            sign={'%'}
          />
        </>
      )
    },
  },
  {
    Header: 'Symbol',
    accessor: 'symbol',
    width: 80,
    Cell: Cell => {
      return <TooltipTicker ticker={Cell?.value} />
    },
  },
]

export const ValueColumns = [
  {
    Header: 'Value ETF',
    accessor: 'name',
    width: 260,
    Cell: Cell => {
      const words = [
        // 'iBoxx',
        // '$',
        // 'iShares',
        // 'MSCI',
        // 'Vanguard',
        // 'PIMCO',
        // 'SPDR',
        'The',
        // 'Invesco',
        'amental',
        'Index',
        // 'Schwab',
        'Fund',
        // 'Core',
        'AlphaDEX',
        'ETF',
      ]
      return (
        <>
          <span>{ExtractValue(Cell?.value, words)}</span>
        </>
      )
    },
  },
  {
    Header: 'Price',
    accessor: 'price',
    width: 100,
    Cell: Cell => {
      return (
        <>
          <span>{numeral(Cell?.value).format('0.00')}</span>
        </>
      )
    },
  },
  {
    Header: 'Change',
    accessor: 'change',
    width: 100,
    Cell: Cell => {
      const changePercent = Cell?.row?.original?.changesPercentage
      return (
        <>
          <FormattedSpanPrecentage
            value={Cell?.value?.toFixed(2)}
            changesPercentage={changePercent?.toFixed(2)}
            sign={'%'}
          />
        </>
      )
    },
  },
  {
    Header: 'Symbol',
    accessor: 'symbol',
    width: 80,
    Cell: Cell => {
      return <TooltipTicker ticker={Cell?.value} />
    },
  },
]

export const SmallColumns = [
  {
    Header: 'Small Cap ETF',
    accessor: 'name',
    width: 260,
    Cell: Cell => {
      const words = [
        'Index',
        'Fund',
        'ETF',
        'Trust',
        'Fund',
        'Invesco',
        'iShares',
      ]
      return (
        <>
          <span>{ExtractValue(Cell?.value, words)}</span>
        </>
      )
    },
  },
  {
    Header: 'Price',
    accessor: 'price',
    width: 100,
    Cell: Cell => {
      return (
        <>
          <span>{numeral(Cell?.value).format('0.00')}</span>
        </>
      )
    },
  },
  {
    Header: 'Change',
    accessor: 'change',
    width: 100,
    Cell: Cell => {
      const changePercent = Cell?.row?.original?.changesPercentage
      return (
        <>
          <FormattedSpanPrecentage
            value={Cell?.value?.toFixed(2)}
            changesPercentage={changePercent?.toFixed(2)}
            sign={'%'}
          />
        </>
      )
    },
  },
  {
    Header: 'Symbol',
    accessor: 'symbol',
    width: 80,
    Cell: Cell => {
      return <TooltipTicker ticker={Cell?.value} />
    },
  },
]

export const MidColumns = [
  {
    Header: 'Mid Cap ETF',
    accessor: 'name',
    width: 260,
    Cell: Cell => {
      const words = [
        'Index',
        'Fund',
        'ETF',
        'Trust',
        'Fund',
        'Invesco',
        'iShares',
      ]
      return (
        <>
          <span>{ExtractValue(Cell?.value, words)}</span>
        </>
      )
    },
  },
  {
    Header: 'Price',
    accessor: 'price',
    width: 100,
    Cell: Cell => {
      return (
        <>
          <span>{numeral(Cell?.value).format('0.00')}</span>
        </>
      )
    },
  },
  {
    Header: 'Change',
    accessor: 'change',
    width: 100,
    Cell: Cell => {
      const changePercent = Cell?.row?.original?.changesPercentage
      return (
        <>
          <FormattedSpanPrecentage
            value={Cell?.value?.toFixed(2)}
            changesPercentage={changePercent?.toFixed(2)}
            sign={'%'}
          />
        </>
      )
    },
  },
  {
    Header: 'Symbol',
    accessor: 'symbol',
    width: 80,
    Cell: Cell => {
      return <TooltipTicker ticker={Cell?.value} />
    },
  },
]

export const LargeColumns = [
  {
    Header: 'Large Cap ETF',
    accessor: 'name',
    width: 260,
    Cell: Cell => {
      const words = [
        'Index',
        'Fund',
        'ETF',
        'Trust',
        'Fund',
        'Invesco',
        'iShares',
      ]
      return (
        <>
          <span>{ExtractValue(Cell?.value, words)}</span>
        </>
      )
    },
  },
  {
    Header: 'Price',
    accessor: 'price',
    width: 100,
    Cell: Cell => {
      return (
        <>
          <span>{numeral(Cell?.value).format('0.00')}</span>
        </>
      )
    },
  },
  {
    Header: 'Change',
    accessor: 'change',
    width: 100,
    Cell: Cell => {
      const changePercent = Cell?.row?.original?.changesPercentage
      return (
        <>
          <FormattedSpanPrecentage
            value={Cell?.value?.toFixed(2)}
            changesPercentage={changePercent?.toFixed(2)}
            sign={'%'}
          />
        </>
      )
    },
  },
  {
    Header: 'Symbol',
    accessor: 'symbol',
    width: 80,
    Cell: Cell => {
      return <TooltipTicker ticker={Cell?.value} />
    },
  },
]

export const IndexColumns = [
  {
    Header: 'Index',
    accessor: 'name',
    width: 260,
    Cell: Cell => {
      const words = []
      return (
        <>
          <span>{ExtractValue(Cell?.value, words)}</span>
        </>
      )
    },
  },
  {
    Header: 'Price',
    accessor: 'price',
    width: 110,
    Cell: Cell => {
      return (
        <>
          <span>{numeral(Cell?.value).format('0,00.0 ')}</span>
        </>
      )
    },
  },
  {
    Header: 'Change',
    accessor: 'change',
    width: 130,
    Cell: Cell => {
      const changePercent = Cell?.row?.original?.changesPercentage
      return (
        <>
          <FormattedSpanPrecentage
            value={Cell?.value?.toFixed(2)}
            changesPercentage={changePercent?.toFixed(2)}
            sign={'%'}
          />
        </>
      )
    },
  },
  {
    Header: 'Symbol',
    accessor: 'symbol',
    width: 80,
    Cell: Cell => {
      const words = ['^']
      return (
        <>
          <span>{ExtractValue(Cell?.value, words)}</span>
        </>
      )
    },
  },
]
