import { useState, useEffect, useRef, useMemo } from 'react'
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
} from 'react-simple-maps'
import { Marker } from 'react-simple-maps'
import { geoMercator } from 'd3-geo'
import * as d3 from 'd3'
import CoreButton from 'core/Button/CoreButton'
import geoJsonData from '../../map.geoJson'
import {
  UilPlusCircle,
  UilMinusCircle,
  UilRedo,
} from '@iconscout/react-unicons'
import styles from './styles.module.scss'
import numeral from 'numeral'

export const colorScalePositive = d3
  .scaleLinear()
  .domain([0, 2])
  .range(['#40954b', '#094519'])

export const colorScaleNegative = d3
  .scaleLinear()
  .domain([-2, 0])
  .range(['#731e0f', '#e04b46'])

export const Map = ({
  data,
  handleZoomIn,
  handleZoomOut,
  handleResetZoom,
  position,
  handleMoveEnd,
  zoomOnCoordinates,
  metrics,
}) => {
  const tooltip = useRef(null)
  const [tooltipContent, setTooltipContent] = useState('')
  const [tooltipVisibility, setTooltipVisibility] = useState(false)
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 })

  const markersRef = useRef([])
  markersRef.current = []

  const addToRefs = el => {
    if (el && !markersRef.current.includes(el)) {
      markersRef.current.push(el)
    }
  }

  const onMarkerEnter = markerIndex => {
    const markerDOMItem = markersRef.current[markerIndex]
    const { top, left, width, height } = markerDOMItem.getBoundingClientRect()

    setTooltipPosition({
      x: left + width / 2,
      y: top - height,
    })

    setTooltipVisibility(true)
  }

  const getCircleSize = () => {
    if (position.zoom < 2) {
      return 5
    } else if (position.zoom < 3) {
      return 3
    } else if (position.zoom < 4) {
      return 2
    }
    return 1
  }

  const getRadius = () => {
    if (position.zoom > 4) {
      return 0.3
    } else {
      return 0.5
    }
  }

  const dataWithSwappedCords = data?.map(d => {
    return { ...d, coordinates: [d?.lng, d?.lat] }
  })

  const customProjection = geoMercator()
    .scale(108)
    .translate([800 / 2, 600 / 2 + 20])
    .rotate([0, 0, 0])

  const chooseColorScale = value => {
    if (value > 2) {
      return 'var(--green)'
    } else if (value < -2) {
      return 'var(--red)'
    } else {
      return value > 0 ? colorScalePositive(value) : colorScaleNegative(value)
    }
  }

  const markers = useMemo(
    () =>
      data?.map(d => ({
        ...d,
        coordinates: [d.lng, d.lat],
      })),
    [data]
  )

  const markerIsFocused = useMemo(
    () =>
      markers?.find(
        marker =>
          marker.coordinates[0] === position.coordinates[0] &&
          marker.coordinates[1] === position.coordinates[1]
      ),
    [markers, position.coordinates]
  )

  useEffect(() => {
    if (markerIsFocused) {
      const tooltipContent = (
        <div>
          Name: {markerIsFocused.name}
          <br />
          Symbol: {markerIsFocused.type}
          <br />
          Change:{' '}
          <span
            style={{
              color: markerIsFocused.change > 0 ? 'var(--green)' : 'var(--red)',
            }}
          >
            {numeral(markerIsFocused.change).format('0.00')}%
          </span>
          <br />
          Price: {numeral(markerIsFocused.price).format('0,0.00')}
          <br />
          Country: {markerIsFocused.country}
        </div>
      )

      setTooltipContent(tooltipContent)
      setTooltipVisibility(true)
    } else {
      setTooltipVisibility(false)
    }
  }, [markerIsFocused])

  const utilsButtons = [
    {
      icon: <UilPlusCircle size={20} />,
      onClick: handleZoomIn,
    },
    {
      icon: <UilMinusCircle size={20} />,
      onClick: handleZoomOut,
    },
    {
      icon: <UilRedo size={20} />,
      onClick: handleResetZoom,
    },
  ]

  const [tooltipDimensions, setTooltipDimensions] = useState({
    width: 0,
    height: 0,
  })

  useEffect(() => {
    if (tooltipVisibility && tooltip.current) {
      const { width, height } = tooltip.current.getBoundingClientRect()
      setTooltipDimensions({ width, height })
    }
  }, [tooltipVisibility])

  const adjustedTooltipPosition = {
    x: tooltipPosition.x - tooltipDimensions.width / 2,
    y: tooltipPosition.y - tooltipDimensions.height,
  }

  const metricIsInMetrics = metricSymbol => {
    return metrics.some(metric => metric.key === metricSymbol)
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          gap: '6px',
          margin: '4px',
          position: 'absolute',
          zIndex: 10000000,
        }}
      >
        {utilsButtons.map((button, index) => (
          <CoreButton
            key={index}
            onClick={button.onClick}
            color="var(--grey-accent)"
          >
            {button.icon}
          </CoreButton>
        ))}
      </div>
      <ComposableMap projection={customProjection}>
        <ZoomableGroup
          zoom={position.zoom}
          center={position.coordinates}
          onMoveEnd={handleMoveEnd}
        >
          <Geographies geography={geoJsonData}>
            {({ geographies }) =>
              geographies.map(geo => {
                const countryData = dataWithSwappedCords?.find(
                  s => s.country === geo.properties.name
                )
                const fillColor = countryData
                  ? chooseColorScale(countryData?.change)
                  : 'var(--background-tertiary)'
                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    style={{
                      default: {
                        fill: fillColor,
                        outline: 'none',
                      },
                      hover: {
                        fill: fillColor,
                        outline: 'none',
                        opacity: 0.7,
                        transition: 'opacity 0.2s',
                      },
                      pressed: {
                        fill: fillColor,
                        outline: 'none',
                      },
                    }}
                    stroke="var(--background-secondary)"
                    strokeWidth={1}
                  />
                )
              })
            }
          </Geographies>
          {markers?.map(
            ({ name, type, coordinates, change, price, country }, index) => {
              const isFocused =
                position?.coordinates?.[0] === coordinates?.[0] &&
                position?.coordinates?.[1] === coordinates?.[1]

              const tooltipContent = (
                <div>
                  Name: {name}
                  <br />
                  Symbol: {type}
                  <br />
                  Change:{' '}
                  <span
                    style={{
                      color: change > 0 ? 'var(--green)' : 'var(--red)',
                    }}
                  >
                    {numeral(change).format('0.00')}%
                  </span>
                  <br />
                  Price: {numeral(price).format('0,0.00')}
                  <br />
                  Country: {country}
                </div>
              )
              return (
                <Marker
                  key={name}
                  coordinates={coordinates}
                  ref={addToRefs}
                  onClick={() => zoomOnCoordinates(coordinates)}
                  onMouseEnter={() => {
                    onMarkerEnter(index)
                    setTooltipContent(tooltipContent)
                  }}
                  onMouseLeave={() => {
                    setTooltipVisibility(false)
                  }}
                  style={{ cursor: 'pointer', zIndex: 10000000 }}
                >
                  <circle
                    r={getCircleSize()}
                    fill="rgba(0, 0, 0, 0.1)"
                    className={styles.pulsingCircle}
                  />
                  <circle
                    r={isFocused ? 2 : getCircleSize()}
                    fill={
                      metricIsInMetrics(type)
                        ? metrics?.find(metric => metric?.key === type)?.color
                        : 'var(--primary-color)'
                    }
                    stroke={
                      isFocused ? 'var(--white)' : 'var(--light-grey-accent)'
                    }
                    strokeWidth={getRadius()}
                  />
                </Marker>
              )
            }
          )}
        </ZoomableGroup>
      </ComposableMap>
      {tooltipVisibility && (
        <div
          ref={tooltip}
          className={styles.tooltip}
          style={{
            left: `${adjustedTooltipPosition.x}px`,
            top: `${adjustedTooltipPosition.y}px`,
          }}
        >
          {tooltipContent}
        </div>
      )}
    </>
  )
}
