import { calculateGrowth, combineData } from './utils'

export const profitChecks = [
  { type: 'grossProfitMarginTTM', criteria: 'over', threshold: 0.4 },
  { type: 'netProfitMarginTTM', criteria: 'over', threshold: 0.1 },
  { type: 'returnOnAssetsTTM', criteria: 'under', threshold: 0.08 },
  { type: 'returnOnEquityTTM', criteria: 'over', threshold: 0.1 },
  { type: 'returnOnCapitalEmployedTTM', criteria: 'over', threshold: 0.1 },
]

export const efficiencyChecks = [
  { type: 'daysOfSalesOutstandingTTM', criteria: 'under', threshold: 60 },
  { type: 'operatingCycleTTM', criteria: 'under', threshold: 90 },
  { type: 'receivablesTurnoverTTM', criteria: 'over', threshold: 6 },
  { type: 'assetTurnoverTTM', criteria: 'over', threshold: 1.2 },
  { type: 'inventoryTurnoverTTM', criteria: 'over', threshold: 6 },
]

export const growthChecks = [
  {
    type: 'revenueGrowth',
    criteria: 'over',
    threshold: 0.15,
  },
  {
    type: 'netIncomeGrowth',
    criteria: 'over',
    threshold: 0.15,
  },
  {
    type: 'freeCashFlowGrowth',
    criteria: 'over',
    threshold: 0.1,
  },
  {
    type: 'totalDebtGrowth',
    criteria: 'under',
    threshold: 0,
  },
  {
    type: 'ebitdaGrowth',
    criteria: 'over',
    threshold: 0.1,
  },
]

export const healthChecks = [
  { type: 'currentRatioTTM', criteria: 'over', threshold: 1.5 },
  { type: 'cashRatioTTM', criteria: 'over', threshold: 0.5 },
  { type: 'debtEquityRatioTTM', criteria: 'under', threshold: 1 },
  { type: 'totalDebtToCapitalizationTTM', criteria: 'under', threshold: 0.5 },
  { type: 'interestCoverageTTM', criteria: 'over', threshold: 1.5 },
]

export const valuationChecks = [
  {
    type: 'dcf',
    criteria: 'over',
    compare: 'price',
  },
  {
    type: 'priceEarningsRatioTTM',
    analytics: true,
    criteria: 'under',
    stopLoss: 0,
  },
  {
    type: 'priceToBookRatioTTM',
    analytics: true,
    criteria: 'under',
    stopLoss: 0,
  },
  {
    type: 'priceToSalesRatioTTM',
    criteria: 'range',
    threshold: [0, 3],
  },
  { type: 'pegTTM', criteria: 'range', threshold: [0, 1] },
]

export const calculateScore = (data, checks, analyticsData) => {
  let score = 0
  let checkResults = []

  checks?.forEach(check => {
    const metricType = check?.type
    const metricValue = data?.[metricType]
    let compareValue
    let analyticsMetricValue
    let passed = false

    // analytics data
    if (check.analytics) {
      const analyticsMetricType = metricType?.replace('TTM', '')?.trim()
      analyticsMetricValue = analyticsData?.[analyticsMetricType]?.AVG
    }

    // custom comparison
    if (check?.compare) {
      compareValue = data?.[check?.compare]
    }

    if (
      metricValue !== undefined &&
      (!check.analytics || analyticsMetricValue !== undefined)
    ) {
      switch (check?.criteria) {
        case 'over':
          if (check?.analytics) {
            passed = metricValue > analyticsMetricValue
          } else if (check?.compare) {
            passed = metricValue > compareValue
          } else {
            passed = metricValue > check?.threshold
          }
          break
        case 'under':
          if (check?.analytics) {
            if (check?.stopLoss) {
              passed =
                metricValue < analyticsMetricValue &&
                analyticsMetricValue > check?.stopLoss
            } else {
              passed = metricValue < analyticsMetricValue
            }
          } else if (check?.compare) {
            passed = metricValue < compareValue
          } else {
            passed = metricValue < check?.threshold
          }
          break
        case 'range':
          passed =
            metricValue >= check?.threshold?.[0] &&
            metricValue <= check?.threshold?.[1]
          break
        default:
          // nothing
          break
      }
    }

    if (passed) score++

    checkResults.push({ ...check, passed })
  })

  return { score, checkResults }
}

export const dataFunction = (data, ratioSummary) => {
  const ratioData = data?.companyData?.ratios?.[0]

  const profileData = data?.companyData?.profile

  const incomeData = data?.companyData?.financialsAnnual?.income
  const balanceData = data?.companyData?.financialsAnnual?.balance
  const cashData = data?.companyData?.financialsAnnual?.cash

  const growthI = calculateGrowth(incomeData, 4, true)
  const growthB = calculateGrowth(balanceData, 4, true)

  const growthC = calculateGrowth(cashData, 4, true)

  const scoreData = combineData(
    ratioData,
    profileData,
    growthI?.[0]?.growth,
    growthB?.[0]?.growth,
    growthC?.[0]?.growth
  )

  const { score: profitScore, checkResults: profitCheckResults } =
    calculateScore(scoreData, profitChecks)
  const { score: healthScore, checkResults: healthCheckResults } =
    calculateScore(scoreData, healthChecks)
  const { score: efficiencyScore, checkResults: efficiencyCheckResults } =
    calculateScore(scoreData, efficiencyChecks)
  const { score: growthScore, checkResults: growthCheckResults } =
    calculateScore(scoreData, growthChecks)
  const { score: valuationScore, checkResults: valuationCheckResults } =
    calculateScore(scoreData, valuationChecks, ratioSummary?.[0])

  const scores = [
    {
      name: 'Valuation',
      score: valuationScore,
    },
    {
      name: 'Profitability',
      score: profitScore,
    },
    {
      name: 'Efficiency',
      score: efficiencyScore,
    },
    {
      name: 'Growth',
      score: growthScore,
    },
    {
      name: 'Health',
      score: healthScore,
    },
  ]

  const checkResults = {
    valuation: valuationCheckResults,
    profitability: profitCheckResults,
    efficiency: efficiencyCheckResults,
    growth: growthCheckResults,
    health: healthCheckResults,
  }

  return { scores, checkResults }
}

export const checks = {
  profitChecks,
  efficiencyChecks,
  growthChecks,
  healthChecks,
  valuationChecks,
}
