import React, { useState } from 'react'
import { PricingTable } from '../PriceTable/PricingTable'
import styles from './PricingView.module.scss'
import { PricingBreakdown } from '../PricingBreakdown/PricingBreakdown'
import { Footer } from 'pages/HomePage/HomePageComponents/Footer/HomePageFooter'
import { NavBar } from 'pages/HomePage/HomePageComponents/NavBar/NavBar'
import { Helmet } from 'react-helmet-async'

export const PricingView = ({ jwt }) => {
  const [isYearly, setIsYearly] = useState(true)

  return (
    <div className={styles.pricingView}>
      <Helmet>
        <title>{`Outseek | Pricing`}</title>
        <meta
          name="description"
          content={
            "Most affordable option on the market. Outseek's pricing is designed to enable all investors with institutional grade investment data."
          }
        />
      </Helmet>
      <NavBar />
      <div className={styles.pricingContent}>
        <PricingHeader />
        <PricingTable jwt={jwt} isYearly={isYearly} setIsYearly={setIsYearly} />
        <div className={styles.pricingTitle}>Detailed Comparison of Plans</div>
        <div className={styles.breakdownWrapper}>
          <PricingBreakdown isYearly={isYearly} setIsYearly={setIsYearly} />
        </div>
      </div>
      <Footer />
    </div>
  )
}

export const PricingHeader = () => {
  return (
    <div className={styles.pricingHeader}>
      <div className={styles.pricingTitle}>
        Price is what you pay.
        <br />
        Value is what you get.
        <br />
      </div>
      <span className={styles.quote}>- Warren Buffett</span>
    </div>
  )
}
