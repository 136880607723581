//table
export const INCOME_STATEMENT = "Income Statement";
export const BALANCE_SHEET = "Balance Sheet";
export const CASH_FLOW_STATEMENT = "Cash Flow Statement";
export const COMPANY_GROWTH_TABLE = "Financial Growth";
export const COMPANY_RATIOS_TABLE = "Financial Ratios";
export const RSS_SEC_FEED = "RSS SEC Feed";
export const INSIDER_TRADING_RSS_SEC_FEED = "Insider trading RSS SEC Feed";
export const EIGHT_K_RSS_FEED = "8K RSS Feed";
export const SOCIAL_SENTIMENT_FEED = "Social Sentiment Feed"
export const ANNUAL_PERIOD = "annual";
export const QUARTERLY_PERIOD = "quarter";

export const numeralExclude = [
  "period",
  "date",
  "symbol",
  "cik",
  "fillingDate",
  "acceptedDate",
  "calendarYear",
];
