// ForgotPassword.js
import React, { useContext } from 'react'
import { AuthContext } from '../AuthContext'
import styles from '../Auth.module.scss'

const ForgotPassword = () => {
  const {
    setResetCode,
    submitNewPassword,
    setUsername,
    forgotPassword,
    resetPassword,
    setResetPassword,
    setForgetPassword,
    setPassword,
    username,
    forgotPasswordError,
    setForgotPasswordError,
    resetPasswordError,
    setResetPasswordError,
  } = useContext(AuthContext)

  return (
    <>
      {!resetPassword ? (
        <>
          <div className={styles.authHeader}>Reset Password</div>
          <input
            className={styles.authInput}
            placeholder="Email"
            onChange={e => {
              setUsername(e.target.value)
              setForgotPasswordError('')
            }}
            autocomplete="off"
            style={{
              border: forgotPasswordError ? '2px solid var(--red)' : null,
            }}
          />
          {forgotPasswordError && (
            <div className={styles.authError}>{forgotPasswordError}</div>
          )}
          <button className={styles.authConfirmButton} onClick={forgotPassword}>
            Send Code
          </button>
          <div
            className={styles.forgotPassword}
            onClick={() => setForgetPassword(false)}
          >
            Cancel
          </div>
        </>
      ) : (
        <>
          <div className={styles.authHeader}>
            We sent a code to {username}'s email address.
          </div>
          <input
            className={styles.authInput}
            placeholder="Reset Code"
            onChange={e => {
              setResetCode(e.target.value)
              setResetPasswordError('')
            }}
            autocomplete="off"
            style={{
              border: resetPasswordError ? '2px solid var(--red)' : null,
            }}
          />
          <input
            className={styles.authInput}
            placeholder="New Password"
            onChange={e => {
              setPassword(e.target.value)
              setResetPasswordError('')
            }}
            autocomplete="off"
            style={{
              border: resetPasswordError ? '2px solid var(--red)' : null,
            }}
          />
          {resetPasswordError && (
            <div className={styles.authError}>{resetPasswordError}</div>
          )}
          <button
            className={styles.authConfirmButton}
            onClick={submitNewPassword}
          >
            Submit new password
          </button>
          <div
            className={styles.forgotPassword}
            onClick={() => setResetPassword(false)}
          >
            Cancel
          </div>
        </>
      )}
    </>
  )
}

export default ForgotPassword
