import moment from 'moment'
// import { subtractWeekdays } from 'pages/views/CompanyOverview/utils'

export const IndexIntervals = [
  //   {
  //     label: '1D',
  //     from: subtractWeekdays(moment().startOf('day').toDate(), 1),
  //     to: moment().startOf('day').toDate(),
  //   },
  //   {
  //     label: '5D',
  //     from: subtractWeekdays(moment().startOf('day').toDate(), 5),
  //     to: moment().startOf('day').toDate(),
  //   },
  {
    label: '1M',
    from: moment().startOf('day').subtract(1, 'months').toDate(),
    to: moment().startOf('day').toDate(),
  },
  {
    label: '3M',
    from: moment().startOf('day').subtract(3, 'months').toDate(),
    to: moment().startOf('day').toDate(),
  },
  {
    label: '6M',
    from: moment().startOf('day').subtract(6, 'months').toDate(),
    to: moment().startOf('day').toDate(),
  },
  {
    label: 'YTD',
    from: moment().startOf('year').toDate(),
    to: moment().startOf('day').toDate(),
  },
  {
    label: '1Y',
    from: moment().startOf('day').subtract(1, 'years').toDate(),
    to: moment().startOf('day').toDate(),
  },
  {
    label: '2Y',
    from: moment().startOf('day').subtract(2, 'years').toDate(),
    to: moment().startOf('day').toDate(),
  },
  {
    label: '3Y',
    from: moment().startOf('day').subtract(3, 'years').toDate(),
    to: moment().startOf('day').toDate(),
  },
  {
    label: '5Y',
    from: moment().startOf('day').subtract(5, 'years').toDate(),
    to: moment().startOf('day').toDate(),
  },
  {
    label: '10Y',
    from: moment().startOf('day').subtract(10, 'years').toDate(),
    to: moment().startOf('day').toDate(),
  },
]
