export const grabSpeakers = text => {
  var delimiter = '\n'
  const testSplit = text.toString().split(delimiter)
  let speakers = []
  testSplit.forEach(entry => speakers.push(entry.split(':')[0]))

  return speakers
}

export const grabParagraphs = text => {
  var delimiter = '\n'
  const testSplit = text.toString().split(delimiter)
  let paragraphs = []

  testSplit.forEach(entry => {
    const paragraph = entry.split(':')[1]
    if (paragraph) {
      const wordCount = paragraph.trim().split(/\s+/).length
      paragraphs.push({ text: paragraph, wordCount })
    }
  })

  return paragraphs
}

export const grabSpeakersWithWordCount = text => {
  var delimiter = '\n'
  const testSplit = text.toString().split(delimiter)
  let speakers = []
  let wordCount = 0

  testSplit.forEach(entry => {
    let splitEntry = entry.split(':')
    if (splitEntry.length > 1) {
      let speakerWordCount = splitEntry[0].split(' ').length
      speakers.push({ speaker: splitEntry[0], wordCountBefore: wordCount })
      wordCount += splitEntry[1].trim().split(' ').length - speakerWordCount
    }
  })

  return speakers
}
