import React from 'react'
import { Tooltip } from '@mui/material'
import styles from './Tooltip.module.scss'
import Spinner from '../ScrollableContainer/Spinner/spinner.jsx'
import hooks from './hooks'
import numeral from 'numeral'
import { TickerOverlayChart } from './TickerOverlayChart.js'
import { TooltipTable } from './TooltipTable.js'
import { useHandlePushCompanyView } from 'recoil/pushView/pushView'
import { useNavigate } from 'react-router'

const CustomTooltipContent = ({ ticker }) => {
  const { data, isLoading: isChartDataLoading } = hooks.useChartData(ticker)
  const { data: companyData, isLoading: isCompanyDataLoading } =
    hooks.useCompanyData(ticker)
  const { data: stockPrice, isLoading: isStockPriceLoading } =
    hooks.useStockPrice(ticker)
  // const { data: flagImageUrl, isLoading: isFlagLoading } = hooks.useFlagImage(
  //   companyData?.profile?.country
  // )

  const getColor = value => {
    return value < 0 ? 'var(--red)' : 'var(--green)'
  }

  const handleImageError = e => {
    e.target.onerror = null
    e.target.style.display = 'none'
  }

  return (
    <div>
      {isChartDataLoading || isCompanyDataLoading || isStockPriceLoading ? (
        <div className={styles.spinnerWrapper}>
          <Spinner />
        </div>
      ) : (
        <>
          <div className={styles.tooltip}>
            <div className={styles.NamePriceFlagWrapper}>
              <div className={styles.NameFlagWrapper}>
                <div className={styles.NameWrapper}>
                  {companyData?.profile?.defaultImage ? null : (
                    <img
                      className={styles.CompanyLogo}
                      src={companyData?.profile?.image}
                      alt={companyData?.profile?.companyName}
                      onError={handleImageError}
                    />
                  )}
                  <span className={styles.Ticker}>{ticker}</span>
                  <div className={styles.TickerDivider} />
                  <span
                    className={styles.CompanyName}
                    title={companyData?.profile?.companyName}
                  >
                    {companyData?.profile?.companyName}
                  </span>
                </div>
                <div className={styles.CurrencyFlag}>
                  {companyData?.profile?.currency ? (
                    <>
                      <span className={styles.ExchangeText}>
                        {companyData?.profile?.currency}
                      </span>
                      {companyData?.profile?.currency &&
                        companyData?.profile?.country && (
                          <div className={styles.TickerDivider} />
                        )}
                    </>
                  ) : null}
                  {/* {flagImageUrl ? (
                    <img
                      title={companyData?.profile?.country}
                      alt={companyData?.profile?.country}
                      src={isFlagLoading ? undefined : flagImageUrl}
                    />
                  ) : null} */}
                </div>
              </div>
              <div className={styles.stockPrice}>
                <div>
                  <span className={styles.PriceText}>
                    {numeral(stockPrice?.price).format('0.00a')}
                  </span>
                  <span
                    className={styles.ChangeText}
                    style={{ color: getColor(stockPrice?.change) }}
                  >
                    {numeral(stockPrice?.change).format('0.00')}
                  </span>
                  <span
                    className={styles.ChangeText}
                    style={{ color: getColor(stockPrice?.changesPercentage) }}
                  >{` (${numeral(stockPrice?.changesPercentage).format(
                    '0.00'
                  )}%)`}</span>
                </div>
                <div className={styles.ExchangeSector}>
                  {companyData?.profile?.sector ? (
                    <>
                      <span className={styles.ExchangeText}>
                        {companyData?.profile?.sector}
                      </span>
                      <div className={styles.TickerDivider} />
                    </>
                  ) : null}
                  <span className={styles.exchangeText}>
                    {stockPrice?.exchange}
                  </span>
                </div>
              </div>
            </div>
            <div className={styles.ChartTableWrapper}>
              <div className={styles.metricsTable}>
                <TooltipTable
                  data={data}
                  isLoading={
                    isChartDataLoading ||
                    isCompanyDataLoading ||
                    isStockPriceLoading
                  }
                  profile={companyData?.profile}
                  metrics={companyData?.metrics}
                  ratios={companyData?.ratios}
                  stockPrice={stockPrice}
                />
              </div>
              <div style={{ height: '125px', minWidth: '270px' }}>
                {data && data.length > 0 ? (
                  <TickerOverlayChart
                    data={data}
                    xAxisKey={'date'}
                    barDataKey={'volume'}
                    lineDataKey={'close'}
                  />
                ) : (
                  <div className={styles.noChartDataContainer}>
                    Chart data unavailable
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

const TooltipTicker = ({ ticker, customURLDirect }) => {
  const navigate = useNavigate()

  const appropriateURL = customURLDirect
    ? customURLDirect
    : '/terminal/companyOverview'

  const handlePushViewClick = () => {
    navigate(appropriateURL + '?ticker=' + ticker)
  }

  return (
    <>
      <Tooltip
        title={<CustomTooltipContent ticker={ticker} />}
        interactive
        placement="right-start"
        TransitionProps={{ timeout: 0 }}
        componentsProps={{
          tooltip: {
            sx: {
              backgroundColor: 'var(--background-primary)',
              border: '1.5px solid var(--grey-accent)',
              borderRadius: '4px',
              maxWidth: '600px',
            },
          },
        }}
      >
        <span className={styles.ticker} onClick={handlePushViewClick}>
          {ticker}
        </span>
      </Tooltip>
    </>
  )
}

export default TooltipTicker
