import React, { useState, useEffect } from 'react'
import { Auth } from 'aws-amplify'
import { SuccessDisplay } from './utils/successDisplay'
import { PricingView } from './PricingComponent/PricingView'

export const Pricing = () => {
  let [message, setMessage] = useState('')
  let [success, setSuccess] = useState(false)
  let [sessionId, setSessionId] = useState('')
  let [jwt, setJwt] = useState('')

  useEffect(() => {
    Auth.currentSession()
      .then(res => {
        let accessToken = res.getAccessToken()
        setJwt(accessToken.getJwtToken())
      })
      .catch(err => console.log('Not authenticated', err))
  }, [])

  useEffect(() => {
    const query = new URLSearchParams(window.location.search)

    let isSuccessful = query.get('success')
    let session_id = query.get('session_id')

    if (isSuccessful) {
      setSuccess(true)
      setSessionId(session_id)
    }
  }, [window.location.search])

  if (success && sessionId !== '') {
    return <SuccessDisplay sessionId={sessionId} jwt={jwt} />
  } else if (!success && message === '') {
    return <PricingView jwt={jwt} />
  } else {
    return <Message message={message} />
  }
}

export const Message = ({ message }) => (
  <div style={{ color: 'var(--white)', fontSize: '15px' }}>{message}</div>
)
