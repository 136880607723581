import { useQuery } from 'react-query'
import { queries } from './queries'
import { useMemo, useState } from 'react'
import { OverviewIntervals } from '../constant'
import { useURLSyncedGlobalTicker } from 'pages/views/hooks'
import { combineData } from '../utils'
import { last } from 'lodash'

export const useDataSource = () => {
  const [interval, setInterval] = useState(OverviewIntervals[3])

  const { handleSetTicker, ticker } = useURLSyncedGlobalTicker()

  const { data: HistoricalData, isLoading: historicalDataIsLoading } = useQuery(
    ['historical-price', ticker, interval],
    queries.fetchPriceData
  )

  const { data: newsData, isLoading: newsDataIsLoading } = useQuery(
    ['news-data', ticker],
    queries.fetchCompanyNews
  )

  const { data: outlookData, isLoading: outlookDataIsLoading } = useQuery(
    ['outlook-data', ticker],
    queries.fetchCompanyData
  )

  const { data: quoteData, isLoading: quoteDataIsLoading } = useQuery(
    ['quote-data', ticker],
    queries.fetchCompanyQuote,
    { refetchInterval: 5000, refetchIntervalInBackground: false }
  )

  const { data: returnsData, isLoading: returnsDataIsLoading } = useQuery(
    ['stock-returns', ticker],
    queries.fetchReturns
  )

  const { data: analystConsensus, isLoading: analystConsensusIsLoading } =
    useQuery(['analyst-consensus', ticker], queries.fetchAnalystConsensus)

  const dataProfile = outlookData?.profile
  const dataQuote = quoteData
  const dataIncome = last(outlookData?.financialsAnnual?.income)
  const dataBalance = last(outlookData?.financialsAnnual?.balance)
  const dataCashflow = last(outlookData?.financialsAnnual?.cash)

  const financialAnnual = combineData(dataIncome, dataBalance, dataCashflow)

  const ProfileQuoteData = combineData(dataQuote, dataProfile)

  const divData = useMemo(() => {
    return outlookData?.stockDividend
      ? [...outlookData.stockDividend].reverse()
      : []
  }, [outlookData?.stockDividend])

  const Profile_UI = useMemo(() => {
    return {
      divData,
      newsData,
      newsDataIsLoading,
      financialAnnual,
      ProfileQuoteData,
      priceData: HistoricalData,
      companyData: outlookData,
      quoteData: quoteData,
      returnsData: returnsData,
      priceDataIsLoading: historicalDataIsLoading,
      companyDataIsLoading: outlookDataIsLoading,
      quoteDataIsLoading: quoteDataIsLoading,
      returnsDataIsLoading: returnsDataIsLoading,
      analystConsensusIsLoading: analystConsensusIsLoading,
      analystConsensus: analystConsensus,
    }
  }, [
    divData,
    newsData,
    newsDataIsLoading,
    financialAnnual,
    ProfileQuoteData,
    HistoricalData,
    outlookData,
    quoteData,
    returnsData,
    historicalDataIsLoading,
    outlookDataIsLoading,
    quoteDataIsLoading,
    returnsDataIsLoading,
    analystConsensusIsLoading,
    analystConsensus,
  ])

  return {
    Profile_UI,
    setTicker: handleSetTicker,
    ticker,
    setInterval,
    interval,
  }
}
