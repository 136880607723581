import { useDataSource } from './hooks'
import { useAnalyticsData } from './useAnalyticsData'
import {
  combineFinancialData,
  calculateCompositeScoresForMappedCategories,
  mergeDataWithGrowth,
} from '../utils'
import { financialMetricsOrganized, ratioMetrics } from '../constants'
import { startCase } from 'lodash'
import { useMemo } from 'react'
import numeral from 'numeral'

export const useRatioData = ({ selectedGroupByColumns }) => {
  const { CompanyData_UI, ticker } = useDataSource()

  const AnalyticsData = useAnalyticsData(selectedGroupByColumns)

  const mostRecentYearData = AnalyticsData?.ratios?.[0] ?? []
  const ratioData = CompanyData_UI?.annualRatiosData ?? []
  const analyticsData = AnalyticsData?.ratios ?? []

  const analyticsdata = combineFinancialData([analyticsData])

  const finData = CompanyData_UI?.annualRatiosData?.[0] ?? []

  const categories = useMemo(
    () => [
      'valuation',
      'profitability',
      'efficiency',
      'management',
      'health',
      // 'dividends',
    ],
    []
  )

  const growthMergedData = mergeDataWithGrowth(analyticsData, ratioData)

  const scores = useMemo(
    () =>
      calculateCompositeScoresForMappedCategories(
        finData,
        mostRecentYearData,
        categories,
        financialMetricsOrganized
      ),
    [finData, mostRecentYearData, categories, financialMetricsOrganized]
  )

  const chartData = useMemo(
    () =>
      categories.map((category, index) => ({
        name: startCase(category),
        score: numeral(parseFloat(scores[index])).format('0'),
      })),
    [categories, scores]
  )

  const allScores = useMemo(() =>
    categories.map(
      (category, index) => ({
        name: startCase(category),
        score: parseFloat(scores[index]),
      }),
      [categories, scores]
    )
  )

  const overallScore = useMemo(
    () => scores.reduce((a, b) => a + b, 0) / scores.length,
    [scores]
  )

  return {
    scores: chartData,
    selectedMetrics: ratioMetrics,
    overallScore: overallScore,
    analyticsData: analyticsdata,
    finData: finData,
    ticker: ticker,
    allScores: allScores,
    growthData: growthMergedData,
  }
}
