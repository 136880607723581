import axios from 'axios'
import http from '../../network/httpClient'

export const fetchData = async (ticker, start, end) => {
  let share_price_data_raw = await http
    .get(
      'papi/data/api/v3/historical-price-full/' +
        ticker +
        '?from=' +
        end() +
        '&to=' +
        start
    )
    .then(data_raw => data_raw.data.message)

  let quote_data = await http
    .get('papi/data/api/v3/quote/' + ticker)
    .then(data_raw => data_raw.data.message)

  return {
    share_price_data: share_price_data_raw.historical.reverse(),
    quote_data,
  }
}

export const fetchGranularData = async ({ call, rowCount, ticker }) => {
  let share_price_data_raw = await axios
    .get(call(ticker))
    .then(data_raw => data_raw.data)

  let quote_data = await http
    .get('papi/data/api/v3/quote/' + ticker)
    .then(data_raw => data_raw.data.message)

  return {
    share_price_data: share_price_data_raw?.slice(0, rowCount)?.reverse(),
    quote_data,
  }
}
