import React, { useEffect } from 'react'
import styles from './Profile.module.scss'
import { useQuery } from 'react-query'
import moment from 'moment'
import numeral from 'numeral'
import NoDataContainer from 'core/NoDataContainer/NoDataContainer'

import http from 'network/httpClient'

const fetchProfile = async ({ queryKey }) => {
  let data = await http.get('papi/data/api/v3/profile/' + queryKey[1])
  return data?.data?.message
}

export const Profile = ({ ticker }) => {
  const { data, error, refetch } = useQuery(['profile', ticker], fetchProfile)

  useEffect(() => {
    refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticker])

  return (
    <>
      {!error && data?.length ? (
        <div className={styles.profileContainer}>
          <div className={styles.firstPanel}>
            <div className={styles.profileCompanyName}>
              {data[0]?.symbol}, {data[0]?.companyName}
            </div>
            <a
              className={styles.websiteLinkText}
              href={data[0]?.website}
              target="_blank"
              rel="noreferrer"
            >
              Company website
            </a>
          </div>
          <div className={styles.secondPanel}>
            <div className={styles.profileLogoContainer}>
              <img
                alt="company"
                className={styles.companyImage}
                src={data[0]?.image}
              ></img>
            </div>
            <div className={styles.profileInfo}>
              <div className={styles.contentColumn1}>
                <div className={styles.content}>
                  <div className={styles.profileDataContainer}>
                    {/* <div className={styles.header}>Symbol</div>
                    <div className={styles.data}>{data[0]?.symbol}</div> */}
                    <div className={styles.header}>Market Cap</div>
                    <div className={styles.data}>
                      {numeral(data[0]?.mktCap).format(' 0.0 a')}
                    </div>
                  </div>
                  <div className={styles.profileLineBreak}> </div>
                  <div className={styles.profileDataContainer}>
                    <div className={styles.header}>IPO Date</div>
                    <div className={styles.data}>
                      {moment(data[0]?.ipoDate).format('ll')}
                    </div>
                  </div>
                  <div className={styles.profileLineBreak}> </div>
                  <div className={styles.profileDataContainer}>
                    <div className={styles.header}>Exchange</div>
                    <div className={styles.data}>
                      {data[0]?.exchangeShortName}
                    </div>
                  </div>
                  <div className={styles.profileLineBreak}> </div>
                  <div className={styles.profileDataContainer}>
                    <div className={styles.header}>Currency</div>
                    <div className={styles.data}>{data[0]?.currency}</div>
                  </div>
                </div>
              </div>
              <div className={styles.contentColumn2}>
                <div className={styles.content}>
                  <div className={styles.profileDataContainer}>
                    <div className={styles.header}>Industry</div>
                    <div className={styles.data}>{data[0]?.industry}</div>
                  </div>
                  <div className={styles.profileLineBreak}> </div>
                  <div className={styles.profileDataContainer}>
                    <div className={styles.header}>Country</div>
                    <div className={styles.data}>{data[0]?.country}</div>
                  </div>
                  <div className={styles.profileLineBreak}> </div>
                  <div className={styles.profileDataContainer}>
                    <div className={styles.header}>Full Time Employees</div>
                    <div className={styles.data}>
                      {numeral(data[0]?.fullTimeEmployees).format(' 0.0 a')}
                    </div>
                  </div>
                  <div className={styles.profileLineBreak}> </div>
                  <div className={styles.profileDataContainer}>
                    <div className={styles.header}>CEO</div>
                    <div className={styles.data}>{data[0]?.ceo}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.descriptionPanel}>
            <div className={styles.profileDescription}>
              {data[0]?.description}
            </div>
          </div>
        </div>
      ) : (
        <NoDataContainer />
      )}
    </>
  )
}
