import React, { createContext, useContext, useState } from 'react'
import { themes } from './constants'

const ThemeContext = createContext()

export const useTheme = () => useContext(ThemeContext)

const loadCustomThemes = () => {
  const existingThemes =
    JSON.parse(window.localStorage.getItem('customThemes')) || {}
  return existingThemes
}

export const ThemeProvider = ({ children }) => {
  const [allThemes, setAllThemes] = useState({
    ...themes,
    ...loadCustomThemes(),
  })

  const applyTheme = themeKey => {
    const theme = allThemes[themeKey]
    if (!theme) {
      return
    }
    const root = document.documentElement

    Object.keys(theme).forEach(property => {
      root.style.setProperty(property, theme[property])
    })
  }

  const refreshThemes = () => {
    const customThemes = loadCustomThemes()
    setAllThemes({ ...themes, ...customThemes })
  }

  const deleteTheme = themeKey => {
    const currentCustomThemes = loadCustomThemes()
    if (currentCustomThemes[themeKey]) {
      delete currentCustomThemes[themeKey]
      window.localStorage.setItem(
        'customThemes',
        JSON.stringify(currentCustomThemes)
      )
      refreshThemes()
    }
  }

  const [theme, setTheme] = useState(() => {
    const localTheme = window.localStorage.getItem('theme')
    return localTheme || 'dark'
  })

  const toggleTheme = newTheme => {
    setTheme(newTheme)
    window.localStorage.setItem('theme', newTheme)
  }

  const addCustomTheme = (themeName, themeProperties) => {
    const newCustomThemes = {
      ...loadCustomThemes(),
      [themeName]: themeProperties,
    }
    window.localStorage.setItem('customThemes', JSON.stringify(newCustomThemes))
    refreshThemes()
  }

  return (
    <ThemeContext.Provider
      value={{
        theme,
        toggleTheme,
        refreshThemes,
        allThemes,
        applyTheme,
        deleteTheme,
        addCustomTheme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  )
}
