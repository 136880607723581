import styles from './BasicNewsTable.module.scss'
import dayjs from 'dayjs'
import TooltipTicker from 'core/TickerOverlay/TickerOverlay'

export const BasicNewsTable = ({ data, showSymbol }) => {
  const currentDate = dayjs()
  return (
    <div className={styles.newsTable}>
      {data.map((item, index) => {
        return (
          <a href={item.url} target="_blank" alt rel="noreferrer">
            <div
              key={index}
              className={styles.newsRow}
              style={{ height: 32 }}
              title={item.text}
            >
              {showSymbol && (
                <div className={styles.symbol}>
                  <TooltipTicker ticker={item?.symbol} />{' '}
                </div>
              )}
              <div className={styles.title}> {item?.title}</div>
              <div className={styles.site}> {item?.site}</div>
              <div className={styles.date}>
                {dayjs(item?.publishedDate).isSame(currentDate, 'day')
                  ? dayjs(item?.publishedDate).format('h:mm a')
                  : dayjs(item?.publishedDate).format('MMM DD, YY')}
              </div>
            </div>
          </a>
        )
      })}
    </div>
  )
}
