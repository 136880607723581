import { initial, startCase, tail } from 'lodash'
import numeral from 'numeral'

const removeFirstAndLast = arr => {
  const newArr = arr.slice(1, arr.length - 1)
  return newArr
}

export const getCoreBarChartProps = (
  finalCombinedData,
  globalDataKey,
  companyData,
  growth
) => ({
  data: growth
    ? removeFirstAndLast(finalCombinedData)
    : initial(finalCombinedData),
  XAxisKey: `${globalDataKey}.date`,
  metrics: [
    {
      key: growth
        ? `${globalDataKey}.analyticalGrowth`
        : `${globalDataKey}.AVG`,
      color: '#7c53db',
    },
    {
      key: growth
        ? `${globalDataKey}.companyGrowth`
        : `${globalDataKey}.companyValue`,
      color: 'var(--primary-color)',
    },
  ],
  xAxisHeight: 12,
  onlyYear: true,
  showYAxis: true,
  axisWidth: 40,
  tickFormat: undefined,
  zeroTick: true,
  showLegend: true,
  legendFormat: value => {
    if (value.includes('.AVG')) {
      return `${startCase(globalDataKey)} Analytical Avg`
    } else {
      return companyData?.profileData?.[0]?.companyName
    }
  },
})

export const overViewData = data => [
  {
    label: 'Analytical Avg:',
    value: numeral(data?.AVG).format('0.00a'),
  },
  {
    label: 'Company Value:',
    value: numeral(data?.companyValue).format('0.00a'),
  },
  {
    label: 'Min:',
    value: numeral(data?.MIN).format('0.00a'),
  },
  {
    label: 'Max:',
    value: numeral(data?.MAX).format('0.00a'),
  },
  {
    label: 'Analytical Growth:',
    value: `${numeral(data?.analyticalGrowth).format('0a')}%`,
  },
  {
    label: 'Company Growth:',
    value: `${numeral(data?.companyGrowth).format('0a')}%`,
  },
]

export const getLastElement = arr => {
  return arr[arr.length - 2]
}
