import styles from './CompanyQuoteTable.module.scss'
import _ from 'lodash'
import numeral from 'numeral'
import dayjs from 'dayjs'

export const CompanyQuoteTable = (data, isView) => {
  const columnsToUse = isView
    ? { left: leftColumnView, right: rightColumnView }
    : { left: leftColumn, right: rightColumn }

  return columnsToUse.left.map((entry, i) => {
    const leftValue = data.data[entry.key]
    const rightValue = data.data[columnsToUse.right[i].key]

    const formatValue = value => {
      if (_.isString(value)) {
        if (dayjs(value).isValid()) {
          return dayjs(value).format('MMM DD')
        } else {
          return value
        }
      } else {
        return numeral(value).format('0.00 a')
      }
    }

    const leftFormatted = formatValue(leftValue)
    const rightFormatted = formatValue(rightValue)

    return (
      <div className={styles.companyQuoteRow}>
        <div className={styles.companyQuoteCell1}>
          <div title={entry.label} className={styles.companyQuoteContent}>
            {entry.label}
          </div>
        </div>
        <div className={styles.companyQuoteCell2}>
          <div
            title={leftFormatted}
            className={styles.companyQuoteContentRight}
          >
            {leftFormatted}
          </div>
        </div>
        <div className={styles.companyQuoteCell3}>
          <div className={styles.companyQuoteContent}>
            <span
              title={columnsToUse.right[i].label}
              style={{ textOverflow: 'ellipsis' }}
            >
              {columnsToUse.right[i].label}
            </span>
          </div>
        </div>
        <div className={styles.companyQuoteCell4}>
          <div
            title={rightFormatted}
            className={styles.companyQuoteContentRight}
          >
            {rightFormatted}
          </div>
        </div>
      </div>
    )
  })
}

const leftColumn = [
  { key: 'open', label: 'Open' },
  { key: 'dayLow', label: 'Day Low' },
  { key: 'dayHigh', label: 'Day High' },
  { key: 'yearLow', label: 'Year Low' },
  { key: 'yearHigh', label: 'Year High' },
  { key: 'priceAvg50', label: '50-Day Avg' },
  { key: 'exchange', label: 'Exchange' },
]

const rightColumn = [
  { key: 'previousClose', label: 'Previous Close' },
  { key: 'marketCap', label: 'Market Cap' },
  { key: 'volume', label: 'Volume' },
  { key: 'avgVolume', label: 'Avg Volume' },
  { key: 'eps', label: 'EPS' },
  { key: 'pe', label: 'P/E' },
  { key: 'sharesOutstanding', label: 'Shares Outstanding' },
]

const leftColumnView = [
  { key: 'open', label: 'Open' },
  { key: 'previousClose', label: 'Previous Close' },
  { key: 'dayLow', label: 'Day Low' },
  { key: 'dayHigh', label: 'Day High' },
  { key: 'yearLow', label: 'Year Low' },
  { key: 'yearHigh', label: 'Year High' },
  { key: 'priceAvg50', label: '50-Day Avg' },
  { key: 'priceAvg200', label: '200-Day Avg' },
  { key: 'volume', label: 'Volume' },
  { key: 'avgVolume', label: 'Avg Volume' },
  { key: 'marketCap', label: 'Market Cap' },
]

const rightColumnView = [
  { key: 'previousClose', label: 'Previous Close' },
  { key: 'marketCap', label: 'Market Cap' },
  { key: 'volume', label: 'Volume' },
  { key: 'avgVolume', label: 'Avg Volume' },
  { key: 'eps', label: 'EPS' },
  { key: 'pe', label: 'P/E' },
  { key: 'sharesOutstanding', label: 'Shares Outstanding' },
  { key: 'earningsAnnouncement', label: 'Next Earnings' },
  { key: 'earningsAnnouncement', label: 'Next Earnings' },
  { key: 'earningsAnnouncement', label: 'Next Earnings' },
  { key: 'earningsAnnouncement', label: 'Next Earnings' },
]
