import dayjs from 'dayjs'
import numeral from 'numeral'

const isDataThisYear = data => {
  const currentYear = new Date().getFullYear()
  const dataYear = new Date(data).getFullYear()
  return currentYear === dataYear
}

export const fundingColumns = [
  {
    Header: 'Form Type',
    accessor: 'formType',
    width: 'fit-content',
  },
  {
    Header: 'Entity Name',
    accessor: 'entityName',
    width: 'fit-content',
    filter: 'search',
  },
  {
    Header: 'Acceptance Time',
    accessor: 'acceptanceTime',
    Cell: ({ value }) => {
      if (isDataThisYear(value))
        return dayjs(value).format('MMM DD, hh:mm:ss A')
      else return dayjs(value).format('MMM DD, YYYY hh:mm:ss A')
    },
    width: 'fit-content',
  },
  {
    Header: 'Year of Incorporation',
    accessor: 'yearOfIncorporation',
    width: 'fit-content',
    Cell: ({ value }) => {
      if (value === null || value === undefined || value === '') return '-'
      else return value
    },
  },
  {
    Header: 'Industry Group Type',
    accessor: 'industryGroupType',
    width: 'fit-content',
  },
  {
    Header: 'Revenue Range',
    accessor: 'revenueRange',
    width: 'fit-content',
    Cell: ({ value }) => {
      if (value === null || value === undefined || value === '') return '-'
      else return value
    },
  },
  {
    Header: 'Date of First Sale',
    accessor: 'dateOfFirstSale',
    width: 'fit-content',
    Cell: ({ value }) => {
      if (value === null || value === undefined || value === '') return '-'
      else return dayjs(value).format('MMM DD, YYYY')
    },
  },
  {
    Header: 'Minimum Investment',
    accessor: 'minimumInvestmentAccepted',
    width: 'fit-content',
    Cell: ({ value }) => (value === 0 ? '-' : numeral(value).format('0,0.0a')),
  },
  {
    Header: 'Offering Amount',
    accessor: 'totalOfferingAmount',
    width: 'fit-content',
    Cell: ({ value }) => (value === 0 ? '-' : numeral(value).format('0,0.0a')),
  },
  {
    Header: 'Amount Sold',
    accessor: 'totalAmountSold',
    width: 'fit-content',
    Cell: ({ value }) => (value === 0 ? '-' : numeral(value).format('0,0.0a')),
  },
  {
    Header: 'Amount Remaining',
    accessor: 'totalAmountRemaining',
    width: 'fit-content',
    Cell: ({ value }) => (value === 0 ? '-' : numeral(value).format('0,0.0a')),
  },
  {
    Header: 'Already Invested',
    accessor: 'totalNumberAlreadyInvested',
    width: 'fit-content',
    Cell: ({ value }) => (value === 0 ? '-' : numeral(value).format('0,0.0a')),
  },
  {
    Header: 'Sales Commissions',
    accessor: 'salesCommissions',
    width: 'fit-content',
    Cell: ({ value }) => (value === 0 ? '-' : numeral(value).format('0,0.0a')),
  },
  {
    Header: 'Finders Fees',
    accessor: 'findersFees',
    width: 'fit-content',
    Cell: ({ value }) => (value === 0 ? '-' : numeral(value).format('0,0.0a')),
  },
  {
    Header: 'Gross Proceeds Used',
    accessor: 'grossProceedsUsed',
    width: 'fit-content',
    Cell: ({ value }) => (value === 0 ? '-' : numeral(value).format('0,0.0a')),
  },
]
