import React, { useEffect, useRef, useMemo, useCallback } from 'react'
import * as d3 from 'd3'

export const BarChart = React.memo(({ data, width = 100, height = 22 }) => {
  const ref = useRef()

  const getColor = useCallback(() => {
    return data?.[0]?.value < data?.[data?.length - 1]?.value
      ? 'var(--green)'
      : 'var(--red)'
  }, [data])

  useEffect(() => {
    const svg = d3
      .select(ref.current)
      .attr('width', width)
      .attr('height', height)

    svg.selectAll('*').remove()

    const color = getColor()

    const xScale = d3
      .scaleBand()
      .domain(data.map(d => d.name))
      .range([0, width])

    const yScale = d3
      .scaleLinear()
      .domain([d3.min(data, d => d.value), d3.max(data, d => d.value)])
      .range([height, 0])

    svg
      .selectAll('rect')
      .data(data)
      .enter()
      .append('rect')
      .attr('x', d => xScale(d.name))
      .attr('y', d => (d.value >= 0 ? yScale(d.value) : yScale(0)))
      .attr('width', xScale.bandwidth())
      .attr('height', d => Math.abs(yScale(d.value) - yScale(0)))
      .attr('fill', color)
  }, [data, getColor, height, width])

  return <svg ref={ref}></svg>
})

export const LineChart = React.memo(({ data, width = 100, height = 22 }) => {
  const ref = useRef()

  const getColor = useCallback(() => {
    return data?.[0]?.value < data?.[data?.length - 1]?.value
      ? 'var(--green)'
      : 'var(--red)'
  }, [data])

  useEffect(() => {
    const svg = d3
      .select(ref.current)
      .attr('width', width)
      .attr('height', height)

    const color = getColor()

    svg.selectAll('*').remove()
    const xScale = d3
      .scalePoint()
      .domain(data.map(d => d.name))
      .range([0, width])

    const yScale = d3
      .scaleLinear()
      .domain([d3.min(data, d => d.value), d3.max(data, d => d.value)])
      .range([height, 0])

    const line = d3
      .line()
      .x(d => xScale(d.name))
      .y(d => yScale(d.value))
      .curve(d3.curveMonotoneX)

    svg
      .append('path')
      .datum(data)
      .attr('fill', 'none')
      .attr('stroke', color)
      .attr('stroke-width', 1.5)
      .attr('d', line)
  }, [data, getColor, height, width])

  return <svg ref={ref}></svg>
})
