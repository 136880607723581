export const stockData = {
  GSPC: [
    { date: '2024-02-08 09:30:00', close: 4994.04 },
    { date: '2024-02-08 10:00:00', close: 4992.92 },
    { date: '2024-02-08 10:30:00', close: 4995.72 },
    { date: '2024-02-08 11:00:00', close: 4987.47 },
    { date: '2024-02-08 11:30:00', close: 4992.34 },
    { date: '2024-02-08 12:00:00', close: 4994.65 },
    { date: '2024-02-08 12:30:00', close: 4989.67 },
    { date: '2024-02-08 13:00:00', close: 4994.38 },
    { date: '2024-02-08 13:30:00', close: 4995.17 },
    { date: '2024-02-08 14:00:00', close: 4992.49 },
    { date: '2024-02-08 14:30:00', close: 4996.69 },
    { date: '2024-02-08 15:00:00', close: 4998.36 },
    { date: '2024-02-08 15:30:00', close: 4998.52 },
    { date: '2024-02-09 09:30:00', close: 5008.64 },
    { date: '2024-02-09 10:00:00', close: 5010.66 },
    { date: '2024-02-09 10:30:00', close: 5013.87 },
    { date: '2024-02-09 11:00:00', close: 5011.13 },
    { date: '2024-02-09 11:30:00', close: 5010.04 },
    { date: '2024-02-09 12:00:00', close: 5015.15 },
    { date: '2024-02-09 12:30:00', close: 5018.56 },
    { date: '2024-02-09 13:00:00', close: 5021.16 },
    { date: '2024-02-09 13:30:00', close: 5021.04 },
    { date: '2024-02-09 14:00:00', close: 5024.73 },
    { date: '2024-02-09 14:30:00', close: 5026.42 },
    { date: '2024-02-09 15:00:00', close: 5024.99 },
    { date: '2024-02-09 15:30:00', close: 5025.89 },
    { date: '2024-02-12 09:30:00', close: 5028.04 },
    { date: '2024-02-12 10:00:00', close: 5026.87 },
    { date: '2024-02-12 10:30:00', close: 5034.77 },
    { date: '2024-02-12 11:00:00', close: 5042.14 },
    { date: '2024-02-12 11:30:00', close: 5046.32 },
    { date: '2024-02-12 12:00:00', close: 5042.78 },
    { date: '2024-02-12 12:30:00', close: 5045.9 },
    { date: '2024-02-12 13:00:00', close: 5039.33 },
    { date: '2024-02-12 13:30:00', close: 5034.91 },
    { date: '2024-02-12 14:00:00', close: 5024.76 },
    { date: '2024-02-12 14:30:00', close: 5019.27 },
    { date: '2024-02-12 15:00:00', close: 5023.08 },
    { date: '2024-02-12 15:30:00', close: 5022.49 },
    { date: '2024-02-13 09:30:00', close: 4951.59 },
    { date: '2024-02-13 10:00:00', close: 4962.19 },
    { date: '2024-02-13 10:30:00', close: 4958.68 },
    { date: '2024-02-13 11:00:00', close: 4958.59 },
    { date: '2024-02-13 11:30:00', close: 4954.45 },
    { date: '2024-02-13 12:00:00', close: 4958.04 },
    { date: '2024-02-13 12:30:00', close: 4959.08 },
    { date: '2024-02-13 13:00:00', close: 4961.29 },
    { date: '2024-02-13 13:30:00', close: 4960.03 },
    { date: '2024-02-13 14:00:00', close: 4961.12 },
    { date: '2024-02-13 14:30:00', close: 4957.93 },
    { date: '2024-02-13 15:00:00', close: 4958.73 },
    { date: '2024-02-13 15:30:00', close: 4958.59 },
    { date: '2024-02-14 09:30:00', close: 4966.11 },
    { date: '2024-02-14 10:00:00', close: 4970.92 },
    { date: '2024-02-14 10:30:00', close: 4972.41 },
    { date: '2024-02-14 11:00:00', close: 4972.81 },
    { date: '2024-02-14 11:30:00', close: 4972.7 },
    { date: '2024-02-14 12:00:00', close: 4968.33 },
    { date: '2024-02-14 12:30:00', close: 4965.11 },
    { date: '2024-02-14 13:00:00', close: 4967.88 },
    { date: '2024-02-14 13:30:00', close: 4971.15 },
    { date: '2024-02-14 14:00:00', close: 4973.39 },
    { date: '2024-02-14 14:30:00', close: 4976.23 },
    { date: '2024-02-14 15:00:00', close: 4977.53 },
    { date: '2024-02-14 15:30:00', close: 4979.47 },
    { date: '2024-02-15 09:30:00', close: 4975.24 },
    { date: '2024-02-15 10:00:00', close: 4982.28 },
    { date: '2024-02-15 10:30:00', close: 4981.45 },
    { date: '2024-02-15 11:00:00', close: 4977.86 },
    { date: '2024-02-15 11:30:00', close: 4980.29 },
    { date: '2024-02-15 12:00:00', close: 4979.11 },
    { date: '2024-02-15 12:30:00', close: 4976.42 },
    { date: '2024-02-15 13:00:00', close: 4978.55 },
    { date: '2024-02-15 13:30:00', close: 4975.98 },
    { date: '2024-02-15 14:00:00', close: 4975.67 },
    { date: '2024-02-15 14:30:00', close: 4977.92 },
    { date: '2024-02-15 15:00:00', close: 4980.31 },
    { date: '2024-02-15 15:30:00', close: 4979.88 },
    { date: '2024-02-16 09:30:00', close: 4981.99 },
    { date: '2024-02-16 10:00:00', close: 4984.06 },
    { date: '2024-02-16 10:30:00', close: 4987.71 },
    { date: '2024-02-16 11:00:00', close: 4985.67 },
    { date: '2024-02-16 11:30:00', close: 4984.44 },
    { date: '2024-02-16 12:00:00', close: 4987.17 },
    { date: '2024-02-16 12:30:00', close: 4984.96 },
    { date: '2024-02-16 13:00:00', close: 4981.45 },
    { date: '2024-02-16 13:30:00', close: 4976.25 },
    { date: '2024-02-16 14:00:00', close: 4976.71 },
    { date: '2024-02-16 14:30:00', close: 4979.49 },
    { date: '2024-02-16 15:00:00', close: 4980.35 },
    { date: '2024-02-16 15:30:00', close: 4982.63 },
  ],
  DJI: [
    {
      date: '2024-02-08 09:30:00',
      close: 38679.97,
    },
    {
      date: '2024-02-08 10:00:00',
      close: 38664.18,
    },
    {
      date: '2024-02-08 10:30:00',
      close: 38611.82,
    },
    {
      date: '2024-02-08 11:00:00',
      close: 38549.77,
    },
    {
      date: '2024-02-08 11:30:00',
      close: 38578.54,
    },
    {
      date: '2024-02-08 12:00:00',
      close: 38599.67,
    },
    {
      date: '2024-02-08 12:30:00',
      close: 38613.73,
    },
    {
      date: '2024-02-08 13:00:00',
      close: 38640.46,
    },
    {
      date: '2024-02-08 13:30:00',
      close: 38676.62,
    },
    {
      date: '2024-02-08 14:00:00',
      close: 38664.6,
    },
    {
      date: '2024-02-08 14:30:00',
      close: 38695.77,
    },
    {
      date: '2024-02-08 15:00:00',
      close: 38705.81,
    },
    {
      date: '2024-02-08 15:30:00',
      close: 38730.05,
    },
    {
      date: '2024-02-09 09:30:00',
      close: 38682.58,
    },
    {
      date: '2024-02-09 10:00:00',
      close: 38661.41,
    },
    {
      date: '2024-02-09 10:30:00',
      close: 38647.84,
    },
    {
      date: '2024-02-09 11:00:00',
      close: 38632.92,
    },
    {
      date: '2024-02-09 11:30:00',
      close: 38581.28,
    },
    {
      date: '2024-02-09 12:00:00',
      close: 38631.97,
    },
    {
      date: '2024-02-09 12:30:00',
      close: 38646.71,
    },
    {
      date: '2024-02-09 13:00:00',
      close: 38666.96,
    },
    {
      date: '2024-02-09 13:30:00',
      close: 38657.56,
    },
    {
      date: '2024-02-09 14:00:00',
      close: 38680.62,
    },
    {
      date: '2024-02-09 14:30:00',
      close: 38688.86,
    },
    {
      date: '2024-02-09 15:00:00',
      close: 38672.25,
    },
    {
      date: '2024-02-09 15:30:00',
      close: 38667.31,
    },
    {
      date: '2024-02-12 09:30:00',
      close: 38686.76,
    },
    {
      date: '2024-02-12 10:00:00',
      close: 38684.58,
    },
    {
      date: '2024-02-12 10:30:00',
      close: 38725.89,
    },
    {
      date: '2024-02-12 11:00:00',
      close: 38828.18,
    },
    {
      date: '2024-02-12 11:30:00',
      close: 38882.08,
    },
    {
      date: '2024-02-12 12:00:00',
      close: 38860.89,
    },
    {
      date: '2024-02-12 12:30:00',
      close: 38886.25,
    },
    {
      date: '2024-02-12 13:00:00',
      close: 38884.96,
    },
    {
      date: '2024-02-12 13:30:00',
      close: 38868.53,
    },
    {
      date: '2024-02-12 14:00:00',
      close: 38799.68,
    },
    {
      date: '2024-02-12 14:30:00',
      close: 38770.56,
    },
    {
      date: '2024-02-12 15:00:00',
      close: 38774.15,
    },
    {
      date: '2024-02-12 15:30:00',
      close: 38807.45,
    },
    {
      date: '2024-02-13 09:30:00',
      close: 38273,
    },
    {
      date: '2024-02-13 10:00:00',
      close: 38335.69,
    },
    {
      date: '2024-02-13 10:30:00',
      close: 38308.81,
    },
    {
      date: '2024-02-13 11:00:00',
      close: 38379.48,
    },
    {
      date: '2024-02-13 11:30:00',
      close: 38357.9,
    },
    {
      date: '2024-02-13 12:00:00',
      close: 38303.06,
    },
    {
      date: '2024-02-13 12:30:00',
      close: 38297.11,
    },
    {
      date: '2024-02-13 13:00:00',
      close: 38256.04,
    },
    {
      date: '2024-02-13 13:30:00',
      close: 38201.66,
    },
    {
      date: '2024-02-13 14:00:00',
      close: 38095.84,
    },
    {
      date: '2024-02-13 14:30:00',
      close: 38065.07,
    },
    {
      date: '2024-02-13 15:00:00',
      close: 38086.03,
    },
    {
      date: '2024-02-13 15:30:00',
      close: 38275.33,
    },
    {
      date: '2024-02-14 09:30:00',
      close: 38325.01,
    },
    {
      date: '2024-02-14 10:00:00',
      close: 38388.77,
    },
    {
      date: '2024-02-14 10:30:00',
      close: 38294.12,
    },
    {
      date: '2024-02-14 11:00:00',
      close: 38320.44,
    },
    {
      date: '2024-02-14 11:30:00',
      close: 38252.82,
    },
    {
      date: '2024-02-14 12:00:00',
      close: 38278.33,
    },
    {
      date: '2024-02-14 12:30:00',
      close: 38296.32,
    },
    {
      date: '2024-02-14 13:00:00',
      close: 38276.27,
    },
    {
      date: '2024-02-14 13:30:00',
      close: 38313,
    },
    {
      date: '2024-02-14 14:00:00',
      close: 38318.11,
    },
    {
      date: '2024-02-14 14:30:00',
      close: 38317.76,
    },
    {
      date: '2024-02-14 15:00:00',
      close: 38396.06,
    },
    {
      date: '2024-02-14 15:30:00',
      close: 38418.08,
    },
    {
      date: '2024-02-15 09:30:00',
      close: 38583.4,
    },
    {
      date: '2024-02-15 10:00:00',
      close: 38603.73,
    },
    {
      date: '2024-02-15 10:30:00',
      close: 38592.96,
    },
    {
      date: '2024-02-15 11:00:00',
      close: 38566.01,
    },
    {
      date: '2024-02-15 11:30:00',
      close: 38608.03,
    },
    {
      date: '2024-02-15 12:00:00',
      close: 38633.5,
    },
    {
      date: '2024-02-15 12:30:00',
      close: 38606.51,
    },
    {
      date: '2024-02-15 13:00:00',
      close: 38687.82,
    },
    {
      date: '2024-02-15 13:30:00',
      close: 38686.48,
    },
    {
      date: '2024-02-15 14:00:00',
      close: 38709.72,
    },
    {
      date: '2024-02-15 14:30:00',
      close: 38751.66,
    },
    {
      date: '2024-02-15 15:00:00',
      close: 38755.35,
    },
    {
      date: '2024-02-15 15:30:00',
      close: 38771.82,
    },
    {
      date: '2024-02-16 09:30:00',
      close: 38617.96,
    },
    {
      date: '2024-02-16 10:00:00',
      close: 38687.25,
    },
    {
      date: '2024-02-16 10:30:00',
      close: 38692.27,
    },
    {
      date: '2024-02-16 11:00:00',
      close: 38728.87,
    },
    {
      date: '2024-02-16 11:30:00',
      close: 38765.57,
    },
    {
      date: '2024-02-16 12:00:00',
      close: 38774.86,
    },
    {
      date: '2024-02-16 12:30:00',
      close: 38756.99,
    },
    {
      date: '2024-02-16 13:00:00',
      close: 38751.19,
    },
    {
      date: '2024-02-16 13:30:00',
      close: 38791,
    },
    {
      date: '2024-02-16 14:00:00',
      close: 38804.71,
    },
    {
      date: '2024-02-16 14:30:00',
      close: 38664.72,
    },
    {
      date: '2024-02-16 15:00:00',
      close: 38685.7,
    },
    {
      date: '2024-02-16 15:30:00',
      close: 38623.64,
    },
    {
      date: '2024-02-20 09:30:00',
      close: 38611.54,
    },
    {
      date: '2024-02-20 10:00:00',
      close: 38625.53,
    },
    {
      date: '2024-02-20 10:30:00',
      close: 38645.71,
    },
    {
      date: '2024-02-20 11:00:00',
      close: 38612.99,
    },
    {
      date: '2024-02-20 11:30:00',
      close: 38539.6,
    },
    {
      date: '2024-02-20 12:00:00',
      close: 38488.02,
    },
    {
      date: '2024-02-20 12:30:00',
      close: 38526.47,
    },
    {
      date: '2024-02-20 13:00:00',
      close: 38520.1,
    },
    {
      date: '2024-02-20 13:30:00',
      close: 38565.1,
    },
    {
      date: '2024-02-20 14:00:00',
      close: 38559.96,
    },
    {
      date: '2024-02-20 14:30:00',
      close: 38600.75,
    },
    {
      date: '2024-02-20 15:00:00',
      close: 38590.92,
    },
    {
      date: '2024-02-20 15:30:00',
      close: 38576.29,
    },
    {
      date: '2024-02-21 09:30:00',
      close: 38446.09,
    },
    {
      date: '2024-02-21 10:00:00',
      close: 38490.94,
    },
    {
      date: '2024-02-21 10:30:00',
      close: 38476.1,
    },
    {
      date: '2024-02-21 11:00:00',
      close: 38510.63,
    },
    {
      date: '2024-02-21 11:30:00',
      close: 38505.67,
    },
    {
      date: '2024-02-21 12:00:00',
      close: 38474,
    },
    {
      date: '2024-02-21 12:30:00',
      close: 38506.21,
    },
    {
      date: '2024-02-21 13:00:00',
      close: 38509.82,
    },
    {
      date: '2024-02-21 13:30:00',
      close: 38512.2,
    },
    {
      date: '2024-02-21 14:00:00',
      close: 38449.29,
    },
    {
      date: '2024-02-21 14:30:00',
      close: 38438.31,
    },
    {
      date: '2024-02-21 15:00:00',
      close: 38390.73,
    },
    {
      date: '2024-02-21 15:30:00',
      close: 38614.05,
    },
    {
      date: '2024-02-22 09:30:00',
      close: 38958.42,
    },
    {
      date: '2024-02-22 10:00:00',
      close: 38898.45,
    },
    {
      date: '2024-02-22 10:30:00',
      close: 38846.19,
    },
    {
      date: '2024-02-22 11:00:00',
      close: 38881,
    },
    {
      date: '2024-02-22 11:30:00',
      close: 38865.73,
    },
    {
      date: '2024-02-22 12:00:00',
      close: 38921.22,
    },
    {
      date: '2024-02-22 12:30:00',
      close: 38951.92,
    },
    {
      date: '2024-02-22 13:00:00',
      close: 38994.88,
    },
    {
      date: '2024-02-22 13:30:00',
      close: 39028.41,
    },
    {
      date: '2024-02-22 14:00:00',
      close: 39142.15,
    },
    {
      date: '2024-02-22 14:30:00',
      close: 39025.17,
    },
    {
      date: '2024-02-22 15:00:00',
      close: 39054.45,
    },
    {
      date: '2024-02-22 15:30:00',
      close: 39061.65,
    },
    {
      date: '2024-02-23 09:30:00',
      close: 39269.8,
    },
    {
      date: '2024-02-23 10:00:00',
      close: 39258.52,
    },
    {
      date: '2024-02-23 10:30:00',
      close: 39265.45,
    },
    {
      date: '2024-02-23 11:00:00',
      close: 39202.98,
    },
    {
      date: '2024-02-23 11:30:00',
      close: 39198.03,
    },
    {
      date: '2024-02-23 12:00:00',
      close: 39173.75,
    },
    {
      date: '2024-02-23 12:30:00',
      close: 39170.51,
    },
    {
      date: '2024-02-23 13:00:00',
      close: 39189.37,
    },
    {
      date: '2024-02-23 13:30:00',
      close: 39153.16,
    },
    {
      date: '2024-02-23 14:00:00',
      close: 39099.95,
    },
    {
      date: '2024-02-23 14:30:00',
      close: 39138.3,
    },
    {
      date: '2024-02-23 15:00:00',
      close: 39153.72,
    },
    {
      date: '2024-02-23 15:30:00',
      close: 39128.96,
    },
    {
      date: '2024-02-26 09:30:00',
      close: 39228.38,
    },
    {
      date: '2024-02-26 10:00:00',
      close: 39195.93,
    },
    {
      date: '2024-02-26 10:30:00',
      close: 39144.63,
    },
    {
      date: '2024-02-26 11:00:00',
      close: 39142.34,
    },
    {
      date: '2024-02-26 11:30:00',
      close: 39160.79,
    },
    {
      date: '2024-02-26 12:00:00',
      close: 39156.91,
    },
    {
      date: '2024-02-26 12:30:00',
      close: 39119.88,
    },
    {
      date: '2024-02-26 13:00:00',
      close: 39084.62,
    },
    {
      date: '2024-02-26 13:30:00',
      close: 39089.81,
    },
    {
      date: '2024-02-26 14:00:00',
      close: 39119.46,
    },
    {
      date: '2024-02-26 14:30:00',
      close: 39104.96,
    },
    {
      date: '2024-02-26 15:00:00',
      close: 39117.15,
    },
    {
      date: '2024-02-26 15:30:00',
      close: 39063.55,
    },
    {
      date: '2024-02-27 09:30:00',
      close: 38957.89,
    },
    {
      date: '2024-02-27 10:00:00',
      close: 38961.87,
    },
    {
      date: '2024-02-27 10:30:00',
      close: 38938.98,
    },
    {
      date: '2024-02-27 11:00:00',
      close: 38910.63,
    },
    {
      date: '2024-02-27 11:30:00',
      close: 38897.55,
    },
    {
      date: '2024-02-27 12:00:00',
      close: 38899.82,
    },
    {
      date: '2024-02-27 12:30:00',
      close: 38895.03,
    },
    {
      date: '2024-02-27 13:00:00',
      close: 38903.72,
    },
    {
      date: '2024-02-27 13:30:00',
      close: 38923.97,
    },
    {
      date: '2024-02-27 14:00:00',
      close: 38940.27,
    },
    {
      date: '2024-02-27 14:30:00',
      close: 38954.41,
    },
    {
      date: '2024-02-27 15:00:00',
      close: 38975.6,
    },
    {
      date: '2024-02-27 15:30:00',
      close: 38973.53,
    },
    {
      date: '2024-02-28 09:30:00',
      close: 38775.14,
    },
    {
      date: '2024-02-28 10:00:00',
      close: 38838.24,
    },
    {
      date: '2024-02-28 10:30:00',
      close: 38903.6,
    },
    {
      date: '2024-02-28 11:00:00',
      close: 38899.46,
    },
    {
      date: '2024-02-28 11:30:00',
      close: 38895.38,
    },
    {
      date: '2024-02-28 12:00:00',
      close: 38900.38,
    },
    {
      date: '2024-02-28 12:30:00',
      close: 38872.2,
    },
    {
      date: '2024-02-28 13:00:00',
      close: 38920.3,
    },
    {
      date: '2024-02-28 13:30:00',
      close: 38921.93,
    },
    {
      date: '2024-02-28 14:00:00',
      close: 38851.92,
    },
    {
      date: '2024-02-28 14:30:00',
      close: 38886.85,
    },
    {
      date: '2024-02-28 15:00:00',
      close: 38928.36,
    },
    {
      date: '2024-02-28 15:30:00',
      close: 38953.09,
    },
    {
      date: '2024-02-29 09:30:00',
      close: 38995.69,
    },
    {
      date: '2024-02-29 10:00:00',
      close: 38938.47,
    },
    {
      date: '2024-02-29 10:30:00',
      close: 38934.03,
    },
    {
      date: '2024-02-29 11:00:00',
      close: 38946.92,
    },
    {
      date: '2024-02-29 11:30:00',
      close: 38871.95,
    },
    {
      date: '2024-02-29 12:00:00',
      close: 38861.07,
    },
    {
      date: '2024-02-29 12:30:00',
      close: 38924.71,
    },
    {
      date: '2024-02-29 13:00:00',
      close: 38922.61,
    },
    {
      date: '2024-02-29 13:30:00',
      close: 38917.57,
    },
    {
      date: '2024-02-29 14:00:00',
      close: 38915.34,
    },
    {
      date: '2024-02-29 14:30:00',
      close: 38927.93,
    },
    {
      date: '2024-02-29 15:00:00',
      close: 38957.9,
    },
    {
      date: '2024-02-29 15:30:00',
      close: 38968.48,
    },
    {
      date: '2024-03-01 09:30:00',
      close: 38877.88,
    },
    {
      date: '2024-03-01 10:00:00',
      close: 39015.28,
    },
    {
      date: '2024-03-01 10:30:00',
      close: 39029.97,
    },
    {
      date: '2024-03-01 11:00:00',
      close: 39006.05,
    },
    {
      date: '2024-03-01 11:30:00',
      close: 39033.26,
    },
    {
      date: '2024-03-01 12:00:00',
      close: 39065.16,
    },
    {
      date: '2024-03-01 12:30:00',
      close: 39061.5,
    },
    {
      date: '2024-03-01 13:00:00',
      close: 39067.44,
    },
    {
      date: '2024-03-01 13:30:00',
      close: 39075.35,
    },
    {
      date: '2024-03-01 14:00:00',
      close: 39097.36,
    },
    {
      date: '2024-03-01 14:30:00',
      close: 39081.5,
    },
    {
      date: '2024-03-01 15:00:00',
      close: 39082.83,
    },
    {
      date: '2024-03-01 15:30:00',
      close: 39086.82,
    },
    {
      date: '2024-03-04 09:30:00',
      close: 39015.29,
    },
    {
      date: '2024-03-04 10:00:00',
      close: 39028.46,
    },
    {
      date: '2024-03-04 10:30:00',
      close: 38990.02,
    },
    {
      date: '2024-03-04 11:00:00',
      close: 38976.47,
    },
    {
      date: '2024-03-04 11:30:00',
      close: 39011.48,
    },
    {
      date: '2024-03-04 12:00:00',
      close: 39055.54,
    },
    {
      date: '2024-03-04 12:30:00',
      close: 39047.07,
    },
    {
      date: '2024-03-04 13:00:00',
      close: 38993.59,
    },
    {
      date: '2024-03-04 13:30:00',
      close: 38994.64,
    },
    {
      date: '2024-03-04 14:00:00',
      close: 39010.82,
    },
    {
      date: '2024-03-04 14:30:00',
      close: 39064,
    },
    {
      date: '2024-03-04 15:00:00',
      close: 39025.57,
    },
    {
      date: '2024-03-04 15:30:00',
      close: 38992.96,
    },
    {
      date: '2024-03-05 09:30:00',
      close: 38797.52,
    },
    {
      date: '2024-03-05 10:00:00',
      close: 38827.01,
    },
    {
      date: '2024-03-05 10:30:00',
      close: 38758.76,
    },
    {
      date: '2024-03-05 11:00:00',
      close: 38743.38,
    },
    {
      date: '2024-03-05 11:30:00',
      close: 38698.67,
    },
    {
      date: '2024-03-05 12:00:00',
      close: 38751.95,
    },
    {
      date: '2024-03-05 12:30:00',
      close: 38696.34,
    },
    {
      date: '2024-03-05 13:00:00',
      close: 38694.24,
    },
    {
      date: '2024-03-05 13:30:00',
      close: 38613.26,
    },
    {
      date: '2024-03-05 14:00:00',
      close: 38627.06,
    },
    {
      date: '2024-03-05 14:30:00',
      close: 38518.06,
    },
    {
      date: '2024-03-05 15:00:00',
      close: 38465.06,
    },
    {
      date: '2024-03-05 15:30:00',
      close: 38583.65,
    },
    {
      date: '2024-03-06 09:30:00',
      close: 38740.58,
    },
    {
      date: '2024-03-06 10:00:00',
      close: 38708.22,
    },
    {
      date: '2024-03-06 10:30:00',
      close: 38756.12,
    },
    {
      date: '2024-03-06 11:00:00',
      close: 38839.4,
    },
    {
      date: '2024-03-06 11:30:00',
      close: 38806.29,
    },
    {
      date: '2024-03-06 12:00:00',
      close: 38802.18,
    },
    {
      date: '2024-03-06 12:30:00',
      close: 38761.71,
    },
    {
      date: '2024-03-06 13:00:00',
      close: 38793.84,
    },
    {
      date: '2024-03-06 13:30:00',
      close: 38686.41,
    },
    {
      date: '2024-03-06 14:00:00',
      close: 38662.27,
    },
    {
      date: '2024-03-06 14:30:00',
      close: 38611.46,
    },
    {
      date: '2024-03-06 15:00:00',
      close: 38617.06,
    },
    {
      date: '2024-03-06 15:30:00',
      close: 38662.56,
    },
    {
      date: '2024-03-07 09:30:00',
      close: 38850.32,
    },
    {
      date: '2024-03-07 10:00:00',
      close: 38830.04,
    },
    {
      date: '2024-03-07 10:30:00',
      close: 38864.04,
    },
    {
      date: '2024-03-07 11:00:00',
      close: 38794.02,
    },
    {
      date: '2024-03-07 11:30:00',
      close: 38825.54,
    },
    {
      date: '2024-03-07 12:00:00',
      close: 38777.39,
    },
    {
      date: '2024-03-07 12:30:00',
      close: 38780.4,
    },
    {
      date: '2024-03-07 13:00:00',
      close: 38827.97,
    },
    {
      date: '2024-03-07 13:30:00',
      close: 38870.84,
    },
    {
      date: '2024-03-07 14:00:00',
      close: 38798.34,
    },
    {
      date: '2024-03-07 14:30:00',
      close: 38777.68,
    },
    {
      date: '2024-03-07 15:00:00',
      close: 38761.05,
    },
    {
      date: '2024-03-07 15:30:00',
      close: 38789.84,
    },
  ],
  IXIC: [
    {
      date: '2024-02-08 09:30:00',
      close: 15775.44,
    },
    {
      date: '2024-02-08 10:00:00',
      close: 15772.97,
    },
    {
      date: '2024-02-08 10:30:00',
      close: 15810.94,
    },
    {
      date: '2024-02-08 11:00:00',
      close: 15784.01,
    },
    {
      date: '2024-02-08 11:30:00',
      close: 15802.47,
    },
    {
      date: '2024-02-08 12:00:00',
      close: 15808.79,
    },
    {
      date: '2024-02-08 12:30:00',
      close: 15777.07,
    },
    {
      date: '2024-02-08 13:00:00',
      close: 15801.75,
    },
    {
      date: '2024-02-08 13:30:00',
      close: 15797.01,
    },
    {
      date: '2024-02-08 14:00:00',
      close: 15780.83,
    },
    {
      date: '2024-02-08 14:30:00',
      close: 15795,
    },
    {
      date: '2024-02-08 15:00:00',
      close: 15802.34,
    },
    {
      date: '2024-02-08 15:30:00',
      close: 15794.52,
    },
    {
      date: '2024-02-09 09:30:00',
      close: 15877.56,
    },
    {
      date: '2024-02-09 10:00:00',
      close: 15904.56,
    },
    {
      date: '2024-02-09 10:30:00',
      close: 15931.59,
    },
    {
      date: '2024-02-09 11:00:00',
      close: 15926.25,
    },
    {
      date: '2024-02-09 11:30:00',
      close: 15938.92,
    },
    {
      date: '2024-02-09 12:00:00',
      close: 15949.15,
    },
    {
      date: '2024-02-09 12:30:00',
      close: 15971.44,
    },
    {
      date: '2024-02-09 13:00:00',
      close: 15983.22,
    },
    {
      date: '2024-02-09 13:30:00',
      close: 15981.37,
    },
    {
      date: '2024-02-09 14:00:00',
      close: 15991.67,
    },
    {
      date: '2024-02-09 14:30:00',
      close: 15994.13,
    },
    {
      date: '2024-02-09 15:00:00',
      close: 15985.6,
    },
    {
      date: '2024-02-09 15:30:00',
      close: 15988.09,
    },
    {
      date: '2024-02-12 09:30:00',
      close: 16009.75,
    },
    {
      date: '2024-02-12 10:00:00',
      close: 16001.22,
    },
    {
      date: '2024-02-12 10:30:00',
      close: 16046.34,
    },
    {
      date: '2024-02-12 11:00:00',
      close: 16055.24,
    },
    {
      date: '2024-02-12 11:30:00',
      close: 16062.62,
    },
    {
      date: '2024-02-12 12:00:00',
      close: 16037.75,
    },
    {
      date: '2024-02-12 12:30:00',
      close: 16060.86,
    },
    {
      date: '2024-02-12 13:00:00',
      close: 16021.16,
    },
    {
      date: '2024-02-12 13:30:00',
      close: 15992.1,
    },
    {
      date: '2024-02-12 14:00:00',
      close: 15954.34,
    },
    {
      date: '2024-02-12 14:30:00',
      close: 15929.45,
    },
    {
      date: '2024-02-12 15:00:00',
      close: 15957.78,
    },
    {
      date: '2024-02-12 15:30:00',
      close: 15947.92,
    },
    {
      date: '2024-02-13 09:30:00',
      close: 15671.61,
    },
    {
      date: '2024-02-13 10:00:00',
      close: 15725.56,
    },
    {
      date: '2024-02-13 10:30:00',
      close: 15724.83,
    },
    {
      date: '2024-02-13 11:00:00',
      close: 15751.05,
    },
    {
      date: '2024-02-13 11:30:00',
      close: 15721.51,
    },
    {
      date: '2024-02-13 12:00:00',
      close: 15688.92,
    },
    {
      date: '2024-02-13 12:30:00',
      close: 15677.18,
    },
    {
      date: '2024-02-13 13:00:00',
      close: 15685.79,
    },
    {
      date: '2024-02-13 13:30:00',
      close: 15662.46,
    },
    {
      date: '2024-02-13 14:00:00',
      close: 15621.3,
    },
    {
      date: '2024-02-13 14:30:00',
      close: 15581.91,
    },
    {
      date: '2024-02-13 15:00:00',
      close: 15572.06,
    },
    {
      date: '2024-02-13 15:30:00',
      close: 15658.7,
    },
    {
      date: '2024-02-14 09:30:00',
      close: 15766.45,
    },
    {
      date: '2024-02-14 10:00:00',
      close: 15806.37,
    },
    {
      date: '2024-02-14 10:30:00',
      close: 15731.25,
    },
    {
      date: '2024-02-14 11:00:00',
      close: 15739.14,
    },
    {
      date: '2024-02-14 11:30:00',
      close: 15715.89,
    },
    {
      date: '2024-02-14 12:00:00',
      close: 15746.23,
    },
    {
      date: '2024-02-14 12:30:00',
      close: 15757.78,
    },
    {
      date: '2024-02-14 13:00:00',
      close: 15744.91,
    },
    {
      date: '2024-02-14 13:30:00',
      close: 15771.57,
    },
    {
      date: '2024-02-14 14:00:00',
      close: 15771.28,
    },
    {
      date: '2024-02-14 14:30:00',
      close: 15785.4,
    },
    {
      date: '2024-02-14 15:00:00',
      close: 15823.63,
    },
    {
      date: '2024-02-14 15:30:00',
      close: 15861.59,
    },
    {
      date: '2024-02-15 09:30:00',
      close: 15812.43,
    },
    {
      date: '2024-02-15 10:00:00',
      close: 15821.54,
    },
    {
      date: '2024-02-15 10:30:00',
      close: 15830.93,
    },
    {
      date: '2024-02-15 11:00:00',
      close: 15820.06,
    },
    {
      date: '2024-02-15 11:30:00',
      close: 15823.49,
    },
    {
      date: '2024-02-15 12:00:00',
      close: 15851.98,
    },
    {
      date: '2024-02-15 12:30:00',
      close: 15839.29,
    },
    {
      date: '2024-02-15 13:00:00',
      close: 15895.36,
    },
    {
      date: '2024-02-15 13:30:00',
      close: 15890.13,
    },
    {
      date: '2024-02-15 14:00:00',
      close: 15889,
    },
    {
      date: '2024-02-15 14:30:00',
      close: 15894.44,
    },
    {
      date: '2024-02-15 15:00:00',
      close: 15885.39,
    },
    {
      date: '2024-02-15 15:30:00',
      close: 15907.26,
    },
    {
      date: '2024-02-16 09:30:00',
      close: 15791.73,
    },
    {
      date: '2024-02-16 10:00:00',
      close: 15796.04,
    },
    {
      date: '2024-02-16 10:30:00',
      close: 15832,
    },
    {
      date: '2024-02-16 11:00:00',
      close: 15880.62,
    },
    {
      date: '2024-02-16 11:30:00',
      close: 15889.22,
    },
    {
      date: '2024-02-16 12:00:00',
      close: 15885.66,
    },
    {
      date: '2024-02-16 12:30:00',
      close: 15867.78,
    },
    {
      date: '2024-02-16 13:00:00',
      close: 15847.98,
    },
    {
      date: '2024-02-16 13:30:00',
      close: 15881.57,
    },
    {
      date: '2024-02-16 14:00:00',
      close: 15896.33,
    },
    {
      date: '2024-02-16 14:30:00',
      close: 15815.35,
    },
    {
      date: '2024-02-16 15:00:00',
      close: 15813.74,
    },
    {
      date: '2024-02-16 15:30:00',
      close: 15773.79,
    },
    {
      date: '2024-02-20 09:30:00',
      close: 15599.32,
    },
    {
      date: '2024-02-20 10:00:00',
      close: 15549.97,
    },
    {
      date: '2024-02-20 10:30:00',
      close: 15608.91,
    },
    {
      date: '2024-02-20 11:00:00',
      close: 15608.36,
    },
    {
      date: '2024-02-20 11:30:00',
      close: 15553.31,
    },
    {
      date: '2024-02-20 12:00:00',
      close: 15525.88,
    },
    {
      date: '2024-02-20 12:30:00',
      close: 15547.32,
    },
    {
      date: '2024-02-20 13:00:00',
      close: 15538.83,
    },
    {
      date: '2024-02-20 13:30:00',
      close: 15569.57,
    },
    {
      date: '2024-02-20 14:00:00',
      close: 15571.3,
    },
    {
      date: '2024-02-20 14:30:00',
      close: 15601.01,
    },
    {
      date: '2024-02-20 15:00:00',
      close: 15614.22,
    },
    {
      date: '2024-02-20 15:30:00',
      close: 15635.1,
    },
    {
      date: '2024-02-21 09:30:00',
      close: 15541.57,
    },
    {
      date: '2024-02-21 10:00:00',
      close: 15536.25,
    },
    {
      date: '2024-02-21 10:30:00',
      close: 15532.94,
    },
    {
      date: '2024-02-21 11:00:00',
      close: 15550.54,
    },
    {
      date: '2024-02-21 11:30:00',
      close: 15558.22,
    },
    {
      date: '2024-02-21 12:00:00',
      close: 15505.15,
    },
    {
      date: '2024-02-21 12:30:00',
      close: 15529.53,
    },
    {
      date: '2024-02-21 13:00:00',
      close: 15512.82,
    },
    {
      date: '2024-02-21 13:30:00',
      close: 15514.35,
    },
    {
      date: '2024-02-21 14:00:00',
      close: 15483.49,
    },
    {
      date: '2024-02-21 14:30:00',
      close: 15477.71,
    },
    {
      date: '2024-02-21 15:00:00',
      close: 15482.92,
    },
    {
      date: '2024-02-21 15:30:00',
      close: 15580.51,
    },
    {
      date: '2024-02-22 09:30:00',
      close: 15940.65,
    },
    {
      date: '2024-02-22 10:00:00',
      close: 15957.54,
    },
    {
      date: '2024-02-22 10:30:00',
      close: 15924.6,
    },
    {
      date: '2024-02-22 11:00:00',
      close: 15952.72,
    },
    {
      date: '2024-02-22 11:30:00',
      close: 15947.34,
    },
    {
      date: '2024-02-22 12:00:00',
      close: 15978.32,
    },
    {
      date: '2024-02-22 12:30:00',
      close: 16002.72,
    },
    {
      date: '2024-02-22 13:00:00',
      close: 16003.95,
    },
    {
      date: '2024-02-22 13:30:00',
      close: 16031.03,
    },
    {
      date: '2024-02-22 14:00:00',
      close: 16057.29,
    },
    {
      date: '2024-02-22 14:30:00',
      close: 16025.91,
    },
    {
      date: '2024-02-22 15:00:00',
      close: 16042,
    },
    {
      date: '2024-02-22 15:30:00',
      close: 16036.06,
    },
    {
      date: '2024-02-23 09:30:00',
      close: 16073.55,
    },
    {
      date: '2024-02-23 10:00:00',
      close: 16044.88,
    },
    {
      date: '2024-02-23 10:30:00',
      close: 16018.57,
    },
    {
      date: '2024-02-23 11:00:00',
      close: 16006.99,
    },
    {
      date: '2024-02-23 11:30:00',
      close: 16034.09,
    },
    {
      date: '2024-02-23 12:00:00',
      close: 16024.18,
    },
    {
      date: '2024-02-23 12:30:00',
      close: 16044.58,
    },
    {
      date: '2024-02-23 13:00:00',
      close: 16052.76,
    },
    {
      date: '2024-02-23 13:30:00',
      close: 16036.98,
    },
    {
      date: '2024-02-23 14:00:00',
      close: 16003.68,
    },
    {
      date: '2024-02-23 14:30:00',
      close: 16018.13,
    },
    {
      date: '2024-02-23 15:00:00',
      close: 15983.84,
    },
    {
      date: '2024-02-23 15:30:00',
      close: 15996.35,
    },
    {
      date: '2024-02-26 09:30:00',
      close: 15992.91,
    },
    {
      date: '2024-02-26 10:00:00',
      close: 16024.74,
    },
    {
      date: '2024-02-26 10:30:00',
      close: 16018.63,
    },
    {
      date: '2024-02-26 11:00:00',
      close: 16008.89,
    },
    {
      date: '2024-02-26 11:30:00',
      close: 16030.56,
    },
    {
      date: '2024-02-26 12:00:00',
      close: 16032.37,
    },
    {
      date: '2024-02-26 12:30:00',
      close: 16014.32,
    },
    {
      date: '2024-02-26 13:00:00',
      close: 15992.13,
    },
    {
      date: '2024-02-26 13:30:00',
      close: 16006.76,
    },
    {
      date: '2024-02-26 14:00:00',
      close: 16020.74,
    },
    {
      date: '2024-02-26 14:30:00',
      close: 16004.53,
    },
    {
      date: '2024-02-26 15:00:00',
      close: 16001.64,
    },
    {
      date: '2024-02-26 15:30:00',
      close: 15975.15,
    },
    {
      date: '2024-02-27 09:30:00',
      close: 15989.29,
    },
    {
      date: '2024-02-27 10:00:00',
      close: 16009.82,
    },
    {
      date: '2024-02-27 10:30:00',
      close: 16014.94,
    },
    {
      date: '2024-02-27 11:00:00',
      close: 15995.47,
    },
    {
      date: '2024-02-27 11:30:00',
      close: 16012.84,
    },
    {
      date: '2024-02-27 12:00:00',
      close: 15994.49,
    },
    {
      date: '2024-02-27 12:30:00',
      close: 15952.04,
    },
    {
      date: '2024-02-27 13:00:00',
      close: 15967.61,
    },
    {
      date: '2024-02-27 13:30:00',
      close: 15990.95,
    },
    {
      date: '2024-02-27 14:00:00',
      close: 16028.81,
    },
    {
      date: '2024-02-27 14:30:00',
      close: 16043.89,
    },
    {
      date: '2024-02-27 15:00:00',
      close: 16041.09,
    },
    {
      date: '2024-02-27 15:30:00',
      close: 16037.65,
    },
    {
      date: '2024-02-28 09:30:00',
      close: 15936.29,
    },
    {
      date: '2024-02-28 10:00:00',
      close: 15943.38,
    },
    {
      date: '2024-02-28 10:30:00',
      close: 15962.94,
    },
    {
      date: '2024-02-28 11:00:00',
      close: 15989.33,
    },
    {
      date: '2024-02-28 11:30:00',
      close: 15993.75,
    },
    {
      date: '2024-02-28 12:00:00',
      close: 15970.04,
    },
    {
      date: '2024-02-28 12:30:00',
      close: 15957.28,
    },
    {
      date: '2024-02-28 13:00:00',
      close: 15970.89,
    },
    {
      date: '2024-02-28 13:30:00',
      close: 15978.6,
    },
    {
      date: '2024-02-28 14:00:00',
      close: 15948.18,
    },
    {
      date: '2024-02-28 14:30:00',
      close: 15950.78,
    },
    {
      date: '2024-02-28 15:00:00',
      close: 15959.2,
    },
    {
      date: '2024-02-28 15:30:00',
      close: 15948.57,
    },
    {
      date: '2024-02-29 09:30:00',
      close: 16082.36,
    },
    {
      date: '2024-02-29 10:00:00',
      close: 16050.08,
    },
    {
      date: '2024-02-29 10:30:00',
      close: 16025.93,
    },
    {
      date: '2024-02-29 11:00:00',
      close: 16000.73,
    },
    {
      date: '2024-02-29 11:30:00',
      close: 15998.35,
    },
    {
      date: '2024-02-29 12:00:00',
      close: 15987.76,
    },
    {
      date: '2024-02-29 12:30:00',
      close: 16023.18,
    },
    {
      date: '2024-02-29 13:00:00',
      close: 16046.51,
    },
    {
      date: '2024-02-29 13:30:00',
      close: 16040.46,
    },
    {
      date: '2024-02-29 14:00:00',
      close: 16033.49,
    },
    {
      date: '2024-02-29 14:30:00',
      close: 16038.18,
    },
    {
      date: '2024-02-29 15:00:00',
      close: 16054.89,
    },
    {
      date: '2024-02-29 15:30:00',
      close: 16088.89,
    },
    {
      date: '2024-03-01 09:30:00',
      close: 16120.26,
    },
    {
      date: '2024-03-01 10:00:00',
      close: 16161.74,
    },
    {
      date: '2024-03-01 10:30:00',
      close: 16168.99,
    },
    {
      date: '2024-03-01 11:00:00',
      close: 16199.79,
    },
    {
      date: '2024-03-01 11:30:00',
      close: 16201.1,
    },
    {
      date: '2024-03-01 12:00:00',
      close: 16220.32,
    },
    {
      date: '2024-03-01 12:30:00',
      close: 16238.66,
    },
    {
      date: '2024-03-01 13:00:00',
      close: 16259.19,
    },
    {
      date: '2024-03-01 13:30:00',
      close: 16282.91,
    },
    {
      date: '2024-03-01 14:00:00',
      close: 16299.58,
    },
    {
      date: '2024-03-01 14:30:00',
      close: 16297.11,
    },
    {
      date: '2024-03-01 15:00:00',
      close: 16277.31,
    },
    {
      date: '2024-03-01 15:30:00',
      close: 16276.75,
    },
    {
      date: '2024-03-04 09:30:00',
      close: 16241.99,
    },
    {
      date: '2024-03-04 10:00:00',
      close: 16220.66,
    },
    {
      date: '2024-03-04 10:30:00',
      close: 16250.71,
    },
    {
      date: '2024-03-04 11:00:00',
      close: 16237.92,
    },
    {
      date: '2024-03-04 11:30:00',
      close: 16243.78,
    },
    {
      date: '2024-03-04 12:00:00',
      close: 16256.99,
    },
    {
      date: '2024-03-04 12:30:00',
      close: 16246.36,
    },
    {
      date: '2024-03-04 13:00:00',
      close: 16230.76,
    },
    {
      date: '2024-03-04 13:30:00',
      close: 16245.23,
    },
    {
      date: '2024-03-04 14:00:00',
      close: 16245.97,
    },
    {
      date: '2024-03-04 14:30:00',
      close: 16278.41,
    },
    {
      date: '2024-03-04 15:00:00',
      close: 16265.07,
    },
    {
      date: '2024-03-04 15:30:00',
      close: 16207.99,
    },
    {
      date: '2024-03-05 09:30:00',
      close: 16024.2,
    },
    {
      date: '2024-03-05 10:00:00',
      close: 15947.14,
    },
    {
      date: '2024-03-05 10:30:00',
      close: 15928.97,
    },
    {
      date: '2024-03-05 11:00:00',
      close: 15947.39,
    },
    {
      date: '2024-03-05 11:30:00',
      close: 15939.79,
    },
    {
      date: '2024-03-05 12:00:00',
      close: 15981.35,
    },
    {
      date: '2024-03-05 12:30:00',
      close: 15965.25,
    },
    {
      date: '2024-03-05 13:00:00',
      close: 15940.56,
    },
    {
      date: '2024-03-05 13:30:00',
      close: 15904.12,
    },
    {
      date: '2024-03-05 14:00:00',
      close: 15918.75,
    },
    {
      date: '2024-03-05 14:30:00',
      close: 15880.93,
    },
    {
      date: '2024-03-05 15:00:00',
      close: 15866.84,
    },
    {
      date: '2024-03-05 15:30:00',
      close: 15941.19,
    },
    {
      date: '2024-03-06 09:30:00',
      close: 16029.84,
    },
    {
      date: '2024-03-06 10:00:00',
      close: 16003.14,
    },
    {
      date: '2024-03-06 10:30:00',
      close: 16050.24,
    },
    {
      date: '2024-03-06 11:00:00',
      close: 16071.69,
    },
    {
      date: '2024-03-06 11:30:00',
      close: 16087.19,
    },
    {
      date: '2024-03-06 12:00:00',
      close: 16115.47,
    },
    {
      date: '2024-03-06 12:30:00',
      close: 16098.93,
    },
    {
      date: '2024-03-06 13:00:00',
      close: 16118.88,
    },
    {
      date: '2024-03-06 13:30:00',
      close: 16056.87,
    },
    {
      date: '2024-03-06 14:00:00',
      close: 16060.76,
    },
    {
      date: '2024-03-06 14:30:00',
      close: 16021.1,
    },
    {
      date: '2024-03-06 15:00:00',
      close: 16009.37,
    },
    {
      date: '2024-03-06 15:30:00',
      close: 16031.07,
    },
    {
      date: '2024-03-07 09:30:00',
      close: 16158.49,
    },
    {
      date: '2024-03-07 10:00:00',
      close: 16200.92,
    },
    {
      date: '2024-03-07 10:30:00',
      close: 16212.93,
    },
    {
      date: '2024-03-07 11:00:00',
      close: 16241.91,
    },
    {
      date: '2024-03-07 11:30:00',
      close: 16247.84,
    },
    {
      date: '2024-03-07 12:00:00',
      close: 16255.97,
    },
    {
      date: '2024-03-07 12:30:00',
      close: 16259.18,
    },
    {
      date: '2024-03-07 13:00:00',
      close: 16278.22,
    },
    {
      date: '2024-03-07 13:30:00',
      close: 16302.44,
    },
    {
      date: '2024-03-07 14:00:00',
      close: 16291.55,
    },
    {
      date: '2024-03-07 14:30:00',
      close: 16280.03,
    },
    {
      date: '2024-03-07 15:00:00',
      close: 16263.3,
    },
    {
      date: '2024-03-07 15:30:00',
      close: 16271.78,
    },
  ],
}

export const newsData = [
  {
    date: '2024-02-01 04:00:00',
    stocktwitsPosts: 12,
    stocktwitsComments: 5,
    stocktwitsLikes: 7,
    stocktwitsImpressions: 3695,
  },
  {
    date: '2024-02-01 03:00:00',
    stocktwitsPosts: 16,
    stocktwitsComments: 8,
    stocktwitsLikes: 28,
    stocktwitsImpressions: 235162,
  },
  {
    date: '2024-02-01 02:00:00',
    stocktwitsPosts: 17,
    stocktwitsComments: 27,
    stocktwitsLikes: 61,
    stocktwitsImpressions: 108293,
  },
  {
    date: '2024-02-01 01:00:00',
    stocktwitsPosts: 26,
    stocktwitsComments: 26,
    stocktwitsLikes: 30,
    stocktwitsImpressions: 61552,
  },
  {
    date: '2024-02-01 00:00:00',
    stocktwitsPosts: 22,
    stocktwitsComments: 17,
    stocktwitsLikes: 46,
    stocktwitsImpressions: 28597,
  },
  {
    date: '2024-01-31 23:00:00',
    stocktwitsPosts: 25,
    stocktwitsComments: 6,
    stocktwitsLikes: 26,
    stocktwitsImpressions: 7187,
  },
  {
    date: '2024-01-31 22:00:00',
    stocktwitsPosts: 39,
    stocktwitsComments: 16,
    stocktwitsLikes: 45,
    stocktwitsImpressions: 54296,
  },
  {
    date: '2024-01-31 21:00:00',
    stocktwitsPosts: 67,
    stocktwitsComments: 32,
    stocktwitsLikes: 88,
    stocktwitsImpressions: 19266,
  },
  {
    date: '2024-01-31 20:00:00',
    stocktwitsPosts: 39,
    stocktwitsComments: 59,
    stocktwitsLikes: 73,
    stocktwitsImpressions: 58786,
  },
  {
    date: '2024-01-31 19:00:00',
    stocktwitsPosts: 76,
    stocktwitsComments: 29,
    stocktwitsLikes: 61,
    stocktwitsImpressions: 89641,
  },
  {
    date: '2024-01-31 18:00:00',
    stocktwitsPosts: 23,
    stocktwitsComments: 9,
    stocktwitsLikes: 33,
    stocktwitsImpressions: 234223,
  },
  {
    date: '2024-01-31 17:00:00',
    stocktwitsPosts: 40,
    stocktwitsComments: 21,
    stocktwitsLikes: 70,
    stocktwitsImpressions: 38666,
  },
  {
    date: '2024-01-31 16:00:00',
    stocktwitsPosts: 34,
    stocktwitsComments: 15,
    stocktwitsLikes: 57,
    stocktwitsImpressions: 16898,
  },
  {
    date: '2024-01-31 15:00:00',
    stocktwitsPosts: 55,
    stocktwitsComments: 22,
    stocktwitsLikes: 69,
    stocktwitsImpressions: 72663,
  },
  {
    date: '2024-01-31 14:00:00',
    stocktwitsPosts: 59,
    stocktwitsComments: 11,
    stocktwitsLikes: 35,
    stocktwitsImpressions: 71993,
  },
  {
    date: '2024-01-31 13:00:00',
    stocktwitsPosts: 20,
    stocktwitsComments: 2,
    stocktwitsLikes: 29,
    stocktwitsImpressions: 13147,
  },
  {
    date: '2024-01-31 12:00:00',
    stocktwitsPosts: 13,
    stocktwitsComments: 11,
    stocktwitsLikes: 17,
    stocktwitsImpressions: 23838,
  },
  {
    date: '2024-01-31 11:00:00',
    stocktwitsPosts: 13,
    stocktwitsComments: 10,
    stocktwitsLikes: 28,
    stocktwitsImpressions: 33336,
  },
  {
    date: '2024-01-31 10:00:00',
    stocktwitsPosts: 2,
    stocktwitsComments: 1,
    stocktwitsLikes: 2,
    stocktwitsImpressions: 66,
  },
  {
    date: '2024-01-31 09:00:00',
    stocktwitsPosts: 6,
    stocktwitsComments: 6,
    stocktwitsLikes: 4,
    stocktwitsImpressions: 451,
  },
  {
    date: '2024-01-31 08:00:00',
    stocktwitsPosts: 1,
    stocktwitsComments: 0,
    stocktwitsLikes: 0,
    stocktwitsImpressions: 66,
  },
  {
    date: '2024-01-31 07:00:00',
    stocktwitsPosts: 7,
    stocktwitsComments: 2,
    stocktwitsLikes: 6,
    stocktwitsImpressions: 299,
  },
  {
    date: '2024-01-31 06:00:00',
    stocktwitsPosts: 6,
    stocktwitsComments: 4,
    stocktwitsLikes: 10,
    stocktwitsImpressions: 5420,
  },
  {
    date: '2024-01-31 05:00:00',
    stocktwitsPosts: 8,
    stocktwitsComments: 0,
    stocktwitsLikes: 5,
    stocktwitsImpressions: 467,
  },
  {
    date: '2024-01-21 12:00:00',
    stocktwitsPosts: 7,
    stocktwitsComments: 0,
    stocktwitsLikes: 2,
    stocktwitsImpressions: 25757,
  },
  {
    date: '2024-01-21 11:00:00',
    stocktwitsPosts: 2,
    stocktwitsComments: 0,
    stocktwitsLikes: 0,
    stocktwitsImpressions: 556,
  },
  {
    date: '2024-01-21 10:00:00',
    stocktwitsPosts: 3,
    stocktwitsComments: 0,
    stocktwitsLikes: 0,
    stocktwitsImpressions: 0,
  },
  {
    date: '2024-01-21 09:00:00',
    stocktwitsPosts: 2,
    stocktwitsComments: 0,
    stocktwitsLikes: 1,
    stocktwitsImpressions: 536,
  },
  {
    date: '2024-01-21 08:00:00',
    stocktwitsPosts: 4,
    stocktwitsComments: 0,
    stocktwitsLikes: 2,
    stocktwitsImpressions: 11110,
  },
  {
    date: '2024-01-21 07:00:00',
    stocktwitsPosts: 3,
    stocktwitsComments: 0,
    stocktwitsLikes: 0,
    stocktwitsImpressions: 10,
  },
  {
    date: '2024-01-21 06:00:00',
    stocktwitsPosts: 2,
    stocktwitsComments: 0,
    stocktwitsLikes: 2,
    stocktwitsImpressions: 10940,
  },
  {
    date: '2024-01-21 05:00:00',
    stocktwitsPosts: 3,
    stocktwitsComments: 2,
    stocktwitsLikes: 8,
    stocktwitsImpressions: 2719,
  },
  {
    date: '2024-01-21 04:00:00',
    stocktwitsPosts: 4,
    stocktwitsComments: 2,
    stocktwitsLikes: 5,
    stocktwitsImpressions: 33615,
  },
  {
    date: '2024-01-21 03:00:00',
    stocktwitsPosts: 5,
    stocktwitsComments: 6,
    stocktwitsLikes: 9,
    stocktwitsImpressions: 15314,
  },
  {
    date: '2024-01-21 02:00:00',
    stocktwitsPosts: 9,
    stocktwitsComments: 5,
    stocktwitsLikes: 12,
    stocktwitsImpressions: 19715,
  },
  {
    date: '2024-01-21 01:00:00',
    stocktwitsPosts: 2,
    stocktwitsComments: 1,
    stocktwitsLikes: 6,
    stocktwitsImpressions: 2322,
  },
  {
    date: '2024-01-21 00:00:00',
    stocktwitsPosts: 6,
    stocktwitsComments: 9,
    stocktwitsLikes: 15,
    stocktwitsImpressions: 20177,
  },
  {
    date: '2024-01-20 23:00:00',
    stocktwitsPosts: 4,
    stocktwitsComments: 2,
    stocktwitsLikes: 1,
    stocktwitsImpressions: 33788,
  },
  {
    date: '2024-01-20 22:00:00',
    stocktwitsPosts: 2,
    stocktwitsComments: 0,
    stocktwitsLikes: 0,
    stocktwitsImpressions: 10905,
  },
  {
    date: '2024-01-20 21:00:00',
    stocktwitsPosts: 4,
    stocktwitsComments: 0,
    stocktwitsLikes: 6,
    stocktwitsImpressions: 15264,
  },
  {
    date: '2024-01-20 20:00:00',
    stocktwitsPosts: 4,
    stocktwitsComments: 3,
    stocktwitsLikes: 2,
    stocktwitsImpressions: 60,
  },
  {
    date: '2024-01-20 19:00:00',
    stocktwitsPosts: 14,
    stocktwitsComments: 25,
    stocktwitsLikes: 22,
    stocktwitsImpressions: 55216,
  },
  {
    date: '2024-01-20 18:00:00',
    stocktwitsPosts: 12,
    stocktwitsComments: 6,
    stocktwitsLikes: 9,
    stocktwitsImpressions: 38519,
  },
  {
    date: '2024-01-20 17:00:00',
    stocktwitsPosts: 12,
    stocktwitsComments: 3,
    stocktwitsLikes: 10,
    stocktwitsImpressions: 20045,
  },
  {
    date: '2024-01-20 16:00:00',
    stocktwitsPosts: 10,
    stocktwitsComments: 2,
    stocktwitsLikes: 5,
    stocktwitsImpressions: 40612,
  },
  {
    date: '2024-01-20 15:00:00',
    stocktwitsPosts: 6,
    stocktwitsComments: 13,
    stocktwitsLikes: 16,
    stocktwitsImpressions: 10357,
  },
  {
    date: '2024-01-20 14:00:00',
    stocktwitsPosts: 17,
    stocktwitsComments: 1,
    stocktwitsLikes: 9,
    stocktwitsImpressions: 46353,
  },
  {
    date: '2024-01-20 13:00:00',
    stocktwitsPosts: 12,
    stocktwitsComments: 28,
    stocktwitsLikes: 13,
    stocktwitsImpressions: 53161,
  },
  {
    date: '2024-01-20 12:00:00',
    stocktwitsPosts: 6,
    stocktwitsComments: 3,
    stocktwitsLikes: 11,
    stocktwitsImpressions: 16228,
  },
  {
    date: '2024-01-20 11:00:00',
    stocktwitsPosts: 5,
    stocktwitsComments: 0,
    stocktwitsLikes: 1,
    stocktwitsImpressions: 22167,
  },
  {
    date: '2024-01-20 10:00:00',
    stocktwitsPosts: 2,
    stocktwitsComments: 1,
    stocktwitsLikes: 0,
    stocktwitsImpressions: 10907,
  },
  {
    date: '2024-01-20 09:00:00',
    stocktwitsPosts: 2,
    stocktwitsComments: 0,
    stocktwitsLikes: 1,
    stocktwitsImpressions: 0,
  },
  {
    date: '2024-01-20 08:00:00',
    stocktwitsPosts: 5,
    stocktwitsComments: 1,
    stocktwitsLikes: 15,
    stocktwitsImpressions: 11138,
  },
  {
    date: '2024-01-20 07:00:00',
    stocktwitsPosts: 6,
    stocktwitsComments: 2,
    stocktwitsLikes: 23,
    stocktwitsImpressions: 33586,
  },
  {
    date: '2024-01-20 06:00:00',
    stocktwitsPosts: 8,
    stocktwitsComments: 1,
    stocktwitsLikes: 9,
    stocktwitsImpressions: 246550,
  },
  {
    date: '2024-01-20 05:00:00',
    stocktwitsPosts: 4,
    stocktwitsComments: 11,
    stocktwitsLikes: 15,
    stocktwitsImpressions: 3205,
  },
  {
    date: '2024-01-20 04:00:00',
    stocktwitsPosts: 3,
    stocktwitsComments: 0,
    stocktwitsLikes: 5,
    stocktwitsImpressions: 49539,
  },
  {
    date: '2024-01-20 03:00:00',
    stocktwitsPosts: 6,
    stocktwitsComments: 1,
    stocktwitsLikes: 6,
    stocktwitsImpressions: 16351,
  },
  {
    date: '2024-01-20 02:00:00',
    stocktwitsPosts: 11,
    stocktwitsComments: 3,
    stocktwitsLikes: 15,
    stocktwitsImpressions: 25327,
  },
  {
    date: '2024-01-20 01:00:00',
    stocktwitsPosts: 5,
    stocktwitsComments: 1,
    stocktwitsLikes: 0,
    stocktwitsImpressions: 21022,
  },
  {
    date: '2024-01-20 00:00:00',
    stocktwitsPosts: 9,
    stocktwitsComments: 17,
    stocktwitsLikes: 25,
    stocktwitsImpressions: 2935,
  },
  {
    date: '2024-01-19 23:00:00',
    stocktwitsPosts: 12,
    stocktwitsComments: 3,
    stocktwitsLikes: 12,
    stocktwitsImpressions: 17108,
  },
  {
    date: '2024-01-19 22:00:00',
    stocktwitsPosts: 16,
    stocktwitsComments: 19,
    stocktwitsLikes: 18,
    stocktwitsImpressions: 47813,
  },
  {
    date: '2024-01-19 21:00:00',
    stocktwitsPosts: 26,
    stocktwitsComments: 27,
    stocktwitsLikes: 33,
    stocktwitsImpressions: 129765,
  },
  {
    date: '2024-01-19 20:00:00',
    stocktwitsPosts: 34,
    stocktwitsComments: 6,
    stocktwitsLikes: 27,
    stocktwitsImpressions: 222126,
  },
  {
    date: '2024-01-19 19:00:00',
    stocktwitsPosts: 57,
    stocktwitsComments: 11,
    stocktwitsLikes: 25,
    stocktwitsImpressions: 346304,
  },
  {
    date: '2024-01-19 18:00:00',
    stocktwitsPosts: 88,
    stocktwitsComments: 27,
    stocktwitsLikes: 70,
    stocktwitsImpressions: 81614,
  },
  {
    date: '2024-01-19 17:00:00',
    stocktwitsPosts: 50,
    stocktwitsComments: 9,
    stocktwitsLikes: 30,
    stocktwitsImpressions: 247071,
  },
  {
    date: '2024-01-19 16:00:00',
    stocktwitsPosts: 47,
    stocktwitsComments: 13,
    stocktwitsLikes: 24,
    stocktwitsImpressions: 301181,
  },
  {
    date: '2024-01-19 15:00:00',
    stocktwitsPosts: 58,
    stocktwitsComments: 14,
    stocktwitsLikes: 36,
    stocktwitsImpressions: 52390,
  },
  {
    date: '2024-01-19 14:00:00',
    stocktwitsPosts: 73,
    stocktwitsComments: 31,
    stocktwitsLikes: 64,
    stocktwitsImpressions: 22343,
  },
  {
    date: '2024-01-19 13:00:00',
    stocktwitsPosts: 43,
    stocktwitsComments: 10,
    stocktwitsLikes: 58,
    stocktwitsImpressions: 488327,
  },
  {
    date: '2024-01-19 12:00:00',
    stocktwitsPosts: 24,
    stocktwitsComments: 13,
    stocktwitsLikes: 29,
    stocktwitsImpressions: 31930,
  },
  {
    date: '2024-01-19 11:00:00',
    stocktwitsPosts: 25,
    stocktwitsComments: 8,
    stocktwitsLikes: 51,
    stocktwitsImpressions: 63066,
  },
  {
    date: '2024-01-19 10:00:00',
    stocktwitsPosts: 9,
    stocktwitsComments: 1,
    stocktwitsLikes: 6,
    stocktwitsImpressions: 11275,
  },
  {
    date: '2024-01-19 09:00:00',
    stocktwitsPosts: 13,
    stocktwitsComments: 4,
    stocktwitsLikes: 17,
    stocktwitsImpressions: 5898,
  },
  {
    date: '2024-01-19 08:00:00',
    stocktwitsPosts: 5,
    stocktwitsComments: 4,
    stocktwitsLikes: 6,
    stocktwitsImpressions: 491,
  },
  {
    date: '2024-01-19 07:00:00',
    stocktwitsPosts: 11,
    stocktwitsComments: 7,
    stocktwitsLikes: 24,
    stocktwitsImpressions: 11012,
  },
  {
    date: '2024-01-19 06:00:00',
    stocktwitsPosts: 5,
    stocktwitsComments: 3,
    stocktwitsLikes: 11,
    stocktwitsImpressions: 269315,
  },
  {
    date: '2024-01-19 05:00:00',
    stocktwitsPosts: 5,
    stocktwitsComments: 0,
    stocktwitsLikes: 3,
    stocktwitsImpressions: 44475,
  },
  {
    date: '2024-01-19 04:00:00',
    stocktwitsPosts: 21,
    stocktwitsComments: 7,
    stocktwitsLikes: 35,
    stocktwitsImpressions: 66176,
  },
  {
    date: '2024-01-19 03:00:00',
    stocktwitsPosts: 13,
    stocktwitsComments: 3,
    stocktwitsLikes: 9,
    stocktwitsImpressions: 27963,
  },
  {
    date: '2024-01-19 02:00:00',
    stocktwitsPosts: 22,
    stocktwitsComments: 11,
    stocktwitsLikes: 41,
    stocktwitsImpressions: 171900,
  },
  {
    date: '2024-01-19 01:00:00',
    stocktwitsPosts: 26,
    stocktwitsComments: 8,
    stocktwitsLikes: 40,
    stocktwitsImpressions: 85105,
  },
  {
    date: '2024-01-19 00:00:00',
    stocktwitsPosts: 24,
    stocktwitsComments: 9,
    stocktwitsLikes: 60,
    stocktwitsImpressions: 38107,
  },
  {
    date: '2024-01-18 23:00:00',
    stocktwitsPosts: 32,
    stocktwitsComments: 15,
    stocktwitsLikes: 38,
    stocktwitsImpressions: 74607,
  },
  {
    date: '2024-01-18 22:00:00',
    stocktwitsPosts: 52,
    stocktwitsComments: 21,
    stocktwitsLikes: 30,
    stocktwitsImpressions: 85382,
  },
  {
    date: '2024-01-18 21:00:00',
    stocktwitsPosts: 35,
    stocktwitsComments: 23,
    stocktwitsLikes: 37,
    stocktwitsImpressions: 68326,
  },
  {
    date: '2024-01-18 20:00:00',
    stocktwitsPosts: 54,
    stocktwitsComments: 13,
    stocktwitsLikes: 73,
    stocktwitsImpressions: 34407,
  },
  {
    date: '2024-01-18 19:00:00',
    stocktwitsPosts: 99,
    stocktwitsComments: 24,
    stocktwitsLikes: 105,
    stocktwitsImpressions: 62916,
  },
  {
    date: '2024-01-18 18:00:00',
    stocktwitsPosts: 50,
    stocktwitsComments: 17,
    stocktwitsLikes: 39,
    stocktwitsImpressions: 121680,
  },
  {
    date: '2024-01-18 17:00:00',
    stocktwitsPosts: 72,
    stocktwitsComments: 190,
    stocktwitsLikes: 64,
    stocktwitsImpressions: 139697,
  },
  {
    date: '2024-01-18 16:00:00',
    stocktwitsPosts: 108,
    stocktwitsComments: 80,
    stocktwitsLikes: 32,
    stocktwitsImpressions: 402264,
  },
  {
    date: '2024-01-18 15:00:00',
    stocktwitsPosts: 86,
    stocktwitsComments: 16,
    stocktwitsLikes: 76,
    stocktwitsImpressions: 132378,
  },
  {
    date: '2024-01-18 14:00:00',
    stocktwitsPosts: 91,
    stocktwitsComments: 17,
    stocktwitsLikes: 121,
    stocktwitsImpressions: 138957,
  },
  {
    date: '2024-01-18 13:00:00',
    stocktwitsPosts: 80,
    stocktwitsComments: 10,
    stocktwitsLikes: 93,
    stocktwitsImpressions: 516424,
  },
  {
    date: '2024-01-18 12:00:00',
    stocktwitsPosts: 65,
    stocktwitsComments: 19,
    stocktwitsLikes: 86,
    stocktwitsImpressions: 466279,
  },
  {
    date: '2024-01-18 11:00:00',
    stocktwitsPosts: 53,
    stocktwitsComments: 16,
    stocktwitsLikes: 55,
    stocktwitsImpressions: 341923,
  },
]
