import { colorFunction } from './utils'

export const mockFinState = []

export const getDataBalanceSheet = FinStat => {
  let data = null

  if (FinStat && FinStat.BalanceData && FinStat.BalanceData.length > 0) {
    data = {
      nodes: [
        { name: 'Assets', color: 'var(--green)' },
        { name: 'Liabilities', color: 'var(--red)' },
        { name: 'Equity', color: 'var(--primary-color)' },
        // current assets
        { name: 'Current Assets', color: 'var(--green)' },
        { name: 'Cash', color: 'var(--green)' },
        { name: 'Short Term Investments', color: 'var(--green)' },
        { name: 'Net Receivables', color: 'var(--green)' },
        { name: 'Inventory', color: 'var(--green)' },
        { name: 'Other Current Assets', color: 'var(--green)' },
        // non current assets
        { name: 'Non Current Assets', color: 'var(--green)' },
        { name: 'Property Plant Equipment Net', color: 'var(--green)' },
        { name: 'Goodwill', color: 'var(--green)' },
        { name: 'Intangible Assets', color: 'var(--green)' },
        { name: 'Long Term Investments', color: 'var(--green)' },
        { name: 'Tax Assets', color: 'var(--green)' },
        { name: 'Other Non Current Assets', color: 'var(--green)' },
        // current liabilities
        { name: 'Current Liabilities', color: 'var(--red)' },
        { name: 'Account Payables', color: 'var(--red)' },
        { name: 'Short Term Debt', color: 'var(--red)' },
        { name: 'Tax Payables', color: 'var(--red)' },
        { name: 'Deferred Revenue', color: 'var(--red)' },
        { name: 'Other Current Liabilities', color: 'var(--red)' },
        // non current liabilities
        { name: 'Non Current Liabilities', color: 'var(--red)' },
        { name: 'Long Term Debt', color: 'var(--red)' },
        { name: 'Deferred Revenue Non Current', color: 'var(--red)' },
        { name: 'Deferred Tax Liabilities Non Current', color: 'var(--red)' },
        { name: 'Other Non Current Liabilities', color: 'var(--red)' },
        // equity
        { name: 'Minority Interest', color: 'var(--primary-color)' },
      ],
      links: [
        {
          source: 0,
          target: 1,
          value: FinStat?.BalanceData[0]?.totalLiabilities,
          color: 'var(--red)',
        }, // Assets -> Liabilities
        {
          source: 0,
          target: 2,
          value: FinStat?.BalanceData[0]?.totalEquity,
          color: 'var(--primary-color)',
        }, // Assets -> Equity
        {
          source: 3,
          target: 0,
          value: FinStat?.BalanceData[0]?.totalCurrentAssets,
          color: 'var(--green)',
        }, // Assets -> TotalNonCurrentAssets
        {
          source: 9,
          target: 0,
          value: FinStat?.BalanceData[0]?.totalNonCurrentAssets,
          color: 'var(--green)',
        }, // Assets -> TotalCurrentAssets
        {
          source: 4,
          target: 3,
          value: FinStat?.BalanceData[0]?.cashAndCashEquivalents,
          color: 'var(--green)',
        }, // TotalCurrentAssets -> cashAndCashEquivalents
        {
          source: 5,
          target: 3,
          value: FinStat?.BalanceData[0]?.shortTermInvestments,
          color: 'var(--green)',
        }, // TotalCurrentAssets -> shortTermInvestments
        {
          source: 6,
          target: 3,
          value: FinStat?.BalanceData[0]?.netReceivables,
          color: 'var(--green)',
        }, // TotalCurrentAssets -> netReceivables
        {
          source: 7,
          target: 3,
          value: FinStat?.BalanceData[0]?.inventory,
          color: 'var(--green)',
        }, // TotalCurrentAssets -> inventory
        {
          source: 8,
          target: 3,
          value: FinStat?.BalanceData[0]?.otherCurrentAssets,
          color: 'var(--green)',
        }, // TotalCurrentAssets -> otherCurrentAssets
        {
          source: 10,
          target: 9,
          value: FinStat?.BalanceData[0]?.propertyPlantEquipmentNet,
          color: 'var(--green)',
        }, // TotalNonCurrentAssets -> propertyPlantEquipmentNet
        {
          source: 11,
          target: 9,
          value: FinStat?.BalanceData[0]?.goodwill,
          color: 'var(--green)',
        }, // TotalNonCurrentAssets -> goodwill
        {
          source: 12,
          target: 9,
          value: FinStat?.BalanceData[0]?.intangibleAssets,
          color: 'var(--green)',
        }, // TotalNonCurrentAssets -> intangibleAssets
        {
          source: 13,
          target: 9,
          value: FinStat?.BalanceData[0]?.longTermInvestments,
          color: 'var(--green)',
        }, // TotalNonCurrentAssets -> longTermInvestments
        {
          source: 14,
          target: 9,
          value: FinStat?.BalanceData[0]?.taxAssets,
          color: 'var(--green)',
        }, // TotalNonCurrentAssets -> taxAssets
        {
          source: 15,
          target: 9,
          value: FinStat?.BalanceData[0]?.otherNonCurrentAssets,
          color: 'var(--green)',
        }, // TotalNonCurrentAssets -> otherNonCurrentAssets
        {
          source: 1,
          target: 16,
          value: FinStat?.BalanceData[0]?.totalCurrentLiabilities,
          color: 'var(--red)',
        }, // TotalCurrentLiabilities -> Liabilities
        {
          source: 1,
          target: 22,
          value: FinStat?.BalanceData[0]?.totalNonCurrentLiabilities,
          color: 'var(--red)',
        }, // TotalNonCurrentLiabilities -> Liabilities
        {
          source: 16,
          target: 17,
          value: FinStat?.BalanceData[0]?.accountPayables,
          color: 'var(--red)',
        }, // TotalCurrentLiabilities -> accountPayables
        {
          source: 16,
          target: 18,
          value: FinStat?.BalanceData[0]?.shortTermDebt,
          color: 'var(--red)',
        }, // TotalCurrentLiabilities -> shortTermDebt
        {
          source: 16,
          target: 19,
          value: FinStat?.BalanceData[0]?.taxPayables,
          color: 'var(--red)',
        }, // TotalCurrentLiabilities -> taxPayables
        {
          source: 16,
          target: 20,
          value: FinStat?.BalanceData[0]?.deferredRevenue,
          color: 'var(--red)',
        }, // TotalCurrentLiabilities -> deferredRevenue
        {
          source: 16,
          target: 21,
          value: FinStat?.BalanceData[0]?.otherCurrentLiabilities,
          color: 'var(--red)',
        }, // TotalCurrentLiabilities -> otherCurrentLiabilities
        {
          source: 22,
          target: 23,
          value: FinStat?.BalanceData[0]?.longTermDebt,
          color: 'var(--red)',
        }, // TotalNonCurrentLiabilities -> longTermDebt
        {
          source: 22,
          target: 24,
          value: FinStat?.BalanceData[0]?.deferredRevenueNonCurrent,
          color: 'var(--red)',
        }, // TotalNonCurrentLiabilities -> deferredRevenueNonCurrent
        {
          source: 22,
          target: 25,
          value: FinStat?.BalanceData[0]?.deferredTaxLiabilitiesNonCurrent,
          color: 'var(--red)',
        }, // TotalNonCurrentLiabilities -> deferredTaxLiabilitiesNonCurrent
        {
          source: 22,
          target: 26,
          value: FinStat?.BalanceData[0]?.otherNonCurrentLiabilities,
          color: 'var(--red)',
        }, // TotalNonCurrentLiabilities -> otherNonCurrentLiabilities
        {
          source: 0,
          target: 27,
          value: FinStat?.BalanceData[0]?.minorityInterest,
          color: 'var(--primary-color)',
        }, // Liabilities -> TotalLiabilities
      ],
    }
  }

  return data
}

export const getDataIncomeStatement = FinStat => {
  let data = null

  if (FinStat && FinStat.IncomeData && FinStat.IncomeData.length > 0) {
    data = {
      nodes: [
        { name: 'Revenue', color: 'var(--green)' },
        { name: 'Cost of Revenue', color: 'var(--red)' },
        {
          name: 'Gross Profit',
          color: colorFunction(FinStat?.IncomeData[0]?.grossProfit),
        },
        { name: 'R&D expense', color: 'var(--red)' },
        { name: 'SG&A Expense', color: 'var(--red)' },
        { name: 'Other Expenses', color: 'var(--red)' },
        { name: 'Operating Expenses', color: 'var(--red)' },
        { name: 'Interest Expense', color: 'var(--red)' },
        { name: 'Depreciation and Amortization' },
        {
          name: 'Operating Income',
          color: colorFunction(FinStat?.IncomeData[0]?.operatingIncome),
        },
        {
          name: 'Net Income',
          color: colorFunction(FinStat?.IncomeData[0]?.netIncome),
        },
        { name: 'Income Tax Expense', color: 'var(--red)' },
        { name: 'Other Income Expenses Net', color: 'var(--red)' },
        {
          name: 'Income Before Tax',
          color: colorFunction(FinStat?.IncomeData[0]?.incomeBeforeTax),
        },
        {
          name: 'Interest Income',
          color: colorFunction(FinStat?.IncomeData[0]?.interestIncome),
        },
      ],
      links: [
        {
          source: 0,
          target: 1,
          value: FinStat?.IncomeData[0]?.costOfRevenue,
          color: 'var(--red)',
        }, // Revenue -> Cost of Revenue
        {
          source: 0,
          target: 2,
          value: FinStat?.IncomeData[0]?.grossProfit,
          color: colorFunction(FinStat?.IncomeData[0]?.grossProfit),
        }, // Revenue -> Gross Profit
        {
          source: 6,
          target: 3,
          value: FinStat?.IncomeData[0]?.researchAndDevelopmentExpenses,
          color: 'var(--red)',
        }, // Gross Profit -> R&D expense
        {
          source: 6,
          target: 4,
          value:
            FinStat?.IncomeData[0]?.sellingGeneralAndAdministrativeExpenses,
          color: 'var(--red)',
        }, // Gross Profit -> SG&A Expense
        {
          source: 6,
          target: 5,
          value: FinStat?.IncomeData[0]?.otherExpenses,
          color: 'var(--red)',
        }, // Gross Profit -> otherExpenses
        {
          source: 2,
          target: 6,
          value: FinStat?.IncomeData[0]?.operatingExpenses,
          color: 'var(--red)',
        }, // R&D expense -> Operating Expenses
        {
          source: 2,
          target: 9,
          value: FinStat?.IncomeData[0]?.operatingIncome,
          color: colorFunction(FinStat?.IncomeData[0]?.operatingIncome),
        }, // SG&A Expense -> Interest Expense
        {
          source: 13,
          target: 10,
          value: FinStat?.IncomeData[0]?.netIncome,
          color: colorFunction(FinStat?.IncomeData[0]?.netIncome),
        }, // otherExpenses -> Depreciation and Amortization
        {
          source: 13,
          target: 11,
          value: FinStat?.IncomeData[0]?.incomeTaxExpense,
          color: 'var(--red)',
        }, // Operating Expenses -> Operating Income
        {
          source: 9,
          target: 12,
          value: Math.abs(FinStat?.IncomeData[0]?.totalOtherIncomeExpensesNet),
          color: 'var(--red)',
        }, // Operating Income -> Net Income
        {
          source: 9,
          target: 13,
          value: FinStat?.IncomeData[0]?.incomeBeforeTax,
          color: colorFunction(FinStat?.IncomeData[0]?.incomeBeforeTax),
        }, // Net Income -> Income Tax Expense
      ],
    }

    if (FinStat?.RevSegsData && FinStat?.RevSegsData?.length > 0) {
      const revData = FinStat?.RevSegsData[0]

      const revDate = Object?.keys(revData)[0]
      const revSegments = revData[revDate]

      Object?.keys(revSegments).forEach((key, index) => {
        data?.nodes.push({ name: key, color: 'var(--primary-color)' })
        data?.links.push({
          source: data?.nodes?.length - 1,
          target: 0,
          value: revSegments[key],
          color: 'var(--primary-color)',
        })
      })
    }
  }
  return data
}

export const getWaterfallIncome = FinStat => {
  if (!FinStat?.IncomeData) {
    return null
  }

  const revenueSegmentData = FinStat => {
    if (!FinStat?.RevSegsData || FinStat?.RevSegsData?.length <= 0) {
      return []
    }

    return FinStat?.RevSegsData[0] &&
      typeof FinStat?.RevSegsData[0] === 'object'
      ? Object.entries(Object.entries(FinStat?.RevSegsData[0])[0][1] || {}).map(
          ([key, value], index) => ({
            category: key,
            value,
            startFromBottom: index === 0,
            color: 'var(--primary-color)',
          })
        )
      : []
  }

  const revenueSegments = revenueSegmentData(FinStat)
  const revenueSegmentsSum = revenueSegments.reduce(
    (acc, curr) => acc + curr.value,
    0
  )

  if (
    revenueSegments.length > 0 &&
    revenueSegmentsSum !== FinStat?.IncomeData[0]?.revenue
  ) {
    revenueSegments.push({
      category: 'Other',
      value: FinStat?.IncomeData[0]?.revenue - revenueSegmentsSum,
      startFromBottom: false,
      color: 'var(--primary-color)',
    })
  }

  const data = [
    ...revenueSegments,
    {
      category: 'Revenue',
      value: FinStat?.IncomeData[0]?.revenue,
      startFromBottom: true,
    },
    {
      category: 'Cost of revenue',
      value: FinStat?.IncomeData[0]?.costOfRevenue * -1,
      startFromBottom: false,
    },
    {
      category: 'Gross Profit',
      value: FinStat?.IncomeData[0]?.grossProfit,
      startFromBottom: true,
    },
    {
      category: 'R&D Expenses',
      value: FinStat?.IncomeData[0]?.researchAndDevelopmentExpenses * -1,
      parent: 'Operating Expenses',
      startFromBottom: false,
    },
    {
      category: 'G&A Expenses',
      value:
        FinStat?.IncomeData[0]?.sellingGeneralAndAdministrativeExpenses * -1,
      parent: 'Operating Expenses',
      startFromBottom: false,
    },
    {
      category: 'Other Expenses',
      value: FinStat?.IncomeData[0]?.otherExpenses * -1,
      parent: 'Operating Expenses',
      startFromBottom: false,
    },
    {
      category: 'Operating Expenses',
      value: FinStat?.IncomeData[0]?.operatingExpenses * -1,
      startFromBottom: false,
    },
    {
      category: 'Operating Income',
      value: FinStat?.IncomeData[0]?.operatingIncome,
      startFromBottom: true,
    },
    {
      category: 'Interest Expense',
      value: FinStat?.IncomeData[0]?.interestExpense * -1,
      startFromBottom: false,
    },
    {
      category: 'Interest Income',
      value: FinStat?.IncomeData[0]?.interestIncome,
      startFromBottom: false,
    },
    {
      category: 'Other Income Expenses Net',
      value: FinStat?.IncomeData[0]?.totalOtherIncomeExpensesNet,
      startFromBottom: false,
    },
    {
      category: 'Income Tax Expense',
      value: FinStat?.IncomeData[0]?.incomeTaxExpense * -1,
      startFromBottom: false,
    },
    {
      category: 'Net Income',
      value: FinStat?.IncomeData[0]?.netIncome,
      startFromBottom: true,
    },
  ]

  const flattenedData = []

  data.forEach(item => {
    if (item.value === 0) {
      return
    }
    const children = item.children
    delete item.children

    flattenedData.push(item)

    if (children) {
      children.forEach(child => {
        flattenedData.push({ ...child, parent: item.category })
      })
    }
  })

  return flattenedData
}

export const getWaterfallCashFlow = FinStat => {
  if (!FinStat?.CashData) {
    return null
  }

  const data = [
    {
      category: 'Net Income',
      value: FinStat?.CashData[0]?.netIncome,
      startFromBottom: true,
    },
    {
      category: 'Depreciation & Amortization',
      value: FinStat?.CashData[0]?.depreciationAndAmortization,
      startFromBottom: false,
    },
    {
      category: 'Deferred Income Tax',
      value: FinStat?.CashData[0]?.deferredIncomeTax,
      startFromBottom: false,
    },
    {
      category: 'Stock Based Compensation',
      value: FinStat?.CashData[0]?.stockBasedCompensation,
      startFromBottom: false,
    },
    {
      category: 'Change in Working Capital',
      value: FinStat?.CashData[0]?.changeInWorkingCapital,
      startFromBottom: false,
    },
    {
      category: 'Other Non Cash Items',
      value: FinStat?.CashData[0]?.otherNonCashItems,
      startFromBottom: false,
    },
    {
      category: 'Net Cash Provided by Operating Activities',
      value: FinStat?.CashData[0]?.netCashProvidedByOperatingActivities,
      startFromBottom: true,
    },
    {
      category: 'Investments in Property, Plant and Equipment',
      value: FinStat?.CashData[0]?.investmentsInPropertyPlantAndEquipment,
      startFromBottom: true,
    },
    {
      category: 'Acquisitions Net',
      value: FinStat?.CashData[0]?.acquisitionsNet,
      startFromBottom: false,
    },
    {
      category: 'Purchases of Investments',
      value: FinStat?.CashData[0]?.purchasesOfInvestments,
      startFromBottom: false,
    },
    {
      category: 'Sales/Maturities of Investments',
      value: FinStat?.CashData[0]?.salesMaturitiesOfInvestments,
      startFromBottom: false,
    },
    {
      category: 'Other Investing Activities',
      value: FinStat?.CashData[0]?.otherInvestingActivites,
      startFromBottom: false,
    },
    {
      category: 'Net Cash Used for Investing Activities',
      value: FinStat?.CashData[0]?.netCashUsedForInvestingActivites,
      startFromBottom: true,
    },
    {
      category: 'Debt Repayment',
      value: FinStat?.CashData[0]?.debtRepayment,
      startFromBottom: true,
    },
    {
      category: 'Common Stock Issued',
      value: FinStat?.CashData[0]?.commonStockIssued,
      startFromBottom: false,
    },
    {
      category: 'Common Stock Repurchased',
      value: FinStat?.CashData[0]?.commonStockRepurchased,
      startFromBottom: false,
    },
    {
      category: 'Dividends Paid',
      value: FinStat?.CashData[0]?.dividendsPaid,
      startFromBottom: false,
    },
    {
      category: 'Other Financing Activities',
      value: FinStat?.CashData[0]?.otherFinancingActivites,
      startFromBottom: false,
    },
    {
      category: 'Net Cash Used/Provided by Financing Activities',
      value: FinStat?.CashData[0]?.netCashUsedProvidedByFinancingActivities,
      startFromBottom: true,
    },
    {
      category: 'Operating Cash Flow',
      value: FinStat?.CashData[0]?.operatingCashFlow,
      startFromBottom: true,
    },
    {
      category: 'Capital Expenditure',
      value: FinStat?.CashData[0]?.capitalExpenditure,
      startFromBottom: false,
    },
    {
      category: 'Free Cash Flow',
      value: FinStat?.CashData[0]?.freeCashFlow,
      startFromBottom: true,
    },
  ]

  const flattenedData = []

  data.forEach(item => {
    if (item.value === 0) {
      return
    }
    const children = item.children
    delete item.children

    flattenedData.push(item)

    if (children) {
      children.forEach(child => {
        flattenedData.push({ ...child, parent: item.category })
      })
    }
  })

  return flattenedData
}

export const getWaterfallBalance = FinStat => {
  if (!FinStat?.BalanceData || FinStat?.BalanceData.length < 2) {
    return null
  }
  const data = [
    {
      category: 'Cash and Short Term Investments',
      value: FinStat?.BalanceData[0]?.cashAndShortTermInvestments,
      startFromBottom: true,
    },
    {
      category: 'Accounts Receivable',
      value: FinStat?.BalanceData[0]?.netReceivables,
      startFromBottom: false,
    },
    {
      category: 'Inventory',
      value: FinStat?.BalanceData[0]?.inventory,
      startFromBottom: false,
    },
    {
      category: 'Other Current Assets',
      value: FinStat?.BalanceData[0]?.otherCurrentAssets,
      startFromBottom: false,
    },
    {
      category: 'Total Current Assets',
      value: FinStat?.BalanceData[0]?.totalCurrentAssets,
      startFromBottom: true,
    },
    {
      category: 'Property, Plant and Equipment',
      value: FinStat?.BalanceData[0]?.propertyPlantEquipmentNet,
      startFromBottom: true,
    },
    {
      category: 'Goodwill',
      value: FinStat?.BalanceData[0]?.goodwill,
      startFromBottom: false,
    },
    {
      category: 'Intangible Assets',
      value: FinStat?.BalanceData[0]?.intangibleAssets,
      startFromBottom: false,
    },
    {
      category: 'long Term Investments',
      value: FinStat?.BalanceData[0]?.longTermInvestments,
      startFromBottom: false,
    },
    {
      category: 'Other Non Current Assets',
      value: FinStat?.BalanceData[0]?.otherNonCurrentAssets,
      startFromBottom: false,
    },
    {
      category: 'Total Non Current Assets',
      value: FinStat?.BalanceData[0]?.totalNonCurrentAssets,
      startFromBottom: true,
    },
    {
      category: 'Total Assets',
      value: FinStat?.BalanceData[0]?.totalAssets,
      startFromBottom: true,
    },
    {
      category: 'Accounts Payable',
      value: FinStat?.BalanceData[0]?.accountPayables * -1,
      startFromBottom: true,
    },
    {
      category: 'Short Term Debt',
      value: FinStat?.BalanceData[0]?.shortTermDebt * -1,
      startFromBottom: false,
    },
    {
      category: 'Tax Payables',
      value: FinStat?.BalanceData[0]?.taxPayables * -1,
      startFromBottom: false,
    },
    {
      category: 'Deferred Revenue',
      value: FinStat?.BalanceData[0]?.deferredRevenue * -1,
      startFromBottom: false,
    },
    {
      category: 'Other Current Liabilities',
      value: FinStat?.BalanceData[0]?.otherCurrentLiabilities * -1,
      startFromBottom: false,
    },
    {
      category: 'Total Current Liabilities',
      value: FinStat?.BalanceData[0]?.totalCurrentLiabilities * -1,
      startFromBottom: true,
    },
    {
      category: 'Long Term Debt',
      value: FinStat?.BalanceData[0]?.longTermDebt * -1,
      startFromBottom: true,
    },
    {
      category: 'Deferred Revenue Non Current',
      value: FinStat?.BalanceData[0]?.deferredRevenueNonCurrent * -1,
      startFromBottom: false,
    },
    {
      category: 'Deferred Tax Liabilities Non Current',
      value: FinStat?.BalanceData[0]?.deferredTaxLiabilitiesNonCurrent * -1,
      startFromBottom: false,
    },
    {
      category: 'Other Long Term Liabilities',
      value: FinStat?.BalanceData[0]?.otherNonCurrentLiabilities * -1,
      startFromBottom: false,
    },
    {
      category: 'Total Non Current Liabilities',
      value: FinStat?.BalanceData[0]?.totalNonCurrentLiabilities * -1,
      startFromBottom: true,
    },
    {
      category: 'Total Liabilities',
      value: FinStat?.BalanceData[0]?.totalLiabilities * -1,
      startFromBottom: true,
    },
    {
      category: 'Common Stock',
      value: FinStat?.BalanceData[0]?.commonStock,
      startFromBottom: true,
    },
    {
      category: 'Retained Earnings',
      value: FinStat?.BalanceData[0]?.retainedEarnings,
      startFromBottom: false,
    },
    {
      category: 'Accumulated Other Comprehensive Income',
      value: FinStat?.BalanceData[0]?.accumulatedOtherComprehensiveIncomeLoss,
      startFromBottom: false,
    },
    {
      category: 'Other total Stockholders Equity',
      value: FinStat?.BalanceData[0]?.othertotalStockholdersEquity,
      startFromBottom: false,
    },
    {
      category: 'Total Stockholders Equity',
      value: FinStat?.BalanceData[0]?.totalStockholdersEquity,
      startFromBottom: true,
    },
  ]

  const flattenedData = []

  data.forEach(item => {
    if (item.value === 0) {
      return
    }
    const children = item.children
    delete item.children

    flattenedData.push(item)

    if (children) {
      children.forEach(child => {
        flattenedData.push({ ...child, parent: item.category })
      })
    }
  })

  return flattenedData
}

export const getWaterfallCashFlowPercentageChange = FinStat => {
  if (!FinStat?.CashGrowthData || FinStat?.CashGrowthData.length < 2) {
    return null
  }

  const categories = [
    { key: 'growthNetIncome' },
    {
      key: 'growthDepreciationAndAmortization',
    },
    { key: 'growthDeferredIncomeTax' },
    { key: 'growthStockBasedCompensation' },
    { key: 'growthChangeInWorkingCapital' },
    { key: 'growthOtherNonCashItems' },
    // {
    //   key: 'growthOtherWorkingCapital',
    // },
    {
      key: 'growthNetCashProvidedByOperatingActivites',
    },
    {
      key: 'growthInvestmentsInPropertyPlantAndEquipment',
    },
    { key: 'growthAcquisitionsNet' },
    { key: 'growthPurchasesOfInvestments' },
    {
      key: 'growthSalesMaturitiesOfInvestments',
      name: 'Sales/Maturities of Investments',
    },
    {
      key: 'growthOtherInvestingActivites',
    },
    {
      key: 'growthNetCashUsedForInvestingActivites',
    },
    { key: 'growthDebtRepayment' },
    // { key: 'growthCommonStockIssued'  },
    { key: 'growthCommonStockRepurchased' },
    { key: 'growthDividendsPaid' },
    {
      key: 'growthOtherFinancingActivites',
    },
    {
      key: 'growthNetCashUsedProvidedByFinancingActivities',
    },
    { key: 'growthOperatingCashFlow' },
    { key: 'growthCapitalExpenditure' },
    { key: 'growthFreeCashFlow' },
  ]

  const firstItem = FinStat.CashGrowthData[0]

  const percentageChangeData = categories.map(({ key, name }) => {
    const percentageChange = firstItem[key] || 0
    return {
      category: name,
      value: percentageChange * 100,
      startFromBottom: true,
    }
  })

  const flattenedData = percentageChangeData.filter(
    dataPoint => dataPoint.value !== 0
  )

  return flattenedData
}

// export const getWaterfallCashFlowPercentageChange1 = FinStat => {
//   if (!FinStat?.CashData || FinStat?.CashData.length < 2) {
//     return null
//   }

//   const categories = [
//     { key: 'netIncome', name: 'Net Income' },
//     { key: 'depreciationAndAmortization', name: 'Depreciation & Amortization' },
//     { key: 'deferredIncomeTax', name: 'Deferred Income Tax' },
//     { key: 'stockBasedCompensation', name: 'Stock Based Compensation' },
//     { key: 'changeInWorkingCapital', name: 'Change in Working Capital' },
//     { key: 'otherNonCashItems', name: 'Other Non Cash Items' },
//     {
//       key: 'netCashProvidedByOperatingActivities',
//       name: 'Net Cash Provided by Operating Activities',
//     },
//     {
//       key: 'investmentsInPropertyPlantAndEquipment',
//       name: 'Investments in Property, Plant and Equipment',
//     },
//     { key: 'acquisitionsNet', name: 'Acquisitions Net' },
//     { key: 'purchasesOfInvestments', name: 'Purchases of Investments' },
//     {
//       key: 'salesMaturitiesOfInvestments',
//       name: 'Sales/Maturities of Investments',
//     },
//     { key: 'otherInvestingActivites', name: 'Other Investing Activities' },
//     {
//       key: 'netCashUsedForInvestingActivites',
//       name: 'Net Cash Used for Investing Activities',
//     },
//     { key: 'debtRepayment', name: 'Debt Repayment' },
//     { key: 'commonStockIssued', name: 'Common Stock Issued' },
//     { key: 'commonStockRepurchased', name: 'Common Stock Repurchased' },
//     { key: 'dividendsPaid', name: 'Dividends Paid' },
//     { key: 'otherFinancingActivites', name: 'Other Financing Activities' },
//     {
//       key: 'netCashUsedProvidedByFinancingActivities',
//       name: 'Net Cash Used/Provided by Financing Activities',
//     },
//     { key: 'operatingCashFlow', name: 'Operating Cash Flow' },
//     { key: 'capitalExpenditure', name: 'Capital Expenditure' },
//     { key: 'freeCashFlow', name: 'Free Cash Flow' },
//   ]
//   const currentYearData = FinStat.CashData[0]
//   const previousYearData = FinStat.CashData[1]

//   const percentageChangeData = categories.map(({ key, name }) => {
//     const currentYearValue = currentYearData[key] || 0
//     const previousYearValue = previousYearData[key] || 0

//     const percentageChange =
//       ((currentYearValue - previousYearValue) / previousYearValue) * 100

//     return {
//       category: name,
//       value: percentageChange,
//       startFromBottom: true,
//     }
//   })

//   const flattenedData = percentageChangeData.flatMap(dataPoint => {
//     if (dataPoint.value === 0) return []
//     const { children, ...rest } = dataPoint
//     const parentAndChildren = [rest]
//     if (children) {
//       parentAndChildren.push(
//         ...children.map(child => ({ ...child, parent: dataPoint.category }))
//       )
//     }
//     return parentAndChildren
//   })

//   return flattenedData
// }

export const getWaterfallIncomePercentageChange = FinStat => {
  if (!FinStat?.IncomeGrowthData || FinStat?.IncomeGrowthData.length < 2) {
    return null
  }

  let revSegData = []

  const revSegDataPresent =
    FinStat?.RevSegsData && FinStat?.RevSegsData.length > 0

  if (revSegDataPresent) {
    const latestRevSeg = FinStat?.RevSegsData[0]
    const latestDate = Object.keys(latestRevSeg)[0]
    const revSegKeys = Object.keys(latestRevSeg[latestDate])

    revSegKeys.forEach(revSegKey => {
      revSegData.unshift({
        category: revSegKey,
        value: 0,
        startFromBottom: true,
      })
    })
  }

  const categories = [
    { key: 'growthRevenue', name: 'Revenue' },
    { key: 'growthCostOfRevenue', name: 'Cost of revenue' },
    { key: 'growthGrossProfit', name: 'Gross Profit' },
    {
      key: 'growthResearchAndDevelopmentExpenses',
      name: 'R&D Expenses',
      parent: 'Operating Expenses',
    },
    {
      key: 'growthSellingAndMarketingExpenses',
      name: 'G&A Expenses',
      parent: 'Operating Expenses',
    },
    {
      key: 'growthOtherExpenses',
      name: 'Other Expenses',
      parent: 'Operating Expenses',
    },
    { key: 'growthOperatingExpenses', name: 'Operating Expenses' },
    { key: 'growthOperatingIncome', name: 'Operating Income' },
    { key: 'growthInterestExpense', name: 'Interest Expense' },
    { key: 'totalOtherIncomeExpensesNet', name: 'Other Income Expenses Net' },
    { key: 'growthIncomeTaxExpense', name: 'Income Tax Expense' },
    { key: 'growthNetIncome', name: 'Net Income' },
  ]

  const currentYearData = FinStat.IncomeGrowthData[0]

  const percentageChangeData = categories
    .map(({ key, name }) => {
      const percentageChange = currentYearData[key] || 0
      return {
        category: name,
        value: percentageChange * 100,
        startFromBottom: true,
      }
    })
    .filter(dataPoint => dataPoint.value !== 0)

  const finalData = [...revSegData, ...percentageChangeData]

  return finalData
}

// export const getWaterfallIncomePercentageChange1 = FinStat => {
//   if (!FinStat?.CashData || FinStat?.CashData.length < 2) {
//     return null
//   }

//   const categories = [
//     { key: 'revenue', name: 'Revenue' },
//     { key: 'costOfRevenue', name: 'Cost of revenue' },
//     { key: 'grossProfit', name: 'Gross Profit' },
//     {
//       key: 'researchAndDevelopmentExpenses',
//       name: 'R&D Expenses',
//       parent: 'Operating Expenses',
//     },
//     {
//       key: 'sellingGeneralAndAdministrativeExpenses',
//       name: 'G&A Expenses',
//       parent: 'Operating Expenses',
//     },
//     {
//       key: 'otherExpenses',
//       name: 'Other Expenses',
//       parent: 'Operating Expenses',
//     },
//     { key: 'operatingExpenses', name: 'Operating Expenses' },
//     { key: 'operatingIncome', name: 'Operating Income' },
//     { key: 'interestExpense', name: 'Interest Expense' },
//     { key: 'interestIncome', name: 'Interest Income' },
//     { key: 'totalOtherIncomeExpensesNet', name: 'Other Income Expenses Net' },
//     { key: 'incomeTaxExpense', name: 'Income Tax Expense' },
//     { key: 'netIncome', name: 'Net Income' },
//   ]

//   const currentYearData = FinStat.IncomeData[0]
//   const previousYearData = FinStat.IncomeData[1]

//   const percentageChangeData = categories.map(({ key, name }) => {
//     const currentYearValue = currentYearData[key] || 0
//     const previousYearValue = previousYearData[key] || 0

//     const percentageChange =
//       ((currentYearValue - previousYearValue) / previousYearValue) * 100

//     return {
//       category: name,
//       value: percentageChange,
//       startFromBottom: true,
//     }
//   })

//   const flattenedData = percentageChangeData.flatMap(dataPoint => {
//     if (dataPoint.value === 0) return []
//     const { children, ...rest } = dataPoint
//     const parentAndChildren = [rest]
//     if (children) {
//       parentAndChildren.push(
//         ...children.map(child => ({ ...child, parent: dataPoint.category }))
//       )
//     }
//     return parentAndChildren
//   })

//   return flattenedData
// }

export const getWaterfallBalancePercentageChange = FinStat => {
  if (!FinStat?.BalanceGrowthData || FinStat?.BalanceGrowthData.length < 2) {
    return null
  }

  const categories = [
    {
      key: 'growthCashAndShortTermInvestments',
    },
    { key: 'growthNetReceivables' },
    { key: 'growthInventory' },
    { key: 'growthOtherCurrentAssets' },
    { key: 'growthTotalCurrentAssets' },
    {
      key: 'growthPropertyPlantEquipmentNet',
    },
    { key: 'growthGoodwill' },
    { key: 'growthIntangibleAssets' },
    { key: 'growthLongTermInvestments' },
    { key: 'growthOtherNonCurrentAssets' },
    { key: 'growthTotalNonCurrentAssets' },
    { key: 'growthTotalAssets' },
    { key: 'growthAccountPayables' },
    { key: 'growthShortTermDebt' },
    { key: 'growthTaxPayables' },
    { key: 'growthDeferredRevenue' },
    { key: 'growthOtherCurrentLiabilities' },
    { key: 'growthTotalCurrentLiabilities' },
    { key: 'growthLongTermDebt' },
    {
      key: 'growthDeferredRevenueNonCurrent',
    },
    {
      key: 'growthDeferrredTaxLiabilitiesNonCurrent',
    },
    {
      key: 'growthOtherNonCurrentLiabilities',
    },
    {
      key: 'growthTotalNonCurrentLiabilities',
    },
    { key: 'growthTotalLiabilities' },
    { key: 'growthCommonStock' },
    { key: 'growthRetainedEarnings' },
    {
      key: 'growthAccumulatedOtherComprehensiveIncomeLoss',
    },
    { key: 'growthOthertotalStockholdersEquity' },
    { key: 'growthTotalStockholdersEquity' },
  ]

  const currentYearData = FinStat.BalanceGrowthData[0]

  const percentageChangeData = categories.map(({ key, name }) => {
    const percentageChange = currentYearData[key] || 0
    return {
      category: name,
      value: percentageChange * 100,
      startFromBottom: true,
    }
  })

  const flattenedData = percentageChangeData.filter(
    dataPoint => dataPoint.value !== 0
  )

  return flattenedData
}

// export const getWaterfallBalancePercentageChange1 = FinStat => {
//   if (!FinStat?.BalanceData || FinStat?.BalanceData.length < 2) {
//     return null
//   }

//   const categories = [
//     {
//       key: 'cashAndShortTermInvestments',
//       name: 'Cash and Short Term Investments',
//     },
//     { key: 'netReceivables', name: 'Accounts Receivable' },
//     { key: 'inventory', name: 'Inventory' },
//     { key: 'otherCurrentAssets', name: 'Other Current Assets' },
//     { key: 'totalCurrentAssets', name: 'Total Current Assets' },
//     { key: 'propertyPlantEquipmentNet', name: 'Property, Plant and Equipment' },
//     { key: 'goodwill', name: 'Goodwill' },
//     { key: 'intangibleAssets', name: 'Intangible Assets' },
//     { key: 'longTermInvestments', name: 'Long Term Investments' },
//     { key: 'otherNonCurrentAssets', name: 'Other Non Current Assets' },
//     { key: 'totalNonCurrentAssets', name: 'Total Non Current Assets' },
//     { key: 'totalAssets', name: 'Total Assets' },
//     { key: 'accountPayables', name: 'Accounts Payable' },
//     { key: 'shortTermDebt', name: 'Short Term Debt' },
//     { key: 'taxPayables', name: 'Tax Payables' },
//     { key: 'deferredRevenue', name: 'Deferred Revenue' },
//     { key: 'otherCurrentLiabilities', name: 'Other Current Liabilities' },
//     { key: 'totalCurrentLiabilities', name: 'Total Current Liabilities' },
//     { key: 'longTermDebt', name: 'Long Term Debt' },
//     { key: 'deferredRevenueNonCurrent', name: 'Deferred Revenue Non Current' },
//     {
//       key: 'deferredTaxLiabilitiesNonCurrent',
//       name: 'Deferred Tax Liabilities Non Current',
//     },
//     { key: 'otherNonCurrentLiabilities', name: 'Other Long Term Liabilities' },
//     {
//       key: 'totalNonCurrentLiabilities',
//       name: 'Total Non Current Liabilities',
//     },
//     { key: 'totalLiabilities', name: 'Total Liabilities' },
//     { key: 'commonStock', name: 'Common Stock' },
//     { key: 'retainedEarnings', name: 'Retained Earnings' },
//     {
//       key: 'accumulatedOtherComprehensiveIncomeLoss',
//       name: 'Accumulated Other Comprehensive Income',
//     },
//     { key: 'totalStockholdersEquity', name: 'Total Stockholders Equity' },
//   ]

//   const currentYearData = FinStat.BalanceData[0]
//   const previousYearData = FinStat.BalanceData[1]

//   const percentageChangeData = categories.map(({ key, name }) => {
//     const currentYearValue = currentYearData[key] || 0
//     const previousYearValue = previousYearData[key] || 0

//     const percentageChange =
//       ((currentYearValue - previousYearValue) / previousYearValue) * 100

//     return {
//       category: name,
//       value: percentageChange,
//       startFromBottom: true,
//     }
//   })

//   const flattenedData = percentageChangeData.flatMap(dataPoint => {
//     if (dataPoint.value === 0) return []
//     const { children, ...rest } = dataPoint
//     const parentAndChildren = [rest]
//     if (children) {
//       parentAndChildren.push(
//         ...children.map(child => ({ ...child, parent: dataPoint.category }))
//       )
//     }
//     return parentAndChildren
//   })

//   return flattenedData
// }
