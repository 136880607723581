import React, { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { useTheme } from 'theme/ThemeContext'

export const ExamplePrompts = ({ handleExamplePromptClick, userTicker }) => {
  const { theme } = useTheme()
  const [selectedPrompts, setSelectedPrompts] = useState([])

  const examplePrompts = [
    'What is the p/e ratio of TSLA stock?',
    'How is the market doing today?',
    'Tell me a stock market joke',
    'Give me the latest news for AAPL',
    'What is the market cap of AMZN?',
    'How has GOOGL performed in the last year?',
    'What are the top gainers today?',
    'Explain what a stock buyback is',
    'What is the dividend yield of MSFT?',
    'What are the key financial ratios for JPM?',
    'Give me the latest insider trading news for TSLA',
    'What is the revenue of BABA?',
  ]

  const userTickerPrompts = [
    `What is the p/e ratio of ${userTicker} stock?`,
    `Give me the latest news for ${userTicker}`,
    `What is the market cap of ${userTicker}?`,
    `How has ${userTicker} performed in the last year?`,
    `What are the analyst ratings for ${userTicker}?`,
    `What are the key financial ratios for ${userTicker}?`,
    `Give me the latest insider trading news for ${userTicker}`,
    `What is the revenue of ${userTicker}?`,
  ]

  const getOtherColor = () => {
    if (
      theme === 'outseekDark' ||
      theme === 'blueDeluxe' ||
      theme === 'azureEclipse'
    ) {
      return '#805cad'
    } else {
      return '#4079a8'
    }
  }

  const getRandomPrompts = (prompts, num) => {
    const shuffled = [...prompts].sort(() => 0.5 - Math.random())
    return shuffled.slice(0, num)
  }

  useEffect(() => {
    const root = document.documentElement
    root.style.setProperty('--secondary-color', getOtherColor())
    setSelectedPrompts(getRandomPrompts(examplePrompts, 4))
  }, [theme])

  const prompts = userTicker ? userTickerPrompts : examplePrompts

  return (
    <div className={styles.prompts}>
      <div className={styles.logo}>Outseek</div>
      <div className={styles.examplePrompts}>
        {selectedPrompts.map((prompt, index) => (
          <button
            key={index}
            className={styles.examplePrompt}
            onClick={() => handleExamplePromptClick(prompt)}
          >
            {prompt}
          </button>
        ))}
      </div>
    </div>
  )
}
