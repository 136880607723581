import http from '../../network/httpClient'
import { useQuery } from 'react-query'
import _ from 'lodash'

export const useData = institution => {
  const {
    data: PortfolioPositionsSummary,
    isLoading: PortfolioPositionsSummaryIsLoading,
  } = useQuery(
    ['fetchInstitutionalHoldingsPortfolioPositionsSummary', institution.cik],
    async () => {
      const res = await fetchInstitutionalHoldingsPortfolioPositionsSummary(
        institution.cik
      )

      return res
    }
  )

  return {
    data: PortfolioPositionsSummary,
    isLoading: PortfolioPositionsSummaryIsLoading,
  }
}

const fetchInstitutionalHoldingsPortfolioPositionsSummary = async cik => {
  let data = await http.get(
    'papi/data/api/v4/institutional-ownership/portfolio-holdings-summary?cik=' +
      cik +
      ''
  )
  // let modifiedData = _.tail(data.data.message)
  return data.data.message
}
