export const siteFilter = (full, width) => {
  return {
    control: () => ({
      backgroundColor: 'transparent',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: full ? ('100%' ? width : width) : '200px',
      fontSize: 'var(--default-text)',
      whiteSpace: 'nowrap',
      color: 'var(--white)',
      cursor: 'pointer',
      border: '2px solid var(--grey-accent)',
      borderRadius: '4px',
      maxHeight: 29,
      zIndex: 1000000,
    }),
    singleValue: () => ({
      gridArea: '1/1/2/3',
      marginLeft: '1px',
      marginRight: '1px',
      maxWidth: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      fontSize: '12px',
      color: 'var(--white)',
    }),
    indicatorSeparator: () => ({
      backgroundColor: 'var(--off-white)',
      alignSelf: 'stretch',
      height: 12,
      width: '1px',
      boxSizing: 'border-box',
      display: 'none',
    }),
    menu: () => ({
      top: '100%',
      backgroundColor: 'var(--background-primary)',
      border: '2px solid var(--grey-accent)',
      borderRadius: '4px',
      boxShadow:
        '0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 12px hsl(0deg 0% 0% / 10%)',
      marginTop: '2px',
      position: 'absolute',
      width: full ? '100%' : 'fit-content',
      minWidth: width ? width : '140px',
      zIndex: 100000000,
      boxSizing: 'border-box',
      fontSize: '12px',
      color: 'var(--white)',
    }),
    MenuList: () => ({
      maxHeight: '300px',
      overflowY: 'auto',
      paddingBottom: '4px',
      paddingTop: '4px',
      position: 'relative',
      boxSizing: 'border-box',
      color: 'var(--white)',
    }),
    option: () => ({
      backgroundColor: 'transparent',
      color: 'var(--off-white)',
      cursor: 'pointer',
      display: 'flex',
      fontSize: '12px',
      padding: '3px 12px',
      alignItems: 'center',
      height: '32px',
      width: '',
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      '&:hover': {
        backgroundColor: 'var(--button-hover)',
      },
    }),
    input: base => ({
      ...base,
      color: 'var(--white)',
      fontSize: '12px',
    }),
    placeholder: base => ({
      ...base,
      color: 'var(--light-grey-accent)',
    }),
  }
}
