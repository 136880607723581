import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import * as d3 from 'd3'
import numeral from 'numeral'
import {
  Tooltip,
  calculateTextWidth,
  splitText,
  calculateTextHeight,
  calculateValueTextWidth,
} from './utils'

export const TreeMap = ({
  data,
  startColor = '#ffd700',
  endColor = '#ff8c00',
  percentage = false,
  whiteLabel = false,
  hoveredSymbol,
  setHoveredSymbol,
}) => {
  const containerRef = useRef(null)
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

  const [tooltip, setTooltip] = useState({
    visible: false,
    content: null,
    position: { x: 0, y: 0 },
  })

  const handleMouseOver = useCallback(
    (event, node) => {
      setTooltip({
        visible: true,
        content: (
          <div>
            <span>Label: {node.data.label}</span>
            <br />
            <span>Value: {numeral(node.value).format('0.00')}%</span>
          </div>
        ),
        position: { x: event.clientX, y: event.clientY },
      })
    },
    [setTooltip]
  )

  const handleMouseMove = useCallback(event => {
    setTooltip(prevState => ({
      ...prevState,
      position: { x: event.clientX, y: event.clientY },
    }))
  }, [])

  const handleMouseOut = useCallback(() => {
    setTooltip({ visible: false, content: null, position: { x: 0, y: 0 } })
    setHoveredSymbol(null)
  }, [setTooltip, setHoveredSymbol])

  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        setDimensions({
          width: containerRef.current.offsetWidth,
          height: containerRef.current.offsetHeight,
        })
      }
    }

    updateDimensions()

    const resizeObserver = new ResizeObserver(updateDimensions)
    resizeObserver.observe(containerRef.current)

    return () => resizeObserver.disconnect()
  }, [])

  const nodes = useMemo(() => {
    if (dimensions.width && dimensions.height) {
      const root = d3
        .hierarchy(data)
        .sum(d => d.value)
        .sort((a, b) => b.height - a.height || b.value - b.value)
      const treemapRoot = d3
        .treemap()
        .size([dimensions.width, dimensions.height])
        .padding(6)(root)
      const nodes = treemapRoot.leaves()

      const colorScale = d3
        .scaleSequential()
        .domain([0, nodes.length - 1])
        .interpolator(d3.interpolateHsl(startColor, endColor))

      return nodes.map((node, index) => ({
        ...node,
        fill: colorScale(index),
      }))
    }
    return []
  }, [data, dimensions, startColor, endColor])

  return (
    <div ref={containerRef} style={{ width: '100%', height: '100%' }}>
      <svg width={dimensions.width} height={dimensions.height}>
        {nodes.map((d, i) => {
          const label = d.data.label
          const valueText = percentage
            ? `${numeral(d.value).format('0.00')}%`
            : d.value
          const fontSize = 13
          const rectWidth = d.x1 - d.x0 - 20
          const rectHeight = d.y1 - d.y0 - 4

          let splitLabel = [label]
          const maxCharLength = Math.floor(rectWidth / (fontSize * 0.6))

          const showText = () => {
            const labelWidth = calculateTextWidth(label)
            if (labelWidth <= rectWidth) {
              return true
            }

            splitLabel = splitText(label, maxCharLength)
            const splitLabelWidth1 = calculateTextWidth(splitLabel[0], fontSize)
            const splitLabelWidth2 = calculateTextWidth(splitLabel[1], fontSize)

            return (
              splitLabelWidth1 <= rectWidth &&
              splitLabelWidth2 <= rectWidth &&
              labelFits
            )
          }

          const isLabelFitInHeight = () => {
            splitLabel = splitText(label, maxCharLength)
            const labelHeight =
              calculateTextHeight(fontSize) * splitLabel.length

            return labelHeight <= rectHeight
          }

          const labelFits = isLabelFitInHeight()

          const shouldShowLabel = showText()

          const showValueText = () => {
            const valueWidth = calculateValueTextWidth(valueText)

            return valueWidth <= rectWidth
          }

          const isTextFitInHeight = () => {
            const labelHeight =
              calculateTextHeight(fontSize) * splitLabel.length
            const valueHeight = calculateTextHeight(fontSize)
            const combinedHeight = labelHeight + valueHeight

            return combinedHeight <= rectHeight
          }

          const textFits = isTextFitInHeight()

          const shouldShowValueText = showValueText()

          return (
            <React.Fragment key={i}>
              <rect
                x={d.x0}
                y={d.y0}
                width={d.x1 - d.x0}
                height={d.y1 - d.y0}
                fill={d.fill}
                stroke={hoveredSymbol === d.data.label ? '#d9d9d9' : d.fill}
                strokeWidth={2}
                ry={4}
                rx={4}
                onMouseOver={e => {
                  handleMouseOver(e, d)
                  setHoveredSymbol(d.data.label)
                }}
                onMouseMove={handleMouseMove}
                onMouseOut={handleMouseOut}
              />
              {shouldShowLabel &&
                labelFits &&
                splitLabel.map((line, index) => (
                  <text
                    key={index}
                    x={d.x0 + 6}
                    y={d.y0 + 18 + index * 18}
                    fill={whiteLabel ? 'white' : 'black'}
                    fontSize={fontSize}
                    fontWeight={500}
                    onMouseOver={e => {
                      handleMouseOver(e, d)
                      setHoveredSymbol(d.data.label)
                    }}
                    onMouseMove={handleMouseMove}
                    onMouseOut={handleMouseOut}
                  >
                    {line}
                  </text>
                ))}
              {shouldShowValueText && textFits && (
                <text
                  x={d.x0 + 6}
                  y={
                    d.y0 +
                    (shouldShowLabel && labelFits
                      ? splitLabel.length * 18 + 18
                      : 18)
                  }
                  fill={whiteLabel ? 'white' : 'black'}
                  fontSize={fontSize}
                  fontWeight={500}
                  onMouseOver={e => {
                    handleMouseOver(e, d)
                    setHoveredSymbol(d.data.label)
                  }}
                  onMouseMove={handleMouseMove}
                  onMouseOut={handleMouseOut}
                >
                  {valueText}
                </text>
              )}
            </React.Fragment>
          )
        })}
      </svg>
      {tooltip.visible && (
        <Tooltip
          content={tooltip.content}
          position={tooltip.position}
          dimensions={dimensions}
        />
      )}
    </div>
  )
}
