import { useState, useMemo } from 'react'
import { useQuery } from 'react-query'

import dataUtils from '../utils'
import API from '../queries'
import { startCase } from 'lodash'

export const useDataSource = () => {
  const [institution, setInstitution] = useState(
    dataUtils.splitInstitutionalTickerValue('0001067983,BERKSHIRE HATHAWAY INC')
  )

  // console.log(institution)

  const {
    data: PortfolioComposition,
    isLoading: PortfolioCompositionIsLoading,
  } = useQuery(
    ['fetchInstitutionalHoldingsPortfolioComposition', institution.cik],
    async () => {
      const res = await API.fetchInstitutionalHoldingsPortfolioComposition(
        institution.cik
      )
      return res
    }
  )

  const {
    data: PortfolioPositionsSummary,
    isLoading: PortfolioPositionsSummaryIsLoading,
  } = useQuery(
    ['fetchInstitutionalHoldingsPortfolioPositionsSummary', institution.cik],
    async () => {
      const res = await API.fetchInstitutionalHoldingsPortfolioPositionsSummary(
        institution.cik
      )

      return res
    }
  )

  const {
    data: PortfolioIndustrySummary,
    isLoading: PortfolioIndustrySummaryIsLoading,
  } = useQuery(
    ['fetchInstitutionalHoldingsPortfolioIndustrySummary', institution.cik],
    async () => {
      const res = await API.fetchInstitutionalHoldingsPortfolioIndustrySummary(
        institution.cik
      )

      return res
    }
  )

  const modifiedPortfolioIndustrySummary = PortfolioIndustrySummary?.map(
    item => {
      return {
        ...item,
        industryTitle: startCase(item?.industryTitle?.toLowerCase()),
      }
    }
  )

  //TODO: TEMP FIX ASK FMP TO FIX

  let modifiedPortfolioPositionsSummary = PortfolioPositionsSummary
  if (
    institution.cik === '0001067983' &&
    institution.name === 'BERKSHIRE HATHAWAY INC'
  ) {
    modifiedPortfolioPositionsSummary = [...(PortfolioPositionsSummary || [])]
    if (modifiedPortfolioPositionsSummary.length > 0) {
      modifiedPortfolioPositionsSummary[0].marketValue = 345189447807
    }
  }

  const threshold = 30

  const currentMarketValue = PortfolioPositionsSummary?.[0]?.marketValue

  const pieData = useMemo(() => {
    if (PortfolioComposition && currentMarketValue) {
      const topList = PortfolioComposition.slice(0, threshold)?.map(value => ({
        value: value?.marketValue,
        label: value?.symbol,
      }))
      let otherMarketValue = PortfolioComposition?.slice(threshold)?.reduce(
        (acc, value) => {
          return acc + value?.marketValue
        },
        0
      )

      const topListReduce = topList?.reduce((acc, item) => acc + item?.value, 0)

      const discrepancy = currentMarketValue - topListReduce - otherMarketValue
      if (discrepancy !== 0) {
        otherMarketValue += discrepancy
      }

      const othersAggregate = { value: otherMarketValue, label: 'Other' }

      return [...topList, othersAggregate]
    }
    return []
  }, [PortfolioComposition, currentMarketValue])

  const industryThreshold = 7

  const IndustryDataPieChart = useMemo(() => {
    if (modifiedPortfolioIndustrySummary) {
      const topList = modifiedPortfolioIndustrySummary
        ?.slice(0, industryThreshold)
        ?.map(item => ({
          value: item?.weight,
          label: item?.industryTitle,
          // label: startCase(item?.industryTitle?.toLowerCase()),
        }))

      let otherWeight = modifiedPortfolioIndustrySummary
        ?.slice(industryThreshold)
        ?.reduce((acc, value) => {
          return acc + value?.weight
        }, 0)

      const topListReduce = topList?.reduce((acc, item) => acc + item?.value, 0)

      const discrepancy = 100 - topListReduce - otherWeight
      if (discrepancy !== 0) {
        otherWeight += discrepancy
      }

      const othersAggregate = { value: otherWeight, label: 'Other' }

      return [...topList, othersAggregate]
    }
    return []
  }, [modifiedPortfolioIndustrySummary])

  const newIndustryData = useMemo(() => {
    if (!modifiedPortfolioIndustrySummary) {
      return []
    }

    let otherWeight = 0
    const filteredData = modifiedPortfolioIndustrySummary?.reduce(
      (acc, item) => {
        if (item?.weight < 1) {
          otherWeight += item?.weight
          return acc
        } else {
          acc.push(item)
          return acc
        }
      },
      []
    )

    if (otherWeight > 0) {
      filteredData?.push({
        industryTitle: 'Other - Sub 1 Percent',
        weight: otherWeight,
      })
    }

    return filteredData
  }, [modifiedPortfolioIndustrySummary])

  const treeMapData = useMemo(() => {
    return {
      name: 'Industry Weightings',
      children: IndustryDataPieChart,
    }
  }, [IndustryDataPieChart])

  const InstitutionalView_UI = useMemo(() => {
    if (
      !PortfolioCompositionIsLoading &&
      !PortfolioPositionsSummaryIsLoading &&
      !PortfolioIndustrySummaryIsLoading
    ) {
      return {
        isLoading: false,
        Table: PortfolioComposition,
        KeyValueTable: PortfolioPositionsSummary,
        IndustryData: newIndustryData,
        // IndustryData: PortfolioIndustrySummary,
        // IndustryData: dataUtils.buildPieChartData(PortfolioIndustrySummary),
        PieChart: IndustryDataPieChart,
        ComposedChart: PortfolioPositionsSummary,
        PortfolioPositionsSummary: PortfolioPositionsSummary,
        PieData: pieData,
        treeMapData,
      }
    } else {
      return {
        isLoading: true,
      }
    }
  }, [
    PortfolioCompositionIsLoading,
    PortfolioPositionsSummaryIsLoading,
    PortfolioIndustrySummaryIsLoading,
    PortfolioComposition,
    PortfolioPositionsSummary,
    newIndustryData,
    IndustryDataPieChart,
    pieData,
    treeMapData,
  ])

  return {
    InstitutionalView_UI,
    // MarketValuePercentageChart: dataUtils?.getIndexedToPercentage(
    //   IndexHistorical,
    //   PortfolioPositionsSummary
    // ),
    institution,
    setInstitution,
  }
}
