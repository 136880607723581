import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import { CustomFilter } from '../../StringSelectStyles'
import numeral from 'numeral'
import MetricInfo from 'core/MetricInfoHover/MetricInfoTooltip'

const getDecimalFormat = value => {
  const decimals = String(value).split('.')[1]
  return decimals
    ? `0.${'0'.repeat(decimals.length)}`
    : '0a'
}

const generateThresholdOptions = (
  thresholdToUse,
  format
) => {
  const underOptions =
    thresholdToUse
      ?.map(threshold =>
        typeof threshold === 'number'
          ? {
              value: `<${threshold}`,
              label: `Under ${numeral(threshold).format(
                getDecimalFormat(threshold)
              )}`,
            }
          : null
      )
      ?.filter(Boolean) || []

  const overOptions =
    thresholdToUse
      ?.map(threshold =>
        typeof threshold === 'number'
          ? {
              value: `>${threshold}`,
              label: `Over ${numeral(threshold).format(
                getDecimalFormat(threshold)
              )}`,
            }
          : null
      )
      .filter(Boolean) || []

  const betweenOptions =
    thresholdToUse
      ?.map(threshold =>
        typeof threshold !== 'number'
          ? {
              value: `${threshold.min}-${threshold.max}`,
              label: `${numeral(threshold.min).format(
                getDecimalFormat(threshold.min)
              )} - ${numeral(threshold.max).format(
                getDecimalFormat(threshold.max)
              )}`,
            }
          : null
      )
      ?.filter(Boolean) || []

  return [
    ...underOptions,
    ...overOptions,
    ...betweenOptions,
  ]
}

export const ConstantBetweenFilter = ({
  label,
  handlePushFilter,
  type,
  metric,
  onSwitchToCustomFilter,
  thresholdToUse,
  thresholdType,
  filters,
}) => {
  const getLimits = value => {
    if (value[0] === '<') {
      return {
        min: Number.MIN_SAFE_INTEGER,
        max: parseFloat(value.substring(1)),
      }
    } else if (value[0] === '>') {
      return {
        min: parseFloat(value.substring(1)),
        max: Number.MAX_SAFE_INTEGER,
      }
    } else {
      const [min, max] = value.split('-').map(Number)
      return {
        min: min,
        max: max,
      }
    }
  }

  let options = []

  if (thresholdType === 'percent') {
    options = [
      ...thresholdToUse?.map(threshold => ({
        value: `<${threshold}`,
        label: `Under ${numeral(threshold).format('0%')}`,
      })),
      ...thresholdToUse?.map(threshold => ({
        value: `>${threshold}`,
        label: `Over ${numeral(threshold).format('0%')}`,
      })),
    ]
  } else if (thresholdType === 'overUnder') {
    options = [
      ...thresholdToUse?.map(threshold => ({
        value: `<${threshold}`,
        label: `Under ${numeral(threshold).format(
          getDecimalFormat(threshold)
        )}`,
      })),
      ...thresholdToUse?.map(threshold => ({
        value: `>${threshold}`,
        label: `Over ${numeral(threshold).format(
          getDecimalFormat(threshold)
        )}`,
      })),
    ]
  } else if (thresholdType === 'between') {
    options = thresholdToUse?.map(threshold => ({
      value: `${threshold.min}-${threshold.max}`,
      label: `${numeral(threshold.min).format(
        '0a'
      )} - ${numeral(threshold.max).format('0a')}`,
    }))
  } else if (thresholdType === 'betweenAndOverUnder') {
    options = generateThresholdOptions(thresholdToUse, '0a')
  } else if (thresholdType === 'ratios') {
    options = generateThresholdOptions(
      thresholdToUse,
      '0.0'
    )
  } else if (thresholdType === 'mixed') {
    options = thresholdToUse
      ?.map((threshold, index) => {
        if (typeof threshold === 'number') {
          if (index === 0) {
            return {
              value: `<${threshold}`,
              label: `Under ${numeral(threshold).format(
                '0a'
              )}`,
            }
          } else {
            return {
              value: `>${threshold}`,
              label: `Over ${numeral(threshold).format(
                '0a'
              )}`,
            }
          }
        } else if (threshold.min && threshold.max) {
          return {
            value: `${threshold.min}-${threshold.max}`,
            label: `${numeral(threshold.min).format(
              '0a'
            )} - ${numeral(threshold.max).format('0a')}`,
          }
        }
      })
      .filter(Boolean)
  }

  const customOption = { value: 'custom', label: 'Custom' }

  options.unshift(customOption)

  const handleSelectionChange = selectedOption => {
    if (selectedOption === customOption) {
      onSwitchToCustomFilter()
    } else {
      const newLimits = getLimits(selectedOption.value)
      handlePushFilter({
        column: metric,
        type: type,
        config: {
          min: newLimits.min,
          max: newLimits.max,
        },
      })
    }
  }

  const isActive = filters?.some(
    filter => filter?.column === metric
  )

  const [selectedOption, setSelectedOption] = useState(null)

  const formatFilterToOption = filterConfig => {
    if (!filterConfig) return null
    const { min, max } = filterConfig?.config
    if (min === Number.MIN_SAFE_INTEGER) return `<${max}`
    if (max === Number.MAX_SAFE_INTEGER) return `>${min}`
    return `${min}-${max}`
  }

  useEffect(() => {
    const filterConfig = filters?.find(
      filter => filter?.column === metric
    )
    const optionValue = formatFilterToOption(filterConfig)
    const matchingOption = options?.find(
      option => option?.value === optionValue
    )
    setSelectedOption(matchingOption)
  }, [filters, metric, options])

  return (
    <div className="filter-input-container">
      <div className="filter-input-label">
        <div>
          <MetricInfo
            metricKey={metric}
            displayMetric={label}
          />
        </div>
      </div>
      <div className="filter-input-min-max">
        <Select
          value={selectedOption}
          onChange={handleSelectionChange}
          options={options}
          placeholder={`Any`}
          styles={{
            ...CustomFilter(isActive),
            menuPortal: base => ({ ...base, zIndex: 9999 }),
          }}
          isSearchable={false}
          menuPortalTarget={document.body}
          menuPosition={'fixed'}
        />
      </div>
    </div>
  )
}
