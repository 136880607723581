import React, { useState, useMemo } from 'react'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { useDropdownValues, useScreenerColumns } from '../../hooks'
import {
  DROPDOWN_METRICS,
  PresetList,
  BETWEEN,
  STRING,
  CATEGORY_WIDTHS,
} from '../constants'
import styles from './screenerMetricCatalog.module.scss'
import { FilterInput } from './FilterInput'
import { FilterPill } from '../FilterPill'
import Fuse from 'fuse.js'
import { countryCodes } from './countryCodes'
import { utils } from '../../utils'
import {
  UilAngleDoubleRight,
  UilAngleDoubleLeft,
  UilFilter,
} from '@iconscout/react-unicons'
import { WavyButton } from 'core/WavyButton/WavyButton'
import Input from 'core/Input/Input'

export const MetricCatalog = ({
  handleClose,
  height,
  isDropdownVisible,
  filters,
  handleDeleteFilter,
  activeTab,
  setActiveTab,
  useCustomFilter,
  setUseCustomFilter,
  isView,
  aiView,
  setAiView,
  chat,
  ...rest
}) => {
  const { data, isLoading } = useScreenerColumns()
  const { data: dropdownOptions, isLoading: dropdownOptionsIsLoading } =
    useDropdownValues()

  const transformedDropdownOptions = useMemo(() => {
    if (dropdownOptions?.country) {
      return {
        ...dropdownOptions,
        country: dropdownOptions.country.map(({ label, value }) => ({
          label: countryCodes[label] || label,
          value,
        })),
      }
    }
    return dropdownOptions
  }, [dropdownOptions])

  const [searchValue, setSearchValue] = useState('')
  const [preSearchActiveTab, setPreSearchActiveTab] = useState(1)
  const [selectedCategory, setSelectedCategory] = useState('')
  const [customFilterStates, setCustomFilterStates] = useState({})

  const minColumnWidth = CATEGORY_WIDTHS[selectedCategory] || 320

  const handleTabSelect = index => {
    setActiveTab(index + 1)
    if (!searchValue) {
      setPreSearchActiveTab(index + 1)
    }
  }

  const MetricList = useMemo(
    () => PresetList(data, searchValue),
    [searchValue, data]
  )

  const options = {
    keys: ['label', 'metric'],
    includeScore: true,
    threshold: 0.3,
    location: 0,
    distance: 100,
    shouldSort: false,
    ignoreLocation: false,
  }

  const fuse = new Fuse(
    MetricList.flatMap(({ metrics }) => metrics),
    options
  )

  const handleFilterItems = value => {
    setSearchValue(value)
    if (value) {
      setActiveTab(0)
    } else {
      setActiveTab(preSearchActiveTab)
    }
  }

  // const metricStyles = {
  //   gridTemplateColumns: `repeat(auto-fill, minmax(${minColumnWidth}px, 1fr))`,
  // }

  const getFilteredMetrics = metrics => {
    return searchValue
      ? Array.from(new Set(fuse.search(searchValue).map(result => result.item)))
      : metrics
  }

  const setUseCustomFilterForMetric = (metric, value) => {
    setCustomFilterStates(prevState => ({
      ...prevState,
      [metric]: value,
    }))
  }

  return (
    <div
      className={`metric-catalog-container ${
        isDropdownVisible ? 'visible' : ''
      }`}
      style={{
        overflow: 'hidden',
        height: '100%',
      }}
    >
      {!isDropdownVisible ? (
        <div
          style={{
            height: '100%',
            width: '40px',
            backgroundColor: 'var(--background-secondary)',
            border: '2px solid var(--grey-accent)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            cursor: 'pointer',
          }}
          onClick={() => handleClose()}
        >
          <UilAngleDoubleRight size="36" color={'var(--light-grey-accent'} />
          <UilFilter size="30" color={'var(--primary-color'} />
          <UilAngleDoubleRight size="36" color={'var(--light-grey-accent'} />
        </div>
      ) : (
        !isLoading &&
        !dropdownOptionsIsLoading && (
          <div
            className={styles.metricCatalogContainer}
            style={{ height: height }}
          >
            <div
              className={styles.closeAndCatalogButton}
              style={{ height: '100%' }}
            >
              <div
                className={styles.metricCatalogCloseContainer}
                onClick={() => handleClose()}
              >
                <UilAngleDoubleLeft size="24" />
                <span className={styles.closeText}>Close Filters</span>
              </div>
              {aiView ? (
                chat
              ) : (
                <Tabs
                  className={styles.metricTabs}
                  selectedIndex={activeTab}
                  onSelect={handleTabSelect}
                  style={{
                    height: '100%',
                  }}
                >
                  <TabList className={styles.screenerTabListWrapper}>
                    <div className={styles.screenerTabList}>
                      {MetricList.map(({ category }, index) => {
                        if (category === 'All Metrics') {
                          return null
                        }
                        return (
                          <React.Fragment key={index}>
                            <Tab
                              className={styles.catalogTab}
                              onClick={() => {
                                setActiveTab(index)
                                setSelectedCategory(category)
                              }}
                              style={{
                                color:
                                  activeTab === index
                                    ? 'var(--primary-color)'
                                    : 'var(--off-white)',
                                backgroundColor: 'var(--background-primary)',
                              }}
                            >
                              <span className={styles.tabLabel}>
                                {category}
                              </span>
                            </Tab>
                            {/* {index !== MetricList.length - 1 && (
                            <div className={styles.tabDivider} />
                          )} */}
                          </React.Fragment>
                        )
                      })}
                    </div>
                    <input
                      className={styles.metricCatalogSearch}
                      placeholder={'Search all metrics...'}
                      type="text"
                      onChange={e => handleFilterItems(e.target.value)}
                    />
                  </TabList>
                  {MetricList.map(({ metrics }) => {
                    const filteredMetrics = getFilteredMetrics(metrics)
                    return (
                      <TabPanel className={styles.tabPanel}>
                        <div
                          className={styles.metricListWrapper}
                          // style={ metricStyles}
                        >
                          {filteredMetrics.map(metric => {
                            const filterType = DROPDOWN_METRICS.includes(
                              metric.metric
                            )
                              ? STRING
                              : BETWEEN
                            return (
                              <FilterInput
                                label={metric.label}
                                metric={metric.metric}
                                type={filterType}
                                hasFactor={metric.hasFactor}
                                options={
                                  transformedDropdownOptions?.[metric.metric]
                                }
                                category={metric.category}
                                thresholdToUse={metric.thresholdToUse}
                                thresholdType={metric.thresholdType}
                                useCustomFilter={
                                  customFilterStates[metric.metric]
                                }
                                setUseCustomFilter={value =>
                                  setUseCustomFilterForMetric(
                                    metric.metric,
                                    value
                                  )
                                }
                                {...rest}
                              />
                            )
                          })}
                        </div>
                      </TabPanel>
                    )
                  })}
                  <div className={styles.aiScreenerButton}>
                    <WavyButton handleClick={() => setAiView(true)}>
                      Use AI Screener
                    </WavyButton>
                  </div>
                </Tabs>
              )}
            </div>
          </div>
        )
      )}
    </div>
  )
}

export default React.memo(MetricCatalog)
