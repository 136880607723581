import moment from 'moment'
import styles from '../NewsView/NewsColumns.module.scss'
import dayjs from 'dayjs'
import { subtractWeekdays } from './utils'
import { financialMetrics } from '../CompanyAnalytics/constants'

export const COMPANY_OVERVIEW_BASE_URL = 'companyOverview'
export const CompanyNewsColumns = [
  {
    Header: 'Stock News',
    accessor: 'title',
    filter: 'none',
    Cell: Cell => {
      return (
        <>
          <div className={styles.NewsWrapper}>
            <a
              href={Cell?.row?.original?.url}
              target="_blank"
              rel="noreferrer"
              className={styles.thingig}
            >
              {Cell.value}
            </a>
            <div
              style={{ color: 'var(--light-grey-accent)' }}
              href={Cell?.row?.original?.url}
              target="_blank"
              rel="noreferrer"
              className={styles.newsTitle1}
            >
              {Cell?.row?.original?.site}
            </div>
          </div>
        </>
      )
    },
    width: 400,
    maxWidth: 10000,
  },
  {
    Header: '',
    accessor: 'publishedDate',
    Cell: Cell => {
      const currentDate = dayjs()
      return (
        <div style={{ color: 'var(--light-grey-accent)' }}>
          {dayjs(Cell.value).isSame(currentDate, 'day')
            ? dayjs(Cell.value).format('h:mm a')
            : dayjs(Cell.value).format('MMM DD, YY')}
        </div>
      )
    },
    width: 70,
    maxWidth: 70,
  },
]

const now = moment().toDate()


export const OverviewIntervals = [
  {
    label: '1D',
    from: subtractWeekdays(moment().startOf('day').toDate()),
    to: now,
  },
  {
    label: '5D',
    from: subtractWeekdays(moment().startOf('day').toDate(), 5),
    to: now,
  },
  {
    label: '1M',
    from: moment().startOf('day').subtract(1, 'months').toDate(),
    to: now,
  },
  {
    label: '3M',
    from: moment().startOf('day').subtract(3, 'months').toDate(),
    to: now,
  },
  {
    label: '6M',
    from: moment().startOf('day').subtract(6, 'months').toDate(),
    to: now,
  },
  {
    label: 'YTD',
    from: moment().startOf('year').toDate(),
    to: now,
  },
  {
    label: '1Y',
    from: moment().startOf('day').subtract(1, 'years').toDate(),
    to: moment().startOf('day').toDate(),
  },
  {
    label: '2Y',
    from: moment().startOf('day').subtract(2, 'years').toDate(),
    to: moment().startOf('day').toDate(),
  },
  {
    label: '3Y',
    from: moment().startOf('day').subtract(3, 'years').toDate(),
    to: moment().startOf('day').toDate(),
  },
  {
    label: '5Y',
    from: moment().startOf('day').subtract(5, 'years').toDate(),
    to: moment().startOf('day').toDate(),
  },
  {
    label: '10Y',
    from: moment().startOf('day').subtract(10, 'years').toDate(),
    to: moment().startOf('day').toDate(),
  },
  {
    label: 'All',
    from: moment().startOf('day').subtract(50, 'years').toDate(),
    to: moment().startOf('day').toDate(),
  },
]

export const QuoteMetrics = [
  {
    header: 'Price',
    key: 'price',
    // selected: true,
  },
  {
    header: 'Market Cap',
    key: 'marketCap',
    // format: 'number',
    selected: true,
  },
  {
    header: '% Change',
    key: 'changesPercentage',
    // selected: true,
    format: 'percent',
  },
  {
    header: 'Volume',
    key: 'volume',
    format: 'number',
    selected: true,
  },
  {
    header: 'Average Volume',
    key: 'avgVolume',
    format: 'number',
    selected: true,
  },
  {
    header: 'Year High',
    key: 'yearHigh',
    // selected: true,
  },
  {
    header: 'Year Low',
    key: 'yearLow',
    // selected: true,
  },
  {
    header: '50 Day Average Price',
    key: 'priceAvg50',
    selected: true,
  },
  {
    header: 'Beta',
    key: 'beta',
    selected: true,
  },
  {
    header: 'Change',
    key: 'change',
  },
  {
    header: 'Day High',
    key: 'dayHigh',
  },
  {
    header: 'Open',
    key: 'open',
  },
  {
    header: 'Previous Close',
    key: 'previousClose',
  },
  {
    header: '200 Day Average Price',
    key: 'priceAvg200',
    selected: true,
  },
  {
    header: 'Company Name',
    key: 'companyName',
    selected: true,
  },
  {
    header: 'Industry',
    key: 'industry',
    selected: true,
  },
  {
    header: 'Sector',
    key: 'sector',
    selected: true,
  },
  {
    header: 'Country',
    key: 'country',
    selected: true,
  },
  {
    header: 'Exchange',
    key: 'exchangeShortName',
    selected: true,
  },
  {
    header: 'Currency',
    key: 'currency',
    selected: true,
  },
  {
    header: 'Full Time Employees',
    key: 'fullTimeEmployees',
    format: 'number',
    selected: true,
  },
  {
    header: 'Next Earnings',
    key: 'earningsAnnouncement',
    format: 'date',
    selected: true,
  },
  {
    header: 'Website',
    key: 'website',
    format: 'link',
    selected: true,
  },
  {
    header: 'Shares Outstanding',
    key: 'sharesOutstanding',
    selected: true,
  },
  {
    header: 'Beta',
    key: 'beta',
    // selected: true,
  },
  {
    header: 'IPO Date',
    key: 'ipoDate',
    format: 'date',
    // selected: true,
  },
  {
    header: 'CEO',
    key: 'ceo',
  },
  {
    header: 'ISIN',
    key: 'isin',
  },
  {
    header: 'CUSIP',
    key: 'cusip',
  },
  {
    header: 'Zip',
    key: 'zip',
  },
  {
    header: 'State',
    key: 'state',
  },
  {
    header: 'City',
    key: 'city',
  },
  {
    header: 'Address',
    key: 'address',
  },
  {
    header: 'Phone Number',
    key: 'phone',
  },
]

export const RatioMetrics = [
  {
    header: 'Dividend Yield %',
    key: 'dividendYielPercentageTTM',
    selected: true,
  },
  {
    header: 'Price / Earnings Ratio',
    key: 'peRatioTTM',
    selected: true,
  },
  {
    header: 'Price / Book Ratio',
    key: 'priceToBookRatioTTM',
    selected: true,
  },
  {
    header: 'Return on Equity',
    key: 'returnOnEquityTTM',
    selected: true,
  },
  {
    header: 'Enterprise Value Multiple',
    key: 'enterpriseValueMultipleTTM',
    selected: true,
  },
  {
    header: 'Current Ratio',
    key: 'currentRatioTTM',
    selected: true,
  },
  {
    header: 'PEG Ratio',
    key: 'pegRatioTTM',
    selected: true,
  },
  {
    header: 'Quick Ratio',
    key: 'quickRatioTTM',
    selected: true,
  },
  {
    header: 'Cash Ratio',
    key: 'cashRatioTTM',
    selected: true,
  },
  {
    header: 'Payout Ratio',
    key: 'payoutRatioTTM',
    selected: true,
  },
  {
    header: 'Days of Sales Outstanding',
    key: 'daysOfSalesOutstandingTTM',
    selected: true,
  },
  {
    header: 'Days of Inventory Outstanding',
    key: 'daysOfInventoryOutstandingTTM',
  },
  {
    header: 'Operating Cycle',
    key: 'operatingCycleTTM',
  },
  {
    header: 'Days of Payables Outstanding',
    key: 'daysOfPayablesOutstandingTTM',
  },
  {
    header: 'Cash Conversion Cycle',
    key: 'cashConversionCycleTTM',
  },
  {
    header: 'Gross Profit Margin',
    key: 'grossProfitMarginTTM',
  },
  {
    header: 'Operating Profit Margin',
    key: 'operatingProfitMarginTTM',
  },
  {
    header: 'Pretax Profit Margin',
    key: 'pretaxProfitMarginTTM',
  },
  {
    header: 'Net Profit Margin',
    key: 'netProfitMarginTTM',
  },
  {
    header: 'Effective Tax Rate',
    key: 'effectiveTaxRateTTM',
  },
  {
    header: 'ROA',
    key: 'returnOnAssetsTTM',
  },
  {
    header: 'Return On Capital Employed',
    key: 'returnOnCapitalEmployedTTM',
  },
  {
    header: 'Net Income Per EBT',
    key: 'netIncomePerEBTTTM',
  },
  {
    header: 'EBT Per EBIT',
    key: 'ebtPerEbitTTM',
  },
  {
    header: 'EBIT Per Revenue',
    key: 'ebitPerRevenueTTM',
  },
  {
    header: 'Debt Ratio',
    key: 'debtRatioTTM',
  },
  {
    header: 'Debt/Equity',
    key: 'debtEquityRatioTTM',
  },
  {
    header: 'LT Debt To Mkt Cap',
    key: 'longTermDebtToCapitalizationTTM',
  },
  {
    header: 'Total Debt To Mkt Cap',
    key: 'totalDebtToCapitalizationTTM',
  },
  {
    header: 'Interest Coverage',
    key: 'interestCoverageTTM',
  },
  {
    header: 'Cash Flow To Debt Ratio',
    key: 'cashFlowToDebtRatioTTM',
  },
  {
    header: 'Company Equity Multiplier',
    key: 'companyEquityMultiplierTTM',
  },
  {
    header: 'Receivables Turnover',
    key: 'receivablesTurnoverTTM',
  },
  {
    header: 'Payables Turnover',
    key: 'payablesTurnoverTTM',
  },
  {
    header: 'Inventory Turnover',
    key: 'inventoryTurnoverTTM',
  },
  {
    header: 'Fixed Asset Turnover',
    key: 'fixedAssetTurnoverTTM',
  },
  {
    header: 'Asset Turnover',
    key: 'assetTurnoverTTM',
  },
  {
    header: 'Operating Cash Flow Per Share',
    key: 'operatingCashFlowPerShareTTM',
  },
  {
    header: 'Free Cash Flow Per Share',
    key: 'freeCashFlowPerShareTTM',
  },
  {
    header: 'Cash Per Share',
    key: 'cashPerShareTTM',
  },
  {
    header: 'OCF/Sales',
    key: 'operatingCashFlowSalesRatioTTM',
  },
  {
    header: 'FCF/OCF',
    key: 'freeCashFlowOperatingCashFlowRatioTTM',
  },
  {
    header: 'Cash Flow Coverage',
    key: 'cashFlowCoverageRatiosTTM',
  },
  {
    header: 'Short Term Coverage',
    key: 'shortTermCoverageRatiosTTM',
  },
  {
    header: 'Capital Expenditure Coverage',
    key: 'capitalExpenditureCoverageRatioTTM',
  },
  {
    header: 'Dividend Paid And Capex Coverage',
    key: 'dividendPaidAndCapexCoverageRatioTTM',
  },
  {
    header: 'P/S',
    key: 'priceToSalesRatioTTM',
  },
  {
    header: 'P/FCF',
    key: 'priceToFreeCashFlowsRatioTTM',
  },
  {
    header: 'P/OCF',
    key: 'priceToOperatingCashFlowsRatioTTM',
  },
  {
    header: 'P/CF Ratio',
    key: 'priceCashFlowRatioTTM',
  },
  {
    header: 'Price Sales Ratio',
    key: 'priceSalesRatioTTM',
  },
  {
    header: 'Dividend Yield',
    key: 'dividendYieldTTM',
  },
  {
    header: 'Price Fair Value',
    key: 'priceFairValueTTM',
  },
  {
    header: 'Dividend Per Share',
    key: 'dividendPerShareTTM',
  },
  {
    header: 'Dividend Yield',
    key: 'dividendYielTTM',
  },
  {
    header: 'Revenue',
    key: 'revenue',
    selected: true,
  },
  {
    header: 'Cost of Revenue',
    key: 'costOfRevenue',
  },
  {
    header: 'Gross Profit',
    key: 'grossProfit',
    selected: true,
  },
  {
    header: 'Gross Profit Ratio',
    key: 'grossProfitRatio',
  },
  {
    header: 'R&D Expenses',
    key: 'researchAndDevelopmentExpenses',
  },
  {
    header: 'G&A Expenses',
    key: 'generalAndAdministrativeExpenses',
  },
  {
    header: 'S&M Expenses',
    key: 'sellingAndMarketingExpenses',
  },
  {
    header: 'SG&A Expenses',
    key: 'sellingGeneralAndAdministrativeExpenses',
  },
  {
    header: 'Other Expenses',
    key: 'otherExpenses',
  },
  {
    header: 'Operating Expenses',
    key: 'operatingExpenses',
  },
  {
    header: 'Cost and Expenses',
    key: 'costAndExpenses',
  },
  {
    header: 'Interest Income',
    key: 'interestIncome',
  },
  {
    header: 'Interest Expense',
    key: 'interestExpense',
  },
  {
    header: 'Depreciation and Amortization',
    key: 'depreciationAndAmortization',
  },
  {
    header: 'EBITDA',
    key: 'ebitda',
    selected: true,
  },
  {
    header: 'EBITDA Ratio',
    key: 'ebitdaratio',
  },
  {
    header: 'Operating Income',
    key: 'operatingIncome',
  },
  {
    header: 'Operating Income Ratio',
    key: 'operatingIncomeRatio',
  },
  {
    header: 'Total Other Income/Expenses Net',
    key: 'totalOtherIncomeExpensesNet',
  },
  {
    header: 'Income Before Tax',
    key: 'incomeBeforeTax',
  },
  {
    header: 'Income Before Tax Ratio',
    key: 'incomeBeforeTaxRatio',
  },
  {
    header: 'Income Tax Expense',
    key: 'incomeTaxExpense',
  },
  {
    header: 'Net Income',
    key: 'netIncome',
    selected: true,
  },
  {
    header: 'Net Income Ratio',
    key: 'netIncomeRatio',
  },
  {
    header: 'EPS Diluted',
    key: 'epsdiluted',
  },
  {
    header: 'Weighted Avg Shrs Out',
    key: 'weightedAverageShsOut',
  },
  {
    header: 'Weighted Avg Shrs Out Dil',
    key: 'weightedAverageShsOutDil',
  },
  // balance Sheet
  {
    header: 'Cash and Cash Equivalents',
    key: 'cashAndCashEquivalents',
  },
  {
    header: 'Short-Term Investments',
    key: 'shortTermInvestments',
  },
  {
    header: 'Cash and Short-Term Investments',
    key: 'cashAndShortTermInvestments',
  },
  {
    header: 'Net Receivables',
    key: 'netReceivables',
  },
  {
    header: 'Inventory',
    key: 'inventory',
  },
  {
    header: 'Other Current Assets',
    key: 'otherCurrentAssets',
  },
  {
    header: 'Current Assets',
    key: 'totalCurrentAssets',
  },
  {
    header: 'Property Plant Equipment',
    key: 'propertyPlantEquipmentNet',
  },
  {
    header: 'Goodwill',
    key: 'goodwill',
  },
  {
    header: 'Intangibles',
    key: 'intangibleAssets',
  },
  {
    header: 'Goodwill and Intangibles',
    key: 'goodwillAndIntangibleAssets',
  },
  {
    header: 'LT Investments',
    key: 'longTermInvestments',
  },
  {
    header: 'Tax Assets',
    key: 'taxAssets',
  },
  {
    header: 'Other Non-Current Assets',
    key: 'otherNonCurrentAssets',
  },
  {
    header: 'Non-Current Assets',
    key: 'totalNonCurrentAssets',
  },
  {
    header: 'Other Assets',
    key: 'otherAssets',
  },
  {
    header: 'Total Assets',
    key: 'totalAssets',
    selected: true,
  },
  {
    header: 'Account Payables',
    key: 'accountPayables',
  },
  {
    header: 'Short Term Debt',
    key: 'shortTermDebt',
  },
  {
    header: 'Tax Payables',
    key: 'taxPayables',
  },
  {
    header: 'Deferred Revenue',
    key: 'deferredRevenue',
  },
  {
    header: 'Other Current Liabilities',
    key: 'otherCurrentLiabilities',
  },
  {
    header: 'Current Liabilities',
    key: 'totalCurrentLiabilities',
  },
  {
    header: 'Long-Term Debt',
    key: 'longTermDebt',
  },
  {
    header: 'Deferred Revenue (Non-Current)',
    key: 'deferredRevenueNonCurrent',
  },
  {
    header: 'Deferred Tax Liabilities (Non-Current)',
    key: 'deferredTaxLiabilitiesNonCurrent',
  },
  {
    header: 'Other Non-Current Liabilities',
    key: 'otherNonCurrentLiabilities',
  },
  {
    header: 'Non-Current Liabilities',
    key: 'totalNonCurrentLiabilities',
  },
  {
    header: 'Other Liabilities',
    key: 'otherLiabilities',
  },
  {
    header: 'Capital Lease Obligations',
    key: 'capitalLeaseObligations',
  },
  {
    header: 'Total Liabilities',
    key: 'totalLiabilities',
  },
  {
    header: 'Preferred Stock',
    key: 'preferredStock',
  },
  {
    header: 'Common Stock',
    key: 'commonStock',
  },
  {
    header: 'Retained Earnings',
    key: 'retainedEarnings',
  },
  {
    header: 'Accumulated Comprehensive Income/Loss',
    key: 'accumulatedOtherComprehensiveIncomeLoss',
  },
  {
    header: 'Other Total Stockholders Equity',
    key: 'othertotalStockholdersEquity',
  },
  {
    header: 'Stockholders Equity',
    key: 'totalStockholdersEquity',
    selected: true,
  },
  {
    header: 'Total Equity',
    key: 'totalEquity',
  },
  {
    header: 'Liabilities and Stockholders Equity',
    key: 'totalLiabilitiesAndStockholdersEquity',
  },
  {
    header: 'Minority Interest',
    key: 'minorityInterest',
  },
  {
    header: 'Liabilities and Equity',
    key: 'totalLiabilitiesAndTotalEquity',
  },
  {
    header: 'Total Investments',
    key: 'totalInvestments',
  },
  {
    header: 'Total Debt',
    key: 'totalDebt',
    selected: true,
  },
  {
    header: 'Net Debt',
    key: 'netDebt',
  },
  // cash flow

  {
    header: 'Net Income',
    key: 'netIncome',
  },
  {
    header: 'Depreciation and Amortization',
    key: 'depreciationAndAmortization',
  },
  {
    header: 'Deferred Income Tax',
    key: 'deferredIncomeTax',
  },
  {
    header: 'Stock-Based Compensation',
    key: 'stockBasedCompensation',
  },
  {
    header: 'Change in Working Capital',
    key: 'changeInWorkingCapital',
  },
  {
    header: 'Accounts Receivables',
    key: 'accountsReceivables',
  },
  {
    header: 'Inventory',
    key: 'inventory',
  },
  {
    header: 'Accounts Payables',
    key: 'accountsPayables',
  },
  {
    header: 'Other Working Capital',
    key: 'otherWorkingCapital',
  },
  {
    header: 'Other Non-Cash Items',
    key: 'otherNonCashItems',
  },
  {
    header: 'Cash Prov. by Operating Activities',
    key: 'netCashProvidedByOperatingActivities',
  },
  {
    header: 'Investments in Property, Plant, and Equipment',
    key: 'investmentsInPropertyPlantAndEquipment',
  },
  {
    header: 'Acquisitions (Net)',
    key: 'acquisitionsNet',
  },
  {
    header: 'Purchases of Investments',
    key: 'purchasesOfInvestments',
  },
  {
    header: 'Sales/Maturities of Investments',
    key: 'salesMaturitiesOfInvestments',
  },
  {
    header: 'Other Investing Activities',
    key: 'otherInvestingActivites',
  },
  {
    header: 'Cash Used for Investing Activities',
    key: 'netCashUsedForInvestingActivites',
  },
  {
    header: 'Debt Repayment',
    key: 'debtRepayment',
  },
  {
    header: 'Common Stock Issued',
    key: 'commonStockIssued',
  },
  {
    header: 'Common Stock Repurchased',
    key: 'commonStockRepurchased',
  },
  {
    header: 'Dividends Paid',
    key: 'dividendsPaid',
  },
  {
    header: 'Other Financing Activities',
    key: 'otherFinancingActivites',
  },
  {
    header: 'Cash Used Prov. by Financing Activities',
    key: 'netCashUsedProvidedByFinancingActivities',
  },
  {
    header: 'Effect of Forex Changes on Cash',
    key: 'effectOfForexChangesOnCash',
  },
  {
    header: 'Net Change in Cash',
    key: 'netChangeInCash',
  },
  {
    header: 'Cash at End of Period',
    key: 'cashAtEndOfPeriod',
  },
  {
    header: 'Cash at Beginning of Period',
    key: 'cashAtBeginningOfPeriod',
  },
  {
    header: 'Operating Cash Flow',
    key: 'operatingCashFlow',
  },
  {
    header: 'Capital Expenditure',
    key: 'capitalExpenditure',
    selected: true,
  },
  {
    header: 'Free Cash Flow',
    key: 'freeCashFlow',
    selected: true,
  },
]
