/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import numeral from 'numeral'
import styles from './DashboardCompanyQuote.module.scss'
import { CompanyQuoteTable } from '../CompanyQuoteTable'
import { SmallChart } from '../CompanyPriceChart'
import { ranges, plotByDayRanges, DAY_RANGE_1Y } from '../../constants'
import { fetchData, fetchGranularData } from '../../utils'
import arrowdown from '../../assets/arrowdown.svg'
import arrowup from '../../assets/arrowup.svg'

export const DashboardCompanyQuote = ({ ticker }) => {
  const [data, setData] = useState()
  const [date, setDate] = useState(ranges[DAY_RANGE_1Y])
  const [range, setRange] = useState(DAY_RANGE_1Y)
  useEffect(() => {
    plotByDayRanges.includes(range)
      ? fetchData(ticker, date.start, date.end).then(data_ => setData(data_))
      : fetchGranularData({ ...date, ticker }).then(data_ => setData(data_))
  }, [date, ticker])

  const defaultvalue = null

  return (
    data && (
      <div className={styles.companyQuote}>
        <div className={styles.companyQuoteLeft}>
          <div className={styles.companyQuoteLeftChart}>
            <SmallChart data={data.share_price_data} />
          </div>
          <div className={styles.companyQuoteLeftRange}>
            {Object.keys(ranges).map(key => {
              return (
                <div
                  title={'View ' + key}
                  className={styles.rangeItem}
                  style={{
                    color: ranges[key] === date && 'var(--primary-color)',
                  }}
                  onClick={() => {
                    setDate(ranges[key])
                    setRange(key)
                  }}
                >
                  {key}
                </div>
              )
            })}
          </div>
        </div>
        <div className={styles.companyQuoteRight}>
          <div className={styles.companyQuoteRightHeader}>
            <div className={styles.quoteTickerAndCompany}>
              <div className={styles.quotePrice}>
                {data?.quote_data[0].symbol}:
              </div>
              <div
                className={styles.quotePrice}
                title={data?.quote_data[0].name}
              >
                <p className={styles.quoteText}>{data?.quote_data[0].name}</p>
              </div>
            </div>
            <div className={styles.quoteWrapper}>
              <div className={styles.quotePrice}>
                {numeral(data?.quote_data[0].price).format('0,0.00 a')}
              </div>
              <div
                className={styles.quotePrice}
                style={{
                  color:
                    data?.quote_data[0].changesPercentage < 0
                      ? 'var(--red)'
                      : 'var(--green)',
                  gap: '0px',
                }}
              >
                <img
                  className={styles.arrowIcon}
                  src={
                    data?.quote_data[0].change < 0
                      ? arrowdown
                      : data?.quote_data[0].change >= 0
                      ? arrowup
                      : defaultvalue
                  }
                  alt="arrow"
                ></img>
                {numeral(data?.quote_data[0].change).format('0.00a')}
              </div>
              <div
                className={styles.quotePrice}
                style={{
                  color:
                    data?.quote_data[0].changesPercentage < 0
                      ? 'var(--red)'
                      : 'var(--green)',
                  gap: '0px',
                }}
              >
                {'('}
                {numeral(data?.quote_data[0].changesPercentage).format('0.00a')}
                %{')'}
              </div>
            </div>
          </div>
          <div className={styles.companyQuoteRightTable}>
            <CompanyQuoteTable data={data?.quote_data[0]} />
          </div>
        </div>
      </div>
    )
  )
}
