import React from 'react'
import numeral from 'numeral'

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'
import _ from 'lodash'

import { RANDOMCHARTCOLORS } from 'constants/theme'
import dayjs from 'dayjs'

const toPercent = decimal => `${(decimal * 100).toFixed(0)}%`

const getPercent = (value, total) => {
  const ratio = total > 0 ? value / total : 0

  return toPercent(ratio)
}

const renderToolTip = (props, i) => {
  const { payload } = props
  const total = payload.reduce((result, entry) => result + entry.value, 0)

  return (
    <ul className="customized-tooltip-content">
      {payload.map((entry, index) => {
        return (
          <div
            className="legendItem"
            style={{ color: entry.color, fontSize: '10.5px' }}
            key={`item-${index}`}
          >
            {entry?.name} {getPercent(entry.value, total)} &nbsp;
            {numeral(entry.value).format('0.000 a')}&nbsp;&nbsp;{' '}
          </div>
        )
      })}
    </ul>
  )
}

const renderLegend = props => {
  const { payload } = props

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        columnGap: '12px',
        height: '100%',
        width: '100%',
        flexWrap: 'wrap',
        lineHeight: '18px',
        marginLeft: '16px',
        justifyContent: 'center',
      }}
    >
      {payload.map((entry, index) => (
        <div
          key={`item-${index}`}
          style={{
            color: entry?.color,
            whiteSpace: 'nowrap',
            fontSize: '11px',
          }}
        >
          {_.startCase(entry.value)}
        </div>
      ))}
    </div>
  )
}

export const BarChartWrapper = ({ data, keys }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart margin={{ top: 0 }} width={500} height={100} data={data}>
        <CartesianGrid
          strokeDasharray="0"
          vertical={false}
          stroke="var(--button-hover)"
        />
        <XAxis
          dataKey="date"
          height={18}
          reversed="true"
          tickLine={{ stroke: 'var(--grey-accent)' }}
          axisLine={{ stroke: 'var(--grey-accent)' }}
          tick={{ stroke: 'var(--grey-accent)', fontWeight: '300' }}
          tickFormatter={tick => {
            return dayjs(tick).format('MMM D, YY')
          }}
        />
        <YAxis
          width={50}
          tick={{ stroke: 'var(--grey-accent)', fontWeight: '300' }}
          tickFormatter={tick => {
            return numeral(tick).format('0 a')
          }}
        />
        <Tooltip
          content={renderToolTip}
          contentStyle={{
            background: 'rgba(215,215,215, 0.3)',
            border: '0.5px solid var(--off-white)',
            borderRadius: '4px',
            padding: '4px',
            margin: '0',
            maxWidth: '310px',
            maxHeight: '60px',
          }}
        />
        <Legend content={renderLegend} />
        {Array.from(keys).map((key, i) => {
          return (
            <Bar
              dataKey={key}
              stackId="a"
              stroke="1c1c1c"
              fill={RANDOMCHARTCOLORS[i % 10]}
            />
          )
        })}
      </BarChart>
    </ResponsiveContainer>
  )
}
