import React, { useState } from 'react'
import styles from './Pricing.module.scss'
import { USERS } from './pricingConfig'
import { UilCheck } from '@iconscout/react-unicons'
import { useNavigate } from 'react-router-dom'
import { ToggleSwitch } from './components/Switch'
import { Auth } from 'aws-amplify'
import useIsAuthenticated from 'core/AuthComponents/useIsAuthenticated'
import { useUserGroup } from 'hooks/userGroupHook'
import { ConfirmModal } from '../UpdateModal/UpdateModal'

export const PricingTable = ({ jwt, setIsYearly, isYearly }) => {
  const [openModal, setOpenModal] = useState(false)
  const [selectedPlan, setSelectedPlan] = useState(null)

  const { userGroup, isUserInPlan } = useUserGroup()

  const { isDemoUser } = useIsAuthenticated()
  const navigate = useNavigate()
  const [username, setUsername] = useState('')

  const handleFreePlanClick = () => {
    navigate('/terminal/marketOverview')
  }

  const getButtonClassName = planName => {
    if (planName === 'Pro') {
      return styles.primeButton
    } else if (planName === 'Plus') {
      return styles.getStartedButton
    }
  }

  const getCardClassName = planName => {
    let className = styles.card
    if (planName === 'Pro') {
      className += ` ${styles.proCardGlow}`
    }
    return className
  }

  const getLookupKey = plan => {
    if (!plan) return ''
    return isYearly ? plan.prices.yearly.priceId : plan.prices.monthly.priceId
  }

  const handleOpenModal = plan => {
    setSelectedPlan(plan)
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const baseURL = process.env.REACT_APP_API_ORIGIN

  const handleFormSubmit = async event => {
    event.preventDefault()

    if (isDemoUser) {
      navigate('/login', { state: { from: '/pricing' } })
      return
    }

    try {
      const user = await Auth.currentAuthenticatedUser()
      const currentAccessToken = (await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()

      const form = event.target
      form.action = `${baseURL}api/create-checkout-session`
      form.cognitoUsername.value = user.username
      form.token.value = currentAccessToken

      form.submit()
    } catch (error) {
      console.error('Error:', error)
      navigate('/login', { state: { from: '/pricing' } })
    }
  }

  return (
    <div className={styles.pricingContainer}>
      <ToggleSwitch isYearly={isYearly} setIsYearly={setIsYearly} />
      <div className={styles.discountBanner}>
        Use code "COPILOT" for 30% off!
      </div>
      <div className={styles.cardWrapper}>
        {Object.values(USERS).map((plan, index) => (
          <div className={getCardClassName(plan.name)} key={index}>
            <div className={styles.cardHeader}>
              <div className={styles.planName}>
                <span>{plan.name}</span>
                {plan.name === 'Pro' && (
                  <div className={styles.mostPopular}>Most Popular</div>
                )}
              </div>
              <div className={styles.planDescription}>{plan.description}</div>
              <div className={styles.planPrice}>
                {isYearly
                  ? plan.prices.yearly.price
                  : plan.prices.monthly.price}
                <span className={styles.planPriceTerm}>CAD /month</span>
              </div>
            </div>
            <div className={styles.cardBody}>
              <div className={styles.features}>
                {plan.featuresDesc.map((feature, featureIndex) => (
                  <div className={styles.featureItem} key={featureIndex}>
                    <UilCheck size={20} /> {feature}
                  </div>
                ))}
              </div>
              {plan.name === 'Free' ? (
                <button
                  onClick={handleFreePlanClick}
                  className={styles.freeButton}
                >
                  {plan.button}
                </button>
              ) : userGroup === plan.cognitoGroup ? (
                <button className={styles.selectedPlanButton}>
                  Current Plan
                </button>
              ) : isUserInPlan ? (
                <button
                  onClick={() => handleOpenModal(plan)}
                  className={getButtonClassName(plan.name)}
                >
                  {plan.name === 'Pro' ? 'Upgrade' : 'Downgrade'}
                </button>
              ) : (
                <form onSubmit={e => handleFormSubmit(e)} method="POST">
                  <input
                    type="hidden"
                    name="priceId"
                    value={getLookupKey(plan)}
                  />
                  <input
                    type="hidden"
                    name="cognitoUsername"
                    value={username}
                  />
                  <input
                    type="hidden"
                    name="groupName"
                    value={plan.cognitoGroup}
                  />
                  <input type="hidden" name="token" value={jwt} />
                  <button
                    type="submit"
                    className={getButtonClassName(plan.name)}
                  >
                    {plan.button}
                  </button>
                </form>
              )}
            </div>
          </div>
        ))}
      </div>
      {/* <ConfirmModal
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        selectedPlan={selectedPlan}
        handleUpdateSubscription={handleUpdateSubscription}
      /> */}
    </div>
  )
}
