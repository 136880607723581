import { useCallback, useMemo } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import http from '../network/httpClient'

const WATCHLIST_URL = 'papi/watchlist/'

async function createWatchlistFn({ body }) {
  let data = await http.post(`${WATCHLIST_URL}`, body)

  return data.data.message
}

async function readAllWatchlistsFn() {
  let data = await http.get(`${WATCHLIST_URL}summary`)
  return data.data
}

async function updateWatchlistFn({ watchlist_id, body }) {
  let data = await http.put(`${WATCHLIST_URL}${watchlist_id}`, body)

  return data.data
}

async function deleteWatchlistsFn({ watchlist_id }) {
  return http.delete(`${WATCHLIST_URL}/${watchlist_id}`)
}

export const useWatchlists = () => {
  // Get QueryClient from the context
  const queryClient = useQueryClient()

  const {
    data: readAll,
    isLoading: isReadAllWatchlistLoading,
    isError: isReadAllWatchlistError,
  } = useQuery([WATCHLIST_URL], () => readAllWatchlistsFn())

  const {
    error: isCreateError,
    isLoading: isCreateWatchlistLoading,
    mutate: createWatchlist,
  } = useMutation({
    mutationFn: createWatchlistFn,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [WATCHLIST_URL],
      }),
  })

  const { mutate: updateWatchlist } = useMutation({
    mutationFn: updateWatchlistFn,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [WATCHLIST_URL],
      }),
  })

  const {
    error: isDeleteError,
    isLoading: isDeleteWatchlistLoading,
    mutate: deleteWatchlist,
  } = useMutation({
    mutationFn: deleteWatchlistsFn,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [WATCHLIST_URL],
      }),
  })

  const appendTickerToWatchlist = useCallback(
    (ticker, watchlistId) => {
      const watchlistToAppendTo =
        readAll?.item?.Items.filter(
          watchlist => watchlist?.watchlistId === watchlistId
        )?.[0] || {}

      const existingTickersSet = new Set(watchlistToAppendTo.tickers)

      if (existingTickersSet.has(ticker)) {
        return {
          success: false,
          message: `Symbol ${ticker} already exist in watchlist.`,
        }
      }

      updateWatchlist({
        watchlist_id: watchlistId,
        body: {
          ...watchlistToAppendTo,
          tickers: [...watchlistToAppendTo.tickers, ticker],
        },
      })

      return {
        success: true,
        message: `The ticker ${ticker} was added to the watchlist.`,
      }
    },
    [readAll?.item?.Items, updateWatchlist]
  )

  const numberOfWatchlists = useMemo(() => {
    return readAll?.item?.Items?.length || Infinity
  }, [readAll])

  const isLoading =
    isCreateWatchlistLoading ||
    isDeleteWatchlistLoading ||
    isReadAllWatchlistLoading

  const isError = isReadAllWatchlistError || isCreateError || isDeleteError

  return {
    readAllForWatchlist: readAll,
    readAll,
    isLoading,
    isError,
    createWatchlist,
    deleteWatchlist,
    updateWatchlist,
    appendTickerToWatchlist,
    numberOfWatchlists,
  }
}

export default useWatchlists
