export function TransformFinancialStatementData(dataIN) {
  let data = [];

  let keys = Object.keys(dataIN[0]);

  for (let j = 0; j < keys.length - 2; j++) {
    let entryV2 = {
      key: keys[j],
    };
    dataIN.forEach((d, i) => {
      entryV2['Q' + i] = dataIN[i][keys[j]];
    });
    data.push(entryV2);
  }

  data = data.map((entry) => {
    return {
      ...entry,
      key: entry.key,
    };
  });

  let period = data.filter((entry) => entry.key === 'period');

  data = data.filter((entry) => entry.key !== 'period');

  data = [period[0], ...data];
  
  return data;
}
