import moment from 'moment'
import { subtractWeekdays } from '../CompanyOverview/utils'

export const descriptionLabels = [
  {
    key: 'sector',
    label: 'Sector',
    text: true,
  },
  {
    key: 'industry',
    label: 'Industry',
    text: true,
  },
  {
    key: 'ceo',
    label: 'CEO',
    text: true,
  },
  {
    key: 'country',
    label: 'Country',
    text: true,
  },
  {
    key: 'ipoDate',
    label: 'IPO Date',
    text: true,
  },
  {
    key: 'currency',
    label: 'Currency',
    text: true,
  },
  {
    key: 'fullTimeEmployees',
    label: 'Full Time Employees',
    text: true,
  },
  {
    key: 'website',
    label: 'Website',
    link: true,
  },
]

export const headQuarterLabels = [
  {
    key: 'country',
    label: 'Country',
    text: true,
  },
  {
    key: 'state',
    label: 'State',
    text: true,
  },
  {
    key: 'city',
    label: 'City',
    text: true,
  },
  {
    key: 'address',
    label: 'Address',
    text: true,
  },
  {
    key: 'zip',
    label: 'Zip',
    text: true,
  },
  {
    key: 'phone',
    label: 'Phone',
    text: true,
  },
]

export const Identification = [
  {
    key: 'isActivelyTrading',
    label: 'Actively Trading',
    text: true,
  },
  {
    key: 'isin',
    label: 'ISIN',
    text: true,
  },
  {
    key: 'cik',
    label: 'CIK',
    text: true,
  },
  {
    key: 'cusip',
    label: 'CUSIP',
    text: true,
  },
]

export const ProfileIntervals = [
  // {
  //   label: '1D',
  //   from: subtractWeekdays(moment().startOf('day').toDate(), 1),
  //   to: moment().startOf('day').toDate(),
  // },
  // {
  //   label: '5D',
  //   from: subtractWeekdays(moment().startOf('day').toDate(), 5),
  //   to: moment().startOf('day').toDate(),
  // },
  // {
  //   label: '1M',
  //   from: moment().startOf('day').subtract(1, 'months').toDate(),
  //   to: moment().startOf('day').toDate(),
  // },
  // {
  //   label: '3M',
  //   from: moment().startOf('day').subtract(3, 'months').toDate(),
  //   to: moment().startOf('day').toDate(),
  // },
  // {
  //   label: '6M',
  //   from: moment().startOf('day').subtract(6, 'months').toDate(),
  //   to: moment().startOf('day').toDate(),
  // },
  // {
  //   label: 'YTD',
  //   from: moment().startOf('year').toDate(),
  //   to: moment().startOf('day').toDate(),
  // },
  {
    label: '1Y',
    from: moment().startOf('day').subtract(1, 'years').toDate(),
    to: moment().startOf('day').toDate(),
  },
  {
    label: '2Y',
    from: moment().startOf('day').subtract(2, 'years').toDate(),
    to: moment().startOf('day').toDate(),
  },
  {
    label: '3Y',
    from: moment().startOf('day').subtract(3, 'years').toDate(),
    to: moment().startOf('day').toDate(),
  },
  {
    label: '5Y',
    from: moment().startOf('day').subtract(5, 'years').toDate(),
    to: moment().startOf('day').toDate(),
  },
  {
    label: '10Y',
    from: moment().startOf('day').subtract(10, 'years').toDate(),
    to: moment().startOf('day').toDate(),
  },
  {
    label: 'All',
    from: moment().startOf('day').subtract(50, 'years').toDate(),
    to: moment().startOf('day').toDate(),
  },
]
