import * as d3 from 'd3'
import numeral from 'numeral'
import './DThreeHeatmap.css'
import Spinner from 'core/ScrollableContainer/Spinner/spinner'

const currencyToCountryCode = currency => {
  const map = {
    USD: 'US',
    EUR: 'EU',
    GBP: 'GB',
    CAD: 'CA',
    AUD: 'AU',
    NZD: 'NZ',
    CHF: 'CH',
    JPY: 'JP',
  }
  return map[currency] || ''
}

const createForexHeatMap = (
  svgRef,
  data,
  isLoading,
  dataKey,
  currencies,
  setCountry,
  onClickTopLeft
) => {
  const render = () => {
    if (isLoading || !data) return

    const parentDiv = svgRef.current.parentElement
    const parentWidth = parentDiv.clientWidth
    const parentHeight = parentDiv.clientHeight

    const margin = {
      top: 30,
      right: 8,
      bottom: 8,
      left: 50,
    }

    const width = parentWidth - margin.left - margin.right
    const height = parentHeight - margin.top - margin.bottom

    const svg = d3.select(svgRef.current)
    svg.selectAll('*').remove()

    const x = d3.scaleBand().domain(currencies).range([0, width])
    const y = d3.scaleBand().domain(currencies).range([0, height])

    const g = svg
      .attr('width', parentWidth)
      .attr('height', parentHeight)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`)

    // currencies.forEach(currency => {
    //   const countryCode = currencyToCountryCode(currency)
    //   const flagUrl = `https://www.countryflagicons.com/FLAT/24/${countryCode}.png`

    //   g.append('image')
    //     .attr('x', -margin.left + 6)
    //     .attr('y', y(currency) + y.bandwidth() / 2 - 10)
    //     .attr('width', 20)
    //     .attr('height', 20)
    //     .attr('xlink:href', flagUrl)
    //     .attr('clip-path', 'circle(35%)')
    // })

    const handleMouseOver = (event, currencyX, currencyY) => {
      d3.selectAll(`[data-x='${currencyX}'][data-y='${currencyY}']`)
        .classed('theHighlighted', true)
        .attr('stroke', 'var(--light-grey-accent)')
        .attr('stroke-width', 2)
        .raise()

      d3.selectAll(
        `[data-label-rect-x='${currencyX}'], [data-label-rect-y='${currencyY}']`
      ).attr('fill', 'rgba(75, 75, 75, 0.5)')
    }

    const handleMouseOut = () => {
      d3.selectAll('.theHighlighted')
        .classed('theHighlighted', false)
        .attr('stroke', 'transparent')
        .attr('stroke-width', 2)
        .lower()

      d3.selectAll(`[data-label-rect-x], [data-label-rect-y]`).attr(
        'fill',
        'transparent'
      )
    }

    currencies.forEach(currency => {
      g.append('rect')
        .attr('x', -margin.left)
        .attr('y', y(currency))
        .attr('width', margin.left)
        .attr('height', y.bandwidth())
        .attr('fill', 'transparent')
        .attr('rx', 4)
        .attr('ry', 4)
        .attr('stroke', 'transparent')
        .attr('stroke-width', 2)
        .attr('data-label-rect-y', currency)
      g.append('text')
        .attr('x', -margin.left + 10)
        .attr('y', y(currency) + y.bandwidth() / 2)
        .attr('dy', '.35em')
        .attr('text-anchor', 'start')
        .style('font-size', 'var(--default-text)')
        .style('fill', 'var(--white)')
        .text(currency)
        .style('cursor', 'pointer')
    })

    currencies.forEach(currency => {
      g.append('rect')
        .attr('x', x(currency))
        .attr('y', -margin.top)
        .attr('width', x.bandwidth())
        .attr('height', margin.top)
        .attr('fill', 'transparent')
        .attr('rx', 4)
        .attr('ry', 4)
        .attr('stroke', 'transparent')
        .attr('stroke-width', 2)
        .attr('data-label-rect-x', currency)
      g.append('text')
        .attr('x', x(currency) + x.bandwidth() / 2)
        .attr('y', -margin.top / 2)
        .attr('dy', '.35em')
        .attr('text-anchor', 'middle')
        .style('font-size', 'var(--default-text)')
        .style('fill', 'var(--white)')
        .text(currency)
        .style('cursor', 'pointer')
    })

    g.append('rect')
      .attr('x', -margin.left)
      .attr('y', -margin.top)
      .attr('width', margin.left)
      .attr('height', margin.top)
      .attr('fill', 'transparent')
      .style('cursor', 'pointer')
      .on('click', onClickTopLeft)
    g.append('text')
      .attr('x', -margin.left / 2)
      .attr('y', -margin.top / 2)
      .attr('dy', '.35em')
      .attr('text-anchor', 'middle')
      .style('font-size', 'var(--default-text)')
      .style('fill', 'var(--white)')
      .on('click', onClickTopLeft)
      .text(dataKey === 'changesPercentage' ? '%Chg' : 'Price')
      .style('cursor', 'pointer')

    const filteredData = data.filter(entry => {
      if (entry && typeof entry.name === 'string') {
        const [baseCurrency, quoteCurrency] = entry.name.split('/')
        return (
          currencies?.includes(baseCurrency) &&
          currencies?.includes(quoteCurrency)
        )
      }
    })

    const maxVal = d3.max(filteredData, d => parseFloat(d[dataKey]))
    const minVal = d3.min(filteredData, d => parseFloat(d[dataKey]))

    const colorScalePositive = d3
      .scaleLinear()
      .domain([0, maxVal])
      .range(['#40954b', '#094519'])

    const colorScaleNegative = d3
      .scaleLinear()
      .domain([minVal, 0])
      .range(['#731e0f', '#e04b46'])

    const textGroup = svg
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`)

    currencies.forEach(currencyY => {
      currencies.forEach(currencyX => {
        const pair = `${currencyY}/${currencyX}`
        const datum = filteredData.find(d => d.name === pair)
        const value = datum ? parseFloat(datum[dataKey]) : null

        const onClickCell = () => {
          const pair = `${currencyY}${currencyX}`
          setCountry(pair)
        }

        const isDiagonal = currencyY === currencyX
        let color

        if (isDiagonal) {
          color = 'transparent'
        } else if (dataKey === 'price') {
          color = 'var(--grey-accent)'
        } else {
          if (value > 0) {
            color = colorScalePositive(value)
          } else if (value < 0) {
            color = colorScaleNegative(value)
          } else {
            color = 'transparent'
          }
        }

        g.append('rect')
          .attr('x', x(currencyX))
          .attr('y', y(currencyY))
          .attr('width', x.bandwidth())
          .attr('height', y.bandwidth())
          .attr('rx', 4)
          .attr('ry', 4)
          .attr('fill', color)
          .attr('stroke', 'transparent')
          .attr('stroke-width', 2)
          .attr('data-x', currencyX)
          .attr('data-y', currencyY)
          .style('cursor', 'pointer')
          .classed('cell', true)
          .on('click', isDiagonal ? null : onClickCell)
          .on('mouseover', event =>
            handleMouseOver(event, currencyX, currencyY)
          )
          .on('mouseout', handleMouseOut)
          .append('title')
          .text(
            `${pair}: ${
              value !== null
                ? dataKey === 'price'
                  ? numeral(value).format('0.000')
                  : ` ${numeral(value).format('0.00')}%`
                : 'N/A'
            }`
          )

        const textX = x(currencyX) + x.bandwidth() / 2
        const textY = y(currencyY) + y.bandwidth() / 2

        if (!isDiagonal) {
          textGroup
            .append('text')
            .attr('x', textX)
            .attr('y', textY)
            .attr('dy', '.35em')
            .attr('text-anchor', 'middle')
            .style('fill', 'white')
            .style('cursor', 'pointer')
            .on('click', onClickCell)
            .style('font-size', '12px')
            .on('mouseover', event =>
              handleMouseOver(event, currencyX, currencyY)
            )
            .on('mouseout', handleMouseOut)
            .text(
              value !== null
                ? dataKey === 'price'
                  ? numeral(value).format('0.000')
                  : ` ${numeral(value).format('0.00')}%`
                : ''
            )
        }
      })
    })
  }

  render()
}

export default createForexHeatMap
