import moment from 'moment'
import chroma from 'chroma-js'

import { UilCheckCircle, UilTimesCircle } from '@iconscout/react-unicons'

export const combineData = (...dataSources) => {
  return dataSources?.reduce((obj, data) => {
    return {
      ...obj,
      ...data,
    }
  }, {})
}

export const colorScale = chroma
  .scale(['#f84f31', '#21ad3d'])
  .mode('lch')
  .domain([0, 4.5])

export const getConsensusColor = consensus => {
  switch (consensus) {
    case 'Strong Buy':
    case 'Buy':
      return 'var(--green)'
    case 'Hold':
      return 'var(--yellow)'
    case 'Sell':
    case 'Strong Sell':
      return 'var(--red)'
    default:
      return 'var(--white)'
  }
}

export const subtractWeekdays = (date, days) => {
  let currentDate = moment(date).startOf('day')
  while (days > 0) {
    currentDate = currentDate.subtract(1, 'days')
    if (currentDate.isoWeekday() !== 6 && currentDate.isoWeekday() !== 7) {
      days -= 1
    }
  }
  return currentDate.toDate()
}

export const getDateKeyWithData = (data, keys) => {
  if (!data || !Array.isArray(data)) {
    return undefined
  }

  for (let key of keys) {
    for (let item of data) {
      if (item[key]) {
        return key
      }
    }
  }
  return undefined
}

export const calculateGrowth = (data, lookBack = 1, isCompounded = false) => {
  data?.sort((a, b) => new Date(a?.date) - new Date(b?.date))

  const growthData = []
  const keysToIgnore = new Set([
    'date',
    'symbol',
    'reportedCurrency',
    'cik',
    'fillingDate',
    'acceptedDate',
    'calendarYear',
    'period',
    'link',
    'finalLink',
  ])

  for (let i = data?.length - 1; i >= lookBack; i--) {
    let current = data[i]
    let previous = data[i - lookBack]
    let growth = {}

    for (let key in current) {
      if (!keysToIgnore?.has(key)) {
        let currentValue = current[key]
        let previousValue = previous[key]

        if (
          typeof currentValue === 'number' &&
          typeof previousValue === 'number' &&
          previousValue !== 0
        ) {
          let growthValue
          if (isCompounded) {
            growthValue =
              Math.pow(currentValue / previousValue, 1 / lookBack) - 1
          } else {
            growthValue = ((currentValue - previousValue) / previousValue) * 100
          }
          growth[`${key}Growth`] = growthValue
        } else {
          growth[`${key}Growth`] = null
        }
      }
    }

    growthData?.push({
      date: current.date,
      growth,
    })
  }

  return growthData
}

export const TooltipContent = ({ data }) => {
  const checksPassed = data?.score
  const checksFailed = 5 - data?.score
  const size = 18

  return (
    <div>
      <span>Category: {data?.name}</span>
      <br />
      <span>{data?.score}/5 Checks Passed </span>
      <br />
      {Array.from({ length: checksPassed }, (_, index) => (
        <UilCheckCircle key={index} color={'var(--green)'} size={size} />
      ))}
      {Array.from({ length: checksFailed }, (_, index) => (
        <UilTimesCircle key={index} color={'var(--red)'} size={size} />
      ))}
    </div>
  )
}
