import { useURLSyncedGlobalTicker } from 'pages/views/hooks'
import { fetches } from './queries'
import { useQuery } from 'react-query'
import { useMemo, useState } from 'react'
import dayjs from 'dayjs'
import { mergeDataByDate } from '../utils'

export const useDataSource = () => {
  const { handleSetTicker, ticker } = useURLSyncedGlobalTicker()
  const [selectedOwner, setSelectedOwner] = useState(null)

  const { data: insiderRoster, isLoading: insiderRosterIsLoading } = useQuery(
    ['insider-roster', ticker],
    fetches.fetchInsiderRoster
  )

  const startDate = dayjs().subtract(1, 'year').format('YYYY-MM-DD')
  const endDate = dayjs().format('YYYY-MM-DD')

  const { data: historicalData, isLoading: historicalDataIsLoading } = useQuery(
    ['historical-price', ticker, { startDate, endDate }],
    fetches.fetchPriceData
  )

  const { data: insiderRosterStats, isLoading: insiderRosterStatsIsLoading } =
    useQuery(['insider-roster-stats', ticker], fetches.fetchInsiderRosterStats)

  const {
    data: rawInsiderTransactions,
    isLoading: rawInsiderTransactionsIsLoading,
  } = useQuery(['insider-trading', ticker], fetches.fetchInsiderTransactions)

  const ttmData = useMemo(() => {
    if (!insiderRosterStats || insiderRosterStats.length < 4) {
      return null
    }

    const ttm = {
      symbol: insiderRosterStats[0]?.symbol ?? '',
      cik: insiderRosterStats[0]?.cik ?? '',
      purchases: 0,
      sales: 0,
      totalBought: 0,
      totalSold: 0,
      averageBoughtTTM: 0,
      averageSoldTTM: 0,
      pPurchases: 0,
      sSales: 0,
    }

    for (let i = 0; i < 4; i++) {
      ttm.purchases += insiderRosterStats[i]?.purchases ?? 0
      ttm.sales += insiderRosterStats[i]?.sales ?? 0
      ttm.totalBought += insiderRosterStats[i]?.totalBought ?? 0
      ttm.totalSold += insiderRosterStats[i]?.totalSold ?? 0
      ttm.pPurchases += insiderRosterStats[i]?.pPurchases ?? 0
      ttm.sSales += insiderRosterStats[i]?.sSales ?? 0
      ttm.averageBoughtTTM += insiderRosterStats[i]?.averageBought ?? 0
      ttm.averageSoldTTM += insiderRosterStats[i]?.averageSold ?? 0
    }

    ttm.averageBoughtTTM = ttm.averageBoughtTTM / 4
    ttm.averageSoldTTM = ttm.averageSoldTTM / 4
    ttm.buySellRatio = ttm.sales === 0 ? 0 : ttm.purchases / ttm.sales

    return ttm
  }, [insiderRosterStats])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const transactionTypes = [
    {
      code: 'A-Award',
      description:
        'Award of stock, generally non-tradable until certain conditions are met.',
    },
    {
      code: 'C-Conversion',
      description:
        'Conversion of derivative security into the underlying stock.',
    },
    {
      code: 'D-Return',
      description: 'Sale or return of securities to the issuer.',
    },
    {
      code: 'E-ExpireShort',
      description: 'Expiration of a short derivative position.',
    },
    {
      code: 'F-InKind',
      description:
        'Payment or distribution made in the form of stock rather than cash.',
    },
    {
      code: 'G-Gift',
      description: 'Gift of securities from or to an insider.',
    },
    {
      code: 'H-ExpireLong',
      description: 'Expiration of a long derivative position.',
    },
    {
      code: 'I-Discretionary',
      description: 'Discretionary transaction, approved by a broker.',
    },
    {
      code: 'J-Other',
      description:
        "Other types of transactions that don't fit into the given categories.",
    },
    {
      code: 'L-Small',
      description:
        'Transactions of small value, often under a certain threshold.',
    },
    {
      code: 'M-Exempt',
      description: 'Transactions that are exempt from reporting requirements.',
    },
    {
      code: 'O-OutOfTheMoney',
      description:
        'Exercise or conversion of derivative security that is out-of-the-money.',
    },
    {
      code: 'P-Purchase',
      description:
        'Purchase of securities on an exchange or from another individual.',
    },
    {
      code: 'S-Sale',
      description:
        'Sale of securities on an exchange or to another individual.',
    },
    {
      code: 'U-Tender',
      description:
        'Tender of shares in response to a tender or exchange offer.',
    },
    {
      code: 'W-Will',
      description: 'Acquisition of securities through a will or inheritance.',
    },
    {
      code: 'X-InTheMoney',
      description:
        'Exercise or conversion of derivative security that is in-the-money.',
    },
    {
      code: 'Z-Trust',
      description: 'Transaction involves a trust.',
    },
  ]

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const insiderTransactions = useMemo(() => {
    return (
      rawInsiderTransactions?.map(transaction => ({
        ...transaction,
        transactionValue: transaction.price * transaction.securitiesTransacted,
      })) ?? []
    )
  }, [rawInsiderTransactions])

  const filteredData = useMemo(() => {
    return selectedOwner
      ? insiderTransactions.filter(
          transaction => transaction.reportingName === selectedOwner
        )
      : insiderTransactions
  }, [selectedOwner, insiderTransactions])

  const mergedData = useMemo(() => {
    return mergeDataByDate(historicalData, filteredData)
  }, [historicalData, filteredData])

  const stockPrice = historicalData?.[0]?.close

  const insiderData = useMemo(() => {
    return {
      insiderRoster,
      insiderRosterStats: {
        insiderRosterStats,
        ttmData,
      },
      historicalData,
      insiderTransactions,
      transactionTypes,
      mergedData,
      filteredData,
      insiderRosterIsLoading,
      insiderRosterStatsIsLoading,
      rawInsiderTransactionsIsLoading,
      historicalDataIsLoading,
    }
  }, [
    insiderRoster,
    insiderRosterStats,
    ttmData,
    historicalData,
    insiderTransactions,
    transactionTypes,
    mergedData,
    filteredData,
    insiderRosterIsLoading,
    insiderRosterStatsIsLoading,
    rawInsiderTransactionsIsLoading,
    historicalDataIsLoading,
  ])

  return {
    insiderData,
    isLoading:
      insiderRosterIsLoading ||
      insiderRosterStatsIsLoading ||
      rawInsiderTransactionsIsLoading ||
      historicalDataIsLoading,
    handleSetTicker,
    ticker,
    selectedOwner,
    setSelectedOwner,
    stockPrice,
  }
}
