import { createContext, useCallback, useState } from 'react'
import AlertsModal from './Alerts'
export const alertsContext = createContext('')

const AlertsProvider = ({ children }) => {
  const [alert, setAlert] = useState('')

  const onUpdateAlert = useCallback(
    al => {
      setAlert(al)
    },
    [setAlert]
  )

  const onClearAlert = useCallback(() => {
    setAlert()
  }, [setAlert])

  return (
    <alertsContext.Provider value={{ onUpdateAlert, onClearAlert }}>
      {<AlertsModal content={alert} onClearAlert={onClearAlert} />}
      {children}
    </alertsContext.Provider>
  )
}

export default AlertsProvider
