import numeral from 'numeral'
import dayjs from 'dayjs'
import TooltipTicker from 'core/TickerOverlay/TickerOverlay'

export const holdersColumns = [
  {
    Header: 'Asset Name',
    accessor: 'asset',
    width: 80,
    // filter: 'search',
    Cell: Cell => {
      return <TooltipTicker ticker={Cell.value} />
    },
  },
  {
    Header: 'Holder Name',
    accessor: 'name',
    minWidth: 30,
    width: 340,
    maxWidth: 340,
    // filter: 'search',
  },
  {
    Header: 'Market Value',
    accessor: 'marketValue',
    minWidth: 70,
    width: 100,
    maxWidth: 300,
    Cell: e => (
      <div style={{ maxWidth: '220px' }}>
        {numeral(e.value).format('0.000 a')}
      </div>
    ),
  },
  {
    Header: '# Of Shares',
    accessor: 'sharesNumber',
    minWidth: 70,
    width: 100,
    maxWidth: 300,
    Cell: e => (
      <div style={{ maxWidth: '220px' }}>
        {numeral(e.value).format('0.000 a')}
      </div>
    ),
  },
  {
    Header: 'Weight',
    accessor: 'weightPercentage',
    minWidth: 70,
    width: 70,
    maxWidth: 300,
    Cell: e => (
      <div style={{ maxWidth: '220px' }}>
        {numeral(e.value).format('0.00 a')}%
      </div>
    ),
  },
  {
    Header: 'Updated',
    accessor: 'updated',
    minWidth: 30,
    width: 100,
    maxWidth: 300,
    Cell: e => {
      const date = () => {
        return dayjs(e.value).format('MMM DD, YYYY')
      }
      return <div style={{ maxWidth: '220px' }}>{date()} </div>
    },
  },
]

export const holdingsColumns = [
  {
    Header: 'Symbol',
    accessor: 'symbol',
    width: 80,
    // filter: 'search',
    Cell: Cell => {
      if (Cell.value === '' || Cell.value === null) {
        return '-'
      }
      return <TooltipTicker ticker={Cell.value} />
    },
  },
  {
    Header: 'Holding Name',
    accessor: 'name',
    minWidth: 30,
    width: 340,
    maxWidth: 340,
    // filter: 'search',
  },
  {
    Header: 'Currency',
    accessor: 'cur_cd',
    width: 60,
  },
  {
    Header: 'Pay off Profile',
    accessor: 'payoffProfile',
    width: 100,
  },
  // {
  //   Header: 'Restricted Security?',
  //   accessor: 'isRestrictedSec',
  //   width: 100,
  // },
  // {
  //   Header: 'Fair Val Level',
  //   accessor: 'fairValLevel',
  //   width: 100,
  // },
  // {
  //   Header: 'Cash Collateral?',
  //   accessor: 'isCashCollateral',
  //   width: 100,
  // },
  // {
  //   Header: 'Loan By Fund?',
  //   accessor: 'isLoanByFund',
  //   width: 100,
  // },
  {
    Header: 'USD Value',
    accessor: 'valUsd',
    minWidth: 70,
    width: 100,
    maxWidth: 300,
    Cell: e => (
      <div style={{ maxWidth: '220px' }}>
        {numeral(e.value).format('0.000 a')}
      </div>
    ),
  },
  {
    Header: 'Weight',
    accessor: 'pctVal',
    minWidth: 70,
    width: 70,
    maxWidth: 300,
    Cell: e => (
      <div style={{ maxWidth: '220px' }}>
        {numeral(e.value).format('0.00 a')}%
      </div>
    ),
  },
  {
    Header: 'Acceptance Date',
    accessor: 'acceptanceTime',
    width: 100,
    Cell: e => {
      const date = () => {
        return dayjs(e.value).format('MMM DD, YYYY')
      }
      return <div style={{ maxWidth: '220px' }}>{date()} </div>
    },
  },
]
