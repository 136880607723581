export const COLOR_PALLET = [
  'rgba(253, 127, 111, 0.6)',
  'rgba(126, 176, 213, 0.6)',
  'rgba(178, 224, 97, 0.6)',
  'rgba(189, 126, 190, 0.6)',
  'rgba(255, 181, 90, 0.6)',
  'rgba(255, 238, 101, 0.6)',
  'rgba(190, 185, 219, 0.6)',
  'rgba(253, 204, 229, 0.6)',
  'rgba(139, 211, 199, 0.6)',
]

// Constants for paid subscriptionss
export const PLUS_USERS = 'PlusUsers'
export const PRO_USERS = 'ProUsers'
export const FREE_USERS = 'FreeUsers'

export const PLAN_RANK = {
  [FREE_USERS]: 0,
  [PLUS_USERS]: 1,
  [PRO_USERS]: 2,
}

// number of dashboards plans are allowed
export const PLAN_DASHBOARD_MAP = {
  [FREE_USERS]: 1,
  [PLUS_USERS]: 3,
  [PRO_USERS]: Infinity,
}
