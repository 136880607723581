import http from 'network/httpClient'

export const fetchCompanyData = async ({ queryKey }) => {
  const [, ticker] = queryKey
  let data = await http.get(`papi/data/api/v4/company-outlook?symbol=${ticker}`)
  return data.data.message
}

export const fetchReturns = async ({ queryKey }) => {
  const [, tickers] = queryKey
  let data = await http.get(`papi/data/api/v3/stock-price-change/${tickers}`)
  return data.data.message
}

export const fetchNews = async ({ queryKey }) => {
  const [, tickers] = queryKey
  let data = await http.get(`papi/data/api/v3/stock_news?tickers=${tickers}`)
  return data.data.message
}

export const fetchQuotes = async ({ queryKey }) => {
  const [, tickers] = queryKey
  let data = await http.get(`papi/data/api/v3/quote/${tickers}`)
  return data.data.message
}
