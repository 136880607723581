import React from 'react'
import { useTable, useSortBy, useFilters, useResizeColumns } from 'react-table'
import '../../styles.scss'
import { DefaultColumnFilter } from './filters'
import { SortColumnFilter } from './filters'
import { useUserGroup } from 'hooks/userGroupHook'
import { Link } from 'react-router-dom'
import { UilLock } from '@iconscout/react-unicons'

export const styles = {
  _table: {
    backgroundColor: 'var(--background-primary)',
    width: '100%',
    maxWidth: '100%',
  },

  _th: {
    paddingLeft: '8px',
    paddingRight: '8px',
    paddingBottom: '4px',
    fontWeight: 500,
    fontSize: '13px',
    height: '24px',
    backgroundColor: 'var(--background-primary)',
    zIndex: 1000,
    color: 'var(--light-grey-accent)',
    cursor: 'pointer',
  },

  _tr_Head: {
    top: 0,
    position: 'sticky',
    whiteSpace: 'nowrap',
    height: '28px',
  },

  _td: {
    fontFamily: 'var(--font-family)',
    fontStyle: 'normal',
    paddingLeft: '8px',
    paddingRight: '8px',
    fontWeight: 400,
    fontSize: '13px',
    backgroundColor: '',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    color: 'var(--white)',
    height: '32px',
    lineHeight: '28px',
    boxSizing: 'border-box',
  },
}

export function BasicTable({
  columns,
  data,
  noHead = false,
  overflowXScroll,
  onClickRow = () => {},
  onMouseOverRow = () => {},
  onMouseLeaveRow = () => {},
  selectedRow,
  hoveredSymbol,
  sliceRows,
  hoverKey = 'symbol',
  haveKey = false,
  cellhighlight,
  ...props
}) {
  const { UpgradeTable } = useUserGroup()
  const defaultColumn = React.useMemo(
    () => ({
      SearchFilter: DefaultColumnFilter,
    }),
    []
  )
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: Array.isArray(data) ? data : [],
        defaultColumn,
        initialState: {
          hiddenColumns: columns.map(column => {
            if (column?.show === false) return column.accessor || column.id
            return null
          }),
        },
      },
      useFilters,
      useSortBy,
      useResizeColumns
    )
  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  const firstPageRows = rows.slice(0, sliceRows ? sliceRows : 1000)
  // Render the UI for your table
  return (
    <div
      className="table-div"
      style={{
        width: '100%',
        maxWidth: '100%',
        height: '100%',
        background: 'var(--background-primary)',
        overflowX: overflowXScroll ? 'scroll' : 'hidden',
        overflowY: 'scroll',
        borderRadius: '4px',
      }}
    >
      <table
        style={{
          ...styles._table,
          width: '100%',
        }}
        {...getTableProps()}
      >
        {noHead ? null : (
          <thead style={styles._tr_Head}>
            {headerGroups.map(headerGroup => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                style={{ borderRadius: '4px' }}
              >
                {headerGroup.headers.map((column, i) => {
                  return (
                    <th
                      style={{
                        ...styles._th,
                        width: column.width,
                      }}
                    >
                      <div
                        style={{
                          height: '100%',
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'row',
                          gap: '8px',
                          alignItems: 'center',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                          }}
                          {...column.getHeaderProps(
                            column.filter === 'search'
                              ? {}
                              : column.getSortByToggleProps()
                          )}
                        >
                          {column.render('Header')}
                          {column.isSorted && column.filter !== 'search' ? (
                            <SortColumnFilter column={column} />
                          ) : (
                            ''
                          )}
                        </div>
                        {column.filter === 'search' ? (
                          <div style={{ width: '100%' }}>
                            {column.canFilter
                              ? column.render('SearchFilter')
                              : null}
                          </div>
                        ) : null}
                      </div>
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
        )}
        <tbody
          style={{
            width: '100%',
            height: '100%',
            background: 'var(--background-primary)',
            overflowX: 'scroll',
            borderRadius: '4px',
          }}
          {...getTableBodyProps()}
        >
          {firstPageRows.map((row, i) => {
            const rowKey = row.original[hoverKey]
            const rowKeySelected = rowKey === selectedRow
            prepareRow(row)
            if (row.original.isLocked) {
              return (
                <tr className="table-row">
                  <Link to="/terminal/upgrade">
                    <div
                      style={{
                        width: '100%',
                        cursor: 'pointer',
                        ...styles._td,
                      }}
                    >
                      <UilLock color={'var(--light-grey-accent)'} size={20} />
                    </div>
                  </Link>
                </tr>
              )
            } else
              return (
                <tr
                  className="table-row"
                  {...row.getRowProps()}
                  style={{
                    cursor: selectedRow && 'pointer',
                    background:
                      haveKey === true && hoveredSymbol === rowKey
                        ? 'var(--background-secondary)'
                        : '',
                    borderLeft:
                      haveKey === true && rowKeySelected
                        ? '2px solid var(--primary-color)'
                        : '2px solid transparent',
                    ...props.rowStyle,
                  }}
                >
                  {row.cells.map((cell, j) => {
                    return (
                      <td
                        onClick={() => onClickRow(row.original)}
                        onMouseOver={() => onMouseOverRow(row.original)}
                        onMouseLeave={() => onMouseLeaveRow()}
                        className={cellhighlight === true ? 'table-cell' : ''}
                        {...cell.getCellProps({
                          style: {
                            maxWidth: cell.column.width,
                            width: cell.column.width,
                            ...styles._td,
                          },
                        })}
                      >
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default BasicTable
