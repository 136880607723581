import React from 'react'
import useIsAuthenticated from 'core/AuthComponents/useIsAuthenticated'
import { Outlet, Navigate } from 'react-router-dom'
import styles from './styles.module.scss'
import Spinner from 'core/ScrollableContainer/Spinner/spinner'

export function ProtectedRoutes() {
  const { isAuthenticated, isDemoUser, isLoading } = useIsAuthenticated()

  if (isLoading) {
    return (
      <div className={styles.page}>
        <Spinner />
      </div>
    )
  }

  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />
}

export default ProtectedRoutes

export function DemoUserProtectedRoute() {
  const { isDemoUser } = useIsAuthenticated()

  if (isDemoUser) {
    return <Navigate to="/terminal/login" />
  }

  return <Outlet />
}
