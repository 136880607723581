import http from '../../../../network/httpClient'

const fetchAnnualRatios = async ({ queryKey }) => {
  const [, ticker] = queryKey
  const data = await http.get(`papi/data/api/v3/ratios/${ticker}?limit=40&`)
  return data.data.message
}

const fetchQuarterlyRatios = async ({ queryKey }) => {
  const [, ticker] = queryKey
  const data = await http.get(
    `papi/data/api/v3/ratios/${ticker}?period=quarter&limit=140&`
  )
  return data.data.message
}

const fetchTTMData = async ({ queryKey }) => {
  const [, ticker] = queryKey
  const data = await http.get(`papi/data/api/v3/ratios-ttm/${ticker}?`)
  return data.data.message
}

const fetchKeyMetricsTTM = async ({ queryKey }) => {
  const [, ticker] = queryKey
  const data = await http.get(
    `papi/data/api/v3/key-metrics-ttm/${ticker}?limit=40&`
  )
  return data.data.message
}

const fetchAnnualFinancialGrowth = async ({ queryKey }) => {
  const [, ticker] = queryKey
  const data = await http.get(
    `papi/data/api/v3/financial-growth/${ticker}?limit=40&`
  )
  return data.data.message
}

const fetchQuarterlyFinancialGrowth = async ({ queryKey }) => {
  const [, ticker] = queryKey
  const data = await http.get(
    `papi/data/api/v3/financial-growth/${ticker}?period=quarter&limit=140&`
  )
  return data.data.message
}

const fetchAnnualKeyMetrics = async ({ queryKey }) => {
  const [, ticker] = queryKey
  const data = await http.get(
    `papi/data/api/v3/key-metrics/${ticker}?limit=40&`
  )
  return data.data.message
}

const fetchQuarterlyKeyMetrics = async ({ queryKey }) => {
  const [, ticker] = queryKey
  const data = await http.get(
    `papi/data/api/v3/key-metrics/${ticker}?period=quarter&limit=130&`
  )
  return data.data.message
}

export const fetches = {
  fetchAnnualRatios,
  fetchQuarterlyRatios,
  fetchTTMData,
  fetchAnnualFinancialGrowth,
  fetchQuarterlyFinancialGrowth,
  fetchAnnualKeyMetrics,
  fetchQuarterlyKeyMetrics,
  fetchKeyMetricsTTM,
}
