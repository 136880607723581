import React, { useMemo } from 'react'
import styles from './ratios.module.scss'
import { QuoteBar } from 'core/QuoteBar/QuoteBar'
import { useDataSource } from './data/hooks'
import Spinner from 'core/ScrollableContainer/Spinner/spinner'
import FNtable from '../FinancialStatements/components/FNTable/FNTable'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { useState } from 'react'
import { ratiosConfig } from './constants'
import { columnKeysTTM } from './ttmColumns'
import { TTMRatios } from './TTMRatios'
import { Helmet } from 'react-helmet-async'
import { NoData } from 'core/NoData/NoData'
import { useUserGroup } from 'hooks/userGroupHook'
import { PREMIUM_FEATURES } from 'pages/Pricing/PriceTable/pricingConfig'

export const Ratios = () => {
  const { getFeatureLimit } = useUserGroup()

  const [selectedTabIndex, setSelectedTabIndex] = useState(0)
  const { RatioData, ticker, setTicker, loadingData } = useDataSource()
  const { annualRatioData, quarterlyRatioData, combinedData } = RatioData
  const [selectedRatioCategory, setSelectedRatioCategory] = useState(
    ratiosConfig[0].type
  )
  const [selectedColumns, setSelectedColumns] = useState(
    ratiosConfig[0].columns
  )
  const handleTabClick = category => {
    setSelectedRatioCategory(category.type)
    setSelectedColumns(category.columns)
  }

  const annualData =
    selectedRatioCategory === 'Growth'
      ? RatioData.annualGrowthData
      : annualRatioData
  const quarterlyData =
    selectedRatioCategory === 'Growth'
      ? RatioData.quarterlyGrowthData
      : quarterlyRatioData

  const getFeatureLimitAnnual = useMemo(() => {
    return getFeatureLimit(PREMIUM_FEATURES?.FINANCIALS_ANNUAL)
  }, [getFeatureLimit])

  const getFeatureLimitQuarterly = useMemo(() => {
    return getFeatureLimit(PREMIUM_FEATURES?.FINANCIALS_QUARTERLY)
  }, [getFeatureLimit])

  const restrictedDataAnnual = annualData?.map((item, index) => {
    const isLocked = index > getFeatureLimitAnnual

    return {
      ...item,
      isLocked,
    }
  })

  const restrictedDataQuarterly = quarterlyData?.map((item, index) => {
    const isLocked = index > getFeatureLimitQuarterly

    return {
      ...item,
      isLocked,
    }
  })

  return (
    <div className={styles.ratios}>
      <Helmet>
        <title>{`Outseek | ${ticker} Ratios`}</title>
        <meta
          name="description"
          content={
            'This page gives the user with a in depth information about a given companies financial ratios. Ratios are broken up with into  the following categories; valuation, profitability, efficiency, financial health, dividends, management efficient, growth and TTM ratios.'
          }
        />
      </Helmet>
      <QuoteBar ticker={ticker} updateTicker={ticker => setTicker(ticker)} />
      <div className={styles.ratiosContent}>
        {loadingData ? (
          <Spinner />
        ) : annualRatioData?.length > 0 ? (
          <Tabs
            className={styles.ratioTabs}
            selectedTabClassName={styles.ratioSelectedTab}
          >
            <TabList className={styles.ratioTabList}>
              {ratiosConfig.map((category, index) => (
                <React.Fragment key={index}>
                  <Tab
                    className={styles.ratioTab}
                    onClick={() => {
                      handleTabClick(category)
                      setSelectedTabIndex(index)
                    }}
                  >
                    {category.label}
                  </Tab>
                  {index < ratiosConfig.length - 1 && (
                    <div
                      className={
                        index === selectedTabIndex ||
                        index === selectedTabIndex - 1
                          ? styles.selectedDivider
                          : styles.divider
                      }
                    />
                  )}
                </React.Fragment>
              ))}
            </TabList>
            {ratiosConfig.map((category, index) => (
              <TabPanel key={index} className={styles.ratioTabPanel}>
                <div className={styles.tabPanelChild}>
                  {selectedRatioCategory === 'TTM Ratios' ? (
                    <TTMRatios
                      columnKeysTTM={columnKeysTTM}
                      data={combinedData}
                    />
                  ) : (
                    <FNtable
                      columnKeys={category.columns}
                      annualData={restrictedDataAnnual}
                      quarterlyData={restrictedDataQuarterly}
                      height={'calc(100vh - 162px)'}
                      ticker={ticker}
                      type={selectedRatioCategory}
                      ratios={true}
                      showChart={true}
                      tableId={2}
                    />
                  )}
                </div>
              </TabPanel>
            ))}
          </Tabs>
        ) : (
          <NoData label={`No Ratio Data Available for ${ticker}.`} />
        )}
      </div>
    </div>
  )
}
