export const wordsToReplace = ['https://', 'http://', 'www.']

export const removeSubstrings = (text, substringsToRemove) => {
  if (typeof text !== 'string') {
    return text
  }

  let updatedText = text
  substringsToRemove?.forEach(substring => {
    updatedText = updatedText.replace(new RegExp(substring, 'g'), '')
  })
  return updatedText
}

export const chunkPairs = array => {
  if (!Array.isArray(array)) {
    return []
  }

  const pairs = []
  for (let i = 0; i < array.length; i += 2) {
    pairs.push(array.slice(i, i + 2))
  }
  return pairs
}
