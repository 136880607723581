import React, { useState, useRef, useMemo } from 'react'
import Select, { components } from 'react-select'
import { tickerList, topTickerList } from 'constants/tickerList'
import { matchSorter } from 'match-sorter'
import styles from './tickerSearch.module.scss'
import { FiSearch } from 'react-icons/fi'
import { globalViewTickerStyles } from 'pages/Terminal/components/ToolMenu/globalTickerSearchStyles'

const SingleValue = ({ data }) => (
  <div className={styles.singleValueWrapper}>
    <div className={styles.singleValueSymbol}>{data.symbol}</div>
    <div className={styles.singleValueName}>{data.name}</div>
  </div>
)

const SearchIcon = ({ isFocused }) => (
  <div className={styles.searchIconWrapper}>
    {isFocused ? null : <FiSearch style={{ height: 16, width: 16 }} />}
  </div>
)

const TickerSearch = ({ updateTicker, ticker, styles }) => {
  const [options, setOptions] = useState(topTickerList)
  const [isFocused, setIsFocused] = useState(false)
  const selectRef = useRef(0)

  const selectedOption = useMemo(() => {
    return tickerList.find(op => op.value === ticker) || null
  }, [ticker])

  const handleOnSelect = item => {
    updateTicker(item?.value)
    setIsFocused(false)
  }

  const handleChangeInput = value => {
    if (value) {
      return setOptions(
        matchSorter(tickerList, value, { keys: ['symbol', 'name'] })?.slice(
          0,
          10
        )
      )
    }
    setOptions(topTickerList)
  }

  const CustomSingleValue = ({ ...props }) => {
    return isFocused ? (
      <components.SingleValue {...props} />
    ) : (
      <SingleValue {...props} />
    )
  }

  const customStyles2 = {
    ...globalViewTickerStyles,
    control: () => ({
      border: isFocused
        ? '2px solid var(--primary-color)'
        : '2px solid var(--grey-accent)',
      transition: 'all 0.2s ease',
      backgroundColor: 'var(--background-secondary)',
      display: 'flex',
      color: 'var(--white)',
      flexDirection: 'row',
      width: '100%',
      alignItems: 'center',
      fontSize: '14px',
      margin: '0px',
      borderRadius: '4px',
      cursor: 'pointer',
      height: 44,
    }),
    input: (provided, state) => ({
      ...provided,
      color: 'var(--white)',
      fontSize: '14px',
      ...(isFocused
        ? { height: 'auto' }
        : { height: 0, padding: 0, margin: 0 }),
    }),
  }

  return (
    <Select
      maxMenuHeight={210}
      ref={selectRef}
      value={selectedOption}
      pageSize={1}
      placeholder={'Search symbols...'}
      onChange={handleOnSelect}
      styles={styles ? customStyles2 : customStyles}
      onInputChange={handleChangeInput}
      options={options}
      closeMenuOnSelect={true}
      menuPortalTarget={document.body}
      menuPosition={'fixed'}
      components={{
        SingleValue: CustomSingleValue,
        DropdownIndicator: () => <SearchIcon isFocused={isFocused} />,
        IndicatorSeparator: null,
      }}
      onFocus={() => {
        setIsFocused(true)
      }}
      onClick={() => {
        setIsFocused(true)
      }}
      onBlur={() => {
        setIsFocused(false)
      }}
      onMenuClose={() => {
        setIsFocused(false)
      }}
      onMenuOpen={() => setIsFocused(true)}
    />
  )
}

export default TickerSearch

const customStyles = {
  control: () => ({
    backgroundColor: 'var(--button-hover)',
    display: 'flex',
    flexDirection: 'row',
    height: 24,
    width: 'fit-content',
    alignItems: 'center',
    fontSize: '12px',
    margin: '0px',
    borderRadius: '4px',
    '&:focus-within': {
      transition: 'all 0.2s ease-in-out',
      width: '280px',
    },
  }),
  singleValue: () => ({
    color: 'var(--off-white)',
    gridArea: '1/1/2/3',
    marginLeft: '1px',
    marginRight: '1px',
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    fontSize: '12px',
  }),
  indicatorSeparator: () => ({
    backgroundColor: 'var(--off-white)',
    alignSelf: 'stretch',
    marginBottom: '8px',
    marginTop: '8px',
    width: '1px',
    boxSizing: 'border-box',
  }),
  menu: () => ({
    position: 'absolute',
    backgroundColor: 'var(--background-primary)',
    border: '2px solid var(--grey-accent)',
    borderRadius: '4px',
    boxShadow:
      '0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 12px hsl(0deg 0% 0% / 10%)',
    marginBottom: '8px',
    marginTop: '8px',
    zIndex: 100,
    boxSizing: 'border-box',
    width: '100%',
  }),
  menuPortal: provided => ({ ...provided, zIndex: 9999 }),
  MenuList: () => ({
    paddingBottom: '4px',
    maxHeight: '100px',
    overflowY: 'auto',
    paddingTop: '4px',
    zIndex: 100,
    position: 'relative',
    boxSizing: 'border-box',
  }),
  option: () => ({
    backgroundColor: 'transparent',
    color: 'var(--off-white)',
    display: 'block',
    fontSize: '12px',
    zIndex: 100,
    padding: '8px 12px',
    width: '',
    cursor: 'pointer',
    boxSizing: 'border-box',
    '&:hover': {
      backgroundColor: 'var(--button-hover)',
    },
  }),
  input: (provided, state) => ({
    ...provided,
    color: 'var(--white)',
    fontSize: '12px',
  }),
}
