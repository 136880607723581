import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  RATIOS,
  FINANCIAL_STATEMENTS,
  COMPANY_OVERVIEW,
  PRICE_CHART,
  SOCIAL_SENTIMENT_COMPANY,
  INSTITUTIONAL_INVESTOR,
} from 'constants/ViewsConstants'
import { viewReducer } from 'recoil/selectors/viewsSelector'
import { addView, currentView } from 'recoil/atoms/views'
import { useRecoilState, useRecoilValue } from 'recoil'
import { showComponentCatalog } from 'recoil/atoms/componentCatalog'
import { useNavigate } from 'react-router-dom'
import { useFirstRender } from 'dashboard/ComparisonCharts'

const companyInsights = [
  RATIOS,
  FINANCIAL_STATEMENTS,
  COMPANY_OVERVIEW,
  PRICE_CHART,
  SOCIAL_SENTIMENT_COMPANY,
  INSTITUTIONAL_INVESTOR,
]

export const useTapActionBar = () => {
  const navigate = useNavigate()
  const firstRender = useFirstRender()
  // show component catalog
  const [, setShowComponentCatalog] = useRecoilState(showComponentCatalog)

  const [activeTab, setActiveTab] = useState(null)
  // views are stored in recoil, only persitsted in client state for now
  const [viewsState] = useRecoilState(viewReducer)
  const isViewActive = useRecoilValue(addView)
  const activeView = useRecoilValue(currentView)

  const dashboards = useSelector(state => state?.loadedDashboards)
  const currentDashboard = useSelector(state => state?.currentDashboard)

  // useEffect(() => {
  //   if (isViewActive) {
  //     setActiveTab(activeView)
  //   } else {
  //     setActiveTab({ id: currentDashboard })
  //   }
  // }, [currentDashboard, isViewActive, activeView])

  // useEffect(() => {
  //   if (!firstRender) {
  //     if (companyInsights.includes(activeView.view)) {
  //       navigate(`/terminal/view/${activeView.view}/symbol=${ticker}`)
  //     } else {
  //       navigate(`/terminal/view/${activeView.view}`)
  //     }
  //   }
  // }, [activeView, navigate, ticker, firstRender])

  const handleShowComponentCatalog = () => {
    setShowComponentCatalog(true)
  }

  return {
    handleShowComponentCatalog,
    isViewActive,
    dashboards,
    currentDashboard,
    activeTab,
    viewsState,
    activeView,
  }
}
