import { useQuery } from 'react-query'

import http from '../../network/httpClient'
import { fetchDropdownOptions } from './utils'

// export const
export const QUERY_SCREENER_COLUMNS = 'papi/screener/columns'
export const SCREENER_COLUMNS_CACHE_KEY = 'Screeer Colums'
export const SCREENER_DROPDOWN_OPTIONS = 'Screener Dropdown options'
export const useScreenerColumns = () => {
  const { data, isLoading } = useQuery(
    [SCREENER_COLUMNS_CACHE_KEY],
    async () => {
      let data = await http.get(QUERY_SCREENER_COLUMNS)
      return data.data.items
    }
  )
  return { data, isLoading }
}

export const useDropdownValues = () => {
  const { data, isLoading } = useQuery(
    [SCREENER_DROPDOWN_OPTIONS],
    fetchDropdownOptions
  )
  return { data, isLoading }
}
// eslint-disable-next-line import/no-anonymous-default-export
export default { useDropdownValues, useScreenerColumns }
