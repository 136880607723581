import { CardWrapper } from 'core/CardWrapper/CardWrapper'
import Spinner from 'core/ScrollableContainer/Spinner/spinner'
import { earningsColumns } from './columns'
import { Helmet } from 'react-helmet-async'
import { Calendar } from '../../Componenets/Calendar'
import { useEarningsCalendar } from '../../data/hooks'
import CalendarTable from 'core/Tables/CalendarTable'
import { Switch } from 'core/Switch/Switch'
import { CalComp, CalCompLogos } from './CalComp'
import { useCalContext } from 'pages/views/Calendars/CalContext'
import { useState } from 'react'
import styles from './style.module.scss'

export const Earnings = () => {
  const { CAL_UI } = useEarningsCalendar()
  const { calType, setCalType, options } = useCalContext()
  const [showOnlySP500, setShowOnlySP500] = useState(true)
  const [showLogo, setShowLogo] = useState(false)

  const SP500 = CAL_UI?.sp500

  const earningOfSP500 = CAL_UI?.earningCal?.filter(item =>
    SP500?.map(sp => sp.symbol).includes(item.symbol)
  )

  const dataToUse = showOnlySP500 ? earningOfSP500 : CAL_UI?.earningCal

  const sortedData = dataToUse?.sort((a, b) => {
    return a.time === 'bmo' ? -1 : 1
  })

  return (
    <>
      <Helmet>
        <title>{`Outseek | Earnings`}</title>
        <meta
          name="description"
          content={
            'Economic calendar provides a list of companies that are expected to release their economic reports.'
          }
        />
      </Helmet>
      <CardWrapper
        label={'Earnings'}
        height={'100%'}
        width={'100%'}
        endButton={
          <Switch
            options={options}
            selectedOption={calType}
            onOptionChange={setCalType}
            forCardWrapper={true}
          />
        }
        content={
          CAL_UI?.earningCalIsLoading ? (
            <Spinner />
          ) : calType === 'table' ? (
            <CalendarTable
              data={CAL_UI?.earningCal}
              columns={earningsColumns}
              overflowXScroll={true}
              filterColumnNames={['time', 'country']}
            />
          ) : (
            <Calendar
              data={sortedData}
              component={showLogo ? CalCompLogos : CalComp}
              filterKeys={['time']}
              searchKeys={['symbol']}
              grid={showLogo ? true : false}
              isLogo={showLogo}
              selectableDates={true}
              additionalProps={
                <div className={styles.dataCheckbox}>
                  <input
                    type="checkbox"
                    checked={showOnlySP500}
                    onChange={() => setShowOnlySP500(!showOnlySP500)}
                    className={styles.checkbox}
                  />
                  <div>Show only SP500</div>
                  <input
                    type="checkbox"
                    checked={showLogo}
                    onChange={() => setShowLogo(!showLogo)}
                    className={styles.checkbox}
                  />
                  <div>Logo View</div>
                </div>
              }
            />
          )
        }
      />
    </>
  )
}
