import numeral from 'numeral'
import dayjs from 'dayjs'

export const crowdFundingKeys = [
  {
    accessor: 'companyName',
    Header: 'Company Name',
    width: '300px',
    Cell: ({ value }) => {
      if (value === null || value === undefined || value === '') return '-'
      else return value
    },
  },
  {
    accessor: 'intermediaryCompanyName',
    Header: 'Intermediary Company Name',
    width: '300px',
    Cell: ({ value }) => {
      if (value === null || value === undefined || value === '') return '-'
      else return value
    },
  },
  {
    accessor: 'securityOfferedType',
    Header: 'Security Offered',
    width: '150px',
    Cell: ({ value }) => {
      if (value === null || value === undefined || value === '') return '-'
      else return value
    },
  },
  {
    accessor: 'offeringPrice',
    Header: 'Offering Price',
    width: '110px',
    Cell: ({ value }) => {
      if (value === null || value === undefined || value === '') return '-'
      else return numeral(value).format('$0,0.00a')
    },
  },
  {
    accessor: 'offeringAmount',
    Header: 'Offering Amount',
    width: '110px',
    Cell: ({ value }) => {
      if (value === null || value === undefined || value === '') return '-'
      else return numeral(value).format('$0,0.0a')
    },
  },
  {
    accessor: 'offeringDeadlineDate',
    Header: 'Offering Deadline',
    width: '110px',
    Cell: ({ value }) => {
      if (value === null || value === undefined || value === '') return '-'
      else return dayjs(value).format('MMM DD, YYYY')
    },
  },
]

export const financials = {
  Assets: {
    'Fiscal Year': 'totalAssetMostRecentFiscalYear',
    'Prior Fiscal Year': 'totalAssetPriorFiscalYear',
  },
  Cash: {
    'Fiscal Year': 'cashAndCashEquiValentMostRecentFiscalYear',
    'Prior Fiscal Year': 'cashAndCashEquiValentPriorFiscalYear',
  },
  'A/R': {
    'Fiscal Year': 'accountsReceivableMostRecentFiscalYear',
    'Prior Fiscal Year': 'accountsReceivablePriorFiscalYear',
  },
  // 'Short Term Debt': {
  'ST Debt': {
    'Fiscal Year': 'shortTermDebtMostRecentFiscalYear',
    'Prior Fiscal Year': 'shortTermDebtPriorFiscalYear',
  },
  // 'Long Term Debt': {
  'LT Debt': {
    'Fiscal Year': 'longTermDebtMostRecentFiscalYear',
    'Prior Fiscal Year': 'longTermDebtPriorFiscalYear',
  },
  Revenue: {
    'Fiscal Year': 'revenueMostRecentFiscalYear',
    'Prior Fiscal Year': 'revenuePriorFiscalYear',
  },
  COGS: {
    'Fiscal Year': 'costGoodsSoldMostRecentFiscalYear',
    'Prior Fiscal Year': 'costGoodsSoldPriorFiscalYear',
  },
  // 'Taxes Paid': {
  //   'Fiscal Year': 'taxesPaidMostRecentFiscalYear',
  //   'Prior Fiscal Year': 'taxesPaidPriorFiscalYear',
  // },
  'Net Income': {
    'Fiscal Year': 'netIncomeMostRecentFiscalYear',
    'Prior Fiscal Year': 'netIncomePriorFiscalYear',
  },
}

export const intermediaryInfo = [
  {
    key: 'intermediaryCompanyName',
    label: 'Intermediary Company Name',
  },
  {
    key: 'compensationAmount',
    label: 'Compensation Amount',
  },
  {
    key: 'financialInterest',
    label: 'Financial Interest',
  },
]

export const offeringInfo = [
  {
    key: 'securityOfferedType',
    label: 'Security Offered',
    text: true,
  },
  // {
  //   key: 'securityOfferedOtherDescription',
  //   label: 'Other Type',
  //   text: true,
  // },
  {
    key: 'numberOfSecurityOffered',
    label: '# of Securities Offered',
  },
  {
    key: 'offeringPrice',
    label: 'Offering Price',
    dollar: true,
  },
  {
    key: 'offeringAmount',
    label: 'Offering Amount',
    dollar: true,
  },
  {
    key: 'overSubscriptionAccepted',
    label: 'Over Subscription Accepted?',
    text: true,
  },
  {
    key: 'overSubscriptionAllocationType',
    label: 'Over Subscription Allocation Type',
    text: true,
  },
  {
    key: 'maximumOfferingAmount',
    label: 'Maximum Offering Amount',
    dollar: true,
  },
  {
    key: 'offeringDeadlineDate',
    label: 'Offering Deadline Date',
    date: true,
  },
]

export const companyInfo = [
  {
    key: 'companyName',
    label: 'Name',
    text: true,
  },
  {
    key: 'issuerWebsite',
    label: 'Website',
    link: true,
  },
  {
    key: 'currentNumberOfEmployees',
    label: '# of Employees',
    text: true,
  },
]
