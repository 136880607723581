import numeral from 'numeral'
import { reformatName, formatString } from '../../utils'
import styles from './TransactionTable.module.scss'
import dayjs from 'dayjs'

const formatDateForDisplay = dateValue => {
  const currentYear = dayjs().year()
  const valueYear = dayjs(dateValue).year()

  if (currentYear === valueYear) {
    return dayjs(dateValue).format('MMM D')
  } else {
    return dayjs(dateValue).format('MMM D, YY' + "'")
  }
}

export const columns = [
  {
    Header: 'Filing Date',
    accessor: 'filingDate',
    Cell: Cell => {
      const formattedDate = formatDateForDisplay(Cell.value)
      return (
        <a
          href={Cell.row.original.link}
          className={styles.link}
          target="_blank"
          rel="noreferrer"
        >
          {formattedDate}
        </a>
      )
    },
    width: 'fit-content',
  },
  {
    Header: 'Acq/Disp',
    accessor: 'acquistionOrDisposition',
    Cell: Cell => {
      const displayValue = Cell.value === 'A' ? 'Acq' : 'Disp'
      const color = Cell.value === 'A' ? 'var(--green)' : 'var(--red)'
      return <span style={{ color }}>{displayValue}</span>
    },
  },
  {
    Header: 'Type',
    accessor: 'transactionType',
    width: 'fit-content',
  },
  {
    Header: 'Securities Owned',
    accessor: 'securitiesOwned',
    Cell: Cell => {
      return Cell.value === 0 ? '-' : numeral(Cell.value).format('0,0')
    },
  },
  {
    Header: 'Name',
    accessor: 'reportingName',
    Cell: Cell => reformatName(Cell.value),
  },
  {
    Header: 'Relationship',
    accessor: 'typeOfOwner',
    Cell: Cell => formatString(Cell.value),
    width: 'fit-content',
  },
  {
    Header: '# Transacted',
    accessor: 'securitiesTransacted',
    Cell: Cell => {
      return Cell.value === 0 ? '-' : numeral(Cell.value).format('0,0')
    },
    width: 'fit-content',
  },
  {
    Header: 'Price',
    accessor: 'price',
    Cell: Cell => {
      return Cell.value === 0 ? '-' : numeral(Cell.value).format('$0,0.00')
    },
    width: 'fit-content',
  },
  {
    Header: 'Value',
    accessor: 'transactionValue',
    Cell: Cell => {
      return Cell.value === 0 ? '-' : numeral(Cell.value).format('$0,0.00a')
    },
    width: 'fit-content',
  },
  {
    Header: 'Security',
    accessor: 'securityName',
    Cell: Cell => {
      return Cell.value === 'Restricted Stock Unit' ? 'RSU' : Cell.value
    },
    width: 'fit-content',
  },
  {
    Header: 'Date',
    accessor: 'transactionDate',
    Cell: Cell => {
      const formattedDate = formatDateForDisplay(Cell.value)
      return formattedDate
    },
    width: 'fit-content',
  },
]
