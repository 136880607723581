import styles from './Input.module.scss'

export const Input = ({ Placeholder, onChange, value, ...props }) => {
  return (
    <input
      className={styles.outseekInput}
      placeholder={Placeholder}
      onChange={e => onChange(e)}
      value={value}
      {...props}
    ></input>
  )
}

export default Input
