import { useMutation, useQuery, useQueryClient } from 'react-query'
import http from '../../network/httpClient'

const Chat_URL = 'papi/messages/'

async function increaseMessageCountFn() {
  let data = await http.post(`${Chat_URL}increaseMessageCount`)
  return data.data
}

async function checkMessageLimitFn() {
  let data = await http.get(`${Chat_URL}checkMessageLimit`)
  return data.data
}

export const useMessages = () => {
  const queryClient = useQueryClient()

  const {
    mutate: increaseMessageCount,
    error: isIncreaseMessageCountError,
    isLoading: isIncreaseMessageCountLoading,
  } = useMutation(increaseMessageCountFn, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [Chat_URL] })
    },
    onError: error => {
      console.error('Error increasing message count:', error)
    },
  })

  const {
    data: checkMessageLimitData,
    error: isCheckMessageLimitError,
    isLoading: isCheckMessageLimitLoading,
    refetch: refetchCheckMessageLimit,
  } = useQuery('checkMessageLimit', checkMessageLimitFn, {
    enabled: false,
  })

  return {
    increaseMessageCount,
    isIncreaseMessageCountError,
    isIncreaseMessageCountLoading,
    checkMessageLimitData,
    isCheckMessageLimitError,
    isCheckMessageLimitLoading,
    refetchCheckMessageLimit,
  }
}

export default useMessages
