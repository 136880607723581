export const SOCIAL_SENTIMENT_TRENDING = 'marketNews'

export const SOCIAL_SENTIMENT_COMPANY = 'stockNews'

export const INSTITUTIONAL_INVESTOR = 'institutionalOwnership'

export const INSTITUTIONAL_INVESTOR_MARKET = 'institutionalFunds'

export const MARKET_OVERVIEW = 'marketOverview'

export const PRICE_CHART = 'priceChart'

export const GENERAL_NEWS = 'generalNews'

export const SCREENER = 'screener'

export const COMP_CHART = 'comparisonChart'

export const COMPANY_OVERVIEW = 'companyOverview'

export const FINANCIAL_STATEMENTS = 'financialStatements'

export const WATCH_LIST = 'watchlist'

export const RATIOS = 'ratios'

export const ANALYTICS = 'companyAnalytics'

export const INSIDER_TRADING = 'insiderTrading'

export const COMPANY_PROFILE = 'companyProfile'

export const COMPANY_EARNINGS = 'earningsAndTranscripts'

export const ETF_VIEW = 'ETFInfo'

export const ETF_HOLDINGS = 'ETFHoldings'

export const INDEXES = 'worldIndexes'

export const SEC_FILLINGS = 'secFillings'

export const CORPORATE_EVENTS = 'corporateEvents'

export const CALENDARS = 'calendars'

export const VIEW_BASE_URL = `/terminal/view/`
