import http from 'network/httpClient'

const fetchInsiderRoster = async ({ queryKey }) => {
  const [, ticker] = queryKey
  const data = await http.get(
    `papi/data/api/v4/insider-roaster?symbol=${ticker}&`
  )
  return data.data.message
}

const fetchInsiderRosterStats = async ({ queryKey }) => {
  const [, ticker] = queryKey
  const data = await http.get(
    `papi/data/api/v4/insider-roaster-statistic?symbol=${ticker}&`
  )
  return data.data.message
}

const fetchInsiderTransactions = async ({ queryKey }) => {
  const [, ticker] = queryKey
  let allTransactions = []

  for (let i = 0; i < 3; i++) {
    const data = await http.get(
      `papi/data/api/v4/insider-trading?symbol=${ticker}&page=${i}&`
    )
    allTransactions = allTransactions.concat(data.data.message)
  }

  return allTransactions
}

const fetchPriceData = async ({ queryKey }) => {
  const [, ticker, { startDate, endDate }] = queryKey
  const response = await http.get(
    `papi/data/api/v3/historical-price-full/${ticker}?from=${startDate}&to=${endDate}`
  )
  if (
    response.data &&
    response.data.message &&
    response.data.message.historical
  ) {
    return response.data.message.historical
  }
  throw new Error('Unexpected data structure or no data returned.')
}

const fetchInsiderTransactionsType = async ({ queryKey }) => {
  const [, ticker] = queryKey
  const data = await http.get(
    `papi/data/api/v4/insider-trading-transaction-type?`
  )
  return data.data.message
}

export const fetches = {
  fetchInsiderRoster,
  fetchInsiderRosterStats,
  fetchInsiderTransactions,
  fetchInsiderTransactionsType,
  fetchPriceData,
}
