export const globeData = [
  {
    name: 'S&P 500',
    country: 'United States of America',
    type: '^GSPC',
    lat: 40.7128,
    lng: -74.006,
  },
  {
    name: 'Dow Jones Industrial Average',
    country: 'United States of America',
    type: '^DJI',
    lat: 40.7128,
    lng: -95,
  },
  {
    name: 'NASDAQ',
    country: 'United States of America',
    type: '^IXIC',
    lat: 40.7128,
    lng: -115,
  },
  {
    name: 'IBEX 35',
    country: 'Spain',
    type: '^IBEX',
    lat: 40.4168,
    lng: -3.7038,
  },
  {
    name: 'FTSE 100',
    country: 'United Kingdom',
    type: '^FTSE',
    lat: 51.5074,
    lng: -0.1278,
  },
  {
    name: 'Nikkei 225',
    country: 'Japan',
    type: '^N225',
    lat: 35.6895,
    lng: 139.6917,
  },
  {
    name: 'Hang Seng',
    country: 'China',
    type: '^HSI',
    lat: 22.3964,
    lng: 114.1095,
  },
  {
    name: 'MOEX Russia Index',
    country: 'Russia',
    type: 'IMOEX.ME',
    lat: 55.7558,
    lng: 37.6176,
  },
  {
    name: 'SSE Composite Index',
    country: 'China',
    type: '000001.SS',
    lat: 39.9042,
    lng: 116.4074,
  },
  {
    name: 'S&P/ASX 200',
    country: 'Australia',
    type: '^AXJO',
    lat: -35.282,
    lng: 149.1287,
  },
  {
    name: 'KOSPI Composite Index',
    country: 'South Korea',
    type: '^KS11',
    lat: 37.5665,
    lng: 126.978,
  },
  {
    name: 'BEL 20',
    country: 'Belgium',
    type: '^BFX',
    lat: 50.8503,
    lng: 4.3517,
  },
  {
    name: 'MERVAL',
    country: 'Argentina',
    type: '^MERV',
    lat: -34.6037,
    lng: -58.3816,
  },
  {
    name: 'CAC 40',
    country: 'France',
    type: '^FCHI',
    lat: 48.8566,
    lng: 2.3522,
  },
  {
    name: 'S&P/TSX Composite Index',
    country: 'Canada',
    type: '^GSPTSE',
    lat: 43.65107,
    lng: -79.347015,
  },
  {
    name: 'FTSE Italia All-Share Index',
    country: 'Italy',
    type: 'ITLMS.MI',
    lat: 41.9028,
    lng: 12.4964,
  },
  {
    name: 'Jakarta Composite Index',
    country: 'Indonesia',
    type: '^JKSE',
    lat: -6.2088,
    lng: 106.8456,
  },
  {
    name: 'WIG',
    country: 'Poland',
    type: 'WIG.WA',
    lat: 52.2297,
    lng: 21.0122,
  },

  {
    name: 'FTSE MIB Index',
    country: 'Italy',
    type: 'FTSEMIB.MI',
    lat: 41.9028,
    lng: 12.4964,
  },
  {
    name: 'EGX 30 Price Return Index',
    country: 'Egypt',
    type: '^CASE30',
    lat: 30.0444,
    lng: 31.2357,
  },
  {
    name: 'IPC Mexico',
    country: 'Mexico',
    type: '^MXX',
    lat: 19.4326,
    lng: -99.1332,
  },
  {
    name: 'S&P/NZX 50',
    country: 'New Zealand',
    type: '^NZ50',
    lat: -41.2865,
    lng: 174.7762,
  },
  {
    name: 'EURONEXT 100',
    country: 'Europe',
    type: '^N100',
    lat: 48.8566,
    lng: 2.3522,
  },
  {
    name: 'ATX',
    country: 'Austria',
    type: '^ATX',
    lat: 48.2082,
    lng: 16.3738,
  },
  {
    name: 'Nifty 50',
    country: 'India',
    type: '^NSEI',
    lat: 28.6139,
    lng: 77.209,
  },
  {
    name: 'BIST 100',
    country: 'Turkey',
    type: 'XU100.IS',
    lat: 41.0082,
    lng: 28.9784,
  },
  {
    name: 'TA-125',
    country: 'Israel',
    type: '^TA125.TA',
    lat: 31.7683,
    lng: 35.2137,
  },
  {
    name: 'Stockholm 30 Index',
    country: 'Sweden',
    type: '^OMXS30',
    lat: 59.3293,
    lng: 18.0686,
  },
  {
    name: 'TSEC weighted index',
    country: 'Taiwan',
    type: '^TWII',
    lat: 25.0329,
    lng: 121.5654,
  },
  {
    name: 'SET Index',
    country: 'Thailand',
    type: '^SET.BK',
    lat: 13.7563,
    lng: 100.5018,
  },
  {
    name: 'S&P BSE SENSEX',
    country: 'India',
    type: '^BSESN',
    lat: 28.6139,
    lng: 77.209,
  },
  {
    name: 'Tadawul All Shares Index',
    country: 'Saudi Arabia',
    type: '^TASI.SR',
    lat: 24.7136,
    lng: 46.6753,
  },
  {
    name: 'KOSPI 200 Index',
    country: 'South Korea',
    type: 'KOSPI200.KS',
    lat: 37.5665,
    lng: 126.978,
  },
  {
    name: 'STI Index',
    country: 'Singapore',
    type: '^STI',
    lat: 1.3521,
    lng: 103.8198,
  },
  {
    name: 'AEX-INDEX',
    country: 'Netherlands',
    type: '^AEX',
    lat: 52.3676,
    lng: 4.9041,
  },
  {
    name: 'S&P BSE-100',
    country: 'India',
    type: '^BSE100.BO',
    lat: 28.6139,
    lng: 77.209,
  },
  {
    name: 'FTSE/JSE Top 40 Index',
    country: 'South Africa',
    type: '^J20X.JO',
    lat: -30.5595,
    lng: 22.9375,
  },
  {
    name: 'FTSE Bursa Malaysia KLCI',
    country: 'Malaysia',
    type: '^KLSE',
    lat: 3.139,
    lng: 101.6869,
  },
  {
    name: 'FTSE 250',
    country: 'United Kingdom',
    type: '^FTMC',
    lat: 51.5074,
    lng: -0.1278,
  },
  {
    name: 'S&P/ASX All Ordinaries',
    country: 'Australia',
    type: '^AORD',
    lat: -25.2744,
    lng: 133.7751,
  },
  {
    name: 'DAX Index',
    country: 'Germany',
    type: '^GDAXI',
    lat: 51.1657,
    lng: 10.4515,
  },
  {
    name: 'Swiss Market Index',
    country: 'Switzerland',
    type: '^SSMI',
    lat: 46.8182,
    lng: 8.2275,
  },
  {
    name: 'OMX Helsinki 25',
    country: 'Finland',
    type: '^OMXH25',
    lat: 61.9241,
    lng: 25.7482,
  },
  {
    name: 'OMX Copenhagen 20',
    country: 'Denmark',
    type: '^OMXC20',
    lat: 55.6761,
    lng: 12.5683,
  },
  {
    name: 'Oslo Bors All-Share Index',
    country: 'Norway',
    type: '^OSEAX',
    lat: 60.472,
    lng: 8.4689,
  },
]
