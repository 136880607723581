import './styles.scss'
import { ConfigurableChart } from './Chart'

import { useEffect, useState } from 'react'
import { CHART_CONSTANTS } from './constants'
import { buildChartData } from './utils'

import http from 'network/httpClient'

const fetchInstStockOwnershipData = ticker => {
  return http.get(
    'papi/data/api/v4/institutional-ownership/symbol-ownership?symbol=' +
      ticker +
      '&includeCurrentQuarter=false'
  )
}

export const InstStockOwnerShip = ({ ticker }) => {
  const [data, setData] = useState()

  useEffect(() => {
    fetchInstStockOwnershipData('AAPL').then(data_raw => {
      setData(buildChartData(data_raw.data.message.slice(0, 4)))
    })
  }, [ticker])

  return (
    data && (
      <div className="InstStockOwn">
        <div className="InstStockOwnWrapper">
          <div className="InstStockOwnRow1">
            <div className="InstStockOwnRow1Item">
              <ConfigurableChart
                apiKey={CHART_CONSTANTS.INVESTOR_HOLDINGS_KEY}
                data={data}
                width={229}
                height={108}
                margin={{ top: 5, right: 5, left: 5, bottom: 5 }}
                label={() => (
                  <div className="labeldiv">
                    <p className="label">
                      {CHART_CONSTANTS.INVESTOR_HOLDINGS_LABEL}
                    </p>
                  </div>
                )}
              />
            </div>
            <div className="InstStockOwnRow1Item">
              <ConfigurableChart
                apiKey={CHART_CONSTANTS.OWNERSHIP_PERCENT_KEY}
                label={() => (
                  <div className="labeldiv">
                    <p className="label">
                      {CHART_CONSTANTS.OWNERSHIP_PERCENT_LABEL}
                    </p>
                  </div>
                )}
                data={data}
                width={229}
                height={108}
                margin={{ top: 5, right: 5, left: 5, bottom: 5 }}
              />
            </div>
            <div className="InstStockOwnRow1Item">
              <ConfigurableChart
                apiKey={CHART_CONSTANTS.TOTAL_INVESTED_KEY}
                label={() => (
                  <div>
                    <p className="label">
                      {CHART_CONSTANTS.TOTAL_INVESTED_LABEL}
                    </p>
                  </div>
                )}
                data={data}
                width={229}
                height={108}
                margin={{ top: 5, right: 5, left: 5, bottom: 5 }}
              />
            </div>
          </div>
          <div className="InstStockOwnRow2">
            <div className="InstStockOwnRow2Item">
              <ConfigurableChart
                apiKey={CHART_CONSTANTS.NUMBER_OF_13F_SHARES_KEY}
                label={() => (
                  <div className="labeldiv">
                    <p className="label">
                      {CHART_CONSTANTS.NUMBER_OF_13F_SHARES_LABEL}
                    </p>
                  </div>
                )}
                data={data}
                width={169}
                height={80}
                margin={{ top: 5, right: 5, left: 5, bottom: 5 }}
              />
            </div>
            <div className="InstStockOwnRow2Item">
              <ConfigurableChart
                apiKey={CHART_CONSTANTS.NEW_POSITIONS_KEY}
                label={() => (
                  <div className="labeldiv">
                    <p className="label">
                      {CHART_CONSTANTS.NEW_POSITIONS_LABEL}
                    </p>
                  </div>
                )}
                data={data}
                width={169}
                height={80}
                margin={{ top: 5, right: 5, left: 5, bottom: 5 }}
              />
            </div>
            <div className="InstStockOwnRow2Item">
              <ConfigurableChart
                apiKey={CHART_CONSTANTS.INCREASED_POSITIONS_KEY}
                label={() => (
                  <div className="labeldiv">
                    <p className="label">
                      {CHART_CONSTANTS.INCREASED_POSITIONS_LABEL}
                    </p>
                  </div>
                )}
                data={data}
                width={169}
                height={80}
                margin={{ top: 5, right: 5, left: 5, bottom: 5 }}
              />
            </div>
            <div className="InstStockOwnRow2Item">
              <ConfigurableChart
                apiKey={CHART_CONSTANTS.CLOSED_POSITIONS_KEY}
                label={() => (
                  <div className="labeldiv">
                    <p className="label">
                      {CHART_CONSTANTS.CLOSED_POSITIONS_LABEL}
                    </p>
                  </div>
                )}
                data={data}
                width={169}
                height={80}
                margin={{ top: 5, right: 5, left: 5, bottom: 5 }}
              />
            </div>
          </div>
          <div className="InstStockOwnRow3">
            <div className="InstStockOwnRow2Item">
              <ConfigurableChart
                apiKey={CHART_CONSTANTS.REDUCED_POSITIONS_KEY}
                label={() => (
                  <div className="labeldiv">
                    <p className="label">
                      {CHART_CONSTANTS.REDUCED_POSITIONS_LABEL}
                    </p>
                  </div>
                )}
                data={data}
                width={169}
                height={80}
                margin={{ top: 5, right: 5, left: 5, bottom: 5 }}
              />
            </div>
            <div className="InstStockOwnRow2Item">
              <ConfigurableChart
                apiKey={CHART_CONSTANTS.TOTAL_CALLS_KEY}
                label={() => (
                  <div className="labeldiv">
                    <p className="label">{CHART_CONSTANTS.TOTAL_CALLS_LABEL}</p>
                  </div>
                )}
                data={data}
                width={169}
                height={80}
                margin={{ top: 5, right: 5, left: 5, bottom: 5 }}
              />
            </div>
            <div className="InstStockOwnRow2Item">
              <ConfigurableChart
                apiKey={CHART_CONSTANTS.TOTAL_PUTS_KEY}
                label={() => (
                  <div className="labeldiv">
                    <p className="label">{CHART_CONSTANTS.TOTAL_PUTS_LABEL}</p>
                  </div>
                )}
                data={data}
                width={169}
                height={80}
                margin={{ top: 5, right: 5, left: 5, bottom: 5 }}
              />
            </div>
            <div className="InstStockOwnRow2Item">
              <ConfigurableChart
                apiKey={CHART_CONSTANTS.PUT_CALL_RATIOS_KEY}
                label={() => (
                  <div className="labeldiv">
                    <p className="label">
                      {CHART_CONSTANTS.PUT_CALL_RATIOS_LABEL}
                    </p>
                  </div>
                )}
                data={data}
                width={169}
                height={80}
                margin={{ top: 5, right: 5, left: 5, bottom: 5 }}
              />
            </div>
          </div>
        </div>
      </div>
    )
  )
}
