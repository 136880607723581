import { useTheme } from 'theme/ThemeContext'
import { themes } from './constants'
import React, { useEffect, useState } from 'react'
import Modal from '@mui/material/Modal'
import styles from './styles.module.scss'
import { startCase } from 'lodash'
import { UilTimes } from '@iconscout/react-unicons'
import { CustomThemeCreator } from 'theme/CustomTheme'
import { UilTrashAlt } from '@iconscout/react-unicons'
import CoreButton from 'core/Button/CoreButton'

export const ChangeTheme = () => {
  const {
    theme,
    toggleTheme,
    refreshThemes,
    allThemes,
    applyTheme,
    deleteTheme,
  } = useTheme()
  const [modalOpen, setModalOpen] = useState(false)
  const [openThemeCreator, setOpenThemeCreator] = useState(false)

  const handleClose = () => {
    setModalOpen(false)
    setOpenThemeCreator(false)
  }

  const handleOpen = () => {
    setModalOpen(true)
    setOpenThemeCreator(false)
  }

  useEffect(() => {
    applyTheme(theme)
  }, [theme])

  const changeTheme = newTheme => {
    if (newTheme !== theme) {
      toggleTheme(newTheme)
    }
  }

  const getThemeColors = allThemes => {
    let themeColors = {}

    for (let themeKey in allThemes) {
      themeColors[themeKey] = Object.values(allThemes[themeKey])
    }

    return themeColors
  }

  const themeColors = getThemeColors(allThemes)

  const isCustomTheme = themeKey => {
    return !Object.keys(themes).includes(themeKey)
  }

  const handleDeleteTheme = themeKey => {
    deleteTheme(themeKey)
    if (theme === themeKey) {
      toggleTheme(Object.keys(themes)?.[0])
    }
  }

  return (
    <React.Fragment>
      <CoreButton onClick={handleOpen}>App Appearance</CoreButton>
      <Modal open={modalOpen} onClose={handleClose} disableAutoFocus={true}>
        <div className={styles.themeModal}>
          <div className={styles.header}>
            <div>
              App Appearance:{' '}
              <span
                style={{
                  color: themes?.[theme]?.['--primary-color'],
                  fontWeight: 700,
                }}
              >
                {startCase(theme)}
              </span>
            </div>
            <UilTimes
              size={24}
              onClick={handleClose}
              className={styles.closeIcon}
            />
          </div>
          {openThemeCreator ? (
            <div className={styles.themeCreatorWrapper}>
              <CustomThemeCreator onThemeSave={refreshThemes} />
              <div
                onClick={() => setOpenThemeCreator(!openThemeCreator)}
                className={styles.createThemeButton}
              >
                {openThemeCreator ? 'Cancel' : 'Create New Theme'}
              </div>
            </div>
          ) : (
            <React.Fragment>
              <div className={styles.themeOptions}>
                {Object.keys(allThemes).map(themeKey => {
                  const colorsArray = themeColors[themeKey]?.slice(0, 5)
                  const primaryColor = allThemes[theme]?.['--primary-color']
                  return (
                    <div
                      key={themeKey}
                      onClick={() => changeTheme(themeKey)}
                      className={styles.colorOptionWrapper}
                      style={{
                        border:
                          theme === themeKey
                            ? `2px solid ${primaryColor}`
                            : '2px solid var(--light-grey-accent)',
                      }}
                    >
                      <div className={styles.colorPallet}>
                        {colorsArray?.map(color => {
                          return (
                            <div
                              key={color}
                              style={{
                                backgroundColor: color,
                                width: '100%',
                                height: '100%',
                              }}
                            />
                          )
                        })}
                      </div>
                      <div key={themeKey} className={styles.themeOption}>
                        <div className={styles.themeNameWrapper}>
                          <div
                            style={{
                              backgroundColor:
                                theme === themeKey
                                  ? 'var(--primary-color)'
                                  : 'transparent',
                            }}
                            className={styles.selectedBox}
                          />
                          <div className={styles.themeName}>
                            {startCase(themeKey)}
                          </div>
                        </div>
                        {isCustomTheme(themeKey) && (
                          <UilTrashAlt
                            onClick={() => handleDeleteTheme(themeKey)}
                            size={20}
                            color={'var(--red)'}
                          />
                        )}
                      </div>
                    </div>
                  )
                })}
              </div>
              <div>
                <div
                  onClick={() => setOpenThemeCreator(!openThemeCreator)}
                  className={styles.saveThemeButton}
                >
                  {openThemeCreator ? 'Cancel' : 'Create New Theme'}
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      </Modal>
    </React.Fragment>
  )
}
