/* eslint-disable no-console */
import layoutConfig from 'constants/layoutConfig'

import { Auth } from 'aws-amplify'
import http from 'network/httpClient'

export const filterCall = call => {
  let parsedCall = call.filter(entry => {
    return entry.username === Auth.user.username
  })
  return parsedCall[0].state
}

export const setType =
  ({ key, value, item }) =>
  dispatch => {
    dispatch({
      type: 'SET_TYPE',
      payload: { key, value, item },
    })
  }

export const setTitle =
  ({ key, value, item }) =>
  dispatch => {
    dispatch({
      type: 'SET_TITLE',
      payload: { key, value, item },
    })
  }

export const setData =
  ({ key, value, item }) =>
  dispatch => {
    dispatch({
      type: 'SET_DATA',
      payload: { key, value, item },
    })
  }

export const setLayout = layout => (dispatch, getState) => {
  dispatch({
    type: 'SET_LAYOUT',
    payload: { layout: layout, breakpoint: getState().breakpoint },
  })
}

export const addComponent = component => dispatch => {
  dispatch({
    type: 'ADD_COMPONENT',
    payload: component,
  })
}

export const removeComponent = id => dispatch => {
  dispatch({
    type: 'REMOVE_COMPONENT',
    payload: id,
  })
}

export const fetchDashboard = id => dispatch => {
  http
    .get('papi/dashboards/' + id)
    .then(function (response) {
      dispatch({
        type: 'LOAD_DATA',
        payload: {
          ...response.data.item.Item,
        },
      })
      dispatch({
        type: 'SET_LAYOUT',
        payload: {
          layout: [...response.data.item.Item.state.layouts.lg],
          breakpoint: 'lg',
        },
      })
    })
    .catch(function (error) {
      console.log(error)
    })
}

export const fetchNames = () => dispatch => {
  http
    .get('papi/dashboards/summary')
    .then(function (response) {
      let user
      let dashboardNamesDates = response.data?.item?.Items?.map(db => {
        const { dashboardId, dashboardName, lastOpened, userId } = db
        user = userId
        return {
          name: dashboardName,
          lastUpdated: lastOpened,
          id: dashboardId,
        }
      })

      dispatch({
        type: 'FETCH_DASHBOARDS',
        payload: {
          count: dashboardNamesDates.length,
          names: [...dashboardNamesDates],
          userId: user,
        },
      })
    })
    .catch(function (error) {
      console.error(error)
    })
}

export const changeTicker = (ticker, ComponentID) => dispatch => {
  dispatch({
    type: 'UPDATE_TICKER',
    payload: { id: ComponentID, ticker: ticker },
  })
}

export const updateTableState = (state, ComponentID) => dispatch => {
  dispatch({
    type: 'UPDATE_TABLE_STATE',
    payload: { id: ComponentID, state: state },
  })
}
export const newDashboard = name => dispatch => {
  const userId = Auth.user.username
  var d = new Date()
  let dashboard = {
    name: name,
    data: {},
    lastUpdated: d.toLocaleDateString(),
    layouts: layoutConfig,
    breakpoint: 'lg',
  }
  http
    .post('papi/dashboards/', {
      userId,
      name: name,
      state: dashboard,
    })
    .then(function (response) {
      const { id, name } = response?.data?.dashboard
      dispatch({
        type: 'LOAD_DATA',
        payload: {
          userId,
          name: name,
          id: id,
          state: dashboard,
        },
      })
      dispatch({
        type: 'SET_LAYOUT',
        payload: {
          layout: [...dashboard.layouts.lg],
          breakpoint: 'lg',
        },
      })
    })
    .catch(function (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    })
}

export const saveDashboard = state => dispatch => {
  dispatch({
    type: 'SAVE_DASHBOARD',
    payload: '',
  })
}

export const renameDashboard = (newName, oldName) => dispatch => {
  dispatch({
    type: 'RENAME_DASHBOARD',
    payload: { newName, oldName },
  })
}

export const closeDashboard = (id, name) => dispatch => {
  dispatch({
    type: 'CLOSE_DASHBOARD',
    payload: {
      id,
      name: name,
    },
  })
}
