import { interpolateHsl } from 'd3-interpolate'

export const generateGradientColors = (
  colorStart,
  colorEnd,
  count,
  exponent = 1
) => {
  const colorInterpolator = interpolateHsl(colorStart, colorEnd)
  return Array.from({ length: count }, (_, i) => {
    const progress = Math.pow(i / (count - 1), exponent)
    return colorInterpolator(progress)
  })
}

export const calculateDonutPath = (
  value,
  outerRadius,
  innerRadius,
  cx,
  cy,
  startAngle,
  total,
  normalPie
) => {
  if (normalPie) innerRadius = 0

  // Calc for start and end or outer circle
  const startXOuter = cx + outerRadius * Math.cos(2 * Math.PI * startAngle)
  const startYOuter = cy + outerRadius * Math.sin(2 * Math.PI * startAngle)
  startAngle += value / total
  const endXOuter = cx + outerRadius * Math.cos(2 * Math.PI * startAngle)
  const endYOuter = cy + outerRadius * Math.sin(2 * Math.PI * startAngle)

  // Calc for start and end or inner circle
  const startXInner = cx + innerRadius * Math.cos(2 * Math.PI * startAngle)
  const startYInner = cy + innerRadius * Math.sin(2 * Math.PI * startAngle)
  const endXInner =
    cx + innerRadius * Math.cos(2 * Math.PI * (startAngle - value / total))
  const endYInner =
    cy + innerRadius * Math.sin(2 * Math.PI * (startAngle - value / total))

  const largeArcFlag = value / total > 0.5 ? 1 : 0

  // draw path
  return `M ${startXOuter},${startYOuter}
            A ${outerRadius},${outerRadius} 0 ${largeArcFlag} 1 ${endXOuter},${endYOuter}
            L ${startXInner},${startYInner}
            A ${innerRadius},${innerRadius} 0 ${largeArcFlag} 0 ${endXInner},${endYInner}
            Z`
}

export const getLabelPosition = (
  percent,
  outerRadius,
  innerRadius,
  cx,
  cy,
  startAngle
) => {
  const middleRadius = (outerRadius + innerRadius) / 2
  const angle = 2 * Math.PI * (startAngle + percent / 2)
  const x = cx + middleRadius * Math.cos(angle)
  const y = cy + middleRadius * Math.sin(angle)
  return { x, y, angle }
}
