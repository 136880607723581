import React from 'react'
import Modal from '@mui/material/Modal'
import CoreButton from 'core/Button/CoreButton'
import styles from './DeleteWatchlistModal.module.scss'
import times from '../../../assets/times.svg'
import Box from '@mui/material/Box'

const DeleteWatchlistModal = ({
  isOpen,
  handleClose,
  handleDelete,
  watchlistName,
}) => {
  return (
    <Modal open={isOpen} onClose={handleClose} disableAutoFocus={true}>
      <Box
        sx={{
          '&:focus': {
            outline: 'none',
          },
        }}
      >
        <div className={styles.watchlistInfoModal}>
          <div className={styles.watchlistInfoModalHeader}>
            <div>Delete "{watchlistName}"?</div>
            <img
              src={times}
              alt="close"
              className={styles.closeModal}
              onClick={handleClose}
            />
          </div>
          <div className={styles.deleteWatchlistQuestion}>
            Are you sure you want to delete this watchlist? This action cannot
            be undone.
          </div>
          <div className={styles.modalConfirmButton}>
            <CoreButton color={'var(--red)'} onClick={handleDelete}>
              Delete Watchlist
            </CoreButton>
          </div>
        </div>
      </Box>
    </Modal>
  )
}

export default DeleteWatchlistModal
