const stripeKeys = {
  proProduct: process.env.REACT_APP_PRO_PRODUCT_KEY,
  proYearly: process.env.REACT_APP_PRO_YEARLY_KEY,
  proMonthly: process.env.REACT_APP_PRO_MONTHLY_KEY,
}

const GLOBAL_STOCK_EXCHANGE = 'Global Stock Coverage'
const FINANCIALS_ANNUAL = 'Financials Annual'
const FINANCIALS_QUARTERLY = 'Financials Quarterly'
const ANALYST_ESTIMATES = 'Analyst Estimates'
const SEC = 'SEC Filings'
const EARNINGS_EARNINGS_TRANSCRIPT = 'Earnings & Earnings Transcript History'
const SAVED_SCREEN = 'Saved Screen'
const SAVED_DASHBOARDS = 'Saved Dashboard'
const SAVED_WATCHLISTS = 'Saved Watchlists'
export const PREMIUM_FEATURES = {
  GLOBAL_STOCK_EXCHANGE,
  FINANCIALS_ANNUAL,
  FINANCIALS_QUARTERLY,
  ANALYST_ESTIMATES,
  EARNINGS_EARNINGS_TRANSCRIPT,
  SEC,
  SAVED_SCREEN,
  SAVED_DASHBOARDS,
  SAVED_WATCHLISTS,
}

const dateYearsAgo = (() => {
  const today = new Date()
  today.setFullYear(today.getFullYear() - 1)
  return today.toLocaleDateString()
})()

const unlimitedDate = (() => {
  const today = new Date()
  today.setFullYear(today.getFullYear() - 100)
  return today.toLocaleDateString()
})()

export const USERS = {
  FreeUsers: {
    name: 'Free',
    cognitoGroup: 'FreeUsers',
    description: 'The best investment research on the internet. For Free.',
    prices: {
      monthly: {
        price: '$0',
      },
      yearly: {
        price: '$0',
      },
    },
    features: {
      [GLOBAL_STOCK_EXCHANGE]: true,
      [FINANCIALS_ANNUAL]: 4,
      [FINANCIALS_QUARTERLY]: 12,
      [SEC]: dateYearsAgo,
      [ANALYST_ESTIMATES]: 1,
      [EARNINGS_EARNINGS_TRANSCRIPT]: 2,
      [SAVED_SCREEN]: 1,
      [SAVED_DASHBOARDS]: 1,
      [SAVED_WATCHLISTS]: 1,
    },
    featuresDesc: [
      '3 Daily Copilot Prompts',
      'Global Stock Coverage',
      '5Y Financials',
      // '1Y Analyst Estimates',
      '2Y Earnings & Earnings Transcript History',
      '1 Saved Screen',
      '1 Dashboard',
      '1 Watchlist',
    ],
    button: 'Enter Terminal',
  },
  // PlusUsers: {
  //   name: 'Plus',
  //   cognitoGroup: 'PlusUsers',
  //   description: 'Enhanced tools for deeper market analysis at a great value.',
  //   productId: stripeKeys.plusProduct,
  //   prices: {
  //     monthly: {
  //       lookupKey: stripeKeys.plusMonthly,
  //       priceId: stripeKeys.plusMonthly,
  //       price: '$8.99',
  //     },
  //     yearly: {
  //       lookupKey: stripeKeys.plusYearly,
  //       priceId: stripeKeys.plusYearly,
  //       price: '$7.99',
  //     },
  //   },
  //   features: {
  //     [GLOBAL_STOCK_EXCHANGE]: true,
  //     [FINANCIALS]: 10,
  //     [ANALYST_ESTIMATES]: 3,
  //     [EARNINGS_EARNINGS_TRANSCRIPT]: 5,
  //     [SAVED_SCREEN]: 5,
  //     [SAVED_DASHBOARDS]: 3,
  //     [SAVED_WATCHLISTS]: 3,
  //   },
  //   featuresDesc: [
  //     'Global Stock Coverage',
  //     '10Y Financials',
  //     '3Y Analyst Estimates',
  //     '5Y Earnings & Earnings Transcript History',
  //     '5 Saved Screens',
  //     '3 Dashboards',
  //     '3 Watchlists',
  //   ],
  //   button: 'Select Plan',
  // },
  ProUsers: {
    name: 'Pro',
    cognitoGroup: 'ProUsers',
    description: 'Unrivalled insights with full access to premium features.',
    productId: stripeKeys.proProduct,
    prices: {
      monthly: {
        lookupKey: stripeKeys.proMonthly,
        priceId: stripeKeys.proMonthly,
        price: '$18.99',
      },
      yearly: {
        lookupKey: stripeKeys.proYearly,
        priceId: stripeKeys.proYearly,
        price: '$14.15',
      },
    },
    features: {
      [GLOBAL_STOCK_EXCHANGE]: true,
      [FINANCIALS_ANNUAL]: Infinity,
      [FINANCIALS_QUARTERLY]: Infinity,
      [SEC]: unlimitedDate,
      [ANALYST_ESTIMATES]: Infinity,
      [EARNINGS_EARNINGS_TRANSCRIPT]: Infinity,
      [SAVED_SCREEN]: Infinity,
      [SAVED_DASHBOARDS]: Infinity,
      [SAVED_WATCHLISTS]: Infinity,
    },
    featuresDesc: [
      '100 Daily Copilot Prompts',
      'Global Stock Coverage',
      '30Y+ Financials',
      // '5Y Analyst Estimates',
      'Full Earnings & Earnings Transcript History',
      'Unlimited Saved Screen',
      'Unlimited Dashboards',
      'Unlimited Watchlists',
    ],
    button: 'Select Plan',
  },
}

/**
 *
 * @param {string} subscriptionPlan
 * @param {string} featureLabel - type of feature in the PREMIUM_FEATURE
 * @returns
 */

export const getFeatureLimit = (subscriptionPlan, featureLabel) => {
  return USERS[subscriptionPlan]?.features[featureLabel]
}

/**
 * returns a boolean on whether the user can access that feature
 * @param {string} subscriptionPlan
 * @param {string} featureLabel
 * @param {number} limit
 * @returns
 */
export const canAccessFeature = (subscriptionPlan, featureLabel, limit) => {
  return USERS[subscriptionPlan]?.features[featureLabel] > limit
}

export default {
  canAccessFeature,
  getFeatureLimit,
  USERS,
}
