import dayjs from 'dayjs'
import { sliderStyles } from './styles'

export const defaultRenderHandleLabels = ({ value, data }) => {
  const year = data?.find(d => d.value === value)?.date
  return (
    <div
      style={{
        ...sliderStyles.handleLabelStyles,
      }}
    >
      {year || value}
    </div>
  )
}

export const defaultRenderTicks = (data, interval = 3) => {
  return data?.map((item, index) => {
    if (index % interval === 0) {
      return (
        <div
          key={index}
          style={{
            left: `${(index / (data.length - 1)) * 100}%`,
            ...sliderStyles.labelStyles,
          }}
        >
          {dayjs(item?.date).format('YY' + "'")}
        </div>
      )
    } else {
      return null
    }
  })
}
