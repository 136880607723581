import numeral from 'numeral'
import { startCase } from 'lodash'

export const industryColumns = [
  {
    Header: 'Industry',
    accessor: 'label',
    width: '100%',
    Cell: Cell => {
      if (Cell.value === null || Cell.value === '') {
        return ''
      } else {
        return <span>{startCase(Cell.value?.toLowerCase())}</span>
      }
    },
  },
  {
    Header: 'Weight',
    accessor: 'value',
    width: '60px',
    Cell: Cell => {
      return <span> {numeral(Cell.value).format('0.00')}%</span>
    },
  },
]

export const countryColumns = [
  {
    Header: 'Country',
    accessor: 'label',
    width: '100%',
    Cell: Cell => {
      if (Cell.value === null || Cell.value === '') {
        return ''
      } else {
        return <span>{startCase(Cell.value?.toLowerCase())}</span>
      }
    },
  },
  {
    Header: 'Weight',
    accessor: 'value',
    width: '60px',
    Cell: Cell => {
      return <span> {numeral(Cell.value).format('0.00')}%</span>
    },
  },
]

export const ETFInfoColumns = [
  { key: 'domicile', label: 'Domicile', text: true },
  { key: 'etfCompany', label: 'ETF Company', text: true },
  { key: 'inceptionDate', label: 'Inception Date', date: true },
  { key: 'isin', label: 'ISIN', text: true },
  { key: 'cusip', label: 'CUSIP', text: true },
  { key: 'navCurrency', label: 'Currency', text: true },
]
