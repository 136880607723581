import React, { useEffect, useState } from 'react'
import { useRanger } from 'react-ranger'
import { sliderStyles } from './styles'
import { defaultRenderHandleLabels, defaultRenderTicks } from './utils'
import { useFirstRender } from 'dashboard/ComparisonCharts'

function RangeSlider({
  min = 0,
  max = 100,
  range,
  onChange,
  interval,
  data,
  renderTicks = defaultRenderTicks,
  renderHandleLabels = defaultRenderHandleLabels,
}) {
  const stepSize = 1
  const [values, setValues] = useState(range || [min, max])
  const firstRender = useFirstRender()

  const { getTrackProps, handles, segments } = useRanger({
    values,
    min,
    max,
    stepSize,
    onChange: newValues => {
      setValues(newValues)
      if (!firstRender) {
        onChange(newValues)
      }
    },
  })

  const steps = Array.from(
    { length: (max - min) / stepSize + 1 },
    (_, index) => min + index * stepSize
  )

  return (
    <>
      <div {...getTrackProps({ style: sliderStyles.trackStyles })}>
        {segments?.map((segment, index) => (
          <div
            key={index}
            {...segment.getSegmentProps({
              style: {
                ...sliderStyles.segmentStyles,
              },
            })}
          />
        ))}
        <div
          style={{
            ...sliderStyles.rangeHighlightStyle,
            left: `${((values[0] - min) / (max - min)) * 100}%`,
            right: `${100 - ((values[1] - min) / (max - min)) * 100}%`,
          }}
        />
        {steps.map((step, index) => (
          <div
            key={index}
            style={{
              left: `${((step - min) / (max - min)) * 100}%`,
              ...sliderStyles.markStyle,
            }}
          />
        ))}
        {handles.map(handle => (
          <div
            key={handle.id}
            {...handle.getHandleProps({ style: sliderStyles.handleStyles })}
          >
            {renderHandleLabels({
              value: handle.value,
              data,
            })}
          </div>
        ))}
        {renderTicks ? renderTicks(data, interval) : null}
      </div>
    </>
  )
}

export default RangeSlider
