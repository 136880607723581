import { useQuery } from 'react-query'

import React from 'react'
import styles from './GeneralNews.module.scss'
import http from '../../../../network/httpClient'
const fetchNews = () => {
  return http.get('papi/data/api/v4/general_news?page=0')
}

export const GeneralNewsFeed = () => {
  return (
    <>
      <div className={styles.generalNewsWrapper}>
        <h2
          style={{
            color: 'var(--white)',
          }}
        >
          General News
        </h2>
        <NewsTable />
      </div>
    </>
  )
}

const NewsTable = () => {
  const { data } = useQuery('news-feed-data', fetchNews, {
    refetchInterval: 1000,
  })

  return (
    <div className={styles.generalNewsFeedWrapper}>
      {data?.data.map(newsData => {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              color: 'var(--white)',
            }}
          >
            <img
              src={newsData.image}
              style={{ maxWidth: '300px' }}
              alt="news img"
            />
          </div>
        )
      })}
    </div>
  )
}
