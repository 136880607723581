import React, { useState } from 'react'
import styles from './AnalyticalPanel.module.scss'
import { startCase } from 'lodash'
import { CoreBarChart } from 'core/Charts/GenericChart'
import { ScaleDiff } from '../ScaleDifference/ScaleDiff'
import FinRadarChartD3 from '../RadarChart/RadarDThree'
import { ScoreCard } from '../ScoreCard/ScoreCard'
import { RenderMetricRows } from '../AccordionTable/Accordion'
import { CardWrapper } from '../../../../../../core/CardWrapper/CardWrapper'
import { getCoreBarChartProps, getLastElement, overViewData } from './utils'
import { DataTable } from '../DataTable/DataTable'
import numeral from 'numeral'

export const AnalyticalPanel = ({
  selectedFinancialMetrics,
  recentAnalyticsData,
  finData,
  symbol,
  expandedPanel = true,
  scores,
  state,
  companyData,
  allScores,
  overallScore,
  companyName,
  growthData,
}) => {
  const [globalDataKey, setVisibleChart] = useState(
    expandedPanel ? 'priceBookValueRatio' : null
  )

  const [growth, setGrowthData] = useState(false)

  const toggleChart = metricName => {
    setVisibleChart(globalDataKey === metricName ? null : metricName)
  }

  const toggleGrowth = () => {
    setGrowthData(!growth)
  }

  const coreBarChartProps = getCoreBarChartProps(
    growthData,
    globalDataKey,
    companyData,
    growth
  )

  const recentData = getLastElement(growthData)

  const getData = recentData?.[globalDataKey]

  const overviewData = overViewData(getData)

  const scaleData = {
    min: getData?.MIN,
    max: getData?.MAX,
    avg: getData?.AVG,
    company: getData?.companyValue,
  }

  const allCategoriesFalse = Object.values(state).every(val => !val)

  return (
    <div className={styles.expandedPanelLayout}>
      <CardWrapper
        height={'100%'}
        width={'484px'}
        label={'Analytical Financial Metrics'}
        overflow={true}
        content={
          allCategoriesFalse ? (
            <div className={styles.nullScaleComp}>
              Select an analytical category.
            </div>
          ) : (
            <RenderMetricRows
              selectedFinancialMetrics={selectedFinancialMetrics}
              finData={finData}
              recentAnalyticsData={recentAnalyticsData}
              globalDataKey={globalDataKey}
              toggleChart={toggleChart}
              symbol={symbol}
              expandedPanel={expandedPanel}
              state={state}
            />
          )
        }
      />
      <div className={styles.sections}>
        <CardWrapper
          height={'calc(100% - 254px)'}
          width={'100%'}
          label={`${companyName} Analytics Overview`}
          content={
            allCategoriesFalse ? (
              <div className={styles.nullScaleComp}>
                Select an analytical category.
              </div>
            ) : (
              <div className={styles.overAllSection}>
                <div className={styles.radarChartWrapper}>
                  <FinRadarChartD3
                    data={scores}
                    dataKey={'score'}
                    category={'name'}
                    color="var(--primary-color)"
                    domain={[0, 100]}
                  />
                </div>
                <div className={styles.scoresWrapper}>
                  {/* <ScoreCard
                    score={overallScore}
                    symbol={symbol}
                    name={'AAPL Overall Analytical Score'}
                  />
                  {allScores.map(score => (
                    <ScoreCard
                      score={score.score}
                      symbol={symbol}
                      name={score.name}
                    />
                  ))} */}
                  <ScoreCard
                    score={overallScore}
                    symbol={symbol}
                    // name={`${symbol} Overall Analytical Score`}
                    name={`${symbol} Overall Score`}
                  />
                  <div className={styles.allScoresWrapper}>
                    {allScores.map(score => (
                      <>
                        <div className={styles.dataRow}>
                          <div>{score.name}</div>
                          <div className={styles.dataValue}>
                            {numeral(score.score).format('0')}
                          </div>
                        </div>
                        <div className={styles.divider} />
                      </>
                    ))}
                  </div>
                </div>
              </div>
            )
          }
        />
        <CardWrapper
          height={'246px'}
          width={'100%'}
          label={
            globalDataKey === null
              ? 'Individual Metrics Analysis'
              : `${startCase(globalDataKey)} Analytical Insights`
          }
          content={
            allCategoriesFalse ? (
              <div className={styles.nullScaleComp}>
                Select an analytical category.
              </div>
            ) : (
              <div className={styles.chartWrapper}>
                {globalDataKey ? (
                  <>
                    <div className={styles.insideChartWrapper}>
                      <DataTable data={overviewData} />
                      {/* <div className={styles.scaleComp}>
                        <DataTable data={overviewData} />
                        <ScaleDiff
                          symbol={symbol}
                          scaleData={scaleData}
                          dataKey={globalDataKey}
                        />
                      </div> */}
                      <div className={styles.buttonWrapper}>
                        <ScaleDiff
                          symbol={symbol}
                          scaleData={scaleData}
                          dataKey={globalDataKey}
                        />
                        <div
                          onClick={toggleGrowth}
                          className={styles.dataSwapButton}
                        >
                          {growth ? 'Normal' : 'Growth'}
                        </div>
                        <CoreBarChart {...coreBarChartProps} />
                      </div>
                    </div>
                  </>
                ) : (
                  <div className={styles.nullScaleComp}>
                    Select a metric to view insights.
                  </div>
                )}
              </div>
            )
          }
        />
      </div>
    </div>
  )
}
