import styles from './QuoteBar.module.scss'
import React, { useState, useRef, useEffect } from 'react'
import { InstitutionalTickerSearch } from 'dashboard/Title/components/TickerSearchView'
import { useData } from './data'
import dayjs from 'dayjs'
import numeral from 'numeral'
import { FormattedSpanPrecentage } from '../PositiveNegativeFormattedElements/FormattedSpan'
import { getQuoteBarItems } from './constants.js'
import leftArrow from './leftArrow.svg'
import rightArrow from './rightArrow.svg'

export const InstitutionalQuoteBar = ({ updateInstitution, institution }) => {
  const { data, isLoading } = useData(institution)

  const quoteBarItems = getQuoteBarItems(data)

  const containerRef = useRef(null)

  const [scrollPosition, setScrollPosition] = useState({
    atStart: true,
    atEnd: false,
  })

  // useEffect(() => {
  //   const container = containerRef.current
  //   const updateScrollPosition = () => {
  //     const atStart = container.scrollLeft === 0
  //     const atEnd =
  //       container.scrollLeft + container.clientWidth >=
  //       container.scrollWidth - 1
  //     setScrollPosition({ atStart, atEnd })
  //   }

  //   container.addEventListener('scroll', updateScrollPosition)

  //   const resizeObserver = new ResizeObserver(updateScrollPosition)
  //   resizeObserver.observe(container)

  //   return () => {
  //     container.removeEventListener('scroll', updateScrollPosition)
  //     resizeObserver.unobserve(container)
  //   }
  // }, [])

  // const scrollContent = direction => {
  //   const container = containerRef.current
  //   const scrollAmount = 150

  //   if (direction === 'left') {
  //     container.scrollTo({
  //       left: container.scrollLeft - scrollAmount,
  //       behavior: 'smooth',
  //     })
  //   } else {
  //     container.scrollTo({
  //       left: container.scrollLeft + scrollAmount,
  //       behavior: 'smooth',
  //     })
  //   }
  // }

  return (
    <div className={styles.quoteBar}>
      {/* {companyData?.profile?.image ? (
        <img
          src={companyData.profile.image}
          className={styles.quoteBarCompanyLogo}
          alt={`${ticker}'s logo`}
        />
      ) : null} */}
      <InstitutionalTickerSearch updateTicker={updateInstitution} />
      {/* <div className={styles.quoteBarItemWrapperStockPrice}>
        <div className={styles.quoteBarPrice}>
          <span
            className={`${
              priceDirection === 'up'
                ? styles.up
                : priceDirection === 'down'
                ? styles.down
                : ''
            }`}
            style={{ padding: '0 1px' }}
          >
            {numeral(stockPrice?.price).format('0,0.00')}
          </span>
          <span className={styles.quoteBarChange}>
            <FormattedSpanPrecentage
              value={numeral(stockPrice?.change).format('0.00')}
              sign={'%'}
              changesPercentage={numeral(stockPrice?.changesPercentage).format(
                '0.00'
              )}
            />
          </span>
        </div>
        <div className={styles.quoteBarTimeStamp}>
          {`(${companyData?.profile?.currency}) Last Updated: ${updatedTimeStamp}`}
        </div>
      </div>
      {!scrollPosition.atStart && (
        <>
          <button
            className={styles.arrowButton}
            onClick={() => scrollContent('left')}
          >
            <img
              src={leftArrow}
              alt="LeftArrow"
              style={{ width: '20px', height: '20px' }}
            />
          </button>
        </>
      )} */}
      <div className={styles.content1Wrapper} ref={containerRef}>
        {quoteBarItems.map((metric, i) => (
          <div key={i} className={styles.dividerWrapper}>
            <div className={styles.quoteBarItemWrapper}>
              <div className={styles.quoteBarItemValue}>{metric.value}</div>
              <div className={styles.quoteBarItemLabel}>{metric.label}</div>
            </div>
            {i === quoteBarItems.length - 1 ? null : (
              <div className={styles.divider}></div>
            )}
          </div>
        ))}
      </div>

      {/* {!scrollPosition.atEnd && (
        <>
          <button
            className={styles.arrowButton}
            onClick={() => scrollContent('right')}
          >
            <img
              src={rightArrow}
              alt="RightArrow"
              style={{ width: '20px', height: '20px' }}
            />
          </button>
        </>
      )} */}
    </div>
  )
}
