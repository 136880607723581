import dayjs from 'dayjs'
import numeral from 'numeral'
import { ExtractValue } from 'core/DataFunctions/ExtractValue'
import TooltipTicker from 'core/TickerOverlay/TickerOverlay'

const columns_SEC_RSS_Feed = [
  {
    Header: 'Date',
    disableFilters: true,
    accessor: 'date',
    Cell: Cell => (
      <div rel="noreferrer">{dayjs(Cell.value).format('MMM D, h:mm A')}</div>
    ),
  },
  {
    Header: 'Symbol',
    accessor: 'ticker',
    width: 100,
    filter: 'search',
    Cell: Cell => {
      return <TooltipTicker ticker={Cell.value} />
    },
  },
  {
    Header: 'Title',
    accessor: 'title',
    filter: 'search',
    width: 400,
    Cell: Cell => {
      const ReplaceWords = [' (Filer)']
      return (
        <a
          style={{
            fontSize: 'var(--default-text)',
            color: 'var(--primary-color)',
          }}
          href={Cell?.row?.original?.link}
          target={'_blank'}
          rel="noreferrer"
        >
          {ExtractValue(Cell.value, ReplaceWords)}
        </a>
      )
    },
  },
]

const columns_8K_RSS_Feed = [
  {
    Header: 'Date',
    disableFilters: true,
    accessor: 'date',
    Cell: Cell => (
      <div rel="noreferrer">{dayjs(Cell.value).format('MMM D, h:mm A')}</div>
    ),
  },
  {
    Header: 'Symbol',
    accessor: 'symbol',
    width: 100,
    filter: 'search',
    Cell: Cell => {
      return <TooltipTicker ticker={Cell.value} />
    },
  },
  {
    Header: 'Title',
    accessor: 'title',
    width: 400,
    filter: 'search',
    Cell: Cell => {
      const ReplaceWords = [' (Filer)']
      return (
        <a
          style={{
            fontSize: 'var(--default-text)',
            color: 'var(--primary-color)',
          }}
          href={Cell?.row?.original?.link}
          target={'_blank'}
          rel="noreferrer"
        >
          {ExtractValue(Cell.value, ReplaceWords)}
        </a>
      )
    },
  },
]

const columns_SEC_Fillings = [
  {
    Header: 'symbol',
    accessor: 'symbol',
    Cell: Cell => {
      return <TooltipTicker ticker={Cell.value} />
    },
  },
  {
    Header: 'fillingDate',
    accessor: 'fillingDate',
  },

  {
    Header: 'Accepted Date',
    accessor: 'acceptedDate',
  },
  {
    Header: 'cik',
    accessor: 'cik',
  },
  {
    Header: 'Form Type',
    accessor: 'type',
  },
]

const columns_Earnings_Calendar = [
  {
    Header: 'date',
    accessor: 'date',
  },
  {
    Header: 'symbol',
    accessor: 'symbol',
    Cell: Cell => {
      return <TooltipTicker ticker={Cell.value} />
    },
  },

  {
    Header: 'eps',
    accessor: 'eps',
  },
  {
    Header: 'epsEstimated',
    accessor: 'epsEstimated',
  },
  {
    Header: 'time',
    accessor: 'time',
  },
  {
    Header: 'revenue',
    accessor: 'revenue',
  },
  {
    Header: 'revenueEstimated',
    accessor: 'revenueEstimated',
  },
  {
    Header: 'updatedFromDate',
    accessor: 'updatedFromDate',
  },
  {
    Header: 'fiscalDateEnding',
    accessor: 'fiscalDateEnding',
  },
]

const columns_Insider_Trading = [
  {
    Header: 'Symbol',
    accessor: 'symbol',
    Cell: Cell => {
      return <TooltipTicker ticker={Cell.value} />
    },
  },
  {
    Header: 'Filing Date',
    accessor: 'filingDate',
    disableFilters: true,
    Cell: e => (
      <a
        href={e?.row?.original?.link}
        target={'_blank'}
        style={{
          fontSize: 'var(--default-text)',
          color: 'var(--primary-color)',
        }}
        rel="noreferrer"
      >
        {dayjs(e.value).format('MMM D, h:mm A')}
      </a>
    ),
  },
  {
    Header: 'Transaction Type',
    accessor: 'transactionType',
    width: 140,
  },
  {
    Header: 'Securities Owned',
    accessor: 'securitiesOwned',
    disableFilters: true,
    Cell: e => <span>{numeral(e.value).format('0.000 a')}</span>,
  },
  {
    Header: 'Owner',
    accessor: 'reportingName',
    width: 200,
    filter: 'search',
  },
  {
    Header: 'Type Of Owner',
    accessor: 'typeOfOwner',
    filter: 'search',
    width: 300,
  },
  {
    Header: 'Form',
    accessor: 'formType',
    disableFilters: true,
    width: 100,
  },
  {
    Header: 'Securities Transacted',
    accessor: 'securitiesTransacted',
    disableFilters: true,
    Cell: e => <span>{numeral(e.value).format('0.000 a')}</span>,
  },
  {
    Header: 'Price',
    accessor: 'price',
    filter: 'sort',
    disableFilters: true,
  },
  {
    Header: 'Transaction Date',
    accessor: 'transactionDate',
    Cell: e => <span> {dayjs(e.value).format('MMM D')}</span>,
    disableFilters: true,
    width: 50,
  },
]

const columns_Social_Sentiment = [
  {
    Header: 'symbol',
    accessor: 'symbol',
    Cell: Cell => {
      return <TooltipTicker ticker={Cell.value} />
    },
  },
  {
    Header: 'Date',
    accessor: 'date',
    disableFilters: true,
  },
  {
    Header: 'Stocktwits Posts',
    accessor: 'stocktwitsPosts',
    disableFilters: true,
  },
  {
    Header: 'Twitter Posts',
    accessor: 'twitterPosts',
    disableFilters: true,
  },
  {
    Header: 'Stocktwits Comments',
    accessor: 'stocktwitsComments',
    disableFilters: true,
  },
  {
    Header: 'twitterComments',
    accessor: 'twitterComments',
    disableFilters: true,
  },
  {
    Header: 'Sstocktwits Likes',
    accessor: 'stocktwitsLikes',
    disableFilters: true,
  },
  {
    Header: 'Twitter Likes',
    accessor: 'twitterLikes',
    disableFilters: true,
  },
  {
    Header: 'Stocktwits Impressions',
    accessor: 'stocktwitsImpressions',
    disableFilters: true,
  },
  {
    Header: 'Twitter Impressions',
    accessor: 'twitterImpressions',
    disableFilters: true,
  },
  {
    Header: 'Stocktwits Sentiment',
    accessor: 'stocktwitsSentiment',
    disableFilters: true,
  },
  {
    Header: 'Twitter Sentiment',
    accessor: 'twitterSentiment',
    disableFilters: true,
  },
]

const columns_Executives = [
  {
    Header: 'title',
    accessor: 'title',
  },
  {
    Header: 'name',
    accessor: 'name',
  },
  {
    Header: 'pay',
    accessor: 'pay',
  },
  {
    Header: 'currencyPay',
    accessor: 'currencyPay',
  },
  {
    Header: 'gender',
    accessor: 'gender',
  },
  {
    Header: 'yearBorn',
    accessor: 'yearBorn',
  },
  {
    Header: 'titleSince',
    accessor: 'titleSince',
  },
]

export const basicTableColumns = {
  columns_Earnings_Calendar,
  columns_Executives,
  columns_Insider_Trading,
  columns_SEC_Fillings,
  columns_SEC_RSS_Feed,
  columns_Social_Sentiment,
  columns_8K_RSS_Feed,
}
