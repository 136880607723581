import React, { memo, useEffect, useState } from 'react'
import { useTable, useRowSelect, useSortBy } from 'react-table'
import '../../styles.scss'
import { SortColumnFilter } from './filters'
const styles = {
  _table: {
    backgroundColor: 'var(--background-primary)',

    // display: 'block',
    width: '100%',
    maxWidth: '100%',
  },

  _th: {
    fontStyle: 'normal',
    paddingLeft: '8px',
    paddingRight: '8px',
    fontWeight: 400,
    height: '24px',
    cursor: 'pointer',
    backgroundColor: 'var(--background-primary)',
    zIndex: 1000,
    color: 'var(--white)',
    userSelect: 'none',
  },

  _tr_Head: {
    top: 0,
    position: 'sticky',
    whiteSpace: 'nowrap',
    height: '28px',
  },

  _td: {
    fontFamily: 'var(--font-family)',
    fontStyle: 'normal',
    paddingLeft: '8px',
    paddingRight: '8px',
    fontWeight: 400,
    fontSize: '13px',
    backgroundColor: '',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    color: 'var(--white)',
    height: '32px',
    lineheight: '28px',
    boxSizing: 'border-box',
  },
}

export function SelectableTable({
  columns,
  overflowXScroll,
  data,
  onSelectRow,
  onDeSelectRow,
  initialSelect,
  cellhighlight = true,
  showHeaders = true,
  metrics,
  onInitialSelect,
  ...props
}) {
  const firstMetric = data.length > 0 ? data?.[0] : null
  const initialSelectedRows = firstMetric ? [firstMetric] : []
  const [selectedRows, setSelectedRow] = useState(initialSelectedRows)

  useEffect(() => {
    const initialSelectedRows = metrics?.map(metric => {
      const row = data.find(d => d?.symbol === metric?.key)
      return { ...row, id: metric.rowId }
    })

    setSelectedRow(initialSelectedRows)
  }, [metrics, data])

  const handlePushRow = row => {
    setSelectedRow([...selectedRows, row])
    onSelectRow(row)
  }
  const handlePopRow = row => {
    setSelectedRow([...selectedRows.filter(r => r.id !== row.id)])
    onDeSelectRow(row)
  }

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        initialState: {
          hiddenColumns: columns.map(column => {
            if (column?.show === false) return column.accessor || column.id
            return null
          }),
        },
      },
      useSortBy,
      useRowSelect
    )

  // useEffect(() => {
  //   setSelectedRow([rows[0]])
  //   onSelectRow(rows[0])
  // }, [data])

  useEffect(() => {
    const initialSelectedRows = metrics.map(metric => {
      const row = data.find(d => d.symbol === metric.key)
      return { ...row, id: metric.rowId }
    })

    setSelectedRow(initialSelectedRows)
  }, [metrics, data])

  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  const firstPageRows = rows?.slice(0, 50)
  // Render the UI for your table
  return (
    <div
      className="table-div"
      style={{
        width: '100%',
        maxWidth: '100%',
        height: '100%',
        background: 'var(--background-primary)',
        overflowX: overflowXScroll ? 'scroll' : 'hidden',
        overflowY: 'scroll',
        borderRadius: '4px',
      }}
    >
      <table style={styles._table} {...getTableProps()}>
        {showHeaders && (
          <thead style={styles._tr_Head}>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, i) => {
                  return (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      style={{ ...styles._th, paddingLeft: i === 0 && 25 }}
                    >
                      <span
                        style={{
                          fontWeight: 'var(--bold-text)',
                          fontSize: '13px',
                        }}
                      >
                        {column.render('Header')}
                        {column.isSorted ? (
                          <SortColumnFilter column={column} />
                        ) : (
                          ''
                        )}
                      </span>
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
        )}

        <tbody
          style={{
            width: '100%',
            height: '100%',
            background: 'var(--background-primary)',
            overflowX: 'scroll',
            borderRadius: '4px',
          }}
          {...getTableBodyProps()}
        >
          {firstPageRows.map((row, i) => {
            prepareRow(row)
            const isSelected = selectedRows?.filter(r => r.id === row.id)?.[0]

            return (
              <tr
                className="table-row"
                {...row.getRowProps()}
                onClick={e => {
                  if (isSelected) {
                    handlePopRow(row)
                  } else {
                    handlePushRow(row)
                  }
                }}
              >
                {row.cells.map((cell, j) => {
                  return (
                    <td
                      {...cell.getCellProps({
                        style: {
                          maxWidth: cell.column.width,
                          width: cell.column.width,
                          cursor: 'pointer',
                          ...styles._td,
                        },
                      })}
                    >
                      {j === 0 && (
                        <div style={{ float: 'left' }}>
                          <span
                            className="dot"
                            style={{
                              backgroundColor: isSelected
                                ? metrics?.find(m => m.rowId === row.id)?.color
                                : 'var(--background-tertiary)',
                            }}
                          ></span>
                        </div>
                      )}
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>{' '}
    </div>
  )
}

export default memo(SelectableTable)
