import styles from './calComp.module.scss'
import TooltipTicker from 'core/TickerOverlay/TickerOverlay'

export const SplitComponent = ({ event }) => {
  const { symbol, numerator, denominator } = event

  return (
    <div className={styles.splitEventWrapper}>
      <TooltipTicker ticker={symbol} />
      <div className={styles.eventDetails}>
        <div className={styles.value}>{numerator}</div>
        <div className={styles.info}>for</div>
        <div className={styles.value}>{denominator}</div>
      </div>
    </div>
  )
}
