export const getSelectStyles = ({ width }) => {
  return {
    control: () => ({
      backgroundColor: 'transparent',
      display: 'flex',
      flexDirection: 'row',
      height: 30,
      width: width,
    }),
    singleValue: () => ({
      color: 'var(--off-white)',
      gridArea: '1/1/2/3',
      marginLeft: '1px',
      marginRight: '1px',
      maxWidth: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      fontSize: '14px',
    }),
    indicatorSeparator: () => ({
      backgroundColor: 'var(--off-white)',
      alignSelf: 'stretch',
      marginBottom: '8px',
      marginTop: '8px',
      width: '1px',
      boxSizing: 'border-box',
    }),
    menu: () => ({
      top: '100%',
      backgroundColor: 'var(--background-primary)',
      borderRadius: '4px',
      border: '2px solid var(--grey-accent)',
      boxShadow:
        '0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 12px hsl(0deg 0% 0% / 10%)',
      marginBottom: '8px',
      marginTop: '8px',
      position: 'absolute',
      width: '100%',
      zIndex: 100,
      boxSizing: 'border-box',
    }),
    menuPortal: provided => ({ ...provided, zIndex: 9999 }),
    MenuList: () => ({
      maxHeight: '300px',
      overflowY: 'auto',
      paddingBottom: '4px',
      paddingTop: '4px',
      zIndex: 100,
      position: 'relative',
      boxSizing: 'border-box',
    }),
    option: () => ({
      backgroundColor: 'transparent',
      color: 'var(--off-white)',
      cursor: 'pointer',
      display: 'block',
      fontSize: '13px',
      zIndex: 100,
      padding: '8px 12px',
      whiteSpace: 'nowrap',
      width: '',
      boxSizing: 'border-box',
      '&:hover': {
        backgroundColor: 'var(--button-hover)',
      },
    }),
    input: (provided, state) => ({
      ...provided,
      color: 'var(--white)',
    }),
  }
}
