import { min, max, mean, median } from 'simple-statistics'

export const calcStats = values => {
  if (!Array.isArray(values) || values.length === 0) {
    return {
      mean: NaN,
      median: NaN,
      min: NaN,
      max: NaN,
      stddev: NaN,
      '75thPercentile': NaN,
    }
  }

  const stats = {
    mean: mean(values),
    median: median(values),
    minimum: min(values),
    maximum: max(values),
  }

  return stats
}

export const stringToNumber = str => {
  const lastChar = str.slice(-1)
  let num = parseFloat(str)
  switch (lastChar) {
    case 'b':
      num *= 1e9
      break
    case 'm':
      num *= 1e6
      break
    case 'k':
      num *= 1e3
      break
    default:
      break
  }
  return num
}

export const changePercent = (value1, value2) => {
  const numValue1 = stringToNumber(value1)
  const numValue2 = stringToNumber(value2)
  const percentageChange = ((numValue1 - numValue2) / numValue2) * 100
  return percentageChange > 0
    ? `+${percentageChange.toFixed(2)}`
    : percentageChange.toFixed(2)
}

/**
 * Since the watchlist button is protected and is governed by subscription plan
 * @param {function} canCreate -
 * @returns object of button props to be spread on the watchlist button
 */
