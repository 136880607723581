import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import Terminal from './pages/Terminal/Terminal'
import Amplify from 'aws-amplify'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallback from './pages/ErrorBoundary/ErrorBoundary'
import Alerts from 'pages/Alerts'
import config from './aws-exports'
import configureStore from './configureStore'
import HomePage from './pages/HomePage/HomePage'
import { PrivacyPolicy } from './pages/HomePage/HomePagePages/TOS_PP/PrivacyPolicy'
import { TermsOfService } from './pages/HomePage/HomePagePages/TOS_PP/TermsfService'
import { RecoilRoot } from 'recoil'
import { RecoilURLSyncJSON } from 'recoil-sync'
import { ContactUs } from './pages/HomePage/HomePagePages/Contact/Contact'
import { FeatureRequest } from './pages/HomePage/HomePagePages/Contact/Contact'
import { Feedback } from './pages/HomePage/HomePagePages/Contact/Contact'
import { ProtectedRoutes } from 'core/ProtectedRoute/ProtectedRoute'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import { FinancialStatementsProvider } from './pages/views/FinancialStatements/FinancialStatementsContext'
import { HelmetProvider } from 'react-helmet-async'
import AuthProvider from 'core/AuthComponents/AuthContext'
import { Pricing } from 'pages/Pricing/Pricing'
import CustomAuth from 'core/AuthComponents/CustomAuth'
import FourOFourIndex from './pages/ErrorBoundary/404/404Index'
import { ThemeProvider } from './theme/ThemeContext'
import Team from 'pages/HomePage/HomePagePages/AboutUs/AboutUs'
import Copilot from 'pages/HomePage/HomePagePages/Copilot/CopilotFeatures'
import Roadmap from 'pages/RoadMap/RoadMap'

import { ScrollToTop } from 'hooks/useScroll'
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})
Amplify.configure(config)
const store = configureStore()

function App() {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <HelmetProvider>
        <ThemeProvider>
          <Alerts>
            <Provider store={store}>
              <QueryClientProvider client={queryClient}>
                <BrowserRouter>
                  <RecoilRoot>
                    <RecoilURLSyncJSON location={{ part: 'queryParams' }}>
                      <AuthProvider>
                        <FinancialStatementsProvider>
                          <ScrollToTop />
                          <Routes>
                            {/* <Route exact path="/" element={<Maintenance />} /> */}
                            <Route exact path="/" element={<HomePage />} />
                            <Route
                              exact
                              path="*"
                              element={<FourOFourIndex />}
                            />
                            <Route
                              exact
                              path="/PrivacyPolicy"
                              element={<PrivacyPolicy />}
                            />
                            <Route
                              exact
                              path="/TermsOfService"
                              element={<TermsOfService />}
                            />
                            <Route
                              exact
                              path="/ContactUs"
                              element={<ContactUs />}
                            />
                            <Route
                              exact
                              path="/Feedback"
                              element={<Feedback />}
                            />
                            <Route exact path="/AboutUs" element={<Team />} />
                            <Route
                              exact
                              path="/copilot"
                              element={<Copilot />}
                            />
                            <Route exact path="/blog" element={<Copilot />} />
                            <Route
                              exact
                              path="/roadmap"
                              element={<Roadmap />}
                            />
                            <Route
                              exact
                              path="/FeatureRequest"
                              element={<FeatureRequest />}
                            />
                            <Route
                              exact
                              path="/pricing"
                              element={<Pricing />}
                            />
                            <Route
                              exact
                              path="/login"
                              element={<CustomAuth />}
                            />
                            <Route element={<ProtectedRoutes />}>
                              <Route
                                path="/terminal/*"
                                element={<Terminal />}
                              ></Route>
                            </Route>
                          </Routes>
                        </FinancialStatementsProvider>
                      </AuthProvider>
                    </RecoilURLSyncJSON>
                  </RecoilRoot>
                </BrowserRouter>
              </QueryClientProvider>
            </Provider>
          </Alerts>
        </ThemeProvider>
      </HelmetProvider>
    </ErrorBoundary>
  )
}
const root = createRoot(document.getElementById('root'))

root.render(<App />)
