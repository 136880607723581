import React, { useState } from 'react'
import { styles } from './ToolmenuStyles'
import { UilAngleDown } from '@iconscout/react-unicons'
import { UilAngleUp } from '@iconscout/react-unicons'
import style from './ToolMenuItem.module.scss'
import _ from 'lodash'
import { MenuToolTip } from './MenuToolTip'
import { NavLink } from 'react-router-dom'

export const ToolMenuExpandableItem = ({
  label,
  IconComp,
  config,
  iconSrc,
  collapsed,
  activeView,
  search,
}) => {
  const [expanded, setExpanded] = useState(false)

  const renderConfigMapTooltip = () => (
    <div>
      {config.map(({ label, navLinkTo }) => (
        <NavLink
          to={navLinkTo}
          className={style.menuItemTooltip}
          aria-label={label}
          title={label}
          style={({ isActive }) => ({
            backgroundColor: isActive ? 'var(--button-hover)' : undefined,
            color: isActive ? 'var(--primary-color)' : 'var(--white',
          })}
        >
          {_.startCase(label.replace('company', ''))}
        </NavLink>
      ))}
    </div>
  )

  if (collapsed) {
    return (
      <MenuToolTip
        label={label}
        renderConfigMapTooltip={renderConfigMapTooltip}
        handleToggleExpanded={() => setExpanded(!expanded)}
        iconSrc={iconSrc}
      />
    )
  } else {
    return (
      <>
        <div
          className="ExpandableMenuItem"
          aria-label={label}
          onClick={() => setExpanded(!expanded)}
          title={label}
          style={{ color: 'var(--white)' }}
        >
          <div
            style={{
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'center',
            }}
          >
            {iconSrc && iconSrc}
            <span style={styles.ToolMenuItem}>{label} </span>
          </div>
          {expanded ? (
            <UilAngleUp color="var(--white)" style={{ marginRight: 10 }} />
          ) : (
            <UilAngleDown color="var(--white)" style={{ marginRight: 10 }} />
          )}
        </div>
        <div
          className="menu-dropdown"
          style={{
            maxHeight: expanded ? '500px' : 0,
            opacity: expanded ? 1 : 0,
            overflow: 'hidden',
            transition:
              'max-height 0.1s ease-in-out, opacity 0.1s ease-in-out 0s',
            backgroundColor: 'var(--background-primary)',
          }}
        >
          {config.map(({ label, navLinkTo }) => {
            return (
              <NavLink
                key={label}
                to={navLinkTo + `${search}`}
                className="dropdown-item"
                activeClassName="activeDropdownItem"
              >
                <ToolMenuItem
                  key={label}
                  style={{
                    borderLeft:
                      activeView === label
                        ? '1px solid var(--light-grey-accent)'
                        : '1px solid var(--light-grey-accent)',
                    borderRadius: 0,
                    width: '100%',
                    paddingLeft: 6,
                    marginBottom: 0,
                    marginLeft: 38,
                  }}
                  navLinkTo={navLinkTo + `${search}`}
                  label={label.replace('company', '')}
                  active={activeView === label}
                />
              </NavLink>
            )
          })}
        </div>
      </>
    )
  }
}

export const ToolMenuItem = ({
  label,
  iconSrc,
  navLinkTo,
  style,
  collapsed,
  chip,
}) => {
  const dashboard = 'Dashboards'
  return (
    <NavLink
      to={navLinkTo}
      className="MenuItem"
      aria-label={label}
      title={label}
      style={({ isActive }) => ({
        ...style,
        backgroundColor:
          label === dashboard
            ? undefined
            : isActive
            ? 'var(--button-hover)'
            : undefined,
      })}
    >
      <div>
        {iconSrc && iconSrc}
        {!collapsed ? (
          <span style={styles.ToolMenuItem}>{_.startCase(label)}</span>
        ) : null}
      </div>
      {chip && !collapsed && chip}
    </NavLink>
  )
}
