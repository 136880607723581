import { useState, useEffect } from 'react'

import styles from './ViewCompanyQuote.module.scss'
import { CompanyQuoteTable } from '../CompanyQuoteTable'

import { ranges, plotByDayRanges, DAY_RANGE_1Y } from '../../constants'
import { fetchData, fetchGranularData } from '../../utils'

export const ViewCompanyQuote = ({ ticker }) => {
  const [data, setData] = useState()
  const [date] = useState(ranges[DAY_RANGE_1Y])
  const [range] = useState(DAY_RANGE_1Y)
  useEffect(() => {
    plotByDayRanges.includes(range)
      ? fetchData(ticker, date.start, date.end).then(data_ => setData(data_))
      : fetchGranularData({ ...date, ticker }).then(data_ => setData(data_))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, ticker])

  return (
    data && (
      <div className={styles.companyQuote}>
        <div className={styles.companyQuoteRight}>
          <div className={styles.companyQuoteRightTable}>
            <CompanyQuoteTable data={data?.quote_data[0]} isView={true} />
          </div>
        </div>
      </div>
    )
  )
}
