export const topInstitutionalList = [
  {
    cik: '0001067983',
    value: '0001067983,BERKSHIRE HATHAWAY INC',
    name: 'BERKSHIRE HATHAWAY INC',
    label: 'BERKSHIRE HATHAWAY INC',
  },
  {
    cik: '0000102909',
    value: '0000102909,VANGUARD GROUP INC',
    name: 'VANGUARD GROUP INC',
    label: 'VANGUARD GROUP INC',
  },
  {
    cik: '0001364742',
    value: '0001364742,BLACKROCK INC.',
    name: 'BLACKROCK INC.',
    label: 'BLACKROCK INC.',
  },
  {
    cik: '0001697748',
    value: '0001697748,ARK INVESTMENT MANAGEMENT LLC',
    name: 'ARK INVESTMENT MANAGEMENT LLC',
    label: 'ARK INVESTMENT MANAGEMENT LLC',
  },
]

export const institutionalList = [
  {
    cik: '0001511144',
    value: '0001511144,10-15 ASSOCIATES, INC.',
    name: '10-15 ASSOCIATES, INC.',
    label: '10-15 ASSOCIATES, INC.',
  },
  {
    cik: '0001602119',
    value: '0001602119,1060 CAPITAL, LLC',
    name: '1060 CAPITAL, LLC',
    label: '1060 CAPITAL, LLC',
  },
  {
    cik: '0001580250',
    value: '0001580250,1060 CAPITAL OPPORTUNITY FUND, LP',
    name: '1060 CAPITAL OPPORTUNITY FUND, LP',
    label: '1060 CAPITAL OPPORTUNITY FUND, LP',
  },
  {
    cik: '0001801172',
    value: '0001801172,11 CAPITAL PARTNERS LP',
    name: '11 CAPITAL PARTNERS LP',
    label: '11 CAPITAL PARTNERS LP',
  },
  {
    cik: '0001633703',
    value: '0001633703,12TH STREET ASSET MANAGEMENT COMPANY, LLC',
    name: '12TH STREET ASSET MANAGEMENT COMPANY, LLC',
    label: '12TH STREET ASSET MANAGEMENT COMPANY, LLC',
  },
  {
    cik: '0001540531',
    value: '0001540531,12 WEST CAPITAL MANAGEMENT LP',
    name: '12 WEST CAPITAL MANAGEMENT LP',
    label: '12 WEST CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001600133',
    value: '0001600133,13D MANAGEMENT LLC',
    name: '13D MANAGEMENT LLC',
    label: '13D MANAGEMENT LLC',
  },
  {
    cik: '0001820788',
    value: '0001820788,140 SUMMER PARTNERS LP',
    name: '140 SUMMER PARTNERS LP',
    label: '140 SUMMER PARTNERS LP',
  },
  {
    cik: '0001584087',
    value: '0001584087,1492 CAPITAL MANAGEMENT LLC',
    name: '1492 CAPITAL MANAGEMENT LLC',
    label: '1492 CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001436866',
    value: '0001436866,1607 CAPITAL PARTNERS, LLC',
    name: '1607 CAPITAL PARTNERS, LLC',
    label: '1607 CAPITAL PARTNERS, LLC',
  },
  {
    cik: '0001907281',
    value: '0001907281,1620 INVESTMENT ADVISORS, INC.',
    name: '1620 INVESTMENT ADVISORS, INC.',
    label: '1620 INVESTMENT ADVISORS, INC.',
  },
  {
    cik: '0001811034',
    value: '0001811034,1623 CAPITAL LLC',
    name: '1623 CAPITAL LLC',
    label: '1623 CAPITAL LLC',
  },
  {
    cik: '0001803140',
    value: '0001803140,1776 WEALTH LLC',
    name: '1776 WEALTH LLC',
    label: '1776 WEALTH LLC',
  },
  {
    cik: '0001727407',
    value: '0001727407,17 CAPITAL PARTNERS, LLC',
    name: '17 CAPITAL PARTNERS, LLC',
    label: '17 CAPITAL PARTNERS, LLC',
  },
  {
    cik: '0001730817',
    value: '0001730817,180 WEALTH ADVISORS, LLC',
    name: '180 WEALTH ADVISORS, LLC',
    label: '180 WEALTH ADVISORS, LLC',
  },
  {
    cik: '0000944388',
    value: '0000944388,1832 ASSET MANAGEMENT L.P.',
    name: '1832 ASSET MANAGEMENT L.P.',
    label: '1832 ASSET MANAGEMENT L.P.',
  },
  {
    cik: '0001885092',
    value: '0001885092,1888 INVESTMENTS, LLC',
    name: '1888 INVESTMENTS, LLC',
    label: '1888 INVESTMENTS, LLC',
  },
  {
    cik: '0001791996',
    value: '0001791996,1900 WEALTH MANAGEMENT LLC',
    name: '1900 WEALTH MANAGEMENT LLC',
    label: '1900 WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0001584289',
    value: '0001584289,1922 INVESTMENT CO LLC',
    name: '1922 INVESTMENT CO LLC',
    label: '1922 INVESTMENT CO LLC',
  },
  {
    cik: '0001641761',
    value: '0001641761,1 NORTH WEALTH SERVICES LLC',
    name: '1 NORTH WEALTH SERVICES LLC',
    label: '1 NORTH WEALTH SERVICES LLC',
  },
  {
    cik: '0001386792',
    value: '0001386792,1ST GLOBAL ADVISORS, INC.',
    name: '1ST GLOBAL ADVISORS, INC.',
    label: '1ST GLOBAL ADVISORS, INC.',
  },
  {
    cik: '0000356264',
    value: '0000356264,1ST SOURCE BANK',
    name: '1ST SOURCE BANK',
    label: '1ST SOURCE BANK',
  },
  {
    cik: '0001656866',
    value: '0001656866,20/20 CAPITAL MANAGEMENT, INC.',
    name: '20/20 CAPITAL MANAGEMENT, INC.',
    label: '20/20 CAPITAL MANAGEMENT, INC.',
  },
  {
    cik: '0001694297',
    value: '0001694,22NW, LP7',
    name: '22NW, LP',
    label: '22NW, LP',
  },
  {
    cik: '0001843587',
    value: '000184358,2XIDEAS AG',
    name: '2XIDEAS AG',
    label: '2XIDEAS AG',
  },
  {
    cik: '0000080835',
    value: '0000080835,300 NORTH CAPITAL, LLC',
    name: '300 NORTH CAPITAL, LLC',
    label: '300 NORTH CAPITAL, LLC',
  },
  {
    cik: '0001873893',
    value: '0001873893,325 CAPITAL LLC',
    name: '325 CAPITAL LLC',
    label: '325 CAPITAL LLC',
  },
  {
    cik: '0001802324',
    value: '0001802324,360 FINANCIAL, INC.',
    name: '360 FINANCIAL, INC.',
    label: '360 FINANCIAL, INC.',
  },
  {
    cik: '0001476534',
    value: '0001476534,361 CAPITAL LLC',
    name: '361 CAPITAL LLC',
    label: '361 CAPITAL LLC',
  },
  {
    cik: '0001845671',
    value: '0001845671,36 SOUTH CAPITAL ADVISORS LLP',
    name: '36 SOUTH CAPITAL ADVISORS LLP',
    label: '36 SOUTH CAPITAL ADVISORS LLP',
  },
  {
    cik: '0001359371',
    value: '0001359371,3D ASSET MANAGEMENT, INC',
    name: '3D ASSET MANAGEMENT, INC',
    label: '3D ASSET MANAGEMENT, INC',
  },
  {
    cik: '0001846943',
    value: '0001846943,3D/L CAPITAL MANAGEMENT, LLC',
    name: '3D/L CAPITAL MANAGEMENT, LLC',
    label: '3D/L CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001665605',
    value: '0001665605,3EDGE ASSET MANAGEMENT, LP',
    name: '3EDGE ASSET MANAGEMENT, LP',
    label: '3EDGE ASSET MANAGEMENT, LP',
  },
  {
    cik: '0001421669',
    value: '0001421669,3G CAPITAL PARTNERS LP',
    name: '3G CAPITAL PARTNERS LP',
    label: '3G CAPITAL PARTNERS LP',
  },
  {
    cik: '0001808275',
    value: '0001808275,3G SAHANA CAPITAL MANAGEMENT LP',
    name: '3G SAHANA CAPITAL MANAGEMENT LP',
    label: '3G SAHANA CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001163603',
    value: '0001163603,3I INVESTMENTS PLC',
    name: '3I INVESTMENTS PLC',
    label: '3I INVESTMENTS PLC',
  },
  {
    cik: '0001909739',
    value: '0001909739,400 CAPITAL MANAGEMENT LLC',
    name: '400 CAPITAL MANAGEMENT LLC',
    label: '400 CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001539436',
    value: '0001539436,40 NORTH MANAGEMENT LLC',
    name: '40 NORTH MANAGEMENT LLC',
    label: '40 NORTH MANAGEMENT LLC',
  },
  {
    cik: '0001473174',
    value: '0001473174,440 INVESTMENT GROUP, LLC',
    name: '440 INVESTMENT GROUP, LLC',
    label: '440 INVESTMENT GROUP, LLC',
  },
  {
    cik: '0001831187',
    value: '0001831187,44 WEALTH MANAGEMENT LLC',
    name: '44 WEALTH MANAGEMENT LLC',
    label: '44 WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0001840775',
    value: '0001840775,4J WEALTH MANAGEMENT LLC',
    name: '4J WEALTH MANAGEMENT LLC',
    label: '4J WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0001840261',
    value: '0001840261,4THOUGHT FINANCIAL GROUP INC.',
    name: '4THOUGHT FINANCIAL GROUP INC.',
    label: '4THOUGHT FINANCIAL GROUP INC.',
  },
  {
    cik: '0001730242',
    value: '0001730,55I, LLC2',
    name: '55I, LLC',
    label: '55I, LLC',
  },
  {
    cik: '0001805589',
    value: '0001805589,59 NORTH CAPITAL MANAGEMENT, LP',
    name: '59 NORTH CAPITAL MANAGEMENT, LP',
    label: '59 NORTH CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0001799883',
    value: '0001799883,5AM VENTURE MANAGEMENT, LLC',
    name: '5AM VENTURE MANAGEMENT, LLC',
    label: '5AM VENTURE MANAGEMENT, LLC',
  },
  {
    cik: '0001911026',
    value: '0001911026,5TH STREET ADVISORS, LLC',
    name: '5TH STREET ADVISORS, LLC',
    label: '5TH STREET ADVISORS, LLC',
  },
  {
    cik: '0001911035',
    value: '0001911035,626 FINANCIAL, LLC',
    name: '626 FINANCIAL, LLC',
    label: '626 FINANCIAL, LLC',
  },
  {
    cik: '0001404574',
    value: '0001404574,683 CAPITAL MANAGEMENT, LLC',
    name: '683 CAPITAL MANAGEMENT, LLC',
    label: '683 CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001782126',
    value: '0001782126,6ELM CAPITAL LP',
    name: '6ELM CAPITAL LP',
    label: '6ELM CAPITAL LP',
  },
  {
    cik: '0001730551',
    value: '000173055,6 MERIDIAN',
    name: '6 MERIDIAN',
    label: '6 MERIDIAN',
  },
  {
    cik: '0001832749',
    value: '0001832749,72 INVESTMENT HOLDINGS, LLC',
    name: '72 INVESTMENT HOLDINGS, LLC',
    label: '72 INVESTMENT HOLDINGS, LLC',
  },
  {
    cik: '0001591442',
    value: '0001591442,734 AGRICULTURE, LLC',
    name: '734 AGRICULTURE, LLC',
    label: '734 AGRICULTURE, LLC',
  },
  {
    cik: '0001720350',
    value: '0001720350,7G CAPITAL MANAGEMENT, LLC',
    name: '7G CAPITAL MANAGEMENT, LLC',
    label: '7G CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001766506',
    value: '0001766506,9258 WEALTH MANAGEMENT, LLC',
    name: '9258 WEALTH MANAGEMENT, LLC',
    label: '9258 WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001846160',
    value: '0001846160,AAFMAA WEALTH MANAGEMENT & TRUST LLC',
    name: 'AAFMAA WEALTH MANAGEMENT & TRUST LLC',
    label: 'AAFMAA WEALTH MANAGEMENT & TRUST LLC',
  },
  {
    cik: '0001726609',
    value: '0001726609,AAF WEALTH MANAGEMENT, LLC',
    name: 'AAF WEALTH MANAGEMENT, LLC',
    label: 'AAF WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001812198',
    value: '0001812198,AARON WEALTH ADVISORS LLC',
    name: 'AARON WEALTH ADVISORS LLC',
    label: 'AARON WEALTH ADVISORS LLC',
  },
  {
    cik: '0001602730',
    value: '0001602730,ABACUS PLANNING GROUP, INC.',
    name: 'ABACUS PLANNING GROUP, INC.',
    label: 'ABACUS PLANNING GROUP, INC.',
  },
  {
    cik: '0000922437',
    value: '0000922437,ABANCO INVESTORS LTD., LLC',
    name: 'ABANCO INVESTORS LTD., LLC',
    label: 'ABANCO INVESTORS LTD., LLC',
  },
  {
    cik: '0001714341',
    value: '0001714341,ABBOT FINANCIAL MANAGEMENT, INC.',
    name: 'ABBOT FINANCIAL MANAGEMENT, INC.',
    label: 'ABBOT FINANCIAL MANAGEMENT, INC.',
  },
  {
    cik: '0001697646',
    value: '0001697646,ABBREA CAPITAL, LLC',
    name: 'ABBREA CAPITAL, LLC',
    label: 'ABBREA CAPITAL, LLC',
  },
  {
    cik: '0001425040',
    value: '0001425040,AB CARVAL INVESTORS, LP',
    name: 'AB CARVAL INVESTORS, LP',
    label: 'AB CARVAL INVESTORS, LP',
  },
  {
    cik: '0001578684',
    value: '0001578684,ABDIEL CAPITAL ADVISORS, LP',
    name: 'ABDIEL CAPITAL ADVISORS, LP',
    label: 'ABDIEL CAPITAL ADVISORS, LP',
  },
  {
    cik: '0001145937',
    value: '0001145937,ABEILLE ASSET MANAGEMENT SA',
    name: 'ABEILLE ASSET MANAGEMENT SA',
    label: 'ABEILLE ASSET MANAGEMENT SA',
  },
  {
    cik: '0001080434',
    value: '0001080434,ABERDEEN ASSET MANAGEMENT PLC/UK',
    name: 'ABERDEEN ASSET MANAGEMENT PLC/UK',
    label: 'ABERDEEN ASSET MANAGEMENT PLC/UK',
  },
  {
    cik: '0001802654',
    value: '0001802654,ABERDEEN WEALTH MANAGEMENT LLC',
    name: 'ABERDEEN WEALTH MANAGEMENT LLC',
    label: 'ABERDEEN WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0001845297',
    value: '0001845297,ABG INNOVATION CAPITAL PARTNERS III GP LTD',
    name: 'ABG INNOVATION CAPITAL PARTNERS III GP LTD',
    label: 'ABG INNOVATION CAPITAL PARTNERS III GP LTD',
  },
  {
    cik: '0001832108',
    value: '0001832108,ABG-WTT GLOBAL LIFE SCIENCE CAPITAL PARTNERS GP LTD',
    name: 'ABG-WTT GLOBAL LIFE SCIENCE CAPITAL PARTNERS GP LTD',
    label: 'ABG-WTT GLOBAL LIFE SCIENCE CAPITAL PARTNERS GP LTD',
  },
  {
    cik: '0001397144',
    value: '0001397144,ABINGWORTH LLP',
    name: 'ABINGWORTH LLP',
    label: 'ABINGWORTH LLP',
  },
  {
    cik: '0001038661',
    value: '0001038661,ABNER HERRMAN & BROCK LLC',
    name: 'ABNER HERRMAN & BROCK LLC',
    label: 'ABNER HERRMAN & BROCK LLC',
  },
  {
    cik: '0001317588',
    value: '0001317588,ABRAMS BISON INVESTMENTS, LLC',
    name: 'ABRAMS BISON INVESTMENTS, LLC',
    label: 'ABRAMS BISON INVESTMENTS, LLC',
  },
  {
    cik: '0001358706',
    value: '0001358706,ABRAMS CAPITAL MANAGEMENT, L.P.',
    name: 'ABRAMS CAPITAL MANAGEMENT, L.P.',
    label: 'ABRAMS CAPITAL MANAGEMENT, L.P.',
  },
  {
    cik: '0001716774',
    value: '00017167,ABRDN PLC',
    name: 'ABRDN PLC',
    label: 'ABRDN PLC',
  },
  {
    cik: '0001666625',
    value: '0001666625,ABS CAPITAL PARTNERS V TRUST',
    name: 'ABS CAPITAL PARTNERS V TRUST',
    label: 'ABS CAPITAL PARTNERS V TRUST',
  },
  {
    cik: '0001802534',
    value: '0001802534,ABSHER WEALTH MANAGEMENT, LLC',
    name: 'ABSHER WEALTH MANAGEMENT, LLC',
    label: 'ABSHER WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001817648',
    value: '0001817648,ABSOLUTE CAPITAL MANAGEMENT, LLC',
    name: 'ABSOLUTE CAPITAL MANAGEMENT, LLC',
    label: 'ABSOLUTE CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001316187',
    value: '0001316187,ABSOLUTE INVESTMENT ADVISERS LLC',
    name: 'ABSOLUTE INVESTMENT ADVISERS LLC',
    label: 'ABSOLUTE INVESTMENT ADVISERS LLC',
  },
  {
    cik: '0001551727',
    value: '0001551727,ABSOLUTE INVESTMENT MANAGEMENT LLC',
    name: 'ABSOLUTE INVESTMENT MANAGEMENT LLC',
    label: 'ABSOLUTE INVESTMENT MANAGEMENT LLC',
  },
  {
    cik: '0001527824',
    value: '0001527824,ABSOLUTE RETURN CAPITAL, LLC',
    name: 'ABSOLUTE RETURN CAPITAL, LLC',
    label: 'ABSOLUTE RETURN CAPITAL, LLC',
  },
  {
    cik: '0001847566',
    value: '0001847566,ABSOLUTO PARTNERS GESTAO DE RECURSOS LTDA',
    name: 'ABSOLUTO PARTNERS GESTAO DE RECURSOS LTDA',
    label: 'ABSOLUTO PARTNERS GESTAO DE RECURSOS LTDA',
  },
  {
    cik: '0001634776',
    value: '0001634776,ABU DHABI INVESTMENT COUNCIL',
    name: 'ABU DHABI INVESTMENT COUNCIL',
    label: 'ABU DHABI INVESTMENT COUNCIL',
  },
  {
    cik: '0001767080',
    value: '0001767080,ABUNDANCE WEALTH COUNSELORS',
    name: 'ABUNDANCE WEALTH COUNSELORS',
    label: 'ABUNDANCE WEALTH COUNSELORS',
  },
  {
    cik: '0001031972',
    value: '0001031972,ACADEMY CAPITAL MANAGEMENT INC/TX',
    name: 'ACADEMY CAPITAL MANAGEMENT INC/TX',
    label: 'ACADEMY CAPITAL MANAGEMENT INC/TX',
  },
  {
    cik: '0000916542',
    value: '0000916542,ACADIAN ASSET MANAGEMENT LLC',
    name: 'ACADIAN ASSET MANAGEMENT LLC',
    label: 'ACADIAN ASSET MANAGEMENT LLC',
  },
  {
    cik: '0000872078',
    value: '0000872078,ACADIA TRUST NA',
    name: 'ACADIA TRUST NA',
    label: 'ACADIA TRUST NA',
  },
  {
    cik: '0001859505',
    value: '00018595,ACAS, LLC',
    name: 'ACAS, LLC',
    label: 'ACAS, LLC',
  },
  {
    cik: '0001665744',
    value: '0001665744,ACCEL GROWTH FUND IV ASSOCIATES L.L.C.',
    name: 'ACCEL GROWTH FUND IV ASSOCIATES L.L.C.',
    label: 'ACCEL GROWTH FUND IV ASSOCIATES L.L.C.',
  },
  {
    cik: '0001767987',
    value: '0001767987,ACCEL GROWTH FUND V ASSOCIATES L.L.C.',
    name: 'ACCEL GROWTH FUND V ASSOCIATES L.L.C.',
    label: 'ACCEL GROWTH FUND V ASSOCIATES L.L.C.',
  },
  {
    cik: '0001665742',
    value: '0001665742,ACCEL LEADERS FUND ASSOCIATES L.L.C.',
    name: 'ACCEL LEADERS FUND ASSOCIATES L.L.C.',
    label: 'ACCEL LEADERS FUND ASSOCIATES L.L.C.',
  },
  {
    cik: '0001768017',
    value: '0001768017,ACCEL LEADERS FUND II ASSOCIATES L.L.C.',
    name: 'ACCEL LEADERS FUND II ASSOCIATES L.L.C.',
    label: 'ACCEL LEADERS FUND II ASSOCIATES L.L.C.',
  },
  {
    cik: '0001495005',
    value: '0001495005,ACCEL LONDON III ASSOCIATES L.P.',
    name: 'ACCEL LONDON III ASSOCIATES L.P.',
    label: 'ACCEL LONDON III ASSOCIATES L.P.',
  },
  {
    cik: '0001248538',
    value: '0001248538,ACCEL VIII ASSOCIATES LLC',
    name: 'ACCEL VIII ASSOCIATES LLC',
    label: 'ACCEL VIII ASSOCIATES LLC',
  },
  {
    cik: '0001838226',
    value: '0001838226,ACCEL WEALTH MANAGEMENT',
    name: 'ACCEL WEALTH MANAGEMENT',
    label: 'ACCEL WEALTH MANAGEMENT',
  },
  {
    cik: '0001494996',
    value: '0001494996,ACCEL X ASSOCIATES L.L.C.',
    name: 'ACCEL X ASSOCIATES L.L.C.',
    label: 'ACCEL X ASSOCIATES L.L.C.',
  },
  {
    cik: '0001535603',
    value: '0001535603,ACCEL XI ASSOCIATES L.L.C.',
    name: 'ACCEL XI ASSOCIATES L.L.C.',
    label: 'ACCEL XI ASSOCIATES L.L.C.',
  },
  {
    cik: '0001467373',
    value: '0001467373,ACCENTURE PLC',
    name: 'ACCENTURE PLC',
    label: 'ACCENTURE PLC',
  },
  {
    cik: '0001699622',
    value: '0001699622,ACCESS FINANCIAL SERVICES, INC.',
    name: 'ACCESS FINANCIAL SERVICES, INC.',
    label: 'ACCESS FINANCIAL SERVICES, INC.',
  },
  {
    cik: '0001618832',
    value: '0001618832,ACCIDENT COMPENSATION CORP',
    name: 'ACCIDENT COMPENSATION CORP',
    label: 'ACCIDENT COMPENSATION CORP',
  },
  {
    cik: '0001280190',
    value: '0001280190,ACCIPITER CAPITAL MANAGEMENT, LLC',
    name: 'ACCIPITER CAPITAL MANAGEMENT, LLC',
    label: 'ACCIPITER CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001898735',
    value: '0001898735,ACCOMPLICE ASSOCIATES II, LLC',
    name: 'ACCOMPLICE ASSOCIATES II, LLC',
    label: 'ACCOMPLICE ASSOCIATES II, LLC',
  },
  {
    cik: '0001941537',
    value: '0001941537,ACCOMPLICE MANAGEMENT, LLC',
    name: 'ACCOMPLICE MANAGEMENT, LLC',
    label: 'ACCOMPLICE MANAGEMENT, LLC',
  },
  {
    cik: '0000769954',
    value: '0000769954,ACCOUNT MANAGEMENT LLC',
    name: 'ACCOUNT MANAGEMENT LLC',
    label: 'ACCOUNT MANAGEMENT LLC',
  },
  {
    cik: '0001518364',
    value: '0001518364,ACCREDITED INVESTORS INC.',
    name: 'ACCREDITED INVESTORS INC.',
    label: 'ACCREDITED INVESTORS INC.',
  },
  {
    cik: '0001844444',
    value: '0001844444,ACCRETIVE WEALTH PARTNERS, LLC',
    name: 'ACCRETIVE WEALTH PARTNERS, LLC',
    label: 'ACCRETIVE WEALTH PARTNERS, LLC',
  },
  {
    cik: '0001607863',
    value: '0001607863,ACCURATE INVESTMENT SOLUTIONS, INC.',
    name: 'ACCURATE INVESTMENT SOLUTIONS, INC.',
    label: 'ACCURATE INVESTMENT SOLUTIONS, INC.',
  },
  {
    cik: '0001877728',
    value: '0001877728,ACCURATE WEALTH MANAGEMENT, LLC',
    name: 'ACCURATE WEALTH MANAGEMENT, LLC',
    label: 'ACCURATE WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001497637',
    value: '0001497637,ACCUVEST GLOBAL ADVISORS',
    name: 'ACCUVEST GLOBAL ADVISORS',
    label: 'ACCUVEST GLOBAL ADVISORS',
  },
  {
    cik: '0001542662',
    value: '0001542662,ACERTUS CAPITAL MANAGEMENT, LLC',
    name: 'ACERTUS CAPITAL MANAGEMENT, LLC',
    label: 'ACERTUS CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001569071',
    value: '000156907,ACG WEALTH',
    name: 'ACG WEALTH',
    label: 'ACG WEALTH',
  },
  {
    cik: '0001626140',
    value: '0001626140,ACHIEVEMENT HOLDINGS L.P.',
    name: 'ACHIEVEMENT HOLDINGS L.P.',
    label: 'ACHIEVEMENT HOLDINGS L.P.',
  },
  {
    cik: '0001697765',
    value: '0001697765,ACHMEA INVESTMENT MANAGEMENT B.V.',
    name: 'ACHMEA INVESTMENT MANAGEMENT B.V.',
    label: 'ACHMEA INVESTMENT MANAGEMENT B.V.',
  },
  {
    cik: '0001761961',
    value: '0001761961,ACIMA PRIVATE WEALTH, LLC',
    name: 'ACIMA PRIVATE WEALTH, LLC',
    label: 'ACIMA PRIVATE WEALTH, LLC',
  },
  {
    cik: '0001510940',
    value: '0001510940,ACK ASSET MANAGEMENT LLC',
    name: 'ACK ASSET MANAGEMENT LLC',
    label: 'ACK ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001699506',
    value: '0001699506,ACKERMAN CAPITAL ADVISORS, LLC',
    name: 'ACKERMAN CAPITAL ADVISORS, LLC',
    label: 'ACKERMAN CAPITAL ADVISORS, LLC',
  },
  {
    cik: '0001803200',
    value: '00018032,ACME, LLC',
    name: 'ACME, LLC',
    label: 'ACME, LLC',
  },
  {
    cik: '0001731031',
    value: '0001731031,ACON EQUITY MANAGEMENT, L.L.C.',
    name: 'ACON EQUITY MANAGEMENT, L.L.C.',
    label: 'ACON EQUITY MANAGEMENT, L.L.C.',
  },
  {
    cik: '0001730745',
    value: '0001730745,ACORN ADVISORY CAPITAL L.P.',
    name: 'ACORN ADVISORY CAPITAL L.P.',
    label: 'ACORN ADVISORY CAPITAL L.P.',
  },
  {
    cik: '0001018561',
    value: '0001018561,ACORN FINANCIAL ADVISORY SERVICES INC /ADV',
    name: 'ACORN FINANCIAL ADVISORY SERVICES INC /ADV',
    label: 'ACORN FINANCIAL ADVISORY SERVICES INC /ADV',
  },
  {
    cik: '0001701879',
    value: '0001701879,ACORNS ADVISERS, LLC',
    name: 'ACORNS ADVISERS, LLC',
    label: 'ACORNS ADVISERS, LLC',
  },
  {
    cik: '0001578860',
    value: '0001578860,ACORNS SECURITIES, LLC',
    name: 'ACORNS SECURITIES, LLC',
    label: 'ACORNS SECURITIES, LLC',
  },
  {
    cik: '0001730610',
    value: '0001730610,ACORN WEALTH ADVISORS, LLC',
    name: 'ACORN WEALTH ADVISORS, LLC',
    label: 'ACORN WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001508822',
    value: '0001508822,ACR ALPINE CAPITAL RESEARCH, LLC',
    name: 'ACR ALPINE CAPITAL RESEARCH, LLC',
    label: 'ACR ALPINE CAPITAL RESEARCH, LLC',
  },
  {
    cik: '0001318601',
    value: '0001318601,ACROPOLIS INVESTMENT MANAGEMENT, LLC',
    name: 'ACROPOLIS INVESTMENT MANAGEMENT, LLC',
    label: 'ACROPOLIS INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001632612',
    value: '0001632612,ACROSPIRE INVESTMENT MANAGEMENT LLC',
    name: 'ACROSPIRE INVESTMENT MANAGEMENT LLC',
    label: 'ACROSPIRE INVESTMENT MANAGEMENT LLC',
  },
  {
    cik: '0001842554',
    value: '0001842554,ACT ADVISORS, LLC.',
    name: 'ACT ADVISORS, LLC.',
    label: 'ACT ADVISORS, LLC.',
  },
  {
    cik: '0001442273',
    value: '0001442273,ACT CAPITAL MANAGEMENT, LLC',
    name: 'ACT CAPITAL MANAGEMENT, LLC',
    label: 'ACT CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001619124',
    value: '0001619124,ACTIAM N.V.',
    name: 'ACTIAM N.V.',
    label: 'ACTIAM N.V.',
  },
  {
    cik: '0001450008',
    value: '0001450008,ACT II MANAGEMENT, LP',
    name: 'ACT II MANAGEMENT, LP',
    label: 'ACT II MANAGEMENT, LP',
  },
  {
    cik: '0001767699',
    value: '0001767699,ACTINVER WEALTH MANAGEMENT, INC.',
    name: 'ACTINVER WEALTH MANAGEMENT, INC.',
    label: 'ACTINVER WEALTH MANAGEMENT, INC.',
  },
  {
    cik: '0001883134',
    value: '0001883134,ACTIVEST WEALTH MANAGEMENT',
    name: 'ACTIVEST WEALTH MANAGEMENT',
    label: 'ACTIVEST WEALTH MANAGEMENT',
  },
  {
    cik: '0001766907',
    value: '0001766907,ACT TWO INVESTORS LLC',
    name: 'ACT TWO INVESTORS LLC',
    label: 'ACT TWO INVESTORS LLC',
  },
  {
    cik: '0001814191',
    value: '0001814191,ACT WEALTH MANAGEMENT, LLC',
    name: 'ACT WEALTH MANAGEMENT, LLC',
    label: 'ACT WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001524828',
    value: '0001524828,ACUITAS INVESTMENTS, LLC',
    name: 'ACUITAS INVESTMENTS, LLC',
    label: 'ACUITAS INVESTMENTS, LLC',
  },
  {
    cik: '0001424111',
    value: '0001424111,ACUITY INVESTMENT MANAGEMENT INC',
    name: 'ACUITY INVESTMENT MANAGEMENT INC',
    label: 'ACUITY INVESTMENT MANAGEMENT INC',
  },
  {
    cik: '0001582844',
    value: '0001582844,ACUTA CAPITAL PARTNERS, LLC',
    name: 'ACUTA CAPITAL PARTNERS, LLC',
    label: 'ACUTA CAPITAL PARTNERS, LLC',
  },
  {
    cik: '0001944780',
    value: '0001944780,ACUTE INVESTMENT ADVISORY, LLC',
    name: 'ACUTE INVESTMENT ADVISORY, LLC',
    label: 'ACUTE INVESTMENT ADVISORY, LLC',
  },
  {
    cik: '0001165408',
    value: '0001165408,ADAGE CAPITAL PARTNERS GP LLC',
    name: 'ADAGE CAPITAL PARTNERS GP LLC',
    label: 'ADAGE CAPITAL PARTNERS GP LLC',
  },
  {
    cik: '0001165408',
    value: '0001165408,ADAGE CAPITAL PARTNERS GP, L.L.C.',
    name: 'ADAGE CAPITAL PARTNERS GP, L.L.C.',
    label: 'ADAGE CAPITAL PARTNERS GP, L.L.C.',
  },
  {
    cik: '0001631190',
    value: '0001631190,ADA INVESTMENT MANAGEMENT, LP',
    name: 'ADA INVESTMENT MANAGEMENT, LP',
    label: 'ADA INVESTMENT MANAGEMENT, LP',
  },
  {
    cik: '0001698926',
    value: '0001698926,ADALTA CAPITAL MANAGEMENT LLC',
    name: 'ADALTA CAPITAL MANAGEMENT LLC',
    label: 'ADALTA CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001542336',
    value: '0001542336,ADAMANT BIOMEDICAL INVESTMENTS AG',
    name: 'ADAMANT BIOMEDICAL INVESTMENTS AG',
    label: 'ADAMANT BIOMEDICAL INVESTMENTS AG',
  },
  {
    cik: '0001732080',
    value: '0001732080,ADAMCAPITAL GESTAO DE RECURSOS LTDA.',
    name: 'ADAMCAPITAL GESTAO DE RECURSOS LTDA.',
    label: 'ADAMCAPITAL GESTAO DE RECURSOS LTDA.',
  },
  {
    cik: '0001386929',
    value: '0001386929,ADAMS ASSET ADVISORS, LLC',
    name: 'ADAMS ASSET ADVISORS, LLC',
    label: 'ADAMS ASSET ADVISORS, LLC',
  },
  {
    cik: '0001911244',
    value: '0001911244,ADAMSBROWN WEALTH CONSULTANTS LLC',
    name: 'ADAMSBROWN WEALTH CONSULTANTS LLC',
    label: 'ADAMSBROWN WEALTH CONSULTANTS LLC',
  },
  {
    cik: '0001799752',
    value: '0001799752,ADAMS CHETWOOD WEALTH MANAGEMENT LLC',
    name: 'ADAMS CHETWOOD WEALTH MANAGEMENT LLC',
    label: 'ADAMS CHETWOOD WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0000002230',
    value: '0000002230,ADAMS DIVERSIFIED EQUITY FUND, INC.',
    name: 'ADAMS DIVERSIFIED EQUITY FUND, INC.',
    label: 'ADAMS DIVERSIFIED EQUITY FUND, INC.',
  },
  {
    cik: '0001570394',
    value: '0001570394,ADAMS HILL CAPITAL LLC',
    name: 'ADAMS HILL CAPITAL LLC',
    label: 'ADAMS HILL CAPITAL LLC',
  },
  {
    cik: '0000216851',
    value: '0000216851,ADAMS NATURAL RESOURCES FUND, INC.',
    name: 'ADAMS NATURAL RESOURCES FUND, INC.',
    label: 'ADAMS NATURAL RESOURCES FUND, INC.',
  },
  {
    cik: '0001193586',
    value: '0001193586,ADAMS STREET PARTNERS LLC',
    name: 'ADAMS STREET PARTNERS LLC',
    label: 'ADAMS STREET PARTNERS LLC',
  },
  {
    cik: '0001803084',
    value: '0001803084,ADAMS WEALTH MANAGEMENT',
    name: 'ADAMS WEALTH MANAGEMENT',
    label: 'ADAMS WEALTH MANAGEMENT',
  },
  {
    cik: '0001804717',
    value: '0001804717,ADAPTIVE FINANCIAL CONSULTING, LLC',
    name: 'ADAPTIVE FINANCIAL CONSULTING, LLC',
    label: 'ADAPTIVE FINANCIAL CONSULTING, LLC',
  },
  {
    cik: '0001317613',
    value: '0001317613,ADAR INVESTMENT MANAGEMENT LLC',
    name: 'ADAR INVESTMENT MANAGEMENT LLC',
    label: 'ADAR INVESTMENT MANAGEMENT LLC',
  },
  {
    cik: '0001569102',
    value: '0001569102,A. D. BEADELL INVESTMENT COUNSEL, INC.',
    name: 'A. D. BEADELL INVESTMENT COUNSEL, INC.',
    label: 'A. D. BEADELL INVESTMENT COUNSEL, INC.',
  },
  {
    cik: '0001569411',
    value: '0001569411,ADDENDA CAPITAL INC.',
    name: 'ADDENDA CAPITAL INC.',
    label: 'ADDENDA CAPITAL INC.',
  },
  {
    cik: '0001843745',
    value: '0001843745,ADDISON ADVISORS LLC',
    name: 'ADDISON ADVISORS LLC',
    label: 'ADDISON ADVISORS LLC',
  },
  {
    cik: '0001581465',
    value: '0001581465,ADDISON CAPITAL CO',
    name: 'ADDISON CAPITAL CO',
    label: 'ADDISON CAPITAL CO',
  },
  {
    cik: '0001213647',
    value: '0001213647,ADDISON CLARK MANAGEMENT, L.L.C.',
    name: 'ADDISON CLARK MANAGEMENT, L.L.C.',
    label: 'ADDISON CLARK MANAGEMENT, L.L.C.',
  },
  {
    cik: '0001812881',
    value: '0001812881,ADDITION ONE GENERAL PARTNER, L.P.',
    name: 'ADDITION ONE GENERAL PARTNER, L.P.',
    label: 'ADDITION ONE GENERAL PARTNER, L.P.',
  },
  {
    cik: '0001884057',
    value: '0001884057,ADDITION THREE GENERAL PARTNER, L.P.',
    name: 'ADDITION THREE GENERAL PARTNER, L.P.',
    label: 'ADDITION THREE GENERAL PARTNER, L.P.',
  },
  {
    cik: '0001067926',
    value: '0001067926,ADELANTE CAPITAL MANAGEMENT LLC',
    name: 'ADELANTE CAPITAL MANAGEMENT LLC',
    label: 'ADELANTE CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001845950',
    value: '0001845,ADE, LLC0',
    name: 'ADE, LLC',
    label: 'ADE, LLC',
  },
  {
    cik: '0001134152',
    value: '0001134152,ADELL HARRIMAN & CARPENTER INC',
    name: 'ADELL HARRIMAN & CARPENTER INC',
    label: 'ADELL HARRIMAN & CARPENTER INC',
  },
  {
    cik: '0001353411',
    value: '0001353411,ADELPHI CAPITAL LLP',
    name: 'ADELPHI CAPITAL LLP',
    label: 'ADELPHI CAPITAL LLP',
  },
  {
    cik: '0001595961',
    value: '0001595961,ADI CAPITAL MANAGEMENT LLC',
    name: 'ADI CAPITAL MANAGEMENT LLC',
    label: 'ADI CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001311981',
    value: '0001311981,ADIRONDACK FUNDS',
    name: 'ADIRONDACK FUNDS',
    label: 'ADIRONDACK FUNDS',
  },
  {
    cik: '0001569100',
    value: '0001569100,ADIRONDACK RESEARCH & MANAGEMENT INC.',
    name: 'ADIRONDACK RESEARCH & MANAGEMENT INC.',
    label: 'ADIRONDACK RESEARCH & MANAGEMENT INC.',
  },
  {
    cik: '0001841016',
    value: '0001841016,ADIRONDACK RETIREMENT SPECIALISTS, INC.',
    name: 'ADIRONDACK RETIREMENT SPECIALISTS, INC.',
    label: 'ADIRONDACK RETIREMENT SPECIALISTS, INC.',
  },
  {
    cik: '0001054257',
    value: '0001054257,ADIRONDACK TRUST CO',
    name: 'ADIRONDACK TRUST CO',
    label: 'ADIRONDACK TRUST CO',
  },
  {
    cik: '0000813470',
    value: '0000813470,ADVANCE CAPITAL I INC',
    name: 'ADVANCE CAPITAL I INC',
    label: 'ADVANCE CAPITAL I INC',
  },
  {
    cik: '0001536029',
    value: '0001536029,ADVANCE CAPITAL MANAGEMENT, INC.',
    name: 'ADVANCE CAPITAL MANAGEMENT, INC.',
    label: 'ADVANCE CAPITAL MANAGEMENT, INC.',
  },
  {
    cik: '0001075444',
    value: '0001075444,ADVANCED ASSET MANAGEMENT ADVISORS INC',
    name: 'ADVANCED ASSET MANAGEMENT ADVISORS INC',
    label: 'ADVANCED ASSET MANAGEMENT ADVISORS INC',
  },
  {
    cik: '0001802867',
    value: '0001802867,ADVANCED RESEARCH INVESTMENT SOLUTIONS, LLC',
    name: 'ADVANCED RESEARCH INVESTMENT SOLUTIONS, LLC',
    label: 'ADVANCED RESEARCH INVESTMENT SOLUTIONS, LLC',
  },
  {
    cik: '0001652132',
    value: '0001652132,ADVANCE EMERGING CAPITAL LTD',
    name: 'ADVANCE EMERGING CAPITAL LTD',
    label: 'ADVANCE EMERGING CAPITAL LTD',
  },
  {
    cik: '0001762344',
    value: '0001762344,ADVANTAGE ALPHA CAPITAL PARTNERS LP',
    name: 'ADVANTAGE ALPHA CAPITAL PARTNERS LP',
    label: 'ADVANTAGE ALPHA CAPITAL PARTNERS LP',
  },
  {
    cik: '0001697815',
    value: '0001697815,ADVANTAGE INVESTMENT MANAGEMENT, LLC',
    name: 'ADVANTAGE INVESTMENT MANAGEMENT, LLC',
    label: 'ADVANTAGE INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001035350',
    value: '0001035350,ADVANTUS CAPITAL MANAGEMENT INC',
    name: 'ADVANTUS CAPITAL MANAGEMENT INC',
    label: 'ADVANTUS CAPITAL MANAGEMENT INC',
  },
  {
    cik: '0001097278',
    value: '0001097278,ADVENT CAPITAL MANAGEMENT /DE/',
    name: 'ADVENT CAPITAL MANAGEMENT /DE/',
    label: 'ADVENT CAPITAL MANAGEMENT /DE/',
  },
  {
    cik: '0001034196',
    value: '0001034196,ADVENT INTERNATIONAL CORP/MA',
    name: 'ADVENT INTERNATIONAL CORP/MA',
    label: 'ADVENT INTERNATIONAL CORP/MA',
  },
  {
    cik: '0001103139',
    value: '0001103139,ADVENTIST HEALTH SYSTEM SUNBELT HEALTHCARE CORP',
    name: 'ADVENTIST HEALTH SYSTEM SUNBELT HEALTHCARE CORP',
    label: 'ADVENTIST HEALTH SYSTEM SUNBELT HEALTHCARE CORP',
  },
  {
    cik: '0001802516',
    value: '0001802516,ADVENTIST HEALTH SYSTEM/ WEST',
    name: 'ADVENTIST HEALTH SYSTEM/ WEST',
    label: 'ADVENTIST HEALTH SYSTEM/ WEST',
  },
  {
    cik: '0001634228',
    value: '0001634228,ADVICEPERIOD, LLC',
    name: 'ADVICEPERIOD, LLC',
    label: 'ADVICEPERIOD, LLC',
  },
  {
    cik: '0001544576',
    value: '0001544576,ADVISER INVESTMENTS LLC',
    name: 'ADVISER INVESTMENTS LLC',
    label: 'ADVISER INVESTMENTS LLC',
  },
  {
    cik: '0001085146',
    value: '0001085146,ADVISOR CONSULTANT NETWORK INC',
    name: 'ADVISOR CONSULTANT NETWORK INC',
    label: 'ADVISOR CONSULTANT NETWORK INC',
  },
  {
    cik: '0001677044',
    value: '0001677044,ADVISOR GROUP HOLDINGS, INC.',
    name: 'ADVISOR GROUP HOLDINGS, INC.',
    label: 'ADVISOR GROUP HOLDINGS, INC.',
  },
  {
    cik: '0001681115',
    value: '0001681115,ADVISOR GROUP, INC.',
    name: 'ADVISOR GROUP, INC.',
    label: 'ADVISOR GROUP, INC.',
  },
  {
    cik: '0001409362',
    value: '0001409362,ADVISORNET FINANCIAL, INC',
    name: 'ADVISORNET FINANCIAL, INC',
    label: 'ADVISORNET FINANCIAL, INC',
  },
  {
    cik: '0001767580',
    value: '0001767580,ADVISOR OS, LLC',
    name: 'ADVISOR OS, LLC',
    label: 'ADVISOR OS, LLC',
  },
  {
    cik: '0001954015',
    value: '0001954015,ADVISOR PARTNERS II, LLC',
    name: 'ADVISOR PARTNERS II, LLC',
    label: 'ADVISOR PARTNERS II, LLC',
  },
  {
    cik: '0001456114',
    value: '0001456114,ADVISOR PARTNERS LLC',
    name: 'ADVISOR PARTNERS LLC',
    label: 'ADVISOR PARTNERS LLC',
  },
  {
    cik: '0001820879',
    value: '0001820879,ADVISOR RESOURCE COUNCIL',
    name: 'ADVISOR RESOURCE COUNCIL',
    label: 'ADVISOR RESOURCE COUNCIL',
  },
  {
    cik: '0001297376',
    value: '0001297376,ADVISORS ASSET MANAGEMENT, INC.',
    name: 'ADVISORS ASSET MANAGEMENT, INC.',
    label: 'ADVISORS ASSET MANAGEMENT, INC.',
  },
  {
    cik: '0001345576',
    value: '0001345576,ADVISORS CAPITAL MANAGEMENT, LLC',
    name: 'ADVISORS CAPITAL MANAGEMENT, LLC',
    label: 'ADVISORS CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001425930',
    value: '0001425930,ADVISORSHARES INVESTMENTS LLC',
    name: 'ADVISORSHARES INVESTMENTS LLC',
    label: 'ADVISORSHARES INVESTMENTS LLC',
  },
  {
    cik: '0000842766',
    value: '0000842766,ADVISORS MANAGEMENT GROUP INC /ADV',
    name: 'ADVISORS MANAGEMENT GROUP INC /ADV',
    label: 'ADVISORS MANAGEMENT GROUP INC /ADV',
  },
  {
    cik: '0001598841',
    value: '0001598841,ADVISORS PREFERRED, LLC',
    name: 'ADVISORS PREFERRED, LLC',
    label: 'ADVISORS PREFERRED, LLC',
  },
  {
    cik: '0001576762',
    value: '0001576762,ADVISORY ALPHA, LLC',
    name: 'ADVISORY ALPHA, LLC',
    label: 'ADVISORY ALPHA, LLC',
  },
  {
    cik: '0000902584',
    value: '0000902584,ADVISORY RESEARCH INC',
    name: 'ADVISORY RESEARCH INC',
    label: 'ADVISORY RESEARCH INC',
  },
  {
    cik: '0001764968',
    value: '0001764968,ADVISORY RESOURCE GROUP',
    name: 'ADVISORY RESOURCE GROUP',
    label: 'ADVISORY RESOURCE GROUP',
  },
  {
    cik: '0001862931',
    value: '0001862931,ADVISORY SERVICES & INVESTMENTS, LLC',
    name: 'ADVISORY SERVICES & INVESTMENTS, LLC',
    label: 'ADVISORY SERVICES & INVESTMENTS, LLC',
  },
  {
    cik: '0001573876',
    value: '0001573876,ADVISORY SERVICES NETWORK, LLC',
    name: 'ADVISORY SERVICES NETWORK, LLC',
    label: 'ADVISORY SERVICES NETWORK, LLC',
  },
  {
    cik: '0001569452',
    value: '0001569452,ADVOCACY WEALTH MANAGEMENT SERVICES, LLC',
    name: 'ADVOCACY WEALTH MANAGEMENT SERVICES, LLC',
    label: 'ADVOCACY WEALTH MANAGEMENT SERVICES, LLC',
  },
  {
    cik: '0001853401',
    value: '0001853401,ADVOCATE GROUP LLC',
    name: 'ADVOCATE GROUP LLC',
    label: 'ADVOCATE GROUP LLC',
  },
  {
    cik: '0001745214',
    value: '0001745214,ADW CAPITAL MANAGEMENT, LLC',
    name: 'ADW CAPITAL MANAGEMENT, LLC',
    label: 'ADW CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001600153',
    value: '0001600153,AEGEA CAPITAL MANAGEMENT LLC',
    name: 'AEGEA CAPITAL MANAGEMENT LLC',
    label: 'AEGEA CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001140753',
    value: '0001140753,AEGIS FINANCIAL CORP',
    name: 'AEGIS FINANCIAL CORP',
    label: 'AEGIS FINANCIAL CORP',
  },
  {
    cik: '0001539994',
    value: '0001539994,AEGON ASSET MANAGEMENT UK PLC',
    name: 'AEGON ASSET MANAGEMENT UK PLC',
    label: 'AEGON ASSET MANAGEMENT UK PLC',
  },
  {
    cik: '0001454937',
    value: '0001454937,AEGON USA INVESTMENT MANAGEMENT, LLC',
    name: 'AEGON USA INVESTMENT MANAGEMENT, LLC',
    label: 'AEGON USA INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001728201',
    value: '0001728201,AEQUIM ALTERNATIVE INVESTMENTS LP',
    name: 'AEQUIM ALTERNATIVE INVESTMENTS LP',
    label: 'AEQUIM ALTERNATIVE INVESTMENTS LP',
  },
  {
    cik: '0001634083',
    value: '0001634083,AERIS CAPITAL HOLDING GMBH',
    name: 'AERIS CAPITAL HOLDING GMBH',
    label: 'AERIS CAPITAL HOLDING GMBH',
  },
  {
    cik: '0001122304',
    value: '0001122304,AETNA INC /PA/',
    name: 'AETNA INC /PA/',
    label: 'AETNA INC /PA/',
  },
  {
    cik: '0001722117',
    value: '0001722117,AEVITAS WEALTH MANAGEMENT, INC.',
    name: 'AEVITAS WEALTH MANAGEMENT, INC.',
    label: 'AEVITAS WEALTH MANAGEMENT, INC.',
  },
  {
    cik: '0001046187',
    value: '0001046187,AEW CAPITAL MANAGEMENT L P',
    name: 'AEW CAPITAL MANAGEMENT L P',
    label: 'AEW CAPITAL MANAGEMENT L P',
  },
  {
    cik: '0001697723',
    value: '0001697723,AE WEALTH MANAGEMENT LLC',
    name: 'AE WEALTH MANAGEMENT LLC',
    label: 'AE WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0001818044',
    value: '0001818044,AF ADVISORS, INC.',
    name: 'AF ADVISORS, INC.',
    label: 'AF ADVISORS, INC.',
  },
  {
    cik: '0001167495',
    value: '0001167495,AFAM CAPITAL, INC.',
    name: 'AFAM CAPITAL, INC.',
    label: 'AFAM CAPITAL, INC.',
  },
  {
    cik: '0001723681',
    value: '0001723681,AFFIANCE FINANCIAL, LLC',
    name: 'AFFIANCE FINANCIAL, LLC',
    label: 'AFFIANCE FINANCIAL, LLC',
  },
  {
    cik: '0001802862',
    value: '0001802862,AFFINIA FINANCIAL GROUP, LLC',
    name: 'AFFINIA FINANCIAL GROUP, LLC',
    label: 'AFFINIA FINANCIAL GROUP, LLC',
  },
  {
    cik: '0001773195',
    value: '0001773195,AFFINITY ASSET ADVISORS, LLC',
    name: 'AFFINITY ASSET ADVISORS, LLC',
    label: 'AFFINITY ASSET ADVISORS, LLC',
  },
  {
    cik: '0001810023',
    value: '0001810023,AFFINITY CAPITAL ADVISORS, LLC',
    name: 'AFFINITY CAPITAL ADVISORS, LLC',
    label: 'AFFINITY CAPITAL ADVISORS, LLC',
  },
  {
    cik: '0001520601',
    value: '0001520601,AFFINITY INVESTMENT ADVISORS, LLC',
    name: 'AFFINITY INVESTMENT ADVISORS, LLC',
    label: 'AFFINITY INVESTMENT ADVISORS, LLC',
  },
  {
    cik: '0000810672',
    value: '0000810672,AFFINITY WEALTH MANAGEMENT',
    name: 'AFFINITY WEALTH MANAGEMENT',
    label: 'AFFINITY WEALTH MANAGEMENT',
  },
  {
    cik: '0000810672',
    value: '0000810672,AFFINITY WEALTH MANAGEMENT LLC',
    name: 'AFFINITY WEALTH MANAGEMENT LLC',
    label: 'AFFINITY WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0001907820',
    value: '0001907820,AFG FIDUCIARY SERVICES LIMITED PARTNERSHIP',
    name: 'AFG FIDUCIARY SERVICES LIMITED PARTNERSHIP',
    label: 'AFG FIDUCIARY SERVICES LIMITED PARTNERSHIP',
  },
  {
    cik: '0001882466',
    value: '0001882466,AFLAC ASSET MANAGEMENT LLC',
    name: 'AFLAC ASSET MANAGEMENT LLC',
    label: 'AFLAC ASSET MANAGEMENT LLC',
  },
  {
    cik: '0000004977',
    value: '00000049,AFLAC INC',
    name: 'AFLAC INC',
    label: 'AFLAC INC',
  },
  {
    cik: '0001879345',
    value: '0001879345,AFS FINANCIAL GROUP, LLC',
    name: 'AFS FINANCIAL GROUP, LLC',
    label: 'AFS FINANCIAL GROUP, LLC',
  },
  {
    cik: '0001512073',
    value: '0001512073,AFT, FORSYTH & COMPANY, INC.',
    name: 'AFT, FORSYTH & COMPANY, INC.',
    label: 'AFT, FORSYTH & COMPANY, INC.',
  },
  {
    cik: '0001566801',
    value: '0001566801,AFT, FORSYTH & SOBER, LLC',
    name: 'AFT, FORSYTH & SOBER, LLC',
    label: 'AFT, FORSYTH & SOBER, LLC',
  },
  {
    cik: '0001569656',
    value: '0001569656,AFT, FORSYTH & SOBER, LLC',
    name: 'AFT, FORSYTH & SOBER, LLC',
    label: 'AFT, FORSYTH & SOBER, LLC',
  },
  {
    cik: '0001232960',
    value: '0001232960,AFTON CAPITAL MANAGEMENT LLC',
    name: 'AFTON CAPITAL MANAGEMENT LLC',
    label: 'AFTON CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001900409',
    value: '0001900409,AGATE PASS INVESTMENT MANAGEMENT, LLC',
    name: 'AGATE PASS INVESTMENT MANAGEMENT, LLC',
    label: 'AGATE PASS INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001591590',
    value: '0001591590,AGF INVESTMENTS AMERICA INC.',
    name: 'AGF INVESTMENTS AMERICA INC.',
    label: 'AGF INVESTMENTS AMERICA INC.',
  },
  {
    cik: '0001157765',
    value: '0001157765,AGF INVESTMENTS INC.',
    name: 'AGF INVESTMENTS INC.',
    label: 'AGF INVESTMENTS INC.',
  },
  {
    cik: '0001479598',
    value: '0001479598,AGF INVESTMENTS LLC',
    name: 'AGF INVESTMENTS LLC',
    label: 'AGF INVESTMENTS LLC',
  },
  {
    cik: '0001633903',
    value: '0001633903,AGILIS PARTNERS LLC',
    name: 'AGILIS PARTNERS LLC',
    label: 'AGILIS PARTNERS LLC',
  },
  {
    cik: '0001845254',
    value: '0001845254,AGINCOURT CAPITAL MANAGEMENT, LLC',
    name: 'AGINCOURT CAPITAL MANAGEMENT, LLC',
    label: 'AGINCOURT CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001105871',
    value: '0001105871,AGRAN LIBBIE',
    name: 'AGRAN LIBBIE',
    label: 'AGRAN LIBBIE',
  },
  {
    cik: '0001419730',
    value: '0001419730,AGTC PARTNERS, L.P.',
    name: 'AGTC PARTNERS, L.P.',
    label: 'AGTC PARTNERS, L.P.',
  },
  {
    cik: '0001670627',
    value: '0001670627,AGUR PROVIDENT & TRAINING FUNDS MANAGEMENT LTD',
    name: 'AGUR PROVIDENT & TRAINING FUNDS MANAGEMENT LTD',
    label: 'AGUR PROVIDENT & TRAINING FUNDS MANAGEMENT LTD',
  },
  {
    cik: '0001799478',
    value: '0001799478,AH EQUITY PARTNERS BIO III, L.L.C.',
    name: 'AH EQUITY PARTNERS BIO III, L.L.C.',
    label: 'AH EQUITY PARTNERS BIO III, L.L.C.',
  },
  {
    cik: '0001725722',
    value: '0001725722,AH EQUITY PARTNERS BIO II, L.L.C.',
    name: 'AH EQUITY PARTNERS BIO II, L.L.C.',
    label: 'AH EQUITY PARTNERS BIO II, L.L.C.',
  },
  {
    cik: '0001540361',
    value: '0001540361,AH EQUITY PARTNERS III, L.L.C.',
    name: 'AH EQUITY PARTNERS III, L.L.C.',
    label: 'AH EQUITY PARTNERS III, L.L.C.',
  },
  {
    cik: '0001540359',
    value: '0001540359,AH EQUITY PARTNERS III (PARALLEL), L.L.C.',
    name: 'AH EQUITY PARTNERS III (PARALLEL), L.L.C.',
    label: 'AH EQUITY PARTNERS III (PARALLEL), L.L.C.',
  },
  {
    cik: '0001541568',
    value: '0001541568,AH EQUITY PARTNERS II, L.L.C.',
    name: 'AH EQUITY PARTNERS II, L.L.C.',
    label: 'AH EQUITY PARTNERS II, L.L.C.',
  },
  {
    cik: '0001466219',
    value: '0001466219,AH EQUITY PARTNERS I, L.L.C.',
    name: 'AH EQUITY PARTNERS I, L.L.C.',
    label: 'AH EQUITY PARTNERS I, L.L.C.',
  },
  {
    cik: '0001603403',
    value: '0001603403,AH EQUITY PARTNERS IV, L.L.C.',
    name: 'AH EQUITY PARTNERS IV, L.L.C.',
    label: 'AH EQUITY PARTNERS IV, L.L.C.',
  },
  {
    cik: '0001603419',
    value: '0001603419,AH EQUITY PARTNERS IV (PARALLEL), L.L.C.',
    name: 'AH EQUITY PARTNERS IV (PARALLEL), L.L.C.',
    label: 'AH EQUITY PARTNERS IV (PARALLEL), L.L.C.',
  },
  {
    cik: '0001889893',
    value: '0001889893,AH EQUITY PARTNERS LSV III, L.L.C.',
    name: 'AH EQUITY PARTNERS LSV III, L.L.C.',
    label: 'AH EQUITY PARTNERS LSV III, L.L.C.',
  },
  {
    cik: '0001829384',
    value: '0001829384,AH EQUITY PARTNERS LSV II, L.L.C.',
    name: 'AH EQUITY PARTNERS LSV II, L.L.C.',
    label: 'AH EQUITY PARTNERS LSV II, L.L.C.',
  },
  {
    cik: '0001772287',
    value: '0001772287,AH EQUITY PARTNERS LSV I, L.L.C.',
    name: 'AH EQUITY PARTNERS LSV I, L.L.C.',
    label: 'AH EQUITY PARTNERS LSV I, L.L.C.',
  },
  {
    cik: '0001675226',
    value: '0001675226,AH EQUITY PARTNERS V, L.L.C.',
    name: 'AH EQUITY PARTNERS V, L.L.C.',
    label: 'AH EQUITY PARTNERS V, L.L.C.',
  },
  {
    cik: '0001908425',
    value: '0001908425,AHL INVESTMENT MANAGEMENT, INC.',
    name: 'AHL INVESTMENT MANAGEMENT, INC.',
    label: 'AHL INVESTMENT MANAGEMENT, INC.',
  },
  {
    cik: '0001424467',
    value: '0001424467,AH LISANTI CAPITAL GROWTH, LLC',
    name: 'AH LISANTI CAPITAL GROWTH, LLC',
    label: 'AH LISANTI CAPITAL GROWTH, LLC',
  },
  {
    cik: '0001598894',
    value: '0001598894,AHL PARTNERS LLP',
    name: 'AHL PARTNERS LLP',
    label: 'AHL PARTNERS LLP',
  },
  {
    cik: '0001698481',
    value: '0001698481,AHRENS INVESTMENT PARTNERS LLC',
    name: 'AHRENS INVESTMENT PARTNERS LLC',
    label: 'AHRENS INVESTMENT PARTNERS LLC',
  },
  {
    cik: '0001522877',
    value: '0001522877,AIA GROUP LTD',
    name: 'AIA GROUP LTD',
    label: 'AIA GROUP LTD',
  },
  {
    cik: '0001758261',
    value: '0001758261,AIA INTERNATIONAL LTD',
    name: 'AIA INTERNATIONAL LTD',
    label: 'AIA INTERNATIONAL LTD',
  },
  {
    cik: '0001802469',
    value: '0001802469,AIA INVESTMENT MANAGEMENT HK LTD',
    name: 'AIA INVESTMENT MANAGEMENT HK LTD',
    label: 'AIA INVESTMENT MANAGEMENT HK LTD',
  },
  {
    cik: '0001757363',
    value: '0001757363,AIA INVESTMENT MANAGEMENT PRIVATE LTD',
    name: 'AIA INVESTMENT MANAGEMENT PRIVATE LTD',
    label: 'AIA INVESTMENT MANAGEMENT PRIVATE LTD',
  },
  {
    cik: '0001757408',
    value: '0001757408,AIA SINGAPORE PRIVATE LTD',
    name: 'AIA SINGAPORE PRIVATE LTD',
    label: 'AIA SINGAPORE PRIVATE LTD',
  },
  {
    cik: '0001680326',
    value: '0001680326,AIDENNLAIR CAPITAL MANAGEMENT LP',
    name: 'AIDENNLAIR CAPITAL MANAGEMENT LP',
    label: 'AIDENNLAIR CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001728031',
    value: '0001728031,AI FINANCIAL SERVICES LLC',
    name: 'AI FINANCIAL SERVICES LLC',
    label: 'AI FINANCIAL SERVICES LLC',
  },
  {
    cik: '0001741224',
    value: '0001741224,AIGEN INVESTMENT MANAGEMENT, LP',
    name: 'AIGEN INVESTMENT MANAGEMENT, LP',
    label: 'AIGEN INVESTMENT MANAGEMENT, LP',
  },
  {
    cik: '0001835943',
    value: '0001835943,AIGH CAPITAL MANAGEMENT LLC',
    name: 'AIGH CAPITAL MANAGEMENT LLC',
    label: 'AIGH CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001653400',
    value: '0001653400,AILANTHUS CAPITAL MANAGEMENT, LP',
    name: 'AILANTHUS CAPITAL MANAGEMENT, LP',
    label: 'AILANTHUS CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0001630365',
    value: '0001630365,AIMZ INVESTMENT ADVISORS, LLC',
    name: 'AIMZ INVESTMENT ADVISORS, LLC',
    label: 'AIMZ INVESTMENT ADVISORS, LLC',
  },
  {
    cik: '0001714498',
    value: '0001714,AIP, LLC8',
    name: 'AIP, LLC',
    label: 'AIP, LLC',
  },
  {
    cik: '0001563592',
    value: '000156359,AIRAIN LTD',
    name: 'AIRAIN LTD',
    label: 'AIRAIN LTD',
  },
  {
    cik: '0001908177',
    value: '0001908177,AIRE ADVISORS, LLC',
    name: 'AIRE ADVISORS, LLC',
    label: 'AIRE ADVISORS, LLC',
  },
  {
    cik: '0001571076',
    value: '0001571076,AISLING CAPITAL LLC',
    name: 'AISLING CAPITAL LLC',
    label: 'AISLING CAPITAL LLC',
  },
  {
    cik: '0001766721',
    value: '0001766721,AISLING CAPITAL MANAGEMENT LP',
    name: 'AISLING CAPITAL MANAGEMENT LP',
    label: 'AISLING CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001013536',
    value: '000101,AJO, LP36',
    name: 'AJO, LP',
    label: 'AJO, LP',
  },
  {
    cik: '0001710065',
    value: '0001710065,AJU IB INVESTMENT CO., LTD.',
    name: 'AJU IB INVESTMENT CO., LTD.',
    label: 'AJU IB INVESTMENT CO., LTD.',
  },
  {
    cik: '0001744073',
    value: '0001744073,AJ WEALTH STRATEGIES, LLC',
    name: 'AJ WEALTH STRATEGIES, LLC',
    label: 'AJ WEALTH STRATEGIES, LLC',
  },
  {
    cik: '0001661568',
    value: '0001661568,AKALOA RESOURCE FOUNDATION',
    name: 'AKALOA RESOURCE FOUNDATION',
    label: 'AKALOA RESOURCE FOUNDATION',
  },
  {
    cik: '0001218957',
    value: '0001218957,AKANTHOS CAPITAL MANAGEMENT LLC',
    name: 'AKANTHOS CAPITAL MANAGEMENT LLC',
    label: 'AKANTHOS CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001801264',
    value: '0001801264,AKARIS GLOBAL PARTNERS, LP',
    name: 'AKARIS GLOBAL PARTNERS, LP',
    label: 'AKARIS GLOBAL PARTNERS, LP',
  },
  {
    cik: '0001474979',
    value: '0001474979,AKKR MANAGEMENT COMPANY, LLC',
    name: 'AKKR MANAGEMENT COMPANY, LLC',
    label: 'AKKR MANAGEMENT COMPANY, LLC',
  },
  {
    cik: '0001376879',
    value: '0001376879,AKO CAPITAL LLP',
    name: 'AKO CAPITAL LLP',
    label: 'AKO CAPITAL LLP',
  },
  {
    cik: '0001112520',
    value: '0001112520,AKRE CAPITAL MANAGEMENT LLC',
    name: 'AKRE CAPITAL MANAGEMENT LLC',
    label: 'AKRE CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001529090',
    value: '0001529090,AKUNA SECURITIES LLC',
    name: 'AKUNA SECURITIES LLC',
    label: 'AKUNA SECURITIES LLC',
  },
  {
    cik: '0001859677',
    value: '0001859677,ALAETHES WEALTH LLC',
    name: 'ALAETHES WEALTH LLC',
    label: 'ALAETHES WEALTH LLC',
  },
  {
    cik: '0001846995',
    value: '0001846995,ALAMAR CAPITAL MANAGEMENT, LLC',
    name: 'ALAMAR CAPITAL MANAGEMENT, LLC',
    label: 'ALAMAR CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001663368',
    value: '0001663368,ALAMBIC INVESTMENT MANAGEMENT, L.P.',
    name: 'ALAMBIC INVESTMENT MANAGEMENT, L.P.',
    label: 'ALAMBIC INVESTMENT MANAGEMENT, L.P.',
  },
  {
    cik: '0001897835',
    value: '0001897835,ALAN B. LANCZ & ASSOCIATES, INC.',
    name: 'ALAN B. LANCZ & ASSOCIATES, INC.',
    label: 'ALAN B. LANCZ & ASSOCIATES, INC.',
  },
  {
    cik: '0001908423',
    value: '0001908423,ALAPOCAS INVESTMENT PARTNERS, INC.',
    name: 'ALAPOCAS INVESTMENT PARTNERS, INC.',
    label: 'ALAPOCAS INVESTMENT PARTNERS, INC.',
  },
  {
    cik: '0001426771',
    value: '0001426771,ALASKA PERMANENT CAPITAL MANAGEMENT',
    name: 'ALASKA PERMANENT CAPITAL MANAGEMENT',
    label: 'ALASKA PERMANENT CAPITAL MANAGEMENT',
  },
  {
    cik: '0001582681',
    value: '0001582681,ALASKA PERMANENT FUND CORP',
    name: 'ALASKA PERMANENT FUND CORP',
    label: 'ALASKA PERMANENT FUND CORP',
  },
  {
    cik: '0001730812',
    value: '0001730812,ALATUS CAPITAL LTD',
    name: 'ALATUS CAPITAL LTD',
    label: 'ALATUS CAPITAL LTD',
  },
  {
    cik: '0001732621',
    value: '0001732621,ALBAR CAPITAL LTD',
    name: 'ALBAR CAPITAL LTD',
    label: 'ALBAR CAPITAL LTD',
  },
  {
    cik: '0001463559',
    value: '0001463559,ALBERTA INVESTMENT MANAGEMENT CORP',
    name: 'ALBERTA INVESTMENT MANAGEMENT CORP',
    label: 'ALBERTA INVESTMENT MANAGEMENT CORP',
  },
  {
    cik: '0001169318',
    value: '0001169318,ALBERT D MASON INC',
    name: 'ALBERT D MASON INC',
    label: 'ALBERT D MASON INC',
  },
  {
    cik: '0001054677',
    value: '0001054677,ALBION FINANCIAL GROUP /UT',
    name: 'ALBION FINANCIAL GROUP /UT',
    label: 'ALBION FINANCIAL GROUP /UT',
  },
  {
    cik: '0001842509',
    value: '0001842509,ALCOSTA CAPITAL MANAGEMENT, INC.',
    name: 'ALCOSTA CAPITAL MANAGEMENT, INC.',
    label: 'ALCOSTA CAPITAL MANAGEMENT, INC.',
  },
  {
    cik: '0001353110',
    value: '0001353110,ALDEBARAN FINANCIAL INC.',
    name: 'ALDEBARAN FINANCIAL INC.',
    label: 'ALDEBARAN FINANCIAL INC.',
  },
  {
    cik: '0001616882',
    value: '0001616882,ALDEN GLOBAL CAPITAL LLC',
    name: 'ALDEN GLOBAL CAPITAL LLC',
    label: 'ALDEN GLOBAL CAPITAL LLC',
  },
  {
    cik: '0001492343',
    value: '0001492343,ALDEN GLOBAL CAPITAL LTD',
    name: 'ALDEN GLOBAL CAPITAL LTD',
    label: 'ALDEN GLOBAL CAPITAL LTD',
  },
  {
    cik: '0001484429',
    value: '0001484429,ALECTA PENSIONSFORSAKRING, OMSESIDIGT',
    name: 'ALECTA PENSIONSFORSAKRING, OMSESIDIGT',
    label: 'ALECTA PENSIONSFORSAKRING, OMSESIDIGT',
  },
  {
    cik: '0001484429',
    value: '0001484429,ALECTA TJANSTEPENSION OMSESIDIGT',
    name: 'ALECTA TJANSTEPENSION OMSESIDIGT',
    label: 'ALECTA TJANSTEPENSION OMSESIDIGT',
  },
  {
    cik: '0001759485',
    value: '0001759485,ALERA INVESTMENT ADVISORS, LLC',
    name: 'ALERA INVESTMENT ADVISORS, LLC',
    label: 'ALERA INVESTMENT ADVISORS, LLC',
  },
  {
    cik: '0001844002',
    value: '0001844002,ALERCE INVESTMENT MANAGEMENT, L.P.',
    name: 'ALERCE INVESTMENT MANAGEMENT, L.P.',
    label: 'ALERCE INVESTMENT MANAGEMENT, L.P.',
  },
  {
    cik: '0000919458',
    value: '0000919458,ALERUS FINANCIAL  NA',
    name: 'ALERUS FINANCIAL  NA',
    label: 'ALERUS FINANCIAL  NA',
  },
  {
    cik: '0001541895',
    value: '0001541895,ALERUS INVESTMENT ADVISORS CORP',
    name: 'ALERUS INVESTMENT ADVISORS CORP',
    label: 'ALERUS INVESTMENT ADVISORS CORP',
  },
  {
    cik: '0001269978',
    value: '0001269978,ALESCO ADVISORS LLC',
    name: 'ALESCO ADVISORS LLC',
    label: 'ALESCO ADVISORS LLC',
  },
  {
    cik: '0001549408',
    value: '0001549408,ALETHEA CAPITAL MANAGEMENT, LLC',
    name: 'ALETHEA CAPITAL MANAGEMENT, LLC',
    label: 'ALETHEA CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001922281',
    value: '0001922281,ALETHEIAN WEALTH ADVISORS LLC',
    name: 'ALETHEIAN WEALTH ADVISORS LLC',
    label: 'ALETHEIAN WEALTH ADVISORS LLC',
  },
  {
    cik: '0001573311',
    value: '0001573311,ALEXANDRIA CAPITAL, LLC',
    name: 'ALEXANDRIA CAPITAL, LLC',
    label: 'ALEXANDRIA CAPITAL, LLC',
  },
  {
    cik: '0001837533',
    value: '0001837533,ALFRED I. DUPONT CHARITABLE TRUST',
    name: 'ALFRED I. DUPONT CHARITABLE TRUST',
    label: 'ALFRED I. DUPONT CHARITABLE TRUST',
  },
  {
    cik: '0001873435',
    value: '0001873435,ALFRETON CAPITAL LLP',
    name: 'ALFRETON CAPITAL LLP',
    label: 'ALFRETON CAPITAL LLP',
  },
  {
    cik: '0001426665',
    value: '0001426665,ALGEBRIS INVESTMENTS (UK) LLP',
    name: 'ALGEBRIS INVESTMENTS (UK) LLP',
    label: 'ALGEBRIS INVESTMENTS (UK) LLP',
  },
  {
    cik: '0001700286',
    value: '0001700286,ALGEBRIS (UK) LTD',
    name: 'ALGEBRIS (UK) LTD',
    label: 'ALGEBRIS (UK) LTD',
  },
  {
    cik: '0001275218',
    value: '0001275218,ALGERT COLDIRON INVESTORS LLC',
    name: 'ALGERT COLDIRON INVESTORS LLC',
    label: 'ALGERT COLDIRON INVESTORS LLC',
  },
  {
    cik: '0001275218',
    value: '0001275218,ALGERT GLOBAL LLC',
    name: 'ALGERT GLOBAL LLC',
    label: 'ALGERT GLOBAL LLC',
  },
  {
    cik: '0001802696',
    value: '0001802696,ALHAMBRA INVESTMENT PARTNERS LLC',
    name: 'ALHAMBRA INVESTMENT PARTNERS LLC',
    label: 'ALHAMBRA INVESTMENT PARTNERS LLC',
  },
  {
    cik: '0001577552',
    value: '0001577552,ALIBABA GROUP HOLDING LTD',
    name: 'ALIBABA GROUP HOLDING LTD',
    label: 'ALIBABA GROUP HOLDING LTD',
  },
  {
    cik: '0001651473',
    value: '0001651473,ALIGHT CAPITAL MANAGEMENT LP',
    name: 'ALIGHT CAPITAL MANAGEMENT LP',
    label: 'ALIGHT CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001961312',
    value: '0001961312,ALIGN WEALTH MANAGEMENT, LLC',
    name: 'ALIGN WEALTH MANAGEMENT, LLC',
    label: 'ALIGN WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001540557',
    value: '0001540557,ALISTAIR CAPITAL MANAGEMENT, L.L.C.',
    name: 'ALISTAIR CAPITAL MANAGEMENT, L.L.C.',
    label: 'ALISTAIR CAPITAL MANAGEMENT, L.L.C.',
  },
  {
    cik: '0001562810',
    value: '0001562810,ALKEN ASSET MANAGEMENT LLP',
    name: 'ALKEN ASSET MANAGEMENT LLP',
    label: 'ALKEN ASSET MANAGEMENT LLP',
  },
  {
    cik: '0001629996',
    value: '0001629996,ALKEN ASSET MANAGEMENT LTD.',
    name: 'ALKEN ASSET MANAGEMENT LTD.',
    label: 'ALKEN ASSET MANAGEMENT LTD.',
  },
  {
    cik: '0001230239',
    value: '0001230239,ALKEON CAPITAL MANAGEMENT LLC',
    name: 'ALKEON CAPITAL MANAGEMENT LLC',
    label: 'ALKEON CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0000775368',
    value: '0000775368,ALLEGHANY CORP /DE',
    name: 'ALLEGHANY CORP /DE',
    label: 'ALLEGHANY CORP /DE',
  },
  {
    cik: '0001844148',
    value: '0001844148,ALLEGHENY FINANCIAL GROUP LTD',
    name: 'ALLEGHENY FINANCIAL GROUP LTD',
    label: 'ALLEGHENY FINANCIAL GROUP LTD',
  },
  {
    cik: '0001843631',
    value: '0001843631,ALLEGIANT PRIVATE ADVISORS, LLC',
    name: 'ALLEGIANT PRIVATE ADVISORS, LLC',
    label: 'ALLEGIANT PRIVATE ADVISORS, LLC',
  },
  {
    cik: '0001666610',
    value: '0001666610,ALLEGIS INVESTMENT ADVISORS, LLC',
    name: 'ALLEGIS INVESTMENT ADVISORS, LLC',
    label: 'ALLEGIS INVESTMENT ADVISORS, LLC',
  },
  {
    cik: '0001601384',
    value: '0001601384,ALLEN CAPITAL GROUP, LLC',
    name: 'ALLEN CAPITAL GROUP, LLC',
    label: 'ALLEN CAPITAL GROUP, LLC',
  },
  {
    cik: '0000919489',
    value: '0000919489,ALLEN HOLDING INC /NY',
    name: 'ALLEN HOLDING INC /NY',
    label: 'ALLEN HOLDING INC /NY',
  },
  {
    cik: '0001326234',
    value: '0001326234,ALLEN INVESTMENT MANAGEMENT LLC',
    name: 'ALLEN INVESTMENT MANAGEMENT LLC',
    label: 'ALLEN INVESTMENT MANAGEMENT LLC',
  },
  {
    cik: '0001389544',
    value: '0001389544,ALLEN OPERATIONS LLC',
    name: 'ALLEN OPERATIONS LLC',
    label: 'ALLEN OPERATIONS LLC',
  },
  {
    cik: '0001569141',
    value: '0001569141,ALLEY CO LLC',
    name: 'ALLEY CO LLC',
    label: 'ALLEY CO LLC',
  },
  {
    cik: '0001917618',
    value: '0001917618,ALLEY INVESTMENT MANAGEMENT COMPANY, LLC',
    name: 'ALLEY INVESTMENT MANAGEMENT COMPANY, LLC',
    label: 'ALLEY INVESTMENT MANAGEMENT COMPANY, LLC',
  },
  {
    cik: '0001109448',
    value: '0001109448,ALLIANCEBERNSTEIN L.P.',
    name: 'ALLIANCEBERNSTEIN L.P.',
    label: 'ALLIANCEBERNSTEIN L.P.',
  },
  {
    cik: '0001424606',
    value: '0001424606,ALLIANCE TRUST PLC',
    name: 'ALLIANCE TRUST PLC',
    label: 'ALLIANCE TRUST PLC',
  },
  {
    cik: '0001849724',
    value: '0001849724,ALLIANCE WEALTH ADVISORS, LLC',
    name: 'ALLIANCE WEALTH ADVISORS, LLC',
    label: 'ALLIANCE WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001623707',
    value: '0001623707,ALLIANCE WEALTH MANAGEMENT GROUP',
    name: 'ALLIANCE WEALTH MANAGEMENT GROUP',
    label: 'ALLIANCE WEALTH MANAGEMENT GROUP',
  },
  {
    cik: '0001535323',
    value: '0001535323,ALLIANZ ASSET MANAGEMENT AG',
    name: 'ALLIANZ ASSET MANAGEMENT AG',
    label: 'ALLIANZ ASSET MANAGEMENT AG',
  },
  {
    cik: '0001535323',
    value: '0001535323,ALLIANZ ASSET MANAGEMENT GMBH',
    name: 'ALLIANZ ASSET MANAGEMENT GMBH',
    label: 'ALLIANZ ASSET MANAGEMENT GMBH',
  },
  {
    cik: '0001140038',
    value: '0001140038,ALLIANZ INVESTMENT MANAGEMENT LLC',
    name: 'ALLIANZ INVESTMENT MANAGEMENT LLC',
    label: 'ALLIANZ INVESTMENT MANAGEMENT LLC',
  },
  {
    cik: '0001860935',
    value: '0001860935,ALLIANZ INVESTMENT MANAGEMENT U.S. LLC',
    name: 'ALLIANZ INVESTMENT MANAGEMENT U.S. LLC',
    label: 'ALLIANZ INVESTMENT MANAGEMENT U.S. LLC',
  },
  {
    cik: '0000072499',
    value: '0000072499,ALLIANZ LIFE INSURANCE CO OF NORTH AMERICA',
    name: 'ALLIANZ LIFE INSURANCE CO OF NORTH AMERICA',
    label: 'ALLIANZ LIFE INSURANCE CO OF NORTH AMERICA',
  },
  {
    cik: '0001729869',
    value: '0001729869,ALLIED INVESTMENT ADVISORS, LLC',
    name: 'ALLIED INVESTMENT ADVISORS, LLC',
    label: 'ALLIED INVESTMENT ADVISORS, LLC',
  },
  {
    cik: '0001908612',
    value: '0001908612,ALLIE FAMILY OFFICE LLC',
    name: 'ALLIE FAMILY OFFICE LLC',
    label: 'ALLIE FAMILY OFFICE LLC',
  },
  {
    cik: '0001765885',
    value: '0001765885,ALLRED CAPITAL MANAGEMENT, LLC',
    name: 'ALLRED CAPITAL MANAGEMENT, LLC',
    label: 'ALLRED CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001746810',
    value: '0001746810,ALL SEASON FINANCIAL ADVISORS, INC.',
    name: 'ALL SEASON FINANCIAL ADVISORS, INC.',
    label: 'ALL SEASON FINANCIAL ADVISORS, INC.',
  },
  {
    cik: '0001890906',
    value: '0001890906,ALLSPRING GLOBAL INVESTMENTS HOLDINGS, LLC',
    name: 'ALLSPRING GLOBAL INVESTMENTS HOLDINGS, LLC',
    label: 'ALLSPRING GLOBAL INVESTMENTS HOLDINGS, LLC',
  },
  {
    cik: '0001633674',
    value: '0001633674,ALLSQUARE WEALTH MANAGEMENT LLC',
    name: 'ALLSQUARE WEALTH MANAGEMENT LLC',
    label: 'ALLSQUARE WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0001633603',
    value: '0001633603,ALL-STARS INVESTMENT LTD',
    name: 'ALL-STARS INVESTMENT LTD',
    label: 'ALL-STARS INVESTMENT LTD',
  },
  {
    cik: '0000899051',
    value: '0000899051,ALLSTATE CORP',
    name: 'ALLSTATE CORP',
    label: 'ALLSTATE CORP',
  },
  {
    cik: '0001476223',
    value: '0001476223,ALLSTON TRADING LLC',
    name: 'ALLSTON TRADING LLC',
    label: 'ALLSTON TRADING LLC',
  },
  {
    cik: '0001731358',
    value: '0001731358,ALL TERRAIN FINANCIAL ADVISORS, LLC',
    name: 'ALL TERRAIN FINANCIAL ADVISORS, LLC',
    label: 'ALL TERRAIN FINANCIAL ADVISORS, LLC',
  },
  {
    cik: '0001555170',
    value: '0001555170,ALLWORTH FINANCIAL LP',
    name: 'ALLWORTH FINANCIAL LP',
    label: 'ALLWORTH FINANCIAL LP',
  },
  {
    cik: '0001822947',
    value: '0001822947,ALLY BRIDGE GROUP (NY) LLC',
    name: 'ALLY BRIDGE GROUP (NY) LLC',
    label: 'ALLY BRIDGE GROUP (NY) LLC',
  },
  {
    cik: '0000040729',
    value: '0000040729,ALLY FINANCIAL INC.',
    name: 'ALLY FINANCIAL INC.',
    label: 'ALLY FINANCIAL INC.',
  },
  {
    cik: '0001706013',
    value: '0001706013,ALLY INVEST ADVISORS INC.',
    name: 'ALLY INVEST ADVISORS INC.',
    label: 'ALLY INVEST ADVISORS INC.',
  },
  {
    cik: '0001680613',
    value: '0001680613,ALMANACK INVESTMENT PARTNERS, LLC.',
    name: 'ALMANACK INVESTMENT PARTNERS, LLC.',
    label: 'ALMANACK INVESTMENT PARTNERS, LLC.',
  },
  {
    cik: '0001801297',
    value: '0001801297,AL MEHWAR COMMERCIAL INVESTMENTS LLC',
    name: 'AL MEHWAR COMMERCIAL INVESTMENTS LLC',
    label: 'AL MEHWAR COMMERCIAL INVESTMENTS LLC',
  },
  {
    cik: '0001950054',
    value: '0001950054,ALM FIRST FINANCIAL ADVISORS, LLC',
    name: 'ALM FIRST FINANCIAL ADVISORS, LLC',
    label: 'ALM FIRST FINANCIAL ADVISORS, LLC',
  },
  {
    cik: '0001596468',
    value: '0001596468,ALMITAS CAPITAL LLC',
    name: 'ALMITAS CAPITAL LLC',
    label: 'ALMITAS CAPITAL LLC',
  },
  {
    cik: '0001621872',
    value: '0001621872,ALPENGLOW CAPITAL LP',
    name: 'ALPENGLOW CAPITAL LP',
    label: 'ALPENGLOW CAPITAL LP',
  },
  {
    cik: '0001652044',
    value: '0001652044,ALPHABET INC.',
    name: 'ALPHABET INC.',
    label: 'ALPHABET INC.',
  },
  {
    cik: '0001913464',
    value: '0001913464,ALPHACENTRIC ADVISORS LLC',
    name: 'ALPHACENTRIC ADVISORS LLC',
    label: 'ALPHACENTRIC ADVISORS LLC',
  },
  {
    cik: '0001713662',
    value: '0001713662,ALPHACORE CAPITAL LLC',
    name: 'ALPHACORE CAPITAL LLC',
    label: 'ALPHACORE CAPITAL LLC',
  },
  {
    cik: '0001633256',
    value: '0001633256,ALPHACREST CAPITAL MANAGEMENT LLC',
    name: 'ALPHACREST CAPITAL MANAGEMENT LLC',
    label: 'ALPHACREST CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001580830',
    value: '0001580830,ALPHA CUBED INVESTMENTS, LLC',
    name: 'ALPHA CUBED INVESTMENTS, LLC',
    label: 'ALPHA CUBED INVESTMENTS, LLC',
  },
  {
    cik: '0001842440',
    value: '0001842440,ALPHA DNA INVESTMENT MANAGEMENT LLC',
    name: 'ALPHA DNA INVESTMENT MANAGEMENT LLC',
    label: 'ALPHA DNA INVESTMENT MANAGEMENT LLC',
  },
  {
    cik: '0001535943',
    value: '0001535943,ALPHADYNE ASSET MANAGEMENT LP',
    name: 'ALPHADYNE ASSET MANAGEMENT LP',
    label: 'ALPHADYNE ASSET MANAGEMENT LP',
  },
  {
    cik: '0001568991',
    value: '0001568991,ALPHA FAMILY TRUST',
    name: 'ALPHA FAMILY TRUST',
    label: 'ALPHA FAMILY TRUST',
  },
  {
    cik: '0001044205',
    value: '0001044205,ALPHAGEN SECURITIES LLC',
    name: 'ALPHAGEN SECURITIES LLC',
    label: 'ALPHAGEN SECURITIES LLC',
  },
  {
    cik: '0001348183',
    value: '0001348183,ALPHAMARK ADVISORS, LLC',
    name: 'ALPHAMARK ADVISORS, LLC',
    label: 'ALPHAMARK ADVISORS, LLC',
  },
  {
    cik: '0001802876',
    value: '0001802876,ALPHA OMEGA GROUP, INC.',
    name: 'ALPHA OMEGA GROUP, INC.',
    label: 'ALPHA OMEGA GROUP, INC.',
  },
  {
    cik: '0001665633',
    value: '0001665633,ALPHA OMEGA WEALTH MANAGEMENT LLC',
    name: 'ALPHA OMEGA WEALTH MANAGEMENT LLC',
    label: 'ALPHA OMEGA WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0001482390',
    value: '0001482390,ALPHAONE INVESTMENT SERVICES, LLC',
    name: 'ALPHAONE INVESTMENT SERVICES, LLC',
    label: 'ALPHAONE INVESTMENT SERVICES, LLC',
  },
  {
    cik: '0001818309',
    value: '0001818309,ALPHA PARADIGM PARTNERS, LLC',
    name: 'ALPHA PARADIGM PARTNERS, LLC',
    label: 'ALPHA PARADIGM PARTNERS, LLC',
  },
  {
    cik: '0001761018',
    value: '0001761018,ALPHA QUANT ADVISORS, LLC',
    name: 'ALPHA QUANT ADVISORS, LLC',
    label: 'ALPHA QUANT ADVISORS, LLC',
  },
  {
    cik: '0001387941',
    value: '0001387941,ALPHASIMPLEX GROUP, LLC',
    name: 'ALPHASIMPLEX GROUP, LLC',
    label: 'ALPHASIMPLEX GROUP, LLC',
  },
  {
    cik: '0001926959',
    value: '0001926959,ALPHA SQUARE GROUP S, LLC',
    name: 'ALPHA SQUARE GROUP S, LLC',
    label: 'ALPHA SQUARE GROUP S, LLC',
  },
  {
    cik: '0001633857',
    value: '0001633857,ALPHASTAR CAPITAL MANAGEMENT, LLC',
    name: 'ALPHASTAR CAPITAL MANAGEMENT, LLC',
    label: 'ALPHASTAR CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001558858',
    value: '0001558858,ALPHA WAVE GLOBAL, LP',
    name: 'ALPHA WAVE GLOBAL, LP',
    label: 'ALPHA WAVE GLOBAL, LP',
  },
  {
    cik: '0001353219',
    value: '0001353219,ALPHA WINDWARD LLC',
    name: 'ALPHA WINDWARD LLC',
    label: 'ALPHA WINDWARD LLC',
  },
  {
    cik: '0001731012',
    value: '0001731012,ALPHINITY INVESTMENT MANAGEMENT PTY LTD',
    name: 'ALPHINITY INVESTMENT MANAGEMENT PTY LTD',
    label: 'ALPHINITY INVESTMENT MANAGEMENT PTY LTD',
  },
  {
    cik: '0001317329',
    value: '0001317329,ALPINE ASSOCIATES ADVISORS',
    name: 'ALPINE ASSOCIATES ADVISORS',
    label: 'ALPINE ASSOCIATES ADVISORS',
  },
  {
    cik: '0001396835',
    value: '0001396835,ALPINE ASSOCIATES MANAGEMENT INC.',
    name: 'ALPINE ASSOCIATES MANAGEMENT INC.',
    label: 'ALPINE ASSOCIATES MANAGEMENT INC.',
  },
  {
    cik: '0001581655',
    value: '0001581655,ALPINE GLOBAL MANAGEMENT, LLC',
    name: 'ALPINE GLOBAL MANAGEMENT, LLC',
    label: 'ALPINE GLOBAL MANAGEMENT, LLC',
  },
  {
    cik: '0001531306',
    value: '0001531306,ALPINE GROUP USVI, LLC',
    name: 'ALPINE GROUP USVI, LLC',
    label: 'ALPINE GROUP USVI, LLC',
  },
  {
    cik: '0001508822',
    value: '0001508822,ALPINE INVESTMENT MANAGEMENT, LLC',
    name: 'ALPINE INVESTMENT MANAGEMENT, LLC',
    label: 'ALPINE INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001549916',
    value: '0001549916,ALPINE PARTNERS USVI, LLC',
    name: 'ALPINE PARTNERS USVI, LLC',
    label: 'ALPINE PARTNERS USVI, LLC',
  },
  {
    cik: '0001581655',
    value: '0001581655,ALPINE PARTNERS VI, LLC',
    name: 'ALPINE PARTNERS VI, LLC',
    label: 'ALPINE PARTNERS VI, LLC',
  },
  {
    cik: '0001906602',
    value: '0001906602,ALPINE PEAKS CAPITAL, LP',
    name: 'ALPINE PEAKS CAPITAL, LP',
    label: 'ALPINE PEAKS CAPITAL, LP',
  },
  {
    cik: '0001226886',
    value: '0001226886,ALPINE WOODS CAPITAL INVESTORS, LLC',
    name: 'ALPINE WOODS CAPITAL INVESTORS, LLC',
    label: 'ALPINE WOODS CAPITAL INVESTORS, LLC',
  },
  {
    cik: '0001512910',
    value: '0001512910,ALPINVEST PARTNERS B.V.',
    name: 'ALPINVEST PARTNERS B.V.',
    label: 'ALPINVEST PARTNERS B.V.',
  },
  {
    cik: '0001376113',
    value: '0001376113,ALPS ADVISORS INC',
    name: 'ALPS ADVISORS INC',
    label: 'ALPS ADVISORS INC',
  },
  {
    cik: '0001536114',
    value: '0001536114,ALTA ADVISERS LTD',
    name: 'ALTA ADVISERS LTD',
    label: 'ALTA ADVISERS LTD',
  },
  {
    cik: '0001093589',
    value: '0001093589,ALTA CAPITAL MANAGEMENT LLC/',
    name: 'ALTA CAPITAL MANAGEMENT LLC/',
    label: 'ALTA CAPITAL MANAGEMENT LLC/',
  },
  {
    cik: '0001858353',
    value: '0001858353,ALTA FOX CAPITAL MANAGEMENT, LLC',
    name: 'ALTA FOX CAPITAL MANAGEMENT, LLC',
    label: 'ALTA FOX CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001641604',
    value: '0001641604,ALTA FUNDAMENTAL ADVISERS LLC',
    name: 'ALTA FUNDAMENTAL ADVISERS LLC',
    label: 'ALTA FUNDAMENTAL ADVISERS LLC',
  },
  {
    cik: '0001478982',
    value: '0001478982,ALTAI CAPITAL MANAGEMENT, L.P.',
    name: 'ALTAI CAPITAL MANAGEMENT, L.P.',
    label: 'ALTAI CAPITAL MANAGEMENT, L.P.',
  },
  {
    cik: '0001583728',
    value: '0001583728,ALTALIS CAPITAL MANAGEMENT LLC',
    name: 'ALTALIS CAPITAL MANAGEMENT LLC',
    label: 'ALTALIS CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001621855',
    value: '0001621855,ALTA PARK CAPITAL, LP',
    name: 'ALTA PARK CAPITAL, LP',
    label: 'ALTA PARK CAPITAL, LP',
  },
  {
    cik: '0001631014',
    value: '0001631014,ALTAROCK PARTNERS LLC',
    name: 'ALTAROCK PARTNERS LLC',
    label: 'ALTAROCK PARTNERS LLC',
  },
  {
    cik: '0001434981',
    value: '0001434981,ALTAVISTA WEALTH MANAGEMENT, INC.',
    name: 'ALTAVISTA WEALTH MANAGEMENT, INC.',
    label: 'ALTAVISTA WEALTH MANAGEMENT, INC.',
  },
  {
    cik: '0001843826',
    value: '0001843826,ALTA WEALTH ADVISORS LLC',
    name: 'ALTA WEALTH ADVISORS LLC',
    label: 'ALTA WEALTH ADVISORS LLC',
  },
  {
    cik: '0001506208',
    value: '0001506208,ALTEGRIS ADVISORS, L.L.C.',
    name: 'ALTEGRIS ADVISORS, L.L.C.',
    label: 'ALTEGRIS ADVISORS, L.L.C.',
  },
  {
    cik: '0001840293',
    value: '0001840293,ALTERITY FINANCIAL GROUP, LLC',
    name: 'ALTERITY FINANCIAL GROUP, LLC',
    label: 'ALTERITY FINANCIAL GROUP, LLC',
  },
  {
    cik: '0001767699',
    value: '0001767699,ALTERNA WEALTH MANAGEMENT, INC',
    name: 'ALTERNA WEALTH MANAGEMENT, INC',
    label: 'ALTERNA WEALTH MANAGEMENT, INC',
  },
  {
    cik: '0000712050',
    value: '0000712050,ALTFEST L J & CO INC',
    name: 'ALTFEST L J & CO INC',
    label: 'ALTFEST L J & CO INC',
  },
  {
    cik: '0001541617',
    value: '0001541617,ALTIMETER CAPITAL MANAGEMENT, LLC',
    name: 'ALTIMETER CAPITAL MANAGEMENT, LLC',
    label: 'ALTIMETER CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001541617',
    value: '0001541617,ALTIMETER CAPITAL MANAGEMENT, LP',
    name: 'ALTIMETER CAPITAL MANAGEMENT, LP',
    label: 'ALTIMETER CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0001746382',
    value: '0001746382,ALTIUM CAPITAL MANAGEMENT LP',
    name: 'ALTIUM CAPITAL MANAGEMENT LP',
    label: 'ALTIUM CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001739594',
    value: '0001739594,ALTIUM WEALTH MANAGEMENT LLC',
    name: 'ALTIUM WEALTH MANAGEMENT LLC',
    label: 'ALTIUM WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0001731671',
    value: '0001731671,ALTMAN ADVISORS, INC.',
    name: 'ALTMAN ADVISORS, INC.',
    label: 'ALTMAN ADVISORS, INC.',
  },
  {
    cik: '0001881755',
    value: '0001881755,ALTOS VENTURES MANAGEMENT INC.',
    name: 'ALTOS VENTURES MANAGEMENT INC.',
    label: 'ALTOS VENTURES MANAGEMENT INC.',
  },
  {
    cik: '0001740837',
    value: '0001740837,ALTRAVUE CAPITAL, LLC',
    name: 'ALTRAVUE CAPITAL, LLC',
    label: 'ALTRAVUE CAPITAL, LLC',
  },
  {
    cik: '0001167388',
    value: '0001167388,ALTRINSIC GLOBAL ADVISORS LLC',
    name: 'ALTRINSIC GLOBAL ADVISORS LLC',
    label: 'ALTRINSIC GLOBAL ADVISORS LLC',
  },
  {
    cik: '0001940646',
    value: '0001940646,ALTRIUS CAPITAL MANAGEMENT INC',
    name: 'ALTRIUS CAPITAL MANAGEMENT INC',
    label: 'ALTRIUS CAPITAL MANAGEMENT INC',
  },
  {
    cik: '0001324290',
    value: '0001324290,ALTSHULER SHAHAM LTD',
    name: 'ALTSHULER SHAHAM LTD',
    label: 'ALTSHULER SHAHAM LTD',
  },
  {
    cik: '0001329844',
    value: '0001329844,ALTUS CAPITAL INC.',
    name: 'ALTUS CAPITAL INC.',
    label: 'ALTUS CAPITAL INC.',
  },
  {
    cik: '0001842572',
    value: '0001842572,ALTUS WEALTH GROUP LLC',
    name: 'ALTUS WEALTH GROUP LLC',
    label: 'ALTUS WEALTH GROUP LLC',
  },
  {
    cik: '0001802107',
    value: '0001802107,ALTUS WEALTH MANAGEMENT, LLC',
    name: 'ALTUS WEALTH MANAGEMENT, LLC',
    label: 'ALTUS WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001827442',
    value: '0001827442,ALUA CAPITAL MANAGEMENT LP',
    name: 'ALUA CAPITAL MANAGEMENT LP',
    label: 'ALUA CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001279895',
    value: '0001279895,ALYDAR PARTNERS LLC',
    name: 'ALYDAR PARTNERS LLC',
    label: 'ALYDAR PARTNERS LLC',
  },
  {
    cik: '0001453072',
    value: '0001453072,ALYESKA INVESTMENT GROUP, L.P.',
    name: 'ALYESKA INVESTMENT GROUP, L.P.',
    label: 'ALYESKA INVESTMENT GROUP, L.P.',
  },
  {
    cik: '0001320652',
    value: '0001320652,AMADEUS CAPITAL PARTNERS LTD',
    name: 'AMADEUS CAPITAL PARTNERS LTD',
    label: 'AMADEUS CAPITAL PARTNERS LTD',
  },
  {
    cik: '0000919192',
    value: '0000919192,AMALGAMATED BANK',
    name: 'AMALGAMATED BANK',
    label: 'AMALGAMATED BANK',
  },
  {
    cik: '0001823608',
    value: '0001823608,AMALGAMATED FINANCIAL CORP.',
    name: 'AMALGAMATED FINANCIAL CORP.',
    label: 'AMALGAMATED FINANCIAL CORP.',
  },
  {
    cik: '0001053013',
    value: '0001053013,AMARILLO NATIONAL BANK',
    name: 'AMARILLO NATIONAL BANK',
    label: 'AMARILLO NATIONAL BANK',
  },
  {
    cik: '0001018724',
    value: '0001018724,AMAZON COM INC',
    name: 'AMAZON COM INC',
    label: 'AMAZON COM INC',
  },
  {
    cik: '0001695078',
    value: '0001695078,AMBASSADOR ADVISORS, LLC',
    name: 'AMBASSADOR ADVISORS, LLC',
    label: 'AMBASSADOR ADVISORS, LLC',
  },
  {
    cik: '0001559673',
    value: '0001559673,AMBER CAPITAL MANAGEMENT LP',
    name: 'AMBER CAPITAL MANAGEMENT LP',
    label: 'AMBER CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001910168',
    value: '0001910168,AMELIORA WEALTH MANAGEMENT LTD.',
    name: 'AMELIORA WEALTH MANAGEMENT LTD.',
    label: 'AMELIORA WEALTH MANAGEMENT LTD.',
  },
  {
    cik: '0001772954',
    value: '0001772954,AMERAUDI ASSET MANAGEMENT, INC.',
    name: 'AMERAUDI ASSET MANAGEMENT, INC.',
    label: 'AMERAUDI ASSET MANAGEMENT, INC.',
  },
  {
    cik: '0001476380',
    value: '0001476380,AMERICAFIRST CAPITAL MANAGEMENT, LLC',
    name: 'AMERICAFIRST CAPITAL MANAGEMENT, LLC',
    label: 'AMERICAFIRST CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001308778',
    value: '0001308778,AMERICA FIRST INVESTMENT ADVISORS, LLC',
    name: 'AMERICA FIRST INVESTMENT ADVISORS, LLC',
    label: 'AMERICA FIRST INVESTMENT ADVISORS, LLC',
  },
  {
    cik: '0001579160',
    value:
      '0001579160,AMERICAFIRST QUANTITATIVE TRUST - AMERICAFIRST DEFENSIVE GROWTH & INCOME PORTFOLIO, SERIES 2',
    name: 'AMERICAFIRST QUANTITATIVE TRUST - AMERICAFIRST DEFENSIVE GROWTH & INCOME PORTFOLIO, SERIES 2',
    label:
      'AMERICAFIRST QUANTITATIVE TRUST - AMERICAFIRST DEFENSIVE GROWTH & INCOME PORTFOLIO, SERIES 2',
  },
  {
    cik: '0001797678',
    value: '0001797678,AMERICANA PARTNERS, LLC',
    name: 'AMERICANA PARTNERS, LLC',
    label: 'AMERICANA PARTNERS, LLC',
  },
  {
    cik: '0001569454',
    value: '0001569454,AMERICAN ASSET MANAGEMENT INC.',
    name: 'AMERICAN ASSET MANAGEMENT INC.',
    label: 'AMERICAN ASSET MANAGEMENT INC.',
  },
  {
    cik: '0001626691',
    value: '0001626691,AMERICAN ASSETS CAPITAL ADVISERS',
    name: 'AMERICAN ASSETS CAPITAL ADVISERS',
    label: 'AMERICAN ASSETS CAPITAL ADVISERS',
  },
  {
    cik: '0001626691',
    value: '0001626691,AMERICAN ASSETS CAPITAL ADVISERS, LLC',
    name: 'AMERICAN ASSETS CAPITAL ADVISERS, LLC',
    label: 'AMERICAN ASSETS CAPITAL ADVISERS, LLC',
  },
  {
    cik: '0000885118',
    value: '0000885118,AMERICAN ASSETS INC',
    name: 'AMERICAN ASSETS INC',
    label: 'AMERICAN ASSETS INC',
  },
  {
    cik: '0001389256',
    value: '0001389256,AMERICAN ASSETS INVESTMENT MANAGEMENT, LLC',
    name: 'AMERICAN ASSETS INVESTMENT MANAGEMENT, LLC',
    label: 'AMERICAN ASSETS INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0000908758',
    value: '0000908758,AMERICAN BEACON ADVISORS, INC.',
    name: 'AMERICAN BEACON ADVISORS, INC.',
    label: 'AMERICAN BEACON ADVISORS, INC.',
  },
  {
    cik: '0001320168',
    value: '0001320168,AMERICAN CAPITAL MANAGEMENT INC',
    name: 'AMERICAN CAPITAL MANAGEMENT INC',
    label: 'AMERICAN CAPITAL MANAGEMENT INC',
  },
  {
    cik: '0001543454',
    value: '0001543454,AMERICAN CAPITAL MORTGAGE MANAGEMENT, LLC',
    name: 'AMERICAN CAPITAL MORTGAGE MANAGEMENT, LLC',
    label: 'AMERICAN CAPITAL MORTGAGE MANAGEMENT, LLC',
  },
  {
    cik: '0000748054',
    value: '0000748054,AMERICAN CENTURY COMPANIES INC',
    name: 'AMERICAN CENTURY COMPANIES INC',
    label: 'AMERICAN CENTURY COMPANIES INC',
  },
  {
    cik: '0000831457',
    value: '0000831457,AMERICAN ECONOMIC PLANNING GROUP INC /ADV',
    name: 'AMERICAN ECONOMIC PLANNING GROUP INC /ADV',
    label: 'AMERICAN ECONOMIC PLANNING GROUP INC /ADV',
  },
  {
    cik: '0001803089',
    value: '0001803089,AMERICAN FAMILY INSURANCE MUTUAL HOLDING CO',
    name: 'AMERICAN FAMILY INSURANCE MUTUAL HOLDING CO',
    label: 'AMERICAN FAMILY INSURANCE MUTUAL HOLDING CO',
  },
  {
    cik: '0000351040',
    value: '0000351040,AMERICAN FAMILY MUTUAL INSURANCE CO',
    name: 'AMERICAN FAMILY MUTUAL INSURANCE CO',
    label: 'AMERICAN FAMILY MUTUAL INSURANCE CO',
  },
  {
    cik: '0001767898',
    value: '0001767898,AMERICAN FINANCIAL ADVISORS, LLC',
    name: 'AMERICAN FINANCIAL ADVISORS, LLC',
    label: 'AMERICAN FINANCIAL ADVISORS, LLC',
  },
  {
    cik: '0001042046',
    value: '0001042046,AMERICAN FINANCIAL GROUP INC',
    name: 'AMERICAN FINANCIAL GROUP INC',
    label: 'AMERICAN FINANCIAL GROUP INC',
  },
  {
    cik: '0000943719',
    value:
      '0000943719,AMERICAN FINANCIAL GROUP INC 401(K) RETIREMENT & SAVINGS PLAN',
    name: 'AMERICAN FINANCIAL GROUP INC 401(K) RETIREMENT & SAVINGS PLAN',
    label: 'AMERICAN FINANCIAL GROUP INC 401(K) RETIREMENT & SAVINGS PLAN',
  },
  {
    cik: '0001680091',
    value: '0001680091,AMERICAN FINANCIAL NETWORK ADVISORY SERVICES LLC',
    name: 'AMERICAN FINANCIAL NETWORK ADVISORY SERVICES LLC',
    label: 'AMERICAN FINANCIAL NETWORK ADVISORY SERVICES LLC',
  },
  {
    cik: '0001392910',
    value: '0001392910,AMERICAN INDEPENDENCE FINANCIAL SERVICES LLC',
    name: 'AMERICAN INDEPENDENCE FINANCIAL SERVICES LLC',
    label: 'AMERICAN INDEPENDENCE FINANCIAL SERVICES LLC',
  },
  {
    cik: '0001805824',
    value:
      '0001805824,AMERICAN INSTITUTE FOR ADVANCED INVESTMENT MANAGEMENT, LLP',
    name: 'AMERICAN INSTITUTE FOR ADVANCED INVESTMENT MANAGEMENT, LLP',
    label: 'AMERICAN INSTITUTE FOR ADVANCED INVESTMENT MANAGEMENT, LLP',
  },
  {
    cik: '0000005272',
    value: '0000005272,AMERICAN INTERNATIONAL GROUP INC',
    name: 'AMERICAN INTERNATIONAL GROUP INC',
    label: 'AMERICAN INTERNATIONAL GROUP INC',
  },
  {
    cik: '0000005272',
    value: '0000005272,AMERICAN INTERNATIONAL GROUP, INC.',
    name: 'AMERICAN INTERNATIONAL GROUP, INC.',
    label: 'AMERICAN INTERNATIONAL GROUP, INC.',
  },
  {
    cik: '0001369913',
    value: '0001369913,AMERICAN INVESTMENT SERVICES, INC.',
    name: 'AMERICAN INVESTMENT SERVICES, INC.',
    label: 'AMERICAN INVESTMENT SERVICES, INC.',
  },
  {
    cik: '0001600085',
    value: '0001600085,AMERICAN MONEY MANAGEMENT, LLC',
    name: 'AMERICAN MONEY MANAGEMENT, LLC',
    label: 'AMERICAN MONEY MANAGEMENT, LLC',
  },
  {
    cik: '0001272164',
    value: '0001272164,AMERICAN NATIONAL BANK',
    name: 'AMERICAN NATIONAL BANK',
    label: 'AMERICAN NATIONAL BANK',
  },
  {
    cik: '0000939835',
    value: '0000939835,AMERICAN NATIONAL BANK & TRUST CO /VA/',
    name: 'AMERICAN NATIONAL BANK & TRUST CO /VA/',
    label: 'AMERICAN NATIONAL BANK & TRUST CO /VA/',
  },
  {
    cik: '0000904163',
    value: '0000904163,AMERICAN NATIONAL INSURANCE CO',
    name: 'AMERICAN NATIONAL INSURANCE CO',
    label: 'AMERICAN NATIONAL INSURANCE CO',
  },
  {
    cik: '0001518195',
    value: '0001518195,AMERICAN NATIONAL REGISTERED INVESTMENT ADVISOR, INC.',
    name: 'AMERICAN NATIONAL REGISTERED INVESTMENT ADVISOR, INC.',
    label: 'AMERICAN NATIONAL REGISTERED INVESTMENT ADVISOR, INC.',
  },
  {
    cik: '0001906656',
    value: '0001906656,AMERICAN PLANNING SERVICES, INC.',
    name: 'AMERICAN PLANNING SERVICES, INC.',
    label: 'AMERICAN PLANNING SERVICES, INC.',
  },
  {
    cik: '0001506796',
    value: '0001506796,AMERICAN RESEARCH & MANAGEMENT CO.',
    name: 'AMERICAN RESEARCH & MANAGEMENT CO.',
    label: 'AMERICAN RESEARCH & MANAGEMENT CO.',
  },
  {
    cik: '0001475483',
    value: '0001475483,AMERICAN SECURITIES LLC',
    name: 'AMERICAN SECURITIES LLC',
    label: 'AMERICAN SECURITIES LLC',
  },
  {
    cik: '0001905128',
    value: '0001905128,AMERICAN TRUST',
    name: 'AMERICAN TRUST',
    label: 'AMERICAN TRUST',
  },
  {
    cik: '0001291424',
    value: '0001291424,AMERICAN TRUST INVESTMENT ADVISORS, LLC',
    name: 'AMERICAN TRUST INVESTMENT ADVISORS, LLC',
    label: 'AMERICAN TRUST INVESTMENT ADVISORS, LLC',
  },
  {
    cik: '0001641891',
    value: '0001641891,AMERIGO ASSET MANAGEMENT',
    name: 'AMERIGO ASSET MANAGEMENT',
    label: 'AMERIGO ASSET MANAGEMENT',
  },
  {
    cik: '0000820027',
    value: '0000820027,AMERIPRISE FINANCIAL INC',
    name: 'AMERIPRISE FINANCIAL INC',
    label: 'AMERIPRISE FINANCIAL INC',
  },
  {
    cik: '0001901275',
    value: '0001901275,AMERITAS ADVISORY SERVICES, LLC',
    name: 'AMERITAS ADVISORY SERVICES, LLC',
    label: 'AMERITAS ADVISORY SERVICES, LLC',
  },
  {
    cik: '0000938165',
    value: '0000938165,AMERITAS INVESTMENT COMPANY, LLC',
    name: 'AMERITAS INVESTMENT COMPANY, LLC',
    label: 'AMERITAS INVESTMENT COMPANY, LLC',
  },
  {
    cik: '0001055980',
    value: '0001055980,AMERITAS INVESTMENT PARTNERS, INC.',
    name: 'AMERITAS INVESTMENT PARTNERS, INC.',
    label: 'AMERITAS INVESTMENT PARTNERS, INC.',
  },
  {
    cik: '0001461539',
    value: '0001461539,AMERIVEST INVESTMENT MANAGEMENT LLC',
    name: 'AMERIVEST INVESTMENT MANAGEMENT LLC',
    label: 'AMERIVEST INVESTMENT MANAGEMENT LLC',
  },
  {
    cik: '0001553562',
    value: '0001553562,AMF PENSIONSFORSAKRING AB',
    name: 'AMF PENSIONSFORSAKRING AB',
    label: 'AMF PENSIONSFORSAKRING AB',
  },
  {
    cik: '0001553562',
    value: '0001553562,AMF TJANSTEPENSION AB',
    name: 'AMF TJANSTEPENSION AB',
    label: 'AMF TJANSTEPENSION AB',
  },
  {
    cik: '0001115419',
    value: '0001115419,AMG FUNDS LLC',
    name: 'AMG FUNDS LLC',
    label: 'AMG FUNDS LLC',
  },
  {
    cik: '0001388829',
    value: '0001388829,AMG NATIONAL TRUST BANK',
    name: 'AMG NATIONAL TRUST BANK',
    label: 'AMG NATIONAL TRUST BANK',
  },
  {
    cik: '0001600535',
    value: '0001600535,AMH EQUITY LTD',
    name: 'AMH EQUITY LTD',
    label: 'AMH EQUITY LTD',
  },
  {
    cik: '0001712575',
    value: '0001712575,AMIA CAPITAL LLP',
    name: 'AMIA CAPITAL LLP',
    label: 'AMIA CAPITAL LLP',
  },
  {
    cik: '0001369702',
    value: '0001369702,AMI ASSET MANAGEMENT CORP',
    name: 'AMI ASSET MANAGEMENT CORP',
    label: 'AMI ASSET MANAGEMENT CORP',
  },
  {
    cik: '0000857508',
    value: '0000857508,AMICA MUTUAL INSURANCE CO',
    name: 'AMICA MUTUAL INSURANCE CO',
    label: 'AMICA MUTUAL INSURANCE CO',
  },
  {
    cik: '0000857538',
    value: '0000857538,AMICA PENSION FUND BOARD OF TRUSTEES',
    name: 'AMICA PENSION FUND BOARD OF TRUSTEES',
    label: 'AMICA PENSION FUND BOARD OF TRUSTEES',
  },
  {
    cik: '0001599950',
    value: '0001599950,AMICA RETIREE MEDICAL TRUST',
    name: 'AMICA RETIREE MEDICAL TRUST',
    label: 'AMICA RETIREE MEDICAL TRUST',
  },
  {
    cik: '0001326537',
    value: '0001326537,AMICI CAPITAL, LLC',
    name: 'AMICI CAPITAL, LLC',
    label: 'AMICI CAPITAL, LLC',
  },
  {
    cik: '0001427356',
    value: '0001427356,AMIDA CAPITAL MANAGEMENT II, LLC',
    name: 'AMIDA CAPITAL MANAGEMENT II, LLC',
    label: 'AMIDA CAPITAL MANAGEMENT II, LLC',
  },
  {
    cik: '0001122241',
    value: '0001122241,AMI INVESTMENT MANAGEMENT INC',
    name: 'AMI INVESTMENT MANAGEMENT INC',
    label: 'AMI INVESTMENT MANAGEMENT INC',
  },
  {
    cik: '0001818897',
    value: '0001818897,AM INVESTMENT STRATEGIES LLC',
    name: 'AM INVESTMENT STRATEGIES LLC',
    label: 'AM INVESTMENT STRATEGIES LLC',
  },
  {
    cik: '0001807559',
    value: '0001807559,AMITELL CAPITAL PTE LTD',
    name: 'AMITELL CAPITAL PTE LTD',
    label: 'AMITELL CAPITAL PTE LTD',
  },
  {
    cik: '0001512277',
    value: '0001512277,AMIYA CAPITAL LLP',
    name: 'AMIYA CAPITAL LLP',
    label: 'AMIYA CAPITAL LLP',
  },
  {
    cik: '0001801585',
    value: '0001801585,AMJ FINANCIAL WEALTH MANAGEMENT',
    name: 'AMJ FINANCIAL WEALTH MANAGEMENT',
    label: 'AMJ FINANCIAL WEALTH MANAGEMENT',
  },
  {
    cik: '0001535782',
    value: '0001535782,AMP CAPITAL INVESTORS LTD',
    name: 'AMP CAPITAL INVESTORS LTD',
    label: 'AMP CAPITAL INVESTORS LTD',
  },
  {
    cik: '0001853635',
    value: '0001853635,AMPFIELD MANAGEMENT, L.P.',
    name: 'AMPFIELD MANAGEMENT, L.P.',
    label: 'AMPFIELD MANAGEMENT, L.P.',
  },
  {
    cik: '0001632494',
    value: '0001632494,AMPLIFY INVESTMENTS, LLC',
    name: 'AMPLIFY INVESTMENTS, LLC',
    label: 'AMPLIFY INVESTMENTS, LLC',
  },
  {
    cik: '0001900584',
    value: '0001900584,AMPLIUS WEALTH ADVISORS, LLC',
    name: 'AMPLIUS WEALTH ADVISORS, LLC',
    label: 'AMPLIUS WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001717977',
    value: '0001717977,AMS CAPITAL LTDA',
    name: 'AMS CAPITAL LTDA',
    label: 'AMS CAPITAL LTDA',
  },
  {
    cik: '0001365555',
    value: '0001365555,AMTRUST FINANCIAL SERVICES, INC.',
    name: 'AMTRUST FINANCIAL SERVICES, INC.',
    label: 'AMTRUST FINANCIAL SERVICES, INC.',
  },
  {
    cik: '0001330387',
    value: '00013,AMUNDI387',
    name: 'AMUNDI',
    label: 'AMUNDI',
  },
  {
    cik: '0001102598',
    value: '0001102598,AMUNDI ASSET MANAGEMENT US, INC.',
    name: 'AMUNDI ASSET MANAGEMENT US, INC.',
    label: 'AMUNDI ASSET MANAGEMENT US, INC.',
  },
  {
    cik: '0001713416',
    value: '0001713416,AMUNDI SMITH BREEDEN LLC',
    name: 'AMUNDI SMITH BREEDEN LLC',
    label: 'AMUNDI SMITH BREEDEN LLC',
  },
  {
    cik: '0001131181',
    value: '0001131181,AMUSSEN HUNSAKER & ASSOCIATES LLC',
    name: 'AMUSSEN HUNSAKER & ASSOCIATES LLC',
    label: 'AMUSSEN HUNSAKER & ASSOCIATES LLC',
  },
  {
    cik: '0001131181',
    value: '0001131181,AMUSSEN HUNSAKER ASSOCIATES LLC',
    name: 'AMUSSEN HUNSAKER ASSOCIATES LLC',
    label: 'AMUSSEN HUNSAKER ASSOCIATES LLC',
  },
  {
    cik: '0001822480',
    value: '0001822480,ANABRANCH CAPITAL MANAGEMENT, LP',
    name: 'ANABRANCH CAPITAL MANAGEMENT, LP',
    label: 'ANABRANCH CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0001753384',
    value: '0001753384,ANALOG CENTURY MANAGEMENT LP',
    name: 'ANALOG CENTURY MANAGEMENT LP',
    label: 'ANALOG CENTURY MANAGEMENT LP',
  },
  {
    cik: '0001634208',
    value: '0001634208,ANALYST IMS INVESTMENT MANAGEMENT SERVICES LTD.',
    name: 'ANALYST IMS INVESTMENT MANAGEMENT SERVICES LTD.',
    label: 'ANALYST IMS INVESTMENT MANAGEMENT SERVICES LTD.',
  },
  {
    cik: '0001086137',
    value: '0001086137,ANALYTIC INVESTORS, LLC',
    name: 'ANALYTIC INVESTORS, LLC',
    label: 'ANALYTIC INVESTORS, LLC',
  },
  {
    cik: '0001616584',
    value: '0001616584,ANANDAR CAPITAL MANAGEMENT LP',
    name: 'ANANDAR CAPITAL MANAGEMENT LP',
    label: 'ANANDAR CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001693745',
    value: '0001693745,ANATOLE INVESTMENT MANAGEMENT LTD',
    name: 'ANATOLE INVESTMENT MANAGEMENT LTD',
    label: 'ANATOLE INVESTMENT MANAGEMENT LTD',
  },
  {
    cik: '0001697186',
    value: '0001697186,ANAVON CAPITAL LLP',
    name: 'ANAVON CAPITAL LLP',
    label: 'ANAVON CAPITAL LLP',
  },
  {
    cik: '0001300714',
    value: '0001300714,ANCHORAGE CAPITAL GROUP, L.L.C.',
    name: 'ANCHORAGE CAPITAL GROUP, L.L.C.',
    label: 'ANCHORAGE CAPITAL GROUP, L.L.C.',
  },
  {
    cik: '0001542943',
    value: '0001542943,ANCHOR BOLT CAPITAL, LP',
    name: 'ANCHOR BOLT CAPITAL, LP',
    label: 'ANCHOR BOLT CAPITAL, LP',
  },
  {
    cik: '0000813933',
    value: '0000813933,ANCHOR CAPITAL ADVISORS LLC',
    name: 'ANCHOR CAPITAL ADVISORS LLC',
    label: 'ANCHOR CAPITAL ADVISORS LLC',
  },
  {
    cik: '0001426749',
    value: '0001426749,ANCIENT ART, L.P.',
    name: 'ANCIENT ART, L.P.',
    label: 'ANCIENT ART, L.P.',
  },
  {
    cik: '0001446114',
    value: '0001446114,ANCORA ADVISORS, LLC',
    name: 'ANCORA ADVISORS, LLC',
    label: 'ANCORA ADVISORS, LLC',
  },
  {
    cik: '0001657660',
    value: '0001657660,ANCORA INVERNESS, LLC',
    name: 'ANCORA INVERNESS, LLC',
    label: 'ANCORA INVERNESS, LLC',
  },
  {
    cik: '0001463698',
    value: '0001463698,ANDALUSIAN CAPITAL PARTNERS, LP',
    name: 'ANDALUSIAN CAPITAL PARTNERS, LP',
    label: 'ANDALUSIAN CAPITAL PARTNERS, LP',
  },
  {
    cik: '0001805591',
    value: '0001805591,ANDAR CAPITAL MANAGEMENT HK LTD',
    name: 'ANDAR CAPITAL MANAGEMENT HK LTD',
    label: 'ANDAR CAPITAL MANAGEMENT HK LTD',
  },
  {
    cik: '0001767513',
    value: '0001767513,ANDERSON FISHER LLC',
    name: 'ANDERSON FISHER LLC',
    label: 'ANDERSON FISHER LLC',
  },
  {
    cik: '0000791191',
    value: '0000791191,ANDERSON HOAGLAND & CO',
    name: 'ANDERSON HOAGLAND & CO',
    label: 'ANDERSON HOAGLAND & CO',
  },
  {
    cik: '0001752985',
    value: '0001752985,ANDESA FINANCIAL MANAGEMENT INC.',
    name: 'ANDESA FINANCIAL MANAGEMENT INC.',
    label: 'ANDESA FINANCIAL MANAGEMENT INC.',
  },
  {
    cik: '0001166147',
    value: '0001166147,ANDOR CAPITAL MANAGEMENT, L.L.C.',
    name: 'ANDOR CAPITAL MANAGEMENT, L.L.C.',
    label: 'ANDOR CAPITAL MANAGEMENT, L.L.C.',
  },
  {
    cik: '0001535452',
    value: '0001535452,ANDRA AP-FONDEN',
    name: 'ANDRA AP-FONDEN',
    label: 'ANDRA AP-FONDEN',
  },
  {
    cik: '0001317097',
    value: '0001317097,ANDREEFF EQUITY ADVISORS, LLC',
    name: 'ANDREEFF EQUITY ADVISORS, LLC',
    label: 'ANDREEFF EQUITY ADVISORS, LLC',
  },
  {
    cik: '0001897071',
    value: '0001897071,ANDREW HILL INVESTMENT ADVISORS, INC.',
    name: 'ANDREW HILL INVESTMENT ADVISORS, INC.',
    label: 'ANDREW HILL INVESTMENT ADVISORS, INC.',
  },
  {
    cik: '0001906227',
    value: '0001906227,ANDREWS, LUCIA WEALTH MANAGEMENT LLC',
    name: 'ANDREWS, LUCIA WEALTH MANAGEMENT LLC',
    label: 'ANDREWS, LUCIA WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0001510475',
    value: '0001510475,ANDROMEDA CAPITAL LLC',
    name: 'ANDROMEDA CAPITAL LLC',
    label: 'ANDROMEDA CAPITAL LLC',
  },
  {
    cik: '0001602020',
    value: '0001602020,ANFIELD CAPITAL MANAGEMENT, LLC',
    name: 'ANFIELD CAPITAL MANAGEMENT, LLC',
    label: 'ANFIELD CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001535695',
    value: '0001535695,ANGELES INVESTMENT ADVISORS, LLC',
    name: 'ANGELES INVESTMENT ADVISORS, LLC',
    label: 'ANGELES INVESTMENT ADVISORS, LLC',
  },
  {
    cik: '0001929170',
    value: '0001929170,ANGELES WEALTH MANAGEMENT, LLC',
    name: 'ANGELES WEALTH MANAGEMENT, LLC',
    label: 'ANGELES WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0000860662',
    value: '0000860662,ANGELO GORDON & CO., L.P.',
    name: 'ANGELO GORDON & CO., L.P.',
    label: 'ANGELO GORDON & CO., L.P.',
  },
  {
    cik: '0001869683',
    value: '0001869683,ANGLEPOINT ASSET MANAGEMENT LTD',
    name: 'ANGLEPOINT ASSET MANAGEMENT LTD',
    label: 'ANGLEPOINT ASSET MANAGEMENT LTD',
  },
  {
    cik: '0001760403',
    value: '0001760403,ANGLEPOINT ASSET MANAGEMENT, LTD.',
    name: 'ANGLEPOINT ASSET MANAGEMENT, LTD.',
    label: 'ANGLEPOINT ASSET MANAGEMENT, LTD.',
  },
  {
    cik: '0001811739',
    value: '0001811739,ANNANDALE CAPITAL, LLC',
    name: 'ANNANDALE CAPITAL, LLC',
    label: 'ANNANDALE CAPITAL, LLC',
  },
  {
    cik: '0001843110',
    value: '0001843110,ANNAPOLIS FINANCIAL SERVICES, LLC',
    name: 'ANNAPOLIS FINANCIAL SERVICES, LLC',
    label: 'ANNAPOLIS FINANCIAL SERVICES, LLC',
  },
  {
    cik: '0001661562',
    value: '0001661562,ANNE RAY CHARITABLE TRUST',
    name: 'ANNE RAY CHARITABLE TRUST',
    label: 'ANNE RAY CHARITABLE TRUST',
  },
  {
    cik: '0001542287',
    value: '0001542287,ANNEX ADVISORY SERVICES, LLC',
    name: 'ANNEX ADVISORY SERVICES, LLC',
    label: 'ANNEX ADVISORY SERVICES, LLC',
  },
  {
    cik: '0001817534',
    value: '0001817534,ANOMALY CAPITAL MANAGEMENT, LP',
    name: 'ANOMALY CAPITAL MANAGEMENT, LP',
    label: 'ANOMALY CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0001827210',
    value: '0001827210,ANQA MANAGEMENT LLC',
    name: 'ANQA MANAGEMENT LLC',
    label: 'ANQA MANAGEMENT LLC',
  },
  {
    cik: '0001906003',
    value: '0001906003,ANSON CAPITAL, INC.',
    name: 'ANSON CAPITAL, INC.',
    label: 'ANSON CAPITAL, INC.',
  },
  {
    cik: '0001491072',
    value: '0001491072,ANSON FUNDS MANAGEMENT LP',
    name: 'ANSON FUNDS MANAGEMENT LP',
    label: 'ANSON FUNDS MANAGEMENT LP',
  },
  {
    cik: '0001750183',
    value: '0001750183,ANTARA CAPITAL LP',
    name: 'ANTARA CAPITAL LP',
    label: 'ANTARA CAPITAL LP',
  },
  {
    cik: '0001512566',
    value: '0001512566,ANTIPODEAN ADVISORS LLC',
    name: 'ANTIPODEAN ADVISORS LLC',
    label: 'ANTIPODEAN ADVISORS LLC',
  },
  {
    cik: '0001652442',
    value: '0001652442,ANTIPODES PARTNERS LTD',
    name: 'ANTIPODES PARTNERS LTD',
    label: 'ANTIPODES PARTNERS LTD',
  },
  {
    cik: '0001568859',
    value: '0001568859,ANTONETTI CAPITAL MANAGEMENT LLC',
    name: 'ANTONETTI CAPITAL MANAGEMENT LLC',
    label: 'ANTONETTI CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001594585',
    value: '0001594585,AO ASSET MANAGEMENT, LLC',
    name: 'AO ASSET MANAGEMENT, LLC',
    label: 'AO ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001594585',
    value: '0001594585,AO ASSET MANAGEMENT, LP',
    name: 'AO ASSET MANAGEMENT, LP',
    label: 'AO ASSET MANAGEMENT, LP',
  },
  {
    cik: '0001568541',
    value: '0001568541,AOZORA BANK, LTD.',
    name: 'AOZORA BANK, LTD.',
    label: 'AOZORA BANK, LTD.',
  },
  {
    cik: '0001731601',
    value: '0001731601,APCM WEALTH MANAGEMENT FOR INDIVIDUALS',
    name: 'APCM WEALTH MANAGEMENT FOR INDIVIDUALS',
    label: 'APCM WEALTH MANAGEMENT FOR INDIVIDUALS',
  },
  {
    cik: '0001843111',
    value: '0001843111,APEIRON CAPITAL LTD',
    name: 'APEIRON CAPITAL LTD',
    label: 'APEIRON CAPITAL LTD',
  },
  {
    cik: '0001801846',
    value: '0001801846,APEIRON RIA LLC',
    name: 'APEIRON RIA LLC',
    label: 'APEIRON RIA LLC',
  },
  {
    cik: '0001801507',
    value: '0001801507,APELLA CAPITAL, LLC',
    name: 'APELLA CAPITAL, LLC',
    label: 'APELLA CAPITAL, LLC',
  },
  {
    cik: '0001577216',
    value: '0001577216,APERIMUS CAPITAL, LLC',
    name: 'APERIMUS CAPITAL, LLC',
    label: 'APERIMUS CAPITAL, LLC',
  },
  {
    cik: '0001364615',
    value: '0001364615,APERIO GROUP, LLC',
    name: 'APERIO GROUP, LLC',
    label: 'APERIO GROUP, LLC',
  },
  {
    cik: '0001633570',
    value: '0001633570,APERTURA CAPITAL, LLC',
    name: 'APERTURA CAPITAL, LLC',
    label: 'APERTURA CAPITAL, LLC',
  },
  {
    cik: '0001787596',
    value: '0001787596,APERTURE INVESTORS, LLC',
    name: 'APERTURE INVESTORS, LLC',
    label: 'APERTURE INVESTORS, LLC',
  },
  {
    cik: '0000943518',
    value: '0000943518,APEX CAPITAL, LLC',
    name: 'APEX CAPITAL, LLC',
    label: 'APEX CAPITAL, LLC',
  },
  {
    cik: '0001293585',
    value: '0001293585,APEX CAPITAL MANAGEMENT',
    name: 'APEX CAPITAL MANAGEMENT',
    label: 'APEX CAPITAL MANAGEMENT',
  },
  {
    cik: '0001566968',
    value: '0001566968,APEX INVESTMENT SERVICES, INC.',
    name: 'APEX INVESTMENT SERVICES, INC.',
    label: 'APEX INVESTMENT SERVICES, INC.',
  },
  {
    cik: '0001765285',
    value: '0001765285,APEXIUM FINANCIAL, LP',
    name: 'APEXIUM FINANCIAL, LP',
    label: 'APEXIUM FINANCIAL, LP',
  },
  {
    cik: '0001434819',
    value: '0001434819,APG ALL PENSIONS GROUP NV',
    name: 'APG ALL PENSIONS GROUP NV',
    label: 'APG ALL PENSIONS GROUP NV',
  },
  {
    cik: '0001434819',
    value: '0001434819,APG ASSET MANAGEMENT N.V.',
    name: 'APG ASSET MANAGEMENT N.V.',
    label: 'APG ASSET MANAGEMENT N.V.',
  },
  {
    cik: '0001323255',
    value: '0001323255,APG ASSET MANAGEMENT US INC.',
    name: 'APG ASSET MANAGEMENT US INC.',
    label: 'APG ASSET MANAGEMENT US INC.',
  },
  {
    cik: '0001386892',
    value: '0001386892,APIS CAPITAL ADVISORS, LLC',
    name: 'APIS CAPITAL ADVISORS, LLC',
    label: 'APIS CAPITAL ADVISORS, LLC',
  },
  {
    cik: '0001845268',
    value: '0001845268,APOGEM CAPITAL LLC',
    name: 'APOGEM CAPITAL LLC',
    label: 'APOGEM CAPITAL LLC',
  },
  {
    cik: '0001730494',
    value: '0001730494,APOLETTO LTD',
    name: 'APOLETTO LTD',
    label: 'APOLETTO LTD',
  },
  {
    cik: '0001449434',
    value: '0001449434,APOLLO MANAGEMENT HOLDINGS, L.P.',
    name: 'APOLLO MANAGEMENT HOLDINGS, L.P.',
    label: 'APOLLO MANAGEMENT HOLDINGS, L.P.',
  },
  {
    cik: '0001764387',
    value: '0001764387,APOLLON WEALTH MANAGEMENT, LLC',
    name: 'APOLLON WEALTH MANAGEMENT, LLC',
    label: 'APOLLON WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001656456',
    value: '0001656456,APPALOOSA LP',
    name: 'APPALOOSA LP',
    label: 'APPALOOSA LP',
  },
  {
    cik: '0001006438',
    value: '0001006438,APPALOOSA MANAGEMENT LP',
    name: 'APPALOOSA MANAGEMENT LP',
    label: 'APPALOOSA MANAGEMENT LP',
  },
  {
    cik: '0001795816',
    value: '0001795816,APPIAN WAY ASSET MANAGEMENT LP',
    name: 'APPIAN WAY ASSET MANAGEMENT LP',
    label: 'APPIAN WAY ASSET MANAGEMENT LP',
  },
  {
    cik: '0001454759',
    value: '0001454759,APPLETON GROUP, LLC',
    name: 'APPLETON GROUP, LLC',
    label: 'APPLETON GROUP, LLC',
  },
  {
    cik: '0001454759',
    value: '0001454759,APPLETON GROUP WEALTH MANAGEMENT LLC',
    name: 'APPLETON GROUP WEALTH MANAGEMENT LLC',
    label: 'APPLETON GROUP WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0001055290',
    value: '0001055290,APPLETON PARTNERS INC/MA',
    name: 'APPLETON PARTNERS INC/MA',
    label: 'APPLETON PARTNERS INC/MA',
  },
  {
    cik: '0001593440',
    value: '0001593440,APPLE TREE PARTNERS IV, L.P.',
    name: 'APPLE TREE PARTNERS IV, L.P.',
    label: 'APPLE TREE PARTNERS IV, L.P.',
  },
  {
    cik: '0001862965',
    value: '0001862965,APPLIED CAPITAL LLC',
    name: 'APPLIED CAPITAL LLC',
    label: 'APPLIED CAPITAL LLC',
  },
  {
    cik: '0001849776',
    value: '0001849776,APPLIED FUNDAMENTAL RESEARCH, LLC',
    name: 'APPLIED FUNDAMENTAL RESEARCH, LLC',
    label: 'APPLIED FUNDAMENTAL RESEARCH, LLC',
  },
  {
    cik: '0001821051',
    value: '0001821051,APPLIED RESEARCH INVESTMENTS, LLC',
    name: 'APPLIED RESEARCH INVESTMENTS, LLC',
    label: 'APPLIED RESEARCH INVESTMENTS, LLC',
  },
  {
    cik: '0000752832',
    value: '0000752832,APPROPRIATE BALANCE FINANCIAL SERVICES INC.',
    name: 'APPROPRIATE BALANCE FINANCIAL SERVICES INC.',
    label: 'APPROPRIATE BALANCE FINANCIAL SERVICES INC.',
  },
  {
    cik: '0001913842',
    value: '0001913842,APRICUS WEALTH, LLC',
    name: 'APRICUS WEALTH, LLC',
    label: 'APRICUS WEALTH, LLC',
  },
  {
    cik: '0001425165',
    value: '0001425165,APRIEM ADVISORS',
    name: 'APRIEM ADVISORS',
    label: 'APRIEM ADVISORS',
  },
  {
    cik: '0001803519',
    value: '0001803519,APRIO WEALTH MANAGEMENT, LLC',
    name: 'APRIO WEALTH MANAGEMENT, LLC',
    label: 'APRIO WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001664193',
    value: '0001664193,APTUS CAPITAL ADVISORS, LLC',
    name: 'APTUS CAPITAL ADVISORS, LLC',
    label: 'APTUS CAPITAL ADVISORS, LLC',
  },
  {
    cik: '0001167456',
    value: '0001167456,AQR ARBITRAGE LLC',
    name: 'AQR ARBITRAGE LLC',
    label: 'AQR ARBITRAGE LLC',
  },
  {
    cik: '0001167557',
    value: '0001167557,AQR CAPITAL MANAGEMENT LLC',
    name: 'AQR CAPITAL MANAGEMENT LLC',
    label: 'AQR CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001404599',
    value: '0001404599,AQUAMARINE CAPITAL MANAGEMENT, LLC',
    name: 'AQUAMARINE CAPITAL MANAGEMENT, LLC',
    label: 'AQUAMARINE CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001953324',
    value: '0001953324,AQUAMARINE ZURICH AG',
    name: 'AQUAMARINE ZURICH AG',
    label: 'AQUAMARINE ZURICH AG',
  },
  {
    cik: '0001803916',
    value: '0001803916,AQUATIC CAPITAL MANAGEMENT LLC',
    name: 'AQUATIC CAPITAL MANAGEMENT LLC',
    label: 'AQUATIC CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001610392',
    value: '0001610392,AQUILA INVESTMENT MANAGEMENT LLC',
    name: 'AQUILA INVESTMENT MANAGEMENT LLC',
    label: 'AQUILA INVESTMENT MANAGEMENT LLC',
  },
  {
    cik: '0001407620',
    value: '0001407620,AQUILINE CAPITAL PARTNERS LLC',
    name: 'AQUILINE CAPITAL PARTNERS LLC',
    label: 'AQUILINE CAPITAL PARTNERS LLC',
  },
  {
    cik: '0001591986',
    value: '0001591986,AQUILO CAPITAL MANAGEMENT, LLC',
    name: 'AQUILO CAPITAL MANAGEMENT, LLC',
    label: 'AQUILO CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001745945',
    value: '0001745945,AQUIRE WEALTH ADVISORS, LLC',
    name: 'AQUIRE WEALTH ADVISORS, LLC',
    label: 'AQUIRE WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001766393',
    value: '0001766393,ARABESQUE ASSET MANAGEMENT LTD',
    name: 'ARABESQUE ASSET MANAGEMENT LTD',
    label: 'ARABESQUE ASSET MANAGEMENT LTD',
  },
  {
    cik: '0001768757',
    value: '0001768757,ARARAT CAPITAL MANAGEMENT, LP',
    name: 'ARARAT CAPITAL MANAGEMENT, LP',
    label: 'ARARAT CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0001080166',
    value: '0001080166,AR ASSET MANAGEMENT INC',
    name: 'AR ASSET MANAGEMENT INC',
    label: 'AR ASSET MANAGEMENT INC',
  },
  {
    cik: '0001601160',
    value: '0001601160,ARAVT GLOBAL LLC',
    name: 'ARAVT GLOBAL LLC',
    label: 'ARAVT GLOBAL LLC',
  },
  {
    cik: '0001513193',
    value: '0001513193,ARBITER PARTNERS CAPITAL MANAGEMENT LLC',
    name: 'ARBITER PARTNERS CAPITAL MANAGEMENT LLC',
    label: 'ARBITER PARTNERS CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001027570',
    value: '0001027570,ARBOR CAPITAL MANAGEMENT INC /ADV',
    name: 'ARBOR CAPITAL MANAGEMENT INC /ADV',
    label: 'ARBOR CAPITAL MANAGEMENT INC /ADV',
  },
  {
    cik: '0001055977',
    value: '0001055977,ARBOR CAPITAL MANAGEMENT LLC',
    name: 'ARBOR CAPITAL MANAGEMENT LLC',
    label: 'ARBOR CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001910112',
    value: '0001910112,ARBORETUM VENTURES, INC.',
    name: 'ARBORETUM VENTURES, INC.',
    label: 'ARBORETUM VENTURES, INC.',
  },
  {
    cik: '0001590073',
    value: '0001590073,ARBOR INVESTMENT ADVISORS, LLC',
    name: 'ARBOR INVESTMENT ADVISORS, LLC',
    label: 'ARBOR INVESTMENT ADVISORS, LLC',
  },
  {
    cik: '0001797135',
    value: '0001797135,ARBOR TRUST WEALTH ADVISORS, LLC',
    name: 'ARBOR TRUST WEALTH ADVISORS, LLC',
    label: 'ARBOR TRUST WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001667164',
    value: '0001667164,ARBOR WEALTH MANAGEMENT, LLC',
    name: 'ARBOR WEALTH MANAGEMENT, LLC',
    label: 'ARBOR WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001016972',
    value: '0001016972,ARCADIA INVESTMENT MANAGEMENT CORP/MI',
    name: 'ARCADIA INVESTMENT MANAGEMENT CORP/MI',
    label: 'ARCADIA INVESTMENT MANAGEMENT CORP/MI',
  },
  {
    cik: '0000947484',
    value: '0000947484,ARCH CAPITAL GROUP LTD.',
    name: 'ARCH CAPITAL GROUP LTD.',
    label: 'ARCH CAPITAL GROUP LTD.',
  },
  {
    cik: '0001499882',
    value: '0001499882,ARCHER CAPITAL MANAGEMENT, L.P.',
    name: 'ARCHER CAPITAL MANAGEMENT, L.P.',
    label: 'ARCHER CAPITAL MANAGEMENT, L.P.',
  },
  {
    cik: '0001803804',
    value: '0001803804,ARCHER INVESTMENT CORP',
    name: 'ARCHER INVESTMENT CORP',
    label: 'ARCHER INVESTMENT CORP',
  },
  {
    cik: '0001769089',
    value: '0001769089,ARCHETYPE WEALTH PARTNERS',
    name: 'ARCHETYPE WEALTH PARTNERS',
    label: 'ARCHETYPE WEALTH PARTNERS',
  },
  {
    cik: '0001606609',
    value: '0001606609,ARCHFORD CAPITAL STRATEGIES, LLC',
    name: 'ARCHFORD CAPITAL STRATEGIES, LLC',
    label: 'ARCHFORD CAPITAL STRATEGIES, LLC',
  },
  {
    cik: '0001536725',
    value: '0001536725,ARCHIPEL ASSET MANAGEMENT AB',
    name: 'ARCHIPEL ASSET MANAGEMENT AB',
    label: 'ARCHIPEL ASSET MANAGEMENT AB',
  },
  {
    cik: '0001398825',
    value: '0001398825,ARCHON CAPITAL MANAGEMENT LLC',
    name: 'ARCHON CAPITAL MANAGEMENT LLC',
    label: 'ARCHON CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001454424',
    value: '0001454424,ARCHON PARTNERS LLC',
    name: 'ARCHON PARTNERS LLC',
    label: 'ARCHON PARTNERS LLC',
  },
  {
    cik: '0001483472',
    value: '0001483472,ARCHPOINT INVESTORS',
    name: 'ARCHPOINT INVESTORS',
    label: 'ARCHPOINT INVESTORS',
  },
  {
    cik: '0001274403',
    value: '0001274403,ARCH VENTURE CORP',
    name: 'ARCH VENTURE CORP',
    label: 'ARCH VENTURE CORP',
  },
  {
    cik: '0001274403',
    value: '0001274403,ARCH VENTURE MANAGEMENT, LLC',
    name: 'ARCH VENTURE MANAGEMENT, LLC',
    label: 'ARCH VENTURE MANAGEMENT, LLC',
  },
  {
    cik: '0001910174',
    value: '0001910174,ARCHVEST WEALTH ADVISORS, INC.',
    name: 'ARCHVEST WEALTH ADVISORS, INC.',
    label: 'ARCHVEST WEALTH ADVISORS, INC.',
  },
  {
    cik: '0001502945',
    value: '0001502945,ARCLIGHT CAPITAL PARTNERS, LLC',
    name: 'ARCLIGHT CAPITAL PARTNERS, LLC',
    label: 'ARCLIGHT CAPITAL PARTNERS, LLC',
  },
  {
    cik: '0001831342',
    value: '0001831342,ARCTIS GLOBAL LLC',
    name: 'ARCTIS GLOBAL LLC',
    label: 'ARCTIS GLOBAL LLC',
  },
  {
    cik: '0001651960',
    value: '0001651960,ARCUS CAPITAL PARTNERS, LLC',
    name: 'ARCUS CAPITAL PARTNERS, LLC',
    label: 'ARCUS CAPITAL PARTNERS, LLC',
  },
  {
    cik: '0001888792',
    value: '0001888792,ARDENT CAPITAL MANAGEMENT, INC.',
    name: 'ARDENT CAPITAL MANAGEMENT, INC.',
    label: 'ARDENT CAPITAL MANAGEMENT, INC.',
  },
  {
    cik: '0001780607',
    value: '0001780607,ARDEN TRUST CO',
    name: 'ARDEN TRUST CO',
    label: 'ARDEN TRUST CO',
  },
  {
    cik: '0001608108',
    value: '0001608108,ARDEVORA ASSET MANAGEMENT LLP',
    name: 'ARDEVORA ASSET MANAGEMENT LLP',
    label: 'ARDEVORA ASSET MANAGEMENT LLP',
  },
  {
    cik: '0001929509',
    value: '0001929509,ARDIAN FRANCE SA',
    name: 'ARDIAN FRANCE SA',
    label: 'ARDIAN FRANCE SA',
  },
  {
    cik: '0001835730',
    value: '0001835730,ARDMORE ROAD ASSET MANAGEMENT LP',
    name: 'ARDMORE ROAD ASSET MANAGEMENT LP',
    label: 'ARDMORE ROAD ASSET MANAGEMENT LP',
  },
  {
    cik: '0000900529',
    value: '0000900529,ARDSLEY ADVISORY PARTNERS',
    name: 'ARDSLEY ADVISORY PARTNERS',
    label: 'ARDSLEY ADVISORY PARTNERS',
  },
  {
    cik: '0000900529',
    value: '0000900529,ARDSLEY ADVISORY PARTNERS LP',
    name: 'ARDSLEY ADVISORY PARTNERS LP',
    label: 'ARDSLEY ADVISORY PARTNERS LP',
  },
  {
    cik: '0001836264',
    value: '0001836264,AREION ASSET MANAGEMENT CO LTD',
    name: 'AREION ASSET MANAGEMENT CO LTD',
    label: 'AREION ASSET MANAGEMENT CO LTD',
  },
  {
    cik: '0001833078',
    value: '0001833078,ARENA CAPITAL ADVISORS, LLC- CA',
    name: 'ARENA CAPITAL ADVISORS, LLC- CA',
    label: 'ARENA CAPITAL ADVISORS, LLC- CA',
  },
  {
    cik: '0001699673',
    value: '0001699673,ARENA INVESTORS LP',
    name: 'ARENA INVESTORS LP',
    label: 'ARENA INVESTORS LP',
  },
  {
    cik: '0001259313',
    value: '0001259313,ARES MANAGEMENT LLC',
    name: 'ARES MANAGEMENT LLC',
    label: 'ARES MANAGEMENT LLC',
  },
  {
    cik: '0001600151',
    value: '0001600151,ARETE WEALTH ADVISORS, LLC',
    name: 'ARETE WEALTH ADVISORS, LLC',
    label: 'ARETE WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001800261',
    value: '0001800261,AREX CAPITAL MANAGEMENT, LP',
    name: 'AREX CAPITAL MANAGEMENT, LP',
    label: 'AREX CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0001556915',
    value: '0001556915,ARGA INVESTMENT MANAGEMENT, LP',
    name: 'ARGA INVESTMENT MANAGEMENT, LP',
    label: 'ARGA INVESTMENT MANAGEMENT, LP',
  },
  {
    cik: '0001426851',
    value: '0001426851,ARGENT ADVISORS, INC.',
    name: 'ARGENT ADVISORS, INC.',
    label: 'ARGENT ADVISORS, INC.',
  },
  {
    cik: '0001120926',
    value: '0001120926,ARGENT CAPITAL MANAGEMENT LLC',
    name: 'ARGENT CAPITAL MANAGEMENT LLC',
    label: 'ARGENT CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001585740',
    value: '0001585740,ARGENTIERE CAPITAL',
    name: 'ARGENTIERE CAPITAL',
    label: 'ARGENTIERE CAPITAL',
  },
  {
    cik: '0001625292',
    value: '0001625292,ARGENT TRUST CO',
    name: 'ARGENT TRUST CO',
    label: 'ARGENT TRUST CO',
  },
  {
    cik: '0001428242',
    value: '0001428242,ARGENT TRUST CO',
    name: 'ARGENT TRUST CO',
    label: 'ARGENT TRUST CO',
  },
  {
    cik: '0001167348',
    value: '0001167348,ARGENT TRUST COMPANY, N.A.',
    name: 'ARGENT TRUST COMPANY, N.A.',
    label: 'ARGENT TRUST COMPANY, N.A.',
  },
  {
    cik: '0001476417',
    value: '0001476417,ARGENTUS CAPITAL MANAGEMENT, LLC',
    name: 'ARGENTUS CAPITAL MANAGEMENT, LLC',
    label: 'ARGENTUS CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001801770',
    value: '0001801770,ARGENT WEALTH MANAGEMENT LLC',
    name: 'ARGENT WEALTH MANAGEMENT LLC',
    label: 'ARGENT WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0001519611',
    value: '0001519611,ARGI INVESTMENT SERVICES, LLC',
    name: 'ARGI INVESTMENT SERVICES, LLC',
    label: 'ARGI INVESTMENT SERVICES, LLC',
  },
  {
    cik: '0001512585',
    value: '0001512585,ARGONAUT CAPITAL MANAGEMENT CORP',
    name: 'ARGONAUT CAPITAL MANAGEMENT CORP',
    label: 'ARGONAUT CAPITAL MANAGEMENT CORP',
  },
  {
    cik: '0000007195',
    value: '0000007195,ARGUS INVESTORS COUNSEL, INC.',
    name: "ARGUS INVESTORS' COUNSEL, INC.",
    label: "ARGUS INVESTORS' COUNSEL, INC.",
  },
  {
    cik: '0001321194',
    value: '0001321194,ARGYLE CAPITAL MANAGEMENT INC.',
    name: 'ARGYLE CAPITAL MANAGEMENT INC.',
    label: 'ARGYLE CAPITAL MANAGEMENT INC.',
  },
  {
    cik: '0001330709',
    value: '0001330709,ARGYLL RESEARCH, LLC',
    name: 'ARGYLL RESEARCH, LLC',
    label: 'ARGYLL RESEARCH, LLC',
  },
  {
    cik: '0001732325',
    value: '0001732325,ARIAS RESOURCE CAPITAL GP LTD.',
    name: 'ARIAS RESOURCE CAPITAL GP LTD.',
    label: 'ARIAS RESOURCE CAPITAL GP LTD.',
  },
  {
    cik: '0001631738',
    value: '0001631738,ARIEL CAPITAL ADVISORS, LLC',
    name: 'ARIEL CAPITAL ADVISORS, LLC',
    label: 'ARIEL CAPITAL ADVISORS, LLC',
  },
  {
    cik: '0000936753',
    value: '0000936753,ARIEL INVESTMENTS, LLC',
    name: 'ARIEL INVESTMENTS, LLC',
    label: 'ARIEL INVESTMENTS, LLC',
  },
  {
    cik: '0001729515',
    value: '0001729515,ARIES WEALTH MANAGEMENT',
    name: 'ARIES WEALTH MANAGEMENT',
    label: 'ARIES WEALTH MANAGEMENT',
  },
  {
    cik: '0001665925',
    value: '0001665925,ARIOSE CAPITAL MANAGEMENT LTD',
    name: 'ARIOSE CAPITAL MANAGEMENT LTD',
    label: 'ARIOSE CAPITAL MANAGEMENT LTD',
  },
  {
    cik: '0001798367',
    value: '0001798367,ARISAIG PARTNERS (ASIA) PTE LTD.',
    name: 'ARISAIG PARTNERS (ASIA) PTE LTD.',
    label: 'ARISAIG PARTNERS (ASIA) PTE LTD.',
  },
  {
    cik: '0001080171',
    value: '0001080171,ARISTEIA CAPITAL LLC',
    name: 'ARISTEIA CAPITAL LLC',
    label: 'ARISTEIA CAPITAL LLC',
  },
  {
    cik: '0001595521',
    value: '0001595521,ARISTIDES CAPITAL LLC',
    name: 'ARISTIDES CAPITAL LLC',
    label: 'ARISTIDES CAPITAL LLC',
  },
  {
    cik: '0001697267',
    value: '0001697267,ARISTOTLE ATLANTIC PARTNERS, LLC',
    name: 'ARISTOTLE ATLANTIC PARTNERS, LLC',
    label: 'ARISTOTLE ATLANTIC PARTNERS, LLC',
  },
  {
    cik: '0001633911',
    value: '0001633911,ARISTOTLE CAPITAL BOSTON, LLC',
    name: 'ARISTOTLE CAPITAL BOSTON, LLC',
    label: 'ARISTOTLE CAPITAL BOSTON, LLC',
  },
  {
    cik: '0000860644',
    value: '0000860644,ARISTOTLE CAPITAL MANAGEMENT, LLC',
    name: 'ARISTOTLE CAPITAL MANAGEMENT, LLC',
    label: 'ARISTOTLE CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001425934',
    value: '0001425934,ARISTOTLE FUND LP',
    name: 'ARISTOTLE FUND LP',
    label: 'ARISTOTLE FUND LP',
  },
  {
    cik: '0001347935',
    value: '0001347935,ARIS WEALTH SERVICES, INC.',
    name: 'ARIS WEALTH SERVICES, INC.',
    label: 'ARIS WEALTH SERVICES, INC.',
  },
  {
    cik: '0001558481',
    value: '0001558481,ARIZONA STATE RETIREMENT SYSTEM',
    name: 'ARIZONA STATE RETIREMENT SYSTEM',
    label: 'ARIZONA STATE RETIREMENT SYSTEM',
  },
  {
    cik: '0001703208',
    value: '0001703208,ARJUNA CAPITAL',
    name: 'ARJUNA CAPITAL',
    label: 'ARJUNA CAPITAL',
  },
  {
    cik: '0001800798',
    value: '0001800798,ARKADIOS WEALTH ADVISORS',
    name: 'ARKADIOS WEALTH ADVISORS',
    label: 'ARKADIOS WEALTH ADVISORS',
  },
  {
    cik: '0000764611',
    value: '0000764611,ARKANSAS FINANCIAL GROUP, INC.',
    name: 'ARKANSAS FINANCIAL GROUP, INC.',
    label: 'ARKANSAS FINANCIAL GROUP, INC.',
  },
  {
    cik: '0001789382',
    value: '0001789382,ARKFELD WEALTH STRATEGIES, L.L.C.',
    name: 'ARKFELD WEALTH STRATEGIES, L.L.C.',
    label: 'ARKFELD WEALTH STRATEGIES, L.L.C.',
  },
  {
    cik: '0001726104',
    value: '0001726104,ARK GLOBAL EMERGING COMPANIES, LP',
    name: 'ARK GLOBAL EMERGING COMPANIES, LP',
    label: 'ARK GLOBAL EMERGING COMPANIES, LP',
  },
  {
    cik: '0001697748',
    value: '0001697748,ARK INVESTMENT MANAGEMENT LLC',
    name: 'ARK INVESTMENT MANAGEMENT LLC',
    label: 'ARK INVESTMENT MANAGEMENT LLC',
  },
  {
    cik: '0001769089',
    value: '0001769089,ARKOS GLOBAL ADVISORS',
    name: 'ARKOS GLOBAL ADVISORS',
    label: 'ARKOS GLOBAL ADVISORS',
  },
  {
    cik: '0001606507',
    value: '0001606507,ARLINGTON CAPITAL MANAGEMENT, INC.',
    name: 'ARLINGTON CAPITAL MANAGEMENT, INC.',
    label: 'ARLINGTON CAPITAL MANAGEMENT, INC.',
  },
  {
    cik: '0001716539',
    value: '0001716539,ARLINGTON FINANCIAL ADVISORS, LLC',
    name: 'ARLINGTON FINANCIAL ADVISORS, LLC',
    label: 'ARLINGTON FINANCIAL ADVISORS, LLC',
  },
  {
    cik: '0001389848',
    value: '0001389848,ARLINGTON PARTNERS LLC',
    name: 'ARLINGTON PARTNERS LLC',
    label: 'ARLINGTON PARTNERS LLC',
  },
  {
    cik: '0001568820',
    value: '0001568820,ARLINGTON VALUE CAPITAL, LLC',
    name: 'ARLINGTON VALUE CAPITAL, LLC',
    label: 'ARLINGTON VALUE CAPITAL, LLC',
  },
  {
    cik: '0001664847',
    value: '0001664847,ARMBRUSTER CAPITAL MANAGEMENT, INC.',
    name: 'ARMBRUSTER CAPITAL MANAGEMENT, INC.',
    label: 'ARMBRUSTER CAPITAL MANAGEMENT, INC.',
  },
  {
    cik: '0001601086',
    value: '0001601086,ARMISTICE CAPITAL, LLC',
    name: 'ARMISTICE CAPITAL, LLC',
    label: 'ARMISTICE CAPITAL, LLC',
  },
  {
    cik: '0001600698',
    value: '0001600698,ARMOR ADVISORS, L.L.C.',
    name: 'ARMOR ADVISORS, L.L.C.',
    label: 'ARMOR ADVISORS, L.L.C.',
  },
  {
    cik: '0001694079',
    value: '0001694079,ARMOR INVESTMENT ADVISORS, LLC',
    name: 'ARMOR INVESTMENT ADVISORS, LLC',
    label: 'ARMOR INVESTMENT ADVISORS, LLC',
  },
  {
    cik: '0001932952',
    value: '0001932952,ARMSTRONG ADVISORY GROUP, INC',
    name: 'ARMSTRONG ADVISORY GROUP, INC',
    label: 'ARMSTRONG ADVISORY GROUP, INC',
  },
  {
    cik: '0001056827',
    value: '0001056827,ARMSTRONG HENRY H ASSOCIATES INC',
    name: 'ARMSTRONG HENRY H ASSOCIATES INC',
    label: 'ARMSTRONG HENRY H ASSOCIATES INC',
  },
  {
    cik: '0001034550',
    value: '0001034550,ARMSTRONG SHAW ASSOCIATES INC/CT',
    name: 'ARMSTRONG SHAW ASSOCIATES INC/CT',
    label: 'ARMSTRONG SHAW ASSOCIATES INC/CT',
  },
  {
    cik: '0001733755',
    value: '0001733755,ARNHOLD LLC',
    name: 'ARNHOLD LLC',
    label: 'ARNHOLD LLC',
  },
  {
    cik: '0001596053',
    value: '0001596053,AROSA CAPITAL MANAGEMENT LP',
    name: 'AROSA CAPITAL MANAGEMENT LP',
    label: 'AROSA CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001650301',
    value: '0001650301,ARP AMERICAS LLC',
    name: 'ARP AMERICAS LLC',
    label: 'ARP AMERICAS LLC',
  },
  {
    cik: '0001811827',
    value: '0001811827,ARQ WEALTH ADVISORS, LLC',
    name: 'ARQ WEALTH ADVISORS, LLC',
    label: 'ARQ WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001389082',
    value: '0001389082,ARROW CAPITAL MANAGEMENT, LLC',
    name: 'ARROW CAPITAL MANAGEMENT, LLC',
    label: 'ARROW CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0000717538',
    value: '0000717538,ARROW FINANCIAL CORP',
    name: 'ARROW FINANCIAL CORP',
    label: 'ARROW FINANCIAL CORP',
  },
  {
    cik: '0001512754',
    value: '0001512754,ARROWGRASS CAPITAL PARTNERS LLP',
    name: 'ARROWGRASS CAPITAL PARTNERS LLP',
    label: 'ARROWGRASS CAPITAL PARTNERS LLP',
  },
  {
    cik: '0001454288',
    value: '0001454288,ARROWGRASS CAPITAL PARTNERS (US) LP',
    name: 'ARROWGRASS CAPITAL PARTNERS (US) LP',
    label: 'ARROWGRASS CAPITAL PARTNERS (US) LP',
  },
  {
    cik: '0001378410',
    value: '0001378410,ARROW INVESTMENT ADVISORS, LLC',
    name: 'ARROW INVESTMENT ADVISORS, LLC',
    label: 'ARROW INVESTMENT ADVISORS, LLC',
  },
  {
    cik: '0001483859',
    value: '0001483859,ARROWMARK COLORADO HOLDINGS LLC',
    name: 'ARROWMARK COLORADO HOLDINGS LLC',
    label: 'ARROWMARK COLORADO HOLDINGS LLC',
  },
  {
    cik: '0001164508',
    value: '0001164508,ARROWSTREET CAPITAL LIMITED PARTNERSHIP',
    name: 'ARROWSTREET CAPITAL LIMITED PARTNERSHIP',
    label: 'ARROWSTREET CAPITAL LIMITED PARTNERSHIP',
  },
  {
    cik: '0001164508',
    value: '0001164508,ARROWSTREET CAPITAL, LIMITED PARTNERSHIP',
    name: 'ARROWSTREET CAPITAL, LIMITED PARTNERSHIP',
    label: 'ARROWSTREET CAPITAL, LIMITED PARTNERSHIP',
  },
  {
    cik: '0000733020',
    value: '0000733020,A.R. SCHMEIDLER & CO., INC.',
    name: 'A.R. SCHMEIDLER & CO., INC.',
    label: 'A.R. SCHMEIDLER & CO., INC.',
  },
  {
    cik: '0000733020',
    value: '0000733020,ARS INVESTMENT PARTNERS, LLC',
    name: 'ARS INVESTMENT PARTNERS, LLC',
    label: 'ARS INVESTMENT PARTNERS, LLC',
  },
  {
    cik: '0001886707',
    value: '0001886707,ARS WEALTH ADVISORS GROUP, LLC',
    name: 'ARS WEALTH ADVISORS GROUP, LLC',
    label: 'ARS WEALTH ADVISORS GROUP, LLC',
  },
  {
    cik: '0001843747',
    value: '0001843747,ARS WEALTH ADVISORS, LLC',
    name: 'ARS WEALTH ADVISORS, LLC',
    label: 'ARS WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001315281',
    value: '0001315281,A.R.T. ADVISORS, LLC',
    name: 'A.R.T. ADVISORS, LLC',
    label: 'A.R.T. ADVISORS, LLC',
  },
  {
    cik: '0001053906',
    value: '0001053906,ARTAL GROUP S.A.',
    name: 'ARTAL GROUP S.A.',
    label: 'ARTAL GROUP S.A.',
  },
  {
    cik: '0001535385',
    value: '0001535385,ARTEMIS INVESTMENT MANAGEMENT LLP',
    name: 'ARTEMIS INVESTMENT MANAGEMENT LLP',
    label: 'ARTEMIS INVESTMENT MANAGEMENT LLP',
  },
  {
    cik: '0001767435',
    value: '0001767435,ARTEMIS WEALTH ADVISORS, LLC',
    name: 'ARTEMIS WEALTH ADVISORS, LLC',
    label: 'ARTEMIS WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001846784',
    value: '0001846784,ARTHA ASSET MANAGEMENT LLC',
    name: 'ARTHA ASSET MANAGEMENT LLC',
    label: 'ARTHA ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001311732',
    value: '0001311732,ARTHA CAPITAL MANAGEMENT, INC.',
    name: 'ARTHA CAPITAL MANAGEMENT, INC.',
    label: 'ARTHA CAPITAL MANAGEMENT, INC.',
  },
  {
    cik: '0001846503',
    value: '0001846503,ARTHEDGE CAPITAL MANAGEMENT, LLC',
    name: 'ARTHEDGE CAPITAL MANAGEMENT, LLC',
    label: 'ARTHEDGE CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001692252',
    value: '0001692252,ARTHUR M. COHEN & ASSOCIATES, LLC',
    name: 'ARTHUR M. COHEN & ASSOCIATES, LLC',
    label: 'ARTHUR M. COHEN & ASSOCIATES, LLC',
  },
  {
    cik: '0001689388',
    value: '0001689388,ARTHUR ZASKE & ASSOCIATES, LLC',
    name: 'ARTHUR ZASKE & ASSOCIATES, LLC',
    label: 'ARTHUR ZASKE & ASSOCIATES, LLC',
  },
  {
    cik: '0001466153',
    value: '0001466153,ARTISAN PARTNERS LIMITED PARTNERSHIP',
    name: 'ARTISAN PARTNERS LIMITED PARTNERSHIP',
    label: 'ARTISAN PARTNERS LIMITED PARTNERSHIP',
  },
  {
    cik: '0001279364',
    value: '0001279364,ARTIS CAPITAL MANAGEMENT, L.P.',
    name: 'ARTIS CAPITAL MANAGEMENT, L.P.',
    label: 'ARTIS CAPITAL MANAGEMENT, L.P.',
  },
  {
    cik: '0001625959',
    value: '0001625959,ARVEST BANK TRUST DIVISION',
    name: 'ARVEST BANK TRUST DIVISION',
    label: 'ARVEST BANK TRUST DIVISION',
  },
  {
    cik: '0000893879',
    value: '0000893879,ARVEST TRUST CO N A',
    name: 'ARVEST TRUST CO N A',
    label: 'ARVEST TRUST CO N A',
  },
  {
    cik: '0001614847',
    value: '0001614847,ARXIS SECURITIES LLC',
    name: 'ARXIS SECURITIES LLC',
    label: 'ARXIS SECURITIES LLC',
  },
  {
    cik: '0001803120',
    value: '0001803120,ARYEH CAPITAL MANAGEMENT LTD.',
    name: 'ARYEH CAPITAL MANAGEMENT LTD.',
    label: 'ARYEH CAPITAL MANAGEMENT LTD.',
  },
  {
    cik: '0001230869',
    value: '0001230869,ASA GOLD & PRECIOUS METALS LTD',
    name: 'ASA GOLD & PRECIOUS METALS LTD',
    label: 'ASA GOLD & PRECIOUS METALS LTD',
  },
  {
    cik: '0001564835',
    value: '0001564835,ASAHI LIFE ASSET MANAGEMENT CO., LTD.',
    name: 'ASAHI LIFE ASSET MANAGEMENT CO., LTD.',
    label: 'ASAHI LIFE ASSET MANAGEMENT CO., LTD.',
  },
  {
    cik: '0001941369',
    value: '0001941369,ASB CONSULTORES, LLC',
    name: 'ASB CONSULTORES, LLC',
    label: 'ASB CONSULTORES, LLC',
  },
  {
    cik: '0001808361',
    value: '0001808361,ASCENDANT CAPITAL MANAGEMENT, LLC',
    name: 'ASCENDANT CAPITAL MANAGEMENT, LLC',
    label: 'ASCENDANT CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001165501',
    value: '0001165501,ASCEND CAPITAL, LLC',
    name: 'ASCEND CAPITAL, LLC',
    label: 'ASCEND CAPITAL, LLC',
  },
  {
    cik: '0001714062',
    value: '0001714062,ASCENSION ASSET MANAGEMENT LLC',
    name: 'ASCENSION ASSET MANAGEMENT LLC',
    label: 'ASCENSION ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001689227',
    value: '0001689227,ASCENSION CAPITAL ADVISORS, INC.',
    name: 'ASCENSION CAPITAL ADVISORS, INC.',
    label: 'ASCENSION CAPITAL ADVISORS, INC.',
  },
  {
    cik: '0001913231',
    value: '0001913231,ASCENT GROUP, LLC',
    name: 'ASCENT GROUP, LLC',
    label: 'ASCENT GROUP, LLC',
  },
  {
    cik: '0001546865',
    value: '0001546865,ASCENT WEALTH PARTNERS, LLC',
    name: 'ASCENT WEALTH PARTNERS, LLC',
    label: 'ASCENT WEALTH PARTNERS, LLC',
  },
  {
    cik: '0001673022',
    value: '0001673022,ASCRIBE CAPITAL LLC',
    name: 'ASCRIBE CAPITAL LLC',
    label: 'ASCRIBE CAPITAL LLC',
  },
  {
    cik: '0001661222',
    value: '0001661222,ASHBURTON (JERSEY) LTD',
    name: 'ASHBURTON (JERSEY) LTD',
    label: 'ASHBURTON (JERSEY) LTD',
  },
  {
    cik: '0001632715',
    value: '0001632715,ASHE CAPITAL MANAGEMENT, LLC',
    name: 'ASHE CAPITAL MANAGEMENT, LLC',
    label: 'ASHE CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001632715',
    value: '0001632715,ASHE CAPITAL MANAGEMENT, LP',
    name: 'ASHE CAPITAL MANAGEMENT, LP',
    label: 'ASHE CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0001398103',
    value: '0001398103,ASHFIELD CAPITAL PARTNERS, LLC',
    name: 'ASHFIELD CAPITAL PARTNERS, LLC',
    label: 'ASHFIELD CAPITAL PARTNERS, LLC',
  },
  {
    cik: '0001861158',
    value: '0001861158,ASHFORD ADVISORS, LLC',
    name: 'ASHFORD ADVISORS, LLC',
    label: 'ASHFORD ADVISORS, LLC',
  },
  {
    cik: '0000897070',
    value: '0000897070,ASHFORD CAPITAL MANAGEMENT INC',
    name: 'ASHFORD CAPITAL MANAGEMENT INC',
    label: 'ASHFORD CAPITAL MANAGEMENT INC',
  },
  {
    cik: '0001616768',
    value: '0001616768,ASHFORD INVESTMENT MANAGEMENT, LLC',
    name: 'ASHFORD INVESTMENT MANAGEMENT, LLC',
    label: 'ASHFORD INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001734830',
    value: '0001734830,ASHLER CAPITAL LLC',
    name: 'ASHLER CAPITAL LLC',
    label: 'ASHLER CAPITAL LLC',
  },
  {
    cik: '0001512135',
    value: '0001512135,ASHMORE GROUP PLC',
    name: 'ASHMORE GROUP PLC',
    label: 'ASHMORE GROUP PLC',
  },
  {
    cik: '0001568887',
    value: '0001568887,ASHMORE WEALTH MANAGEMENT LLC',
    name: 'ASHMORE WEALTH MANAGEMENT LLC',
    label: 'ASHMORE WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0001451318',
    value: '0001451318,ASIAN CENTURY QUEST CAPITAL LLC',
    name: 'ASIAN CENTURY QUEST CAPITAL LLC',
    label: 'ASIAN CENTURY QUEST CAPITAL LLC',
  },
  {
    cik: '0001172401',
    value: '0001172401,ASIA PACIFIC ASSOCIATES III LTD',
    name: 'ASIA PACIFIC ASSOCIATES III LTD',
    label: 'ASIA PACIFIC ASSOCIATES III LTD',
  },
  {
    cik: '0001668453',
    value: '0001668453,ASIA RESEARCH & CAPITAL MANAGEMENT LTD',
    name: 'ASIA RESEARCH & CAPITAL MANAGEMENT LTD',
    label: 'ASIA RESEARCH & CAPITAL MANAGEMENT LTD',
  },
  {
    cik: '0001803291',
    value: '0001803291,ASIO CAPITAL, LLC',
    name: 'ASIO CAPITAL, LLC',
    label: 'ASIO CAPITAL, LLC',
  },
  {
    cik: '0001541596',
    value: '0001541596,ASPEN INVESTMENT MANAGEMENT INC',
    name: 'ASPEN INVESTMENT MANAGEMENT INC',
    label: 'ASPEN INVESTMENT MANAGEMENT INC',
  },
  {
    cik: '0001768375',
    value: '0001768375,ASPEX MANAGEMENT (HK) LTD',
    name: 'ASPEX MANAGEMENT (HK) LTD',
    label: 'ASPEX MANAGEMENT (HK) LTD',
  },
  {
    cik: '0001906014',
    value: '0001906014,ASPIRE CAPITAL ADVISORS LLC',
    name: 'ASPIRE CAPITAL ADVISORS LLC',
    label: 'ASPIRE CAPITAL ADVISORS LLC',
  },
  {
    cik: '0001719203',
    value: '0001719203,ASPIREON WEALTH ADVISORS',
    name: 'ASPIREON WEALTH ADVISORS',
    label: 'ASPIREON WEALTH ADVISORS',
  },
  {
    cik: '0001767340',
    value: '0001767340,ASPIRE PRIVATE CAPITAL, LLC',
    name: 'ASPIRE PRIVATE CAPITAL, LLC',
    label: 'ASPIRE PRIVATE CAPITAL, LLC',
  },
  {
    cik: '0001916412',
    value: '0001916412,ASPIRE WEALTH MANAGEMENT CORP',
    name: 'ASPIRE WEALTH MANAGEMENT CORP',
    label: 'ASPIRE WEALTH MANAGEMENT CORP',
  },
  {
    cik: '0001433541',
    value: '0001433541,ASPIRIANT, LLC',
    name: 'ASPIRIANT, LLC',
    label: 'ASPIRIANT, LLC',
  },
  {
    cik: '0001546007',
    value: '0001546007,ASSENAGON ASSET MANAGEMENT S.A.',
    name: 'ASSENAGON ASSET MANAGEMENT S.A.',
    label: 'ASSENAGON ASSET MANAGEMENT S.A.',
  },
  {
    cik: '0001054233',
    value: '0001054233,ASSET ADVISORS CORP',
    name: 'ASSET ADVISORS CORP',
    label: 'ASSET ADVISORS CORP',
  },
  {
    cik: '0001756695',
    value: '0001756695,ASSET ADVISORS INVESTMENT MANAGEMENT, LLC',
    name: 'ASSET ADVISORS INVESTMENT MANAGEMENT, LLC',
    label: 'ASSET ADVISORS INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001498383',
    value: '0001498383,ASSET ALLOCATION & MANAGEMENT COMPANY, LLC',
    name: 'ASSET ALLOCATION & MANAGEMENT COMPANY, LLC',
    label: 'ASSET ALLOCATION & MANAGEMENT COMPANY, LLC',
  },
  {
    cik: '0001475896',
    value: '0001475896,ASSET DEDICATION, LLC',
    name: 'ASSET DEDICATION, LLC',
    label: 'ASSET DEDICATION, LLC',
  },
  {
    cik: '0001596355',
    value: '0001596355,ASSET MANAGEMENT ADVISORS, LLC',
    name: 'ASSET MANAGEMENT ADVISORS, LLC',
    label: 'ASSET MANAGEMENT ADVISORS, LLC',
  },
  {
    cik: '0001107075',
    value: '0001107075,ASSET MANAGEMENT CORP /IL/ /ADV',
    name: 'ASSET MANAGEMENT CORP /IL/ /ADV',
    label: 'ASSET MANAGEMENT CORP /IL/ /ADV',
  },
  {
    cik: '0001511550',
    value: '0001511550,ASSET MANAGEMENT GROUP, INC.',
    name: 'ASSET MANAGEMENT GROUP, INC.',
    label: 'ASSET MANAGEMENT GROUP, INC.',
  },
  {
    cik: '0001068855',
    value: '0001068855,ASSET MANAGEMENT ONE CO., LTD.',
    name: 'ASSET MANAGEMENT ONE CO., LTD.',
    label: 'ASSET MANAGEMENT ONE CO., LTD.',
  },
  {
    cik: '0001927120',
    value: '0001927120,ASSET MANAGEMENT RESOURCES, LLC',
    name: 'ASSET MANAGEMENT RESOURCES, LLC',
    label: 'ASSET MANAGEMENT RESOURCES, LLC',
  },
  {
    cik: '0001344551',
    value: '0001344551,ASSETMARK, INC',
    name: 'ASSETMARK, INC',
    label: 'ASSETMARK, INC',
  },
  {
    cik: '0000007773',
    value: '0000007773,ASSET PLANNING CORPORATION',
    name: 'ASSET PLANNING CORPORATION',
    label: 'ASSET PLANNING CORPORATION',
  },
  {
    cik: '0000811407',
    value: '0000811407,ASSET PLANNING SERVICES INC /LA/ /ADV',
    name: 'ASSET PLANNING SERVICES INC /LA/ /ADV',
    label: 'ASSET PLANNING SERVICES INC /LA/ /ADV',
  },
  {
    cik: '0001694287',
    value: '0001694287,ASSET PLANNING SERVICES, LTD.',
    name: 'ASSET PLANNING SERVICES, LTD.',
    label: 'ASSET PLANNING SERVICES, LTD.',
  },
  {
    cik: '0000007789',
    value: '0000007789,ASSOCIATED BANC-CORP',
    name: 'ASSOCIATED BANC-CORP',
    label: 'ASSOCIATED BANC-CORP',
  },
  {
    cik: '0001427430',
    value: '0001427430,ASSURED INVESTMENT MANAGEMENT LLC',
    name: 'ASSURED INVESTMENT MANAGEMENT LLC',
    label: 'ASSURED INVESTMENT MANAGEMENT LLC',
  },
  {
    cik: '0001489226',
    value: '0001489226,ASTENBECK CAPITAL MANAGEMENT LLC',
    name: 'ASTENBECK CAPITAL MANAGEMENT LLC',
    label: 'ASTENBECK CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001386714',
    value: '0001386714,ASTON ASSET MANAGEMENT, LLC',
    name: 'ASTON ASSET MANAGEMENT, LLC',
    label: 'ASTON ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001931642',
    value: '0001931642,ASTORIA PORTFOLIO ADVISORS LLC.',
    name: 'ASTORIA PORTFOLIO ADVISORS LLC.',
    label: 'ASTORIA PORTFOLIO ADVISORS LLC.',
  },
  {
    cik: '0001777914',
    value: '0001777914,ASTORIA STRATEGIC WEALTH, INC.',
    name: 'ASTORIA STRATEGIC WEALTH, INC.',
    label: 'ASTORIA STRATEGIC WEALTH, INC.',
  },
  {
    cik: '0001598416',
    value: '0001598416,ASTOR INVESTMENT MANAGEMENT LLC',
    name: 'ASTOR INVESTMENT MANAGEMENT LLC',
    label: 'ASTOR INVESTMENT MANAGEMENT LLC',
  },
  {
    cik: '0001838912',
    value: '0001838912,ASTRANTUS LTD',
    name: 'ASTRANTUS LTD',
    label: 'ASTRANTUS LTD',
  },
  {
    cik: '0001641173',
    value: '0001641173,ASTURIAS CAPITAL LLC',
    name: 'ASTURIAS CAPITAL LLC',
    label: 'ASTURIAS CAPITAL LLC',
  },
  {
    cik: '0001657134',
    value: '0001657134,ASYMMETRY CAPITAL MANAGEMENT, L.P.',
    name: 'ASYMMETRY CAPITAL MANAGEMENT, L.P.',
    label: 'ASYMMETRY CAPITAL MANAGEMENT, L.P.',
  },
  {
    cik: '0001671122',
    value: '0001671122,ATAIROS GROUP, INC.',
    name: 'ATAIROS GROUP, INC.',
    label: 'ATAIROS GROUP, INC.',
  },
  {
    cik: '0001662906',
    value: '0001662906,ATALAN CAPITAL PARTNERS, LP',
    name: 'ATALAN CAPITAL PARTNERS, LP',
    label: 'ATALAN CAPITAL PARTNERS, LP',
  },
  {
    cik: '0000700529',
    value: '0000700529,ATALANTA SOSNOFF CAPITAL, LLC',
    name: 'ATALANTA SOSNOFF CAPITAL, LLC',
    label: 'ATALANTA SOSNOFF CAPITAL, LLC',
  },
  {
    cik: '0001812285',
    value: '0001812285,ATALAYA CAPITAL MANAGEMENT LP',
    name: 'ATALAYA CAPITAL MANAGEMENT LP',
    label: 'ATALAYA CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001506294',
    value: '000150629,AT BANCORP',
    name: 'AT BANCORP',
    label: 'AT BANCORP',
  },
  {
    cik: '0001638901',
    value: '0001638901,ATERNALIS CAPITAL LLC',
    name: 'ATERNALIS CAPITAL LLC',
    label: 'ATERNALIS CAPITAL LLC',
  },
  {
    cik: '0001730574',
    value: '0001730574,ATHANOR CAPITAL, LP',
    name: 'ATHANOR CAPITAL, LP',
    label: 'ATHANOR CAPITAL, LP',
  },
  {
    cik: '0001424480',
    value: '0001424480,ATHENA CAPITAL ADVISORS LLC',
    name: 'ATHENA CAPITAL ADVISORS LLC',
    label: 'ATHENA CAPITAL ADVISORS LLC',
  },
  {
    cik: '0001602999',
    value: '0001602999,ATHENA GLOBAL INVESTORS LLC',
    name: 'ATHENA GLOBAL INVESTORS LLC',
    label: 'ATHENA GLOBAL INVESTORS LLC',
  },
  {
    cik: '0001921093',
    value: '0001921093,ATHENA INVESTMENT MANAGEMENT',
    name: 'ATHENA INVESTMENT MANAGEMENT',
    label: 'ATHENA INVESTMENT MANAGEMENT',
  },
  {
    cik: '0001326196',
    value: '0001326196,ATHERTON LANE ADVISERS, LLC',
    name: 'ATHERTON LANE ADVISERS, LLC',
    label: 'ATHERTON LANE ADVISERS, LLC',
  },
  {
    cik: '0001681662',
    value: '0001681662,ATHOS CAPITAL LTD',
    name: 'ATHOS CAPITAL LTD',
    label: 'ATHOS CAPITAL LTD',
  },
  {
    cik: '0001728001',
    value: '0001728001,ATHYRIUM CAPITAL MANAGEMENT, LP',
    name: 'ATHYRIUM CAPITAL MANAGEMENT, LP',
    label: 'ATHYRIUM CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0001599731',
    value: '0001599731,ATIKA CAPITAL MANAGEMENT LLC',
    name: 'ATIKA CAPITAL MANAGEMENT LLC',
    label: 'ATIKA CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001423065',
    value: '0001423065,ATIVO CAPITAL MANAGEMENT LLC',
    name: 'ATIVO CAPITAL MANAGEMENT LLC',
    label: 'ATIVO CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001569071',
    value: '0001569071,ATLANTA CAPITAL GROUP',
    name: 'ATLANTA CAPITAL GROUP',
    label: 'ATLANTA CAPITAL GROUP',
  },
  {
    cik: '0001027817',
    value: '0001027817,ATLANTA CAPITAL MANAGEMENT CO L L C',
    name: 'ATLANTA CAPITAL MANAGEMENT CO L L C',
    label: 'ATLANTA CAPITAL MANAGEMENT CO L L C',
  },
  {
    cik: '0000909103',
    value: '0000909103,ATLANTA FINANCIAL ASSOCIATES LLC /ADV',
    name: 'ATLANTA FINANCIAL ASSOCIATES LLC /ADV',
    label: 'ATLANTA FINANCIAL ASSOCIATES LLC /ADV',
  },
  {
    cik: '0001419345',
    value: '0001419345,ATLANTIC INVESTMENT CO',
    name: 'ATLANTIC INVESTMENT CO',
    label: 'ATLANTIC INVESTMENT CO',
  },
  {
    cik: '0001063296',
    value: '0001063296,ATLANTIC INVESTMENT MANAGEMENT INC',
    name: 'ATLANTIC INVESTMENT MANAGEMENT INC',
    label: 'ATLANTIC INVESTMENT MANAGEMENT INC',
  },
  {
    cik: '0001063296',
    value: '0001063296,ATLANTIC INVESTMENT MANAGEMENT, INC.',
    name: 'ATLANTIC INVESTMENT MANAGEMENT, INC.',
    label: 'ATLANTIC INVESTMENT MANAGEMENT, INC.',
  },
  {
    cik: '0001893143',
    value: '0001893143,ATLANTIC PRIVATE WEALTH, LLC',
    name: 'ATLANTIC PRIVATE WEALTH, LLC',
    label: 'ATLANTIC PRIVATE WEALTH, LLC',
  },
  {
    cik: '0001298088',
    value: '0001298088,ATLANTIC TRUST GROUP, LLC',
    name: 'ATLANTIC TRUST GROUP, LLC',
    label: 'ATLANTIC TRUST GROUP, LLC',
  },
  {
    cik: '0001708001',
    value: '0001708001,ATLANTIC TRUST, LLC',
    name: 'ATLANTIC TRUST, LLC',
    label: 'ATLANTIC TRUST, LLC',
  },
  {
    cik: '0000883948',
    value: '0000883948,ATLANTIC UNION BANKSHARES CORP',
    name: 'ATLANTIC UNION BANKSHARES CORP',
    label: 'ATLANTIC UNION BANKSHARES CORP',
  },
  {
    cik: '0001388168',
    value: '0001388168,ATLAS BROWN,INC.',
    name: 'ATLAS BROWN,INC.',
    label: 'ATLAS BROWN,INC.',
  },
  {
    cik: '0001434165',
    value: '0001434165,ATLAS CAPITAL ADVISORS LLC',
    name: 'ATLAS CAPITAL ADVISORS LLC',
    label: 'ATLAS CAPITAL ADVISORS LLC',
  },
  {
    cik: '0001924030',
    value: '0001924030,ATLAS FINANCIAL ADVISORS, INC.',
    name: 'ATLAS FINANCIAL ADVISORS, INC.',
    label: 'ATLAS FINANCIAL ADVISORS, INC.',
  },
  {
    cik: '0001879898',
    value: '0001879898,ATLAS FRM LLC',
    name: 'ATLAS FRM LLC',
    label: 'ATLAS FRM LLC',
  },
  {
    cik: '0001924953',
    value: '0001924953,ATLAS INFRASTRUCTURE PARTNERS (UK) LTD.',
    name: 'ATLAS INFRASTRUCTURE PARTNERS (UK) LTD.',
    label: 'ATLAS INFRASTRUCTURE PARTNERS (UK) LTD.',
  },
  {
    cik: '0001757885',
    value: '0001757885,ATLAS PRINCIPALS, LLC',
    name: 'ATLAS PRINCIPALS, LLC',
    label: 'ATLAS PRINCIPALS, LLC',
  },
  {
    cik: '0001814234',
    value: '0001814234,ATLAS PRIVATE WEALTH ADVISORS',
    name: 'ATLAS PRIVATE WEALTH ADVISORS',
    label: 'ATLAS PRIVATE WEALTH ADVISORS',
  },
  {
    cik: '0001800369',
    value: '0001800369,ATLAS PRIVATE WEALTH MANAGEMENT',
    name: 'ATLAS PRIVATE WEALTH MANAGEMENT',
    label: 'ATLAS PRIVATE WEALTH MANAGEMENT',
  },
  {
    cik: '0001650372',
    value: '0001650372,ATLASSIAN CORP',
    name: 'ATLASSIAN CORP',
    label: 'ATLASSIAN CORP',
  },
  {
    cik: '0001650372',
    value: '0001650372,ATLASSIAN CORP PLC',
    name: 'ATLASSIAN CORP PLC',
    label: 'ATLASSIAN CORP PLC',
  },
  {
    cik: '0001767432',
    value: '0001767432,ATLAS VENTURE ADVISORS, INC.',
    name: 'ATLAS VENTURE ADVISORS, INC.',
    label: 'ATLAS VENTURE ADVISORS, INC.',
  },
  {
    cik: '0001671207',
    value: '0001671207,ATLAS VENTURE ASSOCIATES IX, LLC',
    name: 'ATLAS VENTURE ASSOCIATES IX, LLC',
    label: 'ATLAS VENTURE ASSOCIATES IX, LLC',
  },
  {
    cik: '0001780067',
    value: '0001780067,ATLAS VENTURE ASSOCIATES XI, LLC',
    name: 'ATLAS VENTURE ASSOCIATES XI, LLC',
    label: 'ATLAS VENTURE ASSOCIATES XI, LLC',
  },
  {
    cik: '0001917529',
    value: '0001917529,ATLAS VENTURE LIFE SCIENCE ADVISORS, LLC',
    name: 'ATLAS VENTURE LIFE SCIENCE ADVISORS, LLC',
    label: 'ATLAS VENTURE LIFE SCIENCE ADVISORS, LLC',
  },
  {
    cik: '0001832439',
    value: '0001832439,ATMOS CAPITAL GESTAO DE RECURSOS LTDA.',
    name: 'ATMOS CAPITAL GESTAO DE RECURSOS LTDA.',
    label: 'ATMOS CAPITAL GESTAO DE RECURSOS LTDA.',
  },
  {
    cik: '0001738902',
    value: '0001738902,ATOM INVESTORS LP',
    name: 'ATOM INVESTORS LP',
    label: 'ATOM INVESTORS LP',
  },
  {
    cik: '0001690675',
    value: '0001690675,ATOMVEST LTD',
    name: 'ATOMVEST LTD',
    label: 'ATOMVEST LTD',
  },
  {
    cik: '0001846445',
    value: '0001846445,ATONRA PARTNERS',
    name: 'ATONRA PARTNERS',
    label: 'ATONRA PARTNERS',
  },
  {
    cik: '0001593440',
    value: '0001593440,ATP LIFE SCIENCE VENTURES, L.P.',
    name: 'ATP LIFE SCIENCE VENTURES, L.P.',
    label: 'ATP LIFE SCIENCE VENTURES, L.P.',
  },
  {
    cik: '0001777813',
    value: '0001777813,ATREIDES MANAGEMENT, LP',
    name: 'ATREIDES MANAGEMENT, LP',
    label: 'ATREIDES MANAGEMENT, LP',
  },
  {
    cik: '0001535865',
    value: '0001535865,ATRIA INVESTMENTS LLC',
    name: 'ATRIA INVESTMENTS LLC',
    label: 'ATRIA INVESTMENTS LLC',
  },
  {
    cik: '0001927543',
    value: '0001927543,ATRIA WEALTH SOLUTIONS, INC.',
    name: 'ATRIA WEALTH SOLUTIONS, INC.',
    label: 'ATRIA WEALTH SOLUTIONS, INC.',
  },
  {
    cik: '0001760131',
    value: '0001760131,ATTESTOR CAPITAL LLP',
    name: 'ATTESTOR CAPITAL LLP',
    label: 'ATTESTOR CAPITAL LLP',
  },
  {
    cik: '0001800234',
    value: '0001800234,ATTICUS WEALTH MANAGEMENT, LLC',
    name: 'ATTICUS WEALTH MANAGEMENT, LLC',
    label: 'ATTICUS WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001769288',
    value: '0001769288,ATWATER MALICK LLC',
    name: 'ATWATER MALICK LLC',
    label: 'ATWATER MALICK LLC',
  },
  {
    cik: '0001044929',
    value: '0001044929,ATWOOD & PALMER INC',
    name: 'ATWOOD & PALMER INC',
    label: 'ATWOOD & PALMER INC',
  },
  {
    cik: '0001878495',
    value: '0001878495,ATW SPAC MANAGEMENT LLC',
    name: 'ATW SPAC MANAGEMENT LLC',
    label: 'ATW SPAC MANAGEMENT LLC',
  },
  {
    cik: '0001706915',
    value: '0001706915,AUA CAPITAL MANAGEMENT, LLC',
    name: 'AUA CAPITAL MANAGEMENT, LLC',
    label: 'AUA CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001730754',
    value: '0001730754,AUBREY CAPITAL MANAGEMENT LTD',
    name: 'AUBREY CAPITAL MANAGEMENT LTD',
    label: 'AUBREY CAPITAL MANAGEMENT LTD',
  },
  {
    cik: '0001834874',
    value: '0001834874,AUDENT GLOBAL ASSET MANAGEMENT, LLC',
    name: 'AUDENT GLOBAL ASSET MANAGEMENT, LLC',
    label: 'AUDENT GLOBAL ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001801573',
    value: '0001801573,AUFMAN ASSOCIATES INC',
    name: 'AUFMAN ASSOCIATES INC',
    label: 'AUFMAN ASSOCIATES INC',
  },
  {
    cik: '0001630529',
    value: '0001630529,AUGUST CAPITAL MANAGEMENT VII, L.L.C.',
    name: 'AUGUST CAPITAL MANAGEMENT VII, L.L.C.',
    label: 'AUGUST CAPITAL MANAGEMENT VII, L.L.C.',
  },
  {
    cik: '0001552638',
    value: '0001552638,AUGUST CAPITAL MANAGEMENT VI, L.L.C.',
    name: 'AUGUST CAPITAL MANAGEMENT VI, L.L.C.',
    label: 'AUGUST CAPITAL MANAGEMENT VI, L.L.C.',
  },
  {
    cik: '0001547399',
    value: '0001547399,AUGUST CAPITAL MANAGEMENT V, L.L.C.',
    name: 'AUGUST CAPITAL MANAGEMENT V, L.L.C.',
    label: 'AUGUST CAPITAL MANAGEMENT V, L.L.C.',
  },
  {
    cik: '0000778963',
    value: '0000778963,AUGUSTINE ASSET MANAGEMENT INC',
    name: 'AUGUSTINE ASSET MANAGEMENT INC',
    label: 'AUGUSTINE ASSET MANAGEMENT INC',
  },
  {
    cik: '0001387615',
    value: '0001387615,AULL & MONROE INVESTMENT MANAGEMENT CORP',
    name: 'AULL & MONROE INVESTMENT MANAGEMENT CORP',
    label: 'AULL & MONROE INVESTMENT MANAGEMENT CORP',
  },
  {
    cik: '0001362948',
    value: '0001362948,AURELIUS CAPITAL MANAGEMENT, LP',
    name: 'AURELIUS CAPITAL MANAGEMENT, LP',
    label: 'AURELIUS CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0001381296',
    value: '0001381296,AUREUS ASSET MANAGEMENT, LLC',
    name: 'AUREUS ASSET MANAGEMENT, LLC',
    label: 'AUREUS ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001731392',
    value: '0001731392,AURORA FINANCIAL ADVISORS, LLC',
    name: 'AURORA FINANCIAL ADVISORS, LLC',
    label: 'AURORA FINANCIAL ADVISORS, LLC',
  },
  {
    cik: '0001316397',
    value: '0001316397,AURORA INVESTMENT COUNSEL',
    name: 'AURORA INVESTMENT COUNSEL',
    label: 'AURORA INVESTMENT COUNSEL',
  },
  {
    cik: '0001567103',
    value: '0001567103,AURORA INVESTMENT MANAGEMENT L.L.C.',
    name: 'AURORA INVESTMENT MANAGEMENT L.L.C.',
    label: 'AURORA INVESTMENT MANAGEMENT L.L.C.',
  },
  {
    cik: '0001798485',
    value: '0001798485,AURORA INVESTMENT MANAGERS, LLC.',
    name: 'AURORA INVESTMENT MANAGERS, LLC.',
    label: 'AURORA INVESTMENT MANAGERS, LLC.',
  },
  {
    cik: '0001802132',
    value: '0001802132,AURORA PRIVATE WEALTH, INC.',
    name: 'AURORA PRIVATE WEALTH, INC.',
    label: 'AURORA PRIVATE WEALTH, INC.',
  },
  {
    cik: '0001784450',
    value: '0001784450,AURUM WEALTH MANAGEMENT GROUP, LLC',
    name: 'AURUM WEALTH MANAGEMENT GROUP, LLC',
    label: 'AURUM WEALTH MANAGEMENT GROUP, LLC',
  },
  {
    cik: '0001667146',
    value: '0001667146,AUSDAL FINANCIAL PARTNERS, INC.',
    name: 'AUSDAL FINANCIAL PARTNERS, INC.',
    label: 'AUSDAL FINANCIAL PARTNERS, INC.',
  },
  {
    cik: '0001902024',
    value: '0001902024,AUSTIN ASSET MANAGEMENT CO INC',
    name: 'AUSTIN ASSET MANAGEMENT CO INC',
    label: 'AUSTIN ASSET MANAGEMENT CO INC',
  },
  {
    cik: '0001800328',
    value: '0001800328,AUSTIN PRIVATE WEALTH, LLC',
    name: 'AUSTIN PRIVATE WEALTH, LLC',
    label: 'AUSTIN PRIVATE WEALTH, LLC',
  },
  {
    cik: '0001694164',
    value: '0001694164,AUSTRALIANSUPER PTY LTD',
    name: 'AUSTRALIANSUPER PTY LTD',
    label: 'AUSTRALIANSUPER PTY LTD',
  },
  {
    cik: '0001484153',
    value: '0001484153,AUTONOMY CAPITAL (JERSEY) L.P.',
    name: 'AUTONOMY CAPITAL (JERSEY) L.P.',
    label: 'AUTONOMY CAPITAL (JERSEY) L.P.',
  },
  {
    cik: '0001911621',
    value: '0001911621,AUTUMN GLORY PARTNERS, LLC',
    name: 'AUTUMN GLORY PARTNERS, LLC',
    label: 'AUTUMN GLORY PARTNERS, LLC',
  },
  {
    cik: '0001513865',
    value: '0001513865,AUTUS ASSET MANAGEMENT, LLC',
    name: 'AUTUS ASSET MANAGEMENT, LLC',
    label: 'AUTUS ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001811491',
    value: '0001811491,AUXANO ADVISORS, LLC',
    name: 'AUXANO ADVISORS, LLC',
    label: 'AUXANO ADVISORS, LLC',
  },
  {
    cik: '0001105863',
    value: '0001105863,AUXIER ASSET MANAGEMENT',
    name: 'AUXIER ASSET MANAGEMENT',
    label: 'AUXIER ASSET MANAGEMENT',
  },
  {
    cik: '0001904431',
    value: '0001904431,AVAII WEALTH MANAGEMENT, LLC',
    name: 'AVAII WEALTH MANAGEMENT, LLC',
    label: 'AVAII WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001221362',
    value: '0001221362,AVALON ADVISORS, LLC',
    name: 'AVALON ADVISORS, LLC',
    label: 'AVALON ADVISORS, LLC',
  },
  {
    cik: '0001802119',
    value: '0001802119,AVALON ADVISORY GROUP',
    name: 'AVALON ADVISORY GROUP',
    label: 'AVALON ADVISORY GROUP',
  },
  {
    cik: '0001314273',
    value: '0001314273,AVALON GLOBAL ASSET MANAGEMENT LLC',
    name: 'AVALON GLOBAL ASSET MANAGEMENT LLC',
    label: 'AVALON GLOBAL ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001221362',
    value: '0001221362,AVALON INVESTMENT & ADVISORY',
    name: 'AVALON INVESTMENT & ADVISORY',
    label: 'AVALON INVESTMENT & ADVISORY',
  },
  {
    cik: '0001303042',
    value: '0001303042,AVANTAX ADVISORY SERVICES, INC.',
    name: 'AVANTAX ADVISORY SERVICES, INC.',
    label: 'AVANTAX ADVISORY SERVICES, INC.',
  },
  {
    cik: '0001005441',
    value: '0001005441,AVANTAX PLANNING PARTNERS, INC.',
    name: 'AVANTAX PLANNING PARTNERS, INC.',
    label: 'AVANTAX PLANNING PARTNERS, INC.',
  },
  {
    cik: '0001653443',
    value: '0001653443,AVANT CAPITAL LLC',
    name: 'AVANT CAPITAL LLC',
    label: 'AVANT CAPITAL LLC',
  },
  {
    cik: '0001033475',
    value: '0001033475,AVENIR CORP',
    name: 'AVENIR CORP',
    label: 'AVENIR CORP',
  },
  {
    cik: '0001866419',
    value: '0001866419,AVENIR MANAGEMENT COMPANY, LLC',
    name: 'AVENIR MANAGEMENT COMPANY, LLC',
    label: 'AVENIR MANAGEMENT COMPANY, LLC',
  },
  {
    cik: '0001803822',
    value: '0001803822,AVENTAIL CAPITAL GROUP, LP',
    name: 'AVENTAIL CAPITAL GROUP, LP',
    label: 'AVENTAIL CAPITAL GROUP, LP',
  },
  {
    cik: '0001844375',
    value: '0001844375,AVENUE 1 ADVISORS, LLC',
    name: 'AVENUE 1 ADVISORS, LLC',
    label: 'AVENUE 1 ADVISORS, LLC',
  },
  {
    cik: '0001576584',
    value: '0001576584,AVEO CAPITAL PARTNERS, LLC',
    name: 'AVEO CAPITAL PARTNERS, LLC',
    label: 'AVEO CAPITAL PARTNERS, LLC',
  },
  {
    cik: '0001704404',
    value: '0001704404,AVESTAR CAPITAL, LLC',
    name: 'AVESTAR CAPITAL, LLC',
    label: 'AVESTAR CAPITAL, LLC',
  },
  {
    cik: '0001412575',
    value: '0001412575,AVIANCE CAPITAL MANAGEMENT, LLC',
    name: 'AVIANCE CAPITAL MANAGEMENT, LLC',
    label: 'AVIANCE CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001542383',
    value: '0001542383,AVIANCE CAPITAL PARTNERS, LLC',
    name: 'AVIANCE CAPITAL PARTNERS, LLC',
    label: 'AVIANCE CAPITAL PARTNERS, LLC',
  },
  {
    cik: '0001471474',
    value: '0001471474,AVIDIAN WEALTH SOLUTIONS, LLC',
    name: 'AVIDIAN WEALTH SOLUTIONS, LLC',
    label: 'AVIDIAN WEALTH SOLUTIONS, LLC',
  },
  {
    cik: '0001791827',
    value: '0001791827,AVIDITY PARTNERS MANAGEMENT LP',
    name: 'AVIDITY PARTNERS MANAGEMENT LP',
    label: 'AVIDITY PARTNERS MANAGEMENT LP',
  },
  {
    cik: '0001792704',
    value: '0001792704,AVION WEALTH',
    name: 'AVION WEALTH',
    label: 'AVION WEALTH',
  },
  {
    cik: '0001734565',
    value: '0001734565,AVITAS WEALTH MANAGEMENT LLC',
    name: 'AVITAS WEALTH MANAGEMENT LLC',
    label: 'AVITAS WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0000926834',
    value: '0000926834,AVITY INVESTMENT MANAGEMENT INC.',
    name: 'AVITY INVESTMENT MANAGEMENT INC.',
    label: 'AVITY INVESTMENT MANAGEMENT INC.',
  },
  {
    cik: '0001603935',
    value: '0001603935,AVIVA HOLDINGS LTD.',
    name: 'AVIVA HOLDINGS LTD.',
    label: 'AVIVA HOLDINGS LTD.',
  },
  {
    cik: '0001140022',
    value: '00011400,AVIVA PLC',
    name: 'AVIVA PLC',
    label: 'AVIVA PLC',
  },
  {
    cik: '0001738720',
    value: '0001738720,AVONDALE WEALTH MANAGEMENT',
    name: 'AVONDALE WEALTH MANAGEMENT',
    label: 'AVONDALE WEALTH MANAGEMENT',
  },
  {
    cik: '0001633313',
    value: '0001633313,AVORO CAPITAL ADVISORS LLC',
    name: 'AVORO CAPITAL ADVISORS LLC',
    label: 'AVORO CAPITAL ADVISORS LLC',
  },
  {
    cik: '0001842766',
    value: '0001842766,AVORY & COMPANY, LLC',
    name: 'AVORY & COMPANY, LLC',
    label: 'AVORY & COMPANY, LLC',
  },
  {
    cik: '0001250035',
    value: '0001250035,AV PARTNERS VIII LP',
    name: 'AV PARTNERS VIII LP',
    label: 'AV PARTNERS VIII LP',
  },
  {
    cik: '0001829985',
    value: '0001829985,AWARE ASSET MANAGEMENT, INC.',
    name: 'AWARE ASSET MANAGEMENT, INC.',
    label: 'AWARE ASSET MANAGEMENT, INC.',
  },
  {
    cik: '0001682475',
    value: '0001682475,AWH CAPITAL, L.P.',
    name: 'AWH CAPITAL, L.P.',
    label: 'AWH CAPITAL, L.P.',
  },
  {
    cik: '0001806820',
    value: '0001806820,AWM CAPITAL, LLC',
    name: 'AWM CAPITAL, LLC',
    label: 'AWM CAPITAL, LLC',
  },
  {
    cik: '0001535264',
    value: '0001535264,AWM INVESTMENT COMPANY, INC.',
    name: 'AWM INVESTMENT COMPANY, INC.',
    label: 'AWM INVESTMENT COMPANY, INC.',
  },
  {
    cik: '0000898427',
    value: '00,AXA898427',
    name: 'AXA',
    label: 'AXA',
  },
  {
    cik: '0001650781',
    value: '0001650781,AXAR CAPITAL MANAGEMENT L.P.',
    name: 'AXAR CAPITAL MANAGEMENT L.P.',
    label: 'AXAR CAPITAL MANAGEMENT L.P.',
  },
  {
    cik: '0000898427',
    value: '0000898,AXA S.A.7',
    name: 'AXA S.A.',
    label: 'AXA S.A.',
  },
  {
    cik: '0001478997',
    value: '0001478997,AXEL CAPITAL MANAGEMENT, LLC',
    name: 'AXEL CAPITAL MANAGEMENT, LLC',
    label: 'AXEL CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001351143',
    value: '0001351143,AXIAL CAPITAL MANAGEMENT, LLC',
    name: 'AXIAL CAPITAL MANAGEMENT, LLC',
    label: 'AXIAL CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001950607',
    value: '0001950607,AXIM PLANNING & WEALTH',
    name: 'AXIM PLANNING & WEALTH',
    label: 'AXIM PLANNING & WEALTH',
  },
  {
    cik: '0001910475',
    value: '0001910475,AXIOM ADVISORY, LLC',
    name: 'AXIOM ADVISORY, LLC',
    label: 'AXIOM ADVISORY, LLC',
  },
  {
    cik: '0001831985',
    value: '0001831985,AXIOM FINANCIAL STRATEGIES, LLC',
    name: 'AXIOM FINANCIAL STRATEGIES, LLC',
    label: 'AXIOM FINANCIAL STRATEGIES, LLC',
  },
  {
    cik: '0001109147',
    value: '0001109147,AXIOM INTERNATIONAL INVESTORS LLC /DE',
    name: 'AXIOM INTERNATIONAL INVESTORS LLC /DE',
    label: 'AXIOM INTERNATIONAL INVESTORS LLC /DE',
  },
  {
    cik: '0001513703',
    value: '0001513703,AXIOM INVESTMENT MANAGEMENT LLC',
    name: 'AXIOM INVESTMENT MANAGEMENT LLC',
    label: 'AXIOM INVESTMENT MANAGEMENT LLC',
  },
  {
    cik: '0001109147',
    value: '0001109147,AXIOM INVESTORS LLC /DE',
    name: 'AXIOM INVESTORS LLC /DE',
    label: 'AXIOM INVESTORS LLC /DE',
  },
  {
    cik: '0001214816',
    value: '0001214816,AXIS CAPITAL HOLDINGS LTD',
    name: 'AXIS CAPITAL HOLDINGS LTD',
    label: 'AXIS CAPITAL HOLDINGS LTD',
  },
  {
    cik: '0001508755',
    value: '0001508755,AXON CAPITAL LP',
    name: 'AXON CAPITAL LP',
    label: 'AXON CAPITAL LP',
  },
  {
    cik: '0001801467',
    value: '0001801467,AXS INVESTMENTS LLC',
    name: 'AXS INVESTMENTS LLC',
    label: 'AXS INVESTMENTS LLC',
  },
  {
    cik: '0001911056',
    value: '0001911056,AXXCESS WEALTH MANAGEMENT, LLC',
    name: 'AXXCESS WEALTH MANAGEMENT, LLC',
    label: 'AXXCESS WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001642216',
    value: '0001642216,AYALON HOLDINGS LTD.',
    name: 'AYALON HOLDINGS LTD.',
    label: 'AYALON HOLDINGS LTD.',
  },
  {
    cik: '0001642216',
    value: '0001642216,AYALON INSURANCE COMP LTD.',
    name: 'AYALON INSURANCE COMP LTD.',
    label: 'AYALON INSURANCE COMP LTD.',
  },
  {
    cik: '0001471149',
    value: '0001471149,AYER CAPITAL MANAGEMENT, LP',
    name: 'AYER CAPITAL MANAGEMENT, LP',
    label: 'AYER CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0001513455',
    value: '0001513455,AYM CAPITAL, LLC',
    name: 'AYM CAPITAL, LLC',
    label: 'AYM CAPITAL, LLC',
  },
  {
    cik: '0001801563',
    value: '0001801563,AYRSHIRE CAPITAL MANAGEMENT LLC',
    name: 'AYRSHIRE CAPITAL MANAGEMENT LLC',
    label: 'AYRSHIRE CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001698148',
    value: '0001698148,AYRTON CAPITAL LLC',
    name: 'AYRTON CAPITAL LLC',
    label: 'AYRTON CAPITAL LLC',
  },
  {
    cik: '0001625279',
    value: '0001625279,AZARIAS CAPITAL MANAGEMENT, L.P.',
    name: 'AZARIAS CAPITAL MANAGEMENT, L.P.',
    label: 'AZARIAS CAPITAL MANAGEMENT, L.P.',
  },
  {
    cik: '0001541900',
    value: '0001541900,AZENTUS CAPITAL MANAGEMENT LTD',
    name: 'AZENTUS CAPITAL MANAGEMENT LTD',
    label: 'AZENTUS CAPITAL MANAGEMENT LTD',
  },
  {
    cik: '0001942364',
    value: '0001942364,AZIMUTH CAPITAL INVESTMENT MANAGEMENT LLC',
    name: 'AZIMUTH CAPITAL INVESTMENT MANAGEMENT LLC',
    label: 'AZIMUTH CAPITAL INVESTMENT MANAGEMENT LLC',
  },
  {
    cik: '0001352512',
    value: '0001352512,AZIMUTH CAPITAL MANAGEMENT LLC',
    name: 'AZIMUTH CAPITAL MANAGEMENT LLC',
    label: 'AZIMUTH CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001700272',
    value: '0001700272,AZORA CAPITAL LP',
    name: 'AZORA CAPITAL LP',
    label: 'AZORA CAPITAL LP',
  },
  {
    cik: '0001542629',
    value: '0001542629,BAADER BANK AKTIENGESELLSCHAFT',
    name: 'BAADER BANK AKTIENGESELLSCHAFT',
    label: 'BAADER BANK AKTIENGESELLSCHAFT',
  },
  {
    cik: '0001542629',
    value: '0001542629,BAADER BANK INC',
    name: 'BAADER BANK INC',
    label: 'BAADER BANK INC',
  },
  {
    cik: '0000009015',
    value: '0000009015,BABSON CAPITAL MANAGEMENT LLC',
    name: 'BABSON CAPITAL MANAGEMENT LLC',
    label: 'BABSON CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001667391',
    value: '0001667391,BACH INVESTMENT ADVISORS LTD',
    name: 'BACH INVESTMENT ADVISORS LTD',
    label: 'BACH INVESTMENT ADVISORS LTD',
  },
  {
    cik: '0000915325',
    value: '0000915325,BADGLEY PHELPS & BELL INC',
    name: 'BADGLEY PHELPS & BELL INC',
    label: 'BADGLEY PHELPS & BELL INC',
  },
  {
    cik: '0001895362',
    value: '0001895362,BADGLEY PHELPS WEALTH MANAGERS, LLC',
    name: 'BADGLEY PHELPS WEALTH MANAGERS, LLC',
    label: 'BADGLEY PHELPS WEALTH MANAGERS, LLC',
  },
  {
    cik: '0000872259',
    value: '0000872259,BAHL & GAYNOR INC',
    name: 'BAHL & GAYNOR INC',
    label: 'BAHL & GAYNOR INC',
  },
  {
    cik: '0000750641',
    value: '0000750641,BAILARD, INC.',
    name: 'BAILARD, INC.',
    label: 'BAILARD, INC.',
  },
  {
    cik: '0001266850',
    value: '0001266850,BAILEY VALUE LIMITED PARTNERSHIP',
    name: 'BAILEY VALUE LIMITED PARTNERSHIP',
    label: 'BAILEY VALUE LIMITED PARTNERSHIP',
  },
  {
    cik: '0001088875',
    value: '0001088875,BAILLIE GIFFORD & CO',
    name: 'BAILLIE GIFFORD & CO',
    label: 'BAILLIE GIFFORD & CO',
  },
  {
    cik: '0001317947',
    value: '0001317947,BAIN CAPITAL CREDIT, LP',
    name: 'BAIN CAPITAL CREDIT, LP',
    label: 'BAIN CAPITAL CREDIT, LP',
  },
  {
    cik: '0001309111',
    value: '0001309111,BAIN CAPITAL CREDIT MEMBER, LLC',
    name: 'BAIN CAPITAL CREDIT MEMBER, LLC',
    label: 'BAIN CAPITAL CREDIT MEMBER, LLC',
  },
  {
    cik: '0001040508',
    value: '0001040508,BAIN CAPITAL INVESTORS LLC',
    name: 'BAIN CAPITAL INVESTORS LLC',
    label: 'BAIN CAPITAL INVESTORS LLC',
  },
  {
    cik: '0001703031',
    value: '0001703031,BAIN CAPITAL LIFE SCIENCES INVESTORS, LLC',
    name: 'BAIN CAPITAL LIFE SCIENCES INVESTORS, LLC',
    label: 'BAIN CAPITAL LIFE SCIENCES INVESTORS, LLC',
  },
  {
    cik: '0001768111',
    value: '0001768111,BAIN CAPITAL PUBLIC EQUITY MANAGEMENT II, LLC',
    name: 'BAIN CAPITAL PUBLIC EQUITY MANAGEMENT II, LLC',
    label: 'BAIN CAPITAL PUBLIC EQUITY MANAGEMENT II, LLC',
  },
  {
    cik: '0001156175',
    value: '0001156175,BAIN CAPITAL PUBLIC EQUITY MANAGEMENT, LLC',
    name: 'BAIN CAPITAL PUBLIC EQUITY MANAGEMENT, LLC',
    label: 'BAIN CAPITAL PUBLIC EQUITY MANAGEMENT, LLC',
  },
  {
    cik: '0001309469',
    value: '0001309469,BAIN CAPITAL VENTURE INVESTORS, LLC',
    name: 'BAIN CAPITAL VENTURE INVESTORS, LLC',
    label: 'BAIN CAPITAL VENTURE INVESTORS, LLC',
  },
  {
    cik: '0001074027',
    value: '0001074027,BAINCO INTERNATIONAL INVESTORS',
    name: 'BAINCO INTERNATIONAL INVESTORS',
    label: 'BAINCO INTERNATIONAL INVESTORS',
  },
  {
    cik: '0001676601',
    value: '0001676601,BAINES CREEK CAPITAL, LLC',
    name: 'BAINES CREEK CAPITAL, LLC',
    label: 'BAINES CREEK CAPITAL, LLC',
  },
  {
    cik: '0001648711',
    value: '0001648711,BAIRD FINANCIAL GROUP, INC.',
    name: 'BAIRD FINANCIAL GROUP, INC.',
    label: 'BAIRD FINANCIAL GROUP, INC.',
  },
  {
    cik: '0000810972',
    value: '0000810972,BAIRD ROBERT W & CO INC /WI/',
    name: 'BAIRD ROBERT W & CO INC /WI/',
    label: 'BAIRD ROBERT W & CO INC /WI/',
  },
  {
    cik: '0001432529',
    value: '0001432529,BAKER AVENUE ASSET MANAGEMENT, LP',
    name: 'BAKER AVENUE ASSET MANAGEMENT, LP',
    label: 'BAKER AVENUE ASSET MANAGEMENT, LP',
  },
  {
    cik: '0001079398',
    value: '0001079398,BAKER BOYER NATIONAL BANK',
    name: 'BAKER BOYER NATIONAL BANK',
    label: 'BAKER BOYER NATIONAL BANK',
  },
  {
    cik: '0001263508',
    value: '0001263508,BAKER BROS. ADVISORS LP',
    name: 'BAKER BROS. ADVISORS LP',
    label: 'BAKER BROS. ADVISORS LP',
  },
  {
    cik: '0001666470',
    value: '0001666470,BAKER CHAD R',
    name: 'BAKER CHAD R',
    label: 'BAKER CHAD R',
  },
  {
    cik: '0001365559',
    value: '0001365559,BAKER ELLIS ASSET MANAGEMENT LLC',
    name: 'BAKER ELLIS ASSET MANAGEMENT LLC',
    label: 'BAKER ELLIS ASSET MANAGEMENT LLC',
  },
  {
    cik: '0000808362',
    value: '0000808362,BAKER HUGHES HOLDINGS LLC',
    name: 'BAKER HUGHES HOLDINGS LLC',
    label: 'BAKER HUGHES HOLDINGS LLC',
  },
  {
    cik: '0001488207',
    value: '0001488207,BAKER STREET CAPITAL MANAGEMENT, LLC',
    name: 'BAKER STREET CAPITAL MANAGEMENT, LLC',
    label: 'BAKER STREET CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001840565',
    value: '0001840565,BAKER TILLY FINANCIAL, LLC',
    name: 'BAKER TILLY FINANCIAL, LLC',
    label: 'BAKER TILLY FINANCIAL, LLC',
  },
  {
    cik: '0001840565',
    value: '0001840565,BAKER TILLY WEALTH MANAGEMENT, LLC',
    name: 'BAKER TILLY WEALTH MANAGEMENT, LLC',
    label: 'BAKER TILLY WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001308757',
    value: '0001308757,BALASA DINVERNO & FOLTZ LLC',
    name: 'BALASA DINVERNO & FOLTZ LLC',
    label: 'BALASA DINVERNO & FOLTZ LLC',
  },
  {
    cik: '0001929008',
    value: '0001929008,BALBOA WEALTH PARTNERS',
    name: 'BALBOA WEALTH PARTNERS',
    label: 'BALBOA WEALTH PARTNERS',
  },
  {
    cik: '0001130337',
    value: '0001130337,BALCH HILL CAPITAL LLC',
    name: 'BALCH HILL CAPITAL LLC',
    label: 'BALCH HILL CAPITAL LLC',
  },
  {
    cik: '0001761742',
    value: '0001761742,BALDRIGE ASSET MANAGEMENT LLC',
    name: 'BALDRIGE ASSET MANAGEMENT LLC',
    label: 'BALDRIGE ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001021258',
    value: '0001021258,BALDWIN BROTHERS INC/MA',
    name: 'BALDWIN BROTHERS INC/MA',
    label: 'BALDWIN BROTHERS INC/MA',
  },
  {
    cik: '0001021258',
    value: '0001021258,BALDWIN BROTHERS LLC/MA',
    name: 'BALDWIN BROTHERS LLC/MA',
    label: 'BALDWIN BROTHERS LLC/MA',
  },
  {
    cik: '0001346378',
    value: '0001346378,BALDWIN INVESTMENT MANAGEMENT, LLC',
    name: 'BALDWIN INVESTMENT MANAGEMENT, LLC',
    label: 'BALDWIN INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001586678',
    value: '0001586678,BALENTINE LLC',
    name: 'BALENTINE LLC',
    label: 'BALENTINE LLC',
  },
  {
    cik: '0001007446',
    value: '0001007446,BALESTRA CAPITAL LTD.',
    name: 'BALESTRA CAPITAL LTD.',
    label: 'BALESTRA CAPITAL LTD.',
  },
  {
    cik: '0001803415',
    value: '0001803415,BALLAST ADVISORS LLC',
    name: 'BALLAST ADVISORS LLC',
    label: 'BALLAST ADVISORS LLC',
  },
  {
    cik: '0001730477',
    value: '0001730477,BALLAST, INC.',
    name: 'BALLAST, INC.',
    label: 'BALLAST, INC.',
  },
  {
    cik: '0001374889',
    value: '0001374889,BALLENTINE PARTNERS, LLC',
    name: 'BALLENTINE PARTNERS, LLC',
    label: 'BALLENTINE PARTNERS, LLC',
  },
  {
    cik: '0001729096',
    value: '0001729096,BALLEW ADVISORS, INC',
    name: 'BALLEW ADVISORS, INC',
    label: 'BALLEW ADVISORS, INC',
  },
  {
    cik: '0001592450',
    value: '0001592450,BALTER LIQUID ALTERNATIVES, LLC',
    name: 'BALTER LIQUID ALTERNATIVES, LLC',
    label: 'BALTER LIQUID ALTERNATIVES, LLC',
  },
  {
    cik: '0001351363',
    value: '0001351363,BALTIMORE CAPITAL MANAGEMENT, INC',
    name: 'BALTIMORE CAPITAL MANAGEMENT, INC',
    label: 'BALTIMORE CAPITAL MANAGEMENT, INC',
  },
  {
    cik: '0001555486',
    value: '0001555486,BALTIMORE-WASHINGTON FINANCIAL ADVISORS, INC.',
    name: 'BALTIMORE-WASHINGTON FINANCIAL ADVISORS, INC.',
    label: 'BALTIMORE-WASHINGTON FINANCIAL ADVISORS, INC.',
  },
  {
    cik: '0001218710',
    value: '0001218710,BALYASNY ASSET MANAGEMENT LLC',
    name: 'BALYASNY ASSET MANAGEMENT LLC',
    label: 'BALYASNY ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001017918',
    value: '0001017918,BAMCO INC /NY/',
    name: 'BAMCO INC /NY/',
    label: 'BAMCO INC /NY/',
  },
  {
    cik: '0001622093',
    value: '0001622093,BANBURY PARTNERS LLC',
    name: 'BANBURY PARTNERS LLC',
    label: 'BANBURY PARTNERS LLC',
  },
  {
    cik: '0001272345',
    value: '0001272345,BANCED CORP',
    name: 'BANCED CORP',
    label: 'BANCED CORP',
  },
  {
    cik: '0001914099',
    value: '0001914099,BANCFIRST TRUST & INVESTMENT MANAGEMENT',
    name: 'BANCFIRST TRUST & INVESTMENT MANAGEMENT',
    label: 'BANCFIRST TRUST & INVESTMENT MANAGEMENT',
  },
  {
    cik: '0001029311',
    value: '0001029311,BANC FUNDS CO LLC',
    name: 'BANC FUNDS CO LLC',
    label: 'BANC FUNDS CO LLC',
  },
  {
    cik: '0000842180',
    value: '0000842180,BANCO BILBAO VIZCAYA ARGENTARIA, S.A.',
    name: 'BANCO BILBAO VIZCAYA ARGENTARIA, S.A.',
    label: 'BANCO BILBAO VIZCAYA ARGENTARIA, S.A.',
  },
  {
    cik: '0001667109',
    value: '0001667109,BANCO BTG PACTUAL SA',
    name: 'BANCO BTG PACTUAL SA',
    label: 'BANCO BTG PACTUAL SA',
  },
  {
    cik: '0001536080',
    value: '0001536080,BANCO BTG PACTUAL S.A.',
    name: 'BANCO BTG PACTUAL S.A.',
    label: 'BANCO BTG PACTUAL S.A.',
  },
  {
    cik: '0001730299',
    value: '0001730299,BANCO DE SABADELL, S.A',
    name: 'BANCO DE SABADELL, S.A',
    label: 'BANCO DE SABADELL, S.A',
  },
  {
    cik: '0001299939',
    value: '0001299939,BANCORPSOUTH BANK',
    name: 'BANCORPSOUTH BANK',
    label: 'BANCORPSOUTH BANK',
  },
  {
    cik: '0000701853',
    value: '0000701853,BANCORPSOUTH INC',
    name: 'BANCORPSOUTH INC',
    label: 'BANCORPSOUTH INC',
  },
  {
    cik: '0000891478',
    value: '0000891478,BANCO SANTANDER, S.A.',
    name: 'BANCO SANTANDER, S.A.',
    label: 'BANCO SANTANDER, S.A.',
  },
  {
    cik: '0001852042',
    value: '0001852042,BANCREEK CAPITAL MANAGEMENT, LP',
    name: 'BANCREEK CAPITAL MANAGEMENT, LP',
    label: 'BANCREEK CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0001399386',
    value: '0001399386,BANDERA PARTNERS LLC',
    name: 'BANDERA PARTNERS LLC',
    label: 'BANDERA PARTNERS LLC',
  },
  {
    cik: '0001352675',
    value: '0001352675,BANGOR SAVINGS BANK',
    name: 'BANGOR SAVINGS BANK',
    label: 'BANGOR SAVINGS BANK',
  },
  {
    cik: '0000811393',
    value: '0000811393,BANKERS TRUST CO',
    name: 'BANKERS TRUST CO',
    label: 'BANKERS TRUST CO',
  },
  {
    cik: '0001280281',
    value: '0001280281,BANKERS TRUST CO CEDAR RAPIDS',
    name: 'BANKERS TRUST CO CEDAR RAPIDS',
    label: 'BANKERS TRUST CO CEDAR RAPIDS',
  },
  {
    cik: '0001161914',
    value: '0001161914,BANKERS TRUST CO OF SOUTH DAKOTA',
    name: 'BANKERS TRUST CO OF SOUTH DAKOTA',
    label: 'BANKERS TRUST CO OF SOUTH DAKOTA',
  },
  {
    cik: '0000902528',
    value: '0000902528,BANK HAPOALIM BM',
    name: 'BANK HAPOALIM BM',
    label: 'BANK HAPOALIM BM',
  },
  {
    cik: '0001544599',
    value: '0001544599,BANK JULIUS BAER & CO. LTD, ZURICH',
    name: 'BANK JULIUS BAER & CO. LTD, ZURICH',
    label: 'BANK JULIUS BAER & CO. LTD, ZURICH',
  },
  {
    cik: '0000070858',
    value: '0000070858,BANK OF AMERICA CORP /DE/',
    name: 'BANK OF AMERICA CORP /DE/',
    label: 'BANK OF AMERICA CORP /DE/',
  },
  {
    cik: '0001272346',
    value: '0001272346,BANK OF EDWARDSVILLE',
    name: 'BANK OF EDWARDSVILLE',
    label: 'BANK OF EDWARDSVILLE',
  },
  {
    cik: '0001844891',
    value: '0001844891,BANK OF FINLAND',
    name: 'BANK OF FINLAND',
    label: 'BANK OF FINLAND',
  },
  {
    cik: '0000315080',
    value: '0000315080,BANK OF HAWAII',
    name: 'BANK OF HAWAII',
    label: 'BANK OF HAWAII',
  },
  {
    cik: '0001426449',
    value: '0001426449,BANK OF ITALY',
    name: 'BANK OF ITALY',
    label: 'BANK OF ITALY',
  },
  {
    cik: '0001492166',
    value: '0001492166,BANK OF KENTUCKY, INC.',
    name: 'BANK OF KENTUCKY, INC.',
    label: 'BANK OF KENTUCKY, INC.',
  },
  {
    cik: '0001801145',
    value: '0001801145,BANK OF MARIN',
    name: 'BANK OF MARIN',
    label: 'BANK OF MARIN',
  },
  {
    cik: '0000927971',
    value: '0000927971,BANK OF MONTREAL /CAN/',
    name: 'BANK OF MONTREAL /CAN/',
    label: 'BANK OF MONTREAL /CAN/',
  },
  {
    cik: '0001870761',
    value: '0001870761,BANK OF NEW HAMPSHIRE',
    name: 'BANK OF NEW HAMPSHIRE',
    label: 'BANK OF NEW HAMPSHIRE',
  },
  {
    cik: '0001390777',
    value: '0001390777,BANK OF NEW YORK MELLON CORP',
    name: 'BANK OF NEW YORK MELLON CORP',
    label: 'BANK OF NEW YORK MELLON CORP',
  },
  {
    cik: '0000009631',
    value: '0000009631,BANK OF NOVA SCOTIA',
    name: 'BANK OF NOVA SCOTIA',
    label: 'BANK OF NOVA SCOTIA',
  },
  {
    cik: '0001335382',
    value: '0001335382,BANK OF NOVA SCOTIA TRUST CO',
    name: 'BANK OF NOVA SCOTIA TRUST CO',
    label: 'BANK OF NOVA SCOTIA TRUST CO',
  },
  {
    cik: '0001387723',
    value: '0001387723,BANK OF STOCKTON',
    name: 'BANK OF STOCKTON',
    label: 'BANK OF STOCKTON',
  },
  {
    cik: '0001569650',
    value: '0001569650,BANK OF THE OZARKS',
    name: 'BANK OF THE OZARKS',
    label: 'BANK OF THE OZARKS',
  },
  {
    cik: '0000059951',
    value: '0000059951,BANK OF THE WEST',
    name: 'BANK OF THE WEST',
    label: 'BANK OF THE WEST',
  },
  {
    cik: '0000852743',
    value: '0000852743,BANK OF TOKYO - MITSUBISHI UFJ, LTD',
    name: 'BANK OF TOKYO - MITSUBISHI UFJ, LTD',
    label: 'BANK OF TOKYO - MITSUBISHI UFJ, LTD',
  },
  {
    cik: '0001569650',
    value: '0001569,BANK OZK0',
    name: 'BANK OZK',
    label: 'BANK OZK',
  },
  {
    cik: '0001599469',
    value: '0001599469,BANK PICTET & CIE (ASIA) LTD',
    name: 'BANK PICTET & CIE (ASIA) LTD',
    label: 'BANK PICTET & CIE (ASIA) LTD',
  },
  {
    cik: '0001793755',
    value: '0001793755,BANQUE CANTONALE VAUDOISE',
    name: 'BANQUE CANTONALE VAUDOISE',
    label: 'BANQUE CANTONALE VAUDOISE',
  },
  {
    cik: '0001535602',
    value: '0001535602,BANQUE PICTET & CIE SA',
    name: 'BANQUE PICTET & CIE SA',
    label: 'BANQUE PICTET & CIE SA',
  },
  {
    cik: '0001426960',
    value: '0001426960,BANTA ASSET MANAGEMENT LP',
    name: 'BANTA ASSET MANAGEMENT LP',
    label: 'BANTA ASSET MANAGEMENT LP',
  },
  {
    cik: '0000846600',
    value: '0000846600,BANYAN CAPITAL MANAGEMENT, INC.',
    name: 'BANYAN CAPITAL MANAGEMENT, INC.',
    label: 'BANYAN CAPITAL MANAGEMENT, INC.',
  },
  {
    cik: '0001483499',
    value: '0001483499,BANYAN PARTNERS, LLC',
    name: 'BANYAN PARTNERS, LLC',
    label: 'BANYAN PARTNERS, LLC',
  },
  {
    cik: '0001599670',
    value: '0001599670,BARBARA OIL CO.',
    name: 'BARBARA OIL CO.',
    label: 'BARBARA OIL CO.',
  },
  {
    cik: '0001624865',
    value: '0001624865,BARBER FINANCIAL GROUP, INC.',
    name: 'BARBER FINANCIAL GROUP, INC.',
    label: 'BARBER FINANCIAL GROUP, INC.',
  },
  {
    cik: '0000312069',
    value: '0000312069,BARCLAYS PLC',
    name: 'BARCLAYS PLC',
    label: 'BARCLAYS PLC',
  },
  {
    cik: '0001105907',
    value: '0001105907,BARD ASSOCIATES INC',
    name: 'BARD ASSOCIATES INC',
    label: 'BARD ASSOCIATES INC',
  },
  {
    cik: '0001906798',
    value: '0001906798,BARDEN CAPITAL MANAGEMENT, INC.',
    name: 'BARDEN CAPITAL MANAGEMENT, INC.',
    label: 'BARDEN CAPITAL MANAGEMENT, INC.',
  },
  {
    cik: '0001850858',
    value: '0001850858,BARD FINANCIAL SERVICES, INC.',
    name: 'BARD FINANCIAL SERVICES, INC.',
    label: 'BARD FINANCIAL SERVICES, INC.',
  },
  {
    cik: '0001616416',
    value: '0001616416,BARDIN HILL MANAGEMENT PARTNERS LP',
    name: 'BARDIN HILL MANAGEMENT PARTNERS LP',
    label: 'BARDIN HILL MANAGEMENT PARTNERS LP',
  },
  {
    cik: '0001340807',
    value: '0001340807,BARES CAPITAL MANAGEMENT, INC.',
    name: 'BARES CAPITAL MANAGEMENT, INC.',
    label: 'BARES CAPITAL MANAGEMENT, INC.',
  },
  {
    cik: '0001114039',
    value: '0001114039,BAR HARBOR TRUST SERVICES',
    name: 'BAR HARBOR TRUST SERVICES',
    label: 'BAR HARBOR TRUST SERVICES',
  },
  {
    cik: '0001035463',
    value: '0001035463,BAR HARBOR WEALTH MANAGEMENT',
    name: 'BAR HARBOR WEALTH MANAGEMENT',
    label: 'BAR HARBOR WEALTH MANAGEMENT',
  },
  {
    cik: '0001606779',
    value: '0001606779,BARING ASSET MANAGEMENT LTD',
    name: 'BARING ASSET MANAGEMENT LTD',
    label: 'BARING ASSET MANAGEMENT LTD',
  },
  {
    cik: '0001568131',
    value: '0001568131,BARING INTERNATIONAL INVESTMENT LTD',
    name: 'BARING INTERNATIONAL INVESTMENT LTD',
    label: 'BARING INTERNATIONAL INVESTMENT LTD',
  },
  {
    cik: '0000009015',
    value: '0000009015,BARINGS LLC',
    name: 'BARINGS LLC',
    label: 'BARINGS LLC',
  },
  {
    cik: '0001379495',
    value: '0001379495,BARINGS REAL ESTATE ADVISERS LLC',
    name: 'BARINGS REAL ESTATE ADVISERS LLC',
    label: 'BARINGS REAL ESTATE ADVISERS LLC',
  },
  {
    cik: '0000887762',
    value: '0000887762,BARINGTON CAPITAL GROUP, L.P.',
    name: 'BARINGTON CAPITAL GROUP, L.P.',
    label: 'BARINGTON CAPITAL GROUP, L.P.',
  },
  {
    cik: '0001655975',
    value: '0001655975,BARKER PARTNERSHIP L.P.',
    name: 'BARKER PARTNERSHIP L.P.',
    label: 'BARKER PARTNERSHIP L.P.',
  },
  {
    cik: '0001908288',
    value: '0001908288,BARNES PETTEY FINANCIAL ADVISORS, LLC',
    name: 'BARNES PETTEY FINANCIAL ADVISORS, LLC',
    label: 'BARNES PETTEY FINANCIAL ADVISORS, LLC',
  },
  {
    cik: '0001426588',
    value: '0001426588,BARNETT & COMPANY, INC.',
    name: 'BARNETT & COMPANY, INC.',
    label: 'BARNETT & COMPANY, INC.',
  },
  {
    cik: '0001536890',
    value: '0001536890,BAROMETER CAPITAL MANAGEMENT INC.',
    name: 'BAROMETER CAPITAL MANAGEMENT INC.',
    label: 'BAROMETER CAPITAL MANAGEMENT INC.',
  },
  {
    cik: '0001832093',
    value: '0001832093,BARON FINANCIAL GROUP, LLC',
    name: 'BARON FINANCIAL GROUP, LLC',
    label: 'BARON FINANCIAL GROUP, LLC',
  },
  {
    cik: '0001851395',
    value: '0001851395,BARON SILVER STEVENS FINANCIAL ADVISORS, LLC',
    name: 'BARON SILVER STEVENS FINANCIAL ADVISORS, LLC',
    label: 'BARON SILVER STEVENS FINANCIAL ADVISORS, LLC',
  },
  {
    cik: '0001040210',
    value: '0001040210,BARR E S & CO',
    name: 'BARR E S & CO',
    label: 'BARR E S & CO',
  },
  {
    cik: '0001526411',
    value: '0001526411,BARRETT ASSET MANAGEMENT, LLC',
    name: 'BARRETT ASSET MANAGEMENT, LLC',
    label: 'BARRETT ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001909089',
    value: '0001909089,BARRIER CAPITAL MANAGEMENT, LLC',
    name: 'BARRIER CAPITAL MANAGEMENT, LLC',
    label: 'BARRIER CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001483796',
    value: '0001483796,BARRINGTON STRATEGIC WEALTH MANAGEMENT GROUP LLC',
    name: 'BARRINGTON STRATEGIC WEALTH MANAGEMENT GROUP LLC',
    label: 'BARRINGTON STRATEGIC WEALTH MANAGEMENT GROUP LLC',
  },
  {
    cik: '0000313028',
    value: '0000313028,BARROW HANLEY MEWHINNEY & STRAUSS LLC',
    name: 'BARROW HANLEY MEWHINNEY & STRAUSS LLC',
    label: 'BARROW HANLEY MEWHINNEY & STRAUSS LLC',
  },
  {
    cik: '0001631639',
    value: '0001631639,BARRY INVESTMENT ADVISORS, LLC',
    name: 'BARRY INVESTMENT ADVISORS, LLC',
    label: 'BARRY INVESTMENT ADVISORS, LLC',
  },
  {
    cik: '0001550075',
    value: '0001550075,BARTLETT & CO. LLC',
    name: 'BARTLETT & CO. LLC',
    label: 'BARTLETT & CO. LLC',
  },
  {
    cik: '0001387818',
    value: '0001387818,BARTON INVESTMENT MANAGEMENT',
    name: 'BARTON INVESTMENT MANAGEMENT',
    label: 'BARTON INVESTMENT MANAGEMENT',
  },
  {
    cik: '0001511098',
    value: '0001511098,BASKIN FINANCIAL SERVICES INC.',
    name: 'BASKIN FINANCIAL SERVICES INC.',
    label: 'BASKIN FINANCIAL SERVICES INC.',
  },
  {
    cik: '0001875995',
    value: '0001875995,BASSETT HARGROVE INVESTMENT COUNSEL, LLC',
    name: 'BASSETT HARGROVE INVESTMENT COUNSEL, LLC',
    label: 'BASSETT HARGROVE INVESTMENT COUNSEL, LLC',
  },
  {
    cik: '0001287978',
    value: '0001287978,BASSO CAPITAL MANAGEMENT L.P.',
    name: 'BASSO CAPITAL MANAGEMENT L.P.',
    label: 'BASSO CAPITAL MANAGEMENT L.P.',
  },
  {
    cik: '0001287978',
    value: '0001287978,BASSO CAPITAL MANAGEMENT, L.P.',
    name: 'BASSO CAPITAL MANAGEMENT, L.P.',
    label: 'BASSO CAPITAL MANAGEMENT, L.P.',
  },
  {
    cik: '0001085393',
    value: '0001085393,BASSWOOD CAPITAL MANAGEMENT, L.L.C.',
    name: 'BASSWOOD CAPITAL MANAGEMENT, L.L.C.',
    label: 'BASSWOOD CAPITAL MANAGEMENT, L.L.C.',
  },
  {
    cik: '0001384943',
    value: '0001384943,BATH SAVINGS TRUST CO',
    name: 'BATH SAVINGS TRUST CO',
    label: 'BATH SAVINGS TRUST CO',
  },
  {
    cik: '0001548594',
    value: '0001548594,BATTERY MANAGEMENT CORP.',
    name: 'BATTERY MANAGEMENT CORP.',
    label: 'BATTERY MANAGEMENT CORP.',
  },
  {
    cik: '0000316471',
    value: '0000316471,BATTERYMARCH FINANCIAL MANAGEMENT INC',
    name: 'BATTERYMARCH FINANCIAL MANAGEMENT INC',
    label: 'BATTERYMARCH FINANCIAL MANAGEMENT INC',
  },
  {
    cik: '0001517642',
    value: '0001517642,BATTERY PARTNERS IX, LLC',
    name: 'BATTERY PARTNERS IX, LLC',
    label: 'BATTERY PARTNERS IX, LLC',
  },
  {
    cik: '0001542631',
    value: '0001542631,BATTERY PARTNERS VIII, LLC',
    name: 'BATTERY PARTNERS VIII, LLC',
    label: 'BATTERY PARTNERS VIII, LLC',
  },
  {
    cik: '0001404827',
    value: '0001404827,BATTERY PARTNERS VII, LLC',
    name: 'BATTERY PARTNERS VII, LLC',
    label: 'BATTERY PARTNERS VII, LLC',
  },
  {
    cik: '0001730818',
    value: '0001730818,BAUGH & ASSOCIATES, LLC',
    name: 'BAUGH & ASSOCIATES, LLC',
    label: 'BAUGH & ASSOCIATES, LLC',
  },
  {
    cik: '0001061768',
    value: '0001061768,BAUPOST GROUP LLC/MA',
    name: 'BAUPOST GROUP LLC/MA',
    label: 'BAUPOST GROUP LLC/MA',
  },
  {
    cik: '0001096783',
    value: '0001096783,BAXTER BROS INC',
    name: 'BAXTER BROS INC',
    label: 'BAXTER BROS INC',
  },
  {
    cik: '0001771524',
    value: '0001771524,BAYBERRY CAPITAL PARTNERS LP',
    name: 'BAYBERRY CAPITAL PARTNERS LP',
    label: 'BAYBERRY CAPITAL PARTNERS LP',
  },
  {
    cik: '0001748861',
    value:
      '0001748861,BAY COLONY ADVISORY GROUP, INC D/B/A BAY COLONY ADVISORS',
    name: 'BAY COLONY ADVISORY GROUP, INC D/B/A BAY COLONY ADVISORS',
    label: 'BAY COLONY ADVISORY GROUP, INC D/B/A BAY COLONY ADVISORS',
  },
  {
    cik: '0001632551',
    value: '0001632551,BAYESIAN CAPITAL MANAGEMENT, LP',
    name: 'BAYESIAN CAPITAL MANAGEMENT, LP',
    label: 'BAYESIAN CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0001767989',
    value: '0001767989,BAY HARBOR WEALTH MANAGEMENT, LLC',
    name: 'BAY HARBOR WEALTH MANAGEMENT, LLC',
    label: 'BAY HARBOR WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001513783',
    value: '0001513783,BAY MANAGEMENT CO XI LLC',
    name: 'BAY MANAGEMENT CO XI LLC',
    label: 'BAY MANAGEMENT CO XI LLC',
  },
  {
    cik: '0001803679',
    value: '0001803679,BAYMOUNT MANAGEMENT LP',
    name: 'BAYMOUNT MANAGEMENT LP',
    label: 'BAYMOUNT MANAGEMENT LP',
  },
  {
    cik: '0001742941',
    value: '0001742941,BAYOU CITY ENERGY MANAGEMENT LLC',
    name: 'BAYOU CITY ENERGY MANAGEMENT LLC',
    label: 'BAYOU CITY ENERGY MANAGEMENT LLC',
  },
  {
    cik: '0001798924',
    value: '0001798924,BAY RIVERS GROUP',
    name: 'BAY RIVERS GROUP',
    label: 'BAY RIVERS GROUP',
  },
  {
    cik: '0001906790',
    value: '0001906790,BAYSHORE ASSET MANAGEMENT, LLC',
    name: 'BAYSHORE ASSET MANAGEMENT, LLC',
    label: 'BAYSHORE ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001546408',
    value: '0001546408,BAYSTATE WEALTH MANAGEMENT LLC',
    name: 'BAYSTATE WEALTH MANAGEMENT LLC',
    label: 'BAYSTATE WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0001698613',
    value: '0001698613,BAY STREET CAPITAL PARTNERS LLC',
    name: 'BAY STREET CAPITAL PARTNERS LLC',
    label: 'BAY STREET CAPITAL PARTNERS LLC',
  },
  {
    cik: '0001767366',
    value: '0001767366,BAYVIEW ASSET MANAGEMENT, LLC',
    name: 'BAYVIEW ASSET MANAGEMENT, LLC',
    label: 'BAYVIEW ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0000924223',
    value: '0000924223,BB BIOTECH AG',
    name: 'BB BIOTECH AG',
    label: 'BB BIOTECH AG',
  },
  {
    cik: '0001842116',
    value: '0001842116,B, B, H, & B INC.',
    name: 'B, B, H, & B INC.',
    label: 'B, B, H, & B INC.',
  },
  {
    cik: '0001592613',
    value: '0001592613,BBK CAPITAL PARTNERS, LLC',
    name: 'BBK CAPITAL PARTNERS, LLC',
    label: 'BBK CAPITAL PARTNERS, LLC',
  },
  {
    cik: '0001352467',
    value: '0001352467,BBR PARTNERS, LLC',
    name: 'BBR PARTNERS, LLC',
    label: 'BBR PARTNERS, LLC',
  },
  {
    cik: '0001544341',
    value: '0001544341,BBT CAPITAL MANAGEMENT, LLC',
    name: 'BBT CAPITAL MANAGEMENT, LLC',
    label: 'BBT CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0000092230',
    value: '00000922,BB&T CORP',
    name: 'BB&T CORP',
    label: 'BB&T CORP',
  },
  {
    cik: '0000908070',
    value: '0000908070,BB&T INVESTMENT SERVICES, INC.',
    name: 'BB&T INVESTMENT SERVICES, INC.',
    label: 'BB&T INVESTMENT SERVICES, INC.',
  },
  {
    cik: '0001383161',
    value: '0001383161,BB&T SECURITIES, LLC',
    name: 'BB&T SECURITIES, LLC',
    label: 'BB&T SECURITIES, LLC',
  },
  {
    cik: '0001409775',
    value: '0001409775,BBVA COMPASS BANCSHARES, INC',
    name: 'BBVA COMPASS BANCSHARES, INC',
    label: 'BBVA COMPASS BANCSHARES, INC',
  },
  {
    cik: '0000844928',
    value: '0000844,BBVA USA8',
    name: 'BBVA USA',
    label: 'BBVA USA',
  },
  {
    cik: '0001409775',
    value: '0001409775,BBVA USA BANCSHARES, INC.',
    name: 'BBVA USA BANCSHARES, INC.',
    label: 'BBVA USA BANCSHARES, INC.',
  },
  {
    cik: '0001800228',
    value: '0001800228,BCBSM, INC.',
    name: 'BCBSM, INC.',
    label: 'BCBSM, INC.',
  },
  {
    cik: '0001831219',
    value: '0001831219,BC GESTAO DE RECURSOS LTDA.',
    name: 'BC GESTAO DE RECURSOS LTDA.',
    label: 'BC GESTAO DE RECURSOS LTDA.',
  },
  {
    cik: '0001830467',
    value: '0001830467,BCGM WEALTH MANAGEMENT, LLC',
    name: 'BCGM WEALTH MANAGEMENT, LLC',
    label: 'BCGM WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001768195',
    value: '0001768195,BCJ CAPITAL MANAGEMENT, LLC',
    name: 'BCJ CAPITAL MANAGEMENT, LLC',
    label: 'BCJ CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001819275',
    value: '0001819275,BCK CAPITAL MANAGEMENT LP',
    name: 'BCK CAPITAL MANAGEMENT LP',
    label: 'BCK CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001845867',
    value: '0001845867,BCK PARTNERS, INC.',
    name: 'BCK PARTNERS, INC.',
    label: 'BCK PARTNERS, INC.',
  },
  {
    cik: '0001373017',
    value: '0001373017,BCM ADVISORS, LLC',
    name: 'BCM ADVISORS, LLC',
    label: 'BCM ADVISORS, LLC',
  },
  {
    cik: '0001711889',
    value: '0001711889,BC PARTNERS ADVISORS L.P.',
    name: 'BC PARTNERS ADVISORS L.P.',
    label: 'BC PARTNERS ADVISORS L.P.',
  },
  {
    cik: '0001908380',
    value: '0001908380,BCR WEALTH STRATEGIES, LLC',
    name: 'BCR WEALTH STRATEGIES, LLC',
    label: 'BCR WEALTH STRATEGIES, LLC',
  },
  {
    cik: '0001798172',
    value: '0001798172,BCS WEALTH MANAGEMENT',
    name: 'BCS WEALTH MANAGEMENT',
    label: 'BCS WEALTH MANAGEMENT',
  },
  {
    cik: '0001713458',
    value: '0001713458,BDO WEALTH ADVISORS, LLC',
    name: 'BDO WEALTH ADVISORS, LLC',
    label: 'BDO WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001510974',
    value: '0001510974,BDT CAPITAL PARTNERS, LLC',
    name: 'BDT CAPITAL PARTNERS, LLC',
    label: 'BDT CAPITAL PARTNERS, LLC',
  },
  {
    cik: '0001752523',
    value: '0001752523,B&D WHITE CAPITAL COMPANY, LLC',
    name: 'B&D WHITE CAPITAL COMPANY, LLC',
    label: 'B&D WHITE CAPITAL COMPANY, LLC',
  },
  {
    cik: '0001058800',
    value: '0001058800,BEACH INVESTMENT COUNSEL INC/PA',
    name: 'BEACH INVESTMENT COUNSEL INC/PA',
    label: 'BEACH INVESTMENT COUNSEL INC/PA',
  },
  {
    cik: '0001390965',
    value: '0001390965,BEACH INVESTMENT MANAGEMENT, LLC.',
    name: 'BEACH INVESTMENT MANAGEMENT, LLC.',
    label: 'BEACH INVESTMENT MANAGEMENT, LLC.',
  },
  {
    cik: '0001453885',
    value: '0001453885,BEACH POINT CAPITAL MANAGEMENT LP',
    name: 'BEACH POINT CAPITAL MANAGEMENT LP',
    label: 'BEACH POINT CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001575301',
    value: '0001575301,BEACON CAPITAL MANAGEMENT, INC.',
    name: 'BEACON CAPITAL MANAGEMENT, INC.',
    label: 'BEACON CAPITAL MANAGEMENT, INC.',
  },
  {
    cik: '0001921487',
    value: '0001921487,BEACON CAPITAL MANAGEMENT, LLC',
    name: 'BEACON CAPITAL MANAGEMENT, LLC',
    label: 'BEACON CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001800502',
    value: '0001800502,BEACON FINANCIAL ADVISORY LLC',
    name: 'BEACON FINANCIAL ADVISORY LLC',
    label: 'BEACON FINANCIAL ADVISORY LLC',
  },
  {
    cik: '0001591379',
    value: '0001591379,BEACON FINANCIAL GROUP',
    name: 'BEACON FINANCIAL GROUP',
    label: 'BEACON FINANCIAL GROUP',
  },
  {
    cik: '0001784093',
    value: '0001784093,BEACON HARBOR WEALTH ADVISORS, INC.',
    name: 'BEACON HARBOR WEALTH ADVISORS, INC.',
    label: 'BEACON HARBOR WEALTH ADVISORS, INC.',
  },
  {
    cik: '0001654599',
    value: '0001654599,BEACON INVESTMENT ADVISORY SERVICES, INC.',
    name: 'BEACON INVESTMENT ADVISORY SERVICES, INC.',
    label: 'BEACON INVESTMENT ADVISORY SERVICES, INC.',
  },
  {
    cik: '0001569537',
    value: '0001569537,BEACONLIGHT CAPITAL, LLC',
    name: 'BEACONLIGHT CAPITAL, LLC',
    label: 'BEACONLIGHT CAPITAL, LLC',
  },
  {
    cik: '0001744317',
    value: '0001744317,BEACON POINTE ADVISORS, LLC',
    name: 'BEACON POINTE ADVISORS, LLC',
    label: 'BEACON POINTE ADVISORS, LLC',
  },
  {
    cik: '0001748071',
    value: '0001748071,BEACON POINTE WEALTH ADVISORS, LLC',
    name: 'BEACON POINTE WEALTH ADVISORS, LLC',
    label: 'BEACON POINTE WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001008871',
    value: '0001008871,BEACON TRUST CO',
    name: 'BEACON TRUST CO',
    label: 'BEACON TRUST CO',
  },
  {
    cik: '0001107576',
    value: '0001107576,BEACON WEALTHCARE, INC',
    name: 'BEACON WEALTHCARE, INC',
    label: 'BEACON WEALTHCARE, INC',
  },
  {
    cik: '0001849336',
    value: '0001849336,BEACON WEALTHCARE LLC',
    name: 'BEACON WEALTHCARE LLC',
    label: 'BEACON WEALTHCARE LLC',
  },
  {
    cik: '0001908938',
    value: '0001908938,BEAIRD HARRIS WEALTH MANAGEMENT, LLC',
    name: 'BEAIRD HARRIS WEALTH MANAGEMENT, LLC',
    label: 'BEAIRD HARRIS WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001803426',
    value: '0001803426,BEAM ASSET MANAGEMENT, LLC',
    name: 'BEAM ASSET MANAGEMENT, LLC',
    label: 'BEAM ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001803426',
    value: '0001803426,BEAM WEALTH ADVISORS, INC.',
    name: 'BEAM WEALTH ADVISORS, INC.',
    label: 'BEAM WEALTH ADVISORS, INC.',
  },
  {
    cik: '0001714678',
    value: '0001714678,BEATON MANAGEMENT CO. INC.',
    name: 'BEATON MANAGEMENT CO. INC.',
    label: 'BEATON MANAGEMENT CO. INC.',
  },
  {
    cik: '0001798756',
    value: '0001798756,BEAUMONT ASSET MANAGEMENT, L.L.C.',
    name: 'BEAUMONT ASSET MANAGEMENT, L.L.C.',
    label: 'BEAUMONT ASSET MANAGEMENT, L.L.C.',
  },
  {
    cik: '0001806628',
    value: '0001806628,BEAUMONT CAPITAL MANAGEMENT, LLC',
    name: 'BEAUMONT CAPITAL MANAGEMENT, LLC',
    label: 'BEAUMONT CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001352764',
    value: '0001352764,BEAUMONT FINANCIAL PARTNERS, LLC',
    name: 'BEAUMONT FINANCIAL PARTNERS, LLC',
    label: 'BEAUMONT FINANCIAL PARTNERS, LLC',
  },
  {
    cik: '0001790548',
    value: '0001790548,BECK BODE, LLC',
    name: 'BECK BODE, LLC',
    label: 'BECK BODE, LLC',
  },
  {
    cik: '0001601904',
    value: '0001601904,BECK CAPITAL MANAGEMENT, LLC',
    name: 'BECK CAPITAL MANAGEMENT, LLC',
    label: 'BECK CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0000799004',
    value: '0000799004,BECKER CAPITAL MANAGEMENT INC',
    name: 'BECKER CAPITAL MANAGEMENT INC',
    label: 'BECKER CAPITAL MANAGEMENT INC',
  },
  {
    cik: '0001346543',
    value: '0001346543,BECKER DRAPKIN MANAGEMENT, L.P.',
    name: 'BECKER DRAPKIN MANAGEMENT, L.P.',
    label: 'BECKER DRAPKIN MANAGEMENT, L.P.',
  },
  {
    cik: '0000010742',
    value: '0000010742,BECK MACK & OLIVER LLC',
    name: 'BECK MACK & OLIVER LLC',
    label: 'BECK MACK & OLIVER LLC',
  },
  {
    cik: '0001278641',
    value: '0001278641,BEDDOW CAPITAL MANAGEMENT INC',
    name: 'BEDDOW CAPITAL MANAGEMENT INC',
    label: 'BEDDOW CAPITAL MANAGEMENT INC',
  },
  {
    cik: '0001730511',
    value: '0001730511,BEDEL FINANCIAL CONSULTING, INC.',
    name: 'BEDEL FINANCIAL CONSULTING, INC.',
    label: 'BEDEL FINANCIAL CONSULTING, INC.',
  },
  {
    cik: '0001388142',
    value: '0001388142,BEDELL FRAZIER INVESTMENT COUNSELING, LLC',
    name: 'BEDELL FRAZIER INVESTMENT COUNSELING, LLC',
    label: 'BEDELL FRAZIER INVESTMENT COUNSELING, LLC',
  },
  {
    cik: '0001871000',
    value: '0001871000,BEDFORD RIDGE CAPITAL LP',
    name: 'BEDFORD RIDGE CAPITAL LP',
    label: 'BEDFORD RIDGE CAPITAL LP',
  },
  {
    cik: '0001274196',
    value: '0001274196,BEDRIJFSTAKPENSIOENFONDS VOOR DE MEDIA PNO',
    name: 'BEDRIJFSTAKPENSIOENFONDS VOOR DE MEDIA PNO',
    label: 'BEDRIJFSTAKPENSIOENFONDS VOOR DE MEDIA PNO',
  },
  {
    cik: '0001281649',
    value: '0001281649,BEECHER INVESTORS INC',
    name: 'BEECHER INVESTORS INC',
    label: 'BEECHER INVESTORS INC',
  },
  {
    cik: '0001356202',
    value: '0001356202,BEECH HILL ADVISORS, INC.',
    name: 'BEECH HILL ADVISORS, INC.',
    label: 'BEECH HILL ADVISORS, INC.',
  },
  {
    cik: '0000911274',
    value: '0000911274,BEESE FULMER INVESTMENT MANAGEMENT, INC.',
    name: 'BEESE FULMER INVESTMENT MANAGEMENT, INC.',
    label: 'BEESE FULMER INVESTMENT MANAGEMENT, INC.',
  },
  {
    cik: '0001697303',
    value: '0001697303,BEIRNE WEALTH CONSULTING SERVICES, LLC',
    name: 'BEIRNE WEALTH CONSULTING SERVICES, LLC',
    label: 'BEIRNE WEALTH CONSULTING SERVICES, LLC',
  },
  {
    cik: '0001078727',
    value: '0001078727,BEL AIR INVESTMENT ADVISORS LLC',
    name: 'BEL AIR INVESTMENT ADVISORS LLC',
    label: 'BEL AIR INVESTMENT ADVISORS LLC',
  },
  {
    cik: '0001481957',
    value: '0001481957,BELFER MANAGEMENT LLC',
    name: 'BELFER MANAGEMENT LLC',
    label: 'BELFER MANAGEMENT LLC',
  },
  {
    cik: '0001681004',
    value: '0001681004,BELL ASSET MANAGEMENT LTD',
    name: 'BELL ASSET MANAGEMENT LTD',
    label: 'BELL ASSET MANAGEMENT LTD',
  },
  {
    cik: '0001002672',
    value: '00010026,BELL BANK',
    name: 'BELL BANK',
    label: 'BELL BANK',
  },
  {
    cik: '0001725362',
    value: '0001725362,BELL & BROWN WEALTH ADVISORS, LLC',
    name: 'BELL & BROWN WEALTH ADVISORS, LLC',
    label: 'BELL & BROWN WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001600152',
    value: '0001600152,BELLECAPITAL INTERNATIONAL LTD.',
    name: 'BELLECAPITAL INTERNATIONAL LTD.',
    label: 'BELLECAPITAL INTERNATIONAL LTD.',
  },
  {
    cik: '0001642343',
    value: '0001642343,BELLEVUE ASSET MANAGEMENT AG',
    name: 'BELLEVUE ASSET MANAGEMENT AG',
    label: 'BELLEVUE ASSET MANAGEMENT AG',
  },
  {
    cik: '0001801619',
    value: '0001801619,BELLEVUE ASSET MANAGEMENT, LLC',
    name: 'BELLEVUE ASSET MANAGEMENT, LLC',
    label: 'BELLEVUE ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001569067',
    value: '0001569067,BELLEVUE FINANCIAL, INC.',
    name: 'BELLEVUE FINANCIAL, INC.',
    label: 'BELLEVUE FINANCIAL, INC.',
  },
  {
    cik: '0001674546',
    value: '0001674546,BELLEVUE GROUP AG',
    name: 'BELLEVUE GROUP AG',
    label: 'BELLEVUE GROUP AG',
  },
  {
    cik: '0001890149',
    value: '0001890149,BELL INVESTMENT ADVISORS, INC',
    name: 'BELL INVESTMENT ADVISORS, INC',
    label: 'BELL INVESTMENT ADVISORS, INC',
  },
  {
    cik: '0001756283',
    value: '0001756283,BELL ROCK CAPITAL LLC',
    name: 'BELL ROCK CAPITAL LLC',
    label: 'BELL ROCK CAPITAL LLC',
  },
  {
    cik: '0001002672',
    value: '0001002672,BELL STATE BANK & TRUST',
    name: 'BELL STATE BANK & TRUST',
    label: 'BELL STATE BANK & TRUST',
  },
  {
    cik: '0001819815',
    value: '0001819815,BELLWETHER ADVISORS, LLC',
    name: 'BELLWETHER ADVISORS, LLC',
    label: 'BELLWETHER ADVISORS, LLC',
  },
  {
    cik: '0001682454',
    value: '0001682454,BELLWETHER INVESTMENT GROUP, LLC',
    name: 'BELLWETHER INVESTMENT GROUP, LLC',
    label: 'BELLWETHER INVESTMENT GROUP, LLC',
  },
  {
    cik: '0001793367',
    value: '0001793367,BELMONT CAPITAL, LLC',
    name: 'BELMONT CAPITAL, LLC',
    label: 'BELMONT CAPITAL, LLC',
  },
  {
    cik: '0001598803',
    value: '0001598803,BELMONT GLOBAL ADVISORS, INC.',
    name: 'BELMONT GLOBAL ADVISORS, INC.',
    label: 'BELMONT GLOBAL ADVISORS, INC.',
  },
  {
    cik: '0001721242',
    value: '0001721242,BELPOINTE ASSET MANAGEMENT LLC',
    name: 'BELPOINTE ASSET MANAGEMENT LLC',
    label: 'BELPOINTE ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001755386',
    value: '0001755386,BELVEDERE ADVISORS LLC',
    name: 'BELVEDERE ADVISORS LLC',
    label: 'BELVEDERE ADVISORS LLC',
  },
  {
    cik: '0001632341',
    value: '0001632341,BELVEDERE TRADING LLC',
    name: 'BELVEDERE TRADING LLC',
    label: 'BELVEDERE TRADING LLC',
  },
  {
    cik: '0001352859',
    value: '0001352859,BENCHMARK CAPITAL ADVISORS',
    name: 'BENCHMARK CAPITAL ADVISORS',
    label: 'BENCHMARK CAPITAL ADVISORS',
  },
  {
    cik: '0001757706',
    value: '0001757706,BENCHMARK FINANCIAL WEALTH ADVISORS, LLC',
    name: 'BENCHMARK FINANCIAL WEALTH ADVISORS, LLC',
    label: 'BENCHMARK FINANCIAL WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001910971',
    value: '0001910971,BENCHMARK INVESTMENT ADVISORS LLC',
    name: 'BENCHMARK INVESTMENT ADVISORS LLC',
    label: 'BENCHMARK INVESTMENT ADVISORS LLC',
  },
  {
    cik: '0001788241',
    value: '0001788241,BENCHMARK WEALTH MANAGEMENT, LLC',
    name: 'BENCHMARK WEALTH MANAGEMENT, LLC',
    label: 'BENCHMARK WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0000894300',
    value: '0000894300,BENDER ROBERT & ASSOCIATES',
    name: 'BENDER ROBERT & ASSOCIATES',
    label: 'BENDER ROBERT & ASSOCIATES',
  },
  {
    cik: '0001859474',
    value: '0001859474,BENEDETTI & GUCER, INC.',
    name: 'BENEDETTI & GUCER, INC.',
    label: 'BENEDETTI & GUCER, INC.',
  },
  {
    cik: '0000711089',
    value: '0000711089,BENEDICT FINANCIAL ADVISORS INC',
    name: 'BENEDICT FINANCIAL ADVISORS INC',
    label: 'BENEDICT FINANCIAL ADVISORS INC',
  },
  {
    cik: '0001543160',
    value: '0001543160,BENEFIT STREET PARTNERS LLC',
    name: 'BENEFIT STREET PARTNERS LLC',
    label: 'BENEFIT STREET PARTNERS LLC',
  },
  {
    cik: '0001352871',
    value: '0001352871,BENIN MANAGEMENT CORP',
    name: 'BENIN MANAGEMENT CORP',
    label: 'BENIN MANAGEMENT CORP',
  },
  {
    cik: '0001451623',
    value: '0001451623,BENJAMIN EDWARDS INC',
    name: 'BENJAMIN EDWARDS INC',
    label: 'BENJAMIN EDWARDS INC',
  },
  {
    cik: '0001445065',
    value: '0001445065,BENJAMIN F. EDWARDS & COMPANY, INC.',
    name: 'BENJAMIN F. EDWARDS & COMPANY, INC.',
    label: 'BENJAMIN F. EDWARDS & COMPANY, INC.',
  },
  {
    cik: '0001010452',
    value: '0001010452,BENNETT LAWRENCE MANAGEMENT L L C/NY',
    name: 'BENNETT LAWRENCE MANAGEMENT L L C/NY',
    label: 'BENNETT LAWRENCE MANAGEMENT L L C/NY',
  },
  {
    cik: '0001840740',
    value: '0001840740,BENNETT SELBY INVESTMENTS LP',
    name: 'BENNETT SELBY INVESTMENTS LP',
    label: 'BENNETT SELBY INVESTMENTS LP',
  },
  {
    cik: '0001056821',
    value: '0001056821,BENNICAS & ASSOCIATES, INC.',
    name: 'BENNICAS & ASSOCIATES, INC.',
    label: 'BENNICAS & ASSOCIATES, INC.',
  },
  {
    cik: '0001694896',
    value: '0001694896,BENSON INVESTMENT MANAGEMENT COMPANY, INC.',
    name: 'BENSON INVESTMENT MANAGEMENT COMPANY, INC.',
    label: 'BENSON INVESTMENT MANAGEMENT COMPANY, INC.',
  },
  {
    cik: '0001660203',
    value: '0001660203,BERGANKDV WEALTH MANAGEMENT, LLC',
    name: 'BERGANKDV WEALTH MANAGEMENT, LLC',
    label: 'BERGANKDV WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001826136',
    value: '0001826136,BERGER FINANCIAL GROUP, INC',
    name: 'BERGER FINANCIAL GROUP, INC',
    label: 'BERGER FINANCIAL GROUP, INC',
  },
  {
    cik: '0001667694',
    value: '0001667694,BERKELEY CAPITAL PARTNERS, LLC',
    name: 'BERKELEY CAPITAL PARTNERS, LLC',
    label: 'BERKELEY CAPITAL PARTNERS, LLC',
  },
  {
    cik: '0000011544',
    value: '0000011544,BERKLEY W R CORP',
    name: 'BERKLEY W R CORP',
    label: 'BERKLEY W R CORP',
  },
  {
    cik: '0000949012',
    value: '0000949012,BERKSHIRE ASSET MANAGEMENT LLC/PA',
    name: 'BERKSHIRE ASSET MANAGEMENT LLC/PA',
    label: 'BERKSHIRE ASSET MANAGEMENT LLC/PA',
  },
  {
    cik: '0001831984',
    value: '0001831984,BERKSHIRE BANK',
    name: 'BERKSHIRE BANK',
    label: 'BERKSHIRE BANK',
  },
  {
    cik: '0001133742',
    value: '0001133742,BERKSHIRE CAPITAL HOLDINGS INC',
    name: 'BERKSHIRE CAPITAL HOLDINGS INC',
    label: 'BERKSHIRE CAPITAL HOLDINGS INC',
  },
  {
    cik: '0001067983',
    value: '0001067983,BERKSHIRE HATHAWAY INC',
    name: 'BERKSHIRE HATHAWAY INC',
    label: 'BERKSHIRE HATHAWAY INC',
  },
  {
    cik: '0001535172',
    value: '0001535172,BERKSHIRE MONEY MANAGEMENT, INC.',
    name: 'BERKSHIRE MONEY MANAGEMENT, INC.',
    label: 'BERKSHIRE MONEY MANAGEMENT, INC.',
  },
  {
    cik: '0001312988',
    value: '0001312988,BERKSHIRE PARTNERS LLC',
    name: 'BERKSHIRE PARTNERS LLC',
    label: 'BERKSHIRE PARTNERS LLC',
  },
  {
    cik: '0001720336',
    value: '0001720336,BERMAN CAPITAL ADVISORS, LLC',
    name: 'BERMAN CAPITAL ADVISORS, LLC',
    label: 'BERMAN CAPITAL ADVISORS, LLC',
  },
  {
    cik: '0001765595',
    value: '0001765595,BERNARDO WEALTH PLANNING LLC',
    name: 'BERNARDO WEALTH PLANNING LLC',
    label: 'BERNARDO WEALTH PLANNING LLC',
  },
  {
    cik: '0001569863',
    value: '0001569863,BERNICKE & ASSOCIATES, LTD',
    name: 'BERNICKE & ASSOCIATES, LTD',
    label: 'BERNICKE & ASSOCIATES, LTD',
  },
  {
    cik: '0001260468',
    value: '0001260468,BERNZOTT CAPITAL ADVISORS',
    name: 'BERNZOTT CAPITAL ADVISORS',
    label: 'BERNZOTT CAPITAL ADVISORS',
  },
  {
    cik: '0001803097',
    value: '0001803097,BERRY GROUP, LLC',
    name: 'BERRY GROUP, LLC',
    label: 'BERRY GROUP, LLC',
  },
  {
    cik: '0001804007',
    value: '0001804007,BERRY STREET CAPITAL MANAGEMENT LLP',
    name: 'BERRY STREET CAPITAL MANAGEMENT LLP',
    label: 'BERRY STREET CAPITAL MANAGEMENT LLP',
  },
  {
    cik: '0001482611',
    value: '0001482611,BERSON & CORRADO INVESTMENT ADVISORS, LLC',
    name: 'BERSON & CORRADO INVESTMENT ADVISORS, LLC',
    label: 'BERSON & CORRADO INVESTMENT ADVISORS, LLC',
  },
  {
    cik: '0001909372',
    value: '0001909372,BERTRAM GROWTH CAPITAL III (GPLLC), L.L.C.',
    name: 'BERTRAM GROWTH CAPITAL III (GPLLC), L.L.C.',
    label: 'BERTRAM GROWTH CAPITAL III (GPLLC), L.L.C.',
  },
  {
    cik: '0001719307',
    value: '0001719307,BERYL CAPITAL MANAGEMENT LLC',
    name: 'BERYL CAPITAL MANAGEMENT LLC',
    label: 'BERYL CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001572664',
    value: '0001572664,BERYLSON CAPITAL PARTNERS, LLC',
    name: 'BERYLSON CAPITAL PARTNERS, LLC',
    label: 'BERYLSON CAPITAL PARTNERS, LLC',
  },
  {
    cik: '0001054074',
    value: '0001054074,BESSEMER GROUP INC',
    name: 'BESSEMER GROUP INC',
    label: 'BESSEMER GROUP INC',
  },
  {
    cik: '0001023279',
    value: '0001023279,BESSEMER SECURITIES LLC',
    name: 'BESSEMER SECURITIES LLC',
    label: 'BESSEMER SECURITIES LLC',
  },
  {
    cik: '0001594417',
    value: '0001594417,BETA WEALTH GROUP, INC.',
    name: 'BETA WEALTH GROUP, INC.',
    label: 'BETA WEALTH GROUP, INC.',
  },
  {
    cik: '0001633901',
    value: '0001633901,BETTERMENT LLC',
    name: 'BETTERMENT LLC',
    label: 'BETTERMENT LLC',
  },
  {
    cik: '0001858428',
    value: '0001858428,BETTER MONEY DECISIONS, LLC',
    name: 'BETTER MONEY DECISIONS, LLC',
    label: 'BETTER MONEY DECISIONS, LLC',
  },
  {
    cik: '0001911702',
    value: '0001911702,BETTERWEALTH, LLC',
    name: 'BETTERWEALTH, LLC',
    label: 'BETTERWEALTH, LLC',
  },
  {
    cik: '0001361974',
    value: '0001361974,BEUTEL, GOODMAN & CO LTD.',
    name: 'BEUTEL, GOODMAN & CO LTD.',
    label: 'BEUTEL, GOODMAN & CO LTD.',
  },
  {
    cik: '0001639101',
    value: '0001639101,BFAM PARTNERS (CAYMAN) LTD',
    name: 'BFAM PARTNERS (CAYMAN) LTD',
    label: 'BFAM PARTNERS (CAYMAN) LTD',
  },
  {
    cik: '0001706836',
    value: '00017068,BFSG, LLC',
    name: 'BFSG, LLC',
    label: 'BFSG, LLC',
  },
  {
    cik: '0001146089',
    value: '0001146089,BFT FINANCIAL GROUP, LLC',
    name: 'BFT FINANCIAL GROUP, LLC',
    label: 'BFT FINANCIAL GROUP, LLC',
  },
  {
    cik: '0001873989',
    value: '0001873989,BG INVESTMENT SERVICES, INC.',
    name: 'BG INVESTMENT SERVICES, INC.',
    label: 'BG INVESTMENT SERVICES, INC.',
  },
  {
    cik: '0001513271',
    value: '0001513271,BHF KLEINWORT BENSON GROUP S.A.',
    name: 'BHF KLEINWORT BENSON GROUP S.A.',
    label: 'BHF KLEINWORT BENSON GROUP S.A.',
  },
  {
    cik: '0001802858',
    value: '0001802858,BHF RG CAPITAL INC.',
    name: 'BHF RG CAPITAL INC.',
    label: 'BHF RG CAPITAL INC.',
  },
  {
    cik: '0001710539',
    value: '0001710539,BHK INVESTMENT ADVISORS, LLC',
    name: 'BHK INVESTMENT ADVISORS, LLC',
    label: 'BHK INVESTMENT ADVISORS, LLC',
  },
  {
    cik: '0001491617',
    value: '0001491617,BHR CAPITAL LLC',
    name: 'BHR CAPITAL LLC',
    label: 'BHR CAPITAL LLC',
  },
  {
    cik: '0001618872',
    value: '0001618872,BHZ CAPITAL MANAGEMENT, LP',
    name: 'BHZ CAPITAL MANAGEMENT, LP',
    label: 'BHZ CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0001778131',
    value: '0001778131,BI ASSET MANAGEMENT FONDSMAEGLERSELSKAB A/S',
    name: 'BI ASSET MANAGEMENT FONDSMAEGLERSELSKAB A/S',
    label: 'BI ASSET MANAGEMENT FONDSMAEGLERSELSKAB A/S',
  },
  {
    cik: '0001843527',
    value: '0001843527,B&I CAPITAL AG',
    name: 'B&I CAPITAL AG',
    label: 'B&I CAPITAL AG',
  },
  {
    cik: '0001844895',
    value: '0001844895,BICKLING FINANCIAL SERVICES, INC.',
    name: 'BICKLING FINANCIAL SERVICES, INC.',
    label: 'BICKLING FINANCIAL SERVICES, INC.',
  },
  {
    cik: '0001730808',
    value: '0001730808,BIECHELE ROYCE ADVISORS',
    name: 'BIECHELE ROYCE ADVISORS',
    label: 'BIECHELE ROYCE ADVISORS',
  },
  {
    cik: '0001122470',
    value: '0001122470,BIEGEL & WALLER LLC',
    name: 'BIEGEL & WALLER LLC',
    label: 'BIEGEL & WALLER LLC',
  },
  {
    cik: '0001605070',
    value: '0001605070,BIENVILLE CAPITAL MANAGEMENT, LLC',
    name: 'BIENVILLE CAPITAL MANAGEMENT, LLC',
    label: 'BIENVILLE CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001729516',
    value: '0001729516,BIGELOW INVESTMENT ADVISORS, LLC',
    name: 'BIGELOW INVESTMENT ADVISORS, LLC',
    label: 'BIGELOW INVESTMENT ADVISORS, LLC',
  },
  {
    cik: '0001334429',
    value: '0001334429,BIGLARI CAPITAL CORP.',
    name: 'BIGLARI CAPITAL CORP.',
    label: 'BIGLARI CAPITAL CORP.',
  },
  {
    cik: '0000093859',
    value: '0000093859,BIGLARI HOLDINGS INC.',
    name: 'BIGLARI HOLDINGS INC.',
    label: 'BIGLARI HOLDINGS INC.',
  },
  {
    cik: '0001334430',
    value: '0001334430,BIGLARI, SARDAR',
    name: 'BIGLARI, SARDAR',
    label: 'BIGLARI, SARDAR',
  },
  {
    cik: '0001659171',
    value: '0001659171,BIGSUR WEALTH MANAGEMENT LLC',
    name: 'BIGSUR WEALTH MANAGEMENT LLC',
    label: 'BIGSUR WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0001373017',
    value: '0001373017,BILLEAUD CAPITAL MANAGEMENT, INC.',
    name: 'BILLEAUD CAPITAL MANAGEMENT, INC.',
    label: 'BILLEAUD CAPITAL MANAGEMENT, INC.',
  },
  {
    cik: '0001914644',
    value: '0001914644,BILL FEW ASSOCIATES, INC.',
    name: 'BILL FEW ASSOCIATES, INC.',
    label: 'BILL FEW ASSOCIATES, INC.',
  },
  {
    cik: '0001578258',
    value: '0001578258,BILLINGS CAPITAL MANAGEMENT, LLC',
    name: 'BILLINGS CAPITAL MANAGEMENT, LLC',
    label: 'BILLINGS CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001663801',
    value: '0001663801,BILL & MELINDA GATES FOUNDATION',
    name: 'BILL & MELINDA GATES FOUNDATION',
    label: 'BILL & MELINDA GATES FOUNDATION',
  },
  {
    cik: '0001166559',
    value: '0001166559,BILL & MELINDA GATES FOUNDATION TRUST',
    name: 'BILL & MELINDA GATES FOUNDATION TRUST',
    label: 'BILL & MELINDA GATES FOUNDATION TRUST',
  },
  {
    cik: '0001626632',
    value: '0001626632,BILTMORE CAPITAL ADVISORS LLC',
    name: 'BILTMORE CAPITAL ADVISORS LLC',
    label: 'BILTMORE CAPITAL ADVISORS LLC',
  },
  {
    cik: '0001731123',
    value: '0001731123,BILTMORE FAMILY OFFICE, LLC',
    name: 'BILTMORE FAMILY OFFICE, LLC',
    label: 'BILTMORE FAMILY OFFICE, LLC',
  },
  {
    cik: '0001598102',
    value: '0001598102,BILTMORE WEALTH MANAGEMENT, LLC',
    name: 'BILTMORE WEALTH MANAGEMENT, LLC',
    label: 'BILTMORE WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001276673',
    value: '0001276673,BINGHAM OSBORN & SCARBOROUGH LLC',
    name: 'BINGHAM OSBORN & SCARBOROUGH LLC',
    label: 'BINGHAM OSBORN & SCARBOROUGH LLC',
  },
  {
    cik: '0001687078',
    value: '0001687078,BIOIMPACT CAPITAL LLC',
    name: 'BIOIMPACT CAPITAL LLC',
    label: 'BIOIMPACT CAPITAL LLC',
  },
  {
    cik: '0001667082',
    value: '0001667082,BIOMARK CAPITAL MANAGEMENT CO. LLC',
    name: 'BIOMARK CAPITAL MANAGEMENT CO. LLC',
    label: 'BIOMARK CAPITAL MANAGEMENT CO. LLC',
  },
  {
    cik: '0001455176',
    value: '0001455176,BIONDO INVESTMENT ADVISORS, LLC',
    name: 'BIONDO INVESTMENT ADVISORS, LLC',
    label: 'BIONDO INVESTMENT ADVISORS, LLC',
  },
  {
    cik: '0001509445',
    value: '0001509445,BI PARTNERS LLC',
    name: 'BI PARTNERS LLC',
    label: 'BI PARTNERS LLC',
  },
  {
    cik: '0001799859',
    value: '0001799859,BIRCH CAPITAL MANAGEMENT, LLC',
    name: 'BIRCH CAPITAL MANAGEMENT, LLC',
    label: 'BIRCH CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001840341',
    value: '0001840341,BIRCHCREEK WEALTH MANAGEMENT, LLC',
    name: 'BIRCHCREEK WEALTH MANAGEMENT, LLC',
    label: 'BIRCHCREEK WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001652355',
    value: '0001652355,BIRCH GROVE CAPITAL LP',
    name: 'BIRCH GROVE CAPITAL LP',
    label: 'BIRCH GROVE CAPITAL LP',
  },
  {
    cik: '0001424177',
    value: '0001424177,BIRCH HILL INVESTMENT ADVISORS LLC',
    name: 'BIRCH HILL INVESTMENT ADVISORS LLC',
    label: 'BIRCH HILL INVESTMENT ADVISORS LLC',
  },
  {
    cik: '0001541457',
    value: '0001541457,BIRCH RUN CAPITAL ADVISORS, LP',
    name: 'BIRCH RUN CAPITAL ADVISORS, LP',
    label: 'BIRCH RUN CAPITAL ADVISORS, LP',
  },
  {
    cik: '0001618205',
    value: '0001618205,BIRCHVIEW CAPITAL, LP',
    name: 'BIRCHVIEW CAPITAL, LP',
    label: 'BIRCHVIEW CAPITAL, LP',
  },
  {
    cik: '0001079738',
    value: '0001079738,BIRINYI ASSOCIATES INC',
    name: 'BIRINYI ASSOCIATES INC',
    label: 'BIRINYI ASSOCIATES INC',
  },
  {
    cik: '0000918504',
    value: '0000918504,BIRMINGHAM CAPITAL MANAGEMENT CO INC/AL',
    name: 'BIRMINGHAM CAPITAL MANAGEMENT CO INC/AL',
    label: 'BIRMINGHAM CAPITAL MANAGEMENT CO INC/AL',
  },
  {
    cik: '0001603276',
    value: '0001603276,BISHOP & CO INVESTMENT MANAGEMENT, LLC',
    name: 'BISHOP & CO INVESTMENT MANAGEMENT, LLC',
    label: 'BISHOP & CO INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001656001',
    value: '0001656001,BISHOP ROCK CAPITAL, L.P.',
    name: 'BISHOP ROCK CAPITAL, L.P.',
    label: 'BISHOP ROCK CAPITAL, L.P.',
  },
  {
    cik: '0001425041',
    value: '0001425041,BISLETT MANAGEMENT, LLC',
    name: 'BISLETT MANAGEMENT, LLC',
    label: 'BISLETT MANAGEMENT, LLC',
  },
  {
    cik: '0001633172',
    value: '0001633172,BISON WEALTH, LLC',
    name: 'BISON WEALTH, LLC',
    label: 'BISON WEALTH, LLC',
  },
  {
    cik: '0001911156',
    value: '0001911156,BIVIN & ASSOCIATES, INC.',
    name: 'BIVIN & ASSOCIATES, INC.',
    label: 'BIVIN & ASSOCIATES, INC.',
  },
  {
    cik: '0001946136',
    value: '0001946136,BIZMA INVESTIMENTOS LTDA',
    name: 'BIZMA INVESTIMENTOS LTDA',
    label: 'BIZMA INVESTIMENTOS LTDA',
  },
  {
    cik: '0001922884',
    value: '0001922884,BKA WEALTH CONSULTING, INC.',
    name: 'BKA WEALTH CONSULTING, INC.',
    label: 'BKA WEALTH CONSULTING, INC.',
  },
  {
    cik: '0001352864',
    value: '0001352864,BKD WEALTH ADVISORS, LLC',
    name: 'BKD WEALTH ADVISORS, LLC',
    label: 'BKD WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001634865',
    value: '0001634865,BKS ADVISORS, LLC',
    name: 'BKS ADVISORS, LLC',
    label: 'BKS ADVISORS, LLC',
  },
  {
    cik: '0001690186',
    value: '0001690186,BLACK-AND-WHITE CAPITAL LP',
    name: 'BLACK-AND-WHITE CAPITAL LP',
    label: 'BLACK-AND-WHITE CAPITAL LP',
  },
  {
    cik: '0001720745',
    value: '0001720745,BLACKCRANE CAPITAL, LLC',
    name: 'BLACKCRANE CAPITAL, LLC',
    label: 'BLACKCRANE CAPITAL, LLC',
  },
  {
    cik: '0001528593',
    value: '0001528593,BLACK CREEK INVESTMENT MANAGEMENT INC.',
    name: 'BLACK CREEK INVESTMENT MANAGEMENT INC.',
    label: 'BLACK CREEK INVESTMENT MANAGEMENT INC.',
  },
  {
    cik: '0001282197',
    value: '0001282197,BLACK DIAMOND CAPITAL MANAGEMENT, L.L.C.',
    name: 'BLACK DIAMOND CAPITAL MANAGEMENT, L.L.C.',
    label: 'BLACK DIAMOND CAPITAL MANAGEMENT, L.L.C.',
  },
  {
    cik: '0001759236',
    value: '0001759236,BLACK DIAMOND FINANCIAL, LLC',
    name: 'BLACK DIAMOND FINANCIAL, LLC',
    label: 'BLACK DIAMOND FINANCIAL, LLC',
  },
  {
    cik: '0001903059',
    value: '0001903059,BLACKDIAMOND WEALTH MANAGEMENT INC.',
    name: 'BLACKDIAMOND WEALTH MANAGEMENT INC.',
    label: 'BLACKDIAMOND WEALTH MANAGEMENT INC.',
  },
  {
    cik: '0001582561',
    value: '0001582561,BLACKHAWK CAPITAL PARTNERS LLC.',
    name: 'BLACKHAWK CAPITAL PARTNERS LLC.',
    label: 'BLACKHAWK CAPITAL PARTNERS LLC.',
  },
  {
    cik: '0000872162',
    value: '0000872162,BLACKHILL CAPITAL INC',
    name: 'BLACKHILL CAPITAL INC',
    label: 'BLACKHILL CAPITAL INC',
  },
  {
    cik: '0001588959',
    value: '0001588959,BLACK MAPLE CAPITAL MANAGEMENT LP',
    name: 'BLACK MAPLE CAPITAL MANAGEMENT LP',
    label: 'BLACK MAPLE CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001303241',
    value: '0001303241,BLACK RIVER ASSET MANAGEMENT LLC',
    name: 'BLACK RIVER ASSET MANAGEMENT LLC',
    label: 'BLACK RIVER ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001086364',
    value: '0001086364,BLACKROCK ADVISORS LLC',
    name: 'BLACKROCK ADVISORS LLC',
    label: 'BLACKROCK ADVISORS LLC',
  },
  {
    cik: '0001006249',
    value: '0001006249,BLACKROCK FUND ADVISORS',
    name: 'BLACKROCK FUND ADVISORS',
    label: 'BLACKROCK FUND ADVISORS',
  },
  {
    cik: '0001003283',
    value: '0001003283,BLACKROCK GROUP LTD',
    name: 'BLACKROCK GROUP LTD',
    label: 'BLACKROCK GROUP LTD',
  },
  {
    cik: '0001364742',
    value: '0001364742,BLACKROCK INC.',
    name: 'BLACKROCK INC.',
    label: 'BLACKROCK INC.',
  },
  {
    cik: '0000913414',
    value: '0000913414,BLACKROCK INSTITUTIONAL TRUST COMPANY, N.A.',
    name: 'BLACKROCK INSTITUTIONAL TRUST COMPANY, N.A.',
    label: 'BLACKROCK INSTITUTIONAL TRUST COMPANY, N.A.',
  },
  {
    cik: '0001305227',
    value: '0001305227,BLACKROCK INVESTMENT MANAGEMENT, LLC',
    name: 'BLACKROCK INVESTMENT MANAGEMENT, LLC',
    label: 'BLACKROCK INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001085635',
    value: '0001085635,BLACKROCK JAPAN CO. LTD',
    name: 'BLACKROCK JAPAN CO. LTD',
    label: 'BLACKROCK JAPAN CO. LTD',
  },
  {
    cik: '0001800249',
    value: '0001800249,BLACKSHEEP FUND MANAGEMENT LTD',
    name: 'BLACKSHEEP FUND MANAGEMENT LTD',
    label: 'BLACKSHEEP FUND MANAGEMENT LTD',
  },
  {
    cik: '0001666647',
    value: '0001666647,BLACKSTART CAPITAL LP',
    name: 'BLACKSTART CAPITAL LP',
    label: 'BLACKSTART CAPITAL LP',
  },
  {
    cik: '0001393818',
    value: '0001393818,BLACKSTONE GROUP INC',
    name: 'BLACKSTONE GROUP INC',
    label: 'BLACKSTONE GROUP INC',
  },
  {
    cik: '0001393818',
    value: '0001393818,BLACKSTONE INC.',
    name: 'BLACKSTONE INC.',
    label: 'BLACKSTONE INC.',
  },
  {
    cik: '0001910984',
    value: '0001910984,BLACKSTON FINANCIAL ADVISORY GROUP, LLC',
    name: 'BLACKSTON FINANCIAL ADVISORY GROUP, LLC',
    label: 'BLACKSTON FINANCIAL ADVISORY GROUP, LLC',
  },
  {
    cik: '0001704212',
    value: '0001704212,BLACK SWIFT GROUP, LLC',
    name: 'BLACK SWIFT GROUP, LLC',
    label: 'BLACK SWIFT GROUP, LLC',
  },
  {
    cik: '0001169738',
    value: '0001169738,BLACKTHORN INVESTMENT GROUP LLC',
    name: 'BLACKTHORN INVESTMENT GROUP LLC',
    label: 'BLACKTHORN INVESTMENT GROUP LLC',
  },
  {
    cik: '0000902367',
    value: '0000902367,BLAIR WILLIAM & CO/IL',
    name: 'BLAIR WILLIAM & CO/IL',
    label: 'BLAIR WILLIAM & CO/IL',
  },
  {
    cik: '0001708872',
    value: '0001708872,BLANKINSHIP & FOSTER, LLC',
    name: 'BLANKINSHIP & FOSTER, LLC',
    label: 'BLANKINSHIP & FOSTER, LLC',
  },
  {
    cik: '0001389059',
    value: '0001389059,BLB&B ADVISORS, LLC',
    name: 'BLB&B ADVISORS, LLC',
    label: 'BLB&B ADVISORS, LLC',
  },
  {
    cik: '0001781002',
    value: '0001781002,BLEICHROEDER LP',
    name: 'BLEICHROEDER LP',
    label: 'BLEICHROEDER LP',
  },
  {
    cik: '0001580101',
    value: '0001580101,BLENHEIM CAPITAL MANAGEMENT, BV',
    name: 'BLENHEIM CAPITAL MANAGEMENT, BV',
    label: 'BLENHEIM CAPITAL MANAGEMENT, BV',
  },
  {
    cik: '0001389080',
    value: '0001389080,BLENHEIM CAPITAL MANAGEMENT, L.L.C.',
    name: 'BLENHEIM CAPITAL MANAGEMENT, L.L.C.',
    label: 'BLENHEIM CAPITAL MANAGEMENT, L.L.C.',
  },
  {
    cik: '0001688153',
    value: '0001688153,BLOCKHOUSE CAPITAL MANAGEMENT LP',
    name: 'BLOCKHOUSE CAPITAL MANAGEMENT LP',
    label: 'BLOCKHOUSE CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001907826',
    value: '0001907826,BLODGETT WEALTH ADVISORS, LLC',
    name: 'BLODGETT WEALTH ADVISORS, LLC',
    label: 'BLODGETT WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001484047',
    value: '0001484047,BLOOMBERGSEN INC.',
    name: 'BLOOMBERGSEN INC.',
    label: 'BLOOMBERGSEN INC.',
  },
  {
    cik: '0001567752',
    value: '0001567752,BLOOM TREE PARTNERS, LLC',
    name: 'BLOOM TREE PARTNERS, LLC',
    label: 'BLOOM TREE PARTNERS, LLC',
  },
  {
    cik: '0001655156',
    value: '0001655156,BLOOOM, INC.',
    name: 'BLOOOM, INC.',
    label: 'BLOOOM, INC.',
  },
  {
    cik: '0001839735',
    value: '0001839735,BLOSSOM WEALTH MANAGEMENT',
    name: 'BLOSSOM WEALTH MANAGEMENT',
    label: 'BLOSSOM WEALTH MANAGEMENT',
  },
  {
    cik: '0001670104',
    value: '0001670104,BLS CAPITAL FONDSMAEGLERSELSKAB A/S',
    name: 'BLS CAPITAL FONDSMAEGLERSELSKAB A/S',
    label: 'BLS CAPITAL FONDSMAEGLERSELSKAB A/S',
  },
  {
    cik: '0001610946',
    value: '0001610946,BLS GLOBAL EQUITIES LLC',
    name: 'BLS GLOBAL EQUITIES LLC',
    label: 'BLS GLOBAL EQUITIES LLC',
  },
  {
    cik: '0001590935',
    value: '0001590935,BLUE ARROW CAPITAL MANAGEMENT LLC',
    name: 'BLUE ARROW CAPITAL MANAGEMENT LLC',
    label: 'BLUE ARROW CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001879206',
    value: '0001879206,BLUE BARN WEALTH, LLC',
    name: 'BLUE BARN WEALTH, LLC',
    label: 'BLUE BARN WEALTH, LLC',
  },
  {
    cik: '0001352860',
    value: '0001352860,BLUE BELL PRIVATE WEALTH MANAGEMENT, LLC',
    name: 'BLUE BELL PRIVATE WEALTH MANAGEMENT, LLC',
    label: 'BLUE BELL PRIVATE WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001632801',
    value: '0001632801,BLUE CHIP PARTNERS, INC.',
    name: 'BLUE CHIP PARTNERS, INC.',
    label: 'BLUE CHIP PARTNERS, INC.',
  },
  {
    cik: '0001740809',
    value: '0001740809,BLUE CHIP WEALTH MANAGEMENT, INC.',
    name: 'BLUE CHIP WEALTH MANAGEMENT, INC.',
    label: 'BLUE CHIP WEALTH MANAGEMENT, INC.',
  },
  {
    cik: '0001576160',
    value: '0001576160,BLUE CLAY CAPITAL MANAGEMENT, LLC',
    name: 'BLUE CLAY CAPITAL MANAGEMENT, LLC',
    label: 'BLUE CLAY CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001363542',
    value: '0001363542,BLUECREST CAPITAL MANAGEMENT LLP',
    name: 'BLUECREST CAPITAL MANAGEMENT LLP',
    label: 'BLUECREST CAPITAL MANAGEMENT LLP',
  },
  {
    cik: '0001610880',
    value: '0001610880,BLUECREST CAPITAL MANAGEMENT LTD',
    name: 'BLUECREST CAPITAL MANAGEMENT LTD',
    label: 'BLUECREST CAPITAL MANAGEMENT LTD',
  },
  {
    cik: '0001909147',
    value: '0001909147,BLUE DOOR ASSET MANAGEMENT, LLC',
    name: 'BLUE DOOR ASSET MANAGEMENT, LLC',
    label: 'BLUE DOOR ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001730235',
    value: '0001730235,BLUEDRIVE GLOBAL INVESTORS LLP',
    name: 'BLUEDRIVE GLOBAL INVESTORS LLP',
    label: 'BLUEDRIVE GLOBAL INVESTORS LLP',
  },
  {
    cik: '0001599620',
    value: '0001599620,BLUE EDGE CAPITAL, LLC',
    name: 'BLUE EDGE CAPITAL, LLC',
    label: 'BLUE EDGE CAPITAL, LLC',
  },
  {
    cik: '0001576102',
    value: '0001576102,BLUE FIN CAPITAL, INC.',
    name: 'BLUE FIN CAPITAL, INC.',
    label: 'BLUE FIN CAPITAL, INC.',
  },
  {
    cik: '0001144208',
    value: '0001144208,BLUEFIN CAPITAL MANAGEMENT, LLC',
    name: 'BLUEFIN CAPITAL MANAGEMENT, LLC',
    label: 'BLUEFIN CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001532351',
    value: '0001532351,BLUEFIN TRADING, LLC',
    name: 'BLUEFIN TRADING, LLC',
    label: 'BLUEFIN TRADING, LLC',
  },
  {
    cik: '0001696548',
    value: '0001696548,BLUE GRANITE CAPITAL, LLC',
    name: 'BLUE GRANITE CAPITAL, LLC',
    label: 'BLUE GRANITE CAPITAL, LLC',
  },
  {
    cik: '0001727353',
    value: '0001727353,BLUEGRASS CAPITAL PARTNERS LP',
    name: 'BLUEGRASS CAPITAL PARTNERS LP',
    label: 'BLUEGRASS CAPITAL PARTNERS LP',
  },
  {
    cik: '0001764725',
    value: '0001764725,BLUE GROTTO CAPITAL, LLC',
    name: 'BLUE GROTTO CAPITAL, LLC',
    label: 'BLUE GROTTO CAPITAL, LLC',
  },
  {
    cik: '0001325256',
    value: '0001325256,BLUE HARBOUR GROUP, L.P.',
    name: 'BLUE HARBOUR GROUP, L.P.',
    label: 'BLUE HARBOUR GROUP, L.P.',
  },
  {
    cik: '0001586444',
    value: '0001586444,BLUE JAY CAPITAL MANAGEMENT, LLC',
    name: 'BLUE JAY CAPITAL MANAGEMENT, LLC',
    label: 'BLUE JAY CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001582635',
    value: '0001582635,BLUEMAR CAPITAL MANAGEMENT, LLC',
    name: 'BLUEMAR CAPITAL MANAGEMENT, LLC',
    label: 'BLUEMAR CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001427430',
    value: '0001427430,BLUEMOUNTAIN CAPITAL MANAGEMENT, LLC',
    name: 'BLUEMOUNTAIN CAPITAL MANAGEMENT, LLC',
    label: 'BLUEMOUNTAIN CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001858703',
    value: '0001858703,BLUE OWL CAPITAL HOLDINGS LP',
    name: 'BLUE OWL CAPITAL HOLDINGS LP',
    label: 'BLUE OWL CAPITAL HOLDINGS LP',
  },
  {
    cik: '0001675855',
    value: '0001675855,BLUE POOL MANAGEMENT LTD.',
    name: 'BLUE POOL MANAGEMENT LTD.',
    label: 'BLUE POOL MANAGEMENT LTD.',
  },
  {
    cik: '0001702175',
    value: '0001702175,BLUEPORT CAPITAL, L.P.',
    name: 'BLUEPORT CAPITAL, L.P.',
    label: 'BLUEPORT CAPITAL, L.P.',
  },
  {
    cik: '0001794467',
    value: '0001794467,BLUEPRINT INVESTMENT PARTNERS LLC',
    name: 'BLUEPRINT INVESTMENT PARTNERS LLC',
    label: 'BLUEPRINT INVESTMENT PARTNERS LLC',
  },
  {
    cik: '0001062589',
    value: '0001062589,BLUE RIDGE CAPITAL, L.L.C.',
    name: 'BLUE RIDGE CAPITAL, L.L.C.',
    label: 'BLUE RIDGE CAPITAL, L.L.C.',
  },
  {
    cik: '0001296494',
    value: '0001296494,BLUE ROCK ADVISORS, INC.',
    name: 'BLUE ROCK ADVISORS, INC.',
    label: 'BLUE ROCK ADVISORS, INC.',
  },
  {
    cik: '0001666551',
    value: '0001666551,BLUE ROCK ADVISORS, LLC',
    name: 'BLUE ROCK ADVISORS, LLC',
    label: 'BLUE ROCK ADVISORS, LLC',
  },
  {
    cik: '0001629283',
    value: '0001629283,BLUESCAPE ENERGY PARTNERS LLC',
    name: 'BLUESCAPE ENERGY PARTNERS LLC',
    label: 'BLUESCAPE ENERGY PARTNERS LLC',
  },
  {
    cik: '0001727827',
    value: '0001727827,BLUESHIFT ASSET MANAGEMENT, LLC',
    name: 'BLUESHIFT ASSET MANAGEMENT, LLC',
    label: 'BLUESHIFT ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001634301',
    value: '0001634301,BLUE SKY ASSET MANAGEMENT, LLC',
    name: 'BLUE SKY ASSET MANAGEMENT, LLC',
    label: 'BLUE SKY ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001704107',
    value: '0001704107,BLUESKY WEALTH ADVISORS, LLC',
    name: 'BLUESKY WEALTH ADVISORS, LLC',
    label: 'BLUESKY WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001844108',
    value: '0001844108,BLUESPHERE ADVISORS, LLC',
    name: 'BLUESPHERE ADVISORS, LLC',
    label: 'BLUESPHERE ADVISORS, LLC',
  },
  {
    cik: '0001600285',
    value: '0001600285,BLUESPRUCE INVESTMENTS, LP',
    name: 'BLUESPRUCE INVESTMENTS, LP',
    label: 'BLUESPRUCE INVESTMENTS, LP',
  },
  {
    cik: '0001769578',
    value: '0001769578,BLUE SQUARE ASSET MANAGEMENT, LLC',
    name: 'BLUE SQUARE ASSET MANAGEMENT, LLC',
    label: 'BLUE SQUARE ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0000884314',
    value: '0000884314,BLUESTEIN R H & CO',
    name: 'BLUESTEIN R H & CO',
    label: 'BLUESTEIN R H & CO',
  },
  {
    cik: '0000884314',
    value: '0000884314,BLUESTEIN R H & CO LLC',
    name: 'BLUESTEIN R H & CO LLC',
    label: 'BLUESTEIN R H & CO LLC',
  },
  {
    cik: '0001820680',
    value: '0001820680,BLUESTEM FINANCIAL ADVISORS, LLC',
    name: 'BLUESTEM FINANCIAL ADVISORS, LLC',
    label: 'BLUESTEM FINANCIAL ADVISORS, LLC',
  },
  {
    cik: '0001789778',
    value: '0001789778,BLUE WATER LIFE SCIENCE ADVISORS, LLC',
    name: 'BLUE WATER LIFE SCIENCE ADVISORS, LLC',
    label: 'BLUE WATER LIFE SCIENCE ADVISORS, LLC',
  },
  {
    cik: '0001789778',
    value: '0001789778,BLUE WATER LIFE SCIENCE ADVISORS, LP',
    name: 'BLUE WATER LIFE SCIENCE ADVISORS, LP',
    label: 'BLUE WATER LIFE SCIENCE ADVISORS, LP',
  },
  {
    cik: '0001801547',
    value: '0001801547,BLUE WHALE CAPITAL LLP',
    name: 'BLUE WHALE CAPITAL LLP',
    label: 'BLUE WHALE CAPITAL LLP',
  },
  {
    cik: '0001803106',
    value: '0001803106,BLUE ZONE WEALTH ADVISORS, LLC',
    name: 'BLUE ZONE WEALTH ADVISORS, LLC',
    label: 'BLUE ZONE WEALTH ADVISORS, LLC',
  },
  {
    cik: '0000938775',
    value: '0000938775,BLUM CAPITAL PARTNERS LP',
    name: 'BLUM CAPITAL PARTNERS LP',
    label: 'BLUM CAPITAL PARTNERS LP',
  },
  {
    cik: '0001587192',
    value: '0001587192,BLUME CAPITAL MANAGEMENT, INC.',
    name: 'BLUME CAPITAL MANAGEMENT, INC.',
    label: 'BLUME CAPITAL MANAGEMENT, INC.',
  },
  {
    cik: '0001847838',
    value: '0001847838,B. METZLER SEEL. SOHN & CO. AG',
    name: 'B. METZLER SEEL. SOHN & CO. AG',
    label: 'B. METZLER SEEL. SOHN & CO. AG',
  },
  {
    cik: '0001828929',
    value: '0001828929,B. METZLER SEEL. SOHN & CO. HOLDING AG',
    name: 'B. METZLER SEEL. SOHN & CO. HOLDING AG',
    label: 'B. METZLER SEEL. SOHN & CO. HOLDING AG',
  },
  {
    cik: '0000318703',
    value: '0000318703,BMI CAPITAL CORP',
    name: 'BMI CAPITAL CORP',
    label: 'BMI CAPITAL CORP',
  },
  {
    cik: '0001928041',
    value: '0001928041,BMS FINANCIAL ADVISORS, LLC',
    name: 'BMS FINANCIAL ADVISORS, LLC',
    label: 'BMS FINANCIAL ADVISORS, LLC',
  },
  {
    cik: '0001772453',
    value: '0001772453,BMT INVESTMENT ADVISORS',
    name: 'BMT INVESTMENT ADVISORS',
    label: 'BMT INVESTMENT ADVISORS',
  },
  {
    cik: '0001821549',
    value: '0001821549,BNC WEALTH MANAGEMENT, LLC',
    name: 'BNC WEALTH MANAGEMENT, LLC',
    label: 'BNC WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001166588',
    value: '0001166588,BNP PARIBAS ARBITRAGE, SA',
    name: 'BNP PARIBAS ARBITRAGE, SA',
    label: 'BNP PARIBAS ARBITRAGE, SA',
  },
  {
    cik: '0001166588',
    value: '0001166588,BNP PARIBAS ARBITRAGE, SNC',
    name: 'BNP PARIBAS ARBITRAGE, SNC',
    label: 'BNP PARIBAS ARBITRAGE, SNC',
  },
  {
    cik: '0001520354',
    value: '0001520354,BNP PARIBAS ASSET MANAGEMENT HOLDING S.A.',
    name: 'BNP PARIBAS ASSET MANAGEMENT HOLDING S.A.',
    label: 'BNP PARIBAS ASSET MANAGEMENT HOLDING S.A.',
  },
  {
    cik: '0001602987',
    value: '0001602987,BOARDMAN BAY CAPITAL MANAGEMENT LLC',
    name: 'BOARDMAN BAY CAPITAL MANAGEMENT LLC',
    label: 'BOARDMAN BAY CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001315828',
    value:
      '0001315828,BOARD OF TRUSTEES OF THE LELAND STANFORD JUNIOR UNIVERSITY',
    name: 'BOARD OF TRUSTEES OF THE LELAND STANFORD JUNIOR UNIVERSITY',
    label: 'BOARD OF TRUSTEES OF THE LELAND STANFORD JUNIOR UNIVERSITY',
  },
  {
    cik: '0001534017',
    value: '0001534017,BOCAGE CAPITAL, LLC',
    name: 'BOCAGE CAPITAL, LLC',
    label: 'BOCAGE CAPITAL, LLC',
  },
  {
    cik: '0001689418',
    value: '0001689418,BODENHOLM CAPITAL AB',
    name: 'BODENHOLM CAPITAL AB',
    label: 'BODENHOLM CAPITAL AB',
  },
  {
    cik: '0001385080',
    value: '0001385080,BODRI CAPITAL MANAGEMENT, LLC',
    name: 'BODRI CAPITAL MANAGEMENT, LLC',
    label: 'BODRI CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0000012933',
    value: '0000012933,BOENNING & SCATTERGOOD, INC.',
    name: 'BOENNING & SCATTERGOOD, INC.',
    label: 'BOENNING & SCATTERGOOD, INC.',
  },
  {
    cik: '0001715740',
    value: '0001715740,BOGART WEALTH, LLC',
    name: 'BOGART WEALTH, LLC',
    label: 'BOGART WEALTH, LLC',
  },
  {
    cik: '0001131667',
    value: '0001131667,BOGLE INVESTMENT MANAGEMENT L P /DE/',
    name: 'BOGLE INVESTMENT MANAGEMENT L P /DE/',
    label: 'BOGLE INVESTMENT MANAGEMENT L P /DE/',
  },
  {
    cik: '0001308331',
    value: '0001308331,BOIT C F DAVID',
    name: 'BOIT C F DAVID',
    label: 'BOIT C F DAVID',
  },
  {
    cik: '0001317348',
    value: '0001317348,BOK FINANCIAL PRIVATE WEALTH, INC.',
    name: 'BOK FINANCIAL PRIVATE WEALTH, INC.',
    label: 'BOK FINANCIAL PRIVATE WEALTH, INC.',
  },
  {
    cik: '0000009634',
    value: '0000009,BOKF, NA4',
    name: 'BOKF, NA',
    label: 'BOKF, NA',
  },
  {
    cik: '0001556218',
    value: '0001556218,BOLLARD GROUP LLC',
    name: 'BOLLARD GROUP LLC',
    label: 'BOLLARD GROUP LLC',
  },
  {
    cik: '0001591625',
    value: '00015916,BOLTON LP',
    name: 'BOLTON LP',
    label: 'BOLTON LP',
  },
  {
    cik: '0001563690',
    value: '0001563690,BOLTWOOD CAPITAL MANAGEMENT',
    name: 'BOLTWOOD CAPITAL MANAGEMENT',
    label: 'BOLTWOOD CAPITAL MANAGEMENT',
  },
  {
    cik: '0001923934',
    value: '0001923934,BOND CAPITAL MANAGEMENT, LP',
    name: 'BOND CAPITAL MANAGEMENT, LP',
    label: 'BOND CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0001843495',
    value: '0001843495,BOND & DEVICK FINANCIAL NETWORK, INC.',
    name: 'BOND & DEVICK FINANCIAL NETWORK, INC.',
    label: 'BOND & DEVICK FINANCIAL NETWORK, INC.',
  },
  {
    cik: '0001105471',
    value: '0001105471,BONNESS ENTERPRISES INC',
    name: 'BONNESS ENTERPRISES INC',
    label: 'BONNESS ENTERPRISES INC',
  },
  {
    cik: '0001632118',
    value: '0001632118,BONTEMPO OHLY CAPITAL MGMT LLC',
    name: 'BONTEMPO OHLY CAPITAL MGMT LLC',
    label: 'BONTEMPO OHLY CAPITAL MGMT LLC',
  },
  {
    cik: '0001549230',
    value: '0001549230,BOOTHBAY FUND MANAGEMENT, LLC',
    name: 'BOOTHBAY FUND MANAGEMENT, LLC',
    label: 'BOOTHBAY FUND MANAGEMENT, LLC',
  },
  {
    cik: '0001749798',
    value: '0001749798,BOOTHE INVESTMENT GROUP, INC.',
    name: 'BOOTHE INVESTMENT GROUP, INC.',
    label: 'BOOTHE INVESTMENT GROUP, INC.',
  },
  {
    cik: '0001620636',
    value: '0001620636,BOOTH PARK CAPITAL MANAGEMENT LLC',
    name: 'BOOTH PARK CAPITAL MANAGEMENT LLC',
    label: 'BOOTH PARK CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001821271',
    value: '0001821271,BORDEAUX WEALTH ADVISORS LLC',
    name: 'BORDEAUX WEALTH ADVISORS LLC',
    label: 'BORDEAUX WEALTH ADVISORS LLC',
  },
  {
    cik: '0001876496',
    value: '0001876496,BORDER TO COAST PENSIONS PARTNERSHIP LTD',
    name: 'BORDER TO COAST PENSIONS PARTNERSHIP LTD',
    label: 'BORDER TO COAST PENSIONS PARTNERSHIP LTD',
  },
  {
    cik: '0001800641',
    value: '0001800641,BORNITE CAPITAL MANAGEMENT LP',
    name: 'BORNITE CAPITAL MANAGEMENT LP',
    label: 'BORNITE CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001062842',
    value: '0001062842,BOSTON ADVISORS LLC',
    name: 'BOSTON ADVISORS LLC',
    label: 'BOSTON ADVISORS LLC',
  },
  {
    cik: '0001409427',
    value: '0001409427,BOSTON COMMON ASSET MANAGEMENT, LLC',
    name: 'BOSTON COMMON ASSET MANAGEMENT, LLC',
    label: 'BOSTON COMMON ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001039807',
    value: '0001039807,BOSTON FAMILY OFFICE LLC',
    name: 'BOSTON FAMILY OFFICE LLC',
    label: 'BOSTON FAMILY OFFICE LLC',
  },
  {
    cik: '0001053055',
    value: '0001053055,BOSTON FINANCIAL MANGEMENT INC /MA',
    name: 'BOSTON FINANCIAL MANGEMENT INC /MA',
    label: 'BOSTON FINANCIAL MANGEMENT INC /MA',
  },
  {
    cik: '0001053055',
    value: '0001053055,BOSTON FINANCIAL MANGEMENT LLC',
    name: 'BOSTON FINANCIAL MANGEMENT LLC',
    label: 'BOSTON FINANCIAL MANGEMENT LLC',
  },
  {
    cik: '0001386060',
    value: '0001386060,BOSTON PARTNERS',
    name: 'BOSTON PARTNERS',
    label: 'BOSTON PARTNERS',
  },
  {
    cik: '0001172928',
    value: '0001172928,BOSTON PRIVATE BANK & TRUST CO',
    name: 'BOSTON PRIVATE BANK & TRUST CO',
    label: 'BOSTON PRIVATE BANK & TRUST CO',
  },
  {
    cik: '0001626116',
    value: '0001626116,BOSTON PRIVATE WEALTH LLC',
    name: 'BOSTON PRIVATE WEALTH LLC',
    label: 'BOSTON PRIVATE WEALTH LLC',
  },
  {
    cik: '0000931328',
    value: '0000931328,BOSTON PROVIDENT, L.P.',
    name: 'BOSTON PROVIDENT, L.P.',
    label: 'BOSTON PROVIDENT, L.P.',
  },
  {
    cik: '0001259261',
    value: '0001259261,BOSTON RESEARCH & MANAGEMENT INC',
    name: 'BOSTON RESEARCH & MANAGEMENT INC',
    label: 'BOSTON RESEARCH & MANAGEMENT INC',
  },
  {
    cik: '0001630939',
    value: '0001630939,BOSTON STANDARD WEALTH MANAGEMENT, LLC',
    name: 'BOSTON STANDARD WEALTH MANAGEMENT, LLC',
    label: 'BOSTON STANDARD WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001534866',
    value: '0001534866,BOSTON TRUST WALDEN CORP',
    name: 'BOSTON TRUST WALDEN CORP',
    label: 'BOSTON TRUST WALDEN CORP',
  },
  {
    cik: '0001720887',
    value: '0001720887,BOSVALEN ASSET MANAGEMENT LTD',
    name: 'BOSVALEN ASSET MANAGEMENT LTD',
    label: 'BOSVALEN ASSET MANAGEMENT LTD',
  },
  {
    cik: '0001483472',
    value: '0001483472,BOTTY INVESTORS LLC',
    name: 'BOTTY INVESTORS LLC',
    label: 'BOTTY INVESTORS LLC',
  },
  {
    cik: '0001599054',
    value: '0001599054,BOUCHEY FINANCIAL GROUP LTD',
    name: 'BOUCHEY FINANCIAL GROUP LTD',
    label: 'BOUCHEY FINANCIAL GROUP LTD',
  },
  {
    cik: '0001859029',
    value: '0001859029,BOULDER HILL CAPITAL MANAGEMENT LP',
    name: 'BOULDER HILL CAPITAL MANAGEMENT LP',
    label: 'BOULDER HILL CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001620916',
    value: '0001620916,BOULEGERIS INVESTMENTS, INC.',
    name: 'BOULEGERIS INVESTMENTS, INC.',
    label: 'BOULEGERIS INVESTMENTS, INC.',
  },
  {
    cik: '0001771944',
    value: '0001771944,BOUNDARY CREEK ADVISORS LP',
    name: 'BOUNDARY CREEK ADVISORS LP',
    label: 'BOUNDARY CREEK ADVISORS LP',
  },
  {
    cik: '0001088731',
    value: '0001088731,BOURGEON CAPITAL MANAGEMENT LLC',
    name: 'BOURGEON CAPITAL MANAGEMENT LLC',
    label: 'BOURGEON CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001128286',
    value: '0001128286,BOURNE LENT ASSET MANAGEMENT INC',
    name: 'BOURNE LENT ASSET MANAGEMENT INC',
    label: 'BOURNE LENT ASSET MANAGEMENT INC',
  },
  {
    cik: '0001598175',
    value: '0001598175,BOUSSARD & GAVAUDAN ASSET MANAGEMENT LP',
    name: 'BOUSSARD & GAVAUDAN ASSET MANAGEMENT LP',
    label: 'BOUSSARD & GAVAUDAN ASSET MANAGEMENT LP',
  },
  {
    cik: '0001614986',
    value: '0001614986,BOUSSARD & GAVAUDAN INVESTMENT MANAGEMENT LLP',
    name: 'BOUSSARD & GAVAUDAN INVESTMENT MANAGEMENT LLP',
    label: 'BOUSSARD & GAVAUDAN INVESTMENT MANAGEMENT LLP',
  },
  {
    cik: '0001839122',
    value: '0001839122,BOUVEL INVESTMENT PARTNERS, LLC',
    name: 'BOUVEL INVESTMENT PARTNERS, LLC',
    label: 'BOUVEL INVESTMENT PARTNERS, LLC',
  },
  {
    cik: '0001077583',
    value: '0001077583,BOWEN HANES & CO INC',
    name: 'BOWEN HANES & CO INC',
    label: 'BOWEN HANES & CO INC',
  },
  {
    cik: '0001691982',
    value: '0001691982,BOWIE CAPITAL MANAGEMENT, LLC',
    name: 'BOWIE CAPITAL MANAGEMENT, LLC',
    label: 'BOWIE CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001322590',
    value: '0001322590,BOWLING PORTFOLIO MANAGEMENT LLC',
    name: 'BOWLING PORTFOLIO MANAGEMENT LLC',
    label: 'BOWLING PORTFOLIO MANAGEMENT LLC',
  },
  {
    cik: '0001906275',
    value: '0001906275,BOWMAN & CO S.C.',
    name: 'BOWMAN & CO S.C.',
    label: 'BOWMAN & CO S.C.',
  },
  {
    cik: '0001578964',
    value: '0001578964,BOW STREET, LLC',
    name: 'BOW STREET, LLC',
    label: 'BOW STREET, LLC',
  },
  {
    cik: '0001465837',
    value: '0001465837,BOXER CAPITAL, LLC',
    name: 'BOXER CAPITAL, LLC',
    label: 'BOXER CAPITAL, LLC',
  },
  {
    cik: '0001885946',
    value: '0001885946,BOXWOOD VENTURES, INC.',
    name: 'BOXWOOD VENTURES, INC.',
    label: 'BOXWOOD VENTURES, INC.',
  },
  {
    cik: '0001218254',
    value: '0001218254,BOYAR ASSET MANAGEMENT INC',
    name: 'BOYAR ASSET MANAGEMENT INC',
    label: 'BOYAR ASSET MANAGEMENT INC',
  },
  {
    cik: '0001218254',
    value: '0001218254,BOYAR ASSET MANAGEMENT INC.',
    name: 'BOYAR ASSET MANAGEMENT INC.',
    label: 'BOYAR ASSET MANAGEMENT INC.',
  },
  {
    cik: '0001121477',
    value: '0001121477,BOYD WATTERSON ASSET MANAGEMENT LLC/OH',
    name: 'BOYD WATTERSON ASSET MANAGEMENT LLC/OH',
    label: 'BOYD WATTERSON ASSET MANAGEMENT LLC/OH',
  },
  {
    cik: '0001846114',
    value: '0001846114,BOYD WEALTH MANAGEMENT, LLC',
    name: 'BOYD WEALTH MANAGEMENT, LLC',
    label: 'BOYD WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001542165',
    value: '0001542165,BOYER & CORPORON WEALTH MANAGEMENT, LLC',
    name: 'BOYER & CORPORON WEALTH MANAGEMENT, LLC',
    label: 'BOYER & CORPORON WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001573485',
    value: '0001573485,BOYLE CAPITAL MANAGEMENT, L.L.C.',
    name: 'BOYLE CAPITAL MANAGEMENT, L.L.C.',
    label: 'BOYLE CAPITAL MANAGEMENT, L.L.C.',
  },
  {
    cik: '0001082020',
    value: '0001082020,BOYS ARNOLD & CO INC',
    name: 'BOYS ARNOLD & CO INC',
    label: 'BOYS ARNOLD & CO INC',
  },
  {
    cik: '0001844799',
    value: '0001844799,BOYU CAPITAL INVESTMENT MANAGEMENT CO., LTD',
    name: 'BOYU CAPITAL INVESTMENT MANAGEMENT CO., LTD',
    label: 'BOYU CAPITAL INVESTMENT MANAGEMENT CO., LTD',
  },
  {
    cik: '0001654710',
    value: '0001654710,BP CAPITAL FUND ADVISORS, LLC',
    name: 'BP CAPITAL FUND ADVISORS, LLC',
    label: 'BP CAPITAL FUND ADVISORS, LLC',
  },
  {
    cik: '0001731118',
    value: '0001731118,BPIFRANCE SA',
    name: 'BPIFRANCE SA',
    label: 'BPIFRANCE SA',
  },
  {
    cik: '0000313807',
    value: '00003,BP PLC807',
    name: 'BP PLC',
    label: 'BP PLC',
  },
  {
    cik: '0001513702',
    value: '0001513702,BPV RESOURCES, LLC',
    name: 'BPV RESOURCES, LLC',
    label: 'BPV RESOURCES, LLC',
  },
  {
    cik: '0001626116',
    value: '0001626116,BP WEALTH MANAGEMENT, LLC',
    name: 'BP WEALTH MANAGEMENT, LLC',
    label: 'BP WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001426486',
    value: '0001426486,BRACEBRIDGE CAPITAL, LLC',
    name: 'BRACEBRIDGE CAPITAL, LLC',
    label: 'BRACEBRIDGE CAPITAL, LLC',
  },
  {
    cik: '0001861678',
    value: '0001861678,BRADLEY & CO. PRIVATE WEALTH MANAGEMENT, LLC',
    name: 'BRADLEY & CO. PRIVATE WEALTH MANAGEMENT, LLC',
    label: 'BRADLEY & CO. PRIVATE WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001015086',
    value: '0001015086,BRADLEY FOSTER & SARGENT INC/CT',
    name: 'BRADLEY FOSTER & SARGENT INC/CT',
    label: 'BRADLEY FOSTER & SARGENT INC/CT',
  },
  {
    cik: '0001846333',
    value: '0001846333,BRADLEY MARK J.',
    name: 'BRADLEY MARK J.',
    label: 'BRADLEY MARK J.',
  },
  {
    cik: '0001427683',
    value: '0001427683,BRAESIDE INVESTMENTS, LLC',
    name: 'BRAESIDE INVESTMENTS, LLC',
    label: 'BRAESIDE INVESTMENTS, LLC',
  },
  {
    cik: '0001327055',
    value: '0001327055,BRAGG FINANCIAL ADVISORS, INC',
    name: 'BRAGG FINANCIAL ADVISORS, INC',
    label: 'BRAGG FINANCIAL ADVISORS, INC',
  },
  {
    cik: '0001014894',
    value: '0001014894,BRAHMAN CAPITAL CORP.',
    name: 'BRAHMAN CAPITAL CORP.',
    label: 'BRAHMAN CAPITAL CORP.',
  },
  {
    cik: '0001619899',
    value: '0001619899,BRAMSHILL INVESTMENTS, LLC',
    name: 'BRAMSHILL INVESTMENTS, LLC',
    label: 'BRAMSHILL INVESTMENTS, LLC',
  },
  {
    cik: '0001748766',
    value: '0001748766,BRAND ASSET MANAGEMENT GROUP, INC.',
    name: 'BRAND ASSET MANAGEMENT GROUP, INC.',
    label: 'BRAND ASSET MANAGEMENT GROUP, INC.',
  },
  {
    cik: '0001015079',
    value: '0001015079,BRANDES INVESTMENT PARTNERS, LP',
    name: 'BRANDES INVESTMENT PARTNERS, LP',
    label: 'BRANDES INVESTMENT PARTNERS, LP',
  },
  {
    cik: '0001722657',
    value: '0001722657,BRANDYTRUST GLOBAL PARTNERS, LP',
    name: 'BRANDYTRUST GLOBAL PARTNERS, LP',
    label: 'BRANDYTRUST GLOBAL PARTNERS, LP',
  },
  {
    cik: '0000829108',
    value: '0000829108,BRANDYWINE GLOBAL INVESTMENT MANAGEMENT, LLC',
    name: 'BRANDYWINE GLOBAL INVESTMENT MANAGEMENT, LLC',
    label: 'BRANDYWINE GLOBAL INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001135121',
    value: '0001135121,BRANDYWINE MANAGERS, LLC',
    name: 'BRANDYWINE MANAGERS, LLC',
    label: 'BRANDYWINE MANAGERS, LLC',
  },
  {
    cik: '0001764694',
    value: '0001764694,BRANDYWINE OAK PRIVATE WEALTH LLC',
    name: 'BRANDYWINE OAK PRIVATE WEALTH LLC',
    label: 'BRANDYWINE OAK PRIVATE WEALTH LLC',
  },
  {
    cik: '0001123803',
    value: '0001123803,BRANDYWINE TRUST CO',
    name: 'BRANDYWINE TRUST CO',
    label: 'BRANDYWINE TRUST CO',
  },
  {
    cik: '0001279030',
    value: '0001279030,BRANT POINT INVESTMENT MANAGEMENT LLC',
    name: 'BRANT POINT INVESTMENT MANAGEMENT LLC',
    label: 'BRANT POINT INVESTMENT MANAGEMENT LLC',
  },
  {
    cik: '0001643833',
    value: '0001643833,BRASADA CAPITAL MANAGEMENT, LP',
    name: 'BRASADA CAPITAL MANAGEMENT, LP',
    label: 'BRASADA CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0001723787',
    value: '0001723787,BRATTON CAPITAL MANAGEMENT, L.P.',
    name: 'BRATTON CAPITAL MANAGEMENT, L.P.',
    label: 'BRATTON CAPITAL MANAGEMENT, L.P.',
  },
  {
    cik: '0001740842',
    value: '0001740842,BRAUN-BOSTICH & ASSOCIATES INC.',
    name: 'BRAUN-BOSTICH & ASSOCIATES INC.',
    label: 'BRAUN-BOSTICH & ASSOCIATES INC.',
  },
  {
    cik: '0000872080',
    value: '0000872080,BRAUN STACEY ASSOCIATES INC',
    name: 'BRAUN STACEY ASSOCIATES INC',
    label: 'BRAUN STACEY ASSOCIATES INC',
  },
  {
    cik: '0001085867',
    value: '0001085867,BRAVE ASSET MANAGEMENT INC',
    name: 'BRAVE ASSET MANAGEMENT INC',
    label: 'BRAVE ASSET MANAGEMENT INC',
  },
  {
    cik: '0001566494',
    value: '0001566494,BRAVER WEALTH MANAGEMENT, LLC',
    name: 'BRAVER WEALTH MANAGEMENT, LLC',
    label: 'BRAVER WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001553733',
    value: '0001553733,BRAVE WARRIOR ADVISORS, LLC',
    name: 'BRAVE WARRIOR ADVISORS, LLC',
    label: 'BRAVE WARRIOR ADVISORS, LLC',
  },
  {
    cik: '0001631738',
    value: '0001631738,BRAY CAPITAL ADVISORS',
    name: 'BRAY CAPITAL ADVISORS',
    label: 'BRAY CAPITAL ADVISORS',
  },
  {
    cik: '0001332160',
    value: '0001332160,BRC INVESTMENT MANAGEMENT LLC',
    name: 'BRC INVESTMENT MANAGEMENT LLC',
    label: 'BRC INVESTMENT MANAGEMENT LLC',
  },
  {
    cik: '0001702973',
    value: '0001702973,BREAKLINE CAPITAL LLC',
    name: 'BREAKLINE CAPITAL LLC',
    label: 'BREAKLINE CAPITAL LLC',
  },
  {
    cik: '0001710440',
    value: '0001710440,BREDIN INVESTMENT LLC',
    name: 'BREDIN INVESTMENT LLC',
    label: 'BREDIN INVESTMENT LLC',
  },
  {
    cik: '0001814533',
    value: '0001814533,BREGAL INVESTMENTS, INC.',
    name: 'BREGAL INVESTMENTS, INC.',
    label: 'BREGAL INVESTMENTS, INC.',
  },
  {
    cik: '0001814601',
    value: '0001814601,BREGAL NORTH AMERICA GENERAL PARTNER JERSEY LTD',
    name: 'BREGAL NORTH AMERICA GENERAL PARTNER JERSEY LTD',
    label: 'BREGAL NORTH AMERICA GENERAL PARTNER JERSEY LTD',
  },
  {
    cik: '0001814733',
    value: '0001814733,BREGAL SAGEMOUNT I, L.P.',
    name: 'BREGAL SAGEMOUNT I, L.P.',
    label: 'BREGAL SAGEMOUNT I, L.P.',
  },
  {
    cik: '0001105410',
    value: '0001105410,BREITER CAPITAL MANAGEMENT INC',
    name: 'BREITER CAPITAL MANAGEMENT INC',
    label: 'BREITER CAPITAL MANAGEMENT INC',
  },
  {
    cik: '0001483060',
    value: '0001483060,BREITHORN CAPITAL MANAGEMENT',
    name: 'BREITHORN CAPITAL MANAGEMENT',
    label: 'BREITHORN CAPITAL MANAGEMENT',
  },
  {
    cik: '0001775321',
    value: '0001775321,BREMER BANK NATIONAL ASSOCIATION',
    name: 'BREMER BANK NATIONAL ASSOCIATION',
    label: 'BREMER BANK NATIONAL ASSOCIATION',
  },
  {
    cik: '0001086555',
    value: '0001086555,BREMER TRUST NATIONAL ASSOCIATION',
    name: 'BREMER TRUST NATIONAL ASSOCIATION',
    label: 'BREMER TRUST NATIONAL ASSOCIATION',
  },
  {
    cik: '0001768065',
    value: '0001768065,BRENDEL FINANCIAL ADVISORS LLC',
    name: 'BRENDEL FINANCIAL ADVISORS LLC',
    label: 'BRENDEL FINANCIAL ADVISORS LLC',
  },
  {
    cik: '0001599329',
    value: '0001599329,BRENHAM CAPITAL MANAGEMENT, L.P.',
    name: 'BRENHAM CAPITAL MANAGEMENT, L.P.',
    label: 'BRENHAM CAPITAL MANAGEMENT, L.P.',
  },
  {
    cik: '0001425999',
    value: '0001425999,BRENNER WEST CAPITAL ADVISORS, LLC',
    name: 'BRENNER WEST CAPITAL ADVISORS, LLC',
    label: 'BRENNER WEST CAPITAL ADVISORS, LLC',
  },
  {
    cik: '0001721167',
    value: '0001721167,BREOGAN CAPITAL, LP',
    name: 'BREOGAN CAPITAL, LP',
    label: 'BREOGAN CAPITAL, LP',
  },
  {
    cik: '0001531987',
    value: '0001531987,BRETON HILL CAPITAL LTD.',
    name: 'BRETON HILL CAPITAL LTD.',
    label: 'BRETON HILL CAPITAL LTD.',
  },
  {
    cik: '0001512857',
    value: '0001512857,BREVAN HOWARD CAPITAL MANAGEMENT LP',
    name: 'BREVAN HOWARD CAPITAL MANAGEMENT LP',
    label: 'BREVAN HOWARD CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001954243',
    value: '0001954243,BREWIN DOLPHIN LTD',
    name: 'BREWIN DOLPHIN LTD',
    label: 'BREWIN DOLPHIN LTD',
  },
  {
    cik: '0001954056',
    value: '0001954056,BREWIN DOLPHIN WEALTH MANAGEMENT LTD',
    name: 'BREWIN DOLPHIN WEALTH MANAGEMENT LTD',
    label: 'BREWIN DOLPHIN WEALTH MANAGEMENT LTD',
  },
  {
    cik: '0001898145',
    value: '0001898145,BREWSTER FINANCIAL PLANNING LLC',
    name: 'BREWSTER FINANCIAL PLANNING LLC',
    label: 'BREWSTER FINANCIAL PLANNING LLC',
  },
  {
    cik: '0001728354',
    value: '0001728354,BRIAR HALL MANAGEMENT LLC',
    name: 'BRIAR HALL MANAGEMENT LLC',
    label: 'BRIAR HALL MANAGEMENT LLC',
  },
  {
    cik: '0001800586',
    value: '0001800586,BRIAUD FINANCIAL PLANNING, INC',
    name: 'BRIAUD FINANCIAL PLANNING, INC',
    label: 'BRIAUD FINANCIAL PLANNING, INC',
  },
  {
    cik: '0001632253',
    value: '0001632253,BRICK & KYLE, ASSOCIATES',
    name: 'BRICK & KYLE, ASSOCIATES',
    label: 'BRICK & KYLE, ASSOCIATES',
  },
  {
    cik: '0001712533',
    value: '0001712533,BRICKLEY WEALTH MANAGEMENT',
    name: 'BRICKLEY WEALTH MANAGEMENT',
    label: 'BRICKLEY WEALTH MANAGEMENT',
  },
  {
    cik: '0001847956',
    value: '0001847956,BRIDGE ADVISORY, LLC',
    name: 'BRIDGE ADVISORY, LLC',
    label: 'BRIDGE ADVISORY, LLC',
  },
  {
    cik: '0001713521',
    value: '0001713521,BRIDGE CITY CAPITAL, LLC',
    name: 'BRIDGE CITY CAPITAL, LLC',
    label: 'BRIDGE CITY CAPITAL, LLC',
  },
  {
    cik: '0001670903',
    value: '0001670903,BRIDGE CREEK CAPITAL MANAGEMENT LLC',
    name: 'BRIDGE CREEK CAPITAL MANAGEMENT LLC',
    label: 'BRIDGE CREEK CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001370629',
    value: '0001370629,BRIDGECREEK INVESTMENT MANAGEMENT, LLC',
    name: 'BRIDGECREEK INVESTMENT MANAGEMENT, LLC',
    label: 'BRIDGECREEK INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001725690',
    value: '0001725690,BRIDGEFRONT CAPITAL, LLC',
    name: 'BRIDGEFRONT CAPITAL, LLC',
    label: 'BRIDGEFRONT CAPITAL, LLC',
  },
  {
    cik: '0001785717',
    value: '0001785717,BRIDGER CAPITAL MANAGEMENT, LLC',
    name: 'BRIDGER CAPITAL MANAGEMENT, LLC',
    label: 'BRIDGER CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001166309',
    value: '0001166309,BRIDGER MANAGEMENT, LLC',
    name: 'BRIDGER MANAGEMENT, LLC',
    label: 'BRIDGER MANAGEMENT, LLC',
  },
  {
    cik: '0000945444',
    value: '0000945444,BRIDGES INVESTMENT COUNSEL INC/',
    name: 'BRIDGES INVESTMENT COUNSEL INC/',
    label: 'BRIDGES INVESTMENT COUNSEL INC/',
  },
  {
    cik: '0001007295',
    value: '0001007295,BRIDGES INVESTMENT MANAGEMENT INC',
    name: 'BRIDGES INVESTMENT MANAGEMENT INC',
    label: 'BRIDGES INVESTMENT MANAGEMENT INC',
  },
  {
    cik: '0001600319',
    value: '0001600319,BRIDGEWATER ADVISORS INC.',
    name: 'BRIDGEWATER ADVISORS INC.',
    label: 'BRIDGEWATER ADVISORS INC.',
  },
  {
    cik: '0001350694',
    value: '0001350694,BRIDGEWATER ASSOCIATES, LP',
    name: 'BRIDGEWATER ASSOCIATES, LP',
    label: 'BRIDGEWATER ASSOCIATES, LP',
  },
  {
    cik: '0001648901',
    value: '0001648901,BRIDGEWATER WEALTH & FINANCIAL MANAGEMENT, LLC',
    name: 'BRIDGEWATER WEALTH & FINANCIAL MANAGEMENT, LLC',
    label: 'BRIDGEWATER WEALTH & FINANCIAL MANAGEMENT, LLC',
  },
  {
    cik: '0001107261',
    value: '0001107261,BRIDGEWAY CAPITAL MANAGEMENT INC',
    name: 'BRIDGEWAY CAPITAL MANAGEMENT INC',
    label: 'BRIDGEWAY CAPITAL MANAGEMENT INC',
  },
  {
    cik: '0001107261',
    value: '0001107261,BRIDGEWAY CAPITAL MANAGEMENT, LLC',
    name: 'BRIDGEWAY CAPITAL MANAGEMENT, LLC',
    label: 'BRIDGEWAY CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001691804',
    value: '0001691804,BRIDGEWORTH, LLC',
    name: 'BRIDGEWORTH, LLC',
    label: 'BRIDGEWORTH, LLC',
  },
  {
    cik: '0001421306',
    value: '0001421306,BRIGADE CAPITAL MANAGEMENT, LLC',
    name: 'BRIGADE CAPITAL MANAGEMENT, LLC',
    label: 'BRIGADE CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001421306',
    value: '0001421306,BRIGADE CAPITAL MANAGEMENT, LP',
    name: 'BRIGADE CAPITAL MANAGEMENT, LP',
    label: 'BRIGADE CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0001906527',
    value: '0001906527,BRIGGS ADVISORY GROUP, INC.',
    name: 'BRIGGS ADVISORY GROUP, INC.',
    label: 'BRIGGS ADVISORY GROUP, INC.',
  },
  {
    cik: '0001165762',
    value: '0001165762,BRIGHTFIELD CAPITAL MANAGEMENT LLC',
    name: 'BRIGHTFIELD CAPITAL MANAGEMENT LLC',
    label: 'BRIGHTFIELD CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001802838',
    value: '0001802838,BRIGHTLIGHT CAPITAL MANAGEMENT LP',
    name: 'BRIGHTLIGHT CAPITAL MANAGEMENT LP',
    label: 'BRIGHTLIGHT CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001432450',
    value: '0001432450,BRIGHTLINE CAPITAL MANAGEMENT, LLC',
    name: 'BRIGHTLINE CAPITAL MANAGEMENT, LLC',
    label: 'BRIGHTLINE CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001360798',
    value: '0001360798,BRIGHTON JONES LLC',
    name: 'BRIGHTON JONES LLC',
    label: 'BRIGHTON JONES LLC',
  },
  {
    cik: '0001509973',
    value: '0001509973,BRIGHT ROCK CAPITAL MANAGEMENT, LLC',
    name: 'BRIGHT ROCK CAPITAL MANAGEMENT, LLC',
    label: 'BRIGHT ROCK CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001656666',
    value: '0001656666,BRIGHTWORTH',
    name: 'BRIGHTWORTH',
    label: 'BRIGHTWORTH',
  },
  {
    cik: '0001841077',
    value: '0001841077,B. RILEY ASSET MANAGEMENT, LLC',
    name: 'B. RILEY ASSET MANAGEMENT, LLC',
    label: 'B. RILEY ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001464790',
    value: '0001464790,B. RILEY FINANCIAL, INC.',
    name: 'B. RILEY FINANCIAL, INC.',
    label: 'B. RILEY FINANCIAL, INC.',
  },
  {
    cik: '0001599892',
    value: '0001599892,B. RILEY WEALTH MANAGEMENT, INC.',
    name: 'B. RILEY WEALTH MANAGEMENT, INC.',
    label: 'B. RILEY WEALTH MANAGEMENT, INC.',
  },
  {
    cik: '0001665751',
    value: '0001665751,BRILLIANCE ASSET MANAGEMENT LTD',
    name: 'BRILLIANCE ASSET MANAGEMENT LTD',
    label: 'BRILLIANCE ASSET MANAGEMENT LTD',
  },
  {
    cik: '0001502976',
    value: '0001502976,BRINKER CAPITAL INC',
    name: 'BRINKER CAPITAL INC',
    label: 'BRINKER CAPITAL INC',
  },
  {
    cik: '0001360533',
    value: '0001360533,BRINKER CAPITAL INVESTMENTS, LLC',
    name: 'BRINKER CAPITAL INVESTMENTS, LLC',
    label: 'BRINKER CAPITAL INVESTMENTS, LLC',
  },
  {
    cik: '0001747799',
    value: '0001747799,BRIO CONSULTANTS, LLC',
    name: 'BRIO CONSULTANTS, LLC',
    label: 'BRIO CONSULTANTS, LLC',
  },
  {
    cik: '0001671716',
    value: '0001671716,BRISTLECONE ADVISORS, LLC',
    name: 'BRISTLECONE ADVISORS, LLC',
    label: 'BRISTLECONE ADVISORS, LLC',
  },
  {
    cik: '0001697795',
    value: '0001697795,BRISTOL ADVISORS, LLC',
    name: 'BRISTOL ADVISORS, LLC',
    label: 'BRISTOL ADVISORS, LLC',
  },
  {
    cik: '0001726945',
    value: '0001726945,BRISTOL COUNTY SAVINGS BANK',
    name: 'BRISTOL COUNTY SAVINGS BANK',
    label: 'BRISTOL COUNTY SAVINGS BANK',
  },
  {
    cik: '0001650142',
    value: '0001650142,BRISTOL GATE CAPITAL PARTNERS INC.',
    name: 'BRISTOL GATE CAPITAL PARTNERS INC.',
    label: 'BRISTOL GATE CAPITAL PARTNERS INC.',
  },
  {
    cik: '0000276101',
    value: '0000276101,BRISTOL JOHN W & CO INC /NY/',
    name: 'BRISTOL JOHN W & CO INC /NY/',
    label: 'BRISTOL JOHN W & CO INC /NY/',
  },
  {
    cik: '0001536558',
    value: '0001536558,BRITISH AIRWAYS PENSIONS INVESTMENT MANAGEMENT LTD',
    name: 'BRITISH AIRWAYS PENSIONS INVESTMENT MANAGEMENT LTD',
    label: 'BRITISH AIRWAYS PENSIONS INVESTMENT MANAGEMENT LTD',
  },
  {
    cik: '0001228242',
    value: '0001228242,BRITISH COLUMBIA INVESTMENT MANAGEMENT CORP',
    name: 'BRITISH COLUMBIA INVESTMENT MANAGEMENT CORP',
    label: 'BRITISH COLUMBIA INVESTMENT MANAGEMENT CORP',
  },
  {
    cik: '0001759115',
    value: '0001759115,BROAD BAY CAPITAL MANAGEMENT, LP',
    name: 'BROAD BAY CAPITAL MANAGEMENT, LP',
    label: 'BROAD BAY CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0001749585',
    value: '0001749585,BROADCREST ASSET MANAGEMENT, LLC',
    name: 'BROADCREST ASSET MANAGEMENT, LLC',
    label: 'BROADCREST ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001511901',
    value: '0001511901,BROADFIN CAPITAL, LLC',
    name: 'BROADFIN CAPITAL, LLC',
    label: 'BROADFIN CAPITAL, LLC',
  },
  {
    cik: '0001712686',
    value: '0001712686,BROADLEAF PARTNERS, LLC',
    name: 'BROADLEAF PARTNERS, LLC',
    label: 'BROADLEAF PARTNERS, LLC',
  },
  {
    cik: '0001095952',
    value: '0001095952,BROADMARK ASSET MANAGEMENT LLC',
    name: 'BROADMARK ASSET MANAGEMENT LLC',
    label: 'BROADMARK ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001728120',
    value: '0001728120,BROAD PEAK INVESTMENT HOLDINGS LTD.',
    name: 'BROAD PEAK INVESTMENT HOLDINGS LTD.',
    label: 'BROAD PEAK INVESTMENT HOLDINGS LTD.',
  },
  {
    cik: '0001568621',
    value: '0001568621,BROAD RUN INVESTMENT MANAGEMENT, LLC',
    name: 'BROAD RUN INVESTMENT MANAGEMENT, LLC',
    label: 'BROAD RUN INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001255858',
    value: '0001255858,BROADVIEW ADVISORS LLC',
    name: 'BROADVIEW ADVISORS LLC',
    label: 'BROADVIEW ADVISORS LLC',
  },
  {
    cik: '0001278387',
    value: '0001278387,BROADWOOD CAPITAL INC',
    name: 'BROADWOOD CAPITAL INC',
    label: 'BROADWOOD CAPITAL INC',
  },
  {
    cik: '0001314376',
    value: '0001314376,BRODERICK BRIAN C',
    name: 'BRODERICK BRIAN C',
    label: 'BRODERICK BRIAN C',
  },
  {
    cik: '0001891795',
    value: '0001891795,BROMFIELD SNEIDER WEALTH ADVISORS',
    name: 'BROMFIELD SNEIDER WEALTH ADVISORS',
    label: 'BROMFIELD SNEIDER WEALTH ADVISORS',
  },
  {
    cik: '0001666612',
    value: '0001666612,BRONFMAN E.L. ROTHSCHILD, L.P.',
    name: 'BRONFMAN E.L. ROTHSCHILD, L.P.',
    label: 'BRONFMAN E.L. ROTHSCHILD, L.P.',
  },
  {
    cik: '0001511826',
    value: '0001511826,BRONSON POINT MANAGEMENT LLC',
    name: 'BRONSON POINT MANAGEMENT LLC',
    label: 'BRONSON POINT MANAGEMENT LLC',
  },
  {
    cik: '0001471085',
    value: '0001471085,BRONTE CAPITAL MANAGEMENT PTY LTD.',
    name: 'BRONTE CAPITAL MANAGEMENT PTY LTD.',
    label: 'BRONTE CAPITAL MANAGEMENT PTY LTD.',
  },
  {
    cik: '0001001085',
    value: '0001001085,BROOKFIELD ASSET MANAGEMENT INC.',
    name: 'BROOKFIELD ASSET MANAGEMENT INC.',
    label: 'BROOKFIELD ASSET MANAGEMENT INC.',
  },
  {
    cik: '0001760437',
    value: '0001760437,BROOKFIELD BHS ADVISORS LLC',
    name: 'BROOKFIELD BHS ADVISORS LLC',
    label: 'BROOKFIELD BHS ADVISORS LLC',
  },
  {
    cik: '0001273887',
    value: '0001273887,BROOKFIELD PUBLIC SECURITIES GROUP LLC',
    name: 'BROOKFIELD PUBLIC SECURITIES GROUP LLC',
    label: 'BROOKFIELD PUBLIC SECURITIES GROUP LLC',
  },
  {
    cik: '0001916123',
    value: '0001916123,BROOKLYN FI, LLC',
    name: 'BROOKLYN FI, LLC',
    label: 'BROOKLYN FI, LLC',
  },
  {
    cik: '0001512978',
    value: '0001512978,BROOKMONT CAPITAL MANAGEMENT',
    name: 'BROOKMONT CAPITAL MANAGEMENT',
    label: 'BROOKMONT CAPITAL MANAGEMENT',
  },
  {
    cik: '0001156175',
    value: '0001156175,BROOKSIDE CAPITAL MANAGEMENT LLC',
    name: 'BROOKSIDE CAPITAL MANAGEMENT LLC',
    label: 'BROOKSIDE CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001805466',
    value: '0001805466,BROOKSIDE EQUITY PARTNERS LLC',
    name: 'BROOKSIDE EQUITY PARTNERS LLC',
    label: 'BROOKSIDE EQUITY PARTNERS LLC',
  },
  {
    cik: '0001767040',
    value: '0001767040,BROOKS, MOORE & ASSOCIATES, INC.',
    name: 'BROOKS, MOORE & ASSOCIATES, INC.',
    label: 'BROOKS, MOORE & ASSOCIATES, INC.',
  },
  {
    cik: '0001599584',
    value: '0001599584,BROOKSTONE CAPITAL MANAGEMENT',
    name: 'BROOKSTONE CAPITAL MANAGEMENT',
    label: 'BROOKSTONE CAPITAL MANAGEMENT',
  },
  {
    cik: '0001021296',
    value: '0001021296,BROOKTREE CAPITAL MANAGEMENT',
    name: 'BROOKTREE CAPITAL MANAGEMENT',
    label: 'BROOKTREE CAPITAL MANAGEMENT',
  },
  {
    cik: '0001483394',
    value: '0001483394,BROUWER & JANACHOWSKI, LLC',
    name: 'BROUWER & JANACHOWSKI, LLC',
    label: 'BROUWER & JANACHOWSKI, LLC',
  },
  {
    cik: '0001345929',
    value: '0001345929,BROWN ADVISORY INC',
    name: 'BROWN ADVISORY INC',
    label: 'BROWN ADVISORY INC',
  },
  {
    cik: '0001389479',
    value: '0001389479,BROWN ADVISORY SECURITIES, LLC',
    name: 'BROWN ADVISORY SECURITIES, LLC',
    label: 'BROWN ADVISORY SECURITIES, LLC',
  },
  {
    cik: '0000014661',
    value: '0000014661,BROWN BROTHERS HARRIMAN & CO',
    name: 'BROWN BROTHERS HARRIMAN & CO',
    label: 'BROWN BROTHERS HARRIMAN & CO',
  },
  {
    cik: '0000885062',
    value: '0000885062,BROWN CAPITAL MANAGEMENT LLC',
    name: 'BROWN CAPITAL MANAGEMENT LLC',
    label: 'BROWN CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001843853',
    value: '0001843853,BROWN FINANCIAL ADVISORY',
    name: 'BROWN FINANCIAL ADVISORY',
    label: 'BROWN FINANCIAL ADVISORY',
  },
  {
    cik: '0001825564',
    value: '0001825564,BROWNING WEST LP',
    name: 'BROWNING WEST LP',
    label: 'BROWNING WEST LP',
  },
  {
    cik: '0001840084',
    value: '0001840084,BROWN MILLER WEALTH MANAGEMENT, LLC',
    name: 'BROWN MILLER WEALTH MANAGEMENT, LLC',
    label: 'BROWN MILLER WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001580162',
    value: '0001580162,BROWN TROUT MANAGEMENT, LLC',
    name: 'BROWN TROUT MANAGEMENT, LLC',
    label: 'BROWN TROUT MANAGEMENT, LLC',
  },
  {
    cik: '0001664741',
    value: '0001664741,BROWN UNIVERSITY',
    name: 'BROWN UNIVERSITY',
    label: 'BROWN UNIVERSITY',
  },
  {
    cik: '0001468685',
    value: '0001468685,BRR OPCO, LLC',
    name: 'BRR OPCO, LLC',
    label: 'BRR OPCO, LLC',
  },
  {
    cik: '0001358331',
    value: '0001358331,BRUCE & CO., INC.',
    name: 'BRUCE & CO., INC.',
    label: 'BRUCE & CO., INC.',
  },
  {
    cik: '0001633673',
    value: '0001633673,BRUDERMAN ASSET MANAGEMENT, LLC',
    name: 'BRUDERMAN ASSET MANAGEMENT, LLC',
    label: 'BRUDERMAN ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001078841',
    value: '0001078841,BRUNI J V & CO /CO',
    name: 'BRUNI J V & CO /CO',
    label: 'BRUNI J V & CO /CO',
  },
  {
    cik: '0001844103',
    value: '0001844103,BRX GLOBAL LP',
    name: 'BRX GLOBAL LP',
    label: 'BRX GLOBAL LP',
  },
  {
    cik: '0001910641',
    value: '0001910641,BRYANT WOODS INVESTMENT ADVISORS, LLC',
    name: 'BRYANT WOODS INVESTMENT ADVISORS, LLC',
    label: 'BRYANT WOODS INVESTMENT ADVISORS, LLC',
  },
  {
    cik: '0001009212',
    value: '0001009212,BRYN MAWR CAPITAL MANAGEMENT INC',
    name: 'BRYN MAWR CAPITAL MANAGEMENT INC',
    label: 'BRYN MAWR CAPITAL MANAGEMENT INC',
  },
  {
    cik: '0000900974',
    value: '0000900974,BRYN MAWR TRUST CO',
    name: 'BRYN MAWR TRUST CO',
    label: 'BRYN MAWR TRUST CO',
  },
  {
    cik: '0001690531',
    value: '0001690531,BSC PRIVATE WEALTH MANAGEMENT, LLC',
    name: 'BSC PRIVATE WEALTH MANAGEMENT, LLC',
    label: 'BSC PRIVATE WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001911876',
    value: '0001911876,BSN CAPITAL PARTNERS LTD',
    name: 'BSN CAPITAL PARTNERS LTD',
    label: 'BSN CAPITAL PARTNERS LTD',
  },
  {
    cik: '0001536262',
    value:
      '0001536262,B.S. PENSION FUND TRUSTEE LTD ACTING FOR THE BRITISH STEEL PENSION FUND',
    name: 'B.S. PENSION FUND TRUSTEE LTD ACTING FOR THE BRITISH STEEL PENSION FUND',
    label:
      'B.S. PENSION FUND TRUSTEE LTD ACTING FOR THE BRITISH STEEL PENSION FUND',
  },
  {
    cik: '0001512601',
    value: '0001512601,BSW WEALTH PARTNERS',
    name: 'BSW WEALTH PARTNERS',
    label: 'BSW WEALTH PARTNERS',
  },
  {
    cik: '0001450935',
    value: '0001450935,B & T CAPITAL MANAGEMENT DBA ALPHA CAPITAL MANAGEMENT',
    name: 'B & T CAPITAL MANAGEMENT DBA ALPHA CAPITAL MANAGEMENT',
    label: 'B & T CAPITAL MANAGEMENT DBA ALPHA CAPITAL MANAGEMENT',
  },
  {
    cik: '0001611848',
    value: '0001611848,BTC CAPITAL MANAGEMENT, INC.',
    name: 'BTC CAPITAL MANAGEMENT, INC.',
    label: 'BTC CAPITAL MANAGEMENT, INC.',
  },
  {
    cik: '0001569785',
    value: '0001569785,BTG PACTUAL ASSET MANAGEMENT S.A. DTVM',
    name: 'BTG PACTUAL ASSET MANAGEMENT S.A. DTVM',
    label: 'BTG PACTUAL ASSET MANAGEMENT S.A. DTVM',
  },
  {
    cik: '0001567992',
    value: '0001567992,BTG PACTUAL GLOBAL ASSET MANAGEMENT LTD',
    name: 'BTG PACTUAL GLOBAL ASSET MANAGEMENT LTD',
    label: 'BTG PACTUAL GLOBAL ASSET MANAGEMENT LTD',
  },
  {
    cik: '0001534866',
    value: '000153486,BTIM CORP.',
    name: 'BTIM CORP.',
    label: 'BTIM CORP.',
  },
  {
    cik: '0001541741',
    value: '0001541741,BT INVESTMENT MANAGEMENT LTD',
    name: 'BT INVESTMENT MANAGEMENT LTD',
    label: 'BT INVESTMENT MANAGEMENT LTD',
  },
  {
    cik: '0000883790',
    value: '0000883790,BTR CAPITAL MANAGEMENT INC',
    name: 'BTR CAPITAL MANAGEMENT INC',
    label: 'BTR CAPITAL MANAGEMENT INC',
  },
  {
    cik: '0001532842',
    value: '0001532842,BTS ASSET MANAGEMENT, INC.',
    name: 'BTS ASSET MANAGEMENT, INC.',
    label: 'BTS ASSET MANAGEMENT, INC.',
  },
  {
    cik: '0001767863',
    value: '0001767863,BT WEALTH MANAGEMENT LLC',
    name: 'BT WEALTH MANAGEMENT LLC',
    label: 'BT WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0001133999',
    value: '0001133999,BUCKHEAD CAPITAL MANAGEMENT LLC',
    name: 'BUCKHEAD CAPITAL MANAGEMENT LLC',
    label: 'BUCKHEAD CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001542421',
    value: '0001542421,BUCKINGHAM ASSET MANAGEMENT, LLC',
    name: 'BUCKINGHAM ASSET MANAGEMENT, LLC',
    label: 'BUCKINGHAM ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001089212',
    value: '0001089212,BUCKINGHAM CAPITAL MANAGEMENT INC',
    name: 'BUCKINGHAM CAPITAL MANAGEMENT INC',
    label: 'BUCKINGHAM CAPITAL MANAGEMENT INC',
  },
  {
    cik: '0001567634',
    value: '0001567634,BUCKINGHAM CAPITAL MANAGEMENT, INC.',
    name: 'BUCKINGHAM CAPITAL MANAGEMENT, INC.',
    label: 'BUCKINGHAM CAPITAL MANAGEMENT, INC.',
  },
  {
    cik: '0001868872',
    value: '0001868872,BUCKINGHAM STRATEGIC PARTNERS',
    name: 'BUCKINGHAM STRATEGIC PARTNERS',
    label: 'BUCKINGHAM STRATEGIC PARTNERS',
  },
  {
    cik: '0001542421',
    value: '0001542421,BUCKINGHAM STRATEGIC WEALTH, LLC',
    name: 'BUCKINGHAM STRATEGIC WEALTH, LLC',
    label: 'BUCKINGHAM STRATEGIC WEALTH, LLC',
  },
  {
    cik: '0001730126',
    value: '0001730126,BUCKLEY WEALTH MANAGEMENT, LLC',
    name: 'BUCKLEY WEALTH MANAGEMENT, LLC',
    label: 'BUCKLEY WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001468685',
    value: '0001468685,BUDROS, RUHLIN & ROE, INC.',
    name: 'BUDROS, RUHLIN & ROE, INC.',
    label: 'BUDROS, RUHLIN & ROE, INC.',
  },
  {
    cik: '0001317829',
    value: '0001317829,BUFFINGTON MOHR MCNEAL',
    name: 'BUFFINGTON MOHR MCNEAL',
    label: 'BUFFINGTON MOHR MCNEAL',
  },
  {
    cik: '0001772593',
    value: '0001772593,BUILDERS UNION FUND LP',
    name: 'BUILDERS UNION FUND LP',
    label: 'BUILDERS UNION FUND LP',
  },
  {
    cik: '0001771067',
    value: '0001771067,BUILDERS UNION LLP',
    name: 'BUILDERS UNION LLP',
    label: 'BUILDERS UNION LLP',
  },
  {
    cik: '0001504304',
    value: '0001504304,BULLDOG INVESTORS, LLC',
    name: 'BULLDOG INVESTORS, LLC',
    label: 'BULLDOG INVESTORS, LLC',
  },
  {
    cik: '0001504304',
    value: '0001504304,BULLDOG INVESTORS, LLP',
    name: 'BULLDOG INVESTORS, LLP',
    label: 'BULLDOG INVESTORS, LLP',
  },
  {
    cik: '0001801182',
    value: '0001801182,BULLFROG CAPITAL GP, LTD.',
    name: 'BULLFROG CAPITAL GP, LTD.',
    label: 'BULLFROG CAPITAL GP, LTD.',
  },
  {
    cik: '0001824263',
    value: '0001824263,BULL OAK CAPITAL, LLC',
    name: 'BULL OAK CAPITAL, LLC',
    label: 'BULL OAK CAPITAL, LLC',
  },
  {
    cik: '0001513779',
    value: '0001513779,BULLSEYE ASSET MANAGEMENT LLC',
    name: 'BULLSEYE ASSET MANAGEMENT LLC',
    label: 'BULLSEYE ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001912451',
    value: '0001912451,BULLSEYE INVESTMENT MANAGEMENT, LLC',
    name: 'BULLSEYE INVESTMENT MANAGEMENT, LLC',
    label: 'BULLSEYE INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001790837',
    value: '0001790837,BULL STREET ADVISORS, LLC',
    name: 'BULL STREET ADVISORS, LLC',
    label: 'BULL STREET ADVISORS, LLC',
  },
  {
    cik: '0001651753',
    value: '0001651753,BURDA GESELLSCHAFT MIT BESCHRANKTER HAFTUNG',
    name: 'BURDA GESELLSCHAFT MIT BESCHRANKTER HAFTUNG',
    label: 'BURDA GESELLSCHAFT MIT BESCHRANKTER HAFTUNG',
  },
  {
    cik: '0001712024',
    value: '0001712024,BURFORD CAPITAL INVESTMENT MANAGEMENT LLC',
    name: 'BURFORD CAPITAL INVESTMENT MANAGEMENT LLC',
    label: 'BURFORD CAPITAL INVESTMENT MANAGEMENT LLC',
  },
  {
    cik: '0001315868',
    value: '0001315868,BURGUNDY ASSET MANAGEMENT LTD.',
    name: 'BURGUNDY ASSET MANAGEMENT LTD.',
    label: 'BURGUNDY ASSET MANAGEMENT LTD.',
  },
  {
    cik: '0001101250',
    value: '0001101250,BURKE & HERBERT BANK & TRUST CO',
    name: 'BURKE & HERBERT BANK & TRUST CO',
    label: 'BURKE & HERBERT BANK & TRUST CO',
  },
  {
    cik: '0001733510',
    value: '0001733510,BURLESON & COMPANY, LLC',
    name: 'BURLESON & COMPANY, LLC',
    label: 'BURLESON & COMPANY, LLC',
  },
  {
    cik: '0000940445',
    value: '000094044,BURNEY CO/',
    name: 'BURNEY CO/',
    label: 'BURNEY CO/',
  },
  {
    cik: '0000866035',
    value: '0000866035,BURNHAM ASSET MANAGEMENT CORP /NY/',
    name: 'BURNHAM ASSET MANAGEMENT CORP /NY/',
    label: 'BURNHAM ASSET MANAGEMENT CORP /NY/',
  },
  {
    cik: '0001047339',
    value: '0001047339,BURNS J W & CO INC/NY',
    name: 'BURNS J W & CO INC/NY',
    label: 'BURNS J W & CO INC/NY',
  },
  {
    cik: '0001576151',
    value: '0001576151,BURNS WEALTH MANAGEMENT, INC.',
    name: 'BURNS WEALTH MANAGEMENT, INC.',
    label: 'BURNS WEALTH MANAGEMENT, INC.',
  },
  {
    cik: '0001796461',
    value: '0001796461,BURRAGE CAPITAL MANAGEMENT LLC',
    name: 'BURRAGE CAPITAL MANAGEMENT LLC',
    label: 'BURRAGE CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001542952',
    value: '0001542952,BURREN CAPITAL ADVISORS LTD',
    name: 'BURREN CAPITAL ADVISORS LTD',
    label: 'BURREN CAPITAL ADVISORS LTD',
  },
  {
    cik: '0001612041',
    value: '0001612041,BURT WEALTH ADVISORS',
    name: 'BURT WEALTH ADVISORS',
    label: 'BURT WEALTH ADVISORS',
  },
  {
    cik: '0001427202',
    value: '0001427202,BUSEY TRUST CO',
    name: 'BUSEY TRUST CO',
    label: 'BUSEY TRUST CO',
  },
  {
    cik: '0001427202',
    value: '0001427202,BUSEY WEALTH MANAGEMENT',
    name: 'BUSEY WEALTH MANAGEMENT',
    label: 'BUSEY WEALTH MANAGEMENT',
  },
  {
    cik: '0001106544',
    value: '0001106544,BUSH ODONNELL INVESTMENT ADVISORS INC',
    name: 'BUSH ODONNELL INVESTMENT ADVISORS INC',
    label: 'BUSH ODONNELL INVESTMENT ADVISORS INC',
  },
  {
    cik: '0001632802',
    value: '0001632802,BUTENSKY & COHEN FINANCIAL SECURITY, INC',
    name: 'BUTENSKY & COHEN FINANCIAL SECURITY, INC',
    label: 'BUTENSKY & COHEN FINANCIAL SECURITY, INC',
  },
  {
    cik: '0001904033',
    value: '0001904033,BUTTONWOOD FINANCIAL ADVISORS INC.',
    name: 'BUTTONWOOD FINANCIAL ADVISORS INC.',
    label: 'BUTTONWOOD FINANCIAL ADVISORS INC.',
  },
  {
    cik: '0001847661',
    value: '0001847661,BUTTONWOOD FINANCIAL GROUP, LLC',
    name: 'BUTTONWOOD FINANCIAL GROUP, LLC',
    label: 'BUTTONWOOD FINANCIAL GROUP, LLC',
  },
  {
    cik: '0001056807',
    value: '000105680,BVF INC/IL',
    name: 'BVF INC/IL',
    label: 'BVF INC/IL',
  },
  {
    cik: '0001787893',
    value: '0001787,BWCP, LP',
    name: 'BWCP, LP',
    label: 'BWCP, LP',
  },
  {
    cik: '0001568280',
    value: '0001568280,BW GESTAO DE INVESTIMENTOS LTDA.',
    name: 'BW GESTAO DE INVESTIMENTOS LTDA.',
    label: 'BW GESTAO DE INVESTIMENTOS LTDA.',
  },
  {
    cik: '0001911322',
    value: '0001911322,BYRNE ASSET MANAGEMENT LLC',
    name: 'BYRNE ASSET MANAGEMENT LLC',
    label: 'BYRNE ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001868903',
    value: '0001868903,C2C WEALTH MANAGEMENT, LLC',
    name: 'C2C WEALTH MANAGEMENT, LLC',
    label: 'C2C WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001802881',
    value: '0001802881,CAAS CAPITAL MANAGEMENT LP',
    name: 'CAAS CAPITAL MANAGEMENT LP',
    label: 'CAAS CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001688539',
    value: '000168853,CABANA LLC',
    name: 'CABANA LLC',
    label: 'CABANA LLC',
  },
  {
    cik: '0001674581',
    value: '0001674581,CABLE HILL PARTNERS, LLC',
    name: 'CABLE HILL PARTNERS, LLC',
    label: 'CABLE HILL PARTNERS, LLC',
  },
  {
    cik: '0001016683',
    value: '0001016683,CABOT MONEY MANAGEMENT INC',
    name: 'CABOT MONEY MANAGEMENT INC',
    label: 'CABOT MONEY MANAGEMENT INC',
  },
  {
    cik: '0001016683',
    value: '0001016683,CABOT WEALTH MANAGEMENT INC',
    name: 'CABOT WEALTH MANAGEMENT INC',
    label: 'CABOT WEALTH MANAGEMENT INC',
  },
  {
    cik: '0001353198',
    value: '0001353198,CABOT-WELLINGTON, LLC',
    name: 'CABOT-WELLINGTON, LLC',
    label: 'CABOT-WELLINGTON, LLC',
  },
  {
    cik: '0001452208',
    value: '0001452208,CACTI ASSET MANAGEMENT LLC',
    name: 'CACTI ASSET MANAGEMENT LLC',
    label: 'CACTI ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001299939',
    value: '0001299939,CADENCE BANK',
    name: 'CADENCE BANK',
    label: 'CADENCE BANK',
  },
  {
    cik: '0001080381',
    value: '0001080381,CADENCE BANK NA',
    name: 'CADENCE BANK NA',
    label: 'CADENCE BANK NA',
  },
  {
    cik: '0001175399',
    value: '0001175399,CADENCE CAPITAL MANAGEMENT LLC',
    name: 'CADENCE CAPITAL MANAGEMENT LLC',
    label: 'CADENCE CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001845250',
    value: '0001845250,CADENCE WEALTH MANAGEMENT LLC',
    name: 'CADENCE WEALTH MANAGEMENT LLC',
    label: 'CADENCE WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0001803536',
    value: '0001803536,CADENT CAPITAL ADVISORS, LLC',
    name: 'CADENT CAPITAL ADVISORS, LLC',
    label: 'CADENT CAPITAL ADVISORS, LLC',
  },
  {
    cik: '0001423686',
    value: '0001423686,CADIAN CAPITAL MANAGEMENT, LP',
    name: 'CADIAN CAPITAL MANAGEMENT, LP',
    label: 'CADIAN CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0000923469',
    value: '0000923469,CADINHA & CO LLC',
    name: 'CADINHA & CO LLC',
    label: 'CADINHA & CO LLC',
  },
  {
    cik: '0001537335',
    value: '0001537335,CAERUS GLOBAL INVESTORS LLC',
    name: 'CAERUS GLOBAL INVESTORS LLC',
    label: 'CAERUS GLOBAL INVESTORS LLC',
  },
  {
    cik: '0001862682',
    value: '0001862682,CAERUS INVESTMENT ADVISORS, LLC',
    name: 'CAERUS INVESTMENT ADVISORS, LLC',
    label: 'CAERUS INVESTMENT ADVISORS, LLC',
  },
  {
    cik: '0001729932',
    value: '0001729932,CAERUS INVESTORS, LLC',
    name: 'CAERUS INVESTORS, LLC',
    label: 'CAERUS INVESTORS, LLC',
  },
  {
    cik: '0001804673',
    value: '0001804673,CA FAMILY WEALTH, LLC',
    name: 'CA FAMILY WEALTH, LLC',
    label: 'CA FAMILY WEALTH, LLC',
  },
  {
    cik: '0001716399',
    value: '0001716399,CAHABA WEALTH MANAGEMENT, INC.',
    name: 'CAHABA WEALTH MANAGEMENT, INC.',
    label: 'CAHABA WEALTH MANAGEMENT, INC.',
  },
  {
    cik: '0001600327',
    value: '0001600327,CAHILL FINANCIAL ADVISORS INC',
    name: 'CAHILL FINANCIAL ADVISORS INC',
    label: 'CAHILL FINANCIAL ADVISORS INC',
  },
  {
    cik: '0001927960',
    value: '0001927960,CAHILL WEALTH MANAGEMENT, LLC',
    name: 'CAHILL WEALTH MANAGEMENT, LLC',
    label: 'CAHILL WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001513702',
    value: '0001513702,CAIN BROTHERS ASSET MANAGEMENT, LLC',
    name: 'CAIN BROTHERS ASSET MANAGEMENT, LLC',
    label: 'CAIN BROTHERS ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001800358',
    value: '0001800358,CAIRN INVESTMENT GROUP, INC.',
    name: 'CAIRN INVESTMENT GROUP, INC.',
    label: 'CAIRN INVESTMENT GROUP, INC.',
  },
  {
    cik: '0000898286',
    value: '0000898286,CAISSE DE DEPOT ET PLACEMENT DU QUEBEC',
    name: 'CAISSE DE DEPOT ET PLACEMENT DU QUEBEC',
    label: 'CAISSE DE DEPOT ET PLACEMENT DU QUEBEC',
  },
  {
    cik: '0001316507',
    value: '0001316507,CALAMOS ADVISORS LLC',
    name: 'CALAMOS ADVISORS LLC',
    label: 'CALAMOS ADVISORS LLC',
  },
  {
    cik: '0001455258',
    value: '0001455258,CALAMOS WEALTH MANAGEMENT LLC',
    name: 'CALAMOS WEALTH MANAGEMENT LLC',
    label: 'CALAMOS WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0001646192',
    value: '0001646192,CALDERA CAPITAL, LLC',
    name: 'CALDERA CAPITAL, LLC',
    label: 'CALDERA CAPITAL, LLC',
  },
  {
    cik: '0001904373',
    value: '0001904373,CALDWELL INVESTMENT MANAGEMENT LTD.',
    name: 'CALDWELL INVESTMENT MANAGEMENT LTD.',
    label: 'CALDWELL INVESTMENT MANAGEMENT LTD.',
  },
  {
    cik: '0000862853',
    value: '0000862853,CALDWELL & ORKIN INC',
    name: 'CALDWELL & ORKIN INC',
    label: 'CALDWELL & ORKIN INC',
  },
  {
    cik: '0001037766',
    value: '0001037766,CALEDONIA INVESTMENTS PLC',
    name: 'CALEDONIA INVESTMENTS PLC',
    label: 'CALEDONIA INVESTMENTS PLC',
  },
  {
    cik: '0001352924',
    value: '0001352924,CALEDONIA (PRIVATE) INVESTMENTS PTY LTD',
    name: 'CALEDONIA (PRIVATE) INVESTMENTS PTY LTD',
    label: 'CALEDONIA (PRIVATE) INVESTMENTS PTY LTD',
  },
  {
    cik: '0001800453',
    value: '0001800453,CALIBER WEALTH MANAGEMENT, LLC',
    name: 'CALIBER WEALTH MANAGEMENT, LLC',
    label: 'CALIBER WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001666613',
    value: '0001666613,CALIFORNIA CAPITAL ADVISORS, INC.',
    name: 'CALIFORNIA CAPITAL ADVISORS, INC.',
    label: 'CALIFORNIA CAPITAL ADVISORS, INC.',
  },
  {
    cik: '0000803016',
    value: '0000803016,CALIFORNIA FIRST LEASING CORP',
    name: 'CALIFORNIA FIRST LEASING CORP',
    label: 'CALIFORNIA FIRST LEASING CORP',
  },
  {
    cik: '0000919079',
    value: '0000919079,CALIFORNIA PUBLIC EMPLOYEES RETIREMENT SYSTEM',
    name: 'CALIFORNIA PUBLIC EMPLOYEES RETIREMENT SYSTEM',
    label: 'CALIFORNIA PUBLIC EMPLOYEES RETIREMENT SYSTEM',
  },
  {
    cik: '0001081019',
    value: '0001081019,CALIFORNIA STATE TEACHERS RETIREMENT SYSTEM',
    name: 'CALIFORNIA STATE TEACHERS RETIREMENT SYSTEM',
    label: 'CALIFORNIA STATE TEACHERS RETIREMENT SYSTEM',
  },
  {
    cik: '0001666472',
    value: '0001666472,CALIXTO GLOBAL INVESTORS, LP',
    name: 'CALIXTO GLOBAL INVESTORS, LP',
    label: 'CALIXTO GLOBAL INVESTORS, LP',
  },
  {
    cik: '0001395055',
    value: '0001395055,CALLAHAN ADVISORS, LLC',
    name: 'CALLAHAN ADVISORS, LLC',
    label: 'CALLAHAN ADVISORS, LLC',
  },
  {
    cik: '0001542166',
    value: '0001542166,CALLAN CAPITAL, LLC',
    name: 'CALLAN CAPITAL, LLC',
    label: 'CALLAN CAPITAL, LLC',
  },
  {
    cik: '0001741675',
    value: '0001741675,CALLODINE CAPITAL MANAGEMENT, LP',
    name: 'CALLODINE CAPITAL MANAGEMENT, LP',
    label: 'CALLODINE CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0000822648',
    value: '0000822648,CALTON & ASSOCIATES, INC.',
    name: 'CALTON & ASSOCIATES, INC.',
    label: 'CALTON & ASSOCIATES, INC.',
  },
  {
    cik: '0000918006',
    value: '0000918006,CALVERT INVESTMENT MANAGEMENT INC.',
    name: 'CALVERT INVESTMENT MANAGEMENT INC.',
    label: 'CALVERT INVESTMENT MANAGEMENT INC.',
  },
  {
    cik: '0001348886',
    value: '0001348886,CALYPSO CAPITAL MANAGEMENT, LP',
    name: 'CALYPSO CAPITAL MANAGEMENT, LP',
    label: 'CALYPSO CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0001536444',
    value: '0001536444,CAMARDA FINANCIAL ADVISORS, LLC',
    name: 'CAMARDA FINANCIAL ADVISORS, LLC',
    label: 'CAMARDA FINANCIAL ADVISORS, LLC',
  },
  {
    cik: '0001444043',
    value: '0001444043,CAMBER CAPITAL MANAGEMENT LLC',
    name: 'CAMBER CAPITAL MANAGEMENT LLC',
    label: 'CAMBER CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001444043',
    value: '0001444043,CAMBER CAPITAL MANAGEMENT LP',
    name: 'CAMBER CAPITAL MANAGEMENT LP',
    label: 'CAMBER CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0000823621',
    value: '0000823621,CAMBIAR INVESTORS LLC',
    name: 'CAMBIAR INVESTORS LLC',
    label: 'CAMBIAR INVESTORS LLC',
  },
  {
    cik: '0001529389',
    value: '0001529389,CAMBRIA INVESTMENT MANAGEMENT, L.P.',
    name: 'CAMBRIA INVESTMENT MANAGEMENT, L.P.',
    label: 'CAMBRIA INVESTMENT MANAGEMENT, L.P.',
  },
  {
    cik: '0001387814',
    value: '0001387814,CAMBRIAN CAPITAL LIMITED PARTNERSHIP',
    name: 'CAMBRIAN CAPITAL LIMITED PARTNERSHIP',
    label: 'CAMBRIAN CAPITAL LIMITED PARTNERSHIP',
  },
  {
    cik: '0001633695',
    value: '0001633695,CAMBRIDGE ADVISORS INC.',
    name: 'CAMBRIDGE ADVISORS INC.',
    label: 'CAMBRIDGE ADVISORS INC.',
  },
  {
    cik: '0000733986',
    value: '0000733986,CAMBRIDGE ASSOCIATES LLC /MA/ /ADV',
    name: 'CAMBRIDGE ASSOCIATES LLC /MA/ /ADV',
    label: 'CAMBRIDGE ASSOCIATES LLC /MA/ /ADV',
  },
  {
    cik: '0000915412',
    value: '0000915412,CAMBRIDGE ASSOCIATES LTD LLC/ENGLAND/ /ADV',
    name: 'CAMBRIDGE ASSOCIATES LTD LLC/ENGLAND/ /ADV',
    label: 'CAMBRIDGE ASSOCIATES LTD LLC/ENGLAND/ /ADV',
  },
  {
    cik: '0001334199',
    value: '0001334199,CAMBRIDGE FINANCIAL GROUP, INC.',
    name: 'CAMBRIDGE FINANCIAL GROUP, INC.',
    label: 'CAMBRIDGE FINANCIAL GROUP, INC.',
  },
  {
    cik: '0001419186',
    value: '0001419186,CAMBRIDGE INVESTMENT RESEARCH ADVISORS, INC.',
    name: 'CAMBRIDGE INVESTMENT RESEARCH ADVISORS, INC.',
    label: 'CAMBRIDGE INVESTMENT RESEARCH ADVISORS, INC.',
  },
  {
    cik: '0001074272',
    value: '0001074272,CAMBRIDGE TRUST CO',
    name: 'CAMBRIDGE TRUST CO',
    label: 'CAMBRIDGE TRUST CO',
  },
  {
    cik: '0000903944',
    value: '0000903944,CAMDEN ASSET MANAGEMENT L P /CA',
    name: 'CAMDEN ASSET MANAGEMENT L P /CA',
    label: 'CAMDEN ASSET MANAGEMENT L P /CA',
  },
  {
    cik: '0001800752',
    value: '0001800752,CAMDEN CAPITAL, LLC',
    name: 'CAMDEN CAPITAL, LLC',
    label: 'CAMDEN CAPITAL, LLC',
  },
  {
    cik: '0001697855',
    value: '0001697855,CAMDEN NATIONAL BANK',
    name: 'CAMDEN NATIONAL BANK',
    label: 'CAMDEN NATIONAL BANK',
  },
  {
    cik: '0000750686',
    value: '0000750686,CAMDEN NATIONAL CORP',
    name: 'CAMDEN NATIONAL CORP',
    label: 'CAMDEN NATIONAL CORP',
  },
  {
    cik: '0001753570',
    value: '0001753570,CAMELOT CAPITAL PARTNERS, LLC',
    name: 'CAMELOT CAPITAL PARTNERS, LLC',
    label: 'CAMELOT CAPITAL PARTNERS, LLC',
  },
  {
    cik: '0001569766',
    value: '0001569766,CAMELOT PORTFOLIOS, LLC',
    name: 'CAMELOT PORTFOLIOS, LLC',
    label: 'CAMELOT PORTFOLIOS, LLC',
  },
  {
    cik: '0001625244',
    value: '0001625244,CAM GROUP HOLDING A/S',
    name: 'CAM GROUP HOLDING A/S',
    label: 'CAM GROUP HOLDING A/S',
  },
  {
    cik: '0001698094',
    value: '0001698094,CAMG SOLAMERE MANAGEMENT, LLC',
    name: 'CAMG SOLAMERE MANAGEMENT, LLC',
    label: 'CAMG SOLAMERE MANAGEMENT, LLC',
  },
  {
    cik: '0000826794',
    value: '0000826794,CAMPBELL CAPITAL MANAGEMENT INC',
    name: 'CAMPBELL CAPITAL MANAGEMENT INC',
    label: 'CAMPBELL CAPITAL MANAGEMENT INC',
  },
  {
    cik: '0001353570',
    value: '0001353570,CAMPBELL & CO INVESTMENT ADVISER LLC',
    name: 'CAMPBELL & CO INVESTMENT ADVISER LLC',
    label: 'CAMPBELL & CO INVESTMENT ADVISER LLC',
  },
  {
    cik: '0000874791',
    value: '0000874791,CAMPBELL NEWMAN ASSET MANAGEMENT INC',
    name: 'CAMPBELL NEWMAN ASSET MANAGEMENT INC',
    label: 'CAMPBELL NEWMAN ASSET MANAGEMENT INC',
  },
  {
    cik: '0001736648',
    value: '0001736648,CAMPBELL WEALTH MANAGEMENT',
    name: 'CAMPBELL WEALTH MANAGEMENT',
    label: 'CAMPBELL WEALTH MANAGEMENT',
  },
  {
    cik: '0001843492',
    value: '0001843492,CAMPION ASSET MANAGEMENT',
    name: 'CAMPION ASSET MANAGEMENT',
    label: 'CAMPION ASSET MANAGEMENT',
  },
  {
    cik: '0001654586',
    value: '0001654586,CANAAN PARTNERS IX LLC',
    name: 'CANAAN PARTNERS IX LLC',
    label: 'CANAAN PARTNERS IX LLC',
  },
  {
    cik: '0001552200',
    value: '0001552200,CANAAN PARTNERS VIII LLC',
    name: 'CANAAN PARTNERS VIII LLC',
    label: 'CANAAN PARTNERS VIII LLC',
  },
  {
    cik: '0001514133',
    value: '0001514133,CANAAN PARTNERS VII LLC',
    name: 'CANAAN PARTNERS VII LLC',
    label: 'CANAAN PARTNERS VII LLC',
  },
  {
    cik: '0001850694',
    value: '0001850694,CANAAN PARTNERS XI LLC',
    name: 'CANAAN PARTNERS XI LLC',
    label: 'CANAAN PARTNERS XI LLC',
  },
  {
    cik: '0001673228',
    value: '0001673228,CANAAN PARTNERS X LLC',
    name: 'CANAAN PARTNERS X LLC',
    label: 'CANAAN PARTNERS X LLC',
  },
  {
    cik: '0001283718',
    value: '0001283718,CANADA PENSION PLAN INVESTMENT BOARD',
    name: 'CANADA PENSION PLAN INVESTMENT BOARD',
    label: 'CANADA PENSION PLAN INVESTMENT BOARD',
  },
  {
    cik: '0001666733',
    value: '0001666733,CANAL CAPITAL MANAGEMENT, LLC',
    name: 'CANAL CAPITAL MANAGEMENT, LLC',
    label: 'CANAL CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001313473',
    value: '0001313473,CANAL INSURANCE CO',
    name: 'CANAL INSURANCE CO',
    label: 'CANAL INSURANCE CO',
  },
  {
    cik: '0001074266',
    value: '0001074266,CANANDAIGUA NATIONAL BANK & TRUST CO',
    name: 'CANANDAIGUA NATIONAL BANK & TRUST CO',
    label: 'CANANDAIGUA NATIONAL BANK & TRUST CO',
  },
  {
    cik: '0000759458',
    value: '0000759458,CANANDAIGUA NATIONAL CORP',
    name: 'CANANDAIGUA NATIONAL CORP',
    label: 'CANANDAIGUA NATIONAL CORP',
  },
  {
    cik: '0001789779',
    value: '0001789779,CANDLESTICK CAPITAL MANAGEMENT LP',
    name: 'CANDLESTICK CAPITAL MANAGEMENT LP',
    label: 'CANDLESTICK CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001531741',
    value: '0001531741,CANDLEWOOD INVESTMENT GROUP, LP',
    name: 'CANDLEWOOD INVESTMENT GROUP, LP',
    label: 'CANDLEWOOD INVESTMENT GROUP, LP',
  },
  {
    cik: '0001537014',
    value: '0001537014,CANDRIAM LUXEMBOURG S.C.A.',
    name: 'CANDRIAM LUXEMBOURG S.C.A.',
    label: 'CANDRIAM LUXEMBOURG S.C.A.',
  },
  {
    cik: '0001537014',
    value: '0001537014,CANDRIAM S.C.A.',
    name: 'CANDRIAM S.C.A.',
    label: 'CANDRIAM S.C.A.',
  },
  {
    cik: '0001704720',
    value: '0001704720,CANNAE HOLDINGS, INC.',
    name: 'CANNAE HOLDINGS, INC.',
    label: 'CANNAE HOLDINGS, INC.',
  },
  {
    cik: '0001058854',
    value: '0001058854,CANNELL CAPITAL LLC',
    name: 'CANNELL CAPITAL LLC',
    label: 'CANNELL CAPITAL LLC',
  },
  {
    cik: '0000016972',
    value: '0000016972,CANNELL & CO.',
    name: 'CANNELL & CO.',
    label: 'CANNELL & CO.',
  },
  {
    cik: '0000016972',
    value: '0000016972,CANNELL PETER B & CO INC',
    name: 'CANNELL PETER B & CO INC',
    label: 'CANNELL PETER B & CO INC',
  },
  {
    cik: '0001844427',
    value: '0001844427,CANNON GLOBAL INVESTMENT MANAGEMENT, LLC',
    name: 'CANNON GLOBAL INVESTMENT MANAGEMENT, LLC',
    label: 'CANNON GLOBAL INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001597858',
    value: '0001597858,CANTAB CAPITAL PARTNERS LLP',
    name: 'CANTAB CAPITAL PARTNERS LLP',
    label: 'CANTAB CAPITAL PARTNERS LLP',
  },
  {
    cik: '0001279936',
    value: '0001279936,CANTILLON CAPITAL MANAGEMENT LLC',
    name: 'CANTILLON CAPITAL MANAGEMENT LLC',
    label: 'CANTILLON CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001755785',
    value: '0001755785,CANTON HATHAWAY, LLC',
    name: 'CANTON HATHAWAY, LLC',
    label: 'CANTON HATHAWAY, LLC',
  },
  {
    cik: '0001710477',
    value: '0001710477,CANTOR FITZGERALD INVESTMENT ADVISER L.P.',
    name: 'CANTOR FITZGERALD INVESTMENT ADVISER L.P.',
    label: 'CANTOR FITZGERALD INVESTMENT ADVISER L.P.',
  },
  {
    cik: '0001710477',
    value: '0001710477,CANTOR FITZGERALD INVESTMENT ADVISOR L.P',
    name: 'CANTOR FITZGERALD INVESTMENT ADVISOR L.P',
    label: 'CANTOR FITZGERALD INVESTMENT ADVISOR L.P',
  },
  {
    cik: '0001024896',
    value: '0001024896,CANTOR FITZGERALD, L. P.',
    name: 'CANTOR FITZGERALD, L. P.',
    label: 'CANTOR FITZGERALD, L. P.',
  },
  {
    cik: '0001846773',
    value: '0001846773,CANVAS GP 1, L.L.C.',
    name: 'CANVAS GP 1, L.L.C.',
    label: 'CANVAS GP 1, L.L.C.',
  },
  {
    cik: '0001858782',
    value: '0001858782,CANVAS WEALTH ADVISORS, LLC',
    name: 'CANVAS WEALTH ADVISORS, LLC',
    label: 'CANVAS WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001074034',
    value: '0001074034,CANYON CAPITAL ADVISORS LLC',
    name: 'CANYON CAPITAL ADVISORS LLC',
    label: 'CANYON CAPITAL ADVISORS LLC',
  },
  {
    cik: '0001911938',
    value: '0001911938,CAPASSO PLANNING PARTNERS LLC',
    name: 'CAPASSO PLANNING PARTNERS LLC',
    label: 'CAPASSO PLANNING PARTNERS LLC',
  },
  {
    cik: '0001698068',
    value: '0001698068,CAPCO ASSET MANAGEMENT, LLC',
    name: 'CAPCO ASSET MANAGEMENT, LLC',
    label: 'CAPCO ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001768237',
    value: '0001768237,CAPE ANN ASSET MANAGEMENT LTD',
    name: 'CAPE ANN ASSET MANAGEMENT LTD',
    label: 'CAPE ANN ASSET MANAGEMENT LTD',
  },
  {
    cik: '0000936938',
    value: '0000936938,CAPE ANN SAVINGS BANK',
    name: 'CAPE ANN SAVINGS BANK',
    label: 'CAPE ANN SAVINGS BANK',
  },
  {
    cik: '0001054646',
    value: '0001054646,CAPE COD FIVE CENTS SAVINGS BANK',
    name: 'CAPE COD FIVE CENTS SAVINGS BANK',
    label: 'CAPE COD FIVE CENTS SAVINGS BANK',
  },
  {
    cik: '0001628896',
    value: '0001628896,CAPITAL ADVANTAGE, INC.',
    name: 'CAPITAL ADVANTAGE, INC.',
    label: 'CAPITAL ADVANTAGE, INC.',
  },
  {
    cik: '0000860828',
    value: '0000860828,CAPITAL ADVISORS INC/OK',
    name: 'CAPITAL ADVISORS INC/OK',
    label: 'CAPITAL ADVISORS INC/OK',
  },
  {
    cik: '0001597878',
    value: '0001597878,CAPITAL ADVISORS, LTD. LLC',
    name: 'CAPITAL ADVISORS, LTD. LLC',
    label: 'CAPITAL ADVISORS, LTD. LLC',
  },
  {
    cik: '0001824700',
    value: '0001824700,CAPITAL ADVISORS WEALTH MANAGEMENT, LLC',
    name: 'CAPITAL ADVISORS WEALTH MANAGEMENT, LLC',
    label: 'CAPITAL ADVISORS WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001803229',
    value: '0001803229,CAPITAL ADVISORY GROUP ADVISORY SERVICES, LLC',
    name: 'CAPITAL ADVISORY GROUP ADVISORY SERVICES, LLC',
    label: 'CAPITAL ADVISORY GROUP ADVISORY SERVICES, LLC',
  },
  {
    cik: '0001624758',
    value: '0001624758,CAPITAL ANALYSTS, INC.',
    name: 'CAPITAL ANALYSTS, INC.',
    label: 'CAPITAL ANALYSTS, INC.',
  },
  {
    cik: '0001624758',
    value: '0001624758,CAPITAL ANALYSTS, LLC',
    name: 'CAPITAL ANALYSTS, LLC',
    label: 'CAPITAL ANALYSTS, LLC',
  },
  {
    cik: '0001666024',
    value: '0001666024,CAPITAL ASSET ADVISORY SERVICES LLC',
    name: 'CAPITAL ASSET ADVISORY SERVICES LLC',
    label: 'CAPITAL ASSET ADVISORY SERVICES LLC',
  },
  {
    cik: '0001714524',
    value: '0001714524,CAPITAL BANK & TRUST CO',
    name: 'CAPITAL BANK & TRUST CO',
    label: 'CAPITAL BANK & TRUST CO',
  },
  {
    cik: '0001095836',
    value: '0001095836,CAPITAL CITY TRUST CO/FL',
    name: 'CAPITAL CITY TRUST CO/FL',
    label: 'CAPITAL CITY TRUST CO/FL',
  },
  {
    cik: '0001114928',
    value: '0001114928,CAPITAL COUNSEL LLC/NY',
    name: 'CAPITAL COUNSEL LLC/NY',
    label: 'CAPITAL COUNSEL LLC/NY',
  },
  {
    cik: '0001830008',
    value: '0001830008,CAPITAL CS GROUP, LLC',
    name: 'CAPITAL CS GROUP, LLC',
    label: 'CAPITAL CS GROUP, LLC',
  },
  {
    cik: '0001427684',
    value: '0001427684,CAPITAL DIRECTIONS INVESTMENT ADVISORS, LLC',
    name: 'CAPITAL DIRECTIONS INVESTMENT ADVISORS, LLC',
    label: 'CAPITAL DIRECTIONS INVESTMENT ADVISORS, LLC',
  },
  {
    cik: '0001763608',
    value: '0001763608,CAPITAL FINANCIAL PLANNING, LLC',
    name: 'CAPITAL FINANCIAL PLANNING, LLC',
    label: 'CAPITAL FINANCIAL PLANNING, LLC',
  },
  {
    cik: '0001926723',
    value: '0001926723,CAPITAL FINANCIAL SERVICES, LLC',
    name: 'CAPITAL FINANCIAL SERVICES, LLC',
    label: 'CAPITAL FINANCIAL SERVICES, LLC',
  },
  {
    cik: '0001323645',
    value: '0001323645,CAPITAL FUND MANAGEMENT S.A.',
    name: 'CAPITAL FUND MANAGEMENT S.A.',
    label: 'CAPITAL FUND MANAGEMENT S.A.',
  },
  {
    cik: '0000895213',
    value: '0000895213,CAPITAL GROUP INTERNATIONAL, INC./CA/',
    name: 'CAPITAL GROUP INTERNATIONAL, INC./CA/',
    label: 'CAPITAL GROUP INTERNATIONAL, INC./CA/',
  },
  {
    cik: '0001857666',
    value: '0001857666,CAPITAL GROUP PRIVATE CLIENT SERVICES, INC.',
    name: 'CAPITAL GROUP PRIVATE CLIENT SERVICES, INC.',
    label: 'CAPITAL GROUP PRIVATE CLIENT SERVICES, INC.',
  },
  {
    cik: '0000868491',
    value: '0000868491,CAPITAL GROWTH MANAGEMENT LP',
    name: 'CAPITAL GROWTH MANAGEMENT LP',
    label: 'CAPITAL GROWTH MANAGEMENT LP',
  },
  {
    cik: '0001599444',
    value: '0001599444,CAPITAL GUARDIAN ASSET MANAGEMENT, LLC',
    name: 'CAPITAL GUARDIAN ASSET MANAGEMENT, LLC',
    label: 'CAPITAL GUARDIAN ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0000314965',
    value: '0000314965,CAPITAL GUARDIAN TRUST CO',
    name: 'CAPITAL GUARDIAN TRUST CO',
    label: 'CAPITAL GUARDIAN TRUST CO',
  },
  {
    cik: '0001602198',
    value: '0001602198,CAPITAL IMPACT ADVISORS, LLC',
    name: 'CAPITAL IMPACT ADVISORS, LLC',
    label: 'CAPITAL IMPACT ADVISORS, LLC',
  },
  {
    cik: '0001596076',
    value: '0001596076,CAPITAL INNOVATIONS LLC',
    name: 'CAPITAL INNOVATIONS LLC',
    label: 'CAPITAL INNOVATIONS LLC',
  },
  {
    cik: '0001664385',
    value: '0001664385,CAPITAL INSIGHT PARTNERS, LLC',
    name: 'CAPITAL INSIGHT PARTNERS, LLC',
    label: 'CAPITAL INSIGHT PARTNERS, LLC',
  },
  {
    cik: '0000895213',
    value: '0000895213,CAPITAL INTERNATIONAL INC /CA/',
    name: 'CAPITAL INTERNATIONAL INC /CA/',
    label: 'CAPITAL INTERNATIONAL INC /CA/',
  },
  {
    cik: '0000895213',
    value: '0000895213,CAPITAL INTERNATIONAL, INC./CA/',
    name: 'CAPITAL INTERNATIONAL, INC./CA/',
    label: 'CAPITAL INTERNATIONAL, INC./CA/',
  },
  {
    cik: '0001562230',
    value: '0001562230,CAPITAL INTERNATIONAL INVESTORS',
    name: 'CAPITAL INTERNATIONAL INVESTORS',
    label: 'CAPITAL INTERNATIONAL INVESTORS',
  },
  {
    cik: '0001065350',
    value: '0001065350,CAPITAL INTERNATIONAL LTD /CA/',
    name: 'CAPITAL INTERNATIONAL LTD /CA/',
    label: 'CAPITAL INTERNATIONAL LTD /CA/',
  },
  {
    cik: '0001065349',
    value: '0001065349,CAPITAL INTERNATIONAL SARL',
    name: 'CAPITAL INTERNATIONAL SARL',
    label: 'CAPITAL INTERNATIONAL SARL',
  },
  {
    cik: '0001582732',
    value: '0001582732,CAPITAL INVESTMENT ADVISORS, LLC',
    name: 'CAPITAL INVESTMENT ADVISORS, LLC',
    label: 'CAPITAL INVESTMENT ADVISORS, LLC',
  },
  {
    cik: '0001659203',
    value: '0001659203,CAPITAL INVESTMENT ADVISORY SERVICES, LLC',
    name: 'CAPITAL INVESTMENT ADVISORY SERVICES, LLC',
    label: 'CAPITAL INVESTMENT ADVISORY SERVICES, LLC',
  },
  {
    cik: '0001418421',
    value: '0001418421,CAPITAL INVESTMENT COUNSEL, INC',
    name: 'CAPITAL INVESTMENT COUNSEL, INC',
    label: 'CAPITAL INVESTMENT COUNSEL, INC',
  },
  {
    cik: '0000811360',
    value: '0000811360,CAPITAL INVESTMENT SERVICES OF AMERICA, INC.',
    name: 'CAPITAL INVESTMENT SERVICES OF AMERICA, INC.',
    label: 'CAPITAL INVESTMENT SERVICES OF AMERICA, INC.',
  },
  {
    cik: '0000825293',
    value: '0000825293,CAPITAL MANAGEMENT ASSOCIATES /NY/',
    name: 'CAPITAL MANAGEMENT ASSOCIATES /NY/',
    label: 'CAPITAL MANAGEMENT ASSOCIATES /NY/',
  },
  {
    cik: '0001074273',
    value: '0001074273,CAPITAL MANAGEMENT CORP /VA',
    name: 'CAPITAL MANAGEMENT CORP /VA',
    label: 'CAPITAL MANAGEMENT CORP /VA',
  },
  {
    cik: '0001719165',
    value: '0001719165,CAPITAL MARKETS TRADING UK LLP',
    name: 'CAPITAL MARKETS TRADING UK LLP',
    label: 'CAPITAL MARKETS TRADING UK LLP',
  },
  {
    cik: '0001810158',
    value: '0001810158,CAPITAL MARKET STRATEGIES LLC',
    name: 'CAPITAL MARKET STRATEGIES LLC',
    label: 'CAPITAL MARKET STRATEGIES LLC',
  },
  {
    cik: '0000927628',
    value: '0000927628,CAPITAL ONE FINANCIAL CORP',
    name: 'CAPITAL ONE FINANCIAL CORP',
    label: 'CAPITAL ONE FINANCIAL CORP',
  },
  {
    cik: '0000047288',
    value: '0000047288,CAPITAL ONE, NATIONAL ASSOCIATION',
    name: 'CAPITAL ONE, NATIONAL ASSOCIATION',
    label: 'CAPITAL ONE, NATIONAL ASSOCIATION',
  },
  {
    cik: '0001905663',
    value: '0001905663,CAPITAL PERFORMANCE ADVISORS LLP',
    name: 'CAPITAL PERFORMANCE ADVISORS LLP',
    label: 'CAPITAL PERFORMANCE ADVISORS LLP',
  },
  {
    cik: '0001631941',
    value: '0001631941,CAPITAL PLANNING ADVISORS, LLC',
    name: 'CAPITAL PLANNING ADVISORS, LLC',
    label: 'CAPITAL PLANNING ADVISORS, LLC',
  },
  {
    cik: '0001790688',
    value: '0001790688,CAPITAL PLANNING LLC',
    name: 'CAPITAL PLANNING LLC',
    label: 'CAPITAL PLANNING LLC',
  },
  {
    cik: '0001422848',
    value: '0001422848,CAPITAL RESEARCH GLOBAL INVESTORS',
    name: 'CAPITAL RESEARCH GLOBAL INVESTORS',
    label: 'CAPITAL RESEARCH GLOBAL INVESTORS',
  },
  {
    cik: '0001525092',
    value: '0001525092,CAPITAL RETURNS MANAGEMENT, LLC',
    name: 'CAPITAL RETURNS MANAGEMENT, LLC',
    label: 'CAPITAL RETURNS MANAGEMENT, LLC',
  },
  {
    cik: '0001798736',
    value: '0001798736,CAPITAL SQUARE, LLC',
    name: 'CAPITAL SQUARE, LLC',
    label: 'CAPITAL SQUARE, LLC',
  },
  {
    cik: '0001625502',
    value: '0001625502,CAPITAL TODAY EVERGREEN FUND, L.P.',
    name: 'CAPITAL TODAY EVERGREEN FUND, L.P.',
    label: 'CAPITAL TODAY EVERGREEN FUND, L.P.',
  },
  {
    cik: '0001803277',
    value: '0001803277,CAPITAL WEALTH ALLIANCE, LLC',
    name: 'CAPITAL WEALTH ALLIANCE, LLC',
    label: 'CAPITAL WEALTH ALLIANCE, LLC',
  },
  {
    cik: '0001730456',
    value: '0001730456,CAPITAL WEALTH MANAGEMENT, LLC',
    name: 'CAPITAL WEALTH MANAGEMENT, LLC',
    label: 'CAPITAL WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001566728',
    value: '0001566728,CAPITAL WEALTH PLANNING, LLC',
    name: 'CAPITAL WEALTH PLANNING, LLC',
    label: 'CAPITAL WEALTH PLANNING, LLC',
  },
  {
    cik: '0001422849',
    value: '0001422849,CAPITAL WORLD INVESTORS',
    name: 'CAPITAL WORLD INVESTORS',
    label: 'CAPITAL WORLD INVESTORS',
  },
  {
    cik: '0001455704',
    value: '0001455704,CAPITAL Z PARTNERS MANAGEMENT, LLC',
    name: 'CAPITAL Z PARTNERS MANAGEMENT, LLC',
    label: 'CAPITAL Z PARTNERS MANAGEMENT, LLC',
  },
  {
    cik: '0001863768',
    value: '0001863768,CAPITOL FAMILY OFFICE, INC.',
    name: 'CAPITOL FAMILY OFFICE, INC.',
    label: 'CAPITOL FAMILY OFFICE, INC.',
  },
  {
    cik: '0001858879',
    value: '0001858879,CAPITOLIS ADVISORS LLC',
    name: 'CAPITOLIS ADVISORS LLC',
    label: 'CAPITOLIS ADVISORS LLC',
  },
  {
    cik: '0001901361',
    value: '0001901361,CAPRICORN FUND MANAGERS LTD',
    name: 'CAPRICORN FUND MANAGERS LTD',
    label: 'CAPRICORN FUND MANAGERS LTD',
  },
  {
    cik: '0001536261',
    value: '0001536261,CAPRICORN INVESTMENT GROUP LLC',
    name: 'CAPRICORN INVESTMENT GROUP LLC',
    label: 'CAPRICORN INVESTMENT GROUP LLC',
  },
  {
    cik: '0001034369',
    value: '0001034369,CAPRIN ASSET MANAGEMENT LLC /ADV',
    name: 'CAPRIN ASSET MANAGEMENT LLC /ADV',
    label: 'CAPRIN ASSET MANAGEMENT LLC /ADV',
  },
  {
    cik: '0001610769',
    value: '0001610769,CAPROCK GROUP, INC.',
    name: 'CAPROCK GROUP, INC.',
    label: 'CAPROCK GROUP, INC.',
  },
  {
    cik: '0000825204',
    value: '0000825204,CAPSTONE ASSET MANAGEMENT CO',
    name: 'CAPSTONE ASSET MANAGEMENT CO',
    label: 'CAPSTONE ASSET MANAGEMENT CO',
  },
  {
    cik: '0001599217',
    value: '0001599217,CAPSTONE FINANCIAL ADVISORS, INC.',
    name: 'CAPSTONE FINANCIAL ADVISORS, INC.',
    label: 'CAPSTONE FINANCIAL ADVISORS, INC.',
  },
  {
    cik: '0001808129',
    value: '0001808129,CAPSTONE FINANCIAL GROUP, INC.',
    name: 'CAPSTONE FINANCIAL GROUP, INC.',
    label: 'CAPSTONE FINANCIAL GROUP, INC.',
  },
  {
    cik: '0001426196',
    value: '0001426196,CAPSTONE INVESTMENT ADVISORS, LLC',
    name: 'CAPSTONE INVESTMENT ADVISORS, LLC',
    label: 'CAPSTONE INVESTMENT ADVISORS, LLC',
  },
  {
    cik: '0001580101',
    value: '0001580101,CAPSTONE INVESTMENT ADVISORS (NETHERLANDS), B.V.',
    name: 'CAPSTONE INVESTMENT ADVISORS (NETHERLANDS), B.V.',
    label: 'CAPSTONE INVESTMENT ADVISORS (NETHERLANDS), B.V.',
  },
  {
    cik: '0001811805',
    value: '0001811805,CAPSTONE TRITON FINANCIAL GROUP, LLC',
    name: 'CAPSTONE TRITON FINANCIAL GROUP, LLC',
    label: 'CAPSTONE TRITON FINANCIAL GROUP, LLC',
  },
  {
    cik: '0001923052',
    value: '0001923052,CAPSTONE WEALTH MANAGEMENT GROUP, LLC',
    name: 'CAPSTONE WEALTH MANAGEMENT GROUP, LLC',
    label: 'CAPSTONE WEALTH MANAGEMENT GROUP, LLC',
  },
  {
    cik: '0001706766',
    value: '0001706766,CAPTION MANAGEMENT, LLC',
    name: 'CAPTION MANAGEMENT, LLC',
    label: 'CAPTION MANAGEMENT, LLC',
  },
  {
    cik: '0001512024',
    value: '0001512024,CAPTRUST FINANCIAL ADVISORS',
    name: 'CAPTRUST FINANCIAL ADVISORS',
    label: 'CAPTRUST FINANCIAL ADVISORS',
  },
  {
    cik: '0001557017',
    value: '0001557017,CAPULA MANAGEMENT LTD',
    name: 'CAPULA MANAGEMENT LTD',
    label: 'CAPULA MANAGEMENT LTD',
  },
  {
    cik: '0001531809',
    value: '0001531809,CAPWEALTH ADVISORS, LLC',
    name: 'CAPWEALTH ADVISORS, LLC',
    label: 'CAPWEALTH ADVISORS, LLC',
  },
  {
    cik: '0001641865',
    value: '0001641865,CARBONADO CAPITAL',
    name: 'CARBONADO CAPITAL',
    label: 'CARBONADO CAPITAL',
  },
  {
    cik: '0001664503',
    value: '0001664503,CARDAN CAPITAL PARTNERS, LLC',
    name: 'CARDAN CAPITAL PARTNERS, LLC',
    label: 'CARDAN CAPITAL PARTNERS, LLC',
  },
  {
    cik: '0001168889',
    value: '0001168889,CARDEROCK CAPITAL MANAGEMENT INC',
    name: 'CARDEROCK CAPITAL MANAGEMENT INC',
    label: 'CARDEROCK CAPITAL MANAGEMENT INC',
  },
  {
    cik: '0001910000',
    value: '0001910000,CARDIFF PARK ADVISORS, LLC',
    name: 'CARDIFF PARK ADVISORS, LLC',
    label: 'CARDIFF PARK ADVISORS, LLC',
  },
  {
    cik: '0001434845',
    value: '0001434845,CARDINAL CAPITAL MANAGEMENT',
    name: 'CARDINAL CAPITAL MANAGEMENT',
    label: 'CARDINAL CAPITAL MANAGEMENT',
  },
  {
    cik: '0001518235',
    value: '0001518235,CARDINAL CAPITAL MANAGEMENT, INC.',
    name: 'CARDINAL CAPITAL MANAGEMENT, INC.',
    label: 'CARDINAL CAPITAL MANAGEMENT, INC.',
  },
  {
    cik: '0001080383',
    value: '0001080383,CARDINAL CAPITAL MANAGEMENT LLC /CT',
    name: 'CARDINAL CAPITAL MANAGEMENT LLC /CT',
    label: 'CARDINAL CAPITAL MANAGEMENT LLC /CT',
  },
  {
    cik: '0001869778',
    value: '0001869778,CARDINAL POINT WEALTH MANAGEMENT LLC',
    name: 'CARDINAL POINT WEALTH MANAGEMENT LLC',
    label: 'CARDINAL POINT WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0001909054',
    value: '0001909054,CARDINAL POINT WEALTH MANAGEMENT PARTNERS, LLC',
    name: 'CARDINAL POINT WEALTH MANAGEMENT PARTNERS, LLC',
    label: 'CARDINAL POINT WEALTH MANAGEMENT PARTNERS, LLC',
  },
  {
    cik: '0001801989',
    value: '0001801989,CARDINAL STRATEGIC WEALTH GUIDANCE',
    name: 'CARDINAL STRATEGIC WEALTH GUIDANCE',
    label: 'CARDINAL STRATEGIC WEALTH GUIDANCE',
  },
  {
    cik: '0001715163',
    value: '0001715163,CARILLON TOWER ADVISERS, INC.',
    name: 'CARILLON TOWER ADVISERS, INC.',
    label: 'CARILLON TOWER ADVISERS, INC.',
  },
  {
    cik: '0001308523',
    value: '0001308523,CARL DOMINO INC',
    name: 'CARL DOMINO INC',
    label: 'CARL DOMINO INC',
  },
  {
    cik: '0001660071',
    value: '0001660071,CARLEON CAPITAL PARTNERS LLC',
    name: 'CARLEON CAPITAL PARTNERS LLC',
    label: 'CARLEON CAPITAL PARTNERS LLC',
  },
  {
    cik: '0001818535',
    value: '0001818535,CARL P. SHERR & CO., LLC',
    name: 'CARL P. SHERR & CO., LLC',
    label: 'CARL P. SHERR & CO., LLC',
  },
  {
    cik: '0001056973',
    value: '0001056973,CARLSON CAPITAL L P',
    name: 'CARLSON CAPITAL L P',
    label: 'CARLSON CAPITAL L P',
  },
  {
    cik: '0001581298',
    value: '0001581298,CARLSON CAPITAL MANAGEMENT',
    name: 'CARLSON CAPITAL MANAGEMENT',
    label: 'CARLSON CAPITAL MANAGEMENT',
  },
  {
    cik: '0001910180',
    value: '0001910180,CARL STUART INVESTMENT ADVISOR, INC',
    name: 'CARL STUART INVESTMENT ADVISOR, INC',
    label: 'CARL STUART INVESTMENT ADVISOR, INC',
  },
  {
    cik: '0001510870',
    value: '0001510870,CARLTON HOFFERKAMP & JENKS WEALTH MANAGEMENT, LLC',
    name: 'CARLTON HOFFERKAMP & JENKS WEALTH MANAGEMENT, LLC',
    label: 'CARLTON HOFFERKAMP & JENKS WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001527166',
    value: '0001527166,CARLYLE GROUP INC.',
    name: 'CARLYLE GROUP INC.',
    label: 'CARLYLE GROUP INC.',
  },
  {
    cik: '0001878547',
    value: '0001878547,CARMEL CAPITAL MANAGEMENT L.L.C.',
    name: 'CARMEL CAPITAL MANAGEMENT L.L.C.',
    label: 'CARMEL CAPITAL MANAGEMENT L.L.C.',
  },
  {
    cik: '0001900481',
    value: '0001900481,CARMEL CAPITAL PARTNERS, LLC',
    name: 'CARMEL CAPITAL PARTNERS, LLC',
    label: 'CARMEL CAPITAL PARTNERS, LLC',
  },
  {
    cik: '0001730295',
    value: '0001730295,CARMICHAEL HILL & ASSOCIATES, INC.',
    name: 'CARMICHAEL HILL & ASSOCIATES, INC.',
    label: 'CARMICHAEL HILL & ASSOCIATES, INC.',
  },
  {
    cik: '0001569758',
    value: '0001569758,CARMIGNAC GESTION',
    name: 'CARMIGNAC GESTION',
    label: 'CARMIGNAC GESTION',
  },
  {
    cik: '0001511506',
    value: '0001511506,CARNEGIE CAPITAL ASSET MANAGEMENT, LLC',
    name: 'CARNEGIE CAPITAL ASSET MANAGEMENT, LLC',
    label: 'CARNEGIE CAPITAL ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001039067',
    value: '0001039067,CARNEGIE MELLON UNIVERSITY',
    name: 'CARNEGIE MELLON UNIVERSITY',
    label: 'CARNEGIE MELLON UNIVERSITY',
  },
  {
    cik: '0001682266',
    value: '0001682266,CARNICK & KUBIK GROUP, LLC',
    name: 'CARNICK & KUBIK GROUP, LLC',
    label: 'CARNICK & KUBIK GROUP, LLC',
  },
  {
    cik: '0001555900',
    value: '0001555900,CARNICK & KUBIK, LLC',
    name: 'CARNICK & KUBIK, LLC',
    label: 'CARNICK & KUBIK, LLC',
  },
  {
    cik: '0001803425',
    value: '0001803425,CAROLINAS WEALTH CONSULTING LLC',
    name: 'CAROLINAS WEALTH CONSULTING LLC',
    label: 'CAROLINAS WEALTH CONSULTING LLC',
  },
  {
    cik: '0001786411',
    value: '0001786411,CAROLINA WEALTH ADVISORS, LLC',
    name: 'CAROLINA WEALTH ADVISORS, LLC',
    label: 'CAROLINA WEALTH ADVISORS, LLC',
  },
  {
    cik: '0000791490',
    value: '0000791490,CARRET ASSET MANAGEMENT, LLC',
    name: 'CARRET ASSET MANAGEMENT, LLC',
    label: 'CARRET ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001567784',
    value: '0001567784,CARROLL FINANCIAL ASSOCIATES, INC.',
    name: 'CARROLL FINANCIAL ASSOCIATES, INC.',
    label: 'CARROLL FINANCIAL ASSOCIATES, INC.',
  },
  {
    cik: '0001911400',
    value: '0001911400,CARROLL INVESTORS, INC',
    name: 'CARROLL INVESTORS, INC',
    label: 'CARROLL INVESTORS, INC',
  },
  {
    cik: '0001866872',
    value: '0001866872,CARRONADE CAPITAL MANAGEMENT, LP',
    name: 'CARRONADE CAPITAL MANAGEMENT, LP',
    label: 'CARRONADE CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0001909304',
    value: '0001909304,CARSON ADVISORY INC.',
    name: 'CARSON ADVISORY INC.',
    label: 'CARSON ADVISORY INC.',
  },
  {
    cik: '0001900946',
    value: '0001900946,CARSONALLARIA WEALTH MANAGEMENT, LTD.',
    name: 'CARSONALLARIA WEALTH MANAGEMENT, LTD.',
    label: 'CARSONALLARIA WEALTH MANAGEMENT, LTD.',
  },
  {
    cik: '0001808928',
    value: '0001808928,CARTENNA CAPITAL, LP',
    name: 'CARTENNA CAPITAL, LP',
    label: 'CARTENNA CAPITAL, LP',
  },
  {
    cik: '0001919749',
    value: '0001919749,CARTER FINANCIAL GROUP, INC.',
    name: 'CARTER FINANCIAL GROUP, INC.',
    label: 'CARTER FINANCIAL GROUP, INC.',
  },
  {
    cik: '0001600011',
    value: '0001600011,CARTICA MANAGEMENT, LLC',
    name: 'CARTICA MANAGEMENT, LLC',
    label: 'CARTICA MANAGEMENT, LLC',
  },
  {
    cik: '0001425040',
    value: '0001425040,CARVAL INVESTORS, LLC',
    name: 'CARVAL INVESTORS, LLC',
    label: 'CARVAL INVESTORS, LLC',
  },
  {
    cik: '0001425040',
    value: '0001425040,CARVAL INVESTORS, LP',
    name: 'CARVAL INVESTORS, LP',
    label: 'CARVAL INVESTORS, LP',
  },
  {
    cik: '0001576209',
    value: '0001576209,CARVE CAPITALAB',
    name: 'CARVE CAPITALAB',
    label: 'CARVE CAPITALAB',
  },
  {
    cik: '0001766904',
    value: '0001766904,CARY STREET PARTNER INVESTMENT ADVISORY LLC',
    name: 'CARY STREET PARTNER INVESTMENT ADVISORY LLC',
    label: 'CARY STREET PARTNER INVESTMENT ADVISORY LLC',
  },
  {
    cik: '0001845445',
    value: '0001845445,CARY STREET PARTNERS ASSET MANAGEMENT LLC',
    name: 'CARY STREET PARTNERS ASSET MANAGEMENT LLC',
    label: 'CARY STREET PARTNERS ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001766904',
    value: '0001766904,CARY STREET PARTNERS INVESTMENT ADVISORY LLC',
    name: 'CARY STREET PARTNERS INVESTMENT ADVISORY LLC',
    label: 'CARY STREET PARTNERS INVESTMENT ADVISORY LLC',
  },
  {
    cik: '0001546017',
    value: '0001546017,CASABLANCA CAPITAL LP',
    name: 'CASABLANCA CAPITAL LP',
    label: 'CASABLANCA CAPITAL LP',
  },
  {
    cik: '0001535687',
    value: '0001535687,CASCABEL MANAGEMENT LP',
    name: 'CASCABEL MANAGEMENT LP',
    label: 'CASCABEL MANAGEMENT LP',
  },
  {
    cik: '0001681490',
    value: '0001681490,CASCADE INVESTMENT ADVISORS, INC.',
    name: 'CASCADE INVESTMENT ADVISORS, INC.',
    label: 'CASCADE INVESTMENT ADVISORS, INC.',
  },
  {
    cik: '0001800724',
    value: '0001800724,CASCADIA ADVISORY SERVICES, LLC',
    name: 'CASCADIA ADVISORY SERVICES, LLC',
    label: 'CASCADIA ADVISORY SERVICES, LLC',
  },
  {
    cik: '0001534261',
    value: '0001534261,CASDIN CAPITAL, LLC',
    name: 'CASDIN CAPITAL, LLC',
    label: 'CASDIN CAPITAL, LLC',
  },
  {
    cik: '0001697591',
    value: '0001697591,CAS INVESTMENT PARTNERS, LLC',
    name: 'CAS INVESTMENT PARTNERS, LLC',
    label: 'CAS INVESTMENT PARTNERS, LLC',
  },
  {
    cik: '0001518999',
    value: '0001518999,CASPIAN CAPITAL LP',
    name: 'CASPIAN CAPITAL LP',
    label: 'CASPIAN CAPITAL LP',
  },
  {
    cik: '0001900497',
    value: '0001900497,CASSADAY & CO INC.',
    name: 'CASSADAY & CO INC.',
    label: 'CASSADAY & CO INC.',
  },
  {
    cik: '0001904425',
    value: '0001904425,CASSADY SCHILLER WEALTH MANAGEMENT, LLC',
    name: 'CASSADY SCHILLER WEALTH MANAGEMENT, LLC',
    label: 'CASSADY SCHILLER WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001844897',
    value: '0001844897,CASSIA CAPITAL PARTNERS, LLC',
    name: 'CASSIA CAPITAL PARTNERS, LLC',
    label: 'CASSIA CAPITAL PARTNERS, LLC',
  },
  {
    cik: '0001832521',
    value: '0001832521,CASTELLAN GROUP',
    name: 'CASTELLAN GROUP',
    label: 'CASTELLAN GROUP',
  },
  {
    cik: '0001377929',
    value: '0001377929,CASTINE CAPITAL MANAGEMENT, LLC',
    name: 'CASTINE CAPITAL MANAGEMENT, LLC',
    label: 'CASTINE CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001732371',
    value: '0001732371,CASTLEARK ALTERNATIVES, LLC',
    name: 'CASTLEARK ALTERNATIVES, LLC',
    label: 'CASTLEARK ALTERNATIVES, LLC',
  },
  {
    cik: '0001106832',
    value: '0001106832,CASTLEARK MANAGEMENT LLC',
    name: 'CASTLEARK MANAGEMENT LLC',
    label: 'CASTLEARK MANAGEMENT LLC',
  },
  {
    cik: '0001326638',
    value: '0001326638,CASTLE CREEK ARBITRAGE, LLC',
    name: 'CASTLE CREEK ARBITRAGE, LLC',
    label: 'CASTLE CREEK ARBITRAGE, LLC',
  },
  {
    cik: '0001459707',
    value: '0001459707,CASTLE CREEK CAPITAL PARTNERS IV, LP',
    name: 'CASTLE CREEK CAPITAL PARTNERS IV, LP',
    label: 'CASTLE CREEK CAPITAL PARTNERS IV, LP',
  },
  {
    cik: '0001650259',
    value: '0001650259,CASTLE FINANCIAL & RETIREMENT PLANNING ASSOCIATES, INC.',
    name: 'CASTLE FINANCIAL & RETIREMENT PLANNING ASSOCIATES, INC.',
    label: 'CASTLE FINANCIAL & RETIREMENT PLANNING ASSOCIATES, INC.',
  },
  {
    cik: '0001687241',
    value: '0001687241,CASTLE HOOK PARTNERS LP',
    name: 'CASTLE HOOK PARTNERS LP',
    label: 'CASTLE HOOK PARTNERS LP',
  },
  {
    cik: '0001835751',
    value: '0001835751,CASTLEKNIGHT MANAGEMENT LP',
    name: 'CASTLEKNIGHT MANAGEMENT LP',
    label: 'CASTLEKNIGHT MANAGEMENT LP',
  },
  {
    cik: '0001657869',
    value: '0001657869,CASTLELAKE L.P.',
    name: 'CASTLELAKE L.P.',
    label: 'CASTLELAKE L.P.',
  },
  {
    cik: '0001647781',
    value: '0001647781,CASTLE RIDGE INVESTMENT MANAGEMENT LP',
    name: 'CASTLE RIDGE INVESTMENT MANAGEMENT LP',
    label: 'CASTLE RIDGE INVESTMENT MANAGEMENT LP',
  },
  {
    cik: '0001134576',
    value: '0001134576,CASTLEROCK ASSET MANAGEMENT INC',
    name: 'CASTLEROCK ASSET MANAGEMENT INC',
    label: 'CASTLEROCK ASSET MANAGEMENT INC',
  },
  {
    cik: '0001727605',
    value: '0001727605,CASTLE ROCK WEALTH MANAGEMENT, LLC',
    name: 'CASTLE ROCK WEALTH MANAGEMENT, LLC',
    label: 'CASTLE ROCK WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001632548',
    value: '0001632548,CASTLETON INVESTMENT MANAGEMENT LLC',
    name: 'CASTLETON INVESTMENT MANAGEMENT LLC',
    label: 'CASTLETON INVESTMENT MANAGEMENT LLC',
  },
  {
    cik: '0001737088',
    value: '0001737088,CASTLEVIEW PARTNERS, LLC',
    name: 'CASTLEVIEW PARTNERS, LLC',
    label: 'CASTLEVIEW PARTNERS, LLC',
  },
  {
    cik: '0001936420',
    value: '0001936420,CASTLE WEALTH MANAGEMENT LLC',
    name: 'CASTLE WEALTH MANAGEMENT LLC',
    label: 'CASTLE WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0001714364',
    value: '0001714364,CATALUS CAPITAL MANAGEMENT LLC',
    name: 'CATALUS CAPITAL MANAGEMENT LLC',
    label: 'CATALUS CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001549275',
    value: '0001549275,CATALYST CAPITAL ADVISORS LLC',
    name: 'CATALYST CAPITAL ADVISORS LLC',
    label: 'CATALYST CAPITAL ADVISORS LLC',
  },
  {
    cik: '0001927537',
    value: '0001927537,CATALYST FINANCIAL PARTNERS LLC',
    name: 'CATALYST FINANCIAL PARTNERS LLC',
    label: 'CATALYST FINANCIAL PARTNERS LLC',
  },
  {
    cik: '0001831193',
    value: '0001831193,CATALYST PRIVATE WEALTH, LLC',
    name: 'CATALYST PRIVATE WEALTH, LLC',
    label: 'CATALYST PRIVATE WEALTH, LLC',
  },
  {
    cik: '0001677443',
    value: '0001677443,CATAMOUNT WEALTH MANAGEMENT',
    name: 'CATAMOUNT WEALTH MANAGEMENT',
    label: 'CATAMOUNT WEALTH MANAGEMENT',
  },
  {
    cik: '0001426823',
    value: '0001426823,CATAPULT CAPITAL MANAGEMENT LLC',
    name: 'CATAPULT CAPITAL MANAGEMENT LLC',
    label: 'CATAPULT CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001080366',
    value: '0001080366,CATAWBA CAPITAL MANAGEMENT /VA',
    name: 'CATAWBA CAPITAL MANAGEMENT /VA',
    label: 'CATAWBA CAPITAL MANAGEMENT /VA',
  },
  {
    cik: '0001301226',
    value: '0001301226,CATLIN GROUP LTD',
    name: 'CATLIN GROUP LTD',
    label: 'CATLIN GROUP LTD',
  },
  {
    cik: '0001654648',
    value: '0001654648,CAT ROCK CAPITAL MANAGEMENT LP',
    name: 'CAT ROCK CAPITAL MANAGEMENT LP',
    label: 'CAT ROCK CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001560123',
    value: '0001560123,CATTERTON MANAGEMENT COMPANY, L.L.C.',
    name: 'CATTERTON MANAGEMENT COMPANY, L.L.C.',
    label: 'CATTERTON MANAGEMENT COMPANY, L.L.C.',
  },
  {
    cik: '0001165797',
    value: '0001165797,CAUSEWAY CAPITAL MANAGEMENT LLC',
    name: 'CAUSEWAY CAPITAL MANAGEMENT LLC',
    label: 'CAUSEWAY CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001585687',
    value: '0001585687,CAVALANE HOLDINGS PTY LTD',
    name: 'CAVALANE HOLDINGS PTY LTD',
    label: 'CAVALANE HOLDINGS PTY LTD',
  },
  {
    cik: '0001655543',
    value: '0001655543,CAVALIER INVESTMENTS',
    name: 'CAVALIER INVESTMENTS',
    label: 'CAVALIER INVESTMENTS',
  },
  {
    cik: '0001655543',
    value: '0001655543,CAVALIER INVESTMENTS, LLC',
    name: 'CAVALIER INVESTMENTS, LLC',
    label: 'CAVALIER INVESTMENTS, LLC',
  },
  {
    cik: '0001484165',
    value: '0001484165,CAVALRY MANAGEMENT GROUP, LLC',
    name: 'CAVALRY MANAGEMENT GROUP, LLC',
    label: 'CAVALRY MANAGEMENT GROUP, LLC',
  },
  {
    cik: '0000872573',
    value: '0000872573,CAXTON ASSOCIATES LP',
    name: 'CAXTON ASSOCIATES LP',
    label: 'CAXTON ASSOCIATES LP',
  },
  {
    cik: '0001388551',
    value: '0001388551,CAXTON CORP',
    name: 'CAXTON CORP',
    label: 'CAXTON CORP',
  },
  {
    cik: '0001349352',
    value: '0001349352,CAYMUS CAPITAL PARTNERS, L.P.',
    name: 'CAYMUS CAPITAL PARTNERS, L.P.',
    label: 'CAYMUS CAPITAL PARTNERS, L.P.',
  },
  {
    cik: '0001159363',
    value: '0001159363,CAZ INVESTMENTS LP',
    name: 'CAZ INVESTMENTS LP',
    label: 'CAZ INVESTMENTS LP',
  },
  {
    cik: '0001795934',
    value: '0001795934,CBIZ INVESTMENT ADVISORY SERVICES, LLC',
    name: 'CBIZ INVESTMENT ADVISORY SERVICES, LLC',
    label: 'CBIZ INVESTMENT ADVISORY SERVICES, LLC',
  },
  {
    cik: '0001709632',
    value: '0001709632,CBOE VEST FINANCIAL, LLC',
    name: 'CBOE VEST FINANCIAL, LLC',
    label: 'CBOE VEST FINANCIAL, LLC',
  },
  {
    cik: '0001033984',
    value: '0001033984,CBRE CLARION SECURITIES LLC',
    name: 'CBRE CLARION SECURITIES LLC',
    label: 'CBRE CLARION SECURITIES LLC',
  },
  {
    cik: '0001033984',
    value: '0001033984,CBRE INVESTMENT MANAGEMENT LISTED REAL ASSETS LLC',
    name: 'CBRE INVESTMENT MANAGEMENT LISTED REAL ASSETS LLC',
    label: 'CBRE INVESTMENT MANAGEMENT LISTED REAL ASSETS LLC',
  },
  {
    cik: '0001486083',
    value: '0001486083,CB&T WEALTH MANAGEMENT',
    name: 'CB&T WEALTH MANAGEMENT',
    label: 'CB&T WEALTH MANAGEMENT',
  },
  {
    cik: '0001771169',
    value: '0001771169,CCG WEALTH MANAGEMENT, LLC',
    name: 'CCG WEALTH MANAGEMENT, LLC',
    label: 'CCG WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001631562',
    value: '0001631562,CCLA INVESTMENT MANAGEMENT',
    name: 'CCLA INVESTMENT MANAGEMENT',
    label: 'CCLA INVESTMENT MANAGEMENT',
  },
  {
    cik: '0001631562',
    value: '0001631562,CCLA INVESTMENT MANAGEMENT LTD',
    name: 'CCLA INVESTMENT MANAGEMENT LTD',
    label: 'CCLA INVESTMENT MANAGEMENT LTD',
  },
  {
    cik: '0000931097',
    value: '0000931097,CCM INVESTMENT ADVISERS LLC',
    name: 'CCM INVESTMENT ADVISERS LLC',
    label: 'CCM INVESTMENT ADVISERS LLC',
  },
  {
    cik: '0001674350',
    value: '0001674350,CCMP CAPITAL GP, LLC',
    name: 'CCMP CAPITAL GP, LLC',
    label: 'CCMP CAPITAL GP, LLC',
  },
  {
    cik: '0001372939',
    value: '0001372939,CCMP CAPITAL, LLC',
    name: 'CCMP CAPITAL, LLC',
    label: 'CCMP CAPITAL, LLC',
  },
  {
    cik: '0001719387',
    value: '0001719387,CDAM (UK) LTD',
    name: 'CDAM (UK) LTD',
    label: 'CDAM (UK) LTD',
  },
  {
    cik: '0001767302',
    value: '0001767302,CEDAR BROOK FINANCIAL PARTNERS, LLC',
    name: 'CEDAR BROOK FINANCIAL PARTNERS, LLC',
    label: 'CEDAR BROOK FINANCIAL PARTNERS, LLC',
  },
  {
    cik: '0001666746',
    value: '0001666746,CEDAR CAPITAL, LLC',
    name: 'CEDAR CAPITAL, LLC',
    label: 'CEDAR CAPITAL, LLC',
  },
  {
    cik: '0001434641',
    value: '0001434641,CEDAR HILL ASSOCIATES, LLC',
    name: 'CEDAR HILL ASSOCIATES, LLC',
    label: 'CEDAR HILL ASSOCIATES, LLC',
  },
  {
    cik: '0001798986',
    value: '0001798986,CEDAR MOUNTAIN ADVISORS, LLC',
    name: 'CEDAR MOUNTAIN ADVISORS, LLC',
    label: 'CEDAR MOUNTAIN ADVISORS, LLC',
  },
  {
    cik: '0001315765',
    value: '0001315765,CEDAR ROCK CAPITAL LTD',
    name: 'CEDAR ROCK CAPITAL LTD',
    label: 'CEDAR ROCK CAPITAL LTD',
  },
  {
    cik: '0001665642',
    value: '0001665642,CEDAR WEALTH MANAGEMENT, LLC',
    name: 'CEDAR WEALTH MANAGEMENT, LLC',
    label: 'CEDAR WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001861244',
    value: '0001861244,CEDERBERG CAPITAL LTD',
    name: 'CEDERBERG CAPITAL LTD',
    label: 'CEDERBERG CAPITAL LTD',
  },
  {
    cik: '0001860698',
    value: '0001860698,CEERA INVESTMENTS, LLC',
    name: 'CEERA INVESTMENTS, LLC',
    label: 'CEERA INVESTMENTS, LLC',
  },
  {
    cik: '0001453039',
    value: '0001453039,CENTAUR CAPITAL PARTNERS, L.P.',
    name: 'CENTAUR CAPITAL PARTNERS, L.P.',
    label: 'CENTAUR CAPITAL PARTNERS, L.P.',
  },
  {
    cik: '0001297966',
    value: '0001297966,CENTAURUS CAPITAL LP',
    name: 'CENTAURUS CAPITAL LP',
    label: 'CENTAURUS CAPITAL LP',
  },
  {
    cik: '0000891943',
    value: '0000891943,CENTAURUS FINANCIAL, INC.',
    name: 'CENTAURUS FINANCIAL, INC.',
    label: 'CENTAURUS FINANCIAL, INC.',
  },
  {
    cik: '0001839421',
    value: '0001839421,CENTENNIAL WEALTH ADVISORY LLC',
    name: 'CENTENNIAL WEALTH ADVISORY LLC',
    label: 'CENTENNIAL WEALTH ADVISORY LLC',
  },
  {
    cik: '0001720979',
    value: '0001720979,CENTENUS GLOBAL MANAGEMENT, LP',
    name: 'CENTENUS GLOBAL MANAGEMENT, LP',
    label: 'CENTENUS GLOBAL MANAGEMENT, LP',
  },
  {
    cik: '0001872738',
    value: '0001872738,CENTERBOOK PARTNERS LP',
    name: 'CENTERBOOK PARTNERS LP',
    label: 'CENTERBOOK PARTNERS LP',
  },
  {
    cik: '0001484836',
    value: '0001484836,CENTERBRIDGE PARTNERS, L.P.',
    name: 'CENTERBRIDGE PARTNERS, L.P.',
    label: 'CENTERBRIDGE PARTNERS, L.P.',
  },
  {
    cik: '0001521079',
    value: '0001521079,CENTER COAST CAPITAL ADVISORS, LP',
    name: 'CENTER COAST CAPITAL ADVISORS, LP',
    label: 'CENTER COAST CAPITAL ADVISORS, LP',
  },
  {
    cik: '0001894543',
    value: '0001894543,CENTERED WEALTH LLC DBA MILLER EQUITY CAPITAL ADVISORS',
    name: 'CENTERED WEALTH LLC DBA MILLER EQUITY CAPITAL ADVISORS',
    label: 'CENTERED WEALTH LLC DBA MILLER EQUITY CAPITAL ADVISORS',
  },
  {
    cik: '0001844878',
    value: '0001844878,CENTER FOR ASSET MANAGEMENT LLC',
    name: 'CENTER FOR ASSET MANAGEMENT LLC',
    label: 'CENTER FOR ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001803557',
    value: '0001803557,CENTER FOR FINANCIAL PLANNING, INC.',
    name: 'CENTER FOR FINANCIAL PLANNING, INC.',
    label: 'CENTER FOR FINANCIAL PLANNING, INC.',
  },
  {
    cik: '0001771673',
    value: '0001771673,CENTER LAKE CAPITAL MANAGEMENT, LLC',
    name: 'CENTER LAKE CAPITAL MANAGEMENT, LLC',
    label: 'CENTER LAKE CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001599455',
    value: '0001599455,CENTERLINE INVESTMENT PARTNERS, LP',
    name: 'CENTERLINE INVESTMENT PARTNERS, LP',
    label: 'CENTERLINE INVESTMENT PARTNERS, LP',
  },
  {
    cik: '0001733788',
    value: '0001733788,CENTERPOINT ADVISORS, LLC',
    name: 'CENTERPOINT ADVISORS, LLC',
    label: 'CENTERPOINT ADVISORS, LLC',
  },
  {
    cik: '0001725296',
    value: '0001725296,CENTERSQUARE INVESTMENT MANAGEMENT LLC',
    name: 'CENTERSQUARE INVESTMENT MANAGEMENT LLC',
    label: 'CENTERSQUARE INVESTMENT MANAGEMENT LLC',
  },
  {
    cik: '0001722053',
    value: '0001722053,CENTERSTAR ASSET MANAGEMENT, LLC',
    name: 'CENTERSTAR ASSET MANAGEMENT, LLC',
    label: 'CENTERSTAR ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001632849',
    value: '0001632849,CENTERSTAR FUND, LLC',
    name: 'CENTERSTAR FUND, LLC',
    label: 'CENTERSTAR FUND, LLC',
  },
  {
    cik: '0001766788',
    value: '0001766788,CENTERSTONE INVESTORS, LLC',
    name: 'CENTERSTONE INVESTORS, LLC',
    label: 'CENTERSTONE INVESTORS, LLC',
  },
  {
    cik: '0001692507',
    value: '0001692507,CENTIVA CAPITAL, LP',
    name: 'CENTIVA CAPITAL, LP',
    label: 'CENTIVA CAPITAL, LP',
  },
  {
    cik: '0001583672',
    value:
      '0001583672,CENTRAL ASSET INVESTMENTS & MANAGEMENT HOLDINGS (HK) LTD',
    name: 'CENTRAL ASSET INVESTMENTS & MANAGEMENT HOLDINGS (HK) LTD',
    label: 'CENTRAL ASSET INVESTMENTS & MANAGEMENT HOLDINGS (HK) LTD',
  },
  {
    cik: '0001175954',
    value: '0001175954,CENTRAL BANK & TRUST CO',
    name: 'CENTRAL BANK & TRUST CO',
    label: 'CENTRAL BANK & TRUST CO',
  },
  {
    cik: '0000018748',
    value: '0000018748,CENTRAL SECURITIES CORP',
    name: 'CENTRAL SECURITIES CORP',
    label: 'CENTRAL SECURITIES CORP',
  },
  {
    cik: '0001441559',
    value: '0001441559,CENTRAL SQUARE MANAGEMENT LLC',
    name: 'CENTRAL SQUARE MANAGEMENT LLC',
    label: 'CENTRAL SQUARE MANAGEMENT LLC',
  },
  {
    cik: '0000044365',
    value: '0000044365,CENTRAL TRUST CO',
    name: 'CENTRAL TRUST CO',
    label: 'CENTRAL TRUST CO',
  },
  {
    cik: '0000044365',
    value: '0000044365,CENTRAL TRUST & INVESTMENT CO',
    name: 'CENTRAL TRUST & INVESTMENT CO',
    label: 'CENTRAL TRUST & INVESTMENT CO',
  },
  {
    cik: '0001532472',
    value: '0001532472,CENTRE ASSET MANAGEMENT, LLC',
    name: 'CENTRE ASSET MANAGEMENT, LLC',
    label: 'CENTRE ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001752761',
    value: '0001752761,CENTRIC WEALTH MANAGEMENT',
    name: 'CENTRIC WEALTH MANAGEMENT',
    label: 'CENTRIC WEALTH MANAGEMENT',
  },
  {
    cik: '0001289442',
    value: '0001289442,CENTURY CAPITAL MANAGEMENT, LLC',
    name: 'CENTURY CAPITAL MANAGEMENT, LLC',
    label: 'CENTURY CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001054522',
    value: '0001054522,CENTURYLINK INVESTMENT MANAGEMENT CO',
    name: 'CENTURYLINK INVESTMENT MANAGEMENT CO',
    label: 'CENTURYLINK INVESTMENT MANAGEMENT CO',
  },
  {
    cik: '0001843237',
    value: '0001843237,CEPHEI CAPITAL MANAGEMENT (HONG KONG) LTD',
    name: 'CEPHEI CAPITAL MANAGEMENT (HONG KONG) LTD',
    label: 'CEPHEI CAPITAL MANAGEMENT (HONG KONG) LTD',
  },
  {
    cik: '0001525907',
    value: '0001525907,CERBERUS CAPITAL MANAGEMENT, L.P.',
    name: 'CERBERUS CAPITAL MANAGEMENT, L.P.',
    label: 'CERBERUS CAPITAL MANAGEMENT, L.P.',
  },
  {
    cik: '0001578472',
    value: '0001578472,CEREBELLUM GP, LLC',
    name: 'CEREBELLUM GP, LLC',
    label: 'CEREBELLUM GP, LLC',
  },
  {
    cik: '0001738726',
    value: '0001738726,CEREDEX VALUE ADVISORS LLC',
    name: 'CEREDEX VALUE ADVISORS LLC',
    label: 'CEREDEX VALUE ADVISORS LLC',
  },
  {
    cik: '0001566475',
    value: '0001566475,CERITY PARTNERS LLC',
    name: 'CERITY PARTNERS LLC',
    label: 'CERITY PARTNERS LLC',
  },
  {
    cik: '0001536022',
    value: '0001536022,CEROS FINANCIAL SERVICES INC.',
    name: 'CEROS FINANCIAL SERVICES INC.',
    label: 'CEROS FINANCIAL SERVICES INC.',
  },
  {
    cik: '0001730841',
    value: '0001730841,CERRANO CAPITAL MANAGEMENT LP',
    name: 'CERRANO CAPITAL MANAGEMENT LP',
    label: 'CERRANO CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001842669',
    value: '0001842669,CERRO PACIFIC WEALTH ADVISORS LLC',
    name: 'CERRO PACIFIC WEALTH ADVISORS LLC',
    label: 'CERRO PACIFIC WEALTH ADVISORS LLC',
  },
  {
    cik: '0001807622',
    value: '0001807622,CERTARES MANAGEMENT LLC',
    name: 'CERTARES MANAGEMENT LLC',
    label: 'CERTARES MANAGEMENT LLC',
  },
  {
    cik: '0001871984',
    value: '0001871984,CERTARES OPPORTUNITIES LLC',
    name: 'CERTARES OPPORTUNITIES LLC',
    label: 'CERTARES OPPORTUNITIES LLC',
  },
  {
    cik: '0001731732',
    value: '0001731732,CERTIFIED ADVISORY CORP',
    name: 'CERTIFIED ADVISORY CORP',
    label: 'CERTIFIED ADVISORY CORP',
  },
  {
    cik: '0001534468',
    value: '0001534468,CETERA ADVISOR NETWORKS LLC',
    name: 'CETERA ADVISOR NETWORKS LLC',
    label: 'CETERA ADVISOR NETWORKS LLC',
  },
  {
    cik: '0001534400',
    value: '0001534400,CETERA ADVISORS LLC',
    name: 'CETERA ADVISORS LLC',
    label: 'CETERA ADVISORS LLC',
  },
  {
    cik: '0001666741',
    value: '0001666741,CETERA INVESTMENT ADVISERS',
    name: 'CETERA INVESTMENT ADVISERS',
    label: 'CETERA INVESTMENT ADVISERS',
  },
  {
    cik: '0001365341',
    value: '0001365341,CEVIAN CAPITAL II GP LTD',
    name: 'CEVIAN CAPITAL II GP LTD',
    label: 'CEVIAN CAPITAL II GP LTD',
  },
  {
    cik: '0001546989',
    value: '0001546989,CFG ASSET MANAGEMENT LLC',
    name: 'CFG ASSET MANAGEMENT LLC',
    label: 'CFG ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001801097',
    value: '0001801097,CFM WEALTH PARTNERS LLC',
    name: 'CFM WEALTH PARTNERS LLC',
    label: 'CFM WEALTH PARTNERS LLC',
  },
  {
    cik: '0001706351',
    value: '0001706351,CFO4LIFE GROUP, LLC',
    name: 'CFO4LIFE GROUP, LLC',
    label: 'CFO4LIFE GROUP, LLC',
  },
  {
    cik: '0001632248',
    value: '0001632248,CFO4LIFE, L.P.',
    name: 'CFO4LIFE, L.P.',
    label: 'CFO4LIFE, L.P.',
  },
  {
    cik: '0001845688',
    value: '0001845688,CFS INVESTMENT ADVISORY SERVICES, LLC',
    name: 'CFS INVESTMENT ADVISORY SERVICES, LLC',
    label: 'CFS INVESTMENT ADVISORY SERVICES, LLC',
  },
  {
    cik: '0001568542',
    value: '0001568,CFS, LLC2',
    name: 'CFS, LLC',
    label: 'CFS, LLC',
  },
  {
    cik: '0001599444',
    value: '0001599444,CG ASSET MANAGEMENT LLC',
    name: 'CG ASSET MANAGEMENT LLC',
    label: 'CG ASSET MANAGEMENT LLC',
  },
  {
    cik: '0000060335',
    value: '00000603,CGM TRUST',
    name: 'CGM TRUST',
    label: 'CGM TRUST',
  },
  {
    cik: '0001841506',
    value: '0001841506,CGN ADVISORS LLC',
    name: 'CGN ADVISORS LLC',
    label: 'CGN ADVISORS LLC',
  },
  {
    cik: '0001685798',
    value: '0001685798,CGOV ASSET MANAGEMENT',
    name: 'CGOV ASSET MANAGEMENT',
    label: 'CGOV ASSET MANAGEMENT',
  },
  {
    cik: '0001250731',
    value: '0001250731,CHAI TRUST CO LLC',
    name: 'CHAI TRUST CO LLC',
    label: 'CHAI TRUST CO LLC',
  },
  {
    cik: '0001315478',
    value: '0001315478,CHAMPLAIN INVESTMENT PARTNERS, LLC',
    name: 'CHAMPLAIN INVESTMENT PARTNERS, LLC',
    label: 'CHAMPLAIN INVESTMENT PARTNERS, LLC',
  },
  {
    cik: '0001665018',
    value: '0001665018,CHANDLER ASSET MANAGEMENT, INC.',
    name: 'CHANDLER ASSET MANAGEMENT, INC.',
    label: 'CHANDLER ASSET MANAGEMENT, INC.',
  },
  {
    cik: '0001542156',
    value: '0001542156,CHANDLER HOLDINGS LTD',
    name: 'CHANDLER HOLDINGS LTD',
    label: 'CHANDLER HOLDINGS LTD',
  },
  {
    cik: '0001739728',
    value: '0001739728,CHANGE PATH, LLC',
    name: 'CHANGE PATH, LLC',
    label: 'CHANGE PATH, LLC',
  },
  {
    cik: '0001302404',
    value: '0001302404,CHANNING CAPITAL MANAGEMENT, LLC',
    name: 'CHANNING CAPITAL MANAGEMENT, LLC',
    label: 'CHANNING CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001901222',
    value: '0001901222,CHANNING GLOBAL ADVISORS, LLC',
    name: 'CHANNING GLOBAL ADVISORS, LLC',
    label: 'CHANNING GLOBAL ADVISORS, LLC',
  },
  {
    cik: '0001751006',
    value: '0001751006,CHAPMAN INVESTMENT MANAGEMENT, LLC',
    name: 'CHAPMAN INVESTMENT MANAGEMENT, LLC',
    label: 'CHAPMAN INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001422023',
    value: '0001422023,CHAPTER IV INVESTORS, LLC',
    name: 'CHAPTER IV INVESTORS, LLC',
    label: 'CHAPTER IV INVESTORS, LLC',
  },
  {
    cik: '0001797334',
    value: '0001797334,CHAPWOOD CAPITAL INVESTMENT MANAGEMENT, LLC',
    name: 'CHAPWOOD CAPITAL INVESTMENT MANAGEMENT, LLC',
    label: 'CHAPWOOD CAPITAL INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001351841',
    value: '0001351841,CHARLEMAGNE CAPITAL LTD',
    name: 'CHARLEMAGNE CAPITAL LTD',
    label: 'CHARLEMAGNE CAPITAL LTD',
  },
  {
    cik: '0001899059',
    value: '0001899059,CHARLES CARROLL FINANCIAL PARTNERS, LLC',
    name: 'CHARLES CARROLL FINANCIAL PARTNERS, LLC',
    label: 'CHARLES CARROLL FINANCIAL PARTNERS, LLC',
  },
  {
    cik: '0001634255',
    value: '0001634255,CHARLES-LIM CAPITAL LTD',
    name: 'CHARLES-LIM CAPITAL LTD',
    label: 'CHARLES-LIM CAPITAL LTD',
  },
  {
    cik: '0001519780',
    value: '0001519780,CHARLES RIVER XIII GP, LLC',
    name: 'CHARLES RIVER XIII GP, LLC',
    label: 'CHARLES RIVER XIII GP, LLC',
  },
  {
    cik: '0001501902',
    value: '0001501902,CHARLES SCHWAB INVESTMENT ADVISORY, INC.',
    name: 'CHARLES SCHWAB INVESTMENT ADVISORY, INC.',
    label: 'CHARLES SCHWAB INVESTMENT ADVISORY, INC.',
  },
  {
    cik: '0000884546',
    value: '0000884546,CHARLES SCHWAB INVESTMENT MANAGEMENT INC',
    name: 'CHARLES SCHWAB INVESTMENT MANAGEMENT INC',
    label: 'CHARLES SCHWAB INVESTMENT MANAGEMENT INC',
  },
  {
    cik: '0001789219',
    value: '0001789219,CHARLES SCHWAB TRUST CO',
    name: 'CHARLES SCHWAB TRUST CO',
    label: 'CHARLES SCHWAB TRUST CO',
  },
  {
    cik: '0001121779',
    value: '0001121779,CHARLES STEWART MOTT FOUNDATION',
    name: 'CHARLES STEWART MOTT FOUNDATION',
    label: 'CHARLES STEWART MOTT FOUNDATION',
  },
  {
    cik: '0001533321',
    value: '0001533321,CHARTER BRIDGE CAPITAL MANAGEMENT, L.P.',
    name: 'CHARTER BRIDGE CAPITAL MANAGEMENT, L.P.',
    label: 'CHARTER BRIDGE CAPITAL MANAGEMENT, L.P.',
  },
  {
    cik: '0001810720',
    value: '0001810720,CHARTER OAK CAPITAL MANAGEMENT, LLC',
    name: 'CHARTER OAK CAPITAL MANAGEMENT, LLC',
    label: 'CHARTER OAK CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001492145',
    value: '0001492145,CHARTER OAK PARTNERS MANAGEMENT, L.P.',
    name: 'CHARTER OAK PARTNERS MANAGEMENT, L.P.',
    label: 'CHARTER OAK PARTNERS MANAGEMENT, L.P.',
  },
  {
    cik: '0001666624',
    value: '0001666624,CHARTER RESEARCH & INVESTMENT GROUP, INC.',
    name: 'CHARTER RESEARCH & INVESTMENT GROUP, INC.',
    label: 'CHARTER RESEARCH & INVESTMENT GROUP, INC.',
  },
  {
    cik: '0001035463',
    value: '0001035463,CHARTER TRUST CO',
    name: 'CHARTER TRUST CO',
    label: 'CHARTER TRUST CO',
  },
  {
    cik: '0001129770',
    value: '0001129770,CHARTIST INC /CA/',
    name: 'CHARTIST INC /CA/',
    label: 'CHARTIST INC /CA/',
  },
  {
    cik: '0001616525',
    value: '0001616525,CHARTWELL INVESTMENT PARTNERS, INC.',
    name: 'CHARTWELL INVESTMENT PARTNERS, INC.',
    label: 'CHARTWELL INVESTMENT PARTNERS, INC.',
  },
  {
    cik: '0001616525',
    value: '0001616525,CHARTWELL INVESTMENT PARTNERS, LLC',
    name: 'CHARTWELL INVESTMENT PARTNERS, LLC',
    label: 'CHARTWELL INVESTMENT PARTNERS, LLC',
  },
  {
    cik: '0001044516',
    value: '0001044516,CHARTWELL INVESTMENT PARTNERS/PA',
    name: 'CHARTWELL INVESTMENT PARTNERS/PA',
    label: 'CHARTWELL INVESTMENT PARTNERS/PA',
  },
  {
    cik: '0000019475',
    value: '0000019475,CHASE INVESTMENT COUNSEL CORP',
    name: 'CHASE INVESTMENT COUNSEL CORP',
    label: 'CHASE INVESTMENT COUNSEL CORP',
  },
  {
    cik: '0001511989',
    value: '0001511989,CHATHAM ASSET MANAGEMENT, LLC',
    name: 'CHATHAM ASSET MANAGEMENT, LLC',
    label: 'CHATHAM ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001108969',
    value: '0001108969,CHATHAM CAPITAL GROUP, INC.',
    name: 'CHATHAM CAPITAL GROUP, INC.',
    label: 'CHATHAM CAPITAL GROUP, INC.',
  },
  {
    cik: '0001564721',
    value: '0001564721,CHAUTAUQUA CAPITAL MANAGEMENT',
    name: 'CHAUTAUQUA CAPITAL MANAGEMENT',
    label: 'CHAUTAUQUA CAPITAL MANAGEMENT',
  },
  {
    cik: '0001598186',
    value: '0001598186,CHECCHI CAPITAL ADVISERS, LLC',
    name: 'CHECCHI CAPITAL ADVISERS, LLC',
    label: 'CHECCHI CAPITAL ADVISERS, LLC',
  },
  {
    cik: '0001032814',
    value: '0001032814,CHECK CAPITAL MANAGEMENT INC/CA',
    name: 'CHECK CAPITAL MANAGEMENT INC/CA',
    label: 'CHECK CAPITAL MANAGEMENT INC/CA',
  },
  {
    cik: '0001276755',
    value: '0001276755,CHELSEA COUNSEL CO',
    name: 'CHELSEA COUNSEL CO',
    label: 'CHELSEA COUNSEL CO',
  },
  {
    cik: '0000919087',
    value: '0000919087,CHEMICAL BANK',
    name: 'CHEMICAL BANK',
    label: 'CHEMICAL BANK',
  },
  {
    cik: '0001910183',
    value: '0001910183,CHEMISTRY WEALTH MANAGEMENT LLC',
    name: 'CHEMISTRY WEALTH MANAGEMENT LLC',
    label: 'CHEMISTRY WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0000790354',
    value: '0000790354,CHEMUNG CANAL TRUST CO',
    name: 'CHEMUNG CANAL TRUST CO',
    label: 'CHEMUNG CANAL TRUST CO',
  },
  {
    cik: '0001877829',
    value: '0001877829,CHERRY CREEK INVESTMENT ADVISORS, INC.',
    name: 'CHERRY CREEK INVESTMENT ADVISORS, INC.',
    label: 'CHERRY CREEK INVESTMENT ADVISORS, INC.',
  },
  {
    cik: '0001907327',
    value: '0001907327,CHERRYDALE WEALTH MANAGEMENT, LLC',
    name: 'CHERRYDALE WEALTH MANAGEMENT, LLC',
    label: 'CHERRYDALE WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001079397',
    value: '0001079397,CHESAPEAKE ASSET MANAGEMENT LLC',
    name: 'CHESAPEAKE ASSET MANAGEMENT LLC',
    label: 'CHESAPEAKE ASSET MANAGEMENT LLC',
  },
  {
    cik: '0000919165',
    value: '0000919165,CHESAPEAKE PARTNERS MANAGEMENT CO INC/MD',
    name: 'CHESAPEAKE PARTNERS MANAGEMENT CO INC/MD',
    label: 'CHESAPEAKE PARTNERS MANAGEMENT CO INC/MD',
  },
  {
    cik: '0001730383',
    value: '0001730383,CHESAPEAKE WEALTH MANAGEMENT',
    name: 'CHESAPEAKE WEALTH MANAGEMENT',
    label: 'CHESAPEAKE WEALTH MANAGEMENT',
  },
  {
    cik: '0001697162',
    value: '0001697162,CHESCAPMANAGER LLC',
    name: 'CHESCAPMANAGER LLC',
    label: 'CHESCAPMANAGER LLC',
  },
  {
    cik: '0001164632',
    value: '0001164632,CHESLEY TAFT & ASSOCIATES LLC',
    name: 'CHESLEY TAFT & ASSOCIATES LLC',
    label: 'CHESLEY TAFT & ASSOCIATES LLC',
  },
  {
    cik: '0001591278',
    value: '0001591278,CHESSMAN WEALTH STRATEGIES RIA',
    name: 'CHESSMAN WEALTH STRATEGIES RIA',
    label: 'CHESSMAN WEALTH STRATEGIES RIA',
  },
  {
    cik: '0001427147',
    value: '0001427147,CHEVIOT VALUE MANAGEMENT, LLC',
    name: 'CHEVIOT VALUE MANAGEMENT, LLC',
    label: 'CHEVIOT VALUE MANAGEMENT, LLC',
  },
  {
    cik: '0001462020',
    value: '0001462020,CHEVY CHASE TRUST HOLDINGS, INC.',
    name: 'CHEVY CHASE TRUST HOLDINGS, INC.',
    label: 'CHEVY CHASE TRUST HOLDINGS, INC.',
  },
  {
    cik: '0001462020',
    value: '0001462020,CHEVY CHASE TRUST HOLDINGS, LLC',
    name: 'CHEVY CHASE TRUST HOLDINGS, LLC',
    label: 'CHEVY CHASE TRUST HOLDINGS, LLC',
  },
  {
    cik: '0001390457',
    value: '0001390457,CHEYNE CAPITAL MANAGEMENT (UK) LLP',
    name: 'CHEYNE CAPITAL MANAGEMENT (UK) LLP',
    label: 'CHEYNE CAPITAL MANAGEMENT (UK) LLP',
  },
  {
    cik: '0001791468',
    value: '0001791468,CHI ADVISORS LLC',
    name: 'CHI ADVISORS LLC',
    label: 'CHI ADVISORS LLC',
  },
  {
    cik: '0001740053',
    value: '0001740053,CHICAGO CAPITAL, LLC',
    name: 'CHICAGO CAPITAL, LLC',
    label: 'CHICAGO CAPITAL, LLC',
  },
  {
    cik: '0001580162',
    value: '0001580162,CHICAGO CAPITAL MANAGEMENT, LLC',
    name: 'CHICAGO CAPITAL MANAGEMENT, LLC',
    label: 'CHICAGO CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001116992',
    value: '0001116992,CHICAGO EQUITY PARTNERS LLC',
    name: 'CHICAGO EQUITY PARTNERS LLC',
    label: 'CHICAGO EQUITY PARTNERS LLC',
  },
  {
    cik: '0001563525',
    value: '0001563525,CHICAGO PARTNERS INVESTMENT GROUP LLC',
    name: 'CHICAGO PARTNERS INVESTMENT GROUP LLC',
    label: 'CHICAGO PARTNERS INVESTMENT GROUP LLC',
  },
  {
    cik: '0001601539',
    value: '0001601539,CHICAGO TRUST CO NA',
    name: 'CHICAGO TRUST CO NA',
    label: 'CHICAGO TRUST CO NA',
  },
  {
    cik: '0001592178',
    value: '0001592178,CHICAGO WEALTH MANAGEMENT, INC.',
    name: 'CHICAGO WEALTH MANAGEMENT, INC.',
    label: 'CHICAGO WEALTH MANAGEMENT, INC.',
  },
  {
    cik: '0001276460',
    value: '0001276460,CHICKASAW CAPITAL MANAGEMENT LLC',
    name: 'CHICKASAW CAPITAL MANAGEMENT LLC',
    label: 'CHICKASAW CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001926037',
    value: '0001926037,CHICO WEALTH RIA',
    name: 'CHICO WEALTH RIA',
    label: 'CHICO WEALTH RIA',
  },
  {
    cik: '0001491126',
    value: '0001491126,CHIEFTAIN CAPITAL MANAGEMENT, INC.',
    name: 'CHIEFTAIN CAPITAL MANAGEMENT, INC.',
    label: 'CHIEFTAIN CAPITAL MANAGEMENT, INC.',
  },
  {
    cik: '0001362598',
    value: '0001362598,CHILDRENS INVESTMENT FUND MANAGEMENT (UK) LLP',
    name: 'CHILDRENS INVESTMENT FUND MANAGEMENT (UK) LLP',
    label: 'CHILDRENS INVESTMENT FUND MANAGEMENT (UK) LLP',
  },
  {
    cik: '0001849561',
    value: '0001849561,CHILDRESS CAPITAL ADVISORS, LLC',
    name: 'CHILDRESS CAPITAL ADVISORS, LLC',
    label: 'CHILDRESS CAPITAL ADVISORS, LLC',
  },
  {
    cik: '0001056859',
    value: '0001056859,CHILTON CAPITAL MANAGEMENT LLC',
    name: 'CHILTON CAPITAL MANAGEMENT LLC',
    label: 'CHILTON CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001332632',
    value: '0001332632,CHILTON INVESTMENT CO INC.',
    name: 'CHILTON INVESTMENT CO INC.',
    label: 'CHILTON INVESTMENT CO INC.',
  },
  {
    cik: '0001332632',
    value: '0001332632,CHILTON INVESTMENT CO LLC',
    name: 'CHILTON INVESTMENT CO LLC',
    label: 'CHILTON INVESTMENT CO LLC',
  },
  {
    cik: '0001789215',
    value: '0001789215,CHIMERA CAPITAL MANAGEMENT LLC',
    name: 'CHIMERA CAPITAL MANAGEMENT LLC',
    label: 'CHIMERA CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001906594',
    value: '0001906594,CHINA UNIVERSAL ASSET MANAGEMENT CO., LTD.',
    name: 'CHINA UNIVERSAL ASSET MANAGEMENT CO., LTD.',
    label: 'CHINA UNIVERSAL ASSET MANAGEMENT CO., LTD.',
  },
  {
    cik: '0001381066',
    value: '0001381066,CHINOOK CAPITAL MANAGEMENT',
    name: 'CHINOOK CAPITAL MANAGEMENT',
    label: 'CHINOOK CAPITAL MANAGEMENT',
  },
  {
    cik: '0001830922',
    value: '0001830922,CHIRON CAPITAL MANAGEMENT, LLC',
    name: 'CHIRON CAPITAL MANAGEMENT, LLC',
    label: 'CHIRON CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001688882',
    value: '0001688882,CHIRON INVESTMENT MANAGEMENT, LLC',
    name: 'CHIRON INVESTMENT MANAGEMENT, LLC',
    label: 'CHIRON INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001399794',
    value: '0001399794,CHOATE INVESTMENT ADVISORS',
    name: 'CHOATE INVESTMENT ADVISORS',
    label: 'CHOATE INVESTMENT ADVISORS',
  },
  {
    cik: '0001842357',
    value: '0001842357,CHOICE WEALTH ADVISORS, LLC',
    name: 'CHOICE WEALTH ADVISORS, LLC',
    label: 'CHOICE WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001679031',
    value: '0001679031,CHOREO, LLC',
    name: 'CHOREO, LLC',
    label: 'CHOREO, LLC',
  },
  {
    cik: '0001389403',
    value: '0001389403,CHOU ASSOCIATES MANAGEMENT INC.',
    name: 'CHOU ASSOCIATES MANAGEMENT INC.',
    label: 'CHOU ASSOCIATES MANAGEMENT INC.',
  },
  {
    cik: '0001801792',
    value: '0001801792,CHRONOS WEALTH MANAGEMENT, LLC',
    name: 'CHRONOS WEALTH MANAGEMENT, LLC',
    label: 'CHRONOS WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0000020171',
    value: '000002017,CHUBB CORP',
    name: 'CHUBB CORP',
    label: 'CHUBB CORP',
  },
  {
    cik: '0000896159',
    value: '00008961,CHUBB LTD',
    name: 'CHUBB LTD',
    label: 'CHUBB LTD',
  },
  {
    cik: '0001094749',
    value: '0001094749,CHURCHILL MANAGEMENT CORP',
    name: 'CHURCHILL MANAGEMENT CORP',
    label: 'CHURCHILL MANAGEMENT CORP',
  },
  {
    cik: '0001021926',
    value: '0001021926,CIBC ASSET MANAGEMENT INC',
    name: 'CIBC ASSET MANAGEMENT INC',
    label: 'CIBC ASSET MANAGEMENT INC',
  },
  {
    cik: '0001279912',
    value: '0001279912,CIBC BANK USA',
    name: 'CIBC BANK USA',
    label: 'CIBC BANK USA',
  },
  {
    cik: '0001298088',
    value: '0001298088,CIBC PRIVATE WEALTH GROUP, LLC',
    name: 'CIBC PRIVATE WEALTH GROUP, LLC',
    label: 'CIBC PRIVATE WEALTH GROUP, LLC',
  },
  {
    cik: '0001421224',
    value: '0001421224,CIBC WORLD MARKET INC.',
    name: 'CIBC WORLD MARKET INC.',
    label: 'CIBC WORLD MARKET INC.',
  },
  {
    cik: '0001056527',
    value: '0001056527,CIBC WORLD MARKETS CORP',
    name: 'CIBC WORLD MARKETS CORP',
    label: 'CIBC WORLD MARKETS CORP',
  },
  {
    cik: '0001421224',
    value: '0001421224,CIBC WORLD MARKETS INC.',
    name: 'CIBC WORLD MARKETS INC.',
    label: 'CIBC WORLD MARKETS INC.',
  },
  {
    cik: '0001353311',
    value: '0001353311,CIBELLI CAPITAL MANAGEMENT LLC',
    name: 'CIBELLI CAPITAL MANAGEMENT LLC',
    label: 'CIBELLI CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001621915',
    value: '0001621915,CIDEL ASSET MANAGEMENT INC',
    name: 'CIDEL ASSET MANAGEMENT INC',
    label: 'CIDEL ASSET MANAGEMENT INC',
  },
  {
    cik: '0001672201',
    value: '0001672201,CIDER MILL INVESTMENTS LP',
    name: 'CIDER MILL INVESTMENTS LP',
    label: 'CIDER MILL INVESTMENTS LP',
  },
  {
    cik: '0001830820',
    value: '0001830820,CIF ASSET MANAGEMENT LTD',
    name: 'CIF ASSET MANAGEMENT LTD',
    label: 'CIF ASSET MANAGEMENT LTD',
  },
  {
    cik: '0001523847',
    value: '0001523847,CI GLOBAL INVESTMENTS INC.',
    name: 'CI GLOBAL INVESTMENTS INC.',
    label: 'CI GLOBAL INVESTMENTS INC.',
  },
  {
    cik: '0000049969',
    value: '0000049969,CIGNA INVESTMENTS INC /NEW',
    name: 'CIGNA INVESTMENTS INC /NEW',
    label: 'CIGNA INVESTMENTS INC /NEW',
  },
  {
    cik: '0001163648',
    value: '0001163648,CI INVESTMENTS INC.',
    name: 'CI INVESTMENTS INC.',
    label: 'CI INVESTMENTS INC.',
  },
  {
    cik: '0001179791',
    value: '0001179791,CIM INVESTMENT MANAGEMENT INC',
    name: 'CIM INVESTMENT MANAGEMENT INC',
    label: 'CIM INVESTMENT MANAGEMENT INC',
  },
  {
    cik: '0001364725',
    value: '0001364,CIM, LLC5',
    name: 'CIM, LLC',
    label: 'CIM, LLC',
  },
  {
    cik: '0001279888',
    value: '0001279888,CINCINNATI CASUALTY CO',
    name: 'CINCINNATI CASUALTY CO',
    label: 'CINCINNATI CASUALTY CO',
  },
  {
    cik: '0000020286',
    value: '0000020286,CINCINNATI FINANCIAL CORP',
    name: 'CINCINNATI FINANCIAL CORP',
    label: 'CINCINNATI FINANCIAL CORP',
  },
  {
    cik: '0001279886',
    value: '0001279886,CINCINNATI INDEMNITY CO',
    name: 'CINCINNATI INDEMNITY CO',
    label: 'CINCINNATI INDEMNITY CO',
  },
  {
    cik: '0001279885',
    value: '0001279885,CINCINNATI INSURANCE CO',
    name: 'CINCINNATI INSURANCE CO',
    label: 'CINCINNATI INSURANCE CO',
  },
  {
    cik: '0001426763',
    value: '0001426763,CINCINNATI SPECIALTY UNDERWRITERS INSURANCE CO',
    name: 'CINCINNATI SPECIALTY UNDERWRITERS INSURANCE CO',
    label: 'CINCINNATI SPECIALTY UNDERWRITERS INSURANCE CO',
  },
  {
    cik: '0001787258',
    value: '0001787258,CINCTIVE CAPITAL MANAGEMENT LP',
    name: 'CINCTIVE CAPITAL MANAGEMENT LP',
    label: 'CINCTIVE CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001568894',
    value: '0001568894,CINQUE PARTNERS LLC',
    name: 'CINQUE PARTNERS LLC',
    label: 'CINQUE PARTNERS LLC',
  },
  {
    cik: '0001653012',
    value: '0001653012,CIOVACCO CAPITAL MANAGEMENT LLC',
    name: 'CIOVACCO CAPITAL MANAGEMENT LLC',
    label: 'CIOVACCO CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001542420',
    value: '0001542420,CIPHER CAPITAL LP',
    name: 'CIPHER CAPITAL LP',
    label: 'CIPHER CAPITAL LP',
  },
  {
    cik: '0001681453',
    value: '0001681453,CIRCLE ROAD ADVISORS LP',
    name: 'CIRCLE ROAD ADVISORS LP',
    label: 'CIRCLE ROAD ADVISORS LP',
  },
  {
    cik: '0001578242',
    value: '0001578242,CIRCLE WEALTH MANAGEMENT, LLC',
    name: 'CIRCLE WEALTH MANAGEMENT, LLC',
    label: 'CIRCLE WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0000858877',
    value: '0000858877,CISCO SYSTEMS, INC.',
    name: 'CISCO SYSTEMS, INC.',
    label: 'CISCO SYSTEMS, INC.',
  },
  {
    cik: '0001423053',
    value: '0001423053,CITADEL ADVISORS LLC',
    name: 'CITADEL ADVISORS LLC',
    label: 'CITADEL ADVISORS LLC',
  },
  {
    cik: '0001811907',
    value: '0001811907,CITADEL INVESTMENT ADVISORY, INC.',
    name: 'CITADEL INVESTMENT ADVISORY, INC.',
    label: 'CITADEL INVESTMENT ADVISORY, INC.',
  },
  {
    cik: '0001478417',
    value: '0001478417,CIT BANK, NA WEALTH MANAGEMENT',
    name: 'CIT BANK, NA WEALTH MANAGEMENT',
    label: 'CIT BANK, NA WEALTH MANAGEMENT',
  },
  {
    cik: '0000831001',
    value: '0000831001,CITIGROUP INC',
    name: 'CITIGROUP INC',
    label: 'CITIGROUP INC',
  },
  {
    cik: '0001948632',
    value: '0001948632,CITIZENS BUSINESS BANK',
    name: 'CITIZENS BUSINESS BANK',
    label: 'CITIZENS BUSINESS BANK',
  },
  {
    cik: '0000759944',
    value: '0000759944,CITIZENS FINANCIAL GROUP INC/RI',
    name: 'CITIZENS FINANCIAL GROUP INC/RI',
    label: 'CITIZENS FINANCIAL GROUP INC/RI',
  },
  {
    cik: '0001806425',
    value: '0001806425,CITIZENS NATIONAL BANK TRUST DEPARTMENT',
    name: 'CITIZENS NATIONAL BANK TRUST DEPARTMENT',
    label: 'CITIZENS NATIONAL BANK TRUST DEPARTMENT',
  },
  {
    cik: '0000810958',
    value: '0000810958,CITIZENS & NORTHERN CORP',
    name: 'CITIZENS & NORTHERN CORP',
    label: 'CITIZENS & NORTHERN CORP',
  },
  {
    cik: '0001722439',
    value: '0001722439,CITO CAPITAL GROUP, LLC',
    name: 'CITO CAPITAL GROUP, LLC',
    label: 'CITO CAPITAL GROUP, LLC',
  },
  {
    cik: '0001671161',
    value: '0001671161,CITY FINANCIAL INVESTMENT CO LTD',
    name: 'CITY FINANCIAL INVESTMENT CO LTD',
    label: 'CITY FINANCIAL INVESTMENT CO LTD',
  },
  {
    cik: '0000726854',
    value: '0000726854,CITY HOLDING CO',
    name: 'CITY HOLDING CO',
    label: 'CITY HOLDING CO',
  },
  {
    cik: '0000275216',
    value: '0000275216,CITY NATIONAL BANK',
    name: 'CITY NATIONAL BANK',
    label: 'CITY NATIONAL BANK',
  },
  {
    cik: '0001034546',
    value: '0001034546,CITY OF LONDON INVESTMENT MANAGEMENT CO LTD',
    name: 'CITY OF LONDON INVESTMENT MANAGEMENT CO LTD',
    label: 'CITY OF LONDON INVESTMENT MANAGEMENT CO LTD',
  },
  {
    cik: '0001910666',
    value: '0001910666,CITY STATE BANK',
    name: 'CITY STATE BANK',
    label: 'CITY STATE BANK',
  },
  {
    cik: '0001773030',
    value: '0001773030,C-J ADVISORY, INC',
    name: 'C-J ADVISORY, INC',
    label: 'C-J ADVISORY, INC',
  },
  {
    cik: '0001600403',
    value: '0001600403,CKW FINANCIAL GROUP',
    name: 'CKW FINANCIAL GROUP',
    label: 'CKW FINANCIAL GROUP',
  },
  {
    cik: '0001593410',
    value: '0001593410,CLAAR ADVISORS LLC',
    name: 'CLAAR ADVISORS LLC',
    label: 'CLAAR ADVISORS LLC',
  },
  {
    cik: '0001911520',
    value: '0001911520,CLADIS INVESTMENT ADVISORY, LLC',
    name: 'CLADIS INVESTMENT ADVISORY, LLC',
    label: 'CLADIS INVESTMENT ADVISORY, LLC',
  },
  {
    cik: '0001376192',
    value: '0001376192,CLAL INSURANCE ENTERPRISES HOLDINGS LTD',
    name: 'CLAL INSURANCE ENTERPRISES HOLDINGS LTD',
    label: 'CLAL INSURANCE ENTERPRISES HOLDINGS LTD',
  },
  {
    cik: '0001724134',
    value: '0001724134,CLARAPHI ADVISORY NETWORK, LLC',
    name: 'CLARAPHI ADVISORY NETWORK, LLC',
    label: 'CLARAPHI ADVISORY NETWORK, LLC',
  },
  {
    cik: '0001907541',
    value: '0001907541,CLAREMONT FINANCIAL GROUP, INC.',
    name: 'CLAREMONT FINANCIAL GROUP, INC.',
    label: 'CLAREMONT FINANCIAL GROUP, INC.',
  },
  {
    cik: '0001568717',
    value: '0001568717,CLAREN ROAD ASSET MANAGEMENT, LLC',
    name: 'CLAREN ROAD ASSET MANAGEMENT, LLC',
    label: 'CLAREN ROAD ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001735513',
    value: '0001735513,CLARET ASSET MANAGEMENT CORP',
    name: 'CLARET ASSET MANAGEMENT CORP',
    label: 'CLARET ASSET MANAGEMENT CORP',
  },
  {
    cik: '0001766347',
    value: '0001766347,CLARFELD FINANCIAL ADVISORS, LLC',
    name: 'CLARFELD FINANCIAL ADVISORS, LLC',
    label: 'CLARFELD FINANCIAL ADVISORS, LLC',
  },
  {
    cik: '0001905092',
    value: '0001905092,CLARIS ADVISORS, LLC / MO /',
    name: 'CLARIS ADVISORS, LLC / MO /',
    label: 'CLARIS ADVISORS, LLC / MO /',
  },
  {
    cik: '0001706746',
    value: '0001706746,CLARITY CAPITAL KCPS LTD.',
    name: 'CLARITY CAPITAL KCPS LTD.',
    label: 'CLARITY CAPITAL KCPS LTD.',
  },
  {
    cik: '0001862145',
    value: '0001862145,CLARITY FINANCIAL LLC',
    name: 'CLARITY FINANCIAL LLC',
    label: 'CLARITY FINANCIAL LLC',
  },
  {
    cik: '0001767384',
    value: '0001767384,CLARITY WEALTH ADVISORS, LLC',
    name: 'CLARITY WEALTH ADVISORS, LLC',
    label: 'CLARITY WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001664214',
    value: '0001664214,CLARIUS GROUP, LLC',
    name: 'CLARIUS GROUP, LLC',
    label: 'CLARIUS GROUP, LLC',
  },
  {
    cik: '0001388167',
    value: '0001388167,CLARIVEST ASSET MANAGEMENT LLC',
    name: 'CLARIVEST ASSET MANAGEMENT LLC',
    label: 'CLARIVEST ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001352187',
    value: '0001352187,CLARK CAPITAL MANAGEMENT GROUP, INC.',
    name: 'CLARK CAPITAL MANAGEMENT GROUP, INC.',
    label: 'CLARK CAPITAL MANAGEMENT GROUP, INC.',
  },
  {
    cik: '0001056466',
    value: '0001056466,CLARK ESTATES INC/NY',
    name: 'CLARK ESTATES INC/NY',
    label: 'CLARK ESTATES INC/NY',
  },
  {
    cik: '0001001011',
    value:
      '0001001011,CLARK FINANCIAL SERVICES GROUP INC                      /BD',
    name: 'CLARK FINANCIAL SERVICES GROUP INC                      /BD',
    label: 'CLARK FINANCIAL SERVICES GROUP INC                      /BD',
  },
  {
    cik: '0001562855',
    value: '0001562855,CLARKSTON CAPITAL PARTNERS LLC',
    name: 'CLARKSTON CAPITAL PARTNERS LLC',
    label: 'CLARKSTON CAPITAL PARTNERS LLC',
  },
  {
    cik: '0001562855',
    value: '0001562855,CLARKSTON CAPITAL PARTNERS, LLC',
    name: 'CLARKSTON CAPITAL PARTNERS, LLC',
    label: 'CLARKSTON CAPITAL PARTNERS, LLC',
  },
  {
    cik: '0001764386',
    value: '0001764386,CLARO ADVISORS LLC',
    name: 'CLARO ADVISORS LLC',
    label: 'CLARO ADVISORS LLC',
  },
  {
    cik: '0001844568',
    value: '0001844568,CLARUS GROUP, INC.',
    name: 'CLARUS GROUP, INC.',
    label: 'CLARUS GROUP, INC.',
  },
  {
    cik: '0001431431',
    value: '0001431431,CLARUS VENTURES, LLC',
    name: 'CLARUS VENTURES, LLC',
    label: 'CLARUS VENTURES, LLC',
  },
  {
    cik: '0001730149',
    value: '0001730149,CLARUS WEALTH ADVISORS',
    name: 'CLARUS WEALTH ADVISORS',
    label: 'CLARUS WEALTH ADVISORS',
  },
  {
    cik: '0001832158',
    value: '0001832158,CLAUDIA M.P. BATLLE, CRP (R) LLC',
    name: 'CLAUDIA M.P. BATLLE, CRP (R) LLC',
    label: 'CLAUDIA M.P. BATLLE, CRP (R) LLC',
  },
  {
    cik: '0001729848',
    value: '0001729848,CLAYBROOK CAPITAL, LLC',
    name: 'CLAYBROOK CAPITAL, LLC',
    label: 'CLAYBROOK CAPITAL, LLC',
  },
  {
    cik: '0001915687',
    value: '0001915687,CLAY NORTHAM WEALTH MANAGEMENT, LLC',
    name: 'CLAY NORTHAM WEALTH MANAGEMENT, LLC',
    label: 'CLAY NORTHAM WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001538642',
    value: '0001538642,CLAYTON, DUBILIER & RICE, LLC',
    name: 'CLAYTON, DUBILIER & RICE, LLC',
    label: 'CLAYTON, DUBILIER & RICE, LLC',
  },
  {
    cik: '0001550509',
    value: '0001550509,CLAYTON PARTNERS LLC',
    name: 'CLAYTON PARTNERS LLC',
    label: 'CLAYTON PARTNERS LLC',
  },
  {
    cik: '0001911523',
    value: '0001911523,CLEAN ENERGY TRANSITION LLP',
    name: 'CLEAN ENERGY TRANSITION LLP',
    label: 'CLEAN ENERGY TRANSITION LLP',
  },
  {
    cik: '0001525865',
    value: '0001525865,CLEAN YIELD GROUP',
    name: 'CLEAN YIELD GROUP',
    label: 'CLEAN YIELD GROUP',
  },
  {
    cik: '0001167553',
    value: '0001167553,CLEARARC CAPITAL INC',
    name: 'CLEARARC CAPITAL INC',
    label: 'CLEARARC CAPITAL INC',
  },
  {
    cik: '0001348883',
    value: '0001348883,CLEARBRIDGE INVESTMENTS, LLC',
    name: 'CLEARBRIDGE INVESTMENTS, LLC',
    label: 'CLEARBRIDGE INVESTMENTS, LLC',
  },
  {
    cik: '0001499094',
    value: '0001499094,CLEARBRIDGE INVESTMENTS LTD',
    name: 'CLEARBRIDGE INVESTMENTS LTD',
    label: 'CLEARBRIDGE INVESTMENTS LTD',
  },
  {
    cik: '0000820330',
    value: '0000820330,CLEARBRIDGE, LLC',
    name: 'CLEARBRIDGE, LLC',
    label: 'CLEARBRIDGE, LLC',
  },
  {
    cik: '0001784235',
    value: '0001784235,CLEAR CREEK FINANCIAL MANAGEMENT, LLC',
    name: 'CLEAR CREEK FINANCIAL MANAGEMENT, LLC',
    label: 'CLEAR CREEK FINANCIAL MANAGEMENT, LLC',
  },
  {
    cik: '0001643569',
    value: '0001643569,CLEARFIELD CAPITAL MANAGEMENT LP',
    name: 'CLEARFIELD CAPITAL MANAGEMENT LP',
    label: 'CLEARFIELD CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001511857',
    value: '0001511857,CLEAR HARBOR ASSET MANAGEMENT, LLC',
    name: 'CLEAR HARBOR ASSET MANAGEMENT, LLC',
    label: 'CLEAR HARBOR ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001635523',
    value: '0001635523,CLEAR INVESTMENT RESEARCH, LLC',
    name: 'CLEAR INVESTMENT RESEARCH, LLC',
    label: 'CLEAR INVESTMENT RESEARCH, LLC',
  },
  {
    cik: '0001688282',
    value: '0001688282,CLEARLAKE CAPITAL GROUP, L.P.',
    name: 'CLEARLAKE CAPITAL GROUP, L.P.',
    label: 'CLEARLAKE CAPITAL GROUP, L.P.',
  },
  {
    cik: '0001600136',
    value: '0001600136,CLEARLINE CAPITAL LP',
    name: 'CLEARLINE CAPITAL LP',
    label: 'CLEARLINE CAPITAL LP',
  },
  {
    cik: '0001704677',
    value: '0001704677,CLEAR PERSPECTIVE ADVISORS, LLC',
    name: 'CLEAR PERSPECTIVE ADVISORS, LLC',
    label: 'CLEAR PERSPECTIVE ADVISORS, LLC',
  },
  {
    cik: '0001841174',
    value: '0001841174,CLEAR PERSPECTIVES FINANCIAL PLANNING, LLC',
    name: 'CLEAR PERSPECTIVES FINANCIAL PLANNING, LLC',
    label: 'CLEAR PERSPECTIVES FINANCIAL PLANNING, LLC',
  },
  {
    cik: '0001733456',
    value: '0001733456,CLEARSHARES LLC',
    name: 'CLEARSHARES LLC',
    label: 'CLEARSHARES LLC',
  },
  {
    cik: '0001887492',
    value: '0001887492,CLEAR SKY ADVISERS, LLC',
    name: 'CLEAR SKY ADVISERS, LLC',
    label: 'CLEAR SKY ADVISERS, LLC',
  },
  {
    cik: '0000842775',
    value: '0000842775,CLEARSTEAD ADVISORS, LLC',
    name: 'CLEARSTEAD ADVISORS, LLC',
    label: 'CLEARSTEAD ADVISORS, LLC',
  },
  {
    cik: '0001708001',
    value: '0001708001,CLEARSTEAD TRUST, LLC',
    name: 'CLEARSTEAD TRUST, LLC',
    label: 'CLEARSTEAD TRUST, LLC',
  },
  {
    cik: '0001708828',
    value: '0001708828,CLEAR STREET LLC',
    name: 'CLEAR STREET LLC',
    label: 'CLEAR STREET LLC',
  },
  {
    cik: '0001531593',
    value: '0001531593,CLEAR STREET MARKETS LLC',
    name: 'CLEAR STREET MARKETS LLC',
    label: 'CLEAR STREET MARKETS LLC',
  },
  {
    cik: '0001840306',
    value: '0001840306,CLEARVIEW WEALTH ADVISORS LLC',
    name: 'CLEARVIEW WEALTH ADVISORS LLC',
    label: 'CLEARVIEW WEALTH ADVISORS LLC',
  },
  {
    cik: '0001690531',
    value: '0001690531,CLEARWATER CAPITAL ADVISORS, LLC',
    name: 'CLEARWATER CAPITAL ADVISORS, LLC',
    label: 'CLEARWATER CAPITAL ADVISORS, LLC',
  },
  {
    cik: '0001649776',
    value: '0001649776,CLEARY GULL HOLDINGS INC.',
    name: 'CLEARY GULL HOLDINGS INC.',
    label: 'CLEARY GULL HOLDINGS INC.',
  },
  {
    cik: '0001062381',
    value: '0001062381,CLEARY GULL INC.',
    name: 'CLEARY GULL INC.',
    label: 'CLEARY GULL INC.',
  },
  {
    cik: '0001802816',
    value: '0001802816,CLIENT 1ST ADVISORY GROUP, LLC',
    name: 'CLIENT 1ST ADVISORY GROUP, LLC',
    label: 'CLIENT 1ST ADVISORY GROUP, LLC',
  },
  {
    cik: '0001822065',
    value: '0001822065,CLIENT FIRST CAPITAL LLC',
    name: 'CLIENT FIRST CAPITAL LLC',
    label: 'CLIENT FIRST CAPITAL LLC',
  },
  {
    cik: '0001915494',
    value: '0001915494,CLIENTFIRST WEALTH MANAGEMENT, LLC',
    name: 'CLIENTFIRST WEALTH MANAGEMENT, LLC',
    label: 'CLIENTFIRST WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001730467',
    value: '0001730467,CLIFFORD CAPITAL PARTNERS LLC',
    name: 'CLIFFORD CAPITAL PARTNERS LLC',
    label: 'CLIFFORD CAPITAL PARTNERS LLC',
  },
  {
    cik: '0001034642',
    value: '0001034642,CLIFFORD SWAN INVESTMENT COUNSEL LLC',
    name: 'CLIFFORD SWAN INVESTMENT COUNSEL LLC',
    label: 'CLIFFORD SWAN INVESTMENT COUNSEL LLC',
  },
  {
    cik: '0001659851',
    value: '0001659851,CLIFFWATER LLC',
    name: 'CLIFFWATER LLC',
    label: 'CLIFFWATER LLC',
  },
  {
    cik: '0000944361',
    value: '0000944361,CLIFTONLARSONALLEN WEALTH ADVISORS, LLC',
    name: 'CLIFTONLARSONALLEN WEALTH ADVISORS, LLC',
    label: 'CLIFTONLARSONALLEN WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001540796',
    value: '0001540796,CLIFTON PARK CAPITAL MANAGEMENT, LLC',
    name: 'CLIFTON PARK CAPITAL MANAGEMENT, LLC',
    label: 'CLIFTON PARK CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001134119',
    value: '0001134119,CLINTON GROUP INC',
    name: 'CLINTON GROUP INC',
    label: 'CLINTON GROUP INC',
  },
  {
    cik: '0001718570',
    value: '0001718570,CLOSE ASSET MANAGEMENT LTD',
    name: 'CLOSE ASSET MANAGEMENT LTD',
    label: 'CLOSE ASSET MANAGEMENT LTD',
  },
  {
    cik: '0001745907',
    value: '0001745907,CLOUDALPHA CAPITAL MANAGEMENT LIMITED/HONG KONG',
    name: 'CLOUDALPHA CAPITAL MANAGEMENT LIMITED/HONG KONG',
    label: 'CLOUDALPHA CAPITAL MANAGEMENT LIMITED/HONG KONG',
  },
  {
    cik: '0001628121',
    value: '0001628121,CLOUD GATE CAPITAL, LLC',
    name: 'CLOUD GATE CAPITAL, LLC',
    label: 'CLOUD GATE CAPITAL, LLC',
  },
  {
    cik: '0001276144',
    value: '0001276144,CLOUGH CAPITAL PARTNERS L P',
    name: 'CLOUGH CAPITAL PARTNERS L P',
    label: 'CLOUGH CAPITAL PARTNERS L P',
  },
  {
    cik: '0001635925',
    value: '0001635925,CLOVERDALE CAPITAL MANAGEMENT, LLC',
    name: 'CLOVERDALE CAPITAL MANAGEMENT, LLC',
    label: 'CLOVERDALE CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001819581',
    value: '0001819581,CLOVERFIELDS CAPITAL GROUP, LP',
    name: 'CLOVERFIELDS CAPITAL GROUP, LP',
    label: 'CLOVERFIELDS CAPITAL GROUP, LP',
  },
  {
    cik: '0001309342',
    value: '0001309342,CLOVER PARTNERS, L.P.',
    name: 'CLOVER PARTNERS, L.P.',
    label: 'CLOVER PARTNERS, L.P.',
  },
  {
    cik: '0001275729',
    value: '0001275729,CLOVIS CAPITAL MANAGEMENT LP',
    name: 'CLOVIS CAPITAL MANAGEMENT LP',
    label: 'CLOVIS CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001360533',
    value: '0001360533,CLS INVESTMENTS, LLC',
    name: 'CLS INVESTMENTS, LLC',
    label: 'CLS INVESTMENTS, LLC',
  },
  {
    cik: '0001091860',
    value: '0001091860,C M BIDWELL & ASSOCIATES LTD',
    name: 'C M BIDWELL & ASSOCIATES LTD',
    label: 'C M BIDWELL & ASSOCIATES LTD',
  },
  {
    cik: '0001770940',
    value: '0001770940,CMC FINANCIAL GROUP',
    name: 'CMC FINANCIAL GROUP',
    label: 'CMC FINANCIAL GROUP',
  },
  {
    cik: '0001839445',
    value: '0001839445,CMG GLOBAL HOLDINGS, LLC',
    name: 'CMG GLOBAL HOLDINGS, LLC',
    label: 'CMG GLOBAL HOLDINGS, LLC',
  },
  {
    cik: '0001657516',
    value: '0001657516,CMH WEALTH MANAGEMENT LLC',
    name: 'CMH WEALTH MANAGEMENT LLC',
    label: 'CMH WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0001841857',
    value: '0001841857,CM MANAGEMENT, LLC',
    name: 'CM MANAGEMENT, LLC',
    label: 'CM MANAGEMENT, LLC',
  },
  {
    cik: '0001533626',
    value: '0001533626,CMT ASSET MANAGEMENT LTD',
    name: 'CMT ASSET MANAGEMENT LTD',
    label: 'CMT ASSET MANAGEMENT LTD',
  },
  {
    cik: '0001632813',
    value: '0001632813,CMT CAPITAL MARKETS TRADING GMBH',
    name: 'CMT CAPITAL MARKETS TRADING GMBH',
    label: 'CMT CAPITAL MARKETS TRADING GMBH',
  },
  {
    cik: '0001785546',
    value: '0001785546,CMT INVESTMENTS LLC',
    name: 'CMT INVESTMENTS LLC',
    label: 'CMT INVESTMENTS LLC',
  },
  {
    cik: '0001588115',
    value: '0001588115,CMT TRADING LLC',
    name: 'CMT TRADING LLC',
    label: 'CMT TRADING LLC',
  },
  {
    cik: '0000021175',
    value: '0000021175,CNA FINANCIAL CORP',
    name: 'CNA FINANCIAL CORP',
    label: 'CNA FINANCIAL CORP',
  },
  {
    cik: '0001732074',
    value: '0001732,CNB BANK4',
    name: 'CNB BANK',
    label: 'CNB BANK',
  },
  {
    cik: '0001167456',
    value: '0001167456,CNH PARTNERS LLC',
    name: 'CNH PARTNERS LLC',
    label: 'CNH PARTNERS LLC',
  },
  {
    cik: '0001224608',
    value: '0001224608,CNO FINANCIAL GROUP, INC.',
    name: 'CNO FINANCIAL GROUP, INC.',
    label: 'CNO FINANCIAL GROUP, INC.',
  },
  {
    cik: '0001824658',
    value: '0001824658,COANN CAPITAL LLC',
    name: 'COANN CAPITAL LLC',
    label: 'COANN CAPITAL LLC',
  },
  {
    cik: '0001665976',
    value: '0001665976,COASTAL BRIDGE ADVISORS, LLC',
    name: 'COASTAL BRIDGE ADVISORS, LLC',
    label: 'COASTAL BRIDGE ADVISORS, LLC',
  },
  {
    cik: '0001765054',
    value: '0001765054,COASTAL CAPITAL GROUP, INC.',
    name: 'COASTAL CAPITAL GROUP, INC.',
    label: 'COASTAL CAPITAL GROUP, INC.',
  },
  {
    cik: '0001776792',
    value: '0001776792,COASTAL INVESTMENT ADVISORS, INC.',
    name: 'COASTAL INVESTMENT ADVISORS, INC.',
    label: 'COASTAL INVESTMENT ADVISORS, INC.',
  },
  {
    cik: '0001594870',
    value: '0001594870,COASTAL INVESTMENT MANAGEMENT, L.P.',
    name: 'COASTAL INVESTMENT MANAGEMENT, L.P.',
    label: 'COASTAL INVESTMENT MANAGEMENT, L.P.',
  },
  {
    cik: '0001508612',
    value: '0001508612,COASTLAND CAPITAL LLC',
    name: 'COASTLAND CAPITAL LLC',
    label: 'COASTLAND CAPITAL LLC',
  },
  {
    cik: '0001324279',
    value: '0001324279,COASTLINE TRUST CO',
    name: 'COASTLINE TRUST CO',
    label: 'COASTLINE TRUST CO',
  },
  {
    cik: '0001934500',
    value: '0001934500,COASTWISE CAPITAL GROUP, LLC',
    name: 'COASTWISE CAPITAL GROUP, LLC',
    label: 'COASTWISE CAPITAL GROUP, LLC',
  },
  {
    cik: '0001135730',
    value: '0001135730,COATUE MANAGEMENT LLC',
    name: 'COATUE MANAGEMENT LLC',
    label: 'COATUE MANAGEMENT LLC',
  },
  {
    cik: '0001086762',
    value: '0001086762,COBALT CAPITAL MANAGEMENT INC',
    name: 'COBALT CAPITAL MANAGEMENT INC',
    label: 'COBALT CAPITAL MANAGEMENT INC',
  },
  {
    cik: '0001086762',
    value: '0001086762,COBALT CAPITAL MANAGEMENT, INC.',
    name: 'COBALT CAPITAL MANAGEMENT, INC.',
    label: 'COBALT CAPITAL MANAGEMENT, INC.',
  },
  {
    cik: '0001463306',
    value: '0001463306,COBBLE HILL FINANCIAL SERVICES, INC',
    name: 'COBBLE HILL FINANCIAL SERVICES, INC',
    label: 'COBBLE HILL FINANCIAL SERVICES, INC',
  },
  {
    cik: '0001033505',
    value: '0001033505,COBBLESTONE CAPITAL ADVISORS LLC /NY/',
    name: 'COBBLESTONE CAPITAL ADVISORS LLC /NY/',
    label: 'COBBLESTONE CAPITAL ADVISORS LLC /NY/',
  },
  {
    cik: '0001324659',
    value: '0001324659,COBIZ INVESTMENT MANAGEMENT, LLC',
    name: 'COBIZ INVESTMENT MANAGEMENT, LLC',
    label: 'COBIZ INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001656150',
    value: '0001656150,COCO ENTERPRISES, LLC',
    name: 'COCO ENTERPRISES, LLC',
    label: 'COCO ENTERPRISES, LLC',
  },
  {
    cik: '0001662212',
    value: '0001662212,COCONUT GROVE BANK',
    name: 'COCONUT GROVE BANK',
    label: 'COCONUT GROVE BANK',
  },
  {
    cik: '0001832187',
    value: '0001832187,CODEX CAPITAL, L.L.C.',
    name: 'CODEX CAPITAL, L.L.C.',
    label: 'CODEX CAPITAL, L.L.C.',
  },
  {
    cik: '0001505896',
    value: '0001505896,COE CAPITAL MANAGEMENT, LLC',
    name: 'COE CAPITAL MANAGEMENT, LLC',
    label: 'COE CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001860790',
    value: '0001860790,COERENTE CAPITAL MANAGEMENT',
    name: 'COERENTE CAPITAL MANAGEMENT',
    label: 'COERENTE CAPITAL MANAGEMENT',
  },
  {
    cik: '0001162675',
    value: '0001162675,COGHILL CAPITAL MANAGEMENT LLC',
    name: 'COGHILL CAPITAL MANAGEMENT LLC',
    label: 'COGHILL CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001538882',
    value: '0001538882,COGNIOS BETA NEUTRAL LARGE CAP FUND, LP',
    name: 'COGNIOS BETA NEUTRAL LARGE CAP FUND, LP',
    label: 'COGNIOS BETA NEUTRAL LARGE CAP FUND, LP',
  },
  {
    cik: '0001548468',
    value: '0001548468,COGNIOS CAPITAL, LLC',
    name: 'COGNIOS CAPITAL, LLC',
    label: 'COGNIOS CAPITAL, LLC',
  },
  {
    cik: '0001517133',
    value: '0001517133,COHANZICK MANAGEMENT, LLC',
    name: 'COHANZICK MANAGEMENT, LLC',
    label: 'COHANZICK MANAGEMENT, LLC',
  },
  {
    cik: '0001508120',
    value: '0001508120,COHEN CAPITAL MANAGEMENT, INC.',
    name: 'COHEN CAPITAL MANAGEMENT, INC.',
    label: 'COHEN CAPITAL MANAGEMENT, INC.',
  },
  {
    cik: '0001778536',
    value: '0001778536,COHEN & CO FINANCIAL MANAGEMENT, LLC',
    name: 'COHEN & CO FINANCIAL MANAGEMENT, LLC',
    label: 'COHEN & CO FINANCIAL MANAGEMENT, LLC',
  },
  {
    cik: '0001845081',
    value: '0001845081,COHEN INVESTMENT ADVISORS LLC',
    name: 'COHEN INVESTMENT ADVISORS LLC',
    label: 'COHEN INVESTMENT ADVISORS LLC',
  },
  {
    cik: '0001453620',
    value: '0001453620,COHEN KLINGENSTEIN LLC',
    name: 'COHEN KLINGENSTEIN LLC',
    label: 'COHEN KLINGENSTEIN LLC',
  },
  {
    cik: '0001324022',
    value: '0001324022,COHEN LAWRENCE B',
    name: 'COHEN LAWRENCE B',
    label: 'COHEN LAWRENCE B',
  },
  {
    cik: '0000845563',
    value: '0000845563,COHEN & STEERS CAPITAL MANAGEMENT INC',
    name: 'COHEN & STEERS CAPITAL MANAGEMENT INC',
    label: 'COHEN & STEERS CAPITAL MANAGEMENT INC',
  },
  {
    cik: '0001284812',
    value: '0001284812,COHEN & STEERS INC',
    name: 'COHEN & STEERS INC',
    label: 'COHEN & STEERS INC',
  },
  {
    cik: '0001284812',
    value: '0001284812,COHEN & STEERS, INC.',
    name: 'COHEN & STEERS, INC.',
    label: 'COHEN & STEERS, INC.',
  },
  {
    cik: '0001350585',
    value: '0001350585,COHO PARTNERS, LTD.',
    name: 'COHO PARTNERS, LTD.',
    label: 'COHO PARTNERS, LTD.',
  },
  {
    cik: '0001082339',
    value: '0001082339,COLDSTREAM CAPITAL MANAGEMENT INC',
    name: 'COLDSTREAM CAPITAL MANAGEMENT INC',
    label: 'COLDSTREAM CAPITAL MANAGEMENT INC',
  },
  {
    cik: '0001409751',
    value: '0001409751,COLISEUM CAPITAL MANAGEMENT, LLC',
    name: 'COLISEUM CAPITAL MANAGEMENT, LLC',
    label: 'COLISEUM CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001843115',
    value: '0001843115,COLLABORATIVE HOLDINGS MANAGEMENT LP',
    name: 'COLLABORATIVE HOLDINGS MANAGEMENT LP',
    label: 'COLLABORATIVE HOLDINGS MANAGEMENT LP',
  },
  {
    cik: '0001896419',
    value: '0001896419,COLLABORATIVE WEALTH MANAGMENT INC.',
    name: 'COLLABORATIVE WEALTH MANAGMENT INC.',
    label: 'COLLABORATIVE WEALTH MANAGMENT INC.',
  },
  {
    cik: '0001845066',
    value: '0001845066,COLLECTIVE FAMILY OFFICE LLC',
    name: 'COLLECTIVE FAMILY OFFICE LLC',
    label: 'COLLECTIVE FAMILY OFFICE LLC',
  },
  {
    cik: '0001567993',
    value: '0001567993,COLONIAL ASSET MANAGEMENT, INC.',
    name: 'COLONIAL ASSET MANAGEMENT, INC.',
    label: 'COLONIAL ASSET MANAGEMENT, INC.',
  },
  {
    cik: '0001845859',
    value: '0001845859,COLONIAL RIVER WEALTH MANAGEMENT, LLC',
    name: 'COLONIAL RIVER WEALTH MANAGEMENT, LLC',
    label: 'COLONIAL RIVER WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001567993',
    value: '0001567993,COLONIAL TRUST ADVISORS',
    name: 'COLONIAL TRUST ADVISORS',
    label: 'COLONIAL TRUST ADVISORS',
  },
  {
    cik: '0001954093',
    value: '0001954093,COLONIAL TRUST CO / SC',
    name: 'COLONIAL TRUST CO / SC',
    label: 'COLONIAL TRUST CO / SC',
  },
  {
    cik: '0001679688',
    value: '0001679688,COLONY CAPITAL, INC.',
    name: 'COLONY CAPITAL, INC.',
    label: 'COLONY CAPITAL, INC.',
  },
  {
    cik: '0001633573',
    value: '0001633573,COLONY FAMILY OFFICES, LLC',
    name: 'COLONY FAMILY OFFICES, LLC',
    label: 'COLONY FAMILY OFFICES, LLC',
  },
  {
    cik: '0001080628',
    value: '0001080628,COLONY GROUP LLC',
    name: 'COLONY GROUP LLC',
    label: 'COLONY GROUP LLC',
  },
  {
    cik: '0001664147',
    value: '0001664147,COLORADO CAPITAL MANAGEMENT, INC.',
    name: 'COLORADO CAPITAL MANAGEMENT, INC.',
    label: 'COLORADO CAPITAL MANAGEMENT, INC.',
  },
  {
    cik: '0001216093',
    value: '0001216093,COLRAIN CAPITAL LLC',
    name: 'COLRAIN CAPITAL LLC',
    label: 'COLRAIN CAPITAL LLC',
  },
  {
    cik: '0001780330',
    value: '0001780330,COLTON GROOME FINANCIAL ADVISORS, LLC',
    name: 'COLTON GROOME FINANCIAL ADVISORS, LLC',
    label: 'COLTON GROOME FINANCIAL ADVISORS, LLC',
  },
  {
    cik: '0001596586',
    value: '0001596586,COLTRANE ASSET MANAGEMENT L.P.',
    name: 'COLTRANE ASSET MANAGEMENT L.P.',
    label: 'COLTRANE ASSET MANAGEMENT L.P.',
  },
  {
    cik: '0001808179',
    value: '0001808179,COLUMBIA ADVISORY PARTNERS LLC',
    name: 'COLUMBIA ADVISORY PARTNERS LLC',
    label: 'COLUMBIA ADVISORY PARTNERS LLC',
  },
  {
    cik: '0001585047',
    value: '0001585047,COLUMBIA ASSET MANAGEMENT',
    name: 'COLUMBIA ASSET MANAGEMENT',
    label: 'COLUMBIA ASSET MANAGEMENT',
  },
  {
    cik: '0001545625',
    value: '0001545625,COLUMBIA CAPITAL IV, LLC',
    name: 'COLUMBIA CAPITAL IV, LLC',
    label: 'COLUMBIA CAPITAL IV, LLC',
  },
  {
    cik: '0001571042',
    value: '0001571042,COLUMBIA CAPITAL LLC',
    name: 'COLUMBIA CAPITAL LLC',
    label: 'COLUMBIA CAPITAL LLC',
  },
  {
    cik: '0001569608',
    value: '0001569608,COLUMBIA PACIFIC ADVISORS, LLC',
    name: 'COLUMBIA PACIFIC ADVISORS, LLC',
    label: 'COLUMBIA PACIFIC ADVISORS, LLC',
  },
  {
    cik: '0001009215',
    value: '0001009215,COLUMBIA PARTNERS L L C INVESTMENT MANAGEMENT',
    name: 'COLUMBIA PARTNERS L L C INVESTMENT MANAGEMENT',
    label: 'COLUMBIA PARTNERS L L C INVESTMENT MANAGEMENT',
  },
  {
    cik: '0001802291',
    value: '0001802291,COLUMBIA TRUST CO 01012016',
    name: 'COLUMBIA TRUST CO 01012016',
    label: 'COLUMBIA TRUST CO 01012016',
  },
  {
    cik: '0000908733',
    value: '0000908733,COLUMBIA WANGER ASSET MANAGEMENT LLC',
    name: 'COLUMBIA WANGER ASSET MANAGEMENT LLC',
    label: 'COLUMBIA WANGER ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001228773',
    value: '0001228773,COLUMBUS CIRCLE INVESTORS',
    name: 'COLUMBUS CIRCLE INVESTORS',
    label: 'COLUMBUS CIRCLE INVESTORS',
  },
  {
    cik: '0001384982',
    value: '0001384982,COLUMBUS HILL CAPITAL MANAGEMENT, L.P.',
    name: 'COLUMBUS HILL CAPITAL MANAGEMENT, L.P.',
    label: 'COLUMBUS HILL CAPITAL MANAGEMENT, L.P.',
  },
  {
    cik: '0001727269',
    value: '0001727269,COLUMBUS MACRO, LLC',
    name: 'COLUMBUS MACRO, LLC',
    label: 'COLUMBUS MACRO, LLC',
  },
  {
    cik: '0001767392',
    value: '0001767392,COLUMBUS POINT LLP',
    name: 'COLUMBUS POINT LLP',
    label: 'COLUMBUS POINT LLP',
  },
  {
    cik: '0001552564',
    value: '0001552564,COLUMN CAPITAL ADVISORS, LLC',
    name: 'COLUMN CAPITAL ADVISORS, LLC',
    label: 'COLUMN CAPITAL ADVISORS, LLC',
  },
  {
    cik: '0001833486',
    value: '0001833486,COLUMN GROUP LLC',
    name: 'COLUMN GROUP LLC',
    label: 'COLUMN GROUP LLC',
  },
  {
    cik: '0001347769',
    value: '0001347769,COLUMN GROUP L P',
    name: 'COLUMN GROUP L P',
    label: 'COLUMN GROUP L P',
  },
  {
    cik: '0000901541',
    value: '0000901541,COMERICA BANK',
    name: 'COMERICA BANK',
    label: 'COMERICA BANK',
  },
  {
    cik: '0001399248',
    value: '0001399248,COMERICA SECURITIES INC',
    name: 'COMERICA SECURITIES INC',
    label: 'COMERICA SECURITIES INC',
  },
  {
    cik: '0000781875',
    value: '0000781875,COMERICA SECURITIES,INC.',
    name: 'COMERICA SECURITIES,INC.',
    label: 'COMERICA SECURITIES,INC.',
  },
  {
    cik: '0001574947',
    value: '0001574947,COMGEST GLOBAL INVESTORS S.A.S.',
    name: 'COMGEST GLOBAL INVESTORS S.A.S.',
    label: 'COMGEST GLOBAL INVESTORS S.A.S.',
  },
  {
    cik: '0001009076',
    value: '0001009076,COMMERCE BANK',
    name: 'COMMERCE BANK',
    label: 'COMMERCE BANK',
  },
  {
    cik: '0000852933',
    value: '0000852933,COMMERZBANK AKTIENGESELLSCHAFT /FI',
    name: 'COMMERZBANK AKTIENGESELLSCHAFT /FI',
    label: 'COMMERZBANK AKTIENGESELLSCHAFT /FI',
  },
  {
    cik: '0001831942',
    value: '0001831942,COMMODORE CAPITAL LP',
    name: 'COMMODORE CAPITAL LP',
    label: 'COMMODORE CAPITAL LP',
  },
  {
    cik: '0001162331',
    value: '0001162331,COMMONFUND ASSET MANAGEMENT CO INC',
    name: 'COMMONFUND ASSET MANAGEMENT CO INC',
    label: 'COMMONFUND ASSET MANAGEMENT CO INC',
  },
  {
    cik: '0001912835',
    value: '0001912835,COMMONS CAPITAL, LLC',
    name: 'COMMONS CAPITAL, LLC',
    label: 'COMMONS CAPITAL, LLC',
  },
  {
    cik: '0000008565',
    value: '0000008565,COMMONWEALTH BANK OF AUSTRALIA',
    name: 'COMMONWEALTH BANK OF AUSTRALIA',
    label: 'COMMONWEALTH BANK OF AUSTRALIA',
  },
  {
    cik: '0001333978',
    value: '0001333978,COMMONWEALTH EQUITY SERVICES, INC',
    name: 'COMMONWEALTH EQUITY SERVICES, INC',
    label: 'COMMONWEALTH EQUITY SERVICES, INC',
  },
  {
    cik: '0000312272',
    value: '0000312272,COMMONWEALTH EQUITY SERVICES, LLC',
    name: 'COMMONWEALTH EQUITY SERVICES, LLC',
    label: 'COMMONWEALTH EQUITY SERVICES, LLC',
  },
  {
    cik: '0001536755',
    value: '0001536755,COMMONWEALTH FINANCIAL SERVICES, LLC',
    name: 'COMMONWEALTH FINANCIAL SERVICES, LLC',
    label: 'COMMONWEALTH FINANCIAL SERVICES, LLC',
  },
  {
    cik: '0001083190',
    value:
      '0001083190,COMMONWEALTH OF PENNSYLVANIA PUBLIC SCHOOL EMPLS RETRMT SYS',
    name: 'COMMONWEALTH OF PENNSYLVANIA PUBLIC SCHOOL EMPLS RETRMT SYS',
    label: 'COMMONWEALTH OF PENNSYLVANIA PUBLIC SCHOOL EMPLS RETRMT SYS',
  },
  {
    cik: '0001546925',
    value: '0001546925,COMMONWEALTH OPPORTUNITY CAPITAL GP LLC',
    name: 'COMMONWEALTH OPPORTUNITY CAPITAL GP LLC',
    label: 'COMMONWEALTH OPPORTUNITY CAPITAL GP LLC',
  },
  {
    cik: '0001690558',
    value: '0001690558,COMMONWEALTH VENTURE (GP) PARTNERS IV LLC',
    name: 'COMMONWEALTH VENTURE (GP) PARTNERS IV LLC',
    label: 'COMMONWEALTH VENTURE (GP) PARTNERS IV LLC',
  },
  {
    cik: '0001632187',
    value: '0001632187,COMMUNITY BANK, N.A.',
    name: 'COMMUNITY BANK, N.A.',
    label: 'COMMUNITY BANK, N.A.',
  },
  {
    cik: '0001599923',
    value: '0001599923,COMMUNITY BANK OF RAYMORE',
    name: 'COMMUNITY BANK OF RAYMORE',
    label: 'COMMUNITY BANK OF RAYMORE',
  },
  {
    cik: '0000723188',
    value: '0000723188,COMMUNITY BANK SYSTEM, INC.',
    name: 'COMMUNITY BANK SYSTEM, INC.',
    label: 'COMMUNITY BANK SYSTEM, INC.',
  },
  {
    cik: '0001654847',
    value: '0001654847,COMMUNITY BANK & TRUST, WACO, TEXAS',
    name: 'COMMUNITY BANK & TRUST, WACO, TEXAS',
    label: 'COMMUNITY BANK & TRUST, WACO, TEXAS',
  },
  {
    cik: '0001738887',
    value: '0001738887,COMMUNITY CAPITAL MANAGEMENT, INC.',
    name: 'COMMUNITY CAPITAL MANAGEMENT, INC.',
    label: 'COMMUNITY CAPITAL MANAGEMENT, INC.',
  },
  {
    cik: '0001738887',
    value: '0001738887,COMMUNITY CAPITAL MANAGEMENT, LLC',
    name: 'COMMUNITY CAPITAL MANAGEMENT, LLC',
    label: 'COMMUNITY CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001537319',
    value: '0001537319,COMMUNITY FINANCIAL SERVICES GROUP, LLC',
    name: 'COMMUNITY FINANCIAL SERVICES GROUP, LLC',
    label: 'COMMUNITY FINANCIAL SERVICES GROUP, LLC',
  },
  {
    cik: '0001047142',
    value: '0001047142,COMMUNITY TRUST & INVESTMENT CO',
    name: 'COMMUNITY TRUST & INVESTMENT CO',
    label: 'COMMUNITY TRUST & INVESTMENT CO',
  },
  {
    cik: '0001615423',
    value: '0001615423,COMPAGNIE LOMBARD ODIER SCMA',
    name: 'COMPAGNIE LOMBARD ODIER SCMA',
    label: 'COMPAGNIE LOMBARD ODIER SCMA',
  },
  {
    cik: '0001720292',
    value: '0001720292,COMPASS ADVISORY GROUP LLC',
    name: 'COMPASS ADVISORY GROUP LLC',
    label: 'COMPASS ADVISORY GROUP LLC',
  },
  {
    cik: '0001002152',
    value: '0001002152,COMPASS CAPITAL MANAGEMENT INC/',
    name: 'COMPASS CAPITAL MANAGEMENT INC/',
    label: 'COMPASS CAPITAL MANAGEMENT INC/',
  },
  {
    cik: '0001002152',
    value: '0001002152,COMPASS CAPITAL MANAGEMENT, INC',
    name: 'COMPASS CAPITAL MANAGEMENT, INC',
    label: 'COMPASS CAPITAL MANAGEMENT, INC',
  },
  {
    cik: '0001455005',
    value: '0001455005,COMPASS EFFICIENT MODEL PORTFOLIOS, LLC',
    name: 'COMPASS EFFICIENT MODEL PORTFOLIOS, LLC',
    label: 'COMPASS EFFICIENT MODEL PORTFOLIOS, LLC',
  },
  {
    cik: '0001913221',
    value: '0001913221,COMPASS FINANCIAL ADVISORS LLC',
    name: 'COMPASS FINANCIAL ADVISORS LLC',
    label: 'COMPASS FINANCIAL ADVISORS LLC',
  },
  {
    cik: '0001666910',
    value: '0001666910,COMPASS FINANCIAL GROUP, INC.',
    name: 'COMPASS FINANCIAL GROUP, INC.',
    label: 'COMPASS FINANCIAL GROUP, INC.',
  },
  {
    cik: '0001317627',
    value: '0001317627,COMPASS GROUP LLC',
    name: 'COMPASS GROUP LLC',
    label: 'COMPASS GROUP LLC',
  },
  {
    cik: '0001621100',
    value: '0001621100,COMPASS ION ADVISORS, LLC',
    name: 'COMPASS ION ADVISORS, LLC',
    label: 'COMPASS ION ADVISORS, LLC',
  },
  {
    cik: '0001735886',
    value: '0001735886,COMPLEX CAPITAL MANAGEMENT, LLC',
    name: 'COMPLEX CAPITAL MANAGEMENT, LLC',
    label: 'COMPLEX CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001683297',
    value: '0001683297,COMPOSITE CAPITAL MANAGEMENT (HK) LTD',
    name: 'COMPOSITE CAPITAL MANAGEMENT (HK) LTD',
    label: 'COMPOSITE CAPITAL MANAGEMENT (HK) LTD',
  },
  {
    cik: '0001915714',
    value: '0001915714,COMPREHENSIVE FINANCIAL CONSULTANTS INSTITUTIONAL, INC.',
    name: 'COMPREHENSIVE FINANCIAL CONSULTANTS INSTITUTIONAL, INC.',
    label: 'COMPREHENSIVE FINANCIAL CONSULTANTS INSTITUTIONAL, INC.',
  },
  {
    cik: '0001799802',
    value: '0001799802,COMPREHENSIVE FINANCIAL MANAGEMENT LLC',
    name: 'COMPREHENSIVE FINANCIAL MANAGEMENT LLC',
    label: 'COMPREHENSIVE FINANCIAL MANAGEMENT LLC',
  },
  {
    cik: '0001698218',
    value: '0001698218,COMPREHENSIVE PORTFOLIO MANAGEMENT, LLC',
    name: 'COMPREHENSIVE PORTFOLIO MANAGEMENT, LLC',
    label: 'COMPREHENSIVE PORTFOLIO MANAGEMENT, LLC',
  },
  {
    cik: '0001080071',
    value: '0001080071,COMPTON CAPITAL MANAGEMENT INC /RI',
    name: 'COMPTON CAPITAL MANAGEMENT INC /RI',
    label: 'COMPTON CAPITAL MANAGEMENT INC /RI',
  },
  {
    cik: '0001844567',
    value: '0001844567,COMPTON FINANCIAL GROUP, LLC',
    name: 'COMPTON FINANCIAL GROUP, LLC',
    label: 'COMPTON FINANCIAL GROUP, LLC',
  },
  {
    cik: '0001761044',
    value: '0001761044,COMPTON WEALTH ADVISORY GROUP, LLC',
    name: 'COMPTON WEALTH ADVISORY GROUP, LLC',
    label: 'COMPTON WEALTH ADVISORY GROUP, LLC',
  },
  {
    cik: '0001452929',
    value: '0001452929,CONATUS CAPITAL MANAGEMENT LP',
    name: 'CONATUS CAPITAL MANAGEMENT LP',
    label: 'CONATUS CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001568298',
    value: '0001568298,CONCANNON WEALTH MANAGEMENT, LLC',
    name: 'CONCANNON WEALTH MANAGEMENT, LLC',
    label: 'CONCANNON WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001851869',
    value: '0001851869,CONCENTRIC CAPITAL STRATEGIES, LP',
    name: 'CONCENTRIC CAPITAL STRATEGIES, LP',
    label: 'CONCENTRIC CAPITAL STRATEGIES, LP',
  },
  {
    cik: '0001695345',
    value: '0001695345,CONCENTRIC WEALTH MANAGEMENT, LLC',
    name: 'CONCENTRIC WEALTH MANAGEMENT, LLC',
    label: 'CONCENTRIC WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001765387',
    value: '0001765387,CONCENTRUM WEALTH MANAGEMENT',
    name: 'CONCENTRUM WEALTH MANAGEMENT',
    label: 'CONCENTRUM WEALTH MANAGEMENT',
  },
  {
    cik: '0001543830',
    value: '0001543830,CONCEPT CAPITAL MARKETS, LLC',
    name: 'CONCEPT CAPITAL MARKETS, LLC',
    label: 'CONCEPT CAPITAL MARKETS, LLC',
  },
  {
    cik: '0001456534',
    value: '0001456534,CONCERT WEALTH MANAGEMENT INC.',
    name: 'CONCERT WEALTH MANAGEMENT INC.',
    label: 'CONCERT WEALTH MANAGEMENT INC.',
  },
  {
    cik: '0001275124',
    value: '0001275124,CONCORD ASSET MANAGEMENT LLC',
    name: 'CONCORD ASSET MANAGEMENT LLC',
    label: 'CONCORD ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001631773',
    value: '0001631773,CONCORDE ASSET MANAGEMENT, LLC',
    name: 'CONCORDE ASSET MANAGEMENT, LLC',
    label: 'CONCORDE ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001911822',
    value: '0001911822,CONCORDE FINANCIAL CORP',
    name: 'CONCORDE FINANCIAL CORP',
    label: 'CONCORDE FINANCIAL CORP',
  },
  {
    cik: '0001814214',
    value: '0001814214,CONCORD WEALTH PARTNERS',
    name: 'CONCORD WEALTH PARTNERS',
    label: 'CONCORD WEALTH PARTNERS',
  },
  {
    cik: '0001595852',
    value: '0001595852,CONCOURSE CAPITAL MANAGEMENT, LLC',
    name: 'CONCOURSE CAPITAL MANAGEMENT, LLC',
    label: 'CONCOURSE CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0000752798',
    value: '0000752798,CONCOURSE FINANCIAL GROUP SECURITIES, INC.',
    name: 'CONCOURSE FINANCIAL GROUP SECURITIES, INC.',
    label: 'CONCOURSE FINANCIAL GROUP SECURITIES, INC.',
  },
  {
    cik: '0001847739',
    value: '0001847739,CONDIRE MANAGEMENT, LP',
    name: 'CONDIRE MANAGEMENT, LP',
    label: 'CONDIRE MANAGEMENT, LP',
  },
  {
    cik: '0001082491',
    value: '0001082491,CONDOR CAPITAL MANAGEMENT',
    name: 'CONDOR CAPITAL MANAGEMENT',
    label: 'CONDOR CAPITAL MANAGEMENT',
  },
  {
    cik: '0001605541',
    value: '0001605541,CONDORCET GLOBAL OPPORTUNITY FUND, LLC',
    name: 'CONDORCET GLOBAL OPPORTUNITY FUND, LLC',
    label: 'CONDORCET GLOBAL OPPORTUNITY FUND, LLC',
  },
  {
    cik: '0001163744',
    value: '0001163744,CONESTOGA CAPITAL ADVISORS, LLC',
    name: 'CONESTOGA CAPITAL ADVISORS, LLC',
    label: 'CONESTOGA CAPITAL ADVISORS, LLC',
  },
  {
    cik: '0001442056',
    value: '0001442056,CONFLUENCE INVESTMENT MANAGEMENT LLC',
    name: 'CONFLUENCE INVESTMENT MANAGEMENT LLC',
    label: 'CONFLUENCE INVESTMENT MANAGEMENT LLC',
  },
  {
    cik: '0001606810',
    value: '0001606810,CONFLUENCE WEALTH MANAGEMENT LLC',
    name: 'CONFLUENCE WEALTH MANAGEMENT LLC',
    label: 'CONFLUENCE WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0001910411',
    value: '0001910411,CONFLUENCE WEALTH SERVICES, INC.',
    name: 'CONFLUENCE WEALTH SERVICES, INC.',
    label: 'CONFLUENCE WEALTH SERVICES, INC.',
  },
  {
    cik: '0000897378',
    value: '0000897378,CONGRESS ASSET MANAGEMENT CO /MA',
    name: 'CONGRESS ASSET MANAGEMENT CO /MA',
    label: 'CONGRESS ASSET MANAGEMENT CO /MA',
  },
  {
    cik: '0001730945',
    value: '0001730945,CONGRESS PARK CAPITAL LLC',
    name: 'CONGRESS PARK CAPITAL LLC',
    label: 'CONGRESS PARK CAPITAL LLC',
  },
  {
    cik: '0001904976',
    value: '0001904976,CONGRESS WEALTH MANAGEMENT LLC / DE /',
    name: 'CONGRESS WEALTH MANAGEMENT LLC / DE /',
    label: 'CONGRESS WEALTH MANAGEMENT LLC / DE /',
  },
  {
    cik: '0001773994',
    value: '0001773994,CONIFER MANAGEMENT, L.L.C.',
    name: 'CONIFER MANAGEMENT, L.L.C.',
    label: 'CONIFER MANAGEMENT, L.L.C.',
  },
  {
    cik: '0000914933',
    value: '0000914933,CONNABLE OFFICE INC',
    name: 'CONNABLE OFFICE INC',
    label: 'CONNABLE OFFICE INC',
  },
  {
    cik: '0001802462',
    value: '0001802462,CONNACHT ASSET MANAGEMENT LP',
    name: 'CONNACHT ASSET MANAGEMENT LP',
    label: 'CONNACHT ASSET MANAGEMENT LP',
  },
  {
    cik: '0001689829',
    value: '0001689829,CONNECTICUT WEALTH MANAGEMENT, LLC',
    name: 'CONNECTICUT WEALTH MANAGEMENT, LLC',
    label: 'CONNECTICUT WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001543568',
    value: '0001543568,CONNECTIVE CAPITAL MANAGEMENT, LLC',
    name: 'CONNECTIVE CAPITAL MANAGEMENT, LLC',
    label: 'CONNECTIVE CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001905083',
    value: '0001905083,CONNECTIVE PORTFOLIO MANAGEMENT, LLC',
    name: 'CONNECTIVE PORTFOLIO MANAGEMENT, LLC',
    label: 'CONNECTIVE PORTFOLIO MANAGEMENT, LLC',
  },
  {
    cik: '0001857493',
    value: '0001857493,CONNECTUS WEALTH, LLC',
    name: 'CONNECTUS WEALTH, LLC',
    label: 'CONNECTUS WEALTH, LLC',
  },
  {
    cik: '0000801051',
    value: '0000801051,CONNING INC.',
    name: 'CONNING INC.',
    label: 'CONNING INC.',
  },
  {
    cik: '0001845262',
    value: '0001845262,CONNOLLY SARAH T.',
    name: 'CONNOLLY SARAH T.',
    label: 'CONNOLLY SARAH T.',
  },
  {
    cik: '0001140804',
    value: '0001140804,CONNOR CLARK & LUNN INVESTMENT MANAGEMENT LTD',
    name: 'CONNOR CLARK & LUNN INVESTMENT MANAGEMENT LTD',
    label: 'CONNOR CLARK & LUNN INVESTMENT MANAGEMENT LTD',
  },
  {
    cik: '0001596800',
    value: '0001596800,CONNOR, CLARK & LUNN INVESTMENT MANAGEMENT LTD.',
    name: 'CONNOR, CLARK & LUNN INVESTMENT MANAGEMENT LTD.',
    label: 'CONNOR, CLARK & LUNN INVESTMENT MANAGEMENT LTD.',
  },
  {
    cik: '0000884548',
    value: '0000884548,CONNORS INVESTOR SERVICES INC',
    name: 'CONNORS INVESTOR SERVICES INC',
    label: 'CONNORS INVESTOR SERVICES INC',
  },
  {
    cik: '0001163165',
    value: '0001163165,CONOCOPHILLIPS',
    name: 'CONOCOPHILLIPS',
    label: 'CONOCOPHILLIPS',
  },
  {
    cik: '0001729242',
    value: '0001729242,CONRAD N. HILTON FOUNDATION',
    name: 'CONRAD N. HILTON FOUNDATION',
    label: 'CONRAD N. HILTON FOUNDATION',
  },
  {
    cik: '0001905875',
    value: '0001905875,CONRAD SIEGEL INVESTMENT ADVISORS, INC.',
    name: 'CONRAD SIEGEL INVESTMENT ADVISORS, INC.',
    label: 'CONRAD SIEGEL INVESTMENT ADVISORS, INC.',
  },
  {
    cik: '0001597245',
    value: '0001597245,CONSECTOR CAPITAL, LP',
    name: 'CONSECTOR CAPITAL, LP',
    label: 'CONSECTOR CAPITAL, LP',
  },
  {
    cik: '0001705716',
    value: '0001705716,CONSERVEST CAPITAL ADVISORS, INC.',
    name: 'CONSERVEST CAPITAL ADVISORS, INC.',
    label: 'CONSERVEST CAPITAL ADVISORS, INC.',
  },
  {
    cik: '0001908944',
    value: '0001908944,CONSILIO WEALTH ADVISORS, LLC',
    name: 'CONSILIO WEALTH ADVISORS, LLC',
    label: 'CONSILIO WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001611391',
    value: '0001611391,CONSILIUM INVESTMENT MANAGEMENT, LLC',
    name: 'CONSILIUM INVESTMENT MANAGEMENT, LLC',
    label: 'CONSILIUM INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001904906',
    value: '0001904906,CONSILIUM WEALTH ADVISORY, LLC',
    name: 'CONSILIUM WEALTH ADVISORY, LLC',
    label: 'CONSILIUM WEALTH ADVISORY, LLC',
  },
  {
    cik: '0001666613',
    value: '0001666613,CONSOLIDATED CAPITAL MANAGEMENT, LLC',
    name: 'CONSOLIDATED CAPITAL MANAGEMENT, LLC',
    label: 'CONSOLIDATED CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001556168',
    value: '0001556168,CONSOLIDATED INVESTMENT GROUP LLC',
    name: 'CONSOLIDATED INVESTMENT GROUP LLC',
    label: 'CONSOLIDATED INVESTMENT GROUP LLC',
  },
  {
    cik: '0001805250',
    value: '0001805250,CONSOLIDATED PLANNING CORP',
    name: 'CONSOLIDATED PLANNING CORP',
    label: 'CONSOLIDATED PLANNING CORP',
  },
  {
    cik: '0001544773',
    value: '0001544773,CONSONANCE CAPITAL MANAGEMENT LP',
    name: 'CONSONANCE CAPITAL MANAGEMENT LP',
    label: 'CONSONANCE CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001893840',
    value: '0001893840,CONSTELLATION INVESTIMENTOS E PARTICIPACOES LTDA',
    name: 'CONSTELLATION INVESTIMENTOS E PARTICIPACOES LTDA',
    label: 'CONSTELLATION INVESTIMENTOS E PARTICIPACOES LTDA',
  },
  {
    cik: '0001474208',
    value: '0001474208,CONSTELLATION WEALTH ADVISORS, LLC',
    name: 'CONSTELLATION WEALTH ADVISORS, LLC',
    label: 'CONSTELLATION WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001845531',
    value: '0001845531,CONSTITUTION CAPITAL LLC',
    name: 'CONSTITUTION CAPITAL LLC',
    label: 'CONSTITUTION CAPITAL LLC',
  },
  {
    cik: '0001510677',
    value: '0001510677,CONSULTA LTD',
    name: 'CONSULTA LTD',
    label: 'CONSULTA LTD',
  },
  {
    cik: '0001845849',
    value: '0001845849,CONSULTIVA WEALTH MANAGEMENT, CORP.',
    name: 'CONSULTIVA WEALTH MANAGEMENT, CORP.',
    label: 'CONSULTIVA WEALTH MANAGEMENT, CORP.',
  },
  {
    cik: '0001301396',
    value: '0001301396,CONTEXT ADVISORY LLC',
    name: 'CONTEXT ADVISORY LLC',
    label: 'CONTEXT ADVISORY LLC',
  },
  {
    cik: '0001618872',
    value: '0001618872,CONTEXT BH CAPITAL MANAGEMENT, LP',
    name: 'CONTEXT BH CAPITAL MANAGEMENT, LP',
    label: 'CONTEXT BH CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0001301396',
    value: '0001301396,CONTEXT CAPITAL MANAGEMENT, LLC',
    name: 'CONTEXT CAPITAL MANAGEMENT, LLC',
    label: 'CONTEXT CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001505246',
    value: '0001505246,CONTEXT PARTNERS FUND, L.P.',
    name: 'CONTEXT PARTNERS FUND, L.P.',
    label: 'CONTEXT PARTNERS FUND, L.P.',
  },
  {
    cik: '0001277779',
    value: '0001277779,CONTINENTAL ADVISORS LLC',
    name: 'CONTINENTAL ADVISORS LLC',
    label: 'CONTINENTAL ADVISORS LLC',
  },
  {
    cik: '0000929607',
    value: '0000929607,CONTINENTAL GRAIN CO',
    name: 'CONTINENTAL GRAIN CO',
    label: 'CONTINENTAL GRAIN CO',
  },
  {
    cik: '0000883634',
    value: '0000883634,CONTINENTAL INVESTORS SERVICES, INC.',
    name: 'CONTINENTAL INVESTORS SERVICES, INC.',
    label: 'CONTINENTAL INVESTORS SERVICES, INC.',
  },
  {
    cik: '0001846532',
    value: '0001846532,CONTINUUM ADVISORY, LLC',
    name: 'CONTINUUM ADVISORY, LLC',
    label: 'CONTINUUM ADVISORY, LLC',
  },
  {
    cik: '0001510669',
    value: '0001510669,CONTOUR ASSET MANAGEMENT LLC',
    name: 'CONTOUR ASSET MANAGEMENT LLC',
    label: 'CONTOUR ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001050417',
    value: '0001050417,CONTRARIAN CAPITAL MANAGEMENT, L.L.C.',
    name: 'CONTRARIAN CAPITAL MANAGEMENT, L.L.C.',
    label: 'CONTRARIAN CAPITAL MANAGEMENT, L.L.C.',
  },
  {
    cik: '0001483738',
    value: '0001483738,CONTRARIUS INVESTMENT MANAGEMENT LTD',
    name: 'CONTRARIUS INVESTMENT MANAGEMENT LTD',
    label: 'CONTRARIUS INVESTMENT MANAGEMENT LTD',
  },
  {
    cik: '0001365707',
    value: '0001365707,CONTRAVISORY INVESTMENT MANAGEMENT, INC.',
    name: 'CONTRAVISORY INVESTMENT MANAGEMENT, INC.',
    label: 'CONTRAVISORY INVESTMENT MANAGEMENT, INC.',
  },
  {
    cik: '0001665054',
    value: '0001665054,CONVECTOR CAPITAL MANAGEMENT, LP',
    name: 'CONVECTOR CAPITAL MANAGEMENT, LP',
    label: 'CONVECTOR CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0001456133',
    value: '0001456133,CONVERGENCE INVESTMENT PARTNERS, LLC',
    name: 'CONVERGENCE INVESTMENT PARTNERS, LLC',
    label: 'CONVERGENCE INVESTMENT PARTNERS, LLC',
  },
  {
    cik: '0001491296',
    value: '0001491296,CONVERGENT WEALTH ADVISORS',
    name: 'CONVERGENT WEALTH ADVISORS',
    label: 'CONVERGENT WEALTH ADVISORS',
  },
  {
    cik: '0001850901',
    value: '0001850901,CONVERSANT CAPITAL LLC',
    name: 'CONVERSANT CAPITAL LLC',
    label: 'CONVERSANT CAPITAL LLC',
  },
  {
    cik: '0001542280',
    value: '0001542280,COOK & BYNUM CAPITAL MANAGEMENT, LLC',
    name: 'COOK & BYNUM CAPITAL MANAGEMENT, LLC',
    label: 'COOK & BYNUM CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0000024386',
    value: '0000024386,COOKE & BIELER LP',
    name: 'COOKE & BIELER LP',
    label: 'COOKE & BIELER LP',
  },
  {
    cik: '0001141455',
    value: '0001141455,COOKSON PEIRCE & CO INC',
    name: 'COOKSON PEIRCE & CO INC',
    label: 'COOKSON PEIRCE & CO INC',
  },
  {
    cik: '0001916464',
    value: '0001916464,COOK STREET CONSULTING, INC.',
    name: 'COOK STREET CONSULTING, INC.',
    label: 'COOK STREET CONSULTING, INC.',
  },
  {
    cik: '0001910185',
    value: '0001910185,COOK WEALTH MANAGEMENT GROUP LLC',
    name: 'COOK WEALTH MANAGEMENT GROUP LLC',
    label: 'COOK WEALTH MANAGEMENT GROUP LLC',
  },
  {
    cik: '0001812902',
    value: '0001812902,COOMBE, BENDER & CO LLC',
    name: 'COOMBE, BENDER & CO LLC',
    label: 'COOMBE, BENDER & CO LLC',
  },
  {
    cik: '0001098062',
    value: '0001098062,COOPERATIEVE RABOBANK U.A.',
    name: 'COOPERATIEVE RABOBANK U.A.',
    label: 'COOPERATIEVE RABOBANK U.A.',
  },
  {
    cik: '0001512162',
    value: '0001512162,COOPER CREEK PARTNERS MANAGEMENT LLC',
    name: 'COOPER CREEK PARTNERS MANAGEMENT LLC',
    label: 'COOPER CREEK PARTNERS MANAGEMENT LLC',
  },
  {
    cik: '0001751581',
    value: '0001751581,COOPER FINANCIAL GROUP',
    name: 'COOPER FINANCIAL GROUP',
    label: 'COOPER FINANCIAL GROUP',
  },
  {
    cik: '0001654033',
    value: '0001654033,COOPER/HAIMS ADVISORS, LLC',
    name: 'COOPER/HAIMS ADVISORS, LLC',
    label: 'COOPER/HAIMS ADVISORS, LLC',
  },
  {
    cik: '0000898382',
    value: '0000898382,COOPERMAN LEON G',
    name: 'COOPERMAN LEON G',
    label: 'COOPERMAN LEON G',
  },
  {
    cik: '0001512998',
    value:
      '0001512998,COORDINATING INVESTMENT FIDUCIARY OF RAYTHEON MASTER PENSION TRUST',
    name: 'COORDINATING INVESTMENT FIDUCIARY OF RAYTHEON MASTER PENSION TRUST',
    label: 'COORDINATING INVESTMENT FIDUCIARY OF RAYTHEON MASTER PENSION TRUST',
  },
  {
    cik: '0001541743',
    value: '0001541743,COPELAND CAPITAL MANAGEMENT, LLC',
    name: 'COPELAND CAPITAL MANAGEMENT, LLC',
    label: 'COPELAND CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001694620',
    value: '0001694620,COPERNICUS CAPITAL MANAGEMENT, LLC',
    name: 'COPERNICUS CAPITAL MANAGEMENT, LLC',
    label: 'COPERNICUS CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001848433',
    value: '0001848433,COPPELL ADVISORY SOLUTIONS LLC',
    name: 'COPPELL ADVISORY SOLUTIONS LLC',
    label: 'COPPELL ADVISORY SOLUTIONS LLC',
  },
  {
    cik: '0001802225',
    value: '0001802225,COPPERLEAF CAPITAL, LLC',
    name: 'COPPERLEAF CAPITAL, LLC',
    label: 'COPPERLEAF CAPITAL, LLC',
  },
  {
    cik: '0001352543',
    value: '0001352543,COPPER ROCK CAPITAL PARTNERS, LLC',
    name: 'COPPER ROCK CAPITAL PARTNERS, LLC',
    label: 'COPPER ROCK CAPITAL PARTNERS, LLC',
  },
  {
    cik: '0001576332',
    value: '0001576332,COPPERSMITH CAPITAL MANAGEMENT, LLC',
    name: 'COPPERSMITH CAPITAL MANAGEMENT, LLC',
    label: 'COPPERSMITH CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001687832',
    value: '0001687832,COPPERWYND FINANCIAL, LLC',
    name: 'COPPERWYND FINANCIAL, LLC',
    label: 'COPPERWYND FINANCIAL, LLC',
  },
  {
    cik: '0001568298',
    value: '0001568298,CORBENIC PARTNERS LLC',
    name: 'CORBENIC PARTNERS LLC',
    label: 'CORBENIC PARTNERS LLC',
  },
  {
    cik: '0001799581',
    value: '0001799581,CORBETT ROAD CAPITAL MANAGEMENT, LLC',
    name: 'CORBETT ROAD CAPITAL MANAGEMENT, LLC',
    label: 'CORBETT ROAD CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001509874',
    value: '0001509874,CORBIN CAPITAL PARTNERS, L.P.',
    name: 'CORBIN CAPITAL PARTNERS, L.P.',
    label: 'CORBIN CAPITAL PARTNERS, L.P.',
  },
  {
    cik: '0000919538',
    value: '0000919538,CORBYN INVESTMENT MANAGEMENT INC/MD',
    name: 'CORBYN INVESTMENT MANAGEMENT INC/MD',
    label: 'CORBYN INVESTMENT MANAGEMENT INC/MD',
  },
  {
    cik: '0001545812',
    value: '0001545812,CORDA INVESTMENT MANAGEMENT, LLC.',
    name: 'CORDA INVESTMENT MANAGEMENT, LLC.',
    label: 'CORDA INVESTMENT MANAGEMENT, LLC.',
  },
  {
    cik: '0001830942',
    value: '0001830942,CORDANT, INC.',
    name: 'CORDANT, INC.',
    label: 'CORDANT, INC.',
  },
  {
    cik: '0001594061',
    value: '0001594061,CORDASCO FINANCIAL NETWORK',
    name: 'CORDASCO FINANCIAL NETWORK',
    label: 'CORDASCO FINANCIAL NETWORK',
  },
  {
    cik: '0001715862',
    value: '0001715862,CORDATUS WEALTH MANAGEMENT LLC',
    name: 'CORDATUS WEALTH MANAGEMENT LLC',
    label: 'CORDATUS WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0001776878',
    value: '0001776878,CORE ALTERNATIVE CAPITAL',
    name: 'CORE ALTERNATIVE CAPITAL',
    label: 'CORE ALTERNATIVE CAPITAL',
  },
  {
    cik: '0001902501',
    value: '0001902501,CORECAP ADVISORS, LLC',
    name: 'CORECAP ADVISORS, LLC',
    label: 'CORECAP ADVISORS, LLC',
  },
  {
    cik: '0001301743',
    value: '0001301743,CORECOMMODITY MANAGEMENT, LLC',
    name: 'CORECOMMODITY MANAGEMENT, LLC',
    label: 'CORECOMMODITY MANAGEMENT, LLC',
  },
  {
    cik: '0001845199',
    value: '0001845199,COREFIRST BANK & TRUST',
    name: 'COREFIRST BANK & TRUST',
    label: 'COREFIRST BANK & TRUST',
  },
  {
    cik: '0001845838',
    value: '0001845838,COREPATH WEALTH PARTNERS LLC',
    name: 'COREPATH WEALTH PARTNERS LLC',
    label: 'COREPATH WEALTH PARTNERS LLC',
  },
  {
    cik: '0001842785',
    value: '0001842785,COREVIEW CAPITAL MANAGEMENT LTD',
    name: 'COREVIEW CAPITAL MANAGEMENT LTD',
    label: 'COREVIEW CAPITAL MANAGEMENT LTD',
  },
  {
    cik: '0001770532',
    value: '0001770532,CORE WEALTH ADVISORS, INC.',
    name: 'CORE WEALTH ADVISORS, INC.',
    label: 'CORE WEALTH ADVISORS, INC.',
  },
  {
    cik: '0001688519',
    value: '0001688519,CORIENT CAPITAL PARTNERS, LLC',
    name: 'CORIENT CAPITAL PARTNERS, LLC',
    label: 'CORIENT CAPITAL PARTNERS, LLC',
  },
  {
    cik: '0001583977',
    value: '0001583977,CORMORANT ASSET MANAGEMENT, LLC',
    name: 'CORMORANT ASSET MANAGEMENT, LLC',
    label: 'CORMORANT ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001583977',
    value: '0001583977,CORMORANT ASSET MANAGEMENT, LP',
    name: 'CORMORANT ASSET MANAGEMENT, LP',
    label: 'CORMORANT ASSET MANAGEMENT, LP',
  },
  {
    cik: '0001736260',
    value: '0001736260,CORNELL POCHILY INVESTMENT ADVISORS, INC.',
    name: 'CORNELL POCHILY INVESTMENT ADVISORS, INC.',
    label: 'CORNELL POCHILY INVESTMENT ADVISORS, INC.',
  },
  {
    cik: '0000315082',
    value: '0000315082,CORNELL UNIVERSITY',
    name: 'CORNELL UNIVERSITY',
    label: 'CORNELL UNIVERSITY',
  },
  {
    cik: '0001062938',
    value: '0001062938,CORNERCAP INVESTMENT COUNSEL INC',
    name: 'CORNERCAP INVESTMENT COUNSEL INC',
    label: 'CORNERCAP INVESTMENT COUNSEL INC',
  },
  {
    cik: '0001166880',
    value: '0001166880,CORNERSTONE ADVISORS INC',
    name: 'CORNERSTONE ADVISORS INC',
    label: 'CORNERSTONE ADVISORS INC',
  },
  {
    cik: '0001532520',
    value: '0001532520,CORNERSTONE ADVISORS, INC.',
    name: 'CORNERSTONE ADVISORS, INC.',
    label: 'CORNERSTONE ADVISORS, INC.',
  },
  {
    cik: '0001775446',
    value: '0001775446,CORNERSTONE ADVISORS, LLC',
    name: 'CORNERSTONE ADVISORS, LLC',
    label: 'CORNERSTONE ADVISORS, LLC',
  },
  {
    cik: '0001668189',
    value: '0001668189,CORNERSTONE ADVISORY, LLC',
    name: 'CORNERSTONE ADVISORY, LLC',
    label: 'CORNERSTONE ADVISORY, LLC',
  },
  {
    cik: '0000819535',
    value: '0000819535,CORNERSTONE CAPITAL, INC.',
    name: 'CORNERSTONE CAPITAL, INC.',
    label: 'CORNERSTONE CAPITAL, INC.',
  },
  {
    cik: '0001453415',
    value: '0001453415,CORNERSTONE CAPITAL MANAGEMENT HOLDINGS LLC.',
    name: 'CORNERSTONE CAPITAL MANAGEMENT HOLDINGS LLC.',
    label: 'CORNERSTONE CAPITAL MANAGEMENT HOLDINGS LLC.',
  },
  {
    cik: '0001123570',
    value: '0001123570,CORNERSTONE CAPITAL MANAGEMENT LLC',
    name: 'CORNERSTONE CAPITAL MANAGEMENT LLC',
    label: 'CORNERSTONE CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001665445',
    value: '0001665445,CORNERSTONE FINANCIAL PARTNERS, LLC',
    name: 'CORNERSTONE FINANCIAL PARTNERS, LLC',
    label: 'CORNERSTONE FINANCIAL PARTNERS, LLC',
  },
  {
    cik: '0001328062',
    value: '0001328062,CORNERSTONE INVESTMENT PARTNERS, LLC',
    name: 'CORNERSTONE INVESTMENT PARTNERS, LLC',
    label: 'CORNERSTONE INVESTMENT PARTNERS, LLC',
  },
  {
    cik: '0001845620',
    value: '0001845620,CORNERSTONE PARTNERS CAPITAL MANAGEMENT, LLC',
    name: 'CORNERSTONE PARTNERS CAPITAL MANAGEMENT, LLC',
    label: 'CORNERSTONE PARTNERS CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001752833',
    value: '0001752833,CORNERSTONE PARTNERS LLC',
    name: 'CORNERSTONE PARTNERS LLC',
    label: 'CORNERSTONE PARTNERS LLC',
  },
  {
    cik: '0001379495',
    value: '0001379495,CORNERSTONE REAL ESTATE ADVISERS LLC',
    name: 'CORNERSTONE REAL ESTATE ADVISERS LLC',
    label: 'CORNERSTONE REAL ESTATE ADVISERS LLC',
  },
  {
    cik: '0001791803',
    value: '0001791803,CORNERSTONE RETIREMENT GROUP, INC.',
    name: 'CORNERSTONE RETIREMENT GROUP, INC.',
    label: 'CORNERSTONE RETIREMENT GROUP, INC.',
  },
  {
    cik: '0001862176',
    value: '0001862176,CORNERSTONE WEALTH ADVISORS, INC.',
    name: 'CORNERSTONE WEALTH ADVISORS, INC.',
    label: 'CORNERSTONE WEALTH ADVISORS, INC.',
  },
  {
    cik: '0001774879',
    value: '0001774879,CORNERSTONE WEALTH GROUP, LLC',
    name: 'CORNERSTONE WEALTH GROUP, LLC',
    label: 'CORNERSTONE WEALTH GROUP, LLC',
  },
  {
    cik: '0001674117',
    value: '0001674117,CORNERSTONE WEALTH MANAGEMENT, LLC',
    name: 'CORNERSTONE WEALTH MANAGEMENT, LLC',
    label: 'CORNERSTONE WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001569143',
    value: '0001569143,CORONATION ASSET MANAGEMENT (PTY) LTD.',
    name: 'CORONATION ASSET MANAGEMENT (PTY) LTD.',
    label: 'CORONATION ASSET MANAGEMENT (PTY) LTD.',
  },
  {
    cik: '0001594320',
    value: '0001594320,CORONATION FUND MANAGERS LTD.',
    name: 'CORONATION FUND MANAGERS LTD.',
    label: 'CORONATION FUND MANAGERS LTD.',
  },
  {
    cik: '0001482611',
    value: '0001482611,CORRADO ADVISORS, LLC',
    name: 'CORRADO ADVISORS, LLC',
    label: 'CORRADO ADVISORS, LLC',
  },
  {
    cik: '0001537323',
    value: '0001537323,CORRE PARTNERS MANAGEMENT, LLC',
    name: 'CORRE PARTNERS MANAGEMENT, LLC',
    label: 'CORRE PARTNERS MANAGEMENT, LLC',
  },
  {
    cik: '0001406718',
    value: '0001406718,CORRIENTE ADVISORS, LLC',
    name: 'CORRIENTE ADVISORS, LLC',
    label: 'CORRIENTE ADVISORS, LLC',
  },
  {
    cik: '0001408853',
    value: '0001408853,CORSAIR CAPITAL LLC',
    name: 'CORSAIR CAPITAL LLC',
    label: 'CORSAIR CAPITAL LLC',
  },
  {
    cik: '0001276470',
    value: '0001276470,CORSAIR CAPITAL MANAGEMENT, L.P.',
    name: 'CORSAIR CAPITAL MANAGEMENT, L.P.',
    label: 'CORSAIR CAPITAL MANAGEMENT, L.P.',
  },
  {
    cik: '0001869923',
    value: '0001869923,CORSICANA & CO.',
    name: 'CORSICANA & CO.',
    label: 'CORSICANA & CO.',
  },
  {
    cik: '0001571732',
    value: '0001571732,CORTEX CAPITAL MANAGEMENT LLC',
    name: 'CORTEX CAPITAL MANAGEMENT LLC',
    label: 'CORTEX CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001325312',
    value: '0001325312,CORTINA ASSET MANAGEMENT, LLC',
    name: 'CORTINA ASSET MANAGEMENT, LLC',
    label: 'CORTINA ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001568990',
    value: '0001568990,CORTLAND ADVISERS LLC',
    name: 'CORTLAND ADVISERS LLC',
    label: 'CORTLAND ADVISERS LLC',
  },
  {
    cik: '0001014315',
    value: '0001014315,CORTLAND ASSOCIATES INC/MO',
    name: 'CORTLAND ASSOCIATES INC/MO',
    label: 'CORTLAND ASSOCIATES INC/MO',
  },
  {
    cik: '0001910417',
    value: '0001910417,CORTON CAPITAL INC.',
    name: 'CORTON CAPITAL INC.',
    label: 'CORTON CAPITAL INC.',
  },
  {
    cik: '0001486083',
    value: '0001486083,CORUNDUM GROUP, INC.',
    name: 'CORUNDUM GROUP, INC.',
    label: 'CORUNDUM GROUP, INC.',
  },
  {
    cik: '0001535472',
    value: '0001535472,CORVEX MANAGEMENT LP',
    name: 'CORVEX MANAGEMENT LP',
    label: 'CORVEX MANAGEMENT LP',
  },
  {
    cik: '0001455452',
    value: '0001455452,CORVID PEAK CAPITAL MANAGEMENT, LLC',
    name: 'CORVID PEAK CAPITAL MANAGEMENT, LLC',
    label: 'CORVID PEAK CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001760145',
    value: '0001760145,COSTELLO ASSET MANAGEMENT, INC',
    name: 'COSTELLO ASSET MANAGEMENT, INC',
    label: 'COSTELLO ASSET MANAGEMENT, INC',
  },
  {
    cik: '0001945037',
    value: '0001945037,COSTON, MCISAAC & PARTNERS',
    name: 'COSTON, MCISAAC & PARTNERS',
    label: 'COSTON, MCISAAC & PARTNERS',
  },
  {
    cik: '0001801577',
    value: '0001801577,COTA CAPITAL MANAGEMENT, LLC',
    name: 'COTA CAPITAL MANAGEMENT, LLC',
    label: 'COTA CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001665302',
    value: '0001665302,COTTAGE STREET ADVISORS LLC',
    name: 'COTTAGE STREET ADVISORS LLC',
    label: 'COTTAGE STREET ADVISORS LLC',
  },
  {
    cik: '0001903321',
    value: '0001903321,COTTONWOOD CAPITAL ADVISORS, LLC',
    name: 'COTTONWOOD CAPITAL ADVISORS, LLC',
    label: 'COTTONWOOD CAPITAL ADVISORS, LLC',
  },
  {
    cik: '0001533119',
    value: '0001533119,COUGAR GLOBAL INVESTMENTS LTD',
    name: 'COUGAR GLOBAL INVESTMENTS LTD',
    label: 'COUGAR GLOBAL INVESTMENTS LTD',
  },
  {
    cik: '0001841767',
    value: '0001841767,COULTER & JUSTUS FINANCIAL SERVICES, LLC',
    name: 'COULTER & JUSTUS FINANCIAL SERVICES, LLC',
    label: 'COULTER & JUSTUS FINANCIAL SERVICES, LLC',
  },
  {
    cik: '0001498900',
    value: '0001498900,COUNSEL PORTFOLIO SERVICES INC.',
    name: 'COUNSEL PORTFOLIO SERVICES INC.',
    label: 'COUNSEL PORTFOLIO SERVICES INC.',
  },
  {
    cik: '0001698461',
    value: '0001698461,COUNTERPOINT MUTUAL FUNDS LLC',
    name: 'COUNTERPOINT MUTUAL FUNDS LLC',
    label: 'COUNTERPOINT MUTUAL FUNDS LLC',
  },
  {
    cik: '0000824325',
    value: '0000824325,COUNTRY CLUB BANK /GFN',
    name: 'COUNTRY CLUB BANK /GFN',
    label: 'COUNTRY CLUB BANK /GFN',
  },
  {
    cik: '0001299582',
    value: '0001299582,COUNTRY CLUB TRUST COMPANY, N.A.',
    name: 'COUNTRY CLUB TRUST COMPANY, N.A.',
    label: 'COUNTRY CLUB TRUST COMPANY, N.A.',
  },
  {
    cik: '0001209324',
    value: '0001209324,COUNTRY TRUST BANK',
    name: 'COUNTRY TRUST BANK',
    label: 'COUNTRY TRUST BANK',
  },
  {
    cik: '0001620067',
    value: '0001620067,COUPLAND CARDIFF ASSET MANAGEMENT LLP',
    name: 'COUPLAND CARDIFF ASSET MANAGEMENT LLP',
    label: 'COUPLAND CARDIFF ASSET MANAGEMENT LLP',
  },
  {
    cik: '0001119376',
    value: '0001119376,COURAGE CAPITAL MANAGEMENT LLC',
    name: 'COURAGE CAPITAL MANAGEMENT LLC',
    label: 'COURAGE CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001482106',
    value: '0001482106,COURAGE MILLER PARTNERS, LLC',
    name: 'COURAGE MILLER PARTNERS, LLC',
    label: 'COURAGE MILLER PARTNERS, LLC',
  },
  {
    cik: '0001695426',
    value: '0001695426,COURANT INVESTMENT MANAGEMENT LLC',
    name: 'COURANT INVESTMENT MANAGEMENT LLC',
    label: 'COURANT INVESTMENT MANAGEMENT LLC',
  },
  {
    cik: '0001015247',
    value: '0001015247,COURIER CAPITAL CORP',
    name: 'COURIER CAPITAL CORP',
    label: 'COURIER CAPITAL CORP',
  },
  {
    cik: '0001015247',
    value: '0001015247,COURIER CAPITAL LLC',
    name: 'COURIER CAPITAL LLC',
    label: 'COURIER CAPITAL LLC',
  },
  {
    cik: '0001566113',
    value: '0001566113,COURT PLACE ADVISORS, LLC',
    name: 'COURT PLACE ADVISORS, LLC',
    label: 'COURT PLACE ADVISORS, LLC',
  },
  {
    cik: '0001921396',
    value: '0001921396,COURY FIRM ASSET MANAGEMENT LLC',
    name: 'COURY FIRM ASSET MANAGEMENT LLC',
    label: 'COURY FIRM ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001531611',
    value: '0001531611,COVALENT PARTNERS LLC',
    name: 'COVALENT PARTNERS LLC',
    label: 'COVALENT PARTNERS LLC',
  },
  {
    cik: '0001698819',
    value: '0001698819,COVALIS CAPITAL LLP',
    name: 'COVALIS CAPITAL LLP',
    label: 'COVALIS CAPITAL LLP',
  },
  {
    cik: '0001876826',
    value: '0001876826,COVALIS (GIBRALTAR) LTD',
    name: 'COVALIS (GIBRALTAR) LTD',
    label: 'COVALIS (GIBRALTAR) LTD',
  },
  {
    cik: '0001636948',
    value: '0001636948,COVEA FINANCE',
    name: 'COVEA FINANCE',
    label: 'COVEA FINANCE',
  },
  {
    cik: '0001697717',
    value: '0001697717,COVENANT ASSET MANAGEMENT, LLC',
    name: 'COVENANT ASSET MANAGEMENT, LLC',
    label: 'COVENANT ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001568542',
    value: '0001568542,COVENANT FINANCIAL SERVICES, LLC',
    name: 'COVENANT FINANCIAL SERVICES, LLC',
    label: 'COVENANT FINANCIAL SERVICES, LLC',
  },
  {
    cik: '0001599749',
    value: '0001599749,COVENANT MULTIFAMILY OFFICES, LLC',
    name: 'COVENANT MULTIFAMILY OFFICES, LLC',
    label: 'COVENANT MULTIFAMILY OFFICES, LLC',
  },
  {
    cik: '0001302739',
    value: '0001302739,COVENANT PARTNERS, LLC',
    name: 'COVENANT PARTNERS, LLC',
    label: 'COVENANT PARTNERS, LLC',
  },
  {
    cik: '0001909846',
    value: '0001909846,COVESTOR LTD',
    name: 'COVESTOR LTD',
    label: 'COVESTOR LTD',
  },
  {
    cik: '0001531612',
    value: '0001531612,COVE STREET CAPITAL, LLC',
    name: 'COVE STREET CAPITAL, LLC',
    label: 'COVE STREET CAPITAL, LLC',
  },
  {
    cik: '0001568891',
    value: '0001568891,COVEY CAPITAL ADVISORS, LLC',
    name: 'COVEY CAPITAL ADVISORS, LLC',
    label: 'COVEY CAPITAL ADVISORS, LLC',
  },
  {
    cik: '0001323119',
    value: '0001323119,COVINGTON CAPITAL MANAGEMENT',
    name: 'COVINGTON CAPITAL MANAGEMENT',
    label: 'COVINGTON CAPITAL MANAGEMENT',
  },
  {
    cik: '0001569036',
    value: '0001569036,COVINGTON INVESTMENT ADVISORS INC.',
    name: 'COVINGTON INVESTMENT ADVISORS INC.',
    label: 'COVINGTON INVESTMENT ADVISORS INC.',
  },
  {
    cik: '0001844480',
    value: '00018444,COWA, LLC',
    name: 'COWA, LLC',
    label: 'COWA, LLC',
  },
  {
    cik: '0001761435',
    value: '0001761435,COWBIRD CAPITAL LP',
    name: 'COWBIRD CAPITAL LP',
    label: 'COWBIRD CAPITAL LP',
  },
  {
    cik: '0000048966',
    value: '0000048966,COWEN AND COMPANY, LLC',
    name: 'COWEN AND COMPANY, LLC',
    label: 'COWEN AND COMPANY, LLC',
  },
  {
    cik: '0001466538',
    value: '0001466538,COWEN GROUP, INC.',
    name: 'COWEN GROUP, INC.',
    label: 'COWEN GROUP, INC.',
  },
  {
    cik: '0001475770',
    value: '0001475770,COWEN INVESTMENT MANAGEMENT LLC',
    name: 'COWEN INVESTMENT MANAGEMENT LLC',
    label: 'COWEN INVESTMENT MANAGEMENT LLC',
  },
  {
    cik: '0001875920',
    value: '0001875920,COWEN PRIME ADVISORS LLC',
    name: 'COWEN PRIME ADVISORS LLC',
    label: 'COWEN PRIME ADVISORS LLC',
  },
  {
    cik: '0001543830',
    value: '0001543830,COWEN PRIME SERVICES LLC',
    name: 'COWEN PRIME SERVICES LLC',
    label: 'COWEN PRIME SERVICES LLC',
  },
  {
    cik: '0001748728',
    value: '0001748728,COX CAPITAL CO LLC',
    name: 'COX CAPITAL CO LLC',
    label: 'COX CAPITAL CO LLC',
  },
  {
    cik: '0001748728',
    value: '0001748728,COX CAPITAL MGT LLC',
    name: 'COX CAPITAL MGT LLC',
    label: 'COX CAPITAL MGT LLC',
  },
  {
    cik: '0001593038',
    value: '0001593038,COYLE FINANCIAL COUNSEL LLC',
    name: 'COYLE FINANCIAL COUNSEL LLC',
    label: 'COYLE FINANCIAL COUNSEL LLC',
  },
  {
    cik: '0000709428',
    value: '0000709428,COZAD ASSET MANAGEMENT INC',
    name: 'COZAD ASSET MANAGEMENT INC',
    label: 'COZAD ASSET MANAGEMENT INC',
  },
  {
    cik: '0001634083',
    value: '0001634083,C PARTNERS HOLDING GMBH',
    name: 'C PARTNERS HOLDING GMBH',
    label: 'C PARTNERS HOLDING GMBH',
  },
  {
    cik: '0001697457',
    value: '00016974,CPCM, LLC',
    name: 'CPCM, LLC',
    label: 'CPCM, LLC',
  },
  {
    cik: '0001372218',
    value: '0001372,CPMG INC8',
    name: 'CPMG INC',
    label: 'CPMG INC',
  },
  {
    cik: '0001775053',
    value: '0001775053,CPR INVESTMENTS INC.',
    name: 'CPR INVESTMENTS INC.',
    label: 'CPR INVESTMENTS INC.',
  },
  {
    cik: '0001763844',
    value: '0001763844,CPV PARTNERS, LLC',
    name: 'CPV PARTNERS, LLC',
    label: 'CPV PARTNERS, LLC',
  },
  {
    cik: '0001697457',
    value: '00016974,CPWM, LLC',
    name: 'CPWM, LLC',
    label: 'CPWM, LLC',
  },
  {
    cik: '0001431256',
    value: '0001431256,CQS CAYMAN LP',
    name: 'CQS CAYMAN LP',
    label: 'CQS CAYMAN LP',
  },
  {
    cik: '0001447946',
    value: '0001447946,CQS (US), LLC',
    name: 'CQS (US), LLC',
    label: 'CQS (US), LLC',
  },
  {
    cik: '0001536557',
    value: '0001536557,CRABEL CAPITAL MANAGEMENT, LLC',
    name: 'CRABEL CAPITAL MANAGEMENT, LLC',
    label: 'CRABEL CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001765594',
    value: '0001765594,CRA FINANCIAL SERVICES, LLC',
    name: 'CRA FINANCIAL SERVICES, LLC',
    label: 'CRA FINANCIAL SERVICES, LLC',
  },
  {
    cik: '0001942351',
    value: '0001942351,CRAFT VENTURES GP II, LLC',
    name: 'CRAFT VENTURES GP II, LLC',
    label: 'CRAFT VENTURES GP II, LLC',
  },
  {
    cik: '0001895423',
    value: '0001895423,CRAFT VENTURES GP I, LLC',
    name: 'CRAFT VENTURES GP I, LLC',
    label: 'CRAFT VENTURES GP I, LLC',
  },
  {
    cik: '0001789082',
    value: '0001789082,CRAKE ASSET MANAGEMENT LLP',
    name: 'CRAKE ASSET MANAGEMENT LLP',
    label: 'CRAKE ASSET MANAGEMENT LLP',
  },
  {
    cik: '0000820743',
    value: '0000820743,CRAMER ROSENTHAL MCGLYNN LLC',
    name: 'CRAMER ROSENTHAL MCGLYNN LLC',
    label: 'CRAMER ROSENTHAL MCGLYNN LLC',
  },
  {
    cik: '0001584362',
    value: '0001584362,CRANBERRY LAKE ADVISORS LLC',
    name: 'CRANBERRY LAKE ADVISORS LLC',
    label: 'CRANBERRY LAKE ADVISORS LLC',
  },
  {
    cik: '0001599084',
    value: '0001599084,CRANBROOK WEALTH MANAGEMENT, LLC',
    name: 'CRANBROOK WEALTH MANAGEMENT, LLC',
    label: 'CRANBROOK WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001951283',
    value: '0001951283,CRANE ADVISORY, LLC',
    name: 'CRANE ADVISORY, LLC',
    label: 'CRANE ADVISORY, LLC',
  },
  {
    cik: '0000837592',
    value: '0000837592,CRAWFORD INVESTMENT COUNSEL INC',
    name: 'CRAWFORD INVESTMENT COUNSEL INC',
    label: 'CRAWFORD INVESTMENT COUNSEL INC',
  },
  {
    cik: '0001623796',
    value: '0001623796,CRAWFORD LAKE CAPITAL MANAGEMENT, LLC',
    name: 'CRAWFORD LAKE CAPITAL MANAGEMENT, LLC',
    label: 'CRAWFORD LAKE CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001483234',
    value: '000148,CRCM LP34',
    name: 'CRCM LP',
    label: 'CRCM LP',
  },
  {
    cik: '0001957363',
    value: '0001957363,CREATIVE CAPITAL MANAGEMENT INVESTMENTS LLC',
    name: 'CREATIVE CAPITAL MANAGEMENT INVESTMENTS LLC',
    label: 'CREATIVE CAPITAL MANAGEMENT INVESTMENTS LLC',
  },
  {
    cik: '0000819864',
    value: '0000819864,CREATIVE FINANCIAL DESIGNS INC /ADV',
    name: 'CREATIVE FINANCIAL DESIGNS INC /ADV',
    label: 'CREATIVE FINANCIAL DESIGNS INC /ADV',
  },
  {
    cik: '0001739728',
    value: '0001739728,CREATIVEONE WEALTH, LLC',
    name: 'CREATIVEONE WEALTH, LLC',
    label: 'CREATIVEONE WEALTH, LLC',
  },
  {
    cik: '0001540235',
    value: '0001540235,CREATIVE PLANNING',
    name: 'CREATIVE PLANNING',
    label: 'CREATIVE PLANNING',
  },
  {
    cik: '0001191672',
    value: '0001191672,CREDIT AGRICOLE S A',
    name: 'CREDIT AGRICOLE S A',
    label: 'CREDIT AGRICOLE S A',
  },
  {
    cik: '0001631208',
    value: '0001631208,CREDIT CAPITAL INVESTMENTS LLC',
    name: 'CREDIT CAPITAL INVESTMENTS LLC',
    label: 'CREDIT CAPITAL INVESTMENTS LLC',
  },
  {
    cik: '0000824468',
    value: '0000824468,CREDIT SUISSE AG/',
    name: 'CREDIT SUISSE AG/',
    label: 'CREDIT SUISSE AG/',
  },
  {
    cik: '0001417758',
    value: '0001417758,CREDO CAPITAL MANAGEMENT, LLC',
    name: 'CREDO CAPITAL MANAGEMENT, LLC',
    label: 'CREDO CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001631054',
    value: '0001631054,CREEGAN & NASSOURA FINANCIAL GROUP, LLC',
    name: 'CREEGAN & NASSOURA FINANCIAL GROUP, LLC',
    label: 'CREEGAN & NASSOURA FINANCIAL GROUP, LLC',
  },
  {
    cik: '0001676613',
    value: '0001676613,CRESCENT CAPITAL CONSULTING, LLC',
    name: 'CRESCENT CAPITAL CONSULTING, LLC',
    label: 'CRESCENT CAPITAL CONSULTING, LLC',
  },
  {
    cik: '0001513857',
    value: '0001513857,CRESCENT CAPITAL GROUP LP',
    name: 'CRESCENT CAPITAL GROUP LP',
    label: 'CRESCENT CAPITAL GROUP LP',
  },
  {
    cik: '0001713520',
    value: '0001713520,CRESCENT GROVE ADVISORS, LLC',
    name: 'CRESCENT GROVE ADVISORS, LLC',
    label: 'CRESCENT GROVE ADVISORS, LLC',
  },
  {
    cik: '0001629984',
    value: '0001629984,CRESCENT PARK MANAGEMENT, L.P.',
    name: 'CRESCENT PARK MANAGEMENT, L.P.',
    label: 'CRESCENT PARK MANAGEMENT, L.P.',
  },
  {
    cik: '0001962005',
    value: '0001962005,CRESCENT STERLING, LTD.',
    name: 'CRESCENT STERLING, LTD.',
    label: 'CRESCENT STERLING, LTD.',
  },
  {
    cik: '0001761013',
    value: '0001761013,CRESSET ASSET MANAGEMENT, LLC',
    name: 'CRESSET ASSET MANAGEMENT, LLC',
    label: 'CRESSET ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001947670',
    value: '0001947670,CRESTA ADVISORS, LTD.',
    name: 'CRESTA ADVISORS, LTD.',
    label: 'CRESTA ADVISORS, LTD.',
  },
  {
    cik: '0001536054',
    value: '0001536054,CRESTLINE MANAGEMENT, LP',
    name: 'CRESTLINE MANAGEMENT, LP',
    label: 'CRESTLINE MANAGEMENT, LP',
  },
  {
    cik: '0001873372',
    value: '0001873372,CRESTMONT PRIVATE WEALTH LLC',
    name: 'CRESTMONT PRIVATE WEALTH LLC',
    label: 'CRESTMONT PRIVATE WEALTH LLC',
  },
  {
    cik: '0001845617',
    value: '0001845617,CRESTONE ASSET MANAGEMENT LLC',
    name: 'CRESTONE ASSET MANAGEMENT LLC',
    label: 'CRESTONE ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001596461',
    value: '0001596461,CRESTPOINT CAPITAL MANAGEMENT, LLC',
    name: 'CRESTPOINT CAPITAL MANAGEMENT, LLC',
    label: 'CRESTPOINT CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001402174',
    value: '0001402174,CRESTVIEW PARTNERS GP, L.P.',
    name: 'CRESTVIEW PARTNERS GP, L.P.',
    label: 'CRESTVIEW PARTNERS GP, L.P.',
  },
  {
    cik: '0001505639',
    value: '0001505639,CRESTVIEW PARTNERS II GP, L.P.',
    name: 'CRESTVIEW PARTNERS II GP, L.P.',
    label: 'CRESTVIEW PARTNERS II GP, L.P.',
  },
  {
    cik: '0001703027',
    value: '0001703027,CRESTVIEW PARTNERS III GP, L.P.',
    name: 'CRESTVIEW PARTNERS III GP, L.P.',
    label: 'CRESTVIEW PARTNERS III GP, L.P.',
  },
  {
    cik: '0001818687',
    value: '0001818687,CRESTVIEW PARTNERS IV GP, L.P.',
    name: 'CRESTVIEW PARTNERS IV GP, L.P.',
    label: 'CRESTVIEW PARTNERS IV GP, L.P.',
  },
  {
    cik: '0001426853',
    value: '0001426853,CRESTWOOD ADVISORS GROUP LLC',
    name: 'CRESTWOOD ADVISORS GROUP LLC',
    label: 'CRESTWOOD ADVISORS GROUP LLC',
  },
  {
    cik: '0001468395',
    value: '0001468395,CRESTWOOD CAPITAL MANAGEMENT, L.P.',
    name: 'CRESTWOOD CAPITAL MANAGEMENT, L.P.',
    label: 'CRESTWOOD CAPITAL MANAGEMENT, L.P.',
  },
  {
    cik: '0001835669',
    value: '0001835669,CREW CAPITAL MANAGEMENT, LTD.',
    name: 'CREW CAPITAL MANAGEMENT, LTD.',
    label: 'CREW CAPITAL MANAGEMENT, LTD.',
  },
  {
    cik: '0001730033',
    value: '0001730033,CREWE ADVISORS LLC',
    name: 'CREWE ADVISORS LLC',
    label: 'CREWE ADVISORS LLC',
  },
  {
    cik: '0001667163',
    value: '0001667163,CRIBSTONE CAPITAL MANAGEMENT, LLC',
    name: 'CRIBSTONE CAPITAL MANAGEMENT, LLC',
    label: 'CRIBSTONE CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001316388',
    value: '0001316388,CR INTRINSIC INVESTORS, LLC',
    name: 'CR INTRINSIC INVESTORS, LLC',
    label: 'CR INTRINSIC INVESTORS, LLC',
  },
  {
    cik: '0001282550',
    value: '0001282550,CRITERION CAPITAL MANAGEMENT LLC',
    name: 'CRITERION CAPITAL MANAGEMENT LLC',
    label: 'CRITERION CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0000932752',
    value: '0000932752,CROFT-LEOMINSTER INC',
    name: 'CROFT-LEOMINSTER INC',
    label: 'CROFT-LEOMINSTER INC',
  },
  {
    cik: '0001595683',
    value: '0001595683,CROSBY CO OF NEW HAMPSHIRE LLC',
    name: 'CROSBY CO OF NEW HAMPSHIRE LLC',
    label: 'CROSBY CO OF NEW HAMPSHIRE LLC',
  },
  {
    cik: '0001911837',
    value: '0001911837,CROSS CREEK ADVISORS, LLC',
    name: 'CROSS CREEK ADVISORS, LLC',
    label: 'CROSS CREEK ADVISORS, LLC',
  },
  {
    cik: '0001104329',
    value: '0001104329,CROSSLINK CAPITAL INC',
    name: 'CROSSLINK CAPITAL INC',
    label: 'CROSSLINK CAPITAL INC',
  },
  {
    cik: '0001539204',
    value: '0001539204,CROSSMARK GLOBAL HOLDINGS, INC.',
    name: 'CROSSMARK GLOBAL HOLDINGS, INC.',
    label: 'CROSSMARK GLOBAL HOLDINGS, INC.',
  },
  {
    cik: '0001594197',
    value: '0001594197,CROSSPOINT CAPITAL STRATEGIES, LLC',
    name: 'CROSSPOINT CAPITAL STRATEGIES, LLC',
    label: 'CROSSPOINT CAPITAL STRATEGIES, LLC',
  },
  {
    cik: '0001900406',
    value: '0001900406,CROSS STAFF INVESTMENTS INC',
    name: 'CROSS STAFF INVESTMENTS INC',
    label: 'CROSS STAFF INVESTMENTS INC',
  },
  {
    cik: '0001599468',
    value: '0001599468,CROSSVAULT CAPITAL MANAGEMENT LLC',
    name: 'CROSSVAULT CAPITAL MANAGEMENT LLC',
    label: 'CROSSVAULT CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001733248',
    value: '0001733248,CROWN ADVISORS MANAGEMENT, INC.',
    name: 'CROWN ADVISORS MANAGEMENT, INC.',
    label: 'CROWN ADVISORS MANAGEMENT, INC.',
  },
  {
    cik: '0001845698',
    value: '0001845698,CROWN WEALTH GROUP, LLC',
    name: 'CROWN WEALTH GROUP, LLC',
    label: 'CROWN WEALTH GROUP, LLC',
  },
  {
    cik: '0001384305',
    value: '0001384305,CROW POINT PARTNERS, LLC',
    name: 'CROW POINT PARTNERS, LLC',
    label: 'CROW POINT PARTNERS, LLC',
  },
  {
    cik: '0001898772',
    value: '0001898772,CROW S NEST HOLDINGS LP',
    name: "CROW'S NEST HOLDINGS LP",
    label: "CROW'S NEST HOLDINGS LP",
  },
  {
    cik: '0001713506',
    value: '0001713506,CRUISER CAPITAL ADVISORS, LLC',
    name: 'CRUISER CAPITAL ADVISORS, LLC',
    label: 'CRUISER CAPITAL ADVISORS, LLC',
  },
  {
    cik: '0001759578',
    value: '0001759578,CRUMLY & ASSOCIATES INC.',
    name: 'CRUMLY & ASSOCIATES INC.',
    label: 'CRUMLY & ASSOCIATES INC.',
  },
  {
    cik: '0001849834',
    value: '0001849,CRV, LLC4',
    name: 'CRV, LLC',
    label: 'CRV, LLC',
  },
  {
    cik: '0001633969',
    value: '0001633969,CRYDER CAPITAL PARTNERS LLP',
    name: 'CRYDER CAPITAL PARTNERS LLP',
    label: 'CRYDER CAPITAL PARTNERS LLP',
  },
  {
    cik: '0001510668',
    value: '0001510668,CRYSTAL ROCK CAPITAL MANAGEMENT',
    name: 'CRYSTAL ROCK CAPITAL MANAGEMENT',
    label: 'CRYSTAL ROCK CAPITAL MANAGEMENT',
  },
  {
    cik: '0001483801',
    value: '0001483801,CSAT INVESTMENT ADVISORY, L.P.',
    name: 'CSAT INVESTMENT ADVISORY, L.P.',
    label: 'CSAT INVESTMENT ADVISORY, L.P.',
  },
  {
    cik: '0001715593',
    value: '0001715593,CSENGE ADVISORY GROUP',
    name: 'CSENGE ADVISORY GROUP',
    label: 'CSENGE ADVISORY GROUP',
  },
  {
    cik: '0001863523',
    value: '0001863523,CSM ADVISORS, LLC',
    name: 'CSM ADVISORS, LLC',
    label: 'CSM ADVISORS, LLC',
  },
  {
    cik: '0000728575',
    value: '0000728575,CS MCKEE LP',
    name: 'CS MCKEE LP',
    label: 'CS MCKEE LP',
  },
  {
    cik: '0001103646',
    value: '000110364,CSS LLC/IL',
    name: 'CSS LLC/IL',
    label: 'CSS LLC/IL',
  },
  {
    cik: '0001534469',
    value: '0001534469,CSU PRODUCER RESOURCES, INC.',
    name: 'CSU PRODUCER RESOURCES, INC.',
    label: 'CSU PRODUCER RESOURCES, INC.',
  },
  {
    cik: '0001569550',
    value: '0001569550,CTC ALTERNATIVE STRATEGIES, LTD.',
    name: 'CTC ALTERNATIVE STRATEGIES, LTD.',
    label: 'CTC ALTERNATIVE STRATEGIES, LTD.',
  },
  {
    cik: '0001910419',
    value: '0001910419,CTC CAPITAL MANAGEMENT, LLC',
    name: 'CTC CAPITAL MANAGEMENT, LLC',
    label: 'CTC CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001445893',
    value: '000144,CTC LLC93',
    name: 'CTC LLC',
    label: 'CTC LLC',
  },
  {
    cik: '0001807092',
    value: '0001807092,CTF CAPITAL MANAGEMENT, LP',
    name: 'CTF CAPITAL MANAGEMENT, LP',
    label: 'CTF CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0001640485',
    value: '0001640485,CT FINANCIAL ADVISORS LLC',
    name: 'CT FINANCIAL ADVISORS LLC',
    label: 'CT FINANCIAL ADVISORS LLC',
  },
  {
    cik: '0001441646',
    value: '0001441,CT MASON',
    name: 'CT MASON',
    label: 'CT MASON',
  },
  {
    cik: '0001424322',
    value: '0001424322,CUBIC ASSET MANAGEMENT, LLC',
    name: 'CUBIC ASSET MANAGEMENT, LLC',
    label: 'CUBIC ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001603465',
    value: '0001603465,CUBIST SYSTEMATIC STRATEGIES, LLC',
    name: 'CUBIST SYSTEMATIC STRATEGIES, LLC',
    label: 'CUBIST SYSTEMATIC STRATEGIES, LLC',
  },
  {
    cik: '0001632098',
    value: '0001632098,CUE ADVISORY SERVICES, INC.',
    name: 'CUE ADVISORY SERVICES, INC.',
    label: 'CUE ADVISORY SERVICES, INC.',
  },
  {
    cik: '0001224890',
    value: '0001224890,CULBERTSON A N & CO INC',
    name: 'CULBERTSON A N & CO INC',
    label: 'CULBERTSON A N & CO INC',
  },
  {
    cik: '0001362535',
    value: '0001362535,CULLEN CAPITAL MANAGEMENT, LLC',
    name: 'CULLEN CAPITAL MANAGEMENT, LLC',
    label: 'CULLEN CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0000039263',
    value: '0000039263,CULLEN/FROST BANKERS, INC.',
    name: 'CULLEN/FROST BANKERS, INC.',
    label: 'CULLEN/FROST BANKERS, INC.',
  },
  {
    cik: '0001161670',
    value: '0001161670,CULLINAN ASSOCIATES INC',
    name: 'CULLINAN ASSOCIATES INC',
    label: 'CULLINAN ASSOCIATES INC',
  },
  {
    cik: '0001255435',
    value: '0001255435,CUMBERLAND ADVISORS INC',
    name: 'CUMBERLAND ADVISORS INC',
    label: 'CUMBERLAND ADVISORS INC',
  },
  {
    cik: '0000351262',
    value: '0000351262,CUMBERLAND ASSOCIATES LLC',
    name: 'CUMBERLAND ASSOCIATES LLC',
    label: 'CUMBERLAND ASSOCIATES LLC',
  },
  {
    cik: '0001578985',
    value: '0001578985,CUMBERLAND PARTNERS LTD',
    name: 'CUMBERLAND PARTNERS LTD',
    label: 'CUMBERLAND PARTNERS LTD',
  },
  {
    cik: '0001571075',
    value: '0001571075,CUNNING CAPITAL PARTNERS, LP',
    name: 'CUNNING CAPITAL PARTNERS, LP',
    label: 'CUNNING CAPITAL PARTNERS, LP',
  },
  {
    cik: '0001422219',
    value: '0001422219,CUPPS CAPITAL MANAGEMENT, LLC',
    name: 'CUPPS CAPITAL MANAGEMENT, LLC',
    label: 'CUPPS CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001781284',
    value: '0001781284,CURATED WEALTH PARTNERS LLC',
    name: 'CURATED WEALTH PARTNERS LLC',
    label: 'CURATED WEALTH PARTNERS LLC',
  },
  {
    cik: '0001754072',
    value: '0001754072,CURATIVE VENTURES V LLC',
    name: 'CURATIVE VENTURES V LLC',
    label: 'CURATIVE VENTURES V LLC',
  },
  {
    cik: '0001608057',
    value: '0001608057,CURBSTONE FINANCIAL MANAGEMENT CORP',
    name: 'CURBSTONE FINANCIAL MANAGEMENT CORP',
    label: 'CURBSTONE FINANCIAL MANAGEMENT CORP',
  },
  {
    cik: '0001275431',
    value: '0001275431,CURIAN CAPITAL LLC',
    name: 'CURIAN CAPITAL LLC',
    label: 'CURIAN CAPITAL LLC',
  },
  {
    cik: '0001851184',
    value: '0001851184,CURI WEALTH MANAGEMENT, LLC',
    name: 'CURI WEALTH MANAGEMENT, LLC',
    label: 'CURI WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001843867',
    value: '0001843867,CURRAN FINANCIAL PARTNERS, LLC',
    name: 'CURRAN FINANCIAL PARTNERS, LLC',
    label: 'CURRAN FINANCIAL PARTNERS, LLC',
  },
  {
    cik: '0001664656',
    value: '0001664656,CURTIS ADVISORY GROUP, LLC',
    name: 'CURTIS ADVISORY GROUP, LLC',
    label: 'CURTIS ADVISORY GROUP, LLC',
  },
  {
    cik: '0001329375',
    value: '0001329375,CUSHING ASSET MANAGEMENT, LP',
    name: 'CUSHING ASSET MANAGEMENT, LP',
    label: 'CUSHING ASSET MANAGEMENT, LP',
  },
  {
    cik: '0001904423',
    value: '0001904423,CUSTOS FAMILY OFFICE, LLC',
    name: 'CUSTOS FAMILY OFFICE, LLC',
    label: 'CUSTOS FAMILY OFFICE, LLC',
  },
  {
    cik: '0001353098',
    value: '0001353098,CUTLER CAPITAL MANAGEMENT, LLC',
    name: 'CUTLER CAPITAL MANAGEMENT, LLC',
    label: 'CUTLER CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001534270',
    value: '0001534270,CUTLER GROUP LLC / CA',
    name: 'CUTLER GROUP LLC / CA',
    label: 'CUTLER GROUP LLC / CA',
  },
  {
    cik: '0001534270',
    value: '0001534270,CUTLER GROUP LP',
    name: 'CUTLER GROUP LP',
    label: 'CUTLER GROUP LP',
  },
  {
    cik: '0001285973',
    value: '0001285973,CUTLER INVESTMENT COUNSEL LLC',
    name: 'CUTLER INVESTMENT COUNSEL LLC',
    label: 'CUTLER INVESTMENT COUNSEL LLC',
  },
  {
    cik: '0001406995',
    value: '0001406995,CUTTER & CO BROKERAGE, INC.',
    name: 'CUTTER & CO BROKERAGE, INC.',
    label: 'CUTTER & CO BROKERAGE, INC.',
  },
  {
    cik: '0001872254',
    value: '0001872254,CV ADVISORS LLC',
    name: 'CV ADVISORS LLC',
    label: 'CV ADVISORS LLC',
  },
  {
    cik: '0001802084',
    value: '0001802084,CVA FAMILY OFFICE, LLC',
    name: 'CVA FAMILY OFFICE, LLC',
    label: 'CVA FAMILY OFFICE, LLC',
  },
  {
    cik: '0001388469',
    value: '0001388469,CVENTURES, INC.',
    name: 'CVENTURES, INC.',
    label: 'CVENTURES, INC.',
  },
  {
    cik: '0001728194',
    value: '0001728194,CVI HOLDINGS, LLC',
    name: 'CVI HOLDINGS, LLC',
    label: 'CVI HOLDINGS, LLC',
  },
  {
    cik: '0000064803',
    value: '0000064803,CVS HEALTH CORP',
    name: 'CVS HEALTH CORP',
    label: 'CVS HEALTH CORP',
  },
  {
    cik: '0001340459',
    value: '0001340459,C V STARR & CO INC',
    name: 'C V STARR & CO INC',
    label: 'C V STARR & CO INC',
  },
  {
    cik: '0001393369',
    value: '0001393369,C.V. STARR & CO., INC. TRUST',
    name: 'C.V. STARR & CO., INC. TRUST',
    label: 'C.V. STARR & CO., INC. TRUST',
  },
  {
    cik: '0001667134',
    value: '0001667134,CWA ASSET MANAGEMENT GROUP, LLC',
    name: 'CWA ASSET MANAGEMENT GROUP, LLC',
    label: 'CWA ASSET MANAGEMENT GROUP, LLC',
  },
  {
    cik: '0001598348',
    value: '0001598348,CWH CAPITAL MANAGEMENT, INC.',
    name: 'CWH CAPITAL MANAGEMENT, INC.',
    label: 'CWH CAPITAL MANAGEMENT, INC.',
  },
  {
    cik: '0001670139',
    value: '0001670139,CWM ADVISORS, LLC',
    name: 'CWM ADVISORS, LLC',
    label: 'CWM ADVISORS, LLC',
  },
  {
    cik: '0001535847',
    value: '0001535,CWM, LLC7',
    name: 'CWM, LLC',
    label: 'CWM, LLC',
  },
  {
    cik: '0001625244',
    value: '0001625244,C WORLDWIDE GROUP HOLDING A/S',
    name: 'C WORLDWIDE GROUP HOLDING A/S',
    label: 'C WORLDWIDE GROUP HOLDING A/S',
  },
  {
    cik: '0001802459',
    value: '0001802459,CWS FINANCIAL ADVISORS, LLC',
    name: 'CWS FINANCIAL ADVISORS, LLC',
    label: 'CWS FINANCIAL ADVISORS, LLC',
  },
  {
    cik: '0001790629',
    value: '0001790629,CXI ADVISORS',
    name: 'CXI ADVISORS',
    label: 'CXI ADVISORS',
  },
  {
    cik: '0001737089',
    value: '0001737089,CX INSTITUTIONAL',
    name: 'CX INSTITUTIONAL',
    label: 'CX INSTITUTIONAL',
  },
  {
    cik: '0001743636',
    value: '0001743636,CYNOSURE ADVISORS, LLC',
    name: 'CYNOSURE ADVISORS, LLC',
    label: 'CYNOSURE ADVISORS, LLC',
  },
  {
    cik: '0001763409',
    value: '0001763409,CYNOSURE MANAGEMENT, LLC',
    name: 'CYNOSURE MANAGEMENT, LLC',
    label: 'CYNOSURE MANAGEMENT, LLC',
  },
  {
    cik: '0001030618',
    value: '0001030618,CYPRESS ASSET MANAGEMENT INC/TX',
    name: 'CYPRESS ASSET MANAGEMENT INC/TX',
    label: 'CYPRESS ASSET MANAGEMENT INC/TX',
  },
  {
    cik: '0001341748',
    value: '0001341748,CYPRESS CAPITAL GROUP',
    name: 'CYPRESS CAPITAL GROUP',
    label: 'CYPRESS CAPITAL GROUP',
  },
  {
    cik: '0001729939',
    value: '0001729939,CYPRESS CAPITAL, LLC',
    name: 'CYPRESS CAPITAL, LLC',
    label: 'CYPRESS CAPITAL, LLC',
  },
  {
    cik: '0001140468',
    value: '0001140468,CYPRESS CAPITAL MANAGEMENT LLC',
    name: 'CYPRESS CAPITAL MANAGEMENT LLC',
    label: 'CYPRESS CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001633896',
    value: '0001633896,CYPRESS CAPITAL MANAGEMENT LLC (WY)',
    name: 'CYPRESS CAPITAL MANAGEMENT LLC (WY)',
    label: 'CYPRESS CAPITAL MANAGEMENT LLC (WY)',
  },
  {
    cik: '0001937021',
    value: '0001937021,CYPRESS FINANCIAL PLANNING LLC',
    name: 'CYPRESS FINANCIAL PLANNING LLC',
    label: 'CYPRESS FINANCIAL PLANNING LLC',
  },
  {
    cik: '0001081198',
    value: '0001081198,CYPRESS FUNDS LLC',
    name: 'CYPRESS FUNDS LLC',
    label: 'CYPRESS FUNDS LLC',
  },
  {
    cik: '0000925824',
    value: '0000925824,CYPRESS GROWTH FUND III LP',
    name: 'CYPRESS GROWTH FUND III LP',
    label: 'CYPRESS GROWTH FUND III LP',
  },
  {
    cik: '0001825611',
    value: '0001825611,CYPRESS POINT WEALTH MANAGEMENT, LLC',
    name: 'CYPRESS POINT WEALTH MANAGEMENT, LLC',
    label: 'CYPRESS POINT WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001461564',
    value: '0001461564,CYPRESS WEALTH ADVISORS LLC',
    name: 'CYPRESS WEALTH ADVISORS LLC',
    label: 'CYPRESS WEALTH ADVISORS LLC',
  },
  {
    cik: '0001721527',
    value: '0001721527,CYPRESS WEALTH SERVICES, LLC',
    name: 'CYPRESS WEALTH SERVICES, LLC',
    label: 'CYPRESS WEALTH SERVICES, LLC',
  },
  {
    cik: '0001166564',
    value: '0001166564,CYRUS CAPITAL PARTNERS, L.P.',
    name: 'CYRUS CAPITAL PARTNERS, L.P.',
    label: 'CYRUS CAPITAL PARTNERS, L.P.',
  },
  {
    cik: '0001919920',
    value: '0001919920,CYRUS J. LAWRENCE, LLC',
    name: 'CYRUS J. LAWRENCE, LLC',
    label: 'CYRUS J. LAWRENCE, LLC',
  },
  {
    cik: '0001908923',
    value: '0001908923,CZECH NATIONAL BANK',
    name: 'CZECH NATIONAL BANK',
    label: 'CZECH NATIONAL BANK',
  },
  {
    cik: '0001747057',
    value: '0001747057,D1 CAPITAL PARTNERS L.P.',
    name: 'D1 CAPITAL PARTNERS L.P.',
    label: 'D1 CAPITAL PARTNERS L.P.',
  },
  {
    cik: '0001080107',
    value: '0001080107,D.A. DAVIDSON & CO.',
    name: 'D.A. DAVIDSON & CO.',
    label: 'D.A. DAVIDSON & CO.',
  },
  {
    cik: '0001389933',
    value: '0001389933,DAFNA CAPITAL MANAGEMENT LLC',
    name: 'DAFNA CAPITAL MANAGEMENT LLC',
    label: 'DAFNA CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001767343',
    value: '0001767343,DAGCO, INC.',
    name: 'DAGCO, INC.',
    label: 'DAGCO, INC.',
  },
  {
    cik: '0001173460',
    value: '0001173460,DAIDO LIFE INSURANCE CO',
    name: 'DAIDO LIFE INSURANCE CO',
    label: 'DAIDO LIFE INSURANCE CO',
  },
  {
    cik: '0000948518',
    value: '0000948518,DAI ICHI LIFE INSURANCE CO., LTD.',
    name: 'DAI ICHI LIFE INSURANCE CO., LTD.',
    label: 'DAI ICHI LIFE INSURANCE CO., LTD.',
  },
  {
    cik: '0001697728',
    value: '0001697728,DAI-ICHI LIFE INSURANCE COMPANY, LTD',
    name: 'DAI-ICHI LIFE INSURANCE COMPANY, LTD',
    label: 'DAI-ICHI LIFE INSURANCE COMPANY, LTD',
  },
  {
    cik: '0000783412',
    value: '0000783412,DAILY JOURNAL CORP',
    name: 'DAILY JOURNAL CORP',
    label: 'DAILY JOURNAL CORP',
  },
  {
    cik: '0001489000',
    value: '0001489000,DAIWA CAPITAL MARKETS AMERICA INC.',
    name: 'DAIWA CAPITAL MARKETS AMERICA INC.',
    label: 'DAIWA CAPITAL MARKETS AMERICA INC.',
  },
  {
    cik: '0001435346',
    value: '0001435346,DAIWA SB INVESTMENTS LTD.',
    name: 'DAIWA SB INVESTMENTS LTD.',
    label: 'DAIWA SB INVESTMENTS LTD.',
  },
  {
    cik: '0001481045',
    value: '0001481045,DAIWA SECURITIES GROUP INC.',
    name: 'DAIWA SECURITIES GROUP INC.',
    label: 'DAIWA SECURITIES GROUP INC.',
  },
  {
    cik: '0001950556',
    value: '0001950556,DAKOTA COMMUNITY BANK & TRUST NA',
    name: 'DAKOTA COMMUNITY BANK & TRUST NA',
    label: 'DAKOTA COMMUNITY BANK & TRUST NA',
  },
  {
    cik: '0001631353',
    value: '0001631353,DAKOTA WEALTH MANAGEMENT',
    name: 'DAKOTA WEALTH MANAGEMENT',
    label: 'DAKOTA WEALTH MANAGEMENT',
  },
  {
    cik: '0001549575',
    value: '0001549575,DALAL STREET, LLC',
    name: 'DALAL STREET, LLC',
    label: 'DALAL STREET, LLC',
  },
  {
    cik: '0000866842',
    value: '0000866842,DALTON GREINER HARTMAN MAHER & CO',
    name: 'DALTON GREINER HARTMAN MAHER & CO',
    label: 'DALTON GREINER HARTMAN MAHER & CO',
  },
  {
    cik: '0001388838',
    value: '0001388838,DALTON INVESTMENTS LLC',
    name: 'DALTON INVESTMENTS LLC',
    label: 'DALTON INVESTMENTS LLC',
  },
  {
    cik: '0001427514',
    value: '0001427514,DANA INVESTMENT ADVISORS, INC.',
    name: 'DANA INVESTMENT ADVISORS, INC.',
    label: 'DANA INVESTMENT ADVISORS, INC.',
  },
  {
    cik: '0001805604',
    value: '0001805604,DANDA TROUVE INVESTMENT MANAGEMENT, INC.',
    name: 'DANDA TROUVE INVESTMENT MANAGEMENT, INC.',
    label: 'DANDA TROUVE INVESTMENT MANAGEMENT, INC.',
  },
  {
    cik: '0001613634',
    value: '0001613634,DANIEL DEVINE & CO',
    name: 'DANIEL DEVINE & CO',
    label: 'DANIEL DEVINE & CO',
  },
  {
    cik: '0001478179',
    value: '0001478179,DANIELS&TANSEY,LLP',
    name: 'DANIELS&TANSEY,LLP',
    label: 'DANIELS&TANSEY,LLP',
  },
  {
    cik: '0001801470',
    value: '0001801470,DAN L DUNCAN FOUNDATION',
    name: 'DAN L DUNCAN FOUNDATION',
    label: 'DAN L DUNCAN FOUNDATION',
  },
  {
    cik: '0001806570',
    value: '0001806570,DANTAI CAPITAL LTD',
    name: 'DANTAI CAPITAL LTD',
    label: 'DANTAI CAPITAL LTD',
  },
  {
    cik: '0001853322',
    value: '0001853322,DARK FOREST CAPITAL MANAGEMENT LP',
    name: 'DARK FOREST CAPITAL MANAGEMENT LP',
    label: 'DARK FOREST CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001854440',
    value: '0001854440,DARLINGTON PARTNERS CAPITAL MANAGEMENT, LP',
    name: 'DARLINGTON PARTNERS CAPITAL MANAGEMENT, LP',
    label: 'DARLINGTON PARTNERS CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0001659189',
    value: '0001659189,DARRELL & KING LLC',
    name: 'DARRELL & KING LLC',
    label: 'DARRELL & KING LLC',
  },
  {
    cik: '0001908169',
    value: '0001908169,DARROW COMPANY, INC.',
    name: 'DARROW COMPANY, INC.',
    label: 'DARROW COMPANY, INC.',
  },
  {
    cik: '0001609098',
    value: '0001609098,DARSANA CAPITAL PARTNERS LP',
    name: 'DARSANA CAPITAL PARTNERS LP',
    label: 'DARSANA CAPITAL PARTNERS LP',
  },
  {
    cik: '0001080389',
    value: '0001080389,DARUMA CAPITAL MANAGEMENT LLC',
    name: 'DARUMA CAPITAL MANAGEMENT LLC',
    label: 'DARUMA CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001663224',
    value: '0001663224,DARWIN ADVISORS, LLC',
    name: 'DARWIN ADVISORS, LLC',
    label: 'DARWIN ADVISORS, LLC',
  },
  {
    cik: '0001839209',
    value: '0001839209,DARWIN GLOBAL MANAGEMENT, LTD.',
    name: 'DARWIN GLOBAL MANAGEMENT, LTD.',
    label: 'DARWIN GLOBAL MANAGEMENT, LTD.',
  },
  {
    cik: '0001663224',
    value: '0001663224,DARWIN WEALTH MANAGEMENT, LLC',
    name: 'DARWIN WEALTH MANAGEMENT, LLC',
    label: 'DARWIN WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001745885',
    value: '0001745885,DASH ACQUISITIONS INC.',
    name: 'DASH ACQUISITIONS INC.',
    label: 'DASH ACQUISITIONS INC.',
  },
  {
    cik: '0001911965',
    value: '0001911965,DATA COLLECTIVE II GP, LLC',
    name: 'DATA COLLECTIVE II GP, LLC',
    label: 'DATA COLLECTIVE II GP, LLC',
  },
  {
    cik: '0001664974',
    value: '0001664974,DATA COLLECTIVE IV GP, LLC',
    name: 'DATA COLLECTIVE IV GP, LLC',
    label: 'DATA COLLECTIVE IV GP, LLC',
  },
  {
    cik: '0000887777',
    value: '0000887777,DAVENPORT & CO LLC',
    name: 'DAVENPORT & CO LLC',
    label: 'DAVENPORT & CO LLC',
  },
  {
    cik: '0001823718',
    value: '0001823718,DAVENTRY GROUP, LP',
    name: 'DAVENTRY GROUP, LP',
    label: 'DAVENTRY GROUP, LP',
  },
  {
    cik: '0001538773',
    value: '0001538773,DAVIDE LEONE & PARTNERS INVESTMENT CO LLP',
    name: 'DAVIDE LEONE & PARTNERS INVESTMENT CO LLP',
    label: 'DAVIDE LEONE & PARTNERS INVESTMENT CO LLP',
  },
  {
    cik: '0001767846',
    value: '0001767846,DAVIDE LEONE & PARTNERS INVESTMENT CO LTD.',
    name: 'DAVIDE LEONE & PARTNERS INVESTMENT CO LTD.',
    label: 'DAVIDE LEONE & PARTNERS INVESTMENT CO LTD.',
  },
  {
    cik: '0001836266',
    value: '0001836266,DAVID J YVARS GROUP',
    name: 'DAVID J YVARS GROUP',
    label: 'DAVID J YVARS GROUP',
  },
  {
    cik: '0001954126',
    value: '0001954126,DAVID KENNON INC',
    name: 'DAVID KENNON INC',
    label: 'DAVID KENNON INC',
  },
  {
    cik: '0001510809',
    value: '0001510809,DAVID R. RAHN & ASSOCIATES INC.',
    name: 'DAVID R. RAHN & ASSOCIATES INC.',
    label: 'DAVID R. RAHN & ASSOCIATES INC.',
  },
  {
    cik: '0001808748',
    value: '0001808748,DAVIDSON CAPITAL MANAGEMENT INC.',
    name: 'DAVIDSON CAPITAL MANAGEMENT INC.',
    label: 'DAVIDSON CAPITAL MANAGEMENT INC.',
  },
  {
    cik: '0001080107',
    value: '0001080107,DAVIDSON D A & CO',
    name: 'DAVIDSON D A & CO',
    label: 'DAVIDSON D A & CO',
  },
  {
    cik: '0001082482',
    value: '0001082482,DAVIDSON & GARRARD INC',
    name: 'DAVIDSON & GARRARD INC',
    label: 'DAVIDSON & GARRARD INC',
  },
  {
    cik: '0001140771',
    value: '0001140771,DAVIDSON INVESTMENT ADVISORS',
    name: 'DAVIDSON INVESTMENT ADVISORS',
    label: 'DAVIDSON INVESTMENT ADVISORS',
  },
  {
    cik: '0000937617',
    value: '0000937617,DAVIDSON KEMPNER CAPITAL MANAGEMENT LLC',
    name: 'DAVIDSON KEMPNER CAPITAL MANAGEMENT LLC',
    label: 'DAVIDSON KEMPNER CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001595082',
    value: '0001595082,DAVIDSON KEMPNER CAPITAL MANAGEMENT LP',
    name: 'DAVIDSON KEMPNER CAPITAL MANAGEMENT LP',
    label: 'DAVIDSON KEMPNER CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001133119',
    value: '0001133119,DAVIDSON TRUST CO',
    name: 'DAVIDSON TRUST CO',
    label: 'DAVIDSON TRUST CO',
  },
  {
    cik: '0001945894',
    value: '0001945894,DAVIS ASSET MANAGEMENT, L.P.',
    name: 'DAVIS ASSET MANAGEMENT, L.P.',
    label: 'DAVIS ASSET MANAGEMENT, L.P.',
  },
  {
    cik: '0001811806',
    value: '0001811806,DAVIS CAPITAL MANAGEMENT',
    name: 'DAVIS CAPITAL MANAGEMENT',
    label: 'DAVIS CAPITAL MANAGEMENT',
  },
  {
    cik: '0001583119',
    value: '0001583119,DAVIS CAPITAL PARTNERS, LLC',
    name: 'DAVIS CAPITAL PARTNERS, LLC',
    label: 'DAVIS CAPITAL PARTNERS, LLC',
  },
  {
    cik: '0001482171',
    value: '0001482171,DAVIS-REA LTD.',
    name: 'DAVIS-REA LTD.',
    label: 'DAVIS-REA LTD.',
  },
  {
    cik: '0000891287',
    value: '0000891287,DAVIS R M INC',
    name: 'DAVIS R M INC',
    label: 'DAVIS R M INC',
  },
  {
    cik: '0001036325',
    value: '0001036325,DAVIS SELECTED ADVISERS',
    name: 'DAVIS SELECTED ADVISERS',
    label: 'DAVIS SELECTED ADVISERS',
  },
  {
    cik: '0001620943',
    value: '0001620943,DAVY ASSET MANAGEMENT LTD',
    name: 'DAVY ASSET MANAGEMENT LTD',
    label: 'DAVY ASSET MANAGEMENT LTD',
  },
  {
    cik: '0001620943',
    value: '0001620943,DAVY GLOBAL FUND MANAGEMENT LTD',
    name: 'DAVY GLOBAL FUND MANAGEMENT LTD',
    label: 'DAVY GLOBAL FUND MANAGEMENT LTD',
  },
  {
    cik: '0001535677',
    value: '0001535677,DAY & ENNIS, LLC',
    name: 'DAY & ENNIS, LLC',
    label: 'DAY & ENNIS, LLC',
  },
  {
    cik: '0001956824',
    value: '0001956824,DAYMARK WEALTH PARTNERS, LLC',
    name: 'DAYMARK WEALTH PARTNERS, LLC',
    label: 'DAYMARK WEALTH PARTNERS, LLC',
  },
  {
    cik: '0001885767',
    value: '0001885767,DB FITZPATRICK & CO, INC',
    name: 'DB FITZPATRICK & CO, INC',
    label: 'DB FITZPATRICK & CO, INC',
  },
  {
    cik: '0001904822',
    value: '0001904822,DBK FINANCIAL COUNSEL, LLC',
    name: 'DBK FINANCIAL COUNSEL, LLC',
    label: 'DBK FINANCIAL COUNSEL, LLC',
  },
  {
    cik: '0001731717',
    value: '0001731717,D.B. ROOT & COMPANY, LLC',
    name: 'D.B. ROOT & COMPANY, LLC',
    label: 'D.B. ROOT & COMPANY, LLC',
  },
  {
    cik: '0001843991',
    value: '0001843991,DB WEALTH MANAGEMENT GROUP, LLC',
    name: 'DB WEALTH MANAGEMENT GROUP, LLC',
    label: 'DB WEALTH MANAGEMENT GROUP, LLC',
  },
  {
    cik: '0001576422',
    value: '0001576422,D.C. CAPITAL ADVISORS, LTD',
    name: 'D.C. CAPITAL ADVISORS, LTD',
    label: 'D.C. CAPITAL ADVISORS, LTD',
  },
  {
    cik: '0001802493',
    value: '0001802493,DCF ADVISERS, LLC',
    name: 'DCF ADVISERS, LLC',
    label: 'DCF ADVISERS, LLC',
  },
  {
    cik: '0001869028',
    value: '0001869028,DC FUNDS, LP',
    name: 'DC FUNDS, LP',
    label: 'DC FUNDS, LP',
  },
  {
    cik: '0001565951',
    value: '0001565951,DC INVESTMENTS MANAGEMENT, LLC',
    name: 'DC INVESTMENTS MANAGEMENT, LLC',
    label: 'DC INVESTMENTS MANAGEMENT, LLC',
  },
  {
    cik: '0001839498',
    value: '0001839498,DCM ADVISORS, LLC',
    name: 'DCM ADVISORS, LLC',
    label: 'DCM ADVISORS, LLC',
  },
  {
    cik: '0001570782',
    value: '0001570782,DCM HYBRID RMB FUND INTERNATIONAL, LTD.',
    name: 'DCM HYBRID RMB FUND INTERNATIONAL, LTD.',
    label: 'DCM HYBRID RMB FUND INTERNATIONAL, LTD.',
  },
  {
    cik: '0001440811',
    value: '0001440811,DCM INTERNATIONAL IV, LTD',
    name: 'DCM INTERNATIONAL IV, LTD',
    label: 'DCM INTERNATIONAL IV, LTD',
  },
  {
    cik: '0001908879',
    value: '0001908879,DCM INTERNATIONAL VI, LTD.',
    name: 'DCM INTERNATIONAL VI, LTD.',
    label: 'DCM INTERNATIONAL VI, LTD.',
  },
  {
    cik: '0001440813',
    value: '0001440813,DCM INTERNATIONAL V, LTD',
    name: 'DCM INTERNATIONAL V, LTD',
    label: 'DCM INTERNATIONAL V, LTD',
  },
  {
    cik: '0001735070',
    value: '0001735070,DCVC BIO GP, LLC',
    name: 'DCVC BIO GP, LLC',
    label: 'DCVC BIO GP, LLC',
  },
  {
    cik: '0001668343',
    value: '0001668343,DCVC OPPORTUNITY FUND II GP, LLC',
    name: 'DCVC OPPORTUNITY FUND II GP, LLC',
    label: 'DCVC OPPORTUNITY FUND II GP, LLC',
  },
  {
    cik: '0001729673',
    value: '0001729673,DDD PARTNERS, LLC',
    name: 'DDD PARTNERS, LLC',
    label: 'DDD PARTNERS, LLC',
  },
  {
    cik: '0001565951',
    value: '0001565951,DEAN CAPITAL INVESTMENTS MANAGEMENT, LLC',
    name: 'DEAN CAPITAL INVESTMENTS MANAGEMENT, LLC',
    label: 'DEAN CAPITAL INVESTMENTS MANAGEMENT, LLC',
  },
  {
    cik: '0001455468',
    value: '0001455468,DEAN CAPITAL MANAGEMENT',
    name: 'DEAN CAPITAL MANAGEMENT',
    label: 'DEAN CAPITAL MANAGEMENT',
  },
  {
    cik: '0001659346',
    value: '0001659346,DEANE RETIREMENT STRATEGIES, INC.',
    name: 'DEANE RETIREMENT STRATEGIES, INC.',
    label: 'DEANE RETIREMENT STRATEGIES, INC.',
  },
  {
    cik: '0000846797',
    value: '0000846797,DEAN INVESTMENT ASSOCIATES, LLC',
    name: 'DEAN INVESTMENT ASSOCIATES, LLC',
    label: 'DEAN INVESTMENT ASSOCIATES, LLC',
  },
  {
    cik: '0001869456',
    value: '0001869456,DEAN, JACOBSON FINANCIAL SERVICES, LLC',
    name: 'DEAN, JACOBSON FINANCIAL SERVICES, LLC',
    label: 'DEAN, JACOBSON FINANCIAL SERVICES, LLC',
  },
  {
    cik: '0001251188',
    value: '0001251188,DEARBORN CAPITAL MANAGEMENT LLC',
    name: 'DEARBORN CAPITAL MANAGEMENT LLC',
    label: 'DEARBORN CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001206792',
    value: '0001206792,DEARBORN PARTNERS LLC',
    name: 'DEARBORN PARTNERS LLC',
    label: 'DEARBORN PARTNERS LLC',
  },
  {
    cik: '0001134288',
    value: '0001134288,DE BURLO GROUP INC',
    name: 'DE BURLO GROUP INC',
    label: 'DE BURLO GROUP INC',
  },
  {
    cik: '0001426822',
    value: '0001426822,DECADE CAPITAL MANAGEMENT LLC',
    name: 'DECADE CAPITAL MANAGEMENT LLC',
    label: 'DECADE CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001505961',
    value: '0001505961,DECATUR CAPITAL MANAGEMENT, INC.',
    name: 'DECATUR CAPITAL MANAGEMENT, INC.',
    label: 'DECATUR CAPITAL MANAGEMENT, INC.',
  },
  {
    cik: '0001511702',
    value: '0001511702,DECCAN VALUE INVESTORS L.P.',
    name: 'DECCAN VALUE INVESTORS L.P.',
    label: 'DECCAN VALUE INVESTORS L.P.',
  },
  {
    cik: '0001747821',
    value: '0001747821,DECHENG CAPITAL MANAGEMENT III (CAYMAN), LLC',
    name: 'DECHENG CAPITAL MANAGEMENT III (CAYMAN), LLC',
    label: 'DECHENG CAPITAL MANAGEMENT III (CAYMAN), LLC',
  },
  {
    cik: '0001717658',
    value: '0001717658,DEDORA CAPITAL, INC.',
    name: 'DEDORA CAPITAL, INC.',
    label: 'DEDORA CAPITAL, INC.',
  },
  {
    cik: '0001719655',
    value: '0001719655,DEEP BASIN CAPITAL LP',
    name: 'DEEP BASIN CAPITAL LP',
    label: 'DEEP BASIN CAPITAL LP',
  },
  {
    cik: '0001754535',
    value: '0001754535,DEEPCURRENTS INVESTMENT GROUP LLC',
    name: 'DEEPCURRENTS INVESTMENT GROUP LLC',
    label: 'DEEPCURRENTS INVESTMENT GROUP LLC',
  },
  {
    cik: '0001675187',
    value: '0001675187,DEEP FIELD ASSET MANAGEMENT LLC',
    name: 'DEEP FIELD ASSET MANAGEMENT LLC',
    label: 'DEEP FIELD ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001856083',
    value: '0001856083,DEEP TRACK CAPITAL, LP',
    name: 'DEEP TRACK CAPITAL, LP',
    label: 'DEEP TRACK CAPITAL, LP',
  },
  {
    cik: '0000315189',
    value: '000031518,DEERE & CO',
    name: 'DEERE & CO',
    label: 'DEERE & CO',
  },
  {
    cik: '0001009258',
    value: '0001009258,DEERFIELD MANAGEMENT CO',
    name: 'DEERFIELD MANAGEMENT CO',
    label: 'DEERFIELD MANAGEMENT CO',
  },
  {
    cik: '0001009258',
    value: '0001009258,DEERFIELD MANAGEMENT COMPANY, L.P. (SERIES C)',
    name: 'DEERFIELD MANAGEMENT COMPANY, L.P. (SERIES C)',
    label: 'DEERFIELD MANAGEMENT COMPANY, L.P. (SERIES C)',
  },
  {
    cik: '0001655220',
    value: '0001655220,DEER IX & CO. LTD.',
    name: 'DEER IX & CO. LTD.',
    label: 'DEER IX & CO. LTD.',
  },
  {
    cik: '0001494127',
    value: '0001494127,DEER MANAGEMENT CO. LLC',
    name: 'DEER MANAGEMENT CO. LLC',
    label: 'DEER MANAGEMENT CO. LLC',
  },
  {
    cik: '0001702490',
    value: '0001702490,DEER PARK ROAD CORP',
    name: 'DEER PARK ROAD CORP',
    label: 'DEER PARK ROAD CORP',
  },
  {
    cik: '0001362890',
    value: '0001362890,DEER VI & CO. LLC',
    name: 'DEER VI & CO. LLC',
    label: 'DEER VI & CO. LLC',
  },
  {
    cik: '0001540726',
    value: '0001540726,DEER VII & CO. LTD.',
    name: 'DEER VII & CO. LTD.',
    label: 'DEER VII & CO. LTD.',
  },
  {
    cik: '0001559311',
    value: '0001559311,DEER VIII & CO. LTD.',
    name: 'DEER VIII & CO. LTD.',
    label: 'DEER VIII & CO. LTD.',
  },
  {
    cik: '0001766929',
    value: '0001766929,DEFENDER CAPITAL, LLC.',
    name: 'DEFENDER CAPITAL, LLC.',
    label: 'DEFENDER CAPITAL, LLC.',
  },
  {
    cik: '0001849518',
    value: '0001849518,DEFINED WEALTH MANAGEMENT, LLC',
    name: 'DEFINED WEALTH MANAGEMENT, LLC',
    label: 'DEFINED WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001536333',
    value: '0001536333,DEGREEN CAPITAL MANAGEMENT LLC',
    name: 'DEGREEN CAPITAL MANAGEMENT LLC',
    label: 'DEGREEN CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001665586',
    value: '0001665586,DEIMOS ASSET MANAGEMENT LLC',
    name: 'DEIMOS ASSET MANAGEMENT LLC',
    label: 'DEIMOS ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001456228',
    value: '0001456228,DEKABANK DEUTSCHE GIROZENTRALE',
    name: 'DEKABANK DEUTSCHE GIROZENTRALE',
    label: 'DEKABANK DEUTSCHE GIROZENTRALE',
  },
  {
    cik: '0001661536',
    value: '0001661536,DELANEY DENNIS R',
    name: 'DELANEY DENNIS R',
    label: 'DELANEY DENNIS R',
  },
  {
    cik: '0001361371',
    value: '0001361371,DELEK GROUP, LTD.',
    name: 'DELEK GROUP, LTD.',
    label: 'DELEK GROUP, LTD.',
  },
  {
    cik: '0001350639',
    value: '0001350639,DEL MAR ASSET MANAGEMENT, LP',
    name: 'DEL MAR ASSET MANAGEMENT, LP',
    label: 'DEL MAR ASSET MANAGEMENT, LP',
  },
  {
    cik: '0001878326',
    value: '0001878326,DELOS WEALTH ADVISORS, LLC',
    name: 'DELOS WEALTH ADVISORS, LLC',
    label: 'DELOS WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001592180',
    value: '0001592180,DELPHA CAPITAL MANAGEMENT, LLC',
    name: 'DELPHA CAPITAL MANAGEMENT, LLC',
    label: 'DELPHA CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001901146',
    value: '0001901146,DELPHIA (USA) INC.',
    name: 'DELPHIA (USA) INC.',
    label: 'DELPHIA (USA) INC.',
  },
  {
    cik: '0000859139',
    value: '0000859139,DELPHI FINANCIAL GROUP, INC.',
    name: 'DELPHI FINANCIAL GROUP, INC.',
    label: 'DELPHI FINANCIAL GROUP, INC.',
  },
  {
    cik: '0000859139',
    value: '0000859139,DELPHI FINANCIAL GROUP INC/DE',
    name: 'DELPHI FINANCIAL GROUP INC/DE',
    label: 'DELPHI FINANCIAL GROUP INC/DE',
  },
  {
    cik: '0000767684',
    value: '0000767684,DELPHI MANAGEMENT INC /MA/',
    name: 'DELPHI MANAGEMENT INC /MA/',
    label: 'DELPHI MANAGEMENT INC /MA/',
  },
  {
    cik: '0001457494',
    value: '0001457494,DELPHI MANAGEMENT PARTNERS VIII, L.L.C.',
    name: 'DELPHI MANAGEMENT PARTNERS VIII, L.L.C.',
    label: 'DELPHI MANAGEMENT PARTNERS VIII, L.L.C.',
  },
  {
    cik: '0001681373',
    value: '0001681373,DELPHI PRIVATE ADVISORS LLC',
    name: 'DELPHI PRIVATE ADVISORS LLC',
    label: 'DELPHI PRIVATE ADVISORS LLC',
  },
  {
    cik: '0001497758',
    value: '0001497758,DEL REY GLOBAL INVESTORS, LLC',
    name: 'DEL REY GLOBAL INVESTORS, LLC',
    label: 'DEL REY GLOBAL INVESTORS, LLC',
  },
  {
    cik: '0001610580',
    value: '0001610580,DEL-SETTE CAPITAL MANAGEMENT, LLC',
    name: 'DEL-SETTE CAPITAL MANAGEMENT, LLC',
    label: 'DEL-SETTE CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001808389',
    value: '0001808389,DELTA ACCUMULATION, LLC',
    name: 'DELTA ACCUMULATION, LLC',
    label: 'DELTA ACCUMULATION, LLC',
  },
  {
    cik: '0000027904',
    value: '0000027904,DELTA AIR LINES, INC.',
    name: 'DELTA AIR LINES, INC.',
    label: 'DELTA AIR LINES, INC.',
  },
  {
    cik: '0000860857',
    value: '0000860857,DELTA ASSET MANAGEMENT LLC/TN',
    name: 'DELTA ASSET MANAGEMENT LLC/TN',
    label: 'DELTA ASSET MANAGEMENT LLC/TN',
  },
  {
    cik: '0000893738',
    value: '0000893738,DELTA CAPITAL MANAGEMENT LLC',
    name: 'DELTA CAPITAL MANAGEMENT LLC',
    label: 'DELTA CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001552918',
    value: '0001552918,DELTA DENTAL PLAN OF MICHIGAN, INC.',
    name: 'DELTA DENTAL PLAN OF MICHIGAN, INC.',
    label: 'DELTA DENTAL PLAN OF MICHIGAN, INC.',
  },
  {
    cik: '0001790295',
    value: '0001790295,DELTA FINANCIAL ADVISORS LLC',
    name: 'DELTA FINANCIAL ADVISORS LLC',
    label: 'DELTA FINANCIAL ADVISORS LLC',
  },
  {
    cik: '0001757043',
    value: '0001757043,DELTA INVESTMENT MANAGEMENT, LLC',
    name: 'DELTA INVESTMENT MANAGEMENT, LLC',
    label: 'DELTA INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001547720',
    value: '0001547720,DELTA LLOYD ASSET MANAGEMENT N.V.',
    name: 'DELTA LLOYD ASSET MANAGEMENT N.V.',
    label: 'DELTA LLOYD ASSET MANAGEMENT N.V.',
  },
  {
    cik: '0001145935',
    value: '0001145935,DELTA LLOYD NV',
    name: 'DELTA LLOYD NV',
    label: 'DELTA LLOYD NV',
  },
  {
    cik: '0001145839',
    value: '0001145839,DELTA PARTNERS LLC',
    name: 'DELTA PARTNERS LLC',
    label: 'DELTA PARTNERS LLC',
  },
  {
    cik: '0001269950',
    value: '0001269950,DELTEC ASSET MANAGEMENT LLC',
    name: 'DELTEC ASSET MANAGEMENT LLC',
    label: 'DELTEC ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001723115',
    value: '0001723115,DEMARS FINANCIAL GROUP, LLC',
    name: 'DEMARS FINANCIAL GROUP, LLC',
    label: 'DEMARS FINANCIAL GROUP, LLC',
  },
  {
    cik: '0001592614',
    value: '0001592614,DEMPZE NANCY E',
    name: 'DEMPZE NANCY E',
    label: 'DEMPZE NANCY E',
  },
  {
    cik: '0001142941',
    value: '0001142941,DENALI ADVISORS LLC',
    name: 'DENALI ADVISORS LLC',
    label: 'DENALI ADVISORS LLC',
  },
  {
    cik: '0001782124',
    value: '0001782124,DENDUR CAPITAL LP',
    name: 'DENDUR CAPITAL LP',
    label: 'DENDUR CAPITAL LP',
  },
  {
    cik: '0001867570',
    value: '0001867570,DENTGROUP LLC',
    name: 'DENTGROUP LLC',
    label: 'DENTGROUP LLC',
  },
  {
    cik: '0000943656',
    value: '0000943656,DENVER INVESTMENT ADVISORS LLC',
    name: 'DENVER INVESTMENT ADVISORS LLC',
    label: 'DENVER INVESTMENT ADVISORS LLC',
  },
  {
    cik: '0001909800',
    value: '0001909800,DENVER WEALTH MANAGEMENT, INC.',
    name: 'DENVER WEALTH MANAGEMENT, INC.',
    label: 'DENVER WEALTH MANAGEMENT, INC.',
  },
  {
    cik: '0001008894',
    value: '0001008894,DEPRINCE RACE & ZOLLO INC',
    name: 'DEPRINCE RACE & ZOLLO INC',
    label: 'DEPRINCE RACE & ZOLLO INC',
  },
  {
    cik: '0001874068',
    value: '0001874068,DERBEND ASSET MANAGEMENT',
    name: 'DERBEND ASSET MANAGEMENT',
    label: 'DERBEND ASSET MANAGEMENT',
  },
  {
    cik: '0001086611',
    value: '0001086611,DERBY & COMPANY, INC',
    name: 'DERBY & COMPANY, INC',
    label: 'DERBY & COMPANY, INC',
  },
  {
    cik: '0001278678',
    value: '0001278678,DEROY & DEVEREAUX PRIVATE INVESTMENT COUNSEL INC',
    name: 'DEROY & DEVEREAUX PRIVATE INVESTMENT COUNSEL INC',
    label: 'DEROY & DEVEREAUX PRIVATE INVESTMENT COUNSEL INC',
  },
  {
    cik: '0001632692',
    value: '0001632692,DESCHAINE & COMPANY, LLC',
    name: 'DESCHAINE & COMPANY, LLC',
    label: 'DESCHAINE & COMPANY, LLC',
  },
  {
    cik: '0001668256',
    value: '0001668256,DESCHUTES PORTFOLIO STRATEGY, LLC',
    name: 'DESCHUTES PORTFOLIO STRATEGY, LLC',
    label: 'DESCHUTES PORTFOLIO STRATEGY, LLC',
  },
  {
    cik: '0001789351',
    value: '0001789351,DESERET MUTUAL BENEFIT ADMINISTRATORS',
    name: 'DESERET MUTUAL BENEFIT ADMINISTRATORS',
    label: 'DESERET MUTUAL BENEFIT ADMINISTRATORS',
  },
  {
    cik: '0001009207',
    value: '0001009207,D. E. SHAW & CO., INC.',
    name: 'D. E. SHAW & CO., INC.',
    label: 'D. E. SHAW & CO., INC.',
  },
  {
    cik: '0001563660',
    value: '0001563660,DESJARDINS GLOBAL ASSET MANAGEMENT INC.',
    name: 'DESJARDINS GLOBAL ASSET MANAGEMENT INC.',
    label: 'DESJARDINS GLOBAL ASSET MANAGEMENT INC.',
  },
  {
    cik: '0001278573',
    value: '0001278573,DESTINATION WEALTH MANAGEMENT',
    name: 'DESTINATION WEALTH MANAGEMENT',
    label: 'DESTINATION WEALTH MANAGEMENT',
  },
  {
    cik: '0001844369',
    value: '0001844369,DESTINY WEALTH PARTNERS, LLC',
    name: 'DESTINY WEALTH PARTNERS, LLC',
    label: 'DESTINY WEALTH PARTNERS, LLC',
  },
  {
    cik: '0001590721',
    value: '0001590721,DESTRIER CAPITAL MANAGEMENT LLC',
    name: 'DESTRIER CAPITAL MANAGEMENT LLC',
    label: 'DESTRIER CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001799677',
    value: '0001799677,DETALUS ADVISORS, LLC',
    name: 'DETALUS ADVISORS, LLC',
    label: 'DETALUS ADVISORS, LLC',
  },
  {
    cik: '0001591663',
    value: '0001591663,DETWILER FENTON INVESTMENT MANAGEMENT LLC',
    name: 'DETWILER FENTON INVESTMENT MANAGEMENT LLC',
    label: 'DETWILER FENTON INVESTMENT MANAGEMENT LLC',
  },
  {
    cik: '0001860487',
    value: '0001860487,DEUTERIUM CAPITAL MANAGEMENT, LLC',
    name: 'DEUTERIUM CAPITAL MANAGEMENT, LLC',
    label: 'DEUTERIUM CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0000948046',
    value: '0000948046,DEUTSCHE BANK AG\\',
    name: 'DEUTSCHE BANK AG\\',
    label: 'DEUTSCHE BANK AG\\',
  },
  {
    cik: '0001610920',
    value: '0001610920,DEVON PARK ASSOCIATES, LLC',
    name: 'DEVON PARK ASSOCIATES, LLC',
    label: 'DEVON PARK ASSOCIATES, LLC',
  },
  {
    cik: '0000934999',
    value: '0000934999,DF DENT & CO INC',
    name: 'DF DENT & CO INC',
    label: 'DF DENT & CO INC',
  },
  {
    cik: '0001504665',
    value: '0001504665,DFPG INVESTMENTS, INC.',
    name: 'DFPG INVESTMENTS, INC.',
    label: 'DFPG INVESTMENTS, INC.',
  },
  {
    cik: '0001504665',
    value: '0001504665,DFPG INVESTMENTS, LLC',
    name: 'DFPG INVESTMENTS, LLC',
    label: 'DFPG INVESTMENTS, LLC',
  },
  {
    cik: '0001688235',
    value: '0001688235,DFT ENERGY LP',
    name: 'DFT ENERGY LP',
    label: 'DFT ENERGY LP',
  },
  {
    cik: '0001123798',
    value: '0001123798,DG CAPITAL MANAGEMENT INC',
    name: 'DG CAPITAL MANAGEMENT INC',
    label: 'DG CAPITAL MANAGEMENT INC',
  },
  {
    cik: '0001512716',
    value: '0001512716,DG CAPITAL MANAGEMENT, LLC',
    name: 'DG CAPITAL MANAGEMENT, LLC',
    label: 'DG CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001549497',
    value: '0001549497,DGH INVESTMENTS INC.',
    name: 'DGH INVESTMENTS INC.',
    label: 'DGH INVESTMENTS INC.',
  },
  {
    cik: '0001900110',
    value: '0001900110,DGS CAPITAL MANAGEMENT, LLC',
    name: 'DGS CAPITAL MANAGEMENT, LLC',
    label: 'DGS CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001844716',
    value: '0001844716,DHJJ FINANCIAL ADVISORS, LTD.',
    name: 'DHJJ FINANCIAL ADVISORS, LTD.',
    label: 'DHJJ FINANCIAL ADVISORS, LTD.',
  },
  {
    cik: '0001922962',
    value: '0001922962,DHK FINANCIAL ADVISORS, INC.',
    name: 'DHK FINANCIAL ADVISORS, INC.',
    label: 'DHK FINANCIAL ADVISORS, INC.',
  },
  {
    cik: '0001687807',
    value: '0001687807,DIAG CAPITAL MANAGEMENT, LP',
    name: 'DIAG CAPITAL MANAGEMENT, LP',
    label: 'DIAG CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0001411512',
    value: '0001411512,DIALECTIC CAPITAL MANAGEMENT, LLC',
    name: 'DIALECTIC CAPITAL MANAGEMENT, LLC',
    label: 'DIALECTIC CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001731124',
    value: '0001731124,DIAMANT ASSET MANAGEMENT, INC.',
    name: 'DIAMANT ASSET MANAGEMENT, INC.',
    label: 'DIAMANT ASSET MANAGEMENT, INC.',
  },
  {
    cik: '0001068855',
    value: '0001068855,DIAM CO., LTD.',
    name: 'DIAM CO., LTD.',
    label: 'DIAM CO., LTD.',
  },
  {
    cik: '0001727012',
    value: '0001727012,DIAMETER CAPITAL PARTNERS LP',
    name: 'DIAMETER CAPITAL PARTNERS LP',
    label: 'DIAMETER CAPITAL PARTNERS LP',
  },
  {
    cik: '0001719087',
    value: '0001719087,DIAMETRIC CAPITAL, LP',
    name: 'DIAMETRIC CAPITAL, LP',
    label: 'DIAMETRIC CAPITAL, LP',
  },
  {
    cik: '0001217541',
    value: '0001217541,DIAMOND HILL CAPITAL MANAGEMENT INC',
    name: 'DIAMOND HILL CAPITAL MANAGEMENT INC',
    label: 'DIAMOND HILL CAPITAL MANAGEMENT INC',
  },
  {
    cik: '0001633391',
    value: '0001633391,DIANA DAVIS SPENCER FOUNDATION',
    name: 'DIANA DAVIS SPENCER FOUNDATION',
    label: 'DIANA DAVIS SPENCER FOUNDATION',
  },
  {
    cik: '0001552332',
    value: '0001552332,DICKERMAN RONALD',
    name: 'DICKERMAN RONALD',
    label: 'DICKERMAN RONALD',
  },
  {
    cik: '0001879240',
    value: '0001879240,DIFESA CAPITAL MANAGEMENT, LP',
    name: 'DIFESA CAPITAL MANAGEMENT, LP',
    label: 'DIFESA CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0001679688',
    value: '0001679688,DIGITALBRIDGE GROUP, INC.',
    name: 'DIGITALBRIDGE GROUP, INC.',
    label: 'DIGITALBRIDGE GROUP, INC.',
  },
  {
    cik: '0001276525',
    value: '0001276525,DIKER MANAGEMENT LLC',
    name: 'DIKER MANAGEMENT LLC',
    label: 'DIKER MANAGEMENT LLC',
  },
  {
    cik: '0001549042',
    value: '0001549042,DILIGENT INVESTORS, LLC',
    name: 'DILIGENT INVESTORS, LLC',
    label: 'DILIGENT INVESTORS, LLC',
  },
  {
    cik: '0001054425',
    value: '0001054425,DILLON & ASSOCIATES INC',
    name: 'DILLON & ASSOCIATES INC',
    label: 'DILLON & ASSOCIATES INC',
  },
  {
    cik: '0000354204',
    value: '0000354204,DIMENSIONAL FUND ADVISORS LP',
    name: 'DIMENSIONAL FUND ADVISORS LP',
    label: 'DIMENSIONAL FUND ADVISORS LP',
  },
  {
    cik: '0001041773',
    value: '0001041773,DIMENSION CAPITAL MANAGEMENT LLC',
    name: 'DIMENSION CAPITAL MANAGEMENT LLC',
    label: 'DIMENSION CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001729866',
    value: '0001729866,DIMEO SCHNEIDER & ASSOCIATES, L.L.C.',
    name: 'DIMEO SCHNEIDER & ASSOCIATES, L.L.C.',
    label: 'DIMEO SCHNEIDER & ASSOCIATES, L.L.C.',
  },
  {
    cik: '0001014776',
    value: '0001014776,DINSMORE CAPITAL MANAGEMENT CO/NJ',
    name: 'DINSMORE CAPITAL MANAGEMENT CO/NJ',
    label: 'DINSMORE CAPITAL MANAGEMENT CO/NJ',
  },
  {
    cik: '0001632958',
    value: '0001632958,DISCERENE GROUP LP',
    name: 'DISCERENE GROUP LP',
    label: 'DISCERENE GROUP LP',
  },
  {
    cik: '0001909380',
    value: '0001909380,DISCIPLINED EQUITY MANAGEMENT, INC.',
    name: 'DISCIPLINED EQUITY MANAGEMENT, INC.',
    label: 'DISCIPLINED EQUITY MANAGEMENT, INC.',
  },
  {
    cik: '0001050442',
    value: '0001050442,DISCIPLINED GROWTH INVESTORS INC /MN',
    name: 'DISCIPLINED GROWTH INVESTORS INC /MN',
    label: 'DISCIPLINED GROWTH INVESTORS INC /MN',
  },
  {
    cik: '0001812492',
    value: '0001812492,DISCIPLINED INVESTMENTS, LLC',
    name: 'DISCIPLINED INVESTMENTS, LLC',
    label: 'DISCIPLINED INVESTMENTS, LLC',
  },
  {
    cik: '0001389507',
    value: '0001389507,DISCOVERY CAPITAL MANAGEMENT, LLC / CT',
    name: 'DISCOVERY CAPITAL MANAGEMENT, LLC / CT',
    label: 'DISCOVERY CAPITAL MANAGEMENT, LLC / CT',
  },
  {
    cik: '0001312548',
    value: '0001312548,DISCOVERY GROUP I, LLC',
    name: 'DISCOVERY GROUP I, LLC',
    label: 'DISCOVERY GROUP I, LLC',
  },
  {
    cik: '0001916885',
    value: '0001916885,DISCOVERY INVESTMENT FUND L.P.',
    name: 'DISCOVERY INVESTMENT FUND L.P.',
    label: 'DISCOVERY INVESTMENT FUND L.P.',
  },
  {
    cik: '0001750451',
    value: '0001750451,DISCOVERY VALUE FUND',
    name: 'DISCOVERY VALUE FUND',
    label: 'DISCOVERY VALUE FUND',
  },
  {
    cik: '0001461133',
    value: '0001461133,DISCRETIONARY MANAGEMENT SERVICES, LLC',
    name: 'DISCRETIONARY MANAGEMENT SERVICES, LLC',
    label: 'DISCRETIONARY MANAGEMENT SERVICES, LLC',
  },
  {
    cik: '0001828550',
    value: '0001828550,DISRUPTIVE TECHNOLOGY ADVISERS LLC',
    name: 'DISRUPTIVE TECHNOLOGY ADVISERS LLC',
    label: 'DISRUPTIVE TECHNOLOGY ADVISERS LLC',
  },
  {
    cik: '0001741001',
    value: '0001741001,DISTILLATE CAPITAL PARTNERS LLC',
    name: 'DISTILLATE CAPITAL PARTNERS LLC',
    label: 'DISTILLATE CAPITAL PARTNERS LLC',
  },
  {
    cik: '0001730787',
    value: '0001730787,DIVERGENT PLANNING, LLC',
    name: 'DIVERGENT PLANNING, LLC',
    label: 'DIVERGENT PLANNING, LLC',
  },
  {
    cik: '0001731275',
    value: '0001731275,DIVERGENT WEALTH ADVISORS',
    name: 'DIVERGENT WEALTH ADVISORS',
    label: 'DIVERGENT WEALTH ADVISORS',
  },
  {
    cik: '0001427196',
    value: '0001427196,DIVERSIFIED INVESTMENT STRATEGIES, LLC',
    name: 'DIVERSIFIED INVESTMENT STRATEGIES, LLC',
    label: 'DIVERSIFIED INVESTMENT STRATEGIES, LLC',
  },
  {
    cik: '0001841991',
    value: '0001841991,DIVERSIFIED, LLC',
    name: 'DIVERSIFIED, LLC',
    label: 'DIVERSIFIED, LLC',
  },
  {
    cik: '0001634212',
    value: '0001634212,DIVERSIFIED PORTFOLIOS, INC.',
    name: 'DIVERSIFIED PORTFOLIOS, INC.',
    label: 'DIVERSIFIED PORTFOLIOS, INC.',
  },
  {
    cik: '0001479844',
    value: '0001479844,DIVERSIFIED TRUST CO',
    name: 'DIVERSIFIED TRUST CO',
    label: 'DIVERSIFIED TRUST CO',
  },
  {
    cik: '0001365167',
    value: '0001365167,DIVIDEND ASSET CAPITAL, LLC',
    name: 'DIVIDEND ASSET CAPITAL, LLC',
    label: 'DIVIDEND ASSET CAPITAL, LLC',
  },
  {
    cik: '0001365167',
    value: '0001365167,DIVIDEND ASSETS CAPITAL, LLC',
    name: 'DIVIDEND ASSETS CAPITAL, LLC',
    label: 'DIVIDEND ASSETS CAPITAL, LLC',
  },
  {
    cik: '0001901865',
    value: '0001901865,DIVISADERO STREET CAPITAL MANAGEMENT, LP',
    name: 'DIVISADERO STREET CAPITAL MANAGEMENT, LP',
    label: 'DIVISADERO STREET CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0001645721',
    value: '0001645721,DIVISAR CAPITAL MANAGEMENT LLC',
    name: 'DIVISAR CAPITAL MANAGEMENT LLC',
    label: 'DIVISAR CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001909993',
    value: '0001909993,DIXON FNANCIAL SERVICES, INC.',
    name: 'DIXON FNANCIAL SERVICES, INC.',
    label: 'DIXON FNANCIAL SERVICES, INC.',
  },
  {
    cik: '0001014306',
    value: '0001014306,DIXON HUBARD FEINOUR & BROWN INC/VA',
    name: 'DIXON HUBARD FEINOUR & BROWN INC/VA',
    label: 'DIXON HUBARD FEINOUR & BROWN INC/VA',
  },
  {
    cik: '0001906545',
    value: '0001906545,DIXON HUGHES GOODMAN WEALTH ADVISORS LLC',
    name: 'DIXON HUGHES GOODMAN WEALTH ADVISORS LLC',
    label: 'DIXON HUGHES GOODMAN WEALTH ADVISORS LLC',
  },
  {
    cik: '0001021117',
    value: '0001021117,D L CARLSON INVESTMENT GROUP INC',
    name: 'D L CARLSON INVESTMENT GROUP INC',
    label: 'D L CARLSON INVESTMENT GROUP INC',
  },
  {
    cik: '0001578299',
    value: '0001578299,DLD ASSET MANAGEMENT, LP',
    name: 'DLD ASSET MANAGEMENT, LP',
    label: 'DLD ASSET MANAGEMENT, LP',
  },
  {
    cik: '0001423116',
    value: '0001423116,DLS CAPITAL MANAGEMENT, LLC',
    name: 'DLS CAPITAL MANAGEMENT, LLC',
    label: 'DLS CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001893403',
    value: '0001893403,DMG GROUP, LLC',
    name: 'DMG GROUP, LLC',
    label: 'DMG GROUP, LLC',
  },
  {
    cik: '0001456670',
    value: '0001456670,DNB ASSET MANAGEMENT AS',
    name: 'DNB ASSET MANAGEMENT AS',
    label: 'DNB ASSET MANAGEMENT AS',
  },
  {
    cik: '0001172779',
    value: '0001172779,DOCK STREET ASSET MANAGEMENT INC',
    name: 'DOCK STREET ASSET MANAGEMENT INC',
    label: 'DOCK STREET ASSET MANAGEMENT INC',
  },
  {
    cik: '0001807215',
    value: '0001807215,DOCKYARD CAPITAL MANAGEMENT LP',
    name: 'DOCKYARD CAPITAL MANAGEMENT LP',
    label: 'DOCKYARD CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0000200217',
    value: '0000200217,DODGE & COX',
    name: 'DODGE & COX',
    label: 'DODGE & COX',
  },
  {
    cik: '0000936936',
    value: '0000936936,DOHENY ASSET MANAGEMENT /CA',
    name: 'DOHENY ASSET MANAGEMENT /CA',
    label: 'DOHENY ASSET MANAGEMENT /CA',
  },
  {
    cik: '0001801881',
    value: '00018018,DOHJ, LLC',
    name: 'DOHJ, LLC',
    label: 'DOHJ, LLC',
  },
  {
    cik: '0000938077',
    value: '0000938077,DOLIVER ADVISORS, LP',
    name: 'DOLIVER ADVISORS, LP',
    label: 'DOLIVER ADVISORS, LP',
  },
  {
    cik: '0001913771',
    value: '0001913771,DOMAN GROUP, LLC',
    name: 'DOMAN GROUP, LLC',
    label: 'DOMAN GROUP, LLC',
  },
  {
    cik: '0001634553',
    value: '0001634553,DOMANI WEALTH, LLC',
    name: 'DOMANI WEALTH, LLC',
    label: 'DOMANI WEALTH, LLC',
  },
  {
    cik: '0001453102',
    value: '0001453102,DOMINICK & DOMINICK LLC',
    name: 'DOMINICK & DOMINICK LLC',
    label: 'DOMINICK & DOMINICK LLC',
  },
  {
    cik: '0001399069',
    value: '0001399069,DOMINI IMPACT INVESTMENTS LLC',
    name: 'DOMINI IMPACT INVESTMENTS LLC',
    label: 'DOMINI IMPACT INVESTMENTS LLC',
  },
  {
    cik: '0001638049',
    value: '0001638049,DONALD L. HAGAN, LLC',
    name: 'DONALD L. HAGAN, LLC',
    label: 'DONALD L. HAGAN, LLC',
  },
  {
    cik: '0000814375',
    value: '0000814375,DONALD SMITH & CO., INC.',
    name: 'DONALD SMITH & CO., INC.',
    label: 'DONALD SMITH & CO., INC.',
  },
  {
    cik: '0001487438',
    value: '0001487438,DONALDSON CAPITAL MANAGEMENT, LLC',
    name: 'DONALDSON CAPITAL MANAGEMENT, LLC',
    label: 'DONALDSON CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001702435',
    value: '0001702435,DONOGHUE FORLINES LLC',
    name: 'DONOGHUE FORLINES LLC',
    label: 'DONOGHUE FORLINES LLC',
  },
  {
    cik: '0001110443',
    value: '0001110443,DOOLITTLE & GANOS INVESTMENT COUNSEL, LLC',
    name: 'DOOLITTLE & GANOS INVESTMENT COUNSEL, LLC',
    label: 'DOOLITTLE & GANOS INVESTMENT COUNSEL, LLC',
  },
  {
    cik: '0001904152',
    value: '0001904152,DOPKINS WEALTH MANAGEMENT, LLC',
    name: 'DOPKINS WEALTH MANAGEMENT, LLC',
    label: 'DOPKINS WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001646821',
    value: '0001646821,DORAZIO & ASSOCIATES, INC.',
    name: "D'ORAZIO & ASSOCIATES, INC.",
    label: "D'ORAZIO & ASSOCIATES, INC.",
  },
  {
    cik: '0001649888',
    value: '0001649888,DORCHESTER WEALTH MANAGEMENT CO',
    name: 'DORCHESTER WEALTH MANAGEMENT CO',
    label: 'DORCHESTER WEALTH MANAGEMENT CO',
  },
  {
    cik: '0001547007',
    value: '0001547007,DORSAL CAPITAL MANAGEMENT, LLC',
    name: 'DORSAL CAPITAL MANAGEMENT, LLC',
    label: 'DORSAL CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001671657',
    value: '0001671657,DORSEY ASSET MANAGEMENT, LLC',
    name: 'DORSEY ASSET MANAGEMENT, LLC',
    label: 'DORSEY ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001384042',
    value: '0001384042,DORSEY & WHITNEY TRUST CO LLC',
    name: 'DORSEY & WHITNEY TRUST CO LLC',
    label: 'DORSEY & WHITNEY TRUST CO LLC',
  },
  {
    cik: '0001376772',
    value: '0001376772,DORSEY WRIGHT & ASSOCIATES',
    name: 'DORSEY WRIGHT & ASSOCIATES',
    label: 'DORSEY WRIGHT & ASSOCIATES',
  },
  {
    cik: '0001755933',
    value: '0001755933,DOUGHERTY WEALTH ADVISERS LLC',
    name: 'DOUGHERTY WEALTH ADVISERS LLC',
    label: 'DOUGHERTY WEALTH ADVISERS LLC',
  },
  {
    cik: '0001004248',
    value: '0001004248,DOUGLAS C. LANE & ASSOCIATES, INC.',
    name: 'DOUGLAS C. LANE & ASSOCIATES, INC.',
    label: 'DOUGLAS C. LANE & ASSOCIATES, INC.',
  },
  {
    cik: '0001672594',
    value: '0001672594,DOUGLAS LANE & ASSOCIATES, LLC',
    name: 'DOUGLAS LANE & ASSOCIATES, LLC',
    label: 'DOUGLAS LANE & ASSOCIATES, LLC',
  },
  {
    cik: '0001308527',
    value: '0001308527,DOUGLASS WINTHROP ADVISORS, LLC',
    name: 'DOUGLASS WINTHROP ADVISORS, LLC',
    label: 'DOUGLASS WINTHROP ADVISORS, LLC',
  },
  {
    cik: '0000029915',
    value: '0000029915,DOW CHEMICAL CO /DE/',
    name: 'DOW CHEMICAL CO /DE/',
    label: 'DOW CHEMICAL CO /DE/',
  },
  {
    cik: '0001133197',
    value: '0001133197,DOWLING & YAHNKE LLC',
    name: 'DOWLING & YAHNKE LLC',
    label: 'DOWLING & YAHNKE LLC',
  },
  {
    cik: '0001698219',
    value: '0001698219,DOYLE WEALTH MANAGEMENT',
    name: 'DOYLE WEALTH MANAGEMENT',
    label: 'DOYLE WEALTH MANAGEMENT',
  },
  {
    cik: '0001711257',
    value: '0001711257,DPM CAPITAL LLC',
    name: 'DPM CAPITAL LLC',
    label: 'DPM CAPITAL LLC',
  },
  {
    cik: '0001602189',
    value: '0001602189,DRAGONEER INVESTMENT GROUP, LLC',
    name: 'DRAGONEER INVESTMENT GROUP, LLC',
    label: 'DRAGONEER INVESTMENT GROUP, LLC',
  },
  {
    cik: '0001730476',
    value: '0001730476,DRAGON FINANCIAL & INVESTMENT GROUP, LLC',
    name: 'DRAGON FINANCIAL & INVESTMENT GROUP, LLC',
    label: 'DRAGON FINANCIAL & INVESTMENT GROUP, LLC',
  },
  {
    cik: '0001935795',
    value: '0001935795,DRAKE & ASSOCIATES, LLC',
    name: 'DRAKE & ASSOCIATES, LLC',
    label: 'DRAKE & ASSOCIATES, LLC',
  },
  {
    cik: '0001847921',
    value: '0001847921,DRAPER ASSET MANAGEMENT, LLC',
    name: 'DRAPER ASSET MANAGEMENT, LLC',
    label: 'DRAPER ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001910389',
    value: '0001910389,DRAVO BAY LLC',
    name: 'DRAVO BAY LLC',
    label: 'DRAVO BAY LLC',
  },
  {
    cik: '0001055282',
    value: '0001055282,DREMAN VALUE MANAGEMENT, L L C',
    name: 'DREMAN VALUE MANAGEMENT, L L C',
    label: 'DREMAN VALUE MANAGEMENT, L L C',
  },
  {
    cik: '0001508512',
    value: '0001508512,DREXEL MORGAN & CO.',
    name: 'DREXEL MORGAN & CO.',
    label: 'DREXEL MORGAN & CO.',
  },
  {
    cik: '0001630709',
    value: '0001630709,DRH INVESTMENTS, INC.',
    name: 'DRH INVESTMENTS, INC.',
    label: 'DRH INVESTMENTS, INC.',
  },
  {
    cik: '0000938206',
    value: '0000938206,DRIEHAUS CAPITAL MANAGEMENT LLC',
    name: 'DRIEHAUS CAPITAL MANAGEMENT LLC',
    label: 'DRIEHAUS CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001035896',
    value: '0001035896,DRILL CRAIG A',
    name: 'DRILL CRAIG A',
    label: 'DRILL CRAIG A',
  },
  {
    cik: '0001952781',
    value: '0001952781,DRIVE WEALTH MANAGEMENT, LLC',
    name: 'DRIVE WEALTH MANAGEMENT, LLC',
    label: 'DRIVE WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001053292',
    value: '0001053292,DROMS STRAUSS ADVISORS INC /MO/ /ADV',
    name: 'DROMS STRAUSS ADVISORS INC /MO/ /ADV',
    label: 'DROMS STRAUSS ADVISORS INC /MO/ /ADV',
  },
  {
    cik: '0001481986',
    value: '0001481986,DRW SECURITIES, LLC',
    name: 'DRW SECURITIES, LLC',
    label: 'DRW SECURITIES, LLC',
  },
  {
    cik: '0001068940',
    value: '0001068940,DRW SECURITIES, L.L.C.',
    name: 'DRW SECURITIES, L.L.C.',
    label: 'DRW SECURITIES, L.L.C.',
  },
  {
    cik: '0001756488',
    value: '0001756488,DRYDEN CAPITAL, LLC',
    name: 'DRYDEN CAPITAL, LLC',
    label: 'DRYDEN CAPITAL, LLC',
  },
  {
    cik: '0001910462',
    value: '0001910462,DRYSTONE LLC',
    name: 'DRYSTONE LLC',
    label: 'DRYSTONE LLC',
  },
  {
    cik: '0001565061',
    value: '0001565061,DSAM PARTNERS (LONDON) LTD',
    name: 'DSAM PARTNERS (LONDON) LTD',
    label: 'DSAM PARTNERS (LONDON) LTD',
  },
  {
    cik: '0001299434',
    value: '0001299434,DSC ADVISORS, L.P.',
    name: 'DSC ADVISORS, L.P.',
    label: 'DSC ADVISORS, L.P.',
  },
  {
    cik: '0001596920',
    value: '0001596920,D. SCOTT NEAL, INC.',
    name: 'D. SCOTT NEAL, INC.',
    label: 'D. SCOTT NEAL, INC.',
  },
  {
    cik: '0001214639',
    value: '0001214639,DSM CAPITAL PARTNERS LLC',
    name: 'DSM CAPITAL PARTNERS LLC',
    label: 'DSM CAPITAL PARTNERS LLC',
  },
  {
    cik: '0001550172',
    value: '0001550172,DST GLOBAL ADVISORS LTD',
    name: 'DST GLOBAL ADVISORS LTD',
    label: 'DST GLOBAL ADVISORS LTD',
  },
  {
    cik: '0001550224',
    value: '0001550224,DST MANAGERS LTD',
    name: 'DST MANAGERS LTD',
    label: 'DST MANAGERS LTD',
  },
  {
    cik: '0001549931',
    value: '0001549931,DST USA LTD',
    name: 'DST USA LTD',
    label: 'DST USA LTD',
  },
  {
    cik: '0001537621',
    value: '0001537621,DT INVESTMENT PARTNERS, LLC',
    name: 'DT INVESTMENT PARTNERS, LLC',
    label: 'DT INVESTMENT PARTNERS, LLC',
  },
  {
    cik: '0001741024',
    value: '0001741024,DUALITY ADVISERS, LP',
    name: 'DUALITY ADVISERS, LP',
    label: 'DUALITY ADVISERS, LP',
  },
  {
    cik: '0000933482',
    value: '0000933482,DUBUQUE BANK & TRUST CO',
    name: 'DUBUQUE BANK & TRUST CO',
    label: 'DUBUQUE BANK & TRUST CO',
  },
  {
    cik: '0000883511',
    value: '0000883511,DUDLEY & SHANLEY, INC.',
    name: 'DUDLEY & SHANLEY, INC.',
    label: 'DUDLEY & SHANLEY, INC.',
  },
  {
    cik: '0000765443',
    value: '0000765443,DUFF & PHELPS INVESTMENT MANAGEMENT CO',
    name: 'DUFF & PHELPS INVESTMENT MANAGEMENT CO',
    label: 'DUFF & PHELPS INVESTMENT MANAGEMENT CO',
  },
  {
    cik: '0001636191',
    value: '0001636191,DULCET CAPITAL MANAGEMENT',
    name: 'DULCET CAPITAL MANAGEMENT',
    label: 'DULCET CAPITAL MANAGEMENT',
  },
  {
    cik: '0001584258',
    value: '0001584258,DUMAC, INC.',
    name: 'DUMAC, INC.',
    label: 'DUMAC, INC.',
  },
  {
    cik: '0001287618',
    value: '0001287618,DUMONT & BLAKE INVESTMENT ADVISORS LLC',
    name: 'DUMONT & BLAKE INVESTMENT ADVISORS LLC',
    label: 'DUMONT & BLAKE INVESTMENT ADVISORS LLC',
  },
  {
    cik: '0001803609',
    value: '0001803609,DUMONT GLOBAL LP',
    name: 'DUMONT GLOBAL LP',
    label: 'DUMONT GLOBAL LP',
  },
  {
    cik: '0001020585',
    value: '0001020585,DUNCKER STREETT & CO INC',
    name: 'DUNCKER STREETT & CO INC',
    label: 'DUNCKER STREETT & CO INC',
  },
  {
    cik: '0001742418',
    value: '0001742418,DUNDAS PARTNERS LLP',
    name: 'DUNDAS PARTNERS LLP',
    label: 'DUNDAS PARTNERS LLP',
  },
  {
    cik: '0001344422',
    value: '0001344422,DUNVEGAN ASSOCIATES, INC.',
    name: 'DUNVEGAN ASSOCIATES, INC.',
    label: 'DUNVEGAN ASSOCIATES, INC.',
  },
  {
    cik: '0001128251',
    value: '0001128251,DUPONT CAPITAL MANAGEMENT CORP',
    name: 'DUPONT CAPITAL MANAGEMENT CORP',
    label: 'DUPONT CAPITAL MANAGEMENT CORP',
  },
  {
    cik: '0001536411',
    value: '0001536411,DUQUESNE FAMILY OFFICE LLC',
    name: 'DUQUESNE FAMILY OFFICE LLC',
    label: 'DUQUESNE FAMILY OFFICE LLC',
  },
  {
    cik: '0001798849',
    value: '0001798849,DURABLE CAPITAL PARTNERS LP',
    name: 'DURABLE CAPITAL PARTNERS LP',
    label: 'DURABLE CAPITAL PARTNERS LP',
  },
  {
    cik: '0001765888',
    value: '0001765888,DURATIONAL CAPITAL MANAGEMENT LP',
    name: 'DURATIONAL CAPITAL MANAGEMENT LP',
    label: 'DURATIONAL CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001506321',
    value: '0001506321,DW INVESTMENT MANAGEMENT, LP',
    name: 'DW INVESTMENT MANAGEMENT, LP',
    label: 'DW INVESTMENT MANAGEMENT, LP',
  },
  {
    cik: '0001759545',
    value: '0001759545,DWM FINANCIAL GROUP, INC.',
    name: 'DWM FINANCIAL GROUP, INC.',
    label: 'DWM FINANCIAL GROUP, INC.',
  },
  {
    cik: '0001554664',
    value: '0001554664,DYMON ASIA CAPITAL LTD',
    name: 'DYMON ASIA CAPITAL LTD',
    label: 'DYMON ASIA CAPITAL LTD',
  },
  {
    cik: '0001672142',
    value: '0001672142,DYMON ASIA CAPITAL (SINGAPORE) PTE. LTD.',
    name: 'DYMON ASIA CAPITAL (SINGAPORE) PTE. LTD.',
    label: 'DYMON ASIA CAPITAL (SINGAPORE) PTE. LTD.',
  },
  {
    cik: '0001599747',
    value: '0001599747,DYNAMIC ADVISOR SOLUTIONS LLC',
    name: 'DYNAMIC ADVISOR SOLUTIONS LLC',
    label: 'DYNAMIC ADVISOR SOLUTIONS LLC',
  },
  {
    cik: '0001454946',
    value: '0001454946,DYNAMIC CAPITAL MANAGEMENT LTD',
    name: 'DYNAMIC CAPITAL MANAGEMENT LTD',
    label: 'DYNAMIC CAPITAL MANAGEMENT LTD',
  },
  {
    cik: '0001535387',
    value: '0001535387,DYNAMIC TECHNOLOGY LAB PRIVATE LTD',
    name: 'DYNAMIC TECHNOLOGY LAB PRIVATE LTD',
    label: 'DYNAMIC TECHNOLOGY LAB PRIVATE LTD',
  },
  {
    cik: '0001707096',
    value: '0001707096,DYNAMO ADMINISTRACAO DE RECURSOS LTDA.',
    name: 'DYNAMO ADMINISTRACAO DE RECURSOS LTDA.',
    label: 'DYNAMO ADMINISTRACAO DE RECURSOS LTDA.',
  },
  {
    cik: '0001546790',
    value: '0001546790,DYNAMO INTERNACIONAL GESTAO DE RECURSOS LTDA.',
    name: 'DYNAMO INTERNACIONAL GESTAO DE RECURSOS LTDA.',
    label: 'DYNAMO INTERNACIONAL GESTAO DE RECURSOS LTDA.',
  },
  {
    cik: '0001727599',
    value: '0001727599,DYNASTY WEALTH MANAGEMENT, LLC',
    name: 'DYNASTY WEALTH MANAGEMENT, LLC',
    label: 'DYNASTY WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001694217',
    value:
      '0001694217,DZ BANK AG DEUTSCHE ZENTRAL GENOSSENSCHAFTS BANK, FRANKFURT AM MAIN',
    name: 'DZ BANK AG DEUTSCHE ZENTRAL GENOSSENSCHAFTS BANK, FRANKFURT AM MAIN',
    label:
      'DZ BANK AG DEUTSCHE ZENTRAL GENOSSENSCHAFTS BANK, FRANKFURT AM MAIN',
  },
  {
    cik: '0001598490',
    value: '0001598490,EACHWIN CAPITAL, LP',
    name: 'EACHWIN CAPITAL, LP',
    label: 'EACHWIN CAPITAL, LP',
  },
  {
    cik: '0001035912',
    value: '0001035912,EADS & HEALD INVESTMENT COUNSEL/',
    name: 'EADS & HEALD INVESTMENT COUNSEL/',
    label: 'EADS & HEALD INVESTMENT COUNSEL/',
  },
  {
    cik: '0001035912',
    value: '0001035912,EADS & HEALD WEALTH MANAGEMENT',
    name: 'EADS & HEALD WEALTH MANAGEMENT',
    label: 'EADS & HEALD WEALTH MANAGEMENT',
  },
  {
    cik: '0000790502',
    value: '0000790502,EAGLE ASSET MANAGEMENT INC',
    name: 'EAGLE ASSET MANAGEMENT INC',
    label: 'EAGLE ASSET MANAGEMENT INC',
  },
  {
    cik: '0001862864',
    value: '0001862864,EAGLE BAY ADVISORS LLC',
    name: 'EAGLE BAY ADVISORS LLC',
    label: 'EAGLE BAY ADVISORS LLC',
  },
  {
    cik: '0001906547',
    value: '0001906547,EAGLE BLUFFS WEALTH MANAGEMENT LLC',
    name: 'EAGLE BLUFFS WEALTH MANAGEMENT LLC',
    label: 'EAGLE BLUFFS WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0001079161',
    value: '0001079161,EAGLE BOSTON INVESTMENT MANAGEMENT INC',
    name: 'EAGLE BOSTON INVESTMENT MANAGEMENT INC',
    label: 'EAGLE BOSTON INVESTMENT MANAGEMENT INC',
  },
  {
    cik: '0000945631',
    value: '0000945631,EAGLE CAPITAL MANAGEMENT LLC',
    name: 'EAGLE CAPITAL MANAGEMENT LLC',
    label: 'EAGLE CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001600746',
    value: '0001600746,EAGLE CAPITAL MANAGEMENT, LLC',
    name: 'EAGLE CAPITAL MANAGEMENT, LLC',
    label: 'EAGLE CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001607636',
    value: '0001607636,EAGLECLAW CAPITAL MANAGMENT, LLC',
    name: 'EAGLECLAW CAPITAL MANAGMENT, LLC',
    label: 'EAGLECLAW CAPITAL MANAGMENT, LLC',
  },
  {
    cik: '0001130787',
    value: '0001130787,EAGLE GLOBAL ADVISORS LLC',
    name: 'EAGLE GLOBAL ADVISORS LLC',
    label: 'EAGLE GLOBAL ADVISORS LLC',
  },
  {
    cik: '0001842545',
    value: '0001842545,EAGLE HEALTH INVESTMENTS LP',
    name: 'EAGLE HEALTH INVESTMENTS LP',
    label: 'EAGLE HEALTH INVESTMENTS LP',
  },
  {
    cik: '0001445891',
    value: '0001445891,EAGLE RIDGE INVESTMENT MANAGEMENT',
    name: 'EAGLE RIDGE INVESTMENT MANAGEMENT',
    label: 'EAGLE RIDGE INVESTMENT MANAGEMENT',
  },
  {
    cik: '0001911407',
    value: '0001911407,EAGLE ROCK INVESTMENT COMPANY, LLC',
    name: 'EAGLE ROCK INVESTMENT COMPANY, LLC',
    label: 'EAGLE ROCK INVESTMENT COMPANY, LLC',
  },
  {
    cik: '0001907666',
    value: '0001907666,EAGLE STRATEGIES LLC',
    name: 'EAGLE STRATEGIES LLC',
    label: 'EAGLE STRATEGIES LLC',
  },
  {
    cik: '0001741619',
    value: '0001741619,EAM GLOBAL INVESTORS LLC',
    name: 'EAM GLOBAL INVESTORS LLC',
    label: 'EAM GLOBAL INVESTORS LLC',
  },
  {
    cik: '0001510989',
    value: '0001510989,EAM INVESTORS, LLC',
    name: 'EAM INVESTORS, LLC',
    label: 'EAM INVESTORS, LLC',
  },
  {
    cik: '0001102578',
    value: '0001102578,EARNEST PARTNERS LLC',
    name: 'EARNEST PARTNERS LLC',
    label: 'EARNEST PARTNERS LLC',
  },
  {
    cik: '0001650669',
    value: '0001650669,EASTBAY ASSET MANAGEMENT, LLC',
    name: 'EASTBAY ASSET MANAGEMENT, LLC',
    label: 'EASTBAY ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001579254',
    value: '0001579254,EAST COAST ASSET MANAGEMENT, LLC.',
    name: 'EAST COAST ASSET MANAGEMENT, LLC.',
    label: 'EAST COAST ASSET MANAGEMENT, LLC.',
  },
  {
    cik: '0001766150',
    value: '0001766150,EASTERLY INVESTMENT PARTNERS LLC',
    name: 'EASTERLY INVESTMENT PARTNERS LLC',
    label: 'EASTERLY INVESTMENT PARTNERS LLC',
  },
  {
    cik: '0001126395',
    value: '0001126395,EASTERN BANK',
    name: 'EASTERN BANK',
    label: 'EASTERN BANK',
  },
  {
    cik: '0001472800',
    value: '0001472800,EASTOVER INVESTMENT ADVISORS LLC',
    name: 'EASTOVER INVESTMENT ADVISORS LLC',
    label: 'EASTOVER INVESTMENT ADVISORS LLC',
  },
  {
    cik: '0001076598',
    value: '0001076598,EATON VANCE MANAGEMENT',
    name: 'EATON VANCE MANAGEMENT',
    label: 'EATON VANCE MANAGEMENT',
  },
  {
    cik: '0001802837',
    value: '0001802837,EBOR CHARITABLE TRUST',
    name: 'EBOR CHARITABLE TRUST',
    label: 'EBOR CHARITABLE TRUST',
  },
  {
    cik: '0001602970',
    value: '0001602970,ECHINUS ADVISORS, LLC',
    name: 'ECHINUS ADVISORS, LLC',
    label: 'ECHINUS ADVISORS, LLC',
  },
  {
    cik: '0001908617',
    value: '0001908617,ECHO45 ADVISORS LLC',
    name: 'ECHO45 ADVISORS LLC',
    label: 'ECHO45 ADVISORS LLC',
  },
  {
    cik: '0001352342',
    value: '0001352342,ECHO STREET CAPITAL MANAGEMENT LLC',
    name: 'ECHO STREET CAPITAL MANAGEMENT LLC',
    label: 'ECHO STREET CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001846994',
    value: '0001846994,ECHO WEALTH MANAGEMENT, LLC',
    name: 'ECHO WEALTH MANAGEMENT, LLC',
    label: 'ECHO WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001568791',
    value: '0001568791,ECLECTICA ASSET MANAGEMENT LLP',
    name: 'ECLECTICA ASSET MANAGEMENT LLP',
    label: 'ECLECTICA ASSET MANAGEMENT LLP',
  },
  {
    cik: '0000743482',
    value: '0000743482,ECLECTIC ASSOCIATES INC /ADV',
    name: 'ECLECTIC ASSOCIATES INC /ADV',
    label: 'ECLECTIC ASSOCIATES INC /ADV',
  },
  {
    cik: '0001908066',
    value: '0001908066,ECLIPSE VENTURES, LLC',
    name: 'ECLIPSE VENTURES, LLC',
    label: 'ECLIPSE VENTURES, LLC',
  },
  {
    cik: '0001387869',
    value: '0001387869,ECOFIN ADVISORS LTD',
    name: 'ECOFIN ADVISORS LTD',
    label: 'ECOFIN ADVISORS LTD',
  },
  {
    cik: '0001659718',
    value: '0001659718,ECON FINANCIAL SERVICES CORP',
    name: 'ECON FINANCIAL SERVICES CORP',
    label: 'ECON FINANCIAL SERVICES CORP',
  },
  {
    cik: '0001587114',
    value: '0001587114,ECOR1 CAPITAL, LLC',
    name: 'ECOR1 CAPITAL, LLC',
    label: 'ECOR1 CAPITAL, LLC',
  },
  {
    cik: '0001707921',
    value: '0001707921,ECP CONTROLCO, LLC',
    name: 'ECP CONTROLCO, LLC',
    label: 'ECP CONTROLCO, LLC',
  },
  {
    cik: '0001913506',
    value: '0001913506,EDBI PTE LTD',
    name: 'EDBI PTE LTD',
    label: 'EDBI PTE LTD',
  },
  {
    cik: '0001071341',
    value: '0001071341,EDELMAN FINANCIAL GROUP INC.',
    name: 'EDELMAN FINANCIAL GROUP INC.',
    label: 'EDELMAN FINANCIAL GROUP INC.',
  },
  {
    cik: '0001386088',
    value: '0001386088,EDELMAN FINANCIAL SERVICES, LLC',
    name: 'EDELMAN FINANCIAL SERVICES, LLC',
    label: 'EDELMAN FINANCIAL SERVICES, LLC',
  },
  {
    cik: '0001666682',
    value: '0001666682,EDENBROOK CAPITAL, LLC',
    name: 'EDENBROOK CAPITAL, LLC',
    label: 'EDENBROOK CAPITAL, LLC',
  },
  {
    cik: '0001013538',
    value: '0001013538,EDGAR LOMAX CO/VA',
    name: 'EDGAR LOMAX CO/VA',
    label: 'EDGAR LOMAX CO/VA',
  },
  {
    cik: '0001730516',
    value: '0001730516,EDGBASTON INVESTMENT PARTNERS LLP',
    name: 'EDGBASTON INVESTMENT PARTNERS LLP',
    label: 'EDGBASTON INVESTMENT PARTNERS LLP',
  },
  {
    cik: '0001588186',
    value: '0001588186,EDGE ADVISORS, LLC',
    name: 'EDGE ADVISORS, LLC',
    label: 'EDGE ADVISORS, LLC',
  },
  {
    cik: '0001061286',
    value: '0001061286,EDGE ASSET MANAGEMENT, INC',
    name: 'EDGE ASSET MANAGEMENT, INC',
    label: 'EDGE ASSET MANAGEMENT, INC',
  },
  {
    cik: '0001767220',
    value: '0001767220,EDGE CAPITAL GROUP, LLC',
    name: 'EDGE CAPITAL GROUP, LLC',
    label: 'EDGE CAPITAL GROUP, LLC',
  },
  {
    cik: '0001676623',
    value: '0001676623,EDGEHILL ENDOWMENT PARTNERS, LLC',
    name: 'EDGEHILL ENDOWMENT PARTNERS, LLC',
    label: 'EDGEHILL ENDOWMENT PARTNERS, LLC',
  },
  {
    cik: '0001313792',
    value: '0001313792,EDGEMOOR INVESTMENT ADVISORS, INC.',
    name: 'EDGEMOOR INVESTMENT ADVISORS, INC.',
    label: 'EDGEMOOR INVESTMENT ADVISORS, INC.',
  },
  {
    cik: '0001481669',
    value: '0001481669,EDGEPOINT INVESTMENT GROUP INC.',
    name: 'EDGEPOINT INVESTMENT GROUP INC.',
    label: 'EDGEPOINT INVESTMENT GROUP INC.',
  },
  {
    cik: '0001908378',
    value: '0001908378,EDGEROCK CAPITAL LLC',
    name: 'EDGEROCK CAPITAL LLC',
    label: 'EDGEROCK CAPITAL LLC',
  },
  {
    cik: '0001535061',
    value: '0001535061,EDGESTREAM PARTNERS, L.P.',
    name: 'EDGESTREAM PARTNERS, L.P.',
    label: 'EDGESTREAM PARTNERS, L.P.',
  },
  {
    cik: '0001567163',
    value: '0001567163,EDGE WEALTH MANAGEMENT LLC',
    name: 'EDGE WEALTH MANAGEMENT LLC',
    label: 'EDGE WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0000860561',
    value: '0000860561,EDGEWOOD MANAGEMENT LLC',
    name: 'EDGEWOOD MANAGEMENT LLC',
    label: 'EDGEWOOD MANAGEMENT LLC',
  },
  {
    cik: '0001313926',
    value: '0001313926,EDINBURGH PARTNERS LTD',
    name: 'EDINBURGH PARTNERS LTD',
    label: 'EDINBURGH PARTNERS LTD',
  },
  {
    cik: '0001649592',
    value: '0001649592,EDMOND DE ROTHSCHILD ASSET MANAGEMENT (FRANCE)',
    name: 'EDMOND DE ROTHSCHILD ASSET MANAGEMENT (FRANCE)',
    label: 'EDMOND DE ROTHSCHILD ASSET MANAGEMENT (FRANCE)',
  },
  {
    cik: '0001667654',
    value: '0001667654,EDMOND DE ROTHSCHILD ASSET MANAGEMENT (LUXEMBOURG)',
    name: 'EDMOND DE ROTHSCHILD ASSET MANAGEMENT (LUXEMBOURG)',
    label: 'EDMOND DE ROTHSCHILD ASSET MANAGEMENT (LUXEMBOURG)',
  },
  {
    cik: '0001536550',
    value: '0001536550,EDMOND DE ROTHSCHILD (EUROPE)',
    name: 'EDMOND DE ROTHSCHILD (EUROPE)',
    label: 'EDMOND DE ROTHSCHILD (EUROPE)',
  },
  {
    cik: '0001649647',
    value: '0001649647,EDMOND DE ROTHSCHILD HOLDING S.A.',
    name: 'EDMOND DE ROTHSCHILD HOLDING S.A.',
    label: 'EDMOND DE ROTHSCHILD HOLDING S.A.',
  },
  {
    cik: '0001649591',
    value: '0001649591,EDMOND DE ROTHSCHILD (ITALIA) SGR SPA',
    name: 'EDMOND DE ROTHSCHILD (ITALIA) SGR SPA',
    label: 'EDMOND DE ROTHSCHILD (ITALIA) SGR SPA',
  },
  {
    cik: '0001689918',
    value: '0001689918,EDMOND DE ROTHSCHILD (MONACO)',
    name: 'EDMOND DE ROTHSCHILD (MONACO)',
    label: 'EDMOND DE ROTHSCHILD (MONACO)',
  },
  {
    cik: '0001658354',
    value: '0001658354,EDMOND DE ROTHSCHILD PRIVATE MERCHANT BANKING LLP',
    name: 'EDMOND DE ROTHSCHILD PRIVATE MERCHANT BANKING LLP',
    label: 'EDMOND DE ROTHSCHILD PRIVATE MERCHANT BANKING LLP',
  },
  {
    cik: '0001650162',
    value: '0001650162,EDMOND DE ROTHSCHILD (SUISSE) S.A.',
    name: 'EDMOND DE ROTHSCHILD (SUISSE) S.A.',
    label: 'EDMOND DE ROTHSCHILD (SUISSE) S.A.',
  },
  {
    cik: '0001668155',
    value: '0001668155,EDMONDS DUNCAN REGISTERED INVESTMENT ADVISORS, LLC',
    name: 'EDMONDS DUNCAN REGISTERED INVESTMENT ADVISORS, LLC',
    label: 'EDMONDS DUNCAN REGISTERED INVESTMENT ADVISORS, LLC',
  },
  {
    cik: '0001016021',
    value: '000101602,EDMP, INC.',
    name: 'EDMP, INC.',
    label: 'EDMP, INC.',
  },
  {
    cik: '0001510831',
    value: '0001510831,EDMUNDS WHITE PARTNERS LLC',
    name: 'EDMUNDS WHITE PARTNERS LLC',
    label: 'EDMUNDS WHITE PARTNERS LLC',
  },
  {
    cik: '0001542164',
    value: '0001542164,EFFICIENT ADVISORS, LLC',
    name: 'EFFICIENT ADVISORS, LLC',
    label: 'EFFICIENT ADVISORS, LLC',
  },
  {
    cik: '0001429788',
    value: '0001429788,EFFICIENT MARKET ADVISORS, LLC',
    name: 'EFFICIENT MARKET ADVISORS, LLC',
    label: 'EFFICIENT MARKET ADVISORS, LLC',
  },
  {
    cik: '0001792704',
    value: '0001792704,EFFICIENT WEALTH MANAGEMENT LLC',
    name: 'EFFICIENT WEALTH MANAGEMENT LLC',
    label: 'EFFICIENT WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0001598340',
    value: '0001598340,EFG ASSET MANAGEMENT (AMERICAS) CORP.',
    name: 'EFG ASSET MANAGEMENT (AMERICAS) CORP.',
    label: 'EFG ASSET MANAGEMENT (AMERICAS) CORP.',
  },
  {
    cik: '0001911412',
    value: '0001911412,EFG ASSET MANAGEMENT (NORTH AMERICA) CORP.',
    name: 'EFG ASSET MANAGEMENT (NORTH AMERICA) CORP.',
    label: 'EFG ASSET MANAGEMENT (NORTH AMERICA) CORP.',
  },
  {
    cik: '0001842296',
    value: '0001842296,E FUND MANAGEMENT CO., LTD.',
    name: 'E FUND MANAGEMENT CO., LTD.',
    label: 'E FUND MANAGEMENT CO., LTD.',
  },
  {
    cik: '0001777469',
    value: '0001777469,E FUND MANAGEMENT (HONG KONG) CO., LTD.',
    name: 'E FUND MANAGEMENT (HONG KONG) CO., LTD.',
    label: 'E FUND MANAGEMENT (HONG KONG) CO., LTD.',
  },
  {
    cik: '0001598550',
    value: '0001598550,E&G ADVISORS, LP',
    name: 'E&G ADVISORS, LP',
    label: 'E&G ADVISORS, LP',
  },
  {
    cik: '0001581811',
    value: '0001581811,EGERTON CAPITAL (UK) LLP',
    name: 'EGERTON CAPITAL (UK) LLP',
    label: 'EGERTON CAPITAL (UK) LLP',
  },
  {
    cik: '0001927881',
    value: '0001927881,EHP FUNDS INC.',
    name: 'EHP FUNDS INC.',
    label: 'EHP FUNDS INC.',
  },
  {
    cik: '0001512865',
    value: '0001512865,EIDELMAN VIRANT CAPITAL',
    name: 'EIDELMAN VIRANT CAPITAL',
    label: 'EIDELMAN VIRANT CAPITAL',
  },
  {
    cik: '0001616372',
    value: '0001616372,EIG MANAGEMENT COMPANY, LLC',
    name: 'EIG MANAGEMENT COMPANY, LLC',
    label: 'EIG MANAGEMENT COMPANY, LLC',
  },
  {
    cik: '0000911088',
    value: '0000911088,EII CAPITAL MANAGEMENT, INC.',
    name: 'EII CAPITAL MANAGEMENT, INC.',
    label: 'EII CAPITAL MANAGEMENT, INC.',
  },
  {
    cik: '0001849486',
    value: '0001849486,EISLER CAPITAL (UK) LTD.',
    name: 'EISLER CAPITAL (UK) LTD.',
    label: 'EISLER CAPITAL (UK) LTD.',
  },
  {
    cik: '0001624809',
    value: '0001624809,EISNERAMPER WEALTH MANAGEMENT CORPORATE BENEFITS, LLC',
    name: 'EISNERAMPER WEALTH MANAGEMENT CORPORATE BENEFITS, LLC',
    label: 'EISNERAMPER WEALTH MANAGEMENT CORPORATE BENEFITS, LLC',
  },
  {
    cik: '0001353254',
    value: '0001353254,EJF CAPITAL LLC',
    name: 'EJF CAPITAL LLC',
    label: 'EJF CAPITAL LLC',
  },
  {
    cik: '0001666580',
    value: '0001666580,ELBERON, LLC',
    name: 'ELBERON, LLC',
    label: 'ELBERON, LLC',
  },
  {
    cik: '0001690717',
    value: '0001690717,ELCO MANAGEMENT CO., LLC',
    name: 'ELCO MANAGEMENT CO., LLC',
    label: 'ELCO MANAGEMENT CO., LLC',
  },
  {
    cik: '0001727993',
    value: '0001727993,ELDRIDGE INVESTMENT ADVISORS, INC.',
    name: 'ELDRIDGE INVESTMENT ADVISORS, INC.',
    label: 'ELDRIDGE INVESTMENT ADVISORS, INC.',
  },
  {
    cik: '0001586986',
    value: '0001586986,ELECTRON CAPITAL PARTNERS, LLC',
    name: 'ELECTRON CAPITAL PARTNERS, LLC',
    label: 'ELECTRON CAPITAL PARTNERS, LLC',
  },
  {
    cik: '0001661535',
    value: '0001661535,ELEFANTE MARK B',
    name: 'ELEFANTE MARK B',
    label: 'ELEFANTE MARK B',
  },
  {
    cik: '0001053912',
    value: '0001053912,ELEFANTE MICHAEL B',
    name: 'ELEFANTE MICHAEL B',
    label: 'ELEFANTE MICHAEL B',
  },
  {
    cik: '0001860672',
    value: '0001860672,ELEMENTAL CAPITAL PARTNERS LLC',
    name: 'ELEMENTAL CAPITAL PARTNERS LLC',
    label: 'ELEMENTAL CAPITAL PARTNERS LLC',
  },
  {
    cik: '0001535630',
    value: '0001535630,ELEMENT CAPITAL MANAGEMENT LLC',
    name: 'ELEMENT CAPITAL MANAGEMENT LLC',
    label: 'ELEMENT CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001764059',
    value: '0001764059,ELEMENT POINTE ADVISORS, LLC',
    name: 'ELEMENT POINTE ADVISORS, LLC',
    label: 'ELEMENT POINTE ADVISORS, LLC',
  },
  {
    cik: '0001802743',
    value: '0001802743,ELEMENT WEALTH, LLC',
    name: 'ELEMENT WEALTH, LLC',
    label: 'ELEMENT WEALTH, LLC',
  },
  {
    cik: '0001731191',
    value: '0001731191,ELEPHAS INVESTMENT MANAGEMENT LTD',
    name: 'ELEPHAS INVESTMENT MANAGEMENT LTD',
    label: 'ELEPHAS INVESTMENT MANAGEMENT LTD',
  },
  {
    cik: '0001810873',
    value: '0001810873,ELEQUIN CAPITAL LP',
    name: 'ELEQUIN CAPITAL LP',
    label: 'ELEQUIN CAPITAL LP',
  },
  {
    cik: '0001802387',
    value: '0001802387,ELEVATED CAPITAL ADVISORS, LLC',
    name: 'ELEVATED CAPITAL ADVISORS, LLC',
    label: 'ELEVATED CAPITAL ADVISORS, LLC',
  },
  {
    cik: '0001871112',
    value: '0001871112,ELEVATION CAPITAL ADVISORY, LLC',
    name: 'ELEVATION CAPITAL ADVISORY, LLC',
    label: 'ELEVATION CAPITAL ADVISORY, LLC',
  },
  {
    cik: '0001344814',
    value: '0001344814,ELEVATION MANAGEMENT, LLC',
    name: 'ELEVATION MANAGEMENT, LLC',
    label: 'ELEVATION MANAGEMENT, LLC',
  },
  {
    cik: '0001697865',
    value: '0001697865,E-L FINANCIAL CORP LTD',
    name: 'E-L FINANCIAL CORP LTD',
    label: 'E-L FINANCIAL CORP LTD',
  },
  {
    cik: '0001638022',
    value: '0001638022,ELGETHUN CAPITAL MANAGEMENT',
    name: 'ELGETHUN CAPITAL MANAGEMENT',
    label: 'ELGETHUN CAPITAL MANAGEMENT',
  },
  {
    cik: '0001641643',
    value: '0001641643,ELIOT FINKEL INVESTMENT COUNSEL, LLC',
    name: 'ELIOT FINKEL INVESTMENT COUNSEL, LLC',
    label: 'ELIOT FINKEL INVESTMENT COUNSEL, LLC',
  },
  {
    cik: '0001622757',
    value: '0001622757,ELITE WEALTH MANAGEMENT, INC.',
    name: 'ELITE WEALTH MANAGEMENT, INC.',
    label: 'ELITE WEALTH MANAGEMENT, INC.',
  },
  {
    cik: '0001600583',
    value: '0001600583,ELIZABETH PARK CAPITAL ADVISORS, LTD.',
    name: 'ELIZABETH PARK CAPITAL ADVISORS, LTD.',
    label: 'ELIZABETH PARK CAPITAL ADVISORS, LTD.',
  },
  {
    cik: '0001592405',
    value: '0001592405,ELK CREEK PARTNERS, LLC',
    name: 'ELK CREEK PARTNERS, LLC',
    label: 'ELK CREEK PARTNERS, LLC',
  },
  {
    cik: '0001694584',
    value: '0001694584,ELKFORK PARTNERS LLC',
    name: 'ELKFORK PARTNERS LLC',
    label: 'ELKFORK PARTNERS LLC',
  },
  {
    cik: '0001595128',
    value: '0001595128,ELKHORN INVESTMENTS, LLC',
    name: 'ELKHORN INVESTMENTS, LLC',
    label: 'ELKHORN INVESTMENTS, LLC',
  },
  {
    cik: '0000928400',
    value: '0000928400,ELKHORN PARTNERS LIMITED PARTNERSHIP',
    name: 'ELKHORN PARTNERS LIMITED PARTNERSHIP',
    label: 'ELKHORN PARTNERS LIMITED PARTNERSHIP',
  },
  {
    cik: '0001844107',
    value: '0001844107,ELK RIVER WEALTH MANAGEMENT, LLC',
    name: 'ELK RIVER WEALTH MANAGEMENT, LLC',
    label: 'ELK RIVER WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001731497',
    value: '0001731497,ELLENBECKER INVESTMENT GROUP',
    name: 'ELLENBECKER INVESTMENT GROUP',
    label: 'ELLENBECKER INVESTMENT GROUP',
  },
  {
    cik: '0001644128',
    value: '0001644128,ELLEVEST, INC.',
    name: 'ELLEVEST, INC.',
    label: 'ELLEVEST, INC.',
  },
  {
    cik: '0001098490',
    value: '0001098490,ELLINGTON MANAGEMENT GROUP LLC',
    name: 'ELLINGTON MANAGEMENT GROUP LLC',
    label: 'ELLINGTON MANAGEMENT GROUP LLC',
  },
  {
    cik: '0001791786',
    value: '0001791786,ELLIOTT INVESTMENT MANAGEMENT L.P.',
    name: 'ELLIOTT INVESTMENT MANAGEMENT L.P.',
    label: 'ELLIOTT INVESTMENT MANAGEMENT L.P.',
  },
  {
    cik: '0001048445',
    value: '0001048445,ELLIOTT MANAGEMENT CORP',
    name: 'ELLIOTT MANAGEMENT CORP',
    label: 'ELLIOTT MANAGEMENT CORP',
  },
  {
    cik: '0001765590',
    value: '0001765590,ELLIS INVESTMENT PARTNERS, LLC',
    name: 'ELLIS INVESTMENT PARTNERS, LLC',
    label: 'ELLIS INVESTMENT PARTNERS, LLC',
  },
  {
    cik: '0001536147',
    value: '0001536147,ELLIS LAKE CAPITAL, LLC',
    name: 'ELLIS LAKE CAPITAL, LLC',
    label: 'ELLIS LAKE CAPITAL, LLC',
  },
  {
    cik: '0001843553',
    value: '0001843553,ELLSWORTH ADVISORS, LLC',
    name: 'ELLSWORTH ADVISORS, LLC',
    label: 'ELLSWORTH ADVISORS, LLC',
  },
  {
    cik: '0001362987',
    value: '0001362987,ELM ADVISORS, LLC',
    name: 'ELM ADVISORS, LLC',
    label: 'ELM ADVISORS, LLC',
  },
  {
    cik: '0001743941',
    value: '0001743941,ELM PARTNERS MANAGEMENT LLC',
    name: 'ELM PARTNERS MANAGEMENT LLC',
    label: 'ELM PARTNERS MANAGEMENT LLC',
  },
  {
    cik: '0001483276',
    value: '0001483276,ELM RIDGE MANAGEMENT, LLC',
    name: 'ELM RIDGE MANAGEMENT, LLC',
    label: 'ELM RIDGE MANAGEMENT, LLC',
  },
  {
    cik: '0001635152',
    value: '0001635152,ELMROX INVESTMENT GROUP IM L.P.',
    name: 'ELMROX INVESTMENT GROUP IM L.P.',
    label: 'ELMROX INVESTMENT GROUP IM L.P.',
  },
  {
    cik: '0001731927',
    value: '0001731927,ELMWOOD WEALTH MANAGEMENT, INC.',
    name: 'ELMWOOD WEALTH MANAGEMENT, INC.',
    label: 'ELMWOOD WEALTH MANAGEMENT, INC.',
  },
  {
    cik: '0001739877',
    value: '0001739877,ELO MUTUAL PENSION INSURANCE CO',
    name: 'ELO MUTUAL PENSION INSURANCE CO',
    label: 'ELO MUTUAL PENSION INSURANCE CO',
  },
  {
    cik: '0001752758',
    value: '0001752758,ELWOOD & GOETZ WEALTH ADVISORY GROUP, LLC',
    name: 'ELWOOD & GOETZ WEALTH ADVISORY GROUP, LLC',
    label: 'ELWOOD & GOETZ WEALTH ADVISORY GROUP, LLC',
  },
  {
    cik: '0001478861',
    value: '0001478861,EMANCIPATION MANAGEMENT LLC',
    name: 'EMANCIPATION MANAGEMENT LLC',
    label: 'EMANCIPATION MANAGEMENT LLC',
  },
  {
    cik: '0001847772',
    value: '0001847772,EMC CAPITAL MANAGEMENT',
    name: 'EMC CAPITAL MANAGEMENT',
    label: 'EMC CAPITAL MANAGEMENT',
  },
  {
    cik: '0001678434',
    value: '0001678434,EMERALD ACQUISITION LTD.',
    name: 'EMERALD ACQUISITION LTD.',
    label: 'EMERALD ACQUISITION LTD.',
  },
  {
    cik: '0001015083',
    value: '0001015083,EMERALD ADVISERS INC/PA',
    name: 'EMERALD ADVISERS INC/PA',
    label: 'EMERALD ADVISERS INC/PA',
  },
  {
    cik: '0001015083',
    value: '0001015083,EMERALD ADVISERS, LLC',
    name: 'EMERALD ADVISERS, LLC',
    label: 'EMERALD ADVISERS, LLC',
  },
  {
    cik: '0001802611',
    value: '0001802611,EMERALD ADVISORS, LLC',
    name: 'EMERALD ADVISORS, LLC',
    label: 'EMERALD ADVISORS, LLC',
  },
  {
    cik: '0001729303',
    value: '0001729303,EMERALD INVESTMENT PARTNERS, LLC',
    name: 'EMERALD INVESTMENT PARTNERS, LLC',
    label: 'EMERALD INVESTMENT PARTNERS, LLC',
  },
  {
    cik: '0001352895',
    value: '0001352895,EMERALD MUTUAL FUND ADVISERS TRUST',
    name: 'EMERALD MUTUAL FUND ADVISERS TRUST',
    label: 'EMERALD MUTUAL FUND ADVISERS TRUST',
  },
  {
    cik: '0001589115',
    value: '0001589115,EMERGENCE GP PARTNERS, LLC',
    name: 'EMERGENCE GP PARTNERS, LLC',
    label: 'EMERGENCE GP PARTNERS, LLC',
  },
  {
    cik: '0001523609',
    value: '0001523609,EMERGING GLOBAL ADVISORS, LLC',
    name: 'EMERGING GLOBAL ADVISORS, LLC',
    label: 'EMERGING GLOBAL ADVISORS, LLC',
  },
  {
    cik: '0001483628',
    value: '0001483628,EMERGING SOVEREIGN GROUP LLC',
    name: 'EMERGING SOVEREIGN GROUP LLC',
    label: 'EMERGING SOVEREIGN GROUP LLC',
  },
  {
    cik: '0001754001',
    value: '0001754001,EMERGING VARIANT CAPITAL MANAGEMENT LP',
    name: 'EMERGING VARIANT CAPITAL MANAGEMENT LP',
    label: 'EMERGING VARIANT CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0000774602',
    value: '0000774602,EMERSON INVESTMENT MANAGEMENT INC',
    name: 'EMERSON INVESTMENT MANAGEMENT INC',
    label: 'EMERSON INVESTMENT MANAGEMENT INC',
  },
  {
    cik: '0001725357',
    value: '0001725357,EMERSON POINT CAPITAL LP',
    name: 'EMERSON POINT CAPITAL LP',
    label: 'EMERSON POINT CAPITAL LP',
  },
  {
    cik: '0001766671',
    value: '0001766671,EMERSON WEALTH, LLC',
    name: 'EMERSON WEALTH, LLC',
    label: 'EMERSON WEALTH, LLC',
  },
  {
    cik: '0001766671',
    value: '0001766671,EMERSON WEALTH MANAGEMENT, LLC',
    name: 'EMERSON WEALTH MANAGEMENT, LLC',
    label: 'EMERSON WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001495703',
    value: '0001495703,EMERY HOWARD PORTFOLIO MANAGEMENT, INC.',
    name: 'EMERY HOWARD PORTFOLIO MANAGEMENT, INC.',
    label: 'EMERY HOWARD PORTFOLIO MANAGEMENT, INC.',
  },
  {
    cik: '0001859434',
    value: '00018594,EMFO, LLC',
    name: 'EMFO, LLC',
    label: 'EMFO, LLC',
  },
  {
    cik: '0001478394',
    value: '0001478394,EMG HOLDINGS, L.P.',
    name: 'EMG HOLDINGS, L.P.',
    label: 'EMG HOLDINGS, L.P.',
  },
  {
    cik: '0001107310',
    value: '0001107310,EMINENCE CAPITAL LLC',
    name: 'EMINENCE CAPITAL LLC',
    label: 'EMINENCE CAPITAL LLC',
  },
  {
    cik: '0001107310',
    value: '0001107310,EMINENCE CAPITAL, LP',
    name: 'EMINENCE CAPITAL, LP',
    label: 'EMINENCE CAPITAL, LP',
  },
  {
    cik: '0001838289',
    value: '0001838289,EMJ CAPITAL LTD.',
    name: 'EMJ CAPITAL LTD.',
    label: 'EMJ CAPITAL LTD.',
  },
  {
    cik: '0001377635',
    value: '0001377635,EMORY UNIVERSITY',
    name: 'EMORY UNIVERSITY',
    label: 'EMORY UNIVERSITY',
  },
  {
    cik: '0001469336',
    value: '0001469336,EMPERY ASSET MANAGEMENT, LP',
    name: 'EMPERY ASSET MANAGEMENT, LP',
    label: 'EMPERY ASSET MANAGEMENT, LP',
  },
  {
    cik: '0001321711',
    value: '0001321711,EMPIRE CAPITAL MANAGEMENT, L.L.C.',
    name: 'EMPIRE CAPITAL MANAGEMENT, L.L.C.',
    label: 'EMPIRE CAPITAL MANAGEMENT, L.L.C.',
  },
  {
    cik: '0001640951',
    value: '0001640951,EMPIRE LIFE INVESTMENTS INC.',
    name: 'EMPIRE LIFE INVESTMENTS INC.',
    label: 'EMPIRE LIFE INVESTMENTS INC.',
  },
  {
    cik: '0001926596',
    value: '0001926596,EMPIRICAL ASSET MANAGEMENT, LLC',
    name: 'EMPIRICAL ASSET MANAGEMENT, LLC',
    label: 'EMPIRICAL ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001642274',
    value: '0001642274,EMPIRICAL CAPITAL MANAGEMENT, LLC',
    name: 'EMPIRICAL CAPITAL MANAGEMENT, LLC',
    label: 'EMPIRICAL CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001117101',
    value: '0001117101,EMPIRICAL CAPITAL PARTNERS LP',
    name: 'EMPIRICAL CAPITAL PARTNERS LP',
    label: 'EMPIRICAL CAPITAL PARTNERS LP',
  },
  {
    cik: '0001572838',
    value: '0001572838,EMPIRICAL FINANCE, LLC',
    name: 'EMPIRICAL FINANCE, LLC',
    label: 'EMPIRICAL FINANCE, LLC',
  },
  {
    cik: '0001510434',
    value: '0001510434,EMPIRICAL FINANCIAL SERVICES, LLC',
    name: 'EMPIRICAL FINANCIAL SERVICES, LLC',
    label: 'EMPIRICAL FINANCIAL SERVICES, LLC',
  },
  {
    cik: '0001510434',
    value:
      '0001510434,EMPIRICAL FINANCIAL SERVICES, LLC D.B.A. EMPIRICAL WEALTH MANAGEMENT',
    name: 'EMPIRICAL FINANCIAL SERVICES, LLC D.B.A. EMPIRICAL WEALTH MANAGEMENT',
    label:
      'EMPIRICAL FINANCIAL SERVICES, LLC D.B.A. EMPIRICAL WEALTH MANAGEMENT',
  },
  {
    cik: '0001398739',
    value: '0001398739,EMPLOYEES RETIREMENT SYSTEM OF TEXAS',
    name: 'EMPLOYEES RETIREMENT SYSTEM OF TEXAS',
    label: 'EMPLOYEES RETIREMENT SYSTEM OF TEXAS',
  },
  {
    cik: '0001379041',
    value: '0001379041,EMPLOYERS HOLDINGS, INC.',
    name: 'EMPLOYERS HOLDINGS, INC.',
    label: 'EMPLOYERS HOLDINGS, INC.',
  },
  {
    cik: '0001592828',
    value: '0001592828,EMPOWERED FUNDS, LLC',
    name: 'EMPOWERED FUNDS, LLC',
    label: 'EMPOWERED FUNDS, LLC',
  },
  {
    cik: '0001317195',
    value: '0001317195,EMPYREAN CAPITAL PARTNERS, LP',
    name: 'EMPYREAN CAPITAL PARTNERS, LP',
    label: 'EMPYREAN CAPITAL PARTNERS, LP',
  },
  {
    cik: '0001581146',
    value: '0001581146,EMRYS PARTNERS, L.P.',
    name: 'EMRYS PARTNERS, L.P.',
    label: 'EMRYS PARTNERS, L.P.',
  },
  {
    cik: '0001441843',
    value: '0001441843,EMS CAPITAL LP',
    name: 'EMS CAPITAL LP',
    label: 'EMS CAPITAL LP',
  },
  {
    cik: '0001595951',
    value: '0001595951,EMSO ASSET MANAGEMENT LTD',
    name: 'EMSO ASSET MANAGEMENT LTD',
    label: 'EMSO ASSET MANAGEMENT LTD',
  },
  {
    cik: '0001713698',
    value: '0001713698,ENA INVESTMENT CAPITAL LLP',
    name: 'ENA INVESTMENT CAPITAL LLP',
    label: 'ENA INVESTMENT CAPITAL LLP',
  },
  {
    cik: '0001565683',
    value: '0001565683,ENCAP ENERGY CAPITAL FUND IX, L.P.',
    name: 'ENCAP ENERGY CAPITAL FUND IX, L.P.',
    label: 'ENCAP ENERGY CAPITAL FUND IX, L.P.',
  },
  {
    cik: '0001537403',
    value: '0001537403,ENCAP ENERGY CAPITAL FUND VIII CO-INVESTORS, L.P.',
    name: 'ENCAP ENERGY CAPITAL FUND VIII CO-INVESTORS, L.P.',
    label: 'ENCAP ENERGY CAPITAL FUND VIII CO-INVESTORS, L.P.',
  },
  {
    cik: '0001504103',
    value: '0001504103,ENCAP ENERGY CAPITAL FUND VIII, L.P.',
    name: 'ENCAP ENERGY CAPITAL FUND VIII, L.P.',
    label: 'ENCAP ENERGY CAPITAL FUND VIII, L.P.',
  },
  {
    cik: '0001693946',
    value: '0001693946,ENCAP ENERGY CAPITAL FUND XI, L.P.',
    name: 'ENCAP ENERGY CAPITAL FUND XI, L.P.',
    label: 'ENCAP ENERGY CAPITAL FUND XI, L.P.',
  },
  {
    cik: '0001636722',
    value: '0001636722,ENCAP ENERGY CAPITAL FUND X, L.P.',
    name: 'ENCAP ENERGY CAPITAL FUND X, L.P.',
    label: 'ENCAP ENERGY CAPITAL FUND X, L.P.',
  },
  {
    cik: '0001541901',
    value: '0001541901,ENCOMPASS CAPITAL ADVISORS LLC',
    name: 'ENCOMPASS CAPITAL ADVISORS LLC',
    label: 'ENCOMPASS CAPITAL ADVISORS LLC',
  },
  {
    cik: '0001849614',
    value: '0001849614,ENCOMPASS WEALTH ADVISORS, LLC',
    name: 'ENCOMPASS WEALTH ADVISORS, LLC',
    label: 'ENCOMPASS WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001218749',
    value: '0001218749,ENDEAVOUR CAPITAL ADVISORS INC',
    name: 'ENDEAVOUR CAPITAL ADVISORS INC',
    label: 'ENDEAVOUR CAPITAL ADVISORS INC',
  },
  {
    cik: '0001107799',
    value: '0001107799,ENDEX CAPITAL MANAGEMENT LLC',
    name: 'ENDEX CAPITAL MANAGEMENT LLC',
    label: 'ENDEX CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001165248',
    value: '0001165248,ENDICOTT MANAGEMENT CO',
    name: 'ENDICOTT MANAGEMENT CO',
    label: 'ENDICOTT MANAGEMENT CO',
  },
  {
    cik: '0001904477',
    value: '0001904477,ENDOWMENT WEALTH MANAGEMENT, INC.',
    name: 'ENDOWMENT WEALTH MANAGEMENT, INC.',
    label: 'ENDOWMENT WEALTH MANAGEMENT, INC.',
  },
  {
    cik: '0001476225',
    value: '0001476225,ENDURANCE WEALTH MANAGEMENT, INC.',
    name: 'ENDURANCE WEALTH MANAGEMENT, INC.',
    label: 'ENDURANCE WEALTH MANAGEMENT, INC.',
  },
  {
    cik: '0001633055',
    value: '0001633055,ENDURANT CAPITAL MANAGEMENT LP',
    name: 'ENDURANT CAPITAL MANAGEMENT LP',
    label: 'ENDURANT CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001621292',
    value: '0001621292,ENERGY CAPITAL PARTNERS III, LLC',
    name: 'ENERGY CAPITAL PARTNERS III, LLC',
    label: 'ENERGY CAPITAL PARTNERS III, LLC',
  },
  {
    cik: '0001388814',
    value: '0001388814,ENERGY INCOME PARTNERS, LLC',
    name: 'ENERGY INCOME PARTNERS, LLC',
    label: 'ENERGY INCOME PARTNERS, LLC',
  },
  {
    cik: '0001505791',
    value: '0001505791,ENERGY OPPORTUNITIES CAPITAL MANAGEMENT, LLC',
    name: 'ENERGY OPPORTUNITIES CAPITAL MANAGEMENT, LLC',
    label: 'ENERGY OPPORTUNITIES CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001375345',
    value: '0001375345,ENERVEST, LTD.',
    name: 'ENERVEST, LTD.',
    label: 'ENERVEST, LTD.',
  },
  {
    cik: '0001729213',
    value: '0001729213,ENGADINE PARTNERS LLP',
    name: 'ENGADINE PARTNERS LLP',
    label: 'ENGADINE PARTNERS LLP',
  },
  {
    cik: '0001559771',
    value: '0001559771,ENGAGED CAPITAL LLC',
    name: 'ENGAGED CAPITAL LLC',
    label: 'ENGAGED CAPITAL LLC',
  },
  {
    cik: '0001665590',
    value: '0001665590,ENGINE CAPITAL MANAGEMENT, LLC',
    name: 'ENGINE CAPITAL MANAGEMENT, LLC',
    label: 'ENGINE CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001665590',
    value: '0001665590,ENGINE CAPITAL MANAGEMENT, LP',
    name: 'ENGINE CAPITAL MANAGEMENT, LP',
    label: 'ENGINE CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0001620220',
    value: '0001620220,ENGINEERS GATE MANAGER LP',
    name: 'ENGINEERS GATE MANAGER LP',
    label: 'ENGINEERS GATE MANAGER LP',
  },
  {
    cik: '0001835549',
    value: '0001835549,ENGINE NO. 1 LLC',
    name: 'ENGINE NO. 1 LLC',
    label: 'ENGINE NO. 1 LLC',
  },
  {
    cik: '0001684868',
    value: '0001684868,ENGLE CAPITAL MANAGEMENT, L.P.',
    name: 'ENGLE CAPITAL MANAGEMENT, L.P.',
    label: 'ENGLE CAPITAL MANAGEMENT, L.P.',
  },
  {
    cik: '0001806222',
    value: '0001806222,ENGRAVE WEALTH PARTNERS LLC',
    name: 'ENGRAVE WEALTH PARTNERS LLC',
    label: 'ENGRAVE WEALTH PARTNERS LLC',
  },
  {
    cik: '0001909572',
    value: '0001909572,ENHANCING CAPITAL LLC',
    name: 'ENHANCING CAPITAL LLC',
    label: 'ENHANCING CAPITAL LLC',
  },
  {
    cik: '0001676924',
    value: '0001676924,ENLIGHTENMENT RESEARCH, LLC',
    name: 'ENLIGHTENMENT RESEARCH, LLC',
    label: 'ENLIGHTENMENT RESEARCH, LLC',
  },
  {
    cik: '0001802946',
    value: '0001802946,ENNISMORE FUND MANAGEMENT LTD',
    name: 'ENNISMORE FUND MANAGEMENT LTD',
    label: 'ENNISMORE FUND MANAGEMENT LTD',
  },
  {
    cik: '0001387366',
    value: '0001387366,ENSEMBLE CAPITAL MANAGEMENT, LLC',
    name: 'ENSEMBLE CAPITAL MANAGEMENT, LLC',
    label: 'ENSEMBLE CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001454984',
    value: '0001454984,ENSIGN PEAK ADVISORS, INC',
    name: 'ENSIGN PEAK ADVISORS, INC',
    label: 'ENSIGN PEAK ADVISORS, INC',
  },
  {
    cik: '0001363829',
    value: '0001363829,ENSTAR GROUP LTD',
    name: 'ENSTAR GROUP LTD',
    label: 'ENSTAR GROUP LTD',
  },
  {
    cik: '0001770710',
    value: '0001770710,ENTERPRISE BANK & TRUST CO',
    name: 'ENTERPRISE BANK & TRUST CO',
    label: 'ENTERPRISE BANK & TRUST CO',
  },
  {
    cik: '0001025835',
    value: '0001025835,ENTERPRISE FINANCIAL SERVICES CORP',
    name: 'ENTERPRISE FINANCIAL SERVICES CORP',
    label: 'ENTERPRISE FINANCIAL SERVICES CORP',
  },
  {
    cik: '0001569151',
    value: '0001569151,ENTERPRISE INVESTORS CORP',
    name: 'ENTERPRISE INVESTORS CORP',
    label: 'ENTERPRISE INVESTORS CORP',
  },
  {
    cik: '0001777127',
    value: '0001777127,ENTERPRISE TRUST & INVESTMENT CO',
    name: 'ENTERPRISE TRUST & INVESTMENT CO',
    label: 'ENTERPRISE TRUST & INVESTMENT CO',
  },
  {
    cik: '0001080818',
    value: '0001080818,ENTRUST GLOBAL PARTNERS L L C',
    name: 'ENTRUST GLOBAL PARTNERS L L C',
    label: 'ENTRUST GLOBAL PARTNERS L L C',
  },
  {
    cik: '0001727111',
    value: '0001727111,ENTRUST GLOBAL PARTNERS OFFSHORE LP',
    name: 'ENTRUST GLOBAL PARTNERS OFFSHORE LP',
    label: 'ENTRUST GLOBAL PARTNERS OFFSHORE LP',
  },
  {
    cik: '0001438129',
    value: '0001438129,ENTRUSTPERMAL MANAGEMENT LLC',
    name: 'ENTRUSTPERMAL MANAGEMENT LLC',
    label: 'ENTRUSTPERMAL MANAGEMENT LLC',
  },
  {
    cik: '0001727111',
    value: '0001727111,ENTRUSTPERMAL PARTNERS OFFSHORE LP',
    name: 'ENTRUSTPERMAL PARTNERS OFFSHORE LP',
    label: 'ENTRUSTPERMAL PARTNERS OFFSHORE LP',
  },
  {
    cik: '0001407543',
    value: '0001407543,ENVESTNET ASSET MANAGEMENT INC',
    name: 'ENVESTNET ASSET MANAGEMENT INC',
    label: 'ENVESTNET ASSET MANAGEMENT INC',
  },
  {
    cik: '0001887441',
    value: '0001887441,ENZI WEALTH',
    name: 'ENZI WEALTH',
    label: 'ENZI WEALTH',
  },
  {
    cik: '0001580572',
    value: '0001580572,EOS FOCUSED EQUITY MANAGEMENT, L.P.',
    name: 'EOS FOCUSED EQUITY MANAGEMENT, L.P.',
    label: 'EOS FOCUSED EQUITY MANAGEMENT, L.P.',
  },
  {
    cik: '0001548882',
    value: '0001548882,EOS MANAGEMENT, L.P.',
    name: 'EOS MANAGEMENT, L.P.',
    label: 'EOS MANAGEMENT, L.P.',
  },
  {
    cik: '0001803058',
    value: '0001803058,EPG WEALTH MANAGEMENT LLC',
    name: 'EPG WEALTH MANAGEMENT LLC',
    label: 'EPG WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0001875953',
    value: '0001875953,EPIC TRUST INVESTMENT ADVISORS, LLC',
    name: 'EPIC TRUST INVESTMENT ADVISORS, LLC',
    label: 'EPIC TRUST INVESTMENT ADVISORS, LLC',
  },
  {
    cik: '0001779453',
    value: '0001779453,EPIQ CAPITAL GROUP, LLC',
    name: 'EPIQ CAPITAL GROUP, LLC',
    label: 'EPIQ CAPITAL GROUP, LLC',
  },
  {
    cik: '0001799435',
    value: '0001799435,EPIQ PARTNERS, LLC',
    name: 'EPIQ PARTNERS, LLC',
    label: 'EPIQ PARTNERS, LLC',
  },
  {
    cik: '0001305841',
    value: '0001305841,EPOCH INVESTMENT PARTNERS, INC.',
    name: 'EPOCH INVESTMENT PARTNERS, INC.',
    label: 'EPOCH INVESTMENT PARTNERS, INC.',
  },
  {
    cik: '0001713936',
    value: '0001713936,EPSTEIN & WHITE FINANCIAL LLC',
    name: 'EPSTEIN & WHITE FINANCIAL LLC',
    label: 'EPSTEIN & WHITE FINANCIAL LLC',
  },
  {
    cik: '0001540569',
    value: '0001540569,EP WEALTH ADVISORS, INC.',
    name: 'EP WEALTH ADVISORS, INC.',
    label: 'EP WEALTH ADVISORS, INC.',
  },
  {
    cik: '0001540569',
    value: '0001540569,EP WEALTH ADVISORS, LLC',
    name: 'EP WEALTH ADVISORS, LLC',
    label: 'EP WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001566653',
    value: '0001566653,EQIS CAPITAL MANAGEMENT, INC.',
    name: 'EQIS CAPITAL MANAGEMENT, INC.',
    label: 'EQIS CAPITAL MANAGEMENT, INC.',
  },
  {
    cik: '0001757605',
    value: '00017,EQ LLC605',
    name: 'EQ LLC',
    label: 'EQ LLC',
  },
  {
    cik: '0001834239',
    value: '0001834239,EQT FUND MANAGEMENT S.A R.L.',
    name: 'EQT FUND MANAGEMENT S.A R.L.',
    label: 'EQT FUND MANAGEMENT S.A R.L.',
  },
  {
    cik: '0001325558',
    value: '0001325558,EQUINOX FUND MANAGEMENT LLC',
    name: 'EQUINOX FUND MANAGEMENT LLC',
    label: 'EQUINOX FUND MANAGEMENT LLC',
  },
  {
    cik: '0001571222',
    value: '0001571222,EQUINOX INSTITUTIONAL ASSET MANAGEMENT, LP',
    name: 'EQUINOX INSTITUTIONAL ASSET MANAGEMENT, LP',
    label: 'EQUINOX INSTITUTIONAL ASSET MANAGEMENT, LP',
  },
  {
    cik: '0000938552',
    value: '0000938552,EQUINOX PARTNERS INVESTMENT MANAGEMENT LLC',
    name: 'EQUINOX PARTNERS INVESTMENT MANAGEMENT LLC',
    label: 'EQUINOX PARTNERS INVESTMENT MANAGEMENT LLC',
  },
  {
    cik: '0001333986',
    value: '0001333986,EQUITABLE HOLDINGS, INC.',
    name: 'EQUITABLE HOLDINGS, INC.',
    label: 'EQUITABLE HOLDINGS, INC.',
  },
  {
    cik: '0000033250',
    value: '0000033250,EQUITABLE TRUST CO',
    name: 'EQUITABLE TRUST CO',
    label: 'EQUITABLE TRUST CO',
  },
  {
    cik: '0001143261',
    value: '0001143261,EQUITEC PROPRIETARY MARKETS, LLC',
    name: 'EQUITEC PROPRIETARY MARKETS, LLC',
    label: 'EQUITEC PROPRIETARY MARKETS, LLC',
  },
  {
    cik: '0001108829',
    value: '0001108829,EQUITEC SPECIALISTS, LLC',
    name: 'EQUITEC SPECIALISTS, LLC',
    label: 'EQUITEC SPECIALISTS, LLC',
  },
  {
    cik: '0001685771',
    value: '0001685771,EQUITY INVESTMENT CORP',
    name: 'EQUITY INVESTMENT CORP',
    label: 'EQUITY INVESTMENT CORP',
  },
  {
    cik: '0001093694',
    value: '0001093694,EQUITY INVESTMENT CORP/GA',
    name: 'EQUITY INVESTMENT CORP/GA',
    label: 'EQUITY INVESTMENT CORP/GA',
  },
  {
    cik: '0001911084',
    value: '0001911084,EQUIUS PARTNERS, INC.',
    name: 'EQUIUS PARTNERS, INC.',
    label: 'EQUIUS PARTNERS, INC.',
  },
  {
    cik: '0001569136',
    value: '0001569136,ERGOTELES LLC',
    name: 'ERGOTELES LLC',
    label: 'ERGOTELES LLC',
  },
  {
    cik: '0001797734',
    value: '0001797734,ERICKSON FINANCIAL GROUP, LLC',
    name: 'ERICKSON FINANCIAL GROUP, LLC',
    label: 'ERICKSON FINANCIAL GROUP, LLC',
  },
  {
    cik: '0001768302',
    value: '0001768302,ERN FINANCIAL, LLC',
    name: 'ERN FINANCIAL, LLC',
    label: 'ERN FINANCIAL, LLC',
  },
  {
    cik: '0001929660',
    value: '000192,ERN LLC60',
    name: 'ERN LLC',
    label: 'ERN LLC',
  },
  {
    cik: '0001767602',
    value: '0001767602,ERTS WEALTH ADVISORS, LLC',
    name: 'ERTS WEALTH ADVISORS, LLC',
    label: 'ERTS WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001907802',
    value: '0001907802,ERVIN INVESTMENT MANAGEMENT, LLC',
    name: 'ERVIN INVESTMENT MANAGEMENT, LLC',
    label: 'ERVIN INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001802934',
    value: '0001802934,ESA GLOBAL VALUE FUND',
    name: 'ESA GLOBAL VALUE FUND',
    label: 'ESA GLOBAL VALUE FUND',
  },
  {
    cik: '0001910874',
    value: '0001910874,ESCHLER ASSET MANAGEMENT LLP',
    name: 'ESCHLER ASSET MANAGEMENT LLP',
    label: 'ESCHLER ASSET MANAGEMENT LLP',
  },
  {
    cik: '0001931465',
    value: '0001931465,ESG PLANNING',
    name: 'ESG PLANNING',
    label: 'ESG PLANNING',
  },
  {
    cik: '0001785545',
    value: '0001785545,ESL TRUST SERVICES, LLC',
    name: 'ESL TRUST SERVICES, LLC',
    label: 'ESL TRUST SERVICES, LLC',
  },
  {
    cik: '0001533327',
    value: '0001533327,ESPALIER GLOBAL MANAGEMENT LLC',
    name: 'ESPALIER GLOBAL MANAGEMENT LLC',
    label: 'ESPALIER GLOBAL MANAGEMENT LLC',
  },
  {
    cik: '0001949824',
    value: '0001949824,ESSENTIAL PLANNING, LLC.',
    name: 'ESSENTIAL PLANNING, LLC.',
    label: 'ESSENTIAL PLANNING, LLC.',
  },
  {
    cik: '0001315785',
    value: '0001315785,ESSEX FINANCIAL SERVICES, INC.',
    name: 'ESSEX FINANCIAL SERVICES, INC.',
    label: 'ESSEX FINANCIAL SERVICES, INC.',
  },
  {
    cik: '0000201772',
    value: '0000201772,ESSEX INVESTMENT MANAGEMENT CO LLC',
    name: 'ESSEX INVESTMENT MANAGEMENT CO LLC',
    label: 'ESSEX INVESTMENT MANAGEMENT CO LLC',
  },
  {
    cik: '0001889147',
    value: '00018891,ESSEX LLC',
    name: 'ESSEX LLC',
    label: 'ESSEX LLC',
  },
  {
    cik: '0001803087',
    value: '0001803087,ESSEX PRIVATE WEALTH MANAGEMENT, LLC',
    name: 'ESSEX PRIVATE WEALTH MANAGEMENT, LLC',
    label: 'ESSEX PRIVATE WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001752212',
    value: '0001752212,ESSEX SAVINGS BANK',
    name: 'ESSEX SAVINGS BANK',
    label: 'ESSEX SAVINGS BANK',
  },
  {
    cik: '0001542339',
    value: '0001542339,ESSEX WOODLANDS HEALTH VENTURES, INC.',
    name: 'ESSEX WOODLANDS HEALTH VENTURES, INC.',
    label: 'ESSEX WOODLANDS HEALTH VENTURES, INC.',
  },
  {
    cik: '0001542339',
    value: '0001542339,ESSEX WOODLANDS MANAGEMENT, INC.',
    name: 'ESSEX WOODLANDS MANAGEMENT, INC.',
    label: 'ESSEX WOODLANDS MANAGEMENT, INC.',
  },
  {
    cik: '0001344717',
    value: '0001344717,ESTABROOK CAPITAL MANAGEMENT',
    name: 'ESTABROOK CAPITAL MANAGEMENT',
    label: 'ESTABROOK CAPITAL MANAGEMENT',
  },
  {
    cik: '0001559832',
    value: '0001559832,ESTATE COUNSELORS, LLC',
    name: 'ESTATE COUNSELORS, LLC',
    label: 'ESTATE COUNSELORS, LLC',
  },
  {
    cik: '0001645173',
    value: '0001645173,ETF MANAGERS GROUP, LLC',
    name: 'ETF MANAGERS GROUP, LLC',
    label: 'ETF MANAGERS GROUP, LLC',
  },
  {
    cik: '0001635342',
    value: '0001635342,ETF PORTFOLIO PARTNERS, INC.',
    name: 'ETF PORTFOLIO PARTNERS, INC.',
    label: 'ETF PORTFOLIO PARTNERS, INC.',
  },
  {
    cik: '0001911470',
    value: '0001911470,ETF STORE, INC.',
    name: 'ETF STORE, INC.',
    label: 'ETF STORE, INC.',
  },
  {
    cik: '0001780570',
    value: '000178057,ETHIC INC.',
    name: 'ETHIC INC.',
    label: 'ETHIC INC.',
  },
  {
    cik: '0001314588',
    value: '0001314588,ETON PARK CAPITAL MANAGEMENT, L.P.',
    name: 'ETON PARK CAPITAL MANAGEMENT, L.P.',
    label: 'ETON PARK CAPITAL MANAGEMENT, L.P.',
  },
  {
    cik: '0001512581',
    value: '0001512581,ETRADE CAPITAL MANAGEMENT LLC',
    name: 'ETRADE CAPITAL MANAGEMENT LLC',
    label: 'ETRADE CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001080382',
    value: '0001080382,EUBEL BRADY & SUTTMAN ASSET MANAGEMENT INC',
    name: 'EUBEL BRADY & SUTTMAN ASSET MANAGEMENT INC',
    label: 'EUBEL BRADY & SUTTMAN ASSET MANAGEMENT INC',
  },
  {
    cik: '0001534491',
    value: '0001534491,EUCLID ADVISORS LLC',
    name: 'EUCLID ADVISORS LLC',
    label: 'EUCLID ADVISORS LLC',
  },
  {
    cik: '0001825034',
    value: '0001825034,EUCLIDEAN CAPITAL LLC',
    name: 'EUCLIDEAN CAPITAL LLC',
    label: 'EUCLIDEAN CAPITAL LLC',
  },
  {
    cik: '0001597864',
    value: '0001597864,EUCLIDEAN TECHNOLOGIES MANAGEMENT, LLC',
    name: 'EUCLIDEAN TECHNOLOGIES MANAGEMENT, LLC',
    label: 'EUCLIDEAN TECHNOLOGIES MANAGEMENT, LLC',
  },
  {
    cik: '0001905084',
    value: '0001905084,EUDAIMONIA ADVISORS LLC',
    name: 'EUDAIMONIA ADVISORS LLC',
    label: 'EUDAIMONIA ADVISORS LLC',
  },
  {
    cik: '0001809043',
    value: '0001809043,EUDAIMONIA PARTNERS, LLC',
    name: 'EUDAIMONIA PARTNERS, LLC',
    label: 'EUDAIMONIA PARTNERS, LLC',
  },
  {
    cik: '0001632553',
    value: '0001632553,EUKLES ASSET MANAGEMENT',
    name: 'EUKLES ASSET MANAGEMENT',
    label: 'EUKLES ASSET MANAGEMENT',
  },
  {
    cik: '0001540826',
    value: '0001540826,EULAV ASSET MANAGEMENT',
    name: 'EULAV ASSET MANAGEMENT',
    label: 'EULAV ASSET MANAGEMENT',
  },
  {
    cik: '0001796651',
    value: '0001796651,EURO PACIFIC ASSET MANAGEMENT, LLC',
    name: 'EURO PACIFIC ASSET MANAGEMENT, LLC',
    label: 'EURO PACIFIC ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001626379',
    value: '0001626379,EVANSON ASSET MANAGEMENT, LLC',
    name: 'EVANSON ASSET MANAGEMENT, LLC',
    label: 'EVANSON ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001480535',
    value: '0001480535,EVANSTON INVESTMENTS, INC., DBA EVANSTON ADVISORS',
    name: 'EVANSTON INVESTMENTS, INC., DBA EVANSTON ADVISORS',
    label: 'EVANSTON INVESTMENTS, INC., DBA EVANSTON ADVISORS',
  },
  {
    cik: '0001357867',
    value: '0001357867,EVENSKY & KATZ LLC',
    name: 'EVENSKY & KATZ LLC',
    label: 'EVENSKY & KATZ LLC',
  },
  {
    cik: '0001442891',
    value: '0001442891,EVENTIDE ASSET MANAGEMENT, LLC',
    name: 'EVENTIDE ASSET MANAGEMENT, LLC',
    label: 'EVENTIDE ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001466052',
    value: '0001466052,EVERCORE TRUST COMPANY, N.A.',
    name: 'EVERCORE TRUST COMPANY, N.A.',
    label: 'EVERCORE TRUST COMPANY, N.A.',
  },
  {
    cik: '0001482689',
    value: '0001482689,EVERCORE WEALTH MANAGEMENT, LLC',
    name: 'EVERCORE WEALTH MANAGEMENT, LLC',
    label: 'EVERCORE WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001056825',
    value: '0001056825,EVERENCE CAPITAL MANAGEMENT INC',
    name: 'EVERENCE CAPITAL MANAGEMENT INC',
    label: 'EVERENCE CAPITAL MANAGEMENT INC',
  },
  {
    cik: '0001498602',
    value: '0001498602,EVEREST CAPITAL LLC',
    name: 'EVEREST CAPITAL LLC',
    label: 'EVEREST CAPITAL LLC',
  },
  {
    cik: '0001672556',
    value: '0001672556,EVERETT CAPITAL ADVISORS UK LLP',
    name: 'EVERETT CAPITAL ADVISORS UK LLP',
    label: 'EVERETT CAPITAL ADVISORS UK LLP',
  },
  {
    cik: '0001009254',
    value: '0001009254,EVERETT HARRIS & CO /CA/',
    name: 'EVERETT HARRIS & CO /CA/',
    label: 'EVERETT HARRIS & CO /CA/',
  },
  {
    cik: '0001762294',
    value: '0001762294,EVERGREEN ADVISORS, LLC',
    name: 'EVERGREEN ADVISORS, LLC',
    label: 'EVERGREEN ADVISORS, LLC',
  },
  {
    cik: '0001260824',
    value: '0001260824,EVERGREEN CAPITAL MANAGEMENT LLC',
    name: 'EVERGREEN CAPITAL MANAGEMENT LLC',
    label: 'EVERGREEN CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001910942',
    value: '0001910942,EVERGREEN VENTURE PARTNERS LTD.',
    name: 'EVERGREEN VENTURE PARTNERS LTD.',
    label: 'EVERGREEN VENTURE PARTNERS LTD.',
  },
  {
    cik: '0001904432',
    value: '0001904432,EVERGREEN WEALTH SOLUTIONS, LLC',
    name: 'EVERGREEN WEALTH SOLUTIONS, LLC',
    label: 'EVERGREEN WEALTH SOLUTIONS, LLC',
  },
  {
    cik: '0001852858',
    value: '0001852858,EVERHART FINANCIAL GROUP, INC.',
    name: 'EVERHART FINANCIAL GROUP, INC.',
    label: 'EVERHART FINANCIAL GROUP, INC.',
  },
  {
    cik: '0001066816',
    value: '0001066816,EVERMAY WEALTH MANAGEMENT LLC',
    name: 'EVERMAY WEALTH MANAGEMENT LLC',
    label: 'EVERMAY WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0001479983',
    value: '0001479983,EVERMORE GLOBAL ADVISORS, LLC',
    name: 'EVERMORE GLOBAL ADVISORS, LLC',
    label: 'EVERMORE GLOBAL ADVISORS, LLC',
  },
  {
    cik: '0001603464',
    value: '0001603464,EVERPOINT ASSET MANAGEMENT, LLC',
    name: 'EVERPOINT ASSET MANAGEMENT, LLC',
    label: 'EVERPOINT ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001697013',
    value: '0001697013,EVERSEPT PARTNERS, LLC',
    name: 'EVERSEPT PARTNERS, LLC',
    label: 'EVERSEPT PARTNERS, LLC',
  },
  {
    cik: '0001697013',
    value: '0001697013,EVERSEPT PARTNERS, LP',
    name: 'EVERSEPT PARTNERS, LP',
    label: 'EVERSEPT PARTNERS, LP',
  },
  {
    cik: '0001710537',
    value: '0001710537,EVERSOURCE WEALTH ADVISORS, LLC',
    name: 'EVERSOURCE WEALTH ADVISORS, LLC',
    label: 'EVERSOURCE WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001793432',
    value: '0001793432,EVOKE WEALTH, LLC',
    name: 'EVOKE WEALTH, LLC',
    label: 'EVOKE WEALTH, LLC',
  },
  {
    cik: '0001906967',
    value: '0001906967,EVOLUTION ADVISERS, INC.',
    name: 'EVOLUTION ADVISERS, INC.',
    label: 'EVOLUTION ADVISERS, INC.',
  },
  {
    cik: '0001844345',
    value: '0001844345,EVOLUTIONARY TREE CAPITAL MANAGEMENT, LLC',
    name: 'EVOLUTIONARY TREE CAPITAL MANAGEMENT, LLC',
    label: 'EVOLUTIONARY TREE CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001388294',
    value: '0001388294,EVOLUTION CAPITAL MANAGEMENT LLC',
    name: 'EVOLUTION CAPITAL MANAGEMENT LLC',
    label: 'EVOLUTION CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001763121',
    value: '0001763121,EVOLUTION WEALTH ADVISORS, LLC',
    name: 'EVOLUTION WEALTH ADVISORS, LLC',
    label: 'EVOLUTION WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001843019',
    value: '0001843019,EVR RESEARCH LP',
    name: 'EVR RESEARCH LP',
    label: 'EVR RESEARCH LP',
  },
  {
    cik: '0001765278',
    value: '0001765278,EWG ELEVATE INC.',
    name: 'EWG ELEVATE INC.',
    label: 'EWG ELEVATE INC.',
  },
  {
    cik: '0001846838',
    value: '0001846838,EWING MORRIS & CO. INVESTMENT PARTNERS LTD.',
    name: 'EWING MORRIS & CO. INVESTMENT PARTNERS LTD.',
    label: 'EWING MORRIS & CO. INVESTMENT PARTNERS LTD.',
  },
  {
    cik: '0001666256',
    value: '0001666256,EXANE ASSET MANAGEMENT',
    name: 'EXANE ASSET MANAGEMENT',
    label: 'EXANE ASSET MANAGEMENT',
  },
  {
    cik: '0001623883',
    value: '0001623883,EXANE DERIVATIVES',
    name: 'EXANE DERIVATIVES',
    label: 'EXANE DERIVATIVES',
  },
  {
    cik: '0001009003',
    value: '0001009003,EXCALIBUR MANAGEMENT CORP',
    name: 'EXCALIBUR MANAGEMENT CORP',
    label: 'EXCALIBUR MANAGEMENT CORP',
  },
  {
    cik: '0001372365',
    value: '0001372365,EXCELSIA, INC.',
    name: 'EXCELSIA, INC.',
    label: 'EXCELSIA, INC.',
  },
  {
    cik: '0001317733',
    value: '0001317733,EXCHANGE CAPITAL MANAGEMENT, INC.',
    name: 'EXCHANGE CAPITAL MANAGEMENT, INC.',
    label: 'EXCHANGE CAPITAL MANAGEMENT, INC.',
  },
  {
    cik: '0001457320',
    value: '0001457320,EXCHANGE TRADED CONCEPTS, LLC',
    name: 'EXCHANGE TRADED CONCEPTS, LLC',
    label: 'EXCHANGE TRADED CONCEPTS, LLC',
  },
  {
    cik: '0001542611',
    value: '0001542611,EXECUTIVE WEALTH MANAGEMENT, LLC',
    name: 'EXECUTIVE WEALTH MANAGEMENT, LLC',
    label: 'EXECUTIVE WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001109357',
    value: '0001109357,EXELON CORP',
    name: 'EXELON CORP',
    label: 'EXELON CORP',
  },
  {
    cik: '0001576151',
    value: '0001576151,EXENCIAL WEALTH ADVISORS, LLC',
    name: 'EXENCIAL WEALTH ADVISORS, LLC',
    label: 'EXENCIAL WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001752045',
    value: '0001752045,EXETER FINANCIAL, LLC',
    name: 'EXETER FINANCIAL, LLC',
    label: 'EXETER FINANCIAL, LLC',
  },
  {
    cik: '0001736225',
    value: '0001736225,EXODUSPOINT CAPITAL MANAGEMENT, LP',
    name: 'EXODUSPOINT CAPITAL MANAGEMENT, LP',
    label: 'EXODUSPOINT CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0001732768',
    value: '0001732768,EXOR CAPITAL LLP',
    name: 'EXOR CAPITAL LLP',
    label: 'EXOR CAPITAL LLP',
  },
  {
    cik: '0001834630',
    value: '0001834630,EXOS ASSET MANAGEMENT LLC',
    name: 'EXOS ASSET MANAGEMENT LLC',
    label: 'EXOS ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001877521',
    value: '0001877521,EXOS TFP HOLDINGS LLC',
    name: 'EXOS TFP HOLDINGS LLC',
    label: 'EXOS TFP HOLDINGS LLC',
  },
  {
    cik: '0001082970',
    value: '0001082970,EXXONMOBIL INVESTMENT MANAGEMENT INC /TX',
    name: 'EXXONMOBIL INVESTMENT MANAGEMENT INC /TX',
    label: 'EXXONMOBIL INVESTMENT MANAGEMENT INC /TX',
  },
  {
    cik: '0001733777',
    value: '0001733777,F3LOGIC, LLC',
    name: 'F3LOGIC, LLC',
    label: 'F3LOGIC, LLC',
  },
  {
    cik: '0001748271',
    value: '0001748271,FACET WEALTH, INC.',
    name: 'FACET WEALTH, INC.',
    label: 'FACET WEALTH, INC.',
  },
  {
    cik: '0001697137',
    value: '0001697137,FACTORIAL MANAGEMENT LTD',
    name: 'FACTORIAL MANAGEMENT LTD',
    label: 'FACTORIAL MANAGEMENT LTD',
  },
  {
    cik: '0001732687',
    value: '0001732687,FACTORIAL PARTNERS, LLC',
    name: 'FACTORIAL PARTNERS, LLC',
    label: 'FACTORIAL PARTNERS, LLC',
  },
  {
    cik: '0000887818',
    value: '0000887818,FACTORY MUTUAL INSURANCE CO',
    name: 'FACTORY MUTUAL INSURANCE CO',
    label: 'FACTORY MUTUAL INSURANCE CO',
  },
  {
    cik: '0001607866',
    value: '0001607866,FAGAN ASSOCIATES, INC.',
    name: 'FAGAN ASSOCIATES, INC.',
    label: 'FAGAN ASSOCIATES, INC.',
  },
  {
    cik: '0001681372',
    value: '0001681372,FAIRBANKS CAPITAL MANAGEMENT, INC.',
    name: 'FAIRBANKS CAPITAL MANAGEMENT, INC.',
    label: 'FAIRBANKS CAPITAL MANAGEMENT, INC.',
  },
  {
    cik: '0000915191',
    value: '0000915191,FAIRFAX FINANCIAL HOLDINGS LTD/ CAN',
    name: 'FAIRFAX FINANCIAL HOLDINGS LTD/ CAN',
    label: 'FAIRFAX FINANCIAL HOLDINGS LTD/ CAN',
  },
  {
    cik: '0001116247',
    value: '0001116247,FAIRFIELD, BUSH & CO.',
    name: 'FAIRFIELD, BUSH & CO.',
    label: 'FAIRFIELD, BUSH & CO.',
  },
  {
    cik: '0001116247',
    value: '0001116247,FAIRFIELD RESEARCH CORP',
    name: 'FAIRFIELD RESEARCH CORP',
    label: 'FAIRFIELD RESEARCH CORP',
  },
  {
    cik: '0001717825',
    value: '0001717825,FAIRFIELD UNIVERSITY',
    name: 'FAIRFIELD UNIVERSITY',
    label: 'FAIRFIELD UNIVERSITY',
  },
  {
    cik: '0001767056',
    value: '0001767056,FAIRHAVEN WEALTH MANAGEMENT, LLC',
    name: 'FAIRHAVEN WEALTH MANAGEMENT, LLC',
    label: 'FAIRHAVEN WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001056831',
    value: '0001056831,FAIRHOLME CAPITAL MANAGEMENT LLC',
    name: 'FAIRHOLME CAPITAL MANAGEMENT LLC',
    label: 'FAIRHOLME CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001802528',
    value: '0001802528,FAIRMOUNT FUNDS MANAGEMENT LLC',
    name: 'FAIRMOUNT FUNDS MANAGEMENT LLC',
    label: 'FAIRMOUNT FUNDS MANAGEMENT LLC',
  },
  {
    cik: '0001521422',
    value: '0001521422,FAIRPOINTE CAPITAL LLC',
    name: 'FAIRPOINTE CAPITAL LLC',
    label: 'FAIRPOINTE CAPITAL LLC',
  },
  {
    cik: '0001056549',
    value: '0001056549,FAIRVIEW CAPITAL INVESTMENT MANAGEMENT',
    name: 'FAIRVIEW CAPITAL INVESTMENT MANAGEMENT',
    label: 'FAIRVIEW CAPITAL INVESTMENT MANAGEMENT',
  },
  {
    cik: '0001056549',
    value: '0001056549,FAIRVIEW CAPITAL INVESTMENT MANAGEMENT, LLC',
    name: 'FAIRVIEW CAPITAL INVESTMENT MANAGEMENT, LLC',
    label: 'FAIRVIEW CAPITAL INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001558858',
    value: '0001558858,FALCON EDGE CAPITAL LP',
    name: 'FALCON EDGE CAPITAL LP',
    label: 'FALCON EDGE CAPITAL LP',
  },
  {
    cik: '0001251557',
    value: '0001251557,FALCON POINT CAPITAL, LLC',
    name: 'FALCON POINT CAPITAL, LLC',
    label: 'FALCON POINT CAPITAL, LLC',
  },
  {
    cik: '0001839850',
    value: '0001839850,FALCON WEALTH PLANNING',
    name: 'FALCON WEALTH PLANNING',
    label: 'FALCON WEALTH PLANNING',
  },
  {
    cik: '0001961278',
    value: '0001961278,FAMILY ASSET MANAGEMENT, LLC',
    name: 'FAMILY ASSET MANAGEMENT, LLC',
    label: 'FAMILY ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001123320',
    value: '0001123320,FAMILY CAPITAL TRUST CO',
    name: 'FAMILY CAPITAL TRUST CO',
    label: 'FAMILY CAPITAL TRUST CO',
  },
  {
    cik: '0001911695',
    value: '0001911695,FAMILY CFO INC',
    name: 'FAMILY CFO INC',
    label: 'FAMILY CFO INC',
  },
  {
    cik: '0001602603',
    value: '0001602603,FAMILY FIRM, INC.',
    name: 'FAMILY FIRM, INC.',
    label: 'FAMILY FIRM, INC.',
  },
  {
    cik: '0001897700',
    value: '0001897700,FAMILY INVESTMENT CENTER, INC.',
    name: 'FAMILY INVESTMENT CENTER, INC.',
    label: 'FAMILY INVESTMENT CENTER, INC.',
  },
  {
    cik: '0001706016',
    value: '0001706016,FAMILY LEGACY, INC.',
    name: 'FAMILY LEGACY, INC.',
    label: 'FAMILY LEGACY, INC.',
  },
  {
    cik: '0001539947',
    value: '0001539947,FAMILY MANAGEMENT CORP',
    name: 'FAMILY MANAGEMENT CORP',
    label: 'FAMILY MANAGEMENT CORP',
  },
  {
    cik: '0000909661',
    value: '0000909661,FARALLON CAPITAL MANAGEMENT LLC',
    name: 'FARALLON CAPITAL MANAGEMENT LLC',
    label: 'FARALLON CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001479465',
    value: '0001479465,FARLEY CAPITAL II L.P.',
    name: 'FARLEY CAPITAL II L.P.',
    label: 'FARLEY CAPITAL II L.P.',
  },
  {
    cik: '0001479465',
    value: '0001479465,FARLEY CAPITAL L.P.',
    name: 'FARLEY CAPITAL L.P.',
    label: 'FARLEY CAPITAL L.P.',
  },
  {
    cik: '0001259969',
    value: '0001259969,FARMERS & MERCHANTS INVESTMENTS INC',
    name: 'FARMERS & MERCHANTS INVESTMENTS INC',
    label: 'FARMERS & MERCHANTS INVESTMENTS INC',
  },
  {
    cik: '0001697375',
    value: '0001697375,FARMERS & MERCHANTS TRUST CO OF CHAMBERSBURG PA',
    name: 'FARMERS & MERCHANTS TRUST CO OF CHAMBERSBURG PA',
    label: 'FARMERS & MERCHANTS TRUST CO OF CHAMBERSBURG PA',
  },
  {
    cik: '0001737090',
    value: '0001737090,FARMERS & MERCHANTS TRUST CO OF LONG BEACH',
    name: 'FARMERS & MERCHANTS TRUST CO OF LONG BEACH',
    label: 'FARMERS & MERCHANTS TRUST CO OF LONG BEACH',
  },
  {
    cik: '0001632966',
    value: '0001632966,FARMERS NATIONAL BANK',
    name: 'FARMERS NATIONAL BANK',
    label: 'FARMERS NATIONAL BANK',
  },
  {
    cik: '0001636661',
    value: '0001636661,FARMER STEVEN PATRICK',
    name: 'FARMER STEVEN PATRICK',
    label: 'FARMER STEVEN PATRICK',
  },
  {
    cik: '0001318055',
    value: '0001318055,FARMERS TRUST CO',
    name: 'FARMERS TRUST CO',
    label: 'FARMERS TRUST CO',
  },
  {
    cik: '0001706748',
    value: '0001706748,FARMHOUSE INVESTMENT MANAGEMENT LLC',
    name: 'FARMHOUSE INVESTMENT MANAGEMENT LLC',
    label: 'FARMHOUSE INVESTMENT MANAGEMENT LLC',
  },
  {
    cik: '0001633709',
    value: '0001633709,FARMSTEAD CAPITAL MANAGEMENT, LLC',
    name: 'FARMSTEAD CAPITAL MANAGEMENT, LLC',
    label: 'FARMSTEAD CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001034184',
    value: '0001034184,FARR MILLER & WASHINGTON LLC/DC',
    name: 'FARR MILLER & WASHINGTON LLC/DC',
    label: 'FARR MILLER & WASHINGTON LLC/DC',
  },
  {
    cik: '0001596957',
    value: '0001596957,FAS WEALTH PARTNERS',
    name: 'FAS WEALTH PARTNERS',
    label: 'FAS WEALTH PARTNERS',
  },
  {
    cik: '0001512675',
    value: '0001512675,FAYERWEATHER CHARLES',
    name: 'FAYERWEATHER CHARLES',
    label: 'FAYERWEATHER CHARLES',
  },
  {
    cik: '0000937729',
    value: '0000937729,FAYEZ SAROFIM & CO',
    name: 'FAYEZ SAROFIM & CO',
    label: 'FAYEZ SAROFIM & CO',
  },
  {
    cik: '0001141911',
    value: '0001141911,FBL INVESTMENT MANAGEMENT SERVICES INC/IA',
    name: 'FBL INVESTMENT MANAGEMENT SERVICES INC/IA',
    label: 'FBL INVESTMENT MANAGEMENT SERVICES INC/IA',
  },
  {
    cik: '0001371446',
    value: '00013714,FBR & CO.',
    name: 'FBR & CO.',
    label: 'FBR & CO.',
  },
  {
    cik: '0001166402',
    value: '0001166402,FCA CORP /TX',
    name: 'FCA CORP /TX',
    label: 'FCA CORP /TX',
  },
  {
    cik: '0001683754',
    value: '0001683754,FC ADVISORY LLC',
    name: 'FC ADVISORY LLC',
    label: 'FC ADVISORY LLC',
  },
  {
    cik: '0001319184',
    value: '0001319184,F&C ASSET MANAGEMENT PLC',
    name: 'F&C ASSET MANAGEMENT PLC',
    label: 'F&C ASSET MANAGEMENT PLC',
  },
  {
    cik: '0001604903',
    value: '0001604903,FCF ADVISORS LLC',
    name: 'FCF ADVISORS LLC',
    label: 'FCF ADVISORS LLC',
  },
  {
    cik: '0001009950',
    value: '0001009950,FCG ADVISORS, LLC',
    name: 'FCG ADVISORS, LLC',
    label: 'FCG ADVISORS, LLC',
  },
  {
    cik: '0001061187',
    value: '0001061187,FCM INVESTMENTS/TX',
    name: 'FCM INVESTMENTS/TX',
    label: 'FCM INVESTMENTS/TX',
  },
  {
    cik: '0001528436',
    value: '0001528436,FDO PARTNERS, LLC',
    name: 'FDO PARTNERS, LLC',
    label: 'FDO PARTNERS, LLC',
  },
  {
    cik: '0001566601',
    value: '0001566601,FDX ADVISORS, INC.',
    name: 'FDX ADVISORS, INC.',
    label: 'FDX ADVISORS, INC.',
  },
  {
    cik: '0001457970',
    value: '0001457970,FEDERAL RESERVE BANK OF NEW YORK',
    name: 'FEDERAL RESERVE BANK OF NEW YORK',
    label: 'FEDERAL RESERVE BANK OF NEW YORK',
  },
  {
    cik: '0001056288',
    value: '0001056288,FEDERATED HERMES, INC.',
    name: 'FEDERATED HERMES, INC.',
    label: 'FEDERATED HERMES, INC.',
  },
  {
    cik: '0000947822',
    value: '0000947822,FEINBERG STEPHEN',
    name: 'FEINBERG STEPHEN',
    label: 'FEINBERG STEPHEN',
  },
  {
    cik: '0001639943',
    value: '0001639943,FELTZ WEALTH PLAN INC.',
    name: 'FELTZ WEALTH PLAN INC.',
    label: 'FELTZ WEALTH PLAN INC.',
  },
  {
    cik: '0001660531',
    value: '0001660531,FENGHE FUND MANAGEMENT PTE. LTD.',
    name: 'FENGHE FUND MANAGEMENT PTE. LTD.',
    label: 'FENGHE FUND MANAGEMENT PTE. LTD.',
  },
  {
    cik: '0000884566',
    value: '0000884566,FENIMORE ASSET MANAGEMENT INC',
    name: 'FENIMORE ASSET MANAGEMENT INC',
    label: 'FENIMORE ASSET MANAGEMENT INC',
  },
  {
    cik: '0001767433',
    value: '0001767433,FERGUSON SHAPIRO LLC',
    name: 'FERGUSON SHAPIRO LLC',
    label: 'FERGUSON SHAPIRO LLC',
  },
  {
    cik: '0000869353',
    value: '0000869353,FERGUSON WELLMAN CAPITAL MANAGEMENT, INC',
    name: 'FERGUSON WELLMAN CAPITAL MANAGEMENT, INC',
    label: 'FERGUSON WELLMAN CAPITAL MANAGEMENT, INC',
  },
  {
    cik: '0001859259',
    value: '0001859259,FERMATA ADVISORS, LLC',
    name: 'FERMATA ADVISORS, LLC',
    label: 'FERMATA ADVISORS, LLC',
  },
  {
    cik: '0001827734',
    value: '0001827734,FERNBRIDGE CAPITAL MANAGEMENT LP',
    name: 'FERNBRIDGE CAPITAL MANAGEMENT LP',
    label: 'FERNBRIDGE CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001570296',
    value: '0001570296,FERNWOOD INVESTMENT MANAGEMENT, LLC',
    name: 'FERNWOOD INVESTMENT MANAGEMENT, LLC',
    label: 'FERNWOOD INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001343231',
    value: '0001343231,FEROX CAPITAL LLP',
    name: 'FEROX CAPITAL LLP',
    label: 'FEROX CAPITAL LLP',
  },
  {
    cik: '0001343232',
    value: '0001343232,FEROX CAPITAL MANAGEMENT LP',
    name: 'FEROX CAPITAL MANAGEMENT LP',
    label: 'FEROX CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001714346',
    value: '0001714346,FERRIS CAPITAL, LLC',
    name: 'FERRIS CAPITAL, LLC',
    label: 'FERRIS CAPITAL, LLC',
  },
  {
    cik: '0001479598',
    value: '0001479,FFCM LLC8',
    name: 'FFCM LLC',
    label: 'FFCM LLC',
  },
  {
    cik: '0001701880',
    value: '0001701880,FFL PARTNERS, LLC',
    name: 'FFL PARTNERS, LLC',
    label: 'FFL PARTNERS, LLC',
  },
  {
    cik: '0001653199',
    value: '0001653199,FFT WEALTH MANAGEMENT LLC',
    name: 'FFT WEALTH MANAGEMENT LLC',
    label: 'FFT WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0001862427',
    value: '0001862427,FI3 FINANCIAL ADVISORS, LLC',
    name: 'FI3 FINANCIAL ADVISORS, LLC',
    label: 'FI3 FINANCIAL ADVISORS, LLC',
  },
  {
    cik: '0001512251',
    value: '0001512251,FIC CAPITAL INC',
    name: 'FIC CAPITAL INC',
    label: 'FIC CAPITAL INC',
  },
  {
    cik: '0001711360',
    value: '0001711360,FIDATO WEALTH LLC',
    name: 'FIDATO WEALTH LLC',
    label: 'FIDATO WEALTH LLC',
  },
  {
    cik: '0001962755',
    value: '0001962755,FIDELIS CAPITAL PARTNERS, LLC',
    name: 'FIDELIS CAPITAL PARTNERS, LLC',
    label: 'FIDELIS CAPITAL PARTNERS, LLC',
  },
  {
    cik: '0001894206',
    value: '0001894206,FIDELIS IM, LLC',
    name: 'FIDELIS IM, LLC',
    label: 'FIDELIS IM, LLC',
  },
  {
    cik: '0001331875',
    value: '0001331875,FIDELITY NATIONAL FINANCIAL, INC.',
    name: 'FIDELITY NATIONAL FINANCIAL, INC.',
    label: 'FIDELITY NATIONAL FINANCIAL, INC.',
  },
  {
    cik: '0001053911',
    value: '0001053911,FIDGEON TIMOTHY F',
    name: 'FIDGEON TIMOTHY F',
    label: 'FIDGEON TIMOTHY F',
  },
  {
    cik: '0001954805',
    value: '0001954805,FIDUCIARY ALLIANCE LLC',
    name: 'FIDUCIARY ALLIANCE LLC',
    label: 'FIDUCIARY ALLIANCE LLC',
  },
  {
    cik: '0000948985',
    value: '0000948985,FIDUCIARY ASSET MANAGEMENT CO',
    name: 'FIDUCIARY ASSET MANAGEMENT CO',
    label: 'FIDUCIARY ASSET MANAGEMENT CO',
  },
  {
    cik: '0001270764',
    value: '0001270764,FIDUCIARY COUNSELLING INC',
    name: 'FIDUCIARY COUNSELLING INC',
    label: 'FIDUCIARY COUNSELLING INC',
  },
  {
    cik: '0001079373',
    value: '0001079373,FIDUCIARY FINANCIAL SERVICES OF THE SOUTHWEST INC /TX',
    name: 'FIDUCIARY FINANCIAL SERVICES OF THE SOUTHWEST INC /TX',
    label: 'FIDUCIARY FINANCIAL SERVICES OF THE SOUTHWEST INC /TX',
  },
  {
    cik: '0001645890',
    value: '0001645890,FIDUCIARY GROUP LLC',
    name: 'FIDUCIARY GROUP LLC',
    label: 'FIDUCIARY GROUP LLC',
  },
  {
    cik: '0000319902',
    value: '0000319902,FIDUCIARY MANAGEMENT ASSOCIATES LLC',
    name: 'FIDUCIARY MANAGEMENT ASSOCIATES LLC',
    label: 'FIDUCIARY MANAGEMENT ASSOCIATES LLC',
  },
  {
    cik: '0000764532',
    value: '0000764532,FIDUCIARY MANAGEMENT INC /WI/',
    name: 'FIDUCIARY MANAGEMENT INC /WI/',
    label: 'FIDUCIARY MANAGEMENT INC /WI/',
  },
  {
    cik: '0001792851',
    value: '0001792851,FIDUCIARY PLANNING LLC',
    name: 'FIDUCIARY PLANNING LLC',
    label: 'FIDUCIARY PLANNING LLC',
  },
  {
    cik: '0001009259',
    value: '0001009259,FIDUCIARY SERVICES CORP/GA',
    name: 'FIDUCIARY SERVICES CORP/GA',
    label: 'FIDUCIARY SERVICES CORP/GA',
  },
  {
    cik: '0000035442',
    value: '0000035442,FIDUCIARY TRUST CO',
    name: 'FIDUCIARY TRUST CO',
    label: 'FIDUCIARY TRUST CO',
  },
  {
    cik: '0001424480',
    value: '0001424480,FIDUCIARY TRUST INTERNATIONAL LLC',
    name: 'FIDUCIARY TRUST INTERNATIONAL LLC',
    label: 'FIDUCIARY TRUST INTERNATIONAL LLC',
  },
  {
    cik: '0001666504',
    value: '0001666504,FIDUCIARY WEALTH PARTNERS, LLC',
    name: 'FIDUCIARY WEALTH PARTNERS, LLC',
    label: 'FIDUCIARY WEALTH PARTNERS, LLC',
  },
  {
    cik: '0001729866',
    value: '0001729866,FIDUCIENT ADVISORS LLC',
    name: 'FIDUCIENT ADVISORS LLC',
    label: 'FIDUCIENT ADVISORS LLC',
  },
  {
    cik: '0001594492',
    value: '0001594492,FIELD & MAIN BANK',
    name: 'FIELD & MAIN BANK',
    label: 'FIELD & MAIN BANK',
  },
  {
    cik: '0001160841',
    value: '0001160841,FIELDPOINT PRIVATE ADVISORS, INC',
    name: 'FIELDPOINT PRIVATE ADVISORS, INC',
    label: 'FIELDPOINT PRIVATE ADVISORS, INC',
  },
  {
    cik: '0001706170',
    value: '0001706170,FIELDPOINT PRIVATE SECURITIES, LLC',
    name: 'FIELDPOINT PRIVATE SECURITIES, LLC',
    label: 'FIELDPOINT PRIVATE SECURITIES, LLC',
  },
  {
    cik: '0001505817',
    value: '0001505817,FIERA CAPITAL CORP',
    name: 'FIERA CAPITAL CORP',
    label: 'FIERA CAPITAL CORP',
  },
  {
    cik: '0001872766',
    value: '0001872766,FIFTHDELTA LTD',
    name: 'FIFTHDELTA LTD',
    label: 'FIFTHDELTA LTD',
  },
  {
    cik: '0001858558',
    value: '0001858558,FIFTH LANE CAPITAL, LP',
    name: 'FIFTH LANE CAPITAL, LP',
    label: 'FIFTH LANE CAPITAL, LP',
  },
  {
    cik: '0000035527',
    value: '0000035527,FIFTH THIRD BANCORP',
    name: 'FIFTH THIRD BANCORP',
    label: 'FIFTH THIRD BANCORP',
  },
  {
    cik: '0000073956',
    value: '0000073956,FIFTH THIRD SECURITIES, INC.',
    name: 'FIFTH THIRD SECURITIES, INC.',
    label: 'FIFTH THIRD SECURITIES, INC.',
  },
  {
    cik: '0001550228',
    value: '0001550228,FILAMENT LLC',
    name: 'FILAMENT LLC',
    label: 'FILAMENT LLC',
  },
  {
    cik: '0001806091',
    value: '0001806091,FILBRANDT WEALTH MANAGEMENT, LLC',
    name: 'FILBRANDT WEALTH MANAGEMENT, LLC',
    label: 'FILBRANDT WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0000318989',
    value: '000031,FIL LTD89',
    name: 'FIL LTD',
    label: 'FIL LTD',
  },
  {
    cik: '0001518320',
    value: '0001518320,FINANCIAL ADVANTAGE, INC.',
    name: 'FINANCIAL ADVANTAGE, INC.',
    label: 'FINANCIAL ADVANTAGE, INC.',
  },
  {
    cik: '0001766909',
    value: '0001766909,FINANCIAL ADVISORS, LLC',
    name: 'FINANCIAL ADVISORS, LLC',
    label: 'FINANCIAL ADVISORS, LLC',
  },
  {
    cik: '0001697856',
    value: '0001697856,FINANCIAL ADVISORS NETWORK, INC.',
    name: 'FINANCIAL ADVISORS NETWORK, INC.',
    label: 'FINANCIAL ADVISORS NETWORK, INC.',
  },
  {
    cik: '0001059057',
    value: '0001059057,FINANCIAL ADVISORY CORP /UT/ /ADV',
    name: 'FINANCIAL ADVISORY CORP /UT/ /ADV',
    label: 'FINANCIAL ADVISORY CORP /UT/ /ADV',
  },
  {
    cik: '0001539338',
    value: '0001539338,FINANCIAL ADVISORY GROUP',
    name: 'FINANCIAL ADVISORY GROUP',
    label: 'FINANCIAL ADVISORY GROUP',
  },
  {
    cik: '0001596957',
    value: '0001596957,FINANCIAL ADVISORY SERVICE, INC.',
    name: 'FINANCIAL ADVISORY SERVICE, INC.',
    label: 'FINANCIAL ADVISORY SERVICE, INC.',
  },
  {
    cik: '0001730293',
    value: '0001730293,FINANCIAL ADVOCATES INVESTMENT MANAGEMENT',
    name: 'FINANCIAL ADVOCATES INVESTMENT MANAGEMENT',
    label: 'FINANCIAL ADVOCATES INVESTMENT MANAGEMENT',
  },
  {
    cik: '0001416692',
    value: '0001416692,FINANCIAL ARCHITECTS, INC',
    name: 'FINANCIAL ARCHITECTS, INC',
    label: 'FINANCIAL ARCHITECTS, INC',
  },
  {
    cik: '0001844393',
    value: '0001844393,FINANCIAL AVENGERS, INC.',
    name: 'FINANCIAL AVENGERS, INC.',
    label: 'FINANCIAL AVENGERS, INC.',
  },
  {
    cik: '0001907157',
    value: '0001907157,FINANCIAL CONNECTIONS GROUP, INC.',
    name: 'FINANCIAL CONNECTIONS GROUP, INC.',
    label: 'FINANCIAL CONNECTIONS GROUP, INC.',
  },
  {
    cik: '0001865938',
    value: '0001865938,FINANCIAL CONSULATE INC.',
    name: 'FINANCIAL CONSULATE INC.',
    label: 'FINANCIAL CONSULATE INC.',
  },
  {
    cik: '0001624510',
    value: '0001624510,FINANCIAL CONSULATE, INC',
    name: 'FINANCIAL CONSULATE, INC',
    label: 'FINANCIAL CONSULATE, INC',
  },
  {
    cik: '0001883629',
    value: '0001883629,FINANCIAL COUNCIL ASSET MANAGEMENT INC',
    name: 'FINANCIAL COUNCIL ASSET MANAGEMENT INC',
    label: 'FINANCIAL COUNCIL ASSET MANAGEMENT INC',
  },
  {
    cik: '0000949623',
    value: '0000949623,FINANCIAL COUNSELORS INC',
    name: 'FINANCIAL COUNSELORS INC',
    label: 'FINANCIAL COUNSELORS INC',
  },
  {
    cik: '0001420473',
    value: '0001420473,FINANCIAL ENGINES ADVISORS L.L.C.',
    name: 'FINANCIAL ENGINES ADVISORS L.L.C.',
    label: 'FINANCIAL ENGINES ADVISORS L.L.C.',
  },
  {
    cik: '0001641438',
    value: '0001641438,FINANCIAL ENHANCEMENT GROUP LLC',
    name: 'FINANCIAL ENHANCEMENT GROUP LLC',
    label: 'FINANCIAL ENHANCEMENT GROUP LLC',
  },
  {
    cik: '0001911616',
    value: '0001911616,FINANCIAL FUTURES LTD LIABILITY CO.',
    name: 'FINANCIAL FUTURES LTD LIABILITY CO.',
    label: 'FINANCIAL FUTURES LTD LIABILITY CO.',
  },
  {
    cik: '0001741736',
    value: '0001741736,FINANCIAL GRAVITY ASSET MANAGEMENT, INC.',
    name: 'FINANCIAL GRAVITY ASSET MANAGEMENT, INC.',
    label: 'FINANCIAL GRAVITY ASSET MANAGEMENT, INC.',
  },
  {
    cik: '0001377167',
    value: '0001377167,FINANCIAL GRAVITY COMPANIES, INC.',
    name: 'FINANCIAL GRAVITY COMPANIES, INC.',
    label: 'FINANCIAL GRAVITY COMPANIES, INC.',
  },
  {
    cik: '0001741736',
    value: '0001741736,FINANCIAL GRAVITY WEALTH, INC.',
    name: 'FINANCIAL GRAVITY WEALTH, INC.',
    label: 'FINANCIAL GRAVITY WEALTH, INC.',
  },
  {
    cik: '0001929349',
    value: '0001929349,FINANCIAL GUIDANCE GROUP, INC.',
    name: 'FINANCIAL GUIDANCE GROUP, INC.',
    label: 'FINANCIAL GUIDANCE GROUP, INC.',
  },
  {
    cik: '0001642058',
    value: '0001642058,FINANCIAL INSIGHTS, INC.',
    name: 'FINANCIAL INSIGHTS, INC.',
    label: 'FINANCIAL INSIGHTS, INC.',
  },
  {
    cik: '0001051042',
    value: '0001051042,FINANCIAL & INVESTMENT MANAGEMENT GROUP LTD',
    name: 'FINANCIAL & INVESTMENT MANAGEMENT GROUP LTD',
    label: 'FINANCIAL & INVESTMENT MANAGEMENT GROUP LTD',
  },
  {
    cik: '0001730813',
    value: '0001730813,FINANCIAL LIFE ADVISORS',
    name: 'FINANCIAL LIFE ADVISORS',
    label: 'FINANCIAL LIFE ADVISORS',
  },
  {
    cik: '0000883933',
    value: '0000883933,FINANCIAL MANAGEMENT CONCEPTS CORP /ADV',
    name: 'FINANCIAL MANAGEMENT CONCEPTS CORP /ADV',
    label: 'FINANCIAL MANAGEMENT CONCEPTS CORP /ADV',
  },
  {
    cik: '0001844831',
    value: '0001844831,FINANCIAL MANAGEMENT NETWORK INC',
    name: 'FINANCIAL MANAGEMENT NETWORK INC',
    label: 'FINANCIAL MANAGEMENT NETWORK INC',
  },
  {
    cik: '0001568132',
    value: '0001568132,FINANCIAL MANAGEMENT PROFESSIONALS, INC.',
    name: 'FINANCIAL MANAGEMENT PROFESSIONALS, INC.',
    label: 'FINANCIAL MANAGEMENT PROFESSIONALS, INC.',
  },
  {
    cik: '0001538900',
    value: '0001538900,FINANCIAL NETWORK GROUP, LTD',
    name: 'FINANCIAL NETWORK GROUP, LTD',
    label: 'FINANCIAL NETWORK GROUP, LTD',
  },
  {
    cik: '0001840760',
    value: '0001840760,FINANCIAL NETWORK WEALTH MANAGEMENT LLC',
    name: 'FINANCIAL NETWORK WEALTH MANAGEMENT LLC',
    label: 'FINANCIAL NETWORK WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0001049650',
    value: '0001049650,FINANCIAL PARTNERS CAPITAL MANAGEMENT LLC',
    name: 'FINANCIAL PARTNERS CAPITAL MANAGEMENT LLC',
    label: 'FINANCIAL PARTNERS CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001731169',
    value: '0001731169,FINANCIAL PARTNERS GROUP, INC',
    name: 'FINANCIAL PARTNERS GROUP, INC',
    label: 'FINANCIAL PARTNERS GROUP, INC',
  },
  {
    cik: '0001890391',
    value: '0001890391,FINANCIAL PLANNERS OF CLEVELAND, INC.',
    name: 'FINANCIAL PLANNERS OF CLEVELAND, INC.',
    label: 'FINANCIAL PLANNERS OF CLEVELAND, INC.',
  },
  {
    cik: '0001841433',
    value: '0001841433,FINANCIAL PLANNING & INFORMATION SERVICES, INC.',
    name: 'FINANCIAL PLANNING & INFORMATION SERVICES, INC.',
    label: 'FINANCIAL PLANNING & INFORMATION SERVICES, INC.',
  },
  {
    cik: '0001358828',
    value: '0001358828,FINANCIAL SENSE ADVISORS, INC',
    name: 'FINANCIAL SENSE ADVISORS, INC',
    label: 'FINANCIAL SENSE ADVISORS, INC',
  },
  {
    cik: '0001358828',
    value: '0001358828,FINANCIAL SENSE ADVISORS, INC.',
    name: 'FINANCIAL SENSE ADVISORS, INC.',
    label: 'FINANCIAL SENSE ADVISORS, INC.',
  },
  {
    cik: '0001731221',
    value: '0001731221,FINANCIAL SERVICES ADVISORY INC',
    name: 'FINANCIAL SERVICES ADVISORY INC',
    label: 'FINANCIAL SERVICES ADVISORY INC',
  },
  {
    cik: '0001774343',
    value: '0001774343,FINANCIAL STRATEGIES GROUP, INC.',
    name: 'FINANCIAL STRATEGIES GROUP, INC.',
    label: 'FINANCIAL STRATEGIES GROUP, INC.',
  },
  {
    cik: '0001640638',
    value: '0001640638,FINANCIAL STRATEGY NETWORK, LLC',
    name: 'FINANCIAL STRATEGY NETWORK, LLC',
    label: 'FINANCIAL STRATEGY NETWORK, LLC',
  },
  {
    cik: '0001351950',
    value: '0001351950,FINDLAY PARK PARTNERS LLP',
    name: 'FINDLAY PARK PARTNERS LLP',
    label: 'FINDLAY PARK PARTNERS LLP',
  },
  {
    cik: '0001339161',
    value: '0001339161,FINE CAPITAL PARTNERS, L.P.',
    name: 'FINE CAPITAL PARTNERS, L.P.',
    label: 'FINE CAPITAL PARTNERS, L.P.',
  },
  {
    cik: '0001537720',
    value: '0001537720,FINEMARK NATIONAL BANK & TRUST',
    name: 'FINEMARK NATIONAL BANK & TRUST',
    label: 'FINEMARK NATIONAL BANK & TRUST',
  },
  {
    cik: '0001608126',
    value: '0001608126,FINEPOINT CAPITAL LP',
    name: 'FINEPOINT CAPITAL LP',
    label: 'FINEPOINT CAPITAL LP',
  },
  {
    cik: '0001929071',
    value: '0001929071,FINER WEALTH MANAGEMENT, INC.',
    name: 'FINER WEALTH MANAGEMENT, INC.',
    label: 'FINER WEALTH MANAGEMENT, INC.',
  },
  {
    cik: '0001910248',
    value: '0001910248,FINGERLAKES WEALTH MANAGEMENT, INC.',
    name: 'FINGERLAKES WEALTH MANAGEMENT, INC.',
    label: 'FINGERLAKES WEALTH MANAGEMENT, INC.',
  },
  {
    cik: '0001622001',
    value: '0001622001,FINTRUST CAPITAL ADVISORS, LLC',
    name: 'FINTRUST CAPITAL ADVISORS, LLC',
    label: 'FINTRUST CAPITAL ADVISORS, LLC',
  },
  {
    cik: '0001477812',
    value: '0001477812,FINTRUST INVESTMENT ADVISORY SERVICES, LLC',
    name: 'FINTRUST INVESTMENT ADVISORY SERVICES, LLC',
    label: 'FINTRUST INVESTMENT ADVISORY SERVICES, LLC',
  },
  {
    cik: '0001438637',
    value: '0001438637,FIREFLY VALUE PARTNERS, LP',
    name: 'FIREFLY VALUE PARTNERS, LP',
    label: 'FIREFLY VALUE PARTNERS, LP',
  },
  {
    cik: '0001799964',
    value: '0001799964,FIRESTONE CAPITAL MANAGEMENT',
    name: 'FIRESTONE CAPITAL MANAGEMENT',
    label: 'FIRESTONE CAPITAL MANAGEMENT',
  },
  {
    cik: '0001845743',
    value: '0001845743,FIRETHORN WEALTH PARTNERS, LLC',
    name: 'FIRETHORN WEALTH PARTNERS, LLC',
    label: 'FIRETHORN WEALTH PARTNERS, LLC',
  },
  {
    cik: '0001656282',
    value: '0001656282,FIRST AFFIRMATIVE FINANCIAL NETWORK',
    name: 'FIRST AFFIRMATIVE FINANCIAL NETWORK',
    label: 'FIRST AFFIRMATIVE FINANCIAL NETWORK',
  },
  {
    cik: '0001512647',
    value: '0001512647,FIRST ALLIED ADVISORY SERVICES, INC.',
    name: 'FIRST ALLIED ADVISORY SERVICES, INC.',
    label: 'FIRST ALLIED ADVISORY SERVICES, INC.',
  },
  {
    cik: '0001456510',
    value: '0001456510,FIRST ALLIED ASSET MANAGEMENT, INC.',
    name: 'FIRST ALLIED ASSET MANAGEMENT, INC.',
    label: 'FIRST ALLIED ASSET MANAGEMENT, INC.',
  },
  {
    cik: '0001291422',
    value: '0001291422,FIRST AMERICAN BANK',
    name: 'FIRST AMERICAN BANK',
    label: 'FIRST AMERICAN BANK',
  },
  {
    cik: '0000036066',
    value: '0000036066,FIRST AMERICAN TRUST CO',
    name: 'FIRST AMERICAN TRUST CO',
    label: 'FIRST AMERICAN TRUST CO',
  },
  {
    cik: '0000036066',
    value: '0000036066,FIRST AMERICAN TRUST, FSB',
    name: 'FIRST AMERICAN TRUST, FSB',
    label: 'FIRST AMERICAN TRUST, FSB',
  },
  {
    cik: '0001805758',
    value: '0001805758,FIRST ASCENT ASSET MANAGEMENT, LLC',
    name: 'FIRST ASCENT ASSET MANAGEMENT, LLC',
    label: 'FIRST ASCENT ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0000765207',
    value: '0000765207,FIRST BANCORP, INC /ME/',
    name: 'FIRST BANCORP, INC /ME/',
    label: 'FIRST BANCORP, INC /ME/',
  },
  {
    cik: '0001692632',
    value: '0001692632,FIRST BANK & TRUST',
    name: 'FIRST BANK & TRUST',
    label: 'FIRST BANK & TRUST',
  },
  {
    cik: '0001521951',
    value: '0001521951,FIRST BUSINESS FINANCIAL SERVICES, INC.',
    name: 'FIRST BUSINESS FINANCIAL SERVICES, INC.',
    label: 'FIRST BUSINESS FINANCIAL SERVICES, INC.',
  },
  {
    cik: '0001681126',
    value: '0001681126,FIRST CAPITAL ADVISORS GROUP, LLC.',
    name: 'FIRST CAPITAL ADVISORS GROUP, LLC.',
    label: 'FIRST CAPITAL ADVISORS GROUP, LLC.',
  },
  {
    cik: '0000708848',
    value: '0000708848,FIRST CITIZENS BANCORPORATION INC',
    name: 'FIRST CITIZENS BANCORPORATION INC',
    label: 'FIRST CITIZENS BANCORPORATION INC',
  },
  {
    cik: '0001092203',
    value: '0001092203,FIRST CITIZENS BANK & TRUST CO',
    name: 'FIRST CITIZENS BANK & TRUST CO',
    label: 'FIRST CITIZENS BANK & TRUST CO',
  },
  {
    cik: '0001800597',
    value: '0001800597,FIRST CITIZENS FINANCIAL CORP',
    name: 'FIRST CITIZENS FINANCIAL CORP',
    label: 'FIRST CITIZENS FINANCIAL CORP',
  },
  {
    cik: '0001427350',
    value: '0001427350,FIRST CITY CAPITAL MANAGEMENT, INC.',
    name: 'FIRST CITY CAPITAL MANAGEMENT, INC.',
    label: 'FIRST CITY CAPITAL MANAGEMENT, INC.',
  },
  {
    cik: '0001727336',
    value: '0001727336,FIRST COMMAND ADVISORY SERVICES, INC.',
    name: 'FIRST COMMAND ADVISORY SERVICES, INC.',
    label: 'FIRST COMMAND ADVISORY SERVICES, INC.',
  },
  {
    cik: '0001693750',
    value: '0001693750,FIRST COMMAND BANK',
    name: 'FIRST COMMAND BANK',
    label: 'FIRST COMMAND BANK',
  },
  {
    cik: '0001693789',
    value: '0001693789,FIRST COMMAND FINANCIAL SERVICES, INC.',
    name: 'FIRST COMMAND FINANCIAL SERVICES, INC.',
    label: 'FIRST COMMAND FINANCIAL SERVICES, INC.',
  },
  {
    cik: '0000712537',
    value: '0000712537,FIRST COMMONWEALTH FINANCIAL CORP /PA/',
    name: 'FIRST COMMONWEALTH FINANCIAL CORP /PA/',
    label: 'FIRST COMMONWEALTH FINANCIAL CORP /PA/',
  },
  {
    cik: '0001130344',
    value: '0001130344,FIRST COMMUNITY TRUST NA',
    name: 'FIRST COMMUNITY TRUST NA',
    label: 'FIRST COMMUNITY TRUST NA',
  },
  {
    cik: '0001378559',
    value: '0001378559,FIRST DALLAS SECURITIES INC.',
    name: 'FIRST DALLAS SECURITIES INC.',
    label: 'FIRST DALLAS SECURITIES INC.',
  },
  {
    cik: '0001325447',
    value: '0001325447,FIRST EAGLE INVESTMENT MANAGEMENT, LLC',
    name: 'FIRST EAGLE INVESTMENT MANAGEMENT, LLC',
    label: 'FIRST EAGLE INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001911040',
    value: '0001911040,FIRST FARMERS & MERCHANTS BANK / TRUST',
    name: 'FIRST FARMERS & MERCHANTS BANK / TRUST',
    label: 'FIRST FARMERS & MERCHANTS BANK / TRUST',
  },
  {
    cik: '0001072843',
    value: '0001072843,FIRST FIDUCIARY INVESTMENT COUNSEL, INC.',
    name: 'FIRST FIDUCIARY INVESTMENT COUNSEL, INC.',
    label: 'FIRST FIDUCIARY INVESTMENT COUNSEL, INC.',
  },
  {
    cik: '0001315339',
    value: '0001315339,FIRST FINANCIAL BANK - TRUST DIVISION',
    name: 'FIRST FINANCIAL BANK - TRUST DIVISION',
    label: 'FIRST FINANCIAL BANK - TRUST DIVISION',
  },
  {
    cik: '0000714562',
    value: '0000714562,FIRST FINANCIAL CORP /IN/',
    name: 'FIRST FINANCIAL CORP /IN/',
    label: 'FIRST FINANCIAL CORP /IN/',
  },
  {
    cik: '0000768965',
    value: '0000768965,FIRST FINANCIAL EQUITY CORPORATION',
    name: 'FIRST FINANCIAL EQUITY CORPORATION',
    label: 'FIRST FINANCIAL EQUITY CORPORATION',
  },
  {
    cik: '0000764038',
    value: '0000764038,FIRST FINANCIAL HOLDINGS, INC.',
    name: 'FIRST FINANCIAL HOLDINGS, INC.',
    label: 'FIRST FINANCIAL HOLDINGS, INC.',
  },
  {
    cik: '0001086763',
    value: '0001086763,FIRST FOUNDATION ADVISORS',
    name: 'FIRST FOUNDATION ADVISORS',
    label: 'FIRST FOUNDATION ADVISORS',
  },
  {
    cik: '0001830236',
    value: '0001830236,FIRST GROWTH CAPITAL MANAGEMENT LLC',
    name: 'FIRST GROWTH CAPITAL MANAGEMENT LLC',
    label: 'FIRST GROWTH CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001859691',
    value: '0001859691,FIRST GROWTH INVESTMENT MANAGER LP',
    name: 'FIRST GROWTH INVESTMENT MANAGER LP',
    label: 'FIRST GROWTH INVESTMENT MANAGER LP',
  },
  {
    cik: '0001476179',
    value: '0001476179,FIRSTHAND CAPITAL MANAGEMENT, INC.',
    name: 'FIRSTHAND CAPITAL MANAGEMENT, INC.',
    label: 'FIRSTHAND CAPITAL MANAGEMENT, INC.',
  },
  {
    cik: '0000764106',
    value: '0000764106,FIRST HAWAIIAN BANK',
    name: 'FIRST HAWAIIAN BANK',
    label: 'FIRST HAWAIIAN BANK',
  },
  {
    cik: '0001428793',
    value: '0001428793,FIRST HEARTLAND CONSULTANTS, INC.',
    name: 'FIRST HEARTLAND CONSULTANTS, INC.',
    label: 'FIRST HEARTLAND CONSULTANTS, INC.',
  },
  {
    cik: '0001588539',
    value: '0001588539,FIRST HORIZON ADVISORS, INC.',
    name: 'FIRST HORIZON ADVISORS, INC.',
    label: 'FIRST HORIZON ADVISORS, INC.',
  },
  {
    cik: '0000036966',
    value: '0000036966,FIRST HORIZON CORP',
    name: 'FIRST HORIZON CORP',
    label: 'FIRST HORIZON CORP',
  },
  {
    cik: '0001542317',
    value: '0001542317,FIRST HOUSTON CAPITAL, INC.',
    name: 'FIRST HOUSTON CAPITAL, INC.',
    label: 'FIRST HOUSTON CAPITAL, INC.',
  },
  {
    cik: '0001910370',
    value: '0001910370,FIRST INTERNATIONAL BANK & TRUST',
    name: 'FIRST INTERNATIONAL BANK & TRUST',
    label: 'FIRST INTERNATIONAL BANK & TRUST',
  },
  {
    cik: '0000860413',
    value: '0000860413,FIRST INTERSTATE BANCSYSTEM INC',
    name: 'FIRST INTERSTATE BANCSYSTEM INC',
    label: 'FIRST INTERSTATE BANCSYSTEM INC',
  },
  {
    cik: '0001125727',
    value: '0001125727,FIRST INTERSTATE BANK',
    name: 'FIRST INTERSTATE BANK',
    label: 'FIRST INTERSTATE BANK',
  },
  {
    cik: '0000728079',
    value: '0000728079,FIRST INVESTORS MANAGEMENT CO INC',
    name: 'FIRST INVESTORS MANAGEMENT CO INC',
    label: 'FIRST INVESTORS MANAGEMENT CO INC',
  },
  {
    cik: '0001600004',
    value: '0001600004,FIRST LIGHT ASSET MANAGEMENT, LLC',
    name: 'FIRST LIGHT ASSET MANAGEMENT, LLC',
    label: 'FIRST LIGHT ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001490429',
    value: '0001490429,FIRST LONG ISLAND INVESTORS, LLC',
    name: 'FIRST LONG ISLAND INVESTORS, LLC',
    label: 'FIRST LONG ISLAND INVESTORS, LLC',
  },
  {
    cik: '0000728083',
    value: '0000728083,FIRST MANHATTAN CO',
    name: 'FIRST MANHATTAN CO',
    label: 'FIRST MANHATTAN CO',
  },
  {
    cik: '0001666572',
    value: '0001666572,FIRSTMARK CAPITAL I GP, LLC',
    name: 'FIRSTMARK CAPITAL I GP, LLC',
    label: 'FIRSTMARK CAPITAL I GP, LLC',
  },
  {
    cik: '0001844843',
    value: '0001844843,FIRSTMARK CAPITAL OF I GP, LLC',
    name: 'FIRSTMARK CAPITAL OF I GP, LLC',
    label: 'FIRSTMARK CAPITAL OF I GP, LLC',
  },
  {
    cik: '0001845708',
    value: '0001845708,FIRSTMARK CAPITAL OF II GP, LLC',
    name: 'FIRSTMARK CAPITAL OF II GP, LLC',
    label: 'FIRSTMARK CAPITAL OF II GP, LLC',
  },
  {
    cik: '0001259664',
    value: '0001259664,FIRST MERCANTILE TRUST CO',
    name: 'FIRST MERCANTILE TRUST CO',
    label: 'FIRST MERCANTILE TRUST CO',
  },
  {
    cik: '0000712534',
    value: '0000712534,FIRST MERCHANTS CORP',
    name: 'FIRST MERCHANTS CORP',
    label: 'FIRST MERCHANTS CORP',
  },
  {
    cik: '0001078135',
    value: '0001078135,FIRSTMERIT BANK N A TRUSTEE',
    name: 'FIRSTMERIT BANK N A TRUSTEE',
    label: 'FIRSTMERIT BANK N A TRUSTEE',
  },
  {
    cik: '0000872732',
    value: '0000872732,FIRST MIDWEST BANK TRUST DIVISION',
    name: 'FIRST MIDWEST BANK TRUST DIVISION',
    label: 'FIRST MIDWEST BANK TRUST DIVISION',
  },
  {
    cik: '0001941030',
    value: '0001941030,FIRST NATIONAL ADVISERS, LLC',
    name: 'FIRST NATIONAL ADVISERS, LLC',
    label: 'FIRST NATIONAL ADVISERS, LLC',
  },
  {
    cik: '0001313871',
    value: '0001313871,FIRST NATIONAL BANK OF HUTCHINSON',
    name: 'FIRST NATIONAL BANK OF HUTCHINSON',
    label: 'FIRST NATIONAL BANK OF HUTCHINSON',
  },
  {
    cik: '0001423045',
    value:
      '0001423045,FIRST NATIONAL BANK OF MOUNT DORA, TRUST INVESTMENT SERVICES',
    name: 'FIRST NATIONAL BANK OF MOUNT DORA, TRUST INVESTMENT SERVICES',
    label: 'FIRST NATIONAL BANK OF MOUNT DORA, TRUST INVESTMENT SERVICES',
  },
  {
    cik: '0000036644',
    value: '0000036644,FIRST NATIONAL BANK OF OMAHA',
    name: 'FIRST NATIONAL BANK OF OMAHA',
    label: 'FIRST NATIONAL BANK OF OMAHA',
  },
  {
    cik: '0001739878',
    value: '0001739878,FIRST NATIONAL BANK OF SOUTH MIAMI',
    name: 'FIRST NATIONAL BANK OF SOUTH MIAMI',
    label: 'FIRST NATIONAL BANK OF SOUTH MIAMI',
  },
  {
    cik: '0001085041',
    value: '0001085041,FIRST NATIONAL BANK SIOUX FALLS',
    name: 'FIRST NATIONAL BANK SIOUX FALLS',
    label: 'FIRST NATIONAL BANK SIOUX FALLS',
  },
  {
    cik: '0001339908',
    value: '0001339908,FIRST NATIONAL BANK & TRUST CO OF NEWTOWN',
    name: 'FIRST NATIONAL BANK & TRUST CO OF NEWTOWN',
    label: 'FIRST NATIONAL BANK & TRUST CO OF NEWTOWN',
  },
  {
    cik: '0000816192',
    value: '0000816192,FIRST NATIONAL CORP /MA/ /ADV',
    name: 'FIRST NATIONAL CORP /MA/ /ADV',
    label: 'FIRST NATIONAL CORP /MA/ /ADV',
  },
  {
    cik: '0001277303',
    value: '0001277303,FIRST NATIONAL TRUST CO',
    name: 'FIRST NATIONAL TRUST CO',
    label: 'FIRST NATIONAL TRUST CO',
  },
  {
    cik: '0001111629',
    value: '0001111629,FIRST NEW YORK SECURITIES LLC /NY',
    name: 'FIRST NEW YORK SECURITIES LLC /NY',
    label: 'FIRST NEW YORK SECURITIES LLC /NY',
  },
  {
    cik: '0001061304',
    value: '0001061304,FIRST NIAGARA BANK',
    name: 'FIRST NIAGARA BANK',
    label: 'FIRST NIAGARA BANK',
  },
  {
    cik: '0001377581',
    value: '0001377581,FIRST PACIFIC ADVISORS, LLC',
    name: 'FIRST PACIFIC ADVISORS, LLC',
    label: 'FIRST PACIFIC ADVISORS, LLC',
  },
  {
    cik: '0001377581',
    value: '0001377581,FIRST PACIFIC ADVISORS, LP',
    name: 'FIRST PACIFIC ADVISORS, LP',
    label: 'FIRST PACIFIC ADVISORS, LP',
  },
  {
    cik: '0001810089',
    value: '0001810089,FIRST PACIFIC FINANCIAL',
    name: 'FIRST PACIFIC FINANCIAL',
    label: 'FIRST PACIFIC FINANCIAL',
  },
  {
    cik: '0001633869',
    value: '0001633869,FIRST PERSONAL FINANCIAL SERVICES',
    name: 'FIRST PERSONAL FINANCIAL SERVICES',
    label: 'FIRST PERSONAL FINANCIAL SERVICES',
  },
  {
    cik: '0001653926',
    value: '0001653926,FIRST PREMIER BANK',
    name: 'FIRST PREMIER BANK',
    label: 'FIRST PREMIER BANK',
  },
  {
    cik: '0001913420',
    value: '0001913420,FIRSTPURPOSE WEALTH LLC',
    name: 'FIRSTPURPOSE WEALTH LLC',
    label: 'FIRSTPURPOSE WEALTH LLC',
  },
  {
    cik: '0001014736',
    value: '0001014736,FIRST QUADRANT LLC/CA',
    name: 'FIRST QUADRANT LLC/CA',
    label: 'FIRST QUADRANT LLC/CA',
  },
  {
    cik: '0001014736',
    value: '0001014736,FIRST QUADRANT L P/CA',
    name: 'FIRST QUADRANT L P/CA',
    label: 'FIRST QUADRANT L P/CA',
  },
  {
    cik: '0001418204',
    value: '0001418204,FIRST REPUBLIC INVESTMENT MANAGEMENT, INC.',
    name: 'FIRST REPUBLIC INVESTMENT MANAGEMENT, INC.',
    label: 'FIRST REPUBLIC INVESTMENT MANAGEMENT, INC.',
  },
  {
    cik: '0001698102',
    value: '0001698102,FIRST RESERVE ENERGY INFRASTRUCTURE GP II LTD',
    name: 'FIRST RESERVE ENERGY INFRASTRUCTURE GP II LTD',
    label: 'FIRST RESERVE ENERGY INFRASTRUCTURE GP II LTD',
  },
  {
    cik: '0001706604',
    value: '0001706604,FIRST RESERVE GP XIII LTD',
    name: 'FIRST RESERVE GP XIII LTD',
    label: 'FIRST RESERVE GP XIII LTD',
  },
  {
    cik: '0001544731',
    value: '0001544731,FIRST RESERVE GP XII LTD',
    name: 'FIRST RESERVE GP XII LTD',
    label: 'FIRST RESERVE GP XII LTD',
  },
  {
    cik: '0001477477',
    value: '0001477477,FIRST RESERVE GP XI, INC.',
    name: 'FIRST RESERVE GP XI, INC.',
    label: 'FIRST RESERVE GP XI, INC.',
  },
  {
    cik: '0001844719',
    value: '0001844719,FIRST ROUND CAPITAL MANAGEMENT III, LLC',
    name: 'FIRST ROUND CAPITAL MANAGEMENT III, LLC',
    label: 'FIRST ROUND CAPITAL MANAGEMENT III, LLC',
  },
  {
    cik: '0001731530',
    value: '0001731530,FIRST SABREPOINT CAPITAL MANAGEMENT LP',
    name: 'FIRST SABREPOINT CAPITAL MANAGEMENT LP',
    label: 'FIRST SABREPOINT CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001125816',
    value: '0001125816,FIRST TRUST ADVISORS LP',
    name: 'FIRST TRUST ADVISORS LP',
    label: 'FIRST TRUST ADVISORS LP',
  },
  {
    cik: '0001406026',
    value: '0001406026,FIRST TRUST BANK, LTD.',
    name: 'FIRST TRUST BANK, LTD.',
    label: 'FIRST TRUST BANK, LTD.',
  },
  {
    cik: '0001604488',
    value: '0001604488,FIRST TRUST CAPITAL MANAGEMENT L.P.',
    name: 'FIRST TRUST CAPITAL MANAGEMENT L.P.',
    label: 'FIRST TRUST CAPITAL MANAGEMENT L.P.',
  },
  {
    cik: '0001214183',
    value: '0001214183,FIRST UNITED BANK & TRUST',
    name: 'FIRST UNITED BANK & TRUST',
    label: 'FIRST UNITED BANK & TRUST',
  },
  {
    cik: '0001390043',
    value: '0001390043,FIRST WASHINGTON CORP',
    name: 'FIRST WASHINGTON CORP',
    label: 'FIRST WASHINGTON CORP',
  },
  {
    cik: '0001603142',
    value: '0001603142,FIRST WESTERN CAPITAL MANAGEMENT CO',
    name: 'FIRST WESTERN CAPITAL MANAGEMENT CO',
    label: 'FIRST WESTERN CAPITAL MANAGEMENT CO',
  },
  {
    cik: '0001513189',
    value: '0001513189,FIRST WESTERN TRUST BANK',
    name: 'FIRST WESTERN TRUST BANK',
    label: 'FIRST WESTERN TRUST BANK',
  },
  {
    cik: '0000732847',
    value: '0000732847,FIRST WILSHIRE SECURITIES MANAGEMENT INC',
    name: 'FIRST WILSHIRE SECURITIES MANAGEMENT INC',
    label: 'FIRST WILSHIRE SECURITIES MANAGEMENT INC',
  },
  {
    cik: '0001056491',
    value: '0001056491,FIR TREE CAPITAL MANAGEMENT LP',
    name: 'FIR TREE CAPITAL MANAGEMENT LP',
    label: 'FIR TREE CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001637541',
    value: '0001637541,FIS GROUP, INC.',
    name: 'FIS GROUP, INC.',
    label: 'FIS GROUP, INC.',
  },
  {
    cik: '0000850529',
    value: '0000850529,FISHER ASSET MANAGEMENT, LLC',
    name: 'FISHER ASSET MANAGEMENT, LLC',
    label: 'FISHER ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001006407',
    value: '0001006407,FISHMAN JAY A LTD/MI',
    name: 'FISHMAN JAY A LTD/MI',
    label: 'FISHMAN JAY A LTD/MI',
  },
  {
    cik: '0001876326',
    value: '0001876326,FIVE OCEANS ADVISORS',
    name: 'FIVE OCEANS ADVISORS',
    label: 'FIVE OCEANS ADVISORS',
  },
  {
    cik: '0001576002',
    value: '0001576002,FIVE OCEANS ASSET MANAGEMENT LTD',
    name: 'FIVE OCEANS ASSET MANAGEMENT LTD',
    label: 'FIVE OCEANS ASSET MANAGEMENT LTD',
  },
  {
    cik: '0001540880',
    value: '0001540880,FJARDE AP-FONDEN /FOURTH SWEDISH NATIONAL PENSION FUND',
    name: 'FJARDE AP-FONDEN /FOURTH SWEDISH NATIONAL PENSION FUND',
    label: 'FJARDE AP-FONDEN /FOURTH SWEDISH NATIONAL PENSION FUND',
  },
  {
    cik: '0001599170',
    value: '0001599170,FJ CAPITAL MANAGEMENT LLC',
    name: 'FJ CAPITAL MANAGEMENT LLC',
    label: 'FJ CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001691766',
    value: '0001691766,FLAGSHIP HARBOR ADVISORS, LLC',
    name: 'FLAGSHIP HARBOR ADVISORS, LLC',
    label: 'FLAGSHIP HARBOR ADVISORS, LLC',
  },
  {
    cik: '0001400240',
    value: '0001400240,FLAGSHIP PIONEERING INC.',
    name: 'FLAGSHIP PIONEERING INC.',
    label: 'FLAGSHIP PIONEERING INC.',
  },
  {
    cik: '0001842560',
    value: '0001842560,FLAGSHIP PRIVATE WEALTH, LLC',
    name: 'FLAGSHIP PRIVATE WEALTH, LLC',
    label: 'FLAGSHIP PRIVATE WEALTH, LLC',
  },
  {
    cik: '0001508053',
    value: '0001508053,FLAGSHIP VENTURES 2007 GENERAL PARTNER LLC',
    name: 'FLAGSHIP VENTURES 2007 GENERAL PARTNER LLC',
    label: 'FLAGSHIP VENTURES 2007 GENERAL PARTNER LLC',
  },
  {
    cik: '0001508052',
    value: '0001508052,FLAGSHIP VENTURES FUND 2007, L.P.',
    name: 'FLAGSHIP VENTURES FUND 2007, L.P.',
    label: 'FLAGSHIP VENTURES FUND 2007, L.P.',
  },
  {
    cik: '0001508051',
    value: '0001508051,FLAGSHIP VENTURES FUND IV GENERAL PARTNER LLC',
    name: 'FLAGSHIP VENTURES FUND IV GENERAL PARTNER LLC',
    label: 'FLAGSHIP VENTURES FUND IV GENERAL PARTNER LLC',
  },
  {
    cik: '0001503559',
    value: '0001503559,FLAGSHIP VENTURES FUND IV, L.P.',
    name: 'FLAGSHIP VENTURES FUND IV, L.P.',
    label: 'FLAGSHIP VENTURES FUND IV, L.P.',
  },
  {
    cik: '0001400240',
    value: '0001400240,FLAGSHIP VENTURES MANAGEMENT, INC.',
    name: 'FLAGSHIP VENTURES MANAGEMENT, INC.',
    label: 'FLAGSHIP VENTURES MANAGEMENT, INC.',
  },
  {
    cik: '0001697882',
    value: '0001697882,FLAHARTY ASSET MANAGEMENT, LLC',
    name: 'FLAHARTY ASSET MANAGEMENT, LLC',
    label: 'FLAHARTY ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001261239',
    value: '0001261239,FLAHERTY & CRUMRINE INC',
    name: 'FLAHERTY & CRUMRINE INC',
    label: 'FLAHERTY & CRUMRINE INC',
  },
  {
    cik: '0001530579',
    value: '0001530579,FLATBUSH WATERMILL MANAGEMENT, LLC',
    name: 'FLATBUSH WATERMILL MANAGEMENT, LLC',
    label: 'FLATBUSH WATERMILL MANAGEMENT, LLC',
  },
  {
    cik: '0001799456',
    value: '0001799456,FLAT FOOTED LLC',
    name: 'FLAT FOOTED LLC',
    label: 'FLAT FOOTED LLC',
  },
  {
    cik: '0001713697',
    value: '0001713697,FLC CAPITAL ADVISORS',
    name: 'FLC CAPITAL ADVISORS',
    label: 'FLC CAPITAL ADVISORS',
  },
  {
    cik: '0001096986',
    value: '0001096986,FLEMING JAMES B JR',
    name: 'FLEMING JAMES B JR',
    label: 'FLEMING JAMES B JR',
  },
  {
    cik: '0001861705',
    value: '0001861705,FLIGHT DECK CAPITAL, LP',
    name: 'FLIGHT DECK CAPITAL, LP',
    label: 'FLIGHT DECK CAPITAL, LP',
  },
  {
    cik: '0001696726',
    value: '0001696726,FLINTON CAPITAL MANAGEMENT LLC',
    name: 'FLINTON CAPITAL MANAGEMENT LLC',
    label: 'FLINTON CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0000810716',
    value: '0000810716,FLIPPIN BRUCE & PORTER INC',
    name: 'FLIPPIN BRUCE & PORTER INC',
    label: 'FLIPPIN BRUCE & PORTER INC',
  },
  {
    cik: '0001055954',
    value: '0001055954,FLOOD GAMBLE ASSOCIATES INC',
    name: 'FLOOD GAMBLE ASSOCIATES INC',
    label: 'FLOOD GAMBLE ASSOCIATES INC',
  },
  {
    cik: '0001666759',
    value: '0001666759,FLORENCE CAPITAL ADVISORS, LLC',
    name: 'FLORENCE CAPITAL ADVISORS, LLC',
    label: 'FLORENCE CAPITAL ADVISORS, LLC',
  },
  {
    cik: '0001696263',
    value: '0001696263,FLORIN COURT CAPITAL LLP',
    name: 'FLORIN COURT CAPITAL LLP',
    label: 'FLORIN COURT CAPITAL LLP',
  },
  {
    cik: '0001575677',
    value: '0001575677,FLOSSBACH VON STORCH AG',
    name: 'FLOSSBACH VON STORCH AG',
    label: 'FLOSSBACH VON STORCH AG',
  },
  {
    cik: '0001767472',
    value: '0001767472,FLOSSBACH VON STORCH AG ZURICH',
    name: 'FLOSSBACH VON STORCH AG ZURICH',
    label: 'FLOSSBACH VON STORCH AG ZURICH',
  },
  {
    cik: '0001808394',
    value: '0001808394,FLOWER CITY CAPITAL',
    name: 'FLOWER CITY CAPITAL',
    label: 'FLOWER CITY CAPITAL',
  },
  {
    cik: '0001554972',
    value: '0001554972,FLOWERING TREE INVESTMENT MANAGEMENT PTE. LTD.',
    name: 'FLOWERING TREE INVESTMENT MANAGEMENT PTE. LTD.',
    label: 'FLOWERING TREE INVESTMENT MANAGEMENT PTE. LTD.',
  },
  {
    cik: '0001466697',
    value: '0001466697,FLOW TRADERS U.S. LLC',
    name: 'FLOW TRADERS U.S. LLC',
    label: 'FLOW TRADERS U.S. LLC',
  },
  {
    cik: '0001140334',
    value: '0001140334,FLPUTNAM INVESTMENT MANAGEMENT CO',
    name: 'FLPUTNAM INVESTMENT MANAGEMENT CO',
    label: 'FLPUTNAM INVESTMENT MANAGEMENT CO',
  },
  {
    cik: '0001722283',
    value: '0001722283,FLUENT FINANCIAL, LLC',
    name: 'FLUENT FINANCIAL, LLC',
    label: 'FLUENT FINANCIAL, LLC',
  },
  {
    cik: '0001857144',
    value: '0001857144,FLYNN ZITO CAPITAL MANAGEMENT, LLC',
    name: 'FLYNN ZITO CAPITAL MANAGEMENT, LLC',
    label: 'FLYNN ZITO CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001298596',
    value: '0001298596,FMA ADVISORY INC',
    name: 'FMA ADVISORY INC',
    label: 'FMA ADVISORY INC',
  },
  {
    cik: '0001834499',
    value: '0001834499,F/M INVESTMENTS LLC',
    name: 'F/M INVESTMENTS LLC',
    label: 'F/M INVESTMENTS LLC',
  },
  {
    cik: '0000315066',
    value: '000031,FMR LLC66',
    name: 'FMR LLC',
    label: 'FMR LLC',
  },
  {
    cik: '0001742998',
    value: '0001742998,FNY INVESTMENT ADVISERS, LLC',
    name: 'FNY INVESTMENT ADVISERS, LLC',
    label: 'FNY INVESTMENT ADVISERS, LLC',
  },
  {
    cik: '0001408324',
    value: '0001408324,FNY MANAGED ACCOUNTS LLC',
    name: 'FNY MANAGED ACCOUNTS LLC',
    label: 'FNY MANAGED ACCOUNTS LLC',
  },
  {
    cik: '0001563794',
    value: '0001563794,FNY PARTNERS FUND LP',
    name: 'FNY PARTNERS FUND LP',
    label: 'FNY PARTNERS FUND LP',
  },
  {
    cik: '0001426398',
    value: '0001426398,FOCUSED INVESTORS LLC',
    name: 'FOCUSED INVESTORS LLC',
    label: 'FOCUSED INVESTORS LLC',
  },
  {
    cik: '0001586052',
    value: '0001586052,FOCUSED WEALTH MANAGEMENT, INC',
    name: 'FOCUSED WEALTH MANAGEMENT, INC',
    label: 'FOCUSED WEALTH MANAGEMENT, INC',
  },
  {
    cik: '0001845643',
    value: '0001845643,FOCUS WEALTH ADVISORS, LLC',
    name: 'FOCUS WEALTH ADVISORS, LLC',
    label: 'FOCUS WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001665727',
    value: '0001665727,FOLGER HILL ASSET MANAGEMENT LP',
    name: 'FOLGER HILL ASSET MANAGEMENT LP',
    label: 'FOLGER HILL ASSET MANAGEMENT LP',
  },
  {
    cik: '0000885415',
    value: '0000885415,FOLGER NOLAN FLEMING DOUGLAS CAPITAL MANAGEMENT, INC',
    name: 'FOLGER NOLAN FLEMING DOUGLAS CAPITAL MANAGEMENT, INC',
    label: 'FOLGER NOLAN FLEMING DOUGLAS CAPITAL MANAGEMENT, INC',
  },
  {
    cik: '0001622611',
    value: '0001622611,FOLIOMETRIX LLC',
    name: 'FOLIOMETRIX LLC',
    label: 'FOLIOMETRIX LLC',
  },
  {
    cik: '0001269094',
    value: '0001269094,FOLKETRYGDFONDET',
    name: 'FOLKETRYGDFONDET',
    label: 'FOLKETRYGDFONDET',
  },
  {
    cik: '0001328331',
    value: '0001328331,FONDREN MANAGEMENT LP',
    name: 'FONDREN MANAGEMENT LP',
    label: 'FONDREN MANAGEMENT LP',
  },
  {
    cik: '0001357975',
    value: '0001357975,FOOTHILLS ASSET MANAGEMENT, LTD.',
    name: 'FOOTHILLS ASSET MANAGEMENT, LTD.',
    label: 'FOOTHILLS ASSET MANAGEMENT, LTD.',
  },
  {
    cik: '0001742435',
    value: '0001742435,FORA CAPITAL, LLC',
    name: 'FORA CAPITAL, LLC',
    label: 'FORA CAPITAL, LLC',
  },
  {
    cik: '0001802986',
    value: '0001802986,FORAGER CAPITAL MANAGEMENT, LLC',
    name: 'FORAGER CAPITAL MANAGEMENT, LLC',
    label: 'FORAGER CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001911150',
    value: '0001911150,FORAGER FUNDS MANAGEMENT PTY LTD',
    name: 'FORAGER FUNDS MANAGEMENT PTY LTD',
    label: 'FORAGER FUNDS MANAGEMENT PTY LTD',
  },
  {
    cik: '0001911316',
    value: '0001911316,FORBES FINANCIAL PLANNING, INC.',
    name: 'FORBES FINANCIAL PLANNING, INC.',
    label: 'FORBES FINANCIAL PLANNING, INC.',
  },
  {
    cik: '0001067532',
    value: '0001067532,FORBES J M & CO LLP',
    name: 'FORBES J M & CO LLP',
    label: 'FORBES J M & CO LLP',
  },
  {
    cik: '0001317601',
    value: '0001317601,FORCE CAPITAL MANAGEMENT LLC',
    name: 'FORCE CAPITAL MANAGEMENT LLC',
    label: 'FORCE CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001799793',
    value: '0001799793,FORCE HILL CAPITAL MANAGEMENT LP',
    name: 'FORCE HILL CAPITAL MANAGEMENT LP',
    label: 'FORCE HILL CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001730887',
    value: '0001730887,FORD FINANCIAL GROUP, INC.',
    name: 'FORD FINANCIAL GROUP, INC.',
    label: 'FORD FINANCIAL GROUP, INC.',
  },
  {
    cik: '0001730887',
    value: '0001730887,FORD FINANCIAL GROUP, LLC',
    name: 'FORD FINANCIAL GROUP, LLC',
    label: 'FORD FINANCIAL GROUP, LLC',
  },
  {
    cik: '0000315167',
    value: '0000315167,FORD FOUNDATION',
    name: 'FORD FOUNDATION',
    label: 'FORD FOUNDATION',
  },
  {
    cik: '0001714672',
    value: '0001714672,FORE CAPITAL, LLC',
    name: 'FORE CAPITAL, LLC',
    label: 'FORE CAPITAL, LLC',
  },
  {
    cik: '0001696802',
    value: '0001696802,FOREFRONT ANALYTICS, LLC',
    name: 'FOREFRONT ANALYTICS, LLC',
    label: 'FOREFRONT ANALYTICS, LLC',
  },
  {
    cik: '0001904828',
    value: '0001904828,FOREFRONT WEALTH MANAGEMENT INC.',
    name: 'FOREFRONT WEALTH MANAGEMENT INC.',
    label: 'FOREFRONT WEALTH MANAGEMENT INC.',
  },
  {
    cik: '0001313597',
    value: '0001313597,FORE RESEARCH & MANAGEMENT, LP',
    name: 'FORE RESEARCH & MANAGEMENT, LP',
    label: 'FORE RESEARCH & MANAGEMENT, LP',
  },
  {
    cik: '0001909850',
    value: '0001909850,FORERUNNER VENTURES MANAGEMENT, LLC',
    name: 'FORERUNNER VENTURES MANAGEMENT, LLC',
    label: 'FORERUNNER VENTURES MANAGEMENT, LLC',
  },
  {
    cik: '0001630936',
    value: '0001630936,FORESIGHT CAPITAL MANAGEMENT ADVISORS, INC.',
    name: 'FORESIGHT CAPITAL MANAGEMENT ADVISORS, INC.',
    label: 'FORESIGHT CAPITAL MANAGEMENT ADVISORS, INC.',
  },
  {
    cik: '0001729735',
    value: '0001729735,FORESIGHT WEALTH MANAGEMENT, LLC',
    name: 'FORESIGHT WEALTH MANAGEMENT, LLC',
    label: 'FORESIGHT WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001645157',
    value: '0001645157,FORESITE CAPITAL MANAGEMENT III, LLC',
    name: 'FORESITE CAPITAL MANAGEMENT III, LLC',
    label: 'FORESITE CAPITAL MANAGEMENT III, LLC',
  },
  {
    cik: '0001594912',
    value: '0001594912,FORESITE CAPITAL MANAGEMENT II, LLC',
    name: 'FORESITE CAPITAL MANAGEMENT II, LLC',
    label: 'FORESITE CAPITAL MANAGEMENT II, LLC',
  },
  {
    cik: '0001581219',
    value: '0001581219,FORESITE CAPITAL MANAGEMENT I, LLC',
    name: 'FORESITE CAPITAL MANAGEMENT I, LLC',
    label: 'FORESITE CAPITAL MANAGEMENT I, LLC',
  },
  {
    cik: '0001704132',
    value: '0001704132,FORESITE CAPITAL MANAGEMENT IV, LLC',
    name: 'FORESITE CAPITAL MANAGEMENT IV, LLC',
    label: 'FORESITE CAPITAL MANAGEMENT IV, LLC',
  },
  {
    cik: '0001792206',
    value: '0001792206,FORESITE CAPITAL MANAGEMENT V, LLC',
    name: 'FORESITE CAPITAL MANAGEMENT V, LLC',
    label: 'FORESITE CAPITAL MANAGEMENT V, LLC',
  },
  {
    cik: '0001792207',
    value: '0001792207,FORESITE CAPITAL OPPORTUNITY MANAGEMENT V, LLC',
    name: 'FORESITE CAPITAL OPPORTUNITY MANAGEMENT V, LLC',
    label: 'FORESITE CAPITAL OPPORTUNITY MANAGEMENT V, LLC',
  },
  {
    cik: '0001483969',
    value: '0001483969,FORESTER CAPITAL MANAGEMENT, LTD',
    name: 'FORESTER CAPITAL MANAGEMENT, LTD',
    label: 'FORESTER CAPITAL MANAGEMENT, LTD',
  },
  {
    cik: '0001682146',
    value: '0001682146,FORESTERS ASSET MANAGEMENT INC.',
    name: 'FORESTERS ASSET MANAGEMENT INC.',
    label: 'FORESTERS ASSET MANAGEMENT INC.',
  },
  {
    cik: '0000728079',
    value: '0000728079,FORESTERS INVESTMENT MANAGEMENT COMPANY, INC.',
    name: 'FORESTERS INVESTMENT MANAGEMENT COMPANY, INC.',
    label: 'FORESTERS INVESTMENT MANAGEMENT COMPANY, INC.',
  },
  {
    cik: '0001316550',
    value: '0001316550,FOREST HILL CAPITAL, LLC',
    name: 'FOREST HILL CAPITAL, LLC',
    label: 'FOREST HILL CAPITAL, LLC',
  },
  {
    cik: '0001581833',
    value: '0001581833,FORETHOUGHT INVESTMENT ADVISORS, LLC',
    name: 'FORETHOUGHT INVESTMENT ADVISORS, LLC',
    label: 'FORETHOUGHT INVESTMENT ADVISORS, LLC',
  },
  {
    cik: '0001835718',
    value: '0001835718,FORMATION8 GP, LLC',
    name: 'FORMATION8 GP, LLC',
    label: 'FORMATION8 GP, LLC',
  },
  {
    cik: '0001592613',
    value: '0001592613,FORMIDABLE ASSET MANAGEMENT, LLC',
    name: 'FORMIDABLE ASSET MANAGEMENT, LLC',
    label: 'FORMIDABLE ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001626982',
    value: '0001626982,FORMULAFOLIO INVESTMENTS, LLC',
    name: 'FORMULAFOLIO INVESTMENTS, LLC',
    label: 'FORMULAFOLIO INVESTMENTS, LLC',
  },
  {
    cik: '0001093219',
    value: '0001093219,FORMULA GROWTH LTD',
    name: 'FORMULA GROWTH LTD',
    label: 'FORMULA GROWTH LTD',
  },
  {
    cik: '0001909343',
    value: '0001909343,FORRESTAL AGRICULTURAL CORP',
    name: 'FORRESTAL AGRICULTURAL CORP',
    label: 'FORRESTAL AGRICULTURAL CORP',
  },
  {
    cik: '0001603328',
    value: '0001603328,FORSTA AP-FONDEN',
    name: 'FORSTA AP-FONDEN',
    label: 'FORSTA AP-FONDEN',
  },
  {
    cik: '0001003279',
    value: '0001003279,FORTALEZA ASSET MANAGEMENT INC/',
    name: 'FORTALEZA ASSET MANAGEMENT INC/',
    label: 'FORTALEZA ASSET MANAGEMENT INC/',
  },
  {
    cik: '0001688382',
    value: '0001688382,FORT BAKER CAPITAL MANAGEMENT LP',
    name: 'FORT BAKER CAPITAL MANAGEMENT LP',
    label: 'FORT BAKER CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001927129',
    value: '0001927129,FORTE ASSET MANAGEMENT LLC',
    name: 'FORTE ASSET MANAGEMENT LLC',
    label: 'FORTE ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001022314',
    value: '0001022314,FORTE CAPITAL LLC /ADV',
    name: 'FORTE CAPITAL LLC /ADV',
    label: 'FORTE CAPITAL LLC /ADV',
  },
  {
    cik: '0001766509',
    value: '0001766509,FORTEM FINANCIAL GROUP, LLC',
    name: 'FORTEM FINANCIAL GROUP, LLC',
    label: 'FORTEM FINANCIAL GROUP, LLC',
  },
  {
    cik: '0001843536',
    value: '0001843536,FORT HENRY CAPITAL, LLC',
    name: 'FORT HENRY CAPITAL, LLC',
    label: 'FORT HENRY CAPITAL, LLC',
  },
  {
    cik: '0001841633',
    value: '0001841633,FORTHRIGHT WEALTH MANAGEMENT, LLC',
    name: 'FORTHRIGHT WEALTH MANAGEMENT, LLC',
    label: 'FORTHRIGHT WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001697478',
    value: '0001697478,FORTIS ADVISORS, LLC',
    name: 'FORTIS ADVISORS, LLC',
    label: 'FORTIS ADVISORS, LLC',
  },
  {
    cik: '0001802167',
    value: '0001802167,FORTIS CAPITAL MANAGEMENT LLC',
    name: 'FORTIS CAPITAL MANAGEMENT LLC',
    label: 'FORTIS CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001815183',
    value: '0001815183,FORTIS GROUP ADVISORS, LLC',
    name: 'FORTIS GROUP ADVISORS, LLC',
    label: 'FORTIS GROUP ADVISORS, LLC',
  },
  {
    cik: '0001928999',
    value: '0001928999,FORTITUDE ADVISORY GROUP L.L.C.',
    name: 'FORTITUDE ADVISORY GROUP L.L.C.',
    label: 'FORTITUDE ADVISORY GROUP L.L.C.',
  },
  {
    cik: '0001633517',
    value: '000163351,FORT, L.P.',
    name: 'FORT, L.P.',
    label: 'FORT, L.P.',
  },
  {
    cik: '0001223052',
    value: '0001223052,FORT PITT CAPITAL GROUP INC',
    name: 'FORT PITT CAPITAL GROUP INC',
    label: 'FORT PITT CAPITAL GROUP INC',
  },
  {
    cik: '0001663897',
    value: '0001663897,FORT PITT CAPITAL GROUP, LLC',
    name: 'FORT PITT CAPITAL GROUP, LLC',
    label: 'FORT PITT CAPITAL GROUP, LLC',
  },
  {
    cik: '0001589282',
    value: '0001589282,FORT POINT CAPITAL PARTNERS LLC',
    name: 'FORT POINT CAPITAL PARTNERS LLC',
    label: 'FORT POINT CAPITAL PARTNERS LLC',
  },
  {
    cik: '0001380393',
    value: '0001380393,FORTRESS INVESTMENT GROUP LLC',
    name: 'FORTRESS INVESTMENT GROUP LLC',
    label: 'FORTRESS INVESTMENT GROUP LLC',
  },
  {
    cik: '0001862067',
    value: '0001862067,FORTRESS WEALTH GROUP, LLC',
    name: 'FORTRESS WEALTH GROUP, LLC',
    label: 'FORTRESS WEALTH GROUP, LLC',
  },
  {
    cik: '0001908099',
    value: '0001908099,FORTRESS WEALTH MANAGEMENT, INC.',
    name: 'FORTRESS WEALTH MANAGEMENT, INC.',
    label: 'FORTRESS WEALTH MANAGEMENT, INC.',
  },
  {
    cik: '0001606666',
    value: '0001606666,FORT SHERIDAN ADVISORS LLC',
    name: 'FORT SHERIDAN ADVISORS LLC',
    label: 'FORT SHERIDAN ADVISORS LLC',
  },
  {
    cik: '0001918707',
    value: '0001918707,FORTUNE 45 LLC',
    name: 'FORTUNE 45 LLC',
    label: 'FORTUNE 45 LLC',
  },
  {
    cik: '0001649337',
    value: '0001649337,FORT WARREN CAPITAL MANAGEMENT, LP',
    name: 'FORT WARREN CAPITAL MANAGEMENT, LP',
    label: 'FORT WARREN CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0001009016',
    value: '0001009016,FORT WASHINGTON INVESTMENT ADVISORS INC /OH/',
    name: 'FORT WASHINGTON INVESTMENT ADVISORS INC /OH/',
    label: 'FORT WASHINGTON INVESTMENT ADVISORS INC /OH/',
  },
  {
    cik: '0001561322',
    value: '0001561322,FORTY4 ASSET MANAGEMENT LLC',
    name: 'FORTY4 ASSET MANAGEMENT LLC',
    label: 'FORTY4 ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001911832',
    value: '0001911832,FORUM FINANCIAL MANAGEMENT, LP',
    name: 'FORUM FINANCIAL MANAGEMENT, LP',
    label: 'FORUM FINANCIAL MANAGEMENT, LP',
  },
  {
    cik: '0001910636',
    value: '0001910636,FORUM PRIVATE CLIENT GROUP LLC',
    name: 'FORUM PRIVATE CLIENT GROUP LLC',
    label: 'FORUM PRIVATE CLIENT GROUP LLC',
  },
  {
    cik: '0001485921',
    value: '0001485921,FORUM SECURITIES LTD',
    name: 'FORUM SECURITIES LTD',
    label: 'FORUM SECURITIES LTD',
  },
  {
    cik: '0001352864',
    value: '0001352864,FORVIS WEALTH ADVISORS, LLC',
    name: 'FORVIS WEALTH ADVISORS, LLC',
    label: 'FORVIS WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001385864',
    value: '0001385864,FORWARD MANAGEMENT, LLC',
    name: 'FORWARD MANAGEMENT, LLC',
    label: 'FORWARD MANAGEMENT, LLC',
  },
  {
    cik: '0001789908',
    value: '0001789908,FOSSE CAPITAL PARTMERS LLP',
    name: 'FOSSE CAPITAL PARTMERS LLP',
    label: 'FOSSE CAPITAL PARTMERS LLP',
  },
  {
    cik: '0001789908',
    value: '0001789908,FOSSE CAPITAL PARTNERS LLP',
    name: 'FOSSE CAPITAL PARTNERS LLP',
    label: 'FOSSE CAPITAL PARTNERS LLP',
  },
  {
    cik: '0001037558',
    value: '0001037558,FOSTER DYKEMA CABOT & CO INC/MA',
    name: 'FOSTER DYKEMA CABOT & CO INC/MA',
    label: 'FOSTER DYKEMA CABOT & CO INC/MA',
  },
  {
    cik: '0001841544',
    value: '0001841544,FOSTER GROUP, INC.',
    name: 'FOSTER GROUP, INC.',
    label: 'FOSTER GROUP, INC.',
  },
  {
    cik: '0001163902',
    value: '0001163902,FOSTER & MOTLEY INC',
    name: 'FOSTER & MOTLEY INC',
    label: 'FOSTER & MOTLEY INC',
  },
  {
    cik: '0001769063',
    value: '0001769063,FOSTER VICTOR WEALTH ADVISORS, LLC',
    name: 'FOSTER VICTOR WEALTH ADVISORS, LLC',
    label: 'FOSTER VICTOR WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001447884',
    value: '0001447884,FOSUN INTERNATIONAL LTD',
    name: 'FOSUN INTERNATIONAL LTD',
    label: 'FOSUN INTERNATIONAL LTD',
  },
  {
    cik: '0001569519',
    value: '0001569519,FOUNDATION ASSET MANAGEMENT, LLC',
    name: 'FOUNDATION ASSET MANAGEMENT, LLC',
    label: 'FOUNDATION ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001615420',
    value: '0001615420,FOUNDATION CAPITAL, LLC',
    name: 'FOUNDATION CAPITAL, LLC',
    label: 'FOUNDATION CAPITAL, LLC',
  },
  {
    cik: '0001179232',
    value: '0001179232,FOUNDATION RESOURCE MANAGEMENT INC',
    name: 'FOUNDATION RESOURCE MANAGEMENT INC',
    label: 'FOUNDATION RESOURCE MANAGEMENT INC',
  },
  {
    cik: '0001743404',
    value: '0001743404,FOUNDATIONS INVESTMENT ADVISORS, LLC',
    name: 'FOUNDATIONS INVESTMENT ADVISORS, LLC',
    label: 'FOUNDATIONS INVESTMENT ADVISORS, LLC',
  },
  {
    cik: '0001681822',
    value: '0001681822,FOUNDER COLLECTIVE GP, LLC',
    name: 'FOUNDER COLLECTIVE GP, LLC',
    label: 'FOUNDER COLLECTIVE GP, LLC',
  },
  {
    cik: '0001358096',
    value: '0001358096,FOUNDERS BANK & TRUST',
    name: 'FOUNDERS BANK & TRUST',
    label: 'FOUNDERS BANK & TRUST',
  },
  {
    cik: '0001704300',
    value: '0001704300,FOUNDERS CAPITAL MANAGEMENT',
    name: 'FOUNDERS CAPITAL MANAGEMENT',
    label: 'FOUNDERS CAPITAL MANAGEMENT',
  },
  {
    cik: '0001585828',
    value: '0001585828,FOUNDERS CAPITAL MANAGEMENT, LLC',
    name: 'FOUNDERS CAPITAL MANAGEMENT, LLC',
    label: 'FOUNDERS CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001887190',
    value: '0001887190,FOUNDERS CIRCLE CAPITAL LLC',
    name: 'FOUNDERS CIRCLE CAPITAL LLC',
    label: 'FOUNDERS CIRCLE CAPITAL LLC',
  },
  {
    cik: '0001832190',
    value: '0001832190,FOUNDERS FINANCIAL ALLIANCE, LLC',
    name: 'FOUNDERS FINANCIAL ALLIANCE, LLC',
    label: 'FOUNDERS FINANCIAL ALLIANCE, LLC',
  },
  {
    cik: '0001342396',
    value: '0001342396,FOUNDERS FINANCIAL SECURITIES LLC',
    name: 'FOUNDERS FINANCIAL SECURITIES LLC',
    label: 'FOUNDERS FINANCIAL SECURITIES LLC',
  },
  {
    cik: '0001853431',
    value: '0001853431,FOUNDERS FUND GROWTH MANAGEMENT, LLC',
    name: 'FOUNDERS FUND GROWTH MANAGEMENT, LLC',
    label: 'FOUNDERS FUND GROWTH MANAGEMENT, LLC',
  },
  {
    cik: '0001695329',
    value: '0001695329,FOUNDERS FUND II MANAGEMENT, LLC',
    name: 'FOUNDERS FUND II MANAGEMENT, LLC',
    label: 'FOUNDERS FUND II MANAGEMENT, LLC',
  },
  {
    cik: '0001852758',
    value: '0001852758,FOUNDERS FUND ILL MANAGEMENT, LLC',
    name: 'FOUNDERS FUND ILL MANAGEMENT, LLC',
    label: 'FOUNDERS FUND ILL MANAGEMENT, LLC',
  },
  {
    cik: '0001616081',
    value: '0001616081,FOUNDERS FUND IV MANAGEMENT, LLC',
    name: 'FOUNDERS FUND IV MANAGEMENT, LLC',
    label: 'FOUNDERS FUND IV MANAGEMENT, LLC',
  },
  {
    cik: '0001846021',
    value: '0001846021,FOUNDERS FUND VII MANAGEMENT, LLC',
    name: 'FOUNDERS FUND VII MANAGEMENT, LLC',
    label: 'FOUNDERS FUND VII MANAGEMENT, LLC',
  },
  {
    cik: '0001853382',
    value: '0001853382,FOUNDERS FUND VI MANAGEMENT, LLC',
    name: 'FOUNDERS FUND VI MANAGEMENT, LLC',
    label: 'FOUNDERS FUND VI MANAGEMENT, LLC',
  },
  {
    cik: '0001697733',
    value: '0001697733,FOUNDERS FUND V MANAGEMENT, LLC',
    name: 'FOUNDERS FUND V MANAGEMENT, LLC',
    label: 'FOUNDERS FUND V MANAGEMENT, LLC',
  },
  {
    cik: '0001644978',
    value: '0001644978,FOUNDRY GROUP, LLC',
    name: 'FOUNDRY GROUP, LLC',
    label: 'FOUNDRY GROUP, LLC',
  },
  {
    cik: '0001573913',
    value: '0001573913,FOUNDRY PARTNERS, LLC',
    name: 'FOUNDRY PARTNERS, LLC',
    label: 'FOUNDRY PARTNERS, LLC',
  },
  {
    cik: '0001802326',
    value: '0001802326,FOUNTAINCAP RESEARCH & INVESTMENT (HONG KONG) CO., LTD.',
    name: 'FOUNTAINCAP RESEARCH & INVESTMENT (HONG KONG) CO., LTD.',
    label: 'FOUNTAINCAP RESEARCH & INVESTMENT (HONG KONG) CO., LTD.',
  },
  {
    cik: '0001843581',
    value: '0001843581,FOUNTAINHEAD AM, LLC',
    name: 'FOUNTAINHEAD AM, LLC',
    label: 'FOUNTAINHEAD AM, LLC',
  },
  {
    cik: '0001795487',
    value: '0001795487,FOUNTAINVEST CHINA CAPITAL PARTNERS GP3 LTD.',
    name: 'FOUNTAINVEST CHINA CAPITAL PARTNERS GP3 LTD.',
    label: 'FOUNTAINVEST CHINA CAPITAL PARTNERS GP3 LTD.',
  },
  {
    cik: '0001552981',
    value: '0001552981,FOURPOINTS INVESTMENT MANAGERS, S.A.S.',
    name: 'FOURPOINTS INVESTMENT MANAGERS, S.A.S.',
    label: 'FOURPOINTS INVESTMENT MANAGERS, S.A.S.',
  },
  {
    cik: '0001865966',
    value: '0001865966,FOURTHOUGHT FINANCIAL, LLC',
    name: 'FOURTHOUGHT FINANCIAL, LLC',
    label: 'FOURTHOUGHT FINANCIAL, LLC',
  },
  {
    cik: '0001767803',
    value: '0001767803,FOURTHSTONE LLC',
    name: 'FOURTHSTONE LLC',
    label: 'FOURTHSTONE LLC',
  },
  {
    cik: '0001770525',
    value: '0001770525,FOURWORLD CAPITAL MANAGEMENT LLC',
    name: 'FOURWORLD CAPITAL MANAGEMENT LLC',
    label: 'FOURWORLD CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001590531',
    value: '0001590531,FOXHAVEN ASSET MANAGEMENT, LP',
    name: 'FOXHAVEN ASSET MANAGEMENT, LP',
    label: 'FOXHAVEN ASSET MANAGEMENT, LP',
  },
  {
    cik: '0001385011',
    value: '0001385011,FOX POINT CAPITAL MANAGEMENT LLC',
    name: 'FOX POINT CAPITAL MANAGEMENT LLC',
    label: 'FOX POINT CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001533457',
    value: '0001533457,FOX RUN MANAGEMENT, L.L.C.',
    name: 'FOX RUN MANAGEMENT, L.L.C.',
    label: 'FOX RUN MANAGEMENT, L.L.C.',
  },
  {
    cik: '0001322853',
    value: '0001322853,FOYSTON, GORDON, & PAYNE INC',
    name: 'FOYSTON, GORDON, & PAYNE INC',
    label: 'FOYSTON, GORDON, & PAYNE INC',
  },
  {
    cik: '0001546190',
    value: '0001546190,FPR PARTNERS LLC',
    name: 'FPR PARTNERS LLC',
    label: 'FPR PARTNERS LLC',
  },
  {
    cik: '0001843590',
    value: '0001843590,FRACTAL INVESTMENTS LLC',
    name: 'FRACTAL INVESTMENTS LLC',
    label: 'FRACTAL INVESTMENTS LLC',
  },
  {
    cik: '0001613331',
    value: '0001613331,FRAGASSO GROUP INC.',
    name: 'FRAGASSO GROUP INC.',
    label: 'FRAGASSO GROUP INC.',
  },
  {
    cik: '0001859388',
    value: '0001859388,FRANCHISE CAPITAL LTD',
    name: 'FRANCHISE CAPITAL LTD',
    label: 'FRANCHISE CAPITAL LTD',
  },
  {
    cik: '0001197853',
    value: '0001197853,FRANCISCO PARTNERS MANAGEMENT, LP',
    name: 'FRANCISCO PARTNERS MANAGEMENT, LP',
    label: 'FRANCISCO PARTNERS MANAGEMENT, LP',
  },
  {
    cik: '0001727514',
    value: '0001727514,FRANCIS FINANCIAL, INC.',
    name: 'FRANCIS FINANCIAL, INC.',
    label: 'FRANCIS FINANCIAL, INC.',
  },
  {
    cik: '0001707856',
    value: '0001707856,FRANKLIN, PARLAPIANO, TURNER & WELCH, LLC',
    name: 'FRANKLIN, PARLAPIANO, TURNER & WELCH, LLC',
    label: 'FRANKLIN, PARLAPIANO, TURNER & WELCH, LLC',
  },
  {
    cik: '0000038777',
    value: '0000038777,FRANKLIN RESOURCES INC',
    name: 'FRANKLIN RESOURCES INC',
    label: 'FRANKLIN RESOURCES INC',
  },
  {
    cik: '0001697432',
    value: '0001697432,FRANKLIN SQUARE HOLDINGS, L.P.',
    name: 'FRANKLIN SQUARE HOLDINGS, L.P.',
    label: 'FRANKLIN SQUARE HOLDINGS, L.P.',
  },
  {
    cik: '0001010873',
    value: '0001010873,FRANKLIN STREET ADVISORS INC /NC',
    name: 'FRANKLIN STREET ADVISORS INC /NC',
    label: 'FRANKLIN STREET ADVISORS INC /NC',
  },
  {
    cik: '0001784925',
    value: '0001784925,FRANKLIN STREET CAPITAL PARTNERS, LLC',
    name: 'FRANKLIN STREET CAPITAL PARTNERS, LLC',
    label: 'FRANKLIN STREET CAPITAL PARTNERS, LLC',
  },
  {
    cik: '0001892134',
    value: '0001892134,FRAZIER LIFE SCIENCES MANAGEMENT, L.P.',
    name: 'FRAZIER LIFE SCIENCES MANAGEMENT, L.P.',
    label: 'FRAZIER LIFE SCIENCES MANAGEMENT, L.P.',
  },
  {
    cik: '0001032165',
    value: '0001032165,FRAZIER MANAGEMENT LLC',
    name: 'FRAZIER MANAGEMENT LLC',
    label: 'FRAZIER MANAGEMENT LLC',
  },
  {
    cik: '0000003520',
    value: '0000003520,FRED ALGER MANAGEMENT INC',
    name: 'FRED ALGER MANAGEMENT INC',
    label: 'FRED ALGER MANAGEMENT INC',
  },
  {
    cik: '0000003520',
    value: '0000003520,FRED ALGER MANAGEMENT, LLC',
    name: 'FRED ALGER MANAGEMENT, LLC',
    label: 'FRED ALGER MANAGEMENT, LLC',
  },
  {
    cik: '0001844877',
    value: '0001844877,FREED INVESTMENT GROUP',
    name: 'FREED INVESTMENT GROUP',
    label: 'FREED INVESTMENT GROUP',
  },
  {
    cik: '0001846308',
    value: '0001846308,FREEDMAN FINANCIAL ASSOCIATES, INC.',
    name: 'FREEDMAN FINANCIAL ASSOCIATES, INC.',
    label: 'FREEDMAN FINANCIAL ASSOCIATES, INC.',
  },
  {
    cik: '0001467517',
    value: '0001467517,FREEDOM DAY SOLUTIONS, LLC',
    name: 'FREEDOM DAY SOLUTIONS, LLC',
    label: 'FREEDOM DAY SOLUTIONS, LLC',
  },
  {
    cik: '0001796107',
    value: '0001796107,FREEMONT CAPITAL PTE LTD',
    name: 'FREEMONT CAPITAL PTE LTD',
    label: 'FREEMONT CAPITAL PTE LTD',
  },
  {
    cik: '0001609120',
    value: '0001609120,FREEMONT MANAGEMENT S.A.',
    name: 'FREEMONT MANAGEMENT S.A.',
    label: 'FREEMONT MANAGEMENT S.A.',
  },
  {
    cik: '0001660328',
    value: '0001660328,FREESTATE ADVISORS LLC',
    name: 'FREESTATE ADVISORS LLC',
    label: 'FREESTATE ADVISORS LLC',
  },
  {
    cik: '0001470876',
    value: '0001470876,FREESTONE CAPITAL HOLDINGS, LLC',
    name: 'FREESTONE CAPITAL HOLDINGS, LLC',
    label: 'FREESTONE CAPITAL HOLDINGS, LLC',
  },
  {
    cik: '0001598381',
    value: '0001598381,FRENCH WOLF & FARR, INC.',
    name: 'FRENCH WOLF & FARR, INC.',
    label: 'FRENCH WOLF & FARR, INC.',
  },
  {
    cik: '0001459270',
    value: '0001459270,FRESHFORD CAPITAL MANAGEMENT, LLC',
    name: 'FRESHFORD CAPITAL MANAGEMENT, LLC',
    label: 'FRESHFORD CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001893327',
    value: '0001893327,FRG FAMILY WEALTH ADVISORS LLC',
    name: 'FRG FAMILY WEALTH ADVISORS LLC',
    label: 'FRG FAMILY WEALTH ADVISORS LLC',
  },
  {
    cik: '0001381859',
    value: '0001381859,FRIEDBERG INVESTMENT MANAGEMENT',
    name: 'FRIEDBERG INVESTMENT MANAGEMENT',
    label: 'FRIEDBERG INVESTMENT MANAGEMENT',
  },
  {
    cik: '0001598611',
    value: '0001598611,FRIEDENTHAL FINANCIAL',
    name: 'FRIEDENTHAL FINANCIAL',
    label: 'FRIEDENTHAL FINANCIAL',
  },
  {
    cik: '0000820289',
    value: '0000820289,FRIESS ASSOCIATES LLC',
    name: 'FRIESS ASSOCIATES LLC',
    label: 'FRIESS ASSOCIATES LLC',
  },
  {
    cik: '0001491072',
    value: '0001491072,FRIGATE VENTURES LP',
    name: 'FRIGATE VENTURES LP',
    label: 'FRIGATE VENTURES LP',
  },
  {
    cik: '0001767730',
    value: '0001767730,FRISCH FINANCIAL GROUP, INC.',
    name: 'FRISCH FINANCIAL GROUP, INC.',
    label: 'FRISCH FINANCIAL GROUP, INC.',
  },
  {
    cik: '0000936698',
    value: '0000936698,FRONT BARNETT ASSOCIATES LLC',
    name: 'FRONT BARNETT ASSOCIATES LLC',
    label: 'FRONT BARNETT ASSOCIATES LLC',
  },
  {
    cik: '0001430308',
    value: '0001430308,FRONTFOUR CAPITAL GROUP LLC',
    name: 'FRONTFOUR CAPITAL GROUP LLC',
    label: 'FRONTFOUR CAPITAL GROUP LLC',
  },
  {
    cik: '0001910146',
    value: '0001910146,FRONTIER ASSET MANAGEMENT, LLC',
    name: 'FRONTIER ASSET MANAGEMENT, LLC',
    label: 'FRONTIER ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0000351173',
    value: '0000351173,FRONTIER CAPITAL MANAGEMENT CO LLC',
    name: 'FRONTIER CAPITAL MANAGEMENT CO LLC',
    label: 'FRONTIER CAPITAL MANAGEMENT CO LLC',
  },
  {
    cik: '0001316539',
    value: '0001316539,FRONTIER INVESTMENT MGMT CO',
    name: 'FRONTIER INVESTMENT MGMT CO',
    label: 'FRONTIER INVESTMENT MGMT CO',
  },
  {
    cik: '0001548179',
    value: '0001548179,FRONTIER WEALTH MANAGEMENT LLC',
    name: 'FRONTIER WEALTH MANAGEMENT LLC',
    label: 'FRONTIER WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0001729254',
    value: '0001729254,FRONT ROW ADVISORS LLC',
    name: 'FRONT ROW ADVISORS LLC',
    label: 'FRONT ROW ADVISORS LLC',
  },
  {
    cik: '0001683182',
    value: '0001683182,FRONT STREET CAPITAL MANAGEMENT, INC.',
    name: 'FRONT STREET CAPITAL MANAGEMENT, INC.',
    label: 'FRONT STREET CAPITAL MANAGEMENT, INC.',
  },
  {
    cik: '0001416856',
    value: '0001416856,FRUTH INVESTMENT MANAGEMENT',
    name: 'FRUTH INVESTMENT MANAGEMENT',
    label: 'FRUTH INVESTMENT MANAGEMENT',
  },
  {
    cik: '0001804256',
    value: '0001804256,FSA WEALTH MANAGEMENT LLC',
    name: 'FSA WEALTH MANAGEMENT LLC',
    label: 'FSA WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0000887748',
    value: '0000887748,FSB PREMIER WEALTH MANAGEMENT, INC.',
    name: 'FSB PREMIER WEALTH MANAGEMENT, INC.',
    label: 'FSB PREMIER WEALTH MANAGEMENT, INC.',
  },
  {
    cik: '0001623580',
    value: '0001623580,FS CAPITAL PARTNERS VI, LLC',
    name: 'FS CAPITAL PARTNERS VI, LLC',
    label: 'FS CAPITAL PARTNERS VI, LLC',
  },
  {
    cik: '0001406857',
    value: '0001406857,FS CAPITAL PARTNERS V, LLC',
    name: 'FS CAPITAL PARTNERS V, LLC',
    label: 'FS CAPITAL PARTNERS V, LLC',
  },
  {
    cik: '0001925685',
    value: '0001925685,FSC WEALTH ADVISORS, LLC',
    name: 'FSC WEALTH ADVISORS, LLC',
    label: 'FSC WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001041241',
    value: '0001041241,FSI GROUP, LLC',
    name: 'FSI GROUP, LLC',
    label: 'FSI GROUP, LLC',
  },
  {
    cik: '0001942548',
    value: '0001942548,FSM WEALTH ADVISORS, LLC',
    name: 'FSM WEALTH ADVISORS, LLC',
    label: 'FSM WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001512383',
    value: '0001512383,F-SQUARED INVESTMENT MANAGEMENT, LLC',
    name: 'F-SQUARED INVESTMENT MANAGEMENT, LLC',
    label: 'F-SQUARED INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001588539',
    value: '0001588539,FTB ADVISORS, INC.',
    name: 'FTB ADVISORS, INC.',
    label: 'FTB ADVISORS, INC.',
  },
  {
    cik: '0001594954',
    value: '0001594954,FT OPTIONS LLC',
    name: 'FT OPTIONS LLC',
    label: 'FT OPTIONS LLC',
  },
  {
    cik: '0001033324',
    value: '0001033324,FUKOKU MUTUAL LIFE INSURANCE CO',
    name: 'FUKOKU MUTUAL LIFE INSURANCE CO',
    label: 'FUKOKU MUTUAL LIFE INSURANCE CO',
  },
  {
    cik: '0001353395',
    value: '0001353395,FULCRUM ASSET MANAGEMENT LLP',
    name: 'FULCRUM ASSET MANAGEMENT LLP',
    label: 'FULCRUM ASSET MANAGEMENT LLP',
  },
  {
    cik: '0001724090',
    value: '0001724090,FULCRUM CAPITAL LLC',
    name: 'FULCRUM CAPITAL LLC',
    label: 'FULCRUM CAPITAL LLC',
  },
  {
    cik: '0001737888',
    value: '0001737888,FULCRUM EQUITY MANAGEMENT',
    name: 'FULCRUM EQUITY MANAGEMENT',
    label: 'FULCRUM EQUITY MANAGEMENT',
  },
  {
    cik: '0001797673',
    value: '0001797673,FULL18 CAPITAL LLC',
    name: 'FULL18 CAPITAL LLC',
    label: 'FULL18 CAPITAL LLC',
  },
  {
    cik: '0001822731',
    value: '0001822731,FULLEN FINANCIAL GROUP',
    name: 'FULLEN FINANCIAL GROUP',
    label: 'FULLEN FINANCIAL GROUP',
  },
  {
    cik: '0001082327',
    value: '0001082327,FULLER & THALER ASSET MANAGEMENT, INC.',
    name: 'FULLER & THALER ASSET MANAGEMENT, INC.',
    label: 'FULLER & THALER ASSET MANAGEMENT, INC.',
  },
  {
    cik: '0001592746',
    value: '0001592746,FULLERTON FUND MANAGEMENT CO LTD',
    name: 'FULLERTON FUND MANAGEMENT CO LTD',
    label: 'FULLERTON FUND MANAGEMENT CO LTD',
  },
  {
    cik: '0001592746',
    value: '0001592746,FULLERTON FUND MANAGEMENT CO LTD.',
    name: 'FULLERTON FUND MANAGEMENT CO LTD.',
    label: 'FULLERTON FUND MANAGEMENT CO LTD.',
  },
  {
    cik: '0001757617',
    value: '0001757617,FULL SAIL CAPITAL, LLC',
    name: 'FULL SAIL CAPITAL, LLC',
    label: 'FULL SAIL CAPITAL, LLC',
  },
  {
    cik: '0001388269',
    value: '0001388269,FULL VALUE ADVISORS LLC',
    name: 'FULL VALUE ADVISORS LLC',
    label: 'FULL VALUE ADVISORS LLC',
  },
  {
    cik: '0000883782',
    value: '0000883782,FULTON BANK, N.A.',
    name: 'FULTON BANK, N.A.',
    label: 'FULTON BANK, N.A.',
  },
  {
    cik: '0001119254',
    value: '0001119254,FULTON BREAKEFIELD BROENNIMAN LLC',
    name: 'FULTON BREAKEFIELD BROENNIMAN LLC',
    label: 'FULTON BREAKEFIELD BROENNIMAN LLC',
  },
  {
    cik: '0001707206',
    value: '0001707206,FUNDAMENTUN, LLC',
    name: 'FUNDAMENTUN, LLC',
    label: 'FUNDAMENTUN, LLC',
  },
  {
    cik: '0001652027',
    value: '0001652027,FUNDE SINO LIFE INSURANCE CO LTD',
    name: 'FUNDE SINO LIFE INSURANCE CO LTD',
    label: 'FUNDE SINO LIFE INSURANCE CO LTD',
  },
  {
    cik: '0001356249',
    value: '0001356249,FUND EVALUATION GROUP, LLC',
    name: 'FUND EVALUATION GROUP, LLC',
    label: 'FUND EVALUATION GROUP, LLC',
  },
  {
    cik: '0001927961',
    value: '0001927961,FUND MANAGEMENT AT ENGINE NO. 1 LLC',
    name: 'FUND MANAGEMENT AT ENGINE NO. 1 LLC',
    label: 'FUND MANAGEMENT AT ENGINE NO. 1 LLC',
  },
  {
    cik: '0001520023',
    value: '0001520023,FUNDSMITH EQUITY FUND, L.P.',
    name: 'FUNDSMITH EQUITY FUND, L.P.',
    label: 'FUNDSMITH EQUITY FUND, L.P.',
  },
  {
    cik: '0001868537',
    value: '0001868537,FUNDSMITH INVESTMENT SERVICES LTD.',
    name: 'FUNDSMITH INVESTMENT SERVICES LTD.',
    label: 'FUNDSMITH INVESTMENT SERVICES LTD.',
  },
  {
    cik: '0001569205',
    value: '0001569205,FUNDSMITH LLP',
    name: 'FUNDSMITH LLP',
    label: 'FUNDSMITH LLP',
  },
  {
    cik: '0001813932',
    value: '0001813932,FUNDSMITH LONG/SHORT MASTER FUND',
    name: 'FUNDSMITH LONG/SHORT MASTER FUND',
    label: 'FUNDSMITH LONG/SHORT MASTER FUND',
  },
  {
    cik: '0001427261',
    value: '0001427261,FUNDX INVESTMENT GROUP, LLC',
    name: 'FUNDX INVESTMENT GROUP, LLC',
    label: 'FUNDX INVESTMENT GROUP, LLC',
  },
  {
    cik: '0000841802',
    value: '0000841802,FURE FINANCIAL CORP',
    name: 'FURE FINANCIAL CORP',
    label: 'FURE FINANCIAL CORP',
  },
  {
    cik: '0001802955',
    value: '0001802955,FUSION CAPITAL, LLC',
    name: 'FUSION CAPITAL, LLC',
    label: 'FUSION CAPITAL, LLC',
  },
  {
    cik: '0001696209',
    value: '0001696209,FUSION FAMILY WEALTH LLC',
    name: 'FUSION FAMILY WEALTH LLC',
    label: 'FUSION FAMILY WEALTH LLC',
  },
  {
    cik: '0001599633',
    value: '0001599633,FUSION INVESTMENT GROUP, LLC',
    name: 'FUSION INVESTMENT GROUP, LLC',
    label: 'FUSION INVESTMENT GROUP, LLC',
  },
  {
    cik: '0001801892',
    value: '0001801892,FUTURE FINANCIAL WEALTH MANAGMENT LLC',
    name: 'FUTURE FINANCIAL WEALTH MANAGMENT LLC',
    label: 'FUTURE FINANCIAL WEALTH MANAGMENT LLC',
  },
  {
    cik: '0001911970',
    value: '0001911970,FUTURE FUND LLC',
    name: 'FUTURE FUND LLC',
    label: 'FUTURE FUND LLC',
  },
  {
    cik: '0001536726',
    value: '0001536726,FUTURIS ASSET MANAGEMENT AB',
    name: 'FUTURIS ASSET MANAGEMENT AB',
    label: 'FUTURIS ASSET MANAGEMENT AB',
  },
  {
    cik: '0001569453',
    value: '0001569453,F&V CAPITAL MANAGEMENT, LLC',
    name: 'F&V CAPITAL MANAGEMENT, LLC',
    label: 'F&V CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001829231',
    value: '0001829231,FWL INVESTMENT MANAGEMENT, LLC',
    name: 'FWL INVESTMENT MANAGEMENT, LLC',
    label: 'FWL INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001910858',
    value: '0001910858,G2 CAPITAL MANAGEMENT, LLC / OH',
    name: 'G2 CAPITAL MANAGEMENT, LLC / OH',
    label: 'G2 CAPITAL MANAGEMENT, LLC / OH',
  },
  {
    cik: '0001593404',
    value: '0001593404,G2 INVESTMENT PARTNERS MANAGEMENT LLC',
    name: 'G2 INVESTMENT PARTNERS MANAGEMENT LLC',
    label: 'G2 INVESTMENT PARTNERS MANAGEMENT LLC',
  },
  {
    cik: '0001702721',
    value: '0001702721,G2VP I ASSOCIATES, LLC',
    name: 'G2VP I ASSOCIATES, LLC',
    label: 'G2VP I ASSOCIATES, LLC',
  },
  {
    cik: '0001454423',
    value: '0001454423,GABALEX CAPITAL MANAGEMENT LLC',
    name: 'GABALEX CAPITAL MANAGEMENT LLC',
    label: 'GABALEX CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0000932724',
    value: '0000932724,GABELLI & CO INVESTMENT ADVISERS, INC.',
    name: 'GABELLI & CO INVESTMENT ADVISERS, INC.',
    label: 'GABELLI & CO INVESTMENT ADVISERS, INC.',
  },
  {
    cik: '0001081407',
    value: '0001081407,GABELLI FUNDS LLC',
    name: 'GABELLI FUNDS LLC',
    label: 'GABELLI FUNDS LLC',
  },
  {
    cik: '0000932724',
    value: '0000932724,GABELLI SECURITIES, INC.',
    name: 'GABELLI SECURITIES, INC.',
    label: 'GABELLI SECURITIES, INC.',
  },
  {
    cik: '0001740839',
    value: '0001740839,GABLES CAPITAL MANAGEMENT INC.',
    name: 'GABLES CAPITAL MANAGEMENT INC.',
    label: 'GABLES CAPITAL MANAGEMENT INC.',
  },
  {
    cik: '0001599466',
    value: '0001599466,GAGNON ADVISORS, LLC',
    name: 'GAGNON ADVISORS, LLC',
    label: 'GAGNON ADVISORS, LLC',
  },
  {
    cik: '0001125725',
    value: '0001125725,GAGNON SECURITIES LLC',
    name: 'GAGNON SECURITIES LLC',
    label: 'GAGNON SECURITIES LLC',
  },
  {
    cik: '0001697857',
    value: '0001697857,GAINPLAN LLC',
    name: 'GAINPLAN LLC',
    label: 'GAINPLAN LLC',
  },
  {
    cik: '0001446804',
    value: '0001446804,GALAXY CAPITAL TRADING LTD',
    name: 'GALAXY CAPITAL TRADING LTD',
    label: 'GALAXY CAPITAL TRADING LTD',
  },
  {
    cik: '0001765768',
    value: '0001765768,GALIBIER CAPITAL MANAGEMENT LTD.',
    name: 'GALIBIER CAPITAL MANAGEMENT LTD.',
    label: 'GALIBIER CAPITAL MANAGEMENT LTD.',
  },
  {
    cik: '0001740858',
    value: '0001740858,GALILEO (PTC) LTD',
    name: 'GALILEO (PTC) LTD',
    label: 'GALILEO (PTC) LTD',
  },
  {
    cik: '0001960749',
    value: '0001960749,GALLACHER CAPITAL MANAGEMENT LLC',
    name: 'GALLACHER CAPITAL MANAGEMENT LLC',
    label: 'GALLACHER CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001527781',
    value: '0001527781,GALLAGHER FIDUCIARY ADVISORS, LLC',
    name: 'GALLAGHER FIDUCIARY ADVISORS, LLC',
    label: 'GALLAGHER FIDUCIARY ADVISORS, LLC',
  },
  {
    cik: '0001840945',
    value: '0001840945,GALVIN, GAUSTAD & STEIN, LLC',
    name: 'GALVIN, GAUSTAD & STEIN, LLC',
    label: 'GALVIN, GAUSTAD & STEIN, LLC',
  },
  {
    cik: '0001083340',
    value: '0001083340,GAMBLE JONES INVESTMENT COUNSEL',
    name: 'GAMBLE JONES INVESTMENT COUNSEL',
    label: 'GAMBLE JONES INVESTMENT COUNSEL',
  },
  {
    cik: '0000807249',
    value: '0000807249,GAMCO INVESTORS, INC. ET AL',
    name: 'GAMCO INVESTORS, INC. ET AL',
    label: 'GAMCO INVESTORS, INC. ET AL',
  },
  {
    cik: '0001731132',
    value: '0001731132,GAME CREEK CAPITAL, LP',
    name: 'GAME CREEK CAPITAL, LP',
    label: 'GAME CREEK CAPITAL, LP',
  },
  {
    cik: '0001802952',
    value: '0001802952,GAME PLAN FINANCIAL ADVISORS, LLC',
    name: 'GAME PLAN FINANCIAL ADVISORS, LLC',
    label: 'GAME PLAN FINANCIAL ADVISORS, LLC',
  },
  {
    cik: '0001438848',
    value: '0001438848,GAM HOLDING AG',
    name: 'GAM HOLDING AG',
    label: 'GAM HOLDING AG',
  },
  {
    cik: '0001082917',
    value: '0001082917,GANNETT WELSH & KOTLER',
    name: 'GANNETT WELSH & KOTLER',
    label: 'GANNETT WELSH & KOTLER',
  },
  {
    cik: '0001013162',
    value: '0001013162,GANTZERT INVESTMENT CO LLC /ADV',
    name: 'GANTZERT INVESTMENT CO LLC /ADV',
    label: 'GANTZERT INVESTMENT CO LLC /ADV',
  },
  {
    cik: '0000887813',
    value: '0000887813,GARCIA HAMILTON & ASSOCIATES LP/DE',
    name: 'GARCIA HAMILTON & ASSOCIATES LP/DE',
    label: 'GARCIA HAMILTON & ASSOCIATES LP/DE',
  },
  {
    cik: '0001616328',
    value: '0001616328,GARDE CAPITAL, INC.',
    name: 'GARDE CAPITAL, INC.',
    label: 'GARDE CAPITAL, INC.',
  },
  {
    cik: '0001661532',
    value: '0001661532,GARDINER NANCY B',
    name: 'GARDINER NANCY B',
    label: 'GARDINER NANCY B',
  },
  {
    cik: '0000941560',
    value: '0000941560,GARDNER LEWIS ASSET MANAGEMENT L P',
    name: 'GARDNER LEWIS ASSET MANAGEMENT L P',
    label: 'GARDNER LEWIS ASSET MANAGEMENT L P',
  },
  {
    cik: '0000860643',
    value: '0000860643,GARDNER RUSSO & GARDNER',
    name: 'GARDNER RUSSO & GARDNER',
    label: 'GARDNER RUSSO & GARDNER',
  },
  {
    cik: '0000860643',
    value: '0000860643,GARDNER RUSSO & QUINN LLC',
    name: 'GARDNER RUSSO & QUINN LLC',
    label: 'GARDNER RUSSO & QUINN LLC',
  },
  {
    cik: '0001641391',
    value: '0001641391,GARELICK CAPITAL PARTNERS, LP',
    name: 'GARELICK CAPITAL PARTNERS, LP',
    label: 'GARELICK CAPITAL PARTNERS, LP',
  },
  {
    cik: '0001555829',
    value: '0001555829,GARETH MORGAN INVESTMENTS LIMITED PARTNERSHIP',
    name: 'GARETH MORGAN INVESTMENTS LIMITED PARTNERSHIP',
    label: 'GARETH MORGAN INVESTMENTS LIMITED PARTNERSHIP',
  },
  {
    cik: '0001389974',
    value: '0001389974,GARGOYLE INVESTMENT ADVISOR L.L.C.',
    name: 'GARGOYLE INVESTMENT ADVISOR L.L.C.',
    label: 'GARGOYLE INVESTMENT ADVISOR L.L.C.',
  },
  {
    cik: '0001181544',
    value: '0001181544,GARLAND CAPITAL MANAGEMENT INC',
    name: 'GARLAND CAPITAL MANAGEMENT INC',
    label: 'GARLAND CAPITAL MANAGEMENT INC',
  },
  {
    cik: '0001714093',
    value: '0001714093,GARNER ASSET MANAGEMENT CORP',
    name: 'GARNER ASSET MANAGEMENT CORP',
    label: 'GARNER ASSET MANAGEMENT CORP',
  },
  {
    cik: '0001423876',
    value: '0001423876,GARNET EQUITY CAPITAL HOLDINGS, INC.',
    name: 'GARNET EQUITY CAPITAL HOLDINGS, INC.',
    label: 'GARNET EQUITY CAPITAL HOLDINGS, INC.',
  },
  {
    cik: '0001841506',
    value: '0001841506,GARRETT INVESTMENT ADVISORS LLC',
    name: 'GARRETT INVESTMENT ADVISORS LLC',
    label: 'GARRETT INVESTMENT ADVISORS LLC',
  },
  {
    cik: '0001729443',
    value: '0001729443,GARRETT WEALTH ADVISORY GROUP, LLC',
    name: 'GARRETT WEALTH ADVISORY GROUP, LLC',
    label: 'GARRETT WEALTH ADVISORY GROUP, LLC',
  },
  {
    cik: '0001519319',
    value: '0001519319,GARRISON ASSET MANAGEMENT, LLC',
    name: 'GARRISON ASSET MANAGEMENT, LLC',
    label: 'GARRISON ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001056488',
    value: '0001056488,GARRISON BRADFORD & ASSOCIATES INC',
    name: 'GARRISON BRADFORD & ASSOCIATES INC',
    label: 'GARRISON BRADFORD & ASSOCIATES INC',
  },
  {
    cik: '0001519319',
    value: '0001519319,GARRISON FINANCIAL CORP',
    name: 'GARRISON FINANCIAL CORP',
    label: 'GARRISON FINANCIAL CORP',
  },
  {
    cik: '0001767107',
    value: '0001767107,GARRISON POINT ADVISORS, LLC',
    name: 'GARRISON POINT ADVISORS, LLC',
    label: 'GARRISON POINT ADVISORS, LLC',
  },
  {
    cik: '0001820538',
    value: '0001820538,GARRISON POINT CAPITAL, LLC',
    name: 'GARRISON POINT CAPITAL, LLC',
    label: 'GARRISON POINT CAPITAL, LLC',
  },
  {
    cik: '0001569517',
    value: '0001569517,GARY GOLDBERG PLANNING SERVICES INC',
    name: 'GARY GOLDBERG PLANNING SERVICES INC',
    label: 'GARY GOLDBERG PLANNING SERVICES INC',
  },
  {
    cik: '0001312908',
    value: '0001312908,GATES CAPITAL MANAGEMENT, INC.',
    name: 'GATES CAPITAL MANAGEMENT, INC.',
    label: 'GATES CAPITAL MANAGEMENT, INC.',
  },
  {
    cik: '0001512404',
    value: '0001512404,GATEWAY ADVISORY, LLC',
    name: 'GATEWAY ADVISORY, LLC',
    label: 'GATEWAY ADVISORY, LLC',
  },
  {
    cik: '0001114618',
    value: '0001114618,GATEWAY INVESTMENT ADVISERS LLC',
    name: 'GATEWAY INVESTMENT ADVISERS LLC',
    label: 'GATEWAY INVESTMENT ADVISERS LLC',
  },
  {
    cik: '0001570284',
    value: '0001570284,GATOR CAPITAL MANAGEMENT, LLC',
    name: 'GATOR CAPITAL MANAGEMENT, LLC',
    label: 'GATOR CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001523705',
    value: '0001523705,GAVEA INVESTIMENTOS LTDA',
    name: 'GAVEA INVESTIMENTOS LTDA',
    label: 'GAVEA INVESTIMENTOS LTDA',
  },
  {
    cik: '0001563223',
    value: '0001563223,GAVEKAL CAPITAL, LLC',
    name: 'GAVEKAL CAPITAL, LLC',
    label: 'GAVEKAL CAPITAL, LLC',
  },
  {
    cik: '0001655095',
    value: '0001655095,GCA INVESTMENT MANAGEMENT, LLC',
    name: 'GCA INVESTMENT MANAGEMENT, LLC',
    label: 'GCA INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001939480',
    value: '0001939480,GCM GROSVENOR HOLDINGS, LLC',
    name: 'GCM GROSVENOR HOLDINGS, LLC',
    label: 'GCM GROSVENOR HOLDINGS, LLC',
  },
  {
    cik: '0001569413',
    value: '0001569413,GCP CAPITAL PARTNERS LLC',
    name: 'GCP CAPITAL PARTNERS LLC',
    label: 'GCP CAPITAL PARTNERS LLC',
  },
  {
    cik: '0001940869',
    value: '0001940869,GDS WEALTH MANAGEMENT',
    name: 'GDS WEALTH MANAGEMENT',
    label: 'GDS WEALTH MANAGEMENT',
  },
  {
    cik: '0001352107',
    value: '000135210,GEDULD E E',
    name: 'GEDULD E E',
    label: 'GEDULD E E',
  },
  {
    cik: '0001848930',
    value: '0001848930,GEIER ASSET MANAGEMENT, INC.',
    name: 'GEIER ASSET MANAGEMENT, INC.',
    label: 'GEIER ASSET MANAGEMENT, INC.',
  },
  {
    cik: '0001003322',
    value: '0001003322,GE INSURANCE PLAN TRUST',
    name: 'GE INSURANCE PLAN TRUST',
    label: 'GE INSURANCE PLAN TRUST',
  },
  {
    cik: '0001354739',
    value: '0001354739,GELLER ADVISORS LLC',
    name: 'GELLER ADVISORS LLC',
    label: 'GELLER ADVISORS LLC',
  },
  {
    cik: '0001607825',
    value: '0001607825,GEMMER ASSET MANAGEMENT LLC',
    name: 'GEMMER ASSET MANAGEMENT LLC',
    label: 'GEMMER ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001544554',
    value: '0001544554,GEM REALTY CAPITAL',
    name: 'GEM REALTY CAPITAL',
    label: 'GEM REALTY CAPITAL',
  },
  {
    cik: '0001724140',
    value: '0001724140,GEMSSTOCK LTD.',
    name: 'GEMSSTOCK LTD.',
    label: 'GEMSSTOCK LTD.',
  },
  {
    cik: '0001056581',
    value: '0001056581,GENDELL JEFFREY L',
    name: 'GENDELL JEFFREY L',
    label: 'GENDELL JEFFREY L',
  },
  {
    cik: '0001764754',
    value: '0001764754,GENEOS WEALTH MANAGEMENT INC.',
    name: 'GENEOS WEALTH MANAGEMENT INC.',
    label: 'GENEOS WEALTH MANAGEMENT INC.',
  },
  {
    cik: '0000040417',
    value: '0000040417,GENERAL AMERICAN INVESTORS CO INC',
    name: 'GENERAL AMERICAN INVESTORS CO INC',
    label: 'GENERAL AMERICAN INVESTORS CO INC',
  },
  {
    cik: '0001017645',
    value: '0001017645,GENERAL ATLANTIC LLC',
    name: 'GENERAL ATLANTIC LLC',
    label: 'GENERAL ATLANTIC LLC',
  },
  {
    cik: '0001017645',
    value: '0001017645,GENERAL ATLANTIC, L.P.',
    name: 'GENERAL ATLANTIC, L.P.',
    label: 'GENERAL ATLANTIC, L.P.',
  },
  {
    cik: '0001637946',
    value: '0001637946,GENERAL CATALYST GROUP MANAGEMENT, LLC',
    name: 'GENERAL CATALYST GROUP MANAGEMENT, LLC',
    label: 'GENERAL CATALYST GROUP MANAGEMENT, LLC',
  },
  {
    cik: '0000040545',
    value: '0000040545,GENERAL ELECTRIC CO',
    name: 'GENERAL ELECTRIC CO',
    label: 'GENERAL ELECTRIC CO',
  },
  {
    cik: '0001700362',
    value: '0001700362,GENERAL EQUITY HOLDINGS LP',
    name: 'GENERAL EQUITY HOLDINGS LP',
    label: 'GENERAL EQUITY HOLDINGS LP',
  },
  {
    cik: '0001497345',
    value: '0001497345,GENERAL MOTORS HOLDINGS LLC',
    name: 'GENERAL MOTORS HOLDINGS LLC',
    label: 'GENERAL MOTORS HOLDINGS LLC',
  },
  {
    cik: '0000883422',
    value: '0000883422,GENERAL MOTORS INVESTMENT MANAGEMENT CORP',
    name: 'GENERAL MOTORS INVESTMENT MANAGEMENT CORP',
    label: 'GENERAL MOTORS INVESTMENT MANAGEMENT CORP',
  },
  {
    cik: '0001800508',
    value: '0001800508,GENERAL PARTNER, INC.',
    name: 'GENERAL PARTNER, INC.',
    label: 'GENERAL PARTNER, INC.',
  },
  {
    cik: '0001004244',
    value: '0001004244,GENERAL RE NEW ENGLAND ASSET MANAGEMENT INC',
    name: 'GENERAL RE NEW ENGLAND ASSET MANAGEMENT INC',
    label: 'GENERAL RE NEW ENGLAND ASSET MANAGEMENT INC',
  },
  {
    cik: '0001375534',
    value: '0001375534,GENERATION INVESTMENT MANAGEMENT LLP',
    name: 'GENERATION INVESTMENT MANAGEMENT LLP',
    label: 'GENERATION INVESTMENT MANAGEMENT LLP',
  },
  {
    cik: '0001652348',
    value: '0001652348,GENESEE CAPITAL ADVISORS, LLC',
    name: 'GENESEE CAPITAL ADVISORS, LLC',
    label: 'GENESEE CAPITAL ADVISORS, LLC',
  },
  {
    cik: '0001159457',
    value: '0001159457,GENESEE VALLEY TRUST CO',
    name: 'GENESEE VALLEY TRUST CO',
    label: 'GENESEE VALLEY TRUST CO',
  },
  {
    cik: '0001300978',
    value: '0001300978,GENESIS ASSET MANAGERS, LLP',
    name: 'GENESIS ASSET MANAGERS, LLP',
    label: 'GENESIS ASSET MANAGERS, LLP',
  },
  {
    cik: '0001924185',
    value: '0001924185,GENESIS FORTUNE LTD',
    name: 'GENESIS FORTUNE LTD',
    label: 'GENESIS FORTUNE LTD',
  },
  {
    cik: '0001300979',
    value: '0001300979,GENESIS INVESTMENT MANAGEMENT, LLP',
    name: 'GENESIS INVESTMENT MANAGEMENT, LLP',
    label: 'GENESIS INVESTMENT MANAGEMENT, LLP',
  },
  {
    cik: '0001637241',
    value: '0001637241,GENESIS WEALTH ADVISORS, LLC',
    name: 'GENESIS WEALTH ADVISORS, LLC',
    label: 'GENESIS WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001269932',
    value: '0001269932,GENEVA ADVISORS, LLC',
    name: 'GENEVA ADVISORS, LLC',
    label: 'GENEVA ADVISORS, LLC',
  },
  {
    cik: '0001009232',
    value: '0001009232,GENEVA CAPITAL MANAGEMENT LLC',
    name: 'GENEVA CAPITAL MANAGEMENT LLC',
    label: 'GENEVA CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001269932',
    value: '0001269932,GENEVA INVESTMENT MANAGEMENT OF CHICAGO LLC',
    name: 'GENEVA INVESTMENT MANAGEMENT OF CHICAGO LLC',
    label: 'GENEVA INVESTMENT MANAGEMENT OF CHICAGO LLC',
  },
  {
    cik: '0001712671',
    value: '0001712671,GENEVA PARTNERS, LLC',
    name: 'GENEVA PARTNERS, LLC',
    label: 'GENEVA PARTNERS, LLC',
  },
  {
    cik: '0001697367',
    value: '0001697367,GEN IV INVESTMENT OPPORTUNITIES, LLC',
    name: 'GEN IV INVESTMENT OPPORTUNITIES, LLC',
    label: 'GEN IV INVESTMENT OPPORTUNITIES, LLC',
  },
  {
    cik: '0001622852',
    value:
      '0001622852,GENOVESE BURFORD & BROTHERS WEALTH & RETIREMENT PLAN MANAGEMENT, LLC',
    name: 'GENOVESE BURFORD & BROTHERS WEALTH & RETIREMENT PLAN MANAGEMENT, LLC',
    label:
      'GENOVESE BURFORD & BROTHERS WEALTH & RETIREMENT PLAN MANAGEMENT, LLC',
  },
  {
    cik: '0001697110',
    value: '0001697110,GENTRUST, LLC',
    name: 'GENTRUST, LLC',
    label: 'GENTRUST, LLC',
  },
  {
    cik: '0001713678',
    value: '0001713678,GENWEALTH GROUP, INC.',
    name: 'GENWEALTH GROUP, INC.',
    label: 'GENWEALTH GROUP, INC.',
  },
  {
    cik: '0001276520',
    value: '0001276520,GENWORTH FINANCIAL INC',
    name: 'GENWORTH FINANCIAL INC',
    label: 'GENWORTH FINANCIAL INC',
  },
  {
    cik: '0001766561',
    value: '0001766561,GEO CAPITAL GESTORA DE RECURSOS LTD',
    name: 'GEO CAPITAL GESTORA DE RECURSOS LTD',
    label: 'GEO CAPITAL GESTORA DE RECURSOS LTD',
  },
  {
    cik: '0001214717',
    value: '0001214717,GEODE CAPITAL MANAGEMENT, LLC',
    name: 'GEODE CAPITAL MANAGEMENT, LLC',
    label: 'GEODE CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001352117',
    value: '0001352117,GEOLOGIC RESOURCE PARTNERS, LLC',
    name: 'GEOLOGIC RESOURCE PARTNERS, LLC',
    label: 'GEOLOGIC RESOURCE PARTNERS, LLC',
  },
  {
    cik: '0001900576',
    value: '0001900576,GEOMETRIC WEALTH ADVISORS',
    name: 'GEOMETRIC WEALTH ADVISORS',
    label: 'GEOMETRIC WEALTH ADVISORS',
  },
  {
    cik: '0001265376',
    value: '0001265376,GEORGE KAISER FAMILY FOUNDATION',
    name: 'GEORGE KAISER FAMILY FOUNDATION',
    label: 'GEORGE KAISER FAMILY FOUNDATION',
  },
  {
    cik: '0001730469',
    value: '0001730469,GEORGETOWN UNIVERSITY',
    name: 'GEORGETOWN UNIVERSITY',
    label: 'GEORGETOWN UNIVERSITY',
  },
  {
    cik: '0001333602',
    value: '0001333602,GEORGE ZACHARY',
    name: 'GEORGE ZACHARY',
    label: 'GEORGE ZACHARY',
  },
  {
    cik: '0001056821',
    value: '0001056821,GEORGIA BENNICAS DBA BENNICAS & ASSOCIATES',
    name: 'GEORGIA BENNICAS DBA BENNICAS & ASSOCIATES',
    label: 'GEORGIA BENNICAS DBA BENNICAS & ASSOCIATES',
  },
  {
    cik: '0001698448',
    value: '0001698448,GEORGIAN PARTNERS I GP INC.',
    name: 'GEORGIAN PARTNERS I GP INC.',
    label: 'GEORGIAN PARTNERS I GP INC.',
  },
  {
    cik: '0001426876',
    value: '0001426876,GEOSPHERE CAPITAL MANAGEMENT',
    name: 'GEOSPHERE CAPITAL MANAGEMENT',
    label: 'GEOSPHERE CAPITAL MANAGEMENT',
  },
  {
    cik: '0001593387',
    value: '0001593387,GEOWEALTH MANAGEMENT, LLC',
    name: 'GEOWEALTH MANAGEMENT, LLC',
    label: 'GEOWEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001666736',
    value: '0001666736,GERBER KAWASAKI WEALTH & INVESTMENT MANAGEMENT',
    name: 'GERBER KAWASAKI WEALTH & INVESTMENT MANAGEMENT',
    label: 'GERBER KAWASAKI WEALTH & INVESTMENT MANAGEMENT',
  },
  {
    cik: '0000714395',
    value: '0000714395,GERMAN AMERICAN BANCORP, INC.',
    name: 'GERMAN AMERICAN BANCORP, INC.',
    label: 'GERMAN AMERICAN BANCORP, INC.',
  },
  {
    cik: '0001434767',
    value: '0001434767,GERRY FARBER, LLC',
    name: 'GERRY FARBER, LLC',
    label: 'GERRY FARBER, LLC',
  },
  {
    cik: '0001527198',
    value: '0001527198,GERSTEIN FISHER',
    name: 'GERSTEIN FISHER',
    label: 'GERSTEIN FISHER',
  },
  {
    cik: '0001638520',
    value: '0001638520,GFG CAPITAL, LLC',
    name: 'GFG CAPITAL, LLC',
    label: 'GFG CAPITAL, LLC',
  },
  {
    cik: '0001688774',
    value: '0001688774,GFI INVESTMENT COUNSEL LTD.',
    name: 'GFI INVESTMENT COUNSEL LTD.',
    label: 'GFI INVESTMENT COUNSEL LTD.',
  },
  {
    cik: '0001427263',
    value: '0001427263,GFS ADVISORS, LLC',
    name: 'GFS ADVISORS, LLC',
    label: 'GFS ADVISORS, LLC',
  },
  {
    cik: '0001728122',
    value: '0001728122,GFS PRIVATE WEALTH, LLC',
    name: 'GFS PRIVATE WEALTH, LLC',
    label: 'GFS PRIVATE WEALTH, LLC',
  },
  {
    cik: '0001527914',
    value: '0001527914,G.F.W. ENERGY VIII, L.P.',
    name: 'G.F.W. ENERGY VIII, L.P.',
    label: 'G.F.W. ENERGY VIII, L.P.',
  },
  {
    cik: '0001527913',
    value: '0001527913,G.F.W. ENERGY VII, L.P.',
    name: 'G.F.W. ENERGY VII, L.P.',
    label: 'G.F.W. ENERGY VII, L.P.',
  },
  {
    cik: '0001860989',
    value: '0001860989,G.F.W. ENERGY XII, L.P.',
    name: 'G.F.W. ENERGY XII, L.P.',
    label: 'G.F.W. ENERGY XII, L.P.',
  },
  {
    cik: '0001691944',
    value: '0001691944,G.F.W. ENERGY XI, L.P.',
    name: 'G.F.W. ENERGY XI, L.P.',
    label: 'G.F.W. ENERGY XI, L.P.',
  },
  {
    cik: '0001555496',
    value: '0001555496,G.F.W. ENERGY X, L.P.',
    name: 'G.F.W. ENERGY X, L.P.',
    label: 'G.F.W. ENERGY X, L.P.',
  },
  {
    cik: '0001829132',
    value: '0001829132,GGV CAPITAL LLC',
    name: 'GGV CAPITAL LLC',
    label: 'GGV CAPITAL LLC',
  },
  {
    cik: '0001910179',
    value: '0001910,GHE, LLC9',
    name: 'GHE, LLC',
    label: 'GHE, LLC',
  },
  {
    cik: '0001825214',
    value: '0001825214,GHISALLO CAPITAL MANAGEMENT LLC',
    name: 'GHISALLO CAPITAL MANAGEMENT LLC',
    label: 'GHISALLO CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001671186',
    value: '0001671186,GH OPPORTUNITIES GP II, LLC',
    name: 'GH OPPORTUNITIES GP II, LLC',
    label: 'GH OPPORTUNITIES GP II, LLC',
  },
  {
    cik: '0001595851',
    value: '0001595851,GHOST TREE CAPITAL, LLC',
    name: 'GHOST TREE CAPITAL, LLC',
    label: 'GHOST TREE CAPITAL, LLC',
  },
  {
    cik: '0001401561',
    value: '0001401561,GHP INVESTMENT ADVISORS, INC.',
    name: 'GHP INVESTMENT ADVISORS, INC.',
    label: 'GHP INVESTMENT ADVISORS, INC.',
  },
  {
    cik: '0001625986',
    value: '0001625986,GIBRALTAR CAPITAL MANAGEMENT, INC.',
    name: 'GIBRALTAR CAPITAL MANAGEMENT, INC.',
    label: 'GIBRALTAR CAPITAL MANAGEMENT, INC.',
  },
  {
    cik: '0001427372',
    value: '0001427372,GIBSON CAPITAL, LLC',
    name: 'GIBSON CAPITAL, LLC',
    label: 'GIBSON CAPITAL, LLC',
  },
  {
    cik: '0001911266',
    value: '0001911266,GIBSON WEALTH ADVISORS LLC',
    name: 'GIBSON WEALTH ADVISORS LLC',
    label: 'GIBSON WEALTH ADVISORS LLC',
  },
  {
    cik: '0001593921',
    value: '0001593921,GIDEON CAPITAL ADVISORS, INC.',
    name: 'GIDEON CAPITAL ADVISORS, INC.',
    label: 'GIDEON CAPITAL ADVISORS, INC.',
  },
  {
    cik: '0001607863',
    value: '0001607863,GIERL AUGUSTINE INVESTMENT MANAGEMENT INC',
    name: 'GIERL AUGUSTINE INVESTMENT MANAGEMENT INC',
    label: 'GIERL AUGUSTINE INVESTMENT MANAGEMENT INC',
  },
  {
    cik: '0001740316',
    value: '0001740316,GIFFORD FONG ASSOCIATES',
    name: 'GIFFORD FONG ASSOCIATES',
    label: 'GIFFORD FONG ASSOCIATES',
  },
  {
    cik: '0001675762',
    value: '0001675762,GILBERT & COOK, INC.',
    name: 'GILBERT & COOK, INC.',
    label: 'GILBERT & COOK, INC.',
  },
  {
    cik: '0000902464',
    value: '0000902464,GILDER GAGNON HOWE & CO LLC',
    name: 'GILDER GAGNON HOWE & CO LLC',
    label: 'GILDER GAGNON HOWE & CO LLC',
  },
  {
    cik: '0000729563',
    value: '0000729563,GILL CAPITAL PARTNERS, LLC',
    name: 'GILL CAPITAL PARTNERS, LLC',
    label: 'GILL CAPITAL PARTNERS, LLC',
  },
  {
    cik: '0000926834',
    value: '0000926834,GILLESPIE ROBINSON & GRIMM INC',
    name: 'GILLESPIE ROBINSON & GRIMM INC',
    label: 'GILLESPIE ROBINSON & GRIMM INC',
  },
  {
    cik: '0001698055',
    value: '0001698055,GILLSON CAPITAL LP',
    name: 'GILLSON CAPITAL LP',
    label: 'GILLSON CAPITAL LP',
  },
  {
    cik: '0001551969',
    value: '0001551969,GILMAN HILL ASSET MANAGEMENT, LLC',
    name: 'GILMAN HILL ASSET MANAGEMENT, LLC',
    label: 'GILMAN HILL ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001867040',
    value: '0001867040,GIMBEL DANIEL SCOTT',
    name: 'GIMBEL DANIEL SCOTT',
    label: 'GIMBEL DANIEL SCOTT',
  },
  {
    cik: '0001212225',
    value: '0001212225,GIOVINE CAPITAL GROUP LLC',
    name: 'GIOVINE CAPITAL GROUP LLC',
    label: 'GIOVINE CAPITAL GROUP LLC',
  },
  {
    cik: '0001571089',
    value: '0001571089,GIRALDA ADVISORS, LLC',
    name: 'GIRALDA ADVISORS, LLC',
    label: 'GIRALDA ADVISORS, LLC',
  },
  {
    cik: '0001000490',
    value: '0001000490,GIRARD PARTNERS LTD.',
    name: 'GIRARD PARTNERS LTD.',
    label: 'GIRARD PARTNERS LTD.',
  },
  {
    cik: '0000805867',
    value: '0000805867,GIRARD SECURITIES, INC.',
    name: 'GIRARD SECURITIES, INC.',
    label: 'GIRARD SECURITIES, INC.',
  },
  {
    cik: '0001861558',
    value: '0001861558,GITTERMAN WEALTH MANAGEMENT, LLC',
    name: 'GITTERMAN WEALTH MANAGEMENT, LLC',
    label: 'GITTERMAN WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001641864',
    value: '0001641864,GIVERNY CAPITAL INC.',
    name: 'GIVERNY CAPITAL INC.',
    label: 'GIVERNY CAPITAL INC.',
  },
  {
    cik: '0001567397',
    value: '0001567397,GLACIER PEAK CAPITAL LLC',
    name: 'GLACIER PEAK CAPITAL LLC',
    label: 'GLACIER PEAK CAPITAL LLC',
  },
  {
    cik: '0001529872',
    value: '0001529872,GLADE BROOK CAPITAL PARTNERS LLC',
    name: 'GLADE BROOK CAPITAL PARTNERS LLC',
    label: 'GLADE BROOK CAPITAL PARTNERS LLC',
  },
  {
    cik: '0001540656',
    value: '0001540656,GLADIUS CAPITAL MANAGEMENT LP',
    name: 'GLADIUS CAPITAL MANAGEMENT LP',
    label: 'GLADIUS CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001353394',
    value: '0001353394,GLADSTONE CAPITAL MANAGEMENT LLP',
    name: 'GLADSTONE CAPITAL MANAGEMENT LLP',
    label: 'GLADSTONE CAPITAL MANAGEMENT LLP',
  },
  {
    cik: '0001766530',
    value: '0001766530,GLADSTONE INSTITUTIONAL ADVISORY LLC',
    name: 'GLADSTONE INSTITUTIONAL ADVISORY LLC',
    label: 'GLADSTONE INSTITUTIONAL ADVISORY LLC',
  },
  {
    cik: '0001642160',
    value: '0001642160,GLASSMAN WEALTH SERVICES',
    name: 'GLASSMAN WEALTH SERVICES',
    label: 'GLASSMAN WEALTH SERVICES',
  },
  {
    cik: '0001914617',
    value: '0001914617,GLASSY MOUNTAIN ADVISORS, INC.',
    name: 'GLASSY MOUNTAIN ADVISORS, INC.',
    label: 'GLASSY MOUNTAIN ADVISORS, INC.',
  },
  {
    cik: '0001542161',
    value: '0001542161,GLAXIS CAPITAL MANAGEMENT, LLC',
    name: 'GLAXIS CAPITAL MANAGEMENT, LLC',
    label: 'GLAXIS CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001076809',
    value: '0001076809,GLAZER CAPITAL, LLC',
    name: 'GLAZER CAPITAL, LLC',
    label: 'GLAZER CAPITAL, LLC',
  },
  {
    cik: '0001822632',
    value: '0001822632,GLEASON GROUP, INC.',
    name: 'GLEASON GROUP, INC.',
    label: 'GLEASON GROUP, INC.',
  },
  {
    cik: '0001665883',
    value: '0001665883,GLEN CAPITAL PARTNERS LLC',
    name: 'GLEN CAPITAL PARTNERS LLC',
    label: 'GLEN CAPITAL PARTNERS LLC',
  },
  {
    cik: '0001604350',
    value: '0001604350,GLENDON CAPITAL MANAGEMENT LP',
    name: 'GLENDON CAPITAL MANAGEMENT LP',
    label: 'GLENDON CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001696417',
    value: '0001696417,GLEN HARBOR CAPITAL MANAGEMENT LLC',
    name: 'GLEN HARBOR CAPITAL MANAGEMENT LLC',
    label: 'GLEN HARBOR CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001137521',
    value: '0001137521,GLENHILL ADVISORS LLC',
    name: 'GLENHILL ADVISORS LLC',
    label: 'GLENHILL ADVISORS LLC',
  },
  {
    cik: '0000314949',
    value: '0000314949,GLENMEDE TRUST CO NA',
    name: 'GLENMEDE TRUST CO NA',
    label: 'GLENMEDE TRUST CO NA',
  },
  {
    cik: '0001674114',
    value: '0001674114,GLEN POINT CAPITAL LLP',
    name: 'GLEN POINT CAPITAL LLP',
    label: 'GLEN POINT CAPITAL LLP',
  },
  {
    cik: '0001481970',
    value: '0001481970,GLENROCK INC.',
    name: 'GLENROCK INC.',
    label: 'GLENROCK INC.',
  },
  {
    cik: '0001138995',
    value: '0001138995,GLENVIEW CAPITAL MANAGEMENT, LLC',
    name: 'GLENVIEW CAPITAL MANAGEMENT, LLC',
    label: 'GLENVIEW CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001598244',
    value: '0001598244,GLENVIEW STATE BANK TRUST DEPT.',
    name: 'GLENVIEW STATE BANK TRUST DEPT.',
    label: 'GLENVIEW STATE BANK TRUST DEPT.',
  },
  {
    cik: '0001691827',
    value: '0001691827,GLENVIEW TRUST CO',
    name: 'GLENVIEW TRUST CO',
    label: 'GLENVIEW TRUST CO',
  },
  {
    cik: '0001425915',
    value: '0001425,GLG INC.5',
    name: 'GLG INC.',
    label: 'GLG INC.',
  },
  {
    cik: '0001352261',
    value: '000135,GLG LLC61',
    name: 'GLG LLC',
    label: 'GLG LLC',
  },
  {
    cik: '0001289643',
    value: '0001289643,GLG PARTNERS LP',
    name: 'GLG PARTNERS LP',
    label: 'GLG PARTNERS LP',
  },
  {
    cik: '0001584691',
    value: '0001584691,GLOBAL ALPHA CAPITAL MANAGEMENT LTD.',
    name: 'GLOBAL ALPHA CAPITAL MANAGEMENT LTD.',
    label: 'GLOBAL ALPHA CAPITAL MANAGEMENT LTD.',
  },
  {
    cik: '0001942932',
    value: '0001942932,GLOBAL ASSETS ADVISORY, LLC',
    name: 'GLOBAL ASSETS ADVISORY, LLC',
    label: 'GLOBAL ASSETS ADVISORY, LLC',
  },
  {
    cik: '0001581833',
    value: '0001581833,GLOBAL ATLANTIC INVESTMENT ADVISORS, LLC',
    name: 'GLOBAL ATLANTIC INVESTMENT ADVISORS, LLC',
    label: 'GLOBAL ATLANTIC INVESTMENT ADVISORS, LLC',
  },
  {
    cik: '0001766789',
    value: '0001766789,GLOBAL BETA ADVISORS LLC',
    name: 'GLOBAL BETA ADVISORS LLC',
    label: 'GLOBAL BETA ADVISORS LLC',
  },
  {
    cik: '0001364630',
    value: '0001364630,GLOBALBRIDGE, INC.',
    name: 'GLOBALBRIDGE, INC.',
    label: 'GLOBALBRIDGE, INC.',
  },
  {
    cik: '0001512237',
    value: '0001512237,GLOBAL ENDOWMENT MANAGEMENT, LP',
    name: 'GLOBAL ENDOWMENT MANAGEMENT, LP',
    label: 'GLOBAL ENDOWMENT MANAGEMENT, LP',
  },
  {
    cik: '0001704642',
    value: '0001704642,GLOBAL FINANCIAL PRIVATE CAPITAL, INC',
    name: 'GLOBAL FINANCIAL PRIVATE CAPITAL, INC',
    label: 'GLOBAL FINANCIAL PRIVATE CAPITAL, INC',
  },
  {
    cik: '0001821219',
    value: '0001821219,GLOBAL FRONTIER INVESTMENTS LLC',
    name: 'GLOBAL FRONTIER INVESTMENTS LLC',
    label: 'GLOBAL FRONTIER INVESTMENTS LLC',
  },
  {
    cik: '0001614662',
    value: '0001614662,GLOBAL INDEX ADVISORS INC',
    name: 'GLOBAL INDEX ADVISORS INC',
    label: 'GLOBAL INDEX ADVISORS INC',
  },
  {
    cik: '0001843670',
    value: '0001843670,GLOBAL MEDIA & ENTERTAINMENT INVESTMENTS LTD',
    name: 'GLOBAL MEDIA & ENTERTAINMENT INVESTMENTS LTD',
    label: 'GLOBAL MEDIA & ENTERTAINMENT INVESTMENTS LTD',
  },
  {
    cik: '0001749744',
    value: '0001749744,GLOBAL RETIREMENT PARTNERS, LLC',
    name: 'GLOBAL RETIREMENT PARTNERS, LLC',
    label: 'GLOBAL RETIREMENT PARTNERS, LLC',
  },
  {
    cik: '0001760076',
    value: '0001760076,GLOBAL STRATEGIC INVESTMENT SOLUTIONS, LLC',
    name: 'GLOBAL STRATEGIC INVESTMENT SOLUTIONS, LLC',
    label: 'GLOBAL STRATEGIC INVESTMENT SOLUTIONS, LLC',
  },
  {
    cik: '0001413303',
    value: '0001413303,GLOBAL STRATEGIC MANAGEMENT INC',
    name: 'GLOBAL STRATEGIC MANAGEMENT INC',
    label: 'GLOBAL STRATEGIC MANAGEMENT INC',
  },
  {
    cik: '0001511381',
    value: '0001511381,GLOBAL THEMATIC PARTNERS, LLC',
    name: 'GLOBAL THEMATIC PARTNERS, LLC',
    label: 'GLOBAL THEMATIC PARTNERS, LLC',
  },
  {
    cik: '0001729672',
    value: '0001729672,GLOBAL TRUST ASSET MANAGEMENT, LLC',
    name: 'GLOBAL TRUST ASSET MANAGEMENT, LLC',
    label: 'GLOBAL TRUST ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001767855',
    value: '0001767855,GLOBAL WEALTH MANAGEMENT INVESTMENT ADVISORY, INC.',
    name: 'GLOBAL WEALTH MANAGEMENT INVESTMENT ADVISORY, INC.',
    label: 'GLOBAL WEALTH MANAGEMENT INVESTMENT ADVISORY, INC.',
  },
  {
    cik: '0001912297',
    value: '0001912297,GLOBAL WEALTH STRATEGIES & ASSOCIATES',
    name: 'GLOBAL WEALTH STRATEGIES & ASSOCIATES',
    label: 'GLOBAL WEALTH STRATEGIES & ASSOCIATES',
  },
  {
    cik: '0001432354',
    value: '0001432354,GLOBAL X MANAGEMENT CO LLC',
    name: 'GLOBAL X MANAGEMENT CO LLC',
    label: 'GLOBAL X MANAGEMENT CO LLC',
  },
  {
    cik: '0000921531',
    value: '0000921531,GLOBEFLEX CAPITAL L P',
    name: 'GLOBEFLEX CAPITAL L P',
    label: 'GLOBEFLEX CAPITAL L P',
  },
  {
    cik: '0000320335',
    value: '0000320335,GLOBE LIFE INC.',
    name: 'GLOBE LIFE INC.',
    label: 'GLOBE LIFE INC.',
  },
  {
    cik: '0001546283',
    value: '0001546283,GLOVISTA INVESTMENTS LLC',
    name: 'GLOVISTA INVESTMENTS LLC',
    label: 'GLOVISTA INVESTMENTS LLC',
  },
  {
    cik: '0000905790',
    value: '0000905790,GLUSKIN SHEFF & ASSOC INC',
    name: 'GLUSKIN SHEFF & ASSOC INC',
    label: 'GLUSKIN SHEFF & ASSOC INC',
  },
  {
    cik: '0000911270',
    value: '0000911270,GLYNN CAPITAL MANAGEMENT LLC',
    name: 'GLYNN CAPITAL MANAGEMENT LLC',
    label: 'GLYNN CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001598304',
    value: '0001598304,GM ADVISORY GROUP, INC.',
    name: 'GM ADVISORY GROUP, INC.',
    label: 'GM ADVISORY GROUP, INC.',
  },
  {
    cik: '0001106191',
    value: '0001106191,GMT CAPITAL CORP',
    name: 'GMT CAPITAL CORP',
    label: 'GMT CAPITAL CORP',
  },
  {
    cik: '0001895678',
    value: '0001895678,GOALFUSION WEALTH MANAGEMENT, LLC',
    name: 'GOALFUSION WEALTH MANAGEMENT, LLC',
    label: 'GOALFUSION WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001839307',
    value: '0001839307,GOALVEST ADVISORY LLC',
    name: 'GOALVEST ADVISORY LLC',
    label: 'GOALVEST ADVISORY LLC',
  },
  {
    cik: '0001698060',
    value: '0001698060,GOBI CAPITAL LLC',
    name: 'GOBI CAPITAL LLC',
    label: 'GOBI CAPITAL LLC',
  },
  {
    cik: '0000887402',
    value: '0000887402,GODSEY & GIBB ASSOCIATES',
    name: 'GODSEY & GIBB ASSOCIATES',
    label: 'GODSEY & GIBB ASSOCIATES',
  },
  {
    cik: '0000887402',
    value: '0000887402,GODSEY & GIBB, INC',
    name: 'GODSEY & GIBB, INC',
    label: 'GODSEY & GIBB, INC',
  },
  {
    cik: '0001631775',
    value: '0001631775,GODSHALK WELSH CAPITAL MANAGEMENT, INC.',
    name: 'GODSHALK WELSH CAPITAL MANAGEMENT, INC.',
    label: 'GODSHALK WELSH CAPITAL MANAGEMENT, INC.',
  },
  {
    cik: '0001550057',
    value: '0001550057,GOELZER INVESTMENT MANAGEMENT, INC.',
    name: 'GOELZER INVESTMENT MANAGEMENT, INC.',
    label: 'GOELZER INVESTMENT MANAGEMENT, INC.',
  },
  {
    cik: '0001844142',
    value: '0001844142,GOEPPER BURKHARDT LLC',
    name: 'GOEPPER BURKHARDT LLC',
    label: 'GOEPPER BURKHARDT LLC',
  },
  {
    cik: '0001738063',
    value: '0001738063,GO ETF SOLUTIONS LLP',
    name: 'GO ETF SOLUTIONS LLP',
    label: 'GO ETF SOLUTIONS LLP',
  },
  {
    cik: '0000315498',
    value: '0000315498,GOFEN & GLOSSBERG LLC /IL/',
    name: 'GOFEN & GLOSSBERG LLC /IL/',
    label: 'GOFEN & GLOSSBERG LLC /IL/',
  },
  {
    cik: '0001040463',
    value: '0001040463,GOFF JOHN C',
    name: 'GOFF JOHN C',
    label: 'GOFF JOHN C',
  },
  {
    cik: '0001837241',
    value: '0001837241,GOLD DRAGON WORLDWIDE ASSET MANAGEMENT LTD',
    name: 'GOLD DRAGON WORLDWIDE ASSET MANAGEMENT LTD',
    label: 'GOLD DRAGON WORLDWIDE ASSET MANAGEMENT LTD',
  },
  {
    cik: '0001548997',
    value: '0001548997,GOLDEN GATE PRIVATE EQUITY, INC.',
    name: 'GOLDEN GATE PRIVATE EQUITY, INC.',
    label: 'GOLDEN GATE PRIVATE EQUITY, INC.',
  },
  {
    cik: '0001839419',
    value: '0001839419,GOLDEN GREEN, INC.',
    name: 'GOLDEN GREEN, INC.',
    label: 'GOLDEN GREEN, INC.',
  },
  {
    cik: '0001845003',
    value: '0001845003,GOLDEN STATE EQUITY PARTNERS',
    name: 'GOLDEN STATE EQUITY PARTNERS',
    label: 'GOLDEN STATE EQUITY PARTNERS',
  },
  {
    cik: '0001776033',
    value: '0001776033,GOLDEN STATE WEALTH MANAGEMENT, LLC',
    name: 'GOLDEN STATE WEALTH MANAGEMENT, LLC',
    label: 'GOLDEN STATE WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001278951',
    value: '0001278951,GOLDENTREE ASSET MANAGEMENT LP',
    name: 'GOLDENTREE ASSET MANAGEMENT LP',
    label: 'GOLDENTREE ASSET MANAGEMENT LP',
  },
  {
    cik: '0001404740',
    value: '0001404740,GOLDFARB ANDREW P',
    name: 'GOLDFARB ANDREW P',
    label: 'GOLDFARB ANDREW P',
  },
  {
    cik: '0001277537',
    value: '0001277537,GOLDMAN CAPITAL MANAGEMENT INC',
    name: 'GOLDMAN CAPITAL MANAGEMENT INC',
    label: 'GOLDMAN CAPITAL MANAGEMENT INC',
  },
  {
    cik: '0000886982',
    value: '0000886982,GOLDMAN SACHS GROUP INC',
    name: 'GOLDMAN SACHS GROUP INC',
    label: 'GOLDMAN SACHS GROUP INC',
  },
  {
    cik: '0001570251',
    value: '0001570251,GOLDSTEIN MUNGER & ASSOCIATES',
    name: 'GOLDSTEIN MUNGER & ASSOCIATES',
    label: 'GOLDSTEIN MUNGER & ASSOCIATES',
  },
  {
    cik: '0001872787',
    value: '0001872787,GOLDSTREAM CAPITAL MANAGEMENT LTD',
    name: 'GOLDSTREAM CAPITAL MANAGEMENT LTD',
    label: 'GOLDSTREAM CAPITAL MANAGEMENT LTD',
  },
  {
    cik: '0001280043',
    value: '0001280043,GOLUB GROUP LLC',
    name: 'GOLUB GROUP LLC',
    label: 'GOLUB GROUP LLC',
  },
  {
    cik: '0001528086',
    value: '0001528086,GOOD HARBOR FINANCIAL, LLC',
    name: 'GOOD HARBOR FINANCIAL, LLC',
    label: 'GOOD HARBOR FINANCIAL, LLC',
  },
  {
    cik: '0001542300',
    value: '0001542300,GOODHAVEN CAPITAL MANAGEMENT, LLC',
    name: 'GOODHAVEN CAPITAL MANAGEMENT, LLC',
    label: 'GOODHAVEN CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001696260',
    value: '0001696260,GOOD LIFE ADVISORS, LLC',
    name: 'GOOD LIFE ADVISORS, LLC',
    label: 'GOOD LIFE ADVISORS, LLC',
  },
  {
    cik: '0001864123',
    value: '0001864123,GOODMAN ADVISORY GROUP, LLC',
    name: 'GOODMAN ADVISORY GROUP, LLC',
    label: 'GOODMAN ADVISORY GROUP, LLC',
  },
  {
    cik: '0001382303',
    value: '0001382303,GOODMAN FINANCIAL CORP',
    name: 'GOODMAN FINANCIAL CORP',
    label: 'GOODMAN FINANCIAL CORP',
  },
  {
    cik: '0001362255',
    value: '0001362255,GOODNOW INVESTMENT GROUP, LLC',
    name: 'GOODNOW INVESTMENT GROUP, LLC',
    label: 'GOODNOW INVESTMENT GROUP, LLC',
  },
  {
    cik: '0001119191',
    value: '0001119191,GOODWIN DANIEL L',
    name: 'GOODWIN DANIEL L',
    label: 'GOODWIN DANIEL L',
  },
  {
    cik: '0001803294',
    value: '0001803294,GOODWIN INVESTMENT ADVISORY',
    name: 'GOODWIN INVESTMENT ADVISORY',
    label: 'GOODWIN INVESTMENT ADVISORY',
  },
  {
    cik: '0001869164',
    value: '0001869164,GORDIAN CAPITAL SINGAPORE PTE LTD',
    name: 'GORDIAN CAPITAL SINGAPORE PTE LTD',
    label: 'GORDIAN CAPITAL SINGAPORE PTE LTD',
  },
  {
    cik: '0001428776',
    value: '0001428776,GORES GROUP, LLC',
    name: 'GORES GROUP, LLC',
    label: 'GORES GROUP, LLC',
  },
  {
    cik: '0001633516',
    value: '0001633516,GOSS WEALTH MANAGEMENT LLC',
    name: 'GOSS WEALTH MANAGEMENT LLC',
    label: 'GOSS WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0001510387',
    value: '0001510387,GOTHAM ASSET MANAGEMENT, LLC',
    name: 'GOTHAM ASSET MANAGEMENT, LLC',
    label: 'GOTHAM ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001091961',
    value: '0001091961,GOULD ASSET MANAGEMENT LLC /CA/',
    name: 'GOULD ASSET MANAGEMENT LLC /CA/',
    label: 'GOULD ASSET MANAGEMENT LLC /CA/',
  },
  {
    cik: '0001908965',
    value: '0001908965,GOULD CAPITAL, LLC',
    name: 'GOULD CAPITAL, LLC',
    label: 'GOULD CAPITAL, LLC',
  },
  {
    cik: '0001908828',
    value: '0001908828,GOUWS CAPITAL LLC',
    name: 'GOUWS CAPITAL LLC',
    label: 'GOUWS CAPITAL LLC',
  },
  {
    cik: '0001644187',
    value: '0001644187,GOVERNORS LANE LP',
    name: 'GOVERNORS LANE LP',
    label: 'GOVERNORS LANE LP',
  },
  {
    cik: '0001860928',
    value: '0001860928,GP BRINSON INVESTMENTS LLC',
    name: 'GP BRINSON INVESTMENTS LLC',
    label: 'GP BRINSON INVESTMENTS LLC',
  },
  {
    cik: '0001836021',
    value: '0001836021,GPI CAPITAL, L.P.',
    name: 'GPI CAPITAL, L.P.',
    label: 'GPI CAPITAL, L.P.',
  },
  {
    cik: '0001799367',
    value: '0001799367,GPM GROWTH INVESTORS, INC.',
    name: 'GPM GROWTH INVESTORS, INC.',
    label: 'GPM GROWTH INVESTORS, INC.',
  },
  {
    cik: '0001731837',
    value: '0001731837,GPS WEALTH STRATEGIES GROUP, LLC',
    name: 'GPS WEALTH STRATEGIES GROUP, LLC',
    label: 'GPS WEALTH STRATEGIES GROUP, LLC',
  },
  {
    cik: '0001905162',
    value: '0001905,GPWM LLC2',
    name: 'GPWM LLC',
    label: 'GPWM LLC',
  },
  {
    cik: '0001719814',
    value: '0001719814,GQ ASSET MANAGEMENT, LLC',
    name: 'GQ ASSET MANAGEMENT, LLC',
    label: 'GQ ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001697233',
    value: '0001697233,GQG PARTNERS LLC',
    name: 'GQG PARTNERS LLC',
    label: 'GQG PARTNERS LLC',
  },
  {
    cik: '0001218558',
    value: '0001218558,GRACE BROTHERS MANAGEMENT LLC',
    name: 'GRACE BROTHERS MANAGEMENT LLC',
    label: 'GRACE BROTHERS MANAGEMENT LLC',
  },
  {
    cik: '0001441646',
    value: '0001441646,GRACE CAPITAL',
    name: 'GRACE CAPITAL',
    label: 'GRACE CAPITAL',
  },
  {
    cik: '0000903064',
    value: '0000903064,GRACE & WHITE INC /NY',
    name: 'GRACE & WHITE INC /NY',
    label: 'GRACE & WHITE INC /NY',
  },
  {
    cik: '0001727917',
    value: '0001727917,GRADIENT CAPITAL ADVISORS, LLC',
    name: 'GRADIENT CAPITAL ADVISORS, LLC',
    label: 'GRADIENT CAPITAL ADVISORS, LLC',
  },
  {
    cik: '0001542265',
    value: '0001542265,GRADIENT INVESTMENTS LLC',
    name: 'GRADIENT INVESTMENTS LLC',
    label: 'GRADIENT INVESTMENTS LLC',
  },
  {
    cik: '0001315421',
    value: '0001315421,GRAHAM CAPITAL MANAGEMENT, L.P.',
    name: 'GRAHAM CAPITAL MANAGEMENT, L.P.',
    label: 'GRAHAM CAPITAL MANAGEMENT, L.P.',
  },
  {
    cik: '0001907528',
    value: '0001907528,GRAHAM CAPITAL WEALTH MANAGEMENT, LLC',
    name: 'GRAHAM CAPITAL WEALTH MANAGEMENT, LLC',
    label: 'GRAHAM CAPITAL WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001543167',
    value: '0001543167,GRAHAM & DODD FUND, LLC',
    name: 'GRAHAM & DODD FUND, LLC',
    label: 'GRAHAM & DODD FUND, LLC',
  },
  {
    cik: '0000104889',
    value: '0000104889,GRAHAM HOLDINGS CO',
    name: 'GRAHAM HOLDINGS CO',
    label: 'GRAHAM HOLDINGS CO',
  },
  {
    cik: '0001912699',
    value: '0001912699,GRAHAM HOLDINGS CO MASTER RETIREMENT TRUST',
    name: 'GRAHAM HOLDINGS CO MASTER RETIREMENT TRUST',
    label: 'GRAHAM HOLDINGS CO MASTER RETIREMENT TRUST',
  },
  {
    cik: '0001548836',
    value: '0001548836,GRAMERCY FUNDS MANAGEMENT LLC',
    name: 'GRAMERCY FUNDS MANAGEMENT LLC',
    label: 'GRAMERCY FUNDS MANAGEMENT LLC',
  },
  {
    cik: '0001026710',
    value: '0001026710,GRANAHAN INVESTMENT MANAGEMENT INC/MA',
    name: 'GRANAHAN INVESTMENT MANAGEMENT INC/MA',
    label: 'GRANAHAN INVESTMENT MANAGEMENT INC/MA',
  },
  {
    cik: '0001026710',
    value: '0001026710,GRANAHAN INVESTMENT MANAGEMENT, LLC',
    name: 'GRANAHAN INVESTMENT MANAGEMENT, LLC',
    label: 'GRANAHAN INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001857581',
    value: '0001857581,GRANBY CAPITAL MANAGEMENT, LLC',
    name: 'GRANBY CAPITAL MANAGEMENT, LLC',
    label: 'GRANBY CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001844893',
    value: '0001844893,GRAND CENTRAL INVESTMENT GROUP',
    name: 'GRAND CENTRAL INVESTMENT GROUP',
    label: 'GRAND CENTRAL INVESTMENT GROUP',
  },
  {
    cik: '0001569137',
    value: '0001569137,GRANDEUR PEAK GLOBAL ADVISORS, LLC',
    name: 'GRANDEUR PEAK GLOBAL ADVISORS, LLC',
    label: 'GRANDEUR PEAK GLOBAL ADVISORS, LLC',
  },
  {
    cik: '0001308016',
    value: '0001308016,GRANDFIELD & DODD, LLC',
    name: 'GRANDFIELD & DODD, LLC',
    label: 'GRANDFIELD & DODD, LLC',
  },
  {
    cik: '0001277158',
    value: '0001277158,GRAND JEAN CAPITAL MANAGEMENT INC',
    name: 'GRAND JEAN CAPITAL MANAGEMENT INC',
    label: 'GRAND JEAN CAPITAL MANAGEMENT INC',
  },
  {
    cik: '0001576528',
    value: '0001576528,GRANDMASTER CAPITAL MANAGEMENT LLC',
    name: 'GRANDMASTER CAPITAL MANAGEMENT LLC',
    label: 'GRANDMASTER CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001766791',
    value: '0001766791,GRANDVIEW ASSET MANAGEMENT LLC',
    name: 'GRANDVIEW ASSET MANAGEMENT LLC',
    label: 'GRANDVIEW ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001939443',
    value: '0001939443,GRAND WEALTH MANAGEMENT, LLC',
    name: 'GRAND WEALTH MANAGEMENT, LLC',
    label: 'GRAND WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001842881',
    value: '0001842881,GRANGER MANAGEMENT LLC',
    name: 'GRANGER MANAGEMENT LLC',
    label: 'GRANGER MANAGEMENT LLC',
  },
  {
    cik: '0001911472',
    value: '0001911472,GRANITE BAY WEALTH MANAGEMENT, LLC',
    name: 'GRANITE BAY WEALTH MANAGEMENT, LLC',
    label: 'GRANITE BAY WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001910881',
    value: '0001910881,GRANITE HILL INDIA PARTNERS, LLC',
    name: 'GRANITE HILL INDIA PARTNERS, LLC',
    label: 'GRANITE HILL INDIA PARTNERS, LLC',
  },
  {
    cik: '0001464586',
    value: '0001464586,GRANITE INVESTMENT ADVISORS, INC.',
    name: 'GRANITE INVESTMENT ADVISORS, INC.',
    label: 'GRANITE INVESTMENT ADVISORS, INC.',
  },
  {
    cik: '0001542266',
    value: '0001542266,GRANITE INVESTMENT PARTNERS, LLC',
    name: 'GRANITE INVESTMENT PARTNERS, LLC',
    label: 'GRANITE INVESTMENT PARTNERS, LLC',
  },
  {
    cik: '0001599562',
    value: '0001599562,GRANITE POINT CAPITAL MANAGEMENT, L.P.',
    name: 'GRANITE POINT CAPITAL MANAGEMENT, L.P.',
    label: 'GRANITE POINT CAPITAL MANAGEMENT, L.P.',
  },
  {
    cik: '0001839545',
    value: '0001839545,GRANITESHARES ADVISORS LLC',
    name: 'GRANITESHARES ADVISORS LLC',
    label: 'GRANITESHARES ADVISORS LLC',
  },
  {
    cik: '0001705655',
    value: '0001705655,GRANITE SPRINGS ASSET MANAGEMENT LLC',
    name: 'GRANITE SPRINGS ASSET MANAGEMENT LLC',
    label: 'GRANITE SPRINGS ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001810677',
    value: '0001810677,GRANT/GROSSMENDELSOHN, LLC',
    name: 'GRANT/GROSSMENDELSOHN, LLC',
    label: 'GRANT/GROSSMENDELSOHN, LLC',
  },
  {
    cik: '0001352662',
    value: '0001352662,GRANTHAM, MAYO, VAN OTTERLOO & CO. LLC',
    name: 'GRANTHAM, MAYO, VAN OTTERLOO & CO. LLC',
    label: 'GRANTHAM, MAYO, VAN OTTERLOO & CO. LLC',
  },
  {
    cik: '0001740642',
    value: '0001740642,GRANT STREET ASSET MANAGEMENT, INC.',
    name: 'GRANT STREET ASSET MANAGEMENT, INC.',
    label: 'GRANT STREET ASSET MANAGEMENT, INC.',
  },
  {
    cik: '0001608531',
    value: '0001608531,GRASSI INVESTMENT MANAGEMENT',
    name: 'GRASSI INVESTMENT MANAGEMENT',
    label: 'GRASSI INVESTMENT MANAGEMENT',
  },
  {
    cik: '0001135126',
    value: '0001135126,GRASSI INVESTMENT MANAGEMENT LLC',
    name: 'GRASSI INVESTMENT MANAGEMENT LLC',
    label: 'GRASSI INVESTMENT MANAGEMENT LLC',
  },
  {
    cik: '0001599793',
    value: '0001599793,GRATIA CAPITAL, LLC',
    name: 'GRATIA CAPITAL, LLC',
    label: 'GRATIA CAPITAL, LLC',
  },
  {
    cik: '0001619390',
    value: '0001619390,GRATICULE ASIA MACRO ADVISORS LLC',
    name: 'GRATICULE ASIA MACRO ADVISORS LLC',
    label: 'GRATICULE ASIA MACRO ADVISORS LLC',
  },
  {
    cik: '0001027799',
    value: '0001027799,GRATRY & CO LLC',
    name: 'GRATRY & CO LLC',
    label: 'GRATRY & CO LLC',
  },
  {
    cik: '0001652103',
    value: '0001652103,GRATUS CAPITAL LLC',
    name: 'GRATUS CAPITAL LLC',
    label: 'GRATUS CAPITAL LLC',
  },
  {
    cik: '0001759803',
    value: '0001759803,GRAVES LIGHT LENHART WEALTH, INC.',
    name: 'GRAVES LIGHT LENHART WEALTH, INC.',
    label: 'GRAVES LIGHT LENHART WEALTH, INC.',
  },
  {
    cik: '0001759803',
    value: '0001759803,GRAVES-LIGHT PRIVATE WEALTH MANAGEMENT, INC.',
    name: 'GRAVES-LIGHT PRIVATE WEALTH MANAGEMENT, INC.',
    label: 'GRAVES-LIGHT PRIVATE WEALTH MANAGEMENT, INC.',
  },
  {
    cik: '0001252007',
    value: '0001252007,GRAYBILL BARTZ & ASSOC LTD',
    name: 'GRAYBILL BARTZ & ASSOC LTD',
    label: 'GRAYBILL BARTZ & ASSOC LTD',
  },
  {
    cik: '0001070265',
    value: '0001070265,GRAY FINANCIAL GROUP /GA /ADV /ADV',
    name: 'GRAY FINANCIAL GROUP /GA /ADV /ADV',
    label: 'GRAY FINANCIAL GROUP /GA /ADV /ADV',
  },
  {
    cik: '0001684956',
    value: '0001684956,GRAYHAWK INVESTMENT STRATEGIES INC.',
    name: 'GRAYHAWK INVESTMENT STRATEGIES INC.',
    label: 'GRAYHAWK INVESTMENT STRATEGIES INC.',
  },
  {
    cik: '0001794198',
    value: '0001794198,GRAYPOINT LLC',
    name: 'GRAYPOINT LLC',
    label: 'GRAYPOINT LLC',
  },
  {
    cik: '0001958397',
    value: '0001958397,GRAY WEALTH MANAGEMENT INC.',
    name: 'GRAY WEALTH MANAGEMENT INC.',
    label: 'GRAY WEALTH MANAGEMENT INC.',
  },
  {
    cik: '0001801112',
    value: '0001801112,GREAT DIAMOND PARTNERS, LLC',
    name: 'GREAT DIAMOND PARTNERS, LLC',
    label: 'GREAT DIAMOND PARTNERS, LLC',
  },
  {
    cik: '0001231756',
    value: '0001231756,GREAT HILL PARTNERS LLC',
    name: 'GREAT HILL PARTNERS LLC',
    label: 'GREAT HILL PARTNERS LLC',
  },
  {
    cik: '0001231756',
    value: '0001231756,GREAT HILL PARTNERS, L.P.',
    name: 'GREAT HILL PARTNERS, L.P.',
    label: 'GREAT HILL PARTNERS, L.P.',
  },
  {
    cik: '0001078013',
    value: '0001078013,GREAT LAKES ADVISORS, LLC',
    name: 'GREAT LAKES ADVISORS, LLC',
    label: 'GREAT LAKES ADVISORS, LLC',
  },
  {
    cik: '0001801667',
    value: '0001801667,GREAT LAKES RETIREMENT, INC.',
    name: 'GREAT LAKES RETIREMENT, INC.',
    label: 'GREAT LAKES RETIREMENT, INC.',
  },
  {
    cik: '0001540944',
    value: '0001540944,GREATMARK INVESTMENT PARTNERS, INC.',
    name: 'GREATMARK INVESTMENT PARTNERS, INC.',
    label: 'GREATMARK INVESTMENT PARTNERS, INC.',
  },
  {
    cik: '0001815467',
    value: '0001815467,GREAT MOUNTAIN PARTNERS LLC',
    name: 'GREAT MOUNTAIN PARTNERS LLC',
    label: 'GREAT MOUNTAIN PARTNERS LLC',
  },
  {
    cik: '0001281446',
    value: '0001281446,GREAT POINT PARTNERS LLC',
    name: 'GREAT POINT PARTNERS LLC',
    label: 'GREAT POINT PARTNERS LLC',
  },
  {
    cik: '0001760304',
    value: '0001760304,GREAT POINT WEALTH ADVISORS, LLC',
    name: 'GREAT POINT WEALTH ADVISORS, LLC',
    label: 'GREAT POINT WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001769897',
    value: '0001769897,GREAT VALLEY ADVISOR GROUP, INC.',
    name: 'GREAT VALLEY ADVISOR GROUP, INC.',
    label: 'GREAT VALLEY ADVISOR GROUP, INC.',
  },
  {
    cik: '0001046192',
    value: '0001046192,GREAT WEST LIFE ASSURANCE CO /CAN/',
    name: 'GREAT WEST LIFE ASSURANCE CO /CAN/',
    label: 'GREAT WEST LIFE ASSURANCE CO /CAN/',
  },
  {
    cik: '0001439207',
    value: '0001439207,GREEN ALPHA ADVISORS, LLC',
    name: 'GREEN ALPHA ADVISORS, LLC',
    label: 'GREEN ALPHA ADVISORS, LLC',
  },
  {
    cik: '0001484068',
    value: '0001484068,GREEN ARROW CAPITAL MANAGEMENT LLC',
    name: 'GREEN ARROW CAPITAL MANAGEMENT LLC',
    label: 'GREEN ARROW CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001532262',
    value: '0001532262,GREENBRIER PARTNERS CAPITAL MANAGEMENT, LLC',
    name: 'GREENBRIER PARTNERS CAPITAL MANAGEMENT, LLC',
    label: 'GREENBRIER PARTNERS CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001731029',
    value: '0001731029,GREENCAPE CAPITAL PTY LTD',
    name: 'GREENCAPE CAPITAL PTY LTD',
    label: 'GREENCAPE CAPITAL PTY LTD',
  },
  {
    cik: '0001710785',
    value: '0001710785,GREEN COURT CAPITAL MANAGEMENT LTD',
    name: 'GREEN COURT CAPITAL MANAGEMENT LTD',
    label: 'GREEN COURT CAPITAL MANAGEMENT LTD',
  },
  {
    cik: '0001842727',
    value: '0001842727,GREENFIELD FTC, INC.',
    name: 'GREENFIELD FTC, INC.',
    label: 'GREENFIELD FTC, INC.',
  },
  {
    cik: '0001940406',
    value: '0001940406,GREENFIELD SAVINGS BANK',
    name: 'GREENFIELD SAVINGS BANK',
    label: 'GREENFIELD SAVINGS BANK',
  },
  {
    cik: '0001845793',
    value: '0001845793,GREEN HARVEST ASSET MANAGEMENT LLC',
    name: 'GREEN HARVEST ASSET MANAGEMENT LLC',
    label: 'GREEN HARVEST ASSET MANAGEMENT LLC',
  },
  {
    cik: '0000846222',
    value: '0000846222,GREENHAVEN ASSOCIATES INC',
    name: 'GREENHAVEN ASSOCIATES INC',
    label: 'GREENHAVEN ASSOCIATES INC',
  },
  {
    cik: '0001741129',
    value: '0001741129,GREENHAVEN ROAD INVESTMENT MANAGEMENT, L.P.',
    name: 'GREENHAVEN ROAD INVESTMENT MANAGEMENT, L.P.',
    label: 'GREENHAVEN ROAD INVESTMENT MANAGEMENT, L.P.',
  },
  {
    cik: '0001626220',
    value: '0001626220,GREENHOUSE FUNDS LLLP',
    name: 'GREENHOUSE FUNDS LLLP',
    label: 'GREENHOUSE FUNDS LLLP',
  },
  {
    cik: '0001843566',
    value: '0001843566,GREENHOUSE WEALTH MANAGEMENT, LLC',
    name: 'GREENHOUSE WEALTH MANAGEMENT, LLC',
    label: 'GREENHOUSE WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001162170',
    value: '0001162170,GREENLEAF TRUST',
    name: 'GREENLEAF TRUST',
    label: 'GREENLEAF TRUST',
  },
  {
    cik: '0001766504',
    value: '0001766504,GREENLEA LANE CAPITAL MANAGEMENT, LLC',
    name: 'GREENLEA LANE CAPITAL MANAGEMENT, LLC',
    label: 'GREENLEA LANE CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001413048',
    value: '0001413048,GREENLEA LANE CAPITAL PARTNERS LP',
    name: 'GREENLEA LANE CAPITAL PARTNERS LP',
    label: 'GREENLEA LANE CAPITAL PARTNERS LP',
  },
  {
    cik: '0001079114',
    value: '0001079114,GREENLIGHT CAPITAL INC',
    name: 'GREENLIGHT CAPITAL INC',
    label: 'GREENLIGHT CAPITAL INC',
  },
  {
    cik: '0001697323',
    value: '0001697323,GREENLINE PARTNERS, LLC',
    name: 'GREENLINE PARTNERS, LLC',
    label: 'GREENLINE PARTNERS, LLC',
  },
  {
    cik: '0001840735',
    value: '0001840735,GREENOAKS CAPITAL PARTNERS LLC',
    name: 'GREENOAKS CAPITAL PARTNERS LLC',
    label: 'GREENOAKS CAPITAL PARTNERS LLC',
  },
  {
    cik: '0001589248',
    value: '0001589248,GREEN OWL CAPITAL MANAGEMENT, LLC',
    name: 'GREEN OWL CAPITAL MANAGEMENT, LLC',
    label: 'GREEN OWL CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001922200',
    value: '0001922200,GREENSPRING ADVISORS, LLC',
    name: 'GREENSPRING ADVISORS, LLC',
    label: 'GREENSPRING ADVISORS, LLC',
  },
  {
    cik: '0001545140',
    value: '0001545140,GREENSPRING ASSOCIATES, INC.',
    name: 'GREENSPRING ASSOCIATES, INC.',
    label: 'GREENSPRING ASSOCIATES, INC.',
  },
  {
    cik: '0001796458',
    value: '0001796458,GREENSPRING ASSOCIATES, LLC',
    name: 'GREENSPRING ASSOCIATES, LLC',
    label: 'GREENSPRING ASSOCIATES, LLC',
  },
  {
    cik: '0001759271',
    value: '0001759271,GREEN SQUARE ASSET MANAGEMENT, LLC',
    name: 'GREEN SQUARE ASSET MANAGEMENT, LLC',
    label: 'GREEN SQUARE ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001166308',
    value: '0001166308,GREEN SQUARE CAPITAL ADVISORS LLC',
    name: 'GREEN SQUARE CAPITAL ADVISORS LLC',
    label: 'GREEN SQUARE CAPITAL ADVISORS LLC',
  },
  {
    cik: '0001845565',
    value: '0001845565,GREENSTONE PARTNERS & CO., LLC',
    name: 'GREENSTONE PARTNERS & CO., LLC',
    label: 'GREENSTONE PARTNERS & CO., LLC',
  },
  {
    cik: '0001391974',
    value: '0001391974,GREEN STREET INVESTORS, LLC',
    name: 'GREEN STREET INVESTORS, LLC',
    label: 'GREEN STREET INVESTORS, LLC',
  },
  {
    cik: '0001909904',
    value: '0001909904,GREENUP STREET WEALTH MANAGEMENT LLC',
    name: 'GREENUP STREET WEALTH MANAGEMENT LLC',
    label: 'GREENUP STREET WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0001768041',
    value: '0001768041,GREENVALE CAPITAL LLP',
    name: 'GREENVALE CAPITAL LLP',
    label: 'GREENVALE CAPITAL LLP',
  },
  {
    cik: '0001568338',
    value: '0001568338,GREEN VALLEY INVESTORS LLC',
    name: 'GREEN VALLEY INVESTORS LLC',
    label: 'GREEN VALLEY INVESTORS LLC',
  },
  {
    cik: '0001456511',
    value: '0001456511,GREENWICH INVESTMENT MANAGEMENT, INC.',
    name: 'GREENWICH INVESTMENT MANAGEMENT, INC.',
    label: 'GREENWICH INVESTMENT MANAGEMENT, INC.',
  },
  {
    cik: '0001439805',
    value: '0001439805,GREENWICH WEALTH MANAGEMENT LLC',
    name: 'GREENWICH WEALTH MANAGEMENT LLC',
    label: 'GREENWICH WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0001161822',
    value: '0001161822,GREENWOOD CAPITAL ASSOCIATES LLC',
    name: 'GREENWOOD CAPITAL ASSOCIATES LLC',
    label: 'GREENWOOD CAPITAL ASSOCIATES LLC',
  },
  {
    cik: '0001084683',
    value: '0001084683,GREENWOOD GEARHART INC',
    name: 'GREENWOOD GEARHART INC',
    label: 'GREENWOOD GEARHART INC',
  },
  {
    cik: '0001121943',
    value: '0001121943,GREENWOOD INVESTMENTS, INC.',
    name: 'GREENWOOD INVESTMENTS, INC.',
    label: 'GREENWOOD INVESTMENTS, INC.',
  },
  {
    cik: '0001848138',
    value: '0001848138,GREENWOODS ASSET MANAGEMENT HONG KONG LTD.',
    name: 'GREENWOODS ASSET MANAGEMENT HONG KONG LTD.',
    label: 'GREENWOODS ASSET MANAGEMENT HONG KONG LTD.',
  },
  {
    cik: '0001577133',
    value: '0001577133,GREENWOODS ASSET MANAGEMENT LTD',
    name: 'GREENWOODS ASSET MANAGEMENT LTD',
    label: 'GREENWOODS ASSET MANAGEMENT LTD',
  },
  {
    cik: '0001614775',
    value: '0001614775,GRESHAM PARTNERS, LLC',
    name: 'GRESHAM PARTNERS, LLC',
    label: 'GRESHAM PARTNERS, LLC',
  },
  {
    cik: '0001910274',
    value: '0001910274,GREYCROFT LP',
    name: 'GREYCROFT LP',
    label: 'GREYCROFT LP',
  },
  {
    cik: '0001908737',
    value: '0001908737,GREYHOUND CAPITAL MANAGEMENT LLP',
    name: 'GREYHOUND CAPITAL MANAGEMENT LLP',
    label: 'GREYHOUND CAPITAL MANAGEMENT LLP',
  },
  {
    cik: '0001158583',
    value: '0001158583,GREYLIN INVESTMENT MANAGEMENT, INC',
    name: 'GREYLIN INVESTMENT MANAGEMENT, INC',
    label: 'GREYLIN INVESTMENT MANAGEMENT, INC',
  },
  {
    cik: '0001158583',
    value: '0001158583,GREYLIN INVESTMENT MANGEMENT INC',
    name: 'GREYLIN INVESTMENT MANGEMENT INC',
    label: 'GREYLIN INVESTMENT MANGEMENT INC',
  },
  {
    cik: '0001877892',
    value: '0001877892,GREYLOCK 15 GP LLC',
    name: 'GREYLOCK 15 GP LLC',
    label: 'GREYLOCK 15 GP LLC',
  },
  {
    cik: '0001351699',
    value: '0001351699,GREYLOCK XI GP LIMITED PARTNERSHIP',
    name: 'GREYLOCK XI GP LIMITED PARTNERSHIP',
    label: 'GREYLOCK XI GP LIMITED PARTNERSHIP',
  },
  {
    cik: '0001413877',
    value: '0001413877,GREYLOCK XII GP LLC',
    name: 'GREYLOCK XII GP LLC',
    label: 'GREYLOCK XII GP LLC',
  },
  {
    cik: '0001541539',
    value: '0001541539,GREYLOCK XIII GP LLC',
    name: 'GREYLOCK XIII GP LLC',
    label: 'GREYLOCK XIII GP LLC',
  },
  {
    cik: '0001599375',
    value: '0001599375,GREY OWL CAPITAL MANAGEMENT, LLC',
    name: 'GREY OWL CAPITAL MANAGEMENT, LLC',
    label: 'GREY OWL CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001845302',
    value: '0001845302,GREYSTONE FINANCIAL GROUP, LLC',
    name: 'GREYSTONE FINANCIAL GROUP, LLC',
    label: 'GREYSTONE FINANCIAL GROUP, LLC',
  },
  {
    cik: '0001166741',
    value: '0001166741,GREYSTONE INVESTMENT MANAGEMENT LLC',
    name: 'GREYSTONE INVESTMENT MANAGEMENT LLC',
    label: 'GREYSTONE INVESTMENT MANAGEMENT LLC',
  },
  {
    cik: '0001477836',
    value: '0001477836,GREYSTONE MANAGED INVESTMENTS INC.',
    name: 'GREYSTONE MANAGED INVESTMENTS INC.',
    label: 'GREYSTONE MANAGED INVESTMENTS INC.',
  },
  {
    cik: '0001672829',
    value: '0001672829,GREYTOWN ADVISORS INC.',
    name: 'GREYTOWN ADVISORS INC.',
    label: 'GREYTOWN ADVISORS INC.',
  },
  {
    cik: '0001304096',
    value: '0001304096,GREYWOLF CAPITAL MANAGEMENT LP',
    name: 'GREYWOLF CAPITAL MANAGEMENT LP',
    label: 'GREYWOLF CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001049662',
    value: '0001049662,GRIES FINANCIAL LLC',
    name: 'GRIES FINANCIAL LLC',
    label: 'GRIES FINANCIAL LLC',
  },
  {
    cik: '0000883803',
    value: '0000883803,GRIFFIN ASSET MANAGEMENT, INC.',
    name: 'GRIFFIN ASSET MANAGEMENT, INC.',
    label: 'GRIFFIN ASSET MANAGEMENT, INC.',
  },
  {
    cik: '0001321993',
    value: '0001321993,GRIMES & COMPANY, INC.',
    name: 'GRIMES & COMPANY, INC.',
    label: 'GRIMES & COMPANY, INC.',
  },
  {
    cik: '0001105468',
    value: '0001105468,GRISANTI CAPITAL MANAGEMENT LLC',
    name: 'GRISANTI CAPITAL MANAGEMENT LLC',
    label: 'GRISANTI CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0000923116',
    value: '0000923116,GROESBECK INVESTMENT MANAGEMENT CORP /NJ/',
    name: 'GROESBECK INVESTMENT MANAGEMENT CORP /NJ/',
    label: 'GROESBECK INVESTMENT MANAGEMENT CORP /NJ/',
  },
  {
    cik: '0001403169',
    value: '0001403169,GROSVENOR CAPITAL MANAGEMENT, L.P.',
    name: 'GROSVENOR CAPITAL MANAGEMENT, L.P.',
    label: 'GROSVENOR CAPITAL MANAGEMENT, L.P.',
  },
  {
    cik: '0001743987',
    value: '0001743987,GROSVENOR HOLDINGS, L.L.C.',
    name: 'GROSVENOR HOLDINGS, L.L.C.',
    label: 'GROSVENOR HOLDINGS, L.L.C.',
  },
  {
    cik: '0001534450',
    value: '0001534450,GROUND SWELL CAPITAL, LLC',
    name: 'GROUND SWELL CAPITAL, LLC',
    label: 'GROUND SWELL CAPITAL, LLC',
  },
  {
    cik: '0000932540',
    value: '0000932540,GROUP ONE TRADING, L.P.',
    name: 'GROUP ONE TRADING, L.P.',
    label: 'GROUP ONE TRADING, L.P.',
  },
  {
    cik: '0001662212',
    value: '0001662212,GROVE BANK & TRUST',
    name: 'GROVE BANK & TRUST',
    label: 'GROVE BANK & TRUST',
  },
  {
    cik: '0001583114',
    value: '0001583114,GROW PARTNERS, LLC',
    name: 'GROW PARTNERS, LLC',
    label: 'GROW PARTNERS, LLC',
  },
  {
    cik: '0001728718',
    value: '0001728718,GROWTH INTERFACE MANAGEMENT LLC',
    name: 'GROWTH INTERFACE MANAGEMENT LLC',
    label: 'GROWTH INTERFACE MANAGEMENT LLC',
  },
  {
    cik: '0001544554',
    value: '0001544554,GRS ADVISORS, LLC',
    name: 'GRS ADVISORS, LLC',
    label: 'GRS ADVISORS, LLC',
  },
  {
    cik: '0001218583',
    value: '0001218583,GRT CAPITAL PARTNERS L.L.C.',
    name: 'GRT CAPITAL PARTNERS L.L.C.',
    label: 'GRT CAPITAL PARTNERS L.L.C.',
  },
  {
    cik: '0000909450',
    value: '0000909450,GRUBER & MCBAINE CAPITAL MANAGEMENT LLC',
    name: 'GRUBER & MCBAINE CAPITAL MANAGEMENT LLC',
    label: 'GRUBER & MCBAINE CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001585550',
    value: '0001585550,GRUBMAN WEALTH MANAGEMENT',
    name: 'GRUBMAN WEALTH MANAGEMENT',
    label: 'GRUBMAN WEALTH MANAGEMENT',
  },
  {
    cik: '0001134628',
    value: '0001134628,GRUSS CAPITAL MANAGEMENT LP',
    name: 'GRUSS CAPITAL MANAGEMENT LP',
    label: 'GRUSS CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001103738',
    value: '0001103738,GRUSS & CO INC',
    name: 'GRUSS & CO INC',
    label: 'GRUSS & CO INC',
  },
  {
    cik: '0001103738',
    value: '0001103738,GRUSS & CO., LLC',
    name: 'GRUSS & CO., LLC',
    label: 'GRUSS & CO., LLC',
  },
  {
    cik: '0001729094',
    value: '0001729094,GRYPHON FINANCIAL PARTNERS LLC',
    name: 'GRYPHON FINANCIAL PARTNERS LLC',
    label: 'GRYPHON FINANCIAL PARTNERS LLC',
  },
  {
    cik: '0001353326',
    value: '0001353326,GRYPHON INTERNATIONAL INVESTMENT CORP',
    name: 'GRYPHON INTERNATIONAL INVESTMENT CORP',
    label: 'GRYPHON INTERNATIONAL INVESTMENT CORP',
  },
  {
    cik: '0001362033',
    value: '0001362033,GSA CAPITAL PARTNERS LLP',
    name: 'GSA CAPITAL PARTNERS LLP',
    label: 'GSA CAPITAL PARTNERS LLP',
  },
  {
    cik: '0001536600',
    value: '0001536600,GSB PODIUM ADVISORS, LLC',
    name: 'GSB PODIUM ADVISORS, LLC',
    label: 'GSB PODIUM ADVISORS, LLC',
  },
  {
    cik: '0001660694',
    value: '0001660694,GSB WEALTH MANAGEMENT, LLC',
    name: 'GSB WEALTH MANAGEMENT, LLC',
    label: 'GSB WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001731448',
    value: '0001731448,G&S CAPITAL LLC',
    name: 'G&S CAPITAL LLC',
    label: 'G&S CAPITAL LLC',
  },
  {
    cik: '0001391974',
    value: '0001391974,GSI CAPITAL ADVISORS LLC',
    name: 'GSI CAPITAL ADVISORS LLC',
    label: 'GSI CAPITAL ADVISORS LLC',
  },
  {
    cik: '0001714590',
    value: '0001714590,GS INVESTMENTS, INC.',
    name: 'GS INVESTMENTS, INC.',
    label: 'GS INVESTMENTS, INC.',
  },
  {
    cik: '0001576350',
    value: '0001576,GTCR LLC0',
    name: 'GTCR LLC',
    label: 'GTCR LLC',
  },
  {
    cik: '0001732008',
    value: '0001732008,GUARDCAP ASSET MANAGEMENT LTD',
    name: 'GUARDCAP ASSET MANAGEMENT LTD',
    label: 'GUARDCAP ASSET MANAGEMENT LTD',
  },
  {
    cik: '0001512652',
    value: '0001512652,GUARDIAN CAPITAL ADVISORS LP',
    name: 'GUARDIAN CAPITAL ADVISORS LP',
    label: 'GUARDIAN CAPITAL ADVISORS LP',
  },
  {
    cik: '0001224324',
    value: '0001224324,GUARDIAN CAPITAL LP',
    name: 'GUARDIAN CAPITAL LP',
    label: 'GUARDIAN CAPITAL LP',
  },
  {
    cik: '0001723514',
    value: '0001723514,GUARDIAN FINANCIAL PARTNERS, LLC',
    name: 'GUARDIAN FINANCIAL PARTNERS, LLC',
    label: 'GUARDIAN FINANCIAL PARTNERS, LLC',
  },
  {
    cik: '0001365474',
    value: '0001365474,GUARDIAN INVESTMENT MANAGEMENT',
    name: 'GUARDIAN INVESTMENT MANAGEMENT',
    label: 'GUARDIAN INVESTMENT MANAGEMENT',
  },
  {
    cik: '0000728572',
    value: '0000728572,GUARDIAN INVESTOR SERVICES LLC',
    name: 'GUARDIAN INVESTOR SERVICES LLC',
    label: 'GUARDIAN INVESTOR SERVICES LLC',
  },
  {
    cik: '0000901849',
    value: '0000901849,GUARDIAN LIFE INSURANCE CO OF AMERICA',
    name: 'GUARDIAN LIFE INSURANCE CO OF AMERICA',
    label: 'GUARDIAN LIFE INSURANCE CO OF AMERICA',
  },
  {
    cik: '0001595932',
    value: '0001595932,GUARDIAN POINT CAPITAL, LP',
    name: 'GUARDIAN POINT CAPITAL, LP',
    label: 'GUARDIAN POINT CAPITAL, LP',
  },
  {
    cik: '0001858828',
    value: '0001858828,GUARDIAN WEALTH ADVISORS, LLC',
    name: 'GUARDIAN WEALTH ADVISORS, LLC',
    label: 'GUARDIAN WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001901403',
    value: '0001901403,GUARDIAN WEALTH ADVISORS, LLC / NC',
    name: 'GUARDIAN WEALTH ADVISORS, LLC / NC',
    label: 'GUARDIAN WEALTH ADVISORS, LLC / NC',
  },
  {
    cik: '0001910205',
    value: '0001910205,GUARDIAN WEALTH MANAGEMENT, INC.',
    name: 'GUARDIAN WEALTH MANAGEMENT, INC.',
    label: 'GUARDIAN WEALTH MANAGEMENT, INC.',
  },
  {
    cik: '0001910386',
    value: '0001910386,GUERRA PAN ADVISORS, LLC',
    name: 'GUERRA PAN ADVISORS, LLC',
    label: 'GUERRA PAN ADVISORS, LLC',
  },
  {
    cik: '0001283072',
    value: '0001283072,GUGGENHEIM CAPITAL LLC',
    name: 'GUGGENHEIM CAPITAL LLC',
    label: 'GUGGENHEIM CAPITAL LLC',
  },
  {
    cik: '0001767710',
    value: '0001767710,GUIDANCE POINT ADVISORS, LLC',
    name: 'GUIDANCE POINT ADVISORS, LLC',
    label: 'GUIDANCE POINT ADVISORS, LLC',
  },
  {
    cik: '0001689024',
    value: '0001689024,GUIDANT WEALTH ADVISORS',
    name: 'GUIDANT WEALTH ADVISORS',
    label: 'GUIDANT WEALTH ADVISORS',
  },
  {
    cik: '0001308668',
    value: '0001308668,GUIDESTONE CAPITAL MANAGEMENT, LLC',
    name: 'GUIDESTONE CAPITAL MANAGEMENT, LLC',
    label: 'GUIDESTONE CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001317802',
    value: '0001317802,GUILD INVESTMENT MANAGEMENT, INC.',
    name: 'GUILD INVESTMENT MANAGEMENT, INC.',
    label: 'GUILD INVESTMENT MANAGEMENT, INC.',
  },
  {
    cik: '0001409661',
    value: '0001409661,GUINNESS ASSET MANAGEMENT LTD',
    name: 'GUINNESS ASSET MANAGEMENT LTD',
    label: 'GUINNESS ASSET MANAGEMENT LTD',
  },
  {
    cik: '0001409765',
    value: '0001409765,GUINNESS ATKINSON ASSET MANAGEMENT INC',
    name: 'GUINNESS ATKINSON ASSET MANAGEMENT INC',
    label: 'GUINNESS ATKINSON ASSET MANAGEMENT INC',
  },
  {
    cik: '0001598697',
    value: '0001598697,GULF INTERNATIONAL BANK (UK) LTD',
    name: 'GULF INTERNATIONAL BANK (UK) LTD',
    label: 'GULF INTERNATIONAL BANK (UK) LTD',
  },
  {
    cik: '0001792397',
    value: '0001792397,GUNDERSON CAPITAL MANAGEMENT INC.',
    name: 'GUNDERSON CAPITAL MANAGEMENT INC.',
    label: 'GUNDERSON CAPITAL MANAGEMENT INC.',
  },
  {
    cik: '0001549449',
    value: '0001549449,GUNMA BANK,LTD.',
    name: 'GUNMA BANK,LTD.',
    label: 'GUNMA BANK,LTD.',
  },
  {
    cik: '0001639966',
    value: '0001639966,GUPTA WEALTH MANAGEMENT LLC',
    name: 'GUPTA WEALTH MANAGEMENT LLC',
    label: 'GUPTA WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0000928568',
    value: '0000928568,GUYASUTA INVESTMENT ADVISORS INC',
    name: 'GUYASUTA INVESTMENT ADVISORS INC',
    label: 'GUYASUTA INVESTMENT ADVISORS INC',
  },
  {
    cik: '0001493936',
    value: '0001493936,GV CAPITAL MANAGEMENT',
    name: 'GV CAPITAL MANAGEMENT',
    label: 'GV CAPITAL MANAGEMENT',
  },
  {
    cik: '0001616168',
    value: '0001616168,GVO ASSET MANAGEMENT LTD',
    name: 'GVO ASSET MANAGEMENT LTD',
    label: 'GVO ASSET MANAGEMENT LTD',
  },
  {
    cik: '0000867262',
    value: '0000867262,GW CAPITAL INC',
    name: 'GW CAPITAL INC',
    label: 'GW CAPITAL INC',
  },
  {
    cik: '0001080369',
    value: '0001080369,GW HENSSLER & ASSOCIATES LTD',
    name: 'GW HENSSLER & ASSOCIATES LTD',
    label: 'GW HENSSLER & ASSOCIATES LTD',
  },
  {
    cik: '0001082917',
    value: '0001082917,GW&K INVESTMENT MANAGEMENT, LLC',
    name: 'GW&K INVESTMENT MANAGEMENT, LLC',
    label: 'GW&K INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001633516',
    value: '0001633516,GWM ADVISORS LLC',
    name: 'GWM ADVISORS LLC',
    label: 'GWM ADVISORS LLC',
  },
  {
    cik: '0001821462',
    value: '0001821462,GWM ASSET MANAGEMENT LTD',
    name: 'GWM ASSET MANAGEMENT LTD',
    label: 'GWM ASSET MANAGEMENT LTD',
  },
  {
    cik: '0001767715',
    value: '0001767715,GYL FINANCIAL SYNERGIES, LLC',
    name: 'GYL FINANCIAL SYNERGIES, LLC',
    label: 'GYL FINANCIAL SYNERGIES, LLC',
  },
  {
    cik: '0001790782',
    value: '0001790782,GYON TECHNOLOGIES CAPITAL MANAGEMENT, LP',
    name: 'GYON TECHNOLOGIES CAPITAL MANAGEMENT, LP',
    label: 'GYON TECHNOLOGIES CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0001624729',
    value: '0001624729,GYROSCOPE CAPITAL MANAGEMENT GROUP, LLC',
    name: 'GYROSCOPE CAPITAL MANAGEMENT GROUP, LLC',
    label: 'GYROSCOPE CAPITAL MANAGEMENT GROUP, LLC',
  },
  {
    cik: '0001479228',
    value: '0001479228,GYRUS INVESTMENT MANAGEMENT INC.',
    name: 'GYRUS INVESTMENT MANAGEMENT INC.',
    label: 'GYRUS INVESTMENT MANAGEMENT INC.',
  },
  {
    cik: '0001813784',
    value: '0001813784,H/2 CREDIT MANAGER LP',
    name: 'H/2 CREDIT MANAGER LP',
    label: 'H/2 CREDIT MANAGER LP',
  },
  {
    cik: '0001767616',
    value: '000176761,H2O AM LLP',
    name: 'H2O AM LLP',
    label: 'H2O AM LLP',
  },
  {
    cik: '0001697943',
    value: '0001697943,H7 HOLDINGS, LLC',
    name: 'H7 HOLDINGS, LLC',
    label: 'H7 HOLDINGS, LLC',
  },
  {
    cik: '0001714964',
    value: '0001714964,HAAFOR (SINGAPORE) PTE. LTD.',
    name: 'HAAFOR (SINGAPORE) PTE. LTD.',
    label: 'HAAFOR (SINGAPORE) PTE. LTD.',
  },
  {
    cik: '0001116943',
    value: '0001116943,HABERER REGISTERED INVESTMENT ADVISOR INC',
    name: 'HABERER REGISTERED INVESTMENT ADVISOR INC',
    label: 'HABERER REGISTERED INVESTMENT ADVISOR INC',
  },
  {
    cik: '0001315712',
    value: '0001315712,HABROK CAPITAL MANAGEMENT LLP',
    name: 'HABROK CAPITAL MANAGEMENT LLP',
    label: 'HABROK CAPITAL MANAGEMENT LLP',
  },
  {
    cik: '0001851813',
    value: '0001851813,HACKENSACK MERIDIAN HEALTH, INC.',
    name: 'HACKENSACK MERIDIAN HEALTH, INC.',
    label: 'HACKENSACK MERIDIAN HEALTH, INC.',
  },
  {
    cik: '0001171592',
    value: '0001171592,HAHN CAPITAL MANAGEMENT LLC',
    name: 'HAHN CAPITAL MANAGEMENT LLC',
    label: 'HAHN CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001484205',
    value: '0001484205,HALBERT HARGROVE GLOBAL ADVISORS, LLC',
    name: 'HALBERT HARGROVE GLOBAL ADVISORS, LLC',
    label: 'HALBERT HARGROVE GLOBAL ADVISORS, LLC',
  },
  {
    cik: '0001007024',
    value: '0001007024,HALCYON ASSET MANAGEMENT LLC',
    name: 'HALCYON ASSET MANAGEMENT LLC',
    label: 'HALCYON ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001616416',
    value: '0001616416,HALCYON MANAGEMENT PARTNERS LP',
    name: 'HALCYON MANAGEMENT PARTNERS LP',
    label: 'HALCYON MANAGEMENT PARTNERS LP',
  },
  {
    cik: '0001086548',
    value: '0001086548,HALCYON OFFSHORE ASSET MANAGEMENT LLC',
    name: 'HALCYON OFFSHORE ASSET MANAGEMENT LLC',
    label: 'HALCYON OFFSHORE ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001761380',
    value: '0001761380,HALF SKY CAPITAL (UK) LTD',
    name: 'HALF SKY CAPITAL (UK) LTD',
    label: 'HALF SKY CAPITAL (UK) LTD',
  },
  {
    cik: '0001122490',
    value: '0001122490,HALL CAPITAL MANAGEMENT CO INC',
    name: 'HALL CAPITAL MANAGEMENT CO INC',
    label: 'HALL CAPITAL MANAGEMENT CO INC',
  },
  {
    cik: '0001512944',
    value: '0001512944,HALL KATHRYN A.',
    name: 'HALL KATHRYN A.',
    label: 'HALL KATHRYN A.',
  },
  {
    cik: '0001388382',
    value: '0001388382,HALL LAURIE J TRUSTEE',
    name: 'HALL LAURIE J TRUSTEE',
    label: 'HALL LAURIE J TRUSTEE',
  },
  {
    cik: '0000924166',
    value: '0000924166,HALLMARK CAPITAL MANAGEMENT INC',
    name: 'HALLMARK CAPITAL MANAGEMENT INC',
    label: 'HALLMARK CAPITAL MANAGEMENT INC',
  },
  {
    cik: '0001744091',
    value: '0001744091,HALL PRIVATE WEALTH ADVISORS',
    name: 'HALL PRIVATE WEALTH ADVISORS',
    label: 'HALL PRIVATE WEALTH ADVISORS',
  },
  {
    cik: '0001034188',
    value: '0001034188,HALSEY ASSOCIATES INC/CT',
    name: 'HALSEY ASSOCIATES INC/CT',
    label: 'HALSEY ASSOCIATES INC/CT',
  },
  {
    cik: '0001599760',
    value: '0001599760,HAMEL ASSOCIATES, INC.',
    name: 'HAMEL ASSOCIATES, INC.',
    label: 'HAMEL ASSOCIATES, INC.',
  },
  {
    cik: '0001646639',
    value: '0001646639,HAMILTON CAPITAL, LLC',
    name: 'HAMILTON CAPITAL, LLC',
    label: 'HAMILTON CAPITAL, LLC',
  },
  {
    cik: '0001646639',
    value: '0001646639,HAMILTON CAPITAL MANAGEMENT, INC.',
    name: 'HAMILTON CAPITAL MANAGEMENT, INC.',
    label: 'HAMILTON CAPITAL MANAGEMENT, INC.',
  },
  {
    cik: '0001573767',
    value: '0001573767,HAMILTON LANE ADVISORS LLC',
    name: 'HAMILTON LANE ADVISORS LLC',
    label: 'HAMILTON LANE ADVISORS LLC',
  },
  {
    cik: '0001599746',
    value: '0001599746,HAMILTON POINT INVESTMENT ADVISORS, LLC',
    name: 'HAMILTON POINT INVESTMENT ADVISORS, LLC',
    label: 'HAMILTON POINT INVESTMENT ADVISORS, LLC',
  },
  {
    cik: '0001760263',
    value: '0001760263,HAMILTON WEALTH, LLC',
    name: 'HAMILTON WEALTH, LLC',
    label: 'HAMILTON WEALTH, LLC',
  },
  {
    cik: '0001277403',
    value: '0001277403,HAMLIN CAPITAL MANAGEMENT, LLC',
    name: 'HAMLIN CAPITAL MANAGEMENT, LLC',
    label: 'HAMLIN CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001749890',
    value: '0001749890,HAMMER ASSET MANAGEMENT, LLC',
    name: 'HAMMER ASSET MANAGEMENT, LLC',
    label: 'HAMMER ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001512414',
    value: '0001512414,HAMPSHIRE TRUST LLC',
    name: 'HAMPSHIRE TRUST LLC',
    label: 'HAMPSHIRE TRUST LLC',
  },
  {
    cik: '0001697742',
    value: '0001697742,HAMPSTEAD CAPITAL LLP',
    name: 'HAMPSTEAD CAPITAL LLP',
    label: 'HAMPSTEAD CAPITAL LLP',
  },
  {
    cik: '0001834438',
    value: '0001834438,HAMPTON ROAD CAPITAL MANAGEMENT LP',
    name: 'HAMPTON ROAD CAPITAL MANAGEMENT LP',
    label: 'HAMPTON ROAD CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0000750577',
    value: '0000750577,HANCOCK HOLDING CO',
    name: 'HANCOCK HOLDING CO',
    label: 'HANCOCK HOLDING CO',
  },
  {
    cik: '0000750577',
    value: '0000750577,HANCOCK WHITNEY CORP',
    name: 'HANCOCK WHITNEY CORP',
    label: 'HANCOCK WHITNEY CORP',
  },
  {
    cik: '0001009952',
    value: '0001009952,HANCOCK WHITNEY INVESTMENT SERVICES INC.',
    name: 'HANCOCK WHITNEY INVESTMENT SERVICES INC.',
    label: 'HANCOCK WHITNEY INVESTMENT SERVICES INC.',
  },
  {
    cik: '0001398318',
    value: '0001398318,HANDELSBANKEN FONDER AB',
    name: 'HANDELSBANKEN FONDER AB',
    label: 'HANDELSBANKEN FONDER AB',
  },
  {
    cik: '0001659997',
    value: '0001659997,HANDELSINVEST INVESTERINGSFORVALTNING',
    name: 'HANDELSINVEST INVESTERINGSFORVALTNING',
    label: 'HANDELSINVEST INVESTERINGSFORVALTNING',
  },
  {
    cik: '0001511739',
    value: '0001511739,HANLON INVESTMENT MANAGEMENT, INC.',
    name: 'HANLON INVESTMENT MANAGEMENT, INC.',
    label: 'HANLON INVESTMENT MANAGEMENT, INC.',
  },
  {
    cik: '0001044910',
    value: '0001044910,HANSBERGER GLOBAL INVESTORS INC/FL',
    name: 'HANSBERGER GLOBAL INVESTORS INC/FL',
    label: 'HANSBERGER GLOBAL INVESTORS INC/FL',
  },
  {
    cik: '0001619724',
    value: '0001619724,HANSBERGER GROWTH INVESTORS, LP',
    name: 'HANSBERGER GROWTH INVESTORS, LP',
    label: 'HANSBERGER GROWTH INVESTORS, LP',
  },
  {
    cik: '0001128074',
    value: '0001128074,HANSEATIC MANAGEMENT SERVICES INC',
    name: 'HANSEATIC MANAGEMENT SERVICES INC',
    label: 'HANSEATIC MANAGEMENT SERVICES INC',
  },
  {
    cik: '0001393944',
    value: '0001393944,HANSON & DOREMUS INVESTMENT MANAGEMENT',
    name: 'HANSON & DOREMUS INVESTMENT MANAGEMENT',
    label: 'HANSON & DOREMUS INVESTMENT MANAGEMENT',
  },
  {
    cik: '0001555170',
    value: '0001555170,HANSON MCCLAIN, INC.',
    name: 'HANSON MCCLAIN, INC.',
    label: 'HANSON MCCLAIN, INC.',
  },
  {
    cik: '0001071061',
    value: '0001071061,HANTZ FINANCIAL SERVICES, INC.',
    name: 'HANTZ FINANCIAL SERVICES, INC.',
    label: 'HANTZ FINANCIAL SERVICES, INC.',
  },
  {
    cik: '0001695959',
    value: '0001695959,HAPANOWICZ & ASSOCIATES FINANCIAL SERVICES, INC',
    name: 'HAPANOWICZ & ASSOCIATES FINANCIAL SERVICES, INC',
    label: 'HAPANOWICZ & ASSOCIATES FINANCIAL SERVICES, INC',
  },
  {
    cik: '0001484972',
    value: '0001484972,HAP TRADING, LLC',
    name: 'HAP TRADING, LLC',
    label: 'HAP TRADING, LLC',
  },
  {
    cik: '0001387921',
    value: '0001387921,HARBER ASSET MANAGEMENT LLC',
    name: 'HARBER ASSET MANAGEMENT LLC',
    label: 'HARBER ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001616659',
    value: '0001616659,HARBERT FUND ADVISORS, INC.',
    name: 'HARBERT FUND ADVISORS, INC.',
    label: 'HARBERT FUND ADVISORS, INC.',
  },
  {
    cik: '0001259933',
    value: '0001259933,HARBERT MANAGEMENT CORP',
    name: 'HARBERT MANAGEMENT CORP',
    label: 'HARBERT MANAGEMENT CORP',
  },
  {
    cik: '0001466495',
    value: '0001466495,HARBINGER CAPITAL PARTNERS II GP LLC',
    name: 'HARBINGER CAPITAL PARTNERS II GP LLC',
    label: 'HARBINGER CAPITAL PARTNERS II GP LLC',
  },
  {
    cik: '0001456927',
    value: '0001456927,HARBINGER HOLDINGS, LLC',
    name: 'HARBINGER HOLDINGS, LLC',
    label: 'HARBINGER HOLDINGS, LLC',
  },
  {
    cik: '0001687156',
    value: '0001687156,HARBOR ADVISORS LLC',
    name: 'HARBOR ADVISORS LLC',
    label: 'HARBOR ADVISORS LLC',
  },
  {
    cik: '0001100710',
    value: '0001100710,HARBOR ADVISORY CORP /MA/',
    name: 'HARBOR ADVISORY CORP /MA/',
    label: 'HARBOR ADVISORY CORP /MA/',
  },
  {
    cik: '0001039128',
    value: '0001039128,HARBOR CAPITAL ADVISORS, INC.',
    name: 'HARBOR CAPITAL ADVISORS, INC.',
    label: 'HARBOR CAPITAL ADVISORS, INC.',
  },
  {
    cik: '0000857602',
    value: '0000857602,HARBOR FINANCIAL SERVICES, LLC',
    name: 'HARBOR FINANCIAL SERVICES, LLC',
    label: 'HARBOR FINANCIAL SERVICES, LLC',
  },
  {
    cik: '0001840486',
    value: '0001840486,HARBOR GROUP, INC.',
    name: 'HARBOR GROUP, INC.',
    label: 'HARBOR GROUP, INC.',
  },
  {
    cik: '0001468792',
    value: '0001468792,HARBOR INVESTMENT ADVISORY, LLC',
    name: 'HARBOR INVESTMENT ADVISORY, LLC',
    label: 'HARBOR INVESTMENT ADVISORY, LLC',
  },
  {
    cik: '0001730580',
    value: '0001730580,HARBOR ISLAND CAPITAL LLC',
    name: 'HARBOR ISLAND CAPITAL LLC',
    label: 'HARBOR ISLAND CAPITAL LLC',
  },
  {
    cik: '0001635409',
    value: '0001635409,HARBOR SPRING CAPITAL, LLC',
    name: 'HARBOR SPRING CAPITAL, LLC',
    label: 'HARBOR SPRING CAPITAL, LLC',
  },
  {
    cik: '0001730890',
    value: '0001730890,HARBORVIEW ADVISORS, LLC',
    name: 'HARBORVIEW ADVISORS, LLC',
    label: 'HARBORVIEW ADVISORS, LLC',
  },
  {
    cik: '0001682501',
    value: '0001682501,HARBOUR CAPITAL ADVISORS, LLC',
    name: 'HARBOUR CAPITAL ADVISORS, LLC',
    label: 'HARBOUR CAPITAL ADVISORS, LLC',
  },
  {
    cik: '0001275880',
    value: '0001275880,HARBOUR INVESTMENT MANAGEMENT LLC',
    name: 'HARBOUR INVESTMENT MANAGEMENT LLC',
    label: 'HARBOUR INVESTMENT MANAGEMENT LLC',
  },
  {
    cik: '0000810121',
    value: '0000810121,HARBOUR INVESTMENTS, INC.',
    name: 'HARBOUR INVESTMENTS, INC.',
    label: 'HARBOUR INVESTMENTS, INC.',
  },
  {
    cik: '0001201001',
    value: '0001201001,HARBOURVEST PARTNERS LLC',
    name: 'HARBOURVEST PARTNERS LLC',
    label: 'HARBOURVEST PARTNERS LLC',
  },
  {
    cik: '0001055963',
    value: '0001055963,HARDESTY CAPITAL MANAGEMENT CORP',
    name: 'HARDESTY CAPITAL MANAGEMENT CORP',
    label: 'HARDESTY CAPITAL MANAGEMENT CORP',
  },
  {
    cik: '0000928196',
    value: '0000928196,HARDING LOEVNER LP',
    name: 'HARDING LOEVNER LP',
    label: 'HARDING LOEVNER LP',
  },
  {
    cik: '0001006364',
    value: '0001006364,HARDMAN JOHNSTON GLOBAL ADVISORS LLC',
    name: 'HARDMAN JOHNSTON GLOBAL ADVISORS LLC',
    label: 'HARDMAN JOHNSTON GLOBAL ADVISORS LLC',
  },
  {
    cik: '0001761871',
    value: '0001761871,HARDY REED LLC',
    name: 'HARDY REED LLC',
    label: 'HARDY REED LLC',
  },
  {
    cik: '0001429390',
    value: '0001429390,HAREL INSURANCE INVESTMENTS & FINANCIAL SERVICES LTD.',
    name: 'HAREL INSURANCE INVESTMENTS & FINANCIAL SERVICES LTD.',
    label: 'HAREL INSURANCE INVESTMENTS & FINANCIAL SERVICES LTD.',
  },
  {
    cik: '0001649147',
    value: '0001649147,HARFST & ASSOCIATES, INC.',
    name: 'HARFST & ASSOCIATES, INC.',
    label: 'HARFST & ASSOCIATES, INC.',
  },
  {
    cik: '0001802882',
    value: '0001802882,HARRELL INVESTMENT PARTNERS, LLC',
    name: 'HARRELL INVESTMENT PARTNERS, LLC',
    label: 'HARRELL INVESTMENT PARTNERS, LLC',
  },
  {
    cik: '0001386364',
    value: '0001386364,HARRINGTON INVESTMENTS, INC',
    name: 'HARRINGTON INVESTMENTS, INC',
    label: 'HARRINGTON INVESTMENTS, INC',
  },
  {
    cik: '0000813917',
    value: '0000813917,HARRIS ASSOCIATES L P',
    name: 'HARRIS ASSOCIATES L P',
    label: 'HARRIS ASSOCIATES L P',
  },
  {
    cik: '0001903858',
    value: '0001903858,HARRISON & CO WEALTH MANAGEMENT, LLC',
    name: 'HARRISON & CO WEALTH MANAGEMENT, LLC',
    label: 'HARRISON & CO WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001534646',
    value: '0001534646,HARRISON STREET SECURITIES, LLC',
    name: 'HARRISON STREET SECURITIES, LLC',
    label: 'HARRISON STREET SECURITIES, LLC',
  },
  {
    cik: '0001652327',
    value: '0001652327,HARSPRING CAPITAL MANAGEMENT, LLC',
    name: 'HARSPRING CAPITAL MANAGEMENT, LLC',
    label: 'HARSPRING CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001352526',
    value: '0001352526,HARTFORD FINANCIAL MANAGEMENT INC.',
    name: 'HARTFORD FINANCIAL MANAGEMENT INC.',
    label: 'HARTFORD FINANCIAL MANAGEMENT INC.',
  },
  {
    cik: '0001546587',
    value: '0001546587,HARTFORD FUNDS MANAGEMENT CO LLC',
    name: 'HARTFORD FUNDS MANAGEMENT CO LLC',
    label: 'HARTFORD FUNDS MANAGEMENT CO LLC',
  },
  {
    cik: '0000922439',
    value: '0000922439,HARTFORD INVESTMENT MANAGEMENT CO',
    name: 'HARTFORD INVESTMENT MANAGEMENT CO',
    label: 'HARTFORD INVESTMENT MANAGEMENT CO',
  },
  {
    cik: '0000842775',
    value: '0000842775,HARTLAND & CO., LLC',
    name: 'HARTLAND & CO., LLC',
    label: 'HARTLAND & CO., LLC',
  },
  {
    cik: '0000945625',
    value: '0000945625,HARTLINE INVESTMENT CORP/',
    name: 'HARTLINE INVESTMENT CORP/',
    label: 'HARTLINE INVESTMENT CORP/',
  },
  {
    cik: '0001597857',
    value: '0001597857,HARTREE PARTNERS, LP',
    name: 'HARTREE PARTNERS, LP',
    label: 'HARTREE PARTNERS, LP',
  },
  {
    cik: '0000932141',
    value: '0000932141,HARTWELL J M LIMITED PARTNERSHIP',
    name: 'HARTWELL J M LIMITED PARTNERSHIP',
    label: 'HARTWELL J M LIMITED PARTNERSHIP',
  },
  {
    cik: '0001082621',
    value: '0001082621,HARVARD MANAGEMENT CO INC',
    name: 'HARVARD MANAGEMENT CO INC',
    label: 'HARVARD MANAGEMENT CO INC',
  },
  {
    cik: '0001105876',
    value: '0001105876,HARVEST CAPITAL MANAGEMENT INC',
    name: 'HARVEST CAPITAL MANAGEMENT INC',
    label: 'HARVEST CAPITAL MANAGEMENT INC',
  },
  {
    cik: '0001226355',
    value: '0001226355,HARVEST CAPITAL STRATEGIES LLC',
    name: 'HARVEST CAPITAL STRATEGIES LLC',
    label: 'HARVEST CAPITAL STRATEGIES LLC',
  },
  {
    cik: '0001456075',
    value: '0001456075,HARVEST FUND ADVISORS LLC',
    name: 'HARVEST FUND ADVISORS LLC',
    label: 'HARVEST FUND ADVISORS LLC',
  },
  {
    cik: '0001694126',
    value: '0001694126,HARVEST FUND MANAGEMENT CO., LTD',
    name: 'HARVEST FUND MANAGEMENT CO., LTD',
    label: 'HARVEST FUND MANAGEMENT CO., LTD',
  },
  {
    cik: '0001694461',
    value: '0001694461,HARVEST GROUP WEALTH MANAGEMENT, LLC',
    name: 'HARVEST GROUP WEALTH MANAGEMENT, LLC',
    label: 'HARVEST GROUP WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001591097',
    value: '0001591097,HARVEST INVESTMENT SERVICES, LLC',
    name: 'HARVEST INVESTMENT SERVICES, LLC',
    label: 'HARVEST INVESTMENT SERVICES, LLC',
  },
  {
    cik: '0001140315',
    value: '0001140315,HARVEST MANAGEMENT LLC',
    name: 'HARVEST MANAGEMENT LLC',
    label: 'HARVEST MANAGEMENT LLC',
  },
  {
    cik: '0001455530',
    value: '0001455530,HARVEST VOLATILITY MANAGEMENT LLC',
    name: 'HARVEST VOLATILITY MANAGEMENT LLC',
    label: 'HARVEST VOLATILITY MANAGEMENT LLC',
  },
  {
    cik: '0001119032',
    value: '0001119032,HARVEY CAPITAL MANAGEMENT INC',
    name: 'HARVEY CAPITAL MANAGEMENT INC',
    label: 'HARVEY CAPITAL MANAGEMENT INC',
  },
  {
    cik: '0001162781',
    value: '0001162781,HARVEY INVESTMENT CO LLC',
    name: 'HARVEY INVESTMENT CO LLC',
    label: 'HARVEY INVESTMENT CO LLC',
  },
  {
    cik: '0001406484',
    value: '0001406484,HARVEY PARTNERS, LLC',
    name: 'HARVEY PARTNERS, LLC',
    label: 'HARVEY PARTNERS, LLC',
  },
  {
    cik: '0001663224',
    value: '0001663224,HARWOOD ADVISORY GROUP, LLC',
    name: 'HARWOOD ADVISORY GROUP, LLC',
    label: 'HARWOOD ADVISORY GROUP, LLC',
  },
  {
    cik: '0001513062',
    value: '0001513062,HATTERAS ALTERNATIVE MUTUAL FUNDS, LLC',
    name: 'HATTERAS ALTERNATIVE MUTUAL FUNDS, LLC',
    label: 'HATTERAS ALTERNATIVE MUTUAL FUNDS, LLC',
  },
  {
    cik: '0001600745',
    value: '0001600745,HATTERAS FUNDS, LLC',
    name: 'HATTERAS FUNDS, LLC',
    label: 'HATTERAS FUNDS, LLC',
  },
  {
    cik: '0001859542',
    value: '0001859542,HATTON CONSULTING, INC.',
    name: 'HATTON CONSULTING, INC.',
    label: 'HATTON CONSULTING, INC.',
  },
  {
    cik: '0001280200',
    value: '0001280200,HAVENS ADVISORS LLC',
    name: 'HAVENS ADVISORS LLC',
    label: 'HAVENS ADVISORS LLC',
  },
  {
    cik: '0001347683',
    value: '0001347683,HAVERFORD FINANCIAL SERVICES, INC.',
    name: 'HAVERFORD FINANCIAL SERVICES, INC.',
    label: 'HAVERFORD FINANCIAL SERVICES, INC.',
  },
  {
    cik: '0000842941',
    value: '0000842941,HAVERFORD TRUST CO',
    name: 'HAVERFORD TRUST CO',
    label: 'HAVERFORD TRUST CO',
  },
  {
    cik: '0001389212',
    value: '0001389212,HAWKEYE CAPITAL MANAGEMENT, LLC',
    name: 'HAWKEYE CAPITAL MANAGEMENT, LLC',
    label: 'HAWKEYE CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001289851',
    value: '0001289851,HAWKINS CAPITAL L.P.',
    name: 'HAWKINS CAPITAL L.P.',
    label: 'HAWKINS CAPITAL L.P.',
  },
  {
    cik: '0001609074',
    value: '0001609074,HAWK RIDGE CAPITAL MANAGEMENT LP',
    name: 'HAWK RIDGE CAPITAL MANAGEMENT LP',
    label: 'HAWK RIDGE CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001799500',
    value: '0001799500,HAWSGOODWIN INVESTMENT MANAGEMENT LLC',
    name: 'HAWSGOODWIN INVESTMENT MANAGEMENT LLC',
    label: 'HAWSGOODWIN INVESTMENT MANAGEMENT LLC',
  },
  {
    cik: '0001652035',
    value: '0001652035,HAYDEN ROYAL, LLC',
    name: 'HAYDEN ROYAL, LLC',
    label: 'HAYDEN ROYAL, LLC',
  },
  {
    cik: '0001430681',
    value: '0001430681,HAYEK KALLEN INVESTMENT MANAGEMENT',
    name: 'HAYEK KALLEN INVESTMENT MANAGEMENT',
    label: 'HAYEK KALLEN INVESTMENT MANAGEMENT',
  },
  {
    cik: '0001420192',
    value: '0001420192,HAYMAN CAPITAL MANAGEMENT, L.P.',
    name: 'HAYMAN CAPITAL MANAGEMENT, L.P.',
    label: 'HAYMAN CAPITAL MANAGEMENT, L.P.',
  },
  {
    cik: '0001175130',
    value: '0001175130,HAYS ADVISORY LLC',
    name: 'HAYS ADVISORY LLC',
    label: 'HAYS ADVISORY LLC',
  },
  {
    cik: '0001705265',
    value: '0001705265,HAZELVIEW SECURITIES INC.',
    name: 'HAZELVIEW SECURITIES INC.',
    label: 'HAZELVIEW SECURITIES INC.',
  },
  {
    cik: '0000046392',
    value: '0000046392,HAZLETT, BURT & WATSON, INC.',
    name: 'HAZLETT, BURT & WATSON, INC.',
    label: 'HAZLETT, BURT & WATSON, INC.',
  },
  {
    cik: '0001802872',
    value: '0001802872,HBC FINANCIAL SERVICES, PLLC',
    name: 'HBC FINANCIAL SERVICES, PLLC',
    label: 'HBC FINANCIAL SERVICES, PLLC',
  },
  {
    cik: '0001011443',
    value: '0001011443,HBK INVESTMENTS L P',
    name: 'HBK INVESTMENTS L P',
    label: 'HBK INVESTMENTS L P',
  },
  {
    cik: '0001379995',
    value: '0001379995,HBK SORCE ADVISORY LLC',
    name: 'HBK SORCE ADVISORY LLC',
    label: 'HBK SORCE ADVISORY LLC',
  },
  {
    cik: '0001790525',
    value: '0001790525,HBW ADVISORY SERVICES LLC',
    name: 'HBW ADVISORY SERVICES LLC',
    label: 'HBW ADVISORY SERVICES LLC',
  },
  {
    cik: '0001911253',
    value: '0001911253,HB WEALTH MANAGEMENT, LLC',
    name: 'HB WEALTH MANAGEMENT, LLC',
    label: 'HB WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001803295',
    value: '0001803295,HC ADVISORS, LLC',
    name: 'HC ADVISORS, LLC',
    label: 'HC ADVISORS, LLC',
  },
  {
    cik: '0001912547',
    value: '0001912547,H CAPITAL II GP, L.P.',
    name: 'H CAPITAL II GP, L.P.',
    label: 'H CAPITAL II GP, L.P.',
  },
  {
    cik: '0001651808',
    value: '0001651808,H CAPITAL III, L.P.',
    name: 'H CAPITAL III, L.P.',
    label: 'H CAPITAL III, L.P.',
  },
  {
    cik: '0001627836',
    value: '0001627836,H CAPITAL II, L.P.',
    name: 'H CAPITAL II, L.P.',
    label: 'H CAPITAL II, L.P.',
  },
  {
    cik: '0001757491',
    value: '0001757491,H CAPITAL V GP, L.P.',
    name: 'H CAPITAL V GP, L.P.',
    label: 'H CAPITAL V GP, L.P.',
  },
  {
    cik: '0000784045',
    value: '0000784045,HC FINANCIAL ADVISORS INC',
    name: 'HC FINANCIAL ADVISORS INC',
    label: 'HC FINANCIAL ADVISORS INC',
  },
  {
    cik: '0001730936',
    value: '0001730936,HC FINANCIAL ADVISORS, INC.',
    name: 'HC FINANCIAL ADVISORS, INC.',
    label: 'HC FINANCIAL ADVISORS, INC.',
  },
  {
    cik: '0001697715',
    value: '0001697715,HCR WEALTH ADVISORS',
    name: 'HCR WEALTH ADVISORS',
    label: 'HCR WEALTH ADVISORS',
  },
  {
    cik: '0001562453',
    value: '0001562453,HCSF MANAGEMENT, LLC',
    name: 'HCSF MANAGEMENT, LLC',
    label: 'HCSF MANAGEMENT, LLC',
  },
  {
    cik: '0001303042',
    value: '0001303042,H D VEST ADVISORY SERVICES',
    name: 'H D VEST ADVISORY SERVICES',
    label: 'H D VEST ADVISORY SERVICES',
  },
  {
    cik: '0001372130',
    value: '0001372130,HEADINVEST, LLC',
    name: 'HEADINVEST, LLC',
    label: 'HEADINVEST, LLC',
  },
  {
    cik: '0001562452',
    value: '0001562452,HEADLANDS CAPITAL MANAGEMENT, LLC',
    name: 'HEADLANDS CAPITAL MANAGEMENT, LLC',
    label: 'HEADLANDS CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001535845',
    value: '0001535845,HEALTHCARE OF ONTARIO PENSION PLAN TRUST FUND',
    name: 'HEALTHCARE OF ONTARIO PENSION PLAN TRUST FUND',
    label: 'HEALTHCARE OF ONTARIO PENSION PLAN TRUST FUND',
  },
  {
    cik: '0001458461',
    value: '0001458461,HEALTHCARE VALUE CAPITAL, LLC',
    name: 'HEALTHCARE VALUE CAPITAL, LLC',
    label: 'HEALTHCARE VALUE CAPITAL, LLC',
  },
  {
    cik: '0001343781',
    value: '0001343781,HEALTHCOR MANAGEMENT, L.P.',
    name: 'HEALTHCOR MANAGEMENT, L.P.',
    label: 'HEALTHCOR MANAGEMENT, L.P.',
  },
  {
    cik: '0001519462',
    value: '0001519462,HEALTHCOR PARTNERS MANAGEMENT LP',
    name: 'HEALTHCOR PARTNERS MANAGEMENT LP',
    label: 'HEALTHCOR PARTNERS MANAGEMENT LP',
  },
  {
    cik: '0001381843',
    value: '0001381843,HEALTHINVEST PARTNERS AB',
    name: 'HEALTHINVEST PARTNERS AB',
    label: 'HEALTHINVEST PARTNERS AB',
  },
  {
    cik: '0001796409',
    value: '0001796409,HEARD CAPITAL LLC',
    name: 'HEARD CAPITAL LLC',
    label: 'HEARD CAPITAL LLC',
  },
  {
    cik: '0000937394',
    value: '0000937394,HEARTLAND ADVISORS INC',
    name: 'HEARTLAND ADVISORS INC',
    label: 'HEARTLAND ADVISORS INC',
  },
  {
    cik: '0001866040',
    value: '0001866040,HEARTLAND BANK & TRUST CO',
    name: 'HEARTLAND BANK & TRUST CO',
    label: 'HEARTLAND BANK & TRUST CO',
  },
  {
    cik: '0001794935',
    value: '0001794935,HEARTWOOD WEALTH ADVISORS LLC',
    name: 'HEARTWOOD WEALTH ADVISORS LLC',
    label: 'HEARTWOOD WEALTH ADVISORS LLC',
  },
  {
    cik: '0001645754',
    value: '0001645754,HEARTWOOD WEALTH MANAGEMENT LTD',
    name: 'HEARTWOOD WEALTH MANAGEMENT LTD',
    label: 'HEARTWOOD WEALTH MANAGEMENT LTD',
  },
  {
    cik: '0001503269',
    value: '0001503269,HEATHBRIDGE CAPITAL MANAGEMENT LTD.',
    name: 'HEATHBRIDGE CAPITAL MANAGEMENT LTD.',
    label: 'HEATHBRIDGE CAPITAL MANAGEMENT LTD.',
  },
  {
    cik: '0001633910',
    value: '0001633910,HEDEKER WEALTH, LLC',
    name: 'HEDEKER WEALTH, LLC',
    label: 'HEDEKER WEALTH, LLC',
  },
  {
    cik: '0001633910',
    value: '0001633910,HEDEKER WEALTH MANAGEMENT GROUP, LLC',
    name: 'HEDEKER WEALTH MANAGEMENT GROUP, LLC',
    label: 'HEDEKER WEALTH MANAGEMENT GROUP, LLC',
  },
  {
    cik: '0001906202',
    value: '0001906202,HEDGES ASSET MANAGEMENT LLC',
    name: 'HEDGES ASSET MANAGEMENT LLC',
    label: 'HEDGES ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001581793',
    value: '0001581793,HEFREN-TILLOTSON, INC.',
    name: 'HEFREN-TILLOTSON, INC.',
    label: 'HEFREN-TILLOTSON, INC.',
  },
  {
    cik: '0001674672',
    value: '0001674672,HEFTY WEALTH PARTNERS',
    name: 'HEFTY WEALTH PARTNERS',
    label: 'HEFTY WEALTH PARTNERS',
  },
  {
    cik: '0001826937',
    value: '0001826937,HEIN PARK CAPITAL MANAGEMENT LP',
    name: 'HEIN PARK CAPITAL MANAGEMENT LP',
    label: 'HEIN PARK CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001882903',
    value: '0001882903,HEIRLOOM WEALTH MANAGEMENT',
    name: 'HEIRLOOM WEALTH MANAGEMENT',
    label: 'HEIRLOOM WEALTH MANAGEMENT',
  },
  {
    cik: '0001080117',
    value: '0001080117,HEITMAN REAL ESTATE SECURITIES LLC',
    name: 'HEITMAN REAL ESTATE SECURITIES LLC',
    label: 'HEITMAN REAL ESTATE SECURITIES LLC',
  },
  {
    cik: '0001895252',
    value: '0001895252,HELEN STEPHENS GROUP, LLC',
    name: 'HELEN STEPHENS GROUP, LLC',
    label: 'HELEN STEPHENS GROUP, LLC',
  },
  {
    cik: '0001839497',
    value: '0001839497,HELIKON INVESTMENTS LTD',
    name: 'HELIKON INVESTMENTS LTD',
    label: 'HELIKON INVESTMENTS LTD',
  },
  {
    cik: '0001727612',
    value: '0001727612,HELIOS CAPITAL MANAGEMENT PTE. LTD',
    name: 'HELIOS CAPITAL MANAGEMENT PTE. LTD',
    label: 'HELIOS CAPITAL MANAGEMENT PTE. LTD',
  },
  {
    cik: '0001402635',
    value: '0001402635,HELLMAN & FRIEDMAN INVESTORS V (CAYMAN), LTD.',
    name: 'HELLMAN & FRIEDMAN INVESTORS V (CAYMAN), LTD.',
    label: 'HELLMAN & FRIEDMAN INVESTORS V (CAYMAN), LTD.',
  },
  {
    cik: '0001462335',
    value: '0001462335,HELLMAN & FRIEDMAN LLC',
    name: 'HELLMAN & FRIEDMAN LLC',
    label: 'HELLMAN & FRIEDMAN LLC',
  },
  {
    cik: '0000779519',
    value: '0000779519,HELLMAN JORDAN MANAGEMENT CO INC /MA/',
    name: 'HELLMAN JORDAN MANAGEMENT CO INC /MA/',
    label: 'HELLMAN JORDAN MANAGEMENT CO INC /MA/',
  },
  {
    cik: '0001840129',
    value: '0001840129,HEL VED CAPITAL MANAGEMENT LTD',
    name: 'HEL VED CAPITAL MANAGEMENT LTD',
    label: 'HEL VED CAPITAL MANAGEMENT LTD',
  },
  {
    cik: '0001494234',
    value: '0001494234,HEMENWAY TRUST CO LLC',
    name: 'HEMENWAY TRUST CO LLC',
    label: 'HEMENWAY TRUST CO LLC',
  },
  {
    cik: '0001838234',
    value: '0001838234,HEMINGTON WEALTH MANAGEMENT',
    name: 'HEMINGTON WEALTH MANAGEMENT',
    label: 'HEMINGTON WEALTH MANAGEMENT',
  },
  {
    cik: '0001541496',
    value: '0001541496,HENDERSHOT INVESTMENTS INC.',
    name: 'HENDERSHOT INVESTMENTS INC.',
    label: 'HENDERSHOT INVESTMENTS INC.',
  },
  {
    cik: '0001274173',
    value: '0001274173,HENDERSON GROUP PLC',
    name: 'HENDERSON GROUP PLC',
    label: 'HENDERSON GROUP PLC',
  },
  {
    cik: '0001084207',
    value: '0001084207,HENDLEY & CO INC',
    name: 'HENDLEY & CO INC',
    label: 'HENDLEY & CO INC',
  },
  {
    cik: '0001536430',
    value: '0001536430,HENGEHOLD CAPITAL MANAGEMENT LLC',
    name: 'HENGEHOLD CAPITAL MANAGEMENT LLC',
    label: 'HENGEHOLD CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001574717',
    value: '0001574717,HENGISTBURY INVESTMENT PARTNERS LLP',
    name: 'HENGISTBURY INVESTMENT PARTNERS LLP',
    label: 'HENGISTBURY INVESTMENT PARTNERS LLP',
  },
  {
    cik: '0001145255',
    value: '0001145255,HENNESSY ADVISORS INC',
    name: 'HENNESSY ADVISORS INC',
    label: 'HENNESSY ADVISORS INC',
  },
  {
    cik: '0001861642',
    value: '0001861642,HENNION & WALSH ASSET MANAGEMENT, INC.',
    name: 'HENNION & WALSH ASSET MANAGEMENT, INC.',
    label: 'HENNION & WALSH ASSET MANAGEMENT, INC.',
  },
  {
    cik: '0001903859',
    value: '0001903859,HENRICKSON NAUTA WEALTH ADVISORS, INC.',
    name: 'HENRICKSON NAUTA WEALTH ADVISORS, INC.',
    label: 'HENRICKSON NAUTA WEALTH ADVISORS, INC.',
  },
  {
    cik: '0001750423',
    value: '0001750423,HENRY JAMES INTERNATIONAL MANAGEMENT INC.',
    name: 'HENRY JAMES INTERNATIONAL MANAGEMENT INC.',
    label: 'HENRY JAMES INTERNATIONAL MANAGEMENT INC.',
  },
  {
    cik: '0001512022',
    value: '0001512022,HERALD INVESTMENT MANAGEMENT LTD',
    name: 'HERALD INVESTMENT MANAGEMENT LTD',
    label: 'HERALD INVESTMENT MANAGEMENT LTD',
  },
  {
    cik: '0001909319',
    value: '0001909319,HERBST GROUP, LLC',
    name: 'HERBST GROUP, LLC',
    label: 'HERBST GROUP, LLC',
  },
  {
    cik: '0001752035',
    value: '0001752035,HERITAGE FINANCIAL SERVICES, LLC',
    name: 'HERITAGE FINANCIAL SERVICES, LLC',
    label: 'HERITAGE FINANCIAL SERVICES, LLC',
  },
  {
    cik: '0001876811',
    value: '0001876811,HERITAGE INVESTMENT GROUP, INC.',
    name: 'HERITAGE INVESTMENT GROUP, INC.',
    label: 'HERITAGE INVESTMENT GROUP, INC.',
  },
  {
    cik: '0000937589',
    value: '0000937589,HERITAGE INVESTORS MANAGEMENT CORP',
    name: 'HERITAGE INVESTORS MANAGEMENT CORP',
    label: 'HERITAGE INVESTORS MANAGEMENT CORP',
  },
  {
    cik: '0001706028',
    value: '0001706028,HERITAGE TRUST CO',
    name: 'HERITAGE TRUST CO',
    label: 'HERITAGE TRUST CO',
  },
  {
    cik: '0001698220',
    value: '0001698220,HERITAGE WAY ADVISORS, LLC',
    name: 'HERITAGE WAY ADVISORS, LLC',
    label: 'HERITAGE WAY ADVISORS, LLC',
  },
  {
    cik: '0001591122',
    value: '0001591122,HERITAGE WEALTH ADVISORS',
    name: 'HERITAGE WEALTH ADVISORS',
    label: 'HERITAGE WEALTH ADVISORS',
  },
  {
    cik: '0001834505',
    value: '0001834505,HERITAGE WEALTH MANAGEMENT, LLC',
    name: 'HERITAGE WEALTH MANAGEMENT, LLC',
    label: 'HERITAGE WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001463559',
    value:
      '0001463559,HER MAJESTY THE QUEEN IN RIGHT OF THE PROVINCE OF ALBERTA AS REPRESENTED BY ALBERTA INVESTMENT MANAGEMENT CORP',
    name: 'HER MAJESTY THE QUEEN IN RIGHT OF THE PROVINCE OF ALBERTA AS REPRESENTED BY ALBERTA INVESTMENT MANAGEMENT CORP',
    label:
      'HER MAJESTY THE QUEEN IN RIGHT OF THE PROVINCE OF ALBERTA AS REPRESENTED BY ALBERTA INVESTMENT MANAGEMENT CORP',
  },
  {
    cik: '0001013143',
    value: '0001013143,HERMES INVESTMENT MANAGEMENT LTD',
    name: 'HERMES INVESTMENT MANAGEMENT LTD',
    label: 'HERMES INVESTMENT MANAGEMENT LTD',
  },
  {
    cik: '0001788700',
    value: '0001788700,HERNANI HOLDINGS LLC',
    name: 'HERNANI HOLDINGS LLC',
    label: 'HERNANI HOLDINGS LLC',
  },
  {
    cik: '0001914186',
    value: '000191418,HERNANI LP',
    name: 'HERNANI LP',
    label: 'HERNANI LP',
  },
  {
    cik: '0001320005',
    value: '0001320005,HERNDON CAPITAL MANAGEMENT',
    name: 'HERNDON CAPITAL MANAGEMENT',
    label: 'HERNDON CAPITAL MANAGEMENT',
  },
  {
    cik: '0001891713',
    value: '0001891713,HEROLD ADVISORS, INC.',
    name: 'HEROLD ADVISORS, INC.',
    label: 'HEROLD ADVISORS, INC.',
  },
  {
    cik: '0001842149',
    value: '0001842149,HERON BAY CAPITAL MANAGEMENT',
    name: 'HERON BAY CAPITAL MANAGEMENT',
    label: 'HERON BAY CAPITAL MANAGEMENT',
  },
  {
    cik: '0001588225',
    value: '0001588225,HERONETTA MANAGEMENT, L.P.',
    name: 'HERONETTA MANAGEMENT, L.P.',
    label: 'HERONETTA MANAGEMENT, L.P.',
  },
  {
    cik: '0001803648',
    value: '0001803648,HERON FINANCIAL GROUP, LLC',
    name: 'HERON FINANCIAL GROUP, LLC',
    label: 'HERON FINANCIAL GROUP, LLC',
  },
  {
    cik: '0001575766',
    value: '0001575766,HERRING CREEK CAPITAL MANAGEMENT, LLC',
    name: 'HERRING CREEK CAPITAL MANAGEMENT, LLC',
    label: 'HERRING CREEK CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0000908551',
    value: '0000908551,HERSHEY TRUST CO',
    name: 'HERSHEY TRUST CO',
    label: 'HERSHEY TRUST CO',
  },
  {
    cik: '0001831542',
    value: '0001831542,HEXAGON CAPITAL PARTNERS LLC',
    name: 'HEXAGON CAPITAL PARTNERS LLC',
    label: 'HEXAGON CAPITAL PARTNERS LLC',
  },
  {
    cik: '0001389191',
    value: '0001389191,HEXAVEST INC.',
    name: 'HEXAVEST INC.',
    label: 'HEXAVEST INC.',
  },
  {
    cik: '0001767563',
    value: '0001767563,H&F CORPORATE INVESTORS VIII, LTD.',
    name: 'H&F CORPORATE INVESTORS VIII, LTD.',
    label: 'H&F CORPORATE INVESTORS VIII, LTD.',
  },
  {
    cik: '0001660963',
    value: '0001660963,H&F CORPORATE INVESTORS VII, LTD.',
    name: 'H&F CORPORATE INVESTORS VII, LTD.',
    label: 'H&F CORPORATE INVESTORS VII, LTD.',
  },
  {
    cik: '0001901930',
    value: '0001901930,H&F CORPORATE INVESTORS X, LTD.',
    name: 'H&F CORPORATE INVESTORS X, LTD.',
    label: 'H&F CORPORATE INVESTORS X, LTD.',
  },
  {
    cik: '0001911087',
    value: '0001911087,HFG ADVISORS, INC.',
    name: 'HFG ADVISORS, INC.',
    label: 'HFG ADVISORS, INC.',
  },
  {
    cik: '0001877822',
    value: '0001877822,HFR WEALTH MANAGEMENT, LLC',
    name: 'HFR WEALTH MANAGEMENT, LLC',
    label: 'HFR WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001711200',
    value: '0001711200,HGC INVESTMENT MANAGEMENT INC.',
    name: 'HGC INVESTMENT MANAGEMENT INC.',
    label: 'HGC INVESTMENT MANAGEMENT INC.',
  },
  {
    cik: '0001595907',
    value: '0001595907,HGI CAPITAL MANAGEMENT, LLC',
    name: 'HGI CAPITAL MANAGEMENT, LLC',
    label: 'HGI CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0000740272',
    value: '0000740272,HGK ASSET MANAGEMENT INC',
    name: 'HGK ASSET MANAGEMENT INC',
    label: 'HGK ASSET MANAGEMENT INC',
  },
  {
    cik: '0001525362',
    value: '0001525362,HG VORA CAPITAL MANAGEMENT, LLC',
    name: 'HG VORA CAPITAL MANAGEMENT, LLC',
    label: 'HG VORA CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001307203',
    value: '0001307203,HHG & COMPANY, LLC',
    name: 'HHG & COMPANY, LLC',
    label: 'HHG & COMPANY, LLC',
  },
  {
    cik: '0001759760',
    value: '0001759760,H&H INTERNATIONAL INVESTMENT, LLC',
    name: 'H&H INTERNATIONAL INVESTMENT, LLC',
    label: 'H&H INTERNATIONAL INVESTMENT, LLC',
  },
  {
    cik: '0001762304',
    value: '0001762304,HHLR ADVISORS, LTD.',
    name: 'HHLR ADVISORS, LTD.',
    label: 'HHLR ADVISORS, LTD.',
  },
  {
    cik: '0001750086',
    value: '0001750086,HHM WEALTH ADVISORS, LLC',
    name: 'HHM WEALTH ADVISORS, LLC',
    label: 'HHM WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001397545',
    value: '0001397545,HHR ASSET MANAGEMENT, LLC',
    name: 'HHR ASSET MANAGEMENT, LLC',
    label: 'HHR ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001802635',
    value: '0001802635,H&H RETIREMENT DESIGN & MANAGEMENT INC',
    name: 'H&H RETIREMENT DESIGN & MANAGEMENT INC',
    label: 'H&H RETIREMENT DESIGN & MANAGEMENT INC',
  },
  {
    cik: '0001817276',
    value: '0001817276,HICKORY LANE CAPITAL MANAGEMENT LP',
    name: 'HICKORY LANE CAPITAL MANAGEMENT LP',
    label: 'HICKORY LANE CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001823823',
    value: '0001823823,HIDDENITE CAPITAL PARTNERS LP',
    name: 'HIDDENITE CAPITAL PARTNERS LP',
    label: 'HIDDENITE CAPITAL PARTNERS LP',
  },
  {
    cik: '0001750312',
    value: '0001750312,HIDDEN LAKE ASSET MANAGEMENT LP',
    name: 'HIDDEN LAKE ASSET MANAGEMENT LP',
    label: 'HIDDEN LAKE ASSET MANAGEMENT LP',
  },
  {
    cik: '0000919185',
    value: '0000919185,HIGHBRIDGE CAPITAL MANAGEMENT LLC',
    name: 'HIGHBRIDGE CAPITAL MANAGEMENT LLC',
    label: 'HIGHBRIDGE CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001537176',
    value: '0001537176,HIGHER MOMENT AURORA FUND, LP',
    name: 'HIGHER MOMENT AURORA FUND, LP',
    label: 'HIGHER MOMENT AURORA FUND, LP',
  },
  {
    cik: '0001599471',
    value: '0001599471,HIGHER MOMENT CAPITAL LP',
    name: 'HIGHER MOMENT CAPITAL LP',
    label: 'HIGHER MOMENT CAPITAL LP',
  },
  {
    cik: '0001569139',
    value: '0001569139,HIGH FALLS ADVISORS, INC',
    name: 'HIGH FALLS ADVISORS, INC',
    label: 'HIGH FALLS ADVISORS, INC',
  },
  {
    cik: '0001079563',
    value: '0001079563,HIGHFIELDS CAPITAL MANAGEMENT LP',
    name: 'HIGHFIELDS CAPITAL MANAGEMENT LP',
    label: 'HIGHFIELDS CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0000716187',
    value: '0000716187,HIGHLAND ASSOCIATES INC',
    name: 'HIGHLAND ASSOCIATES INC',
    label: 'HIGHLAND ASSOCIATES INC',
  },
  {
    cik: '0001469877',
    value: '0001469877,HIGHLAND CAPITAL MANAGEMENT FUND ADVISORS, L.P.',
    name: 'HIGHLAND CAPITAL MANAGEMENT FUND ADVISORS, L.P.',
    label: 'HIGHLAND CAPITAL MANAGEMENT FUND ADVISORS, L.P.',
  },
  {
    cik: '0000860486',
    value: '0000860486,HIGHLAND CAPITAL MANAGEMENT, LLC',
    name: 'HIGHLAND CAPITAL MANAGEMENT, LLC',
    label: 'HIGHLAND CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001167365',
    value: '0001167365,HIGHLAND CAPITAL MANAGEMENT LP',
    name: 'HIGHLAND CAPITAL MANAGEMENT LP',
    label: 'HIGHLAND CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001519113',
    value: '0001519113,HIGHLANDER CAPITAL MANAGEMENT, LLC',
    name: 'HIGHLANDER CAPITAL MANAGEMENT, LLC',
    label: 'HIGHLANDER CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001903866',
    value: '0001903866,HIGHLANDER PARTNERS, L.P.',
    name: 'HIGHLANDER PARTNERS, L.P.',
    label: 'HIGHLANDER PARTNERS, L.P.',
  },
  {
    cik: '0001939237',
    value: '0001939237,HIGHLAND FINANCIAL ADVISORS, LLC',
    name: 'HIGHLAND FINANCIAL ADVISORS, LLC',
    label: 'HIGHLAND FINANCIAL ADVISORS, LLC',
  },
  {
    cik: '0001870731',
    value: '0001870731,HIGHLAND MANAGEMENT PARTNERS 9 LLC',
    name: 'HIGHLAND MANAGEMENT PARTNERS 9 LLC',
    label: 'HIGHLAND MANAGEMENT PARTNERS 9 LLC',
  },
  {
    cik: '0001554026',
    value: '0001554026,HIGHLAND MANAGEMENT PARTNERS VII, LLC',
    name: 'HIGHLAND MANAGEMENT PARTNERS VII, LLC',
    label: 'HIGHLAND MANAGEMENT PARTNERS VII, LLC',
  },
  {
    cik: '0001456415',
    value: '0001456415,HIGHLAND MANAGEMENT PARTNERS VI, INC.',
    name: 'HIGHLAND MANAGEMENT PARTNERS VI, INC.',
    label: 'HIGHLAND MANAGEMENT PARTNERS VI, INC.',
  },
  {
    cik: '0001664772',
    value: '0001664772,HIGHLAND PRIVATE WEALTH MANAGEMENT',
    name: 'HIGHLAND PRIVATE WEALTH MANAGEMENT',
    label: 'HIGHLAND PRIVATE WEALTH MANAGEMENT',
  },
  {
    cik: '0001166850',
    value: '0001166850,HIGHLINE CAPITAL MANAGEMENT LLC',
    name: 'HIGHLINE CAPITAL MANAGEMENT LLC',
    label: 'HIGHLINE CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001166850',
    value: '0001166850,HIGHLINE CAPITAL MANAGEMENT, L.P.',
    name: 'HIGHLINE CAPITAL MANAGEMENT, L.P.',
    label: 'HIGHLINE CAPITAL MANAGEMENT, L.P.',
  },
  {
    cik: '0001802451',
    value: '0001802451,HIGHMARK WEALTH MANAGEMENT LLC',
    name: 'HIGHMARK WEALTH MANAGEMENT LLC',
    label: 'HIGHMARK WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0001453819',
    value: '0001453819,HIGHMOUNT CAPITAL, LLC',
    name: 'HIGHMOUNT CAPITAL, LLC',
    label: 'HIGHMOUNT CAPITAL, LLC',
  },
  {
    cik: '0001919142',
    value: '0001919142,HIGH NET WORTH ADVISORY GROUP LLC',
    name: 'HIGH NET WORTH ADVISORY GROUP LLC',
    label: 'HIGH NET WORTH ADVISORY GROUP LLC',
  },
  {
    cik: '0001807283',
    value: '0001807283,HIGH NOTE WEALTH, LLC',
    name: 'HIGH NOTE WEALTH, LLC',
    label: 'HIGH NOTE WEALTH, LLC',
  },
  {
    cik: '0001855571',
    value: '0001855571,HIGH PINES WEALTH MANAGEMENT, LLC',
    name: 'HIGH PINES WEALTH MANAGEMENT, LLC',
    label: 'HIGH PINES WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001616034',
    value: '0001616034,HIGHPOINT ADVISOR GROUP LLC',
    name: 'HIGHPOINT ADVISOR GROUP LLC',
    label: 'HIGHPOINT ADVISOR GROUP LLC',
  },
  {
    cik: '0001156185',
    value: '0001156185,HIGH POINT BANK & TRUST CO',
    name: 'HIGH POINT BANK & TRUST CO',
    label: 'HIGH POINT BANK & TRUST CO',
  },
  {
    cik: '0001280051',
    value: '0001280051,HIGH POINTE CAPITAL MANAGEMENT LLC',
    name: 'HIGH POINTE CAPITAL MANAGEMENT LLC',
    label: 'HIGH POINTE CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001802532',
    value: '0001802532,HIGHSIDE GLOBAL MANAGEMENT LLC',
    name: 'HIGHSIDE GLOBAL MANAGEMENT LLC',
    label: 'HIGHSIDE GLOBAL MANAGEMENT LLC',
  },
  {
    cik: '0001512607',
    value: '0001512607,HIGHSTAR CAPITAL LP',
    name: 'HIGHSTAR CAPITAL LP',
    label: 'HIGHSTAR CAPITAL LP',
  },
  {
    cik: '0001484368',
    value: '0001484368,HIGHSTREET ASSET MANAGEMENT INC.',
    name: 'HIGHSTREET ASSET MANAGEMENT INC.',
    label: 'HIGHSTREET ASSET MANAGEMENT INC.',
  },
  {
    cik: '0001911968',
    value: '0001911968,HIGHTOWER 6M HOLDING, LLC',
    name: 'HIGHTOWER 6M HOLDING, LLC',
    label: 'HIGHTOWER 6M HOLDING, LLC',
  },
  {
    cik: '0001462245',
    value: '0001462245,HIGHTOWER ADVISORS, LLC',
    name: 'HIGHTOWER ADVISORS, LLC',
    label: 'HIGHTOWER ADVISORS, LLC',
  },
  {
    cik: '0001090413',
    value: '0001090413,HIGHTOWER TRUST COMPANY, N.A.',
    name: 'HIGHTOWER TRUST COMPANY, N.A.',
    label: 'HIGHTOWER TRUST COMPANY, N.A.',
  },
  {
    cik: '0001090413',
    value: '0001090413,HIGHTOWER TRUST SERVICES, LTA',
    name: 'HIGHTOWER TRUST SERVICES, LTA',
    label: 'HIGHTOWER TRUST SERVICES, LTA',
  },
  {
    cik: '0001903786',
    value: '0001903786,HIGHVIEW CAPITAL MANAGEMENT LLC/DE/',
    name: 'HIGHVIEW CAPITAL MANAGEMENT LLC/DE/',
    label: 'HIGHVIEW CAPITAL MANAGEMENT LLC/DE/',
  },
  {
    cik: '0001335325',
    value: '0001335325,HIGHVISTA STRATEGIES LLC',
    name: 'HIGHVISTA STRATEGIES LLC',
    label: 'HIGHVISTA STRATEGIES LLC',
  },
  {
    cik: '0001683046',
    value: '0001683046,HIGHWATER WEALTH MANAGEMENT, LLC',
    name: 'HIGHWATER WEALTH MANAGEMENT, LLC',
    label: 'HIGHWATER WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001606152',
    value: '0001606152,HIKARI POWER LTD',
    name: 'HIKARI POWER LTD',
    label: 'HIKARI POWER LTD',
  },
  {
    cik: '0001423673',
    value: '0001423673,HIKARI TSUSHIN, INC.',
    name: 'HIKARI TSUSHIN, INC.',
    label: 'HIKARI TSUSHIN, INC.',
  },
  {
    cik: '0001776074',
    value: '0001776074,HI-LINE CAPITAL MANAGEMENT, LLC',
    name: 'HI-LINE CAPITAL MANAGEMENT, LLC',
    label: 'HI-LINE CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001837343',
    value: '0001837343,HILL CITY CAPITAL, LP',
    name: 'HILL CITY CAPITAL, LP',
    label: 'HILL CITY CAPITAL, LP',
  },
  {
    cik: '0001415345',
    value: '0001415345,HILLCREST ASSET MANAGEMENT LLC',
    name: 'HILLCREST ASSET MANAGEMENT LLC',
    label: 'HILLCREST ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001767121',
    value: '0001767121,HILLCREST WEALTH ADVISORS - NY, LLC',
    name: 'HILLCREST WEALTH ADVISORS - NY, LLC',
    label: 'HILLCREST WEALTH ADVISORS - NY, LLC',
  },
  {
    cik: '0001762304',
    value: '0001762304,HILLHOUSE CAPITAL ADVISORS, LTD.',
    name: 'HILLHOUSE CAPITAL ADVISORS, LTD.',
    label: 'HILLHOUSE CAPITAL ADVISORS, LTD.',
  },
  {
    cik: '0001510589',
    value: '0001510589,HILLHOUSE CAPITAL MANAGEMENT, LTD.',
    name: 'HILLHOUSE CAPITAL MANAGEMENT, LTD.',
    label: 'HILLHOUSE CAPITAL MANAGEMENT, LTD.',
  },
  {
    cik: '0001909798',
    value: '0001909798,HILL ISLAND FINANCIAL LLC',
    name: 'HILL ISLAND FINANCIAL LLC',
    label: 'HILL ISLAND FINANCIAL LLC',
  },
  {
    cik: '0001314620',
    value: '0001314620,HILLMAN CAPITAL MANAGEMENT, INC.',
    name: 'HILLMAN CAPITAL MANAGEMENT, INC.',
    label: 'HILLMAN CAPITAL MANAGEMENT, INC.',
  },
  {
    cik: '0001036288',
    value: '000103628,HILLMAN CO',
    name: 'HILLMAN CO',
    label: 'HILLMAN CO',
  },
  {
    cik: '0001676292',
    value: '0001676292,HILL PATH CAPITAL LP',
    name: 'HILL PATH CAPITAL LP',
    label: 'HILL PATH CAPITAL LP',
  },
  {
    cik: '0001309148',
    value: '0001309148,HILLS BANK & TRUST CO',
    name: 'HILLS BANK & TRUST CO',
    label: 'HILLS BANK & TRUST CO',
  },
  {
    cik: '0001368465',
    value: '0001368465,HILLSDALE INVESTMENT MANAGEMENT INC.',
    name: 'HILLSDALE INVESTMENT MANAGEMENT INC.',
    label: 'HILLSDALE INVESTMENT MANAGEMENT INC.',
  },
  {
    cik: '0001310052',
    value: '0001310052,HILLSWICK ASSET MANAGEMENT, LLC',
    name: 'HILLSWICK ASSET MANAGEMENT, LLC',
    label: 'HILLSWICK ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001265131',
    value: '0001265131,HILLTOP HOLDINGS INC.',
    name: 'HILLTOP HOLDINGS INC.',
    label: 'HILLTOP HOLDINGS INC.',
  },
  {
    cik: '0001483938',
    value: '0001483938,HILLTOP PARK ASSOCIATES LLC',
    name: 'HILLTOP PARK ASSOCIATES LLC',
    label: 'HILLTOP PARK ASSOCIATES LLC',
  },
  {
    cik: '0001786241',
    value: '0001786241,HILLTOP WEALTH ADVISORS, LLC',
    name: 'HILLTOP WEALTH ADVISORS, LLC',
    label: 'HILLTOP WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001631615',
    value: '0001631615,HILLVIEW CAPITAL ADVISORS, LLC',
    name: 'HILLVIEW CAPITAL ADVISORS, LLC',
    label: 'HILLVIEW CAPITAL ADVISORS, LLC',
  },
  {
    cik: '0001683073',
    value: '0001683073,HILL WINDS CAPITAL LP',
    name: 'HILL WINDS CAPITAL LP',
    label: 'HILL WINDS CAPITAL LP',
  },
  {
    cik: '0001541211',
    value: '0001541211,HILTON CAPITAL MANAGEMENT, LLC',
    name: 'HILTON CAPITAL MANAGEMENT, LLC',
    label: 'HILTON CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001709323',
    value: '0001709323,HIMALAYA CAPITAL MANAGEMENT LLC',
    name: 'HIMALAYA CAPITAL MANAGEMENT LLC',
    label: 'HIMALAYA CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001817652',
    value: '0001817652,HIMENSION CAPITAL (SINGAPORE) PTE. LTD.',
    name: 'HIMENSION CAPITAL (SINGAPORE) PTE. LTD.',
    label: 'HIMENSION CAPITAL (SINGAPORE) PTE. LTD.',
  },
  {
    cik: '0001360710',
    value: '0001360710,HIRTLE CALLAGHAN & CO LLC',
    name: 'HIRTLE CALLAGHAN & CO LLC',
    label: 'HIRTLE CALLAGHAN & CO LLC',
  },
  {
    cik: '0001547135',
    value: '0001547135,HIRZEL CAPITAL MANAGEMENT LLC',
    name: 'HIRZEL CAPITAL MANAGEMENT LLC',
    label: 'HIRZEL CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001611613',
    value: '0001611613,HITCHWOOD CAPITAL MANAGEMENT LP',
    name: 'HITCHWOOD CAPITAL MANAGEMENT LP',
    label: 'HITCHWOOD CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001568939',
    value: '0001568939,HITE HEDGE ASSET MANAGEMENT LLC',
    name: 'HITE HEDGE ASSET MANAGEMENT LLC',
    label: 'HITE HEDGE ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001806752',
    value: '0001806752,HIXON ZUERCHER, LLC',
    name: 'HIXON ZUERCHER, LLC',
    label: 'HIXON ZUERCHER, LLC',
  },
  {
    cik: '0001430233',
    value: '0001430233,HL FINANCIAL SERVICES LLC',
    name: 'HL FINANCIAL SERVICES LLC',
    label: 'HL FINANCIAL SERVICES LLC',
  },
  {
    cik: '0001909569',
    value: '0001909569,H. L. ORMOND & COMPANY, LLC',
    name: 'H. L. ORMOND & COMPANY, LLC',
    label: 'H. L. ORMOND & COMPANY, LLC',
  },
  {
    cik: '0001367786',
    value: '0001367786,HM CAPITAL MANAGEMENT, LLC',
    name: 'HM CAPITAL MANAGEMENT, LLC',
    label: 'HM CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001469026',
    value: '0001469026,HMI CAPITAL MANAGEMENT, L.P.',
    name: 'HMI CAPITAL MANAGEMENT, L.P.',
    label: 'HMI CAPITAL MANAGEMENT, L.P.',
  },
  {
    cik: '0000928052',
    value: '0000928052,HM PAYSON & CO',
    name: 'HM PAYSON & CO',
    label: 'HM PAYSON & CO',
  },
  {
    cik: '0001689470',
    value: '0001689470,HMS CAPITAL MANAGEMENT, LLC',
    name: 'HMS CAPITAL MANAGEMENT, LLC',
    label: 'HMS CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001707885',
    value: '0001707885,HNA CAPITAL GROUP CO., LTD.',
    name: 'HNA CAPITAL GROUP CO., LTD.',
    label: 'HNA CAPITAL GROUP CO., LTD.',
  },
  {
    cik: '0001701408',
    value: '0001701408,HNA TOURISM GROUP CO., LTD.',
    name: 'HNA TOURISM GROUP CO., LTD.',
    label: 'HNA TOURISM GROUP CO., LTD.',
  },
  {
    cik: '0001767841',
    value: '0001767841,HNP CAPITAL LLC',
    name: 'HNP CAPITAL LLC',
    label: 'HNP CAPITAL LLC',
  },
  {
    cik: '0001552059',
    value: '0001552059,HN SALTORO CAPITAL, LP',
    name: 'HN SALTORO CAPITAL, LP',
    label: 'HN SALTORO CAPITAL, LP',
  },
  {
    cik: '0001568890',
    value: '0001568890,HOCHMAN COLE INVESTMENT ADVISORS, INC.',
    name: 'HOCHMAN COLE INVESTMENT ADVISORS, INC.',
    label: 'HOCHMAN COLE INVESTMENT ADVISORS, INC.',
  },
  {
    cik: '0001446962',
    value: '0001446962,HOCHSCHILD MINING PLC',
    name: 'HOCHSCHILD MINING PLC',
    label: 'HOCHSCHILD MINING PLC',
  },
  {
    cik: '0001337263',
    value: '0001337263,HODGES CAPITAL MANAGEMENT INC.',
    name: 'HODGES CAPITAL MANAGEMENT INC.',
    label: 'HODGES CAPITAL MANAGEMENT INC.',
  },
  {
    cik: '0001598177',
    value: '0001598177,HOERTKORN RICHARD CHARLES',
    name: 'HOERTKORN RICHARD CHARLES',
    label: 'HOERTKORN RICHARD CHARLES',
  },
  {
    cik: '0001840268',
    value: '0001840268,HOESE & CO LLP',
    name: 'HOESE & CO LLP',
    label: 'HOESE & CO LLP',
  },
  {
    cik: '0001799544',
    value: '0001799544,HOEY INVESTMENTS, INC',
    name: 'HOEY INVESTMENTS, INC',
    label: 'HOEY INVESTMENTS, INC',
  },
  {
    cik: '0001958491',
    value: '0001958491,HOFER & ASSOCIATES. INC',
    name: 'HOFER & ASSOCIATES. INC',
    label: 'HOFER & ASSOCIATES. INC',
  },
  {
    cik: '0001848530',
    value: '0001848530,HOFFMAN, ALAN N INVESTMENT MANAGEMENT',
    name: 'HOFFMAN, ALAN N INVESTMENT MANAGEMENT',
    label: 'HOFFMAN, ALAN N INVESTMENT MANAGEMENT',
  },
  {
    cik: '0001802533',
    value: '0001802533,HOHIMER WEALTH MANAGEMENT, LLC',
    name: 'HOHIMER WEALTH MANAGEMENT, LLC',
    label: 'HOHIMER WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001172799',
    value: '0001172799,HOKANSON ASSOCIATES, INC.',
    name: 'HOKANSON ASSOCIATES, INC.',
    label: 'HOKANSON ASSOCIATES, INC.',
  },
  {
    cik: '0001724246',
    value: '0001724246,HOLDCO ASSET MANAGEMENT, LP',
    name: 'HOLDCO ASSET MANAGEMENT, LP',
    label: 'HOLDCO ASSET MANAGEMENT, LP',
  },
  {
    cik: '0001724246',
    value: '0001724246,HOLDCO ASSET MANAGEMENT, L.P.',
    name: 'HOLDCO ASSET MANAGEMENT, L.P.',
    label: 'HOLDCO ASSET MANAGEMENT, L.P.',
  },
  {
    cik: '0001184820',
    value: '0001184820,HOLDERNESS INVESTMENTS CO',
    name: 'HOLDERNESS INVESTMENTS CO',
    label: 'HOLDERNESS INVESTMENTS CO',
  },
  {
    cik: '0001729304',
    value: '0001729304,HOLISTIC FINANCIAL PARTNERS',
    name: 'HOLISTIC FINANCIAL PARTNERS',
    label: 'HOLISTIC FINANCIAL PARTNERS',
  },
  {
    cik: '0001890183',
    value: '0001890183,HOLLAND ADVISORY SERVICES, INC.',
    name: 'HOLLAND ADVISORY SERVICES, INC.',
    label: 'HOLLAND ADVISORY SERVICES, INC.',
  },
  {
    cik: '0000936939',
    value: '0000936939,HOLLAND CAPITAL MANAGEMENT LLC',
    name: 'HOLLAND CAPITAL MANAGEMENT LLC',
    label: 'HOLLAND CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001161722',
    value: '0001161722,HOLLENCREST CAPITAL MANAGEMENT',
    name: 'HOLLENCREST CAPITAL MANAGEMENT',
    label: 'HOLLENCREST CAPITAL MANAGEMENT',
  },
  {
    cik: '0001535164',
    value: '0001535164,HOLLOWAY WEALTH MANAGEMENT, LLC',
    name: 'HOLLOWAY WEALTH MANAGEMENT, LLC',
    label: 'HOLLOWAY WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001455251',
    value: '0001455251,HOLLOW BROOK WEALTH MANAGEMENT LLC',
    name: 'HOLLOW BROOK WEALTH MANAGEMENT LLC',
    label: 'HOLLOW BROOK WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0001700574',
    value: '0001700574,HOLOCENE ADVISORS, LP',
    name: 'HOLOCENE ADVISORS, LP',
    label: 'HOLOCENE ADVISORS, LP',
  },
  {
    cik: '0001428569',
    value: '0001428569,HOLOWESKO PARTNERS LTD.',
    name: 'HOLOWESKO PARTNERS LTD.',
    label: 'HOLOWESKO PARTNERS LTD.',
  },
  {
    cik: '0001599852',
    value:
      '0001599852,HOLT CAPITAL ADVISORS, L.L.C. DBA HOLT CAPITAL PARTNERS, L.P.',
    name: 'HOLT CAPITAL ADVISORS, L.L.C. DBA HOLT CAPITAL PARTNERS, L.P.',
    label: 'HOLT CAPITAL ADVISORS, L.L.C. DBA HOLT CAPITAL PARTNERS, L.P.',
  },
  {
    cik: '0001665446',
    value: '0001665446,HOME FEDERAL BANK OF TENNESSEE',
    name: 'HOME FEDERAL BANK OF TENNESSEE',
    label: 'HOME FEDERAL BANK OF TENNESSEE',
  },
  {
    cik: '0000944804',
    value: '0000944804,HOMESTEAD ADVISERS CORP',
    name: 'HOMESTEAD ADVISERS CORP',
    label: 'HOMESTEAD ADVISERS CORP',
  },
  {
    cik: '0001504744',
    value: '0001504744,HOMRICH & BERG',
    name: 'HOMRICH & BERG',
    label: 'HOMRICH & BERG',
  },
  {
    cik: '0001675688',
    value: '0001675688,HONEYCOMB ASSET MANAGEMENT LP',
    name: 'HONEYCOMB ASSET MANAGEMENT LP',
    label: 'HONEYCOMB ASSET MANAGEMENT LP',
  },
  {
    cik: '0000773840',
    value: '0000773840,HONEYWELL INTERNATIONAL INC',
    name: 'HONEYWELL INTERNATIONAL INC',
    label: 'HONEYWELL INTERNATIONAL INC',
  },
  {
    cik: '0001852813',
    value: '0001852813,HONGKOU CAPITAL LP',
    name: 'HONGKOU CAPITAL LP',
    label: 'HONGKOU CAPITAL LP',
  },
  {
    cik: '0001005441',
    value: '0001005441,HONKAMP KRUEGER FINANCIAL SERVICES INC /ADV',
    name: 'HONKAMP KRUEGER FINANCIAL SERVICES INC /ADV',
    label: 'HONKAMP KRUEGER FINANCIAL SERVICES INC /ADV',
  },
  {
    cik: '0001578177',
    value: '0001578177,HOOD RIVER CAPITAL MANAGEMENT LLC',
    name: 'HOOD RIVER CAPITAL MANAGEMENT LLC',
    label: 'HOOD RIVER CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001766786',
    value: '0001766786,HOOVER FINANCIAL ADVISORS, INC.',
    name: 'HOOVER FINANCIAL ADVISORS, INC.',
    label: 'HOOVER FINANCIAL ADVISORS, INC.',
  },
  {
    cik: '0001277050',
    value: '0001277050,HOPLITE CAPITAL MANAGEMENT, L.P.',
    name: 'HOPLITE CAPITAL MANAGEMENT, L.P.',
    label: 'HOPLITE CAPITAL MANAGEMENT, L.P.',
  },
  {
    cik: '0001584086',
    value: '0001584086,HORAN CAPITAL ADVISORS, LLC.',
    name: 'HORAN CAPITAL ADVISORS, LLC.',
    label: 'HORAN CAPITAL ADVISORS, LLC.',
  },
  {
    cik: '0001269134',
    value: '0001269134,HORAN CAPITAL MANAGEMENT',
    name: 'HORAN CAPITAL MANAGEMENT',
    label: 'HORAN CAPITAL MANAGEMENT',
  },
  {
    cik: '0001671623',
    value: '0001671623,HORAN SECURITIES, INC.',
    name: 'HORAN SECURITIES, INC.',
    label: 'HORAN SECURITIES, INC.',
  },
  {
    cik: '0001762718',
    value: '0001762718,HORIKO CAPITAL MANAGEMENT LLC',
    name: 'HORIKO CAPITAL MANAGEMENT LLC',
    label: 'HORIKO CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0000706129',
    value: '0000706129,HORIZON BANCORP INC /IN/',
    name: 'HORIZON BANCORP INC /IN/',
    label: 'HORIZON BANCORP INC /IN/',
  },
  {
    cik: '0001910660',
    value: '0001910660,HORIZON FAMILY WEALTH, INC.',
    name: 'HORIZON FAMILY WEALTH, INC.',
    label: 'HORIZON FAMILY WEALTH, INC.',
  },
  {
    cik: '0001674020',
    value: '0001674020,HORIZON FINANCIAL SERVICES, LLC',
    name: 'HORIZON FINANCIAL SERVICES, LLC',
    label: 'HORIZON FINANCIAL SERVICES, LLC',
  },
  {
    cik: '0001426940',
    value: '0001426940,HORIZON INVESTMENT SERVICES, LLC',
    name: 'HORIZON INVESTMENT SERVICES, LLC',
    label: 'HORIZON INVESTMENT SERVICES, LLC',
  },
  {
    cik: '0001560717',
    value: '0001560717,HORIZON INVESTMENTS, LLC',
    name: 'HORIZON INVESTMENTS, LLC',
    label: 'HORIZON INVESTMENTS, LLC',
  },
  {
    cik: '0001056823',
    value: '0001056823,HORIZON KINETICS ASSET MANAGEMENT LLC',
    name: 'HORIZON KINETICS ASSET MANAGEMENT LLC',
    label: 'HORIZON KINETICS ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001519418',
    value: '0001519418,HORIZON KINETICS LLC',
    name: 'HORIZON KINETICS LLC',
    label: 'HORIZON KINETICS LLC',
  },
  {
    cik: '0001943228',
    value: '0001943228,HORIZONS WEALTH MANAGEMENT',
    name: 'HORIZONS WEALTH MANAGEMENT',
    label: 'HORIZONS WEALTH MANAGEMENT',
  },
  {
    cik: '0001815123',
    value: '0001815123,HORIZON WEALTH MANAGEMENT, LLC',
    name: 'HORIZON WEALTH MANAGEMENT, LLC',
    label: 'HORIZON WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001385925',
    value: '0001385925,HORRELL CAPITAL MANAGEMENT, INC.',
    name: 'HORRELL CAPITAL MANAGEMENT, INC.',
    label: 'HORRELL CAPITAL MANAGEMENT, INC.',
  },
  {
    cik: '0001340614',
    value: '0001340614,HORSEMAN CAPITAL MANAGEMENT LTD',
    name: 'HORSEMAN CAPITAL MANAGEMENT LTD',
    label: 'HORSEMAN CAPITAL MANAGEMENT LTD',
  },
  {
    cik: '0001818207',
    value: '0001818207,HORST & GRABEN WEALTH MANAGEMENT LLC',
    name: 'HORST & GRABEN WEALTH MANAGEMENT LLC',
    label: 'HORST & GRABEN WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0001575444',
    value: '0001575444,HORTON CAPITAL MANAGEMENT, LLC',
    name: 'HORTON CAPITAL MANAGEMENT, LLC',
    label: 'HORTON CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001615042',
    value: '0001615042,HOSKING & CO LTD',
    name: 'HOSKING & CO LTD',
    label: 'HOSKING & CO LTD',
  },
  {
    cik: '0001650135',
    value: '0001650135,HOSKING PARTNERS LLP',
    name: 'HOSKING PARTNERS LLP',
    label: 'HOSKING PARTNERS LLP',
  },
  {
    cik: '0001761645',
    value: '0001761645,HOTALING INVESTMENT MANAGEMENT, LLC',
    name: 'HOTALING INVESTMENT MANAGEMENT, LLC',
    label: 'HOTALING INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001164833',
    value: '0001164833,HOTCHKIS & WILEY CAPITAL MANAGEMENT LLC',
    name: 'HOTCHKIS & WILEY CAPITAL MANAGEMENT LLC',
    label: 'HOTCHKIS & WILEY CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001802879',
    value: '0001802879,HOULIHAN FINANCIAL RESOURCE GROUP, LTD.',
    name: 'HOULIHAN FINANCIAL RESOURCE GROUP, LTD.',
    label: 'HOULIHAN FINANCIAL RESOURCE GROUP, LTD.',
  },
  {
    cik: '0001353316',
    value: '0001353316,HOUND PARTNERS, LLC',
    name: 'HOUND PARTNERS, LLC',
    label: 'HOUND PARTNERS, LLC',
  },
  {
    cik: '0001442573',
    value: '0001442573,HOURGLASS CAPITAL, LLC',
    name: 'HOURGLASS CAPITAL, LLC',
    label: 'HOURGLASS CAPITAL, LLC',
  },
  {
    cik: '0001881590',
    value: '0001881590,HOUSEHOLDER GROUP ESTATE & RETIREMENT SPECIALIST, LLC',
    name: 'HOUSEHOLDER GROUP ESTATE & RETIREMENT SPECIALIST, LLC',
    label: 'HOUSEHOLDER GROUP ESTATE & RETIREMENT SPECIALIST, LLC',
  },
  {
    cik: '0001214916',
    value: '0001214916,HOVDE CAPITAL ADVISORS LLC',
    name: 'HOVDE CAPITAL ADVISORS LLC',
    label: 'HOVDE CAPITAL ADVISORS LLC',
  },
  {
    cik: '0001078658',
    value: '0001078658,HOWARD CAPITAL MANAGEMENT',
    name: 'HOWARD CAPITAL MANAGEMENT',
    label: 'HOWARD CAPITAL MANAGEMENT',
  },
  {
    cik: '0001860501',
    value: '0001860501,HOWARD CAPITAL MANAGEMENT GROUP, LLC',
    name: 'HOWARD CAPITAL MANAGEMENT GROUP, LLC',
    label: 'HOWARD CAPITAL MANAGEMENT GROUP, LLC',
  },
  {
    cik: '0001733173',
    value: '0001733173,HOWARD CAPITAL MANAGEMENT INC.',
    name: 'HOWARD CAPITAL MANAGEMENT INC.',
    label: 'HOWARD CAPITAL MANAGEMENT INC.',
  },
  {
    cik: '0001768130',
    value: '0001768130,HOWARD FINANCIAL SERVICES, LTD.',
    name: 'HOWARD FINANCIAL SERVICES, LTD.',
    label: 'HOWARD FINANCIAL SERVICES, LTD.',
  },
  {
    cik: '0000810386',
    value: '0000810386,HOWARD HUGHES MEDICAL INSTITUTE',
    name: 'HOWARD HUGHES MEDICAL INSTITUTE',
    label: 'HOWARD HUGHES MEDICAL INSTITUTE',
  },
  {
    cik: '0001766286',
    value: '0001766286,HOWARD WEALTH MANAGEMENT, LLC',
    name: 'HOWARD WEALTH MANAGEMENT, LLC',
    label: 'HOWARD WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0000769963',
    value: '0000769963,HOWE & RUSLING INC',
    name: 'HOWE & RUSLING INC',
    label: 'HOWE & RUSLING INC',
  },
  {
    cik: '0001079112',
    value: '0001079112,HOWLAND CAPITAL MANAGEMENT LLC',
    name: 'HOWLAND CAPITAL MANAGEMENT LLC',
    label: 'HOWLAND CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001497943',
    value: '0001497943,HOXTON FINANCIAL, INC.',
    name: 'HOXTON FINANCIAL, INC.',
    label: 'HOXTON FINANCIAL, INC.',
  },
  {
    cik: '0001954782',
    value: '0001954782,HOXTON PLANNING & MANAGEMENT, LLC',
    name: 'HOXTON PLANNING & MANAGEMENT, LLC',
    label: 'HOXTON PLANNING & MANAGEMENT, LLC',
  },
  {
    cik: '0001756985',
    value: '0001756985,HOYA CAPITAL REAL ESTATE, LLC',
    name: 'HOYA CAPITAL REAL ESTATE, LLC',
    label: 'HOYA CAPITAL REAL ESTATE, LLC',
  },
  {
    cik: '0001559077',
    value: '0001559077,HOYLECOHEN, LLC',
    name: 'HOYLECOHEN, LLC',
    label: 'HOYLECOHEN, LLC',
  },
  {
    cik: '0001364412',
    value: '0001364412,H PARTNERS MANAGEMENT, LLC',
    name: 'H PARTNERS MANAGEMENT, LLC',
    label: 'H PARTNERS MANAGEMENT, LLC',
  },
  {
    cik: '0001731296',
    value: '0001731296,HPC GERMANY GMBH',
    name: 'HPC GERMANY GMBH',
    label: 'HPC GERMANY GMBH',
  },
  {
    cik: '0001731296',
    value: '0001731296,HPC GERMANY GMBH & CO. KG',
    name: 'HPC GERMANY GMBH & CO. KG',
    label: 'HPC GERMANY GMBH & CO. KG',
  },
  {
    cik: '0001566475',
    value: '0001566475,HPM PARTNERS LLC',
    name: 'HPM PARTNERS LLC',
    label: 'HPM PARTNERS LLC',
  },
  {
    cik: '0001670185',
    value: '0001670185,HPS INVESTMENT PARTNERS, LLC',
    name: 'HPS INVESTMENT PARTNERS, LLC',
    label: 'HPS INVESTMENT PARTNERS, LLC',
  },
  {
    cik: '0001557689',
    value: '0001557689,HRS INVESTMENT HOLDINGS, LLC',
    name: 'HRS INVESTMENT HOLDINGS, LLC',
    label: 'HRS INVESTMENT HOLDINGS, LLC',
  },
  {
    cik: '0001475597',
    value: '0001475597,HRT FINANCIAL LLC',
    name: 'HRT FINANCIAL LLC',
    label: 'HRT FINANCIAL LLC',
  },
  {
    cik: '0001475597',
    value: '0001475597,HRT FINANCIAL LP',
    name: 'HRT FINANCIAL LP',
    label: 'HRT FINANCIAL LP',
  },
  {
    cik: '0000873630',
    value: '0000873630,HSBC HOLDINGS PLC',
    name: 'HSBC HOLDINGS PLC',
    label: 'HSBC HOLDINGS PLC',
  },
  {
    cik: '0001455253',
    value: '0001455253,HS MANAGEMENT PARTNERS, LLC',
    name: 'HS MANAGEMENT PARTNERS, LLC',
    label: 'HS MANAGEMENT PARTNERS, LLC',
  },
  {
    cik: '0001885404',
    value: '0001885404,HST VENTURES, LLC',
    name: 'HST VENTURES, LLC',
    label: 'HST VENTURES, LLC',
  },
  {
    cik: '0001552999',
    value: '0001552999,HT PARTNERS LLC',
    name: 'HT PARTNERS LLC',
    label: 'HT PARTNERS LLC',
  },
  {
    cik: '0001791555',
    value: '0001791555,HUBBELL STRICKLAND WEALTH MANAGEMENT, LLC',
    name: 'HUBBELL STRICKLAND WEALTH MANAGEMENT, LLC',
    label: 'HUBBELL STRICKLAND WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001511697',
    value: '0001511697,HUBER CAPITAL MANAGEMENT LLC',
    name: 'HUBER CAPITAL MANAGEMENT LLC',
    label: 'HUBER CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001729051',
    value: '0001729051,HUBER FINANCIAL ADVISORS, LLC',
    name: 'HUBER FINANCIAL ADVISORS, LLC',
    label: 'HUBER FINANCIAL ADVISORS, LLC',
  },
  {
    cik: '0001543536',
    value: '0001543536,HUDOCK CAPITAL GROUP, LLC',
    name: 'HUDOCK CAPITAL GROUP, LLC',
    label: 'HUDOCK CAPITAL GROUP, LLC',
  },
  {
    cik: '0001543536',
    value: '0001543536,HUDOCK, INC.',
    name: 'HUDOCK, INC.',
    label: 'HUDOCK, INC.',
  },
  {
    cik: '0001393825',
    value: '0001393825,HUDSON BAY CAPITAL MANAGEMENT LP',
    name: 'HUDSON BAY CAPITAL MANAGEMENT LP',
    label: 'HUDSON BAY CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001167026',
    value: '0001167026,HUDSON CANYON INVESTMENT COUNSELORS LLC',
    name: 'HUDSON CANYON INVESTMENT COUNSELORS LLC',
    label: 'HUDSON CANYON INVESTMENT COUNSELORS LLC',
  },
  {
    cik: '0001727342',
    value: '0001727342,HUDSON CAPITAL MANAGEMENT LLC',
    name: 'HUDSON CAPITAL MANAGEMENT LLC',
    label: 'HUDSON CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001652522',
    value: '0001652522,HUDSON EXECUTIVE CAPITAL LP',
    name: 'HUDSON EXECUTIVE CAPITAL LP',
    label: 'HUDSON EXECUTIVE CAPITAL LP',
  },
  {
    cik: '0001847700',
    value: '0001847700,HUDSON PORTFOLIO MANAGEMENT LLC',
    name: 'HUDSON PORTFOLIO MANAGEMENT LLC',
    label: 'HUDSON PORTFOLIO MANAGEMENT LLC',
  },
  {
    cik: '0001841183',
    value: '0001841183,HUDSON STRUCTURED CAPITAL MANAGEMENT LTD.',
    name: 'HUDSON STRUCTURED CAPITAL MANAGEMENT LTD.',
    label: 'HUDSON STRUCTURED CAPITAL MANAGEMENT LTD.',
  },
  {
    cik: '0000943442',
    value: '0000943442,HUDSON VALLEY INVESTMENT ADVISORS INC /ADV',
    name: 'HUDSON VALLEY INVESTMENT ADVISORS INC /ADV',
    label: 'HUDSON VALLEY INVESTMENT ADVISORS INC /ADV',
  },
  {
    cik: '0001840501',
    value: '0001840501,HUDSON VALUE PARTNERS, LLC',
    name: 'HUDSON VALUE PARTNERS, LLC',
    label: 'HUDSON VALUE PARTNERS, LLC',
  },
  {
    cik: '0001697597',
    value: '0001697597,HUDSON WAY CAPITAL MANAGEMENT LLC',
    name: 'HUDSON WAY CAPITAL MANAGEMENT LLC',
    label: 'HUDSON WAY CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001095721',
    value: '0001095721,HUFF W R ASSET MANAGEMENT CO LLC /DE/',
    name: 'HUFF W R ASSET MANAGEMENT CO LLC /DE/',
    label: 'HUFF W R ASSET MANAGEMENT CO LLC /DE/',
  },
  {
    cik: '0001336098',
    value: '0001336098,HUGH JOHNSON ADVISORS LLC',
    name: 'HUGH JOHNSON ADVISORS LLC',
    label: 'HUGH JOHNSON ADVISORS LLC',
  },
  {
    cik: '0001767513',
    value: '0001767513,HUMAN INVESTING LLC',
    name: 'HUMAN INVESTING LLC',
    label: 'HUMAN INVESTING LLC',
  },
  {
    cik: '0001845373',
    value: '0001845373,HUMANKIND INVESTMENTS LLC',
    name: 'HUMANKIND INVESTMENTS LLC',
    label: 'HUMANKIND INVESTMENTS LLC',
  },
  {
    cik: '0001802279',
    value: '0001802279,HUMMER FINANCIAL ADVISORY SERVICES INC',
    name: 'HUMMER FINANCIAL ADVISORY SERVICES INC',
    label: 'HUMMER FINANCIAL ADVISORY SERVICES INC',
  },
  {
    cik: '0001367004',
    value: '0001367004,HUMMER WINBLAD EQUITY PARTNERS V, LLC',
    name: 'HUMMER WINBLAD EQUITY PARTNERS V, LLC',
    label: 'HUMMER WINBLAD EQUITY PARTNERS V, LLC',
  },
  {
    cik: '0001380137',
    value: '0001380137,HUNTER ASSOCIATES INC.',
    name: 'HUNTER ASSOCIATES INC.',
    label: 'HUNTER ASSOCIATES INC.',
  },
  {
    cik: '0001380137',
    value: '0001380137,HUNTER ASSOCIATES INVESTMENT MANAGEMENT LLC',
    name: 'HUNTER ASSOCIATES INVESTMENT MANAGEMENT LLC',
    label: 'HUNTER ASSOCIATES INVESTMENT MANAGEMENT LLC',
  },
  {
    cik: '0001808919',
    value: '0001808919,HUNTER PERKINS CAPITAL MANAGEMENT, LLC',
    name: 'HUNTER PERKINS CAPITAL MANAGEMENT, LLC',
    label: 'HUNTER PERKINS CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001697398',
    value: '0001697398,HUNTING HILL GLOBAL CAPITAL, LLC',
    name: 'HUNTING HILL GLOBAL CAPITAL, LLC',
    label: 'HUNTING HILL GLOBAL CAPITAL, LLC',
  },
  {
    cik: '0001143565',
    value: '0001143565,HUNTINGTON ASSET ADVISORS, INC.',
    name: 'HUNTINGTON ASSET ADVISORS, INC.',
    label: 'HUNTINGTON ASSET ADVISORS, INC.',
  },
  {
    cik: '0000049196',
    value: '0000049196,HUNTINGTON BANCSHARES INC /MD/',
    name: 'HUNTINGTON BANCSHARES INC /MD/',
    label: 'HUNTINGTON BANCSHARES INC /MD/',
  },
  {
    cik: '0000049205',
    value: '0000049205,HUNTINGTON NATIONAL BANK',
    name: 'HUNTINGTON NATIONAL BANK',
    label: 'HUNTINGTON NATIONAL BANK',
  },
  {
    cik: '0001552426',
    value: '0001552426,HUNTINGTON STEELE LLC',
    name: 'HUNTINGTON STEELE LLC',
    label: 'HUNTINGTON STEELE LLC',
  },
  {
    cik: '0001661191',
    value: '0001661191,HUNT LANE CAPITAL LP',
    name: 'HUNT LANE CAPITAL LP',
    label: 'HUNT LANE CAPITAL LP',
  },
  {
    cik: '0001632844',
    value: '0001632844,HURLEY CAPITAL, LLC',
    name: 'HURLEY CAPITAL, LLC',
    label: 'HURLEY CAPITAL, LLC',
  },
  {
    cik: '0001747749',
    value: '0001747749,HURLOW WEALTH MANAGEMENT GROUP, INC.',
    name: 'HURLOW WEALTH MANAGEMENT GROUP, INC.',
    label: 'HURLOW WEALTH MANAGEMENT GROUP, INC.',
  },
  {
    cik: '0001179475',
    value: '0001179475,HUSSMAN STRATEGIC ADVISORS, INC.',
    name: 'HUSSMAN STRATEGIC ADVISORS, INC.',
    label: 'HUSSMAN STRATEGIC ADVISORS, INC.',
  },
  {
    cik: '0001054880',
    value: '0001054880,HUTCHENS INVESTMENT MANAGEMENT INC',
    name: 'HUTCHENS INVESTMENT MANAGEMENT INC',
    label: 'HUTCHENS INVESTMENT MANAGEMENT INC',
  },
  {
    cik: '0001920117',
    value: '0001920117,HUTCHENS & KRAMER INVESTMENT MANAGEMENT GROUP, LLC',
    name: 'HUTCHENS & KRAMER INVESTMENT MANAGEMENT GROUP, LLC',
    label: 'HUTCHENS & KRAMER INVESTMENT MANAGEMENT GROUP, LLC',
  },
  {
    cik: '0001482799',
    value: '0001482799,HUTCHIN HILL CAPITAL, LP',
    name: 'HUTCHIN HILL CAPITAL, LP',
    label: 'HUTCHIN HILL CAPITAL, LP',
  },
  {
    cik: '0001078246',
    value: '0001078246,HUTCHINSON CAPITAL MANAGEMENT/CA',
    name: 'HUTCHINSON CAPITAL MANAGEMENT/CA',
    label: 'HUTCHINSON CAPITAL MANAGEMENT/CA',
  },
  {
    cik: '0000944733',
    value: '0000944733,HUTNER CAPITAL MANAGEMENT INC',
    name: 'HUTNER CAPITAL MANAGEMENT INC',
    label: 'HUTNER CAPITAL MANAGEMENT INC',
  },
  {
    cik: '0001665055',
    value: '0001665055,HUTNER DANIEL ELLSWORTH',
    name: 'HUTNER DANIEL ELLSWORTH',
    label: 'HUTNER DANIEL ELLSWORTH',
  },
  {
    cik: '0001599390',
    value: '0001599390,HWG HOLDINGS LP',
    name: 'HWG HOLDINGS LP',
    label: 'HWG HOLDINGS LP',
  },
  {
    cik: '0001810676',
    value: '0001810676,HYA ADVISORS, INC',
    name: 'HYA ADVISORS, INC',
    label: 'HYA ADVISORS, INC',
  },
  {
    cik: '0001135077',
    value: '0001135077,HYMAN CHARLES D',
    name: 'HYMAN CHARLES D',
    label: 'HYMAN CHARLES D',
  },
  {
    cik: '0001307203',
    value: '0001307203,HYNES, HIMMELREICH, GLENNON & CO, LLC',
    name: 'HYNES, HIMMELREICH, GLENNON & CO, LLC',
    label: 'HYNES, HIMMELREICH, GLENNON & CO, LLC',
  },
  {
    cik: '0001619844',
    value: '0001619844,HYPERION ASSET MANAGEMENT LTD',
    name: 'HYPERION ASSET MANAGEMENT LTD',
    label: 'HYPERION ASSET MANAGEMENT LTD',
  },
  {
    cik: '0001794499',
    value: '0001794499,HYPERION CAPITAL ADVISORS LP',
    name: 'HYPERION CAPITAL ADVISORS LP',
    label: 'HYPERION CAPITAL ADVISORS LP',
  },
  {
    cik: '0001892772',
    value: '0001892772,HYUNDAI INVESTMENTS CO., LTD.',
    name: 'HYUNDAI INVESTMENTS CO., LTD.',
    label: 'HYUNDAI INVESTMENTS CO., LTD.',
  },
  {
    cik: '0001510201',
    value: '0001510201,IAB FINANCIAL BANK',
    name: 'IAB FINANCIAL BANK',
    label: 'IAB FINANCIAL BANK',
  },
  {
    cik: '0001827261',
    value: '0001827261,IAG WEALTH PARTNERS, LLC',
    name: 'IAG WEALTH PARTNERS, LLC',
    label: 'IAG WEALTH PARTNERS, LLC',
  },
  {
    cik: '0001825292',
    value: '0001825292,IAM ADVISORY, LLC',
    name: 'IAM ADVISORY, LLC',
    label: 'IAM ADVISORY, LLC',
  },
  {
    cik: '0001633625',
    value: '0001633625,IAT REINSURANCE CO LTD.',
    name: 'IAT REINSURANCE CO LTD.',
    label: 'IAT REINSURANCE CO LTD.',
  },
  {
    cik: '0001684985',
    value: '0001684985,IA VENTURE PARTNERS, LLC',
    name: 'IA VENTURE PARTNERS, LLC',
    label: 'IA VENTURE PARTNERS, LLC',
  },
  {
    cik: '0001530237',
    value: '0001530237,IA VENTURE STRATEGIES FUND II, LP',
    name: 'IA VENTURE STRATEGIES FUND II, LP',
    label: 'IA VENTURE STRATEGIES FUND II, LP',
  },
  {
    cik: '0001092688',
    value: '0001092688,IBBOTSON ASSOCIATES INC',
    name: 'IBBOTSON ASSOCIATES INC',
    label: 'IBBOTSON ASSOCIATES INC',
  },
  {
    cik: '0000933141',
    value: '0000933141,IBERIABANK CORP',
    name: 'IBERIABANK CORP',
    label: 'IBERIABANK CORP',
  },
  {
    cik: '0001531964',
    value: '0001531964,IBEX INVESTORS LLC',
    name: 'IBEX INVESTORS LLC',
    label: 'IBEX INVESTORS LLC',
  },
  {
    cik: '0001686242',
    value: '0001686242,IBEX WEALTH ADVISORS',
    name: 'IBEX WEALTH ADVISORS',
    label: 'IBEX WEALTH ADVISORS',
  },
  {
    cik: '0001599371',
    value: '0001599371,IBIS CAPITAL PARTNERS LLP',
    name: 'IBIS CAPITAL PARTNERS LLP',
    label: 'IBIS CAPITAL PARTNERS LLP',
  },
  {
    cik: '0001018825',
    value: '0001018825,IBM RETIREMENT FUND',
    name: 'IBM RETIREMENT FUND',
    label: 'IBM RETIREMENT FUND',
  },
  {
    cik: '0001944579',
    value: '0001944579,ICA GROUP WEALTH MANAGEMENT, LLC',
    name: 'ICA GROUP WEALTH MANAGEMENT, LLC',
    label: 'ICA GROUP WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0000921669',
    value: '0000921669,ICAHN CARL C',
    name: 'ICAHN CARL C',
    label: 'ICAHN CARL C',
  },
  {
    cik: '0001911091',
    value: '0001911091,ICAPITAL WEALTH LLC',
    name: 'ICAPITAL WEALTH LLC',
    label: 'ICAPITAL WEALTH LLC',
  },
  {
    cik: '0001002342',
    value: '0001002342,ICC CAPITAL MANAGEMENT INC/',
    name: 'ICC CAPITAL MANAGEMENT INC/',
    label: 'ICC CAPITAL MANAGEMENT INC/',
  },
  {
    cik: '0001683411',
    value: '0001683411,ICE POND LANE ADVISERS, LLC',
    name: 'ICE POND LANE ADVISERS, LLC',
    label: 'ICE POND LANE ADVISERS, LLC',
  },
  {
    cik: '0001697850',
    value: '0001697850,ICICI PRUDENTIAL ASSET MANAGEMENT CO LTD',
    name: 'ICICI PRUDENTIAL ASSET MANAGEMENT CO LTD',
    label: 'ICICI PRUDENTIAL ASSET MANAGEMENT CO LTD',
  },
  {
    cik: '0000905608',
    value: '0000905608,ICM ASSET MANAGEMENT INC/WA',
    name: 'ICM ASSET MANAGEMENT INC/WA',
    label: 'ICM ASSET MANAGEMENT INC/WA',
  },
  {
    cik: '0001058470',
    value: '0001058470,ICON ADVISERS INC/CO',
    name: 'ICON ADVISERS INC/CO',
    label: 'ICON ADVISERS INC/CO',
  },
  {
    cik: '0001569709',
    value: '0001569709,ICONIQ CAPITAL, LLC',
    name: 'ICONIQ CAPITAL, LLC',
    label: 'ICONIQ CAPITAL, LLC',
  },
  {
    cik: '0001718732',
    value: '0001718732,ICON WEALTH PARTNERS, LLC',
    name: 'ICON WEALTH PARTNERS, LLC',
    label: 'ICON WEALTH PARTNERS, LLC',
  },
  {
    cik: '0001794820',
    value: '0001794820,ICW INVESTMENT ADVISORS LLC',
    name: 'ICW INVESTMENT ADVISORS LLC',
    label: 'ICW INVESTMENT ADVISORS LLC',
  },
  {
    cik: '0001500732',
    value: '0001500732,IDG-ACCEL CHINA CAPITAL ASSOCIATES L.P.',
    name: 'IDG-ACCEL CHINA CAPITAL ASSOCIATES L.P.',
    label: 'IDG-ACCEL CHINA CAPITAL ASSOCIATES L.P.',
  },
  {
    cik: '0001846013',
    value: '0001846013,IDG-ACCEL CHINA CAPITAL GP II ASSOCIATES LTD.',
    name: 'IDG-ACCEL CHINA CAPITAL GP II ASSOCIATES LTD.',
    label: 'IDG-ACCEL CHINA CAPITAL GP II ASSOCIATES LTD.',
  },
  {
    cik: '0001500731',
    value: '0001500731,IDG-ACCEL CHINA GROWTH FUND ASSOCIATES L.P.',
    name: 'IDG-ACCEL CHINA GROWTH FUND ASSOCIATES L.P.',
    label: 'IDG-ACCEL CHINA GROWTH FUND ASSOCIATES L.P.',
  },
  {
    cik: '0001550192',
    value: '0001550192,IDG-ACCEL CHINA GROWTH FUND III ASSOCIATES L.P.',
    name: 'IDG-ACCEL CHINA GROWTH FUND III ASSOCIATES L.P.',
    label: 'IDG-ACCEL CHINA GROWTH FUND III ASSOCIATES L.P.',
  },
  {
    cik: '0001609021',
    value: '0001609021,IDG CHINA VENTURE CAPITAL FUND IV ASSOCIATES L.P.',
    name: 'IDG CHINA VENTURE CAPITAL FUND IV ASSOCIATES L.P.',
    label: 'IDG CHINA VENTURE CAPITAL FUND IV ASSOCIATES L.P.',
  },
  {
    cik: '0001709671',
    value: '0001709671,IDG CHINA VENTURE CAPITAL FUND V ASSOCIATES L.P.',
    name: 'IDG CHINA VENTURE CAPITAL FUND V ASSOCIATES L.P.',
    label: 'IDG CHINA VENTURE CAPITAL FUND V ASSOCIATES L.P.',
  },
  {
    cik: '0001600246',
    value: '0001600246,IDG TECHNOLOGY VENTURE INVESTMENT III, LLC',
    name: 'IDG TECHNOLOGY VENTURE INVESTMENT III, LLC',
    label: 'IDG TECHNOLOGY VENTURE INVESTMENT III, LLC',
  },
  {
    cik: '0001879976',
    value: '0001879976,IDG TECHNOLOGY VENTURE INVESTMENT IV, LLC',
    name: 'IDG TECHNOLOGY VENTURE INVESTMENT IV, LLC',
    label: 'IDG TECHNOLOGY VENTURE INVESTMENT IV, LLC',
  },
  {
    cik: '0001571626',
    value: '0001571626,ID MANAGEMENT A/S',
    name: 'ID MANAGEMENT A/S',
    label: 'ID MANAGEMENT A/S',
  },
  {
    cik: '0001779789',
    value: '0001779789,IEQ CAPITAL, LLC',
    name: 'IEQ CAPITAL, LLC',
    label: 'IEQ CAPITAL, LLC',
  },
  {
    cik: '0001492375',
    value: '0001492375,IFC HOLDINGS INCORPORATED/FL',
    name: 'IFC HOLDINGS INCORPORATED/FL',
    label: 'IFC HOLDINGS INCORPORATED/FL',
  },
  {
    cik: '0001783412',
    value: '0001783412,IFG ADVISORS, LLC',
    name: 'IFG ADVISORS, LLC',
    label: 'IFG ADVISORS, LLC',
  },
  {
    cik: '0001728319',
    value: '0001728319,IFG ADVISORY, LLC',
    name: 'IFG ADVISORY, LLC',
    label: 'IFG ADVISORY, LLC',
  },
  {
    cik: '0001698246',
    value: '0001698246,IFM INVESTORS PTY LTD',
    name: 'IFM INVESTORS PTY LTD',
    label: 'IFM INVESTORS PTY LTD',
  },
  {
    cik: '0001641866',
    value: '0001641866,IFP ADVISORS, INC',
    name: 'IFP ADVISORS, INC',
    label: 'IFP ADVISORS, INC',
  },
  {
    cik: '0001382646',
    value: '0001382646,IFRAH FINANCIAL SERVICES, INC.',
    name: 'IFRAH FINANCIAL SERVICES, INC.',
    label: 'IFRAH FINANCIAL SERVICES, INC.',
  },
  {
    cik: '0001765617',
    value: '0001765617,IFS ADVISORS, LLC',
    name: 'IFS ADVISORS, LLC',
    label: 'IFS ADVISORS, LLC',
  },
  {
    cik: '0000898581',
    value: '0000898581,I.G. INVESTMENT MANAGEMENT, LTD.',
    name: 'I.G. INVESTMENT MANAGEMENT, LTD.',
    label: 'I.G. INVESTMENT MANAGEMENT, LTD.',
  },
  {
    cik: '0001536355',
    value: '0001536355,IGNIS INVESTMENT SERVICES LTD',
    name: 'IGNIS INVESTMENT SERVICES LTD',
    label: 'IGNIS INVESTMENT SERVICES LTD',
  },
  {
    cik: '0001849348',
    value: '0001849348,IGNITE PLANNERS, LLC',
    name: 'IGNITE PLANNERS, LLC',
    label: 'IGNITE PLANNERS, LLC',
  },
  {
    cik: '0001597549',
    value: '0001597549,IGUANA HEALTHCARE MANAGEMENT, LLC',
    name: 'IGUANA HEALTHCARE MANAGEMENT, LLC',
    label: 'IGUANA HEALTHCARE MANAGEMENT, LLC',
  },
  {
    cik: '0001811472',
    value: '000181147,I.G.Y. LTD',
    name: 'I.G.Y. LTD',
    label: 'I.G.Y. LTD',
  },
  {
    cik: '0001697490',
    value: '0001697490,IHT WEALTH MANAGEMENT, LLC',
    name: 'IHT WEALTH MANAGEMENT, LLC',
    label: 'IHT WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001783139',
    value: '0001783139,III CAPITAL MANAGEMENT',
    name: 'III CAPITAL MANAGEMENT',
    label: 'III CAPITAL MANAGEMENT',
  },
  {
    cik: '0001778253',
    value: '0001778253,IKARIAN CAPITAL, LLC',
    name: 'IKARIAN CAPITAL, LLC',
    label: 'IKARIAN CAPITAL, LLC',
  },
  {
    cik: '0001372270',
    value: '0001372270,IKOS CIF LTD',
    name: 'IKOS CIF LTD',
    label: 'IKOS CIF LTD',
  },
  {
    cik: '0001541910',
    value: '0001541910,ILLINOIS MUNICIPAL RETIREMENT FUND',
    name: 'ILLINOIS MUNICIPAL RETIREMENT FUND',
    label: 'ILLINOIS MUNICIPAL RETIREMENT FUND',
  },
  {
    cik: '0001661580',
    value: '0001661580,ILMARINEN MUTUAL PENSION INSURANCE CO',
    name: 'ILMARINEN MUTUAL PENSION INSURANCE CO',
    label: 'ILMARINEN MUTUAL PENSION INSURANCE CO',
  },
  {
    cik: '0001455495',
    value: '0001455495,IMA WEALTH, INC.',
    name: 'IMA WEALTH, INC.',
    label: 'IMA WEALTH, INC.',
  },
  {
    cik: '0001452861',
    value: '0001452861,IMC-CHICAGO, LLC',
    name: 'IMC-CHICAGO, LLC',
    label: 'IMC-CHICAGO, LLC',
  },
  {
    cik: '0001636441',
    value: '0001636441,IMMERSION CAPITAL LLP',
    name: 'IMMERSION CAPITAL LLP',
    label: 'IMMERSION CAPITAL LLP',
  },
  {
    cik: '0001896447',
    value: '0001896447,IMPACTFOLIO, LLC',
    name: 'IMPACTFOLIO, LLC',
    label: 'IMPACTFOLIO, LLC',
  },
  {
    cik: '0001786767',
    value: '0001786767,IMPACTIVE CAPITAL LP',
    name: 'IMPACTIVE CAPITAL LP',
    label: 'IMPACTIVE CAPITAL LP',
  },
  {
    cik: '0001317679',
    value: '0001317679,IMPALA ASSET MANAGEMENT LLC',
    name: 'IMPALA ASSET MANAGEMENT LLC',
    label: 'IMPALA ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001634155',
    value: '0001634155,IMPAX ASSET MANAGEMENT (AIFM) LTD',
    name: 'IMPAX ASSET MANAGEMENT (AIFM) LTD',
    label: 'IMPAX ASSET MANAGEMENT (AIFM) LTD',
  },
  {
    cik: '0001749768',
    value: '0001749768,IMPAX ASSET MANAGEMENT GROUP PLC',
    name: 'IMPAX ASSET MANAGEMENT GROUP PLC',
    label: 'IMPAX ASSET MANAGEMENT GROUP PLC',
  },
  {
    cik: '0001127990',
    value: '0001127990,IMPAX ASSET MANAGEMENT LLC',
    name: 'IMPAX ASSET MANAGEMENT LLC',
    label: 'IMPAX ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001428948',
    value: '0001428948,IMPAX ASSET MANAGEMENT LTD',
    name: 'IMPAX ASSET MANAGEMENT LTD',
    label: 'IMPAX ASSET MANAGEMENT LTD',
  },
  {
    cik: '0001343916',
    value: '0001343916,IMS CAPITAL MANAGEMENT',
    name: 'IMS CAPITAL MANAGEMENT',
    label: 'IMS CAPITAL MANAGEMENT',
  },
  {
    cik: '0001513137',
    value: '0001513137,INCA INVESTMENTS LLC',
    name: 'INCA INVESTMENTS LLC',
    label: 'INCA INVESTMENTS LLC',
  },
  {
    cik: '0001567195',
    value: '0001567195,INCLINE GLOBAL MANAGEMENT LLC',
    name: 'INCLINE GLOBAL MANAGEMENT LLC',
    label: 'INCLINE GLOBAL MANAGEMENT LLC',
  },
  {
    cik: '0001817187',
    value: '0001817187,INCLUSIVE CAPITAL PARTNERS, L.P.',
    name: 'INCLUSIVE CAPITAL PARTNERS, L.P.',
    label: 'INCLUSIVE CAPITAL PARTNERS, L.P.',
  },
  {
    cik: '0001165830',
    value: '0001165830,INCOME RESEARCH & MANAGEMENT',
    name: 'INCOME RESEARCH & MANAGEMENT',
    label: 'INCOME RESEARCH & MANAGEMENT',
  },
  {
    cik: '0001524362',
    value: '0001524362,INDABA CAPITAL MANAGEMENT, L.P.',
    name: 'INDABA CAPITAL MANAGEMENT, L.P.',
    label: 'INDABA CAPITAL MANAGEMENT, L.P.',
  },
  {
    cik: '0001679118',
    value: '0001679118,INDEPENDENCE ADVISORS, LLC',
    name: 'INDEPENDENCE ADVISORS, LLC',
    label: 'INDEPENDENCE ADVISORS, LLC',
  },
  {
    cik: '0001730479',
    value: '0001730479,INDEPENDENCE BANK OF KENTUCKY',
    name: 'INDEPENDENCE BANK OF KENTUCKY',
    label: 'INDEPENDENCE BANK OF KENTUCKY',
  },
  {
    cik: '0001348904',
    value: '0001348904,INDEPENDENCE CAPITAL ASSET PARTNERS, LLC',
    name: 'INDEPENDENCE CAPITAL ASSET PARTNERS, LLC',
    label: 'INDEPENDENCE CAPITAL ASSET PARTNERS, LLC',
  },
  {
    cik: '0001317103',
    value: '0001317103,INDEPENDENCE TRUST CO',
    name: 'INDEPENDENCE TRUST CO',
    label: 'INDEPENDENCE TRUST CO',
  },
  {
    cik: '0001732846',
    value: '0001732846,INDEPENDENCE WEALTH ADVISORS, INC.',
    name: 'INDEPENDENCE WEALTH ADVISORS, INC.',
    label: 'INDEPENDENCE WEALTH ADVISORS, INC.',
  },
  {
    cik: '0001696899',
    value: '0001696899,INDEPENDENT ADVISOR ALLIANCE',
    name: 'INDEPENDENT ADVISOR ALLIANCE',
    label: 'INDEPENDENT ADVISOR ALLIANCE',
  },
  {
    cik: '0001756485',
    value: '0001756485,INDEPENDENT FAMILY OFFICE, LLC',
    name: 'INDEPENDENT FAMILY OFFICE, LLC',
    label: 'INDEPENDENT FAMILY OFFICE, LLC',
  },
  {
    cik: '0001483866',
    value: '0001483866,INDEPENDENT FRANCHISE PARTNERS LLP',
    name: 'INDEPENDENT FRANCHISE PARTNERS LLP',
    label: 'INDEPENDENT FRANCHISE PARTNERS LLP',
  },
  {
    cik: '0001121908',
    value: '0001121908,INDEPENDENT INVESTORS INC',
    name: 'INDEPENDENT INVESTORS INC',
    label: 'INDEPENDENT INVESTORS INC',
  },
  {
    cik: '0001538037',
    value: '0001538037,INDEPENDENT ORDER OF FORESTERS',
    name: 'INDEPENDENT ORDER OF FORESTERS',
    label: 'INDEPENDENT ORDER OF FORESTERS',
  },
  {
    cik: '0001328450',
    value: '0001328450,INDEPENDENT PORTFOLIO CONSULTANTS, INC.',
    name: 'INDEPENDENT PORTFOLIO CONSULTANTS, INC.',
    label: 'INDEPENDENT PORTFOLIO CONSULTANTS, INC.',
  },
  {
    cik: '0001891240',
    value: '0001891240,INDEPENDENT WEALTH NETWORK INC.',
    name: 'INDEPENDENT WEALTH NETWORK INC.',
    label: 'INDEPENDENT WEALTH NETWORK INC.',
  },
  {
    cik: '0001908316',
    value: '0001908316,IN-DEPTH PARTNERS, LLC',
    name: 'IN-DEPTH PARTNERS, LLC',
    label: 'IN-DEPTH PARTNERS, LLC',
  },
  {
    cik: '0001665097',
    value: '0001665097,INDEX FUND ADVISORS, INC.',
    name: 'INDEX FUND ADVISORS, INC.',
    label: 'INDEX FUND ADVISORS, INC.',
  },
  {
    cik: '0001415996',
    value: '0001415996,INDEXIQ ADVISORS LLC',
    name: 'INDEXIQ ADVISORS LLC',
    label: 'INDEXIQ ADVISORS LLC',
  },
  {
    cik: '0001579671',
    value: '0001579671,INDEX MANAGEMENT SOLUTIONS, LLC',
    name: 'INDEX MANAGEMENT SOLUTIONS, LLC',
    label: 'INDEX MANAGEMENT SOLUTIONS, LLC',
  },
  {
    cik: '0001503683',
    value: '0001503683,INDEX VENTURE ASSOCIATES III LTD',
    name: 'INDEX VENTURE ASSOCIATES III LTD',
    label: 'INDEX VENTURE ASSOCIATES III LTD',
  },
  {
    cik: '0001447962',
    value: '0001447962,INDEX VENTURE ASSOCIATES IV LTD',
    name: 'INDEX VENTURE ASSOCIATES IV LTD',
    label: 'INDEX VENTURE ASSOCIATES IV LTD',
  },
  {
    cik: '0001755035',
    value: '0001755035,INDEX VENTURE ASSOCIATES VI LTD',
    name: 'INDEX VENTURE ASSOCIATES VI LTD',
    label: 'INDEX VENTURE ASSOCIATES VI LTD',
  },
  {
    cik: '0001611751',
    value: '0001611751,INDEX VENTURE ASSOCIATES V LTD',
    name: 'INDEX VENTURE ASSOCIATES V LTD',
    label: 'INDEX VENTURE ASSOCIATES V LTD',
  },
  {
    cik: '0001785453',
    value: '0001785453,INDEX VENTURE GROWTH ASSOCIATES III LTD',
    name: 'INDEX VENTURE GROWTH ASSOCIATES III LTD',
    label: 'INDEX VENTURE GROWTH ASSOCIATES III LTD',
  },
  {
    cik: '0001736898',
    value: '0001736898,INDEX VENTURE GROWTH ASSOCIATES II LTD',
    name: 'INDEX VENTURE GROWTH ASSOCIATES II LTD',
    label: 'INDEX VENTURE GROWTH ASSOCIATES II LTD',
  },
  {
    cik: '0001447961',
    value: '0001447961,INDEX VENTURE GROWTH ASSOCIATES I LTD',
    name: 'INDEX VENTURE GROWTH ASSOCIATES I LTD',
    label: 'INDEX VENTURE GROWTH ASSOCIATES I LTD',
  },
  {
    cik: '0001865139',
    value: '0001865139,INDEX VENTURE LIFE ASSOCIATES VI LTD',
    name: 'INDEX VENTURE LIFE ASSOCIATES VI LTD',
    label: 'INDEX VENTURE LIFE ASSOCIATES VI LTD',
  },
  {
    cik: '0001356407',
    value: '0001356407,INDIANA TRUST & INVESTMENT MANAGEMENT CO',
    name: 'INDIANA TRUST & INVESTMENT MANAGEMENT CO',
    label: 'INDIANA TRUST & INVESTMENT MANAGEMENT CO',
  },
  {
    cik: '0001449781',
    value: '0001449781,INDICES-PAC RESEARCH CORP.',
    name: 'INDICES-PAC RESEARCH CORP.',
    label: 'INDICES-PAC RESEARCH CORP.',
  },
  {
    cik: '0001802244',
    value: '0001802244,INDIE ASSET PARTNERS, LLC',
    name: 'INDIE ASSET PARTNERS, LLC',
    label: 'INDIE ASSET PARTNERS, LLC',
  },
  {
    cik: '0001652253',
    value: '0001652253,INDIGO LATAM MANAGEMENT LLC',
    name: 'INDIGO LATAM MANAGEMENT LLC',
    label: 'INDIGO LATAM MANAGEMENT LLC',
  },
  {
    cik: '0001846122',
    value: '0001846122,INDIGO PARTNERS LLC',
    name: 'INDIGO PARTNERS LLC',
    label: 'INDIGO PARTNERS LLC',
  },
  {
    cik: '0001350879',
    value: '0001350879,INDUS CAPITAL PARTNERS, LLC',
    name: 'INDUS CAPITAL PARTNERS, LLC',
    label: 'INDUS CAPITAL PARTNERS, LLC',
  },
  {
    cik: '0001630798',
    value: '0001630798,INDUSTRIAL ALLIANCE INVESTMENT MANAGEMENT INC.',
    name: 'INDUSTRIAL ALLIANCE INVESTMENT MANAGEMENT INC.',
    label: 'INDUSTRIAL ALLIANCE INVESTMENT MANAGEMENT INC.',
  },
  {
    cik: '0001811532',
    value: '0001811532,INDUSTRY VENTURES, L.L.C.',
    name: 'INDUSTRY VENTURES, L.L.C.',
    label: 'INDUSTRY VENTURES, L.L.C.',
  },
  {
    cik: '0001936249',
    value: '0001936249,INFINI CAPITAL MANAGEMENT LTD',
    name: 'INFINI CAPITAL MANAGEMENT LTD',
    label: 'INFINI CAPITAL MANAGEMENT LTD',
  },
  {
    cik: '0001842683',
    value: '0001842683,INFINI MASTER FUND',
    name: 'INFINI MASTER FUND',
    label: 'INFINI MASTER FUND',
  },
  {
    cik: '0001621802',
    value: '0001621802,INFRASTRUCTURE CAPITAL ADVISORS, LLC',
    name: 'INFRASTRUCTURE CAPITAL ADVISORS, LLC',
    label: 'INFRASTRUCTURE CAPITAL ADVISORS, LLC',
  },
  {
    cik: '0001749333',
    value: '0001749333,INFUSIVE ASSET MANAGEMENT INC.',
    name: 'INFUSIVE ASSET MANAGEMENT INC.',
    label: 'INFUSIVE ASSET MANAGEMENT INC.',
  },
  {
    cik: '0001041885',
    value: '0001041885,INGALLS & SNYDER LLC',
    name: 'INGALLS & SNYDER LLC',
    label: 'INGALLS & SNYDER LLC',
  },
  {
    cik: '0001039765',
    value: '0001039765,ING GROEP NV',
    name: 'ING GROEP NV',
    label: 'ING GROEP NV',
  },
  {
    cik: '0001068837',
    value: '0001068837,ING INVESTMENT MANAGEMENT LLC',
    name: 'ING INVESTMENT MANAGEMENT LLC',
    label: 'ING INVESTMENT MANAGEMENT LLC',
  },
  {
    cik: '0001749824',
    value: '0001749824,INHERENT GROUP, LP',
    name: 'INHERENT GROUP, LP',
    label: 'INHERENT GROUP, LP',
  },
  {
    cik: '0001759751',
    value: '0001759751,INLET PRIVATE WEALTH, LLC',
    name: 'INLET PRIVATE WEALTH, LLC',
    label: 'INLET PRIVATE WEALTH, LLC',
  },
  {
    cik: '0001752579',
    value: '0001752579,INNEALTA CAPITAL, LLC',
    name: 'INNEALTA CAPITAL, LLC',
    label: 'INNEALTA CAPITAL, LLC',
  },
  {
    cik: '0001860981',
    value: '0001860981,INNOVATIVE PORTFOLIOS',
    name: 'INNOVATIVE PORTFOLIOS',
    label: 'INNOVATIVE PORTFOLIOS',
  },
  {
    cik: '0001888831',
    value: '0001888831,INNOVA WEALTH PARTNERS',
    name: 'INNOVA WEALTH PARTNERS',
    label: 'INNOVA WEALTH PARTNERS',
  },
  {
    cik: '0001913494',
    value: '0001913494,INNOVIUS CAPITAL MANAGEMENT, LLC',
    name: 'INNOVIUS CAPITAL MANAGEMENT, LLC',
    label: 'INNOVIUS CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001535477',
    value: '0001535477,INR ADVISORY SERVICES LLC',
    name: 'INR ADVISORY SERVICES LLC',
    label: 'INR ADVISORY SERVICES LLC',
  },
  {
    cik: '0001767868',
    value: '0001767868,INSCRIPTION CAPITAL, LLC',
    name: 'INSCRIPTION CAPITAL, LLC',
    label: 'INSCRIPTION CAPITAL, LLC',
  },
  {
    cik: '0001334952',
    value: '0001334952,INSIGHT 2811, INC.',
    name: 'INSIGHT 2811, INC.',
    label: 'INSIGHT 2811, INC.',
  },
  {
    cik: '0001844880',
    value: '0001844880,INSIGHT ADVISORS, LLC/ PA',
    name: 'INSIGHT ADVISORS, LLC/ PA',
    label: 'INSIGHT ADVISORS, LLC/ PA',
  },
  {
    cik: '0000938506',
    value: '0000938506,INSIGHT CAPITAL RESEARCH & MANAGEMENT INC',
    name: 'INSIGHT CAPITAL RESEARCH & MANAGEMENT INC',
    label: 'INSIGHT CAPITAL RESEARCH & MANAGEMENT INC',
  },
  {
    cik: '0001576953',
    value: '0001576953,INSIGHT EQUITY MANAGEMENT CO LLC',
    name: 'INSIGHT EQUITY MANAGEMENT CO LLC',
    label: 'INSIGHT EQUITY MANAGEMENT CO LLC',
  },
  {
    cik: '0001792942',
    value: '0001792942,INSIGHT FOLIOS INC',
    name: 'INSIGHT FOLIOS INC',
    label: 'INSIGHT FOLIOS INC',
  },
  {
    cik: '0001305473',
    value: '0001305473,INSIGHT HOLDINGS GROUP, LLC',
    name: 'INSIGHT HOLDINGS GROUP, LLC',
    label: 'INSIGHT HOLDINGS GROUP, LLC',
  },
  {
    cik: '0001909322',
    value: '0001909322,INSIGHT INV LLC',
    name: 'INSIGHT INV LLC',
    label: 'INSIGHT INV LLC',
  },
  {
    cik: '0001627003',
    value: '0001627003,INSIGHT WEALTH PARTNERS, LLC',
    name: 'INSIGHT WEALTH PARTNERS, LLC',
    label: 'INSIGHT WEALTH PARTNERS, LLC',
  },
  {
    cik: '0001749914',
    value: '0001749914,INSIGHT WEALTH STRATEGIES, LLC',
    name: 'INSIGHT WEALTH STRATEGIES, LLC',
    label: 'INSIGHT WEALTH STRATEGIES, LLC',
  },
  {
    cik: '0001810558',
    value: '0001810558,INSPIRE ADVISORS, LLC',
    name: 'INSPIRE ADVISORS, LLC',
    label: 'INSPIRE ADVISORS, LLC',
  },
  {
    cik: '0001935942',
    value: '0001935942,INSPIRE DEVELOPERS LLC',
    name: 'INSPIRE DEVELOPERS LLC',
    label: 'INSPIRE DEVELOPERS LLC',
  },
  {
    cik: '0001670139',
    value: '0001670139,INSPIRE INVESTING, LLC',
    name: 'INSPIRE INVESTING, LLC',
    label: 'INSPIRE INVESTING, LLC',
  },
  {
    cik: '0001633448',
    value: '0001633448,INSPIRION WEALTH ADVISORS, LLC',
    name: 'INSPIRION WEALTH ADVISORS, LLC',
    label: 'INSPIRION WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001533954',
    value: '0001533954,INSTITUTE FOR WEALTH MANAGEMENT, LLC.',
    name: 'INSTITUTE FOR WEALTH MANAGEMENT, LLC.',
    label: 'INSTITUTE FOR WEALTH MANAGEMENT, LLC.',
  },
  {
    cik: '0000050672',
    value: '0000050672,INSTITUTIONAL CAPITAL LLC',
    name: 'INSTITUTIONAL CAPITAL LLC',
    label: 'INSTITUTIONAL CAPITAL LLC',
  },
  {
    cik: '0001673815',
    value: '0001673815,INSTITUTIONAL & FAMILY ASSET MANAGEMENT, LLC',
    name: 'INSTITUTIONAL & FAMILY ASSET MANAGEMENT, LLC',
    label: 'INSTITUTIONAL & FAMILY ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001493921',
    value: '0001493921,INSTITUTIONAL VENTURE MANAGEMENT XIII, LLC',
    name: 'INSTITUTIONAL VENTURE MANAGEMENT XIII, LLC',
    label: 'INSTITUTIONAL VENTURE MANAGEMENT XIII, LLC',
  },
  {
    cik: '0001412031',
    value: '0001412031,INSTITUTIONAL VENTURE MANAGEMENT XII, LLC',
    name: 'INSTITUTIONAL VENTURE MANAGEMENT XII, LLC',
    label: 'INSTITUTIONAL VENTURE MANAGEMENT XII, LLC',
  },
  {
    cik: '0001550304',
    value: '0001550304,INSTITUTIONAL VENTURE MANAGEMENT XIV, LLC',
    name: 'INSTITUTIONAL VENTURE MANAGEMENT XIV, LLC',
    label: 'INSTITUTIONAL VENTURE MANAGEMENT XIV, LLC',
  },
  {
    cik: '0001716997',
    value: '0001716997,INSTITUTIONAL VENTURE MANAGEMENT XVI, LLC',
    name: 'INSTITUTIONAL VENTURE MANAGEMENT XVI, LLC',
    label: 'INSTITUTIONAL VENTURE MANAGEMENT XVI, LLC',
  },
  {
    cik: '0001640275',
    value: '0001640275,INSTITUTIONAL VENTURE MANAGEMENT XV, LLC',
    name: 'INSTITUTIONAL VENTURE MANAGEMENT XV, LLC',
    label: 'INSTITUTIONAL VENTURE MANAGEMENT XV, LLC',
  },
  {
    cik: '0001385426',
    value: '0001385426,INTACT FINANCIAL CORP',
    name: 'INTACT FINANCIAL CORP',
    label: 'INTACT FINANCIAL CORP',
  },
  {
    cik: '0001443077',
    value: '0001443077,INTACT INVESTMENT MANAGEMENT INC.',
    name: 'INTACT INVESTMENT MANAGEMENT INC.',
    label: 'INTACT INVESTMENT MANAGEMENT INC.',
  },
  {
    cik: '0001086318',
    value: '0001086318,INTECH INVESTMENT MANAGEMENT LLC',
    name: 'INTECH INVESTMENT MANAGEMENT LLC',
    label: 'INTECH INVESTMENT MANAGEMENT LLC',
  },
  {
    cik: '0001802098',
    value: '0001802098,INTEGER WEALTH ADVISORS GROUP LLC',
    name: 'INTEGER WEALTH ADVISORS GROUP LLC',
    label: 'INTEGER WEALTH ADVISORS GROUP LLC',
  },
  {
    cik: '0001451428',
    value: '0001451428,INTEGRAL DERIVATIVES, LLC',
    name: 'INTEGRAL DERIVATIVES, LLC',
    label: 'INTEGRAL DERIVATIVES, LLC',
  },
  {
    cik: '0001773206',
    value: '0001773206,INTEGRAL HEALTH ASSET MANAGEMENT, LLC',
    name: 'INTEGRAL HEALTH ASSET MANAGEMENT, LLC',
    label: 'INTEGRAL HEALTH ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001902826',
    value: '0001902826,INTEGRAL INVESTMENT ADVISORS, INC.',
    name: 'INTEGRAL INVESTMENT ADVISORS, INC.',
    label: 'INTEGRAL INVESTMENT ADVISORS, INC.',
  },
  {
    cik: '0001660177',
    value: '0001660177,INTEGRATED ADVISORS NETWORK LLC',
    name: 'INTEGRATED ADVISORS NETWORK LLC',
    label: 'INTEGRATED ADVISORS NETWORK LLC',
  },
  {
    cik: '0001608826',
    value: '0001608826,INTEGRATED CAPITAL MANAGEMENT, INC.',
    name: 'INTEGRATED CAPITAL MANAGEMENT, INC.',
    label: 'INTEGRATED CAPITAL MANAGEMENT, INC.',
  },
  {
    cik: '0001665337',
    value: '0001665337,INTEGRATED INVESTMENT CONSULTANTS, LLC',
    name: 'INTEGRATED INVESTMENT CONSULTANTS, LLC',
    label: 'INTEGRATED INVESTMENT CONSULTANTS, LLC',
  },
  {
    cik: '0001737109',
    value: '0001737109,INTEGRATED WEALTH CONCEPTS LLC',
    name: 'INTEGRATED WEALTH CONCEPTS LLC',
    label: 'INTEGRATED WEALTH CONCEPTS LLC',
  },
  {
    cik: '0001110443',
    value: '0001110443,INTEGRATED WEALTH COUNSEL, LLC',
    name: 'INTEGRATED WEALTH COUNSEL, LLC',
    label: 'INTEGRATED WEALTH COUNSEL, LLC',
  },
  {
    cik: '0001640250',
    value: '0001640250,INTEGRATED WEALTH MANAGEMENT',
    name: 'INTEGRATED WEALTH MANAGEMENT',
    label: 'INTEGRATED WEALTH MANAGEMENT',
  },
  {
    cik: '0001352306',
    value: '0001352306,INTEGRE ADVISORS',
    name: 'INTEGRE ADVISORS',
    label: 'INTEGRE ADVISORS',
  },
  {
    cik: '0001908685',
    value: '0001908685,INTEGRITY ADVISORY, LLC',
    name: 'INTEGRITY ADVISORY, LLC',
    label: 'INTEGRITY ADVISORY, LLC',
  },
  {
    cik: '0001910842',
    value: '0001910842,INTEGRITY CAPITAL MANAGEMENT (HK) LTD',
    name: 'INTEGRITY CAPITAL MANAGEMENT (HK) LTD',
    label: 'INTEGRITY CAPITAL MANAGEMENT (HK) LTD',
  },
  {
    cik: '0001917704',
    value: '0001917704,INTEGRITY FINANCIAL CORP /WA',
    name: 'INTEGRITY FINANCIAL CORP /WA',
    label: 'INTEGRITY FINANCIAL CORP /WA',
  },
  {
    cik: '0001633448',
    value: '0001633448,INTEGRYS WEALTH ADVISORS LLC',
    name: 'INTEGRYS WEALTH ADVISORS LLC',
    label: 'INTEGRYS WEALTH ADVISORS LLC',
  },
  {
    cik: '0000050863',
    value: '000005086,INTEL CORP',
    name: 'INTEL CORP',
    label: 'INTEL CORP',
  },
  {
    cik: '0001677253',
    value: '0001677253,INTELLECTUS PARTNERS, LLC',
    name: 'INTELLECTUS PARTNERS, LLC',
    label: 'INTELLECTUS PARTNERS, LLC',
  },
  {
    cik: '0001819955',
    value: '0001819955,INTELLIGENCE DRIVEN ADVISERS, LLC',
    name: 'INTELLIGENCE DRIVEN ADVISERS, LLC',
    label: 'INTELLIGENCE DRIVEN ADVISERS, LLC',
  },
  {
    cik: '0001911000',
    value: '0001911000,INTELLIGENT FINANCIAL STRATEGIES',
    name: 'INTELLIGENT FINANCIAL STRATEGIES',
    label: 'INTELLIGENT FINANCIAL STRATEGIES',
  },
  {
    cik: '0001419099',
    value: '0001419099,INTERACTIVE FINANCIAL ADVISORS',
    name: 'INTERACTIVE FINANCIAL ADVISORS',
    label: 'INTERACTIVE FINANCIAL ADVISORS',
  },
  {
    cik: '0001845998',
    value: '0001845998,INTERCHANGE CAPITAL PARTNERS, LLC',
    name: 'INTERCHANGE CAPITAL PARTNERS, LLC',
    label: 'INTERCHANGE CAPITAL PARTNERS, LLC',
  },
  {
    cik: '0001709698',
    value: '0001709698,INTERCONTINENTAL WEALTH ADVISORS, LLC',
    name: 'INTERCONTINENTAL WEALTH ADVISORS, LLC',
    label: 'INTERCONTINENTAL WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001911342',
    value: '0001911342,INTERGY PRIVATE WEALTH, LLC',
    name: 'INTERGY PRIVATE WEALTH, LLC',
    label: 'INTERGY PRIVATE WEALTH, LLC',
  },
  {
    cik: '0001664713',
    value: '0001664713,INTERMEDE INVESTMENT PARTNERS LTD',
    name: 'INTERMEDE INVESTMENT PARTNERS LTD',
    label: 'INTERMEDE INVESTMENT PARTNERS LTD',
  },
  {
    cik: '0001818604',
    value: '0001818604,INTERNATIONAL ASSETS INVESTMENT MANAGEMENT, LLC',
    name: 'INTERNATIONAL ASSETS INVESTMENT MANAGEMENT, LLC',
    label: 'INTERNATIONAL ASSETS INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0000942257',
    value: '0000942257,INTERNATIONAL BIOTECHNOLOGY TRUST PLC',
    name: 'INTERNATIONAL BIOTECHNOLOGY TRUST PLC',
    label: 'INTERNATIONAL BIOTECHNOLOGY TRUST PLC',
  },
  {
    cik: '0001691813',
    value:
      '0001691813,INTERNATIONAL CITY MANAGEMENT ASSOCIATION RETIREMENT CORP',
    name: 'INTERNATIONAL CITY MANAGEMENT ASSOCIATION RETIREMENT CORP',
    label: 'INTERNATIONAL CITY MANAGEMENT ASSOCIATION RETIREMENT CORP',
  },
  {
    cik: '0001456417',
    value: '0001456417,INTERNATIONAL VALUE ADVISERS, LLC',
    name: 'INTERNATIONAL VALUE ADVISERS, LLC',
    label: 'INTERNATIONAL VALUE ADVISERS, LLC',
  },
  {
    cik: '0001841616',
    value: '0001841616,INTEROCEAN CAPITAL GROUP, LLC',
    name: 'INTEROCEAN CAPITAL GROUP, LLC',
    label: 'INTEROCEAN CAPITAL GROUP, LLC',
  },
  {
    cik: '0001537703',
    value: '0001537703,INTEROCEAN CAPITAL, LLC',
    name: 'INTEROCEAN CAPITAL, LLC',
    label: 'INTEROCEAN CAPITAL, LLC',
  },
  {
    cik: '0001633861',
    value: '0001633861,INTERSECT CAPITAL LLC',
    name: 'INTERSECT CAPITAL LLC',
    label: 'INTERSECT CAPITAL LLC',
  },
  {
    cik: '0001590228',
    value: '0001590228,INTERVAL PARTNERS, LLC',
    name: 'INTERVAL PARTNERS, LLC',
    label: 'INTERVAL PARTNERS, LLC',
  },
  {
    cik: '0001590228',
    value: '0001590228,INTERVAL PARTNERS, LP',
    name: 'INTERVAL PARTNERS, LP',
    label: 'INTERVAL PARTNERS, LP',
  },
  {
    cik: '0001697720',
    value: '0001697720,INTERWEST VENTURE MANAGEMENT CO',
    name: 'INTERWEST VENTURE MANAGEMENT CO',
    label: 'INTERWEST VENTURE MANAGEMENT CO',
  },
  {
    cik: '0000913760',
    value: '0000913760,INTL FCSTONE INC.',
    name: 'INTL FCSTONE INC.',
    label: 'INTL FCSTONE INC.',
  },
  {
    cik: '0001847343',
    value: '0001847343,INTRACK INVESTMENT MANAGEMENT INC',
    name: 'INTRACK INVESTMENT MANAGEMENT INC',
    label: 'INTRACK INVESTMENT MANAGEMENT INC',
  },
  {
    cik: '0001092838',
    value: '0001092838,INTREPID CAPITAL MANAGEMENT INC',
    name: 'INTREPID CAPITAL MANAGEMENT INC',
    label: 'INTREPID CAPITAL MANAGEMENT INC',
  },
  {
    cik: '0001134459',
    value: '0001134459,INTREPID CAPITAL MANAGEMENT INC /DE/',
    name: 'INTREPID CAPITAL MANAGEMENT INC /DE/',
    label: 'INTREPID CAPITAL MANAGEMENT INC /DE/',
  },
  {
    cik: '0001596197',
    value: '0001596197,INTREPID FAMILY OFFICE LLC',
    name: 'INTREPID FAMILY OFFICE LLC',
    label: 'INTREPID FAMILY OFFICE LLC',
  },
  {
    cik: '0001641296',
    value: '0001641296,INTREPID FINANCIAL PLANNING GROUP LLC',
    name: 'INTREPID FINANCIAL PLANNING GROUP LLC',
    label: 'INTREPID FINANCIAL PLANNING GROUP LLC',
  },
  {
    cik: '0001671754',
    value: '0001671754,INTRINSIC EDGE CAPITAL MANAGEMENT LLC',
    name: 'INTRINSIC EDGE CAPITAL MANAGEMENT LLC',
    label: 'INTRINSIC EDGE CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001910364',
    value: '0001910364,INTRINSIC VALUE PARTNERS, LLC',
    name: 'INTRINSIC VALUE PARTNERS, LLC',
    label: 'INTRINSIC VALUE PARTNERS, LLC',
  },
  {
    cik: '0001812792',
    value: '0001812792,INTRUA FINANCIAL, LLC',
    name: 'INTRUA FINANCIAL, LLC',
    label: 'INTRUA FINANCIAL, LLC',
  },
  {
    cik: '0001034771',
    value: '0001034771,INTRUST BANK NA',
    name: 'INTRUST BANK NA',
    label: 'INTRUST BANK NA',
  },
  {
    cik: '0001726823',
    value: '0001726823,INVENIRE PARTNERS, LP',
    name: 'INVENIRE PARTNERS, LP',
    label: 'INVENIRE PARTNERS, LP',
  },
  {
    cik: '0001769456',
    value: '0001769456,INVENOMIC CAPITAL MANAGEMENT LP',
    name: 'INVENOMIC CAPITAL MANAGEMENT LP',
    label: 'INVENOMIC CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001307748',
    value: '0001307748,INVENTRUST PROPERTIES CORP.',
    name: 'INVENTRUST PROPERTIES CORP.',
    label: 'INVENTRUST PROPERTIES CORP.',
  },
  {
    cik: '0000866361',
    value: '0000866361,INVERNESS COUNSEL INC /NY/',
    name: 'INVERNESS COUNSEL INC /NY/',
    label: 'INVERNESS COUNSEL INC /NY/',
  },
  {
    cik: '0000866361',
    value: '0000866361,INVERNESS COUNSEL LLC /NY/',
    name: 'INVERNESS COUNSEL LLC /NY/',
    label: 'INVERNESS COUNSEL LLC /NY/',
  },
  {
    cik: '0001657660',
    value: '0001657660,INVERNESS INVESTMENT GROUP, LLC',
    name: 'INVERNESS INVESTMENT GROUP, LLC',
    label: 'INVERNESS INVESTMENT GROUP, LLC',
  },
  {
    cik: '0000914208',
    value: '0000914208,INVESCO LTD.',
    name: 'INVESCO LTD.',
    label: 'INVESCO LTD.',
  },
  {
    cik: '0001128452',
    value: '0001128452,INVESCO PRIVATE CAPITAL, INC.',
    name: 'INVESCO PRIVATE CAPITAL, INC.',
    label: 'INVESCO PRIVATE CAPITAL, INC.',
  },
  {
    cik: '0000870481',
    value: '0000870481,INVESCO SENIOR SECURED MANAGEMENT INC /ADV',
    name: 'INVESCO SENIOR SECURED MANAGEMENT INC /ADV',
    label: 'INVESCO SENIOR SECURED MANAGEMENT INC /ADV',
  },
  {
    cik: '0001418329',
    value: '0001418329,INVESTEC ASSET MANAGEMENT LTD',
    name: 'INVESTEC ASSET MANAGEMENT LTD',
    label: 'INVESTEC ASSET MANAGEMENT LTD',
  },
  {
    cik: '0001633343',
    value: '0001633343,INVESTEC ASSET MANAGEMENT NORTH AMERICA, INC.',
    name: 'INVESTEC ASSET MANAGEMENT NORTH AMERICA, INC.',
    label: 'INVESTEC ASSET MANAGEMENT NORTH AMERICA, INC.',
  },
  {
    cik: '0001491998',
    value: '0001491998,INVESTEC ASSET MANAGEMENT (PTY) LTD',
    name: 'INVESTEC ASSET MANAGEMENT (PTY) LTD',
    label: 'INVESTEC ASSET MANAGEMENT (PTY) LTD',
  },
  {
    cik: '0001953154',
    value: '0001953154,INVESTIDOR PROFISSIONAL GESTAO DE RECURSOS LTDA.',
    name: 'INVESTIDOR PROFISSIONAL GESTAO DE RECURSOS LTDA.',
    label: 'INVESTIDOR PROFISSIONAL GESTAO DE RECURSOS LTDA.',
  },
  {
    cik: '0001926349',
    value: '0001926349,INVESTMARK ADVISORY GROUP LLC',
    name: 'INVESTMARK ADVISORY GROUP LLC',
    label: 'INVESTMARK ADVISORY GROUP LLC',
  },
  {
    cik: '0001910273',
    value: '0001910273,INVESTMENT ADVISORY GROUP, LLC',
    name: 'INVESTMENT ADVISORY GROUP, LLC',
    label: 'INVESTMENT ADVISORY GROUP, LLC',
  },
  {
    cik: '0000925953',
    value: '0000925953,INVESTMENT ADVISORY SERVICES INC /TX /ADV',
    name: 'INVESTMENT ADVISORY SERVICES INC /TX /ADV',
    label: 'INVESTMENT ADVISORY SERVICES INC /TX /ADV',
  },
  {
    cik: '0001569606',
    value:
      '0001569606,INVESTMENTAKTIENGESELLSCHAFT FUER LANGFRISTIGE INVESTOREN TGV',
    name: 'INVESTMENTAKTIENGESELLSCHAFT FUER LANGFRISTIGE INVESTOREN TGV',
    label: 'INVESTMENTAKTIENGESELLSCHAFT FUER LANGFRISTIGE INVESTOREN TGV',
  },
  {
    cik: '0001351904',
    value: '0001351904,INVESTMENT CENTERS OF AMERICA INC',
    name: 'INVESTMENT CENTERS OF AMERICA INC',
    label: 'INVESTMENT CENTERS OF AMERICA INC',
  },
  {
    cik: '0001670053',
    value: '0001670053,INVESTMENT COUNSEL INC.',
    name: 'INVESTMENT COUNSEL INC.',
    label: 'INVESTMENT COUNSEL INC.',
  },
  {
    cik: '0000051964',
    value: '0000051964,INVESTMENT COUNSELORS OF MARYLAND LLC',
    name: 'INVESTMENT COUNSELORS OF MARYLAND LLC',
    label: 'INVESTMENT COUNSELORS OF MARYLAND LLC',
  },
  {
    cik: '0001566030',
    value: '0001566030,INVESTMENT HOUSE LLC',
    name: 'INVESTMENT HOUSE LLC',
    label: 'INVESTMENT HOUSE LLC',
  },
  {
    cik: '0000052024',
    value: '0000052024,INVESTMENT MANAGEMENT ASSOCIATES INC /ADV',
    name: 'INVESTMENT MANAGEMENT ASSOCIATES INC /ADV',
    label: 'INVESTMENT MANAGEMENT ASSOCIATES INC /ADV',
  },
  {
    cik: '0001811568',
    value: '0001811568,INVESTMENT MANAGEMENT CORP OF ONTARIO',
    name: 'INVESTMENT MANAGEMENT CORP OF ONTARIO',
    label: 'INVESTMENT MANAGEMENT CORP OF ONTARIO',
  },
  {
    cik: '0001009022',
    value: '0001009022,INVESTMENT MANAGEMENT OF VIRGINIA LLC',
    name: 'INVESTMENT MANAGEMENT OF VIRGINIA LLC',
    label: 'INVESTMENT MANAGEMENT OF VIRGINIA LLC',
  },
  {
    cik: '0001050068',
    value: '0001050068,INVESTMENT PARTNERS, LTD.',
    name: 'INVESTMENT PARTNERS, LTD.',
    label: 'INVESTMENT PARTNERS, LTD.',
  },
  {
    cik: '0001050068',
    value:
      '0001050068,INVESTMENT PARTNERS LTD /OH/                            /ADV',
    name: 'INVESTMENT PARTNERS LTD /OH/                            /ADV',
    label: 'INVESTMENT PARTNERS LTD /OH/                            /ADV',
  },
  {
    cik: '0001802136',
    value: '0001802136,INVESTMENT RESEARCH & ADVISORY GROUP, INC.',
    name: 'INVESTMENT RESEARCH & ADVISORY GROUP, INC.',
    label: 'INVESTMENT RESEARCH & ADVISORY GROUP, INC.',
  },
  {
    cik: '0000892914',
    value: '0000892914,INVESTMENT SECURITY GROUP INC /CO/ /ADV',
    name: 'INVESTMENT SECURITY GROUP INC /CO/ /ADV',
    label: 'INVESTMENT SECURITY GROUP INC /CO/ /ADV',
  },
  {
    cik: '0001909307',
    value: '0001909307,INVESTMENTS & FINANCIAL PLANNING, LLC',
    name: 'INVESTMENTS & FINANCIAL PLANNING, LLC',
    label: 'INVESTMENTS & FINANCIAL PLANNING, LLC',
  },
  {
    cik: '0000889232',
    value: '0000889232,INVESTOR AB',
    name: 'INVESTOR AB',
    label: 'INVESTOR AB',
  },
  {
    cik: '0000867926',
    value: '0000867926,INVESTORS ASSET MANAGEMENT OF GEORGIA INC /GA/ /ADV',
    name: 'INVESTORS ASSET MANAGEMENT OF GEORGIA INC /GA/ /ADV',
    label: 'INVESTORS ASSET MANAGEMENT OF GEORGIA INC /GA/ /ADV',
  },
  {
    cik: '0001427271',
    value: '0001427271,INVESTORS CAPITAL ADVISORY SERVICES',
    name: 'INVESTORS CAPITAL ADVISORY SERVICES',
    label: 'INVESTORS CAPITAL ADVISORY SERVICES',
  },
  {
    cik: '0001800535',
    value: '0001800535,INVESTORS FINANCIAL GROUP, LLC',
    name: 'INVESTORS FINANCIAL GROUP, LLC',
    label: 'INVESTORS FINANCIAL GROUP, LLC',
  },
  {
    cik: '0001730630',
    value: '0001730630,INVESTORS RESEARCH CORP',
    name: 'INVESTORS RESEARCH CORP',
    label: 'INVESTORS RESEARCH CORP',
  },
  {
    cik: '0001590481',
    value: '0001590481,INVESTORS TRUST INC',
    name: 'INVESTORS TRUST INC',
    label: 'INVESTORS TRUST INC',
  },
  {
    cik: '0001476993',
    value: '0001476993,INVESTURE, LLC',
    name: 'INVESTURE, LLC',
    label: 'INVESTURE, LLC',
  },
  {
    cik: '0001511172',
    value: '0001511172,INVICTA CAPITAL MANAGEMENT, LLC',
    name: 'INVICTA CAPITAL MANAGEMENT, LLC',
    label: 'INVICTA CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001660389',
    value: '0001660389,INVICTUS RG',
    name: 'INVICTUS RG',
    label: 'INVICTUS RG',
  },
  {
    cik: '0001547494',
    value: '0001547494,INVICTUS RG PTE. LTD.',
    name: 'INVICTUS RG PTE. LTD.',
    label: 'INVICTUS RG PTE. LTD.',
  },
  {
    cik: '0001867587',
    value: '000186758,INVST, LLC',
    name: 'INVST, LLC',
    label: 'INVST, LLC',
  },
  {
    cik: '0001802978',
    value: '0001802978,INVUS FINANCIAL ADVISORS, LLC',
    name: 'INVUS FINANCIAL ADVISORS, LLC',
    label: 'INVUS FINANCIAL ADVISORS, LLC',
  },
  {
    cik: '0001844573',
    value: '0001844573,ION ACQUISITION CORP GP LTD.',
    name: 'ION ACQUISITION CORP GP LTD.',
    label: 'ION ACQUISITION CORP GP LTD.',
  },
  {
    cik: '0001603837',
    value: '0001603837,ION ASSET MANAGEMENT LTD.',
    name: 'ION ASSET MANAGEMENT LTD.',
    label: 'ION ASSET MANAGEMENT LTD.',
  },
  {
    cik: '0001803307',
    value: '0001803307,ION CROSSOVER PARTNERS LTD',
    name: 'ION CROSSOVER PARTNERS LTD',
    label: 'ION CROSSOVER PARTNERS LTD',
  },
  {
    cik: '0001386462',
    value: '0001386462,IONIC CAPITAL MANAGEMENT LLC',
    name: 'IONIC CAPITAL MANAGEMENT LLC',
    label: 'IONIC CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001546967',
    value: '0001546967,IOWA STATE BANK',
    name: 'IOWA STATE BANK',
    label: 'IOWA STATE BANK',
  },
  {
    cik: '0001303275',
    value: '0001303275,IPC/NYCG LLC',
    name: 'IPC/NYCG LLC',
    label: 'IPC/NYCG LLC',
  },
  {
    cik: '0001602237',
    value: '0001602237,IPG INVESTMENT ADVISORS LLC',
    name: 'IPG INVESTMENT ADVISORS LLC',
    label: 'IPG INVESTMENT ADVISORS LLC',
  },
  {
    cik: '0001696561',
    value: '0001696561,IPL ADVISERS, LLC',
    name: 'IPL ADVISERS, LLC',
    label: 'IPL ADVISERS, LLC',
  },
  {
    cik: '0001463753',
    value: '0001463753,IPSWICH INVESTMENT MANAGEMENT CO., INC.',
    name: 'IPSWICH INVESTMENT MANAGEMENT CO., INC.',
    label: 'IPSWICH INVESTMENT MANAGEMENT CO., INC.',
  },
  {
    cik: '0001620943',
    value: '0001620943,IQ EQ FUND MANAGEMENT (IRELAND) LTD',
    name: 'IQ EQ FUND MANAGEMENT (IRELAND) LTD',
    label: 'IQ EQ FUND MANAGEMENT (IRELAND) LTD',
  },
  {
    cik: '0001033427',
    value: '0001033427,IRIDIAN ASSET MANAGEMENT LLC/CT',
    name: 'IRIDIAN ASSET MANAGEMENT LLC/CT',
    label: 'IRIDIAN ASSET MANAGEMENT LLC/CT',
  },
  {
    cik: '0001273960',
    value: '0001273960,IRONBRIDGE CAPITAL MANAGEMENT LP',
    name: 'IRONBRIDGE CAPITAL MANAGEMENT LP',
    label: 'IRONBRIDGE CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001850996',
    value: '0001850996,IRONBRIDGE PRIVATE WEALTH, LLC',
    name: 'IRONBRIDGE PRIVATE WEALTH, LLC',
    label: 'IRONBRIDGE PRIVATE WEALTH, LLC',
  },
  {
    cik: '0001595509',
    value: '0001595509,IRON FINANCIAL, LLC',
    name: 'IRON FINANCIAL, LLC',
    label: 'IRON FINANCIAL, LLC',
  },
  {
    cik: '0001836270',
    value: '0001836270,IRON HORSE WEALTH MANAGEMENT, LLC',
    name: 'IRON HORSE WEALTH MANAGEMENT, LLC',
    label: 'IRON HORSE WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001845360',
    value: '0001845360,IRON PARK CAPITAL PARTNERS, LP',
    name: 'IRON PARK CAPITAL PARTNERS, LP',
    label: 'IRON PARK CAPITAL PARTNERS, LP',
  },
  {
    cik: '0001615135',
    value: '0001615135,IRONSIDES ASSET ADVISORS, LLC',
    name: 'IRONSIDES ASSET ADVISORS, LLC',
    label: 'IRONSIDES ASSET ADVISORS, LLC',
  },
  {
    cik: '0001600478',
    value: '0001600478,IRONSIDES PARTNERS LLC',
    name: 'IRONSIDES PARTNERS LLC',
    label: 'IRONSIDES PARTNERS LLC',
  },
  {
    cik: '0001779763',
    value: '0001779763,IRON TRIANGLE PARTNERS LP',
    name: 'IRON TRIANGLE PARTNERS LP',
    label: 'IRON TRIANGLE PARTNERS LP',
  },
  {
    cik: '0001730478',
    value: '0001730478,IRONVINE CAPITAL PARTNERS, LLC',
    name: 'IRONVINE CAPITAL PARTNERS, LLC',
    label: 'IRONVINE CAPITAL PARTNERS, LLC',
  },
  {
    cik: '0001630360',
    value: '0001630360,IRONWOOD FINANCIAL, LLC',
    name: 'IRONWOOD FINANCIAL, LLC',
    label: 'IRONWOOD FINANCIAL, LLC',
  },
  {
    cik: '0001330463',
    value: '0001330463,IRONWOOD INVESTMENT COUNSEL, LLC',
    name: 'IRONWOOD INVESTMENT COUNSEL, LLC',
    label: 'IRONWOOD INVESTMENT COUNSEL, LLC',
  },
  {
    cik: '0001127761',
    value: '0001127761,IRONWOOD INVESTMENT MANAGEMENT LLC',
    name: 'IRONWOOD INVESTMENT MANAGEMENT LLC',
    label: 'IRONWOOD INVESTMENT MANAGEMENT LLC',
  },
  {
    cik: '0001844821',
    value: '0001844821,IRONWOOD WEALTH MANAGEMENT, INC.',
    name: 'IRONWOOD WEALTH MANAGEMENT, INC.',
    label: 'IRONWOOD WEALTH MANAGEMENT, INC.',
  },
  {
    cik: '0001630360',
    value: '0001630360,IRONWOOD WEALTH MANAGEMENT, LLC',
    name: 'IRONWOOD WEALTH MANAGEMENT, LLC',
    label: 'IRONWOOD WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001692820',
    value: '0001692820,IRONWOOD WEALTH MANAGEMENT, LLC.',
    name: 'IRONWOOD WEALTH MANAGEMENT, LLC.',
    label: 'IRONWOOD WEALTH MANAGEMENT, LLC.',
  },
  {
    cik: '0001540425',
    value: '0001540425,IRVING MAGEE INVESTMENT MANAGEMENT',
    name: 'IRVING MAGEE INVESTMENT MANAGEMENT',
    label: 'IRVING MAGEE INVESTMENT MANAGEMENT',
  },
  {
    cik: '0001600141',
    value: '0001600141,ISF MANAGEMENT LLC',
    name: 'ISF MANAGEMENT LLC',
    label: 'ISF MANAGEMENT LLC',
  },
  {
    cik: '0001738560',
    value: '0001738560,ISLAY CAPITAL MANAGEMENT, LLC',
    name: 'ISLAY CAPITAL MANAGEMENT, LLC',
    label: 'ISLAY CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001721508',
    value: '0001721508,ISLET MANAGEMENT, LP',
    name: 'ISLET MANAGEMENT, LP',
    label: 'ISLET MANAGEMENT, LP',
  },
  {
    cik: '0001764008',
    value: '0001764008,ISOMER PARTNERS LP',
    name: 'ISOMER PARTNERS LP',
    label: 'ISOMER PARTNERS LP',
  },
  {
    cik: '0001706511',
    value: '0001706511,ISRAEL DISCOUNT BANK OF NEW YORK',
    name: 'ISRAEL DISCOUNT BANK OF NEW YORK',
    label: 'ISRAEL DISCOUNT BANK OF NEW YORK',
  },
  {
    cik: '0001639666',
    value: '0001639666,ISTHMUS PARTNERS, LLC',
    name: 'ISTHMUS PARTNERS, LLC',
    label: 'ISTHMUS PARTNERS, LLC',
  },
  {
    cik: '0001484190',
    value: '0001484190,ISZO CAPITAL LP',
    name: 'ISZO CAPITAL LP',
    label: 'ISZO CAPITAL LP',
  },
  {
    cik: '0001607925',
    value: '0001607925,ISZO CAPITAL MANAGEMENT LP',
    name: 'ISZO CAPITAL MANAGEMENT LP',
    label: 'ISZO CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001132597',
    value: '0001132597,ITAU UNIBANCO HOLDING S.A.',
    name: 'ITAU UNIBANCO HOLDING S.A.',
    label: 'ITAU UNIBANCO HOLDING S.A.',
  },
  {
    cik: '0001541597',
    value: '0001541597,ITAU USA ASSET MANAGEMENT INC.',
    name: 'ITAU USA ASSET MANAGEMENT INC.',
    label: 'ITAU USA ASSET MANAGEMENT INC.',
  },
  {
    cik: '0001484043',
    value: '0001484043,ITHAKA GROUP LLC',
    name: 'ITHAKA GROUP LLC',
    label: 'ITHAKA GROUP LLC',
  },
  {
    cik: '0001645889',
    value: '0001645889,ITS ASSET MANAGEMENT, L.P.',
    name: 'ITS ASSET MANAGEMENT, L.P.',
    label: 'ITS ASSET MANAGEMENT, L.P.',
  },
  {
    cik: '0001842811',
    value: '0001842811,IVC WEALTH ADVISORS LLC',
    name: 'IVC WEALTH ADVISORS LLC',
    label: 'IVC WEALTH ADVISORS LLC',
  },
  {
    cik: '0001116544',
    value: '0001116544,IVORY INVESTMENT MANAGEMENT, LLC',
    name: 'IVORY INVESTMENT MANAGEMENT, LLC',
    label: 'IVORY INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001839851',
    value: '0001839851,IVY LANE CAPITAL MANAGEMENT, LLC',
    name: 'IVY LANE CAPITAL MANAGEMENT, LLC',
    label: 'IVY LANE CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001809525',
    value: '0001809525,IVYROCK ASSET MANAGEMENT (HK) LTD',
    name: 'IVYROCK ASSET MANAGEMENT (HK) LTD',
    label: 'IVYROCK ASSET MANAGEMENT (HK) LTD',
  },
  {
    cik: '0001890435',
    value: '0001890435,IYO BANK, LTD.',
    name: 'IYO BANK, LTD.',
    label: 'IYO BANK, LTD.',
  },
  {
    cik: '0001586882',
    value: '0001586882,J2 CAPITAL MANAGEMENT INC',
    name: 'J2 CAPITAL MANAGEMENT INC',
    label: 'J2 CAPITAL MANAGEMENT INC',
  },
  {
    cik: '0001594167',
    value: '0001594167,JABODON PT CO',
    name: 'JABODON PT CO',
    label: 'JABODON PT CO',
  },
  {
    cik: '0001429895',
    value: '0001429895,JABRE CAPITAL PARTNERS S.A.',
    name: 'JABRE CAPITAL PARTNERS S.A.',
    label: 'JABRE CAPITAL PARTNERS S.A.',
  },
  {
    cik: '0001831003',
    value: '0001831003,JACKSON CREEK INVESTMENT ADVISORS LLC',
    name: 'JACKSON CREEK INVESTMENT ADVISORS LLC',
    label: 'JACKSON CREEK INVESTMENT ADVISORS LLC',
  },
  {
    cik: '0001730475',
    value: '0001730475,JACKSON FINANCIAL MANAGEMENT',
    name: 'JACKSON FINANCIAL MANAGEMENT',
    label: 'JACKSON FINANCIAL MANAGEMENT',
  },
  {
    cik: '0001650300',
    value: '0001650300,JACKSON, GRANT INVESTMENT ADVISERS, INC.',
    name: 'JACKSON, GRANT INVESTMENT ADVISERS, INC.',
    label: 'JACKSON, GRANT INVESTMENT ADVISERS, INC.',
  },
  {
    cik: '0001911159',
    value: '0001911159,JACKSON HILL ADVISORS, LLC',
    name: 'JACKSON HILL ADVISORS, LLC',
    label: 'JACKSON HILL ADVISORS, LLC',
  },
  {
    cik: '0001767049',
    value: '0001767049,JACKSON HOLE CAPITAL PARTNERS, LLC',
    name: 'JACKSON HOLE CAPITAL PARTNERS, LLC',
    label: 'JACKSON HOLE CAPITAL PARTNERS, LLC',
  },
  {
    cik: '0001632560',
    value: '0001632560,JACKSON PARK CAPITAL, LLC',
    name: 'JACKSON PARK CAPITAL, LLC',
    label: 'JACKSON PARK CAPITAL, LLC',
  },
  {
    cik: '0001812178',
    value: '0001812178,JACKSON SQUARE CAPITAL, LLC',
    name: 'JACKSON SQUARE CAPITAL, LLC',
    label: 'JACKSON SQUARE CAPITAL, LLC',
  },
  {
    cik: '0001605911',
    value: '0001605911,JACKSON SQUARE PARTNERS, LLC',
    name: 'JACKSON SQUARE PARTNERS, LLC',
    label: 'JACKSON SQUARE PARTNERS, LLC',
  },
  {
    cik: '0001903880',
    value: '0001903880,JACKSON THORNTON ASSET MANAGEMENT, LLC',
    name: 'JACKSON THORNTON ASSET MANAGEMENT, LLC',
    label: 'JACKSON THORNTON ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001536799',
    value: '0001536799,JACKSON WEALTH MANAGEMENT, LLC',
    name: 'JACKSON WEALTH MANAGEMENT, LLC',
    label: 'JACKSON WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001279256',
    value: '0001279256,JACOB ASSET MANAGEMENT OF NEW YORK LLC',
    name: 'JACOB ASSET MANAGEMENT OF NEW YORK LLC',
    label: 'JACOB ASSET MANAGEMENT OF NEW YORK LLC',
  },
  {
    cik: '0001689646',
    value: '0001689646,JACOBI CAPITAL MANAGEMENT LLC',
    name: 'JACOBI CAPITAL MANAGEMENT LLC',
    label: 'JACOBI CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001351063',
    value: '0001351063,JACOBS ASSET MANAGEMENT, LLC',
    name: 'JACOBS ASSET MANAGEMENT, LLC',
    label: 'JACOBS ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001055544',
    value: '0001055544,JACOBS & CO/CA',
    name: 'JACOBS & CO/CA',
    label: 'JACOBS & CO/CA',
  },
  {
    cik: '0001782491',
    value: '0001782491,JACOBSEN CAPITAL MANAGEMENT',
    name: 'JACOBSEN CAPITAL MANAGEMENT',
    label: 'JACOBSEN CAPITAL MANAGEMENT',
  },
  {
    cik: '0000884414',
    value: '0000884414,JACOBS LEVY EQUITY MANAGEMENT, INC',
    name: 'JACOBS LEVY EQUITY MANAGEMENT, INC',
    label: 'JACOBS LEVY EQUITY MANAGEMENT, INC',
  },
  {
    cik: '0001632096',
    value: '0001632096,JACOBSON & SCHMITT ADVISORS, LLC',
    name: 'JACOBSON & SCHMITT ADVISORS, LLC',
    label: 'JACOBSON & SCHMITT ADVISORS, LLC',
  },
  {
    cik: '0001501542',
    value: '0001501542,JACOBUS WEALTH MANAGEMENT, INC.',
    name: 'JACOBUS WEALTH MANAGEMENT, INC.',
    label: 'JACOBUS WEALTH MANAGEMENT, INC.',
  },
  {
    cik: '0001761663',
    value: '0001761663,JADE CAPITAL ADVISORS, LLC',
    name: 'JADE CAPITAL ADVISORS, LLC',
    label: 'JADE CAPITAL ADVISORS, LLC',
  },
  {
    cik: '0001404652',
    value: '0001404652,JAFFETILCHIN INVESTMENT PARTNERS, LLC',
    name: 'JAFFETILCHIN INVESTMENT PARTNERS, LLC',
    label: 'JAFFETILCHIN INVESTMENT PARTNERS, LLC',
  },
  {
    cik: '0001692623',
    value: '0001692623,JAFRA CAPITAL MANAGEMENT LP',
    name: 'JAFRA CAPITAL MANAGEMENT LP',
    label: 'JAFRA CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001080374',
    value: '0001080374,JAG CAPITAL MANAGEMENT, LLC',
    name: 'JAG CAPITAL MANAGEMENT, LLC',
    label: 'JAG CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001767141',
    value: '0001767141,JAGUAR LISTED PROPERTY LLC',
    name: 'JAGUAR LISTED PROPERTY LLC',
    label: 'JAGUAR LISTED PROPERTY LLC',
  },
  {
    cik: '0001731152',
    value: '0001731152,JAMES HAMBRO & PARTNERS',
    name: 'JAMES HAMBRO & PARTNERS',
    label: 'JAMES HAMBRO & PARTNERS',
  },
  {
    cik: '0000810384',
    value: '0000810384,JAMES INVESTMENT RESEARCH INC',
    name: 'JAMES INVESTMENT RESEARCH INC',
    label: 'JAMES INVESTMENT RESEARCH INC',
  },
  {
    cik: '0001949059',
    value: '0001949059,JAMES J. BURNS & COMPANY, LLC',
    name: 'JAMES J. BURNS & COMPANY, LLC',
    label: 'JAMES J. BURNS & COMPANY, LLC',
  },
  {
    cik: '0001911052',
    value: '0001911052,JAMES REED FINANCIAL SERVICES, INC.',
    name: 'JAMES REED FINANCIAL SERVICES, INC.',
    label: 'JAMES REED FINANCIAL SERVICES, INC.',
  },
  {
    cik: '0000886920',
    value: '0000886920,JAMISON EATON & WOOD INC /NJ',
    name: 'JAMISON EATON & WOOD INC /NJ',
    label: 'JAMISON EATON & WOOD INC /NJ',
  },
  {
    cik: '0001159159',
    value: '0001159159,JANA PARTNERS LLC',
    name: 'JANA PARTNERS LLC',
    label: 'JANA PARTNERS LLC',
  },
  {
    cik: '0001595888',
    value: '0001595888,JANE STREET GROUP, LLC',
    name: 'JANE STREET GROUP, LLC',
    label: 'JANE STREET GROUP, LLC',
  },
  {
    cik: '0001330294',
    value: '0001330294,JANE STREET HOLDING, LLC',
    name: 'JANE STREET HOLDING, LLC',
    label: 'JANE STREET HOLDING, LLC',
  },
  {
    cik: '0001598561',
    value: '0001598561,JANICZEK WEALTH MANAGEMENT, LLC',
    name: 'JANICZEK WEALTH MANAGEMENT, LLC',
    label: 'JANICZEK WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001569855',
    value: '0001569855,JANNEY CAPITAL MANAGEMENT LLC',
    name: 'JANNEY CAPITAL MANAGEMENT LLC',
    label: 'JANNEY CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001329948',
    value: '0001329948,JANNEY MONTGOMERY SCOTT LLC',
    name: 'JANNEY MONTGOMERY SCOTT LLC',
    label: 'JANNEY MONTGOMERY SCOTT LLC',
  },
  {
    cik: '0001855967',
    value: '0001855967,JANUARY CAPITAL ADVISORS LLC',
    name: 'JANUARY CAPITAL ADVISORS LLC',
    label: 'JANUARY CAPITAL ADVISORS LLC',
  },
  {
    cik: '0000812295',
    value: '0000812295,JANUS CAPITAL MANAGEMENT LLC',
    name: 'JANUS CAPITAL MANAGEMENT LLC',
    label: 'JANUS CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001274173',
    value: '0001274173,JANUS HENDERSON GROUP PLC',
    name: 'JANUS HENDERSON GROUP PLC',
    label: 'JANUS HENDERSON GROUP PLC',
  },
  {
    cik: '0001783464',
    value: '0001783464,JAPAN POST HOLDINGS CO., LTD.',
    name: 'JAPAN POST HOLDINGS CO., LTD.',
    label: 'JAPAN POST HOLDINGS CO., LTD.',
  },
  {
    cik: '0001123778',
    value: '0001123778,JARISLOWSKY, FRASER LTD',
    name: 'JARISLOWSKY, FRASER LTD',
    label: 'JARISLOWSKY, FRASER LTD',
  },
  {
    cik: '0001875202',
    value: '0001875202,J ARNOLD WEALTH MANAGEMENT CO',
    name: 'J ARNOLD WEALTH MANAGEMENT CO',
    label: 'J ARNOLD WEALTH MANAGEMENT CO',
  },
  {
    cik: '0001483535',
    value: '0001483535,JASINKIEWICZ CAPITAL MANAGEMENT, LP',
    name: 'JASINKIEWICZ CAPITAL MANAGEMENT, LP',
    label: 'JASINKIEWICZ CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0001453381',
    value: '0001453381,JASPER RIDGE PARTNERS, L.P.',
    name: 'JASPER RIDGE PARTNERS, L.P.',
    label: 'JASPER RIDGE PARTNERS, L.P.',
  },
  {
    cik: '0001426728',
    value: '0001426728,JAT CAPITAL MANAGEMENT, L.P.',
    name: 'JAT CAPITAL MANAGEMENT, L.P.',
    label: 'JAT CAPITAL MANAGEMENT, L.P.',
  },
  {
    cik: '0001834438',
    value: '0001834438,JAT CAPITAL MGMT LP',
    name: 'JAT CAPITAL MGMT LP',
    label: 'JAT CAPITAL MGMT LP',
  },
  {
    cik: '0001002078',
    value: '0001002078,JAYHAWK CAPITAL MANAGEMENT, L.L.C.',
    name: 'JAYHAWK CAPITAL MANAGEMENT, L.L.C.',
    label: 'JAYHAWK CAPITAL MANAGEMENT, L.L.C.',
  },
  {
    cik: '0001879371',
    value: '0001879371,JB CAPITAL LLC',
    name: 'JB CAPITAL LLC',
    label: 'JB CAPITAL LLC',
  },
  {
    cik: '0001138532',
    value: '0001138532,JB CAPITAL PARTNERS LP',
    name: 'JB CAPITAL PARTNERS LP',
    label: 'JB CAPITAL PARTNERS LP',
  },
  {
    cik: '0001483864',
    value: '0001483864,JBF CAPITAL, INC.',
    name: 'JBF CAPITAL, INC.',
    label: 'JBF CAPITAL, INC.',
  },
  {
    cik: '0001523988',
    value: '0001523988,JB INVESTMENTS MANAGEMENT, LLC',
    name: 'JB INVESTMENTS MANAGEMENT, LLC',
    label: 'JB INVESTMENTS MANAGEMENT, LLC',
  },
  {
    cik: '0001776321',
    value: '0001776321,JBJ INVESTMENT PARTNERS, LLC',
    name: 'JBJ INVESTMENT PARTNERS, LLC',
    label: 'JBJ INVESTMENT PARTNERS, LLC',
  },
  {
    cik: '0001673642',
    value: '0001673642,JCF III AIV I GP LTD.',
    name: 'JCF III AIV I GP LTD.',
    label: 'JCF III AIV I GP LTD.',
  },
  {
    cik: '0001492040',
    value: '0001492040,JCIC ASSET MANAGEMENT INC.',
    name: 'JCIC ASSET MANAGEMENT INC.',
    label: 'JCIC ASSET MANAGEMENT INC.',
  },
  {
    cik: '0001461945',
    value: '0001461945,JCP INVESTMENT MANAGEMENT, LLC',
    name: 'JCP INVESTMENT MANAGEMENT, LLC',
    label: 'JCP INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001444055',
    value: '0001444055,JCSD CAPITAL, LLC',
    name: 'JCSD CAPITAL, LLC',
    label: 'JCSD CAPITAL, LLC',
  },
  {
    cik: '0001219037',
    value: '0001219037,JD CAPITAL MANAGEMENT LLC',
    name: 'JD CAPITAL MANAGEMENT LLC',
    label: 'JD CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001756777',
    value: '0001756777,JDC-JSC L.P.',
    name: 'JDC-JSC L.P.',
    label: 'JDC-JSC L.P.',
  },
  {
    cik: '0001840561',
    value: '0001840561,JD FINANCIAL SERVICES INC.',
    name: 'JD FINANCIAL SERVICES INC.',
    label: 'JD FINANCIAL SERVICES INC.',
  },
  {
    cik: '0001960212',
    value: '0001960212,JDM FINANCIAL GROUP LLC',
    name: 'JDM FINANCIAL GROUP LLC',
    label: 'JDM FINANCIAL GROUP LLC',
  },
  {
    cik: '0001451460',
    value: '0001451460,JEEREDDI INVESTMENTS, LP',
    name: 'JEEREDDI INVESTMENTS, LP',
    label: 'JEEREDDI INVESTMENTS, LP',
  },
  {
    cik: '0000096223',
    value: '0000096223,JEFFERIES FINANCIAL GROUP INC.',
    name: 'JEFFERIES FINANCIAL GROUP INC.',
    label: 'JEFFERIES FINANCIAL GROUP INC.',
  },
  {
    cik: '0001084580',
    value: '0001084580,JEFFERIES GROUP LLC',
    name: 'JEFFERIES GROUP LLC',
    label: 'JEFFERIES GROUP LLC',
  },
  {
    cik: '0001749980',
    value: '0001749980,JENEQ MANAGEMENT LP',
    name: 'JENEQ MANAGEMENT LP',
    label: 'JENEQ MANAGEMENT LP',
  },
  {
    cik: '0001666667',
    value: '0001666667,JENKINS WEALTH',
    name: 'JENKINS WEALTH',
    label: 'JENKINS WEALTH',
  },
  {
    cik: '0000053417',
    value: '0000053417,JENNISON ASSOCIATES LLC',
    name: 'JENNISON ASSOCIATES LLC',
    label: 'JENNISON ASSOCIATES LLC',
  },
  {
    cik: '0001106129',
    value: '0001106129,JENSEN INVESTMENT MANAGEMENT INC',
    name: 'JENSEN INVESTMENT MANAGEMENT INC',
    label: 'JENSEN INVESTMENT MANAGEMENT INC',
  },
  {
    cik: '0001695908',
    value: '0001695908,JENTNER CORP',
    name: 'JENTNER CORP',
    label: 'JENTNER CORP',
  },
  {
    cik: '0001831263',
    value: '0001831263,JEPPSON WEALTH MANAGEMENT, LLC',
    name: 'JEPPSON WEALTH MANAGEMENT, LLC',
    label: 'JEPPSON WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001525234',
    value: '0001525234,JERICHO CAPITAL ASSET MANAGEMENT L.P.',
    name: 'JERICHO CAPITAL ASSET MANAGEMENT L.P.',
    label: 'JERICHO CAPITAL ASSET MANAGEMENT L.P.',
  },
  {
    cik: '0001278235',
    value: '0001278235,JET CAPITAL INVESTORS L P',
    name: 'JET CAPITAL INVESTORS L P',
    label: 'JET CAPITAL INVESTORS L P',
  },
  {
    cik: '0001350605',
    value: '0001350605,JETSTREAM CAPITAL LLC',
    name: 'JETSTREAM CAPITAL LLC',
    label: 'JETSTREAM CAPITAL LLC',
  },
  {
    cik: '0001766972',
    value: '0001766972,JFG WEALTH MANAGEMENT, LLC',
    name: 'JFG WEALTH MANAGEMENT, LLC',
    label: 'JFG WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001568068',
    value: '0001568068,JFS WEALTH ADVISORS, LLC',
    name: 'JFS WEALTH ADVISORS, LLC',
    label: 'JFS WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001320294',
    value: '0001320294,JGE CAPITAL MANAGEMENT LLC',
    name: 'JGE CAPITAL MANAGEMENT LLC',
    label: 'JGE CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001412741',
    value: '0001412741,J. GOLDMAN & CO LP',
    name: 'J. GOLDMAN & CO LP',
    label: 'J. GOLDMAN & CO LP',
  },
  {
    cik: '0001536562',
    value: '0001536562,JGP GLOBAL GESTAO DE RECURSOS LTDA.',
    name: 'JGP GLOBAL GESTAO DE RECURSOS LTDA.',
    label: 'JGP GLOBAL GESTAO DE RECURSOS LTDA.',
  },
  {
    cik: '0001748278',
    value: '0001748278,JGP WEALTH MANAGEMENT, LLC',
    name: 'JGP WEALTH MANAGEMENT, LLC',
    label: 'JGP WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001496859',
    value: '0001496859,JHL CAPITAL GROUP LLC',
    name: 'JHL CAPITAL GROUP LLC',
    label: 'JHL CAPITAL GROUP LLC',
  },
  {
    cik: '0001567443',
    value: '0001567443,J.H. WHITNEY CAPITAL PARTNERS, LLC',
    name: 'J.H. WHITNEY CAPITAL PARTNERS, LLC',
    label: 'J.H. WHITNEY CAPITAL PARTNERS, LLC',
  },
  {
    cik: '0001567459',
    value: '0001567459,J.H. WHITNEY EQUITY PARTNERS VII, LLC',
    name: 'J.H. WHITNEY EQUITY PARTNERS VII, LLC',
    label: 'J.H. WHITNEY EQUITY PARTNERS VII, LLC',
  },
  {
    cik: '0001598561',
    value: '0001598561,JJJ ADVISORS INC.',
    name: 'JJJ ADVISORS INC.',
    label: 'JJJ ADVISORS INC.',
  },
  {
    cik: '0001742315',
    value: '0001742315,J. L. BAINBRIDGE & CO., INC.',
    name: 'J. L. BAINBRIDGE & CO., INC.',
    label: 'J. L. BAINBRIDGE & CO., INC.',
  },
  {
    cik: '0001085227',
    value: '0001085227,JLB & ASSOCIATES INC',
    name: 'JLB & ASSOCIATES INC',
    label: 'JLB & ASSOCIATES INC',
  },
  {
    cik: '0001767141',
    value: '0001767141,JLP ASSET MANAGEMENT, LLC',
    name: 'JLP ASSET MANAGEMENT, LLC',
    label: 'JLP ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001907294',
    value: '0001907294,JMAC ENTERPRISES LLC',
    name: 'JMAC ENTERPRISES LLC',
    label: 'JMAC ENTERPRISES LLC',
  },
  {
    cik: '0001314404',
    value: '0001314404,JMG FINANCIAL GROUP, LTD.',
    name: 'JMG FINANCIAL GROUP, LTD.',
    label: 'JMG FINANCIAL GROUP, LTD.',
  },
  {
    cik: '0001794198',
    value: '0001794198,JNB ADVISORS LLC',
    name: 'JNB ADVISORS LLC',
    label: 'JNB ADVISORS LLC',
  },
  {
    cik: '0001569118',
    value: '0001569118,JNBA FINANCIAL ADVISORS',
    name: 'JNBA FINANCIAL ADVISORS',
    label: 'JNBA FINANCIAL ADVISORS',
  },
  {
    cik: '0001770120',
    value: '0001770120,JNE PARTNERS LLP',
    name: 'JNE PARTNERS LLP',
    label: 'JNE PARTNERS LLP',
  },
  {
    cik: '0001599330',
    value: '0001599330,JOEL ISAACSON & CO., LLC',
    name: 'JOEL ISAACSON & CO., LLC',
    label: 'JOEL ISAACSON & CO., LLC',
  },
  {
    cik: '0001344114',
    value: '0001344114,J O HAMBRO INVESTMENT MANAGEMENT LTD',
    name: 'J O HAMBRO INVESTMENT MANAGEMENT LTD',
    label: 'J O HAMBRO INVESTMENT MANAGEMENT LTD',
  },
  {
    cik: '0001665589',
    value: '0001665589,JOHANSON FINANCIAL ADVISORS, INC.',
    name: 'JOHANSON FINANCIAL ADVISORS, INC.',
    label: 'JOHANSON FINANCIAL ADVISORS, INC.',
  },
  {
    cik: '0000919530',
    value: '0000919530,JOHN G ULLMAN & ASSOCIATES INC',
    name: 'JOHN G ULLMAN & ASSOCIATES INC',
    label: 'JOHN G ULLMAN & ASSOCIATES INC',
  },
  {
    cik: '0001910790',
    value: '0001910790,JOHN & KATHLEEN SCHREIBER FOUNDATION',
    name: 'JOHN & KATHLEEN SCHREIBER FOUNDATION',
    label: 'JOHN & KATHLEEN SCHREIBER FOUNDATION',
  },
  {
    cik: '0001764756',
    value: '0001764756,JOHNS HOPKINS UNIVERSITY',
    name: 'JOHNS HOPKINS UNIVERSITY',
    label: 'JOHNS HOPKINS UNIVERSITY',
  },
  {
    cik: '0001844238',
    value: '0001844238,JOHNSON BIXBY & ASSOCIATES, LLC',
    name: 'JOHNSON BIXBY & ASSOCIATES, LLC',
    label: 'JOHNSON BIXBY & ASSOCIATES, LLC',
  },
  {
    cik: '0001477024',
    value: '0001477024,JOHNSON FINANCIAL GROUP, INC.',
    name: 'JOHNSON FINANCIAL GROUP, INC.',
    label: 'JOHNSON FINANCIAL GROUP, INC.',
  },
  {
    cik: '0001706327',
    value: '0001706327,JOHNSON FINANCIAL GROUP, LLC',
    name: 'JOHNSON FINANCIAL GROUP, LLC',
    label: 'JOHNSON FINANCIAL GROUP, LLC',
  },
  {
    cik: '0000821197',
    value: '0000821197,JOHNSON INVESTMENT COUNSEL INC',
    name: 'JOHNSON INVESTMENT COUNSEL INC',
    label: 'JOHNSON INVESTMENT COUNSEL INC',
  },
  {
    cik: '0000200406',
    value: '0000200406,JOHNSON & JOHNSON',
    name: 'JOHNSON & JOHNSON',
    label: 'JOHNSON & JOHNSON',
  },
  {
    cik: '0000924020',
    value: '0000924020,JOHNSON & JOHNSON INNOVATION - JJDC, INC.',
    name: 'JOHNSON & JOHNSON INNOVATION - JJDC, INC.',
    label: 'JOHNSON & JOHNSON INNOVATION - JJDC, INC.',
  },
  {
    cik: '0001732399',
    value: '0001732399,JOHNSON MIDWEST FINANCIAL, LLC',
    name: 'JOHNSON MIDWEST FINANCIAL, LLC',
    label: 'JOHNSON MIDWEST FINANCIAL, LLC',
  },
  {
    cik: '0001954136',
    value: '0001954136,JOHNSON & WHITE WEALTH MANAGEMENT, LLC',
    name: 'JOHNSON & WHITE WEALTH MANAGEMENT, LLC',
    label: 'JOHNSON & WHITE WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001006364',
    value: '0001006364,JOHNSTON ASSET MANAGEMENT CORP',
    name: 'JOHNSTON ASSET MANAGEMENT CORP',
    label: 'JOHNSTON ASSET MANAGEMENT CORP',
  },
  {
    cik: '0001798926',
    value: '0001798926,JOHN W. BROOKER & CO., CPAS',
    name: 'JOHN W. BROOKER & CO., CPAS',
    label: 'JOHN W. BROOKER & CO., CPAS',
  },
  {
    cik: '0001697539',
    value: '0001697539,JOHN W. ROSENTHAL CAPITAL MANAGEMENT, INC.',
    name: 'JOHN W. ROSENTHAL CAPITAL MANAGEMENT, INC.',
    label: 'JOHN W. ROSENTHAL CAPITAL MANAGEMENT, INC.',
  },
  {
    cik: '0001106500',
    value: '0001106500,JOHO CAPITAL LLC',
    name: 'JOHO CAPITAL LLC',
    label: 'JOHO CAPITAL LLC',
  },
  {
    cik: '0001569117',
    value: '0001569117,JOLLEY ASSET MANAGEMENT, LLC',
    name: 'JOLLEY ASSET MANAGEMENT, LLC',
    label: 'JOLLEY ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001492040',
    value: '0001492040,JONES COLLOMBIN INVESTMENT COUNSEL INC',
    name: 'JONES COLLOMBIN INVESTMENT COUNSEL INC',
    label: 'JONES COLLOMBIN INVESTMENT COUNSEL INC',
  },
  {
    cik: '0000815917',
    value: '0000815917,JONES FINANCIAL COMPANIES LLLP',
    name: 'JONES FINANCIAL COMPANIES LLLP',
    label: 'JONES FINANCIAL COMPANIES LLLP',
  },
  {
    cik: '0001842361',
    value: '0001842361,JONES ROAD CAPITAL MANAGEMENT, L.P.',
    name: 'JONES ROAD CAPITAL MANAGEMENT, L.P.',
    label: 'JONES ROAD CAPITAL MANAGEMENT, L.P.',
  },
  {
    cik: '0001707975',
    value: '0001707975,JORDAN PARK GROUP LLC',
    name: 'JORDAN PARK GROUP LLC',
    label: 'JORDAN PARK GROUP LLC',
  },
  {
    cik: '0001910383',
    value: '0001910383,JOSEPH GROUP CAPITAL MANAGEMENT',
    name: 'JOSEPH GROUP CAPITAL MANAGEMENT',
    label: 'JOSEPH GROUP CAPITAL MANAGEMENT',
  },
  {
    cik: '0001775391',
    value: '0001775391,JOSEPH P. LUCIA & ASSOCIATES, LLC',
    name: 'JOSEPH P. LUCIA & ASSOCIATES, LLC',
    label: 'JOSEPH P. LUCIA & ASSOCIATES, LLC',
  },
  {
    cik: '0001726846',
    value: '0001726846,JOSEPH R. WEISS, LLC',
    name: 'JOSEPH R. WEISS, LLC',
    label: 'JOSEPH R. WEISS, LLC',
  },
  {
    cik: '0001911348',
    value: '0001911348,JOSH ARNOLD INVESTMENT CONSULTANT, LLC',
    name: 'JOSH ARNOLD INVESTMENT CONSULTANT, LLC',
    label: 'JOSH ARNOLD INVESTMENT CONSULTANT, LLC',
  },
  {
    cik: '0001929070',
    value: '0001929070,JOULE FINANCIAL, LLC',
    name: 'JOULE FINANCIAL, LLC',
    label: 'JOULE FINANCIAL, LLC',
  },
  {
    cik: '0001840085',
    value: '0001840085,JOURNEY ADVISORY GROUP, LLC',
    name: 'JOURNEY ADVISORY GROUP, LLC',
    label: 'JOURNEY ADVISORY GROUP, LLC',
  },
  {
    cik: '0001909316',
    value: '0001909316,JOURNEY STRATEGIC WEALTH LLC',
    name: 'JOURNEY STRATEGIC WEALTH LLC',
    label: 'JOURNEY STRATEGIC WEALTH LLC',
  },
  {
    cik: '0001493936',
    value: '0001493936,JOYN ADVISORS, INC.',
    name: 'JOYN ADVISORS, INC.',
    label: 'JOYN ADVISORS, INC.',
  },
  {
    cik: '0001605949',
    value: '0001605949,J&P(CHINA)CAPITAL MANAGEMENT CO.,LTD',
    name: 'J&P(CHINA)CAPITAL MANAGEMENT CO.,LTD',
    label: 'J&P(CHINA)CAPITAL MANAGEMENT CO.,LTD',
  },
  {
    cik: '0001426755',
    value: '0001426755,J.P. MARVEL INVESTMENT ADVISORS, LLC',
    name: 'J.P. MARVEL INVESTMENT ADVISORS, LLC',
    label: 'J.P. MARVEL INVESTMENT ADVISORS, LLC',
  },
  {
    cik: '0000019617',
    value: '0000019617,JPMORGAN CHASE & CO',
    name: 'JPMORGAN CHASE & CO',
    label: 'JPMORGAN CHASE & CO',
  },
  {
    cik: '0001623184',
    value: '0001623184,J.P. TURNER & CO CAPITAL MNAGEMENT, LLC',
    name: 'J.P. TURNER & CO CAPITAL MNAGEMENT, LLC',
    label: 'J.P. TURNER & CO CAPITAL MNAGEMENT, LLC',
  },
  {
    cik: '0001631084',
    value: '0001631084,JRM INVESTMENT COUNSEL, LLC',
    name: 'JRM INVESTMENT COUNSEL, LLC',
    label: 'JRM INVESTMENT COUNSEL, LLC',
  },
  {
    cik: '0001535293',
    value: '0001535293,J.SAFRA ASSET MANAGEMENT CORP',
    name: 'J.SAFRA ASSET MANAGEMENT CORP',
    label: 'J.SAFRA ASSET MANAGEMENT CORP',
  },
  {
    cik: '0001562668',
    value: '0001562668,JS CAPITAL MANAGEMENT LLC',
    name: 'JS CAPITAL MANAGEMENT LLC',
    label: 'JS CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001802224',
    value: '0001802224,JSF FINANCIAL, LLC',
    name: 'JSF FINANCIAL, LLC',
    label: 'JSF FINANCIAL, LLC',
  },
  {
    cik: '0001875645',
    value: '0001875645,JTC EMPLOYER SOLUTIONS TRUSTEE LTD',
    name: 'JTC EMPLOYER SOLUTIONS TRUSTEE LTD',
    label: 'JTC EMPLOYER SOLUTIONS TRUSTEE LTD',
  },
  {
    cik: '0001689013',
    value: '0001689013,JT STRATFORD LLC',
    name: 'JT STRATFORD LLC',
    label: 'JT STRATFORD LLC',
  },
  {
    cik: '0001657064',
    value: '0001657064,JULEX CAPITAL MANAGEMENT, LLC',
    name: 'JULEX CAPITAL MANAGEMENT, LLC',
    label: 'JULEX CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001831577',
    value: '0001831577,JUMP FINANCIAL, LLC',
    name: 'JUMP FINANCIAL, LLC',
    label: 'JUMP FINANCIAL, LLC',
  },
  {
    cik: '0001520770',
    value: '0001520770,JUMP TRADING, LLC',
    name: 'JUMP TRADING, LLC',
    label: 'JUMP TRADING, LLC',
  },
  {
    cik: '0001127998',
    value: '0001127998,JUMP TRADING, LLC',
    name: 'JUMP TRADING, LLC',
    label: 'JUMP TRADING, LLC',
  },
  {
    cik: '0001632866',
    value: '0001632866,JUNCTURE WEALTH STRATEGIES, LLC',
    name: 'JUNCTURE WEALTH STRATEGIES, LLC',
    label: 'JUNCTURE WEALTH STRATEGIES, LLC',
  },
  {
    cik: '0001803460',
    value: '0001803460,JUNIPER HILL CAPITAL MANAGEMENT LP',
    name: 'JUNIPER HILL CAPITAL MANAGEMENT LP',
    label: 'JUNIPER HILL CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001794034',
    value: '0001794034,JUNIPER INVESTMENT COMPANY, LLC',
    name: 'JUNIPER INVESTMENT COMPANY, LLC',
    label: 'JUNIPER INVESTMENT COMPANY, LLC',
  },
  {
    cik: '0001832179',
    value: '0001832179,JUNSON ASSET MANAGEMENT (HONG KONG) LTD',
    name: 'JUNSON ASSET MANAGEMENT (HONG KONG) LTD',
    label: 'JUNSON ASSET MANAGEMENT (HONG KONG) LTD',
  },
  {
    cik: '0001595880',
    value: '0001595880,JUNTO CAPITAL MANAGEMENT LP',
    name: 'JUNTO CAPITAL MANAGEMENT LP',
    label: 'JUNTO CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001215838',
    value: '0001215838,JUPITER ASSET MANAGEMENT LTD',
    name: 'JUPITER ASSET MANAGEMENT LTD',
    label: 'JUPITER ASSET MANAGEMENT LTD',
  },
  {
    cik: '0001774207',
    value: '0001774207,JUPITER WEALTH MANAGEMENT LLC',
    name: 'JUPITER WEALTH MANAGEMENT LLC',
    label: 'JUPITER WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0001767306',
    value: '0001767306,JUSTINVEST LLC',
    name: 'JUSTINVEST LLC',
    label: 'JUSTINVEST LLC',
  },
  {
    cik: '0001137058',
    value: '0001137058,JUVONEN RONALD',
    name: 'JUVONEN RONALD',
    label: 'JUVONEN RONALD',
  },
  {
    cik: '0001571902',
    value: '0001571902,JVL ADVISORS, L.L.C.',
    name: 'JVL ADVISORS, L.L.C.',
    label: 'JVL ADVISORS, L.L.C.',
  },
  {
    cik: '0001915765',
    value: '0001915765,JVL ASSOCIATES LLC',
    name: 'JVL ASSOCIATES LLC',
    label: 'JVL ASSOCIATES LLC',
  },
  {
    cik: '0001549738',
    value: '0001549738,JW ASSET MANAGEMENT, LLC',
    name: 'JW ASSET MANAGEMENT, LLC',
    label: 'JW ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001591765',
    value: '0001591765,J.W. CHILDS ASSOCIATES, L.P.',
    name: 'J.W. CHILDS ASSOCIATES, L.P.',
    label: 'J.W. CHILDS ASSOCIATES, L.P.',
  },
  {
    cik: '0001404763',
    value: '0001404763,J.W. COLE ADVISORS, INC.',
    name: 'J.W. COLE ADVISORS, INC.',
    label: 'J.W. COLE ADVISORS, INC.',
  },
  {
    cik: '0001536139',
    value: '0001536139,J. W. COONS ADVISORS, LLC',
    name: 'J. W. COONS ADVISORS, LLC',
    label: 'J. W. COONS ADVISORS, LLC',
  },
  {
    cik: '0001567233',
    value: '000156723,JWEST, LLC',
    name: 'JWEST, LLC',
    label: 'JWEST, LLC',
  },
  {
    cik: '0001461790',
    value: '0001461790,K2 PRINCIPAL FUND, L.P.',
    name: 'K2 PRINCIPAL FUND, L.P.',
    label: 'K2 PRINCIPAL FUND, L.P.',
  },
  {
    cik: '0001730924',
    value: '0001730924,KABAREC FINANCIAL ADVISORS, LTD.',
    name: 'KABAREC FINANCIAL ADVISORS, LTD.',
    label: 'KABAREC FINANCIAL ADVISORS, LTD.',
  },
  {
    cik: '0001605429',
    value: '0001605429,KABOUTER MANAGEMENT, LLC',
    name: 'KABOUTER MANAGEMENT, LLC',
    label: 'KABOUTER MANAGEMENT, LLC',
  },
  {
    cik: '0001720980',
    value: '0001720980,KADENSA CAPITAL LTD',
    name: 'KADENSA CAPITAL LTD',
    label: 'KADENSA CAPITAL LTD',
  },
  {
    cik: '0001039565',
    value: '0001039565,KAHN BROTHERS & CO INC /DE/',
    name: 'KAHN BROTHERS & CO INC /DE/',
    label: 'KAHN BROTHERS & CO INC /DE/',
  },
  {
    cik: '0001039565',
    value: '0001039565,KAHN BROTHERS GROUP INC',
    name: 'KAHN BROTHERS GROUP INC',
    label: 'KAHN BROTHERS GROUP INC',
  },
  {
    cik: '0001669336',
    value: '0001669336,KAIZEN ADVISORY, LLC',
    name: 'KAIZEN ADVISORY, LLC',
    label: 'KAIZEN ADVISORY, LLC',
  },
  {
    cik: '0001842013',
    value: '0001842013,KAIZEN FINANCIAL STRATEGIES',
    name: 'KAIZEN FINANCIAL STRATEGIES',
    label: 'KAIZEN FINANCIAL STRATEGIES',
  },
  {
    cik: '0001702912',
    value: '0001702912,KALEIDOSCOPE CAPITAL LP',
    name: 'KALEIDOSCOPE CAPITAL LP',
    label: 'KALEIDOSCOPE CAPITAL LP',
  },
  {
    cik: '0000928749',
    value: '0000928749,KALMAR INVESTMENTS INC /DE/',
    name: 'KALMAR INVESTMENTS INC /DE/',
    label: 'KALMAR INVESTMENTS INC /DE/',
  },
  {
    cik: '0001666788',
    value: '0001666788,KALOS MANAGEMENT, INC.',
    name: 'KALOS MANAGEMENT, INC.',
    label: 'KALOS MANAGEMENT, INC.',
  },
  {
    cik: '0001539976',
    value: '0001539976,KAMES CAPITAL MANAGEMENT LTD',
    name: 'KAMES CAPITAL MANAGEMENT LTD',
    label: 'KAMES CAPITAL MANAGEMENT LTD',
  },
  {
    cik: '0001539994',
    value: '0001539994,KAMES CAPITAL PLC',
    name: 'KAMES CAPITAL PLC',
    label: 'KAMES CAPITAL PLC',
  },
  {
    cik: '0001802765',
    value: '0001802765,KAM LAWRENCE',
    name: 'KAM LAWRENCE',
    label: 'KAM LAWRENCE',
  },
  {
    cik: '0001317267',
    value: '0001317267,KAMUNTING STREET CAPITAL MANAGEMENT, L.P.',
    name: 'KAMUNTING STREET CAPITAL MANAGEMENT, L.P.',
    label: 'KAMUNTING STREET CAPITAL MANAGEMENT, L.P.',
  },
  {
    cik: '0001085825',
    value: '0001085825,KANALY TRUST CO',
    name: 'KANALY TRUST CO',
    label: 'KANALY TRUST CO',
  },
  {
    cik: '0001164478',
    value: '0001164478,KANAWHA CAPITAL MANAGEMENT LLC',
    name: 'KANAWHA CAPITAL MANAGEMENT LLC',
    label: 'KANAWHA CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001681614',
    value: '0001681614,KANEN WEALTH MANAGEMENT LLC',
    name: 'KANEN WEALTH MANAGEMENT LLC',
    label: 'KANEN WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0001666660',
    value: '0001666660,KANHA CAPITAL, LP',
    name: 'KANHA CAPITAL, LP',
    label: 'KANHA CAPITAL, LP',
  },
  {
    cik: '0001512128',
    value: '0001512128,KARAMAAN GROUP, LLC',
    name: 'KARAMAAN GROUP, LLC',
    label: 'KARAMAAN GROUP, LLC',
  },
  {
    cik: '0001792430',
    value: '0001792430,KARANI ASSET MANAGEMENT LLC',
    name: 'KARANI ASSET MANAGEMENT LLC',
    label: 'KARANI ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001914235',
    value: '0001914235,KARLINSKI ANDREW C',
    name: 'KARLINSKI ANDREW C',
    label: 'KARLINSKI ANDREW C',
  },
  {
    cik: '0001842433',
    value: '0001842433,KARN, COUZENS & ASSOCIATES, INC.',
    name: 'KARN, COUZENS & ASSOCIATES, INC.',
    label: 'KARN, COUZENS & ASSOCIATES, INC.',
  },
  {
    cik: '0001127544',
    value: '0001127544,KARPAS STRATEGIES LLC',
    name: 'KARPAS STRATEGIES LLC',
    label: 'KARPAS STRATEGIES LLC',
  },
  {
    cik: '0001127544',
    value: '0001127544,KARPAS STRATEGIES, LLC',
    name: 'KARPAS STRATEGIES, LLC',
    label: 'KARPAS STRATEGIES, LLC',
  },
  {
    cik: '0001629680',
    value: '0001629680,KARP CAPITAL MANAGEMENT CORP',
    name: 'KARP CAPITAL MANAGEMENT CORP',
    label: 'KARP CAPITAL MANAGEMENT CORP',
  },
  {
    cik: '0001048703',
    value: '0001048703,KARPUS MANAGEMENT, INC.',
    name: 'KARPUS MANAGEMENT, INC.',
    label: 'KARPUS MANAGEMENT, INC.',
  },
  {
    cik: '0001634119',
    value: '0001634119,KARST PEAK CAPITAL LTD',
    name: 'KARST PEAK CAPITAL LTD',
    label: 'KARST PEAK CAPITAL LTD',
  },
  {
    cik: '0001659420',
    value: '0001659420,KASSIRER ASSET MANAGEMENT CORP',
    name: 'KASSIRER ASSET MANAGEMENT CORP',
    label: 'KASSIRER ASSET MANAGEMENT CORP',
  },
  {
    cik: '0001908433',
    value: '0001908433,KATHLEEN S. WRIGHT ASSOCIATES INC.',
    name: 'KATHLEEN S. WRIGHT ASSOCIATES INC.',
    label: 'KATHLEEN S. WRIGHT ASSOCIATES INC.',
  },
  {
    cik: '0001794543',
    value: '0001794543,KATHMERE CAPITAL MANAGEMENT, LLC',
    name: 'KATHMERE CAPITAL MANAGEMENT, LLC',
    label: 'KATHMERE CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001841766',
    value: '0001841766,KAVAR CAPITAL PARTNERS GROUP, LLC',
    name: 'KAVAR CAPITAL PARTNERS GROUP, LLC',
    label: 'KAVAR CAPITAL PARTNERS GROUP, LLC',
  },
  {
    cik: '0001758087',
    value: '0001758087,KAVAR CAPITAL PARTNERS, LLC',
    name: 'KAVAR CAPITAL PARTNERS, LLC',
    label: 'KAVAR CAPITAL PARTNERS, LLC',
  },
  {
    cik: '0001641042',
    value: '0001641042,KAVI ASSET MANAGEMENT, LP',
    name: 'KAVI ASSET MANAGEMENT, LP',
    label: 'KAVI ASSET MANAGEMENT, LP',
  },
  {
    cik: '0001534242',
    value: '0001534242,KAWA CAPITAL MANAGEMENT, INC',
    name: 'KAWA CAPITAL MANAGEMENT, INC',
    label: 'KAWA CAPITAL MANAGEMENT, INC',
  },
  {
    cik: '0001697551',
    value: '0001697551,KAYAK INVESTMENT PARTNERS LLC',
    name: 'KAYAK INVESTMENT PARTNERS LLC',
    label: 'KAYAK INVESTMENT PARTNERS LLC',
  },
  {
    cik: '0001111797',
    value: '0001111797,KAYE CAPITAL MANAGEMENT',
    name: 'KAYE CAPITAL MANAGEMENT',
    label: 'KAYE CAPITAL MANAGEMENT',
  },
  {
    cik: '0000949615',
    value: '0000949615,KAYNE ANDERSON CAPITAL ADVISORS LP',
    name: 'KAYNE ANDERSON CAPITAL ADVISORS LP',
    label: 'KAYNE ANDERSON CAPITAL ADVISORS LP',
  },
  {
    cik: '0001021223',
    value: '0001021223,KAYNE ANDERSON RUDNICK INVESTMENT MANAGEMENT LLC',
    name: 'KAYNE ANDERSON RUDNICK INVESTMENT MANAGEMENT LLC',
    label: 'KAYNE ANDERSON RUDNICK INVESTMENT MANAGEMENT LLC',
  },
  {
    cik: '0000766018',
    value: '0000766018,KAYS FINANCIAL ADVISORY CORP /ADV',
    name: 'KAYS FINANCIAL ADVISORY CORP /ADV',
    label: 'KAYS FINANCIAL ADVISORY CORP /ADV',
  },
  {
    cik: '0001580415',
    value: '0001580415,KAZAZIAN ASSET MANAGEMENT, LLC',
    name: 'KAZAZIAN ASSET MANAGEMENT, LLC',
    label: 'KAZAZIAN ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001411133',
    value: '0001411133,KBC GROUP NV',
    name: 'KBC GROUP NV',
    label: 'KBC GROUP NV',
  },
  {
    cik: '0001864910',
    value: '0001864910,KB FINANCIAL PARTNERS, LLC',
    name: 'KB FINANCIAL PARTNERS, LLC',
    label: 'KB FINANCIAL PARTNERS, LLC',
  },
  {
    cik: '0001569391',
    value: '0001569391,KCG HOLDINGS, INC.',
    name: 'KCG HOLDINGS, INC.',
    label: 'KCG HOLDINGS, INC.',
  },
  {
    cik: '0001738738',
    value: '0001738738,KC INVESTMENT ADVISORS, LLC',
    name: 'KC INVESTMENT ADVISORS, LLC',
    label: 'KC INVESTMENT ADVISORS, LLC',
  },
  {
    cik: '0001657662',
    value: '0001657,KCK LTD.',
    name: 'KCK LTD.',
    label: 'KCK LTD.',
  },
  {
    cik: '0001722967',
    value: '0001722967,KCL CAPITAL, L.P.',
    name: 'KCL CAPITAL, L.P.',
    label: 'KCL CAPITAL, L.P.',
  },
  {
    cik: '0001109228',
    value: '0001109228,KCM INVESTMENT ADVISORS LLC',
    name: 'KCM INVESTMENT ADVISORS LLC',
    label: 'KCM INVESTMENT ADVISORS LLC',
  },
  {
    cik: '0001697719',
    value: '0001697719,KCS WEALTH ADVISORY',
    name: 'KCS WEALTH ADVISORY',
    label: 'KCS WEALTH ADVISORY',
  },
  {
    cik: '0001052174',
    value: '0001052174,KDI CAPITAL PARTNERS LLC',
    name: 'KDI CAPITAL PARTNERS LLC',
    label: 'KDI CAPITAL PARTNERS LLC',
  },
  {
    cik: '0001169347',
    value: '0001169347,KEANE CAPITAL MANAGEMENT INC',
    name: 'KEANE CAPITAL MANAGEMENT INC',
    label: 'KEANE CAPITAL MANAGEMENT INC',
  },
  {
    cik: '0001674599',
    value: '0001674599,KEANE CAPITAL PARTNERS, LLC',
    name: 'KEANE CAPITAL PARTNERS, LLC',
    label: 'KEANE CAPITAL PARTNERS, LLC',
  },
  {
    cik: '0001908695',
    value: '0001908695,KEARNS & ASSOCIATES LLC',
    name: 'KEARNS & ASSOCIATES LLC',
    label: 'KEARNS & ASSOCIATES LLC',
  },
  {
    cik: '0001020580',
    value: '0001020580,KEATING INVESTMENT COUNSELORS INC',
    name: 'KEATING INVESTMENT COUNSELORS INC',
    label: 'KEATING INVESTMENT COUNSELORS INC',
  },
  {
    cik: '0001903035',
    value: '0001903035,KEB ASSET MANAGEMENT, LLC',
    name: 'KEB ASSET MANAGEMENT, LLC',
    label: 'KEB ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001787147',
    value: '0001787147,KEEBECK ALPHA, LP',
    name: 'KEEBECK ALPHA, LP',
    label: 'KEEBECK ALPHA, LP',
  },
  {
    cik: '0001803675',
    value: '0001803675,KEEBECK WEALTH MANAGEMENT, LLC',
    name: 'KEEBECK WEALTH MANAGEMENT, LLC',
    label: 'KEEBECK WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001730660',
    value: '0001730660,KEELER THOMAS MANAGEMENT LLC',
    name: 'KEELER THOMAS MANAGEMENT LLC',
    label: 'KEELER THOMAS MANAGEMENT LLC',
  },
  {
    cik: '0001056504',
    value: '0001056504,KEELEY ASSET MANAGEMENT CORP',
    name: 'KEELEY ASSET MANAGEMENT CORP',
    label: 'KEELEY ASSET MANAGEMENT CORP',
  },
  {
    cik: '0001700530',
    value: '0001700530,KEELEY-TETON ADVISORS, LLC',
    name: 'KEELEY-TETON ADVISORS, LLC',
    label: 'KEELEY-TETON ADVISORS, LLC',
  },
  {
    cik: '0001633038',
    value: '0001633038,KEEL POINT ADVISORS, LLC',
    name: 'KEEL POINT ADVISORS, LLC',
    label: 'KEEL POINT ADVISORS, LLC',
  },
  {
    cik: '0001386935',
    value: '0001386935,KEEL POINT, LLC',
    name: 'KEEL POINT, LLC',
    label: 'KEEL POINT, LLC',
  },
  {
    cik: '0001647363',
    value: '0001647363,KEENAN CAPITAL, LLC',
    name: 'KEENAN CAPITAL, LLC',
    label: 'KEENAN CAPITAL, LLC',
  },
  {
    cik: '0001907874',
    value: '0001907874,KEENE & ASSOCIATES, INC.',
    name: 'KEENE & ASSOCIATES, INC.',
    label: 'KEENE & ASSOCIATES, INC.',
  },
  {
    cik: '0001776701',
    value: '0001776701,KEHRS RIDGE CAPITAL,LLC',
    name: 'KEHRS RIDGE CAPITAL,LLC',
    label: 'KEHRS RIDGE CAPITAL,LLC',
  },
  {
    cik: '0001910381',
    value: '0001910381,KELLETT SCHAFFNER WEALTH ADVISORS LLC',
    name: 'KELLETT SCHAFFNER WEALTH ADVISORS LLC',
    label: 'KELLETT SCHAFFNER WEALTH ADVISORS LLC',
  },
  {
    cik: '0001550188',
    value: '0001550188,KELLNER CAPITAL, LLC',
    name: 'KELLNER CAPITAL, LLC',
    label: 'KELLNER CAPITAL, LLC',
  },
  {
    cik: '0001197921',
    value: '0001197921,KELLOGG W K FOUNDATION TRUST',
    name: 'KELLOGG W K FOUNDATION TRUST',
    label: 'KELLOGG W K FOUNDATION TRUST',
  },
  {
    cik: '0000920441',
    value: '0000920441,KELLY LAWRENCE W & ASSOCIATES INC/CA',
    name: 'KELLY LAWRENCE W & ASSOCIATES INC/CA',
    label: 'KELLY LAWRENCE W & ASSOCIATES INC/CA',
  },
  {
    cik: '0001672067',
    value: '0001672067,KELMAN-LAZAROV, INC.',
    name: 'KELMAN-LAZAROV, INC.',
    label: 'KELMAN-LAZAROV, INC.',
  },
  {
    cik: '0001600083',
    value: '0001600083,KELSO & COMPANY, L.P.',
    name: 'KELSO & COMPANY, L.P.',
    label: 'KELSO & COMPANY, L.P.',
  },
  {
    cik: '0001555283',
    value: '0001555283,KEMNAY ADVISORY SERVICES INC.',
    name: 'KEMNAY ADVISORY SERVICES INC.',
    label: 'KEMNAY ADVISORY SERVICES INC.',
  },
  {
    cik: '0001367401',
    value: '0001367401,KEMPEN CAPITAL MANAGEMENT N.V.',
    name: 'KEMPEN CAPITAL MANAGEMENT N.V.',
    label: 'KEMPEN CAPITAL MANAGEMENT N.V.',
  },
  {
    cik: '0000860748',
    value: '0000860748,KEMPER CORP',
    name: 'KEMPER CORP',
    label: 'KEMPER CORP',
  },
  {
    cik: '0001352900',
    value: '0001352900,KEMPER CORP MASTER RETIREMENT TRUST',
    name: 'KEMPER CORP MASTER RETIREMENT TRUST',
    label: 'KEMPER CORP MASTER RETIREMENT TRUST',
  },
  {
    cik: '0000898399',
    value: '0000898399,KEMPNER CAPITAL MANAGEMENT INC.',
    name: 'KEMPNER CAPITAL MANAGEMENT INC.',
    label: 'KEMPNER CAPITAL MANAGEMENT INC.',
  },
  {
    cik: '0001731372',
    value: '0001731372,KENDALL CAPITAL MANAGEMENT',
    name: 'KENDALL CAPITAL MANAGEMENT',
    label: 'KENDALL CAPITAL MANAGEMENT',
  },
  {
    cik: '0001533212',
    value: '0001533212,KENDALL SQUARE CAPITAL, LLC',
    name: 'KENDALL SQUARE CAPITAL, LLC',
    label: 'KENDALL SQUARE CAPITAL, LLC',
  },
  {
    cik: '0001908904',
    value: '0001908904,KENFARB & CO.',
    name: 'KENFARB & CO.',
    label: 'KENFARB & CO.',
  },
  {
    cik: '0001324869',
    value: '0001324869,KENMARE CAPITAL PARTNERS, L.L.C.',
    name: 'KENMARE CAPITAL PARTNERS, L.L.C.',
    label: 'KENMARE CAPITAL PARTNERS, L.L.C.',
  },
  {
    cik: '0000884589',
    value: '0000884589,KENNEDY CAPITAL MANAGEMENT, INC.',
    name: 'KENNEDY CAPITAL MANAGEMENT, INC.',
    label: 'KENNEDY CAPITAL MANAGEMENT, INC.',
  },
  {
    cik: '0001797633',
    value: '0001797633,KENNEDY LEWIS MANAGEMENT LP',
    name: 'KENNEDY LEWIS MANAGEMENT LP',
    label: 'KENNEDY LEWIS MANAGEMENT LP',
  },
  {
    cik: '0001863265',
    value: '0001863265,KENNICOTT CAPITAL MANAGEMENT LLC',
    name: 'KENNICOTT CAPITAL MANAGEMENT LLC',
    label: 'KENNICOTT CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001963352',
    value: '0001963352,KENNON-GREEN & COMPANY, LLC',
    name: 'KENNON-GREEN & COMPANY, LLC',
    label: 'KENNON-GREEN & COMPANY, LLC',
  },
  {
    cik: '0001113000',
    value: '0001113000,KENSICO CAPITAL MANAGEMENT CORP',
    name: 'KENSICO CAPITAL MANAGEMENT CORP',
    label: 'KENSICO CAPITAL MANAGEMENT CORP',
  },
  {
    cik: '0001845536',
    value: '0001845536,KENSINGTON INVESTMENT COUNSEL, LLC',
    name: 'KENSINGTON INVESTMENT COUNSEL, LLC',
    label: 'KENSINGTON INVESTMENT COUNSEL, LLC',
  },
  {
    cik: '0001653168',
    value: '0001653168,KEN STERN & ASSOCIATES, INC.',
    name: 'KEN STERN & ASSOCIATES, INC.',
    label: 'KEN STERN & ASSOCIATES, INC.',
  },
  {
    cik: '0001777141',
    value: '0001777141,KENT LAKE CAPITAL LLC',
    name: 'KENT LAKE CAPITAL LLC',
    label: 'KENT LAKE CAPITAL LLC',
  },
  {
    cik: '0001537783',
    value: '0001537783,KENTUCKY RETIREMENT SYSTEMS',
    name: 'KENTUCKY RETIREMENT SYSTEMS',
    label: 'KENTUCKY RETIREMENT SYSTEMS',
  },
  {
    cik: '0001584686',
    value: '0001584686,KENTUCKY RETIREMENT SYSTEMS INSURANCE TRUST FUND',
    name: 'KENTUCKY RETIREMENT SYSTEMS INSURANCE TRUST FUND',
    label: 'KENTUCKY RETIREMENT SYSTEMS INSURANCE TRUST FUND',
  },
  {
    cik: '0001512020',
    value: '0001512020,KEPOS CAPITAL LP',
    name: 'KEPOS CAPITAL LP',
    label: 'KEPOS CAPITAL LP',
  },
  {
    cik: '0001912523',
    value: '0001912523,KERBERROSE WEALTH MANAGEMENT, LLC',
    name: 'KERBERROSE WEALTH MANAGEMENT, LLC',
    label: 'KERBERROSE WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001890748',
    value: '0001890748,KERCHEVILLE ADVISORS, LLC',
    name: 'KERCHEVILLE ADVISORS, LLC',
    label: 'KERCHEVILLE ADVISORS, LLC',
  },
  {
    cik: '0001478434',
    value: '0001478434,KERN COBALT CO-INVEST PARTNERS AP LP',
    name: 'KERN COBALT CO-INVEST PARTNERS AP LP',
    label: 'KERN COBALT CO-INVEST PARTNERS AP LP',
  },
  {
    cik: '0001589989',
    value: '0001589989,KERNODLE & KATON ASSET MANAGEMENT GROUP, LLC',
    name: 'KERNODLE & KATON ASSET MANAGEMENT GROUP, LLC',
    label: 'KERNODLE & KATON ASSET MANAGEMENT GROUP, LLC',
  },
  {
    cik: '0001804116',
    value: '0001804116,KERNTKE OTTO MCGLONE WEALTH MANAGEMENT GROUP',
    name: 'KERNTKE OTTO MCGLONE WEALTH MANAGEMENT GROUP',
    label: 'KERNTKE OTTO MCGLONE WEALTH MANAGEMENT GROUP',
  },
  {
    cik: '0001569688',
    value: '0001569688,KERRISDALE ADVISERS, LLC',
    name: 'KERRISDALE ADVISERS, LLC',
    label: 'KERRISDALE ADVISERS, LLC',
  },
  {
    cik: '0001840455',
    value: '0001840455,KESLER, NORMAN & WRIDE, LLC',
    name: 'KESLER, NORMAN & WRIDE, LLC',
    label: 'KESLER, NORMAN & WRIDE, LLC',
  },
  {
    cik: '0001525947',
    value: '0001525947,KESSLER INVESTMENT GROUP, LLC',
    name: 'KESSLER INVESTMENT GROUP, LLC',
    label: 'KESSLER INVESTMENT GROUP, LLC',
  },
  {
    cik: '0001830819',
    value: '0001830819,KESTRA ADVISORY SERVICES, LLC',
    name: 'KESTRA ADVISORY SERVICES, LLC',
    label: 'KESTRA ADVISORY SERVICES, LLC',
  },
  {
    cik: '0001649451',
    value: '0001649451,KESTRA PRIVATE WEALTH SERVICES, LLC',
    name: 'KESTRA PRIVATE WEALTH SERVICES, LLC',
    label: 'KESTRA PRIVATE WEALTH SERVICES, LLC',
  },
  {
    cik: '0000917579',
    value: '0000917579,KESTREL INVESTMENT MANAGEMENT CORP',
    name: 'KESTREL INVESTMENT MANAGEMENT CORP',
    label: 'KESTREL INVESTMENT MANAGEMENT CORP',
  },
  {
    cik: '0001387369',
    value: '0001387369,KETTLE HILL CAPITAL MANAGEMENT, LLC',
    name: 'KETTLE HILL CAPITAL MANAGEMENT, LLC',
    label: 'KETTLE HILL CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001785498',
    value: '0001785498,KEUDELL/MORRISON WEALTH MANAGEMENT',
    name: 'KEUDELL/MORRISON WEALTH MANAGEMENT',
    label: 'KEUDELL/MORRISON WEALTH MANAGEMENT',
  },
  {
    cik: '0001089877',
    value: '0001089877,KEYBANK NATIONAL ASSOCIATION/OH',
    name: 'KEYBANK NATIONAL ASSOCIATION/OH',
    label: 'KEYBANK NATIONAL ASSOCIATION/OH',
  },
  {
    cik: '0001912612',
    value: '0001912612,KEY CLIENT FIDUCIARY ADVISORS, LLC',
    name: 'KEY CLIENT FIDUCIARY ADVISORS, LLC',
    label: 'KEY CLIENT FIDUCIARY ADVISORS, LLC',
  },
  {
    cik: '0001336885',
    value: '0001336885,KEY COLONY MANAGEMENT, LLC',
    name: 'KEY COLONY MANAGEMENT, LLC',
    label: 'KEY COLONY MANAGEMENT, LLC',
  },
  {
    cik: '0001801720',
    value: '0001801720,KEY FINANCIAL INC',
    name: 'KEY FINANCIAL INC',
    label: 'KEY FINANCIAL INC',
  },
  {
    cik: '0001830175',
    value: '0001830175,KEYFRAME CAPITAL PARTNERS, L.P.',
    name: 'KEYFRAME CAPITAL PARTNERS, L.P.',
    label: 'KEYFRAME CAPITAL PARTNERS, L.P.',
  },
  {
    cik: '0001549641',
    value: '0001549641,KEY GROUP HOLDINGS (CAYMAN), LTD.',
    name: 'KEY GROUP HOLDINGS (CAYMAN), LTD.',
    label: 'KEY GROUP HOLDINGS (CAYMAN), LTD.',
  },
  {
    cik: '0001571207',
    value: '0001571207,KEYPOINT CAPITAL MANAGEMENT, LLC',
    name: 'KEYPOINT CAPITAL MANAGEMENT, LLC',
    label: 'KEYPOINT CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001662970',
    value: '0001662970,KEY SQUARE CAPITAL MANAGEMENT LLC',
    name: 'KEY SQUARE CAPITAL MANAGEMENT LLC',
    label: 'KEY SQUARE CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001729299',
    value: '0001729299,KEYSTONE FINANCIAL GROUP',
    name: 'KEYSTONE FINANCIAL GROUP',
    label: 'KEYSTONE FINANCIAL GROUP',
  },
  {
    cik: '0001596906',
    value: '0001596906,KEYSTONE FINANCIAL PLANNING, INC.',
    name: 'KEYSTONE FINANCIAL PLANNING, INC.',
    label: 'KEYSTONE FINANCIAL PLANNING, INC.',
  },
  {
    cik: '0001865158',
    value: '0001865158,KEYSTONE FINANCIAL SERVICES',
    name: 'KEYSTONE FINANCIAL SERVICES',
    label: 'KEYSTONE FINANCIAL SERVICES',
  },
  {
    cik: '0001845980',
    value: '0001845980,KEYSTONE WEALTH PARTNERS',
    name: 'KEYSTONE WEALTH PARTNERS',
    label: 'KEYSTONE WEALTH PARTNERS',
  },
  {
    cik: '0001866189',
    value: '0001866189,KEYSTONE WEALTH SERVICES, LLC',
    name: 'KEYSTONE WEALTH SERVICES, LLC',
    label: 'KEYSTONE WEALTH SERVICES, LLC',
  },
  {
    cik: '0001474069',
    value: '0001474069,KEYWISE CAPITAL MANAGEMENT (HK) LTD',
    name: 'KEYWISE CAPITAL MANAGEMENT (HK) LTD',
    label: 'KEYWISE CAPITAL MANAGEMENT (HK) LTD',
  },
  {
    cik: '0001473434',
    value: '0001473434,KEYWISE CAPITAL MANAGEMENT LTD',
    name: 'KEYWISE CAPITAL MANAGEMENT LTD',
    label: 'KEYWISE CAPITAL MANAGEMENT LTD',
  },
  {
    cik: '0001800556',
    value: '0001800556,KFA PRIVATE WEALTH GROUP, LLC',
    name: 'KFA PRIVATE WEALTH GROUP, LLC',
    label: 'KFA PRIVATE WEALTH GROUP, LLC',
  },
  {
    cik: '0001919344',
    value: '0001919344,KFG WEALTH MANAGEMENT, LLC',
    name: 'KFG WEALTH MANAGEMENT, LLC',
    label: 'KFG WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001657043',
    value: '0001657043,KG FUNDS MANAGEMENT, LLC',
    name: 'KG FUNDS MANAGEMENT, LLC',
    label: 'KG FUNDS MANAGEMENT, LLC',
  },
  {
    cik: '0001549641',
    value: '000154,KGH LTD',
    name: 'KGH LTD',
    label: 'KGH LTD',
  },
  {
    cik: '0001771687',
    value: '0001771687,KG&L CAPITAL MANAGEMENT,LLC',
    name: 'KG&L CAPITAL MANAGEMENT,LLC',
    label: 'KG&L CAPITAL MANAGEMENT,LLC',
  },
  {
    cik: '0001671971',
    value: '0001671971,KHP CAPITAL, LLC',
    name: 'KHP CAPITAL, LLC',
    label: 'KHP CAPITAL, LLC',
  },
  {
    cik: '0001877963',
    value: '0001877963,KHROM CAPITAL MANAGEMENT LLC',
    name: 'KHROM CAPITAL MANAGEMENT LLC',
    label: 'KHROM CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001314377',
    value: '0001314377,KIDDER STEPHEN W',
    name: 'KIDDER STEPHEN W',
    label: 'KIDDER STEPHEN W',
  },
  {
    cik: '0001352278',
    value: '0001352278,KIDRON CAPITAL LLC',
    name: 'KIDRON CAPITAL LLC',
    label: 'KIDRON CAPITAL LLC',
  },
  {
    cik: '0001909180',
    value: '0001909180,KIDS CAPITAL MANAGEMENT, L.P.',
    name: 'KIDS CAPITAL MANAGEMENT, L.P.',
    label: 'KIDS CAPITAL MANAGEMENT, L.P.',
  },
  {
    cik: '0001537931',
    value: '0001537931,KILDONAN CASTLE ASSET MANAGEMENT L.P.',
    name: 'KILDONAN CASTLE ASSET MANAGEMENT L.P.',
    label: 'KILDONAN CASTLE ASSET MANAGEMENT L.P.',
  },
  {
    cik: '0001723223',
    value: '0001723223,KILEY JUERGENS WEALTH MANAGEMENT, LLC',
    name: 'KILEY JUERGENS WEALTH MANAGEMENT, LLC',
    label: 'KILEY JUERGENS WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0000861465',
    value: '0000861465,KILLEN GROUP INC',
    name: 'KILLEN GROUP INC',
    label: 'KILLEN GROUP INC',
  },
  {
    cik: '0001582633',
    value: '0001582633,KILTEARN PARTNERS LLP',
    name: 'KILTEARN PARTNERS LLP',
    label: 'KILTEARN PARTNERS LLP',
  },
  {
    cik: '0001318103',
    value: '0001318103,KIMELMAN & BAIRD, LLC',
    name: 'KIMELMAN & BAIRD, LLC',
    label: 'KIMELMAN & BAIRD, LLC',
  },
  {
    cik: '0001888968',
    value: '0001888,KIM, LLC',
    name: 'KIM, LLC',
    label: 'KIM, LLC',
  },
  {
    cik: '0001706220',
    value: '0001706220,KIMMERIDGE ENERGY MANAGEMENT COMPANY, LLC',
    name: 'KIMMERIDGE ENERGY MANAGEMENT COMPANY, LLC',
    label: 'KIMMERIDGE ENERGY MANAGEMENT COMPANY, LLC',
  },
  {
    cik: '0001454409',
    value: '0001454409,KINGDOM RIDGE CAPITAL, LLC',
    name: 'KINGDOM RIDGE CAPITAL, LLC',
    label: 'KINGDOM RIDGE CAPITAL, LLC',
  },
  {
    cik: '0001000097',
    value: '0001000097,KINGDON CAPITAL MANAGEMENT, L.L.C.',
    name: 'KINGDON CAPITAL MANAGEMENT, L.L.C.',
    label: 'KINGDON CAPITAL MANAGEMENT, L.L.C.',
  },
  {
    cik: '0001483170',
    value: '0001483170,KINGFISHER CAPITAL LLC',
    name: 'KINGFISHER CAPITAL LLC',
    label: 'KINGFISHER CAPITAL LLC',
  },
  {
    cik: '0000794221',
    value: '0000794221,KING INVESTMENT ADVISORS INC/TX',
    name: 'KING INVESTMENT ADVISORS INC/TX',
    label: 'KING INVESTMENT ADVISORS INC/TX',
  },
  {
    cik: '0000310051',
    value: '0000310051,KING LUTHER CAPITAL MANAGEMENT CORP',
    name: 'KING LUTHER CAPITAL MANAGEMENT CORP',
    label: 'KING LUTHER CAPITAL MANAGEMENT CORP',
  },
  {
    cik: '0001927315',
    value: '0001927315,KINGSBURY CAPITAL INVESTMENT ADVISORS LLC',
    name: 'KINGSBURY CAPITAL INVESTMENT ADVISORS LLC',
    label: 'KINGSBURY CAPITAL INVESTMENT ADVISORS LLC',
  },
  {
    cik: '0001388736',
    value: '0001388736,KINGS POINT CAPITAL MANAGEMENT',
    name: 'KINGS POINT CAPITAL MANAGEMENT',
    label: 'KINGS POINT CAPITAL MANAGEMENT',
  },
  {
    cik: '0001458425',
    value: '0001458425,KINGSTOWN CAPITAL MANAGEMENT L.P.',
    name: 'KINGSTOWN CAPITAL MANAGEMENT L.P.',
    label: 'KINGSTOWN CAPITAL MANAGEMENT L.P.',
  },
  {
    cik: '0001218199',
    value: '0001218199,KING STREET CAPITAL MANAGEMENT, L.P.',
    name: 'KING STREET CAPITAL MANAGEMENT, L.P.',
    label: 'KING STREET CAPITAL MANAGEMENT, L.P.',
  },
  {
    cik: '0001791965',
    value: '0001791965,KINGSVIEW WEALTH MANAGEMENT, LLC',
    name: 'KINGSVIEW WEALTH MANAGEMENT, LLC',
    label: 'KINGSVIEW WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001568235',
    value: '0001568235,KING WEALTH',
    name: 'KING WEALTH',
    label: 'KING WEALTH',
  },
  {
    cik: '0001818557',
    value: '0001818557,KINLOCH CAPITAL, LLC',
    name: 'KINLOCH CAPITAL, LLC',
    label: 'KINLOCH CAPITAL, LLC',
  },
  {
    cik: '0001569518',
    value: '0001569518,KINNERET ADVISORY, LLC',
    name: 'KINNERET ADVISORY, LLC',
    label: 'KINNERET ADVISORY, LLC',
  },
  {
    cik: '0001766574',
    value: '0001766574,KINNEVIK AB (PUBL)',
    name: 'KINNEVIK AB (PUBL)',
    label: 'KINNEVIK AB (PUBL)',
  },
  {
    cik: '0001803121',
    value: '0001803121,KINNEY ASSET MANAGEMENT, LLC',
    name: 'KINNEY ASSET MANAGEMENT, LLC',
    label: 'KINNEY ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001669162',
    value: '0001669162,KINSALE CAPITAL GROUP, INC.',
    name: 'KINSALE CAPITAL GROUP, INC.',
    label: 'KINSALE CAPITAL GROUP, INC.',
  },
  {
    cik: '0001557513',
    value: '0001557513,KINSIGHT, LLC',
    name: 'KINSIGHT, LLC',
    label: 'KINSIGHT, LLC',
  },
  {
    cik: '0001795594',
    value: '0001795594,KIRKOSWALD ASSET MANAGEMENT LLC',
    name: 'KIRKOSWALD ASSET MANAGEMENT LLC',
    label: 'KIRKOSWALD ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001876477',
    value: '0001876477,KIRON CAPITAL GESTAO DE RECURSOS LTDA.',
    name: 'KIRON CAPITAL GESTAO DE RECURSOS LTDA.',
    label: 'KIRON CAPITAL GESTAO DE RECURSOS LTDA.',
  },
  {
    cik: '0000764112',
    value: '0000764112,KIRR MARBACH & CO LLC /IN/',
    name: 'KIRR MARBACH & CO LLC /IN/',
    label: 'KIRR MARBACH & CO LLC /IN/',
  },
  {
    cik: '0001667434',
    value: '0001667434,KISKI PARTNERS, LLC',
    name: 'KISKI PARTNERS, LLC',
    label: 'KISKI PARTNERS, LLC',
  },
  {
    cik: '0001684769',
    value: '0001684769,KISSINGER FINANCIAL SERVICES LLC',
    name: 'KISSINGER FINANCIAL SERVICES LLC',
    label: 'KISSINGER FINANCIAL SERVICES LLC',
  },
  {
    cik: '0001541897',
    value: '0001541897,KISTLER-TIFFANY COMPANIES, LLC',
    name: 'KISTLER-TIFFANY COMPANIES, LLC',
    label: 'KISTLER-TIFFANY COMPANIES, LLC',
  },
  {
    cik: '0001687444',
    value: '0001687444,KITE LAKE CAPITAL MANAGEMENT (UK) LLP',
    name: 'KITE LAKE CAPITAL MANAGEMENT (UK) LLP',
    label: 'KITE LAKE CAPITAL MANAGEMENT (UK) LLP',
  },
  {
    cik: '0001166620',
    value: '0001166620,KITZINGER LAUTMANN CAPITAL MANAGEMENT INC',
    name: 'KITZINGER LAUTMANN CAPITAL MANAGEMENT INC',
    label: 'KITZINGER LAUTMANN CAPITAL MANAGEMENT INC',
  },
  {
    cik: '0001555829',
    value: '0001555829,KIWI WEALTH INVESTMENTS LIMITED PARTNERSHIP',
    name: 'KIWI WEALTH INVESTMENTS LIMITED PARTNERSHIP',
    label: 'KIWI WEALTH INVESTMENTS LIMITED PARTNERSHIP',
  },
  {
    cik: '0001798860',
    value: '0001798860,KIZE CAPITAL LP',
    name: 'KIZE CAPITAL LP',
    label: 'KIZE CAPITAL LP',
  },
  {
    cik: '0001418359',
    value: '0001418359,K.J. HARRISON & PARTNERS INC',
    name: 'K.J. HARRISON & PARTNERS INC',
    label: 'K.J. HARRISON & PARTNERS INC',
  },
  {
    cik: '0001819279',
    value: '0001819279,KLAAS FINANCIAL ASSET ADVISORS, LLC',
    name: 'KLAAS FINANCIAL ASSET ADVISORS, LLC',
    label: 'KLAAS FINANCIAL ASSET ADVISORS, LLC',
  },
  {
    cik: '0001166620',
    value: '0001166620,KLCM ADVISORS, INC.',
    name: 'KLCM ADVISORS, INC.',
    label: 'KLCM ADVISORS, INC.',
  },
  {
    cik: '0001410251',
    value: '0001410251,KLEINWORT BENSON INVESTORS DUBLIN LTD',
    name: 'KLEINWORT BENSON INVESTORS DUBLIN LTD',
    label: 'KLEINWORT BENSON INVESTORS DUBLIN LTD',
  },
  {
    cik: '0000859872',
    value: '0000859872,KLINGENSTEIN FIELDS & CO LLC',
    name: 'KLINGENSTEIN FIELDS & CO LLC',
    label: 'KLINGENSTEIN FIELDS & CO LLC',
  },
  {
    cik: '0000859872',
    value: '0000859872,KLINGENSTEIN FIELDS & CO LP',
    name: 'KLINGENSTEIN FIELDS & CO LP',
    label: 'KLINGENSTEIN FIELDS & CO LP',
  },
  {
    cik: '0001662449',
    value: '0001662449,KLINGMAN & ASSOCIATES, LLC',
    name: 'KLINGMAN & ASSOCIATES, LLC',
    label: 'KLINGMAN & ASSOCIATES, LLC',
  },
  {
    cik: '0001018327',
    value: '0001018327,KLINSKY STEVEN B',
    name: 'KLINSKY STEVEN B',
    label: 'KLINSKY STEVEN B',
  },
  {
    cik: '0001831683',
    value: '0001831683,KLK CAPITAL MANAGEMENT LLC',
    name: 'KLK CAPITAL MANAGEMENT LLC',
    label: 'KLK CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001593399',
    value: '0001593399,KLP ENTERPRISES, LLC',
    name: 'KLP ENTERPRISES, LLC',
    label: 'KLP ENTERPRISES, LLC',
  },
  {
    cik: '0001616051',
    value: '0001616051,KLS DIVERSIFIED ASSET MANAGEMENT LP',
    name: 'KLS DIVERSIFIED ASSET MANAGEMENT LP',
    label: 'KLS DIVERSIFIED ASSET MANAGEMENT LP',
  },
  {
    cik: '0001532292',
    value: '0001532292,KLS PROFESSIONAL ADVISORS GROUP, LLC',
    name: 'KLS PROFESSIONAL ADVISORS GROUP, LLC',
    label: 'KLS PROFESSIONAL ADVISORS GROUP, LLC',
  },
  {
    cik: '0001773205',
    value: '0001773205,KMG FIDUCIARY PARTNERS, LLC',
    name: 'KMG FIDUCIARY PARTNERS, LLC',
    label: 'KMG FIDUCIARY PARTNERS, LLC',
  },
  {
    cik: '0001580308',
    value: '0001580308,KMS FINANCIAL SERVICES, INC',
    name: 'KMS FINANCIAL SERVICES, INC',
    label: 'KMS FINANCIAL SERVICES, INC',
  },
  {
    cik: '0001512397',
    value: '0001512397,KNIGHTHEAD CAPITAL MANAGEMENT, LLC',
    name: 'KNIGHTHEAD CAPITAL MANAGEMENT, LLC',
    label: 'KNIGHTHEAD CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001332811',
    value: '0001332811,KNIGHTSBRIDGE ASSET MANAGEMENT, LLC',
    name: 'KNIGHTSBRIDGE ASSET MANAGEMENT, LLC',
    label: 'KNIGHTSBRIDGE ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001688666',
    value: '0001688666,KNIGHTS OF COLUMBUS ASSET ADVISORS LLC',
    name: 'KNIGHTS OF COLUMBUS ASSET ADVISORS LLC',
    label: 'KNIGHTS OF COLUMBUS ASSET ADVISORS LLC',
  },
  {
    cik: '0001325083',
    value: '0001325083,KNOLL CAPITAL MANAGEMENT, LLC',
    name: 'KNOLL CAPITAL MANAGEMENT, LLC',
    label: 'KNOLL CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0000808722',
    value: '0000808722,KNOTT DAVID M',
    name: 'KNOTT DAVID M',
    label: 'KNOTT DAVID M',
  },
  {
    cik: '0001892770',
    value: '0001892770,KNOTT DAVID M JR',
    name: 'KNOTT DAVID M JR',
    label: 'KNOTT DAVID M JR',
  },
  {
    cik: '0001563223',
    value: '0001563223,KNOWLEDGE LEADERS CAPITAL, LLC',
    name: 'KNOWLEDGE LEADERS CAPITAL, LLC',
    label: 'KNOWLEDGE LEADERS CAPITAL, LLC',
  },
  {
    cik: '0001768089',
    value: '0001768089,KNUFF & CO LLC',
    name: 'KNUFF & CO LLC',
    label: 'KNUFF & CO LLC',
  },
  {
    cik: '0000923338',
    value: '0000923338,KOCH INDUSTRIES INC',
    name: 'KOCH INDUSTRIES INC',
    label: 'KOCH INDUSTRIES INC',
  },
  {
    cik: '0001399770',
    value: '0001399770,KOHLBERG KRAVIS ROBERTS & CO. L.P.',
    name: 'KOHLBERG KRAVIS ROBERTS & CO. L.P.',
    label: 'KOHLBERG KRAVIS ROBERTS & CO. L.P.',
  },
  {
    cik: '0001696615',
    value: '0001696615,KOHMANN BOSSHARD FINANCIAL SERVICES, LLC',
    name: 'KOHMANN BOSSHARD FINANCIAL SERVICES, LLC',
    label: 'KOHMANN BOSSHARD FINANCIAL SERVICES, LLC',
  },
  {
    cik: '0001623716',
    value: '000162371,KOKINO LLC',
    name: 'KOKINO LLC',
    label: 'KOKINO LLC',
  },
  {
    cik: '0001466067',
    value: '0001466067,KOKUSAI ASSET MANAGEMENT CO., LTD.',
    name: 'KOKUSAI ASSET MANAGEMENT CO., LTD.',
    label: 'KOKUSAI ASSET MANAGEMENT CO., LTD.',
  },
  {
    cik: '0001904326',
    value: '0001904326,KOLINSKY WEALTH MANAGEMENT, LLC',
    name: 'KOLINSKY WEALTH MANAGEMENT, LLC',
    label: 'KOLINSKY WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001713390',
    value: '0001713390,KONTIKI CAPITAL MANAGEMENT (HK) LTD.',
    name: 'KONTIKI CAPITAL MANAGEMENT (HK) LTD.',
    label: 'KONTIKI CAPITAL MANAGEMENT (HK) LTD.',
  },
  {
    cik: '0001961850',
    value: '0001961850,KOOMAN & ASSOCIATES',
    name: 'KOOMAN & ASSOCIATES',
    label: 'KOOMAN & ASSOCIATES',
  },
  {
    cik: '0001026013',
    value: '0001026013,KOONCE CALVIN SCOTT',
    name: 'KOONCE CALVIN SCOTT',
    label: 'KOONCE CALVIN SCOTT',
  },
  {
    cik: '0001581500',
    value: '0001581500,KOPERNIK GLOBAL ALL-CAP FUND, LP',
    name: 'KOPERNIK GLOBAL ALL-CAP FUND, LP',
    label: 'KOPERNIK GLOBAL ALL-CAP FUND, LP',
  },
  {
    cik: '0001599814',
    value: '0001599814,KOPERNIK GLOBAL INVESTORS, LLC',
    name: 'KOPERNIK GLOBAL INVESTORS, LLC',
    label: 'KOPERNIK GLOBAL INVESTORS, LLC',
  },
  {
    cik: '0001728689',
    value: '0001728689,KOPION ASSET MANAGEMENT, LLC',
    name: 'KOPION ASSET MANAGEMENT, LLC',
    label: 'KOPION ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001683689',
    value: '0001683689,KOPP FAMILY OFFICE, LLC',
    name: 'KOPP FAMILY OFFICE, LLC',
    label: 'KOPP FAMILY OFFICE, LLC',
  },
  {
    cik: '0001040566',
    value: '0001040566,KOPP FUNDS INC',
    name: 'KOPP FUNDS INC',
    label: 'KOPP FUNDS INC',
  },
  {
    cik: '0000884306',
    value: '0000884306,KOPP INVESTMENT ADVISORS LLC',
    name: 'KOPP INVESTMENT ADVISORS LLC',
    label: 'KOPP INVESTMENT ADVISORS LLC',
  },
  {
    cik: '0001659815',
    value: '0001659815,KORA MANAGEMENT LP',
    name: 'KORA MANAGEMENT LP',
    label: 'KORA MANAGEMENT LP',
  },
  {
    cik: '0001441689',
    value: '0001441689,KOREA INVESTMENT CORP',
    name: 'KOREA INVESTMENT CORP',
    label: 'KOREA INVESTMENT CORP',
  },
  {
    cik: '0001762776',
    value: '0001762776,KORE PRIVATE WEALTH LLC',
    name: 'KORE PRIVATE WEALTH LLC',
    label: 'KORE PRIVATE WEALTH LLC',
  },
  {
    cik: '0000898358',
    value: '0000898358,KORNITZER CAPITAL MANAGEMENT INC /KS',
    name: 'KORNITZER CAPITAL MANAGEMENT INC /KS',
    label: 'KORNITZER CAPITAL MANAGEMENT INC /KS',
  },
  {
    cik: '0001509855',
    value: '0001509855,KORTRIGHT CAPITAL PARTNERS LP',
    name: 'KORTRIGHT CAPITAL PARTNERS LP',
    label: 'KORTRIGHT CAPITAL PARTNERS LP',
  },
  {
    cik: '0001633389',
    value: '0001633389,KOSHINSKI ASSET MANAGEMENT, INC.',
    name: 'KOSHINSKI ASSET MANAGEMENT, INC.',
    label: 'KOSHINSKI ASSET MANAGEMENT, INC.',
  },
  {
    cik: '0001861163',
    value: '0001861163,KOSS-OLINGER CONSULTING, LLC',
    name: 'KOSS-OLINGER CONSULTING, LLC',
    label: 'KOSS-OLINGER CONSULTING, LLC',
  },
  {
    cik: '0001728321',
    value: '0001728321,KOVACK ADVISORS, INC.',
    name: 'KOVACK ADVISORS, INC.',
    label: 'KOVACK ADVISORS, INC.',
  },
  {
    cik: '0001296434',
    value: '0001296434,KOVITZ INVESTMENT GROUP, LLC',
    name: 'KOVITZ INVESTMENT GROUP, LLC',
    label: 'KOVITZ INVESTMENT GROUP, LLC',
  },
  {
    cik: '0001665198',
    value: '0001665198,KOVITZ INVESTMENT GROUP PARTNERS, LLC',
    name: 'KOVITZ INVESTMENT GROUP PARTNERS, LLC',
    label: 'KOVITZ INVESTMENT GROUP PARTNERS, LLC',
  },
  {
    cik: '0001846786',
    value: '0001846786,KOWAL INVESTMENT GROUP, LLC',
    name: 'KOWAL INVESTMENT GROUP, LLC',
    label: 'KOWAL INVESTMENT GROUP, LLC',
  },
  {
    cik: '0001764970',
    value: '0001764970,KOZAK & ASSOCIATES, INC.',
    name: 'KOZAK & ASSOCIATES, INC.',
    label: 'KOZAK & ASSOCIATES, INC.',
  },
  {
    cik: '0001537345',
    value: '0001537345,KPCB DGF ASSOCIATES, LLC',
    name: 'KPCB DGF ASSOCIATES, LLC',
    label: 'KPCB DGF ASSOCIATES, LLC',
  },
  {
    cik: '0001611737',
    value: '0001611737,KPCB DGF II ASSOCIATES, LLC',
    name: 'KPCB DGF II ASSOCIATES, LLC',
    label: 'KPCB DGF II ASSOCIATES, LLC',
  },
  {
    cik: '0001678001',
    value: '0001678001,KPCB DGF III ASSOCIATES, LLC',
    name: 'KPCB DGF III ASSOCIATES, LLC',
    label: 'KPCB DGF III ASSOCIATES, LLC',
  },
  {
    cik: '0001500596',
    value: '0001500596,KPCB GGF ASSOCIATES, LLC',
    name: 'KPCB GGF ASSOCIATES, LLC',
    label: 'KPCB GGF ASSOCIATES, LLC',
  },
  {
    cik: '0001500599',
    value: '0001500599,KPCB XII ASSOCIATES, LLC',
    name: 'KPCB XII ASSOCIATES, LLC',
    label: 'KPCB XII ASSOCIATES, LLC',
  },
  {
    cik: '0001500595',
    value: '0001500595,KPCB XIII ASSOCIATES, LLC',
    name: 'KPCB XIII ASSOCIATES, LLC',
    label: 'KPCB XIII ASSOCIATES, LLC',
  },
  {
    cik: '0001546196',
    value: '0001546196,KPCB XIV ASSOCIATES, LLC',
    name: 'KPCB XIV ASSOCIATES, LLC',
    label: 'KPCB XIV ASSOCIATES, LLC',
  },
  {
    cik: '0001547838',
    value: '0001547838,KPCB XV ASSOCIATES, LLC',
    name: 'KPCB XV ASSOCIATES, LLC',
    label: 'KPCB XV ASSOCIATES, LLC',
  },
  {
    cik: '0001611734',
    value: '0001611734,KPCB XVI ASSOCIATES, LLC',
    name: 'KPCB XVI ASSOCIATES, LLC',
    label: 'KPCB XVI ASSOCIATES, LLC',
  },
  {
    cik: '0001799162',
    value: '0001799162,KPS GLOBAL ASSET MANAGEMENT UK LTD',
    name: 'KPS GLOBAL ASSET MANAGEMENT UK LTD',
    label: 'KPS GLOBAL ASSET MANAGEMENT UK LTD',
  },
  {
    cik: '0001910852',
    value: '0001910852,KRAEMATON INVESTMENT ADVISORS, INC',
    name: 'KRAEMATON INVESTMENT ADVISORS, INC',
    label: 'KRAEMATON INVESTMENT ADVISORS, INC',
  },
  {
    cik: '0001908611',
    value: '0001908611,KRAFT ASSET MANAGEMENT, LLC',
    name: 'KRAFT ASSET MANAGEMENT, LLC',
    label: 'KRAFT ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001536592',
    value: '0001536592,KRANE FUNDS ADVISORS LLC',
    name: 'KRANE FUNDS ADVISORS LLC',
    label: 'KRANE FUNDS ADVISORS LLC',
  },
  {
    cik: '0001639754',
    value:
      '0001639754,KRANOT HISHTALMUT LE MORIM TICHONIIM HAVERA MENAHELET LTD',
    name: 'KRANOT HISHTALMUT LE MORIM TICHONIIM HAVERA MENAHELET LTD',
    label: 'KRANOT HISHTALMUT LE MORIM TICHONIIM HAVERA MENAHELET LTD',
  },
  {
    cik: '0001639753',
    value:
      '0001639753,KRANOT HISHTALMUT LE MORIM VE GANANOT HAVERA MENAHELET LTD',
    name: 'KRANOT HISHTALMUT LE MORIM VE GANANOT HAVERA MENAHELET LTD',
    label: 'KRANOT HISHTALMUT LE MORIM VE GANANOT HAVERA MENAHELET LTD',
  },
  {
    cik: '0001600307',
    value: '0001600307,KRASNEY FINANCIAL LLC',
    name: 'KRASNEY FINANCIAL LLC',
    label: 'KRASNEY FINANCIAL LLC',
  },
  {
    cik: '0000740918',
    value: '0000740918,KR CAPITAL ADVISORS INC',
    name: 'KR CAPITAL ADVISORS INC',
    label: 'KR CAPITAL ADVISORS INC',
  },
  {
    cik: '0001055948',
    value: '0001055948,KR CAPITAL PARTNERS I L P',
    name: 'KR CAPITAL PARTNERS I L P',
    label: 'KR CAPITAL PARTNERS I L P',
  },
  {
    cik: '0001609251',
    value: '0001609251,KRENSAVAGE ASSET MANAGEMENT, LLC',
    name: 'KRENSAVAGE ASSET MANAGEMENT, LLC',
    label: 'KRENSAVAGE ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0000056822',
    value: '0000056822,KRESGE FOUNDATION',
    name: 'KRESGE FOUNDATION',
    label: 'KRESGE FOUNDATION',
  },
  {
    cik: '0001659047',
    value: '0001659047,KRILOGY FINANCIAL LLC',
    name: 'KRILOGY FINANCIAL LLC',
    label: 'KRILOGY FINANCIAL LLC',
  },
  {
    cik: '0001910876',
    value: '0001910876,KRS CAPITAL MANAGEMENT, LLC',
    name: 'KRS CAPITAL MANAGEMENT, LLC',
    label: 'KRS CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001467517',
    value: '0001467517,KRUEGER & CATALANO CAPITAL PARTNERS, LLC',
    name: 'KRUEGER & CATALANO CAPITAL PARTNERS, LLC',
    label: 'KRUEGER & CATALANO CAPITAL PARTNERS, LLC',
  },
  {
    cik: '0001672472',
    value: '0001672472,KRV CAPITAL LP',
    name: 'KRV CAPITAL LP',
    label: 'KRV CAPITAL LP',
  },
  {
    cik: '0001911807',
    value: '0001911807,KRYGER CAPITAL LTD',
    name: 'KRYGER CAPITAL LTD',
    label: 'KRYGER CAPITAL LTD',
  },
  {
    cik: '0001451643',
    value: '0001451643,KSA CAPITAL MANAGEMENT, LLC',
    name: 'KSA CAPITAL MANAGEMENT, LLC',
    label: 'KSA CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001633629',
    value: '0001633629,KSL ADVISORS, LLC',
    name: 'KSL ADVISORS, LLC',
    label: 'KSL ADVISORS, LLC',
  },
  {
    cik: '0001349834',
    value: '0001349834,KS MANAGEMENT CORP',
    name: 'KS MANAGEMENT CORP',
    label: 'KS MANAGEMENT CORP',
  },
  {
    cik: '0001604723',
    value: '0001604723,KUNTZ LESHER CAPITAL LLC',
    name: 'KUNTZ LESHER CAPITAL LLC',
    label: 'KUNTZ LESHER CAPITAL LLC',
  },
  {
    cik: '0001813203',
    value: '0001813203,KUVARI PARTNERS LLP',
    name: 'KUVARI PARTNERS LLP',
    label: 'KUVARI PARTNERS LLP',
  },
  {
    cik: '0001822236',
    value: '000182223,KWB WEALTH',
    name: 'KWB WEALTH',
    label: 'KWB WEALTH',
  },
  {
    cik: '0001633024',
    value: '00016330,KWMG, LLC',
    name: 'KWMG, LLC',
    label: 'KWMG, LLC',
  },
  {
    cik: '0001512384',
    value: '0001512384,KYLIN MANAGEMENT LLC',
    name: 'KYLIN MANAGEMENT LLC',
    label: 'KYLIN MANAGEMENT LLC',
  },
  {
    cik: '0001907884',
    value: '0001907884,KYNAM CAPITAL MANAGEMENT, LP',
    name: 'KYNAM CAPITAL MANAGEMENT, LP',
    label: 'KYNAM CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0001446440',
    value: '0001446440,KYNIKOS ASSOCIATES LP',
    name: 'KYNIKOS ASSOCIATES LP',
    label: 'KYNIKOS ASSOCIATES LP',
  },
  {
    cik: '0001817646',
    value: '0001817646,L1 CAPITAL PTY LTD',
    name: 'L1 CAPITAL PTY LTD',
    label: 'L1 CAPITAL PTY LTD',
  },
  {
    cik: '0001686988',
    value: '0001686988,L2 ASSET MANAGEMENT, LLC',
    name: 'L2 ASSET MANAGEMENT, LLC',
    label: 'L2 ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001029730',
    value: '0001029730,LADENBURG THALMANN FINANCIAL SERVICES INC',
    name: 'LADENBURG THALMANN FINANCIAL SERVICES INC',
    label: 'LADENBURG THALMANN FINANCIAL SERVICES INC',
  },
  {
    cik: '0001308377',
    value: '0001308377,LAFAYETTE INVESTMENTS, INC.',
    name: 'LAFAYETTE INVESTMENTS, INC.',
    label: 'LAFAYETTE INVESTMENTS, INC.',
  },
  {
    cik: '0001307878',
    value: '0001307878,LAFFER INVESTMENTS',
    name: 'LAFFER INVESTMENTS',
    label: 'LAFFER INVESTMENTS',
  },
  {
    cik: '0001307878',
    value: '0001307878,LAFFER TENGLER INVESTMENTS',
    name: 'LAFFER TENGLER INVESTMENTS',
    label: 'LAFFER TENGLER INVESTMENTS',
  },
  {
    cik: '0001673995',
    value: '0001673995,LA FINANCIERE DE L ECHIQUIER',
    name: "LA FINANCIERE DE L'ECHIQUIER",
    label: "LA FINANCIERE DE L'ECHIQUIER",
  },
  {
    cik: '0001405555',
    value: '0001405555,LAFITTE CAPITAL MANAGEMENT LP',
    name: 'LAFITTE CAPITAL MANAGEMENT LP',
    label: 'LAFITTE CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001387458',
    value: '0001387458,LAFLEUR & GODFREY LLC',
    name: 'LAFLEUR & GODFREY LLC',
    label: 'LAFLEUR & GODFREY LLC',
  },
  {
    cik: '0001485921',
    value: '0001485921,LA FRANCAISE FORUM SECURITIES LTD',
    name: 'LA FRANCAISE FORUM SECURITIES LTD',
    label: 'LA FRANCAISE FORUM SECURITIES LTD',
  },
  {
    cik: '0001632108',
    value: '0001632108,LAGODA INVESTMENT MANAGEMENT, L.P.',
    name: 'LAGODA INVESTMENT MANAGEMENT, L.P.',
    label: 'LAGODA INVESTMENT MANAGEMENT, L.P.',
  },
  {
    cik: '0001482339',
    value: '0001482339,LAIDLAW GROUP, LLC',
    name: 'LAIDLAW GROUP, LLC',
    label: 'LAIDLAW GROUP, LLC',
  },
  {
    cik: '0001800608',
    value: '0001800608,LAIDLAW WEALTH MANAGEMENT LLC',
    name: 'LAIDLAW WEALTH MANAGEMENT LLC',
    label: 'LAIDLAW WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0000723204',
    value: '0000723204,LAIRD NORTON TRUST COMPANY, LLC',
    name: 'LAIRD NORTON TRUST COMPANY, LLC',
    label: 'LAIRD NORTON TRUST COMPANY, LLC',
  },
  {
    cik: '0001705594',
    value: '0001705594,LAKE HILLS WEALTH MANAGEMENT, LLC',
    name: 'LAKE HILLS WEALTH MANAGEMENT, LLC',
    label: 'LAKE HILLS WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001844830',
    value: '0001844830,LAKEHOUSE CAPITAL PTY LTD',
    name: 'LAKEHOUSE CAPITAL PTY LTD',
    label: 'LAKEHOUSE CAPITAL PTY LTD',
  },
  {
    cik: '0001768068',
    value: '0001768068,LAKE POINT WEALTH MANAGEMENT',
    name: 'LAKE POINT WEALTH MANAGEMENT',
    label: 'LAKE POINT WEALTH MANAGEMENT',
  },
  {
    cik: '0001421594',
    value: '0001421594,LAKESHORE CAPITAL, LLC',
    name: 'LAKESHORE CAPITAL, LLC',
    label: 'LAKESHORE CAPITAL, LLC',
  },
  {
    cik: '0001913467',
    value: '0001913467,LAKESIDE ADVISORS, INC.',
    name: 'LAKESIDE ADVISORS, INC.',
    label: 'LAKESIDE ADVISORS, INC.',
  },
  {
    cik: '0001693706',
    value: '0001693706,LAKESIDE WEALTH MANAGEMENT GROUP, LLC',
    name: 'LAKESIDE WEALTH MANAGEMENT GROUP, LLC',
    label: 'LAKESIDE WEALTH MANAGEMENT GROUP, LLC',
  },
  {
    cik: '0001840513',
    value: '0001840513,LAKESTAR (G.P.) LTD',
    name: 'LAKESTAR (G.P.) LTD',
    label: 'LAKESTAR (G.P.) LTD',
  },
  {
    cik: '0001912120',
    value: '0001912120,LAKESTAR GROWTH I (G.P.) LTD',
    name: 'LAKESTAR GROWTH I (G.P.) LTD',
    label: 'LAKESTAR GROWTH I (G.P.) LTD',
  },
  {
    cik: '0001845267',
    value: '0001845267,LAKESTAR II (G.P.) LTD',
    name: 'LAKESTAR II (G.P.) LTD',
    label: 'LAKESTAR II (G.P.) LTD',
  },
  {
    cik: '0001724269',
    value: '0001724269,LAKE STREET ADVISORS GROUP, LLC',
    name: 'LAKE STREET ADVISORS GROUP, LLC',
    label: 'LAKE STREET ADVISORS GROUP, LLC',
  },
  {
    cik: '0001772031',
    value: '0001772031,LAKE STREET FINANCIAL LLC',
    name: 'LAKE STREET FINANCIAL LLC',
    label: 'LAKE STREET FINANCIAL LLC',
  },
  {
    cik: '0001633172',
    value: '0001633172,LAKEVIEW CAPITAL PARTNERS, LLC',
    name: 'LAKEVIEW CAPITAL PARTNERS, LLC',
    label: 'LAKEVIEW CAPITAL PARTNERS, LLC',
  },
  {
    cik: '0001424381',
    value: '0001424381,LAKEWOOD CAPITAL MANAGEMENT, LP',
    name: 'LAKEWOOD CAPITAL MANAGEMENT, LP',
    label: 'LAKEWOOD CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0001961944',
    value: '0001961944,LAM GROUP, INC.',
    name: 'LAM GROUP, INC.',
    label: 'LAM GROUP, INC.',
  },
  {
    cik: '0001542550',
    value: '0001542550,LAMOND CAPITAL PARTNERS LLC',
    name: 'LAMOND CAPITAL PARTNERS LLC',
    label: 'LAMOND CAPITAL PARTNERS LLC',
  },
  {
    cik: '0001630064',
    value: '0001630064,LANCASTER INVESTMENT MANAGEMENT',
    name: 'LANCASTER INVESTMENT MANAGEMENT',
    label: 'LANCASTER INVESTMENT MANAGEMENT',
  },
  {
    cik: '0000913990',
    value: '0000913990,LANDAAS & CO /WI /ADV',
    name: 'LANDAAS & CO /WI /ADV',
    label: 'LANDAAS & CO /WI /ADV',
  },
  {
    cik: '0001536520',
    value: '0001536520,LAND & BUILDINGS INVESTMENT MANAGEMENT, LLC',
    name: 'LAND & BUILDINGS INVESTMENT MANAGEMENT, LLC',
    label: 'LAND & BUILDINGS INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001745164',
    value: '0001745164,LANDMARK BANK',
    name: 'LANDMARK BANK',
    label: 'LANDMARK BANK',
  },
  {
    cik: '0001641440',
    value: '0001641440,LANDMARK FINANCIAL ADVISORS, LLC',
    name: 'LANDMARK FINANCIAL ADVISORS, LLC',
    label: 'LANDMARK FINANCIAL ADVISORS, LLC',
  },
  {
    cik: '0001728778',
    value: '0001728778,LANDMARK WEALTH MANAGEMENT, LLC',
    name: 'LANDMARK WEALTH MANAGEMENT, LLC',
    label: 'LANDMARK WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001762342',
    value: '0001762342,LANDSBERG, BENNETT, & DUBBANEH LLC',
    name: 'LANDSBERG, BENNETT, & DUBBANEH LLC',
    label: 'LANDSBERG, BENNETT, & DUBBANEH LLC',
  },
  {
    cik: '0001536186',
    value: '0001536186,LANDSCAPE CAPITAL MANAGEMENT, L.L.C.',
    name: 'LANDSCAPE CAPITAL MANAGEMENT, L.L.C.',
    label: 'LANDSCAPE CAPITAL MANAGEMENT, L.L.C.',
  },
  {
    cik: '0001410352',
    value: '0001410352,LANE FIVE CAPITAL MANAGEMENT, LP',
    name: 'LANE FIVE CAPITAL MANAGEMENT, LP',
    label: 'LANE FIVE CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0001901384',
    value: '0001901384,LANE GENERATIONAL LLC',
    name: 'LANE GENERATIONAL LLC',
    label: 'LANE GENERATIONAL LLC',
  },
  {
    cik: '0001510972',
    value: '0001510972,LANEXA MANAGEMENT, LLC',
    name: 'LANEXA MANAGEMENT, LLC',
    label: 'LANEXA MANAGEMENT, LLC',
  },
  {
    cik: '0001926344',
    value: '0001926344,LANHAM O DELL & COMPANY, INC.',
    name: "LANHAM O'DELL & COMPANY, INC.",
    label: "LANHAM O'DELL & COMPANY, INC.",
  },
  {
    cik: '0001748965',
    value: '0001748965,LANNEBO FONDER AB',
    name: 'LANNEBO FONDER AB',
    label: 'LANNEBO FONDER AB',
  },
  {
    cik: '0001536071',
    value: '0001536071,LANSDOWNE PARTNERS AUSTRIA GMBH',
    name: 'LANSDOWNE PARTNERS AUSTRIA GMBH',
    label: 'LANSDOWNE PARTNERS AUSTRIA GMBH',
  },
  {
    cik: '0001315309',
    value: '0001315309,LANSDOWNE PARTNERS LIMITED PARTNERSHIP',
    name: 'LANSDOWNE PARTNERS LIMITED PARTNERSHIP',
    label: 'LANSDOWNE PARTNERS LIMITED PARTNERSHIP',
  },
  {
    cik: '0001608485',
    value: '0001608485,LANSDOWNE PARTNERS (UK) LLP',
    name: 'LANSDOWNE PARTNERS (UK) LLP',
    label: 'LANSDOWNE PARTNERS (UK) LLP',
  },
  {
    cik: '0001691908',
    value: '0001691908,LANSING MANAGEMENT LP',
    name: 'LANSING MANAGEMENT LP',
    label: 'LANSING MANAGEMENT LP',
  },
  {
    cik: '0001926571',
    value: '0001926571,LANSING STREET ADVISORS',
    name: 'LANSING STREET ADVISORS',
    label: 'LANSING STREET ADVISORS',
  },
  {
    cik: '0001766883',
    value: '0001766883,LANTZ FINANCIAL LLC',
    name: 'LANTZ FINANCIAL LLC',
    label: 'LANTZ FINANCIAL LLC',
  },
  {
    cik: '0001357993',
    value: '0001357993,LAPIDES ASSET MANAGEMENT, LLC',
    name: 'LAPIDES ASSET MANAGEMENT, LLC',
    label: 'LAPIDES ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001420989',
    value: '0001420989,LARA, MAY & ASSOCIATES, LLC',
    name: 'LARA, MAY & ASSOCIATES, LLC',
    label: 'LARA, MAY & ASSOCIATES, LLC',
  },
  {
    cik: '0001908275',
    value: '0001908275,LARAWAY FINANCIAL ADVISORS INC',
    name: 'LARAWAY FINANCIAL ADVISORS INC',
    label: 'LARAWAY FINANCIAL ADVISORS INC',
  },
  {
    cik: '0001908275',
    value: '0001908275,LARAWAY FINANCIAL INC',
    name: 'LARAWAY FINANCIAL INC',
    label: 'LARAWAY FINANCIAL INC',
  },
  {
    cik: '0001731061',
    value: '0001731061,LARSON FINANCIAL GROUP LLC',
    name: 'LARSON FINANCIAL GROUP LLC',
    label: 'LARSON FINANCIAL GROUP LLC',
  },
  {
    cik: '0001085601',
    value: '0001085601,LASALLE INVESTMENT MANAGEMENT SECURITIES LLC',
    name: 'LASALLE INVESTMENT MANAGEMENT SECURITIES LLC',
    label: 'LASALLE INVESTMENT MANAGEMENT SECURITIES LLC',
  },
  {
    cik: '0001679807',
    value: '0001679807,LASALLE STREET CAPITAL MANAGEMENT, LLC',
    name: 'LASALLE STREET CAPITAL MANAGEMENT, LLC',
    label: 'LASALLE STREET CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001259927',
    value: '000125992,LASRY MARC',
    name: 'LASRY MARC',
    label: 'LASRY MARC',
  },
  {
    cik: '0001317961',
    value: '0001317961,LATASH INVESTMENTS, LLC',
    name: 'LATASH INVESTMENTS, LLC',
    label: 'LATASH INVESTMENTS, LLC',
  },
  {
    cik: '0001009262',
    value: '0001009262,LATEEF INVESTMENT MANAGEMENT, L.P.',
    name: 'LATEEF INVESTMENT MANAGEMENT, L.P.',
    label: 'LATEEF INVESTMENT MANAGEMENT, L.P.',
  },
  {
    cik: '0001124841',
    value: '0001124841,LATHROP INVESTMENT MANAGEMENT CORP',
    name: 'LATHROP INVESTMENT MANAGEMENT CORP',
    label: 'LATHROP INVESTMENT MANAGEMENT CORP',
  },
  {
    cik: '0001914668',
    value: '0001914668,LATIGO INVESTMENTS, LLC',
    name: 'LATIGO INVESTMENTS, LLC',
    label: 'LATIGO INVESTMENTS, LLC',
  },
  {
    cik: '0001656532',
    value: '0001656532,LATIMER LIGHT CAPITAL, L.P.',
    name: 'LATIMER LIGHT CAPITAL, L.P.',
    label: 'LATIMER LIGHT CAPITAL, L.P.',
  },
  {
    cik: '0001843715',
    value: '0001843715,LATITUDE ADVISORS, LLC',
    name: 'LATITUDE ADVISORS, LLC',
    label: 'LATITUDE ADVISORS, LLC',
  },
  {
    cik: '0001710207',
    value: '0001710207,LATTICE CAPITAL MANAGEMENT, LLC',
    name: 'LATTICE CAPITAL MANAGEMENT, LLC',
    label: 'LATTICE CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001540945',
    value: '0001540945,LATTICE STRATEGIES LLC',
    name: 'LATTICE STRATEGIES LLC',
    label: 'LATTICE STRATEGIES LLC',
  },
  {
    cik: '0001161825',
    value: '0001161825,LAU ASSOCIATES LLC',
    name: 'LAU ASSOCIATES LLC',
    label: 'LAU ASSOCIATES LLC',
  },
  {
    cik: '0001387978',
    value: '0001387978,LAUREL GROVE CAPITAL, LLC',
    name: 'LAUREL GROVE CAPITAL, LLC',
    label: 'LAUREL GROVE CAPITAL, LLC',
  },
  {
    cik: '0001666179',
    value: '0001666179,LAUREL WEALTH ADVISORS, INC.',
    name: 'LAUREL WEALTH ADVISORS, INC.',
    label: 'LAUREL WEALTH ADVISORS, INC.',
  },
  {
    cik: '0001757128',
    value: '0001757128,LAUREL WEALTH ADVISORS LLC',
    name: 'LAUREL WEALTH ADVISORS LLC',
    label: 'LAUREL WEALTH ADVISORS LLC',
  },
  {
    cik: '0001842010',
    value: '0001842010,LAUREL WEALTH PLANNING LLC',
    name: 'LAUREL WEALTH PLANNING LLC',
    label: 'LAUREL WEALTH PLANNING LLC',
  },
  {
    cik: '0001390202',
    value: '0001390202,LAURION CAPITAL MANAGEMENT LP',
    name: 'LAURION CAPITAL MANAGEMENT LP',
    label: 'LAURION CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001869316',
    value: '0001869316,LAURUS INVESTMENT COUNSEL INC.',
    name: 'LAURUS INVESTMENT COUNSEL INC.',
    label: 'LAURUS INVESTMENT COUNSEL INC.',
  },
  {
    cik: '0001909828',
    value: '0001909828,LAUTERBACH FINANCIAL ADVISORS, LLC',
    name: 'LAUTERBACH FINANCIAL ADVISORS, LLC',
    label: 'LAUTERBACH FINANCIAL ADVISORS, LLC',
  },
  {
    cik: '0001641447',
    value: '0001641447,LAVACA CAPITAL LLC',
    name: 'LAVACA CAPITAL LLC',
    label: 'LAVACA CAPITAL LLC',
  },
  {
    cik: '0001013701',
    value: '0001013701,LAWSON KROEKER INVESTMENT MANAGEMENT INC/NE',
    name: 'LAWSON KROEKER INVESTMENT MANAGEMENT INC/NE',
    label: 'LAWSON KROEKER INVESTMENT MANAGEMENT INC/NE',
  },
  {
    cik: '0001207017',
    value: '0001207017,LAZARD ASSET MANAGEMENT LLC',
    name: 'LAZARD ASSET MANAGEMENT LLC',
    label: 'LAZARD ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001426748',
    value: '0001426748,LAZARD FRERES GESTION S.A.S.',
    name: 'LAZARD FRERES GESTION S.A.S.',
    label: 'LAZARD FRERES GESTION S.A.S.',
  },
  {
    cik: '0001600566',
    value: '0001600566,LBA WEALTH MANAGEMENT, LLC',
    name: 'LBA WEALTH MANAGEMENT, LLC',
    label: 'LBA WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001601622',
    value: '0001601622,LBJ FAMILY WEALTH ADVISORS, LTD.',
    name: 'LBJ FAMILY WEALTH ADVISORS, LTD.',
    label: 'LBJ FAMILY WEALTH ADVISORS, LTD.',
  },
  {
    cik: '0001536924',
    value: '0001536924,LBMC INVESTMENT ADVISORS, LLC',
    name: 'LBMC INVESTMENT ADVISORS, LLC',
    label: 'LBMC INVESTMENT ADVISORS, LLC',
  },
  {
    cik: '0001617878',
    value: '0001617878,LBN ADVISERS LTD',
    name: 'LBN ADVISERS LTD',
    label: 'LBN ADVISERS LTD',
  },
  {
    cik: '0001861752',
    value: '0001861752,LCM CAPITAL MANAGEMENT INC',
    name: 'LCM CAPITAL MANAGEMENT INC',
    label: 'LCM CAPITAL MANAGEMENT INC',
  },
  {
    cik: '0001074902',
    value: '00010749,LCNB CORP',
    name: 'LCNB CORP',
    label: 'LCNB CORP',
  },
  {
    cik: '0001566089',
    value: '0001566089,LDR CAPITAL MANAGEMENT LLC',
    name: 'LDR CAPITAL MANAGEMENT LLC',
    label: 'LDR CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001802797',
    value: '0001802797,LEAD EDGE CAPITAL MANAGEMENT, LLC',
    name: 'LEAD EDGE CAPITAL MANAGEMENT, LLC',
    label: 'LEAD EDGE CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001779672',
    value: '0001779672,LEAP INVESTMENTS LP',
    name: 'LEAP INVESTMENTS LP',
    label: 'LEAP INVESTMENTS LP',
  },
  {
    cik: '0000924181',
    value: '0000924181,LEAVELL INVESTMENT MANAGEMENT, INC.',
    name: 'LEAVELL INVESTMENT MANAGEMENT, INC.',
    label: 'LEAVELL INVESTMENT MANAGEMENT, INC.',
  },
  {
    cik: '0001633883',
    value: '0001633883,LEBENTHAL ASSET MANAGEMENT, LLC',
    name: 'LEBENTHAL ASSET MANAGEMENT, LLC',
    label: 'LEBENTHAL ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001845766',
    value: '0001845766,LEBENTHAL GLOBAL ADVISORS, LLC',
    name: 'LEBENTHAL GLOBAL ADVISORS, LLC',
    label: 'LEBENTHAL GLOBAL ADVISORS, LLC',
  },
  {
    cik: '0001633909',
    value: '0001633909,LEBENTHAL HOLDINGS,LLC',
    name: 'LEBENTHAL HOLDINGS,LLC',
    label: 'LEBENTHAL HOLDINGS,LLC',
  },
  {
    cik: '0001424467',
    value: '0001424467,LEBENTHAL LISANTI CAPITAL GROWTH, LLC',
    name: 'LEBENTHAL LISANTI CAPITAL GROWTH, LLC',
    label: 'LEBENTHAL LISANTI CAPITAL GROWTH, LLC',
  },
  {
    cik: '0001633884',
    value: '0001633884,LEBENTHAL WEALTH ADVISORS, LLC',
    name: 'LEBENTHAL WEALTH ADVISORS, LLC',
    label: 'LEBENTHAL WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001089707',
    value: '0001089707,LEDERER & ASSOCIATES INVESTMENT COUNSEL/CA',
    name: 'LEDERER & ASSOCIATES INVESTMENT COUNSEL/CA',
    label: 'LEDERER & ASSOCIATES INVESTMENT COUNSEL/CA',
  },
  {
    cik: '0001927042',
    value: '0001927042,LEDGE WEALTH MANAGEMENT, INC.',
    name: 'LEDGE WEALTH MANAGEMENT, INC.',
    label: 'LEDGE WEALTH MANAGEMENT, INC.',
  },
  {
    cik: '0001310658',
    value: '0001310658,LEDYARD NATIONAL BANK',
    name: 'LEDYARD NATIONAL BANK',
    label: 'LEDYARD NATIONAL BANK',
  },
  {
    cik: '0001762470',
    value: '0001762470,LEE CAPITAL ASSET MANAGEMENT LLC',
    name: 'LEE CAPITAL ASSET MANAGEMENT LLC',
    label: 'LEE CAPITAL ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001748305',
    value: '0001748305,LEE CAPITAL HOLDINGS LLC',
    name: 'LEE CAPITAL HOLDINGS LLC',
    label: 'LEE CAPITAL HOLDINGS LLC',
  },
  {
    cik: '0001656713',
    value: '0001656713,LEE CAPITAL MANAGEMENT LP',
    name: 'LEE CAPITAL MANAGEMENT LP',
    label: 'LEE CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0000939219',
    value: '0000939219,LEE DANNER & BASS INC',
    name: 'LEE DANNER & BASS INC',
    label: 'LEE DANNER & BASS INC',
  },
  {
    cik: '0001408700',
    value: '0001408700,LEE EQUITY PARTNERS, LLC',
    name: 'LEE EQUITY PARTNERS, LLC',
    label: 'LEE EQUITY PARTNERS, LLC',
  },
  {
    cik: '0001391166',
    value: '0001391166,LEE FINANCIAL CO',
    name: 'LEE FINANCIAL CO',
    label: 'LEE FINANCIAL CO',
  },
  {
    cik: '0000837980',
    value: '0000837980,LEE FINANCIAL GROUP HAWAII, INC.',
    name: 'LEE FINANCIAL GROUP HAWAII, INC.',
    label: 'LEE FINANCIAL GROUP HAWAII, INC.',
  },
  {
    cik: '0001674836',
    value: '0001674836,LEE JOHNSON CAPITAL MANAGEMENT, LLC',
    name: 'LEE JOHNSON CAPITAL MANAGEMENT, LLC',
    label: 'LEE JOHNSON CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001777817',
    value: '0001777817,LEELYN SMITH, LLC',
    name: 'LEELYN SMITH, LLC',
    label: 'LEELYN SMITH, LLC',
  },
  {
    cik: '0001470944',
    value: '0001470944,LEE MUNDER CAPITAL GROUP, LLC',
    name: 'LEE MUNDER CAPITAL GROUP, LLC',
    label: 'LEE MUNDER CAPITAL GROUP, LLC',
  },
  {
    cik: '0001260945',
    value: '0001260945,LEE THOMAS H PARTNERS LP',
    name: 'LEE THOMAS H PARTNERS LP',
    label: 'LEE THOMAS H PARTNERS LP',
  },
  {
    cik: '0001735201',
    value: '0001735201,LEEWARD FINANCIAL PARTNERS, LLC',
    name: 'LEEWARD FINANCIAL PARTNERS, LLC',
    label: 'LEEWARD FINANCIAL PARTNERS, LLC',
  },
  {
    cik: '0001923173',
    value: '0001923173,LEEWARD INVESTMENTS, LLC - MA',
    name: 'LEEWARD INVESTMENTS, LLC - MA',
    label: 'LEEWARD INVESTMENTS, LLC - MA',
  },
  {
    cik: '0001720969',
    value: '0001720969,LEGACY ADVISORS, LLC',
    name: 'LEGACY ADVISORS, LLC',
    label: 'LEGACY ADVISORS, LLC',
  },
  {
    cik: '0001689144',
    value: '0001689144,LEGACY BRIDGE, LLC',
    name: 'LEGACY BRIDGE, LLC',
    label: 'LEGACY BRIDGE, LLC',
  },
  {
    cik: '0001450558',
    value: '0001450558,LEGACY CAPITAL PARTNERS, INC.',
    name: 'LEGACY CAPITAL PARTNERS, INC.',
    label: 'LEGACY CAPITAL PARTNERS, INC.',
  },
  {
    cik: '0001766228',
    value: '0001766228,LEGACY CAPITAL WEALTH PARTNERS, LLC',
    name: 'LEGACY CAPITAL WEALTH PARTNERS, LLC',
    label: 'LEGACY CAPITAL WEALTH PARTNERS, LLC',
  },
  {
    cik: '0001904825',
    value: '0001904825,LEGACY CG, LLC',
    name: 'LEGACY CG, LLC',
    label: 'LEGACY CG, LLC',
  },
  {
    cik: '0001730810',
    value: '0001730810,LEGACY FINANCIAL ADVISORS, INC.',
    name: 'LEGACY FINANCIAL ADVISORS, INC.',
    label: 'LEGACY FINANCIAL ADVISORS, INC.',
  },
  {
    cik: '0001834011',
    value: '0001834011,LEGACY FINANCIAL GROUP LLC',
    name: 'LEGACY FINANCIAL GROUP LLC',
    label: 'LEGACY FINANCIAL GROUP LLC',
  },
  {
    cik: '0001723925',
    value: '0001723925,LEGACY FINANCIAL STRATEGIES, LLC',
    name: 'LEGACY FINANCIAL STRATEGIES, LLC',
    label: 'LEGACY FINANCIAL STRATEGIES, LLC',
  },
  {
    cik: '0001352547',
    value: '0001352547,LEGACY PRIVATE TRUST CO.',
    name: 'LEGACY PRIVATE TRUST CO.',
    label: 'LEGACY PRIVATE TRUST CO.',
  },
  {
    cik: '0001846151',
    value: '0001846151,LEGACY WEALTH ASSET MANAGEMENT, LLC',
    name: 'LEGACY WEALTH ASSET MANAGEMENT, LLC',
    label: 'LEGACY WEALTH ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001488921',
    value: '0001488921,LEGACY WEALTH MANAGEMENT, INC',
    name: 'LEGACY WEALTH MANAGEMENT, INC',
    label: 'LEGACY WEALTH MANAGEMENT, INC',
  },
  {
    cik: '0001725243',
    value: '0001725243,LEGACY WEALTH PARTNERS, INC.',
    name: 'LEGACY WEALTH PARTNERS, INC.',
    label: 'LEGACY WEALTH PARTNERS, INC.',
  },
  {
    cik: '0001802490',
    value: '0001802490,LEGACY WEALTH PLANNING, LLC',
    name: 'LEGACY WEALTH PLANNING, LLC',
    label: 'LEGACY WEALTH PLANNING, LLC',
  },
  {
    cik: '0001841769',
    value: '0001841769,LEGAL ADVANTAGE INVESTMENTS, INC.',
    name: 'LEGAL ADVANTAGE INVESTMENTS, INC.',
    label: 'LEGAL ADVANTAGE INVESTMENTS, INC.',
  },
  {
    cik: '0000764068',
    value: '0000764068,LEGAL & GENERAL GROUP PLC',
    name: 'LEGAL & GENERAL GROUP PLC',
    label: 'LEGAL & GENERAL GROUP PLC',
  },
  {
    cik: '0001442641',
    value: '0001442641,LEGATO CAPITAL MANAGEMENT LLC',
    name: 'LEGATO CAPITAL MANAGEMENT LLC',
    label: 'LEGATO CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001781948',
    value: '0001781948,LEGEND FINANCIAL ADVISORS, INC.',
    name: 'LEGEND FINANCIAL ADVISORS, INC.',
    label: 'LEGEND FINANCIAL ADVISORS, INC.',
  },
  {
    cik: '0001316466',
    value: '0001316466,LEGG MASON ASSET MANAGEMENT (JAPAN) CO., LTD.',
    name: 'LEGG MASON ASSET MANAGEMENT (JAPAN) CO., LTD.',
    label: 'LEGG MASON ASSET MANAGEMENT (JAPAN) CO., LTD.',
  },
  {
    cik: '0000820330',
    value: '0000820330,LEGG MASON CAPITAL MANAGEMENT LLC',
    name: 'LEGG MASON CAPITAL MANAGEMENT LLC',
    label: 'LEGG MASON CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001574917',
    value: '0001574917,LEGG MASON GLOBAL ASSET ALLOCATION, LLC',
    name: 'LEGG MASON GLOBAL ASSET ALLOCATION, LLC',
    label: 'LEGG MASON GLOBAL ASSET ALLOCATION, LLC',
  },
  {
    cik: '0000704051',
    value: '0000704051,LEGG MASON, INC.',
    name: 'LEGG MASON, INC.',
    label: 'LEGG MASON, INC.',
  },
  {
    cik: '0001324943',
    value: '0001324943,LEGG MASON INVESTMENT COUNSEL, LLC',
    name: 'LEGG MASON INVESTMENT COUNSEL, LLC',
    label: 'LEGG MASON INVESTMENT COUNSEL, LLC',
  },
  {
    cik: '0001135986',
    value: '0001135986,LEGG MASON INVESTMENT COUNSEL & TRUST CO., N.A.',
    name: 'LEGG MASON INVESTMENT COUNSEL & TRUST CO., N.A.',
    label: 'LEGG MASON INVESTMENT COUNSEL & TRUST CO., N.A.',
  },
  {
    cik: '0001560207',
    value: '0001560207,LEGION PARTNERS ASSET MANAGEMENT, LLC',
    name: 'LEGION PARTNERS ASSET MANAGEMENT, LLC',
    label: 'LEGION PARTNERS ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001568495',
    value: '0001568495,LEHMAN BROTHERS HOLDINGS INC.',
    name: 'LEHMAN BROTHERS HOLDINGS INC.',
    label: 'LEHMAN BROTHERS HOLDINGS INC.',
  },
  {
    cik: '0001767952',
    value: '0001767952,LEHMAN & DERAFELO FINANCIAL RESOURCES LLC',
    name: 'LEHMAN & DERAFELO FINANCIAL RESOURCES LLC',
    label: 'LEHMAN & DERAFELO FINANCIAL RESOURCES LLC',
  },
  {
    cik: '0001589661',
    value: '0001589661,LEHMAN FINANCIAL RESOURCES, INC.',
    name: 'LEHMAN FINANCIAL RESOURCES, INC.',
    label: 'LEHMAN FINANCIAL RESOURCES, INC.',
  },
  {
    cik: '0001710666',
    value: '0001710666,LEICHT FINANCIAL PLANNING & WEALTH MANAGEMENT, INC.',
    name: 'LEICHT FINANCIAL PLANNING & WEALTH MANAGEMENT, INC.',
    label: 'LEICHT FINANCIAL PLANNING & WEALTH MANAGEMENT, INC.',
  },
  {
    cik: '0001631507',
    value: '0001631507,LEISURE CAPITAL MANAGEMENT',
    name: 'LEISURE CAPITAL MANAGEMENT',
    label: 'LEISURE CAPITAL MANAGEMENT',
  },
  {
    cik: '0001353651',
    value: '0001353651,LEITH WHEELER INVESTMENT COUNSEL LTD.',
    name: 'LEITH WHEELER INVESTMENT COUNSEL LTD.',
    label: 'LEITH WHEELER INVESTMENT COUNSEL LTD.',
  },
  {
    cik: '0001698146',
    value: '0001698146,LEJEUNE PUETZ INVESTMENT COUNSEL LLC',
    name: 'LEJEUNE PUETZ INVESTMENT COUNSEL LLC',
    label: 'LEJEUNE PUETZ INVESTMENT COUNSEL LLC',
  },
  {
    cik: '0001569292',
    value: '0001569292,LENOX WEALTH ADVISORS, INC.',
    name: 'LENOX WEALTH ADVISORS, INC.',
    label: 'LENOX WEALTH ADVISORS, INC.',
  },
  {
    cik: '0001569292',
    value: '0001569292,LENOX WEALTH ADVISORS, LLC',
    name: 'LENOX WEALTH ADVISORS, LLC',
    label: 'LENOX WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001464332',
    value: '0001464332,LENOX WEALTH MANAGEMENT, INC.',
    name: 'LENOX WEALTH MANAGEMENT, INC.',
    label: 'LENOX WEALTH MANAGEMENT, INC.',
  },
  {
    cik: '0001146089',
    value: '0001146089,LEO BROKERAGE, LLC',
    name: 'LEO BROKERAGE, LLC',
    label: 'LEO BROKERAGE, LLC',
  },
  {
    cik: '0001920405',
    value: '0001920405,LEO H. EVART, INC.',
    name: 'LEO H. EVART, INC.',
    label: 'LEO H. EVART, INC.',
  },
  {
    cik: '0001535315',
    value: '0001535315,LEONARD CAPITAL MANAGEMENT INC',
    name: 'LEONARD CAPITAL MANAGEMENT INC',
    label: 'LEONARD CAPITAL MANAGEMENT INC',
  },
  {
    cik: '0001175523',
    value: '0001175523,LEONARD GREEN & PARTNERS, L.P.',
    name: 'LEONARD GREEN & PARTNERS, L.P.',
    label: 'LEONARD GREEN & PARTNERS, L.P.',
  },
  {
    cik: '0001731447',
    value: '0001731447,LEONARD RICKEY INVESTMENT ADVISORS P.L.L.C.',
    name: 'LEONARD RICKEY INVESTMENT ADVISORS P.L.L.C.',
    label: 'LEONARD RICKEY INVESTMENT ADVISORS P.L.L.C.',
  },
  {
    cik: '0001434689',
    value: '0001434689,LEONETTI & ASSOCIATES, LLC',
    name: 'LEONETTI & ASSOCIATES, LLC',
    label: 'LEONETTI & ASSOCIATES, LLC',
  },
  {
    cik: '0001492162',
    value: '0001492162,LESA SROUFE & CO',
    name: 'LESA SROUFE & CO',
    label: 'LESA SROUFE & CO',
  },
  {
    cik: '0001725888',
    value: '0001725888,LESTER MURRAY ANTMAN DBA SIMPLYRICH',
    name: 'LESTER MURRAY ANTMAN DBA SIMPLYRICH',
    label: 'LESTER MURRAY ANTMAN DBA SIMPLYRICH',
  },
  {
    cik: '0001297496',
    value: '0001297496,LETKO, BROSSEAU & ASSOCIATES INC',
    name: 'LETKO, BROSSEAU & ASSOCIATES INC',
    label: 'LETKO, BROSSEAU & ASSOCIATES INC',
  },
  {
    cik: '0000096223',
    value: '0000096223,LEUCADIA NATIONAL CORP',
    name: 'LEUCADIA NATIONAL CORP',
    label: 'LEUCADIA NATIONAL CORP',
  },
  {
    cik: '0001170152',
    value: '0001170152,LEUTHOLD GROUP, LLC',
    name: 'LEUTHOLD GROUP, LLC',
    label: 'LEUTHOLD GROUP, LLC',
  },
  {
    cik: '0001385915',
    value: '0001385915,LEVASSEUR CAPITAL PARTNERS LLC',
    name: 'LEVASSEUR CAPITAL PARTNERS LLC',
    label: 'LEVASSEUR CAPITAL PARTNERS LLC',
  },
  {
    cik: '0001871734',
    value: '0001871734,LEVEL FINANCIAL ADVISORS, INC.',
    name: 'LEVEL FINANCIAL ADVISORS, INC.',
    label: 'LEVEL FINANCIAL ADVISORS, INC.',
  },
  {
    cik: '0001696715',
    value: '0001696715,LEVEL FOUR ADVISORY SERVICES, LLC',
    name: 'LEVEL FOUR ADVISORY SERVICES, LLC',
    label: 'LEVEL FOUR ADVISORY SERVICES, LLC',
  },
  {
    cik: '0000857602',
    value: '0000857602,LEVEL FOUR FINANCIAL, LLC',
    name: 'LEVEL FOUR FINANCIAL, LLC',
    label: 'LEVEL FOUR FINANCIAL, LLC',
  },
  {
    cik: '0001662944',
    value: '0001662944,LEVERAGE PARTNERS ABSOLUTE RETURN FUND SPC',
    name: 'LEVERAGE PARTNERS ABSOLUTE RETURN FUND SPC',
    label: 'LEVERAGE PARTNERS ABSOLUTE RETURN FUND SPC',
  },
  {
    cik: '0001908386',
    value: '0001908386,LEVERTY FINANCIAL GROUP, LLC',
    name: 'LEVERTY FINANCIAL GROUP, LLC',
    label: 'LEVERTY FINANCIAL GROUP, LLC',
  },
  {
    cik: '0001354821',
    value: '0001354821,LEVIN CAPITAL STRATEGIES, L.P.',
    name: 'LEVIN CAPITAL STRATEGIES, L.P.',
    label: 'LEVIN CAPITAL STRATEGIES, L.P.',
  },
  {
    cik: '0001766150',
    value: '0001766150,LEVIN EASTERLY PARTNERS LLC',
    name: 'LEVIN EASTERLY PARTNERS LLC',
    label: 'LEVIN EASTERLY PARTNERS LLC',
  },
  {
    cik: '0001332010',
    value: '0001332010,LEVITT CAPITAL MANAGEMENT LLC',
    name: 'LEVITT CAPITAL MANAGEMENT LLC',
    label: 'LEVITT CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001135307',
    value: '0001135307,LEVY HARKINS & CO INC/',
    name: 'LEVY HARKINS & CO INC/',
    label: 'LEVY HARKINS & CO INC/',
  },
  {
    cik: '0001843138',
    value: '0001843138,LEVY WEALTH MANAGEMENT GROUP, LLC',
    name: 'LEVY WEALTH MANAGEMENT GROUP, LLC',
    label: 'LEVY WEALTH MANAGEMENT GROUP, LLC',
  },
  {
    cik: '0001349267',
    value: '0001349267,LEWIS CAPITAL MANAGEMENT, LLC',
    name: 'LEWIS CAPITAL MANAGEMENT, LLC',
    label: 'LEWIS CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001729677',
    value: '0001729677,LEXAURUM ADVISORS, LLC',
    name: 'LEXAURUM ADVISORS, LLC',
    label: 'LEXAURUM ADVISORS, LLC',
  },
  {
    cik: '0001012036',
    value: '0001012036,LEXINGTON ADVISORS INC /ADV',
    name: 'LEXINGTON ADVISORS INC /ADV',
    label: 'LEXINGTON ADVISORS INC /ADV',
  },
  {
    cik: '0001621157',
    value: '0001621157,LEXINGTON PARTNERS L.P.',
    name: 'LEXINGTON PARTNERS L.P.',
    label: 'LEXINGTON PARTNERS L.P.',
  },
  {
    cik: '0001512672',
    value: '0001512672,LEXINGTON PARTNERS VI HOLDINGS, LLC',
    name: 'LEXINGTON PARTNERS VI HOLDINGS, LLC',
    label: 'LEXINGTON PARTNERS VI HOLDINGS, LLC',
  },
  {
    cik: '0001642275',
    value: '0001642275,LEXINGTON PARTNERS VI HOLDINGS SUB LLC',
    name: 'LEXINGTON PARTNERS VI HOLDINGS SUB LLC',
    label: 'LEXINGTON PARTNERS VI HOLDINGS SUB LLC',
  },
  {
    cik: '0001737871',
    value: '0001737871,LFA - LUGANO FINANCIAL ADVISORS SA',
    name: 'LFA - LUGANO FINANCIAL ADVISORS SA',
    label: 'LFA - LUGANO FINANCIAL ADVISORS SA',
  },
  {
    cik: '0001694127',
    value: '0001694127,LFL ADVISERS, LLC',
    name: 'LFL ADVISERS, LLC',
    label: 'LFL ADVISERS, LLC',
  },
  {
    cik: '0001942844',
    value: '0001942844,LFS ASSET MANAGEMENT',
    name: 'LFS ASSET MANAGEMENT',
    label: 'LFS ASSET MANAGEMENT',
  },
  {
    cik: '0001589358',
    value: '0001589358,LG CAPITAL MANAGEMENT, LP',
    name: 'LG CAPITAL MANAGEMENT, LP',
    label: 'LG CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0001653202',
    value: '0001653202,LGL PARTNERS, LLC',
    name: 'LGL PARTNERS, LLC',
    label: 'LGL PARTNERS, LLC',
  },
  {
    cik: '0001641992',
    value: '0001641992,LGT CAPITAL PARTNERS LTD.',
    name: 'LGT CAPITAL PARTNERS LTD.',
    label: 'LGT CAPITAL PARTNERS LTD.',
  },
  {
    cik: '0001911284',
    value: '0001911284,LGT FUND MANAGEMENT CO LTD.',
    name: 'LGT FUND MANAGEMENT CO LTD.',
    label: 'LGT FUND MANAGEMENT CO LTD.',
  },
  {
    cik: '0001911278',
    value: '0001911278,LGT GROUP FOUNDATION',
    name: 'LGT GROUP FOUNDATION',
    label: 'LGT GROUP FOUNDATION',
  },
  {
    cik: '0001707790',
    value: '0001707790,LH CAPITAL MARKETS, LLC',
    name: 'LH CAPITAL MARKETS, LLC',
    label: 'LH CAPITAL MARKETS, LLC',
  },
  {
    cik: '0001366838',
    value: '0001366838,LIBERTY CAPITAL MANAGEMENT, INC.',
    name: 'LIBERTY CAPITAL MANAGEMENT, INC.',
    label: 'LIBERTY CAPITAL MANAGEMENT, INC.',
  },
  {
    cik: '0001533497',
    value: '0001533497,LIBERTY MUTUAL GROUP ASSET MANAGEMENT INC.',
    name: 'LIBERTY MUTUAL GROUP ASSET MANAGEMENT INC.',
    label: 'LIBERTY MUTUAL GROUP ASSET MANAGEMENT INC.',
  },
  {
    cik: '0001807328',
    value: '0001807328,LIBERTY ONE INVESTMENT MANAGEMENT, LLC',
    name: 'LIBERTY ONE INVESTMENT MANAGEMENT, LLC',
    label: 'LIBERTY ONE INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001489178',
    value: '0001489178,LIBERTY STREET ADVISORS, INC.',
    name: 'LIBERTY STREET ADVISORS, INC.',
    label: 'LIBERTY STREET ADVISORS, INC.',
  },
  {
    cik: '0001769302',
    value: '0001769302,LIBERTY WEALTH MANAGEMENT LLC',
    name: 'LIBERTY WEALTH MANAGEMENT LLC',
    label: 'LIBERTY WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0001005557',
    value: '0001005557,LIBRA ADVISORS LLC',
    name: 'LIBRA ADVISORS LLC',
    label: 'LIBRA ADVISORS LLC',
  },
  {
    cik: '0001788330',
    value: '0001788330,LIBRARY RESEARCH LTD',
    name: 'LIBRARY RESEARCH LTD',
    label: 'LIBRARY RESEARCH LTD',
  },
  {
    cik: '0001925385',
    value: '0001925385,LIBRA WEALTH LLC',
    name: 'LIBRA WEALTH LLC',
    label: 'LIBRA WEALTH LLC',
  },
  {
    cik: '0001650150',
    value: '0001650150,LIDO ADVISORS, LLC',
    name: 'LIDO ADVISORS, LLC',
    label: 'LIDO ADVISORS, LLC',
  },
  {
    cik: '0001908585',
    value: '0001908585,LIFEGUIDE FINANCIAL ADVISORS, LLC',
    name: 'LIFEGUIDE FINANCIAL ADVISORS, LLC',
    label: 'LIFEGUIDE FINANCIAL ADVISORS, LLC',
  },
  {
    cik: '0001583104',
    value: '0001583104,LIFEPLAN FINANCIAL GROUP, INC',
    name: 'LIFEPLAN FINANCIAL GROUP, INC',
    label: 'LIFEPLAN FINANCIAL GROUP, INC',
  },
  {
    cik: '0001910934',
    value: '0001910934,LIFE PLANNING PARTNERS, INC',
    name: 'LIFE PLANNING PARTNERS, INC',
    label: 'LIFE PLANNING PARTNERS, INC',
  },
  {
    cik: '0001838533',
    value: '0001838533,LIFEPRO ASSET MANAGEMENT',
    name: 'LIFEPRO ASSET MANAGEMENT',
    label: 'LIFEPRO ASSET MANAGEMENT',
  },
  {
    cik: '0001801373',
    value: '0001801373,LIFESTEPS FINANCIAL, INC.',
    name: 'LIFESTEPS FINANCIAL, INC.',
    label: 'LIFESTEPS FINANCIAL, INC.',
  },
  {
    cik: '0001882132',
    value: '0001882132,LIFEWORKS ADVISORS, LLC',
    name: 'LIFEWORKS ADVISORS, LLC',
    label: 'LIFEWORKS ADVISORS, LLC',
  },
  {
    cik: '0001623210',
    value: '0001623210,LIGHTHOUSE FINANCIAL ADVISORS, INC.',
    name: 'LIGHTHOUSE FINANCIAL ADVISORS, INC.',
    label: 'LIGHTHOUSE FINANCIAL ADVISORS, INC.',
  },
  {
    cik: '0001600344',
    value: '0001600344,LIGHTHOUSE INVESTMENT PARTNERS, LLC',
    name: 'LIGHTHOUSE INVESTMENT PARTNERS, LLC',
    label: 'LIGHTHOUSE INVESTMENT PARTNERS, LLC',
  },
  {
    cik: '0001708670',
    value: '0001708670,LIGHT SKY MACRO LP',
    name: 'LIGHT SKY MACRO LP',
    label: 'LIGHT SKY MACRO LP',
  },
  {
    cik: '0001912676',
    value: '0001912676,LIGHTSPEED ULTIMATE GENERAL PARTNER IX, LTD.',
    name: 'LIGHTSPEED ULTIMATE GENERAL PARTNER IX, LTD.',
    label: 'LIGHTSPEED ULTIMATE GENERAL PARTNER IX, LTD.',
  },
  {
    cik: '0001912675',
    value: '0001912675,LIGHTSPEED ULTIMATE GENERAL PARTNER SELECT III, LTD.',
    name: 'LIGHTSPEED ULTIMATE GENERAL PARTNER SELECT III, LTD.',
    label: 'LIGHTSPEED ULTIMATE GENERAL PARTNER SELECT III, LTD.',
  },
  {
    cik: '0001569049',
    value: '0001569049,LIGHT STREET CAPITAL MANAGEMENT, LLC',
    name: 'LIGHT STREET CAPITAL MANAGEMENT, LLC',
    label: 'LIGHT STREET CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001632899',
    value: '0001632899,LIGHTYEAR CAPITAL LLC',
    name: 'LIGHTYEAR CAPITAL LLC',
    label: 'LIGHTYEAR CAPITAL LLC',
  },
  {
    cik: '0000059470',
    value: '0000059470,LILLEY & CO',
    name: 'LILLEY & CO',
    label: 'LILLEY & CO',
  },
  {
    cik: '0000316011',
    value: '0000316011,LILLY ENDOWMENT INC',
    name: 'LILLY ENDOWMENT INC',
    label: 'LILLY ENDOWMENT INC',
  },
  {
    cik: '0001529541',
    value: '0001529541,LIME ROCK MANAGEMENT LP',
    name: 'LIME ROCK MANAGEMENT LP',
    label: 'LIME ROCK MANAGEMENT LP',
  },
  {
    cik: '0001481882',
    value: '0001481882,LIMESTONE VALUE PARTNERS, LLC',
    name: 'LIMESTONE VALUE PARTNERS, LLC',
    label: 'LIMESTONE VALUE PARTNERS, LLC',
  },
  {
    cik: '0001058231',
    value: '0001058231,LINCLUDEN MANAGEMENT LTD',
    name: 'LINCLUDEN MANAGEMENT LTD',
    label: 'LINCLUDEN MANAGEMENT LTD',
  },
  {
    cik: '0000872359',
    value: '0000872359,LINCOLN CAPITAL CORP',
    name: 'LINCOLN CAPITAL CORP',
    label: 'LINCOLN CAPITAL CORP',
  },
  {
    cik: '0001633862',
    value: '0001633862,LINCOLN CAPITAL LLC',
    name: 'LINCOLN CAPITAL LLC',
    label: 'LINCOLN CAPITAL LLC',
  },
  {
    cik: '0000059558',
    value: '0000059558,LINCOLN NATIONAL CORP',
    name: 'LINCOLN NATIONAL CORP',
    label: 'LINCOLN NATIONAL CORP',
  },
  {
    cik: '0001767045',
    value: '0001767045,LINDBROOK CAPITAL, LLC',
    name: 'LINDBROOK CAPITAL, LLC',
    label: 'LINDBROOK CAPITAL, LLC',
  },
  {
    cik: '0001279396',
    value: '0001279396,LINDEN ADVISORS LP',
    name: 'LINDEN ADVISORS LP',
    label: 'LINDEN ADVISORS LP',
  },
  {
    cik: '0001833819',
    value: '0001833819,LINDEN ROSE INVESTMENT LLC',
    name: 'LINDEN ROSE INVESTMENT LLC',
    label: 'LINDEN ROSE INVESTMENT LLC',
  },
  {
    cik: '0001874374',
    value: '0001874374,LINDENWOLD ADVISORS',
    name: 'LINDENWOLD ADVISORS',
    label: 'LINDENWOLD ADVISORS',
  },
  {
    cik: '0001484150',
    value: '0001484150,LINDSELL TRAIN LTD',
    name: 'LINDSELL TRAIN LTD',
    label: 'LINDSELL TRAIN LTD',
  },
  {
    cik: '0001797873',
    value: '0001797873,LINEWEAVER WEALTH ADVISORS, LLC',
    name: 'LINEWEAVER WEALTH ADVISORS, LLC',
    label: 'LINEWEAVER WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001527570',
    value: '0001527570,LINGOHR & PARTNER ASSET MANAGEMENT GMBH',
    name: 'LINGOHR & PARTNER ASSET MANAGEMENT GMBH',
    label: 'LINGOHR & PARTNER ASSET MANAGEMENT GMBH',
  },
  {
    cik: '0001905644',
    value: '0001905644,LINKER CAPITAL MANAGEMENT INC.',
    name: 'LINKER CAPITAL MANAGEMENT INC.',
    label: 'LINKER CAPITAL MANAGEMENT INC.',
  },
  {
    cik: '0001792424',
    value: '0001792424,LINK FUND SOLUTIONS LTD',
    name: 'LINK FUND SOLUTIONS LTD',
    label: 'LINK FUND SOLUTIONS LTD',
  },
  {
    cik: '0001313294',
    value: '0001313294,LINSCOMB & WILLIAMS, INC.',
    name: 'LINSCOMB & WILLIAMS, INC.',
    label: 'LINSCOMB & WILLIAMS, INC.',
  },
  {
    cik: '0001849040',
    value: '0001849040,LINSE CAPITAL LLC',
    name: 'LINSE CAPITAL LLC',
    label: 'LINSE CAPITAL LLC',
  },
  {
    cik: '0001849153',
    value: '0001849153,LINSE CAPITAL MANAGEMENT PR LLC',
    name: 'LINSE CAPITAL MANAGEMENT PR LLC',
    label: 'LINSE CAPITAL MANAGEMENT PR LLC',
  },
  {
    cik: '0001545065',
    value: '0001545065,LIONEYE CAPITAL MANAGEMENT LLC',
    name: 'LIONEYE CAPITAL MANAGEMENT LLC',
    label: 'LIONEYE CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001951388',
    value: '0001951388,LION LONG TERM PARTNERS LP',
    name: 'LION LONG TERM PARTNERS LP',
    label: 'LION LONG TERM PARTNERS LP',
  },
  {
    cik: '0001643354',
    value: '0001643354,LION POINT CAPITAL, LP',
    name: 'LION POINT CAPITAL, LP',
    label: 'LION POINT CAPITAL, LP',
  },
  {
    cik: '0001800533',
    value: '0001800533,LIONSBRIDGE WEALTH MANAGEMENT LLC',
    name: 'LIONSBRIDGE WEALTH MANAGEMENT LLC',
    label: 'LIONSBRIDGE WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0001575662',
    value: '0001575662,LIONSTONE CAPITAL MANAGEMENT LLC',
    name: 'LIONSTONE CAPITAL MANAGEMENT LLC',
    label: 'LIONSTONE CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001801991',
    value: '0001801991,LION STREET ADVISORS, LLC',
    name: 'LION STREET ADVISORS, LLC',
    label: 'LION STREET ADVISORS, LLC',
  },
  {
    cik: '0001544204',
    value: '0001544204,LIONTRUST INVESTMENT PARTNERS LLP',
    name: 'LIONTRUST INVESTMENT PARTNERS LLP',
    label: 'LIONTRUST INVESTMENT PARTNERS LLP',
  },
  {
    cik: '0001570396',
    value: '0001570396,LIPE & DALTON',
    name: 'LIPE & DALTON',
    label: 'LIPE & DALTON',
  },
  {
    cik: '0001774727',
    value: '0001774727,LIQUID STRATEGIES, LLC',
    name: 'LIQUID STRATEGIES, LLC',
    label: 'LIQUID STRATEGIES, LLC',
  },
  {
    cik: '0001424467',
    value: '0001424467,LISANTI CAPITAL GROWTH, LLC',
    name: 'LISANTI CAPITAL GROWTH, LLC',
    label: 'LISANTI CAPITAL GROWTH, LLC',
  },
  {
    cik: '0001292975',
    value: '0001292975,LITESPEED MANAGEMENT, L.L.C.',
    name: 'LITESPEED MANAGEMENT, L.L.C.',
    label: 'LITESPEED MANAGEMENT, L.L.C.',
  },
  {
    cik: '0001551867',
    value: '0001551867,LITMAN GREGORY ASSET MANAGEMENT LLC',
    name: 'LITMAN GREGORY ASSET MANAGEMENT LLC',
    label: 'LITMAN GREGORY ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001551867',
    value: '0001551867,LITMAN GREGORY WEALTH MANAGEMENT LLC',
    name: 'LITMAN GREGORY WEALTH MANAGEMENT LLC',
    label: 'LITMAN GREGORY WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0001539639',
    value: '0001539639,LITTLE HARBOR ADVISORS, LLC',
    name: 'LITTLE HARBOR ADVISORS, LLC',
    label: 'LITTLE HARBOR ADVISORS, LLC',
  },
  {
    cik: '0001748814',
    value: '0001748814,LITTLE HOUSE CAPITAL LLC',
    name: 'LITTLE HOUSE CAPITAL LLC',
    label: 'LITTLE HOUSE CAPITAL LLC',
  },
  {
    cik: '0001042537',
    value: '0001042537,LITTLEJOHN & CO LLC',
    name: 'LITTLEJOHN & CO LLC',
    label: 'LITTLEJOHN & CO LLC',
  },
  {
    cik: '0001799877',
    value: '0001799877,LIVE OAK PRIVATE WEALTH LLC',
    name: 'LIVE OAK PRIVATE WEALTH LLC',
    label: 'LIVE OAK PRIVATE WEALTH LLC',
  },
  {
    cik: '0001484721',
    value: '0001484721,LIVE YOUR VISION, LLC',
    name: 'LIVE YOUR VISION, LLC',
    label: 'LIVE YOUR VISION, LLC',
  },
  {
    cik: '0001547926',
    value: '0001547926,LIVFORSAKRINGSAKTIEBOLAGET SKANDIA (PUBL)',
    name: 'LIVFORSAKRINGSAKTIEBOLAGET SKANDIA (PUBL)',
    label: 'LIVFORSAKRINGSAKTIEBOLAGET SKANDIA (PUBL)',
  },
  {
    cik: '0001547926',
    value: '0001547926,LIVFORSAKRINGSBOLAGET SKANDIA, OMSESIDIGT',
    name: 'LIVFORSAKRINGSBOLAGET SKANDIA, OMSESIDIGT',
    label: 'LIVFORSAKRINGSBOLAGET SKANDIA, OMSESIDIGT',
  },
  {
    cik: '0001379119',
    value:
      '0001379119,LIVINGSTON GROUP ASSET MANAGEMENT CO (OPERATING AS SOUTHPORT CAPITAL MANAGEMENT)',
    name: 'LIVINGSTON GROUP ASSET MANAGEMENT CO (OPERATING AS SOUTHPORT CAPITAL MANAGEMENT)',
    label:
      'LIVINGSTON GROUP ASSET MANAGEMENT CO (OPERATING AS SOUTHPORT CAPITAL MANAGEMENT)',
  },
  {
    cik: '0001317264',
    value: '0001317264,LI WINIFRED I',
    name: 'LI WINIFRED I',
    label: 'LI WINIFRED I',
  },
  {
    cik: '0001598806',
    value: '0001598806,LJ TRUST CO LLC',
    name: 'LJ TRUST CO LLC',
    label: 'LJ TRUST CO LLC',
  },
  {
    cik: '0001665976',
    value: '0001665976,LLBH PRIVATE WEALTH MANAGEMENT, LLC',
    name: 'LLBH PRIVATE WEALTH MANAGEMENT, LLC',
    label: 'LLBH PRIVATE WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001911184',
    value: '0001911184,LL FUNDS, LLC',
    name: 'LL FUNDS, LLC',
    label: 'LL FUNDS, LLC',
  },
  {
    cik: '0001802474',
    value: '0001802474,LLOYD ADVISORY SERVICES, LLC.',
    name: 'LLOYD ADVISORY SERVICES, LLC.',
    label: 'LLOYD ADVISORY SERVICES, LLC.',
  },
  {
    cik: '0001909251',
    value: '0001909251,LLOYD PARK, LLC',
    name: 'LLOYD PARK, LLC',
    label: 'LLOYD PARK, LLC',
  },
  {
    cik: '0001160106',
    value: '0001160106,LLOYDS BANKING GROUP PLC',
    name: 'LLOYDS BANKING GROUP PLC',
    label: 'LLOYDS BANKING GROUP PLC',
  },
  {
    cik: '0001730910',
    value: '0001730910,LLR MANAGEMENT, L.P.',
    name: 'LLR MANAGEMENT, L.P.',
    label: 'LLR MANAGEMENT, L.P.',
  },
  {
    cik: '0001793163',
    value: '0001793163,LLR WALNUT, L.P.',
    name: 'LLR WALNUT, L.P.',
    label: 'LLR WALNUT, L.P.',
  },
  {
    cik: '0001470944',
    value: '0001470944,LMCG INVESTMENTS, LLC',
    name: 'LMCG INVESTMENTS, LLC',
    label: 'LMCG INVESTMENTS, LLC',
  },
  {
    cik: '0001942401',
    value: '0001942401,LMDAGG, L.L.C.',
    name: 'LMDAGG, L.L.C.',
    label: 'LMDAGG, L.L.C.',
  },
  {
    cik: '0000870260',
    value: '0000870260,L.M. KOHN & COMPANY',
    name: 'L.M. KOHN & COMPANY',
    label: 'L.M. KOHN & COMPANY',
  },
  {
    cik: '0001135778',
    value: '000113,LMM LLC78',
    name: 'LMM LLC',
    label: 'LMM LLC',
  },
  {
    cik: '0001515685',
    value: '0001515685,LMR MASTER FUND LTD',
    name: 'LMR MASTER FUND LTD',
    label: 'LMR MASTER FUND LTD',
  },
  {
    cik: '0001578621',
    value: '0001578621,LMR PARTNERS LLP',
    name: 'LMR PARTNERS LLP',
    label: 'LMR PARTNERS LLP',
  },
  {
    cik: '0001666868',
    value: '0001666868,LNZ CAPITAL LP',
    name: 'LNZ CAPITAL LP',
    label: 'LNZ CAPITAL LP',
  },
  {
    cik: '0001906539',
    value: '0001906539,LOCKERMAN FINANCIAL GROUP, INC.',
    name: 'LOCKERMAN FINANCIAL GROUP, INC.',
    label: 'LOCKERMAN FINANCIAL GROUP, INC.',
  },
  {
    cik: '0000936468',
    value: '0000936468,LOCKHEED MARTIN CORP',
    name: 'LOCKHEED MARTIN CORP',
    label: 'LOCKHEED MARTIN CORP',
  },
  {
    cik: '0000897599',
    value: '0000897599,LOCKHEED MARTIN INVESTMENT MANAGEMENT CO',
    name: 'LOCKHEED MARTIN INVESTMENT MANAGEMENT CO',
    label: 'LOCKHEED MARTIN INVESTMENT MANAGEMENT CO',
  },
  {
    cik: '0001349434',
    value: '0001349434,LOCUST WOOD CAPITAL ADVISERS, LLC',
    name: 'LOCUST WOOD CAPITAL ADVISERS, LLC',
    label: 'LOCUST WOOD CAPITAL ADVISERS, LLC',
  },
  {
    cik: '0001778719',
    value: '0001778719,LODBROK CAPITAL LLP',
    name: 'LODBROK CAPITAL LLP',
    label: 'LODBROK CAPITAL LLP',
  },
  {
    cik: '0001058949',
    value: '0001058949,LODESTAR INVESTMENT COUNSEL LLC/IL',
    name: 'LODESTAR INVESTMENT COUNSEL LLC/IL',
    label: 'LODESTAR INVESTMENT COUNSEL LLC/IL',
  },
  {
    cik: '0001598245',
    value: '0001598245,LODGE HILL CAPITAL, LLC',
    name: 'LODGE HILL CAPITAL, LLC',
    label: 'LODGE HILL CAPITAL, LLC',
  },
  {
    cik: '0001434467',
    value: '0001434467,LOEB OFFSHORE MANAGEMENT LP',
    name: 'LOEB OFFSHORE MANAGEMENT LP',
    label: 'LOEB OFFSHORE MANAGEMENT LP',
  },
  {
    cik: '0000942618',
    value: '0000942618,LOEB PARTNERS CORP',
    name: 'LOEB PARTNERS CORP',
    label: 'LOEB PARTNERS CORP',
  },
  {
    cik: '0000060086',
    value: '000006008,LOEWS CORP',
    name: 'LOEWS CORP',
    label: 'LOEWS CORP',
  },
  {
    cik: '0001121330',
    value: '0001121330,LOGAN CAPITAL MANAGEMENT INC',
    name: 'LOGAN CAPITAL MANAGEMENT INC',
    label: 'LOGAN CAPITAL MANAGEMENT INC',
  },
  {
    cik: '0001535521',
    value: '0001535521,LOGAN STONE CAPITAL, LLC',
    name: 'LOGAN STONE CAPITAL, LLC',
    label: 'LOGAN STONE CAPITAL, LLC',
  },
  {
    cik: '0001589278',
    value: '0001589278,LOGE SOLUTIONS, LLC',
    name: 'LOGE SOLUTIONS, LLC',
    label: 'LOGE SOLUTIONS, LLC',
  },
  {
    cik: '0001792126',
    value: '0001792126,LOGOS GLOBAL MANAGEMENT LP',
    name: 'LOGOS GLOBAL MANAGEMENT LP',
    label: 'LOGOS GLOBAL MANAGEMENT LP',
  },
  {
    cik: '0001842840',
    value: '0001842840,LOKKEN INVESTMENT GROUP LLC',
    name: 'LOKKEN INVESTMENT GROUP LLC',
    label: 'LOKKEN INVESTMENT GROUP LLC',
  },
  {
    cik: '0001559972',
    value: '0001559972,LOMAS CAPITAL MANAGEMENT, LLC',
    name: 'LOMAS CAPITAL MANAGEMENT, LLC',
    label: 'LOMAS CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0000887211',
    value: '0000887211,LOMBARDIA CAPITAL PARTNERS LLC',
    name: 'LOMBARDIA CAPITAL PARTNERS LLC',
    label: 'LOMBARDIA CAPITAL PARTNERS LLC',
  },
  {
    cik: '0001535784',
    value: '0001535784,LOMBARD ODIER ASSET MANAGEMENT (EUROPE) LTD',
    name: 'LOMBARD ODIER ASSET MANAGEMENT (EUROPE) LTD',
    label: 'LOMBARD ODIER ASSET MANAGEMENT (EUROPE) LTD',
  },
  {
    cik: '0001535660',
    value: '0001535660,LOMBARD ODIER ASSET MANAGEMENT (SWITZERLAND) SA',
    name: 'LOMBARD ODIER ASSET MANAGEMENT (SWITZERLAND) SA',
    label: 'LOMBARD ODIER ASSET MANAGEMENT (SWITZERLAND) SA',
  },
  {
    cik: '0001491719',
    value: '0001491719,LOMBARD ODIER ASSET MANAGEMENT (USA) CORP',
    name: 'LOMBARD ODIER ASSET MANAGEMENT (USA) CORP',
    label: 'LOMBARD ODIER ASSET MANAGEMENT (USA) CORP',
  },
  {
    cik: '0001615424',
    value: '0001615424,LOMBARD ODIER & CIE (BAHAMAS) LTD',
    name: 'LOMBARD ODIER & CIE (BAHAMAS) LTD',
    label: 'LOMBARD ODIER & CIE (BAHAMAS) LTD',
  },
  {
    cik: '0001615305',
    value: '0001615305,LOMBARD ODIER & CIE (CANADA), LIMITED PARTNERSHIP',
    name: 'LOMBARD ODIER & CIE (CANADA), LIMITED PARTNERSHIP',
    label: 'LOMBARD ODIER & CIE (CANADA), LIMITED PARTNERSHIP',
  },
  {
    cik: '0001528147',
    value: '0001528147,LOMBARD ODIER TRANSATLANTIC, LIMITED PARTNERSHIP',
    name: 'LOMBARD ODIER TRANSATLANTIC, LIMITED PARTNERSHIP',
    label: 'LOMBARD ODIER TRANSATLANTIC, LIMITED PARTNERSHIP',
  },
  {
    cik: '0001767559',
    value: '0001767559,LONDON & CAPITAL ASSET MANAGEMENT LTD',
    name: 'LONDON & CAPITAL ASSET MANAGEMENT LTD',
    label: 'LONDON & CAPITAL ASSET MANAGEMENT LTD',
  },
  {
    cik: '0001259887',
    value: '0001259887,LONDON CO OF VIRGINIA',
    name: 'LONDON CO OF VIRGINIA',
    label: 'LONDON CO OF VIRGINIA',
  },
  {
    cik: '0001061165',
    value: '0001061165,LONE PINE CAPITAL LLC',
    name: 'LONE PINE CAPITAL LLC',
    label: 'LONE PINE CAPITAL LLC',
  },
  {
    cik: '0001317410',
    value: '0001317410,LONESTAR CAPITAL MANAGEMENT LLC',
    name: 'LONESTAR CAPITAL MANAGEMENT LLC',
    label: 'LONESTAR CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001583825',
    value: '0001583825,LONE STAR GLOBAL ACQUISITIONS, LTD.',
    name: 'LONE STAR GLOBAL ACQUISITIONS, LTD.',
    label: 'LONE STAR GLOBAL ACQUISITIONS, LTD.',
  },
  {
    cik: '0001589325',
    value: '0001589325,LONE STAR VALUE INVESTORS GP LLC',
    name: 'LONE STAR VALUE INVESTORS GP LLC',
    label: 'LONE STAR VALUE INVESTORS GP LLC',
  },
  {
    cik: '0001589350',
    value: '0001589350,LONE STAR VALUE MANAGEMENT LLC',
    name: 'LONE STAR VALUE MANAGEMENT LLC',
    label: 'LONE STAR VALUE MANAGEMENT LLC',
  },
  {
    cik: '0001316393',
    value: '0001316393,LONGBOW CAPITAL PARTNERS, L.P.',
    name: 'LONGBOW CAPITAL PARTNERS, L.P.',
    label: 'LONGBOW CAPITAL PARTNERS, L.P.',
  },
  {
    cik: '0001845501',
    value: '0001845501,LONG CORRIDOR ASSET MANAGEMENT LTD',
    name: 'LONG CORRIDOR ASSET MANAGEMENT LTD',
    label: 'LONG CORRIDOR ASSET MANAGEMENT LTD',
  },
  {
    cik: '0001534417',
    value: '0001534417,LONGER INVESTMENTS INC.',
    name: 'LONGER INVESTMENTS INC.',
    label: 'LONGER INVESTMENTS INC.',
  },
  {
    cik: '0001167487',
    value: '0001167487,LONGFELLOW INVESTMENT MANAGEMENT CO LLC',
    name: 'LONGFELLOW INVESTMENT MANAGEMENT CO LLC',
    label: 'LONGFELLOW INVESTMENT MANAGEMENT CO LLC',
  },
  {
    cik: '0001602716',
    value: '0001602716,LONG FOCUS CAPITAL MANAGEMENT, LLC',
    name: 'LONG FOCUS CAPITAL MANAGEMENT, LLC',
    label: 'LONG FOCUS CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001453447',
    value: '0001453447,LONGHORN CAPITAL PARTNERS, L.P.',
    name: 'LONGHORN CAPITAL PARTNERS, L.P.',
    label: 'LONGHORN CAPITAL PARTNERS, L.P.',
  },
  {
    cik: '0001664999',
    value: '0001664999,LONGITUDE (CAYMAN) LTD.',
    name: 'LONGITUDE (CAYMAN) LTD.',
    label: 'LONGITUDE (CAYMAN) LTD.',
  },
  {
    cik: '0001516622',
    value: '0001516622,LONG OAR GLOBAL INVESTORS, LLC',
    name: 'LONG OAR GLOBAL INVESTORS, LLC',
    label: 'LONG OAR GLOBAL INVESTORS, LLC',
  },
  {
    cik: '0001751978',
    value: '0001751978,LONG PATH PARTNERS FUND, LP',
    name: 'LONG PATH PARTNERS FUND, LP',
    label: 'LONG PATH PARTNERS FUND, LP',
  },
  {
    cik: '0001905112',
    value: '0001905112,LONG PATH PARTNERS LP',
    name: 'LONG PATH PARTNERS LP',
    label: 'LONG PATH PARTNERS LP',
  },
  {
    cik: '0001499066',
    value: '0001499066,LONG POND CAPITAL, LP',
    name: 'LONG POND CAPITAL, LP',
    label: 'LONG POND CAPITAL, LP',
  },
  {
    cik: '0001620081',
    value: '0001620081,LONG ROAD INVESTMENT COUNSEL, LLC',
    name: 'LONG ROAD INVESTMENT COUNSEL, LLC',
    label: 'LONG ROAD INVESTMENT COUNSEL, LLC',
  },
  {
    cik: '0001903296',
    value: '0001903296,LONG RUN WEALTH ADVISORS, LLC',
    name: 'LONG RUN WEALTH ADVISORS, LLC',
    label: 'LONG RUN WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001086477',
    value: '0001086477,LONGVIEW ASSET MANAGEMENT, LLC',
    name: 'LONGVIEW ASSET MANAGEMENT, LLC',
    label: 'LONGVIEW ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001426092',
    value: '0001426092,LONGVIEW PARTNERS (GUERNSEY) LTD',
    name: 'LONGVIEW PARTNERS (GUERNSEY) LTD',
    label: 'LONGVIEW PARTNERS (GUERNSEY) LTD',
  },
  {
    cik: '0001869470',
    value: '0001869470,LONG WALK MANAGEMENT LP',
    name: 'LONG WALK MANAGEMENT LP',
    label: 'LONG WALK MANAGEMENT LP',
  },
  {
    cik: '0001511465',
    value: '0001511465,LONG WHARF INVESTORS, INC.',
    name: 'LONG WHARF INVESTORS, INC.',
    label: 'LONG WHARF INVESTORS, INC.',
  },
  {
    cik: '0001582844',
    value: '0001582844,LONGWOOD CAPITAL PARTNERS LLC',
    name: 'LONGWOOD CAPITAL PARTNERS LLC',
    label: 'LONGWOOD CAPITAL PARTNERS LLC',
  },
  {
    cik: '0001631474',
    value: '0001631474,LONGWOOD FUND GP, LLC',
    name: 'LONGWOOD FUND GP, LLC',
    label: 'LONGWOOD FUND GP, LLC',
  },
  {
    cik: '0000312348',
    value: '0000312348,LOOMIS SAYLES & CO L P',
    name: 'LOOMIS SAYLES & CO L P',
    label: 'LOOMIS SAYLES & CO L P',
  },
  {
    cik: '0001358538',
    value: '0001358538,LORBER DAVID A',
    name: 'LORBER DAVID A',
    label: 'LORBER DAVID A',
  },
  {
    cik: '0000728100',
    value: '0000728100,LORD, ABBETT & CO. LLC',
    name: 'LORD, ABBETT & CO. LLC',
    label: 'LORD, ABBETT & CO. LLC',
  },
  {
    cik: '0001599762',
    value: '0001599762,LOREM IPSUM MANAGEMENT, LLC',
    name: 'LOREM IPSUM MANAGEMENT, LLC',
    label: 'LOREM IPSUM MANAGEMENT, LLC',
  },
  {
    cik: '0000932974',
    value: '0000932974,LORING WOLCOTT & COOLIDGE FIDUCIARY ADVISORS LLP/MA',
    name: 'LORING WOLCOTT & COOLIDGE FIDUCIARY ADVISORS LLP/MA',
    label: 'LORING WOLCOTT & COOLIDGE FIDUCIARY ADVISORS LLP/MA',
  },
  {
    cik: '0001851362',
    value: '0001851362,LORNE STEINBERG WEALTH MANAGEMENT INC.',
    name: 'LORNE STEINBERG WEALTH MANAGEMENT INC.',
    label: 'LORNE STEINBERG WEALTH MANAGEMENT INC.',
  },
  {
    cik: '0001177206',
    value: '0001177206,LOS ANGELES CAPITAL MANAGEMENT & EQUITY RESEARCH INC',
    name: 'LOS ANGELES CAPITAL MANAGEMENT & EQUITY RESEARCH INC',
    label: 'LOS ANGELES CAPITAL MANAGEMENT & EQUITY RESEARCH INC',
  },
  {
    cik: '0001177206',
    value: '0001177206,LOS ANGELES CAPITAL MANAGEMENT LLC',
    name: 'LOS ANGELES CAPITAL MANAGEMENT LLC',
    label: 'LOS ANGELES CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001597089',
    value: '0001597089,LOUDON INVESTMENT MANAGEMENT, LLC',
    name: 'LOUDON INVESTMENT MANAGEMENT, LLC',
    label: 'LOUDON INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001889527',
    value: '0001889527,LOUIS-DREYFUS FAMILY OFFICE LLC',
    name: 'LOUIS-DREYFUS FAMILY OFFICE LLC',
    label: 'LOUIS-DREYFUS FAMILY OFFICE LLC',
  },
  {
    cik: '0001537191',
    value: '0001537191,LOUISIANA STATE EMPLOYEES RETIREMENT SYSTEM',
    name: 'LOUISIANA STATE EMPLOYEES RETIREMENT SYSTEM',
    label: 'LOUISIANA STATE EMPLOYEES RETIREMENT SYSTEM',
  },
  {
    cik: '0001569515',
    value: '0001569515,LOUKAS STEPHEN',
    name: 'LOUKAS STEPHEN',
    label: 'LOUKAS STEPHEN',
  },
  {
    cik: '0001821168',
    value: '0001821168,LOUNTZIS ASSET MANAGEMENT, LLC',
    name: 'LOUNTZIS ASSET MANAGEMENT, LLC',
    label: 'LOUNTZIS ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001568190',
    value: '0001568190,LOURD CAPITAL, LLC',
    name: 'LOURD CAPITAL, LLC',
    label: 'LOURD CAPITAL, LLC',
  },
  {
    cik: '0001798026',
    value: '0001798026,LOVELESS WEALTH MANAGEMENT LLC',
    name: 'LOVELESS WEALTH MANAGEMENT LLC',
    label: 'LOVELESS WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0000022657',
    value: '0000022657,LOWE BROCKENBROUGH & CO INC',
    name: 'LOWE BROCKENBROUGH & CO INC',
    label: 'LOWE BROCKENBROUGH & CO INC',
  },
  {
    cik: '0001574010',
    value: '0001574010,LOWE FS, LLC',
    name: 'LOWE FS, LLC',
    label: 'LOWE FS, LLC',
  },
  {
    cik: '0001816635',
    value: '0001816635,LOWELL BLAKE & ASSOCIATES INC.',
    name: 'LOWELL BLAKE & ASSOCIATES INC.',
    label: 'LOWELL BLAKE & ASSOCIATES INC.',
  },
  {
    cik: '0001919701',
    value: '0001919701,LOWERY THOMAS, LLC',
    name: 'LOWERY THOMAS, LLC',
    label: 'LOWERY THOMAS, LLC',
  },
  {
    cik: '0001574010',
    value: '0001574010,LOWE WEALTH ADVISORS, LLC',
    name: 'LOWE WEALTH ADVISORS, LLC',
    label: 'LOWE WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001403438',
    value: '0001403438,LPL FINANCIAL LLC',
    name: 'LPL FINANCIAL LLC',
    label: 'LPL FINANCIAL LLC',
  },
  {
    cik: '0001801101',
    value: '0001801,LPWM LLC1',
    name: 'LPWM LLC',
    label: 'LPWM LLC',
  },
  {
    cik: '0001798923',
    value: '0001798923,LRT CAPITAL MANAGEMENT, LLC',
    name: 'LRT CAPITAL MANAGEMENT, LLC',
    label: 'LRT CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001392364',
    value: '0001392364,L & S ADVISORS INC',
    name: 'L & S ADVISORS INC',
    label: 'L & S ADVISORS INC',
  },
  {
    cik: '0001455845',
    value: '0001455845,LS INVESTMENT ADVISORS, LLC',
    name: 'LS INVESTMENT ADVISORS, LLC',
    label: 'LS INVESTMENT ADVISORS, LLC',
  },
  {
    cik: '0001728850',
    value: '0001728850,LSP INVESTMENT ADVISORS, LLC',
    name: 'LSP INVESTMENT ADVISORS, LLC',
    label: 'LSP INVESTMENT ADVISORS, LLC',
  },
  {
    cik: '0001405844',
    value: '0001405844,LS POWER PARTNERS II, L.P.',
    name: 'LS POWER PARTNERS II, L.P.',
    label: 'LS POWER PARTNERS II, L.P.',
  },
  {
    cik: '0001405847',
    value: '0001405847,LS POWER PARTNERS, L.P.',
    name: 'LS POWER PARTNERS, L.P.',
    label: 'LS POWER PARTNERS, L.P.',
  },
  {
    cik: '0001050470',
    value: '0001050470,LSV ASSET MANAGEMENT',
    name: 'LSV ASSET MANAGEMENT',
    label: 'LSV ASSET MANAGEMENT',
  },
  {
    cik: '0001936987',
    value: '0001936987,LTG CAPITAL LLC',
    name: 'LTG CAPITAL LLC',
    label: 'LTG CAPITAL LLC',
  },
  {
    cik: '0001894188',
    value: '0001894188,LTS ONE MANAGEMENT LP',
    name: 'LTS ONE MANAGEMENT LP',
    label: 'LTS ONE MANAGEMENT LP',
  },
  {
    cik: '0001473033',
    value: '0001473033,LUBAR & CO., INC',
    name: 'LUBAR & CO., INC',
    label: 'LUBAR & CO., INC',
  },
  {
    cik: '0001655557',
    value: '0001655557,LUBERT-ADLER MANAGEMENT COMPANY, LP',
    name: 'LUBERT-ADLER MANAGEMENT COMPANY, LP',
    label: 'LUBERT-ADLER MANAGEMENT COMPANY, LP',
  },
  {
    cik: '0001280577',
    value: '0001280577,LUCAS CAPITAL MANAGEMENT',
    name: 'LUCAS CAPITAL MANAGEMENT',
    label: 'LUCAS CAPITAL MANAGEMENT',
  },
  {
    cik: '0001218961',
    value: '0001218961,LUCERNE CAPITAL MANAGEMENT LP',
    name: 'LUCERNE CAPITAL MANAGEMENT LP',
    label: 'LUCERNE CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001688171',
    value: '0001688171,LUCHA CAPITAL MANAGEMENT, LP',
    name: 'LUCHA CAPITAL MANAGEMENT, LP',
    label: 'LUCHA CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0001730471',
    value: '0001730471,LUCIA WEALTH SERVICES, LLC',
    name: 'LUCIA WEALTH SERVICES, LLC',
    label: 'LUCIA WEALTH SERVICES, LLC',
  },
  {
    cik: '0001593365',
    value: '0001593365,LUCUS ADVISORS LLC',
    name: 'LUCUS ADVISORS LLC',
    label: 'LUCUS ADVISORS LLC',
  },
  {
    cik: '0001730546',
    value: '0001730546,LUKEN INVESTMENT ANALYTICS, LLC',
    name: 'LUKEN INVESTMENT ANALYTICS, LLC',
    label: 'LUKEN INVESTMENT ANALYTICS, LLC',
  },
  {
    cik: '0001843558',
    value: '0001843558,LUMATURE WEALTH PARTNERS, LLC',
    name: 'LUMATURE WEALTH PARTNERS, LLC',
    label: 'LUMATURE WEALTH PARTNERS, LLC',
  },
  {
    cik: '0001602476',
    value: '0001602476,LUMBARD & KELLNER, LLC',
    name: 'LUMBARD & KELLNER, LLC',
    label: 'LUMBARD & KELLNER, LLC',
  },
  {
    cik: '0001511847',
    value: '0001511847,LUMINA FUND MANAGEMENT LLC',
    name: 'LUMINA FUND MANAGEMENT LLC',
    label: 'LUMINA FUND MANAGEMENT LLC',
  },
  {
    cik: '0001279151',
    value: '0001279151,LUMINUS MANAGEMENT LLC',
    name: 'LUMINUS MANAGEMENT LLC',
    label: 'LUMINUS MANAGEMENT LLC',
  },
  {
    cik: '0001694165',
    value: '0001694165,LUNIA CAPITAL LP',
    name: 'LUNIA CAPITAL LP',
    label: 'LUNIA CAPITAL LP',
  },
  {
    cik: '0001737112',
    value: '0001737112,LUTZ FINANCIAL SERVICES LLC',
    name: 'LUTZ FINANCIAL SERVICES LLC',
    label: 'LUTZ FINANCIAL SERVICES LLC',
  },
  {
    cik: '0001316580',
    value: '0001316580,LUXOR CAPITAL GROUP, LP',
    name: 'LUXOR CAPITAL GROUP, LP',
    label: 'LUXOR CAPITAL GROUP, LP',
  },
  {
    cik: '0001602955',
    value: '0001602955,LUZICH PARTNERS LLC',
    name: 'LUZICH PARTNERS LLC',
    label: 'LUZICH PARTNERS LLC',
  },
  {
    cik: '0001053054',
    value: '0001053054,LVM CAPITAL MANAGEMENT LTD/MI',
    name: 'LVM CAPITAL MANAGEMENT LTD/MI',
    label: 'LVM CAPITAL MANAGEMENT LTD/MI',
  },
  {
    cik: '0001576053',
    value: '0001576053,LVW ADVISORS, LLC',
    name: 'LVW ADVISORS, LLC',
    label: 'LVW ADVISORS, LLC',
  },
  {
    cik: '0001574408',
    value: '0001574408,LVZ ADVISORS, INC.',
    name: 'LVZ ADVISORS, INC.',
    label: 'LVZ ADVISORS, INC.',
  },
  {
    cik: '0001574408',
    value: '00015744,LVZ, INC.',
    name: 'LVZ, INC.',
    label: 'LVZ, INC.',
  },
  {
    cik: '0001697716',
    value: '0001697716,LWM ADVISORY SERVICES, LLC',
    name: 'LWM ADVISORY SERVICES, LLC',
    label: 'LWM ADVISORY SERVICES, LLC',
  },
  {
    cik: '0001801634',
    value: '0001801634,LWS WEALTH ADVISORS, INC.',
    name: 'LWS WEALTH ADVISORS, INC.',
    label: 'LWS WEALTH ADVISORS, INC.',
  },
  {
    cik: '0001686444',
    value: '0001686444,LYELL WEALTH MANAGEMENT, LP',
    name: 'LYELL WEALTH MANAGEMENT, LP',
    label: 'LYELL WEALTH MANAGEMENT, LP',
  },
  {
    cik: '0001906719',
    value: '0001906719,LYNCH ASSET MANAGEMENT, INC.',
    name: 'LYNCH ASSET MANAGEMENT, INC.',
    label: 'LYNCH ASSET MANAGEMENT, INC.',
  },
  {
    cik: '0001108965',
    value: '0001108965,LYNCH & ASSOCIATES/IN',
    name: 'LYNCH & ASSOCIATES/IN',
    label: 'LYNCH & ASSOCIATES/IN',
  },
  {
    cik: '0001734341',
    value: '0001734341,LYNROCK LAKE LP',
    name: 'LYNROCK LAKE LP',
    label: 'LYNROCK LAKE LP',
  },
  {
    cik: '0001846412',
    value: '0001846412,LYNWOOD CAPITAL MANAGEMENT INC.',
    name: 'LYNWOOD CAPITAL MANAGEMENT INC.',
    label: 'LYNWOOD CAPITAL MANAGEMENT INC.',
  },
  {
    cik: '0001910456',
    value: '0001910456,LYNX1 CAPITAL MANAGEMENT LP',
    name: 'LYNX1 CAPITAL MANAGEMENT LP',
    label: 'LYNX1 CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001570598',
    value: '0001570598,LYON STREET CAPITAL, LLC',
    name: 'LYON STREET CAPITAL, LLC',
    label: 'LYON STREET CAPITAL, LLC',
  },
  {
    cik: '0001526528',
    value: '0001526528,LYONS WEALTH MANAGEMENT, LLC.',
    name: 'LYONS WEALTH MANAGEMENT, LLC.',
    label: 'LYONS WEALTH MANAGEMENT, LLC.',
  },
  {
    cik: '0001542302',
    value: '0001542302,LYRICAL ASSET MANAGEMENT LP',
    name: 'LYRICAL ASSET MANAGEMENT LP',
    label: 'LYRICAL ASSET MANAGEMENT LP',
  },
  {
    cik: '0001710618',
    value: '0001710618,LYXOR ASSET MANAGEMENT INC.',
    name: 'LYXOR ASSET MANAGEMENT INC.',
    label: 'LYXOR ASSET MANAGEMENT INC.',
  },
  {
    cik: '0001807489',
    value: '0001807489,M28 CAPITAL MANAGEMENT LP',
    name: 'M28 CAPITAL MANAGEMENT LP',
    label: 'M28 CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001511291',
    value: '00015112,M3F, INC.',
    name: 'M3F, INC.',
    label: 'M3F, INC.',
  },
  {
    cik: '0001536216',
    value: '0001536216,MACELLUM ADVISORS, LP',
    name: 'MACELLUM ADVISORS, LP',
    label: 'MACELLUM ADVISORS, LP',
  },
  {
    cik: '0001442173',
    value: '0001442173,MACGUIRE, CHESWICK & TUTTLE INVESTMENT COUNSEL LLC',
    name: 'MACGUIRE, CHESWICK & TUTTLE INVESTMENT COUNSEL LLC',
    label: 'MACGUIRE, CHESWICK & TUTTLE INVESTMENT COUNSEL LLC',
  },
  {
    cik: '0001843358',
    value: '0001843358,MACH-1 FINANCIAL GROUP, INC.',
    name: 'MACH-1 FINANCIAL GROUP, INC.',
    label: 'MACH-1 FINANCIAL GROUP, INC.',
  },
  {
    cik: '0001843358',
    value: '0001843358,MACH-1 FINANCIAL GROUP, LLC',
    name: 'MACH-1 FINANCIAL GROUP, LLC',
    label: 'MACH-1 FINANCIAL GROUP, LLC',
  },
  {
    cik: '0001744373',
    value: '0001744373,MACHINA CAPITAL S.A.S.',
    name: 'MACHINA CAPITAL S.A.S.',
    label: 'MACHINA CAPITAL S.A.S.',
  },
  {
    cik: '0000061227',
    value: '0000061227,MACKAY SHIELDS LLC',
    name: 'MACKAY SHIELDS LLC',
    label: 'MACKAY SHIELDS LLC',
  },
  {
    cik: '0000919859',
    value: '0000919859,MACKENZIE FINANCIAL CORP',
    name: 'MACKENZIE FINANCIAL CORP',
    label: 'MACKENZIE FINANCIAL CORP',
  },
  {
    cik: '0001536549',
    value: '0001536549,MACKEY KOMARA & DANKOVICH, LLC',
    name: 'MACKEY KOMARA & DANKOVICH, LLC',
    label: 'MACKEY KOMARA & DANKOVICH, LLC',
  },
  {
    cik: '0001106590',
    value: '0001106590,MACNEALY HOOVER INVESTMENT MANAGEMENT INC',
    name: 'MACNEALY HOOVER INVESTMENT MANAGEMENT INC',
    label: 'MACNEALY HOOVER INVESTMENT MANAGEMENT INC',
  },
  {
    cik: '0001418333',
    value: '0001418333,MACQUARIE GROUP LTD',
    name: 'MACQUARIE GROUP LTD',
    label: 'MACQUARIE GROUP LTD',
  },
  {
    cik: '0001762388',
    value: '0001762388,MACRO CONSULTING GROUP',
    name: 'MACRO CONSULTING GROUP',
    label: 'MACRO CONSULTING GROUP',
  },
  {
    cik: '0001551727',
    value: '0001551727,MACROVIEW INVESTMENT MANAGEMENT LLC',
    name: 'MACROVIEW INVESTMENT MANAGEMENT LLC',
    label: 'MACROVIEW INVESTMENT MANAGEMENT LLC',
  },
  {
    cik: '0001542284',
    value: '0001542284,MADDEN ADVISORY SERVICES, INC.',
    name: 'MADDEN ADVISORY SERVICES, INC.',
    label: 'MADDEN ADVISORY SERVICES, INC.',
  },
  {
    cik: '0001803566',
    value: '0001803566,MADDEN SECURITIES CORP',
    name: 'MADDEN SECURITIES CORP',
    label: 'MADDEN SECURITIES CORP',
  },
  {
    cik: '0001535862',
    value: '0001535862,MADER & SHANNON WEALTH MANAGEMENT, INC.',
    name: 'MADER & SHANNON WEALTH MANAGEMENT, INC.',
    label: 'MADER & SHANNON WEALTH MANAGEMENT, INC.',
  },
  {
    cik: '0001299351',
    value: '0001299351,MADISON ASSET MANAGEMENT, LLC',
    name: 'MADISON ASSET MANAGEMENT, LLC',
    label: 'MADISON ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001801265',
    value: '0001801265,MADISON AVENUE PARTNERS, LP',
    name: 'MADISON AVENUE PARTNERS, LP',
    label: 'MADISON AVENUE PARTNERS, LP',
  },
  {
    cik: '0001181100',
    value: '0001181100,MADISON DEARBORN PARTNERS LLC',
    name: 'MADISON DEARBORN PARTNERS LLC',
    label: 'MADISON DEARBORN PARTNERS LLC',
  },
  {
    cik: '0001706035',
    value: '0001706035,MADISON INTERNATIONAL GLOBAL ALPHA REALTY, LP',
    name: 'MADISON INTERNATIONAL GLOBAL ALPHA REALTY, LP',
    label: 'MADISON INTERNATIONAL GLOBAL ALPHA REALTY, LP',
  },
  {
    cik: '0001640680',
    value: '0001640680,MADISON INTERNATIONAL REALTY HOLDINGS, LLC',
    name: 'MADISON INTERNATIONAL REALTY HOLDINGS, LLC',
    label: 'MADISON INTERNATIONAL REALTY HOLDINGS, LLC',
  },
  {
    cik: '0001138060',
    value: '0001138060,MADISON INVESTMENT HOLDINGS INC',
    name: 'MADISON INVESTMENT HOLDINGS INC',
    label: 'MADISON INVESTMENT HOLDINGS INC',
  },
  {
    cik: '0001388007',
    value: '0001388007,MADISON SCOTTSDALE, LC',
    name: 'MADISON SCOTTSDALE, LC',
    label: 'MADISON SCOTTSDALE, LC',
  },
  {
    cik: '0001423217',
    value: '0001423217,MADISON STREET PARTNERS, LLC',
    name: 'MADISON STREET PARTNERS, LLC',
    label: 'MADISON STREET PARTNERS, LLC',
  },
  {
    cik: '0001801857',
    value: '0001801857,MADISON WEALTH MANAGEMENT',
    name: 'MADISON WEALTH MANAGEMENT',
    label: 'MADISON WEALTH MANAGEMENT',
  },
  {
    cik: '0001844424',
    value: '0001844424,MADISON WEALTH PARTNERS, INC',
    name: 'MADISON WEALTH PARTNERS, INC',
    label: 'MADISON WEALTH PARTNERS, INC',
  },
  {
    cik: '0001384484',
    value: '0001384484,MAD RIVER INVESTORS',
    name: 'MAD RIVER INVESTORS',
    label: 'MAD RIVER INVESTORS',
  },
  {
    cik: '0001578370',
    value: '0001578370,MADRONA FINANCIAL SERVICES, LLC',
    name: 'MADRONA FINANCIAL SERVICES, LLC',
    label: 'MADRONA FINANCIAL SERVICES, LLC',
  },
  {
    cik: '0001663871',
    value: '0001663871,MADRONA FUNDS, LLC',
    name: 'MADRONA FUNDS, LLC',
    label: 'MADRONA FUNDS, LLC',
  },
  {
    cik: '0001887785',
    value: '0001887785,MADRONA VENTURE GROUP, LLC',
    name: 'MADRONA VENTURE GROUP, LLC',
    label: 'MADRONA VENTURE GROUP, LLC',
  },
  {
    cik: '0001787423',
    value: '0001787423,MADRYN ASSET MANAGEMENT, LP',
    name: 'MADRYN ASSET MANAGEMENT, LP',
    label: 'MADRYN ASSET MANAGEMENT, LP',
  },
  {
    cik: '0001889283',
    value: '0001889283,MADRYN SELECT OPPORTUNITIES, LP',
    name: 'MADRYN SELECT OPPORTUNITIES, LP',
    label: 'MADRYN SELECT OPPORTUNITIES, LP',
  },
  {
    cik: '0001218179',
    value: '0001218179,MAGEE THOMSON INVESTMENT PARTNERS LLC',
    name: 'MAGEE THOMSON INVESTMENT PARTNERS LLC',
    label: 'MAGEE THOMSON INVESTMENT PARTNERS LLC',
  },
  {
    cik: '0001536105',
    value: '0001536105,MAGELLAN ASSET MANAGEMENT LTD',
    name: 'MAGELLAN ASSET MANAGEMENT LTD',
    label: 'MAGELLAN ASSET MANAGEMENT LTD',
  },
  {
    cik: '0001907544',
    value: '0001907544,MAGMA VENTURE PARTNERS GENERAL PARTNER LTD.',
    name: 'MAGMA VENTURE PARTNERS GENERAL PARTNER LTD.',
    label: 'MAGMA VENTURE PARTNERS GENERAL PARTNER LTD.',
  },
  {
    cik: '0001352851',
    value: '0001352851,MAGNETAR FINANCIAL LLC',
    name: 'MAGNETAR FINANCIAL LLC',
    label: 'MAGNETAR FINANCIAL LLC',
  },
  {
    cik: '0001803662',
    value: '0001803662,MAGNOLIA CAPITAL ADVISORS LLC',
    name: 'MAGNOLIA CAPITAL ADVISORS LLC',
    label: 'MAGNOLIA CAPITAL ADVISORS LLC',
  },
  {
    cik: '0001802361',
    value: '0001802361,MAGNOLIA CAPITAL MANAGEMENT LTD',
    name: 'MAGNOLIA CAPITAL MANAGEMENT LTD',
    label: 'MAGNOLIA CAPITAL MANAGEMENT LTD',
  },
  {
    cik: '0001618376',
    value: '0001618376,MAGNOLIA GROUP, LLC',
    name: 'MAGNOLIA GROUP, LLC',
    label: 'MAGNOLIA GROUP, LLC',
  },
  {
    cik: '0001908732',
    value: '0001908732,MAGNOLIA WEALTH MANAGEMENT, LLC',
    name: 'MAGNOLIA WEALTH MANAGEMENT, LLC',
    label: 'MAGNOLIA WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001634785',
    value: '0001634785,MAGNUM NGL HOLDCO LLC',
    name: 'MAGNUM NGL HOLDCO LLC',
    label: 'MAGNUM NGL HOLDCO LLC',
  },
  {
    cik: '0001765216',
    value: '0001765216,MAGNUS FINANCIAL GROUP LLC',
    name: 'MAGNUS FINANCIAL GROUP LLC',
    label: 'MAGNUS FINANCIAL GROUP LLC',
  },
  {
    cik: '0001040197',
    value: '0001040197,MAI CAPITAL MANAGEMENT',
    name: 'MAI CAPITAL MANAGEMENT',
    label: 'MAI CAPITAL MANAGEMENT',
  },
  {
    cik: '0001698607',
    value: '0001698607,MAIN MANAGEMENT ETF ADVISORS, LLC',
    name: 'MAIN MANAGEMENT ETF ADVISORS, LLC',
    label: 'MAIN MANAGEMENT ETF ADVISORS, LLC',
  },
  {
    cik: '0001862787',
    value: '0001862787,MAIN MANAGEMENT FUND ADVISORS LLC',
    name: 'MAIN MANAGEMENT FUND ADVISORS LLC',
    label: 'MAIN MANAGEMENT FUND ADVISORS LLC',
  },
  {
    cik: '0001583092',
    value: '0001583092,MAIN MANAGEMENT LLC',
    name: 'MAIN MANAGEMENT LLC',
    label: 'MAIN MANAGEMENT LLC',
  },
  {
    cik: '0001127612',
    value: '0001127612,MAINSTAY CAPITAL MANAGEMENT LLC /ADV',
    name: 'MAINSTAY CAPITAL MANAGEMENT LLC /ADV',
    label: 'MAINSTAY CAPITAL MANAGEMENT LLC /ADV',
  },
  {
    cik: '0001763350',
    value: '0001763350,MAIN STREET FINANCIAL SOLUTIONS, LLC',
    name: 'MAIN STREET FINANCIAL SOLUTIONS, LLC',
    label: 'MAIN STREET FINANCIAL SOLUTIONS, LLC',
  },
  {
    cik: '0001729854',
    value: '0001729854,MAINSTREET INVESTMENT ADVISORS LLC',
    name: 'MAINSTREET INVESTMENT ADVISORS LLC',
    label: 'MAINSTREET INVESTMENT ADVISORS LLC',
  },
  {
    cik: '0001513211',
    value: '0001513211,MAIN STREET RESEARCH LLC',
    name: 'MAIN STREET RESEARCH LLC',
    label: 'MAIN STREET RESEARCH LLC',
  },
  {
    cik: '0001536336',
    value: '0001536336,MA INVESTMENT PARTNERSHIP, LP',
    name: 'MA INVESTMENT PARTNERSHIP, LP',
    label: 'MA INVESTMENT PARTNERSHIP, LP',
  },
  {
    cik: '0001070134',
    value: '0001070134,MAIRS & POWER INC',
    name: 'MAIRS & POWER INC',
    label: 'MAIRS & POWER INC',
  },
  {
    cik: '0001599883',
    value: '0001599883,MAJEDIE ASSET MANAGEMENT LTD',
    name: 'MAJEDIE ASSET MANAGEMENT LTD',
    label: 'MAJEDIE ASSET MANAGEMENT LTD',
  },
  {
    cik: '0001730525',
    value: '0001730525,MAJ INVEST HOLDING A/S',
    name: 'MAJ INVEST HOLDING A/S',
    label: 'MAJ INVEST HOLDING A/S',
  },
  {
    cik: '0001540866',
    value: '0001540866,MAKAIRA PARTNERS LLC',
    name: 'MAKAIRA PARTNERS LLC',
    label: 'MAKAIRA PARTNERS LLC',
  },
  {
    cik: '0001385702',
    value: '0001385702,MAK CAPITAL ONE LLC',
    name: 'MAK CAPITAL ONE LLC',
    label: 'MAK CAPITAL ONE LLC',
  },
  {
    cik: '0001359262',
    value: '0001359262,MAKENA CAPITAL MANAGEMENT LLC',
    name: 'MAKENA CAPITAL MANAGEMENT LLC',
    label: 'MAKENA CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001603001',
    value: '0001603001,MALAGA COVE CAPITAL, LLC',
    name: 'MALAGA COVE CAPITAL, LLC',
    label: 'MALAGA COVE CAPITAL, LLC',
  },
  {
    cik: '0001040762',
    value: '0001040762,MALTESE CAPITAL MANAGEMENT LLC',
    name: 'MALTESE CAPITAL MANAGEMENT LLC',
    label: 'MALTESE CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001764260',
    value: '0001764260,MALTIN WEALTH MANAGEMENT, INC.',
    name: 'MALTIN WEALTH MANAGEMENT, INC.',
    label: 'MALTIN WEALTH MANAGEMENT, INC.',
  },
  {
    cik: '0001731159',
    value: '0001731159,MANA ADVISORS LLC',
    name: 'MANA ADVISORS LLC',
    label: 'MANA ADVISORS LLC',
  },
  {
    cik: '0001418660',
    value: '0001418660,MANAGED ACCOUNT ADVISORS LLC',
    name: 'MANAGED ACCOUNT ADVISORS LLC',
    label: 'MANAGED ACCOUNT ADVISORS LLC',
  },
  {
    cik: '0001696656',
    value: '0001696656,MANAGED ACCOUNT SERVICES INC',
    name: 'MANAGED ACCOUNT SERVICES INC',
    label: 'MANAGED ACCOUNT SERVICES INC',
  },
  {
    cik: '0001600999',
    value: '0001600999,MANAGED ASSET PORTFOLIOS, LLC',
    name: 'MANAGED ASSET PORTFOLIOS, LLC',
    label: 'MANAGED ASSET PORTFOLIOS, LLC',
  },
  {
    cik: '0001467902',
    value: '0001467902,MANATUCK HILL PARTNERS, LLC',
    name: 'MANATUCK HILL PARTNERS, LLC',
    label: 'MANATUCK HILL PARTNERS, LLC',
  },
  {
    cik: '0001393389',
    value: '0001393389,MANCHESTER CAPITAL MANAGEMENT LLC',
    name: 'MANCHESTER CAPITAL MANAGEMENT LLC',
    label: 'MANCHESTER CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0000866590',
    value: '0000866590,MANCHESTER FINANCIAL INC',
    name: 'MANCHESTER FINANCIAL INC',
    label: 'MANCHESTER FINANCIAL INC',
  },
  {
    cik: '0001664324',
    value: '0001664324,MANDATUM LIFE INSURANCE CO LTD',
    name: 'MANDATUM LIFE INSURANCE CO LTD',
    label: 'MANDATUM LIFE INSURANCE CO LTD',
  },
  {
    cik: '0001856155',
    value: '0001856155,MANE GLOBAL CAPITAL MANAGEMENT LP',
    name: 'MANE GLOBAL CAPITAL MANAGEMENT LP',
    label: 'MANE GLOBAL CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001730480',
    value: '0001730480,MANGHAM ASSOCIATES,LLC',
    name: 'MANGHAM ASSOCIATES,LLC',
    label: 'MANGHAM ASSOCIATES,LLC',
  },
  {
    cik: '0001637460',
    value: '0001637460,MAN GROUP PLC',
    name: 'MAN GROUP PLC',
    label: 'MAN GROUP PLC',
  },
  {
    cik: '0001535392',
    value: '0001535392,MANGROVE PARTNERS',
    name: 'MANGROVE PARTNERS',
    label: 'MANGROVE PARTNERS',
  },
  {
    cik: '0001904770',
    value: '0001904770,MANHATTAN WEST ASSET MANAGEMENT, LLC',
    name: 'MANHATTAN WEST ASSET MANAGEMENT, LLC',
    label: 'MANHATTAN WEST ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001392910',
    value: '0001392910,MANIFOLD FUND ADVISORS, LLC',
    name: 'MANIFOLD FUND ADVISORS, LLC',
    label: 'MANIFOLD FUND ADVISORS, LLC',
  },
  {
    cik: '0001454893',
    value: '0001454893,MANIKAY PARTNERS, LLC',
    name: 'MANIKAY PARTNERS, LLC',
    label: 'MANIKAY PARTNERS, LLC',
  },
  {
    cik: '0001542352',
    value: '0001542352,MAN INVESTMENTS (CH) AG',
    name: 'MAN INVESTMENTS (CH) AG',
    label: 'MAN INVESTMENTS (CH) AG',
  },
  {
    cik: '0001390262',
    value: '0001390262,MAN INVESTMENTS LTD',
    name: 'MAN INVESTMENTS LTD',
    label: 'MAN INVESTMENTS LTD',
  },
  {
    cik: '0001657428',
    value: '0001657428,MANITOU INVESTMENT MANAGEMENT LTD.',
    name: 'MANITOU INVESTMENT MANAGEMENT LTD.',
    label: 'MANITOU INVESTMENT MANAGEMENT LTD.',
  },
  {
    cik: '0001819870',
    value: '0001819870,MANIYAR CAPITAL ADVISORS UK LTD.',
    name: 'MANIYAR CAPITAL ADVISORS UK LTD.',
    label: 'MANIYAR CAPITAL ADVISORS UK LTD.',
  },
  {
    cik: '0000062039',
    value: '0000062039,MANNING & NAPIER ADVISORS LLC',
    name: 'MANNING & NAPIER ADVISORS LLC',
    label: 'MANNING & NAPIER ADVISORS LLC',
  },
  {
    cik: '0001725248',
    value: '0001725248,MANNING & NAPIER GROUP, LLC',
    name: 'MANNING & NAPIER GROUP, LLC',
    label: 'MANNING & NAPIER GROUP, LLC',
  },
  {
    cik: '0001567641',
    value: '0001567641,MANOR ROAD CAPITAL PARTNERS, LLC',
    name: 'MANOR ROAD CAPITAL PARTNERS, LLC',
    label: 'MANOR ROAD CAPITAL PARTNERS, LLC',
  },
  {
    cik: '0001888482',
    value: '0001888482,MANTEIO CAPITAL LLC',
    name: 'MANTEIO CAPITAL LLC',
    label: 'MANTEIO CAPITAL LLC',
  },
  {
    cik: '0001695459',
    value: '0001695459,MANTLE RIDGE LP',
    name: 'MANTLE RIDGE LP',
    label: 'MANTLE RIDGE LP',
  },
  {
    cik: '0000928047',
    value: '0000928047,MANUFACTURERS LIFE INSURANCE COMPANY, THE',
    name: 'MANUFACTURERS LIFE INSURANCE COMPANY, THE',
    label: 'MANUFACTURERS LIFE INSURANCE COMPANY, THE',
  },
  {
    cik: '0001681596',
    value: '0001681596,MAPLE-BROWN ABBOTT LTD',
    name: 'MAPLE-BROWN ABBOTT LTD',
    label: 'MAPLE-BROWN ABBOTT LTD',
  },
  {
    cik: '0001313893',
    value: '0001313893,MAPLE CAPITAL MANAGEMENT, INC.',
    name: 'MAPLE CAPITAL MANAGEMENT, INC.',
    label: 'MAPLE CAPITAL MANAGEMENT, INC.',
  },
  {
    cik: '0001512173',
    value: '0001512173,MAPLELANE CAPITAL, LLC',
    name: 'MAPLELANE CAPITAL, LLC',
    label: 'MAPLELANE CAPITAL, LLC',
  },
  {
    cik: '0001658363',
    value: '0001658363,MAPLE ROCK CAPITAL PARTNERS INC.',
    name: 'MAPLE ROCK CAPITAL PARTNERS INC.',
    label: 'MAPLE ROCK CAPITAL PARTNERS INC.',
  },
  {
    cik: '0001277275',
    value: '0001277275,MAPLE SECURITIES USA INC',
    name: 'MAPLE SECURITIES USA INC',
    label: 'MAPLE SECURITIES USA INC',
  },
  {
    cik: '0001767474',
    value: '0001767474,MA PRIVATE WEALTH',
    name: 'MA PRIVATE WEALTH',
    label: 'MA PRIVATE WEALTH',
  },
  {
    cik: '0001040592',
    value: '0001040592,MARATHON ASSET MANAGEMENT LLP',
    name: 'MARATHON ASSET MANAGEMENT LLP',
    label: 'MARATHON ASSET MANAGEMENT LLP',
  },
  {
    cik: '0001279913',
    value: '0001279913,MARATHON ASSET MANAGEMENT LP',
    name: 'MARATHON ASSET MANAGEMENT LP',
    label: 'MARATHON ASSET MANAGEMENT LP',
  },
  {
    cik: '0001861026',
    value: '0001861026,MARATHON ASSET MANAGEMENT LTD',
    name: 'MARATHON ASSET MANAGEMENT LTD',
    label: 'MARATHON ASSET MANAGEMENT LTD',
  },
  {
    cik: '0001861026',
    value: '0001861026,MARATHON ASSET MGMT LTD',
    name: 'MARATHON ASSET MGMT LTD',
    label: 'MARATHON ASSET MGMT LTD',
  },
  {
    cik: '0001213206',
    value: '0001213206,MARATHON CAPITAL MANAGEMENT',
    name: 'MARATHON CAPITAL MANAGEMENT',
    label: 'MARATHON CAPITAL MANAGEMENT',
  },
  {
    cik: '0001353311',
    value: '0001353311,MARATHON PARTNERS EQUITY MANAGEMENT, LLC',
    name: 'MARATHON PARTNERS EQUITY MANAGEMENT, LLC',
    label: 'MARATHON PARTNERS EQUITY MANAGEMENT, LLC',
  },
  {
    cik: '0001572748',
    value: '0001572748,MARATHON TRADING INVESTMENT MANAGEMENT LLC',
    name: 'MARATHON TRADING INVESTMENT MANAGEMENT LLC',
    label: 'MARATHON TRADING INVESTMENT MANAGEMENT LLC',
  },
  {
    cik: '0001427112',
    value: '0001427112,MARBLE ARCH INVESTMENTS, LP',
    name: 'MARBLE ARCH INVESTMENTS, LP',
    label: 'MARBLE ARCH INVESTMENTS, LP',
  },
  {
    cik: '0001387130',
    value: '0001387130,MARBLE HARBOR INVESTMENT COUNSEL, LLC',
    name: 'MARBLE HARBOR INVESTMENT COUNSEL, LLC',
    label: 'MARBLE HARBOR INVESTMENT COUNSEL, LLC',
  },
  {
    cik: '0001734512',
    value: '0001734512,MARBLE HOLDINGS, LLC',
    name: 'MARBLE HOLDINGS, LLC',
    label: 'MARBLE HOLDINGS, LLC',
  },
  {
    cik: '0001730142',
    value: '0001730142,MARBLE RIDGE CAPITAL LP',
    name: 'MARBLE RIDGE CAPITAL LP',
    label: 'MARBLE RIDGE CAPITAL LP',
  },
  {
    cik: '0001541996',
    value: '0001541996,MARCATO CAPITAL MANAGEMENT LP',
    name: 'MARCATO CAPITAL MANAGEMENT LP',
    label: 'MARCATO CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001574661',
    value: '0001574661,MARCH ALTUS CAPITAL MANAGEMENT LP',
    name: 'MARCH ALTUS CAPITAL MANAGEMENT LP',
    label: 'MARCH ALTUS CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001782974',
    value: '0001782974,MARCHO PARTNERS LLP',
    name: 'MARCHO PARTNERS LLP',
    label: 'MARCHO PARTNERS LLP',
  },
  {
    cik: '0001080493',
    value: '0001080493,MARCO INVESTMENT MANAGEMENT LLC',
    name: 'MARCO INVESTMENT MANAGEMENT LLC',
    label: 'MARCO INVESTMENT MANAGEMENT LLC',
  },
  {
    cik: '0001538112',
    value: '0001538112,MARCUARD FAMILY OFFICE LTD',
    name: 'MARCUARD FAMILY OFFICE LTD',
    label: 'MARCUARD FAMILY OFFICE LTD',
  },
  {
    cik: '0001784450',
    value: '0001784450,MARCUM WEALTH, LLC',
    name: 'MARCUM WEALTH, LLC',
    label: 'MARCUM WEALTH, LLC',
  },
  {
    cik: '0001662918',
    value: '0001662918,MARCUS CAPITAL, LLC',
    name: 'MARCUS CAPITAL, LLC',
    label: 'MARCUS CAPITAL, LLC',
  },
  {
    cik: '0001661571',
    value: '0001661571,MARGARET A. CARGILL FOUNDATION',
    name: 'MARGARET A. CARGILL FOUNDATION',
    label: 'MARGARET A. CARGILL FOUNDATION',
  },
  {
    cik: '0001607147',
    value: '0001607147,MARIANAS FUND MANAGEMENT LLC',
    name: 'MARIANAS FUND MANAGEMENT LLC',
    label: 'MARIANAS FUND MANAGEMENT LLC',
  },
  {
    cik: '0001114702',
    value: '0001114702,MARIETTA INVESTMENT PARTNERS LLC',
    name: 'MARIETTA INVESTMENT PARTNERS LLC',
    label: 'MARIETTA INVESTMENT PARTNERS LLC',
  },
  {
    cik: '0001697847',
    value: '0001697847,MARIETTA WEALTH MANAGEMENT, LLC',
    name: 'MARIETTA WEALTH MANAGEMENT, LLC',
    label: 'MARIETTA WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001096978',
    value: '0001096978,MARINER INVESTMENT GROUP LLC',
    name: 'MARINER INVESTMENT GROUP LLC',
    label: 'MARINER INVESTMENT GROUP LLC',
  },
  {
    cik: '0001373442',
    value: '0001373442,MARINER, LLC',
    name: 'MARINER, LLC',
    label: 'MARINER, LLC',
  },
  {
    cik: '0001567390',
    value: '0001567390,MARINER WEALTH ADVISORS-CINCINNATI, LLC',
    name: 'MARINER WEALTH ADVISORS-CINCINNATI, LLC',
    label: 'MARINER WEALTH ADVISORS-CINCINNATI, LLC',
  },
  {
    cik: '0001373442',
    value: '0001373442,MARINER WEALTH ADVISORS, LLC',
    name: 'MARINER WEALTH ADVISORS, LLC',
    label: 'MARINER WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001642112',
    value: '0001642112,MARINER WEALTH ADVISORS-NYC, LLC',
    name: 'MARINER WEALTH ADVISORS-NYC, LLC',
    label: 'MARINER WEALTH ADVISORS-NYC, LLC',
  },
  {
    cik: '0001845680',
    value: '0001845680,MARINO MANAGEMENT, LLC',
    name: 'MARINO MANAGEMENT, LLC',
    label: 'MARINO MANAGEMENT, LLC',
  },
  {
    cik: '0001099762',
    value: '0001099762,MARINO, STRAM & ASSOCIATES LLC',
    name: 'MARINO, STRAM & ASSOCIATES LLC',
    label: 'MARINO, STRAM & ASSOCIATES LLC',
  },
  {
    cik: '0001911097',
    value: '0001911097,MARION WEALTH MANAGEMENT',
    name: 'MARION WEALTH MANAGEMENT',
    label: 'MARION WEALTH MANAGEMENT',
  },
  {
    cik: '0001890698',
    value: '0001890698,MARIPAU WEALTH MANAGEMENT LLC',
    name: 'MARIPAU WEALTH MANAGEMENT LLC',
    label: 'MARIPAU WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0000860176',
    value: '0000860176,MARK ASSET MANAGEMENT CORP',
    name: 'MARK ASSET MANAGEMENT CORP',
    label: 'MARK ASSET MANAGEMENT CORP',
  },
  {
    cik: '0000860176',
    value: '0000860176,MARK ASSET MANAGEMENT LP',
    name: 'MARK ASSET MANAGEMENT LP',
    label: 'MARK ASSET MANAGEMENT LP',
  },
  {
    cik: '0001096343',
    value: '0001096343,MARKEL CORP',
    name: 'MARKEL CORP',
    label: 'MARKEL CORP',
  },
  {
    cik: '0001561089',
    value: '0001561089,MARKETFIELD ASSET MANAGEMENT LLC',
    name: 'MARKETFIELD ASSET MANAGEMENT LLC',
    label: 'MARKETFIELD ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001767601',
    value: '0001767601,MARKET STREET WEALTH MANAGEMENT ADVISORS LLC',
    name: 'MARKET STREET WEALTH MANAGEMENT ADVISORS LLC',
    label: 'MARKET STREET WEALTH MANAGEMENT ADVISORS LLC',
  },
  {
    cik: '0001802473',
    value: '0001802473,MARKS GROUP WEALTH MANAGEMENT, INC',
    name: 'MARKS GROUP WEALTH MANAGEMENT, INC',
    label: 'MARKS GROUP WEALTH MANAGEMENT, INC',
  },
  {
    cik: '0001607355',
    value: '0001607355,MARK SHEPTOFF FINANCIAL PLANNING, LLC',
    name: 'MARK SHEPTOFF FINANCIAL PLANNING, LLC',
    label: 'MARK SHEPTOFF FINANCIAL PLANNING, LLC',
  },
  {
    cik: '0001089991',
    value: '0001089991,MARKSTON INTERNATIONAL LLC',
    name: 'MARKSTON INTERNATIONAL LLC',
    label: 'MARKSTON INTERNATIONAL LLC',
  },
  {
    cik: '0001838660',
    value: '0001838660,MARKS WEALTH, LLC',
    name: 'MARKS WEALTH, LLC',
    label: 'MARKS WEALTH, LLC',
  },
  {
    cik: '0001608618',
    value: '0001608618,MARLIN SAMS CAPITAL MANAGEMENT LLC',
    name: 'MARLIN SAMS CAPITAL MANAGEMENT LLC',
    label: 'MARLIN SAMS CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001624049',
    value: '0001624049,MARLOWE PARTNERS LP',
    name: 'MARLOWE PARTNERS LP',
    label: 'MARLOWE PARTNERS LP',
  },
  {
    cik: '0001802091',
    value: '0001802091,MAROTTA ASSET MANAGEMENT',
    name: 'MAROTTA ASSET MANAGEMENT',
    label: 'MAROTTA ASSET MANAGEMENT',
  },
  {
    cik: '0001524341',
    value: '0001524341,MARQUARD & BAHLS AG',
    name: 'MARQUARD & BAHLS AG',
    label: 'MARQUARD & BAHLS AG',
  },
  {
    cik: '0001056519',
    value: '0001056519,MARQUE MILLENNIUM CAPITAL MANAGEMENT LCC',
    name: 'MARQUE MILLENNIUM CAPITAL MANAGEMENT LCC',
    label: 'MARQUE MILLENNIUM CAPITAL MANAGEMENT LCC',
  },
  {
    cik: '0001395067',
    value: '0001395067,MARQUETTE ASSET MANAGEMENT INC.',
    name: 'MARQUETTE ASSET MANAGEMENT INC.',
    label: 'MARQUETTE ASSET MANAGEMENT INC.',
  },
  {
    cik: '0001395067',
    value: '0001395067,MARQUETTE ASSET MANAGEMENT, LLC',
    name: 'MARQUETTE ASSET MANAGEMENT, LLC',
    label: 'MARQUETTE ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0000701189',
    value: '0000701189,MARSHALL FINANCIAL GROUP INC /ADV',
    name: 'MARSHALL FINANCIAL GROUP INC /ADV',
    label: 'MARSHALL FINANCIAL GROUP INC /ADV',
  },
  {
    cik: '0001841496',
    value: '0001841496,MARSHALL FINANCIAL GROUP LLC',
    name: 'MARSHALL FINANCIAL GROUP LLC',
    label: 'MARSHALL FINANCIAL GROUP LLC',
  },
  {
    cik: '0000820123',
    value: '0000820123,MARSHALL & SULLIVAN INC /WA/',
    name: 'MARSHALL & SULLIVAN INC /WA/',
    label: 'MARSHALL & SULLIVAN INC /WA/',
  },
  {
    cik: '0001519964',
    value: '0001519964,MARSHALL WACE ASIA LTD',
    name: 'MARSHALL WACE ASIA LTD',
    label: 'MARSHALL WACE ASIA LTD',
  },
  {
    cik: '0001318757',
    value: '0001318757,MARSHALL WACE LLP',
    name: 'MARSHALL WACE LLP',
    label: 'MARSHALL WACE LLP',
  },
  {
    cik: '0001318757',
    value: '0001318757,MARSHALL WACE, LLP',
    name: 'MARSHALL WACE, LLP',
    label: 'MARSHALL WACE, LLP',
  },
  {
    cik: '0001325091',
    value: '0001325091,MARSHALL WACE NORTH AMERICA L.P.',
    name: 'MARSHALL WACE NORTH AMERICA L.P.',
    label: 'MARSHALL WACE NORTH AMERICA L.P.',
  },
  {
    cik: '0000914976',
    value: '0000914976,MARSHFIELD ASSOCIATES',
    name: 'MARSHFIELD ASSOCIATES',
    label: 'MARSHFIELD ASSOCIATES',
  },
  {
    cik: '0001281011',
    value: '0001281011,MARSHWINDS ADVISORY CO',
    name: 'MARSHWINDS ADVISORY CO',
    label: 'MARSHWINDS ADVISORY CO',
  },
  {
    cik: '0001055966',
    value: '0001055966,MARSICO CAPITAL MANAGEMENT LLC',
    name: 'MARSICO CAPITAL MANAGEMENT LLC',
    label: 'MARSICO CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001904126',
    value: '0001904126,MARTIN CAPITAL ADVISORS, LLP',
    name: 'MARTIN CAPITAL ADVISORS, LLP',
    label: 'MARTIN CAPITAL ADVISORS, LLP',
  },
  {
    cik: '0001132943',
    value: '0001132943,MARTIN CAPITAL MANAGEMENT LLC',
    name: 'MARTIN CAPITAL MANAGEMENT LLC',
    label: 'MARTIN CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001731216',
    value: '0001731216,MARTIN CAPITAL PARTNERS, LLC',
    name: 'MARTIN CAPITAL PARTNERS, LLC',
    label: 'MARTIN CAPITAL PARTNERS, LLC',
  },
  {
    cik: '0001008868',
    value: '0001008868,MARTIN & CO INC /TN/',
    name: 'MARTIN & CO INC /TN/',
    label: 'MARTIN & CO INC /TN/',
  },
  {
    cik: '0001094499',
    value: '0001094499,MARTIN CURRIE LTD',
    name: 'MARTIN CURRIE LTD',
    label: 'MARTIN CURRIE LTD',
  },
  {
    cik: '0000936944',
    value: '0000936944,MARTINGALE ASSET MANAGEMENT L P',
    name: 'MARTINGALE ASSET MANAGEMENT L P',
    label: 'MARTINGALE ASSET MANAGEMENT L P',
  },
  {
    cik: '0001511888',
    value: '0001511888,MARTIN INVESTMENT MANAGEMENT, LLC',
    name: 'MARTIN INVESTMENT MANAGEMENT, LLC',
    label: 'MARTIN INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0000860645',
    value: '0000860645,MARVIN & PALMER ASSOCIATES INC',
    name: 'MARVIN & PALMER ASSOCIATES INC',
    label: 'MARVIN & PALMER ASSOCIATES INC',
  },
  {
    cik: '0001419999',
    value: '0001419999,MAR VISTA INVESTMENT PARTNERS LLC',
    name: 'MAR VISTA INVESTMENT PARTNERS LLC',
    label: 'MAR VISTA INVESTMENT PARTNERS LLC',
  },
  {
    cik: '0001044321',
    value: '0001044321,MARXE AUSTIN W & GREENHOUSE DAVID M',
    name: 'MARXE AUSTIN W & GREENHOUSE DAVID M',
    label: 'MARXE AUSTIN W & GREENHOUSE DAVID M',
  },
  {
    cik: '0001891201',
    value: '0001891201,MARYLAND CAPITAL ADVISORS INC.',
    name: 'MARYLAND CAPITAL ADVISORS INC.',
    label: 'MARYLAND CAPITAL ADVISORS INC.',
  },
  {
    cik: '0001356643',
    value: '0001356643,MARYLAND CAPITAL MANAGEMENT',
    name: 'MARYLAND CAPITAL MANAGEMENT',
    label: 'MARYLAND CAPITAL MANAGEMENT',
  },
  {
    cik: '0001795552',
    value: '0001795552,MARYLAND STATE RETIREMENT & PENSION SYSTEM',
    name: 'MARYLAND STATE RETIREMENT & PENSION SYSTEM',
    label: 'MARYLAND STATE RETIREMENT & PENSION SYSTEM',
  },
  {
    cik: '0001906683',
    value: '0001906683,MAS ADVISORS LLC',
    name: 'MAS ADVISORS LLC',
    label: 'MAS ADVISORS LLC',
  },
  {
    cik: '0001695664',
    value: '0001695664,MASCOMA WEALTH MANAGEMENT LLC',
    name: 'MASCOMA WEALTH MANAGEMENT LLC',
    label: 'MASCOMA WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0001698093',
    value: '0001698093,MASO CAPITAL PARTNERS LTD',
    name: 'MASO CAPITAL PARTNERS LTD',
    label: 'MASO CAPITAL PARTNERS LTD',
  },
  {
    cik: '0001917686',
    value: '0001917686,MASON & ASSOCIATES INC',
    name: 'MASON & ASSOCIATES INC',
    label: 'MASON & ASSOCIATES INC',
  },
  {
    cik: '0001218735',
    value: '0001218735,MASON CAPITAL MANAGEMENT LLC',
    name: 'MASON CAPITAL MANAGEMENT LLC',
    label: 'MASON CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0000938552',
    value: '0000938552,MASON HILL ADVISORS LLC',
    name: 'MASON HILL ADVISORS LLC',
    label: 'MASON HILL ADVISORS LLC',
  },
  {
    cik: '0001167711',
    value: '0001167711,MASON STREET ADVISORS, LLC',
    name: 'MASON STREET ADVISORS, LLC',
    label: 'MASON STREET ADVISORS, LLC',
  },
  {
    cik: '0000912938',
    value: '0000912938,MASSACHUSETTS FINANCIAL SERVICES CO /MA/',
    name: 'MASSACHUSETTS FINANCIAL SERVICES CO /MA/',
    label: 'MASSACHUSETTS FINANCIAL SERVICES CO /MA/',
  },
  {
    cik: '0000351051',
    value: '0000351051,MASSACHUSETTS INSTITUTE OF TECHNOLOGY',
    name: 'MASSACHUSETTS INSTITUTE OF TECHNOLOGY',
    label: 'MASSACHUSETTS INSTITUTE OF TECHNOLOGY',
  },
  {
    cik: '0000225602',
    value: '0000225602,MASSACHUSETTS MUTUAL LIFE INSURANCE CO',
    name: 'MASSACHUSETTS MUTUAL LIFE INSURANCE CO',
    label: 'MASSACHUSETTS MUTUAL LIFE INSURANCE CO',
  },
  {
    cik: '0001910663',
    value: '0001910663,MASS AVE GLOBAL INC.',
    name: 'MASS AVE GLOBAL INC.',
    label: 'MASS AVE GLOBAL INC.',
  },
  {
    cik: '0001692038',
    value: '0001692038,MASSEY QUICK SIMON & CO., LLC',
    name: 'MASSEY QUICK SIMON & CO., LLC',
    label: 'MASSEY QUICK SIMON & CO., LLC',
  },
  {
    cik: '0001678378',
    value: '0001678378,MASS GENERAL BRIGHAM, INC',
    name: 'MASS GENERAL BRIGHAM, INC',
    label: 'MASS GENERAL BRIGHAM, INC',
  },
  {
    cik: '0001103653',
    value: '0001103653,MASSMUTUAL TRUST CO FSB/ADV',
    name: 'MASSMUTUAL TRUST CO FSB/ADV',
    label: 'MASSMUTUAL TRUST CO FSB/ADV',
  },
  {
    cik: '0001903153',
    value: '0001903153,MASSO TORRENCE WEALTH MANAGEMENT INC.',
    name: 'MASSO TORRENCE WEALTH MANAGEMENT INC.',
    label: 'MASSO TORRENCE WEALTH MANAGEMENT INC.',
  },
  {
    cik: '0001429074',
    value: '0001429074,MAST CAPITAL MANAGEMENT LLC',
    name: 'MAST CAPITAL MANAGEMENT LLC',
    label: 'MAST CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001104186',
    value: '0001104186,MASTERS CAPITAL MANAGEMENT LLC',
    name: 'MASTERS CAPITAL MANAGEMENT LLC',
    label: 'MASTERS CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001817145',
    value: '0001817145,MASTERTON CAPITAL MANAGEMENT, LP',
    name: 'MASTERTON CAPITAL MANAGEMENT, LP',
    label: 'MASTERTON CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0001014738',
    value: '0001014738,MASTRAPASQUA ASSET MANAGEMENT INC',
    name: 'MASTRAPASQUA ASSET MANAGEMENT INC',
    label: 'MASTRAPASQUA ASSET MANAGEMENT INC',
  },
  {
    cik: '0001579668',
    value: '0001579668,MATARIN CAPITAL MANAGEMENT, LLC',
    name: 'MATARIN CAPITAL MANAGEMENT, LLC',
    label: 'MATARIN CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001527641',
    value: '0001527641,MATHER GROUP, INC.',
    name: 'MATHER GROUP, INC.',
    label: 'MATHER GROUP, INC.',
  },
  {
    cik: '0001527641',
    value: '0001527641,MATHER GROUP, LLC.',
    name: 'MATHER GROUP, LLC.',
    label: 'MATHER GROUP, LLC.',
  },
  {
    cik: '0001389223',
    value: '0001389223,MATHES COMPANY, INC.',
    name: 'MATHES COMPANY, INC.',
    label: 'MATHES COMPANY, INC.',
  },
  {
    cik: '0001668256',
    value: '0001668256,MATISSE CAPITAL',
    name: 'MATISSE CAPITAL',
    label: 'MATISSE CAPITAL',
  },
  {
    cik: '0001251962',
    value: '0001251962,MATLINPATTERSON GLOBAL ADVISERS LLC',
    name: 'MATLINPATTERSON GLOBAL ADVISERS LLC',
    label: 'MATLINPATTERSON GLOBAL ADVISERS LLC',
  },
  {
    cik: '0001016287',
    value: '0001016287,MATRIX ASSET ADVISORS INC/NY',
    name: 'MATRIX ASSET ADVISORS INC/NY',
    label: 'MATRIX ASSET ADVISORS INC/NY',
  },
  {
    cik: '0001410830',
    value: '0001410830,MATRIX CAPITAL MANAGEMENT COMPANY, LLC',
    name: 'MATRIX CAPITAL MANAGEMENT COMPANY, LLC',
    label: 'MATRIX CAPITAL MANAGEMENT COMPANY, LLC',
  },
  {
    cik: '0001410830',
    value: '0001410830,MATRIX CAPITAL MANAGEMENT COMPANY, LP',
    name: 'MATRIX CAPITAL MANAGEMENT COMPANY, LP',
    label: 'MATRIX CAPITAL MANAGEMENT COMPANY, LP',
  },
  {
    cik: '0001910313',
    value: '0001910313,MATRIX CHINA MANAGEMENT III, L.P.',
    name: 'MATRIX CHINA MANAGEMENT III, L.P.',
    label: 'MATRIX CHINA MANAGEMENT III, L.P.',
  },
  {
    cik: '0001910355',
    value: '0001910355,MATRIX CHINA MANAGEMENT IV, L.P.',
    name: 'MATRIX CHINA MANAGEMENT IV, L.P.',
    label: 'MATRIX CHINA MANAGEMENT IV, L.P.',
  },
  {
    cik: '0001600026',
    value: '0001600026,MATRIX IX MANAGEMENT CO. LLC',
    name: 'MATRIX IX MANAGEMENT CO. LLC',
    label: 'MATRIX IX MANAGEMENT CO. LLC',
  },
  {
    cik: '0001906866',
    value: '0001906866,MATRIX PRIVATE CAPITAL GROUP LLC',
    name: 'MATRIX PRIVATE CAPITAL GROUP LLC',
    label: 'MATRIX PRIVATE CAPITAL GROUP LLC',
  },
  {
    cik: '0001784277',
    value: '0001784277,MATRIX TRUST CO',
    name: 'MATRIX TRUST CO',
    label: 'MATRIX TRUST CO',
  },
  {
    cik: '0001600025',
    value: '0001600025,MATRIX VIII US MANAGEMENT CO. LLC',
    name: 'MATRIX VIII US MANAGEMENT CO. LLC',
    label: 'MATRIX VIII US MANAGEMENT CO. LLC',
  },
  {
    cik: '0001259923',
    value: '0001259923,MATRIX VII MANAGEMENT CO LLC',
    name: 'MATRIX VII MANAGEMENT CO LLC',
    label: 'MATRIX VII MANAGEMENT CO LLC',
  },
  {
    cik: '0001672512',
    value: '0001672512,MATSON MONEY. INC.',
    name: 'MATSON MONEY. INC.',
    label: 'MATSON MONEY. INC.',
  },
  {
    cik: '0001568049',
    value: '0001568049,MATTERHORN CAPITAL MANAGEMENT, LLC',
    name: 'MATTERHORN CAPITAL MANAGEMENT, LLC',
    label: 'MATTERHORN CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001803227',
    value: '0001803227,MATTERN CAPITAL MANAGEMENT, LLC',
    name: 'MATTERN CAPITAL MANAGEMENT, LLC',
    label: 'MATTERN CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001802376',
    value: '0001802376,MATTERN WEALTH MANAGEMENT LLC',
    name: 'MATTERN WEALTH MANAGEMENT LLC',
    label: 'MATTERN WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0001351431',
    value: '0001351431,MATTHEW 25 MANAGEMENT CORP',
    name: 'MATTHEW 25 MANAGEMENT CORP',
    label: 'MATTHEW 25 MANAGEMENT CORP',
  },
  {
    cik: '0000935359',
    value: '0000935359,MATTHEW GOFF INVESTMENT ADVISOR, LLC',
    name: 'MATTHEW GOFF INVESTMENT ADVISOR, LLC',
    label: 'MATTHEW GOFF INVESTMENT ADVISOR, LLC',
  },
  {
    cik: '0001028074',
    value: '0001028074,MATTHEWS INTERNATIONAL CAPITAL MANAGEMENT LLC',
    name: 'MATTHEWS INTERNATIONAL CAPITAL MANAGEMENT LLC',
    label: 'MATTHEWS INTERNATIONAL CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001633046',
    value: '0001633046,MAVEN SECURITIES LTD',
    name: 'MAVEN SECURITIES LTD',
    label: 'MAVEN SECURITIES LTD',
  },
  {
    cik: '0001658005',
    value: '0001658005,MAVEN VENTURES PARTNERS II, LLC',
    name: 'MAVEN VENTURES PARTNERS II, LLC',
    label: 'MAVEN VENTURES PARTNERS II, LLC',
  },
  {
    cik: '0000934639',
    value: '0000934639,MAVERICK CAPITAL LTD',
    name: 'MAVERICK CAPITAL LTD',
    label: 'MAVERICK CAPITAL LTD',
  },
  {
    cik: '0001587645',
    value: '0001587645,MAVERON GENERAL PARTNER III LLC',
    name: 'MAVERON GENERAL PARTNER III LLC',
    label: 'MAVERON GENERAL PARTNER III LLC',
  },
  {
    cik: '0001591048',
    value: '0001591048,MAVERON GENERAL PARTNER IV LLC',
    name: 'MAVERON GENERAL PARTNER IV LLC',
    label: 'MAVERON GENERAL PARTNER IV LLC',
  },
  {
    cik: '0001538449',
    value: '0001538449,MAWER INVESTMENT MANAGEMENT LTD.',
    name: 'MAWER INVESTMENT MANAGEMENT LTD.',
    label: 'MAWER INVESTMENT MANAGEMENT LTD.',
  },
  {
    cik: '0001838222',
    value: '0001838222,MAXI INVESTMENTS CY LTD',
    name: 'MAXI INVESTMENTS CY LTD',
    label: 'MAXI INVESTMENTS CY LTD',
  },
  {
    cik: '0001793913',
    value: '0001793913,MAXIM CAPITAL MANAGEMENT LLC',
    name: 'MAXIM CAPITAL MANAGEMENT LLC',
    label: 'MAXIM CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001858699',
    value: '0001858699,MAYAR CAPITAL LTD.',
    name: 'MAYAR CAPITAL LTD.',
    label: 'MAYAR CAPITAL LTD.',
  },
  {
    cik: '0001801926',
    value: '0001801926,MAYFAIR ADVISORY GROUP, LLC',
    name: 'MAYFAIR ADVISORY GROUP, LLC',
    label: 'MAYFAIR ADVISORY GROUP, LLC',
  },
  {
    cik: '0001708788',
    value: '0001708788,MAYFIELD SELECT MANAGEMENT (UGP), LTD.',
    name: 'MAYFIELD SELECT MANAGEMENT (UGP), LTD.',
    label: 'MAYFIELD SELECT MANAGEMENT (UGP), LTD.',
  },
  {
    cik: '0001573274',
    value:
      '0001573274,MAYFIELD XIII MANAGEMENT (UGP), LTD., A CAYMAN ISLANDS EXEMPTED CO.',
    name: 'MAYFIELD XIII MANAGEMENT (UGP), LTD., A CAYMAN ISLANDS EXEMPTED CO.',
    label:
      'MAYFIELD XIII MANAGEMENT (UGP), LTD., A CAYMAN ISLANDS EXEMPTED CO.',
  },
  {
    cik: '0001404329',
    value: '0001404329,MAYFIELD XII MANAGEMENT, L.L.C.',
    name: 'MAYFIELD XII MANAGEMENT, L.L.C.',
    label: 'MAYFIELD XII MANAGEMENT, L.L.C.',
  },
  {
    cik: '0001648252',
    value: '0001648252,MAYFIELD XIV MANAGEMENT (UGP), LTD.',
    name: 'MAYFIELD XIV MANAGEMENT (UGP), LTD.',
    label: 'MAYFIELD XIV MANAGEMENT (UGP), LTD.',
  },
  {
    cik: '0001852930',
    value: '0001852930,MAYFLOWER FINANCIAL ADVISORS, LLC',
    name: 'MAYFLOWER FINANCIAL ADVISORS, LLC',
    label: 'MAYFLOWER FINANCIAL ADVISORS, LLC',
  },
  {
    cik: '0001318153',
    value: '0001318153,MAYO CLINIC',
    name: 'MAYO CLINIC',
    label: 'MAYO CLINIC',
  },
  {
    cik: '0001216093',
    value: '0001216093,MAYO INVESTMENT ADVISERS LLC',
    name: 'MAYO INVESTMENT ADVISERS LLC',
    label: 'MAYO INVESTMENT ADVISERS LLC',
  },
  {
    cik: '0001922875',
    value: '0001922875,MAYPORT, LLC',
    name: 'MAYPORT, LLC',
    label: 'MAYPORT, LLC',
  },
  {
    cik: '0001731134',
    value: '0001731134,MAYTECH GLOBAL INVESTMENTS, LLC',
    name: 'MAYTECH GLOBAL INVESTMENTS, LLC',
    label: 'MAYTECH GLOBAL INVESTMENTS, LLC',
  },
  {
    cik: '0001708759',
    value: '0001708759,MAYTUS CAPITAL MANAGEMENT, LLC',
    name: 'MAYTUS CAPITAL MANAGEMENT, LLC',
    label: 'MAYTUS CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001078779',
    value: '0001078779,MAZAMA CAPITAL MANAGEMENT INC',
    name: 'MAZAMA CAPITAL MANAGEMENT INC',
    label: 'MAZAMA CAPITAL MANAGEMENT INC',
  },
  {
    cik: '0001848704',
    value: '0001848704,MBA ADVISORS LLC',
    name: 'MBA ADVISORS LLC',
    label: 'MBA ADVISORS LLC',
  },
  {
    cik: '0001892378',
    value: '0001892378,MBB PUBLIC MARKETS I LLC',
    name: 'MBB PUBLIC MARKETS I LLC',
    label: 'MBB PUBLIC MARKETS I LLC',
  },
  {
    cik: '0001775850',
    value: '0001775850,MBE WEALTH MANAGEMENT, LLC',
    name: 'MBE WEALTH MANAGEMENT, LLC',
    label: 'MBE WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001845785',
    value: '0001845785,MBL WEALTH, LLC',
    name: 'MBL WEALTH, LLC',
    label: 'MBL WEALTH, LLC',
  },
  {
    cik: '0001762068',
    value: '0001762068,MBM WEALTH CONSULTANTS, LLC',
    name: 'MBM WEALTH CONSULTANTS, LLC',
    label: 'MBM WEALTH CONSULTANTS, LLC',
  },
  {
    cik: '0001695490',
    value: '0001695490,MCADAM, LLC',
    name: 'MCADAM, LLC',
    label: 'MCADAM, LLC',
  },
  {
    cik: '0001456685',
    value: '0001456685,MCADAMS WRIGHT RAGEN, INC.',
    name: 'MCADAMS WRIGHT RAGEN, INC.',
    label: 'MCADAMS WRIGHT RAGEN, INC.',
  },
  {
    cik: '0001774087',
    value: '0001774087,MCALISTER, SWEET & ASSOCIATES, INC.',
    name: 'MCALISTER, SWEET & ASSOCIATES, INC.',
    label: 'MCALISTER, SWEET & ASSOCIATES, INC.',
  },
  {
    cik: '0001908893',
    value: '0001908893,MCBROOM & ASSOCIATES, LLC',
    name: 'MCBROOM & ASSOCIATES, LLC',
    label: 'MCBROOM & ASSOCIATES, LLC',
  },
  {
    cik: '0001843169',
    value: '0001843169,MCCARTHY ASSET MANAGEMENT, INC.',
    name: 'MCCARTHY ASSET MANAGEMENT, INC.',
    label: 'MCCARTHY ASSET MANAGEMENT, INC.',
  },
  {
    cik: '0001308506',
    value: '0001308506,MCCARTHY CAPITAL CORP',
    name: 'MCCARTHY CAPITAL CORP',
    label: 'MCCARTHY CAPITAL CORP',
  },
  {
    cik: '0001424196',
    value: '0001424196,MCCLAIN VALUE MANAGEMENT LLC',
    name: 'MCCLAIN VALUE MANAGEMENT LLC',
    label: 'MCCLAIN VALUE MANAGEMENT LLC',
  },
  {
    cik: '0001842974',
    value: '0001842974,MCCLARREN FINANCIAL ADVISORS, INC.',
    name: 'MCCLARREN FINANCIAL ADVISORS, INC.',
    label: 'MCCLARREN FINANCIAL ADVISORS, INC.',
  },
  {
    cik: '0001727573',
    value: '0001727573,MCCOLLUM CHRISTOFERSON GROUP LLC',
    name: 'MCCOLLUM CHRISTOFERSON GROUP LLC',
    label: 'MCCOLLUM CHRISTOFERSON GROUP LLC',
  },
  {
    cik: '0001544224',
    value: '0001544224,MCCUTCHEN GROUP LLC',
    name: 'MCCUTCHEN GROUP LLC',
    label: 'MCCUTCHEN GROUP LLC',
  },
  {
    cik: '0001104366',
    value: '0001104366,MCDANIEL TERRY & CO',
    name: 'MCDANIEL TERRY & CO',
    label: 'MCDANIEL TERRY & CO',
  },
  {
    cik: '0001033225',
    value: '0001033225,MCDONALD CAPITAL INVESTORS INC/CA',
    name: 'MCDONALD CAPITAL INVESTORS INC/CA',
    label: 'MCDONALD CAPITAL INVESTORS INC/CA',
  },
  {
    cik: '0001331074',
    value: '0001331074,MCDONALD PARTNERS LLC',
    name: 'MCDONALD PARTNERS LLC',
    label: 'MCDONALD PARTNERS LLC',
  },
  {
    cik: '0001912128',
    value: '0001912128,MCDONOUGH CAPITAL MANAGEMENT, INC',
    name: 'MCDONOUGH CAPITAL MANAGEMENT, INC',
    label: 'MCDONOUGH CAPITAL MANAGEMENT, INC',
  },
  {
    cik: '0001886813',
    value: '0001886813,MCELHENNY SHEFFIELD CAPITAL MANAGEMENT, LLC',
    name: 'MCELHENNY SHEFFIELD CAPITAL MANAGEMENT, LLC',
    label: 'MCELHENNY SHEFFIELD CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001602905',
    value: '0001602905,MCF ADVISORS LLC',
    name: 'MCF ADVISORS LLC',
    label: 'MCF ADVISORS LLC',
  },
  {
    cik: '0001845031',
    value: '0001845031,MCGINN INVESTMENT MANAGEMENT, INC.',
    name: 'MCGINN INVESTMENT MANAGEMENT, INC.',
    label: 'MCGINN INVESTMENT MANAGEMENT, INC.',
  },
  {
    cik: '0000063995',
    value: '0000063995,MCGLINN CAPITAL MANAGEMENT INC',
    name: 'MCGLINN CAPITAL MANAGEMENT INC',
    label: 'MCGLINN CAPITAL MANAGEMENT INC',
  },
  {
    cik: '0001633227',
    value: '0001633227,MCGOWAN GROUP ASSET MANAGEMENT, INC.',
    name: 'MCGOWAN GROUP ASSET MANAGEMENT, INC.',
    label: 'MCGOWAN GROUP ASSET MANAGEMENT, INC.',
  },
  {
    cik: '0001756959',
    value: '0001756959,MCGUIRE INVESTMENT GROUP, LLC',
    name: 'MCGUIRE INVESTMENT GROUP, LLC',
    label: 'MCGUIRE INVESTMENT GROUP, LLC',
  },
  {
    cik: '0001714506',
    value: '0001714,MCIA INC6',
    name: 'MCIA INC',
    label: 'MCIA INC',
  },
  {
    cik: '0001701714',
    value: '0001701714,MCILRATH & ECK, LLC',
    name: 'MCILRATH & ECK, LLC',
    label: 'MCILRATH & ECK, LLC',
  },
  {
    cik: '0000915287',
    value: '0000915287,MCKINLEY CAPITAL MANAGEMENT LLC /DELAWARE',
    name: 'MCKINLEY CAPITAL MANAGEMENT LLC /DELAWARE',
    label: 'MCKINLEY CAPITAL MANAGEMENT LLC /DELAWARE',
  },
  {
    cik: '0001484265',
    value: '0001484265,MCKINLEY CARTER WEALTH SERVICES, INC.',
    name: 'MCKINLEY CARTER WEALTH SERVICES, INC.',
    label: 'MCKINLEY CARTER WEALTH SERVICES, INC.',
  },
  {
    cik: '0001833140',
    value: '0001833140,MCLEAN ASSET MANAGEMENT CORP',
    name: 'MCLEAN ASSET MANAGEMENT CORP',
    label: 'MCLEAN ASSET MANAGEMENT CORP',
  },
  {
    cik: '0001688184',
    value: '0001688184,MCMAHON FINANCIAL ADVISORS, LLC',
    name: 'MCMAHON FINANCIAL ADVISORS, LLC',
    label: 'MCMAHON FINANCIAL ADVISORS, LLC',
  },
  {
    cik: '0001079930',
    value: '0001079930,MCMILLION CAPITAL MANAGEMENT INC',
    name: 'MCMILLION CAPITAL MANAGEMENT INC',
    label: 'MCMILLION CAPITAL MANAGEMENT INC',
  },
  {
    cik: '0001162695',
    value: '0001162695,MCMORGAN & CO LLC',
    name: 'MCMORGAN & CO LLC',
    label: 'MCMORGAN & CO LLC',
  },
  {
    cik: '0001622431',
    value: '0001622431,MCNAMARA FINANCIAL SERVICES, INC.',
    name: 'MCNAMARA FINANCIAL SERVICES, INC.',
    label: 'MCNAMARA FINANCIAL SERVICES, INC.',
  },
  {
    cik: '0001802691',
    value: '0001802691,MCNAUGHTON WEALTH MANAGEMENT, LLC',
    name: 'MCNAUGHTON WEALTH MANAGEMENT, LLC',
    label: 'MCNAUGHTON WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001663138',
    value: '0001663138,MCQUEEN, BALL & ASSOCIATES INC.',
    name: 'MCQUEEN, BALL & ASSOCIATES INC.',
    label: 'MCQUEEN, BALL & ASSOCIATES INC.',
  },
  {
    cik: '0000320376',
    value: '0000320376,MCRAE CAPITAL MANAGEMENT INC',
    name: 'MCRAE CAPITAL MANAGEMENT INC',
    label: 'MCRAE CAPITAL MANAGEMENT INC',
  },
  {
    cik: '0001485153',
    value: '00014851,MDE GROUP',
    name: 'MDE GROUP',
    label: 'MDE GROUP',
  },
  {
    cik: '0001724264',
    value: '0001724264,MD FINANCIAL MANAGEMENT INC.',
    name: 'MD FINANCIAL MANAGEMENT INC.',
    label: 'MD FINANCIAL MANAGEMENT INC.',
  },
  {
    cik: '0001655265',
    value: '0001655265,MDP GLOBAL INVESTORS II LTD',
    name: 'MDP GLOBAL INVESTORS II LTD',
    label: 'MDP GLOBAL INVESTORS II LTD',
  },
  {
    cik: '0000922940',
    value: '0000922940,M.D. SASS INVESTORS SERVICES, INC.',
    name: 'M.D. SASS INVESTORS SERVICES, INC.',
    label: 'M.D. SASS INVESTORS SERVICES, INC.',
  },
  {
    cik: '0000922940',
    value: '0000922940,MD SASS INVESTORS SERVICES INC',
    name: 'MD SASS INVESTORS SERVICES INC',
    label: 'MD SASS INVESTORS SERVICES INC',
  },
  {
    cik: '0001633834',
    value: '0001633834,MDV MANAGEMENT COMPANY, L.L.C.',
    name: 'MDV MANAGEMENT COMPANY, L.L.C.',
    label: 'MDV MANAGEMENT COMPANY, L.L.C.',
  },
  {
    cik: '0001695508',
    value: '0001695508,MEADOW CREEK INVESTMENT MANAGEMENT LLC',
    name: 'MEADOW CREEK INVESTMENT MANAGEMENT LLC',
    label: 'MEADOW CREEK INVESTMENT MANAGEMENT LLC',
  },
  {
    cik: '0001801107',
    value: '0001801107,MEADOW CREEK WEALTH ADVISORS LLC',
    name: 'MEADOW CREEK WEALTH ADVISORS LLC',
    label: 'MEADOW CREEK WEALTH ADVISORS LLC',
  },
  {
    cik: '0001654198',
    value: '0001654198,MEAG MUNICH ERGO ASSETMANAGEMENT GMBH',
    name: 'MEAG MUNICH ERGO ASSETMANAGEMENT GMBH',
    label: 'MEAG MUNICH ERGO ASSETMANAGEMENT GMBH',
  },
  {
    cik: '0001142433',
    value: '0001142433,MEAG MUNICH ERGO KAPITALANLAGEGESELLSCHAFT MBH',
    name: 'MEAG MUNICH ERGO KAPITALANLAGEGESELLSCHAFT MBH',
    label: 'MEAG MUNICH ERGO KAPITALANLAGEGESELLSCHAFT MBH',
  },
  {
    cik: '0001142433',
    value: '0001142433,MEAG MUNICH ERGO, KAPITALANLAGEGESELLSCHAFT MBH',
    name: 'MEAG MUNICH ERGO, KAPITALANLAGEGESELLSCHAFT MBH',
    label: 'MEAG MUNICH ERGO, KAPITALANLAGEGESELLSCHAFT MBH',
  },
  {
    cik: '0000701516',
    value: '0000701516,MEANS INVESTMENT CO., INC.',
    name: 'MEANS INVESTMENT CO., INC.',
    label: 'MEANS INVESTMENT CO., INC.',
  },
  {
    cik: '0001720235',
    value: '0001720235,MEASURED WEALTH PRIVATE CLIENT GROUP, LLC',
    name: 'MEASURED WEALTH PRIVATE CLIENT GROUP, LLC',
    label: 'MEASURED WEALTH PRIVATE CLIENT GROUP, LLC',
  },
  {
    cik: '0001439743',
    value: '0001439743,MECHANICS BANK TRUST DEPARTMENT',
    name: 'MECHANICS BANK TRUST DEPARTMENT',
    label: 'MECHANICS BANK TRUST DEPARTMENT',
  },
  {
    cik: '0001821336',
    value: '0001821336,MECHANICS FINANCIAL CORP',
    name: 'MECHANICS FINANCIAL CORP',
    label: 'MECHANICS FINANCIAL CORP',
  },
  {
    cik: '0001133939',
    value: '0001133939,MEDIATEL PARTNERS',
    name: 'MEDIATEL PARTNERS',
    label: 'MEDIATEL PARTNERS',
  },
  {
    cik: '0001715579',
    value: '0001715579,MEDICXI VENTURES MANAGEMENT (JERSEY) LTD',
    name: 'MEDICXI VENTURES MANAGEMENT (JERSEY) LTD',
    label: 'MEDICXI VENTURES MANAGEMENT (JERSEY) LTD',
  },
  {
    cik: '0001843543',
    value: '0001843543,MEDINA SINGH PARTNERS, LLC',
    name: 'MEDINA SINGH PARTNERS, LLC',
    label: 'MEDINA SINGH PARTNERS, LLC',
  },
  {
    cik: '0001382333',
    value: '0001382333,MEDITOR GROUP LTD',
    name: 'MEDITOR GROUP LTD',
    label: 'MEDITOR GROUP LTD',
  },
  {
    cik: '0000809443',
    value: '0000809443,MEEDER ASSET MANAGEMENT INC',
    name: 'MEEDER ASSET MANAGEMENT INC',
    label: 'MEEDER ASSET MANAGEMENT INC',
  },
  {
    cik: '0001664660',
    value: '0001664660,MEGHALAYA PARTNERS, L.P.',
    name: 'MEGHALAYA PARTNERS, L.P.',
    label: 'MEGHALAYA PARTNERS, L.P.',
  },
  {
    cik: '0001491685',
    value: '0001491685,MEIJI YASUDA ASSET MANAGEMENT CO LTD.',
    name: 'MEIJI YASUDA ASSET MANAGEMENT CO LTD.',
    label: 'MEIJI YASUDA ASSET MANAGEMENT CO LTD.',
  },
  {
    cik: '0001144492',
    value: '0001144492,MEIJI YASUDA LIFE INSURANCE CO',
    name: 'MEIJI YASUDA LIFE INSURANCE CO',
    label: 'MEIJI YASUDA LIFE INSURANCE CO',
  },
  {
    cik: '0001398346',
    value: '0001398346,MEITAV DASH INVESTMENTS LTD',
    name: 'MEITAV DASH INVESTMENTS LTD',
    label: 'MEITAV DASH INVESTMENTS LTD',
  },
  {
    cik: '0001911615',
    value: '0001911615,MEIXLER INVESTMENT MANAGEMENT, LTD.',
    name: 'MEIXLER INVESTMENT MANAGEMENT, LTD.',
    label: 'MEIXLER INVESTMENT MANAGEMENT, LTD.',
  },
  {
    cik: '0001905765',
    value: '0001905765,MELONE PRIVATE WEALTH, LLC',
    name: 'MELONE PRIVATE WEALTH, LLC',
    label: 'MELONE PRIVATE WEALTH, LLC',
  },
  {
    cik: '0001712901',
    value: '0001712901,MELQART ASSET MANAGEMENT (UK) LTD',
    name: 'MELQART ASSET MANAGEMENT (UK) LTD',
    label: 'MELQART ASSET MANAGEMENT (UK) LTD',
  },
  {
    cik: '0001628110',
    value: '0001628110,MELVIN CAPITAL MANAGEMENT LP',
    name: 'MELVIN CAPITAL MANAGEMENT LP',
    label: 'MELVIN CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001908587',
    value: '0001908587,MEMBERS ADVISORY GROUP LLC',
    name: 'MEMBERS ADVISORY GROUP LLC',
    label: 'MEMBERS ADVISORY GROUP LLC',
  },
  {
    cik: '0001025421',
    value: '0001025421,MEMBERS CAPITAL ADVISORS INC',
    name: 'MEMBERS CAPITAL ADVISORS INC',
    label: 'MEMBERS CAPITAL ADVISORS INC',
  },
  {
    cik: '0001677560',
    value: '0001677560,MEMBERS TRUST CO',
    name: 'MEMBERS TRUST CO',
    label: 'MEMBERS TRUST CO',
  },
  {
    cik: '0001566787',
    value: '0001566787,MEMORIAL SLOAN KETTERING CANCER CENTER',
    name: 'MEMORIAL SLOAN KETTERING CANCER CENTER',
    label: 'MEMORIAL SLOAN KETTERING CANCER CENTER',
  },
  {
    cik: '0001803156',
    value: '0001803156,MENARD FINANCIAL GROUP LLC',
    name: 'MENARD FINANCIAL GROUP LLC',
    label: 'MENARD FINANCIAL GROUP LLC',
  },
  {
    cik: '0001911384',
    value: '0001911384,MENDEL CAPITAL MANAGEMENT LLC',
    name: 'MENDEL CAPITAL MANAGEMENT LLC',
    label: 'MENDEL CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001600585',
    value: '0001600585,MENDEL MONEY MANAGEMENT',
    name: 'MENDEL MONEY MANAGEMENT',
    label: 'MENDEL MONEY MANAGEMENT',
  },
  {
    cik: '0001177043',
    value: '0001177043,MENDON CAPITAL ADVISORS CORP',
    name: 'MENDON CAPITAL ADVISORS CORP',
    label: 'MENDON CAPITAL ADVISORS CORP',
  },
  {
    cik: '0001609674',
    value: '0001609674,MENGIS CAPITAL MANAGEMENT, INC.',
    name: 'MENGIS CAPITAL MANAGEMENT, INC.',
    label: 'MENGIS CAPITAL MANAGEMENT, INC.',
  },
  {
    cik: '0001279708',
    value: '0001279708,MENLO ADVISORS LLC',
    name: 'MENLO ADVISORS LLC',
    label: 'MENLO ADVISORS LLC',
  },
  {
    cik: '0001351917',
    value: '0001351917,MENORA MIVTACHIM HOLDINGS LTD.',
    name: 'MENORA MIVTACHIM HOLDINGS LTD.',
    label: 'MENORA MIVTACHIM HOLDINGS LTD.',
  },
  {
    cik: '0001374701',
    value: '0001374701,MENTA CAPITAL LLC',
    name: 'MENTA CAPITAL LLC',
    label: 'MENTA CAPITAL LLC',
  },
  {
    cik: '0001016538',
    value: '0001016538,MERCATOR ASSET MANAGEMENT L P',
    name: 'MERCATOR ASSET MANAGEMENT L P',
    label: 'MERCATOR ASSET MANAGEMENT L P',
  },
  {
    cik: '0001799043',
    value: '0001799043,MERCATOR FUND (CAYMAN MASTER) LP',
    name: 'MERCATOR FUND (CAYMAN MASTER) LP',
    label: 'MERCATOR FUND (CAYMAN MASTER) LP',
  },
  {
    cik: '0001631185',
    value: '0001631185,MERCER CAPITAL ADVISERS, INC.',
    name: 'MERCER CAPITAL ADVISERS, INC.',
    label: 'MERCER CAPITAL ADVISERS, INC.',
  },
  {
    cik: '0000853758',
    value: '0000853758,MERCER GLOBAL ADVISORS INC /ADV',
    name: 'MERCER GLOBAL ADVISORS INC /ADV',
    label: 'MERCER GLOBAL ADVISORS INC /ADV',
  },
  {
    cik: '0001409728',
    value: '0001409728,MERCER INVESTMENT MANAGEMENT, INC.',
    name: 'MERCER INVESTMENT MANAGEMENT, INC.',
    label: 'MERCER INVESTMENT MANAGEMENT, INC.',
  },
  {
    cik: '0001409728',
    value: '0001409728,MERCER INVESTMENTS LLC',
    name: 'MERCER INVESTMENTS LLC',
    label: 'MERCER INVESTMENTS LLC',
  },
  {
    cik: '0001425160',
    value: '0001425160,MERCHANTS GATE CAPITAL LP',
    name: "MERCHANTS' GATE CAPITAL LP",
    label: "MERCHANTS' GATE CAPITAL LP",
  },
  {
    cik: '0001736852',
    value: '0001736852,MEREWETHER CYCLICALS PARTNERS LP',
    name: 'MEREWETHER CYCLICALS PARTNERS LP',
    label: 'MEREWETHER CYCLICALS PARTNERS LP',
  },
  {
    cik: '0001736852',
    value: '0001736852,MEREWETHER INVESTMENT MANAGEMENT, LP',
    name: 'MEREWETHER INVESTMENT MANAGEMENT, LP',
    label: 'MEREWETHER INVESTMENT MANAGEMENT, LP',
  },
  {
    cik: '0001080386',
    value: '0001080386,MERIAN GLOBAL INVESTORS (UK) LTD',
    name: 'MERIAN GLOBAL INVESTORS (UK) LTD',
    label: 'MERIAN GLOBAL INVESTORS (UK) LTD',
  },
  {
    cik: '0001763722',
    value: '0001763722,MERIDIAN FINANCIAL PARTNERS LLC',
    name: 'MERIDIAN FINANCIAL PARTNERS LLC',
    label: 'MERIDIAN FINANCIAL PARTNERS LLC',
  },
  {
    cik: '0001427351',
    value: '0001427351,MERIDIAN INVESTMENT COUNSEL INC.',
    name: 'MERIDIAN INVESTMENT COUNSEL INC.',
    label: 'MERIDIAN INVESTMENT COUNSEL INC.',
  },
  {
    cik: '0000806097',
    value: '0000806097,MERIDIAN MANAGEMENT CO',
    name: 'MERIDIAN MANAGEMENT CO',
    label: 'MERIDIAN MANAGEMENT CO',
  },
  {
    cik: '0001768095',
    value: '0001768095,MERIDIAN WEALTH ADVISORS, LLC',
    name: 'MERIDIAN WEALTH ADVISORS, LLC',
    label: 'MERIDIAN WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001697300',
    value: '0001697300,MERIDIAN WEALTH MANAGEMENT, LLC',
    name: 'MERIDIAN WEALTH MANAGEMENT, LLC',
    label: 'MERIDIAN WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001781942',
    value: '0001781942,MERIDIAN WEALTH PARTNERS, LLC',
    name: 'MERIDIAN WEALTH PARTNERS, LLC',
    label: 'MERIDIAN WEALTH PARTNERS, LLC',
  },
  {
    cik: '0001559020',
    value: '0001559020,MERION INVESTMENT MANAGEMENT LP',
    name: 'MERION INVESTMENT MANAGEMENT LP',
    label: 'MERION INVESTMENT MANAGEMENT LP',
  },
  {
    cik: '0001511915',
    value: '0001511915,MERISTEM FAMILY WEALTH, LLC',
    name: 'MERISTEM FAMILY WEALTH, LLC',
    label: 'MERISTEM FAMILY WEALTH, LLC',
  },
  {
    cik: '0001427119',
    value: '0001427119,MERITAGE GROUP LP',
    name: 'MERITAGE GROUP LP',
    label: 'MERITAGE GROUP LP',
  },
  {
    cik: '0000881432',
    value: '0000881432,MERITAGE PORTFOLIO MANAGEMENT',
    name: 'MERITAGE PORTFOLIO MANAGEMENT',
    label: 'MERITAGE PORTFOLIO MANAGEMENT',
  },
  {
    cik: '0001500544',
    value: '0001500544,MERITECH CAPITAL ASSOCIATES III L.L.C.',
    name: 'MERITECH CAPITAL ASSOCIATES III L.L.C.',
    label: 'MERITECH CAPITAL ASSOCIATES III L.L.C.',
  },
  {
    cik: '0001600189',
    value: '0001600189,MERITECH CAPITAL ASSOCIATES IV L.L.C.',
    name: 'MERITECH CAPITAL ASSOCIATES IV L.L.C.',
    label: 'MERITECH CAPITAL ASSOCIATES IV L.L.C.',
  },
  {
    cik: '0001600189',
    value: '0001600189,MERITECH CAPITAL ASSOCIATES IV, L.L.C.',
    name: 'MERITECH CAPITAL ASSOCIATES IV, L.L.C.',
    label: 'MERITECH CAPITAL ASSOCIATES IV, L.L.C.',
  },
  {
    cik: '0001741044',
    value: '0001741044,MERITECH CAPITAL ASSOCIATES VI L.L.C.',
    name: 'MERITECH CAPITAL ASSOCIATES VI L.L.C.',
    label: 'MERITECH CAPITAL ASSOCIATES VI L.L.C.',
  },
  {
    cik: '0001613048',
    value: '0001613048,MERITECH CAPITAL ASSOCIATES V L.L.C.',
    name: 'MERITECH CAPITAL ASSOCIATES V L.L.C.',
    label: 'MERITECH CAPITAL ASSOCIATES V L.L.C.',
  },
  {
    cik: '0001621225',
    value: '0001621225,MERIT FINANCIAL GROUP, LLC',
    name: 'MERIT FINANCIAL GROUP, LLC',
    label: 'MERIT FINANCIAL GROUP, LLC',
  },
  {
    cik: '0001524467',
    value: '0001524467,MERIT INVESTMENT MANAGEMENT, LLC',
    name: 'MERIT INVESTMENT MANAGEMENT, LLC',
    label: 'MERIT INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001302842',
    value: '0001302842,MERK INVESTMENTS LLC',
    name: 'MERK INVESTMENTS LLC',
    label: 'MERK INVESTMENTS LLC',
  },
  {
    cik: '0001911169',
    value: '0001911169,MERLIN CAPITAL LLC',
    name: 'MERLIN CAPITAL LLC',
    label: 'MERLIN CAPITAL LLC',
  },
  {
    cik: '0001911372',
    value: '0001911372,MEROS INVESTMENT MANAGEMENT, LP',
    name: 'MEROS INVESTMENT MANAGEMENT, LP',
    label: 'MEROS INVESTMENT MANAGEMENT, LP',
  },
  {
    cik: '0001568389',
    value: '0001568389,MERRIMAN WEALTH MANAGEMENT, LLC',
    name: 'MERRIMAN WEALTH MANAGEMENT, LLC',
    label: 'MERRIMAN WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001383782',
    value: '0001383782,MERRION INVESTMENT MANAGEMENT CO, LLC',
    name: 'MERRION INVESTMENT MANAGEMENT CO, LLC',
    label: 'MERRION INVESTMENT MANAGEMENT CO, LLC',
  },
  {
    cik: '0001512145',
    value: '0001512145,MERU CAPITAL GROUP, LP',
    name: 'MERU CAPITAL GROUP, LP',
    label: 'MERU CAPITAL GROUP, LP',
  },
  {
    cik: '0000846788',
    value:
      '0000846788,MESIROW FINANCIAL INVESTMENT MANAGEMENT - EQUITY & FIXED INCOME',
    name: 'MESIROW FINANCIAL INVESTMENT MANAGEMENT - EQUITY & FIXED INCOME',
    label: 'MESIROW FINANCIAL INVESTMENT MANAGEMENT - EQUITY & FIXED INCOME',
  },
  {
    cik: '0000846788',
    value: '0000846788,MESIROW FINANCIAL INVESTMENT MANAGEMENT - FIXED INCOME',
    name: 'MESIROW FINANCIAL INVESTMENT MANAGEMENT - FIXED INCOME',
    label: 'MESIROW FINANCIAL INVESTMENT MANAGEMENT - FIXED INCOME',
  },
  {
    cik: '0001469475',
    value: '0001469475,MESIROW FINANCIAL INVESTMENT MANAGEMENT, INC.',
    name: 'MESIROW FINANCIAL INVESTMENT MANAGEMENT, INC.',
    label: 'MESIROW FINANCIAL INVESTMENT MANAGEMENT, INC.',
  },
  {
    cik: '0001947591',
    value: '0001947591,MESIROW INSTITUTIONAL INVESTMENT MANAGEMENT, INC.',
    name: 'MESIROW INSTITUTIONAL INVESTMENT MANAGEMENT, INC.',
    label: 'MESIROW INSTITUTIONAL INVESTMENT MANAGEMENT, INC.',
  },
  {
    cik: '0000883487',
    value:
      '0000883487,MESSNER & SMITH THEME VALUE INVESTMENT MANAGEMENT LTD /CA/',
    name: 'MESSNER & SMITH THEME VALUE INVESTMENT MANAGEMENT LTD /CA/',
    label: 'MESSNER & SMITH THEME VALUE INVESTMENT MANAGEMENT LTD /CA/',
  },
  {
    cik: '0001298477',
    value: '0001298477,METALMARK CAPITAL LLC',
    name: 'METALMARK CAPITAL LLC',
    label: 'METALMARK CAPITAL LLC',
  },
  {
    cik: '0001569639',
    value: '0001569639,METALMARK MANAGEMENT II LLC',
    name: 'METALMARK MANAGEMENT II LLC',
    label: 'METALMARK MANAGEMENT II LLC',
  },
  {
    cik: '0001718013',
    value: '0001718013,METATRON CAPITAL SICAV PLC',
    name: 'METATRON CAPITAL SICAV PLC',
    label: 'METATRON CAPITAL SICAV PLC',
  },
  {
    cik: '0001864835',
    value: '0001864835,METAVASI CAPITAL LP',
    name: 'METAVASI CAPITAL LP',
    label: 'METAVASI CAPITAL LP',
  },
  {
    cik: '0001905106',
    value: '0001905106,METEORA CAPITAL, LLC',
    name: 'METEORA CAPITAL, LLC',
    label: 'METEORA CAPITAL, LLC',
  },
  {
    cik: '0001594916',
    value: '0001594916,METIS GLOBAL PARTNERS, LLC',
    name: 'METIS GLOBAL PARTNERS, LLC',
    label: 'METIS GLOBAL PARTNERS, LLC',
  },
  {
    cik: '0001099219',
    value: '0001099219,METLIFE INC',
    name: 'METLIFE INC',
    label: 'METLIFE INC',
  },
  {
    cik: '0001529735',
    value: '0001529735,METLIFE INVESTMENT ADVISORS, LLC',
    name: 'METLIFE INVESTMENT ADVISORS, LLC',
    label: 'METLIFE INVESTMENT ADVISORS, LLC',
  },
  {
    cik: '0001529735',
    value: '0001529735,METLIFE INVESTMENT MANAGEMENT, LLC',
    name: 'METLIFE INVESTMENT MANAGEMENT, LLC',
    label: 'METLIFE INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001422771',
    value: '0001422771,METLIFE SECURITIES, INC',
    name: 'METLIFE SECURITIES, INC',
    label: 'METLIFE SECURITIES, INC',
  },
  {
    cik: '0001765388',
    value: '0001765388,METROPOLIS CAPITAL LTD',
    name: 'METROPOLIS CAPITAL LTD',
    label: 'METROPOLIS CAPITAL LTD',
  },
  {
    cik: '0001280054',
    value: '0001280054,METROPOLITAN CAPITAL ADVISORS INC',
    name: 'METROPOLITAN CAPITAL ADVISORS INC',
    label: 'METROPOLITAN CAPITAL ADVISORS INC',
  },
  {
    cik: '0000728618',
    value: '0000728618,METROPOLITAN LIFE INSURANCE CO/NY',
    name: 'METROPOLITAN LIFE INSURANCE CO/NY',
    label: 'METROPOLITAN LIFE INSURANCE CO/NY',
  },
  {
    cik: '0000045319',
    value: '0000045319,MEYER HANDELMAN CO',
    name: 'MEYER HANDELMAN CO',
    label: 'MEYER HANDELMAN CO',
  },
  {
    cik: '0001864916',
    value: '0001864916,MEZZASALMA ADVISORS, LLC',
    name: 'MEZZASALMA ADVISORS, LLC',
    label: 'MEZZASALMA ADVISORS, LLC',
  },
  {
    cik: '0001688184',
    value: '0001688184,MFA WEALTH ADVISORS, LLC',
    name: 'MFA WEALTH ADVISORS, LLC',
    label: 'MFA WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001730083',
    value: '0001730083,M FINANCIAL ASSET MANAGEMENT, INC.',
    name: 'M FINANCIAL ASSET MANAGEMENT, INC.',
    label: 'M FINANCIAL ASSET MANAGEMENT, INC.',
  },
  {
    cik: '0001732811',
    value: '0001732811,MFN PARTNERS MANAGEMENT, LP',
    name: 'MFN PARTNERS MANAGEMENT, LP',
    label: 'MFN PARTNERS MANAGEMENT, LP',
  },
  {
    cik: '0001723594',
    value: '0001723594,MGG INVESTMENT GROUP LP',
    name: 'MGG INVESTMENT GROUP LP',
    label: 'MGG INVESTMENT GROUP LP',
  },
  {
    cik: '0000922898',
    value: '0000922898,M&G INVESTMENT MANAGEMENT LTD',
    name: 'M&G INVESTMENT MANAGEMENT LTD',
    label: 'M&G INVESTMENT MANAGEMENT LTD',
  },
  {
    cik: '0001731444',
    value: '0001731444,MGO ONE SEVEN LLC',
    name: 'MGO ONE SEVEN LLC',
    label: 'MGO ONE SEVEN LLC',
  },
  {
    cik: '0001952532',
    value: '0001952532,MGO PRIVATE WEALTH, LLC',
    name: 'MGO PRIVATE WEALTH, LLC',
    label: 'MGO PRIVATE WEALTH, LLC',
  },
  {
    cik: '0001727710',
    value: '0001727710,MHI FUNDS, LLC',
    name: 'MHI FUNDS, LLC',
    label: 'MHI FUNDS, LLC',
  },
  {
    cik: '0001569638',
    value: '0001569638,M HOLDINGS SECURITIES, INC.',
    name: 'M HOLDINGS SECURITIES, INC.',
    label: 'M HOLDINGS SECURITIES, INC.',
  },
  {
    cik: '0001277742',
    value: '0001277742,MHR FUND MANAGEMENT LLC',
    name: 'MHR FUND MANAGEMENT LLC',
    label: 'MHR FUND MANAGEMENT LLC',
  },
  {
    cik: '0001675170',
    value: '0001675170,MICAWBER CAPITAL, LLC',
    name: 'MICAWBER CAPITAL, LLC',
    label: 'MICAWBER CAPITAL, LLC',
  },
  {
    cik: '0001889830',
    value: '0001889830,MIC CAPITAL MANAGEMENT UK LLP',
    name: 'MIC CAPITAL MANAGEMENT UK LLP',
    label: 'MIC CAPITAL MANAGEMENT UK LLP',
  },
  {
    cik: '0001770682',
    value: '0001770682,MIC CAPITAL PARTNERS (PUBLIC) (US) IM, LLC',
    name: 'MIC CAPITAL PARTNERS (PUBLIC) (US) IM, LLC',
    label: 'MIC CAPITAL PARTNERS (PUBLIC) (US) IM, LLC',
  },
  {
    cik: '0001599858',
    value: '0001599858,MICHAEL & SUSAN DELL FOUNDATION',
    name: 'MICHAEL & SUSAN DELL FOUNDATION',
    label: 'MICHAEL & SUSAN DELL FOUNDATION',
  },
  {
    cik: '0001911264',
    value: '0001911264,MID-AMERICAN WEALTH ADVISORY GROUP, INC.',
    name: 'MID-AMERICAN WEALTH ADVISORY GROUP, INC.',
    label: 'MID-AMERICAN WEALTH ADVISORY GROUP, INC.',
  },
  {
    cik: '0000065957',
    value: '0000065957,MIDAS MANAGEMENT CORP',
    name: 'MIDAS MANAGEMENT CORP',
    label: 'MIDAS MANAGEMENT CORP',
  },
  {
    cik: '0000746504',
    value: '0000746504,MID ATLANTIC FINANCIAL MANAGEMENT INC/ADV',
    name: 'MID ATLANTIC FINANCIAL MANAGEMENT INC/ADV',
    label: 'MID ATLANTIC FINANCIAL MANAGEMENT INC/ADV',
  },
  {
    cik: '0001015887',
    value: '0001015887,MID CONTINENT CAPITAL LLC/IL',
    name: 'MID CONTINENT CAPITAL LLC/IL',
    label: 'MID CONTINENT CAPITAL LLC/IL',
  },
  {
    cik: '0000919497',
    value: '0000919497,MIDDLETON & CO INC/MA',
    name: 'MIDDLETON & CO INC/MA',
    label: 'MIDDLETON & CO INC/MA',
  },
  {
    cik: '0001906805',
    value: '0001906805,MIDWEST HERITAGE BANK, FSB',
    name: 'MIDWEST HERITAGE BANK, FSB',
    label: 'MIDWEST HERITAGE BANK, FSB',
  },
  {
    cik: '0001278178',
    value: '0001278178,MIDWEST INVESTMENT MANAGEMENT LLC',
    name: 'MIDWEST INVESTMENT MANAGEMENT LLC',
    label: 'MIDWEST INVESTMENT MANAGEMENT LLC',
  },
  {
    cik: '0001412665',
    value: '0001412665,MIDWESTONE FINANCIAL GROUP, INC.',
    name: 'MIDWESTONE FINANCIAL GROUP, INC.',
    label: 'MIDWESTONE FINANCIAL GROUP, INC.',
  },
  {
    cik: '0001730575',
    value: '0001730575,MIDWEST PROFESSIONAL PLANNERS, LTD.',
    name: 'MIDWEST PROFESSIONAL PLANNERS, LTD.',
    label: 'MIDWEST PROFESSIONAL PLANNERS, LTD.',
  },
  {
    cik: '0001844894',
    value: '0001844894,MIDWEST WEALTH MANAGEMENT INC.',
    name: 'MIDWEST WEALTH MANAGEMENT INC.',
    label: 'MIDWEST WEALTH MANAGEMENT INC.',
  },
  {
    cik: '0001425649',
    value: '0001425649,MIG CAPITAL, LLC',
    name: 'MIG CAPITAL, LLC',
    label: 'MIG CAPITAL, LLC',
  },
  {
    cik: '0001415912',
    value: '0001415912,MIGDAL INSURANCE & FINANCIAL HOLDINGS LTD.',
    name: 'MIGDAL INSURANCE & FINANCIAL HOLDINGS LTD.',
    label: 'MIGDAL INSURANCE & FINANCIAL HOLDINGS LTD.',
  },
  {
    cik: '0001638902',
    value: '0001638902,MIK CAPITAL LLC',
    name: 'MIK CAPITAL LLC',
    label: 'MIK CAPITAL LLC',
  },
  {
    cik: '0001785981',
    value: '0001785981,MIK CAPITAL, LP',
    name: 'MIK CAPITAL, LP',
    label: 'MIK CAPITAL, LP',
  },
  {
    cik: '0001276151',
    value: '0001276151,MILES CAPITAL, INC.',
    name: 'MILES CAPITAL, INC.',
    label: 'MILES CAPITAL, INC.',
  },
  {
    cik: '0001819679',
    value: '0001819679,MILESTONE ADVISORY PARTNERS',
    name: 'MILESTONE ADVISORY PARTNERS',
    label: 'MILESTONE ADVISORY PARTNERS',
  },
  {
    cik: '0001317348',
    value: '0001317348,MILESTONE GROUP, INC.',
    name: 'MILESTONE GROUP, INC.',
    label: 'MILESTONE GROUP, INC.',
  },
  {
    cik: '0001708139',
    value: '0001708139,MILESTONE RESOURCES GROUP LTD',
    name: 'MILESTONE RESOURCES GROUP LTD',
    label: 'MILESTONE RESOURCES GROUP LTD',
  },
  {
    cik: '0001904020',
    value: '0001904020,MILESTONE WEALTH, LLC',
    name: 'MILESTONE WEALTH, LLC',
    label: 'MILESTONE WEALTH, LLC',
  },
  {
    cik: '0001294571',
    value: '0001294571,MILLBURN RIDGEFIELD CORP',
    name: 'MILLBURN RIDGEFIELD CORP',
    label: 'MILLBURN RIDGEFIELD CORP',
  },
  {
    cik: '0001901166',
    value: '0001901166,MILL CAPITAL MANAGEMENT, LLC',
    name: 'MILL CAPITAL MANAGEMENT, LLC',
    label: 'MILL CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001273087',
    value: '0001273087,MILLENNIUM MANAGEMENT LLC',
    name: 'MILLENNIUM MANAGEMENT LLC',
    label: 'MILLENNIUM MANAGEMENT LLC',
  },
  {
    cik: '0001599955',
    value: '0001599955,MILLENNIUM TVP MANAGEMENT CO., LLC',
    name: 'MILLENNIUM TVP MANAGEMENT CO., LLC',
    label: 'MILLENNIUM TVP MANAGEMENT CO., LLC',
  },
  {
    cik: '0000756094',
    value: '0000756094,MILLER GREEN FINANCIAL SERVICES INC /ADV',
    name: 'MILLER GREEN FINANCIAL SERVICES INC /ADV',
    label: 'MILLER GREEN FINANCIAL SERVICES INC /ADV',
  },
  {
    cik: '0000903947',
    value: '0000903947,MILLER HOWARD INVESTMENTS INC /NY',
    name: 'MILLER HOWARD INVESTMENTS INC /NY',
    label: 'MILLER HOWARD INVESTMENTS INC /NY',
  },
  {
    cik: '0001407382',
    value: '0001407382,MILLER INVESTMENT MANAGEMENT, LP',
    name: 'MILLER INVESTMENT MANAGEMENT, LP',
    label: 'MILLER INVESTMENT MANAGEMENT, LP',
  },
  {
    cik: '0001135778',
    value: '0001135778,MILLER VALUE PARTNERS, LLC',
    name: 'MILLER VALUE PARTNERS, LLC',
    label: 'MILLER VALUE PARTNERS, LLC',
  },
  {
    cik: '0001908158',
    value: '0001908158,MILLER WEALTH ADVISORS, LLC',
    name: 'MILLER WEALTH ADVISORS, LLC',
    label: 'MILLER WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001499266',
    value: '0001499266,MILLIE CAPITAL MANAGEMENT, LLC',
    name: 'MILLIE CAPITAL MANAGEMENT, LLC',
    label: 'MILLIE CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001547927',
    value: '0001547927,MILLIMAN FINANCIAL RISK MANAGEMENT LLC',
    name: 'MILLIMAN FINANCIAL RISK MANAGEMENT LLC',
    label: 'MILLIMAN FINANCIAL RISK MANAGEMENT LLC',
  },
  {
    cik: '0001604831',
    value: '0001604831,MILLRACE ASSET GROUP, INC.',
    name: 'MILLRACE ASSET GROUP, INC.',
    label: 'MILLRACE ASSET GROUP, INC.',
  },
  {
    cik: '0001512275',
    value: '0001512275,MILL ROAD CAPITAL MANAGEMENT LLC',
    name: 'MILL ROAD CAPITAL MANAGEMENT LLC',
    label: 'MILL ROAD CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001802994',
    value: '0001802994,MINE & ARAO WEALTH CREATION & MANAGEMENT, LLC.',
    name: 'MINE & ARAO WEALTH CREATION & MANAGEMENT, LLC.',
    label: 'MINE & ARAO WEALTH CREATION & MANAGEMENT, LLC.',
  },
  {
    cik: '0001541536',
    value: '0001541536,MINERVA ADVISORS LLC',
    name: 'MINERVA ADVISORS LLC',
    label: 'MINERVA ADVISORS LLC',
  },
  {
    cik: '0001744318',
    value: '0001744318,MINICHMACGREGOR WEALTH MANAGEMENT, LLC',
    name: 'MINICHMACGREGOR WEALTH MANAGEMENT, LLC',
    label: 'MINICHMACGREGOR WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001317253',
    value: '0001317253,MINNEAPOLIS PORTFOLIO MANAGEMENT GROUP, LLC',
    name: 'MINNEAPOLIS PORTFOLIO MANAGEMENT GROUP, LLC',
    label: 'MINNEAPOLIS PORTFOLIO MANAGEMENT GROUP, LLC',
  },
  {
    cik: '0001835714',
    value: '0001835714,MINOT CAPITAL, LP',
    name: 'MINOT CAPITAL, LP',
    label: 'MINOT CAPITAL, LP',
  },
  {
    cik: '0001801774',
    value: '0001801774,MINOT WEALTH MANAGEMENT LLC',
    name: 'MINOT WEALTH MANAGEMENT LLC',
    label: 'MINOT WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0001666606',
    value: '0001666606,MINT TOWER CAPITAL MANAGEMENT B.V.',
    name: 'MINT TOWER CAPITAL MANAGEMENT B.V.',
    label: 'MINT TOWER CAPITAL MANAGEMENT B.V.',
  },
  {
    cik: '0001535457',
    value: '0001535457,MIO PARTNERS, INC.',
    name: 'MIO PARTNERS, INC.',
    label: 'MIO PARTNERS, INC.',
  },
  {
    cik: '0001538853',
    value: '0001538853,MIRABELLA FINANCIAL SERVICES LLP',
    name: 'MIRABELLA FINANCIAL SERVICES LLP',
    label: 'MIRABELLA FINANCIAL SERVICES LLP',
  },
  {
    cik: '0001585859',
    value: '0001585859,MIRACLE MILE ADVISORS, LLC',
    name: 'MIRACLE MILE ADVISORS, LLC',
    label: 'MIRACLE MILE ADVISORS, LLC',
  },
  {
    cik: '0001633446',
    value: '0001633446,MIRADOR CAPITAL PARTNERS LP',
    name: 'MIRADOR CAPITAL PARTNERS LP',
    label: 'MIRADOR CAPITAL PARTNERS LP',
  },
  {
    cik: '0001569395',
    value: '0001569395,MIRAE ASSET GLOBAL INVESTMENTS CO., LTD.',
    name: 'MIRAE ASSET GLOBAL INVESTMENTS CO., LTD.',
    label: 'MIRAE ASSET GLOBAL INVESTMENTS CO., LTD.',
  },
  {
    cik: '0001767812',
    value: '0001767812,MIRAMAR CAPITAL, LLC',
    name: 'MIRAMAR CAPITAL, LLC',
    label: 'MIRAMAR CAPITAL, LLC',
  },
  {
    cik: '0001587301',
    value: '0001587301,MIRAMAR FIDUCIARY CORP',
    name: 'MIRAMAR FIDUCIARY CORP',
    label: 'MIRAMAR FIDUCIARY CORP',
  },
  {
    cik: '0001767843',
    value: '00017,MIROVA843',
    name: 'MIROVA',
    label: 'MIROVA',
  },
  {
    cik: '0001802900',
    value: '0001802900,MIROVA US LLC',
    name: 'MIROVA US LLC',
    label: 'MIROVA US LLC',
  },
  {
    cik: '0001764144',
    value: '0001764144,MIRSKY FINANCIAL MANAGEMENT CORP.',
    name: 'MIRSKY FINANCIAL MANAGEMENT CORP.',
    label: 'MIRSKY FINANCIAL MANAGEMENT CORP.',
  },
  {
    cik: '0001801583',
    value: '0001801583,MISSION CREEK CAPITAL PARTNERS, INC',
    name: 'MISSION CREEK CAPITAL PARTNERS, INC',
    label: 'MISSION CREEK CAPITAL PARTNERS, INC',
  },
  {
    cik: '0001801583',
    value: '0001801583,MISSION CREEK CAPITAL PARTNERS, INC.',
    name: 'MISSION CREEK CAPITAL PARTNERS, INC.',
    label: 'MISSION CREEK CAPITAL PARTNERS, INC.',
  },
  {
    cik: '0001645382',
    value: '0001645382,MISSION WEALTH MANAGEMENT, LLC',
    name: 'MISSION WEALTH MANAGEMENT, LLC',
    label: 'MISSION WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001645382',
    value: '0001645382,MISSION WEALTH MANAGEMENT, LP',
    name: 'MISSION WEALTH MANAGEMENT, LP',
    label: 'MISSION WEALTH MANAGEMENT, LP',
  },
  {
    cik: '0001908186',
    value: '0001908186,MISSOURI TRUST & INVESTMENT CO',
    name: 'MISSOURI TRUST & INVESTMENT CO',
    label: 'MISSOURI TRUST & INVESTMENT CO',
  },
  {
    cik: '0001080351',
    value: '0001080351,MITCHELL CAPITAL MANAGEMENT CO',
    name: 'MITCHELL CAPITAL MANAGEMENT CO',
    label: 'MITCHELL CAPITAL MANAGEMENT CO',
  },
  {
    cik: '0001190327',
    value: '0001190327,MITCHELL GROUP INC',
    name: 'MITCHELL GROUP INC',
    label: 'MITCHELL GROUP INC',
  },
  {
    cik: '0001632105',
    value: '0001632105,MITCHELL MCLEOD PUGH & WILLIAMS INC',
    name: 'MITCHELL MCLEOD PUGH & WILLIAMS INC',
    label: 'MITCHELL MCLEOD PUGH & WILLIAMS INC',
  },
  {
    cik: '0001836506',
    value: '0001836506,MITCHELL & PAHL PRIVATE WEALTH, LLC',
    name: 'MITCHELL & PAHL PRIVATE WEALTH, LLC',
    label: 'MITCHELL & PAHL PRIVATE WEALTH, LLC',
  },
  {
    cik: '0001048921',
    value: '0001048921,MITCHELL SINKLER & STARR/PA',
    name: 'MITCHELL SINKLER & STARR/PA',
    label: 'MITCHELL SINKLER & STARR/PA',
  },
  {
    cik: '0001834600',
    value: '0001834600,MITHAQ CAPITAL SPC',
    name: 'MITHAQ CAPITAL SPC',
    label: 'MITHAQ CAPITAL SPC',
  },
  {
    cik: '0001605913',
    value: '0001605913,MITRA CAPITAL LLC',
    name: 'MITRA CAPITAL LLC',
    label: 'MITRA CAPITAL LLC',
  },
  {
    cik: '0001466546',
    value: '0001466546,MITSUBISHI UFJ ASSET MANAGEMENT CO., LTD.',
    name: 'MITSUBISHI UFJ ASSET MANAGEMENT CO., LTD.',
    label: 'MITSUBISHI UFJ ASSET MANAGEMENT CO., LTD.',
  },
  {
    cik: '0001694895',
    value: '0001694895,MITSUBISHI UFJ ASSET MANAGEMENT (UK) LTD.',
    name: 'MITSUBISHI UFJ ASSET MANAGEMENT (UK) LTD.',
    label: 'MITSUBISHI UFJ ASSET MANAGEMENT (UK) LTD.',
  },
  {
    cik: '0000067088',
    value: '0000067088,MITSUBISHI UFJ FINANCIAL GROUP INC',
    name: 'MITSUBISHI UFJ FINANCIAL GROUP INC',
    label: 'MITSUBISHI UFJ FINANCIAL GROUP INC',
  },
  {
    cik: '0001466546',
    value: '0001466546,MITSUBISHI UFJ KOKUSAI ASSET MANAGEMENT CO., LTD.',
    name: 'MITSUBISHI UFJ KOKUSAI ASSET MANAGEMENT CO., LTD.',
    label: 'MITSUBISHI UFJ KOKUSAI ASSET MANAGEMENT CO., LTD.',
  },
  {
    cik: '0001545545',
    value: '0001545545,MITSUBISHI UFJ MORGAN STANLEY SECURITIES CO., LTD.',
    name: 'MITSUBISHI UFJ MORGAN STANLEY SECURITIES CO., LTD.',
    label: 'MITSUBISHI UFJ MORGAN STANLEY SECURITIES CO., LTD.',
  },
  {
    cik: '0001374022',
    value: '0001374022,MITSUBISHI UFJ SECURITIES HOLDINGS CO., LTD.',
    name: 'MITSUBISHI UFJ SECURITIES HOLDINGS CO., LTD.',
    label: 'MITSUBISHI UFJ SECURITIES HOLDINGS CO., LTD.',
  },
  {
    cik: '0001597694',
    value: '0001597694,MITSUBISHI UFJ SECURITIES INTERNATIONAL PLC',
    name: 'MITSUBISHI UFJ SECURITIES INTERNATIONAL PLC',
    label: 'MITSUBISHI UFJ SECURITIES INTERNATIONAL PLC',
  },
  {
    cik: '0001492299',
    value: '0001492299,MITSUBISHI UFJ SECURITIES (USA), INC.',
    name: 'MITSUBISHI UFJ SECURITIES (USA), INC.',
    label: 'MITSUBISHI UFJ SECURITIES (USA), INC.',
  },
  {
    cik: '0001462160',
    value: '0001462160,MITSUBISHI UFJ TRUST & BANKING CORP',
    name: 'MITSUBISHI UFJ TRUST & BANKING CORP',
    label: 'MITSUBISHI UFJ TRUST & BANKING CORP',
  },
  {
    cik: '0001456827',
    value: '0001456827,MITTLEMAN BROTHERS LLC',
    name: 'MITTLEMAN BROTHERS LLC',
    label: 'MITTLEMAN BROTHERS LLC',
  },
  {
    cik: '0001456827',
    value: '0001456827,MITTLEMAN INVESTMENT MANAGEMENT, LLC',
    name: 'MITTLEMAN INVESTMENT MANAGEMENT, LLC',
    label: 'MITTLEMAN INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001352272',
    value: '0001352272,MIURA GLOBAL MANAGEMENT, LLC',
    name: 'MIURA GLOBAL MANAGEMENT, LLC',
    label: 'MIURA GLOBAL MANAGEMENT, LLC',
  },
  {
    cik: '0001599719',
    value:
      '0001599719,MIVTACHIM THE WORKERS SOCIAL INSURANCE FUND LTD. (UNDER SPECIAL MANAGEMENT)',
    name: 'MIVTACHIM THE WORKERS SOCIAL INSURANCE FUND LTD. (UNDER SPECIAL MANAGEMENT)',
    label:
      'MIVTACHIM THE WORKERS SOCIAL INSURANCE FUND LTD. (UNDER SPECIAL MANAGEMENT)',
  },
  {
    cik: '0001512444',
    value: '0001512444,MIZUHO ASSET MANAGEMENT CO.,LTD.',
    name: 'MIZUHO ASSET MANAGEMENT CO.,LTD.',
    label: 'MIZUHO ASSET MANAGEMENT CO.,LTD.',
  },
  {
    cik: '0001512734',
    value: '0001512734,MIZUHO BANK, LTD.',
    name: 'MIZUHO BANK, LTD.',
    label: 'MIZUHO BANK, LTD.',
  },
  {
    cik: '0001335730',
    value: '0001335730,MIZUHO FINANCIAL GROUP INC',
    name: 'MIZUHO FINANCIAL GROUP INC',
    label: 'MIZUHO FINANCIAL GROUP INC',
  },
  {
    cik: '0001825516',
    value: '0001825516,MIZUHO MARKETS AMERICAS LLC',
    name: 'MIZUHO MARKETS AMERICAS LLC',
    label: 'MIZUHO MARKETS AMERICAS LLC',
  },
  {
    cik: '0001803149',
    value: '0001803149,MIZUHO MARKETS CAYMAN LP',
    name: 'MIZUHO MARKETS CAYMAN LP',
    label: 'MIZUHO MARKETS CAYMAN LP',
  },
  {
    cik: '0001520309',
    value: '0001520309,MIZUHO SECURITIES CO. LTD.',
    name: 'MIZUHO SECURITIES CO. LTD.',
    label: 'MIZUHO SECURITIES CO. LTD.',
  },
  {
    cik: '0001513006',
    value: '0001513006,MIZUHO SECURITIES USA INC.',
    name: 'MIZUHO SECURITIES USA INC.',
    label: 'MIZUHO SECURITIES USA INC.',
  },
  {
    cik: '0001513006',
    value: '0001513006,MIZUHO SECURITIES USA LLC',
    name: 'MIZUHO SECURITIES USA LLC',
    label: 'MIZUHO SECURITIES USA LLC',
  },
  {
    cik: '0001512575',
    value: '0001512575,MIZUHO TRUST & BANKING CO., LTD.',
    name: 'MIZUHO TRUST & BANKING CO., LTD.',
    label: 'MIZUHO TRUST & BANKING CO., LTD.',
  },
  {
    cik: '0000715113',
    value: '0000715113,MJP ASSOCIATES INC /ADV',
    name: 'MJP ASSOCIATES INC /ADV',
    label: 'MJP ASSOCIATES INC /ADV',
  },
  {
    cik: '0001536549',
    value: '0001536549,MKD WEALTH COACHES, LLC',
    name: 'MKD WEALTH COACHES, LLC',
    label: 'MKD WEALTH COACHES, LLC',
  },
  {
    cik: '0001534949',
    value: '0001534949,MKP CAPITAL MANAGEMENT, L.L.C.',
    name: 'MKP CAPITAL MANAGEMENT, L.L.C.',
    label: 'MKP CAPITAL MANAGEMENT, L.L.C.',
  },
  {
    cik: '0001593688',
    value: '0001593688,M. KRAUS & CO',
    name: 'M. KRAUS & CO',
    label: 'M. KRAUS & CO',
  },
  {
    cik: '0001906802',
    value: '0001906802,MKT ADVISORS LLC',
    name: 'MKT ADVISORS LLC',
    label: 'MKT ADVISORS LLC',
  },
  {
    cik: '0001842370',
    value: '0001842370,M. KULYK & ASSOCIATES, LLC',
    name: 'M. KULYK & ASSOCIATES, LLC',
    label: 'M. KULYK & ASSOCIATES, LLC',
  },
  {
    cik: '0001903320',
    value: '0001903320,ML & R WEALTH MANAGEMENT LLC',
    name: 'ML & R WEALTH MANAGEMENT LLC',
    label: 'ML & R WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0001842667',
    value: '0001842667,MMA ASSET MANAGEMENT LLC',
    name: 'MMA ASSET MANAGEMENT LLC',
    label: 'MMA ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001765876',
    value: '0001765876,MMBG INVESTMENT ADVISORS CO.',
    name: 'MMBG INVESTMENT ADVISORS CO.',
    label: 'MMBG INVESTMENT ADVISORS CO.',
  },
  {
    cik: '0001304857',
    value: '0001304857,MMCAP INTERNATIONAL INC. SPC',
    name: 'MMCAP INTERNATIONAL INC. SPC',
    label: 'MMCAP INTERNATIONAL INC. SPC',
  },
  {
    cik: '0001483617',
    value: '0001483617,MM CAPITAL LLC',
    name: 'MM CAPITAL LLC',
    label: 'MM CAPITAL LLC',
  },
  {
    cik: '0000701059',
    value: '0000701059,MML INVESTORS SERVICES, LLC',
    name: 'MML INVESTORS SERVICES, LLC',
    label: 'MML INVESTORS SERVICES, LLC',
  },
  {
    cik: '0001666940',
    value: '0001666940,MN SERVICES VERMOGENSBEHEER B.V.',
    name: 'MN SERVICES VERMOGENSBEHEER B.V.',
    label: 'MN SERVICES VERMOGENSBEHEER B.V.',
  },
  {
    cik: '0001377817',
    value: '0001377817,MOAB CAPITAL PARTNERS LLC',
    name: 'MOAB CAPITAL PARTNERS LLC',
    label: 'MOAB CAPITAL PARTNERS LLC',
  },
  {
    cik: '0001688237',
    value: '0001688237,MOBIUS CAPITAL MANAGEMENT, LLC',
    name: 'MOBIUS CAPITAL MANAGEMENT, LLC',
    label: 'MOBIUS CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001834210',
    value: '0001834210,MODEL CAPITAL MANAGEMENT LLC',
    name: 'MODEL CAPITAL MANAGEMENT LLC',
    label: 'MODEL CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001542143',
    value: '0001542143,MODERA WEALTH MANAGEMENT, LLC',
    name: 'MODERA WEALTH MANAGEMENT, LLC',
    label: 'MODERA WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001799284',
    value: '0001799284,MODUS ADVISORS, LLC',
    name: 'MODUS ADVISORS, LLC',
    label: 'MODUS ADVISORS, LLC',
  },
  {
    cik: '0001742647',
    value: '0001742647,MOERUS CAPITAL MANAGEMENT LLC',
    name: 'MOERUS CAPITAL MANAGEMENT LLC',
    label: 'MOERUS CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001126735',
    value: '0001126735,MOGY JOEL R INVESTMENT COUNSEL INC',
    name: 'MOGY JOEL R INVESTMENT COUNSEL INC',
    label: 'MOGY JOEL R INVESTMENT COUNSEL INC',
  },
  {
    cik: '0001367663',
    value: '0001367663,MOHICAN FINANCIAL MANAGEMENT, LLC',
    name: 'MOHICAN FINANCIAL MANAGEMENT, LLC',
    label: 'MOHICAN FINANCIAL MANAGEMENT, LLC',
  },
  {
    cik: '0001649107',
    value: '0001649107,MOISAND FITZGERALD TAMAYO, LLC',
    name: 'MOISAND FITZGERALD TAMAYO, LLC',
    label: 'MOISAND FITZGERALD TAMAYO, LLC',
  },
  {
    cik: '0001713112',
    value: '0001713112,MOKOSAK ADVISORY GROUP, LLC',
    name: 'MOKOSAK ADVISORY GROUP, LLC',
    label: 'MOKOSAK ADVISORY GROUP, LLC',
  },
  {
    cik: '0001475271',
    value: '0001475271,MOLLER FINANCIAL SERVICES',
    name: 'MOLLER FINANCIAL SERVICES',
    label: 'MOLLER FINANCIAL SERVICES',
  },
  {
    cik: '0001697934',
    value: '0001697934,MOLONEY SECURITIES ASSET MANAGEMENT, LLC',
    name: 'MOLONEY SECURITIES ASSET MANAGEMENT, LLC',
    label: 'MOLONEY SECURITIES ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001932998',
    value: '0001932998,MOMENTUM ADVISORS, LLC',
    name: 'MOMENTUM ADVISORS, LLC',
    label: 'MOMENTUM ADVISORS, LLC',
  },
  {
    cik: '0001803593',
    value: '0001803593,MONACO ASSET MANAGEMENT SAM',
    name: 'MONACO ASSET MANAGEMENT SAM',
    label: 'MONACO ASSET MANAGEMENT SAM',
  },
  {
    cik: '0001281084',
    value: '0001281084,MONARCH ALTERNATIVE CAPITAL LP',
    name: 'MONARCH ALTERNATIVE CAPITAL LP',
    label: 'MONARCH ALTERNATIVE CAPITAL LP',
  },
  {
    cik: '0000938759',
    value: '0000938759,MONARCH CAPITAL MANAGEMENT INC/',
    name: 'MONARCH CAPITAL MANAGEMENT INC/',
    label: 'MONARCH CAPITAL MANAGEMENT INC/',
  },
  {
    cik: '0001568555',
    value: '0001568555,MONARCH PARTNERS ASSET MANAGEMENT LLC',
    name: 'MONARCH PARTNERS ASSET MANAGEMENT LLC',
    label: 'MONARCH PARTNERS ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001627608',
    value: '0001627608,MONASHEE INVESTMENT MANAGEMENT LLC',
    name: 'MONASHEE INVESTMENT MANAGEMENT LLC',
    label: 'MONASHEE INVESTMENT MANAGEMENT LLC',
  },
  {
    cik: '0001708759',
    value: '0001708759,MONDRIAN CAPITAL MANAGEMENT, LLC',
    name: 'MONDRIAN CAPITAL MANAGEMENT, LLC',
    label: 'MONDRIAN CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001317724',
    value: '0001317724,MONDRIAN INVESTMENT PARTNERS LTD',
    name: 'MONDRIAN INVESTMENT PARTNERS LTD',
    label: 'MONDRIAN INVESTMENT PARTNERS LTD',
  },
  {
    cik: '0001765690',
    value: '0001765690,MONECO ADVISORS, LLC',
    name: 'MONECO ADVISORS, LLC',
    label: 'MONECO ADVISORS, LLC',
  },
  {
    cik: '0001536361',
    value: '0001536361,MONEDA S.A. ADMINISTRADORA GENERAL DE FONDOS',
    name: 'MONEDA S.A. ADMINISTRADORA GENERAL DE FONDOS',
    label: 'MONEDA S.A. ADMINISTRADORA GENERAL DE FONDOS',
  },
  {
    cik: '0001536362',
    value: '0001536362,MONEDA USA, INC.',
    name: 'MONEDA USA, INC.',
    label: 'MONEDA USA, INC.',
  },
  {
    cik: '0001120927',
    value: '0001120927,MONETA GROUP INVESTMENT ADVISORS LLC',
    name: 'MONETA GROUP INVESTMENT ADVISORS LLC',
    label: 'MONETA GROUP INVESTMENT ADVISORS LLC',
  },
  {
    cik: '0001133014',
    value: '0001133014,MONETARY MANAGEMENT GROUP INC',
    name: 'MONETARY MANAGEMENT GROUP INC',
    label: 'MONETARY MANAGEMENT GROUP INC',
  },
  {
    cik: '0000869179',
    value: '0000869179,MONETTA FINANCIAL SERVICES INC',
    name: 'MONETTA FINANCIAL SERVICES INC',
    label: 'MONETTA FINANCIAL SERVICES INC',
  },
  {
    cik: '0000716851',
    value: '0000716851,MONEY CONCEPTS CAPITAL CORP',
    name: 'MONEY CONCEPTS CAPITAL CORP',
    label: 'MONEY CONCEPTS CAPITAL CORP',
  },
  {
    cik: '0001721608',
    value: '0001721608,MONEY DESIGN CO.,LTD.',
    name: 'MONEY DESIGN CO.,LTD.',
    label: 'MONEY DESIGN CO.,LTD.',
  },
  {
    cik: '0001699803',
    value: '0001699803,MONEY MATTERS FINANCIAL GROUP INC',
    name: 'MONEY MATTERS FINANCIAL GROUP INC',
    label: 'MONEY MATTERS FINANCIAL GROUP INC',
  },
  {
    cik: '0001703080',
    value: '0001703080,MONEYWISE, INC.',
    name: 'MONEYWISE, INC.',
    label: 'MONEYWISE, INC.',
  },
  {
    cik: '0001776588',
    value: '0001776588,MONOGRAPH WEALTH ADVISORS, LLC',
    name: 'MONOGRAPH WEALTH ADVISORS, LLC',
    label: 'MONOGRAPH WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001904020',
    value: '0001904020,MONOLITH ADVISORS',
    name: 'MONOLITH ADVISORS',
    label: 'MONOLITH ADVISORS',
  },
  {
    cik: '0001075699',
    value: '0001075699,MONROE BANK & TRUST/MI',
    name: 'MONROE BANK & TRUST/MI',
    label: 'MONROE BANK & TRUST/MI',
  },
  {
    cik: '0001633676',
    value: '0001633676,MONROE HALL ASSET MANAGEMENT LLC',
    name: 'MONROE HALL ASSET MANAGEMENT LLC',
    label: 'MONROE HALL ASSET MANAGEMENT LLC',
  },
  {
    cik: '0000829407',
    value: '0000829407,MONTAG A & ASSOCIATES INC',
    name: 'MONTAG A & ASSOCIATES INC',
    label: 'MONTAG A & ASSOCIATES INC',
  },
  {
    cik: '0000067698',
    value: '0000067698,MONTAG & CALDWELL, LLC',
    name: 'MONTAG & CALDWELL, LLC',
    label: 'MONTAG & CALDWELL, LLC',
  },
  {
    cik: '0001483238',
    value: '0001483238,MONTAGE INVESTMENTS, LLC',
    name: 'MONTAGE INVESTMENTS, LLC',
    label: 'MONTAGE INVESTMENTS, LLC',
  },
  {
    cik: '0001831133',
    value: '0001831133,MONTANARO ASSET MANAGEMENT LTD',
    name: 'MONTANARO ASSET MANAGEMENT LTD',
    label: 'MONTANARO ASSET MANAGEMENT LTD',
  },
  {
    cik: '0001940660',
    value: '0001940660,MONTCHANIN ASSET MANAGEMENT, LLC',
    name: 'MONTCHANIN ASSET MANAGEMENT, LLC',
    label: 'MONTCHANIN ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001422508',
    value: '0001422508,MONTECITO BANK & TRUST',
    name: 'MONTECITO BANK & TRUST',
    label: 'MONTECITO BANK & TRUST',
  },
  {
    cik: '0001755651',
    value: '0001755651,MONTE FINANCIAL GROUP, LLC',
    name: 'MONTE FINANCIAL GROUP, LLC',
    label: 'MONTE FINANCIAL GROUP, LLC',
  },
  {
    cik: '0001839826',
    value: '0001839826,MONTEREY PRIVATE WEALTH, INC.',
    name: 'MONTEREY PRIVATE WEALTH, INC.',
    label: 'MONTEREY PRIVATE WEALTH, INC.',
  },
  {
    cik: '0001272544',
    value: '0001272544,MONTGOMERY INVESTMENT MANAGEMENT INC',
    name: 'MONTGOMERY INVESTMENT MANAGEMENT INC',
    label: 'MONTGOMERY INVESTMENT MANAGEMENT INC',
  },
  {
    cik: '0001791996',
    value: '0001791996,MONTICELLO WEALTH MANAGEMENT, LLC',
    name: 'MONTICELLO WEALTH MANAGEMENT, LLC',
    label: 'MONTICELLO WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001844314',
    value: '0001844314,MONTIS FINANCIAL, LLC',
    name: 'MONTIS FINANCIAL, LLC',
    label: 'MONTIS FINANCIAL, LLC',
  },
  {
    cik: '0001165880',
    value: '0001165880,MONTPELIER RE HOLDINGS LTD',
    name: 'MONTPELIER RE HOLDINGS LTD',
    label: 'MONTPELIER RE HOLDINGS LTD',
  },
  {
    cik: '0001475940',
    value: '0001475940,MONTRUSCO BOLTON INVESTMENTS INC.',
    name: 'MONTRUSCO BOLTON INVESTMENTS INC.',
    label: 'MONTRUSCO BOLTON INVESTMENTS INC.',
  },
  {
    cik: '0001918613',
    value: '0001918613,MONTZ HARCUS WEALTH MANAGEMENT LLC',
    name: 'MONTZ HARCUS WEALTH MANAGEMENT LLC',
    label: 'MONTZ HARCUS WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0001861798',
    value: '0001861798,MONUMENTAL FINANCIAL GROUP INC.',
    name: 'MONUMENTAL FINANCIAL GROUP INC.',
    label: 'MONUMENTAL FINANCIAL GROUP INC.',
  },
  {
    cik: '0001729428',
    value: '0001729428,MONUMENT CAPITAL MANAGEMENT',
    name: 'MONUMENT CAPITAL MANAGEMENT',
    label: 'MONUMENT CAPITAL MANAGEMENT',
  },
  {
    cik: '0000936941',
    value: '0000936941,MOODY ALDRICH PARTNERS LLC',
    name: 'MOODY ALDRICH PARTNERS LLC',
    label: 'MOODY ALDRICH PARTNERS LLC',
  },
  {
    cik: '0000938592',
    value: '0000938592,MOODY LYNN & LIEBERSON, INC.',
    name: 'MOODY LYNN & LIEBERSON, INC.',
    label: 'MOODY LYNN & LIEBERSON, INC.',
  },
  {
    cik: '0000938592',
    value: '0000938592,MOODY LYNN & LIEBERSON, LLC',
    name: 'MOODY LYNN & LIEBERSON, LLC',
    label: 'MOODY LYNN & LIEBERSON, LLC',
  },
  {
    cik: '0001092903',
    value: '0001092903,MOODY NATIONAL BANK TRUST DIVISION',
    name: 'MOODY NATIONAL BANK TRUST DIVISION',
    label: 'MOODY NATIONAL BANK TRUST DIVISION',
  },
  {
    cik: '0001410588',
    value: '0001410588,MOON CAPITAL MANAGEMENT, LLC',
    name: 'MOON CAPITAL MANAGEMENT, LLC',
    label: 'MOON CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001387303',
    value: '0001387303,MOON CAPITAL MANAGEMENT LP',
    name: 'MOON CAPITAL MANAGEMENT LP',
    label: 'MOON CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001448574',
    value: '0001448574,MOORE CAPITAL MANAGEMENT, LP',
    name: 'MOORE CAPITAL MANAGEMENT, LP',
    label: 'MOORE CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0001607239',
    value: '0001607239,MOORS & CABOT, INC.',
    name: 'MOORS & CABOT, INC.',
    label: 'MOORS & CABOT, INC.',
  },
  {
    cik: '0001598011',
    value: '0001598011,MORENO EVELYN V',
    name: 'MORENO EVELYN V',
    label: 'MORENO EVELYN V',
  },
  {
    cik: '0001050463',
    value: '0001050463,MORGAN DEMPSEY CAPITAL MANAGEMENT LLC',
    name: 'MORGAN DEMPSEY CAPITAL MANAGEMENT LLC',
    label: 'MORGAN DEMPSEY CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001133335',
    value: '0001133335,MORGAN JESS S & CO INC',
    name: 'MORGAN JESS S & CO INC',
    label: 'MORGAN JESS S & CO INC',
  },
  {
    cik: '0001753218',
    value: '0001753218,MORGANROSEL WEALTH MANAGEMENT, LLC',
    name: 'MORGANROSEL WEALTH MANAGEMENT, LLC',
    label: 'MORGANROSEL WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0000895421',
    value: '0000895421,MORGAN STANLEY',
    name: 'MORGAN STANLEY',
    label: 'MORGAN STANLEY',
  },
  {
    cik: '0001016150',
    value: '0001016150,MORGENS WATERFALL VINTIADIS & CO INC',
    name: 'MORGENS WATERFALL VINTIADIS & CO INC',
    label: 'MORGENS WATERFALL VINTIADIS & CO INC',
  },
  {
    cik: '0001666860',
    value: '0001666860,MORGENTHALER MANAGEMENT PARTNERS IX, LLC',
    name: 'MORGENTHALER MANAGEMENT PARTNERS IX, LLC',
    label: 'MORGENTHALER MANAGEMENT PARTNERS IX, LLC',
  },
  {
    cik: '0001626494',
    value: '0001626494,MORK CAPITAL MANAGEMENT',
    name: 'MORK CAPITAL MANAGEMENT',
    label: 'MORK CAPITAL MANAGEMENT',
  },
  {
    cik: '0001626494',
    value: '0001626494,MORK CAPITAL MANAGEMENT, LLC',
    name: 'MORK CAPITAL MANAGEMENT, LLC',
    label: 'MORK CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001905198',
    value: '0001905198,MORLING FINANCIAL ADVISORS, LLC',
    name: 'MORLING FINANCIAL ADVISORS, LLC',
    label: 'MORLING FINANCIAL ADVISORS, LLC',
  },
  {
    cik: '0001673385',
    value: '0001673385,MORNINGSTAR INVESTMENT MANAGEMENT LLC',
    name: 'MORNINGSTAR INVESTMENT MANAGEMENT LLC',
    label: 'MORNINGSTAR INVESTMENT MANAGEMENT LLC',
  },
  {
    cik: '0001478215',
    value: '0001478215,MORNINGSTAR INVESTMENT SERVICES LLC',
    name: 'MORNINGSTAR INVESTMENT SERVICES LLC',
    label: 'MORNINGSTAR INVESTMENT SERVICES LLC',
  },
  {
    cik: '0001673922',
    value: '0001673922,MORRIS CAPITAL ADVISORS, LLC',
    name: 'MORRIS CAPITAL ADVISORS, LLC',
    label: 'MORRIS CAPITAL ADVISORS, LLC',
  },
  {
    cik: '0001387386',
    value: '0001387386,MORRIS FINANCIAL CONCEPTS, INC.',
    name: 'MORRIS FINANCIAL CONCEPTS, INC.',
    label: 'MORRIS FINANCIAL CONCEPTS, INC.',
  },
  {
    cik: '0001841816',
    value: '0001841816,MORRIS RETIREMENT ADVISORS, LLC',
    name: 'MORRIS RETIREMENT ADVISORS, LLC',
    label: 'MORRIS RETIREMENT ADVISORS, LLC',
  },
  {
    cik: '0001705711',
    value: '0001705711,MORSE ASSET MANAGEMENT, INC',
    name: 'MORSE ASSET MANAGEMENT, INC',
    label: 'MORSE ASSET MANAGEMENT, INC',
  },
  {
    cik: '0001909851',
    value: '0001909851,MORTON BROWN FAMILY WEALTH, LLC',
    name: 'MORTON BROWN FAMILY WEALTH, LLC',
    label: 'MORTON BROWN FAMILY WEALTH, LLC',
  },
  {
    cik: '0001808523',
    value: '0001808523,MOSAIC ADVISORS LLC',
    name: 'MOSAIC ADVISORS LLC',
    label: 'MOSAIC ADVISORS LLC',
  },
  {
    cik: '0001674343',
    value: '0001674343,MOSAIC FAMILY WEALTH, LLC',
    name: 'MOSAIC FAMILY WEALTH, LLC',
    label: 'MOSAIC FAMILY WEALTH, LLC',
  },
  {
    cik: '0001513122',
    value: '0001513122,MOSAIC FINANCIAL PARTNERS, INC.',
    name: 'MOSAIC FINANCIAL PARTNERS, INC.',
    label: 'MOSAIC FINANCIAL PARTNERS, INC.',
  },
  {
    cik: '0000906396',
    value: '0000906396,MOSELEY INVESTMENT MANAGEMENT INC',
    name: 'MOSELEY INVESTMENT MANAGEMENT INC',
    label: 'MOSELEY INVESTMENT MANAGEMENT INC',
  },
  {
    cik: '0001803081',
    value: '0001803081,MOSER WEALTH ADVISORS, LLC',
    name: 'MOSER WEALTH ADVISORS, LLC',
    label: 'MOSER WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001306333',
    value: '0001,MOTCO6333',
    name: 'MOTCO',
    label: 'MOTCO',
  },
  {
    cik: '0001845783',
    value: '0001845783,MOTIVE CAPITAL FUND I GP, LP',
    name: 'MOTIVE CAPITAL FUND I GP, LP',
    label: 'MOTIVE CAPITAL FUND I GP, LP',
  },
  {
    cik: '0001877093',
    value: '0001877093,MOTIVE WEALTH ADVISORS',
    name: 'MOTIVE WEALTH ADVISORS',
    label: 'MOTIVE WEALTH ADVISORS',
  },
  {
    cik: '0001512814',
    value: '0001512814,MOTLEY FOOL ASSET MANAGEMENT LLC',
    name: 'MOTLEY FOOL ASSET MANAGEMENT LLC',
    label: 'MOTLEY FOOL ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001607978',
    value: '0001607978,MOTLEY FOOL WEALTH MANAGEMENT, LLC',
    name: 'MOTLEY FOOL WEALTH MANAGEMENT, LLC',
    label: 'MOTLEY FOOL WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001792510',
    value: '0001792510,MOULTON WEALTH MANAGEMENT, INC',
    name: 'MOULTON WEALTH MANAGEMENT, INC',
    label: 'MOULTON WEALTH MANAGEMENT, INC',
  },
  {
    cik: '0001714506',
    value: '0001714506,MOUNTAIN CAPITAL INVESTMENT ADVISORS, INC',
    name: 'MOUNTAIN CAPITAL INVESTMENT ADVISORS, INC',
    label: 'MOUNTAIN CAPITAL INVESTMENT ADVISORS, INC',
  },
  {
    cik: '0001730532',
    value: '0001730532,MOUNTAINEER PARTNERS MANAGEMENT, LLC',
    name: 'MOUNTAINEER PARTNERS MANAGEMENT, LLC',
    label: 'MOUNTAINEER PARTNERS MANAGEMENT, LLC',
  },
  {
    cik: '0001308555',
    value: '0001308555,MOUNTAIN LAKE INVESTMENT MANAGEMENT LLC',
    name: 'MOUNTAIN LAKE INVESTMENT MANAGEMENT LLC',
    label: 'MOUNTAIN LAKE INVESTMENT MANAGEMENT LLC',
  },
  {
    cik: '0001067324',
    value: '0001067324,MOUNTAIN PACIFIC INVESTMENT ADVISERS INC/ID',
    name: 'MOUNTAIN PACIFIC INVESTMENT ADVISERS INC/ID',
    label: 'MOUNTAIN PACIFIC INVESTMENT ADVISERS INC/ID',
  },
  {
    cik: '0001753564',
    value: '0001753564,MOUNTAIN ROAD ADVISORS, LLC',
    name: 'MOUNTAIN ROAD ADVISORS, LLC',
    label: 'MOUNTAIN ROAD ADVISORS, LLC',
  },
  {
    cik: '0001896379',
    value: '0001896379,MOUNT CAPITAL LTD',
    name: 'MOUNT CAPITAL LTD',
    label: 'MOUNT CAPITAL LTD',
  },
  {
    cik: '0001479058',
    value: '0001479058,MOUNT KELLETT CAPITAL MANAGEMENT LP',
    name: 'MOUNT KELLETT CAPITAL MANAGEMENT LP',
    label: 'MOUNT KELLETT CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001185072',
    value: '0001185072,MOUNT LUCAS MANAGEMENT LP',
    name: 'MOUNT LUCAS MANAGEMENT LP',
    label: 'MOUNT LUCAS MANAGEMENT LP',
  },
  {
    cik: '0000799003',
    value: '0000799003,MOUNT VERNON ASSOCIATES INC /MD/',
    name: 'MOUNT VERNON ASSOCIATES INC /MD/',
    label: 'MOUNT VERNON ASSOCIATES INC /MD/',
  },
  {
    cik: '0001744349',
    value: '0001744349,MOUNT YALE INVESTMENT ADVISORS, LLC',
    name: 'MOUNT YALE INVESTMENT ADVISORS, LLC',
    label: 'MOUNT YALE INVESTMENT ADVISORS, LLC',
  },
  {
    cik: '0001624692',
    value: '0001624692,MPAM CREDIT TRADING PARTNERS L.P.',
    name: 'MPAM CREDIT TRADING PARTNERS L.P.',
    label: 'MPAM CREDIT TRADING PARTNERS L.P.',
  },
  {
    cik: '0001730084',
    value: '0001730084,MPI INVESTMENT MANAGEMENT, INC.',
    name: 'MPI INVESTMENT MANAGEMENT, INC.',
    label: 'MPI INVESTMENT MANAGEMENT, INC.',
  },
  {
    cik: '0001263048',
    value: '0001263048,MPM ASSET MANAGEMENT LLC',
    name: 'MPM ASSET MANAGEMENT LLC',
    label: 'MPM ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001687078',
    value: '0001687078,MPM ONCOLOGY IMPACT MANAGEMENT LP',
    name: 'MPM ONCOLOGY IMPACT MANAGEMENT LP',
    label: 'MPM ONCOLOGY IMPACT MANAGEMENT LP',
  },
  {
    cik: '0001716180',
    value: '0001716180,MPS LORIA FINANCIAL PLANNERS, LLC',
    name: 'MPS LORIA FINANCIAL PLANNERS, LLC',
    label: 'MPS LORIA FINANCIAL PLANNERS, LLC',
  },
  {
    cik: '0001483226',
    value: '0001483226,MP (THRIFT) GLOBAL ADVISERS III LLC',
    name: 'MP (THRIFT) GLOBAL ADVISERS III LLC',
    label: 'MP (THRIFT) GLOBAL ADVISERS III LLC',
  },
  {
    cik: '0001759395',
    value: '0001759395,MQS MANAGEMENT LLC',
    name: 'MQS MANAGEMENT LLC',
    label: 'MQS MANAGEMENT LLC',
  },
  {
    cik: '0001587375',
    value: '0001587375,MRA ASSOCIATES USA LLC',
    name: 'MRA ASSOCIATES USA LLC',
    label: 'MRA ASSOCIATES USA LLC',
  },
  {
    cik: '0001542324',
    value: '0001542324,MRAZ, AMERINE & ASSOCIATES, INC.',
    name: 'MRAZ, AMERINE & ASSOCIATES, INC.',
    label: 'MRAZ, AMERINE & ASSOCIATES, INC.',
  },
  {
    cik: '0001057395',
    value: '0001057395,M&R CAPITAL MANAGEMENT INC',
    name: 'M&R CAPITAL MANAGEMENT INC',
    label: 'M&R CAPITAL MANAGEMENT INC',
  },
  {
    cik: '0001616024',
    value: '0001616024,M&R FOLLOW-ON FUND MANAGEMENT, LP',
    name: 'M&R FOLLOW-ON FUND MANAGEMENT, LP',
    label: 'M&R FOLLOW-ON FUND MANAGEMENT, LP',
  },
  {
    cik: '0001160099',
    value: '0001160099,MRJ CAPITAL INC',
    name: 'MRJ CAPITAL INC',
    label: 'MRJ CAPITAL INC',
  },
  {
    cik: '0001105497',
    value: '0001105497,MSD CAPITAL L P',
    name: 'MSD CAPITAL L P',
    label: 'MSD CAPITAL L P',
  },
  {
    cik: '0001514334',
    value: '0001514334,MSD PARTNERS, L.P.',
    name: 'MSD PARTNERS, L.P.',
    label: 'MSD PARTNERS, L.P.',
  },
  {
    cik: '0001043853',
    value: '0001043853,MSF CAPITAL, LLC',
    name: 'MSF CAPITAL, LLC',
    label: 'MSF CAPITAL, LLC',
  },
  {
    cik: '0001422771',
    value: '0001422771,MSI FINANCIAL SERVICES INC',
    name: 'MSI FINANCIAL SERVICES INC',
    label: 'MSI FINANCIAL SERVICES INC',
  },
  {
    cik: '0000036270',
    value: '0000036270,M&T BANK CORP',
    name: 'M&T BANK CORP',
    label: 'M&T BANK CORP',
  },
  {
    cik: '0001821455',
    value: '0001821455,MTC WEALTH MANAGEMENT LLC',
    name: 'MTC WEALTH MANAGEMENT LLC',
    label: 'MTC WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0001831416',
    value: '0001831416,MTM INVESTMENT MANAGEMENT, LLC',
    name: 'MTM INVESTMENT MANAGEMENT, LLC',
    label: 'MTM INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001907809',
    value: '0001907809,MUDITA ADVISORS LLP',
    name: 'MUDITA ADVISORS LLP',
    label: 'MUDITA ADVISORS LLP',
  },
  {
    cik: '0001655183',
    value: '0001655183,MUDRICK CAPITAL MANAGEMENT, L.P.',
    name: 'MUDRICK CAPITAL MANAGEMENT, L.P.',
    label: 'MUDRICK CAPITAL MANAGEMENT, L.P.',
  },
  {
    cik: '0001011659',
    value: '0001011659,MUFG AMERICAS HOLDINGS CORP',
    name: 'MUFG AMERICAS HOLDINGS CORP',
    label: 'MUFG AMERICAS HOLDINGS CORP',
  },
  {
    cik: '0000852743',
    value: '0000852743,MUFG BANK, LTD.',
    name: 'MUFG BANK, LTD.',
    label: 'MUFG BANK, LTD.',
  },
  {
    cik: '0001656187',
    value: '0001656187,MUFG SECURITIES (CANADA), LTD.',
    name: 'MUFG SECURITIES (CANADA), LTD.',
    label: 'MUFG SECURITIES (CANADA), LTD.',
  },
  {
    cik: '0001597694',
    value: '0001597694,MUFG SECURITIES EMEA PLC',
    name: 'MUFG SECURITIES EMEA PLC',
    label: 'MUFG SECURITIES EMEA PLC',
  },
  {
    cik: '0001133219',
    value: '0001133219,MUHLENKAMP & CO INC',
    name: 'MUHLENKAMP & CO INC',
    label: 'MUHLENKAMP & CO INC',
  },
  {
    cik: '0001473438',
    value: '0001473438,MU INVESTMENTS CO., LTD.',
    name: 'MU INVESTMENTS CO., LTD.',
    label: 'MU INVESTMENTS CO., LTD.',
  },
  {
    cik: '0000806260',
    value: '0000806260,MUNDER CAPITAL MANAGEMENT',
    name: 'MUNDER CAPITAL MANAGEMENT',
    label: 'MUNDER CAPITAL MANAGEMENT',
  },
  {
    cik: '0001588873',
    value: '0001588873,MUNICIPAL EMPLOYEES RETIREMENT SYSTEM OF MICHIGAN',
    name: "MUNICIPAL EMPLOYEES' RETIREMENT SYSTEM OF MICHIGAN",
    label: "MUNICIPAL EMPLOYEES' RETIREMENT SYSTEM OF MICHIGAN",
  },
  {
    cik: '0001838556',
    value: '0001838556,MURCHINSON LTD.',
    name: 'MURCHINSON LTD.',
    label: 'MURCHINSON LTD.',
  },
  {
    cik: '0001056515',
    value: '0001056515,MURPHY CAPITAL MANAGEMENT INC',
    name: 'MURPHY CAPITAL MANAGEMENT INC',
    label: 'MURPHY CAPITAL MANAGEMENT INC',
  },
  {
    cik: '0001323510',
    value: '0001323510,MURPHY MICHAEL R',
    name: 'MURPHY MICHAEL R',
    label: 'MURPHY MICHAEL R',
  },
  {
    cik: '0001730521',
    value: '0001730521,MURPHY, MIDDLETON, HINKLE & PARKER, INC.',
    name: 'MURPHY, MIDDLETON, HINKLE & PARKER, INC.',
    label: 'MURPHY, MIDDLETON, HINKLE & PARKER, INC.',
  },
  {
    cik: '0001569148',
    value: '0001569148,MURPHY POHLAD ASSET MANAGEMENT LLC',
    name: 'MURPHY POHLAD ASSET MANAGEMENT LLC',
    label: 'MURPHY POHLAD ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001061170',
    value: '0001061170,MUSE JOHN R',
    name: 'MUSE JOHN R',
    label: 'MUSE JOHN R',
  },
  {
    cik: '0001806987',
    value: '0001806987,MUST ASSET MANAGEMENT INC.',
    name: 'MUST ASSET MANAGEMENT INC.',
    label: 'MUST ASSET MANAGEMENT INC.',
  },
  {
    cik: '0001694080',
    value: '0001694080,MUTUAL ADVISORS, LLC',
    name: 'MUTUAL ADVISORS, LLC',
    label: 'MUTUAL ADVISORS, LLC',
  },
  {
    cik: '0000922127',
    value: '0000922127,MUTUAL OF AMERICA CAPITAL MANAGEMENT CORP',
    name: 'MUTUAL OF AMERICA CAPITAL MANAGEMENT CORP',
    label: 'MUTUAL OF AMERICA CAPITAL MANAGEMENT CORP',
  },
  {
    cik: '0000922127',
    value: '0000922127,MUTUAL OF AMERICA CAPITAL MANAGEMENT LLC',
    name: 'MUTUAL OF AMERICA CAPITAL MANAGEMENT LLC',
    label: 'MUTUAL OF AMERICA CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001478417',
    value: '0001478417,MUTUAL OF OMAHA BANK WEALTH MANAGEMENT',
    name: 'MUTUAL OF OMAHA BANK WEALTH MANAGEMENT',
    label: 'MUTUAL OF OMAHA BANK WEALTH MANAGEMENT',
  },
  {
    cik: '0001618824',
    value: '0001618824,MUZINICH & CO., INC.',
    name: 'MUZINICH & CO., INC.',
    label: 'MUZINICH & CO., INC.',
  },
  {
    cik: '0001633366',
    value: '0001633366,MV CAPITAL MANAGEMENT, INC.',
    name: 'MV CAPITAL MANAGEMENT, INC.',
    label: 'MV CAPITAL MANAGEMENT, INC.',
  },
  {
    cik: '0001503556',
    value: '0001503556,MV MANAGEMENT XI, L.L.C.',
    name: 'MV MANAGEMENT XI, L.L.C.',
    label: 'MV MANAGEMENT XI, L.L.C.',
  },
  {
    cik: '0001547584',
    value: '000154758,MWG GP LLC',
    name: 'MWG GP LLC',
    label: 'MWG GP LLC',
  },
  {
    cik: '0001845151',
    value: '0001845151,MWG MANAGEMENT LTD.',
    name: 'MWG MANAGEMENT LTD.',
    label: 'MWG MANAGEMENT LTD.',
  },
  {
    cik: '0001910173',
    value: '0001910173,MY.ALPHA MANAGEMENT HK ADVISORS LTD',
    name: 'MY.ALPHA MANAGEMENT HK ADVISORS LTD',
    label: 'MY.ALPHA MANAGEMENT HK ADVISORS LTD',
  },
  {
    cik: '0001371726',
    value: '0001371726,MYCIO WEALTH PARTNERS, LLC',
    name: 'MYCIO WEALTH PARTNERS, LLC',
    label: 'MYCIO WEALTH PARTNERS, LLC',
  },
  {
    cik: '0001589689',
    value: '0001589689,MYDA ADVISORS LLC',
    name: 'MYDA ADVISORS LLC',
    label: 'MYDA ADVISORS LLC',
  },
  {
    cik: '0001767802',
    value: '0001767802,MY LEGACY ADVISORS, LLC',
    name: 'MY LEGACY ADVISORS, LLC',
    label: 'MY LEGACY ADVISORS, LLC',
  },
  {
    cik: '0001916366',
    value: '0001916366,MY PERSONAL CFO, LLC',
    name: 'MY PERSONAL CFO, LLC',
    label: 'MY PERSONAL CFO, LLC',
  },
  {
    cik: '0001541349',
    value: '0001541349,MYRIAD ASSET MANAGEMENT LTD.',
    name: 'MYRIAD ASSET MANAGEMENT LTD.',
    label: 'MYRIAD ASSET MANAGEMENT LTD.',
  },
  {
    cik: '0001857187',
    value: '0001857187,MYRIAD ASSET MANAGEMENT US LP',
    name: 'MYRIAD ASSET MANAGEMENT US LP',
    label: 'MYRIAD ASSET MANAGEMENT US LP',
  },
  {
    cik: '0001912460',
    value: '0001912460,MYSTIC ASSET MANAGEMENT, INC.',
    name: 'MYSTIC ASSET MANAGEMENT, INC.',
    label: 'MYSTIC ASSET MANAGEMENT, INC.',
  },
  {
    cik: '0001827845',
    value: '0001827845,MY WEALTH MANAGEMENT INC.',
    name: 'MY WEALTH MANAGEMENT INC.',
    label: 'MY WEALTH MANAGEMENT INC.',
  },
  {
    cik: '0001754950',
    value: '0001754950,NACHMAN NORWOOD & PARROTT, INC',
    name: 'NACHMAN NORWOOD & PARROTT, INC',
    label: 'NACHMAN NORWOOD & PARROTT, INC',
  },
  {
    cik: '0001541787',
    value: '0001541787,NADLER FINANCIAL GROUP, INC.',
    name: 'NADLER FINANCIAL GROUP, INC.',
    label: 'NADLER FINANCIAL GROUP, INC.',
  },
  {
    cik: '0001632680',
    value: '000163268,NAIR VINAY',
    name: 'NAIR VINAY',
    label: 'NAIR VINAY',
  },
  {
    cik: '0001622904',
    value: '0001622904,NALANDA INDIA EQUITY FUND LTD',
    name: 'NALANDA INDIA EQUITY FUND LTD',
    label: 'NALANDA INDIA EQUITY FUND LTD',
  },
  {
    cik: '0001430193',
    value: '0001430193,NALANDA INDIA FUND LTD',
    name: 'NALANDA INDIA FUND LTD',
    label: 'NALANDA INDIA FUND LTD',
  },
  {
    cik: '0001767500',
    value: '0001767500,NALLS SHERBAKOFF GROUP, LLC',
    name: 'NALLS SHERBAKOFF GROUP, LLC',
    label: 'NALLS SHERBAKOFF GROUP, LLC',
  },
  {
    cik: '0001782539',
    value: '0001782539,NAN FUNG GROUP HOLDINGS LTD',
    name: 'NAN FUNG GROUP HOLDINGS LTD',
    label: 'NAN FUNG GROUP HOLDINGS LTD',
  },
  {
    cik: '0001781880',
    value: '0001781880,NAN FUNG TRINITY (HK) LTD',
    name: 'NAN FUNG TRINITY (HK) LTD',
    label: 'NAN FUNG TRINITY (HK) LTD',
  },
  {
    cik: '0001912801',
    value: '0001912801,NANODIMENSION, INC.',
    name: 'NANODIMENSION, INC.',
    label: 'NANODIMENSION, INC.',
  },
  {
    cik: '0001730464',
    value: '0001730464,NAN SHAN LIFE INSURANCE CO., LTD.',
    name: 'NAN SHAN LIFE INSURANCE CO., LTD.',
    label: 'NAN SHAN LIFE INSURANCE CO., LTD.',
  },
  {
    cik: '0001472322',
    value: '0001472322,NANTAHALA CAPITAL MANAGEMENT, LLC',
    name: 'NANTAHALA CAPITAL MANAGEMENT, LLC',
    label: 'NANTAHALA CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001595124',
    value: '0001595124,NAPIER PARK GLOBAL CAPITAL (US) LP',
    name: 'NAPIER PARK GLOBAL CAPITAL (US) LP',
    label: 'NAPIER PARK GLOBAL CAPITAL (US) LP',
  },
  {
    cik: '0001582151',
    value: '0001582151,NAPLES GLOBAL ADVISORS, LLC',
    name: 'NAPLES GLOBAL ADVISORS, LLC',
    label: 'NAPLES GLOBAL ADVISORS, LLC',
  },
  {
    cik: '0001698091',
    value: '0001698091,NARUS FINANCIAL PARTNERS, LLC',
    name: 'NARUS FINANCIAL PARTNERS, LLC',
    label: 'NARUS FINANCIAL PARTNERS, LLC',
  },
  {
    cik: '0001461287',
    value: '0001461287,NARWHAL CAPITAL MANAGEMENT',
    name: 'NARWHAL CAPITAL MANAGEMENT',
    label: 'NARWHAL CAPITAL MANAGEMENT',
  },
  {
    cik: '0001106051',
    value: '0001106051,NASPERS LTD',
    name: 'NASPERS LTD',
    label: 'NASPERS LTD',
  },
  {
    cik: '0001464811',
    value: '0001464811,NATIONAL ASSET MANAGEMENT, INC.',
    name: 'NATIONAL ASSET MANAGEMENT, INC.',
    label: 'NATIONAL ASSET MANAGEMENT, INC.',
  },
  {
    cik: '0000926171',
    value: '0000926171,NATIONAL BANK OF CANADA /FI/',
    name: 'NATIONAL BANK OF CANADA /FI/',
    label: 'NATIONAL BANK OF CANADA /FI/',
  },
  {
    cik: '0001167348',
    value: '0001167348,NATIONAL INDEPENDENT TRUST CO',
    name: 'NATIONAL INDEPENDENT TRUST CO',
    label: 'NATIONAL INDEPENDENT TRUST CO',
  },
  {
    cik: '0000939791',
    value: '0000939791,NATIONAL INVESTMENT SERVICES INC /WI',
    name: 'NATIONAL INVESTMENT SERVICES INC /WI',
    label: 'NATIONAL INVESTMENT SERVICES INC /WI',
  },
  {
    cik: '0001800336',
    value: '0001800336,NATIONAL INVESTMENT SERVICES OF AMERICA, LLC',
    name: 'NATIONAL INVESTMENT SERVICES OF AMERICA, LLC',
    label: 'NATIONAL INVESTMENT SERVICES OF AMERICA, LLC',
  },
  {
    cik: '0001542927',
    value:
      '0001542927,NATIONAL MUTUAL INSURANCE FEDERATION OF AGRICULTURAL COOPERATIVES',
    name: 'NATIONAL MUTUAL INSURANCE FEDERATION OF AGRICULTURAL COOPERATIVES',
    label: 'NATIONAL MUTUAL INSURANCE FEDERATION OF AGRICULTURAL COOPERATIVES',
  },
  {
    cik: '0001007095',
    value: '0001007095,NATIONAL PENN INVESTORS TRUST CO /PA',
    name: 'NATIONAL PENN INVESTORS TRUST CO /PA',
    label: 'NATIONAL PENN INVESTORS TRUST CO /PA',
  },
  {
    cik: '0001608046',
    value: '0001608046,NATIONAL PENSION SERVICE',
    name: 'NATIONAL PENSION SERVICE',
    label: 'NATIONAL PENSION SERVICE',
  },
  {
    cik: '0001492262',
    value: '0001492262,NATIONAL PLANNING CORP',
    name: 'NATIONAL PLANNING CORP',
    label: 'NATIONAL PLANNING CORP',
  },
  {
    cik: '0000833018',
    value: '0000833018,NATIONAL RURAL ELECTRIC COOPERATIVE ASSOCIATION',
    name: 'NATIONAL RURAL ELECTRIC COOPERATIVE ASSOCIATION',
    label: 'NATIONAL RURAL ELECTRIC COOPERATIVE ASSOCIATION',
  },
  {
    cik: '0001109767',
    value: '0001109767,NATIONS FINANCIAL GROUP INC, /IA/ /ADV',
    name: 'NATIONS FINANCIAL GROUP INC, /IA/ /ADV',
    label: 'NATIONS FINANCIAL GROUP INC, /IA/ /ADV',
  },
  {
    cik: '0001802257',
    value: '0001802257,NATIONWIDE ASSET MANAGEMENT LLC',
    name: 'NATIONWIDE ASSET MANAGEMENT LLC',
    label: 'NATIONWIDE ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001097218',
    value: '0001097218,NATIONWIDE FUND ADVISORS',
    name: 'NATIONWIDE FUND ADVISORS',
    label: 'NATIONWIDE FUND ADVISORS',
  },
  {
    cik: '0001274981',
    value: '000127,NATIXIS',
    name: 'NATIXIS',
    label: 'NATIXIS',
  },
  {
    cik: '0001018331',
    value: '0001018331,NATIXIS ADVISORS, L.P.',
    name: 'NATIXIS ADVISORS, L.P.',
    label: 'NATIXIS ADVISORS, L.P.',
  },
  {
    cik: '0001166767',
    value: '0001166767,NATIXIS ASSET MANAGEMENT',
    name: 'NATIXIS ASSET MANAGEMENT',
    label: 'NATIXIS ASSET MANAGEMENT',
  },
  {
    cik: '0001166767',
    value: '0001166767,NATIXIS INVESTMENT MANAGERS INTERNATIONAL',
    name: 'NATIXIS INVESTMENT MANAGERS INTERNATIONAL',
    label: 'NATIXIS INVESTMENT MANAGERS INTERNATIONAL',
  },
  {
    cik: '0001800245',
    value: '0001800245,NATURAL INVESTMENTS, LLC',
    name: 'NATURAL INVESTMENTS, LLC',
    label: 'NATURAL INVESTMENTS, LLC',
  },
  {
    cik: '0000844150',
    value: '0000844150,NATWEST GROUP PLC',
    name: 'NATWEST GROUP PLC',
    label: 'NATWEST GROUP PLC',
  },
  {
    cik: '0001909759',
    value: '0001909759,NAUSET WEALTH MANAGEMENT. LLC',
    name: 'NAUSET WEALTH MANAGEMENT. LLC',
    label: 'NAUSET WEALTH MANAGEMENT. LLC',
  },
  {
    cik: '0001820593',
    value: '0001820593,NAVALIGN, LLC',
    name: 'NAVALIGN, LLC',
    label: 'NAVALIGN, LLC',
  },
  {
    cik: '0000872163',
    value: '0000872163,NAVELLIER & ASSOCIATES INC',
    name: 'NAVELLIER & ASSOCIATES INC',
    label: 'NAVELLIER & ASSOCIATES INC',
  },
  {
    cik: '0001839930',
    value: '0001839930,NAVIGATION WEALTH MANAGEMENT, INC.',
    name: 'NAVIGATION WEALTH MANAGEMENT, INC.',
    label: 'NAVIGATION WEALTH MANAGEMENT, INC.',
  },
  {
    cik: '0001849055',
    value: '0001849055,NAVIS WEALTH ADVISORS, LLC',
    name: 'NAVIS WEALTH ADVISORS, LLC',
    label: 'NAVIS WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001855567',
    value: '0001855567,NAVITER WEALTH, LLC',
    name: 'NAVITER WEALTH, LLC',
    label: 'NAVITER WEALTH, LLC',
  },
  {
    cik: '0001665012',
    value: '0001665012,NAYA CAPITAL MANAGEMENT UK LTD',
    name: 'NAYA CAPITAL MANAGEMENT UK LTD',
    label: 'NAYA CAPITAL MANAGEMENT UK LTD',
  },
  {
    cik: '0001598015',
    value: '0001598015,NAYA MANAGEMENT LLP',
    name: 'NAYA MANAGEMENT LLP',
    label: 'NAYA MANAGEMENT LLP',
  },
  {
    cik: '0000898413',
    value: '0000898413,NBT BANK N A /NY',
    name: 'NBT BANK N A /NY',
    label: 'NBT BANK N A /NY',
  },
  {
    cik: '0001401650',
    value: '0001401650,NBVM GP, LLC',
    name: 'NBVM GP, LLC',
    label: 'NBVM GP, LLC',
  },
  {
    cik: '0001512901',
    value: '0001512901,NBW CAPITAL LLC',
    name: 'NBW CAPITAL LLC',
    label: 'NBW CAPITAL LLC',
  },
  {
    cik: '0001353315',
    value: '0001353315,NCM CAPITAL ADVISERS, INC.',
    name: 'NCM CAPITAL ADVISERS, INC.',
    label: 'NCM CAPITAL ADVISERS, INC.',
  },
  {
    cik: '0000857113',
    value: '0000857113,NCM CAPITAL MANAGEMENT GROUP INC',
    name: 'NCM CAPITAL MANAGEMENT GROUP INC',
    label: 'NCM CAPITAL MANAGEMENT GROUP INC',
  },
  {
    cik: '0001907054',
    value: '0001907054,NCM CAPITAL MANAGEMENT, LLC',
    name: 'NCM CAPITAL MANAGEMENT, LLC',
    label: 'NCM CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001512848',
    value: '0001512848,NEA MANAGEMENT COMPANY, LLC',
    name: 'NEA MANAGEMENT COMPANY, LLC',
    label: 'NEA MANAGEMENT COMPANY, LLC',
  },
  {
    cik: '0001544744',
    value: '0001544744,NEBULA CAPITAL MANAGEMENT, LP',
    name: 'NEBULA CAPITAL MANAGEMENT, LP',
    label: 'NEBULA CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0000783827',
    value: '0000783827,NEEDELMAN ASSET MANAGEMENT INC',
    name: 'NEEDELMAN ASSET MANAGEMENT INC',
    label: 'NEEDELMAN ASSET MANAGEMENT INC',
  },
  {
    cik: '0001167212',
    value: '0001167212,NEEDHAM INVESTMENT MANAGEMENT LLC',
    name: 'NEEDHAM INVESTMENT MANAGEMENT LLC',
    label: 'NEEDHAM INVESTMENT MANAGEMENT LLC',
  },
  {
    cik: '0001909567',
    value: '0001909567,NEIRG WEALTH MANAGEMENT, LLC',
    name: 'NEIRG WEALTH MANAGEMENT, LLC',
    label: 'NEIRG WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001884716',
    value: '0001884716,NEKTON CAPITAL LTD.',
    name: 'NEKTON CAPITAL LTD.',
    label: 'NEKTON CAPITAL LTD.',
  },
  {
    cik: '0001846677',
    value: '0001846677,NELLORE CAPITAL MANAGEMENT LLC',
    name: 'NELLORE CAPITAL MANAGEMENT LLC',
    label: 'NELLORE CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001512611',
    value: '0001512611,NELSON CAPITAL MANAGEMENT, LLC',
    name: 'NELSON CAPITAL MANAGEMENT, LLC',
    label: 'NELSON CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001599868',
    value:
      '0001599868,NELSON, VAN DENBURG & CAMPBELL WEALTH MANAGEMENT GROUP, LLC',
    name: 'NELSON, VAN DENBURG & CAMPBELL WEALTH MANAGEMENT GROUP, LLC',
    label: 'NELSON, VAN DENBURG & CAMPBELL WEALTH MANAGEMENT GROUP, LLC',
  },
  {
    cik: '0001727783',
    value: '0001727783,NEO IVY CAPITAL MANAGEMENT',
    name: 'NEO IVY CAPITAL MANAGEMENT',
    label: 'NEO IVY CAPITAL MANAGEMENT',
  },
  {
    cik: '0001291628',
    value: '0001291628,NEON LIBERTY CAPITAL MANAGEMENT LLC',
    name: 'NEON LIBERTY CAPITAL MANAGEMENT LLC',
    label: 'NEON LIBERTY CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001587643',
    value: '0001587643,NEPSIS CAPITAL MANAGEMENT, INC.',
    name: 'NEPSIS CAPITAL MANAGEMENT, INC.',
    label: 'NEPSIS CAPITAL MANAGEMENT, INC.',
  },
  {
    cik: '0001587643',
    value: '0001587643,NEPSIS INC.',
    name: 'NEPSIS INC.',
    label: 'NEPSIS INC.',
  },
  {
    cik: '0001587643',
    value: '0001587643,NEPSIS, INC.',
    name: 'NEPSIS, INC.',
    label: 'NEPSIS, INC.',
  },
  {
    cik: '0001110646',
    value: '0001110646,NETEASE, INC.',
    name: 'NETEASE, INC.',
    label: 'NETEASE, INC.',
  },
  {
    cik: '0001315497',
    value: '0001315497,NETOLS ASSET MANAGEMENT, INC.',
    name: 'NETOLS ASSET MANAGEMENT, INC.',
    label: 'NETOLS ASSET MANAGEMENT, INC.',
  },
  {
    cik: '0001465109',
    value: '0001465109,NEUBERGER BERMAN GROUP LLC',
    name: 'NEUBERGER BERMAN GROUP LLC',
    label: 'NEUBERGER BERMAN GROUP LLC',
  },
  {
    cik: '0001693800',
    value: '0001693800,NEUBURGH ADVISERS LLC',
    name: 'NEUBURGH ADVISERS LLC',
    label: 'NEUBURGH ADVISERS LLC',
  },
  {
    cik: '0001727862',
    value: '0001727862,NEUMANN ADVISORY HONG KONG LTD',
    name: 'NEUMANN ADVISORY HONG KONG LTD',
    label: 'NEUMANN ADVISORY HONG KONG LTD',
  },
  {
    cik: '0001631930',
    value: '0001631930,NEUMANN CAPITAL MANAGEMENT, LLC',
    name: 'NEUMANN CAPITAL MANAGEMENT, LLC',
    label: 'NEUMANN CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001044936',
    value: '0001044936,NEUMEIER POMA INVESTMENT COUNSEL LLC',
    name: 'NEUMEIER POMA INVESTMENT COUNSEL LLC',
    label: 'NEUMEIER POMA INVESTMENT COUNSEL LLC',
  },
  {
    cik: '0000071210',
    value: '0000071210,NEVILLE RODIE & SHAW INC',
    name: 'NEVILLE RODIE & SHAW INC',
    label: 'NEVILLE RODIE & SHAW INC',
  },
  {
    cik: '0001426790',
    value: '0001426790,NEVSKY CAPITAL LLP',
    name: 'NEVSKY CAPITAL LLP',
    label: 'NEVSKY CAPITAL LLP',
  },
  {
    cik: '0001803456',
    value: '0001803456,N.E.W. ADVISORY SERVICES LLC',
    name: 'N.E.W. ADVISORY SERVICES LLC',
    label: 'N.E.W. ADVISORY SERVICES LLC',
  },
  {
    cik: '0001010232',
    value: '0001010232,NEW AMSTERDAM PARTNERS LLC/NY',
    name: 'NEW AMSTERDAM PARTNERS LLC/NY',
    label: 'NEW AMSTERDAM PARTNERS LLC/NY',
  },
  {
    cik: '0001879757',
    value: '0001879757,NEWBRIDGE FINANCIAL SERVICES GROUP, INC.',
    name: 'NEWBRIDGE FINANCIAL SERVICES GROUP, INC.',
    label: 'NEWBRIDGE FINANCIAL SERVICES GROUP, INC.',
  },
  {
    cik: '0001389875',
    value: '0001389875,NEWBROOK CAPITAL ADVISORS LP',
    name: 'NEWBROOK CAPITAL ADVISORS LP',
    label: 'NEWBROOK CAPITAL ADVISORS LP',
  },
  {
    cik: '0001696731',
    value: '0001696731,NEW CAPITAL MANAGEMENT LP',
    name: 'NEW CAPITAL MANAGEMENT LP',
    label: 'NEW CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001574758',
    value: '0001574758,NEW CENTURY ADVISORS, LLC',
    name: 'NEW CENTURY ADVISORS, LLC',
    label: 'NEW CENTURY ADVISORS, LLC',
  },
  {
    cik: '0001431518',
    value: '0001431518,NEW CENTURY INVESTMENT MANAGEMENT, INC',
    name: 'NEW CENTURY INVESTMENT MANAGEMENT, INC',
    label: 'NEW CENTURY INVESTMENT MANAGEMENT, INC',
  },
  {
    cik: '0001728123',
    value: '0001728123,NEWDAY SOLUTIONS, LLC',
    name: 'NEWDAY SOLUTIONS, LLC',
    label: 'NEWDAY SOLUTIONS, LLC',
  },
  {
    cik: '0001633516',
    value: '0001633516,NEWEDGE ADVISORS, LLC',
    name: 'NEWEDGE ADVISORS, LLC',
    label: 'NEWEDGE ADVISORS, LLC',
  },
  {
    cik: '0001861796',
    value: '0001861796,NEWEDGE WEALTH, LLC',
    name: 'NEWEDGE WEALTH, LLC',
    label: 'NEWEDGE WEALTH, LLC',
  },
  {
    cik: '0001004244',
    value: '0001004244,NEW ENGLAND ASSET MANAGEMENT INC',
    name: 'NEW ENGLAND ASSET MANAGEMENT INC',
    label: 'NEW ENGLAND ASSET MANAGEMENT INC',
  },
  {
    cik: '0000878057',
    value: '0000878057,NEW ENGLAND GUILD WEALTH ADVISORS, INC.',
    name: 'NEW ENGLAND GUILD WEALTH ADVISORS, INC.',
    label: 'NEW ENGLAND GUILD WEALTH ADVISORS, INC.',
  },
  {
    cik: '0001535679',
    value: '0001535679,NEW ENGLAND INVESTMENT & RETIREMENT GROUP, INC.',
    name: 'NEW ENGLAND INVESTMENT & RETIREMENT GROUP, INC.',
    label: 'NEW ENGLAND INVESTMENT & RETIREMENT GROUP, INC.',
  },
  {
    cik: '0001582813',
    value: '0001582813,NEW ENGLAND PRIVATE WEALTH ADVISORS LLC',
    name: 'NEW ENGLAND PRIVATE WEALTH ADVISORS LLC',
    label: 'NEW ENGLAND PRIVATE WEALTH ADVISORS LLC',
  },
  {
    cik: '0001540867',
    value: '0001540867,NEW ENGLAND PROFESSIONAL PLANNING GROUP INC.',
    name: 'NEW ENGLAND PROFESSIONAL PLANNING GROUP INC.',
    label: 'NEW ENGLAND PROFESSIONAL PLANNING GROUP INC.',
  },
  {
    cik: '0001596077',
    value: '0001596077,NEW ENGLAND RESEARCH & MANAGEMENT, INC.',
    name: 'NEW ENGLAND RESEARCH & MANAGEMENT, INC.',
    label: 'NEW ENGLAND RESEARCH & MANAGEMENT, INC.',
  },
  {
    cik: '0000910316',
    value: '0000910316,NEW ENGLAND SECURITIES CORP /MA/',
    name: 'NEW ENGLAND SECURITIES CORP /MA/',
    label: 'NEW ENGLAND SECURITIES CORP /MA/',
  },
  {
    cik: '0001044207',
    value: '0001044207,NEWFLEET ASSET MANAGEMENT, LLC',
    name: 'NEWFLEET ASSET MANAGEMENT, LLC',
    label: 'NEWFLEET ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001599212',
    value: '0001599212,NEWFOCUS FINANCIAL GROUP, LLC',
    name: 'NEWFOCUS FINANCIAL GROUP, LLC',
    label: 'NEWFOCUS FINANCIAL GROUP, LLC',
  },
  {
    cik: '0001697752',
    value: '0001697752,NEWFOUNDLAND CAPITAL MANAGEMENT',
    name: 'NEWFOUNDLAND CAPITAL MANAGEMENT',
    label: 'NEWFOUNDLAND CAPITAL MANAGEMENT',
  },
  {
    cik: '0001630806',
    value: '0001630806,NEWFOUND RESEARCH LLC',
    name: 'NEWFOUND RESEARCH LLC',
    label: 'NEWFOUND RESEARCH LLC',
  },
  {
    cik: '0000939761',
    value: '0000939761,NEWGATE CAPITAL MANAGEMENT LLC',
    name: 'NEWGATE CAPITAL MANAGEMENT LLC',
    label: 'NEWGATE CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001845012',
    value: '0001845012,NEWGEN ASSET MANAGEMENT LTD',
    name: 'NEWGEN ASSET MANAGEMENT LTD',
    label: 'NEWGEN ASSET MANAGEMENT LTD',
  },
  {
    cik: '0001107211',
    value: '0001107211,NEW GENERATION ADVISORS LLC',
    name: 'NEW GENERATION ADVISORS LLC',
    label: 'NEW GENERATION ADVISORS LLC',
  },
  {
    cik: '0001548577',
    value: '0001548577,NEW HARBOR FINANCIAL GROUP, LLC',
    name: 'NEW HARBOR FINANCIAL GROUP, LLC',
    label: 'NEW HARBOR FINANCIAL GROUP, LLC',
  },
  {
    cik: '0001482010',
    value: '0001482010,NEW JERSEY BETTER EDUCATIONAL SAVINGS TRUST',
    name: 'NEW JERSEY BETTER EDUCATIONAL SAVINGS TRUST',
    label: 'NEW JERSEY BETTER EDUCATIONAL SAVINGS TRUST',
  },
  {
    cik: '0001419027',
    value: '0001419027,NEWLAND CAPITAL MANAGEMENT, LLC',
    name: 'NEWLAND CAPITAL MANAGEMENT, LLC',
    label: 'NEWLAND CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001631614',
    value: '0001631614,NEW LEAF VENTURE PARTNERS, L.L.C.',
    name: 'NEW LEAF VENTURE PARTNERS, L.L.C.',
    label: 'NEW LEAF VENTURE PARTNERS, L.L.C.',
  },
  {
    cik: '0001908065',
    value: '0001908065,NEW LEGACY GROUP, LLC',
    name: 'NEW LEGACY GROUP, LLC',
    label: 'NEW LEGACY GROUP, LLC',
  },
  {
    cik: '0001782866',
    value: '0001782866,NEWLIGHT PARTNERS LP',
    name: 'NEWLIGHT PARTNERS LP',
    label: 'NEWLIGHT PARTNERS LP',
  },
  {
    cik: '0001566493',
    value: '0001566493,NEWMAN DIGNAN & SHEERAR, INC.',
    name: 'NEWMAN DIGNAN & SHEERAR, INC.',
    label: 'NEWMAN DIGNAN & SHEERAR, INC.',
  },
  {
    cik: '0001838938',
    value: '0001838938,NEWMAN & SCHIMEL, LLC',
    name: 'NEWMAN & SCHIMEL, LLC',
    label: 'NEWMAN & SCHIMEL, LLC',
  },
  {
    cik: '0001690680',
    value: '0001690680,NEWMARK GROUP, INC.',
    name: 'NEWMARK GROUP, INC.',
    label: 'NEWMARK GROUP, INC.',
  },
  {
    cik: '0000862469',
    value: '0000862469,NEW MEXICO EDUCATIONAL RETIREMENT BOARD',
    name: 'NEW MEXICO EDUCATIONAL RETIREMENT BOARD',
    label: 'NEW MEXICO EDUCATIONAL RETIREMENT BOARD',
  },
  {
    cik: '0001909654',
    value: '0001909654,NEW MILLENNIUM GROUP LLC',
    name: 'NEW MILLENNIUM GROUP LLC',
    label: 'NEW MILLENNIUM GROUP LLC',
  },
  {
    cik: '0001407718',
    value: '0001407718,NEW MOUNTAIN CAPITAL, L.L.C.',
    name: 'NEW MOUNTAIN CAPITAL, L.L.C.',
    label: 'NEW MOUNTAIN CAPITAL, L.L.C.',
  },
  {
    cik: '0001388805',
    value: '0001388805,NEW MOUNTAIN VANTAGE ADVISERS, L.L.C.',
    name: 'NEW MOUNTAIN VANTAGE ADVISERS, L.L.C.',
    label: 'NEW MOUNTAIN VANTAGE ADVISERS, L.L.C.',
  },
  {
    cik: '0001909565',
    value: '0001909565,NEW PERSPECTIVES, INC',
    name: 'NEW PERSPECTIVES, INC',
    label: 'NEW PERSPECTIVES, INC',
  },
  {
    cik: '0001607597',
    value: '0001607597,NEWPORT ASIA LLC',
    name: 'NEWPORT ASIA LLC',
    label: 'NEWPORT ASIA LLC',
  },
  {
    cik: '0001869032',
    value: '0001869032,NEWPORT CAPITAL GROUP, LLC',
    name: 'NEWPORT CAPITAL GROUP, LLC',
    label: 'NEWPORT CAPITAL GROUP, LLC',
  },
  {
    cik: '0001722329',
    value: '0001722329,NEWPORT TRUST CO',
    name: 'NEWPORT TRUST CO',
    label: 'NEWPORT TRUST CO',
  },
  {
    cik: '0001730882',
    value: '0001730882,NEWPORT WEALTH STRATEGIES, INC.',
    name: 'NEWPORT WEALTH STRATEGIES, INC.',
    label: 'NEWPORT WEALTH STRATEGIES, INC.',
  },
  {
    cik: '0001731466',
    value: '0001731466,NEW POTOMAC PARTNERES, LLC',
    name: 'NEW POTOMAC PARTNERES, LLC',
    label: 'NEW POTOMAC PARTNERES, LLC',
  },
  {
    cik: '0001731466',
    value: '0001731466,NEW POTOMAC PARTNERS, LLC',
    name: 'NEW POTOMAC PARTNERS, LLC',
    label: 'NEW POTOMAC PARTNERS, LLC',
  },
  {
    cik: '0001569354',
    value: '0001569354,NEWQUEST CAPITAL MANAGEMENT (CAYMAN) LTD',
    name: 'NEWQUEST CAPITAL MANAGEMENT (CAYMAN) LTD',
    label: 'NEWQUEST CAPITAL MANAGEMENT (CAYMAN) LTD',
  },
  {
    cik: '0001569352',
    value: '0001569352,NEW SILK ROAD INVESTMENT PTE LTD',
    name: 'NEW SILK ROAD INVESTMENT PTE LTD',
    label: 'NEW SILK ROAD INVESTMENT PTE LTD',
  },
  {
    cik: '0001044797',
    value: '0001044797,NEW SOUTH CAPITAL MANAGEMENT INC',
    name: 'NEW SOUTH CAPITAL MANAGEMENT INC',
    label: 'NEW SOUTH CAPITAL MANAGEMENT INC',
  },
  {
    cik: '0001546989',
    value: '0001546989,NEWSQUARE CAPITAL LLC',
    name: 'NEWSQUARE CAPITAL LLC',
    label: 'NEWSQUARE CAPITAL LLC',
  },
  {
    cik: '0001878581',
    value: '0001878581,NEW THINKING (HONG KONG) INVESTMENT MANAGEMENT CO. LTD',
    name: 'NEW THINKING (HONG KONG) INVESTMENT MANAGEMENT CO. LTD',
    label: 'NEW THINKING (HONG KONG) INVESTMENT MANAGEMENT CO. LTD',
  },
  {
    cik: '0001842787',
    value: '0001842787,NEWTON ONE INVESTMENTS LLC',
    name: 'NEWTON ONE INVESTMENTS LLC',
    label: 'NEWTON ONE INVESTMENTS LLC',
  },
  {
    cik: '0001569241',
    value: '0001569241,NEWTYN MANAGEMENT, LLC',
    name: 'NEWTYN MANAGEMENT, LLC',
    label: 'NEWTYN MANAGEMENT, LLC',
  },
  {
    cik: '0001512538',
    value: '0001512538,NEW VERNON CAPITAL HOLDINGS II LLC',
    name: 'NEW VERNON CAPITAL HOLDINGS II LLC',
    label: 'NEW VERNON CAPITAL HOLDINGS II LLC',
  },
  {
    cik: '0001500605',
    value: '0001500605,NEW VERNON INVESTMENT MANAGEMENT LLC',
    name: 'NEW VERNON INVESTMENT MANAGEMENT LLC',
    label: 'NEW VERNON INVESTMENT MANAGEMENT LLC',
  },
  {
    cik: '0001750432',
    value: '0001750432,NEWVIEW CAPITAL PARTNERS I, LLC',
    name: 'NEWVIEW CAPITAL PARTNERS I, LLC',
    label: 'NEWVIEW CAPITAL PARTNERS I, LLC',
  },
  {
    cik: '0001913111',
    value: '0001913111,NEWVIEW HMS SPV PARTNERS, LLC',
    name: 'NEWVIEW HMS SPV PARTNERS, LLC',
    label: 'NEWVIEW HMS SPV PARTNERS, LLC',
  },
  {
    cik: '0001767724',
    value: '0001767724,NEW WORLD ADVISORS LLC',
    name: 'NEW WORLD ADVISORS LLC',
    label: 'NEW WORLD ADVISORS LLC',
  },
  {
    cik: '0001173497',
    value: '0001173497,NEW YORK COMMUNITY BANK',
    name: 'NEW YORK COMMUNITY BANK',
    label: 'NEW YORK COMMUNITY BANK',
  },
  {
    cik: '0000071633',
    value: '0000071633,NEW YORK LIFE INSURANCE CO',
    name: 'NEW YORK LIFE INSURANCE CO',
    label: 'NEW YORK LIFE INSURANCE CO',
  },
  {
    cik: '0001133639',
    value: '0001133639,NEW YORK LIFE INVESTMENT MANAGEMENT LLC',
    name: 'NEW YORK LIFE INVESTMENT MANAGEMENT LLC',
    label: 'NEW YORK LIFE INVESTMENT MANAGEMENT LLC',
  },
  {
    cik: '0001845268',
    value: '0001845268,NEW YORK LIFE INVESTMENTS ALTERNATIVES',
    name: 'NEW YORK LIFE INVESTMENTS ALTERNATIVES',
    label: 'NEW YORK LIFE INVESTMENTS ALTERNATIVES',
  },
  {
    cik: '0001033244',
    value: '0001033244,NEW YORK LIFE TRUST CO',
    name: 'NEW YORK LIFE TRUST CO',
    label: 'NEW YORK LIFE TRUST CO',
  },
  {
    cik: '0000810265',
    value: '0000810265,NEW YORK STATE COMMON RETIREMENT FUND',
    name: 'NEW YORK STATE COMMON RETIREMENT FUND',
    label: 'NEW YORK STATE COMMON RETIREMENT FUND',
  },
  {
    cik: '0000314969',
    value: '0000314969,NEW YORK STATE TEACHERS RETIREMENT SYSTEM',
    name: 'NEW YORK STATE TEACHERS RETIREMENT SYSTEM',
    label: 'NEW YORK STATE TEACHERS RETIREMENT SYSTEM',
  },
  {
    cik: '0001469877',
    value: '0001469877,NEXPOINT ASSET MANAGEMENT, L.P.',
    name: 'NEXPOINT ASSET MANAGEMENT, L.P.',
    label: 'NEXPOINT ASSET MANAGEMENT, L.P.',
  },
  {
    cik: '0001616454',
    value: '0001616454,NEXTCAPITAL ADVISERS, INC.',
    name: 'NEXTCAPITAL ADVISERS, INC.',
    label: 'NEXTCAPITAL ADVISERS, INC.',
  },
  {
    cik: '0001752762',
    value: '0001752762,NEXT CAPITAL MANAGEMENT LLC',
    name: 'NEXT CAPITAL MANAGEMENT LLC',
    label: 'NEXT CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001134007',
    value: '0001134007,NEXT CENTURY GROWTH INVESTORS LLC',
    name: 'NEXT CENTURY GROWTH INVESTORS LLC',
    label: 'NEXT CENTURY GROWTH INVESTORS LLC',
  },
  {
    cik: '0001911010',
    value: '0001911010,NEXTECH INVEST AG',
    name: 'NEXTECH INVEST AG',
    label: 'NEXTECH INVEST AG',
  },
  {
    cik: '0001616654',
    value: '0001616654,NEXT FINANCIAL GROUP, INC',
    name: 'NEXT FINANCIAL GROUP, INC',
    label: 'NEXT FINANCIAL GROUP, INC',
  },
  {
    cik: '0001541670',
    value: '0001541670,NEXT FINANCIAL HOLDINGS',
    name: 'NEXT FINANCIAL HOLDINGS',
    label: 'NEXT FINANCIAL HOLDINGS',
  },
  {
    cik: '0001646343',
    value: '0001646343,NEXTHERA CAPITAL LP',
    name: 'NEXTHERA CAPITAL LP',
    label: 'NEXTHERA CAPITAL LP',
  },
  {
    cik: '0001910366',
    value: '0001910366,NEXT LEVEL PRIVATE LLC',
    name: 'NEXT LEVEL PRIVATE LLC',
    label: 'NEXT LEVEL PRIVATE LLC',
  },
  {
    cik: '0001570575',
    value: '0001570575,NEXUS ASSET MANAGEMENT LLC',
    name: 'NEXUS ASSET MANAGEMENT LLC',
    label: 'NEXUS ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001476329',
    value: '0001476329,NEXUS INVESTMENT MANAGEMENT INC.',
    name: 'NEXUS INVESTMENT MANAGEMENT INC.',
    label: 'NEXUS INVESTMENT MANAGEMENT INC.',
  },
  {
    cik: '0001476329',
    value: '0001476329,NEXUS INVESTMENT MANAGEMENT ULC',
    name: 'NEXUS INVESTMENT MANAGEMENT ULC',
    label: 'NEXUS INVESTMENT MANAGEMENT ULC',
  },
  {
    cik: '0001785668',
    value: '0001785668,NEXWAVE CAPITAL PARTNERS LLC',
    name: 'NEXWAVE CAPITAL PARTNERS LLC',
    label: 'NEXWAVE CAPITAL PARTNERS LLC',
  },
  {
    cik: '0001512858',
    value: '0001512858,NFC INVESTMENTS, LLC',
    name: 'NFC INVESTMENTS, LLC',
    label: 'NFC INVESTMENTS, LLC',
  },
  {
    cik: '0001862664',
    value: '0001862664,NFJ INVESTMENT GROUP, LLC',
    name: 'NFJ INVESTMENT GROUP, LLC',
    label: 'NFJ INVESTMENT GROUP, LLC',
  },
  {
    cik: '0001637643',
    value: '0001637643,NF TRINITY CAPITAL (HONG KONG) LTD',
    name: 'NF TRINITY CAPITAL (HONG KONG) LTD',
    label: 'NF TRINITY CAPITAL (HONG KONG) LTD',
  },
  {
    cik: '0001018331',
    value: '0001018331,NGAM ADVISORS, L.P.',
    name: 'NGAM ADVISORS, L.P.',
    label: 'NGAM ADVISORS, L.P.',
  },
  {
    cik: '0001600162',
    value: '0001600162,N.G. PARTNERS II, L.L.C.',
    name: 'N.G. PARTNERS II, L.L.C.',
    label: 'N.G. PARTNERS II, L.L.C.',
  },
  {
    cik: '0001576089',
    value: '0001576089,NGP MR MANAGEMENT, LLC',
    name: 'NGP MR MANAGEMENT, LLC',
    label: 'NGP MR MANAGEMENT, LLC',
  },
  {
    cik: '0001815217',
    value: '0001815217,NIA IMPACT ADVISORS, LLC',
    name: 'NIA IMPACT ADVISORS, LLC',
    label: 'NIA IMPACT ADVISORS, LLC',
  },
  {
    cik: '0000903949',
    value: '0000903949,NICHOLAS CO INC /WI',
    name: 'NICHOLAS CO INC /WI',
    label: 'NICHOLAS CO INC /WI',
  },
  {
    cik: '0000903949',
    value: '0000903949,NICHOLAS COMPANY, INC.',
    name: 'NICHOLAS COMPANY, INC.',
    label: 'NICHOLAS COMPANY, INC.',
  },
  {
    cik: '0001678953',
    value: '0001678953,NICHOLAS HOFFMAN & COMPANY, LLC.',
    name: 'NICHOLAS HOFFMAN & COMPANY, LLC.',
    label: 'NICHOLAS HOFFMAN & COMPANY, LLC.',
  },
  {
    cik: '0001389574',
    value: '0001389574,NICHOLAS INVESTMENT PARTNERS, LP',
    name: 'NICHOLAS INVESTMENT PARTNERS, LP',
    label: 'NICHOLAS INVESTMENT PARTNERS, LP',
  },
  {
    cik: '0001512914',
    value: '0001512914,NICHOLS ASSET MANAGEMENT LLC',
    name: 'NICHOLS ASSET MANAGEMENT LLC',
    label: 'NICHOLS ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001141781',
    value: '0001141781,NICHOLS & PRATT ADVISERS LLP /MA',
    name: 'NICHOLS & PRATT ADVISERS LLP /MA',
    label: 'NICHOLS & PRATT ADVISERS LLP /MA',
  },
  {
    cik: '0001734493',
    value: '0001734493,NICOLA WEALTH MANAGEMENT LTD.',
    name: 'NICOLA WEALTH MANAGEMENT LTD.',
    label: 'NICOLA WEALTH MANAGEMENT LTD.',
  },
  {
    cik: '0001775863',
    value: '0001775863,NICOLET ADVISORY SERVICES, LLC',
    name: 'NICOLET ADVISORY SERVICES, LLC',
    label: 'NICOLET ADVISORY SERVICES, LLC',
  },
  {
    cik: '0001174850',
    value: '0001174850,NICOLET BANKSHARES INC',
    name: 'NICOLET BANKSHARES INC',
    label: 'NICOLET BANKSHARES INC',
  },
  {
    cik: '0001729048',
    value: '0001729048,NICOLLET INVESTMENT MANAGEMENT, INC.',
    name: 'NICOLLET INVESTMENT MANAGEMENT, INC.',
    label: 'NICOLLET INVESTMENT MANAGEMENT, INC.',
  },
  {
    cik: '0001143892',
    value: '0001143892,NIEMANN CAPITAL MANAGEMENT INC',
    name: 'NIEMANN CAPITAL MANAGEMENT INC',
    label: 'NIEMANN CAPITAL MANAGEMENT INC',
  },
  {
    cik: '0001282683',
    value: '0001282683,NIERENBERG INVESTMENT MANAGEMENT COMPANY, INC.',
    name: 'NIERENBERG INVESTMENT MANAGEMENT COMPANY, INC.',
    label: 'NIERENBERG INVESTMENT MANAGEMENT COMPANY, INC.',
  },
  {
    cik: '0001410833',
    value: '0001410833,NIGHT OWL CAPITAL MANAGEMENT, LLC',
    name: 'NIGHT OWL CAPITAL MANAGEMENT, LLC',
    label: 'NIGHT OWL CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001668679',
    value: '0001668679,NIGHT SQUARE CAPITAL PARTNERS, LP',
    name: 'NIGHT SQUARE CAPITAL PARTNERS, LP',
    label: 'NIGHT SQUARE CAPITAL PARTNERS, LP',
  },
  {
    cik: '0001480751',
    value: '0001480751,NIKKO ASSET MANAGEMENT AMERICAS, INC.',
    name: 'NIKKO ASSET MANAGEMENT AMERICAS, INC.',
    label: 'NIKKO ASSET MANAGEMENT AMERICAS, INC.',
  },
  {
    cik: '0001729847',
    value: '0001729847,NIKULSKI FINANCIAL, INC.',
    name: 'NIKULSKI FINANCIAL, INC.',
    label: 'NIKULSKI FINANCIAL, INC.',
  },
  {
    cik: '0001911627',
    value: '0001911627,NIMBLE PARTNERS, LLC',
    name: 'NIMBLE PARTNERS, LLC',
    label: 'NIMBLE PARTNERS, LLC',
  },
  {
    cik: '0001567745',
    value: '0001567745,NINE CHAPTERS CAPITAL MANAGEMENT LLC',
    name: 'NINE CHAPTERS CAPITAL MANAGEMENT LLC',
    label: 'NINE CHAPTERS CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001569356',
    value: '0001569356,NINE MASTS CAPITAL LTD',
    name: 'NINE MASTS CAPITAL LTD',
    label: 'NINE MASTS CAPITAL LTD',
  },
  {
    cik: '0001715635',
    value: '0001715635,NINEPOINT PARTNERS LP',
    name: 'NINEPOINT PARTNERS LP',
    label: 'NINEPOINT PARTNERS LP',
  },
  {
    cik: '0001824516',
    value: '0001824516,NINEPOINTTWO CAPITAL',
    name: 'NINEPOINTTWO CAPITAL',
    label: 'NINEPOINTTWO CAPITAL',
  },
  {
    cik: '0001824516',
    value: '0001824516,NINEPOINTTWO CAPITAL LLC',
    name: 'NINEPOINTTWO CAPITAL LLC',
    label: 'NINEPOINTTWO CAPITAL LLC',
  },
  {
    cik: '0001635236',
    value: '0001635236,NINE TEN CAPITAL MANAGEMENT LLC',
    name: 'NINE TEN CAPITAL MANAGEMENT LLC',
    label: 'NINE TEN CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001603715',
    value: '0001603715,NINE TEN PARTNERS LP',
    name: 'NINE TEN PARTNERS LP',
    label: 'NINE TEN PARTNERS LP',
  },
  {
    cik: '0001633343',
    value: '0001633343,NINETY ONE NORTH AMERICA, INC.',
    name: 'NINETY ONE NORTH AMERICA, INC.',
    label: 'NINETY ONE NORTH AMERICA, INC.',
  },
  {
    cik: '0001491998',
    value: '0001491998,NINETY ONE SA (PTY) LTD',
    name: 'NINETY ONE SA (PTY) LTD',
    label: 'NINETY ONE SA (PTY) LTD',
  },
  {
    cik: '0001418329',
    value: '0001418329,NINETY ONE UK LTD',
    name: 'NINETY ONE UK LTD',
    label: 'NINETY ONE UK LTD',
  },
  {
    cik: '0001040190',
    value: '0001040190,NIPPON LIFE GLOBAL INVESTORS AMERICAS, INC.',
    name: 'NIPPON LIFE GLOBAL INVESTORS AMERICAS, INC.',
    label: 'NIPPON LIFE GLOBAL INVESTORS AMERICAS, INC.',
  },
  {
    cik: '0000905591',
    value: '0000905591,NIPPON LIFE INSURANCE CO',
    name: 'NIPPON LIFE INSURANCE CO',
    label: 'NIPPON LIFE INSURANCE CO',
  },
  {
    cik: '0000937615',
    value: '0000937615,NISA INVESTMENT ADVISORS L L C',
    name: 'NISA INVESTMENT ADVISORS L L C',
    label: 'NISA INVESTMENT ADVISORS L L C',
  },
  {
    cik: '0000937615',
    value: '0000937615,NISA INVESTMENT ADVISORS, LLC',
    name: 'NISA INVESTMENT ADVISORS, LLC',
    label: 'NISA INVESTMENT ADVISORS, LLC',
  },
  {
    cik: '0001633799',
    value: '0001633799,NISHKAMA CAPITAL, LLC',
    name: 'NISHKAMA CAPITAL, LLC',
    label: 'NISHKAMA CAPITAL, LLC',
  },
  {
    cik: '0000869589',
    value: '0000869589,NISSAY ASSET MANAGEMENT CORP /JAPAN/ /ADV',
    name: 'NISSAY ASSET MANAGEMENT CORP /JAPAN/ /ADV',
    label: 'NISSAY ASSET MANAGEMENT CORP /JAPAN/ /ADV',
  },
  {
    cik: '0001630243',
    value: '0001630243,NITORUM CAPITAL, L.P.',
    name: 'NITORUM CAPITAL, L.P.',
    label: 'NITORUM CAPITAL, L.P.',
  },
  {
    cik: '0001846352',
    value: '0001846352,NIXON CAPITAL, LLC',
    name: 'NIXON CAPITAL, LLC',
    label: 'NIXON CAPITAL, LLC',
  },
  {
    cik: '0001626757',
    value: '0001626757,NIXON PEABODY TRUST CO',
    name: 'NIXON PEABODY TRUST CO',
    label: 'NIXON PEABODY TRUST CO',
  },
  {
    cik: '0001483259',
    value: '0001483259,NJ STATE EMPLOYEES DEFERRED COMPENSATION PLAN',
    name: 'NJ STATE EMPLOYEES DEFERRED COMPENSATION PLAN',
    label: 'NJ STATE EMPLOYEES DEFERRED COMPENSATION PLAN',
  },
  {
    cik: '0001757282',
    value: '00017572,NKCFO LLC',
    name: 'NKCFO LLC',
    label: 'NKCFO LLC',
  },
  {
    cik: '0001040190',
    value: '0001040190,NLI INTERNATIONAL INC',
    name: 'NLI INTERNATIONAL INC',
    label: 'NLI INTERNATIONAL INC',
  },
  {
    cik: '0001667232',
    value: '0001667232,NN INVESTMENT PARTNERS HOLDINGS N.V.',
    name: 'NN INVESTMENT PARTNERS HOLDINGS N.V.',
    label: 'NN INVESTMENT PARTNERS HOLDINGS N.V.',
  },
  {
    cik: '0001911025',
    value: '0001911025,NOBLE PATH ASSET MANAGEMENT LP',
    name: 'NOBLE PATH ASSET MANAGEMENT LP',
    label: 'NOBLE PATH ASSET MANAGEMENT LP',
  },
  {
    cik: '0001262677',
    value: '0001262677,NOESIS CAPITAL MANGEMENT CORP',
    name: 'NOESIS CAPITAL MANGEMENT CORP',
    label: 'NOESIS CAPITAL MANGEMENT CORP',
  },
  {
    cik: '0001709622',
    value: '0001709622,NOKED CAPITAL LTD',
    name: 'NOKED CAPITAL LTD',
    label: 'NOKED CAPITAL LTD',
  },
  {
    cik: '0001767750',
    value: '0001767750,NOKED ISRAEL LTD',
    name: 'NOKED ISRAEL LTD',
    label: 'NOKED ISRAEL LTD',
  },
  {
    cik: '0001541055',
    value: '0001541055,NOKOMIS CAPITAL, L.L.C.',
    name: 'NOKOMIS CAPITAL, L.L.C.',
    label: 'NOKOMIS CAPITAL, L.L.C.',
  },
  {
    cik: '0001535176',
    value: '0001535176,NOKOTA MANAGEMENT, LP',
    name: 'NOKOTA MANAGEMENT, LP',
    label: 'NOKOTA MANAGEMENT, LP',
  },
  {
    cik: '0001055964',
    value: '0001055964,NOMURA ASSET MANAGEMENT CO LTD',
    name: 'NOMURA ASSET MANAGEMENT CO LTD',
    label: 'NOMURA ASSET MANAGEMENT CO LTD',
  },
  {
    cik: '0001163653',
    value: '0001163653,NOMURA HOLDINGS INC',
    name: 'NOMURA HOLDINGS INC',
    label: 'NOMURA HOLDINGS INC',
  },
  {
    cik: '0001218210',
    value: '0001218210,NORDEA INVESTMENT MANAGEMENT AB',
    name: 'NORDEA INVESTMENT MANAGEMENT AB',
    label: 'NORDEA INVESTMENT MANAGEMENT AB',
  },
  {
    cik: '0001374170',
    value: '0001374170,NORGES BANK',
    name: 'NORGES BANK',
    label: 'NORGES BANK',
  },
  {
    cik: '0001496637',
    value: '0001496637,NORINCHUKIN BANK, THE',
    name: 'NORINCHUKIN BANK, THE',
    label: 'NORINCHUKIN BANK, THE',
  },
  {
    cik: '0001352449',
    value: '0001352449,NORMAN FIELDS, GOTTSCHO CAPITAL MANAGEMENT, LLC',
    name: 'NORMAN FIELDS, GOTTSCHO CAPITAL MANAGEMENT, LLC',
    label: 'NORMAN FIELDS, GOTTSCHO CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001021450',
    value: '0001021450,NOROIAN STEVEN A & ASSOCIATES INC/CA',
    name: 'NOROIAN STEVEN A & ASSOCIATES INC/CA',
    label: 'NOROIAN STEVEN A & ASSOCIATES INC/CA',
  },
  {
    cik: '0001092290',
    value: '0001092290,NORRIS PERNE & FRENCH LLP/MI',
    name: 'NORRIS PERNE & FRENCH LLP/MI',
    label: 'NORRIS PERNE & FRENCH LLP/MI',
  },
  {
    cik: '0000861462',
    value: '0000861462,NORTH AMERICAN MANAGEMENT CORP',
    name: 'NORTH AMERICAN MANAGEMENT CORP',
    label: 'NORTH AMERICAN MANAGEMENT CORP',
  },
  {
    cik: '0001686776',
    value: '0001686776,NORTHCAPE CAPITAL PTY LTD',
    name: 'NORTHCAPE CAPITAL PTY LTD',
    label: 'NORTHCAPE CAPITAL PTY LTD',
  },
  {
    cik: '0001902806',
    value: '0001902806,NORTHCAPE WEALTH MANAGEMENT, LLC',
    name: 'NORTHCAPE WEALTH MANAGEMENT, LLC',
    label: 'NORTHCAPE WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001456227',
    value: '0001456227,NORTHCOAST ASSET MANAGEMENT LLC',
    name: 'NORTHCOAST ASSET MANAGEMENT LLC',
    label: 'NORTHCOAST ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001893159',
    value: '0001893159,NORTHCREST ASSET MANANGEMENT, LLC',
    name: 'NORTHCREST ASSET MANANGEMENT, LLC',
    label: 'NORTHCREST ASSET MANANGEMENT, LLC',
  },
  {
    cik: '0001599863',
    value: '0001599863,NORTHEAST FINANCIAL CONSULTANTS INC',
    name: 'NORTHEAST FINANCIAL CONSULTANTS INC',
    label: 'NORTHEAST FINANCIAL CONSULTANTS INC',
  },
  {
    cik: '0001082215',
    value: '0001082215,NORTHEAST INVESTMENT MANAGEMENT',
    name: 'NORTHEAST INVESTMENT MANAGEMENT',
    label: 'NORTHEAST INVESTMENT MANAGEMENT',
  },
  {
    cik: '0001581794',
    value: '0001581794,NORTHERN CAPITAL MANAGEMENT, INC.',
    name: 'NORTHERN CAPITAL MANAGEMENT, INC.',
    label: 'NORTHERN CAPITAL MANAGEMENT, INC.',
  },
  {
    cik: '0000312955',
    value: '0000312955,NORTHERN CAPITAL MANAGEMENT LLC',
    name: 'NORTHERN CAPITAL MANAGEMENT LLC',
    label: 'NORTHERN CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001461201',
    value: '0001461201,NORTHERN CROSS, LLC',
    name: 'NORTHERN CROSS, LLC',
    label: 'NORTHERN CROSS, LLC',
  },
  {
    cik: '0001138486',
    value: '0001138486,NORTHERN OAK CAPITAL MANAGEMENT INC',
    name: 'NORTHERN OAK CAPITAL MANAGEMENT INC',
    label: 'NORTHERN OAK CAPITAL MANAGEMENT INC',
  },
  {
    cik: '0001138486',
    value: '0001138486,NORTHERN OAK WEALTH MANAGEMENT INC',
    name: 'NORTHERN OAK WEALTH MANAGEMENT INC',
    label: 'NORTHERN OAK WEALTH MANAGEMENT INC',
  },
  {
    cik: '0001346543',
    value: '0001346543,NORTHERN RIGHT CAPITAL MANAGEMENT, L.P.',
    name: 'NORTHERN RIGHT CAPITAL MANAGEMENT, L.P.',
    label: 'NORTHERN RIGHT CAPITAL MANAGEMENT, L.P.',
  },
  {
    cik: '0001065441',
    value: '0001065441,NORTHERN TRUST CO OF CONNECTICUT',
    name: 'NORTHERN TRUST CO OF CONNECTICUT',
    label: 'NORTHERN TRUST CO OF CONNECTICUT',
  },
  {
    cik: '0000073124',
    value: '0000073124,NORTHERN TRUST CORP',
    name: 'NORTHERN TRUST CORP',
    label: 'NORTHERN TRUST CORP',
  },
  {
    cik: '0001526201',
    value: '0001526201,NORTH FORTY MANAGEMENT, LLC',
    name: 'NORTH FORTY MANAGEMENT, LLC',
    label: 'NORTH FORTY MANAGEMENT, LLC',
  },
  {
    cik: '0001715329',
    value: '0001715329,NORTH FOURTH ASSET MANAGEMENT, LP',
    name: 'NORTH FOURTH ASSET MANAGEMENT, LP',
    label: 'NORTH FOURTH ASSET MANAGEMENT, LP',
  },
  {
    cik: '0001745796',
    value: '0001745796,NORTH GROWTH MANAGEMENT LTD.',
    name: 'NORTH GROWTH MANAGEMENT LTD.',
    label: 'NORTH GROWTH MANAGEMENT LTD.',
  },
  {
    cik: '0001729675',
    value: '0001729675,NORTH ISLAND MANAGECO, LLC',
    name: 'NORTH ISLAND MANAGECO, LLC',
    label: 'NORTH ISLAND MANAGECO, LLC',
  },
  {
    cik: '0001693032',
    value: '0001693032,NORTHLAND CAPITAL MANAGEMENT LLC',
    name: 'NORTHLAND CAPITAL MANAGEMENT LLC',
    label: 'NORTHLAND CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001655982',
    value: '0001655982,NORTHLANDING FINANCIAL PARTNERS, LLC',
    name: 'NORTHLANDING FINANCIAL PARTNERS, LLC',
    label: 'NORTHLANDING FINANCIAL PARTNERS, LLC',
  },
  {
    cik: '0001747888',
    value: '0001747888,NORTH PEAK CAPITAL MANAGEMENT, LLC',
    name: 'NORTH PEAK CAPITAL MANAGEMENT, LLC',
    label: 'NORTH PEAK CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001132668',
    value: '0001132668,NORTHPOINTE CAPITAL LLC',
    name: 'NORTHPOINTE CAPITAL LLC',
    label: 'NORTHPOINTE CAPITAL LLC',
  },
  {
    cik: '0001105344',
    value: '0001105344,NORTH POINT PORTFOLIO MANAGERS CORP/OH',
    name: 'NORTH POINT PORTFOLIO MANAGERS CORP/OH',
    label: 'NORTH POINT PORTFOLIO MANAGERS CORP/OH',
  },
  {
    cik: '0001911245',
    value: '0001911245,NORTHPOND VENTURES, LLC',
    name: 'NORTHPOND VENTURES, LLC',
    label: 'NORTHPOND VENTURES, LLC',
  },
  {
    cik: '0001867831',
    value: '0001867831,NORTH REEF CAPITAL MANAGEMENT LP',
    name: 'NORTH REEF CAPITAL MANAGEMENT LP',
    label: 'NORTH REEF CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001219062',
    value: '0001219062,NORTHROAD CAPITAL MANAGEMENT LLC',
    name: 'NORTHROAD CAPITAL MANAGEMENT LLC',
    label: 'NORTHROAD CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001632097',
    value: '0001632097,NORTHROCK PARTNERS, LLC',
    name: 'NORTHROCK PARTNERS, LLC',
    label: 'NORTHROCK PARTNERS, LLC',
  },
  {
    cik: '0001212897',
    value: '0001212897,NORTH RUN CAPITAL, LP',
    name: 'NORTH RUN CAPITAL, LP',
    label: 'NORTH RUN CAPITAL, LP',
  },
  {
    cik: '0001631052',
    value: '0001631052,NORTHSIDE CAPITAL MANAGEMENT, LLC',
    name: 'NORTHSIDE CAPITAL MANAGEMENT, LLC',
    label: 'NORTHSIDE CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001816000',
    value: '0001816000,NORTHSTAR ADVISORY GROUP, LLC',
    name: 'NORTHSTAR ADVISORY GROUP, LLC',
    label: 'NORTHSTAR ADVISORY GROUP, LLC',
  },
  {
    cik: '0001056559',
    value: '0001056559,NORTH STAR ASSET MANAGEMENT INC',
    name: 'NORTH STAR ASSET MANAGEMENT INC',
    label: 'NORTH STAR ASSET MANAGEMENT INC',
  },
  {
    cik: '0001132708',
    value: '0001132708,NORTHSTAR ASSET MANAGEMENT LLC',
    name: 'NORTHSTAR ASSET MANAGEMENT LLC',
    label: 'NORTHSTAR ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001730709',
    value: '0001730709,NORTHSTAR CAPITAL MANAGEMENT LLC',
    name: 'NORTHSTAR CAPITAL MANAGEMENT LLC',
    label: 'NORTHSTAR CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001631408',
    value: '0001631408,NORTHSTAR GROUP, INC.',
    name: 'NORTHSTAR GROUP, INC.',
    label: 'NORTHSTAR GROUP, INC.',
  },
  {
    cik: '0001106505',
    value: '0001106505,NORTHSTAR INVESTMENT ADVISORS LLC',
    name: 'NORTHSTAR INVESTMENT ADVISORS LLC',
    label: 'NORTHSTAR INVESTMENT ADVISORS LLC',
  },
  {
    cik: '0001342857',
    value: '0001342857,NORTH STAR INVESTMENT MANAGEMENT CORP.',
    name: 'NORTH STAR INVESTMENT MANAGEMENT CORP.',
    label: 'NORTH STAR INVESTMENT MANAGEMENT CORP.',
  },
  {
    cik: '0001703100',
    value: '0001703100,NORTHSTAR WEALTH PARTNERS LLC',
    name: 'NORTHSTAR WEALTH PARTNERS LLC',
    label: 'NORTHSTAR WEALTH PARTNERS LLC',
  },
  {
    cik: '0001557543',
    value: '0001557543,NORTH TIDE CAPITAL, LLC',
    name: 'NORTH TIDE CAPITAL, LLC',
    label: 'NORTH TIDE CAPITAL, LLC',
  },
  {
    cik: '0001471265',
    value: '0001471265,NORTHWEST BANCSHARES, INC.',
    name: 'NORTHWEST BANCSHARES, INC.',
    label: 'NORTHWEST BANCSHARES, INC.',
  },
  {
    cik: '0001730404',
    value: '0001730404,NORTHWEST CAPITAL MANAGEMENT INC',
    name: 'NORTHWEST CAPITAL MANAGEMENT INC',
    label: 'NORTHWEST CAPITAL MANAGEMENT INC',
  },
  {
    cik: '0001629649',
    value: '0001629649,NORTHWESTERN MUTUAL INVESTMENT MANAGEMENT COMPANY, LLC',
    name: 'NORTHWESTERN MUTUAL INVESTMENT MANAGEMENT COMPANY, LLC',
    label: 'NORTHWESTERN MUTUAL INVESTMENT MANAGEMENT COMPANY, LLC',
  },
  {
    cik: '0000910886',
    value: '0000910886,NORTHWESTERN MUTUAL INVESTMENT SERVICES LLC',
    name: 'NORTHWESTERN MUTUAL INVESTMENT SERVICES LLC',
    label: 'NORTHWESTERN MUTUAL INVESTMENT SERVICES LLC',
  },
  {
    cik: '0000073076',
    value: '0000073076,NORTHWESTERN MUTUAL LIFE INSURANCE CO',
    name: 'NORTHWESTERN MUTUAL LIFE INSURANCE CO',
    label: 'NORTHWESTERN MUTUAL LIFE INSURANCE CO',
  },
  {
    cik: '0001141802',
    value: '0001141802,NORTHWESTERN MUTUAL WEALTH MANAGEMENT CO',
    name: 'NORTHWESTERN MUTUAL WEALTH MANAGEMENT CO',
    label: 'NORTHWESTERN MUTUAL WEALTH MANAGEMENT CO',
  },
  {
    cik: '0000873687',
    value: '0000873687,NORTHWESTERN UNIVERSITY',
    name: 'NORTHWESTERN UNIVERSITY',
    label: 'NORTHWESTERN UNIVERSITY',
  },
  {
    cik: '0001428350',
    value: '0001428350,NORTHWEST INVESTMENT COUNSELORS, LLC',
    name: 'NORTHWEST INVESTMENT COUNSELORS, LLC',
    label: 'NORTHWEST INVESTMENT COUNSELORS, LLC',
  },
  {
    cik: '0001697767',
    value: '0001697767,NORTHWEST QUADRANT WEALTH MANAGEMENT, LLC',
    name: 'NORTHWEST QUADRANT WEALTH MANAGEMENT, LLC',
    label: 'NORTHWEST QUADRANT WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001695344',
    value: '0001695344,NORTHWEST WEALTH MANAGEMENT, LLC',
    name: 'NORTHWEST WEALTH MANAGEMENT, LLC',
    label: 'NORTHWEST WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001843039',
    value: '0001843039,NORTHWOOD INVESTORS LLC',
    name: 'NORTHWOOD INVESTORS LLC',
    label: 'NORTHWOOD INVESTORS LLC',
  },
  {
    cik: '0001736465',
    value: '0001736465,NORTHWOOD LIQUID MANAGEMENT LP',
    name: 'NORTHWOOD LIQUID MANAGEMENT LP',
    label: 'NORTHWOOD LIQUID MANAGEMENT LP',
  },
  {
    cik: '0001692227',
    value: '0001692227,NORWAY SAVINGS BANK',
    name: 'NORWAY SAVINGS BANK',
    label: 'NORWAY SAVINGS BANK',
  },
  {
    cik: '0001309124',
    value: '0001309124,NORWEST VENTURE PARTNERS IX, LP',
    name: 'NORWEST VENTURE PARTNERS IX, LP',
    label: 'NORWEST VENTURE PARTNERS IX, LP',
  },
  {
    cik: '0001176230',
    value: '0001176230,NORWEST VENTURE PARTNERS VIII LP',
    name: 'NORWEST VENTURE PARTNERS VIII LP',
    label: 'NORWEST VENTURE PARTNERS VIII LP',
  },
  {
    cik: '0001772583',
    value: '0001772583,NORWEST VENTURE PARTNERS XIII, LP',
    name: 'NORWEST VENTURE PARTNERS XIII, LP',
    label: 'NORWEST VENTURE PARTNERS XIII, LP',
  },
  {
    cik: '0001614660',
    value: '0001614660,NORWEST VENTURE PARTNERS XII, LP',
    name: 'NORWEST VENTURE PARTNERS XII, LP',
    label: 'NORWEST VENTURE PARTNERS XII, LP',
  },
  {
    cik: '0001534522',
    value: '0001534522,NORWEST VENTURE PARTNERS XI, LP',
    name: 'NORWEST VENTURE PARTNERS XI, LP',
    label: 'NORWEST VENTURE PARTNERS XI, LP',
  },
  {
    cik: '0001842721',
    value: '0001842721,NORWEST VENTURE PARTNERS XIV, LP',
    name: 'NORWEST VENTURE PARTNERS XIV, LP',
    label: 'NORWEST VENTURE PARTNERS XIV, LP',
  },
  {
    cik: '0001361181',
    value: '0001361181,NORWEST VENTURE PARTNERS X L P',
    name: 'NORWEST VENTURE PARTNERS X L P',
    label: 'NORWEST VENTURE PARTNERS X L P',
  },
  {
    cik: '0001013272',
    value: '0001013272,NORWOOD FINANCIAL CORP',
    name: 'NORWOOD FINANCIAL CORP',
    label: 'NORWOOD FINANCIAL CORP',
  },
  {
    cik: '0001888610',
    value: '0001888610,NORWOOD INVESTMENT PARTNERS, LP',
    name: 'NORWOOD INVESTMENT PARTNERS, LP',
    label: 'NORWOOD INVESTMENT PARTNERS, LP',
  },
  {
    cik: '0001765774',
    value: '0001765774,NO STREET GP LP',
    name: 'NO STREET GP LP',
    label: 'NO STREET GP LP',
  },
  {
    cik: '0001592616',
    value: '0001592616,NOTIS-MCCONARTY EDWARD',
    name: 'NOTIS-MCCONARTY EDWARD',
    label: 'NOTIS-MCCONARTY EDWARD',
  },
  {
    cik: '0001093276',
    value: '0001093276,NOTTINGHAM ADVISORS, INC.',
    name: 'NOTTINGHAM ADVISORS, INC.',
    label: 'NOTTINGHAM ADVISORS, INC.',
  },
  {
    cik: '0001843462',
    value: '0001843462,NOVAK FINANCIAL PLANNING, LLC',
    name: 'NOVAK FINANCIAL PLANNING, LLC',
    label: 'NOVAK FINANCIAL PLANNING, LLC',
  },
  {
    cik: '0001815025',
    value: '0001815025,NOVAPOINT CAPITAL, LLC',
    name: 'NOVAPOINT CAPITAL, LLC',
    label: 'NOVAPOINT CAPITAL, LLC',
  },
  {
    cik: '0001729264',
    value: '0001729264,NOVAQUEST CAPITAL MANAGEMENT, L.L.C.',
    name: 'NOVAQUEST CAPITAL MANAGEMENT, L.L.C.',
    label: 'NOVAQUEST CAPITAL MANAGEMENT, L.L.C.',
  },
  {
    cik: '0001388409',
    value: '0001388409,NOVARE CAPITAL MANAGEMENT LLC',
    name: 'NOVARE CAPITAL MANAGEMENT LLC',
    label: 'NOVARE CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001729457',
    value: '0001729457,NOVA R WEALTH, INC.',
    name: 'NOVA R WEALTH, INC.',
    label: 'NOVA R WEALTH, INC.',
  },
  {
    cik: '0001536442',
    value: '0001536442,NOVEN FINANCIAL GROUP, INC.',
    name: 'NOVEN FINANCIAL GROUP, INC.',
    label: 'NOVEN FINANCIAL GROUP, INC.',
  },
  {
    cik: '0001388325',
    value: '0001388,NOVO A/S5',
    name: 'NOVO A/S',
    label: 'NOVO A/S',
  },
  {
    cik: '0001388325',
    value: '0001388325,NOVO HOLDINGS A/S',
    name: 'NOVO HOLDINGS A/S',
    label: 'NOVO HOLDINGS A/S',
  },
  {
    cik: '0001598203',
    value: '0001598203,NQ HCIF GP, LTD.',
    name: 'NQ HCIF GP, LTD.',
    label: 'NQ HCIF GP, LTD.',
  },
  {
    cik: '0001413654',
    value: '0001413654,NS ADVISORS, LLC',
    name: 'NS ADVISORS, LLC',
    label: 'NS ADVISORS, LLC',
  },
  {
    cik: '0001476961',
    value: '0001476961,NSB ADVISORS LLC',
    name: 'NSB ADVISORS LLC',
    label: 'NSB ADVISORS LLC',
  },
  {
    cik: '0001866574',
    value: '0001866574,NSI RETAIL ADVISORS, LLC',
    name: 'NSI RETAIL ADVISORS, LLC',
    label: 'NSI RETAIL ADVISORS, LLC',
  },
  {
    cik: '0001657111',
    value: '0001657111,NS PARTNERS LTD',
    name: 'NS PARTNERS LTD',
    label: 'NS PARTNERS LTD',
  },
  {
    cik: '0001910010',
    value: '0001910010,NTB FINANCIAL CORP',
    name: 'NTB FINANCIAL CORP',
    label: 'NTB FINANCIAL CORP',
  },
  {
    cik: '0001284208',
    value: '0001284208,NTV ASSET MANAGEMENT LLC',
    name: 'NTV ASSET MANAGEMENT LLC',
    label: 'NTV ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001473182',
    value: '0001473182,NUANCE INVESTMENTS, LLC',
    name: 'NUANCE INVESTMENTS, LLC',
    label: 'NUANCE INVESTMENTS, LLC',
  },
  {
    cik: '0001737348',
    value: '0001737348,NUCLEO CAPITAL LTDA.',
    name: 'NUCLEO CAPITAL LTDA.',
    label: 'NUCLEO CAPITAL LTDA.',
  },
  {
    cik: '0001695648',
    value: '0001695648,NUMEN CAPITAL LLP',
    name: 'NUMEN CAPITAL LLP',
    label: 'NUMEN CAPITAL LLP',
  },
  {
    cik: '0001365324',
    value: '0001365324,NUMERIC INVESTORS LLC',
    name: 'NUMERIC INVESTORS LLC',
    label: 'NUMERIC INVESTORS LLC',
  },
  {
    cik: '0001611457',
    value: '0001611457,NUMERIXS INVESTMENT TECHNOLOGIES INC',
    name: 'NUMERIXS INVESTMENT TECHNOLOGIES INC',
    label: 'NUMERIXS INVESTMENT TECHNOLOGIES INC',
  },
  {
    cik: '0001617516',
    value: '0001617516,NUMINA CAPITAL MANAGEMENT, LLC',
    name: 'NUMINA CAPITAL MANAGEMENT, LLC',
    label: 'NUMINA CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001661655',
    value: '0001661655,NUT TREE CAPITAL MANAGEMENT, LP',
    name: 'NUT TREE CAPITAL MANAGEMENT, LP',
    label: 'NUT TREE CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0001521019',
    value: '0001521019,NUVEEN ASSET MANAGEMENT, LLC',
    name: 'NUVEEN ASSET MANAGEMENT, LLC',
    label: 'NUVEEN ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001311016',
    value: '0001311016,NUVEEN FUND ADVISORS, LLC',
    name: 'NUVEEN FUND ADVISORS, LLC',
    label: 'NUVEEN FUND ADVISORS, LLC',
  },
  {
    cik: '0001536141',
    value: '0001536141,NUWAVE INVESTMENT MANAGEMENT, LLC',
    name: 'NUWAVE INVESTMENT MANAGEMENT, LLC',
    label: 'NUWAVE INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001915315',
    value: '0001915315,NVEST FINANCIAL, LLC',
    name: 'NVEST FINANCIAL, LLC',
    label: 'NVEST FINANCIAL, LLC',
  },
  {
    cik: '0001697274',
    value: '00016972,NVWM, LLC',
    name: 'NVWM, LLC',
    label: 'NVWM, LLC',
  },
  {
    cik: '0001655006',
    value: '0001655,NWAM LLC6',
    name: 'NWAM LLC',
    label: 'NWAM LLC',
  },
  {
    cik: '0001103887',
    value: '0001103887,NWI MANAGEMENT LP',
    name: 'NWI MANAGEMENT LP',
    label: 'NWI MANAGEMENT LP',
  },
  {
    cik: '0001803054',
    value: '0001803054,NWK GROUP, INC.',
    name: 'NWK GROUP, INC.',
    label: 'NWK GROUP, INC.',
  },
  {
    cik: '0000763848',
    value: '0000763848,NWQ INVESTMENT MANAGEMENT COMPANY, LLC',
    name: 'NWQ INVESTMENT MANAGEMENT COMPANY, LLC',
    label: 'NWQ INVESTMENT MANAGEMENT COMPANY, LLC',
  },
  {
    cik: '0001606720',
    value: '0001606720,NYL INVESTORS LLC',
    name: 'NYL INVESTORS LLC',
    label: 'NYL INVESTORS LLC',
  },
  {
    cik: '0001816616',
    value: '0001816616,NZS CAPITAL, LLC',
    name: 'NZS CAPITAL, LLC',
    label: 'NZS CAPITAL, LLC',
  },
  {
    cik: '0001726375',
    value: '0001726375,OAK ASSET MANAGEMENT, LLC',
    name: 'OAK ASSET MANAGEMENT, LLC',
    label: 'OAK ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0000836372',
    value: '0000836372,OAK ASSOCIATES LTD /OH/',
    name: 'OAK ASSOCIATES LTD /OH/',
    label: 'OAK ASSOCIATES LTD /OH/',
  },
  {
    cik: '0001080114',
    value: '0001080114,OAKBROOK INVESTMENTS LLC',
    name: 'OAKBROOK INVESTMENTS LLC',
    label: 'OAKBROOK INVESTMENTS LLC',
  },
  {
    cik: '0001657335',
    value: '0001657335,OAKCLIFF CAPITAL PARTNERS, LP',
    name: 'OAKCLIFF CAPITAL PARTNERS, LP',
    label: 'OAKCLIFF CAPITAL PARTNERS, LP',
  },
  {
    cik: '0001846161',
    value: '0001846161,OAK FAMILY ADVISORS, LLC',
    name: 'OAK FAMILY ADVISORS, LLC',
    label: 'OAK FAMILY ADVISORS, LLC',
  },
  {
    cik: '0001661762',
    value: '0001661762,OAK GROVE CAPITAL LLC',
    name: 'OAK GROVE CAPITAL LLC',
    label: 'OAK GROVE CAPITAL LLC',
  },
  {
    cik: '0001912040',
    value: '0001912040,OAK HARBOR WEALTH PARTNERS, LLC',
    name: 'OAK HARBOR WEALTH PARTNERS, LLC',
    label: 'OAK HARBOR WEALTH PARTNERS, LLC',
  },
  {
    cik: '0001839430',
    value: '0001839430,OAK HARVEST INVESTMENT SERVICES',
    name: 'OAK HARVEST INVESTMENT SERVICES',
    label: 'OAK HARVEST INVESTMENT SERVICES',
  },
  {
    cik: '0001164688',
    value: '0001164688,OAK HILL ADVISORS LP',
    name: 'OAK HILL ADVISORS LP',
    label: 'OAK HILL ADVISORS LP',
  },
  {
    cik: '0001537607',
    value: '0001537607,OAK HILL CAPITAL MANAGEMENT, LLC',
    name: 'OAK HILL CAPITAL MANAGEMENT, LLC',
    label: 'OAK HILL CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001681681',
    value: '0001681681,OAKHURST ADVISORS,LLC',
    name: 'OAKHURST ADVISORS,LLC',
    label: 'OAKHURST ADVISORS,LLC',
  },
  {
    cik: '0001030818',
    value: '0001030818,OAK MANAGEMENT CORP',
    name: 'OAK MANAGEMENT CORP',
    label: 'OAK MANAGEMENT CORP',
  },
  {
    cik: '0000924171',
    value: '0000924171,OAKMONT CORP',
    name: 'OAKMONT CORP',
    label: 'OAKMONT CORP',
  },
  {
    cik: '0001631353',
    value: '0001631353,OAKMONT PARTNERS LLC',
    name: 'OAKMONT PARTNERS LLC',
    label: 'OAKMONT PARTNERS LLC',
  },
  {
    cik: '0001709443',
    value: '0001709443,OAK POINT WEALTH MANAGEMENT',
    name: 'OAK POINT WEALTH MANAGEMENT',
    label: 'OAK POINT WEALTH MANAGEMENT',
  },
  {
    cik: '0001054554',
    value: '0001054554,OAK RIDGE INVESTMENTS LLC',
    name: 'OAK RIDGE INVESTMENTS LLC',
    label: 'OAK RIDGE INVESTMENTS LLC',
  },
  {
    cik: '0001755987',
    value: '0001755987,OAK THISTLE LLC',
    name: 'OAK THISTLE LLC',
    label: 'OAK THISTLE LLC',
  },
  {
    cik: '0001550660',
    value: '0001550660,OAKTOP CAPITAL MANAGEMENT II, L.P.',
    name: 'OAKTOP CAPITAL MANAGEMENT II, L.P.',
    label: 'OAKTOP CAPITAL MANAGEMENT II, L.P.',
  },
  {
    cik: '0000949509',
    value: '0000949509,OAKTREE CAPITAL MANAGEMENT LP',
    name: 'OAKTREE CAPITAL MANAGEMENT LP',
    label: 'OAKTREE CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001822973',
    value: '0001822973,OAKTREE FUND ADVISORS, LLC',
    name: 'OAKTREE FUND ADVISORS, LLC',
    label: 'OAKTREE FUND ADVISORS, LLC',
  },
  {
    cik: '0001698006',
    value: '0001698006,OAKVIEW CAPITAL MANAGEMENT, L.P.',
    name: 'OAKVIEW CAPITAL MANAGEMENT, L.P.',
    label: 'OAKVIEW CAPITAL MANAGEMENT, L.P.',
  },
  {
    cik: '0001844024',
    value: '0001844024,OAKWELL PRIVATE WEALTH MANAGEMENT, LLC',
    name: 'OAKWELL PRIVATE WEALTH MANAGEMENT, LLC',
    label: 'OAKWELL PRIVATE WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001077148',
    value: '0001077148,OAKWOOD CAPITAL MANAGEMENT LLC/CA',
    name: 'OAKWOOD CAPITAL MANAGEMENT LLC/CA',
    label: 'OAKWOOD CAPITAL MANAGEMENT LLC/CA',
  },
  {
    cik: '0001588871',
    value: '0001588871,OAKWORTH CAPITAL, INC.',
    name: 'OAKWORTH CAPITAL, INC.',
    label: 'OAKWORTH CAPITAL, INC.',
  },
  {
    cik: '0001569667',
    value: '0001569667,OARSMAN CAPITAL, INC.',
    name: 'OARSMAN CAPITAL, INC.',
    label: 'OARSMAN CAPITAL, INC.',
  },
  {
    cik: '0001317904',
    value: '0001317904,OASIS MANAGEMENT CO LTD.',
    name: 'OASIS MANAGEMENT CO LTD.',
    label: 'OASIS MANAGEMENT CO LTD.',
  },
  {
    cik: '0001349654',
    value: '0001349654,OBERMEYER ASSET MANAGEMENT CO',
    name: 'OBERMEYER ASSET MANAGEMENT CO',
    label: 'OBERMEYER ASSET MANAGEMENT CO',
  },
  {
    cik: '0001349654',
    value: '0001349654,OBERMEYER WOOD INVESTMENT COUNSEL, LLLP',
    name: 'OBERMEYER WOOD INVESTMENT COUNSEL, LLLP',
    label: 'OBERMEYER WOOD INVESTMENT COUNSEL, LLLP',
  },
  {
    cik: '0001218663',
    value: '0001218663,OBERNDORF WILLIAM E',
    name: 'OBERNDORF WILLIAM E',
    label: 'OBERNDORF WILLIAM E',
  },
  {
    cik: '0000937886',
    value: '0000937886,OBERWEIS ASSET MANAGEMENT INC/',
    name: 'OBERWEIS ASSET MANAGEMENT INC/',
    label: 'OBERWEIS ASSET MANAGEMENT INC/',
  },
  {
    cik: '0001924152',
    value: '0001924152,OBJECTIVE CAPITAL MANAGEMENT, LLC',
    name: 'OBJECTIVE CAPITAL MANAGEMENT, LLC',
    label: 'OBJECTIVE CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001768635',
    value: '0001768635,O BRIEN GREENE & CO. INC',
    name: "O'BRIEN GREENE & CO. INC",
    label: "O'BRIEN GREENE & CO. INC",
  },
  {
    cik: '0001689874',
    value: '0001689874,O BRIEN WEALTH PARTNERS LLC',
    name: "O'BRIEN WEALTH PARTNERS LLC",
    label: "O'BRIEN WEALTH PARTNERS LLC",
  },
  {
    cik: '0001909128',
    value: '0001909128,OBSIDIAN PERSONAL PLANNING SOLUTIONS LLC',
    name: 'OBSIDIAN PERSONAL PLANNING SOLUTIONS LLC',
    label: 'OBSIDIAN PERSONAL PLANNING SOLUTIONS LLC',
  },
  {
    cik: '0001910451',
    value: '0001910451,OBVIOUS MANAGEMENT SERVICES, L.L.C.',
    name: 'OBVIOUS MANAGEMENT SERVICES, L.L.C.',
    label: 'OBVIOUS MANAGEMENT SERVICES, L.L.C.',
  },
  {
    cik: '0001770994',
    value: '0001770994,OCCIDENTAL ASSET MANAGEMENT, LLC',
    name: 'OCCIDENTAL ASSET MANAGEMENT, LLC',
    label: 'OCCIDENTAL ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001819697',
    value: '0001819697,OCCUDO QUANTITATIVE STRATEGIES LP',
    name: 'OCCUDO QUANTITATIVE STRATEGIES LP',
    label: 'OCCUDO QUANTITATIVE STRATEGIES LP',
  },
  {
    cik: '0001766724',
    value: '0001766724,OCEAN ARETE LTD',
    name: 'OCEAN ARETE LTD',
    label: 'OCEAN ARETE LTD',
  },
  {
    cik: '0001731260',
    value: '0001731260,OCEAN CAPITAL MANAGEMENT, LLC',
    name: 'OCEAN CAPITAL MANAGEMENT, LLC',
    label: 'OCEAN CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001803012',
    value: '0001803012,OCEAN ENDOWMENT PARTNERS, LLC',
    name: 'OCEAN ENDOWMENT PARTNERS, LLC',
    label: 'OCEAN ENDOWMENT PARTNERS, LLC',
  },
  {
    cik: '0001438258',
    value: '0001438258,OCEANIC INVESTMENT MANAGEMENT LTD',
    name: 'OCEANIC INVESTMENT MANAGEMENT LTD',
    label: 'OCEANIC INVESTMENT MANAGEMENT LTD',
  },
  {
    cik: '0001738126',
    value: '0001738126,OCEANLINK MANAGEMENT LTD.',
    name: 'OCEANLINK MANAGEMENT LTD.',
    label: 'OCEANLINK MANAGEMENT LTD.',
  },
  {
    cik: '0001720034',
    value: '0001720034,OCEANWOOD CAPITAL MANAGEMENT LTD',
    name: 'OCEANWOOD CAPITAL MANAGEMENT LTD',
    label: 'OCEANWOOD CAPITAL MANAGEMENT LTD',
  },
  {
    cik: '0001764525',
    value: '0001764525,OCO CAPITAL PARTNERS, L.P.',
    name: 'OCO CAPITAL PARTNERS, L.P.',
    label: 'OCO CAPITAL PARTNERS, L.P.',
  },
  {
    cik: '0001839435',
    value: '0001839435,OCTAGON CAPITAL ADVISORS LP',
    name: 'OCTAGON CAPITAL ADVISORS LP',
    label: 'OCTAGON CAPITAL ADVISORS LP',
  },
  {
    cik: '0001891904',
    value: '0001891904,OCTAHEDRON CAPITAL MANAGEMENT L.P.',
    name: 'OCTAHEDRON CAPITAL MANAGEMENT L.P.',
    label: 'OCTAHEDRON CAPITAL MANAGEMENT L.P.',
  },
  {
    cik: '0001841259',
    value: '0001841259,OCTAVIA WEALTH ADVISORS, LLC',
    name: 'OCTAVIA WEALTH ADVISORS, LLC',
    label: 'OCTAVIA WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001742569',
    value: '0001742569,O DELL GROUP, LLC',
    name: "O'DELL GROUP, LLC",
    label: "O'DELL GROUP, LLC",
  },
  {
    cik: '0001845109',
    value: '0001845109,ODER INVESTMENT MANAGEMENT, LLC',
    name: 'ODER INVESTMENT MANAGEMENT, LLC',
    label: 'ODER INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001512596',
    value: '0001512596,ODEY ASSET MANAGEMENT GROUP LTD',
    name: 'ODEY ASSET MANAGEMENT GROUP LTD',
    label: 'ODEY ASSET MANAGEMENT GROUP LTD',
  },
  {
    cik: '0001512597',
    value: '0001512597,ODEY HOLDINGS AG',
    name: 'ODEY HOLDINGS AG',
    label: 'ODEY HOLDINGS AG',
  },
  {
    cik: '0001877288',
    value: '0001877288,ODEY HOLDINGS LTD',
    name: 'ODEY HOLDINGS LTD',
    label: 'ODEY HOLDINGS LTD',
  },
  {
    cik: '0001730962',
    value: '0001730962,ODONNELL FINANCIAL SERVICES, LLC',
    name: 'ODONNELL FINANCIAL SERVICES, LLC',
    label: 'ODONNELL FINANCIAL SERVICES, LLC',
  },
  {
    cik: '0001073724',
    value: '0001073724,OECHSLE INTERNATIONAL ADVISORS, LLC',
    name: 'OECHSLE INTERNATIONAL ADVISORS, LLC',
    label: 'OECHSLE INTERNATIONAL ADVISORS, LLC',
  },
  {
    cik: '0001817681',
    value: '0001817681,OEP CAPITAL ADVISORS, L.P.',
    name: 'OEP CAPITAL ADVISORS, L.P.',
    label: 'OEP CAPITAL ADVISORS, L.P.',
  },
  {
    cik: '0001424071',
    value: '0001424071,OFFIT CAPITAL ADVISORS LLC',
    name: 'OFFIT CAPITAL ADVISORS LLC',
    label: 'OFFIT CAPITAL ADVISORS LLC',
  },
  {
    cik: '0001619083',
    value: '0001619083,OGBORNE CAPITAL MANAGEMENT, LLC',
    name: 'OGBORNE CAPITAL MANAGEMENT, LLC',
    label: 'OGBORNE CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0000714364',
    value: '0000714364,OGOREK ANTHONY JOSEPH /NY/ /ADV',
    name: 'OGOREK ANTHONY JOSEPH /NY/ /ADV',
    label: 'OGOREK ANTHONY JOSEPH /NY/ /ADV',
  },
  {
    cik: '0001600327',
    value: '0001600327,OHE FINANCIAL INC',
    name: 'OHE FINANCIAL INC',
    label: 'OHE FINANCIAL INC',
  },
  {
    cik: '0001573575',
    value: '0001573575,OHIO NATIONAL INVESTMENTS, INC.',
    name: 'OHIO NATIONAL INVESTMENTS, INC.',
    label: 'OHIO NATIONAL INVESTMENTS, INC.',
  },
  {
    cik: '0001594492',
    value: '0001594492,OHIO VALLEY FINANCIAL GROUP',
    name: 'OHIO VALLEY FINANCIAL GROUP',
    label: 'OHIO VALLEY FINANCIAL GROUP',
  },
  {
    cik: '0001548880',
    value: '0001548880,OIKOS HOLDINGS LLC',
    name: 'OIKOS HOLDINGS LLC',
    label: 'OIKOS HOLDINGS LLC',
  },
  {
    cik: '0001157519',
    value: '0001157519,OKABENA INVESTMENT SERVICES INC',
    name: 'OKABENA INVESTMENT SERVICES INC',
    label: 'OKABENA INVESTMENT SERVICES INC',
  },
  {
    cik: '0001780565',
    value: '0001780565,O KEEFE STEVENS ADVISORY, INC.',
    name: "O'KEEFE STEVENS ADVISORY, INC.",
    label: "O'KEEFE STEVENS ADVISORY, INC.",
  },
  {
    cik: '0001496147',
    value: '0001496147,OKUMUS FUND MANAGEMENT LTD.',
    name: 'OKUMUS FUND MANAGEMENT LTD.',
    label: 'OKUMUS FUND MANAGEMENT LTD.',
  },
  {
    cik: '0001571857',
    value: '0001571857,OLD BELLOWS PARTNERS LP',
    name: 'OLD BELLOWS PARTNERS LP',
    label: 'OLD BELLOWS PARTNERS LP',
  },
  {
    cik: '0001133385',
    value: '0001133385,OLD DOMINION CAPITAL MANAGEMENT INC',
    name: 'OLD DOMINION CAPITAL MANAGEMENT INC',
    label: 'OLD DOMINION CAPITAL MANAGEMENT INC',
  },
  {
    cik: '0001775530',
    value: '0001775530,OLDFATHER FINANCIAL SERVICES, LLC',
    name: 'OLDFATHER FINANCIAL SERVICES, LLC',
    label: 'OLDFATHER FINANCIAL SERVICES, LLC',
  },
  {
    cik: '0001492815',
    value: '0001492815,OLDFIELD PARTNERS LLP',
    name: 'OLDFIELD PARTNERS LLP',
    label: 'OLDFIELD PARTNERS LLP',
  },
  {
    cik: '0001455915',
    value: '0001455915,OLD MISSION CAPITAL LLC',
    name: 'OLD MISSION CAPITAL LLC',
    label: 'OLD MISSION CAPITAL LLC',
  },
  {
    cik: '0001431146',
    value: '0001431146,OLD MISSION CAPITAL, LLC',
    name: 'OLD MISSION CAPITAL, LLC',
    label: 'OLD MISSION CAPITAL, LLC',
  },
  {
    cik: '0001080386',
    value: '0001080386,OLD MUTUAL ASSET MANAGERS UK LTD',
    name: 'OLD MUTUAL ASSET MANAGERS UK LTD',
    label: 'OLD MUTUAL ASSET MANAGERS UK LTD',
  },
  {
    cik: '0001463524',
    value: '0001463524,OLD MUTUAL CUSTOMISED SOLUTIONS (PROPRIETARY) LTD',
    name: 'OLD MUTUAL CUSTOMISED SOLUTIONS (PROPRIETARY) LTD',
    label: 'OLD MUTUAL CUSTOMISED SOLUTIONS (PROPRIETARY) LTD',
  },
  {
    cik: '0001080386',
    value: '0001080386,OLD MUTUAL GLOBAL INVESTORS (UK) LTD.',
    name: 'OLD MUTUAL GLOBAL INVESTORS (UK) LTD.',
    label: 'OLD MUTUAL GLOBAL INVESTORS (UK) LTD.',
  },
  {
    cik: '0000707179',
    value: '0000707179,OLD NATIONAL BANCORP /IN/',
    name: 'OLD NATIONAL BANCORP /IN/',
    label: 'OLD NATIONAL BANCORP /IN/',
  },
  {
    cik: '0001760444',
    value: '0001760444,OLD NORTH STATE TRUST, LLC',
    name: 'OLD NORTH STATE TRUST, LLC',
    label: 'OLD NORTH STATE TRUST, LLC',
  },
  {
    cik: '0001102256',
    value: '0001102256,OLD POINT TRUST & FINANCIAL SERVICES N A',
    name: 'OLD POINT TRUST & FINANCIAL SERVICES N A',
    label: 'OLD POINT TRUST & FINANCIAL SERVICES N A',
  },
  {
    cik: '0001744348',
    value: '0001744348,OLD PORT ADVISORS',
    name: 'OLD PORT ADVISORS',
    label: 'OLD PORT ADVISORS',
  },
  {
    cik: '0000074260',
    value: '0000074260,OLD REPUBLIC INTERNATIONAL CORP',
    name: 'OLD REPUBLIC INTERNATIONAL CORP',
    label: 'OLD REPUBLIC INTERNATIONAL CORP',
  },
  {
    cik: '0000903783',
    value: '0000903783,OLD SECOND NATIONAL BANK OF AURORA',
    name: 'OLD SECOND NATIONAL BANK OF AURORA',
    label: 'OLD SECOND NATIONAL BANK OF AURORA',
  },
  {
    cik: '0001802131',
    value: '0001802131,OLD WELL PARTNERS, LLC',
    name: 'OLD WELL PARTNERS, LLC',
    label: 'OLD WELL PARTNERS, LLC',
  },
  {
    cik: '0001549358',
    value: '0001549358,OLD WEST INVESTMENT MANAGEMENT, LLC',
    name: 'OLD WEST INVESTMENT MANAGEMENT, LLC',
    label: 'OLD WEST INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001906766',
    value: '0001906766,OLIO FINANCIAL PLANNING',
    name: 'OLIO FINANCIAL PLANNING',
    label: 'OLIO FINANCIAL PLANNING',
  },
  {
    cik: '0001907568',
    value: '0001907568,OLIVER LAGORE VANVALIN INVESTMENT GROUP',
    name: 'OLIVER LAGORE VANVALIN INVESTMENT GROUP',
    label: 'OLIVER LAGORE VANVALIN INVESTMENT GROUP',
  },
  {
    cik: '0001666239',
    value: '0001666239,OLIVER LUXXE ASSETS LLC',
    name: 'OLIVER LUXXE ASSETS LLC',
    label: 'OLIVER LUXXE ASSETS LLC',
  },
  {
    cik: '0001738126',
    value: '0001738126,OLP CAPITAL MANAGEMENT LTD',
    name: 'OLP CAPITAL MANAGEMENT LTD',
    label: 'OLP CAPITAL MANAGEMENT LTD',
  },
  {
    cik: '0000947996',
    value: '0000947996,OLSTEIN CAPITAL MANAGEMENT, L.P.',
    name: 'OLSTEIN CAPITAL MANAGEMENT, L.P.',
    label: 'OLSTEIN CAPITAL MANAGEMENT, L.P.',
  },
  {
    cik: '0001841359',
    value: '0001841359,OLYMPUS PEAK ASSET MANAGEMENT LP',
    name: 'OLYMPUS PEAK ASSET MANAGEMENT LP',
    label: 'OLYMPUS PEAK ASSET MANAGEMENT LP',
  },
  {
    cik: '0000898202',
    value: '0000898202,OMEGA ADVISORS INC.',
    name: 'OMEGA ADVISORS INC.',
    label: 'OMEGA ADVISORS INC.',
  },
  {
    cik: '0001767982',
    value: '0001767982,OMEGA FINANCIAL GROUP, LLC',
    name: 'OMEGA FINANCIAL GROUP, LLC',
    label: 'OMEGA FINANCIAL GROUP, LLC',
  },
  {
    cik: '0001637359',
    value: '0001637359,OMEGA FUND MANAGEMENT, LLC',
    name: 'OMEGA FUND MANAGEMENT, LLC',
    label: 'OMEGA FUND MANAGEMENT, LLC',
  },
  {
    cik: '0001637359',
    value: '0001637359,OMEGA FUND MANAGEMENT (US) INC.',
    name: 'OMEGA FUND MANAGEMENT (US) INC.',
    label: 'OMEGA FUND MANAGEMENT (US) INC.',
  },
  {
    cik: '0001053321',
    value: '0001053321,OMERS ADMINISTRATION CORP',
    name: 'OMERS ADMINISTRATION CORP',
    label: 'OMERS ADMINISTRATION CORP',
  },
  {
    cik: '0001696136',
    value: '0001696136,OMNIA FAMILY WEALTH, LLC',
    name: 'OMNIA FAMILY WEALTH, LLC',
    label: 'OMNIA FAMILY WEALTH, LLC',
  },
  {
    cik: '0001911807',
    value: '0001911807,OMNI EVENT MANAGEMENT LTD',
    name: 'OMNI EVENT MANAGEMENT LTD',
    label: 'OMNI EVENT MANAGEMENT LTD',
  },
  {
    cik: '0001576497',
    value: '0001576497,OMNI PARTNERS LLP',
    name: 'OMNI PARTNERS LLP',
    label: 'OMNI PARTNERS LLP',
  },
  {
    cik: '0001800081',
    value: '0001800081,OMNI PARTNERS US LLC',
    name: 'OMNI PARTNERS US LLC',
    label: 'OMNI PARTNERS US LLC',
  },
  {
    cik: '0001698777',
    value: '0001698777,OMNISTAR FINANCIAL GROUP, INC.',
    name: 'OMNISTAR FINANCIAL GROUP, INC.',
    label: 'OMNISTAR FINANCIAL GROUP, INC.',
  },
  {
    cik: '0001513013',
    value: '0001513013,OMT CAPITAL MANAGEMENT LLC',
    name: 'OMT CAPITAL MANAGEMENT LLC',
    label: 'OMT CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001712892',
    value: '0001712892,ONCE CAPITAL MANAGEMENT, LLC',
    name: 'ONCE CAPITAL MANAGEMENT, LLC',
    label: 'ONCE CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001817181',
    value: '0001817181,ONE01 CAPITAL, LP',
    name: 'ONE01 CAPITAL, LP',
    label: 'ONE01 CAPITAL, LP',
  },
  {
    cik: '0001755535',
    value: '0001755535,ONE68 GLOBAL CAPITAL, LLC',
    name: 'ONE68 GLOBAL CAPITAL, LLC',
    label: 'ONE68 GLOBAL CAPITAL, LLC',
  },
  {
    cik: '0001819695',
    value: '0001819695,ONE ADVISORY PARTNERS, LLC',
    name: 'ONE ADVISORY PARTNERS, LLC',
    label: 'ONE ADVISORY PARTNERS, LLC',
  },
  {
    cik: '0001780985',
    value: '0001780985,ONEASCENT FINANCIAL SERVICES LLC',
    name: 'ONEASCENT FINANCIAL SERVICES LLC',
    label: 'ONEASCENT FINANCIAL SERVICES LLC',
  },
  {
    cik: '0001844707',
    value: '0001844707,ONEASCENT WEALTH MANAGEMENT LLC',
    name: 'ONEASCENT WEALTH MANAGEMENT LLC',
    label: 'ONEASCENT WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0001606588',
    value: '0001606588,ONE CAPITAL MANAGEMENT, LLC',
    name: 'ONE CAPITAL MANAGEMENT, LLC',
    label: 'ONE CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001800938',
    value: '0001800938,ONE CHARLES PRIVATE WEALTH SERVICES, LLC',
    name: 'ONE CHARLES PRIVATE WEALTH SERVICES, LLC',
    label: 'ONE CHARLES PRIVATE WEALTH SERVICES, LLC',
  },
  {
    cik: '0001823172',
    value: '0001823172,ONE DAY IN JULY LLC',
    name: 'ONE DAY IN JULY LLC',
    label: 'ONE DAY IN JULY LLC',
  },
  {
    cik: '0001753875',
    value: '0001753875,ONE FIN CAPITAL MANAGEMENT LP',
    name: 'ONE FIN CAPITAL MANAGEMENT LP',
    label: 'ONE FIN CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001861159',
    value: '0001861159,O NEIL GLOBAL ADVISORS, INC.',
    name: "O'NEIL GLOBAL ADVISORS, INC.",
    label: "O'NEIL GLOBAL ADVISORS, INC.",
  },
  {
    cik: '0001860132',
    value: '0001860132,ONE + ONE WEALTH MANAGEMENT, LLC',
    name: 'ONE + ONE WEALTH MANAGEMENT, LLC',
    label: 'ONE + ONE WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001860132',
    value: '0001860132,ONE PLUS ONE WEALTH MANAGEMENT, LLC',
    name: 'ONE PLUS ONE WEALTH MANAGEMENT, LLC',
    label: 'ONE PLUS ONE WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001679027',
    value: '0001679027,ONE TUSK INVESTMENT PARTNERS LP',
    name: 'ONE TUSK INVESTMENT PARTNERS LP',
    label: 'ONE TUSK INVESTMENT PARTNERS LP',
  },
  {
    cik: '0001725297',
    value: '0001725297,ONE WEALTH ADVISORS, LLC',
    name: 'ONE WEALTH ADVISORS, LLC',
    label: 'ONE WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001534300',
    value: '0001534300,ONE WILLIAM STREET CAPITAL MANAGEMENT, L.P.',
    name: 'ONE WILLIAM STREET CAPITAL MANAGEMENT, L.P.',
    label: 'ONE WILLIAM STREET CAPITAL MANAGEMENT, L.P.',
  },
  {
    cik: '0000937567',
    value: '0000937567,ONTARIO TEACHERS PENSION PLAN BOARD',
    name: 'ONTARIO TEACHERS PENSION PLAN BOARD',
    label: 'ONTARIO TEACHERS PENSION PLAN BOARD',
  },
  {
    cik: '0001819476',
    value: '0001819476,ONYX BRIDGE WEALTH GROUP LLC',
    name: 'ONYX BRIDGE WEALTH GROUP LLC',
    label: 'ONYX BRIDGE WEALTH GROUP LLC',
  },
  {
    cik: '0001957840',
    value: '0001957840,ONYX FINANCIAL ADVISORS, LLC',
    name: 'ONYX FINANCIAL ADVISORS, LLC',
    label: 'ONYX FINANCIAL ADVISORS, LLC',
  },
  {
    cik: '0001595855',
    value: '0001595855,OPALEYE MANAGEMENT INC.',
    name: 'OPALEYE MANAGEMENT INC.',
    label: 'OPALEYE MANAGEMENT INC.',
  },
  {
    cik: '0001844201',
    value: '0001844201,OPAL WEALTH ADVISORS, LLC',
    name: 'OPAL WEALTH ADVISORS, LLC',
    label: 'OPAL WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001597966',
    value: '0001597966,OPEN FIELD CAPITAL LLC',
    name: 'OPEN FIELD CAPITAL LLC',
    label: 'OPEN FIELD CAPITAL LLC',
  },
  {
    cik: '0001658115',
    value: '0001658115,OPENVIEW MANAGEMENT, LLC',
    name: 'OPENVIEW MANAGEMENT, LLC',
    label: 'OPENVIEW MANAGEMENT, LLC',
  },
  {
    cik: '0001661163',
    value: '0001661163,OPERA TRADING CAPITAL',
    name: 'OPERA TRADING CAPITAL',
    label: 'OPERA TRADING CAPITAL',
  },
  {
    cik: '0001909750',
    value: '0001909750,OPEROSE ADVISORS LLC',
    name: 'OPEROSE ADVISORS LLC',
    label: 'OPEROSE ADVISORS LLC',
  },
  {
    cik: '0001694948',
    value: '0001694948,OPES ADVISORS INC',
    name: 'OPES ADVISORS INC',
    label: 'OPES ADVISORS INC',
  },
  {
    cik: '0001766918',
    value: '0001766918,OPES WEALTH MANAGEMENT LLC',
    name: 'OPES WEALTH MANAGEMENT LLC',
    label: 'OPES WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0001844640',
    value: '0001844640,OPHIR ASSET MANAGEMENT PTY LTD',
    name: 'OPHIR ASSET MANAGEMENT PTY LTD',
    label: 'OPHIR ASSET MANAGEMENT PTY LTD',
  },
  {
    cik: '0001269119',
    value: '0001269119,OPPENHEIMER ASSET MANAGEMENT INC.',
    name: 'OPPENHEIMER ASSET MANAGEMENT INC.',
    label: 'OPPENHEIMER ASSET MANAGEMENT INC.',
  },
  {
    cik: '0001591377',
    value: '0001591377,OPPENHEIMER & CLOSE, LLC',
    name: 'OPPENHEIMER & CLOSE, LLC',
    label: 'OPPENHEIMER & CLOSE, LLC',
  },
  {
    cik: '0000822581',
    value: '0000822581,OPPENHEIMER & CO INC',
    name: 'OPPENHEIMER & CO INC',
    label: 'OPPENHEIMER & CO INC',
  },
  {
    cik: '0000728889',
    value: '0000728889,OPPENHEIMER FUNDS INC',
    name: 'OPPENHEIMER FUNDS INC',
    label: 'OPPENHEIMER FUNDS INC',
  },
  {
    cik: '0001632810',
    value: '0001632810,OPSEU PENSION PLAN TRUST FUND',
    name: 'OPSEU PENSION PLAN TRUST FUND',
    label: 'OPSEU PENSION PLAN TRUST FUND',
  },
  {
    cik: '0001802961',
    value: '000180296,OPTAS, LLC',
    name: 'OPTAS, LLC',
    label: 'OPTAS, LLC',
  },
  {
    cik: '0001738654',
    value: '0001738654,OPTI CAPITAL MANAGEMENT, LP',
    name: 'OPTI CAPITAL MANAGEMENT, LP',
    label: 'OPTI CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0001775361',
    value: '0001775361,OPTIFOUR INTEGRATED WEALTH MANAGEMENT, LLC',
    name: 'OPTIFOUR INTEGRATED WEALTH MANAGEMENT, LLC',
    label: 'OPTIFOUR INTEGRATED WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001744575',
    value: '0001744575,OPTIMA FUND MANAGEMENT LLC',
    name: 'OPTIMA FUND MANAGEMENT LLC',
    label: 'OPTIMA FUND MANAGEMENT LLC',
  },
  {
    cik: '0001651336',
    value: '0001651336,OPTIMAL ASSET MANAGEMENT, INC.',
    name: 'OPTIMAL ASSET MANAGEMENT, INC.',
    label: 'OPTIMAL ASSET MANAGEMENT, INC.',
  },
  {
    cik: '0001750585',
    value: '0001750585,OPTIMAS CAPITAL LTD',
    name: 'OPTIMAS CAPITAL LTD',
    label: 'OPTIMAS CAPITAL LTD',
  },
  {
    cik: '0001037763',
    value: '0001037763,OPTIMUM INVESTMENT ADVISORS',
    name: 'OPTIMUM INVESTMENT ADVISORS',
    label: 'OPTIMUM INVESTMENT ADVISORS',
  },
  {
    cik: '0001781079',
    value: '0001781079,OPTIMUM QUANTVEST CORP',
    name: 'OPTIMUM QUANTVEST CORP',
    label: 'OPTIMUM QUANTVEST CORP',
  },
  {
    cik: '0001732539',
    value: '0001732539,OPTIMUS PRIME FUND MANAGEMENT CO., LTD.',
    name: 'OPTIMUS PRIME FUND MANAGEMENT CO., LTD.',
    label: 'OPTIMUS PRIME FUND MANAGEMENT CO., LTD.',
  },
  {
    cik: '0001794972',
    value: '0001794972,OPTIONS SOLUTIONS, LLC',
    name: 'OPTIONS SOLUTIONS, LLC',
    label: 'OPTIONS SOLUTIONS, LLC',
  },
  {
    cik: '0001859606',
    value: '0001859606,OPTIVER HOLDING B.V.',
    name: 'OPTIVER HOLDING B.V.',
    label: 'OPTIVER HOLDING B.V.',
  },
  {
    cik: '0001716559',
    value: '0001716559,OPTIVER US LLC',
    name: 'OPTIVER US LLC',
    label: 'OPTIVER US LLC',
  },
  {
    cik: '0001330325',
    value: '0001330325,OPUS CAPITAL GROUP, LLC',
    name: 'OPUS CAPITAL GROUP, LLC',
    label: 'OPUS CAPITAL GROUP, LLC',
  },
  {
    cik: '0001097833',
    value: '0001097833,OPUS INVESTMENT MANAGEMENT INC',
    name: 'OPUS INVESTMENT MANAGEMENT INC',
    label: 'OPUS INVESTMENT MANAGEMENT INC',
  },
  {
    cik: '0001600175',
    value: '0001600175,OPUS POINT PARTNERS MANAGEMENT, LLC',
    name: 'OPUS POINT PARTNERS MANAGEMENT, LLC',
    label: 'OPUS POINT PARTNERS MANAGEMENT, LLC',
  },
  {
    cik: '0001012622',
    value: '0001012622,ORACLE INVESTMENT MANAGEMENT INC',
    name: 'ORACLE INVESTMENT MANAGEMENT INC',
    label: 'ORACLE INVESTMENT MANAGEMENT INC',
  },
  {
    cik: '0001426756',
    value: '0001426756,ORANGE CAPITAL, LLC',
    name: 'ORANGE CAPITAL, LLC',
    label: 'ORANGE CAPITAL, LLC',
  },
  {
    cik: '0001055951',
    value: '0001055951,ORBIMED ADVISORS LLC',
    name: 'ORBIMED ADVISORS LLC',
    label: 'ORBIMED ADVISORS LLC',
  },
  {
    cik: '0001663865',
    value: '0001663865,ORBIS ALLAN GRAY LTD',
    name: 'ORBIS ALLAN GRAY LTD',
    label: 'ORBIS ALLAN GRAY LTD',
  },
  {
    cik: '0001640626',
    value: '0001640626,ORBIS FINANCIAL LTD',
    name: 'ORBIS FINANCIAL LTD',
    label: 'ORBIS FINANCIAL LTD',
  },
  {
    cik: '0001086504',
    value: '0001086504,ORBIS HOLDINGS LTD',
    name: 'ORBIS HOLDINGS LTD',
    label: 'ORBIS HOLDINGS LTD',
  },
  {
    cik: '0001598147',
    value: '0001598147,ORBIS INVESTMENT MANAGEMENT (U.S.), L.P.',
    name: 'ORBIS INVESTMENT MANAGEMENT (U.S.), L.P.',
    label: 'ORBIS INVESTMENT MANAGEMENT (U.S.), L.P.',
  },
  {
    cik: '0001399486',
    value: '0001399486,ORBIS WORLD LTD',
    name: 'ORBIS WORLD LTD',
    label: 'ORBIS WORLD LTD',
  },
  {
    cik: '0001512808',
    value: '0001512808,ORBITRONIX LP',
    name: 'ORBITRONIX LP',
    label: 'ORBITRONIX LP',
  },
  {
    cik: '0001511229',
    value: '0001511229,ORCA INVESTMENT MANAGEMENT, LLC',
    name: 'ORCA INVESTMENT MANAGEMENT, LLC',
    label: 'ORCA INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001905367',
    value: '0001905367,ORCAM FINANCIAL GROUP',
    name: 'ORCAM FINANCIAL GROUP',
    label: 'ORCAM FINANCIAL GROUP',
  },
  {
    cik: '0001858319',
    value: '0001858319,ORCHARD CAPITAL MANAGEMENT, LLC',
    name: 'ORCHARD CAPITAL MANAGEMENT, LLC',
    label: 'ORCHARD CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001858319',
    value: '0001858319,ORCHARD CAPITAL MANAGMENT, LLC',
    name: 'ORCHARD CAPITAL MANAGMENT, LLC',
    label: 'ORCHARD CAPITAL MANAGMENT, LLC',
  },
  {
    cik: '0001565989',
    value: '0001565989,ORCHARD HILL CAPITAL MANAGEMENT LP',
    name: 'ORCHARD HILL CAPITAL MANAGEMENT LP',
    label: 'ORCHARD HILL CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001729675',
    value: '0001729675,ORDINAL HOLDINGS MANAGECO, LP',
    name: 'ORDINAL HOLDINGS MANAGECO, LP',
    label: 'ORDINAL HOLDINGS MANAGECO, LP',
  },
  {
    cik: '0001833471',
    value: '0001833471,OREGON HEALTH & SCIENCE UNIVERSITY',
    name: 'OREGON HEALTH & SCIENCE UNIVERSITY',
    label: 'OREGON HEALTH & SCIENCE UNIVERSITY',
  },
  {
    cik: '0001107314',
    value: '0001107314,OREGON PUBLIC EMPLOYEES RETIREMENT FUND',
    name: 'OREGON PUBLIC EMPLOYEES RETIREMENT FUND',
    label: 'OREGON PUBLIC EMPLOYEES RETIREMENT FUND',
  },
  {
    cik: '0001658535',
    value: '0001658535,ORGEL WEALTH MANAGEMENT, LLC',
    name: 'ORGEL WEALTH MANAGEMENT, LLC',
    label: 'ORGEL WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001661140',
    value: '0001661140,ORIBEL CAPITAL MANAGEMENT, LP',
    name: 'ORIBEL CAPITAL MANAGEMENT, LP',
    label: 'ORIBEL CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0001484040',
    value: '0001484040,ORIGIN ASSET MANAGEMENT LLP',
    name: 'ORIGIN ASSET MANAGEMENT LLP',
    label: 'ORIGIN ASSET MANAGEMENT LLP',
  },
  {
    cik: '0001502631',
    value: '0001502631,ORINDA ASSET MANAGEMENT LLC',
    name: 'ORINDA ASSET MANAGEMENT LLC',
    label: 'ORINDA ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001844227',
    value: '0001844227,ORIN GREEN FINANCIAL, LLC',
    name: 'ORIN GREEN FINANCIAL, LLC',
    label: 'ORIN GREEN FINANCIAL, LLC',
  },
  {
    cik: '0001846758',
    value: '0001846758,ORION CAPITAL MANAGEMENT LLC',
    name: 'ORION CAPITAL MANAGEMENT LLC',
    label: 'ORION CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001802647',
    value: '0001802647,ORION PORTFOLIO SOLUTIONS, LLC',
    name: 'ORION PORTFOLIO SOLUTIONS, LLC',
    label: 'ORION PORTFOLIO SOLUTIONS, LLC',
  },
  {
    cik: '0001070304',
    value: '00010703,ORIX CORP',
    name: 'ORIX CORP',
    label: 'ORIX CORP',
  },
  {
    cik: '0001602159',
    value: '0001602159,ORIX CORP USA',
    name: 'ORIX CORP USA',
    label: 'ORIX CORP USA',
  },
  {
    cik: '0001550093',
    value: '0001550093,ORLAND PROPERTIES LTD',
    name: 'ORLAND PROPERTIES LTD',
    label: 'ORLAND PROPERTIES LTD',
  },
  {
    cik: '0001013234',
    value: '0001013234,ORLEANS CAPITAL MANAGEMENT CORP/LA',
    name: 'ORLEANS CAPITAL MANAGEMENT CORP/LA',
    label: 'ORLEANS CAPITAL MANAGEMENT CORP/LA',
  },
  {
    cik: '0001911468',
    value: '0001911468,O ROURKE & COMPANY, INC',
    name: "O'ROURKE & COMPANY, INC",
    label: "O'ROURKE & COMPANY, INC",
  },
  {
    cik: '0001227068',
    value: '0001227068,ORRELL CAPITAL MANAGEMENT INC',
    name: 'ORRELL CAPITAL MANAGEMENT INC',
    label: 'ORRELL CAPITAL MANAGEMENT INC',
  },
  {
    cik: '0000826154',
    value: '0000826154,ORRSTOWN FINANCIAL SERVICES INC',
    name: 'ORRSTOWN FINANCIAL SERVICES INC',
    label: 'ORRSTOWN FINANCIAL SERVICES INC',
  },
  {
    cik: '0001785717',
    value: '0001785717,ORSER CAPITAL MANAGEMENT, LLC',
    name: 'ORSER CAPITAL MANAGEMENT, LLC',
    label: 'ORSER CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001784418',
    value: '0001784418,OSBON CAPITAL MANAGEMENT LLC',
    name: 'OSBON CAPITAL MANAGEMENT LLC',
    label: 'OSBON CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001005607',
    value: '0001005607,OSBORNE PARTNERS CAPITAL MANAGEMENT',
    name: 'OSBORNE PARTNERS CAPITAL MANAGEMENT',
    label: 'OSBORNE PARTNERS CAPITAL MANAGEMENT',
  },
  {
    cik: '0001005607',
    value: '0001005607,OSBORNE PARTNERS CAPITAL MANAGEMENT, LLC',
    name: 'OSBORNE PARTNERS CAPITAL MANAGEMENT, LLC',
    label: 'OSBORNE PARTNERS CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001583000',
    value: '0001583000,OSBORN ROHS WILLIAMS & DONOHOE LTD',
    name: 'OSBORN ROHS WILLIAMS & DONOHOE LTD',
    label: 'OSBORN ROHS WILLIAMS & DONOHOE LTD',
  },
  {
    cik: '0001672775',
    value: '0001672775,O SHARES INVESTMENT ADVISERS, LLC',
    name: "O'SHARES INVESTMENT ADVISERS, LLC",
    label: "O'SHARES INVESTMENT ADVISERS, LLC",
  },
  {
    cik: '0001423442',
    value: '0001423442,O SHAUGHNESSY ASSET MANAGEMENT, LLC',
    name: "O'SHAUGHNESSY ASSET MANAGEMENT, LLC",
    label: "O'SHAUGHNESSY ASSET MANAGEMENT, LLC",
  },
  {
    cik: '0001569174',
    value: '0001569174,OSHER VAN DE VOORDE INVESTMENT MANAGEMENT',
    name: 'OSHER VAN DE VOORDE INVESTMENT MANAGEMENT',
    label: 'OSHER VAN DE VOORDE INVESTMENT MANAGEMENT',
  },
  {
    cik: '0001529832',
    value: '0001529832,OSKIE CAPITAL MANAGEMENT, LLC',
    name: 'OSKIE CAPITAL MANAGEMENT, LLC',
    label: 'OSKIE CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001512590',
    value: '0001512590,OSLO ASSET MANAGEMENT AS',
    name: 'OSLO ASSET MANAGEMENT AS',
    label: 'OSLO ASSET MANAGEMENT AS',
  },
  {
    cik: '0001316729',
    value: '0001316729,OSMIUM PARTNERS, LLC',
    name: 'OSMIUM PARTNERS, LLC',
    label: 'OSMIUM PARTNERS, LLC',
  },
  {
    cik: '0001326533',
    value: '0001326533,OSPRAIE MANAGEMENT, LLC',
    name: 'OSPRAIE MANAGEMENT, LLC',
    label: 'OSPRAIE MANAGEMENT, LLC',
  },
  {
    cik: '0001767471',
    value: '00017,OSSIAM471',
    name: 'OSSIAM',
    label: 'OSSIAM',
  },
  {
    cik: '0001007524',
    value: '0001007524,OSTERWEIS CAPITAL MANAGEMENT INC',
    name: 'OSTERWEIS CAPITAL MANAGEMENT INC',
    label: 'OSTERWEIS CAPITAL MANAGEMENT INC',
  },
  {
    cik: '0001767945',
    value: '0001767945,OSTRUM ASSET MANAGEMENT',
    name: 'OSTRUM ASSET MANAGEMENT',
    label: 'OSTRUM ASSET MANAGEMENT',
  },
  {
    cik: '0001166767',
    value: '0001166767,OSTRUM ASSET MANAGEMENT',
    name: 'OSTRUM ASSET MANAGEMENT',
    label: 'OSTRUM ASSET MANAGEMENT',
  },
  {
    cik: '0001696259',
    value: '0001696259,OSTRUM ASSET MANAGEMENT U.S., LLC',
    name: 'OSTRUM ASSET MANAGEMENT U.S., LLC',
    label: 'OSTRUM ASSET MANAGEMENT U.S., LLC',
  },
  {
    cik: '0001317346',
    value: '0001317346,OTA FINANCIAL GROUP L.P.',
    name: 'OTA FINANCIAL GROUP L.P.',
    label: 'OTA FINANCIAL GROUP L.P.',
  },
  {
    cik: '0001268580',
    value: '000126,OTA LLC',
    name: 'OTA LLC',
    label: 'OTA LLC',
  },
  {
    cik: '0001640361',
    value: '0001640361,OTTER CREEK ADVISORS, LLC',
    name: 'OTTER CREEK ADVISORS, LLC',
    label: 'OTTER CREEK ADVISORS, LLC',
  },
  {
    cik: '0001226779',
    value: '0001226779,OTTER CREEK MANAGEMENT INC',
    name: 'OTTER CREEK MANAGEMENT INC',
    label: 'OTTER CREEK MANAGEMENT INC',
  },
  {
    cik: '0001880124',
    value: '0001880124,OUP MANAGEMENT CO., LLC',
    name: 'OUP MANAGEMENT CO., LLC',
    label: 'OUP MANAGEMENT CO., LLC',
  },
  {
    cik: '0001321219',
    value: '0001321219,OUTFITTER ADVISORS, LTD.',
    name: 'OUTFITTER ADVISORS, LTD.',
    label: 'OUTFITTER ADVISORS, LTD.',
  },
  {
    cik: '0001846505',
    value: '0001846505,OUTFITTER FINANCIAL LLC',
    name: 'OUTFITTER FINANCIAL LLC',
    label: 'OUTFITTER FINANCIAL LLC',
  },
  {
    cik: '0001767151',
    value: '0001767151,OUTLOOK WEALTH ADVISORS, LLC',
    name: 'OUTLOOK WEALTH ADVISORS, LLC',
    label: 'OUTLOOK WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001724517',
    value: '0001724517,OVATA CAPITAL MANAGEMENT LTD',
    name: 'OVATA CAPITAL MANAGEMENT LTD',
    label: 'OVATA CAPITAL MANAGEMENT LTD',
  },
  {
    cik: '0001269786',
    value: '0001269786,OVERBROOK MANAGEMENT CORP',
    name: 'OVERBROOK MANAGEMENT CORP',
    label: 'OVERBROOK MANAGEMENT CORP',
  },
  {
    cik: '0001508612',
    value: '0001508612,OVERLAND ADVISORS, LLC',
    name: 'OVERLAND ADVISORS, LLC',
    label: 'OVERLAND ADVISORS, LLC',
  },
  {
    cik: '0001569857',
    value: '0001569857,OVERLOOK HOLDINGS LTD',
    name: 'OVERLOOK HOLDINGS LTD',
    label: 'OVERLOOK HOLDINGS LTD',
  },
  {
    cik: '0001313756',
    value: '0001313756,OWL CREEK ASSET MANAGEMENT, L.P.',
    name: 'OWL CREEK ASSET MANAGEMENT, L.P.',
    label: 'OWL CREEK ASSET MANAGEMENT, L.P.',
  },
  {
    cik: '0001845806',
    value: '0001845806,OWLS NEST PARTNERS IA, LLC',
    name: 'OWLS NEST PARTNERS IA, LLC',
    label: 'OWLS NEST PARTNERS IA, LLC',
  },
  {
    cik: '0001656735',
    value: '0001656735,OWNERSHIP CAPITAL B.V.',
    name: 'OWNERSHIP CAPITAL B.V.',
    label: 'OWNERSHIP CAPITAL B.V.',
  },
  {
    cik: '0001564770',
    value: '0001564770,OXBOW ADVISORS, LLC',
    name: 'OXBOW ADVISORS, LLC',
    label: 'OXBOW ADVISORS, LLC',
  },
  {
    cik: '0001713269',
    value: '0001713269,OXBOW CAPITAL MANAGEMENT (HK) LTD',
    name: 'OXBOW CAPITAL MANAGEMENT (HK) LTD',
    label: 'OXBOW CAPITAL MANAGEMENT (HK) LTD',
  },
  {
    cik: '0001438284',
    value: '0001438284,OXFORD ASSET MANAGEMENT',
    name: 'OXFORD ASSET MANAGEMENT',
    label: 'OXFORD ASSET MANAGEMENT',
  },
  {
    cik: '0001245862',
    value: '0001245862,OXFORD FINANCIAL GROUP LTD',
    name: 'OXFORD FINANCIAL GROUP LTD',
    label: 'OXFORD FINANCIAL GROUP LTD',
  },
  {
    cik: '0001759320',
    value: '0001759320,OXINAS PARTNERS WEALTH MANAGEMENT LLC',
    name: 'OXINAS PARTNERS WEALTH MANAGEMENT LLC',
    label: 'OXINAS PARTNERS WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0001846002',
    value: '0001846002,OXLER PRIVATE WEALTH LLC',
    name: 'OXLER PRIVATE WEALTH LLC',
    label: 'OXLER PRIVATE WEALTH LLC',
  },
  {
    cik: '0001054587',
    value: '0001054587,OZ MANAGEMENT LP',
    name: 'OZ MANAGEMENT LP',
    label: 'OZ MANAGEMENT LP',
  },
  {
    cik: '0001406313',
    value: '0001406313,P2 CAPITAL PARTNERS, LLC',
    name: 'P2 CAPITAL PARTNERS, LLC',
    label: 'P2 CAPITAL PARTNERS, LLC',
  },
  {
    cik: '0001611457',
    value: '0001611457,PACAD INVESTMENT LTD.',
    name: 'PACAD INVESTMENT LTD.',
    label: 'PACAD INVESTMENT LTD.',
  },
  {
    cik: '0001616667',
    value: '0001616667,PACER ADVISORS, INC.',
    name: 'PACER ADVISORS, INC.',
    label: 'PACER ADVISORS, INC.',
  },
  {
    cik: '0001819919',
    value: '0001819919,PACES FERRY WEALTH ADVISORS, LLC',
    name: 'PACES FERRY WEALTH ADVISORS, LLC',
    label: 'PACES FERRY WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001563634',
    value: '0001563634,PACHIRA INVESTMENTS INC.',
    name: 'PACHIRA INVESTMENTS INC.',
    label: 'PACHIRA INVESTMENTS INC.',
  },
  {
    cik: '0001486713',
    value: '0001486713,PACIFICA CAPITAL INVESTMENTS, LLC',
    name: 'PACIFICA CAPITAL INVESTMENTS, LLC',
    label: 'PACIFICA CAPITAL INVESTMENTS, LLC',
  },
  {
    cik: '0001357372',
    value: '0001357372,PACIFIC ALTERNATIVE ASSET MANAGEMENT COMPANY, LLC',
    name: 'PACIFIC ALTERNATIVE ASSET MANAGEMENT COMPANY, LLC',
    label: 'PACIFIC ALTERNATIVE ASSET MANAGEMENT COMPANY, LLC',
  },
  {
    cik: '0001799900',
    value: '0001799900,PACIFICA PARTNERS INC.',
    name: 'PACIFICA PARTNERS INC.',
    label: 'PACIFICA PARTNERS INC.',
  },
  {
    cik: '0001844577',
    value: '0001844577,PACIFIC ASSET MANAGEMENT LLC',
    name: 'PACIFIC ASSET MANAGEMENT LLC',
    label: 'PACIFIC ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001803523',
    value: '0001803523,PACIFIC CAPITAL WEALTH ADVISORS, INC',
    name: 'PACIFIC CAPITAL WEALTH ADVISORS, INC',
    label: 'PACIFIC CAPITAL WEALTH ADVISORS, INC',
  },
  {
    cik: '0001698222',
    value: '0001698222,PACIFIC CENTER FOR FINANCIAL SERVICES',
    name: 'PACIFIC CENTER FOR FINANCIAL SERVICES',
    label: 'PACIFIC CENTER FOR FINANCIAL SERVICES',
  },
  {
    cik: '0001768887',
    value: '0001768887,PACIFIC EDGE ADVISORS, LLC',
    name: 'PACIFIC EDGE ADVISORS, LLC',
    label: 'PACIFIC EDGE ADVISORS, LLC',
  },
  {
    cik: '0001279926',
    value: '0001279926,PACIFIC FINANCIAL GROUP INC',
    name: 'PACIFIC FINANCIAL GROUP INC',
    label: 'PACIFIC FINANCIAL GROUP INC',
  },
  {
    cik: '0000908431',
    value: '0000908431,PACIFIC GLOBAL INVESTMENT MANAGEMENT CO',
    name: 'PACIFIC GLOBAL INVESTMENT MANAGEMENT CO',
    label: 'PACIFIC GLOBAL INVESTMENT MANAGEMENT CO',
  },
  {
    cik: '0001637187',
    value: '0001637187,PACIFIC GROVE CAPITAL LP',
    name: 'PACIFIC GROVE CAPITAL LP',
    label: 'PACIFIC GROVE CAPITAL LP',
  },
  {
    cik: '0001323414',
    value: '0001323414,PACIFIC HEIGHTS ASSET MANAGEMENT LLC',
    name: 'PACIFIC HEIGHTS ASSET MANAGEMENT LLC',
    label: 'PACIFIC HEIGHTS ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001591546',
    value: '0001591546,PACIFIC RIDGE CAPITAL PARTNERS, LLC',
    name: 'PACIFIC RIDGE CAPITAL PARTNERS, LLC',
    label: 'PACIFIC RIDGE CAPITAL PARTNERS, LLC',
  },
  {
    cik: '0000947517',
    value: '0000947517,PACIFIC SUN FINANCIAL CORP',
    name: 'PACIFIC SUN FINANCIAL CORP',
    label: 'PACIFIC SUN FINANCIAL CORP',
  },
  {
    cik: '0001729381',
    value: '0001729381,PACIFIC VIEW ASSET MANAGEMENT, LLC',
    name: 'PACIFIC VIEW ASSET MANAGEMENT, LLC',
    label: 'PACIFIC VIEW ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001852338',
    value: '0001852338,PACIFIC WEALTH MANAGEMENT',
    name: 'PACIFIC WEALTH MANAGEMENT',
    label: 'PACIFIC WEALTH MANAGEMENT',
  },
  {
    cik: '0001766571',
    value: '0001766571,PACIFIC WEALTH STRATEGIES GROUP, INC.',
    name: 'PACIFIC WEALTH STRATEGIES GROUP, INC.',
    label: 'PACIFIC WEALTH STRATEGIES GROUP, INC.',
  },
  {
    cik: '0001793269',
    value: '0001793269,PACITTI GROUP INC.',
    name: 'PACITTI GROUP INC.',
    label: 'PACITTI GROUP INC.',
  },
  {
    cik: '0001664017',
    value: '0001664017,PACKER & CO LTD',
    name: 'PACKER & CO LTD',
    label: 'PACKER & CO LTD',
  },
  {
    cik: '0001907212',
    value: '0001907212,PACK PRIVATE WEALTH, LLC',
    name: 'PACK PRIVATE WEALTH, LLC',
    label: 'PACK PRIVATE WEALTH, LLC',
  },
  {
    cik: '0001732988',
    value: '0001732988,PACTIV GENERAL EMPLOYEE BENEFIT TRUST',
    name: 'PACTIV GENERAL EMPLOYEE BENEFIT TRUST',
    label: 'PACTIV GENERAL EMPLOYEE BENEFIT TRUST',
  },
  {
    cik: '0001304762',
    value: '0001304762,PACWEST FINANCIAL MANAGEMENT INC',
    name: 'PACWEST FINANCIAL MANAGEMENT INC',
    label: 'PACWEST FINANCIAL MANAGEMENT INC',
  },
  {
    cik: '0001592615',
    value: '0001592615,PAGE ARTHUR B',
    name: 'PAGE ARTHUR B',
    label: 'PAGE ARTHUR B',
  },
  {
    cik: '0001698215',
    value: '0001698215,PAGNATOKARP PARTNERS LLC',
    name: 'PAGNATOKARP PARTNERS LLC',
    label: 'PAGNATOKARP PARTNERS LLC',
  },
  {
    cik: '0001620822',
    value: '0001620822,PAGODA ASSET MANAGEMENT, LP',
    name: 'PAGODA ASSET MANAGEMENT, LP',
    label: 'PAGODA ASSET MANAGEMENT, LP',
  },
  {
    cik: '0001899703',
    value: '0001899703,PALADIN ADVISORY GROUP, LLC',
    name: 'PALADIN ADVISORY GROUP, LLC',
    label: 'PALADIN ADVISORY GROUP, LLC',
  },
  {
    cik: '0001321655',
    value: '0001321655,PALANTIR TECHNOLOGIES INC.',
    name: 'PALANTIR TECHNOLOGIES INC.',
    label: 'PALANTIR TECHNOLOGIES INC.',
  },
  {
    cik: '0001568788',
    value: '0001568788,PALESTRA CAPITAL MANAGEMENT LLC',
    name: 'PALESTRA CAPITAL MANAGEMENT LLC',
    label: 'PALESTRA CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001434323',
    value: '0001434323,PALISADE ASSET MANAGEMENT, LLC',
    name: 'PALISADE ASSET MANAGEMENT, LLC',
    label: 'PALISADE ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001009006',
    value: '0001009006,PALISADE CAPITAL MANAGEMENT LLC/NJ',
    name: 'PALISADE CAPITAL MANAGEMENT LLC/NJ',
    label: 'PALISADE CAPITAL MANAGEMENT LLC/NJ',
  },
  {
    cik: '0001388443',
    value: '0001388443,PALISADES HUDSON ASSET MANAGEMENT, L.P.',
    name: 'PALISADES HUDSON ASSET MANAGEMENT, L.P.',
    label: 'PALISADES HUDSON ASSET MANAGEMENT, L.P.',
  },
  {
    cik: '0001427373',
    value: '0001427373,PALISADES INVESTMENT PARTNERS, LLC',
    name: 'PALISADES INVESTMENT PARTNERS, LLC',
    label: 'PALISADES INVESTMENT PARTNERS, LLC',
  },
  {
    cik: '0001629271',
    value: '0001629271,PALLADIEM, LLC',
    name: 'PALLADIEM, LLC',
    label: 'PALLADIEM, LLC',
  },
  {
    cik: '0001436871',
    value: '0001436871,PALLADIUM PARTNERS LLC',
    name: 'PALLADIUM PARTNERS LLC',
    label: 'PALLADIUM PARTNERS LLC',
  },
  {
    cik: '0001862282',
    value: '0001862282,PALLAS CAPITAL ADVISORS LLC',
    name: 'PALLAS CAPITAL ADVISORS LLC',
    label: 'PALLAS CAPITAL ADVISORS LLC',
  },
  {
    cik: '0001881490',
    value: '0001881490,PALLISER CAPITAL (UK) LTD',
    name: 'PALLISER CAPITAL (UK) LTD',
    label: 'PALLISER CAPITAL (UK) LTD',
  },
  {
    cik: '0001767735',
    value: '0001767735,PALMER KNIGHT CO',
    name: 'PALMER KNIGHT CO',
    label: 'PALMER KNIGHT CO',
  },
  {
    cik: '0001306923',
    value: '0001306923,PALO ALTO INVESTORS LP',
    name: 'PALO ALTO INVESTORS LP',
    label: 'PALO ALTO INVESTORS LP',
  },
  {
    cik: '0001615963',
    value: '0001615963,PALO CAPITAL, INC.',
    name: 'PALO CAPITAL, INC.',
    label: 'PALO CAPITAL, INC.',
  },
  {
    cik: '0001103882',
    value: '0001103882,PALOMA PARTNERS MANAGEMENT CO',
    name: 'PALOMA PARTNERS MANAGEMENT CO',
    label: 'PALOMA PARTNERS MANAGEMENT CO',
  },
  {
    cik: '0001056516',
    value: '0001056516,PALOUSE CAPITAL MANAGEMENT, INC.',
    name: 'PALOUSE CAPITAL MANAGEMENT, INC.',
    label: 'PALOUSE CAPITAL MANAGEMENT, INC.',
  },
  {
    cik: '0001846310',
    value: '0001846310,PALUMBO WEALTH MANAGEMENT LLC',
    name: 'PALUMBO WEALTH MANAGEMENT LLC',
    label: 'PALUMBO WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0001554913',
    value: '0001554913,PAMPLONA CAPITAL MANAGEMENT, LLC',
    name: 'PAMPLONA CAPITAL MANAGEMENT, LLC',
    label: 'PAMPLONA CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0000883677',
    value: '0000883677,PANAGORA ASSET MANAGEMENT INC',
    name: 'PANAGORA ASSET MANAGEMENT INC',
    label: 'PANAGORA ASSET MANAGEMENT INC',
  },
  {
    cik: '0001564220',
    value: '0001564220,PANNING CAPITAL MANAGEMENT, LP',
    name: 'PANNING CAPITAL MANAGEMENT, LP',
    label: 'PANNING CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0001802531',
    value: '0001802531,PANTHEON INVESTMENT GROUP, LLC',
    name: 'PANTHEON INVESTMENT GROUP, LLC',
    label: 'PANTHEON INVESTMENT GROUP, LLC',
  },
  {
    cik: '0001603497',
    value: '0001603497,PANTHERA INVESTMENT GMBH',
    name: 'PANTHERA INVESTMENT GMBH',
    label: 'PANTHERA INVESTMENT GMBH',
  },
  {
    cik: '0001797217',
    value: '0001797217,PANVIEW ASIAN EQUITY MASTER FUND',
    name: 'PANVIEW ASIAN EQUITY MASTER FUND',
    label: 'PANVIEW ASIAN EQUITY MASTER FUND',
  },
  {
    cik: '0000809339',
    value: '0000809339,PAPP L ROY & ASSOCIATES',
    name: 'PAPP L ROY & ASSOCIATES',
    label: 'PAPP L ROY & ASSOCIATES',
  },
  {
    cik: '0001068809',
    value: '0001068809,PARA ADVISORS LLC',
    name: 'PARA ADVISORS LLC',
    label: 'PARA ADVISORS LLC',
  },
  {
    cik: '0001648101',
    value: '0001648101,PARACLE ADVISORS, LLC',
    name: 'PARACLE ADVISORS, LLC',
    label: 'PARACLE ADVISORS, LLC',
  },
  {
    cik: '0001620275',
    value: '0001620275,PARADICE INVESTMENT MANAGEMENT LLC',
    name: 'PARADICE INVESTMENT MANAGEMENT LLC',
    label: 'PARADICE INVESTMENT MANAGEMENT LLC',
  },
  {
    cik: '0001733356',
    value: '0001733356,PARADIEM, LLC',
    name: 'PARADIEM, LLC',
    label: 'PARADIEM, LLC',
  },
  {
    cik: '0001034549',
    value: '0001034549,PARADIGM ASSET MANAGEMENT CO LLC',
    name: 'PARADIGM ASSET MANAGEMENT CO LLC',
    label: 'PARADIGM ASSET MANAGEMENT CO LLC',
  },
  {
    cik: '0001855655',
    value: '0001855655,PARADIGM BIOCAPITAL ADVISORS LP',
    name: 'PARADIGM BIOCAPITAL ADVISORS LP',
    label: 'PARADIGM BIOCAPITAL ADVISORS LP',
  },
  {
    cik: '0001037792',
    value: '0001037792,PARADIGM CAPITAL MANAGEMENT INC/NY',
    name: 'PARADIGM CAPITAL MANAGEMENT INC/NY',
    label: 'PARADIGM CAPITAL MANAGEMENT INC/NY',
  },
  {
    cik: '0001910312',
    value: '0001910312,PARADIGM CAPITAL MANAGEMENT, LLC/NV',
    name: 'PARADIGM CAPITAL MANAGEMENT, LLC/NV',
    label: 'PARADIGM CAPITAL MANAGEMENT, LLC/NV',
  },
  {
    cik: '0001597691',
    value: '0001597691,PARADIGM FINANCIAL ADVISORS, LLC',
    name: 'PARADIGM FINANCIAL ADVISORS, LLC',
    label: 'PARADIGM FINANCIAL ADVISORS, LLC',
  },
  {
    cik: '0001766564',
    value: '0001766564,PARADIGM FINANCIAL PARTNERS, LLC',
    name: 'PARADIGM FINANCIAL PARTNERS, LLC',
    label: 'PARADIGM FINANCIAL PARTNERS, LLC',
  },
  {
    cik: '0001911136',
    value: '0001911136,PARADIGM OPERATIONS LP',
    name: 'PARADIGM OPERATIONS LP',
    label: 'PARADIGM OPERATIONS LP',
  },
  {
    cik: '0001859579',
    value: '0001859579,PARADIGM, STRATEGIES IN WEALTH MANAGEMENT, LLC',
    name: 'PARADIGM, STRATEGIES IN WEALTH MANAGEMENT, LLC',
    label: 'PARADIGM, STRATEGIES IN WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001556198',
    value: '0001556198,PARAFESTAS ANASTASIOS',
    name: 'PARAFESTAS ANASTASIOS',
    label: 'PARAFESTAS ANASTASIOS',
  },
  {
    cik: '0001799719',
    value: '0001799719,PARAGON ADVISORS, LLC',
    name: 'PARAGON ADVISORS, LLC',
    label: 'PARAGON ADVISORS, LLC',
  },
  {
    cik: '0001566137',
    value: '0001566137,PARAGON ASSOCIATES II, LTD',
    name: 'PARAGON ASSOCIATES II, LTD',
    label: 'PARAGON ASSOCIATES II, LTD',
  },
  {
    cik: '0001047859',
    value:
      '0001047859,PARAGON ASSOCIATES & PARAGON ASSOCIATES II JOINT VENTURE',
    name: 'PARAGON ASSOCIATES & PARAGON ASSOCIATES II JOINT VENTURE',
    label: 'PARAGON ASSOCIATES & PARAGON ASSOCIATES II JOINT VENTURE',
  },
  {
    cik: '0001388437',
    value: '0001388437,PARAGON CAPITAL MANAGEMENT LLC',
    name: 'PARAGON CAPITAL MANAGEMENT LLC',
    label: 'PARAGON CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001569765',
    value: '0001569765,PARAGON CAPITAL MANAGEMENT LTD',
    name: 'PARAGON CAPITAL MANAGEMENT LTD',
    label: 'PARAGON CAPITAL MANAGEMENT LTD',
  },
  {
    cik: '0001910845',
    value: '0001910845,PARAGON FINANCIAL PARTNERS, INC.',
    name: 'PARAGON FINANCIAL PARTNERS, INC.',
    label: 'PARAGON FINANCIAL PARTNERS, INC.',
  },
  {
    cik: '0001922684',
    value: '0001922684,PARAGON PRIVATE WEALTH MANAGEMENT, LLC',
    name: 'PARAGON PRIVATE WEALTH MANAGEMENT, LLC',
    label: 'PARAGON PRIVATE WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001680638',
    value: '0001680638,PARAGON WEALTH STRATEGIES, LLC',
    name: 'PARAGON WEALTH STRATEGIES, LLC',
    label: 'PARAGON WEALTH STRATEGIES, LLC',
  },
  {
    cik: '0001895911',
    value: '0001895911,PARALEL ADVISORS LLC',
    name: 'PARALEL ADVISORS LLC',
    label: 'PARALEL ADVISORS LLC',
  },
  {
    cik: '0001521001',
    value: '0001521001,PARALLAX VOLATILITY ADVISERS, L.P.',
    name: 'PARALLAX VOLATILITY ADVISERS, L.P.',
    label: 'PARALLAX VOLATILITY ADVISERS, L.P.',
  },
  {
    cik: '0001690010',
    value: '0001690010,PARALLEL ADVISORS, LLC',
    name: 'PARALLEL ADVISORS, LLC',
    label: 'PARALLEL ADVISORS, LLC',
  },
  {
    cik: '0001535110',
    value: '0001535110,PARAMETRICA MANAGEMENT LTD',
    name: 'PARAMETRICA MANAGEMENT LTD',
    label: 'PARAMETRICA MANAGEMENT LTD',
  },
  {
    cik: '0000932859',
    value: '0000932859,PARAMETRIC PORTFOLIO ASSOCIATES LLC',
    name: 'PARAMETRIC PORTFOLIO ASSOCIATES LLC',
    label: 'PARAMETRIC PORTFOLIO ASSOCIATES LLC',
  },
  {
    cik: '0001407409',
    value: '0001407409,PARAMETRIC RISK ADVISORS LLC',
    name: 'PARAMETRIC RISK ADVISORS LLC',
    label: 'PARAMETRIC RISK ADVISORS LLC',
  },
  {
    cik: '0001591925',
    value: '0001591925,PARASOL INVESTMENT MANAGEMENT, LLC',
    name: 'PARASOL INVESTMENT MANAGEMENT, LLC',
    label: 'PARASOL INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001051359',
    value: '0001051359,PAR CAPITAL MANAGEMENT INC',
    name: 'PAR CAPITAL MANAGEMENT INC',
    label: 'PAR CAPITAL MANAGEMENT INC',
  },
  {
    cik: '0001801263',
    value: '0001801263,PARCION PRIVATE WEALTH LLC',
    name: 'PARCION PRIVATE WEALTH LLC',
    label: 'PARCION PRIVATE WEALTH LLC',
  },
  {
    cik: '0001515072',
    value: '0001515072,PARENTEBEARD WEALTH MANAGEMENT, LLC',
    name: 'PARENTEBEARD WEALTH MANAGEMENT, LLC',
    label: 'PARENTEBEARD WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001752148',
    value: '0001752148,PARIAN GLOBAL MANAGEMENT LP',
    name: 'PARIAN GLOBAL MANAGEMENT LP',
    label: 'PARIAN GLOBAL MANAGEMENT LP',
  },
  {
    cik: '0001879203',
    value: '0001879203,PARIAX, LLC',
    name: 'PARIAX, LLC',
    label: 'PARIAX, LLC',
  },
  {
    cik: '0001716659',
    value: '0001716659,PARISI GRAY WEALTH MANAGEMENT',
    name: 'PARISI GRAY WEALTH MANAGEMENT',
    label: 'PARISI GRAY WEALTH MANAGEMENT',
  },
  {
    cik: '0001640801',
    value: '0001640801,PARK AVENUE INSTITUTIONAL ADVISERS LLC',
    name: 'PARK AVENUE INSTITUTIONAL ADVISERS LLC',
    label: 'PARK AVENUE INSTITUTIONAL ADVISERS LLC',
  },
  {
    cik: '0001071640',
    value: '0001071640,PARK AVENUE SECURITIES LLC',
    name: 'PARK AVENUE SECURITIES LLC',
    label: 'PARK AVENUE SECURITIES LLC',
  },
  {
    cik: '0001833156',
    value: '0001833156,PARK CAPITAL GROUP, INC.',
    name: 'PARK CAPITAL GROUP, INC.',
    label: 'PARK CAPITAL GROUP, INC.',
  },
  {
    cik: '0001546592',
    value: '0001546592,PARK CIRCLE CO',
    name: 'PARK CIRCLE CO',
    label: 'PARK CIRCLE CO',
  },
  {
    cik: '0001672070',
    value: '0001672070,PARKER INVESTMENT MANAGEMENT, LLC',
    name: 'PARKER INVESTMENT MANAGEMENT, LLC',
    label: 'PARKER INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001777015',
    value: '0001777015,PARKMAN HEALTHCARE PARTNERS LLC',
    name: 'PARKMAN HEALTHCARE PARTNERS LLC',
    label: 'PARKMAN HEALTHCARE PARTNERS LLC',
  },
  {
    cik: '0000805676',
    value: '0000805676,PARK NATIONAL CORP /OH/',
    name: 'PARK NATIONAL CORP /OH/',
    label: 'PARK NATIONAL CORP /OH/',
  },
  {
    cik: '0001596510',
    value: '0001596510,PARK PRESIDIO CAPITAL LLC',
    name: 'PARK PRESIDIO CAPITAL LLC',
    label: 'PARK PRESIDIO CAPITAL LLC',
  },
  {
    cik: '0001585550',
    value: '0001585550,PARKSIDE ADVISORS LLC',
    name: 'PARKSIDE ADVISORS LLC',
    label: 'PARKSIDE ADVISORS LLC',
  },
  {
    cik: '0001455267',
    value: '0001455267,PARKSIDE FINANCIAL BANK & TRUST',
    name: 'PARKSIDE FINANCIAL BANK & TRUST',
    label: 'PARKSIDE FINANCIAL BANK & TRUST',
  },
  {
    cik: '0001706248',
    value: '0001706248,PARKSIDE INVESTMENTS, LLC',
    name: 'PARKSIDE INVESTMENTS, LLC',
    label: 'PARKSIDE INVESTMENTS, LLC',
  },
  {
    cik: '0001386928',
    value: '0001386928,PARK WEST ASSET MANAGEMENT LLC',
    name: 'PARK WEST ASSET MANAGEMENT LLC',
    label: 'PARK WEST ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001597823',
    value: '0001597823,PARKWOOD LLC',
    name: 'PARKWOOD LLC',
    label: 'PARKWOOD LLC',
  },
  {
    cik: '0000948669',
    value: '0000948669,PARNASSUS INVESTMENTS /CA',
    name: 'PARNASSUS INVESTMENTS /CA',
    label: 'PARNASSUS INVESTMENTS /CA',
  },
  {
    cik: '0000948669',
    value: '0000948669,PARNASSUS INVESTMENTS, LLC',
    name: 'PARNASSUS INVESTMENTS, LLC',
    label: 'PARNASSUS INVESTMENTS, LLC',
  },
  {
    cik: '0001716164',
    value: '0001716164,PARPLUS PARTNERS',
    name: 'PARPLUS PARTNERS',
    label: 'PARPLUS PARTNERS',
  },
  {
    cik: '0001349138',
    value: '0001349138,PARSEC FINANCIAL MANAGEMENT, INC.',
    name: 'PARSEC FINANCIAL MANAGEMENT, INC.',
    label: 'PARSEC FINANCIAL MANAGEMENT, INC.',
  },
  {
    cik: '0001776090',
    value: '0001776090,PARSIFAL CAPITAL MANAGEMENT, LP',
    name: 'PARSIFAL CAPITAL MANAGEMENT, LP',
    label: 'PARSIFAL CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0001018674',
    value: '0001018674,PARSONS CAPITAL MANAGEMENT INC/RI',
    name: 'PARSONS CAPITAL MANAGEMENT INC/RI',
    label: 'PARSONS CAPITAL MANAGEMENT INC/RI',
  },
  {
    cik: '0001088859',
    value: '0001088859,PARTHENON LLC',
    name: 'PARTHENON LLC',
    label: 'PARTHENON LLC',
  },
  {
    cik: '0001442756',
    value: '0001442756,PARTNER FUND MANAGEMENT, L.P.',
    name: 'PARTNER FUND MANAGEMENT, L.P.',
    label: 'PARTNER FUND MANAGEMENT, L.P.',
  },
  {
    cik: '0001442276',
    value: '0001442276,PARTNER INVESTMENT MANAGEMENT, L.P.',
    name: 'PARTNER INVESTMENT MANAGEMENT, L.P.',
    label: 'PARTNER INVESTMENT MANAGEMENT, L.P.',
  },
  {
    cik: '0001301542',
    value: '0001301542,PARTNERRE ASSET MANAGEMENT CORP',
    name: 'PARTNERRE ASSET MANAGEMENT CORP',
    label: 'PARTNERRE ASSET MANAGEMENT CORP',
  },
  {
    cik: '0001599479',
    value: '0001599479,PARTNERRE PRINCIPAL FINANCE INC.',
    name: 'PARTNERRE PRINCIPAL FINANCE INC.',
    label: 'PARTNERRE PRINCIPAL FINANCE INC.',
  },
  {
    cik: '0001171473',
    value: '0001171473,PARTNERS CAPITAL INVESTMENT GROUP (HOLDINGS), LLC',
    name: 'PARTNERS CAPITAL INVESTMENT GROUP (HOLDINGS), LLC',
    label: 'PARTNERS CAPITAL INVESTMENT GROUP (HOLDINGS), LLC',
  },
  {
    cik: '0001694870',
    value: '0001694870,PARTNERS CAPITAL INVESTMENT GROUP, LLP',
    name: 'PARTNERS CAPITAL INVESTMENT GROUP, LLP',
    label: 'PARTNERS CAPITAL INVESTMENT GROUP, LLP',
  },
  {
    cik: '0001534259',
    value: '0001534259,PARTNERS GROUP HOLDING AG',
    name: 'PARTNERS GROUP HOLDING AG',
    label: 'PARTNERS GROUP HOLDING AG',
  },
  {
    cik: '0001678378',
    value: '0001678378,PARTNERS HEALTHCARE SYSTEM, INC',
    name: 'PARTNERS HEALTHCARE SYSTEM, INC',
    label: 'PARTNERS HEALTHCARE SYSTEM, INC',
  },
  {
    cik: '0001162777',
    value: '0001162777,PARTNERS VALUE INVESTMENTS LP',
    name: 'PARTNERS VALUE INVESTMENTS LP',
    label: 'PARTNERS VALUE INVESTMENTS LP',
  },
  {
    cik: '0001532516',
    value: '0001532516,PARTNERVEST ADVISORY SERVICES LLC',
    name: 'PARTNERVEST ADVISORY SERVICES LLC',
    label: 'PARTNERVEST ADVISORY SERVICES LLC',
  },
  {
    cik: '0001611520',
    value: '0001611520,PARUS FINANCE SA',
    name: 'PARUS FINANCE SA',
    label: 'PARUS FINANCE SA',
  },
  {
    cik: '0001611519',
    value: '0001611519,PARUS FINANCE (UK) LTD',
    name: 'PARUS FINANCE (UK) LTD',
    label: 'PARUS FINANCE (UK) LTD',
  },
  {
    cik: '0001760578',
    value: '0001760578,PASADENA PRIVATE WEALTH, LLC',
    name: 'PASADENA PRIVATE WEALTH, LLC',
    label: 'PASADENA PRIVATE WEALTH, LLC',
  },
  {
    cik: '0001905809',
    value: '0001905809,PASSAIC PARTNERS LLC',
    name: 'PASSAIC PARTNERS LLC',
    label: 'PASSAIC PARTNERS LLC',
  },
  {
    cik: '0001352428',
    value: '0001352428,PASSPORT CAPITAL, LLC',
    name: 'PASSPORT CAPITAL, LLC',
    label: 'PASSPORT CAPITAL, LLC',
  },
  {
    cik: '0001679542',
    value: '0001679542,PASTEL & ASSOCIES SA',
    name: 'PASTEL & ASSOCIES SA',
    label: 'PASTEL & ASSOCIES SA',
  },
  {
    cik: '0001767326',
    value: '0001767326,PATHLIGHT INVESTORS LLC',
    name: 'PATHLIGHT INVESTORS LLC',
    label: 'PATHLIGHT INVESTORS LLC',
  },
  {
    cik: '0001511137',
    value: '0001511137,PATHSTONE FAMILY OFFICE, LLC',
    name: 'PATHSTONE FAMILY OFFICE, LLC',
    label: 'PATHSTONE FAMILY OFFICE, LLC',
  },
  {
    cik: '0001037353',
    value: '0001037353,PATHWAY CAPITAL MANAGEMENT, LP',
    name: 'PATHWAY CAPITAL MANAGEMENT, LP',
    label: 'PATHWAY CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0001738828',
    value: '0001738828,PATHWAY FINANCIAL ADVISORS LLC',
    name: 'PATHWAY FINANCIAL ADVISORS LLC',
    label: 'PATHWAY FINANCIAL ADVISORS LLC',
  },
  {
    cik: '0001854794',
    value: '0001854794,PATIENT CAPITAL MANAGEMENT, LLC',
    name: 'PATIENT CAPITAL MANAGEMENT, LLC',
    label: 'PATIENT CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001953787',
    value: '0001953787,PATRICK MAURO INVESTMENT ADVISOR, INC.',
    name: 'PATRICK MAURO INVESTMENT ADVISOR, INC.',
    label: 'PATRICK MAURO INVESTMENT ADVISOR, INC.',
  },
  {
    cik: '0001694883',
    value: '0001694883,PATRIOT FINANCIAL GROUP INSURANCE AGENCY, LLC',
    name: 'PATRIOT FINANCIAL GROUP INSURANCE AGENCY, LLC',
    label: 'PATRIOT FINANCIAL GROUP INSURANCE AGENCY, LLC',
  },
  {
    cik: '0001470261',
    value: '0001470261,PATRIOT FINANCIAL PARTNERS GP, LP',
    name: 'PATRIOT FINANCIAL PARTNERS GP, LP',
    label: 'PATRIOT FINANCIAL PARTNERS GP, LP',
  },
  {
    cik: '0001773830',
    value: '0001773830,PATRIOT INVESTMENT MANAGEMENT INC.',
    name: 'PATRIOT INVESTMENT MANAGEMENT INC.',
    label: 'PATRIOT INVESTMENT MANAGEMENT INC.',
  },
  {
    cik: '0001347104',
    value: '0001347104,PATRIOT WEALTH MANAGEMENT, INC.',
    name: 'PATRIOT WEALTH MANAGEMENT, INC.',
    label: 'PATRIOT WEALTH MANAGEMENT, INC.',
  },
  {
    cik: '0001767910',
    value: '0001767910,PATRIZIA PTY LTD',
    name: 'PATRIZIA PTY LTD',
    label: 'PATRIZIA PTY LTD',
  },
  {
    cik: '0001841768',
    value: '0001841768,PATRON PARTNERS ADVISORS, LLC',
    name: 'PATRON PARTNERS ADVISORS, LLC',
    label: 'PATRON PARTNERS ADVISORS, LLC',
  },
  {
    cik: '0001841768',
    value: '0001841768,PATRON PARTNERS, LLC',
    name: 'PATRON PARTNERS, LLC',
    label: 'PATRON PARTNERS, LLC',
  },
  {
    cik: '0001633207',
    value: '0001633207,PATTEN GROUP, INC.',
    name: 'PATTEN GROUP, INC.',
    label: 'PATTEN GROUP, INC.',
  },
  {
    cik: '0001044905',
    value: '0001044905,PATTEN & PATTEN INC/TN',
    name: 'PATTEN & PATTEN INC/TN',
    label: 'PATTEN & PATTEN INC/TN',
  },
  {
    cik: '0001511037',
    value: '0001511037,PATTON ALBERTSON MILLER GROUP, LLC',
    name: 'PATTON ALBERTSON MILLER GROUP, LLC',
    label: 'PATTON ALBERTSON MILLER GROUP, LLC',
  },
  {
    cik: '0001511037',
    value: '0001511037,PATTON ALBERTSON & MILLER, LLC',
    name: 'PATTON ALBERTSON & MILLER, LLC',
    label: 'PATTON ALBERTSON & MILLER, LLC',
  },
  {
    cik: '0001689232',
    value: '0001689232,PATTON FUND MANAGEMENT, INC.',
    name: 'PATTON FUND MANAGEMENT, INC.',
    label: 'PATTON FUND MANAGEMENT, INC.',
  },
  {
    cik: '0001809125',
    value: '0001809125,PAUL JOHN BALSON',
    name: 'PAUL JOHN BALSON',
    label: 'PAUL JOHN BALSON',
  },
  {
    cik: '0001035674',
    value: '0001035674,PAULSON & CO INC',
    name: 'PAULSON & CO INC',
    label: 'PAULSON & CO INC',
  },
  {
    cik: '0001035674',
    value: '0001035674,PAULSON & CO. INC.',
    name: 'PAULSON & CO. INC.',
    label: 'PAULSON & CO. INC.',
  },
  {
    cik: '0001731878',
    value: '0001731878,PAULSON WEALTH MANAGEMENT INC.',
    name: 'PAULSON WEALTH MANAGEMENT INC.',
    label: 'PAULSON WEALTH MANAGEMENT INC.',
  },
  {
    cik: '0001906649',
    value: '0001906649,PAVADI CAPITAL LLC',
    name: 'PAVADI CAPITAL LLC',
    label: 'PAVADI CAPITAL LLC',
  },
  {
    cik: '0001792565',
    value: '0001792565,PAVION BLUE CAPITAL, LLC',
    name: 'PAVION BLUE CAPITAL, LLC',
    label: 'PAVION BLUE CAPITAL, LLC',
  },
  {
    cik: '0000949853',
    value: '0000949853,P.A.W. CAPITAL CORP',
    name: 'P.A.W. CAPITAL CORP',
    label: 'P.A.W. CAPITAL CORP',
  },
  {
    cik: '0001803673',
    value: '0001803673,PAX FINANCIAL GROUP, LLC',
    name: 'PAX FINANCIAL GROUP, LLC',
    label: 'PAX FINANCIAL GROUP, LLC',
  },
  {
    cik: '0001127990',
    value: '0001127990,PAX WORLD MANAGEMENT LLC',
    name: 'PAX WORLD MANAGEMENT LLC',
    label: 'PAX WORLD MANAGEMENT LLC',
  },
  {
    cik: '0000919219',
    value: '0000919219,PAYDEN & RYGEL',
    name: 'PAYDEN & RYGEL',
    label: 'PAYDEN & RYGEL',
  },
  {
    cik: '0001910391',
    value: '0001910391,PB INVESTMENT PARTNERS, L.P.',
    name: 'PB INVESTMENT PARTNERS, L.P.',
    label: 'PB INVESTMENT PARTNERS, L.P.',
  },
  {
    cik: '0001801682',
    value: '0001801682,PBMARES WEALTH MANAGEMENT LLC',
    name: 'PBMARES WEALTH MANAGEMENT LLC',
    label: 'PBMARES WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0001634553',
    value: '0001634553,PBWM HOLDINGS, LLC',
    name: 'PBWM HOLDINGS, LLC',
    label: 'PBWM HOLDINGS, LLC',
  },
  {
    cik: '0001905393',
    value: '0001905393,PCG WEALTH ADVISORS, LLC',
    name: 'PCG WEALTH ADVISORS, LLC',
    label: 'PCG WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001426318',
    value: '0001426318,PCJ INVESTMENT COUNSEL LTD.',
    name: 'PCJ INVESTMENT COUNSEL LTD.',
    label: 'PCJ INVESTMENT COUNSEL LTD.',
  },
  {
    cik: '0001591641',
    value: '0001591641,PCO INVESTMENT MANAGEMENT LP',
    name: 'PCO INVESTMENT MANAGEMENT LP',
    label: 'PCO INVESTMENT MANAGEMENT LP',
  },
  {
    cik: '0000838618',
    value: '0000838618,PDS PLANNING, INC',
    name: 'PDS PLANNING, INC',
    label: 'PDS PLANNING, INC',
  },
  {
    cik: '0001564702',
    value: '0001564702,PDT PARTNERS, LLC',
    name: 'PDT PARTNERS, LLC',
    label: 'PDT PARTNERS, LLC',
  },
  {
    cik: '0001598381',
    value: '0001598381,PEACHTREE INVESTMENT ADVISORS, INC.',
    name: 'PEACHTREE INVESTMENT ADVISORS, INC.',
    label: 'PEACHTREE INVESTMENT ADVISORS, INC.',
  },
  {
    cik: '0001743863',
    value: '0001743863,PEACHTREE INVESTMENT PARTNERS, LLC',
    name: 'PEACHTREE INVESTMENT PARTNERS, LLC',
    label: 'PEACHTREE INVESTMENT PARTNERS, LLC',
  },
  {
    cik: '0001756111',
    value: '0001756111,PEAK6 INVESTMENTS LLC',
    name: 'PEAK6 INVESTMENTS LLC',
    label: 'PEAK6 INVESTMENTS LLC',
  },
  {
    cik: '0001389957',
    value: '0001389957,PEAK6 INVESTMENTS, L.P.',
    name: 'PEAK6 INVESTMENTS, L.P.',
    label: 'PEAK6 INVESTMENTS, L.P.',
  },
  {
    cik: '0001632512',
    value: '0001632512,PEAK ASSET MANAGEMENT, LLC',
    name: 'PEAK ASSET MANAGEMENT, LLC',
    label: 'PEAK ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001710524',
    value: '0001710524,PEAK CAPITAL MANAGEMENT LLC',
    name: 'PEAK CAPITAL MANAGEMENT LLC',
    label: 'PEAK CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001843309',
    value: '0001843309,PEAK FINANCIAL ADVISORS LLC',
    name: 'PEAK FINANCIAL ADVISORS LLC',
    label: 'PEAK FINANCIAL ADVISORS LLC',
  },
  {
    cik: '0001669662',
    value: '0001669662,PEAK FINANCIAL MANAGEMENT, INC.',
    name: 'PEAK FINANCIAL MANAGEMENT, INC.',
    label: 'PEAK FINANCIAL MANAGEMENT, INC.',
  },
  {
    cik: '0001510972',
    value: '0001510972,PEAK TEN CAPITAL LLC',
    name: 'PEAK TEN CAPITAL LLC',
    label: 'PEAK TEN CAPITAL LLC',
  },
  {
    cik: '0001050743',
    value: '0001050743,PEAPACK GLADSTONE FINANCIAL CORP',
    name: 'PEAPACK GLADSTONE FINANCIAL CORP',
    label: 'PEAPACK GLADSTONE FINANCIAL CORP',
  },
  {
    cik: '0001714260',
    value: '0001714260,PEARL RIVER CAPITAL, LLC',
    name: 'PEARL RIVER CAPITAL, LLC',
    label: 'PEARL RIVER CAPITAL, LLC',
  },
  {
    cik: '0000919086',
    value: '0000919086,PEAR TREE ADVISORS INC',
    name: 'PEAR TREE ADVISORS INC',
    label: 'PEAR TREE ADVISORS INC',
  },
  {
    cik: '0001594540',
    value: '0001594540,PEAVINE CAPITAL, LLC',
    name: 'PEAVINE CAPITAL, LLC',
    label: 'PEAVINE CAPITAL, LLC',
  },
  {
    cik: '0001594540',
    value: '0001594540,PEAVINE CAPITAL MANAGEMENT, LLC',
    name: 'PEAVINE CAPITAL MANAGEMENT, LLC',
    label: 'PEAVINE CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001597200',
    value: '0001597200,PECAUT & CO.',
    name: 'PECAUT & CO.',
    label: 'PECAUT & CO.',
  },
  {
    cik: '0001050464',
    value: '0001050464,PECONIC PARTNERS LLC',
    name: 'PECONIC PARTNERS LLC',
    label: 'PECONIC PARTNERS LLC',
  },
  {
    cik: '0001597843',
    value: '0001597843,PEDDOCK CAPITAL ADVISORS, LLC',
    name: 'PEDDOCK CAPITAL ADVISORS, LLC',
    label: 'PEDDOCK CAPITAL ADVISORS, LLC',
  },
  {
    cik: '0001729049',
    value: '0001729049,PEGASUS ASSET MANAGEMENT, INC.',
    name: 'PEGASUS ASSET MANAGEMENT, INC.',
    label: 'PEGASUS ASSET MANAGEMENT, INC.',
  },
  {
    cik: '0001650654',
    value: '0001650654,PEGASUS PARTNERS LTD.',
    name: 'PEGASUS PARTNERS LTD.',
    label: 'PEGASUS PARTNERS LTD.',
  },
  {
    cik: '0001080623',
    value: '0001080623,PEG CAPITAL MANAGEMENT INC /NH/',
    name: 'PEG CAPITAL MANAGEMENT INC /NH/',
    label: 'PEG CAPITAL MANAGEMENT INC /NH/',
  },
  {
    cik: '0001910344',
    value: '0001910344,P/E GLOBAL LLC',
    name: 'P/E GLOBAL LLC',
    label: 'P/E GLOBAL LLC',
  },
  {
    cik: '0001020317',
    value: '0001020317,PEKIN HARDY STRAUSS, INC.',
    name: 'PEKIN HARDY STRAUSS, INC.',
    label: 'PEKIN HARDY STRAUSS, INC.',
  },
  {
    cik: '0001654111',
    value: '0001654111,PELHAM CAPITAL LTD.',
    name: 'PELHAM CAPITAL LTD.',
    label: 'PELHAM CAPITAL LTD.',
  },
  {
    cik: '0001696704',
    value: '0001696704,PELHAM GLOBAL FINANCIALS LTD',
    name: 'PELHAM GLOBAL FINANCIALS LTD',
    label: 'PELHAM GLOBAL FINANCIALS LTD',
  },
  {
    cik: '0001787862',
    value: '0001787862,PELION, INC.',
    name: 'PELION, INC.',
    label: 'PELION, INC.',
  },
  {
    cik: '0001710075',
    value: '0001710075,PELORUS JACK CAPITAL, L.P.',
    name: 'PELORUS JACK CAPITAL, L.P.',
    label: 'PELORUS JACK CAPITAL, L.P.',
  },
  {
    cik: '0001080132',
    value: '0001080132,PELOTON WEALTH STRATEGISTS',
    name: 'PELOTON WEALTH STRATEGISTS',
    label: 'PELOTON WEALTH STRATEGISTS',
  },
  {
    cik: '0001063497',
    value: '0001063497,PEMBROKE MANAGEMENT, LTD',
    name: 'PEMBROKE MANAGEMENT, LTD',
    label: 'PEMBROKE MANAGEMENT, LTD',
  },
  {
    cik: '0001381055',
    value: '0001381055,PENBROOK MANAGEMENT LLC',
    name: 'PENBROOK MANAGEMENT LLC',
    label: 'PENBROOK MANAGEMENT LLC',
  },
  {
    cik: '0001541741',
    value: '0001541741,PENDAL GROUP LTD',
    name: 'PENDAL GROUP LTD',
    label: 'PENDAL GROUP LTD',
  },
  {
    cik: '0001706164',
    value: '0001706164,PENDERFUND CAPITAL MANAGEMENT LTD.',
    name: 'PENDERFUND CAPITAL MANAGEMENT LTD.',
    label: 'PENDERFUND CAPITAL MANAGEMENT LTD.',
  },
  {
    cik: '0001091923',
    value: '0001091923,PENINSULA ASSET MANAGEMENT INC',
    name: 'PENINSULA ASSET MANAGEMENT INC',
    label: 'PENINSULA ASSET MANAGEMENT INC',
  },
  {
    cik: '0001600052',
    value: '0001600052,PENINSULA WEALTH, LLC',
    name: 'PENINSULA WEALTH, LLC',
    label: 'PENINSULA WEALTH, LLC',
  },
  {
    cik: '0001168664',
    value: '0001168664,PENNANT CAPITAL MANAGEMENT, LLC',
    name: 'PENNANT CAPITAL MANAGEMENT, LLC',
    label: 'PENNANT CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001759176',
    value: '0001759176,PENNANT INVESTORS, LP',
    name: 'PENNANT INVESTORS, LP',
    label: 'PENNANT INVESTORS, LP',
  },
  {
    cik: '0001158202',
    value: '0001158202,PENN CAPITAL MANAGEMENT CO INC',
    name: 'PENN CAPITAL MANAGEMENT CO INC',
    label: 'PENN CAPITAL MANAGEMENT CO INC',
  },
  {
    cik: '0001158202',
    value: '0001158202,PENN CAPITAL MANAGEMENT COMPANY, LLC',
    name: 'PENN CAPITAL MANAGEMENT COMPANY, LLC',
    label: 'PENN CAPITAL MANAGEMENT COMPANY, LLC',
  },
  {
    cik: '0001108893',
    value: '0001108893,PENN DAVIS MCFARLAND INC',
    name: 'PENN DAVIS MCFARLAND INC',
    label: 'PENN DAVIS MCFARLAND INC',
  },
  {
    cik: '0000855702',
    value: '0000855702,PENN MUTUAL ASSET MANAGEMENT, LLC',
    name: 'PENN MUTUAL ASSET MANAGEMENT, LLC',
    label: 'PENN MUTUAL ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0000077132',
    value: '0000077132,PENN MUTUAL LIFE INSURANCE CO',
    name: 'PENN MUTUAL LIFE INSURANCE CO',
    label: 'PENN MUTUAL LIFE INSURANCE CO',
  },
  {
    cik: '0001004140',
    value: '0001004140,PENNSYLVANIA CAPITAL MANAGEMENT INC /ADV',
    name: 'PENNSYLVANIA CAPITAL MANAGEMENT INC /ADV',
    label: 'PENNSYLVANIA CAPITAL MANAGEMENT INC /ADV',
  },
  {
    cik: '0001560808',
    value: '0001560808,PENNSYLVANIA TRUST CO',
    name: 'PENNSYLVANIA TRUST CO',
    label: 'PENNSYLVANIA TRUST CO',
  },
  {
    cik: '0001394866',
    value: '0001394866,PENOBSCOT INVESTMENT MANAGEMENT COMPANY, INC.',
    name: 'PENOBSCOT INVESTMENT MANAGEMENT COMPANY, INC.',
    label: 'PENOBSCOT INVESTMENT MANAGEMENT COMPANY, INC.',
  },
  {
    cik: '0001875768',
    value: '0001875768,PENOBSCOT WEALTH MANAGEMENT',
    name: 'PENOBSCOT WEALTH MANAGEMENT',
    label: 'PENOBSCOT WEALTH MANAGEMENT',
  },
  {
    cik: '0001593324',
    value: '0001593324,PENSERRA CAPITAL MANAGEMENT LLC',
    name: 'PENSERRA CAPITAL MANAGEMENT LLC',
    label: 'PENSERRA CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001218761',
    value: '0001218761,PENSIOENFONDS RAIL & OV',
    name: 'PENSIOENFONDS RAIL & OV',
    label: 'PENSIOENFONDS RAIL & OV',
  },
  {
    cik: '0001297731',
    value: '0001297731,PENSIONFUND DSM NETHERLANDS',
    name: 'PENSIONFUND DSM NETHERLANDS',
    label: 'PENSIONFUND DSM NETHERLANDS',
  },
  {
    cik: '0001637246',
    value: '0001637246,PENSIONFUND SABIC',
    name: 'PENSIONFUND SABIC',
    label: 'PENSIONFUND SABIC',
  },
  {
    cik: '0001652594',
    value: '0001652594,PENSIONMARK FINANCIAL GROUP, LLC',
    name: 'PENSIONMARK FINANCIAL GROUP, LLC',
    label: 'PENSIONMARK FINANCIAL GROUP, LLC',
  },
  {
    cik: '0001536340',
    value: '0001536340,PENSION PARTNERS, LLC',
    name: 'PENSION PARTNERS, LLC',
    label: 'PENSION PARTNERS, LLC',
  },
  {
    cik: '0001425851',
    value: '0001425851,PENTWATER CAPITAL MANAGEMENT LP',
    name: 'PENTWATER CAPITAL MANAGEMENT LP',
    label: 'PENTWATER CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0000934866',
    value: '0000934866,PEOPLES BANK /OH',
    name: 'PEOPLES BANK /OH',
    label: 'PEOPLES BANK /OH',
  },
  {
    cik: '0001056943',
    value: '0001056943,PEOPLES FINANCIAL SERVICES CORP.',
    name: 'PEOPLES FINANCIAL SERVICES CORP.',
    label: 'PEOPLES FINANCIAL SERVICES CORP.',
  },
  {
    cik: '0001378946',
    value: '0001378946,PEOPLE S UNITED FINANCIAL, INC.',
    name: "PEOPLE'S UNITED FINANCIAL, INC.",
    label: "PEOPLE'S UNITED FINANCIAL, INC.",
  },
  {
    cik: '0001224962',
    value: '0001224962,PERCEPTIVE ADVISORS LLC',
    name: 'PERCEPTIVE ADVISORS LLC',
    label: 'PERCEPTIVE ADVISORS LLC',
  },
  {
    cik: '0001535227',
    value: '0001535227,PEREGRINE ASSET ADVISERS, INC.',
    name: 'PEREGRINE ASSET ADVISERS, INC.',
    label: 'PEREGRINE ASSET ADVISERS, INC.',
  },
  {
    cik: '0000764529',
    value: '0000764529,PEREGRINE CAPITAL MANAGEMENT LLC',
    name: 'PEREGRINE CAPITAL MANAGEMENT LLC',
    label: 'PEREGRINE CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001426083',
    value: '0001426083,PERELLA WEINBERG PARTNERS CAPITAL MANAGEMENT LP',
    name: 'PERELLA WEINBERG PARTNERS CAPITAL MANAGEMENT LP',
    label: 'PERELLA WEINBERG PARTNERS CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001697360',
    value: '0001697360,PERENNIAL ADVISORS, LLC',
    name: 'PERENNIAL ADVISORS, LLC',
    label: 'PERENNIAL ADVISORS, LLC',
  },
  {
    cik: '0001801523',
    value: '0001801523,PERENNIAL INVESTMENT ADVISORS, LLC',
    name: 'PERENNIAL INVESTMENT ADVISORS, LLC',
    label: 'PERENNIAL INVESTMENT ADVISORS, LLC',
  },
  {
    cik: '0001390271',
    value: '0001390271,PERENNIAL INVESTMENT PARTNERS LTD',
    name: 'PERENNIAL INVESTMENT PARTNERS LTD',
    label: 'PERENNIAL INVESTMENT PARTNERS LTD',
  },
  {
    cik: '0001650303',
    value: '0001650303,PERFORMA LTD (US), LLC',
    name: 'PERFORMA LTD (US), LLC',
    label: 'PERFORMA LTD (US), LLC',
  },
  {
    cik: '0001908765',
    value: '0001908765,PERFROMANCE WEALTH PARTNERS, LLC',
    name: 'PERFROMANCE WEALTH PARTNERS, LLC',
    label: 'PERFROMANCE WEALTH PARTNERS, LLC',
  },
  {
    cik: '0001347193',
    value: '0001347193,PERGAMON OFFSHORE ADVISORS, L.P.',
    name: 'PERGAMON OFFSHORE ADVISORS, L.P.',
    label: 'PERGAMON OFFSHORE ADVISORS, L.P.',
  },
  {
    cik: '0001575239',
    value: '0001575239,PERIGON WEALTH MANAGEMENT, LLC',
    name: 'PERIGON WEALTH MANAGEMENT, LLC',
    label: 'PERIGON WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001388136',
    value: '0001388136,PERIMETER CAPITAL PARTNERS, LLC',
    name: 'PERIMETER CAPITAL PARTNERS, LLC',
    label: 'PERIMETER CAPITAL PARTNERS, LLC',
  },
  {
    cik: '0001695320',
    value: '0001695320,PERISCOPE CAPITAL INC.',
    name: 'PERISCOPE CAPITAL INC.',
    label: 'PERISCOPE CAPITAL INC.',
  },
  {
    cik: '0000884300',
    value: '0000884300,PERKINS CAPITAL MANAGEMENT INC',
    name: 'PERKINS CAPITAL MANAGEMENT INC',
    label: 'PERKINS CAPITAL MANAGEMENT INC',
  },
  {
    cik: '0001513038',
    value: '0001513038,PERKINS COIE TRUST CO',
    name: 'PERKINS COIE TRUST CO',
    label: 'PERKINS COIE TRUST CO',
  },
  {
    cik: '0001263567',
    value: '0001263567,PERKINS INVESTMENT MANAGEMENT, LLC',
    name: 'PERKINS INVESTMENT MANAGEMENT, LLC',
    label: 'PERKINS INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001438129',
    value: '0001438129,PERMAL ASSET MANAGEMENT LLC',
    name: 'PERMAL ASSET MANAGEMENT LLC',
    label: 'PERMAL ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001599637',
    value: '0001599637,PERMANENS CAPITAL L.P.',
    name: 'PERMANENS CAPITAL L.P.',
    label: 'PERMANENS CAPITAL L.P.',
  },
  {
    cik: '0001472850',
    value: '0001472850,PERMIAN INVESTMENT PARTNERS, LP',
    name: 'PERMIAN INVESTMENT PARTNERS, LP',
    label: 'PERMIAN INVESTMENT PARTNERS, LP',
  },
  {
    cik: '0001337151',
    value: '0001337151,PERMIRA EUROPE III G.P. LTD',
    name: 'PERMIRA EUROPE III G.P. LTD',
    label: 'PERMIRA EUROPE III G.P. LTD',
  },
  {
    cik: '0001925332',
    value: '0001925332,PERMIT CAPITAL ADVISORS, LLC',
    name: 'PERMIT CAPITAL ADVISORS, LLC',
    label: 'PERMIT CAPITAL ADVISORS, LLC',
  },
  {
    cik: '0001313978',
    value: '0001313978,PERMIT CAPITAL, LLC',
    name: 'PERMIT CAPITAL, LLC',
    label: 'PERMIT CAPITAL, LLC',
  },
  {
    cik: '0001570625',
    value: '0001570625,PERPETUAL INVESTMENT MANAGEMENT LTD',
    name: 'PERPETUAL INVESTMENT MANAGEMENT LTD',
    label: 'PERPETUAL INVESTMENT MANAGEMENT LTD',
  },
  {
    cik: '0001647273',
    value: '0001647273,PERPETUAL LTD',
    name: 'PERPETUAL LTD',
    label: 'PERPETUAL LTD',
  },
  {
    cik: '0001279342',
    value: '0001279342,PERRITT CAPITAL MANAGEMENT INC',
    name: 'PERRITT CAPITAL MANAGEMENT INC',
    label: 'PERRITT CAPITAL MANAGEMENT INC',
  },
  {
    cik: '0000919085',
    value: '0000919085,PERRY CAPITAL',
    name: 'PERRY CAPITAL',
    label: 'PERRY CAPITAL',
  },
  {
    cik: '0001769704',
    value: '0001769704,PERRY CREEK CAPITAL LP',
    name: 'PERRY CREEK CAPITAL LP',
    label: 'PERRY CREEK CAPITAL LP',
  },
  {
    cik: '0001802695',
    value: '0001802695,PERSEVERANCE ASSET MANAGEMENT INTERNATIONAL',
    name: 'PERSEVERANCE ASSET MANAGEMENT INTERNATIONAL',
    label: 'PERSEVERANCE ASSET MANAGEMENT INTERNATIONAL',
  },
  {
    cik: '0001336528',
    value: '0001336528,PERSHING SQUARE CAPITAL MANAGEMENT, L.P.',
    name: 'PERSHING SQUARE CAPITAL MANAGEMENT, L.P.',
    label: 'PERSHING SQUARE CAPITAL MANAGEMENT, L.P.',
  },
  {
    cik: '0001571669',
    value: '0001571669,PERSONAL CAPITAL ADVISORS CORP',
    name: 'PERSONAL CAPITAL ADVISORS CORP',
    label: 'PERSONAL CAPITAL ADVISORS CORP',
  },
  {
    cik: '0001677501',
    value: '0001677501,PERSONAL CFO SOLUTIONS, LLC',
    name: 'PERSONAL CFO SOLUTIONS, LLC',
    label: 'PERSONAL CFO SOLUTIONS, LLC',
  },
  {
    cik: '0000077657',
    value: '0000077657,PERSONAL FINANCIAL CONSULTANTS INC /ADV',
    name: 'PERSONAL FINANCIAL CONSULTANTS INC /ADV',
    label: 'PERSONAL FINANCIAL CONSULTANTS INC /ADV',
  },
  {
    cik: '0001717027',
    value:
      '0001717027,PERSONAL RESOURCES INVESTMENT & STRATEGIC MANAGEMENT, INC.',
    name: 'PERSONAL RESOURCES INVESTMENT & STRATEGIC MANAGEMENT, INC.',
    label: 'PERSONAL RESOURCES INVESTMENT & STRATEGIC MANAGEMENT, INC.',
  },
  {
    cik: '0001766435',
    value: '0001766435,PERSONAL WEALTH PARTNERS',
    name: 'PERSONAL WEALTH PARTNERS',
    label: 'PERSONAL WEALTH PARTNERS',
  },
  {
    cik: '0001894571',
    value: '0001894571,PERTENTO PARTNERS LLP',
    name: 'PERTENTO PARTNERS LLP',
    label: 'PERTENTO PARTNERS LLP',
  },
  {
    cik: '0000819535',
    value: '0000819535,PETERSEN FLYNN & DINSMORE INC',
    name: 'PETERSEN FLYNN & DINSMORE INC',
    label: 'PETERSEN FLYNN & DINSMORE INC',
  },
  {
    cik: '0001903905',
    value: '0001903905,PETERSON FINANCIAL GROUP, INC.',
    name: 'PETERSON FINANCIAL GROUP, INC.',
    label: 'PETERSON FINANCIAL GROUP, INC.',
  },
  {
    cik: '0001713558',
    value: '0001713558,PETERSON WEALTH ADVISORS, LLC',
    name: 'PETERSON WEALTH ADVISORS, LLC',
    label: 'PETERSON WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001785144',
    value: '0001785144,PETERSON WEALTH MANAGEMENT',
    name: 'PETERSON WEALTH MANAGEMENT',
    label: 'PETERSON WEALTH MANAGEMENT',
  },
  {
    cik: '0001906799',
    value: '0001906799,PETERSON WEALTH SERVICES',
    name: 'PETERSON WEALTH SERVICES',
    label: 'PETERSON WEALTH SERVICES',
  },
  {
    cik: '0001600202',
    value: '0001600202,PETIOLE USA LTD',
    name: 'PETIOLE USA LTD',
    label: 'PETIOLE USA LTD',
  },
  {
    cik: '0001803255',
    value: '0001803255,PETIX & BOTTE CO',
    name: 'PETIX & BOTTE CO',
    label: 'PETIX & BOTTE CO',
  },
  {
    cik: '0000216851',
    value: '0000216851,PETROLEUM & RESOURCES CORP',
    name: 'PETROLEUM & RESOURCES CORP',
    label: 'PETROLEUM & RESOURCES CORP',
  },
  {
    cik: '0001473429',
    value: '0001473429,PETRUS TRUST COMPANY, LTA',
    name: 'PETRUS TRUST COMPANY, LTA',
    label: 'PETRUS TRUST COMPANY, LTA',
  },
  {
    cik: '0001596901',
    value: '0001596901,PETTEE INVESTORS, INC.',
    name: 'PETTEE INVESTORS, INC.',
    label: 'PETTEE INVESTORS, INC.',
  },
  {
    cik: '0001694592',
    value: '0001694592,PETTINGA FINANCIAL ADVISORS, LLC',
    name: 'PETTINGA FINANCIAL ADVISORS, LLC',
    label: 'PETTINGA FINANCIAL ADVISORS, LLC',
  },
  {
    cik: '0001128213',
    value: '0001128213,PETTYJOHN CO',
    name: 'PETTYJOHN CO',
    label: 'PETTYJOHN CO',
  },
  {
    cik: '0001128213',
    value: '0001128213,PETTYJOHN, WOOD & WHITE, INC',
    name: 'PETTYJOHN, WOOD & WHITE, INC',
    label: 'PETTYJOHN, WOOD & WHITE, INC',
  },
  {
    cik: '0001694435',
    value: '0001694435,PFG ADVISORS',
    name: 'PFG ADVISORS',
    label: 'PFG ADVISORS',
  },
  {
    cik: '0001544806',
    value: '0001544806,PFG FINANCIAL ADVISORS',
    name: 'PFG FINANCIAL ADVISORS',
    label: 'PFG FINANCIAL ADVISORS',
  },
  {
    cik: '0001799957',
    value: '0001799957,PFG PRIVATE WEALTH MANAGEMENT, LLC',
    name: 'PFG PRIVATE WEALTH MANAGEMENT, LLC',
    label: 'PFG PRIVATE WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001740063',
    value: '0001740063,PFLUG KOORY, LLC',
    name: 'PFLUG KOORY, LLC',
    label: 'PFLUG KOORY, LLC',
  },
  {
    cik: '0001715304',
    value: '0001715304,PFM ASSET MANAGEMENT LLC',
    name: 'PFM ASSET MANAGEMENT LLC',
    label: 'PFM ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001442756',
    value: '0001442756,PFM HEALTH SCIENCES, LP',
    name: 'PFM HEALTH SCIENCES, LP',
    label: 'PFM HEALTH SCIENCES, LP',
  },
  {
    cik: '0000354497',
    value: '0000354497,PFS INVESTMENTS INC.',
    name: 'PFS INVESTMENTS INC.',
    label: 'PFS INVESTMENTS INC.',
  },
  {
    cik: '0001472190',
    value: '0001472190,PGGM INVESTMENTS',
    name: 'PGGM INVESTMENTS',
    label: 'PGGM INVESTMENTS',
  },
  {
    cik: '0001598762',
    value: '0001598762,PHARMSTANDARD INTERNATIONAL S.A.',
    name: 'PHARMSTANDARD INTERNATIONAL S.A.',
    label: 'PHARMSTANDARD INTERNATIONAL S.A.',
  },
  {
    cik: '0001478032',
    value: '0001478032,PHARO MANAGEMENT, INC.',
    name: 'PHARO MANAGEMENT, INC.',
    label: 'PHARO MANAGEMENT, INC.',
  },
  {
    cik: '0001841126',
    value: '0001841126,PHASE 2 PARTNERS, LLC',
    name: 'PHASE 2 PARTNERS, LLC',
    label: 'PHASE 2 PARTNERS, LLC',
  },
  {
    cik: '0001619495',
    value: '0001619495,PHASECAPITAL LP',
    name: 'PHASECAPITAL LP',
    label: 'PHASECAPITAL LP',
  },
  {
    cik: '0001537276',
    value: '0001537276,PHH INVESTMENTS, LTD.',
    name: 'PHH INVESTMENTS, LTD.',
    label: 'PHH INVESTMENTS, LTD.',
  },
  {
    cik: '0001351407',
    value: '0001351407,PHILADELPHIA FINANCIAL MANAGEMENT OF SAN FRANCISCO, LLC',
    name: 'PHILADELPHIA FINANCIAL MANAGEMENT OF SAN FRANCISCO, LLC',
    label: 'PHILADELPHIA FINANCIAL MANAGEMENT OF SAN FRANCISCO, LLC',
  },
  {
    cik: '0001218555',
    value: '0001218555,PHILADELPHIA INTERNATIONAL ADVISORS LP',
    name: 'PHILADELPHIA INTERNATIONAL ADVISORS LP',
    label: 'PHILADELPHIA INTERNATIONAL ADVISORS LP',
  },
  {
    cik: '0001110806',
    value: '0001110806,PHILADELPHIA TRUST CO',
    name: 'PHILADELPHIA TRUST CO',
    label: 'PHILADELPHIA TRUST CO',
  },
  {
    cik: '0001350660',
    value: '0001350660,PHILLIPS FINANCIAL MANAGEMENT, LLC',
    name: 'PHILLIPS FINANCIAL MANAGEMENT, LLC',
    label: 'PHILLIPS FINANCIAL MANAGEMENT, LLC',
  },
  {
    cik: '0001942361',
    value: '0001942361,PHILO SMITH CAPITAL CORP',
    name: 'PHILO SMITH CAPITAL CORP',
    label: 'PHILO SMITH CAPITAL CORP',
  },
  {
    cik: '0001942361',
    value: '0001942361,PHILOSMITH CAPITAL CORP',
    name: 'PHILOSMITH CAPITAL CORP',
    label: 'PHILOSMITH CAPITAL CORP',
  },
  {
    cik: '0001701057',
    value: '0001701057,PHILOSMITH CAPITAL CORP',
    name: 'PHILOSMITH CAPITAL CORP',
    label: 'PHILOSMITH CAPITAL CORP',
  },
  {
    cik: '0001869199',
    value: '0001869199,PHILOSOPHY CAPITAL MANAGEMENT LLC',
    name: 'PHILOSOPHY CAPITAL MANAGEMENT LLC',
    label: 'PHILOSOPHY CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001356783',
    value: '0001356783,PHOCAS FINANCIAL CORP.',
    name: 'PHOCAS FINANCIAL CORP.',
    label: 'PHOCAS FINANCIAL CORP.',
  },
  {
    cik: '0001766752',
    value: '0001766752,PHOENICIAN CAPITAL LLC',
    name: 'PHOENICIAN CAPITAL LLC',
    label: 'PHOENICIAN CAPITAL LLC',
  },
  {
    cik: '0001679543',
    value: '0001679543,PHOENIX HOLDINGS LTD.',
    name: 'PHOENIX HOLDINGS LTD.',
    label: 'PHOENIX HOLDINGS LTD.',
  },
  {
    cik: '0001415030',
    value: '0001415030,PHOENIX INVESTMENT ADVISER LLC',
    name: 'PHOENIX INVESTMENT ADVISER LLC',
    label: 'PHOENIX INVESTMENT ADVISER LLC',
  },
  {
    cik: '0001842362',
    value: '0001842362,PHOENIX WEALTH ADVISORS',
    name: 'PHOENIX WEALTH ADVISORS',
    label: 'PHOENIX WEALTH ADVISORS',
  },
  {
    cik: '0001907803',
    value: '0001907803,PHRACTION MANAGEMENT LLC',
    name: 'PHRACTION MANAGEMENT LLC',
    label: 'PHRACTION MANAGEMENT LLC',
  },
  {
    cik: '0001665518',
    value: '0001665518,PHYSICIANS FINANCIAL SERVICES, INC.',
    name: 'PHYSICIANS FINANCIAL SERVICES, INC.',
    label: 'PHYSICIANS FINANCIAL SERVICES, INC.',
  },
  {
    cik: '0001291274',
    value: '0001291274,PICTET ASSET MANAGEMENT LTD',
    name: 'PICTET ASSET MANAGEMENT LTD',
    label: 'PICTET ASSET MANAGEMENT LTD',
  },
  {
    cik: '0001361570',
    value: '0001361570,PICTET ASSET MANAGEMENT SA',
    name: 'PICTET ASSET MANAGEMENT SA',
    label: 'PICTET ASSET MANAGEMENT SA',
  },
  {
    cik: '0001535631',
    value: '0001535631,PICTET BANK & TRUST LTD',
    name: 'PICTET BANK & TRUST LTD',
    label: 'PICTET BANK & TRUST LTD',
  },
  {
    cik: '0001123274',
    value: '0001123274,PICTET & CIE (EUROPE) SA',
    name: 'PICTET & CIE (EUROPE) SA',
    label: 'PICTET & CIE (EUROPE) SA',
  },
  {
    cik: '0001599576',
    value: '0001599576,PICTET NORTH AMERICA ADVISORS SA',
    name: 'PICTET NORTH AMERICA ADVISORS SA',
    label: 'PICTET NORTH AMERICA ADVISORS SA',
  },
  {
    cik: '0001539041',
    value: '0001539041,PICTON MAHONEY ASSET MANAGEMENT',
    name: 'PICTON MAHONEY ASSET MANAGEMENT',
    label: 'PICTON MAHONEY ASSET MANAGEMENT',
  },
  {
    cik: '0001270341',
    value: '0001270341,PIEDMONT INVESTMENT ADVISORS INC',
    name: 'PIEDMONT INVESTMENT ADVISORS INC',
    label: 'PIEDMONT INVESTMENT ADVISORS INC',
  },
  {
    cik: '0001697366',
    value: '0001697366,PIER 88 INVESTMENT PARTNERS LLC',
    name: 'PIER 88 INVESTMENT PARTNERS LLC',
    label: 'PIER 88 INVESTMENT PARTNERS LLC',
  },
  {
    cik: '0001310051',
    value: '0001310051,PIER CAPITAL, LLC',
    name: 'PIER CAPITAL, LLC',
    label: 'PIER CAPITAL, LLC',
  },
  {
    cik: '0001561509',
    value: '0001561509,PIERMONT CAPITAL MANAGEMENT INC.',
    name: 'PIERMONT CAPITAL MANAGEMENT INC.',
    label: 'PIERMONT CAPITAL MANAGEMENT INC.',
  },
  {
    cik: '0001554815',
    value: '0001554815,PIERSHALE FINANCIAL GROUP, INC.',
    name: 'PIERSHALE FINANCIAL GROUP, INC.',
    label: 'PIERSHALE FINANCIAL GROUP, INC.',
  },
  {
    cik: '0001089911',
    value: '0001089911,PILLAR PACIFIC CAPITAL MANAGEMENT LLC',
    name: 'PILLAR PACIFIC CAPITAL MANAGEMENT LLC',
    label: 'PILLAR PACIFIC CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001632226',
    value: '0001632226,PILLAR WEALTH ADVISORS, LLC',
    name: 'PILLAR WEALTH ADVISORS, LLC',
    label: 'PILLAR WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001531721',
    value: '0001531721,PINEBRIDGE INVESTMENTS, L.P.',
    name: 'PINEBRIDGE INVESTMENTS, L.P.',
    label: 'PINEBRIDGE INVESTMENTS, L.P.',
  },
  {
    cik: '0001531721',
    value: '0001531721,PINEBRIDGE INVESTMENTS LTD',
    name: 'PINEBRIDGE INVESTMENTS LTD',
    label: 'PINEBRIDGE INVESTMENTS LTD',
  },
  {
    cik: '0001630691',
    value: '0001630691,PINE BROOK ROAD ADVISORS, L.P.',
    name: 'PINE BROOK ROAD ADVISORS, L.P.',
    label: 'PINE BROOK ROAD ADVISORS, L.P.',
  },
  {
    cik: '0001899753',
    value: '0001899753,PINE HAVEN INVESTMENT COUNSEL, INC',
    name: 'PINE HAVEN INVESTMENT COUNSEL, INC',
    label: 'PINE HAVEN INVESTMENT COUNSEL, INC',
  },
  {
    cik: '0001390205',
    value: '0001390205,PINENO LEVIN & FORD ASSET MANAGEMENT, INC.',
    name: 'PINENO LEVIN & FORD ASSET MANAGEMENT, INC.',
    label: 'PINENO LEVIN & FORD ASSET MANAGEMENT, INC.',
  },
  {
    cik: '0001845915',
    value: '0001845915,PINE RIDGE ADVISERS LLC',
    name: 'PINE RIDGE ADVISERS LLC',
    label: 'PINE RIDGE ADVISERS LLC',
  },
  {
    cik: '0001288136',
    value: '0001288136,PINE RIVER CAPITAL MANAGEMENT L.P.',
    name: 'PINE RIVER CAPITAL MANAGEMENT L.P.',
    label: 'PINE RIVER CAPITAL MANAGEMENT L.P.',
  },
  {
    cik: '0001621602',
    value: '0001621602,PINEVIEW ASSET MANAGEMENT LP',
    name: 'PINEVIEW ASSET MANAGEMENT LP',
    label: 'PINEVIEW ASSET MANAGEMENT LP',
  },
  {
    cik: '0001536446',
    value: '0001536446,PINKERTON RETIREMENT SPECIALISTS, LLC',
    name: 'PINKERTON RETIREMENT SPECIALISTS, LLC',
    label: 'PINKERTON RETIREMENT SPECIALISTS, LLC',
  },
  {
    cik: '0001504404',
    value: '0001504404,PINNACLE ADVISORY GROUP, INC.',
    name: 'PINNACLE ADVISORY GROUP, INC.',
    label: 'PINNACLE ADVISORY GROUP, INC.',
  },
  {
    cik: '0000743127',
    value: '0000743127,PINNACLE ASSOCIATES LTD',
    name: 'PINNACLE ASSOCIATES LTD',
    label: 'PINNACLE ASSOCIATES LTD',
  },
  {
    cik: '0001703383',
    value: '0001703383,PINNACLE BANCORP, INC.',
    name: 'PINNACLE BANCORP, INC.',
    label: 'PINNACLE BANCORP, INC.',
  },
  {
    cik: '0001679064',
    value: '0001679064,PINNACLE FAMILY ADVISORS, LLC',
    name: 'PINNACLE FAMILY ADVISORS, LLC',
    label: 'PINNACLE FAMILY ADVISORS, LLC',
  },
  {
    cik: '0001905962',
    value: '0001905962,PINNACLE FINANCIAL GROUP, LLC / IL',
    name: 'PINNACLE FINANCIAL GROUP, LLC / IL',
    label: 'PINNACLE FINANCIAL GROUP, LLC / IL',
  },
  {
    cik: '0001115055',
    value: '0001115055,PINNACLE FINANCIAL PARTNERS INC',
    name: 'PINNACLE FINANCIAL PARTNERS INC',
    label: 'PINNACLE FINANCIAL PARTNERS INC',
  },
  {
    cik: '0001411784',
    value: '0001411784,PINNACLE HOLDINGS, LLC',
    name: 'PINNACLE HOLDINGS, LLC',
    label: 'PINNACLE HOLDINGS, LLC',
  },
  {
    cik: '0001908610',
    value: '0001908610,PINNACLE PRIVATE WEALTH, LLC',
    name: 'PINNACLE PRIVATE WEALTH, LLC',
    label: 'PINNACLE PRIVATE WEALTH, LLC',
  },
  {
    cik: '0001071341',
    value: '0001071341,PINNACLE SUMMER INVESTMENTS, INC.',
    name: 'PINNACLE SUMMER INVESTMENTS, INC.',
    label: 'PINNACLE SUMMER INVESTMENTS, INC.',
  },
  {
    cik: '0001698283',
    value: '0001698283,PINNACLE WEALTH ADVISORS',
    name: 'PINNACLE WEALTH ADVISORS',
    label: 'PINNACLE WEALTH ADVISORS',
  },
  {
    cik: '0001631864',
    value: '0001631864,PINNACLE WEALTH MANAGEMENT ADVISORY GROUP, LLC',
    name: 'PINNACLE WEALTH MANAGEMENT ADVISORY GROUP, LLC',
    label: 'PINNACLE WEALTH MANAGEMENT ADVISORY GROUP, LLC',
  },
  {
    cik: '0001868491',
    value: '0001868491,PINNACLE WEALTH MANAGEMENT GROUP, INC.',
    name: 'PINNACLE WEALTH MANAGEMENT GROUP, INC.',
    label: 'PINNACLE WEALTH MANAGEMENT GROUP, INC.',
  },
  {
    cik: '0001911464',
    value: '0001911464,PINNACLE WEALTH MANAGEMENT, LLC',
    name: 'PINNACLE WEALTH MANAGEMENT, LLC',
    label: 'PINNACLE WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001726752',
    value: '0001726752,PINNACLE WEALTH PLANNING SERVICES, INC.',
    name: 'PINNACLE WEALTH PLANNING SERVICES, INC.',
    label: 'PINNACLE WEALTH PLANNING SERVICES, INC.',
  },
  {
    cik: '0001856103',
    value: '0001856103,PINNBROOK CAPITAL MANAGEMENT LP',
    name: 'PINNBROOK CAPITAL MANAGEMENT LP',
    label: 'PINNBROOK CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001094584',
    value: '0001094584,PIN OAK INVESTMENT ADVISORS INC',
    name: 'PIN OAK INVESTMENT ADVISORS INC',
    label: 'PIN OAK INVESTMENT ADVISORS INC',
  },
  {
    cik: '0001803237',
    value: '0001803237,PINPOINT ASSET MANAGEMENT LTD',
    name: 'PINPOINT ASSET MANAGEMENT LTD',
    label: 'PINPOINT ASSET MANAGEMENT LTD',
  },
  {
    cik: '0001687470',
    value: '0001687470,PINZ CAPITAL MANAGEMENT, LP',
    name: 'PINZ CAPITAL MANAGEMENT, LP',
    label: 'PINZ CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0001102598',
    value: '0001102598,PIONEER INVESTMENT MANAGEMENT INC',
    name: 'PIONEER INVESTMENT MANAGEMENT INC',
    label: 'PIONEER INVESTMENT MANAGEMENT INC',
  },
  {
    cik: '0001006435',
    value: '0001006435,PIONEER TRUST BANK N A/OR',
    name: 'PIONEER TRUST BANK N A/OR',
    label: 'PIONEER TRUST BANK N A/OR',
  },
  {
    cik: '0000724683',
    value: '0000724683,PIPER JAFFRAY & CO.',
    name: 'PIPER JAFFRAY & CO.',
    label: 'PIPER JAFFRAY & CO.',
  },
  {
    cik: '0000724683',
    value: '0000724683,PIPER SANDLER & CO.',
    name: 'PIPER SANDLER & CO.',
    label: 'PIPER SANDLER & CO.',
  },
  {
    cik: '0001855835',
    value: '0001855835,PISCATAQUA SAVINGS BANK',
    name: 'PISCATAQUA SAVINGS BANK',
    label: 'PISCATAQUA SAVINGS BANK',
  },
  {
    cik: '0000315014',
    value: '0000315014,PITCAIRN CO',
    name: 'PITCAIRN CO',
    label: 'PITCAIRN CO',
  },
  {
    cik: '0001034886',
    value: '0001034886,PITTENGER & ANDERSON INC',
    name: 'PITTENGER & ANDERSON INC',
    label: 'PITTENGER & ANDERSON INC',
  },
  {
    cik: '0001909664',
    value: '0001909664,PITTI GROUP WEALTH MANAGEMENT, LLC',
    name: 'PITTI GROUP WEALTH MANAGEMENT, LLC',
    label: 'PITTI GROUP WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001844645',
    value: '0001844645,PIVOTAL BIOVENTURE PARTNERS INVESTMENT ADVISOR LLC',
    name: 'PIVOTAL BIOVENTURE PARTNERS INVESTMENT ADVISOR LLC',
    label: 'PIVOTAL BIOVENTURE PARTNERS INVESTMENT ADVISOR LLC',
  },
  {
    cik: '0001380023',
    value: '0001380023,PIVOT POINT CAPITAL PARTNERS, LLC',
    name: 'PIVOT POINT CAPITAL PARTNERS, LLC',
    label: 'PIVOT POINT CAPITAL PARTNERS, LLC',
  },
  {
    cik: '0001315726',
    value: '0001315726,PLACEMARK INVESTMENTS, INC.',
    name: 'PLACEMARK INVESTMENTS, INC.',
    label: 'PLACEMARK INVESTMENTS, INC.',
  },
  {
    cik: '0001761269',
    value: '0001761269,PLAISANCE CAPITAL LLC',
    name: 'PLAISANCE CAPITAL LLC',
    label: 'PLAISANCE CAPITAL LLC',
  },
  {
    cik: '0001599579',
    value: '0001599579,PLANCORP, LLC',
    name: 'PLANCORP, LLC',
    label: 'PLANCORP, LLC',
  },
  {
    cik: '0001940033',
    value: '0001940033,PLAN GROUP FINANCIAL, LLC',
    name: 'PLAN GROUP FINANCIAL, LLC',
    label: 'PLAN GROUP FINANCIAL, LLC',
  },
  {
    cik: '0001831332',
    value: '0001831332,PLANNED SOLUTIONS, INC.',
    name: 'PLANNED SOLUTIONS, INC.',
    label: 'PLANNED SOLUTIONS, INC.',
  },
  {
    cik: '0000712011',
    value: '0000712011,PLANNING ALTERNATIVES LTD /ADV',
    name: 'PLANNING ALTERNATIVES LTD /ADV',
    label: 'PLANNING ALTERNATIVES LTD /ADV',
  },
  {
    cik: '0001910966',
    value: '0001910966,PLANNING CENTER, INC.',
    name: 'PLANNING CENTER, INC.',
    label: 'PLANNING CENTER, INC.',
  },
  {
    cik: '0000821103',
    value: '0000821103,PLANNING DIRECTIONS INC',
    name: 'PLANNING DIRECTIONS INC',
    label: 'PLANNING DIRECTIONS INC',
  },
  {
    cik: '0001766969',
    value: '0001766969,PLANNING SOLUTIONS GROUP LLC',
    name: 'PLANNING SOLUTIONS GROUP LLC',
    label: 'PLANNING SOLUTIONS GROUP LLC',
  },
  {
    cik: '0001424717',
    value: '0001424717,PLANTE MORAN FINANCIAL ADVISORS, LLC',
    name: 'PLANTE MORAN FINANCIAL ADVISORS, LLC',
    label: 'PLANTE MORAN FINANCIAL ADVISORS, LLC',
  },
  {
    cik: '0001830817',
    value: '0001830817,PLATFORM TECHNOLOGY PARTNERS',
    name: 'PLATFORM TECHNOLOGY PARTNERS',
    label: 'PLATFORM TECHNOLOGY PARTNERS',
  },
  {
    cik: '0001803274',
    value: '0001803274,PLATINUM EQUITY ADVISORS, LLC/DE',
    name: 'PLATINUM EQUITY ADVISORS, LLC/DE',
    label: 'PLATINUM EQUITY ADVISORS, LLC/DE',
  },
  {
    cik: '0001228754',
    value: '0001228754,PLATINUM EQUITY, LLC',
    name: 'PLATINUM EQUITY, LLC',
    label: 'PLATINUM EQUITY, LLC',
  },
  {
    cik: '0001256071',
    value: '0001256071,PLATINUM INVESTMENT MANAGEMENT LTD',
    name: 'PLATINUM INVESTMENT MANAGEMENT LTD',
    label: 'PLATINUM INVESTMENT MANAGEMENT LTD',
  },
  {
    cik: '0001368616',
    value: '0001368616,PLATINUM MANAGEMENT (NY) LLC',
    name: 'PLATINUM MANAGEMENT (NY) LLC',
    label: 'PLATINUM MANAGEMENT (NY) LLC',
  },
  {
    cik: '0001387980',
    value: '0001387980,PLATTE RIVER CAPITAL LLC',
    name: 'PLATTE RIVER CAPITAL LLC',
    label: 'PLATTE RIVER CAPITAL LLC',
  },
  {
    cik: '0001802013',
    value: '0001802013,PLATTE RIVER WEALTH ADVISORS, LLC',
    name: 'PLATTE RIVER WEALTH ADVISORS, LLC',
    label: 'PLATTE RIVER WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001859195',
    value: '0001859195,PLATT INVESTMENT COUNSEL, LLC',
    name: 'PLATT INVESTMENT COUNSEL, LLC',
    label: 'PLATT INVESTMENT COUNSEL, LLC',
  },
  {
    cik: '0001569147',
    value: '0001569147,PL CAPITAL ADVISORS, LLC',
    name: 'PL CAPITAL ADVISORS, LLC',
    label: 'PL CAPITAL ADVISORS, LLC',
  },
  {
    cik: '0001580144',
    value: '0001580144,PLEASANT LAKE PARTNERS LLC',
    name: 'PLEASANT LAKE PARTNERS LLC',
    label: 'PLEASANT LAKE PARTNERS LLC',
  },
  {
    cik: '0001640095',
    value: '0001640095,PLEIAD INVESTMENT ADVISORS LTD',
    name: 'PLEIAD INVESTMENT ADVISORS LTD',
    label: 'PLEIAD INVESTMENT ADVISORS LTD',
  },
  {
    cik: '0001623678',
    value: '0001623678,PLIMOTH TRUST CO LLC',
    name: 'PLIMOTH TRUST CO LLC',
    label: 'PLIMOTH TRUST CO LLC',
  },
  {
    cik: '0001637714',
    value: '0001637714,PLURIBUS LABS, LLC',
    name: 'PLURIBUS LABS, LLC',
    label: 'PLURIBUS LABS, LLC',
  },
  {
    cik: '0001643351',
    value: '0001643351,PLUSTICK MANAGEMENT LLC',
    name: 'PLUSTICK MANAGEMENT LLC',
    label: 'PLUSTICK MANAGEMENT LLC',
  },
  {
    cik: '0001596882',
    value: '0001596882,PLYMOUTH LANE CAPITAL MANAGEMENT, LLC',
    name: 'PLYMOUTH LANE CAPITAL MANAGEMENT, LLC',
    label: 'PLYMOUTH LANE CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001630363',
    value: '0001630363,PM CAPITAL LTD',
    name: 'PM CAPITAL LTD',
    label: 'PM CAPITAL LTD',
  },
  {
    cik: '0000713676',
    value: '0000713676,PNC FINANCIAL SERVICES GROUP, INC.',
    name: 'PNC FINANCIAL SERVICES GROUP, INC.',
    label: 'PNC FINANCIAL SERVICES GROUP, INC.',
  },
  {
    cik: '0001783285',
    value: '0001783285,PNC MANAGED ACCOUNT SOLUTIONS, INC.',
    name: 'PNC MANAGED ACCOUNT SOLUTIONS, INC.',
    label: 'PNC MANAGED ACCOUNT SOLUTIONS, INC.',
  },
  {
    cik: '0001730815',
    value: '0001730815,POEHLING CAPITAL MANAGEMENT, INC.',
    name: 'POEHLING CAPITAL MANAGEMENT, INC.',
    label: 'POEHLING CAPITAL MANAGEMENT, INC.',
  },
  {
    cik: '0001730815',
    value: '0001730815,POEHLING CAPITAL MANAGEMENT, LLC',
    name: 'POEHLING CAPITAL MANAGEMENT, LLC',
    label: 'POEHLING CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001599822',
    value: '0001599822,POINT72 ASIA (HONG KONG) LTD',
    name: 'POINT72 ASIA (HONG KONG) LTD',
    label: 'POINT72 ASIA (HONG KONG) LTD',
  },
  {
    cik: '0001666791',
    value: '0001666791,POINT72 ASIA (SINGAPORE) PTE. LTD.',
    name: 'POINT72 ASIA (SINGAPORE) PTE. LTD.',
    label: 'POINT72 ASIA (SINGAPORE) PTE. LTD.',
  },
  {
    cik: '0001603466',
    value: '0001603466,POINT72 ASSET MANAGEMENT, L.P.',
    name: 'POINT72 ASSET MANAGEMENT, L.P.',
    label: 'POINT72 ASSET MANAGEMENT, L.P.',
  },
  {
    cik: '0001698051',
    value: '0001698051,POINT72 EUROPE (LONDON) LLP',
    name: 'POINT72 EUROPE (LONDON) LLP',
    label: 'POINT72 EUROPE (LONDON) LLP',
  },
  {
    cik: '0001599822',
    value: '0001599822,POINT72 HONG KONG LTD',
    name: 'POINT72 HONG KONG LTD',
    label: 'POINT72 HONG KONG LTD',
  },
  {
    cik: '0001631943',
    value: '0001631943,POINT BREAK CAPITAL MANAGEMENT, LLC',
    name: 'POINT BREAK CAPITAL MANAGEMENT, LLC',
    label: 'POINT BREAK CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001640335',
    value: '0001640335,POINTE CAPITAL MANAGEMENT LLC',
    name: 'POINTE CAPITAL MANAGEMENT LLC',
    label: 'POINTE CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001509842',
    value: '0001509842,POINTSTATE CAPITAL LP',
    name: 'POINTSTATE CAPITAL LP',
    label: 'POINTSTATE CAPITAL LP',
  },
  {
    cik: '0001408103',
    value: '0001408103,POINT VIEW FINANCIAL SERVICES, INC.',
    name: 'POINT VIEW FINANCIAL SERVICES, INC.',
    label: 'POINT VIEW FINANCIAL SERVICES, INC.',
  },
  {
    cik: '0001326389',
    value: '0001326389,POLAR ASSET MANAGEMENT PARTNERS INC.',
    name: 'POLAR ASSET MANAGEMENT PARTNERS INC.',
    label: 'POLAR ASSET MANAGEMENT PARTNERS INC.',
  },
  {
    cik: '0001837309',
    value: '0001837309,POLAR CAPITAL HOLDINGS PLC',
    name: 'POLAR CAPITAL HOLDINGS PLC',
    label: 'POLAR CAPITAL HOLDINGS PLC',
  },
  {
    cik: '0001439303',
    value: '0001439303,POLAR CAPITAL LLP',
    name: 'POLAR CAPITAL LLP',
    label: 'POLAR CAPITAL LLP',
  },
  {
    cik: '0001316926',
    value: '0001316926,POLARIS CAPITAL MANAGEMENT, LLC',
    name: 'POLARIS CAPITAL MANAGEMENT, LLC',
    label: 'POLARIS CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001582968',
    value: '0001582968,POLARIS GREYSTONE FINANCIAL GROUP, LLC',
    name: 'POLARIS GREYSTONE FINANCIAL GROUP, LLC',
    label: 'POLARIS GREYSTONE FINANCIAL GROUP, LLC',
  },
  {
    cik: '0001295438',
    value: '0001295438,POLARIS VENTURE MANAGEMENT CO IV LLC',
    name: 'POLARIS VENTURE MANAGEMENT CO IV LLC',
    label: 'POLARIS VENTURE MANAGEMENT CO IV LLC',
  },
  {
    cik: '0001665594',
    value: '0001665594,POLARIS VENTURE MANAGEMENT CO. VI, L.L.C.',
    name: 'POLARIS VENTURE MANAGEMENT CO. VI, L.L.C.',
    label: 'POLARIS VENTURE MANAGEMENT CO. VI, L.L.C.',
  },
  {
    cik: '0001439589',
    value: '0001439589,POLARIS VENTURE MANAGEMENT CO. V, L.L.C.',
    name: 'POLARIS VENTURE MANAGEMENT CO. V, L.L.C.',
    label: 'POLARIS VENTURE MANAGEMENT CO. V, L.L.C.',
  },
  {
    cik: '0001582968',
    value: '0001582968,POLARIS WEALTH ADVISERS, LLC',
    name: 'POLARIS WEALTH ADVISERS, LLC',
    label: 'POLARIS WEALTH ADVISERS, LLC',
  },
  {
    cik: '0001582968',
    value: '0001582968,POLARIS WEALTH ADVISORY GROUP, LLC',
    name: 'POLARIS WEALTH ADVISORY GROUP, LLC',
    label: 'POLARIS WEALTH ADVISORY GROUP, LLC',
  },
  {
    cik: '0001901667',
    value: '0001901667,POLARITY INVESTMENT PARTNERS LP',
    name: 'POLARITY INVESTMENT PARTNERS LP',
    label: 'POLARITY INVESTMENT PARTNERS LP',
  },
  {
    cik: '0001326389',
    value: '0001326389,POLAR SECURITIES INC.',
    name: 'POLAR SECURITIES INC.',
    label: 'POLAR SECURITIES INC.',
  },
  {
    cik: '0001034524',
    value: '0001034524,POLEN CAPITAL MANAGEMENT LLC',
    name: 'POLEN CAPITAL MANAGEMENT LLC',
    label: 'POLEN CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001695214',
    value: '0001695214,POLIANTA LTD',
    name: 'POLIANTA LTD',
    label: 'POLIANTA LTD',
  },
  {
    cik: '0001885245',
    value: '0001885245,POLITAN CAPITAL MANAGEMENT LP',
    name: 'POLITAN CAPITAL MANAGEMENT LP',
    label: 'POLITAN CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001629869',
    value: '0001629869,POLUNIN CAPITAL PARTNERS LTD',
    name: 'POLUNIN CAPITAL PARTNERS LTD',
    label: 'POLUNIN CAPITAL PARTNERS LTD',
  },
  {
    cik: '0001515070',
    value: '0001515070,POLYGON MANAGEMENT LTD.',
    name: 'POLYGON MANAGEMENT LTD.',
    label: 'POLYGON MANAGEMENT LTD.',
  },
  {
    cik: '0001565772',
    value: '0001565772,POMELO CAPITAL LLC',
    name: 'POMELO CAPITAL LLC',
    label: 'POMELO CAPITAL LLC',
  },
  {
    cik: '0001746488',
    value: '0001746488,PONOI II MANAGEMENT, LLC',
    name: 'PONOI II MANAGEMENT, LLC',
    label: 'PONOI II MANAGEMENT, LLC',
  },
  {
    cik: '0001720702',
    value: '0001720702,PONOI MANAGEMENT, LLC',
    name: 'PONOI MANAGEMENT, LLC',
    label: 'PONOI MANAGEMENT, LLC',
  },
  {
    cik: '0001166134',
    value: '0001166134,POPE ASSET MANAGEMENT, LLC',
    name: 'POPE ASSET MANAGEMENT, LLC',
    label: 'POPE ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001454949',
    value: '0001454949,POPLAR FOREST CAPITAL LLC',
    name: 'POPLAR FOREST CAPITAL LLC',
    label: 'POPLAR FOREST CAPITAL LLC',
  },
  {
    cik: '0001729212',
    value: '0001729212,PORT CAPITAL LLC',
    name: 'PORT CAPITAL LLC',
    label: 'PORT CAPITAL LLC',
  },
  {
    cik: '0001353318',
    value: '0001353318,PORTFOLIO 21 INVESTMENTS',
    name: 'PORTFOLIO 21 INVESTMENTS',
    label: 'PORTFOLIO 21 INVESTMENTS',
  },
  {
    cik: '0001542606',
    value: '0001542606,PORTFOLIO SOLUTIONS, LLC',
    name: 'PORTFOLIO SOLUTIONS, LLC',
    label: 'PORTFOLIO SOLUTIONS, LLC',
  },
  {
    cik: '0001423282',
    value: '0001423282,PORTFOLIO STRATEGIES INC',
    name: 'PORTFOLIO STRATEGIES INC',
    label: 'PORTFOLIO STRATEGIES INC',
  },
  {
    cik: '0001753271',
    value: '0001753271,PORTFOLIO STRATEGIES, INC.',
    name: 'PORTFOLIO STRATEGIES, INC.',
    label: 'PORTFOLIO STRATEGIES, INC.',
  },
  {
    cik: '0001504941',
    value: '0001504941,PORTLAND GLOBAL ADVISORS LLC',
    name: 'PORTLAND GLOBAL ADVISORS LLC',
    label: 'PORTLAND GLOBAL ADVISORS LLC',
  },
  {
    cik: '0001703658',
    value: '0001703658,PORTLAND HILL ASSET MANAGEMENT LTD',
    name: 'PORTLAND HILL ASSET MANAGEMENT LTD',
    label: 'PORTLAND HILL ASSET MANAGEMENT LTD',
  },
  {
    cik: '0001647692',
    value: '0001647692,PORTLAND HILL CAPITAL LLP',
    name: 'PORTLAND HILL CAPITAL LLP',
    label: 'PORTLAND HILL CAPITAL LLP',
  },
  {
    cik: '0001081668',
    value: '0001081668,PORTLAND INVESTMENT COUNSEL INC.',
    name: 'PORTLAND INVESTMENT COUNSEL INC.',
    label: 'PORTLAND INVESTMENT COUNSEL INC.',
  },
  {
    cik: '0001705910',
    value: '0001705910,PORTLAND LTD',
    name: 'PORTLAND LTD',
    label: 'PORTLAND LTD',
  },
  {
    cik: '0001634896',
    value: '0001634896,PORTMAN LTD',
    name: 'PORTMAN LTD',
    label: 'PORTMAN LTD',
  },
  {
    cik: '0000883724',
    value: '0000883724,PORTOLA GROUP INC /CA',
    name: 'PORTOLA GROUP INC /CA',
    label: 'PORTOLA GROUP INC /CA',
  },
  {
    cik: '0001350290',
    value: '0001350290,PORTOLAN CAPITAL MANAGEMENT, LLC',
    name: 'PORTOLAN CAPITAL MANAGEMENT, LLC',
    label: 'PORTOLAN CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001719883',
    value: '0001719883,PORTSEA ASSET MANAGEMENT LLP',
    name: 'PORTSEA ASSET MANAGEMENT LLP',
    label: 'PORTSEA ASSET MANAGEMENT LLP',
  },
  {
    cik: '0001170789',
    value: '0001170789,POST ADVISORY GROUP LLC',
    name: 'POST ADVISORY GROUP LLC',
    label: 'POST ADVISORY GROUP LLC',
  },
  {
    cik: '0001911838',
    value: '0001911838,POST OAK ENERGY HOLDINGS, LLC',
    name: 'POST OAK ENERGY HOLDINGS, LLC',
    label: 'POST OAK ENERGY HOLDINGS, LLC',
  },
  {
    cik: '0001418746',
    value: '0001418746,POTOMAC CAPITAL MANAGEMENT, INC.',
    name: 'POTOMAC CAPITAL MANAGEMENT, INC.',
    label: 'POTOMAC CAPITAL MANAGEMENT, INC.',
  },
  {
    cik: '0001536630',
    value: '0001536630,POTRERO CAPITAL RESEARCH LLC',
    name: 'POTRERO CAPITAL RESEARCH LLC',
    label: 'POTRERO CAPITAL RESEARCH LLC',
  },
  {
    cik: '0001802195',
    value: '0001802195,POWELL INVESTMENT ADVISORS, LLC',
    name: 'POWELL INVESTMENT ADVISORS, LLC',
    label: 'POWELL INVESTMENT ADVISORS, LLC',
  },
  {
    cik: '0000801166',
    value: '0000801166,POWER CORP OF CANADA',
    name: 'POWER CORP OF CANADA',
    label: 'POWER CORP OF CANADA',
  },
  {
    cik: '0001927474',
    value: '0001927474,POWERS ADVISORY GROUP, LLC',
    name: 'POWERS ADVISORY GROUP, LLC',
    label: 'POWERS ADVISORY GROUP, LLC',
  },
  {
    cik: '0000898417',
    value: '0000898417,PPM AMERICA INC/IL',
    name: 'PPM AMERICA INC/IL',
    label: 'PPM AMERICA INC/IL',
  },
  {
    cik: '0001936380',
    value: '0001936380,PPS&V ASSET MANAGEMENT CONSULTANTS, INC.',
    name: 'PPS&V ASSET MANAGEMENT CONSULTANTS, INC.',
    label: 'PPS&V ASSET MANAGEMENT CONSULTANTS, INC.',
  },
  {
    cik: '0001320769',
    value: '0001320769,PRAESIDIUM INVESTMENT MANAGEMENT COMPANY, LLC',
    name: 'PRAESIDIUM INVESTMENT MANAGEMENT COMPANY, LLC',
    label: 'PRAESIDIUM INVESTMENT MANAGEMENT COMPANY, LLC',
  },
  {
    cik: '0001818940',
    value: '0001818940,PRAETORIAN WEALTH MANAGEMENT, INC.',
    name: 'PRAETORIAN WEALTH MANAGEMENT, INC.',
    label: 'PRAETORIAN WEALTH MANAGEMENT, INC.',
  },
  {
    cik: '0001555512',
    value: '0001555512,PRAGMA GESTAO DE PATRIMONIO LTD',
    name: 'PRAGMA GESTAO DE PATRIMONIO LTD',
    label: 'PRAGMA GESTAO DE PATRIMONIO LTD',
  },
  {
    cik: '0001726673',
    value: '0001726673,PRAIRIEVIEW PARTNERS, LLC',
    name: 'PRAIRIEVIEW PARTNERS, LLC',
    label: 'PRAIRIEVIEW PARTNERS, LLC',
  },
  {
    cik: '0001839695',
    value: '0001839695,PRAIRIEWOOD CAPITAL, LLC',
    name: 'PRAIRIEWOOD CAPITAL, LLC',
    label: 'PRAIRIEWOOD CAPITAL, LLC',
  },
  {
    cik: '0001710951',
    value: '0001710951,PRANA CAPITAL MANAGEMENT, LP',
    name: 'PRANA CAPITAL MANAGEMENT, LP',
    label: 'PRANA CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0001728318',
    value: '0001728318,PRATT COLLARD ADVISORY PARTNERS LLC',
    name: 'PRATT COLLARD ADVISORY PARTNERS LLC',
    label: 'PRATT COLLARD ADVISORY PARTNERS LLC',
  },
  {
    cik: '0001135631',
    value: '0001135631,PRECEPT MANAGEMENT LLC',
    name: 'PRECEPT MANAGEMENT LLC',
    label: 'PRECEPT MANAGEMENT LLC',
  },
  {
    cik: '0001654407',
    value: '0001654407,PRECISION PATH CAPITAL, LP',
    name: 'PRECISION PATH CAPITAL, LP',
    label: 'PRECISION PATH CAPITAL, LP',
  },
  {
    cik: '0001844197',
    value: '0001844197,PRECISION WEALTH STRATEGIES, LLC',
    name: 'PRECISION WEALTH STRATEGIES, LLC',
    label: 'PRECISION WEALTH STRATEGIES, LLC',
  },
  {
    cik: '0001671811',
    value: '0001671811,PRECOCITY CAPITAL LP',
    name: 'PRECOCITY CAPITAL LP',
    label: 'PRECOCITY CAPITAL LP',
  },
  {
    cik: '0001387508',
    value: '0001387508,PRELUDE CAPITAL MANAGEMENT, LLC',
    name: 'PRELUDE CAPITAL MANAGEMENT, LLC',
    label: 'PRELUDE CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001696677',
    value: '0001696677,PREMIA GLOBAL ADVISORS, LLC',
    name: 'PREMIA GLOBAL ADVISORS, LLC',
    label: 'PREMIA GLOBAL ADVISORS, LLC',
  },
  {
    cik: '0001374998',
    value: '0001374998,PREMIER ASSET MANAGEMENT LLC',
    name: 'PREMIER ASSET MANAGEMENT LLC',
    label: 'PREMIER ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001673907',
    value: '0001673907,PREMIER FUND MANAGERS LTD',
    name: 'PREMIER FUND MANAGERS LTD',
    label: 'PREMIER FUND MANAGERS LTD',
  },
  {
    cik: '0001599974',
    value: '0001599974,PREMISE CAPITAL, LLC',
    name: 'PREMISE CAPITAL, LLC',
    label: 'PREMISE CAPITAL, LLC',
  },
  {
    cik: '0001326150',
    value: '0001326150,PRENTICE CAPITAL MANAGEMENT, LP',
    name: 'PRENTICE CAPITAL MANAGEMENT, LP',
    label: 'PRENTICE CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0001864229',
    value: '0001864229,PRENTICE WEALTH MANAGEMENT LLC',
    name: 'PRENTICE WEALTH MANAGEMENT LLC',
    label: 'PRENTICE WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0001134687',
    value: '0001134687,PRENTISS SMITH & CO INC',
    name: 'PRENTISS SMITH & CO INC',
    label: 'PRENTISS SMITH & CO INC',
  },
  {
    cik: '0001538653',
    value: '0001538653,PRESCOTT GENERAL PARTNERS LLC',
    name: 'PRESCOTT GENERAL PARTNERS LLC',
    label: 'PRESCOTT GENERAL PARTNERS LLC',
  },
  {
    cik: '0001166152',
    value: '0001166152,PRESCOTT GROUP CAPITAL MANAGEMENT, L.L.C.',
    name: 'PRESCOTT GROUP CAPITAL MANAGEMENT, L.L.C.',
    label: 'PRESCOTT GROUP CAPITAL MANAGEMENT, L.L.C.',
  },
  {
    cik: '0001236421',
    value: '0001236421,PRESCOTT INVESTORS PROFIT SHARING TRUST',
    name: 'PRESCOTT INVESTORS PROFIT SHARING TRUST',
    label: 'PRESCOTT INVESTORS PROFIT SHARING TRUST',
  },
  {
    cik: '0001931750',
    value: '0001931750,PRESIDIO CAPITAL MANAGEMENT, LLC',
    name: 'PRESIDIO CAPITAL MANAGEMENT, LLC',
    label: 'PRESIDIO CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001399360',
    value: '0001399360,PRESIMA INC.',
    name: 'PRESIMA INC.',
    label: 'PRESIMA INC.',
  },
  {
    cik: '0001399360',
    value: '0001399360,PRESIMA SECURITIES ULC',
    name: 'PRESIMA SECURITIES ULC',
    label: 'PRESIMA SECURITIES ULC',
  },
  {
    cik: '0001772715',
    value: '0001772715,PRESTIGE WEALTH MANAGEMENT GROUP LLC',
    name: 'PRESTIGE WEALTH MANAGEMENT GROUP LLC',
    label: 'PRESTIGE WEALTH MANAGEMENT GROUP LLC',
  },
  {
    cik: '0001353323',
    value: '0001353323,P.R. HERZIG & CO. INC.',
    name: 'P.R. HERZIG & CO. INC.',
    label: 'P.R. HERZIG & CO. INC.',
  },
  {
    cik: '0001633624',
    value: '0001633624,PRICE CAPITAL MANAGEMENT, INC.',
    name: 'PRICE CAPITAL MANAGEMENT, INC.',
    label: 'PRICE CAPITAL MANAGEMENT, INC.',
  },
  {
    cik: '0001930102',
    value: '0001930102,PRICE JENNIFER C.',
    name: 'PRICE JENNIFER C.',
    label: 'PRICE JENNIFER C.',
  },
  {
    cik: '0000918537',
    value: '0000918537,PRICE MICHAEL F',
    name: 'PRICE MICHAEL F',
    label: 'PRICE MICHAEL F',
  },
  {
    cik: '0000080255',
    value: '0000080255,PRICE T ROWE ASSOCIATES INC /MD/',
    name: 'PRICE T ROWE ASSOCIATES INC /MD/',
    label: 'PRICE T ROWE ASSOCIATES INC /MD/',
  },
  {
    cik: '0001534691',
    value: '0001534691,PRICE WEALTH MANAGEMENT, INC.',
    name: 'PRICE WEALTH MANAGEMENT, INC.',
    label: 'PRICE WEALTH MANAGEMENT, INC.',
  },
  {
    cik: '0001686305',
    value: '0001686305,PRIMAVERA CAPITAL MANAGEMENT LTD',
    name: 'PRIMAVERA CAPITAL MANAGEMENT LTD',
    label: 'PRIMAVERA CAPITAL MANAGEMENT LTD',
  },
  {
    cik: '0001731446',
    value: '0001731446,PRIME CAPITAL INVESTMENT ADVISORS, LLC',
    name: 'PRIME CAPITAL INVESTMENT ADVISORS, LLC',
    label: 'PRIME CAPITAL INVESTMENT ADVISORS, LLC',
  },
  {
    cik: '0001448793',
    value: '0001448793,PRIME CAPITAL MANAGEMENT CO LTD',
    name: 'PRIME CAPITAL MANAGEMENT CO LTD',
    label: 'PRIME CAPITAL MANAGEMENT CO LTD',
  },
  {
    cik: '0000763212',
    value: '0000763212,PRIMECAP MANAGEMENT CO/CA/',
    name: 'PRIMECAP MANAGEMENT CO/CA/',
    label: 'PRIMECAP MANAGEMENT CO/CA/',
  },
  {
    cik: '0001915450',
    value: '0001915450,PRIME MOVERS LAB LLC',
    name: 'PRIME MOVERS LAB LLC',
    label: 'PRIME MOVERS LAB LLC',
  },
  {
    cik: '0001909619',
    value: '0001909619,PRIMORIS WEALTH ADVISORS, LLC',
    name: 'PRIMORIS WEALTH ADVISORS, LLC',
    label: 'PRIMORIS WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001558313',
    value: '0001558313,PRINCE STREET CAPITAL MANAGEMENT LLC',
    name: 'PRINCE STREET CAPITAL MANAGEMENT LLC',
    label: 'PRINCE STREET CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001590346',
    value: '0001590346,PRINCETON ALPHA MANAGEMENT LP',
    name: 'PRINCETON ALPHA MANAGEMENT LP',
    label: 'PRINCETON ALPHA MANAGEMENT LP',
  },
  {
    cik: '0000868911',
    value: '0000868911,PRINCETON CAPITAL MANAGEMENT INC',
    name: 'PRINCETON CAPITAL MANAGEMENT INC',
    label: 'PRINCETON CAPITAL MANAGEMENT INC',
  },
  {
    cik: '0001167026',
    value: '0001167026,PRINCETON CAPITAL MANAGEMENT LLC',
    name: 'PRINCETON CAPITAL MANAGEMENT LLC',
    label: 'PRINCETON CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001767349',
    value: '0001767349,PRINCETON GLOBAL ASSET MANAGEMENT LLC',
    name: 'PRINCETON GLOBAL ASSET MANAGEMENT LLC',
    label: 'PRINCETON GLOBAL ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001568289',
    value: '0001568289,PRINCETON PORTFOLIO STRATEGIES GROUP LLC',
    name: 'PRINCETON PORTFOLIO STRATEGIES GROUP LLC',
    label: 'PRINCETON PORTFOLIO STRATEGIES GROUP LLC',
  },
  {
    cik: '0001126328',
    value: '0001126328,PRINCIPAL FINANCIAL GROUP INC',
    name: 'PRINCIPAL FINANCIAL GROUP INC',
    label: 'PRINCIPAL FINANCIAL GROUP INC',
  },
  {
    cik: '0001759271',
    value: '0001759271,PRINCIPAL STREET PARTNERS, LLC',
    name: 'PRINCIPAL STREET PARTNERS, LLC',
    label: 'PRINCIPAL STREET PARTNERS, LLC',
  },
  {
    cik: '0001743413',
    value: '0001743413,PRINCIPLE WEALTH PARTNERS LLC',
    name: 'PRINCIPLE WEALTH PARTNERS LLC',
    label: 'PRINCIPLE WEALTH PARTNERS LLC',
  },
  {
    cik: '0001568839',
    value: '0001568839,PRING TURNER CAPITAL GROUP INC',
    name: 'PRING TURNER CAPITAL GROUP INC',
    label: 'PRING TURNER CAPITAL GROUP INC',
  },
  {
    cik: '0001008929',
    value: '0001008929,PRIO WEALTH LIMITED PARTNERSHIP',
    name: 'PRIO WEALTH LIMITED PARTNERSHIP',
    label: 'PRIO WEALTH LIMITED PARTNERSHIP',
  },
  {
    cik: '0001569846',
    value: '0001569846,PRISMA CAPITAL PARTNERS LP',
    name: 'PRISMA CAPITAL PARTNERS LP',
    label: 'PRISMA CAPITAL PARTNERS LP',
  },
  {
    cik: '0001717027',
    value: '0001717027,PRISM ADVISORS, INC.',
    name: 'PRISM ADVISORS, INC.',
    label: 'PRISM ADVISORS, INC.',
  },
  {
    cik: '0001567755',
    value: '0001567755,PRIVATE ADVISOR GROUP, LLC',
    name: 'PRIVATE ADVISOR GROUP, LLC',
    label: 'PRIVATE ADVISOR GROUP, LLC',
  },
  {
    cik: '0001766226',
    value: '0001766226,PRIVATE ADVISORS, LLC',
    name: 'PRIVATE ADVISORS, LLC',
    label: 'PRIVATE ADVISORS, LLC',
  },
  {
    cik: '0001632965',
    value: '0001632965,PRIVATE ADVISORY GROUP LLC',
    name: 'PRIVATE ADVISORY GROUP LLC',
    label: 'PRIVATE ADVISORY GROUP LLC',
  },
  {
    cik: '0001026200',
    value: '0001026200,PRIVATE ASSET MANAGEMENT INC',
    name: 'PRIVATE ASSET MANAGEMENT INC',
    label: 'PRIVATE ASSET MANAGEMENT INC',
  },
  {
    cik: '0001279912',
    value: '0001279912,PRIVATE BANK & TRUST CO',
    name: 'PRIVATE BANK & TRUST CO',
    label: 'PRIVATE BANK & TRUST CO',
  },
  {
    cik: '0001350780',
    value: '0001350780,PRIVATE CAPITAL ADVISORS, INC.',
    name: 'PRIVATE CAPITAL ADVISORS, INC.',
    label: 'PRIVATE CAPITAL ADVISORS, INC.',
  },
  {
    cik: '0001732854',
    value: '0001732854,PRIVATE CAPITAL GROUP, LLC',
    name: 'PRIVATE CAPITAL GROUP, LLC',
    label: 'PRIVATE CAPITAL GROUP, LLC',
  },
  {
    cik: '0001112289',
    value: '0001112289,PRIVATE CAPITAL MANAGEMENT INC',
    name: 'PRIVATE CAPITAL MANAGEMENT INC',
    label: 'PRIVATE CAPITAL MANAGEMENT INC',
  },
  {
    cik: '0001778185',
    value: '0001778185,PRIVATE CAPITAL MANAGEMENT LLC',
    name: 'PRIVATE CAPITAL MANAGEMENT LLC',
    label: 'PRIVATE CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001588456',
    value: '0001588456,PRIVATE CAPITAL MANAGEMENT, LLC',
    name: 'PRIVATE CAPITAL MANAGEMENT, LLC',
    label: 'PRIVATE CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001453526',
    value: '0001453526,PRIVATE HARBOUR INVESTMENT MANAGEMENT & COUNSEL, LLC',
    name: 'PRIVATE HARBOUR INVESTMENT MANAGEMENT & COUNSEL, LLC',
    label: 'PRIVATE HARBOUR INVESTMENT MANAGEMENT & COUNSEL, LLC',
  },
  {
    cik: '0001142031',
    value: '0001142031,PRIVATE MANAGEMENT GROUP INC',
    name: 'PRIVATE MANAGEMENT GROUP INC',
    label: 'PRIVATE MANAGEMENT GROUP INC',
  },
  {
    cik: '0001601742',
    value: '0001601742,PRIVATE OCEAN, LLC',
    name: 'PRIVATE OCEAN, LLC',
    label: 'PRIVATE OCEAN, LLC',
  },
  {
    cik: '0001766520',
    value: '0001766520,PRIVATE PORTFOLIO PARTNERS LLC',
    name: 'PRIVATE PORTFOLIO PARTNERS LLC',
    label: 'PRIVATE PORTFOLIO PARTNERS LLC',
  },
  {
    cik: '0001277557',
    value: '0001277557,PRIVATE TRUST CO NA',
    name: 'PRIVATE TRUST CO NA',
    label: 'PRIVATE TRUST CO NA',
  },
  {
    cik: '0001640638',
    value: '0001640638,PRIVATE VISTA, LLC',
    name: 'PRIVATE VISTA, LLC',
    label: 'PRIVATE VISTA, LLC',
  },
  {
    cik: '0001462525',
    value: '0001462525,PRIVATE WEALTH ADVISORS, INC.',
    name: 'PRIVATE WEALTH ADVISORS, INC.',
    label: 'PRIVATE WEALTH ADVISORS, INC.',
  },
  {
    cik: '0001787027',
    value: '0001787027,PRIVATE WEALTH ADVISORS, LLC',
    name: 'PRIVATE WEALTH ADVISORS, LLC',
    label: 'PRIVATE WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001512780',
    value: '0001512780,PRIVATE WEALTH GROUP, LLC',
    name: 'PRIVATE WEALTH GROUP, LLC',
    label: 'PRIVATE WEALTH GROUP, LLC',
  },
  {
    cik: '0001335851',
    value: '0001335851,PRIVATE WEALTH PARTNERS, LLC',
    name: 'PRIVATE WEALTH PARTNERS, LLC',
    label: 'PRIVATE WEALTH PARTNERS, LLC',
  },
  {
    cik: '0001803189',
    value: '0001803189,PRIVATE WEALTH STRATEGIES, L.L.C.',
    name: 'PRIVATE WEALTH STRATEGIES, L.L.C.',
    label: 'PRIVATE WEALTH STRATEGIES, L.L.C.',
  },
  {
    cik: '0001795097',
    value: '0001795097,PRIVIUM FUND MANAGEMENT B.V.',
    name: 'PRIVIUM FUND MANAGEMENT B.V.',
    label: 'PRIVIUM FUND MANAGEMENT B.V.',
  },
  {
    cik: '0001845773',
    value: '0001845773,PRIVIUM FUND MANAGEMENT (UK) LTD',
    name: 'PRIVIUM FUND MANAGEMENT (UK) LTD',
    label: 'PRIVIUM FUND MANAGEMENT (UK) LTD',
  },
  {
    cik: '0001608604',
    value: '0001608604,PROBABILITIES FUND MANAGEMENT, LLC',
    name: 'PROBABILITIES FUND MANAGEMENT, LLC',
    label: 'PROBABILITIES FUND MANAGEMENT, LLC',
  },
  {
    cik: '0001676603',
    value: '0001676603,PROBITY ADVISORS, INC.',
    name: 'PROBITY ADVISORS, INC.',
    label: 'PROBITY ADVISORS, INC.',
  },
  {
    cik: '0001804329',
    value: '0001804329,PROCYON ADVISORS, LLC',
    name: 'PROCYON ADVISORS, LLC',
    label: 'PROCYON ADVISORS, LLC',
  },
  {
    cik: '0001804329',
    value: '0001804329,PROCYON PRIVATE WEALTH PARTNERS, LLC',
    name: 'PROCYON PRIVATE WEALTH PARTNERS, LLC',
    label: 'PROCYON PRIVATE WEALTH PARTNERS, LLC',
  },
  {
    cik: '0001842526',
    value: '0001842526,PROEM ADVISORS LLC',
    name: 'PROEM ADVISORS LLC',
    label: 'PROEM ADVISORS LLC',
  },
  {
    cik: '0000752798',
    value: '0000752798,PROEQUITIES, INC.',
    name: 'PROEQUITIES, INC.',
    label: 'PROEQUITIES, INC.',
  },
  {
    cik: '0000894205',
    value: '0000894205,PROFESSIONAL ADVISORY SERVICES INC',
    name: 'PROFESSIONAL ADVISORY SERVICES INC',
    label: 'PROFESSIONAL ADVISORY SERVICES INC',
  },
  {
    cik: '0001798221',
    value: '0001798221,PROFESSIONAL FINANCIAL ADVISORS, LLC',
    name: 'PROFESSIONAL FINANCIAL ADVISORS, LLC',
    label: 'PROFESSIONAL FINANCIAL ADVISORS, LLC',
  },
  {
    cik: '0001740871',
    value: '0001740871,PROFESSIONAL PLANNING',
    name: 'PROFESSIONAL PLANNING',
    label: 'PROFESSIONAL PLANNING',
  },
  {
    cik: '0001123812',
    value: '0001123812,PROFFITT & GOODSON INC',
    name: 'PROFFITT & GOODSON INC',
    label: 'PROFFITT & GOODSON INC',
  },
  {
    cik: '0001654175',
    value: '0001654175,PROFICIO CAPITAL PARTNERS LLC',
    name: 'PROFICIO CAPITAL PARTNERS LLC',
    label: 'PROFICIO CAPITAL PARTNERS LLC',
  },
  {
    cik: '0001286295',
    value: '0001286295,PROFIT INVESTMENT MANAGEMENT, LLC',
    name: 'PROFIT INVESTMENT MANAGEMENT, LLC',
    label: 'PROFIT INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001129919',
    value: '0001129919,PROFUND ADVISORS LLC',
    name: 'PROFUND ADVISORS LLC',
    label: 'PROFUND ADVISORS LLC',
  },
  {
    cik: '0001536006',
    value: '0001536006,PROGENY 3, INC.',
    name: 'PROGENY 3, INC.',
    label: 'PROGENY 3, INC.',
  },
  {
    cik: '0001353318',
    value: '0001353318,PROGRESSIVE INVESTMENT MANAGEMENT CORP',
    name: 'PROGRESSIVE INVESTMENT MANAGEMENT CORP',
    label: 'PROGRESSIVE INVESTMENT MANAGEMENT CORP',
  },
  {
    cik: '0001599325',
    value: '0001599325,PROPRIUM CAPITAL PARTNERS, L.P.',
    name: 'PROPRIUM CAPITAL PARTNERS, L.P.',
    label: 'PROPRIUM CAPITAL PARTNERS, L.P.',
  },
  {
    cik: '0001431819',
    value: '0001431819,PROQUEST ASSOCIATES IV LLC',
    name: 'PROQUEST ASSOCIATES IV LLC',
    label: 'PROQUEST ASSOCIATES IV LLC',
  },
  {
    cik: '0001844911',
    value: '0001844911,PROQUILITY PRIVATE WEALTH PARTNERS, LLC',
    name: 'PROQUILITY PRIVATE WEALTH PARTNERS, LLC',
    label: 'PROQUILITY PRIVATE WEALTH PARTNERS, LLC',
  },
  {
    cik: '0001357955',
    value: '0001357955,PROSHARE ADVISORS LLC',
    name: 'PROSHARE ADVISORS LLC',
    label: 'PROSHARE ADVISORS LLC',
  },
  {
    cik: '0001617201',
    value: '0001617201,PROSIGHT MANAGEMENT, LP',
    name: 'PROSIGHT MANAGEMENT, LP',
    label: 'PROSIGHT MANAGEMENT, LP',
  },
  {
    cik: '0001618234',
    value: '0001618234,PROSIRIS CAPITAL MANAGEMENT LP',
    name: 'PROSIRIS CAPITAL MANAGEMENT LP',
    label: 'PROSIRIS CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001632368',
    value: '0001632368,PROSPECT CAPITAL ADVISORS, LLC',
    name: 'PROSPECT CAPITAL ADVISORS, LLC',
    label: 'PROSPECT CAPITAL ADVISORS, LLC',
  },
  {
    cik: '0001837320',
    value: '0001837320,PROSPECT HILL MANAGEMENT, LLC',
    name: 'PROSPECT HILL MANAGEMENT, LLC',
    label: 'PROSPECT HILL MANAGEMENT, LLC',
  },
  {
    cik: '0001080380',
    value: '0001080380,PROSPECTOR PARTNERS LLC',
    name: 'PROSPECTOR PARTNERS LLC',
    label: 'PROSPECTOR PARTNERS LLC',
  },
  {
    cik: '0001541625',
    value: '0001541625,PROSPERA FINANCIAL SERVICES',
    name: 'PROSPERA FINANCIAL SERVICES',
    label: 'PROSPERA FINANCIAL SERVICES',
  },
  {
    cik: '0001541625',
    value: '0001541625,PROSPERA FINANCIAL SERVICES INC',
    name: 'PROSPERA FINANCIAL SERVICES INC',
    label: 'PROSPERA FINANCIAL SERVICES INC',
  },
  {
    cik: '0001514542',
    value: '0001514542,PROSPERITY CAPITAL MANAGEMENT LTD',
    name: 'PROSPERITY CAPITAL MANAGEMENT LTD',
    label: 'PROSPERITY CAPITAL MANAGEMENT LTD',
  },
  {
    cik: '0001910398',
    value: '0001910398,PROSPERITY CONSULTING GROUP, LLC',
    name: 'PROSPERITY CONSULTING GROUP, LLC',
    label: 'PROSPERITY CONSULTING GROUP, LLC',
  },
  {
    cik: '0001843292',
    value: '0001843292,PROSPERITY PLANNING, INC.',
    name: 'PROSPERITY PLANNING, INC.',
    label: 'PROSPERITY PLANNING, INC.',
  },
  {
    cik: '0000355429',
    value: '0000355429,PROTECTIVE LIFE CORP',
    name: 'PROTECTIVE LIFE CORP',
    label: 'PROTECTIVE LIFE CORP',
  },
  {
    cik: '0001925991',
    value: '0001925991,PROVENCE WEALTH MANAGEMENT GROUP',
    name: 'PROVENCE WEALTH MANAGEMENT GROUP',
    label: 'PROVENCE WEALTH MANAGEMENT GROUP',
  },
  {
    cik: '0001752906',
    value: '0001752906,PROVENIRE CAPITAL, LLC',
    name: 'PROVENIRE CAPITAL, LLC',
    label: 'PROVENIRE CAPITAL, LLC',
  },
  {
    cik: '0000931588',
    value: '0000931588,PROVIDA PENSION FUND ADMINISTRATOR',
    name: 'PROVIDA PENSION FUND ADMINISTRATOR',
    label: 'PROVIDA PENSION FUND ADMINISTRATOR',
  },
  {
    cik: '0001730814',
    value: '0001730814,PROVIDENCE CAPITAL ADVISORS, LLC',
    name: 'PROVIDENCE CAPITAL ADVISORS, LLC',
    label: 'PROVIDENCE CAPITAL ADVISORS, LLC',
  },
  {
    cik: '0001492133',
    value: '0001492133,PROVIDENCE EQUITY PARTNERS L.L.C.',
    name: 'PROVIDENCE EQUITY PARTNERS L.L.C.',
    label: 'PROVIDENCE EQUITY PARTNERS L.L.C.',
  },
  {
    cik: '0001713735',
    value: '0001713735,PROVIDENCE FIRST TRUST CO',
    name: 'PROVIDENCE FIRST TRUST CO',
    label: 'PROVIDENCE FIRST TRUST CO',
  },
  {
    cik: '0001744955',
    value: '0001744955,PROVIDENCE WEALTH ADVISORS, LLC',
    name: 'PROVIDENCE WEALTH ADVISORS, LLC',
    label: 'PROVIDENCE WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001076964',
    value: '0001076964,PROVIDENT INVESTMENT MANAGEMENT, INC.',
    name: 'PROVIDENT INVESTMENT MANAGEMENT, INC.',
    label: 'PROVIDENT INVESTMENT MANAGEMENT, INC.',
  },
  {
    cik: '0001140436',
    value: '0001140436,PROVIDENT TRUST CO',
    name: 'PROVIDENT TRUST CO',
    label: 'PROVIDENT TRUST CO',
  },
  {
    cik: '0001799797',
    value: '0001799797,PROVIDENT WEALTH MANAGEMENT, LLC',
    name: 'PROVIDENT WEALTH MANAGEMENT, LLC',
    label: 'PROVIDENT WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001305707',
    value: '0001305707,PROVISE MANAGEMENT GROUP, LLC',
    name: 'PROVISE MANAGEMENT GROUP, LLC',
    label: 'PROVISE MANAGEMENT GROUP, LLC',
  },
  {
    cik: '0001481447',
    value: '0001481447,PROXIMA CAPITAL MANAGEMENT, LLC',
    name: 'PROXIMA CAPITAL MANAGEMENT, LLC',
    label: 'PROXIMA CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001568552',
    value: '0001568552,PRUDENCE ASSET MANAGEMENT LTD',
    name: 'PRUDENCE ASSET MANAGEMENT LTD',
    label: 'PRUDENCE ASSET MANAGEMENT LTD',
  },
  {
    cik: '0001909568',
    value: '0001909568,PRUDENCE INVESTMENT MANAGEMENT (HONG KONG) LTD.',
    name: 'PRUDENCE INVESTMENT MANAGEMENT (HONG KONG) LTD.',
    label: 'PRUDENCE INVESTMENT MANAGEMENT (HONG KONG) LTD.',
  },
  {
    cik: '0001137774',
    value: '0001137774,PRUDENTIAL FINANCIAL INC',
    name: 'PRUDENTIAL FINANCIAL INC',
    label: 'PRUDENTIAL FINANCIAL INC',
  },
  {
    cik: '0000898419',
    value: '0000898419,PRUDENTIAL PLC',
    name: 'PRUDENTIAL PLC',
    label: 'PRUDENTIAL PLC',
  },
  {
    cik: '0001766005',
    value: '0001766005,PRUDENT INVESTORS NETWORK',
    name: 'PRUDENT INVESTORS NETWORK',
    label: 'PRUDENT INVESTORS NETWORK',
  },
  {
    cik: '0001766005',
    value: '0001766005,PRUDENT INVESTORS NETWORK, INC.',
    name: 'PRUDENT INVESTORS NETWORK, INC.',
    label: 'PRUDENT INVESTORS NETWORK, INC.',
  },
  {
    cik: '0001817174',
    value: '0001817174,PRUDENT MAN ADVISORS, LLC',
    name: 'PRUDENT MAN ADVISORS, LLC',
    label: 'PRUDENT MAN ADVISORS, LLC',
  },
  {
    cik: '0001760472',
    value: '0001760472,PRW WEALTH MANAGEMENT LLC',
    name: 'PRW WEALTH MANAGEMENT LLC',
    label: 'PRW WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0001906526',
    value: '0001906526,PRYSM CAPITAL, L.P.',
    name: 'PRYSM CAPITAL, L.P.',
    label: 'PRYSM CAPITAL, L.P.',
  },
  {
    cik: '0001468428',
    value: '0001468428,PSAGOT INVESTMENT HOUSE LTD.',
    name: 'PSAGOT INVESTMENT HOUSE LTD.',
    label: 'PSAGOT INVESTMENT HOUSE LTD.',
  },
  {
    cik: '0001912576',
    value: '0001912576,PSAGOT VALUE HOLDINGS LTD. / (ISRAEL)',
    name: 'PSAGOT VALUE HOLDINGS LTD. / (ISRAEL)',
    label: 'PSAGOT VALUE HOLDINGS LTD. / (ISRAEL)',
  },
  {
    cik: '0001040198',
    value: '0001040198,P SCHOENFELD ASSET MANAGEMENT LP',
    name: 'P SCHOENFELD ASSET MANAGEMENT LP',
    label: 'P SCHOENFELD ASSET MANAGEMENT LP',
  },
  {
    cik: '0001865991',
    value: '0001865991,PSG EQUITY L.L.C.',
    name: 'PSG EQUITY L.L.C.',
    label: 'PSG EQUITY L.L.C.',
  },
  {
    cik: '0001779040',
    value: '0001779040,PSI ADVISORS, LLC',
    name: 'PSI ADVISORS, LLC',
    label: 'PSI ADVISORS, LLC',
  },
  {
    cik: '0001706247',
    value: '0001706247,P-SOLVE INVESTMENTS LTD',
    name: 'P-SOLVE INVESTMENTS LTD',
    label: 'P-SOLVE INVESTMENTS LTD',
  },
  {
    cik: '0001633903',
    value: '0001633903,P-SOLVE LLC',
    name: 'P-SOLVE LLC',
    label: 'P-SOLVE LLC',
  },
  {
    cik: '0001623624',
    value: '0001623624,PSQUARED ASSET MANAGEMENT AG',
    name: 'PSQUARED ASSET MANAGEMENT AG',
    label: 'PSQUARED ASSET MANAGEMENT AG',
  },
  {
    cik: '0001810059',
    value: '0001810059,P-STS SPV GP IA, LLC',
    name: 'P-STS SPV GP IA, LLC',
    label: 'P-STS SPV GP IA, LLC',
  },
  {
    cik: '0001810095',
    value: '0001810095,P-STS SPV GP I, LLC',
    name: 'P-STS SPV GP I, LLC',
    label: 'P-STS SPV GP I, LLC',
  },
  {
    cik: '0001654913',
    value: '0001654913,PT ASSET MANAGEMENT, LLC',
    name: 'PT ASSET MANAGEMENT, LLC',
    label: 'PT ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0000315297',
    value: '0000315297,PUBLIC EMPLOYEES RETIREMENT ASSOCIATION OF COLORADO',
    name: 'PUBLIC EMPLOYEES RETIREMENT ASSOCIATION OF COLORADO',
    label: 'PUBLIC EMPLOYEES RETIREMENT ASSOCIATION OF COLORADO',
  },
  {
    cik: '0001007280',
    value: '0001007280,PUBLIC EMPLOYEES RETIREMENT SYSTEM OF OHIO',
    name: 'PUBLIC EMPLOYEES RETIREMENT SYSTEM OF OHIO',
    label: 'PUBLIC EMPLOYEES RETIREMENT SYSTEM OF OHIO',
  },
  {
    cik: '0001767640',
    value: '0001767640,PUBLIC INVESTMENT FUND',
    name: 'PUBLIC INVESTMENT FUND',
    label: 'PUBLIC INVESTMENT FUND',
  },
  {
    cik: '0001396318',
    value: '0001396318,PUBLIC SECTOR PENSION INVESTMENT BOARD',
    name: 'PUBLIC SECTOR PENSION INVESTMENT BOARD',
    label: 'PUBLIC SECTOR PENSION INVESTMENT BOARD',
  },
  {
    cik: '0001658437',
    value: '0001658437,PUISSANCE CAPITAL MANAGEMENT LP',
    name: 'PUISSANCE CAPITAL MANAGEMENT LP',
    label: 'PUISSANCE CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001238990',
    value: '0001238990,PUNCH & ASSOCIATES INVESTMENT MANAGEMENT, INC.',
    name: 'PUNCH & ASSOCIATES INVESTMENT MANAGEMENT, INC.',
    label: 'PUNCH & ASSOCIATES INVESTMENT MANAGEMENT, INC.',
  },
  {
    cik: '0001419050',
    value: '0001419050,PUNCH CARD CAPITAL, L.P.',
    name: 'PUNCH CARD CAPITAL, L.P.',
    label: 'PUNCH CARD CAPITAL, L.P.',
  },
  {
    cik: '0001631664',
    value: '0001631664,PUNCH CARD MANAGEMENT L.P.',
    name: 'PUNCH CARD MANAGEMENT L.P.',
    label: 'PUNCH CARD MANAGEMENT L.P.',
  },
  {
    cik: '0001358828',
    value: '0001358828,PUPLAVA FINANCIAL SERVICES, INC.',
    name: 'PUPLAVA FINANCIAL SERVICES, INC.',
    label: 'PUPLAVA FINANCIAL SERVICES, INC.',
  },
  {
    cik: '0001590144',
    value: '0001590144,PURA VIDA INVESTMENTS, LLC',
    name: 'PURA VIDA INVESTMENTS, LLC',
    label: 'PURA VIDA INVESTMENTS, LLC',
  },
  {
    cik: '0001571556',
    value: '0001571556,PURE FINANCIAL ADVISORS, INC.',
    name: 'PURE FINANCIAL ADVISORS, INC.',
    label: 'PURE FINANCIAL ADVISORS, INC.',
  },
  {
    cik: '0001571556',
    value: '0001571556,PURE FINANCIAL ADVISORS, LLC',
    name: 'PURE FINANCIAL ADVISORS, LLC',
    label: 'PURE FINANCIAL ADVISORS, LLC',
  },
  {
    cik: '0001844250',
    value: '0001844250,PURUS WEALTH MANAGEMENT, LLC',
    name: 'PURUS WEALTH MANAGEMENT, LLC',
    label: 'PURUS WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001140334',
    value: '0001140334,PUTNAM FL INVESTMENT MANAGEMENT CO',
    name: 'PUTNAM FL INVESTMENT MANAGEMENT CO',
    label: 'PUTNAM FL INVESTMENT MANAGEMENT CO',
  },
  {
    cik: '0001421578',
    value: '0001421578,PUTNAM INVESTMENTS LLC',
    name: 'PUTNAM INVESTMENTS LLC',
    label: 'PUTNAM INVESTMENTS LLC',
  },
  {
    cik: '0001053914',
    value: '0001053914,PUZO MICHAEL J',
    name: 'PUZO MICHAEL J',
    label: 'PUZO MICHAEL J',
  },
  {
    cik: '0001624741',
    value: '0001624741,PVG ASSET MANAGEMENT',
    name: 'PVG ASSET MANAGEMENT',
    label: 'PVG ASSET MANAGEMENT',
  },
  {
    cik: '0000820434',
    value: '0000820434,PVG ASSET MANAGEMENT CORP',
    name: 'PVG ASSET MANAGEMENT CORP',
    label: 'PVG ASSET MANAGEMENT CORP',
  },
  {
    cik: '0001263568',
    value: '00012635,PWMCO LLC',
    name: 'PWMCO LLC',
    label: 'PWMCO LLC',
  },
  {
    cik: '0001594107',
    value: '0001594107,PW PARTNERS CAPITAL MANAGEMENT LLC',
    name: 'PW PARTNERS CAPITAL MANAGEMENT LLC',
    label: 'PW PARTNERS CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001800135',
    value: '0001800135,PYA WALTMAN CAPITAL, LLC',
    name: 'PYA WALTMAN CAPITAL, LLC',
    label: 'PYA WALTMAN CAPITAL, LLC',
  },
  {
    cik: '0001631273',
    value: '0001631273,PYRRHO CAPITAL MANAGEMENT, LP',
    name: 'PYRRHO CAPITAL MANAGEMENT, LP',
    label: 'PYRRHO CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0001027796',
    value: '0001027796,PZENA INVESTMENT MANAGEMENT LLC',
    name: 'PZENA INVESTMENT MANAGEMENT LLC',
    label: 'PZENA INVESTMENT MANAGEMENT LLC',
  },
  {
    cik: '0001732543',
    value: '0001732543,Q3 ASSET MANAGEMENT',
    name: 'Q3 ASSET MANAGEMENT',
    label: 'Q3 ASSET MANAGEMENT',
  },
  {
    cik: '0001884252',
    value: '0001884252,QALHAT CAPITAL LTD',
    name: 'QALHAT CAPITAL LTD',
    label: 'QALHAT CAPITAL LTD',
  },
  {
    cik: '0000930441',
    value: '0000930441,QCI ASSET MANAGEMENT INC/NY',
    name: 'QCI ASSET MANAGEMENT INC/NY',
    label: 'QCI ASSET MANAGEMENT INC/NY',
  },
  {
    cik: '0001166340',
    value: '0001166340,QCM CAYMAN, LTD.',
    name: 'QCM CAYMAN, LTD.',
    label: 'QCM CAYMAN, LTD.',
  },
  {
    cik: '0001598279',
    value: '0001598279,Q DOMESTIC ADVISORS, LLC',
    name: 'Q DOMESTIC ADVISORS, LLC',
    label: 'Q DOMESTIC ADVISORS, LLC',
  },
  {
    cik: '0001522955',
    value: '0001522955,QFR CAPITAL MANAGEMENT, L.P.',
    name: 'QFR CAPITAL MANAGEMENT, L.P.',
    label: 'QFR CAPITAL MANAGEMENT, L.P.',
  },
  {
    cik: '0001469589',
    value: '0001469589,Q GLOBAL ADVISORS, LLC',
    name: 'Q GLOBAL ADVISORS, LLC',
    label: 'Q GLOBAL ADVISORS, LLC',
  },
  {
    cik: '0001909617',
    value: '0001909617,QIMING U.S. VENTURES MANAGEMENT, LLC',
    name: 'QIMING U.S. VENTURES MANAGEMENT, LLC',
    label: 'QIMING U.S. VENTURES MANAGEMENT, LLC',
  },
  {
    cik: '0001831076',
    value: '0001831076,QPULA TRADING MANAGEMENT LP',
    name: 'QPULA TRADING MANAGEMENT LP',
    label: 'QPULA TRADING MANAGEMENT LP',
  },
  {
    cik: '0001802496',
    value: '0001802496,QP WEALTH MANAGEMENT, LLC',
    name: 'QP WEALTH MANAGEMENT, LLC',
    label: 'QP WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0000316471',
    value: '0000316471,QS BATTERYMARCH FINANCIAL MANAGEMENT, INC',
    name: 'QS BATTERYMARCH FINANCIAL MANAGEMENT, INC',
    label: 'QS BATTERYMARCH FINANCIAL MANAGEMENT, INC',
  },
  {
    cik: '0001501436',
    value: '0001501436,QS INVESTORS, LLC',
    name: 'QS INVESTORS, LLC',
    label: 'QS INVESTORS, LLC',
  },
  {
    cik: '0001574917',
    value: '0001574917,QS LEGG MASON GLOBAL ASSET ALLOCATION, LLC',
    name: 'QS LEGG MASON GLOBAL ASSET ALLOCATION, LLC',
    label: 'QS LEGG MASON GLOBAL ASSET ALLOCATION, LLC',
  },
  {
    cik: '0001768099',
    value: '0001768099,QTRON INVESTMENTS LLC',
    name: 'QTRON INVESTMENTS LLC',
    label: 'QTRON INVESTMENTS LLC',
  },
  {
    cik: '0001615486',
    value: '0001615486,QUAD CAPITAL MANAGEMENT ADVISORS LLC',
    name: 'QUAD CAPITAL MANAGEMENT ADVISORS LLC',
    label: 'QUAD CAPITAL MANAGEMENT ADVISORS LLC',
  },
  {
    cik: '0001741426',
    value: '0001741426,QUAD-CITIES INVESTMENT GROUP, LLC',
    name: 'QUAD-CITIES INVESTMENT GROUP, LLC',
    label: 'QUAD-CITIES INVESTMENT GROUP, LLC',
  },
  {
    cik: '0001281082',
    value: '0001281082,QUADRANGLE GP INVESTORS LLC',
    name: 'QUADRANGLE GP INVESTORS LLC',
    label: 'QUADRANGLE GP INVESTORS LLC',
  },
  {
    cik: '0001650717',
    value: '0001650717,QUADRANT CAPITAL GROUP LLC',
    name: 'QUADRANT CAPITAL GROUP LLC',
    label: 'QUADRANT CAPITAL GROUP LLC',
  },
  {
    cik: '0001615359',
    value: '0001615359,QUADRANT CAPITAL MANAGEMENT, LLC',
    name: 'QUADRANT CAPITAL MANAGEMENT, LLC',
    label: 'QUADRANT CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001727454',
    value: '0001727454,QUADRANT PRIVATE WEALTH MANAGEMENT, LLC',
    name: 'QUADRANT PRIVATE WEALTH MANAGEMENT, LLC',
    label: 'QUADRANT PRIVATE WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001504510',
    value: '0001504510,QUADRANT REAL ESTATE ADVISORS LLC',
    name: 'QUADRANT REAL ESTATE ADVISORS LLC',
    label: 'QUADRANT REAL ESTATE ADVISORS LLC',
  },
  {
    cik: '0001697722',
    value: '0001697722,QUADRATIC CAPITAL MANAGEMENT LLC',
    name: 'QUADRATIC CAPITAL MANAGEMENT LLC',
    label: 'QUADRATIC CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001549410',
    value: '0001549410,QUADRATURE CAPITAL LLP',
    name: 'QUADRATURE CAPITAL LLP',
    label: 'QUADRATURE CAPITAL LLP',
  },
  {
    cik: '0001651424',
    value: '0001651424,QUADRATURE CAPITAL LTD',
    name: 'QUADRATURE CAPITAL LTD',
    label: 'QUADRATURE CAPITAL LTD',
  },
  {
    cik: '0001843510',
    value: '0001843510,QUAERO CAPITAL S.A.',
    name: 'QUAERO CAPITAL S.A.',
    label: 'QUAERO CAPITAL S.A.',
  },
  {
    cik: '0001633648',
    value: '0001633648,QUAKER CAPITAL INVESTMENTS, LLC',
    name: 'QUAKER CAPITAL INVESTMENTS, LLC',
    label: 'QUAKER CAPITAL INVESTMENTS, LLC',
  },
  {
    cik: '0001733472',
    value: '0001733472,QUAKER WEALTH MANAGEMENT, LLC',
    name: 'QUAKER WEALTH MANAGEMENT, LLC',
    label: 'QUAKER WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0000804328',
    value: '0000804328,QUALCOMM INC/DE',
    name: 'QUALCOMM INC/DE',
    label: 'QUALCOMM INC/DE',
  },
  {
    cik: '0001603905',
    value: '0001603905,QUALITY GROWTH MANAGEMENT, INC.',
    name: 'QUALITY GROWTH MANAGEMENT, INC.',
    label: 'QUALITY GROWTH MANAGEMENT, INC.',
  },
  {
    cik: '0001713587',
    value: '0001713587,QUANTAMENTAL TECHNOLOGIES LLC',
    name: 'QUANTAMENTAL TECHNOLOGIES LLC',
    label: 'QUANTAMENTAL TECHNOLOGIES LLC',
  },
  {
    cik: '0001512991',
    value: '0001512991,QUANTBOT TECHNOLOGIES LP',
    name: 'QUANTBOT TECHNOLOGIES LP',
    label: 'QUANTBOT TECHNOLOGIES LP',
  },
  {
    cik: '0001561418',
    value: '0001561418,QUANTEDGE CAPITAL PTE LTD',
    name: 'QUANTEDGE CAPITAL PTE LTD',
    label: 'QUANTEDGE CAPITAL PTE LTD',
  },
  {
    cik: '0001759654',
    value: '0001759654,QUANTINNO CAPITAL MANAGEMENT LP',
    name: 'QUANTINNO CAPITAL MANAGEMENT LP',
    label: 'QUANTINNO CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001368905',
    value: '0001368905,QUANTITATIVE ADVANTAGE LLC',
    name: 'QUANTITATIVE ADVANTAGE LLC',
    label: 'QUANTITATIVE ADVANTAGE LLC',
  },
  {
    cik: '0001368905',
    value: '0001368905,QUANTITATIVE ADVANTAGE, LLC',
    name: 'QUANTITATIVE ADVANTAGE, LLC',
    label: 'QUANTITATIVE ADVANTAGE, LLC',
  },
  {
    cik: '0001445911',
    value: '0001445911,QUANTITATIVE INVESTMENT MANAGEMENT, LLC',
    name: 'QUANTITATIVE INVESTMENT MANAGEMENT, LLC',
    label: 'QUANTITATIVE INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001591902',
    value: '0001591902,QUANTITATIVE SYSTEMATIC STRATEGIES LLC',
    name: 'QUANTITATIVE SYSTEMATIC STRATEGIES LLC',
    label: 'QUANTITATIVE SYSTEMATIC STRATEGIES LLC',
  },
  {
    cik: '0001861809',
    value: '0001861809,QUANTITATIVE VALUE TECHNOLOGIES, LLC',
    name: 'QUANTITATIVE VALUE TECHNOLOGIES, LLC',
    label: 'QUANTITATIVE VALUE TECHNOLOGIES, LLC',
  },
  {
    cik: '0001600581',
    value: '0001600581,QUANTRES ASSET MANAGEMENT LTD',
    name: 'QUANTRES ASSET MANAGEMENT LTD',
    label: 'QUANTRES ASSET MANAGEMENT LTD',
  },
  {
    cik: '0001280511',
    value: '0001280511,QUANTUM CAPITAL MANAGEMENT',
    name: 'QUANTUM CAPITAL MANAGEMENT',
    label: 'QUANTUM CAPITAL MANAGEMENT',
  },
  {
    cik: '0001513125',
    value: '0001513125,QUANTUM CAPITAL MANAGEMENT, LLC / NJ',
    name: 'QUANTUM CAPITAL MANAGEMENT, LLC / NJ',
    label: 'QUANTUM CAPITAL MANAGEMENT, LLC / NJ',
  },
  {
    cik: '0001929139',
    value: '0001929139,QUANTUM FINANCIAL ADVISORS, LLC',
    name: 'QUANTUM FINANCIAL ADVISORS, LLC',
    label: 'QUANTUM FINANCIAL ADVISORS, LLC',
  },
  {
    cik: '0001929662',
    value: '0001929662,QUANTUM PRIVATE WEALTH, LLC',
    name: 'QUANTUM PRIVATE WEALTH, LLC',
    label: 'QUANTUM PRIVATE WEALTH, LLC',
  },
  {
    cik: '0001705929',
    value: '0001705929,QUATTRO FINANCIAL ADVISORS LLC',
    name: 'QUATTRO FINANCIAL ADVISORS LLC',
    label: 'QUATTRO FINANCIAL ADVISORS LLC',
  },
  {
    cik: '0001729829',
    value: '0001729829,QUBE RESEARCH & TECHNOLOGIES LTD',
    name: 'QUBE RESEARCH & TECHNOLOGIES LTD',
    label: 'QUBE RESEARCH & TECHNOLOGIES LTD',
  },
  {
    cik: '0001912338',
    value: '0001912338,QUENT CAPITAL, LLC',
    name: 'QUENT CAPITAL, LLC',
    label: 'QUENT CAPITAL, LLC',
  },
  {
    cik: '0001564579',
    value: '0001564579,QUENTEC ASSET MANAGEMENT LLC',
    name: 'QUENTEC ASSET MANAGEMENT LLC',
    label: 'QUENTEC ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001764171',
    value: '0001764171,QUESTAR ASSET MANAGEMENT, INC.',
    name: 'QUESTAR ASSET MANAGEMENT, INC.',
    label: 'QUESTAR ASSET MANAGEMENT, INC.',
  },
  {
    cik: '0000826516',
    value: '0000826516,QUEST CAPITAL MANAGEMENT INC /ADV',
    name: 'QUEST CAPITAL MANAGEMENT INC /ADV',
    label: 'QUEST CAPITAL MANAGEMENT INC /ADV',
  },
  {
    cik: '0001036248',
    value: '0001036248,QUEST INVESTMENT MANAGEMENT INC/OR',
    name: 'QUEST INVESTMENT MANAGEMENT INC/OR',
    label: 'QUEST INVESTMENT MANAGEMENT INC/OR',
  },
  {
    cik: '0001036248',
    value: '0001036248,QUEST INVESTMENT MANAGEMENT LLC',
    name: 'QUEST INVESTMENT MANAGEMENT LLC',
    label: 'QUEST INVESTMENT MANAGEMENT LLC',
  },
  {
    cik: '0001749851',
    value: '0001749851,QUILTER INVESTORS LTD',
    name: 'QUILTER INVESTORS LTD',
    label: 'QUILTER INVESTORS LTD',
  },
  {
    cik: '0001770632',
    value: '0001770632,QUILTER PLC',
    name: 'QUILTER PLC',
    label: 'QUILTER PLC',
  },
  {
    cik: '0001633275',
    value: '0001633275,QUINN OPPORTUNITY PARTNERS LLC',
    name: 'QUINN OPPORTUNITY PARTNERS LLC',
    label: 'QUINN OPPORTUNITY PARTNERS LLC',
  },
  {
    cik: '0001498035',
    value: '0001498035,QUINTIUM ADVISORS, LLC',
    name: 'QUINTIUM ADVISORS, LLC',
    label: 'QUINTIUM ADVISORS, LLC',
  },
  {
    cik: '0001455139',
    value: '0001455139,QUOTIENT INVESTORS, LLC',
    name: 'QUOTIENT INVESTORS, LLC',
    label: 'QUOTIENT INVESTORS, LLC',
  },
  {
    cik: '0001912226',
    value: '0001912226,QVIDTVM MANAGEMENT LLC',
    name: 'QVIDTVM MANAGEMENT LLC',
    label: 'QVIDTVM MANAGEMENT LLC',
  },
  {
    cik: '0001570253',
    value: '0001570253,QV INVESTORS INC.',
    name: 'QV INVESTORS INC.',
    label: 'QV INVESTORS INC.',
  },
  {
    cik: '0001718858',
    value: '000171,QVR LLC58',
    name: 'QVR LLC',
    label: 'QVR LLC',
  },
  {
    cik: '0001290162',
    value: '0001290162,QVT FINANCIAL LP',
    name: 'QVT FINANCIAL LP',
    label: 'QVT FINANCIAL LP',
  },
  {
    cik: '0001616664',
    value: '0001616664,RAAB & MOSKOWITZ ASSET MANAGEMENT LLC',
    name: 'RAAB & MOSKOWITZ ASSET MANAGEMENT LLC',
    label: 'RAAB & MOSKOWITZ ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001098062',
    value: '0001098062,RABOBANK NEDERLAND /FI',
    name: 'RABOBANK NEDERLAND /FI',
    label: 'RABOBANK NEDERLAND /FI',
  },
  {
    cik: '0001346824',
    value: '0001346824,RA CAPITAL MANAGEMENT, L.P.',
    name: 'RA CAPITAL MANAGEMENT, L.P.',
    label: 'RA CAPITAL MANAGEMENT, L.P.',
  },
  {
    cik: '0001218275',
    value: '0001218275,RADCLIFFE CAPITAL MANAGEMENT, L.P.',
    name: 'RADCLIFFE CAPITAL MANAGEMENT, L.P.',
    label: 'RADCLIFFE CAPITAL MANAGEMENT, L.P.',
  },
  {
    cik: '0001911132',
    value: '0001911132,RADCLIFF MANAGEMENT LLC',
    name: 'RADCLIFF MANAGEMENT LLC',
    label: 'RADCLIFF MANAGEMENT LLC',
  },
  {
    cik: '0001756893',
    value: '0001756893,RADIN CAPITAL PARTNERS INC.',
    name: 'RADIN CAPITAL PARTNERS INC.',
    label: 'RADIN CAPITAL PARTNERS INC.',
  },
  {
    cik: '0001696867',
    value: '0001696867,RADNOR CAPITAL MANAGEMENT, LLC',
    name: 'RADNOR CAPITAL MANAGEMENT, LLC',
    label: 'RADNOR CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001660405',
    value: '0001660405,RADNOR FINANCIAL ADVISORS, LLC',
    name: 'RADNOR FINANCIAL ADVISORS, LLC',
    label: 'RADNOR FINANCIAL ADVISORS, LLC',
  },
  {
    cik: '0001389426',
    value: '0001389426,RAFFERTY ASSET MANAGEMENT, LLC',
    name: 'RAFFERTY ASSET MANAGEMENT, LLC',
    label: 'RAFFERTY ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001169581',
    value: '0001169581,RAFFLES ASSOCIATES LP',
    name: 'RAFFLES ASSOCIATES LP',
    label: 'RAFFLES ASSOCIATES LP',
  },
  {
    cik: '0001444376',
    value: '0001444376,RAGING CAPITAL MANAGEMENT, LLC',
    name: 'RAGING CAPITAL MANAGEMENT, LLC',
    label: 'RAGING CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001026303',
    value: '0001026303,RAIFF PARTNERS INC',
    name: 'RAIFF PARTNERS INC',
    label: 'RAIFF PARTNERS INC',
  },
  {
    cik: '0001350312',
    value: '0001350312,RAIL-SPLITTER CAPITAL MANAGEMENT, LLC',
    name: 'RAIL-SPLITTER CAPITAL MANAGEMENT, LLC',
    label: 'RAIL-SPLITTER CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001658020',
    value: '0001658020,RAILWAY PENSION INVESTMENTS LTD',
    name: 'RAILWAY PENSION INVESTMENTS LTD',
    label: 'RAILWAY PENSION INVESTMENTS LTD',
  },
  {
    cik: '0001667553',
    value: '0001667553,RAIN CAPITAL MANAGEMENT, LLC',
    name: 'RAIN CAPITAL MANAGEMENT, LLC',
    label: 'RAIN CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001631543',
    value: '0001631543,RAINE CAPITAL LLC',
    name: 'RAINE CAPITAL LLC',
    label: 'RAINE CAPITAL LLC',
  },
  {
    cik: '0001728355',
    value: '0001728355,RAINEY & RANDALL INVESTMENT MANAGEMENT INC.',
    name: 'RAINEY & RANDALL INVESTMENT MANAGEMENT INC.',
    label: 'RAINEY & RANDALL INVESTMENT MANAGEMENT INC.',
  },
  {
    cik: '0001424116',
    value: '0001424116,RAINIER GROUP INVESTMENT ADVISORY LLC',
    name: 'RAINIER GROUP INVESTMENT ADVISORY LLC',
    label: 'RAINIER GROUP INVESTMENT ADVISORY LLC',
  },
  {
    cik: '0000947772',
    value: '0000947772,RAINIER INVESTMENT MANAGEMENT LLC',
    name: 'RAINIER INVESTMENT MANAGEMENT LLC',
    label: 'RAINIER INVESTMENT MANAGEMENT LLC',
  },
  {
    cik: '0001912314',
    value: '0001912314,RAINSBERGER WEALTH ADVISORS, INC.',
    name: 'RAINSBERGER WEALTH ADVISORS, INC.',
    label: 'RAINSBERGER WEALTH ADVISORS, INC.',
  },
  {
    cik: '0001729139',
    value: '0001729139,RAINWATER CHARITABLE FOUNDATION',
    name: 'RAINWATER CHARITABLE FOUNDATION',
    label: 'RAINWATER CHARITABLE FOUNDATION',
  },
  {
    cik: '0001911726',
    value: '0001911726,RALEIGH CAPITAL MANAGEMENT INC.',
    name: 'RALEIGH CAPITAL MANAGEMENT INC.',
    label: 'RALEIGH CAPITAL MANAGEMENT INC.',
  },
  {
    cik: '0001275893',
    value: '0001275893,RAMIUS ADVISORS LLC',
    name: 'RAMIUS ADVISORS LLC',
    label: 'RAMIUS ADVISORS LLC',
  },
  {
    cik: '0001633693',
    value: '0001633693,RAMPART INVESTMENT MANAGEMENT COMPANY, LLC',
    name: 'RAMPART INVESTMENT MANAGEMENT COMPANY, LLC',
    label: 'RAMPART INVESTMENT MANAGEMENT COMPANY, LLC',
  },
  {
    cik: '0001541399',
    value: '0001541399,RAMSAY, STATTMAN, VELA & PRICE, INC.',
    name: 'RAMSAY, STATTMAN, VELA & PRICE, INC.',
    label: 'RAMSAY, STATTMAN, VELA & PRICE, INC.',
  },
  {
    cik: '0001173329',
    value: '0001173329,RAMSEY ASSET MANAGEMENT',
    name: 'RAMSEY ASSET MANAGEMENT',
    label: 'RAMSEY ASSET MANAGEMENT',
  },
  {
    cik: '0001132897',
    value: '0001132897,RAMSEY QUANTITATIVE SYSTEMS',
    name: 'RAMSEY QUANTITATIVE SYSTEMS',
    label: 'RAMSEY QUANTITATIVE SYSTEMS',
  },
  {
    cik: '0001793269',
    value: '0001793269,RANCH CAPITAL ADVISORS INC.',
    name: 'RANCH CAPITAL ADVISORS INC.',
    label: 'RANCH CAPITAL ADVISORS INC.',
  },
  {
    cik: '0001475150',
    value: '0001475150,RANDOLPH CO INC',
    name: 'RANDOLPH CO INC',
    label: 'RANDOLPH CO INC',
  },
  {
    cik: '0001540141',
    value: '0001540141,RAND WEALTH, LLC',
    name: 'RAND WEALTH, LLC',
    label: 'RAND WEALTH, LLC',
  },
  {
    cik: '0001928635',
    value: '0001928635,RANGE FINANCIAL GROUP LLC',
    name: 'RANGE FINANCIAL GROUP LLC',
    label: 'RANGE FINANCIAL GROUP LLC',
  },
  {
    cik: '0001689007',
    value: '0001689007,RANGER GLOBAL REAL ESTATE ADVISORS, LLC',
    name: 'RANGER GLOBAL REAL ESTATE ADVISORS, LLC',
    label: 'RANGER GLOBAL REAL ESTATE ADVISORS, LLC',
  },
  {
    cik: '0001556970',
    value: '0001556970,RANGER INTERNATIONAL MANAGEMENT, LP',
    name: 'RANGER INTERNATIONAL MANAGEMENT, LP',
    label: 'RANGER INTERNATIONAL MANAGEMENT, LP',
  },
  {
    cik: '0001319691',
    value: '0001319691,RANGER INVESTMENT MANAGEMENT, L.P.',
    name: 'RANGER INVESTMENT MANAGEMENT, L.P.',
    label: 'RANGER INVESTMENT MANAGEMENT, L.P.',
  },
  {
    cik: '0001453155',
    value: '0001453155,RAPTOR CAPITAL MANAGEMENT LP',
    name: 'RAPTOR CAPITAL MANAGEMENT LP',
    label: 'RAPTOR CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001499094',
    value: '0001499094,RARE INFRASTRUCTURE LTD',
    name: 'RARE INFRASTRUCTURE LTD',
    label: 'RARE INFRASTRUCTURE LTD',
  },
  {
    cik: '0001566887',
    value: '0001566887,RATAN CAPITAL MANAGEMENT LP',
    name: 'RATAN CAPITAL MANAGEMENT LP',
    label: 'RATAN CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001351991',
    value: '0001351991,RATHBONE BROTHERS PLC',
    name: 'RATHBONE BROTHERS PLC',
    label: 'RATHBONE BROTHERS PLC',
  },
  {
    cik: '0001351991',
    value: '0001351991,RATHBONES GROUP PLC',
    name: 'RATHBONES GROUP PLC',
    label: 'RATHBONES GROUP PLC',
  },
  {
    cik: '0001582112',
    value: '0001582112,RATHER & KITTRELL, INC.',
    name: 'RATHER & KITTRELL, INC.',
    label: 'RATHER & KITTRELL, INC.',
  },
  {
    cik: '0001143565',
    value: '0001143565,RATIONAL ADVISORS LLC',
    name: 'RATIONAL ADVISORS LLC',
    label: 'RATIONAL ADVISORS LLC',
  },
  {
    cik: '0001854762',
    value: '0001854,RATOS AB2',
    name: 'RATOS AB',
    label: 'RATOS AB',
  },
  {
    cik: '0001554656',
    value: '0001554656,RAUB BROCK CAPITAL MANAGEMENT LP',
    name: 'RAUB BROCK CAPITAL MANAGEMENT LP',
    label: 'RAUB BROCK CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001613715',
    value: '0001613715,RAVENEUR INVESTMENT GROUP LP',
    name: 'RAVENEUR INVESTMENT GROUP LP',
    label: 'RAVENEUR INVESTMENT GROUP LP',
  },
  {
    cik: '0001607618',
    value: '0001607618,RAVEN ROCK CAPITAL MANAGEMENT, LLC',
    name: 'RAVEN ROCK CAPITAL MANAGEMENT, LLC',
    label: 'RAVEN ROCK CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001569433',
    value: '0001569433,RAVEN ROCK CREDIT MASTER FUND L.P.',
    name: 'RAVEN ROCK CREDIT MASTER FUND L.P.',
    label: 'RAVEN ROCK CREDIT MASTER FUND L.P.',
  },
  {
    cik: '0000837286',
    value: '0000837286,RAY GERALD L & ASSOCIATES LTD',
    name: 'RAY GERALD L & ASSOCIATES LTD',
    label: 'RAY GERALD L & ASSOCIATES LTD',
  },
  {
    cik: '0001084208',
    value: '0001084208,RAYMOND JAMES & ASSOCIATES',
    name: 'RAYMOND JAMES & ASSOCIATES',
    label: 'RAYMOND JAMES & ASSOCIATES',
  },
  {
    cik: '0001462284',
    value: '0001462284,RAYMOND JAMES FINANCIAL SERVICES ADVISORS, INC.',
    name: 'RAYMOND JAMES FINANCIAL SERVICES ADVISORS, INC.',
    label: 'RAYMOND JAMES FINANCIAL SERVICES ADVISORS, INC.',
  },
  {
    cik: '0001927067',
    value: '0001927067,RAYMOND JAMES TRUST CO. OF NH',
    name: 'RAYMOND JAMES TRUST CO. OF NH',
    label: 'RAYMOND JAMES TRUST CO. OF NH',
  },
  {
    cik: '0001088950',
    value: '0001088950,RAYMOND JAMES TRUST N.A.',
    name: 'RAYMOND JAMES TRUST N.A.',
    label: 'RAYMOND JAMES TRUST N.A.',
  },
  {
    cik: '0001121877',
    value: '0001121877,RAYNER & HAYNOR',
    name: 'RAYNER & HAYNOR',
    label: 'RAYNER & HAYNOR',
  },
  {
    cik: '0001199406',
    value: '0001199406,RAYNOR GEOFFREY',
    name: 'RAYNOR GEOFFREY',
    label: 'RAYNOR GEOFFREY',
  },
  {
    cik: '0001776296',
    value: '0001776296,RBA WEALTH MANAGEMENT, LLC',
    name: 'RBA WEALTH MANAGEMENT, LLC',
    label: 'RBA WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001695582',
    value: '0001695582,RB CAPITAL MANAGEMENT, LLC',
    name: 'RB CAPITAL MANAGEMENT, LLC',
    label: 'RB CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001134621',
    value: '0001134621,RBF CAPITAL, LLC',
    name: 'RBF CAPITAL, LLC',
    label: 'RBF CAPITAL, LLC',
  },
  {
    cik: '0001162827',
    value: '0001162827,RBO & CO LLC',
    name: 'RBO & CO LLC',
    label: 'RBO & CO LLC',
  },
  {
    cik: '0000860585',
    value: '0000860585,RBS PARTNERS, L.P.',
    name: 'RBS PARTNERS, L.P.',
    label: 'RBS PARTNERS, L.P.',
  },
  {
    cik: '0001546773',
    value: '0001546773,RCF MANAGEMENT L.L.C.',
    name: 'RCF MANAGEMENT L.L.C.',
    label: 'RCF MANAGEMENT L.L.C.',
  },
  {
    cik: '0001774086',
    value: '0001774086,RDA FINANCIAL NETWORK',
    name: 'RDA FINANCIAL NETWORK',
    label: 'RDA FINANCIAL NETWORK',
  },
  {
    cik: '0001579111',
    value: '0001579111,RDL FINANCIAL INC',
    name: 'RDL FINANCIAL INC',
    label: 'RDL FINANCIAL INC',
  },
  {
    cik: '0001912187',
    value: '0001912187,RDST CAPITAL LLC',
    name: 'RDST CAPITAL LLC',
    label: 'RDST CAPITAL LLC',
  },
  {
    cik: '0000944804',
    value: '0000944804,RE ADVISERS CORP',
    name: 'RE ADVISERS CORP',
    label: 'RE ADVISERS CORP',
  },
  {
    cik: '0001177018',
    value: '0001177018,REAL ESTATE MANAGEMENT SERVICES LLC',
    name: 'REAL ESTATE MANAGEMENT SERVICES LLC',
    label: 'REAL ESTATE MANAGEMENT SERVICES LLC',
  },
  {
    cik: '0001467888',
    value: '0001467888,REALM PARTNERS LLC',
    name: 'REALM PARTNERS LLC',
    label: 'REALM PARTNERS LLC',
  },
  {
    cik: '0000740913',
    value: '0000740913,REAVES W H & CO INC',
    name: 'REAVES W H & CO INC',
    label: 'REAVES W H & CO INC',
  },
  {
    cik: '0001674502',
    value: '0001674502,REBY ADVISORS, LLC',
    name: 'REBY ADVISORS, LLC',
    label: 'REBY ADVISORS, LLC',
  },
  {
    cik: '0001729522',
    value:
      '0001729522,RECTOR, CHURCH-WARDENS & VESTRYMEN OF TRINITY CHURCH IN THE CITY OF NEW-YORK',
    name: 'RECTOR, CHURCH-WARDENS & VESTRYMEN OF TRINITY CHURCH IN THE CITY OF NEW-YORK',
    label:
      'RECTOR, CHURCH-WARDENS & VESTRYMEN OF TRINITY CHURCH IN THE CITY OF NEW-YORK',
  },
  {
    cik: '0001803146',
    value: '0001803146,RECURRENT INVESTMENT ADVISORS LLC',
    name: 'RECURRENT INVESTMENT ADVISORS LLC',
    label: 'RECURRENT INVESTMENT ADVISORS LLC',
  },
  {
    cik: '0001593365',
    value: '0001593365,RED ALDER LLC',
    name: 'RED ALDER LLC',
    label: 'RED ALDER LLC',
  },
  {
    cik: '0001729867',
    value: '0001729867,RED CEDAR CAPITAL, LLC',
    name: 'RED CEDAR CAPITAL, LLC',
    label: 'RED CEDAR CAPITAL, LLC',
  },
  {
    cik: '0001734398',
    value: '0001734398,RED CEDAR INVESTMENT MANAGEMENT, LLC',
    name: 'RED CEDAR INVESTMENT MANAGEMENT, LLC',
    label: 'RED CEDAR INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001703147',
    value: '0001703147,RED CEDAR MANAGEMENT, LP',
    name: 'RED CEDAR MANAGEMENT, LP',
    label: 'RED CEDAR MANAGEMENT, LP',
  },
  {
    cik: '0001783599',
    value: '0001783599,RED DOOR WEALTH MANAGEMENT, LLC',
    name: 'RED DOOR WEALTH MANAGEMENT, LLC',
    label: 'RED DOOR WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001842054',
    value: '0001842054,REDE WEALTH, LLC',
    name: 'REDE WEALTH, LLC',
    label: 'REDE WEALTH, LLC',
  },
  {
    cik: '0001535811',
    value: '0001535811,REDHAWK WEALTH ADVISORS, INC.',
    name: 'REDHAWK WEALTH ADVISORS, INC.',
    label: 'REDHAWK WEALTH ADVISORS, INC.',
  },
  {
    cik: '0001801241',
    value: '0001801241,RE DICKINSON INVESTMENT ADVISORS, LLC',
    name: 'RE DICKINSON INVESTMENT ADVISORS, LLC',
    label: 'RE DICKINSON INVESTMENT ADVISORS, LLC',
  },
  {
    cik: '0001425738',
    value: '0001425738,REDMILE GROUP, LLC',
    name: 'REDMILE GROUP, LLC',
    label: 'REDMILE GROUP, LLC',
  },
  {
    cik: '0001567890',
    value: '0001567890,REDMOND ASSET MANAGEMENT, LLC',
    name: 'REDMOND ASSET MANAGEMENT, LLC',
    label: 'REDMOND ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001910482',
    value: '0001910482,REDMONT WEALTH ADVISORS LLC',
    name: 'REDMONT WEALTH ADVISORS LLC',
    label: 'REDMONT WEALTH ADVISORS LLC',
  },
  {
    cik: '0001374588',
    value: '0001374588,RED MOUNTAIN CAPITAL PARTNERS LLC',
    name: 'RED MOUNTAIN CAPITAL PARTNERS LLC',
    label: 'RED MOUNTAIN CAPITAL PARTNERS LLC',
  },
  {
    cik: '0001732538',
    value: '0001732538,REDPOINT INVESTMENT MANAGEMENT PTY LTD',
    name: 'REDPOINT INVESTMENT MANAGEMENT PTY LTD',
    label: 'REDPOINT INVESTMENT MANAGEMENT PTY LTD',
  },
  {
    cik: '0001689508',
    value: '0001689508,REDPOINT MANAGEMENT, LLC',
    name: 'REDPOINT MANAGEMENT, LLC',
    label: 'REDPOINT MANAGEMENT, LLC',
  },
  {
    cik: '0001504209',
    value: '0001504209,RED ROCKS CAPITAL LLC',
    name: 'RED ROCKS CAPITAL LLC',
    label: 'RED ROCKS CAPITAL LLC',
  },
  {
    cik: '0001795356',
    value: '0001795356,RED SPRUCE CAPITAL, LLC',
    name: 'RED SPRUCE CAPITAL, LLC',
    label: 'RED SPRUCE CAPITAL, LLC',
  },
  {
    cik: '0001910488',
    value: '0001910488,RED TORTOISE LLC',
    name: 'RED TORTOISE LLC',
    label: 'RED TORTOISE LLC',
  },
  {
    cik: '0001846493',
    value: '0001846493,RED WAVE INVESTMENTS LLC',
    name: 'RED WAVE INVESTMENTS LLC',
    label: 'RED WAVE INVESTMENTS LLC',
  },
  {
    cik: '0001575578',
    value: '0001575578,REDWOOD CAPITAL INVESTMENTS, LLC',
    name: 'REDWOOD CAPITAL INVESTMENTS, LLC',
    label: 'REDWOOD CAPITAL INVESTMENTS, LLC',
  },
  {
    cik: '0001316622',
    value: '0001316622,REDWOOD CAPITAL MANAGEMENT, LLC',
    name: 'REDWOOD CAPITAL MANAGEMENT, LLC',
    label: 'REDWOOD CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001927724',
    value: '0001927724,REDWOOD FINANCIAL NETWORK CORP',
    name: 'REDWOOD FINANCIAL NETWORK CORP',
    label: 'REDWOOD FINANCIAL NETWORK CORP',
  },
  {
    cik: '0001691170',
    value: '0001691170,REDWOOD GROVE CAPITAL, LLC',
    name: 'REDWOOD GROVE CAPITAL, LLC',
    label: 'REDWOOD GROVE CAPITAL, LLC',
  },
  {
    cik: '0001717479',
    value: '0001717479,REDWOOD INVESTMENT MANAGEMENT, LLC',
    name: 'REDWOOD INVESTMENT MANAGEMENT, LLC',
    label: 'REDWOOD INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001384058',
    value: '0001384058,REDWOOD INVESTMENTS, LLC',
    name: 'REDWOOD INVESTMENTS, LLC',
    label: 'REDWOOD INVESTMENTS, LLC',
  },
  {
    cik: '0001688931',
    value: '0001688931,REDW STANLEY FINANCIAL ADVISORS LLC',
    name: 'REDW STANLEY FINANCIAL ADVISORS LLC',
    label: 'REDW STANLEY FINANCIAL ADVISORS LLC',
  },
  {
    cik: '0001688931',
    value: '0001688931,REDW WEALTH LLC',
    name: 'REDW WEALTH LLC',
    label: 'REDW WEALTH LLC',
  },
  {
    cik: '0001724729',
    value: '0001724729,REFINED WEALTH MANAGEMENT',
    name: 'REFINED WEALTH MANAGEMENT',
    label: 'REFINED WEALTH MANAGEMENT',
  },
  {
    cik: '0001559789',
    value: '0001559789,REGAL INVESTMENT ADVISORS LLC',
    name: 'REGAL INVESTMENT ADVISORS LLC',
    label: 'REGAL INVESTMENT ADVISORS LLC',
  },
  {
    cik: '0001577774',
    value: '0001577774,REGAL PARTNERS LTD',
    name: 'REGAL PARTNERS LTD',
    label: 'REGAL PARTNERS LTD',
  },
  {
    cik: '0001656537',
    value: '0001656537,REGAL WEALTH GROUP, INC.',
    name: 'REGAL WEALTH GROUP, INC.',
    label: 'REGAL WEALTH GROUP, INC.',
  },
  {
    cik: '0001911488',
    value: '0001911488,REGATTA CAPITAL GROUP, LLC',
    name: 'REGATTA CAPITAL GROUP, LLC',
    label: 'REGATTA CAPITAL GROUP, LLC',
  },
  {
    cik: '0001863894',
    value: '0001863894,REGENCY CAPITAL MANAGEMENT INC.\\DE',
    name: 'REGENCY CAPITAL MANAGEMENT INC.\\DE',
    label: 'REGENCY CAPITAL MANAGEMENT INC.\\DE',
  },
  {
    cik: '0001217758',
    value: '0001217758,REGENTATLANTIC CAPITAL LLC',
    name: 'REGENTATLANTIC CAPITAL LLC',
    label: 'REGENTATLANTIC CAPITAL LLC',
  },
  {
    cik: '0001559968',
    value: '0001559968,REGENT INVESTMENT MANAGEMENT LLC',
    name: 'REGENT INVESTMENT MANAGEMENT LLC',
    label: 'REGENT INVESTMENT MANAGEMENT LLC',
  },
  {
    cik: '0001803329',
    value: '0001803329,REGENT PEAK WEALTH ADVISORS LLC',
    name: 'REGENT PEAK WEALTH ADVISORS LLC',
    label: 'REGENT PEAK WEALTH ADVISORS LLC',
  },
  {
    cik: '0001862881',
    value: '0001862881,REGENTS CAPITAL LTD',
    name: 'REGENTS CAPITAL LTD',
    label: 'REGENTS CAPITAL LTD',
  },
  {
    cik: '0000315054',
    value: '0000315054,REGENTS OF THE UNIVERSITY OF CALIFORNIA',
    name: 'REGENTS OF THE UNIVERSITY OF CALIFORNIA',
    label: 'REGENTS OF THE UNIVERSITY OF CALIFORNIA',
  },
  {
    cik: '0001344703',
    value: '0001344703,REGIMENT CAPITAL MANAGEMENT LLC',
    name: 'REGIMENT CAPITAL MANAGEMENT LLC',
    label: 'REGIMENT CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001281761',
    value: '0001281761,REGIONS FINANCIAL CORP',
    name: 'REGIONS FINANCIAL CORP',
    label: 'REGIONS FINANCIAL CORP',
  },
  {
    cik: '0001352776',
    value: '0001352776,REGIS MANAGEMENT CO LLC',
    name: 'REGIS MANAGEMENT CO LLC',
    label: 'REGIS MANAGEMENT CO LLC',
  },
  {
    cik: '0001633037',
    value: '0001633037,REHMANN CAPITAL ADVISORY GROUP',
    name: 'REHMANN CAPITAL ADVISORY GROUP',
    label: 'REHMANN CAPITAL ADVISORY GROUP',
  },
  {
    cik: '0001388028',
    value: '0001388028,REIK & CO., LLC',
    name: 'REIK & CO., LLC',
    label: 'REIK & CO., LLC',
  },
  {
    cik: '0001363561',
    value: '0001363561,REILLY FINANCIAL ADVISORS, LLC',
    name: 'REILLY FINANCIAL ADVISORS, LLC',
    label: 'REILLY FINANCIAL ADVISORS, LLC',
  },
  {
    cik: '0001599813',
    value: '0001599813,REILLY HERBERT FAULKNER III',
    name: 'REILLY HERBERT FAULKNER III',
    label: 'REILLY HERBERT FAULKNER III',
  },
  {
    cik: '0001080298',
    value: '0001080298,REINHART PARTNERS, INC.',
    name: 'REINHART PARTNERS, INC.',
    label: 'REINHART PARTNERS, INC.',
  },
  {
    cik: '0001757260',
    value: '0001757260,REITZ CAPITAL ADVISORS LLC',
    name: 'REITZ CAPITAL ADVISORS LLC',
    label: 'REITZ CAPITAL ADVISORS LLC',
  },
  {
    cik: '0001047644',
    value: '0001047644,RELATIONAL INVESTORS LLC',
    name: 'RELATIONAL INVESTORS LLC',
    label: 'RELATIONAL INVESTORS LLC',
  },
  {
    cik: '0001649910',
    value: '0001649910,RELATIVE VALUE PARTNERS GROUP, LLC',
    name: 'RELATIVE VALUE PARTNERS GROUP, LLC',
    label: 'RELATIVE VALUE PARTNERS GROUP, LLC',
  },
  {
    cik: '0001356223',
    value: '0001356223,RELATIVE VALUE PARTNERS, LLC',
    name: 'RELATIVE VALUE PARTNERS, LLC',
    label: 'RELATIVE VALUE PARTNERS, LLC',
  },
  {
    cik: '0001728449',
    value: '0001728449,RELAXING RETIREMENT COACH',
    name: 'RELAXING RETIREMENT COACH',
    label: 'RELAXING RETIREMENT COACH',
  },
  {
    cik: '0001260101',
    value: '0001260101,RELIANCE TRUST CO',
    name: 'RELIANCE TRUST CO',
    label: 'RELIANCE TRUST CO',
  },
  {
    cik: '0001583864',
    value: '0001583864,RELIANCE TRUST CO OF DELAWARE',
    name: 'RELIANCE TRUST CO OF DELAWARE',
    label: 'RELIANCE TRUST CO OF DELAWARE',
  },
  {
    cik: '0001517429',
    value: '0001517429,RELIANT INVESTMENT MANAGEMENT, LLC',
    name: 'RELIANT INVESTMENT MANAGEMENT, LLC',
    label: 'RELIANT INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001823393',
    value: '0001823393,RELIANT WEALTH PLANNING',
    name: 'RELIANT WEALTH PLANNING',
    label: 'RELIANT WEALTH PLANNING',
  },
  {
    cik: '0001793691',
    value: '0001793691,RELYEA ZUCKERBERG HANSON LLC',
    name: 'RELYEA ZUCKERBERG HANSON LLC',
    label: 'RELYEA ZUCKERBERG HANSON LLC',
  },
  {
    cik: '0001844571',
    value: '0001844571,REMPART ASSET MANAGEMENT INC.',
    name: 'REMPART ASSET MANAGEMENT INC.',
    label: 'REMPART ASSET MANAGEMENT INC.',
  },
  {
    cik: '0001535058',
    value: '0001535058,RENAISSANCE CAPITAL LLC',
    name: 'RENAISSANCE CAPITAL LLC',
    label: 'RENAISSANCE CAPITAL LLC',
  },
  {
    cik: '0000944234',
    value: '0000944234,RENAISSANCE GROUP LLC',
    name: 'RENAISSANCE GROUP LLC',
    label: 'RENAISSANCE GROUP LLC',
  },
  {
    cik: '0001802297',
    value: '0001802297,RENAISSANCE INVESTMENT CONSULTANTS HOLDING COMPANY, LLC',
    name: 'RENAISSANCE INVESTMENT CONSULTANTS HOLDING COMPANY, LLC',
    label: 'RENAISSANCE INVESTMENT CONSULTANTS HOLDING COMPANY, LLC',
  },
  {
    cik: '0001509508',
    value: '0001509508,RENAISSANCE INVESTMENT GROUP LLC',
    name: 'RENAISSANCE INVESTMENT GROUP LLC',
    label: 'RENAISSANCE INVESTMENT GROUP LLC',
  },
  {
    cik: '0001037389',
    value: '0001037389,RENAISSANCE TECHNOLOGIES LLC',
    name: 'RENAISSANCE TECHNOLOGIES LLC',
    label: 'RENAISSANCE TECHNOLOGIES LLC',
  },
  {
    cik: '0001656167',
    value: '0001656167,RENASANT BANK',
    name: 'RENASANT BANK',
    label: 'RENASANT BANK',
  },
  {
    cik: '0001599016',
    value: '0001599016,RENCH WEALTH MANAGEMENT, INC.',
    name: 'RENCH WEALTH MANAGEMENT, INC.',
    label: 'RENCH WEALTH MANAGEMENT, INC.',
  },
  {
    cik: '0001909393',
    value: '0001909393,REPERTOIRE PARTNERS LP',
    name: 'REPERTOIRE PARTNERS LP',
    label: 'REPERTOIRE PARTNERS LP',
  },
  {
    cik: '0001730573',
    value: '0001730573,REQUISITE CAPITAL MANAGEMENT, LLC',
    name: 'REQUISITE CAPITAL MANAGEMENT, LLC',
    label: 'REQUISITE CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001728398',
    value: '0001728398,REQUISITE ENERGY FUND I LP',
    name: 'REQUISITE ENERGY FUND I LP',
    label: 'REQUISITE ENERGY FUND I LP',
  },
  {
    cik: '0001337848',
    value: '0001337848,RESEARCH AFFILIATES, LLC',
    name: 'RESEARCH AFFILIATES, LLC',
    label: 'RESEARCH AFFILIATES, LLC',
  },
  {
    cik: '0001536078',
    value: '0001536078,RESERVOIR OPERATIONS, L.P.',
    name: 'RESERVOIR OPERATIONS, L.P.',
    label: 'RESERVOIR OPERATIONS, L.P.',
  },
  {
    cik: '0001532421',
    value: '0001532421,RESILIENT CAPITAL LLP',
    name: 'RESILIENT CAPITAL LLP',
    label: 'RESILIENT CAPITAL LLP',
  },
  {
    cik: '0001800217',
    value: '0001800217,RESOLUTE ADVISORS LLC',
    name: 'RESOLUTE ADVISORS LLC',
    label: 'RESOLUTE ADVISORS LLC',
  },
  {
    cik: '0001834913',
    value: '0001834913,RESOLUTE CAPITAL ASSET PARTNERS LLC',
    name: 'RESOLUTE CAPITAL ASSET PARTNERS LLC',
    label: 'RESOLUTE CAPITAL ASSET PARTNERS LLC',
  },
  {
    cik: '0001908859',
    value: '0001908859,RESOLUTE FINANCIAL, LLC',
    name: 'RESOLUTE FINANCIAL, LLC',
    label: 'RESOLUTE FINANCIAL, LLC',
  },
  {
    cik: '0001612389',
    value: '0001612389,RESOLUTION CAPITAL LTD',
    name: 'RESOLUTION CAPITAL LTD',
    label: 'RESOLUTION CAPITAL LTD',
  },
  {
    cik: '0001803236',
    value: '0001803236,RESONANT CAPITAL ADVISORS, LLC',
    name: 'RESONANT CAPITAL ADVISORS, LLC',
    label: 'RESONANT CAPITAL ADVISORS, LLC',
  },
  {
    cik: '0001649994',
    value: '0001649994,RESONATE CAPITAL, LLC',
    name: 'RESONATE CAPITAL, LLC',
    label: 'RESONATE CAPITAL, LLC',
  },
  {
    cik: '0000083402',
    value: '0000083402,RESOURCE AMERICA, INC.',
    name: 'RESOURCE AMERICA, INC.',
    label: 'RESOURCE AMERICA, INC.',
  },
  {
    cik: '0000831941',
    value: '0000831941,RESOURCE CONSULTING GROUP INC',
    name: 'RESOURCE CONSULTING GROUP INC',
    label: 'RESOURCE CONSULTING GROUP INC',
  },
  {
    cik: '0001731437',
    value: '0001731437,RESOURCE MANAGEMENT, LLC',
    name: 'RESOURCE MANAGEMENT, LLC',
    label: 'RESOURCE MANAGEMENT, LLC',
  },
  {
    cik: '0001537147',
    value: '0001537147,RESOURCE PLANNING GROUP',
    name: 'RESOURCE PLANNING GROUP',
    label: 'RESOURCE PLANNING GROUP',
  },
  {
    cik: '0001690370',
    value: '0001690370,RESOURCES INVESTMENT ADVISORS, INC.',
    name: 'RESOURCES INVESTMENT ADVISORS, INC.',
    label: 'RESOURCES INVESTMENT ADVISORS, INC.',
  },
  {
    cik: '0001690370',
    value: '0001690370,RESOURCES INVESTMENT ADVISORS, LLC.',
    name: 'RESOURCES INVESTMENT ADVISORS, LLC.',
    label: 'RESOURCES INVESTMENT ADVISORS, LLC.',
  },
  {
    cik: '0000749044',
    value: '0000749044,RESOURCES MANAGEMENT CORP /CT/ /ADV',
    name: 'RESOURCES MANAGEMENT CORP /CT/ /ADV',
    label: 'RESOURCES MANAGEMENT CORP /CT/ /ADV',
  },
  {
    cik: '0001767617',
    value: '0001767617,RESTON WEALTH MANAGEMENT LLC',
    name: 'RESTON WEALTH MANAGEMENT LLC',
    label: 'RESTON WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0001012091',
    value: '0001012091,RESTRUCTURING CAPITAL ASSOCIATES LP',
    name: 'RESTRUCTURING CAPITAL ASSOCIATES LP',
    label: 'RESTRUCTURING CAPITAL ASSOCIATES LP',
  },
  {
    cik: '0001912339',
    value: '0001912339,RESURGENT FINANCIAL ADVISORS LLC',
    name: 'RESURGENT FINANCIAL ADVISORS LLC',
    label: 'RESURGENT FINANCIAL ADVISORS LLC',
  },
  {
    cik: '0001919176',
    value: '0001919176,RETIREFUL, LLC',
    name: 'RETIREFUL, LLC',
    label: 'RETIREFUL, LLC',
  },
  {
    cik: '0001672681',
    value: '0001672681,RETIREMENT CAPITAL STRATEGIES',
    name: 'RETIREMENT CAPITAL STRATEGIES',
    label: 'RETIREMENT CAPITAL STRATEGIES',
  },
  {
    cik: '0000927952',
    value: '0000927952,RETIREMENT DESIGN & MANAGEMENT INC /ADV',
    name: 'RETIREMENT DESIGN & MANAGEMENT INC /ADV',
    label: 'RETIREMENT DESIGN & MANAGEMENT INC /ADV',
  },
  {
    cik: '0001962532',
    value: '0001962532,RETIREMENT FINANCIAL SOLUTIONS, LLC',
    name: 'RETIREMENT FINANCIAL SOLUTIONS, LLC',
    label: 'RETIREMENT FINANCIAL SOLUTIONS, LLC',
  },
  {
    cik: '0001740491',
    value: '0001740491,RETIREMENT GROUP, LLC',
    name: 'RETIREMENT GROUP, LLC',
    label: 'RETIREMENT GROUP, LLC',
  },
  {
    cik: '0001933059',
    value: '0001933059,RETIREMENT GUYS FORMULA LLC',
    name: 'RETIREMENT GUYS FORMULA LLC',
    label: 'RETIREMENT GUYS FORMULA LLC',
  },
  {
    cik: '0001730386',
    value: '0001730386,RETIREMENT INCOME SOLUTIONS, INC',
    name: 'RETIREMENT INCOME SOLUTIONS, INC',
    label: 'RETIREMENT INCOME SOLUTIONS, INC',
  },
  {
    cik: '0001777817',
    value: '0001777817,RETIREMENT NETWORK',
    name: 'RETIREMENT NETWORK',
    label: 'RETIREMENT NETWORK',
  },
  {
    cik: '0001817693',
    value: '0001817693,RETIREMENT PLANNING CO OF NEW ENGLAND, INC.',
    name: 'RETIREMENT PLANNING CO OF NEW ENGLAND, INC.',
    label: 'RETIREMENT PLANNING CO OF NEW ENGLAND, INC.',
  },
  {
    cik: '0001508195',
    value: '0001508195,RETIREMENT PLANNING GROUP',
    name: 'RETIREMENT PLANNING GROUP',
    label: 'RETIREMENT PLANNING GROUP',
  },
  {
    cik: '0001914606',
    value: '0001914606,RETIREMENT SOLUTION INC.',
    name: 'RETIREMENT SOLUTION INC.',
    label: 'RETIREMENT SOLUTION INC.',
  },
  {
    cik: '0001593051',
    value: '0001593051,RETIREMENT SYSTEMS OF ALABAMA',
    name: 'RETIREMENT SYSTEMS OF ALABAMA',
    label: 'RETIREMENT SYSTEMS OF ALABAMA',
  },
  {
    cik: '0001894484',
    value: '0001894484,REUTER JAMES WEALTH MANAGEMENT, LLC',
    name: 'REUTER JAMES WEALTH MANAGEMENT, LLC',
    label: 'REUTER JAMES WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001801811',
    value: '0001801811,REVELATION CAPITAL MANAGEMENT, LLC',
    name: 'REVELATION CAPITAL MANAGEMENT, LLC',
    label: 'REVELATION CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001512971',
    value: '0001512971,REVELATION CAPITAL MANAGEMENT LTD.',
    name: 'REVELATION CAPITAL MANAGEMENT LTD.',
    label: 'REVELATION CAPITAL MANAGEMENT LTD.',
  },
  {
    cik: '0001907530',
    value: '0001907530,REVERENCE CAPITAL PARTNERS, L.P.',
    name: 'REVERENCE CAPITAL PARTNERS, L.P.',
    label: 'REVERENCE CAPITAL PARTNERS, L.P.',
  },
  {
    cik: '0001816374',
    value: '0001816374,REVOLUTION GROWTH MANAGEMENT COMPANY, INC.',
    name: 'REVOLUTION GROWTH MANAGEMENT COMPANY, INC.',
    label: 'REVOLUTION GROWTH MANAGEMENT COMPANY, INC.',
  },
  {
    cik: '0001808992',
    value: '0001808992,REVOLVE WEALTH PARTNERS, LLC',
    name: 'REVOLVE WEALTH PARTNERS, LLC',
    label: 'REVOLVE WEALTH PARTNERS, LLC',
  },
  {
    cik: '0001389307',
    value: '0001389307,REX CAPITAL ADVISORS, LLC',
    name: 'REX CAPITAL ADVISORS, LLC',
    label: 'REX CAPITAL ADVISORS, LLC',
  },
  {
    cik: '0001455969',
    value: '0001455969,REYNDERS MCVEIGH CAPITAL MANAGEMENT, LLC',
    name: 'REYNDERS MCVEIGH CAPITAL MANAGEMENT, LLC',
    label: 'REYNDERS MCVEIGH CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001286313',
    value: '0001286313,REYNOLDS CAPITAL MANAGEMENT LLC',
    name: 'REYNOLDS CAPITAL MANAGEMENT LLC',
    label: 'REYNOLDS CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001930621',
    value: '0001930621,REYNOLDS INVESTMENT MANAGEMENT, INC.',
    name: 'REYNOLDS INVESTMENT MANAGEMENT, INC.',
    label: 'REYNOLDS INVESTMENT MANAGEMENT, INC.',
  },
  {
    cik: '0001535859',
    value: '0001535859,REZNY WEALTH MANAGEMENT, INC.',
    name: 'REZNY WEALTH MANAGEMENT, INC.',
    label: 'REZNY WEALTH MANAGEMENT, INC.',
  },
  {
    cik: '0001655670',
    value: '0001655670,R&F CAPITAL ADVISORS LP',
    name: 'R&F CAPITAL ADVISORS LP',
    label: 'R&F CAPITAL ADVISORS LP',
  },
  {
    cik: '0001633387',
    value: '0001633387,RFG ADVISORY GROUP, LLC',
    name: 'RFG ADVISORY GROUP, LLC',
    label: 'RFG ADVISORY GROUP, LLC',
  },
  {
    cik: '0001633387',
    value: '0001633387,RFG ADVISORY, LLC',
    name: 'RFG ADVISORY, LLC',
    label: 'RFG ADVISORY, LLC',
  },
  {
    cik: '0001845210',
    value: '0001845210,RFG HOLDINGS, INC.',
    name: 'RFG HOLDINGS, INC.',
    label: 'RFG HOLDINGS, INC.',
  },
  {
    cik: '0001956564',
    value: '0001956564,RFP FINANCIAL GROUP LLC',
    name: 'RFP FINANCIAL GROUP LLC',
    label: 'RFP FINANCIAL GROUP LLC',
  },
  {
    cik: '0001323953',
    value: '0001323953,RGM CAPITAL, LLC',
    name: 'RGM CAPITAL, LLC',
    label: 'RGM CAPITAL, LLC',
  },
  {
    cik: '0001216800',
    value: '0001216800,R. G. NIEDERHOFFER CAPITAL MANAGEMENT INC',
    name: 'R. G. NIEDERHOFFER CAPITAL MANAGEMENT INC',
    label: 'R. G. NIEDERHOFFER CAPITAL MANAGEMENT INC',
  },
  {
    cik: '0001689872',
    value: '0001689872,RGT CAPITAL MANAGEMENT, LTD.',
    name: 'RGT CAPITAL MANAGEMENT, LTD.',
    label: 'RGT CAPITAL MANAGEMENT, LTD.',
  },
  {
    cik: '0001689872',
    value: '0001689872,RGT WEALTH ADVISORS, LLC',
    name: 'RGT WEALTH ADVISORS, LLC',
    label: 'RGT WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001598352',
    value: '0001598352,R.H. DINEL INVESTMENT COUNSEL, INC.',
    name: 'R.H. DINEL INVESTMENT COUNSEL, INC.',
    label: 'R.H. DINEL INVESTMENT COUNSEL, INC.',
  },
  {
    cik: '0001599882',
    value: '0001599882,RHENMAN & PARTNERS ASSET MANAGEMENT AB',
    name: 'RHENMAN & PARTNERS ASSET MANAGEMENT AB',
    label: 'RHENMAN & PARTNERS ASSET MANAGEMENT AB',
  },
  {
    cik: '0001735445',
    value: '0001735445,RHEOS CAPITAL WORKS INC.',
    name: 'RHEOS CAPITAL WORKS INC.',
    label: 'RHEOS CAPITAL WORKS INC.',
  },
  {
    cik: '0001828776',
    value: '0001828776,RHINO INVESTMENT PARTNERS, INC',
    name: 'RHINO INVESTMENT PARTNERS, INC',
    label: 'RHINO INVESTMENT PARTNERS, INC',
  },
  {
    cik: '0001513271',
    value: '0001513271,RHJ INTERNATIONAL SA',
    name: 'RHJ INTERNATIONAL SA',
    label: 'RHJ INTERNATIONAL SA',
  },
  {
    cik: '0001020455',
    value: '0001020455,RHO CAPITAL PARTNERS INC',
    name: 'RHO CAPITAL PARTNERS INC',
    label: 'RHO CAPITAL PARTNERS INC',
  },
  {
    cik: '0001766514',
    value: '0001766514,RHS FINANCIAL, LLC',
    name: 'RHS FINANCIAL, LLC',
    label: 'RHS FINANCIAL, LLC',
  },
  {
    cik: '0001115418',
    value: '0001115418,RHUMBLINE ADVISERS',
    name: 'RHUMBLINE ADVISERS',
    label: 'RHUMBLINE ADVISERS',
  },
  {
    cik: '0001912617',
    value: '0001912617,RIBBIT CAPITAL GP III, LTD.',
    name: 'RIBBIT CAPITAL GP III, LTD.',
    label: 'RIBBIT CAPITAL GP III, LTD.',
  },
  {
    cik: '0001912628',
    value: '0001912628,RIBBIT CAPITAL GP II, LTD.',
    name: 'RIBBIT CAPITAL GP II, LTD.',
    label: 'RIBBIT CAPITAL GP II, LTD.',
  },
  {
    cik: '0001697452',
    value: '0001697452,RIBBIT CAPITAL GP IV, LTD.',
    name: 'RIBBIT CAPITAL GP IV, LTD.',
    label: 'RIBBIT CAPITAL GP IV, LTD.',
  },
  {
    cik: '0001697450',
    value: '0001697450,RIBBIT CAPITAL IV, L.P.',
    name: 'RIBBIT CAPITAL IV, L.P.',
    label: 'RIBBIT CAPITAL IV, L.P.',
  },
  {
    cik: '0001230765',
    value: '0001230765,RICE HALL JAMES & ASSOCIATES, LLC',
    name: 'RICE HALL JAMES & ASSOCIATES, LLC',
    label: 'RICE HALL JAMES & ASSOCIATES, LLC',
  },
  {
    cik: '0001736736',
    value: '0001736736,RICE PARTNERSHIP, LLC',
    name: 'RICE PARTNERSHIP, LLC',
    label: 'RICE PARTNERSHIP, LLC',
  },
  {
    cik: '0001528214',
    value: '0001528214,RICHARD BERNSTEIN ADVISORS LLC',
    name: 'RICHARD BERNSTEIN ADVISORS LLC',
    label: 'RICHARD BERNSTEIN ADVISORS LLC',
  },
  {
    cik: '0001387304',
    value: '0001387304,RICHARD C. YOUNG & CO., LTD.',
    name: 'RICHARD C. YOUNG & CO., LTD.',
    label: 'RICHARD C. YOUNG & CO., LTD.',
  },
  {
    cik: '0001811783',
    value: '0001811783,RICHARD P SLAUGHTER ASSOCIATES INC',
    name: 'RICHARD P SLAUGHTER ASSOCIATES INC',
    label: 'RICHARD P SLAUGHTER ASSOCIATES INC',
  },
  {
    cik: '0001916690',
    value: '0001916690,RICHELIEU GESTION PLC',
    name: 'RICHELIEU GESTION PLC',
    label: 'RICHELIEU GESTION PLC',
  },
  {
    cik: '0001540462',
    value: '0001540462,RICHMOND BROTHERS, INC.',
    name: 'RICHMOND BROTHERS, INC.',
    label: 'RICHMOND BROTHERS, INC.',
  },
  {
    cik: '0001501577',
    value: '0001501577,RICHMOND HILL INVESTMENT CO., LP',
    name: 'RICHMOND HILL INVESTMENT CO., LP',
    label: 'RICHMOND HILL INVESTMENT CO., LP',
  },
  {
    cik: '0001455952',
    value: '0001455952,RICHMOND HILL INVESTMENTS, LLC',
    name: 'RICHMOND HILL INVESTMENTS, LLC',
    label: 'RICHMOND HILL INVESTMENTS, LLC',
  },
  {
    cik: '0001911900',
    value: '0001911900,RICHWOOD INVESTMENT ADVISORS, LLC',
    name: 'RICHWOOD INVESTMENT ADVISORS, LLC',
    label: 'RICHWOOD INVESTMENT ADVISORS, LLC',
  },
  {
    cik: '0001423994',
    value: '0001423994,RIDGEBACK CAPITAL INVESTMENTS L.P.',
    name: 'RIDGEBACK CAPITAL INVESTMENTS L.P.',
    label: 'RIDGEBACK CAPITAL INVESTMENTS L.P.',
  },
  {
    cik: '0001860719',
    value: '0001860719,RIDGECREST WEALTH PARTNERS, LLC',
    name: 'RIDGECREST WEALTH PARTNERS, LLC',
    label: 'RIDGECREST WEALTH PARTNERS, LLC',
  },
  {
    cik: '0001634140',
    value: '0001634140,RIDGEGATE PROPRIETARY LTD',
    name: 'RIDGEGATE PROPRIETARY LTD',
    label: 'RIDGEGATE PROPRIETARY LTD',
  },
  {
    cik: '0001594467',
    value: '0001594467,RIDGEMONT PARTNERS MANAGEMENT, LLC',
    name: 'RIDGEMONT PARTNERS MANAGEMENT, LLC',
    label: 'RIDGEMONT PARTNERS MANAGEMENT, LLC',
  },
  {
    cik: '0001703556',
    value: '0001703556,RIDGEWOOD INVESTMENTS LLC',
    name: 'RIDGEWOOD INVESTMENTS LLC',
    label: 'RIDGEWOOD INVESTMENTS LLC',
  },
  {
    cik: '0001319111',
    value: '0001319111,RIDGEWORTH CAPITAL MANAGEMENT LLC',
    name: 'RIDGEWORTH CAPITAL MANAGEMENT LLC',
    label: 'RIDGEWORTH CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001601348',
    value: '0001601348,RIGGS ASSET MANAGMENT CO. INC.',
    name: 'RIGGS ASSET MANAGMENT CO. INC.',
    label: 'RIGGS ASSET MANAGMENT CO. INC.',
  },
  {
    cik: '0001576654',
    value: '0001576654,RIKOON GROUP, LLC',
    name: 'RIKOON GROUP, LLC',
    label: 'RIKOON GROUP, LLC',
  },
  {
    cik: '0001328785',
    value: '0001328785,RIMA SENVEST MANAGEMENT, L.L.C.',
    name: 'RIMA SENVEST MANAGEMENT, L.L.C.',
    label: 'RIMA SENVEST MANAGEMENT, L.L.C.',
  },
  {
    cik: '0001766625',
    value: '0001766625,RIMROCK CAPITAL MANAGEMENT, LLC',
    name: 'RIMROCK CAPITAL MANAGEMENT, LLC',
    label: 'RIMROCK CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001642351',
    value: '0001642351,RINET CO LLC',
    name: 'RINET CO LLC',
    label: 'RINET CO LLC',
  },
  {
    cik: '0001771122',
    value: '0001771122,RINGS CAPITAL MANAGEMENT LLC',
    name: 'RINGS CAPITAL MANAGEMENT LLC',
    label: 'RINGS CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001730774',
    value: '0001730774,RINKEY INVESTMENTS',
    name: 'RINKEY INVESTMENTS',
    label: 'RINKEY INVESTMENTS',
  },
  {
    cik: '0001802893',
    value: '0001802893,RIP ROAD CAPITAL PARTNERS LP',
    name: 'RIP ROAD CAPITAL PARTNERS LP',
    label: 'RIP ROAD CAPITAL PARTNERS LP',
  },
  {
    cik: '0001831316',
    value: '0001831316,RISE ADVISORS, LLC',
    name: 'RISE ADVISORS, LLC',
    label: 'RISE ADVISORS, LLC',
  },
  {
    cik: '0001569886',
    value: '0001569886,RISK PARADIGM GROUP, LLC',
    name: 'RISK PARADIGM GROUP, LLC',
    label: 'RISK PARADIGM GROUP, LLC',
  },
  {
    cik: '0001392910',
    value: '0001392910,RISKX INVESTMENTS, LLC',
    name: 'RISKX INVESTMENTS, LLC',
    label: 'RISKX INVESTMENTS, LLC',
  },
  {
    cik: '0000939334',
    value: '0000939334,RIT CAPITAL PARTNERS PLC',
    name: 'RIT CAPITAL PARTNERS PLC',
    label: 'RIT CAPITAL PARTNERS PLC',
  },
  {
    cik: '0001698218',
    value: '0001698218,RITHOLTZ WEALTH MANAGEMENT',
    name: 'RITHOLTZ WEALTH MANAGEMENT',
    label: 'RITHOLTZ WEALTH MANAGEMENT',
  },
  {
    cik: '0001844428',
    value: '0001844428,RITTER DANIHER FINANCIAL ADVISORY LLC',
    name: 'RITTER DANIHER FINANCIAL ADVISORY LLC',
    label: 'RITTER DANIHER FINANCIAL ADVISORY LLC',
  },
  {
    cik: '0001844428',
    value: '0001844428,RITTER DANIHER FINANCIAL ADVISORY, LLC',
    name: 'RITTER DANIHER FINANCIAL ADVISORY, LLC',
    label: 'RITTER DANIHER FINANCIAL ADVISORY, LLC',
  },
  {
    cik: '0001936603',
    value: '0001936603,RITTER DANIHER FINANCIAL ADVISORY LLC / DE',
    name: 'RITTER DANIHER FINANCIAL ADVISORY LLC / DE',
    label: 'RITTER DANIHER FINANCIAL ADVISORY LLC / DE',
  },
  {
    cik: '0001112325',
    value: '0001112325,RIVERBRIDGE PARTNERS LLC',
    name: 'RIVERBRIDGE PARTNERS LLC',
    label: 'RIVERBRIDGE PARTNERS LLC',
  },
  {
    cik: '0001469751',
    value: '0001469751,RIVERFRONT INVESTMENT GROUP, LLC',
    name: 'RIVERFRONT INVESTMENT GROUP, LLC',
    label: 'RIVERFRONT INVESTMENT GROUP, LLC',
  },
  {
    cik: '0001619779',
    value: '0001619779,RIVERGLADES FAMILY OFFICES LLC',
    name: 'RIVERGLADES FAMILY OFFICES LLC',
    label: 'RIVERGLADES FAMILY OFFICES LLC',
  },
  {
    cik: '0001568679',
    value: '0001568679,RIVERHEAD CAPITAL MANAGEMENT LLC',
    name: 'RIVERHEAD CAPITAL MANAGEMENT LLC',
    label: 'RIVERHEAD CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001534408',
    value: '0001534408,RIVERLOFT CAPITAL MANAGEMENT L.P.',
    name: 'RIVERLOFT CAPITAL MANAGEMENT L.P.',
    label: 'RIVERLOFT CAPITAL MANAGEMENT L.P.',
  },
  {
    cik: '0001486180',
    value: '0001486180,RIVER & MERCANTILE ASSET MANAGEMENT LLP',
    name: 'RIVER & MERCANTILE ASSET MANAGEMENT LLP',
    label: 'RIVER & MERCANTILE ASSET MANAGEMENT LLP',
  },
  {
    cik: '0001706247',
    value: '0001706247,RIVER & MERCANTILE INVESTMENTS LTD',
    name: 'RIVER & MERCANTILE INVESTMENTS LTD',
    label: 'RIVER & MERCANTILE INVESTMENTS LTD',
  },
  {
    cik: '0001633903',
    value: '0001633903,RIVER & MERCANTILE LLC',
    name: 'RIVER & MERCANTILE LLC',
    label: 'RIVER & MERCANTILE LLC',
  },
  {
    cik: '0001447578',
    value: '0001447578,RIVERNORTH CAPITAL MANAGEMENT, LLC',
    name: 'RIVERNORTH CAPITAL MANAGEMENT, LLC',
    label: 'RIVERNORTH CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001343119',
    value: '0001343119,RIVER OAKS CAPITAL LLC',
    name: 'RIVER OAKS CAPITAL LLC',
    label: 'RIVER OAKS CAPITAL LLC',
  },
  {
    cik: '0001484256',
    value: '0001484256,RIVERPARK ADVISORS, LLC',
    name: 'RIVERPARK ADVISORS, LLC',
    label: 'RIVERPARK ADVISORS, LLC',
  },
  {
    cik: '0001614704',
    value: '0001614704,RIVERPARK CAPITAL MANAGEMENT LLC',
    name: 'RIVERPARK CAPITAL MANAGEMENT LLC',
    label: 'RIVERPARK CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001567390',
    value: '0001567390,RIVERPOINT CAPITAL MANAGEMENT, LLC',
    name: 'RIVERPOINT CAPITAL MANAGEMENT, LLC',
    label: 'RIVERPOINT CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001682733',
    value: '0001682733,RIVERPOINT WEALTH MANAGEMENT HOLDINGS, LLC',
    name: 'RIVERPOINT WEALTH MANAGEMENT HOLDINGS, LLC',
    label: 'RIVERPOINT WEALTH MANAGEMENT HOLDINGS, LLC',
  },
  {
    cik: '0001341401',
    value: '0001341401,RIVER ROAD ASSET MANAGEMENT, LLC',
    name: 'RIVER ROAD ASSET MANAGEMENT, LLC',
    label: 'RIVER ROAD ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001787663',
    value: '0001787663,RIVERSEDGE ADVISORS, LLC',
    name: 'RIVERSEDGE ADVISORS, LLC',
    label: 'RIVERSEDGE ADVISORS, LLC',
  },
  {
    cik: '0001763404',
    value: '0001763404,RIVERSTONE ADVISORS, LLC',
    name: 'RIVERSTONE ADVISORS, LLC',
    label: 'RIVERSTONE ADVISORS, LLC',
  },
  {
    cik: '0001132236',
    value: '0001132236,RIVERSTONE GROUP, LLC',
    name: 'RIVERSTONE GROUP, LLC',
    label: 'RIVERSTONE GROUP, LLC',
  },
  {
    cik: '0001247497',
    value: '0001247497,RIVERSTONE HOLDINGS LLC',
    name: 'RIVERSTONE HOLDINGS LLC',
    label: 'RIVERSTONE HOLDINGS LLC',
  },
  {
    cik: '0001512346',
    value: '0001512346,RIVERSVILLE CAPITAL, INC.',
    name: 'RIVERSVILLE CAPITAL, INC.',
    label: 'RIVERSVILLE CAPITAL, INC.',
  },
  {
    cik: '0001872501',
    value: '0001872501,RIVERTREE ADVISORS, LLC',
    name: 'RIVERTREE ADVISORS, LLC',
    label: 'RIVERTREE ADVISORS, LLC',
  },
  {
    cik: '0001812549',
    value: '0001812549,RIVERVEST VENTURE MANAGEMENT LLC',
    name: 'RIVERVEST VENTURE MANAGEMENT LLC',
    label: 'RIVERVEST VENTURE MANAGEMENT LLC',
  },
  {
    cik: '0001593600',
    value: '0001593600,RIVERVIEW TRUST CO',
    name: 'RIVERVIEW TRUST CO',
    label: 'RIVERVIEW TRUST CO',
  },
  {
    cik: '0001882572',
    value: '0001882572,RIVERWATER PARTNERS LLC',
    name: 'RIVERWATER PARTNERS LLC',
    label: 'RIVERWATER PARTNERS LLC',
  },
  {
    cik: '0001642570',
    value: '0001642570,RIVER WEALTH ADVISORS LLC',
    name: 'RIVER WEALTH ADVISORS LLC',
    label: 'RIVER WEALTH ADVISORS LLC',
  },
  {
    cik: '0001550599',
    value: '0001550599,RIVERWOOD CAPITAL MANAGEMENT LTD.',
    name: 'RIVERWOOD CAPITAL MANAGEMENT LTD.',
    label: 'RIVERWOOD CAPITAL MANAGEMENT LTD.',
  },
  {
    cik: '0001566691',
    value: '0001566691,RIVULET CAPITAL, LLC',
    name: 'RIVULET CAPITAL, LLC',
    label: 'RIVULET CAPITAL, LLC',
  },
  {
    cik: '0001569633',
    value: '0001569633,RIZVI TRAVERSE MANAGEMENT, LLC',
    name: 'RIZVI TRAVERSE MANAGEMENT, LLC',
    label: 'RIZVI TRAVERSE MANAGEMENT, LLC',
  },
  {
    cik: '0001671817',
    value: '0001671817,RJA ASSET MANAGEMENT LLC',
    name: 'RJA ASSET MANAGEMENT LLC',
    label: 'RJA ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001595686',
    value: '0001595686,RK ASSET MANAGEMENT, LLC',
    name: 'RK ASSET MANAGEMENT, LLC',
    label: 'RK ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001426266',
    value: '0001426266,RK CAPITAL MANAGEMENT, LLC',
    name: 'RK CAPITAL MANAGEMENT, LLC',
    label: 'RK CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001604723',
    value: '0001604723,RKL WEALTH MANAGEMENT LLC',
    name: 'RKL WEALTH MANAGEMENT LLC',
    label: 'RKL WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0001332905',
    value: '0001332905,RMB CAPITAL MANAGEMENT, LLC',
    name: 'RMB CAPITAL MANAGEMENT, LLC',
    label: 'RMB CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001177132',
    value: '0001177132,RMR ADVISORS LLC',
    name: 'RMR ADVISORS LLC',
    label: 'RMR ADVISORS LLC',
  },
  {
    cik: '0001803848',
    value: '0001803848,RMR WEALTH BUILDERS',
    name: 'RMR WEALTH BUILDERS',
    label: 'RMR WEALTH BUILDERS',
  },
  {
    cik: '0001426774',
    value: '0001426774,R.M.SINCERBEAUX CAPITAL MANAGEMENT LLC',
    name: 'R.M.SINCERBEAUX CAPITAL MANAGEMENT LLC',
    label: 'R.M.SINCERBEAUX CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0000051762',
    value: '0000051762,RNC CAPITAL MANAGEMENT LLC',
    name: 'RNC CAPITAL MANAGEMENT LLC',
    label: 'RNC CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001744238',
    value: '0001744238,ROAM GLOBAL MANAGEMENT LP',
    name: 'ROAM GLOBAL MANAGEMENT LP',
    label: 'ROAM GLOBAL MANAGEMENT LP',
  },
  {
    cik: '0001089710',
    value: '0001089710,ROANOKE ASSET MANAGEMENT CORP/ NY',
    name: 'ROANOKE ASSET MANAGEMENT CORP/ NY',
    label: 'ROANOKE ASSET MANAGEMENT CORP/ NY',
  },
  {
    cik: '0001569786',
    value: '0001569786,ROARING BLUE LION CAPITAL MANAGEMENT, L.P.',
    name: 'ROARING BLUE LION CAPITAL MANAGEMENT, L.P.',
    label: 'ROARING BLUE LION CAPITAL MANAGEMENT, L.P.',
  },
  {
    cik: '0001847820',
    value: '0001847820,ROBBINS FARLEY',
    name: 'ROBBINS FARLEY',
    label: 'ROBBINS FARLEY',
  },
  {
    cik: '0001847820',
    value: '0001847820,ROBBINS FARLEY LLC',
    name: 'ROBBINS FARLEY LLC',
    label: 'ROBBINS FARLEY LLC',
  },
  {
    cik: '0001418773',
    value: '0001418773,ROBECO INSTITUTIONAL ASSET MANAGEMENT B.V.',
    name: 'ROBECO INSTITUTIONAL ASSET MANAGEMENT B.V.',
    label: 'ROBECO INSTITUTIONAL ASSET MANAGEMENT B.V.',
  },
  {
    cik: '0001455838',
    value: '0001455838,ROBECOSAM USA, INC.',
    name: 'ROBECOSAM USA, INC.',
    label: 'ROBECOSAM USA, INC.',
  },
  {
    cik: '0001418427',
    value: '0001418427,ROBECO SCHWEIZ AG',
    name: 'ROBECO SCHWEIZ AG',
    label: 'ROBECO SCHWEIZ AG',
  },
  {
    cik: '0001883719',
    value: '0001883719,ROBERTS CAPITAL ADVISORS, LLC',
    name: 'ROBERTS CAPITAL ADVISORS, LLC',
    label: 'ROBERTS CAPITAL ADVISORS, LLC',
  },
  {
    cik: '0001017115',
    value: '0001017115,ROBERTS GLORE & CO INC /IL/',
    name: 'ROBERTS GLORE & CO INC /IL/',
    label: 'ROBERTS GLORE & CO INC /IL/',
  },
  {
    cik: '0001352122',
    value: '0001352122,ROBERTSON OPPORTUNITY CAPITAL, LLC',
    name: 'ROBERTSON OPPORTUNITY CAPITAL, LLC',
    label: 'ROBERTSON OPPORTUNITY CAPITAL, LLC',
  },
  {
    cik: '0001685959',
    value: '0001685959,ROBERTSON STEPHENS ADVISORS LLC',
    name: 'ROBERTSON STEPHENS ADVISORS LLC',
    label: 'ROBERTSON STEPHENS ADVISORS LLC',
  },
  {
    cik: '0001767307',
    value: '0001767307,ROBERTSON STEPHENS WEALTH MANAGEMENT, LLC',
    name: 'ROBERTSON STEPHENS WEALTH MANAGEMENT, LLC',
    label: 'ROBERTSON STEPHENS WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001885319',
    value: '0001885319,ROBERTS WEALTH ADVISORS, LLC',
    name: 'ROBERTS WEALTH ADVISORS, LLC',
    label: 'ROBERTS WEALTH ADVISORS, LLC',
  },
  {
    cik: '0000315157',
    value: '0000315157,ROBERT WOOD JOHNSON FOUNDATION',
    name: 'ROBERT WOOD JOHNSON FOUNDATION',
    label: 'ROBERT WOOD JOHNSON FOUNDATION',
  },
  {
    cik: '0001599795',
    value: '0001599795,ROBINSON CAPITAL MANAGEMENT, LLC',
    name: 'ROBINSON CAPITAL MANAGEMENT, LLC',
    label: 'ROBINSON CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001694283',
    value: '0001694283,ROBINSON VALUE MANAGEMENT, LTD.',
    name: 'ROBINSON VALUE MANAGEMENT, LTD.',
    label: 'ROBINSON VALUE MANAGEMENT, LTD.',
  },
  {
    cik: '0001642305',
    value: '0001642305,ROBLE, BELKO & COMPANY, INC',
    name: 'ROBLE, BELKO & COMPANY, INC',
    label: 'ROBLE, BELKO & COMPANY, INC',
  },
  {
    cik: '0001105838',
    value: '0001105838,ROBOTTI ROBERT',
    name: 'ROBOTTI ROBERT',
    label: 'ROBOTTI ROBERT',
  },
  {
    cik: '0001068840',
    value: '0001068840,ROBSHAW & JULIAN ASSOCIATES INC',
    name: 'ROBSHAW & JULIAN ASSOCIATES INC',
    label: 'ROBSHAW & JULIAN ASSOCIATES INC',
  },
  {
    cik: '0001758154',
    value: '0001758154,ROCATON INVESTMENT ADVISORS, LLC',
    name: 'ROCATON INVESTMENT ADVISORS, LLC',
    label: 'ROCATON INVESTMENT ADVISORS, LLC',
  },
  {
    cik: '0001821984',
    value: '0001821984,ROCKBRIDGE INVESTMENT MANAGEMENT, LCC',
    name: 'ROCKBRIDGE INVESTMENT MANAGEMENT, LCC',
    label: 'ROCKBRIDGE INVESTMENT MANAGEMENT, LCC',
  },
  {
    cik: '0001484067',
    value: '0001484067,ROCK CREEK GROUP, LP',
    name: 'ROCK CREEK GROUP, LP',
    label: 'ROCK CREEK GROUP, LP',
  },
  {
    cik: '0001739439',
    value: '0001739439,ROCKEFELLER CAPITAL MANAGEMENT L.P.',
    name: 'ROCKEFELLER CAPITAL MANAGEMENT L.P.',
    label: 'ROCKEFELLER CAPITAL MANAGEMENT L.P.',
  },
  {
    cik: '0000869878',
    value: '0000869878,ROCKEFELLER FINANCIAL SERVICES INC',
    name: 'ROCKEFELLER FINANCIAL SERVICES INC',
    label: 'ROCKEFELLER FINANCIAL SERVICES INC',
  },
  {
    cik: '0000084616',
    value: '0000084616,ROCKLAND TRUST CO',
    name: 'ROCKLAND TRUST CO',
    label: 'ROCKLAND TRUST CO',
  },
  {
    cik: '0001301540',
    value: '0001301540,ROCK POINT ADVISORS, LLC',
    name: 'ROCK POINT ADVISORS, LLC',
    label: 'ROCK POINT ADVISORS, LLC',
  },
  {
    cik: '0001607231',
    value: '0001607231,ROCKSHELTER CAPITAL MANAGEMENT LLC',
    name: 'ROCKSHELTER CAPITAL MANAGEMENT LLC',
    label: 'ROCKSHELTER CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001595725',
    value: '0001595725,ROCK SPRINGS CAPITAL MANAGEMENT LP',
    name: 'ROCK SPRINGS CAPITAL MANAGEMENT LP',
    label: 'ROCK SPRINGS CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001512779',
    value: '0001512779,ROCKY MOUNTAIN ADVISERS, LLC',
    name: 'ROCKY MOUNTAIN ADVISERS, LLC',
    label: 'ROCKY MOUNTAIN ADVISERS, LLC',
  },
  {
    cik: '0001581594',
    value: '0001581594,ROCKY MOUNTAIN PRIVATE WEALTH MANAGEMENT, L.L.C.',
    name: 'ROCKY MOUNTAIN PRIVATE WEALTH MANAGEMENT, L.L.C.',
    label: 'ROCKY MOUNTAIN PRIVATE WEALTH MANAGEMENT, L.L.C.',
  },
  {
    cik: '0001846515',
    value: '0001846515,RODGERS & ASSOCIATES, LTD',
    name: 'RODGERS & ASSOCIATES, LTD',
    label: 'RODGERS & ASSOCIATES, LTD',
  },
  {
    cik: '0001397290',
    value: '0001397290,RODGERS BROTHERS INC.',
    name: 'RODGERS BROTHERS INC.',
    label: 'RODGERS BROTHERS INC.',
  },
  {
    cik: '0000869367',
    value: '0000869367,ROFFMAN MILLER ASSOCIATES INC /PA/',
    name: 'ROFFMAN MILLER ASSOCIATES INC /PA/',
    label: 'ROFFMAN MILLER ASSOCIATES INC /PA/',
  },
  {
    cik: '0001911328',
    value: '0001911328,ROGER WITTLIN INVESTMENT ADVISORY, LLC',
    name: 'ROGER WITTLIN INVESTMENT ADVISORY, LLC',
    label: 'ROGER WITTLIN INVESTMENT ADVISORY, LLC',
  },
  {
    cik: '0001666335',
    value: '0001666335,ROKOS CAPITAL MANAGEMENT LLP',
    name: 'ROKOS CAPITAL MANAGEMENT LLP',
    label: 'ROKOS CAPITAL MANAGEMENT LLP',
  },
  {
    cik: '0001853019',
    value: '0001853019,ROLLINS FINANCIAL',
    name: 'ROLLINS FINANCIAL',
    label: 'ROLLINS FINANCIAL',
  },
  {
    cik: '0000733444',
    value: '0000733444,ROMAN BUTLER FULLERTON & CO',
    name: 'ROMAN BUTLER FULLERTON & CO',
    label: 'ROMAN BUTLER FULLERTON & CO',
  },
  {
    cik: '0000200648',
    value: '0000200648,ROMANO BROTHERS AND COMPANY',
    name: 'ROMANO BROTHERS AND COMPANY',
    label: 'ROMANO BROTHERS AND COMPANY',
  },
  {
    cik: '0001242972',
    value: '0001242972,RONALD BLUE & CO LLC',
    name: 'RONALD BLUE & CO LLC',
    label: 'RONALD BLUE & CO LLC',
  },
  {
    cik: '0001856022',
    value: '0001856022,RONALD BLUE TRUST, INC.',
    name: 'RONALD BLUE TRUST, INC.',
    label: 'RONALD BLUE TRUST, INC.',
  },
  {
    cik: '0001600569',
    value: '0001600569,RONIT CAPITAL LLP',
    name: 'RONIT CAPITAL LLP',
    label: 'RONIT CAPITAL LLP',
  },
  {
    cik: '0001596055',
    value: '0001596055,RONNA SUE COHEN',
    name: 'RONNA SUE COHEN',
    label: 'RONNA SUE COHEN',
  },
  {
    cik: '0001596212',
    value: '0001596212,ROOF ADVISORY GROUP, INC.',
    name: 'ROOF ADVISORY GROUP, INC.',
    label: 'ROOF ADVISORY GROUP, INC.',
  },
  {
    cik: '0001028874',
    value: '0001028874,ROOF EIDAM & MAYCOCK/ADV',
    name: 'ROOF EIDAM & MAYCOCK/ADV',
    label: 'ROOF EIDAM & MAYCOCK/ADV',
  },
  {
    cik: '0001020617',
    value: '0001020617,ROOSEVELT INVESTMENT GROUP INC',
    name: 'ROOSEVELT INVESTMENT GROUP INC',
    label: 'ROOSEVELT INVESTMENT GROUP INC',
  },
  {
    cik: '0001020617',
    value: '0001020617,ROOSEVELT INVESTMENT GROUP LLC',
    name: 'ROOSEVELT INVESTMENT GROUP LLC',
    label: 'ROOSEVELT INVESTMENT GROUP LLC',
  },
  {
    cik: '0001641652',
    value: '0001641652,ROPES WEALTH ADVISORS LLC',
    name: 'ROPES WEALTH ADVISORS LLC',
    label: 'ROPES WEALTH ADVISORS LLC',
  },
  {
    cik: '0001622820',
    value: '0001622820,ROSENBAUM JAY D.',
    name: 'ROSENBAUM JAY D.',
    label: 'ROSENBAUM JAY D.',
  },
  {
    cik: '0001812103',
    value: '0001812103,ROSENBERG MATTHEW HAMILTON',
    name: 'ROSENBERG MATTHEW HAMILTON',
    label: 'ROSENBERG MATTHEW HAMILTON',
  },
  {
    cik: '0000918893',
    value: '0000918893,ROSENBLUM SILVERMAN SUTTON S F INC /CA',
    name: 'ROSENBLUM SILVERMAN SUTTON S F INC /CA',
    label: 'ROSENBLUM SILVERMAN SUTTON S F INC /CA',
  },
  {
    cik: '0001906572',
    value: '0001906572,ROSE PARK ADVISORS, LLC',
    name: 'ROSE PARK ADVISORS, LLC',
    label: 'ROSE PARK ADVISORS, LLC',
  },
  {
    cik: '0001733082',
    value: '0001733082,ROSSMORE PRIVATE CAPITAL',
    name: 'ROSSMORE PRIVATE CAPITAL',
    label: 'ROSSMORE PRIVATE CAPITAL',
  },
  {
    cik: '0001652237',
    value: '0001652237,ROTATION CAPITAL MANAGEMENT, LP',
    name: 'ROTATION CAPITAL MANAGEMENT, LP',
    label: 'ROTATION CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0001529426',
    value: '0001529426,ROTELLA CAPITAL MANAGEMENT, INC.',
    name: 'ROTELLA CAPITAL MANAGEMENT, INC.',
    label: 'ROTELLA CAPITAL MANAGEMENT, INC.',
  },
  {
    cik: '0001840888',
    value: '0001840888,ROTH FINANCIAL PARTNERS LLC',
    name: 'ROTH FINANCIAL PARTNERS LLC',
    label: 'ROTH FINANCIAL PARTNERS LLC',
  },
  {
    cik: '0000071259',
    value: '0000071259,ROTHSCHILD ASSET MANAGEMENT INC',
    name: 'ROTHSCHILD ASSET MANAGEMENT INC',
    label: 'ROTHSCHILD ASSET MANAGEMENT INC',
  },
  {
    cik: '0001569833',
    value: '0001569833,ROTHSCHILD CAPITAL PARTNERS, LLC',
    name: 'ROTHSCHILD CAPITAL PARTNERS, LLC',
    label: 'ROTHSCHILD CAPITAL PARTNERS, LLC',
  },
  {
    cik: '0000071259',
    value: '0000071259,ROTHSCHILD & CO ASSET MANAGEMENT US INC.',
    name: 'ROTHSCHILD & CO ASSET MANAGEMENT US INC.',
    label: 'ROTHSCHILD & CO ASSET MANAGEMENT US INC.',
  },
  {
    cik: '0001784260',
    value: '0001784260,ROTHSCHILD & CO WEALTH MANAGEMENT UK LTD',
    name: 'ROTHSCHILD & CO WEALTH MANAGEMENT UK LTD',
    label: 'ROTHSCHILD & CO WEALTH MANAGEMENT UK LTD',
  },
  {
    cik: '0001020918',
    value: '0001020918,ROTHSCHILD INVESTMENT CORP /IL',
    name: 'ROTHSCHILD INVESTMENT CORP /IL',
    label: 'ROTHSCHILD INVESTMENT CORP /IL',
  },
  {
    cik: '0001769700',
    value: '0001769700,ROUBAIX CAPITAL, LLC',
    name: 'ROUBAIX CAPITAL, LLC',
    label: 'ROUBAIX CAPITAL, LLC',
  },
  {
    cik: '0001331693',
    value: '0001331693,ROUMELL ASSET MANAGEMENT, LLC',
    name: 'ROUMELL ASSET MANAGEMENT, LLC',
    label: 'ROUMELL ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001696438',
    value: '0001696438,ROUND HILL ASSET MANAGEMENT',
    name: 'ROUND HILL ASSET MANAGEMENT',
    label: 'ROUND HILL ASSET MANAGEMENT',
  },
  {
    cik: '0001846991',
    value: '0001846991,ROUND ROCK ADVISORS, LLC',
    name: 'ROUND ROCK ADVISORS, LLC',
    label: 'ROUND ROCK ADVISORS, LLC',
  },
  {
    cik: '0001456257',
    value: '0001456257,ROUND TABLE INVESTMENT MANAGEMENT COMPANY, LP',
    name: 'ROUND TABLE INVESTMENT MANAGEMENT COMPANY, LP',
    label: 'ROUND TABLE INVESTMENT MANAGEMENT COMPANY, LP',
  },
  {
    cik: '0001375664',
    value: '0001375664,ROUND TABLE SERVICES, LLC',
    name: 'ROUND TABLE SERVICES, LLC',
    label: 'ROUND TABLE SERVICES, LLC',
  },
  {
    cik: '0001476804',
    value: '0001476804,ROUNDVIEW CAPITAL LLC',
    name: 'ROUNDVIEW CAPITAL LLC',
    label: 'ROUNDVIEW CAPITAL LLC',
  },
  {
    cik: '0001512171',
    value: '0001512171,ROUTE ONE INVESTMENT COMPANY, L.P.',
    name: 'ROUTE ONE INVESTMENT COMPANY, L.P.',
    label: 'ROUTE ONE INVESTMENT COMPANY, L.P.',
  },
  {
    cik: '0001653857',
    value: '0001653857,ROVIDA ADVISORS INC.',
    name: 'ROVIDA ADVISORS INC.',
    label: 'ROVIDA ADVISORS INC.',
  },
  {
    cik: '0001059057',
    value: '0001059057,ROVIN CAPITAL /UT/ /ADV',
    name: 'ROVIN CAPITAL /UT/ /ADV',
    label: 'ROVIN CAPITAL /UT/ /ADV',
  },
  {
    cik: '0000825052',
    value: '0000825052,ROWLAND CARMICHAEL ADVISORS INC /ADV',
    name: 'ROWLAND CARMICHAEL ADVISORS INC /ADV',
    label: 'ROWLAND CARMICHAEL ADVISORS INC /ADV',
  },
  {
    cik: '0000225816',
    value: '0000225816,ROWLAND & CO INVESTMENT COUNSEL/ADV',
    name: 'ROWLAND & CO INVESTMENT COUNSEL/ADV',
    label: 'ROWLAND & CO INVESTMENT COUNSEL/ADV',
  },
  {
    cik: '0001000275',
    value: '0001000275,ROYAL BANK OF CANADA',
    name: 'ROYAL BANK OF CANADA',
    label: 'ROYAL BANK OF CANADA',
  },
  {
    cik: '0000844150',
    value: '0000844150,ROYAL BANK OF SCOTLAND GROUP PLC',
    name: 'ROYAL BANK OF SCOTLAND GROUP PLC',
    label: 'ROYAL BANK OF SCOTLAND GROUP PLC',
  },
  {
    cik: '0001182836',
    value: '0001182836,ROYAL CAPITAL MANAGEMENT, LLC',
    name: 'ROYAL CAPITAL MANAGEMENT, LLC',
    label: 'ROYAL CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001904323',
    value: '0001904323,ROYAL CAPITAL WEALTH MANAGEMENT, LLC',
    name: 'ROYAL CAPITAL WEALTH MANAGEMENT, LLC',
    label: 'ROYAL CAPITAL WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001810099',
    value: '0001810099,ROYAL FUND MANAGEMENT, LLC',
    name: 'ROYAL FUND MANAGEMENT, LLC',
    label: 'ROYAL FUND MANAGEMENT, LLC',
  },
  {
    cik: '0001801876',
    value: '0001801876,ROYAL HARBOR PARTNERS, LLC',
    name: 'ROYAL HARBOR PARTNERS, LLC',
    label: 'ROYAL HARBOR PARTNERS, LLC',
  },
  {
    cik: '0000863748',
    value: '0000863748,ROYAL LONDON ASSET MANAGEMENT LTD',
    name: 'ROYAL LONDON ASSET MANAGEMENT LTD',
    label: 'ROYAL LONDON ASSET MANAGEMENT LTD',
  },
  {
    cik: '0000906304',
    value: '0000906304,ROYCE & ASSOCIATES LLC',
    name: 'ROYCE & ASSOCIATES LLC',
    label: 'ROYCE & ASSOCIATES LLC',
  },
  {
    cik: '0000906304',
    value: '0000906304,ROYCE & ASSOCIATES LP',
    name: 'ROYCE & ASSOCIATES LP',
    label: 'ROYCE & ASSOCIATES LP',
  },
  {
    cik: '0001582271',
    value: '0001582271,ROYSTONE CAPITAL MANAGEMENT LP',
    name: 'ROYSTONE CAPITAL MANAGEMENT LP',
    label: 'ROYSTONE CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001843303',
    value: '0001843303,R.P. BOGGS & CO.',
    name: 'R.P. BOGGS & CO.',
    label: 'R.P. BOGGS & CO.',
  },
  {
    cik: '0001832568',
    value: '0001832568,RPD FUND MANAGEMENT LLC',
    name: 'RPD FUND MANAGEMENT LLC',
    label: 'RPD FUND MANAGEMENT LLC',
  },
  {
    cik: '0001569884',
    value: '0001569884,RPG FAMILY WEALTH ADVISORY, LLC',
    name: 'RPG FAMILY WEALTH ADVISORY, LLC',
    label: 'RPG FAMILY WEALTH ADVISORY, LLC',
  },
  {
    cik: '0001666664',
    value: '0001666664,RPG INVESTMENT ADVISORY, LLC',
    name: 'RPG INVESTMENT ADVISORY, LLC',
    label: 'RPG INVESTMENT ADVISORY, LLC',
  },
  {
    cik: '0001767393',
    value: '0001767393,RP INVESTMENT ADVISORS LP',
    name: 'RP INVESTMENT ADVISORS LP',
    label: 'RP INVESTMENT ADVISORS LP',
  },
  {
    cik: '0001507673',
    value: '0001507673,RP MANAGEMENT, LLC',
    name: 'RP MANAGEMENT, LLC',
    label: 'RP MANAGEMENT, LLC',
  },
  {
    cik: '0001885066',
    value: '000188,RPO LLC66',
    name: 'RPO LLC',
    label: 'RPO LLC',
  },
  {
    cik: '0001714450',
    value: '00017144,RPTC INC.',
    name: 'RPTC INC.',
    label: 'RPTC INC.',
  },
  {
    cik: '0001631118',
    value: '0001631,RQSI LTD8',
    name: 'RQSI LTD',
    label: 'RQSI LTD',
  },
  {
    cik: '0001322613',
    value: '0001322613,RR ADVISORS, LLC',
    name: 'RR ADVISORS, LLC',
    label: 'RR ADVISORS, LLC',
  },
  {
    cik: '0001526297',
    value: '0001526297,RRE VENTURES GP III, LLC',
    name: 'RRE VENTURES GP III, LLC',
    label: 'RRE VENTURES GP III, LLC',
  },
  {
    cik: '0001599961',
    value: '0001599961,RRJ CAPITAL II LTD',
    name: 'RRJ CAPITAL II LTD',
    label: 'RRJ CAPITAL II LTD',
  },
  {
    cik: '0001529337',
    value: '0001529337,RRJ CAPITAL LTD',
    name: 'RRJ CAPITAL LTD',
    label: 'RRJ CAPITAL LTD',
  },
  {
    cik: '0001280217',
    value: '0001280217,RR PARTNERS LP',
    name: 'RR PARTNERS LP',
    label: 'RR PARTNERS LP',
  },
  {
    cik: '0001401459',
    value: '0001401459,RS CRUM INC.',
    name: 'RS CRUM INC.',
    label: 'RS CRUM INC.',
  },
  {
    cik: '0001085256',
    value: '0001085256,RS INVESTMENT MANAGEMENT CO LLC',
    name: 'RS INVESTMENT MANAGEMENT CO LLC',
    label: 'RS INVESTMENT MANAGEMENT CO LLC',
  },
  {
    cik: '0001679031',
    value: '0001679031,RSM US WEALTH MANAGEMENT LLC',
    name: 'RSM US WEALTH MANAGEMENT LLC',
    label: 'RSM US WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0001810555',
    value: '0001810555,RTD FINANCIAL ADVISORS, INC.',
    name: 'RTD FINANCIAL ADVISORS, INC.',
    label: 'RTD FINANCIAL ADVISORS, INC.',
  },
  {
    cik: '0001590803',
    value: '0001590803,RTLC MANAGEMENT, LLC',
    name: 'RTLC MANAGEMENT, LLC',
    label: 'RTLC MANAGEMENT, LLC',
  },
  {
    cik: '0001493215',
    value: '0001493215,RTW INVESTMENTS, LLC',
    name: 'RTW INVESTMENTS, LLC',
    label: 'RTW INVESTMENTS, LLC',
  },
  {
    cik: '0001493215',
    value: '0001493215,RTW INVESTMENTS, LP',
    name: 'RTW INVESTMENTS, LP',
    label: 'RTW INVESTMENTS, LP',
  },
  {
    cik: '0000728014',
    value: '0000728014,RUANE, CUNNIFF & GOLDFARB INC',
    name: 'RUANE, CUNNIFF & GOLDFARB INC',
    label: 'RUANE, CUNNIFF & GOLDFARB INC',
  },
  {
    cik: '0001720792',
    value: '0001720792,RUANE, CUNNIFF & GOLDFARB L.P.',
    name: 'RUANE, CUNNIFF & GOLDFARB L.P.',
    label: 'RUANE, CUNNIFF & GOLDFARB L.P.',
  },
  {
    cik: '0001628676',
    value: '0001628676,RUBRIC CAPITAL MANAGEMENT, LLC',
    name: 'RUBRIC CAPITAL MANAGEMENT, LLC',
    label: 'RUBRIC CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001687509',
    value: '0001687509,RUBRIC CAPITAL MANAGEMENT LP',
    name: 'RUBRIC CAPITAL MANAGEMENT LP',
    label: 'RUBRIC CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001783477',
    value: '0001783477,RUDD INTERNATIONAL, INC.',
    name: 'RUDD INTERNATIONAL, INC.',
    label: 'RUDD INTERNATIONAL, INC.',
  },
  {
    cik: '0001835407',
    value: '0001835407,RUDIUS MANAGEMENT LP',
    name: 'RUDIUS MANAGEMENT LP',
    label: 'RUDIUS MANAGEMENT LP',
  },
  {
    cik: '0000860189',
    value: '0000860189,RUDMAN ERROL M',
    name: 'RUDMAN ERROL M',
    label: 'RUDMAN ERROL M',
  },
  {
    cik: '0001426859',
    value: '000142685,RUFFER LLP',
    name: 'RUFFER LLP',
    label: 'RUFFER LLP',
  },
  {
    cik: '0001632549',
    value: '0001632549,RUGGIE CAPITAL GROUP',
    name: 'RUGGIE CAPITAL GROUP',
    label: 'RUGGIE CAPITAL GROUP',
  },
  {
    cik: '0001803994',
    value: '0001803994,RUNNING OAK CAPITAL LLC',
    name: 'RUNNING OAK CAPITAL LLC',
    label: 'RUNNING OAK CAPITAL LLC',
  },
  {
    cik: '0001840629',
    value: '0001840629,RUNNING POINT CAPITAL ADVISORS, LLC',
    name: 'RUNNING POINT CAPITAL ADVISORS, LLC',
    label: 'RUNNING POINT CAPITAL ADVISORS, LLC',
  },
  {
    cik: '0001736465',
    value: '0001736465,RUSH ISLAND MANAGEMENT, LP',
    name: 'RUSH ISLAND MANAGEMENT, LP',
    label: 'RUSH ISLAND MANAGEMENT, LP',
  },
  {
    cik: '0001340614',
    value: '0001340614,RUSSELL CLARK INVESTMENT MANAGEMENT LTD',
    name: 'RUSSELL CLARK INVESTMENT MANAGEMENT LTD',
    label: 'RUSSELL CLARK INVESTMENT MANAGEMENT LTD',
  },
  {
    cik: '0000721204',
    value: '0000721204,RUSSELL FRANK CO/',
    name: 'RUSSELL FRANK CO/',
    label: 'RUSSELL FRANK CO/',
  },
  {
    cik: '0001692234',
    value: '0001692234,RUSSELL INVESTMENTS GROUP, LTD.',
    name: 'RUSSELL INVESTMENTS GROUP, LTD.',
    label: 'RUSSELL INVESTMENTS GROUP, LTD.',
  },
  {
    cik: '0001555433',
    value: '0001555433,RUSSIA PARTNERS MANAGEMENT, LLC',
    name: 'RUSSIA PARTNERS MANAGEMENT, LLC',
    label: 'RUSSIA PARTNERS MANAGEMENT, LLC',
  },
  {
    cik: '0001128239',
    value: '0001128239,RUTABAGA CAPITAL MANAGEMENT LLC/MA',
    name: 'RUTABAGA CAPITAL MANAGEMENT LLC/MA',
    label: 'RUTABAGA CAPITAL MANAGEMENT LLC/MA',
  },
  {
    cik: '0001721568',
    value: '0001721568,RVB CAPITAL MANAGEMENT LLC',
    name: 'RVB CAPITAL MANAGEMENT LLC',
    label: 'RVB CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001766596',
    value: '0001766596,RV CAPITAL AG',
    name: 'RV CAPITAL AG',
    label: 'RV CAPITAL AG',
  },
  {
    cik: '0001766596',
    value: '0001766596,RV CAPITAL GMBH',
    name: 'RV CAPITAL GMBH',
    label: 'RV CAPITAL GMBH',
  },
  {
    cik: '0001756543',
    value: '0001756543,RVW WEALTH, LLC',
    name: 'RVW WEALTH, LLC',
    label: 'RVW WEALTH, LLC',
  },
  {
    cik: '0001638081',
    value: '0001638081,RWC ASSET ADVISORS (US) LLC',
    name: 'RWC ASSET ADVISORS (US) LLC',
    label: 'RWC ASSET ADVISORS (US) LLC',
  },
  {
    cik: '0001520478',
    value: '0001520478,RWC ASSET MANAGEMENT LLP',
    name: 'RWC ASSET MANAGEMENT LLP',
    label: 'RWC ASSET MANAGEMENT LLP',
  },
  {
    cik: '0001844369',
    value: '0001844369,RWM ASSET MANAGEMENT, LLC',
    name: 'RWM ASSET MANAGEMENT, LLC',
    label: 'RWM ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001846544',
    value: '0001846544,RWQ FINANCIAL MANAGEMENT SERVICES, INC.',
    name: 'RWQ FINANCIAL MANAGEMENT SERVICES, INC.',
    label: 'RWQ FINANCIAL MANAGEMENT SERVICES, INC.',
  },
  {
    cik: '0001800745',
    value: '0001800745,R. W. ROGE & COMPANY, INC.',
    name: 'R. W. ROGE & COMPANY, INC.',
    label: 'R. W. ROGE & COMPANY, INC.',
  },
  {
    cik: '0001507683',
    value: '000150768,RWWM, INC.',
    name: 'RWWM, INC.',
    label: 'RWWM, INC.',
  },
  {
    cik: '0001844147',
    value: '0001844147,RYE BROOK CAPITAL LLC',
    name: 'RYE BROOK CAPITAL LLC',
    label: 'RYE BROOK CAPITAL LLC',
  },
  {
    cik: '0001510281',
    value: '0001510281,SABA CAPITAL MANAGEMENT, L.P.',
    name: 'SABA CAPITAL MANAGEMENT, L.P.',
    label: 'SABA CAPITAL MANAGEMENT, L.P.',
  },
  {
    cik: '0001596953',
    value: '0001596953,SABAL CAPITAL MANAGEMENT, LLC',
    name: 'SABAL CAPITAL MANAGEMENT, LLC',
    label: 'SABAL CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001323276',
    value: '0001323276,SABAL TRUST CO',
    name: 'SABAL TRUST CO',
    label: 'SABAL TRUST CO',
  },
  {
    cik: '0001568913',
    value: '0001568913,SABAN CAPITAL GROUP LLC',
    name: 'SABAN CAPITAL GROUP LLC',
    label: 'SABAN CAPITAL GROUP LLC',
  },
  {
    cik: '0001568981',
    value: '0001568981,SABAN CHERYL',
    name: 'SABAN CHERYL',
    label: 'SABAN CHERYL',
  },
  {
    cik: '0001535610',
    value: '0001535610,SABBY MANAGEMENT, LLC',
    name: 'SABBY MANAGEMENT, LLC',
    label: 'SABBY MANAGEMENT, LLC',
  },
  {
    cik: '0001359894',
    value: '0001359894,SAB CAPITAL MANAGEMENT LP',
    name: 'SAB CAPITAL MANAGEMENT LP',
    label: 'SAB CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001911378',
    value: '0001911378,SABER CAPITAL MANAGMENT, LLC',
    name: 'SABER CAPITAL MANAGMENT, LLC',
    label: 'SABER CAPITAL MANAGMENT, LLC',
  },
  {
    cik: '0001599822',
    value: '0001599822,S.A.C. CAPITAL ADVISORS (HONG KONG) LTD.',
    name: 'S.A.C. CAPITAL ADVISORS (HONG KONG) LTD.',
    label: 'S.A.C. CAPITAL ADVISORS (HONG KONG) LTD.',
  },
  {
    cik: '0001451928',
    value: '0001451928,SAC CAPITAL ADVISORS LP',
    name: 'SAC CAPITAL ADVISORS LP',
    label: 'SAC CAPITAL ADVISORS LP',
  },
  {
    cik: '0001582090',
    value: '0001582090,SACHEM HEAD CAPITAL MANAGEMENT LP',
    name: 'SACHEM HEAD CAPITAL MANAGEMENT LP',
    label: 'SACHEM HEAD CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001755368',
    value: '0001755368,SADDLE POINT MANAGEMENT, L.P.',
    name: 'SADDLE POINT MANAGEMENT, L.P.',
    label: 'SADDLE POINT MANAGEMENT, L.P.',
  },
  {
    cik: '0001663896',
    value: '0001663896,SADDLE ROAD PARTNERS, LLC',
    name: 'SADDLE ROAD PARTNERS, LLC',
    label: 'SADDLE ROAD PARTNERS, LLC',
  },
  {
    cik: '0001021008',
    value: '0001021008,SADOFF INVESTMENT MANAGEMENT LLC',
    name: 'SADOFF INVESTMENT MANAGEMENT LLC',
    label: 'SADOFF INVESTMENT MANAGEMENT LLC',
  },
  {
    cik: '0001922498',
    value: '0001922498,SAFEGUARD FINANCIAL, LLC',
    name: 'SAFEGUARD FINANCIAL, LLC',
    label: 'SAFEGUARD FINANCIAL, LLC',
  },
  {
    cik: '0001846711',
    value: '0001846711,SAFEGUARD INVESTMENT ADVISORY GROUP, LLC',
    name: 'SAFEGUARD INVESTMENT ADVISORY GROUP, LLC',
    label: 'SAFEGUARD INVESTMENT ADVISORY GROUP, LLC',
  },
  {
    cik: '0001830103',
    value: '0001830103,SAFIR WEALTH ADVISORS, LLC',
    name: 'SAFIR WEALTH ADVISORS, LLC',
    label: 'SAFIR WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001423386',
    value: '0001423386,SAGARD CAPITAL PARTNERS MANAGEMENT CORP',
    name: 'SAGARD CAPITAL PARTNERS MANAGEMENT CORP',
    label: 'SAGARD CAPITAL PARTNERS MANAGEMENT CORP',
  },
  {
    cik: '0001534378',
    value: '0001534378,SAGE ADVISORY SERVICES, LTD.CO.',
    name: 'SAGE ADVISORY SERVICES, LTD.CO.',
    label: 'SAGE ADVISORY SERVICES, LTD.CO.',
  },
  {
    cik: '0001714107',
    value: '0001714107,SAGE CAPITAL ADVISORS,LLC',
    name: 'SAGE CAPITAL ADVISORS,LLC',
    label: 'SAGE CAPITAL ADVISORS,LLC',
  },
  {
    cik: '0001324360',
    value: '0001324360,SAGE CAPITAL MANAGEMENT, LLC',
    name: 'SAGE CAPITAL MANAGEMENT, LLC',
    label: 'SAGE CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001771179',
    value: '0001771179,SAGEFIELD CAPITAL LP',
    name: 'SAGEFIELD CAPITAL LP',
    label: 'SAGEFIELD CAPITAL LP',
  },
  {
    cik: '0000882119',
    value: '0000882119,SAGE FINANCIAL GROUP INC',
    name: 'SAGE FINANCIAL GROUP INC',
    label: 'SAGE FINANCIAL GROUP INC',
  },
  {
    cik: '0001762086',
    value: '0001762086,SAGE FINANCIAL MANAGEMENT GROUP, INC.',
    name: 'SAGE FINANCIAL MANAGEMENT GROUP, INC.',
    label: 'SAGE FINANCIAL MANAGEMENT GROUP, INC.',
  },
  {
    cik: '0001861378',
    value: '0001861378,SAGEGUARD FINANCIAL GROUP, LLC',
    name: 'SAGEGUARD FINANCIAL GROUP, LLC',
    label: 'SAGEGUARD FINANCIAL GROUP, LLC',
  },
  {
    cik: '0001925418',
    value: '0001925418,SAGE INVESTMENT ADVISERS LLC',
    name: 'SAGE INVESTMENT ADVISERS LLC',
    label: 'SAGE INVESTMENT ADVISERS LLC',
  },
  {
    cik: '0001832274',
    value: '0001832274,SAGE MOUNTAIN ADVISORS LLC',
    name: 'SAGE MOUNTAIN ADVISORS LLC',
    label: 'SAGE MOUNTAIN ADVISORS LLC',
  },
  {
    cik: '0001905278',
    value: '0001905278,SAGE PARTNERS LTD',
    name: 'SAGE PARTNERS LTD',
    label: 'SAGE PARTNERS LTD',
  },
  {
    cik: '0001845635',
    value: '0001845635,SAGE PRIVATE WEALTH GROUP, LLC',
    name: 'SAGE PRIVATE WEALTH GROUP, LLC',
    label: 'SAGE PRIVATE WEALTH GROUP, LLC',
  },
  {
    cik: '0001771605',
    value: '0001771605,SAGE RHINO CAPITAL LLC',
    name: 'SAGE RHINO CAPITAL LLC',
    label: 'SAGE RHINO CAPITAL LLC',
  },
  {
    cik: '0001722622',
    value: '0001722622,SAGE ROCK CAPITAL MANAGEMENT LP',
    name: 'SAGE ROCK CAPITAL MANAGEMENT LP',
    label: 'SAGE ROCK CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001389563',
    value: '0001389563,SAGEVIEW CAPITAL LP',
    name: 'SAGEVIEW CAPITAL LP',
    label: 'SAGEVIEW CAPITAL LP',
  },
  {
    cik: '0001482688',
    value: '0001482688,SAGEWORTH TRUST CO',
    name: 'SAGEWORTH TRUST CO',
    label: 'SAGEWORTH TRUST CO',
  },
  {
    cik: '0001871593',
    value: '0001871593,SAGEWORTH TRUST CO OF SOUTH DAKOTA',
    name: 'SAGEWORTH TRUST CO OF SOUTH DAKOTA',
    label: 'SAGEWORTH TRUST CO OF SOUTH DAKOTA',
  },
  {
    cik: '0001747744',
    value: '0001747744,SAGIL CAPITAL LLP',
    name: 'SAGIL CAPITAL LLP',
    label: 'SAGIL CAPITAL LLP',
  },
  {
    cik: '0001463970',
    value: '0001463970,SAIERS CAPITAL, LLC',
    name: 'SAIERS CAPITAL, LLC',
    label: 'SAIERS CAPITAL, LLC',
  },
  {
    cik: '0001697159',
    value: '0001697159,SAIF ADVISORS LTD',
    name: 'SAIF ADVISORS LTD',
    label: 'SAIF ADVISORS LTD',
  },
  {
    cik: '0001803898',
    value: '0001803898,SAILER FINANCIAL LLC',
    name: 'SAILER FINANCIAL LLC',
    label: 'SAILER FINANCIAL LLC',
  },
  {
    cik: '0001608904',
    value: '0001608904,SAILINGSTONE CAPITAL PARTNERS LLC',
    name: 'SAILINGSTONE CAPITAL PARTNERS LLC',
    label: 'SAILINGSTONE CAPITAL PARTNERS LLC',
  },
  {
    cik: '0001049648',
    value: '0001049648,SALEM CAPITAL MANAGEMENT INC',
    name: 'SALEM CAPITAL MANAGEMENT INC',
    label: 'SALEM CAPITAL MANAGEMENT INC',
  },
  {
    cik: '0000884423',
    value: '0000884423,SALEM INVESTMENT COUNSELORS INC',
    name: 'SALEM INVESTMENT COUNSELORS INC',
    label: 'SALEM INVESTMENT COUNSELORS INC',
  },
  {
    cik: '0001108524',
    value: '0001108524,SALESFORCE COM INC',
    name: 'SALESFORCE COM INC',
    label: 'SALESFORCE COM INC',
  },
  {
    cik: '0001108524',
    value: '0001108524,SALESFORCE, INC.',
    name: 'SALESFORCE, INC.',
    label: 'SALESFORCE, INC.',
  },
  {
    cik: '0001510769',
    value: '0001510769,SALIENT CAPITAL ADVISORS, LLC',
    name: 'SALIENT CAPITAL ADVISORS, LLC',
    label: 'SALIENT CAPITAL ADVISORS, LLC',
  },
  {
    cik: '0001714472',
    value: '0001714472,SALIENT PRIVATE CLIENT, LLC',
    name: 'SALIENT PRIVATE CLIENT, LLC',
    label: 'SALIENT PRIVATE CLIENT, LLC',
  },
  {
    cik: '0001090413',
    value: '0001090413,SALIENT TRUST CO LTA',
    name: 'SALIENT TRUST CO LTA',
    label: 'SALIENT TRUST CO LTA',
  },
  {
    cik: '0001766156',
    value: '0001766156,SALOMON & LUDWIN, LLC',
    name: 'SALOMON & LUDWIN, LLC',
    label: 'SALOMON & LUDWIN, LLC',
  },
  {
    cik: '0001552059',
    value: '0001552059,SALTORO CAPITAL, LP',
    name: 'SALTORO CAPITAL, LP',
    label: 'SALTORO CAPITAL, LP',
  },
  {
    cik: '0001879635',
    value: '0001879635,SAL TRADING, LLC',
    name: 'SAL TRADING, LLC',
    label: 'SAL TRADING, LLC',
  },
  {
    cik: '0001809236',
    value: '0001809236,SALVUS WEALTH MANAGEMENT, LLC',
    name: 'SALVUS WEALTH MANAGEMENT, LLC',
    label: 'SALVUS WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001059543',
    value: '0001059543,SALZHAUER MICHAEL',
    name: 'SALZHAUER MICHAEL',
    label: 'SALZHAUER MICHAEL',
  },
  {
    cik: '0001390111',
    value: '0001390111,SALZMAN & CO., INC.',
    name: 'SALZMAN & CO., INC.',
    label: 'SALZMAN & CO., INC.',
  },
  {
    cik: '0001600944',
    value: '0001600944,SAM ADVISORS, LLC',
    name: 'SAM ADVISORS, LLC',
    label: 'SAM ADVISORS, LLC',
  },
  {
    cik: '0001544366',
    value: '0001544366,SAMALIN INVESTMENT COUNSEL, LLC',
    name: 'SAMALIN INVESTMENT COUNSEL, LLC',
    label: 'SAMALIN INVESTMENT COUNSEL, LLC',
  },
  {
    cik: '0001800911',
    value: '0001800911,S.A. MASON LLC',
    name: 'S.A. MASON LLC',
    label: 'S.A. MASON LLC',
  },
  {
    cik: '0001509943',
    value: '0001509943,SAMJO CAPITAL LLC',
    name: 'SAMJO CAPITAL LLC',
    label: 'SAMJO CAPITAL LLC',
  },
  {
    cik: '0001421097',
    value: '0001421097,SAMLYN CAPITAL, LLC',
    name: 'SAMLYN CAPITAL, LLC',
    label: 'SAMLYN CAPITAL, LLC',
  },
  {
    cik: '0001744967',
    value: '0001744967,SAMSARA BIOCAPITAL, LLC',
    name: 'SAMSARA BIOCAPITAL, LLC',
    label: 'SAMSARA BIOCAPITAL, LLC',
  },
  {
    cik: '0001631665',
    value: '0001631665,SAMSON CAPITAL ADVISORS LLC',
    name: 'SAMSON CAPITAL ADVISORS LLC',
    label: 'SAMSON CAPITAL ADVISORS LLC',
  },
  {
    cik: '0001591271',
    value: '0001591271,SAMSON CAPITAL MANAGEMENT, LLC',
    name: 'SAMSON CAPITAL MANAGEMENT, LLC',
    label: 'SAMSON CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001788558',
    value: '0001788558,SAMSON ROCK CAPITAL LLP',
    name: 'SAMSON ROCK CAPITAL LLP',
    label: 'SAMSON ROCK CAPITAL LLP',
  },
  {
    cik: '0001535106',
    value: '0001535106,SAMSUNG ASSET MANAGEMENT (NEW YORK), INC.',
    name: 'SAMSUNG ASSET MANAGEMENT (NEW YORK), INC.',
    label: 'SAMSUNG ASSET MANAGEMENT (NEW YORK), INC.',
  },
  {
    cik: '0001210976',
    value: '0001210976,SANBORN KILCOLLIN PARTNERS LLC',
    name: 'SANBORN KILCOLLIN PARTNERS LLC',
    label: 'SANBORN KILCOLLIN PARTNERS LLC',
  },
  {
    cik: '0001697740',
    value: '0001697740,SANCHEZ WEALTH MANAGEMENT GROUP',
    name: 'SANCHEZ WEALTH MANAGEMENT GROUP',
    label: 'SANCHEZ WEALTH MANAGEMENT GROUP',
  },
  {
    cik: '0001594197',
    value: '0001594197,SANCTUARY WEALTH ADVISORS, LLC',
    name: 'SANCTUARY WEALTH ADVISORS, LLC',
    label: 'SANCTUARY WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001728436',
    value: '0001728436,SANCTUARY WEALTH MANAGEMENT, L.L.C.',
    name: 'SANCTUARY WEALTH MANAGEMENT, L.L.C.',
    label: 'SANCTUARY WEALTH MANAGEMENT, L.L.C.',
  },
  {
    cik: '0001756870',
    value: '0001756870,SANDBAR ASSET MANAGEMENT LLP',
    name: 'SANDBAR ASSET MANAGEMENT LLP',
    label: 'SANDBAR ASSET MANAGEMENT LLP',
  },
  {
    cik: '0001844892',
    value: '0001844892,SANDBOX FINANCIAL PARTNERS, LLC',
    name: 'SANDBOX FINANCIAL PARTNERS, LLC',
    label: 'SANDBOX FINANCIAL PARTNERS, LLC',
  },
  {
    cik: '0001140474',
    value: '0001140474,SANDELL ASSET MANAGEMENT',
    name: 'SANDELL ASSET MANAGEMENT',
    label: 'SANDELL ASSET MANAGEMENT',
  },
  {
    cik: '0001576277',
    value: '0001576277,SANDELL INVESTMENT SERVICES LLC',
    name: 'SANDELL INVESTMENT SERVICES LLC',
    label: 'SANDELL INVESTMENT SERVICES LLC',
  },
  {
    cik: '0001173889',
    value: '0001173889,SANDER CAPITAL ADVISORS INC',
    name: 'SANDER CAPITAL ADVISORS INC',
    label: 'SANDER CAPITAL ADVISORS INC',
  },
  {
    cik: '0001508097',
    value: '0001508097,SANDERS CAPITAL, LLC',
    name: 'SANDERS CAPITAL, LLC',
    label: 'SANDERS CAPITAL, LLC',
  },
  {
    cik: '0001053994',
    value: '0001053994,SANDERS MORRIS HARRIS INC.',
    name: 'SANDERS MORRIS HARRIS INC.',
    label: 'SANDERS MORRIS HARRIS INC.',
  },
  {
    cik: '0001053994',
    value: '0001053994,SANDERS MORRIS HARRIS LLC',
    name: 'SANDERS MORRIS HARRIS LLC',
    label: 'SANDERS MORRIS HARRIS LLC',
  },
  {
    cik: '0001665077',
    value: '0001665077,SAND GROVE CAPITAL MANAGEMENT LLP',
    name: 'SAND GROVE CAPITAL MANAGEMENT LLP',
    label: 'SAND GROVE CAPITAL MANAGEMENT LLP',
  },
  {
    cik: '0001556245',
    value: '0001556245,SANDHILL CAPITAL PARTNERS LLC',
    name: 'SANDHILL CAPITAL PARTNERS LLC',
    label: 'SANDHILL CAPITAL PARTNERS LLC',
  },
  {
    cik: '0001009209',
    value: '0001009209,SAND HILL GLOBAL ADVISORS, LLC',
    name: 'SAND HILL GLOBAL ADVISORS, LLC',
    label: 'SAND HILL GLOBAL ADVISORS, LLC',
  },
  {
    cik: '0001617032',
    value: '0001617032,SANDIA HOLDINGS, LLC',
    name: 'SANDIA HOLDINGS, LLC',
    label: 'SANDIA HOLDINGS, LLC',
  },
  {
    cik: '0001880562',
    value: '0001880562,SANDIA INVESTMENT MANAGEMENT LP',
    name: 'SANDIA INVESTMENT MANAGEMENT LP',
    label: 'SANDIA INVESTMENT MANAGEMENT LP',
  },
  {
    cik: '0001000742',
    value: '0001000742,SANDLER CAPITAL MANAGEMENT',
    name: 'SANDLER CAPITAL MANAGEMENT',
    label: 'SANDLER CAPITAL MANAGEMENT',
  },
  {
    cik: '0001040762',
    value: '0001040762,SANDLER ONEILL ASSET MANAGEMENT LLC',
    name: 'SANDLER ONEILL ASSET MANAGEMENT LLC',
    label: 'SANDLER ONEILL ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001020066',
    value: '0001020066,SANDS CAPITAL MANAGEMENT, LLC',
    name: 'SANDS CAPITAL MANAGEMENT, LLC',
    label: 'SANDS CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001846444',
    value: '0001846444,SANDS CAPITAL VENTURES, LLC',
    name: 'SANDS CAPITAL VENTURES, LLC',
    label: 'SANDS CAPITAL VENTURES, LLC',
  },
  {
    cik: '0001908217',
    value: '0001908217,SANDY COVE ADVISORS, LLC',
    name: 'SANDY COVE ADVISORS, LLC',
    label: 'SANDY COVE ADVISORS, LLC',
  },
  {
    cik: '0001457833',
    value: '0001457833,SANDY SPRING BANK',
    name: 'SANDY SPRING BANK',
    label: 'SANDY SPRING BANK',
  },
  {
    cik: '0001932496',
    value: '0001932496,SANFORD ADVISORY SERVICES, LLC',
    name: 'SANFORD ADVISORY SERVICES, LLC',
    label: 'SANFORD ADVISORY SERVICES, LLC',
  },
  {
    cik: '0001540142',
    value: '0001540142,SAN FRANCISCO SENTRY INVESTMENT GROUP (CA)',
    name: 'SAN FRANCISCO SENTRY INVESTMENT GROUP (CA)',
    label: 'SAN FRANCISCO SENTRY INVESTMENT GROUP (CA)',
  },
  {
    cik: '0001581830',
    value: '0001581830,SANIBEL CAPTIVA INVESTMENT ADVISERS, INC.',
    name: 'SANIBEL CAPTIVA INVESTMENT ADVISERS, INC.',
    label: 'SANIBEL CAPTIVA INVESTMENT ADVISERS, INC.',
  },
  {
    cik: '0001317947',
    value: '0001317947,SANKATY ADVISORS, LLC',
    name: 'SANKATY ADVISORS, LLC',
    label: 'SANKATY ADVISORS, LLC',
  },
  {
    cik: '0001844709',
    value: '0001844709,SAN LUIS WEALTH ADVISORS LLC',
    name: 'SAN LUIS WEALTH ADVISORS LLC',
    label: 'SAN LUIS WEALTH ADVISORS LLC',
  },
  {
    cik: '0001675134',
    value: '0001675134,SANOOR CAPITAL MANAGEMENT LP',
    name: 'SANOOR CAPITAL MANAGEMENT LP',
    label: 'SANOOR CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001335970',
    value: '0001335970,SANSAR CAPITAL MANAGEMENT, LLC',
    name: 'SANSAR CAPITAL MANAGEMENT, LLC',
    label: 'SANSAR CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001109426',
    value: '0001109426,SANTA BARBARA ASSET MANAGEMENT, LLC',
    name: 'SANTA BARBARA ASSET MANAGEMENT, LLC',
    label: 'SANTA BARBARA ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001352065',
    value: '0001352065,SANTA FE PARTNERS LLC',
    name: 'SANTA FE PARTNERS LLC',
    label: 'SANTA FE PARTNERS LLC',
  },
  {
    cik: '0000904793',
    value: '0000904793,SANTA MONICA PARTNERS LP',
    name: 'SANTA MONICA PARTNERS LP',
    label: 'SANTA MONICA PARTNERS LP',
  },
  {
    cik: '0001688829',
    value: '0001688829,SANTE CAPITAL FUND, LP',
    name: 'SANTE CAPITAL FUND, LP',
    label: 'SANTE CAPITAL FUND, LP',
  },
  {
    cik: '0001730959',
    value: '0001730959,SANTORI & PETERS, INC.',
    name: 'SANTORI & PETERS, INC.',
    label: 'SANTORI & PETERS, INC.',
  },
  {
    cik: '0001682598',
    value: '0001682598,SAPIENCE INVESTMENTS, LLC',
    name: 'SAPIENCE INVESTMENTS, LLC',
    label: 'SAPIENCE INVESTMENTS, LLC',
  },
  {
    cik: '0001647565',
    value: '0001647565,SAPPHIRE STAR PARTNERS LP',
    name: 'SAPPHIRE STAR PARTNERS LP',
    label: 'SAPPHIRE STAR PARTNERS LP',
  },
  {
    cik: '0001634035',
    value: '0001634035,SAPPHIRE VENTURES (GPE) I, L.L.C.',
    name: 'SAPPHIRE VENTURES (GPE) I, L.L.C.',
    label: 'SAPPHIRE VENTURES (GPE) I, L.L.C.',
  },
  {
    cik: '0001697665',
    value: '0001697665,SAPPHIRE VENTURES, L.L.C.',
    name: 'SAPPHIRE VENTURES, L.L.C.',
    label: 'SAPPHIRE VENTURES, L.L.C.',
  },
  {
    cik: '0001816444',
    value: '0001816444,SARA-BAY FINANCIAL',
    name: 'SARA-BAY FINANCIAL',
    label: 'SARA-BAY FINANCIAL',
  },
  {
    cik: '0001510481',
    value: '0001510481,SARASIN & PARTNERS LLP',
    name: 'SARASIN & PARTNERS LLP',
    label: 'SARASIN & PARTNERS LLP',
  },
  {
    cik: '0001477872',
    value: '0001477872,SARATOGA RESEARCH & INVESTMENT MANAGEMENT',
    name: 'SARATOGA RESEARCH & INVESTMENT MANAGEMENT',
    label: 'SARATOGA RESEARCH & INVESTMENT MANAGEMENT',
  },
  {
    cik: '0001460629',
    value: '0001460629,SARBIT ADVISORY SERVICES INC.',
    name: 'SARBIT ADVISORY SERVICES INC.',
    label: 'SARBIT ADVISORY SERVICES INC.',
  },
  {
    cik: '0001613939',
    value: '0001613939,SARGENT BICKHAM LAGUDIS LLC',
    name: 'SARGENT BICKHAM LAGUDIS LLC',
    label: 'SARGENT BICKHAM LAGUDIS LLC',
  },
  {
    cik: '0001766157',
    value: '0001766157,SARGENT INVESTMENT GROUP, LLC',
    name: 'SARGENT INVESTMENT GROUP, LLC',
    label: 'SARGENT INVESTMENT GROUP, LLC',
  },
  {
    cik: '0001577524',
    value: '0001577524,SARISSA CAPITAL MANAGEMENT LP',
    name: 'SARISSA CAPITAL MANAGEMENT LP',
    label: 'SARISSA CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001915870',
    value: '0001915870,SARISSA CONSULTING LLC',
    name: 'SARISSA CONSULTING LLC',
    label: 'SARISSA CONSULTING LLC',
  },
  {
    cik: '0000788714',
    value: '0000788714,SASCO CAPITAL INC / CT/',
    name: 'SASCO CAPITAL INC / CT/',
    label: 'SASCO CAPITAL INC / CT/',
  },
  {
    cik: '0001692751',
    value: '0001692751,SASSICAIA CAPITAL ADVISERS LLC',
    name: 'SASSICAIA CAPITAL ADVISERS LLC',
    label: 'SASSICAIA CAPITAL ADVISERS LLC',
  },
  {
    cik: '0001568540',
    value: '0001568540,SATHER FINANCIAL GROUP INC',
    name: 'SATHER FINANCIAL GROUP INC',
    label: 'SATHER FINANCIAL GROUP INC',
  },
  {
    cik: '0001807288',
    value: '0001807288,SATOVSKY ASSET MANAGEMENT LLC',
    name: 'SATOVSKY ASSET MANAGEMENT LLC',
    label: 'SATOVSKY ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001844002',
    value: '0001844002,SATTER MANAGEMENT CO., L.P.',
    name: 'SATTER MANAGEMENT CO., L.P.',
    label: 'SATTER MANAGEMENT CO., L.P.',
  },
  {
    cik: '0001316617',
    value: '0001316617,SATURNA CAPITAL CORP',
    name: 'SATURNA CAPITAL CORP',
    label: 'SATURNA CAPITAL CORP',
  },
  {
    cik: '0001918181',
    value: '0001918181,SAUDI CENTRAL BANK',
    name: 'SAUDI CENTRAL BANK',
    label: 'SAUDI CENTRAL BANK',
  },
  {
    cik: '0001482880',
    value: '0001482880,SAVANT CAPITAL, LLC',
    name: 'SAVANT CAPITAL, LLC',
    label: 'SAVANT CAPITAL, LLC',
  },
  {
    cik: '0001790723',
    value: '000179072,SAVIOR LLC',
    name: 'SAVIOR LLC',
    label: 'SAVIOR LLC',
  },
  {
    cik: '0001442757',
    value: '0001442757,SAVITR CAPITAL, LLC',
    name: 'SAVITR CAPITAL, LLC',
    label: 'SAVITR CAPITAL, LLC',
  },
  {
    cik: '0001105467',
    value: '0001105467,SAWGRASS ASSET MANAGEMENT LLC',
    name: 'SAWGRASS ASSET MANAGEMENT LLC',
    label: 'SAWGRASS ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001486066',
    value: '0001486066,SAWTOOTH ASSET MANAGEMENT, INC.',
    name: 'SAWTOOTH ASSET MANAGEMENT, INC.',
    label: 'SAWTOOTH ASSET MANAGEMENT, INC.',
  },
  {
    cik: '0001486066',
    value: '0001486066,SAWTOOTH SOLUTIONS, LLC',
    name: 'SAWTOOTH SOLUTIONS, LLC',
    label: 'SAWTOOTH SOLUTIONS, LLC',
  },
  {
    cik: '0001722436',
    value: '0001722436,SAWYER & COMPANY, INC',
    name: 'SAWYER & COMPANY, INC',
    label: 'SAWYER & COMPANY, INC',
  },
  {
    cik: '0001905867',
    value: '0001905867,SAXON INTERESTS, INC.',
    name: 'SAXON INTERESTS, INC.',
    label: 'SAXON INTERESTS, INC.',
  },
  {
    cik: '0001846150',
    value: '0001846150,SAX WEALTH ADVISORS, LLC',
    name: 'SAX WEALTH ADVISORS, LLC',
    label: 'SAX WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001715528',
    value: '0001715528,SAYA MANAGEMENT LP',
    name: 'SAYA MANAGEMENT LP',
    label: 'SAYA MANAGEMENT LP',
  },
  {
    cik: '0001044924',
    value: '0001044924,SAYBROOK CAPITAL /NC',
    name: 'SAYBROOK CAPITAL /NC',
    label: 'SAYBROOK CAPITAL /NC',
  },
  {
    cik: '0001896711',
    value: '0001896711,SBB RESEARCH GROUP LLC',
    name: 'SBB RESEARCH GROUP LLC',
    label: 'SBB RESEARCH GROUP LLC',
  },
  {
    cik: '0001883884',
    value: '0001883884,SB GLOBAL ADVISERS LTD',
    name: 'SB GLOBAL ADVISERS LTD',
    label: 'SB GLOBAL ADVISERS LTD',
  },
  {
    cik: '0001731509',
    value: '0001731509,SB INVESTMENT ADVISERS (UK) LTD',
    name: 'SB INVESTMENT ADVISERS (UK) LTD',
    label: 'SB INVESTMENT ADVISERS (UK) LTD',
  },
  {
    cik: '0001911894',
    value: '0001911894,SBK FINANCIAL, INC.',
    name: 'SBK FINANCIAL, INC.',
    label: 'SBK FINANCIAL, INC.',
  },
  {
    cik: '0001818296',
    value: '0001818296,SB MANAGEMENT LTD',
    name: 'SB MANAGEMENT LTD',
    label: 'SB MANAGEMENT LTD',
  },
  {
    cik: '0001424874',
    value: '0001424874,SCALE MANAGEMENT, LLC',
    name: 'SCALE MANAGEMENT, LLC',
    label: 'SCALE MANAGEMENT, LLC',
  },
  {
    cik: '0001815355',
    value: '0001815355,SCARBOROUGH ADVISORS, LLC',
    name: 'SCARBOROUGH ADVISORS, LLC',
    label: 'SCARBOROUGH ADVISORS, LLC',
  },
  {
    cik: '0001470766',
    value: '0001470766,SC CHINA HOLDING LTD',
    name: 'SC CHINA HOLDING LTD',
    label: 'SC CHINA HOLDING LTD',
  },
  {
    cik: '0001822015',
    value: '0001822015,SCEP MANAGEMENT LTD',
    name: 'SCEP MANAGEMENT LTD',
    label: 'SCEP MANAGEMENT LTD',
  },
  {
    cik: '0001167376',
    value: '0001167376,SCEPTER HOLDINGS, INC.',
    name: 'SCEPTER HOLDINGS, INC.',
    label: 'SCEPTER HOLDINGS, INC.',
  },
  {
    cik: '0001133918',
    value: '0001133918,SCFF MANAGEMENT LLC',
    name: 'SCFF MANAGEMENT LLC',
    label: 'SCFF MANAGEMENT LLC',
  },
  {
    cik: '0001906806',
    value: '0001906806,S.C. FINANCIAL SERVICES, INC.',
    name: 'S.C. FINANCIAL SERVICES, INC.',
    label: 'S.C. FINANCIAL SERVICES, INC.',
  },
  {
    cik: '0001085550',
    value: '0001085550,SCF PARTNERS, INC.',
    name: 'SCF PARTNERS, INC.',
    label: 'SCF PARTNERS, INC.',
  },
  {
    cik: '0001537530',
    value: '0001537530,SCGE MANAGEMENT, L.P.',
    name: 'SCGE MANAGEMENT, L.P.',
    label: 'SCGE MANAGEMENT, L.P.',
  },
  {
    cik: '0001471126',
    value: '0001471126,SCGF GENPAR LTD',
    name: 'SCGF GENPAR LTD',
    label: 'SCGF GENPAR LTD',
  },
  {
    cik: '0001367787',
    value: '0001367787,SCGF III MANAGEMENT LLC',
    name: 'SCGF III MANAGEMENT LLC',
    label: 'SCGF III MANAGEMENT LLC',
  },
  {
    cik: '0001898838',
    value: '0001898838,SCHAEFFER FINANCIAL LLC',
    name: 'SCHAEFFER FINANCIAL LLC',
    label: 'SCHAEFFER FINANCIAL LLC',
  },
  {
    cik: '0001056593',
    value: '0001056593,SCHAFER CULLEN CAPITAL MANAGEMENT INC',
    name: 'SCHAFER CULLEN CAPITAL MANAGEMENT INC',
    label: 'SCHAFER CULLEN CAPITAL MANAGEMENT INC',
  },
  {
    cik: '0001315889',
    value: '0001315889,SCHALLER INVESTMENT GROUP INC',
    name: 'SCHALLER INVESTMENT GROUP INC',
    label: 'SCHALLER INVESTMENT GROUP INC',
  },
  {
    cik: '0001033974',
    value: '0001033974,SCHAPER BENZ & WISE INVESTMENT COUNSEL INC/WI',
    name: 'SCHAPER BENZ & WISE INVESTMENT COUNSEL INC/WI',
    label: 'SCHAPER BENZ & WISE INVESTMENT COUNSEL INC/WI',
  },
  {
    cik: '0001463746',
    value: '0001463746,SCHARF INVESTMENTS, LLC',
    name: 'SCHARF INVESTMENTS, LLC',
    label: 'SCHARF INVESTMENTS, LLC',
  },
  {
    cik: '0001962838',
    value: '0001962838,SCHEAR INVESTMENT ADVISERS, LLC',
    name: 'SCHEAR INVESTMENT ADVISERS, LLC',
    label: 'SCHEAR INVESTMENT ADVISERS, LLC',
  },
  {
    cik: '0001951586',
    value: '0001951586,SCHECHTER INVESTMENT ADVISORS, LLC',
    name: 'SCHECHTER INVESTMENT ADVISORS, LLC',
    label: 'SCHECHTER INVESTMENT ADVISORS, LLC',
  },
  {
    cik: '0001426327',
    value: '0001426327,SCHEER, ROWLETT & ASSOCIATES INVESTMENT MANAGEMENT LTD.',
    name: 'SCHEER, ROWLETT & ASSOCIATES INVESTMENT MANAGEMENT LTD.',
    label: 'SCHEER, ROWLETT & ASSOCIATES INVESTMENT MANAGEMENT LTD.',
  },
  {
    cik: '0001537455',
    value: '0001537455,SCHF (GPE), LLC',
    name: 'SCHF (GPE), LLC',
    label: 'SCHF (GPE), LLC',
  },
  {
    cik: '0001907898',
    value: '0001907898,SC&H FINANCIAL ADVISORS, INC.',
    name: 'SC&H FINANCIAL ADVISORS, INC.',
    label: 'SC&H FINANCIAL ADVISORS, INC.',
  },
  {
    cik: '0001840014',
    value: '0001840014,SCHIAVI & CO LLC',
    name: 'SCHIAVI & CO LLC',
    label: 'SCHIAVI & CO LLC',
  },
  {
    cik: '0000733020',
    value: '0000733020,SCHMEIDLER A R & CO INC',
    name: 'SCHMEIDLER A R & CO INC',
    label: 'SCHMEIDLER A R & CO INC',
  },
  {
    cik: '0001008895',
    value: '0001008895,SCHMIDT P J INVESTMENT MANAGEMENT INC',
    name: 'SCHMIDT P J INVESTMENT MANAGEMENT INC',
    label: 'SCHMIDT P J INVESTMENT MANAGEMENT INC',
  },
  {
    cik: '0001036250',
    value: '0001036250,SCHNEIDER CAPITAL MANAGEMENT CORP',
    name: 'SCHNEIDER CAPITAL MANAGEMENT CORP',
    label: 'SCHNEIDER CAPITAL MANAGEMENT CORP',
  },
  {
    cik: '0001665359',
    value: '0001665359,SCHNEIDER DOWNS WEALTH MANAGEMENT ADVISORS, LP',
    name: 'SCHNEIDER DOWNS WEALTH MANAGEMENT ADVISORS, LP',
    label: 'SCHNEIDER DOWNS WEALTH MANAGEMENT ADVISORS, LP',
  },
  {
    cik: '0001603937',
    value: '0001603937,SCHNIEDERS CAPITAL MANAGEMENT LLC',
    name: 'SCHNIEDERS CAPITAL MANAGEMENT LLC',
    label: 'SCHNIEDERS CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001606134',
    value: '0001606134,SCHOLTZ & COMPANY, LLC',
    name: 'SCHOLTZ & COMPANY, LLC',
    label: 'SCHOLTZ & COMPANY, LLC',
  },
  {
    cik: '0001425994',
    value: '0001425994,SCHONFELD GROUP HOLDINGS LLC',
    name: 'SCHONFELD GROUP HOLDINGS LLC',
    label: 'SCHONFELD GROUP HOLDINGS LLC',
  },
  {
    cik: '0001665241',
    value: '0001665241,SCHONFELD STRATEGIC ADVISORS LLC',
    name: 'SCHONFELD STRATEGIC ADVISORS LLC',
    label: 'SCHONFELD STRATEGIC ADVISORS LLC',
  },
  {
    cik: '0001279915',
    value: '0001279915,SCHOONER CAPITAL CORP',
    name: 'SCHOONER CAPITAL CORP',
    label: 'SCHOONER CAPITAL CORP',
  },
  {
    cik: '0001650318',
    value: '0001650318,SCHOONER FOUNDATION',
    name: 'SCHOONER FOUNDATION',
    label: 'SCHOONER FOUNDATION',
  },
  {
    cik: '0001569258',
    value: '0001569258,SCHOONER INVESTMENT GROUP, LLC.',
    name: 'SCHOONER INVESTMENT GROUP, LLC.',
    label: 'SCHOONER INVESTMENT GROUP, LLC.',
  },
  {
    cik: '0001086619',
    value: '0001086619,SCHRODER INVESTMENT MANAGEMENT GROUP',
    name: 'SCHRODER INVESTMENT MANAGEMENT GROUP',
    label: 'SCHRODER INVESTMENT MANAGEMENT GROUP',
  },
  {
    cik: '0001706247',
    value: '0001706247,SCHRODERS IS LTD',
    name: 'SCHRODERS IS LTD',
    label: 'SCHRODERS IS LTD',
  },
  {
    cik: '0001914987',
    value: '0001914987,SCHRUM PRIVATE WEALTH MANAGEMENT LLC',
    name: 'SCHRUM PRIVATE WEALTH MANAGEMENT LLC',
    label: 'SCHRUM PRIVATE WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0001894830',
    value: '0001894830,SCHUBERT & CO',
    name: 'SCHUBERT & CO',
    label: 'SCHUBERT & CO',
  },
  {
    cik: '0001080197',
    value: '0001080197,SCHULHOFF & CO INC',
    name: 'SCHULHOFF & CO INC',
    label: 'SCHULHOFF & CO INC',
  },
  {
    cik: '0001857024',
    value: '0001857024,SCHUSTERMAN INTERESTS, LLC',
    name: 'SCHUSTERMAN INTERESTS, LLC',
    label: 'SCHUSTERMAN INTERESTS, LLC',
  },
  {
    cik: '0001177044',
    value: '0001177044,SCHUTZ ANTON VILLARS',
    name: 'SCHUTZ ANTON VILLARS',
    label: 'SCHUTZ ANTON VILLARS',
  },
  {
    cik: '0001535839',
    value: '0001535839,SCHWAB CHARITABLE FUND',
    name: 'SCHWAB CHARITABLE FUND',
    label: 'SCHWAB CHARITABLE FUND',
  },
  {
    cik: '0000884546',
    value: '0000884546,SCHWAB CHARLES INVESTMENT MANAGEMENT INC',
    name: 'SCHWAB CHARLES INVESTMENT MANAGEMENT INC',
    label: 'SCHWAB CHARLES INVESTMENT MANAGEMENT INC',
  },
  {
    cik: '0000909151',
    value: '0000909151,SCHWARTZ INVESTMENT COUNSEL INC',
    name: 'SCHWARTZ INVESTMENT COUNSEL INC',
    label: 'SCHWARTZ INVESTMENT COUNSEL INC',
  },
  {
    cik: '0001794153',
    value: '0001794153,SCHWARZ DYGOS WHEELER INVESTMENT ADVISORS LLC',
    name: 'SCHWARZ DYGOS WHEELER INVESTMENT ADVISORS LLC',
    label: 'SCHWARZ DYGOS WHEELER INVESTMENT ADVISORS LLC',
  },
  {
    cik: '0000937522',
    value: '0000937522,SCHWERIN BOYLE CAPITAL MANAGEMENT INC',
    name: 'SCHWERIN BOYLE CAPITAL MANAGEMENT INC',
    label: 'SCHWERIN BOYLE CAPITAL MANAGEMENT INC',
  },
  {
    cik: '0001653169',
    value: '0001653169,SCIENCAST MANAGEMENT LP',
    name: 'SCIENCAST MANAGEMENT LP',
    label: 'SCIENCAST MANAGEMENT LP',
  },
  {
    cik: '0001649339',
    value: '0001649339,SCION ASSET MANAGEMENT, LLC',
    name: 'SCION ASSET MANAGEMENT, LLC',
    label: 'SCION ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001611353',
    value: '0001611353,SC ISRAEL IV GENPAR, LTD.',
    name: 'SC ISRAEL IV GENPAR, LTD.',
    label: 'SC ISRAEL IV GENPAR, LTD.',
  },
  {
    cik: '0001611401',
    value: '0001611401,SC ISRAEL VENTURE V MANAGEMENT, L.P.',
    name: 'SC ISRAEL VENTURE V MANAGEMENT, L.P.',
    label: 'SC ISRAEL VENTURE V MANAGEMENT, L.P.',
  },
  {
    cik: '0001906793',
    value: '0001906793,SCISSORTAIL WEALTH MANAGEMENT, LLC',
    name: 'SCISSORTAIL WEALTH MANAGEMENT, LLC',
    label: 'SCISSORTAIL WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001086416',
    value: '0001086416,SCOGGIN LLC',
    name: 'SCOGGIN LLC',
    label: 'SCOGGIN LLC',
  },
  {
    cik: '0001086416',
    value: '0001086416,SCOGGIN MANAGEMENT LP',
    name: 'SCOGGIN MANAGEMENT LP',
    label: 'SCOGGIN MANAGEMENT LP',
  },
  {
    cik: '0001082461',
    value: '0001082,S&CO INC',
    name: 'S&CO INC',
    label: 'S&CO INC',
  },
  {
    cik: '0001279150',
    value: '0001279150,SCOPIA CAPITAL MANAGEMENT LLC',
    name: 'SCOPIA CAPITAL MANAGEMENT LLC',
    label: 'SCOPIA CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001279150',
    value: '0001279150,SCOPIA CAPITAL MANAGEMENT LP',
    name: 'SCOPIA CAPITAL MANAGEMENT LP',
    label: 'SCOPIA CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001317583',
    value: '0001317583,SCOPUS ASSET MANAGEMENT, L.P.',
    name: 'SCOPUS ASSET MANAGEMENT, L.P.',
    label: 'SCOPUS ASSET MANAGEMENT, L.P.',
  },
  {
    cik: '0001586560',
    value: '0001586560,SCORIA CAPITAL PARTNERS LP',
    name: 'SCORIA CAPITAL PARTNERS LP',
    label: 'SCORIA CAPITAL PARTNERS LP',
  },
  {
    cik: '0001335644',
    value: '0001335644,SCOTIA CAPITAL INC.',
    name: 'SCOTIA CAPITAL INC.',
    label: 'SCOTIA CAPITAL INC.',
  },
  {
    cik: '0001905673',
    value: '0001905673,SCOTT CAPITAL ADVISORS, LLC',
    name: 'SCOTT CAPITAL ADVISORS, LLC',
    label: 'SCOTT CAPITAL ADVISORS, LLC',
  },
  {
    cik: '0001841989',
    value: '0001841989,SCOTT INVESTMENT PARTNERS LLP',
    name: 'SCOTT INVESTMENT PARTNERS LLP',
    label: 'SCOTT INVESTMENT PARTNERS LLP',
  },
  {
    cik: '0001651646',
    value: '0001651646,SCOTTRADE INVESTMENT MANAGEMENT',
    name: 'SCOTTRADE INVESTMENT MANAGEMENT',
    label: 'SCOTTRADE INVESTMENT MANAGEMENT',
  },
  {
    cik: '0001315269',
    value: '0001315269,SCOTT & SELBER, INC.',
    name: 'SCOTT & SELBER, INC.',
    label: 'SCOTT & SELBER, INC.',
  },
  {
    cik: '0001134406',
    value: '0001134406,SCOUT CAPITAL MANAGEMENT LLC',
    name: 'SCOUT CAPITAL MANAGEMENT LLC',
    label: 'SCOUT CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001161927',
    value: '0001161927,SCOUT INVESTMENTS, INC.',
    name: 'SCOUT INVESTMENTS, INC.',
    label: 'SCOUT INVESTMENTS, INC.',
  },
  {
    cik: '0001474522',
    value: '0001474522,SCOUT TRADING, LLC',
    name: 'SCOUT TRADING, LLC',
    label: 'SCOUT TRADING, LLC',
  },
  {
    cik: '0001730961',
    value: '0001730961,SCP INVESTMENT, LP',
    name: 'SCP INVESTMENT, LP',
    label: 'SCP INVESTMENT, LP',
  },
  {
    cik: '0001303159',
    value: '0001303159,SCS CAPITAL MANAGEMENT LLC',
    name: 'SCS CAPITAL MANAGEMENT LLC',
    label: 'SCS CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001803397',
    value: '0001803397,SCULATI WEALTH MANAGEMENT, LLC',
    name: 'SCULATI WEALTH MANAGEMENT, LLC',
    label: 'SCULATI WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001054587',
    value: '0001054587,SCULPTOR CAPITAL LP',
    name: 'SCULPTOR CAPITAL LP',
    label: 'SCULPTOR CAPITAL LP',
  },
  {
    cik: '0001607841',
    value: '0001607841,SC US (TTGP), LTD.',
    name: 'SC US (TTGP), LTD.',
    label: 'SC US (TTGP), LTD.',
  },
  {
    cik: '0001738045',
    value: '0001738045,SCW CAPITAL MANAGEMENT, LP',
    name: 'SCW CAPITAL MANAGEMENT, LP',
    label: 'SCW CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0001374246',
    value: '0001374246,SC XII MANAGEMENT LLC',
    name: 'SC XII MANAGEMENT LLC',
    label: 'SC XII MANAGEMENT LLC',
  },
  {
    cik: '0001261135',
    value: '0001261135,SC XI MANAGEMENT LLC',
    name: 'SC XI MANAGEMENT LLC',
    label: 'SC XI MANAGEMENT LLC',
  },
  {
    cik: '0001206787',
    value: '0001206787,SC X MANAGEMENT LLC',
    name: 'SC X MANAGEMENT LLC',
    label: 'SC X MANAGEMENT LLC',
  },
  {
    cik: '0001304229',
    value: '0001304229,SEABRIDGE INVESTMENT ADVISORS LLC',
    name: 'SEABRIDGE INVESTMENT ADVISORS LLC',
    label: 'SEABRIDGE INVESTMENT ADVISORS LLC',
  },
  {
    cik: '0001422068',
    value: '0001422068,SEACLIFF CAPITAL, LLC',
    name: 'SEACLIFF CAPITAL, LLC',
    label: 'SEACLIFF CAPITAL, LLC',
  },
  {
    cik: '0001728121',
    value: '0001728121,SEACREST WEALTH MANAGEMENT, LLC',
    name: 'SEACREST WEALTH MANAGEMENT, LLC',
    label: 'SEACREST WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001697721',
    value: '0001697721,SEAFARER CAPITAL PARTNERS, LLC',
    name: 'SEAFARER CAPITAL PARTNERS, LLC',
    label: 'SEAFARER CAPITAL PARTNERS, LLC',
  },
  {
    cik: '0001795626',
    value: '0001795626,SEAHAWK CHINA DYNAMIC FUND',
    name: 'SEAHAWK CHINA DYNAMIC FUND',
    label: 'SEAHAWK CHINA DYNAMIC FUND',
  },
  {
    cik: '0001513101',
    value: '0001513101,SEAMANS CAPITAL MANAGEMENT, LLC',
    name: 'SEAMANS CAPITAL MANAGEMENT, LLC',
    label: 'SEAMANS CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001944662',
    value: '0001944662,SEA OTTER ADVISORS LLC',
    name: 'SEA OTTER ADVISORS LLC',
    label: 'SEA OTTER ADVISORS LLC',
  },
  {
    cik: '0001911018',
    value: '0001911018,SEAPORT GLOBAL ADVISORS, LLC',
    name: 'SEAPORT GLOBAL ADVISORS, LLC',
    label: 'SEAPORT GLOBAL ADVISORS, LLC',
  },
  {
    cik: '0001801142',
    value: '0001801142,SEAPORT GLOBAL ASSET MANAGEMENT LLC',
    name: 'SEAPORT GLOBAL ASSET MANAGEMENT LLC',
    label: 'SEAPORT GLOBAL ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001697030',
    value: '0001697030,SEARCHLIGHT CAPITAL PARTNERS, L.P.',
    name: 'SEARCHLIGHT CAPITAL PARTNERS, L.P.',
    label: 'SEARCHLIGHT CAPITAL PARTNERS, L.P.',
  },
  {
    cik: '0000354923',
    value: '0000354923,SEARCY FINANCIAL SERVICES INC /ADV',
    name: 'SEARCY FINANCIAL SERVICES INC /ADV',
    label: 'SEARCY FINANCIAL SERVICES INC /ADV',
  },
  {
    cik: '0000710127',
    value: '0000710127,SEARLE & CO.',
    name: 'SEARLE & CO.',
    label: 'SEARLE & CO.',
  },
  {
    cik: '0001418342',
    value: '0001418342,SEASCAPE CAPITAL MANAGEMENT',
    name: 'SEASCAPE CAPITAL MANAGEMENT',
    label: 'SEASCAPE CAPITAL MANAGEMENT',
  },
  {
    cik: '0001929977',
    value: '0001929977,SEASIDE WEALTH MANAGEMENT, INC.',
    name: 'SEASIDE WEALTH MANAGEMENT, INC.',
    label: 'SEASIDE WEALTH MANAGEMENT, INC.',
  },
  {
    cik: '0001552187',
    value: '0001552187,SEASTONE CAPITAL MANAGEMENT, L.P.',
    name: 'SEASTONE CAPITAL MANAGEMENT, L.P.',
    label: 'SEASTONE CAPITAL MANAGEMENT, L.P.',
  },
  {
    cik: '0001512367',
    value: '0001512367,SEATOWN HOLDINGS PTE. LTD.',
    name: 'SEATOWN HOLDINGS PTE. LTD.',
    label: 'SEATOWN HOLDINGS PTE. LTD.',
  },
  {
    cik: '0001832158',
    value: '0001832158,SEAVIEW INVESTMENT MANAGERS LLC',
    name: 'SEAVIEW INVESTMENT MANAGERS LLC',
    label: 'SEAVIEW INVESTMENT MANAGERS LLC',
  },
  {
    cik: '0001008929',
    value: '0001008929,SEAWARD MANAGEMENT LIMITED PARTNERSHIP',
    name: 'SEAWARD MANAGEMENT LIMITED PARTNERSHIP',
    label: 'SEAWARD MANAGEMENT LIMITED PARTNERSHIP',
  },
  {
    cik: '0001544825',
    value: '0001544825,SEAWOLF CAPITAL, LLC',
    name: 'SEAWOLF CAPITAL, LLC',
    label: 'SEAWOLF CAPITAL, LLC',
  },
  {
    cik: '0001136704',
    value: '0001136704,SECOND CURVE CAPITAL LLC',
    name: 'SECOND CURVE CAPITAL LLC',
    label: 'SECOND CURVE CAPITAL LLC',
  },
  {
    cik: '0001946654',
    value: '0001946654,SECOND HALF FINANCIAL PARTNERS, LLC',
    name: 'SECOND HALF FINANCIAL PARTNERS, LLC',
    label: 'SECOND HALF FINANCIAL PARTNERS, LLC',
  },
  {
    cik: '0001593191',
    value: '0001593191,SECOR CAPITAL ADVISORS, LP',
    name: 'SECOR CAPITAL ADVISORS, LP',
    label: 'SECOR CAPITAL ADVISORS, LP',
  },
  {
    cik: '0001730971',
    value: '0001730971,SECREST BLAKEY & ASSOCIATES, LLC',
    name: 'SECREST BLAKEY & ASSOCIATES, LLC',
    label: 'SECREST BLAKEY & ASSOCIATES, LLC',
  },
  {
    cik: '0001274413',
    value: '0001274413,SECTORAL ASSET MANAGEMENT INC',
    name: 'SECTORAL ASSET MANAGEMENT INC',
    label: 'SECTORAL ASSET MANAGEMENT INC',
  },
  {
    cik: '0001475373',
    value: '0001475373,SECTOR GAMMA AS',
    name: 'SECTOR GAMMA AS',
    label: 'SECTOR GAMMA AS',
  },
  {
    cik: '0001800692',
    value: '0001800692,SECURE ASSET MANAGEMENT, LLC',
    name: 'SECURE ASSET MANAGEMENT, LLC',
    label: 'SECURE ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001035350',
    value: '0001035350,SECURIAN ASSET MANAGEMENT, INC',
    name: 'SECURIAN ASSET MANAGEMENT, INC',
    label: 'SECURIAN ASSET MANAGEMENT, INC',
  },
  {
    cik: '0000899211',
    value: '0000899211,SECURITY ASSET MANAGEMENT',
    name: 'SECURITY ASSET MANAGEMENT',
    label: 'SECURITY ASSET MANAGEMENT',
  },
  {
    cik: '0000869772',
    value: '0000869772,SECURITY BENEFIT LIFE INSURANCE CO /KS/',
    name: 'SECURITY BENEFIT LIFE INSURANCE CO /KS/',
    label: 'SECURITY BENEFIT LIFE INSURANCE CO /KS/',
  },
  {
    cik: '0001068829',
    value: '0001068829,SECURITY CAPITAL RESEARCH & MANAGEMENT INC',
    name: 'SECURITY CAPITAL RESEARCH & MANAGEMENT INC',
    label: 'SECURITY CAPITAL RESEARCH & MANAGEMENT INC',
  },
  {
    cik: '0001913590',
    value: '0001913590,SECURITY FINANCIAL SERVICES, INC.',
    name: 'SECURITY FINANCIAL SERVICES, INC.',
    label: 'SECURITY FINANCIAL SERVICES, INC.',
  },
  {
    cik: '0001719739',
    value: '0001719739,SECURITY NATIONAL BANK',
    name: 'SECURITY NATIONAL BANK',
    label: 'SECURITY NATIONAL BANK',
  },
  {
    cik: '0000894309',
    value: '0000894309,SECURITY NATIONAL BANK OF SIOUX CITY IOWA /IA/',
    name: 'SECURITY NATIONAL BANK OF SIOUX CITY IOWA /IA/',
    label: 'SECURITY NATIONAL BANK OF SIOUX CITY IOWA /IA/',
  },
  {
    cik: '0001105909',
    value: '0001105909,SECURITY NATIONAL BANK OF SO DAK',
    name: 'SECURITY NATIONAL BANK OF SO DAK',
    label: 'SECURITY NATIONAL BANK OF SO DAK',
  },
  {
    cik: '0001158274',
    value: '0001158274,SECURITY NATIONAL TRUST CO',
    name: 'SECURITY NATIONAL TRUST CO',
    label: 'SECURITY NATIONAL TRUST CO',
  },
  {
    cik: '0001765515',
    value: '0001765515,S.E.E.D. PLANNING GROUP LLC',
    name: 'S.E.E.D. PLANNING GROUP LLC',
    label: 'S.E.E.D. PLANNING GROUP LLC',
  },
  {
    cik: '0001705655',
    value: '0001705655,SEELAUS ASSET MANAGEMENT LLC',
    name: 'SEELAUS ASSET MANAGEMENT LLC',
    label: 'SEELAUS ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001767840',
    value: '000176,SEEYOND',
    name: 'SEEYOND',
    label: 'SEEYOND',
  },
  {
    cik: '0001006378',
    value: '0001006378,SEGALL BRYANT & HAMILL',
    name: 'SEGALL BRYANT & HAMILL',
    label: 'SEGALL BRYANT & HAMILL',
  },
  {
    cik: '0001006378',
    value: '0001006378,SEGALL BRYANT & HAMILL, LLC',
    name: 'SEGALL BRYANT & HAMILL, LLC',
    label: 'SEGALL BRYANT & HAMILL, LLC',
  },
  {
    cik: '0001544676',
    value: '0001544676,SEGANTII CAPITAL MANAGEMENT LTD',
    name: 'SEGANTII CAPITAL MANAGEMENT LTD',
    label: 'SEGANTII CAPITAL MANAGEMENT LTD',
  },
  {
    cik: '0001575581',
    value: '0001575581,SEGMENT WEALTH MANAGEMENT, LLC',
    name: 'SEGMENT WEALTH MANAGEMENT, LLC',
    label: 'SEGMENT WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001607512',
    value: '0001607512,SEGRA CAPITAL MANAGEMENT, LLC',
    name: 'SEGRA CAPITAL MANAGEMENT, LLC',
    label: 'SEGRA CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001026081',
    value: '0001026081,SEIDMAN LAWRENCE B',
    name: 'SEIDMAN LAWRENCE B',
    label: 'SEIDMAN LAWRENCE B',
  },
  {
    cik: '0000350894',
    value: '0000350894,SEI INVESTMENTS CO',
    name: 'SEI INVESTMENTS CO',
    label: 'SEI INVESTMENTS CO',
  },
  {
    cik: '0001204692',
    value: '0001204692,SEI TRUST CO',
    name: 'SEI TRUST CO',
    label: 'SEI TRUST CO',
  },
  {
    cik: '0001134283',
    value: '0001134283,SEIZERT CAPITAL PARTNERS, LLC',
    name: 'SEIZERT CAPITAL PARTNERS, LLC',
    label: 'SEIZERT CAPITAL PARTNERS, LLC',
  },
  {
    cik: '0001867731',
    value: '0001867731,SELDON CAPITAL LP',
    name: 'SELDON CAPITAL LP',
    label: 'SELDON CAPITAL LP',
  },
  {
    cik: '0001600636',
    value: '0001600636,SELECT ASSET MANAGEMENT & TRUST',
    name: 'SELECT ASSET MANAGEMENT & TRUST',
    label: 'SELECT ASSET MANAGEMENT & TRUST',
  },
  {
    cik: '0001592643',
    value: '0001592643,SELECT EQUITY GROUP, L.P.',
    name: 'SELECT EQUITY GROUP, L.P.',
    label: 'SELECT EQUITY GROUP, L.P.',
  },
  {
    cik: '0001755670',
    value: '0001755670,SELECTIVE WEALTH MANAGEMENT',
    name: 'SELECTIVE WEALTH MANAGEMENT',
    label: 'SELECTIVE WEALTH MANAGEMENT',
  },
  {
    cik: '0001755670',
    value: '0001755670,SELECTIVE WEALTH MANAGEMENT, INC.',
    name: 'SELECTIVE WEALTH MANAGEMENT, INC.',
    label: 'SELECTIVE WEALTH MANAGEMENT, INC.',
  },
  {
    cik: '0001565432',
    value: '0001565432,SELKIRK MANAGEMENT LLC',
    name: 'SELKIRK MANAGEMENT LLC',
    label: 'SELKIRK MANAGEMENT LLC',
  },
  {
    cik: '0001080523',
    value: '0001080523,SELWAY ASSET MANAGEMENT',
    name: 'SELWAY ASSET MANAGEMENT',
    label: 'SELWAY ASSET MANAGEMENT',
  },
  {
    cik: '0001288125',
    value: '0001288125,SELZ CAPITAL LLC',
    name: 'SELZ CAPITAL LLC',
    label: 'SELZ CAPITAL LLC',
  },
  {
    cik: '0001361936',
    value: '0001361936,SEMINOLE MANAGEMENT CO., INC.',
    name: 'SEMINOLE MANAGEMENT CO., INC.',
    label: 'SEMINOLE MANAGEMENT CO., INC.',
  },
  {
    cik: '0001767301',
    value: '0001767301,SEMMAX FINANCIAL ADVISORS INC.',
    name: 'SEMMAX FINANCIAL ADVISORS INC.',
    label: 'SEMMAX FINANCIAL ADVISORS INC.',
  },
  {
    cik: '0001115373',
    value: '0001115373,SEMPER AUGUSTUS INVESTMENTS GROUP LLC',
    name: 'SEMPER AUGUSTUS INVESTMENTS GROUP LLC',
    label: 'SEMPER AUGUSTUS INVESTMENTS GROUP LLC',
  },
  {
    cik: '0001559538',
    value: '0001559538,SEMPER TRUST CO.',
    name: 'SEMPER TRUST CO.',
    label: 'SEMPER TRUST CO.',
  },
  {
    cik: '0001443689',
    value: '0001443689,SENATOR INVESTMENT GROUP LP',
    name: 'SENATOR INVESTMENT GROUP LP',
    label: 'SENATOR INVESTMENT GROUP LP',
  },
  {
    cik: '0001659380',
    value: '0001659380,SENDER CO & PARTNERS, INC.',
    name: 'SENDER CO & PARTNERS, INC.',
    label: 'SENDER CO & PARTNERS, INC.',
  },
  {
    cik: '0001639375',
    value: '0001639375,SENDERO WEALTH MANAGEMENT, LLC',
    name: 'SENDERO WEALTH MANAGEMENT, LLC',
    label: 'SENDERO WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001360505',
    value: '0001360505,SENECA CAPITAL INVESTMENTS, L.P.',
    name: 'SENECA CAPITAL INVESTMENTS, L.P.',
    label: 'SENECA CAPITAL INVESTMENTS, L.P.',
  },
  {
    cik: '0001731359',
    value: '0001731359,SENECA HOUSE ADVISORS',
    name: 'SENECA HOUSE ADVISORS',
    label: 'SENECA HOUSE ADVISORS',
  },
  {
    cik: '0001605826',
    value: '0001605826,SENSATO INVESTORS LLC',
    name: 'SENSATO INVESTORS LLC',
    label: 'SENSATO INVESTORS LLC',
  },
  {
    cik: '0001567738',
    value: '0001567738,SENSIBLE FINANCIAL PLANNING & MANAGEMENT, LLC.',
    name: 'SENSIBLE FINANCIAL PLANNING & MANAGEMENT, LLC.',
    label: 'SENSIBLE FINANCIAL PLANNING & MANAGEMENT, LLC.',
  },
  {
    cik: '0001093255',
    value: '0001093255,SENTINEL ASSET MANAGEMENT, INC.',
    name: 'SENTINEL ASSET MANAGEMENT, INC.',
    label: 'SENTINEL ASSET MANAGEMENT, INC.',
  },
  {
    cik: '0001802290',
    value: '0001802290,SENTINEL PENSION ADVISORS INC',
    name: 'SENTINEL PENSION ADVISORS INC',
    label: 'SENTINEL PENSION ADVISORS INC',
  },
  {
    cik: '0001114739',
    value: '0001114739,SENTINEL TRUST CO LBA',
    name: 'SENTINEL TRUST CO LBA',
    label: 'SENTINEL TRUST CO LBA',
  },
  {
    cik: '0001811345',
    value: '0001811345,SENTINUS, LLC',
    name: 'SENTINUS, LLC',
    label: 'SENTINUS, LLC',
  },
  {
    cik: '0000089014',
    value: '0000089014,SENTRY INVESTMENT MANAGEMENT INC',
    name: 'SENTRY INVESTMENT MANAGEMENT INC',
    label: 'SENTRY INVESTMENT MANAGEMENT INC',
  },
  {
    cik: '0000089014',
    value: '0000089014,SENTRY INVESTMENT MANAGEMENT LLC',
    name: 'SENTRY INVESTMENT MANAGEMENT LLC',
    label: 'SENTRY INVESTMENT MANAGEMENT LLC',
  },
  {
    cik: '0001430427',
    value: '0001430427,SENTRY INVESTMENTS CORP.',
    name: 'SENTRY INVESTMENTS CORP.',
    label: 'SENTRY INVESTMENTS CORP.',
  },
  {
    cik: '0001328785',
    value: '0001328785,SENVEST MANAGEMENT, LLC',
    name: 'SENVEST MANAGEMENT, LLC',
    label: 'SENVEST MANAGEMENT, LLC',
  },
  {
    cik: '0001579853',
    value: '0001579853,SENZAR ASSET MANAGEMENT, LLC',
    name: 'SENZAR ASSET MANAGEMENT, LLC',
    label: 'SENZAR ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001751412',
    value: '0001751412,SEPIO CAPITAL, LLC',
    name: 'SEPIO CAPITAL, LLC',
    label: 'SEPIO CAPITAL, LLC',
  },
  {
    cik: '0001751412',
    value: '0001751412,SEPIO CAPITAL, LP',
    name: 'SEPIO CAPITAL, LP',
    label: 'SEPIO CAPITAL, LP',
  },
  {
    cik: '0001509550',
    value: '0001509550,SEQUENT ASSET MANAGEMENT, LLC',
    name: 'SEQUENT ASSET MANAGEMENT, LLC',
    label: 'SEQUENT ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001822015',
    value: '0001822015,SEQUOIA CHINA EQUITY PARTNERS (HONG KONG) LTD',
    name: 'SEQUOIA CHINA EQUITY PARTNERS (HONG KONG) LTD',
    label: 'SEQUOIA CHINA EQUITY PARTNERS (HONG KONG) LTD',
  },
  {
    cik: '0001599900',
    value: '0001599900,SEQUOIA FINANCIAL ADVISORS, LLC',
    name: 'SEQUOIA FINANCIAL ADVISORS, LLC',
    label: 'SEQUOIA FINANCIAL ADVISORS, LLC',
  },
  {
    cik: '0001730811',
    value: '0001730811,SEQUOIA WEALTH MANAGEMENT, LLC',
    name: 'SEQUOIA WEALTH MANAGEMENT, LLC',
    label: 'SEQUOIA WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001420299',
    value: '0001420299,SERENGETI ASSET MANAGEMENT LP',
    name: 'SERENGETI ASSET MANAGEMENT LP',
    label: 'SERENGETI ASSET MANAGEMENT LP',
  },
  {
    cik: '0001706018',
    value: '0001706018,SERENITY CAPITAL LLC',
    name: 'SERENITY CAPITAL LLC',
    label: 'SERENITY CAPITAL LLC',
  },
  {
    cik: '0001662807',
    value: '0001662807,SERENITY CAPITAL MANAGEMENT, LTD',
    name: 'SERENITY CAPITAL MANAGEMENT, LTD',
    label: 'SERENITY CAPITAL MANAGEMENT, LTD',
  },
  {
    cik: '0001889663',
    value: '0001889663,SERENT CAPITAL MANAGEMENT CO LLC',
    name: 'SERENT CAPITAL MANAGEMENT CO LLC',
    label: 'SERENT CAPITAL MANAGEMENT CO LLC',
  },
  {
    cik: '0001595849',
    value: '0001595849,SESSA CAPITAL IM, L.P.',
    name: 'SESSA CAPITAL IM, L.P.',
    label: 'SESSA CAPITAL IM, L.P.',
  },
  {
    cik: '0001911113',
    value: '0001911113,SETTIAN CAPITAL LP',
    name: 'SETTIAN CAPITAL LP',
    label: 'SETTIAN CAPITAL LP',
  },
  {
    cik: '0001673954',
    value: '0001673954,SEVENBRIDGE FINANCIAL GROUP, LLC',
    name: 'SEVENBRIDGE FINANCIAL GROUP, LLC',
    label: 'SEVENBRIDGE FINANCIAL GROUP, LLC',
  },
  {
    cik: '0001632929',
    value: '0001632929,SEVEN BRIDGES ADVISORS LLC',
    name: 'SEVEN BRIDGES ADVISORS LLC',
    label: 'SEVEN BRIDGES ADVISORS LLC',
  },
  {
    cik: '0001575129',
    value: '0001575129,SEVEN EIGHT CAPITAL, LLC',
    name: 'SEVEN EIGHT CAPITAL, LLC',
    label: 'SEVEN EIGHT CAPITAL, LLC',
  },
  {
    cik: '0001575129',
    value: '0001575129,SEVEN EIGHT CAPITAL, LP',
    name: 'SEVEN EIGHT CAPITAL, LP',
    label: 'SEVEN EIGHT CAPITAL, LP',
  },
  {
    cik: '0001909126',
    value: '0001909126,SEVEN GRAND MANAGERS, LLC',
    name: 'SEVEN GRAND MANAGERS, LLC',
    label: 'SEVEN GRAND MANAGERS, LLC',
  },
  {
    cik: '0001648946',
    value: '0001648946,SEVEN HARBOUR GLOBAL, LP',
    name: 'SEVEN HARBOUR GLOBAL, LP',
    label: 'SEVEN HARBOUR GLOBAL, LP',
  },
  {
    cik: '0001599425',
    value: '0001599425,SEVEN LOCKS CAPITAL MANAGEMENT LP',
    name: 'SEVEN LOCKS CAPITAL MANAGEMENT LP',
    label: 'SEVEN LOCKS CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001821035',
    value: '0001821035,SEVENONESEVEN CAPITAL MANAGEMENT',
    name: 'SEVENONESEVEN CAPITAL MANAGEMENT',
    label: 'SEVENONESEVEN CAPITAL MANAGEMENT',
  },
  {
    cik: '0001554308',
    value: '0001554308,SEVEN POST INVESTMENT OFFICE LP',
    name: 'SEVEN POST INVESTMENT OFFICE LP',
    label: 'SEVEN POST INVESTMENT OFFICE LP',
  },
  {
    cik: '0001803898',
    value: '0001803898,SEVEN SPRINGS WEALTH GROUP, LLC',
    name: 'SEVEN SPRINGS WEALTH GROUP, LLC',
    label: 'SEVEN SPRINGS WEALTH GROUP, LLC',
  },
  {
    cik: '0001317784',
    value: '0001317784,SFE INVESTMENT COUNSEL',
    name: 'SFE INVESTMENT COUNSEL',
    label: 'SFE INVESTMENT COUNSEL',
  },
  {
    cik: '0001908914',
    value: '0001908914,SFG WEALTH MANAGEMENT, LLC.',
    name: 'SFG WEALTH MANAGEMENT, LLC.',
    label: 'SFG WEALTH MANAGEMENT, LLC.',
  },
  {
    cik: '0001911308',
    value: '0001911308,SFI ADVISORS, LLC',
    name: 'SFI ADVISORS, LLC',
    label: 'SFI ADVISORS, LLC',
  },
  {
    cik: '0001512026',
    value: '00015120,SFMG, LLC',
    name: 'SFMG, LLC',
    label: 'SFMG, LLC',
  },
  {
    cik: '0001630888',
    value: '0001630888,SG3 MANAGEMENT, LLC',
    name: 'SG3 MANAGEMENT, LLC',
    label: 'SG3 MANAGEMENT, LLC',
  },
  {
    cik: '0001313360',
    value: '0001313360,SG AMERICAS SECURITIES, LLC',
    name: 'SG AMERICAS SECURITIES, LLC',
    label: 'SG AMERICAS SECURITIES, LLC',
  },
  {
    cik: '0001510099',
    value: '0001510099,SG CAPITAL MANAGEMENT LLC',
    name: 'SG CAPITAL MANAGEMENT LLC',
    label: 'SG CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001568158',
    value: '0001568158,SG SELECT LLC',
    name: 'SG SELECT LLC',
    label: 'SG SELECT LLC',
  },
  {
    cik: '0001383838',
    value: '0001383838,SHAH CAPITAL MANAGEMENT',
    name: 'SHAH CAPITAL MANAGEMENT',
    label: 'SHAH CAPITAL MANAGEMENT',
  },
  {
    cik: '0001511794',
    value: '0001511794,SHAKER FINANCIAL SERVICES, LLC',
    name: 'SHAKER FINANCIAL SERVICES, LLC',
    label: 'SHAKER FINANCIAL SERVICES, LLC',
  },
  {
    cik: '0001009005',
    value: '0001009005,SHAKER INVESTMENTS LLC/OH',
    name: 'SHAKER INVESTMENTS LLC/OH',
    label: 'SHAKER INVESTMENTS LLC/OH',
  },
  {
    cik: '0001658509',
    value: '0001658509,SHAKESPEARE WEALTH MANAGEMENT, INC.',
    name: 'SHAKESPEARE WEALTH MANAGEMENT, INC.',
    label: 'SHAKESPEARE WEALTH MANAGEMENT, INC.',
  },
  {
    cik: '0001555909',
    value: '0001555909,SHAMROCK ASSET MANAGEMENT LLC',
    name: 'SHAMROCK ASSET MANAGEMENT LLC',
    label: 'SHAMROCK ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001622346',
    value: '0001622346,SHANDA ASSET MANAGEMENT HOLDINGS LTD',
    name: 'SHANDA ASSET MANAGEMENT HOLDINGS LTD',
    label: 'SHANDA ASSET MANAGEMENT HOLDINGS LTD',
  },
  {
    cik: '0001903273',
    value: '0001903273,SHANKERVALLEAU WEALTH ADVISORS, INC.',
    name: 'SHANKERVALLEAU WEALTH ADVISORS, INC.',
    label: 'SHANKERVALLEAU WEALTH ADVISORS, INC.',
  },
  {
    cik: '0001301050',
    value: '0001301050,SHANNON RIVER FUND MANAGEMENT LLC',
    name: 'SHANNON RIVER FUND MANAGEMENT LLC',
    label: 'SHANNON RIVER FUND MANAGEMENT LLC',
  },
  {
    cik: '0001766806',
    value: '0001766806,SHAOLIN CAPITAL MANAGEMENT LLC',
    name: 'SHAOLIN CAPITAL MANAGEMENT LLC',
    label: 'SHAOLIN CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001177244',
    value: '0001177244,SHAPIRO CAPITAL MANAGEMENT LLC',
    name: 'SHAPIRO CAPITAL MANAGEMENT LLC',
    label: 'SHAPIRO CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001008930',
    value: '0001008930,SHAPIRO ROBERT N',
    name: 'SHAPIRO ROBERT N',
    label: 'SHAPIRO ROBERT N',
  },
  {
    cik: '0001693672',
    value: '0001693672,SHARE ANDREW L.',
    name: 'SHARE ANDREW L.',
    label: 'SHARE ANDREW L.',
  },
  {
    cik: '0001642246',
    value: '0001642246,SHARKEY, HOWES & JAVER',
    name: 'SHARKEY, HOWES & JAVER',
    label: 'SHARKEY, HOWES & JAVER',
  },
  {
    cik: '0001818890',
    value: '0001818890,SHARP CAPITAL GESTORA DE RECURSOS LTDA.',
    name: 'SHARP CAPITAL GESTORA DE RECURSOS LTDA.',
    label: 'SHARP CAPITAL GESTORA DE RECURSOS LTDA.',
  },
  {
    cik: '0001959415',
    value: '0001959415,SHARPER & GRANITE LLC',
    name: 'SHARPER & GRANITE LLC',
    label: 'SHARPER & GRANITE LLC',
  },
  {
    cik: '0001766908',
    value: '0001766908,SHAWSPRING PARTNERS LLC',
    name: 'SHAWSPRING PARTNERS LLC',
    label: 'SHAWSPRING PARTNERS LLC',
  },
  {
    cik: '0001723643',
    value: '0001723643,SHAY CAPITAL LLC',
    name: 'SHAY CAPITAL LLC',
    label: 'SHAY CAPITAL LLC',
  },
  {
    cik: '0001484085',
    value: '0001484085,SHAYNE & CO., LLC',
    name: 'SHAYNE & CO., LLC',
    label: 'SHAYNE & CO., LLC',
  },
  {
    cik: '0001666789',
    value: '0001666789,SHEAFF BROCK INVESTMENT ADVISORS, LLC',
    name: 'SHEAFF BROCK INVESTMENT ADVISORS, LLC',
    label: 'SHEAFF BROCK INVESTMENT ADVISORS, LLC',
  },
  {
    cik: '0001910624',
    value: '0001910624,SHEARES HEALTHCARE GROUP PTE. LTD.',
    name: 'SHEARES HEALTHCARE GROUP PTE. LTD.',
    label: 'SHEARES HEALTHCARE GROUP PTE. LTD.',
  },
  {
    cik: '0001633680',
    value: '0001633680,SHEARLINK CAPITAL, LLC',
    name: 'SHEARLINK CAPITAL, LLC',
    label: 'SHEARLINK CAPITAL, LLC',
  },
  {
    cik: '0001905665',
    value: '0001905665,SHEARWATER CAPITAL LLC',
    name: 'SHEARWATER CAPITAL LLC',
    label: 'SHEARWATER CAPITAL LLC',
  },
  {
    cik: '0001785199',
    value: '0001785199,SHEETS SMITH INVESTMENT MANAGEMENT',
    name: 'SHEETS SMITH INVESTMENT MANAGEMENT',
    label: 'SHEETS SMITH INVESTMENT MANAGEMENT',
  },
  {
    cik: '0001157436',
    value: '0001157436,SHEETS SMITH WEALTH MANAGEMENT',
    name: 'SHEETS SMITH WEALTH MANAGEMENT',
    label: 'SHEETS SMITH WEALTH MANAGEMENT',
  },
  {
    cik: '0001314150',
    value: '0001314150,SHEFFIELD ASSET MANAGEMENT, L.L.C.',
    name: 'SHEFFIELD ASSET MANAGEMENT, L.L.C.',
    label: 'SHEFFIELD ASSET MANAGEMENT, L.L.C.',
  },
  {
    cik: '0001211028',
    value: '0001211028,SHELL ASSET MANAGEMENT CO',
    name: 'SHELL ASSET MANAGEMENT CO',
    label: 'SHELL ASSET MANAGEMENT CO',
  },
  {
    cik: '0001591744',
    value: '0001591744,SHELLBACK CAPITAL, LP',
    name: 'SHELLBACK CAPITAL, LP',
    label: 'SHELLBACK CAPITAL, LP',
  },
  {
    cik: '0001697803',
    value: '0001697803,SHELTER HARBOR ADVISORS, LP',
    name: 'SHELTER HARBOR ADVISORS, LP',
    label: 'SHELTER HARBOR ADVISORS, LP',
  },
  {
    cik: '0001757975',
    value: '0001757975,SHELTER HAVEN CAPITAL MANAGEMENT, L.P.',
    name: 'SHELTER HAVEN CAPITAL MANAGEMENT, L.P.',
    label: 'SHELTER HAVEN CAPITAL MANAGEMENT, L.P.',
  },
  {
    cik: '0001455289',
    value: '0001455289,SHELTER INS PROFIT SHARING',
    name: 'SHELTER INS PROFIT SHARING',
    label: 'SHELTER INS PROFIT SHARING',
  },
  {
    cik: '0001455290',
    value: '0001455290,SHELTER INS RETIREMENT PLAN',
    name: 'SHELTER INS RETIREMENT PLAN',
    label: 'SHELTER INS RETIREMENT PLAN',
  },
  {
    cik: '0001455288',
    value: '0001455288,SHELTER MUTUAL INSURANCE CO',
    name: 'SHELTER MUTUAL INSURANCE CO',
    label: 'SHELTER MUTUAL INSURANCE CO',
  },
  {
    cik: '0001002784',
    value: '0001002784,SHELTON CAPITAL MANAGEMENT',
    name: 'SHELTON CAPITAL MANAGEMENT',
    label: 'SHELTON CAPITAL MANAGEMENT',
  },
  {
    cik: '0001903883',
    value: '0001903883,SHELTON WEALTH MANAGEMENT, LLC',
    name: 'SHELTON WEALTH MANAGEMENT, LLC',
    label: 'SHELTON WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001275228',
    value: '0001275228,SHENKMAN CAPITAL MANAGEMENT INC',
    name: 'SHENKMAN CAPITAL MANAGEMENT INC',
    label: 'SHENKMAN CAPITAL MANAGEMENT INC',
  },
  {
    cik: '0001279329',
    value: '0001279329,SHEN NEIL NANPENG',
    name: 'SHEN NEIL NANPENG',
    label: 'SHEN NEIL NANPENG',
  },
  {
    cik: '0001696628',
    value: '0001696628,SHEPHERD FINANCIAL PARTNERS LLC',
    name: 'SHEPHERD FINANCIAL PARTNERS LLC',
    label: 'SHEPHERD FINANCIAL PARTNERS LLC',
  },
  {
    cik: '0001218583',
    value: '0001218583,SHEPHERD KAPLAN KROCHUK, LLC',
    name: 'SHEPHERD KAPLAN KROCHUK, LLC',
    label: 'SHEPHERD KAPLAN KROCHUK, LLC',
  },
  {
    cik: '0001803200',
    value: '0001803200,SHERPACAPITAL, LLC',
    name: 'SHERPACAPITAL, LLC',
    label: 'SHERPACAPITAL, LLC',
  },
  {
    cik: '0001058022',
    value: '0001058022,SHIKIAR ASSET MANAGEMENT INC',
    name: 'SHIKIAR ASSET MANAGEMENT INC',
    label: 'SHIKIAR ASSET MANAGEMENT INC',
  },
  {
    cik: '0001722084',
    value: '0001722084,SHILANSKI & ASSOCIATES, INC.',
    name: 'SHILANSKI & ASSOCIATES, INC.',
    label: 'SHILANSKI & ASSOCIATES, INC.',
  },
  {
    cik: '0001002912',
    value: '0001002912,SHINE INVESTMENT ADVISORY SERVICES INC',
    name: 'SHINE INVESTMENT ADVISORY SERVICES INC',
    label: 'SHINE INVESTMENT ADVISORY SERVICES INC',
  },
  {
    cik: '0001512440',
    value: '0001512440,SHINKO ASSET MANAGEMENT CO., LTD.',
    name: 'SHINKO ASSET MANAGEMENT CO., LTD.',
    label: 'SHINKO ASSET MANAGEMENT CO., LTD.',
  },
  {
    cik: '0001961210',
    value: '0001961210,SHIRA RIDGE WEALTH MANAGEMENT',
    name: 'SHIRA RIDGE WEALTH MANAGEMENT',
    label: 'SHIRA RIDGE WEALTH MANAGEMENT',
  },
  {
    cik: '0001727709',
    value: '0001727709,SHOALS CAPITAL MANAGEMENT LP',
    name: 'SHOALS CAPITAL MANAGEMENT LP',
    label: 'SHOALS CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001599623',
    value: '0001599623,SHOKER INVESTMENT COUNSEL, INC.',
    name: 'SHOKER INVESTMENT COUNSEL, INC.',
    label: 'SHOKER INVESTMENT COUNSEL, INC.',
  },
  {
    cik: '0001727270',
    value: '0001727270,SHONE ASSET MANAGEMENT LLC',
    name: 'SHONE ASSET MANAGEMENT LLC',
    label: 'SHONE ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001844922',
    value: '0001844922,SHOREHAVEN WEALTH PARTNERS, LLC',
    name: 'SHOREHAVEN WEALTH PARTNERS, LLC',
    label: 'SHOREHAVEN WEALTH PARTNERS, LLC',
  },
  {
    cik: '0001907092',
    value: '0001907092,SHORE POINT ADVISORS, LLC',
    name: 'SHORE POINT ADVISORS, LLC',
    label: 'SHORE POINT ADVISORS, LLC',
  },
  {
    cik: '0001759476',
    value: '0001759476,SHOREPOINT CAPITAL PARTNERS LLC',
    name: 'SHOREPOINT CAPITAL PARTNERS LLC',
    label: 'SHOREPOINT CAPITAL PARTNERS LLC',
  },
  {
    cik: '0000908195',
    value: '0000908195,SHUFRO ROSE & CO LLC',
    name: 'SHUFRO ROSE & CO LLC',
    label: 'SHUFRO ROSE & CO LLC',
  },
  {
    cik: '0001767070',
    value: '0001767070,SHULMAN DEMEO ASSET MANAGEMENT LLC',
    name: 'SHULMAN DEMEO ASSET MANAGEMENT LLC',
    label: 'SHULMAN DEMEO ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001426857',
    value: '000142,SIB LLC',
    name: 'SIB LLC',
    label: 'SIB LLC',
  },
  {
    cik: '0001696494',
    value: '0001696494,SICART ASSOCIATES LLC',
    name: 'SICART ASSOCIATES LLC',
    label: 'SICART ASSOCIATES LLC',
  },
  {
    cik: '0001856637',
    value: '0001856637,SIEMENS FONDS INVEST GMBH',
    name: 'SIEMENS FONDS INVEST GMBH',
    label: 'SIEMENS FONDS INVEST GMBH',
  },
  {
    cik: '0001913599',
    value: '0001913599,SIENA CAPITAL LLC',
    name: 'SIENA CAPITAL LLC',
    label: 'SIENA CAPITAL LLC',
  },
  {
    cik: '0001694886',
    value: '0001694886,SIENA CAPITAL MANAGEMENT, LLC',
    name: 'SIENA CAPITAL MANAGEMENT, LLC',
    label: 'SIENA CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001694886',
    value: '0001694886,SIENA CAPITAL PARTNERS GP, LLC',
    name: 'SIENA CAPITAL PARTNERS GP, LLC',
    label: 'SIENA CAPITAL PARTNERS GP, LLC',
  },
  {
    cik: '0001787274',
    value: '0001787274,SIERRA CAPITAL LLC',
    name: 'SIERRA CAPITAL LLC',
    label: 'SIERRA CAPITAL LLC',
  },
  {
    cik: '0001841827',
    value: '0001841827,SIG CHINA INVESTMENTS MASTER FUND III, LLLP',
    name: 'SIG CHINA INVESTMENTS MASTER FUND III, LLLP',
    label: 'SIG CHINA INVESTMENTS MASTER FUND III, LLLP',
  },
  {
    cik: '0001696957',
    value: '0001696957,SIGFIG WEALTH MANAGEMENT, LLC',
    name: 'SIGFIG WEALTH MANAGEMENT, LLC',
    label: 'SIGFIG WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001841979',
    value: '0001841979,SIGHTLINE WEALTH ADVISORS, LLC',
    name: 'SIGHTLINE WEALTH ADVISORS, LLC',
    label: 'SIGHTLINE WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001167507',
    value: '0001167507,SIGMA CAPITAL MANAGEMENT LLC',
    name: 'SIGMA CAPITAL MANAGEMENT LLC',
    label: 'SIGMA CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001085163',
    value: '0001085163,SIGMA INVESTMENT COUNSELORS INC',
    name: 'SIGMA INVESTMENT COUNSELORS INC',
    label: 'SIGMA INVESTMENT COUNSELORS INC',
  },
  {
    cik: '0001532618',
    value: '0001532618,SIGMA INVESTMENT MANAGEMENT CO',
    name: 'SIGMA INVESTMENT MANAGEMENT CO',
    label: 'SIGMA INVESTMENT MANAGEMENT CO',
  },
  {
    cik: '0001449126',
    value: '0001449126,SIGMA PLANNING CORP',
    name: 'SIGMA PLANNING CORP',
    label: 'SIGMA PLANNING CORP',
  },
  {
    cik: '0001566414',
    value: '0001566414,SIGNALPOINT ASSET MANAGEMENT, LLC',
    name: 'SIGNALPOINT ASSET MANAGEMENT, LLC',
    label: 'SIGNALPOINT ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001601928',
    value: '0001601928,SIGNATOR FINANCIAL SERVICES, INC.',
    name: 'SIGNATOR FINANCIAL SERVICES, INC.',
    label: 'SIGNATOR FINANCIAL SERVICES, INC.',
  },
  {
    cik: '0001079935',
    value: '0001079935,SIGNATURE ESTATE & INVESTMENT ADVISORS LLC',
    name: 'SIGNATURE ESTATE & INVESTMENT ADVISORS LLC',
    label: 'SIGNATURE ESTATE & INVESTMENT ADVISORS LLC',
  },
  {
    cik: '0001456048',
    value: '0001456048,SIGNATUREFD, LLC',
    name: 'SIGNATUREFD, LLC',
    label: 'SIGNATUREFD, LLC',
  },
  {
    cik: '0001542162',
    value: '0001542162,SIGNATURE FINANCIAL MANAGEMENT, INC.',
    name: 'SIGNATURE FINANCIAL MANAGEMENT, INC.',
    label: 'SIGNATURE FINANCIAL MANAGEMENT, INC.',
  },
  {
    cik: '0001117233',
    value: '0001117233,SIGNATURE SECURITIES GROUP CORPORATION',
    name: 'SIGNATURE SECURITIES GROUP CORPORATION',
    label: 'SIGNATURE SECURITIES GROUP CORPORATION',
  },
  {
    cik: '0001736666',
    value: '0001736666,SIGNATURE WEALTH MANAGEMENT GROUP',
    name: 'SIGNATURE WEALTH MANAGEMENT GROUP',
    label: 'SIGNATURE WEALTH MANAGEMENT GROUP',
  },
  {
    cik: '0001824694',
    value: '0001824694,SIGNATURE WEALTH MANAGEMENT PARTNERS, LLC',
    name: 'SIGNATURE WEALTH MANAGEMENT PARTNERS, LLC',
    label: 'SIGNATURE WEALTH MANAGEMENT PARTNERS, LLC',
  },
  {
    cik: '0001600307',
    value: '0001600307,SIGNET FINANCIAL MANAGEMENT, LLC',
    name: 'SIGNET FINANCIAL MANAGEMENT, LLC',
    label: 'SIGNET FINANCIAL MANAGEMENT, LLC',
  },
  {
    cik: '0001766328',
    value: '0001766328,SIGNET INVESTMENT ADVISORY GROUP, INC.',
    name: 'SIGNET INVESTMENT ADVISORY GROUP, INC.',
    label: 'SIGNET INVESTMENT ADVISORY GROUP, INC.',
  },
  {
    cik: '0001599475',
    value: '0001599475,SIGNET MANAGEMENT USA, LLC',
    name: 'SIGNET MANAGEMENT USA, LLC',
    label: 'SIGNET MANAGEMENT USA, LLC',
  },
  {
    cik: '0001131908',
    value: '0001131908,SIGNIA CAPITAL MANAGEMENT LLC',
    name: 'SIGNIA CAPITAL MANAGEMENT LLC',
    label: 'SIGNIA CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001769062',
    value: '0001769062,SIGNIFY WEALTH',
    name: 'SIGNIFY WEALTH',
    label: 'SIGNIFY WEALTH',
  },
  {
    cik: '0001663490',
    value: '0001663490,SIGNITION LP',
    name: 'SIGNITION LP',
    label: 'SIGNITION LP',
  },
  {
    cik: '0001868643',
    value: '0001868643,SIG NORTH TRADING, ULC',
    name: 'SIG NORTH TRADING, ULC',
    label: 'SIG NORTH TRADING, ULC',
  },
  {
    cik: '0001560812',
    value: '0001560812,SIGNPOST CAPITAL ADVISORS, LP',
    name: 'SIGNPOST CAPITAL ADVISORS, LP',
    label: 'SIGNPOST CAPITAL ADVISORS, LP',
  },
  {
    cik: '0001568814',
    value: '0001568814,SIGULER GUFF ADVISERS, LLC',
    name: 'SIGULER GUFF ADVISERS, LLC',
    label: 'SIGULER GUFF ADVISERS, LLC',
  },
  {
    cik: '0001845814',
    value: '0001845814,SIH MPH ROLLOVER GP, LLC',
    name: 'SIH MPH ROLLOVER GP, LLC',
    label: 'SIH MPH ROLLOVER GP, LLC',
  },
  {
    cik: '0001351903',
    value: '0001351903,SII INVESTMENTS INC/WI',
    name: 'SII INVESTMENTS INC/WI',
    label: 'SII INVESTMENTS INC/WI',
  },
  {
    cik: '0001506409',
    value: '0001506409,SILCHESTER INTERNATIONAL INVESTORS LLP',
    name: 'SILCHESTER INTERNATIONAL INVESTORS LLP',
    label: 'SILCHESTER INTERNATIONAL INVESTORS LLP',
  },
  {
    cik: '0001799054',
    value: '0001799054,SILICON HILLS WEALTH MANAGEMENT, LLC',
    name: 'SILICON HILLS WEALTH MANAGEMENT, LLC',
    label: 'SILICON HILLS WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001673633',
    value: '0001673633,SILICON VALLEY CAPITAL PARTNERS',
    name: 'SILICON VALLEY CAPITAL PARTNERS',
    label: 'SILICON VALLEY CAPITAL PARTNERS',
  },
  {
    cik: '0001738728',
    value: '0001738728,SILVANT CAPITAL MANAGEMENT LLC',
    name: 'SILVANT CAPITAL MANAGEMENT LLC',
    label: 'SILVANT CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001816307',
    value: '0001816307,SILVERARC CAPITAL MANAGEMENT, LLC',
    name: 'SILVERARC CAPITAL MANAGEMENT, LLC',
    label: 'SILVERARC CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001278960',
    value: '0001278960,SILVERBACK ASSET MANAGEMENT LLC',
    name: 'SILVERBACK ASSET MANAGEMENT LLC',
    label: 'SILVERBACK ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001140042',
    value: '0001140042,SILVER CAPITAL MANAGEMENT LLC',
    name: 'SILVER CAPITAL MANAGEMENT LLC',
    label: 'SILVER CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001232395',
    value: '0001232395,SILVERCREST ASSET MANAGEMENT GROUP LLC',
    name: 'SILVERCREST ASSET MANAGEMENT GROUP LLC',
    label: 'SILVERCREST ASSET MANAGEMENT GROUP LLC',
  },
  {
    cik: '0001910078',
    value: '0001910078,SILVERHAWK ASSET MANAGEMENT, LLC',
    name: 'SILVERHAWK ASSET MANAGEMENT, LLC',
    label: 'SILVERHAWK ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001705712',
    value: '0001705712,SILVER HEIGHTS CAPITAL MANAGEMENT INC',
    name: 'SILVER HEIGHTS CAPITAL MANAGEMENT INC',
    label: 'SILVER HEIGHTS CAPITAL MANAGEMENT INC',
  },
  {
    cik: '0001596055',
    value: '0001596055,SILVER LAKE ADVISORY, LLC',
    name: 'SILVER LAKE ADVISORY, LLC',
    label: 'SILVER LAKE ADVISORY, LLC',
  },
  {
    cik: '0001418226',
    value: '0001418226,SILVER LAKE GROUP, L.L.C.',
    name: 'SILVER LAKE GROUP, L.L.C.',
    label: 'SILVER LAKE GROUP, L.L.C.',
  },
  {
    cik: '0001535611',
    value: '0001535611,SILVERLEAFE CAPITAL PARTNERS, LLC',
    name: 'SILVERLEAFE CAPITAL PARTNERS, LLC',
    label: 'SILVERLEAFE CAPITAL PARTNERS, LLC',
  },
  {
    cik: '0001906937',
    value: '0001906937,SILVER OAK ADVISORY GROUP, INC.',
    name: 'SILVER OAK ADVISORY GROUP, INC.',
    label: 'SILVER OAK ADVISORY GROUP, INC.',
  },
  {
    cik: '0001080576',
    value: '0001080576,SILVER OAK SECURITIES, INC',
    name: 'SILVER OAK SECURITIES, INC',
    label: 'SILVER OAK SECURITIES, INC',
  },
  {
    cik: '0001080576',
    value: '0001080576,SILVER OAK SECURITIES, INCORPORATED',
    name: 'SILVER OAK SECURITIES, INCORPORATED',
    label: 'SILVER OAK SECURITIES, INCORPORATED',
  },
  {
    cik: '0001332784',
    value: '0001332784,SILVER POINT CAPITAL L.P.',
    name: 'SILVER POINT CAPITAL L.P.',
    label: 'SILVER POINT CAPITAL L.P.',
  },
  {
    cik: '0001599350',
    value: '0001599350,SILVER ROCK FINANCIAL LLC',
    name: 'SILVER ROCK FINANCIAL LLC',
    label: 'SILVER ROCK FINANCIAL LLC',
  },
  {
    cik: '0001674784',
    value: '0001674784,SILVER ROCK FINANCIAL LP',
    name: 'SILVER ROCK FINANCIAL LP',
    label: 'SILVER ROCK FINANCIAL LP',
  },
  {
    cik: '0001802437',
    value: '0001802437,SILVERSAGE ADVISORS',
    name: 'SILVERSAGE ADVISORS',
    label: 'SILVERSAGE ADVISORS',
  },
  {
    cik: '0001567982',
    value: '0001567982,SIMCAH MANAGEMENT LLC',
    name: 'SIMCAH MANAGEMENT LLC',
    label: 'SIMCAH MANAGEMENT LLC',
  },
  {
    cik: '0001633362',
    value: '0001633362,SIMCOE CAPITAL MANAGEMENT, LLC',
    name: 'SIMCOE CAPITAL MANAGEMENT, LLC',
    label: 'SIMCOE CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001674486',
    value: '0001674486,SIMMONS BANK',
    name: 'SIMMONS BANK',
    label: 'SIMMONS BANK',
  },
  {
    cik: '0001085338',
    value: '0001085338,SIMMONS FIRST TRUST CO NA',
    name: 'SIMMONS FIRST TRUST CO NA',
    label: 'SIMMONS FIRST TRUST CO NA',
  },
  {
    cik: '0001085550',
    value: '0001085550,SIMMONS L E & ASSOCIATES INC',
    name: 'SIMMONS L E & ASSOCIATES INC',
    label: 'SIMMONS L E & ASSOCIATES INC',
  },
  {
    cik: '0001673693',
    value: '0001673693,SIMONBAKER & PARTNERS LLC',
    name: 'SIMONBAKER & PARTNERS LLC',
    label: 'SIMONBAKER & PARTNERS LLC',
  },
  {
    cik: '0001599849',
    value: '0001599849,SIMON CHARITABLE PUBLIC LLC',
    name: 'SIMON CHARITABLE PUBLIC LLC',
    label: 'SIMON CHARITABLE PUBLIC LLC',
  },
  {
    cik: '0001063761',
    value: '0001063761,SIMON PROPERTY GROUP INC /DE/',
    name: 'SIMON PROPERTY GROUP INC /DE/',
    label: 'SIMON PROPERTY GROUP INC /DE/',
  },
  {
    cik: '0001692038',
    value: '0001692038,SIMON QUICK ADVISORS, LLC',
    name: 'SIMON QUICK ADVISORS, LLC',
    label: 'SIMON QUICK ADVISORS, LLC',
  },
  {
    cik: '0001488542',
    value: '0001488542,SIMPLEX TRADING, LLC',
    name: 'SIMPLEX TRADING, LLC',
    label: 'SIMPLEX TRADING, LLC',
  },
  {
    cik: '0001913243',
    value: '0001913243,SIMPLICITY WEALTH,LLC',
    name: 'SIMPLICITY WEALTH,LLC',
    label: 'SIMPLICITY WEALTH,LLC',
  },
  {
    cik: '0001716983',
    value: '0001716983,SIMPLIFI, INC.',
    name: 'SIMPLIFI, INC.',
    label: 'SIMPLIFI, INC.',
  },
  {
    cik: '0001846368',
    value: '0001846368,SIMPLIFY ASSET MANAGEMENT INC.',
    name: 'SIMPLIFY ASSET MANAGEMENT INC.',
    label: 'SIMPLIFY ASSET MANAGEMENT INC.',
  },
  {
    cik: '0001538900',
    value: '0001538900,SIMPLY MONEY ADVISORS',
    name: 'SIMPLY MONEY ADVISORS',
    label: 'SIMPLY MONEY ADVISORS',
  },
  {
    cik: '0001909816',
    value: '0001909816,SINECERA CAPITAL, LLC',
    name: 'SINECERA CAPITAL, LLC',
    label: 'SINECERA CAPITAL, LLC',
  },
  {
    cik: '0001219573',
    value: '0001219573,SINGAPORE TECHNOLOGIES TELEMEDIA PTE LTD',
    name: 'SINGAPORE TECHNOLOGIES TELEMEDIA PTE LTD',
    label: 'SINGAPORE TECHNOLOGIES TELEMEDIA PTE LTD',
  },
  {
    cik: '0001801184',
    value: '0001801184,SINGLE POINT PARTNERS, LLC',
    name: 'SINGLE POINT PARTNERS, LLC',
    label: 'SINGLE POINT PARTNERS, LLC',
  },
  {
    cik: '0001482416',
    value: '0001482416,SIO CAPITAL MANAGEMENT, LLC',
    name: 'SIO CAPITAL MANAGEMENT, LLC',
    label: 'SIO CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0000893273',
    value: '0000893273,SIPHRON CAPITAL MANAGEMENT /CA/',
    name: 'SIPHRON CAPITAL MANAGEMENT /CA/',
    label: 'SIPHRON CAPITAL MANAGEMENT /CA/',
  },
  {
    cik: '0001557406',
    value: '0001557406,SIPPICAN CAPITAL ADIVSORS',
    name: 'SIPPICAN CAPITAL ADIVSORS',
    label: 'SIPPICAN CAPITAL ADIVSORS',
  },
  {
    cik: '0001557406',
    value: '0001557406,SIPPICAN CAPITAL ADVISORS',
    name: 'SIPPICAN CAPITAL ADVISORS',
    label: 'SIPPICAN CAPITAL ADVISORS',
  },
  {
    cik: '0001434997',
    value: '0001434997,SIR CAPITAL MANAGEMENT, L.P.',
    name: 'SIR CAPITAL MANAGEMENT, L.P.',
    label: 'SIR CAPITAL MANAGEMENT, L.P.',
  },
  {
    cik: '0001104883',
    value: '0001104883,SIRIOS CAPITAL MANAGEMENT L P',
    name: 'SIRIOS CAPITAL MANAGEMENT L P',
    label: 'SIRIOS CAPITAL MANAGEMENT L P',
  },
  {
    cik: '0001705438',
    value: '0001705438,SIRIS CAPITAL GROUP, LLC',
    name: 'SIRIS CAPITAL GROUP, LLC',
    label: 'SIRIS CAPITAL GROUP, LLC',
  },
  {
    cik: '0000769317',
    value: '0000769317,SIT INVESTMENT ASSOCIATES INC',
    name: 'SIT INVESTMENT ASSOCIATES INC',
    label: 'SIT INVESTMENT ASSOCIATES INC',
  },
  {
    cik: '0001664771',
    value: '0001664771,SITRIN CAPITAL MANAGEMENT LLC',
    name: 'SITRIN CAPITAL MANAGEMENT LLC',
    label: 'SITRIN CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001846138',
    value: '0001846138,SITTNER & NELSON, LLC',
    name: 'SITTNER & NELSON, LLC',
    label: 'SITTNER & NELSON, LLC',
  },
  {
    cik: '0001596452',
    value: '0001596452,SIVIK GLOBAL HEALTHCARE LLC',
    name: 'SIVIK GLOBAL HEALTHCARE LLC',
    label: 'SIVIK GLOBAL HEALTHCARE LLC',
  },
  {
    cik: '0001794293',
    value: '0001794293,SIX COLUMNS CAPITAL, LP',
    name: 'SIX COLUMNS CAPITAL, LP',
    label: 'SIX COLUMNS CAPITAL, LP',
  },
  {
    cik: '0001812095',
    value: '0001812095,SIXTH STREET PARTNERS MANAGEMENT COMPANY, L.P.',
    name: 'SIXTH STREET PARTNERS MANAGEMENT COMPANY, L.P.',
    label: 'SIXTH STREET PARTNERS MANAGEMENT COMPANY, L.P.',
  },
  {
    cik: '0001697024',
    value: '0001697024,SJA FINANCIAL ADVISORY, LLC',
    name: 'SJA FINANCIAL ADVISORY, LLC',
    label: 'SJA FINANCIAL ADVISORY, LLC',
  },
  {
    cik: '0001894302',
    value: '0001894302,SJS INVESTMENT CONSULTING INC.',
    name: 'SJS INVESTMENT CONSULTING INC.',
    label: 'SJS INVESTMENT CONSULTING INC.',
  },
  {
    cik: '0001860998',
    value: '0001860998,SKAANA MANAGEMENT L.P.',
    name: 'SKAANA MANAGEMENT L.P.',
    label: 'SKAANA MANAGEMENT L.P.',
  },
  {
    cik: '0001534653',
    value: '0001534653,SKANDINAVISKA ENSKILDA BANKEN AB (PUBL)',
    name: 'SKANDINAVISKA ENSKILDA BANKEN AB (PUBL)',
    label: 'SKANDINAVISKA ENSKILDA BANKEN AB (PUBL)',
  },
  {
    cik: '0000932024',
    value: '0000932024,SKBA CAPITAL MANAGEMENT LLC',
    name: 'SKBA CAPITAL MANAGEMENT LLC',
    label: 'SKBA CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001869333',
    value: '0001869333,SKORPIOS TRUST',
    name: 'SKORPIOS TRUST',
    label: 'SKORPIOS TRUST',
  },
  {
    cik: '0001639668',
    value: '0001639668,SKYBRIDGE CAPITAL II, LLC',
    name: 'SKYBRIDGE CAPITAL II, LLC',
    label: 'SKYBRIDGE CAPITAL II, LLC',
  },
  {
    cik: '0001675884',
    value: '0001675884,SKYE GLOBAL MANAGEMENT LP',
    name: 'SKYE GLOBAL MANAGEMENT LP',
    label: 'SKYE GLOBAL MANAGEMENT LP',
  },
  {
    cik: '0001353968',
    value: '0001353968,SKY INVESTMENT COUNSEL INC',
    name: 'SKY INVESTMENT COUNSEL INC',
    label: 'SKY INVESTMENT COUNSEL INC',
  },
  {
    cik: '0001333792',
    value: '0001333792,SKY INVESTMENT GROUP LLC',
    name: 'SKY INVESTMENT GROUP LLC',
    label: 'SKY INVESTMENT GROUP LLC',
  },
  {
    cik: '0001842707',
    value: '0001842707,SKYKNIGHT CAPITAL, L.P.',
    name: 'SKYKNIGHT CAPITAL, L.P.',
    label: 'SKYKNIGHT CAPITAL, L.P.',
  },
  {
    cik: '0001299910',
    value: '0001299910,SKYLANDS CAPITAL, LLC',
    name: 'SKYLANDS CAPITAL, LLC',
    label: 'SKYLANDS CAPITAL, LLC',
  },
  {
    cik: '0001024716',
    value: '0001024716,SKYLINE ASSET MANAGEMENT LP',
    name: 'SKYLINE ASSET MANAGEMENT LP',
    label: 'SKYLINE ASSET MANAGEMENT LP',
  },
  {
    cik: '0001800379',
    value: '0001800379,SKYOAK WEALTH, LLC',
    name: 'SKYOAK WEALTH, LLC',
    label: 'SKYOAK WEALTH, LLC',
  },
  {
    cik: '0001800379',
    value: '0001800379,SKYOAK WEALT, LLC',
    name: 'SKYOAK WEALT, LLC',
    label: 'SKYOAK WEALT, LLC',
  },
  {
    cik: '0001512690',
    value: '0001512690,SKYTOP CAPITAL MANAGEMENT LLC',
    name: 'SKYTOP CAPITAL MANAGEMENT LLC',
    label: 'SKYTOP CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001848237',
    value: '0001848237,SKYVIEW INVESTMENT ADVISORS, LLC',
    name: 'SKYVIEW INVESTMENT ADVISORS, LLC',
    label: 'SKYVIEW INVESTMENT ADVISORS, LLC',
  },
  {
    cik: '0001680365',
    value: '0001680365,SL ADVISORS, LLC',
    name: 'SL ADVISORS, LLC',
    label: 'SL ADVISORS, LLC',
  },
  {
    cik: '0001559706',
    value: '0001559706,SLATE PATH CAPITAL LP',
    name: 'SLATE PATH CAPITAL LP',
    label: 'SLATE PATH CAPITAL LP',
  },
  {
    cik: '0001764049',
    value: '0001764049,SLATESTONE WEALTH, LLC',
    name: 'SLATESTONE WEALTH, LLC',
    label: 'SLATESTONE WEALTH, LLC',
  },
  {
    cik: '0001384801',
    value: '0001384801,SLEEP, ZAKARIA & CO LTD.',
    name: 'SLEEP, ZAKARIA & CO LTD.',
    label: 'SLEEP, ZAKARIA & CO LTD.',
  },
  {
    cik: '0001415293',
    value: '0001415293,SLOANE ROBINSON LLP',
    name: 'SLOANE ROBINSON LLP',
    label: 'SLOANE ROBINSON LLP',
  },
  {
    cik: '0001716984',
    value: '0001716984,SLOW CAPITAL, INC.',
    name: 'SLOW CAPITAL, INC.',
    label: 'SLOW CAPITAL, INC.',
  },
  {
    cik: '0001690295',
    value: '0001690295,SLOY DAHL & HOLST, LLC',
    name: 'SLOY DAHL & HOLST, LLC',
    label: 'SLOY DAHL & HOLST, LLC',
  },
  {
    cik: '0001093060',
    value: '0001093060,SLS MANAGEMENT LLC',
    name: 'SLS MANAGEMENT LLC',
    label: 'SLS MANAGEMENT LLC',
  },
  {
    cik: '0001330833',
    value: '0001330833,SMALL CAP VALUE FUND, INC.',
    name: 'SMALL CAP VALUE FUND, INC.',
    label: 'SMALL CAP VALUE FUND, INC.',
  },
  {
    cik: '0001921304',
    value: '0001921304,SMALLWOOD WEALTH INVESTMENT MANAGEMENT, LLC',
    name: 'SMALLWOOD WEALTH INVESTMENT MANAGEMENT, LLC',
    label: 'SMALLWOOD WEALTH INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001728866',
    value: '0001728866,SMART MONEY GROUP LLC',
    name: 'SMART MONEY GROUP LLC',
    label: 'SMART MONEY GROUP LLC',
  },
  {
    cik: '0001513227',
    value: '0001513227,SMART PORTFOLIOS, LLC',
    name: 'SMART PORTFOLIOS, LLC',
    label: 'SMART PORTFOLIOS, LLC',
  },
  {
    cik: '0001427008',
    value: '0001427008,SMEAD CAPITAL MANAGEMENT, INC.',
    name: 'SMEAD CAPITAL MANAGEMENT, INC.',
    label: 'SMEAD CAPITAL MANAGEMENT, INC.',
  },
  {
    cik: '0001180391',
    value: '0001180391,SMH CAPITAL ADVISORS INC',
    name: 'SMH CAPITAL ADVISORS INC',
    label: 'SMH CAPITAL ADVISORS INC',
  },
  {
    cik: '0001609008',
    value: '0001609008,SMI ADVISORY SERVICES, LLC',
    name: 'SMI ADVISORY SERVICES, LLC',
    label: 'SMI ADVISORY SERVICES, LLC',
  },
  {
    cik: '0001540138',
    value: '0001540138,SMITH AFFILIATED CAPITAL CORP.',
    name: 'SMITH AFFILIATED CAPITAL CORP.',
    label: 'SMITH AFFILIATED CAPITAL CORP.',
  },
  {
    cik: '0001553540',
    value: '0001553540,SMITH ANGLIN FINANCIAL, LLC',
    name: 'SMITH ANGLIN FINANCIAL, LLC',
    label: 'SMITH ANGLIN FINANCIAL, LLC',
  },
  {
    cik: '0001091561',
    value: '0001091561,SMITH ASSET MANAGEMENT GROUP LP',
    name: 'SMITH ASSET MANAGEMENT GROUP LP',
    label: 'SMITH ASSET MANAGEMENT GROUP LP',
  },
  {
    cik: '0001103245',
    value: '0001103245,SMITHBRIDGE ASSET MANAGEMENT INC/DE',
    name: 'SMITHBRIDGE ASSET MANAGEMENT INC/DE',
    label: 'SMITHBRIDGE ASSET MANAGEMENT INC/DE',
  },
  {
    cik: '0000747559',
    value: '0000747559,SMITH CAPITAL MANAGEMENT INC /AR/ADV',
    name: 'SMITH CAPITAL MANAGEMENT INC /AR/ADV',
    label: 'SMITH CAPITAL MANAGEMENT INC /AR/ADV',
  },
  {
    cik: '0001082509',
    value: '0001082509,SMITH CHAS P & ASSOCIATES PA CPAS',
    name: 'SMITH CHAS P & ASSOCIATES PA CPAS',
    label: 'SMITH CHAS P & ASSOCIATES PA CPAS',
  },
  {
    cik: '0001019754',
    value: '0001019754,SMITHFIELD TRUST CO',
    name: 'SMITHFIELD TRUST CO',
    label: 'SMITHFIELD TRUST CO',
  },
  {
    cik: '0001470414',
    value: '0001470414,SMITH, GRAHAM & CO., INVESTMENT ADVISORS, LP',
    name: 'SMITH, GRAHAM & CO., INVESTMENT ADVISORS, LP',
    label: 'SMITH, GRAHAM & CO., INVESTMENT ADVISORS, LP',
  },
  {
    cik: '0001893134',
    value: '0001893134,SMITH GROUP ASSET MANAGEMENT, LLC',
    name: 'SMITH GROUP ASSET MANAGEMENT, LLC',
    label: 'SMITH GROUP ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001418870',
    value: '0001418870,SMITH HAYES ADVISERS, INC',
    name: 'SMITH HAYES ADVISERS, INC',
    label: 'SMITH HAYES ADVISERS, INC',
  },
  {
    cik: '0001543991',
    value: '0001543991,SMITH & HOWARD WEALTH MANAGEMENT, LLC',
    name: 'SMITH & HOWARD WEALTH MANAGEMENT, LLC',
    label: 'SMITH & HOWARD WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0000200724',
    value: '0000200724,SMITH, MOORE & CO.',
    name: 'SMITH, MOORE & CO.',
    label: 'SMITH, MOORE & CO.',
  },
  {
    cik: '0001482012',
    value: '0001482012,SMITH, SALLEY & ASSOCIATES',
    name: 'SMITH, SALLEY & ASSOCIATES',
    label: 'SMITH, SALLEY & ASSOCIATES',
  },
  {
    cik: '0000944317',
    value: '0000944317,SMITH SHELLNUT WILSON LLC /ADV',
    name: 'SMITH SHELLNUT WILSON LLC /ADV',
    label: 'SMITH SHELLNUT WILSON LLC /ADV',
  },
  {
    cik: '0000926688',
    value: '0000926688,SMITH THOMAS W',
    name: 'SMITH THOMAS W',
    label: 'SMITH THOMAS W',
  },
  {
    cik: '0001362178',
    value: '0001362178,SMITHWOOD ADVISERS, L.P.',
    name: 'SMITHWOOD ADVISERS, L.P.',
    label: 'SMITHWOOD ADVISERS, L.P.',
  },
  {
    cik: '0001138996',
    value: '0001138996,S. MUOIO & CO. LLC',
    name: 'S. MUOIO & CO. LLC',
    label: 'S. MUOIO & CO. LLC',
  },
  {
    cik: '0001820681',
    value: '0001820681,SNIDER FINANCIAL GROUP',
    name: 'SNIDER FINANCIAL GROUP',
    label: 'SNIDER FINANCIAL GROUP',
  },
  {
    cik: '0001262124',
    value: '0001262124,SNOW CAPITAL MANAGEMENT LP',
    name: 'SNOW CAPITAL MANAGEMENT LP',
    label: 'SNOW CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001903055',
    value: '0001903055,SNOWDEN CAPITAL ADVISORS LLC',
    name: 'SNOWDEN CAPITAL ADVISORS LLC',
    label: 'SNOWDEN CAPITAL ADVISORS LLC',
  },
  {
    cik: '0001849613',
    value: '0001849613,SNOWHOOK CAPITAL MANAGEMENT LP',
    name: 'SNOWHOOK CAPITAL MANAGEMENT LP',
    label: 'SNOWHOOK CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001565316',
    value: '0001565316,SNOW LAKE CAPITAL (HK) LTD',
    name: 'SNOW LAKE CAPITAL (HK) LTD',
    label: 'SNOW LAKE CAPITAL (HK) LTD',
  },
  {
    cik: '0001731416',
    value: '0001731416,SNOW PARK CAPITAL PARTNERS, LP',
    name: 'SNOW PARK CAPITAL PARTNERS, LP',
    label: 'SNOW PARK CAPITAL PARTNERS, LP',
  },
  {
    cik: '0001698661',
    value: '0001698661,SNOW PHIPPS GROUP, LLC',
    name: 'SNOW PHIPPS GROUP, LLC',
    label: 'SNOW PHIPPS GROUP, LLC',
  },
  {
    cik: '0001557485',
    value: '0001557485,SNS FINANCIAL GROUP, LLC',
    name: 'SNS FINANCIAL GROUP, LLC',
    label: 'SNS FINANCIAL GROUP, LLC',
  },
  {
    cik: '0001050477',
    value: '0001050477,SNYDER CAPITAL MANAGEMENT L P',
    name: 'SNYDER CAPITAL MANAGEMENT L P',
    label: 'SNYDER CAPITAL MANAGEMENT L P',
  },
  {
    cik: '0001726948',
    value: '0001726948,SOAPSTONE MANAGEMENT L.P.',
    name: 'SOAPSTONE MANAGEMENT L.P.',
    label: 'SOAPSTONE MANAGEMENT L.P.',
  },
  {
    cik: '0001849444',
    value: '0001849444,SOA WEALTH ADVISORS, LLC.',
    name: 'SOA WEALTH ADVISORS, LLC.',
    label: 'SOA WEALTH ADVISORS, LLC.',
  },
  {
    cik: '0001682274',
    value: '0001682274,SOCIAL CAPITAL PEP MANAGEMENT LLC',
    name: 'SOCIAL CAPITAL PEP MANAGEMENT LLC',
    label: 'SOCIAL CAPITAL PEP MANAGEMENT LLC',
  },
  {
    cik: '0000865845',
    value: '0000865845,SOCIETE GENERALE',
    name: 'SOCIETE GENERALE',
    label: 'SOCIETE GENERALE',
  },
  {
    cik: '0001781882',
    value: '0001781882,SOCORRO ASSET MANAGEMENT LP',
    name: 'SOCORRO ASSET MANAGEMENT LP',
    label: 'SOCORRO ASSET MANAGEMENT LP',
  },
  {
    cik: '0001353141',
    value: '0001353141,SOCRATIC FUND MANAGEMENT, L.P.',
    name: 'SOCRATIC FUND MANAGEMENT, L.P.',
    label: 'SOCRATIC FUND MANAGEMENT, L.P.',
  },
  {
    cik: '0001852688',
    value: '0001852688,SODITIC ASSET MANAGEMENT LLP',
    name: 'SODITIC ASSET MANAGEMENT LLP',
    label: 'SODITIC ASSET MANAGEMENT LLP',
  },
  {
    cik: '0001631134',
    value: '0001631134,SOFINNOVA INVESTMENTS, INC.',
    name: 'SOFINNOVA INVESTMENTS, INC.',
    label: 'SOFINNOVA INVESTMENTS, INC.',
  },
  {
    cik: '0001559565',
    value: '0001559565,SOFINNOVA MANAGEMENT VIII, L.L.C.',
    name: 'SOFINNOVA MANAGEMENT VIII, L.L.C.',
    label: 'SOFINNOVA MANAGEMENT VIII, L.L.C.',
  },
  {
    cik: '0001631134',
    value: '0001631134,SOFINNOVA VENTURES INC',
    name: 'SOFINNOVA VENTURES INC',
    label: 'SOFINNOVA VENTURES INC',
  },
  {
    cik: '0001925582',
    value: '0001925582,SOFI WEALTH, LLC',
    name: 'SOFI WEALTH, LLC',
    label: 'SOFI WEALTH, LLC',
  },
  {
    cik: '0001697569',
    value: '000169,SOF LTD',
    name: 'SOF LTD',
    label: 'SOF LTD',
  },
  {
    cik: '0001741736',
    value: '0001741736,SOFOS INVESTMENTS, INC.',
    name: 'SOFOS INVESTMENTS, INC.',
    label: 'SOFOS INVESTMENTS, INC.',
  },
  {
    cik: '0001065521',
    value: '0001065521,SOFTBANK GROUP CORP',
    name: 'SOFTBANK GROUP CORP',
    label: 'SOFTBANK GROUP CORP',
  },
  {
    cik: '0001803391',
    value: '0001803391,SOFTVEST ADVISORS, LLC',
    name: 'SOFTVEST ADVISORS, LLC',
    label: 'SOFTVEST ADVISORS, LLC',
  },
  {
    cik: '0001364739',
    value: '0001364739,SOLARIS ASSET MANAGEMENT LLC',
    name: 'SOLARIS ASSET MANAGEMENT LLC',
    label: 'SOLARIS ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001604867',
    value: '0001604867,SOLAS CAPITAL MANAGEMENT, LLC',
    name: 'SOLAS CAPITAL MANAGEMENT, LLC',
    label: 'SOLAS CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001339270',
    value: '0001339270,SOL CAPITAL MANAGEMENT CO',
    name: 'SOL CAPITAL MANAGEMENT CO',
    label: 'SOL CAPITAL MANAGEMENT CO',
  },
  {
    cik: '0001790604',
    value: '0001790604,SOLEL PARTNERS LP',
    name: 'SOLEL PARTNERS LP',
    label: 'SOLEL PARTNERS LP',
  },
  {
    cik: '0001802630',
    value: '0001802630,SOLEUS CAPITAL MANAGEMENT, L.P.',
    name: 'SOLEUS CAPITAL MANAGEMENT, L.P.',
    label: 'SOLEUS CAPITAL MANAGEMENT, L.P.',
  },
  {
    cik: '0001906613',
    value: '0001906613,SOLIDARILTY WEALTH, LLC',
    name: 'SOLIDARILTY WEALTH, LLC',
    label: 'SOLIDARILTY WEALTH, LLC',
  },
  {
    cik: '0001839890',
    value: '0001839890,SOLITUDE FINANCIAL SERVICES',
    name: 'SOLITUDE FINANCIAL SERVICES',
    label: 'SOLITUDE FINANCIAL SERVICES',
  },
  {
    cik: '0001730769',
    value: '0001730769,SOLSTEIN CAPITAL, LLC',
    name: 'SOLSTEIN CAPITAL, LLC',
    label: 'SOLSTEIN CAPITAL, LLC',
  },
  {
    cik: '0001802530',
    value: '0001802530,SOLTIS INVESTMENT ADVISORS LLC',
    name: 'SOLTIS INVESTMENT ADVISORS LLC',
    label: 'SOLTIS INVESTMENT ADVISORS LLC',
  },
  {
    cik: '0001407737',
    value: '0001407737,SOLUS ALTERNATIVE ASSET MANAGEMENT LP',
    name: 'SOLUS ALTERNATIVE ASSET MANAGEMENT LP',
    label: 'SOLUS ALTERNATIVE ASSET MANAGEMENT LP',
  },
  {
    cik: '0001680964',
    value: '0001680964,SOMA EQUITY PARTNERS LP',
    name: 'SOMA EQUITY PARTNERS LP',
    label: 'SOMA EQUITY PARTNERS LP',
  },
  {
    cik: '0001616009',
    value: '0001616009,SOMERSET CAPITAL MANAGEMENT LLP',
    name: 'SOMERSET CAPITAL MANAGEMENT LLP',
    label: 'SOMERSET CAPITAL MANAGEMENT LLP',
  },
  {
    cik: '0001567912',
    value: '0001567912,SOMERSET GROUP LLC',
    name: 'SOMERSET GROUP LLC',
    label: 'SOMERSET GROUP LLC',
  },
  {
    cik: '0001597099',
    value: '0001597099,SOMERSET TRUST CO',
    name: 'SOMERSET TRUST CO',
    label: 'SOMERSET TRUST CO',
  },
  {
    cik: '0001215208',
    value: '0001215208,SOMERVILLE KURT F',
    name: 'SOMERVILLE KURT F',
    label: 'SOMERVILLE KURT F',
  },
  {
    cik: '0001533504',
    value: '0001533504,SOMPO ASSET MANAGEMENT CO., LTD.',
    name: 'SOMPO ASSET MANAGEMENT CO., LTD.',
    label: 'SOMPO ASSET MANAGEMENT CO., LTD.',
  },
  {
    cik: '0001856405',
    value: '0001856405,SONA ASSET MANAGEMENT (US) LLC',
    name: 'SONA ASSET MANAGEMENT (US) LLC',
    label: 'SONA ASSET MANAGEMENT (US) LLC',
  },
  {
    cik: '0001172036',
    value: '0001172036,SONATA CAPITAL GROUP INC',
    name: 'SONATA CAPITAL GROUP INC',
    label: 'SONATA CAPITAL GROUP INC',
  },
  {
    cik: '0001557787',
    value: '0001557787,SONEN CAPITAL LLC',
    name: 'SONEN CAPITAL LLC',
    label: 'SONEN CAPITAL LLC',
  },
  {
    cik: '0001771377',
    value: '0001771377,SONIC FUND II, L.P.',
    name: 'SONIC FUND II, L.P.',
    label: 'SONIC FUND II, L.P.',
  },
  {
    cik: '0001907552',
    value: '0001907552,SONORA INVESTMENT MANAGEMENT GROUP, LLC',
    name: 'SONORA INVESTMENT MANAGEMENT GROUP, LLC',
    label: 'SONORA INVESTMENT MANAGEMENT GROUP, LLC',
  },
  {
    cik: '0001418539',
    value: '0001418539,SONORA INVESTMENT MANAGEMENT, LLC',
    name: 'SONORA INVESTMENT MANAGEMENT, LLC',
    label: 'SONORA INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001646247',
    value: '0001646247,SONTAG ADVISORY, LLC',
    name: 'SONTAG ADVISORY, LLC',
    label: 'SONTAG ADVISORY, LLC',
  },
  {
    cik: '0001507758',
    value: '0001507758,SOPHROSYNE CAPITAL LLC',
    name: 'SOPHROSYNE CAPITAL LLC',
    label: 'SOPHROSYNE CAPITAL LLC',
  },
  {
    cik: '0001894532',
    value: '0001894532,SORA INVESTORS LLC',
    name: 'SORA INVESTORS LLC',
    label: 'SORA INVESTORS LLC',
  },
  {
    cik: '0001534498',
    value: '0001534498,SORIN CAPITAL MANAGEMENT LLC',
    name: 'SORIN CAPITAL MANAGEMENT LLC',
    label: 'SORIN CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001517857',
    value: '0001517857,SOROBAN CAPITAL PARTNERS LLC',
    name: 'SOROBAN CAPITAL PARTNERS LLC',
    label: 'SOROBAN CAPITAL PARTNERS LLC',
  },
  {
    cik: '0001517857',
    value: '0001517857,SOROBAN CAPITAL PARTNERS LP',
    name: 'SOROBAN CAPITAL PARTNERS LP',
    label: 'SOROBAN CAPITAL PARTNERS LP',
  },
  {
    cik: '0001748240',
    value: '0001748240,SOROS CAPITAL MANAGEMENT LLC',
    name: 'SOROS CAPITAL MANAGEMENT LLC',
    label: 'SOROS CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001029160',
    value: '0001029160,SOROS FUND MANAGEMENT LLC',
    name: 'SOROS FUND MANAGEMENT LLC',
    label: 'SOROS FUND MANAGEMENT LLC',
  },
  {
    cik: '0001811240',
    value: '0001811240,SOUDERS FINANCIAL ADVISORS',
    name: 'SOUDERS FINANCIAL ADVISORS',
    label: 'SOUDERS FINANCIAL ADVISORS',
  },
  {
    cik: '0001315881',
    value: '0001315881,SOUND ENERGY PARTNERS, INC.',
    name: 'SOUND ENERGY PARTNERS, INC.',
    label: 'SOUND ENERGY PARTNERS, INC.',
  },
  {
    cik: '0001800620',
    value: '0001800620,SOUND FINANCIAL STRATEGIES GROUP, LLC',
    name: 'SOUND FINANCIAL STRATEGIES GROUP, LLC',
    label: 'SOUND FINANCIAL STRATEGIES GROUP, LLC',
  },
  {
    cik: '0001730960',
    value: '0001730960,SOUND INCOME STRATEGIES, LLC',
    name: 'SOUND INCOME STRATEGIES, LLC',
    label: 'SOUND INCOME STRATEGIES, LLC',
  },
  {
    cik: '0001831431',
    value: '0001831431,SOUNDMARK WEALTH MANAGEMENT, LLC',
    name: 'SOUNDMARK WEALTH MANAGEMENT, LLC',
    label: 'SOUNDMARK WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001591307',
    value: '0001591307,SOUND POINT CAPITAL MANAGEMENT, LP',
    name: 'SOUND POINT CAPITAL MANAGEMENT, LP',
    label: 'SOUND POINT CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0000820124',
    value: '0000820124,SOUND SHORE MANAGEMENT INC /CT/',
    name: 'SOUND SHORE MANAGEMENT INC /CT/',
    label: 'SOUND SHORE MANAGEMENT INC /CT/',
  },
  {
    cik: '0001784777',
    value: '0001784777,SOUND VIEW WEALTH ADVISORS GROUP, LLC',
    name: 'SOUND VIEW WEALTH ADVISORS GROUP, LLC',
    label: 'SOUND VIEW WEALTH ADVISORS GROUP, LLC',
  },
  {
    cik: '0001767243',
    value: '0001767243,SOUND VIEW WEALTH ADVISORS, LLC',
    name: 'SOUND VIEW WEALTH ADVISORS, LLC',
    label: 'SOUND VIEW WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001803253',
    value: '0001803253,SOUNDWATCH CAPITAL LLC',
    name: 'SOUNDWATCH CAPITAL LLC',
    label: 'SOUNDWATCH CAPITAL LLC',
  },
  {
    cik: '0001830954',
    value: '0001830954,SOURCE FINANCIAL ADVISORS LLC',
    name: 'SOURCE FINANCIAL ADVISORS LLC',
    label: 'SOURCE FINANCIAL ADVISORS LLC',
  },
  {
    cik: '0001822534',
    value: '0001822534,SOURCEROCK FUND LP',
    name: 'SOURCEROCK FUND LP',
    label: 'SOURCEROCK FUND LP',
  },
  {
    cik: '0001822531',
    value: '0001822531,SOURCEROCK GROUP LLC',
    name: 'SOURCEROCK GROUP LLC',
    label: 'SOURCEROCK GROUP LLC',
  },
  {
    cik: '0001538846',
    value: '0001538846,SOUTH DAKOTA INVESTMENT COUNCIL',
    name: 'SOUTH DAKOTA INVESTMENT COUNCIL',
    label: 'SOUTH DAKOTA INVESTMENT COUNCIL',
  },
  {
    cik: '0001390003',
    value: '0001390003,SOUTHEAST ASSET ADVISORS INC.',
    name: 'SOUTHEAST ASSET ADVISORS INC.',
    label: 'SOUTHEAST ASSET ADVISORS INC.',
  },
  {
    cik: '0000807985',
    value: '0000807985,SOUTHEASTERN ASSET MANAGEMENT INC/TN/',
    name: 'SOUTHEASTERN ASSET MANAGEMENT INC/TN/',
    label: 'SOUTHEASTERN ASSET MANAGEMENT INC/TN/',
  },
  {
    cik: '0001909249',
    value: '0001909249,SOUTHERN CAPITAL ADVISORS, LLC',
    name: 'SOUTHERN CAPITAL ADVISORS, LLC',
    label: 'SOUTHERN CAPITAL ADVISORS, LLC',
  },
  {
    cik: '0001831944',
    value: '0001831944,SOUTHERN FARM BUREAU LIFE INSURANCE',
    name: 'SOUTHERN FARM BUREAU LIFE INSURANCE',
    label: 'SOUTHERN FARM BUREAU LIFE INSURANCE',
  },
  {
    cik: '0001089755',
    value: '0001089755,SOUTHERNSUN ASSET MANAGEMENT, LLC',
    name: 'SOUTHERNSUN ASSET MANAGEMENT, LLC',
    label: 'SOUTHERNSUN ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001811579',
    value: '0001811579,SOUTHERN WEALTH MANAGEMENT, LLP',
    name: 'SOUTHERN WEALTH MANAGEMENT, LLP',
    label: 'SOUTHERN WEALTH MANAGEMENT, LLP',
  },
  {
    cik: '0001574986',
    value: '0001574986,SOUTH FERRY CAPITAL MANAGEMENT, L.P.',
    name: 'SOUTH FERRY CAPITAL MANAGEMENT, L.P.',
    label: 'SOUTH FERRY CAPITAL MANAGEMENT, L.P.',
  },
  {
    cik: '0001600219',
    value: '0001600219,SOUTH GEORGIA CAPITAL, LLC',
    name: 'SOUTH GEORGIA CAPITAL, LLC',
    label: 'SOUTH GEORGIA CAPITAL, LLC',
  },
  {
    cik: '0001361978',
    value: '0001361978,SOUTHPAW ASSET MANAGEMENT LP',
    name: 'SOUTHPAW ASSET MANAGEMENT LP',
    label: 'SOUTHPAW ASSET MANAGEMENT LP',
  },
  {
    cik: '0001319998',
    value: '0001319998,SOUTHPOINT CAPITAL ADVISORS LP',
    name: 'SOUTHPOINT CAPITAL ADVISORS LP',
    label: 'SOUTHPOINT CAPITAL ADVISORS LP',
  },
  {
    cik: '0001549553',
    value: '0001549553,SOUTHPORT MANAGEMENT, L.L.C.',
    name: 'SOUTHPORT MANAGEMENT, L.L.C.',
    label: 'SOUTHPORT MANAGEMENT, L.L.C.',
  },
  {
    cik: '0001852993',
    value: '0001852993,SOUTH SHORE CAPITAL ADVISORS',
    name: 'SOUTH SHORE CAPITAL ADVISORS',
    label: 'SOUTH SHORE CAPITAL ADVISORS',
  },
  {
    cik: '0001732093',
    value: '0001732093,SOUTHSIDE CAPITAL, LLC',
    name: 'SOUTHSIDE CAPITAL, LLC',
    label: 'SOUTHSIDE CAPITAL, LLC',
  },
  {
    cik: '0000764038',
    value: '0000764038,SOUTH STATE CORP',
    name: 'SOUTH STATE CORP',
    label: 'SOUTH STATE CORP',
  },
  {
    cik: '0000764038',
    value: '0000764038,SOUTHSTATE CORP',
    name: 'SOUTHSTATE CORP',
    label: 'SOUTHSTATE CORP',
  },
  {
    cik: '0001399706',
    value: '0001399706,SOUTH STREET ADVISORS LLC',
    name: 'SOUTH STREET ADVISORS LLC',
    label: 'SOUTH STREET ADVISORS LLC',
  },
  {
    cik: '0001259513',
    value: '0001259513,SOUTH TEXAS MONEY MANAGEMENT LTD',
    name: 'SOUTH TEXAS MONEY MANAGEMENT LTD',
    label: 'SOUTH TEXAS MONEY MANAGEMENT LTD',
  },
  {
    cik: '0001626420',
    value: '0001626420,SOVARNUM CAPITAL L.P.',
    name: 'SOVARNUM CAPITAL L.P.',
    label: 'SOVARNUM CAPITAL L.P.',
  },
  {
    cik: '0001846175',
    value: '0001846175,SOVEREIGN FINANCIAL GROUP, INC.',
    name: 'SOVEREIGN FINANCIAL GROUP, INC.',
    label: 'SOVEREIGN FINANCIAL GROUP, INC.',
  },
  {
    cik: '0001908165',
    value: '0001908165,SOVEREIGN INVESTMENT ADVISORS, LLC',
    name: 'SOVEREIGN INVESTMENT ADVISORS, LLC',
    label: 'SOVEREIGN INVESTMENT ADVISORS, LLC',
  },
  {
    cik: '0001755622',
    value: '0001755622,SOVIERO ASSET MANAGEMENT, LP',
    name: 'SOVIERO ASSET MANAGEMENT, LP',
    label: 'SOVIERO ASSET MANAGEMENT, LP',
  },
  {
    cik: '0001806226',
    value: '0001806226,SOWA FINANCIAL GROUP, INC.',
    name: 'SOWA FINANCIAL GROUP, INC.',
    label: 'SOWA FINANCIAL GROUP, INC.',
  },
  {
    cik: '0001633697',
    value: '0001633697,SOWELL FINANCIAL SERVICES LLC',
    name: 'SOWELL FINANCIAL SERVICES LLC',
    label: 'SOWELL FINANCIAL SERVICES LLC',
  },
  {
    cik: '0001912294',
    value: '0001912294,SOZO VENTURES GP II, L.P.',
    name: 'SOZO VENTURES GP II, L.P.',
    label: 'SOZO VENTURES GP II, L.P.',
  },
  {
    cik: '0001912295',
    value: '0001912295,SOZO VENTURES GP I, L.P.',
    name: 'SOZO VENTURES GP I, L.P.',
    label: 'SOZO VENTURES GP I, L.P.',
  },
  {
    cik: '0001846116',
    value: '0001846116,SPARK GROWTH MANAGEMENT PARTNERS II, LLC',
    name: 'SPARK GROWTH MANAGEMENT PARTNERS II, LLC',
    label: 'SPARK GROWTH MANAGEMENT PARTNERS II, LLC',
  },
  {
    cik: '0001608856',
    value: '0001608856,SPARK GROWTH MANAGEMENT PARTNERS LLC',
    name: 'SPARK GROWTH MANAGEMENT PARTNERS LLC',
    label: 'SPARK GROWTH MANAGEMENT PARTNERS LLC',
  },
  {
    cik: '0001554871',
    value: '0001554871,SPARK INVESTMENT MANAGEMENT LLC',
    name: 'SPARK INVESTMENT MANAGEMENT LLC',
    label: 'SPARK INVESTMENT MANAGEMENT LLC',
  },
  {
    cik: '0001640445',
    value: '0001640445,SPARK MANAGEMENT PARTNERS III (AIV I), LLC',
    name: 'SPARK MANAGEMENT PARTNERS III (AIV I), LLC',
    label: 'SPARK MANAGEMENT PARTNERS III (AIV I), LLC',
  },
  {
    cik: '0001600213',
    value: '0001600213,SPARK MANAGEMENT PARTNERS II, LLC',
    name: 'SPARK MANAGEMENT PARTNERS II, LLC',
    label: 'SPARK MANAGEMENT PARTNERS II, LLC',
  },
  {
    cik: '0001930346',
    value: '0001930346,SPARTAN FUND MANAGEMENT INC.',
    name: 'SPARTAN FUND MANAGEMENT INC.',
    label: 'SPARTAN FUND MANAGEMENT INC.',
  },
  {
    cik: '0001749283',
    value: '0001749283,SPARTAN PLANNING & WEALTH MANAGEMENT',
    name: 'SPARTAN PLANNING & WEALTH MANAGEMENT',
    label: 'SPARTAN PLANNING & WEALTH MANAGEMENT',
  },
  {
    cik: '0001751817',
    value: '0001751817,SP ASSET MANAGEMENT',
    name: 'SP ASSET MANAGEMENT',
    label: 'SP ASSET MANAGEMENT',
  },
  {
    cik: '0001415201',
    value: '0001415201,SPC FINANCIAL, INC.',
    name: 'SPC FINANCIAL, INC.',
    label: 'SPC FINANCIAL, INC.',
  },
  {
    cik: '0001718982',
    value: '0001718982,SPEARHEAD CAPITAL ADVISORS, LLC',
    name: 'SPEARHEAD CAPITAL ADVISORS, LLC',
    label: 'SPEARHEAD CAPITAL ADVISORS, LLC',
  },
  {
    cik: '0001426319',
    value: '0001426319,SPEARS ABACUS ADVISORS LLC',
    name: 'SPEARS ABACUS ADVISORS LLC',
    label: 'SPEARS ABACUS ADVISORS LLC',
  },
  {
    cik: '0000884310',
    value: '0000884310,SPECTRUM ADVISORY SERVICES INC',
    name: 'SPECTRUM ADVISORY SERVICES INC',
    label: 'SPECTRUM ADVISORY SERVICES INC',
  },
  {
    cik: '0001318293',
    value: '0001318293,SPECTRUM ASSET MANAGEMENT, INC.',
    name: 'SPECTRUM ASSET MANAGEMENT, INC.',
    label: 'SPECTRUM ASSET MANAGEMENT, INC.',
  },
  {
    cik: '0001632078',
    value: '0001632078,SPECTRUM ASSET MANAGEMENT, INC. (NB/CA)',
    name: 'SPECTRUM ASSET MANAGEMENT, INC. (NB/CA)',
    label: 'SPECTRUM ASSET MANAGEMENT, INC. (NB/CA)',
  },
  {
    cik: '0001657260',
    value: '0001657260,SPECTRUM EQUITY MANAGEMENT, INC.',
    name: 'SPECTRUM EQUITY MANAGEMENT, INC.',
    label: 'SPECTRUM EQUITY MANAGEMENT, INC.',
  },
  {
    cik: '0001566531',
    value: '0001566531,SPECTRUM FINANCIAL ALLIANCE LTD LLC',
    name: 'SPECTRUM FINANCIAL ALLIANCE LTD LLC',
    label: 'SPECTRUM FINANCIAL ALLIANCE LTD LLC',
  },
  {
    cik: '0001534843',
    value: '0001534843,SPECTRUM MANAGEMENT GROUP, INC.',
    name: 'SPECTRUM MANAGEMENT GROUP, INC.',
    label: 'SPECTRUM MANAGEMENT GROUP, INC.',
  },
  {
    cik: '0001534843',
    value: '0001534843,SPECTRUM MANAGEMENT GROUP, LLC',
    name: 'SPECTRUM MANAGEMENT GROUP, LLC',
    label: 'SPECTRUM MANAGEMENT GROUP, LLC',
  },
  {
    cik: '0001725394',
    value: '0001725394,SPECTRUM PLANNING & ADVISORY SERVICES INC.',
    name: 'SPECTRUM PLANNING & ADVISORY SERVICES INC.',
    label: 'SPECTRUM PLANNING & ADVISORY SERVICES INC.',
  },
  {
    cik: '0001776821',
    value: '0001776821,SPECTRUM WEALTH ADVISORY GROUP, LLC',
    name: 'SPECTRUM WEALTH ADVISORY GROUP, LLC',
    label: 'SPECTRUM WEALTH ADVISORY GROUP, LLC',
  },
  {
    cik: '0000900169',
    value: '0000900169,SPEECE THORSON CAPITAL GROUP INC',
    name: 'SPEECE THORSON CAPITAL GROUP INC',
    label: 'SPEECE THORSON CAPITAL GROUP INC',
  },
  {
    cik: '0001729269',
    value: '0001729269,SPENCE ASSET MANAGEMENT',
    name: 'SPENCE ASSET MANAGEMENT',
    label: 'SPENCE ASSET MANAGEMENT',
  },
  {
    cik: '0001134319',
    value: '0001134319,SPERO-SMITH INVESTMENT ADVISERS INC /OH',
    name: 'SPERO-SMITH INVESTMENT ADVISERS INC /OH',
    label: 'SPERO-SMITH INVESTMENT ADVISERS INC /OH',
  },
  {
    cik: '0001218761',
    value: '0001218761,SPF BEHEER BV',
    name: 'SPF BEHEER BV',
    label: 'SPF BEHEER BV',
  },
  {
    cik: '0001496201',
    value: '0001496201,SPHERA FUNDS MANAGEMENT LTD.',
    name: 'SPHERA FUNDS MANAGEMENT LTD.',
    label: 'SPHERA FUNDS MANAGEMENT LTD.',
  },
  {
    cik: '0000857956',
    value: '0000857956,SPHINX TRADING, LP',
    name: 'SPHINX TRADING, LP',
    label: 'SPHINX TRADING, LP',
  },
  {
    cik: '0001703301',
    value: '0001703301,SPIDERROCK ADVISORS LLC',
    name: 'SPIDERROCK ADVISORS LLC',
    label: 'SPIDERROCK ADVISORS LLC',
  },
  {
    cik: '0001703301',
    value: '0001703301,SPIDERROCK ADVISORS, LLC',
    name: 'SPIDERROCK ADVISORS, LLC',
    label: 'SPIDERROCK ADVISORS, LLC',
  },
  {
    cik: '0001421412',
    value: '0001421412,SPIDERROCK TRADING LLC',
    name: 'SPIDERROCK TRADING LLC',
    label: 'SPIDERROCK TRADING LLC',
  },
  {
    cik: '0001633677',
    value: '0001633677,SPINDLETOP CAPITAL, LLC',
    name: 'SPINDLETOP CAPITAL, LLC',
    label: 'SPINDLETOP CAPITAL, LLC',
  },
  {
    cik: '0001719281',
    value: '0001719281,SPINNAKER CAPITAL LLC',
    name: 'SPINNAKER CAPITAL LLC',
    label: 'SPINNAKER CAPITAL LLC',
  },
  {
    cik: '0001178653',
    value: '0001178653,SPINNAKER CAPITAL LTD',
    name: 'SPINNAKER CAPITAL LTD',
    label: 'SPINNAKER CAPITAL LTD',
  },
  {
    cik: '0001911067',
    value: '0001911067,SPINNAKER INVESTMENT GROUP, LLC',
    name: 'SPINNAKER INVESTMENT GROUP, LLC',
    label: 'SPINNAKER INVESTMENT GROUP, LLC',
  },
  {
    cik: '0001158970',
    value: '0001158970,SPINNAKER TRUST',
    name: 'SPINNAKER TRUST',
    label: 'SPINNAKER TRUST',
  },
  {
    cik: '0001858740',
    value: '0001858740,SPIRE WEALTH MANAGEMENT',
    name: 'SPIRE WEALTH MANAGEMENT',
    label: 'SPIRE WEALTH MANAGEMENT',
  },
  {
    cik: '0001041283',
    value: '0001041283,SPIRIT OF AMERICA MANAGEMENT CORP/NY',
    name: 'SPIRIT OF AMERICA MANAGEMENT CORP/NY',
    label: 'SPIRIT OF AMERICA MANAGEMENT CORP/NY',
  },
  {
    cik: '0001531064',
    value: '0001531064,SPITFIRE CAPITAL LLC',
    name: 'SPITFIRE CAPITAL LLC',
    label: 'SPITFIRE CAPITAL LLC',
  },
  {
    cik: '0000919468',
    value: '0000919468,SPO ADVISORY CORP',
    name: 'SPO ADVISORY CORP',
    label: 'SPO ADVISORY CORP',
  },
  {
    cik: '0001809159',
    value: '0001809159,SPOTLIGHT ASSET GROUP, INC.',
    name: 'SPOTLIGHT ASSET GROUP, INC.',
    label: 'SPOTLIGHT ASSET GROUP, INC.',
  },
  {
    cik: '0001511566',
    value: '0001511566,SPOT TRADING L.L.C',
    name: 'SPOT TRADING L.L.C',
    label: 'SPOT TRADING L.L.C',
  },
  {
    cik: '0001088412',
    value: '0001088412,SPOT TRADING L.L.C.',
    name: 'SPOT TRADING L.L.C.',
    label: 'SPOT TRADING L.L.C.',
  },
  {
    cik: '0001771174',
    value: '0001771174,SPOUTING ROCK ASSET MANAGEMENT, LLC',
    name: 'SPOUTING ROCK ASSET MANAGEMENT, LLC',
    label: 'SPOUTING ROCK ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001668016',
    value: '0001668016,SPQ ASIA CAPITAL LTD',
    name: 'SPQ ASIA CAPITAL LTD',
    label: 'SPQ ASIA CAPITAL LTD',
  },
  {
    cik: '0001800913',
    value: '0001800913,SPRENG CAPITAL MANAGEMENT, INC.',
    name: 'SPRENG CAPITAL MANAGEMENT, INC.',
    label: 'SPRENG CAPITAL MANAGEMENT, INC.',
  },
  {
    cik: '0001144903',
    value: '0001144903,SPRINGBANK VALUE PARTNERS LP',
    name: 'SPRINGBANK VALUE PARTNERS LP',
    label: 'SPRINGBANK VALUE PARTNERS LP',
  },
  {
    cik: '0001387508',
    value: '0001387508,SPRINGBOK CAPITAL MANAGEMENT, LLC',
    name: 'SPRINGBOK CAPITAL MANAGEMENT, LLC',
    label: 'SPRINGBOK CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001899146',
    value: '0001899146,SPRING CAPITAL MANAGEMENT, LLC',
    name: 'SPRING CAPITAL MANAGEMENT, LLC',
    label: 'SPRING CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001451531',
    value: '0001451531,SPRING CREEK CAPITAL LLC',
    name: 'SPRING CREEK CAPITAL LLC',
    label: 'SPRING CREEK CAPITAL LLC',
  },
  {
    cik: '0001737421',
    value: '0001737421,SPRING CREEK INVESTMENT MANAGEMENT, LLC',
    name: 'SPRING CREEK INVESTMENT MANAGEMENT, LLC',
    label: 'SPRING CREEK INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001660086',
    value: '0001660086,SPRINGHOUSE CAPITAL MANAGEMENT, LP',
    name: 'SPRINGHOUSE CAPITAL MANAGEMENT, LP',
    label: 'SPRINGHOUSE CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0000351262',
    value: '0000351262,SPRINGOWL ASSOCIATES LLC',
    name: 'SPRINGOWL ASSOCIATES LLC',
    label: 'SPRINGOWL ASSOCIATES LLC',
  },
  {
    cik: '0001911536',
    value: '0001911536,SPRINGS CAPITAL (HONG KONG) LTD',
    name: 'SPRINGS CAPITAL (HONG KONG) LTD',
    label: 'SPRINGS CAPITAL (HONG KONG) LTD',
  },
  {
    cik: '0001512920',
    value: '0001512920,SPROTT INC.',
    name: 'SPROTT INC.',
    label: 'SPROTT INC.',
  },
  {
    cik: '0001234074',
    value: '0001234074,SPRUCEGROVE INVESTMENT MANAGEMENT LTD',
    name: 'SPRUCEGROVE INVESTMENT MANAGEMENT LTD',
    label: 'SPRUCEGROVE INVESTMENT MANAGEMENT LTD',
  },
  {
    cik: '0001543170',
    value: '0001543170,SPRUCE HOUSE INVESTMENT MANAGEMENT LLC',
    name: 'SPRUCE HOUSE INVESTMENT MANAGEMENT LLC',
    label: 'SPRUCE HOUSE INVESTMENT MANAGEMENT LLC',
  },
  {
    cik: '0001797827',
    value: '0001797827,SPRUCE HOUSE PARTNERSHIP LLC',
    name: 'SPRUCE HOUSE PARTNERSHIP LLC',
    label: 'SPRUCE HOUSE PARTNERSHIP LLC',
  },
  {
    cik: '0001542179',
    value: '0001542179,SPRUCE INVESTMENT ADVISORS LLC',
    name: 'SPRUCE INVESTMENT ADVISORS LLC',
    label: 'SPRUCE INVESTMENT ADVISORS LLC',
  },
  {
    cik: '0001731579',
    value: '0001731579,SPRUCE POINT CAPITAL MANAGEMENT, LLC',
    name: 'SPRUCE POINT CAPITAL MANAGEMENT, LLC',
    label: 'SPRUCE POINT CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001542179',
    value: '0001542179,SPRUCE PRIVATE INVESTORS, LLC',
    name: 'SPRUCE PRIVATE INVESTORS, LLC',
    label: 'SPRUCE PRIVATE INVESTORS, LLC',
  },
  {
    cik: '0001831949',
    value: '0001831949,SPT INVEST MANAGEMENT SARL',
    name: 'SPT INVEST MANAGEMENT SARL',
    label: 'SPT INVEST MANAGEMENT SARL',
  },
  {
    cik: '0001589176',
    value: '0001589176,SPX EQUITIES GESTAO DE RECURSOS LTDA',
    name: 'SPX EQUITIES GESTAO DE RECURSOS LTDA',
    label: 'SPX EQUITIES GESTAO DE RECURSOS LTDA',
  },
  {
    cik: '0001589176',
    value: '0001589176,SPX GESTAO DE RECURSOS LTDA',
    name: 'SPX GESTAO DE RECURSOS LTDA',
    label: 'SPX GESTAO DE RECURSOS LTDA',
  },
  {
    cik: '0001654344',
    value: '0001654344,SPYGLASS CAPITAL MANAGEMENT LLC',
    name: 'SPYGLASS CAPITAL MANAGEMENT LLC',
    label: 'SPYGLASS CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001534380',
    value: '0001534380,SQ ADVISORS, LLC',
    name: 'SQ ADVISORS, LLC',
    label: 'SQ ADVISORS, LLC',
  },
  {
    cik: '0001665887',
    value: '0001665887,SQN INVESTORS LP',
    name: 'SQN INVESTORS LP',
    label: 'SQN INVESTORS LP',
  },
  {
    cik: '0001577806',
    value: '0001577806,SQUADRA INVESTMENTS - GESTAO DE RECURSOS LTDA.',
    name: 'SQUADRA INVESTMENTS - GESTAO DE RECURSOS LTDA.',
    label: 'SQUADRA INVESTMENTS - GESTAO DE RECURSOS LTDA.',
  },
  {
    cik: '0001642575',
    value: '0001642575,SQUAREPOINT OPS LLC',
    name: 'SQUAREPOINT OPS LLC',
    label: 'SQUAREPOINT OPS LLC',
  },
  {
    cik: '0001727592',
    value: '0001727592,SQUAR MILNER FINANCIAL SERVICES LLC',
    name: 'SQUAR MILNER FINANCIAL SERVICES LLC',
    label: 'SQUAR MILNER FINANCIAL SERVICES LLC',
  },
  {
    cik: '0001496228',
    value: '0001496,SRB CORP',
    name: 'SRB CORP',
    label: 'SRB CORP',
  },
  {
    cik: '0001910264',
    value: '0001910264,SR ONE CAPITAL MANAGEMENT, LP',
    name: 'SR ONE CAPITAL MANAGEMENT, LP',
    label: 'SR ONE CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0001586767',
    value: '0001586767,SRS CAPITAL ADVISORS, INC.',
    name: 'SRS CAPITAL ADVISORS, INC.',
    label: 'SRS CAPITAL ADVISORS, INC.',
  },
  {
    cik: '0001616004',
    value: '0001616004,S. R. SCHILL & ASSOCIATES',
    name: 'S. R. SCHILL & ASSOCIATES',
    label: 'S. R. SCHILL & ASSOCIATES',
  },
  {
    cik: '0001503174',
    value: '0001503174,SRS INVESTMENT MANAGEMENT, LLC',
    name: 'SRS INVESTMENT MANAGEMENT, LLC',
    label: 'SRS INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001911735',
    value: '0001911735,SS&H FINANCIAL ADVISORS, INC.',
    name: 'SS&H FINANCIAL ADVISORS, INC.',
    label: 'SS&H FINANCIAL ADVISORS, INC.',
  },
  {
    cik: '0000938487',
    value: '0000938487,SSI INVESTMENT MANAGEMENT INC',
    name: 'SSI INVESTMENT MANAGEMENT INC',
    label: 'SSI INVESTMENT MANAGEMENT INC',
  },
  {
    cik: '0000938487',
    value: '0000938487,SSI INVESTMENT MANAGEMENT LLC',
    name: 'SSI INVESTMENT MANAGEMENT LLC',
    label: 'SSI INVESTMENT MANAGEMENT LLC',
  },
  {
    cik: '0001322924',
    value: '0001322924,S SQUARED TECHNOLOGY, LLC',
    name: 'S SQUARED TECHNOLOGY, LLC',
    label: 'S SQUARED TECHNOLOGY, LLC',
  },
  {
    cik: '0001842089',
    value: '0001842089,STABLEFORD CAPITAL II LLC',
    name: 'STABLEFORD CAPITAL II LLC',
    label: 'STABLEFORD CAPITAL II LLC',
  },
  {
    cik: '0001308685',
    value: '0001308685,STACK FINANCIAL MANAGEMENT, INC',
    name: 'STACK FINANCIAL MANAGEMENT, INC',
    label: 'STACK FINANCIAL MANAGEMENT, INC',
  },
  {
    cik: '0001742654',
    value: '0001742654,STACKLINE PARTNERS LP',
    name: 'STACKLINE PARTNERS LP',
    label: 'STACKLINE PARTNERS LP',
  },
  {
    cik: '0001321220',
    value: '0001321220,STADION MONEY MANAGEMENT, LLC',
    name: 'STADION MONEY MANAGEMENT, LLC',
    label: 'STADION MONEY MANAGEMENT, LLC',
  },
  {
    cik: '0001105087',
    value: '0001105087,STADIUM CAPITAL MANAGEMENT LLC',
    name: 'STADIUM CAPITAL MANAGEMENT LLC',
    label: 'STADIUM CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001771444',
    value: '0001771444,STAGWELL GROUP LLC',
    name: 'STAGWELL GROUP LLC',
    label: 'STAGWELL GROUP LLC',
  },
  {
    cik: '0001454138',
    value: '0001454138,STAIRWAY PARTNERS, LLC',
    name: 'STAIRWAY PARTNERS, LLC',
    label: 'STAIRWAY PARTNERS, LLC',
  },
  {
    cik: '0001106565',
    value: '0001106565,STALEY CAPITAL ADVISERS INC',
    name: 'STALEY CAPITAL ADVISERS INC',
    label: 'STALEY CAPITAL ADVISERS INC',
  },
  {
    cik: '0001766357',
    value: '0001766357,STAMINA CAPITAL MANAGEMENT LP',
    name: 'STAMINA CAPITAL MANAGEMENT LP',
    label: 'STAMINA CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001705399',
    value: '0001705399,STAMOS CAPITAL PARTNERS, L.P.',
    name: 'STAMOS CAPITAL PARTNERS, L.P.',
    label: 'STAMOS CAPITAL PARTNERS, L.P.',
  },
  {
    cik: '0001120079',
    value: '0001120079,STANCORP INVESTMENT ADVISERS INC',
    name: 'STANCORP INVESTMENT ADVISERS INC',
    label: 'STANCORP INVESTMENT ADVISERS INC',
  },
  {
    cik: '0001809494',
    value: '0001809494,STANDARD FAMILY OFFICE LLC',
    name: 'STANDARD FAMILY OFFICE LLC',
    label: 'STANDARD FAMILY OFFICE LLC',
  },
  {
    cik: '0001409888',
    value: '0001409888,STANDARD GENERAL L.P.',
    name: 'STANDARD GENERAL L.P.',
    label: 'STANDARD GENERAL L.P.',
  },
  {
    cik: '0001539436',
    value: '0001539436,STANDARD INVESTMENTS LLC',
    name: 'STANDARD INVESTMENTS LLC',
    label: 'STANDARD INVESTMENTS LLC',
  },
  {
    cik: '0001716774',
    value: '0001716774,STANDARD LIFE ABERDEEN PLC',
    name: 'STANDARD LIFE ABERDEEN PLC',
    label: 'STANDARD LIFE ABERDEEN PLC',
  },
  {
    cik: '0001505708',
    value: '0001505708,STANDARD LIFE INVESTMENTS LTD',
    name: 'STANDARD LIFE INVESTMENTS LTD',
    label: 'STANDARD LIFE INVESTMENTS LTD',
  },
  {
    cik: '0001034012',
    value: '0001034012,STANDARD PACIFIC CAPITAL LLC/CA',
    name: 'STANDARD PACIFIC CAPITAL LLC/CA',
    label: 'STANDARD PACIFIC CAPITAL LLC/CA',
  },
  {
    cik: '0001377633',
    value: '0001377633,STANFORD INVESTMENT GROUP, INC.',
    name: 'STANFORD INVESTMENT GROUP, INC.',
    label: 'STANFORD INVESTMENT GROUP, INC.',
  },
  {
    cik: '0001602072',
    value: '0001602072,STANHOPE INVESTMENTS',
    name: 'STANHOPE INVESTMENTS',
    label: 'STANHOPE INVESTMENTS',
  },
  {
    cik: '0001518934',
    value: '0001518934,STANLEY CAPITAL MANAGEMENT, LLC',
    name: 'STANLEY CAPITAL MANAGEMENT, LLC',
    label: 'STANLEY CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001601489',
    value: '0001601489,STANLEY-LAMAN GROUP, LTD.',
    name: 'STANLEY-LAMAN GROUP, LTD.',
    label: 'STANLEY-LAMAN GROUP, LTD.',
  },
  {
    cik: '0001725910',
    value: '0001725910,STANSBERRY ASSET MANAGEMENT, LLC',
    name: 'STANSBERRY ASSET MANAGEMENT, LLC',
    label: 'STANSBERRY ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001954085',
    value: '0001954085,STAPP WEALTH MANAGEMENT, PLLC',
    name: 'STAPP WEALTH MANAGEMENT, PLLC',
    label: 'STAPP WEALTH MANAGEMENT, PLLC',
  },
  {
    cik: '0001517137',
    value: '0001517137,STARBOARD VALUE LP',
    name: 'STARBOARD VALUE LP',
    label: 'STARBOARD VALUE LP',
  },
  {
    cik: '0001652391',
    value: '0001652391,STARFIRE INVESTMENT ADVISERS, INC.',
    name: 'STARFIRE INVESTMENT ADVISERS, INC.',
    label: 'STARFIRE INVESTMENT ADVISERS, INC.',
  },
  {
    cik: '0001908885',
    value: '0001908885,STARFOX FINANCIAL SERVICES, LLC',
    name: 'STARFOX FINANCIAL SERVICES, LLC',
    label: 'STARFOX FINANCIAL SERVICES, LLC',
  },
  {
    cik: '0001608288',
    value: '0001608288,STAR-GLOBAL EQUITIES FUND, LLC',
    name: 'STAR-GLOBAL EQUITIES FUND, LLC',
    label: 'STAR-GLOBAL EQUITIES FUND, LLC',
  },
  {
    cik: '0001236615',
    value: '0001236615,STARR INTERNATIONAL CO INC',
    name: 'STARR INTERNATIONAL CO INC',
    label: 'STARR INTERNATIONAL CO INC',
  },
  {
    cik: '0001388788',
    value: '0001388788,STARWOOD CAPITAL GROUP MANAGEMENT, L.L.C.',
    name: 'STARWOOD CAPITAL GROUP MANAGEMENT, L.L.C.',
    label: 'STARWOOD CAPITAL GROUP MANAGEMENT, L.L.C.',
  },
  {
    cik: '0001387005',
    value: '0001387005,STARWOOD REAL ESTATE SECURITIES, LLC',
    name: 'STARWOOD REAL ESTATE SECURITIES, LLC',
    label: 'STARWOOD REAL ESTATE SECURITIES, LLC',
  },
  {
    cik: '0000938076',
    value:
      '0000938076,STATE BOARD OF ADMINISTRATION OF FLORIDA RETIREMENT SYSTEM',
    name: 'STATE BOARD OF ADMINISTRATION OF FLORIDA RETIREMENT SYSTEM',
    label: 'STATE BOARD OF ADMINISTRATION OF FLORIDA RETIREMENT SYSTEM',
  },
  {
    cik: '0000315032',
    value: '0000315032,STATE FARM MUTUAL AUTOMOBILE INSURANCE CO',
    name: 'STATE FARM MUTUAL AUTOMOBILE INSURANCE CO',
    label: 'STATE FARM MUTUAL AUTOMOBILE INSURANCE CO',
  },
  {
    cik: '0001370102',
    value: '0001370102,STATE OF ALASKA, DEPARTMENT OF REVENUE',
    name: 'STATE OF ALASKA, DEPARTMENT OF REVENUE',
    label: 'STATE OF ALASKA, DEPARTMENT OF REVENUE',
  },
  {
    cik: '0000762152',
    value: '0000762152,STATE OF MICHIGAN RETIREMENT SYSTEM',
    name: 'STATE OF MICHIGAN RETIREMENT SYSTEM',
    label: 'STATE OF MICHIGAN RETIREMENT SYSTEM',
  },
  {
    cik: '0001483262',
    value: '0001483262,STATE OF NEW JERSEY COMMON PENSION FUND A',
    name: 'STATE OF NEW JERSEY COMMON PENSION FUND A',
    label: 'STATE OF NEW JERSEY COMMON PENSION FUND A',
  },
  {
    cik: '0001483261',
    value: '0001483261,STATE OF NEW JERSEY COMMON PENSION FUND B',
    name: 'STATE OF NEW JERSEY COMMON PENSION FUND B',
    label: 'STATE OF NEW JERSEY COMMON PENSION FUND B',
  },
  {
    cik: '0001483066',
    value: '0001483066,STATE OF NEW JERSEY COMMON PENSION FUND D',
    name: 'STATE OF NEW JERSEY COMMON PENSION FUND D',
    label: 'STATE OF NEW JERSEY COMMON PENSION FUND D',
  },
  {
    cik: '0001483260',
    value: '0001483260,STATE OF NEW JERSEY COMMON PENSION FUND E',
    name: 'STATE OF NEW JERSEY COMMON PENSION FUND E',
    label: 'STATE OF NEW JERSEY COMMON PENSION FUND E',
  },
  {
    cik: '0001587973',
    value: '0001587973,STATE OF TENNESSEE, TREASURY DEPARTMENT',
    name: 'STATE OF TENNESSEE, TREASURY DEPARTMENT',
    label: 'STATE OF TENNESSEE, TREASURY DEPARTMENT',
  },
  {
    cik: '0000854157',
    value: '0000854157,STATE OF WISCONSIN INVESTMENT BOARD',
    name: 'STATE OF WISCONSIN INVESTMENT BOARD',
    label: 'STATE OF WISCONSIN INVESTMENT BOARD',
  },
  {
    cik: '0001821626',
    value: '0001821626,STATE OF WYOMING',
    name: 'STATE OF WYOMING',
    label: 'STATE OF WYOMING',
  },
  {
    cik: '0000093751',
    value: '0000093751,STATE STREET CORP',
    name: 'STATE STREET CORP',
    label: 'STATE STREET CORP',
  },
  {
    cik: '0000762152',
    value: '0000762152,STATE TREASURER STATE OF MICHIGAN',
    name: 'STATE TREASURER STATE OF MICHIGAN',
    label: 'STATE TREASURER STATE OF MICHIGAN',
  },
  {
    cik: '0001709719',
    value: '0001709719,STATON FINANCIAL ADVISORS, LLC',
    name: 'STATON FINANCIAL ADVISORS, LLC',
    label: 'STATON FINANCIAL ADVISORS, LLC',
  },
  {
    cik: '0001471474',
    value: '0001471474,STA WEALTH MANAGEMENT, LLC',
    name: 'STA WEALTH MANAGEMENT, LLC',
    label: 'STA WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001010911',
    value: '0001010911,S&T BANK/PA',
    name: 'S&T BANK/PA',
    label: 'S&T BANK/PA',
  },
  {
    cik: '0001213310',
    value: '0001213310,STEADFAST ADVISORS LP',
    name: 'STEADFAST ADVISORS LP',
    label: 'STEADFAST ADVISORS LP',
  },
  {
    cik: '0001214822',
    value: '0001214822,STEADFAST CAPITAL MANAGEMENT LP',
    name: 'STEADFAST CAPITAL MANAGEMENT LP',
    label: 'STEADFAST CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001872297',
    value: '0001872297,STEADFAST CAPITAL MANAGEMENT (UK) LTD.',
    name: 'STEADFAST CAPITAL MANAGEMENT (UK) LTD.',
    label: 'STEADFAST CAPITAL MANAGEMENT (UK) LTD.',
  },
  {
    cik: '0001844008',
    value: '0001844008,STEADVIEW CAPITAL MANAGEMENT LLC',
    name: 'STEADVIEW CAPITAL MANAGEMENT LLC',
    label: 'STEADVIEW CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001635663',
    value: '0001635663,STEAMBOAT CAPITAL PARTNERS, LLC',
    name: 'STEAMBOAT CAPITAL PARTNERS, LLC',
    label: 'STEAMBOAT CAPITAL PARTNERS, LLC',
  },
  {
    cik: '0001632757',
    value: '0001632757,STEAMBOAT VENTURES ASIA MANAGER, L.P.',
    name: 'STEAMBOAT VENTURES ASIA MANAGER, L.P.',
    label: 'STEAMBOAT VENTURES ASIA MANAGER, L.P.',
  },
  {
    cik: '0001611877',
    value: '0001611877,STEAMBOAT VENTURES MANAGER V, L.P.',
    name: 'STEAMBOAT VENTURES MANAGER V, L.P.',
    label: 'STEAMBOAT VENTURES MANAGER V, L.P.',
  },
  {
    cik: '0001569099',
    value: '0001569099,STEARNS FINANCIAL SERVICES GROUP',
    name: 'STEARNS FINANCIAL SERVICES GROUP',
    label: 'STEARNS FINANCIAL SERVICES GROUP',
  },
  {
    cik: '0001673693',
    value: '0001673693,STEELBRIDGE PRIVATE WEALTH ADVISORS LLC',
    name: 'STEELBRIDGE PRIVATE WEALTH ADVISORS LLC',
    label: 'STEELBRIDGE PRIVATE WEALTH ADVISORS LLC',
  },
  {
    cik: '0001665853',
    value: '0001665853,STEEL CANYON CAPITAL, LLC',
    name: 'STEEL CANYON CAPITAL, LLC',
    label: 'STEEL CANYON CAPITAL, LLC',
  },
  {
    cik: '0001133521',
    value: '0001133521,STEELHEAD PARTNERS LLC',
    name: 'STEELHEAD PARTNERS LLC',
    label: 'STEELHEAD PARTNERS LLC',
  },
  {
    cik: '0001452857',
    value: '0001452857,STEEL PARTNERS HOLDINGS L.P.',
    name: 'STEEL PARTNERS HOLDINGS L.P.',
    label: 'STEEL PARTNERS HOLDINGS L.P.',
  },
  {
    cik: '0001801868',
    value: '0001801868,STEEL PEAK WEALTH MANAGEMENT LLC',
    name: 'STEEL PEAK WEALTH MANAGEMENT LLC',
    label: 'STEEL PEAK WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0001908976',
    value: '0001908976,STEGENT EQUITY ADVISORS, INC.',
    name: 'STEGENT EQUITY ADVISORS, INC.',
    label: 'STEGENT EQUITY ADVISORS, INC.',
  },
  {
    cik: '0001120048',
    value: '0001120048,STEGINSKY CAPITAL LLC',
    name: 'STEGINSKY CAPITAL LLC',
    label: 'STEGINSKY CAPITAL LLC',
  },
  {
    cik: '0001907433',
    value: '0001907433,STEGNER INVESTMENT ASSOCIATES INC.',
    name: 'STEGNER INVESTMENT ASSOCIATES INC.',
    label: 'STEGNER INVESTMENT ASSOCIATES INC.',
  },
  {
    cik: '0001731876',
    value: '0001731876,STEIGERWALD, GORDON & KOCH INC.',
    name: 'STEIGERWALD, GORDON & KOCH INC.',
    label: 'STEIGERWALD, GORDON & KOCH INC.',
  },
  {
    cik: '0001169883',
    value: '0001169883,STEINBERG ASSET MANAGEMENT LLC',
    name: 'STEINBERG ASSET MANAGEMENT LLC',
    label: 'STEINBERG ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001288917',
    value: '0001288917,STEINBERG GLOBAL ASSET MANAGEMENT',
    name: 'STEINBERG GLOBAL ASSET MANAGEMENT',
    label: 'STEINBERG GLOBAL ASSET MANAGEMENT',
  },
  {
    cik: '0001519676',
    value: '0001519676,STELAC ADVISORY SERVICES LLC',
    name: 'STELAC ADVISORY SERVICES LLC',
    label: 'STELAC ADVISORY SERVICES LLC',
  },
  {
    cik: '0001326918',
    value: '0001326918,STELLAR CAPITAL MANAGEMENT, LLC',
    name: 'STELLAR CAPITAL MANAGEMENT, LLC',
    label: 'STELLAR CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001427165',
    value: '0001427165,STELLIAM INVESTMENT MANAGEMENT, LP',
    name: 'STELLIAM INVESTMENT MANAGEMENT, LP',
    label: 'STELLIAM INVESTMENT MANAGEMENT, LP',
  },
  {
    cik: '0001840750',
    value: '0001840750,STENAHM ASSET MANAGMENT LTD',
    name: 'STENAHM ASSET MANAGMENT LTD',
    label: 'STENAHM ASSET MANAGMENT LTD',
  },
  {
    cik: '0000941519',
    value: '000094151,STEPH & CO',
    name: 'STEPH & CO',
    label: 'STEPH & CO',
  },
  {
    cik: '0001961632',
    value: '0001961632,STEPHENS CONSULTING, LLC',
    name: 'STEPHENS CONSULTING, LLC',
    label: 'STEPHENS CONSULTING, LLC',
  },
  {
    cik: '0001726808',
    value: '0001726808,STEPHENS GROUP ASSET MANAGEMENT, LLC',
    name: 'STEPHENS GROUP ASSET MANAGEMENT, LLC',
    label: 'STEPHENS GROUP ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001427999',
    value: '0001427999,STEPHENS GROUP, LLC',
    name: 'STEPHENS GROUP, LLC',
    label: 'STEPHENS GROUP, LLC',
  },
  {
    cik: '0000757657',
    value: '0000757657,STEPHENS INC /AR/',
    name: 'STEPHENS INC /AR/',
    label: 'STEPHENS INC /AR/',
  },
  {
    cik: '0001314440',
    value: '0001314440,STEPHENS INVESTMENT MANAGEMENT GROUP LLC',
    name: 'STEPHENS INVESTMENT MANAGEMENT GROUP LLC',
    label: 'STEPHENS INVESTMENT MANAGEMENT GROUP LLC',
  },
  {
    cik: '0000941519',
    value: '0000941519,STEPHENSON NATIONAL BANK & TRUST',
    name: 'STEPHENSON NATIONAL BANK & TRUST',
    label: 'STEPHENSON NATIONAL BANK & TRUST',
  },
  {
    cik: '0001502287',
    value: '0001502287,STEPSTONE GROUP LP',
    name: 'STEPSTONE GROUP LP',
    label: 'STEPSTONE GROUP LP',
  },
  {
    cik: '0001329883',
    value: '0001329883,STERLING CAPITAL MANAGEMENT LLC',
    name: 'STERLING CAPITAL MANAGEMENT LLC',
    label: 'STERLING CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001844278',
    value: '0001844278,STERLING FINANCIAL PLANNING, INC.',
    name: 'STERLING FINANCIAL PLANNING, INC.',
    label: 'STERLING FINANCIAL PLANNING, INC.',
  },
  {
    cik: '0001611545',
    value: '0001611545,STERLING FUND MANAGEMENT LLC',
    name: 'STERLING FUND MANAGEMENT LLC',
    label: 'STERLING FUND MANAGEMENT LLC',
  },
  {
    cik: '0001551787',
    value: '0001551787,STERLING GLOBAL STRATEGIES LLC',
    name: 'STERLING GLOBAL STRATEGIES LLC',
    label: 'STERLING GLOBAL STRATEGIES LLC',
  },
  {
    cik: '0001009198',
    value: '0001009198,STERLING INVESTMENT ADVISORS LLC /ADV',
    name: 'STERLING INVESTMENT ADVISORS LLC /ADV',
    label: 'STERLING INVESTMENT ADVISORS LLC /ADV',
  },
  {
    cik: '0001701132',
    value: '0001701132,STERLING INVESTMENT ADVISORS, LTD.',
    name: 'STERLING INVESTMENT ADVISORS, LTD.',
    label: 'STERLING INVESTMENT ADVISORS, LTD.',
  },
  {
    cik: '0001925853',
    value: '0001925853,STERLING INVESTMENT COUNSEL, LLC',
    name: 'STERLING INVESTMENT COUNSEL, LLC',
    label: 'STERLING INVESTMENT COUNSEL, LLC',
  },
  {
    cik: '0001509873',
    value: '0001509873,STERLING INVESTMENT MANAGEMENT, INC.',
    name: 'STERLING INVESTMENT MANAGEMENT, INC.',
    label: 'STERLING INVESTMENT MANAGEMENT, INC.',
  },
  {
    cik: '0001509873',
    value: '0001509873,STERLING INVESTMENT MANAGEMENT, LLC',
    name: 'STERLING INVESTMENT MANAGEMENT, LLC',
    label: 'STERLING INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001574234',
    value: '0001574234,STERLING INVESTMENT PARTNERS MANAGEMENT II LLC',
    name: 'STERLING INVESTMENT PARTNERS MANAGEMENT II LLC',
    label: 'STERLING INVESTMENT PARTNERS MANAGEMENT II LLC',
  },
  {
    cik: '0001842015',
    value: '0001842015,STERLING MANOR FINANCIAL, LLC',
    name: 'STERLING MANOR FINANCIAL, LLC',
    label: 'STERLING MANOR FINANCIAL, LLC',
  },
  {
    cik: '0001590656',
    value: '0001590656,STERLING RIDGE CAPITAL MANAGEMENT LP',
    name: 'STERLING RIDGE CAPITAL MANAGEMENT LP',
    label: 'STERLING RIDGE CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001492996',
    value: '0001492996,STERNE AGEE GROUP, INC.',
    name: 'STERNE AGEE GROUP, INC.',
    label: 'STERNE AGEE GROUP, INC.',
  },
  {
    cik: '0001438574',
    value: '0001438574,STERNECK CAPITAL MANAGEMENT, LLC',
    name: 'STERNECK CAPITAL MANAGEMENT, LLC',
    label: 'STERNECK CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001812093',
    value: '0001812093,STEVARD, LLC',
    name: 'STEVARD, LLC',
    label: 'STEVARD, LLC',
  },
  {
    cik: '0001134813',
    value: '0001134813,STEVENS CAPITAL MANAGEMENT LP',
    name: 'STEVENS CAPITAL MANAGEMENT LP',
    label: 'STEVENS CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001388076',
    value: '0001388076,STEVENS FIRST PRINCIPLES INVESTMENT ADVISORS',
    name: 'STEVENS FIRST PRINCIPLES INVESTMENT ADVISORS',
    label: 'STEVENS FIRST PRINCIPLES INVESTMENT ADVISORS',
  },
  {
    cik: '0000919533',
    value: '0000919533,STEWARD CAPITAL MANAGEMENT/',
    name: 'STEWARD CAPITAL MANAGEMENT/',
    label: 'STEWARD CAPITAL MANAGEMENT/',
  },
  {
    cik: '0001906349',
    value: '0001906349,STEWARD FINANCIAL GROUP LLC',
    name: 'STEWARD FINANCIAL GROUP LLC',
    label: 'STEWARD FINANCIAL GROUP LLC',
  },
  {
    cik: '0001723397',
    value: '0001723397,STEWARD PARTNERS INVESTMENT ADVISORY, LLC',
    name: 'STEWARD PARTNERS INVESTMENT ADVISORY, LLC',
    label: 'STEWARD PARTNERS INVESTMENT ADVISORY, LLC',
  },
  {
    cik: '0001733219',
    value: '0001733219,STEWARDSHIP ADVISORS, LLC',
    name: 'STEWARDSHIP ADVISORS, LLC',
    label: 'STEWARDSHIP ADVISORS, LLC',
  },
  {
    cik: '0001740729',
    value: '0001740729,STEWARDSHIP FINANCIAL ADVISORS, LLC',
    name: 'STEWARDSHIP FINANCIAL ADVISORS, LLC',
    label: 'STEWARDSHIP FINANCIAL ADVISORS, LLC',
  },
  {
    cik: '0001803340',
    value: '0001803340,STEWART ASSET MANAGEMENT, LLC',
    name: 'STEWART ASSET MANAGEMENT, LLC',
    label: 'STEWART ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0000860580',
    value: '0000860580,STEWART & PATTEN CO LLC',
    name: 'STEWART & PATTEN CO LLC',
    label: 'STEWART & PATTEN CO LLC',
  },
  {
    cik: '0001307493',
    value: '0001307493,STEWART WEST INDIES TRADING CO., LTD',
    name: 'STEWART WEST INDIES TRADING CO., LTD',
    label: 'STEWART WEST INDIES TRADING CO., LTD',
  },
  {
    cik: '0000831571',
    value: '0000831571,ST GERMAIN D J CO INC',
    name: 'ST GERMAIN D J CO INC',
    label: 'ST GERMAIN D J CO INC',
  },
  {
    cik: '0001388279',
    value: '0001388279,STIEVEN CAPITAL ADVISORS, L.P.',
    name: 'STIEVEN CAPITAL ADVISORS, L.P.',
    label: 'STIEVEN CAPITAL ADVISORS, L.P.',
  },
  {
    cik: '0000720672',
    value: '0000720672,STIFEL FINANCIAL CORP',
    name: 'STIFEL FINANCIAL CORP',
    label: 'STIFEL FINANCIAL CORP',
  },
  {
    cik: '0001904832',
    value: '0001904832,STILES FINANCIAL SERVICES INC',
    name: 'STILES FINANCIAL SERVICES INC',
    label: 'STILES FINANCIAL SERVICES INC',
  },
  {
    cik: '0001482970',
    value: '0001482970,STILLWATER CAPITAL ADVISORS, LLC',
    name: 'STILLWATER CAPITAL ADVISORS, LLC',
    label: 'STILLWATER CAPITAL ADVISORS, LLC',
  },
  {
    cik: '0001478775',
    value: '0001478775,STILLWATER INVESTMENT MANAGEMENT, LLC',
    name: 'STILLWATER INVESTMENT MANAGEMENT, LLC',
    label: 'STILLWATER INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001638853',
    value: '0001638853,STILLWATER INVESTMENT MANAGEMENT, L.P.',
    name: 'STILLWATER INVESTMENT MANAGEMENT, L.P.',
    label: 'STILLWATER INVESTMENT MANAGEMENT, L.P.',
  },
  {
    cik: '0001113303',
    value: '0001113303,STILWELL JOSEPH',
    name: 'STILWELL JOSEPH',
    label: 'STILWELL JOSEPH',
  },
  {
    cik: '0001397076',
    value: '0001397076,STILWELL VALUE LLC',
    name: 'STILWELL VALUE LLC',
    label: 'STILWELL VALUE LLC',
  },
  {
    cik: '0001901055',
    value: '0001901055,ST. JAMES INVESTMENT ADVISORS, LLC',
    name: 'ST. JAMES INVESTMENT ADVISORS, LLC',
    label: 'ST. JAMES INVESTMENT ADVISORS, LLC',
  },
  {
    cik: '0001504492',
    value: '0001504492,ST. JAMES INVESTMENT COMPANY, LLC',
    name: 'ST. JAMES INVESTMENT COMPANY, LLC',
    label: 'ST. JAMES INVESTMENT COMPANY, LLC',
  },
  {
    cik: '0001605522',
    value: '0001605522,ST. JOHNS INVESTMENT MANAGEMENT COMPANY, LLC',
    name: 'ST. JOHNS INVESTMENT MANAGEMENT COMPANY, LLC',
    label: 'ST. JOHNS INVESTMENT MANAGEMENT COMPANY, LLC',
  },
  {
    cik: '0001661144',
    value: '0001661144,ST. LOUIS TRUST CO',
    name: 'ST. LOUIS TRUST CO',
    label: 'ST. LOUIS TRUST CO',
  },
  {
    cik: '0001505183',
    value: '0001505183,STOCKBRIDGE PARTNERS LLC',
    name: 'STOCKBRIDGE PARTNERS LLC',
    label: 'STOCKBRIDGE PARTNERS LLC',
  },
  {
    cik: '0001389400',
    value: '0001389400,STOCKMAN ASSET MANAGEMENT',
    name: 'STOCKMAN ASSET MANAGEMENT',
    label: 'STOCKMAN ASSET MANAGEMENT',
  },
  {
    cik: '0001389400',
    value: '0001389400,STOCKMAN WEALTH MANAGEMENT, INC.',
    name: 'STOCKMAN WEALTH MANAGEMENT, INC.',
    label: 'STOCKMAN WEALTH MANAGEMENT, INC.',
  },
  {
    cik: '0000741073',
    value: '0000741073,STOCK YARDS BANK & TRUST CO',
    name: 'STOCK YARDS BANK & TRUST CO',
    label: 'STOCK YARDS BANK & TRUST CO',
  },
  {
    cik: '0001734460',
    value: '0001734460,STOKES CAPITAL ADVISORS, LLC',
    name: 'STOKES CAPITAL ADVISORS, LLC',
    label: 'STOKES CAPITAL ADVISORS, LLC',
  },
  {
    cik: '0001802278',
    value: '0001802278,STOKES FAMILY OFFICE, LLC',
    name: 'STOKES FAMILY OFFICE, LLC',
    label: 'STOKES FAMILY OFFICE, LLC',
  },
  {
    cik: '0001653866',
    value: '0001653866,STOKES & HUBBELL CAPITAL MANAGEMENT, LLC',
    name: 'STOKES & HUBBELL CAPITAL MANAGEMENT, LLC',
    label: 'STOKES & HUBBELL CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001808195',
    value: '000180819,STOLPER CO',
    name: 'STOLPER CO',
    label: 'STOLPER CO',
  },
  {
    cik: '0001904459',
    value: '0001904459,STONE ASSET MANAGEMENT INC.',
    name: 'STONE ASSET MANAGEMENT INC.',
    label: 'STONE ASSET MANAGEMENT INC.',
  },
  {
    cik: '0001600035',
    value: '0001600035,STONEBRIDGE CAPITAL ADVISORS LLC',
    name: 'STONEBRIDGE CAPITAL ADVISORS LLC',
    label: 'STONEBRIDGE CAPITAL ADVISORS LLC',
  },
  {
    cik: '0000051812',
    value: '0000051812,STONEBRIDGE CAPITAL MANAGEMENT INC',
    name: 'STONEBRIDGE CAPITAL MANAGEMENT INC',
    label: 'STONEBRIDGE CAPITAL MANAGEMENT INC',
  },
  {
    cik: '0001802494',
    value: '0001802494,STONEBRIDGE FINANCIAL PLANNING GROUP, LLC',
    name: 'STONEBRIDGE FINANCIAL PLANNING GROUP, LLC',
    label: 'STONEBRIDGE FINANCIAL PLANNING GROUP, LLC',
  },
  {
    cik: '0001904154',
    value: '0001904154,STONEGATE INVESTMENT GROUP, LLC',
    name: 'STONEGATE INVESTMENT GROUP, LLC',
    label: 'STONEGATE INVESTMENT GROUP, LLC',
  },
  {
    cik: '0001786379',
    value: '0001786379,STONEHAGE FLEMING FINANCIAL SERVICES HOLDINGS LTD',
    name: 'STONEHAGE FLEMING FINANCIAL SERVICES HOLDINGS LTD',
    label: 'STONEHAGE FLEMING FINANCIAL SERVICES HOLDINGS LTD',
  },
  {
    cik: '0001790827',
    value: '0001790827,STONEHAGE FLEMING (UK) LTD',
    name: 'STONEHAGE FLEMING (UK) LTD',
    label: 'STONEHAGE FLEMING (UK) LTD',
  },
  {
    cik: '0001508123',
    value: '0001508123,STONE HARBOR INVESTMENT PARTNERS LP',
    name: 'STONE HARBOR INVESTMENT PARTNERS LP',
    label: 'STONE HARBOR INVESTMENT PARTNERS LP',
  },
  {
    cik: '0001550191',
    value: '0001550191,STONEHEARTH CAPITAL MANAGEMENT, LLC',
    name: 'STONEHEARTH CAPITAL MANAGEMENT, LLC',
    label: 'STONEHEARTH CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001266227',
    value: '0001266227,STONEHILL CAPITAL MANAGEMENT LLC',
    name: 'STONEHILL CAPITAL MANAGEMENT LLC',
    label: 'STONEHILL CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001589943',
    value: '0001589943,STONE HOUSE CAPITAL MANAGEMENT, LLC',
    name: 'STONE HOUSE CAPITAL MANAGEMENT, LLC',
    label: 'STONE HOUSE CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001696497',
    value: '0001696497,STONE HOUSE INVESTMENT MANAGEMENT, LLC',
    name: 'STONE HOUSE INVESTMENT MANAGEMENT, LLC',
    label: 'STONE HOUSE INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001614505',
    value: '0001614505,STONE MILLINER ASSET MANAGEMENT AG',
    name: 'STONE MILLINER ASSET MANAGEMENT AG',
    label: 'STONE MILLINER ASSET MANAGEMENT AG',
  },
  {
    cik: '0001656524',
    value: '0001656524,STONEPEAK GP INVESTORS MANAGER LLC',
    name: 'STONEPEAK GP INVESTORS MANAGER LLC',
    label: 'STONEPEAK GP INVESTORS MANAGER LLC',
  },
  {
    cik: '0001440771',
    value: '0001440771,STONEPINE CAPITAL MANAGEMENT, LLC',
    name: 'STONEPINE CAPITAL MANAGEMENT, LLC',
    label: 'STONEPINE CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001332638',
    value: '0001332638,STONE POINT CAPITAL LLC',
    name: 'STONE POINT CAPITAL LLC',
    label: 'STONE POINT CAPITAL LLC',
  },
  {
    cik: '0001908192',
    value: '0001908192,STONE POINT WEALTH LLC',
    name: 'STONE POINT WEALTH LLC',
    label: 'STONE POINT WEALTH LLC',
  },
  {
    cik: '0001584728',
    value: '0001584728,STONE RIDGE ASSET MANAGEMENT LLC',
    name: 'STONE RIDGE ASSET MANAGEMENT LLC',
    label: 'STONE RIDGE ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001108831',
    value: '0001108831,STONERIDGE INVESTMENT PARTNERS LLC',
    name: 'STONERIDGE INVESTMENT PARTNERS LLC',
    label: 'STONERIDGE INVESTMENT PARTNERS LLC',
  },
  {
    cik: '0001494179',
    value: '0001494179,STONERISE CAPITAL MANAGEMENT, LLC',
    name: 'STONERISE CAPITAL MANAGEMENT, LLC',
    label: 'STONERISE CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001512746',
    value: '0001512746,STONE RUN CAPITAL, LLC',
    name: 'STONE RUN CAPITAL, LLC',
    label: 'STONE RUN CAPITAL, LLC',
  },
  {
    cik: '0001548497',
    value: '0001548497,STONE TORO INVESTMENT ADVISERS, LP',
    name: 'STONE TORO INVESTMENT ADVISERS, LP',
    label: 'STONE TORO INVESTMENT ADVISERS, LP',
  },
  {
    cik: '0000913760',
    value: '0000913760,STONEX GROUP INC.',
    name: 'STONEX GROUP INC.',
    label: 'STONEX GROUP INC.',
  },
  {
    cik: '0001325261',
    value: '0001325261,STONNINGTON GROUP, LLC',
    name: 'STONNINGTON GROUP, LLC',
    label: 'STONNINGTON GROUP, LLC',
  },
  {
    cik: '0001776023',
    value: '0001776023,STONY POINT CAPITAL LLC',
    name: 'STONY POINT CAPITAL LLC',
    label: 'STONY POINT CAPITAL LLC',
  },
  {
    cik: '0001731359',
    value: '0001731359,STONY POINT WEALTH MANAGEMENT INC.',
    name: 'STONY POINT WEALTH MANAGEMENT INC.',
    label: 'STONY POINT WEALTH MANAGEMENT INC.',
  },
  {
    cik: '0001729093',
    value: '0001729093,STORDAHL CAPITAL MANAGEMENT, INC.',
    name: 'STORDAHL CAPITAL MANAGEMENT, INC.',
    label: 'STORDAHL CAPITAL MANAGEMENT, INC.',
  },
  {
    cik: '0001693141',
    value: '0001693141,STORMBORN CAPITAL MANAGEMENT, LLC',
    name: 'STORMBORN CAPITAL MANAGEMENT, LLC',
    label: 'STORMBORN CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001928010',
    value: '0001928010,STORM KING CAPITAL PARTNERS, LLC',
    name: 'STORM KING CAPITAL PARTNERS, LLC',
    label: 'STORM KING CAPITAL PARTNERS, LLC',
  },
  {
    cik: '0001902506',
    value: '0001902506,STRAIGHT PATH WEALTH MANAGEMENT',
    name: 'STRAIGHT PATH WEALTH MANAGEMENT',
    label: 'STRAIGHT PATH WEALTH MANAGEMENT',
  },
  {
    cik: '0000728882',
    value: '0000728882,STRALEM & CO INC',
    name: 'STRALEM & CO INC',
    label: 'STRALEM & CO INC',
  },
  {
    cik: '0001569119',
    value: '0001569119,STRATEGIC ADVISORS LLC',
    name: 'STRATEGIC ADVISORS LLC',
    label: 'STRATEGIC ADVISORS LLC',
  },
  {
    cik: '0001888541',
    value: '0001888541,STRATEGIC ASSET MANAGEMENT, LLC',
    name: 'STRATEGIC ASSET MANAGEMENT, LLC',
    label: 'STRATEGIC ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001767821',
    value: '0001767821,STRATEGIC BLUEPRINT, LLC',
    name: 'STRATEGIC BLUEPRINT, LLC',
    label: 'STRATEGIC BLUEPRINT, LLC',
  },
  {
    cik: '0001731127',
    value: '0001731127,STRATEGIC CAPITAL ADVISERS, INC.',
    name: 'STRATEGIC CAPITAL ADVISERS, INC.',
    label: 'STRATEGIC CAPITAL ADVISERS, INC.',
  },
  {
    cik: '0001625243',
    value: '0001625243,STRATEGIC CAPITAL ALTERNATIVES LLC',
    name: 'STRATEGIC CAPITAL ALTERNATIVES LLC',
    label: 'STRATEGIC CAPITAL ALTERNATIVES LLC',
  },
  {
    cik: '0001536037',
    value: '0001536037,STRATEGIC CAPITAL GROUP LLC',
    name: 'STRATEGIC CAPITAL GROUP LLC',
    label: 'STRATEGIC CAPITAL GROUP LLC',
  },
  {
    cik: '0001843578',
    value: '0001843578,STRATEGIC EQUITY MANAGEMENT',
    name: 'STRATEGIC EQUITY MANAGEMENT',
    label: 'STRATEGIC EQUITY MANAGEMENT',
  },
  {
    cik: '0001729755',
    value: '0001729755,STRATEGIC FAMILY WEALTH COUNSELORS, L.L.C.',
    name: 'STRATEGIC FAMILY WEALTH COUNSELORS, L.L.C.',
    label: 'STRATEGIC FAMILY WEALTH COUNSELORS, L.L.C.',
  },
  {
    cik: '0001665199',
    value: '0001665199,STRATEGIC FINANCIAL GROUP, LLC',
    name: 'STRATEGIC FINANCIAL GROUP, LLC',
    label: 'STRATEGIC FINANCIAL GROUP, LLC',
  },
  {
    cik: '0001802277',
    value: '0001802277,STRATEGIC FINANCIAL PLANNING, INC.',
    name: 'STRATEGIC FINANCIAL PLANNING, INC.',
    label: 'STRATEGIC FINANCIAL PLANNING, INC.',
  },
  {
    cik: '0001432539',
    value: '0001432539,STRATEGIC FINANCIAL SERVICES, INC,',
    name: 'STRATEGIC FINANCIAL SERVICES, INC,',
    label: 'STRATEGIC FINANCIAL SERVICES, INC,',
  },
  {
    cik: '0001580212',
    value: '0001580212,STRATEGIC GLOBAL ADVISORS, LLC',
    name: 'STRATEGIC GLOBAL ADVISORS, LLC',
    label: 'STRATEGIC GLOBAL ADVISORS, LLC',
  },
  {
    cik: '0001540306',
    value: '0001540306,STRATEGIC INCOME MANAGEMENT, LLC',
    name: 'STRATEGIC INCOME MANAGEMENT, LLC',
    label: 'STRATEGIC INCOME MANAGEMENT, LLC',
  },
  {
    cik: '0001760540',
    value: '0001760540,STRATEGIC INVESTMENT ADVISORS / MI',
    name: 'STRATEGIC INVESTMENT ADVISORS / MI',
    label: 'STRATEGIC INVESTMENT ADVISORS / MI',
  },
  {
    cik: '0000846633',
    value: '0000846633,STRATEGIC INVESTMENT MANAGEMENT, LLC',
    name: 'STRATEGIC INVESTMENT MANAGEMENT, LLC',
    label: 'STRATEGIC INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001688150',
    value: '0001688150,STRATEGIC INVESTMENT OPPORTUNITIES LLC',
    name: 'STRATEGIC INVESTMENT OPPORTUNITIES LLC',
    label: 'STRATEGIC INVESTMENT OPPORTUNITIES LLC',
  },
  {
    cik: '0001295044',
    value: '0001295044,STRATEGIC POINT INVESTMENT ADVISORS, LLC',
    name: 'STRATEGIC POINT INVESTMENT ADVISORS, LLC',
    label: 'STRATEGIC POINT INVESTMENT ADVISORS, LLC',
  },
  {
    cik: '0001904067',
    value: '0001904067,STRATEGIC VALUE BANK PARTNERS LLC',
    name: 'STRATEGIC VALUE BANK PARTNERS LLC',
    label: 'STRATEGIC VALUE BANK PARTNERS LLC',
  },
  {
    cik: '0001301912',
    value: '0001301912,STRATEGIC VALUE PARTNERS, LLC',
    name: 'STRATEGIC VALUE PARTNERS, LLC',
    label: 'STRATEGIC VALUE PARTNERS, LLC',
  },
  {
    cik: '0001720887',
    value: '0001720887,STRATEGIC VISION INVESTMENT LTD',
    name: 'STRATEGIC VISION INVESTMENT LTD',
    label: 'STRATEGIC VISION INVESTMENT LTD',
  },
  {
    cik: '0001548916',
    value: '0001548916,STRATEGIC WEALTH ADVISORS GROUP, LLC',
    name: 'STRATEGIC WEALTH ADVISORS GROUP, LLC',
    label: 'STRATEGIC WEALTH ADVISORS GROUP, LLC',
  },
  {
    cik: '0001802365',
    value: '0001802365,STRATEGIC WEALTH DESIGNERS',
    name: 'STRATEGIC WEALTH DESIGNERS',
    label: 'STRATEGIC WEALTH DESIGNERS',
  },
  {
    cik: '0001802365',
    value: '0001802365,STRATEGIC WEALTH INVESTMENT GROUP, LLC',
    name: 'STRATEGIC WEALTH INVESTMENT GROUP, LLC',
    label: 'STRATEGIC WEALTH INVESTMENT GROUP, LLC',
  },
  {
    cik: '0001750403',
    value: '0001750403,STRATEGIC WEALTH MANAGEMENT GROUP, LLC',
    name: 'STRATEGIC WEALTH MANAGEMENT GROUP, LLC',
    label: 'STRATEGIC WEALTH MANAGEMENT GROUP, LLC',
  },
  {
    cik: '0000849854',
    value: '0000849854,STRATEGIC WEALTH MANAGEMENT INC',
    name: 'STRATEGIC WEALTH MANAGEMENT INC',
    label: 'STRATEGIC WEALTH MANAGEMENT INC',
  },
  {
    cik: '0001682677',
    value: '0001682677,STRATEGIC WEALTH PARTNERS GROUP LLC',
    name: 'STRATEGIC WEALTH PARTNERS GROUP LLC',
    label: 'STRATEGIC WEALTH PARTNERS GROUP LLC',
  },
  {
    cik: '0001720777',
    value: '0001720777,STRATEGIC WEALTH PARTNERS, LTD.',
    name: 'STRATEGIC WEALTH PARTNERS, LTD.',
    label: 'STRATEGIC WEALTH PARTNERS, LTD.',
  },
  {
    cik: '0001665199',
    value: '0001665199,STRATEGIQ FINANCIAL GROUP, LLC',
    name: 'STRATEGIQ FINANCIAL GROUP, LLC',
    label: 'STRATEGIQ FINANCIAL GROUP, LLC',
  },
  {
    cik: '0001166716',
    value: '0001166716,STRATEGY ASSET MANAGERS LLC',
    name: 'STRATEGY ASSET MANAGERS LLC',
    label: 'STRATEGY ASSET MANAGERS LLC',
  },
  {
    cik: '0001592413',
    value: '0001592413,STRATEGY CAPITAL LLC',
    name: 'STRATEGY CAPITAL LLC',
    label: 'STRATEGY CAPITAL LLC',
  },
  {
    cik: '0001553656',
    value: '0001553656,STRATFORD CONSULTING, LLC',
    name: 'STRATFORD CONSULTING, LLC',
    label: 'STRATFORD CONSULTING, LLC',
  },
  {
    cik: '0001589278',
    value: '0001589278,STRATIFI, LLC',
    name: 'STRATIFI, LLC',
    label: 'STRATIFI, LLC',
  },
  {
    cik: '0001707202',
    value: '0001707202,STRATOS WEALTH ADVISORS, LLC',
    name: 'STRATOS WEALTH ADVISORS, LLC',
    label: 'STRATOS WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001612865',
    value: '0001612865,STRATOS WEALTH PARTNERS, LTD.',
    name: 'STRATOS WEALTH PARTNERS, LTD.',
    label: 'STRATOS WEALTH PARTNERS, LTD.',
  },
  {
    cik: '0000763910',
    value: '0000763910,STRATTON MANAGEMENT CO',
    name: 'STRATTON MANAGEMENT CO',
    label: 'STRATTON MANAGEMENT CO',
  },
  {
    cik: '0001471474',
    value: '0001471474,STREETTALK ADVISORS, LLC',
    name: 'STREETTALK ADVISORS, LLC',
    label: 'STREETTALK ADVISORS, LLC',
  },
  {
    cik: '0001921196',
    value: '0001921196,STRENGTHENING FAMILIES & COMMUNITIES, LLC',
    name: 'STRENGTHENING FAMILIES & COMMUNITIES, LLC',
    label: 'STRENGTHENING FAMILIES & COMMUNITIES, LLC',
  },
  {
    cik: '0001625800',
    value: '0001625800,STRID GROUP, LLC',
    name: 'STRID GROUP, LLC',
    label: 'STRID GROUP, LLC',
  },
  {
    cik: '0001599210',
    value: '0001599210,STRINGER ASSET MANAGEMENT, LLC',
    name: 'STRINGER ASSET MANAGEMENT, LLC',
    label: 'STRINGER ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001812155',
    value: '0001812155,STRONGBOX WEALTH, LLC',
    name: 'STRONGBOX WEALTH, LLC',
    label: 'STRONGBOX WEALTH, LLC',
  },
  {
    cik: '0001889322',
    value: '0001889322,STRONG TOWER ADVISORY SERVICES',
    name: 'STRONG TOWER ADVISORY SERVICES',
    label: 'STRONG TOWER ADVISORY SERVICES',
  },
  {
    cik: '0000820478',
    value: '00008204,STRS OHIO',
    name: 'STRS OHIO',
    label: 'STRS OHIO',
  },
  {
    cik: '0001655140',
    value: '0001655140,STRYCKER VIEW CAPITAL LLC',
    name: 'STRYCKER VIEW CAPITAL LLC',
    label: 'STRYCKER VIEW CAPITAL LLC',
  },
  {
    cik: '0001653253',
    value: '0001653253,STUART CHAUSSEE & ASSOCIATES, INC.',
    name: 'STUART CHAUSSEE & ASSOCIATES, INC.',
    label: 'STUART CHAUSSEE & ASSOCIATES, INC.',
  },
  {
    cik: '0001893893',
    value: '0001893893,STUDIO INVESTMENT MANAGEMENT LLC',
    name: 'STUDIO INVESTMENT MANAGEMENT LLC',
    label: 'STUDIO INVESTMENT MANAGEMENT LLC',
  },
  {
    cik: '0001702698',
    value: '0001702698,STUMPF CAPITAL MANAGEMENT LLC',
    name: 'STUMPF CAPITAL MANAGEMENT LLC',
    label: 'STUMPF CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001720346',
    value: '0001720346,STURGEON VENTURES LLP',
    name: 'STURGEON VENTURES LLP',
    label: 'STURGEON VENTURES LLP',
  },
  {
    cik: '0001328533',
    value: '0001328533,STUX CAPITAL MANAGEMENT, LLC',
    name: 'STUX CAPITAL MANAGEMENT, LLC',
    label: 'STUX CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001086131',
    value: '0001086131,STUYVESANT CAPITAL MANAGEMENT',
    name: 'STUYVESANT CAPITAL MANAGEMENT',
    label: 'STUYVESANT CAPITAL MANAGEMENT',
  },
  {
    cik: '0001904897',
    value: '0001904897,STYRAX CAPITAL, LP',
    name: 'STYRAX CAPITAL, LP',
    label: 'STYRAX CAPITAL, LP',
  },
  {
    cik: '0001721780',
    value: '0001721780,SUCCESSFUL PORTFOLIOS LLC',
    name: 'SUCCESSFUL PORTFOLIOS LLC',
    label: 'SUCCESSFUL PORTFOLIOS LLC',
  },
  {
    cik: '0000897293',
    value: '0000897293,SUFFOLK CAPITAL MANAGEMENT LLC',
    name: 'SUFFOLK CAPITAL MANAGEMENT LLC',
    label: 'SUFFOLK CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001664864',
    value: '0001664864,SUGARLOAF WEALTH MANAGEMENT, LLC',
    name: 'SUGARLOAF WEALTH MANAGEMENT, LLC',
    label: 'SUGARLOAF WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001599904',
    value: '0001599904,SULLIVAN, BRUYETTE, SPEROS & BLANEY, INC.',
    name: 'SULLIVAN, BRUYETTE, SPEROS & BLANEY, INC.',
    label: 'SULLIVAN, BRUYETTE, SPEROS & BLANEY, INC.',
  },
  {
    cik: '0001547831',
    value: '0001547831,SUMIT CAPITAL, LLC',
    name: 'SUMIT CAPITAL, LLC',
    label: 'SUMIT CAPITAL, LLC',
  },
  {
    cik: '0000937760',
    value: '0000937760,SUMITOMO LIFE INSURANCE CO',
    name: 'SUMITOMO LIFE INSURANCE CO',
    label: 'SUMITOMO LIFE INSURANCE CO',
  },
  {
    cik: '0001411530',
    value: '0001411530,SUMITOMO MITSUI ASSET MANAGEMENT COMPANY, LTD',
    name: 'SUMITOMO MITSUI ASSET MANAGEMENT COMPANY, LTD',
    label: 'SUMITOMO MITSUI ASSET MANAGEMENT COMPANY, LTD',
  },
  {
    cik: '0001411530',
    value: '0001411530,SUMITOMO MITSUI DS ASSET MANAGEMENT COMPANY, LTD',
    name: 'SUMITOMO MITSUI DS ASSET MANAGEMENT COMPANY, LTD',
    label: 'SUMITOMO MITSUI DS ASSET MANAGEMENT COMPANY, LTD',
  },
  {
    cik: '0001022837',
    value: '0001022837,SUMITOMO MITSUI FINANCIAL GROUP, INC.',
    name: 'SUMITOMO MITSUI FINANCIAL GROUP, INC.',
    label: 'SUMITOMO MITSUI FINANCIAL GROUP, INC.',
  },
  {
    cik: '0001475365',
    value: '0001475365,SUMITOMO MITSUI TRUST HOLDINGS, INC.',
    name: 'SUMITOMO MITSUI TRUST HOLDINGS, INC.',
    label: 'SUMITOMO MITSUI TRUST HOLDINGS, INC.',
  },
  {
    cik: '0001846436',
    value: '0001846436,SUMMERHAVEN INVESTMENT MANAGEMENT, LLC',
    name: 'SUMMERHAVEN INVESTMENT MANAGEMENT, LLC',
    label: 'SUMMERHAVEN INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001604873',
    value: '0001604873,SUMMER ROAD LLC',
    name: 'SUMMER ROAD LLC',
    label: 'SUMMER ROAD LLC',
  },
  {
    cik: '0001509974',
    value: '0001509974,SUMMIT ASSET MANAGEMENT, LLC',
    name: 'SUMMIT ASSET MANAGEMENT, LLC',
    label: 'SUMMIT ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001056868',
    value: '0001056868,SUMMIT CAPITAL MANAGEMENT LLC',
    name: 'SUMMIT CAPITAL MANAGEMENT LLC',
    label: 'SUMMIT CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001333709',
    value: '0001333709,SUMMIT CREEK ADVISORS LLC',
    name: 'SUMMIT CREEK ADVISORS LLC',
    label: 'SUMMIT CREEK ADVISORS LLC',
  },
  {
    cik: '0001608062',
    value: '0001608062,SUMMIT EQUITIES INC',
    name: 'SUMMIT EQUITIES INC',
    label: 'SUMMIT EQUITIES INC',
  },
  {
    cik: '0000811808',
    value: '0000811808,SUMMIT FINANCIAL GROUP INC',
    name: 'SUMMIT FINANCIAL GROUP INC',
    label: 'SUMMIT FINANCIAL GROUP INC',
  },
  {
    cik: '0000811808',
    value: '0000811808,SUMMIT FINANCIAL GROUP, INC.',
    name: 'SUMMIT FINANCIAL GROUP, INC.',
    label: 'SUMMIT FINANCIAL GROUP, INC.',
  },
  {
    cik: '0001765536',
    value: '0001765536,SUMMIT FINANCIAL, LLC',
    name: 'SUMMIT FINANCIAL, LLC',
    label: 'SUMMIT FINANCIAL, LLC',
  },
  {
    cik: '0001625246',
    value: '0001625246,SUMMIT FINANCIAL STRATEGIES, INC.',
    name: 'SUMMIT FINANCIAL STRATEGIES, INC.',
    label: 'SUMMIT FINANCIAL STRATEGIES, INC.',
  },
  {
    cik: '0001632283',
    value: '0001632283,SUMMIT FINANCIAL WEALTH ADVISORS, LLC',
    name: 'SUMMIT FINANCIAL WEALTH ADVISORS, LLC',
    label: 'SUMMIT FINANCIAL WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001697953',
    value: '0001697953,SUMMIT GLOBAL INVESTMENTS',
    name: 'SUMMIT GLOBAL INVESTMENTS',
    label: 'SUMMIT GLOBAL INVESTMENTS',
  },
  {
    cik: '0001357634',
    value: '0001357634,SUMMIT GLOBAL MANAGEMENT, INC.',
    name: 'SUMMIT GLOBAL MANAGEMENT, INC.',
    label: 'SUMMIT GLOBAL MANAGEMENT, INC.',
  },
  {
    cik: '0001055980',
    value: '0001055980,SUMMIT INVESTMENT ADVISORS, INC.',
    name: 'SUMMIT INVESTMENT ADVISORS, INC.',
    label: 'SUMMIT INVESTMENT ADVISORS, INC.',
  },
  {
    cik: '0000830588',
    value: '0000830588,SUMMIT PARTNERS L P',
    name: 'SUMMIT PARTNERS L P',
    label: 'SUMMIT PARTNERS L P',
  },
  {
    cik: '0001638555',
    value: '0001638555,SUMMIT PARTNERS PUBLIC ASSET MANAGEMENT, LLC',
    name: 'SUMMIT PARTNERS PUBLIC ASSET MANAGEMENT, LLC',
    label: 'SUMMIT PARTNERS PUBLIC ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001801413',
    value: '0001801413,SUMMIT PLACE FINANCIAL ADVISORS, LLC',
    name: 'SUMMIT PLACE FINANCIAL ADVISORS, LLC',
    label: 'SUMMIT PLACE FINANCIAL ADVISORS, LLC',
  },
  {
    cik: '0001540951',
    value: '0001540951,SUMMIT ROCK ADVISORS, LLC',
    name: 'SUMMIT ROCK ADVISORS, LLC',
    label: 'SUMMIT ROCK ADVISORS, LLC',
  },
  {
    cik: '0001540951',
    value: '0001540951,SUMMIT ROCK ADVISORS, LP',
    name: 'SUMMIT ROCK ADVISORS, LP',
    label: 'SUMMIT ROCK ADVISORS, LP',
  },
  {
    cik: '0001280043',
    value: '0001280043,SUMMITRY LLC',
    name: 'SUMMITRY LLC',
    label: 'SUMMITRY LLC',
  },
  {
    cik: '0001531593',
    value: '0001531593,SUMMIT SECURITIES GROUP, LLC',
    name: 'SUMMIT SECURITIES GROUP, LLC',
    label: 'SUMMIT SECURITIES GROUP, LLC',
  },
  {
    cik: '0001595533',
    value: '0001595533,SUMMIT STREET CAPITAL MANAGEMENT, LLC',
    name: 'SUMMIT STREET CAPITAL MANAGEMENT, LLC',
    label: 'SUMMIT STREET CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001698478',
    value: '0001698478,SUMMIT TRAIL ADVISORS, LLC',
    name: 'SUMMIT TRAIL ADVISORS, LLC',
    label: 'SUMMIT TRAIL ADVISORS, LLC',
  },
  {
    cik: '0001666659',
    value: '0001666659,SUMMITVIEW INVESTMENT MANAGEMENT',
    name: 'SUMMITVIEW INVESTMENT MANAGEMENT',
    label: 'SUMMITVIEW INVESTMENT MANAGEMENT',
  },
  {
    cik: '0001802865',
    value: '0001802865,SUMMIT WEALTH GROUP, LLC',
    name: 'SUMMIT WEALTH GROUP, LLC',
    label: 'SUMMIT WEALTH GROUP, LLC',
  },
  {
    cik: '0001766995',
    value: '0001766995,SUMMIT WEALTH & RETIREMENT PLANNING, INC.',
    name: 'SUMMIT WEALTH & RETIREMENT PLANNING, INC.',
    label: 'SUMMIT WEALTH & RETIREMENT PLANNING, INC.',
  },
  {
    cik: '0001697729',
    value: '0001697729,SUMMIT X, LLC',
    name: 'SUMMIT X, LLC',
    label: 'SUMMIT X, LLC',
  },
  {
    cik: '0001644795',
    value: '0001644795,SUMWAY DEVELOPMENT LTD.',
    name: 'SUMWAY DEVELOPMENT LTD.',
    label: 'SUMWAY DEVELOPMENT LTD.',
  },
  {
    cik: '0001632932',
    value: '0001632932,SUNBELT SECURITIES, INC.',
    name: 'SUNBELT SECURITIES, INC.',
    label: 'SUNBELT SECURITIES, INC.',
  },
  {
    cik: '0001800158',
    value: '0001800158,SUNBURST FINANCIAL GROUP, LLC',
    name: 'SUNBURST FINANCIAL GROUP, LLC',
    label: 'SUNBURST FINANCIAL GROUP, LLC',
  },
  {
    cik: '0001315059',
    value: '0001315059,SUNCOAST EQUITY MANAGEMENT',
    name: 'SUNCOAST EQUITY MANAGEMENT',
    label: 'SUNCOAST EQUITY MANAGEMENT',
  },
  {
    cik: '0001748269',
    value: '0001748269,SUNESIS ADVISORS, LLC',
    name: 'SUNESIS ADVISORS, LLC',
    label: 'SUNESIS ADVISORS, LLC',
  },
  {
    cik: '0001818759',
    value: '0001818759,SUNFLOWER BANK, N.A.',
    name: 'SUNFLOWER BANK, N.A.',
    label: 'SUNFLOWER BANK, N.A.',
  },
  {
    cik: '0001478417',
    value: '0001478417,SUNFLOWER BANK, NA WEALTH MANAGEMENT',
    name: 'SUNFLOWER BANK, NA WEALTH MANAGEMENT',
    label: 'SUNFLOWER BANK, NA WEALTH MANAGEMENT',
  },
  {
    cik: '0001097362',
    value: '0001097362,SUN LIFE FINANCIAL INC',
    name: 'SUN LIFE FINANCIAL INC',
    label: 'SUN LIFE FINANCIAL INC',
  },
  {
    cik: '0001418948',
    value: '0001418948,SUNNYMEATH ASSET MANAGEMENT, INC.',
    name: 'SUNNYMEATH ASSET MANAGEMENT, INC.',
    label: 'SUNNYMEATH ASSET MANAGEMENT, INC.',
  },
  {
    cik: '0001103882',
    value: '0001103882,SUNRISE PARTNERS LIMITED PARTNERSHIP',
    name: 'SUNRISE PARTNERS LIMITED PARTNERSHIP',
    label: 'SUNRISE PARTNERS LIMITED PARTNERSHIP',
  },
  {
    cik: '0001663900',
    value: '0001663900,SUNRIVER MANAGEMENT LLC',
    name: 'SUNRIVER MANAGEMENT LLC',
    label: 'SUNRIVER MANAGEMENT LLC',
  },
  {
    cik: '0000750556',
    value: '0000750556,SUNTRUST BANKS INC',
    name: 'SUNTRUST BANKS INC',
    label: 'SUNTRUST BANKS INC',
  },
  {
    cik: '0001739424',
    value: '0001739424,SUNTX CAPITAL MANAGEMENT CORP.',
    name: 'SUNTX CAPITAL MANAGEMENT CORP.',
    label: 'SUNTX CAPITAL MANAGEMENT CORP.',
  },
  {
    cik: '0001280493',
    value: '0001280493,SUN VALLEY GOLD LLC',
    name: 'SUN VALLEY GOLD LLC',
    label: 'SUN VALLEY GOLD LLC',
  },
  {
    cik: '0001483065',
    value: '0001483065,SUPPLEMENTAL ANNUITY COLLECTIVE TRUST OF NJ',
    name: 'SUPPLEMENTAL ANNUITY COLLECTIVE TRUST OF NJ',
    label: 'SUPPLEMENTAL ANNUITY COLLECTIVE TRUST OF NJ',
  },
  {
    cik: '0001593842',
    value: '0001593842,SUREVEST INC.',
    name: 'SUREVEST INC.',
    label: 'SUREVEST INC.',
  },
  {
    cik: '0001593842',
    value: '0001593842,SUREVEST, LLC',
    name: 'SUREVEST, LLC',
    label: 'SUREVEST, LLC',
  },
  {
    cik: '0001534908',
    value: '0001534908,SUREVIEW CAPITAL LLC',
    name: 'SUREVIEW CAPITAL LLC',
    label: 'SUREVIEW CAPITAL LLC',
  },
  {
    cik: '0001900195',
    value: '0001900195,SURIENCE PRIVATE WEALTH LLC',
    name: 'SURIENCE PRIVATE WEALTH LLC',
    label: 'SURIENCE PRIVATE WEALTH LLC',
  },
  {
    cik: '0001603858',
    value: '0001603858,SUSA FUND MANAGEMENT LLP',
    name: 'SUSA FUND MANAGEMENT LLP',
    label: 'SUSA FUND MANAGEMENT LLP',
  },
  {
    cik: '0001879628',
    value: '0001879628,SUSQUEHANNA CAPITAL MANAGEMENT, LLC',
    name: 'SUSQUEHANNA CAPITAL MANAGEMENT, LLC',
    label: 'SUSQUEHANNA CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001728195',
    value: '0001728195,SUSQUEHANNA FOUNDATION',
    name: 'SUSQUEHANNA FOUNDATION',
    label: 'SUSQUEHANNA FOUNDATION',
  },
  {
    cik: '0001446179',
    value: '0001446179,SUSQUEHANNA FUNDAMENTAL INVESTMENTS, LLC',
    name: 'SUSQUEHANNA FUNDAMENTAL INVESTMENTS, LLC',
    label: 'SUSQUEHANNA FUNDAMENTAL INVESTMENTS, LLC',
  },
  {
    cik: '0001446194',
    value: '0001446194,SUSQUEHANNA INTERNATIONAL GROUP, LLP',
    name: 'SUSQUEHANNA INTERNATIONAL GROUP, LLP',
    label: 'SUSQUEHANNA INTERNATIONAL GROUP, LLP',
  },
  {
    cik: '0001765924',
    value: '0001765924,SUSQUEHANNA INTERNATIONAL GROUP LTD.',
    name: 'SUSQUEHANNA INTERNATIONAL GROUP LTD.',
    label: 'SUSQUEHANNA INTERNATIONAL GROUP LTD.',
  },
  {
    cik: '0001765923',
    value: '0001765923,SUSQUEHANNA INTERNATIONAL SECURITIES, LTD.',
    name: 'SUSQUEHANNA INTERNATIONAL SECURITIES, LTD.',
    label: 'SUSQUEHANNA INTERNATIONAL SECURITIES, LTD.',
  },
  {
    cik: '0001217675',
    value: '0001217675,SUSQUEHANNA TRUST & INVESTMENT CO',
    name: 'SUSQUEHANNA TRUST & INVESTMENT CO',
    label: 'SUSQUEHANNA TRUST & INVESTMENT CO',
  },
  {
    cik: '0001290668',
    value: '0001290668,SUSTAINABLE GROWTH ADVISERS, LP',
    name: 'SUSTAINABLE GROWTH ADVISERS, LP',
    label: 'SUSTAINABLE GROWTH ADVISERS, LP',
  },
  {
    cik: '0001580677',
    value: '0001580677,SUSTAINABLE INSIGHT CAPITAL MANAGEMENT, LLC',
    name: 'SUSTAINABLE INSIGHT CAPITAL MANAGEMENT, LLC',
    label: 'SUSTAINABLE INSIGHT CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001930301',
    value: '0001930301,SUTTON PLACE INVESTORS LLC',
    name: 'SUTTON PLACE INVESTORS LLC',
    label: 'SUTTON PLACE INVESTORS LLC',
  },
  {
    cik: '0001684898',
    value: '0001684898,SUTTON SQUARE MANAGEMENT L.P.',
    name: 'SUTTON SQUARE MANAGEMENT L.P.',
    label: 'SUTTON SQUARE MANAGEMENT L.P.',
  },
  {
    cik: '0001569064',
    value: '0001569064,SUVRETTA CAPITAL MANAGEMENT, LLC',
    name: 'SUVRETTA CAPITAL MANAGEMENT, LLC',
    label: 'SUVRETTA CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001587836',
    value: '0001587836,SVA PLUMB WEALTH MANAGEMENT, LLC',
    name: 'SVA PLUMB WEALTH MANAGEMENT, LLC',
    label: 'SVA PLUMB WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001806668',
    value: '0001806668,SVB INVESTMENT SERVICES INC.',
    name: 'SVB INVESTMENT SERVICES INC.',
    label: 'SVB INVESTMENT SERVICES INC.',
  },
  {
    cik: '0001806668',
    value: '0001806668,SVB WEALTH ADVISORY, INC.',
    name: 'SVB WEALTH ADVISORY, INC.',
    label: 'SVB WEALTH ADVISORY, INC.',
  },
  {
    cik: '0001626116',
    value: '0001626116,SVB WEALTH LLC',
    name: 'SVB WEALTH LLC',
    label: 'SVB WEALTH LLC',
  },
  {
    cik: '0001606074',
    value: '0001606074,SVENNILSON PETER',
    name: 'SVENNILSON PETER',
    label: 'SVENNILSON PETER',
  },
  {
    cik: '0001643792',
    value: '0001643792,SVENSKA HANDELSBANKEN AB (PUBL)',
    name: 'SVENSKA HANDELSBANKEN AB (PUBL)',
    label: 'SVENSKA HANDELSBANKEN AB (PUBL)',
  },
  {
    cik: '0001587143',
    value: '0001587143,SV HEALTH INVESTORS, LLC',
    name: 'SV HEALTH INVESTORS, LLC',
    label: 'SV HEALTH INVESTORS, LLC',
  },
  {
    cik: '0001741677',
    value: '0001741677,SWAINE & LEIDEL WEALTH SERVICES, LLC',
    name: 'SWAINE & LEIDEL WEALTH SERVICES, LLC',
    label: 'SWAINE & LEIDEL WEALTH SERVICES, LLC',
  },
  {
    cik: '0001591505',
    value: '0001591505,SWAN GLOBAL INVESTMENTS, LLC',
    name: 'SWAN GLOBAL INVESTMENTS, LLC',
    label: 'SWAN GLOBAL INVESTMENTS, LLC',
  },
  {
    cik: '0001019531',
    value: '0001019531,SWARTHMORE GROUP INC',
    name: 'SWARTHMORE GROUP INC',
    label: 'SWARTHMORE GROUP INC',
  },
  {
    cik: '0001633050',
    value: '0001633,SWEDBANK',
    name: 'SWEDBANK',
    label: 'SWEDBANK',
  },
  {
    cik: '0001908936',
    value: '0001908936,SWEET FINANCIAL PARTNERS, LLC',
    name: 'SWEET FINANCIAL PARTNERS, LLC',
    label: 'SWEET FINANCIAL PARTNERS, LLC',
  },
  {
    cik: '0001595853',
    value: '0001595853,SWIFT RUN CAPITAL MANAGEMENT, LLC',
    name: 'SWIFT RUN CAPITAL MANAGEMENT, LLC',
    label: 'SWIFT RUN CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001824821',
    value: '0001824821,SW INVESTMENT MANAGEMENT LLC',
    name: 'SW INVESTMENT MANAGEMENT LLC',
    label: 'SW INVESTMENT MANAGEMENT LLC',
  },
  {
    cik: '0001582202',
    value: '0001582202,SWISS NATIONAL BANK',
    name: 'SWISS NATIONAL BANK',
    label: 'SWISS NATIONAL BANK',
  },
  {
    cik: '0001805754',
    value: '0001805754,SWISSPARTNERS ADVISORS LTD',
    name: 'SWISSPARTNERS ADVISORS LTD',
    label: 'SWISSPARTNERS ADVISORS LTD',
  },
  {
    cik: '0001837984',
    value: '0001837984,SWISSPARTNERS LTD.',
    name: 'SWISSPARTNERS LTD.',
    label: 'SWISSPARTNERS LTD.',
  },
  {
    cik: '0000315043',
    value: '0000315043,SWISS REINSURANCE CO LTD',
    name: 'SWISS REINSURANCE CO LTD',
    label: 'SWISS REINSURANCE CO LTD',
  },
  {
    cik: '0001519921',
    value: '0001519921,SWISS RE LTD',
    name: 'SWISS RE LTD',
    label: 'SWISS RE LTD',
  },
  {
    cik: '0001874081',
    value: '0001874081,SWM ADVISORS',
    name: 'SWM ADVISORS',
    label: 'SWM ADVISORS',
  },
  {
    cik: '0001839255',
    value: '00018392,SWMG, LLC',
    name: 'SWMG, LLC',
    label: 'SWMG, LLC',
  },
  {
    cik: '0001731731',
    value: '0001731731,SWS PARTNERS',
    name: 'SWS PARTNERS',
    label: 'SWS PARTNERS',
  },
  {
    cik: '0001811522',
    value: '0001811522,SYCALE ADVISORS (NY) LLC',
    name: 'SYCALE ADVISORS (NY) LLC',
    label: 'SYCALE ADVISORS (NY) LLC',
  },
  {
    cik: '0001632833',
    value: '0001632833,SYCAMORE PARTNERS MANAGEMENT, L.L.C.',
    name: 'SYCAMORE PARTNERS MANAGEMENT, L.L.C.',
    label: 'SYCAMORE PARTNERS MANAGEMENT, L.L.C.',
  },
  {
    cik: '0001632833',
    value: '0001632833,SYCAMORE PARTNERS MANAGEMENT, L.P.',
    name: 'SYCAMORE PARTNERS MANAGEMENT, L.P.',
    label: 'SYCAMORE PARTNERS MANAGEMENT, L.P.',
  },
  {
    cik: '0001728681',
    value: '0001728681,SYCOMORE ASSET MANAGEMENT',
    name: 'SYCOMORE ASSET MANAGEMENT',
    label: 'SYCOMORE ASSET MANAGEMENT',
  },
  {
    cik: '0001627436',
    value: '0001627436,SYLEBRA CAPITAL LTD',
    name: 'SYLEBRA CAPITAL LTD',
    label: 'SYLEBRA CAPITAL LTD',
  },
  {
    cik: '0001811513',
    value: '0001811513,SYMETRA INVESTMENT MANAGEMENT CO',
    name: 'SYMETRA INVESTMENT MANAGEMENT CO',
    label: 'SYMETRA INVESTMENT MANAGEMENT CO',
  },
  {
    cik: '0001559495',
    value: '0001559495,SYMETRA LIFE INSURANCE CO',
    name: 'SYMETRA LIFE INSURANCE CO',
    label: 'SYMETRA LIFE INSURANCE CO',
  },
  {
    cik: '0000764739',
    value: '0000764739,SYM FINANCIAL CORP',
    name: 'SYM FINANCIAL CORP',
    label: 'SYM FINANCIAL CORP',
  },
  {
    cik: '0001691919',
    value: '0001691919,SYMMETRY INVESTMENTS LP',
    name: 'SYMMETRY INVESTMENTS LP',
    label: 'SYMMETRY INVESTMENTS LP',
  },
  {
    cik: '0001697493',
    value: '0001697493,SYMMETRY PARTNER, LLC',
    name: 'SYMMETRY PARTNER, LLC',
    label: 'SYMMETRY PARTNER, LLC',
  },
  {
    cik: '0001697493',
    value: '0001697493,SYMMETRY PARTNERS, LLC',
    name: 'SYMMETRY PARTNERS, LLC',
    label: 'SYMMETRY PARTNERS, LLC',
  },
  {
    cik: '0001389234',
    value: '0001389234,SYMMETRY PEAK MANAGEMENT LLC',
    name: 'SYMMETRY PEAK MANAGEMENT LLC',
    label: 'SYMMETRY PEAK MANAGEMENT LLC',
  },
  {
    cik: '0001056958',
    value: '0001056958,SYMONS CAPITAL MANAGEMENT INC',
    name: 'SYMONS CAPITAL MANAGEMENT INC',
    label: 'SYMONS CAPITAL MANAGEMENT INC',
  },
  {
    cik: '0001030815',
    value: '0001030815,SYMPHONY ASSET MANAGEMENT LLC',
    name: 'SYMPHONY ASSET MANAGEMENT LLC',
    label: 'SYMPHONY ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001800687',
    value: '0001800687,SYMPHONY FINANCIAL, LTD. CO.',
    name: 'SYMPHONY FINANCIAL, LTD. CO.',
    label: 'SYMPHONY FINANCIAL, LTD. CO.',
  },
  {
    cik: '0001666501',
    value: '0001666501,SYMPHONY TECHNOLOGY GROUP, L.L.C.',
    name: 'SYMPHONY TECHNOLOGY GROUP, L.L.C.',
    label: 'SYMPHONY TECHNOLOGY GROUP, L.L.C.',
  },
  {
    cik: '0001725042',
    value: '0001725042,SYNCONA PORTFOLIO LTD',
    name: 'SYNCONA PORTFOLIO LTD',
    label: 'SYNCONA PORTFOLIO LTD',
  },
  {
    cik: '0001699080',
    value: '0001699080,SYNERGY ASSET MANAGEMENT, LLC',
    name: 'SYNERGY ASSET MANAGEMENT, LLC',
    label: 'SYNERGY ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001905669',
    value: '0001905669,SYNERGY FINANCIAL GROUP, LTD',
    name: 'SYNERGY FINANCIAL GROUP, LTD',
    label: 'SYNERGY FINANCIAL GROUP, LTD',
  },
  {
    cik: '0001699080',
    value: '0001699080,SYNERGY FINANCIAL MANAGEMENT, LLC',
    name: 'SYNERGY FINANCIAL MANAGEMENT, LLC',
    label: 'SYNERGY FINANCIAL MANAGEMENT, LLC',
  },
  {
    cik: '0000018349',
    value: '0000018349,SYNOVUS FINANCIAL CORP',
    name: 'SYNOVUS FINANCIAL CORP',
    label: 'SYNOVUS FINANCIAL CORP',
  },
  {
    cik: '0001608179',
    value: '0001608179,SYNTAL CAPITAL PARTNERS, LLC',
    name: 'SYNTAL CAPITAL PARTNERS, LLC',
    label: 'SYNTAL CAPITAL PARTNERS, LLC',
  },
  {
    cik: '0001858294',
    value: '0001858294,SYNTAX ADVISORS, LLC',
    name: 'SYNTAX ADVISORS, LLC',
    label: 'SYNTAX ADVISORS, LLC',
  },
  {
    cik: '0001766392',
    value: '0001766392,SYQUANT CAPITAL SAS',
    name: 'SYQUANT CAPITAL SAS',
    label: 'SYQUANT CAPITAL SAS',
  },
  {
    cik: '0001882691',
    value: '0001882691,SYSTELLIGENCE, LLC',
    name: 'SYSTELLIGENCE, LLC',
    label: 'SYSTELLIGENCE, LLC',
  },
  {
    cik: '0001806755',
    value: '0001806755,SYSTEMATIC ALPHA INVESTMENTS, LLC',
    name: 'SYSTEMATIC ALPHA INVESTMENTS, LLC',
    label: 'SYSTEMATIC ALPHA INVESTMENTS, LLC',
  },
  {
    cik: '0001085936',
    value: '0001085936,SYSTEMATIC FINANCIAL MANAGEMENT LP',
    name: 'SYSTEMATIC FINANCIAL MANAGEMENT LP',
    label: 'SYSTEMATIC FINANCIAL MANAGEMENT LP',
  },
  {
    cik: '0001626473',
    value: '0001626473,SYSTEM TWO ADVISORS',
    name: 'SYSTEM TWO ADVISORS',
    label: 'SYSTEM TWO ADVISORS',
  },
  {
    cik: '0001682057',
    value: '0001682057,SYVERSON STREGE & CO',
    name: 'SYVERSON STREGE & CO',
    label: 'SYVERSON STREGE & CO',
  },
  {
    cik: '0001327388',
    value: '0001327388,T2 PARTNERS MANAGEMENT, LP',
    name: 'T2 PARTNERS MANAGEMENT, LP',
    label: 'T2 PARTNERS MANAGEMENT, LP',
  },
  {
    cik: '0001811243',
    value: '0001811243,TAAL CAPITAL MANAGEMENT LP',
    name: 'TAAL CAPITAL MANAGEMENT LP',
    label: 'TAAL CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001034569',
    value: '0001034569,TA ASSOCIATES, L.P.',
    name: 'TA ASSOCIATES, L.P.',
    label: 'TA ASSOCIATES, L.P.',
  },
  {
    cik: '0001834780',
    value: '0001834780,TABOR ASSET MANAGEMENT, LP',
    name: 'TABOR ASSET MANAGEMENT, LP',
    label: 'TABOR ASSET MANAGEMENT, LP',
  },
  {
    cik: '0001846287',
    value: '0001846287,TACITA CAPITAL INC',
    name: 'TACITA CAPITAL INC',
    label: 'TACITA CAPITAL INC',
  },
  {
    cik: '0001390113',
    value: '0001390113,TACONIC CAPITAL ADVISORS LP',
    name: 'TACONIC CAPITAL ADVISORS LP',
    label: 'TACONIC CAPITAL ADVISORS LP',
  },
  {
    cik: '0001388348',
    value: '0001388348,TACTICAL ALLOCATION GROUP LLC',
    name: 'TACTICAL ALLOCATION GROUP LLC',
    label: 'TACTICAL ALLOCATION GROUP LLC',
  },
  {
    cik: '0001759641',
    value: '0001759641,TADDEI, LUDWIG & ASSOCIATES, INC.',
    name: 'TADDEI, LUDWIG & ASSOCIATES, INC.',
    label: 'TADDEI, LUDWIG & ASSOCIATES, INC.',
  },
  {
    cik: '0001903044',
    value: '0001903044,TAGSTONE CAPITAL, INC.',
    name: 'TAGSTONE CAPITAL, INC.',
    label: 'TAGSTONE CAPITAL, INC.',
  },
  {
    cik: '0001424878',
    value: '0001424878,TAHITHROMOS, L.L.C.',
    name: 'TAHITHROMOS, L.L.C.',
    label: 'TAHITHROMOS, L.L.C.',
  },
  {
    cik: '0001886506',
    value: '0001886506,TAIKA CAPITAL, LP',
    name: 'TAIKA CAPITAL, LP',
    label: 'TAIKA CAPITAL, LP',
  },
  {
    cik: '0001755911',
    value: '0001755911,TAIKANG ASSET MANAGEMENT (HONG KONG) CO LTD',
    name: 'TAIKANG ASSET MANAGEMENT (HONG KONG) CO LTD',
    label: 'TAIKANG ASSET MANAGEMENT (HONG KONG) CO LTD',
  },
  {
    cik: '0001652062',
    value: '0001652062,TAIREN CAPITAL LTD',
    name: 'TAIREN CAPITAL LTD',
    label: 'TAIREN CAPITAL LTD',
  },
  {
    cik: '0001254890',
    value: '0001254890,TAIYO LIFE INSURANCE CO',
    name: 'TAIYO LIFE INSURANCE CO',
    label: 'TAIYO LIFE INSURANCE CO',
  },
  {
    cik: '0001523557',
    value: '0001523557,TALARA CAPITAL MANAGEMENT, LLC',
    name: 'TALARA CAPITAL MANAGEMENT, LLC',
    label: 'TALARA CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001569502',
    value: '0001569502,TALKOT CAPITAL, LLC',
    name: 'TALKOT CAPITAL, LLC',
    label: 'TALKOT CAPITAL, LLC',
  },
  {
    cik: '0001661462',
    value: '0001661462,TAMARACK ADVISERS, LP',
    name: 'TAMARACK ADVISERS, LP',
    label: 'TAMARACK ADVISERS, LP',
  },
  {
    cik: '0001537996',
    value: '0001537996,TAMARACK CAPITAL MANAGEMENT, LLC',
    name: 'TAMARACK CAPITAL MANAGEMENT, LLC',
    label: 'TAMARACK CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001703081',
    value: '0001703081,TAMAR SECURITIES, LLC',
    name: 'TAMAR SECURITIES, LLC',
    label: 'TAMAR SECURITIES, LLC',
  },
  {
    cik: '0001594591',
    value: '0001594591,TAM CAPITAL MANAGEMENT INC.',
    name: 'TAM CAPITAL MANAGEMENT INC.',
    label: 'TAM CAPITAL MANAGEMENT INC.',
  },
  {
    cik: '0001407389',
    value: '0001407389,TAMRO CAPITAL PARTNERS LLC',
    name: 'TAMRO CAPITAL PARTNERS LLC',
    label: 'TAMRO CAPITAL PARTNERS LLC',
  },
  {
    cik: '0001892929',
    value: '0001892929,TANAGER WEALTH MANAGEMENT LLP',
    name: 'TANAGER WEALTH MANAGEMENT LLP',
    label: 'TANAGER WEALTH MANAGEMENT LLP',
  },
  {
    cik: '0000816788',
    value: '0000816788,TANAKA CAPITAL MANAGEMENT INC',
    name: 'TANAKA CAPITAL MANAGEMENT INC',
    label: 'TANAKA CAPITAL MANAGEMENT INC',
  },
  {
    cik: '0000873759',
    value: '0000873759,TANDEM CAPITAL MANAGEMENT CORP /ADV',
    name: 'TANDEM CAPITAL MANAGEMENT CORP /ADV',
    label: 'TANDEM CAPITAL MANAGEMENT CORP /ADV',
  },
  {
    cik: '0001694284',
    value: '0001694284,TANDEM INVESTMENT ADVISORS, INC.',
    name: 'TANDEM INVESTMENT ADVISORS, INC.',
    label: 'TANDEM INVESTMENT ADVISORS, INC.',
  },
  {
    cik: '0001232621',
    value: '0001232621,TANG CAPITAL MANAGEMENT LLC',
    name: 'TANG CAPITAL MANAGEMENT LLC',
    label: 'TANG CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001178579',
    value: '0001178579,TANG KEVIN C',
    name: 'TANG KEVIN C',
    label: 'TANG KEVIN C',
  },
  {
    cik: '0001454308',
    value: '0001454308,TANGLEWOOD WEALTH MANAGEMENT, INC.',
    name: 'TANGLEWOOD WEALTH MANAGEMENT, INC.',
    label: 'TANGLEWOOD WEALTH MANAGEMENT, INC.',
  },
  {
    cik: '0001802967',
    value: '0001802967,TAO CAPITAL MANAGEMENT LP',
    name: 'TAO CAPITAL MANAGEMENT LP',
    label: 'TAO CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001912008',
    value: '0001912008,TAO PRO LLC',
    name: 'TAO PRO LLC',
    label: 'TAO PRO LLC',
  },
  {
    cik: '0001748930',
    value: '0001748930,TAP CONSULTING, LLC',
    name: 'TAP CONSULTING, LLC',
    label: 'TAP CONSULTING, LLC',
  },
  {
    cik: '0001666737',
    value: '0001666737,TAPPAN STREET PARTNERS LLC',
    name: 'TAPPAN STREET PARTNERS LLC',
    label: 'TAPPAN STREET PARTNERS LLC',
  },
  {
    cik: '0001599603',
    value: '0001599603,TARBOX FAMILY OFFICE, INC.',
    name: 'TARBOX FAMILY OFFICE, INC.',
    label: 'TARBOX FAMILY OFFICE, INC.',
  },
  {
    cik: '0001600217',
    value: '0001600217,TARGET ASSET MANAGEMENT PTE LTD',
    name: 'TARGET ASSET MANAGEMENT PTE LTD',
    label: 'TARGET ASSET MANAGEMENT PTE LTD',
  },
  {
    cik: '0001777371',
    value: '0001777371,TARSADIA CAPITAL, LLC',
    name: 'TARSADIA CAPITAL, LLC',
    label: 'TARSADIA CAPITAL, LLC',
  },
  {
    cik: '0001802488',
    value: '0001802488,TATRO CAPITAL, LLC',
    name: 'TATRO CAPITAL, LLC',
    label: 'TATRO CAPITAL, LLC',
  },
  {
    cik: '0001227157',
    value: '0001227157,TAUBE HODSON STONEX PARTNERS LLP',
    name: 'TAUBE HODSON STONEX PARTNERS LLP',
    label: 'TAUBE HODSON STONEX PARTNERS LLP',
  },
  {
    cik: '0001317209',
    value: '0001317209,TAURUS ASSET MANAGEMENT, LLC',
    name: 'TAURUS ASSET MANAGEMENT, LLC',
    label: 'TAURUS ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001672526',
    value: '0001672526,TAVIO CAPITAL LLC',
    name: 'TAVIO CAPITAL LLC',
    label: 'TAVIO CAPITAL LLC',
  },
  {
    cik: '0001561784',
    value: '0001561784,TAYLOR ASSET MANAGEMENT INC',
    name: 'TAYLOR ASSET MANAGEMENT INC',
    label: 'TAYLOR ASSET MANAGEMENT INC',
  },
  {
    cik: '0001667102',
    value: '0001667102,TAYLOR, COTTRILL, ERICKSON & ASSOCIATES, INC.',
    name: 'TAYLOR, COTTRILL, ERICKSON & ASSOCIATES, INC.',
    label: 'TAYLOR, COTTRILL, ERICKSON & ASSOCIATES, INC.',
  },
  {
    cik: '0001428601',
    value: '0001428601,TAYLOR, COTTRILL LLC',
    name: 'TAYLOR, COTTRILL LLC',
    label: 'TAYLOR, COTTRILL LLC',
  },
  {
    cik: '0001632665',
    value: '0001632665,TAYLOR FRIGON CAPITAL MANAGEMENT LLC',
    name: 'TAYLOR FRIGON CAPITAL MANAGEMENT LLC',
    label: 'TAYLOR FRIGON CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001727762',
    value: '0001727762,TAYLOR HOFFMAN WEALTH MANAGEMENT',
    name: 'TAYLOR HOFFMAN WEALTH MANAGEMENT',
    label: 'TAYLOR HOFFMAN WEALTH MANAGEMENT',
  },
  {
    cik: '0001512901',
    value: '0001512901,TAYLOR INVESTMENT COUNSELORS LLC',
    name: 'TAYLOR INVESTMENT COUNSELORS LLC',
    label: 'TAYLOR INVESTMENT COUNSELORS LLC',
  },
  {
    cik: '0001844266',
    value: '0001844266,TAYLOR & MORGAN WEALTH MANAGEMENT, LLC',
    name: 'TAYLOR & MORGAN WEALTH MANAGEMENT, LLC',
    label: 'TAYLOR & MORGAN WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001606716',
    value: '0001606716,TAYLOR WEALTH MANAGEMENT PARTNERS',
    name: 'TAYLOR WEALTH MANAGEMENT PARTNERS',
    label: 'TAYLOR WEALTH MANAGEMENT PARTNERS',
  },
  {
    cik: '0001483503',
    value: '0001483503,TB ALTERNATIVE ASSETS LTD.',
    name: 'TB ALTERNATIVE ASSETS LTD.',
    label: 'TB ALTERNATIVE ASSETS LTD.',
  },
  {
    cik: '0001787125',
    value: '0001787125,TBH GLOBAL ASSET MANAGEMENT, LLC',
    name: 'TBH GLOBAL ASSET MANAGEMENT, LLC',
    label: 'TBH GLOBAL ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001166988',
    value: '0001166988,TBP ADVISORS LTD',
    name: 'TBP ADVISORS LTD',
    label: 'TBP ADVISORS LTD',
  },
  {
    cik: '0001436081',
    value: '0001436081,TB PARTNERS GP LTD',
    name: 'TB PARTNERS GP LTD',
    label: 'TB PARTNERS GP LTD',
  },
  {
    cik: '0001426967',
    value: '0001426967,TBP INVESTMENTS MANAGEMENT, LLC',
    name: 'TBP INVESTMENTS MANAGEMENT, LLC',
    label: 'TBP INVESTMENTS MANAGEMENT, LLC',
  },
  {
    cik: '0001790153',
    value: '0001790153,TCF NATIONAL BANK',
    name: 'TCF NATIONAL BANK',
    label: 'TCF NATIONAL BANK',
  },
  {
    cik: '0001632539',
    value: '0001632539,TCG ADVISORS, LP',
    name: 'TCG ADVISORS, LP',
    label: 'TCG ADVISORS, LP',
  },
  {
    cik: '0001841815',
    value: '0001841815,TCG ADVISORY SERVICES, LLC',
    name: 'TCG ADVISORY SERVICES, LLC',
    label: 'TCG ADVISORY SERVICES, LLC',
  },
  {
    cik: '0001930483',
    value: '0001930483,TCG CAPITAL MANAGEMENT, LP',
    name: 'TCG CAPITAL MANAGEMENT, LP',
    label: 'TCG CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0001839948',
    value: '0001839948,TCG CROSSOVER MANAGEMENT, LLC',
    name: 'TCG CROSSOVER MANAGEMENT, LLC',
    label: 'TCG CROSSOVER MANAGEMENT, LLC',
  },
  {
    cik: '0001647251',
    value: '0001647251,TCI FUND MANAGEMENT LTD',
    name: 'TCI FUND MANAGEMENT LTD',
    label: 'TCI FUND MANAGEMENT LTD',
  },
  {
    cik: '0001583751',
    value: '0001583751,TCI WEALTH ADVISORS, INC.',
    name: 'TCI WEALTH ADVISORS, INC.',
    label: 'TCI WEALTH ADVISORS, INC.',
  },
  {
    cik: '0001911491',
    value: '0001911491,TCM ADVISORS, LLC',
    name: 'TCM ADVISORS, LLC',
    label: 'TCM ADVISORS, LLC',
  },
  {
    cik: '0001961898',
    value: '0001961898,TCP ASSET MANAGEMENT, LLC',
    name: 'TCP ASSET MANAGEMENT, LLC',
    label: 'TCP ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001167167',
    value: '0001167167,TCS CAPITAL MANAGEMENT LLC',
    name: 'TCS CAPITAL MANAGEMENT LLC',
    label: 'TCS CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001483870',
    value: '0001483870,TCTC HOLDINGS, LLC',
    name: 'TCTC HOLDINGS, LLC',
    label: 'TCTC HOLDINGS, LLC',
  },
  {
    cik: '0000850401',
    value: '0000850401,TCW GROUP INC',
    name: 'TCW GROUP INC',
    label: 'TCW GROUP INC',
  },
  {
    cik: '0001907375',
    value: '0001907,TCWP LLC5',
    name: 'TCWP LLC',
    label: 'TCWP LLC',
  },
  {
    cik: '0001461539',
    value: '0001461539,TD AMERITRADE INVESTMENT MANAGEMENT, LLC',
    name: 'TD AMERITRADE INVESTMENT MANAGEMENT, LLC',
    label: 'TD AMERITRADE INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001568219',
    value: '0001568219,TD AMERITRADE TRUST CO',
    name: 'TD AMERITRADE TRUST CO',
    label: 'TD AMERITRADE TRUST CO',
  },
  {
    cik: '0001115941',
    value: '0001115941,TDAM USA INC.',
    name: 'TDAM USA INC.',
    label: 'TDAM USA INC.',
  },
  {
    cik: '0001056053',
    value: '0001056053,TD ASSET MANAGEMENT INC',
    name: 'TD ASSET MANAGEMENT INC',
    label: 'TD ASSET MANAGEMENT INC',
  },
  {
    cik: '0001483824',
    value: '0001483824,TD CAPITAL MANAGEMENT LLC',
    name: 'TD CAPITAL MANAGEMENT LLC',
    label: 'TD CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001335977',
    value: '0001335977,TD SECURITIES (USA) LLC',
    name: 'TD SECURITIES (USA) LLC',
    label: 'TD SECURITIES (USA) LLC',
  },
  {
    cik: '0000796848',
    value: '0000796848,TEACHER RETIREMENT SYSTEM OF TEXAS',
    name: 'TEACHER RETIREMENT SYSTEM OF TEXAS',
    label: 'TEACHER RETIREMENT SYSTEM OF TEXAS',
  },
  {
    cik: '0000939222',
    value: '0000939222,TEACHERS ADVISORS INC',
    name: 'TEACHERS ADVISORS INC',
    label: 'TEACHERS ADVISORS INC',
  },
  {
    cik: '0000315038',
    value: '0000315038,TEACHERS INSURANCE & ANNUITY ASSOCIATION OF AMERICA',
    name: 'TEACHERS INSURANCE & ANNUITY ASSOCIATION OF AMERICA',
    label: 'TEACHERS INSURANCE & ANNUITY ASSOCIATION OF AMERICA',
  },
  {
    cik: '0000714142',
    value: '0000714142,TEACHERS RETIREMENT SYSTEM OF THE STATE OF KENTUCKY',
    name: 'TEACHERS RETIREMENT SYSTEM OF THE STATE OF KENTUCKY',
    label: 'TEACHERS RETIREMENT SYSTEM OF THE STATE OF KENTUCKY',
  },
  {
    cik: '0001832237',
    value: '0001832237,TE AHUMAIRANGI INVESTMENT MANAGEMENT LTD',
    name: 'TE AHUMAIRANGI INVESTMENT MANAGEMENT LTD',
    label: 'TE AHUMAIRANGI INVESTMENT MANAGEMENT LTD',
  },
  {
    cik: '0001164062',
    value: '0001164062,TEALWOOD ASSET MANAGEMENT INC',
    name: 'TEALWOOD ASSET MANAGEMENT INC',
    label: 'TEALWOOD ASSET MANAGEMENT INC',
  },
  {
    cik: '0001629931',
    value: '0001629931,TEAMWORK FINANCIAL ADVISORS, LLC',
    name: 'TEAMWORK FINANCIAL ADVISORS, LLC',
    label: 'TEAMWORK FINANCIAL ADVISORS, LLC',
  },
  {
    cik: '0001910154',
    value: '0001910154,TECA PARTNERS, LP',
    name: 'TECA PARTNERS, LP',
    label: 'TECA PARTNERS, LP',
  },
  {
    cik: '0001698264',
    value: '0001698264,TECHNOLOGY CROSSOVER MANAGEMENT IX, LTD.',
    name: 'TECHNOLOGY CROSSOVER MANAGEMENT IX, LTD.',
    label: 'TECHNOLOGY CROSSOVER MANAGEMENT IX, LTD.',
  },
  {
    cik: '0001626282',
    value: '0001626282,TECHNOLOGY CROSSOVER MANAGEMENT VIII, LTD.',
    name: 'TECHNOLOGY CROSSOVER MANAGEMENT VIII, LTD.',
    label: 'TECHNOLOGY CROSSOVER MANAGEMENT VIII, LTD.',
  },
  {
    cik: '0001466999',
    value: '0001466999,TECHNOLOGY CROSSOVER MANAGEMENT VII, LTD.',
    name: 'TECHNOLOGY CROSSOVER MANAGEMENT VII, LTD.',
    label: 'TECHNOLOGY CROSSOVER MANAGEMENT VII, LTD.',
  },
  {
    cik: '0001370400',
    value: '0001370400,TECHNOLOGY CROSSOVER MANAGEMENT VI, L.L.C.',
    name: 'TECHNOLOGY CROSSOVER MANAGEMENT VI, L.L.C.',
    label: 'TECHNOLOGY CROSSOVER MANAGEMENT VI, L.L.C.',
  },
  {
    cik: '0001283643',
    value: '0001283643,TECHNOLOGY CROSSOVER MANAGEMENT V LLC',
    name: 'TECHNOLOGY CROSSOVER MANAGEMENT V LLC',
    label: 'TECHNOLOGY CROSSOVER MANAGEMENT V LLC',
  },
  {
    cik: '0001787727',
    value: '0001787727,TECHNOLOGY CROSSOVER MANAGEMENT X, LTD.',
    name: 'TECHNOLOGY CROSSOVER MANAGEMENT X, LTD.',
    label: 'TECHNOLOGY CROSSOVER MANAGEMENT X, LTD.',
  },
  {
    cik: '0001633745',
    value: '0001633745,TECH SQUARE TRADING LP',
    name: 'TECH SQUARE TRADING LP',
    label: 'TECH SQUARE TRADING LP',
  },
  {
    cik: '0001859918',
    value: '0001859918,TECTONIC ADVISORS LLC',
    name: 'TECTONIC ADVISORS LLC',
    label: 'TECTONIC ADVISORS LLC',
  },
  {
    cik: '0001574886',
    value: '0001574886,TEEWINOT CAPITAL ADVISERS, L.L.C.',
    name: 'TEEWINOT CAPITAL ADVISERS, L.L.C.',
    label: 'TEEWINOT CAPITAL ADVISERS, L.L.C.',
  },
  {
    cik: '0001536063',
    value: '0001536063,TEGEAN CAPITAL MANAGEMENT, LLC',
    name: 'TEGEAN CAPITAL MANAGEMENT, LLC',
    label: 'TEGEAN CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001531879',
    value: '0001531879,TEILINGER CAPITAL LTD.',
    name: 'TEILINGER CAPITAL LTD.',
    label: 'TEILINGER CAPITAL LTD.',
  },
  {
    cik: '0001575283',
    value: '0001575283,TEILINGER USA, INC.',
    name: 'TEILINGER USA, INC.',
    label: 'TEILINGER USA, INC.',
  },
  {
    cik: '0001300336',
    value: '0001300336,TEKLA CAPITAL MANAGEMENT LLC',
    name: 'TEKLA CAPITAL MANAGEMENT LLC',
    label: 'TEKLA CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001558971',
    value: '0001558971,TEKNE CAPITAL MANAGEMENT, LLC',
    name: 'TEKNE CAPITAL MANAGEMENT, LLC',
    label: 'TEKNE CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001588012',
    value: '0001588012,TELEGRAPH HILL PARTNERS MANAGEMENT COMPANY, LLC',
    name: 'TELEGRAPH HILL PARTNERS MANAGEMENT COMPANY, LLC',
    label: 'TELEGRAPH HILL PARTNERS MANAGEMENT COMPANY, LLC',
  },
  {
    cik: '0001420816',
    value: '0001420816,TELEMARK ASSET MANAGEMENT, LLC',
    name: 'TELEMARK ASSET MANAGEMENT, LLC',
    label: 'TELEMARK ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001537055',
    value: '0001537055,TELEMETRY INVESTMENTS, L.L.C.',
    name: 'TELEMETRY INVESTMENTS, L.L.C.',
    label: 'TELEMETRY INVESTMENTS, L.L.C.',
  },
  {
    cik: '0001590491',
    value: '0001590491,TELEMUS CAPITAL, LLC',
    name: 'TELEMUS CAPITAL, LLC',
    label: 'TELEMUS CAPITAL, LLC',
  },
  {
    cik: '0001334170',
    value: '0001334170,TELLURIDE ASSET MANAGEMENT LLC',
    name: 'TELLURIDE ASSET MANAGEMENT LLC',
    label: 'TELLURIDE ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001567889',
    value: '0001567889,TELOS CAPITAL MANAGEMENT, INC.',
    name: 'TELOS CAPITAL MANAGEMENT, INC.',
    label: 'TELOS CAPITAL MANAGEMENT, INC.',
  },
  {
    cik: '0001021944',
    value: '0001021944,TEMASEK HOLDINGS (PRIVATE) LTD',
    name: 'TEMASEK HOLDINGS (PRIVATE) LTD',
    label: 'TEMASEK HOLDINGS (PRIVATE) LTD',
  },
  {
    cik: '0001806428',
    value: '0001806428,TEMPUS WEALTH PLANNING, LLC',
    name: 'TEMPUS WEALTH PLANNING, LLC',
    label: 'TEMPUS WEALTH PLANNING, LLC',
  },
  {
    cik: '0001832212',
    value: '0001832212,TEN CAPITAL WEALTH ADVISORS, LLC',
    name: 'TEN CAPITAL WEALTH ADVISORS, LLC',
    label: 'TEN CAPITAL WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001755784',
    value: '0001755784,TENCORE PARTNERS, LP',
    name: 'TENCORE PARTNERS, LP',
    label: 'TENCORE PARTNERS, LP',
  },
  {
    cik: '0001849517',
    value: '0001849517,TENERE CAPITAL LLC',
    name: 'TENERE CAPITAL LLC',
    label: 'TENERE CAPITAL LLC',
  },
  {
    cik: '0001826790',
    value: '0001826790,TENNESSEE VALLEY ASSET MANAGEMENT PARTNERS',
    name: 'TENNESSEE VALLEY ASSET MANAGEMENT PARTNERS',
    label: 'TENNESSEE VALLEY ASSET MANAGEMENT PARTNERS',
  },
  {
    cik: '0001346554',
    value: '0001346554,TENOR CAPITAL MANAGEMENT CO., L.P.',
    name: 'TENOR CAPITAL MANAGEMENT CO., L.P.',
    label: 'TENOR CAPITAL MANAGEMENT CO., L.P.',
  },
  {
    cik: '0001874146',
    value: '0001874146,TENRET CO LLC',
    name: 'TENRET CO LLC',
    label: 'TENRET CO LLC',
  },
  {
    cik: '0001598843',
    value: '0001598843,TENSILE CAPITAL MANAGEMENT LLC',
    name: 'TENSILE CAPITAL MANAGEMENT LLC',
    label: 'TENSILE CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001598843',
    value: '0001598843,TENSILE CAPITAL MANAGEMENT LP',
    name: 'TENSILE CAPITAL MANAGEMENT LP',
    label: 'TENSILE CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001624095',
    value: '0001624095,TENZING GLOBAL MANAGEMENT, LLC',
    name: 'TENZING GLOBAL MANAGEMENT, LLC',
    label: 'TENZING GLOBAL MANAGEMENT, LLC',
  },
  {
    cik: '0001904821',
    value: '0001904821,TERAMO ADVISORS, LLC',
    name: 'TERAMO ADVISORS, LLC',
    label: 'TERAMO ADVISORS, LLC',
  },
  {
    cik: '0001658652',
    value: '0001658652,TERRA NOVA ASSET MANAGEMENT LLC',
    name: 'TERRA NOVA ASSET MANAGEMENT LLC',
    label: 'TERRA NOVA ASSET MANAGEMENT LLC',
  },
  {
    cik: '0000314169',
    value: '0000314169,TERRIL BROTHERS, INC.',
    name: 'TERRIL BROTHERS, INC.',
    label: 'TERRIL BROTHERS, INC.',
  },
  {
    cik: '0001562744',
    value: '0001562744,TESCO PENSION INVESTMENT LTD',
    name: 'TESCO PENSION INVESTMENT LTD',
    label: 'TESCO PENSION INVESTMENT LTD',
  },
  {
    cik: '0001278430',
    value: '0001278430,TESUJI PARTNERS LLC',
    name: 'TESUJI PARTNERS LLC',
    label: 'TESUJI PARTNERS LLC',
  },
  {
    cik: '0001444874',
    value: '0001444874,TETON ADVISORS, INC.',
    name: 'TETON ADVISORS, INC.',
    label: 'TETON ADVISORS, INC.',
  },
  {
    cik: '0001382078',
    value: '0001382078,TETREM CAPITAL MANAGEMENT LTD.',
    name: 'TETREM CAPITAL MANAGEMENT LTD.',
    label: 'TETREM CAPITAL MANAGEMENT LTD.',
  },
  {
    cik: '0001940416',
    value: '0001940416,TEVIS INVESTMENT MANAGEMENT',
    name: 'TEVIS INVESTMENT MANAGEMENT',
    label: 'TEVIS INVESTMENT MANAGEMENT',
  },
  {
    cik: '0001455924',
    value: '0001455924,TEXAN CAPITAL MANAGEMENT',
    name: 'TEXAN CAPITAL MANAGEMENT',
    label: 'TEXAN CAPITAL MANAGEMENT',
  },
  {
    cik: '0001077428',
    value: '0001077428,TEXAS CAPITAL BANCSHARES INC/TX',
    name: 'TEXAS CAPITAL BANCSHARES INC/TX',
    label: 'TEXAS CAPITAL BANCSHARES INC/TX',
  },
  {
    cik: '0001802655',
    value: '0001802655,TEXAS CAPITAL BANK WEALTH MANAGEMENT SERVICES INC',
    name: 'TEXAS CAPITAL BANK WEALTH MANAGEMENT SERVICES INC',
    label: 'TEXAS CAPITAL BANK WEALTH MANAGEMENT SERVICES INC',
  },
  {
    cik: '0001766881',
    value: '0001766881,TEXAS CHILDREN S',
    name: "TEXAS CHILDREN'S",
    label: "TEXAS CHILDREN'S",
  },
  {
    cik: '0001698632',
    value: '0001698632,TEXAS COUNTY & DISTRICT RETIREMENT SYSTEM',
    name: 'TEXAS COUNTY & DISTRICT RETIREMENT SYSTEM',
    label: 'TEXAS COUNTY & DISTRICT RETIREMENT SYSTEM',
  },
  {
    cik: '0001223779',
    value: '0001223779,TEXAS PERMANENT SCHOOL FUND',
    name: 'TEXAS PERMANENT SCHOOL FUND',
    label: 'TEXAS PERMANENT SCHOOL FUND',
  },
  {
    cik: '0001176347',
    value: '0001176347,TEXAS TREASURY SAFEKEEPING TRUST CO',
    name: 'TEXAS TREASURY SAFEKEEPING TRUST CO',
    label: 'TEXAS TREASURY SAFEKEEPING TRUST CO',
  },
  {
    cik: '0001332342',
    value: '0001332342,TEXAS YALE CAPITAL CORP.',
    name: 'TEXAS YALE CAPITAL CORP.',
    label: 'TEXAS YALE CAPITAL CORP.',
  },
  {
    cik: '0001655789',
    value: '0001655789,TEZA CAPITAL MANAGEMENT LLC',
    name: 'TEZA CAPITAL MANAGEMENT LLC',
    label: 'TEZA CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001827844',
    value: '0001827844,TFC FINANCIAL MANAGEMENT',
    name: 'TFC FINANCIAL MANAGEMENT',
    label: 'TFC FINANCIAL MANAGEMENT',
  },
  {
    cik: '0001806473',
    value: '0001806473,TFG ADVISERS LLC',
    name: 'TFG ADVISERS LLC',
    label: 'TFG ADVISERS LLC',
  },
  {
    cik: '0001515070',
    value: '0001515070,TFG ASSET MANAGEMENT GP LTD',
    name: 'TFG ASSET MANAGEMENT GP LTD',
    label: 'TFG ASSET MANAGEMENT GP LTD',
  },
  {
    cik: '0001698750',
    value: '0001698750,TFO-TDC, LLC',
    name: 'TFO-TDC, LLC',
    label: 'TFO-TDC, LLC',
  },
  {
    cik: '0001427379',
    value: '0001427379,TFS CAPITAL LLC',
    name: 'TFS CAPITAL LLC',
    label: 'TFS CAPITAL LLC',
  },
  {
    cik: '0001697331',
    value: '0001697331,THAI LIFE INSURANCE PCL.',
    name: 'THAI LIFE INSURANCE PCL.',
    label: 'THAI LIFE INSURANCE PCL.',
  },
  {
    cik: '0001714267',
    value: '0001714267,THAMES CAPITAL MANAGEMENT LLC',
    name: 'THAMES CAPITAL MANAGEMENT LLC',
    label: 'THAMES CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001911222',
    value: '0001911222,THAYER STREET PARTNERS MANAGEMENT, LLC',
    name: 'THAYER STREET PARTNERS MANAGEMENT, LLC',
    label: 'THAYER STREET PARTNERS MANAGEMENT, LLC',
  },
  {
    cik: '0001057439',
    value: '0001057439,THB ASSET MANAGEMENT',
    name: 'THB ASSET MANAGEMENT',
    label: 'THB ASSET MANAGEMENT',
  },
  {
    cik: '0001511881',
    value: '0001511881,THELEME PARTNERS LLP',
    name: 'THELEME PARTNERS LLP',
    label: 'THELEME PARTNERS LLP',
  },
  {
    cik: '0001899158',
    value: '0001899158,THEORY FINANCIAL LLC',
    name: 'THEORY FINANCIAL LLC',
    label: 'THEORY FINANCIAL LLC',
  },
  {
    cik: '0001562087',
    value: '0001562087,THIEL MACRO LLC',
    name: 'THIEL MACRO LLC',
    label: 'THIEL MACRO LLC',
  },
  {
    cik: '0001914983',
    value: '0001914983,THINKBETTER GROUP LLC',
    name: 'THINKBETTER GROUP LLC',
    label: 'THINKBETTER GROUP LLC',
  },
  {
    cik: '0001596898',
    value: '0001596898,THINK INVESTMENTS LLC',
    name: 'THINK INVESTMENTS LLC',
    label: 'THINK INVESTMENTS LLC',
  },
  {
    cik: '0001635999',
    value: '0001635999,THINK INVESTMENTS LP',
    name: 'THINK INVESTMENTS LP',
    label: 'THINK INVESTMENTS LP',
  },
  {
    cik: '0001099281',
    value: '0001099281,THIRD AVENUE MANAGEMENT LLC',
    name: 'THIRD AVENUE MANAGEMENT LLC',
    label: 'THIRD AVENUE MANAGEMENT LLC',
  },
  {
    cik: '0001040273',
    value: '0001040273,THIRD POINT LLC',
    name: 'THIRD POINT LLC',
    label: 'THIRD POINT LLC',
  },
  {
    cik: '0001600135',
    value: '0001600135,THIRD ROCK VENTURES, LLC',
    name: 'THIRD ROCK VENTURES, LLC',
    label: 'THIRD ROCK VENTURES, LLC',
  },
  {
    cik: '0001542294',
    value: '0001542294,THIRD SECURITY, LLC',
    name: 'THIRD SECURITY, LLC',
    label: 'THIRD SECURITY, LLC',
  },
  {
    cik: '0001450701',
    value: '0001450701,THOMA BRAVO, L.P.',
    name: 'THOMA BRAVO, L.P.',
    label: 'THOMA BRAVO, L.P.',
  },
  {
    cik: '0001260945',
    value: '0001260945,THOMAS H LEE PARTNERS LP',
    name: 'THOMAS H LEE PARTNERS LP',
    label: 'THOMAS H LEE PARTNERS LP',
  },
  {
    cik: '0001509510',
    value: '0001509510,THOMAS J. HERZFELD ADVISORS, INC.',
    name: 'THOMAS J. HERZFELD ADVISORS, INC.',
    label: 'THOMAS J. HERZFELD ADVISORS, INC.',
  },
  {
    cik: '0001263532',
    value: '0001263532,THOMASPARTNERS, INC.',
    name: 'THOMASPARTNERS, INC.',
    label: 'THOMASPARTNERS, INC.',
  },
  {
    cik: '0001505207',
    value: '0001505207,THOMAS STORY & SON LLC',
    name: 'THOMAS STORY & SON LLC',
    label: 'THOMAS STORY & SON LLC',
  },
  {
    cik: '0001561790',
    value: '0001561790,THOMASVILLE NATIONAL BANK',
    name: 'THOMASVILLE NATIONAL BANK',
    label: 'THOMASVILLE NATIONAL BANK',
  },
  {
    cik: '0001092351',
    value: '0001092351,THOMAS WHITE INTERNATIONAL LTD',
    name: 'THOMAS WHITE INTERNATIONAL LTD',
    label: 'THOMAS WHITE INTERNATIONAL LTD',
  },
  {
    cik: '0001767809',
    value: '0001767809,THOMIST CAPITAL MANAGEMENT, LP',
    name: 'THOMIST CAPITAL MANAGEMENT, LP',
    label: 'THOMIST CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0001017284',
    value: '0001017284,THOMPSON DAVIS & CO., INC.',
    name: 'THOMPSON DAVIS & CO., INC.',
    label: 'THOMPSON DAVIS & CO., INC.',
  },
  {
    cik: '0001277279',
    value: '0001277279,THOMPSON INVESTMENT MANAGEMENT, INC.',
    name: 'THOMPSON INVESTMENT MANAGEMENT, INC.',
    label: 'THOMPSON INVESTMENT MANAGEMENT, INC.',
  },
  {
    cik: '0000825161',
    value: '0000825161,THOMPSON RUBINSTEIN INVESTMENT MANAGEMENT INC /OR/',
    name: 'THOMPSON RUBINSTEIN INVESTMENT MANAGEMENT INC /OR/',
    label: 'THOMPSON RUBINSTEIN INVESTMENT MANAGEMENT INC /OR/',
  },
  {
    cik: '0001008322',
    value: '0001008322,THOMPSON SIEGEL & WALMSLEY LLC',
    name: 'THOMPSON SIEGEL & WALMSLEY LLC',
    label: 'THOMPSON SIEGEL & WALMSLEY LLC',
  },
  {
    cik: '0001057439',
    value: '0001057439,THOMSON HORSTMANN & BRYANT INC',
    name: 'THOMSON HORSTMANN & BRYANT INC',
    label: 'THOMSON HORSTMANN & BRYANT INC',
  },
  {
    cik: '0001591692',
    value: '0001591692,THOR ADVISORS, LLC',
    name: 'THOR ADVISORS, LLC',
    label: 'THOR ADVISORS, LLC',
  },
  {
    cik: '0001145020',
    value: '0001145020,THORNBURG INVESTMENT MANAGEMENT INC',
    name: 'THORNBURG INVESTMENT MANAGEMENT INC',
    label: 'THORNBURG INVESTMENT MANAGEMENT INC',
  },
  {
    cik: '0001482638',
    value: '0001482638,THORNMARK ASSET MANAGEMENT INC.',
    name: 'THORNMARK ASSET MANAGEMENT INC.',
    label: 'THORNMARK ASSET MANAGEMENT INC.',
  },
  {
    cik: '0001688511',
    value: '0001688511,THORNTREE CAPITAL PARTNERS LP',
    name: 'THORNTREE CAPITAL PARTNERS LP',
    label: 'THORNTREE CAPITAL PARTNERS LP',
  },
  {
    cik: '0001091370',
    value: '0001091370,THOROUGHBRED FINANCIAL SERVICES, LLC',
    name: 'THOROUGHBRED FINANCIAL SERVICES, LLC',
    label: 'THOROUGHBRED FINANCIAL SERVICES, LLC',
  },
  {
    cik: '0001631260',
    value: '0001631260,THRAX MANAGEMENT, LLC',
    name: 'THRAX MANAGEMENT, LLC',
    label: 'THRAX MANAGEMENT, LLC',
  },
  {
    cik: '0001834450',
    value: '0001834450,THRC MANAGEMENT, LLC',
    name: 'THRC MANAGEMENT, LLC',
    label: 'THRC MANAGEMENT, LLC',
  },
  {
    cik: '0001814104',
    value: '0001814104,THREADGILL FINANCIAL, LLC',
    name: 'THREADGILL FINANCIAL, LLC',
    label: 'THREADGILL FINANCIAL, LLC',
  },
  {
    cik: '0001593380',
    value: '0001593380,THREE BAYS CAPITAL LP',
    name: 'THREE BAYS CAPITAL LP',
    label: 'THREE BAYS CAPITAL LP',
  },
  {
    cik: '0001950962',
    value: '0001950962,THREE BRIDGE WEALTH ADVISORS, LLC',
    name: 'THREE BRIDGE WEALTH ADVISORS, LLC',
    label: 'THREE BRIDGE WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001584246',
    value: '0001584246,THREE CORNER GLOBAL INVESTORS LP',
    name: 'THREE CORNER GLOBAL INVESTORS LP',
    label: 'THREE CORNER GLOBAL INVESTORS LP',
  },
  {
    cik: '0001598013',
    value: '0001598013,THREE PEAKS CAPITAL MANAGEMENT, LLC',
    name: 'THREE PEAKS CAPITAL MANAGEMENT, LLC',
    label: 'THREE PEAKS CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001666738',
    value: '0001666738,THRESHOLD GROUP LLC',
    name: 'THRESHOLD GROUP LLC',
    label: 'THRESHOLD GROUP LLC',
  },
  {
    cik: '0001845943',
    value: '0001845943,THRIVE CAPITAL MANAGEMENT, LLC',
    name: 'THRIVE CAPITAL MANAGEMENT, LLC',
    label: 'THRIVE CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001765681',
    value: '0001765681,THRIVE CAPITAL MANAGEMENT, LLC',
    name: 'THRIVE CAPITAL MANAGEMENT, LLC',
    label: 'THRIVE CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0000314984',
    value: '0000314984,THRIVENT FINANCIAL FOR LUTHERANS',
    name: 'THRIVENT FINANCIAL FOR LUTHERANS',
    label: 'THRIVENT FINANCIAL FOR LUTHERANS',
  },
  {
    cik: '0001847610',
    value: '0001847610,THRIVE WEALTH MANAGEMENT, LLC',
    name: 'THRIVE WEALTH MANAGEMENT, LLC',
    label: 'THRIVE WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001636974',
    value: '0001636974,THUNDERBIRD PARTNERS LLP',
    name: 'THUNDERBIRD PARTNERS LLP',
    label: 'THUNDERBIRD PARTNERS LLP',
  },
  {
    cik: '0000887793',
    value: '0000887793,TIAA CREF INVESTMENT MANAGEMENT LLC',
    name: 'TIAA CREF INVESTMENT MANAGEMENT LLC',
    label: 'TIAA CREF INVESTMENT MANAGEMENT LLC',
  },
  {
    cik: '0001091559',
    value: '0001091559,TIAA CREF TRUST CO FSB/MO',
    name: 'TIAA CREF TRUST CO FSB/MO',
    label: 'TIAA CREF TRUST CO FSB/MO',
  },
  {
    cik: '0001735101',
    value: '0001735101,TIAA ENDOWMENT & PHILANTHROPIC SERVICES, LLC',
    name: 'TIAA ENDOWMENT & PHILANTHROPIC SERVICES, LLC',
    label: 'TIAA ENDOWMENT & PHILANTHROPIC SERVICES, LLC',
  },
  {
    cik: '0001711615',
    value: '00017116,TIAA, FSB',
    name: 'TIAA, FSB',
    label: 'TIAA, FSB',
  },
  {
    cik: '0001729684',
    value: '0001729684,TIBRA EQUITIES EUROPE LTD',
    name: 'TIBRA EQUITIES EUROPE LTD',
    label: 'TIBRA EQUITIES EUROPE LTD',
  },
  {
    cik: '0001590569',
    value: '0001590569,TIDE POINT CAPITAL MANAGEMENT, LP',
    name: 'TIDE POINT CAPITAL MANAGEMENT, LP',
    label: 'TIDE POINT CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0001483232',
    value: '0001483232,TIEDEMANN ADVISORS, LLC',
    name: 'TIEDEMANN ADVISORS, LLC',
    label: 'TIEDEMANN ADVISORS, LLC',
  },
  {
    cik: '0001313345',
    value: '0001313345,TIEDEMANN TRUST CO',
    name: 'TIEDEMANN TRUST CO',
    label: 'TIEDEMANN TRUST CO',
  },
  {
    cik: '0001432976',
    value: '0001432976,TIEDEMANN WEALTH MANAGEMENT HOLDINGS, LLC',
    name: 'TIEDEMANN WEALTH MANAGEMENT HOLDINGS, LLC',
    label: 'TIEDEMANN WEALTH MANAGEMENT HOLDINGS, LLC',
  },
  {
    cik: '0001483232',
    value: '0001483232,TIEDEMANN WEALTH MANAGEMENT, LLC',
    name: 'TIEDEMANN WEALTH MANAGEMENT, LLC',
    label: 'TIEDEMANN WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001575151',
    value: '0001575151,TIEMANN INVESTMENT ADVISORS, LLC',
    name: 'TIEMANN INVESTMENT ADVISORS, LLC',
    label: 'TIEMANN INVESTMENT ADVISORS, LLC',
  },
  {
    cik: '0001381954',
    value: '0001381954,TIETON CAPITAL MANAGEMENT, LLC',
    name: 'TIETON CAPITAL MANAGEMENT, LLC',
    label: 'TIETON CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001271347',
    value: '0001271347,TIFF ADVISORY SERVICES INC',
    name: 'TIFF ADVISORY SERVICES INC',
    label: 'TIFF ADVISORY SERVICES INC',
  },
  {
    cik: '0001358253',
    value: '0001358253,TIG ADVISORS, LLC',
    name: 'TIG ADVISORS, LLC',
    label: 'TIG ADVISORS, LLC',
  },
  {
    cik: '0001385534',
    value: '0001385534,TIGER CONSUMER MANAGEMENT, LP',
    name: 'TIGER CONSUMER MANAGEMENT, LP',
    label: 'TIGER CONSUMER MANAGEMENT, LP',
  },
  {
    cik: '0001569175',
    value: '0001569175,TIGER EYE CAPITAL LLC',
    name: 'TIGER EYE CAPITAL LLC',
    label: 'TIGER EYE CAPITAL LLC',
  },
  {
    cik: '0001167483',
    value: '0001167483,TIGER GLOBAL MANAGEMENT LLC',
    name: 'TIGER GLOBAL MANAGEMENT LLC',
    label: 'TIGER GLOBAL MANAGEMENT LLC',
  },
  {
    cik: '0001535279',
    value: '0001535279,TIGER LEGATUS CAPITAL MANAGEMENT, LLC',
    name: 'TIGER LEGATUS CAPITAL MANAGEMENT, LLC',
    label: 'TIGER LEGATUS CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001027451',
    value: '0001027451,TIGER MANAGEMENT L.L.C.',
    name: 'TIGER MANAGEMENT L.L.C.',
    label: 'TIGER MANAGEMENT L.L.C.',
  },
  {
    cik: '0001664492',
    value: '0001664492,TIGER PACIFIC CAPITAL LP',
    name: 'TIGER PACIFIC CAPITAL LP',
    label: 'TIGER PACIFIC CAPITAL LP',
  },
  {
    cik: '0001569607',
    value: '0001569607,TIGERSHARK MANAGEMENT, LLC',
    name: 'TIGERSHARK MANAGEMENT, LLC',
    label: 'TIGERSHARK MANAGEMENT, LLC',
  },
  {
    cik: '0001389279',
    value: '0001389279,TIGER VEDA MANAGEMENT LLC',
    name: 'TIGER VEDA MANAGEMENT LLC',
    label: 'TIGER VEDA MANAGEMENT LLC',
  },
  {
    cik: '0001740840',
    value: '0001740840,TIKEHAU INVESTMENT MANAGEMENT',
    name: 'TIKEHAU INVESTMENT MANAGEMENT',
    label: 'TIKEHAU INVESTMENT MANAGEMENT',
  },
  {
    cik: '0001606477',
    value: '0001606477,TIKVAH MANAGEMENT LLC',
    name: 'TIKVAH MANAGEMENT LLC',
    label: 'TIKVAH MANAGEMENT LLC',
  },
  {
    cik: '0001639592',
    value: '0001639592,TILDEN PARK MANAGEMENT I LLC',
    name: 'TILDEN PARK MANAGEMENT I LLC',
    label: 'TILDEN PARK MANAGEMENT I LLC',
  },
  {
    cik: '0001910210',
    value: '0001910210,TILIA FIDUCIARY PARTNERS, INC.',
    name: 'TILIA FIDUCIARY PARTNERS, INC.',
    label: 'TILIA FIDUCIARY PARTNERS, INC.',
  },
  {
    cik: '0001585045',
    value: '0001585045,TILLAR-WENSTRUP ADVISORS, LLC',
    name: 'TILLAR-WENSTRUP ADVISORS, LLC',
    label: 'TILLAR-WENSTRUP ADVISORS, LLC',
  },
  {
    cik: '0001705265',
    value: '0001705265,TIMBERCREEK ASSET MANAGEMENT LTD.',
    name: 'TIMBERCREEK ASSET MANAGEMENT LTD.',
    label: 'TIMBERCREEK ASSET MANAGEMENT LTD.',
  },
  {
    cik: '0001510848',
    value: '0001510848,TIMBER CREEK CAPITAL MANAGEMENT LLC',
    name: 'TIMBER CREEK CAPITAL MANAGEMENT LLC',
    label: 'TIMBER CREEK CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001294223',
    value: '0001294223,TIMBER HILL LLC',
    name: 'TIMBER HILL LLC',
    label: 'TIMBER HILL LLC',
  },
  {
    cik: '0001851296',
    value: '0001851296,TIMELO INVESTMENT MANAGEMENT INC.',
    name: 'TIMELO INVESTMENT MANAGEMENT INC.',
    label: 'TIMELO INVESTMENT MANAGEMENT INC.',
  },
  {
    cik: '0001765054',
    value: '0001765054,TIMESCALE FINANCIAL, INC.',
    name: 'TIMESCALE FINANCIAL, INC.',
    label: 'TIMESCALE FINANCIAL, INC.',
  },
  {
    cik: '0001313816',
    value: '0001313816,TIMESSQUARE CAPITAL MANAGEMENT, LLC',
    name: 'TIMESSQUARE CAPITAL MANAGEMENT, LLC',
    label: 'TIMESSQUARE CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001537558',
    value: '0001537558,TIMPANI CAPITAL MANAGEMENT LLC',
    name: 'TIMPANI CAPITAL MANAGEMENT LLC',
    label: 'TIMPANI CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001034541',
    value: '0001034541,TIMUCUAN ASSET MANAGEMENT INC/FL',
    name: 'TIMUCUAN ASSET MANAGEMENT INC/FL',
    label: 'TIMUCUAN ASSET MANAGEMENT INC/FL',
  },
  {
    cik: '0001569740',
    value: '0001569740,TINICUM INC',
    name: 'TINICUM INC',
    label: 'TINICUM INC',
  },
  {
    cik: '0001569853',
    value: '0001569853,TIPP HILL CAPITAL MANAGEMENT LLC',
    name: 'TIPP HILL CAPITAL MANAGEMENT LLC',
    label: 'TIPP HILL CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0000884535',
    value: '0000884535,TIRSCHWELL & LOEWY INC',
    name: 'TIRSCHWELL & LOEWY INC',
    label: 'TIRSCHWELL & LOEWY INC',
  },
  {
    cik: '0001526246',
    value: '0001526246,TITAN CAPITAL MANAGEMENT, LLC /CA',
    name: 'TITAN CAPITAL MANAGEMENT, LLC /CA',
    label: 'TITAN CAPITAL MANAGEMENT, LLC /CA',
  },
  {
    cik: '0001526246',
    value: '0001526246,TITAN CAPITAL MANAGEMENT, LLC /TX',
    name: 'TITAN CAPITAL MANAGEMENT, LLC /TX',
    label: 'TITAN CAPITAL MANAGEMENT, LLC /TX',
  },
  {
    cik: '0001845930',
    value: '0001845930,TITAN GLOBAL CAPITAL MANAGEMENT USA LLC',
    name: 'TITAN GLOBAL CAPITAL MANAGEMENT USA LLC',
    label: 'TITAN GLOBAL CAPITAL MANAGEMENT USA LLC',
  },
  {
    cik: '0001223025',
    value: '0001223025,TITLEIST ASSET MANAGEMENT, LTD.',
    name: 'TITLEIST ASSET MANAGEMENT, LTD.',
    label: 'TITLEIST ASSET MANAGEMENT, LTD.',
  },
  {
    cik: '0001802284',
    value: '0001802284,TI-TRUST, INC',
    name: 'TI-TRUST, INC',
    label: 'TI-TRUST, INC',
  },
  {
    cik: '0001732007',
    value: '0001732007,TITUS WEALTH MANAGEMENT',
    name: 'TITUS WEALTH MANAGEMENT',
    label: 'TITUS WEALTH MANAGEMENT',
  },
  {
    cik: '0001568680',
    value: '0001568680,TIVERTON ASSET MANAGEMENT LLC',
    name: 'TIVERTON ASSET MANAGEMENT LLC',
    label: 'TIVERTON ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001843275',
    value: '0001843275,TKG ADVISORS, LLC',
    name: 'TKG ADVISORS, LLC',
    label: 'TKG ADVISORS, LLC',
  },
  {
    cik: '0001535764',
    value: '0001535764,TLP GROUP LLC',
    name: 'TLP GROUP LLC',
    label: 'TLP GROUP LLC',
  },
  {
    cik: '0001802868',
    value: '0001802868,TL PRIVATE WEALTH',
    name: 'TL PRIVATE WEALTH',
    label: 'TL PRIVATE WEALTH',
  },
  {
    cik: '0001732537',
    value: '000,TLWM32537',
    name: 'TLWM',
    label: 'TLWM',
  },
  {
    cik: '0001767217',
    value: '0001767217,TLW WEALTH MANAGEMENT, LLC',
    name: 'TLW WEALTH MANAGEMENT, LLC',
    label: 'TLW WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001014358',
    value: '0001014358,TMD & ASSOCIATES, INC',
    name: 'TMD & ASSOCIATES, INC',
    label: 'TMD & ASSOCIATES, INC',
  },
  {
    cik: '0001842820',
    value: '0001842820,TMD WEALTH MANAGEMENT, LLC',
    name: 'TMD WEALTH MANAGEMENT, LLC',
    label: 'TMD WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001357544',
    value: '0001357544,TMFS HOLDINGS LLC',
    name: 'TMFS HOLDINGS LLC',
    label: 'TMFS HOLDINGS LLC',
  },
  {
    cik: '0001561790',
    value: '0001561790,TNB FINANCIAL',
    name: 'TNB FINANCIAL',
    label: 'TNB FINANCIAL',
  },
  {
    cik: '0001849683',
    value: '000184,TNF LLC83',
    name: 'TNF LLC',
    label: 'TNF LLC',
  },
  {
    cik: '0001527488',
    value: '0001,TOBAM7488',
    name: 'TOBAM',
    label: 'TOBAM',
  },
  {
    cik: '0001802080',
    value: '0001802080,TOBIAS FINANCIAL ADVISORS, INC.',
    name: 'TOBIAS FINANCIAL ADVISORS, INC.',
    label: 'TOBIAS FINANCIAL ADVISORS, INC.',
  },
  {
    cik: '0000883961',
    value: '0000883961,TOCQUEVILLE ASSET MANAGEMENT L.P.',
    name: 'TOCQUEVILLE ASSET MANAGEMENT L.P.',
    label: 'TOCQUEVILLE ASSET MANAGEMENT L.P.',
  },
  {
    cik: '0001071483',
    value: '0001071483,TODD ASSET MANAGEMENT LLC',
    name: 'TODD ASSET MANAGEMENT LLC',
    label: 'TODD ASSET MANAGEMENT LLC',
  },
  {
    cik: '0000932000',
    value: '0000932000,TOEWS CORP /ADV',
    name: 'TOEWS CORP /ADV',
    label: 'TOEWS CORP /ADV',
  },
  {
    cik: '0001504169',
    value: '0001504169,TOKIO MARINE ASSET MANAGEMENT CO LTD',
    name: 'TOKIO MARINE ASSET MANAGEMENT CO LTD',
    label: 'TOKIO MARINE ASSET MANAGEMENT CO LTD',
  },
  {
    cik: '0000098561',
    value: '0000098561,TOKIO MARINE & NICHIDO FIRE INSURANCE CO., LTD',
    name: 'TOKIO MARINE & NICHIDO FIRE INSURANCE CO., LTD',
    label: 'TOKIO MARINE & NICHIDO FIRE INSURANCE CO., LTD',
  },
  {
    cik: '0001772937',
    value: '0001772937,TOLLESON WEALTH MANAGEMENT, INC.',
    name: 'TOLLESON WEALTH MANAGEMENT, INC.',
    label: 'TOLLESON WEALTH MANAGEMENT, INC.',
  },
  {
    cik: '0001005817',
    value: '0001005817,TOMPKINS FINANCIAL CORP',
    name: 'TOMPKINS FINANCIAL CORP',
    label: 'TOMPKINS FINANCIAL CORP',
  },
  {
    cik: '0001743937',
    value: '0001743937,TOMS CAPITAL INVESTMENT MANAGEMENT LP',
    name: 'TOMS CAPITAL INVESTMENT MANAGEMENT LP',
    label: 'TOMS CAPITAL INVESTMENT MANAGEMENT LP',
  },
  {
    cik: '0001614200',
    value: '0001614200,TOP ACE ASSET MANAGEMENT LTD',
    name: 'TOP ACE ASSET MANAGEMENT LTD',
    label: 'TOP ACE ASSET MANAGEMENT LTD',
  },
  {
    cik: '0001880087',
    value: '0001880087,TOPEL & DISTASI WEALTH MANAGEMENT, LLC',
    name: 'TOPEL & DISTASI WEALTH MANAGEMENT, LLC',
    label: 'TOPEL & DISTASI WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0000320335',
    value: '0000320335,TORCHMARK CORP',
    name: 'TORCHMARK CORP',
    label: 'TORCHMARK CORP',
  },
  {
    cik: '0001598898',
    value: '0001598898,TORCH WEALTH MANAGEMENT LLC',
    name: 'TORCH WEALTH MANAGEMENT LLC',
    label: 'TORCH WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0001749611',
    value: '0001749611,TORONADO PARTNERS, LLC',
    name: 'TORONADO PARTNERS, LLC',
    label: 'TORONADO PARTNERS, LLC',
  },
  {
    cik: '0001621915',
    value: '0001621915,TORON CAPITAL MARKETS INC.',
    name: 'TORON CAPITAL MARKETS INC.',
    label: 'TORON CAPITAL MARKETS INC.',
  },
  {
    cik: '0000947263',
    value: '0000947263,TORONTO DOMINION BANK',
    name: 'TORONTO DOMINION BANK',
    label: 'TORONTO DOMINION BANK',
  },
  {
    cik: '0001600064',
    value: '0001600064,TOROSO INVESTMENTS, LLC',
    name: 'TOROSO INVESTMENTS, LLC',
    label: 'TOROSO INVESTMENTS, LLC',
  },
  {
    cik: '0001699092',
    value: '0001699092,TORQ CAPITAL MANAGEMENT (HK) LTD',
    name: 'TORQ CAPITAL MANAGEMENT (HK) LTD',
    label: 'TORQ CAPITAL MANAGEMENT (HK) LTD',
  },
  {
    cik: '0000098758',
    value: '000009875,TORRAY LLC',
    name: 'TORRAY LLC',
    label: 'TORRAY LLC',
  },
  {
    cik: '0001387869',
    value: '0001387869,TORTOISE ADVISORS UK LTD',
    name: 'TORTOISE ADVISORS UK LTD',
    label: 'TORTOISE ADVISORS UK LTD',
  },
  {
    cik: '0001280965',
    value: '0001280965,TORTOISE CAPITAL ADVISORS, L.L.C.',
    name: 'TORTOISE CAPITAL ADVISORS, L.L.C.',
    label: 'TORTOISE CAPITAL ADVISORS, L.L.C.',
  },
  {
    cik: '0001650373',
    value: '0001650373,TORTOISE INDEX SOLUTIONS, LLC',
    name: 'TORTOISE INDEX SOLUTIONS, LLC',
    label: 'TORTOISE INDEX SOLUTIONS, LLC',
  },
  {
    cik: '0001599511',
    value: '0001599511,TORTOISE INVESTMENT MANAGEMENT, LLC',
    name: 'TORTOISE INVESTMENT MANAGEMENT, LLC',
    label: 'TORTOISE INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001439289',
    value: '0001439289,TOSCAFUND ASSET MANAGEMENT LLP',
    name: 'TOSCAFUND ASSET MANAGEMENT LLP',
    label: 'TOSCAFUND ASSET MANAGEMENT LLP',
  },
  {
    cik: '0001804909',
    value: '0001804909,TOTAL CLARITY WEALTH MANAGEMENT, INC.',
    name: 'TOTAL CLARITY WEALTH MANAGEMENT, INC.',
    label: 'TOTAL CLARITY WEALTH MANAGEMENT, INC.',
  },
  {
    cik: '0001068804',
    value: '0001068804,TOTAL INVESTMENT MANAGEMENT INC',
    name: 'TOTAL INVESTMENT MANAGEMENT INC',
    label: 'TOTAL INVESTMENT MANAGEMENT INC',
  },
  {
    cik: '0001762997',
    value: '0001762997,TOTAL WEALTH PLANNING, LLC',
    name: 'TOTAL WEALTH PLANNING, LLC',
    label: 'TOTAL WEALTH PLANNING, LLC',
  },
  {
    cik: '0001759364',
    value: '0001759364,TOTEM POINT MANAGEMENT, LLC',
    name: 'TOTEM POINT MANAGEMENT, LLC',
    label: 'TOTEM POINT MANAGEMENT, LLC',
  },
  {
    cik: '0000866780',
    value: '0000866780,TOTH FINANCIAL ADVISORY CORP',
    name: 'TOTH FINANCIAL ADVISORY CORP',
    label: 'TOTH FINANCIAL ADVISORY CORP',
  },
  {
    cik: '0001843283',
    value: '0001843283,TOUCHSTONE CAPITAL, INC.',
    name: 'TOUCHSTONE CAPITAL, INC.',
    label: 'TOUCHSTONE CAPITAL, INC.',
  },
  {
    cik: '0001336657',
    value: '0001336657,TOURADJI CAPITAL MANAGEMENT, LP',
    name: 'TOURADJI CAPITAL MANAGEMENT, LP',
    label: 'TOURADJI CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0001592476',
    value: '0001592476,TOURBILLON CAPITAL PARTNERS LP',
    name: 'TOURBILLON CAPITAL PARTNERS LP',
    label: 'TOURBILLON CAPITAL PARTNERS LP',
  },
  {
    cik: '0001278249',
    value: '0001278249,TOWER BRIDGE ADVISORS',
    name: 'TOWER BRIDGE ADVISORS',
    label: 'TOWER BRIDGE ADVISORS',
  },
  {
    cik: '0001633288',
    value: '0001633288,TOWERCREST CAPITAL MANAGEMENT',
    name: 'TOWERCREST CAPITAL MANAGEMENT',
    label: 'TOWERCREST CAPITAL MANAGEMENT',
  },
  {
    cik: '0001767905',
    value: '0001767905,TOWER HOUSE PARTNERS LLP',
    name: 'TOWER HOUSE PARTNERS LLP',
    label: 'TOWER HOUSE PARTNERS LLP',
  },
  {
    cik: '0001731445',
    value: '0001731445,TOWERPOINT WEALTH, LLC',
    name: 'TOWERPOINT WEALTH, LLC',
    label: 'TOWERPOINT WEALTH, LLC',
  },
  {
    cik: '0001533421',
    value: '0001533421,TOWER RESEARCH CAPITAL LLC',
    name: 'TOWER RESEARCH CAPITAL LLC',
    label: 'TOWER RESEARCH CAPITAL LLC',
  },
  {
    cik: '0001533421',
    value: '0001533421,TOWER RESEARCH CAPITAL LLC (TRC)',
    name: 'TOWER RESEARCH CAPITAL LLC (TRC)',
    label: 'TOWER RESEARCH CAPITAL LLC (TRC)',
  },
  {
    cik: '0001665979',
    value: '0001665979,TOWER SQUARE INVESTMENT MANAGEMENT, LLC',
    name: 'TOWER SQUARE INVESTMENT MANAGEMENT, LLC',
    label: 'TOWER SQUARE INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001123263',
    value: '0001123263,TOWER TRUST CO',
    name: 'TOWER TRUST CO',
    label: 'TOWER TRUST CO',
  },
  {
    cik: '0001729045',
    value: '0001729045,TOWER VIEW INVESTMENT MANAGEMENT & RESEARCH LLC',
    name: 'TOWER VIEW INVESTMENT MANAGEMENT & RESEARCH LLC',
    label: 'TOWER VIEW INVESTMENT MANAGEMENT & RESEARCH LLC',
  },
  {
    cik: '0001166573',
    value: '0001166573,TOWERVIEW LLC',
    name: 'TOWERVIEW LLC',
    label: 'TOWERVIEW LLC',
  },
  {
    cik: '0001852808',
    value: '0001852808,TOWER WEALTH PARTNERS, INC.',
    name: 'TOWER WEALTH PARTNERS, INC.',
    label: 'TOWER WEALTH PARTNERS, INC.',
  },
  {
    cik: '0001224014',
    value: '000122401,TOWLE & CO',
    name: 'TOWLE & CO',
    label: 'TOWLE & CO',
  },
  {
    cik: '0001327944',
    value:
      '0001327944,TOWN & COUNTRY BANK & TRUST CO DBA FIRST BANKERS TRUST CO',
    name: 'TOWN & COUNTRY BANK & TRUST CO DBA FIRST BANKERS TRUST CO',
    label: 'TOWN & COUNTRY BANK & TRUST CO DBA FIRST BANKERS TRUST CO',
  },
  {
    cik: '0000709089',
    value: '0000709089,TOWNSEND ASSET MANAGEMENT CORP /NC/ /ADV',
    name: 'TOWNSEND ASSET MANAGEMENT CORP /NC/ /ADV',
    label: 'TOWNSEND ASSET MANAGEMENT CORP /NC/ /ADV',
  },
  {
    cik: '0001729359',
    value: '0001729359,TOWNSEND & ASSOCIATES, INC',
    name: 'TOWNSEND & ASSOCIATES, INC',
    label: 'TOWNSEND & ASSOCIATES, INC',
  },
  {
    cik: '0001761755',
    value: '0001761755,TOWNSQUARE CAPITAL LLC',
    name: 'TOWNSQUARE CAPITAL LLC',
    label: 'TOWNSQUARE CAPITAL LLC',
  },
  {
    cik: '0001719203',
    value: '0001719203,TPB WEALTH ADVISORS',
    name: 'TPB WEALTH ADVISORS',
    label: 'TPB WEALTH ADVISORS',
  },
  {
    cik: '0001508755',
    value: '0001508755,TPG-AXON MANAGEMENT LP',
    name: 'TPG-AXON MANAGEMENT LP',
    label: 'TPG-AXON MANAGEMENT LP',
  },
  {
    cik: '0001706669',
    value: '0001706669,TPG FINANCIAL ADVISORS, LLC',
    name: 'TPG FINANCIAL ADVISORS, LLC',
    label: 'TPG FINANCIAL ADVISORS, LLC',
  },
  {
    cik: '0001903793',
    value: '0001903793,TPG GP A, LLC',
    name: 'TPG GP A, LLC',
    label: 'TPG GP A, LLC',
  },
  {
    cik: '0001495741',
    value: '0001495741,TPG GROUP HOLDINGS (SBS) ADVISORS, INC.',
    name: 'TPG GROUP HOLDINGS (SBS) ADVISORS, INC.',
    label: 'TPG GROUP HOLDINGS (SBS) ADVISORS, INC.',
  },
  {
    cik: '0001535249',
    value: '0001535249,TPH ASSET MANAGEMENT, LLC',
    name: 'TPH ASSET MANAGEMENT, LLC',
    label: 'TPH ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001785438',
    value: '0001785438,TPI FUND MANAGERS LTD',
    name: 'TPI FUND MANAGERS LTD',
    label: 'TPI FUND MANAGERS LTD',
  },
  {
    cik: '0001642044',
    value: '0001642044,TRADELINK CAPITAL LLC',
    name: 'TRADELINK CAPITAL LLC',
    label: 'TRADELINK CAPITAL LLC',
  },
  {
    cik: '0001402302',
    value: '0001402302,TRADELINK GLOBAL EQUITY LLC',
    name: 'TRADELINK GLOBAL EQUITY LLC',
    label: 'TRADELINK GLOBAL EQUITY LLC',
  },
  {
    cik: '0001616026',
    value: '0001616026,TRADEWINDS CAPITAL MANAGEMENT, LLC',
    name: 'TRADEWINDS CAPITAL MANAGEMENT, LLC',
    label: 'TRADEWINDS CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001383221',
    value: '0001383221,TRADEWINDS GLOBAL INVESTORS, LLC',
    name: 'TRADEWINDS GLOBAL INVESTORS, LLC',
    label: 'TRADEWINDS GLOBAL INVESTORS, LLC',
  },
  {
    cik: '0001932342',
    value: '0001932342,TRADEWINDS, LLC.',
    name: 'TRADEWINDS, LLC.',
    label: 'TRADEWINDS, LLC.',
  },
  {
    cik: '0001389526',
    value: '0001389526,TRADEWORX INC.',
    name: 'TRADEWORX INC.',
    label: 'TRADEWORX INC.',
  },
  {
    cik: '0001778396',
    value: '0001778396,TRADITION ASSET MANAGEMENT LLC',
    name: 'TRADITION ASSET MANAGEMENT LLC',
    label: 'TRADITION ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001166385',
    value: '0001166385,TRADITION CAPITAL MANAGEMENT LLC',
    name: 'TRADITION CAPITAL MANAGEMENT LLC',
    label: 'TRADITION CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001843010',
    value: '0001843010,TRADITION WEALTH MANAGEMENT, LLC',
    name: 'TRADITION WEALTH MANAGEMENT, LLC',
    label: 'TRADITION WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001387672',
    value: '0001387672,TRAFELET BROKAW CAPITAL MANAGEMENT, L.P.',
    name: 'TRAFELET BROKAW CAPITAL MANAGEMENT, L.P.',
    label: 'TRAFELET BROKAW CAPITAL MANAGEMENT, L.P.',
  },
  {
    cik: '0001725120',
    value: '0001725120,TRAILHEAD PACIFIC, LLC',
    name: 'TRAILHEAD PACIFIC, LLC',
    label: 'TRAILHEAD PACIFIC, LLC',
  },
  {
    cik: '0001911013',
    value: '0001911013,TRAIL RIDGE INVESTMENT ADVISORS, LLC',
    name: 'TRAIL RIDGE INVESTMENT ADVISORS, LLC',
    label: 'TRAIL RIDGE INVESTMENT ADVISORS, LLC',
  },
  {
    cik: '0000860862',
    value: '0000860862,TRAIN BABCOCK ADVISORS LLC',
    name: 'TRAIN BABCOCK ADVISORS LLC',
    label: 'TRAIN BABCOCK ADVISORS LLC',
  },
  {
    cik: '0001009262',
    value: '0001009262,TRAN CAPITAL MANAGEMENT, L.P.',
    name: 'TRAN CAPITAL MANAGEMENT, L.P.',
    label: 'TRAN CAPITAL MANAGEMENT, L.P.',
  },
  {
    cik: '0001783773',
    value: '0001783773,TRANQUILITY PARTNERS, LLC',
    name: 'TRANQUILITY PARTNERS, LLC',
    label: 'TRANQUILITY PARTNERS, LLC',
  },
  {
    cik: '0001847769',
    value: '0001847769,TRANQUILLI FINANCIAL ADVISOR LLC',
    name: 'TRANQUILLI FINANCIAL ADVISOR LLC',
    label: 'TRANQUILLI FINANCIAL ADVISOR LLC',
  },
  {
    cik: '0001502149',
    value: '0001502149,TRANSAMERICA FINANCIAL ADVISORS, INC.',
    name: 'TRANSAMERICA FINANCIAL ADVISORS, INC.',
    label: 'TRANSAMERICA FINANCIAL ADVISORS, INC.',
  },
  {
    cik: '0001935000',
    value: '0001935000,TRANSATLANTIQUE PRIVATE WEALTH LLC',
    name: 'TRANSATLANTIQUE PRIVATE WEALTH LLC',
    label: 'TRANSATLANTIQUE PRIVATE WEALTH LLC',
  },
  {
    cik: '0001776082',
    value: '0001776082,TRANSCEND CAPITAL ADVISORS, LLC',
    name: 'TRANSCEND CAPITAL ADVISORS, LLC',
    label: 'TRANSCEND CAPITAL ADVISORS, LLC',
  },
  {
    cik: '0001776082',
    value: '0001776082,TRANSCEND WEALTH COLLECTIVE, LLC',
    name: 'TRANSCEND WEALTH COLLECTIVE, LLC',
    label: 'TRANSCEND WEALTH COLLECTIVE, LLC',
  },
  {
    cik: '0001682266',
    value: '0001682266,TRANSFORM WEALTH, LLC',
    name: 'TRANSFORM WEALTH, LLC',
    label: 'TRANSFORM WEALTH, LLC',
  },
  {
    cik: '0001906537',
    value: '0001906537,TRANSPARENT WEALTH PARTNERS LLC',
    name: 'TRANSPARENT WEALTH PARTNERS LLC',
    label: 'TRANSPARENT WEALTH PARTNERS LLC',
  },
  {
    cik: '0000086312',
    value: '0000086312,TRAVELERS COMPANIES, INC.',
    name: 'TRAVELERS COMPANIES, INC.',
    label: 'TRAVELERS COMPANIES, INC.',
  },
  {
    cik: '0001767346',
    value: '0001767346,TRAVERSO CHAMBERS PRIVATE WEALTH MANAGEMENT, LLC',
    name: 'TRAVERSO CHAMBERS PRIVATE WEALTH MANAGEMENT, LLC',
    label: 'TRAVERSO CHAMBERS PRIVATE WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001666786',
    value: '0001666786,TRAYNOR CAPITAL MANAGEMENT, INC.',
    name: 'TRAYNOR CAPITAL MANAGEMENT, INC.',
    label: 'TRAYNOR CAPITAL MANAGEMENT, INC.',
  },
  {
    cik: '0001513300',
    value: '0001513300,TRB ADVISORS LP',
    name: 'TRB ADVISORS LP',
    label: 'TRB ADVISORS LP',
  },
  {
    cik: '0001911307',
    value: '0001911307,TREASURE COAST FINANCIAL PLANNING',
    name: 'TREASURE COAST FINANCIAL PLANNING',
    label: 'TREASURE COAST FINANCIAL PLANNING',
  },
  {
    cik: '0001761054',
    value: '0001761054,TREASURER OF THE STATE OF NORTH CAROLINA',
    name: 'TREASURER OF THE STATE OF NORTH CAROLINA',
    label: 'TREASURER OF THE STATE OF NORTH CAROLINA',
  },
  {
    cik: '0001650290',
    value: '0001650290,TREDJE AP-FONDEN',
    name: 'TREDJE AP-FONDEN',
    label: 'TREDJE AP-FONDEN',
  },
  {
    cik: '0001535096',
    value: '0001535096,TREE LINE ADVISORS (HONG KONG) LTD.',
    name: 'TREE LINE ADVISORS (HONG KONG) LTD.',
    label: 'TREE LINE ADVISORS (HONG KONG) LTD.',
  },
  {
    cik: '0001112179',
    value: '0001112179,TREES INVESTMENT COUNSEL LLC',
    name: 'TREES INVESTMENT COUNSEL LLC',
    label: 'TREES INVESTMENT COUNSEL LLC',
  },
  {
    cik: '0001768195',
    value: '0001768195,TREK FINANCIAL, LLC',
    name: 'TREK FINANCIAL, LLC',
    label: 'TREK FINANCIAL, LLC',
  },
  {
    cik: '0001722638',
    value: '0001722638,TRELLIS ADVISORS, LLC',
    name: 'TRELLIS ADVISORS, LLC',
    label: 'TRELLIS ADVISORS, LLC',
  },
  {
    cik: '0001307877',
    value: '0001307877,TRELLUS MANAGEMENT COMPANY, LLC',
    name: 'TRELLUS MANAGEMENT COMPANY, LLC',
    label: 'TRELLUS MANAGEMENT COMPANY, LLC',
  },
  {
    cik: '0001353312',
    value: '0001353312,TREMBLANT CAPITAL GROUP',
    name: 'TREMBLANT CAPITAL GROUP',
    label: 'TREMBLANT CAPITAL GROUP',
  },
  {
    cik: '0000925776',
    value: '0000925776,TRENT CAPITAL MANAGEMENT INC /NC/',
    name: 'TRENT CAPITAL MANAGEMENT INC /NC/',
    label: 'TRENT CAPITAL MANAGEMENT INC /NC/',
  },
  {
    cik: '0001901929',
    value: '0001901929,TREVIAN WEALTH MANAGEMENT LLC',
    name: 'TREVIAN WEALTH MANAGEMENT LLC',
    label: 'TREVIAN WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0000866995',
    value: '0000866995,TREVOR STEWART BURTON & JACOBSEN INC',
    name: 'TREVOR STEWART BURTON & JACOBSEN INC',
    label: 'TREVOR STEWART BURTON & JACOBSEN INC',
  },
  {
    cik: '0001633445',
    value: '0001633445,TREXQUANT INVESTMENT LP',
    name: 'TREXQUANT INVESTMENT LP',
    label: 'TREXQUANT INVESTMENT LP',
  },
  {
    cik: '0001835976',
    value: '0001835976,TREYBOURNE WEALTH PLANNERS, INC.',
    name: 'TREYBOURNE WEALTH PLANNERS, INC.',
    label: 'TREYBOURNE WEALTH PLANNERS, INC.',
  },
  {
    cik: '0001873063',
    value: '0001873063,TREYNOR BANCSHARES, INC.',
    name: 'TREYNOR BANCSHARES, INC.',
    label: 'TREYNOR BANCSHARES, INC.',
  },
  {
    cik: '0001675088',
    value: '0001675088,TRG INVESTMENTS, LLC',
    name: 'TRG INVESTMENTS, LLC',
    label: 'TRG INVESTMENTS, LLC',
  },
  {
    cik: '0001730210',
    value: '0001730210,TRH FINANCIAL, LLC',
    name: 'TRH FINANCIAL, LLC',
    label: 'TRH FINANCIAL, LLC',
  },
  {
    cik: '0001925074',
    value: '0001925074,TRIAD FINANCIAL ADVISORS, INC.',
    name: 'TRIAD FINANCIAL ADVISORS, INC.',
    label: 'TRIAD FINANCIAL ADVISORS, INC.',
  },
  {
    cik: '0001615717',
    value: '0001615717,TRIAD INVESTMENT MANAGEMENT',
    name: 'TRIAD INVESTMENT MANAGEMENT',
    label: 'TRIAD INVESTMENT MANAGEMENT',
  },
  {
    cik: '0001843848',
    value: '0001843848,TRIAGEN WEALTH MANAGEMENT LLC',
    name: 'TRIAGEN WEALTH MANAGEMENT LLC',
    label: 'TRIAGEN WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0001345471',
    value: '0001345471,TRIAN FUND MANAGEMENT, L.P.',
    name: 'TRIAN FUND MANAGEMENT, L.P.',
    label: 'TRIAN FUND MANAGEMENT, L.P.',
  },
  {
    cik: '0001532600',
    value: '0001532600,TRIANGLE PEAK PARTNERS, LP',
    name: 'TRIANGLE PEAK PARTNERS, LP',
    label: 'TRIANGLE PEAK PARTNERS, LP',
  },
  {
    cik: '0001541353',
    value: '0001541353,TRIANGLE SECURITIES WEALTH MANAGEMENT',
    name: 'TRIANGLE SECURITIES WEALTH MANAGEMENT',
    label: 'TRIANGLE SECURITIES WEALTH MANAGEMENT',
  },
  {
    cik: '0001674193',
    value: '0001674193,TRIARII CAPITAL MANAGEMENT LP',
    name: 'TRIARII CAPITAL MANAGEMENT LP',
    label: 'TRIARII CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001938757',
    value: '0001938757,TRIASIMA PORTFOLIO MANAGEMENT INC.',
    name: 'TRIASIMA PORTFOLIO MANAGEMENT INC.',
    label: 'TRIASIMA PORTFOLIO MANAGEMENT INC.',
  },
  {
    cik: '0001852813',
    value: '0001852813,TRIATOMIC MANAGEMENT LP',
    name: 'TRIATOMIC MANAGEMENT LP',
    label: 'TRIATOMIC MANAGEMENT LP',
  },
  {
    cik: '0001441888',
    value: '0001441888,TRIBUTARY CAPITAL MANAGEMENT, LLC',
    name: 'TRIBUTARY CAPITAL MANAGEMENT, LLC',
    label: 'TRIBUTARY CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001455452',
    value: '0001455452,TRICADIA CAPITAL MANAGEMENT, LLC',
    name: 'TRICADIA CAPITAL MANAGEMENT, LLC',
    label: 'TRICADIA CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001484086',
    value: '0001484086,TRIDENT INVESTMENT MANAGEMENT, LLC',
    name: 'TRIDENT INVESTMENT MANAGEMENT, LLC',
    label: 'TRIDENT INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001962615',
    value: '0001962615,TRIFECTA CAPITAL ADVISORS, LLC',
    name: 'TRIFECTA CAPITAL ADVISORS, LLC',
    label: 'TRIFECTA CAPITAL ADVISORS, LLC',
  },
  {
    cik: '0001336800',
    value: '0001336800,TRIGRAN INVESTMENTS, INC.',
    name: 'TRIGRAN INVESTMENTS, INC.',
    label: 'TRIGRAN INVESTMENTS, INC.',
  },
  {
    cik: '0001803478',
    value: '0001803478,TRIKON ASSET MANAGEMENT LTD',
    name: 'TRIKON ASSET MANAGEMENT LTD',
    label: 'TRIKON ASSET MANAGEMENT LTD',
  },
  {
    cik: '0001462014',
    value: '0001462014,TRILANTIC CAPITAL MANAGEMENT L.P.',
    name: 'TRILANTIC CAPITAL MANAGEMENT L.P.',
    label: 'TRILANTIC CAPITAL MANAGEMENT L.P.',
  },
  {
    cik: '0000884541',
    value: '0000884541,TRILLIUM ASSET MANAGEMENT, LLC',
    name: 'TRILLIUM ASSET MANAGEMENT, LLC',
    label: 'TRILLIUM ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001826434',
    value: '0001826434,TRI LOCUM PARTNERS LP',
    name: 'TRI LOCUM PARTNERS LP',
    label: 'TRI LOCUM PARTNERS LP',
  },
  {
    cik: '0001722512',
    value: '0001722512,TRILOGY CAPITAL INC.',
    name: 'TRILOGY CAPITAL INC.',
    label: 'TRILOGY CAPITAL INC.',
  },
  {
    cik: '0001128573',
    value: '0001128573,TRILOGY GLOBAL ADVISORS LP',
    name: 'TRILOGY GLOBAL ADVISORS LP',
    label: 'TRILOGY GLOBAL ADVISORS LP',
  },
  {
    cik: '0001322749',
    value: '0001322749,TRIMARAN FUND MANAGEMENT, L.L.C.',
    name: 'TRIMARAN FUND MANAGEMENT, L.L.C.',
    label: 'TRIMARAN FUND MANAGEMENT, L.L.C.',
  },
  {
    cik: '0001604903',
    value: '0001604903,TRIMTABS ASSET MANAGEMENT, LLC',
    name: 'TRIMTABS ASSET MANAGEMENT, LLC',
    label: 'TRIMTABS ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001809574',
    value: '0001809574,TRINITY FINANCIAL ADVISORS LLC',
    name: 'TRINITY FINANCIAL ADVISORS LLC',
    label: 'TRINITY FINANCIAL ADVISORS LLC',
  },
  {
    cik: '0001652529',
    value: '0001652529,TRINITY LEGACY PARTNERS LLC',
    name: 'TRINITY LEGACY PARTNERS LLC',
    label: 'TRINITY LEGACY PARTNERS LLC',
  },
  {
    cik: '0001652529',
    value: '0001652529,TRINITY LEGACY PARTNERS, LLC',
    name: 'TRINITY LEGACY PARTNERS, LLC',
    label: 'TRINITY LEGACY PARTNERS, LLC',
  },
  {
    cik: '0001766510',
    value: '0001766510,TRINITYPOINT WEALTH, LLC',
    name: 'TRINITYPOINT WEALTH, LLC',
    label: 'TRINITYPOINT WEALTH, LLC',
  },
  {
    cik: '0001592579',
    value: '0001592579,TRINITY STREET ASSET MANAGEMENT LLP',
    name: 'TRINITY STREET ASSET MANAGEMENT LLP',
    label: 'TRINITY STREET ASSET MANAGEMENT LLP',
  },
  {
    cik: '0001685364',
    value: '0001685364,TRINITY WEALTH MANAGEMENT, LLC',
    name: 'TRINITY WEALTH MANAGEMENT, LLC',
    label: 'TRINITY WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001557664',
    value: '0001557664,TRIOAKS CAPITAL MANAGEMENT L.P.',
    name: 'TRIOAKS CAPITAL MANAGEMENT L.P.',
    label: 'TRIOAKS CAPITAL MANAGEMENT L.P.',
  },
  {
    cik: '0001748729',
    value: '0001748729,TRIODOS INVESTMENT MANAGEMENT BV',
    name: 'TRIODOS INVESTMENT MANAGEMENT BV',
    label: 'TRIODOS INVESTMENT MANAGEMENT BV',
  },
  {
    cik: '0001454502',
    value: '0001454502,TRIPLE FROND PARTNERS LLC',
    name: 'TRIPLE FROND PARTNERS LLC',
    label: 'TRIPLE FROND PARTNERS LLC',
  },
  {
    cik: '0001466715',
    value: '0001466715,TRISHIELD CAPITAL MANAGEMENT LLC',
    name: 'TRISHIELD CAPITAL MANAGEMENT LLC',
    label: 'TRISHIELD CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001821289',
    value: '0001821289,TRI-STAR ADVISORS INC.',
    name: 'TRI-STAR ADVISORS INC.',
    label: 'TRI-STAR ADVISORS INC.',
  },
  {
    cik: '0001791126',
    value: '0001791126,TRITON WEALTH MANAGEMENT, PLLC',
    name: 'TRITON WEALTH MANAGEMENT, PLLC',
    label: 'TRITON WEALTH MANAGEMENT, PLLC',
  },
  {
    cik: '0001730765',
    value: '0001730765,TRIUMPH CAPITAL MANAGEMENT',
    name: 'TRIUMPH CAPITAL MANAGEMENT',
    label: 'TRIUMPH CAPITAL MANAGEMENT',
  },
  {
    cik: '0001555623',
    value: '0001555623,TRIVEST ADVISORS LTD',
    name: 'TRIVEST ADVISORS LTD',
    label: 'TRIVEST ADVISORS LTD',
  },
  {
    cik: '0001897612',
    value: '0001897612,T. ROWE PRICE INVESTMENT MANAGEMENT, INC.',
    name: 'T. ROWE PRICE INVESTMENT MANAGEMENT, INC.',
    label: 'T. ROWE PRICE INVESTMENT MANAGEMENT, INC.',
  },
  {
    cik: '0001601407',
    value: '0001601407,TROY ASSET MANAGEMENT LTD',
    name: 'TROY ASSET MANAGEMENT LTD',
    label: 'TROY ASSET MANAGEMENT LTD',
  },
  {
    cik: '0001810512',
    value: '0001810512,TRUADVICE, LLC',
    name: 'TRUADVICE, LLC',
    label: 'TRUADVICE, LLC',
  },
  {
    cik: '0001321300',
    value: '0001321300,TRUE BEARING ASSET MANAGEMENT LP',
    name: 'TRUE BEARING ASSET MANAGEMENT LP',
    label: 'TRUE BEARING ASSET MANAGEMENT LP',
  },
  {
    cik: '0001766067',
    value: '0001766067,TRUEFG, LLC',
    name: 'TRUEFG, LLC',
    label: 'TRUEFG, LLC',
  },
  {
    cik: '0001302720',
    value: '0001302720,TRUEHAND, INC',
    name: 'TRUEHAND, INC',
    label: 'TRUEHAND, INC',
  },
  {
    cik: '0001846236',
    value: '0001846236,TRUE LINK FINANCIAL ADVISORS, LLC',
    name: 'TRUE LINK FINANCIAL ADVISORS, LLC',
    label: 'TRUE LINK FINANCIAL ADVISORS, LLC',
  },
  {
    cik: '0001634556',
    value: '0001634556,TRUE NORTH ADVISORS, LLC',
    name: 'TRUE NORTH ADVISORS, LLC',
    label: 'TRUE NORTH ADVISORS, LLC',
  },
  {
    cik: '0001455495',
    value: '0001455495,TRUENORTH, INC.',
    name: 'TRUENORTH, INC.',
    label: 'TRUENORTH, INC.',
  },
  {
    cik: '0001427748',
    value: '0001427748,TRUEPOINT, INC.',
    name: 'TRUEPOINT, INC.',
    label: 'TRUEPOINT, INC.',
  },
  {
    cik: '0001667074',
    value: '0001667074,TRUE PRIVATE WEALTH ADVISORS',
    name: 'TRUE PRIVATE WEALTH ADVISORS',
    label: 'TRUE PRIVATE WEALTH ADVISORS',
  },
  {
    cik: '0001598007',
    value: '0001598007,TRUE VENTURE PARTNERS IV, L.L.C.',
    name: 'TRUE VENTURE PARTNERS IV, L.L.C.',
    label: 'TRUE VENTURE PARTNERS IV, L.L.C.',
  },
  {
    cik: '0001817714',
    value: '0001817714,TRUEWEALTH ADVISORS, LLC',
    name: 'TRUEWEALTH ADVISORS, LLC',
    label: 'TRUEWEALTH ADVISORS, LLC',
  },
  {
    cik: '0001908108',
    value: '0001908108,TRUEWEALTH FINANCIAL PARTNERS',
    name: 'TRUEWEALTH FINANCIAL PARTNERS',
    label: 'TRUEWEALTH FINANCIAL PARTNERS',
  },
  {
    cik: '0001722092',
    value: '0001722092,TRUEWEALTH LLC',
    name: 'TRUEWEALTH LLC',
    label: 'TRUEWEALTH LLC',
  },
  {
    cik: '0001824827',
    value: '0001824827,TRUE WIND CAPITAL MANAGEMENT, L.P.',
    name: 'TRUE WIND CAPITAL MANAGEMENT, L.P.',
    label: 'TRUE WIND CAPITAL MANAGEMENT, L.P.',
  },
  {
    cik: '0001849497',
    value: '0001849497,TRU INDEPENDENCE ASSET MANAGEMENT 2, LLC',
    name: 'TRU INDEPENDENCE ASSET MANAGEMENT 2, LLC',
    label: 'TRU INDEPENDENCE ASSET MANAGEMENT 2, LLC',
  },
  {
    cik: '0001785445',
    value: '0001785445,TRU INDEPENDENCE LLC',
    name: 'TRU INDEPENDENCE LLC',
    label: 'TRU INDEPENDENCE LLC',
  },
  {
    cik: '0000092230',
    value: '0000092230,TRUIST FINANCIAL CORP',
    name: 'TRUIST FINANCIAL CORP',
    label: 'TRUIST FINANCIAL CORP',
  },
  {
    cik: '0001608376',
    value: '0001608376,TRUST ASSET MANAGEMENT LLC',
    name: 'TRUST ASSET MANAGEMENT LLC',
    label: 'TRUST ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001428242',
    value: '0001428,TRUST CO2',
    name: 'TRUST CO',
    label: 'TRUST CO',
  },
  {
    cik: '0001632554',
    value: '0001632,TRUST CO4',
    name: 'TRUST CO',
    label: 'TRUST CO',
  },
  {
    cik: '0000357301',
    value: '0000357301,TRUSTCO BANK CORP N Y',
    name: 'TRUSTCO BANK CORP N Y',
    label: 'TRUSTCO BANK CORP N Y',
  },
  {
    cik: '0001803988',
    value: '0001803988,TRUST CO OF KANSAS',
    name: 'TRUST CO OF KANSAS',
    label: 'TRUST CO OF KANSAS',
  },
  {
    cik: '0001083323',
    value: '0001083323,TRUST CO OF OKLAHOMA',
    name: 'TRUST CO OF OKLAHOMA',
    label: 'TRUST CO OF OKLAHOMA',
  },
  {
    cik: '0001425963',
    value: '0001425963,TRUST CO OF THE OZARKS',
    name: 'TRUST CO OF THE OZARKS',
    label: 'TRUST CO OF THE OZARKS',
  },
  {
    cik: '0001008937',
    value: '0001008937,TRUST CO OF TOLEDO NA /OH/',
    name: 'TRUST CO OF TOLEDO NA /OH/',
    label: 'TRUST CO OF TOLEDO NA /OH/',
  },
  {
    cik: '0001134008',
    value: '0001134008,TRUST CO OF VERMONT',
    name: 'TRUST CO OF VERMONT',
    label: 'TRUST CO OF VERMONT',
  },
  {
    cik: '0001135439',
    value: '0001135439,TRUST CO OF VIRGINIA /VA',
    name: 'TRUST CO OF VIRGINIA /VA',
    label: 'TRUST CO OF VIRGINIA /VA',
  },
  {
    cik: '0001730322',
    value: '0001730322,TRUSTCORE FINANCIAL SERVICES, LLC',
    name: 'TRUSTCORE FINANCIAL SERVICES, LLC',
    label: 'TRUSTCORE FINANCIAL SERVICES, LLC',
  },
  {
    cik: '0001082849',
    value: '0001082849,TRUST DEPARTMENT MB FINANCIAL BANK N A',
    name: 'TRUST DEPARTMENT MB FINANCIAL BANK N A',
    label: 'TRUST DEPARTMENT MB FINANCIAL BANK N A',
  },
  {
    cik: '0001420995',
    value: '0001420995,TRUSTEES OF COLUMBIA UNIVERSITY IN THE CITY OF NEW YORK',
    name: 'TRUSTEES OF COLUMBIA UNIVERSITY IN THE CITY OF NEW YORK',
    label: 'TRUSTEES OF COLUMBIA UNIVERSITY IN THE CITY OF NEW YORK',
  },
  {
    cik: '0001599404',
    value: '0001599404,TRUSTEES OF DARTMOUTH COLLEGE',
    name: 'TRUSTEES OF DARTMOUTH COLLEGE',
    label: 'TRUSTEES OF DARTMOUTH COLLEGE',
  },
  {
    cik: '0000902967',
    value: '0000902967,TRUSTEES OF GENERAL ELECTRIC PENSION TRUST',
    name: 'TRUSTEES OF GENERAL ELECTRIC PENSION TRUST',
    label: 'TRUSTEES OF GENERAL ELECTRIC PENSION TRUST',
  },
  {
    cik: '0001728827',
    value: '0001728827,TRUSTEES OF PRINCETON UNIVERSITY',
    name: 'TRUSTEES OF PRINCETON UNIVERSITY',
    label: 'TRUSTEES OF PRINCETON UNIVERSITY',
  },
  {
    cik: '0001904607',
    value: '0001904607,TRUSTEES OF THE SMITH COLLEGE',
    name: 'TRUSTEES OF THE SMITH COLLEGE',
    label: 'TRUSTEES OF THE SMITH COLLEGE',
  },
  {
    cik: '0000351083',
    value: '0000351083,TRUSTEES OF THE UNIVERSITY OF PENNSYLVANIA',
    name: 'TRUSTEES OF THE UNIVERSITY OF PENNSYLVANIA',
    label: 'TRUSTEES OF THE UNIVERSITY OF PENNSYLVANIA',
  },
  {
    cik: '0001141800',
    value: '0001141800,TRUST & FIDUCIARY MANAGEMENT SERVICES INC',
    name: 'TRUST & FIDUCIARY MANAGEMENT SERVICES INC',
    label: 'TRUST & FIDUCIARY MANAGEMENT SERVICES INC',
  },
  {
    cik: '0001597690',
    value: '0001597690,TRUST INVESTMENT ADVISORS',
    name: 'TRUST INVESTMENT ADVISORS',
    label: 'TRUST INVESTMENT ADVISORS',
  },
  {
    cik: '0000861176',
    value: '0000861176,TRUSTMARK NATIONAL BANK TRUST DEPARTMENT',
    name: 'TRUSTMARK NATIONAL BANK TRUST DEPARTMENT',
    label: 'TRUSTMARK NATIONAL BANK TRUST DEPARTMENT',
  },
  {
    cik: '0001569067',
    value: '0001569067,TRUTINA FINANCIAL LLC',
    name: 'TRUTINA FINANCIAL LLC',
    label: 'TRUTINA FINANCIAL LLC',
  },
  {
    cik: '0001750405',
    value: '0001750405,TRUVESTMENTS CAPITAL LLC',
    name: 'TRUVESTMENTS CAPITAL LLC',
    label: 'TRUVESTMENTS CAPITAL LLC',
  },
  {
    cik: '0001660071',
    value: '0001660071,TRUVVO PARTNERS LLC',
    name: 'TRUVVO PARTNERS LLC',
    label: 'TRUVVO PARTNERS LLC',
  },
  {
    cik: '0001834985',
    value: '0001834985,TRUWEALTH ADVISORS, LLC',
    name: 'TRUWEALTH ADVISORS, LLC',
    label: 'TRUWEALTH ADVISORS, LLC',
  },
  {
    cik: '0001721168',
    value: '0001721168,TRUXT INVESTMENTOS LTDA.',
    name: 'TRUXT INVESTMENTOS LTDA.',
    label: 'TRUXT INVESTMENTOS LTDA.',
  },
  {
    cik: '0001637299',
    value: '0001637299,TRV GP III, LLC',
    name: 'TRV GP III, LLC',
    label: 'TRV GP III, LLC',
  },
  {
    cik: '0001614073',
    value: '0001614073,TRV GP II, LLC',
    name: 'TRV GP II, LLC',
    label: 'TRV GP II, LLC',
  },
  {
    cik: '0001688325',
    value: '0001688325,TRV GP IV, LLC',
    name: 'TRV GP IV, LLC',
    label: 'TRV GP IV, LLC',
  },
  {
    cik: '0001578973',
    value: '0001578973,TRV GP, LLC',
    name: 'TRV GP, LLC',
    label: 'TRV GP, LLC',
  },
  {
    cik: '0001807902',
    value: '0001807902,TRYBE CAPITAL MANAGEMENT LP',
    name: 'TRYBE CAPITAL MANAGEMENT LP',
    label: 'TRYBE CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001574850',
    value: '0001574850,TSAI CAPITAL CORP',
    name: 'TSAI CAPITAL CORP',
    label: 'TSAI CAPITAL CORP',
  },
  {
    cik: '0001800513',
    value: '00018005,TSFG, LLC',
    name: 'TSFG, LLC',
    label: 'TSFG, LLC',
  },
  {
    cik: '0001649761',
    value: '0001649761,TSG6 MANAGEMENT L.L.C.',
    name: 'TSG6 MANAGEMENT L.L.C.',
    label: 'TSG6 MANAGEMENT L.L.C.',
  },
  {
    cik: '0001910156',
    value: '0001910156,TSG CONSUMER PARTNERS LP',
    name: 'TSG CONSUMER PARTNERS LP',
    label: 'TSG CONSUMER PARTNERS LP',
  },
  {
    cik: '0001317208',
    value: '0001317208,TSP CAPITAL MANAGEMENT GROUP, LLC',
    name: 'TSP CAPITAL MANAGEMENT GROUP, LLC',
    label: 'TSP CAPITAL MANAGEMENT GROUP, LLC',
  },
  {
    cik: '0001483532',
    value: '0001483532,TT INTERNATIONAL',
    name: 'TT INTERNATIONAL',
    label: 'TT INTERNATIONAL',
  },
  {
    cik: '0001809187',
    value: '0001809187,TT INTERNATIONAL ASSET MANAGEMENT LTD',
    name: 'TT INTERNATIONAL ASSET MANAGEMENT LTD',
    label: 'TT INTERNATIONAL ASSET MANAGEMENT LTD',
  },
  {
    cik: '0001682576',
    value: '0001682576,TTP INVESTMENTS, INC.',
    name: 'TTP INVESTMENTS, INC.',
    label: 'TTP INVESTMENTS, INC.',
  },
  {
    cik: '0000923093',
    value: '0000923093,TUDOR INVESTMENT CORP ET AL',
    name: 'TUDOR INVESTMENT CORP ET AL',
    label: 'TUDOR INVESTMENT CORP ET AL',
  },
  {
    cik: '0001055963',
    value: '0001055963,TUFTON CAPITAL MANAGEMENT',
    name: 'TUFTON CAPITAL MANAGEMENT',
    label: 'TUFTON CAPITAL MANAGEMENT',
  },
  {
    cik: '0000778925',
    value: '0000778925,TUKMAN GROSSMAN CAPITAL MANAGEMENT INC',
    name: 'TUKMAN GROSSMAN CAPITAL MANAGEMENT INC',
    label: 'TUKMAN GROSSMAN CAPITAL MANAGEMENT INC',
  },
  {
    cik: '0001652174',
    value: '0001652174,TURIM 21 INVESTIMENTOS LTDA.',
    name: 'TURIM 21 INVESTIMENTOS LTDA.',
    label: 'TURIM 21 INVESTIMENTOS LTDA.',
  },
  {
    cik: '0001554179',
    value: '0001554179,TURIYA ADVISORS ASIA LTD',
    name: 'TURIYA ADVISORS ASIA LTD',
    label: 'TURIYA ADVISORS ASIA LTD',
  },
  {
    cik: '0000877035',
    value: '0000877035,TURNER INVESTMENTS LLC',
    name: 'TURNER INVESTMENTS LLC',
    label: 'TURNER INVESTMENTS LLC',
  },
  {
    cik: '0001484148',
    value: '0001484148,TURTLE CREEK ASSET MANAGEMENT INC.',
    name: 'TURTLE CREEK ASSET MANAGEMENT INC.',
    label: 'TURTLE CREEK ASSET MANAGEMENT INC.',
  },
  {
    cik: '0001730844',
    value: '0001730844,TUSCAN RIDGE ASSET MANAGEMENT LLC',
    name: 'TUSCAN RIDGE ASSET MANAGEMENT LLC',
    label: 'TUSCAN RIDGE ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001581641',
    value: '0001581641,TUTTLE CAPITAL MANAGEMENT, LLC',
    name: 'TUTTLE CAPITAL MANAGEMENT, LLC',
    label: 'TUTTLE CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001735925',
    value: '0001735925,T VENTURES MANAGEMENT CO, LTD.',
    name: 'T VENTURES MANAGEMENT CO, LTD.',
    label: 'T VENTURES MANAGEMENT CO, LTD.',
  },
  {
    cik: '0001290448',
    value: '0001290448,TVL MANAGEMENT CORP',
    name: 'TVL MANAGEMENT CORP',
    label: 'TVL MANAGEMENT CORP',
  },
  {
    cik: '0001669690',
    value: '0001669690,TVR CAPITAL MANAGEMENT LP',
    name: 'TVR CAPITAL MANAGEMENT LP',
    label: 'TVR CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0000732905',
    value: '0000732905,TWEEDY BROWNE CO LLC//',
    name: 'TWEEDY BROWNE CO LLC//',
    label: 'TWEEDY BROWNE CO LLC//',
  },
  {
    cik: '0000732905',
    value: '0000732905,TWEEDY, BROWNE CO LLC',
    name: 'TWEEDY, BROWNE CO LLC',
    label: 'TWEEDY, BROWNE CO LLC',
  },
  {
    cik: '0001411268',
    value: '0001411268,TWELE CAPITAL MANAGEMENT, INC.',
    name: 'TWELE CAPITAL MANAGEMENT, INC.',
    label: 'TWELE CAPITAL MANAGEMENT, INC.',
  },
  {
    cik: '0001877090',
    value: '0001877090,TWELVE POINTS WEALTH MANAGEMENT LLC',
    name: 'TWELVE POINTS WEALTH MANAGEMENT LLC',
    label: 'TWELVE POINTS WEALTH MANAGEMENT LLC',
  },
  {
    cik: '0001928941',
    value: '0001928941,TWENTY ACRE CAPITAL LP',
    name: 'TWENTY ACRE CAPITAL LP',
    label: 'TWENTY ACRE CAPITAL LP',
  },
  {
    cik: '0001769646',
    value: '0001769646,TWINBEECH CAPITAL LP',
    name: 'TWINBEECH CAPITAL LP',
    label: 'TWINBEECH CAPITAL LP',
  },
  {
    cik: '0001059187',
    value: '0001059187,TWIN CAPITAL MANAGEMENT INC',
    name: 'TWIN CAPITAL MANAGEMENT INC',
    label: 'TWIN CAPITAL MANAGEMENT INC',
  },
  {
    cik: '0001387761',
    value: '0001387761,TWIN FOCUS CAPITAL PARTNERS, LLC',
    name: 'TWIN FOCUS CAPITAL PARTNERS, LLC',
    label: 'TWIN FOCUS CAPITAL PARTNERS, LLC',
  },
  {
    cik: '0001847794',
    value: '0001847794,TWIN LAKES CAPITAL MANAGEMENT, LLC',
    name: 'TWIN LAKES CAPITAL MANAGEMENT, LLC',
    label: 'TWIN LAKES CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001351351',
    value: '0001351351,TWIN SECURITIES, INC.',
    name: 'TWIN SECURITIES, INC.',
    label: 'TWIN SECURITIES, INC.',
  },
  {
    cik: '0001535588',
    value: '0001535588,TWIN TREE MANAGEMENT, LP',
    name: 'TWIN TREE MANAGEMENT, LP',
    label: 'TWIN TREE MANAGEMENT, LP',
  },
  {
    cik: '0001606430',
    value: '0001606430,TWO CREEKS CAPITAL MANAGEMENT, LP',
    name: 'TWO CREEKS CAPITAL MANAGEMENT, LP',
    label: 'TWO CREEKS CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0001962382',
    value: '0001962382,TWO POINT CAPITAL MANAGEMENT, INC.',
    name: 'TWO POINT CAPITAL MANAGEMENT, INC.',
    label: 'TWO POINT CAPITAL MANAGEMENT, INC.',
  },
  {
    cik: '0001823138',
    value: '0001823138,TWO SEAS CAPITAL LP',
    name: 'TWO SEAS CAPITAL LP',
    label: 'TWO SEAS CAPITAL LP',
  },
  {
    cik: '0001478735',
    value: '0001478735,TWO SIGMA ADVISERS, LLC',
    name: 'TWO SIGMA ADVISERS, LLC',
    label: 'TWO SIGMA ADVISERS, LLC',
  },
  {
    cik: '0001478735',
    value: '0001478735,TWO SIGMA ADVISERS, LP',
    name: 'TWO SIGMA ADVISERS, LP',
    label: 'TWO SIGMA ADVISERS, LP',
  },
  {
    cik: '0001179392',
    value: '0001179392,TWO SIGMA INVESTMENTS LLC',
    name: 'TWO SIGMA INVESTMENTS LLC',
    label: 'TWO SIGMA INVESTMENTS LLC',
  },
  {
    cik: '0001179392',
    value: '0001179392,TWO SIGMA INVESTMENTS, LP',
    name: 'TWO SIGMA INVESTMENTS, LP',
    label: 'TWO SIGMA INVESTMENTS, LP',
  },
  {
    cik: '0001450144',
    value: '0001450144,TWO SIGMA SECURITIES, LLC',
    name: 'TWO SIGMA SECURITIES, LLC',
    label: 'TWO SIGMA SECURITIES, LLC',
  },
  {
    cik: '0001878979',
    value: '0001878979,TWO SIGMA VENTURES, LP',
    name: 'TWO SIGMA VENTURES, LP',
    label: 'TWO SIGMA VENTURES, LP',
  },
  {
    cik: '0001553936',
    value: '0001553936,TYBOURNE CAPITAL MANAGEMENT (HK) LTD',
    name: 'TYBOURNE CAPITAL MANAGEMENT (HK) LTD',
    label: 'TYBOURNE CAPITAL MANAGEMENT (HK) LTD',
  },
  {
    cik: '0001694909',
    value: '0001694909,TYERS ASSET MANAGEMENT LLC',
    name: 'TYERS ASSET MANAGEMENT LLC',
    label: 'TYERS ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001308001',
    value: '0001308001,TYGH CAPITAL MANAGEMENT, INC.',
    name: 'TYGH CAPITAL MANAGEMENT, INC.',
    label: 'TYGH CAPITAL MANAGEMENT, INC.',
  },
  {
    cik: '0001912584',
    value: '0001912584,TYLER-STONE WEALTH MANAGEMENT',
    name: 'TYLER-STONE WEALTH MANAGEMENT',
    label: 'TYLER-STONE WEALTH MANAGEMENT',
  },
  {
    cik: '0001219314',
    value: '0001219314,TYNDALL CAPITAL PARTNERS L P',
    name: 'TYNDALL CAPITAL PARTNERS L P',
    label: 'TYNDALL CAPITAL PARTNERS L P',
  },
  {
    cik: '0001592179',
    value: '0001592179,TYRIAN INVESTMENTS, L.P.',
    name: 'TYRIAN INVESTMENTS, L.P.',
    label: 'TYRIAN INVESTMENTS, L.P.',
  },
  {
    cik: '0001543065',
    value: '0001543065,TYRUS CAPITAL S.A.M.',
    name: 'TYRUS CAPITAL S.A.M.',
    label: 'TYRUS CAPITAL S.A.M.',
  },
  {
    cik: '0001598558',
    value: '0001598558,TYVOR CAPITAL LLC',
    name: 'TYVOR CAPITAL LLC',
    label: 'TYVOR CAPITAL LLC',
  },
  {
    cik: '0001727588',
    value: '0001727588,UBP INVESTMENT ADVISORS SA',
    name: 'UBP INVESTMENT ADVISORS SA',
    label: 'UBP INVESTMENT ADVISORS SA',
  },
  {
    cik: '0001114446',
    value: '00011,UBS AG',
    name: 'UBS AG',
    label: 'UBS AG',
  },
  {
    cik: '0000861177',
    value: '0000861177,UBS ASSET MANAGEMENT AMERICAS INC',
    name: 'UBS ASSET MANAGEMENT AMERICAS INC',
    label: 'UBS ASSET MANAGEMENT AMERICAS INC',
  },
  {
    cik: '0001610520',
    value: '0001610520,UBS GROUP AG',
    name: 'UBS GROUP AG',
    label: 'UBS GROUP AG',
  },
  {
    cik: '0001132716',
    value: '0001132716,UBS OCONNOR LLC',
    name: 'UBS OCONNOR LLC',
    label: 'UBS OCONNOR LLC',
  },
  {
    cik: '0001907240',
    value: '0001907240,UDINE WEALTH MANAGEMENT, INC.',
    name: 'UDINE WEALTH MANAGEMENT, INC.',
    label: 'UDINE WEALTH MANAGEMENT, INC.',
  },
  {
    cik: '0001697814',
    value: '0001697814,UG INVESTMENT ADVISERS LTD.',
    name: 'UG INVESTMENT ADVISERS LTD.',
    label: 'UG INVESTMENT ADVISERS LTD.',
  },
  {
    cik: '0001911497',
    value: '0001911497,ULLAND INVESTMENT ADVISORS, LLC',
    name: 'ULLAND INVESTMENT ADVISORS, LLC',
    label: 'ULLAND INVESTMENT ADVISORS, LLC',
  },
  {
    cik: '0001697725',
    value: '0001697725,ULLMANN FINANCIAL GROUP, INC.',
    name: 'ULLMANN FINANCIAL GROUP, INC.',
    label: 'ULLMANN FINANCIAL GROUP, INC.',
  },
  {
    cik: '0001697725',
    value: '0001697725,ULLMANN WEALTH PARTNERS GROUP, LLC',
    name: 'ULLMANN WEALTH PARTNERS GROUP, LLC',
    label: 'ULLMANN WEALTH PARTNERS GROUP, LLC',
  },
  {
    cik: '0001878849',
    value: '0001878849,ULRICH CONSULTANTS & INVESTMENT GROUP, LLC',
    name: 'ULRICH CONSULTANTS & INVESTMENT GROUP, LLC',
    label: 'ULRICH CONSULTANTS & INVESTMENT GROUP, LLC',
  },
  {
    cik: '0001340098',
    value: '0001340098,ULYS, L.L.C.',
    name: 'ULYS, L.L.C.',
    label: 'ULYS, L.L.C.',
  },
  {
    cik: '0001510912',
    value: '0001510912,ULYSSES MANAGEMENT LLC',
    name: 'ULYSSES MANAGEMENT LLC',
    label: 'ULYSSES MANAGEMENT LLC',
  },
  {
    cik: '0001795173',
    value: '0001795173,UMA FINANCIAL SERVICES, INC.',
    name: 'UMA FINANCIAL SERVICES, INC.',
    label: 'UMA FINANCIAL SERVICES, INC.',
  },
  {
    cik: '0000933429',
    value: '0000933429,UMB BANK, N.A.',
    name: 'UMB BANK, N.A.',
    label: 'UMB BANK, N.A.',
  },
  {
    cik: '0000933429',
    value: '0000933429,UMB BANK N A/MO',
    name: 'UMB BANK N A/MO',
    label: 'UMB BANK N A/MO',
  },
  {
    cik: '0001911286',
    value: '0001911286,UNC MANAGEMENT COMPANY, INC.',
    name: 'UNC MANAGEMENT COMPANY, INC.',
    label: 'UNC MANAGEMENT COMPANY, INC.',
  },
  {
    cik: '0001729754',
    value: '0001729754,UNCOMMON CENTS INVESTING LLC',
    name: 'UNCOMMON CENTS INVESTING LLC',
    label: 'UNCOMMON CENTS INVESTING LLC',
  },
  {
    cik: '0001910387',
    value: '0001910387,UNCONVENTIONAL INVESTOR, LLC',
    name: 'UNCONVENTIONAL INVESTOR, LLC',
    label: 'UNCONVENTIONAL INVESTOR, LLC',
  },
  {
    cik: '0001512545',
    value: '0001512545,UNDERHILL INVESTMENT MANAGEMENT LLC',
    name: 'UNDERHILL INVESTMENT MANAGEMENT LLC',
    label: 'UNDERHILL INVESTMENT MANAGEMENT LLC',
  },
  {
    cik: '0001765893',
    value: '0001765893,UNIFIED TRUST COMPANY, N.A.',
    name: 'UNIFIED TRUST COMPANY, N.A.',
    label: 'UNIFIED TRUST COMPANY, N.A.',
  },
  {
    cik: '0001745879',
    value: '0001745879,UNIGESTION HOLDING SA',
    name: 'UNIGESTION HOLDING SA',
    label: 'UNIGESTION HOLDING SA',
  },
  {
    cik: '0001730565',
    value: '0001730565,UNIO CAPITAL LLC',
    name: 'UNIO CAPITAL LLC',
    label: 'UNIO CAPITAL LLC',
  },
  {
    cik: '0001775889',
    value: '0001775889,UNION BANCAIRE PRIVEE ASSET MANAGEMENT LLC',
    name: 'UNION BANCAIRE PRIVEE ASSET MANAGEMENT LLC',
    label: 'UNION BANCAIRE PRIVEE ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001011659',
    value: '0001011659,UNIONBANCAL CORP',
    name: 'UNIONBANCAL CORP',
    label: 'UNIONBANCAL CORP',
  },
  {
    cik: '0000883948',
    value: '0000883948,UNION BANKSHARES CORP',
    name: 'UNION BANKSHARES CORP',
    label: 'UNION BANKSHARES CORP',
  },
  {
    cik: '0001862428',
    value: '0001862428,UNION HERITAGE CAPITAL, LLC',
    name: 'UNION HERITAGE CAPITAL, LLC',
    label: 'UNION HERITAGE CAPITAL, LLC',
  },
  {
    cik: '0001885902',
    value: '0001885902,UNION INVESTMENTS & DEVELOPMENT LTD.',
    name: 'UNION INVESTMENTS & DEVELOPMENT LTD.',
    label: 'UNION INVESTMENTS & DEVELOPMENT LTD.',
  },
  {
    cik: '0001591799',
    value: '0001591799,UNION POINT ADVISORS, LLC',
    name: 'UNION POINT ADVISORS, LLC',
    label: 'UNION POINT ADVISORS, LLC',
  },
  {
    cik: '0001640023',
    value: '0001640023,UNION SQUARE GP 2004, L.L.C.',
    name: 'UNION SQUARE GP 2004, L.L.C.',
    label: 'UNION SQUARE GP 2004, L.L.C.',
  },
  {
    cik: '0001677323',
    value: '0001677323,UNION SQUARE GP 2008, L.L.C.',
    name: 'UNION SQUARE GP 2008, L.L.C.',
    label: 'UNION SQUARE GP 2008, L.L.C.',
  },
  {
    cik: '0001633002',
    value: '0001633002,UNION SQUARE OPPORTUNITY GP, L.L.C.',
    name: 'UNION SQUARE OPPORTUNITY GP, L.L.C.',
    label: 'UNION SQUARE OPPORTUNITY GP, L.L.C.',
  },
  {
    cik: '0001666231',
    value: '0001666231,UNION SQUARE PARK CAPITAL MANAGEMENT, LLC',
    name: 'UNION SQUARE PARK CAPITAL MANAGEMENT, LLC',
    label: 'UNION SQUARE PARK CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001908167',
    value: '0001908167,UNIONVIEW, LLC',
    name: 'UNIONVIEW, LLC',
    label: 'UNIONVIEW, LLC',
  },
  {
    cik: '0001520710',
    value: '0001520710,UNIPLAN INVESTMENT COUNSEL, INC.',
    name: 'UNIPLAN INVESTMENT COUNSEL, INC.',
    label: 'UNIPLAN INVESTMENT COUNSEL, INC.',
  },
  {
    cik: '0001695818',
    value: '0001695818,UNISON ADVISORS LLC',
    name: 'UNISON ADVISORS LLC',
    label: 'UNISON ADVISORS LLC',
  },
  {
    cik: '0001840755',
    value: '0001840755,UNISON ASSET MANAGEMENT LLC',
    name: 'UNISON ASSET MANAGEMENT LLC',
    label: 'UNISON ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001349353',
    value:
      '0001349353,UNITED AMERICAN SECURITIES INC. (D/B/A UAS ASSET MANAGEMENT)',
    name: 'UNITED AMERICAN SECURITIES INC. (D/B/A UAS ASSET MANAGEMENT)',
    label: 'UNITED AMERICAN SECURITIES INC. (D/B/A UAS ASSET MANAGEMENT)',
  },
  {
    cik: '0001539948',
    value: '0001539948,UNITED ASSET STRATEGIES, INC.',
    name: 'UNITED ASSET STRATEGIES, INC.',
    label: 'UNITED ASSET STRATEGIES, INC.',
  },
  {
    cik: '0001286478',
    value: '0001286478,UNITED BANK',
    name: 'UNITED BANK',
    label: 'UNITED BANK',
  },
  {
    cik: '0001697791',
    value: '0001697791,UNITED BANK',
    name: 'UNITED BANK',
    label: 'UNITED BANK',
  },
  {
    cik: '0001044330',
    value: '0001044330,UNITED BANK, INC.',
    name: 'UNITED BANK, INC.',
    label: 'UNITED BANK, INC.',
  },
  {
    cik: '0001286478',
    value: '0001286478,UNITED BANK /VA/',
    name: 'UNITED BANK /VA/',
    label: 'UNITED BANK /VA/',
  },
  {
    cik: '0001463217',
    value: '0001463217,UNITED CAPITAL FINANCIAL ADVISERS, LLC',
    name: 'UNITED CAPITAL FINANCIAL ADVISERS, LLC',
    label: 'UNITED CAPITAL FINANCIAL ADVISERS, LLC',
  },
  {
    cik: '0001753219',
    value: '0001753219,UNITED CAPITAL MANAGEMENT OF KS, INC.',
    name: 'UNITED CAPITAL MANAGEMENT OF KS, INC.',
    label: 'UNITED CAPITAL MANAGEMENT OF KS, INC.',
  },
  {
    cik: '0000101199',
    value: '0000101199,UNITED FIRE GROUP INC',
    name: 'UNITED FIRE GROUP INC',
    label: 'UNITED FIRE GROUP INC',
  },
  {
    cik: '0001679524',
    value: '0001679524,UNITED INCOME, INC.',
    name: 'UNITED INCOME, INC.',
    label: 'UNITED INCOME, INC.',
  },
  {
    cik: '0001669362',
    value: '0001669362,UNITED MARITIME CAPITAL LLC',
    name: 'UNITED MARITIME CAPITAL LLC',
    label: 'UNITED MARITIME CAPITAL LLC',
  },
  {
    cik: '0001021249',
    value: '0001021249,UNITED SERVICES AUTOMOBILE ASSOCIATION',
    name: 'UNITED SERVICES AUTOMOBILE ASSOCIATION',
    label: 'UNITED SERVICES AUTOMOBILE ASSOCIATION',
  },
  {
    cik: '0001053136',
    value: '0001053136,UNITED STATES STEEL & CARNEGIE PENSION FUND',
    name: 'UNITED STATES STEEL & CARNEGIE PENSION FUND',
    label: 'UNITED STATES STEEL & CARNEGIE PENSION FUND',
  },
  {
    cik: '0001730896',
    value:
      '0001730896,UNITED SUPER PTY LTD IN ITS CAPACITY AS TRUSTEE FOR THE CONSTRUCTION & BUILDING UNIONS SUPERANNUATION FUND',
    name: 'UNITED SUPER PTY LTD IN ITS CAPACITY AS TRUSTEE FOR THE CONSTRUCTION & BUILDING UNIONS SUPERANNUATION FUND',
    label:
      'UNITED SUPER PTY LTD IN ITS CAPACITY AS TRUSTEE FOR THE CONSTRUCTION & BUILDING UNIONS SUPERANNUATION FUND',
  },
  {
    cik: '0000314957',
    value: '0000314957,UNIVERSITY OF CHICAGO',
    name: 'UNIVERSITY OF CHICAGO',
    label: 'UNIVERSITY OF CHICAGO',
  },
  {
    cik: '0001624050',
    value: '0001624050,UNIVERSITY OF MARYLAND FOUNDATION, INC.',
    name: 'UNIVERSITY OF MARYLAND FOUNDATION, INC.',
    label: 'UNIVERSITY OF MARYLAND FOUNDATION, INC.',
  },
  {
    cik: '0001802874',
    value: '0001802874,UNIVERSITY OF MASSACHUSETTS FOUNDATION, INC.',
    name: 'UNIVERSITY OF MASSACHUSETTS FOUNDATION, INC.',
    label: 'UNIVERSITY OF MASSACHUSETTS FOUNDATION, INC.',
  },
  {
    cik: '0000903514',
    value: '0000903514,UNIVERSITY OF NOTRE DAME DU LAC',
    name: 'UNIVERSITY OF NOTRE DAME DU LAC',
    label: 'UNIVERSITY OF NOTRE DAME DU LAC',
  },
  {
    cik: '0001739440',
    value: '0001739440,UNIVERSITY OF TENNESSEE',
    name: 'UNIVERSITY OF TENNESSEE',
    label: 'UNIVERSITY OF TENNESSEE',
  },
  {
    cik: '0001019231',
    value: '0001019231,UNIVERSITY OF TEXAS INVESTMENT MANAGMENT CO',
    name: 'UNIVERSITY OF TEXAS INVESTMENT MANAGMENT CO',
    label: 'UNIVERSITY OF TEXAS INVESTMENT MANAGMENT CO',
  },
  {
    cik: '0001019231',
    value: '0001019231,UNIVERSITY OF TEXAS/TEXAS AM INVESTMENT MANAGMENT CO',
    name: 'UNIVERSITY OF TEXAS/TEXAS AM INVESTMENT MANAGMENT CO',
    label: 'UNIVERSITY OF TEXAS/TEXAS AM INVESTMENT MANAGMENT CO',
  },
  {
    cik: '0000102212',
    value: '0000102212,UNIVEST CORP OF PENNSYLVANIA',
    name: 'UNIVEST CORP OF PENNSYLVANIA',
    label: 'UNIVEST CORP OF PENNSYLVANIA',
  },
  {
    cik: '0000102212',
    value: '0000102212,UNIVEST FINANCIAL CORP',
    name: 'UNIVEST FINANCIAL CORP',
    label: 'UNIVEST FINANCIAL CORP',
  },
  {
    cik: '0001662237',
    value: '0001662237,UNTERBERG CAPITAL LLC',
    name: 'UNTERBERG CAPITAL LLC',
    label: 'UNTERBERG CAPITAL LLC',
  },
  {
    cik: '0001808696',
    value: '0001808696,UNTITLED INVESTMENTS LP',
    name: 'UNTITLED INVESTMENTS LP',
    label: 'UNTITLED INVESTMENTS LP',
  },
  {
    cik: '0001852894',
    value: '0001852894,UPFRONT VENTURES MANAGEMENT, LLC',
    name: 'UPFRONT VENTURES MANAGEMENT, LLC',
    label: 'UPFRONT VENTURES MANAGEMENT, LLC',
  },
  {
    cik: '0001911228',
    value: '0001911228,UPPER LEFT WEALTH MANAGEMENT, LLC',
    name: 'UPPER LEFT WEALTH MANAGEMENT, LLC',
    label: 'UPPER LEFT WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001845521',
    value: '0001845521,URBAN WEALTH MANAGEMENT, LLC',
    name: 'URBAN WEALTH MANAGEMENT, LLC',
    label: 'URBAN WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001693838',
    value: '0001693838,URSA FUND MANAGEMENT, LLC',
    name: 'URSA FUND MANAGEMENT, LLC',
    label: 'URSA FUND MANAGEMENT, LLC',
  },
  {
    cik: '0001735605',
    value: '0001735605,USADVISORS WEALTH MANAGEMENT, LLC',
    name: 'USADVISORS WEALTH MANAGEMENT, LLC',
    label: 'USADVISORS WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001633716',
    value: '0001633716,USA FINANCIAL PORTFORMULAS CORP',
    name: 'USA FINANCIAL PORTFORMULAS CORP',
    label: 'USA FINANCIAL PORTFORMULAS CORP',
  },
  {
    cik: '0001425419',
    value: '0001425419,USA MUTUALS ADVISORS, INC.',
    name: 'USA MUTUALS ADVISORS, INC.',
    label: 'USA MUTUALS ADVISORS, INC.',
  },
  {
    cik: '0001882673',
    value: '0001882673,US ASSET MANAGEMENT LLC',
    name: 'US ASSET MANAGEMENT LLC',
    label: 'US ASSET MANAGEMENT LLC',
  },
  {
    cik: '0000036104',
    value: '0000036104,US BANCORP \\DE\\',
    name: 'US BANCORP \\DE\\',
    label: 'US BANCORP \\DE\\',
  },
  {
    cik: '0001808915',
    value: '0001808915,U.S. CAPITAL WEALTH ADVISORS, LLC',
    name: 'U.S. CAPITAL WEALTH ADVISORS, LLC',
    label: 'U.S. CAPITAL WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001525212',
    value: '0001525212,USCA RIA LLC',
    name: 'USCA RIA LLC',
    label: 'USCA RIA LLC',
  },
  {
    cik: '0001640420',
    value: '0001640420,US FINANCIAL ADVISORS, LLC',
    name: 'US FINANCIAL ADVISORS, LLC',
    label: 'US FINANCIAL ADVISORS, LLC',
  },
  {
    cik: '0000101507',
    value: '0000101507,US GLOBAL INVESTORS FUNDS',
    name: 'US GLOBAL INVESTORS FUNDS',
    label: 'US GLOBAL INVESTORS FUNDS',
  },
  {
    cik: '0000754811',
    value: '0000754811,U S GLOBAL INVESTORS INC',
    name: 'U S GLOBAL INVESTORS INC',
    label: 'U S GLOBAL INVESTORS INC',
  },
  {
    cik: '0001587381',
    value: '0001587381,USS INVESTMENT MANAGEMENT LTD',
    name: 'USS INVESTMENT MANAGEMENT LTD',
    label: 'USS INVESTMENT MANAGEMENT LTD',
  },
  {
    cik: '0001548392',
    value: '0001548392,UTAH RETIREMENT SYSTEMS',
    name: 'UTAH RETIREMENT SYSTEMS',
    label: 'UTAH RETIREMENT SYSTEMS',
  },
  {
    cik: '0001540780',
    value: '0001540780,V2 CAPITAL, LLC',
    name: 'V2 CAPITAL, LLC',
    label: 'V2 CAPITAL, LLC',
  },
  {
    cik: '0001524493',
    value: '0001524493,V3 CAPITAL MANAGEMENT, L.P.',
    name: 'V3 CAPITAL MANAGEMENT, L.P.',
    label: 'V3 CAPITAL MANAGEMENT, L.P.',
  },
  {
    cik: '0001900990',
    value: '0001900990,VAHANIAN & ASSOCIATES FINANCIAL PLANNING INC.',
    name: 'VAHANIAN & ASSOCIATES FINANCIAL PLANNING INC.',
    label: 'VAHANIAN & ASSOCIATES FINANCIAL PLANNING INC.',
  },
  {
    cik: '0001624723',
    value: '0001624723,VALENTINE CAPITAL ASSET MANAGEMENT, INC.',
    name: 'VALENTINE CAPITAL ASSET MANAGEMENT, INC.',
    label: 'VALENTINE CAPITAL ASSET MANAGEMENT, INC.',
  },
  {
    cik: '0001025948',
    value: '0001025948,VALENTINE VENTURES LLC',
    name: 'VALENTINE VENTURES LLC',
    label: 'VALENTINE VENTURES LLC',
  },
  {
    cik: '0001722641',
    value: '0001722641,VALEO FINANCIAL ADVISORS, LLC',
    name: 'VALEO FINANCIAL ADVISORS, LLC',
    label: 'VALEO FINANCIAL ADVISORS, LLC',
  },
  {
    cik: '0001452689',
    value: '0001452689,VALIANT CAPITAL MANAGEMENT, L.P.',
    name: 'VALIANT CAPITAL MANAGEMENT, L.P.',
    label: 'VALIANT CAPITAL MANAGEMENT, L.P.',
  },
  {
    cik: '0001080201',
    value: '0001080201,VALICENTI ADVISORY SERVICES INC',
    name: 'VALICENTI ADVISORY SERVICES INC',
    label: 'VALICENTI ADVISORY SERVICES INC',
  },
  {
    cik: '0001401388',
    value: '0001401388,VALINOR MANAGEMENT, L.P.',
    name: 'VALINOR MANAGEMENT, L.P.',
    label: 'VALINOR MANAGEMENT, L.P.',
  },
  {
    cik: '0001851418',
    value: '0001851418,VALLEY BROOK CAPITAL GROUP, INC.',
    name: 'VALLEY BROOK CAPITAL GROUP, INC.',
    label: 'VALLEY BROOK CAPITAL GROUP, INC.',
  },
  {
    cik: '0001128122',
    value: '0001128122,VALLEY FINANCIAL MANAGEMENT, INC.',
    name: 'VALLEY FINANCIAL MANAGEMENT, INC.',
    label: 'VALLEY FINANCIAL MANAGEMENT, INC.',
  },
  {
    cik: '0001697868',
    value: '0001697868,VALLEY FORGE ADVISORS, L.P.',
    name: 'VALLEY FORGE ADVISORS, L.P.',
    label: 'VALLEY FORGE ADVISORS, L.P.',
  },
  {
    cik: '0000894135',
    value: '0000894135,VALLEY FORGE ASSET MANAGEMENT CORP /PA/',
    name: 'VALLEY FORGE ASSET MANAGEMENT CORP /PA/',
    label: 'VALLEY FORGE ASSET MANAGEMENT CORP /PA/',
  },
  {
    cik: '0001697868',
    value: '0001697868,VALLEY FORGE CAPITAL MANAGEMENT, LP',
    name: 'VALLEY FORGE CAPITAL MANAGEMENT, LP',
    label: 'VALLEY FORGE CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0000920655',
    value: '0000920655,VALLEY FORGE INVESTMENT CONSULTANTS INC ADV',
    name: 'VALLEY FORGE INVESTMENT CONSULTANTS INC ADV',
    label: 'VALLEY FORGE INVESTMENT CONSULTANTS INC ADV',
  },
  {
    cik: '0000773411',
    value: '0000773411,VALLEY NATIONAL ADVISERS INC',
    name: 'VALLEY NATIONAL ADVISERS INC',
    label: 'VALLEY NATIONAL ADVISERS INC',
  },
  {
    cik: '0000714310',
    value: '0000714310,VALLEY NATIONAL BANCORP',
    name: 'VALLEY NATIONAL BANCORP',
    label: 'VALLEY NATIONAL BANCORP',
  },
  {
    cik: '0001846639',
    value: '0001846639,VALLIANCE ASSET MANAGEMENT LTD',
    name: 'VALLIANCE ASSET MANAGEMENT LTD',
    label: 'VALLIANCE ASSET MANAGEMENT LTD',
  },
  {
    cik: '0001380443',
    value: '0001380443,VALMARK ADVISERS, INC.',
    name: 'VALMARK ADVISERS, INC.',
    label: 'VALMARK ADVISERS, INC.',
  },
  {
    cik: '0001846237',
    value: '0001846237,VALOR MANAGEMENT LLC',
    name: 'VALOR MANAGEMENT LLC',
    label: 'VALOR MANAGEMENT LLC',
  },
  {
    cik: '0001761450',
    value: '0001761450,VALTINSON BRUNER FINANCIAL PLANNING LLC',
    name: 'VALTINSON BRUNER FINANCIAL PLANNING LLC',
    label: 'VALTINSON BRUNER FINANCIAL PLANNING LLC',
  },
  {
    cik: '0001604406',
    value: '0001604406,VALTURA CAPITAL PARTNERS LLC',
    name: 'VALTURA CAPITAL PARTNERS LLC',
    label: 'VALTURA CAPITAL PARTNERS LLC',
  },
  {
    cik: '0001418814',
    value: '0001418814,VALUEACT HOLDINGS, L.P.',
    name: 'VALUEACT HOLDINGS, L.P.',
    label: 'VALUEACT HOLDINGS, L.P.',
  },
  {
    cik: '0001140331',
    value: '0001140331,VALUE HOLDINGS LP',
    name: 'VALUE HOLDINGS LP',
    label: 'VALUE HOLDINGS LP',
  },
  {
    cik: '0001695658',
    value: '0001695658,VALUE HOLDINGS MANAGEMENT CO. LLC',
    name: 'VALUE HOLDINGS MANAGEMENT CO. LLC',
    label: 'VALUE HOLDINGS MANAGEMENT CO. LLC',
  },
  {
    cik: '0001697867',
    value: '0001697867,VALUEINVEST ASSET MANAGEMENT S.A.',
    name: 'VALUEINVEST ASSET MANAGEMENT S.A.',
    label: 'VALUEINVEST ASSET MANAGEMENT S.A.',
  },
  {
    cik: '0001686844',
    value: '0001686844,VALUE MONITORING, INC.',
    name: 'VALUE MONITORING, INC.',
    label: 'VALUE MONITORING, INC.',
  },
  {
    cik: '0001800916',
    value: '0001800916,VALUE PARTNER INVESTMENTS INC.',
    name: 'VALUE PARTNER INVESTMENTS INC.',
    label: 'VALUE PARTNER INVESTMENTS INC.',
  },
  {
    cik: '0001800916',
    value: '0001800916,VALUE PARTNERS INVESTMENTS INC.',
    name: 'VALUE PARTNERS INVESTMENTS INC.',
    label: 'VALUE PARTNERS INVESTMENTS INC.',
  },
  {
    cik: '0001005846',
    value: '0001005846,VALUE PARTNERS LTD /ADV',
    name: 'VALUE PARTNERS LTD /ADV',
    label: 'VALUE PARTNERS LTD /ADV',
  },
  {
    cik: '0001913608',
    value: '0001913608,VALUES FIRST ADVISORS, INC.',
    name: 'VALUES FIRST ADVISORS, INC.',
    label: 'VALUES FIRST ADVISORS, INC.',
  },
  {
    cik: '0001842246',
    value: '0001842246,VALUE STAR ASSET MANAGEMENT (HONG KONG) LTD',
    name: 'VALUE STAR ASSET MANAGEMENT (HONG KONG) LTD',
    label: 'VALUE STAR ASSET MANAGEMENT (HONG KONG) LTD',
  },
  {
    cik: '0001282189',
    value: '0001282189,VALUEWORKS LLC',
    name: 'VALUEWORKS LLC',
    label: 'VALUEWORKS LLC',
  },
  {
    cik: '0001524408',
    value: '0001524408,VAN BERKOM & ASSOCIATES INC.',
    name: 'VAN BERKOM & ASSOCIATES INC.',
    label: 'VAN BERKOM & ASSOCIATES INC.',
  },
  {
    cik: '0001910854',
    value: '0001910854,VANCE WEALTH, INC.',
    name: 'VANCE WEALTH, INC.',
    label: 'VANCE WEALTH, INC.',
  },
  {
    cik: '0001846311',
    value: '0001846311,VANCITY INVESTMENT MANAGEMENT LTD',
    name: 'VANCITY INVESTMENT MANAGEMENT LTD',
    label: 'VANCITY INVESTMENT MANAGEMENT LTD',
  },
  {
    cik: '0001482935',
    value: '0001482935,VAN CLEEF ASSET MANAGEMENT,INC',
    name: 'VAN CLEEF ASSET MANAGEMENT,INC',
    label: 'VAN CLEEF ASSET MANAGEMENT,INC',
  },
  {
    cik: '0001142062',
    value: '0001142062,VAN DEN BERG MANAGEMENT I, INC',
    name: 'VAN DEN BERG MANAGEMENT I, INC',
    label: 'VAN DEN BERG MANAGEMENT I, INC',
  },
  {
    cik: '0000869178',
    value: '0000869178,VAN ECK ASSOCIATES CORP',
    name: 'VAN ECK ASSOCIATES CORP',
    label: 'VAN ECK ASSOCIATES CORP',
  },
  {
    cik: '0001730578',
    value: '0001730578,VANGUARD CAPITAL WEALTH ADVISORS',
    name: 'VANGUARD CAPITAL WEALTH ADVISORS',
    label: 'VANGUARD CAPITAL WEALTH ADVISORS',
  },
  {
    cik: '0000102909',
    value: '0000102909,VANGUARD GROUP INC',
    name: 'VANGUARD GROUP INC',
    label: 'VANGUARD GROUP INC',
  },
  {
    cik: '0001767306',
    value: '0001767306,VANGUARD PERSONALIZED INDEXING MANAGEMENT, LLC',
    name: 'VANGUARD PERSONALIZED INDEXING MANAGEMENT, LLC',
    label: 'VANGUARD PERSONALIZED INDEXING MANAGEMENT, LLC',
  },
  {
    cik: '0001600145',
    value: '0001600145,VAN HULZEN ASSET MANAGEMENT, LLC',
    name: 'VAN HULZEN ASSET MANAGEMENT, LLC',
    label: 'VAN HULZEN ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001769031',
    value: '0001769031,VAN LEEUWEN & COMPANY, LLC',
    name: 'VAN LEEUWEN & COMPANY, LLC',
    label: 'VAN LEEUWEN & COMPANY, LLC',
  },
  {
    cik: '0001080173',
    value: '0001080173,VAN STRUM & TOWNE INC.',
    name: 'VAN STRUM & TOWNE INC.',
    label: 'VAN STRUM & TOWNE INC.',
  },
  {
    cik: '0001843547',
    value: '0001843547,VANTAGE CONSULTING GROUP INC',
    name: 'VANTAGE CONSULTING GROUP INC',
    label: 'VANTAGE CONSULTING GROUP INC',
  },
  {
    cik: '0001911766',
    value: '0001911766,VANTAGE FINANCIAL PARTNERS, LLC',
    name: 'VANTAGE FINANCIAL PARTNERS, LLC',
    label: 'VANTAGE FINANCIAL PARTNERS, LLC',
  },
  {
    cik: '0001693809',
    value: '0001693809,VANTAGE FINANCIAL PARTNERS LTD., INC.',
    name: 'VANTAGE FINANCIAL PARTNERS LTD., INC.',
    label: 'VANTAGE FINANCIAL PARTNERS LTD., INC.',
  },
  {
    cik: '0001599405',
    value: '0001599405,VANTAGE INVESTMENT ADVISORS, LLC',
    name: 'VANTAGE INVESTMENT ADVISORS, LLC',
    label: 'VANTAGE INVESTMENT ADVISORS, LLC',
  },
  {
    cik: '0001534561',
    value: '0001534561,VANTAGE INVESTMENT PARTNERS, LLC',
    name: 'VANTAGE INVESTMENT PARTNERS, LLC',
    label: 'VANTAGE INVESTMENT PARTNERS, LLC',
  },
  {
    cik: '0001087912',
    value: '0001087912,VANTAGEPOINT INVESTMENT ADVISERS LLC',
    name: 'VANTAGEPOINT INVESTMENT ADVISERS LLC',
    label: 'VANTAGEPOINT INVESTMENT ADVISERS LLC',
  },
  {
    cik: '0001686278',
    value: '0001686278,VANTIS INVESTMENT ADVISERS L.P.',
    name: 'VANTIS INVESTMENT ADVISERS L.P.',
    label: 'VANTIS INVESTMENT ADVISERS L.P.',
  },
  {
    cik: '0001559130',
    value: '0001559130,VARDE MANAGEMENT, L.P.',
    name: 'VARDE MANAGEMENT, L.P.',
    label: 'VARDE MANAGEMENT, L.P.',
  },
  {
    cik: '0001902567',
    value: '0001902567,VARENNE CAPITAL PARTNERS',
    name: 'VARENNE CAPITAL PARTNERS',
    label: 'VARENNE CAPITAL PARTNERS',
  },
  {
    cik: '0001929184',
    value: '0001929184,VARLEY HOLDINGS PTY LTD',
    name: 'VARLEY HOLDINGS PTY LTD',
    label: 'VARLEY HOLDINGS PTY LTD',
  },
  {
    cik: '0001698484',
    value: '0001698484,VARMA MUTUAL PENSION INSURANCE CO',
    name: 'VARMA MUTUAL PENSION INSURANCE CO',
    label: 'VARMA MUTUAL PENSION INSURANCE CO',
  },
  {
    cik: '0001203547',
    value: '0001203547,VASSALLUZZO SCOTT J',
    name: 'VASSALLUZZO SCOTT J',
    label: 'VASSALLUZZO SCOTT J',
  },
  {
    cik: '0001548880',
    value: '0001548880,VATERA HOLDINGS LLC',
    name: 'VATERA HOLDINGS LLC',
    label: 'VATERA HOLDINGS LLC',
  },
  {
    cik: '0000805870',
    value: '0000805870,VAUGHAN & CO SECURITIES, INC.',
    name: 'VAUGHAN & CO SECURITIES, INC.',
    label: 'VAUGHAN & CO SECURITIES, INC.',
  },
  {
    cik: '0001063571',
    value: '0001063571,VAUGHAN DAVID INVESTMENTS INC/IL',
    name: 'VAUGHAN DAVID INVESTMENTS INC/IL',
    label: 'VAUGHAN DAVID INVESTMENTS INC/IL',
  },
  {
    cik: '0001063571',
    value: '0001063571,VAUGHAN DAVID INVESTMENTS LLC/IL',
    name: 'VAUGHAN DAVID INVESTMENTS LLC/IL',
    label: 'VAUGHAN DAVID INVESTMENTS LLC/IL',
  },
  {
    cik: '0001021642',
    value: '0001021642,VAUGHAN NELSON INVESTMENT MANAGEMENT, L.P.',
    name: 'VAUGHAN NELSON INVESTMENT MANAGEMENT, L.P.',
    label: 'VAUGHAN NELSON INVESTMENT MANAGEMENT, L.P.',
  },
  {
    cik: '0001716476',
    value: '0001716476,VAZIRANI ASSET MANAGEMENT LLC',
    name: 'VAZIRANI ASSET MANAGEMENT LLC',
    label: 'VAZIRANI ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001673588',
    value: '0001673588,VCU INVESTMENT MANAGEMENT CO',
    name: 'VCU INVESTMENT MANAGEMENT CO',
    label: 'VCU INVESTMENT MANAGEMENT CO',
  },
  {
    cik: '0001780631',
    value: '0001780631,VECTOR CAPITAL, LTD.',
    name: 'VECTOR CAPITAL, LTD.',
    label: 'VECTOR CAPITAL, LTD.',
  },
  {
    cik: '0001857418',
    value: '0001857418,VECTOR CAPITAL MANAGEMENT, L.P.',
    name: 'VECTOR CAPITAL MANAGEMENT, L.P.',
    label: 'VECTOR CAPITAL MANAGEMENT, L.P.',
  },
  {
    cik: '0001750557',
    value: '0001750557,VECTORS RESEARCH MANAGEMENT, LLC',
    name: 'VECTORS RESEARCH MANAGEMENT, LLC',
    label: 'VECTORS RESEARCH MANAGEMENT, LLC',
  },
  {
    cik: '0001686409',
    value: '0001686409,VECTOR WEALTH MANAGEMENT, LLC',
    name: 'VECTOR WEALTH MANAGEMENT, LLC',
    label: 'VECTOR WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001844375',
    value: '0001844375,VEERY CAPITAL, LLC',
    name: 'VEERY CAPITAL, LLC',
    label: 'VEERY CAPITAL, LLC',
  },
  {
    cik: '0001536244',
    value: '0001536244,VEGA ENERGY GP, LLC',
    name: 'VEGA ENERGY GP, LLC',
    label: 'VEGA ENERGY GP, LLC',
  },
  {
    cik: '0001895612',
    value: '0001895612,VELA INVESTMENT MANAGEMENT, LLC',
    name: 'VELA INVESTMENT MANAGEMENT, LLC',
    label: 'VELA INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001848809',
    value: '0001848809,VELAN CAPITAL INVESTMENT MANAGEMENT LP',
    name: 'VELAN CAPITAL INVESTMENT MANAGEMENT LP',
    label: 'VELAN CAPITAL INVESTMENT MANAGEMENT LP',
  },
  {
    cik: '0001766802',
    value: '0001766802,VELANNE ASSET MANAGEMENT LTD',
    name: 'VELANNE ASSET MANAGEMENT LTD',
    label: 'VELANNE ASSET MANAGEMENT LTD',
  },
  {
    cik: '0001796592',
    value: '0001796592,VELT PARTNERS INVESTIMENTOS LTDA.',
    name: 'VELT PARTNERS INVESTIMENTOS LTDA.',
    label: 'VELT PARTNERS INVESTIMENTOS LTDA.',
  },
  {
    cik: '0001630413',
    value: '0001630413,VENATOR CAPITAL MANAGEMENT LTD.',
    name: 'VENATOR CAPITAL MANAGEMENT LTD.',
    label: 'VENATOR CAPITAL MANAGEMENT LTD.',
  },
  {
    cik: '0001838211',
    value: '0001838211,VENATOR MANAGEMENT LLC',
    name: 'VENATOR MANAGEMENT LLC',
    label: 'VENATOR MANAGEMENT LLC',
  },
  {
    cik: '0001776382',
    value: '0001776382,VENBIO PARTNERS LLC',
    name: 'VENBIO PARTNERS LLC',
    label: 'VENBIO PARTNERS LLC',
  },
  {
    cik: '0001737053',
    value: '0001737053,VENBIO PARTNERS L.P.',
    name: 'VENBIO PARTNERS L.P.',
    label: 'VENBIO PARTNERS L.P.',
  },
  {
    cik: '0001633313',
    value: '0001633313,VENBIO SELECT ADVISOR LLC',
    name: 'VENBIO SELECT ADVISOR LLC',
    label: 'VENBIO SELECT ADVISOR LLC',
  },
  {
    cik: '0001399348',
    value: '0001399348,VENOR CAPITAL MANAGEMENT LP',
    name: 'VENOR CAPITAL MANAGEMENT LP',
    label: 'VENOR CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001495383',
    value: '0001495383,VENROCK MANAGEMENT VI, LLC',
    name: 'VENROCK MANAGEMENT VI, LLC',
    label: 'VENROCK MANAGEMENT VI, LLC',
  },
  {
    cik: '0001444116',
    value: '0001444116,VENROCK MANAGEMENT V, LLC',
    name: 'VENROCK MANAGEMENT V, LLC',
    label: 'VENROCK MANAGEMENT V, LLC',
  },
  {
    cik: '0001444117',
    value: '0001444117,VENROCK PARTNERS MANAGEMENT V, LLC',
    name: 'VENROCK PARTNERS MANAGEMENT V, LLC',
    label: 'VENROCK PARTNERS MANAGEMENT V, LLC',
  },
  {
    cik: '0001792283',
    value: '0001792283,VENTURE VISIONARY PARTNERS LLC',
    name: 'VENTURE VISIONARY PARTNERS LLC',
    label: 'VENTURE VISIONARY PARTNERS LLC',
  },
  {
    cik: '0001666363',
    value: '0001666363,VENTURI WEALTH MANAGEMENT, LLC',
    name: 'VENTURI WEALTH MANAGEMENT, LLC',
    label: 'VENTURI WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001767297',
    value: '0001767297,VERABANK, N.A.',
    name: 'VERABANK, N.A.',
    label: 'VERABANK, N.A.',
  },
  {
    cik: '0001811005',
    value: '0001811005,VERACITY CAPITAL LLC',
    name: 'VERACITY CAPITAL LLC',
    label: 'VERACITY CAPITAL LLC',
  },
  {
    cik: '0001812822',
    value: '0001812822,VERDAD ADVISERS, LP',
    name: 'VERDAD ADVISERS, LP',
    label: 'VERDAD ADVISERS, LP',
  },
  {
    cik: '0001802891',
    value: '0001802891,VERDE CAPITAL MANAGEMENT',
    name: 'VERDE CAPITAL MANAGEMENT',
    label: 'VERDE CAPITAL MANAGEMENT',
  },
  {
    cik: '0001719305',
    value: '0001719305,VERDENCE CAPITAL ADVISORS LLC',
    name: 'VERDENCE CAPITAL ADVISORS LLC',
    label: 'VERDENCE CAPITAL ADVISORS LLC',
  },
  {
    cik: '0001629290',
    value: '0001629290,VERDE SERVICOS INTERNACIONAIS S.A.',
    name: 'VERDE SERVICOS INTERNACIONAIS S.A.',
    label: 'VERDE SERVICOS INTERNACIONAIS S.A.',
  },
  {
    cik: '0001627417',
    value: '0001627417,VERGER CAPITAL MANAGEMENT LLC',
    name: 'VERGER CAPITAL MANAGEMENT LLC',
    label: 'VERGER CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001912300',
    value: '0001912300,VERICREST PRIVATE WEALTH',
    name: 'VERICREST PRIVATE WEALTH',
    label: 'VERICREST PRIVATE WEALTH',
  },
  {
    cik: '0001905111',
    value: '0001905111,VERISTAR CAPITAL MANAGEMENT LP',
    name: 'VERISTAR CAPITAL MANAGEMENT LP',
    label: 'VERISTAR CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001300311',
    value: '0001300311,VERITABLE, L.P.',
    name: 'VERITABLE, L.P.',
    label: 'VERITABLE, L.P.',
  },
  {
    cik: '0001541448',
    value: '0001541448,VERITAS ASSET MANAGEMENT LLP',
    name: 'VERITAS ASSET MANAGEMENT LLP',
    label: 'VERITAS ASSET MANAGEMENT LLP',
  },
  {
    cik: '0001576707',
    value: '0001576707,VERITAS INVESTMENT MANAGEMENT LLP',
    name: 'VERITAS INVESTMENT MANAGEMENT LLP',
    label: 'VERITAS INVESTMENT MANAGEMENT LLP',
  },
  {
    cik: '0001577920',
    value: '0001577920,VERITAS INVESTMENT MANAGEMENT (UK) LTD',
    name: 'VERITAS INVESTMENT MANAGEMENT (UK) LTD',
    label: 'VERITAS INVESTMENT MANAGEMENT (UK) LTD',
  },
  {
    cik: '0001842702',
    value: '0001842702,VERITAS INVESTMENT PARTNERS (UK) LTD.',
    name: 'VERITAS INVESTMENT PARTNERS (UK) LTD.',
    label: 'VERITAS INVESTMENT PARTNERS (UK) LTD.',
  },
  {
    cik: '0001845469',
    value: '0001845469,VERITI MANAGEMENT LLC',
    name: 'VERITI MANAGEMENT LLC',
    label: 'VERITI MANAGEMENT LLC',
  },
  {
    cik: '0001454027',
    value: '0001454027,VERITION FUND MANAGEMENT LLC',
    name: 'VERITION FUND MANAGEMENT LLC',
    label: 'VERITION FUND MANAGEMENT LLC',
  },
  {
    cik: '0001597298',
    value: '0001597298,VERITY ASSET MANAGEMENT, INC.',
    name: 'VERITY ASSET MANAGEMENT, INC.',
    label: 'VERITY ASSET MANAGEMENT, INC.',
  },
  {
    cik: '0001542108',
    value: '0001542108,VERITY & VERITY, LLC',
    name: 'VERITY & VERITY, LLC',
    label: 'VERITY & VERITY, LLC',
  },
  {
    cik: '0001763138',
    value: '0001763138,VERITY WEALTH ADVISORS',
    name: 'VERITY WEALTH ADVISORS',
    label: 'VERITY WEALTH ADVISORS',
  },
  {
    cik: '0001421758',
    value: '0001421758,VERMILLION ASSET MANAGEMENT LLC',
    name: 'VERMILLION ASSET MANAGEMENT LLC',
    label: 'VERMILLION ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001599171',
    value: '0001599171,VERNIER CAPITAL PARTNERS, LP',
    name: 'VERNIER CAPITAL PARTNERS, LP',
    label: 'VERNIER CAPITAL PARTNERS, LP',
  },
  {
    cik: '0001735057',
    value: '0001735057,VERSANT CAPITAL MANAGEMENT, INC',
    name: 'VERSANT CAPITAL MANAGEMENT, INC',
    label: 'VERSANT CAPITAL MANAGEMENT, INC',
  },
  {
    cik: '0001560009',
    value: '0001560009,VERSANT VENTURE MANAGEMENT, LLC',
    name: 'VERSANT VENTURE MANAGEMENT, LLC',
    label: 'VERSANT VENTURE MANAGEMENT, LLC',
  },
  {
    cik: '0001650301',
    value: '0001650301,VERSOR INVESTMENTS LP',
    name: 'VERSOR INVESTMENTS LP',
    label: 'VERSOR INVESTMENTS LP',
  },
  {
    cik: '0001904664',
    value: '0001904664,VERT ASSET MANAGEMENT LLC',
    name: 'VERT ASSET MANAGEMENT LLC',
    label: 'VERT ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001389610',
    value: '0001389610,VERTEX ONE ASSET MANAGEMENT INC.',
    name: 'VERTEX ONE ASSET MANAGEMENT INC.',
    label: 'VERTEX ONE ASSET MANAGEMENT INC.',
  },
  {
    cik: '0001796874',
    value: '0001796874,VERUS CAPITAL PARTNERS, LLC',
    name: 'VERUS CAPITAL PARTNERS, LLC',
    label: 'VERUS CAPITAL PARTNERS, LLC',
  },
  {
    cik: '0001481714',
    value: '0001481714,VERUS FINANCIAL PARTNERS, INC.',
    name: 'VERUS FINANCIAL PARTNERS, INC.',
    label: 'VERUS FINANCIAL PARTNERS, INC.',
  },
  {
    cik: '0001810554',
    value: '0001810554,VESA EQUITY INVESTMENT S.A R.L.',
    name: 'VESA EQUITY INVESTMENT S.A R.L.',
    label: 'VESA EQUITY INVESTMENT S.A R.L.',
  },
  {
    cik: '0001910342',
    value: '0001910342,VESEY STREET CAPITAL PARTNERS, L.L.C.',
    name: 'VESEY STREET CAPITAL PARTNERS, L.L.C.',
    label: 'VESEY STREET CAPITAL PARTNERS, L.L.C.',
  },
  {
    cik: '0001537430',
    value: '0001537430,VESTAR CAPITAL PARTNERS',
    name: 'VESTAR CAPITAL PARTNERS',
    label: 'VESTAR CAPITAL PARTNERS',
  },
  {
    cik: '0001536925',
    value: '0001536925,VESTCOR INC',
    name: 'VESTCOR INC',
    label: 'VESTCOR INC',
  },
  {
    cik: '0001736982',
    value: '0001736982,VESTMARK ADVISORY SOLUTIONS, INC.',
    name: 'VESTMARK ADVISORY SOLUTIONS, INC.',
    label: 'VESTMARK ADVISORY SOLUTIONS, INC.',
  },
  {
    cik: '0001132699',
    value: '0001132699,VESTOR CAPITAL, LLC',
    name: 'VESTOR CAPITAL, LLC',
    label: 'VESTOR CAPITAL, LLC',
  },
  {
    cik: '0001709253',
    value: '0001709253,VESTPRO FINANCIAL PARTNERS, INC. DBA CPF TEXAS',
    name: 'VESTPRO FINANCIAL PARTNERS, INC. DBA CPF TEXAS',
    label: 'VESTPRO FINANCIAL PARTNERS, INC. DBA CPF TEXAS',
  },
  {
    cik: '0001880666',
    value: '0001880666,VETAMER CAPITAL MANAGEMENT, L.P.',
    name: 'VETAMER CAPITAL MANAGEMENT, L.P.',
    label: 'VETAMER CAPITAL MANAGEMENT, L.P.',
  },
  {
    cik: '0001577774',
    value: '0001577774,VGI PARTNERS LTD',
    name: 'VGI PARTNERS LTD',
    label: 'VGI PARTNERS LTD',
  },
  {
    cik: '0001738053',
    value: '0001738053,VHCP MANAGEMENT III, LLC',
    name: 'VHCP MANAGEMENT III, LLC',
    label: 'VHCP MANAGEMENT III, LLC',
  },
  {
    cik: '0001602263',
    value: '0001602263,VHCP MANAGEMENT II, LLC',
    name: 'VHCP MANAGEMENT II, LLC',
    label: 'VHCP MANAGEMENT II, LLC',
  },
  {
    cik: '0001479355',
    value: '0001479355,VHCP MANAGEMENT, LLC',
    name: 'VHCP MANAGEMENT, LLC',
    label: 'VHCP MANAGEMENT, LLC',
  },
  {
    cik: '0001127889',
    value: '0001127889,VICKERMAN & DRISCOLL FINANCIAL ADVISORS INC',
    name: 'VICKERMAN & DRISCOLL FINANCIAL ADVISORS INC',
    label: 'VICKERMAN & DRISCOLL FINANCIAL ADVISORS INC',
  },
  {
    cik: '0001898296',
    value: '0001898296,VICKERMAN INVESTMENT ADVISORS, INC.',
    name: 'VICKERMAN INVESTMENT ADVISORS, INC.',
    label: 'VICKERMAN INVESTMENT ADVISORS, INC.',
  },
  {
    cik: '0001542246',
    value: '0001542246,VICTORIA 1522 INVESTMENTS, LP',
    name: 'VICTORIA 1522 INVESTMENTS, LP',
    label: 'VICTORIA 1522 INVESTMENTS, LP',
  },
  {
    cik: '0001040188',
    value: '0001040188,VICTORY CAPITAL MANAGEMENT INC',
    name: 'VICTORY CAPITAL MANAGEMENT INC',
    label: 'VICTORY CAPITAL MANAGEMENT INC',
  },
  {
    cik: '0001544806',
    value: '0001544806,VICUS CAPITAL',
    name: 'VICUS CAPITAL',
    label: 'VICUS CAPITAL',
  },
  {
    cik: '0001831423',
    value: '0001831423,VIDA VENTURES ADVISORS, LLC',
    name: 'VIDA VENTURES ADVISORS, LLC',
    label: 'VIDA VENTURES ADVISORS, LLC',
  },
  {
    cik: '0001634047',
    value: '0001634047,VIDENT INVESTMENT ADVISORY, LLC',
    name: 'VIDENT INVESTMENT ADVISORY, LLC',
    label: 'VIDENT INVESTMENT ADVISORY, LLC',
  },
  {
    cik: '0001913545',
    value: '0001913545,VIENNA ASSET MANAGEMENT LLC',
    name: 'VIENNA ASSET MANAGEMENT LLC',
    label: 'VIENNA ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001906223',
    value: '0001906223,VIEWPOINT CAPITAL MANAGEMENT LLC',
    name: 'VIEWPOINT CAPITAL MANAGEMENT LLC',
    label: 'VIEWPOINT CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001767457',
    value: '0001767457,VIEWPOINT INVESTMENT PARTNERS CORP',
    name: 'VIEWPOINT INVESTMENT PARTNERS CORP',
    label: 'VIEWPOINT INVESTMENT PARTNERS CORP',
  },
  {
    cik: '0001619125',
    value: '0001619125,VIEX CAPITAL ADVISORS, LLC',
    name: 'VIEX CAPITAL ADVISORS, LLC',
    label: 'VIEX CAPITAL ADVISORS, LLC',
  },
  {
    cik: '0001425845',
    value: '0001425845,VIGILANT CAPITAL MANAGEMENT, LLC',
    name: 'VIGILANT CAPITAL MANAGEMENT, LLC',
    label: 'VIGILANT CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001730889',
    value: '0001730889,VIGILARE WEALTH MANAGEMENT',
    name: 'VIGILARE WEALTH MANAGEMENT',
    label: 'VIGILARE WEALTH MANAGEMENT',
  },
  {
    cik: '0001169069',
    value: '0001169069,VIKING FUND MANAGEMENT LLC',
    name: 'VIKING FUND MANAGEMENT LLC',
    label: 'VIKING FUND MANAGEMENT LLC',
  },
  {
    cik: '0001103804',
    value: '0001103804,VIKING GLOBAL INVESTORS LP',
    name: 'VIKING GLOBAL INVESTORS LP',
    label: 'VIKING GLOBAL INVESTORS LP',
  },
  {
    cik: '0001600375',
    value: '0001600375,VILAS FUND, LP',
    name: 'VILAS FUND, LP',
    label: 'VILAS FUND, LP',
  },
  {
    cik: '0001113629',
    value: '0001113629,VILLERE ST DENIS J & CO LLC',
    name: 'VILLERE ST DENIS J & CO LLC',
    label: 'VILLERE ST DENIS J & CO LLC',
  },
  {
    cik: '0001326766',
    value: '0001326766,VIOLICH CAPITAL MANAGEMENT, INC.',
    name: 'VIOLICH CAPITAL MANAGEMENT, INC.',
    label: 'VIOLICH CAPITAL MANAGEMENT, INC.',
  },
  {
    cik: '0001758163',
    value: '0001758163,VIRGINIA NATIONAL BANK',
    name: 'VIRGINIA NATIONAL BANK',
    label: 'VIRGINIA NATIONAL BANK',
  },
  {
    cik: '0000897676',
    value: '0000897676,VIRGINIA RETIREMENT SYSTEM',
    name: 'VIRGINIA RETIREMENT SYSTEM',
    label: 'VIRGINIA RETIREMENT SYSTEM',
  },
  {
    cik: '0001005354',
    value: '0001005354,VIRGINIA RETIREMENT SYSTEMS ET AL',
    name: 'VIRGINIA RETIREMENT SYSTEMS ET AL',
    label: 'VIRGINIA RETIREMENT SYSTEMS ET AL',
  },
  {
    cik: '0001898282',
    value: '0001898282,VIRGINIA WEALTH MANAGEMENT GROUP, INC.',
    name: 'VIRGINIA WEALTH MANAGEMENT GROUP, INC.',
    label: 'VIRGINIA WEALTH MANAGEMENT GROUP, INC.',
  },
  {
    cik: '0001730068',
    value: '0001730068,VIRIDIAN RIA, LLC',
    name: 'VIRIDIAN RIA, LLC',
    label: 'VIRIDIAN RIA, LLC',
  },
  {
    cik: '0001729985',
    value: '0001729985,VIRTUE CAPITAL MANAGEMENT, LLC',
    name: 'VIRTUE CAPITAL MANAGEMENT, LLC',
    label: 'VIRTUE CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001533964',
    value: '0001533964,VIRTU FINANCIAL LLC',
    name: 'VIRTU FINANCIAL LLC',
    label: 'VIRTU FINANCIAL LLC',
  },
  {
    cik: '0001569391',
    value: '0001569391,VIRTU KCG HOLDINGS LLC',
    name: 'VIRTU KCG HOLDINGS LLC',
    label: 'VIRTU KCG HOLDINGS LLC',
  },
  {
    cik: '0001591068',
    value: '0001591068,VIRTUS ALTERNATIVE INVESTMENT ADVISERS, INC.',
    name: 'VIRTUS ALTERNATIVE INVESTMENT ADVISERS, INC.',
    label: 'VIRTUS ALTERNATIVE INVESTMENT ADVISERS, INC.',
  },
  {
    cik: '0001587281',
    value: '0001587281,VIRTUS ETF ADVISERS LLC',
    name: 'VIRTUS ETF ADVISERS LLC',
    label: 'VIRTUS ETF ADVISERS LLC',
  },
  {
    cik: '0001913482',
    value: '0001913482,VIRTUS FAMILY OFFICE LLC',
    name: 'VIRTUS FAMILY OFFICE LLC',
    label: 'VIRTUS FAMILY OFFICE LLC',
  },
  {
    cik: '0001319111',
    value: '0001319111,VIRTUS FUND ADVISERS, LLC',
    name: 'VIRTUS FUND ADVISERS, LLC',
    label: 'VIRTUS FUND ADVISERS, LLC',
  },
  {
    cik: '0000019481',
    value: '0000019481,VIRTUS INVESTMENT ADVISERS, INC.',
    name: 'VIRTUS INVESTMENT ADVISERS, INC.',
    label: 'VIRTUS INVESTMENT ADVISERS, INC.',
  },
  {
    cik: '0001709478',
    value: '0001709478,VISA FOUNDATION',
    name: 'VISA FOUNDATION',
    label: 'VISA FOUNDATION',
  },
  {
    cik: '0001403161',
    value: '00014031,VISA INC.',
    name: 'VISA INC.',
    label: 'VISA INC.',
  },
  {
    cik: '0001812090',
    value: '0001812090,VISE TECHNOLOGIES, INC.',
    name: 'VISE TECHNOLOGIES, INC.',
    label: 'VISE TECHNOLOGIES, INC.',
  },
  {
    cik: '0001746438',
    value: '0001746438,VISHRIA BIRD FINANCIAL GROUP, LLC',
    name: 'VISHRIA BIRD FINANCIAL GROUP, LLC',
    label: 'VISHRIA BIRD FINANCIAL GROUP, LLC',
  },
  {
    cik: '0001541666',
    value: '0001541666,VISIONARY ASSET MANAGEMENT, INC.',
    name: 'VISIONARY ASSET MANAGEMENT, INC.',
    label: 'VISIONARY ASSET MANAGEMENT, INC.',
  },
  {
    cik: '0001802984',
    value: '0001802984,VISIONARY WEALTH ADVISORS',
    name: 'VISIONARY WEALTH ADVISORS',
    label: 'VISIONARY WEALTH ADVISORS',
  },
  {
    cik: '0001764403',
    value: '0001764403,VISION CAPITAL CORP',
    name: 'VISION CAPITAL CORP',
    label: 'VISION CAPITAL CORP',
  },
  {
    cik: '0001417889',
    value: '0001417889,VISION CAPITAL MANAGEMENT, INC.',
    name: 'VISION CAPITAL MANAGEMENT, INC.',
    label: 'VISION CAPITAL MANAGEMENT, INC.',
  },
  {
    cik: '0001750924',
    value: '0001750924,VISIONPOINT ADVISORY GROUP, LLC',
    name: 'VISIONPOINT ADVISORY GROUP, LLC',
    label: 'VISIONPOINT ADVISORY GROUP, LLC',
  },
  {
    cik: '0001377452',
    value: '0001377452,VISIUM ASSET MANAGEMENT, LP',
    name: 'VISIUM ASSET MANAGEMENT, LP',
    label: 'VISIUM ASSET MANAGEMENT, LP',
  },
  {
    cik: '0001486946',
    value: '0001486946,VISTA CAPITAL PARTNERS, INC.',
    name: 'VISTA CAPITAL PARTNERS, INC.',
    label: 'VISTA CAPITAL PARTNERS, INC.',
  },
  {
    cik: '0001569532',
    value: '0001569532,VISTA EQUITY PARTNERS III, LLC',
    name: 'VISTA EQUITY PARTNERS III, LLC',
    label: 'VISTA EQUITY PARTNERS III, LLC',
  },
  {
    cik: '0001569532',
    value: '0001569532,VISTA EQUITY PARTNERS MANAGEMENT, LLC',
    name: 'VISTA EQUITY PARTNERS MANAGEMENT, LLC',
    label: 'VISTA EQUITY PARTNERS MANAGEMENT, LLC',
  },
  {
    cik: '0001893809',
    value: '0001893809,VISTA FINANCE, LLC',
    name: 'VISTA FINANCE, LLC',
    label: 'VISTA FINANCE, LLC',
  },
  {
    cik: '0001740140',
    value: '0001740140,VISTA INVESTMENT MANAGEMENT',
    name: 'VISTA INVESTMENT MANAGEMENT',
    label: 'VISTA INVESTMENT MANAGEMENT',
  },
  {
    cik: '0001657980',
    value: '0001657980,VISTA PRIVATE WEALTH PARTNERS. LLC',
    name: 'VISTA PRIVATE WEALTH PARTNERS. LLC',
    label: 'VISTA PRIVATE WEALTH PARTNERS. LLC',
  },
  {
    cik: '0001800593',
    value: '0001800593,VISTA WEALTH MANAGEMENT GROUP, LLC',
    name: 'VISTA WEALTH MANAGEMENT GROUP, LLC',
    label: 'VISTA WEALTH MANAGEMENT GROUP, LLC',
  },
  {
    cik: '0001546531',
    value: '0001546531,VITOL HOLDING B.V.',
    name: 'VITOL HOLDING B.V.',
    label: 'VITOL HOLDING B.V.',
  },
  {
    cik: '0001535984',
    value: '0001535984,VITOL HOLDING SARL',
    name: 'VITOL HOLDING SARL',
    label: 'VITOL HOLDING SARL',
  },
  {
    cik: '0001692590',
    value: '0001692590,VITRUVIAN PARTNERS LLP',
    name: 'VITRUVIAN PARTNERS LLP',
    label: 'VITRUVIAN PARTNERS LLP',
  },
  {
    cik: '0001604488',
    value: '0001604488,VIVALDI ASSET MANAGEMENT, LLC',
    name: 'VIVALDI ASSET MANAGEMENT, LLC',
    label: 'VIVALDI ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001682021',
    value: '0001682021,VIVALDI CAPITAL MANAGEMENT LP',
    name: 'VIVALDI CAPITAL MANAGEMENT LP',
    label: 'VIVALDI CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001802911',
    value: '0001802911,VIVID FINANCIAL MANAGEMENT, INC.',
    name: 'VIVID FINANCIAL MANAGEMENT, INC.',
    label: 'VIVID FINANCIAL MANAGEMENT, INC.',
  },
  {
    cik: '0001674712',
    value: '0001674712,VIVO CAPITAL, LLC',
    name: 'VIVO CAPITAL, LLC',
    label: 'VIVO CAPITAL, LLC',
  },
  {
    cik: '0001501927',
    value: '0001501927,VK SERVICES, LLC',
    name: 'VK SERVICES, LLC',
    label: 'VK SERVICES, LLC',
  },
  {
    cik: '0001406585',
    value: '0001406585,VNBTRUST, NATIONAL ASSOCIATION',
    name: 'VNBTRUST, NATIONAL ASSOCIATION',
    label: 'VNBTRUST, NATIONAL ASSOCIATION',
  },
  {
    cik: '0001563530',
    value: '0001563530,VOCE CAPITAL MANAGEMENT LLC',
    name: 'VOCE CAPITAL MANAGEMENT LLC',
    label: 'VOCE CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001766790',
    value: '0001766790,VOIT & COMPANY, LLC',
    name: 'VOIT & COMPANY, LLC',
    label: 'VOIT & COMPANY, LLC',
  },
  {
    cik: '0001479847',
    value: '0001479847,VOLEON CAPITAL MANAGEMENT LP',
    name: 'VOLEON CAPITAL MANAGEMENT LP',
    label: 'VOLEON CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001512419',
    value: '0001512419,VOLLERO BEACH CAPITAL PARTNERS LLC',
    name: 'VOLLERO BEACH CAPITAL PARTNERS LLC',
    label: 'VOLLERO BEACH CAPITAL PARTNERS LLC',
  },
  {
    cik: '0001556921',
    value: '0001556921,VOLORIDGE INVESTMENT MANAGEMENT, LLC',
    name: 'VOLORIDGE INVESTMENT MANAGEMENT, LLC',
    label: 'VOLORIDGE INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001908070',
    value: '0001908070,VON BERGE WEALTH MANAGEMENT GROUP, LLC',
    name: 'VON BERGE WEALTH MANAGEMENT GROUP, LLC',
    label: 'VON BERGE WEALTH MANAGEMENT GROUP, LLC',
  },
  {
    cik: '0000928633',
    value: '0000928633,VONTOBEL ASSET MANAGEMENT INC',
    name: 'VONTOBEL ASSET MANAGEMENT INC',
    label: 'VONTOBEL ASSET MANAGEMENT INC',
  },
  {
    cik: '0001588340',
    value: '0001588340,VONTOBEL HOLDING LTD.',
    name: 'VONTOBEL HOLDING LTD.',
    label: 'VONTOBEL HOLDING LTD.',
  },
  {
    cik: '0001634222',
    value: '0001634222,VONTOBEL SWISS WEALTH ADVISORS AG',
    name: 'VONTOBEL SWISS WEALTH ADVISORS AG',
    label: 'VONTOBEL SWISS WEALTH ADVISORS AG',
  },
  {
    cik: '0001802614',
    value: '0001802614,VORPAL, LLC',
    name: 'VORPAL, LLC',
    label: 'VORPAL, LLC',
  },
  {
    cik: '0001730145',
    value: '0001730145,VOSS CAPITAL LLC',
    name: 'VOSS CAPITAL LLC',
    label: 'VOSS CAPITAL LLC',
  },
  {
    cik: '0001730145',
    value: '0001730145,VOSS CAPITAL, LLC',
    name: 'VOSS CAPITAL, LLC',
    label: 'VOSS CAPITAL, LLC',
  },
  {
    cik: '0001424367',
    value: '0001424367,VOYA FINANCIAL ADVISORS, INC.',
    name: 'VOYA FINANCIAL ADVISORS, INC.',
    label: 'VOYA FINANCIAL ADVISORS, INC.',
  },
  {
    cik: '0001849753',
    value: '0001849753,VOYAGER GLOBAL MANAGEMENT LP',
    name: 'VOYAGER GLOBAL MANAGEMENT LP',
    label: 'VOYAGER GLOBAL MANAGEMENT LP',
  },
  {
    cik: '0001068837',
    value: '0001068837,VOYA INVESTMENT MANAGEMENT LLC',
    name: 'VOYA INVESTMENT MANAGEMENT LLC',
    label: 'VOYA INVESTMENT MANAGEMENT LLC',
  },
  {
    cik: '0001894203',
    value: '0001894203,VPR MANAGEMENT LLC',
    name: 'VPR MANAGEMENT LLC',
    label: 'VPR MANAGEMENT LLC',
  },
  {
    cik: '0001615982',
    value: '0001615982,VR ADVISER, LLC',
    name: 'VR ADVISER, LLC',
    label: 'VR ADVISER, LLC',
  },
  {
    cik: '0001348145',
    value: '0001348145,VR ADVISORY SERVICES LTD',
    name: 'VR ADVISORY SERVICES LTD',
    label: 'VR ADVISORY SERVICES LTD',
  },
  {
    cik: '0001449689',
    value: '0001449689,VSR FINANCIAL SERVICES, INC.',
    name: 'VSR FINANCIAL SERVICES, INC.',
    label: 'VSR FINANCIAL SERVICES, INC.',
  },
  {
    cik: '0001388712',
    value: '0001388712,VTL ASSOCIATES, LLC',
    name: 'VTL ASSOCIATES, LLC',
    label: 'VTL ASSOCIATES, LLC',
  },
  {
    cik: '0001556785',
    value: '0001556785,VULCAN VALUE PARTNERS, LLC',
    name: 'VULCAN VALUE PARTNERS, LLC',
    label: 'VULCAN VALUE PARTNERS, LLC',
  },
  {
    cik: '0001735804',
    value: '0001735804,VV MANAGER LLC',
    name: 'VV MANAGER LLC',
    label: 'VV MANAGER LLC',
  },
  {
    cik: '0001661149',
    value: '0001661149,V WEALTH ADVISORS LLC',
    name: 'V WEALTH ADVISORS LLC',
    label: 'V WEALTH ADVISORS LLC',
  },
  {
    cik: '0001628622',
    value: '0001628622,VY CAPITAL MANAGEMENT CO. LTD.',
    name: 'VY CAPITAL MANAGEMENT CO. LTD.',
    label: 'VY CAPITAL MANAGEMENT CO. LTD.',
  },
  {
    cik: '0001568787',
    value: '0001568787,WA ASSET MANAGEMENT, LLC',
    name: 'WA ASSET MANAGEMENT, LLC',
    label: 'WA ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001776757',
    value: '0001776757,WADDELL & ASSOCIATES, LLC',
    name: 'WADDELL & ASSOCIATES, LLC',
    label: 'WADDELL & ASSOCIATES, LLC',
  },
  {
    cik: '0001052100',
    value: '0001052100,WADDELL & REED FINANCIAL INC',
    name: 'WADDELL & REED FINANCIAL INC',
    label: 'WADDELL & REED FINANCIAL INC',
  },
  {
    cik: '0001802670',
    value: '0001802670,WADE FINANCIAL ADVISORY, INC',
    name: 'WADE FINANCIAL ADVISORY, INC',
    label: 'WADE FINANCIAL ADVISORY, INC',
  },
  {
    cik: '0001132556',
    value: '0001132556,WADE G W & INC',
    name: 'WADE G W & INC',
    label: 'WADE G W & INC',
  },
  {
    cik: '0001929907',
    value: '0001929907,W ADVISORS, LLC',
    name: 'W ADVISORS, LLC',
    label: 'W ADVISORS, LLC',
  },
  {
    cik: '0000920440',
    value: '000092044,WAFRA INC.',
    name: 'WAFRA INC.',
    label: 'WAFRA INC.',
  },
  {
    cik: '0001259671',
    value: '0001259671,WAGNER BOWMAN MANAGEMENT CORP',
    name: 'WAGNER BOWMAN MANAGEMENT CORP',
    label: 'WAGNER BOWMAN MANAGEMENT CORP',
  },
  {
    cik: '0001728657',
    value: '0001728657,WAGNER WEALTH MANAGEMENT, LLC',
    name: 'WAGNER WEALTH MANAGEMENT, LLC',
    label: 'WAGNER WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001763146',
    value: '0001763146,WAHED INVEST LLC',
    name: 'WAHED INVEST LLC',
    label: 'WAHED INVEST LLC',
  },
  {
    cik: '0001600435',
    value: '0001600435,WAKEFIELD ASSET MANAGEMENT LLLP',
    name: 'WAKEFIELD ASSET MANAGEMENT LLLP',
    label: 'WAKEFIELD ASSET MANAGEMENT LLLP',
  },
  {
    cik: '0001598180',
    value: '0001598180,WALDRON PRIVATE WEALTH LLC',
    name: 'WALDRON PRIVATE WEALTH LLC',
    label: 'WALDRON PRIVATE WEALTH LLC',
  },
  {
    cik: '0001923053',
    value: '0001923053,WALKER ASSET MANAGEMENT, LLC',
    name: 'WALKER ASSET MANAGEMENT, LLC',
    label: 'WALKER ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001817494',
    value: '0001817494,WALKNER CONDON FINANCIAL ADVISORS LLC',
    name: 'WALKNER CONDON FINANCIAL ADVISORS LLC',
    label: 'WALKNER CONDON FINANCIAL ADVISORS LLC',
  },
  {
    cik: '0001459754',
    value: '0001459754,WALLACE CAPITAL MANAGEMENT INC.',
    name: 'WALLACE CAPITAL MANAGEMENT INC.',
    label: 'WALLACE CAPITAL MANAGEMENT INC.',
  },
  {
    cik: '0001699803',
    value: '0001699803,WALL CAPITAL GROUP, INC.',
    name: 'WALL CAPITAL GROUP, INC.',
    label: 'WALL CAPITAL GROUP, INC.',
  },
  {
    cik: '0001812177',
    value: '0001812177,WALLED LAKE PLANNING & WEALTH MANAGEMENT, LLC',
    name: 'WALLED LAKE PLANNING & WEALTH MANAGEMENT, LLC',
    label: 'WALLED LAKE PLANNING & WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001736079',
    value: '0001736079,WALLER FINANCIAL PLANNING GROUP, INC',
    name: 'WALLER FINANCIAL PLANNING GROUP, INC',
    label: 'WALLER FINANCIAL PLANNING GROUP, INC',
  },
  {
    cik: '0001758720',
    value: '0001758720,WALLEYE CAPITAL LLC',
    name: 'WALLEYE CAPITAL LLC',
    label: 'WALLEYE CAPITAL LLC',
  },
  {
    cik: '0001388391',
    value: '0001388391,WALLEYE TRADING LLC',
    name: 'WALLEYE TRADING LLC',
    label: 'WALLEYE TRADING LLC',
  },
  {
    cik: '0001426754',
    value: '0001426754,WALLINGTON ASSET MANAGEMENT, LLC',
    name: 'WALLINGTON ASSET MANAGEMENT, LLC',
    label: 'WALLINGTON ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001507971',
    value: '0001507971,WALL STREET ACCESS ASSET MANAGEMENT, LLC',
    name: 'WALL STREET ACCESS ASSET MANAGEMENT, LLC',
    label: 'WALL STREET ACCESS ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0000828405',
    value: '0000828405,WALL STREET ASSOCIATES',
    name: 'WALL STREET ASSOCIATES',
    label: 'WALL STREET ASSOCIATES',
  },
  {
    cik: '0001599275',
    value: '0001599275,WALNUT PRIVATE EQUITY PARTNERS, LLC',
    name: 'WALNUT PRIVATE EQUITY PARTNERS, LLC',
    label: 'WALNUT PRIVATE EQUITY PARTNERS, LLC',
  },
  {
    cik: '0000752365',
    value: '0000752365,WALTER & KEENAN FINANCIAL CONSULTING CO /MI/ /ADV',
    name: 'WALTER & KEENAN FINANCIAL CONSULTING CO /MI/ /ADV',
    label: 'WALTER & KEENAN FINANCIAL CONSULTING CO /MI/ /ADV',
  },
  {
    cik: '0000752365',
    value: '0000752365,WALTER & KEENAN WEALTH MANAGEMENT LLC /IN/ /ADV',
    name: 'WALTER & KEENAN WEALTH MANAGEMENT LLC /IN/ /ADV',
    label: 'WALTER & KEENAN WEALTH MANAGEMENT LLC /IN/ /ADV',
  },
  {
    cik: '0001925251',
    value: '0001925251,WALTER PUBLIC INVESTMENTS INC.',
    name: 'WALTER PUBLIC INVESTMENTS INC.',
    label: 'WALTER PUBLIC INVESTMENTS INC.',
  },
  {
    cik: '0001483339',
    value: '0001483339,WALTHAUSEN & CO., LLC',
    name: 'WALTHAUSEN & CO., LLC',
    label: 'WALTHAUSEN & CO., LLC',
  },
  {
    cik: '0001755723',
    value: '0001755723,WAMBOLT & ASSOCIATES, LLC',
    name: 'WAMBOLT & ASSOCIATES, LLC',
    label: 'WAMBOLT & ASSOCIATES, LLC',
  },
  {
    cik: '0001539919',
    value: '0001539919,WARATAH CAPITAL ADVISORS LTD.',
    name: 'WARATAH CAPITAL ADVISORS LTD.',
    label: 'WARATAH CAPITAL ADVISORS LTD.',
  },
  {
    cik: '0001597506',
    value: '0001597506,WARBERG ASSET MANAGEMENT LLC',
    name: 'WARBERG ASSET MANAGEMENT LLC',
    label: 'WARBERG ASSET MANAGEMENT LLC',
  },
  {
    cik: '0000929408',
    value: '0000929408,WARBURG PINCUS & CO.',
    name: 'WARBURG PINCUS & CO.',
    label: 'WARBURG PINCUS & CO.',
  },
  {
    cik: '0001162870',
    value: '0001162870,WARBURG PINCUS LLC',
    name: 'WARBURG PINCUS LLC',
    label: 'WARBURG PINCUS LLC',
  },
  {
    cik: '0001632574',
    value: '0001632574,WARD FERRY MANAGEMENT (BVI) LTD',
    name: 'WARD FERRY MANAGEMENT (BVI) LTD',
    label: 'WARD FERRY MANAGEMENT (BVI) LTD',
  },
  {
    cik: '0001673887',
    value: '0001673887,WARLANDER ASSET MANAGEMENT, LP',
    name: 'WARLANDER ASSET MANAGEMENT, LP',
    label: 'WARLANDER ASSET MANAGEMENT, LP',
  },
  {
    cik: '0001927175',
    value: '0001927175,WARNER FINANCIAL, INC',
    name: 'WARNER FINANCIAL, INC',
    label: 'WARNER FINANCIAL, INC',
  },
  {
    cik: '0001568787',
    value: '0001568787,WARREN AVERETT ASSET MANAGEMENT, LLC',
    name: 'WARREN AVERETT ASSET MANAGEMENT, LLC',
    label: 'WARREN AVERETT ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001703496',
    value: '0001703496,WARREN STREET WEALTH ADVISORS, LLC',
    name: 'WARREN STREET WEALTH ADVISORS, LLC',
    label: 'WARREN STREET WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001954551',
    value: '0001954551,WARTHER PRIVATE WEALTH, LLC',
    name: 'WARTHER PRIVATE WEALTH, LLC',
    label: 'WARTHER PRIVATE WEALTH, LLC',
  },
  {
    cik: '0001931041',
    value: '0001931041,WARWICK INVESTMENT MANAGEMENT, INC.',
    name: 'WARWICK INVESTMENT MANAGEMENT, INC.',
    label: 'WARWICK INVESTMENT MANAGEMENT, INC.',
  },
  {
    cik: '0000814133',
    value: '0000814133,WASATCH ADVISORS INC',
    name: 'WASATCH ADVISORS INC',
    label: 'WASATCH ADVISORS INC',
  },
  {
    cik: '0001793923',
    value: '0001793923,WASHBURN CAPITAL MANAGEMENT, INC.',
    name: 'WASHBURN CAPITAL MANAGEMENT, INC.',
    label: 'WASHBURN CAPITAL MANAGEMENT, INC.',
  },
  {
    cik: '0000928566',
    value: '0000928566,WASHINGTON CAPITAL MANAGEMENT INC',
    name: 'WASHINGTON CAPITAL MANAGEMENT INC',
    label: 'WASHINGTON CAPITAL MANAGEMENT INC',
  },
  {
    cik: '0001825404',
    value: '0001825404,WASHINGTON HARBOUR PARTNERS LP',
    name: 'WASHINGTON HARBOUR PARTNERS LP',
    label: 'WASHINGTON HARBOUR PARTNERS LP',
  },
  {
    cik: '0000943712',
    value: '0000943712,WASHINGTON STATE INVESTMENT BOARD',
    name: 'WASHINGTON STATE INVESTMENT BOARD',
    label: 'WASHINGTON STATE INVESTMENT BOARD',
  },
  {
    cik: '0001900099',
    value: '0001900099,WASHINGTON TRUST ADVISORS, INC.',
    name: 'WASHINGTON TRUST ADVISORS, INC.',
    label: 'WASHINGTON TRUST ADVISORS, INC.',
  },
  {
    cik: '0000861787',
    value: '0000861787,WASHINGTON TRUST BANK',
    name: 'WASHINGTON TRUST BANK',
    label: 'WASHINGTON TRUST BANK',
  },
  {
    cik: '0001079736',
    value: '0001079736,WASHINGTON TRUST CO',
    name: 'WASHINGTON TRUST CO',
    label: 'WASHINGTON TRUST CO',
  },
  {
    cik: '0000903603',
    value: '0000903603,WASHINGTON UNIVERSITY',
    name: 'WASHINGTON UNIVERSITY',
    label: 'WASHINGTON UNIVERSITY',
  },
  {
    cik: '0001748726',
    value: '0001748726,WATCHMAN GROUP, INC.',
    name: 'WATCHMAN GROUP, INC.',
    label: 'WATCHMAN GROUP, INC.',
  },
  {
    cik: '0001626757',
    value: '0001626757,WATCH POINT TRUST CO',
    name: 'WATCH POINT TRUST CO',
    label: 'WATCH POINT TRUST CO',
  },
  {
    cik: '0001423875',
    value: '0001423875,WATER ASSET MANAGEMENT LLC',
    name: 'WATER ASSET MANAGEMENT LLC',
    label: 'WATER ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001595077',
    value: '0001595077,WATERFRONT CAPITAL PARTNERS, LLC',
    name: 'WATERFRONT CAPITAL PARTNERS, LLC',
    label: 'WATERFRONT CAPITAL PARTNERS, LLC',
  },
  {
    cik: '0001775210',
    value: '0001775210,WATERFRONT WEALTH INC.',
    name: 'WATERFRONT WEALTH INC.',
    label: 'WATERFRONT WEALTH INC.',
  },
  {
    cik: '0001222765',
    value: '0001222765,WATER ISLAND CAPITAL LLC',
    name: 'WATER ISLAND CAPITAL LLC',
    label: 'WATER ISLAND CAPITAL LLC',
  },
  {
    cik: '0001730942',
    value: '0001730942,WATERLOO CAPITAL, L.P.',
    name: 'WATERLOO CAPITAL, L.P.',
    label: 'WATERLOO CAPITAL, L.P.',
  },
  {
    cik: '0001706457',
    value: '0001706457,WATERMARK ASSET MANAGEMENT, INC.',
    name: 'WATERMARK ASSET MANAGEMENT, INC.',
    label: 'WATERMARK ASSET MANAGEMENT, INC.',
  },
  {
    cik: '0001589570',
    value: '0001589570,WATER OAK ADVISORS LLC',
    name: 'WATER OAK ADVISORS LLC',
    label: 'WATER OAK ADVISORS LLC',
  },
  {
    cik: '0001315926',
    value: '0001315926,WATERSHED ASSET MANAGEMENT, L.L.C.',
    name: 'WATERSHED ASSET MANAGEMENT, L.L.C.',
    label: 'WATERSHED ASSET MANAGEMENT, L.L.C.',
  },
  {
    cik: '0001222993',
    value: '0001222993,WATERS PARKERSON & CO., LLC',
    name: 'WATERS PARKERSON & CO., LLC',
    label: 'WATERS PARKERSON & CO., LLC',
  },
  {
    cik: '0001512370',
    value: '0001512370,WATERSTONE CAPITAL MANAGEMENT, L.P.',
    name: 'WATERSTONE CAPITAL MANAGEMENT, L.P.',
    label: 'WATERSTONE CAPITAL MANAGEMENT, L.P.',
  },
  {
    cik: '0001733600',
    value: '0001733600,WATERTON GLOBAL RESOURCE MANAGEMENT, INC.',
    name: 'WATERTON GLOBAL RESOURCE MANAGEMENT, INC.',
    label: 'WATERTON GLOBAL RESOURCE MANAGEMENT, INC.',
  },
  {
    cik: '0001795711',
    value: '0001795711,WATSON REBECCA',
    name: 'WATSON REBECCA',
    label: 'WATSON REBECCA',
  },
  {
    cik: '0001623810',
    value: '0001623810,WAVELENGTH ASSET MANAGEMENT LLC',
    name: 'WAVELENGTH ASSET MANAGEMENT LLC',
    label: 'WAVELENGTH ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001872038',
    value: '0001872038,WAVELENGTH CAPITAL MANAGEMENT, LLC',
    name: 'WAVELENGTH CAPITAL MANAGEMENT, LLC',
    label: 'WAVELENGTH CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001344114',
    value: '0001344114,WAVERTON INVESTMENT MANAGEMENT LTD',
    name: 'WAVERTON INVESTMENT MANAGEMENT LTD',
    label: 'WAVERTON INVESTMENT MANAGEMENT LTD',
  },
  {
    cik: '0001844873',
    value: '0001844873,WAYCROSS PARTNERS, LLC',
    name: 'WAYCROSS PARTNERS, LLC',
    label: 'WAYCROSS PARTNERS, LLC',
  },
  {
    cik: '0001898131',
    value: '0001898131,WAYFINDING FINANCIAL, LLC',
    name: 'WAYFINDING FINANCIAL, LLC',
    label: 'WAYFINDING FINANCIAL, LLC',
  },
  {
    cik: '0000049096',
    value: '0000049096,WAYNE HUMMER INVESTMENTS L.L.C.',
    name: 'WAYNE HUMMER INVESTMENTS L.L.C.',
    label: 'WAYNE HUMMER INVESTMENTS L.L.C.',
  },
  {
    cik: '0001905662',
    value: '0001905662,WAYPOINT CAPITAL ADVISORS, LLC',
    name: 'WAYPOINT CAPITAL ADVISORS, LLC',
    label: 'WAYPOINT CAPITAL ADVISORS, LLC',
  },
  {
    cik: '0001817306',
    value: '0001817306,WAYPOINT WEALTH PARTNERS INC.',
    name: 'WAYPOINT WEALTH PARTNERS INC.',
    label: 'WAYPOINT WEALTH PARTNERS INC.',
  },
  {
    cik: '0001389839',
    value: '0001389839,WAYZATA INVESTMENT PARTNERS LLC',
    name: 'WAYZATA INVESTMENT PARTNERS LLC',
    label: 'WAYZATA INVESTMENT PARTNERS LLC',
  },
  {
    cik: '0001259671',
    value: '0001259671,WBH ADVISORY INC',
    name: 'WBH ADVISORY INC',
    label: 'WBH ADVISORY INC',
  },
  {
    cik: '0001397424',
    value: '0001397424,WBI INVESTMENTS',
    name: 'WBI INVESTMENTS',
    label: 'WBI INVESTMENTS',
  },
  {
    cik: '0001397424',
    value: '0001397424,WBI INVESTMENTS, INC.',
    name: 'WBI INVESTMENTS, INC.',
    label: 'WBI INVESTMENTS, INC.',
  },
  {
    cik: '0001767329',
    value: '0001767329,W CAPITAL MANAGEMENT, LLC',
    name: 'W CAPITAL MANAGEMENT, LLC',
    label: 'W CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001468686',
    value: '0001468686,WCG MANAGEMENT, L.P.',
    name: 'WCG MANAGEMENT, L.P.',
    label: 'WCG MANAGEMENT, L.P.',
  },
  {
    cik: '0001855713',
    value: '0001855713,WCG WEALTH ADVISORS LLC',
    name: 'WCG WEALTH ADVISORS LLC',
    label: 'WCG WEALTH ADVISORS LLC',
  },
  {
    cik: '0001061186',
    value: '0001061186,WCM INVESTMENT MANAGEMENT, LLC',
    name: 'WCM INVESTMENT MANAGEMENT, LLC',
    label: 'WCM INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001847258',
    value: '0001847258,WC WALKER & ASSOCIATES, INC.',
    name: 'WC WALKER & ASSOCIATES, INC.',
    label: 'WC WALKER & ASSOCIATES, INC.',
  },
  {
    cik: '0001841015',
    value: '0001841015,WD RUTHERFORD LLC',
    name: 'WD RUTHERFORD LLC',
    label: 'WD RUTHERFORD LLC',
  },
  {
    cik: '0001806580',
    value: '0001806580,WEALTH ADVISORS, INC.',
    name: 'WEALTH ADVISORS, INC.',
    label: 'WEALTH ADVISORS, INC.',
  },
  {
    cik: '0001803005',
    value: '0001803005,WEALTH ADVISORS OF IOWA, LLC',
    name: 'WEALTH ADVISORS OF IOWA, LLC',
    label: 'WEALTH ADVISORS OF IOWA, LLC',
  },
  {
    cik: '0001715228',
    value: '0001715228,WEALTH ADVISORS OF TAMPA BAY, LLC',
    name: 'WEALTH ADVISORS OF TAMPA BAY, LLC',
    label: 'WEALTH ADVISORS OF TAMPA BAY, LLC',
  },
  {
    cik: '0001834802',
    value: '0001834802,WEALTH ADVISORY SOLUTIONS, LLC',
    name: 'WEALTH ADVISORY SOLUTIONS, LLC',
    label: 'WEALTH ADVISORY SOLUTIONS, LLC',
  },
  {
    cik: '0001793904',
    value: '0001793904,WEALTH ALLIANCE',
    name: 'WEALTH ALLIANCE',
    label: 'WEALTH ALLIANCE',
  },
  {
    cik: '0001735734',
    value: '0001735734,WEALTH ALLIANCE ADVISORY GROUP, LLC',
    name: 'WEALTH ALLIANCE ADVISORY GROUP, LLC',
    label: 'WEALTH ALLIANCE ADVISORY GROUP, LLC',
  },
  {
    cik: '0001611518',
    value: '0001611518,WEALTH ARCHITECTS, LLC',
    name: 'WEALTH ARCHITECTS, LLC',
    label: 'WEALTH ARCHITECTS, LLC',
  },
  {
    cik: '0001767313',
    value: '0001767313,WEALTHBRIDGE CAPITAL MANAGEMENT, LLC',
    name: 'WEALTHBRIDGE CAPITAL MANAGEMENT, LLC',
    label: 'WEALTHBRIDGE CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001799425',
    value: '0001799425,WEALTHBRIDGE INVESTMENT COUNSEL INC.',
    name: 'WEALTHBRIDGE INVESTMENT COUNSEL INC.',
    label: 'WEALTHBRIDGE INVESTMENT COUNSEL INC.',
  },
  {
    cik: '0001683059',
    value: '0001683059,WEALTHCARE ADVISORY PARTNERS LLC',
    name: 'WEALTHCARE ADVISORY PARTNERS LLC',
    label: 'WEALTHCARE ADVISORY PARTNERS LLC',
  },
  {
    cik: '0001516450',
    value: '0001516450,WEALTHCARE CAPITAL MANAGEMENT LLC',
    name: 'WEALTHCARE CAPITAL MANAGEMENT LLC',
    label: 'WEALTHCARE CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001908462',
    value: '0001908462,WEALTHCARE INVESTMENT PARTNERS, LLC',
    name: 'WEALTHCARE INVESTMENT PARTNERS, LLC',
    label: 'WEALTHCARE INVESTMENT PARTNERS, LLC',
  },
  {
    cik: '0001802660',
    value: '000180266,WEALTH CMT',
    name: 'WEALTH CMT',
    label: 'WEALTH CMT',
  },
  {
    cik: '0001846462',
    value: '0001846462,WEALTH DIMENSIONS GROUP, LTD.',
    name: 'WEALTH DIMENSIONS GROUP, LTD.',
    label: 'WEALTH DIMENSIONS GROUP, LTD.',
  },
  {
    cik: '0001907254',
    value: '0001907254,WEALTH EFFECTS LLC',
    name: 'WEALTH EFFECTS LLC',
    label: 'WEALTH EFFECTS LLC',
  },
  {
    cik: '0001632972',
    value: '0001632972,WEALTH ENHANCEMENT ADVISORY SERVICES, LLC',
    name: 'WEALTH ENHANCEMENT ADVISORY SERVICES, LLC',
    label: 'WEALTH ENHANCEMENT ADVISORY SERVICES, LLC',
  },
  {
    cik: '0001803919',
    value: '0001803919,WEALTH ENHANCEMENT & PRESERVATION OF GA, LLC',
    name: 'WEALTH ENHANCEMENT & PRESERVATION OF GA, LLC',
    label: 'WEALTH ENHANCEMENT & PRESERVATION OF GA, LLC',
  },
  {
    cik: '0001763921',
    value: '0001763921,WEALTHFRONT ADVISERS LLC',
    name: 'WEALTHFRONT ADVISERS LLC',
    label: 'WEALTHFRONT ADVISERS LLC',
  },
  {
    cik: '0001524566',
    value: '0001524566,WEALTHFRONT CORP',
    name: 'WEALTHFRONT CORP',
    label: 'WEALTHFRONT CORP',
  },
  {
    cik: '0001835441',
    value: '0001835441,WEALTHGATE FAMILY OFFICE, LLC',
    name: 'WEALTHGATE FAMILY OFFICE, LLC',
    label: 'WEALTHGATE FAMILY OFFICE, LLC',
  },
  {
    cik: '0001843294',
    value: '0001843294,WEALTH MANAGEMENT PARTNERS, LLC',
    name: 'WEALTH MANAGEMENT PARTNERS, LLC',
    label: 'WEALTH MANAGEMENT PARTNERS, LLC',
  },
  {
    cik: '0001063516',
    value: '0001063516,WEALTH MANAGEMENT RESOURCES, INC.',
    name: 'WEALTH MANAGEMENT RESOURCES, INC.',
    label: 'WEALTH MANAGEMENT RESOURCES, INC.',
  },
  {
    cik: '0001905979',
    value: '0001905979,WEALTH MANAGEMENT SOLUTIONS, LLC',
    name: 'WEALTH MANAGEMENT SOLUTIONS, LLC',
    label: 'WEALTH MANAGEMENT SOLUTIONS, LLC',
  },
  {
    cik: '0001774552',
    value: '0001774552,WEALTHNAVI INC.',
    name: 'WEALTHNAVI INC.',
    label: 'WEALTHNAVI INC.',
  },
  {
    cik: '0001763608',
    value: '0001763608,WEALTHONE, LLC',
    name: 'WEALTHONE, LLC',
    label: 'WEALTHONE, LLC',
  },
  {
    cik: '0001697029',
    value: '0001697029,WEALTH & PENSION SERVICES GROUP, INC.',
    name: 'WEALTH & PENSION SERVICES GROUP, INC.',
    label: 'WEALTH & PENSION SERVICES GROUP, INC.',
  },
  {
    cik: '0001929986',
    value: '0001929986,WEALTHPLAN INVESTMENT MANAGEMENT, LLC',
    name: 'WEALTHPLAN INVESTMENT MANAGEMENT, LLC',
    label: 'WEALTHPLAN INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001639943',
    value: '0001639943,WEALTHPLAN PARTNERS, LLC',
    name: 'WEALTHPLAN PARTNERS, LLC',
    label: 'WEALTHPLAN PARTNERS, LLC',
  },
  {
    cik: '0001763608',
    value: '0001763608,WEALTHPOINT, LLC',
    name: 'WEALTHPOINT, LLC',
    label: 'WEALTHPOINT, LLC',
  },
  {
    cik: '0001803386',
    value: '0001803386,WEALTH QUARTERBACK LLC',
    name: 'WEALTH QUARTERBACK LLC',
    label: 'WEALTH QUARTERBACK LLC',
  },
  {
    cik: '0001632968',
    value: '0001632968,WEALTHQUEST CORP',
    name: 'WEALTHQUEST CORP',
    label: 'WEALTHQUEST CORP',
  },
  {
    cik: '0001730973',
    value: '0001730973,WEALTHSHIELD, LLC',
    name: 'WEALTHSHIELD, LLC',
    label: 'WEALTHSHIELD, LLC',
  },
  {
    cik: '0001729303',
    value: '0001729303,WEALTHSHIELD PARTNERS, LLC',
    name: 'WEALTHSHIELD PARTNERS, LLC',
    label: 'WEALTHSHIELD PARTNERS, LLC',
  },
  {
    cik: '0001915565',
    value: '0001915565,WEALTHSIMPLE INC.',
    name: 'WEALTHSIMPLE INC.',
    label: 'WEALTHSIMPLE INC.',
  },
  {
    cik: '0001811613',
    value: '0001811613,WEALTHSIMPLE US, LTD.',
    name: 'WEALTHSIMPLE US, LTD.',
    label: 'WEALTHSIMPLE US, LTD.',
  },
  {
    cik: '0001674623',
    value: '0001674623,WEALTHSOURCE PARTNERS, LLC',
    name: 'WEALTHSOURCE PARTNERS, LLC',
    label: 'WEALTHSOURCE PARTNERS, LLC',
  },
  {
    cik: '0001909805',
    value: '0001909805,WEALTHSPAN PARTNERS, LLC',
    name: 'WEALTHSPAN PARTNERS, LLC',
    label: 'WEALTHSPAN PARTNERS, LLC',
  },
  {
    cik: '0001646247',
    value: '0001646247,WEALTHSPIRE ADVISORS, LLC',
    name: 'WEALTHSPIRE ADVISORS, LLC',
    label: 'WEALTHSPIRE ADVISORS, LLC',
  },
  {
    cik: '0001666612',
    value: '0001666612,WEALTHSPIRE ADVISORS, L.P.',
    name: 'WEALTHSPIRE ADVISORS, L.P.',
    label: 'WEALTHSPIRE ADVISORS, L.P.',
  },
  {
    cik: '0001844495',
    value: '0001844495,WEALTHSPRING CAPITAL LLC',
    name: 'WEALTHSPRING CAPITAL LLC',
    label: 'WEALTHSPRING CAPITAL LLC',
  },
  {
    cik: '0001602224',
    value: '0001602224,WEALTHSTAR ADVISORS, LLC',
    name: 'WEALTHSTAR ADVISORS, LLC',
    label: 'WEALTHSTAR ADVISORS, LLC',
  },
  {
    cik: '0001548784',
    value: '0001548784,WEALTHSTONE, INC.',
    name: 'WEALTHSTONE, INC.',
    label: 'WEALTHSTONE, INC.',
  },
  {
    cik: '0001833780',
    value: '0001833780,WEALTHSTREAM ADVISORS, INC.',
    name: 'WEALTHSTREAM ADVISORS, INC.',
    label: 'WEALTHSTREAM ADVISORS, INC.',
  },
  {
    cik: '0001730466',
    value: '0001730466,WEALTHSTREET INVESTMENT ADVISORS, LLC',
    name: 'WEALTHSTREET INVESTMENT ADVISORS, LLC',
    label: 'WEALTHSTREET INVESTMENT ADVISORS, LLC',
  },
  {
    cik: '0001424447',
    value: '0001424447,WEALTHTRUST-ARIZONA, LLC',
    name: 'WEALTHTRUST-ARIZONA, LLC',
    label: 'WEALTHTRUST-ARIZONA, LLC',
  },
  {
    cik: '0001867894',
    value: '0001867894,WEALTHTRUST ASSET MANAGEMENT, LLC',
    name: 'WEALTHTRUST ASSET MANAGEMENT, LLC',
    label: 'WEALTHTRUST ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001425949',
    value: '0001425949,WEALTHTRUST AXIOM LLC',
    name: 'WEALTHTRUST AXIOM LLC',
    label: 'WEALTHTRUST AXIOM LLC',
  },
  {
    cik: '0001427806',
    value: '0001427806,WEALTHTRUST FAIRPORT, LLC',
    name: 'WEALTHTRUST FAIRPORT, LLC',
    label: 'WEALTHTRUST FAIRPORT, LLC',
  },
  {
    cik: '0001632422',
    value: '0001632422,WEALTHY FOUNTAIN HOLDINGS INC',
    name: 'WEALTHY FOUNTAIN HOLDINGS INC',
    label: 'WEALTHY FOUNTAIN HOLDINGS INC',
  },
  {
    cik: '0001731444',
    value: '0001731444,WE ARE ONE SEVEN, LLC',
    name: 'WE ARE ONE SEVEN, LLC',
    label: 'WE ARE ONE SEVEN, LLC',
  },
  {
    cik: '0001082045',
    value: '0001082045,WEATHERBIE CAPITAL, LLC',
    name: 'WEATHERBIE CAPITAL, LLC',
    label: 'WEATHERBIE CAPITAL, LLC',
  },
  {
    cik: '0001762575',
    value: '0001762575,WEATHER GAUGE ADVISORY, LLC',
    name: 'WEATHER GAUGE ADVISORY, LLC',
    label: 'WEATHER GAUGE ADVISORY, LLC',
  },
  {
    cik: '0000934745',
    value: '0000934745,WEATHERLY ASSET MANAGEMENT L. P.',
    name: 'WEATHERLY ASSET MANAGEMENT L. P.',
    label: 'WEATHERLY ASSET MANAGEMENT L. P.',
  },
  {
    cik: '0001535578',
    value: '0001535578,WEATHERSTONE CAPITAL MANAGEMENT',
    name: 'WEATHERSTONE CAPITAL MANAGEMENT',
    label: 'WEATHERSTONE CAPITAL MANAGEMENT',
  },
  {
    cik: '0001294588',
    value: '0001294588,WEAVER C. BARKSDALE & ASSOCIATES, INC.',
    name: 'WEAVER C. BARKSDALE & ASSOCIATES, INC.',
    label: 'WEAVER C. BARKSDALE & ASSOCIATES, INC.',
  },
  {
    cik: '0001780055',
    value: '0001780055,WEAVER CONSULTING GROUP',
    name: 'WEAVER CONSULTING GROUP',
    label: 'WEAVER CONSULTING GROUP',
  },
  {
    cik: '0001139986',
    value: '0001139986,WEBER ALAN W',
    name: 'WEBER ALAN W',
    label: 'WEBER ALAN W',
  },
  {
    cik: '0001105837',
    value: '0001105837,WEBSTER BANK, N. A.',
    name: 'WEBSTER BANK, N. A.',
    label: 'WEBSTER BANK, N. A.',
  },
  {
    cik: '0001841757',
    value: '0001841757,WEBSTERROGERS FINANCIAL ADVISORS, LLC',
    name: 'WEBSTERROGERS FINANCIAL ADVISORS, LLC',
    label: 'WEBSTERROGERS FINANCIAL ADVISORS, LLC',
  },
  {
    cik: '0001538338',
    value: '0001538338,WECHTER FELDMAN WEALTH MANAGEMENT, INC.',
    name: 'WECHTER FELDMAN WEALTH MANAGEMENT, INC.',
    label: 'WECHTER FELDMAN WEALTH MANAGEMENT, INC.',
  },
  {
    cik: '0001142495',
    value: '0001142495,WEDBUSH MORGAN SECURITIES INC',
    name: 'WEDBUSH MORGAN SECURITIES INC',
    label: 'WEDBUSH MORGAN SECURITIES INC',
  },
  {
    cik: '0001142495',
    value: '0001142495,WEDBUSH SECURITIES INC',
    name: 'WEDBUSH SECURITIES INC',
    label: 'WEDBUSH SECURITIES INC',
  },
  {
    cik: '0001015308',
    value: '0001015308,WEDGE CAPITAL MANAGEMENT L L P/NC',
    name: 'WEDGE CAPITAL MANAGEMENT L L P/NC',
    label: 'WEDGE CAPITAL MANAGEMENT L L P/NC',
  },
  {
    cik: '0001125243',
    value: '0001125243,WEDGEWOOD INVESTORS INC /PA/',
    name: 'WEDGEWOOD INVESTORS INC /PA/',
    label: 'WEDGEWOOD INVESTORS INC /PA/',
  },
  {
    cik: '0000859804',
    value: '0000859804,WEDGEWOOD PARTNERS INC',
    name: 'WEDGEWOOD PARTNERS INC',
    label: 'WEDGEWOOD PARTNERS INC',
  },
  {
    cik: '0001904274',
    value: '0001904274,WEDMONT PRIVATE CAPITAL',
    name: 'WEDMONT PRIVATE CAPITAL',
    label: 'WEDMONT PRIVATE CAPITAL',
  },
  {
    cik: '0001702435',
    value: '0001702435,W. E. DONOGHUE & CO., LLC',
    name: 'W. E. DONOGHUE & CO., LLC',
    label: 'W. E. DONOGHUE & CO., LLC',
  },
  {
    cik: '0001278793',
    value: '0001278793,WEIK CAPITAL MANAGEMENT',
    name: 'WEIK CAPITAL MANAGEMENT',
    label: 'WEIK CAPITAL MANAGEMENT',
  },
  {
    cik: '0001318011',
    value: '0001318011,WEIL COMPANY, INC.',
    name: 'WEIL COMPANY, INC.',
    label: 'WEIL COMPANY, INC.',
  },
  {
    cik: '0001357550',
    value: '0001357550,WEISS ASSET MANAGEMENT LP',
    name: 'WEISS ASSET MANAGEMENT LP',
    label: 'WEISS ASSET MANAGEMENT LP',
  },
  {
    cik: '0001388312',
    value: '0001388312,WEISS MULTI-STRATEGY ADVISERS LLC',
    name: 'WEISS MULTI-STRATEGY ADVISERS LLC',
    label: 'WEISS MULTI-STRATEGY ADVISERS LLC',
  },
  {
    cik: '0001846789',
    value: '0001846789,WEITZEL FINANCIAL SERVICES, INC.',
    name: 'WEITZEL FINANCIAL SERVICES, INC.',
    label: 'WEITZEL FINANCIAL SERVICES, INC.',
  },
  {
    cik: '0000883965',
    value: '0000883965,WEITZ INVESTMENT MANAGEMENT, INC.',
    name: 'WEITZ INVESTMENT MANAGEMENT, INC.',
    label: 'WEITZ INVESTMENT MANAGEMENT, INC.',
  },
  {
    cik: '0001644329',
    value: '0001644329,WELA STRATEGIES, INC.',
    name: 'WELA STRATEGIES, INC.',
    label: 'WELA STRATEGIES, INC.',
  },
  {
    cik: '0001042063',
    value: '0001042063,WELCH CAPITAL PARTNERS LLC/NY',
    name: 'WELCH CAPITAL PARTNERS LLC/NY',
    label: 'WELCH CAPITAL PARTNERS LLC/NY',
  },
  {
    cik: '0000105495',
    value: '0000105495,WELCH & FORBES LLC',
    name: 'WELCH & FORBES LLC',
    label: 'WELCH & FORBES LLC',
  },
  {
    cik: '0001443095',
    value: '0001443095,WELCH GROUP, LLC',
    name: 'WELCH GROUP, LLC',
    label: 'WELCH GROUP, LLC',
  },
  {
    cik: '0001697444',
    value: '0001697444,WELCH INVESTMENTS, LLC',
    name: 'WELCH INVESTMENTS, LLC',
    label: 'WELCH INVESTMENTS, LLC',
  },
  {
    cik: '0001739943',
    value: '0001739943,WELD CAPITAL MANAGEMENT LLC',
    name: 'WELD CAPITAL MANAGEMENT LLC',
    label: 'WELD CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001026720',
    value:
      '0001026720,WELLCOME TRUST LTD (THE) AS TRUSTEE OF THE WELLCOME TRUST',
    name: 'WELLCOME TRUST LTD (THE) AS TRUSTEE OF THE WELLCOME TRUST',
    label: 'WELLCOME TRUST LTD (THE) AS TRUSTEE OF THE WELLCOME TRUST',
  },
  {
    cik: '0001667132',
    value: '0001667132,WELL DONE, LLC',
    name: 'WELL DONE, LLC',
    label: 'WELL DONE, LLC',
  },
  {
    cik: '0001533551',
    value: '0001533551,WELLESLEY ASSET MANAGEMENT',
    name: 'WELLESLEY ASSET MANAGEMENT',
    label: 'WELLESLEY ASSET MANAGEMENT',
  },
  {
    cik: '0001669733',
    value: '0001669733,WELLESLEY INVESTMENT PARTNERS, LLC',
    name: 'WELLESLEY INVESTMENT PARTNERS, LLC',
    label: 'WELLESLEY INVESTMENT PARTNERS, LLC',
  },
  {
    cik: '0000902219',
    value: '0000902219,WELLINGTON MANAGEMENT CO LLP',
    name: 'WELLINGTON MANAGEMENT CO LLP',
    label: 'WELLINGTON MANAGEMENT CO LLP',
  },
  {
    cik: '0000902219',
    value: '0000902219,WELLINGTON MANAGEMENT GROUP LLP',
    name: 'WELLINGTON MANAGEMENT GROUP LLP',
    label: 'WELLINGTON MANAGEMENT GROUP LLP',
  },
  {
    cik: '0001506071',
    value: '0001506071,WELLINGTON SHIELDS CAPITAL MANAGEMENT, LLC',
    name: 'WELLINGTON SHIELDS CAPITAL MANAGEMENT, LLC',
    label: 'WELLINGTON SHIELDS CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001506073',
    value: '0001506073,WELLINGTON SHIELDS & CO., LLC',
    name: 'WELLINGTON SHIELDS & CO., LLC',
    label: 'WELLINGTON SHIELDS & CO., LLC',
  },
  {
    cik: '0000072971',
    value: '0000072971,WELLS FARGO & COMPANY/MN',
    name: 'WELLS FARGO & COMPANY/MN',
    label: 'WELLS FARGO & COMPANY/MN',
  },
  {
    cik: '0001844454',
    value: '0001844454,WELLS FINANCIAL ADVISORS INC',
    name: 'WELLS FINANCIAL ADVISORS INC',
    label: 'WELLS FINANCIAL ADVISORS INC',
  },
  {
    cik: '0001811308',
    value: '0001811308,WELLSPRING FINANCIAL ADVISORS, LLC',
    name: 'WELLSPRING FINANCIAL ADVISORS, LLC',
    label: 'WELLSPRING FINANCIAL ADVISORS, LLC',
  },
  {
    cik: '0001737987',
    value: '0001737987,WELLS TRECASO FINANCIAL GROUP, LLC',
    name: 'WELLS TRECASO FINANCIAL GROUP, LLC',
    label: 'WELLS TRECASO FINANCIAL GROUP, LLC',
  },
  {
    cik: '0000878228',
    value: '0000878228,WENDELL DAVID ASSOCIATES INC',
    name: 'WENDELL DAVID ASSOCIATES INC',
    label: 'WENDELL DAVID ASSOCIATES INC',
  },
  {
    cik: '0000877134',
    value: '0000877134,WESBANCO BANK INC',
    name: 'WESBANCO BANK INC',
    label: 'WESBANCO BANK INC',
  },
  {
    cik: '0001667140',
    value: '0001667140,WESCAP MANAGEMENT GROUP, INC.',
    name: 'WESCAP MANAGEMENT GROUP, INC.',
    label: 'WESCAP MANAGEMENT GROUP, INC.',
  },
  {
    cik: '0001864880',
    value: '0001864880,WESCOTT FINANCIAL ADVISORY GROUP, LLC',
    name: 'WESCOTT FINANCIAL ADVISORY GROUP, LLC',
    label: 'WESCOTT FINANCIAL ADVISORY GROUP, LLC',
  },
  {
    cik: '0001773368',
    value: '0001773368,WESLEYAN ASSURANCE SOCIETY',
    name: 'WESLEYAN ASSURANCE SOCIETY',
    label: 'WESLEYAN ASSURANCE SOCIETY',
  },
  {
    cik: '0001279097',
    value: '0001279097,WESLEY CAPITAL MANAGEMENT, LLC',
    name: 'WESLEY CAPITAL MANAGEMENT, LLC',
    label: 'WESLEY CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001484540',
    value: '0001484540,WESPAC ADVISORS, LLC',
    name: 'WESPAC ADVISORS, LLC',
    label: 'WESPAC ADVISORS, LLC',
  },
  {
    cik: '0001668188',
    value: '0001668188,WESPAC ADVISORS SOCAL, LLC',
    name: 'WESPAC ADVISORS SOCAL, LLC',
    label: 'WESPAC ADVISORS SOCAL, LLC',
  },
  {
    cik: '0001166928',
    value: '0001166928,WEST BANCORPORATION INC',
    name: 'WEST BANCORPORATION INC',
    label: 'WEST BANCORPORATION INC',
  },
  {
    cik: '0001650092',
    value: '0001650092,WESTBOURNE INVESTMENT ADVISORS, INC.',
    name: 'WESTBOURNE INVESTMENT ADVISORS, INC.',
    label: 'WESTBOURNE INVESTMENT ADVISORS, INC.',
  },
  {
    cik: '0001738723',
    value: '0001738723,WEST BRANCH CAPITAL LLC',
    name: 'WEST BRANCH CAPITAL LLC',
    label: 'WEST BRANCH CAPITAL LLC',
  },
  {
    cik: '0001846644',
    value: '0001846644,WESTBRIDGE CAPITAL MANAGEMENT, LLC',
    name: 'WESTBRIDGE CAPITAL MANAGEMENT, LLC',
    label: 'WESTBRIDGE CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001102062',
    value: '0001102062,WEST CHESTER CAPITAL ADVISORS, INC',
    name: 'WEST CHESTER CAPITAL ADVISORS, INC',
    label: 'WEST CHESTER CAPITAL ADVISORS, INC',
  },
  {
    cik: '0001390063',
    value: '0001390063,WESTCHESTER CAPITAL MANAGEMENT, INC.',
    name: 'WESTCHESTER CAPITAL MANAGEMENT, INC.',
    label: 'WESTCHESTER CAPITAL MANAGEMENT, INC.',
  },
  {
    cik: '0001512805',
    value: '0001512805,WESTCHESTER CAPITAL MANAGEMENT, LLC',
    name: 'WESTCHESTER CAPITAL MANAGEMENT, LLC',
    label: 'WESTCHESTER CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001276537',
    value: '0001276537,WEST COAST ASSET MANAGEMENT INC',
    name: 'WEST COAST ASSET MANAGEMENT INC',
    label: 'WEST COAST ASSET MANAGEMENT INC',
  },
  {
    cik: '0001511985',
    value: '0001511985,WEST COAST FINANCIAL LLC',
    name: 'WEST COAST FINANCIAL LLC',
    label: 'WEST COAST FINANCIAL LLC',
  },
  {
    cik: '0001351731',
    value: '0001351731,WESTEND ADVISORS, LLC',
    name: 'WESTEND ADVISORS, LLC',
    label: 'WESTEND ADVISORS, LLC',
  },
  {
    cik: '0001331997',
    value: '0001331997,WESTEND CAPITAL MANAGEMENT LLC',
    name: 'WESTEND CAPITAL MANAGEMENT LLC',
    label: 'WESTEND CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001546172',
    value: '0001546172,WESTERLY CAPITAL MANAGEMENT, LLC',
    name: 'WESTERLY CAPITAL MANAGEMENT, LLC',
    label: 'WESTERLY CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001138897',
    value: '0001138897,WESTERN ASSET MANAGEMENT CO',
    name: 'WESTERN ASSET MANAGEMENT CO',
    label: 'WESTERN ASSET MANAGEMENT CO',
  },
  {
    cik: '0001138897',
    value: '0001138897,WESTERN ASSET MANAGEMENT COMPANY, LLC',
    name: 'WESTERN ASSET MANAGEMENT COMPANY, LLC',
    label: 'WESTERN ASSET MANAGEMENT COMPANY, LLC',
  },
  {
    cik: '0000106077',
    value: '0000106077,WESTERN FINANCIAL CORP',
    name: 'WESTERN FINANCIAL CORP',
    label: 'WESTERN FINANCIAL CORP',
  },
  {
    cik: '0001954480',
    value: '0001954480,WESTERN FINANCIAL CORP/CA',
    name: 'WESTERN FINANCIAL CORP/CA',
    label: 'WESTERN FINANCIAL CORP/CA',
  },
  {
    cik: '0000106077',
    value: '0000106077,WESTERN FINANCIAL CORPORATION',
    name: 'WESTERN FINANCIAL CORPORATION',
    label: 'WESTERN FINANCIAL CORPORATION',
  },
  {
    cik: '0001259392',
    value: '0001259392,WESTERN NATIONAL TRUST CO',
    name: 'WESTERN NATIONAL TRUST CO',
    label: 'WESTERN NATIONAL TRUST CO',
  },
  {
    cik: '0001907320',
    value: '0001907320,WESTERN PACIFIC WEALTH MANAGEMENT, LP',
    name: 'WESTERN PACIFIC WEALTH MANAGEMENT, LP',
    label: 'WESTERN PACIFIC WEALTH MANAGEMENT, LP',
  },
  {
    cik: '0001517666',
    value: '0001517666,WESTERN STANDARD LLC',
    name: 'WESTERN STANDARD LLC',
    label: 'WESTERN STANDARD LLC',
  },
  {
    cik: '0001767902',
    value: '0001767902,WESTERN WEALTH MANAGEMENT, LLC',
    name: 'WESTERN WEALTH MANAGEMENT, LLC',
    label: 'WESTERN WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001450746',
    value: '0001450746,WEST FACE CAPITAL INC.',
    name: 'WEST FACE CAPITAL INC.',
    label: 'WEST FACE CAPITAL INC.',
  },
  {
    cik: '0001568303',
    value: '0001568303,WEST FAMILY INVESTMENTS, INC.',
    name: 'WEST FAMILY INVESTMENTS, INC.',
    label: 'WEST FAMILY INVESTMENTS, INC.',
  },
  {
    cik: '0001177719',
    value: '0001177719,WESTFIELD CAPITAL MANAGEMENT CO LP',
    name: 'WESTFIELD CAPITAL MANAGEMENT CO LP',
    label: 'WESTFIELD CAPITAL MANAGEMENT CO LP',
  },
  {
    cik: '0001843684',
    value: '0001843684,WEST FINANCIAL ADVISORS, LLC',
    name: 'WEST FINANCIAL ADVISORS, LLC',
    label: 'WEST FINANCIAL ADVISORS, LLC',
  },
  {
    cik: '0001639997',
    value: '0001639997,WESTHAMPTON CAPITAL, LLC',
    name: 'WESTHAMPTON CAPITAL, LLC',
    label: 'WESTHAMPTON CAPITAL, LLC',
  },
  {
    cik: '0001759641',
    value: '0001759641,WESTHILL FINANCIAL ADVISORS, INC.',
    name: 'WESTHILL FINANCIAL ADVISORS, INC.',
    label: 'WESTHILL FINANCIAL ADVISORS, INC.',
  },
  {
    cik: '0001909879',
    value: '0001909879,WEST MICHIGAN ADVISORS, LLC',
    name: 'WEST MICHIGAN ADVISORS, LLC',
    label: 'WEST MICHIGAN ADVISORS, LLC',
  },
  {
    cik: '0001387399',
    value: '0001387399,WEST OAK CAPITAL, LLC',
    name: 'WEST OAK CAPITAL, LLC',
    label: 'WEST OAK CAPITAL, LLC',
  },
  {
    cik: '0001084469',
    value: '0001084469,WESTON CAPITAL MANAGEMENT INC',
    name: 'WESTON CAPITAL MANAGEMENT INC',
    label: 'WESTON CAPITAL MANAGEMENT INC',
  },
  {
    cik: '0001483467',
    value: '0001483467,WESTOVER CAPITAL ADVISORS, LLC',
    name: 'WESTOVER CAPITAL ADVISORS, LLC',
    label: 'WESTOVER CAPITAL ADVISORS, LLC',
  },
  {
    cik: '0000719245',
    value: '0000719245,WESTPAC BANKING CORP',
    name: 'WESTPAC BANKING CORP',
    label: 'WESTPAC BANKING CORP',
  },
  {
    cik: '0001674205',
    value: '0001674205,WESTPARK MANAGEMENT, LLC',
    name: 'WESTPARK MANAGEMENT, LLC',
    label: 'WESTPARK MANAGEMENT, LLC',
  },
  {
    cik: '0001092785',
    value: '0001092785,WESTPORT ADVISERS LLC',
    name: 'WESTPORT ADVISERS LLC',
    label: 'WESTPORT ADVISERS LLC',
  },
  {
    cik: '0000811454',
    value: '0000811454,WESTPORT ASSET MANAGEMENT INC',
    name: 'WESTPORT ASSET MANAGEMENT INC',
    label: 'WESTPORT ASSET MANAGEMENT INC',
  },
  {
    cik: '0000878313',
    value: '0000878313,WESTPORT RESOURCES MANAGEMENT INC',
    name: 'WESTPORT RESOURCES MANAGEMENT INC',
    label: 'WESTPORT RESOURCES MANAGEMENT INC',
  },
  {
    cik: '0001910585',
    value: '0001910585,WEST RIM CAPITAL ASSOCIATES II, L.P.',
    name: 'WEST RIM CAPITAL ASSOCIATES II, L.P.',
    label: 'WEST RIM CAPITAL ASSOCIATES II, L.P.',
  },
  {
    cik: '0001806366',
    value: '0001806366,WESTSHORE WEALTH, LLC',
    name: 'WESTSHORE WEALTH, LLC',
    label: 'WESTSHORE WEALTH, LLC',
  },
  {
    cik: '0001538383',
    value: '0001538383,WESTSIDE INVESTMENT MANAGEMENT, INC.',
    name: 'WESTSIDE INVESTMENT MANAGEMENT, INC.',
    label: 'WESTSIDE INVESTMENT MANAGEMENT, INC.',
  },
  {
    cik: '0001923591',
    value: '0001923591,WEST WEALTH GROUP, LLC',
    name: 'WEST WEALTH GROUP, LLC',
    label: 'WEST WEALTH GROUP, LLC',
  },
  {
    cik: '0001356513',
    value: '0001356513,WESTWOOD GLOBAL INVESTMENTS, LLC',
    name: 'WESTWOOD GLOBAL INVESTMENTS, LLC',
    label: 'WESTWOOD GLOBAL INVESTMENTS, LLC',
  },
  {
    cik: '0001165002',
    value: '0001165002,WESTWOOD HOLDINGS GROUP INC',
    name: 'WESTWOOD HOLDINGS GROUP INC',
    label: 'WESTWOOD HOLDINGS GROUP INC',
  },
  {
    cik: '0000912160',
    value: '0000912160,WESTWOOD MANAGEMENT CORP /IL/',
    name: 'WESTWOOD MANAGEMENT CORP /IL/',
    label: 'WESTWOOD MANAGEMENT CORP /IL/',
  },
  {
    cik: '0001861125',
    value: '0001861125,WESTWOOD WEALTH MANAGEMENT',
    name: 'WESTWOOD WEALTH MANAGEMENT',
    label: 'WESTWOOD WEALTH MANAGEMENT',
  },
  {
    cik: '0001279627',
    value: '0001279627,WETHERBY ASSET MANAGEMENT INC',
    name: 'WETHERBY ASSET MANAGEMENT INC',
    label: 'WETHERBY ASSET MANAGEMENT INC',
  },
  {
    cik: '0001048462',
    value: '0001048462,WEXFORD CAPITAL LP',
    name: 'WEXFORD CAPITAL LP',
    label: 'WEXFORD CAPITAL LP',
  },
  {
    cik: '0001280604',
    value: '0001280604,WEYBOSSET RESEARCH & MANAGEMENT LLC',
    name: 'WEYBOSSET RESEARCH & MANAGEMENT LLC',
    label: 'WEYBOSSET RESEARCH & MANAGEMENT LLC',
  },
  {
    cik: '0001539066',
    value: '0001539066,WEYLAND CAPITAL MANAGEMENT, LLC',
    name: 'WEYLAND CAPITAL MANAGEMENT, LLC',
    label: 'WEYLAND CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001821407',
    value: '0001821407,WFA OF SAN DIEGO, LLC',
    name: 'WFA OF SAN DIEGO, LLC',
    label: 'WFA OF SAN DIEGO, LLC',
  },
  {
    cik: '0001533892',
    value: '0001533892,WFG ADVISORS, LP',
    name: 'WFG ADVISORS, LP',
    label: 'WFG ADVISORS, LP',
  },
  {
    cik: '0001577001',
    value: '0001577001,W.G. SHAHEEN & ASSOCIATES DBA WHITNEY & CO',
    name: 'W.G. SHAHEEN & ASSOCIATES DBA WHITNEY & CO',
    label: 'W.G. SHAHEEN & ASSOCIATES DBA WHITNEY & CO',
  },
  {
    cik: '0001387322',
    value: '0001387322,WHALE ROCK CAPITAL MANAGEMENT LLC',
    name: 'WHALE ROCK CAPITAL MANAGEMENT LLC',
    label: 'WHALE ROCK CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001389709',
    value: '0001389709,WHALEROCK POINT PARTNERS, LLC',
    name: 'WHALEROCK POINT PARTNERS, LLC',
    label: 'WHALEROCK POINT PARTNERS, LLC',
  },
  {
    cik: '0001513126',
    value: '0001513126,WHARTON BUSINESS GROUP, LLC',
    name: 'WHARTON BUSINESS GROUP, LLC',
    label: 'WHARTON BUSINESS GROUP, LLC',
  },
  {
    cik: '0001940823',
    value: '0001940823,W.H. CORNERSTONE INVESTMENTS INC.',
    name: 'W.H. CORNERSTONE INVESTMENTS INC.',
    label: 'W.H. CORNERSTONE INVESTMENTS INC.',
  },
  {
    cik: '0001603470',
    value: '0001603470,WHEATLAND ADVISORS INC',
    name: 'WHEATLAND ADVISORS INC',
    label: 'WHEATLAND ADVISORS INC',
  },
  {
    cik: '0001894921',
    value: '0001894921,WHELAN FINANCIAL',
    name: 'WHELAN FINANCIAL',
    label: 'WHELAN FINANCIAL',
  },
  {
    cik: '0001607278',
    value: '0001607278,WHETSTONE CAPITAL ADVISORS, LLC',
    name: 'WHETSTONE CAPITAL ADVISORS, LLC',
    label: 'WHETSTONE CAPITAL ADVISORS, LLC',
  },
  {
    cik: '0001197259',
    value: '0001197259,WHIPPOORWILL ASSOCIATES, INC.',
    name: 'WHIPPOORWILL ASSOCIATES, INC.',
    label: 'WHIPPOORWILL ASSOCIATES, INC.',
  },
  {
    cik: '0001813577',
    value: '0001813577,WHITCOMB & HESS, INC.',
    name: 'WHITCOMB & HESS, INC.',
    label: 'WHITCOMB & HESS, INC.',
  },
  {
    cik: '0001531593',
    value: '0001531593,WHITE BAY PT LLC',
    name: 'WHITE BAY PT LLC',
    label: 'WHITE BAY PT LLC',
  },
  {
    cik: '0001257391',
    value: '0001257391,WHITEBOX ADVISORS LLC',
    name: 'WHITEBOX ADVISORS LLC',
    label: 'WHITEBOX ADVISORS LLC',
  },
  {
    cik: '0001425294',
    value: '0001425294,WHITE ELM CAPITAL, LLC',
    name: 'WHITE ELM CAPITAL, LLC',
    label: 'WHITE ELM CAPITAL, LLC',
  },
  {
    cik: '0001884931',
    value: '0001884931,WHITEFORT CAPITAL MANAGEMENT, LP',
    name: 'WHITEFORT CAPITAL MANAGEMENT, LP',
    label: 'WHITEFORT CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0001667102',
    value: '0001667102,WHITEGATE INVESTMENT COUNSELORS, INC.',
    name: 'WHITEGATE INVESTMENT COUNSELORS, INC.',
    label: 'WHITEGATE INVESTMENT COUNSELORS, INC.',
  },
  {
    cik: '0001767910',
    value: '0001767910,WHITEHELM CAPITAL PTY LTD',
    name: 'WHITEHELM CAPITAL PTY LTD',
    label: 'WHITEHELM CAPITAL PTY LTD',
  },
  {
    cik: '0001904677',
    value: '0001904677,WHITE KNIGHT STRATEGIC WEALTH ADVISORS, LLC',
    name: 'WHITE KNIGHT STRATEGIC WEALTH ADVISORS, LLC',
    label: 'WHITE KNIGHT STRATEGIC WEALTH ADVISORS, LLC',
  },
  {
    cik: '0001727593',
    value: '0001727593,WHITE LIGHTHOUSE INVESTMENT MANAGEMENT INC.',
    name: 'WHITE LIGHTHOUSE INVESTMENT MANAGEMENT INC.',
    label: 'WHITE LIGHTHOUSE INVESTMENT MANAGEMENT INC.',
  },
  {
    cik: '0001253840',
    value: '0001253840,WHITE MOUNTAINS ADVISORS LLC',
    name: 'WHITE MOUNTAINS ADVISORS LLC',
    label: 'WHITE MOUNTAINS ADVISORS LLC',
  },
  {
    cik: '0000776867',
    value: '0000776867,WHITE MOUNTAINS INSURANCE GROUP LTD',
    name: 'WHITE MOUNTAINS INSURANCE GROUP LTD',
    label: 'WHITE MOUNTAINS INSURANCE GROUP LTD',
  },
  {
    cik: '0000825217',
    value: '0000825217,WHITENER CAPITAL MANAGEMENT, INC.',
    name: 'WHITENER CAPITAL MANAGEMENT, INC.',
    label: 'WHITENER CAPITAL MANAGEMENT, INC.',
  },
  {
    cik: '0001137429',
    value: '0001137429,WHITE PINE CAPITAL LLC',
    name: 'WHITE PINE CAPITAL LLC',
    label: 'WHITE PINE CAPITAL LLC',
  },
  {
    cik: '0001318259',
    value: '0001318259,WHITE PINE INVESTMENT CO',
    name: 'WHITE PINE INVESTMENT CO',
    label: 'WHITE PINE INVESTMENT CO',
  },
  {
    cik: '0001425362',
    value: '0001425362,WHITE RIVER INVESTMENT PARTNERS, LLC',
    name: 'WHITE RIVER INVESTMENT PARTNERS, LLC',
    label: 'WHITE RIVER INVESTMENT PARTNERS, LLC',
  },
  {
    cik: '0001642729',
    value: '0001642729,WHITE SQUARE CAPITAL LLP',
    name: 'WHITE SQUARE CAPITAL LLP',
    label: 'WHITE SQUARE CAPITAL LLP',
  },
  {
    cik: '0001569173',
    value: '0001569173,WHITNELL & CO.',
    name: 'WHITNELL & CO.',
    label: 'WHITNELL & CO.',
  },
  {
    cik: '0001137881',
    value: '0001137881,WHITTIER TRUST CO',
    name: 'WHITTIER TRUST CO',
    label: 'WHITTIER TRUST CO',
  },
  {
    cik: '0001263548',
    value: '0001263548,WHITTIER TRUST CO OF NEVADA INC',
    name: 'WHITTIER TRUST CO OF NEVADA INC',
    label: 'WHITTIER TRUST CO OF NEVADA INC',
  },
  {
    cik: '0000105692',
    value: '0000105692,WHV INVESTMENT MANAGEMENT, INC.',
    name: 'WHV INVESTMENT MANAGEMENT, INC.',
    label: 'WHV INVESTMENT MANAGEMENT, INC.',
  },
  {
    cik: '0001904388',
    value: '0001904388,WICK CAPITAL PARTNERS, LLC',
    name: 'WICK CAPITAL PARTNERS, LLC',
    label: 'WICK CAPITAL PARTNERS, LLC',
  },
  {
    cik: '0001906640',
    value: '0001906640,WIDMANN FINANCIAL SERVICES, INC.',
    name: 'WIDMANN FINANCIAL SERVICES, INC.',
    label: 'WIDMANN FINANCIAL SERVICES, INC.',
  },
  {
    cik: '0001007399',
    value: '0001007399,WILBANKS SMITH & THOMAS ASSET MANAGEMENT LLC',
    name: 'WILBANKS SMITH & THOMAS ASSET MANAGEMENT LLC',
    label: 'WILBANKS SMITH & THOMAS ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001582384',
    value: '0001582384,WILDCAT CAPITAL MANAGEMENT, LLC',
    name: 'WILDCAT CAPITAL MANAGEMENT, LLC',
    label: 'WILDCAT CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0000902614',
    value: '0000902614,WILEN INVESTMENT MANAGEMENT CORP.',
    name: 'WILEN INVESTMENT MANAGEMENT CORP.',
    label: 'WILEN INVESTMENT MANAGEMENT CORP.',
  },
  {
    cik: '0000107136',
    value: '0000107136,WILEY BROS.-AINTREE CAPITAL, LLC',
    name: 'WILEY BROS.-AINTREE CAPITAL, LLC',
    label: 'WILEY BROS.-AINTREE CAPITAL, LLC',
  },
  {
    cik: '0001276853',
    value: '0001276853,WILKINS INVESTMENT COUNSEL INC',
    name: 'WILKINS INVESTMENT COUNSEL INC',
    label: 'WILKINS INVESTMENT COUNSEL INC',
  },
  {
    cik: '0001764000',
    value: '0001764000,WILKINSON GLOBAL ASSET MANAGEMENT LLC',
    name: 'WILKINSON GLOBAL ASSET MANAGEMENT LLC',
    label: 'WILKINSON GLOBAL ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001662888',
    value: '0001662888,WILKS BROTHERS, LLC',
    name: 'WILKS BROTHERS, LLC',
    label: 'WILKS BROTHERS, LLC',
  },
  {
    cik: '0001509379',
    value: '0001509379,WILLETT ADVISORS LLC',
    name: 'WILLETT ADVISORS LLC',
    label: 'WILLETT ADVISORS LLC',
  },
  {
    cik: '0001910961',
    value: '0001910961,WILLIAM ALLAN CORP',
    name: 'WILLIAM ALLAN CORP',
    label: 'WILLIAM ALLAN CORP',
  },
  {
    cik: '0001910961',
    value: '0001910961,WILLIAM ALLAN, LLC',
    name: 'WILLIAM ALLAN, LLC',
    label: 'WILLIAM ALLAN, LLC',
  },
  {
    cik: '0001644956',
    value: '0001644956,WILLIAM BLAIR INVESTMENT MANAGEMENT, LLC',
    name: 'WILLIAM BLAIR INVESTMENT MANAGEMENT, LLC',
    label: 'WILLIAM BLAIR INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0000928204',
    value: '0000928204,WILLIAM HARRIS INVESTORS INC',
    name: 'WILLIAM HARRIS INVESTORS INC',
    label: 'WILLIAM HARRIS INVESTORS INC',
  },
  {
    cik: '0001674705',
    value: '0001674705,WILLIAM MARSH RICE UNIVERSITY',
    name: 'WILLIAM MARSH RICE UNIVERSITY',
    label: 'WILLIAM MARSH RICE UNIVERSITY',
  },
  {
    cik: '0001846923',
    value: '0001846923,WILLIAMS FINANCIAL, LLC',
    name: 'WILLIAMS FINANCIAL, LLC',
    label: 'WILLIAMS FINANCIAL, LLC',
  },
  {
    cik: '0000949743',
    value: '0000949743,WILLIAMS JONES & ASSOCIATES LLC',
    name: 'WILLIAMS JONES & ASSOCIATES LLC',
    label: 'WILLIAMS JONES & ASSOCIATES LLC',
  },
  {
    cik: '0001788587',
    value: '0001788587,WILLIAMS JONES WEALTH MANAGEMENT, LLC.',
    name: 'WILLIAMS JONES WEALTH MANAGEMENT, LLC.',
    label: 'WILLIAMS JONES WEALTH MANAGEMENT, LLC.',
  },
  {
    cik: '0001577773',
    value: '0001577773,WILLINGDON WEALTH MANAGEMENT',
    name: 'WILLINGDON WEALTH MANAGEMENT',
    label: 'WILLINGDON WEALTH MANAGEMENT',
  },
  {
    cik: '0001310929',
    value: '0001310929,WILLIS INVESTMENT COUNSEL',
    name: 'WILLIS INVESTMENT COUNSEL',
    label: 'WILLIS INVESTMENT COUNSEL',
  },
  {
    cik: '0001853239',
    value: '0001853239,WILLIS JOHNSON & ASSOCIATES, INC.',
    name: 'WILLIS JOHNSON & ASSOCIATES, INC.',
    label: 'WILLIS JOHNSON & ASSOCIATES, INC.',
  },
  {
    cik: '0001872538',
    value: '0001872,WIL, LLC8',
    name: 'WIL, LLC',
    label: 'WIL, LLC',
  },
  {
    cik: '0001927796',
    value: '0001927796,WILLNER & HELLER, LLC',
    name: 'WILLNER & HELLER, LLC',
    label: 'WILLNER & HELLER, LLC',
  },
  {
    cik: '0001806816',
    value: '0001806816,WILLOUGHBY CAPITAL HOLDINGS, LLC',
    name: 'WILLOUGHBY CAPITAL HOLDINGS, LLC',
    label: 'WILLOUGHBY CAPITAL HOLDINGS, LLC',
  },
  {
    cik: '0001632407',
    value: '0001632407,WILLOW CREEK WEALTH MANAGEMENT INC.',
    name: 'WILLOW CREEK WEALTH MANAGEMENT INC.',
    label: 'WILLOW CREEK WEALTH MANAGEMENT INC.',
  },
  {
    cik: '0001512420',
    value: '0001512420,WILLOW STREET ADVISORS LLC',
    name: 'WILLOW STREET ADVISORS LLC',
    label: 'WILLOW STREET ADVISORS LLC',
  },
  {
    cik: '0001525109',
    value: '0001525109,WILLS FINANCIAL GROUP, INC.',
    name: 'WILLS FINANCIAL GROUP, INC.',
    label: 'WILLS FINANCIAL GROUP, INC.',
  },
  {
    cik: '0001056357',
    value: '0001056357,WILMINGTON FUNDS MANAGEMENT CORP',
    name: 'WILMINGTON FUNDS MANAGEMENT CORP',
    label: 'WILMINGTON FUNDS MANAGEMENT CORP',
  },
  {
    cik: '0000905571',
    value: '0000905571,WILMINGTON TRUST CO',
    name: 'WILMINGTON TRUST CO',
    label: 'WILMINGTON TRUST CO',
  },
  {
    cik: '0001418288',
    value: '0001418288,WILMINGTON TRUST INVESTMENT MANAGEMENT, LLC',
    name: 'WILMINGTON TRUST INVESTMENT MANAGEMENT, LLC',
    label: 'WILMINGTON TRUST INVESTMENT MANAGEMENT, LLC',
  },
  {
    cik: '0001537825',
    value: '0001537825,WILMINGTON TRUST, NATIONAL ASSOCIATION',
    name: 'WILMINGTON TRUST, NATIONAL ASSOCIATION',
    label: 'WILMINGTON TRUST, NATIONAL ASSOCIATION',
  },
  {
    cik: '0001598379',
    value: '0001598379,WILSEY ASSET MANAGEMENT INC',
    name: 'WILSEY ASSET MANAGEMENT INC',
    label: 'WILSEY ASSET MANAGEMENT INC',
  },
  {
    cik: '0001616628',
    value: '0001616628,WILSHIRE ADVISORS LLC',
    name: 'WILSHIRE ADVISORS LLC',
    label: 'WILSHIRE ADVISORS LLC',
  },
  {
    cik: '0001914472',
    value: '0001914472,WILSON ASSET MANAGEMENT (INTERNATIONAL) PTY LTD',
    name: 'WILSON ASSET MANAGEMENT (INTERNATIONAL) PTY LTD',
    label: 'WILSON ASSET MANAGEMENT (INTERNATIONAL) PTY LTD',
  },
  {
    cik: '0001903579',
    value: '0001903579,WILSON & BOUCHER CAPITAL MANAGEMENT, LLC',
    name: 'WILSON & BOUCHER CAPITAL MANAGEMENT, LLC',
    label: 'WILSON & BOUCHER CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001529425',
    value: '0001529425,WILSON, KEMP & ASSOCIATES, INC.',
    name: 'WILSON, KEMP & ASSOCIATES, INC.',
    label: 'WILSON, KEMP & ASSOCIATES, INC.',
  },
  {
    cik: '0001724610',
    value: '0001724610,WIM ASSET MANAGEMENT (HK) LTD',
    name: 'WIM ASSET MANAGEMENT (HK) LTD',
    label: 'WIM ASSET MANAGEMENT (HK) LTD',
  },
  {
    cik: '0001667019',
    value: '0001667019,WIMMER ASSOCIATES 1, LLC',
    name: 'WIMMER ASSOCIATES 1, LLC',
    label: 'WIMMER ASSOCIATES 1, LLC',
  },
  {
    cik: '0001897144',
    value: '0001897144,WIN ADVISORS, INC',
    name: 'WIN ADVISORS, INC',
    label: 'WIN ADVISORS, INC',
  },
  {
    cik: '0001585822',
    value: '0001585822,WINCH ADVISORY SERVICES, LLC',
    name: 'WINCH ADVISORY SERVICES, LLC',
    label: 'WINCH ADVISORY SERVICES, LLC',
  },
  {
    cik: '0001599383',
    value: '0001599383,WINDACRE PARTNERSHIP LLC',
    name: 'WINDACRE PARTNERSHIP LLC',
    label: 'WINDACRE PARTNERSHIP LLC',
  },
  {
    cik: '0001687953',
    value: '0001687953,WINDER INVESTMENT PTE LTD',
    name: 'WINDER INVESTMENT PTE LTD',
    label: 'WINDER INVESTMENT PTE LTD',
  },
  {
    cik: '0001512415',
    value: '0001512415,WINDHAM CAPITAL MANAGEMENT, LLC',
    name: 'WINDHAM CAPITAL MANAGEMENT, LLC',
    label: 'WINDHAM CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001507640',
    value: '0001507640,WINDHAVEN INVESTMENT MANAGEMENT, INC.',
    name: 'WINDHAVEN INVESTMENT MANAGEMENT, INC.',
    label: 'WINDHAVEN INVESTMENT MANAGEMENT, INC.',
  },
  {
    cik: '0001831132',
    value: '0001831132,WINDMILL HILL ASSET MANAGEMENT LTD',
    name: 'WINDMILL HILL ASSET MANAGEMENT LTD',
    label: 'WINDMILL HILL ASSET MANAGEMENT LTD',
  },
  {
    cik: '0001657703',
    value: '0001657703,WIND RIVER TRUST CO',
    name: 'WIND RIVER TRUST CO',
    label: 'WIND RIVER TRUST CO',
  },
  {
    cik: '0001543100',
    value: '0001543100,WINDSOR CAPITAL MANAGEMENT, LLC',
    name: 'WINDSOR CAPITAL MANAGEMENT, LLC',
    label: 'WINDSOR CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001766553',
    value: '0001766553,WINDSOR CREEK ADVISORS, LLC',
    name: 'WINDSOR CREEK ADVISORS, LLC',
    label: 'WINDSOR CREEK ADVISORS, LLC',
  },
  {
    cik: '0001132356',
    value: '0001132356,WINDSOR FINANCIAL GROUP LLC',
    name: 'WINDSOR FINANCIAL GROUP LLC',
    label: 'WINDSOR FINANCIAL GROUP LLC',
  },
  {
    cik: '0001731713',
    value: '0001731713,WINDSOR GROUP LTD',
    name: 'WINDSOR GROUP LTD',
    label: 'WINDSOR GROUP LTD',
  },
  {
    cik: '0001078635',
    value: '0001078635,WINDWARD CAPITAL MANAGEMENT CO /CA',
    name: 'WINDWARD CAPITAL MANAGEMENT CO /CA',
    label: 'WINDWARD CAPITAL MANAGEMENT CO /CA',
  },
  {
    cik: '0001835866',
    value: '0001835866,WINDWARD PRIVATE WEALTH MANAGEMENT INC.',
    name: 'WINDWARD PRIVATE WEALTH MANAGEMENT INC.',
    label: 'WINDWARD PRIVATE WEALTH MANAGEMENT INC.',
  },
  {
    cik: '0001569655',
    value: '0001569655,WINFIELD ASSOCIATES, INC.',
    name: 'WINFIELD ASSOCIATES, INC.',
    label: 'WINFIELD ASSOCIATES, INC.',
  },
  {
    cik: '0001599573',
    value: '0001599573,WING LAKE CAPITAL, LLC',
    name: 'WING LAKE CAPITAL, LLC',
    label: 'WING LAKE CAPITAL, LLC',
  },
  {
    cik: '0001599886',
    value: '0001599886,WINGSPAN INVESTMENT MANAGEMENT, LP',
    name: 'WINGSPAN INVESTMENT MANAGEMENT, LP',
    label: 'WINGSPAN INVESTMENT MANAGEMENT, LP',
  },
  {
    cik: '0000052234',
    value: '0000052234,WINMILL & CO. INC',
    name: 'WINMILL & CO. INC',
    label: 'WINMILL & CO. INC',
  },
  {
    cik: '0001793399',
    value: '0001793399,WINNING POINTS ADVISORS, LLC',
    name: 'WINNING POINTS ADVISORS, LLC',
    label: 'WINNING POINTS ADVISORS, LLC',
  },
  {
    cik: '0001286534',
    value: '0001286534,WINSLOW ASSET MANAGEMENT INC',
    name: 'WINSLOW ASSET MANAGEMENT INC',
    label: 'WINSLOW ASSET MANAGEMENT INC',
  },
  {
    cik: '0000900973',
    value: '0000900973,WINSLOW CAPITAL MANAGEMENT, LLC',
    name: 'WINSLOW CAPITAL MANAGEMENT, LLC',
    label: 'WINSLOW CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001597409',
    value: '0001597409,WINSLOW, EVANS & CROCKER, INC.',
    name: 'WINSLOW, EVANS & CROCKER, INC.',
    label: 'WINSLOW, EVANS & CROCKER, INC.',
  },
  {
    cik: '0001360079',
    value: '0001360079,WINTERGREEN ADVISERS, LLC',
    name: 'WINTERGREEN ADVISERS, LLC',
    label: 'WINTERGREEN ADVISERS, LLC',
  },
  {
    cik: '0001739043',
    value: '0001739043,WINTHROP ADVISORY GROUP LLC',
    name: 'WINTHROP ADVISORY GROUP LLC',
    label: 'WINTHROP ADVISORY GROUP LLC',
  },
  {
    cik: '0001734109',
    value: '0001734109,WINTHROP PARTNERS - WNY, LLC',
    name: 'WINTHROP PARTNERS - WNY, LLC',
    label: 'WINTHROP PARTNERS - WNY, LLC',
  },
  {
    cik: '0001612063',
    value: '0001612063,WINTON CAPITAL GROUP LTD',
    name: 'WINTON CAPITAL GROUP LTD',
    label: 'WINTON CAPITAL GROUP LTD',
  },
  {
    cik: '0001456098',
    value: '0001456098,WINTON CAPITAL MANAGEMENT LTD',
    name: 'WINTON CAPITAL MANAGEMENT LTD',
    label: 'WINTON CAPITAL MANAGEMENT LTD',
  },
  {
    cik: '0001612063',
    value: '0001612063,WINTON GROUP LTD',
    name: 'WINTON GROUP LTD',
    label: 'WINTON GROUP LTD',
  },
  {
    cik: '0000049096',
    value: '0000049096,WINTRUST INVESTMENTS LLC',
    name: 'WINTRUST INVESTMENTS LLC',
    label: 'WINTRUST INVESTMENTS LLC',
  },
  {
    cik: '0001830600',
    value: '0001830600,WIPFLI FINANCIAL ADVISORS LLC,',
    name: 'WIPFLI FINANCIAL ADVISORS LLC,',
    label: 'WIPFLI FINANCIAL ADVISORS LLC,',
  },
  {
    cik: '0000926833',
    value: '0000926833,WISCONSIN CAPITAL MANAGEMENT LLC',
    name: 'WISCONSIN CAPITAL MANAGEMENT LLC',
    label: 'WISCONSIN CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001573263',
    value: '0001573263,WISER WEALTH MANAGEMENT, INC',
    name: 'WISER WEALTH MANAGEMENT, INC',
    label: 'WISER WEALTH MANAGEMENT, INC',
  },
  {
    cik: '0001777734',
    value: '0001777734,WISHBONE MANAGEMENT, LP',
    name: 'WISHBONE MANAGEMENT, LP',
    label: 'WISHBONE MANAGEMENT, LP',
  },
  {
    cik: '0001840025',
    value: '0001840,WIT, LLC5',
    name: 'WIT, LLC',
    label: 'WIT, LLC',
  },
  {
    cik: '0001697790',
    value: '0001697790,WITTENBERG INVESTMENT MANAGEMENT, INC.',
    name: 'WITTENBERG INVESTMENT MANAGEMENT, INC.',
    label: 'WITTENBERG INVESTMENT MANAGEMENT, INC.',
  },
  {
    cik: '0001727642',
    value: '0001727642,WJ INTERESTS, LLC',
    name: 'WJ INTERESTS, LLC',
    label: 'WJ INTERESTS, LLC',
  },
  {
    cik: '0001801674',
    value: '0001801674,WJ WEALTH MANAGEMENT, LLC',
    name: 'WJ WEALTH MANAGEMENT, LLC',
    label: 'WJ WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001202045',
    value: '0001202045,WL ROSS & CO LLC',
    name: 'WL ROSS & CO LLC',
    label: 'WL ROSS & CO LLC',
  },
  {
    cik: '0001906711',
    value: '0001906711,WMG FINANCIAL ADVISORS, LLC',
    name: 'WMG FINANCIAL ADVISORS, LLC',
    label: 'WMG FINANCIAL ADVISORS, LLC',
  },
  {
    cik: '0001480916',
    value: '0001480916,WMS PARTNERS, LLC',
    name: 'WMS PARTNERS, LLC',
    label: 'WMS PARTNERS, LLC',
  },
  {
    cik: '0001803296',
    value: '0001803296,WNY ASSET MANAGEMENT, LLC',
    name: 'WNY ASSET MANAGEMENT, LLC',
    label: 'WNY ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001767940',
    value: '0001767940,WOLFF FINANCIAL MANAGEMENT LLC',
    name: 'WOLFF FINANCIAL MANAGEMENT LLC',
    label: 'WOLFF FINANCIAL MANAGEMENT LLC',
  },
  {
    cik: '0001832097',
    value: '0001832097,WOLFF WIESE MAGANA LLC',
    name: 'WOLFF WIESE MAGANA LLC',
    label: 'WOLFF WIESE MAGANA LLC',
  },
  {
    cik: '0001579111',
    value: '0001579111,WOLF GROUP CAPITAL ADVISORS',
    name: 'WOLF GROUP CAPITAL ADVISORS',
    label: 'WOLF GROUP CAPITAL ADVISORS',
  },
  {
    cik: '0001785988',
    value: '0001785988,WOLF HILL CAPITAL MANAGEMENT, LP',
    name: 'WOLF HILL CAPITAL MANAGEMENT, LP',
    label: 'WOLF HILL CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0001835700',
    value: '0001835700,WOLFSWOOD HOLDINGS, LLC',
    name: 'WOLFSWOOD HOLDINGS, LLC',
    label: 'WOLFSWOOD HOLDINGS, LLC',
  },
  {
    cik: '0001279891',
    value: '0001279891,WOLVERINE ASSET MANAGEMENT LLC',
    name: 'WOLVERINE ASSET MANAGEMENT LLC',
    label: 'WOLVERINE ASSET MANAGEMENT LLC',
  },
  {
    cik: '0000927337',
    value: '0000927337,WOLVERINE TRADING, LLC',
    name: 'WOLVERINE TRADING, LLC',
    label: 'WOLVERINE TRADING, LLC',
  },
  {
    cik: '0000771118',
    value: '0000771118,WOODARD & CO ASSET MANAGEMENT GROUP INC /ADV',
    name: 'WOODARD & CO ASSET MANAGEMENT GROUP INC /ADV',
    label: 'WOODARD & CO ASSET MANAGEMENT GROUP INC /ADV',
  },
  {
    cik: '0001100916',
    value: '0001100916,WOOD ASSET MANAGEMENT INC',
    name: 'WOOD ASSET MANAGEMENT INC',
    label: 'WOOD ASSET MANAGEMENT INC',
  },
  {
    cik: '0001511723',
    value: '0001511723,WOODBINE CAPITAL ADVISORS LP',
    name: 'WOODBINE CAPITAL ADVISORS LP',
    label: 'WOODBINE CAPITAL ADVISORS LP',
  },
  {
    cik: '0001634557',
    value: '0001634557,WOODFORD INVESTMENT MANAGEMENT LLP',
    name: 'WOODFORD INVESTMENT MANAGEMENT LLP',
    label: 'WOODFORD INVESTMENT MANAGEMENT LLP',
  },
  {
    cik: '0001569566',
    value: '0001569566,WOOD INVESTMENT COUNSEL LLC',
    name: 'WOOD INVESTMENT COUNSEL LLC',
    label: 'WOOD INVESTMENT COUNSEL LLC',
  },
  {
    cik: '0001165805',
    value: '0001165805,WOODLEY FARRA MANION PORTFOLIO MANAGEMENT INC',
    name: 'WOODLEY FARRA MANION PORTFOLIO MANAGEMENT INC',
    label: 'WOODLEY FARRA MANION PORTFOLIO MANAGEMENT INC',
  },
  {
    cik: '0001784547',
    value: '0001784547,WOODLINE PARTNERS LP',
    name: 'WOODLINE PARTNERS LP',
    label: 'WOODLINE PARTNERS LP',
  },
  {
    cik: '0001133653',
    value: '0001133653,WOODMONT INVESTMENT COUNSEL LLC',
    name: 'WOODMONT INVESTMENT COUNSEL LLC',
    label: 'WOODMONT INVESTMENT COUNSEL LLC',
  },
  {
    cik: '0001697848',
    value: '0001697848,WOODSON CAPITAL MANAGEMENT, LP',
    name: 'WOODSON CAPITAL MANAGEMENT, LP',
    label: 'WOODSON CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0001008877',
    value: '0001008877,WOODSTOCK CORP',
    name: 'WOODSTOCK CORP',
    label: 'WOODSTOCK CORP',
  },
  {
    cik: '0001725247',
    value: '0001725247,WOODTRUST FINANCIAL CORP',
    name: 'WOODTRUST FINANCIAL CORP',
    label: 'WOODTRUST FINANCIAL CORP',
  },
  {
    cik: '0001846177',
    value: '0001846177,WOODWARD DIVERSIFIED CAPITAL, LLC',
    name: 'WOODWARD DIVERSIFIED CAPITAL, LLC',
    label: 'WOODWARD DIVERSIFIED CAPITAL, LLC',
  },
  {
    cik: '0001566968',
    value: '0001566968,WOOSTER CORTHELL WEALTH MANAGEMENT, INC.',
    name: 'WOOSTER CORTHELL WEALTH MANAGEMENT, INC.',
    label: 'WOOSTER CORTHELL WEALTH MANAGEMENT, INC.',
  },
  {
    cik: '0001807878',
    value: '0001807878,WORKING CAPITAL ADVISORS (UK) LTD.',
    name: 'WORKING CAPITAL ADVISORS (UK) LTD.',
    label: 'WORKING CAPITAL ADVISORS (UK) LTD.',
  },
  {
    cik: '0001389215',
    value: '0001389215,WORLD ASSET MANAGEMENT INC',
    name: 'WORLD ASSET MANAGEMENT INC',
    label: 'WORLD ASSET MANAGEMENT INC',
  },
  {
    cik: '0001745981',
    value: '0001745981,WORLDQUANT MILLENNIUM ADVISORS LLC',
    name: 'WORLDQUANT MILLENNIUM ADVISORS LLC',
    label: 'WORLDQUANT MILLENNIUM ADVISORS LLC',
  },
  {
    cik: '0001680843',
    value: '0001680843,WORM CAPITAL, LLC',
    name: 'WORM CAPITAL, LLC',
    label: 'WORM CAPITAL, LLC',
  },
  {
    cik: '0001927285',
    value: '0001927285,WORTH ASSET MANAGEMENT, LLC',
    name: 'WORTH ASSET MANAGEMENT, LLC',
    label: 'WORTH ASSET MANAGEMENT, LLC',
  },
  {
    cik: '0001921448',
    value: '0001921448,WORTHPOINTE, LLC',
    name: 'WORTHPOINTE, LLC',
    label: 'WORTHPOINTE, LLC',
  },
  {
    cik: '0001696778',
    value: '0001696778,WORTH VENTURE PARTNERS, LLC',
    name: 'WORTH VENTURE PARTNERS, LLC',
    label: 'WORTH VENTURE PARTNERS, LLC',
  },
  {
    cik: '0001697796',
    value: '0001697796,WP ADVISORS, LLC',
    name: 'WP ADVISORS, LLC',
    label: 'WP ADVISORS, LLC',
  },
  {
    cik: '0001266014',
    value: '0001266014,WRAPMANAGER INC',
    name: 'WRAPMANAGER INC',
    label: 'WRAPMANAGER INC',
  },
  {
    cik: '0001538052',
    value: '0001538052,WRIGHT FUND MANAGEMENT, LLC',
    name: 'WRIGHT FUND MANAGEMENT, LLC',
    label: 'WRIGHT FUND MANAGEMENT, LLC',
  },
  {
    cik: '0001538052',
    value: '0001538052,WRIGHT FUND MANAGMENT, LLC',
    name: 'WRIGHT FUND MANAGMENT, LLC',
    label: 'WRIGHT FUND MANAGMENT, LLC',
  },
  {
    cik: '0000108572',
    value: '0000108572,WRIGHT INVESTORS SERVICE INC',
    name: 'WRIGHT INVESTORS SERVICE INC',
    label: 'WRIGHT INVESTORS SERVICE INC',
  },
  {
    cik: '0001700481',
    value: '0001700481,WSFS CAPITAL MANAGEMENT, LLC',
    name: 'WSFS CAPITAL MANAGEMENT, LLC',
    label: 'WSFS CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0000877338',
    value: '0000877338,WS MANAGEMENT LLLP',
    name: 'WS MANAGEMENT LLLP',
    label: 'WS MANAGEMENT LLLP',
  },
  {
    cik: '0001780365',
    value: '0001780365,WT ASSET MANAGEMENT LTD',
    name: 'WT ASSET MANAGEMENT LTD',
    label: 'WT ASSET MANAGEMENT LTD',
  },
  {
    cik: '0001780507',
    value: '0001780507,WT WEALTH MANAGEMENT',
    name: 'WT WEALTH MANAGEMENT',
    label: 'WT WEALTH MANAGEMENT',
  },
  {
    cik: '0000108634',
    value: '0000108634,WULFF, HANSEN & CO.',
    name: 'WULFF, HANSEN & CO.',
    label: 'WULFF, HANSEN & CO.',
  },
  {
    cik: '0001599273',
    value: '0001599273,WUNDERLICH CAPITAL MANAGEMNT',
    name: 'WUNDERLICH CAPITAL MANAGEMNT',
    label: 'WUNDERLICH CAPITAL MANAGEMNT',
  },
  {
    cik: '0001599892',
    value: '0001599892,WUNDERLICH SECURITIES, INC.',
    name: 'WUNDERLICH SECURITIES, INC.',
    label: 'WUNDERLICH SECURITIES, INC.',
  },
  {
    cik: '0001251567',
    value: '0001251567,WYNNEFIELD CAPITAL INC',
    name: 'WYNNEFIELD CAPITAL INC',
    label: 'WYNNEFIELD CAPITAL INC',
  },
  {
    cik: '0001677440',
    value: '0001677440,XACT KAPITALFORVALTNING AB',
    name: 'XACT KAPITALFORVALTNING AB',
    label: 'XACT KAPITALFORVALTNING AB',
  },
  {
    cik: '0001791002',
    value: '0001791002,XCEL WEALTH MANAGEMENT, LLC',
    name: 'XCEL WEALTH MANAGEMENT, LLC',
    label: 'XCEL WEALTH MANAGEMENT, LLC',
  },
  {
    cik: '0001910318',
    value:
      '0001910318,XIAMEN XINWEIDACHUANG INVESTMENT PARTNERSHIP (LIMITED PARTNERSHIP)',
    name: 'XIAMEN XINWEIDACHUANG INVESTMENT PARTNERSHIP (LIMITED PARTNERSHIP)',
    label: 'XIAMEN XINWEIDACHUANG INVESTMENT PARTNERSHIP (LIMITED PARTNERSHIP)',
  },
  {
    cik: '0001572311',
    value: '0001572311,XL GROUP INVESTMENTS LTD',
    name: 'XL GROUP INVESTMENTS LTD',
    label: 'XL GROUP INVESTMENTS LTD',
  },
  {
    cik: '0001800465',
    value: '0001800465,XML FINANCIAL, LLC',
    name: 'XML FINANCIAL, LLC',
    label: 'XML FINANCIAL, LLC',
  },
  {
    cik: '0001843228',
    value: '0001843228,XN EXPONENT ADVISORS LLC',
    name: 'XN EXPONENT ADVISORS LLC',
    label: 'XN EXPONENT ADVISORS LLC',
  },
  {
    cik: '0001756558',
    value: '0001,XN LP',
    name: 'XN LP',
    label: 'XN LP',
  },
  {
    cik: '0001637541',
    value: '0001637541,XPONANCE, INC.',
    name: 'XPONANCE, INC.',
    label: 'XPONANCE, INC.',
  },
  {
    cik: '0001531971',
    value: '0001531971,XR SECURITIES LLC',
    name: 'XR SECURITIES LLC',
    label: 'XR SECURITIES LLC',
  },
  {
    cik: '0001729300',
    value: '0001729300,X-SQUARE CAPITAL, LLC',
    name: 'X-SQUARE CAPITAL, LLC',
    label: 'X-SQUARE CAPITAL, LLC',
  },
  {
    cik: '0001715650',
    value: '0001715650,XTX MARKETS LLC',
    name: 'XTX MARKETS LLC',
    label: 'XTX MARKETS LLC',
  },
  {
    cik: '0001828301',
    value: '0001828301,XTX TOPCO LTD',
    name: 'XTX TOPCO LTD',
    label: 'XTX TOPCO LTD',
  },
  {
    cik: '0001556846',
    value: '000155684,XULU, INC.',
    name: 'XULU, INC.',
    label: 'XULU, INC.',
  },
  {
    cik: '0001828822',
    value: '000182882,XXEC, INC.',
    name: 'XXEC, INC.',
    label: 'XXEC, INC.',
  },
  {
    cik: '0000905567',
    value: '0000905567,YACKTMAN ASSET MANAGEMENT LP',
    name: 'YACKTMAN ASSET MANAGEMENT LP',
    label: 'YACKTMAN ASSET MANAGEMENT LP',
  },
  {
    cik: '0001894044',
    value:
      '0001894044,YAHAV ACHIM VE ACHAYOT - PROVIDENT FUNDS MANAGEMENT CO LTD.',
    name: 'YAHAV ACHIM VE ACHAYOT - PROVIDENT FUNDS MANAGEMENT CO LTD.',
    label: 'YAHAV ACHIM VE ACHAYOT - PROVIDENT FUNDS MANAGEMENT CO LTD.',
  },
  {
    cik: '0001584639',
    value: '0001584639,YAKIRA CAPITAL MANAGEMENT, INC.',
    name: 'YAKIRA CAPITAL MANAGEMENT, INC.',
    label: 'YAKIRA CAPITAL MANAGEMENT, INC.',
  },
  {
    cik: '0000938582',
    value: '0000938582,YALE UNIVERSITY',
    name: 'YALE UNIVERSITY',
    label: 'YALE UNIVERSITY',
  },
  {
    cik: '0001767686',
    value: '0001767686,YARBROUGH CAPITAL, LLC',
    name: 'YARBROUGH CAPITAL, LLC',
    label: 'YARBROUGH CAPITAL, LLC',
  },
  {
    cik: '0001908607',
    value: '0001908607,YARGER WEALTH STRATEGIES, LLC',
    name: 'YARGER WEALTH STRATEGIES, LLC',
    label: 'YARGER WEALTH STRATEGIES, LLC',
  },
  {
    cik: '0001772940',
    value: '0001772940,YARRA SQUARE PARTNERS LP',
    name: 'YARRA SQUARE PARTNERS LP',
    label: 'YARRA SQUARE PARTNERS LP',
  },
  {
    cik: '0001755028',
    value: '0001755028,YAUPON CAPITAL MANAGEMENT LP',
    name: 'YAUPON CAPITAL MANAGEMENT LP',
    label: 'YAUPON CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001569853',
    value: '0001569853,Y/CAP MANAGEMENT, LLC',
    name: 'Y/CAP MANAGEMENT, LLC',
    label: 'Y/CAP MANAGEMENT, LLC',
  },
  {
    cik: '0001584801',
    value: '0001584,YCG, LLC',
    name: 'YCG, LLC',
    label: 'YCG, LLC',
  },
  {
    cik: '0001682196',
    value: '0001682196,YELLOWSTONE PARTNERS, LLC',
    name: 'YELLOWSTONE PARTNERS, LLC',
    label: 'YELLOWSTONE PARTNERS, LLC',
  },
  {
    cik: '0001630409',
    value: '0001630409,YG PARTNERS, LLC',
    name: 'YG PARTNERS, LLC',
    label: 'YG PARTNERS, LLC',
  },
  {
    cik: '0001469219',
    value: '0001469219,YHB INVESTMENT ADVISORS, INC.',
    name: 'YHB INVESTMENT ADVISORS, INC.',
    label: 'YHB INVESTMENT ADVISORS, INC.',
  },
  {
    cik: '0001447228',
    value: '0001447228,YIHENG CAPITAL LLC',
    name: 'YIHENG CAPITAL LLC',
    label: 'YIHENG CAPITAL LLC',
  },
  {
    cik: '0001447228',
    value: '0001447228,YIHENG CAPITAL MANAGEMENT, L.P.',
    name: 'YIHENG CAPITAL MANAGEMENT, L.P.',
    label: 'YIHENG CAPITAL MANAGEMENT, L.P.',
  },
  {
    cik: '0001772875',
    value: '0001772875,Y-INTERCEPT (HONG KONG) LTD',
    name: 'Y-INTERCEPT (HONG KONG) LTD',
    label: 'Y-INTERCEPT (HONG KONG) LTD',
  },
  {
    cik: '0001442485',
    value: '0001442485,YM INVESTMENT LTD',
    name: 'YM INVESTMENT LTD',
    label: 'YM INVESTMENT LTD',
  },
  {
    cik: '0001738071',
    value: '0001738071,YONG RONG (HK) ASSET MANAGEMENT LTD',
    name: 'YONG RONG (HK) ASSET MANAGEMENT LTD',
    label: 'YONG RONG (HK) ASSET MANAGEMENT LTD',
  },
  {
    cik: '0001696064',
    value: '0001696064,YORKBRIDGE WEALTH PARTNERS, LLC',
    name: 'YORKBRIDGE WEALTH PARTNERS, LLC',
    label: 'YORKBRIDGE WEALTH PARTNERS, LLC',
  },
  {
    cik: '0001480532',
    value: '0001480532,YORK CAPITAL MANAGEMENT GLOBAL ADVISORS, LLC',
    name: 'YORK CAPITAL MANAGEMENT GLOBAL ADVISORS, LLC',
    label: 'YORK CAPITAL MANAGEMENT GLOBAL ADVISORS, LLC',
  },
  {
    cik: '0001503466',
    value: '0001503466,YORKTOWN ENERGY PARTNERS IX, L.P.',
    name: 'YORKTOWN ENERGY PARTNERS IX, L.P.',
    label: 'YORKTOWN ENERGY PARTNERS IX, L.P.',
  },
  {
    cik: '0001473116',
    value: '0001473116,YORKTOWN ENERGY PARTNERS VIII, L.P.',
    name: 'YORKTOWN ENERGY PARTNERS VIII, L.P.',
    label: 'YORKTOWN ENERGY PARTNERS VIII, L.P.',
  },
  {
    cik: '0001423080',
    value: '0001423080,YORKTOWN ENERGY PARTNERS VII, L.P.',
    name: 'YORKTOWN ENERGY PARTNERS VII, L.P.',
    label: 'YORKTOWN ENERGY PARTNERS VII, L.P.',
  },
  {
    cik: '0001347504',
    value: '0001347504,YORKTOWN ENERGY PARTNERS VI LP',
    name: 'YORKTOWN ENERGY PARTNERS VI LP',
    label: 'YORKTOWN ENERGY PARTNERS VI LP',
  },
  {
    cik: '0001210407',
    value: '0001210407,YORKTOWN ENERGY PARTNERS V LP',
    name: 'YORKTOWN ENERGY PARTNERS V LP',
    label: 'YORKTOWN ENERGY PARTNERS V LP',
  },
  {
    cik: '0001667954',
    value: '0001667954,YORKTOWN ENERGY PARTNERS XI, L.P.',
    name: 'YORKTOWN ENERGY PARTNERS XI, L.P.',
    label: 'YORKTOWN ENERGY PARTNERS XI, L.P.',
  },
  {
    cik: '0001577919',
    value: '0001577919,YORKTOWN ENERGY PARTNERS X, L.P.',
    name: 'YORKTOWN ENERGY PARTNERS X, L.P.',
    label: 'YORKTOWN ENERGY PARTNERS X, L.P.',
  },
  {
    cik: '0001313559',
    value: '0001313559,YORKTOWN MANAGEMENT & RESEARCH CO INC',
    name: 'YORKTOWN MANAGEMENT & RESEARCH CO INC',
    label: 'YORKTOWN MANAGEMENT & RESEARCH CO INC',
  },
  {
    cik: '0001590784',
    value: '0001590784,YORKVILLE CAPITAL MANAGEMENT, LLC',
    name: 'YORKVILLE CAPITAL MANAGEMENT, LLC',
    label: 'YORKVILLE CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001489859',
    value: '0001489859,YOST CAPITAL MANAGEMENT, L.L.C.',
    name: 'YOST CAPITAL MANAGEMENT, L.L.C.',
    label: 'YOST CAPITAL MANAGEMENT, L.L.C.',
  },
  {
    cik: '0001489859',
    value: '0001489859,YOST CAPITAL MANAGEMENT, LP',
    name: 'YOST CAPITAL MANAGEMENT, LP',
    label: 'YOST CAPITAL MANAGEMENT, LP',
  },
  {
    cik: '0001663649',
    value: '0001663649,YOUNGS ADVISORY GROUP, INC.',
    name: 'YOUNGS ADVISORY GROUP, INC.',
    label: 'YOUNGS ADVISORY GROUP, INC.',
  },
  {
    cik: '0001774437',
    value: '0001774437,YOUR ADVOCATES LTD., LLP',
    name: 'YOUR ADVOCATES LTD., LLP',
    label: 'YOUR ADVOCATES LTD., LLP',
  },
  {
    cik: '0001858789',
    value: '0001858789,YOUSIF CAPITAL MANAGEMENT, LLC',
    name: 'YOUSIF CAPITAL MANAGEMENT, LLC',
    label: 'YOUSIF CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001610189',
    value: '0001610189,YT EXTRACTION CO INVESTMENT PARTNERS, LP',
    name: 'YT EXTRACTION CO INVESTMENT PARTNERS, LP',
    label: 'YT EXTRACTION CO INVESTMENT PARTNERS, LP',
  },
  {
    cik: '0001668029',
    value: '00016,YU FAN',
    name: 'YU FAN',
    label: 'YU FAN',
  },
  {
    cik: '0001912273',
    value: '0001912273,YUNQI CAPITAL LTD',
    name: 'YUNQI CAPITAL LTD',
    label: 'YUNQI CAPITAL LTD',
  },
  {
    cik: '0001086483',
    value: '0001086483,ZACKS INVESTMENT MANAGEMENT',
    name: 'ZACKS INVESTMENT MANAGEMENT',
    label: 'ZACKS INVESTMENT MANAGEMENT',
  },
  {
    cik: '0001009012',
    value: '0001009012,ZAZOVE ASSOCIATES LLC',
    name: 'ZAZOVE ASSOCIATES LLC',
    label: 'ZAZOVE ASSOCIATES LLC',
  },
  {
    cik: '0001438572',
    value: '0001438572,ZBI EQUITIES, L.L.C.',
    name: 'ZBI EQUITIES, L.L.C.',
    label: 'ZBI EQUITIES, L.L.C.',
  },
  {
    cik: '0001666558',
    value: '0001666558,ZBI SERVICES, L.L.C.',
    name: 'ZBI SERVICES, L.L.C.',
    label: 'ZBI SERVICES, L.L.C.',
  },
  {
    cik: '0001666757',
    value: '000166,ZB,N.A.',
    name: 'ZB,N.A.',
    label: 'ZB,N.A.',
  },
  {
    cik: '0001597741',
    value: '0001597741,ZEAL ASSET MANAGEMENT LTD',
    name: 'ZEAL ASSET MANAGEMENT LTD',
    label: 'ZEAL ASSET MANAGEMENT LTD',
  },
  {
    cik: '0001316915',
    value: '0001316915,ZEBRA CAPITAL MANAGEMENT LLC',
    name: 'ZEBRA CAPITAL MANAGEMENT LLC',
    label: 'ZEBRA CAPITAL MANAGEMENT LLC',
  },
  {
    cik: '0001808163',
    value: '0001808163,ZEIT CAPITAL LLC',
    name: 'ZEIT CAPITAL LLC',
    label: 'ZEIT CAPITAL LLC',
  },
  {
    cik: '0001469528',
    value: '0001469528,ZEKE CAPITAL ADVISORS, LLC',
    name: 'ZEKE CAPITAL ADVISORS, LLC',
    label: 'ZEKE CAPITAL ADVISORS, LLC',
  },
  {
    cik: '0001046675',
    value: '0001046675,ZEKE LP /PA',
    name: 'ZEKE LP /PA',
    label: 'ZEKE LP /PA',
  },
  {
    cik: '0001592162',
    value: '0001592162,ZELMAN CAPITAL LLC',
    name: 'ZELMAN CAPITAL LLC',
    label: 'ZELMAN CAPITAL LLC',
  },
  {
    cik: '0001536727',
    value: '0001536727,ZENIT ASSET MANAGEMENT AB',
    name: 'ZENIT ASSET MANAGEMENT AB',
    label: 'ZENIT ASSET MANAGEMENT AB',
  },
  {
    cik: '0001792460',
    value: '0001792460,ZENO RESEARCH, LLC',
    name: 'ZENO RESEARCH, LLC',
    label: 'ZENO RESEARCH, LLC',
  },
  {
    cik: '0001887163',
    value: '0001887163,ZENYATTA CAPITAL MANAGEMENT LP',
    name: 'ZENYATTA CAPITAL MANAGEMENT LP',
    label: 'ZENYATTA CAPITAL MANAGEMENT LP',
  },
  {
    cik: '0001004744',
    value: '0001004744,ZESIGER CAPITAL GROUP LLC',
    name: 'ZESIGER CAPITAL GROUP LLC',
    label: 'ZESIGER CAPITAL GROUP LLC',
  },
  {
    cik: '0001276918',
    value: '0001276918,ZEVENBERGEN CAPITAL INVESTMENTS LLC',
    name: 'ZEVENBERGEN CAPITAL INVESTMENTS LLC',
    label: 'ZEVENBERGEN CAPITAL INVESTMENTS LLC',
  },
  {
    cik: '0001394096',
    value: '0001394096,ZEVIN ASSET MANAGEMENT LLC',
    name: 'ZEVIN ASSET MANAGEMENT LLC',
    label: 'ZEVIN ASSET MANAGEMENT LLC',
  },
  {
    cik: '0001776290',
    value: '0001776290,ZHANG FINANCIAL LLC',
    name: 'ZHANG FINANCIAL LLC',
    label: 'ZHANG FINANCIAL LLC',
  },
  {
    cik: '0001307617',
    value: '0001307617,ZIEGLER CAPITAL MANAGEMENT, LLC',
    name: 'ZIEGLER CAPITAL MANAGEMENT, LLC',
    label: 'ZIEGLER CAPITAL MANAGEMENT, LLC',
  },
  {
    cik: '0001598039',
    value: '0001598039,ZIFF BROTHERS INVESTMENTS, L.L.C.',
    name: 'ZIFF BROTHERS INVESTMENTS, L.L.C.',
    label: 'ZIFF BROTHERS INVESTMENTS, L.L.C.',
  },
  {
    cik: '0001908404',
    value: '0001908404,ZIMMERMANN INVESTMENT MANAGEMENT & PLANNING LLC',
    name: 'ZIMMERMANN INVESTMENT MANAGEMENT & PLANNING LLC',
    label: 'ZIMMERMANN INVESTMENT MANAGEMENT & PLANNING LLC',
  },
  {
    cik: '0001565854',
    value: '0001565854,ZIMMER PARTNERS, LP',
    name: 'ZIMMER PARTNERS, LP',
    label: 'ZIMMER PARTNERS, LP',
  },
  {
    cik: '0001642140',
    value: '0001642140,ZIONS BANCORPORATION',
    name: 'ZIONS BANCORPORATION',
    label: 'ZIONS BANCORPORATION',
  },
  {
    cik: '0001666757',
    value: '0001666757,ZIONS BANCORPORATION, N.A.',
    name: 'ZIONS BANCORPORATION, N.A.',
    label: 'ZIONS BANCORPORATION, N.A.',
  },
  {
    cik: '0001259392',
    value: '0001259392,ZIONS TRUST, NATIONAL ASSOCIATION',
    name: 'ZIONS TRUST, NATIONAL ASSOCIATION',
    label: 'ZIONS TRUST, NATIONAL ASSOCIATION',
  },
  {
    cik: '0001585521',
    value: '0001585521,ZOOM VIDEO COMMUNICATIONS, INC.',
    name: 'ZOOM VIDEO COMMUNICATIONS, INC.',
    label: 'ZOOM VIDEO COMMUNICATIONS, INC.',
  },
  {
    cik: '0001504681',
    value: '0001504681,ZPR INVESTMENT MANAGEMENT',
    name: 'ZPR INVESTMENT MANAGEMENT',
    label: 'ZPR INVESTMENT MANAGEMENT',
  },
  {
    cik: '0001423296',
    value: '0001423296,ZUCKERMAN INVESTMENT GROUP, LLC',
    name: 'ZUCKERMAN INVESTMENT GROUP, LLC',
    label: 'ZUCKERMAN INVESTMENT GROUP, LLC',
  },
  {
    cik: '0001909760',
    value: '0001909760,ZULLO INVESTMENT GROUP, INC.',
    name: 'ZULLO INVESTMENT GROUP, INC.',
    label: 'ZULLO INVESTMENT GROUP, INC.',
  },
  {
    cik: '0001368163',
    value: '0001368163,ZURCHER KANTONALBANK (ZURICH CANTONALBANK)',
    name: 'ZURCHER KANTONALBANK (ZURICH CANTONALBANK)',
    label: 'ZURCHER KANTONALBANK (ZURICH CANTONALBANK)',
  },
  {
    cik: '0001127799',
    value: '0001127799,ZURICH INSURANCE GROUP LTD/FI',
    name: 'ZURICH INSURANCE GROUP LTD/FI',
    label: 'ZURICH INSURANCE GROUP LTD/FI',
  },
  {
    cik: '0000860656',
    value: '0000860656,ZWEIG ADVISERS LLC',
    name: 'ZWEIG ADVISERS LLC',
    label: 'ZWEIG ADVISERS LLC',
  },
  {
    cik: '0001533950',
    value: '0001533950,ZWEIG-DIMENNA ASSOCIATES LLC',
    name: 'ZWEIG-DIMENNA ASSOCIATES LLC',
    label: 'ZWEIG-DIMENNA ASSOCIATES LLC',
  },
  {
    cik: '0000842782',
    value: '0000842782,ZWJ INVESTMENT COUNSEL INC',
    name: 'ZWJ INVESTMENT COUNSEL INC',
    label: 'ZWJ INVESTMENT COUNSEL INC',
  },
]
