import http from 'network/httpClient'
import moment from 'moment'

export const reduceData = (data, startDate, weekly, triMonthly) => {
  let filteredData = []
  let startingDate = moment(startDate)
  let currentDate = moment().format('YYYY-MM-DD')

  if (weekly) {
    for (let i = data.length - 1; i >= 0; i--) {
      let dataDate = moment(data[i].date)
      if (dataDate.isSameOrAfter(startingDate, 'week')) {
        filteredData.push(data[i])
        startingDate.add(1, 'week')
      }
    }
  } else if (triMonthly) {
    for (let i = data.length - 1; i >= 0; i--) {
      let dataDate = moment(data[i].date)
      if (dataDate.isSameOrAfter(startingDate, 'month')) {
        filteredData.push(data[i])
        startingDate.add(3, 'month')
      }
    }
  }

  if (data.length > 0) {
    filteredData.push(data[0])
  }

  let currentDataPoint = data.find(item => item.date === currentDate)
  if (currentDataPoint) {
    if (
      !filteredData.length ||
      filteredData[filteredData.length - 1].date !== currentDate
    ) {
      filteredData.push(currentDataPoint)
    }
  }

  return filteredData.reverse()
}

const fetchPriceData = async ({ queryKey }) => {
  const [, ticker, { label, from, to }] = queryKey
  let data

  const time = label => {
    if (label === '5D') {
      return '15min'
    } else if (label === '1D') {
      return '1min'
    } else if (label === '1M') {
      return '30min'
    } else if (label === '3M') {
      return '1hour'
    }
  }

  switch (label) {
    case '1D':
    case '5D':
    case '1M':
    case '3M':
      data = await http.get(
        `papi/data/api/v3/historical-chart/${time(label)}/${ticker}`
      )
      data = data.data.message
      break
    default:
      let startDate = from ? moment(from).format('YYYY-MM-DD') : null
      let endDate = to ? moment(to).format('YYYY-MM-DD') : null
      data = await http.get(
        `papi/data/api/v3/historical-price-full/${ticker}?from=${startDate}&to=${endDate}`
      )
      data = data.data.message.historical
  }

  let startDate = from ? moment(from) : null
  let endDate = to ? moment(to) : null
  let historicalData = data.filter(item => {
    let date = moment(item.date)
    return (
      (!startDate || date.isAfter(startDate)) &&
      (!endDate || date.isBefore(endDate))
    )
  })

  if (from && moment(to).diff(moment(from), 'years') > 10) {
    historicalData = reduceData(historicalData, from, false, true)
  } else if (from && moment(to).diff(moment(from), 'years') >= 3) {
    historicalData = reduceData(historicalData, from, true, false)
  }

  return historicalData.reverse()
}

const fetchCompanyData = async ({ queryKey }) => {
  const [, ticker] = queryKey
  let data = await http.get(`papi/data/api/v4/company-outlook?symbol=${ticker}`)
  return data.data.message
}

const fetchCompanyQuote = async ({ queryKey }) => {
  const [, ticker] = queryKey
  let data = await http.get(`papi/data/api/v3/quote/${ticker}`)
  return data.data.message[0]
}

const fetchReturns = async ({ queryKey }) => {
  const [, ticker] = queryKey
  let data = await http.get(`papi/data/api/v3/stock-price-change/${ticker}`)
  return data.data.message[0]
}

const fetchCompanyNews = async ({ queryKey }) => {
  const [, ticker] = queryKey
  let data = await http.get(`papi/data/api/v3/stock_news?tickers=${ticker}`)
  return data.data.message
}

const fetchAnalystConsensus = async ({ queryKey }) => {
  const [, ticker] = queryKey
  let data = await http.get(
    `papi/data/api/v4/upgrades-downgrades-consensus?symbol=${ticker}`
  )
  return data.data.message
}

export const queries = {
  fetchAnalystConsensus,
  fetchCompanyData,
  fetchCompanyNews,
  fetchCompanyQuote,
  fetchPriceData,
  fetchReturns,
}
