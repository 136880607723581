export const initialState = {
  name: '',
  numberDashboards: 0,
  lastSaved: '',
  dashboardNames: [],
  data: {},
  layouts: {},
  breakpoint: 'lg',
  ComponentCount: 0,
}

export const newDashboardState = name => {
  return {
    name: name,
    numberDashboards: 0,
    lastSaved: '',
    dashboardNames: [],
    data: {},
    layouts: {},
    breakpoint: 'lg',
    ComponentCount: 0,
  }
}

export default initialState
