import React from 'react'
import { CoreLineChart } from 'core/Charts/GenericChart'
import numeral from 'numeral'
import styles from './totalsTrendWidget.module.scss'
import { UilComments } from '@iconscout/react-unicons'
import { UilHeart } from '@iconscout/react-unicons'
import { UilEye } from '@iconscout/react-unicons'
import { UilImagePlus } from '@iconscout/react-unicons'

const calculateTotal = (data, key) => {
  return data.reduce((a, b) => a + b[key], 0)
}

export const TotalsTrendWidget = ({ data, metrics, Label, collapsed, img }) => {
  const blue = 'var(--primary-color)'
  const getIcon = Label => {
    switch (Label) {
      case 'Posts':
        return <UilImagePlus color={blue} />
      case 'Comments':
        return <UilComments color={blue} />
      case 'Impressions':
        return <UilEye color={blue} />
      case 'Likes':
        return <UilHeart color={blue} />
      default:
        return <UilHeart color={blue} />
    }
  }

  const getTitle = Label => {
    switch (Label) {
      case 'Posts':
        return 'Posts'
      case 'Comments':
        return 'Comments'
      case 'Impressions':
        return 'Impressions'
      case 'Likes':
        return 'Likes'
      default:
        return 'Likes'
    }
  }

  if (collapsed) {
    return (
      <div
        className={styles.chartWidgetContainerCollapsed}
        title={getTitle(Label)}
      >
        <div className={styles.chartWidgetLeftPanelCollapsed}>
          {getIcon(Label)}
          <div className={styles.chartWidgetScoreCollapsed}>
            {!data || data.length === 0
              ? 'N/A'
              : calculateTotal(data, metrics[0].key) < 1000
              ? numeral(calculateTotal(data, metrics[0].key)).format('0.a')
              : numeral(calculateTotal(data, metrics[0].key)).format('0.0a')}
          </div>
        </div>
      </div>
    )
  } else
    return (
      <div className={styles.chartWidgetContainer}>
        <div className={styles.chartWidgetTitleHeader}>
          <div className={styles.chartWidgetTitle}>{Label}</div>
          <div className={styles.chartWidgetScore}>
            {!data || data.length === 0
              ? 'N/A'
              : numeral(calculateTotal(data, metrics[0].key)).format('0.00 a')}
          </div>
        </div>
        <div className={styles.chartWidgetGraphContainer}>
          {!data || data.length === 0 ? (
            <div className={styles.chartWidgetNoData}>
              <div className={styles.chartWidgetNoDataText}>
                No data available
              </div>
            </div>
          ) : (
            <CoreLineChart
              data={data}
              metrics={metrics}
              XAxisKey={'date'}
              showYAxis={true}
              customTickFormatter
              standardYAxis
              decimalCount={0}
              showSingleYAxis={'right'}
              axisWidth={40}
              xAxisHeight={12}
            />
          )}
        </div>
      </div>
    )
}

export default TotalsTrendWidget
