import { useMemo, useState } from 'react'
import { useTable, useSortBy } from 'react-table'
import numeral from 'numeral'
import styles from './StatsTable.module.scss'
import { calcStats, changePercent } from '../../util'
import _ from 'lodash'
import { SortColumnFilter } from 'core/Tables/filters'
import TooltipTicker from 'core/TickerOverlay/TickerOverlay'

export const StatsTable = ({
  data,
  columns,
  watchlistName,
  individualAnalysis,
}) => {
  const [selectedSymbol, setSelectedSymbol] = useState('')
  const [selectedStatistic, setSelectedStatistic] = useState('mean')

  const stats = useMemo(() => {
    return columns.reduce((value, column) => {
      if (column.isNumeric) {
        value[column.accessor] = calcStats(
          data?.map(item => item[column.accessor])
        )
      }
      return value
    }, {})
  }, [columns, data])

  const statColumns = useMemo(() => {
    const headerMap = columns.reduce((colObjectHeader, col) => {
      colObjectHeader[col.accessor] = col.Header
      return colObjectHeader
    }, {})

    return [
      {
        Header: 'Watchlist Statistics',
        accessor: 'statName',
        width: 150,
        minWidth: 150,
        maxWidth: 150,
        Cell: Cell => {
          return <span>{_.startCase(Cell.value)}</span>
        },
      },
      ...Object.keys(stats).map(key => ({
        Header: headerMap[key] || key,
        accessor: key,
      })),
    ]
  }, [stats, columns])

  const statData = useMemo(
    () =>
      Object.keys(stats['price'] || {}).map(statName => ({
        statName,
        ...Object.fromEntries(
          Object.keys(stats).map(key => [
            key,
            statName === 'count'
              ? stats[key][statName]
              : numeral(stats[key][statName]).format('0,0.00a'),
          ])
        ),
      })),
    [stats]
  )

  const selectedStatAndCompanyData = useMemo(() => {
    if (selectedSymbol) {
      const companyData = data.find(item => item.symbol === selectedSymbol)

      if (companyData) {
        const selectedCompanyStats = Object.fromEntries(
          columns
            .filter(column => column.isNumeric)
            .map(column => {
              const value = companyData[column.accessor]
              const stat = stats[column.accessor]
              return [
                column.Header,
                {
                  companyValue: numeral(value).format('0,0.00a'),
                  statValue: numeral(stat[selectedStatistic]).format('0,0.00a'),
                },
              ]
            })
        )
        return selectedCompanyStats
      }
    }
    return null
  }, [selectedSymbol, data, columns, stats, selectedStatistic])

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns: statColumns, data: statData }, useSortBy)

  return (
    <div className={styles.watchlistStatistics}>
      {/* Watchlist Statistics */}
      <table {...getTableProps()} className={styles.statTable}>
        <thead className={styles.statsTableHead}>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={styles.statTableHeader}
                  style={{
                    width: column.width,
                    maxWidth: column.maxWidth,
                    minWidth: column.minWidth,
                  }}
                >
                  {column.render('Header')}
                  <span>
                    {column.isSorted ? (
                      <SortColumnFilter column={column} />
                    ) : (
                      ''
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()} className={styles.statTableRow}>
                {row.cells.map(cell => (
                  <td
                    {...cell.getCellProps()}
                    className={styles.statTableData}
                    style={{
                      width: cell.column.width,
                      maxWidth: cell.column.maxWidth,
                      minWidth: cell.column.minWidth,
                    }}
                  >
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            )
          })}
        </tbody>
      </table>
      {individualAnalysis === true ? (
        <div className={styles.compareStats}>
          Compare Symbol To Statistics
          <select
            value={selectedSymbol}
            onChange={event => setSelectedSymbol(event.target.value)}
            className={styles.compareStatsSelect}
          >
            {data.map(({ symbol }) => (
              <option key={symbol} value={symbol}>
                {symbol}
              </option>
            ))}
          </select>
          <select
            value={selectedStatistic}
            onChange={event => setSelectedStatistic(event.target.value)}
            className={styles.compareStatsSelect}
          >
            {Object.keys(stats['price'] || {}).map(stat => (
              <option key={stat} value={stat}>
                {stat}
              </option>
            ))}
          </select>
          <div className={styles.compareStatsItems}>
            {selectedStatAndCompanyData &&
              Object.entries(selectedStatAndCompanyData).map(
                ([key, { companyValue, statValue }]) => (
                  <div className={styles.compareStatsItem} key={key}>
                    <div className={styles.statHeader}>{key}</div>
                    <div className={styles.statValues}>
                      <div className={styles.compareStatsItemSymbol}>
                        <TooltipTicker ticker={selectedSymbol} />
                        {`: ${companyValue}`}
                      </div>
                      <div className={styles.compareStatsItemStat}>
                        {`${
                          selectedStatistic === 'standardDeviation'
                            ? 'Standard Dev'
                            : _.startCase(selectedStatistic)
                        }: ${statValue}`}
                      </div>
                    </div>
                    <div>
                      <span
                        style={{
                          color:
                            changePercent(companyValue, statValue) < 0
                              ? 'var(--red)'
                              : 'var(--green)',
                        }}
                      >
                        {`${changePercent(companyValue, statValue)}%`}
                      </span>
                    </div>
                  </div>
                )
              )}
          </div>
        </div>
      ) : null}
    </div>
  )
}
