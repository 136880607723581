import { useState } from 'react'

const TimeRange = ({
  intervals,
  interval,
  onChange,
  vertical,
  spaceBetween,
  gap,
  fitContent,
  background,
}) => {
  const [selected, setSelected] = useState(interval)

  return (
    <div
      className="TimeRange-container"
      style={{
        width: fitContent ? 'fit-content' : '100%',
        justifyContent: vertical && 'center',
        height: fitContent && 'fit-content',
        backgroundColor: fitContent ? background : 'var(--background-primary)',
      }}
    >
      <div
        className="TimeRange-range-container"
        style={{
          flexDirection: vertical && 'column',
          justifyContent: spaceBetween ? spaceBetween : 'space-between',
          gap: gap ? gap : null,
        }}
      >
        {intervals.map(interval => {
          return (
            <div
              className="interval-container"
              onClick={() => onChange(interval)}
            >
              <div
                className="interval-continer-select"
                onClick={() => setSelected(interval)}
                style={{
                  color:
                    selected?.label === interval?.label
                      ? 'var(--primary-color)'
                      : '',
                  backgroundColor:
                    selected?.label === interval?.label
                      ? 'var(--button-hover)'
                      : '',
                }}
              >
                {interval.label}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default TimeRange
