import { nonMetricRows } from './constants'
export const CreateSubRows = (data, tempConfig) => {
  const config_keys = Object.keys(tempConfig)
  let CollapsedData = data.map(entry => {
    if (config_keys.includes(entry.key)) {
      let childrenKeys = Object.keys(tempConfig[entry.key])
      let children = data.filter(d => childrenKeys.includes(d.key))
      return {
        ...entry,
        subRows: children,
      }
    }
    return null
  })
  CollapsedData = CollapsedData.filter(d => d)

  let nonMetricGroups = config_keys
    .map(key => {
      if (nonMetricRows.includes(key)) {
        let childrenKeys = Object.keys(tempConfig[key])
        let children = data.filter(d => childrenKeys.includes(d.key))
        return {
          key: key,
          subRows: children,
        }
      }
      return null
    })
    .filter(g => g)

  return [...nonMetricGroups, ...CollapsedData]
}
