import React, { useRef, useState, useEffect } from 'react'
import numeral from 'numeral'
import {
  calculateDonutPath,
  getLabelPosition,
  generateGradientColors,
} from './utils'

const DonutChart = ({
  data,
  hoveredSymbol,
  normalPie,
  thickRatio = 0.35,
  hoverScaleFactor = 0.08,
  scaleIn = true,
  colorStart = '#ffd700',
  colorEnd = '#ff8c00',
  noLabel,
  removeValue,
  setHoveredSymbol,
  paddings,
  otherKey = 'Other',
}) => {
  const containerRef = useRef(null)
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })
  const [radiusState, setRadiusState] = useState(
    data?.map(() => ({ outerRadius: 0, innerRadius: 0 }))
  )
  const [hoveredSlice, setHoveredSlice] = useState(null)
  const padding = paddings ? paddings : 16
  const margin = {
    top: padding,
    right: padding,
    bottom: padding,
    left: padding,
  }

  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        setDimensions({
          width: containerRef.current.offsetWidth,
          height: containerRef.current.offsetHeight,
        })
      }
    }

    updateDimensions()

    const resizeObserver = new ResizeObserver(updateDimensions)
    resizeObserver.observe(containerRef.current)

    return () => {
      resizeObserver.disconnect()
    }
  }, [])

  useEffect(() => {
    if (hoveredSymbol !== null) {
      const index = data?.findIndex(item => item.label === hoveredSymbol)
      const otherIndex = data?.findIndex(item => item.label === otherKey)
      if (index >= 0) {
        setHoveredSlice(index)
      } else if (otherIndex >= 0) {
        setHoveredSlice(otherIndex)
      } else {
        setHoveredSlice(null)
      }
    } else {
      setHoveredSlice(null)
    }
  }, [hoveredSymbol, data, otherKey])

  const { width, height } = dimensions
  const effectiveWidth = width - margin.left - margin.right
  const effectiveHeight = height - margin.top - margin.bottom
  const size = Math.min(effectiveWidth, effectiveHeight)
  const outerRadius = size / 2
  const defaultInnerRadius = outerRadius * (1 - thickRatio)
  const cx = effectiveWidth / 2 + margin.left
  const cy = effectiveHeight / 2 + margin.top

  let totalValue = data?.reduce((acc, { value }) => acc + value, 0)

  const renderCenterText = () => {
    if (hoveredSlice !== null) {
      const percent = data[hoveredSlice]?.value / totalValue
      return (
        <>
          <tspan
            x={0}
            dy="-0.6em"
            textAnchor="middle"
            fill="var(--white)"
            fontSize={15}
          >
            {data[hoveredSlice]?.label}
          </tspan>
          <tspan x={0} dy="1.2em" textAnchor="middle" fill="var(--white)" fontSize={18}>
            {`${
              removeValue
                ? ''
                : numeral(data[hoveredSlice]?.value).format('0.00a')
            }  (${numeral(percent).format('0.00%')})`}
          </tspan>
        </>
      )
    }
    return (
      <>
        <tspan x={0} dy="-0.6em" textAnchor="middle" fill="var(--white)" fontSize={15}>
          Total Value
        </tspan>
        <tspan x={0} dy="1.3em" textAnchor="middle" fill="var(--white)" fontSize={18}>
          {removeValue ? null : numeral(totalValue).format('0.00a')} (100%)
        </tspan>
      </>
    )
  }

  const doesTextFit = (text, angle, outerRadius, innerRadius) => {
    const textWidth = text?.length * 7
    const arcLength =
      (angle / (2 * Math.PI)) * (Math.PI * (outerRadius + innerRadius))

    return textWidth < arcLength
  }

  const gradientColors = generateGradientColors(
    colorStart,
    colorEnd,
    data?.length
  )

  useEffect(() => {
    setRadiusState(
      radiusState.map(() => ({
        outerRadius: outerRadius,
        innerRadius: defaultInnerRadius,
      }))
    )
  }, [data, defaultInnerRadius, outerRadius])

  const handleMouseEnter = index => {
    const newRadiusState = data?.map((_, i) => {
      if (index === i) {
        return {
          outerRadius: scaleIn
            ? outerRadius
            : outerRadius * (1 + hoverScaleFactor),
          innerRadius: scaleIn
            ? defaultInnerRadius * (1 - hoverScaleFactor)
            : defaultInnerRadius,
        }
      }
      return radiusState[i]
    })
    setRadiusState(newRadiusState)
    setHoveredSlice(index)
  }

  const handleMouseLeave = () => {
    setRadiusState(
      radiusState?.map(() => ({
        outerRadius: outerRadius,
        innerRadius: defaultInnerRadius,
      }))
    )
    setHoveredSlice(null)
  }

  const renderSlices = () => {
    let startAngle = -0.5
    return data?.map((slice, index) => {
      const percent = slice.value / totalValue
      const angle = percent * Math.PI * 2

      const text = noLabel
        ? `${numeral(percent).format('0%')}`
        : `${slice.label} (${numeral(percent).format('0%')})`
      const isHovered = index === hoveredSlice
      let { outerRadiusAdjustment, innerRadius } = radiusState[index]

      let innerRadiusValue = normalPie ? 0 : innerRadius

      if (isHovered) {
        if (scaleIn) {
          outerRadiusAdjustment = outerRadius
          innerRadiusValue = defaultInnerRadius * (1 - hoverScaleFactor)
        } else {
          outerRadiusAdjustment =
            outerRadius * (1 + (isHovered ? hoverScaleFactor : 0))
          innerRadiusValue = defaultInnerRadius
        }
      } else {
        outerRadiusAdjustment = outerRadius
        innerRadiusValue = defaultInnerRadius
      }

      const style = {
        transition: 'all 0.2s ease-in-out',
      }

      const d = calculateDonutPath(
        slice.value,
        outerRadiusAdjustment,
        innerRadiusValue,
        0,
        0,
        startAngle,
        totalValue,
        normalPie
      )
      const { x, y } = getLabelPosition(
        percent,
        outerRadiusAdjustment,
        innerRadiusValue,
        0,
        0,
        startAngle
      )
      const textFits = doesTextFit(
        text,
        angle,
        outerRadiusAdjustment,
        innerRadiusValue
      )

      startAngle += percent
      return (
        <React.Fragment key={index}>
          <path
            d={d}
            fill={gradientColors[index]}
            onMouseEnter={() => {
              handleMouseEnter(index)
              setHoveredSymbol(slice.label)
            }}
            onMouseLeave={handleMouseLeave}
            stroke="var(--background-primary)"
            strokeWidth="1"
            style={style}
          />
          {textFits && (
            <text
              x={x}
              y={y}
              dy=".35em"
              textAnchor={'middle'}
              fill="var(--white)"
              fontSize={13}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
            >
              {text}
            </text>
          )}
        </React.Fragment>
      )
    })
  }

  return (
    <div ref={containerRef} style={{ width: '100%', height: '100%' }}>
      {width > 0 && height > 0 && (
        <svg width={width} height={height}>
          <g transform={`translate(${cx}, ${cy})`}>
            {renderSlices()}
            <text x={0} y={0} dy=".35em" textAnchor="middle" fill="var(--white)">
              {normalPie === true ? null : renderCenterText()}
            </text>
          </g>
        </svg>
      )}
    </div>
  )
}

export default DonutChart
