import React, { useState } from 'react'
import Waterfall from './Waterfall'
import styles from './waterfallChart.module.scss'
import CoreButton from 'core/Button/CoreButton'

export const WaterfallChart = ({ data, getData, getPercentageData }) => {
  const [labelPosition, setLabelPosition] = useState('right')
  const [barColor, setBarColor] = useState('default')

  // TODO: memoize
  const handleLabelPositionChange = newPosition => {
    setLabelPosition(newPosition)
  }

  // TODO: memoize
  const data1 = getPercentageData(data)
  const data2 = getData(data)

  return (
    <div className={styles.waterfallComponentWrapper}>
      <div className={styles.buttonWrapper}>
        <div className={styles.changeDataFrequencyWrapper}>
          <button
            className={styles.dataFrequencyButton}
            style={{
              color: labelPosition === 'left' ? 'var(--primary-color)' : '',
            }}
            onClick={() => handleLabelPositionChange('left')}
          >
            Label Left
          </button>
          <div className={styles.divider} />
          <button
            className={styles.dataFrequencyButton}
            style={{
              color: labelPosition === 'right' ? 'var(--primary-color)' : '',
            }}
            onClick={() => handleLabelPositionChange('right')}
          >
            Label Right
          </button>
        </div>
        <CoreButton
          onClick={() => setBarColor(barColor !== 'blue' ? 'blue' : 'default')}
          color={
            barColor === 'blue' ? 'var(--primary-color)' : 'var(--grey-accent)'
          }
        >
          Color Change
        </CoreButton>
      </div>
      <div className={styles.waterfallWrapper}>
        <Waterfall
          data={data2}
          labelPosition={labelPosition}
          barColor={barColor}
          dataChanged={data1}
        />
      </div>
    </div>
  )
}
