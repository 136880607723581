import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import { MultiSelectTickerSearch } from 'dashboard/Title/components/MultiSelectTickerSearch'
import CoreButton from 'core/Button/CoreButton'
import times from '../../assets/times.svg'
import styles from './AddTickersWatchlistModal.module.scss'

const AddTickerModal = ({
  open,
  handleClose,
  updateTicker,
  handleAddTicker,
  selectedWatchlistName,
  duplicateTicker,
  setDuplicateTicker,
}) => {
  return (
    <Modal open={open} onClose={handleClose} disableAutoFocus={true}>
      <Box
        sx={{
          '&:focus': {
            outline: 'none',
          },
        }}
      >
        <div className={styles.addTickerModal}>
          <div className={styles.watchlistModalHeader}>
            <div>Add Symbol to {selectedWatchlistName}</div>
            <img
              src={times}
              onClick={handleClose}
              alt="close modal"
              className={styles.closeModal}
            />
          </div>
          <MultiSelectTickerSearch
            updateTicker={updateTicker}
            clearError={setDuplicateTicker}
          />
          {duplicateTicker && (
            <div className={styles.duplicateTickerError}>
              Ticker(s) {duplicateTicker} already exist in watchlist.
            </div>
          )}
          <div className={styles.modalConfirmButton}>
            <CoreButton
              color={'var(--primary-color)'}
              onClick={handleAddTicker}
            >
              Add Symbol
            </CoreButton>
          </div>
        </div>
      </Box>
    </Modal>
  )
}

export default AddTickerModal
