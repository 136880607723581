import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ItemBlock from '../../../../dashboard/GridBlock/ItemBlock'
import { useSelector } from 'react-redux'
import Title from '../../../../dashboard/Title/Title'
import { changeTicker } from '../../../../redux/actions/app-actions'
import styles from './GridItem.module.scss'

const GridItem = ({
  title,
  data,
  type,
  className,
  style,
  dispatch,
  root,
  children,
  ...rest
}) => {
  const width = parseInt(style.width, 10)
  const height = parseInt(style.height, 10) - 72

  const tickerSelect = useSelector(state => {
    try {
      return state.data[root].spec.ticker
    } catch {
      return ''
    }
  })
  const [ticker, setTicker] = useState(tickerSelect)

  const handleSetTicker = tickerIN => {
    if (tickerIN !== '' || tickerIN != null) {
      setTicker(tickerIN)
      rest.changeTicker(tickerIN, root)
    }
  }

  return (
    <div className={`grid-item ${className}`} style={style} {...rest}>
      <div className={styles.gridItemTitle}>
        <Title
          handleSetTicker={handleSetTicker}
          title={title}
          type={type}
          root={root}
          width={width}
        />
      </div>

      <div className={styles.gridItemGraph}>
        <ItemBlock
          root={root}
          ticker={ticker}
          type={type}
          data={data}
          width={width}
          height={height}
        />
      </div>
      {children}
    </div>
  )
}

GridItem.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  root: PropTypes.string.isRequired,
  children: PropTypes.array,
}

export default connect(null, {
  changeTicker,
})(GridItem)
