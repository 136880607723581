/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { useTable, useFilters, useSortBy } from 'react-table'
import { DefaultColumnFilter } from './filters'
import { SortColumnFilter } from './filters'
import { connect } from 'react-redux'
import { updateTableState } from 'redux/actions/app-actions'
import '../../styles.scss'
const styles = {
  _table: {
    backgroundColor: 'var(--background-primary)',
    width: '100%',
    maxWidth: '100%',
  },

  _th: {
    paddingLeft: '8px',
    paddingRight: '8px',
    paddingBottom: '4px',
    fontWeight: 500,
    fontSize: '13px',
    height: '24px',
    backgroundColor: 'var(--background-primary)',
    zIndex: 1000,
    color: 'var(--white)',
  },

  _tr_Head: {
    top: 0,
    position: 'sticky',
    whiteSpace: 'nowrap',
    height: '28px',
  },

  _td: {
    fontFamily: 'var(--font-family)',
    fontStyle: 'normal',
    paddingLeft: '8px',
    paddingRight: '8px',
    fontWeight: 400,
    fontSize: '13px',
    backgroundColor: '',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    color: 'var(--white)',
    height: '32px',
    lineHeight: '28px',
    boxSizing: 'border-box',
  },
}
function BasicTable({ root, initialState, columns, data, height, ...props }) {
  const defaultColumn = React.useMemo(
    () => ({
      SearchFilter: DefaultColumnFilter,
    }),
    []
  )
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
  } = useTable(
    {
      initialState,
      columns,
      data,
      defaultColumn,
    },
    useFilters,
    useSortBy
  )

  useEffect(() => {
    props.updateTableState(state, root)
  }, [state])
  const firstPageRows = rows.slice(0, 1000)
  return (
    <div
      className="table-div"
      style={{
        width: '100%',
        height: height + 30,
        background: 'var(--background-primary)',
        overflowX: 'scroll',
        overflowY: 'scroll',
        borderRadius: '4px',
      }}
    >
      <table style={styles._table} {...getTableProps()}>
        <thead style={styles._tr_Head}>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, i) => {
                return (
                  <th style={{ ...styles._th, width: column.width }}>
                    <div
                      style={{
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '4px',
                      }}
                    >
                      <div
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.render('Header')}
                        {column.isSorted ? (
                          <SortColumnFilter column={column} />
                        ) : (
                          // 'ㅤ'
                          ''
                        )}
                      </div>
                      {column.filter === 'search' ? (
                        <div>
                          {column.canFilter
                            ? column.render('SearchFilter')
                            : null}
                        </div>
                      ) : null}
                    </div>
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        <tbody style={{ overflow: 'scroll' }} {...getTableBodyProps()}>
          {firstPageRows.map((row, i) => {
            prepareRow(row)
            return (
              <tr className="table-row" {...row.getRowProps()}>
                {row.cells.map((cell, j) => {
                  return (
                    <td
                      className="table-cell"
                      title={cell.value}
                      {...cell.getCellProps({
                        style: {
                          // minWidth: cell.column.minWidth,
                          maxWidth: cell.column.width,
                          width: cell.column.width,
                          ...styles._td,
                        },
                      })}
                    >
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default connect(null, { updateTableState })(BasicTable)
