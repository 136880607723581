import {
  INSTITUTIONAL_INVESTOR,
  INSTITUTIONAL_INVESTOR_MARKET,
  SOCIAL_SENTIMENT_COMPANY,
  SOCIAL_SENTIMENT_TRENDING,
  MARKET_OVERVIEW,
  PRICE_CHART,
  GENERAL_NEWS,
  SCREENER,
  COMP_CHART,
  COMPANY_OVERVIEW,
  FINANCIAL_STATEMENTS,
  WATCH_LIST,
  RATIOS,
  ANALYTICS,
  INSIDER_TRADING,
  COMPANY_PROFILE,
  COMPANY_EARNINGS,
  ETF_VIEW,
  ETF_HOLDINGS,
  INDEXES,
  SEC_FILLINGS,
  CORPORATE_EVENTS,
  CALENDARS,
} from 'constants/ViewsConstants'
import { NewsView, TrendingNewsView } from '../views/NewsView/index'
import { Screener } from '../views/Screener/Screener'
import { PriceChart } from '../views/PriceCharts/PriceChart'
import { GeneralNewsFeed } from '../views/NewsView/pages/GeneralNews'
import { CompChart } from '../views/CompChart/CompChart'
import { CompanyOverview } from '../views/CompanyOverview/CompanyOverview'
import { FinancialStatementsView } from '../views/FinancialStatements/FinancialStatements'
import { InsiderTrading } from '../views/InsiderTrading/InsiderTrading'
import Watchlist from '../views/Watchlist/Watchlist'
import { Analytics } from '../views/CompanyAnalytics/CompanyAnalytics'
import {
  InstitutionalView,
  StockView,
} from '../views/InstitutionalOwnership/index'
import { MarketOverview } from '../views/MarketOverview/MarketOverview'
import { Ratios } from '../views/Ratios/Ratios'
import { CompanyProfile } from '../views/CompanyProfile/CompanyProfile'
import { EarningsView } from '../views/Earnings/Earnings'
import { ETF } from '../views/ETF/ETFInfo/ETF'
import { ETFHoldings } from '../views/ETF/ETFHoldings/ETFHoldings'
import { Indexes } from 'pages/views/Indexes/Indexes'
import { SecFillings } from 'pages/views/SecFillings/SecFillings'
import { Events } from 'pages/views/CorporateEvents/rootEvents'
import { Calendars } from 'pages/views/Calendars/rootCalendars'

const urlConstant = '/terminal/'

export const MarketViews = [
  {
    label: MARKET_OVERVIEW,
    View: MarketOverview,
    navLinkTo: `${urlConstant}${MARKET_OVERVIEW}`,
  },
  {
    label: INDEXES,
    View: Indexes,
    navLinkTo: `${urlConstant}${INDEXES}`,
  },
  {
    label: SOCIAL_SENTIMENT_TRENDING,
    View: TrendingNewsView,
    navLinkTo: `${urlConstant}${SOCIAL_SENTIMENT_TRENDING}`,
  },
  {
    label: CORPORATE_EVENTS,
    View: Events,
    navLinkTo: `${urlConstant}${CORPORATE_EVENTS}`,
  },
  {
    label: CALENDARS,
    View: Calendars,
    navLinkTo: `${urlConstant}${CALENDARS}`,
  },
]

export const NewsViews = [
  {
    label: SOCIAL_SENTIMENT_TRENDING,
    View: TrendingNewsView,
    navLinkTo: `${urlConstant}${SOCIAL_SENTIMENT_TRENDING}`,
  },
]

export const InstitutionalViews = [
  {
    label: INSTITUTIONAL_INVESTOR_MARKET,
    View: InstitutionalView,
    navLinkTo: `${urlConstant}${INSTITUTIONAL_INVESTOR_MARKET}`,
  },
]

export const ETFViews = [
  {
    label: ETF_VIEW,
    View: ETF,
    navLinkTo: `${urlConstant}${ETF_VIEW}`,
  },
  {
    label: ETF_HOLDINGS,
    View: ETFHoldings,
    navLinkTo: `${urlConstant}${ETF_HOLDINGS}`,
  },
]

export const TechnicalViews = [
  {
    label: COMPANY_OVERVIEW,
    View: CompanyOverview,
    navLinkTo: `${urlConstant}${COMPANY_OVERVIEW}`,
  },

  {
    label: COMPANY_PROFILE,
    View: CompanyProfile,
    navLinkTo: `${urlConstant}${COMPANY_PROFILE}`,
  },
  {
    label: FINANCIAL_STATEMENTS,
    View: FinancialStatementsView,
    navLinkTo: `${urlConstant}${FINANCIAL_STATEMENTS}`,
  },
  {
    label: RATIOS,
    View: Ratios,
    navLinkTo: `${urlConstant}${RATIOS}`,
  },
  {
    label: COMPANY_EARNINGS,
    View: EarningsView,
    navLinkTo: `${urlConstant}${COMPANY_EARNINGS}`,
  },
  {
    label: INSIDER_TRADING,
    View: InsiderTrading,
    navLinkTo: `${urlConstant}${INSIDER_TRADING}`,
  },
  {
    label: SOCIAL_SENTIMENT_COMPANY,
    View: NewsView,
    navLinkTo: `${urlConstant}${SOCIAL_SENTIMENT_COMPANY}`,
  },
  {
    label: INSTITUTIONAL_INVESTOR,
    View: StockView,
    navLinkTo: `${urlConstant}${INSTITUTIONAL_INVESTOR}`,
  },
  {
    label: ANALYTICS,
    View: Analytics,
    navLinkTo: `${urlConstant}${ANALYTICS}`,
  },
  {
    label: SEC_FILLINGS,
    View: SecFillings,
    navLinkTo: `${urlConstant}${SEC_FILLINGS}`,
  },
  {
    label: PRICE_CHART,
    View: PriceChart,
    navLinkTo: `${urlConstant}${PRICE_CHART}`,
  },
  {
    label: COMP_CHART,
    View: CompChart,
    navLinkTo: `${urlConstant}${COMP_CHART}`,
  },
]

export const ToolViews = [
  {
    id: SCREENER,
    label: SCREENER,
    View: Screener,
  },
  {
    id: WATCH_LIST,
    label: WATCH_LIST,
    View: Watchlist,
  },
]

export const ViewMapper = {
  [SOCIAL_SENTIMENT_COMPANY]: NewsView,
  [INSTITUTIONAL_INVESTOR]: StockView,
  [INSTITUTIONAL_INVESTOR_MARKET]: InstitutionalView,
  [SOCIAL_SENTIMENT_TRENDING]: TrendingNewsView,
  [MARKET_OVERVIEW]: MarketOverview,
  [PRICE_CHART]: PriceChart,
  [GENERAL_NEWS]: GeneralNewsFeed,
  [SCREENER]: Screener,
  [COMP_CHART]: CompChart,
  [COMPANY_OVERVIEW]: CompanyOverview,
  [FINANCIAL_STATEMENTS]: FinancialStatementsView,
  [WATCH_LIST]: Watchlist,
  [RATIOS]: Ratios,
  [ANALYTICS]: Analytics,
  [INSIDER_TRADING]: InsiderTrading,
  [COMPANY_PROFILE]: CompanyProfile,
  [COMPANY_EARNINGS]: EarningsView,
  [ETF_VIEW]: ETF,
  [ETF_HOLDINGS]: ETFHoldings,
  [INDEXES]: Indexes,
  [SEC_FILLINGS]: SecFillings,
}
