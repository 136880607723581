import {
  BALANCE_SHEET,
  INCOME_STATEMENT,
  CASH_FLOW_STATEMENT,
} from 'constants/tableConstants'

import {
  balanceColumnKeys,
  cashColumnKeys,
  incomeColumnKeys,
  incomeGrowthColumnKeys,
} from './components/FNTable/columns'

export const FinancialStatements = [
  {
    label: 'Income Statement',
    type: INCOME_STATEMENT,
    columns: incomeColumnKeys || [],
    // growthColumns: incomeGrowthColumnKeys || [],
  },
  {
    label: 'Balance Sheet',
    type: BALANCE_SHEET,
    columns: balanceColumnKeys || [],
    // growthColumns: incomeGrowthColumnKeys || [],
  },
  {
    label: 'Cash Flow Statement',
    type: CASH_FLOW_STATEMENT,
    columns: cashColumnKeys || [],
    // growthColumns: incomeGrowthColumnKeys || [],
  },
]

export const FINANCIAL_STATEMENTS_BASE_URL = 'Financial Statements'
