// export const topTickerList = [
//   'AAPL',
//   'MSFT',
//   'GOOGL',
//   'AMZN',
//   'BRK-A',
//   'XOM',
//   'V',
//   'UNH',
//   'JNJ',
//   'TSLA',
//   'JPM',
//   'WMT',
//   'MA',
//   'META',
//   'PG',
//   'CVX',
//   'LLY',
//   'HD',
//   'BABA',
//   'BAC',
//   'KO',
//   'PFE',
//   'PEP',
//   'ORCL',
//   'TM',
//   'COST',
//   'NFLX',
// ]


export const topTickerList = [
  {
    value: 'AAPL',
    label: 'AAPL,  Apple Inc.',
    name: 'Apple Inc.',
    symbol: 'AAPL',
  },
  {
    value: 'MSFT',
    label: 'MSFT,  Microsoft Corporation',
    name: 'Microsoft Corporation',
    symbol: 'MSFT',
  },
  {
    value: 'GOOG',
    label: 'GOOG,  Alphabet Inc.',
    name: 'Alphabet Inc.',
    symbol: 'GOOG',
  },
  {
    value: 'AMZN',
    label: 'AMZN,  Amazon.com, Inc.',
    name: 'Amazon.com, Inc.',
    symbol: 'AMZN',
  },
  {
    value: 'BRK-A',
    label: 'BRK-A,  Berkshire Hathaway Inc.',
    name: 'Berkshire Hathaway Inc.',
    symbol: 'BRK-A',
  },
  {
    value: 'XOM',
    label: 'XOM, Exxon Mobil Corporation',
    name: 'Exxon Mobil Corporation',
    symbol: 'XOM',
  },
  {
    value: 'V',
    label: 'V,  Visa Inc.',
    name: 'Visa Inc.',
    symbol: 'V',
  },
  {
    value: 'UNH',
    label: 'UNH, UnitedHealth Group Incorporated',
    name: 'UnitedHealth Group Incorporated',
    symbol: 'UNH',
  },
  {
    value: 'JNJ',
    label: 'JNJ,  Johnson & Johnson',
    name: 'Johnson & Johnson',
    symbol: 'JNJ',
  },
  {
    value: 'TSLA',
    label: 'TSLA,  Tesla, Inc.',
    name: 'Tesla, Inc.',
    symbol: 'TSLA',
  },
  {
    value: 'JPM',
    label: 'JPM, JPMorgan Chase & Co.',
    name: 'JPMorgan Chase & Co.',
    symbol: 'JPM',
  },
  {
    value: 'WMT',
    label: 'WMT,  Walmart Inc.',
    name: 'Walmart Inc.',
    symbol: 'WMT',
  },
  {
    value: 'MA',
    label: 'MA,  Mastercard Incorporated',
    name: 'Mastercard Incorporated',
    symbol: 'MA',
  },
  {
    value: 'META',
    label: 'META,  Meta Platforms, Inc.',
    name: 'Meta Platforms, Inc.',
    symbol: 'META',
  },
  {
    value: 'PG',
    label: 'PG, The Procter & Gamble Company',
    name: 'The Procter & Gamble Company',
    symbol: 'PG',
  },
  {
    value: 'CVX',
    label: 'CVX, Chevron Corporation',
    name: 'Chevron Corporation',
    symbol: 'CVX',
  },
  {
    value: 'LLY',
    label: 'LLY, Eli Lilly and Company',
    name: 'Eli Lilly and Company',
    symbol: 'LLY',
  },
  {
    value: 'HD',
    label: 'HD, The Home Depot, Inc.',
    name: 'The Home Depot, Inc.',
    symbol: 'HD',
  },
  {
    value: 'BABA',
    label: 'BABA, Alibaba Group Holding Limited',
    name: 'Alibaba Group Holding Limited',
    symbol: 'BABA',
  },
  {
    value: 'BAC',
    label: 'BAC, Bank of America Corporation',
    name: 'Bank of America Corporation',
    symbol: 'BAC',
  },
  {
    value: 'KO',
    label: 'KO, The Coca-Cola Company',
    name: 'The Coca-Cola Company',
    symbol: 'KO',
  },
  {
    value: 'PFE',
    label: 'PFE, Pfizer Inc.',
    name: 'Pfizer Inc.',
    symbol: 'PFE',
  },
  {
    value: 'PEP',
    label: 'PEP, PepsiCo, Inc.',
    name: 'PepsiCo, Inc.',
    symbol: 'PEP',
  },
  {
    value: 'ORCL',
    label: 'ORCL, Oracle Corporation',
    name: 'Oracle Corporation',
    symbol: 'ORCL',
  },
  {
    value: 'TM',
    label: 'TM, Toyota Motor Corporation',
    name: 'Toyota Motor Corporation',
    symbol: 'TM',
  },
  {
    value: 'COST',
    label: 'COST, Costco Wholesale Corporation',
    name: 'Costco Wholesale Corporation',
    symbol: 'COST',
  },
  {
    value: 'NFLX',
    label: 'NFLX,  Netflix, Inc.',
    name: 'Netflix, Inc.',
    symbol: 'NFLX',
  },
]

export const tickerList = [
  {
    value: 'SPY',
    label: 'SPY,  SPDR S&P 500 ETF Trust',
    name: 'SPDR S&P 500 ETF Trust',
    symbol: 'SPY',
  },
  {
    value: 'CMCSA',
    label: 'CMCSA,  Comcast Corporation',
    name: 'Comcast Corporation',
    symbol: 'CMCSA',
  },
  {
    value: 'KMI',
    label: 'KMI,  Kinder Morgan, Inc.',
    name: 'Kinder Morgan, Inc.',
    symbol: 'KMI',
  },
  {
    value: 'INTC',
    label: 'INTC,  Intel Corporation',
    name: 'Intel Corporation',
    symbol: 'INTC',
  },
  {
    value: 'MU',
    label: 'MU,  Micron Technology, Inc.',
    name: 'Micron Technology, Inc.',
    symbol: 'MU',
  },
  {
    value: 'GDX',
    label: 'GDX,  VanEck Gold Miners ETF',
    name: 'VanEck Gold Miners ETF',
    symbol: 'GDX',
  },
  {
    value: 'GE',
    label: 'GE,  General Electric Company',
    name: 'General Electric Company',
    symbol: 'GE',
  },
  {
    value: 'BAC',
    label: 'BAC,  Bank of America Corporation',
    name: 'Bank of America Corporation',
    symbol: 'BAC',
  },
  {
    value: 'EEM',
    label: 'EEM,  iShares MSCI Emerging Markets ETF',
    name: 'iShares MSCI Emerging Markets ETF',
    symbol: 'EEM',
  },
  {
    value: 'XLF',
    label: 'XLF,  Financial Select Sector SPDR Fund',
    name: 'Financial Select Sector SPDR Fund',
    symbol: 'XLF',
  },
  {
    value: 'AAPL',
    label: 'AAPL,  Apple Inc.',
    name: 'Apple Inc.',
    symbol: 'AAPL',
  },
  {
    value: 'MSFT',
    label: 'MSFT,  Microsoft Corporation',
    name: 'Microsoft Corporation',
    symbol: 'MSFT',
  },
  {
    value: 'SIRI',
    label: 'SIRI,  Sirius XM Holdings Inc.',
    name: 'Sirius XM Holdings Inc.',
    symbol: 'SIRI',
  },
  {
    value: 'HPQ',
    label: 'HPQ,  HP Inc.',
    name: 'HP Inc.',
    symbol: 'HPQ',
  },
  {
    value: 'CX',
    label: 'CX,  CEMEX, S.A.B. de C.V.',
    name: 'CEMEX, S.A.B. de C.V.',
    symbol: 'CX',
  },
  {
    value: 'EFA',
    label: 'EFA,  iShares MSCI EAFE ETF',
    name: 'iShares MSCI EAFE ETF',
    symbol: 'EFA',
  },
  {
    value: 'CZR',
    label: 'CZR,  Caesars Entertainment, Inc.',
    name: 'Caesars Entertainment, Inc.',
    symbol: 'CZR',
  },
  {
    value: 'QQQ',
    label: 'QQQ,  Invesco QQQ Trust',
    name: 'Invesco QQQ Trust',
    symbol: 'QQQ',
  },
  {
    value: 'F',
    label: 'F,  Ford Motor Company',
    name: 'Ford Motor Company',
    symbol: 'F',
  },
  {
    value: 'AMD',
    label: 'AMD,  Advanced Micro Devices, Inc.',
    name: 'Advanced Micro Devices, Inc.',
    symbol: 'AMD',
  },
  {
    value: 'SNAP',
    label: 'SNAP,  Snap Inc.',
    name: 'Snap Inc.',
    symbol: 'SNAP',
  },
  {
    value: 'WFC',
    label: 'WFC,  Wells Fargo & Company',
    name: 'Wells Fargo & Company',
    symbol: 'WFC',
  },
  {
    value: 'AIG',
    label: 'AIG,  American International Group, Inc.',
    name: 'American International Group, Inc.',
    symbol: 'AIG',
  },
  {
    value: 'T',
    label: 'T,  AT&T Inc.',
    name: 'AT&T Inc.',
    symbol: 'T',
  },
  {
    value: 'C',
    label: 'C,  Citigroup Inc.',
    name: 'Citigroup Inc.',
    symbol: 'C',
  },
  {
    value: 'VALE',
    label: 'VALE,  Vale S.A.',
    name: 'Vale S.A.',
    symbol: 'VALE',
  },
  {
    value: 'MS',
    label: 'MS,  Morgan Stanley',
    name: 'Morgan Stanley',
    symbol: 'MS',
  },
  {
    value: 'JPM',
    label: 'JPM,  JPMorgan Chase & Co.',
    name: 'JPMorgan Chase & Co.',
    symbol: 'JPM',
  },
  {
    value: 'ORCL',
    label: 'ORCL,  Oracle Corporation',
    name: 'Oracle Corporation',
    symbol: 'ORCL',
  },
  {
    value: 'NKE',
    label: 'NKE,  NIKE, Inc.',
    name: 'NIKE, Inc.',
    symbol: 'NKE',
  },
  {
    value: 'VWO',
    label: 'VWO,  Vanguard Emerging Markets Stock Index Fund',
    name: 'Vanguard Emerging Markets Stock Index Fund',
    symbol: 'VWO',
  },
  {
    value: 'PG',
    label: 'PG,  The Procter & Gamble Company',
    name: 'The Procter & Gamble Company',
    symbol: 'PG',
  },
  {
    value: 'FXI',
    label: 'FXI,  iShares China Large-Cap ETF',
    name: 'iShares China Large-Cap ETF',
    symbol: 'FXI',
  },
  {
    value: 'IWM',
    label: 'IWM,  iShares Russell 2000 ETF',
    name: 'iShares Russell 2000 ETF',
    symbol: 'IWM',
  },
  {
    value: 'GSM',
    label: 'GSM,  Ferroglobe PLC',
    name: 'Ferroglobe PLC',
    symbol: 'GSM',
  },
  {
    value: 'BBD',
    label: 'BBD,  Banco Bradesco S.A.',
    name: 'Banco Bradesco S.A.',
    symbol: 'BBD',
  },
  {
    value: 'PFE',
    label: 'PFE,  Pfizer Inc.',
    name: 'Pfizer Inc.',
    symbol: 'PFE',
  },
  {
    value: 'JD',
    label: 'JD,  JD.com, Inc.',
    name: 'JD.com, Inc.',
    symbol: 'JD',
  },
  {
    value: 'NOK',
    label: 'NOK,  Nokia Oyj',
    name: 'Nokia Oyj',
    symbol: 'NOK',
  },
  {
    value: 'AMLP',
    label: 'AMLP,  Alerian MLP ETF',
    name: 'Alerian MLP ETF',
    symbol: 'AMLP',
  },
  {
    value: 'AVGO',
    label: 'AVGO,  Broadcom Inc.',
    name: 'Broadcom Inc.',
    symbol: 'AVGO',
  },
  {
    value: 'AMAT',
    label: 'AMAT,  Applied Materials, Inc.',
    name: 'Applied Materials, Inc.',
    symbol: 'AMAT',
  },
  {
    value: 'UVXY',
    label: 'UVXY,  ProShares Ultra VIX Short-Term Futures ETF',
    name: 'ProShares Ultra VIX Short-Term Futures ETF',
    symbol: 'UVXY',
  },
  {
    value: 'VXX',
    label: 'VXX,  iPath Series B S&P 500 VIX Short-Term Futures ETN',
    name: 'iPath Series B S&P 500 VIX Short-Term Futures ETN',
    symbol: 'VXX',
  },
  {
    value: 'VEA',
    label: 'VEA,  Vanguard Developed Markets Index Fund',
    name: 'Vanguard Developed Markets Index Fund',
    symbol: 'VEA',
  },
  {
    value: 'XOM',
    label: 'XOM,  Exxon Mobil Corporation',
    name: 'Exxon Mobil Corporation',
    symbol: 'XOM',
  },
  {
    value: 'QCOM',
    label: 'QCOM,  QUALCOMM Incorporated',
    name: 'QUALCOMM Incorporated',
    symbol: 'QCOM',
  },
  {
    value: 'VIPS',
    label: 'VIPS,  Vipshop Holdings Limited',
    name: 'Vipshop Holdings Limited',
    symbol: 'VIPS',
  },
  {
    value: 'GLD',
    label: 'GLD,  SPDR Gold Shares',
    name: 'SPDR Gold Shares',
    symbol: 'GLD',
  },
  {
    value: 'CSCO',
    label: 'CSCO,  Cisco Systems, Inc.',
    name: 'Cisco Systems, Inc.',
    symbol: 'CSCO',
  },
  {
    value: 'AXP',
    label: 'AXP,  American Express Company',
    name: 'American Express Company',
    symbol: 'AXP',
  },
  {
    value: 'BMY',
    label: 'BMY,  Bristol-Myers Squibb Company',
    name: 'Bristol-Myers Squibb Company',
    symbol: 'BMY',
  },
  {
    value: 'V',
    label: 'V,  Visa Inc.',
    name: 'Visa Inc.',
    symbol: 'V',
  },
  {
    value: 'USO',
    label: 'USO,  United States Oil Fund, LP',
    name: 'United States Oil Fund, LP',
    symbol: 'USO',
  },
  {
    value: 'GRPN',
    label: 'GRPN,  Groupon, Inc.',
    name: 'Groupon, Inc.',
    symbol: 'GRPN',
  },
  {
    value: 'OIH',
    label: 'OIH,  VanEck Oil Services ETF',
    name: 'VanEck Oil Services ETF',
    symbol: 'OIH',
  },
  {
    value: 'GERN',
    label: 'GERN,  Geron Corporation',
    name: 'Geron Corporation',
    symbol: 'GERN',
  },
  {
    value: 'KEY',
    label: 'KEY,  KeyCorp',
    name: 'KeyCorp',
    symbol: 'KEY',
  },
  {
    value: 'RF',
    label: 'RF,  Regions Financial Corporation',
    name: 'Regions Financial Corporation',
    symbol: 'RF',
  },
  {
    value: 'KR',
    label: 'KR,  The Kroger Co.',
    name: 'The Kroger Co.',
    symbol: 'KR',
  },
  {
    value: 'HAL',
    label: 'HAL,  Halliburton Company',
    name: 'Halliburton Company',
    symbol: 'HAL',
  },
  {
    value: 'BABA',
    label: 'BABA,  Alibaba Group Holding Limited',
    name: 'Alibaba Group Holding Limited',
    symbol: 'BABA',
  },
  {
    value: 'MRO',
    label: 'MRO,  Marathon Oil Corporation',
    name: 'Marathon Oil Corporation',
    symbol: 'MRO',
  },
  {
    value: 'GILD',
    label: 'GILD,  Gilead Sciences, Inc.',
    name: 'Gilead Sciences, Inc.',
    symbol: 'GILD',
  },
  {
    value: 'IEMG',
    label: 'IEMG,  iShares Core MSCI Emerging Markets ETF',
    name: 'iShares Core MSCI Emerging Markets ETF',
    symbol: 'IEMG',
  },
  {
    value: 'GM',
    label: 'GM,  General Motors Company',
    name: 'General Motors Company',
    symbol: 'GM',
  },
  {
    value: 'FCX',
    label: 'FCX,  Freeport-McMoRan Inc.',
    name: 'Freeport-McMoRan Inc.',
    symbol: 'FCX',
  },
  {
    value: 'CRM',
    label: 'CRM,  Salesforce, Inc.',
    name: 'Salesforce, Inc.',
    symbol: 'CRM',
  },
  {
    value: 'ATVI',
    label: 'ATVI,  Activision Blizzard, Inc.',
    name: 'Activision Blizzard, Inc.',
    symbol: 'ATVI',
  },
  {
    value: 'SQ',
    label: 'SQ,  Block, Inc.',
    name: 'Block, Inc.',
    symbol: 'SQ',
  },
  {
    value: 'XLK',
    label: 'XLK,  Technology Select Sector SPDR Fund',
    name: 'Technology Select Sector SPDR Fund',
    symbol: 'XLK',
  },
  {
    value: 'NUE',
    label: 'NUE,  Nucor Corporation',
    name: 'Nucor Corporation',
    symbol: 'NUE',
  },
  {
    value: 'XOP',
    label: 'XOP,  SPDR S&P Oil & Gas Exploration & Production ETF',
    name: 'SPDR S&P Oil & Gas Exploration & Production ETF',
    symbol: 'XOP',
  },
  {
    value: 'SWN',
    label: 'SWN,  Southwestern Energy Company',
    name: 'Southwestern Energy Company',
    symbol: 'SWN',
  },
  {
    value: 'LOW',
    label: "LOW,  Lowe's Companies, Inc.",
    name: "Lowe's Companies, Inc.",
    symbol: 'LOW',
  },
  {
    value: 'RAD',
    label: 'RAD,  Rite Aid Corporation',
    name: 'Rite Aid Corporation',
    symbol: 'RAD',
  },
  {
    value: 'VEON',
    label: 'VEON,  VEON Ltd.',
    name: 'VEON Ltd.',
    symbol: 'VEON',
  },
  {
    value: 'HYG',
    label: 'HYG,  iShares iBoxx $ High Yield Corporate Bond ETF',
    name: 'iShares iBoxx $ High Yield Corporate Bond ETF',
    symbol: 'HYG',
  },
  {
    value: 'JNK',
    label: 'JNK,  SPDR Bloomberg High Yield Bond ETF',
    name: 'SPDR Bloomberg High Yield Bond ETF',
    symbol: 'JNK',
  },
  {
    value: 'EWJ',
    label: 'EWJ,  iShares MSCI Japan ETF',
    name: 'iShares MSCI Japan ETF',
    symbol: 'EWJ',
  },
  {
    value: 'XLU',
    label: 'XLU,  Utilities Select Sector SPDR Fund',
    name: 'Utilities Select Sector SPDR Fund',
    symbol: 'XLU',
  },
  {
    value: 'SLB',
    label: 'SLB,  Schlumberger Limited',
    name: 'Schlumberger Limited',
    symbol: 'SLB',
  },
  {
    value: 'FLEX',
    label: 'FLEX,  Flex Ltd.',
    name: 'Flex Ltd.',
    symbol: 'FLEX',
  },
  {
    value: 'FOXA',
    label: 'FOXA,  Fox Corporation',
    name: 'Fox Corporation',
    symbol: 'FOXA',
  },
  {
    value: 'ABBV',
    label: 'ABBV,  AbbVie Inc.',
    name: 'AbbVie Inc.',
    symbol: 'ABBV',
  },
  {
    value: 'GIS',
    label: 'GIS,  General Mills, Inc.',
    name: 'General Mills, Inc.',
    symbol: 'GIS',
  },
  {
    value: 'VZ',
    label: 'VZ,  Verizon Communications Inc.',
    name: 'Verizon Communications Inc.',
    symbol: 'VZ',
  },
  {
    value: 'XRX',
    label: 'XRX,  Xerox Holdings Corporation',
    name: 'Xerox Holdings Corporation',
    symbol: 'XRX',
  },
  {
    value: 'CVLT',
    label: 'CVLT,  Commvault Systems, Inc.',
    name: 'Commvault Systems, Inc.',
    symbol: 'CVLT',
  },
  {
    value: 'IEFA',
    label: 'IEFA,  iShares Core MSCI EAFE ETF',
    name: 'iShares Core MSCI EAFE ETF',
    symbol: 'IEFA',
  },
  {
    value: 'X',
    label: 'X,  United States Steel Corporation',
    name: 'United States Steel Corporation',
    symbol: 'X',
  },
  {
    value: 'MAT',
    label: 'MAT,  Mattel, Inc.',
    name: 'Mattel, Inc.',
    symbol: 'MAT',
  },
  {
    value: 'DVN',
    label: 'DVN,  Devon Energy Corporation',
    name: 'Devon Energy Corporation',
    symbol: 'DVN',
  },
  {
    value: 'BKLN',
    label: 'BKLN,  Invesco Senior Loan ETF',
    name: 'Invesco Senior Loan ETF',
    symbol: 'BKLN',
  },
  {
    value: 'IBN',
    label: 'IBN,  ICICI Bank Limited',
    name: 'ICICI Bank Limited',
    symbol: 'IBN',
  },
  {
    value: 'MPC',
    label: 'MPC,  Marathon Petroleum Corporation',
    name: 'Marathon Petroleum Corporation',
    symbol: 'MPC',
  },
  {
    value: 'EZU',
    label: 'EZU,  iShares MSCI Eurozone ETF',
    name: 'iShares MSCI Eurozone ETF',
    symbol: 'EZU',
  },
  {
    value: 'PM',
    label: 'PM,  Philip Morris International Inc.',
    name: 'Philip Morris International Inc.',
    symbol: 'PM',
  },
  {
    value: 'MSCI',
    label: 'MSCI,  MSCI Inc.',
    name: 'MSCI Inc.',
    symbol: 'MSCI',
  },
  {
    value: 'RDN',
    label: 'RDN,  Radian Group Inc.',
    name: 'Radian Group Inc.',
    symbol: 'RDN',
  },
  {
    value: 'KO',
    label: 'KO,  The Coca-Cola Company',
    name: 'The Coca-Cola Company',
    symbol: 'KO',
  },
  {
    value: 'SRC',
    label: 'SRC,  Spirit Realty Capital, Inc.',
    name: 'Spirit Realty Capital, Inc.',
    symbol: 'SRC',
  },
  {
    value: 'COP',
    label: 'COP,  ConocoPhillips',
    name: 'ConocoPhillips',
    symbol: 'COP',
  },
  {
    value: 'CHK',
    label: 'CHK,  Chesapeake Energy Corporation',
    name: 'Chesapeake Energy Corporation',
    symbol: 'CHK',
  },
  {
    value: 'DIS',
    label: 'DIS,  The Walt Disney Company',
    name: 'The Walt Disney Company',
    symbol: 'DIS',
  },
  {
    value: 'LEN',
    label: 'LEN,  Lennar Corporation',
    name: 'Lennar Corporation',
    symbol: 'LEN',
  },
  {
    value: 'CVX',
    label: 'CVX,  Chevron Corporation',
    name: 'Chevron Corporation',
    symbol: 'CVX',
  },
  {
    value: 'PYPL',
    label: 'PYPL,  PayPal Holdings, Inc.',
    name: 'PayPal Holdings, Inc.',
    symbol: 'PYPL',
  },
  {
    value: 'GDXJ',
    label: 'GDXJ,  VanEck Junior Gold Miners ETF',
    name: 'VanEck Junior Gold Miners ETF',
    symbol: 'GDXJ',
  },
  {
    value: 'AUY',
    label: 'AUY,  Yamana Gold Inc.',
    name: 'Yamana Gold Inc.',
    symbol: 'AUY',
  },
  {
    value: 'DBEF',
    label: 'DBEF,  Xtrackers MSCI EAFE Hedged Equity ETF',
    name: 'Xtrackers MSCI EAFE Hedged Equity ETF',
    symbol: 'DBEF',
  },
  {
    value: 'WMT',
    label: 'WMT,  Walmart Inc.',
    name: 'Walmart Inc.',
    symbol: 'WMT',
  },
  {
    value: 'CLF',
    label: 'CLF,  Cleveland-Cliffs Inc.',
    name: 'Cleveland-Cliffs Inc.',
    symbol: 'CLF',
  },
  {
    value: 'MRK',
    label: 'MRK,  Merck & Co., Inc.',
    name: 'Merck & Co., Inc.',
    symbol: 'MRK',
  },
  {
    value: 'MDLZ',
    label: 'MDLZ,  Mondelez International, Inc.',
    name: 'Mondelez International, Inc.',
    symbol: 'MDLZ',
  },
  {
    value: 'MGM',
    label: 'MGM,  MGM Resorts International',
    name: 'MGM Resorts International',
    symbol: 'MGM',
  },
  {
    value: 'GFI',
    label: 'GFI,  Gold Fields Limited',
    name: 'Gold Fields Limited',
    symbol: 'GFI',
  },
  {
    value: 'KGC',
    label: 'KGC,  Kinross Gold Corporation',
    name: 'Kinross Gold Corporation',
    symbol: 'KGC',
  },
  {
    value: 'SCHW',
    label: 'SCHW,  The Charles Schwab Corporation',
    name: 'The Charles Schwab Corporation',
    symbol: 'SCHW',
  },
  {
    value: 'NBR',
    label: 'NBR,  Nabors Industries Ltd.',
    name: 'Nabors Industries Ltd.',
    symbol: 'NBR',
  },
  {
    value: 'NVDA',
    label: 'NVDA,  NVIDIA Corporation',
    name: 'NVIDIA Corporation',
    symbol: 'NVDA',
  },
  {
    value: 'JNJ',
    label: 'JNJ,  Johnson & Johnson',
    name: 'Johnson & Johnson',
    symbol: 'JNJ',
  },
  {
    value: 'WDC',
    label: 'WDC,  Western Digital Corporation',
    name: 'Western Digital Corporation',
    symbol: 'WDC',
  },
  {
    value: 'BSX',
    label: 'BSX,  Boston Scientific Corporation',
    name: 'Boston Scientific Corporation',
    symbol: 'BSX',
  },
  {
    value: 'ON',
    label: 'ON,  ON Semiconductor Corporation',
    name: 'ON Semiconductor Corporation',
    symbol: 'ON',
  },
  {
    value: 'ITUB',
    label: 'ITUB,  Itaú Unibanco Holding S.A.',
    name: 'Itaú Unibanco Holding S.A.',
    symbol: 'ITUB',
  },
  {
    value: 'BK',
    label: 'BK,  The Bank of New York Mellon Corporation',
    name: 'The Bank of New York Mellon Corporation',
    symbol: 'BK',
  },
  {
    value: 'HMY',
    label: 'HMY,  Harmony Gold Mining Company Limited',
    name: 'Harmony Gold Mining Company Limited',
    symbol: 'HMY',
  },
  {
    value: 'APA',
    label: 'APA,  APA Corporation',
    name: 'APA Corporation',
    symbol: 'APA',
  },
  {
    value: 'NGD',
    label: 'NGD,  New Gold Inc.',
    name: 'New Gold Inc.',
    symbol: 'NGD',
  },
  {
    value: 'KOS',
    label: 'KOS,  Kosmos Energy Ltd.',
    name: 'Kosmos Energy Ltd.',
    symbol: 'KOS',
  },
  {
    value: 'DAL',
    label: 'DAL,  Delta Air Lines, Inc.',
    name: 'Delta Air Lines, Inc.',
    symbol: 'DAL',
  },
  {
    value: 'INFY',
    label: 'INFY,  Infosys Limited',
    name: 'Infosys Limited',
    symbol: 'INFY',
  },
  {
    value: 'CCL',
    label: 'CCL,  Carnival Corporation & plc',
    name: 'Carnival Corporation & plc',
    symbol: 'CCL',
  },
  {
    value: 'MFGP',
    label: 'MFGP,  Micro Focus International plc',
    name: 'Micro Focus International plc',
    symbol: 'MFGP',
  },
  {
    value: 'PBR',
    label: 'PBR,  Petróleo Brasileiro S.A. - Petrobras',
    name: 'Petróleo Brasileiro S.A. - Petrobras',
    symbol: 'PBR',
  },
  {
    value: 'LPI',
    label: 'LPI,  Laredo Petroleum, Inc.',
    name: 'Laredo Petroleum, Inc.',
    symbol: 'LPI',
  },
  {
    value: 'ABT',
    label: 'ABT,  Abbott Laboratories',
    name: 'Abbott Laboratories',
    symbol: 'ABT',
  },
  {
    value: 'FITB',
    label: 'FITB,  Fifth Third Bancorp',
    name: 'Fifth Third Bancorp',
    symbol: 'FITB',
  },
  {
    value: 'PTEN',
    label: 'PTEN,  Patterson-UTI Energy, Inc.',
    name: 'Patterson-UTI Energy, Inc.',
    symbol: 'PTEN',
  },
  {
    value: 'USB',
    label: 'USB,  U.S. Bancorp',
    name: 'U.S. Bancorp',
    symbol: 'USB',
  },
  {
    value: 'BA',
    label: 'BA,  The Boeing Company',
    name: 'The Boeing Company',
    symbol: 'BA',
  },
  {
    value: 'GGB',
    label: 'GGB,  Gerdau S.A.',
    name: 'Gerdau S.A.',
    symbol: 'GGB',
  },
  {
    value: 'JBLU',
    label: 'JBLU,  JetBlue Airways Corporation',
    name: 'JetBlue Airways Corporation',
    symbol: 'JBLU',
  },
  {
    value: 'EBAY',
    label: 'EBAY,  eBay Inc.',
    name: 'eBay Inc.',
    symbol: 'EBAY',
  },
  {
    value: 'HBAN',
    label: 'HBAN,  Huntington Bancshares Incorporated',
    name: 'Huntington Bancshares Incorporated',
    symbol: 'HBAN',
  },
  {
    value: 'DB',
    label: 'DB,  Deutsche Bank Aktiengesellschaft',
    name: 'Deutsche Bank Aktiengesellschaft',
    symbol: 'DB',
  },
  {
    value: 'MRVL',
    label: 'MRVL,  Marvell Technology, Inc.',
    name: 'Marvell Technology, Inc.',
    symbol: 'MRVL',
  },
  {
    value: 'WBA',
    label: 'WBA,  Walgreens Boots Alliance, Inc.',
    name: 'Walgreens Boots Alliance, Inc.',
    symbol: 'WBA',
  },
  {
    value: 'CDNA',
    label: 'CDNA,  CareDx, Inc',
    name: 'CareDx, Inc',
    symbol: 'CDNA',
  },
  {
    value: 'RSX',
    label: 'RSX,  VanEck Russia ETF',
    name: 'VanEck Russia ETF',
    symbol: 'RSX',
  },
  {
    value: 'KNX',
    label: 'KNX,  Knight-Swift Transportation Holdings Inc.',
    name: 'Knight-Swift Transportation Holdings Inc.',
    symbol: 'KNX',
  },
  {
    value: 'KBH',
    label: 'KBH,  KB Home',
    name: 'KB Home',
    symbol: 'KBH',
  },
  {
    value: 'PGR',
    label: 'PGR,  The Progressive Corporation',
    name: 'The Progressive Corporation',
    symbol: 'PGR',
  },
  {
    value: 'XLE',
    label: 'XLE,  Energy Select Sector SPDR Fund',
    name: 'Energy Select Sector SPDR Fund',
    symbol: 'XLE',
  },
  {
    value: 'MO',
    label: 'MO,  Altria Group, Inc.',
    name: 'Altria Group, Inc.',
    symbol: 'MO',
  },
  {
    value: 'MDT',
    label: 'MDT,  Medtronic plc',
    name: 'Medtronic plc',
    symbol: 'MDT',
  },
  {
    value: 'RIG',
    label: 'RIG,  Transocean Ltd.',
    name: 'Transocean Ltd.',
    symbol: 'RIG',
  },
  {
    value: 'BCS',
    label: 'BCS,  Barclays PLC',
    name: 'Barclays PLC',
    symbol: 'BCS',
  },
  {
    value: 'EOG',
    label: 'EOG,  EOG Resources, Inc.',
    name: 'EOG Resources, Inc.',
    symbol: 'EOG',
  },
  {
    value: 'XLP',
    label: 'XLP,  Consumer Staples Select Sector SPDR Fund',
    name: 'Consumer Staples Select Sector SPDR Fund',
    symbol: 'XLP',
  },
  {
    value: 'HPE',
    label: 'HPE,  Hewlett Packard Enterprise Company',
    name: 'Hewlett Packard Enterprise Company',
    symbol: 'HPE',
  },
  {
    value: 'TXN',
    label: 'TXN,  Texas Instruments Incorporated',
    name: 'Texas Instruments Incorporated',
    symbol: 'TXN',
  },
  {
    value: 'OKE',
    label: 'OKE,  ONEOK, Inc.',
    name: 'ONEOK, Inc.',
    symbol: 'OKE',
  },
  {
    value: 'CVS',
    label: 'CVS,  CVS Health Corporation',
    name: 'CVS Health Corporation',
    symbol: 'CVS',
  },
  {
    value: 'SVXY',
    label: 'SVXY,  ProShares Short VIX Short-Term Futures ETF',
    name: 'ProShares Short VIX Short-Term Futures ETF',
    symbol: 'SVXY',
  },
  {
    value: 'HES',
    label: 'HES,  Hess Corporation',
    name: 'Hess Corporation',
    symbol: 'HES',
  },
  {
    value: 'GDDY',
    label: 'GDDY,  GoDaddy Inc.',
    name: 'GoDaddy Inc.',
    symbol: 'GDDY',
  },
  {
    value: 'BX',
    label: 'BX,  Blackstone Inc.',
    name: 'Blackstone Inc.',
    symbol: 'BX',
  },
  {
    value: 'TS',
    label: 'TS,  Tenaris S.A.',
    name: 'Tenaris S.A.',
    symbol: 'TS',
  },
  {
    value: 'BOX',
    label: 'BOX,  Box, Inc.',
    name: 'Box, Inc.',
    symbol: 'BOX',
  },
  {
    value: 'OXY',
    label: 'OXY,  Occidental Petroleum Corporation',
    name: 'Occidental Petroleum Corporation',
    symbol: 'OXY',
  },
  {
    value: 'CVE',
    label: 'CVE,  Cenovus Energy Inc.',
    name: 'Cenovus Energy Inc.',
    symbol: 'CVE',
  },
  {
    value: 'SYF',
    label: 'SYF,  Synchrony Financial',
    name: 'Synchrony Financial',
    symbol: 'SYF',
  },
  {
    value: 'IAU',
    label: 'IAU,  iShares Gold Trust',
    name: 'iShares Gold Trust',
    symbol: 'IAU',
  },
  {
    value: 'MTG',
    label: 'MTG,  MGIC Investment Corporation',
    name: 'MGIC Investment Corporation',
    symbol: 'MTG',
  },
  {
    value: 'LUV',
    label: 'LUV,  Southwest Airlines Co.',
    name: 'Southwest Airlines Co.',
    symbol: 'LUV',
  },
  {
    value: 'FTI',
    label: 'FTI,  TechnipFMC plc',
    name: 'TechnipFMC plc',
    symbol: 'FTI',
  },
  {
    value: 'AA',
    label: 'AA,  Alcoa Corporation',
    name: 'Alcoa Corporation',
    symbol: 'AA',
  },
  {
    value: 'ABEV',
    label: 'ABEV,  Ambev S.A.',
    name: 'Ambev S.A.',
    symbol: 'ABEV',
  },
  {
    value: 'VTI',
    label: 'VTI,  Vanguard Total Stock Market Index Fund',
    name: 'Vanguard Total Stock Market Index Fund',
    symbol: 'VTI',
  },
  {
    value: 'EQR',
    label: 'EQR,  Equity Residential',
    name: 'Equity Residential',
    symbol: 'EQR',
  },
  {
    value: 'EWG',
    label: 'EWG,  iShares MSCI Germany ETF',
    name: 'iShares MSCI Germany ETF',
    symbol: 'EWG',
  },
  {
    value: 'ETSY',
    label: 'ETSY,  Etsy, Inc.',
    name: 'Etsy, Inc.',
    symbol: 'ETSY',
  },
  {
    value: 'TAL',
    label: 'TAL,  TAL Education Group',
    name: 'TAL Education Group',
    symbol: 'TAL',
  },
  {
    value: 'UNH',
    label: 'UNH,  UnitedHealth Group Incorporated',
    name: 'UnitedHealth Group Incorporated',
    symbol: 'UNH',
  },
  {
    value: 'GES',
    label: "GES,  Guess', Inc.",
    name: "Guess', Inc.",
    symbol: 'GES',
  },
  {
    value: 'AMZN',
    label: 'AMZN,  Amazon.com, Inc.',
    name: 'Amazon.com, Inc.',
    symbol: 'AMZN',
  },
  {
    value: 'NFLX',
    label: 'NFLX,  Netflix, Inc.',
    name: 'Netflix, Inc.',
    symbol: 'NFLX',
  },
  {
    value: 'ENTG',
    label: 'ENTG,  Entegris, Inc.',
    name: 'Entegris, Inc.',
    symbol: 'ENTG',
  },
  {
    value: 'CSX',
    label: 'CSX,  CSX Corporation',
    name: 'CSX Corporation',
    symbol: 'CSX',
  },
  {
    value: 'WYNN',
    label: 'WYNN,  Wynn Resorts, Limited',
    name: 'Wynn Resorts, Limited',
    symbol: 'WYNN',
  },
  {
    value: 'FOX',
    label: 'FOX,  Fox Corporation',
    name: 'Fox Corporation',
    symbol: 'FOX',
  },
  {
    value: 'CNC',
    label: 'CNC,  Centene Corporation',
    name: 'Centene Corporation',
    symbol: 'CNC',
  },
  {
    value: 'ZION',
    label: 'ZION,  Zions Bancorporation, National Association',
    name: 'Zions Bancorporation, National Association',
    symbol: 'ZION',
  },
  {
    value: 'RES',
    label: 'RES,  RPC, Inc.',
    name: 'RPC, Inc.',
    symbol: 'RES',
  },
  {
    value: 'CI',
    label: 'CI,  Cigna Corporation',
    name: 'Cigna Corporation',
    symbol: 'CI',
  },
  {
    value: 'KRE',
    label: 'KRE,  SPDR S&P Regional Banking ETF',
    name: 'SPDR S&P Regional Banking ETF',
    symbol: 'KRE',
  },
  {
    value: 'WMB',
    label: 'WMB,  The Williams Companies, Inc.',
    name: 'The Williams Companies, Inc.',
    symbol: 'WMB',
  },
  {
    value: 'SYNH',
    label: 'SYNH,  Syneos Health, Inc.',
    name: 'Syneos Health, Inc.',
    symbol: 'SYNH',
  },
  {
    value: 'HBI',
    label: 'HBI,  Hanesbrands Inc.',
    name: 'Hanesbrands Inc.',
    symbol: 'HBI',
  },
  {
    value: 'XL',
    label: 'XL,  XL Fleet Corp.',
    name: 'XL Fleet Corp.',
    symbol: 'XL',
  },
  {
    value: 'PAYX',
    label: 'PAYX,  Paychex, Inc.',
    name: 'Paychex, Inc.',
    symbol: 'PAYX',
  },
  {
    value: 'D',
    label: 'D,  Dominion Energy, Inc.',
    name: 'Dominion Energy, Inc.',
    symbol: 'D',
  },
  {
    value: 'CHS',
    label: "CHS,  Chico's FAS, Inc.",
    name: "Chico's FAS, Inc.",
    symbol: 'CHS',
  },
  {
    value: 'CFG',
    label: 'CFG,  Citizens Financial Group, Inc.',
    name: 'Citizens Financial Group, Inc.',
    symbol: 'CFG',
  },
  {
    value: 'XLI',
    label: 'XLI,  Industrial Select Sector SPDR Fund',
    name: 'Industrial Select Sector SPDR Fund',
    symbol: 'XLI',
  },
  {
    value: 'SU',
    label: 'SU,  Suncor Energy Inc.',
    name: 'Suncor Energy Inc.',
    symbol: 'SU',
  },
  {
    value: 'SLV',
    label: 'SLV,  iShares Silver Trust',
    name: 'iShares Silver Trust',
    symbol: 'SLV',
  },
  {
    value: 'MMM',
    label: 'MMM,  3M Company',
    name: '3M Company',
    symbol: 'MMM',
  },
  {
    value: 'BIIB',
    label: 'BIIB,  Biogen Inc.',
    name: 'Biogen Inc.',
    symbol: 'BIIB',
  },
  {
    value: 'JBL',
    label: 'JBL,  Jabil Inc.',
    name: 'Jabil Inc.',
    symbol: 'JBL',
  },
  {
    value: 'ACN',
    label: 'ACN,  Accenture plc',
    name: 'Accenture plc',
    symbol: 'ACN',
  },
  {
    value: 'NTR',
    label: 'NTR,  Nutrien Ltd.',
    name: 'Nutrien Ltd.',
    symbol: 'NTR',
  },
  {
    value: 'BRFS',
    label: 'BRFS,  BRF S.A.',
    name: 'BRF S.A.',
    symbol: 'BRFS',
  },
  {
    value: 'SBUX',
    label: 'SBUX,  Starbucks Corporation',
    name: 'Starbucks Corporation',
    symbol: 'SBUX',
  },
  {
    value: 'EWU',
    label: 'EWU,  iShares MSCI United Kingdom ETF',
    name: 'iShares MSCI United Kingdom ETF',
    symbol: 'EWU',
  },
  {
    value: 'LVS',
    label: 'LVS,  Las Vegas Sands Corp.',
    name: 'Las Vegas Sands Corp.',
    symbol: 'LVS',
  },
  {
    value: 'EGO',
    label: 'EGO,  Eldorado Gold Corporation',
    name: 'Eldorado Gold Corporation',
    symbol: 'EGO',
  },
  {
    value: 'TEVA',
    label: 'TEVA,  Teva Pharmaceutical Industries Limited',
    name: 'Teva Pharmaceutical Industries Limited',
    symbol: 'TEVA',
  },
  {
    value: 'PAA',
    label: 'PAA,  Plains All American Pipeline, L.P.',
    name: 'Plains All American Pipeline, L.P.',
    symbol: 'PAA',
  },
  {
    value: 'SQQQ',
    label: 'SQQQ,  ProShares UltraPro Short QQQ',
    name: 'ProShares UltraPro Short QQQ',
    symbol: 'SQQQ',
  },
  {
    value: 'VLO',
    label: 'VLO,  Valero Energy Corporation',
    name: 'Valero Energy Corporation',
    symbol: 'VLO',
  },
  {
    value: 'IMAX',
    label: 'IMAX,  IMAX Corporation',
    name: 'IMAX Corporation',
    symbol: 'IMAX',
  },
  {
    value: 'FNB',
    label: 'FNB,  F.N.B. Corporation',
    name: 'F.N.B. Corporation',
    symbol: 'FNB',
  },
  {
    value: 'ADSK',
    label: 'ADSK,  Autodesk, Inc.',
    name: 'Autodesk, Inc.',
    symbol: 'ADSK',
  },
  {
    value: 'HD',
    label: 'HD,  The Home Depot, Inc.',
    name: 'The Home Depot, Inc.',
    symbol: 'HD',
  },
  {
    value: 'BIDU',
    label: 'BIDU,  Baidu, Inc.',
    name: 'Baidu, Inc.',
    symbol: 'BIDU',
  },
  {
    value: 'AR',
    label: 'AR,  Antero Resources Corporation',
    name: 'Antero Resources Corporation',
    symbol: 'AR',
  },
  {
    value: 'ARNC',
    label: 'ARNC,  Arconic Corporation',
    name: 'Arconic Corporation',
    symbol: 'ARNC',
  },
  {
    value: 'VOYA',
    label: 'VOYA,  Voya Financial, Inc.',
    name: 'Voya Financial, Inc.',
    symbol: 'VOYA',
  },
  {
    value: 'SPXU',
    label: 'SPXU,  ProShares UltraPro Short S&P500',
    name: 'ProShares UltraPro Short S&P500',
    symbol: 'SPXU',
  },
  {
    value: 'MLCO',
    label: 'MLCO,  Melco Resorts & Entertainment Limited',
    name: 'Melco Resorts & Entertainment Limited',
    symbol: 'MLCO',
  },
  {
    value: 'SJNK',
    label: 'SJNK,  SPDR Bloomberg Short Term High Yield Bond ETF',
    name: 'SPDR Bloomberg Short Term High Yield Bond ETF',
    symbol: 'SJNK',
  },
  {
    value: 'CWB',
    label: 'CWB,  SPDR Bloomberg Convertible Securities ETF',
    name: 'SPDR Bloomberg Convertible Securities ETF',
    symbol: 'CWB',
  },
  {
    value: 'SM',
    label: 'SM,  SM Energy Company',
    name: 'SM Energy Company',
    symbol: 'SM',
  },
  {
    value: 'NTRS',
    label: 'NTRS,  Northern Trust Corporation',
    name: 'Northern Trust Corporation',
    symbol: 'NTRS',
  },
  {
    value: 'DHI',
    label: 'DHI,  D.R. Horton, Inc.',
    name: 'D.R. Horton, Inc.',
    symbol: 'DHI',
  },
  {
    value: 'UAL',
    label: 'UAL,  United Airlines Holdings, Inc.',
    name: 'United Airlines Holdings, Inc.',
    symbol: 'UAL',
  },
  {
    value: 'HRB',
    label: 'HRB,  H&R Block, Inc.',
    name: 'H&R Block, Inc.',
    symbol: 'HRB',
  },
  {
    value: 'BTU',
    label: 'BTU,  Peabody Energy Corporation',
    name: 'Peabody Energy Corporation',
    symbol: 'BTU',
  },
  {
    value: 'JBGS',
    label: 'JBGS,  JBG SMITH Properties',
    name: 'JBG SMITH Properties',
    symbol: 'JBGS',
  },
  {
    value: 'IPG',
    label: 'IPG,  The Interpublic Group of Companies, Inc.',
    name: 'The Interpublic Group of Companies, Inc.',
    symbol: 'IPG',
  },
  {
    value: 'DE',
    label: 'DE,  Deere & Company',
    name: 'Deere & Company',
    symbol: 'DE',
  },
  {
    value: 'KHC',
    label: 'KHC,  The Kraft Heinz Company',
    name: 'The Kraft Heinz Company',
    symbol: 'KHC',
  },
  {
    value: 'IBM',
    label: 'IBM,  International Business Machines Corporation',
    name: 'International Business Machines Corporation',
    symbol: 'IBM',
  },
  {
    value: 'NYT',
    label: 'NYT,  The New York Times Company',
    name: 'The New York Times Company',
    symbol: 'NYT',
  },
  {
    value: 'DBJP',
    label: 'DBJP,  Xtrackers MSCI Japan Hedged Equity ETF',
    name: 'Xtrackers MSCI Japan Hedged Equity ETF',
    symbol: 'DBJP',
  },
  {
    value: 'AAL',
    label: 'AAL,  American Airlines Group Inc.',
    name: 'American Airlines Group Inc.',
    symbol: 'AAL',
  },
  {
    value: 'OLN',
    label: 'OLN,  Olin Corporation',
    name: 'Olin Corporation',
    symbol: 'OLN',
  },
  {
    value: 'JCI',
    label: 'JCI,  Johnson Controls International plc',
    name: 'Johnson Controls International plc',
    symbol: 'JCI',
  },
  {
    value: 'VICI',
    label: 'VICI,  VICI Properties Inc.',
    name: 'VICI Properties Inc.',
    symbol: 'VICI',
  },
  {
    value: 'VST',
    label: 'VST,  Vistra Corp.',
    name: 'Vistra Corp.',
    symbol: 'VST',
  },
  {
    value: 'NWL',
    label: 'NWL,  Newell Brands Inc.',
    name: 'Newell Brands Inc.',
    symbol: 'NWL',
  },
  {
    value: 'EPD',
    label: 'EPD,  Enterprise Products Partners L.P.',
    name: 'Enterprise Products Partners L.P.',
    symbol: 'EPD',
  },
  {
    value: 'KMB',
    label: 'KMB,  Kimberly-Clark Corporation',
    name: 'Kimberly-Clark Corporation',
    symbol: 'KMB',
  },
  {
    value: 'AEP',
    label: 'AEP,  American Electric Power Company, Inc.',
    name: 'American Electric Power Company, Inc.',
    symbol: 'AEP',
  },
  {
    value: 'IT',
    label: 'IT,  Gartner, Inc.',
    name: 'Gartner, Inc.',
    symbol: 'IT',
  },
  {
    value: 'CAT',
    label: 'CAT,  Caterpillar Inc.',
    name: 'Caterpillar Inc.',
    symbol: 'CAT',
  },
  {
    value: 'AU',
    label: 'AU,  AngloGold Ashanti Limited',
    name: 'AngloGold Ashanti Limited',
    symbol: 'AU',
  },
  {
    value: 'XRAY',
    label: 'XRAY,  DENTSPLY SIRONA Inc.',
    name: 'DENTSPLY SIRONA Inc.',
    symbol: 'XRAY',
  },
  {
    value: 'KKR',
    label: 'KKR,  KKR & Co. Inc.',
    name: 'KKR & Co. Inc.',
    symbol: 'KKR',
  },
  {
    value: 'IEUR',
    label: 'IEUR,  iShares Core MSCI Europe ETF',
    name: 'iShares Core MSCI Europe ETF',
    symbol: 'IEUR',
  },
  {
    value: 'LQD',
    label: 'LQD,  iShares iBoxx $ Investment Grade Corporate Bond ETF',
    name: 'iShares iBoxx $ Investment Grade Corporate Bond ETF',
    symbol: 'LQD',
  },
  {
    value: 'CMC',
    label: 'CMC,  Commercial Metals Company',
    name: 'Commercial Metals Company',
    symbol: 'CMC',
  },
  {
    value: 'XBI',
    label: 'XBI,  SPDR S&P Biotech ETF',
    name: 'SPDR S&P Biotech ETF',
    symbol: 'XBI',
  },
  {
    value: 'TMUS',
    label: 'TMUS,  T-Mobile US, Inc.',
    name: 'T-Mobile US, Inc.',
    symbol: 'TMUS',
  },
  {
    value: 'EWW',
    label: 'EWW,  iShares MSCI Mexico ETF',
    name: 'iShares MSCI Mexico ETF',
    symbol: 'EWW',
  },
  {
    value: 'NRG',
    label: 'NRG,  NRG Energy, Inc.',
    name: 'NRG Energy, Inc.',
    symbol: 'NRG',
  },
  {
    value: 'CPB',
    label: 'CPB,  Campbell Soup Company',
    name: 'Campbell Soup Company',
    symbol: 'CPB',
  },
  {
    value: 'ATHM',
    label: 'ATHM,  Autohome Inc.',
    name: 'Autohome Inc.',
    symbol: 'ATHM',
  },
  {
    value: 'WRB',
    label: 'WRB,  W. R. Berkley Corporation',
    name: 'W. R. Berkley Corporation',
    symbol: 'WRB',
  },
  {
    value: 'MOMO',
    label: 'MOMO,  Hello Group Inc.',
    name: 'Hello Group Inc.',
    symbol: 'MOMO',
  },
  {
    value: 'TGT',
    label: 'TGT,  Target Corporation',
    name: 'Target Corporation',
    symbol: 'TGT',
  },
  {
    value: 'TV',
    label: 'TV,  Grupo Televisa, S.A.B.',
    name: 'Grupo Televisa, S.A.B.',
    symbol: 'TV',
  },
  {
    value: 'GLW',
    label: 'GLW,  Corning Incorporated',
    name: 'Corning Incorporated',
    symbol: 'GLW',
  },
  {
    value: 'MAS',
    label: 'MAS,  Masco Corporation',
    name: 'Masco Corporation',
    symbol: 'MAS',
  },
  {
    value: 'DOV',
    label: 'DOV,  Dover Corporation',
    name: 'Dover Corporation',
    symbol: 'DOV',
  },
  {
    value: 'HRL',
    label: 'HRL,  Hormel Foods Corporation',
    name: 'Hormel Foods Corporation',
    symbol: 'HRL',
  },
  {
    value: 'TECK',
    label: 'TECK,  Teck Resources Limited',
    name: 'Teck Resources Limited',
    symbol: 'TECK',
  },
  {
    value: 'AES',
    label: 'AES,  The AES Corporation',
    name: 'The AES Corporation',
    symbol: 'AES',
  },
  {
    value: 'PPL',
    label: 'PPL,  PPL Corporation',
    name: 'PPL Corporation',
    symbol: 'PPL',
  },
  {
    value: 'IXUS',
    label: 'IXUS,  iShares Core MSCI Total International Stock ETF',
    name: 'iShares Core MSCI Total International Stock ETF',
    symbol: 'IXUS',
  },
  {
    value: 'SEE',
    label: 'SEE,  Sealed Air Corporation',
    name: 'Sealed Air Corporation',
    symbol: 'SEE',
  },
  {
    value: 'NLY',
    label: 'NLY,  Annaly Capital Management, Inc.',
    name: 'Annaly Capital Management, Inc.',
    symbol: 'NLY',
  },
  {
    value: 'FRC',
    label: 'FRC,  First Republic Bank',
    name: 'First Republic Bank',
    symbol: 'FRC',
  },
  {
    value: 'EWT',
    label: 'EWT,  iShares MSCI Taiwan ETF',
    name: 'iShares MSCI Taiwan ETF',
    symbol: 'EWT',
  },
  {
    value: 'STX',
    label: 'STX,  Seagate Technology Holdings plc',
    name: 'Seagate Technology Holdings plc',
    symbol: 'STX',
  },
  {
    value: 'ADBE',
    label: 'ADBE,  Adobe Inc.',
    name: 'Adobe Inc.',
    symbol: 'ADBE',
  },
  {
    value: 'K',
    label: 'K,  Kellogg Company',
    name: 'Kellogg Company',
    symbol: 'K',
  },
  {
    value: 'M',
    label: "M,  Macy's, Inc.",
    name: "Macy's, Inc.",
    symbol: 'M',
  },
  {
    value: 'TSLA',
    label: 'TSLA,  Tesla, Inc.',
    name: 'Tesla, Inc.',
    symbol: 'TSLA',
  },
  {
    value: 'KBE',
    label: 'KBE,  SPDR S&P Bank ETF',
    name: 'SPDR S&P Bank ETF',
    symbol: 'KBE',
  },
  {
    value: 'TSM',
    label: 'TSM,  Taiwan Semiconductor Manufacturing Company Limited',
    name: 'Taiwan Semiconductor Manufacturing Company Limited',
    symbol: 'TSM',
  },
  {
    value: 'NYCB',
    label: 'NYCB,  New York Community Bancorp, Inc.',
    name: 'New York Community Bancorp, Inc.',
    symbol: 'NYCB',
  },
  {
    value: 'TRQ',
    label: 'TRQ,  Turquoise Hill Resources Ltd.',
    name: 'Turquoise Hill Resources Ltd.',
    symbol: 'TRQ',
  },
  {
    value: 'BAH',
    label: 'BAH,  Booz Allen Hamilton Holding Corporation',
    name: 'Booz Allen Hamilton Holding Corporation',
    symbol: 'BAH',
  },
  {
    value: 'BUFF',
    label: 'BUFF,  Innovator Laddered Allocation Power Buffer ETF',
    name: 'Innovator Laddered Allocation Power Buffer ETF',
    symbol: 'BUFF',
  },
  {
    value: 'ARI',
    label: 'ARI,  Apollo Commercial Real Estate Finance, Inc.',
    name: 'Apollo Commercial Real Estate Finance, Inc.',
    symbol: 'ARI',
  },
  {
    value: 'MET',
    label: 'MET,  MetLife, Inc.',
    name: 'MetLife, Inc.',
    symbol: 'MET',
  },
  {
    value: 'AFL',
    label: 'AFL,  Aflac Incorporated',
    name: 'Aflac Incorporated',
    symbol: 'AFL',
  },
  {
    value: 'TPR',
    label: 'TPR,  Tapestry, Inc.',
    name: 'Tapestry, Inc.',
    symbol: 'TPR',
  },
  {
    value: 'WPM',
    label: 'WPM,  Wheaton Precious Metals Corp.',
    name: 'Wheaton Precious Metals Corp.',
    symbol: 'WPM',
  },
  {
    value: 'LNT',
    label: 'LNT,  Alliant Energy Corporation',
    name: 'Alliant Energy Corporation',
    symbol: 'LNT',
  },
  {
    value: 'BTG',
    label: 'BTG,  B2Gold Corp.',
    name: 'B2Gold Corp.',
    symbol: 'BTG',
  },
  {
    value: 'LKQ',
    label: 'LKQ,  LKQ Corporation',
    name: 'LKQ Corporation',
    symbol: 'LKQ',
  },
  {
    value: 'PCG',
    label: 'PCG,  PG&E Corporation',
    name: 'PG&E Corporation',
    symbol: 'PCG',
  },
  {
    value: 'CENX',
    label: 'CENX,  Century Aluminum Company',
    name: 'Century Aluminum Company',
    symbol: 'CENX',
  },
  {
    value: 'CLR',
    label: 'CLR,  Continental Resources, Inc.',
    name: 'Continental Resources, Inc.',
    symbol: 'CLR',
  },
  {
    value: 'IMGN',
    label: 'IMGN,  ImmunoGen, Inc.',
    name: 'ImmunoGen, Inc.',
    symbol: 'IMGN',
  },
  {
    value: 'HL',
    label: 'HL,  Hecla Mining Company',
    name: 'Hecla Mining Company',
    symbol: 'HL',
  },
  {
    value: 'LULU',
    label: 'LULU,  Lululemon Athletica Inc.',
    name: 'Lululemon Athletica Inc.',
    symbol: 'LULU',
  },
  {
    value: 'UAA',
    label: 'UAA,  Under Armour, Inc.',
    name: 'Under Armour, Inc.',
    symbol: 'UAA',
  },
  {
    value: 'CAG',
    label: 'CAG,  Conagra Brands, Inc.',
    name: 'Conagra Brands, Inc.',
    symbol: 'CAG',
  },
  {
    value: 'STLD',
    label: 'STLD,  Steel Dynamics, Inc.',
    name: 'Steel Dynamics, Inc.',
    symbol: 'STLD',
  },
  {
    value: 'PEP',
    label: 'PEP,  PepsiCo, Inc.',
    name: 'PepsiCo, Inc.',
    symbol: 'PEP',
  },
  {
    value: 'SO',
    label: 'SO,  The Southern Company',
    name: 'The Southern Company',
    symbol: 'SO',
  },
  {
    value: 'COST',
    label: 'COST,  Costco Wholesale Corporation',
    name: 'Costco Wholesale Corporation',
    symbol: 'COST',
  },
  {
    value: 'GKOS',
    label: 'GKOS,  Glaukos Corporation',
    name: 'Glaukos Corporation',
    symbol: 'GKOS',
  },
  {
    value: 'CCI',
    label: 'CCI,  Crown Castle Inc.',
    name: 'Crown Castle Inc.',
    symbol: 'CCI',
  },
  {
    value: 'ZTO',
    label: 'ZTO,  ZTO Express (Cayman) Inc.',
    name: 'ZTO Express (Cayman) Inc.',
    symbol: 'ZTO',
  },
  {
    value: 'VGK',
    label: 'VGK,  Vanguard European Stock Index Fund',
    name: 'Vanguard European Stock Index Fund',
    symbol: 'VGK',
  },
  {
    value: 'GS',
    label: 'GS,  The Goldman Sachs Group, Inc.',
    name: 'The Goldman Sachs Group, Inc.',
    symbol: 'GS',
  },
  {
    value: 'IJR',
    label: 'IJR,  iShares Core S&P Small-Cap ETF',
    name: 'iShares Core S&P Small-Cap ETF',
    symbol: 'IJR',
  },
  {
    value: 'ADT',
    label: 'ADT,  ADT Inc.',
    name: 'ADT Inc.',
    symbol: 'ADT',
  },
  {
    value: 'ALTR',
    label: 'ALTR,  Altair Engineering Inc.',
    name: 'Altair Engineering Inc.',
    symbol: 'ALTR',
  },
  {
    value: 'EMR',
    label: 'EMR,  Emerson Electric Co.',
    name: 'Emerson Electric Co.',
    symbol: 'EMR',
  },
  {
    value: 'CNP',
    label: 'CNP,  CenterPoint Energy, Inc.',
    name: 'CenterPoint Energy, Inc.',
    symbol: 'CNP',
  },
  {
    value: 'COTY',
    label: 'COTY,  Coty Inc.',
    name: 'Coty Inc.',
    symbol: 'COTY',
  },
  {
    value: 'MA',
    label: 'MA,  Mastercard Incorporated',
    name: 'Mastercard Incorporated',
    symbol: 'MA',
  },
  {
    value: 'AWI',
    label: 'AWI,  Armstrong World Industries, Inc.',
    name: 'Armstrong World Industries, Inc.',
    symbol: 'AWI',
  },
  {
    value: 'IYR',
    label: 'IYR,  iShares U.S. Real Estate ETF',
    name: 'iShares U.S. Real Estate ETF',
    symbol: 'IYR',
  },
  {
    value: 'ADP',
    label: 'ADP,  Automatic Data Processing, Inc.',
    name: 'Automatic Data Processing, Inc.',
    symbol: 'ADP',
  },
  {
    value: 'TZA',
    label: 'TZA,  Direxion Daily Small Cap Bear 3X Shares',
    name: 'Direxion Daily Small Cap Bear 3X Shares',
    symbol: 'TZA',
  },
  {
    value: 'BBVA',
    label: 'BBVA,  Banco Bilbao Vizcaya Argentaria, S.A.',
    name: 'Banco Bilbao Vizcaya Argentaria, S.A.',
    symbol: 'BBVA',
  },
  {
    value: 'MNST',
    label: 'MNST,  Monster Beverage Corporation',
    name: 'Monster Beverage Corporation',
    symbol: 'MNST',
  },
  {
    value: 'FEZ',
    label: 'FEZ,  SPDR EURO STOXX 50 ETF',
    name: 'SPDR EURO STOXX 50 ETF',
    symbol: 'FEZ',
  },
  {
    value: 'AER',
    label: 'AER,  AerCap Holdings N.V.',
    name: 'AerCap Holdings N.V.',
    symbol: 'AER',
  },
  {
    value: 'GTN',
    label: 'GTN,  Gray Television, Inc.',
    name: 'Gray Television, Inc.',
    symbol: 'GTN',
  },
  {
    value: 'EWY',
    label: 'EWY,  iShares MSCI South Korea ETF',
    name: 'iShares MSCI South Korea ETF',
    symbol: 'EWY',
  },
  {
    value: 'CDE',
    label: 'CDE,  Coeur Mining, Inc.',
    name: 'Coeur Mining, Inc.',
    symbol: 'CDE',
  },
  {
    value: 'NCR',
    label: 'NCR,  NCR Corporation',
    name: 'NCR Corporation',
    symbol: 'NCR',
  },
  {
    value: 'AMT',
    label: 'AMT,  American Tower Corporation',
    name: 'American Tower Corporation',
    symbol: 'AMT',
  },
  {
    value: 'PSTG',
    label: 'PSTG,  Pure Storage, Inc.',
    name: 'Pure Storage, Inc.',
    symbol: 'PSTG',
  },
  {
    value: 'SJM',
    label: 'SJM,  The J. M. Smucker Company',
    name: 'The J. M. Smucker Company',
    symbol: 'SJM',
  },
  {
    value: 'ETN',
    label: 'ETN,  Eaton Corporation plc',
    name: 'Eaton Corporation plc',
    symbol: 'ETN',
  },
  {
    value: 'DUK',
    label: 'DUK,  Duke Energy Corporation',
    name: 'Duke Energy Corporation',
    symbol: 'DUK',
  },
  {
    value: 'CL',
    label: 'CL,  Colgate-Palmolive Company',
    name: 'Colgate-Palmolive Company',
    symbol: 'CL',
  },
  {
    value: 'IDRA',
    label: 'IDRA,  Idera Pharmaceuticals, Inc.',
    name: 'Idera Pharmaceuticals, Inc.',
    symbol: 'IDRA',
  },
  {
    value: 'KAR',
    label: 'KAR,  KAR Auction Services, Inc.',
    name: 'KAR Auction Services, Inc.',
    symbol: 'KAR',
  },
  {
    value: 'NEE',
    label: 'NEE,  NextEra Energy, Inc.',
    name: 'NextEra Energy, Inc.',
    symbol: 'NEE',
  },
  {
    value: 'PXD',
    label: 'PXD,  Pioneer Natural Resources Company',
    name: 'Pioneer Natural Resources Company',
    symbol: 'PXD',
  },
  {
    value: 'ADM',
    label: 'ADM,  Archer-Daniels-Midland Company',
    name: 'Archer-Daniels-Midland Company',
    symbol: 'ADM',
  },
  {
    value: 'VTR',
    label: 'VTR,  Ventas, Inc.',
    name: 'Ventas, Inc.',
    symbol: 'VTR',
  },
  {
    value: 'VMW',
    label: 'VMW,  VMware, Inc.',
    name: 'VMware, Inc.',
    symbol: 'VMW',
  },
  {
    value: 'RIGL',
    label: 'RIGL,  Rigel Pharmaceuticals, Inc.',
    name: 'Rigel Pharmaceuticals, Inc.',
    symbol: 'RIGL',
  },
  {
    value: 'DKS',
    label: "DKS,  DICK'S Sporting Goods, Inc.",
    name: "DICK'S Sporting Goods, Inc.",
    symbol: 'DKS',
  },
  {
    value: 'WTI',
    label: 'WTI,  W&T Offshore, Inc.',
    name: 'W&T Offshore, Inc.',
    symbol: 'WTI',
  },
  {
    value: 'LRCX',
    label: 'LRCX,  Lam Research Corporation',
    name: 'Lam Research Corporation',
    symbol: 'LRCX',
  },
  {
    value: 'NXPI',
    label: 'NXPI,  NXP Semiconductors N.V.',
    name: 'NXP Semiconductors N.V.',
    symbol: 'NXPI',
  },
  {
    value: 'CE',
    label: 'CE,  Celanese Corporation',
    name: 'Celanese Corporation',
    symbol: 'CE',
  },
  {
    value: 'HUN',
    label: 'HUN,  Huntsman Corporation',
    name: 'Huntsman Corporation',
    symbol: 'HUN',
  },
  {
    value: 'CSTM',
    label: 'CSTM,  Constellium SE',
    name: 'Constellium SE',
    symbol: 'CSTM',
  },
  {
    value: 'GPK',
    label: 'GPK,  Graphic Packaging Holding Company',
    name: 'Graphic Packaging Holding Company',
    symbol: 'GPK',
  },
  {
    value: 'WELL',
    label: 'WELL,  Welltower Inc.',
    name: 'Welltower Inc.',
    symbol: 'WELL',
  },
  {
    value: 'TJX',
    label: 'TJX,  The TJX Companies, Inc.',
    name: 'The TJX Companies, Inc.',
    symbol: 'TJX',
  },
  {
    value: 'PLD',
    label: 'PLD,  Prologis, Inc.',
    name: 'Prologis, Inc.',
    symbol: 'PLD',
  },
  {
    value: 'KSS',
    label: "KSS,  Kohl's Corporation",
    name: "Kohl's Corporation",
    symbol: 'KSS',
  },
  {
    value: 'TLT',
    label: 'TLT,  iShares 20+ Year Treasury Bond ETF',
    name: 'iShares 20+ Year Treasury Bond ETF',
    symbol: 'TLT',
  },
  {
    value: 'DRI',
    label: 'DRI,  Darden Restaurants, Inc.',
    name: 'Darden Restaurants, Inc.',
    symbol: 'DRI',
  },
  {
    value: 'ATI',
    label: 'ATI,  ATI Inc.',
    name: 'ATI Inc.',
    symbol: 'ATI',
  },
  {
    value: 'IAG',
    label: 'IAG,  IAMGOLD Corporation',
    name: 'IAMGOLD Corporation',
    symbol: 'IAG',
  },
  {
    value: 'VFC',
    label: 'VFC,  V.F. Corporation',
    name: 'V.F. Corporation',
    symbol: 'VFC',
  },
  {
    value: 'BDN',
    label: 'BDN,  Brandywine Realty Trust',
    name: 'Brandywine Realty Trust',
    symbol: 'BDN',
  },
  {
    value: 'HON',
    label: 'HON,  Honeywell International Inc.',
    name: 'Honeywell International Inc.',
    symbol: 'HON',
  },
  {
    value: 'YNDX',
    label: 'YNDX,  Yandex N.V.',
    name: 'Yandex N.V.',
    symbol: 'YNDX',
  },
  {
    value: 'GOOGL',
    label: 'GOOGL,  Alphabet Inc.',
    name: 'Alphabet Inc.',
    symbol: 'GOOGL',
  },
  {
    value: 'ICE',
    label: 'ICE,  Intercontinental Exchange, Inc.',
    name: 'Intercontinental Exchange, Inc.',
    symbol: 'ICE',
  },
  {
    value: 'OMER',
    label: 'OMER,  Omeros Corporation',
    name: 'Omeros Corporation',
    symbol: 'OMER',
  },
  {
    value: 'WY',
    label: 'WY,  Weyerhaeuser Company',
    name: 'Weyerhaeuser Company',
    symbol: 'WY',
  },
  {
    value: 'HEFA',
    label: 'HEFA,  iShares Currency Hedged MSCI EAFE ETF',
    name: 'iShares Currency Hedged MSCI EAFE ETF',
    symbol: 'HEFA',
  },
  {
    value: 'CGNX',
    label: 'CGNX,  Cognex Corporation',
    name: 'Cognex Corporation',
    symbol: 'CGNX',
  },
  {
    value: 'AMGN',
    label: 'AMGN,  Amgen Inc.',
    name: 'Amgen Inc.',
    symbol: 'AMGN',
  },
  {
    value: 'FSLR',
    label: 'FSLR,  First Solar, Inc.',
    name: 'First Solar, Inc.',
    symbol: 'FSLR',
  },
  {
    value: 'IVZ',
    label: 'IVZ,  Invesco Ltd.',
    name: 'Invesco Ltd.',
    symbol: 'IVZ',
  },
  {
    value: 'DG',
    label: 'DG,  Dollar General Corporation',
    name: 'Dollar General Corporation',
    symbol: 'DG',
  },
  {
    value: 'SID',
    label: 'SID,  Companhia Siderúrgica Nacional',
    name: 'Companhia Siderúrgica Nacional',
    symbol: 'SID',
  },
  {
    value: 'SLM',
    label: 'SLM,  SLM Corporation',
    name: 'SLM Corporation',
    symbol: 'SLM',
  },
  {
    value: 'SLCA',
    label: 'SLCA,  U.S. Silica Holdings, Inc.',
    name: 'U.S. Silica Holdings, Inc.',
    symbol: 'SLCA',
  },
  {
    value: 'SGU',
    label: 'SGU,  Star Group, L.P.',
    name: 'Star Group, L.P.',
    symbol: 'SGU',
  },
  {
    value: 'ODP',
    label: 'ODP,  The ODP Corporation',
    name: 'The ODP Corporation',
    symbol: 'ODP',
  },
  {
    value: 'PK',
    label: 'PK,  Park Hotels & Resorts Inc.',
    name: 'Park Hotels & Resorts Inc.',
    symbol: 'PK',
  },
  {
    value: 'TOL',
    label: 'TOL,  Toll Brothers, Inc.',
    name: 'Toll Brothers, Inc.',
    symbol: 'TOL',
  },
  {
    value: 'PDCO',
    label: 'PDCO,  Patterson Companies, Inc.',
    name: 'Patterson Companies, Inc.',
    symbol: 'PDCO',
  },
  {
    value: 'ITB',
    label: 'ITB,  iShares U.S. Home Construction ETF',
    name: 'iShares U.S. Home Construction ETF',
    symbol: 'ITB',
  },
  {
    value: 'Z',
    label: 'Z,  Zillow Group, Inc.',
    name: 'Zillow Group, Inc.',
    symbol: 'Z',
  },
  {
    value: 'BCO',
    label: "BCO,  The Brink's Company",
    name: "The Brink's Company",
    symbol: 'BCO',
  },
  {
    value: 'CME',
    label: 'CME,  CME Group Inc.',
    name: 'CME Group Inc.',
    symbol: 'CME',
  },
  {
    value: 'BKD',
    label: 'BKD,  Brookdale Senior Living Inc.',
    name: 'Brookdale Senior Living Inc.',
    symbol: 'BKD',
  },
  {
    value: 'PPBI',
    label: 'PPBI,  Pacific Premier Bancorp, Inc.',
    name: 'Pacific Premier Bancorp, Inc.',
    symbol: 'PPBI',
  },
  {
    value: 'AIV',
    label: 'AIV,  Apartment Investment and Management Company',
    name: 'Apartment Investment and Management Company',
    symbol: 'AIV',
  },
  {
    value: 'EXC',
    label: 'EXC,  Exelon Corporation',
    name: 'Exelon Corporation',
    symbol: 'EXC',
  },
  {
    value: 'TAP',
    label: 'TAP,  Molson Coors Beverage Company',
    name: 'Molson Coors Beverage Company',
    symbol: 'TAP',
  },
  {
    value: 'MON',
    label: 'MON,  Monument Circle Acquisition Corp.',
    name: 'Monument Circle Acquisition Corp.',
    symbol: 'MON',
  },
  {
    value: 'UNP',
    label: 'UNP,  Union Pacific Corporation',
    name: 'Union Pacific Corporation',
    symbol: 'UNP',
  },
  {
    value: 'IWD',
    label: 'IWD,  iShares Russell 1000 Value ETF',
    name: 'iShares Russell 1000 Value ETF',
    symbol: 'IWD',
  },
  {
    value: 'BDX',
    label: 'BDX,  Becton, Dickinson and Company',
    name: 'Becton, Dickinson and Company',
    symbol: 'BDX',
  },
  {
    value: 'GME',
    label: 'GME,  GameStop Corp.',
    name: 'GameStop Corp.',
    symbol: 'GME',
  },
  {
    value: 'MX',
    label: 'MX,  Magnachip Semiconductor Corporation',
    name: 'Magnachip Semiconductor Corporation',
    symbol: 'MX',
  },
  {
    value: 'RLJ',
    label: 'RLJ,  RLJ Lodging Trust',
    name: 'RLJ Lodging Trust',
    symbol: 'RLJ',
  },
  {
    value: 'USMV',
    label: 'USMV,  iShares MSCI USA Min Vol Factor ETF',
    name: 'iShares MSCI USA Min Vol Factor ETF',
    symbol: 'USMV',
  },
  {
    value: 'NCLH',
    label: 'NCLH,  Norwegian Cruise Line Holdings Ltd.',
    name: 'Norwegian Cruise Line Holdings Ltd.',
    symbol: 'NCLH',
  },
  {
    value: 'NOV',
    label: 'NOV,  NOV Inc.',
    name: 'NOV Inc.',
    symbol: 'NOV',
  },
  {
    value: 'CNDT',
    label: 'CNDT,  Conduent Incorporated',
    name: 'Conduent Incorporated',
    symbol: 'CNDT',
  },
  {
    value: 'LMT',
    label: 'LMT,  Lockheed Martin Corporation',
    name: 'Lockheed Martin Corporation',
    symbol: 'LMT',
  },
  {
    value: 'MTZ',
    label: 'MTZ,  MasTec, Inc.',
    name: 'MasTec, Inc.',
    symbol: 'MTZ',
  },
  {
    value: 'TBT',
    label: 'TBT,  ProShares UltraShort 20+ Year Treasury',
    name: 'ProShares UltraShort 20+ Year Treasury',
    symbol: 'TBT',
  },
  {
    value: 'WEN',
    label: "WEN,  The Wendy's Company",
    name: "The Wendy's Company",
    symbol: 'WEN',
  },
  {
    value: 'VNQ',
    label: 'VNQ,  Vanguard Real Estate Index Fund',
    name: 'Vanguard Real Estate Index Fund',
    symbol: 'VNQ',
  },
  {
    value: 'GPS',
    label: 'GPS,  The Gap, Inc.',
    name: 'The Gap, Inc.',
    symbol: 'GPS',
  },
  {
    value: 'SYY',
    label: 'SYY,  Sysco Corporation',
    name: 'Sysco Corporation',
    symbol: 'SYY',
  },
  {
    value: 'CEF',
    label: 'CEF,  Sprott Physical Gold and Silver Trust',
    name: 'Sprott Physical Gold and Silver Trust',
    symbol: 'CEF',
  },
  {
    value: 'FTV',
    label: 'FTV,  Fortive Corporation',
    name: 'Fortive Corporation',
    symbol: 'FTV',
  },
  {
    value: 'BKI',
    label: 'BKI,  Black Knight, Inc.',
    name: 'Black Knight, Inc.',
    symbol: 'BKI',
  },
  {
    value: 'CTSH',
    label: 'CTSH,  Cognizant Technology Solutions Corporation',
    name: 'Cognizant Technology Solutions Corporation',
    symbol: 'CTSH',
  },
  {
    value: 'HOLX',
    label: 'HOLX,  Hologic, Inc.',
    name: 'Hologic, Inc.',
    symbol: 'HOLX',
  },
  {
    value: 'EIX',
    label: 'EIX,  Edison International',
    name: 'Edison International',
    symbol: 'EIX',
  },
  {
    value: 'URA',
    label: 'URA,  Global X Uranium ETF',
    name: 'Global X Uranium ETF',
    symbol: 'URA',
  },
  {
    value: 'CPE',
    label: 'CPE,  Callon Petroleum Company',
    name: 'Callon Petroleum Company',
    symbol: 'CPE',
  },
  {
    value: 'XME',
    label: 'XME,  SPDR S&P Metals and Mining ETF',
    name: 'SPDR S&P Metals and Mining ETF',
    symbol: 'XME',
  },
  {
    value: 'CF',
    label: 'CF,  CF Industries Holdings, Inc.',
    name: 'CF Industries Holdings, Inc.',
    symbol: 'CF',
  },
  {
    value: 'PNC',
    label: 'PNC,  The PNC Financial Services Group, Inc.',
    name: 'The PNC Financial Services Group, Inc.',
    symbol: 'PNC',
  },
  {
    value: 'SABR',
    label: 'SABR,  Sabre Corporation',
    name: 'Sabre Corporation',
    symbol: 'SABR',
  },
  {
    value: 'WIT',
    label: 'WIT,  Wipro Limited',
    name: 'Wipro Limited',
    symbol: 'WIT',
  },
  {
    value: 'LYB',
    label: 'LYB,  LyondellBasell Industries N.V.',
    name: 'LyondellBasell Industries N.V.',
    symbol: 'LYB',
  },
  {
    value: 'DISH',
    label: 'DISH,  DISH Network Corporation',
    name: 'DISH Network Corporation',
    symbol: 'DISH',
  },
  {
    value: 'MGA',
    label: 'MGA,  Magna International Inc.',
    name: 'Magna International Inc.',
    symbol: 'MGA',
  },
  {
    value: 'AEO',
    label: 'AEO,  American Eagle Outfitters, Inc.',
    name: 'American Eagle Outfitters, Inc.',
    symbol: 'AEO',
  },
  {
    value: 'XHB',
    label: 'XHB,  SPDR S&P Homebuilders ETF',
    name: 'SPDR S&P Homebuilders ETF',
    symbol: 'XHB',
  },
  {
    value: 'BP',
    label: 'BP,  BP p.l.c.',
    name: 'BP p.l.c.',
    symbol: 'BP',
  },
  {
    value: 'XEL',
    label: 'XEL,  Xcel Energy Inc.',
    name: 'Xcel Energy Inc.',
    symbol: 'XEL',
  },
  {
    value: 'NTNX',
    label: 'NTNX,  Nutanix, Inc.',
    name: 'Nutanix, Inc.',
    symbol: 'NTNX',
  },
  {
    value: 'VVV',
    label: 'VVV,  Valvoline Inc.',
    name: 'Valvoline Inc.',
    symbol: 'VVV',
  },
  {
    value: 'HQY',
    label: 'HQY,  HealthEquity, Inc.',
    name: 'HealthEquity, Inc.',
    symbol: 'HQY',
  },
  {
    value: 'ARCC',
    label: 'ARCC,  Ares Capital Corporation',
    name: 'Ares Capital Corporation',
    symbol: 'ARCC',
  },
  {
    value: 'UPS',
    label: 'UPS,  United Parcel Service, Inc.',
    name: 'United Parcel Service, Inc.',
    symbol: 'UPS',
  },
  {
    value: 'MUR',
    label: 'MUR,  Murphy Oil Corporation',
    name: 'Murphy Oil Corporation',
    symbol: 'MUR',
  },
  {
    value: 'VMC',
    label: 'VMC,  Vulcan Materials Company',
    name: 'Vulcan Materials Company',
    symbol: 'VMC',
  },
  {
    value: 'SDS',
    label: 'SDS,  ProShares UltraShort S&P500',
    name: 'ProShares UltraShort S&P500',
    symbol: 'SDS',
  },
  {
    value: 'NOMD',
    label: 'NOMD,  Nomad Foods Limited',
    name: 'Nomad Foods Limited',
    symbol: 'NOMD',
  },
  {
    value: 'SFIX',
    label: 'SFIX,  Stitch Fix, Inc.',
    name: 'Stitch Fix, Inc.',
    symbol: 'SFIX',
  },
  {
    value: 'GD',
    label: 'GD,  General Dynamics Corporation',
    name: 'General Dynamics Corporation',
    symbol: 'GD',
  },
  {
    value: 'KIM',
    label: 'KIM,  Kimco Realty Corporation',
    name: 'Kimco Realty Corporation',
    symbol: 'KIM',
  },
  {
    value: 'FNF',
    label: 'FNF,  Fidelity National Financial, Inc.',
    name: 'Fidelity National Financial, Inc.',
    symbol: 'FNF',
  },
  {
    value: 'BXMT',
    label: 'BXMT,  Blackstone Mortgage Trust, Inc.',
    name: 'Blackstone Mortgage Trust, Inc.',
    symbol: 'BXMT',
  },
  {
    value: 'NWS',
    label: 'NWS,  News Corporation',
    name: 'News Corporation',
    symbol: 'NWS',
  },
  {
    value: 'CHD',
    label: 'CHD,  Church & Dwight Co., Inc.',
    name: 'Church & Dwight Co., Inc.',
    symbol: 'CHD',
  },
  {
    value: 'XLY',
    label: 'XLY,  Consumer Discretionary Select Sector SPDR Fund',
    name: 'Consumer Discretionary Select Sector SPDR Fund',
    symbol: 'XLY',
  },
  {
    value: 'DLR',
    label: 'DLR,  Digital Realty Trust, Inc.',
    name: 'Digital Realty Trust, Inc.',
    symbol: 'DLR',
  },
  {
    value: 'IP',
    label: 'IP,  International Paper Company',
    name: 'International Paper Company',
    symbol: 'IP',
  },
  {
    value: 'SE',
    label: 'SE,  Sea Limited',
    name: 'Sea Limited',
    symbol: 'SE',
  },
  {
    value: 'CC',
    label: 'CC,  The Chemours Company',
    name: 'The Chemours Company',
    symbol: 'CC',
  },
  {
    value: 'DAN',
    label: 'DAN,  Dana Incorporated',
    name: 'Dana Incorporated',
    symbol: 'DAN',
  },
  {
    value: 'ZBH',
    label: 'ZBH,  Zimmer Biomet Holdings, Inc.',
    name: 'Zimmer Biomet Holdings, Inc.',
    symbol: 'ZBH',
  },
  {
    value: 'NVAX',
    label: 'NVAX,  Novavax, Inc.',
    name: 'Novavax, Inc.',
    symbol: 'NVAX',
  },
  {
    value: 'LPX',
    label: 'LPX,  Louisiana-Pacific Corporation',
    name: 'Louisiana-Pacific Corporation',
    symbol: 'LPX',
  },
  {
    value: 'BBY',
    label: 'BBY,  Best Buy Co., Inc.',
    name: 'Best Buy Co., Inc.',
    symbol: 'BBY',
  },
  {
    value: 'BG',
    label: 'BG,  Bunge Limited',
    name: 'Bunge Limited',
    symbol: 'BG',
  },
  {
    value: 'LC',
    label: 'LC,  LendingClub Corporation',
    name: 'LendingClub Corporation',
    symbol: 'LC',
  },
  {
    value: 'PWR',
    label: 'PWR,  Quanta Services, Inc.',
    name: 'Quanta Services, Inc.',
    symbol: 'PWR',
  },
  {
    value: 'TNDM',
    label: 'TNDM,  Tandem Diabetes Care, Inc.',
    name: 'Tandem Diabetes Care, Inc.',
    symbol: 'TNDM',
  },
  {
    value: 'DXCM',
    label: 'DXCM,  DexCom, Inc.',
    name: 'DexCom, Inc.',
    symbol: 'DXCM',
  },
  {
    value: 'NTCT',
    label: 'NTCT,  NetScout Systems, Inc.',
    name: 'NetScout Systems, Inc.',
    symbol: 'NTCT',
  },
  {
    value: 'NYMT',
    label: 'NYMT,  New York Mortgage Trust, Inc.',
    name: 'New York Mortgage Trust, Inc.',
    symbol: 'NYMT',
  },
  {
    value: 'NEM',
    label: 'NEM,  Newmont Corporation',
    name: 'Newmont Corporation',
    symbol: 'NEM',
  },
  {
    value: 'BEN',
    label: 'BEN,  Franklin Resources, Inc.',
    name: 'Franklin Resources, Inc.',
    symbol: 'BEN',
  },
  {
    value: 'NAVI',
    label: 'NAVI,  Navient Corporation',
    name: 'Navient Corporation',
    symbol: 'NAVI',
  },
  {
    value: 'PBH',
    label: 'PBH,  Prestige Consumer Healthcare Inc.',
    name: 'Prestige Consumer Healthcare Inc.',
    symbol: 'PBH',
  },
  {
    value: 'SBH',
    label: 'SBH,  Sally Beauty Holdings, Inc.',
    name: 'Sally Beauty Holdings, Inc.',
    symbol: 'SBH',
  },
  {
    value: 'NI',
    label: 'NI,  NiSource Inc.',
    name: 'NiSource Inc.',
    symbol: 'NI',
  },
  {
    value: 'LLY',
    label: 'LLY,  Eli Lilly and Company',
    name: 'Eli Lilly and Company',
    symbol: 'LLY',
  },
  {
    value: 'STNG',
    label: 'STNG,  Scorpio Tankers Inc.',
    name: 'Scorpio Tankers Inc.',
    symbol: 'STNG',
  },
  {
    value: 'TTWO',
    label: 'TTWO,  Take-Two Interactive Software, Inc.',
    name: 'Take-Two Interactive Software, Inc.',
    symbol: 'TTWO',
  },
  {
    value: 'KLAC',
    label: 'KLAC,  KLA Corporation',
    name: 'KLA Corporation',
    symbol: 'KLAC',
  },
  {
    value: 'LADR',
    label: 'LADR,  Ladder Capital Corp',
    name: 'Ladder Capital Corp',
    symbol: 'LADR',
  },
  {
    value: 'XRT',
    label: 'XRT,  SPDR S&P Retail ETF',
    name: 'SPDR S&P Retail ETF',
    symbol: 'XRT',
  },
  {
    value: 'FHN',
    label: 'FHN,  First Horizon Corporation',
    name: 'First Horizon Corporation',
    symbol: 'FHN',
  },
  {
    value: 'GOOG',
    label: 'GOOG,  Alphabet Inc.',
    name: 'Alphabet Inc.',
    symbol: 'GOOG',
  },
  {
    value: 'HSIC',
    label: 'HSIC,  Henry Schein, Inc.',
    name: 'Henry Schein, Inc.',
    symbol: 'HSIC',
  },
  {
    value: 'HLX',
    label: 'HLX,  Helix Energy Solutions Group, Inc.',
    name: 'Helix Energy Solutions Group, Inc.',
    symbol: 'HLX',
  },
  {
    value: 'RSG',
    label: 'RSG,  Republic Services, Inc.',
    name: 'Republic Services, Inc.',
    symbol: 'RSG',
  },
  {
    value: 'BIG',
    label: 'BIG,  Big Lots, Inc.',
    name: 'Big Lots, Inc.',
    symbol: 'BIG',
  },
  {
    value: 'CYH',
    label: 'CYH,  Community Health Systems, Inc.',
    name: 'Community Health Systems, Inc.',
    symbol: 'CYH',
  },
  {
    value: 'PAYC',
    label: 'PAYC,  Paycom Software, Inc.',
    name: 'Paycom Software, Inc.',
    symbol: 'PAYC',
  },
  {
    value: 'YUM',
    label: 'YUM,  Yum! Brands, Inc.',
    name: 'Yum! Brands, Inc.',
    symbol: 'YUM',
  },
  {
    value: 'STZ',
    label: 'STZ,  Constellation Brands, Inc.',
    name: 'Constellation Brands, Inc.',
    symbol: 'STZ',
  },
  {
    value: 'EWH',
    label: 'EWH,  iShares MSCI Hong Kong ETF',
    name: 'iShares MSCI Hong Kong ETF',
    symbol: 'EWH',
  },
  {
    value: 'LBTYK',
    label: 'LBTYK,  Liberty Global plc',
    name: 'Liberty Global plc',
    symbol: 'LBTYK',
  },
  {
    value: 'LYG',
    label: 'LYG,  Lloyds Banking Group plc',
    name: 'Lloyds Banking Group plc',
    symbol: 'LYG',
  },
  {
    value: 'MEOH',
    label: 'MEOH,  Methanex Corporation',
    name: 'Methanex Corporation',
    symbol: 'MEOH',
  },
  {
    value: 'GIII',
    label: 'GIII,  G-III Apparel Group, Ltd.',
    name: 'G-III Apparel Group, Ltd.',
    symbol: 'GIII',
  },
  {
    value: 'USFD',
    label: 'USFD,  US Foods Holding Corp.',
    name: 'US Foods Holding Corp.',
    symbol: 'USFD',
  },
  {
    value: 'SSO',
    label: 'SSO,  ProShares Ultra S&P500',
    name: 'ProShares Ultra S&P500',
    symbol: 'SSO',
  },
  {
    value: 'SFM',
    label: 'SFM,  Sprouts Farmers Market, Inc.',
    name: 'Sprouts Farmers Market, Inc.',
    symbol: 'SFM',
  },
  {
    value: 'SPXL',
    label: 'SPXL,  Direxion Daily S&P500 Bull 3X Shares',
    name: 'Direxion Daily S&P500 Bull 3X Shares',
    symbol: 'SPXL',
  },
  {
    value: 'THC',
    label: 'THC,  Tenet Healthcare Corporation',
    name: 'Tenet Healthcare Corporation',
    symbol: 'THC',
  },
  {
    value: 'DOC',
    label: 'DOC,  Physicians Realty Trust',
    name: 'Physicians Realty Trust',
    symbol: 'DOC',
  },
  {
    value: 'ALLY',
    label: 'ALLY,  Ally Financial Inc.',
    name: 'Ally Financial Inc.',
    symbol: 'ALLY',
  },
  {
    value: 'QRVO',
    label: 'QRVO,  Qorvo, Inc.',
    name: 'Qorvo, Inc.',
    symbol: 'QRVO',
  },
  {
    value: 'TOPS',
    label: 'TOPS,  Top Ships Inc.',
    name: 'Top Ships Inc.',
    symbol: 'TOPS',
  },
  {
    value: 'HEDJ',
    label: 'HEDJ,  WisdomTree Europe Hedged Equity Fund',
    name: 'WisdomTree Europe Hedged Equity Fund',
    symbol: 'HEDJ',
  },
  {
    value: 'EVR',
    label: 'EVR,  Evercore Inc.',
    name: 'Evercore Inc.',
    symbol: 'EVR',
  },
  {
    value: 'CMS',
    label: 'CMS,  CMS Energy Corporation',
    name: 'CMS Energy Corporation',
    symbol: 'CMS',
  },
  {
    value: 'XLB',
    label: 'XLB,  Materials Select Sector SPDR Fund',
    name: 'Materials Select Sector SPDR Fund',
    symbol: 'XLB',
  },
  {
    value: 'DOX',
    label: 'DOX,  Amdocs Limited',
    name: 'Amdocs Limited',
    symbol: 'DOX',
  },
  {
    value: 'RIO',
    label: 'RIO,  Rio Tinto Group',
    name: 'Rio Tinto Group',
    symbol: 'RIO',
  },
  {
    value: 'NMRK',
    label: 'NMRK,  Newmark Group, Inc.',
    name: 'Newmark Group, Inc.',
    symbol: 'NMRK',
  },
  {
    value: 'PDM',
    label: 'PDM,  Piedmont Office Realty Trust, Inc.',
    name: 'Piedmont Office Realty Trust, Inc.',
    symbol: 'PDM',
  },
  {
    value: 'KMT',
    label: 'KMT,  Kennametal Inc.',
    name: 'Kennametal Inc.',
    symbol: 'KMT',
  },
  {
    value: 'SRNE',
    label: 'SRNE,  Sorrento Therapeutics, Inc.',
    name: 'Sorrento Therapeutics, Inc.',
    symbol: 'SRNE',
  },
  {
    value: 'AMR',
    label: 'AMR,  Alpha Metallurgical Resources, Inc.',
    name: 'Alpha Metallurgical Resources, Inc.',
    symbol: 'AMR',
  },
  {
    value: 'HP',
    label: 'HP,  Helmerich & Payne, Inc.',
    name: 'Helmerich & Payne, Inc.',
    symbol: 'HP',
  },
  {
    value: 'FLR',
    label: 'FLR,  Fluor Corporation',
    name: 'Fluor Corporation',
    symbol: 'FLR',
  },
  {
    value: 'JHG',
    label: 'JHG,  Janus Henderson Group plc',
    name: 'Janus Henderson Group plc',
    symbol: 'JHG',
  },
  {
    value: 'FDX',
    label: 'FDX,  FedEx Corporation',
    name: 'FedEx Corporation',
    symbol: 'FDX',
  },
  {
    value: 'EMN',
    label: 'EMN,  Eastman Chemical Company',
    name: 'Eastman Chemical Company',
    symbol: 'EMN',
  },
  {
    value: 'OPK',
    label: 'OPK,  OPKO Health, Inc.',
    name: 'OPKO Health, Inc.',
    symbol: 'OPK',
  },
  {
    value: 'LNG',
    label: 'LNG,  Cheniere Energy, Inc.',
    name: 'Cheniere Energy, Inc.',
    symbol: 'LNG',
  },
  {
    value: 'EWZ',
    label: 'EWZ,  iShares MSCI Brazil ETF',
    name: 'iShares MSCI Brazil ETF',
    symbol: 'EWZ',
  },
  {
    value: 'WU',
    label: 'WU,  The Western Union Company',
    name: 'The Western Union Company',
    symbol: 'WU',
  },
  {
    value: 'BAX',
    label: 'BAX,  Baxter International Inc.',
    name: 'Baxter International Inc.',
    symbol: 'BAX',
  },
  {
    value: 'CARS',
    label: 'CARS,  Cars.com Inc.',
    name: 'Cars.com Inc.',
    symbol: 'CARS',
  },
  {
    value: 'TEL',
    label: 'TEL,  TE Connectivity Ltd.',
    name: 'TE Connectivity Ltd.',
    symbol: 'TEL',
  },
  {
    value: 'MLM',
    label: 'MLM,  Martin Marietta Materials, Inc.',
    name: 'Martin Marietta Materials, Inc.',
    symbol: 'MLM',
  },
  {
    value: 'GWW',
    label: 'GWW,  W.W. Grainger, Inc.',
    name: 'W.W. Grainger, Inc.',
    symbol: 'GWW',
  },
  {
    value: 'PBF',
    label: 'PBF,  PBF Energy Inc.',
    name: 'PBF Energy Inc.',
    symbol: 'PBF',
  },
  {
    value: 'GT',
    label: 'GT,  The Goodyear Tire & Rubber Company',
    name: 'The Goodyear Tire & Rubber Company',
    symbol: 'GT',
  },
  {
    value: 'EA',
    label: 'EA,  Electronic Arts Inc.',
    name: 'Electronic Arts Inc.',
    symbol: 'EA',
  },
  {
    value: 'GPRO',
    label: 'GPRO,  GoPro, Inc.',
    name: 'GoPro, Inc.',
    symbol: 'GPRO',
  },
  {
    value: 'BW',
    label: 'BW,  Babcock & Wilcox Enterprises, Inc.',
    name: 'Babcock & Wilcox Enterprises, Inc.',
    symbol: 'BW',
  },
  {
    value: 'CMI',
    label: 'CMI,  Cummins Inc.',
    name: 'Cummins Inc.',
    symbol: 'CMI',
  },
  {
    value: 'CNQ',
    label: 'CNQ,  Canadian Natural Resources Limited',
    name: 'Canadian Natural Resources Limited',
    symbol: 'CNQ',
  },
  {
    value: 'HCA',
    label: 'HCA,  HCA Healthcare, Inc.',
    name: 'HCA Healthcare, Inc.',
    symbol: 'HCA',
  },
  {
    value: 'VRTX',
    label: 'VRTX,  Vertex Pharmaceuticals Incorporated',
    name: 'Vertex Pharmaceuticals Incorporated',
    symbol: 'VRTX',
  },
  {
    value: 'SAN',
    label: 'SAN,  Banco Santander, S.A.',
    name: 'Banco Santander, S.A.',
    symbol: 'SAN',
  },
  {
    value: 'CNHI',
    label: 'CNHI,  CNH Industrial N.V.',
    name: 'CNH Industrial N.V.',
    symbol: 'CNHI',
  },
  {
    value: 'CNX',
    label: 'CNX,  CNX Resources Corporation',
    name: 'CNX Resources Corporation',
    symbol: 'CNX',
  },
  {
    value: 'YY',
    label: 'YY,  JOYY Inc.',
    name: 'JOYY Inc.',
    symbol: 'YY',
  },
  {
    value: 'ES',
    label: 'ES,  Eversource Energy',
    name: 'Eversource Energy',
    symbol: 'ES',
  },
  {
    value: 'DXJ',
    label: 'DXJ,  WisdomTree Japan Hedged Equity Fund',
    name: 'WisdomTree Japan Hedged Equity Fund',
    symbol: 'DXJ',
  },
  {
    value: 'FWONK',
    label: 'FWONK,  Formula One Group',
    name: 'Formula One Group',
    symbol: 'FWONK',
  },
  {
    value: 'ARMK',
    label: 'ARMK,  Aramark',
    name: 'Aramark',
    symbol: 'ARMK',
  },
  {
    value: 'OII',
    label: 'OII,  Oceaneering International, Inc.',
    name: 'Oceaneering International, Inc.',
    symbol: 'OII',
  },
  {
    value: 'NOW',
    label: 'NOW,  ServiceNow, Inc.',
    name: 'ServiceNow, Inc.',
    symbol: 'NOW',
  },
  {
    value: 'APTV',
    label: 'APTV,  Aptiv PLC',
    name: 'Aptiv PLC',
    symbol: 'APTV',
  },
  {
    value: 'HOMB',
    label: 'HOMB,  Home Bancshares, Inc. (Conway, AR)',
    name: 'Home Bancshares, Inc. (Conway, AR)',
    symbol: 'HOMB',
  },
  {
    value: 'QD',
    label: 'QD,  Qudian Inc.',
    name: 'Qudian Inc.',
    symbol: 'QD',
  },
  {
    value: 'NSC',
    label: 'NSC,  Norfolk Southern Corporation',
    name: 'Norfolk Southern Corporation',
    symbol: 'NSC',
  },
  {
    value: 'NKTR',
    label: 'NKTR,  Nektar Therapeutics',
    name: 'Nektar Therapeutics',
    symbol: 'NKTR',
  },
  {
    value: 'PSX',
    label: 'PSX,  Phillips 66',
    name: 'Phillips 66',
    symbol: 'PSX',
  },
  {
    value: 'GAB',
    label: 'GAB,  The Gabelli Equity Trust Inc.',
    name: 'The Gabelli Equity Trust Inc.',
    symbol: 'GAB',
  },
  {
    value: 'UPRO',
    label: 'UPRO,  ProShares UltraPro S&P500',
    name: 'ProShares UltraPro S&P500',
    symbol: 'UPRO',
  },
  {
    value: 'MCHI',
    label: 'MCHI,  iShares MSCI China ETF',
    name: 'iShares MSCI China ETF',
    symbol: 'MCHI',
  },
  {
    value: 'AKAM',
    label: 'AKAM,  Akamai Technologies, Inc.',
    name: 'Akamai Technologies, Inc.',
    symbol: 'AKAM',
  },
  {
    value: 'IEF',
    label: 'IEF,  iShares 7-10 Year Treasury Bond ETF',
    name: 'iShares 7-10 Year Treasury Bond ETF',
    symbol: 'IEF',
  },
  {
    value: 'PNW',
    label: 'PNW,  Pinnacle West Capital Corporation',
    name: 'Pinnacle West Capital Corporation',
    symbol: 'PNW',
  },
  {
    value: 'AMH',
    label: 'AMH,  American Homes 4 Rent',
    name: 'American Homes 4 Rent',
    symbol: 'AMH',
  },
  {
    value: 'CDNS',
    label: 'CDNS,  Cadence Design Systems, Inc.',
    name: 'Cadence Design Systems, Inc.',
    symbol: 'CDNS',
  },
  {
    value: 'LABD',
    label: 'LABD,  Direxion Daily S&P Biotech Bear 3X Shares',
    name: 'Direxion Daily S&P Biotech Bear 3X Shares',
    symbol: 'LABD',
  },
  {
    value: 'A',
    label: 'A,  Agilent Technologies, Inc.',
    name: 'Agilent Technologies, Inc.',
    symbol: 'A',
  },
  {
    value: 'MDRX',
    label: 'MDRX,  Allscripts Healthcare Solutions, Inc.',
    name: 'Allscripts Healthcare Solutions, Inc.',
    symbol: 'MDRX',
  },
  {
    value: 'MOS',
    label: 'MOS,  The Mosaic Company',
    name: 'The Mosaic Company',
    symbol: 'MOS',
  },
  {
    value: 'LPL',
    label: 'LPL,  LG Display Co., Ltd.',
    name: 'LG Display Co., Ltd.',
    symbol: 'LPL',
  },
  {
    value: 'SKX',
    label: 'SKX,  Skechers U.S.A., Inc.',
    name: 'Skechers U.S.A., Inc.',
    symbol: 'SKX',
  },
  {
    value: 'HST',
    label: 'HST,  Host Hotels & Resorts, Inc.',
    name: 'Host Hotels & Resorts, Inc.',
    symbol: 'HST',
  },
  {
    value: 'UE',
    label: 'UE,  Urban Edge Properties',
    name: 'Urban Edge Properties',
    symbol: 'UE',
  },
  {
    value: 'PHM',
    label: 'PHM,  PulteGroup, Inc.',
    name: 'PulteGroup, Inc.',
    symbol: 'PHM',
  },
  {
    value: 'MT',
    label: 'MT,  ArcelorMittal S.A.',
    name: 'ArcelorMittal S.A.',
    symbol: 'MT',
  },
  {
    value: 'CAAP',
    label: 'CAAP,  Corporación América Airports S.A.',
    name: 'Corporación América Airports S.A.',
    symbol: 'CAAP',
  },
  {
    value: 'RRC',
    label: 'RRC,  Range Resources Corporation',
    name: 'Range Resources Corporation',
    symbol: 'RRC',
  },
  {
    value: 'FL',
    label: 'FL,  Foot Locker, Inc.',
    name: 'Foot Locker, Inc.',
    symbol: 'FL',
  },
  {
    value: 'NTAP',
    label: 'NTAP,  NetApp, Inc.',
    name: 'NetApp, Inc.',
    symbol: 'NTAP',
  },
  {
    value: 'AGO',
    label: 'AGO,  Assured Guaranty Ltd.',
    name: 'Assured Guaranty Ltd.',
    symbol: 'AGO',
  },
  {
    value: 'BBBY',
    label: 'BBBY,  Bed Bath & Beyond Inc.',
    name: 'Bed Bath & Beyond Inc.',
    symbol: 'BBBY',
  },
  {
    value: 'CCJ',
    label: 'CCJ,  Cameco Corporation',
    name: 'Cameco Corporation',
    symbol: 'CCJ',
  },
  {
    value: 'MAR',
    label: 'MAR,  Marriott International, Inc.',
    name: 'Marriott International, Inc.',
    symbol: 'MAR',
  },
  {
    value: 'AXTA',
    label: 'AXTA,  Axalta Coating Systems Ltd.',
    name: 'Axalta Coating Systems Ltd.',
    symbol: 'AXTA',
  },
  {
    value: 'VIAV',
    label: 'VIAV,  Viavi Solutions Inc.',
    name: 'Viavi Solutions Inc.',
    symbol: 'VIAV',
  },
  {
    value: 'SHOO',
    label: 'SHOO,  Steven Madden, Ltd.',
    name: 'Steven Madden, Ltd.',
    symbol: 'SHOO',
  },
  {
    value: 'AEM',
    label: 'AEM,  Agnico Eagle Mines Limited',
    name: 'Agnico Eagle Mines Limited',
    symbol: 'AEM',
  },
  {
    value: 'BB',
    label: 'BB,  BlackBerry Limited',
    name: 'BlackBerry Limited',
    symbol: 'BB',
  },
  {
    value: 'CB',
    label: 'CB,  Chubb Limited',
    name: 'Chubb Limited',
    symbol: 'CB',
  },
  {
    value: 'PRU',
    label: 'PRU,  Prudential Financial, Inc.',
    name: 'Prudential Financial, Inc.',
    symbol: 'PRU',
  },
  {
    value: 'RUN',
    label: 'RUN,  Sunrun Inc.',
    name: 'Sunrun Inc.',
    symbol: 'RUN',
  },
  {
    value: 'HA',
    label: 'HA,  Hawaiian Holdings, Inc.',
    name: 'Hawaiian Holdings, Inc.',
    symbol: 'HA',
  },
  {
    value: 'INDA',
    label: 'INDA,  iShares MSCI India ETF',
    name: 'iShares MSCI India ETF',
    symbol: 'INDA',
  },
  {
    value: 'UCBI',
    label: 'UCBI,  United Community Banks, Inc.',
    name: 'United Community Banks, Inc.',
    symbol: 'UCBI',
  },
  {
    value: 'LBTYA',
    label: 'LBTYA,  Liberty Global plc',
    name: 'Liberty Global plc',
    symbol: 'LBTYA',
  },
  {
    value: 'AVYA',
    label: 'AVYA,  Avaya Holdings Corp.',
    name: 'Avaya Holdings Corp.',
    symbol: 'AVYA',
  },
  {
    value: 'CBSH',
    label: 'CBSH,  Commerce Bancshares, Inc.',
    name: 'Commerce Bancshares, Inc.',
    symbol: 'CBSH',
  },
  {
    value: 'ANF',
    label: 'ANF,  Abercrombie & Fitch Co.',
    name: 'Abercrombie & Fitch Co.',
    symbol: 'ANF',
  },
  {
    value: 'HIG',
    label: 'HIG,  The Hartford Financial Services Group, Inc.',
    name: 'The Hartford Financial Services Group, Inc.',
    symbol: 'HIG',
  },
  {
    value: 'ITW',
    label: 'ITW,  Illinois Tool Works Inc.',
    name: 'Illinois Tool Works Inc.',
    symbol: 'ITW',
  },
  {
    value: 'AZN',
    label: 'AZN,  AstraZeneca PLC',
    name: 'AstraZeneca PLC',
    symbol: 'AZN',
  },
  {
    value: 'YELP',
    label: 'YELP,  Yelp Inc.',
    name: 'Yelp Inc.',
    symbol: 'YELP',
  },
  {
    value: 'EVC',
    label: 'EVC,  Entravision Communications Corporation',
    name: 'Entravision Communications Corporation',
    symbol: 'EVC',
  },
  {
    value: 'SPXS',
    label: 'SPXS,  Direxion Daily S&P 500 Bear 3X Shares',
    name: 'Direxion Daily S&P 500 Bear 3X Shares',
    symbol: 'SPXS',
  },
  {
    value: 'IRM',
    label: 'IRM,  Iron Mountain Incorporated',
    name: 'Iron Mountain Incorporated',
    symbol: 'IRM',
  },
  {
    value: 'TSCO',
    label: 'TSCO,  Tractor Supply Company',
    name: 'Tractor Supply Company',
    symbol: 'TSCO',
  },
  {
    value: 'EWC',
    label: 'EWC,  iShares MSCI Canada ETF',
    name: 'iShares MSCI Canada ETF',
    symbol: 'EWC',
  },
  {
    value: 'POST',
    label: 'POST,  Post Holdings, Inc.',
    name: 'Post Holdings, Inc.',
    symbol: 'POST',
  },
  {
    value: 'EL',
    label: 'EL,  The Estée Lauder Companies Inc.',
    name: 'The Estée Lauder Companies Inc.',
    symbol: 'EL',
  },
  {
    value: 'ING',
    label: 'ING,  ING Groep N.V.',
    name: 'ING Groep N.V.',
    symbol: 'ING',
  },
  {
    value: 'UBS',
    label: 'UBS,  UBS Group AG',
    name: 'UBS Group AG',
    symbol: 'UBS',
  },
  {
    value: 'KRG',
    label: 'KRG,  Kite Realty Group Trust',
    name: 'Kite Realty Group Trust',
    symbol: 'KRG',
  },
  {
    value: 'VSH',
    label: 'VSH,  Vishay Intertechnology, Inc.',
    name: 'Vishay Intertechnology, Inc.',
    symbol: 'VSH',
  },
  {
    value: 'TER',
    label: 'TER,  Teradyne, Inc.',
    name: 'Teradyne, Inc.',
    symbol: 'TER',
  },
  {
    value: 'EMB',
    label: 'EMB,  iShares J.P. Morgan USD Emerging Markets Bond ETF',
    name: 'iShares J.P. Morgan USD Emerging Markets Bond ETF',
    symbol: 'EMB',
  },
  {
    value: 'SPSB',
    label: 'SPSB,  SPDR Portfolio Short Term Corporate Bond ETF',
    name: 'SPDR Portfolio Short Term Corporate Bond ETF',
    symbol: 'SPSB',
  },
  {
    value: 'TWLO',
    label: 'TWLO,  Twilio Inc.',
    name: 'Twilio Inc.',
    symbol: 'TWLO',
  },
  {
    value: 'TRUE',
    label: 'TRUE,  TrueCar, Inc.',
    name: 'TrueCar, Inc.',
    symbol: 'TRUE',
  },
  {
    value: 'HPP',
    label: 'HPP,  Hudson Pacific Properties, Inc.',
    name: 'Hudson Pacific Properties, Inc.',
    symbol: 'HPP',
  },
  {
    value: 'CNO',
    label: 'CNO,  CNO Financial Group, Inc.',
    name: 'CNO Financial Group, Inc.',
    symbol: 'CNO',
  },
  {
    value: 'JNPR',
    label: 'JNPR,  Juniper Networks, Inc.',
    name: 'Juniper Networks, Inc.',
    symbol: 'JNPR',
  },
  {
    value: 'SMH',
    label: 'SMH,  VanEck Semiconductor ETF',
    name: 'VanEck Semiconductor ETF',
    symbol: 'SMH',
  },
  {
    value: 'URBN',
    label: 'URBN,  Urban Outfitters, Inc.',
    name: 'Urban Outfitters, Inc.',
    symbol: 'URBN',
  },
  {
    value: 'ROKU',
    label: 'ROKU,  Roku, Inc.',
    name: 'Roku, Inc.',
    symbol: 'ROKU',
  },
  {
    value: 'FEDU',
    label: 'FEDU,  Four Seasons Education (Cayman) Inc.',
    name: 'Four Seasons Education (Cayman) Inc.',
    symbol: 'FEDU',
  },
  {
    value: 'AUD',
    label: 'AUD,  Audacy, Inc.',
    name: 'Audacy, Inc.',
    symbol: 'AUD',
  },
  {
    value: 'BANC',
    label: 'BANC,  Banc of California, Inc.',
    name: 'Banc of California, Inc.',
    symbol: 'BANC',
  },
  {
    value: 'INVH',
    label: 'INVH,  Invitation Homes Inc.',
    name: 'Invitation Homes Inc.',
    symbol: 'INVH',
  },
  {
    value: 'OMF',
    label: 'OMF,  OneMain Holdings, Inc.',
    name: 'OneMain Holdings, Inc.',
    symbol: 'OMF',
  },
  {
    value: 'LW',
    label: 'LW,  Lamb Weston Holdings, Inc.',
    name: 'Lamb Weston Holdings, Inc.',
    symbol: 'LW',
  },
  {
    value: 'CTRE',
    label: 'CTRE,  CareTrust REIT, Inc.',
    name: 'CareTrust REIT, Inc.',
    symbol: 'CTRE',
  },
  {
    value: 'NHI',
    label: 'NHI,  National Health Investors, Inc.',
    name: 'National Health Investors, Inc.',
    symbol: 'NHI',
  },
  {
    value: 'FE',
    label: 'FE,  FirstEnergy Corp.',
    name: 'FirstEnergy Corp.',
    symbol: 'FE',
  },
  {
    value: 'NOC',
    label: 'NOC,  Northrop Grumman Corporation',
    name: 'Northrop Grumman Corporation',
    symbol: 'NOC',
  },
  {
    value: 'ASH',
    label: 'ASH,  Ashland Inc.',
    name: 'Ashland Inc.',
    symbol: 'ASH',
  },
  {
    value: 'MCHP',
    label: 'MCHP,  Microchip Technology Incorporated',
    name: 'Microchip Technology Incorporated',
    symbol: 'MCHP',
  },
  {
    value: 'KLIC',
    label: 'KLIC,  Kulicke and Soffa Industries, Inc.',
    name: 'Kulicke and Soffa Industries, Inc.',
    symbol: 'KLIC',
  },
  {
    value: 'O',
    label: 'O,  Realty Income Corporation',
    name: 'Realty Income Corporation',
    symbol: 'O',
  },
  {
    value: 'XLV',
    label: 'XLV,  Health Care Select Sector SPDR Fund',
    name: 'Health Care Select Sector SPDR Fund',
    symbol: 'XLV',
  },
  {
    value: 'FET',
    label: 'FET,  Forum Energy Technologies, Inc.',
    name: 'Forum Energy Technologies, Inc.',
    symbol: 'FET',
  },
  {
    value: 'TPH',
    label: 'TPH,  Tri Pointe Homes, Inc.',
    name: 'Tri Pointe Homes, Inc.',
    symbol: 'TPH',
  },
  {
    value: 'AMX',
    label: 'AMX,  América Móvil, S.A.B. de C.V.',
    name: 'América Móvil, S.A.B. de C.V.',
    symbol: 'AMX',
  },
  {
    value: 'FLO',
    label: 'FLO,  Flowers Foods, Inc.',
    name: 'Flowers Foods, Inc.',
    symbol: 'FLO',
  },
  {
    value: 'SHO',
    label: 'SHO,  Sunstone Hotel Investors, Inc.',
    name: 'Sunstone Hotel Investors, Inc.',
    symbol: 'SHO',
  },
  {
    value: 'TEAM',
    label: 'TEAM,  Atlassian Corporation',
    name: 'Atlassian Corporation',
    symbol: 'TEAM',
  },
  {
    value: 'COF',
    label: 'COF,  Capital One Financial Corporation',
    name: 'Capital One Financial Corporation',
    symbol: 'COF',
  },
  {
    value: 'EWL',
    label: 'EWL,  iShares MSCI Switzerland ETF',
    name: 'iShares MSCI Switzerland ETF',
    symbol: 'EWL',
  },
  {
    value: 'FLT',
    label: 'FLT,  FLEETCOR Technologies, Inc.',
    name: 'FLEETCOR Technologies, Inc.',
    symbol: 'FLT',
  },
  {
    value: 'PLUG',
    label: 'PLUG,  Plug Power Inc.',
    name: 'Plug Power Inc.',
    symbol: 'PLUG',
  },
  {
    value: 'HIMX',
    label: 'HIMX,  Himax Technologies, Inc.',
    name: 'Himax Technologies, Inc.',
    symbol: 'HIMX',
  },
  {
    value: 'IGT',
    label: 'IGT,  International Game Technology PLC',
    name: 'International Game Technology PLC',
    symbol: 'IGT',
  },
  {
    value: 'IBB',
    label: 'IBB,  iShares Biotechnology ETF',
    name: 'iShares Biotechnology ETF',
    symbol: 'IBB',
  },
  {
    value: 'FANG',
    label: 'FANG,  Diamondback Energy, Inc.',
    name: 'Diamondback Energy, Inc.',
    symbol: 'FANG',
  },
  {
    value: 'XLRE',
    label: 'XLRE,  The Real Estate Select Sector SPDR Fund',
    name: 'The Real Estate Select Sector SPDR Fund',
    symbol: 'XLRE',
  },
  {
    value: 'TROW',
    label: 'TROW,  T. Rowe Price Group, Inc.',
    name: 'T. Rowe Price Group, Inc.',
    symbol: 'TROW',
  },
  {
    value: 'ARLP',
    label: 'ARLP,  Alliance Resource Partners, L.P.',
    name: 'Alliance Resource Partners, L.P.',
    symbol: 'ARLP',
  },
  {
    value: 'OSK',
    label: 'OSK,  Oshkosh Corporation',
    name: 'Oshkosh Corporation',
    symbol: 'OSK',
  },
  {
    value: 'DHR',
    label: 'DHR,  Danaher Corporation',
    name: 'Danaher Corporation',
    symbol: 'DHR',
  },
  {
    value: 'BLMN',
    label: "BLMN,  Bloomin' Brands, Inc.",
    name: "Bloomin' Brands, Inc.",
    symbol: 'BLMN',
  },
  {
    value: 'BNDX',
    label: 'BNDX,  Vanguard Total International Bond Index Fund',
    name: 'Vanguard Total International Bond Index Fund',
    symbol: 'BNDX',
  },
  {
    value: 'AMN',
    label: 'AMN,  AMN Healthcare Services, Inc.',
    name: 'AMN Healthcare Services, Inc.',
    symbol: 'AMN',
  },
  {
    value: 'PPC',
    label: "PPC,  Pilgrim's Pride Corporation",
    name: "Pilgrim's Pride Corporation",
    symbol: 'PPC',
  },
  {
    value: 'GNTX',
    label: 'GNTX,  Gentex Corporation',
    name: 'Gentex Corporation',
    symbol: 'GNTX',
  },
  {
    value: 'RCL',
    label: 'RCL,  Royal Caribbean Cruises Ltd.',
    name: 'Royal Caribbean Cruises Ltd.',
    symbol: 'RCL',
  },
  {
    value: 'INTU',
    label: 'INTU,  Intuit Inc.',
    name: 'Intuit Inc.',
    symbol: 'INTU',
  },
  {
    value: 'W',
    label: 'W,  Wayfair Inc.',
    name: 'Wayfair Inc.',
    symbol: 'W',
  },
  {
    value: 'PCAR',
    label: 'PCAR,  PACCAR Inc',
    name: 'PACCAR Inc',
    symbol: 'PCAR',
  },
  {
    value: 'IR',
    label: 'IR,  Ingersoll Rand Inc.',
    name: 'Ingersoll Rand Inc.',
    symbol: 'IR',
  },
  {
    value: 'PEG',
    label: 'PEG,  Public Service Enterprise Group Incorporated',
    name: 'Public Service Enterprise Group Incorporated',
    symbol: 'PEG',
  },
  {
    value: 'FBP',
    label: 'FBP,  First BanCorp.',
    name: 'First BanCorp.',
    symbol: 'FBP',
  },
  {
    value: 'WEC',
    label: 'WEC,  WEC Energy Group, Inc.',
    name: 'WEC Energy Group, Inc.',
    symbol: 'WEC',
  },
  {
    value: 'MDC',
    label: 'MDC,  M.D.C. Holdings, Inc.',
    name: 'M.D.C. Holdings, Inc.',
    symbol: 'MDC',
  },
  {
    value: 'TISI',
    label: 'TISI,  Team, Inc.',
    name: 'Team, Inc.',
    symbol: 'TISI',
  },
  {
    value: 'YUMC',
    label: 'YUMC,  Yum China Holdings, Inc.',
    name: 'Yum China Holdings, Inc.',
    symbol: 'YUMC',
  },
  {
    value: 'ALV',
    label: 'ALV,  Autoliv, Inc.',
    name: 'Autoliv, Inc.',
    symbol: 'ALV',
  },
  {
    value: 'IWF',
    label: 'IWF,  iShares Russell 1000 Growth ETF',
    name: 'iShares Russell 1000 Growth ETF',
    symbol: 'IWF',
  },
  {
    value: 'BHP',
    label: 'BHP,  BHP Group Limited',
    name: 'BHP Group Limited',
    symbol: 'BHP',
  },
  {
    value: 'BXP',
    label: 'BXP,  Boston Properties, Inc.',
    name: 'Boston Properties, Inc.',
    symbol: 'BXP',
  },
  {
    value: 'BYD',
    label: 'BYD,  Boyd Gaming Corporation',
    name: 'Boyd Gaming Corporation',
    symbol: 'BYD',
  },
  {
    value: 'WGO',
    label: 'WGO,  Winnebago Industries, Inc.',
    name: 'Winnebago Industries, Inc.',
    symbol: 'WGO',
  },
  {
    value: 'PUMP',
    label: 'PUMP,  ProPetro Holding Corp.',
    name: 'ProPetro Holding Corp.',
    symbol: 'PUMP',
  },
  {
    value: 'NOG',
    label: 'NOG,  Northern Oil and Gas, Inc.',
    name: 'Northern Oil and Gas, Inc.',
    symbol: 'NOG',
  },
  {
    value: 'FOLD',
    label: 'FOLD,  Amicus Therapeutics, Inc.',
    name: 'Amicus Therapeutics, Inc.',
    symbol: 'FOLD',
  },
  {
    value: 'JBHT',
    label: 'JBHT,  J.B. Hunt Transport Services, Inc.',
    name: 'J.B. Hunt Transport Services, Inc.',
    symbol: 'JBHT',
  },
  {
    value: 'AGG',
    label: 'AGG,  iShares Core U.S. Aggregate Bond ETF',
    name: 'iShares Core U.S. Aggregate Bond ETF',
    symbol: 'AGG',
  },
  {
    value: 'FBHS',
    label: 'FBHS,  Fortune Brands Home & Security, Inc.',
    name: 'Fortune Brands Home & Security, Inc.',
    symbol: 'FBHS',
  },
  {
    value: 'TXT',
    label: 'TXT,  Textron Inc.',
    name: 'Textron Inc.',
    symbol: 'TXT',
  },
  {
    value: 'EXPD',
    label: 'EXPD,  Expeditors International of Washington, Inc.',
    name: 'Expeditors International of Washington, Inc.',
    symbol: 'EXPD',
  },
  {
    value: 'EXPE',
    label: 'EXPE,  Expedia Group, Inc.',
    name: 'Expedia Group, Inc.',
    symbol: 'EXPE',
  },
  {
    value: 'WERN',
    label: 'WERN,  Werner Enterprises, Inc.',
    name: 'Werner Enterprises, Inc.',
    symbol: 'WERN',
  },
  {
    value: 'AGI',
    label: 'AGI,  Alamos Gold Inc.',
    name: 'Alamos Gold Inc.',
    symbol: 'AGI',
  },
  {
    value: 'ED',
    label: 'ED,  Consolidated Edison, Inc.',
    name: 'Consolidated Edison, Inc.',
    symbol: 'ED',
  },
  {
    value: 'HCSG',
    label: 'HCSG,  Healthcare Services Group, Inc.',
    name: 'Healthcare Services Group, Inc.',
    symbol: 'HCSG',
  },
  {
    value: 'DVAX',
    label: 'DVAX,  Dynavax Technologies Corporation',
    name: 'Dynavax Technologies Corporation',
    symbol: 'DVAX',
  },
  {
    value: 'DLTR',
    label: 'DLTR,  Dollar Tree, Inc.',
    name: 'Dollar Tree, Inc.',
    symbol: 'DLTR',
  },
  {
    value: 'VIXY',
    label: 'VIXY,  ProShares VIX Short-Term Futures ETF',
    name: 'ProShares VIX Short-Term Futures ETF',
    symbol: 'VIXY',
  },
  {
    value: 'OUT',
    label: 'OUT,  Outfront Media Inc.',
    name: 'Outfront Media Inc.',
    symbol: 'OUT',
  },
  {
    value: 'MTSI',
    label: 'MTSI,  MACOM Technology Solutions Holdings, Inc.',
    name: 'MACOM Technology Solutions Holdings, Inc.',
    symbol: 'MTSI',
  },
  {
    value: 'DBD',
    label: 'DBD,  Diebold Nixdorf, Incorporated',
    name: 'Diebold Nixdorf, Incorporated',
    symbol: 'DBD',
  },
  {
    value: 'CBT',
    label: 'CBT,  Cabot Corporation',
    name: 'Cabot Corporation',
    symbol: 'CBT',
  },
  {
    value: 'STOR',
    label: 'STOR,  STORE Capital Corporation',
    name: 'STORE Capital Corporation',
    symbol: 'STOR',
  },
  {
    value: 'VEU',
    label: 'VEU,  Vanguard FTSE All-World ex-US Index Fund',
    name: 'Vanguard FTSE All-World ex-US Index Fund',
    symbol: 'VEU',
  },
  {
    value: 'BGCP',
    label: 'BGCP,  BGC Partners, Inc.',
    name: 'BGC Partners, Inc.',
    symbol: 'BGCP',
  },
  {
    value: 'VCIT',
    label: 'VCIT,  Vanguard Intermediate-Term Corporate Bond Index Fund',
    name: 'Vanguard Intermediate-Term Corporate Bond Index Fund',
    symbol: 'VCIT',
  },
  {
    value: 'ABB',
    label: 'ABB,  ABB Ltd',
    name: 'ABB Ltd',
    symbol: 'ABB',
  },
  {
    value: 'GTE',
    label: 'GTE,  Gran Tierra Energy Inc.',
    name: 'Gran Tierra Energy Inc.',
    symbol: 'GTE',
  },
  {
    value: 'SWKS',
    label: 'SWKS,  Skyworks Solutions, Inc.',
    name: 'Skyworks Solutions, Inc.',
    symbol: 'SWKS',
  },
  {
    value: 'CP',
    label: 'CP,  Canadian Pacific Railway Limited',
    name: 'Canadian Pacific Railway Limited',
    symbol: 'CP',
  },
  {
    value: 'PNR',
    label: 'PNR,  Pentair plc',
    name: 'Pentair plc',
    symbol: 'PNR',
  },
  {
    value: 'PKI',
    label: 'PKI,  PerkinElmer, Inc.',
    name: 'PerkinElmer, Inc.',
    symbol: 'PKI',
  },
  {
    value: 'CROX',
    label: 'CROX,  Crocs, Inc.',
    name: 'Crocs, Inc.',
    symbol: 'CROX',
  },
  {
    value: 'ASB',
    label: 'ASB,  Associated Banc-Corp',
    name: 'Associated Banc-Corp',
    symbol: 'ASB',
  },
  {
    value: 'UHS',
    label: 'UHS,  Universal Health Services, Inc.',
    name: 'Universal Health Services, Inc.',
    symbol: 'UHS',
  },
  {
    value: 'ENB',
    label: 'ENB,  Enbridge Inc.',
    name: 'Enbridge Inc.',
    symbol: 'ENB',
  },
  {
    value: 'TDC',
    label: 'TDC,  Teradata Corporation',
    name: 'Teradata Corporation',
    symbol: 'TDC',
  },
  {
    value: 'AG',
    label: 'AG,  First Majestic Silver Corp.',
    name: 'First Majestic Silver Corp.',
    symbol: 'AG',
  },
  {
    value: 'ACHC',
    label: 'ACHC,  Acadia Healthcare Company, Inc.',
    name: 'Acadia Healthcare Company, Inc.',
    symbol: 'ACHC',
  },
  {
    value: 'NAT',
    label: 'NAT,  Nordic American Tankers Limited',
    name: 'Nordic American Tankers Limited',
    symbol: 'NAT',
  },
  {
    value: 'SPG',
    label: 'SPG,  Simon Property Group, Inc.',
    name: 'Simon Property Group, Inc.',
    symbol: 'SPG',
  },
  {
    value: 'EWA',
    label: 'EWA,  iShares MSCI Australia ETF',
    name: 'iShares MSCI Australia ETF',
    symbol: 'EWA',
  },
  {
    value: 'QRTEA',
    label: 'QRTEA,  Qurate Retail, Inc.',
    name: 'Qurate Retail, Inc.',
    symbol: 'QRTEA',
  },
  {
    value: 'TRIP',
    label: 'TRIP,  Tripadvisor, Inc.',
    name: 'Tripadvisor, Inc.',
    symbol: 'TRIP',
  },
  {
    value: 'TRP',
    label: 'TRP,  TC Energy Corporation',
    name: 'TC Energy Corporation',
    symbol: 'TRP',
  },
  {
    value: 'FAST',
    label: 'FAST,  Fastenal Company',
    name: 'Fastenal Company',
    symbol: 'FAST',
  },
  {
    value: 'RH',
    label: 'RH,  RH',
    name: 'RH',
    symbol: 'RH',
  },
  {
    value: 'IVV',
    label: 'IVV,  iShares Core S&P 500 ETF',
    name: 'iShares Core S&P 500 ETF',
    symbol: 'IVV',
  },
  {
    value: 'CBRE',
    label: 'CBRE,  CBRE Group, Inc.',
    name: 'CBRE Group, Inc.',
    symbol: 'CBRE',
  },
  {
    value: 'HGV',
    label: 'HGV,  Hilton Grand Vacations Inc.',
    name: 'Hilton Grand Vacations Inc.',
    symbol: 'HGV',
  },
  {
    value: 'AEG',
    label: 'AEG,  Aegon N.V.',
    name: 'Aegon N.V.',
    symbol: 'AEG',
  },
  {
    value: 'BTI',
    label: 'BTI,  British American Tobacco p.l.c.',
    name: 'British American Tobacco p.l.c.',
    symbol: 'BTI',
  },
  {
    value: 'EWP',
    label: 'EWP,  iShares MSCI Spain ETF',
    name: 'iShares MSCI Spain ETF',
    symbol: 'EWP',
  },
  {
    value: 'NVS',
    label: 'NVS,  Novartis AG',
    name: 'Novartis AG',
    symbol: 'NVS',
  },
  {
    value: 'EWBC',
    label: 'EWBC,  East West Bancorp, Inc.',
    name: 'East West Bancorp, Inc.',
    symbol: 'EWBC',
  },
  {
    value: 'QSR',
    label: 'QSR,  Restaurant Brands International Inc.',
    name: 'Restaurant Brands International Inc.',
    symbol: 'QSR',
  },
  {
    value: 'GSAT',
    label: 'GSAT,  Globalstar, Inc.',
    name: 'Globalstar, Inc.',
    symbol: 'GSAT',
  },
  {
    value: 'DBEU',
    label: 'DBEU,  Xtrackers MSCI Europe Hedged Equity ETF',
    name: 'Xtrackers MSCI Europe Hedged Equity ETF',
    symbol: 'DBEU',
  },
  {
    value: 'VRAY',
    label: 'VRAY,  ViewRay, Inc.',
    name: 'ViewRay, Inc.',
    symbol: 'VRAY',
  },
  {
    value: 'SGH',
    label: 'SGH,  SMART Global Holdings, Inc.',
    name: 'SMART Global Holdings, Inc.',
    symbol: 'SGH',
  },
  {
    value: 'ALNY',
    label: 'ALNY,  Alnylam Pharmaceuticals, Inc.',
    name: 'Alnylam Pharmaceuticals, Inc.',
    symbol: 'ALNY',
  },
  {
    value: 'SCHB',
    label: 'SCHB,  Schwab U.S. Broad Market ETF',
    name: 'Schwab U.S. Broad Market ETF',
    symbol: 'SCHB',
  },
  {
    value: 'BMRN',
    label: 'BMRN,  BioMarin Pharmaceutical Inc.',
    name: 'BioMarin Pharmaceutical Inc.',
    symbol: 'BMRN',
  },
  {
    value: 'CASA',
    label: 'CASA,  Casa Systems, Inc.',
    name: 'Casa Systems, Inc.',
    symbol: 'CASA',
  },
  {
    value: 'SCZ',
    label: 'SCZ,  iShares MSCI EAFE Small-Cap ETF',
    name: 'iShares MSCI EAFE Small-Cap ETF',
    symbol: 'SCZ',
  },
  {
    value: 'AGNC',
    label: 'AGNC,  AGNC Investment Corp.',
    name: 'AGNC Investment Corp.',
    symbol: 'AGNC',
  },
  {
    value: 'HRTX',
    label: 'HRTX,  Heron Therapeutics, Inc.',
    name: 'Heron Therapeutics, Inc.',
    symbol: 'HRTX',
  },
  {
    value: 'LDOS',
    label: 'LDOS,  Leidos Holdings, Inc.',
    name: 'Leidos Holdings, Inc.',
    symbol: 'LDOS',
  },
  {
    value: 'MPW',
    label: 'MPW,  Medical Properties Trust, Inc.',
    name: 'Medical Properties Trust, Inc.',
    symbol: 'MPW',
  },
  {
    value: 'TXMD',
    label: 'TXMD,  TherapeuticsMD, Inc.',
    name: 'TherapeuticsMD, Inc.',
    symbol: 'TXMD',
  },
  {
    value: 'CIM',
    label: 'CIM,  Chimera Investment Corporation',
    name: 'Chimera Investment Corporation',
    symbol: 'CIM',
  },
  {
    value: 'INSM',
    label: 'INSM,  Insmed Incorporated',
    name: 'Insmed Incorporated',
    symbol: 'INSM',
  },
  {
    value: 'NG',
    label: 'NG,  NovaGold Resources Inc.',
    name: 'NovaGold Resources Inc.',
    symbol: 'NG',
  },
  {
    value: 'BGFV',
    label: 'BGFV,  Big 5 Sporting Goods Corporation',
    name: 'Big 5 Sporting Goods Corporation',
    symbol: 'BGFV',
  },
  {
    value: 'AEE',
    label: 'AEE,  Ameren Corporation',
    name: 'Ameren Corporation',
    symbol: 'AEE',
  },
  {
    value: 'HCC',
    label: 'HCC,  Warrior Met Coal, Inc.',
    name: 'Warrior Met Coal, Inc.',
    symbol: 'HCC',
  },
  {
    value: 'LAMR',
    label: 'LAMR,  Lamar Advertising Company',
    name: 'Lamar Advertising Company',
    symbol: 'LAMR',
  },
  {
    value: 'EXEL',
    label: 'EXEL,  Exelixis, Inc.',
    name: 'Exelixis, Inc.',
    symbol: 'EXEL',
  },
  {
    value: 'HZNP',
    label: 'HZNP,  Horizon Therapeutics Public Limited Company',
    name: 'Horizon Therapeutics Public Limited Company',
    symbol: 'HZNP',
  },
  {
    value: 'ADI',
    label: 'ADI,  Analog Devices, Inc.',
    name: 'Analog Devices, Inc.',
    symbol: 'ADI',
  },
  {
    value: 'FLOT',
    label: 'FLOT,  iShares Floating Rate Bond ETF',
    name: 'iShares Floating Rate Bond ETF',
    symbol: 'FLOT',
  },
  {
    value: 'AKBA',
    label: 'AKBA,  Akebia Therapeutics, Inc.',
    name: 'Akebia Therapeutics, Inc.',
    symbol: 'AKBA',
  },
  {
    value: 'OLED',
    label: 'OLED,  Universal Display Corporation',
    name: 'Universal Display Corporation',
    symbol: 'OLED',
  },
  {
    value: 'EYE',
    label: 'EYE,  National Vision Holdings, Inc.',
    name: 'National Vision Holdings, Inc.',
    symbol: 'EYE',
  },
  {
    value: 'TMO',
    label: 'TMO,  Thermo Fisher Scientific Inc.',
    name: 'Thermo Fisher Scientific Inc.',
    symbol: 'TMO',
  },
  {
    value: 'LBRDA',
    label: 'LBRDA,  Liberty Broadband Corporation',
    name: 'Liberty Broadband Corporation',
    symbol: 'LBRDA',
  },
  {
    value: 'OFC',
    label: 'OFC,  Corporate Office Properties Trust',
    name: 'Corporate Office Properties Trust',
    symbol: 'OFC',
  },
  {
    value: 'STT',
    label: 'STT,  State Street Corporation',
    name: 'State Street Corporation',
    symbol: 'STT',
  },
  {
    value: 'HLF',
    label: 'HLF,  Herbalife Nutrition Ltd.',
    name: 'Herbalife Nutrition Ltd.',
    symbol: 'HLF',
  },
  {
    value: 'WRK',
    label: 'WRK,  WestRock Company',
    name: 'WestRock Company',
    symbol: 'WRK',
  },
  {
    value: 'GREK',
    label: 'GREK,  Global X MSCI Greece ETF',
    name: 'Global X MSCI Greece ETF',
    symbol: 'GREK',
  },
  {
    value: 'BR',
    label: 'BR,  Broadridge Financial Solutions, Inc.',
    name: 'Broadridge Financial Solutions, Inc.',
    symbol: 'BR',
  },
  {
    value: 'MCD',
    label: "MCD,  McDonald's Corporation",
    name: "McDonald's Corporation",
    symbol: 'MCD',
  },
  {
    value: 'PRO',
    label: 'PRO,  PROS Holdings, Inc.',
    name: 'PROS Holdings, Inc.',
    symbol: 'PRO',
  },
  {
    value: 'SSNC',
    label: 'SSNC,  SS&C Technologies Holdings, Inc.',
    name: 'SS&C Technologies Holdings, Inc.',
    symbol: 'SSNC',
  },
  {
    value: 'TWO',
    label: 'TWO,  Two Harbors Investment Corp.',
    name: 'Two Harbors Investment Corp.',
    symbol: 'TWO',
  },
  {
    value: 'CHTR',
    label: 'CHTR,  Charter Communications, Inc.',
    name: 'Charter Communications, Inc.',
    symbol: 'CHTR',
  },
  {
    value: 'AXL',
    label: 'AXL,  American Axle & Manufacturing Holdings, Inc.',
    name: 'American Axle & Manufacturing Holdings, Inc.',
    symbol: 'AXL',
  },
  {
    value: 'ACAD',
    label: 'ACAD,  ACADIA Pharmaceuticals Inc.',
    name: 'ACADIA Pharmaceuticals Inc.',
    symbol: 'ACAD',
  },
  {
    value: 'VLY',
    label: 'VLY,  Valley National Bancorp',
    name: 'Valley National Bancorp',
    symbol: 'VLY',
  },
  {
    value: 'VOD',
    label: 'VOD,  Vodafone Group Public Limited Company',
    name: 'Vodafone Group Public Limited Company',
    symbol: 'VOD',
  },
  {
    value: 'EXTR',
    label: 'EXTR,  Extreme Networks, Inc.',
    name: 'Extreme Networks, Inc.',
    symbol: 'EXTR',
  },
  {
    value: 'SUN',
    label: 'SUN,  Sunoco LP',
    name: 'Sunoco LP',
    symbol: 'SUN',
  },
  {
    value: 'VECO',
    label: 'VECO,  Veeco Instruments Inc.',
    name: 'Veeco Instruments Inc.',
    symbol: 'VECO',
  },
  {
    value: 'ZEN',
    label: 'ZEN,  Zendesk, Inc.',
    name: 'Zendesk, Inc.',
    symbol: 'ZEN',
  },
  {
    value: 'ATEN',
    label: 'ATEN,  A10 Networks, Inc.',
    name: 'A10 Networks, Inc.',
    symbol: 'ATEN',
  },
  {
    value: 'CRC',
    label: 'CRC,  California Resources Corporation',
    name: 'California Resources Corporation',
    symbol: 'CRC',
  },
  {
    value: 'ATUS',
    label: 'ATUS,  Altice USA, Inc.',
    name: 'Altice USA, Inc.',
    symbol: 'ATUS',
  },
  {
    value: 'SIG',
    label: 'SIG,  Signet Jewelers Limited',
    name: 'Signet Jewelers Limited',
    symbol: 'SIG',
  },
  {
    value: 'BDC',
    label: 'BDC,  Belden Inc.',
    name: 'Belden Inc.',
    symbol: 'BDC',
  },
  {
    value: 'ERIC',
    label: 'ERIC,  Telefonaktiebolaget LM Ericsson (publ)',
    name: 'Telefonaktiebolaget LM Ericsson (publ)',
    symbol: 'ERIC',
  },
  {
    value: 'XPO',
    label: 'XPO,  XPO Logistics, Inc.',
    name: 'XPO Logistics, Inc.',
    symbol: 'XPO',
  },
  {
    value: 'APO',
    label: 'APO,  Apollo Global Management, Inc.',
    name: 'Apollo Global Management, Inc.',
    symbol: 'APO',
  },
  {
    value: 'CRI',
    label: "CRI,  Carter's, Inc.",
    name: "Carter's, Inc.",
    symbol: 'CRI',
  },
  {
    value: 'MANH',
    label: 'MANH,  Manhattan Associates, Inc.',
    name: 'Manhattan Associates, Inc.',
    symbol: 'MANH',
  },
  {
    value: 'AGCO',
    label: 'AGCO,  AGCO Corporation',
    name: 'AGCO Corporation',
    symbol: 'AGCO',
  },
  {
    value: 'TILE',
    label: 'TILE,  Interface, Inc.',
    name: 'Interface, Inc.',
    symbol: 'TILE',
  },
  {
    value: 'MMC',
    label: 'MMC,  Marsh & McLennan Companies, Inc.',
    name: 'Marsh & McLennan Companies, Inc.',
    symbol: 'MMC',
  },
  {
    value: 'GNW',
    label: 'GNW,  Genworth Financial, Inc.',
    name: 'Genworth Financial, Inc.',
    symbol: 'GNW',
  },
  {
    value: 'CAKE',
    label: 'CAKE,  The Cheesecake Factory Incorporated',
    name: 'The Cheesecake Factory Incorporated',
    symbol: 'CAKE',
  },
  {
    value: 'CAR',
    label: 'CAR,  Avis Budget Group, Inc.',
    name: 'Avis Budget Group, Inc.',
    symbol: 'CAR',
  },
  {
    value: 'TMHC',
    label: 'TMHC,  Taylor Morrison Home Corporation',
    name: 'Taylor Morrison Home Corporation',
    symbol: 'TMHC',
  },
  {
    value: 'URI',
    label: 'URI,  United Rentals, Inc.',
    name: 'United Rentals, Inc.',
    symbol: 'URI',
  },
  {
    value: 'SPLK',
    label: 'SPLK,  Splunk Inc.',
    name: 'Splunk Inc.',
    symbol: 'SPLK',
  },
  {
    value: 'VRSN',
    label: 'VRSN,  VeriSign, Inc.',
    name: 'VeriSign, Inc.',
    symbol: 'VRSN',
  },
  {
    value: 'MCO',
    label: "MCO,  Moody's Corporation",
    name: "Moody's Corporation",
    symbol: 'MCO',
  },
  {
    value: 'SLG',
    label: 'SLG,  SL Green Realty Corp.',
    name: 'SL Green Realty Corp.',
    symbol: 'SLG',
  },
  {
    value: 'BZUN',
    label: 'BZUN,  Baozun Inc.',
    name: 'Baozun Inc.',
    symbol: 'BZUN',
  },
  {
    value: 'PAGS',
    label: 'PAGS,  PagSeguro Digital Ltd.',
    name: 'PagSeguro Digital Ltd.',
    symbol: 'PAGS',
  },
  {
    value: 'L',
    label: 'L,  Loews Corporation',
    name: 'Loews Corporation',
    symbol: 'L',
  },
  {
    value: 'NWSA',
    label: 'NWSA,  News Corporation',
    name: 'News Corporation',
    symbol: 'NWSA',
  },
  {
    value: 'MBI',
    label: 'MBI,  MBIA Inc.',
    name: 'MBIA Inc.',
    symbol: 'MBI',
  },
  {
    value: 'VNO',
    label: 'VNO,  Vornado Realty Trust',
    name: 'Vornado Realty Trust',
    symbol: 'VNO',
  },
  {
    value: 'PDS',
    label: 'PDS,  Precision Drilling Corporation',
    name: 'Precision Drilling Corporation',
    symbol: 'PDS',
  },
  {
    value: 'BCC',
    label: 'BCC,  Boise Cascade Company',
    name: 'Boise Cascade Company',
    symbol: 'BCC',
  },
  {
    value: 'ULTA',
    label: 'ULTA,  Ulta Beauty, Inc.',
    name: 'Ulta Beauty, Inc.',
    symbol: 'ULTA',
  },
  {
    value: 'PRTY',
    label: 'PRTY,  Party City Holdco Inc.',
    name: 'Party City Holdco Inc.',
    symbol: 'PRTY',
  },
  {
    value: 'JACK',
    label: 'JACK,  Jack in the Box Inc.',
    name: 'Jack in the Box Inc.',
    symbol: 'JACK',
  },
  {
    value: 'OGE',
    label: 'OGE,  OGE Energy Corp.',
    name: 'OGE Energy Corp.',
    symbol: 'OGE',
  },
  {
    value: 'QGEN',
    label: 'QGEN,  Qiagen N.V.',
    name: 'Qiagen N.V.',
    symbol: 'QGEN',
  },
  {
    value: 'CPRT',
    label: 'CPRT,  Copart, Inc.',
    name: 'Copart, Inc.',
    symbol: 'CPRT',
  },
  {
    value: 'EAT',
    label: 'EAT,  Brinker International, Inc.',
    name: 'Brinker International, Inc.',
    symbol: 'EAT',
  },
  {
    value: 'DXC',
    label: 'DXC,  DXC Technology Company',
    name: 'DXC Technology Company',
    symbol: 'DXC',
  },
  {
    value: 'UEC',
    label: 'UEC,  Uranium Energy Corp.',
    name: 'Uranium Energy Corp.',
    symbol: 'UEC',
  },
  {
    value: 'CLVS',
    label: 'CLVS,  Clovis Oncology, Inc.',
    name: 'Clovis Oncology, Inc.',
    symbol: 'CLVS',
  },
  {
    value: 'UDR',
    label: 'UDR,  UDR, Inc.',
    name: 'UDR, Inc.',
    symbol: 'UDR',
  },
  {
    value: 'KBR',
    label: 'KBR,  KBR, Inc.',
    name: 'KBR, Inc.',
    symbol: 'KBR',
  },
  {
    value: 'STM',
    label: 'STM,  STMicroelectronics N.V.',
    name: 'STMicroelectronics N.V.',
    symbol: 'STM',
  },
  {
    value: 'AQUA',
    label: 'AQUA,  Evoqua Water Technologies Corp.',
    name: 'Evoqua Water Technologies Corp.',
    symbol: 'AQUA',
  },
  {
    value: 'SBRA',
    label: 'SBRA,  Sabra Health Care REIT, Inc.',
    name: 'Sabra Health Care REIT, Inc.',
    symbol: 'SBRA',
  },
  {
    value: 'EQT',
    label: 'EQT,  EQT Corporation',
    name: 'EQT Corporation',
    symbol: 'EQT',
  },
  {
    value: 'FISV',
    label: 'FISV,  Fiserv, Inc.',
    name: 'Fiserv, Inc.',
    symbol: 'FISV',
  },
  {
    value: 'SPIB',
    label: 'SPIB,  SPDR Portfolio Intermediate Term Corporate Bond ETF',
    name: 'SPDR Portfolio Intermediate Term Corporate Bond ETF',
    symbol: 'SPIB',
  },
  {
    value: 'SRE',
    label: 'SRE,  Sempra',
    name: 'Sempra',
    symbol: 'SRE',
  },
  {
    value: 'DIA',
    label: 'DIA,  SPDR Dow Jones Industrial Average ETF Trust',
    name: 'SPDR Dow Jones Industrial Average ETF Trust',
    symbol: 'DIA',
  },
  {
    value: 'SAVE',
    label: 'SAVE,  Spirit Airlines, Inc.',
    name: 'Spirit Airlines, Inc.',
    symbol: 'SAVE',
  },
  {
    value: 'DXLG',
    label: 'DXLG,  Destination XL Group, Inc.',
    name: 'Destination XL Group, Inc.',
    symbol: 'DXLG',
  },
  {
    value: 'SQM',
    label: 'SQM,  Sociedad Química y Minera de Chile S.A.',
    name: 'Sociedad Química y Minera de Chile S.A.',
    symbol: 'SQM',
  },
  {
    value: 'SXT',
    label: 'SXT,  Sensient Technologies Corporation',
    name: 'Sensient Technologies Corporation',
    symbol: 'SXT',
  },
  {
    value: 'OMC',
    label: 'OMC,  Omnicom Group Inc.',
    name: 'Omnicom Group Inc.',
    symbol: 'OMC',
  },
  {
    value: 'LXP',
    label: 'LXP,  LXP Industrial Trust',
    name: 'LXP Industrial Trust',
    symbol: 'LXP',
  },
  {
    value: 'ONB',
    label: 'ONB,  Old National Bancorp',
    name: 'Old National Bancorp',
    symbol: 'ONB',
  },
  {
    value: 'FMC',
    label: 'FMC,  FMC Corporation',
    name: 'FMC Corporation',
    symbol: 'FMC',
  },
  {
    value: 'KN',
    label: 'KN,  Knowles Corporation',
    name: 'Knowles Corporation',
    symbol: 'KN',
  },
  {
    value: 'KW',
    label: 'KW,  Kennedy-Wilson Holdings, Inc.',
    name: 'Kennedy-Wilson Holdings, Inc.',
    symbol: 'KW',
  },
  {
    value: 'SCS',
    label: 'SCS,  Steelcase Inc.',
    name: 'Steelcase Inc.',
    symbol: 'SCS',
  },
  {
    value: 'CXW',
    label: 'CXW,  CoreCivic, Inc.',
    name: 'CoreCivic, Inc.',
    symbol: 'CXW',
  },
  {
    value: 'DFS',
    label: 'DFS,  Discover Financial Services',
    name: 'Discover Financial Services',
    symbol: 'DFS',
  },
  {
    value: 'VTV',
    label: 'VTV,  Vanguard Value Index Fund',
    name: 'Vanguard Value Index Fund',
    symbol: 'VTV',
  },
  {
    value: 'PH',
    label: 'PH,  Parker-Hannifin Corporation',
    name: 'Parker-Hannifin Corporation',
    symbol: 'PH',
  },
  {
    value: 'USA',
    label: 'USA,  Liberty All-Star Equity Fund',
    name: 'Liberty All-Star Equity Fund',
    symbol: 'USA',
  },
  {
    value: 'CIEN',
    label: 'CIEN,  Ciena Corporation',
    name: 'Ciena Corporation',
    symbol: 'CIEN',
  },
  {
    value: 'OIS',
    label: 'OIS,  Oil States International, Inc.',
    name: 'Oil States International, Inc.',
    symbol: 'OIS',
  },
  {
    value: 'SCCO',
    label: 'SCCO,  Southern Copper Corporation',
    name: 'Southern Copper Corporation',
    symbol: 'SCCO',
  },
  {
    value: 'VT',
    label: 'VT,  Vanguard Total World Stock Index Fund',
    name: 'Vanguard Total World Stock Index Fund',
    symbol: 'VT',
  },
  {
    value: 'YEXT',
    label: 'YEXT,  Yext, Inc.',
    name: 'Yext, Inc.',
    symbol: 'YEXT',
  },
  {
    value: 'ATSG',
    label: 'ATSG,  Air Transport Services Group, Inc.',
    name: 'Air Transport Services Group, Inc.',
    symbol: 'ATSG',
  },
  {
    value: 'REVG',
    label: 'REVG,  REV Group, Inc.',
    name: 'REV Group, Inc.',
    symbol: 'REVG',
  },
  {
    value: 'ALLE',
    label: 'ALLE,  Allegion plc',
    name: 'Allegion plc',
    symbol: 'ALLE',
  },
  {
    value: 'MHK',
    label: 'MHK,  Mohawk Industries, Inc.',
    name: 'Mohawk Industries, Inc.',
    symbol: 'MHK',
  },
  {
    value: 'SPWH',
    label: "SPWH,  Sportsman's Warehouse Holdings, Inc.",
    name: "Sportsman's Warehouse Holdings, Inc.",
    symbol: 'SPWH',
  },
  {
    value: 'TWNK',
    label: 'TWNK,  Hostess Brands, Inc.',
    name: 'Hostess Brands, Inc.',
    symbol: 'TWNK',
  },
  {
    value: 'EQIX',
    label: 'EQIX,  Equinix, Inc.',
    name: 'Equinix, Inc.',
    symbol: 'EQIX',
  },
  {
    value: 'VNTR',
    label: 'VNTR,  Venator Materials PLC',
    name: 'Venator Materials PLC',
    symbol: 'VNTR',
  },
  {
    value: 'SUM',
    label: 'SUM,  Summit Materials, Inc.',
    name: 'Summit Materials, Inc.',
    symbol: 'SUM',
  },
  {
    value: 'RDFN',
    label: 'RDFN,  Redfin Corporation',
    name: 'Redfin Corporation',
    symbol: 'RDFN',
  },
  {
    value: 'ERJ',
    label: 'ERJ,  Embraer S.A.',
    name: 'Embraer S.A.',
    symbol: 'ERJ',
  },
  {
    value: 'UNM',
    label: 'UNM,  Unum Group',
    name: 'Unum Group',
    symbol: 'UNM',
  },
  {
    value: 'NJR',
    label: 'NJR,  New Jersey Resources Corporation',
    name: 'New Jersey Resources Corporation',
    symbol: 'NJR',
  },
  {
    value: 'NNN',
    label: 'NNN,  National Retail Properties, Inc.',
    name: 'National Retail Properties, Inc.',
    symbol: 'NNN',
  },
  {
    value: 'PAGP',
    label: 'PAGP,  Plains GP Holdings, L.P.',
    name: 'Plains GP Holdings, L.P.',
    symbol: 'PAGP',
  },
  {
    value: 'TNA',
    label: 'TNA,  Direxion Daily Small Cap Bull 3X Shares',
    name: 'Direxion Daily Small Cap Bull 3X Shares',
    symbol: 'TNA',
  },
  {
    value: 'LIVN',
    label: 'LIVN,  LivaNova PLC',
    name: 'LivaNova PLC',
    symbol: 'LIVN',
  },
  {
    value: 'CTAS',
    label: 'CTAS,  Cintas Corporation',
    name: 'Cintas Corporation',
    symbol: 'CTAS',
  },
  {
    value: 'VRNT',
    label: 'VRNT,  Verint Systems Inc.',
    name: 'Verint Systems Inc.',
    symbol: 'VRNT',
  },
  {
    value: 'HAS',
    label: 'HAS,  Hasbro, Inc.',
    name: 'Hasbro, Inc.',
    symbol: 'HAS',
  },
  {
    value: 'AOS',
    label: 'AOS,  A. O. Smith Corporation',
    name: 'A. O. Smith Corporation',
    symbol: 'AOS',
  },
  {
    value: 'DGX',
    label: 'DGX,  Quest Diagnostics Incorporated',
    name: 'Quest Diagnostics Incorporated',
    symbol: 'DGX',
  },
  {
    value: 'CUZ',
    label: 'CUZ,  Cousins Properties Incorporated',
    name: 'Cousins Properties Incorporated',
    symbol: 'CUZ',
  },
  {
    value: 'HALO',
    label: 'HALO,  Halozyme Therapeutics, Inc.',
    name: 'Halozyme Therapeutics, Inc.',
    symbol: 'HALO',
  },
  {
    value: 'SGMO',
    label: 'SGMO,  Sangamo Therapeutics, Inc.',
    name: 'Sangamo Therapeutics, Inc.',
    symbol: 'SGMO',
  },
  {
    value: 'KTOS',
    label: 'KTOS,  Kratos Defense & Security Solutions, Inc.',
    name: 'Kratos Defense & Security Solutions, Inc.',
    symbol: 'KTOS',
  },
  {
    value: 'FIS',
    label: 'FIS,  Fidelity National Information Services, Inc.',
    name: 'Fidelity National Information Services, Inc.',
    symbol: 'FIS',
  },
  {
    value: 'SCHX',
    label: 'SCHX,  Schwab U.S. Large-Cap ETF',
    name: 'Schwab U.S. Large-Cap ETF',
    symbol: 'SCHX',
  },
  {
    value: 'TRN',
    label: 'TRN,  Trinity Industries, Inc.',
    name: 'Trinity Industries, Inc.',
    symbol: 'TRN',
  },
  {
    value: 'SPGI',
    label: 'SPGI,  S&P Global Inc.',
    name: 'S&P Global Inc.',
    symbol: 'SPGI',
  },
  {
    value: 'BHF',
    label: 'BHF,  Brighthouse Financial, Inc.',
    name: 'Brighthouse Financial, Inc.',
    symbol: 'BHF',
  },
  {
    value: 'RYAAY',
    label: 'RYAAY,  Ryanair Holdings plc',
    name: 'Ryanair Holdings plc',
    symbol: 'RYAAY',
  },
  {
    value: 'AN',
    label: 'AN,  AutoNation, Inc.',
    name: 'AutoNation, Inc.',
    symbol: 'AN',
  },
  {
    value: 'WDAY',
    label: 'WDAY,  Workday, Inc.',
    name: 'Workday, Inc.',
    symbol: 'WDAY',
  },
  {
    value: 'ABR',
    label: 'ABR,  Arbor Realty Trust, Inc.',
    name: 'Arbor Realty Trust, Inc.',
    symbol: 'ABR',
  },
  {
    value: 'MCK',
    label: 'MCK,  McKesson Corporation',
    name: 'McKesson Corporation',
    symbol: 'MCK',
  },
  {
    value: 'VC',
    label: 'VC,  Visteon Corporation',
    name: 'Visteon Corporation',
    symbol: 'VC',
  },
  {
    value: 'ROIC',
    label: 'ROIC,  Retail Opportunity Investments Corp.',
    name: 'Retail Opportunity Investments Corp.',
    symbol: 'ROIC',
  },
  {
    value: 'MUX',
    label: 'MUX,  McEwen Mining Inc.',
    name: 'McEwen Mining Inc.',
    symbol: 'MUX',
  },
  {
    value: 'IEX',
    label: 'IEX,  IDEX Corporation',
    name: 'IDEX Corporation',
    symbol: 'IEX',
  },
  {
    value: 'ASHR',
    label: 'ASHR,  Xtrackers Harvest CSI 300 China A-Shares ETF',
    name: 'Xtrackers Harvest CSI 300 China A-Shares ETF',
    symbol: 'ASHR',
  },
  {
    value: 'IJH',
    label: 'IJH,  iShares Core S&P Mid-Cap ETF',
    name: 'iShares Core S&P Mid-Cap ETF',
    symbol: 'IJH',
  },
  {
    value: 'INN',
    label: 'INN,  Summit Hotel Properties, Inc.',
    name: 'Summit Hotel Properties, Inc.',
    symbol: 'INN',
  },
  {
    value: 'ITOT',
    label: 'ITOT,  iShares Core S&P Total U.S. Stock Market ETF',
    name: 'iShares Core S&P Total U.S. Stock Market ETF',
    symbol: 'ITOT',
  },
  {
    value: 'FAZ',
    label: 'FAZ,  Direxion Daily Financial Bear 3X Shares',
    name: 'Direxion Daily Financial Bear 3X Shares',
    symbol: 'FAZ',
  },
  {
    value: 'SH',
    label: 'SH,  ProShares Short S&P500',
    name: 'ProShares Short S&P500',
    symbol: 'SH',
  },
  {
    value: 'TROX',
    label: 'TROX,  Tronox Holdings plc',
    name: 'Tronox Holdings plc',
    symbol: 'TROX',
  },
  {
    value: 'OEC',
    label: 'OEC,  Orion Engineered Carbons S.A.',
    name: 'Orion Engineered Carbons S.A.',
    symbol: 'OEC',
  },
  {
    value: 'ABC',
    label: 'ABC,  AmerisourceBergen Corporation',
    name: 'AmerisourceBergen Corporation',
    symbol: 'ABC',
  },
  {
    value: 'EFX',
    label: 'EFX,  Equifax Inc.',
    name: 'Equifax Inc.',
    symbol: 'EFX',
  },
  {
    value: 'DPZ',
    label: "DPZ,  Domino's Pizza, Inc.",
    name: "Domino's Pizza, Inc.",
    symbol: 'DPZ',
  },
  {
    value: 'ENLC',
    label: 'ENLC,  EnLink Midstream, LLC',
    name: 'EnLink Midstream, LLC',
    symbol: 'ENLC',
  },
  {
    value: 'TTM',
    label: 'TTM,  Tata Motors Limited',
    name: 'Tata Motors Limited',
    symbol: 'TTM',
  },
  {
    value: 'OHI',
    label: 'OHI,  Omega Healthcare Investors, Inc.',
    name: 'Omega Healthcare Investors, Inc.',
    symbol: 'OHI',
  },
  {
    value: 'VOO',
    label: 'VOO,  Vanguard 500 Index Fund',
    name: 'Vanguard 500 Index Fund',
    symbol: 'VOO',
  },
  {
    value: 'SUI',
    label: 'SUI,  Sun Communities, Inc.',
    name: 'Sun Communities, Inc.',
    symbol: 'SUI',
  },
  {
    value: 'SIVB',
    label: 'SIVB,  SVB Financial Group',
    name: 'SVB Financial Group',
    symbol: 'SIVB',
  },
  {
    value: 'MPLX',
    label: 'MPLX,  MPLX LP',
    name: 'MPLX LP',
    symbol: 'MPLX',
  },
  {
    value: 'EC',
    label: 'EC,  Ecopetrol S.A.',
    name: 'Ecopetrol S.A.',
    symbol: 'EC',
  },
  {
    value: 'FIVE',
    label: 'FIVE,  Five Below, Inc.',
    name: 'Five Below, Inc.',
    symbol: 'FIVE',
  },
  {
    value: 'LPLA',
    label: 'LPLA,  LPL Financial Holdings Inc.',
    name: 'LPL Financial Holdings Inc.',
    symbol: 'LPLA',
  },
  {
    value: 'SEIC',
    label: 'SEIC,  SEI Investments Company',
    name: 'SEI Investments Company',
    symbol: 'SEIC',
  },
  {
    value: 'SIX',
    label: 'SIX,  Six Flags Entertainment Corporation',
    name: 'Six Flags Entertainment Corporation',
    symbol: 'SIX',
  },
  {
    value: 'VEEV',
    label: 'VEEV,  Veeva Systems Inc.',
    name: 'Veeva Systems Inc.',
    symbol: 'VEEV',
  },
  {
    value: 'FORM',
    label: 'FORM,  FormFactor, Inc.',
    name: 'FormFactor, Inc.',
    symbol: 'FORM',
  },
  {
    value: 'VIG',
    label: 'VIG,  Vanguard Dividend Appreciation Index Fund',
    name: 'Vanguard Dividend Appreciation Index Fund',
    symbol: 'VIG',
  },
  {
    value: 'NVO',
    label: 'NVO,  Novo Nordisk A/S',
    name: 'Novo Nordisk A/S',
    symbol: 'NVO',
  },
  {
    value: 'SWK',
    label: 'SWK,  Stanley Black & Decker, Inc.',
    name: 'Stanley Black & Decker, Inc.',
    symbol: 'SWK',
  },
  {
    value: 'SNDR',
    label: 'SNDR,  Schneider National, Inc.',
    name: 'Schneider National, Inc.',
    symbol: 'SNDR',
  },
  {
    value: 'VNDA',
    label: 'VNDA,  Vanda Pharmaceuticals Inc.',
    name: 'Vanda Pharmaceuticals Inc.',
    symbol: 'VNDA',
  },
  {
    value: 'HTHT',
    label: 'HTHT,  H World Group Limited',
    name: 'H World Group Limited',
    symbol: 'HTHT',
  },
  {
    value: 'EW',
    label: 'EW,  Edwards Lifesciences Corporation',
    name: 'Edwards Lifesciences Corporation',
    symbol: 'EW',
  },
  {
    value: 'SKT',
    label: 'SKT,  Tanger Factory Outlet Centers, Inc.',
    name: 'Tanger Factory Outlet Centers, Inc.',
    symbol: 'SKT',
  },
  {
    value: 'ROK',
    label: 'ROK,  Rockwell Automation, Inc.',
    name: 'Rockwell Automation, Inc.',
    symbol: 'ROK',
  },
  {
    value: 'PENN',
    label: 'PENN,  PENN Entertainment, Inc.',
    name: 'PENN Entertainment, Inc.',
    symbol: 'PENN',
  },
  {
    value: 'AMRN',
    label: 'AMRN,  Amarin Corporation plc',
    name: 'Amarin Corporation plc',
    symbol: 'AMRN',
  },
  {
    value: 'ALL',
    label: 'ALL,  The Allstate Corporation',
    name: 'The Allstate Corporation',
    symbol: 'ALL',
  },
  {
    value: 'IONS',
    label: 'IONS,  Ionis Pharmaceuticals, Inc.',
    name: 'Ionis Pharmaceuticals, Inc.',
    symbol: 'IONS',
  },
  {
    value: 'APRN',
    label: 'APRN,  Blue Apron Holdings, Inc.',
    name: 'Blue Apron Holdings, Inc.',
    symbol: 'APRN',
  },
  {
    value: 'KRNY',
    label: 'KRNY,  Kearny Financial Corp.',
    name: 'Kearny Financial Corp.',
    symbol: 'KRNY',
  },
  {
    value: 'SBAC',
    label: 'SBAC,  SBA Communications Corporation',
    name: 'SBA Communications Corporation',
    symbol: 'SBAC',
  },
  {
    value: 'MFC',
    label: 'MFC,  Manulife Financial Corporation',
    name: 'Manulife Financial Corporation',
    symbol: 'MFC',
  },
  {
    value: 'ZTS',
    label: 'ZTS,  Zoetis Inc.',
    name: 'Zoetis Inc.',
    symbol: 'ZTS',
  },
  {
    value: 'BUD',
    label: 'BUD,  Anheuser-Busch InBev SA/NV',
    name: 'Anheuser-Busch InBev SA/NV',
    symbol: 'BUD',
  },
  {
    value: 'UA',
    label: 'UA,  Under Armour, Inc.',
    name: 'Under Armour, Inc.',
    symbol: 'UA',
  },
  {
    value: 'AROC',
    label: 'AROC,  Archrock, Inc.',
    name: 'Archrock, Inc.',
    symbol: 'AROC',
  },
  {
    value: 'THO',
    label: 'THO,  THOR Industries, Inc.',
    name: 'THOR Industries, Inc.',
    symbol: 'THO',
  },
  {
    value: 'BGNE',
    label: 'BGNE,  BeiGene, Ltd.',
    name: 'BeiGene, Ltd.',
    symbol: 'BGNE',
  },
  {
    value: 'WB',
    label: 'WB,  Weibo Corporation',
    name: 'Weibo Corporation',
    symbol: 'WB',
  },
  {
    value: 'KT',
    label: 'KT,  KT Corporation',
    name: 'KT Corporation',
    symbol: 'KT',
  },
  {
    value: 'BOTZ',
    label: 'BOTZ,  Global X Robotics & Artificial Intelligence ETF',
    name: 'Global X Robotics & Artificial Intelligence ETF',
    symbol: 'BOTZ',
  },
  {
    value: 'TGNA',
    label: 'TGNA,  TEGNA Inc.',
    name: 'TEGNA Inc.',
    symbol: 'TGNA',
  },
  {
    value: 'CHGG',
    label: 'CHGG,  Chegg, Inc.',
    name: 'Chegg, Inc.',
    symbol: 'CHGG',
  },
  {
    value: 'HYD',
    label: 'HYD,  VanEck High Yield Muni ETF',
    name: 'VanEck High Yield Muni ETF',
    symbol: 'HYD',
  },
  {
    value: 'WM',
    label: 'WM,  Waste Management, Inc.',
    name: 'Waste Management, Inc.',
    symbol: 'WM',
  },
  {
    value: 'ASX',
    label: 'ASX,  ASE Technology Holding Co., Ltd.',
    name: 'ASE Technology Holding Co., Ltd.',
    symbol: 'ASX',
  },
  {
    value: 'THRM',
    label: 'THRM,  Gentherm Incorporated',
    name: 'Gentherm Incorporated',
    symbol: 'THRM',
  },
  {
    value: 'UMPQ',
    label: 'UMPQ,  Umpqua Holdings Corporation',
    name: 'Umpqua Holdings Corporation',
    symbol: 'UMPQ',
  },
  {
    value: 'ALB',
    label: 'ALB,  Albemarle Corporation',
    name: 'Albemarle Corporation',
    symbol: 'ALB',
  },
  {
    value: 'RHI',
    label: 'RHI,  Robert Half International Inc.',
    name: 'Robert Half International Inc.',
    symbol: 'RHI',
  },
  {
    value: 'HLT',
    label: 'HLT,  Hilton Worldwide Holdings Inc.',
    name: 'Hilton Worldwide Holdings Inc.',
    symbol: 'HLT',
  },
  {
    value: 'DNOW',
    label: 'DNOW,  NOW Inc.',
    name: 'NOW Inc.',
    symbol: 'DNOW',
  },
  {
    value: 'PNFP',
    label: 'PNFP,  Pinnacle Financial Partners, Inc.',
    name: 'Pinnacle Financial Partners, Inc.',
    symbol: 'PNFP',
  },
  {
    value: 'DRH',
    label: 'DRH,  DiamondRock Hospitality Company',
    name: 'DiamondRock Hospitality Company',
    symbol: 'DRH',
  },
  {
    value: 'OC',
    label: 'OC,  Owens Corning',
    name: 'Owens Corning',
    symbol: 'OC',
  },
  {
    value: 'CUBE',
    label: 'CUBE,  CubeSmart',
    name: 'CubeSmart',
    symbol: 'CUBE',
  },
  {
    value: 'GLPI',
    label: 'GLPI,  Gaming and Leisure Properties, Inc.',
    name: 'Gaming and Leisure Properties, Inc.',
    symbol: 'GLPI',
  },
  {
    value: 'CBOE',
    label: 'CBOE,  Cboe Global Markets, Inc.',
    name: 'Cboe Global Markets, Inc.',
    symbol: 'CBOE',
  },
  {
    value: 'RDNT',
    label: 'RDNT,  RadNet, Inc.',
    name: 'RadNet, Inc.',
    symbol: 'RDNT',
  },
  {
    value: 'AGRO',
    label: 'AGRO,  Adecoagro S.A.',
    name: 'Adecoagro S.A.',
    symbol: 'AGRO',
  },
  {
    value: 'AL',
    label: 'AL,  Air Lease Corporation',
    name: 'Air Lease Corporation',
    symbol: 'AL',
  },
  {
    value: 'AMJ',
    label: 'AMJ,  JPMorgan Alerian MLP Index ETN',
    name: 'JPMorgan Alerian MLP Index ETN',
    symbol: 'AMJ',
  },
  {
    value: 'EVH',
    label: 'EVH,  Evolent Health, Inc.',
    name: 'Evolent Health, Inc.',
    symbol: 'EVH',
  },
  {
    value: 'UIS',
    label: 'UIS,  Unisys Corporation',
    name: 'Unisys Corporation',
    symbol: 'UIS',
  },
  {
    value: 'JELD',
    label: 'JELD,  JELD-WEN Holding, Inc.',
    name: 'JELD-WEN Holding, Inc.',
    symbol: 'JELD',
  },
  {
    value: 'TDG',
    label: 'TDG,  TransDigm Group Incorporated',
    name: 'TransDigm Group Incorporated',
    symbol: 'TDG',
  },
  {
    value: 'LITE',
    label: 'LITE,  Lumentum Holdings Inc.',
    name: 'Lumentum Holdings Inc.',
    symbol: 'LITE',
  },
  {
    value: 'RY',
    label: 'RY,  Royal Bank of Canada',
    name: 'Royal Bank of Canada',
    symbol: 'RY',
  },
  {
    value: 'ROST',
    label: 'ROST,  Ross Stores, Inc.',
    name: 'Ross Stores, Inc.',
    symbol: 'ROST',
  },
  {
    value: 'SYK',
    label: 'SYK,  Stryker Corporation',
    name: 'Stryker Corporation',
    symbol: 'SYK',
  },
  {
    value: 'REI',
    label: 'REI,  Ring Energy, Inc.',
    name: 'Ring Energy, Inc.',
    symbol: 'REI',
  },
  {
    value: 'TRV',
    label: 'TRV,  The Travelers Companies, Inc.',
    name: 'The Travelers Companies, Inc.',
    symbol: 'TRV',
  },
  {
    value: 'CSL',
    label: 'CSL,  Carlisle Companies Incorporated',
    name: 'Carlisle Companies Incorporated',
    symbol: 'CSL',
  },
  {
    value: 'GWRE',
    label: 'GWRE,  Guidewire Software, Inc.',
    name: 'Guidewire Software, Inc.',
    symbol: 'GWRE',
  },
  {
    value: 'ANET',
    label: 'ANET,  Arista Networks, Inc.',
    name: 'Arista Networks, Inc.',
    symbol: 'ANET',
  },
  {
    value: 'PCRX',
    label: 'PCRX,  Pacira BioSciences, Inc.',
    name: 'Pacira BioSciences, Inc.',
    symbol: 'PCRX',
  },
  {
    value: 'CSIQ',
    label: 'CSIQ,  Canadian Solar Inc.',
    name: 'Canadian Solar Inc.',
    symbol: 'CSIQ',
  },
  {
    value: 'ICHR',
    label: 'ICHR,  Ichor Holdings, Ltd.',
    name: 'Ichor Holdings, Ltd.',
    symbol: 'ICHR',
  },
  {
    value: 'WSM',
    label: 'WSM,  Williams-Sonoma, Inc.',
    name: 'Williams-Sonoma, Inc.',
    symbol: 'WSM',
  },
  {
    value: 'EXP',
    label: 'EXP,  Eagle Materials Inc.',
    name: 'Eagle Materials Inc.',
    symbol: 'EXP',
  },
  {
    value: 'BWA',
    label: 'BWA,  BorgWarner Inc.',
    name: 'BorgWarner Inc.',
    symbol: 'BWA',
  },
  {
    value: 'SWCH',
    label: 'SWCH,  Switch, Inc.',
    name: 'Switch, Inc.',
    symbol: 'SWCH',
  },
  {
    value: 'APLE',
    label: 'APLE,  Apple Hospitality REIT, Inc.',
    name: 'Apple Hospitality REIT, Inc.',
    symbol: 'APLE',
  },
  {
    value: 'DRQ',
    label: 'DRQ,  Dril-Quip, Inc.',
    name: 'Dril-Quip, Inc.',
    symbol: 'DRQ',
  },
  {
    value: 'AXS',
    label: 'AXS,  AXIS Capital Holdings Limited',
    name: 'AXIS Capital Holdings Limited',
    symbol: 'AXS',
  },
  {
    value: 'JNUG',
    label: 'JNUG,  Direxion Daily Junior Gold Miners Index Bull 2X Shares',
    name: 'Direxion Daily Junior Gold Miners Index Bull 2X Shares',
    symbol: 'JNUG',
  },
  {
    value: 'MTDR',
    label: 'MTDR,  Matador Resources Company',
    name: 'Matador Resources Company',
    symbol: 'MTDR',
  },
  {
    value: 'CAJ',
    label: 'CAJ,  Canon Inc.',
    name: 'Canon Inc.',
    symbol: 'CAJ',
  },
  {
    value: 'CNI',
    label: 'CNI,  Canadian National Railway Company',
    name: 'Canadian National Railway Company',
    symbol: 'CNI',
  },
  {
    value: 'ILMN',
    label: 'ILMN,  Illumina, Inc.',
    name: 'Illumina, Inc.',
    symbol: 'ILMN',
  },
  {
    value: 'ISRG',
    label: 'ISRG,  Intuitive Surgical, Inc.',
    name: 'Intuitive Surgical, Inc.',
    symbol: 'ISRG',
  },
  {
    value: 'CAL',
    label: 'CAL,  Caleres, Inc.',
    name: 'Caleres, Inc.',
    symbol: 'CAL',
  },
  {
    value: 'SAP',
    label: 'SAP,  SAP SE',
    name: 'SAP SE',
    symbol: 'SAP',
  },
  {
    value: 'ESNT',
    label: 'ESNT,  Essent Group Ltd.',
    name: 'Essent Group Ltd.',
    symbol: 'ESNT',
  },
  {
    value: 'NMFC',
    label: 'NMFC,  New Mountain Finance Corporation',
    name: 'New Mountain Finance Corporation',
    symbol: 'NMFC',
  },
  {
    value: 'BLDP',
    label: 'BLDP,  Ballard Power Systems Inc.',
    name: 'Ballard Power Systems Inc.',
    symbol: 'BLDP',
  },
  {
    value: 'OI',
    label: 'OI,  O-I Glass, Inc.',
    name: 'O-I Glass, Inc.',
    symbol: 'OI',
  },
  {
    value: 'MDU',
    label: 'MDU,  MDU Resources Group, Inc.',
    name: 'MDU Resources Group, Inc.',
    symbol: 'MDU',
  },
  {
    value: 'EPI',
    label: 'EPI,  WisdomTree India Earnings Fund',
    name: 'WisdomTree India Earnings Fund',
    symbol: 'EPI',
  },
  {
    value: 'CWH',
    label: 'CWH,  Camping World Holdings, Inc.',
    name: 'Camping World Holdings, Inc.',
    symbol: 'CWH',
  },
  {
    value: 'DECK',
    label: 'DECK,  Deckers Outdoor Corporation',
    name: 'Deckers Outdoor Corporation',
    symbol: 'DECK',
  },
  {
    value: 'ACWI',
    label: 'ACWI,  iShares MSCI ACWI ETF',
    name: 'iShares MSCI ACWI ETF',
    symbol: 'ACWI',
  },
  {
    value: 'SMFG',
    label: 'SMFG,  Sumitomo Mitsui Financial Group, Inc.',
    name: 'Sumitomo Mitsui Financial Group, Inc.',
    symbol: 'SMFG',
  },
  {
    value: 'FULT',
    label: 'FULT,  Fulton Financial Corporation',
    name: 'Fulton Financial Corporation',
    symbol: 'FULT',
  },
  {
    value: 'UNIT',
    label: 'UNIT,  Uniti Group Inc.',
    name: 'Uniti Group Inc.',
    symbol: 'UNIT',
  },
  {
    value: 'LAZ',
    label: 'LAZ,  Lazard Ltd',
    name: 'Lazard Ltd',
    symbol: 'LAZ',
  },
  {
    value: 'CASY',
    label: "CASY,  Casey's General Stores, Inc.",
    name: "Casey's General Stores, Inc.",
    symbol: 'CASY',
  },
  {
    value: 'PODD',
    label: 'PODD,  Insulet Corporation',
    name: 'Insulet Corporation',
    symbol: 'PODD',
  },
  {
    value: 'NEXA',
    label: 'NEXA,  Nexa Resources S.A.',
    name: 'Nexa Resources S.A.',
    symbol: 'NEXA',
  },
  {
    value: 'UCO',
    label: 'UCO,  ProShares Ultra Bloomberg Crude Oil',
    name: 'ProShares Ultra Bloomberg Crude Oil',
    symbol: 'UCO',
  },
  {
    value: 'MATW',
    label: 'MATW,  Matthews International Corporation',
    name: 'Matthews International Corporation',
    symbol: 'MATW',
  },
  {
    value: 'PLNT',
    label: 'PLNT,  Planet Fitness, Inc.',
    name: 'Planet Fitness, Inc.',
    symbol: 'PLNT',
  },
  {
    value: 'CAH',
    label: 'CAH,  Cardinal Health, Inc.',
    name: 'Cardinal Health, Inc.',
    symbol: 'CAH',
  },
  {
    value: 'AYI',
    label: 'AYI,  Acuity Brands, Inc.',
    name: 'Acuity Brands, Inc.',
    symbol: 'AYI',
  },
  {
    value: 'DVA',
    label: 'DVA,  DaVita Inc.',
    name: 'DaVita Inc.',
    symbol: 'DVA',
  },
  {
    value: 'AMPE',
    label: 'AMPE,  Ampio Pharmaceuticals, Inc.',
    name: 'Ampio Pharmaceuticals, Inc.',
    symbol: 'AMPE',
  },
  {
    value: 'REGN',
    label: 'REGN,  Regeneron Pharmaceuticals, Inc.',
    name: 'Regeneron Pharmaceuticals, Inc.',
    symbol: 'REGN',
  },
  {
    value: 'MELI',
    label: 'MELI,  MercadoLibre, Inc.',
    name: 'MercadoLibre, Inc.',
    symbol: 'MELI',
  },
  {
    value: 'WCN',
    label: 'WCN,  Waste Connections, Inc.',
    name: 'Waste Connections, Inc.',
    symbol: 'WCN',
  },
  {
    value: 'IMMR',
    label: 'IMMR,  Immersion Corporation',
    name: 'Immersion Corporation',
    symbol: 'IMMR',
  },
  {
    value: 'VKTX',
    label: 'VKTX,  Viking Therapeutics, Inc.',
    name: 'Viking Therapeutics, Inc.',
    symbol: 'VKTX',
  },
  {
    value: 'MYOV',
    label: 'MYOV,  Myovant Sciences Ltd.',
    name: 'Myovant Sciences Ltd.',
    symbol: 'MYOV',
  },
  {
    value: 'ABM',
    label: 'ABM,  ABM Industries Incorporated',
    name: 'ABM Industries Incorporated',
    symbol: 'ABM',
  },
  {
    value: 'NGL',
    label: 'NGL,  NGL Energy Partners LP',
    name: 'NGL Energy Partners LP',
    symbol: 'NGL',
  },
  {
    value: 'ARCO',
    label: 'ARCO,  Arcos Dorados Holdings Inc.',
    name: 'Arcos Dorados Holdings Inc.',
    symbol: 'ARCO',
  },
  {
    value: 'HEWJ',
    label: 'HEWJ,  iShares Currency Hedged MSCI Japan ETF',
    name: 'iShares Currency Hedged MSCI Japan ETF',
    symbol: 'HEWJ',
  },
  {
    value: 'UNG',
    label: 'UNG,  United States Natural Gas Fund, LP',
    name: 'United States Natural Gas Fund, LP',
    symbol: 'UNG',
  },
  {
    value: 'MGPI',
    label: 'MGPI,  MGP Ingredients, Inc.',
    name: 'MGP Ingredients, Inc.',
    symbol: 'MGPI',
  },
  {
    value: 'NTES',
    label: 'NTES,  NetEase, Inc.',
    name: 'NetEase, Inc.',
    symbol: 'NTES',
  },
  {
    value: 'LTRPA',
    label: 'LTRPA,  Liberty TripAdvisor Holdings, Inc.',
    name: 'Liberty TripAdvisor Holdings, Inc.',
    symbol: 'LTRPA',
  },
  {
    value: 'LNC',
    label: 'LNC,  Lincoln National Corporation',
    name: 'Lincoln National Corporation',
    symbol: 'LNC',
  },
  {
    value: 'NGG',
    label: 'NGG,  National Grid plc',
    name: 'National Grid plc',
    symbol: 'NGG',
  },
  {
    value: 'TQQQ',
    label: 'TQQQ,  ProShares UltraPro QQQ',
    name: 'ProShares UltraPro QQQ',
    symbol: 'TQQQ',
  },
  {
    value: 'SND',
    label: 'SND,  Smart Sand, Inc.',
    name: 'Smart Sand, Inc.',
    symbol: 'SND',
  },
  {
    value: 'SCHF',
    label: 'SCHF,  Schwab International Equity ETF',
    name: 'Schwab International Equity ETF',
    symbol: 'SCHF',
  },
  {
    value: 'GTES',
    label: 'GTES,  Gates Industrial Corporation plc',
    name: 'Gates Industrial Corporation plc',
    symbol: 'GTES',
  },
  {
    value: 'CMA',
    label: 'CMA,  Comerica Incorporated',
    name: 'Comerica Incorporated',
    symbol: 'CMA',
  },
  {
    value: 'ALK',
    label: 'ALK,  Alaska Air Group, Inc.',
    name: 'Alaska Air Group, Inc.',
    symbol: 'ALK',
  },
  {
    value: 'PFG',
    label: 'PFG,  Principal Financial Group, Inc.',
    name: 'Principal Financial Group, Inc.',
    symbol: 'PFG',
  },
  {
    value: 'HUM',
    label: 'HUM,  Humana Inc.',
    name: 'Humana Inc.',
    symbol: 'HUM',
  },
  {
    value: 'TEX',
    label: 'TEX,  Terex Corporation',
    name: 'Terex Corporation',
    symbol: 'TEX',
  },
  {
    value: 'SAND',
    label: 'SAND,  Sandstorm Gold Ltd.',
    name: 'Sandstorm Gold Ltd.',
    symbol: 'SAND',
  },
  {
    value: 'JWN',
    label: 'JWN,  Nordstrom, Inc.',
    name: 'Nordstrom, Inc.',
    symbol: 'JWN',
  },
  {
    value: 'HDSN',
    label: 'HDSN,  Hudson Technologies, Inc.',
    name: 'Hudson Technologies, Inc.',
    symbol: 'HDSN',
  },
  {
    value: 'EDU',
    label: 'EDU,  New Oriental Education & Technology Group Inc.',
    name: 'New Oriental Education & Technology Group Inc.',
    symbol: 'EDU',
  },
  {
    value: 'ESRT',
    label: 'ESRT,  Empire State Realty Trust, Inc.',
    name: 'Empire State Realty Trust, Inc.',
    symbol: 'ESRT',
  },
  {
    value: 'SCHD',
    label: 'SCHD,  Schwab U.S. Dividend Equity ETF',
    name: 'Schwab U.S. Dividend Equity ETF',
    symbol: 'SCHD',
  },
  {
    value: 'LBRT',
    label: 'LBRT,  Liberty Energy Inc.',
    name: 'Liberty Energy Inc.',
    symbol: 'LBRT',
  },
  {
    value: 'PEB',
    label: 'PEB,  Pebblebrook Hotel Trust',
    name: 'Pebblebrook Hotel Trust',
    symbol: 'PEB',
  },
  {
    value: 'TSN',
    label: 'TSN,  Tyson Foods, Inc.',
    name: 'Tyson Foods, Inc.',
    symbol: 'TSN',
  },
  {
    value: 'HSBC',
    label: 'HSBC,  HSBC Holdings plc',
    name: 'HSBC Holdings plc',
    symbol: 'HSBC',
  },
  {
    value: 'OMI',
    label: 'OMI,  Owens & Minor, Inc.',
    name: 'Owens & Minor, Inc.',
    symbol: 'OMI',
  },
  {
    value: 'PTC',
    label: 'PTC,  PTC Inc.',
    name: 'PTC Inc.',
    symbol: 'PTC',
  },
  {
    value: 'CDW',
    label: 'CDW,  CDW Corporation',
    name: 'CDW Corporation',
    symbol: 'CDW',
  },
  {
    value: 'EWI',
    label: 'EWI,  iShares MSCI Italy ETF',
    name: 'iShares MSCI Italy ETF',
    symbol: 'EWI',
  },
  {
    value: 'SBGI',
    label: 'SBGI,  Sinclair Broadcast Group, Inc.',
    name: 'Sinclair Broadcast Group, Inc.',
    symbol: 'SBGI',
  },
  {
    value: 'RSP',
    label: 'RSP,  Invesco S&P 500 Equal Weight ETF',
    name: 'Invesco S&P 500 Equal Weight ETF',
    symbol: 'RSP',
  },
  {
    value: 'MMP',
    label: 'MMP,  Magellan Midstream Partners, L.P.',
    name: 'Magellan Midstream Partners, L.P.',
    symbol: 'MMP',
  },
  {
    value: 'PLAY',
    label: "PLAY,  Dave & Buster's Entertainment, Inc.",
    name: "Dave & Buster's Entertainment, Inc.",
    symbol: 'PLAY',
  },
  {
    value: 'HDB',
    label: 'HDB,  HDFC Bank Limited',
    name: 'HDFC Bank Limited',
    symbol: 'HDB',
  },
  {
    value: 'TSE',
    label: 'TSE,  Trinseo PLC',
    name: 'Trinseo PLC',
    symbol: 'TSE',
  },
  {
    value: 'WAL',
    label: 'WAL,  Western Alliance Bancorporation',
    name: 'Western Alliance Bancorporation',
    symbol: 'WAL',
  },
  {
    value: 'SBS',
    label:
      'SBS,  Companhia de Saneamento Básico do Estado de São Paulo - SABESP',
    name: 'Companhia de Saneamento Básico do Estado de São Paulo - SABESP',
    symbol: 'SBS',
  },
  {
    value: 'ARE',
    label: 'ARE,  Alexandria Real Estate Equities, Inc.',
    name: 'Alexandria Real Estate Equities, Inc.',
    symbol: 'ARE',
  },
  {
    value: 'EMLC',
    label: 'EMLC,  VanEck J.P. Morgan EM Local Currency Bond ETF',
    name: 'VanEck J.P. Morgan EM Local Currency Bond ETF',
    symbol: 'EMLC',
  },
  {
    value: 'EXAS',
    label: 'EXAS,  Exact Sciences Corporation',
    name: 'Exact Sciences Corporation',
    symbol: 'EXAS',
  },
  {
    value: 'TRGP',
    label: 'TRGP,  Targa Resources Corp.',
    name: 'Targa Resources Corp.',
    symbol: 'TRGP',
  },
  {
    value: 'LYV',
    label: 'LYV,  Live Nation Entertainment, Inc.',
    name: 'Live Nation Entertainment, Inc.',
    symbol: 'LYV',
  },
  {
    value: 'MFA',
    label: 'MFA,  MFA Financial, Inc.',
    name: 'MFA Financial, Inc.',
    symbol: 'MFA',
  },
  {
    value: 'ECL',
    label: 'ECL,  Ecolab Inc.',
    name: 'Ecolab Inc.',
    symbol: 'ECL',
  },
  {
    value: 'ARES',
    label: 'ARES,  Ares Management Corporation',
    name: 'Ares Management Corporation',
    symbol: 'ARES',
  },
  {
    value: 'DDD',
    label: 'DDD,  3D Systems Corporation',
    name: '3D Systems Corporation',
    symbol: 'DDD',
  },
  {
    value: 'MKC',
    label: 'MKC,  McCormick & Company, Incorporated',
    name: 'McCormick & Company, Incorporated',
    symbol: 'MKC',
  },
  {
    value: 'WAFD',
    label: 'WAFD,  Washington Federal, Inc.',
    name: 'Washington Federal, Inc.',
    symbol: 'WAFD',
  },
  {
    value: 'BSBR',
    label: 'BSBR,  Banco Santander (Brasil) S.A.',
    name: 'Banco Santander (Brasil) S.A.',
    symbol: 'BSBR',
  },
  {
    value: 'CQP',
    label: 'CQP,  Cheniere Energy Partners, L.P.',
    name: 'Cheniere Energy Partners, L.P.',
    symbol: 'CQP',
  },
  {
    value: 'PETQ',
    label: 'PETQ,  PetIQ, Inc.',
    name: 'PetIQ, Inc.',
    symbol: 'PETQ',
  },
  {
    value: 'RMD',
    label: 'RMD,  ResMed Inc.',
    name: 'ResMed Inc.',
    symbol: 'RMD',
  },
  {
    value: 'IRDM',
    label: 'IRDM,  Iridium Communications Inc.',
    name: 'Iridium Communications Inc.',
    symbol: 'IRDM',
  },
  {
    value: 'BKU',
    label: 'BKU,  BankUnited, Inc.',
    name: 'BankUnited, Inc.',
    symbol: 'BKU',
  },
  {
    value: 'H',
    label: 'H,  Hyatt Hotels Corporation',
    name: 'Hyatt Hotels Corporation',
    symbol: 'H',
  },
  {
    value: 'MDXG',
    label: 'MDXG,  MiMedx Group, Inc.',
    name: 'MiMedx Group, Inc.',
    symbol: 'MDXG',
  },
  {
    value: 'EXPR',
    label: 'EXPR,  Express, Inc.',
    name: 'Express, Inc.',
    symbol: 'EXPR',
  },
  {
    value: 'AVB',
    label: 'AVB,  AvalonBay Communities, Inc.',
    name: 'AvalonBay Communities, Inc.',
    symbol: 'AVB',
  },
  {
    value: 'INCY',
    label: 'INCY,  Incyte Corporation',
    name: 'Incyte Corporation',
    symbol: 'INCY',
  },
  {
    value: 'LBRDK',
    label: 'LBRDK,  Liberty Broadband Corporation',
    name: 'Liberty Broadband Corporation',
    symbol: 'LBRDK',
  },
  {
    value: 'NVCN',
    label: 'NVCN,  Neovasc Inc.',
    name: 'Neovasc Inc.',
    symbol: 'NVCN',
  },
  {
    value: 'TRMB',
    label: 'TRMB,  Trimble Inc.',
    name: 'Trimble Inc.',
    symbol: 'TRMB',
  },
  {
    value: 'CVEO',
    label: 'CVEO,  Civeo Corporation',
    name: 'Civeo Corporation',
    symbol: 'CVEO',
  },
  {
    value: 'TDOC',
    label: 'TDOC,  Teladoc Health, Inc.',
    name: 'Teladoc Health, Inc.',
    symbol: 'TDOC',
  },
  {
    value: 'TRU',
    label: 'TRU,  TransUnion',
    name: 'TransUnion',
    symbol: 'TRU',
  },
  {
    value: 'XYL',
    label: 'XYL,  Xylem Inc.',
    name: 'Xylem Inc.',
    symbol: 'XYL',
  },
  {
    value: 'MOAT',
    label: 'MOAT,  VanEck Morningstar Wide Moat ETF',
    name: 'VanEck Morningstar Wide Moat ETF',
    symbol: 'MOAT',
  },
  {
    value: 'ACGL',
    label: 'ACGL,  Arch Capital Group Ltd.',
    name: 'Arch Capital Group Ltd.',
    symbol: 'ACGL',
  },
  {
    value: 'SBNY',
    label: 'SBNY,  Signature Bank',
    name: 'Signature Bank',
    symbol: 'SBNY',
  },
  {
    value: 'GRFS',
    label: 'GRFS,  Grifols, S.A.',
    name: 'Grifols, S.A.',
    symbol: 'GRFS',
  },
  {
    value: 'DY',
    label: 'DY,  Dycom Industries, Inc.',
    name: 'Dycom Industries, Inc.',
    symbol: 'DY',
  },
  {
    value: 'SONY',
    label: 'SONY,  Sony Group Corporation',
    name: 'Sony Group Corporation',
    symbol: 'SONY',
  },
  {
    value: 'MAA',
    label: 'MAA,  Mid-America Apartment Communities, Inc.',
    name: 'Mid-America Apartment Communities, Inc.',
    symbol: 'MAA',
  },
  {
    value: 'AEL',
    label: 'AEL,  American Equity Investment Life Holding Company',
    name: 'American Equity Investment Life Holding Company',
    symbol: 'AEL',
  },
  {
    value: 'GOGO',
    label: 'GOGO,  Gogo Inc.',
    name: 'Gogo Inc.',
    symbol: 'GOGO',
  },
  {
    value: 'DBC',
    label: 'DBC,  Invesco DB Commodity Index Tracking Fund',
    name: 'Invesco DB Commodity Index Tracking Fund',
    symbol: 'DBC',
  },
  {
    value: 'WES',
    label: 'WES,  Western Midstream Partners, LP',
    name: 'Western Midstream Partners, LP',
    symbol: 'WES',
  },
  {
    value: 'QUAD',
    label: 'QUAD,  Quad/Graphics, Inc.',
    name: 'Quad/Graphics, Inc.',
    symbol: 'QUAD',
  },
  {
    value: 'RYN',
    label: 'RYN,  Rayonier Inc.',
    name: 'Rayonier Inc.',
    symbol: 'RYN',
  },
  {
    value: 'SYNA',
    label: 'SYNA,  Synaptics Incorporated',
    name: 'Synaptics Incorporated',
    symbol: 'SYNA',
  },
  {
    value: 'VSTO',
    label: 'VSTO,  Vista Outdoor Inc.',
    name: 'Vista Outdoor Inc.',
    symbol: 'VSTO',
  },
  {
    value: 'PPG',
    label: 'PPG,  PPG Industries, Inc.',
    name: 'PPG Industries, Inc.',
    symbol: 'PPG',
  },
  {
    value: 'ORI',
    label: 'ORI,  Old Republic International Corporation',
    name: 'Old Republic International Corporation',
    symbol: 'ORI',
  },
  {
    value: 'TPC',
    label: 'TPC,  Tutor Perini Corporation',
    name: 'Tutor Perini Corporation',
    symbol: 'TPC',
  },
  {
    value: 'XHR',
    label: 'XHR,  Xenia Hotels & Resorts, Inc.',
    name: 'Xenia Hotels & Resorts, Inc.',
    symbol: 'XHR',
  },
  {
    value: 'MC',
    label: 'MC,  Moelis & Company',
    name: 'Moelis & Company',
    symbol: 'MC',
  },
  {
    value: 'DDS',
    label: "DDS,  Dillard's, Inc.",
    name: "Dillard's, Inc.",
    symbol: 'DDS',
  },
  {
    value: 'TMST',
    label: 'TMST,  TimkenSteel Corporation',
    name: 'TimkenSteel Corporation',
    symbol: 'TMST',
  },
  {
    value: 'FR',
    label: 'FR,  First Industrial Realty Trust, Inc.',
    name: 'First Industrial Realty Trust, Inc.',
    symbol: 'FR',
  },
  {
    value: 'NMIH',
    label: 'NMIH,  NMI Holdings, Inc.',
    name: 'NMI Holdings, Inc.',
    symbol: 'NMIH',
  },
  {
    value: 'MTCH',
    label: 'MTCH,  Match Group, Inc.',
    name: 'Match Group, Inc.',
    symbol: 'MTCH',
  },
  {
    value: 'TRHC',
    label: 'TRHC,  Tabula Rasa HealthCare, Inc.',
    name: 'Tabula Rasa HealthCare, Inc.',
    symbol: 'TRHC',
  },
  {
    value: 'XNET',
    label: 'XNET,  Xunlei Limited',
    name: 'Xunlei Limited',
    symbol: 'XNET',
  },
  {
    value: 'CIG',
    label: 'CIG,  Companhia Energética de Minas Gerais',
    name: 'Companhia Energética de Minas Gerais',
    symbol: 'CIG',
  },
  {
    value: 'HAIN',
    label: 'HAIN,  The Hain Celestial Group, Inc.',
    name: 'The Hain Celestial Group, Inc.',
    symbol: 'HAIN',
  },
  {
    value: 'GSY',
    label: 'GSY,  Invesco Ultra Short Duration ETF',
    name: 'Invesco Ultra Short Duration ETF',
    symbol: 'GSY',
  },
  {
    value: 'CUK',
    label: 'CUK,  Carnival Corporation & plc',
    name: 'Carnival Corporation & plc',
    symbol: 'CUK',
  },
  {
    value: 'MSI',
    label: 'MSI,  Motorola Solutions, Inc.',
    name: 'Motorola Solutions, Inc.',
    symbol: 'MSI',
  },
  {
    value: 'PDCE',
    label: 'PDCE,  PDC Energy, Inc.',
    name: 'PDC Energy, Inc.',
    symbol: 'PDCE',
  },
  {
    value: 'FNV',
    label: 'FNV,  Franco-Nevada Corporation',
    name: 'Franco-Nevada Corporation',
    symbol: 'FNV',
  },
  {
    value: 'LPSN',
    label: 'LPSN,  LivePerson, Inc.',
    name: 'LivePerson, Inc.',
    symbol: 'LPSN',
  },
  {
    value: 'TELL',
    label: 'TELL,  Tellurian Inc.',
    name: 'Tellurian Inc.',
    symbol: 'TELL',
  },
  {
    value: 'KMX',
    label: 'KMX,  CarMax, Inc.',
    name: 'CarMax, Inc.',
    symbol: 'KMX',
  },
  {
    value: 'ESPR',
    label: 'ESPR,  Esperion Therapeutics, Inc.',
    name: 'Esperion Therapeutics, Inc.',
    symbol: 'ESPR',
  },
  {
    value: 'BRX',
    label: 'BRX,  Brixmor Property Group Inc.',
    name: 'Brixmor Property Group Inc.',
    symbol: 'BRX',
  },
  {
    value: 'LH',
    label: 'LH,  Laboratory Corporation of America Holdings',
    name: 'Laboratory Corporation of America Holdings',
    symbol: 'LH',
  },
  {
    value: 'PLCE',
    label: "PLCE,  The Children's Place, Inc.",
    name: "The Children's Place, Inc.",
    symbol: 'PLCE',
  },
  {
    value: 'NBHC',
    label: 'NBHC,  National Bank Holdings Corporation',
    name: 'National Bank Holdings Corporation',
    symbol: 'NBHC',
  },
  {
    value: 'FND',
    label: 'FND,  Floor & Decor Holdings, Inc.',
    name: 'Floor & Decor Holdings, Inc.',
    symbol: 'FND',
  },
  {
    value: 'MOH',
    label: 'MOH,  Molina Healthcare, Inc.',
    name: 'Molina Healthcare, Inc.',
    symbol: 'MOH',
  },
  {
    value: 'FUL',
    label: 'FUL,  H.B. Fuller Company',
    name: 'H.B. Fuller Company',
    symbol: 'FUL',
  },
  {
    value: 'MRC',
    label: 'MRC,  MRC Global Inc.',
    name: 'MRC Global Inc.',
    symbol: 'MRC',
  },
  {
    value: 'EXR',
    label: 'EXR,  Extra Space Storage Inc.',
    name: 'Extra Space Storage Inc.',
    symbol: 'EXR',
  },
  {
    value: 'SCHL',
    label: 'SCHL,  Scholastic Corporation',
    name: 'Scholastic Corporation',
    symbol: 'SCHL',
  },
  {
    value: 'CPG',
    label: 'CPG,  Crescent Point Energy Corp.',
    name: 'Crescent Point Energy Corp.',
    symbol: 'CPG',
  },
  {
    value: 'ADTN',
    label: 'ADTN,  ADTRAN Holdings, Inc.',
    name: 'ADTRAN Holdings, Inc.',
    symbol: 'ADTN',
  },
  {
    value: 'WHR',
    label: 'WHR,  Whirlpool Corporation',
    name: 'Whirlpool Corporation',
    symbol: 'WHR',
  },
  {
    value: 'HSY',
    label: 'HSY,  The Hershey Company',
    name: 'The Hershey Company',
    symbol: 'HSY',
  },
  {
    value: 'HTGM',
    label: 'HTGM,  HTG Molecular Diagnostics, Inc.',
    name: 'HTG Molecular Diagnostics, Inc.',
    symbol: 'HTGM',
  },
  {
    value: 'SEAS',
    label: 'SEAS,  SeaWorld Entertainment, Inc.',
    name: 'SeaWorld Entertainment, Inc.',
    symbol: 'SEAS',
  },
  {
    value: 'BECN',
    label: 'BECN,  Beacon Roofing Supply, Inc.',
    name: 'Beacon Roofing Supply, Inc.',
    symbol: 'BECN',
  },
  {
    value: 'BPOP',
    label: 'BPOP,  Popular, Inc.',
    name: 'Popular, Inc.',
    symbol: 'BPOP',
  },
  {
    value: 'IEV',
    label: 'IEV,  iShares Europe ETF',
    name: 'iShares Europe ETF',
    symbol: 'IEV',
  },
  {
    value: 'PGRE',
    label: 'PGRE,  Paramount Group, Inc.',
    name: 'Paramount Group, Inc.',
    symbol: 'PGRE',
  },
  {
    value: 'COO',
    label: 'COO,  The Cooper Companies, Inc.',
    name: 'The Cooper Companies, Inc.',
    symbol: 'COO',
  },
  {
    value: 'JT',
    label: 'JT,  Jianpu Technology Inc.',
    name: 'Jianpu Technology Inc.',
    symbol: 'JT',
  },
  {
    value: 'COMM',
    label: 'COMM,  CommScope Holding Company, Inc.',
    name: 'CommScope Holding Company, Inc.',
    symbol: 'COMM',
  },
  {
    value: 'ABEO',
    label: 'ABEO,  Abeona Therapeutics Inc.',
    name: 'Abeona Therapeutics Inc.',
    symbol: 'ABEO',
  },
  {
    value: 'CPT',
    label: 'CPT,  Camden Property Trust',
    name: 'Camden Property Trust',
    symbol: 'CPT',
  },
  {
    value: 'SHOP',
    label: 'SHOP,  Shopify Inc.',
    name: 'Shopify Inc.',
    symbol: 'SHOP',
  },
  {
    value: 'RETA',
    label: 'RETA,  Reata Pharmaceuticals, Inc.',
    name: 'Reata Pharmaceuticals, Inc.',
    symbol: 'RETA',
  },
  {
    value: 'MWA',
    label: 'MWA,  Mueller Water Products, Inc.',
    name: 'Mueller Water Products, Inc.',
    symbol: 'MWA',
  },
  {
    value: 'BLUE',
    label: 'BLUE,  bluebird bio, Inc.',
    name: 'bluebird bio, Inc.',
    symbol: 'BLUE',
  },
  {
    value: 'EIDO',
    label: 'EIDO,  iShares MSCI Indonesia ETF',
    name: 'iShares MSCI Indonesia ETF',
    symbol: 'EIDO',
  },
  {
    value: 'SOXS',
    label: 'SOXS,  Direxion Daily Semiconductor Bear 3X Shares',
    name: 'Direxion Daily Semiconductor Bear 3X Shares',
    symbol: 'SOXS',
  },
  {
    value: 'WPRT',
    label: 'WPRT,  Westport Fuel Systems Inc.',
    name: 'Westport Fuel Systems Inc.',
    symbol: 'WPRT',
  },
  {
    value: 'CORT',
    label: 'CORT,  Corcept Therapeutics Incorporated',
    name: 'Corcept Therapeutics Incorporated',
    symbol: 'CORT',
  },
  {
    value: 'BOOT',
    label: 'BOOT,  Boot Barn Holdings, Inc.',
    name: 'Boot Barn Holdings, Inc.',
    symbol: 'BOOT',
  },
  {
    value: 'ALEX',
    label: 'ALEX,  Alexander & Baldwin, Inc.',
    name: 'Alexander & Baldwin, Inc.',
    symbol: 'ALEX',
  },
  {
    value: 'VTIP',
    label:
      'VTIP,  Vanguard Short-Term Inflation-Protected Securities Index Fund',
    name: 'Vanguard Short-Term Inflation-Protected Securities Index Fund',
    symbol: 'VTIP',
  },
  {
    value: 'TKR',
    label: 'TKR,  The Timken Company',
    name: 'The Timken Company',
    symbol: 'TKR',
  },
  {
    value: 'RL',
    label: 'RL,  Ralph Lauren Corporation',
    name: 'Ralph Lauren Corporation',
    symbol: 'RL',
  },
  {
    value: 'DBA',
    label: 'DBA,  Invesco DB Agriculture Fund',
    name: 'Invesco DB Agriculture Fund',
    symbol: 'DBA',
  },
  {
    value: 'XXII',
    label: 'XXII,  22nd Century Group, Inc.',
    name: '22nd Century Group, Inc.',
    symbol: 'XXII',
  },
  {
    value: 'AXGN',
    label: 'AXGN,  AxoGen, Inc.',
    name: 'AxoGen, Inc.',
    symbol: 'AXGN',
  },
  {
    value: 'MASI',
    label: 'MASI,  Masimo Corporation',
    name: 'Masimo Corporation',
    symbol: 'MASI',
  },
  {
    value: 'OKTA',
    label: 'OKTA,  Okta, Inc.',
    name: 'Okta, Inc.',
    symbol: 'OKTA',
  },
  {
    value: 'TD',
    label: 'TD,  The Toronto-Dominion Bank',
    name: 'The Toronto-Dominion Bank',
    symbol: 'TD',
  },
  {
    value: 'BRO',
    label: 'BRO,  Brown & Brown, Inc.',
    name: 'Brown & Brown, Inc.',
    symbol: 'BRO',
  },
  {
    value: 'ALSN',
    label: 'ALSN,  Allison Transmission Holdings, Inc.',
    name: 'Allison Transmission Holdings, Inc.',
    symbol: 'ALSN',
  },
  {
    value: 'FAF',
    label: 'FAF,  First American Financial Corporation',
    name: 'First American Financial Corporation',
    symbol: 'FAF',
  },
  {
    value: 'BRKR',
    label: 'BRKR,  Bruker Corporation',
    name: 'Bruker Corporation',
    symbol: 'BRKR',
  },
  {
    value: 'G',
    label: 'G,  Genpact Limited',
    name: 'Genpact Limited',
    symbol: 'G',
  },
  {
    value: 'PANW',
    label: 'PANW,  Palo Alto Networks, Inc.',
    name: 'Palo Alto Networks, Inc.',
    symbol: 'PANW',
  },
  {
    value: 'GPC',
    label: 'GPC,  Genuine Parts Company',
    name: 'Genuine Parts Company',
    symbol: 'GPC',
  },
  {
    value: 'ELP',
    label: 'ELP,  Companhia Paranaense de Energia - COPEL',
    name: 'Companhia Paranaense de Energia - COPEL',
    symbol: 'ELP',
  },
  {
    value: 'KMPR',
    label: 'KMPR,  Kemper Corporation',
    name: 'Kemper Corporation',
    symbol: 'KMPR',
  },
  {
    value: 'BGS',
    label: 'BGS,  B&G Foods, Inc.',
    name: 'B&G Foods, Inc.',
    symbol: 'BGS',
  },
  {
    value: 'NBIX',
    label: 'NBIX,  Neurocrine Biosciences, Inc.',
    name: 'Neurocrine Biosciences, Inc.',
    symbol: 'NBIX',
  },
  {
    value: 'CHKP',
    label: 'CHKP,  Check Point Software Technologies Ltd.',
    name: 'Check Point Software Technologies Ltd.',
    symbol: 'CHKP',
  },
  {
    value: 'JAZZ',
    label: 'JAZZ,  Jazz Pharmaceuticals plc',
    name: 'Jazz Pharmaceuticals plc',
    symbol: 'JAZZ',
  },
  {
    value: 'CMCO',
    label: 'CMCO,  Columbus McKinnon Corporation',
    name: 'Columbus McKinnon Corporation',
    symbol: 'CMCO',
  },
  {
    value: 'CRS',
    label: 'CRS,  Carpenter Technology Corporation',
    name: 'Carpenter Technology Corporation',
    symbol: 'CRS',
  },
  {
    value: 'AMC',
    label: 'AMC,  AMC Entertainment Holdings, Inc.',
    name: 'AMC Entertainment Holdings, Inc.',
    symbol: 'AMC',
  },
  {
    value: 'BVN',
    label: 'BVN,  Compañía de Minas Buenaventura S.A.A.',
    name: 'Compañía de Minas Buenaventura S.A.A.',
    symbol: 'BVN',
  },
  {
    value: 'EXK',
    label: 'EXK,  Endeavour Silver Corp.',
    name: 'Endeavour Silver Corp.',
    symbol: 'EXK',
  },
  {
    value: 'BLK',
    label: 'BLK,  BlackRock, Inc.',
    name: 'BlackRock, Inc.',
    symbol: 'BLK',
  },
  {
    value: 'IMO',
    label: 'IMO,  Imperial Oil Limited',
    name: 'Imperial Oil Limited',
    symbol: 'IMO',
  },
  {
    value: 'UUP',
    label: 'UUP,  Invesco DB US Dollar Index Bullish Fund',
    name: 'Invesco DB US Dollar Index Bullish Fund',
    symbol: 'UUP',
  },
  {
    value: 'POR',
    label: 'POR,  Portland General Electric Company',
    name: 'Portland General Electric Company',
    symbol: 'POR',
  },
  {
    value: 'GCI',
    label: 'GCI,  Gannett Co., Inc.',
    name: 'Gannett Co., Inc.',
    symbol: 'GCI',
  },
  {
    value: 'RWX',
    label: 'RWX,  SPDR Dow Jones International Real Estate ETF',
    name: 'SPDR Dow Jones International Real Estate ETF',
    symbol: 'RWX',
  },
  {
    value: 'DYN',
    label: 'DYN,  Dyne Therapeutics, Inc.',
    name: 'Dyne Therapeutics, Inc.',
    symbol: 'DYN',
  },
  {
    value: 'ORLY',
    label: "ORLY,  O'Reilly Automotive, Inc.",
    name: "O'Reilly Automotive, Inc.",
    symbol: 'ORLY',
  },
  {
    value: 'HDV',
    label: 'HDV,  iShares Core High Dividend ETF',
    name: 'iShares Core High Dividend ETF',
    symbol: 'HDV',
  },
  {
    value: 'FFIV',
    label: 'FFIV,  F5, Inc.',
    name: 'F5, Inc.',
    symbol: 'FFIV',
  },
  {
    value: 'PHB',
    label: 'PHB,  Invesco Fundamental High Yield Corporate Bond ETF',
    name: 'Invesco Fundamental High Yield Corporate Bond ETF',
    symbol: 'PHB',
  },
  {
    value: 'HOG',
    label: 'HOG,  Harley-Davidson, Inc.',
    name: 'Harley-Davidson, Inc.',
    symbol: 'HOG',
  },
  {
    value: 'STWD',
    label: 'STWD,  Starwood Property Trust, Inc.',
    name: 'Starwood Property Trust, Inc.',
    symbol: 'STWD',
  },
  {
    value: 'PRTA',
    label: 'PRTA,  Prothena Corporation plc',
    name: 'Prothena Corporation plc',
    symbol: 'PRTA',
  },
  {
    value: 'UNVR',
    label: 'UNVR,  Univar Solutions Inc.',
    name: 'Univar Solutions Inc.',
    symbol: 'UNVR',
  },
  {
    value: 'TUP',
    label: 'TUP,  Tupperware Brands Corporation',
    name: 'Tupperware Brands Corporation',
    symbol: 'TUP',
  },
  {
    value: 'FSM',
    label: 'FSM,  Fortuna Silver Mines Inc.',
    name: 'Fortuna Silver Mines Inc.',
    symbol: 'FSM',
  },
  {
    value: 'XIN',
    label: 'XIN,  Xinyuan Real Estate Co., Ltd.',
    name: 'Xinyuan Real Estate Co., Ltd.',
    symbol: 'XIN',
  },
  {
    value: 'SCWX',
    label: 'SCWX,  SecureWorks Corp.',
    name: 'SecureWorks Corp.',
    symbol: 'SCWX',
  },
  {
    value: 'PAAS',
    label: 'PAAS,  Pan American Silver Corp.',
    name: 'Pan American Silver Corp.',
    symbol: 'PAAS',
  },
  {
    value: 'NUGT',
    label: 'NUGT,  Direxion Daily Gold Miners Index Bull 2X Shares',
    name: 'Direxion Daily Gold Miners Index Bull 2X Shares',
    symbol: 'NUGT',
  },
  {
    value: 'ADNT',
    label: 'ADNT,  Adient plc',
    name: 'Adient plc',
    symbol: 'ADNT',
  },
  {
    value: 'TRUP',
    label: 'TRUP,  Trupanion, Inc.',
    name: 'Trupanion, Inc.',
    symbol: 'TRUP',
  },
  {
    value: 'HAE',
    label: 'HAE,  Haemonetics Corporation',
    name: 'Haemonetics Corporation',
    symbol: 'HAE',
  },
  {
    value: 'PCY',
    label: 'PCY,  Invesco Emerging Markets Sovereign Debt ETF',
    name: 'Invesco Emerging Markets Sovereign Debt ETF',
    symbol: 'PCY',
  },
  {
    value: 'HOPE',
    label: 'HOPE,  Hope Bancorp, Inc.',
    name: 'Hope Bancorp, Inc.',
    symbol: 'HOPE',
  },
  {
    value: 'MRTN',
    label: 'MRTN,  Marten Transport, Ltd.',
    name: 'Marten Transport, Ltd.',
    symbol: 'MRTN',
  },
  {
    value: 'DLX',
    label: 'DLX,  Deluxe Corporation',
    name: 'Deluxe Corporation',
    symbol: 'DLX',
  },
  {
    value: 'UUUU',
    label: 'UUUU,  Energy Fuels Inc.',
    name: 'Energy Fuels Inc.',
    symbol: 'UUUU',
  },
  {
    value: 'GEO',
    label: 'GEO,  The GEO Group, Inc.',
    name: 'The GEO Group, Inc.',
    symbol: 'GEO',
  },
  {
    value: 'AAP',
    label: 'AAP,  Advance Auto Parts, Inc.',
    name: 'Advance Auto Parts, Inc.',
    symbol: 'AAP',
  },
  {
    value: 'AON',
    label: 'AON,  Aon plc',
    name: 'Aon plc',
    symbol: 'AON',
  },
  {
    value: 'TGTX',
    label: 'TGTX,  TG Therapeutics, Inc.',
    name: 'TG Therapeutics, Inc.',
    symbol: 'TGTX',
  },
  {
    value: 'SKM',
    label: 'SKM,  SK Telecom Co.,Ltd',
    name: 'SK Telecom Co.,Ltd',
    symbol: 'SKM',
  },
  {
    value: 'MAN',
    label: 'MAN,  ManpowerGroup Inc.',
    name: 'ManpowerGroup Inc.',
    symbol: 'MAN',
  },
  {
    value: 'SDY',
    label: 'SDY,  SPDR S&P Dividend ETF',
    name: 'SPDR S&P Dividend ETF',
    symbol: 'SDY',
  },
  {
    value: 'CRUS',
    label: 'CRUS,  Cirrus Logic, Inc.',
    name: 'Cirrus Logic, Inc.',
    symbol: 'CRUS',
  },
  {
    value: 'TXRH',
    label: 'TXRH,  Texas Roadhouse, Inc.',
    name: 'Texas Roadhouse, Inc.',
    symbol: 'TXRH',
  },
  {
    value: 'AAXJ',
    label: 'AAXJ,  iShares MSCI All Country Asia ex Japan ETF',
    name: 'iShares MSCI All Country Asia ex Japan ETF',
    symbol: 'AAXJ',
  },
  {
    value: 'TNET',
    label: 'TNET,  TriNet Group, Inc.',
    name: 'TriNet Group, Inc.',
    symbol: 'TNET',
  },
  {
    value: 'LZB',
    label: 'LZB,  La-Z-Boy Incorporated',
    name: 'La-Z-Boy Incorporated',
    symbol: 'LZB',
  },
  {
    value: 'EEFT',
    label: 'EEFT,  Euronet Worldwide, Inc.',
    name: 'Euronet Worldwide, Inc.',
    symbol: 'EEFT',
  },
  {
    value: 'CVGI',
    label: 'CVGI,  Commercial Vehicle Group, Inc.',
    name: 'Commercial Vehicle Group, Inc.',
    symbol: 'CVGI',
  },
  {
    value: 'TRI',
    label: 'TRI,  Thomson Reuters Corporation',
    name: 'Thomson Reuters Corporation',
    symbol: 'TRI',
  },
  {
    value: 'JQC',
    label: 'JQC,  Nuveen Credit Strategies Income Fund',
    name: 'Nuveen Credit Strategies Income Fund',
    symbol: 'JQC',
  },
  {
    value: 'GMS',
    label: 'GMS,  GMS Inc.',
    name: 'GMS Inc.',
    symbol: 'GMS',
  },
  {
    value: 'PBI',
    label: 'PBI,  Pitney Bowes Inc.',
    name: 'Pitney Bowes Inc.',
    symbol: 'PBI',
  },
  {
    value: 'RJF',
    label: 'RJF,  Raymond James Financial, Inc.',
    name: 'Raymond James Financial, Inc.',
    symbol: 'RJF',
  },
  {
    value: 'WWD',
    label: 'WWD,  Woodward, Inc.',
    name: 'Woodward, Inc.',
    symbol: 'WWD',
  },
  {
    value: 'SNY',
    label: 'SNY,  Sanofi',
    name: 'Sanofi',
    symbol: 'SNY',
  },
  {
    value: 'IWB',
    label: 'IWB,  iShares Russell 1000 ETF',
    name: 'iShares Russell 1000 ETF',
    symbol: 'IWB',
  },
  {
    value: 'TTC',
    label: 'TTC,  The Toro Company',
    name: 'The Toro Company',
    symbol: 'TTC',
  },
  {
    value: 'BAM',
    label: 'BAM,  Brookfield Asset Management Inc.',
    name: 'Brookfield Asset Management Inc.',
    symbol: 'BAM',
  },
  {
    value: 'SHW',
    label: 'SHW,  The Sherwin-Williams Company',
    name: 'The Sherwin-Williams Company',
    symbol: 'SHW',
  },
  {
    value: 'AMP',
    label: 'AMP,  Ameriprise Financial, Inc.',
    name: 'Ameriprise Financial, Inc.',
    symbol: 'AMP',
  },
  {
    value: 'WSO',
    label: 'WSO,  Watsco, Inc.',
    name: 'Watsco, Inc.',
    symbol: 'WSO',
  },
  {
    value: 'ATO',
    label: 'ATO,  Atmos Energy Corporation',
    name: 'Atmos Energy Corporation',
    symbol: 'ATO',
  },
  {
    value: 'QID',
    label: 'QID,  ProShares UltraShort QQQ',
    name: 'ProShares UltraShort QQQ',
    symbol: 'QID',
  },
  {
    value: 'HOLI',
    label: 'HOLI,  Hollysys Automation Technologies Ltd.',
    name: 'Hollysys Automation Technologies Ltd.',
    symbol: 'HOLI',
  },
  {
    value: 'CBAY',
    label: 'CBAY,  CymaBay Therapeutics, Inc.',
    name: 'CymaBay Therapeutics, Inc.',
    symbol: 'CBAY',
  },
  {
    value: 'STAG',
    label: 'STAG,  STAG Industrial, Inc.',
    name: 'STAG Industrial, Inc.',
    symbol: 'STAG',
  },
  {
    value: 'WAB',
    label: 'WAB,  Westinghouse Air Brake Technologies Corporation',
    name: 'Westinghouse Air Brake Technologies Corporation',
    symbol: 'WAB',
  },
  {
    value: 'AVEO',
    label: 'AVEO,  AVEO Pharmaceuticals, Inc.',
    name: 'AVEO Pharmaceuticals, Inc.',
    symbol: 'AVEO',
  },
  {
    value: 'PFGC',
    label: 'PFGC,  Performance Food Group Company',
    name: 'Performance Food Group Company',
    symbol: 'PFGC',
  },
  {
    value: 'MUSA',
    label: 'MUSA,  Murphy USA Inc.',
    name: 'Murphy USA Inc.',
    symbol: 'MUSA',
  },
  {
    value: 'RCM',
    label: 'RCM,  R1 RCM Inc.',
    name: 'R1 RCM Inc.',
    symbol: 'RCM',
  },
  {
    value: 'DK',
    label: 'DK,  Delek US Holdings, Inc.',
    name: 'Delek US Holdings, Inc.',
    symbol: 'DK',
  },
  {
    value: 'CM',
    label: 'CM,  Canadian Imperial Bank of Commerce',
    name: 'Canadian Imperial Bank of Commerce',
    symbol: 'CM',
  },
  {
    value: 'SWX',
    label: 'SWX,  Southwest Gas Holdings, Inc.',
    name: 'Southwest Gas Holdings, Inc.',
    symbol: 'SWX',
  },
  {
    value: 'SSRM',
    label: 'SSRM,  SSR Mining Inc.',
    name: 'SSR Mining Inc.',
    symbol: 'SSRM',
  },
  {
    value: 'ACWF',
    label: 'ACWF,  iShares MSCI Global Multifactor ETF',
    name: 'iShares MSCI Global Multifactor ETF',
    symbol: 'ACWF',
  },
  {
    value: 'MTW',
    label: 'MTW,  The Manitowoc Company, Inc.',
    name: 'The Manitowoc Company, Inc.',
    symbol: 'MTW',
  },
  {
    value: 'IRS',
    label: 'IRS,  IRSA Inversiones y Representaciones Sociedad Anónima',
    name: 'IRSA Inversiones y Representaciones Sociedad Anónima',
    symbol: 'IRS',
  },
  {
    value: 'PSQ',
    label: 'PSQ,  ProShares Short QQQ',
    name: 'ProShares Short QQQ',
    symbol: 'PSQ',
  },
  {
    value: 'TSEM',
    label: 'TSEM,  Tower Semiconductor Ltd.',
    name: 'Tower Semiconductor Ltd.',
    symbol: 'TSEM',
  },
  {
    value: 'ILF',
    label: 'ILF,  iShares Latin America 40 ETF',
    name: 'iShares Latin America 40 ETF',
    symbol: 'ILF',
  },
  {
    value: 'DNLI',
    label: 'DNLI,  Denali Therapeutics Inc.',
    name: 'Denali Therapeutics Inc.',
    symbol: 'DNLI',
  },
  {
    value: 'CHRW',
    label: 'CHRW,  C.H. Robinson Worldwide, Inc.',
    name: 'C.H. Robinson Worldwide, Inc.',
    symbol: 'CHRW',
  },
  {
    value: 'SPPI',
    label: 'SPPI,  Spectrum Pharmaceuticals, Inc.',
    name: 'Spectrum Pharmaceuticals, Inc.',
    symbol: 'SPPI',
  },
  {
    value: 'TTD',
    label: 'TTD,  The Trade Desk, Inc.',
    name: 'The Trade Desk, Inc.',
    symbol: 'TTD',
  },
  {
    value: 'ASML',
    label: 'ASML,  ASML Holding N.V.',
    name: 'ASML Holding N.V.',
    symbol: 'ASML',
  },
  {
    value: 'IDXX',
    label: 'IDXX,  IDEXX Laboratories, Inc.',
    name: 'IDEXX Laboratories, Inc.',
    symbol: 'IDXX',
  },
  {
    value: 'ONCS',
    label: 'ONCS,  OncoSec Medical Incorporated',
    name: 'OncoSec Medical Incorporated',
    symbol: 'ONCS',
  },
  {
    value: 'SRPT',
    label: 'SRPT,  Sarepta Therapeutics, Inc.',
    name: 'Sarepta Therapeutics, Inc.',
    symbol: 'SRPT',
  },
  {
    value: 'SGEN',
    label: 'SGEN,  Seagen Inc.',
    name: 'Seagen Inc.',
    symbol: 'SGEN',
  },
  {
    value: 'CNK',
    label: 'CNK,  Cinemark Holdings, Inc.',
    name: 'Cinemark Holdings, Inc.',
    symbol: 'CNK',
  },
  {
    value: 'TCBI',
    label: 'TCBI,  Texas Capital Bancshares, Inc.',
    name: 'Texas Capital Bancshares, Inc.',
    symbol: 'TCBI',
  },
  {
    value: 'OCN',
    label: 'OCN,  Ocwen Financial Corporation',
    name: 'Ocwen Financial Corporation',
    symbol: 'OCN',
  },
  {
    value: 'MDB',
    label: 'MDB,  MongoDB, Inc.',
    name: 'MongoDB, Inc.',
    symbol: 'MDB',
  },
  {
    value: 'DXPE',
    label: 'DXPE,  DXP Enterprises, Inc.',
    name: 'DXP Enterprises, Inc.',
    symbol: 'DXPE',
  },
  {
    value: 'NWBI',
    label: 'NWBI,  Northwest Bancshares, Inc.',
    name: 'Northwest Bancshares, Inc.',
    symbol: 'NWBI',
  },
  {
    value: 'ENS',
    label: 'ENS,  EnerSys',
    name: 'EnerSys',
    symbol: 'ENS',
  },
  {
    value: 'TFX',
    label: 'TFX,  Teleflex Incorporated',
    name: 'Teleflex Incorporated',
    symbol: 'TFX',
  },
  {
    value: 'EQC',
    label: 'EQC,  Equity Commonwealth',
    name: 'Equity Commonwealth',
    symbol: 'EQC',
  },
  {
    value: 'DEI',
    label: 'DEI,  Douglas Emmett, Inc.',
    name: 'Douglas Emmett, Inc.',
    symbol: 'DEI',
  },
  {
    value: 'MTN',
    label: 'MTN,  Vail Resorts, Inc.',
    name: 'Vail Resorts, Inc.',
    symbol: 'MTN',
  },
  {
    value: 'SPR',
    label: 'SPR,  Spirit AeroSystems Holdings, Inc.',
    name: 'Spirit AeroSystems Holdings, Inc.',
    symbol: 'SPR',
  },
  {
    value: 'PSEC',
    label: 'PSEC,  Prospect Capital Corporation',
    name: 'Prospect Capital Corporation',
    symbol: 'PSEC',
  },
  {
    value: 'LEE',
    label: 'LEE,  Lee Enterprises, Incorporated',
    name: 'Lee Enterprises, Incorporated',
    symbol: 'LEE',
  },
  {
    value: 'QDEL',
    label: 'QDEL,  QuidelOrtho Corporation',
    name: 'QuidelOrtho Corporation',
    symbol: 'QDEL',
  },
  {
    value: 'WETF',
    label: 'WETF,  WisdomTree, Inc.',
    name: 'WisdomTree, Inc.',
    symbol: 'WETF',
  },
  {
    value: 'HBM',
    label: 'HBM,  Hudbay Minerals Inc.',
    name: 'Hudbay Minerals Inc.',
    symbol: 'HBM',
  },
  {
    value: 'INVA',
    label: 'INVA,  Innoviva, Inc.',
    name: 'Innoviva, Inc.',
    symbol: 'INVA',
  },
  {
    value: 'BJRI',
    label: "BJRI,  BJ's Restaurants, Inc.",
    name: "BJ's Restaurants, Inc.",
    symbol: 'BJRI',
  },
  {
    value: 'EVRI',
    label: 'EVRI,  Everi Holdings Inc.',
    name: 'Everi Holdings Inc.',
    symbol: 'EVRI',
  },
  {
    value: 'LCI',
    label: 'LCI,  Lannett Company, Inc.',
    name: 'Lannett Company, Inc.',
    symbol: 'LCI',
  },
  {
    value: 'PII',
    label: 'PII,  Polaris Inc.',
    name: 'Polaris Inc.',
    symbol: 'PII',
  },
  {
    value: 'SCO',
    label: 'SCO,  ProShares UltraShort Bloomberg Crude Oil',
    name: 'ProShares UltraShort Bloomberg Crude Oil',
    symbol: 'SCO',
  },
  {
    value: 'AHH',
    label: 'AHH,  Armada Hoffler Properties, Inc.',
    name: 'Armada Hoffler Properties, Inc.',
    symbol: 'AHH',
  },
  {
    value: 'ZS',
    label: 'ZS,  Zscaler, Inc.',
    name: 'Zscaler, Inc.',
    symbol: 'ZS',
  },
  {
    value: 'MIDD',
    label: 'MIDD,  The Middleby Corporation',
    name: 'The Middleby Corporation',
    symbol: 'MIDD',
  },
  {
    value: 'SF',
    label: 'SF,  Stifel Financial Corp.',
    name: 'Stifel Financial Corp.',
    symbol: 'SF',
  },
  {
    value: 'RCII',
    label: 'RCII,  Rent-A-Center, Inc.',
    name: 'Rent-A-Center, Inc.',
    symbol: 'RCII',
  },
  {
    value: 'DCP',
    label: 'DCP,  DCP Midstream, LP',
    name: 'DCP Midstream, LP',
    symbol: 'DCP',
  },
  {
    value: 'WBS',
    label: 'WBS,  Webster Financial Corporation',
    name: 'Webster Financial Corporation',
    symbol: 'WBS',
  },
  {
    value: 'CRON',
    label: 'CRON,  Cronos Group Inc.',
    name: 'Cronos Group Inc.',
    symbol: 'CRON',
  },
  {
    value: 'WST',
    label: 'WST,  West Pharmaceutical Services, Inc.',
    name: 'West Pharmaceutical Services, Inc.',
    symbol: 'WST',
  },
  {
    value: 'SCI',
    label: 'SCI,  Service Corporation International',
    name: 'Service Corporation International',
    symbol: 'SCI',
  },
  {
    value: 'BERY',
    label: 'BERY,  Berry Global Group, Inc.',
    name: 'Berry Global Group, Inc.',
    symbol: 'BERY',
  },
  {
    value: 'SPNT',
    label: 'SPNT,  SiriusPoint Ltd.',
    name: 'SiriusPoint Ltd.',
    symbol: 'SPNT',
  },
  {
    value: 'CCK',
    label: 'CCK,  Crown Holdings, Inc.',
    name: 'Crown Holdings, Inc.',
    symbol: 'CCK',
  },
  {
    value: 'MAG',
    label: 'MAG,  MAG Silver Corp.',
    name: 'MAG Silver Corp.',
    symbol: 'MAG',
  },
  {
    value: 'WNC',
    label: 'WNC,  Wabash National Corporation',
    name: 'Wabash National Corporation',
    symbol: 'WNC',
  },
  {
    value: 'CSGP',
    label: 'CSGP,  CoStar Group, Inc.',
    name: 'CoStar Group, Inc.',
    symbol: 'CSGP',
  },
  {
    value: 'ETR',
    label: 'ETR,  Entergy Corporation',
    name: 'Entergy Corporation',
    symbol: 'ETR',
  },
  {
    value: 'VLUE',
    label: 'VLUE,  iShares MSCI USA Value Factor ETF',
    name: 'iShares MSCI USA Value Factor ETF',
    symbol: 'VLUE',
  },
  {
    value: 'TTMI',
    label: 'TTMI,  TTM Technologies, Inc.',
    name: 'TTM Technologies, Inc.',
    symbol: 'TTMI',
  },
  {
    value: 'FLS',
    label: 'FLS,  Flowserve Corporation',
    name: 'Flowserve Corporation',
    symbol: 'FLS',
  },
  {
    value: 'ARWR',
    label: 'ARWR,  Arrowhead Pharmaceuticals, Inc.',
    name: 'Arrowhead Pharmaceuticals, Inc.',
    symbol: 'ARWR',
  },
  {
    value: 'IAC',
    label: 'IAC,  IAC Inc.',
    name: 'IAC Inc.',
    symbol: 'IAC',
  },
  {
    value: 'VIRT',
    label: 'VIRT,  Virtu Financial, Inc.',
    name: 'Virtu Financial, Inc.',
    symbol: 'VIRT',
  },
  {
    value: 'UCTT',
    label: 'UCTT,  Ultra Clean Holdings, Inc.',
    name: 'Ultra Clean Holdings, Inc.',
    symbol: 'UCTT',
  },
  {
    value: 'VUG',
    label: 'VUG,  Vanguard Growth Index Fund',
    name: 'Vanguard Growth Index Fund',
    symbol: 'VUG',
  },
  {
    value: 'ANGI',
    label: 'ANGI,  Angi Inc.',
    name: 'Angi Inc.',
    symbol: 'ANGI',
  },
  {
    value: 'BCOR',
    label: 'BCOR,  Blucora, Inc.',
    name: 'Blucora, Inc.',
    symbol: 'BCOR',
  },
  {
    value: 'CRH',
    label: 'CRH,  CRH plc',
    name: 'CRH plc',
    symbol: 'CRH',
  },
  {
    value: 'GHL',
    label: 'GHL,  Greenhill & Co., Inc.',
    name: 'Greenhill & Co., Inc.',
    symbol: 'GHL',
  },
  {
    value: 'IPI',
    label: 'IPI,  Intrepid Potash, Inc.',
    name: 'Intrepid Potash, Inc.',
    symbol: 'IPI',
  },
  {
    value: 'HIW',
    label: 'HIW,  Highwoods Properties, Inc.',
    name: 'Highwoods Properties, Inc.',
    symbol: 'HIW',
  },
  {
    value: 'BLDR',
    label: 'BLDR,  Builders FirstSource, Inc.',
    name: 'Builders FirstSource, Inc.',
    symbol: 'BLDR',
  },
  {
    value: 'CPS',
    label: 'CPS,  Cooper-Standard Holdings Inc.',
    name: 'Cooper-Standard Holdings Inc.',
    symbol: 'CPS',
  },
  {
    value: 'CHEF',
    label: "CHEF,  The Chefs' Warehouse, Inc.",
    name: "The Chefs' Warehouse, Inc.",
    symbol: 'CHEF',
  },
  {
    value: 'APH',
    label: 'APH,  Amphenol Corporation',
    name: 'Amphenol Corporation',
    symbol: 'APH',
  },
  {
    value: 'REXR',
    label: 'REXR,  Rexford Industrial Realty, Inc.',
    name: 'Rexford Industrial Realty, Inc.',
    symbol: 'REXR',
  },
  {
    value: 'KURA',
    label: 'KURA,  Kura Oncology, Inc.',
    name: 'Kura Oncology, Inc.',
    symbol: 'KURA',
  },
  {
    value: 'GTHX',
    label: 'GTHX,  G1 Therapeutics, Inc.',
    name: 'G1 Therapeutics, Inc.',
    symbol: 'GTHX',
  },
  {
    value: 'TK',
    label: 'TK,  Teekay Corporation',
    name: 'Teekay Corporation',
    symbol: 'TK',
  },
  {
    value: 'MRNS',
    label: 'MRNS,  Marinus Pharmaceuticals, Inc.',
    name: 'Marinus Pharmaceuticals, Inc.',
    symbol: 'MRNS',
  },
  {
    value: 'GPRE',
    label: 'GPRE,  Green Plains Inc.',
    name: 'Green Plains Inc.',
    symbol: 'GPRE',
  },
  {
    value: 'RS',
    label: 'RS,  Reliance Steel & Aluminum Co.',
    name: 'Reliance Steel & Aluminum Co.',
    symbol: 'RS',
  },
  {
    value: 'HIBB',
    label: 'HIBB,  Hibbett, Inc.',
    name: 'Hibbett, Inc.',
    symbol: 'HIBB',
  },
  {
    value: 'ALGN',
    label: 'ALGN,  Align Technology, Inc.',
    name: 'Align Technology, Inc.',
    symbol: 'ALGN',
  },
  {
    value: 'HEI',
    label: 'HEI,  HEICO Corporation',
    name: 'HEICO Corporation',
    symbol: 'HEI',
  },
  {
    value: 'CFR',
    label: 'CFR,  Cullen/Frost Bankers, Inc.',
    name: 'Cullen/Frost Bankers, Inc.',
    symbol: 'CFR',
  },
  {
    value: 'YPF',
    label: 'YPF,  YPF Sociedad Anónima',
    name: 'YPF Sociedad Anónima',
    symbol: 'YPF',
  },
  {
    value: 'LGIH',
    label: 'LGIH,  LGI Homes, Inc.',
    name: 'LGI Homes, Inc.',
    symbol: 'LGIH',
  },
  {
    value: 'CMG',
    label: 'CMG,  Chipotle Mexican Grill, Inc.',
    name: 'Chipotle Mexican Grill, Inc.',
    symbol: 'CMG',
  },
  {
    value: 'REG',
    label: 'REG,  Regency Centers Corporation',
    name: 'Regency Centers Corporation',
    symbol: 'REG',
  },
  {
    value: 'AVXL',
    label: 'AVXL,  Anavex Life Sciences Corp.',
    name: 'Anavex Life Sciences Corp.',
    symbol: 'AVXL',
  },
  {
    value: 'AXDX',
    label: 'AXDX,  Accelerate Diagnostics, Inc.',
    name: 'Accelerate Diagnostics, Inc.',
    symbol: 'AXDX',
  },
  {
    value: 'RPM',
    label: 'RPM,  RPM International Inc.',
    name: 'RPM International Inc.',
    symbol: 'RPM',
  },
  {
    value: 'BNS',
    label: 'BNS,  The Bank of Nova Scotia',
    name: 'The Bank of Nova Scotia',
    symbol: 'BNS',
  },
  {
    value: 'CTLP',
    label: 'CTLP,  Cantaloupe, Inc.',
    name: 'Cantaloupe, Inc.',
    symbol: 'CTLP',
  },
  {
    value: 'BWXT',
    label: 'BWXT,  BWX Technologies, Inc.',
    name: 'BWX Technologies, Inc.',
    symbol: 'BWXT',
  },
  {
    value: 'WOW',
    label: 'WOW,  WideOpenWest, Inc.',
    name: 'WideOpenWest, Inc.',
    symbol: 'WOW',
  },
  {
    value: 'PRMW',
    label: 'PRMW,  Primo Water Corporation',
    name: 'Primo Water Corporation',
    symbol: 'PRMW',
  },
  {
    value: 'WOR',
    label: 'WOR,  Worthington Industries, Inc.',
    name: 'Worthington Industries, Inc.',
    symbol: 'WOR',
  },
  {
    value: 'NUVA',
    label: 'NUVA,  NuVasive, Inc.',
    name: 'NuVasive, Inc.',
    symbol: 'NUVA',
  },
  {
    value: 'TIP',
    label: 'TIP,  iShares TIPS Bond ETF',
    name: 'iShares TIPS Bond ETF',
    symbol: 'TIP',
  },
  {
    value: 'ADVM',
    label: 'ADVM,  Adverum Biotechnologies, Inc.',
    name: 'Adverum Biotechnologies, Inc.',
    symbol: 'ADVM',
  },
  {
    value: 'SWIR',
    label: 'SWIR,  Sierra Wireless, Inc.',
    name: 'Sierra Wireless, Inc.',
    symbol: 'SWIR',
  },
  {
    value: 'MBB',
    label: 'MBB,  iShares MBS ETF',
    name: 'iShares MBS ETF',
    symbol: 'MBB',
  },
  {
    value: 'HUBS',
    label: 'HUBS,  HubSpot, Inc.',
    name: 'HubSpot, Inc.',
    symbol: 'HUBS',
  },
  {
    value: 'FRPT',
    label: 'FRPT,  Freshpet, Inc.',
    name: 'Freshpet, Inc.',
    symbol: 'FRPT',
  },
  {
    value: 'ARAY',
    label: 'ARAY,  Accuray Incorporated',
    name: 'Accuray Incorporated',
    symbol: 'ARAY',
  },
  {
    value: 'QUOT',
    label: 'QUOT,  Quotient Technology Inc.',
    name: 'Quotient Technology Inc.',
    symbol: 'QUOT',
  },
  {
    value: 'COLD',
    label: 'COLD,  Americold Realty Trust, Inc.',
    name: 'Americold Realty Trust, Inc.',
    symbol: 'COLD',
  },
  {
    value: 'NS',
    label: 'NS,  NuStar Energy L.P.',
    name: 'NuStar Energy L.P.',
    symbol: 'NS',
  },
  {
    value: 'YANG',
    label: 'YANG,  Direxion Daily FTSE China Bear 3X Shares',
    name: 'Direxion Daily FTSE China Bear 3X Shares',
    symbol: 'YANG',
  },
  {
    value: 'FAS',
    label: 'FAS,  Direxion Daily Financial Bull 3X Shares',
    name: 'Direxion Daily Financial Bull 3X Shares',
    symbol: 'FAS',
  },
  {
    value: 'FMX',
    label: 'FMX,  Fomento Económico Mexicano, S.A.B. de C.V.',
    name: 'Fomento Económico Mexicano, S.A.B. de C.V.',
    symbol: 'FMX',
  },
  {
    value: 'HTLD',
    label: 'HTLD,  Heartland Express, Inc.',
    name: 'Heartland Express, Inc.',
    symbol: 'HTLD',
  },
  {
    value: 'VRNS',
    label: 'VRNS,  Varonis Systems, Inc.',
    name: 'Varonis Systems, Inc.',
    symbol: 'VRNS',
  },
  {
    value: 'IVE',
    label: 'IVE,  iShares S&P 500 Value ETF',
    name: 'iShares S&P 500 Value ETF',
    symbol: 'IVE',
  },
  {
    value: 'AYTU',
    label: 'AYTU,  Aytu BioPharma, Inc.',
    name: 'Aytu BioPharma, Inc.',
    symbol: 'AYTU',
  },
  {
    value: 'PKG',
    label: 'PKG,  Packaging Corporation of America',
    name: 'Packaging Corporation of America',
    symbol: 'PKG',
  },
  {
    value: 'CINF',
    label: 'CINF,  Cincinnati Financial Corporation',
    name: 'Cincinnati Financial Corporation',
    symbol: 'CINF',
  },
  {
    value: 'ANY',
    label: 'ANY,  Sphere 3D Corp.',
    name: 'Sphere 3D Corp.',
    symbol: 'ANY',
  },
  {
    value: 'DENN',
    label: "DENN,  Denny's Corporation",
    name: "Denny's Corporation",
    symbol: 'DENN',
  },
  {
    value: 'CLX',
    label: 'CLX,  The Clorox Company',
    name: 'The Clorox Company',
    symbol: 'CLX',
  },
  {
    value: 'VGR',
    label: 'VGR,  Vector Group Ltd.',
    name: 'Vector Group Ltd.',
    symbol: 'VGR',
  },
  {
    value: 'HII',
    label: 'HII,  Huntington Ingalls Industries, Inc.',
    name: 'Huntington Ingalls Industries, Inc.',
    symbol: 'HII',
  },
  {
    value: 'GLRE',
    label: 'GLRE,  Greenlight Capital Re, Ltd.',
    name: 'Greenlight Capital Re, Ltd.',
    symbol: 'GLRE',
  },
  {
    value: 'PRGO',
    label: 'PRGO,  Perrigo Company plc',
    name: 'Perrigo Company plc',
    symbol: 'PRGO',
  },
  {
    value: 'OIL',
    label: 'OIL,  iPath Pure Beta Crude Oil ETN',
    name: 'iPath Pure Beta Crude Oil ETN',
    symbol: 'OIL',
  },
  {
    value: 'RRR',
    label: 'RRR,  Red Rock Resorts, Inc.',
    name: 'Red Rock Resorts, Inc.',
    symbol: 'RRR',
  },
  {
    value: 'MSA',
    label: 'MSA,  MSA Safety Incorporated',
    name: 'MSA Safety Incorporated',
    symbol: 'MSA',
  },
  {
    value: 'SQNS',
    label: 'SQNS,  Sequans Communications S.A.',
    name: 'Sequans Communications S.A.',
    symbol: 'SQNS',
  },
  {
    value: 'ZG',
    label: 'ZG,  Zillow Group, Inc.',
    name: 'Zillow Group, Inc.',
    symbol: 'ZG',
  },
  {
    value: 'ACM',
    label: 'ACM,  AECOM',
    name: 'AECOM',
    symbol: 'ACM',
  },
  {
    value: 'HLI',
    label: 'HLI,  Houlihan Lokey, Inc.',
    name: 'Houlihan Lokey, Inc.',
    symbol: 'HLI',
  },
  {
    value: 'CMP',
    label: 'CMP,  Compass Minerals International, Inc.',
    name: 'Compass Minerals International, Inc.',
    symbol: 'CMP',
  },
  {
    value: 'GGAL',
    label: 'GGAL,  Grupo Financiero Galicia S.A.',
    name: 'Grupo Financiero Galicia S.A.',
    symbol: 'GGAL',
  },
  {
    value: 'FTNT',
    label: 'FTNT,  Fortinet, Inc.',
    name: 'Fortinet, Inc.',
    symbol: 'FTNT',
  },
  {
    value: 'CLDX',
    label: 'CLDX,  Celldex Therapeutics, Inc.',
    name: 'Celldex Therapeutics, Inc.',
    symbol: 'CLDX',
  },
  {
    value: 'VXUS',
    label: 'VXUS,  Vanguard Total Intl Stock Idx Fund',
    name: 'Vanguard Total Intl Stock Idx Fund',
    symbol: 'VXUS',
  },
  {
    value: 'SPB',
    label: 'SPB,  Spectrum Brands Holdings, Inc.',
    name: 'Spectrum Brands Holdings, Inc.',
    symbol: 'SPB',
  },
  {
    value: 'IFF',
    label: 'IFF,  International Flavors & Fragrances Inc.',
    name: 'International Flavors & Fragrances Inc.',
    symbol: 'IFF',
  },
  {
    value: 'VCEL',
    label: 'VCEL,  Vericel Corporation',
    name: 'Vericel Corporation',
    symbol: 'VCEL',
  },
  {
    value: 'SRCL',
    label: 'SRCL,  Stericycle, Inc.',
    name: 'Stericycle, Inc.',
    symbol: 'SRCL',
  },
  {
    value: 'RING',
    label: 'RING,  iShares MSCI Global Gold Miners ETF',
    name: 'iShares MSCI Global Gold Miners ETF',
    symbol: 'RING',
  },
  {
    value: 'RBBN',
    label: 'RBBN,  Ribbon Communications Inc.',
    name: 'Ribbon Communications Inc.',
    symbol: 'RBBN',
  },
  {
    value: 'DBO',
    label: 'DBO,  Invesco DB Oil Fund',
    name: 'Invesco DB Oil Fund',
    symbol: 'DBO',
  },
  {
    value: 'RYI',
    label: 'RYI,  Ryerson Holding Corporation',
    name: 'Ryerson Holding Corporation',
    symbol: 'RYI',
  },
  {
    value: 'MAC',
    label: 'MAC,  The Macerich Company',
    name: 'The Macerich Company',
    symbol: 'MAC',
  },
  {
    value: 'SENS',
    label: 'SENS,  Senseonics Holdings, Inc.',
    name: 'Senseonics Holdings, Inc.',
    symbol: 'SENS',
  },
  {
    value: 'IQV',
    label: 'IQV,  IQVIA Holdings Inc.',
    name: 'IQVIA Holdings Inc.',
    symbol: 'IQV',
  },
  {
    value: 'WNS',
    label: 'WNS,  WNS (Holdings) Limited',
    name: 'WNS (Holdings) Limited',
    symbol: 'WNS',
  },
  {
    value: 'WTFC',
    label: 'WTFC,  Wintrust Financial Corporation',
    name: 'Wintrust Financial Corporation',
    symbol: 'WTFC',
  },
  {
    value: 'SGRY',
    label: 'SGRY,  Surgery Partners, Inc.',
    name: 'Surgery Partners, Inc.',
    symbol: 'SGRY',
  },
  {
    value: 'VIV',
    label: 'VIV,  Telefônica Brasil S.A.',
    name: 'Telefônica Brasil S.A.',
    symbol: 'VIV',
  },
  {
    value: 'XPER',
    label: 'XPER,  Xperi Inc.',
    name: 'Xperi Inc.',
    symbol: 'XPER',
  },
  {
    value: 'FCF',
    label: 'FCF,  First Commonwealth Financial Corporation',
    name: 'First Commonwealth Financial Corporation',
    symbol: 'FCF',
  },
  {
    value: 'ERX',
    label: 'ERX,  Direxion Daily Energy Bull 2X Shares',
    name: 'Direxion Daily Energy Bull 2X Shares',
    symbol: 'ERX',
  },
  {
    value: 'VYM',
    label: 'VYM,  Vanguard High Dividend Yield Index Fund',
    name: 'Vanguard High Dividend Yield Index Fund',
    symbol: 'VYM',
  },
  {
    value: 'AGEN',
    label: 'AGEN,  Agenus Inc.',
    name: 'Agenus Inc.',
    symbol: 'AGEN',
  },
  {
    value: 'IVR',
    label: 'IVR,  Invesco Mortgage Capital Inc.',
    name: 'Invesco Mortgage Capital Inc.',
    symbol: 'IVR',
  },
  {
    value: 'RPD',
    label: 'RPD,  Rapid7, Inc.',
    name: 'Rapid7, Inc.',
    symbol: 'RPD',
  },
  {
    value: 'GLNG',
    label: 'GLNG,  Golar LNG Limited',
    name: 'Golar LNG Limited',
    symbol: 'GLNG',
  },
  {
    value: 'PETS',
    label: 'PETS,  PetMed Express, Inc.',
    name: 'PetMed Express, Inc.',
    symbol: 'PETS',
  },
  {
    value: 'TTI',
    label: 'TTI,  TETRA Technologies, Inc.',
    name: 'TETRA Technologies, Inc.',
    symbol: 'TTI',
  },
  {
    value: 'THS',
    label: 'THS,  TreeHouse Foods, Inc.',
    name: 'TreeHouse Foods, Inc.',
    symbol: 'THS',
  },
  {
    value: 'AMBA',
    label: 'AMBA,  Ambarella, Inc.',
    name: 'Ambarella, Inc.',
    symbol: 'AMBA',
  },
  {
    value: 'AMKR',
    label: 'AMKR,  Amkor Technology, Inc.',
    name: 'Amkor Technology, Inc.',
    symbol: 'AMKR',
  },
  {
    value: 'TBI',
    label: 'TBI,  TrueBlue, Inc.',
    name: 'TrueBlue, Inc.',
    symbol: 'TBI',
  },
  {
    value: 'COUP',
    label: 'COUP,  Coupa Software Incorporated',
    name: 'Coupa Software Incorporated',
    symbol: 'COUP',
  },
  {
    value: 'MNKD',
    label: 'MNKD,  MannKind Corporation',
    name: 'MannKind Corporation',
    symbol: 'MNKD',
  },
  {
    value: 'ICPT',
    label: 'ICPT,  Intercept Pharmaceuticals, Inc.',
    name: 'Intercept Pharmaceuticals, Inc.',
    symbol: 'ICPT',
  },
  {
    value: 'MITK',
    label: 'MITK,  Mitek Systems, Inc.',
    name: 'Mitek Systems, Inc.',
    symbol: 'MITK',
  },
  {
    value: 'BHE',
    label: 'BHE,  Benchmark Electronics, Inc.',
    name: 'Benchmark Electronics, Inc.',
    symbol: 'BHE',
  },
  {
    value: 'PZZA',
    label: "PZZA,  Papa John's International, Inc.",
    name: "Papa John's International, Inc.",
    symbol: 'PZZA',
  },
  {
    value: 'BGI',
    label: 'BGI,  Birks Group Inc.',
    name: 'Birks Group Inc.',
    symbol: 'BGI',
  },
  {
    value: 'BCE',
    label: 'BCE,  BCE Inc.',
    name: 'BCE Inc.',
    symbol: 'BCE',
  },
  {
    value: 'GOVT',
    label: 'GOVT,  iShares U.S. Treasury Bond ETF',
    name: 'iShares U.S. Treasury Bond ETF',
    symbol: 'GOVT',
  },
  {
    value: 'POOL',
    label: 'POOL,  Pool Corporation',
    name: 'Pool Corporation',
    symbol: 'POOL',
  },
  {
    value: 'MGRC',
    label: 'MGRC,  McGrath RentCorp',
    name: 'McGrath RentCorp',
    symbol: 'MGRC',
  },
  {
    value: 'FANH',
    label: 'FANH,  Fanhua Inc.',
    name: 'Fanhua Inc.',
    symbol: 'FANH',
  },
  {
    value: 'INFN',
    label: 'INFN,  Infinera Corporation',
    name: 'Infinera Corporation',
    symbol: 'INFN',
  },
  {
    value: 'MMSI',
    label: 'MMSI,  Merit Medical Systems, Inc.',
    name: 'Merit Medical Systems, Inc.',
    symbol: 'MMSI',
  },
  {
    value: 'SDOW',
    label: 'SDOW,  ProShares UltraPro Short Dow30',
    name: 'ProShares UltraPro Short Dow30',
    symbol: 'SDOW',
  },
  {
    value: 'PTN',
    label: 'PTN,  Palatin Technologies, Inc.',
    name: 'Palatin Technologies, Inc.',
    symbol: 'PTN',
  },
  {
    value: 'ENIC',
    label: 'ENIC,  Enel Chile S.A.',
    name: 'Enel Chile S.A.',
    symbol: 'ENIC',
  },
  {
    value: 'SPXC',
    label: 'SPXC,  SPX Technologies, Inc.',
    name: 'SPX Technologies, Inc.',
    symbol: 'SPXC',
  },
  {
    value: 'HEWG',
    label: 'HEWG,  iShares Currency Hedged MSCI Germany ETF',
    name: 'iShares Currency Hedged MSCI Germany ETF',
    symbol: 'HEWG',
  },
  {
    value: 'ARCH',
    label: 'ARCH,  Arch Resources, Inc.',
    name: 'Arch Resources, Inc.',
    symbol: 'ARCH',
  },
  {
    value: 'AM',
    label: 'AM,  Antero Midstream Corporation',
    name: 'Antero Midstream Corporation',
    symbol: 'AM',
  },
  {
    value: 'TGI',
    label: 'TGI,  Triumph Group, Inc.',
    name: 'Triumph Group, Inc.',
    symbol: 'TGI',
  },
  {
    value: 'AWK',
    label: 'AWK,  American Water Works Company, Inc.',
    name: 'American Water Works Company, Inc.',
    symbol: 'AWK',
  },
  {
    value: 'R',
    label: 'R,  Ryder System, Inc.',
    name: 'Ryder System, Inc.',
    symbol: 'R',
  },
  {
    value: 'EXG',
    label:
      'EXG,  Eaton Vance Tax-Managed Global Diversified Equity Income Fund',
    name: 'Eaton Vance Tax-Managed Global Diversified Equity Income Fund',
    symbol: 'EXG',
  },
  {
    value: 'BKH',
    label: 'BKH,  Black Hills Corporation',
    name: 'Black Hills Corporation',
    symbol: 'BKH',
  },
  {
    value: 'AVD',
    label: 'AVD,  American Vanguard Corporation',
    name: 'American Vanguard Corporation',
    symbol: 'AVD',
  },
  {
    value: 'CMCM',
    label: 'CMCM,  Cheetah Mobile Inc.',
    name: 'Cheetah Mobile Inc.',
    symbol: 'CMCM',
  },
  {
    value: 'UPLD',
    label: 'UPLD,  Upland Software, Inc.',
    name: 'Upland Software, Inc.',
    symbol: 'UPLD',
  },
  {
    value: 'HYS',
    label:
      'HYS,  PIMCO 0-5 Year High Yield Corporate Bond Index Exchange-Traded Fund',
    name: 'PIMCO 0-5 Year High Yield Corporate Bond Index Exchange-Traded Fund',
    symbol: 'HYS',
  },
  {
    value: 'GPN',
    label: 'GPN,  Global Payments Inc.',
    name: 'Global Payments Inc.',
    symbol: 'GPN',
  },
  {
    value: 'COLM',
    label: 'COLM,  Columbia Sportswear Company',
    name: 'Columbia Sportswear Company',
    symbol: 'COLM',
  },
  {
    value: 'HE',
    label: 'HE,  Hawaiian Electric Industries, Inc.',
    name: 'Hawaiian Electric Industries, Inc.',
    symbol: 'HE',
  },
  {
    value: 'SJI',
    label: 'SJI,  South Jersey Industries, Inc.',
    name: 'South Jersey Industries, Inc.',
    symbol: 'SJI',
  },
  {
    value: 'CCOI',
    label: 'CCOI,  Cogent Communications Holdings, Inc.',
    name: 'Cogent Communications Holdings, Inc.',
    symbol: 'CCOI',
  },
  {
    value: 'SEDG',
    label: 'SEDG,  SolarEdge Technologies, Inc.',
    name: 'SolarEdge Technologies, Inc.',
    symbol: 'SEDG',
  },
  {
    value: 'PBYI',
    label: 'PBYI,  Puma Biotechnology, Inc.',
    name: 'Puma Biotechnology, Inc.',
    symbol: 'PBYI',
  },
  {
    value: 'IRWD',
    label: 'IRWD,  Ironwood Pharmaceuticals, Inc.',
    name: 'Ironwood Pharmaceuticals, Inc.',
    symbol: 'IRWD',
  },
  {
    value: 'CSGS',
    label: 'CSGS,  CSG Systems International, Inc.',
    name: 'CSG Systems International, Inc.',
    symbol: 'CSGS',
  },
  {
    value: 'CNSL',
    label: 'CNSL,  Consolidated Communications Holdings, Inc.',
    name: 'Consolidated Communications Holdings, Inc.',
    symbol: 'CNSL',
  },
  {
    value: 'SUPN',
    label: 'SUPN,  Supernus Pharmaceuticals, Inc.',
    name: 'Supernus Pharmaceuticals, Inc.',
    symbol: 'SUPN',
  },
  {
    value: 'UGI',
    label: 'UGI,  UGI Corporation',
    name: 'UGI Corporation',
    symbol: 'UGI',
  },
  {
    value: 'COLL',
    label: 'COLL,  Collegium Pharmaceutical, Inc.',
    name: 'Collegium Pharmaceutical, Inc.',
    symbol: 'COLL',
  },
  {
    value: 'BKCC',
    label: 'BKCC,  BlackRock Capital Investment Corporation',
    name: 'BlackRock Capital Investment Corporation',
    symbol: 'BKCC',
  },
  {
    value: 'CBD',
    label: 'CBD,  Companhia Brasileira de Distribuição',
    name: 'Companhia Brasileira de Distribuição',
    symbol: 'CBD',
  },
  {
    value: 'SMG',
    label: 'SMG,  The Scotts Miracle-Gro Company',
    name: 'The Scotts Miracle-Gro Company',
    symbol: 'SMG',
  },
  {
    value: 'PACB',
    label: 'PACB,  Pacific Biosciences of California, Inc.',
    name: 'Pacific Biosciences of California, Inc.',
    symbol: 'PACB',
  },
  {
    value: 'AVDL',
    label: 'AVDL,  Avadel Pharmaceuticals plc',
    name: 'Avadel Pharmaceuticals plc',
    symbol: 'AVDL',
  },
  {
    value: 'PGX',
    label: 'PGX,  Invesco Preferred ETF',
    name: 'Invesco Preferred ETF',
    symbol: 'PGX',
  },
  {
    value: 'JNCE',
    label: 'JNCE,  Jounce Therapeutics, Inc.',
    name: 'Jounce Therapeutics, Inc.',
    symbol: 'JNCE',
  },
  {
    value: 'SLVO',
    label: 'SLVO,  Credit Suisse X-Links Silver Shares Covered Call ETN',
    name: 'Credit Suisse X-Links Silver Shares Covered Call ETN',
    symbol: 'SLVO',
  },
  {
    value: 'WAT',
    label: 'WAT,  Waters Corporation',
    name: 'Waters Corporation',
    symbol: 'WAT',
  },
  {
    value: 'MUB',
    label: 'MUB,  iShares National Muni Bond ETF',
    name: 'iShares National Muni Bond ETF',
    symbol: 'MUB',
  },
  {
    value: 'MKSI',
    label: 'MKSI,  MKS Instruments, Inc.',
    name: 'MKS Instruments, Inc.',
    symbol: 'MKSI',
  },
  {
    value: 'CWT',
    label: 'CWT,  California Water Service Group',
    name: 'California Water Service Group',
    symbol: 'CWT',
  },
  {
    value: 'NEAR',
    label: 'NEAR,  iShares Short Maturity Bond ETF',
    name: 'iShares Short Maturity Bond ETF',
    symbol: 'NEAR',
  },
  {
    value: 'MTB',
    label: 'MTB,  M&T Bank Corporation',
    name: 'M&T Bank Corporation',
    symbol: 'MTB',
  },
  {
    value: 'CRL',
    label: 'CRL,  Charles River Laboratories International, Inc.',
    name: 'Charles River Laboratories International, Inc.',
    symbol: 'CRL',
  },
  {
    value: 'FDS',
    label: 'FDS,  FactSet Research Systems Inc.',
    name: 'FactSet Research Systems Inc.',
    symbol: 'FDS',
  },
  {
    value: 'JLL',
    label: 'JLL,  Jones Lang LaSalle Incorporated',
    name: 'Jones Lang LaSalle Incorporated',
    symbol: 'JLL',
  },
  {
    value: 'HSC',
    label: 'HSC,  Harsco Corporation',
    name: 'Harsco Corporation',
    symbol: 'HSC',
  },
  {
    value: 'LNTH',
    label: 'LNTH,  Lantheus Holdings, Inc.',
    name: 'Lantheus Holdings, Inc.',
    symbol: 'LNTH',
  },
  {
    value: 'OEF',
    label: 'OEF,  iShares S&P 100 ETF',
    name: 'iShares S&P 100 ETF',
    symbol: 'OEF',
  },
  {
    value: 'PNM',
    label: 'PNM,  PNM Resources, Inc.',
    name: 'PNM Resources, Inc.',
    symbol: 'PNM',
  },
  {
    value: 'PACW',
    label: 'PACW,  PacWest Bancorp',
    name: 'PacWest Bancorp',
    symbol: 'PACW',
  },
  {
    value: 'MXL',
    label: 'MXL,  MaxLinear, Inc.',
    name: 'MaxLinear, Inc.',
    symbol: 'MXL',
  },
  {
    value: 'OLLI',
    label: "OLLI,  Ollie's Bargain Outlet Holdings, Inc.",
    name: "Ollie's Bargain Outlet Holdings, Inc.",
    symbol: 'OLLI',
  },
  {
    value: 'LOCO',
    label: 'LOCO,  El Pollo Loco Holdings, Inc.',
    name: 'El Pollo Loco Holdings, Inc.',
    symbol: 'LOCO',
  },
  {
    value: 'RUSHA',
    label: 'RUSHA,  Rush Enterprises, Inc.',
    name: 'Rush Enterprises, Inc.',
    symbol: 'RUSHA',
  },
  {
    value: 'APPN',
    label: 'APPN,  Appian Corporation',
    name: 'Appian Corporation',
    symbol: 'APPN',
  },
  {
    value: 'RHE',
    label: 'RHE,  Regional Health Properties, Inc.',
    name: 'Regional Health Properties, Inc.',
    symbol: 'RHE',
  },
  {
    value: 'AVY',
    label: 'AVY,  Avery Dennison Corporation',
    name: 'Avery Dennison Corporation',
    symbol: 'AVY',
  },
  {
    value: 'WMS',
    label: 'WMS,  Advanced Drainage Systems, Inc.',
    name: 'Advanced Drainage Systems, Inc.',
    symbol: 'WMS',
  },
  {
    value: 'MCFT',
    label: 'MCFT,  MasterCraft Boat Holdings, Inc.',
    name: 'MasterCraft Boat Holdings, Inc.',
    symbol: 'MCFT',
  },
  {
    value: 'DHT',
    label: 'DHT,  DHT Holdings, Inc.',
    name: 'DHT Holdings, Inc.',
    symbol: 'DHT',
  },
  {
    value: 'AVA',
    label: 'AVA,  Avista Corporation',
    name: 'Avista Corporation',
    symbol: 'AVA',
  },
  {
    value: 'WLK',
    label: 'WLK,  Westlake Corporation',
    name: 'Westlake Corporation',
    symbol: 'WLK',
  },
  {
    value: 'PSA',
    label: 'PSA,  Public Storage',
    name: 'Public Storage',
    symbol: 'PSA',
  },
  {
    value: 'AAWW',
    label: 'AAWW,  Atlas Air Worldwide Holdings, Inc.',
    name: 'Atlas Air Worldwide Holdings, Inc.',
    symbol: 'AAWW',
  },
  {
    value: 'WIX',
    label: 'WIX,  Wix.com Ltd.',
    name: 'Wix.com Ltd.',
    symbol: 'WIX',
  },
  {
    value: 'INO',
    label: 'INO,  Inovio Pharmaceuticals, Inc.',
    name: 'Inovio Pharmaceuticals, Inc.',
    symbol: 'INO',
  },
  {
    value: 'NTB',
    label: 'NTB,  The Bank of N.T. Butterfield & Son Limited',
    name: 'The Bank of N.T. Butterfield & Son Limited',
    symbol: 'NTB',
  },
  {
    value: 'AAOI',
    label: 'AAOI,  Applied Optoelectronics, Inc.',
    name: 'Applied Optoelectronics, Inc.',
    symbol: 'AAOI',
  },
  {
    value: 'FOSL',
    label: 'FOSL,  Fossil Group, Inc.',
    name: 'Fossil Group, Inc.',
    symbol: 'FOSL',
  },
  {
    value: 'STE',
    label: 'STE,  STERIS plc',
    name: 'STERIS plc',
    symbol: 'STE',
  },
  {
    value: 'OSTK',
    label: 'OSTK,  Overstock.com, Inc.',
    name: 'Overstock.com, Inc.',
    symbol: 'OSTK',
  },
  {
    value: 'SCHE',
    label: 'SCHE,  Schwab Emerging Markets Equity ETF',
    name: 'Schwab Emerging Markets Equity ETF',
    symbol: 'SCHE',
  },
  {
    value: 'FOXF',
    label: 'FOXF,  Fox Factory Holding Corp.',
    name: 'Fox Factory Holding Corp.',
    symbol: 'FOXF',
  },
  {
    value: 'MDGS',
    label: 'MDGS,  Medigus Ltd.',
    name: 'Medigus Ltd.',
    symbol: 'MDGS',
  },
  {
    value: 'AZPN',
    label: 'AZPN,  Aspen Technology, Inc.',
    name: 'Aspen Technology, Inc.',
    symbol: 'AZPN',
  },
  {
    value: 'DCI',
    label: 'DCI,  Donaldson Company, Inc.',
    name: 'Donaldson Company, Inc.',
    symbol: 'DCI',
  },
  {
    value: 'FTAI',
    label: 'FTAI,  Fortress Transportation and Infrastructure Investors LLC',
    name: 'Fortress Transportation and Infrastructure Investors LLC',
    symbol: 'FTAI',
  },
  {
    value: 'DSS',
    label: 'DSS,  DSS, Inc.',
    name: 'DSS, Inc.',
    symbol: 'DSS',
  },
  {
    value: 'ONVO',
    label: 'ONVO,  Organovo Holdings, Inc.',
    name: 'Organovo Holdings, Inc.',
    symbol: 'ONVO',
  },
  {
    value: 'AIZ',
    label: 'AIZ,  Assurant, Inc.',
    name: 'Assurant, Inc.',
    symbol: 'AIZ',
  },
  {
    value: 'IRBT',
    label: 'IRBT,  iRobot Corporation',
    name: 'iRobot Corporation',
    symbol: 'IRBT',
  },
  {
    value: 'SBLK',
    label: 'SBLK,  Star Bulk Carriers Corp.',
    name: 'Star Bulk Carriers Corp.',
    symbol: 'SBLK',
  },
  {
    value: 'CWST',
    label: 'CWST,  Casella Waste Systems, Inc.',
    name: 'Casella Waste Systems, Inc.',
    symbol: 'CWST',
  },
  {
    value: 'BURL',
    label: 'BURL,  Burlington Stores, Inc.',
    name: 'Burlington Stores, Inc.',
    symbol: 'BURL',
  },
  {
    value: 'LEG',
    label: 'LEG,  Leggett & Platt, Incorporated',
    name: 'Leggett & Platt, Incorporated',
    symbol: 'LEG',
  },
  {
    value: 'AAN',
    label: "AAN,  The Aaron's Company, Inc.",
    name: "The Aaron's Company, Inc.",
    symbol: 'AAN',
  },
  {
    value: 'SOI',
    label: 'SOI,  Solaris Oilfield Infrastructure, Inc.',
    name: 'Solaris Oilfield Infrastructure, Inc.',
    symbol: 'SOI',
  },
  {
    value: 'PRA',
    label: 'PRA,  ProAssurance Corporation',
    name: 'ProAssurance Corporation',
    symbol: 'PRA',
  },
  {
    value: 'GPMT',
    label: 'GPMT,  Granite Point Mortgage Trust Inc.',
    name: 'Granite Point Mortgage Trust Inc.',
    symbol: 'GPMT',
  },
  {
    value: 'EDZ',
    label: 'EDZ,  Direxion Daily MSCI Emerging Markets Bear 3X Shares',
    name: 'Direxion Daily MSCI Emerging Markets Bear 3X Shares',
    symbol: 'EDZ',
  },
  {
    value: 'INT',
    label: 'INT,  World Fuel Services Corporation',
    name: 'World Fuel Services Corporation',
    symbol: 'INT',
  },
  {
    value: 'UMBF',
    label: 'UMBF,  UMB Financial Corporation',
    name: 'UMB Financial Corporation',
    symbol: 'UMBF',
  },
  {
    value: 'LPG',
    label: 'LPG,  Dorian LPG Ltd.',
    name: 'Dorian LPG Ltd.',
    symbol: 'LPG',
  },
  {
    value: 'SOHU',
    label: 'SOHU,  Sohu.com Limited',
    name: 'Sohu.com Limited',
    symbol: 'SOHU',
  },
  {
    value: 'FGEN',
    label: 'FGEN,  FibroGen, Inc.',
    name: 'FibroGen, Inc.',
    symbol: 'FGEN',
  },
  {
    value: 'BAP',
    label: 'BAP,  Credicorp Ltd.',
    name: 'Credicorp Ltd.',
    symbol: 'BAP',
  },
  {
    value: 'BC',
    label: 'BC,  Brunswick Corporation',
    name: 'Brunswick Corporation',
    symbol: 'BC',
  },
  {
    value: 'OCSL',
    label: 'OCSL,  Oaktree Specialty Lending Corporation',
    name: 'Oaktree Specialty Lending Corporation',
    symbol: 'OCSL',
  },
  {
    value: 'MBUU',
    label: 'MBUU,  Malibu Boats, Inc.',
    name: 'Malibu Boats, Inc.',
    symbol: 'MBUU',
  },
  {
    value: 'HTLF',
    label: 'HTLF,  Heartland Financial USA, Inc.',
    name: 'Heartland Financial USA, Inc.',
    symbol: 'HTLF',
  },
  {
    value: 'BLKB',
    label: 'BLKB,  Blackbaud, Inc.',
    name: 'Blackbaud, Inc.',
    symbol: 'BLKB',
  },
  {
    value: 'SNN',
    label: 'SNN,  Smith & Nephew plc',
    name: 'Smith & Nephew plc',
    symbol: 'SNN',
  },
  {
    value: 'CUBI',
    label: 'CUBI,  Customers Bancorp, Inc.',
    name: 'Customers Bancorp, Inc.',
    symbol: 'CUBI',
  },
  {
    value: 'ENPH',
    label: 'ENPH,  Enphase Energy, Inc.',
    name: 'Enphase Energy, Inc.',
    symbol: 'ENPH',
  },
  {
    value: 'CADE',
    label: 'CADE,  Cadence Bank',
    name: 'Cadence Bank',
    symbol: 'CADE',
  },
  {
    value: 'AJRD',
    label: 'AJRD,  Aerojet Rocketdyne Holdings, Inc.',
    name: 'Aerojet Rocketdyne Holdings, Inc.',
    symbol: 'AJRD',
  },
  {
    value: 'APAM',
    label: 'APAM,  Artisan Partners Asset Management Inc.',
    name: 'Artisan Partners Asset Management Inc.',
    symbol: 'APAM',
  },
  {
    value: 'DXGE',
    label: 'DXGE,  WisdomTree Germany Hedged Equity Fund',
    name: 'WisdomTree Germany Hedged Equity Fund',
    symbol: 'DXGE',
  },
  {
    value: 'PEI',
    label: 'PEI,  Pennsylvania Real Estate Investment Trust',
    name: 'Pennsylvania Real Estate Investment Trust',
    symbol: 'PEI',
  },
  {
    value: 'HLIT',
    label: 'HLIT,  Harmonic Inc.',
    name: 'Harmonic Inc.',
    symbol: 'HLIT',
  },
  {
    value: 'ST',
    label: 'ST,  Sensata Technologies Holding plc',
    name: 'Sensata Technologies Holding plc',
    symbol: 'ST',
  },
  {
    value: 'IPAR',
    label: 'IPAR,  Inter Parfums, Inc.',
    name: 'Inter Parfums, Inc.',
    symbol: 'IPAR',
  },
  {
    value: 'CRTO',
    label: 'CRTO,  Criteo S.A.',
    name: 'Criteo S.A.',
    symbol: 'CRTO',
  },
  {
    value: 'AKR',
    label: 'AKR,  Acadia Realty Trust',
    name: 'Acadia Realty Trust',
    symbol: 'AKR',
  },
  {
    value: 'MRTX',
    label: 'MRTX,  Mirati Therapeutics, Inc.',
    name: 'Mirati Therapeutics, Inc.',
    symbol: 'MRTX',
  },
  {
    value: 'ZUMZ',
    label: 'ZUMZ,  Zumiez Inc.',
    name: 'Zumiez Inc.',
    symbol: 'ZUMZ',
  },
  {
    value: 'CHKR',
    label: 'CHKR,  Chesapeake Granite Wash Trust',
    name: 'Chesapeake Granite Wash Trust',
    symbol: 'CHKR',
  },
  {
    value: 'MLI',
    label: 'MLI,  Mueller Industries, Inc.',
    name: 'Mueller Industries, Inc.',
    symbol: 'MLI',
  },
  {
    value: 'ROG',
    label: 'ROG,  Rogers Corporation',
    name: 'Rogers Corporation',
    symbol: 'ROG',
  },
  {
    value: 'UMC',
    label: 'UMC,  United Microelectronics Corporation',
    name: 'United Microelectronics Corporation',
    symbol: 'UMC',
  },
  {
    value: 'EWD',
    label: 'EWD,  iShares MSCI Sweden ETF',
    name: 'iShares MSCI Sweden ETF',
    symbol: 'EWD',
  },
  {
    value: 'BAND',
    label: 'BAND,  Bandwidth Inc.',
    name: 'Bandwidth Inc.',
    symbol: 'BAND',
  },
  {
    value: 'ABMD',
    label: 'ABMD,  Abiomed, Inc.',
    name: 'Abiomed, Inc.',
    symbol: 'ABMD',
  },
  {
    value: 'KEYS',
    label: 'KEYS,  Keysight Technologies, Inc.',
    name: 'Keysight Technologies, Inc.',
    symbol: 'KEYS',
  },
  {
    value: 'SSP',
    label: 'SSP,  The E.W. Scripps Company',
    name: 'The E.W. Scripps Company',
    symbol: 'SSP',
  },
  {
    value: 'FRT',
    label: 'FRT,  Federal Realty Investment Trust',
    name: 'Federal Realty Investment Trust',
    symbol: 'FRT',
  },
  {
    value: 'NDSN',
    label: 'NDSN,  Nordson Corporation',
    name: 'Nordson Corporation',
    symbol: 'NDSN',
  },
  {
    value: 'ASC',
    label: 'ASC,  Ardmore Shipping Corporation',
    name: 'Ardmore Shipping Corporation',
    symbol: 'ASC',
  },
  {
    value: 'BATRK',
    label: 'BATRK,  The Liberty Braves Group',
    name: 'The Liberty Braves Group',
    symbol: 'BATRK',
  },
  {
    value: 'STC',
    label: 'STC,  Stewart Information Services Corporation',
    name: 'Stewart Information Services Corporation',
    symbol: 'STC',
  },
  {
    value: 'IRT',
    label: 'IRT,  Independence Realty Trust, Inc.',
    name: 'Independence Realty Trust, Inc.',
    symbol: 'IRT',
  },
  {
    value: 'FFBC',
    label: 'FFBC,  First Financial Bancorp.',
    name: 'First Financial Bancorp.',
    symbol: 'FFBC',
  },
  {
    value: 'DLTH',
    label: 'DLTH,  Duluth Holdings Inc.',
    name: 'Duluth Holdings Inc.',
    symbol: 'DLTH',
  },
  {
    value: 'LII',
    label: 'LII,  Lennox International Inc.',
    name: 'Lennox International Inc.',
    symbol: 'LII',
  },
  {
    value: 'SCHV',
    label: 'SCHV,  Schwab U.S. Large-Cap Value ETF',
    name: 'Schwab U.S. Large-Cap Value ETF',
    symbol: 'SCHV',
  },
  {
    value: 'OSG',
    label: 'OSG,  Overseas Shipholding Group, Inc.',
    name: 'Overseas Shipholding Group, Inc.',
    symbol: 'OSG',
  },
  {
    value: 'GOOS',
    label: 'GOOS,  Canada Goose Holdings Inc.',
    name: 'Canada Goose Holdings Inc.',
    symbol: 'GOOS',
  },
  {
    value: 'AXTI',
    label: 'AXTI,  AXT, Inc.',
    name: 'AXT, Inc.',
    symbol: 'AXTI',
  },
  {
    value: 'EGRX',
    label: 'EGRX,  Eagle Pharmaceuticals, Inc.',
    name: 'Eagle Pharmaceuticals, Inc.',
    symbol: 'EGRX',
  },
  {
    value: 'BMO',
    label: 'BMO,  Bank of Montreal',
    name: 'Bank of Montreal',
    symbol: 'BMO',
  },
  {
    value: 'EVV',
    label: 'EVV,  Eaton Vance Limited Duration Income Fund',
    name: 'Eaton Vance Limited Duration Income Fund',
    symbol: 'EVV',
  },
  {
    value: 'DBVT',
    label: 'DBVT,  DBV Technologies S.A.',
    name: 'DBV Technologies S.A.',
    symbol: 'DBVT',
  },
  {
    value: 'EPAM',
    label: 'EPAM,  EPAM Systems, Inc.',
    name: 'EPAM Systems, Inc.',
    symbol: 'EPAM',
  },
  {
    value: 'GDEN',
    label: 'GDEN,  Golden Entertainment, Inc.',
    name: 'Golden Entertainment, Inc.',
    symbol: 'GDEN',
  },
  {
    value: 'PVH',
    label: 'PVH,  PVH Corp.',
    name: 'PVH Corp.',
    symbol: 'PVH',
  },
  {
    value: 'CRBP',
    label: 'CRBP,  Corbus Pharmaceuticals Holdings, Inc.',
    name: 'Corbus Pharmaceuticals Holdings, Inc.',
    symbol: 'CRBP',
  },
  {
    value: 'MTD',
    label: 'MTD,  Mettler-Toledo International Inc.',
    name: 'Mettler-Toledo International Inc.',
    symbol: 'MTD',
  },
  {
    value: 'BCRX',
    label: 'BCRX,  BioCryst Pharmaceuticals, Inc.',
    name: 'BioCryst Pharmaceuticals, Inc.',
    symbol: 'BCRX',
  },
  {
    value: 'TOWN',
    label: 'TOWN,  TowneBank',
    name: 'TowneBank',
    symbol: 'TOWN',
  },
  {
    value: 'FIVN',
    label: 'FIVN,  Five9, Inc.',
    name: 'Five9, Inc.',
    symbol: 'FIVN',
  },
  {
    value: 'EEV',
    label: 'EEV,  ProShares UltraShort MSCI Emerging Markets',
    name: 'ProShares UltraShort MSCI Emerging Markets',
    symbol: 'EEV',
  },
  {
    value: 'EGHT',
    label: 'EGHT,  8x8, Inc.',
    name: '8x8, Inc.',
    symbol: 'EGHT',
  },
  {
    value: 'ESS',
    label: 'ESS,  Essex Property Trust, Inc.',
    name: 'Essex Property Trust, Inc.',
    symbol: 'ESS',
  },
  {
    value: 'JKHY',
    label: 'JKHY,  Jack Henry & Associates, Inc.',
    name: 'Jack Henry & Associates, Inc.',
    symbol: 'JKHY',
  },
  {
    value: 'INGR',
    label: 'INGR,  Ingredion Incorporated',
    name: 'Ingredion Incorporated',
    symbol: 'INGR',
  },
  {
    value: 'HT',
    label: 'HT,  Hersha Hospitality Trust',
    name: 'Hersha Hospitality Trust',
    symbol: 'HT',
  },
  {
    value: 'CPHI',
    label: 'CPHI,  China Pharma Holdings, Inc.',
    name: 'China Pharma Holdings, Inc.',
    symbol: 'CPHI',
  },
  {
    value: 'EFAV',
    label: 'EFAV,  iShares MSCI EAFE Min Vol Factor ETF',
    name: 'iShares MSCI EAFE Min Vol Factor ETF',
    symbol: 'EFAV',
  },
  {
    value: 'WPC',
    label: 'WPC,  W. P. Carey Inc.',
    name: 'W. P. Carey Inc.',
    symbol: 'WPC',
  },
  {
    value: 'VRSK',
    label: 'VRSK,  Verisk Analytics, Inc.',
    name: 'Verisk Analytics, Inc.',
    symbol: 'VRSK',
  },
  {
    value: 'ICLR',
    label: 'ICLR,  ICON Public Limited Company',
    name: 'ICON Public Limited Company',
    symbol: 'ICLR',
  },
  {
    value: 'AQMS',
    label: 'AQMS,  Aqua Metals, Inc.',
    name: 'Aqua Metals, Inc.',
    symbol: 'AQMS',
  },
  {
    value: 'AWR',
    label: 'AWR,  American States Water Company',
    name: 'American States Water Company',
    symbol: 'AWR',
  },
  {
    value: 'DRRX',
    label: 'DRRX,  DURECT Corporation',
    name: 'DURECT Corporation',
    symbol: 'DRRX',
  },
  {
    value: 'VRA',
    label: 'VRA,  Vera Bradley, Inc.',
    name: 'Vera Bradley, Inc.',
    symbol: 'VRA',
  },
  {
    value: 'WLKP',
    label: 'WLKP,  Westlake Chemical Partners LP',
    name: 'Westlake Chemical Partners LP',
    symbol: 'WLKP',
  },
  {
    value: 'BFAM',
    label: 'BFAM,  Bright Horizons Family Solutions Inc.',
    name: 'Bright Horizons Family Solutions Inc.',
    symbol: 'BFAM',
  },
  {
    value: 'IOVA',
    label: 'IOVA,  Iovance Biotherapeutics, Inc.',
    name: 'Iovance Biotherapeutics, Inc.',
    symbol: 'IOVA',
  },
  {
    value: 'TEN',
    label: 'TEN,  Tenneco Inc.',
    name: 'Tenneco Inc.',
    symbol: 'TEN',
  },
  {
    value: 'SUP',
    label: 'SUP,  Superior Industries International, Inc.',
    name: 'Superior Industries International, Inc.',
    symbol: 'SUP',
  },
  {
    value: 'LL',
    label: 'LL,  LL Flooring Holdings, Inc.',
    name: 'LL Flooring Holdings, Inc.',
    symbol: 'LL',
  },
  {
    value: 'LEA',
    label: 'LEA,  Lear Corporation',
    name: 'Lear Corporation',
    symbol: 'LEA',
  },
  {
    value: 'APD',
    label: 'APD,  Air Products and Chemicals, Inc.',
    name: 'Air Products and Chemicals, Inc.',
    symbol: 'APD',
  },
  {
    value: 'OSUR',
    label: 'OSUR,  OraSure Technologies, Inc.',
    name: 'OraSure Technologies, Inc.',
    symbol: 'OSUR',
  },
  {
    value: 'AVT',
    label: 'AVT,  Avnet, Inc.',
    name: 'Avnet, Inc.',
    symbol: 'AVT',
  },
  {
    value: 'SXC',
    label: 'SXC,  SunCoke Energy, Inc.',
    name: 'SunCoke Energy, Inc.',
    symbol: 'SXC',
  },
  {
    value: 'SPLV',
    label: 'SPLV,  Invesco S&P 500 Low Volatility ETF',
    name: 'Invesco S&P 500 Low Volatility ETF',
    symbol: 'SPLV',
  },
  {
    value: 'ATTO',
    label: 'ATTO,  Atento S.A.',
    name: 'Atento S.A.',
    symbol: 'ATTO',
  },
  {
    value: 'DVY',
    label: 'DVY,  iShares Select Dividend ETF',
    name: 'iShares Select Dividend ETF',
    symbol: 'DVY',
  },
  {
    value: 'NVCR',
    label: 'NVCR,  NovoCure Limited',
    name: 'NovoCure Limited',
    symbol: 'NVCR',
  },
  {
    value: 'DAR',
    label: 'DAR,  Darling Ingredients Inc.',
    name: 'Darling Ingredients Inc.',
    symbol: 'DAR',
  },
  {
    value: 'PCH',
    label: 'PCH,  PotlatchDeltic Corporation',
    name: 'PotlatchDeltic Corporation',
    symbol: 'PCH',
  },
  {
    value: 'CRIS',
    label: 'CRIS,  Curis, Inc.',
    name: 'Curis, Inc.',
    symbol: 'CRIS',
  },
  {
    value: 'RMBS',
    label: 'RMBS,  Rambus Inc.',
    name: 'Rambus Inc.',
    symbol: 'RMBS',
  },
  {
    value: 'PRTK',
    label: 'PRTK,  Paratek Pharmaceuticals, Inc.',
    name: 'Paratek Pharmaceuticals, Inc.',
    symbol: 'PRTK',
  },
  {
    value: 'AJG',
    label: 'AJG,  Arthur J. Gallagher & Co.',
    name: 'Arthur J. Gallagher & Co.',
    symbol: 'AJG',
  },
  {
    value: 'RGA',
    label: 'RGA,  Reinsurance Group of America, Incorporated',
    name: 'Reinsurance Group of America, Incorporated',
    symbol: 'RGA',
  },
  {
    value: 'TAST',
    label: 'TAST,  Carrols Restaurant Group, Inc.',
    name: 'Carrols Restaurant Group, Inc.',
    symbol: 'TAST',
  },
  {
    value: 'KB',
    label: 'KB,  KB Financial Group Inc.',
    name: 'KB Financial Group Inc.',
    symbol: 'KB',
  },
  {
    value: 'OTEX',
    label: 'OTEX,  Open Text Corporation',
    name: 'Open Text Corporation',
    symbol: 'OTEX',
  },
  {
    value: 'FHB',
    label: 'FHB,  First Hawaiian, Inc.',
    name: 'First Hawaiian, Inc.',
    symbol: 'FHB',
  },
  {
    value: 'SANM',
    label: 'SANM,  Sanmina Corporation',
    name: 'Sanmina Corporation',
    symbol: 'SANM',
  },
  {
    value: 'SNV',
    label: 'SNV,  Synovus Financial Corp.',
    name: 'Synovus Financial Corp.',
    symbol: 'SNV',
  },
  {
    value: 'EUFN',
    label: 'EUFN,  iShares MSCI Europe Financials ETF',
    name: 'iShares MSCI Europe Financials ETF',
    symbol: 'EUFN',
  },
  {
    value: 'MOD',
    label: 'MOD,  Modine Manufacturing Company',
    name: 'Modine Manufacturing Company',
    symbol: 'MOD',
  },
  {
    value: 'MGI',
    label: 'MGI,  MoneyGram International, Inc.',
    name: 'MoneyGram International, Inc.',
    symbol: 'MGI',
  },
  {
    value: 'SNA',
    label: 'SNA,  Snap-on Incorporated',
    name: 'Snap-on Incorporated',
    symbol: 'SNA',
  },
  {
    value: 'KOPN',
    label: 'KOPN,  Kopin Corporation',
    name: 'Kopin Corporation',
    symbol: 'KOPN',
  },
  {
    value: 'CLDT',
    label: 'CLDT,  Chatham Lodging Trust',
    name: 'Chatham Lodging Trust',
    symbol: 'CLDT',
  },
  {
    value: 'OSBC',
    label: 'OSBC,  Old Second Bancorp, Inc.',
    name: 'Old Second Bancorp, Inc.',
    symbol: 'OSBC',
  },
  {
    value: 'BRKL',
    label: 'BRKL,  Brookline Bancorp, Inc.',
    name: 'Brookline Bancorp, Inc.',
    symbol: 'BRKL',
  },
  {
    value: 'DEO',
    label: 'DEO,  Diageo plc',
    name: 'Diageo plc',
    symbol: 'DEO',
  },
  {
    value: 'UNFI',
    label: 'UNFI,  United Natural Foods, Inc.',
    name: 'United Natural Foods, Inc.',
    symbol: 'UNFI',
  },
  {
    value: 'PFF',
    label: 'PFF,  iShares Preferred and Income Securities ETF',
    name: 'iShares Preferred and Income Securities ETF',
    symbol: 'PFF',
  },
  {
    value: 'SAH',
    label: 'SAH,  Sonic Automotive, Inc.',
    name: 'Sonic Automotive, Inc.',
    symbol: 'SAH',
  },
  {
    value: 'NVTA',
    label: 'NVTA,  Invitae Corporation',
    name: 'Invitae Corporation',
    symbol: 'NVTA',
  },
  {
    value: 'HZO',
    label: 'HZO,  MarineMax, Inc.',
    name: 'MarineMax, Inc.',
    symbol: 'HZO',
  },
  {
    value: 'TNK',
    label: 'TNK,  Teekay Tankers Ltd.',
    name: 'Teekay Tankers Ltd.',
    symbol: 'TNK',
  },
  {
    value: 'GSG',
    label: 'GSG,  iShares S&P GSCI Commodity-Indexed Trust',
    name: 'iShares S&P GSCI Commodity-Indexed Trust',
    symbol: 'GSG',
  },
  {
    value: 'SATS',
    label: 'SATS,  EchoStar Corporation',
    name: 'EchoStar Corporation',
    symbol: 'SATS',
  },
  {
    value: 'SLGN',
    label: 'SLGN,  Silgan Holdings Inc.',
    name: 'Silgan Holdings Inc.',
    symbol: 'SLGN',
  },
  {
    value: 'IBKR',
    label: 'IBKR,  Interactive Brokers Group, Inc.',
    name: 'Interactive Brokers Group, Inc.',
    symbol: 'IBKR',
  },
  {
    value: 'HMC',
    label: 'HMC,  Honda Motor Co., Ltd.',
    name: 'Honda Motor Co., Ltd.',
    symbol: 'HMC',
  },
  {
    value: 'GPI',
    label: 'GPI,  Group 1 Automotive, Inc.',
    name: 'Group 1 Automotive, Inc.',
    symbol: 'GPI',
  },
  {
    value: 'CPA',
    label: 'CPA,  Copa Holdings, S.A.',
    name: 'Copa Holdings, S.A.',
    symbol: 'CPA',
  },
  {
    value: 'ROCK',
    label: 'ROCK,  Gibraltar Industries, Inc.',
    name: 'Gibraltar Industries, Inc.',
    symbol: 'ROCK',
  },
  {
    value: 'RNWK',
    label: 'RNWK,  RealNetworks, Inc.',
    name: 'RealNetworks, Inc.',
    symbol: 'RNWK',
  },
  {
    value: 'HOV',
    label: 'HOV,  Hovnanian Enterprises, Inc.',
    name: 'Hovnanian Enterprises, Inc.',
    symbol: 'HOV',
  },
  {
    value: 'ASTE',
    label: 'ASTE,  Astec Industries, Inc.',
    name: 'Astec Industries, Inc.',
    symbol: 'ASTE',
  },
  {
    value: 'ACET',
    label: 'ACET,  Adicet Bio, Inc.',
    name: 'Adicet Bio, Inc.',
    symbol: 'ACET',
  },
  {
    value: 'CTLT',
    label: 'CTLT,  Catalent, Inc.',
    name: 'Catalent, Inc.',
    symbol: 'CTLT',
  },
  {
    value: 'ROBO',
    label: 'ROBO,  Robo Global Robotics and Automation Index ETF',
    name: 'Robo Global Robotics and Automation Index ETF',
    symbol: 'ROBO',
  },
  {
    value: 'DMRC',
    label: 'DMRC,  Digimarc Corporation',
    name: 'Digimarc Corporation',
    symbol: 'DMRC',
  },
  {
    value: 'CUTR',
    label: 'CUTR,  Cutera, Inc.',
    name: 'Cutera, Inc.',
    symbol: 'CUTR',
  },
  {
    value: 'AMCX',
    label: 'AMCX,  AMC Networks Inc.',
    name: 'AMC Networks Inc.',
    symbol: 'AMCX',
  },
  {
    value: 'DXD',
    label: 'DXD,  ProShares UltraShort Dow30',
    name: 'ProShares UltraShort Dow30',
    symbol: 'DXD',
  },
  {
    value: 'NMM',
    label: 'NMM,  Navios Maritime Partners L.P.',
    name: 'Navios Maritime Partners L.P.',
    symbol: 'NMM',
  },
  {
    value: 'SAIC',
    label: 'SAIC,  Science Applications International Corporation',
    name: 'Science Applications International Corporation',
    symbol: 'SAIC',
  },
  {
    value: 'QLD',
    label: 'QLD,  ProShares Ultra QQQ',
    name: 'ProShares Ultra QQQ',
    symbol: 'QLD',
  },
  {
    value: 'DIOD',
    label: 'DIOD,  Diodes Incorporated',
    name: 'Diodes Incorporated',
    symbol: 'DIOD',
  },
  {
    value: 'RARE',
    label: 'RARE,  Ultragenyx Pharmaceutical Inc.',
    name: 'Ultragenyx Pharmaceutical Inc.',
    symbol: 'RARE',
  },
  {
    value: 'TPX',
    label: 'TPX,  Tempur Sealy International, Inc.',
    name: 'Tempur Sealy International, Inc.',
    symbol: 'TPX',
  },
  {
    value: 'BNFT',
    label: 'BNFT,  Benefitfocus, Inc.',
    name: 'Benefitfocus, Inc.',
    symbol: 'BNFT',
  },
  {
    value: 'VVR',
    label: 'VVR,  Invesco Senior Income Trust',
    name: 'Invesco Senior Income Trust',
    symbol: 'VVR',
  },
  {
    value: 'IYE',
    label: 'IYE,  iShares U.S. Energy ETF',
    name: 'iShares U.S. Energy ETF',
    symbol: 'IYE',
  },
  {
    value: 'PLYA',
    label: 'PLYA,  Playa Hotels & Resorts N.V.',
    name: 'Playa Hotels & Resorts N.V.',
    symbol: 'PLYA',
  },
  {
    value: 'CFFN',
    label: 'CFFN,  Capitol Federal Financial, Inc.',
    name: 'Capitol Federal Financial, Inc.',
    symbol: 'CFFN',
  },
  {
    value: 'HSII',
    label: 'HSII,  Heidrick & Struggles International, Inc.',
    name: 'Heidrick & Struggles International, Inc.',
    symbol: 'HSII',
  },
  {
    value: 'GDS',
    label: 'GDS,  GDS Holdings Limited',
    name: 'GDS Holdings Limited',
    symbol: 'GDS',
  },
  {
    value: 'UL',
    label: 'UL,  Unilever PLC',
    name: 'Unilever PLC',
    symbol: 'UL',
  },
  {
    value: 'LOPE',
    label: 'LOPE,  Grand Canyon Education, Inc.',
    name: 'Grand Canyon Education, Inc.',
    symbol: 'LOPE',
  },
  {
    value: 'TCMD',
    label: 'TCMD,  Tactile Systems Technology, Inc.',
    name: 'Tactile Systems Technology, Inc.',
    symbol: 'TCMD',
  },
  {
    value: 'HVT',
    label: 'HVT,  Haverty Furniture Companies, Inc.',
    name: 'Haverty Furniture Companies, Inc.',
    symbol: 'HVT',
  },
  {
    value: 'EWQ',
    label: 'EWQ,  iShares MSCI France ETF',
    name: 'iShares MSCI France ETF',
    symbol: 'EWQ',
  },
  {
    value: 'GOLD',
    label: 'GOLD,  Barrick Gold Corporation',
    name: 'Barrick Gold Corporation',
    symbol: 'GOLD',
  },
  {
    value: 'RLI',
    label: 'RLI,  RLI Corp.',
    name: 'RLI Corp.',
    symbol: 'RLI',
  },
  {
    value: 'KEX',
    label: 'KEX,  Kirby Corporation',
    name: 'Kirby Corporation',
    symbol: 'KEX',
  },
  {
    value: 'SPYG',
    label: 'SPYG,  SPDR Portfolio S&P 500 Growth ETF',
    name: 'SPDR Portfolio S&P 500 Growth ETF',
    symbol: 'SPYG',
  },
  {
    value: 'SMTC',
    label: 'SMTC,  Semtech Corporation',
    name: 'Semtech Corporation',
    symbol: 'SMTC',
  },
  {
    value: 'OR',
    label: 'OR,  Osisko Gold Royalties Ltd',
    name: 'Osisko Gold Royalties Ltd',
    symbol: 'OR',
  },
  {
    value: 'XNCR',
    label: 'XNCR,  Xencor, Inc.',
    name: 'Xencor, Inc.',
    symbol: 'XNCR',
  },
  {
    value: 'B',
    label: 'B,  Barnes Group Inc.',
    name: 'Barnes Group Inc.',
    symbol: 'B',
  },
  {
    value: 'DIN',
    label: 'DIN,  Dine Brands Global, Inc.',
    name: 'Dine Brands Global, Inc.',
    symbol: 'DIN',
  },
  {
    value: 'SCHN',
    label: 'SCHN,  Schnitzer Steel Industries, Inc.',
    name: 'Schnitzer Steel Industries, Inc.',
    symbol: 'SCHN',
  },
  {
    value: 'NM',
    label: 'NM,  Navios Maritime Holdings Inc.',
    name: 'Navios Maritime Holdings Inc.',
    symbol: 'NM',
  },
  {
    value: 'HSON',
    label: 'HSON,  Hudson Global, Inc.',
    name: 'Hudson Global, Inc.',
    symbol: 'HSON',
  },
  {
    value: 'UBSI',
    label: 'UBSI,  United Bankshares, Inc.',
    name: 'United Bankshares, Inc.',
    symbol: 'UBSI',
  },
  {
    value: 'HEZU',
    label: 'HEZU,  iShares Currency Hedged MSCI Eurozone ETF',
    name: 'iShares Currency Hedged MSCI Eurozone ETF',
    symbol: 'HEZU',
  },
  {
    value: 'GVA',
    label: 'GVA,  Granite Construction Incorporated',
    name: 'Granite Construction Incorporated',
    symbol: 'GVA',
  },
  {
    value: 'EVTC',
    label: 'EVTC,  EVERTEC, Inc.',
    name: 'EVERTEC, Inc.',
    symbol: 'EVTC',
  },
  {
    value: 'IVW',
    label: 'IVW,  iShares S&P 500 Growth ETF',
    name: 'iShares S&P 500 Growth ETF',
    symbol: 'IVW',
  },
  {
    value: 'SMCI',
    label: 'SMCI,  Super Micro Computer, Inc.',
    name: 'Super Micro Computer, Inc.',
    symbol: 'SMCI',
  },
  {
    value: 'HDGE',
    label: 'HDGE,  AdvisorShares Ranger Equity Bear ETF',
    name: 'AdvisorShares Ranger Equity Bear ETF',
    symbol: 'HDGE',
  },
  {
    value: 'DESP',
    label: 'DESP,  Despegar.com, Corp.',
    name: 'Despegar.com, Corp.',
    symbol: 'DESP',
  },
  {
    value: 'ODFL',
    label: 'ODFL,  Old Dominion Freight Line, Inc.',
    name: 'Old Dominion Freight Line, Inc.',
    symbol: 'ODFL',
  },
  {
    value: 'CFMS',
    label: 'CFMS,  Conformis, Inc.',
    name: 'Conformis, Inc.',
    symbol: 'CFMS',
  },
  {
    value: 'VRP',
    label: 'VRP,  Invesco Variable Rate Preferred ETF',
    name: 'Invesco Variable Rate Preferred ETF',
    symbol: 'VRP',
  },
  {
    value: 'NFJ',
    label: 'NFJ,  Virtus Dividend, Interest & Premium Strategy Fund',
    name: 'Virtus Dividend, Interest & Premium Strategy Fund',
    symbol: 'NFJ',
  },
  {
    value: 'ENZ',
    label: 'ENZ,  Enzo Biochem, Inc.',
    name: 'Enzo Biochem, Inc.',
    symbol: 'ENZ',
  },
  {
    value: 'GGN',
    label: 'GGN,  GAMCO Global Gold, Natural Resources & Income Trust',
    name: 'GAMCO Global Gold, Natural Resources & Income Trust',
    symbol: 'GGN',
  },
  {
    value: 'CDXS',
    label: 'CDXS,  Codexis, Inc.',
    name: 'Codexis, Inc.',
    symbol: 'CDXS',
  },
  {
    value: 'TFI',
    label: 'TFI,  SPDR Nuveen Bloomberg Municipal Bond ETF',
    name: 'SPDR Nuveen Bloomberg Municipal Bond ETF',
    symbol: 'TFI',
  },
  {
    value: 'FCFS',
    label: 'FCFS,  FirstCash Holdings, Inc',
    name: 'FirstCash Holdings, Inc',
    symbol: 'FCFS',
  },
  {
    value: 'URG',
    label: 'URG,  Ur-Energy Inc.',
    name: 'Ur-Energy Inc.',
    symbol: 'URG',
  },
  {
    value: 'VREX',
    label: 'VREX,  Varex Imaging Corporation',
    name: 'Varex Imaging Corporation',
    symbol: 'VREX',
  },
  {
    value: 'CNS',
    label: 'CNS,  Cohen & Steers, Inc.',
    name: 'Cohen & Steers, Inc.',
    symbol: 'CNS',
  },
  {
    value: 'LXU',
    label: 'LXU,  LSB Industries, Inc.',
    name: 'LSB Industries, Inc.',
    symbol: 'LXU',
  },
  {
    value: 'SON',
    label: 'SON,  Sonoco Products Company',
    name: 'Sonoco Products Company',
    symbol: 'SON',
  },
  {
    value: 'GDOT',
    label: 'GDOT,  Green Dot Corporation',
    name: 'Green Dot Corporation',
    symbol: 'GDOT',
  },
  {
    value: 'ROP',
    label: 'ROP,  Roper Technologies, Inc.',
    name: 'Roper Technologies, Inc.',
    symbol: 'ROP',
  },
  {
    value: 'NLS',
    label: 'NLS,  Nautilus, Inc.',
    name: 'Nautilus, Inc.',
    symbol: 'NLS',
  },
  {
    value: 'SITE',
    label: 'SITE,  SiteOne Landscape Supply, Inc.',
    name: 'SiteOne Landscape Supply, Inc.',
    symbol: 'SITE',
  },
  {
    value: 'LSXMK',
    label: 'LSXMK,  The Liberty SiriusXM Group',
    name: 'The Liberty SiriusXM Group',
    symbol: 'LSXMK',
  },
  {
    value: 'MSTR',
    label: 'MSTR,  MicroStrategy Incorporated',
    name: 'MicroStrategy Incorporated',
    symbol: 'MSTR',
  },
  {
    value: 'COHU',
    label: 'COHU,  Cohu, Inc.',
    name: 'Cohu, Inc.',
    symbol: 'COHU',
  },
  {
    value: 'RBA',
    label: 'RBA,  Ritchie Bros. Auctioneers Incorporated',
    name: 'Ritchie Bros. Auctioneers Incorporated',
    symbol: 'RBA',
  },
  {
    value: 'OTIC',
    label: 'OTIC,  Otonomy, Inc.',
    name: 'Otonomy, Inc.',
    symbol: 'OTIC',
  },
  {
    value: 'CPRX',
    label: 'CPRX,  Catalyst Pharmaceuticals, Inc.',
    name: 'Catalyst Pharmaceuticals, Inc.',
    symbol: 'CPRX',
  },
  {
    value: 'EFR',
    label: 'EFR,  Eaton Vance Senior Floating-Rate Trust',
    name: 'Eaton Vance Senior Floating-Rate Trust',
    symbol: 'EFR',
  },
  {
    value: 'GRMN',
    label: 'GRMN,  Garmin Ltd.',
    name: 'Garmin Ltd.',
    symbol: 'GRMN',
  },
  {
    value: 'SPDW',
    label: 'SPDW,  SPDR Portfolio Developed World ex-US ETF',
    name: 'SPDR Portfolio Developed World ex-US ETF',
    symbol: 'SPDW',
  },
  {
    value: 'MEDP',
    label: 'MEDP,  Medpace Holdings, Inc.',
    name: 'Medpace Holdings, Inc.',
    symbol: 'MEDP',
  },
  {
    value: 'TEF',
    label: 'TEF,  Telefónica, S.A.',
    name: 'Telefónica, S.A.',
    symbol: 'TEF',
  },
  {
    value: 'KEP',
    label: 'KEP,  Korea Electric Power Corporation',
    name: 'Korea Electric Power Corporation',
    symbol: 'KEP',
  },
  {
    value: 'CEIX',
    label: 'CEIX,  CONSOL Energy Inc.',
    name: 'CONSOL Energy Inc.',
    symbol: 'CEIX',
  },
  {
    value: 'GIL',
    label: 'GIL,  Gildan Activewear Inc.',
    name: 'Gildan Activewear Inc.',
    symbol: 'GIL',
  },
  {
    value: 'KYN',
    label: 'KYN,  Kayne Anderson Energy Infrastructure Fund, Inc.',
    name: 'Kayne Anderson Energy Infrastructure Fund, Inc.',
    symbol: 'KYN',
  },
  {
    value: 'NVGS',
    label: 'NVGS,  Navigator Holdings Ltd.',
    name: 'Navigator Holdings Ltd.',
    symbol: 'NVGS',
  },
  {
    value: 'MCY',
    label: 'MCY,  Mercury General Corporation',
    name: 'Mercury General Corporation',
    symbol: 'MCY',
  },
  {
    value: 'TWOU',
    label: 'TWOU,  2U, Inc.',
    name: '2U, Inc.',
    symbol: 'TWOU',
  },
  {
    value: 'MDGL',
    label: 'MDGL,  Madrigal Pharmaceuticals, Inc.',
    name: 'Madrigal Pharmaceuticals, Inc.',
    symbol: 'MDGL',
  },
  {
    value: 'AZUL',
    label: 'AZUL,  Azul S.A.',
    name: 'Azul S.A.',
    symbol: 'AZUL',
  },
  {
    value: 'TCS',
    label: 'TCS,  The Container Store Group, Inc.',
    name: 'The Container Store Group, Inc.',
    symbol: 'TCS',
  },
  {
    value: 'ACTG',
    label: 'ACTG,  Acacia Research Corporation',
    name: 'Acacia Research Corporation',
    symbol: 'ACTG',
  },
  {
    value: 'GATX',
    label: 'GATX,  GATX Corporation',
    name: 'GATX Corporation',
    symbol: 'GATX',
  },
  {
    value: 'EPC',
    label: 'EPC,  Edgewell Personal Care Company',
    name: 'Edgewell Personal Care Company',
    symbol: 'EPC',
  },
  {
    value: 'SRG',
    label: 'SRG,  Seritage Growth Properties',
    name: 'Seritage Growth Properties',
    symbol: 'SRG',
  },
  {
    value: 'AMWD',
    label: 'AMWD,  American Woodmark Corporation',
    name: 'American Woodmark Corporation',
    symbol: 'AMWD',
  },
  {
    value: 'CATY',
    label: 'CATY,  Cathay General Bancorp',
    name: 'Cathay General Bancorp',
    symbol: 'CATY',
  },
  {
    value: 'MSM',
    label: 'MSM,  MSC Industrial Direct Co., Inc.',
    name: 'MSC Industrial Direct Co., Inc.',
    symbol: 'MSM',
  },
  {
    value: 'COLB',
    label: 'COLB,  Columbia Banking System, Inc.',
    name: 'Columbia Banking System, Inc.',
    symbol: 'COLB',
  },
  {
    value: 'GEF',
    label: 'GEF,  Greif, Inc.',
    name: 'Greif, Inc.',
    symbol: 'GEF',
  },
  {
    value: 'LOMA',
    label: 'LOMA,  Loma Negra Compañía Industrial Argentina Sociedad Anónima',
    name: 'Loma Negra Compañía Industrial Argentina Sociedad Anónima',
    symbol: 'LOMA',
  },
  {
    value: 'MPAA',
    label: 'MPAA,  Motorcar Parts of America, Inc.',
    name: 'Motorcar Parts of America, Inc.',
    symbol: 'MPAA',
  },
  {
    value: 'GGG',
    label: 'GGG,  Graco Inc.',
    name: 'Graco Inc.',
    symbol: 'GGG',
  },
  {
    value: 'TDS',
    label: 'TDS,  Telephone and Data Systems, Inc.',
    name: 'Telephone and Data Systems, Inc.',
    symbol: 'TDS',
  },
  {
    value: 'COWN',
    label: 'COWN,  Cowen Inc.',
    name: 'Cowen Inc.',
    symbol: 'COWN',
  },
  {
    value: 'MTH',
    label: 'MTH,  Meritage Homes Corporation',
    name: 'Meritage Homes Corporation',
    symbol: 'MTH',
  },
  {
    value: 'GTLS',
    label: 'GTLS,  Chart Industries, Inc.',
    name: 'Chart Industries, Inc.',
    symbol: 'GTLS',
  },
  {
    value: 'SKYW',
    label: 'SKYW,  SkyWest, Inc.',
    name: 'SkyWest, Inc.',
    symbol: 'SKYW',
  },
  {
    value: 'CONN',
    label: "CONN,  Conn's, Inc.",
    name: "Conn's, Inc.",
    symbol: 'CONN',
  },
  {
    value: 'EZPW',
    label: 'EZPW,  EZCORP, Inc.',
    name: 'EZCORP, Inc.',
    symbol: 'EZPW',
  },
  {
    value: 'GNL',
    label: 'GNL,  Global Net Lease, Inc.',
    name: 'Global Net Lease, Inc.',
    symbol: 'GNL',
  },
  {
    value: 'BXC',
    label: 'BXC,  BlueLinx Holdings Inc.',
    name: 'BlueLinx Holdings Inc.',
    symbol: 'BXC',
  },
  {
    value: 'NPO',
    label: 'NPO,  EnPro Industries, Inc.',
    name: 'EnPro Industries, Inc.',
    symbol: 'NPO',
  },
  {
    value: 'RCI',
    label: 'RCI,  Rogers Communications Inc.',
    name: 'Rogers Communications Inc.',
    symbol: 'RCI',
  },
  {
    value: 'LFIN',
    label: 'LFIN,  Longfin Corp.',
    name: 'Longfin Corp.',
    symbol: 'LFIN',
  },
  {
    value: 'BSMX',
    label:
      'BSMX,  Banco Santander México, S.A., Institución de Banca Múltiple, Grupo Financiero Santander México',
    name: 'Banco Santander México, S.A., Institución de Banca Múltiple, Grupo Financiero Santander México',
    symbol: 'BSMX',
  },
  {
    value: 'MHLD',
    label: 'MHLD,  Maiden Holdings, Ltd.',
    name: 'Maiden Holdings, Ltd.',
    symbol: 'MHLD',
  },
  {
    value: 'AERI',
    label: 'AERI,  Aerie Pharmaceuticals, Inc.',
    name: 'Aerie Pharmaceuticals, Inc.',
    symbol: 'AERI',
  },
  {
    value: 'BAK',
    label: 'BAK,  Braskem S.A.',
    name: 'Braskem S.A.',
    symbol: 'BAK',
  },
  {
    value: 'SOXX',
    label: 'SOXX,  iShares Semiconductor ETF',
    name: 'iShares Semiconductor ETF',
    symbol: 'SOXX',
  },
  {
    value: 'SEM',
    label: 'SEM,  Select Medical Holdings Corporation',
    name: 'Select Medical Holdings Corporation',
    symbol: 'SEM',
  },
  {
    value: 'CCU',
    label: 'CCU,  Compañía Cervecerías Unidas S.A.',
    name: 'Compañía Cervecerías Unidas S.A.',
    symbol: 'CCU',
  },
  {
    value: 'KPTI',
    label: 'KPTI,  Karyopharm Therapeutics Inc.',
    name: 'Karyopharm Therapeutics Inc.',
    symbol: 'KPTI',
  },
  {
    value: 'GEL',
    label: 'GEL,  Genesis Energy, L.P.',
    name: 'Genesis Energy, L.P.',
    symbol: 'GEL',
  },
  {
    value: 'AVID',
    label: 'AVID,  Avid Technology, Inc.',
    name: 'Avid Technology, Inc.',
    symbol: 'AVID',
  },
  {
    value: 'HEP',
    label: 'HEP,  Holly Energy Partners, L.P.',
    name: 'Holly Energy Partners, L.P.',
    symbol: 'HEP',
  },
  {
    value: 'SLDB',
    label: 'SLDB,  Solid Biosciences Inc.',
    name: 'Solid Biosciences Inc.',
    symbol: 'SLDB',
  },
  {
    value: 'GNRC',
    label: 'GNRC,  Generac Holdings Inc.',
    name: 'Generac Holdings Inc.',
    symbol: 'GNRC',
  },
  {
    value: 'WD',
    label: 'WD,  Walker & Dunlop, Inc.',
    name: 'Walker & Dunlop, Inc.',
    symbol: 'WD',
  },
  {
    value: 'SCHG',
    label: 'SCHG,  Schwab U.S. Large-Cap Growth ETF',
    name: 'Schwab U.S. Large-Cap Growth ETF',
    symbol: 'SCHG',
  },
  {
    value: 'DNB',
    label: 'DNB,  Dun & Bradstreet Holdings, Inc.',
    name: 'Dun & Bradstreet Holdings, Inc.',
    symbol: 'DNB',
  },
  {
    value: 'TBBK',
    label: 'TBBK,  The Bancorp, Inc.',
    name: 'The Bancorp, Inc.',
    symbol: 'TBBK',
  },
  {
    value: 'IVC',
    label: 'IVC,  Invacare Corporation',
    name: 'Invacare Corporation',
    symbol: 'IVC',
  },
  {
    value: 'TOUR',
    label: 'TOUR,  Tuniu Corporation',
    name: 'Tuniu Corporation',
    symbol: 'TOUR',
  },
  {
    value: 'ITT',
    label: 'ITT,  ITT Inc.',
    name: 'ITT Inc.',
    symbol: 'ITT',
  },
  {
    value: 'MTRX',
    label: 'MTRX,  Matrix Service Company',
    name: 'Matrix Service Company',
    symbol: 'MTRX',
  },
  {
    value: 'EPOL',
    label: 'EPOL,  iShares MSCI Poland ETF',
    name: 'iShares MSCI Poland ETF',
    symbol: 'EPOL',
  },
  {
    value: 'BIV',
    label: 'BIV,  Vanguard Intermediate-Term Bond Index Fund',
    name: 'Vanguard Intermediate-Term Bond Index Fund',
    symbol: 'BIV',
  },
  {
    value: 'SPWR',
    label: 'SPWR,  SunPower Corporation',
    name: 'SunPower Corporation',
    symbol: 'SPWR',
  },
  {
    value: 'CHH',
    label: 'CHH,  Choice Hotels International, Inc.',
    name: 'Choice Hotels International, Inc.',
    symbol: 'CHH',
  },
  {
    value: 'SPYV',
    label: 'SPYV,  SPDR Portfolio S&P 500 Value ETF',
    name: 'SPDR Portfolio S&P 500 Value ETF',
    symbol: 'SPYV',
  },
  {
    value: 'BDJ',
    label: 'BDJ,  BlackRock Enhanced Equity Dividend Trust',
    name: 'BlackRock Enhanced Equity Dividend Trust',
    symbol: 'BDJ',
  },
  {
    value: 'PGTI',
    label: 'PGTI,  PGT Innovations, Inc.',
    name: 'PGT Innovations, Inc.',
    symbol: 'PGTI',
  },
  {
    value: 'SLAB',
    label: 'SLAB,  Silicon Laboratories Inc.',
    name: 'Silicon Laboratories Inc.',
    symbol: 'SLAB',
  },
  {
    value: 'WWE',
    label: 'WWE,  World Wrestling Entertainment, Inc.',
    name: 'World Wrestling Entertainment, Inc.',
    symbol: 'WWE',
  },
  {
    value: 'RNG',
    label: 'RNG,  RingCentral, Inc.',
    name: 'RingCentral, Inc.',
    symbol: 'RNG',
  },
  {
    value: 'EHC',
    label: 'EHC,  Encompass Health Corporation',
    name: 'Encompass Health Corporation',
    symbol: 'EHC',
  },
  {
    value: 'PEGA',
    label: 'PEGA,  Pegasystems Inc.',
    name: 'Pegasystems Inc.',
    symbol: 'PEGA',
  },
  {
    value: 'NOVT',
    label: 'NOVT,  Novanta Inc.',
    name: 'Novanta Inc.',
    symbol: 'NOVT',
  },
  {
    value: 'DTE',
    label: 'DTE,  DTE Energy Company',
    name: 'DTE Energy Company',
    symbol: 'DTE',
  },
  {
    value: 'MYGN',
    label: 'MYGN,  Myriad Genetics, Inc.',
    name: 'Myriad Genetics, Inc.',
    symbol: 'MYGN',
  },
  {
    value: 'DEA',
    label: 'DEA,  Easterly Government Properties, Inc.',
    name: 'Easterly Government Properties, Inc.',
    symbol: 'DEA',
  },
  {
    value: 'PXLW',
    label: 'PXLW,  Pixelworks, Inc.',
    name: 'Pixelworks, Inc.',
    symbol: 'PXLW',
  },
  {
    value: 'DGRO',
    label: 'DGRO,  iShares Core Dividend Growth ETF',
    name: 'iShares Core Dividend Growth ETF',
    symbol: 'DGRO',
  },
  {
    value: 'ERF',
    label: 'ERF,  Enerplus Corporation',
    name: 'Enerplus Corporation',
    symbol: 'ERF',
  },
  {
    value: 'AEIS',
    label: 'AEIS,  Advanced Energy Industries, Inc.',
    name: 'Advanced Energy Industries, Inc.',
    symbol: 'AEIS',
  },
  {
    value: 'ETV',
    label: 'ETV,  Eaton Vance Tax-Managed Buy-Write Opportunities Fund',
    name: 'Eaton Vance Tax-Managed Buy-Write Opportunities Fund',
    symbol: 'ETV',
  },
  {
    value: 'LHCG',
    label: 'LHCG,  LHC Group, Inc.',
    name: 'LHC Group, Inc.',
    symbol: 'LHCG',
  },
  {
    value: 'NDAQ',
    label: 'NDAQ,  Nasdaq, Inc.',
    name: 'Nasdaq, Inc.',
    symbol: 'NDAQ',
  },
  {
    value: 'NFG',
    label: 'NFG,  National Fuel Gas Company',
    name: 'National Fuel Gas Company',
    symbol: 'NFG',
  },
  {
    value: 'WSFS',
    label: 'WSFS,  WSFS Financial Corporation',
    name: 'WSFS Financial Corporation',
    symbol: 'WSFS',
  },
  {
    value: 'CNNE',
    label: 'CNNE,  Cannae Holdings, Inc.',
    name: 'Cannae Holdings, Inc.',
    symbol: 'CNNE',
  },
  {
    value: 'WCC',
    label: 'WCC,  WESCO International, Inc.',
    name: 'WESCO International, Inc.',
    symbol: 'WCC',
  },
  {
    value: 'BRC',
    label: 'BRC,  Brady Corporation',
    name: 'Brady Corporation',
    symbol: 'BRC',
  },
  {
    value: 'PIRS',
    label: 'PIRS,  Pieris Pharmaceuticals, Inc.',
    name: 'Pieris Pharmaceuticals, Inc.',
    symbol: 'PIRS',
  },
  {
    value: 'AAT',
    label: 'AAT,  American Assets Trust, Inc.',
    name: 'American Assets Trust, Inc.',
    symbol: 'AAT',
  },
  {
    value: 'ENR',
    label: 'ENR,  Energizer Holdings, Inc.',
    name: 'Energizer Holdings, Inc.',
    symbol: 'ENR',
  },
  {
    value: 'NSA',
    label: 'NSA,  National Storage Affiliates Trust',
    name: 'National Storage Affiliates Trust',
    symbol: 'NSA',
  },
  {
    value: 'ABIO',
    label: 'ABIO,  ARCA biopharma, Inc.',
    name: 'ARCA biopharma, Inc.',
    symbol: 'ABIO',
  },
  {
    value: 'RFP',
    label: 'RFP,  Resolute Forest Products Inc.',
    name: 'Resolute Forest Products Inc.',
    symbol: 'RFP',
  },
  {
    value: 'MMYT',
    label: 'MMYT,  MakeMyTrip Limited',
    name: 'MakeMyTrip Limited',
    symbol: 'MMYT',
  },
  {
    value: 'UTHR',
    label: 'UTHR,  United Therapeutics Corporation',
    name: 'United Therapeutics Corporation',
    symbol: 'UTHR',
  },
  {
    value: 'CALM',
    label: 'CALM,  Cal-Maine Foods, Inc.',
    name: 'Cal-Maine Foods, Inc.',
    symbol: 'CALM',
  },
  {
    value: 'ALRM',
    label: 'ALRM,  Alarm.com Holdings, Inc.',
    name: 'Alarm.com Holdings, Inc.',
    symbol: 'ALRM',
  },
  {
    value: 'USAC',
    label: 'USAC,  USA Compression Partners, LP',
    name: 'USA Compression Partners, LP',
    symbol: 'USAC',
  },
  {
    value: 'MKTX',
    label: 'MKTX,  MarketAxess Holdings Inc.',
    name: 'MarketAxess Holdings Inc.',
    symbol: 'MKTX',
  },
  {
    value: 'LSCC',
    label: 'LSCC,  Lattice Semiconductor Corporation',
    name: 'Lattice Semiconductor Corporation',
    symbol: 'LSCC',
  },
  {
    value: 'PTGX',
    label: 'PTGX,  Protagonist Therapeutics, Inc.',
    name: 'Protagonist Therapeutics, Inc.',
    symbol: 'PTGX',
  },
  {
    value: 'WSR',
    label: 'WSR,  Whitestone REIT',
    name: 'Whitestone REIT',
    symbol: 'WSR',
  },
  {
    value: 'RWT',
    label: 'RWT,  Redwood Trust, Inc.',
    name: 'Redwood Trust, Inc.',
    symbol: 'RWT',
  },
  {
    value: 'ECPG',
    label: 'ECPG,  Encore Capital Group, Inc.',
    name: 'Encore Capital Group, Inc.',
    symbol: 'ECPG',
  },
  {
    value: 'SFL',
    label: 'SFL,  SFL Corporation Ltd.',
    name: 'SFL Corporation Ltd.',
    symbol: 'SFL',
  },
  {
    value: 'AME',
    label: 'AME,  AMETEK, Inc.',
    name: 'AMETEK, Inc.',
    symbol: 'AME',
  },
  {
    value: 'BKNG',
    label: 'BKNG,  Booking Holdings Inc.',
    name: 'Booking Holdings Inc.',
    symbol: 'BKNG',
  },
  {
    value: 'DCT',
    label: 'DCT,  Duck Creek Technologies, Inc.',
    name: 'Duck Creek Technologies, Inc.',
    symbol: 'DCT',
  },
  {
    value: 'CSTE',
    label: 'CSTE,  Caesarstone Ltd.',
    name: 'Caesarstone Ltd.',
    symbol: 'CSTE',
  },
  {
    value: 'QNST',
    label: 'QNST,  QuinStreet, Inc.',
    name: 'QuinStreet, Inc.',
    symbol: 'QNST',
  },
  {
    value: 'SR',
    label: 'SR,  Spire Inc.',
    name: 'Spire Inc.',
    symbol: 'SR',
  },
  {
    value: 'PMTS',
    label: 'PMTS,  CPI Card Group Inc.',
    name: 'CPI Card Group Inc.',
    symbol: 'PMTS',
  },
  {
    value: 'SIMO',
    label: 'SIMO,  Silicon Motion Technology Corporation',
    name: 'Silicon Motion Technology Corporation',
    symbol: 'SIMO',
  },
  {
    value: 'AMED',
    label: 'AMED,  Amedisys, Inc.',
    name: 'Amedisys, Inc.',
    symbol: 'AMED',
  },
  {
    value: 'HRTG',
    label: 'HRTG,  Heritage Insurance Holdings, Inc.',
    name: 'Heritage Insurance Holdings, Inc.',
    symbol: 'HRTG',
  },
  {
    value: 'EUM',
    label: 'EUM,  ProShares Short MSCI Emerging Markets',
    name: 'ProShares Short MSCI Emerging Markets',
    symbol: 'EUM',
  },
  {
    value: 'IGF',
    label: 'IGF,  iShares Global Infrastructure ETF',
    name: 'iShares Global Infrastructure ETF',
    symbol: 'IGF',
  },
  {
    value: 'VNET',
    label: 'VNET,  VNET Group, Inc.',
    name: 'VNET Group, Inc.',
    symbol: 'VNET',
  },
  {
    value: 'AYX',
    label: 'AYX,  Alteryx, Inc.',
    name: 'Alteryx, Inc.',
    symbol: 'AYX',
  },
  {
    value: 'CHRS',
    label: 'CHRS,  Coherus BioSciences, Inc.',
    name: 'Coherus BioSciences, Inc.',
    symbol: 'CHRS',
  },
  {
    value: 'BWB',
    label: 'BWB,  Bridgewater Bancshares, Inc.',
    name: 'Bridgewater Bancshares, Inc.',
    symbol: 'BWB',
  },
  {
    value: 'BEDU',
    label: 'BEDU,  Bright Scholar Education Holdings Limited',
    name: 'Bright Scholar Education Holdings Limited',
    symbol: 'BEDU',
  },
  {
    value: 'CIB',
    label: 'CIB,  Bancolombia S.A.',
    name: 'Bancolombia S.A.',
    symbol: 'CIB',
  },
  {
    value: 'FSP',
    label: 'FSP,  Franklin Street Properties Corp.',
    name: 'Franklin Street Properties Corp.',
    symbol: 'FSP',
  },
  {
    value: 'IIIN',
    label: 'IIIN,  Insteel Industries, Inc.',
    name: 'Insteel Industries, Inc.',
    symbol: 'IIIN',
  },
  {
    value: 'BLD',
    label: 'BLD,  TopBuild Corp.',
    name: 'TopBuild Corp.',
    symbol: 'BLD',
  },
  {
    value: 'HFWA',
    label: 'HFWA,  Heritage Financial Corporation',
    name: 'Heritage Financial Corporation',
    symbol: 'HFWA',
  },
  {
    value: 'SCSC',
    label: 'SCSC,  ScanSource, Inc.',
    name: 'ScanSource, Inc.',
    symbol: 'SCSC',
  },
  {
    value: 'NETI',
    label: 'NETI,  Eneti Inc.',
    name: 'Eneti Inc.',
    symbol: 'NETI',
  },
  {
    value: 'BHLB',
    label: 'BHLB,  Berkshire Hills Bancorp, Inc.',
    name: 'Berkshire Hills Bancorp, Inc.',
    symbol: 'BHLB',
  },
  {
    value: 'CASI',
    label: 'CASI,  CASI Pharmaceuticals, Inc.',
    name: 'CASI Pharmaceuticals, Inc.',
    symbol: 'CASI',
  },
  {
    value: 'RE',
    label: 'RE,  Everest Re Group, Ltd.',
    name: 'Everest Re Group, Ltd.',
    symbol: 'RE',
  },
  {
    value: 'LUNA',
    label: 'LUNA,  Luna Innovations Incorporated',
    name: 'Luna Innovations Incorporated',
    symbol: 'LUNA',
  },
  {
    value: 'FBC',
    label: 'FBC,  Flagstar Bancorp, Inc.',
    name: 'Flagstar Bancorp, Inc.',
    symbol: 'FBC',
  },
  {
    value: 'CLS',
    label: 'CLS,  Celestica Inc.',
    name: 'Celestica Inc.',
    symbol: 'CLS',
  },
  {
    value: 'PHK',
    label: 'PHK,  PIMCO High Income Fund',
    name: 'PIMCO High Income Fund',
    symbol: 'PHK',
  },
  {
    value: 'SFNC',
    label: 'SFNC,  Simmons First National Corporation',
    name: 'Simmons First National Corporation',
    symbol: 'SFNC',
  },
  {
    value: 'GBX',
    label: 'GBX,  The Greenbrier Companies, Inc.',
    name: 'The Greenbrier Companies, Inc.',
    symbol: 'GBX',
  },
  {
    value: 'DGRW',
    label: 'DGRW,  WisdomTree U.S. Quality Dividend Growth Fund',
    name: 'WisdomTree U.S. Quality Dividend Growth Fund',
    symbol: 'DGRW',
  },
  {
    value: 'HUBB',
    label: 'HUBB,  Hubbell Incorporated',
    name: 'Hubbell Incorporated',
    symbol: 'HUBB',
  },
  {
    value: 'EPP',
    label: 'EPP,  iShares MSCI Pacific ex Japan ETF',
    name: 'iShares MSCI Pacific ex Japan ETF',
    symbol: 'EPP',
  },
  {
    value: 'EWM',
    label: 'EWM,  iShares MSCI Malaysia ETF',
    name: 'iShares MSCI Malaysia ETF',
    symbol: 'EWM',
  },
  {
    value: 'PBA',
    label: 'PBA,  Pembina Pipeline Corporation',
    name: 'Pembina Pipeline Corporation',
    symbol: 'PBA',
  },
  {
    value: 'UAN',
    label: 'UAN,  CVR Partners, LP',
    name: 'CVR Partners, LP',
    symbol: 'UAN',
  },
  {
    value: 'GMED',
    label: 'GMED,  Globus Medical, Inc.',
    name: 'Globus Medical, Inc.',
    symbol: 'GMED',
  },
  {
    value: 'VERU',
    label: 'VERU,  Veru Inc.',
    name: 'Veru Inc.',
    symbol: 'VERU',
  },
  {
    value: 'PB',
    label: 'PB,  Prosperity Bancshares, Inc.',
    name: 'Prosperity Bancshares, Inc.',
    symbol: 'PB',
  },
  {
    value: 'VVI',
    label: 'VVI,  Viad Corp',
    name: 'Viad Corp',
    symbol: 'VVI',
  },
  {
    value: 'MRCY',
    label: 'MRCY,  Mercury Systems, Inc.',
    name: 'Mercury Systems, Inc.',
    symbol: 'MRCY',
  },
  {
    value: 'TU',
    label: 'TU,  TELUS Corporation',
    name: 'TELUS Corporation',
    symbol: 'TU',
  },
  {
    value: 'LCII',
    label: 'LCII,  LCI Industries',
    name: 'LCI Industries',
    symbol: 'LCII',
  },
  {
    value: 'ICFI',
    label: 'ICFI,  ICF International, Inc.',
    name: 'ICF International, Inc.',
    symbol: 'ICFI',
  },
  {
    value: 'ACIW',
    label: 'ACIW,  ACI Worldwide, Inc.',
    name: 'ACI Worldwide, Inc.',
    symbol: 'ACIW',
  },
  {
    value: 'ADX',
    label: 'ADX,  Adams Diversified Equity Fund, Inc.',
    name: 'Adams Diversified Equity Fund, Inc.',
    symbol: 'ADX',
  },
  {
    value: 'PTCT',
    label: 'PTCT,  PTC Therapeutics, Inc.',
    name: 'PTC Therapeutics, Inc.',
    symbol: 'PTCT',
  },
  {
    value: 'TFSL',
    label: 'TFSL,  TFS Financial Corporation',
    name: 'TFS Financial Corporation',
    symbol: 'TFSL',
  },
  {
    value: 'ATNM',
    label: 'ATNM,  Actinium Pharmaceuticals, Inc.',
    name: 'Actinium Pharmaceuticals, Inc.',
    symbol: 'ATNM',
  },
  {
    value: 'RRGB',
    label: 'RRGB,  Red Robin Gourmet Burgers, Inc.',
    name: 'Red Robin Gourmet Burgers, Inc.',
    symbol: 'RRGB',
  },
  {
    value: 'CECE',
    label: 'CECE,  CECO Environmental Corp.',
    name: 'CECO Environmental Corp.',
    symbol: 'CECE',
  },
  {
    value: 'IART',
    label: 'IART,  Integra LifeSciences Holdings Corporation',
    name: 'Integra LifeSciences Holdings Corporation',
    symbol: 'IART',
  },
  {
    value: 'VB',
    label: 'VB,  Vanguard Small Cap Index Fund',
    name: 'Vanguard Small Cap Index Fund',
    symbol: 'VB',
  },
  {
    value: 'III',
    label: 'III,  Information Services Group, Inc.',
    name: 'Information Services Group, Inc.',
    symbol: 'III',
  },
  {
    value: 'JILL',
    label: 'JILL,  J.Jill, Inc.',
    name: 'J.Jill, Inc.',
    symbol: 'JILL',
  },
  {
    value: 'YELL',
    label: 'YELL,  Yellow Corporation',
    name: 'Yellow Corporation',
    symbol: 'YELL',
  },
  {
    value: 'WWW',
    label: 'WWW,  Wolverine World Wide, Inc.',
    name: 'Wolverine World Wide, Inc.',
    symbol: 'WWW',
  },
  {
    value: 'LXRX',
    label: 'LXRX,  Lexicon Pharmaceuticals, Inc.',
    name: 'Lexicon Pharmaceuticals, Inc.',
    symbol: 'LXRX',
  },
  {
    value: 'PRAA',
    label: 'PRAA,  PRA Group, Inc.',
    name: 'PRA Group, Inc.',
    symbol: 'PRAA',
  },
  {
    value: 'CLM',
    label: 'CLM,  Cornerstone Strategic Value Fund, Inc.',
    name: 'Cornerstone Strategic Value Fund, Inc.',
    symbol: 'CLM',
  },
  {
    value: 'RPT',
    label: 'RPT,  RPT Realty',
    name: 'RPT Realty',
    symbol: 'RPT',
  },
  {
    value: 'SCVL',
    label: 'SCVL,  Shoe Carnival, Inc.',
    name: 'Shoe Carnival, Inc.',
    symbol: 'SCVL',
  },
  {
    value: 'AFG',
    label: 'AFG,  American Financial Group, Inc.',
    name: 'American Financial Group, Inc.',
    symbol: 'AFG',
  },
  {
    value: 'KRNT',
    label: 'KRNT,  Kornit Digital Ltd.',
    name: 'Kornit Digital Ltd.',
    symbol: 'KRNT',
  },
  {
    value: 'CYD',
    label: 'CYD,  China Yuchai International Limited',
    name: 'China Yuchai International Limited',
    symbol: 'CYD',
  },
  {
    value: 'MYRG',
    label: 'MYRG,  MYR Group Inc.',
    name: 'MYR Group Inc.',
    symbol: 'MYRG',
  },
  {
    value: 'BANR',
    label: 'BANR,  Banner Corporation',
    name: 'Banner Corporation',
    symbol: 'BANR',
  },
  {
    value: 'CRESY',
    label:
      'CRESY,  Cresud Sociedad Anónima, Comercial, Inmobiliaria, Financiera y Agropecuaria',
    name: 'Cresud Sociedad Anónima, Comercial, Inmobiliaria, Financiera y Agropecuaria',
    symbol: 'CRESY',
  },
  {
    value: 'XT',
    label: 'XT,  iShares Exponential Technologies ETF',
    name: 'iShares Exponential Technologies ETF',
    symbol: 'XT',
  },
  {
    value: 'IUSG',
    label: 'IUSG,  iShares Core S&P U.S. Growth ETF',
    name: 'iShares Core S&P U.S. Growth ETF',
    symbol: 'IUSG',
  },
  {
    value: 'FPH',
    label: 'FPH,  Five Point Holdings, LLC',
    name: 'Five Point Holdings, LLC',
    symbol: 'FPH',
  },
  {
    value: 'FN',
    label: 'FN,  Fabrinet',
    name: 'Fabrinet',
    symbol: 'FN',
  },
  {
    value: 'CPK',
    label: 'CPK,  Chesapeake Utilities Corporation',
    name: 'Chesapeake Utilities Corporation',
    symbol: 'CPK',
  },
  {
    value: 'ORC',
    label: 'ORC,  Orchid Island Capital, Inc.',
    name: 'Orchid Island Capital, Inc.',
    symbol: 'ORC',
  },
  {
    value: 'SLF',
    label: 'SLF,  Sun Life Financial Inc.',
    name: 'Sun Life Financial Inc.',
    symbol: 'SLF',
  },
  {
    value: 'VSAT',
    label: 'VSAT,  Viasat, Inc.',
    name: 'Viasat, Inc.',
    symbol: 'VSAT',
  },
  {
    value: 'WK',
    label: 'WK,  Workiva Inc.',
    name: 'Workiva Inc.',
    symbol: 'WK',
  },
  {
    value: 'FUN',
    label: 'FUN,  Cedar Fair, L.P.',
    name: 'Cedar Fair, L.P.',
    symbol: 'FUN',
  },
  {
    value: 'SIGI',
    label: 'SIGI,  Selective Insurance Group, Inc.',
    name: 'Selective Insurance Group, Inc.',
    symbol: 'SIGI',
  },
  {
    value: 'SCHA',
    label: 'SCHA,  Schwab U.S. Small-Cap ETF',
    name: 'Schwab U.S. Small-Cap ETF',
    symbol: 'SCHA',
  },
  {
    value: 'CACI',
    label: 'CACI,  CACI International Inc',
    name: 'CACI International Inc',
    symbol: 'CACI',
  },
  {
    value: 'RMTI',
    label: 'RMTI,  Rockwell Medical, Inc.',
    name: 'Rockwell Medical, Inc.',
    symbol: 'RMTI',
  },
  {
    value: 'SUPV',
    label: 'SUPV,  Grupo Supervielle S.A.',
    name: 'Grupo Supervielle S.A.',
    symbol: 'SUPV',
  },
  {
    value: 'ALKS',
    label: 'ALKS,  Alkermes plc',
    name: 'Alkermes plc',
    symbol: 'ALKS',
  },
  {
    value: 'GLDD',
    label: 'GLDD,  Great Lakes Dredge & Dock Corporation',
    name: 'Great Lakes Dredge & Dock Corporation',
    symbol: 'GLDD',
  },
  {
    value: 'BOH',
    label: 'BOH,  Bank of Hawaii Corporation',
    name: 'Bank of Hawaii Corporation',
    symbol: 'BOH',
  },
  {
    value: 'FIZZ',
    label: 'FIZZ,  National Beverage Corp.',
    name: 'National Beverage Corp.',
    symbol: 'FIZZ',
  },
  {
    value: 'LIQT',
    label: 'LIQT,  LiqTech International, Inc.',
    name: 'LiqTech International, Inc.',
    symbol: 'LIQT',
  },
  {
    value: 'CEPU',
    label: 'CEPU,  Central Puerto S.A.',
    name: 'Central Puerto S.A.',
    symbol: 'CEPU',
  },
  {
    value: 'DORM',
    label: 'DORM,  Dorman Products, Inc.',
    name: 'Dorman Products, Inc.',
    symbol: 'DORM',
  },
  {
    value: 'EVBG',
    label: 'EVBG,  Everbridge, Inc.',
    name: 'Everbridge, Inc.',
    symbol: 'EVBG',
  },
  {
    value: 'IGOV',
    label: 'IGOV,  iShares International Treasury Bond ETF',
    name: 'iShares International Treasury Bond ETF',
    symbol: 'IGOV',
  },
  {
    value: 'AEMD',
    label: 'AEMD,  Aethlon Medical, Inc.',
    name: 'Aethlon Medical, Inc.',
    symbol: 'AEMD',
  },
  {
    value: 'ENV',
    label: 'ENV,  Envestnet, Inc.',
    name: 'Envestnet, Inc.',
    symbol: 'ENV',
  },
  {
    value: 'AIN',
    label: 'AIN,  Albany International Corp.',
    name: 'Albany International Corp.',
    symbol: 'AIN',
  },
  {
    value: 'CAE',
    label: 'CAE,  CAE Inc.',
    name: 'CAE Inc.',
    symbol: 'CAE',
  },
  {
    value: 'NUV',
    label: 'NUV,  Nuveen Municipal Value Fund, Inc.',
    name: 'Nuveen Municipal Value Fund, Inc.',
    symbol: 'NUV',
  },
  {
    value: 'HXL',
    label: 'HXL,  Hexcel Corporation',
    name: 'Hexcel Corporation',
    symbol: 'HXL',
  },
  {
    value: 'SPHD',
    label: 'SPHD,  Invesco S&P 500 High Dividend Low Volatility ETF',
    name: 'Invesco S&P 500 High Dividend Low Volatility ETF',
    symbol: 'SPHD',
  },
  {
    value: 'TUSK',
    label: 'TUSK,  Mammoth Energy Services, Inc.',
    name: 'Mammoth Energy Services, Inc.',
    symbol: 'TUSK',
  },
  {
    value: 'EME',
    label: 'EME,  EMCOR Group, Inc.',
    name: 'EMCOR Group, Inc.',
    symbol: 'EME',
  },
  {
    value: 'VUZI',
    label: 'VUZI,  Vuzix Corporation',
    name: 'Vuzix Corporation',
    symbol: 'VUZI',
  },
  {
    value: 'OFG',
    label: 'OFG,  OFG Bancorp',
    name: 'OFG Bancorp',
    symbol: 'OFG',
  },
  {
    value: 'GLT',
    label: 'GLT,  Glatfelter Corporation',
    name: 'Glatfelter Corporation',
    symbol: 'GLT',
  },
  {
    value: 'IUSV',
    label: 'IUSV,  iShares Core S&P U.S. Value ETF',
    name: 'iShares Core S&P U.S. Value ETF',
    symbol: 'IUSV',
  },
  {
    value: 'PHG',
    label: 'PHG,  Koninklijke Philips N.V.',
    name: 'Koninklijke Philips N.V.',
    symbol: 'PHG',
  },
  {
    value: 'DUST',
    label: 'DUST,  Direxion Daily Gold Miners Index Bear 2X Shares',
    name: 'Direxion Daily Gold Miners Index Bear 2X Shares',
    symbol: 'DUST',
  },
  {
    value: 'MERC',
    label: 'MERC,  Mercer International Inc.',
    name: 'Mercer International Inc.',
    symbol: 'MERC',
  },
  {
    value: 'HEES',
    label: 'HEES,  H&E Equipment Services, Inc.',
    name: 'H&E Equipment Services, Inc.',
    symbol: 'HEES',
  },
  {
    value: 'BXMX',
    label: 'BXMX,  Nuveen S&P 500 Buy-Write Income Fund',
    name: 'Nuveen S&P 500 Buy-Write Income Fund',
    symbol: 'BXMX',
  },
  {
    value: 'AZO',
    label: 'AZO,  AutoZone, Inc.',
    name: 'AutoZone, Inc.',
    symbol: 'AZO',
  },
  {
    value: 'AMZA',
    label: 'AMZA,  InfraCap MLP ETF',
    name: 'InfraCap MLP ETF',
    symbol: 'AMZA',
  },
  {
    value: 'AGFS',
    label: 'AGFS,  AgroFresh Solutions, Inc.',
    name: 'AgroFresh Solutions, Inc.',
    symbol: 'AGFS',
  },
  {
    value: 'IDV',
    label: 'IDV,  iShares International Select Dividend ETF',
    name: 'iShares International Select Dividend ETF',
    symbol: 'IDV',
  },
  {
    value: 'JKS',
    label: 'JKS,  JinkoSolar Holding Co., Ltd.',
    name: 'JinkoSolar Holding Co., Ltd.',
    symbol: 'JKS',
  },
  {
    value: 'SNCR',
    label: 'SNCR,  Synchronoss Technologies, Inc.',
    name: 'Synchronoss Technologies, Inc.',
    symbol: 'SNCR',
  },
  {
    value: 'TM',
    label: 'TM,  Toyota Motor Corporation',
    name: 'Toyota Motor Corporation',
    symbol: 'TM',
  },
  {
    value: 'UVV',
    label: 'UVV,  Universal Corporation',
    name: 'Universal Corporation',
    symbol: 'UVV',
  },
  {
    value: 'VNM',
    label: 'VNM,  VanEck Vietnam ETF',
    name: 'VanEck Vietnam ETF',
    symbol: 'VNM',
  },
  {
    value: 'BIOC',
    label: 'BIOC,  Biocept, Inc.',
    name: 'Biocept, Inc.',
    symbol: 'BIOC',
  },
  {
    value: 'HUBG',
    label: 'HUBG,  Hub Group, Inc.',
    name: 'Hub Group, Inc.',
    symbol: 'HUBG',
  },
  {
    value: 'LAD',
    label: 'LAD,  Lithia Motors, Inc.',
    name: 'Lithia Motors, Inc.',
    symbol: 'LAD',
  },
  {
    value: 'TWI',
    label: 'TWI,  Titan International, Inc.',
    name: 'Titan International, Inc.',
    symbol: 'TWI',
  },
  {
    value: 'SNBR',
    label: 'SNBR,  Sleep Number Corporation',
    name: 'Sleep Number Corporation',
    symbol: 'SNBR',
  },
  {
    value: 'RYAM',
    label: 'RYAM,  Rayonier Advanced Materials Inc.',
    name: 'Rayonier Advanced Materials Inc.',
    symbol: 'RYAM',
  },
  {
    value: 'LOGI',
    label: 'LOGI,  Logitech International S.A.',
    name: 'Logitech International S.A.',
    symbol: 'LOGI',
  },
  {
    value: 'DCO',
    label: 'DCO,  Ducommun Incorporated',
    name: 'Ducommun Incorporated',
    symbol: 'DCO',
  },
  {
    value: 'FLWS',
    label: 'FLWS,  1-800-FLOWERS.COM, Inc.',
    name: '1-800-FLOWERS.COM, Inc.',
    symbol: 'FLWS',
  },
  {
    value: 'HYT',
    label: 'HYT,  BlackRock Corporate High Yield Fund, Inc.',
    name: 'BlackRock Corporate High Yield Fund, Inc.',
    symbol: 'HYT',
  },
  {
    value: 'FCPT',
    label: 'FCPT,  Four Corners Property Trust, Inc.',
    name: 'Four Corners Property Trust, Inc.',
    symbol: 'FCPT',
  },
  {
    value: 'EBIX',
    label: 'EBIX,  Ebix, Inc.',
    name: 'Ebix, Inc.',
    symbol: 'EBIX',
  },
  {
    value: 'UDOW',
    label: 'UDOW,  ProShares UltraPro Dow30',
    name: 'ProShares UltraPro Dow30',
    symbol: 'UDOW',
  },
  {
    value: 'MTUM',
    label: 'MTUM,  iShares MSCI USA Momentum Factor ETF',
    name: 'iShares MSCI USA Momentum Factor ETF',
    symbol: 'MTUM',
  },
  {
    value: 'MFG',
    label: 'MFG,  Mizuho Financial Group, Inc.',
    name: 'Mizuho Financial Group, Inc.',
    symbol: 'MFG',
  },
  {
    value: 'ANAB',
    label: 'ANAB,  AnaptysBio, Inc.',
    name: 'AnaptysBio, Inc.',
    symbol: 'ANAB',
  },
  {
    value: 'ABUS',
    label: 'ABUS,  Arbutus Biopharma Corporation',
    name: 'Arbutus Biopharma Corporation',
    symbol: 'ABUS',
  },
  {
    value: 'ANSS',
    label: 'ANSS,  ANSYS, Inc.',
    name: 'ANSYS, Inc.',
    symbol: 'ANSS',
  },
  {
    value: 'NVRO',
    label: 'NVRO,  Nevro Corp.',
    name: 'Nevro Corp.',
    symbol: 'NVRO',
  },
  {
    value: 'MMS',
    label: 'MMS,  Maximus, Inc.',
    name: 'Maximus, Inc.',
    symbol: 'MMS',
  },
  {
    value: 'HTGC',
    label: 'HTGC,  Hercules Capital, Inc.',
    name: 'Hercules Capital, Inc.',
    symbol: 'HTGC',
  },
  {
    value: 'CBRL',
    label: 'CBRL,  Cracker Barrel Old Country Store, Inc.',
    name: 'Cracker Barrel Old Country Store, Inc.',
    symbol: 'CBRL',
  },
  {
    value: 'ADXS',
    label: 'ADXS,  Advaxis, Inc.',
    name: 'Advaxis, Inc.',
    symbol: 'ADXS',
  },
  {
    value: 'ITRI',
    label: 'ITRI,  Itron, Inc.',
    name: 'Itron, Inc.',
    symbol: 'ITRI',
  },
  {
    value: 'GBCI',
    label: 'GBCI,  Glacier Bancorp, Inc.',
    name: 'Glacier Bancorp, Inc.',
    symbol: 'GBCI',
  },
  {
    value: 'NR',
    label: 'NR,  Newpark Resources, Inc.',
    name: 'Newpark Resources, Inc.',
    symbol: 'NR',
  },
  {
    value: 'ERII',
    label: 'ERII,  Energy Recovery, Inc.',
    name: 'Energy Recovery, Inc.',
    symbol: 'ERII',
  },
  {
    value: 'CLMT',
    label: 'CLMT,  Calumet Specialty Products Partners, L.P.',
    name: 'Calumet Specialty Products Partners, L.P.',
    symbol: 'CLMT',
  },
  {
    value: 'RVT',
    label: 'RVT,  Royce Value Trust Inc.',
    name: 'Royce Value Trust Inc.',
    symbol: 'RVT',
  },
  {
    value: 'FMS',
    label: 'FMS,  Fresenius Medical Care AG & Co. KGaA',
    name: 'Fresenius Medical Care AG & Co. KGaA',
    symbol: 'FMS',
  },
  {
    value: 'EMD',
    label: 'EMD,  Western Asset Emerging Markets Debt Fund Inc.',
    name: 'Western Asset Emerging Markets Debt Fund Inc.',
    symbol: 'EMD',
  },
  {
    value: 'RDY',
    label: "RDY,  Dr. Reddy's Laboratories Limited",
    name: "Dr. Reddy's Laboratories Limited",
    symbol: 'RDY',
  },
  {
    value: 'CERS',
    label: 'CERS,  Cerus Corporation',
    name: 'Cerus Corporation',
    symbol: 'CERS',
  },
  {
    value: 'LIT',
    label: 'LIT,  Global X Lithium & Battery Tech ETF',
    name: 'Global X Lithium & Battery Tech ETF',
    symbol: 'LIT',
  },
  {
    value: 'BL',
    label: 'BL,  BlackLine, Inc.',
    name: 'BlackLine, Inc.',
    symbol: 'BL',
  },
  {
    value: 'TLK',
    label:
      'TLK,  Perusahaan Perseroan (Persero) PT Telekomunikasi Indonesia Tbk',
    name: 'Perusahaan Perseroan (Persero) PT Telekomunikasi Indonesia Tbk',
    symbol: 'TLK',
  },
  {
    value: 'SSYS',
    label: 'SSYS,  Stratasys Ltd.',
    name: 'Stratasys Ltd.',
    symbol: 'SSYS',
  },
  {
    value: 'GUNR',
    label:
      'GUNR,  FlexShares Morningstar Global Upstream Natural Resources Index Fund',
    name: 'FlexShares Morningstar Global Upstream Natural Resources Index Fund',
    symbol: 'GUNR',
  },
  {
    value: 'HTH',
    label: 'HTH,  Hilltop Holdings Inc.',
    name: 'Hilltop Holdings Inc.',
    symbol: 'HTH',
  },
  {
    value: 'LKFN',
    label: 'LKFN,  Lakeland Financial Corporation',
    name: 'Lakeland Financial Corporation',
    symbol: 'LKFN',
  },
  {
    value: 'ANGO',
    label: 'ANGO,  AngioDynamics, Inc.',
    name: 'AngioDynamics, Inc.',
    symbol: 'ANGO',
  },
  {
    value: 'PKX',
    label: 'PKX,  POSCO Holdings Inc.',
    name: 'POSCO Holdings Inc.',
    symbol: 'PKX',
  },
  {
    value: 'SVM',
    label: 'SVM,  Silvercorp Metals Inc.',
    name: 'Silvercorp Metals Inc.',
    symbol: 'SVM',
  },
  {
    value: 'LE',
    label: "LE,  Lands' End, Inc.",
    name: "Lands' End, Inc.",
    symbol: 'LE',
  },
  {
    value: 'PAG',
    label: 'PAG,  Penske Automotive Group, Inc.',
    name: 'Penske Automotive Group, Inc.',
    symbol: 'PAG',
  },
  {
    value: 'PRIM',
    label: 'PRIM,  Primoris Services Corporation',
    name: 'Primoris Services Corporation',
    symbol: 'PRIM',
  },
  {
    value: 'CTMX',
    label: 'CTMX,  CytomX Therapeutics, Inc.',
    name: 'CytomX Therapeutics, Inc.',
    symbol: 'CTMX',
  },
  {
    value: 'FLRN',
    label: 'FLRN,  SPDR Bloomberg Investment Grade Floating Rate ETF',
    name: 'SPDR Bloomberg Investment Grade Floating Rate ETF',
    symbol: 'FLRN',
  },
  {
    value: 'BBGI',
    label: 'BBGI,  Beasley Broadcast Group, Inc.',
    name: 'Beasley Broadcast Group, Inc.',
    symbol: 'BBGI',
  },
  {
    value: 'CASH',
    label: 'CASH,  Pathward Financial, Inc.',
    name: 'Pathward Financial, Inc.',
    symbol: 'CASH',
  },
  {
    value: 'PINC',
    label: 'PINC,  Premier, Inc.',
    name: 'Premier, Inc.',
    symbol: 'PINC',
  },
  {
    value: 'SFBS',
    label: 'SFBS,  ServisFirst Bancshares, Inc.',
    name: 'ServisFirst Bancshares, Inc.',
    symbol: 'SFBS',
  },
  {
    value: 'SMPL',
    label: 'SMPL,  The Simply Good Foods Company',
    name: 'The Simply Good Foods Company',
    symbol: 'SMPL',
  },
  {
    value: 'FNKO',
    label: 'FNKO,  Funko, Inc.',
    name: 'Funko, Inc.',
    symbol: 'FNKO',
  },
  {
    value: 'SSTK',
    label: 'SSTK,  Shutterstock, Inc.',
    name: 'Shutterstock, Inc.',
    symbol: 'SSTK',
  },
  {
    value: 'ZBRA',
    label: 'ZBRA,  Zebra Technologies Corporation',
    name: 'Zebra Technologies Corporation',
    symbol: 'ZBRA',
  },
  {
    value: 'BMA',
    label: 'BMA,  Banco Macro S.A.',
    name: 'Banco Macro S.A.',
    symbol: 'BMA',
  },
  {
    value: 'GABC',
    label: 'GABC,  German American Bancorp, Inc.',
    name: 'German American Bancorp, Inc.',
    symbol: 'GABC',
  },
  {
    value: 'NCMI',
    label: 'NCMI,  National CineMedia, Inc.',
    name: 'National CineMedia, Inc.',
    symbol: 'NCMI',
  },
  {
    value: 'PCSB',
    label: 'PCSB,  PCSB Financial Corporation',
    name: 'PCSB Financial Corporation',
    symbol: 'PCSB',
  },
  {
    value: 'FDP',
    label: 'FDP,  Fresh Del Monte Produce Inc.',
    name: 'Fresh Del Monte Produce Inc.',
    symbol: 'FDP',
  },
  {
    value: 'ADMP',
    label: 'ADMP,  Adamis Pharmaceuticals Corporation',
    name: 'Adamis Pharmaceuticals Corporation',
    symbol: 'ADMP',
  },
  {
    value: 'EDIT',
    label: 'EDIT,  Editas Medicine, Inc.',
    name: 'Editas Medicine, Inc.',
    symbol: 'EDIT',
  },
  {
    value: 'BGY',
    label: 'BGY,  BlackRock Enhanced International Dividend Trust',
    name: 'BlackRock Enhanced International Dividend Trust',
    symbol: 'BGY',
  },
  {
    value: 'AB',
    label: 'AB,  AllianceBernstein Holding L.P.',
    name: 'AllianceBernstein Holding L.P.',
    symbol: 'AB',
  },
  {
    value: 'GAIA',
    label: 'GAIA,  Gaia, Inc.',
    name: 'Gaia, Inc.',
    symbol: 'GAIA',
  },
  {
    value: 'PRGS',
    label: 'PRGS,  Progress Software Corporation',
    name: 'Progress Software Corporation',
    symbol: 'PRGS',
  },
  {
    value: 'TRMK',
    label: 'TRMK,  Trustmark Corporation',
    name: 'Trustmark Corporation',
    symbol: 'TRMK',
  },
  {
    value: 'ACRS',
    label: 'ACRS,  Aclaris Therapeutics, Inc.',
    name: 'Aclaris Therapeutics, Inc.',
    symbol: 'ACRS',
  },
  {
    value: 'NUS',
    label: 'NUS,  Nu Skin Enterprises, Inc.',
    name: 'Nu Skin Enterprises, Inc.',
    symbol: 'NUS',
  },
  {
    value: 'SPSC',
    label: 'SPSC,  SPS Commerce, Inc.',
    name: 'SPS Commerce, Inc.',
    symbol: 'SPSC',
  },
  {
    value: 'EFSC',
    label: 'EFSC,  Enterprise Financial Services Corp',
    name: 'Enterprise Financial Services Corp',
    symbol: 'EFSC',
  },
  {
    value: 'BOKF',
    label: 'BOKF,  BOK Financial Corporation',
    name: 'BOK Financial Corporation',
    symbol: 'BOKF',
  },
  {
    value: 'MD',
    label: 'MD,  Pediatrix Medical Group, Inc.',
    name: 'Pediatrix Medical Group, Inc.',
    symbol: 'MD',
  },
  {
    value: 'PSMT',
    label: 'PSMT,  PriceSmart, Inc.',
    name: 'PriceSmart, Inc.',
    symbol: 'PSMT',
  },
  {
    value: 'FTK',
    label: 'FTK,  Flotek Industries, Inc.',
    name: 'Flotek Industries, Inc.',
    symbol: 'FTK',
  },
  {
    value: 'UVSP',
    label: 'UVSP,  Univest Financial Corporation',
    name: 'Univest Financial Corporation',
    symbol: 'UVSP',
  },
  {
    value: 'OCFC',
    label: 'OCFC,  OceanFirst Financial Corp.',
    name: 'OceanFirst Financial Corp.',
    symbol: 'OCFC',
  },
  {
    value: 'RADA',
    label: 'RADA,  RADA Electronic Industries Ltd.',
    name: 'RADA Electronic Industries Ltd.',
    symbol: 'RADA',
  },
  {
    value: 'PAM',
    label: 'PAM,  Pampa Energía S.A.',
    name: 'Pampa Energía S.A.',
    symbol: 'PAM',
  },
  {
    value: 'BND',
    label: 'BND,  Vanguard Total Bond Market Index Fund',
    name: 'Vanguard Total Bond Market Index Fund',
    symbol: 'BND',
  },
  {
    value: 'EZA',
    label: 'EZA,  iShares MSCI South Africa ETF',
    name: 'iShares MSCI South Africa ETF',
    symbol: 'EZA',
  },
  {
    value: 'GOGL',
    label: 'GOGL,  Golden Ocean Group Limited',
    name: 'Golden Ocean Group Limited',
    symbol: 'GOGL',
  },
  {
    value: 'CNCE',
    label: 'CNCE,  Concert Pharmaceuticals, Inc.',
    name: 'Concert Pharmaceuticals, Inc.',
    symbol: 'CNCE',
  },
  {
    value: 'CCRN',
    label: 'CCRN,  Cross Country Healthcare, Inc.',
    name: 'Cross Country Healthcare, Inc.',
    symbol: 'CCRN',
  },
  {
    value: 'JBT',
    label: 'JBT,  John Bean Technologies Corporation',
    name: 'John Bean Technologies Corporation',
    symbol: 'JBT',
  },
  {
    value: 'PJT',
    label: 'PJT,  PJT Partners Inc.',
    name: 'PJT Partners Inc.',
    symbol: 'PJT',
  },
  {
    value: 'BKE',
    label: 'BKE,  The Buckle, Inc.',
    name: 'The Buckle, Inc.',
    symbol: 'BKE',
  },
  {
    value: 'RHP',
    label: 'RHP,  Ryman Hospitality Properties, Inc.',
    name: 'Ryman Hospitality Properties, Inc.',
    symbol: 'RHP',
  },
  {
    value: 'IRTC',
    label: 'IRTC,  iRhythm Technologies, Inc.',
    name: 'iRhythm Technologies, Inc.',
    symbol: 'IRTC',
  },
  {
    value: 'ADC',
    label: 'ADC,  Agree Realty Corporation',
    name: 'Agree Realty Corporation',
    symbol: 'ADC',
  },
  {
    value: 'SMIN',
    label: 'SMIN,  iShares MSCI India Small-Cap ETF',
    name: 'iShares MSCI India Small-Cap ETF',
    symbol: 'SMIN',
  },
  {
    value: 'ENVA',
    label: 'ENVA,  Enova International, Inc.',
    name: 'Enova International, Inc.',
    symbol: 'ENVA',
  },
  {
    value: 'QTNT',
    label: 'QTNT,  Quotient Limited',
    name: 'Quotient Limited',
    symbol: 'QTNT',
  },
  {
    value: 'CMRE',
    label: 'CMRE,  Costamare Inc.',
    name: 'Costamare Inc.',
    symbol: 'CMRE',
  },
  {
    value: 'EGAN',
    label: 'EGAN,  eGain Corporation',
    name: 'eGain Corporation',
    symbol: 'EGAN',
  },
  {
    value: 'TPIC',
    label: 'TPIC,  TPI Composites, Inc.',
    name: 'TPI Composites, Inc.',
    symbol: 'TPIC',
  },
  {
    value: 'ARW',
    label: 'ARW,  Arrow Electronics, Inc.',
    name: 'Arrow Electronics, Inc.',
    symbol: 'ARW',
  },
  {
    value: 'NEA',
    label: 'NEA,  Nuveen AMT-Free Quality Municipal Income Fund',
    name: 'Nuveen AMT-Free Quality Municipal Income Fund',
    symbol: 'NEA',
  },
  {
    value: 'TA',
    label: 'TA,  TravelCenters of America Inc.',
    name: 'TravelCenters of America Inc.',
    symbol: 'TA',
  },
  {
    value: 'AMG',
    label: 'AMG,  Affiliated Managers Group, Inc.',
    name: 'Affiliated Managers Group, Inc.',
    symbol: 'AMG',
  },
  {
    value: 'EFV',
    label: 'EFV,  iShares MSCI EAFE Value ETF',
    name: 'iShares MSCI EAFE Value ETF',
    symbol: 'EFV',
  },
  {
    value: 'PI',
    label: 'PI,  Impinj, Inc.',
    name: 'Impinj, Inc.',
    symbol: 'PI',
  },
  {
    value: 'NMR',
    label: 'NMR,  Nomura Holdings, Inc.',
    name: 'Nomura Holdings, Inc.',
    symbol: 'NMR',
  },
  {
    value: 'FPE',
    label: 'FPE,  First Trust Preferred Securities and Income ETF',
    name: 'First Trust Preferred Securities and Income ETF',
    symbol: 'FPE',
  },
  {
    value: 'BLV',
    label: 'BLV,  Vanguard Long-Term Bond Fund',
    name: 'Vanguard Long-Term Bond Fund',
    symbol: 'BLV',
  },
  {
    value: 'MNRO',
    label: 'MNRO,  Monro, Inc.',
    name: 'Monro, Inc.',
    symbol: 'MNRO',
  },
  {
    value: 'CWI',
    label: 'CWI,  SPDR MSCI ACWI ex-US ETF',
    name: 'SPDR MSCI ACWI ex-US ETF',
    symbol: 'CWI',
  },
  {
    value: 'ACLS',
    label: 'ACLS,  Axcelis Technologies, Inc.',
    name: 'Axcelis Technologies, Inc.',
    symbol: 'ACLS',
  },
  {
    value: 'ITCI',
    label: 'ITCI,  Intra-Cellular Therapies, Inc.',
    name: 'Intra-Cellular Therapies, Inc.',
    symbol: 'ITCI',
  },
  {
    value: 'LSI',
    label: 'LSI,  Life Storage, Inc.',
    name: 'Life Storage, Inc.',
    symbol: 'LSI',
  },
  {
    value: 'NOBL',
    label: 'NOBL,  ProShares S&P 500 Dividend Aristocrats ETF',
    name: 'ProShares S&P 500 Dividend Aristocrats ETF',
    symbol: 'NOBL',
  },
  {
    value: 'VIVO',
    label: 'VIVO,  Meridian Bioscience, Inc.',
    name: 'Meridian Bioscience, Inc.',
    symbol: 'VIVO',
  },
  {
    value: 'MCHX',
    label: 'MCHX,  Marchex, Inc.',
    name: 'Marchex, Inc.',
    symbol: 'MCHX',
  },
  {
    value: 'EPR',
    label: 'EPR,  EPR Properties',
    name: 'EPR Properties',
    symbol: 'EPR',
  },
  {
    value: 'GEM',
    label: 'GEM,  Goldman Sachs ActiveBeta Emerging Markets Equity ETF',
    name: 'Goldman Sachs ActiveBeta Emerging Markets Equity ETF',
    symbol: 'GEM',
  },
  {
    value: 'IGD',
    label: 'IGD,  Voya Global Equity Dividend and Premium Opportunity Fund',
    name: 'Voya Global Equity Dividend and Premium Opportunity Fund',
    symbol: 'IGD',
  },
  {
    value: 'EWS',
    label: 'EWS,  iShares MSCI Singapore ETF',
    name: 'iShares MSCI Singapore ETF',
    symbol: 'EWS',
  },
  {
    value: 'CRAI',
    label: 'CRAI,  CRA International, Inc.',
    name: 'CRA International, Inc.',
    symbol: 'CRAI',
  },
  {
    value: 'DSGX',
    label: 'DSGX,  The Descartes Systems Group Inc.',
    name: 'The Descartes Systems Group Inc.',
    symbol: 'DSGX',
  },
  {
    value: 'CTIC',
    label: 'CTIC,  CTI BioPharma Corp.',
    name: 'CTI BioPharma Corp.',
    symbol: 'CTIC',
  },
  {
    value: 'GUT',
    label: 'GUT,  The Gabelli Utility Trust',
    name: 'The Gabelli Utility Trust',
    symbol: 'GUT',
  },
  {
    value: 'AIT',
    label: 'AIT,  Applied Industrial Technologies, Inc.',
    name: 'Applied Industrial Technologies, Inc.',
    symbol: 'AIT',
  },
  {
    value: 'HCM',
    label: 'HCM,  HUTCHMED (China) Limited',
    name: 'HUTCHMED (China) Limited',
    symbol: 'HCM',
  },
  {
    value: 'TREE',
    label: 'TREE,  LendingTree, Inc.',
    name: 'LendingTree, Inc.',
    symbol: 'TREE',
  },
  {
    value: 'MODN',
    label: 'MODN,  Model N, Inc.',
    name: 'Model N, Inc.',
    symbol: 'MODN',
  },
  {
    value: 'AXAS',
    label: 'AXAS,  Abraxas Petroleum Corporation',
    name: 'Abraxas Petroleum Corporation',
    symbol: 'AXAS',
  },
  {
    value: 'OUNZ',
    label: 'OUNZ,  VanEck Merk Gold Trust',
    name: 'VanEck Merk Gold Trust',
    symbol: 'OUNZ',
  },
  {
    value: 'CTSO',
    label: 'CTSO,  Cytosorbents Corporation',
    name: 'Cytosorbents Corporation',
    symbol: 'CTSO',
  },
  {
    value: 'KIE',
    label: 'KIE,  SPDR S&P Insurance ETF',
    name: 'SPDR S&P Insurance ETF',
    symbol: 'KIE',
  },
  {
    value: 'SNX',
    label: 'SNX,  TD SYNNEX Corporation',
    name: 'TD SYNNEX Corporation',
    symbol: 'SNX',
  },
  {
    value: 'ACOR',
    label: 'ACOR,  Acorda Therapeutics, Inc.',
    name: 'Acorda Therapeutics, Inc.',
    symbol: 'ACOR',
  },
  {
    value: 'PCTY',
    label: 'PCTY,  Paylocity Holding Corporation',
    name: 'Paylocity Holding Corporation',
    symbol: 'PCTY',
  },
  {
    value: 'TX',
    label: 'TX,  Ternium S.A.',
    name: 'Ternium S.A.',
    symbol: 'TX',
  },
  {
    value: 'BSAC',
    label: 'BSAC,  Banco Santander-Chile',
    name: 'Banco Santander-Chile',
    symbol: 'BSAC',
  },
  {
    value: 'ALIM',
    label: 'ALIM,  Alimera Sciences, Inc.',
    name: 'Alimera Sciences, Inc.',
    symbol: 'ALIM',
  },
  {
    value: 'LILAK',
    label: 'LILAK,  Liberty Latin America Ltd.',
    name: 'Liberty Latin America Ltd.',
    symbol: 'LILAK',
  },
  {
    value: 'ASUR',
    label: 'ASUR,  Asure Software, Inc.',
    name: 'Asure Software, Inc.',
    symbol: 'ASUR',
  },
  {
    value: 'UTG',
    label: 'UTG,  Reaves Utility Income Fund',
    name: 'Reaves Utility Income Fund',
    symbol: 'UTG',
  },
  {
    value: 'SSL',
    label: 'SSL,  Sasol Limited',
    name: 'Sasol Limited',
    symbol: 'SSL',
  },
  {
    value: 'HMN',
    label: 'HMN,  Horace Mann Educators Corporation',
    name: 'Horace Mann Educators Corporation',
    symbol: 'HMN',
  },
  {
    value: 'VMBS',
    label: 'VMBS,  Vanguard Mortgage-Backed Securities Index Fund',
    name: 'Vanguard Mortgage-Backed Securities Index Fund',
    symbol: 'VMBS',
  },
  {
    value: 'NTLA',
    label: 'NTLA,  Intellia Therapeutics, Inc.',
    name: 'Intellia Therapeutics, Inc.',
    symbol: 'NTLA',
  },
  {
    value: 'AEHR',
    label: 'AEHR,  Aehr Test Systems',
    name: 'Aehr Test Systems',
    symbol: 'AEHR',
  },
  {
    value: 'BMI',
    label: 'BMI,  Badger Meter, Inc.',
    name: 'Badger Meter, Inc.',
    symbol: 'BMI',
  },
  {
    value: 'CHCT',
    label: 'CHCT,  Community Healthcare Trust Incorporated',
    name: 'Community Healthcare Trust Incorporated',
    symbol: 'CHCT',
  },
  {
    value: 'MSBI',
    label: 'MSBI,  Midland States Bancorp, Inc.',
    name: 'Midland States Bancorp, Inc.',
    symbol: 'MSBI',
  },
  {
    value: 'HLNE',
    label: 'HLNE,  Hamilton Lane Incorporated',
    name: 'Hamilton Lane Incorporated',
    symbol: 'HLNE',
  },
  {
    value: 'XES',
    label: 'XES,  SPDR S&P Oil & Gas Equipment & Services ETF',
    name: 'SPDR S&P Oil & Gas Equipment & Services ETF',
    symbol: 'XES',
  },
  {
    value: 'HHC',
    label: 'HHC,  The Howard Hughes Corporation',
    name: 'The Howard Hughes Corporation',
    symbol: 'HHC',
  },
  {
    value: 'DLS',
    label: 'DLS,  WisdomTree International SmallCap Dividend Fund',
    name: 'WisdomTree International SmallCap Dividend Fund',
    symbol: 'DLS',
  },
  {
    value: 'PMT',
    label: 'PMT,  PennyMac Mortgage Investment Trust',
    name: 'PennyMac Mortgage Investment Trust',
    symbol: 'PMT',
  },
  {
    value: 'APPS',
    label: 'APPS,  Digital Turbine, Inc.',
    name: 'Digital Turbine, Inc.',
    symbol: 'APPS',
  },
  {
    value: 'SHAK',
    label: 'SHAK,  Shake Shack Inc.',
    name: 'Shake Shack Inc.',
    symbol: 'SHAK',
  },
  {
    value: 'GOLF',
    label: 'GOLF,  Acushnet Holdings Corp.',
    name: 'Acushnet Holdings Corp.',
    symbol: 'GOLF',
  },
  {
    value: 'WTTR',
    label: 'WTTR,  Select Energy Services, Inc.',
    name: 'Select Energy Services, Inc.',
    symbol: 'WTTR',
  },
  {
    value: 'VYGR',
    label: 'VYGR,  Voyager Therapeutics, Inc.',
    name: 'Voyager Therapeutics, Inc.',
    symbol: 'VYGR',
  },
  {
    value: 'DOOR',
    label: 'DOOR,  Masonite International Corporation',
    name: 'Masonite International Corporation',
    symbol: 'DOOR',
  },
  {
    value: 'LGND',
    label: 'LGND,  Ligand Pharmaceuticals Incorporated',
    name: 'Ligand Pharmaceuticals Incorporated',
    symbol: 'LGND',
  },
  {
    value: 'CENTA',
    label: 'CENTA,  Central Garden & Pet Company',
    name: 'Central Garden & Pet Company',
    symbol: 'CENTA',
  },
  {
    value: 'EGP',
    label: 'EGP,  EastGroup Properties, Inc.',
    name: 'EastGroup Properties, Inc.',
    symbol: 'EGP',
  },
  {
    value: 'SNPS',
    label: 'SNPS,  Synopsys, Inc.',
    name: 'Synopsys, Inc.',
    symbol: 'SNPS',
  },
  {
    value: 'FCEL',
    label: 'FCEL,  FuelCell Energy, Inc.',
    name: 'FuelCell Energy, Inc.',
    symbol: 'FCEL',
  },
  {
    value: 'PSO',
    label: 'PSO,  Pearson plc',
    name: 'Pearson plc',
    symbol: 'PSO',
  },
  {
    value: 'AOD',
    label: 'AOD,  Abrdn Total Dynamic Dividend Fund',
    name: 'Abrdn Total Dynamic Dividend Fund',
    symbol: 'AOD',
  },
  {
    value: 'EEX',
    label: 'EEX,  Emerald Holding, Inc.',
    name: 'Emerald Holding, Inc.',
    symbol: 'EEX',
  },
  {
    value: 'UIHC',
    label: 'UIHC,  United Insurance Holdings Corp.',
    name: 'United Insurance Holdings Corp.',
    symbol: 'UIHC',
  },
  {
    value: 'VCV',
    label: 'VCV,  Invesco California Value Municipal Income Trust',
    name: 'Invesco California Value Municipal Income Trust',
    symbol: 'VCV',
  },
  {
    value: 'BIO',
    label: 'BIO,  Bio-Rad Laboratories, Inc.',
    name: 'Bio-Rad Laboratories, Inc.',
    symbol: 'BIO',
  },
  {
    value: 'E',
    label: 'E,  Eni S.p.A.',
    name: 'Eni S.p.A.',
    symbol: 'E',
  },
  {
    value: 'KRC',
    label: 'KRC,  Kilroy Realty Corporation',
    name: 'Kilroy Realty Corporation',
    symbol: 'KRC',
  },
  {
    value: 'RVNC',
    label: 'RVNC,  Revance Therapeutics, Inc.',
    name: 'Revance Therapeutics, Inc.',
    symbol: 'RVNC',
  },
  {
    value: 'NATI',
    label: 'NATI,  National Instruments Corporation',
    name: 'National Instruments Corporation',
    symbol: 'NATI',
  },
  {
    value: 'ACCO',
    label: 'ACCO,  ACCO Brands Corporation',
    name: 'ACCO Brands Corporation',
    symbol: 'ACCO',
  },
  {
    value: 'PLXS',
    label: 'PLXS,  Plexus Corp.',
    name: 'Plexus Corp.',
    symbol: 'PLXS',
  },
  {
    value: 'TRNO',
    label: 'TRNO,  Terreno Realty Corporation',
    name: 'Terreno Realty Corporation',
    symbol: 'TRNO',
  },
  {
    value: 'ASGN',
    label: 'ASGN,  ASGN Incorporated',
    name: 'ASGN Incorporated',
    symbol: 'ASGN',
  },
  {
    value: 'CVNA',
    label: 'CVNA,  Carvana Co.',
    name: 'Carvana Co.',
    symbol: 'CVNA',
  },
  {
    value: 'FEN',
    label: 'FEN,  First Trust Energy Income and Growth Fund',
    name: 'First Trust Energy Income and Growth Fund',
    symbol: 'FEN',
  },
  {
    value: 'OMAB',
    label: 'OMAB,  Grupo Aeroportuario del Centro Norte, S.A.B. de C.V.',
    name: 'Grupo Aeroportuario del Centro Norte, S.A.B. de C.V.',
    symbol: 'OMAB',
  },
  {
    value: 'VNOM',
    label: 'VNOM,  Viper Energy Partners LP',
    name: 'Viper Energy Partners LP',
    symbol: 'VNOM',
  },
  {
    value: 'TCPC',
    label: 'TCPC,  BlackRock TCP Capital Corp.',
    name: 'BlackRock TCP Capital Corp.',
    symbol: 'TCPC',
  },
  {
    value: 'IBTX',
    label: 'IBTX,  Independent Bank Group, Inc.',
    name: 'Independent Bank Group, Inc.',
    symbol: 'IBTX',
  },
  {
    value: 'FWONA',
    label: 'FWONA,  Formula One Group',
    name: 'Formula One Group',
    symbol: 'FWONA',
  },
  {
    value: 'GER',
    label: 'GER,  Goldman Sachs MLP and Energy Renaissance Fund',
    name: 'Goldman Sachs MLP and Energy Renaissance Fund',
    symbol: 'GER',
  },
  {
    value: 'QTRX',
    label: 'QTRX,  Quanterix Corporation',
    name: 'Quanterix Corporation',
    symbol: 'QTRX',
  },
  {
    value: 'ATKR',
    label: 'ATKR,  Atkore Inc.',
    name: 'Atkore Inc.',
    symbol: 'ATKR',
  },
  {
    value: 'UHAL',
    label: 'UHAL,  AMERCO',
    name: 'AMERCO',
    symbol: 'UHAL',
  },
  {
    value: 'SJR',
    label: 'SJR,  Shaw Communications Inc.',
    name: 'Shaw Communications Inc.',
    symbol: 'SJR',
  },
  {
    value: 'SA',
    label: 'SA,  Seabridge Gold Inc.',
    name: 'Seabridge Gold Inc.',
    symbol: 'SA',
  },
  {
    value: 'BCOV',
    label: 'BCOV,  Brightcove Inc.',
    name: 'Brightcove Inc.',
    symbol: 'BCOV',
  },
  {
    value: 'CVBF',
    label: 'CVBF,  CVB Financial Corp.',
    name: 'CVB Financial Corp.',
    symbol: 'CVBF',
  },
  {
    value: 'NGVT',
    label: 'NGVT,  Ingevity Corporation',
    name: 'Ingevity Corporation',
    symbol: 'NGVT',
  },
  {
    value: 'BY',
    label: 'BY,  Byline Bancorp, Inc.',
    name: 'Byline Bancorp, Inc.',
    symbol: 'BY',
  },
  {
    value: 'LSXMA',
    label: 'LSXMA,  The Liberty SiriusXM Group',
    name: 'The Liberty SiriusXM Group',
    symbol: 'LSXMA',
  },
  {
    value: 'PATK',
    label: 'PATK,  Patrick Industries, Inc.',
    name: 'Patrick Industries, Inc.',
    symbol: 'PATK',
  },
  {
    value: 'AGQ',
    label: 'AGQ,  ProShares Ultra Silver',
    name: 'ProShares Ultra Silver',
    symbol: 'AGQ',
  },
  {
    value: 'INUV',
    label: 'INUV,  Inuvo, Inc.',
    name: 'Inuvo, Inc.',
    symbol: 'INUV',
  },
  {
    value: 'VBLT',
    label: 'VBLT,  Vascular Biogenics Ltd.',
    name: 'Vascular Biogenics Ltd.',
    symbol: 'VBLT',
  },
  {
    value: 'VMI',
    label: 'VMI,  Valmont Industries, Inc.',
    name: 'Valmont Industries, Inc.',
    symbol: 'VMI',
  },
  {
    value: 'BPMC',
    label: 'BPMC,  Blueprint Medicines Corporation',
    name: 'Blueprint Medicines Corporation',
    symbol: 'BPMC',
  },
  {
    value: 'SASR',
    label: 'SASR,  Sandy Spring Bancorp, Inc.',
    name: 'Sandy Spring Bancorp, Inc.',
    symbol: 'SASR',
  },
  {
    value: 'NBRV',
    label: 'NBRV,  Nabriva Therapeutics plc',
    name: 'Nabriva Therapeutics plc',
    symbol: 'NBRV',
  },
  {
    value: 'PGAL',
    label: 'PGAL,  Global X MSCI Portugal ETF',
    name: 'Global X MSCI Portugal ETF',
    symbol: 'PGAL',
  },
  {
    value: 'VGT',
    label: 'VGT,  Vanguard Information Technology Index Fund',
    name: 'Vanguard Information Technology Index Fund',
    symbol: 'VGT',
  },
  {
    value: 'FCN',
    label: 'FCN,  FTI Consulting, Inc.',
    name: 'FTI Consulting, Inc.',
    symbol: 'FCN',
  },
  {
    value: 'ATOM',
    label: 'ATOM,  Atomera Incorporated',
    name: 'Atomera Incorporated',
    symbol: 'ATOM',
  },
  {
    value: 'WEX',
    label: 'WEX,  WEX Inc.',
    name: 'WEX Inc.',
    symbol: 'WEX',
  },
  {
    value: 'NXST',
    label: 'NXST,  Nexstar Media Group, Inc.',
    name: 'Nexstar Media Group, Inc.',
    symbol: 'NXST',
  },
  {
    value: 'CURO',
    label: 'CURO,  CURO Group Holdings Corp.',
    name: 'CURO Group Holdings Corp.',
    symbol: 'CURO',
  },
  {
    value: 'KODK',
    label: 'KODK,  Eastman Kodak Company',
    name: 'Eastman Kodak Company',
    symbol: 'KODK',
  },
  {
    value: 'MTRN',
    label: 'MTRN,  Materion Corporation',
    name: 'Materion Corporation',
    symbol: 'MTRN',
  },
  {
    value: 'TECH',
    label: 'TECH,  Bio-Techne Corporation',
    name: 'Bio-Techne Corporation',
    symbol: 'TECH',
  },
  {
    value: 'WING',
    label: 'WING,  Wingstop Inc.',
    name: 'Wingstop Inc.',
    symbol: 'WING',
  },
  {
    value: 'HAFC',
    label: 'HAFC,  Hanmi Financial Corporation',
    name: 'Hanmi Financial Corporation',
    symbol: 'HAFC',
  },
  {
    value: 'ADES',
    label: 'ADES,  Advanced Emissions Solutions, Inc.',
    name: 'Advanced Emissions Solutions, Inc.',
    symbol: 'ADES',
  },
  {
    value: 'ATRA',
    label: 'ATRA,  Atara Biotherapeutics, Inc.',
    name: 'Atara Biotherapeutics, Inc.',
    symbol: 'ATRA',
  },
  {
    value: 'BOOM',
    label: 'BOOM,  DMC Global Inc.',
    name: 'DMC Global Inc.',
    symbol: 'BOOM',
  },
  {
    value: 'GLYC',
    label: 'GLYC,  GlycoMimetics, Inc.',
    name: 'GlycoMimetics, Inc.',
    symbol: 'GLYC',
  },
  {
    value: 'SPH',
    label: 'SPH,  Suburban Propane Partners, L.P.',
    name: 'Suburban Propane Partners, L.P.',
    symbol: 'SPH',
  },
  {
    value: 'NEWR',
    label: 'NEWR,  New Relic, Inc.',
    name: 'New Relic, Inc.',
    symbol: 'NEWR',
  },
  {
    value: 'SHV',
    label: 'SHV,  iShares Short Treasury Bond ETF',
    name: 'iShares Short Treasury Bond ETF',
    symbol: 'SHV',
  },
  {
    value: 'FXE',
    label: 'FXE,  Invesco CurrencyShares Euro Currency Trust',
    name: 'Invesco CurrencyShares Euro Currency Trust',
    symbol: 'FXE',
  },
  {
    value: 'BZH',
    label: 'BZH,  Beazer Homes USA, Inc.',
    name: 'Beazer Homes USA, Inc.',
    symbol: 'BZH',
  },
  {
    value: 'PRI',
    label: 'PRI,  Primerica, Inc.',
    name: 'Primerica, Inc.',
    symbol: 'PRI',
  },
  {
    value: 'FTSM',
    label: 'FTSM,  First Trust Enhanced Short Maturity ETF',
    name: 'First Trust Enhanced Short Maturity ETF',
    symbol: 'FTSM',
  },
  {
    value: 'EXLS',
    label: 'EXLS,  ExlService Holdings, Inc.',
    name: 'ExlService Holdings, Inc.',
    symbol: 'EXLS',
  },
  {
    value: 'QLYS',
    label: 'QLYS,  Qualys, Inc.',
    name: 'Qualys, Inc.',
    symbol: 'QLYS',
  },
  {
    value: 'QURE',
    label: 'QURE,  uniQure N.V.',
    name: 'uniQure N.V.',
    symbol: 'QURE',
  },
  {
    value: 'STAR',
    label: 'STAR,  iStar Inc.',
    name: 'iStar Inc.',
    symbol: 'STAR',
  },
  {
    value: 'CLSD',
    label: 'CLSD,  Clearside Biomedical, Inc.',
    name: 'Clearside Biomedical, Inc.',
    symbol: 'CLSD',
  },
  {
    value: 'FNDE',
    label: 'FNDE,  Schwab Fundamental Emerging Markets Large Company Index ETF',
    name: 'Schwab Fundamental Emerging Markets Large Company Index ETF',
    symbol: 'FNDE',
  },
  {
    value: 'ICUI',
    label: 'ICUI,  ICU Medical, Inc.',
    name: 'ICU Medical, Inc.',
    symbol: 'ICUI',
  },
  {
    value: 'WVE',
    label: 'WVE,  Wave Life Sciences Ltd.',
    name: 'Wave Life Sciences Ltd.',
    symbol: 'WVE',
  },
  {
    value: 'MNTX',
    label: 'MNTX,  Manitex International, Inc.',
    name: 'Manitex International, Inc.',
    symbol: 'MNTX',
  },
  {
    value: 'LSTR',
    label: 'LSTR,  Landstar System, Inc.',
    name: 'Landstar System, Inc.',
    symbol: 'LSTR',
  },
  {
    value: 'LTC',
    label: 'LTC,  LTC Properties, Inc.',
    name: 'LTC Properties, Inc.',
    symbol: 'LTC',
  },
  {
    value: 'ELS',
    label: 'ELS,  Equity LifeStyle Properties, Inc.',
    name: 'Equity LifeStyle Properties, Inc.',
    symbol: 'ELS',
  },
  {
    value: 'TGS',
    label: 'TGS,  Transportadora de Gas del Sur S.A.',
    name: 'Transportadora de Gas del Sur S.A.',
    symbol: 'TGS',
  },
  {
    value: 'VGZ',
    label: 'VGZ,  Vista Gold Corp.',
    name: 'Vista Gold Corp.',
    symbol: 'VGZ',
  },
  {
    value: 'IGHG',
    label: 'IGHG,  ProShares Investment Grade—Interest Rate Hedged',
    name: 'ProShares Investment Grade—Interest Rate Hedged',
    symbol: 'IGHG',
  },
  {
    value: 'NAK',
    label: 'NAK,  Northern Dynasty Minerals Ltd.',
    name: 'Northern Dynasty Minerals Ltd.',
    symbol: 'NAK',
  },
  {
    value: 'ASIX',
    label: 'ASIX,  AdvanSix Inc.',
    name: 'AdvanSix Inc.',
    symbol: 'ASIX',
  },
  {
    value: 'SYBX',
    label: 'SYBX,  Synlogic, Inc.',
    name: 'Synlogic, Inc.',
    symbol: 'SYBX',
  },
  {
    value: 'TEO',
    label: 'TEO,  Telecom Argentina S.A.',
    name: 'Telecom Argentina S.A.',
    symbol: 'TEO',
  },
  {
    value: 'WASH',
    label: 'WASH,  Washington Trust Bancorp, Inc.',
    name: 'Washington Trust Bancorp, Inc.',
    symbol: 'WASH',
  },
  {
    value: 'RMNI',
    label: 'RMNI,  Rimini Street, Inc.',
    name: 'Rimini Street, Inc.',
    symbol: 'RMNI',
  },
  {
    value: 'LMAT',
    label: 'LMAT,  LeMaitre Vascular, Inc.',
    name: 'LeMaitre Vascular, Inc.',
    symbol: 'LMAT',
  },
  {
    value: 'ZEPP',
    label: 'ZEPP,  Zepp Health Corporation',
    name: 'Zepp Health Corporation',
    symbol: 'ZEPP',
  },
  {
    value: 'SRT',
    label: 'SRT,  Startek, Inc.',
    name: 'Startek, Inc.',
    symbol: 'SRT',
  },
  {
    value: 'CBZ',
    label: 'CBZ,  CBIZ, Inc.',
    name: 'CBIZ, Inc.',
    symbol: 'CBZ',
  },
  {
    value: 'BLRX',
    label: 'BLRX,  BioLineRx Ltd.',
    name: 'BioLineRx Ltd.',
    symbol: 'BLRX',
  },
  {
    value: 'KIRK',
    label: "KIRK,  Kirkland's, Inc.",
    name: "Kirkland's, Inc.",
    symbol: 'KIRK',
  },
  {
    value: 'CDZI',
    label: 'CDZI,  Cadiz Inc.',
    name: 'Cadiz Inc.',
    symbol: 'CDZI',
  },
  {
    value: 'SAIA',
    label: 'SAIA,  Saia, Inc.',
    name: 'Saia, Inc.',
    symbol: 'SAIA',
  },
  {
    value: 'CRMD',
    label: 'CRMD,  CorMedix Inc.',
    name: 'CorMedix Inc.',
    symbol: 'CRMD',
  },
  {
    value: 'PIN',
    label: 'PIN,  Invesco India ETF',
    name: 'Invesco India ETF',
    symbol: 'PIN',
  },
  {
    value: 'RQI',
    label: 'RQI,  Cohen & Steers Quality Income Realty Fund, Inc.',
    name: 'Cohen & Steers Quality Income Realty Fund, Inc.',
    symbol: 'RQI',
  },
  {
    value: 'THM',
    label: 'THM,  International Tower Hill Mines Ltd.',
    name: 'International Tower Hill Mines Ltd.',
    symbol: 'THM',
  },
  {
    value: 'SHY',
    label: 'SHY,  iShares 1-3 Year Treasury Bond ETF',
    name: 'iShares 1-3 Year Treasury Bond ETF',
    symbol: 'SHY',
  },
  {
    value: 'RRTS',
    label: 'RRTS,  Roadrunner Transportation Systems, Inc.',
    name: 'Roadrunner Transportation Systems, Inc.',
    symbol: 'RRTS',
  },
  {
    value: 'OGS',
    label: 'OGS,  ONE Gas, Inc.',
    name: 'ONE Gas, Inc.',
    symbol: 'OGS',
  },
  {
    value: 'RWO',
    label: 'RWO,  SPDR Dow Jones Global Real Estate ETF',
    name: 'SPDR Dow Jones Global Real Estate ETF',
    symbol: 'RWO',
  },
  {
    value: 'AY',
    label: 'AY,  Atlantica Sustainable Infrastructure plc',
    name: 'Atlantica Sustainable Infrastructure plc',
    symbol: 'AY',
  },
  {
    value: 'VSS',
    label: 'VSS,  Vanguard FTSE All-World ex-US Small Capital Index Fund',
    name: 'Vanguard FTSE All-World ex-US Small Capital Index Fund',
    symbol: 'VSS',
  },
  {
    value: 'SCHM',
    label: 'SCHM,  Schwab U.S. Mid-Cap ETF',
    name: 'Schwab U.S. Mid-Cap ETF',
    symbol: 'SCHM',
  },
  {
    value: 'CIO',
    label: 'CIO,  City Office REIT, Inc.',
    name: 'City Office REIT, Inc.',
    symbol: 'CIO',
  },
  {
    value: 'THD',
    label: 'THD,  iShares MSCI Thailand ETF',
    name: 'iShares MSCI Thailand ETF',
    symbol: 'THD',
  },
  {
    value: 'ADUS',
    label: 'ADUS,  Addus HomeCare Corporation',
    name: 'Addus HomeCare Corporation',
    symbol: 'ADUS',
  },
  {
    value: 'FTEC',
    label: 'FTEC,  Fidelity MSCI Information Technology Index ETF',
    name: 'Fidelity MSCI Information Technology Index ETF',
    symbol: 'FTEC',
  },
  {
    value: 'LBAI',
    label: 'LBAI,  Lakeland Bancorp, Inc.',
    name: 'Lakeland Bancorp, Inc.',
    symbol: 'LBAI',
  },
  {
    value: 'NWE',
    label: 'NWE,  NorthWestern Corporation',
    name: 'NorthWestern Corporation',
    symbol: 'NWE',
  },
  {
    value: 'GOL',
    label: 'GOL,  Gol Linhas Aéreas Inteligentes S.A.',
    name: 'Gol Linhas Aéreas Inteligentes S.A.',
    symbol: 'GOL',
  },
  {
    value: 'ELF',
    label: 'ELF,  e.l.f. Beauty, Inc.',
    name: 'e.l.f. Beauty, Inc.',
    symbol: 'ELF',
  },
  {
    value: 'VAC',
    label: 'VAC,  Marriott Vacations Worldwide Corporation',
    name: 'Marriott Vacations Worldwide Corporation',
    symbol: 'VAC',
  },
  {
    value: 'OMCL',
    label: 'OMCL,  Omnicell, Inc.',
    name: 'Omnicell, Inc.',
    symbol: 'OMCL',
  },
  {
    value: 'UFPI',
    label: 'UFPI,  UFP Industries, Inc.',
    name: 'UFP Industries, Inc.',
    symbol: 'UFPI',
  },
  {
    value: 'IBOC',
    label: 'IBOC,  International Bancshares Corporation',
    name: 'International Bancshares Corporation',
    symbol: 'IBOC',
  },
  {
    value: 'CSQ',
    label: 'CSQ,  Calamos Strategic Total Return Fund',
    name: 'Calamos Strategic Total Return Fund',
    symbol: 'CSQ',
  },
  {
    value: 'HELE',
    label: 'HELE,  Helen of Troy Limited',
    name: 'Helen of Troy Limited',
    symbol: 'HELE',
  },
  {
    value: 'IIF',
    label: 'IIF,  Morgan Stanley India Investment Fund, Inc.',
    name: 'Morgan Stanley India Investment Fund, Inc.',
    symbol: 'IIF',
  },
  {
    value: 'NIB',
    label: 'NIB,  iPath Bloomberg Cocoa Subindex Total Return(SM) ETN',
    name: 'iPath Bloomberg Cocoa Subindex Total Return(SM) ETN',
    symbol: 'NIB',
  },
  {
    value: 'AIR',
    label: 'AIR,  AAR Corp.',
    name: 'AAR Corp.',
    symbol: 'AIR',
  },
  {
    value: 'MINT',
    label: 'MINT,  PIMCO Enhanced Short Maturity Active Exchange-Traded Fund',
    name: 'PIMCO Enhanced Short Maturity Active Exchange-Traded Fund',
    symbol: 'MINT',
  },
  {
    value: 'EBR',
    label: 'EBR,  Centrais Elétricas Brasileiras S.A. - Eletrobrás',
    name: 'Centrais Elétricas Brasileiras S.A. - Eletrobrás',
    symbol: 'EBR',
  },
  {
    value: 'TRVG',
    label: 'TRVG,  trivago N.V.',
    name: 'trivago N.V.',
    symbol: 'TRVG',
  },
  {
    value: 'SIEN',
    label: 'SIEN,  Sientra, Inc.',
    name: 'Sientra, Inc.',
    symbol: 'SIEN',
  },
  {
    value: 'HASI',
    label: 'HASI,  Hannon Armstrong Sustainable Infrastructure Capital, Inc.',
    name: 'Hannon Armstrong Sustainable Infrastructure Capital, Inc.',
    symbol: 'HASI',
  },
  {
    value: 'CVI',
    label: 'CVI,  CVR Energy, Inc.',
    name: 'CVR Energy, Inc.',
    symbol: 'CVI',
  },
  {
    value: 'CPLP',
    label: 'CPLP,  Capital Product Partners L.P.',
    name: 'Capital Product Partners L.P.',
    symbol: 'CPLP',
  },
  {
    value: 'SPAB',
    label: 'SPAB,  SPDR Portfolio Aggregate Bond ETF',
    name: 'SPDR Portfolio Aggregate Bond ETF',
    symbol: 'SPAB',
  },
  {
    value: 'AWF',
    label: 'AWF,  AllianceBernstein Global High Income Fund',
    name: 'AllianceBernstein Global High Income Fund',
    symbol: 'AWF',
  },
  {
    value: 'FBK',
    label: 'FBK,  FB Financial Corporation',
    name: 'FB Financial Corporation',
    symbol: 'FBK',
  },
  {
    value: 'IYZ',
    label: 'IYZ,  iShares U.S. Telecommunications ETF',
    name: 'iShares U.S. Telecommunications ETF',
    symbol: 'IYZ',
  },
  {
    value: 'FAX',
    label: 'FAX,  Abrdn Asia-Pacific Income Fund Inc',
    name: 'Abrdn Asia-Pacific Income Fund Inc',
    symbol: 'FAX',
  },
  {
    value: 'SHYG',
    label: 'SHYG,  iShares 0-5 Year High Yield Corporate Bond ETF',
    name: 'iShares 0-5 Year High Yield Corporate Bond ETF',
    symbol: 'SHYG',
  },
  {
    value: 'NICE',
    label: 'NICE,  NICE Ltd.',
    name: 'NICE Ltd.',
    symbol: 'NICE',
  },
  {
    value: 'KALU',
    label: 'KALU,  Kaiser Aluminum Corporation',
    name: 'Kaiser Aluminum Corporation',
    symbol: 'KALU',
  },
  {
    value: 'RELX',
    label: 'RELX,  RELX PLC',
    name: 'RELX PLC',
    symbol: 'RELX',
  },
  {
    value: 'CAMP',
    label: 'CAMP,  CalAmp Corp.',
    name: 'CalAmp Corp.',
    symbol: 'CAMP',
  },
  {
    value: 'ASPS',
    label: 'ASPS,  Altisource Portfolio Solutions S.A.',
    name: 'Altisource Portfolio Solutions S.A.',
    symbol: 'ASPS',
  },
  {
    value: 'RGS',
    label: 'RGS,  Regis Corporation',
    name: 'Regis Corporation',
    symbol: 'RGS',
  },
  {
    value: 'BNO',
    label: 'BNO,  United States Brent Oil Fund, LP',
    name: 'United States Brent Oil Fund, LP',
    symbol: 'BNO',
  },
  {
    value: 'ACWV',
    label: 'ACWV,  iShares MSCI Global Min Vol Factor ETF',
    name: 'iShares MSCI Global Min Vol Factor ETF',
    symbol: 'ACWV',
  },
  {
    value: 'ITGR',
    label: 'ITGR,  Integer Holdings Corporation',
    name: 'Integer Holdings Corporation',
    symbol: 'ITGR',
  },
  {
    value: 'CGBD',
    label: 'CGBD,  Carlyle Secured Lending, Inc.',
    name: 'Carlyle Secured Lending, Inc.',
    symbol: 'CGBD',
  },
  {
    value: 'EWN',
    label: 'EWN,  iShares MSCI Netherlands ETF',
    name: 'iShares MSCI Netherlands ETF',
    symbol: 'EWN',
  },
  {
    value: 'RGEN',
    label: 'RGEN,  Repligen Corporation',
    name: 'Repligen Corporation',
    symbol: 'RGEN',
  },
  {
    value: 'KRO',
    label: 'KRO,  Kronos Worldwide, Inc.',
    name: 'Kronos Worldwide, Inc.',
    symbol: 'KRO',
  },
  {
    value: 'SB',
    label: 'SB,  Safe Bulkers, Inc.',
    name: 'Safe Bulkers, Inc.',
    symbol: 'SB',
  },
  {
    value: 'AOSL',
    label: 'AOSL,  Alpha and Omega Semiconductor Limited',
    name: 'Alpha and Omega Semiconductor Limited',
    symbol: 'AOSL',
  },
  {
    value: 'CVGW',
    label: 'CVGW,  Calavo Growers, Inc.',
    name: 'Calavo Growers, Inc.',
    symbol: 'CVGW',
  },
  {
    value: 'DSKE',
    label: 'DSKE,  Daseke, Inc.',
    name: 'Daseke, Inc.',
    symbol: 'DSKE',
  },
  {
    value: 'DGICA',
    label: 'DGICA,  Donegal Group Inc.',
    name: 'Donegal Group Inc.',
    symbol: 'DGICA',
  },
  {
    value: 'CCO',
    label: 'CCO,  Clear Channel Outdoor Holdings, Inc.',
    name: 'Clear Channel Outdoor Holdings, Inc.',
    symbol: 'CCO',
  },
  {
    value: 'GXC',
    label: 'GXC,  SPDR S&P China ETF',
    name: 'SPDR S&P China ETF',
    symbol: 'GXC',
  },
  {
    value: 'SILC',
    label: 'SILC,  Silicom Ltd.',
    name: 'Silicom Ltd.',
    symbol: 'SILC',
  },
  {
    value: 'RGLD',
    label: 'RGLD,  Royal Gold, Inc.',
    name: 'Royal Gold, Inc.',
    symbol: 'RGLD',
  },
  {
    value: 'GBDC',
    label: 'GBDC,  Golub Capital BDC, Inc.',
    name: 'Golub Capital BDC, Inc.',
    symbol: 'GBDC',
  },
  {
    value: 'IFGL',
    label: 'IFGL,  iShares International Developed Real Estate ETF',
    name: 'iShares International Developed Real Estate ETF',
    symbol: 'IFGL',
  },
  {
    value: 'EBS',
    label: 'EBS,  Emergent BioSolutions Inc.',
    name: 'Emergent BioSolutions Inc.',
    symbol: 'EBS',
  },
  {
    value: 'EURN',
    label: 'EURN,  Euronav NV',
    name: 'Euronav NV',
    symbol: 'EURN',
  },
  {
    value: 'QTWO',
    label: 'QTWO,  Q2 Holdings, Inc.',
    name: 'Q2 Holdings, Inc.',
    symbol: 'QTWO',
  },
  {
    value: 'SNLN',
    label: 'SNLN,  Highland/iBoxx Senior Loan ETF',
    name: 'Highland/iBoxx Senior Loan ETF',
    symbol: 'SNLN',
  },
  {
    value: 'SAGE',
    label: 'SAGE,  Sage Therapeutics, Inc.',
    name: 'Sage Therapeutics, Inc.',
    symbol: 'SAGE',
  },
  {
    value: 'NOA',
    label: 'NOA,  North American Construction Group Ltd.',
    name: 'North American Construction Group Ltd.',
    symbol: 'NOA',
  },
  {
    value: 'APVO',
    label: 'APVO,  Aptevo Therapeutics Inc.',
    name: 'Aptevo Therapeutics Inc.',
    symbol: 'APVO',
  },
  {
    value: 'CALX',
    label: 'CALX,  Calix, Inc.',
    name: 'Calix, Inc.',
    symbol: 'CALX',
  },
  {
    value: 'PLAB',
    label: 'PLAB,  Photronics, Inc.',
    name: 'Photronics, Inc.',
    symbol: 'PLAB',
  },
  {
    value: 'AGYS',
    label: 'AGYS,  Agilysys, Inc.',
    name: 'Agilysys, Inc.',
    symbol: 'AGYS',
  },
  {
    value: 'AHT',
    label: 'AHT,  Ashford Hospitality Trust, Inc.',
    name: 'Ashford Hospitality Trust, Inc.',
    symbol: 'AHT',
  },
  {
    value: 'CALA',
    label: 'CALA,  Calithera Biosciences, Inc.',
    name: 'Calithera Biosciences, Inc.',
    symbol: 'CALA',
  },
  {
    value: 'TGH',
    label: 'TGH,  Textainer Group Holdings Limited',
    name: 'Textainer Group Holdings Limited',
    symbol: 'TGH',
  },
  {
    value: 'CG',
    label: 'CG,  The Carlyle Group Inc.',
    name: 'The Carlyle Group Inc.',
    symbol: 'CG',
  },
  {
    value: 'EVA',
    label: 'EVA,  Enviva Inc.',
    name: 'Enviva Inc.',
    symbol: 'EVA',
  },
  {
    value: 'RPV',
    label: 'RPV,  Invesco S&P 500 Pure Value ETF',
    name: 'Invesco S&P 500 Pure Value ETF',
    symbol: 'RPV',
  },
  {
    value: 'ATR',
    label: 'ATR,  AptarGroup, Inc.',
    name: 'AptarGroup, Inc.',
    symbol: 'ATR',
  },
  {
    value: 'TRST',
    label: 'TRST,  TrustCo Bank Corp NY',
    name: 'TrustCo Bank Corp NY',
    symbol: 'TRST',
  },
  {
    value: 'TEDU',
    label: 'TEDU,  Tarena International, Inc.',
    name: 'Tarena International, Inc.',
    symbol: 'TEDU',
  },
  {
    value: 'FWRD',
    label: 'FWRD,  Forward Air Corporation',
    name: 'Forward Air Corporation',
    symbol: 'FWRD',
  },
  {
    value: 'ABG',
    label: 'ABG,  Asbury Automotive Group, Inc.',
    name: 'Asbury Automotive Group, Inc.',
    symbol: 'ABG',
  },
  {
    value: 'LBC',
    label: 'LBC,  Luther Burbank Corporation',
    name: 'Luther Burbank Corporation',
    symbol: 'LBC',
  },
  {
    value: 'MARA',
    label: 'MARA,  Marathon Digital Holdings, Inc.',
    name: 'Marathon Digital Holdings, Inc.',
    symbol: 'MARA',
  },
  {
    value: 'DS',
    label: 'DS,  Drive Shack Inc.',
    name: 'Drive Shack Inc.',
    symbol: 'DS',
  },
  {
    value: 'FLIC',
    label: 'FLIC,  The First of Long Island Corporation',
    name: 'The First of Long Island Corporation',
    symbol: 'FLIC',
  },
  {
    value: 'ARDX',
    label: 'ARDX,  Ardelyx, Inc.',
    name: 'Ardelyx, Inc.',
    symbol: 'ARDX',
  },
  {
    value: 'ARR',
    label: 'ARR,  ARMOUR Residential REIT, Inc.',
    name: 'ARMOUR Residential REIT, Inc.',
    symbol: 'ARR',
  },
  {
    value: 'ADAP',
    label: 'ADAP,  Adaptimmune Therapeutics plc',
    name: 'Adaptimmune Therapeutics plc',
    symbol: 'ADAP',
  },
  {
    value: 'BIL',
    label: 'BIL,  SPDR Bloomberg 1-3 Month T-Bill ETF',
    name: 'SPDR Bloomberg 1-3 Month T-Bill ETF',
    symbol: 'BIL',
  },
  {
    value: 'ATGE',
    label: 'ATGE,  Adtalem Global Education Inc.',
    name: 'Adtalem Global Education Inc.',
    symbol: 'ATGE',
  },
  {
    value: 'WHD',
    label: 'WHD,  Cactus, Inc.',
    name: 'Cactus, Inc.',
    symbol: 'WHD',
  },
  {
    value: 'BSV',
    label: 'BSV,  Vanguard Short-Term Bond Index Fund',
    name: 'Vanguard Short-Term Bond Index Fund',
    symbol: 'BSV',
  },
  {
    value: 'LECO',
    label: 'LECO,  Lincoln Electric Holdings, Inc.',
    name: 'Lincoln Electric Holdings, Inc.',
    symbol: 'LECO',
  },
  {
    value: 'PID',
    label: 'PID,  Invesco International Dividend Achievers ETF',
    name: 'Invesco International Dividend Achievers ETF',
    symbol: 'PID',
  },
  {
    value: 'TRVN',
    label: 'TRVN,  Trevena, Inc.',
    name: 'Trevena, Inc.',
    symbol: 'TRVN',
  },
  {
    value: 'JPS',
    label: 'JPS,  Nuveen Preferred Securities Income Fund',
    name: 'Nuveen Preferred Securities Income Fund',
    symbol: 'JPS',
  },
  {
    value: 'SPHQ',
    label: 'SPHQ,  Invesco S&P 500 Quality ETF',
    name: 'Invesco S&P 500 Quality ETF',
    symbol: 'SPHQ',
  },
  {
    value: 'CATO',
    label: 'CATO,  The Cato Corporation',
    name: 'The Cato Corporation',
    symbol: 'CATO',
  },
  {
    value: 'SGOL',
    label: 'SGOL,  abrdn Physical Gold Shares ETF',
    name: 'abrdn Physical Gold Shares ETF',
    symbol: 'SGOL',
  },
  {
    value: 'MTX',
    label: 'MTX,  Minerals Technologies Inc.',
    name: 'Minerals Technologies Inc.',
    symbol: 'MTX',
  },
  {
    value: 'BOE',
    label: 'BOE,  BlackRock Enhanced Global Dividend Trust',
    name: 'BlackRock Enhanced Global Dividend Trust',
    symbol: 'BOE',
  },
  {
    value: 'FENG',
    label: 'FENG,  Phoenix New Media Limited',
    name: 'Phoenix New Media Limited',
    symbol: 'FENG',
  },
  {
    value: 'TREX',
    label: 'TREX,  Trex Company, Inc.',
    name: 'Trex Company, Inc.',
    symbol: 'TREX',
  },
  {
    value: 'FTS',
    label: 'FTS,  Fortis Inc.',
    name: 'Fortis Inc.',
    symbol: 'FTS',
  },
  {
    value: 'VV',
    label: 'VV,  Vanguard Large Cap Index Fund',
    name: 'Vanguard Large Cap Index Fund',
    symbol: 'VV',
  },
  {
    value: 'CCS',
    label: 'CCS,  Century Communities, Inc.',
    name: 'Century Communities, Inc.',
    symbol: 'CCS',
  },
  {
    value: 'CHUY',
    label: "CHUY,  Chuy's Holdings, Inc.",
    name: "Chuy's Holdings, Inc.",
    symbol: 'CHUY',
  },
  {
    value: 'FDN',
    label: 'FDN,  First Trust Dow Jones Internet Index Fund',
    name: 'First Trust Dow Jones Internet Index Fund',
    symbol: 'FDN',
  },
  {
    value: 'CLB',
    label: 'CLB,  Core Laboratories N.V.',
    name: 'Core Laboratories N.V.',
    symbol: 'CLB',
  },
  {
    value: 'CTRN',
    label: 'CTRN,  Citi Trends, Inc.',
    name: 'Citi Trends, Inc.',
    symbol: 'CTRN',
  },
  {
    value: 'VSTM',
    label: 'VSTM,  Verastem, Inc.',
    name: 'Verastem, Inc.',
    symbol: 'VSTM',
  },
  {
    value: 'UTI',
    label: 'UTI,  Universal Technical Institute, Inc.',
    name: 'Universal Technical Institute, Inc.',
    symbol: 'UTI',
  },
  {
    value: 'INTT',
    label: 'INTT,  inTEST Corporation',
    name: 'inTEST Corporation',
    symbol: 'INTT',
  },
  {
    value: 'BGB',
    label: 'BGB,  Blackstone/GSO Strategic Credit Fund',
    name: 'Blackstone/GSO Strategic Credit Fund',
    symbol: 'BGB',
  },
  {
    value: 'IBP',
    label: 'IBP,  Installed Building Products, Inc.',
    name: 'Installed Building Products, Inc.',
    symbol: 'IBP',
  },
  {
    value: 'CENT',
    label: 'CENT,  Central Garden & Pet Company',
    name: 'Central Garden & Pet Company',
    symbol: 'CENT',
  },
  {
    value: 'SPTN',
    label: 'SPTN,  SpartanNash Company',
    name: 'SpartanNash Company',
    symbol: 'SPTN',
  },
  {
    value: 'NML',
    label: 'NML,  Neuberger Berman MLP and Energy Income Fund Inc.',
    name: 'Neuberger Berman MLP and Energy Income Fund Inc.',
    symbol: 'NML',
  },
  {
    value: 'NSIT',
    label: 'NSIT,  Insight Enterprises, Inc.',
    name: 'Insight Enterprises, Inc.',
    symbol: 'NSIT',
  },
  {
    value: 'AMRS',
    label: 'AMRS,  Amyris, Inc.',
    name: 'Amyris, Inc.',
    symbol: 'AMRS',
  },
  {
    value: 'EQBK',
    label: 'EQBK,  Equity Bancshares, Inc.',
    name: 'Equity Bancshares, Inc.',
    symbol: 'EQBK',
  },
  {
    value: 'SEAC',
    label: 'SEAC,  SeaChange International, Inc.',
    name: 'SeaChange International, Inc.',
    symbol: 'SEAC',
  },
  {
    value: 'RNGR',
    label: 'RNGR,  Ranger Energy Services, Inc.',
    name: 'Ranger Energy Services, Inc.',
    symbol: 'RNGR',
  },
  {
    value: 'ACWX',
    label: 'ACWX,  iShares MSCI ACWI ex U.S. ETF',
    name: 'iShares MSCI ACWI ex U.S. ETF',
    symbol: 'ACWX',
  },
  {
    value: 'ARGX',
    label: 'ARGX,  argenx SE',
    name: 'argenx SE',
    symbol: 'ARGX',
  },
  {
    value: 'RACE',
    label: 'RACE,  Ferrari N.V.',
    name: 'Ferrari N.V.',
    symbol: 'RACE',
  },
  {
    value: 'STKL',
    label: 'STKL,  SunOpta Inc.',
    name: 'SunOpta Inc.',
    symbol: 'STKL',
  },
  {
    value: 'VFH',
    label: 'VFH,  Vanguard Financials Index Fund',
    name: 'Vanguard Financials Index Fund',
    symbol: 'VFH',
  },
  {
    value: 'ROL',
    label: 'ROL,  Rollins, Inc.',
    name: 'Rollins, Inc.',
    symbol: 'ROL',
  },
  {
    value: 'KOP',
    label: 'KOP,  Koppers Holdings Inc.',
    name: 'Koppers Holdings Inc.',
    symbol: 'KOP',
  },
  {
    value: 'STAA',
    label: 'STAA,  STAAR Surgical Company',
    name: 'STAAR Surgical Company',
    symbol: 'STAA',
  },
  {
    value: 'LFUS',
    label: 'LFUS,  Littelfuse, Inc.',
    name: 'Littelfuse, Inc.',
    symbol: 'LFUS',
  },
  {
    value: 'CYBR',
    label: 'CYBR,  CyberArk Software Ltd.',
    name: 'CyberArk Software Ltd.',
    symbol: 'CYBR',
  },
  {
    value: 'APOG',
    label: 'APOG,  Apogee Enterprises, Inc.',
    name: 'Apogee Enterprises, Inc.',
    symbol: 'APOG',
  },
  {
    value: 'MPWR',
    label: 'MPWR,  Monolithic Power Systems, Inc.',
    name: 'Monolithic Power Systems, Inc.',
    symbol: 'MPWR',
  },
  {
    value: 'MBIN',
    label: 'MBIN,  Merchants Bancorp',
    name: 'Merchants Bancorp',
    symbol: 'MBIN',
  },
  {
    value: 'HI',
    label: 'HI,  Hillenbrand, Inc.',
    name: 'Hillenbrand, Inc.',
    symbol: 'HI',
  },
  {
    value: 'PZA',
    label: 'PZA,  Invesco National AMT-Free Municipal Bond ETF',
    name: 'Invesco National AMT-Free Municipal Bond ETF',
    symbol: 'PZA',
  },
  {
    value: 'FELE',
    label: 'FELE,  Franklin Electric Co., Inc.',
    name: 'Franklin Electric Co., Inc.',
    symbol: 'FELE',
  },
  {
    value: 'SSB',
    label: 'SSB,  SouthState Corporation',
    name: 'SouthState Corporation',
    symbol: 'SSB',
  },
  {
    value: 'BTCM',
    label: 'BTCM,  BIT Mining Limited',
    name: 'BIT Mining Limited',
    symbol: 'BTCM',
  },
  {
    value: 'ADMA',
    label: 'ADMA,  ADMA Biologics, Inc.',
    name: 'ADMA Biologics, Inc.',
    symbol: 'ADMA',
  },
  {
    value: 'ETY',
    label: 'ETY,  Eaton Vance Tax-Managed Diversified Equity Income Fund',
    name: 'Eaton Vance Tax-Managed Diversified Equity Income Fund',
    symbol: 'ETY',
  },
  {
    value: 'GLP',
    label: 'GLP,  Global Partners LP',
    name: 'Global Partners LP',
    symbol: 'GLP',
  },
  {
    value: 'ICLN',
    label: 'ICLN,  iShares Global Clean Energy ETF',
    name: 'iShares Global Clean Energy ETF',
    symbol: 'ICLN',
  },
  {
    value: 'ITRN',
    label: 'ITRN,  Ituran Location and Control Ltd.',
    name: 'Ituran Location and Control Ltd.',
    symbol: 'ITRN',
  },
  {
    value: 'MAIN',
    label: 'MAIN,  Main Street Capital Corporation',
    name: 'Main Street Capital Corporation',
    symbol: 'MAIN',
  },
  {
    value: 'LANC',
    label: 'LANC,  Lancaster Colony Corporation',
    name: 'Lancaster Colony Corporation',
    symbol: 'LANC',
  },
  {
    value: 'CHDN',
    label: 'CHDN,  Churchill Downs Incorporated',
    name: 'Churchill Downs Incorporated',
    symbol: 'CHDN',
  },
  {
    value: 'DQ',
    label: 'DQ,  Daqo New Energy Corp.',
    name: 'Daqo New Energy Corp.',
    symbol: 'DQ',
  },
  {
    value: 'TYL',
    label: 'TYL,  Tyler Technologies, Inc.',
    name: 'Tyler Technologies, Inc.',
    symbol: 'TYL',
  },
  {
    value: 'AKTS',
    label: 'AKTS,  Akoustis Technologies, Inc.',
    name: 'Akoustis Technologies, Inc.',
    symbol: 'AKTS',
  },
  {
    value: 'SLP',
    label: 'SLP,  Simulations Plus, Inc.',
    name: 'Simulations Plus, Inc.',
    symbol: 'SLP',
  },
  {
    value: 'RICK',
    label: 'RICK,  RCI Hospitality Holdings, Inc.',
    name: 'RCI Hospitality Holdings, Inc.',
    symbol: 'RICK',
  },
  {
    value: 'AVAL',
    label: 'AVAL,  Grupo Aval Acciones y Valores S.A.',
    name: 'Grupo Aval Acciones y Valores S.A.',
    symbol: 'AVAL',
  },
  {
    value: 'ENTA',
    label: 'ENTA,  Enanta Pharmaceuticals, Inc.',
    name: 'Enanta Pharmaceuticals, Inc.',
    symbol: 'ENTA',
  },
  {
    value: 'DJP',
    label: 'DJP,  iPath Bloomberg Commodity Index Total Return(SM) ETN',
    name: 'iPath Bloomberg Commodity Index Total Return(SM) ETN',
    symbol: 'DJP',
  },
  {
    value: 'EXPO',
    label: 'EXPO,  Exponent, Inc.',
    name: 'Exponent, Inc.',
    symbol: 'EXPO',
  },
  {
    value: 'IXC',
    label: 'IXC,  iShares Global Energy ETF',
    name: 'iShares Global Energy ETF',
    symbol: 'IXC',
  },
  {
    value: 'INDY',
    label: 'INDY,  iShares India 50 ETF',
    name: 'iShares India 50 ETF',
    symbol: 'INDY',
  },
  {
    value: 'FRGI',
    label: 'FRGI,  Fiesta Restaurant Group, Inc.',
    name: 'Fiesta Restaurant Group, Inc.',
    symbol: 'FRGI',
  },
  {
    value: 'HBIO',
    label: 'HBIO,  Harvard Bioscience, Inc.',
    name: 'Harvard Bioscience, Inc.',
    symbol: 'HBIO',
  },
  {
    value: 'DAKT',
    label: 'DAKT,  Daktronics, Inc.',
    name: 'Daktronics, Inc.',
    symbol: 'DAKT',
  },
  {
    value: 'EFG',
    label: 'EFG,  iShares MSCI EAFE Growth ETF',
    name: 'iShares MSCI EAFE Growth ETF',
    symbol: 'EFG',
  },
  {
    value: 'AAON',
    label: 'AAON,  AAON, Inc.',
    name: 'AAON, Inc.',
    symbol: 'AAON',
  },
  {
    value: 'SKY',
    label: 'SKY,  Skyline Champion Corporation',
    name: 'Skyline Champion Corporation',
    symbol: 'SKY',
  },
  {
    value: 'FPL',
    label: 'FPL,  First Trust New Opportunities MLP & Energy Fund',
    name: 'First Trust New Opportunities MLP & Energy Fund',
    symbol: 'FPL',
  },
  {
    value: 'NSTG',
    label: 'NSTG,  NanoString Technologies, Inc.',
    name: 'NanoString Technologies, Inc.',
    symbol: 'NSTG',
  },
  {
    value: 'MOV',
    label: 'MOV,  Movado Group, Inc.',
    name: 'Movado Group, Inc.',
    symbol: 'MOV',
  },
  {
    value: 'JRO',
    label: 'JRO,  Nuveen Floating Rate Income Opportunity Fund',
    name: 'Nuveen Floating Rate Income Opportunity Fund',
    symbol: 'JRO',
  },
  {
    value: 'CDMO',
    label: 'CDMO,  Avid Bioservices, Inc.',
    name: 'Avid Bioservices, Inc.',
    symbol: 'CDMO',
  },
  {
    value: 'ARC',
    label: 'ARC,  ARC Document Solutions, Inc.',
    name: 'ARC Document Solutions, Inc.',
    symbol: 'ARC',
  },
  {
    value: 'KFY',
    label: 'KFY,  Korn Ferry',
    name: 'Korn Ferry',
    symbol: 'KFY',
  },
  {
    value: 'CQQQ',
    label: 'CQQQ,  Invesco China Technology ETF',
    name: 'Invesco China Technology ETF',
    symbol: 'CQQQ',
  },
  {
    value: 'GIB',
    label: 'GIB,  CGI Inc.',
    name: 'CGI Inc.',
    symbol: 'GIB',
  },
  {
    value: 'MDY',
    label: 'MDY,  SPDR S&P MIDCAP 400 ETF Trust',
    name: 'SPDR S&P MIDCAP 400 ETF Trust',
    symbol: 'MDY',
  },
  {
    value: 'DFIN',
    label: 'DFIN,  Donnelley Financial Solutions, Inc.',
    name: 'Donnelley Financial Solutions, Inc.',
    symbol: 'DFIN',
  },
  {
    value: 'NNI',
    label: 'NNI,  Nelnet, Inc.',
    name: 'Nelnet, Inc.',
    symbol: 'NNI',
  },
  {
    value: 'COGT',
    label: 'COGT,  Cogent Biosciences, Inc.',
    name: 'Cogent Biosciences, Inc.',
    symbol: 'COGT',
  },
  {
    value: 'EVF',
    label: 'EVF,  Eaton Vance Senior Income Trust',
    name: 'Eaton Vance Senior Income Trust',
    symbol: 'EVF',
  },
  {
    value: 'IBDD',
    label: 'IBDD,  iShares iBonds Mar 2023 Term Corporate ETF',
    name: 'iShares iBonds Mar 2023 Term Corporate ETF',
    symbol: 'IBDD',
  },
  {
    value: 'NTG',
    label: 'NTG,  Tortoise Midstream Energy Fund, Inc.',
    name: 'Tortoise Midstream Energy Fund, Inc.',
    symbol: 'NTG',
  },
  {
    value: 'VYNT',
    label: 'VYNT,  Vyant Bio, Inc.',
    name: 'Vyant Bio, Inc.',
    symbol: 'VYNT',
  },
  {
    value: 'RMAX',
    label: 'RMAX,  RE/MAX Holdings, Inc.',
    name: 'RE/MAX Holdings, Inc.',
    symbol: 'RMAX',
  },
  {
    value: 'VCLT',
    label: 'VCLT,  Vanguard Long-Term Corporate Bond Index Fund',
    name: 'Vanguard Long-Term Corporate Bond Index Fund',
    symbol: 'VCLT',
  },
  {
    value: 'CRVS',
    label: 'CRVS,  Corvus Pharmaceuticals, Inc.',
    name: 'Corvus Pharmaceuticals, Inc.',
    symbol: 'CRVS',
  },
  {
    value: 'HRI',
    label: 'HRI,  Herc Holdings Inc.',
    name: 'Herc Holdings Inc.',
    symbol: 'HRI',
  },
  {
    value: 'PRLB',
    label: 'PRLB,  Proto Labs, Inc.',
    name: 'Proto Labs, Inc.',
    symbol: 'PRLB',
  },
  {
    value: 'BIT',
    label: 'BIT,  BlackRock Multi-Sector Income Trust',
    name: 'BlackRock Multi-Sector Income Trust',
    symbol: 'BIT',
  },
  {
    value: 'MMI',
    label: 'MMI,  Marcus & Millichap, Inc.',
    name: 'Marcus & Millichap, Inc.',
    symbol: 'MMI',
  },
  {
    value: 'CR',
    label: 'CR,  Crane Holdings, Co.',
    name: 'Crane Holdings, Co.',
    symbol: 'CR',
  },
  {
    value: 'CEQP',
    label: 'CEQP,  Crestwood Equity Partners LP',
    name: 'Crestwood Equity Partners LP',
    symbol: 'CEQP',
  },
  {
    value: 'CRWS',
    label: 'CRWS,  Crown Crafts, Inc.',
    name: 'Crown Crafts, Inc.',
    symbol: 'CRWS',
  },
  {
    value: 'LNN',
    label: 'LNN,  Lindsay Corporation',
    name: 'Lindsay Corporation',
    symbol: 'LNN',
  },
  {
    value: 'EDC',
    label: 'EDC,  Direxion Daily MSCI Emerging Markets Bull 3X Shares',
    name: 'Direxion Daily MSCI Emerging Markets Bull 3X Shares',
    symbol: 'EDC',
  },
  {
    value: 'TCBK',
    label: 'TCBK,  TriCo Bancshares',
    name: 'TriCo Bancshares',
    symbol: 'TCBK',
  },
  {
    value: 'SNDX',
    label: 'SNDX,  Syndax Pharmaceuticals, Inc.',
    name: 'Syndax Pharmaceuticals, Inc.',
    symbol: 'SNDX',
  },
  {
    value: 'INGN',
    label: 'INGN,  Inogen, Inc.',
    name: 'Inogen, Inc.',
    symbol: 'INGN',
  },
  {
    value: 'GDV',
    label: 'GDV,  The Gabelli Dividend & Income Trust',
    name: 'The Gabelli Dividend & Income Trust',
    symbol: 'GDV',
  },
  {
    value: 'GSBC',
    label: 'GSBC,  Great Southern Bancorp, Inc.',
    name: 'Great Southern Bancorp, Inc.',
    symbol: 'GSBC',
  },
  {
    value: 'PEN',
    label: 'PEN,  Penumbra, Inc.',
    name: 'Penumbra, Inc.',
    symbol: 'PEN',
  },
  {
    value: 'AGR',
    label: 'AGR,  Avangrid, Inc.',
    name: 'Avangrid, Inc.',
    symbol: 'AGR',
  },
  {
    value: 'ANIP',
    label: 'ANIP,  ANI Pharmaceuticals, Inc.',
    name: 'ANI Pharmaceuticals, Inc.',
    symbol: 'ANIP',
  },
  {
    value: 'CETX',
    label: 'CETX,  Cemtrex, Inc.',
    name: 'Cemtrex, Inc.',
    symbol: 'CETX',
  },
  {
    value: 'FXC',
    label: 'FXC,  Invesco CurrencyShares Canadian Dollar Trust',
    name: 'Invesco CurrencyShares Canadian Dollar Trust',
    symbol: 'FXC',
  },
  {
    value: 'GNCA',
    label: 'GNCA,  Genocea Biosciences, Inc.',
    name: 'Genocea Biosciences, Inc.',
    symbol: 'GNCA',
  },
  {
    value: 'LQDT',
    label: 'LQDT,  Liquidity Services, Inc.',
    name: 'Liquidity Services, Inc.',
    symbol: 'LQDT',
  },
  {
    value: 'SCJ',
    label: 'SCJ,  iShares MSCI Japan Small-Cap ETF',
    name: 'iShares MSCI Japan Small-Cap ETF',
    symbol: 'SCJ',
  },
  {
    value: 'TKC',
    label: 'TKC,  Turkcell Iletisim Hizmetleri A.S.',
    name: 'Turkcell Iletisim Hizmetleri A.S.',
    symbol: 'TKC',
  },
  {
    value: 'VMO',
    label: 'VMO,  Invesco Municipal Opportunity Trust',
    name: 'Invesco Municipal Opportunity Trust',
    symbol: 'VMO',
  },
  {
    value: 'CRK',
    label: 'CRK,  Comstock Resources, Inc.',
    name: 'Comstock Resources, Inc.',
    symbol: 'CRK',
  },
  {
    value: 'FATE',
    label: 'FATE,  Fate Therapeutics, Inc.',
    name: 'Fate Therapeutics, Inc.',
    symbol: 'FATE',
  },
  {
    value: 'NWN',
    label: 'NWN,  Northwest Natural Holding Company',
    name: 'Northwest Natural Holding Company',
    symbol: 'NWN',
  },
  {
    value: 'OSS',
    label: 'OSS,  One Stop Systems, Inc.',
    name: 'One Stop Systems, Inc.',
    symbol: 'OSS',
  },
  {
    value: 'TSQ',
    label: 'TSQ,  Townsquare Media, Inc.',
    name: 'Townsquare Media, Inc.',
    symbol: 'TSQ',
  },
  {
    value: 'CODI',
    label: 'CODI,  Compass Diversified',
    name: 'Compass Diversified',
    symbol: 'CODI',
  },
  {
    value: 'RDVY',
    label: 'RDVY,  First Trust Rising Dividend Achievers ETF',
    name: 'First Trust Rising Dividend Achievers ETF',
    symbol: 'RDVY',
  },
  {
    value: 'PBPB',
    label: 'PBPB,  Potbelly Corporation',
    name: 'Potbelly Corporation',
    symbol: 'PBPB',
  },
  {
    value: 'CRF',
    label: 'CRF,  Cornerstone Total Return Fund, Inc.',
    name: 'Cornerstone Total Return Fund, Inc.',
    symbol: 'CRF',
  },
  {
    value: 'IGR',
    label: 'IGR,  CBRE Global Real Estate Income Fund',
    name: 'CBRE Global Real Estate Income Fund',
    symbol: 'IGR',
  },
  {
    value: 'AVAV',
    label: 'AVAV,  AeroVironment, Inc.',
    name: 'AeroVironment, Inc.',
    symbol: 'AVAV',
  },
  {
    value: 'SPEM',
    label: 'SPEM,  SPDR Portfolio Emerging Markets ETF',
    name: 'SPDR Portfolio Emerging Markets ETF',
    symbol: 'SPEM',
  },
  {
    value: 'IPGP',
    label: 'IPGP,  IPG Photonics Corporation',
    name: 'IPG Photonics Corporation',
    symbol: 'IPGP',
  },
  {
    value: 'TTOO',
    label: 'TTOO,  T2 Biosystems, Inc.',
    name: 'T2 Biosystems, Inc.',
    symbol: 'TTOO',
  },
  {
    value: 'SAM',
    label: 'SAM,  The Boston Beer Company, Inc.',
    name: 'The Boston Beer Company, Inc.',
    symbol: 'SAM',
  },
  {
    value: 'DSX',
    label: 'DSX,  Diana Shipping Inc.',
    name: 'Diana Shipping Inc.',
    symbol: 'DSX',
  },
  {
    value: 'IIPR',
    label: 'IIPR,  Innovative Industrial Properties, Inc.',
    name: 'Innovative Industrial Properties, Inc.',
    symbol: 'IIPR',
  },
  {
    value: 'AMPH',
    label: 'AMPH,  Amphastar Pharmaceuticals, Inc.',
    name: 'Amphastar Pharmaceuticals, Inc.',
    symbol: 'AMPH',
  },
  {
    value: 'CNMD',
    label: 'CNMD,  CONMED Corporation',
    name: 'CONMED Corporation',
    symbol: 'CNMD',
  },
  {
    value: 'DOG',
    label: 'DOG,  ProShares Short Dow30',
    name: 'ProShares Short Dow30',
    symbol: 'DOG',
  },
  {
    value: 'HEQ',
    label: 'HEQ,  John Hancock Hedged Equity & Income Fund',
    name: 'John Hancock Hedged Equity & Income Fund',
    symbol: 'HEQ',
  },
  {
    value: 'NDLS',
    label: 'NDLS,  Noodles & Company',
    name: 'Noodles & Company',
    symbol: 'NDLS',
  },
  {
    value: 'VWOB',
    label: 'VWOB,  Vanguard Emerging Markets Government Bond Index Fund',
    name: 'Vanguard Emerging Markets Government Bond Index Fund',
    symbol: 'VWOB',
  },
  {
    value: 'ABCB',
    label: 'ABCB,  Ameris Bancorp',
    name: 'Ameris Bancorp',
    symbol: 'ABCB',
  },
  {
    value: 'CEN',
    label: 'CEN,  Center Coast Brookfield MLP & Energy Infrastructure Fund',
    name: 'Center Coast Brookfield MLP & Energy Infrastructure Fund',
    symbol: 'CEN',
  },
  {
    value: 'CYTK',
    label: 'CYTK,  Cytokinetics, Incorporated',
    name: 'Cytokinetics, Incorporated',
    symbol: 'CYTK',
  },
  {
    value: 'NTGR',
    label: 'NTGR,  NETGEAR, Inc.',
    name: 'NETGEAR, Inc.',
    symbol: 'NTGR',
  },
  {
    value: 'ITA',
    label: 'ITA,  iShares U.S. Aerospace & Defense ETF',
    name: 'iShares U.S. Aerospace & Defense ETF',
    symbol: 'ITA',
  },
  {
    value: 'FONR',
    label: 'FONR,  FONAR Corporation',
    name: 'FONAR Corporation',
    symbol: 'FONR',
  },
  {
    value: 'UNF',
    label: 'UNF,  UniFirst Corporation',
    name: 'UniFirst Corporation',
    symbol: 'UNF',
  },
  {
    value: 'RDWR',
    label: 'RDWR,  Radware Ltd.',
    name: 'Radware Ltd.',
    symbol: 'RDWR',
  },
  {
    value: 'JDST',
    label: 'JDST,  Direxion Daily Junior Gold Miners Index Bear 2X Shares',
    name: 'Direxion Daily Junior Gold Miners Index Bear 2X Shares',
    symbol: 'JDST',
  },
  {
    value: 'FTXO',
    label: 'FTXO,  First Trust Nasdaq Bank ETF',
    name: 'First Trust Nasdaq Bank ETF',
    symbol: 'FTXO',
  },
  {
    value: 'GIFI',
    label: 'GIFI,  Gulf Island Fabrication, Inc.',
    name: 'Gulf Island Fabrication, Inc.',
    symbol: 'GIFI',
  },
  {
    value: 'BGSF',
    label: 'BGSF,  BGSF, Inc.',
    name: 'BGSF, Inc.',
    symbol: 'BGSF',
  },
  {
    value: 'CACC',
    label: 'CACC,  Credit Acceptance Corporation',
    name: 'Credit Acceptance Corporation',
    symbol: 'CACC',
  },
  {
    value: 'EMO',
    label: 'EMO,  ClearBridge Energy Midstream Opportunity Fund Inc',
    name: 'ClearBridge Energy Midstream Opportunity Fund Inc',
    symbol: 'EMO',
  },
  {
    value: 'OSIS',
    label: 'OSIS,  OSI Systems, Inc.',
    name: 'OSI Systems, Inc.',
    symbol: 'OSIS',
  },
  {
    value: 'NEP',
    label: 'NEP,  NextEra Energy Partners, LP',
    name: 'NextEra Energy Partners, LP',
    symbol: 'NEP',
  },
  {
    value: 'EPM',
    label: 'EPM,  Evolution Petroleum Corporation',
    name: 'Evolution Petroleum Corporation',
    symbol: 'EPM',
  },
  {
    value: 'CNA',
    label: 'CNA,  CNA Financial Corporation',
    name: 'CNA Financial Corporation',
    symbol: 'CNA',
  },
  {
    value: 'SRLN',
    label: 'SRLN,  SPDR Blackstone Senior Loan ETF',
    name: 'SPDR Blackstone Senior Loan ETF',
    symbol: 'SRLN',
  },
  {
    value: 'FXY',
    label: 'FXY,  Invesco CurrencyShares Japanese Yen Trust',
    name: 'Invesco CurrencyShares Japanese Yen Trust',
    symbol: 'FXY',
  },
  {
    value: 'LAUR',
    label: 'LAUR,  Laureate Education, Inc.',
    name: 'Laureate Education, Inc.',
    symbol: 'LAUR',
  },
  {
    value: 'SSD',
    label: 'SSD,  Simpson Manufacturing Co., Inc.',
    name: 'Simpson Manufacturing Co., Inc.',
    symbol: 'SSD',
  },
  {
    value: 'AMBC',
    label: 'AMBC,  Ambac Financial Group, Inc.',
    name: 'Ambac Financial Group, Inc.',
    symbol: 'AMBC',
  },
  {
    value: 'TSLX',
    label: 'TSLX,  Sixth Street Specialty Lending, Inc.',
    name: 'Sixth Street Specialty Lending, Inc.',
    symbol: 'TSLX',
  },
  {
    value: 'DON',
    label: 'DON,  WisdomTree U.S. MidCap Dividend Fund',
    name: 'WisdomTree U.S. MidCap Dividend Fund',
    symbol: 'DON',
  },
  {
    value: 'OXLC',
    label: 'OXLC,  Oxford Lane Capital Corp.',
    name: 'Oxford Lane Capital Corp.',
    symbol: 'OXLC',
  },
  {
    value: 'TWM',
    label: 'TWM,  ProShares UltraShort Russell2000',
    name: 'ProShares UltraShort Russell2000',
    symbol: 'TWM',
  },
  {
    value: 'IYF',
    label: 'IYF,  iShares U.S. Financials ETF',
    name: 'iShares U.S. Financials ETF',
    symbol: 'IYF',
  },
  {
    value: 'TNC',
    label: 'TNC,  Tennant Company',
    name: 'Tennant Company',
    symbol: 'TNC',
  },
  {
    value: 'EIM',
    label: 'EIM,  Eaton Vance Municipal Bond Fund',
    name: 'Eaton Vance Municipal Bond Fund',
    symbol: 'EIM',
  },
  {
    value: 'PNNT',
    label: 'PNNT,  PennantPark Investment Corporation',
    name: 'PennantPark Investment Corporation',
    symbol: 'PNNT',
  },
  {
    value: 'AGIO',
    label: 'AGIO,  Agios Pharmaceuticals, Inc.',
    name: 'Agios Pharmaceuticals, Inc.',
    symbol: 'AGIO',
  },
  {
    value: 'UGP',
    label: 'UGP,  Ultrapar Participações S.A.',
    name: 'Ultrapar Participações S.A.',
    symbol: 'UGP',
  },
  {
    value: 'CO',
    label: 'CO,  Global Cord Blood Corporation',
    name: 'Global Cord Blood Corporation',
    symbol: 'CO',
  },
  {
    value: 'VCSH',
    label: 'VCSH,  Vanguard Short-Term Corporate Bond Index Fund',
    name: 'Vanguard Short-Term Corporate Bond Index Fund',
    symbol: 'VCSH',
  },
  {
    value: 'TDY',
    label: 'TDY,  Teledyne Technologies Incorporated',
    name: 'Teledyne Technologies Incorporated',
    symbol: 'TDY',
  },
  {
    value: 'SFE',
    label: 'SFE,  Safeguard Scientifics, Inc.',
    name: 'Safeguard Scientifics, Inc.',
    symbol: 'SFE',
  },
  {
    value: 'TRTX',
    label: 'TRTX,  TPG RE Finance Trust, Inc.',
    name: 'TPG RE Finance Trust, Inc.',
    symbol: 'TRTX',
  },
  {
    value: 'PHYS',
    label: 'PHYS,  Sprott Physical Gold Trust',
    name: 'Sprott Physical Gold Trust',
    symbol: 'PHYS',
  },
  {
    value: 'DFE',
    label: 'DFE,  WisdomTree Europe SmallCap Dividend Fund',
    name: 'WisdomTree Europe SmallCap Dividend Fund',
    symbol: 'DFE',
  },
  {
    value: 'RUTH',
    label: "RUTH,  Ruth's Hospitality Group, Inc.",
    name: "Ruth's Hospitality Group, Inc.",
    symbol: 'RUTH',
  },
  {
    value: 'SRCE',
    label: 'SRCE,  1st Source Corporation',
    name: '1st Source Corporation',
    symbol: 'SRCE',
  },
  {
    value: 'HURN',
    label: 'HURN,  Huron Consulting Group Inc.',
    name: 'Huron Consulting Group Inc.',
    symbol: 'HURN',
  },
  {
    value: 'MNDO',
    label: 'MNDO,  MIND C.T.I. Ltd',
    name: 'MIND C.T.I. Ltd',
    symbol: 'MNDO',
  },
  {
    value: 'IGE',
    label: 'IGE,  iShares North American Natural Resources ETF',
    name: 'iShares North American Natural Resources ETF',
    symbol: 'IGE',
  },
  {
    value: 'SRI',
    label: 'SRI,  Stoneridge, Inc.',
    name: 'Stoneridge, Inc.',
    symbol: 'SRI',
  },
  {
    value: 'WLDN',
    label: 'WLDN,  Willdan Group, Inc.',
    name: 'Willdan Group, Inc.',
    symbol: 'WLDN',
  },
  {
    value: 'MEI',
    label: 'MEI,  Methode Electronics, Inc.',
    name: 'Methode Electronics, Inc.',
    symbol: 'MEI',
  },
  {
    value: 'SBSI',
    label: 'SBSI,  Southside Bancshares, Inc.',
    name: 'Southside Bancshares, Inc.',
    symbol: 'SBSI',
  },
  {
    value: 'OPTT',
    label: 'OPTT,  Ocean Power Technologies, Inc.',
    name: 'Ocean Power Technologies, Inc.',
    symbol: 'OPTT',
  },
  {
    value: 'WSC',
    label: 'WSC,  WillScot Mobile Mini Holdings Corp.',
    name: 'WillScot Mobile Mini Holdings Corp.',
    symbol: 'WSC',
  },
  {
    value: 'QCRH',
    label: 'QCRH,  QCR Holdings, Inc.',
    name: 'QCR Holdings, Inc.',
    symbol: 'QCRH',
  },
  {
    value: 'HSKA',
    label: 'HSKA,  Heska Corporation',
    name: 'Heska Corporation',
    symbol: 'HSKA',
  },
  {
    value: 'USPH',
    label: 'USPH,  U.S. Physical Therapy, Inc.',
    name: 'U.S. Physical Therapy, Inc.',
    symbol: 'USPH',
  },
  {
    value: 'JOE',
    label: 'JOE,  The St. Joe Company',
    name: 'The St. Joe Company',
    symbol: 'JOE',
  },
  {
    value: 'RMR',
    label: 'RMR,  The RMR Group Inc.',
    name: 'The RMR Group Inc.',
    symbol: 'RMR',
  },
  {
    value: 'CARA',
    label: 'CARA,  Cara Therapeutics, Inc.',
    name: 'Cara Therapeutics, Inc.',
    symbol: 'CARA',
  },
  {
    value: 'CMRX',
    label: 'CMRX,  Chimerix, Inc.',
    name: 'Chimerix, Inc.',
    symbol: 'CMRX',
  },
  {
    value: 'WATT',
    label: 'WATT,  Energous Corporation',
    name: 'Energous Corporation',
    symbol: 'WATT',
  },
  {
    value: 'CEI',
    label: 'CEI,  Camber Energy, Inc.',
    name: 'Camber Energy, Inc.',
    symbol: 'CEI',
  },
  {
    value: 'TARO',
    label: 'TARO,  Taro Pharmaceutical Industries Ltd.',
    name: 'Taro Pharmaceutical Industries Ltd.',
    symbol: 'TARO',
  },
  {
    value: 'SOXL',
    label: 'SOXL,  Direxion Daily Semiconductor Bull 3X Shares',
    name: 'Direxion Daily Semiconductor Bull 3X Shares',
    symbol: 'SOXL',
  },
  {
    value: 'HIX',
    label: 'HIX,  Western Asset High Income Fund II Inc.',
    name: 'Western Asset High Income Fund II Inc.',
    symbol: 'HIX',
  },
  {
    value: 'SBCF',
    label: 'SBCF,  Seacoast Banking Corporation of Florida',
    name: 'Seacoast Banking Corporation of Florida',
    symbol: 'SBCF',
  },
  {
    value: 'TRTN',
    label: 'TRTN,  Triton International Limited',
    name: 'Triton International Limited',
    symbol: 'TRTN',
  },
  {
    value: 'NNBR',
    label: 'NNBR,  NN, Inc.',
    name: 'NN, Inc.',
    symbol: 'NNBR',
  },
  {
    value: 'SBT',
    label: 'SBT,  Sterling Bancorp, Inc. (Southfield, MI)',
    name: 'Sterling Bancorp, Inc. (Southfield, MI)',
    symbol: 'SBT',
  },
  {
    value: 'PARR',
    label: 'PARR,  Par Pacific Holdings, Inc.',
    name: 'Par Pacific Holdings, Inc.',
    symbol: 'PARR',
  },
  {
    value: 'MLPA',
    label: 'MLPA,  Global X MLP ETF',
    name: 'Global X MLP ETF',
    symbol: 'MLPA',
  },
  {
    value: 'SSTI',
    label: 'SSTI,  ShotSpotter, Inc.',
    name: 'ShotSpotter, Inc.',
    symbol: 'SSTI',
  },
  {
    value: 'ASA',
    label: 'ASA,  ASA Gold and Precious Metals Limited',
    name: 'ASA Gold and Precious Metals Limited',
    symbol: 'ASA',
  },
  {
    value: 'PRPO',
    label: 'PRPO,  Precipio, Inc.',
    name: 'Precipio, Inc.',
    symbol: 'PRPO',
  },
  {
    value: 'REX',
    label: 'REX,  REX American Resources Corporation',
    name: 'REX American Resources Corporation',
    symbol: 'REX',
  },
  {
    value: 'FRME',
    label: 'FRME,  First Merchants Corporation',
    name: 'First Merchants Corporation',
    symbol: 'FRME',
  },
  {
    value: 'IDCC',
    label: 'IDCC,  InterDigital, Inc.',
    name: 'InterDigital, Inc.',
    symbol: 'IDCC',
  },
  {
    value: 'TMV',
    label: 'TMV,  Direxion Daily 20+ Year Treasury Bear 3X Shares',
    name: 'Direxion Daily 20+ Year Treasury Bear 3X Shares',
    symbol: 'TMV',
  },
  {
    value: 'TUR',
    label: 'TUR,  iShares MSCI Turkey ETF',
    name: 'iShares MSCI Turkey ETF',
    symbol: 'TUR',
  },
  {
    value: 'AWP',
    label: 'AWP,  abrdn Global Premier Properties Fund',
    name: 'abrdn Global Premier Properties Fund',
    symbol: 'AWP',
  },
  {
    value: 'NSSC',
    label: 'NSSC,  Napco Security Technologies, Inc.',
    name: 'Napco Security Technologies, Inc.',
    symbol: 'NSSC',
  },
  {
    value: 'ECH',
    label: 'ECH,  iShares MSCI Chile ETF',
    name: 'iShares MSCI Chile ETF',
    symbol: 'ECH',
  },
  {
    value: 'NEO',
    label: 'NEO,  NeoGenomics, Inc.',
    name: 'NeoGenomics, Inc.',
    symbol: 'NEO',
  },
  {
    value: 'FPI',
    label: 'FPI,  Farmland Partners Inc.',
    name: 'Farmland Partners Inc.',
    symbol: 'FPI',
  },
  {
    value: 'GLOB',
    label: 'GLOB,  Globant S.A.',
    name: 'Globant S.A.',
    symbol: 'GLOB',
  },
  {
    value: 'EMKR',
    label: 'EMKR,  EMCORE Corporation',
    name: 'EMCORE Corporation',
    symbol: 'EMKR',
  },
  {
    value: 'BNED',
    label: 'BNED,  Barnes & Noble Education, Inc.',
    name: 'Barnes & Noble Education, Inc.',
    symbol: 'BNED',
  },
  {
    value: 'FBNC',
    label: 'FBNC,  First Bancorp',
    name: 'First Bancorp',
    symbol: 'FBNC',
  },
  {
    value: 'SLRC',
    label: 'SLRC,  SLR Investment Corp.',
    name: 'SLR Investment Corp.',
    symbol: 'SLRC',
  },
  {
    value: 'CPF',
    label: 'CPF,  Central Pacific Financial Corp.',
    name: 'Central Pacific Financial Corp.',
    symbol: 'CPF',
  },
  {
    value: 'FNCL',
    label: 'FNCL,  Fidelity MSCI Financials Index ETF',
    name: 'Fidelity MSCI Financials Index ETF',
    symbol: 'FNCL',
  },
  {
    value: 'DSL',
    label: 'DSL,  DoubleLine Income Solutions Fund',
    name: 'DoubleLine Income Solutions Fund',
    symbol: 'DSL',
  },
  {
    value: 'ISTB',
    label: 'ISTB,  iShares Core 1-5 Year USD Bond ETF',
    name: 'iShares Core 1-5 Year USD Bond ETF',
    symbol: 'ISTB',
  },
  {
    value: 'MTBC',
    label: 'MTBC,  CareCloud, Inc.',
    name: 'CareCloud, Inc.',
    symbol: 'MTBC',
  },
  {
    value: 'NEON',
    label: 'NEON,  Neonode Inc.',
    name: 'Neonode Inc.',
    symbol: 'NEON',
  },
  {
    value: 'NXE',
    label: 'NXE,  NexGen Energy Ltd.',
    name: 'NexGen Energy Ltd.',
    symbol: 'NXE',
  },
  {
    value: 'PBFX',
    label: 'PBFX,  PBF Logistics LP',
    name: 'PBF Logistics LP',
    symbol: 'PBFX',
  },
  {
    value: 'RCUS',
    label: 'RCUS,  Arcus Biosciences, Inc.',
    name: 'Arcus Biosciences, Inc.',
    symbol: 'RCUS',
  },
  {
    value: 'AMRC',
    label: 'AMRC,  Ameresco, Inc.',
    name: 'Ameresco, Inc.',
    symbol: 'AMRC',
  },
  {
    value: 'SD',
    label: 'SD,  SandRidge Energy, Inc.',
    name: 'SandRidge Energy, Inc.',
    symbol: 'SD',
  },
  {
    value: 'EGLE',
    label: 'EGLE,  Eagle Bulk Shipping Inc.',
    name: 'Eagle Bulk Shipping Inc.',
    symbol: 'EGLE',
  },
  {
    value: 'MTEM',
    label: 'MTEM,  Molecular Templates, Inc.',
    name: 'Molecular Templates, Inc.',
    symbol: 'MTEM',
  },
  {
    value: 'EGBN',
    label: 'EGBN,  Eagle Bancorp, Inc.',
    name: 'Eagle Bancorp, Inc.',
    symbol: 'EGBN',
  },
  {
    value: 'THG',
    label: 'THG,  The Hanover Insurance Group, Inc.',
    name: 'The Hanover Insurance Group, Inc.',
    symbol: 'THG',
  },
  {
    value: 'NOAH',
    label: 'NOAH,  Noah Holdings Limited',
    name: 'Noah Holdings Limited',
    symbol: 'NOAH',
  },
  {
    value: 'IDA',
    label: 'IDA,  IDACORP, Inc.',
    name: 'IDACORP, Inc.',
    symbol: 'IDA',
  },
  {
    value: 'MED',
    label: 'MED,  Medifast, Inc.',
    name: 'Medifast, Inc.',
    symbol: 'MED',
  },
  {
    value: 'FRBK',
    label: 'FRBK,  Republic First Bancorp, Inc.',
    name: 'Republic First Bancorp, Inc.',
    symbol: 'FRBK',
  },
  {
    value: 'PEBO',
    label: 'PEBO,  Peoples Bancorp Inc.',
    name: 'Peoples Bancorp Inc.',
    symbol: 'PEBO',
  },
  {
    value: 'IWN',
    label: 'IWN,  iShares Russell 2000 Value ETF',
    name: 'iShares Russell 2000 Value ETF',
    symbol: 'IWN',
  },
  {
    value: 'LOB',
    label: 'LOB,  Live Oak Bancshares, Inc.',
    name: 'Live Oak Bancshares, Inc.',
    symbol: 'LOB',
  },
  {
    value: 'ETJ',
    label: 'ETJ,  Eaton Vance Risk-Managed Diversified Equity Income Fund',
    name: 'Eaton Vance Risk-Managed Diversified Equity Income Fund',
    symbol: 'ETJ',
  },
  {
    value: 'FMHI',
    label: 'FMHI,  First Trust Municipal High Income ETF',
    name: 'First Trust Municipal High Income ETF',
    symbol: 'FMHI',
  },
  {
    value: 'MCRI',
    label: 'MCRI,  Monarch Casino & Resort, Inc.',
    name: 'Monarch Casino & Resort, Inc.',
    symbol: 'MCRI',
  },
  {
    value: 'NSL',
    label: 'NSL,  Nuveen Senior Income Fund',
    name: 'Nuveen Senior Income Fund',
    symbol: 'NSL',
  },
  {
    value: 'CRNT',
    label: 'CRNT,  Ceragon Networks Ltd.',
    name: 'Ceragon Networks Ltd.',
    symbol: 'CRNT',
  },
  {
    value: 'WMK',
    label: 'WMK,  Weis Markets, Inc.',
    name: 'Weis Markets, Inc.',
    symbol: 'WMK',
  },
  {
    value: 'IDT',
    label: 'IDT,  IDT Corporation',
    name: 'IDT Corporation',
    symbol: 'IDT',
  },
  {
    value: 'INSG',
    label: 'INSG,  Inseego Corp.',
    name: 'Inseego Corp.',
    symbol: 'INSG',
  },
  {
    value: 'SDIV',
    label: 'SDIV,  Global X SuperDividend ETF',
    name: 'Global X SuperDividend ETF',
    symbol: 'SDIV',
  },
  {
    value: 'TNP',
    label: 'TNP,  Tsakos Energy Navigation Limited',
    name: 'Tsakos Energy Navigation Limited',
    symbol: 'TNP',
  },
  {
    value: 'NGS',
    label: 'NGS,  Natural Gas Services Group, Inc.',
    name: 'Natural Gas Services Group, Inc.',
    symbol: 'NGS',
  },
  {
    value: 'CPSI',
    label: 'CPSI,  Computer Programs and Systems, Inc.',
    name: 'Computer Programs and Systems, Inc.',
    symbol: 'CPSI',
  },
  {
    value: 'AGS',
    label: 'AGS,  PlayAGS, Inc.',
    name: 'PlayAGS, Inc.',
    symbol: 'AGS',
  },
  {
    value: 'SCPH',
    label: 'SCPH,  scPharmaceuticals Inc.',
    name: 'scPharmaceuticals Inc.',
    symbol: 'SCPH',
  },
  {
    value: 'GPRK',
    label: 'GPRK,  GeoPark Limited',
    name: 'GeoPark Limited',
    symbol: 'GPRK',
  },
  {
    value: 'CDXC',
    label: 'CDXC,  ChromaDex Corporation',
    name: 'ChromaDex Corporation',
    symbol: 'CDXC',
  },
  {
    value: 'BBSI',
    label: 'BBSI,  Barrett Business Services, Inc.',
    name: 'Barrett Business Services, Inc.',
    symbol: 'BBSI',
  },
  {
    value: 'FOR',
    label: 'FOR,  Forestar Group Inc.',
    name: 'Forestar Group Inc.',
    symbol: 'FOR',
  },
  {
    value: 'PPT',
    label: 'PPT,  Putnam Premier Income Trust',
    name: 'Putnam Premier Income Trust',
    symbol: 'PPT',
  },
  {
    value: 'VLRS',
    label: 'VLRS,  Controladora Vuela Compañía de Aviación, S.A.B. de C.V.',
    name: 'Controladora Vuela Compañía de Aviación, S.A.B. de C.V.',
    symbol: 'VLRS',
  },
  {
    value: 'ASMB',
    label: 'ASMB,  Assembly Biosciences, Inc.',
    name: 'Assembly Biosciences, Inc.',
    symbol: 'ASMB',
  },
  {
    value: 'CIR',
    label: 'CIR,  CIRCOR International, Inc.',
    name: 'CIRCOR International, Inc.',
    symbol: 'CIR',
  },
  {
    value: 'FARO',
    label: 'FARO,  FARO Technologies, Inc.',
    name: 'FARO Technologies, Inc.',
    symbol: 'FARO',
  },
  {
    value: 'THR',
    label: 'THR,  Thermon Group Holdings, Inc.',
    name: 'Thermon Group Holdings, Inc.',
    symbol: 'THR',
  },
  {
    value: 'PWV',
    label: 'PWV,  Invesco Dynamic Large Cap Value ETF',
    name: 'Invesco Dynamic Large Cap Value ETF',
    symbol: 'PWV',
  },
  {
    value: 'RNR',
    label: 'RNR,  RenaissanceRe Holdings Ltd.',
    name: 'RenaissanceRe Holdings Ltd.',
    symbol: 'RNR',
  },
  {
    value: 'CHE',
    label: 'CHE,  Chemed Corporation',
    name: 'Chemed Corporation',
    symbol: 'CHE',
  },
  {
    value: 'JRVR',
    label: 'JRVR,  James River Group Holdings, Ltd.',
    name: 'James River Group Holdings, Ltd.',
    symbol: 'JRVR',
  },
  {
    value: 'NBTB',
    label: 'NBTB,  NBT Bancorp Inc.',
    name: 'NBT Bancorp Inc.',
    symbol: 'NBTB',
  },
  {
    value: 'CMTL',
    label: 'CMTL,  Comtech Telecommunications Corp.',
    name: 'Comtech Telecommunications Corp.',
    symbol: 'CMTL',
  },
  {
    value: 'CRSP',
    label: 'CRSP,  CRISPR Therapeutics AG',
    name: 'CRISPR Therapeutics AG',
    symbol: 'CRSP',
  },
  {
    value: 'DHX',
    label: 'DHX,  DHI Group, Inc.',
    name: 'DHI Group, Inc.',
    symbol: 'DHX',
  },
  {
    value: 'EWO',
    label: 'EWO,  iShares MSCI Austria ETF',
    name: 'iShares MSCI Austria ETF',
    symbol: 'EWO',
  },
  {
    value: 'IVAC',
    label: 'IVAC,  Intevac, Inc.',
    name: 'Intevac, Inc.',
    symbol: 'IVAC',
  },
  {
    value: 'KFRC',
    label: 'KFRC,  Kforce Inc.',
    name: 'Kforce Inc.',
    symbol: 'KFRC',
  },
  {
    value: 'MUC',
    label: 'MUC,  BlackRock MuniHoldings California Quality Fund, Inc.',
    name: 'BlackRock MuniHoldings California Quality Fund, Inc.',
    symbol: 'MUC',
  },
  {
    value: 'CDTX',
    label: 'CDTX,  Cidara Therapeutics, Inc.',
    name: 'Cidara Therapeutics, Inc.',
    symbol: 'CDTX',
  },
  {
    value: 'CNOB',
    label: 'CNOB,  ConnectOne Bancorp, Inc.',
    name: 'ConnectOne Bancorp, Inc.',
    symbol: 'CNOB',
  },
  {
    value: 'GLAD',
    label: 'GLAD,  Gladstone Capital Corporation',
    name: 'Gladstone Capital Corporation',
    symbol: 'GLAD',
  },
  {
    value: 'PRF',
    label: 'PRF,  Invesco FTSE RAFI US 1000 ETF',
    name: 'Invesco FTSE RAFI US 1000 ETF',
    symbol: 'PRF',
  },
  {
    value: 'UFCS',
    label: 'UFCS,  United Fire Group, Inc.',
    name: 'United Fire Group, Inc.',
    symbol: 'UFCS',
  },
  {
    value: 'TRS',
    label: 'TRS,  TriMas Corporation',
    name: 'TriMas Corporation',
    symbol: 'TRS',
  },
  {
    value: 'NSP',
    label: 'NSP,  Insperity, Inc.',
    name: 'Insperity, Inc.',
    symbol: 'NSP',
  },
  {
    value: 'BBN',
    label: 'BBN,  BlackRock Taxable Municipal Bond Trust',
    name: 'BlackRock Taxable Municipal Bond Trust',
    symbol: 'BBN',
  },
  {
    value: 'NZF',
    label: 'NZF,  Nuveen Municipal Credit Income Fund',
    name: 'Nuveen Municipal Credit Income Fund',
    symbol: 'NZF',
  },
  {
    value: 'MEIP',
    label: 'MEIP,  MEI Pharma, Inc.',
    name: 'MEI Pharma, Inc.',
    symbol: 'MEIP',
  },
  {
    value: 'CLNE',
    label: 'CLNE,  Clean Energy Fuels Corp.',
    name: 'Clean Energy Fuels Corp.',
    symbol: 'CLNE',
  },
  {
    value: 'OMEX',
    label: 'OMEX,  Odyssey Marine Exploration, Inc.',
    name: 'Odyssey Marine Exploration, Inc.',
    symbol: 'OMEX',
  },
  {
    value: 'CBU',
    label: 'CBU,  Community Bank System, Inc.',
    name: 'Community Bank System, Inc.',
    symbol: 'CBU',
  },
  {
    value: 'EVM',
    label: 'EVM,  Eaton Vance California Municipal Bond Fund',
    name: 'Eaton Vance California Municipal Bond Fund',
    symbol: 'EVM',
  },
  {
    value: 'IGV',
    label: 'IGV,  iShares Expanded Tech-Software Sector ETF',
    name: 'iShares Expanded Tech-Software Sector ETF',
    symbol: 'IGV',
  },
  {
    value: 'QUAL',
    label: 'QUAL,  iShares MSCI USA Quality Factor ETF',
    name: 'iShares MSCI USA Quality Factor ETF',
    symbol: 'QUAL',
  },
  {
    value: 'TCON',
    label: 'TCON,  TRACON Pharmaceuticals, Inc.',
    name: 'TRACON Pharmaceuticals, Inc.',
    symbol: 'TCON',
  },
  {
    value: 'VKI',
    label: 'VKI,  Invesco Advantage Municipal Income Trust II',
    name: 'Invesco Advantage Municipal Income Trust II',
    symbol: 'VKI',
  },
  {
    value: 'NX',
    label: 'NX,  Quanex Building Products Corporation',
    name: 'Quanex Building Products Corporation',
    symbol: 'NX',
  },
  {
    value: 'STRA',
    label: 'STRA,  Strategic Education, Inc.',
    name: 'Strategic Education, Inc.',
    symbol: 'STRA',
  },
  {
    value: 'EWZS',
    label: 'EWZS,  iShares MSCI Brazil Small-Cap ETF',
    name: 'iShares MSCI Brazil Small-Cap ETF',
    symbol: 'EWZS',
  },
  {
    value: 'JAKK',
    label: 'JAKK,  JAKKS Pacific, Inc.',
    name: 'JAKKS Pacific, Inc.',
    symbol: 'JAKK',
  },
  {
    value: 'NERV',
    label: 'NERV,  Minerva Neurosciences, Inc.',
    name: 'Minerva Neurosciences, Inc.',
    symbol: 'NERV',
  },
  {
    value: 'EARN',
    label: 'EARN,  Ellington Residential Mortgage REIT',
    name: 'Ellington Residential Mortgage REIT',
    symbol: 'EARN',
  },
  {
    value: 'ATNX',
    label: 'ATNX,  Athenex, Inc.',
    name: 'Athenex, Inc.',
    symbol: 'ATNX',
  },
  {
    value: 'NYMX',
    label: 'NYMX,  Nymox Pharmaceutical Corporation',
    name: 'Nymox Pharmaceutical Corporation',
    symbol: 'NYMX',
  },
  {
    value: 'MYO',
    label: 'MYO,  Myomo, Inc.',
    name: 'Myomo, Inc.',
    symbol: 'MYO',
  },
  {
    value: 'PDFS',
    label: 'PDFS,  PDF Solutions, Inc.',
    name: 'PDF Solutions, Inc.',
    symbol: 'PDFS',
  },
  {
    value: 'MVIS',
    label: 'MVIS,  MicroVision, Inc.',
    name: 'MicroVision, Inc.',
    symbol: 'MVIS',
  },
  {
    value: 'TBK',
    label: 'TBK,  Triumph Bancorp, Inc.',
    name: 'Triumph Bancorp, Inc.',
    symbol: 'TBK',
  },
  {
    value: 'DWX',
    label: 'DWX,  SPDR S&P International Dividend ETF',
    name: 'SPDR S&P International Dividend ETF',
    symbol: 'DWX',
  },
  {
    value: 'PFSI',
    label: 'PFSI,  PennyMac Financial Services, Inc.',
    name: 'PennyMac Financial Services, Inc.',
    symbol: 'PFSI',
  },
  {
    value: 'AQN',
    label: 'AQN,  Algonquin Power & Utilities Corp.',
    name: 'Algonquin Power & Utilities Corp.',
    symbol: 'AQN',
  },
  {
    value: 'CHIQ',
    label: 'CHIQ,  Global X MSCI China Consumer Discretionary ETF',
    name: 'Global X MSCI China Consumer Discretionary ETF',
    symbol: 'CHIQ',
  },
  {
    value: 'FIBK',
    label: 'FIBK,  First Interstate BancSystem, Inc.',
    name: 'First Interstate BancSystem, Inc.',
    symbol: 'FIBK',
  },
  {
    value: 'DEUS',
    label: 'DEUS,  Xtrackers Russell US Multifactor ETF',
    name: 'Xtrackers Russell US Multifactor ETF',
    symbol: 'DEUS',
  },
  {
    value: 'EDD',
    label: 'EDD,  Morgan Stanley Emerging Markets Domestic Debt Fund, Inc.',
    name: 'Morgan Stanley Emerging Markets Domestic Debt Fund, Inc.',
    symbol: 'EDD',
  },
  {
    value: 'FEI',
    label: 'FEI,  First Trust MLP and Energy Income Fund',
    name: 'First Trust MLP and Energy Income Fund',
    symbol: 'FEI',
  },
  {
    value: 'MARK',
    label: 'MARK,  Remark Holdings, Inc.',
    name: 'Remark Holdings, Inc.',
    symbol: 'MARK',
  },
  {
    value: 'OPTN',
    label: 'OPTN,  OptiNose, Inc.',
    name: 'OptiNose, Inc.',
    symbol: 'OPTN',
  },
  {
    value: 'TLH',
    label: 'TLH,  iShares 10-20 Year Treasury Bond ETF',
    name: 'iShares 10-20 Year Treasury Bond ETF',
    symbol: 'TLH',
  },
  {
    value: 'VOC',
    label: 'VOC,  VOC Energy Trust',
    name: 'VOC Energy Trust',
    symbol: 'VOC',
  },
  {
    value: 'SRV',
    label: 'SRV,  The Cushing MLP & Infrastructure Total Return Fund',
    name: 'The Cushing MLP & Infrastructure Total Return Fund',
    symbol: 'SRV',
  },
  {
    value: 'ETO',
    label:
      'ETO,  Eaton Vance Tax-Advantaged Global Dividend Opportunities Fund',
    name: 'Eaton Vance Tax-Advantaged Global Dividend Opportunities Fund',
    symbol: 'ETO',
  },
  {
    value: 'FCG',
    label: 'FCG,  First Trust Natural Gas ETF',
    name: 'First Trust Natural Gas ETF',
    symbol: 'FCG',
  },
  {
    value: 'BLCM',
    label: 'BLCM,  Bellicum Pharmaceuticals, Inc.',
    name: 'Bellicum Pharmaceuticals, Inc.',
    symbol: 'BLCM',
  },
  {
    value: 'FFIN',
    label: 'FFIN,  First Financial Bankshares, Inc.',
    name: 'First Financial Bankshares, Inc.',
    symbol: 'FFIN',
  },
  {
    value: 'RGNX',
    label: 'RGNX,  REGENXBIO Inc.',
    name: 'REGENXBIO Inc.',
    symbol: 'RGNX',
  },
  {
    value: 'APPF',
    label: 'APPF,  AppFolio, Inc.',
    name: 'AppFolio, Inc.',
    symbol: 'APPF',
  },
  {
    value: 'KE',
    label: 'KE,  Kimball Electronics, Inc.',
    name: 'Kimball Electronics, Inc.',
    symbol: 'KE',
  },
  {
    value: 'ASR',
    label: 'ASR,  Grupo Aeroportuario del Sureste, S. A. B. de C. V.',
    name: 'Grupo Aeroportuario del Sureste, S. A. B. de C. V.',
    symbol: 'ASR',
  },
  {
    value: 'MBRX',
    label: 'MBRX,  Moleculin Biotech, Inc.',
    name: 'Moleculin Biotech, Inc.',
    symbol: 'MBRX',
  },
  {
    value: 'AFMD',
    label: 'AFMD,  Affimed N.V.',
    name: 'Affimed N.V.',
    symbol: 'AFMD',
  },
  {
    value: 'ASYS',
    label: 'ASYS,  Amtech Systems, Inc.',
    name: 'Amtech Systems, Inc.',
    symbol: 'ASYS',
  },
  {
    value: 'APEI',
    label: 'APEI,  American Public Education, Inc.',
    name: 'American Public Education, Inc.',
    symbol: 'APEI',
  },
  {
    value: 'GORO',
    label: 'GORO,  Gold Resource Corporation',
    name: 'Gold Resource Corporation',
    symbol: 'GORO',
  },
  {
    value: 'TECL',
    label: 'TECL,  Direxion Daily Technology Bull 3X Shares',
    name: 'Direxion Daily Technology Bull 3X Shares',
    symbol: 'TECL',
  },
  {
    value: 'VICR',
    label: 'VICR,  Vicor Corporation',
    name: 'Vicor Corporation',
    symbol: 'VICR',
  },
  {
    value: 'MORN',
    label: 'MORN,  Morningstar, Inc.',
    name: 'Morningstar, Inc.',
    symbol: 'MORN',
  },
  {
    value: 'EIGR',
    label: 'EIGR,  Eiger BioPharmaceuticals, Inc.',
    name: 'Eiger BioPharmaceuticals, Inc.',
    symbol: 'EIGR',
  },
  {
    value: 'ATRC',
    label: 'ATRC,  AtriCure, Inc.',
    name: 'AtriCure, Inc.',
    symbol: 'ATRC',
  },
  {
    value: 'EMLP',
    label: 'EMLP,  First Trust North American Energy Infrastructure Fund',
    name: 'First Trust North American Energy Infrastructure Fund',
    symbol: 'EMLP',
  },
  {
    value: 'ICD',
    label: 'ICD,  Independence Contract Drilling, Inc.',
    name: 'Independence Contract Drilling, Inc.',
    symbol: 'ICD',
  },
  {
    value: 'CW',
    label: 'CW,  Curtiss-Wright Corporation',
    name: 'Curtiss-Wright Corporation',
    symbol: 'CW',
  },
  {
    value: 'IWR',
    label: 'IWR,  iShares Russell Mid-Cap ETF',
    name: 'iShares Russell Mid-Cap ETF',
    symbol: 'IWR',
  },
  {
    value: 'APLS',
    label: 'APLS,  Apellis Pharmaceuticals, Inc.',
    name: 'Apellis Pharmaceuticals, Inc.',
    symbol: 'APLS',
  },
  {
    value: 'CFO',
    label: 'CFO,  VictoryShares US 500 Enhanced Volatility Wtd ETF',
    name: 'VictoryShares US 500 Enhanced Volatility Wtd ETF',
    symbol: 'CFO',
  },
  {
    value: 'ILTB',
    label: 'ILTB,  iShares Core 10+ Year USD Bond ETF',
    name: 'iShares Core 10+ Year USD Bond ETF',
    symbol: 'ILTB',
  },
  {
    value: 'ALE',
    label: 'ALE,  ALLETE, Inc.',
    name: 'ALLETE, Inc.',
    symbol: 'ALE',
  },
  {
    value: 'HZN',
    label: 'HZN,  Horizon Global Corporation',
    name: 'Horizon Global Corporation',
    symbol: 'HZN',
  },
  {
    value: 'UEIC',
    label: 'UEIC,  Universal Electronics Inc.',
    name: 'Universal Electronics Inc.',
    symbol: 'UEIC',
  },
  {
    value: 'CKPT',
    label: 'CKPT,  Checkpoint Therapeutics, Inc.',
    name: 'Checkpoint Therapeutics, Inc.',
    symbol: 'CKPT',
  },
  {
    value: 'HTBI',
    label: 'HTBI,  HomeTrust Bancshares, Inc.',
    name: 'HomeTrust Bancshares, Inc.',
    symbol: 'HTBI',
  },
  {
    value: 'IDN',
    label: 'IDN,  Intellicheck, Inc.',
    name: 'Intellicheck, Inc.',
    symbol: 'IDN',
  },
  {
    value: 'KOF',
    label: 'KOF,  Coca-Cola FEMSA, S.A.B. de C.V.',
    name: 'Coca-Cola FEMSA, S.A.B. de C.V.',
    symbol: 'KOF',
  },
  {
    value: 'LYTS',
    label: 'LYTS,  LSI Industries Inc.',
    name: 'LSI Industries Inc.',
    symbol: 'LYTS',
  },
  {
    value: 'PHI',
    label: 'PHI,  PLDT Inc.',
    name: 'PLDT Inc.',
    symbol: 'PHI',
  },
  {
    value: 'SPHB',
    label: 'SPHB,  Invesco S&P 500 High Beta ETF',
    name: 'Invesco S&P 500 High Beta ETF',
    symbol: 'SPHB',
  },
  {
    value: 'CTR',
    label: 'CTR,  ClearBridge MLP and Midstream Total Return Fund Inc.',
    name: 'ClearBridge MLP and Midstream Total Return Fund Inc.',
    symbol: 'CTR',
  },
  {
    value: 'YINN',
    label: 'YINN,  Direxion Daily FTSE China Bull 3X Shares',
    name: 'Direxion Daily FTSE China Bull 3X Shares',
    symbol: 'YINN',
  },
  {
    value: 'ORA',
    label: 'ORA,  Ormat Technologies, Inc.',
    name: 'Ormat Technologies, Inc.',
    symbol: 'ORA',
  },
  {
    value: 'CMPR',
    label: 'CMPR,  Cimpress plc',
    name: 'Cimpress plc',
    symbol: 'CMPR',
  },
  {
    value: 'ESTE',
    label: 'ESTE,  Earthstone Energy, Inc.',
    name: 'Earthstone Energy, Inc.',
    symbol: 'ESTE',
  },
  {
    value: 'SHM',
    label: 'SHM,  SPDR Nuveen Bloomberg Short Term Municipal Bond ETF',
    name: 'SPDR Nuveen Bloomberg Short Term Municipal Bond ETF',
    symbol: 'SHM',
  },
  {
    value: 'VKQ',
    label: 'VKQ,  Invesco Municipal Trust',
    name: 'Invesco Municipal Trust',
    symbol: 'VKQ',
  },
  {
    value: 'SRDX',
    label: 'SRDX,  Surmodics, Inc.',
    name: 'Surmodics, Inc.',
    symbol: 'SRDX',
  },
  {
    value: 'OCUL',
    label: 'OCUL,  Ocular Therapeutix, Inc.',
    name: 'Ocular Therapeutix, Inc.',
    symbol: 'OCUL',
  },
  {
    value: 'WSBC',
    label: 'WSBC,  WesBanco, Inc.',
    name: 'WesBanco, Inc.',
    symbol: 'WSBC',
  },
  {
    value: 'PFBC',
    label: 'PFBC,  Preferred Bank',
    name: 'Preferred Bank',
    symbol: 'PFBC',
  },
  {
    value: 'CARG',
    label: 'CARG,  CarGurus, Inc.',
    name: 'CarGurus, Inc.',
    symbol: 'CARG',
  },
  {
    value: 'EMAN',
    label: 'EMAN,  eMagin Corporation',
    name: 'eMagin Corporation',
    symbol: 'EMAN',
  },
  {
    value: 'WMC',
    label: 'WMC,  Western Asset Mortgage Capital Corporation',
    name: 'Western Asset Mortgage Capital Corporation',
    symbol: 'WMC',
  },
  {
    value: 'TAN',
    label: 'TAN,  Invesco Solar ETF',
    name: 'Invesco Solar ETF',
    symbol: 'TAN',
  },
  {
    value: 'LX',
    label: 'LX,  LexinFintech Holdings Ltd.',
    name: 'LexinFintech Holdings Ltd.',
    symbol: 'LX',
  },
  {
    value: 'AIMC',
    label: 'AIMC,  Altra Industrial Motion Corp.',
    name: 'Altra Industrial Motion Corp.',
    symbol: 'AIMC',
  },
  {
    value: 'CHI',
    label: 'CHI,  Calamos Convertible Opportunities and Income Fund',
    name: 'Calamos Convertible Opportunities and Income Fund',
    symbol: 'CHI',
  },
  {
    value: 'LEJU',
    label: 'LEJU,  Leju Holdings Limited',
    name: 'Leju Holdings Limited',
    symbol: 'LEJU',
  },
  {
    value: 'SCHH',
    label: 'SCHH,  Schwab U.S. REIT ETF',
    name: 'Schwab U.S. REIT ETF',
    symbol: 'SCHH',
  },
  {
    value: 'SIVR',
    label: 'SIVR,  abrdn Physical Silver Shares ETF',
    name: 'abrdn Physical Silver Shares ETF',
    symbol: 'SIVR',
  },
  {
    value: 'NCZ',
    label: 'NCZ,  Virtus Convertible & Income Fund II',
    name: 'Virtus Convertible & Income Fund II',
    symbol: 'NCZ',
  },
  {
    value: 'UTF',
    label: 'UTF,  Cohen & Steers Infrastructure Fund, Inc',
    name: 'Cohen & Steers Infrastructure Fund, Inc',
    symbol: 'UTF',
  },
  {
    value: 'IBND',
    label: 'IBND,  SPDR Bloomberg International Corporate Bond ETF',
    name: 'SPDR Bloomberg International Corporate Bond ETF',
    symbol: 'IBND',
  },
  {
    value: 'GHY',
    label: 'GHY,  PGIM Global High Yield Fund, Inc',
    name: 'PGIM Global High Yield Fund, Inc',
    symbol: 'GHY',
  },
  {
    value: 'PAC',
    label: 'PAC,  Grupo Aeroportuario del Pacífico, S.A.B. de C.V.',
    name: 'Grupo Aeroportuario del Pacífico, S.A.B. de C.V.',
    symbol: 'PAC',
  },
  {
    value: 'IWS',
    label: 'IWS,  iShares Russell Mid-Cap Value ETF',
    name: 'iShares Russell Mid-Cap Value ETF',
    symbol: 'IWS',
  },
  {
    value: 'PLX',
    label: 'PLX,  Protalix BioTherapeutics, Inc.',
    name: 'Protalix BioTherapeutics, Inc.',
    symbol: 'PLX',
  },
  {
    value: 'IEI',
    label: 'IEI,  iShares 3-7 Year Treasury Bond ETF',
    name: 'iShares 3-7 Year Treasury Bond ETF',
    symbol: 'IEI',
  },
  {
    value: 'NVEE',
    label: 'NVEE,  NV5 Global, Inc.',
    name: 'NV5 Global, Inc.',
    symbol: 'NVEE',
  },
  {
    value: 'ATRO',
    label: 'ATRO,  Astronics Corporation',
    name: 'Astronics Corporation',
    symbol: 'ATRO',
  },
  {
    value: 'CFRX',
    label: 'CFRX,  ContraFect Corporation',
    name: 'ContraFect Corporation',
    symbol: 'CFRX',
  },
  {
    value: 'AZZ',
    label: 'AZZ,  AZZ Inc.',
    name: 'AZZ Inc.',
    symbol: 'AZZ',
  },
  {
    value: 'SJT',
    label: 'SJT,  San Juan Basin Royalty Trust',
    name: 'San Juan Basin Royalty Trust',
    symbol: 'SJT',
  },
  {
    value: 'DX',
    label: 'DX,  Dynex Capital, Inc.',
    name: 'Dynex Capital, Inc.',
    symbol: 'DX',
  },
  {
    value: 'VO',
    label: 'VO,  Vanguard Mid Cap Index Fund',
    name: 'Vanguard Mid Cap Index Fund',
    symbol: 'VO',
  },
  {
    value: 'IWV',
    label: 'IWV,  iShares Russell 3000 ETF',
    name: 'iShares Russell 3000 ETF',
    symbol: 'IWV',
  },
  {
    value: 'BLX',
    label: 'BLX,  Banco Latinoamericano de Comercio Exterior, S. A.',
    name: 'Banco Latinoamericano de Comercio Exterior, S. A.',
    symbol: 'BLX',
  },
  {
    value: 'ENSG',
    label: 'ENSG,  The Ensign Group, Inc.',
    name: 'The Ensign Group, Inc.',
    symbol: 'ENSG',
  },
  {
    value: 'CYBE',
    label: 'CYBE,  CyberOptics Corporation',
    name: 'CyberOptics Corporation',
    symbol: 'CYBE',
  },
  {
    value: 'EFC',
    label: 'EFC,  Ellington Financial Inc.',
    name: 'Ellington Financial Inc.',
    symbol: 'EFC',
  },
  {
    value: 'ACP',
    label: 'ACP,  Abrdn Income Credit Strategies Fund',
    name: 'Abrdn Income Credit Strategies Fund',
    symbol: 'ACP',
  },
  {
    value: 'AMTX',
    label: 'AMTX,  Aemetis, Inc.',
    name: 'Aemetis, Inc.',
    symbol: 'AMTX',
  },
  {
    value: 'ICL',
    label: 'ICL,  ICL Group Ltd',
    name: 'ICL Group Ltd',
    symbol: 'ICL',
  },
  {
    value: 'PHD',
    label: 'PHD,  Pioneer Floating Rate Fund, Inc.',
    name: 'Pioneer Floating Rate Fund, Inc.',
    symbol: 'PHD',
  },
  {
    value: 'RWLK',
    label: 'RWLK,  ReWalk Robotics Ltd.',
    name: 'ReWalk Robotics Ltd.',
    symbol: 'RWLK',
  },
  {
    value: 'TDTT',
    label: 'TDTT,  FlexShares iBoxx 3-Year Target Duration TIPS Index Fund',
    name: 'FlexShares iBoxx 3-Year Target Duration TIPS Index Fund',
    symbol: 'TDTT',
  },
  {
    value: 'SMHI',
    label: 'SMHI,  SEACOR Marine Holdings Inc.',
    name: 'SEACOR Marine Holdings Inc.',
    symbol: 'SMHI',
  },
  {
    value: 'SHEN',
    label: 'SHEN,  Shenandoah Telecommunications Company',
    name: 'Shenandoah Telecommunications Company',
    symbol: 'SHEN',
  },
  {
    value: 'ITI',
    label: 'ITI,  Iteris, Inc.',
    name: 'Iteris, Inc.',
    symbol: 'ITI',
  },
  {
    value: 'HNI',
    label: 'HNI,  HNI Corporation',
    name: 'HNI Corporation',
    symbol: 'HNI',
  },
  {
    value: 'LPTX',
    label: 'LPTX,  Leap Therapeutics, Inc.',
    name: 'Leap Therapeutics, Inc.',
    symbol: 'LPTX',
  },
  {
    value: 'NEU',
    label: 'NEU,  NewMarket Corporation',
    name: 'NewMarket Corporation',
    symbol: 'NEU',
  },
  {
    value: 'FSS',
    label: 'FSS,  Federal Signal Corporation',
    name: 'Federal Signal Corporation',
    symbol: 'FSS',
  },
  {
    value: 'IWP',
    label: 'IWP,  iShares Russell Mid-Cap Growth ETF',
    name: 'iShares Russell Mid-Cap Growth ETF',
    symbol: 'IWP',
  },
  {
    value: 'HCI',
    label: 'HCI,  HCI Group, Inc.',
    name: 'HCI Group, Inc.',
    symbol: 'HCI',
  },
  {
    value: 'IFMK',
    label: 'IFMK,  iFresh Inc.',
    name: 'iFresh Inc.',
    symbol: 'IFMK',
  },
  {
    value: 'NXRT',
    label: 'NXRT,  NexPoint Residential Trust, Inc.',
    name: 'NexPoint Residential Trust, Inc.',
    symbol: 'NXRT',
  },
  {
    value: 'QQQX',
    label: 'QQQX,  Nuveen Nasdaq 100 Dynamic Overwrite Fund',
    name: 'Nuveen Nasdaq 100 Dynamic Overwrite Fund',
    symbol: 'QQQX',
  },
  {
    value: 'FRPH',
    label: 'FRPH,  FRP Holdings, Inc.',
    name: 'FRP Holdings, Inc.',
    symbol: 'FRPH',
  },
  {
    value: 'GUSH',
    label: 'GUSH,  Direxion Daily S&P Oil & Gas Exp. & Prod. Bull 2X Shares',
    name: 'Direxion Daily S&P Oil & Gas Exp. & Prod. Bull 2X Shares',
    symbol: 'GUSH',
  },
  {
    value: 'ISD',
    label: 'ISD,  PGIM High Yield Bond Fund, Inc.',
    name: 'PGIM High Yield Bond Fund, Inc.',
    symbol: 'ISD',
  },
  {
    value: 'IWY',
    label: 'IWY,  iShares Russell Top 200 Growth ETF',
    name: 'iShares Russell Top 200 Growth ETF',
    symbol: 'IWY',
  },
  {
    value: 'MRIN',
    label: 'MRIN,  Marin Software Incorporated',
    name: 'Marin Software Incorporated',
    symbol: 'MRIN',
  },
  {
    value: 'NLST',
    label: 'NLST,  Netlist, Inc.',
    name: 'Netlist, Inc.',
    symbol: 'NLST',
  },
  {
    value: 'NVG',
    label: 'NVG,  Nuveen AMT-Free Municipal Credit Income Fund',
    name: 'Nuveen AMT-Free Municipal Credit Income Fund',
    symbol: 'NVG',
  },
  {
    value: 'PHT',
    label: 'PHT,  Pioneer High Income Fund, Inc.',
    name: 'Pioneer High Income Fund, Inc.',
    symbol: 'PHT',
  },
  {
    value: 'PIM',
    label: 'PIM,  Putnam Master Intermediate Income Trust',
    name: 'Putnam Master Intermediate Income Trust',
    symbol: 'PIM',
  },
  {
    value: 'PRTS',
    label: 'PRTS,  CarParts.com, Inc.',
    name: 'CarParts.com, Inc.',
    symbol: 'PRTS',
  },
  {
    value: 'TYG',
    label: 'TYG,  Tortoise Energy Infrastructure Corporation',
    name: 'Tortoise Energy Infrastructure Corporation',
    symbol: 'TYG',
  },
  {
    value: 'VBTX',
    label: 'VBTX,  Veritex Holdings, Inc.',
    name: 'Veritex Holdings, Inc.',
    symbol: 'VBTX',
  },
  {
    value: 'FRO',
    label: 'FRO,  Frontline Ltd.',
    name: 'Frontline Ltd.',
    symbol: 'FRO',
  },
  {
    value: 'HY',
    label: 'HY,  Hyster-Yale Materials Handling, Inc.',
    name: 'Hyster-Yale Materials Handling, Inc.',
    symbol: 'HY',
  },
  {
    value: 'BCPC',
    label: 'BCPC,  Balchem Corporation',
    name: 'Balchem Corporation',
    symbol: 'BCPC',
  },
  {
    value: 'CLH',
    label: 'CLH,  Clean Harbors, Inc.',
    name: 'Clean Harbors, Inc.',
    symbol: 'CLH',
  },
  {
    value: 'USNA',
    label: 'USNA,  USANA Health Sciences, Inc.',
    name: 'USANA Health Sciences, Inc.',
    symbol: 'USNA',
  },
  {
    value: 'OTTR',
    label: 'OTTR,  Otter Tail Corporation',
    name: 'Otter Tail Corporation',
    symbol: 'OTTR',
  },
  {
    value: 'CTO',
    label: 'CTO,  CTO Realty Growth, Inc.',
    name: 'CTO Realty Growth, Inc.',
    symbol: 'CTO',
  },
  {
    value: 'VBIV',
    label: 'VBIV,  VBI Vaccines Inc.',
    name: 'VBI Vaccines Inc.',
    symbol: 'VBIV',
  },
  {
    value: 'BIZD',
    label: 'BIZD,  VanEck BDC Income ETF',
    name: 'VanEck BDC Income ETF',
    symbol: 'BIZD',
  },
  {
    value: 'EPHE',
    label: 'EPHE,  iShares MSCI Philippines ETF',
    name: 'iShares MSCI Philippines ETF',
    symbol: 'EPHE',
  },
  {
    value: 'TBPH',
    label: 'TBPH,  Theravance Biopharma, Inc.',
    name: 'Theravance Biopharma, Inc.',
    symbol: 'TBPH',
  },
  {
    value: 'WPP',
    label: 'WPP,  WPP plc',
    name: 'WPP plc',
    symbol: 'WPP',
  },
  {
    value: 'PAHC',
    label: 'PAHC,  Phibro Animal Health Corporation',
    name: 'Phibro Animal Health Corporation',
    symbol: 'PAHC',
  },
  {
    value: 'QUIK',
    label: 'QUIK,  QuickLogic Corporation',
    name: 'QuickLogic Corporation',
    symbol: 'QUIK',
  },
  {
    value: 'FNDX',
    label: 'FNDX,  Schwab Fundamental U.S. Large Company Index ETF',
    name: 'Schwab Fundamental U.S. Large Company Index ETF',
    symbol: 'FNDX',
  },
  {
    value: 'ALDX',
    label: 'ALDX,  Aldeyra Therapeutics, Inc.',
    name: 'Aldeyra Therapeutics, Inc.',
    symbol: 'ALDX',
  },
  {
    value: 'IFN',
    label: 'IFN,  The India Fund, Inc.',
    name: 'The India Fund, Inc.',
    symbol: 'IFN',
  },
  {
    value: 'ONTX',
    label: 'ONTX,  Onconova Therapeutics, Inc.',
    name: 'Onconova Therapeutics, Inc.',
    symbol: 'ONTX',
  },
  {
    value: 'RYE',
    label: 'RYE,  Invesco S&P 500 Equal Weight Energy ETF',
    name: 'Invesco S&P 500 Equal Weight Energy ETF',
    symbol: 'RYE',
  },
  {
    value: 'WWR',
    label: 'WWR,  Westwater Resources, Inc.',
    name: 'Westwater Resources, Inc.',
    symbol: 'WWR',
  },
  {
    value: 'MATX',
    label: 'MATX,  Matson, Inc.',
    name: 'Matson, Inc.',
    symbol: 'MATX',
  },
  {
    value: 'ANGL',
    label: 'ANGL,  VanEck Fallen Angel High Yield Bond ETF',
    name: 'VanEck Fallen Angel High Yield Bond ETF',
    symbol: 'ANGL',
  },
  {
    value: 'HCKT',
    label: 'HCKT,  The Hackett Group, Inc.',
    name: 'The Hackett Group, Inc.',
    symbol: 'HCKT',
  },
  {
    value: 'WSBF',
    label: 'WSBF,  Waterstone Financial, Inc.',
    name: 'Waterstone Financial, Inc.',
    symbol: 'WSBF',
  },
  {
    value: 'PKE',
    label: 'PKE,  Park Aerospace Corp.',
    name: 'Park Aerospace Corp.',
    symbol: 'PKE',
  },
  {
    value: 'JRS',
    label: 'JRS,  Nuveen Real Estate Income Fund',
    name: 'Nuveen Real Estate Income Fund',
    symbol: 'JRS',
  },
  {
    value: 'MAXR',
    label: 'MAXR,  Maxar Technologies Inc.',
    name: 'Maxar Technologies Inc.',
    symbol: 'MAXR',
  },
  {
    value: 'JAGX',
    label: 'JAGX,  Jaguar Health, Inc.',
    name: 'Jaguar Health, Inc.',
    symbol: 'JAGX',
  },
  {
    value: 'CSII',
    label: 'CSII,  Cardiovascular Systems, Inc.',
    name: 'Cardiovascular Systems, Inc.',
    symbol: 'CSII',
  },
  {
    value: 'OXM',
    label: 'OXM,  Oxford Industries, Inc.',
    name: 'Oxford Industries, Inc.',
    symbol: 'OXM',
  },
  {
    value: 'FXP',
    label: 'FXP,  ProShares UltraShort FTSE China 50',
    name: 'ProShares UltraShort FTSE China 50',
    symbol: 'FXP',
  },
  {
    value: 'PFLT',
    label: 'PFLT,  PennantPark Floating Rate Capital Ltd.',
    name: 'PennantPark Floating Rate Capital Ltd.',
    symbol: 'PFLT',
  },
  {
    value: 'CMT',
    label: 'CMT,  Core Molding Technologies, Inc.',
    name: 'Core Molding Technologies, Inc.',
    symbol: 'CMT',
  },
  {
    value: 'ALGT',
    label: 'ALGT,  Allegiant Travel Company',
    name: 'Allegiant Travel Company',
    symbol: 'ALGT',
  },
  {
    value: 'MFIN',
    label: 'MFIN,  Medallion Financial Corp.',
    name: 'Medallion Financial Corp.',
    symbol: 'MFIN',
  },
  {
    value: 'QYLD',
    label: 'QYLD,  Global X NASDAQ 100 Covered Call ETF',
    name: 'Global X NASDAQ 100 Covered Call ETF',
    symbol: 'QYLD',
  },
  {
    value: 'LABU',
    label: 'LABU,  Direxion Daily S&P Biotech Bull 3X Shares',
    name: 'Direxion Daily S&P Biotech Bull 3X Shares',
    symbol: 'LABU',
  },
  {
    value: 'FNHC',
    label: 'FNHC,  FedNat Holding Company',
    name: 'FedNat Holding Company',
    symbol: 'FNHC',
  },
  {
    value: 'FSV',
    label: 'FSV,  FirstService Corporation',
    name: 'FirstService Corporation',
    symbol: 'FSV',
  },
  {
    value: 'NAUH',
    label: 'NAUH,  National American University Holdings, Inc.',
    name: 'National American University Holdings, Inc.',
    symbol: 'NAUH',
  },
  {
    value: 'PFXF',
    label: 'PFXF,  VanEck Preferred Securities ex Financials ETF',
    name: 'VanEck Preferred Securities ex Financials ETF',
    symbol: 'PFXF',
  },
  {
    value: 'RDI',
    label: 'RDI,  Reading International, Inc.',
    name: 'Reading International, Inc.',
    symbol: 'RDI',
  },
  {
    value: 'SMLP',
    label: 'SMLP,  Summit Midstream Partners, LP',
    name: 'Summit Midstream Partners, LP',
    symbol: 'SMLP',
  },
  {
    value: 'HTBK',
    label: 'HTBK,  Heritage Commerce Corp',
    name: 'Heritage Commerce Corp',
    symbol: 'HTBK',
  },
  {
    value: 'CLXT',
    label: 'CLXT,  Calyxt, Inc.',
    name: 'Calyxt, Inc.',
    symbol: 'CLXT',
  },
  {
    value: 'KNSL',
    label: 'KNSL,  Kinsale Capital Group, Inc.',
    name: 'Kinsale Capital Group, Inc.',
    symbol: 'KNSL',
  },
  {
    value: 'DDM',
    label: 'DDM,  ProShares Ultra Dow30',
    name: 'ProShares Ultra Dow30',
    symbol: 'DDM',
  },
  {
    value: 'METC',
    label: 'METC,  Ramaco Resources, Inc.',
    name: 'Ramaco Resources, Inc.',
    symbol: 'METC',
  },
  {
    value: 'STIP',
    label: 'STIP,  iShares 0-5 Year TIPS Bond ETF',
    name: 'iShares 0-5 Year TIPS Bond ETF',
    symbol: 'STIP',
  },
  {
    value: 'NEOG',
    label: 'NEOG,  Neogen Corporation',
    name: 'Neogen Corporation',
    symbol: 'NEOG',
  },
  {
    value: 'GTY',
    label: 'GTY,  Getty Realty Corp.',
    name: 'Getty Realty Corp.',
    symbol: 'GTY',
  },
  {
    value: 'WTS',
    label: 'WTS,  Watts Water Technologies, Inc.',
    name: 'Watts Water Technologies, Inc.',
    symbol: 'WTS',
  },
  {
    value: 'BFZ',
    label: 'BFZ,  BlackRock California Municipal Income Trust',
    name: 'BlackRock California Municipal Income Trust',
    symbol: 'BFZ',
  },
  {
    value: 'HMST',
    label: 'HMST,  HomeStreet, Inc.',
    name: 'HomeStreet, Inc.',
    symbol: 'HMST',
  },
  {
    value: 'FFIC',
    label: 'FFIC,  Flushing Financial Corporation',
    name: 'Flushing Financial Corporation',
    symbol: 'FFIC',
  },
  {
    value: 'ECOM',
    label: 'ECOM,  ChannelAdvisor Corporation',
    name: 'ChannelAdvisor Corporation',
    symbol: 'ECOM',
  },
  {
    value: 'GALT',
    label: 'GALT,  Galectin Therapeutics Inc.',
    name: 'Galectin Therapeutics Inc.',
    symbol: 'GALT',
  },
  {
    value: 'BBW',
    label: 'BBW,  Build-A-Bear Workshop, Inc.',
    name: 'Build-A-Bear Workshop, Inc.',
    symbol: 'BBW',
  },
  {
    value: 'IMKTA',
    label: 'IMKTA,  Ingles Markets, Incorporated',
    name: 'Ingles Markets, Incorporated',
    symbol: 'IMKTA',
  },
  {
    value: 'SPSM',
    label: 'SPSM,  SPDR Portfolio S&P 600 Small Cap ETF',
    name: 'SPDR Portfolio S&P 600 Small Cap ETF',
    symbol: 'SPSM',
  },
  {
    value: 'NRK',
    label: 'NRK,  Nuveen New York AMT-Free Quality Municipal Income Fund',
    name: 'Nuveen New York AMT-Free Quality Municipal Income Fund',
    symbol: 'NRK',
  },
  {
    value: 'RAIL',
    label: 'RAIL,  FreightCar America, Inc.',
    name: 'FreightCar America, Inc.',
    symbol: 'RAIL',
  },
  {
    value: 'KSA',
    label: 'KSA,  iShares MSCI Saudi Arabia ETF',
    name: 'iShares MSCI Saudi Arabia ETF',
    symbol: 'KSA',
  },
  {
    value: 'INPX',
    label: 'INPX,  Inpixon',
    name: 'Inpixon',
    symbol: 'INPX',
  },
  {
    value: 'INDB',
    label: 'INDB,  Independent Bank Corp.',
    name: 'Independent Bank Corp.',
    symbol: 'INDB',
  },
  {
    value: 'IWO',
    label: 'IWO,  iShares Russell 2000 Growth ETF',
    name: 'iShares Russell 2000 Growth ETF',
    symbol: 'IWO',
  },
  {
    value: 'GSLC',
    label: 'GSLC,  Goldman Sachs ActiveBeta U.S. Large Cap Equity ETF',
    name: 'Goldman Sachs ActiveBeta U.S. Large Cap Equity ETF',
    symbol: 'GSLC',
  },
  {
    value: 'AUPH',
    label: 'AUPH,  Aurinia Pharmaceuticals Inc.',
    name: 'Aurinia Pharmaceuticals Inc.',
    symbol: 'AUPH',
  },
  {
    value: 'AVK',
    label: 'AVK,  Advent Convertible and Income Fund',
    name: 'Advent Convertible and Income Fund',
    symbol: 'AVK',
  },
  {
    value: 'HIO',
    label: 'HIO,  Western Asset High Income Opportunity Fund Inc.',
    name: 'Western Asset High Income Opportunity Fund Inc.',
    symbol: 'HIO',
  },
  {
    value: 'NMZ',
    label: 'NMZ,  Nuveen Municipal High Income Opportunity Fund',
    name: 'Nuveen Municipal High Income Opportunity Fund',
    symbol: 'NMZ',
  },
  {
    value: 'PTY',
    label: 'PTY,  PIMCO Corporate & Income Opportunity Fund',
    name: 'PIMCO Corporate & Income Opportunity Fund',
    symbol: 'PTY',
  },
  {
    value: 'TR',
    label: 'TR,  Tootsie Roll Industries, Inc.',
    name: 'Tootsie Roll Industries, Inc.',
    symbol: 'TR',
  },
  {
    value: 'CABO',
    label: 'CABO,  Cable One, Inc.',
    name: 'Cable One, Inc.',
    symbol: 'CABO',
  },
  {
    value: 'LCUT',
    label: 'LCUT,  Lifetime Brands, Inc.',
    name: 'Lifetime Brands, Inc.',
    symbol: 'LCUT',
  },
  {
    value: 'IDOG',
    label: 'IDOG,  ALPS International Sector Dividend Dogs ETF',
    name: 'ALPS International Sector Dividend Dogs ETF',
    symbol: 'IDOG',
  },
  {
    value: 'BWX',
    label: 'BWX,  SPDR Bloomberg International Treasury Bond ETF',
    name: 'SPDR Bloomberg International Treasury Bond ETF',
    symbol: 'BWX',
  },
  {
    value: 'ERIE',
    label: 'ERIE,  Erie Indemnity Company',
    name: 'Erie Indemnity Company',
    symbol: 'ERIE',
  },
  {
    value: 'AAC',
    label: 'AAC,  Ares Acquisition Corporation',
    name: 'Ares Acquisition Corporation',
    symbol: 'AAC',
  },
  {
    value: 'BCX',
    label: 'BCX,  Blackrock Resources & Commodities Strategy Trust',
    name: 'Blackrock Resources & Commodities Strategy Trust',
    symbol: 'BCX',
  },
  {
    value: 'GIM',
    label: 'GIM,  Templeton Global Income Fund',
    name: 'Templeton Global Income Fund',
    symbol: 'GIM',
  },
  {
    value: 'HSTM',
    label: 'HSTM,  HealthStream, Inc.',
    name: 'HealthStream, Inc.',
    symbol: 'HSTM',
  },
  {
    value: 'EEMV',
    label: 'EEMV,  iShares MSCI Emerging Markets Min Vol Factor ETF',
    name: 'iShares MSCI Emerging Markets Min Vol Factor ETF',
    symbol: 'EEMV',
  },
  {
    value: 'AGM',
    label: 'AGM,  Federal Agricultural Mortgage Corporation',
    name: 'Federal Agricultural Mortgage Corporation',
    symbol: 'AGM',
  },
  {
    value: 'DFFN',
    label: 'DFFN,  Diffusion Pharmaceuticals Inc.',
    name: 'Diffusion Pharmaceuticals Inc.',
    symbol: 'DFFN',
  },
  {
    value: 'VIVE',
    label: 'VIVE,  Viveve Medical, Inc.',
    name: 'Viveve Medical, Inc.',
    symbol: 'VIVE',
  },
  {
    value: 'UMH',
    label: 'UMH,  UMH Properties, Inc.',
    name: 'UMH Properties, Inc.',
    symbol: 'UMH',
  },
  {
    value: 'FIX',
    label: 'FIX,  Comfort Systems USA, Inc.',
    name: 'Comfort Systems USA, Inc.',
    symbol: 'FIX',
  },
  {
    value: 'FDUS',
    label: 'FDUS,  Fidus Investment Corporation',
    name: 'Fidus Investment Corporation',
    symbol: 'FDUS',
  },
  {
    value: 'CIVB',
    label: 'CIVB,  Civista Bancshares, Inc.',
    name: 'Civista Bancshares, Inc.',
    symbol: 'CIVB',
  },
  {
    value: 'RKDA',
    label: 'RKDA,  Arcadia Biosciences, Inc.',
    name: 'Arcadia Biosciences, Inc.',
    symbol: 'RKDA',
  },
  {
    value: 'XSLV',
    label: 'XSLV,  Invesco S&P SmallCap Low Volatility ETF',
    name: 'Invesco S&P SmallCap Low Volatility ETF',
    symbol: 'XSLV',
  },
  {
    value: 'OFIX',
    label: 'OFIX,  Orthofix Medical Inc.',
    name: 'Orthofix Medical Inc.',
    symbol: 'OFIX',
  },
  {
    value: 'KVHI',
    label: 'KVHI,  KVH Industries, Inc.',
    name: 'KVH Industries, Inc.',
    symbol: 'KVHI',
  },
  {
    value: 'TTGT',
    label: 'TTGT,  TechTarget, Inc.',
    name: 'TechTarget, Inc.',
    symbol: 'TTGT',
  },
  {
    value: 'HQH',
    label: 'HQH,  Tekla Healthcare Investors',
    name: 'Tekla Healthcare Investors',
    symbol: 'HQH',
  },
  {
    value: 'IWX',
    label: 'IWX,  iShares Russell Top 200 Value ETF',
    name: 'iShares Russell Top 200 Value ETF',
    symbol: 'IWX',
  },
  {
    value: 'ARCB',
    label: 'ARCB,  ArcBest Corporation',
    name: 'ArcBest Corporation',
    symbol: 'ARCB',
  },
  {
    value: 'WABC',
    label: 'WABC,  Westamerica Bancorporation',
    name: 'Westamerica Bancorporation',
    symbol: 'WABC',
  },
  {
    value: 'ACRX',
    label: 'ACRX,  AcelRx Pharmaceuticals, Inc.',
    name: 'AcelRx Pharmaceuticals, Inc.',
    symbol: 'ACRX',
  },
  {
    value: 'CAF',
    label: 'CAF,  Morgan Stanley China A Share Fund, Inc.',
    name: 'Morgan Stanley China A Share Fund, Inc.',
    symbol: 'CAF',
  },
  {
    value: 'DXJS',
    label: 'DXJS,  WisdomTree Japan Hedged SmallCap Equity Fund',
    name: 'WisdomTree Japan Hedged SmallCap Equity Fund',
    symbol: 'DXJS',
  },
  {
    value: 'GLMD',
    label: 'GLMD,  Galmed Pharmaceuticals Ltd.',
    name: 'Galmed Pharmaceuticals Ltd.',
    symbol: 'GLMD',
  },
  {
    value: 'GLOP',
    label: 'GLOP,  GasLog Partners LP',
    name: 'GasLog Partners LP',
    symbol: 'GLOP',
  },
  {
    value: 'ITM',
    label: 'ITM,  VanEck Intermediate Muni ETF',
    name: 'VanEck Intermediate Muni ETF',
    symbol: 'ITM',
  },
  {
    value: 'LODE',
    label: 'LODE,  Comstock Inc.',
    name: 'Comstock Inc.',
    symbol: 'LODE',
  },
  {
    value: 'MDWD',
    label: 'MDWD,  MediWound Ltd.',
    name: 'MediWound Ltd.',
    symbol: 'MDWD',
  },
  {
    value: 'MG',
    label: 'MG,  Mistras Group, Inc.',
    name: 'Mistras Group, Inc.',
    symbol: 'MG',
  },
  {
    value: 'NOVN',
    label: 'NOVN,  Novan, Inc.',
    name: 'Novan, Inc.',
    symbol: 'NOVN',
  },
  {
    value: 'OIA',
    label: 'OIA,  Invesco Municipal Income Opportunities Trust',
    name: 'Invesco Municipal Income Opportunities Trust',
    symbol: 'OIA',
  },
  {
    value: 'PCK',
    label: 'PCK,  PIMCO California Municipal Income Fund II',
    name: 'PIMCO California Municipal Income Fund II',
    symbol: 'PCK',
  },
  {
    value: 'TANH',
    label: 'TANH,  Tantech Holdings Ltd',
    name: 'Tantech Holdings Ltd',
    symbol: 'TANH',
  },
  {
    value: 'TEI',
    label: 'TEI,  Templeton Emerging Markets Income Fund',
    name: 'Templeton Emerging Markets Income Fund',
    symbol: 'TEI',
  },
  {
    value: 'TTT',
    label: 'TTT,  ProShares UltraPro Short 20+ Year Treasury',
    name: 'ProShares UltraPro Short 20+ Year Treasury',
    symbol: 'TTT',
  },
  {
    value: 'VHC',
    label: 'VHC,  VirnetX Holding Corp',
    name: 'VirnetX Holding Corp',
    symbol: 'VHC',
  },
  {
    value: 'BKF',
    label: 'BKF,  iShares MSCI BIC ETF',
    name: 'iShares MSCI BIC ETF',
    symbol: 'BKF',
  },
  {
    value: 'VHI',
    label: 'VHI,  Valhi, Inc.',
    name: 'Valhi, Inc.',
    symbol: 'VHI',
  },
  {
    value: 'PUK',
    label: 'PUK,  Prudential plc',
    name: 'Prudential plc',
    symbol: 'PUK',
  },
  {
    value: 'UBFO',
    label: 'UBFO,  United Security Bancshares',
    name: 'United Security Bancshares',
    symbol: 'UBFO',
  },
  {
    value: 'CNXN',
    label: 'CNXN,  PC Connection, Inc.',
    name: 'PC Connection, Inc.',
    symbol: 'CNXN',
  },
  {
    value: 'FRST',
    label: 'FRST,  Primis Financial Corp.',
    name: 'Primis Financial Corp.',
    symbol: 'FRST',
  },
  {
    value: 'DLB',
    label: 'DLB,  Dolby Laboratories, Inc.',
    name: 'Dolby Laboratories, Inc.',
    symbol: 'DLB',
  },
  {
    value: 'HAYN',
    label: 'HAYN,  Haynes International, Inc.',
    name: 'Haynes International, Inc.',
    symbol: 'HAYN',
  },
  {
    value: 'TCX',
    label: 'TCX,  Tucows Inc.',
    name: 'Tucows Inc.',
    symbol: 'TCX',
  },
  {
    value: 'TPB',
    label: 'TPB,  Turning Point Brands, Inc.',
    name: 'Turning Point Brands, Inc.',
    symbol: 'TPB',
  },
  {
    value: 'CII',
    label: 'CII,  BlackRock Enhanced Capital and Income Fund, Inc.',
    name: 'BlackRock Enhanced Capital and Income Fund, Inc.',
    symbol: 'CII',
  },
  {
    value: 'DWM',
    label: 'DWM,  WisdomTree International Equity Fund',
    name: 'WisdomTree International Equity Fund',
    symbol: 'DWM',
  },
  {
    value: 'SPMD',
    label: 'SPMD,  SPDR Portfolio S&P 400 Mid Cap ETF',
    name: 'SPDR Portfolio S&P 400 Mid Cap ETF',
    symbol: 'SPMD',
  },
  {
    value: 'SPLG',
    label: 'SPLG,  SPDR Portfolio S&P 500 ETF',
    name: 'SPDR Portfolio S&P 500 ETF',
    symbol: 'SPLG',
  },
  {
    value: 'MACK',
    label: 'MACK,  Merrimack Pharmaceuticals, Inc.',
    name: 'Merrimack Pharmaceuticals, Inc.',
    symbol: 'MACK',
  },
  {
    value: 'ACST',
    label: 'ACST,  Acasti Pharma Inc.',
    name: 'Acasti Pharma Inc.',
    symbol: 'ACST',
  },
  {
    value: 'THQ',
    label: 'THQ,  Tekla Healthcare Opportunities Fund',
    name: 'Tekla Healthcare Opportunities Fund',
    symbol: 'THQ',
  },
  {
    value: 'EZM',
    label: 'EZM,  WisdomTree U.S. MidCap Earnings Fund',
    name: 'WisdomTree U.S. MidCap Earnings Fund',
    symbol: 'EZM',
  },
  {
    value: 'MCR',
    label: 'MCR,  MFS Charter Income Trust',
    name: 'MFS Charter Income Trust',
    symbol: 'MCR',
  },
  {
    value: 'IYW',
    label: 'IYW,  iShares U.S. Technology ETF',
    name: 'iShares U.S. Technology ETF',
    symbol: 'IYW',
  },
  {
    value: 'BTO',
    label: 'BTO,  John Hancock Financial Opportunities Fund',
    name: 'John Hancock Financial Opportunities Fund',
    symbol: 'BTO',
  },
  {
    value: 'FFWM',
    label: 'FFWM,  First Foundation Inc.',
    name: 'First Foundation Inc.',
    symbol: 'FFWM',
  },
  {
    value: 'BLOK',
    label: 'BLOK,  Amplify Transformational Data Sharing ETF',
    name: 'Amplify Transformational Data Sharing ETF',
    symbol: 'BLOK',
  },
  {
    value: 'BIP',
    label: 'BIP,  Brookfield Infrastructure Partners L.P.',
    name: 'Brookfield Infrastructure Partners L.P.',
    symbol: 'BIP',
  },
  {
    value: 'CAC',
    label: 'CAC,  Camden National Corporation',
    name: 'Camden National Corporation',
    symbol: 'CAC',
  },
  {
    value: 'NATH',
    label: "NATH,  Nathan's Famous, Inc.",
    name: "Nathan's Famous, Inc.",
    symbol: 'NATH',
  },
  {
    value: 'ANDE',
    label: 'ANDE,  The Andersons, Inc.',
    name: 'The Andersons, Inc.',
    symbol: 'ANDE',
  },
  {
    value: 'WIW',
    label: 'WIW,  Western Asset Inflation-Linked Opportunities & Income Fund',
    name: 'Western Asset Inflation-Linked Opportunities & Income Fund',
    symbol: 'WIW',
  },
  {
    value: 'MCS',
    label: 'MCS,  The Marcus Corporation',
    name: 'The Marcus Corporation',
    symbol: 'MCS',
  },
  {
    value: 'GOOD',
    label: 'GOOD,  Gladstone Commercial Corporation',
    name: 'Gladstone Commercial Corporation',
    symbol: 'GOOD',
  },
  {
    value: 'CIA',
    label: 'CIA,  Citizens, Inc.',
    name: 'Citizens, Inc.',
    symbol: 'CIA',
  },
  {
    value: 'CLW',
    label: 'CLW,  Clearwater Paper Corporation',
    name: 'Clearwater Paper Corporation',
    symbol: 'CLW',
  },
  {
    value: 'AMRK',
    label: 'AMRK,  A-Mark Precious Metals, Inc.',
    name: 'A-Mark Precious Metals, Inc.',
    symbol: 'AMRK',
  },
  {
    value: 'ATHX',
    label: 'ATHX,  Athersys, Inc.',
    name: 'Athersys, Inc.',
    symbol: 'ATHX',
  },
  {
    value: 'CCD',
    label: 'CCD,  Calamos Dynamic Convertible and Income Fund',
    name: 'Calamos Dynamic Convertible and Income Fund',
    symbol: 'CCD',
  },
  {
    value: 'CEM',
    label: 'CEM,  ClearBridge MLP and Midstream Fund Inc',
    name: 'ClearBridge MLP and Midstream Fund Inc',
    symbol: 'CEM',
  },
  {
    value: 'COOL',
    label: 'COOL,  Corner Growth Acquisition Corp.',
    name: 'Corner Growth Acquisition Corp.',
    symbol: 'COOL',
  },
  {
    value: 'DGII',
    label: 'DGII,  Digi International Inc.',
    name: 'Digi International Inc.',
    symbol: 'DGII',
  },
  {
    value: 'GMRE',
    label: 'GMRE,  Global Medical REIT Inc.',
    name: 'Global Medical REIT Inc.',
    symbol: 'GMRE',
  },
  {
    value: 'IHD',
    label: 'IHD,  Voya Emerging Markets High Dividend Equity Fund',
    name: 'Voya Emerging Markets High Dividend Equity Fund',
    symbol: 'IHD',
  },
  {
    value: 'IXN',
    label: 'IXN,  iShares Global Tech ETF',
    name: 'iShares Global Tech ETF',
    symbol: 'IXN',
  },
  {
    value: 'KRYS',
    label: 'KRYS,  Krystal Biotech, Inc.',
    name: 'Krystal Biotech, Inc.',
    symbol: 'KRYS',
  },
  {
    value: 'NTIP',
    label: 'NTIP,  Network-1 Technologies, Inc.',
    name: 'Network-1 Technologies, Inc.',
    symbol: 'NTIP',
  },
  {
    value: 'ORN',
    label: 'ORN,  Orion Group Holdings, Inc.',
    name: 'Orion Group Holdings, Inc.',
    symbol: 'ORN',
  },
  {
    value: 'PEY',
    label: 'PEY,  Invesco High Yield Equity Dividend Achievers ETF',
    name: 'Invesco High Yield Equity Dividend Achievers ETF',
    symbol: 'PEY',
  },
  {
    value: 'PLXP',
    label: 'PLXP,  PLx Pharma Inc.',
    name: 'PLx Pharma Inc.',
    symbol: 'PLXP',
  },
  {
    value: 'SLGL',
    label: 'SLGL,  Sol-Gel Technologies Ltd.',
    name: 'Sol-Gel Technologies Ltd.',
    symbol: 'SLGL',
  },
  {
    value: 'VLGEA',
    label: 'VLGEA,  Village Super Market, Inc.',
    name: 'Village Super Market, Inc.',
    symbol: 'VLGEA',
  },
  {
    value: 'YTRA',
    label: 'YTRA,  Yatra Online, Inc.',
    name: 'Yatra Online, Inc.',
    symbol: 'YTRA',
  },
  {
    value: 'JO',
    label: 'JO,  iPath Series B Bloomberg Coffee Subindex Total Return ETN',
    name: 'iPath Series B Bloomberg Coffee Subindex Total Return ETN',
    symbol: 'JO',
  },
  {
    value: 'MKL',
    label: 'MKL,  Markel Corporation',
    name: 'Markel Corporation',
    symbol: 'MKL',
  },
  {
    value: 'FNDB',
    label: 'FNDB,  Schwab Fundamental U.S. Broad Market Index ETF',
    name: 'Schwab Fundamental U.S. Broad Market Index ETF',
    symbol: 'FNDB',
  },
  {
    value: 'EOI',
    label: 'EOI,  Eaton Vance Enhanced Equity Income Fund',
    name: 'Eaton Vance Enhanced Equity Income Fund',
    symbol: 'EOI',
  },
  {
    value: 'WRLD',
    label: 'WRLD,  World Acceptance Corporation',
    name: 'World Acceptance Corporation',
    symbol: 'WRLD',
  },
  {
    value: 'QAI',
    label: 'QAI,  IQ Hedge Multi-Strategy Tracker ETF',
    name: 'IQ Hedge Multi-Strategy Tracker ETF',
    symbol: 'QAI',
  },
  {
    value: 'FICO',
    label: 'FICO,  Fair Isaac Corporation',
    name: 'Fair Isaac Corporation',
    symbol: 'FICO',
  },
  {
    value: 'GRC',
    label: 'GRC,  The Gorman-Rupp Company',
    name: 'The Gorman-Rupp Company',
    symbol: 'GRC',
  },
  {
    value: 'OLP',
    label: 'OLP,  One Liberty Properties, Inc.',
    name: 'One Liberty Properties, Inc.',
    symbol: 'OLP',
  },
  {
    value: 'PZN',
    label: 'PZN,  Pzena Investment Management, Inc.',
    name: 'Pzena Investment Management, Inc.',
    symbol: 'PZN',
  },
  {
    value: 'SYBT',
    label: 'SYBT,  Stock Yards Bancorp, Inc.',
    name: 'Stock Yards Bancorp, Inc.',
    symbol: 'SYBT',
  },
  {
    value: 'FTF',
    label: 'FTF,  Franklin Limited Duration Income Trust',
    name: 'Franklin Limited Duration Income Trust',
    symbol: 'FTF',
  },
  {
    value: 'HWKN',
    label: 'HWKN,  Hawkins, Inc.',
    name: 'Hawkins, Inc.',
    symbol: 'HWKN',
  },
  {
    value: 'PICB',
    label: 'PICB,  Invesco International Corporate Bond ETF',
    name: 'Invesco International Corporate Bond ETF',
    symbol: 'PICB',
  },
  {
    value: 'DHY',
    label: 'DHY,  Credit Suisse High Yield Bond Fund, Inc.',
    name: 'Credit Suisse High Yield Bond Fund, Inc.',
    symbol: 'DHY',
  },
  {
    value: 'SJW',
    label: 'SJW,  SJW Group',
    name: 'SJW Group',
    symbol: 'SJW',
  },
  {
    value: 'GFF',
    label: 'GFF,  Griffon Corporation',
    name: 'Griffon Corporation',
    symbol: 'GFF',
  },
  {
    value: 'LRGF',
    label: 'LRGF,  iShares U.S. Equity Factor ETF',
    name: 'iShares U.S. Equity Factor ETF',
    symbol: 'LRGF',
  },
  {
    value: 'DRIP',
    label: 'DRIP,  Direxion Daily S&P Oil & Gas Exp. & Prod. Bear 2X Shares',
    name: 'Direxion Daily S&P Oil & Gas Exp. & Prod. Bear 2X Shares',
    symbol: 'DRIP',
  },
  {
    value: 'CHY',
    label: 'CHY,  Calamos Convertible and High Income Fund',
    name: 'Calamos Convertible and High Income Fund',
    symbol: 'CHY',
  },
  {
    value: 'LEO',
    label: 'LEO,  BNY Mellon Strategic Municipals, Inc.',
    name: 'BNY Mellon Strategic Municipals, Inc.',
    symbol: 'LEO',
  },
  {
    value: 'CEVA',
    label: 'CEVA,  CEVA, Inc.',
    name: 'CEVA, Inc.',
    symbol: 'CEVA',
  },
  {
    value: 'AGX',
    label: 'AGX,  Argan, Inc.',
    name: 'Argan, Inc.',
    symbol: 'AGX',
  },
  {
    value: 'XOMA',
    label: 'XOMA,  XOMA Corporation',
    name: 'XOMA Corporation',
    symbol: 'XOMA',
  },
  {
    value: 'SMP',
    label: 'SMP,  Standard Motor Products, Inc.',
    name: 'Standard Motor Products, Inc.',
    symbol: 'SMP',
  },
  {
    value: 'NVIV',
    label: 'NVIV,  InVivo Therapeutics Holdings Corp.',
    name: 'InVivo Therapeutics Holdings Corp.',
    symbol: 'NVIV',
  },
  {
    value: 'AIA',
    label: 'AIA,  iShares Asia 50 ETF',
    name: 'iShares Asia 50 ETF',
    symbol: 'AIA',
  },
  {
    value: 'EFZ',
    label: 'EFZ,  ProShares Short MSCI EAFE',
    name: 'ProShares Short MSCI EAFE',
    symbol: 'EFZ',
  },
  {
    value: 'GNK',
    label: 'GNK,  Genco Shipping & Trading Limited',
    name: 'Genco Shipping & Trading Limited',
    symbol: 'GNK',
  },
  {
    value: 'HCCI',
    label: 'HCCI,  Heritage-Crystal Clean, Inc',
    name: 'Heritage-Crystal Clean, Inc',
    symbol: 'HCCI',
  },
  {
    value: 'HQL',
    label: 'HQL,  Tekla Life Sciences Investors',
    name: 'Tekla Life Sciences Investors',
    symbol: 'HQL',
  },
  {
    value: 'IGI',
    label:
      'IGI,  Western Asset Investment Grade Defined Opportunity Trust Inc.',
    name: 'Western Asset Investment Grade Defined Opportunity Trust Inc.',
    symbol: 'IGI',
  },
  {
    value: 'ILPT',
    label: 'ILPT,  Industrial Logistics Properties Trust',
    name: 'Industrial Logistics Properties Trust',
    symbol: 'ILPT',
  },
  {
    value: 'KIDS',
    label: 'KIDS,  OrthoPediatrics Corp.',
    name: 'OrthoPediatrics Corp.',
    symbol: 'KIDS',
  },
  {
    value: 'PFN',
    label: 'PFN,  PIMCO Income Strategy Fund II',
    name: 'PIMCO Income Strategy Fund II',
    symbol: 'PFN',
  },
  {
    value: 'PULM',
    label: 'PULM,  Pulmatrix, Inc.',
    name: 'Pulmatrix, Inc.',
    symbol: 'PULM',
  },
  {
    value: 'SCYX',
    label: 'SCYX,  SCYNEXIS, Inc.',
    name: 'SCYNEXIS, Inc.',
    symbol: 'SCYX',
  },
  {
    value: 'SECO',
    label: 'SECO,  Secoo Holding Limited',
    name: 'Secoo Holding Limited',
    symbol: 'SECO',
  },
  {
    value: 'XPL',
    label: 'XPL,  Solitario Zinc Corp.',
    name: 'Solitario Zinc Corp.',
    symbol: 'XPL',
  },
  {
    value: 'CHW',
    label: 'CHW,  Calamos Global Dynamic Income Fund',
    name: 'Calamos Global Dynamic Income Fund',
    symbol: 'CHW',
  },
  {
    value: 'EIG',
    label: 'EIG,  Employers Holdings, Inc.',
    name: 'Employers Holdings, Inc.',
    symbol: 'EIG',
  },
  {
    value: 'BST',
    label: 'BST,  BlackRock Science and Technology Trust',
    name: 'BlackRock Science and Technology Trust',
    symbol: 'BST',
  },
  {
    value: 'CHCO',
    label: 'CHCO,  City Holding Company',
    name: 'City Holding Company',
    symbol: 'CHCO',
  },
  {
    value: 'STK',
    label: 'STK,  Columbia Seligman Premium Technology Growth Fund',
    name: 'Columbia Seligman Premium Technology Growth Fund',
    symbol: 'STK',
  },
  {
    value: 'IBCP',
    label: 'IBCP,  Independent Bank Corporation',
    name: 'Independent Bank Corporation',
    symbol: 'IBCP',
  },
  {
    value: 'DNP',
    label: 'DNP,  DNP Select Income Fund Inc.',
    name: 'DNP Select Income Fund Inc.',
    symbol: 'DNP',
  },
  {
    value: 'INSW',
    label: 'INSW,  International Seaways, Inc.',
    name: 'International Seaways, Inc.',
    symbol: 'INSW',
  },
  {
    value: 'NCV',
    label: 'NCV,  Virtus Convertible & Income Fund',
    name: 'Virtus Convertible & Income Fund',
    symbol: 'NCV',
  },
  {
    value: 'PCN',
    label: 'PCN,  PIMCO Corporate & Income Strategy Fund',
    name: 'PIMCO Corporate & Income Strategy Fund',
    symbol: 'PCN',
  },
  {
    value: 'CHT',
    label: 'CHT,  Chunghwa Telecom Co., Ltd.',
    name: 'Chunghwa Telecom Co., Ltd.',
    symbol: 'CHT',
  },
  {
    value: 'EAD',
    label:
      'EAD,  Wells Fargo Advantage Funds - Allspring Income Opportunities Fund',
    name: 'Wells Fargo Advantage Funds - Allspring Income Opportunities Fund',
    symbol: 'EAD',
  },
  {
    value: 'MBWM',
    label: 'MBWM,  Mercantile Bank Corporation',
    name: 'Mercantile Bank Corporation',
    symbol: 'MBWM',
  },
  {
    value: 'ETW',
    label: 'ETW,  Eaton Vance Tax-Managed Global Buy-Write Opportunities Fund',
    name: 'Eaton Vance Tax-Managed Global Buy-Write Opportunities Fund',
    symbol: 'ETW',
  },
  {
    value: 'RCKY',
    label: 'RCKY,  Rocky Brands, Inc.',
    name: 'Rocky Brands, Inc.',
    symbol: 'RCKY',
  },
  {
    value: 'KAMN',
    label: 'KAMN,  Kaman Corporation',
    name: 'Kaman Corporation',
    symbol: 'KAMN',
  },
  {
    value: 'USM',
    label: 'USM,  United States Cellular Corporation',
    name: 'United States Cellular Corporation',
    symbol: 'USM',
  },
  {
    value: 'POWI',
    label: 'POWI,  Power Integrations, Inc.',
    name: 'Power Integrations, Inc.',
    symbol: 'POWI',
  },
  {
    value: 'TDW',
    label: 'TDW,  Tidewater Inc.',
    name: 'Tidewater Inc.',
    symbol: 'TDW',
  },
  {
    value: 'HIL',
    label: 'HIL,  Hill International, Inc.',
    name: 'Hill International, Inc.',
    symbol: 'HIL',
  },
  {
    value: 'NAD',
    label: 'NAD,  Nuveen Quality Municipal Income Fund',
    name: 'Nuveen Quality Municipal Income Fund',
    symbol: 'NAD',
  },
  {
    value: 'OOMA',
    label: 'OOMA,  Ooma, Inc.',
    name: 'Ooma, Inc.',
    symbol: 'OOMA',
  },
  {
    value: 'NTRA',
    label: 'NTRA,  Natera, Inc.',
    name: 'Natera, Inc.',
    symbol: 'NTRA',
  },
  {
    value: 'PGC',
    label: 'PGC,  Peapack-Gladstone Financial Corporation',
    name: 'Peapack-Gladstone Financial Corporation',
    symbol: 'PGC',
  },
  {
    value: 'EPV',
    label: 'EPV,  ProShares UltraShort FTSE Europe',
    name: 'ProShares UltraShort FTSE Europe',
    symbol: 'EPV',
  },
  {
    value: 'UVE',
    label: 'UVE,  Universal Insurance Holdings, Inc.',
    name: 'Universal Insurance Holdings, Inc.',
    symbol: 'UVE',
  },
  {
    value: 'ATAX',
    label: 'ATAX,  America First Multifamily Investors, L.P.',
    name: 'America First Multifamily Investors, L.P.',
    symbol: 'ATAX',
  },
  {
    value: 'BTZ',
    label: 'BTZ,  BlackRock Credit Allocation Income Trust',
    name: 'BlackRock Credit Allocation Income Trust',
    symbol: 'BTZ',
  },
  {
    value: 'CXE',
    label: 'CXE,  MFS High Income Municipal Trust',
    name: 'MFS High Income Municipal Trust',
    symbol: 'CXE',
  },
  {
    value: 'DPG',
    label: 'DPG,  Duff & Phelps Utility and Infrastructure Fund Inc.',
    name: 'Duff & Phelps Utility and Infrastructure Fund Inc.',
    symbol: 'DPG',
  },
  {
    value: 'FFC',
    label: 'FFC,  Flaherty & Crumrine Preferred Securities Income Fund Inc.',
    name: 'Flaherty & Crumrine Preferred Securities Income Fund Inc.',
    symbol: 'FFC',
  },
  {
    value: 'FPF',
    label: 'FPF,  First Trust Intermediate Duration Preferred & Income Fund',
    name: 'First Trust Intermediate Duration Preferred & Income Fund',
    symbol: 'FPF',
  },
  {
    value: 'FXF',
    label: 'FXF,  Invesco CurrencyShares Swiss Franc Trust',
    name: 'Invesco CurrencyShares Swiss Franc Trust',
    symbol: 'FXF',
  },
  {
    value: 'FXN',
    label: 'FXN,  First Trust Energy AlphaDEX Fund',
    name: 'First Trust Energy AlphaDEX Fund',
    symbol: 'FXN',
  },
  {
    value: 'MBOT',
    label: 'MBOT,  Microbot Medical Inc.',
    name: 'Microbot Medical Inc.',
    symbol: 'MBOT',
  },
  {
    value: 'MIXT',
    label: 'MIXT,  MiX Telematics Limited',
    name: 'MiX Telematics Limited',
    symbol: 'MIXT',
  },
  {
    value: 'MOTS',
    label: 'MOTS,  Motus GI Holdings, Inc.',
    name: 'Motus GI Holdings, Inc.',
    symbol: 'MOTS',
  },
  {
    value: 'MTL',
    label: 'MTL,  Mechel PAO',
    name: 'Mechel PAO',
    symbol: 'MTL',
  },
  {
    value: 'NEXT',
    label: 'NEXT,  NextDecade Corporation',
    name: 'NextDecade Corporation',
    symbol: 'NEXT',
  },
  {
    value: 'NFBK',
    label: 'NFBK,  Northfield Bancorp, Inc. (Staten Island, NY)',
    name: 'Northfield Bancorp, Inc. (Staten Island, NY)',
    symbol: 'NFBK',
  },
  {
    value: 'NL',
    label: 'NL,  NL Industries, Inc.',
    name: 'NL Industries, Inc.',
    symbol: 'NL',
  },
  {
    value: 'NRO',
    label: 'NRO,  Neuberger Berman Real Estate Securities Income Fund Inc.',
    name: 'Neuberger Berman Real Estate Securities Income Fund Inc.',
    symbol: 'NRO',
  },
  {
    value: 'YPS',
    label: 'YPS,  Arrow Reverse Cap 500 ETF',
    name: 'Arrow Reverse Cap 500 ETF',
    symbol: 'YPS',
  },
  {
    value: 'UGL',
    label: 'UGL,  ProShares Ultra Gold',
    name: 'ProShares Ultra Gold',
    symbol: 'UGL',
  },
  {
    value: 'USEG',
    label: 'USEG,  U.S. Energy Corp.',
    name: 'U.S. Energy Corp.',
    symbol: 'USEG',
  },
  {
    value: 'SHG',
    label: 'SHG,  Shinhan Financial Group Co., Ltd.',
    name: 'Shinhan Financial Group Co., Ltd.',
    symbol: 'SHG',
  },
  {
    value: 'MQT',
    label: 'MQT,  BlackRock MuniYield Quality Fund II, Inc.',
    name: 'BlackRock MuniYield Quality Fund II, Inc.',
    symbol: 'MQT',
  },
  {
    value: 'APTO',
    label: 'APTO,  Aptose Biosciences Inc.',
    name: 'Aptose Biosciences Inc.',
    symbol: 'APTO',
  },
  {
    value: 'CORR',
    label: 'CORR,  CorEnergy Infrastructure Trust, Inc.',
    name: 'CorEnergy Infrastructure Trust, Inc.',
    symbol: 'CORR',
  },
  {
    value: 'FXR',
    label: 'FXR,  First Trust Industrials/Producer Durables AlphaDEX Fund',
    name: 'First Trust Industrials/Producer Durables AlphaDEX Fund',
    symbol: 'FXR',
  },
  {
    value: 'ARGT',
    label: 'ARGT,  Global X MSCI Argentina ETF',
    name: 'Global X MSCI Argentina ETF',
    symbol: 'ARGT',
  },
  {
    value: 'EGY',
    label: 'EGY,  VAALCO Energy, Inc.',
    name: 'VAALCO Energy, Inc.',
    symbol: 'EGY',
  },
  {
    value: 'MRSN',
    label: 'MRSN,  Mersana Therapeutics, Inc.',
    name: 'Mersana Therapeutics, Inc.',
    symbol: 'MRSN',
  },
  {
    value: 'VHT',
    label: 'VHT,  Vanguard Health Care Index Fund',
    name: 'Vanguard Health Care Index Fund',
    symbol: 'VHT',
  },
  {
    value: 'VBK',
    label: 'VBK,  Vanguard Small Cap Growth Index Fund',
    name: 'Vanguard Small Cap Growth Index Fund',
    symbol: 'VBK',
  },
  {
    value: 'GHM',
    label: 'GHM,  Graham Corporation',
    name: 'Graham Corporation',
    symbol: 'GHM',
  },
  {
    value: 'GWX',
    label: 'GWX,  SPDR S&P International Small Cap ETF',
    name: 'SPDR S&P International Small Cap ETF',
    symbol: 'GWX',
  },
  {
    value: 'GCO',
    label: 'GCO,  Genesco Inc.',
    name: 'Genesco Inc.',
    symbol: 'GCO',
  },
  {
    value: 'VIRX',
    label: 'VIRX,  Viracta Therapeutics, Inc.',
    name: 'Viracta Therapeutics, Inc.',
    symbol: 'VIRX',
  },
  {
    value: 'JBSS',
    label: 'JBSS,  John B. Sanfilippo & Son, Inc.',
    name: 'John B. Sanfilippo & Son, Inc.',
    symbol: 'JBSS',
  },
  {
    value: 'RGR',
    label: 'RGR,  Sturm, Ruger & Company, Inc.',
    name: 'Sturm, Ruger & Company, Inc.',
    symbol: 'RGR',
  },
  {
    value: 'QTEC',
    label: 'QTEC,  First Trust NASDAQ-100-Technology Sector Index Fund',
    name: 'First Trust NASDAQ-100-Technology Sector Index Fund',
    symbol: 'QTEC',
  },
  {
    value: 'LPTH',
    label: 'LPTH,  LightPath Technologies, Inc.',
    name: 'LightPath Technologies, Inc.',
    symbol: 'LPTH',
  },
  {
    value: 'MITT',
    label: 'MITT,  AG Mortgage Investment Trust, Inc.',
    name: 'AG Mortgage Investment Trust, Inc.',
    symbol: 'MITT',
  },
  {
    value: 'REGL',
    label: 'REGL,  ProShares S&P MidCap 400 Dividend Aristocrats ETF',
    name: 'ProShares S&P MidCap 400 Dividend Aristocrats ETF',
    symbol: 'REGL',
  },
  {
    value: 'TTEC',
    label: 'TTEC,  TTEC Holdings, Inc.',
    name: 'TTEC Holdings, Inc.',
    symbol: 'TTEC',
  },
  {
    value: 'FISI',
    label: 'FISI,  Financial Institutions, Inc.',
    name: 'Financial Institutions, Inc.',
    symbol: 'FISI',
  },
  {
    value: 'VCYT',
    label: 'VCYT,  Veracyte, Inc.',
    name: 'Veracyte, Inc.',
    symbol: 'VCYT',
  },
  {
    value: 'CGEN',
    label: 'CGEN,  Compugen Ltd.',
    name: 'Compugen Ltd.',
    symbol: 'CGEN',
  },
  {
    value: 'FM',
    label: 'FM,  iShares MSCI Frontier and Select EM ETF',
    name: 'iShares MSCI Frontier and Select EM ETF',
    symbol: 'FM',
  },
  {
    value: 'JPC',
    label: 'JPC,  Nuveen Preferred & Income Opportunities Fund',
    name: 'Nuveen Preferred & Income Opportunities Fund',
    symbol: 'JPC',
  },
  {
    value: 'MOFG',
    label: 'MOFG,  MidWestOne Financial Group, Inc.',
    name: 'MidWestOne Financial Group, Inc.',
    symbol: 'MOFG',
  },
  {
    value: 'REM',
    label: 'REM,  iShares Mortgage Real Estate Capped ETF',
    name: 'iShares Mortgage Real Estate Capped ETF',
    symbol: 'REM',
  },
  {
    value: 'IPKW',
    label: 'IPKW,  Invesco International BuyBack Achievers ETF',
    name: 'Invesco International BuyBack Achievers ETF',
    symbol: 'IPKW',
  },
  {
    value: 'NVR',
    label: 'NVR,  NVR, Inc.',
    name: 'NVR, Inc.',
    symbol: 'NVR',
  },
  {
    value: 'RNST',
    label: 'RNST,  Renasant Corporation',
    name: 'Renasant Corporation',
    symbol: 'RNST',
  },
  {
    value: 'ULBI',
    label: 'ULBI,  Ultralife Corporation',
    name: 'Ultralife Corporation',
    symbol: 'ULBI',
  },
  {
    value: 'SCHC',
    label: 'SCHC,  Schwab International Small-Cap Equity ETF',
    name: 'Schwab International Small-Cap Equity ETF',
    symbol: 'SCHC',
  },
  {
    value: 'ESE',
    label: 'ESE,  ESCO Technologies Inc.',
    name: 'ESCO Technologies Inc.',
    symbol: 'ESE',
  },
  {
    value: 'FBMS',
    label: 'FBMS,  The First Bancshares, Inc.',
    name: 'The First Bancshares, Inc.',
    symbol: 'FBMS',
  },
  {
    value: 'ACIU',
    label: 'ACIU,  AC Immune SA',
    name: 'AC Immune SA',
    symbol: 'ACIU',
  },
  {
    value: 'KELYA',
    label: 'KELYA,  Kelly Services, Inc.',
    name: 'Kelly Services, Inc.',
    symbol: 'KELYA',
  },
  {
    value: 'TMP',
    label: 'TMP,  Tompkins Financial Corporation',
    name: 'Tompkins Financial Corporation',
    symbol: 'TMP',
  },
  {
    value: 'WIP',
    label:
      'WIP,  SPDR FTSE International Government Inflation-Protected Bond ETF',
    name: 'SPDR FTSE International Government Inflation-Protected Bond ETF',
    symbol: 'WIP',
  },
  {
    value: 'ACRE',
    label: 'ACRE,  Ares Commercial Real Estate Corporation',
    name: 'Ares Commercial Real Estate Corporation',
    symbol: 'ACRE',
  },
  {
    value: 'IMOS',
    label: 'IMOS,  ChipMOS TECHNOLOGIES INC.',
    name: 'ChipMOS TECHNOLOGIES INC.',
    symbol: 'IMOS',
  },
  {
    value: 'HFRO',
    label: 'HFRO,  Highland Funds I - Highland Income Fund',
    name: 'Highland Funds I - Highland Income Fund',
    symbol: 'HFRO',
  },
  {
    value: 'SPOK',
    label: 'SPOK,  Spok Holdings, Inc.',
    name: 'Spok Holdings, Inc.',
    symbol: 'SPOK',
  },
  {
    value: 'CAMT',
    label: 'CAMT,  Camtek Ltd.',
    name: 'Camtek Ltd.',
    symbol: 'CAMT',
  },
  {
    value: 'DCF',
    label:
      'DCF,  BNY Mellon Alcentra Global Credit Income 2024 Target Term Fund, Inc.',
    name: 'BNY Mellon Alcentra Global Credit Income 2024 Target Term Fund, Inc.',
    symbol: 'DCF',
  },
  {
    value: 'DGS',
    label: 'DGS,  WisdomTree Emerging Markets SmallCap Dividend Fund',
    name: 'WisdomTree Emerging Markets SmallCap Dividend Fund',
    symbol: 'DGS',
  },
  {
    value: 'ERY',
    label: 'ERY,  Direxion Daily Energy Bear 2X Shares',
    name: 'Direxion Daily Energy Bear 2X Shares',
    symbol: 'ERY',
  },
  {
    value: 'MCBC',
    label: 'MCBC,  Macatawa Bank Corporation',
    name: 'Macatawa Bank Corporation',
    symbol: 'MCBC',
  },
  {
    value: 'MDIV',
    label: 'MDIV,  Multi-Asset Diversified Income Index Fund',
    name: 'Multi-Asset Diversified Income Index Fund',
    symbol: 'MDIV',
  },
  {
    value: 'NIM',
    label: 'NIM,  Nuveen Select Maturities Municipal Fund',
    name: 'Nuveen Select Maturities Municipal Fund',
    symbol: 'NIM',
  },
  {
    value: 'NRP',
    label: 'NRP,  Natural Resource Partners L.P.',
    name: 'Natural Resource Partners L.P.',
    symbol: 'NRP',
  },
  {
    value: 'PBT',
    label: 'PBT,  Permian Basin Royalty Trust',
    name: 'Permian Basin Royalty Trust',
    symbol: 'PBT',
  },
  {
    value: 'PLUS',
    label: 'PLUS,  ePlus inc.',
    name: 'ePlus inc.',
    symbol: 'PLUS',
  },
  {
    value: 'SELB',
    label: 'SELB,  Selecta Biosciences, Inc.',
    name: 'Selecta Biosciences, Inc.',
    symbol: 'SELB',
  },
  {
    value: 'TBF',
    label: 'TBF,  ProShares Short 20+ Year Treasury',
    name: 'ProShares Short 20+ Year Treasury',
    symbol: 'TBF',
  },
  {
    value: 'THFF',
    label: 'THFF,  First Financial Corporation',
    name: 'First Financial Corporation',
    symbol: 'THFF',
  },
  {
    value: 'FGB',
    label:
      'FGB,  First Trust Specialty Finance and Financial Opportunities Fund',
    name: 'First Trust Specialty Finance and Financial Opportunities Fund',
    symbol: 'FGB',
  },
  {
    value: 'RFIL',
    label: 'RFIL,  RF Industries, Ltd.',
    name: 'RF Industries, Ltd.',
    symbol: 'RFIL',
  },
  {
    value: 'NCSM',
    label: 'NCSM,  NCS Multistage Holdings, Inc.',
    name: 'NCS Multistage Holdings, Inc.',
    symbol: 'NCSM',
  },
  {
    value: 'PRFT',
    label: 'PRFT,  Perficient, Inc.',
    name: 'Perficient, Inc.',
    symbol: 'PRFT',
  },
  {
    value: 'PFS',
    label: 'PFS,  Provident Financial Services, Inc.',
    name: 'Provident Financial Services, Inc.',
    symbol: 'PFS',
  },
  {
    value: 'CSWI',
    label: 'CSWI,  CSW Industrials, Inc.',
    name: 'CSW Industrials, Inc.',
    symbol: 'CSWI',
  },
  {
    value: 'MPA',
    label: 'MPA,  BlackRock MuniYield Pennsylvania Quality Fund',
    name: 'BlackRock MuniYield Pennsylvania Quality Fund',
    symbol: 'MPA',
  },
  {
    value: 'TPHS',
    label: 'TPHS,  Trinity Place Holdings Inc.',
    name: 'Trinity Place Holdings Inc.',
    symbol: 'TPHS',
  },
  {
    value: 'ECON',
    label: 'ECON,  Columbia Emerging Markets Consumer ETF',
    name: 'Columbia Emerging Markets Consumer ETF',
    symbol: 'ECON',
  },
  {
    value: 'HYLB',
    label: 'HYLB,  Xtrackers USD High Yield Corporate Bond ETF',
    name: 'Xtrackers USD High Yield Corporate Bond ETF',
    symbol: 'HYLB',
  },
  {
    value: 'DEM',
    label: 'DEM,  WisdomTree Emerging Markets High Dividend Fund',
    name: 'WisdomTree Emerging Markets High Dividend Fund',
    symbol: 'DEM',
  },
  {
    value: 'BLBD',
    label: 'BLBD,  Blue Bird Corporation',
    name: 'Blue Bird Corporation',
    symbol: 'BLBD',
  },
  {
    value: 'NCNA',
    label: 'NCNA,  NuCana plc',
    name: 'NuCana plc',
    symbol: 'NCNA',
  },
  {
    value: 'BSCM',
    label: 'BSCM,  Invesco BulletShares 2022 Corporate Bond ETF',
    name: 'Invesco BulletShares 2022 Corporate Bond ETF',
    symbol: 'BSCM',
  },
  {
    value: 'KMPH',
    label: 'KMPH,  KemPharm, Inc.',
    name: 'KemPharm, Inc.',
    symbol: 'KMPH',
  },
  {
    value: 'CDTI',
    label: 'CDTI,  CDTi Advanced Materials, Inc.',
    name: 'CDTi Advanced Materials, Inc.',
    symbol: 'CDTI',
  },
  {
    value: 'PDP',
    label: 'PDP,  Invesco DWA Momentum ETF',
    name: 'Invesco DWA Momentum ETF',
    symbol: 'PDP',
  },
  {
    value: 'ZLAB',
    label: 'ZLAB,  Zai Lab Limited',
    name: 'Zai Lab Limited',
    symbol: 'ZLAB',
  },
  {
    value: 'AMSC',
    label: 'AMSC,  American Superconductor Corporation',
    name: 'American Superconductor Corporation',
    symbol: 'AMSC',
  },
  {
    value: 'RCKT',
    label: 'RCKT,  Rocket Pharmaceuticals, Inc.',
    name: 'Rocket Pharmaceuticals, Inc.',
    symbol: 'RCKT',
  },
  {
    value: 'VNQI',
    label: 'VNQI,  Vanguard Global ex-U.S. Real Estate Index Fund',
    name: 'Vanguard Global ex-U.S. Real Estate Index Fund',
    symbol: 'VNQI',
  },
  {
    value: 'TGB',
    label: 'TGB,  Taseko Mines Limited',
    name: 'Taseko Mines Limited',
    symbol: 'TGB',
  },
  {
    value: 'FBT',
    label: 'FBT,  First Trust NYSE Arca Biotechnology Index Fund',
    name: 'First Trust NYSE Arca Biotechnology Index Fund',
    symbol: 'FBT',
  },
  {
    value: 'YRD',
    label: 'YRD,  Yiren Digital Ltd.',
    name: 'Yiren Digital Ltd.',
    symbol: 'YRD',
  },
  {
    value: 'AOM',
    label: 'AOM,  iShares Core Moderate Allocation ETF',
    name: 'iShares Core Moderate Allocation ETF',
    symbol: 'AOM',
  },
  {
    value: 'FGM',
    label: 'FGM,  First Trust Germany AlphaDEX Fund',
    name: 'First Trust Germany AlphaDEX Fund',
    symbol: 'FGM',
  },
  {
    value: 'GLO',
    label: 'GLO,  Clough Global Opportunities Fund',
    name: 'Clough Global Opportunities Fund',
    symbol: 'GLO',
  },
  {
    value: 'RWM',
    label: 'RWM,  ProShares Short Russell2000',
    name: 'ProShares Short Russell2000',
    symbol: 'RWM',
  },
  {
    value: 'LEMB',
    label: 'LEMB,  iShares J.P. Morgan EM Local Currency Bond ETF',
    name: 'iShares J.P. Morgan EM Local Currency Bond ETF',
    symbol: 'LEMB',
  },
  {
    value: 'GAIN',
    label: 'GAIN,  Gladstone Investment Corporation',
    name: 'Gladstone Investment Corporation',
    symbol: 'GAIN',
  },
  {
    value: 'JRI',
    label: 'JRI,  Nuveen Real Asset Income and Growth Fund',
    name: 'Nuveen Real Asset Income and Growth Fund',
    symbol: 'JRI',
  },
  {
    value: 'LRN',
    label: 'LRN,  Stride, Inc.',
    name: 'Stride, Inc.',
    symbol: 'LRN',
  },
  {
    value: 'GNT',
    label: 'GNT,  GAMCO Natural Resources, Gold & Income Trust',
    name: 'GAMCO Natural Resources, Gold & Income Trust',
    symbol: 'GNT',
  },
  {
    value: 'GXG',
    label: 'GXG,  Global X MSCI Colombia ETF',
    name: 'Global X MSCI Colombia ETF',
    symbol: 'GXG',
  },
  {
    value: 'MBIO',
    label: 'MBIO,  Mustang Bio, Inc.',
    name: 'Mustang Bio, Inc.',
    symbol: 'MBIO',
  },
  {
    value: 'ARDC',
    label: 'ARDC,  Ares Dynamic Credit Allocation Fund, Inc.',
    name: 'Ares Dynamic Credit Allocation Fund, Inc.',
    symbol: 'ARDC',
  },
  {
    value: 'CEV',
    label: 'CEV,  Eaton Vance California Municipal Income Trust',
    name: 'Eaton Vance California Municipal Income Trust',
    symbol: 'CEV',
  },
  {
    value: 'COPX',
    label: 'COPX,  Global X Copper Miners ETF',
    name: 'Global X Copper Miners ETF',
    symbol: 'COPX',
  },
  {
    value: 'ECC',
    label: 'ECC,  Eagle Point Credit Company Inc.',
    name: 'Eagle Point Credit Company Inc.',
    symbol: 'ECC',
  },
  {
    value: 'EFT',
    label: 'EFT,  Eaton Vance Floating-Rate Income Trust',
    name: 'Eaton Vance Floating-Rate Income Trust',
    symbol: 'EFT',
  },
  {
    value: 'EHI',
    label: 'EHI,  Western Asset Global High Income Fund Inc.',
    name: 'Western Asset Global High Income Fund Inc.',
    symbol: 'EHI',
  },
  {
    value: 'EOD',
    label:
      'EOD,  Wells Fargo Advantage Funds - Allspring Global Dividend Opportunity Fund',
    name: 'Wells Fargo Advantage Funds - Allspring Global Dividend Opportunity Fund',
    symbol: 'EOD',
  },
  {
    value: 'ETG',
    label: 'ETG,  Eaton Vance Tax-Advantaged Global Dividend Income Fund',
    name: 'Eaton Vance Tax-Advantaged Global Dividend Income Fund',
    symbol: 'ETG',
  },
  {
    value: 'FSD',
    label: 'FSD,  First Trust High Income Long/Short Fund',
    name: 'First Trust High Income Long/Short Fund',
    symbol: 'FSD',
  },
  {
    value: 'IFRX',
    label: 'IFRX,  InflaRx N.V.',
    name: 'InflaRx N.V.',
    symbol: 'IFRX',
  },
  {
    value: 'JGH',
    label: 'JGH,  Nuveen Global High Income Fund',
    name: 'Nuveen Global High Income Fund',
    symbol: 'JGH',
  },
  {
    value: 'KNOP',
    label: 'KNOP,  KNOT Offshore Partners LP',
    name: 'KNOT Offshore Partners LP',
    symbol: 'KNOP',
  },
  {
    value: 'LMBS',
    label: 'LMBS,  First Trust Low Duration Opportunities ETF',
    name: 'First Trust Low Duration Opportunities ETF',
    symbol: 'LMBS',
  },
  {
    value: 'OCX',
    label: 'OCX,  OncoCyte Corporation',
    name: 'OncoCyte Corporation',
    symbol: 'OCX',
  },
  {
    value: 'RENN',
    label: 'RENN,  Renren Inc.',
    name: 'Renren Inc.',
    symbol: 'RENN',
  },
  {
    value: 'VRTV',
    label: 'VRTV,  Veritiv Corporation',
    name: 'Veritiv Corporation',
    symbol: 'VRTV',
  },
  {
    value: 'WKHS',
    label: 'WKHS,  Workhorse Group Inc.',
    name: 'Workhorse Group Inc.',
    symbol: 'WKHS',
  },
  {
    value: 'TSI',
    label: 'TSI,  TCW Strategic Income Fund, Inc.',
    name: 'TCW Strategic Income Fund, Inc.',
    symbol: 'TSI',
  },
  {
    value: 'CLLS',
    label: 'CLLS,  Cellectis S.A.',
    name: 'Cellectis S.A.',
    symbol: 'CLLS',
  },
  {
    value: 'JJSF',
    label: 'JJSF,  J&J Snack Foods Corp.',
    name: 'J&J Snack Foods Corp.',
    symbol: 'JJSF',
  },
  {
    value: 'TTEK',
    label: 'TTEK,  Tetra Tech, Inc.',
    name: 'Tetra Tech, Inc.',
    symbol: 'TTEK',
  },
  {
    value: 'CTBI',
    label: 'CTBI,  Community Trust Bancorp, Inc.',
    name: 'Community Trust Bancorp, Inc.',
    symbol: 'CTBI',
  },
  {
    value: 'REMX',
    label: 'REMX,  VanEck Rare Earth/Strategic Metals ETF',
    name: 'VanEck Rare Earth/Strategic Metals ETF',
    symbol: 'REMX',
  },
  {
    value: 'CCF',
    label: 'CCF,  Chase Corporation',
    name: 'Chase Corporation',
    symbol: 'CCF',
  },
  {
    value: 'YCS',
    label: 'YCS,  ProShares UltraShort Yen',
    name: 'ProShares UltraShort Yen',
    symbol: 'YCS',
  },
  {
    value: 'FVD',
    label: 'FVD,  First Trust Value Line Dividend Index Fund',
    name: 'First Trust Value Line Dividend Index Fund',
    symbol: 'FVD',
  },
  {
    value: 'HAWX',
    label: 'HAWX,  iShares Currency Hedged MSCI ACWI ex U.S. ETF',
    name: 'iShares Currency Hedged MSCI ACWI ex U.S. ETF',
    symbol: 'HAWX',
  },
  {
    value: 'IHG',
    label: 'IHG,  InterContinental Hotels Group PLC',
    name: 'InterContinental Hotels Group PLC',
    symbol: 'IHG',
  },
  {
    value: 'RFDA',
    label: 'RFDA,  RiverFront Dynamic US Dividend Advantage ETF',
    name: 'RiverFront Dynamic US Dividend Advantage ETF',
    symbol: 'RFDA',
  },
  {
    value: 'BATRA',
    label: 'BATRA,  The Liberty Braves Group',
    name: 'The Liberty Braves Group',
    symbol: 'BATRA',
  },
  {
    value: 'DZZ',
    label: 'DZZ,  DB Gold Double Short ETN',
    name: 'DB Gold Double Short ETN',
    symbol: 'DZZ',
  },
  {
    value: 'FBIO',
    label: 'FBIO,  Fortress Biotech, Inc.',
    name: 'Fortress Biotech, Inc.',
    symbol: 'FBIO',
  },
  {
    value: 'KIO',
    label: 'KIO,  KKR Income Opportunities Fund',
    name: 'KKR Income Opportunities Fund',
    symbol: 'KIO',
  },
  {
    value: 'BSRR',
    label: 'BSRR,  Sierra Bancorp',
    name: 'Sierra Bancorp',
    symbol: 'BSRR',
  },
  {
    value: 'EDUC',
    label: 'EDUC,  Educational Development Corporation',
    name: 'Educational Development Corporation',
    symbol: 'EDUC',
  },
  {
    value: 'SOR',
    label: 'SOR,  Source Capital, Inc.',
    name: 'Source Capital, Inc.',
    symbol: 'SOR',
  },
  {
    value: 'PSLV',
    label: 'PSLV,  Sprott Physical Silver Trust',
    name: 'Sprott Physical Silver Trust',
    symbol: 'PSLV',
  },
  {
    value: 'NVMI',
    label: 'NVMI,  Nova Ltd.',
    name: 'Nova Ltd.',
    symbol: 'NVMI',
  },
  {
    value: 'PXJ',
    label: 'PXJ,  Invesco Dynamic Oil & Gas Services ETF',
    name: 'Invesco Dynamic Oil & Gas Services ETF',
    symbol: 'PXJ',
  },
  {
    value: 'KMF',
    label: 'KMF,  Kayne Anderson NextGen Energy & Infrastructure, Inc.',
    name: 'Kayne Anderson NextGen Energy & Infrastructure, Inc.',
    symbol: 'KMF',
  },
  {
    value: 'KWEB',
    label: 'KWEB,  KraneShares CSI China Internet ETF',
    name: 'KraneShares CSI China Internet ETF',
    symbol: 'KWEB',
  },
  {
    value: 'FLXS',
    label: 'FLXS,  Flexsteel Industries, Inc.',
    name: 'Flexsteel Industries, Inc.',
    symbol: 'FLXS',
  },
  {
    value: 'VALU',
    label: 'VALU,  Value Line, Inc.',
    name: 'Value Line, Inc.',
    symbol: 'VALU',
  },
  {
    value: 'RIBT',
    label: 'RIBT,  RiceBran Technologies',
    name: 'RiceBran Technologies',
    symbol: 'RIBT',
  },
  {
    value: 'DDWM',
    label:
      'DDWM,  WisdomTree Dynamic Currency Hedged International Equity Fund',
    name: 'WisdomTree Dynamic Currency Hedged International Equity Fund',
    symbol: 'DDWM',
  },
  {
    value: 'HACK',
    label: 'HACK,  ETFMG Prime Cyber Security ETF',
    name: 'ETFMG Prime Cyber Security ETF',
    symbol: 'HACK',
  },
  {
    value: 'MYE',
    label: 'MYE,  Myers Industries, Inc.',
    name: 'Myers Industries, Inc.',
    symbol: 'MYE',
  },
  {
    value: 'CWEB',
    label: 'CWEB,  Direxion Daily CSI China Internet Bull 2X Shares',
    name: 'Direxion Daily CSI China Internet Bull 2X Shares',
    symbol: 'CWEB',
  },
  {
    value: 'PMF',
    label: 'PMF,  PIMCO Municipal Income Fund',
    name: 'PIMCO Municipal Income Fund',
    symbol: 'PMF',
  },
  {
    value: 'GLPG',
    label: 'GLPG,  Galapagos NV',
    name: 'Galapagos NV',
    symbol: 'GLPG',
  },
  {
    value: 'RLGT',
    label: 'RLGT,  Radiant Logistics, Inc.',
    name: 'Radiant Logistics, Inc.',
    symbol: 'RLGT',
  },
  {
    value: 'FC',
    label: 'FC,  Franklin Covey Co.',
    name: 'Franklin Covey Co.',
    symbol: 'FC',
  },
  {
    value: 'NC',
    label: 'NC,  NACCO Industries, Inc.',
    name: 'NACCO Industries, Inc.',
    symbol: 'NC',
  },
  {
    value: 'APT',
    label: 'APT,  Alpha Pro Tech, Ltd.',
    name: 'Alpha Pro Tech, Ltd.',
    symbol: 'APT',
  },
  {
    value: 'EWX',
    label: 'EWX,  SPDR S&P Emerging Markets Small Cap ETF',
    name: 'SPDR S&P Emerging Markets Small Cap ETF',
    symbol: 'EWX',
  },
  {
    value: 'EVI',
    label: 'EVI,  EVI Industries, Inc.',
    name: 'EVI Industries, Inc.',
    symbol: 'EVI',
  },
  {
    value: 'AGRX',
    label: 'AGRX,  Agile Therapeutics, Inc.',
    name: 'Agile Therapeutics, Inc.',
    symbol: 'AGRX',
  },
  {
    value: 'AGTC',
    label: 'AGTC,  Applied Genetic Technologies Corporation',
    name: 'Applied Genetic Technologies Corporation',
    symbol: 'AGTC',
  },
  {
    value: 'ALLT',
    label: 'ALLT,  Allot Ltd.',
    name: 'Allot Ltd.',
    symbol: 'ALLT',
  },
  {
    value: 'BANX',
    label: 'BANX,  ArrowMark Financial Corp.',
    name: 'ArrowMark Financial Corp.',
    symbol: 'BANX',
  },
  {
    value: 'BFK',
    label: 'BFK,  BlackRock Municipal Income Trust',
    name: 'BlackRock Municipal Income Trust',
    symbol: 'BFK',
  },
  {
    value: 'CANE',
    label: 'CANE,  Teucrium Sugar Fund',
    name: 'Teucrium Sugar Fund',
    symbol: 'CANE',
  },
  {
    value: 'CBIO',
    label: 'CBIO,  Catalyst Biosciences, Inc.',
    name: 'Catalyst Biosciences, Inc.',
    symbol: 'CBIO',
  },
  {
    value: 'CEA',
    label: 'CEA,  China Eastern Airlines Corporation Limited',
    name: 'China Eastern Airlines Corporation Limited',
    symbol: 'CEA',
  },
  {
    value: 'CNET',
    label: 'CNET,  ZW Data Action Technologies Inc.',
    name: 'ZW Data Action Technologies Inc.',
    symbol: 'CNET',
  },
  {
    value: 'DCPH',
    label: 'DCPH,  Deciphera Pharmaceuticals, Inc.',
    name: 'Deciphera Pharmaceuticals, Inc.',
    symbol: 'DCPH',
  },
  {
    value: 'HBB',
    label: 'HBB,  Hamilton Beach Brands Holding Company',
    name: 'Hamilton Beach Brands Holding Company',
    symbol: 'HBB',
  },
  {
    value: 'HOFT',
    label: 'HOFT,  Hooker Furnishings Corporation',
    name: 'Hooker Furnishings Corporation',
    symbol: 'HOFT',
  },
  {
    value: 'HYZD',
    label: 'HYZD,  WisdomTree Interest Rate Hedged High Yield Bond Fund',
    name: 'WisdomTree Interest Rate Hedged High Yield Bond Fund',
    symbol: 'HYZD',
  },
  {
    value: 'ICLK',
    label: 'ICLK,  iClick Interactive Asia Group Limited',
    name: 'iClick Interactive Asia Group Limited',
    symbol: 'ICLK',
  },
  {
    value: 'IGA',
    label: 'IGA,  Voya Global Advantage and Premium Opportunity Fund',
    name: 'Voya Global Advantage and Premium Opportunity Fund',
    symbol: 'IGA',
  },
  {
    value: 'IIM',
    label: 'IIM,  Invesco Value Municipal Income Trust',
    name: 'Invesco Value Municipal Income Trust',
    symbol: 'IIM',
  },
  {
    value: 'ISTR',
    label: 'ISTR,  Investar Holding Corporation',
    name: 'Investar Holding Corporation',
    symbol: 'ISTR',
  },
  {
    value: 'KBAL',
    label: 'KBAL,  Kimball International, Inc.',
    name: 'Kimball International, Inc.',
    symbol: 'KBAL',
  },
  {
    value: 'LNDC',
    label: 'LNDC,  Landec Corporation',
    name: 'Landec Corporation',
    symbol: 'LNDC',
  },
  {
    value: 'NAN',
    label: 'NAN,  Nuveen New York Quality Municipal Income Fund',
    name: 'Nuveen New York Quality Municipal Income Fund',
    symbol: 'NAN',
  },
  {
    value: 'NDP',
    label: 'NDP,  Tortoise Energy Independence Fund, Inc.',
    name: 'Tortoise Energy Independence Fund, Inc.',
    symbol: 'NDP',
  },
  {
    value: 'NWPX',
    label: 'NWPX,  Northwest Pipe Company',
    name: 'Northwest Pipe Company',
    symbol: 'NWPX',
  },
  {
    value: 'PRFZ',
    label: 'PRFZ,  Invesco FTSE RAFI US 1500 Small-Mid ETF',
    name: 'Invesco FTSE RAFI US 1500 Small-Mid ETF',
    symbol: 'PRFZ',
  },
  {
    value: 'RM',
    label: 'RM,  Regional Management Corp.',
    name: 'Regional Management Corp.',
    symbol: 'RM',
  },
  {
    value: 'SYRS',
    label: 'SYRS,  Syros Pharmaceuticals, Inc.',
    name: 'Syros Pharmaceuticals, Inc.',
    symbol: 'SYRS',
  },
  {
    value: 'UTL',
    label: 'UTL,  Unitil Corporation',
    name: 'Unitil Corporation',
    symbol: 'UTL',
  },
  {
    value: 'WNEB',
    label: 'WNEB,  Western New England Bancorp, Inc.',
    name: 'Western New England Bancorp, Inc.',
    symbol: 'WNEB',
  },
  {
    value: 'ZEUS',
    label: 'ZEUS,  Olympic Steel, Inc.',
    name: 'Olympic Steel, Inc.',
    symbol: 'ZEUS',
  },
  {
    value: 'MVF',
    label: 'MVF,  BlackRock MuniVest Fund, Inc.',
    name: 'BlackRock MuniVest Fund, Inc.',
    symbol: 'MVF',
  },
  {
    value: 'ERM',
    label: 'ERM,  EquityCompass Risk Manager ETF',
    name: 'EquityCompass Risk Manager ETF',
    symbol: 'ERM',
  },
  {
    value: 'NYF',
    label: 'NYF,  iShares New York Muni Bond ETF',
    name: 'iShares New York Muni Bond ETF',
    symbol: 'NYF',
  },
  {
    value: 'SCL',
    label: 'SCL,  Stepan Company',
    name: 'Stepan Company',
    symbol: 'SCL',
  },
  {
    value: 'SALM',
    label: 'SALM,  Salem Media Group, Inc.',
    name: 'Salem Media Group, Inc.',
    symbol: 'SALM',
  },
  {
    value: 'EOS',
    label: 'EOS,  Eaton Vance Enhanced Equity Income Fund II',
    name: 'Eaton Vance Enhanced Equity Income Fund II',
    symbol: 'EOS',
  },
  {
    value: 'NEWT',
    label: 'NEWT,  Newtek Business Services Corp.',
    name: 'Newtek Business Services Corp.',
    symbol: 'NEWT',
  },
  {
    value: 'NH',
    label: 'NH,  NantHealth, Inc.',
    name: 'NantHealth, Inc.',
    symbol: 'NH',
  },
  {
    value: 'SPXX',
    label: 'SPXX,  Nuveen S&P 500 Dynamic Overwrite Fund',
    name: 'Nuveen S&P 500 Dynamic Overwrite Fund',
    symbol: 'SPXX',
  },
  {
    value: 'SPNE',
    label: 'SPNE,  SeaSpine Holdings Corporation',
    name: 'SeaSpine Holdings Corporation',
    symbol: 'SPNE',
  },
  {
    value: 'FORR',
    label: 'FORR,  Forrester Research, Inc.',
    name: 'Forrester Research, Inc.',
    symbol: 'FORR',
  },
  {
    value: 'IYT',
    label: 'IYT,  iShares Transportation Average ETF',
    name: 'iShares Transportation Average ETF',
    symbol: 'IYT',
  },
  {
    value: 'NTP',
    label: 'NTP,  Nam Tai Property Inc.',
    name: 'Nam Tai Property Inc.',
    symbol: 'NTP',
  },
  {
    value: 'STRL',
    label: 'STRL,  Sterling Infrastructure, Inc.',
    name: 'Sterling Infrastructure, Inc.',
    symbol: 'STRL',
  },
  {
    value: 'BLCN',
    label: 'BLCN,  Siren Nasdaq NexGen Economy ETF',
    name: 'Siren Nasdaq NexGen Economy ETF',
    symbol: 'BLCN',
  },
  {
    value: 'MRUS',
    label: 'MRUS,  Merus N.V.',
    name: 'Merus N.V.',
    symbol: 'MRUS',
  },
  {
    value: 'RILY',
    label: 'RILY,  B. Riley Financial, Inc.',
    name: 'B. Riley Financial, Inc.',
    symbol: 'RILY',
  },
  {
    value: 'DBL',
    label: 'DBL,  DoubleLine Opportunistic Credit Fund',
    name: 'DoubleLine Opportunistic Credit Fund',
    symbol: 'DBL',
  },
  {
    value: 'MUI',
    label: 'MUI,  BlackRock Municipal Income Fund, Inc.',
    name: 'BlackRock Municipal Income Fund, Inc.',
    symbol: 'MUI',
  },
  {
    value: 'EPU',
    label: 'EPU,  iShares MSCI Peru ETF',
    name: 'iShares MSCI Peru ETF',
    symbol: 'EPU',
  },
  {
    value: 'IMUC',
    label: 'IMUC,  ImmunoCellular Therapeutics, Ltd.',
    name: 'ImmunoCellular Therapeutics, Ltd.',
    symbol: 'IMUC',
  },
  {
    value: 'RJA',
    label:
      'RJA,  ELEMENTS Linked to the Rogers International Commodity Index - Agriculture Total Return',
    name: 'ELEMENTS Linked to the Rogers International Commodity Index - Agriculture Total Return',
    symbol: 'RJA',
  },
  {
    value: 'NIE',
    label: 'NIE,  Virtus Equity & Convertible Income Fund',
    name: 'Virtus Equity & Convertible Income Fund',
    symbol: 'NIE',
  },
  {
    value: 'TLTE',
    label:
      'TLTE,  FlexShares Morningstar Emerging Markets Factor Tilt Index Fund',
    name: 'FlexShares Morningstar Emerging Markets Factor Tilt Index Fund',
    symbol: 'TLTE',
  },
  {
    value: 'ASG',
    label: 'ASG,  Liberty All-Star Growth Fund, Inc.',
    name: 'Liberty All-Star Growth Fund, Inc.',
    symbol: 'ASG',
  },
  {
    value: 'STCN',
    label: 'STCN,  Steel Connect, Inc.',
    name: 'Steel Connect, Inc.',
    symbol: 'STCN',
  },
  {
    value: 'MMLP',
    label: 'MMLP,  Martin Midstream Partners L.P.',
    name: 'Martin Midstream Partners L.P.',
    symbol: 'MMLP',
  },
  {
    value: 'HBNC',
    label: 'HBNC,  Horizon Bancorp, Inc.',
    name: 'Horizon Bancorp, Inc.',
    symbol: 'HBNC',
  },
  {
    value: 'CUE',
    label: 'CUE,  Cue Biopharma, Inc.',
    name: 'Cue Biopharma, Inc.',
    symbol: 'CUE',
  },
  {
    value: 'INFU',
    label: 'INFU,  InfuSystem Holdings, Inc.',
    name: 'InfuSystem Holdings, Inc.',
    symbol: 'INFU',
  },
  {
    value: 'CHAD',
    label: 'CHAD,  Direxion Daily CSI 300 China A Share Bear 1X Shares',
    name: 'Direxion Daily CSI 300 China A Share Bear 1X Shares',
    symbol: 'CHAD',
  },
  {
    value: 'PCEF',
    label: 'PCEF,  Invesco CEF Income Composite ETF',
    name: 'Invesco CEF Income Composite ETF',
    symbol: 'PCEF',
  },
  {
    value: 'RPG',
    label: 'RPG,  Invesco S&P 500 Pure Growth ETF',
    name: 'Invesco S&P 500 Pure Growth ETF',
    symbol: 'RPG',
  },
  {
    value: 'MGNX',
    label: 'MGNX,  MacroGenics, Inc.',
    name: 'MacroGenics, Inc.',
    symbol: 'MGNX',
  },
  {
    value: 'TDIV',
    label: 'TDIV,  First Trust NASDAQ Technology Dividend Index Fund',
    name: 'First Trust NASDAQ Technology Dividend Index Fund',
    symbol: 'TDIV',
  },
  {
    value: 'BSCO',
    label: 'BSCO,  Invesco BulletShares 2024 Corporate Bond ETF',
    name: 'Invesco BulletShares 2024 Corporate Bond ETF',
    symbol: 'BSCO',
  },
  {
    value: 'AMEH',
    label: 'AMEH,  Apollo Medical Holdings, Inc.',
    name: 'Apollo Medical Holdings, Inc.',
    symbol: 'AMEH',
  },
  {
    value: 'STN',
    label: 'STN,  Stantec Inc.',
    name: 'Stantec Inc.',
    symbol: 'STN',
  },
  {
    value: 'AXSM',
    label: 'AXSM,  Axsome Therapeutics, Inc.',
    name: 'Axsome Therapeutics, Inc.',
    symbol: 'AXSM',
  },
  {
    value: 'GHC',
    label: 'GHC,  Graham Holdings Company',
    name: 'Graham Holdings Company',
    symbol: 'GHC',
  },
  {
    value: 'CIK',
    label: 'CIK,  Credit Suisse Asset Management Income Fund, Inc.',
    name: 'Credit Suisse Asset Management Income Fund, Inc.',
    symbol: 'CIK',
  },
  {
    value: 'HYLD',
    label: 'HYLD,  High Yield ETF',
    name: 'High Yield ETF',
    symbol: 'HYLD',
  },
  {
    value: 'DCOM',
    label: 'DCOM,  Dime Community Bancshares, Inc.',
    name: 'Dime Community Bancshares, Inc.',
    symbol: 'DCOM',
  },
  {
    value: 'AMOT',
    label: 'AMOT,  Allied Motion Technologies Inc.',
    name: 'Allied Motion Technologies Inc.',
    symbol: 'AMOT',
  },
  {
    value: 'BIB',
    label: 'BIB,  ProShares Ultra Nasdaq Biotechnology',
    name: 'ProShares Ultra Nasdaq Biotechnology',
    symbol: 'BIB',
  },
  {
    value: 'BRT',
    label: 'BRT,  BRT Apartments Corp.',
    name: 'BRT Apartments Corp.',
    symbol: 'BRT',
  },
  {
    value: 'BWFG',
    label: 'BWFG,  Bankwell Financial Group, Inc.',
    name: 'Bankwell Financial Group, Inc.',
    symbol: 'BWFG',
  },
  {
    value: 'CPER',
    label: 'CPER,  United States Copper Index Fund, LP',
    name: 'United States Copper Index Fund, LP',
    symbol: 'CPER',
  },
  {
    value: 'CSV',
    label: 'CSV,  Carriage Services, Inc.',
    name: 'Carriage Services, Inc.',
    symbol: 'CSV',
  },
  {
    value: 'CVU',
    label: 'CVU,  CPI Aerostructures, Inc.',
    name: 'CPI Aerostructures, Inc.',
    symbol: 'CVU',
  },
  {
    value: 'DSM',
    label: 'DSM,  BNY Mellon Strategic Municipal Bond Fund, Inc.',
    name: 'BNY Mellon Strategic Municipal Bond Fund, Inc.',
    symbol: 'DSM',
  },
  {
    value: 'EBF',
    label: 'EBF,  Ennis, Inc.',
    name: 'Ennis, Inc.',
    symbol: 'EBF',
  },
  {
    value: 'EVT',
    label: 'EVT,  Eaton Vance Tax-Advantaged Dividend Income Fund',
    name: 'Eaton Vance Tax-Advantaged Dividend Income Fund',
    symbol: 'EVT',
  },
  {
    value: 'FMBH',
    label: 'FMBH,  First Mid Bancshares, Inc.',
    name: 'First Mid Bancshares, Inc.',
    symbol: 'FMBH',
  },
  {
    value: 'FOF',
    label: 'FOF,  Cohen & Steers Closed-End Opportunity Fund, Inc.',
    name: 'Cohen & Steers Closed-End Opportunity Fund, Inc.',
    symbol: 'FOF',
  },
  {
    value: 'FTCS',
    label: 'FTCS,  First Trust Capital Strength ETF',
    name: 'First Trust Capital Strength ETF',
    symbol: 'FTCS',
  },
  {
    value: 'GEOS',
    label: 'GEOS,  Geospace Technologies Corporation',
    name: 'Geospace Technologies Corporation',
    symbol: 'GEOS',
  },
  {
    value: 'IDE',
    label: 'IDE,  Voya Infrastructure, Industrials and Materials Fund',
    name: 'Voya Infrastructure, Industrials and Materials Fund',
    symbol: 'IDE',
  },
  {
    value: 'IEUS',
    label: 'IEUS,  iShares MSCI Europe Small-Cap ETF',
    name: 'iShares MSCI Europe Small-Cap ETF',
    symbol: 'IEUS',
  },
  {
    value: 'INBK',
    label: 'INBK,  First Internet Bancorp',
    name: 'First Internet Bancorp',
    symbol: 'INBK',
  },
  {
    value: 'IYK',
    label: 'IYK,  iShares US Consumer Staples ETF',
    name: 'iShares US Consumer Staples ETF',
    symbol: 'IYK',
  },
  {
    value: 'KALV',
    label: 'KALV,  KalVista Pharmaceuticals, Inc.',
    name: 'KalVista Pharmaceuticals, Inc.',
    symbol: 'KALV',
  },
  {
    value: 'LIND',
    label: 'LIND,  Lindblad Expeditions Holdings, Inc.',
    name: 'Lindblad Expeditions Holdings, Inc.',
    symbol: 'LIND',
  },
  {
    value: 'LVHI',
    label:
      'LVHI,  Franklin International Low Volatility High Dividend Index ETF',
    name: 'Franklin International Low Volatility High Dividend Index ETF',
    symbol: 'LVHI',
  },
  {
    value: 'MCRB',
    label: 'MCRB,  Seres Therapeutics, Inc.',
    name: 'Seres Therapeutics, Inc.',
    symbol: 'MCRB',
  },
  {
    value: 'MMT',
    label: 'MMT,  MFS Multimarket Income Trust',
    name: 'MFS Multimarket Income Trust',
    symbol: 'MMT',
  },
  {
    value: 'MMU',
    label: 'MMU,  Western Asset Managed Municipals Fund Inc.',
    name: 'Western Asset Managed Municipals Fund Inc.',
    symbol: 'MMU',
  },
  {
    value: 'NTWK',
    label: 'NTWK,  NetSol Technologies, Inc.',
    name: 'NetSol Technologies, Inc.',
    symbol: 'NTWK',
  },
  {
    value: 'NUDM',
    label: 'NUDM,  Nuveen ESG International Developed Markets Equity ETF',
    name: 'Nuveen ESG International Developed Markets Equity ETF',
    symbol: 'NUDM',
  },
  {
    value: 'PANL',
    label: 'PANL,  Pangaea Logistics Solutions, Ltd.',
    name: 'Pangaea Logistics Solutions, Ltd.',
    symbol: 'PANL',
  },
  {
    value: 'PNQI',
    label: 'PNQI,  Invesco NASDAQ Internet ETF',
    name: 'Invesco NASDAQ Internet ETF',
    symbol: 'PNQI',
  },
  {
    value: 'PPA',
    label: 'PPA,  Invesco Aerospace & Defense ETF',
    name: 'Invesco Aerospace & Defense ETF',
    symbol: 'PPA',
  },
  {
    value: 'PXH',
    label: 'PXH,  Invesco FTSE RAFI Emerging Markets ETF',
    name: 'Invesco FTSE RAFI Emerging Markets ETF',
    symbol: 'PXH',
  },
  {
    value: 'RCS',
    label: 'RCS,  PIMCO Strategic Income Fund, Inc.',
    name: 'PIMCO Strategic Income Fund, Inc.',
    symbol: 'RCS',
  },
  {
    value: 'RFDI',
    label: 'RFDI,  First Trust RiverFront Dynamic Developed International ETF',
    name: 'First Trust RiverFront Dynamic Developed International ETF',
    symbol: 'RFDI',
  },
  {
    value: 'SVVC',
    label: 'SVVC,  Firsthand Technology Value Fund, Inc.',
    name: 'Firsthand Technology Value Fund, Inc.',
    symbol: 'SVVC',
  },
  {
    value: 'SZC',
    label: 'SZC,  NXG NextGen Infrastructure Income Fund',
    name: 'NXG NextGen Infrastructure Income Fund',
    symbol: 'SZC',
  },
  {
    value: 'URE',
    label: 'URE,  ProShares Ultra Real Estate',
    name: 'ProShares Ultra Real Estate',
    symbol: 'URE',
  },
  {
    value: 'VGM',
    label: 'VGM,  Invesco Trust for Investment Grade Municipals',
    name: 'Invesco Trust for Investment Grade Municipals',
    symbol: 'VGM',
  },
  {
    value: 'VIXM',
    label: 'VIXM,  ProShares VIX Mid-Term Futures ETF',
    name: 'ProShares VIX Mid-Term Futures ETF',
    symbol: 'VIXM',
  },
  {
    value: 'ZSL',
    label: 'ZSL,  ProShares UltraShort Silver',
    name: 'ProShares UltraShort Silver',
    symbol: 'ZSL',
  },
  {
    value: 'DRV',
    label: 'DRV,  Direxion Daily Real Estate Bear 3X Shares',
    name: 'Direxion Daily Real Estate Bear 3X Shares',
    symbol: 'DRV',
  },
  {
    value: 'DSU',
    label: 'DSU,  BlackRock Debt Strategies Fund, Inc.',
    name: 'BlackRock Debt Strategies Fund, Inc.',
    symbol: 'DSU',
  },
  {
    value: 'MRAM',
    label: 'MRAM,  Everspin Technologies, Inc.',
    name: 'Everspin Technologies, Inc.',
    symbol: 'MRAM',
  },
  {
    value: 'VOE',
    label: 'VOE,  Vanguard Mid-Cap Value Index Fund',
    name: 'Vanguard Mid-Cap Value Index Fund',
    symbol: 'VOE',
  },
  {
    value: 'KALA',
    label: 'KALA,  Kala Pharmaceuticals, Inc.',
    name: 'Kala Pharmaceuticals, Inc.',
    symbol: 'KALA',
  },
  {
    value: 'WDFC',
    label: 'WDFC,  WD-40 Company',
    name: 'WD-40 Company',
    symbol: 'WDFC',
  },
  {
    value: 'BCBP',
    label: 'BCBP,  BCB Bancorp, Inc.',
    name: 'BCB Bancorp, Inc.',
    symbol: 'BCBP',
  },
  {
    value: 'EMQQ',
    label: 'EMQQ,  EMQQ The Emerging Markets Internet & Ecommerce ETF',
    name: 'EMQQ The Emerging Markets Internet & Ecommerce ETF',
    symbol: 'EMQQ',
  },
  {
    value: 'IQI',
    label: 'IQI,  Invesco Quality Municipal Income Trust',
    name: 'Invesco Quality Municipal Income Trust',
    symbol: 'IQI',
  },
  {
    value: 'SPYD',
    label: 'SPYD,  SPDR Portfolio S&P 500 High Dividend ETF',
    name: 'SPDR Portfolio S&P 500 High Dividend ETF',
    symbol: 'SPYD',
  },
  {
    value: 'SRTY',
    label: 'SRTY,  ProShares UltraPro Short Russell2000',
    name: 'ProShares UltraPro Short Russell2000',
    symbol: 'SRTY',
  },
  {
    value: 'BHB',
    label: 'BHB,  Bar Harbor Bankshares',
    name: 'Bar Harbor Bankshares',
    symbol: 'BHB',
  },
  {
    value: 'WTM',
    label: 'WTM,  White Mountains Insurance Group, Ltd.',
    name: 'White Mountains Insurance Group, Ltd.',
    symbol: 'WTM',
  },
  {
    value: 'LILA',
    label: 'LILA,  Liberty Latin America Ltd.',
    name: 'Liberty Latin America Ltd.',
    symbol: 'LILA',
  },
  {
    value: 'EELV',
    label: 'EELV,  Invesco S&P Emerging Markets Low Volatility ETF',
    name: 'Invesco S&P Emerging Markets Low Volatility ETF',
    symbol: 'EELV',
  },
  {
    value: 'IBIO',
    label: 'IBIO,  iBio, Inc.',
    name: 'iBio, Inc.',
    symbol: 'IBIO',
  },
  {
    value: 'IEP',
    label: 'IEP,  Icahn Enterprises L.P.',
    name: 'Icahn Enterprises L.P.',
    symbol: 'IEP',
  },
  {
    value: 'MJ',
    label: 'MJ,  ETFMG Alternative Harvest ETF',
    name: 'ETFMG Alternative Harvest ETF',
    symbol: 'MJ',
  },
  {
    value: 'VOT',
    label: 'VOT,  Vanguard Mid-Cap Growth Index Fund',
    name: 'Vanguard Mid-Cap Growth Index Fund',
    symbol: 'VOT',
  },
  {
    value: 'CURE',
    label: 'CURE,  Direxion Daily Healthcare Bull 3X Shares',
    name: 'Direxion Daily Healthcare Bull 3X Shares',
    symbol: 'CURE',
  },
  {
    value: 'LMNR',
    label: 'LMNR,  Limoneira Company',
    name: 'Limoneira Company',
    symbol: 'LMNR',
  },
  {
    value: 'VNRX',
    label: 'VNRX,  VolitionRx Limited',
    name: 'VolitionRx Limited',
    symbol: 'VNRX',
  },
  {
    value: 'AGLE',
    label: 'AGLE,  Aeglea BioTherapeutics, Inc.',
    name: 'Aeglea BioTherapeutics, Inc.',
    symbol: 'AGLE',
  },
  {
    value: 'RYTM',
    label: 'RYTM,  Rhythm Pharmaceuticals, Inc.',
    name: 'Rhythm Pharmaceuticals, Inc.',
    symbol: 'RYTM',
  },
  {
    value: 'CELH',
    label: 'CELH,  Celsius Holdings, Inc.',
    name: 'Celsius Holdings, Inc.',
    symbol: 'CELH',
  },
  {
    value: 'WEYS',
    label: 'WEYS,  Weyco Group, Inc.',
    name: 'Weyco Group, Inc.',
    symbol: 'WEYS',
  },
  {
    value: 'QEFA',
    label: 'QEFA,  SPDR MSCI EAFE StrategicFactors ETF',
    name: 'SPDR MSCI EAFE StrategicFactors ETF',
    symbol: 'QEFA',
  },
  {
    value: 'SBOW',
    label: 'SBOW,  SilverBow Resources, Inc.',
    name: 'SilverBow Resources, Inc.',
    symbol: 'SBOW',
  },
  {
    value: 'CHMI',
    label: 'CHMI,  Cherry Hill Mortgage Investment Corporation',
    name: 'Cherry Hill Mortgage Investment Corporation',
    symbol: 'CHMI',
  },
  {
    value: 'MOTI',
    label: 'MOTI,  VanEck Morningstar International Moat ETF',
    name: 'VanEck Morningstar International Moat ETF',
    symbol: 'MOTI',
  },
  {
    value: 'DMF',
    label: 'DMF,  BNY Mellon Municipal Income, Inc.',
    name: 'BNY Mellon Municipal Income, Inc.',
    symbol: 'DMF',
  },
  {
    value: 'RDIV',
    label: 'RDIV,  Invesco S&P Ultra Dividend Revenue ETF',
    name: 'Invesco S&P Ultra Dividend Revenue ETF',
    symbol: 'RDIV',
  },
  {
    value: 'ALOT',
    label: 'ALOT,  AstroNova, Inc.',
    name: 'AstroNova, Inc.',
    symbol: 'ALOT',
  },
  {
    value: 'AFB',
    label: 'AFB,  AllianceBernstein National Municipal Income Fund, Inc.',
    name: 'AllianceBernstein National Municipal Income Fund, Inc.',
    symbol: 'AFB',
  },
  {
    value: 'PDBC',
    label:
      'PDBC,  Invesco Optimum Yield Diversified Commodity Strategy No K-1 ETF',
    name: 'Invesco Optimum Yield Diversified Commodity Strategy No K-1 ETF',
    symbol: 'PDBC',
  },
  {
    value: 'GOF',
    label: 'GOF,  Guggenheim Strategic Opportunities Fund',
    name: 'Guggenheim Strategic Opportunities Fund',
    symbol: 'GOF',
  },
  {
    value: 'RBB',
    label: 'RBB,  RBB Bancorp',
    name: 'RBB Bancorp',
    symbol: 'RBB',
  },
  {
    value: 'DFJ',
    label: 'DFJ,  WisdomTree Japan SmallCap Dividend Fund',
    name: 'WisdomTree Japan SmallCap Dividend Fund',
    symbol: 'DFJ',
  },
  {
    value: 'STKS',
    label: 'STKS,  The ONE Group Hospitality, Inc.',
    name: 'The ONE Group Hospitality, Inc.',
    symbol: 'STKS',
  },
  {
    value: 'VOXX',
    label: 'VOXX,  VOXX International Corporation',
    name: 'VOXX International Corporation',
    symbol: 'VOXX',
  },
  {
    value: 'ESGR',
    label: 'ESGR,  Enstar Group Limited',
    name: 'Enstar Group Limited',
    symbol: 'ESGR',
  },
  {
    value: 'JFR',
    label: 'JFR,  Nuveen Floating Rate Income Fund',
    name: 'Nuveen Floating Rate Income Fund',
    symbol: 'JFR',
  },
  {
    value: 'RIOT',
    label: 'RIOT,  Riot Blockchain, Inc.',
    name: 'Riot Blockchain, Inc.',
    symbol: 'RIOT',
  },
  {
    value: 'AOK',
    label: 'AOK,  iShares Core Conservative Allocation ETF',
    name: 'iShares Core Conservative Allocation ETF',
    symbol: 'AOK',
  },
  {
    value: 'ASND',
    label: 'ASND,  Ascendis Pharma A/S',
    name: 'Ascendis Pharma A/S',
    symbol: 'ASND',
  },
  {
    value: 'BSM',
    label: 'BSM,  Black Stone Minerals, L.P.',
    name: 'Black Stone Minerals, L.P.',
    symbol: 'BSM',
  },
  {
    value: 'BTAI',
    label: 'BTAI,  BioXcel Therapeutics, Inc.',
    name: 'BioXcel Therapeutics, Inc.',
    symbol: 'BTAI',
  },
  {
    value: 'CRMT',
    label: "CRMT,  America's Car-Mart, Inc.",
    name: "America's Car-Mart, Inc.",
    symbol: 'CRMT',
  },
  {
    value: 'CVCY',
    label: 'CVCY,  Central Valley Community Bancorp',
    name: 'Central Valley Community Bancorp',
    symbol: 'CVCY',
  },
  {
    value: 'CVY',
    label: 'CVY,  Invesco Zacks Multi-Asset Income ETF',
    name: 'Invesco Zacks Multi-Asset Income ETF',
    symbol: 'CVY',
  },
  {
    value: 'CWCO',
    label: 'CWCO,  Consolidated Water Co. Ltd.',
    name: 'Consolidated Water Co. Ltd.',
    symbol: 'CWCO',
  },
  {
    value: 'DBGR',
    label: 'DBGR,  Xtrackers MSCI Germany Hedged Equity ETF',
    name: 'Xtrackers MSCI Germany Hedged Equity ETF',
    symbol: 'DBGR',
  },
  {
    value: 'DGRE',
    label: 'DGRE,  WisdomTree Emerging Markets Quality Dividend Growth Fund',
    name: 'WisdomTree Emerging Markets Quality Dividend Growth Fund',
    symbol: 'DGRE',
  },
  {
    value: 'EHTH',
    label: 'EHTH,  eHealth, Inc.',
    name: 'eHealth, Inc.',
    symbol: 'EHTH',
  },
  {
    value: 'FREL',
    label: 'FREL,  Fidelity MSCI Real Estate Index ETF',
    name: 'Fidelity MSCI Real Estate Index ETF',
    symbol: 'FREL',
  },
  {
    value: 'GLL',
    label: 'GLL,  ProShares UltraShort Gold',
    name: 'ProShares UltraShort Gold',
    symbol: 'GLL',
  },
  {
    value: 'GROW',
    label: 'GROW,  U.S. Global Investors, Inc.',
    name: 'U.S. Global Investors, Inc.',
    symbol: 'GROW',
  },
  {
    value: 'IJT',
    label: 'IJT,  iShares S&P Small-Cap 600 Growth ETF',
    name: 'iShares S&P Small-Cap 600 Growth ETF',
    symbol: 'IJT',
  },
  {
    value: 'IPAY',
    label: 'IPAY,  ETFMG Prime Mobile Payments ETF',
    name: 'ETFMG Prime Mobile Payments ETF',
    symbol: 'IPAY',
  },
  {
    value: 'IQDF',
    label: 'IQDF,  FlexShares International Quality Dividend Index Fund',
    name: 'FlexShares International Quality Dividend Index Fund',
    symbol: 'IQDF',
  },
  {
    value: 'IRL',
    label: 'IRL,  The New Ireland Fund, Inc.',
    name: 'The New Ireland Fund, Inc.',
    symbol: 'IRL',
  },
  {
    value: 'KF',
    label: 'KF,  The Korea Fund, Inc.',
    name: 'The Korea Fund, Inc.',
    symbol: 'KF',
  },
  {
    value: 'KINS',
    label: 'KINS,  Kingstone Companies, Inc.',
    name: 'Kingstone Companies, Inc.',
    symbol: 'KINS',
  },
  {
    value: 'KTF',
    label: 'KTF,  DWS Municipal Income Trust',
    name: 'DWS Municipal Income Trust',
    symbol: 'KTF',
  },
  {
    value: 'MPX',
    label: 'MPX,  Marine Products Corporation',
    name: 'Marine Products Corporation',
    symbol: 'MPX',
  },
  {
    value: 'NAC',
    label: 'NAC,  Nuveen California Quality Municipal Income Fund',
    name: 'Nuveen California Quality Municipal Income Fund',
    symbol: 'NAC',
  },
  {
    value: 'NBW',
    label: 'NBW,  Neuberger Berman California Municipal Fund Inc.',
    name: 'Neuberger Berman California Municipal Fund Inc.',
    symbol: 'NBW',
  },
  {
    value: 'ORAN',
    label: 'ORAN,  Orange S.A.',
    name: 'Orange S.A.',
    symbol: 'ORAN',
  },
  {
    value: 'PCYG',
    label: 'PCYG,  Park City Group, Inc.',
    name: 'Park City Group, Inc.',
    symbol: 'PCYG',
  },
  {
    value: 'PDT',
    label: 'PDT,  John Hancock Premium Dividend Fund',
    name: 'John Hancock Premium Dividend Fund',
    symbol: 'PDT',
  },
  {
    value: 'PML',
    label: 'PML,  PIMCO Municipal Income Fund II',
    name: 'PIMCO Municipal Income Fund II',
    symbol: 'PML',
  },
  {
    value: 'PSP',
    label: 'PSP,  Invesco Global Listed Private Equity ETF',
    name: 'Invesco Global Listed Private Equity ETF',
    symbol: 'PSP',
  },
  {
    value: 'QIWI',
    label: 'QIWI,  QIWI plc',
    name: 'QIWI plc',
    symbol: 'QIWI',
  },
  {
    value: 'RMT',
    label: 'RMT,  Royce Micro-Cap Trust, Inc.',
    name: 'Royce Micro-Cap Trust, Inc.',
    symbol: 'RMT',
  },
  {
    value: 'SJB',
    label: 'SJB,  Proshares Short High Yield',
    name: 'Proshares Short High Yield',
    symbol: 'SJB',
  },
  {
    value: 'UFAB',
    label: 'UFAB,  Unique Fabricating, Inc.',
    name: 'Unique Fabricating, Inc.',
    symbol: 'UFAB',
  },
  {
    value: 'UTSI',
    label: 'UTSI,  UTStarcom Holdings Corp.',
    name: 'UTStarcom Holdings Corp.',
    symbol: 'UTSI',
  },
  {
    value: 'VERI',
    label: 'VERI,  Veritone, Inc.',
    name: 'Veritone, Inc.',
    symbol: 'VERI',
  },
  {
    value: 'VXF',
    label: 'VXF,  Vanguard Extended Market Index Fund',
    name: 'Vanguard Extended Market Index Fund',
    symbol: 'VXF',
  },
  {
    value: 'WHG',
    label: 'WHG,  Westwood Holdings Group, Inc.',
    name: 'Westwood Holdings Group, Inc.',
    symbol: 'WHG',
  },
  {
    value: 'PGZ',
    label: 'PGZ,  Principal Real Estate Income Fund',
    name: 'Principal Real Estate Income Fund',
    symbol: 'PGZ',
  },
  {
    value: 'SCHZ',
    label: 'SCHZ,  Schwab U.S. Aggregate Bond ETF',
    name: 'Schwab U.S. Aggregate Bond ETF',
    symbol: 'SCHZ',
  },
  {
    value: 'CDC',
    label: 'CDC,  VictoryShares US EQ Income Enhanced Volatility Wtd ETF',
    name: 'VictoryShares US EQ Income Enhanced Volatility Wtd ETF',
    symbol: 'CDC',
  },
  {
    value: 'FENC',
    label: 'FENC,  Fennec Pharmaceuticals Inc.',
    name: 'Fennec Pharmaceuticals Inc.',
    symbol: 'FENC',
  },
  {
    value: 'FARM',
    label: 'FARM,  Farmer Bros. Co.',
    name: 'Farmer Bros. Co.',
    symbol: 'FARM',
  },
  {
    value: 'JXI',
    label: 'JXI,  iShares Global Utilities ETF',
    name: 'iShares Global Utilities ETF',
    symbol: 'JXI',
  },
  {
    value: 'CTBB',
    label: 'CTBB,  Qwest Corp. NT',
    name: 'Qwest Corp. NT',
    symbol: 'CTBB',
  },
  {
    value: 'VGIT',
    label: 'VGIT,  Vanguard Intermediate-Term Treasury Index Fund',
    name: 'Vanguard Intermediate-Term Treasury Index Fund',
    symbol: 'VGIT',
  },
  {
    value: 'CYB',
    label: 'CYB,  WisdomTree Chinese Yuan Strategy Fund',
    name: 'WisdomTree Chinese Yuan Strategy Fund',
    symbol: 'CYB',
  },
  {
    value: 'ANIK',
    label: 'ANIK,  Anika Therapeutics, Inc.',
    name: 'Anika Therapeutics, Inc.',
    symbol: 'ANIK',
  },
  {
    value: 'SVRA',
    label: 'SVRA,  Savara Inc.',
    name: 'Savara Inc.',
    symbol: 'SVRA',
  },
  {
    value: 'TG',
    label: 'TG,  Tredegar Corporation',
    name: 'Tredegar Corporation',
    symbol: 'TG',
  },
  {
    value: 'QQEW',
    label: 'QQEW,  First Trust NASDAQ-100 Equal Weighted Index Fund',
    name: 'First Trust NASDAQ-100 Equal Weighted Index Fund',
    symbol: 'QQEW',
  },
  {
    value: 'VGSH',
    label: 'VGSH,  Vanguard Short-Term Treasury Index Fund',
    name: 'Vanguard Short-Term Treasury Index Fund',
    symbol: 'VGSH',
  },
  {
    value: 'EXI',
    label: 'EXI,  iShares Global Industrials ETF',
    name: 'iShares Global Industrials ETF',
    symbol: 'EXI',
  },
  {
    value: 'BUSE',
    label: 'BUSE,  First Busey Corporation',
    name: 'First Busey Corporation',
    symbol: 'BUSE',
  },
  {
    value: 'HONE',
    label: 'HONE,  HarborOne Bancorp, Inc.',
    name: 'HarborOne Bancorp, Inc.',
    symbol: 'HONE',
  },
  {
    value: 'IAF',
    label: 'IAF,  Abrdn Australia Equity Fund Inc',
    name: 'Abrdn Australia Equity Fund Inc',
    symbol: 'IAF',
  },
  {
    value: 'HDMV',
    label:
      'HDMV,  First Trust Horizon Managed Volatility Developed International ETF',
    name: 'First Trust Horizon Managed Volatility Developed International ETF',
    symbol: 'HDMV',
  },
  {
    value: 'IDLV',
    label: 'IDLV,  Invesco S&P International Developed Low Volatility ETF',
    name: 'Invesco S&P International Developed Low Volatility ETF',
    symbol: 'IDLV',
  },
  {
    value: 'TOTL',
    label: 'TOTL,  SPDR DoubleLine Total Return Tactical ETF',
    name: 'SPDR DoubleLine Total Return Tactical ETF',
    symbol: 'TOTL',
  },
  {
    value: 'UHT',
    label: 'UHT,  Universal Health Realty Income Trust',
    name: 'Universal Health Realty Income Trust',
    symbol: 'UHT',
  },
  {
    value: 'IESC',
    label: 'IESC,  IES Holdings, Inc.',
    name: 'IES Holdings, Inc.',
    symbol: 'IESC',
  },
  {
    value: 'LITB',
    label: 'LITB,  LightInTheBox Holding Co., Ltd.',
    name: 'LightInTheBox Holding Co., Ltd.',
    symbol: 'LITB',
  },
  {
    value: 'SCHP',
    label: 'SCHP,  Schwab U.S. TIPS ETF',
    name: 'Schwab U.S. TIPS ETF',
    symbol: 'SCHP',
  },
  {
    value: 'SMBC',
    label: 'SMBC,  Southern Missouri Bancorp, Inc.',
    name: 'Southern Missouri Bancorp, Inc.',
    symbol: 'SMBC',
  },
  {
    value: 'VCTR',
    label: 'VCTR,  Victory Capital Holdings, Inc.',
    name: 'Victory Capital Holdings, Inc.',
    symbol: 'VCTR',
  },
  {
    value: 'ZTR',
    label: 'ZTR,  Virtus Total Return Fund Inc.',
    name: 'Virtus Total Return Fund Inc.',
    symbol: 'ZTR',
  },
  {
    value: 'JHMM',
    label: 'JHMM,  John Hancock Multifactor Mid Cap ETF',
    name: 'John Hancock Multifactor Mid Cap ETF',
    symbol: 'JHMM',
  },
  {
    value: 'VTEB',
    label: 'VTEB,  Vanguard Tax-Exempt Bond Index Fund',
    name: 'Vanguard Tax-Exempt Bond Index Fund',
    symbol: 'VTEB',
  },
  {
    value: 'JPIN',
    label: 'JPIN,  JPMorgan Diversified Return International Equity ETF',
    name: 'JPMorgan Diversified Return International Equity ETF',
    symbol: 'JPIN',
  },
  {
    value: 'LMFA',
    label: 'LMFA,  LM Funding America, Inc.',
    name: 'LM Funding America, Inc.',
    symbol: 'LMFA',
  },
  {
    value: 'IJS',
    label: 'IJS,  iShares S&P Small-Cap 600 Value ETF',
    name: 'iShares S&P Small-Cap 600 Value ETF',
    symbol: 'IJS',
  },
  {
    value: 'KAI',
    label: 'KAI,  Kadant Inc.',
    name: 'Kadant Inc.',
    symbol: 'KAI',
  },
  {
    value: 'PRQR',
    label: 'PRQR,  ProQR Therapeutics N.V.',
    name: 'ProQR Therapeutics N.V.',
    symbol: 'PRQR',
  },
  {
    value: 'UBA',
    label: 'UBA,  Urstadt Biddle Properties Inc.',
    name: 'Urstadt Biddle Properties Inc.',
    symbol: 'UBA',
  },
  {
    value: 'FCBC',
    label: 'FCBC,  First Community Bankshares, Inc.',
    name: 'First Community Bankshares, Inc.',
    symbol: 'FCBC',
  },
  {
    value: 'VDC',
    label: 'VDC,  Vanguard Consumer Staples Fund',
    name: 'Vanguard Consumer Staples Fund',
    symbol: 'VDC',
  },
  {
    value: 'MHO',
    label: 'MHO,  M/I Homes, Inc.',
    name: 'M/I Homes, Inc.',
    symbol: 'MHO',
  },
  {
    value: 'UTMD',
    label: 'UTMD,  Utah Medical Products, Inc.',
    name: 'Utah Medical Products, Inc.',
    symbol: 'UTMD',
  },
  {
    value: 'HDG',
    label: 'HDG,  ProShares Hedge Replication ETF',
    name: 'ProShares Hedge Replication ETF',
    symbol: 'HDG',
  },
  {
    value: 'FEMS',
    label: 'FEMS,  First Trust Emerging Markets Small Cap AlphaDEX Fund',
    name: 'First Trust Emerging Markets Small Cap AlphaDEX Fund',
    symbol: 'FEMS',
  },
  {
    value: 'SPTM',
    label: 'SPTM,  SPDR Portfolio S&P 1500 Composite Stock Market ETF',
    name: 'SPDR Portfolio S&P 1500 Composite Stock Market ETF',
    symbol: 'SPTM',
  },
  {
    value: 'VCR',
    label: 'VCR,  Vanguard Consumer Discretionary Fund',
    name: 'Vanguard Consumer Discretionary Fund',
    symbol: 'VCR',
  },
  {
    value: 'ATNI',
    label: 'ATNI,  ATN International, Inc.',
    name: 'ATN International, Inc.',
    symbol: 'ATNI',
  },
  {
    value: 'FNDA',
    label: 'FNDA,  Schwab Fundamental U.S. Small Company Index ETF',
    name: 'Schwab Fundamental U.S. Small Company Index ETF',
    symbol: 'FNDA',
  },
  {
    value: 'GMF',
    label: 'GMF,  SPDR S&P Emerging Asia Pacific ETF',
    name: 'SPDR S&P Emerging Asia Pacific ETF',
    symbol: 'GMF',
  },
  {
    value: 'MDYG',
    label: 'MDYG,  SPDR S&P 400 Mid Cap Growth ETF',
    name: 'SPDR S&P 400 Mid Cap Growth ETF',
    symbol: 'MDYG',
  },
  {
    value: 'SCM',
    label: 'SCM,  Stellus Capital Investment Corporation',
    name: 'Stellus Capital Investment Corporation',
    symbol: 'SCM',
  },
  {
    value: 'AIRG',
    label: 'AIRG,  Airgain, Inc.',
    name: 'Airgain, Inc.',
    symbol: 'AIRG',
  },
  {
    value: 'BBC',
    label: 'BBC,  Virtus LifeSci Biotech Clinical Trials ETF',
    name: 'Virtus LifeSci Biotech Clinical Trials ETF',
    symbol: 'BBC',
  },
  {
    value: 'BFOR',
    label: "BFOR,  Barron's 400 ETF",
    name: "Barron's 400 ETF",
    symbol: 'BFOR',
  },
  {
    value: 'BGH',
    label: 'BGH,  Barings Global Short Duration High Yield Fund',
    name: 'Barings Global Short Duration High Yield Fund',
    symbol: 'BGH',
  },
  {
    value: 'BGR',
    label: 'BGR,  BlackRock Energy and Resources Trust',
    name: 'BlackRock Energy and Resources Trust',
    symbol: 'BGR',
  },
  {
    value: 'BGX',
    label: 'BGX,  Blackstone / GSO Long-Short Credit Income Fund',
    name: 'Blackstone / GSO Long-Short Credit Income Fund',
    symbol: 'BGX',
  },
  {
    value: 'BOND',
    label: 'BOND,  PIMCO Active Bond Exchange-Traded Fund',
    name: 'PIMCO Active Bond Exchange-Traded Fund',
    symbol: 'BOND',
  },
  {
    value: 'BOSC',
    label: 'BOSC,  B.O.S. Better Online Solutions Ltd.',
    name: 'B.O.S. Better Online Solutions Ltd.',
    symbol: 'BOSC',
  },
  {
    value: 'BSJM',
    label: 'BSJM,  Invesco BulletShares 2022 High Yield Corporate Bond ETF',
    name: 'Invesco BulletShares 2022 High Yield Corporate Bond ETF',
    symbol: 'BSJM',
  },
  {
    value: 'BUI',
    label:
      'BUI,  BlackRock Utilities, Infrastructure & Power Opportunities Trust',
    name: 'BlackRock Utilities, Infrastructure & Power Opportunities Trust',
    symbol: 'BUI',
  },
  {
    value: 'CDLX',
    label: 'CDLX,  Cardlytics, Inc.',
    name: 'Cardlytics, Inc.',
    symbol: 'CDLX',
  },
  {
    value: 'CEE',
    label: 'CEE,  The Central and Eastern Europe Fund, Inc.',
    name: 'The Central and Eastern Europe Fund, Inc.',
    symbol: 'CEE',
  },
  {
    value: 'CNTY',
    label: 'CNTY,  Century Casinos, Inc.',
    name: 'Century Casinos, Inc.',
    symbol: 'CNTY',
  },
  {
    value: 'DAIO',
    label: 'DAIO,  Data I/O Corporation',
    name: 'Data I/O Corporation',
    symbol: 'DAIO',
  },
  {
    value: 'DES',
    label: 'DES,  WisdomTree U.S. SmallCap Dividend Fund',
    name: 'WisdomTree U.S. SmallCap Dividend Fund',
    symbol: 'DES',
  },
  {
    value: 'EDIV',
    label: 'EDIV,  SPDR S&P Emerging Markets Dividend ETF',
    name: 'SPDR S&P Emerging Markets Dividend ETF',
    symbol: 'EDIV',
  },
  {
    value: 'EFU',
    label: 'EFU,  ProShares UltraShort MSCI EAFE',
    name: 'ProShares UltraShort MSCI EAFE',
    symbol: 'EFU',
  },
  {
    value: 'EGPT',
    label: 'EGPT,  VanEck Egypt Index ETF',
    name: 'VanEck Egypt Index ETF',
    symbol: 'EGPT',
  },
  {
    value: 'EOLS',
    label: 'EOLS,  Evolus, Inc.',
    name: 'Evolus, Inc.',
    symbol: 'EOLS',
  },
  {
    value: 'ERC',
    label: 'ERC,  Allspring Multi-Sector Income Fund',
    name: 'Allspring Multi-Sector Income Fund',
    symbol: 'ERC',
  },
  {
    value: 'ETB',
    label: 'ETB,  Eaton Vance Tax-Managed Buy-Write Income Fund',
    name: 'Eaton Vance Tax-Managed Buy-Write Income Fund',
    symbol: 'ETB',
  },
  {
    value: 'GECC',
    label: 'GECC,  Great Elm Capital Corp.',
    name: 'Great Elm Capital Corp.',
    symbol: 'GECC',
  },
  {
    value: 'HEAR',
    label: 'HEAR,  Turtle Beach Corporation',
    name: 'Turtle Beach Corporation',
    symbol: 'HEAR',
  },
  {
    value: 'HYI',
    label: 'HYI,  Western Asset High Yield Defined Opportunity Fund Inc.',
    name: 'Western Asset High Yield Defined Opportunity Fund Inc.',
    symbol: 'HYI',
  },
  {
    value: 'IHDG',
    label:
      'IHDG,  WisdomTree International Hedged Quality Dividend Growth Fund',
    name: 'WisdomTree International Hedged Quality Dividend Growth Fund',
    symbol: 'IHDG',
  },
  {
    value: 'IHI',
    label: 'IHI,  iShares U.S. Medical Devices ETF',
    name: 'iShares U.S. Medical Devices ETF',
    symbol: 'IHI',
  },
  {
    value: 'IOR',
    label: 'IOR,  Income Opportunity Realty Investors, Inc.',
    name: 'Income Opportunity Realty Investors, Inc.',
    symbol: 'IOR',
  },
  {
    value: 'IVH',
    label: 'IVH,  Ivy Funds - Delaware Ivy High Income Opportunities Fund',
    name: 'Ivy Funds - Delaware Ivy High Income Opportunities Fund',
    symbol: 'IVH',
  },
  {
    value: 'IYM',
    label: 'IYM,  iShares U.S. Basic Materials ETF',
    name: 'iShares U.S. Basic Materials ETF',
    symbol: 'IYM',
  },
  {
    value: 'KEMQ',
    label: 'KEMQ,  KraneShares Emerging Markets Consumer Technology Index ETF',
    name: 'KraneShares Emerging Markets Consumer Technology Index ETF',
    symbol: 'KEMQ',
  },
  {
    value: 'KREF',
    label: 'KREF,  KKR Real Estate Finance Trust Inc.',
    name: 'KKR Real Estate Finance Trust Inc.',
    symbol: 'KREF',
  },
  {
    value: 'MAV',
    label: 'MAV,  Pioneer Municipal High Income Advantage Fund, Inc.',
    name: 'Pioneer Municipal High Income Advantage Fund, Inc.',
    symbol: 'MAV',
  },
  {
    value: 'MCN',
    label: 'MCN,  Madison Covered Call & Equity Strategy Fund',
    name: 'Madison Covered Call & Equity Strategy Fund',
    symbol: 'MCN',
  },
  {
    value: 'MLPX',
    label: 'MLPX,  Global X MLP & Energy Infrastructure ETF',
    name: 'Global X MLP & Energy Infrastructure ETF',
    symbol: 'MLPX',
  },
  {
    value: 'MRCC',
    label: 'MRCC,  Monroe Capital Corporation',
    name: 'Monroe Capital Corporation',
    symbol: 'MRCC',
  },
  {
    value: 'MTNB',
    label: 'MTNB,  Matinas BioPharma Holdings, Inc.',
    name: 'Matinas BioPharma Holdings, Inc.',
    symbol: 'MTNB',
  },
  {
    value: 'MYN',
    label: 'MYN,  BlackRock MuniYield New York Quality Fund, Inc.',
    name: 'BlackRock MuniYield New York Quality Fund, Inc.',
    symbol: 'MYN',
  },
  {
    value: 'NPK',
    label: 'NPK,  National Presto Industries, Inc.',
    name: 'National Presto Industries, Inc.',
    symbol: 'NPK',
  },
  {
    value: 'PFO',
    label: 'PFO,  Flaherty & Crumrine Preferred Income Opportunity Fund Inc.',
    name: 'Flaherty & Crumrine Preferred Income Opportunity Fund Inc.',
    symbol: 'PFO',
  },
  {
    value: 'PMX',
    label: 'PMX,  PIMCO Municipal Income Fund III',
    name: 'PIMCO Municipal Income Fund III',
    symbol: 'PMX',
  },
  {
    value: 'POWL',
    label: 'POWL,  Powell Industries, Inc.',
    name: 'Powell Industries, Inc.',
    symbol: 'POWL',
  },
  {
    value: 'PSCE',
    label: 'PSCE,  Invesco S&P SmallCap Energy ETF',
    name: 'Invesco S&P SmallCap Energy ETF',
    symbol: 'PSCE',
  },
  {
    value: 'PWOD',
    label: 'PWOD,  Penns Woods Bancorp, Inc.',
    name: 'Penns Woods Bancorp, Inc.',
    symbol: 'PWOD',
  },
  {
    value: 'PXQ',
    label: 'PXQ,  Invesco Dynamic Networking ETF',
    name: 'Invesco Dynamic Networking ETF',
    symbol: 'PXQ',
  },
  {
    value: 'RSLS',
    label: 'RSLS,  ReShape Lifesciences Inc.',
    name: 'ReShape Lifesciences Inc.',
    symbol: 'RSLS',
  },
  {
    value: 'SEED',
    label: 'SEED,  Origin Agritech Limited',
    name: 'Origin Agritech Limited',
    symbol: 'SEED',
  },
  {
    value: 'SGDJ',
    label: 'SGDJ,  Sprott Junior Gold Miners ETF',
    name: 'Sprott Junior Gold Miners ETF',
    symbol: 'SGDJ',
  },
  {
    value: 'SHYD',
    label: 'SHYD,  VanEck Short High Yield Muni ETF',
    name: 'VanEck Short High Yield Muni ETF',
    symbol: 'SHYD',
  },
  {
    value: 'SOJC',
    label: 'SOJC,  The Southern Company JR 2017B NT 77',
    name: 'The Southern Company JR 2017B NT 77',
    symbol: 'SOJC',
  },
  {
    value: 'STON',
    label: 'STON,  StoneMor Inc.',
    name: 'StoneMor Inc.',
    symbol: 'STON',
  },
  {
    value: 'TGLS',
    label: 'TGLS,  Tecnoglass Inc.',
    name: 'Tecnoglass Inc.',
    symbol: 'TGLS',
  },
  {
    value: 'TITN',
    label: 'TITN,  Titan Machinery Inc.',
    name: 'Titan Machinery Inc.',
    symbol: 'TITN',
  },
  {
    value: 'TLYS',
    label: "TLYS,  Tilly's, Inc.",
    name: "Tilly's, Inc.",
    symbol: 'TLYS',
  },
  {
    value: 'VDE',
    label: 'VDE,  Vanguard Energy Index Fund',
    name: 'Vanguard Energy Index Fund',
    symbol: 'VDE',
  },
  {
    value: 'VXZ',
    label: 'VXZ,  iPath Series B S&P 500 VIX Mid-Term Futures ETN',
    name: 'iPath Series B S&P 500 VIX Mid-Term Futures ETN',
    symbol: 'VXZ',
  },
  {
    value: 'ZROZ',
    label:
      'ZROZ,  PIMCO 25+ Year Zero Coupon U.S. Treasury Index Exchange-Traded Fund',
    name: 'PIMCO 25+ Year Zero Coupon U.S. Treasury Index Exchange-Traded Fund',
    symbol: 'ZROZ',
  },
  {
    value: 'ZYNE',
    label: 'ZYNE,  Zynerba Pharmaceuticals, Inc.',
    name: 'Zynerba Pharmaceuticals, Inc.',
    symbol: 'ZYNE',
  },
  {
    value: 'CTG',
    label: 'CTG,  Computer Task Group, Incorporated',
    name: 'Computer Task Group, Incorporated',
    symbol: 'CTG',
  },
  {
    value: 'PCYO',
    label: 'PCYO,  Pure Cycle Corporation',
    name: 'Pure Cycle Corporation',
    symbol: 'PCYO',
  },
  {
    value: 'SNOA',
    label: 'SNOA,  Sonoma Pharmaceuticals, Inc.',
    name: 'Sonoma Pharmaceuticals, Inc.',
    symbol: 'SNOA',
  },
  {
    value: 'DXYN',
    label: 'DXYN,  The Dixie Group, Inc.',
    name: 'The Dixie Group, Inc.',
    symbol: 'DXYN',
  },
  {
    value: 'IJJ',
    label: 'IJJ,  iShares S&P Mid-Cap 400 Value ETF',
    name: 'iShares S&P Mid-Cap 400 Value ETF',
    symbol: 'IJJ',
  },
  {
    value: 'CYRX',
    label: 'CYRX,  Cryoport, Inc.',
    name: 'Cryoport, Inc.',
    symbol: 'CYRX',
  },
  {
    value: 'COKE',
    label: 'COKE,  Coca-Cola Consolidated, Inc.',
    name: 'Coca-Cola Consolidated, Inc.',
    symbol: 'COKE',
  },
  {
    value: 'FDVV',
    label: 'FDVV,  Fidelity High Dividend ETF',
    name: 'Fidelity High Dividend ETF',
    symbol: 'FDVV',
  },
  {
    value: 'GSL',
    label: 'GSL,  Global Ship Lease, Inc.',
    name: 'Global Ship Lease, Inc.',
    symbol: 'GSL',
  },
  {
    value: 'ELD',
    label: 'ELD,  WisdomTree Emerging Markets Local Debt Fund',
    name: 'WisdomTree Emerging Markets Local Debt Fund',
    symbol: 'ELD',
  },
  {
    value: 'DGP',
    label: 'DGP,  DB Gold Double Long ETN',
    name: 'DB Gold Double Long ETN',
    symbol: 'DGP',
  },
  {
    value: 'TRC',
    label: 'TRC,  Tejon Ranch Co.',
    name: 'Tejon Ranch Co.',
    symbol: 'TRC',
  },
  {
    value: 'KNDI',
    label: 'KNDI,  Kandi Technologies Group, Inc.',
    name: 'Kandi Technologies Group, Inc.',
    symbol: 'KNDI',
  },
  {
    value: 'FTSL',
    label: 'FTSL,  First Trust Senior Loan Fund',
    name: 'First Trust Senior Loan Fund',
    symbol: 'FTSL',
  },
  {
    value: 'BRZU',
    label: 'BRZU,  Direxion Daily MSCI Brazil Bull 2X Shares',
    name: 'Direxion Daily MSCI Brazil Bull 2X Shares',
    symbol: 'BRZU',
  },
  {
    value: 'PGJ',
    label: 'PGJ,  Invesco Golden Dragon China ETF',
    name: 'Invesco Golden Dragon China ETF',
    symbol: 'PGJ',
  },
  {
    value: 'SPTL',
    label: 'SPTL,  SPDR Portfolio Long Term Treasury ETF',
    name: 'SPDR Portfolio Long Term Treasury ETF',
    symbol: 'SPTL',
  },
  {
    value: 'FNI',
    label: 'FNI,  First Trust Chindia ETF',
    name: 'First Trust Chindia ETF',
    symbol: 'FNI',
  },
  {
    value: 'MOO',
    label: 'MOO,  VanEck Agribusiness ETF',
    name: 'VanEck Agribusiness ETF',
    symbol: 'MOO',
  },
  {
    value: 'USDU',
    label: 'USDU,  WisdomTree Bloomberg U.S. Dollar Bullish Fund',
    name: 'WisdomTree Bloomberg U.S. Dollar Bullish Fund',
    symbol: 'USDU',
  },
  {
    value: 'WHLR',
    label: 'WHLR,  Wheeler Real Estate Investment Trust, Inc.',
    name: 'Wheeler Real Estate Investment Trust, Inc.',
    symbol: 'WHLR',
  },
  {
    value: 'NSPR',
    label: 'NSPR,  InspireMD, Inc.',
    name: 'InspireMD, Inc.',
    symbol: 'NSPR',
  },
  {
    value: 'UWM',
    label: 'UWM,  ProShares Ultra Russell2000',
    name: 'ProShares Ultra Russell2000',
    symbol: 'UWM',
  },
  {
    value: 'DXR',
    label: 'DXR,  Daxor Corporation',
    name: 'Daxor Corporation',
    symbol: 'DXR',
  },
  {
    value: 'RDHL',
    label: 'RDHL,  RedHill Biopharma Ltd.',
    name: 'RedHill Biopharma Ltd.',
    symbol: 'RDHL',
  },
  {
    value: 'PFSW',
    label: 'PFSW,  PFSweb, Inc.',
    name: 'PFSweb, Inc.',
    symbol: 'PFSW',
  },
  {
    value: 'ARKK',
    label: 'ARKK,  ARK Innovation ETF',
    name: 'ARK Innovation ETF',
    symbol: 'ARKK',
  },
  {
    value: 'MUJ',
    label: 'MUJ,  BlackRock MuniHoldings New Jersey Quality Fund, Inc.',
    name: 'BlackRock MuniHoldings New Jersey Quality Fund, Inc.',
    symbol: 'MUJ',
  },
  {
    value: 'HYEM',
    label: 'HYEM,  VanEck Emerging Markets High Yield Bond ETF',
    name: 'VanEck Emerging Markets High Yield Bond ETF',
    symbol: 'HYEM',
  },
  {
    value: 'SLX',
    label: 'SLX,  VanEck Steel ETF',
    name: 'VanEck Steel ETF',
    symbol: 'SLX',
  },
  {
    value: 'CLAR',
    label: 'CLAR,  Clarus Corporation',
    name: 'Clarus Corporation',
    symbol: 'CLAR',
  },
  {
    value: 'MNA',
    label: 'MNA,  IQ Merger Arbitrage ETF',
    name: 'IQ Merger Arbitrage ETF',
    symbol: 'MNA',
  },
  {
    value: 'PLOW',
    label: 'PLOW,  Douglas Dynamics, Inc.',
    name: 'Douglas Dynamics, Inc.',
    symbol: 'PLOW',
  },
  {
    value: 'TWIN',
    label: 'TWIN,  Twin Disc, Incorporated',
    name: 'Twin Disc, Incorporated',
    symbol: 'TWIN',
  },
  {
    value: 'CCLP',
    label: 'CCLP,  CSI Compressco LP',
    name: 'CSI Compressco LP',
    symbol: 'CCLP',
  },
  {
    value: 'RNP',
    label: 'RNP,  Cohen & Steers REIT and Preferred Income Fund, Inc.',
    name: 'Cohen & Steers REIT and Preferred Income Fund, Inc.',
    symbol: 'RNP',
  },
  {
    value: 'DMO',
    label: 'DMO,  Western Asset Mortgage Opportunity Fund Inc.',
    name: 'Western Asset Mortgage Opportunity Fund Inc.',
    symbol: 'DMO',
  },
  {
    value: 'KRMA',
    label: 'KRMA,  Global X Conscious Companies ETF',
    name: 'Global X Conscious Companies ETF',
    symbol: 'KRMA',
  },
  {
    value: 'FNWB',
    label: 'FNWB,  First Northwest Bancorp',
    name: 'First Northwest Bancorp',
    symbol: 'FNWB',
  },
  {
    value: 'TRT',
    label: 'TRT,  Trio-Tech International',
    name: 'Trio-Tech International',
    symbol: 'TRT',
  },
  {
    value: 'TSBK',
    label: 'TSBK,  Timberland Bancorp, Inc.',
    name: 'Timberland Bancorp, Inc.',
    symbol: 'TSBK',
  },
  {
    value: 'RFFC',
    label: 'RFFC,  RiverFront Dynamic US Flex-Cap ETF',
    name: 'RiverFront Dynamic US Flex-Cap ETF',
    symbol: 'RFFC',
  },
  {
    value: 'VPG',
    label: 'VPG,  Vishay Precision Group, Inc.',
    name: 'Vishay Precision Group, Inc.',
    symbol: 'VPG',
  },
  {
    value: 'BGT',
    label: 'BGT,  BlackRock Floating Rate Income Trust',
    name: 'BlackRock Floating Rate Income Trust',
    symbol: 'BGT',
  },
  {
    value: 'NHC',
    label: 'NHC,  National HealthCare Corporation',
    name: 'National HealthCare Corporation',
    symbol: 'NHC',
  },
  {
    value: 'PKB',
    label: 'PKB,  Invesco Dynamic Building & Construction ETF',
    name: 'Invesco Dynamic Building & Construction ETF',
    symbol: 'PKB',
  },
  {
    value: 'REET',
    label: 'REET,  iShares Global REIT ETF',
    name: 'iShares Global REIT ETF',
    symbol: 'REET',
  },
  {
    value: 'EMCB',
    label: 'EMCB,  WisdomTree Emerging Markets Corporate Bond Fund',
    name: 'WisdomTree Emerging Markets Corporate Bond Fund',
    symbol: 'EMCB',
  },
  {
    value: 'RYT',
    label: 'RYT,  Invesco S&P 500 Equal Weight Technology ETF',
    name: 'Invesco S&P 500 Equal Weight Technology ETF',
    symbol: 'RYT',
  },
  {
    value: 'INFI',
    label: 'INFI,  Infinity Pharmaceuticals, Inc.',
    name: 'Infinity Pharmaceuticals, Inc.',
    symbol: 'INFI',
  },
  {
    value: 'MGU',
    label: 'MGU,  Macquarie Global Infrastructure Total Return Fund Inc.',
    name: 'Macquarie Global Infrastructure Total Return Fund Inc.',
    symbol: 'MGU',
  },
  {
    value: 'VSEC',
    label: 'VSEC,  VSE Corporation',
    name: 'VSE Corporation',
    symbol: 'VSEC',
  },
  {
    value: 'MGEE',
    label: 'MGEE,  MGE Energy, Inc.',
    name: 'MGE Energy, Inc.',
    symbol: 'MGEE',
  },
  {
    value: 'CVCO',
    label: 'CVCO,  Cavco Industries, Inc.',
    name: 'Cavco Industries, Inc.',
    symbol: 'CVCO',
  },
  {
    value: 'FGD',
    label: 'FGD,  First Trust Dow Jones Global Select Dividend Index Fund',
    name: 'First Trust Dow Jones Global Select Dividend Index Fund',
    symbol: 'FGD',
  },
  {
    value: 'CAPL',
    label: 'CAPL,  CrossAmerica Partners LP',
    name: 'CrossAmerica Partners LP',
    symbol: 'CAPL',
  },
  {
    value: 'VOX',
    label: 'VOX,  Vanguard Communication Services Index Fund',
    name: 'Vanguard Communication Services Index Fund',
    symbol: 'VOX',
  },
  {
    value: 'SP',
    label: 'SP,  SP Plus Corporation',
    name: 'SP Plus Corporation',
    symbol: 'SP',
  },
  {
    value: 'STBA',
    label: 'STBA,  S&T Bancorp, Inc.',
    name: 'S&T Bancorp, Inc.',
    symbol: 'STBA',
  },
  {
    value: 'MSN',
    label: 'MSN,  Emerson Radio Corp.',
    name: 'Emerson Radio Corp.',
    symbol: 'MSN',
  },
  {
    value: 'TBNK',
    label: 'TBNK,  Territorial Bancorp Inc.',
    name: 'Territorial Bancorp Inc.',
    symbol: 'TBNK',
  },
  {
    value: 'BFIN',
    label: 'BFIN,  BankFinancial Corporation',
    name: 'BankFinancial Corporation',
    symbol: 'BFIN',
  },
  {
    value: 'NUEM',
    label: 'NUEM,  Nuveen ESG Emerging Markets Equity ETF',
    name: 'Nuveen ESG Emerging Markets Equity ETF',
    symbol: 'NUEM',
  },
  {
    value: 'PJP',
    label: 'PJP,  Invesco Dynamic Pharmaceuticals ETF',
    name: 'Invesco Dynamic Pharmaceuticals ETF',
    symbol: 'PJP',
  },
  {
    value: 'DKL',
    label: 'DKL,  Delek Logistics Partners, LP',
    name: 'Delek Logistics Partners, LP',
    symbol: 'DKL',
  },
  {
    value: 'SLY',
    label: 'SLY,  SPDR S&P 600 Small Cap ETF',
    name: 'SPDR S&P 600 Small Cap ETF',
    symbol: 'SLY',
  },
  {
    value: 'SPFF',
    label: 'SPFF,  Global X SuperIncome Preferred ETF',
    name: 'Global X SuperIncome Preferred ETF',
    symbol: 'SPFF',
  },
  {
    value: 'ALG',
    label: 'ALG,  Alamo Group Inc.',
    name: 'Alamo Group Inc.',
    symbol: 'ALG',
  },
  {
    value: 'ARKQ',
    label: 'ARKQ,  ARK Autonomous Technology & Robotics ETF',
    name: 'ARK Autonomous Technology & Robotics ETF',
    symbol: 'ARKQ',
  },
  {
    value: 'ARKR',
    label: 'ARKR,  Ark Restaurants Corp.',
    name: 'Ark Restaurants Corp.',
    symbol: 'ARKR',
  },
  {
    value: 'ATOS',
    label: 'ATOS,  Atossa Therapeutics, Inc.',
    name: 'Atossa Therapeutics, Inc.',
    symbol: 'ATOS',
  },
  {
    value: 'BBH',
    label: 'BBH,  VanEck Biotech ETF',
    name: 'VanEck Biotech ETF',
    symbol: 'BBH',
  },
  {
    value: 'BIOL',
    label: 'BIOL,  BIOLASE, Inc.',
    name: 'BIOLASE, Inc.',
    symbol: 'BIOL',
  },
  {
    value: 'BLW',
    label: 'BLW,  BlackRock Limited Duration Income Trust',
    name: 'BlackRock Limited Duration Income Trust',
    symbol: 'BLW',
  },
  {
    value: 'BSL',
    label: 'BSL,  Blackstone / GSO Senior Floating Rate Term Fund',
    name: 'Blackstone / GSO Senior Floating Rate Term Fund',
    symbol: 'BSL',
  },
  {
    value: 'BTT',
    label: 'BTT,  Blackrock Municipal 2030 Target Term Trust',
    name: 'Blackrock Municipal 2030 Target Term Trust',
    symbol: 'BTT',
  },
  {
    value: 'CET',
    label: 'CET,  Central Securities Corp.',
    name: 'Central Securities Corp.',
    symbol: 'CET',
  },
  {
    value: 'CGNT',
    label: 'CGNT,  Cognyte Software Ltd.',
    name: 'Cognyte Software Ltd.',
    symbol: 'CGNT',
  },
  {
    value: 'CIGI',
    label: 'CIGI,  Colliers International Group Inc.',
    name: 'Colliers International Group Inc.',
    symbol: 'CIGI',
  },
  {
    value: 'CRBN',
    label: 'CRBN,  iShares MSCI ACWI Low Carbon Target ETF',
    name: 'iShares MSCI ACWI Low Carbon Target ETF',
    symbol: 'CRBN',
  },
  {
    value: 'CREG',
    label: 'CREG,  Smart Powerr Corp.',
    name: 'Smart Powerr Corp.',
    symbol: 'CREG',
  },
  {
    value: 'DNN',
    label: 'DNN,  Denison Mines Corp.',
    name: 'Denison Mines Corp.',
    symbol: 'DNN',
  },
  {
    value: 'DOL',
    label: 'DOL,  WisdomTree International LargeCap Dividend Fund',
    name: 'WisdomTree International LargeCap Dividend Fund',
    symbol: 'DOL',
  },
  {
    value: 'DTW',
    label: 'DTW,  DTE Energy Company JR SUB DB 2017 E',
    name: 'DTE Energy Company JR SUB DB 2017 E',
    symbol: 'DTW',
  },
  {
    value: 'EUSC',
    label: 'EUSC,  WisdomTree Europe Hedged SmallCap Equity Fund',
    name: 'WisdomTree Europe Hedged SmallCap Equity Fund',
    symbol: 'EUSC',
  },
  {
    value: 'GENC',
    label: 'GENC,  Gencor Industries, Inc.',
    name: 'Gencor Industries, Inc.',
    symbol: 'GENC',
  },
  {
    value: 'GNMA',
    label: 'GNMA,  iShares GNMA Bond ETF',
    name: 'iShares GNMA Bond ETF',
    symbol: 'GNMA',
  },
  {
    value: 'GRX',
    label: 'GRX,  The Gabelli Healthcare & Wellness Trust',
    name: 'The Gabelli Healthcare & Wellness Trust',
    symbol: 'GRX',
  },
  {
    value: 'GVI',
    label: 'GVI,  iShares Intermediate Government/Credit Bond ETF',
    name: 'iShares Intermediate Government/Credit Bond ETF',
    symbol: 'GVI',
  },
  {
    value: 'IAE',
    label: 'IAE,  Voya Asia Pacific High Dividend Equity Income Fund',
    name: 'Voya Asia Pacific High Dividend Equity Income Fund',
    symbol: 'IAE',
  },
  {
    value: 'IBDQ',
    label: 'IBDQ,  iShares iBonds Dec 2025 Term Corporate ETF',
    name: 'iShares iBonds Dec 2025 Term Corporate ETF',
    symbol: 'IBDQ',
  },
  {
    value: 'ICF',
    label: 'ICF,  iShares Cohen & Steers REIT ETF',
    name: 'iShares Cohen & Steers REIT ETF',
    symbol: 'ICF',
  },
  {
    value: 'IOSP',
    label: 'IOSP,  Innospec Inc.',
    name: 'Innospec Inc.',
    symbol: 'IOSP',
  },
  {
    value: 'JCE',
    label: 'JCE,  Nuveen Core Equity Alpha Fund',
    name: 'Nuveen Core Equity Alpha Fund',
    symbol: 'JCE',
  },
  {
    value: 'KLDW',
    label: 'KLDW,  Knowledge Leaders Developed World ETF',
    name: 'Knowledge Leaders Developed World ETF',
    symbol: 'KLDW',
  },
  {
    value: 'MFM',
    label: 'MFM,  MFS Municipal Income Trust',
    name: 'MFS Municipal Income Trust',
    symbol: 'MFM',
  },
  {
    value: 'MNOV',
    label: 'MNOV,  MediciNova, Inc.',
    name: 'MediciNova, Inc.',
    symbol: 'MNOV',
  },
  {
    value: 'MQY',
    label: 'MQY,  BlackRock MuniYield Quality Fund, Inc.',
    name: 'BlackRock MuniYield Quality Fund, Inc.',
    symbol: 'MQY',
  },
  {
    value: 'MYI',
    label: 'MYI,  BlackRock MuniYield Quality Fund III, Inc.',
    name: 'BlackRock MuniYield Quality Fund III, Inc.',
    symbol: 'MYI',
  },
  {
    value: 'NURO',
    label: 'NURO,  NeuroMetrix, Inc.',
    name: 'NeuroMetrix, Inc.',
    symbol: 'NURO',
  },
  {
    value: 'NVEC',
    label: 'NVEC,  NVE Corporation',
    name: 'NVE Corporation',
    symbol: 'NVEC',
  },
  {
    value: 'OVID',
    label: 'OVID,  Ovid Therapeutics Inc.',
    name: 'Ovid Therapeutics Inc.',
    symbol: 'OVID',
  },
  {
    value: 'PCTI',
    label: 'PCTI,  PCTEL, Inc.',
    name: 'PCTEL, Inc.',
    symbol: 'PCTI',
  },
  {
    value: 'PHDG',
    label: 'PHDG,  Invesco S&P 500 Downside Hedged ETF',
    name: 'Invesco S&P 500 Downside Hedged ETF',
    symbol: 'PHDG',
  },
  {
    value: 'PLM',
    label: 'PLM,  PolyMet Mining Corp.',
    name: 'PolyMet Mining Corp.',
    symbol: 'PLM',
  },
  {
    value: 'PLSE',
    label: 'PLSE,  Pulse Biosciences, Inc.',
    name: 'Pulse Biosciences, Inc.',
    symbol: 'PLSE',
  },
  {
    value: 'PMM',
    label: 'PMM,  Putnam Managed Municipal Income Trust',
    name: 'Putnam Managed Municipal Income Trust',
    symbol: 'PMM',
  },
  {
    value: 'RBCAA',
    label: 'RBCAA,  Republic Bancorp, Inc.',
    name: 'Republic Bancorp, Inc.',
    symbol: 'RBCAA',
  },
  {
    value: 'RVP',
    label: 'RVP,  Retractable Technologies, Inc.',
    name: 'Retractable Technologies, Inc.',
    symbol: 'RVP',
  },
  {
    value: 'SAFE',
    label: 'SAFE,  Safehold Inc.',
    name: 'Safehold Inc.',
    symbol: 'SAFE',
  },
  {
    value: 'SAL',
    label: 'SAL,  Salisbury Bancorp, Inc.',
    name: 'Salisbury Bancorp, Inc.',
    symbol: 'SAL',
  },
  {
    value: 'SIL',
    label: 'SIL,  Global X Silver Miners ETF',
    name: 'Global X Silver Miners ETF',
    symbol: 'SIL',
  },
  {
    value: 'SLQD',
    label: 'SLQD,  iShares 0-5 Year Investment Grade Corporate Bond ETF',
    name: 'iShares 0-5 Year Investment Grade Corporate Bond ETF',
    symbol: 'SLQD',
  },
  {
    value: 'SRAX',
    label: 'SRAX,  SRAX, Inc.',
    name: 'SRAX, Inc.',
    symbol: 'SRAX',
  },
  {
    value: 'VET',
    label: 'VET,  Vermilion Energy Inc.',
    name: 'Vermilion Energy Inc.',
    symbol: 'VET',
  },
  {
    value: 'VSMV',
    label: 'VSMV,  VictoryShares US Multi-Factor Minimum Volatility ETF',
    name: 'VictoryShares US Multi-Factor Minimum Volatility ETF',
    symbol: 'VSMV',
  },
  {
    value: 'WF',
    label: 'WF,  Woori Financial Group Inc.',
    name: 'Woori Financial Group Inc.',
    symbol: 'WF',
  },
  {
    value: 'JHI',
    label: 'JHI,  John Hancock Investors Trust',
    name: 'John Hancock Investors Trust',
    symbol: 'JHI',
  },
  {
    value: 'IDX',
    label: 'IDX,  VanEck Indonesia Index ETF',
    name: 'VanEck Indonesia Index ETF',
    symbol: 'IDX',
  },
  {
    value: 'ARKW',
    label: 'ARKW,  ARK Next Generation Internet ETF',
    name: 'ARK Next Generation Internet ETF',
    symbol: 'ARKW',
  },
  {
    value: 'FCVT',
    label: 'FCVT,  First Trust SSI Strategic Convertible Securities ETF',
    name: 'First Trust SSI Strategic Convertible Securities ETF',
    symbol: 'FCVT',
  },
  {
    value: 'PFL',
    label: 'PFL,  PIMCO Income Strategy Fund',
    name: 'PIMCO Income Strategy Fund',
    symbol: 'PFL',
  },
  {
    value: 'FIDU',
    label: 'FIDU,  Fidelity MSCI Industrials Index ETF',
    name: 'Fidelity MSCI Industrials Index ETF',
    symbol: 'FIDU',
  },
  {
    value: 'BCH',
    label: 'BCH,  Banco de Chile',
    name: 'Banco de Chile',
    symbol: 'BCH',
  },
  {
    value: 'IX',
    label: 'IX,  ORIX Corporation',
    name: 'ORIX Corporation',
    symbol: 'IX',
  },
  {
    value: 'PBE',
    label: 'PBE,  Invesco Dynamic Biotechnology & Genome ETF',
    name: 'Invesco Dynamic Biotechnology & Genome ETF',
    symbol: 'PBE',
  },
  {
    value: 'FXU',
    label: 'FXU,  First Trust Utilities AlphaDEX Fund',
    name: 'First Trust Utilities AlphaDEX Fund',
    symbol: 'FXU',
  },
  {
    value: 'USRT',
    label: 'USRT,  iShares Core U.S. REIT ETF',
    name: 'iShares Core U.S. REIT ETF',
    symbol: 'USRT',
  },
  {
    value: 'GNR',
    label: 'GNR,  SPDR S&P Global Natural Resources ETF',
    name: 'SPDR S&P Global Natural Resources ETF',
    symbol: 'GNR',
  },
  {
    value: 'SNGX',
    label: 'SNGX,  Soligenix, Inc.',
    name: 'Soligenix, Inc.',
    symbol: 'SNGX',
  },
  {
    value: 'NINE',
    label: 'NINE,  Nine Energy Service, Inc.',
    name: 'Nine Energy Service, Inc.',
    symbol: 'NINE',
  },
  {
    value: 'VPL',
    label: 'VPL,  Vanguard Pacific Stock Index Fund',
    name: 'Vanguard Pacific Stock Index Fund',
    symbol: 'VPL',
  },
  {
    value: 'MDYV',
    label: 'MDYV,  SPDR S&P 400 Mid Cap Value ETF',
    name: 'SPDR S&P 400 Mid Cap Value ETF',
    symbol: 'MDYV',
  },
  {
    value: 'SGG',
    label: 'SGG,  iPath Series B Bloomberg Sugar Subindex Total Return ETN',
    name: 'iPath Series B Bloomberg Sugar Subindex Total Return ETN',
    symbol: 'SGG',
  },
  {
    value: 'CSBR',
    label: 'CSBR,  Champions Oncology, Inc.',
    name: 'Champions Oncology, Inc.',
    symbol: 'CSBR',
  },
  {
    value: 'SPNS',
    label: 'SPNS,  Sapiens International Corporation N.V.',
    name: 'Sapiens International Corporation N.V.',
    symbol: 'SPNS',
  },
  {
    value: 'GF',
    label: 'GF,  The New Germany Fund, Inc.',
    name: 'The New Germany Fund, Inc.',
    symbol: 'GF',
  },
  {
    value: 'FF',
    label: 'FF,  FutureFuel Corp.',
    name: 'FutureFuel Corp.',
    symbol: 'FF',
  },
  {
    value: 'FIHD',
    label: 'FIHD,  UBS AG FI Enhanced Global High Yield ETN',
    name: 'UBS AG FI Enhanced Global High Yield ETN',
    symbol: 'FIHD',
  },
  {
    value: 'UGA',
    label: 'UGA,  United States Gasoline Fund, LP',
    name: 'United States Gasoline Fund, LP',
    symbol: 'UGA',
  },
  {
    value: 'IMTM',
    label: 'IMTM,  iShares MSCI Intl Momentum Factor ETF',
    name: 'iShares MSCI Intl Momentum Factor ETF',
    symbol: 'IMTM',
  },
  {
    value: 'AVGR',
    label: 'AVGR,  Avinger, Inc.',
    name: 'Avinger, Inc.',
    symbol: 'AVGR',
  },
  {
    value: 'ACMR',
    label: 'ACMR,  ACM Research, Inc.',
    name: 'ACM Research, Inc.',
    symbol: 'ACMR',
  },
  {
    value: 'GWRS',
    label: 'GWRS,  Global Water Resources, Inc.',
    name: 'Global Water Resources, Inc.',
    symbol: 'GWRS',
  },
  {
    value: 'PRK',
    label: 'PRK,  Park National Corporation',
    name: 'Park National Corporation',
    symbol: 'PRK',
  },
  {
    value: 'SLYV',
    label: 'SLYV,  SPDR S&P 600 Small Cap Value ETF',
    name: 'SPDR S&P 600 Small Cap Value ETF',
    symbol: 'SLYV',
  },
  {
    value: 'PCF',
    label: 'PCF,  High Income Securities Fund',
    name: 'High Income Securities Fund',
    symbol: 'PCF',
  },
  {
    value: 'CCBG',
    label: 'CCBG,  Capital City Bank Group, Inc.',
    name: 'Capital City Bank Group, Inc.',
    symbol: 'CCBG',
  },
  {
    value: 'DBV',
    label: 'DBV,  Invesco DB G10 Currency Harvest Fund',
    name: 'Invesco DB G10 Currency Harvest Fund',
    symbol: 'DBV',
  },
  {
    value: 'PPLT',
    label: 'PPLT,  abrdn Physical Platinum Shares ETF',
    name: 'abrdn Physical Platinum Shares ETF',
    symbol: 'PPLT',
  },
  {
    value: 'SMN',
    label: 'SMN,  ProShares UltraShort Basic Materials',
    name: 'ProShares UltraShort Basic Materials',
    symbol: 'SMN',
  },
  {
    value: 'IMH',
    label: 'IMH,  Impac Mortgage Holdings, Inc.',
    name: 'Impac Mortgage Holdings, Inc.',
    symbol: 'IMH',
  },
  {
    value: 'FPX',
    label: 'FPX,  First Trust US Equity Opportunities ETF',
    name: 'First Trust US Equity Opportunities ETF',
    symbol: 'FPX',
  },
  {
    value: 'MGK',
    label: 'MGK,  Vanguard Mega Cap Growth Index Fund',
    name: 'Vanguard Mega Cap Growth Index Fund',
    symbol: 'MGK',
  },
  {
    value: 'BMRC',
    label: 'BMRC,  Bank of Marin Bancorp',
    name: 'Bank of Marin Bancorp',
    symbol: 'BMRC',
  },
  {
    value: 'PBW',
    label: 'PBW,  Invesco WilderHill Clean Energy ETF',
    name: 'Invesco WilderHill Clean Energy ETF',
    symbol: 'PBW',
  },
  {
    value: 'RGI',
    label: 'RGI,  Invesco S&P 500 Equal Weight Industrials ETF',
    name: 'Invesco S&P 500 Equal Weight Industrials ETF',
    symbol: 'RGI',
  },
  {
    value: 'XPH',
    label: 'XPH,  SPDR S&P Pharmaceuticals ETF',
    name: 'SPDR S&P Pharmaceuticals ETF',
    symbol: 'XPH',
  },
  {
    value: 'FXZ',
    label: 'FXZ,  First Trust Materials AlphaDEX Fund',
    name: 'First Trust Materials AlphaDEX Fund',
    symbol: 'FXZ',
  },
  {
    value: 'FSZ',
    label: 'FSZ,  First Trust Switzerland AlphaDEX Fund',
    name: 'First Trust Switzerland AlphaDEX Fund',
    symbol: 'FSZ',
  },
  {
    value: 'PDI',
    label: 'PDI,  PIMCO Dynamic Income Fund',
    name: 'PIMCO Dynamic Income Fund',
    symbol: 'PDI',
  },
  {
    value: 'FBZ',
    label: 'FBZ,  First Trust Brazil AlphaDEX Fund',
    name: 'First Trust Brazil AlphaDEX Fund',
    symbol: 'FBZ',
  },
  {
    value: 'IJK',
    label: 'IJK,  iShares S&P Mid-Cap 400 Growth ETF',
    name: 'iShares S&P Mid-Cap 400 Growth ETF',
    symbol: 'IJK',
  },
  {
    value: 'AC',
    label: 'AC,  Associated Capital Group, Inc.',
    name: 'Associated Capital Group, Inc.',
    symbol: 'AC',
  },
  {
    value: 'PEO',
    label: 'PEO,  Adams Natural Resources Fund, Inc.',
    name: 'Adams Natural Resources Fund, Inc.',
    symbol: 'PEO',
  },
  {
    value: 'DHIL',
    label: 'DHIL,  Diamond Hill Investment Group, Inc.',
    name: 'Diamond Hill Investment Group, Inc.',
    symbol: 'DHIL',
  },
  {
    value: 'CIBR',
    label: 'CIBR,  First Trust NASDAQ Cybersecurity ETF',
    name: 'First Trust NASDAQ Cybersecurity ETF',
    symbol: 'CIBR',
  },
  {
    value: 'REW',
    label: 'REW,  ProShares UltraShort Technology',
    name: 'ProShares UltraShort Technology',
    symbol: 'REW',
  },
  {
    value: 'XPP',
    label: 'XPP,  ProShares Ultra FTSE China 50',
    name: 'ProShares Ultra FTSE China 50',
    symbol: 'XPP',
  },
  {
    value: 'ESSA',
    label: 'ESSA,  ESSA Bancorp, Inc.',
    name: 'ESSA Bancorp, Inc.',
    symbol: 'ESSA',
  },
  {
    value: 'FFNW',
    label: 'FFNW,  First Financial Northwest, Inc.',
    name: 'First Financial Northwest, Inc.',
    symbol: 'FFNW',
  },
  {
    value: 'CELC',
    label: 'CELC,  Celcuity Inc.',
    name: 'Celcuity Inc.',
    symbol: 'CELC',
  },
  {
    value: 'OPY',
    label: 'OPY,  Oppenheimer Holdings Inc.',
    name: 'Oppenheimer Holdings Inc.',
    symbol: 'OPY',
  },
  {
    value: 'TIPX',
    label: 'TIPX,  SPDR Bloomberg 1-10 Year TIPS ETF',
    name: 'SPDR Bloomberg 1-10 Year TIPS ETF',
    symbol: 'TIPX',
  },
  {
    value: 'KWR',
    label: 'KWR,  Quaker Chemical Corporation',
    name: 'Quaker Chemical Corporation',
    symbol: 'KWR',
  },
  {
    value: 'CMSA',
    label: 'CMSA,  CMS Energy Corporation 5.6% JRSUB NT 78',
    name: 'CMS Energy Corporation 5.6% JRSUB NT 78',
    symbol: 'CMSA',
  },
  {
    value: 'FCNCA',
    label: 'FCNCA,  First Citizens BancShares, Inc.',
    name: 'First Citizens BancShares, Inc.',
    symbol: 'FCNCA',
  },
  {
    value: 'USCI',
    label: 'USCI,  United States Commodity Index Fund, LP',
    name: 'United States Commodity Index Fund, LP',
    symbol: 'USCI',
  },
  {
    value: 'SPLB',
    label: 'SPLB,  SPDR Portfolio Long Term Corporate Bond ETF',
    name: 'SPDR Portfolio Long Term Corporate Bond ETF',
    symbol: 'SPLB',
  },
  {
    value: 'XONE',
    label:
      'XONE,  Bondbloxx Bloomberg One Year Target Duration US Treasury ETF',
    name: 'Bondbloxx Bloomberg One Year Target Duration US Treasury ETF',
    symbol: 'XONE',
  },
  {
    value: 'WTBA',
    label: 'WTBA,  West Bancorporation, Inc.',
    name: 'West Bancorporation, Inc.',
    symbol: 'WTBA',
  },
  {
    value: 'SCHO',
    label: 'SCHO,  Schwab Short-Term U.S. Treasury ETF',
    name: 'Schwab Short-Term U.S. Treasury ETF',
    symbol: 'SCHO',
  },
  {
    value: 'ULH',
    label: 'ULH,  Universal Logistics Holdings, Inc.',
    name: 'Universal Logistics Holdings, Inc.',
    symbol: 'ULH',
  },
  {
    value: 'ACHV',
    label: 'ACHV,  Achieve Life Sciences, Inc.',
    name: 'Achieve Life Sciences, Inc.',
    symbol: 'ACHV',
  },
  {
    value: 'AIRR',
    label: 'AIRR,  First Trust RBA American Industrial RenaissanceTM ETF',
    name: 'First Trust RBA American Industrial RenaissanceTM ETF',
    symbol: 'AIRR',
  },
  {
    value: 'ALT',
    label: 'ALT,  Altimmune, Inc.',
    name: 'Altimmune, Inc.',
    symbol: 'ALT',
  },
  {
    value: 'ARKG',
    label: 'ARKG,  ARK Genomic Revolution ETF',
    name: 'ARK Genomic Revolution ETF',
    symbol: 'ARKG',
  },
  {
    value: 'AROW',
    label: 'AROW,  Arrow Financial Corporation',
    name: 'Arrow Financial Corporation',
    symbol: 'AROW',
  },
  {
    value: 'AUMN',
    label: 'AUMN,  Golden Minerals Company',
    name: 'Golden Minerals Company',
    symbol: 'AUMN',
  },
  {
    value: 'AZRE',
    label: 'AZRE,  Azure Power Global Limited',
    name: 'Azure Power Global Limited',
    symbol: 'AZRE',
  },
  {
    value: 'BAB',
    label: 'BAB,  Invesco Taxable Municipal Bond ETF',
    name: 'Invesco Taxable Municipal Bond ETF',
    symbol: 'BAB',
  },
  {
    value: 'BANF',
    label: 'BANF,  BancFirst Corporation',
    name: 'BancFirst Corporation',
    symbol: 'BANF',
  },
  {
    value: 'BELFB',
    label: 'BELFB,  Bel Fuse Inc.',
    name: 'Bel Fuse Inc.',
    symbol: 'BELFB',
  },
  {
    value: 'BHK',
    label: 'BHK,  BlackRock Core Bond Trust',
    name: 'BlackRock Core Bond Trust',
    symbol: 'BHK',
  },
  {
    value: 'BPT',
    label: 'BPT,  BP Prudhoe Bay Royalty Trust',
    name: 'BP Prudhoe Bay Royalty Trust',
    symbol: 'BPT',
  },
  {
    value: 'BRF',
    label: 'BRF,  VanEck Brazil Small-Cap ETF',
    name: 'VanEck Brazil Small-Cap ETF',
    symbol: 'BRF',
  },
  {
    value: 'BTAL',
    label: 'BTAL,  AGFiQ U.S. Market Neutral Anti-Beta Fund',
    name: 'AGFiQ U.S. Market Neutral Anti-Beta Fund',
    symbol: 'BTAL',
  },
  {
    value: 'BYFC',
    label: 'BYFC,  Broadway Financial Corporation',
    name: 'Broadway Financial Corporation',
    symbol: 'BYFC',
  },
  {
    value: 'CBH',
    label: 'CBH,  Virtus Convertible & Income 2024 Target Term Fund',
    name: 'Virtus Convertible & Income 2024 Target Term Fund',
    symbol: 'CBH',
  },
  {
    value: 'NUWE',
    label: 'NUWE,  Nuwellis, Inc.',
    name: 'Nuwellis, Inc.',
    symbol: 'NUWE',
  },
  {
    value: 'CHMG',
    label: 'CHMG,  Chemung Financial Corporation',
    name: 'Chemung Financial Corporation',
    symbol: 'CHMG',
  },
  {
    value: 'CMCL',
    label: 'CMCL,  Caledonia Mining Corporation Plc',
    name: 'Caledonia Mining Corporation Plc',
    symbol: 'CMCL',
  },
  {
    value: 'COE',
    label: 'COE,  51Talk Online Education Group',
    name: '51Talk Online Education Group',
    symbol: 'COE',
  },
  {
    value: 'CPIX',
    label: 'CPIX,  Cumberland Pharmaceuticals Inc.',
    name: 'Cumberland Pharmaceuticals Inc.',
    symbol: 'CPIX',
  },
  {
    value: 'CGRN',
    label: 'CGRN,  Capstone Green Energy Corporation',
    name: 'Capstone Green Energy Corporation',
    symbol: 'CGRN',
  },
  {
    value: 'CSTR',
    label: 'CSTR,  CapStar Financial Holdings, Inc.',
    name: 'CapStar Financial Holdings, Inc.',
    symbol: 'CSTR',
  },
  {
    value: 'DFEN',
    label: 'DFEN,  Direxion Daily Aerospace & Defense Bull 3X Shares',
    name: 'Direxion Daily Aerospace & Defense Bull 3X Shares',
    symbol: 'DFEN',
  },
  {
    value: 'DFP',
    label: 'DFP,  Flaherty & Crumrine Dynamic Preferred and Income Fund Inc.',
    name: 'Flaherty & Crumrine Dynamic Preferred and Income Fund Inc.',
    symbol: 'DFP',
  },
  {
    value: 'DIAX',
    label: 'DIAX,  Nuveen Dow 30 Dynamic Overwrite Fund',
    name: 'Nuveen Dow 30 Dynamic Overwrite Fund',
    symbol: 'DIAX',
  },
  {
    value: 'DRIO',
    label: 'DRIO,  DarioHealth Corp.',
    name: 'DarioHealth Corp.',
    symbol: 'DRIO',
  },
  {
    value: 'EDI',
    label: 'EDI,  Virtus Stone Harbor Emerging Markets Total Income Fund',
    name: 'Virtus Stone Harbor Emerging Markets Total Income Fund',
    symbol: 'EDI',
  },
  {
    value: 'EEMA',
    label: 'EEMA,  iShares MSCI Emerging Markets Asia ETF',
    name: 'iShares MSCI Emerging Markets Asia ETF',
    symbol: 'EEMA',
  },
  {
    value: 'EFL',
    label: 'EFL,  Eaton Vance Floating-Rate 2022 Target Term Trust',
    name: 'Eaton Vance Floating-Rate 2022 Target Term Trust',
    symbol: 'EFL',
  },
  {
    value: 'ELSE',
    label: 'ELSE,  Electro-Sensors, Inc.',
    name: 'Electro-Sensors, Inc.',
    symbol: 'ELSE',
  },
  {
    value: 'EMHY',
    label: 'EMHY,  iShares J.P. Morgan EM High Yield Bond ETF',
    name: 'iShares J.P. Morgan EM High Yield Bond ETF',
    symbol: 'EMHY',
  },
  {
    value: 'ENOR',
    label: 'ENOR,  iShares MSCI Norway ETF',
    name: 'iShares MSCI Norway ETF',
    symbol: 'ENOR',
  },
  {
    value: 'ESLT',
    label: 'ESLT,  Elbit Systems Ltd.',
    name: 'Elbit Systems Ltd.',
    symbol: 'ESLT',
  },
  {
    value: 'EVOL',
    label: 'EVOL,  Symbolic Logic, Inc.',
    name: 'Symbolic Logic, Inc.',
    symbol: 'EVOL',
  },
  {
    value: 'FAM',
    label: 'FAM,  First Trust/Abrdn Global Opportunity Income Fund',
    name: 'First Trust/Abrdn Global Opportunity Income Fund',
    symbol: 'FAM',
  },
  {
    value: 'FDEU',
    label: 'FDEU,  First Trust Dynamic Europe Equity Income Fund',
    name: 'First Trust Dynamic Europe Equity Income Fund',
    symbol: 'FDEU',
  },
  {
    value: 'FENY',
    label: 'FENY,  Fidelity MSCI Energy Index ETF',
    name: 'Fidelity MSCI Energy Index ETF',
    symbol: 'FENY',
  },
  {
    value: 'FIF',
    label: 'FIF,  First Trust Energy Infrastructure Fund',
    name: 'First Trust Energy Infrastructure Fund',
    symbol: 'FIF',
  },
  {
    value: 'FLTR',
    label: 'FLTR,  VanEck IG Floating Rate ETF',
    name: 'VanEck IG Floating Rate ETF',
    symbol: 'FLTR',
  },
  {
    value: 'FNDF',
    label: 'FNDF,  Schwab Fundamental International Large Company Index ETF',
    name: 'Schwab Fundamental International Large Company Index ETF',
    symbol: 'FNDF',
  },
  {
    value: 'FRBA',
    label: 'FRBA,  First Bank',
    name: 'First Bank',
    symbol: 'FRBA',
  },
  {
    value: 'FRSX',
    label: 'FRSX,  Foresight Autonomous Holdings Ltd.',
    name: 'Foresight Autonomous Holdings Ltd.',
    symbol: 'FRSX',
  },
  {
    value: 'GASS',
    label: 'GASS,  StealthGas Inc.',
    name: 'StealthGas Inc.',
    symbol: 'GASS',
  },
  {
    value: 'GBLI',
    label: 'GBLI,  Global Indemnity Group, LLC',
    name: 'Global Indemnity Group, LLC',
    symbol: 'GBLI',
  },
  {
    value: 'GOAU',
    label: 'GOAU,  U.S. Global GO GOLD and Precious Metal Miners ETF',
    name: 'U.S. Global GO GOLD and Precious Metal Miners ETF',
    symbol: 'GOAU',
  },
  {
    value: 'GPP',
    label: 'GPP,  Green Plains Partners LP',
    name: 'Green Plains Partners LP',
    symbol: 'GPP',
  },
  {
    value: 'GSIE',
    label: 'GSIE,  Goldman Sachs ActiveBeta International Equity ETF',
    name: 'Goldman Sachs ActiveBeta International Equity ETF',
    symbol: 'GSIE',
  },
  {
    value: 'IBD',
    label: 'IBD,  Inspire Corporate Bond ETF',
    name: 'Inspire Corporate Bond ETF',
    symbol: 'IBD',
  },
  {
    value: 'IPDN',
    label: 'IPDN,  Professional Diversity Network, Inc.',
    name: 'Professional Diversity Network, Inc.',
    symbol: 'IPDN',
  },
  {
    value: 'IYJ',
    label: 'IYJ,  iShares U.S. Industrials ETF',
    name: 'iShares U.S. Industrials ETF',
    symbol: 'IYJ',
  },
  {
    value: 'JLS',
    label: 'JLS,  Nuveen Mortgage and Income Fund',
    name: 'Nuveen Mortgage and Income Fund',
    symbol: 'JLS',
  },
  {
    value: 'JOUT',
    label: 'JOUT,  Johnson Outdoors Inc.',
    name: 'Johnson Outdoors Inc.',
    symbol: 'JOUT',
  },
  {
    value: 'KBWB',
    label: 'KBWB,  Invesco KBW Bank ETF',
    name: 'Invesco KBW Bank ETF',
    symbol: 'KBWB',
  },
  {
    value: 'KEQU',
    label: 'KEQU,  Kewaunee Scientific Corporation',
    name: 'Kewaunee Scientific Corporation',
    symbol: 'KEQU',
  },
  {
    value: 'KSM',
    label: 'KSM,  DWS Strategic Municipal Income Trust',
    name: 'DWS Strategic Municipal Income Trust',
    symbol: 'KSM',
  },
  {
    value: 'LDP',
    label:
      'LDP,  Cohen & Steers Limited Duration Preferred and Income Fund, Inc.',
    name: 'Cohen & Steers Limited Duration Preferred and Income Fund, Inc.',
    symbol: 'LDP',
  },
  {
    value: 'LGI',
    label: 'LGI,  Lazard Global Total Return and Income Fund, Inc.',
    name: 'Lazard Global Total Return and Income Fund, Inc.',
    symbol: 'LGI',
  },
  {
    value: 'LIFE',
    label: 'LIFE,  aTyr Pharma, Inc.',
    name: 'aTyr Pharma, Inc.',
    symbol: 'LIFE',
  },
  {
    value: 'LPCN',
    label: 'LPCN,  Lipocine Inc.',
    name: 'Lipocine Inc.',
    symbol: 'LPCN',
  },
  {
    value: 'LTBR',
    label: 'LTBR,  Lightbridge Corporation',
    name: 'Lightbridge Corporation',
    symbol: 'LTBR',
  },
  {
    value: 'MHH',
    label: 'MHH,  Mastech Digital, Inc.',
    name: 'Mastech Digital, Inc.',
    symbol: 'MHH',
  },
  {
    value: 'MLR',
    label: 'MLR,  Miller Industries, Inc.',
    name: 'Miller Industries, Inc.',
    symbol: 'MLR',
  },
  {
    value: 'MNP',
    label: 'MNP,  Western Asset Municipal Partners Fund Inc.',
    name: 'Western Asset Municipal Partners Fund Inc.',
    symbol: 'MNP',
  },
  {
    value: 'MXF',
    label: 'MXF,  The Mexico Fund, Inc.',
    name: 'The Mexico Fund, Inc.',
    symbol: 'MXF',
  },
  {
    value: 'MYSZ',
    label: 'MYSZ,  My Size, Inc.',
    name: 'My Size, Inc.',
    symbol: 'MYSZ',
  },
  {
    value: 'NEPT',
    label: 'NEPT,  Neptune Wellness Solutions Inc.',
    name: 'Neptune Wellness Solutions Inc.',
    symbol: 'NEPT',
  },
  {
    value: 'NHS',
    label: 'NHS,  Neuberger Berman High Yield Strategies Fund Inc.',
    name: 'Neuberger Berman High Yield Strategies Fund Inc.',
    symbol: 'NHS',
  },
  {
    value: 'NIQ',
    label: 'NIQ,  Nuveen Intermediate Duration Quality Municipal Term Fund',
    name: 'Nuveen Intermediate Duration Quality Municipal Term Fund',
    symbol: 'NIQ',
  },
  {
    value: 'NODK',
    label: 'NODK,  NI Holdings, Inc.',
    name: 'NI Holdings, Inc.',
    symbol: 'NODK',
  },
  {
    value: 'NTIC',
    label: 'NTIC,  Northern Technologies International Corporation',
    name: 'Northern Technologies International Corporation',
    symbol: 'NTIC',
  },
  {
    value: 'OFS',
    label: 'OFS,  OFS Capital Corporation',
    name: 'OFS Capital Corporation',
    symbol: 'OFS',
  },
  {
    value: 'OPP',
    label: 'OPP,  RiverNorth/DoubleLine Strategic Opportunity Fund, Inc.',
    name: 'RiverNorth/DoubleLine Strategic Opportunity Fund, Inc.',
    symbol: 'OPP',
  },
  {
    value: 'PAR',
    label: 'PAR,  PAR Technology Corporation',
    name: 'PAR Technology Corporation',
    symbol: 'PAR',
  },
  {
    value: 'PFFD',
    label: 'PFFD,  Global X U.S. Preferred ETF',
    name: 'Global X U.S. Preferred ETF',
    symbol: 'PFFD',
  },
  {
    value: 'PGF',
    label: 'PGF,  Invesco Financial Preferred ETF',
    name: 'Invesco Financial Preferred ETF',
    symbol: 'PGF',
  },
  {
    value: 'PIXY',
    label: 'PIXY,  ShiftPixy, Inc.',
    name: 'ShiftPixy, Inc.',
    symbol: 'PIXY',
  },
  {
    value: 'PNI',
    label: 'PNI,  PIMCO New York Municipal Income Fund II',
    name: 'PIMCO New York Municipal Income Fund II',
    symbol: 'PNI',
  },
  {
    value: 'PSCD',
    label: 'PSCD,  Invesco S&P SmallCap Consumer Discretionary ETF',
    name: 'Invesco S&P SmallCap Consumer Discretionary ETF',
    symbol: 'PSCD',
  },
  {
    value: 'PYN',
    label: 'PYN,  PIMCO New York Municipal Income Fund III',
    name: 'PIMCO New York Municipal Income Fund III',
    symbol: 'PYN',
  },
  {
    value: 'QUMU',
    label: 'QUMU,  Qumu Corporation',
    name: 'Qumu Corporation',
    symbol: 'QUMU',
  },
  {
    value: 'QVAL',
    label: 'QVAL,  Alpha Architect U.S. Quantitative Value ETF',
    name: 'Alpha Architect U.S. Quantitative Value ETF',
    symbol: 'QVAL',
  },
  {
    value: 'RDCM',
    label: 'RDCM,  RADCOM Ltd.',
    name: 'RADCOM Ltd.',
    symbol: 'RDCM',
  },
  {
    value: 'RELL',
    label: 'RELL,  Richardson Electronics, Ltd.',
    name: 'Richardson Electronics, Ltd.',
    symbol: 'RELL',
  },
  {
    value: 'RILYG',
    label: 'RILYG,  B. Riley Financial, Inc. 5.00% Senior Notes due 2026',
    name: 'B. Riley Financial, Inc. 5.00% Senior Notes due 2026',
    symbol: 'RILYG',
  },
  {
    value: 'RJI',
    label:
      'RJI,  ELEMENTS Linked to the Rogers International Commodity Index - Total Return',
    name: 'ELEMENTS Linked to the Rogers International Commodity Index - Total Return',
    symbol: 'RJI',
  },
  {
    value: 'ROM',
    label: 'ROM,  ProShares Ultra Technology',
    name: 'ProShares Ultra Technology',
    symbol: 'ROM',
  },
  {
    value: 'SBI',
    label: 'SBI,  Western Asset Intermediate Muni Fund Inc.',
    name: 'Western Asset Intermediate Muni Fund Inc.',
    symbol: 'SBI',
  },
  {
    value: 'SDOG',
    label: 'SDOG,  ALPS Sector Dividend Dogs ETF',
    name: 'ALPS Sector Dividend Dogs ETF',
    symbol: 'SDOG',
  },
  {
    value: 'SDPI',
    label: 'SDPI,  Superior Drilling Products, Inc.',
    name: 'Superior Drilling Products, Inc.',
    symbol: 'SDPI',
  },
  {
    value: 'SEII',
    label: 'SEII,  Sharing Economy International Inc.',
    name: 'Sharing Economy International Inc.',
    symbol: 'SEII',
  },
  {
    value: 'SGC',
    label: 'SGC,  Superior Group of Companies, Inc.',
    name: 'Superior Group of Companies, Inc.',
    symbol: 'SGC',
  },
  {
    value: 'SMBK',
    label: 'SMBK,  SmartFinancial, Inc.',
    name: 'SmartFinancial, Inc.',
    symbol: 'SMBK',
  },
  {
    value: 'SOHO',
    label: 'SOHO,  Sotherly Hotels Inc.',
    name: 'Sotherly Hotels Inc.',
    symbol: 'SOHO',
  },
  {
    value: 'SRS',
    label: 'SRS,  ProShares UltraShort Real Estate',
    name: 'ProShares UltraShort Real Estate',
    symbol: 'SRS',
  },
  {
    value: 'STRS',
    label: 'STRS,  Stratus Properties Inc.',
    name: 'Stratus Properties Inc.',
    symbol: 'STRS',
  },
  {
    value: 'SYLD',
    label: 'SYLD,  Cambria Shareholder Yield ETF',
    name: 'Cambria Shareholder Yield ETF',
    symbol: 'SYLD',
  },
  {
    value: 'TECS',
    label: 'TECS,  Direxion Daily Technology Bear 3X Shares',
    name: 'Direxion Daily Technology Bear 3X Shares',
    symbol: 'TECS',
  },
  {
    value: 'TIG',
    label: 'TIG,  Trean Insurance Group, Inc.',
    name: 'Trean Insurance Group, Inc.',
    symbol: 'TIG',
  },
  {
    value: 'TIPT',
    label: 'TIPT,  Tiptree Inc.',
    name: 'Tiptree Inc.',
    symbol: 'TIPT',
  },
  {
    value: 'TMF',
    label: 'TMF,  Direxion Daily 20+ Year Treasury Bull 3X Shares',
    name: 'Direxion Daily 20+ Year Treasury Bull 3X Shares',
    symbol: 'TMF',
  },
  {
    value: 'TTNP',
    label: 'TTNP,  Titan Pharmaceuticals, Inc.',
    name: 'Titan Pharmaceuticals, Inc.',
    symbol: 'TTNP',
  },
  {
    value: 'TVC',
    label: 'TVC,  Tennessee Valley Authority PARRS D 2028',
    name: 'Tennessee Valley Authority PARRS D 2028',
    symbol: 'TVC',
  },
  {
    value: 'UBT',
    label: 'UBT,  ProShares Ultra 20+ Year Treasury',
    name: 'ProShares Ultra 20+ Year Treasury',
    symbol: 'UBT',
  },
  {
    value: 'VRIG',
    label: 'VRIG,  Invesco Variable Rate Investment Grade ETF',
    name: 'Invesco Variable Rate Investment Grade ETF',
    symbol: 'VRIG',
  },
  {
    value: 'VTHR',
    label: 'VTHR,  Vanguard Russell 3000 Index Fund',
    name: 'Vanguard Russell 3000 Index Fund',
    symbol: 'VTHR',
  },
  {
    value: 'VTN',
    label: 'VTN,  Invesco Trust for Investment Grade New York Municipals',
    name: 'Invesco Trust for Investment Grade New York Municipals',
    symbol: 'VTN',
  },
  {
    value: 'VTVT',
    label: 'VTVT,  vTv Therapeutics Inc.',
    name: 'vTv Therapeutics Inc.',
    symbol: 'VTVT',
  },
  {
    value: 'VUSE',
    label: 'VUSE,  Vident Core U.S. Equity Fund',
    name: 'Vident Core U.S. Equity Fund',
    symbol: 'VUSE',
  },
  {
    value: 'WIA',
    label: 'WIA,  Western Asset Inflation-Linked Income Fund',
    name: 'Western Asset Inflation-Linked Income Fund',
    symbol: 'WIA',
  },
  {
    value: 'WSTL',
    label: 'WSTL,  Westell Technologies, Inc.',
    name: 'Westell Technologies, Inc.',
    symbol: 'WSTL',
  },
  {
    value: 'XSD',
    label: 'XSD,  SPDR S&P Semiconductor ETF',
    name: 'SPDR S&P Semiconductor ETF',
    symbol: 'XSD',
  },
  {
    value: 'VRTS',
    label: 'VRTS,  Virtus Investment Partners, Inc.',
    name: 'Virtus Investment Partners, Inc.',
    symbol: 'VRTS',
  },
  {
    value: 'BOIL',
    label: 'BOIL,  ProShares Ultra Bloomberg Natural Gas',
    name: 'ProShares Ultra Bloomberg Natural Gas',
    symbol: 'BOIL',
  },
  {
    value: 'FWP',
    label: 'FWP,  Forward Pharma A/S',
    name: 'Forward Pharma A/S',
    symbol: 'FWP',
  },
  {
    value: 'YORW',
    label: 'YORW,  The York Water Company',
    name: 'The York Water Company',
    symbol: 'YORW',
  },
  {
    value: 'TGEN',
    label: 'TGEN,  Tecogen Inc.',
    name: 'Tecogen Inc.',
    symbol: 'TGEN',
  },
  {
    value: 'SENEA',
    label: 'SENEA,  Seneca Foods Corporation',
    name: 'Seneca Foods Corporation',
    symbol: 'SENEA',
  },
  {
    value: 'PSCI',
    label: 'PSCI,  Invesco S&P SmallCap Industrials ETF',
    name: 'Invesco S&P SmallCap Industrials ETF',
    symbol: 'PSCI',
  },
  {
    value: 'EML',
    label: 'EML,  The Eastern Company',
    name: 'The Eastern Company',
    symbol: 'EML',
  },
  {
    value: 'PFMT',
    label: 'PFMT,  Performant Financial Corporation',
    name: 'Performant Financial Corporation',
    symbol: 'PFMT',
  },
  {
    value: 'CIF',
    label: 'CIF,  MFS Intermediate High Income Fund',
    name: 'MFS Intermediate High Income Fund',
    symbol: 'CIF',
  },
  {
    value: 'XMLV',
    label: 'XMLV,  Invesco S&P MidCap Low Volatility ETF',
    name: 'Invesco S&P MidCap Low Volatility ETF',
    symbol: 'XMLV',
  },
  {
    value: 'IAGG',
    label: 'IAGG,  iShares Core International Aggregate Bond ETF',
    name: 'iShares Core International Aggregate Bond ETF',
    symbol: 'IAGG',
  },
  {
    value: 'VBR',
    label: 'VBR,  Vanguard Small Cap Value Index Fund',
    name: 'Vanguard Small Cap Value Index Fund',
    symbol: 'VBR',
  },
  {
    value: 'CRVL',
    label: 'CRVL,  CorVel Corporation',
    name: 'CorVel Corporation',
    symbol: 'CRVL',
  },
  {
    value: 'IPAC',
    label: 'IPAC,  iShares Core MSCI Pacific ETF',
    name: 'iShares Core MSCI Pacific ETF',
    symbol: 'IPAC',
  },
  {
    value: 'BBU',
    label: 'BBU,  Brookfield Business Partners L.P.',
    name: 'Brookfield Business Partners L.P.',
    symbol: 'BBU',
  },
  {
    value: 'HYMB',
    label: 'HYMB,  SPDR Nuveen Bloomberg High Yield Municipal Bond ETF',
    name: 'SPDR Nuveen Bloomberg High Yield Municipal Bond ETF',
    symbol: 'HYMB',
  },
  {
    value: 'PVI',
    label: 'PVI,  Invesco VRDO Tax-Free ETF',
    name: 'Invesco VRDO Tax-Free ETF',
    symbol: 'PVI',
  },
  {
    value: 'ALBO',
    label: 'ALBO,  Albireo Pharma, Inc.',
    name: 'Albireo Pharma, Inc.',
    symbol: 'ALBO',
  },
  {
    value: 'URGN',
    label: 'URGN,  UroGen Pharma Ltd.',
    name: 'UroGen Pharma Ltd.',
    symbol: 'URGN',
  },
  {
    value: 'MSB',
    label: 'MSB,  Mesabi Trust',
    name: 'Mesabi Trust',
    symbol: 'MSB',
  },
  {
    value: 'BH',
    label: 'BH,  Biglari Holdings Inc.',
    name: 'Biglari Holdings Inc.',
    symbol: 'BH',
  },
  {
    value: 'JYNT',
    label: 'JYNT,  The Joint Corp.',
    name: 'The Joint Corp.',
    symbol: 'JYNT',
  },
  {
    value: 'MGV',
    label: 'MGV,  Vanguard Mega Cap Value Index Fund',
    name: 'Vanguard Mega Cap Value Index Fund',
    symbol: 'MGV',
  },
  {
    value: 'SCHK',
    label: 'SCHK,  Schwab 1000 Index ETF',
    name: 'Schwab 1000 Index ETF',
    symbol: 'SCHK',
  },
  {
    value: 'GRBK',
    label: 'GRBK,  Green Brick Partners, Inc.',
    name: 'Green Brick Partners, Inc.',
    symbol: 'GRBK',
  },
  {
    value: 'NCA',
    label: 'NCA,  Nuveen California Municipal Value Fund',
    name: 'Nuveen California Municipal Value Fund',
    symbol: 'NCA',
  },
  {
    value: 'FNLC',
    label: 'FNLC,  The First Bancorp, Inc.',
    name: 'The First Bancorp, Inc.',
    symbol: 'FNLC',
  },
  {
    value: 'MBCN',
    label: 'MBCN,  Middlefield Banc Corp.',
    name: 'Middlefield Banc Corp.',
    symbol: 'MBCN',
  },
  {
    value: 'FILL',
    label: 'FILL,  iShares MSCI Global Energy Producers ETF',
    name: 'iShares MSCI Global Energy Producers ETF',
    symbol: 'FILL',
  },
  {
    value: 'VONV',
    label: 'VONV,  Vanguard Russell 1000 Value Index Fund',
    name: 'Vanguard Russell 1000 Value Index Fund',
    symbol: 'VONV',
  },
  {
    value: 'NKSH',
    label: 'NKSH,  National Bankshares, Inc.',
    name: 'National Bankshares, Inc.',
    symbol: 'NKSH',
  },
  {
    value: 'AMNB',
    label: 'AMNB,  American National Bankshares Inc.',
    name: 'American National Bankshares Inc.',
    symbol: 'AMNB',
  },
  {
    value: 'UYG',
    label: 'UYG,  ProShares Ultra Financials',
    name: 'ProShares Ultra Financials',
    symbol: 'UYG',
  },
  {
    value: 'CHSCM',
    label: 'CHSCM,  CHS Inc.',
    name: 'CHS Inc.',
    symbol: 'CHSCM',
  },
  {
    value: 'CHAU',
    label: 'CHAU,  Direxion Daily CSI 300 China A Share Bull 2X Shares',
    name: 'Direxion Daily CSI 300 China A Share Bull 2X Shares',
    symbol: 'CHAU',
  },
  {
    value: 'GPJA',
    label: 'GPJA,  Georgia Power Company 5% JR SUB NT 77',
    name: 'Georgia Power Company 5% JR SUB NT 77',
    symbol: 'GPJA',
  },
  {
    value: 'RYH',
    label: 'RYH,  Invesco S&P 500 Equal Weight Health Care ETF',
    name: 'Invesco S&P 500 Equal Weight Health Care ETF',
    symbol: 'RYH',
  },
  {
    value: 'JHMT',
    label: 'JHMT,  John Hancock Multifactor Technology ETF',
    name: 'John Hancock Multifactor Technology ETF',
    symbol: 'JHMT',
  },
  {
    value: 'ONEQ',
    label: 'ONEQ,  Fidelity Nasdaq Composite Index ETF',
    name: 'Fidelity Nasdaq Composite Index ETF',
    symbol: 'ONEQ',
  },
  {
    value: 'LAND',
    label: 'LAND,  Gladstone Land Corporation',
    name: 'Gladstone Land Corporation',
    symbol: 'LAND',
  },
  {
    value: 'SCHR',
    label: 'SCHR,  Schwab Intermediate-Term U.S. Treasury ETF',
    name: 'Schwab Intermediate-Term U.S. Treasury ETF',
    symbol: 'SCHR',
  },
  {
    value: 'CHSCN',
    label: 'CHSCN,  CHS Inc.',
    name: 'CHS Inc.',
    symbol: 'CHSCN',
  },
  {
    value: 'YYY',
    label: 'YYY,  Amplify High Income ETF',
    name: 'Amplify High Income ETF',
    symbol: 'YYY',
  },
  {
    value: 'MILN',
    label: 'MILN,  Global X Millennials Consumer ETF',
    name: 'Global X Millennials Consumer ETF',
    symbol: 'MILN',
  },
  {
    value: 'CFFI',
    label: 'CFFI,  C&F Financial Corporation',
    name: 'C&F Financial Corporation',
    symbol: 'CFFI',
  },
  {
    value: 'AIEQ',
    label: 'AIEQ,  AI Powered Equity ETF',
    name: 'AI Powered Equity ETF',
    symbol: 'AIEQ',
  },
  {
    value: 'XAR',
    label: 'XAR,  SPDR S&P Aerospace & Defense ETF',
    name: 'SPDR S&P Aerospace & Defense ETF',
    symbol: 'XAR',
  },
  {
    value: 'AOR',
    label: 'AOR,  iShares Core Growth Allocation ETF',
    name: 'iShares Core Growth Allocation ETF',
    symbol: 'AOR',
  },
  {
    value: 'IXG',
    label: 'IXG,  iShares Global Financials ETF',
    name: 'iShares Global Financials ETF',
    symbol: 'IXG',
  },
  {
    value: 'VIS',
    label: 'VIS,  Vanguard Industrials Index Fund',
    name: 'Vanguard Industrials Index Fund',
    symbol: 'VIS',
  },
  {
    value: 'ALX',
    label: "ALX,  Alexander's, Inc.",
    name: "Alexander's, Inc.",
    symbol: 'ALX',
  },
  {
    value: 'ATXI',
    label: 'ATXI,  Avenue Therapeutics, Inc.',
    name: 'Avenue Therapeutics, Inc.',
    symbol: 'ATXI',
  },
  {
    value: 'RWR',
    label: 'RWR,  SPDR Dow Jones REIT ETF',
    name: 'SPDR Dow Jones REIT ETF',
    symbol: 'RWR',
  },
  {
    value: 'CMBS',
    label: 'CMBS,  iShares CMBS ETF',
    name: 'iShares CMBS ETF',
    symbol: 'CMBS',
  },
  {
    value: 'EDN',
    label:
      'EDN,  Empresa Distribuidora y Comercializadora Norte Sociedad Anónima',
    name: 'Empresa Distribuidora y Comercializadora Norte Sociedad Anónima',
    symbol: 'EDN',
  },
  {
    value: 'DGZ',
    label: 'DGZ,  DB Gold Short ETN',
    name: 'DB Gold Short ETN',
    symbol: 'DGZ',
  },
  {
    value: 'GURU',
    label: 'GURU,  Global X Guru Index ETF',
    name: 'Global X Guru Index ETF',
    symbol: 'GURU',
  },
  {
    value: 'KCE',
    label: 'KCE,  SPDR S&P Capital Markets ETF',
    name: 'SPDR S&P Capital Markets ETF',
    symbol: 'KCE',
  },
  {
    value: 'SHBI',
    label: 'SHBI,  Shore Bancshares, Inc.',
    name: 'Shore Bancshares, Inc.',
    symbol: 'SHBI',
  },
  {
    value: 'RFV',
    label: 'RFV,  Invesco S&P MidCap 400 Pure Value ETF',
    name: 'Invesco S&P MidCap 400 Pure Value ETF',
    symbol: 'RFV',
  },
  {
    value: 'CWBR',
    label: 'CWBR,  CohBar, Inc.',
    name: 'CohBar, Inc.',
    symbol: 'CWBR',
  },
  {
    value: 'IBUY',
    label: 'IBUY,  Amplify Online Retail ETF',
    name: 'Amplify Online Retail ETF',
    symbol: 'IBUY',
  },
  {
    value: 'MXC',
    label: 'MXC,  Mexco Energy Corporation',
    name: 'Mexco Energy Corporation',
    symbol: 'MXC',
  },
  {
    value: 'RYF',
    label: 'RYF,  Invesco S&P 500 Equal Weight Financials ETF',
    name: 'Invesco S&P 500 Equal Weight Financials ETF',
    symbol: 'RYF',
  },
  {
    value: 'PTEU',
    label: 'PTEU,  Pacer Trendpilot European Index ETF',
    name: 'Pacer Trendpilot European Index ETF',
    symbol: 'PTEU',
  },
  {
    value: 'MVO',
    label: 'MVO,  MV Oil Trust',
    name: 'MV Oil Trust',
    symbol: 'MVO',
  },
  {
    value: 'IRIX',
    label: 'IRIX,  IRIDEX Corporation',
    name: 'IRIDEX Corporation',
    symbol: 'IRIX',
  },
  {
    value: 'JETS',
    label: 'JETS,  U.S. Global Jets ETF',
    name: 'U.S. Global Jets ETF',
    symbol: 'JETS',
  },
  {
    value: 'AOA',
    label: 'AOA,  iShares Core Aggressive Allocation ETF',
    name: 'iShares Core Aggressive Allocation ETF',
    symbol: 'AOA',
  },
  {
    value: 'CYAD',
    label: 'CYAD,  Celyad Oncology SA',
    name: 'Celyad Oncology SA',
    symbol: 'CYAD',
  },
  {
    value: 'FFTY',
    label: 'FFTY,  Innovator IBD 50 ETF',
    name: 'Innovator IBD 50 ETF',
    symbol: 'FFTY',
  },
  {
    value: 'IWC',
    label: 'IWC,  iShares Micro-Cap ETF',
    name: 'iShares Micro-Cap ETF',
    symbol: 'IWC',
  },
  {
    value: 'XELA',
    label: 'XELA,  Exela Technologies, Inc.',
    name: 'Exela Technologies, Inc.',
    symbol: 'XELA',
  },
  {
    value: 'UFPT',
    label: 'UFPT,  UFP Technologies, Inc.',
    name: 'UFP Technologies, Inc.',
    symbol: 'UFPT',
  },
  {
    value: 'WIRE',
    label: 'WIRE,  Encore Wire Corporation',
    name: 'Encore Wire Corporation',
    symbol: 'WIRE',
  },
  {
    value: 'MYMD',
    label: 'MYMD,  MyMD Pharmaceuticals, Inc.',
    name: 'MyMD Pharmaceuticals, Inc.',
    symbol: 'MYMD',
  },
  {
    value: 'CLPR',
    label: 'CLPR,  Clipper Realty Inc.',
    name: 'Clipper Realty Inc.',
    symbol: 'CLPR',
  },
  {
    value: 'TCFC',
    label: 'TCFC,  The Community Financial Corporation',
    name: 'The Community Financial Corporation',
    symbol: 'TCFC',
  },
  {
    value: 'DAX',
    label: 'DAX,  Global X DAX Germany ETF',
    name: 'Global X DAX Germany ETF',
    symbol: 'DAX',
  },
  {
    value: 'DSI',
    label: 'DSI,  iShares MSCI KLD 400 Social ETF',
    name: 'iShares MSCI KLD 400 Social ETF',
    symbol: 'DSI',
  },
  {
    value: 'IGN',
    label: 'IGN,  iShares North American Tech-Multimedia Networking ETF',
    name: 'iShares North American Tech-Multimedia Networking ETF',
    symbol: 'IGN',
  },
  {
    value: 'ATRI',
    label: 'ATRI,  Atrion Corporation',
    name: 'Atrion Corporation',
    symbol: 'ATRI',
  },
  {
    value: 'BRID',
    label: 'BRID,  Bridgford Foods Corporation',
    name: 'Bridgford Foods Corporation',
    symbol: 'BRID',
  },
  {
    value: 'PGHY',
    label: 'PGHY,  Invesco Global Short Term High Yield Bond ETF',
    name: 'Invesco Global Short Term High Yield Bond ETF',
    symbol: 'PGHY',
  },
  {
    value: 'AUBN',
    label: 'AUBN,  Auburn National Bancorporation, Inc.',
    name: 'Auburn National Bancorporation, Inc.',
    symbol: 'AUBN',
  },
  {
    value: 'DWSN',
    label: 'DWSN,  Dawson Geophysical Company',
    name: 'Dawson Geophysical Company',
    symbol: 'DWSN',
  },
  {
    value: 'USD',
    label: 'USD,  ProShares Ultra Semiconductors',
    name: 'ProShares Ultra Semiconductors',
    symbol: 'USD',
  },
  {
    value: 'BWZ',
    label: 'BWZ,  SPDR Bloomberg Short Term International Treasury Bond ETF',
    name: 'SPDR Bloomberg Short Term International Treasury Bond ETF',
    symbol: 'BWZ',
  },
  {
    value: 'JOB',
    label: 'JOB,  GEE Group, Inc.',
    name: 'GEE Group, Inc.',
    symbol: 'JOB',
  },
  {
    value: 'OVBC',
    label: 'OVBC,  Ohio Valley Banc Corp.',
    name: 'Ohio Valley Banc Corp.',
    symbol: 'OVBC',
  },
  {
    value: 'SLYG',
    label: 'SLYG,  SPDR S&P 600 Small Cap Growth ETF',
    name: 'SPDR S&P 600 Small Cap Growth ETF',
    symbol: 'SLYG',
  },
  {
    value: 'ADRE',
    label: 'ADRE,  Invesco BLDRS Emerging Markets 50 ADR Index Fund',
    name: 'Invesco BLDRS Emerging Markets 50 ADR Index Fund',
    symbol: 'ADRE',
  },
  {
    value: 'AE',
    label: 'AE,  Adams Resources & Energy, Inc.',
    name: 'Adams Resources & Energy, Inc.',
    symbol: 'AE',
  },
  {
    value: 'AFT',
    label: 'AFT,  Apollo Senior Floating Rate Fund Inc.',
    name: 'Apollo Senior Floating Rate Fund Inc.',
    symbol: 'AFT',
  },
  {
    value: 'AHPI',
    label: 'AHPI,  Allied Healthcare Products, Inc.',
    name: 'Allied Healthcare Products, Inc.',
    symbol: 'AHPI',
  },
  {
    value: 'ALTY',
    label: 'ALTY,  Global X Alternative Income ETF',
    name: 'Global X Alternative Income ETF',
    symbol: 'ALTY',
  },
  {
    value: 'AMSF',
    label: 'AMSF,  AMERISAFE, Inc.',
    name: 'AMERISAFE, Inc.',
    symbol: 'AMSF',
  },
  {
    value: 'AP',
    label: 'AP,  Ampco-Pittsburgh Corporation',
    name: 'Ampco-Pittsburgh Corporation',
    symbol: 'AP',
  },
  {
    value: 'APWC',
    label: 'APWC,  Asia Pacific Wire & Cable Corporation Limited',
    name: 'Asia Pacific Wire & Cable Corporation Limited',
    symbol: 'APWC',
  },
  {
    value: 'ARCT',
    label: 'ARCT,  Arcturus Therapeutics Holdings Inc.',
    name: 'Arcturus Therapeutics Holdings Inc.',
    symbol: 'ARCT',
  },
  {
    value: 'ASPN',
    label: 'ASPN,  Aspen Aerogels, Inc.',
    name: 'Aspen Aerogels, Inc.',
    symbol: 'ASPN',
  },
  {
    value: 'AWRE',
    label: 'AWRE,  Aware, Inc.',
    name: 'Aware, Inc.',
    symbol: 'AWRE',
  },
  {
    value: 'BCTF',
    label: 'BCTF,  Bancorp 34, Inc.',
    name: 'Bancorp 34, Inc.',
    symbol: 'BCTF',
  },
  {
    value: 'BKT',
    label: 'BKT,  BlackRock Income Trust, Inc.',
    name: 'BlackRock Income Trust, Inc.',
    symbol: 'BKT',
  },
  {
    value: 'BLPH',
    label: 'BLPH,  Bellerophon Therapeutics, Inc.',
    name: 'Bellerophon Therapeutics, Inc.',
    symbol: 'BLPH',
  },
  {
    value: 'BNSO',
    label: 'BNSO,  Bonso Electronics International Inc.',
    name: 'Bonso Electronics International Inc.',
    symbol: 'BNSO',
  },
  {
    value: 'BNY',
    label: 'BNY,  BlackRock New York Municipal Income Trust',
    name: 'BlackRock New York Municipal Income Trust',
    symbol: 'BNY',
  },
  {
    value: 'BSCQ',
    label: 'BSCQ,  Invesco BulletShares 2026 Corporate Bond ETF',
    name: 'Invesco BulletShares 2026 Corporate Bond ETF',
    symbol: 'BSCQ',
  },
  {
    value: 'BSET',
    label: 'BSET,  Bassett Furniture Industries, Incorporated',
    name: 'Bassett Furniture Industries, Incorporated',
    symbol: 'BSET',
  },
  {
    value: 'BSJN',
    label: 'BSJN,  Invesco BulletShares 2023 High Yield Corporate Bond ETF',
    name: 'Invesco BulletShares 2023 High Yield Corporate Bond ETF',
    symbol: 'BSJN',
  },
  {
    value: 'BWEN',
    label: 'BWEN,  Broadwind, Inc.',
    name: 'Broadwind, Inc.',
    symbol: 'BWEN',
  },
  {
    value: 'BYM',
    label: 'BYM,  BlackRock Municipal Income Quality Trust',
    name: 'BlackRock Municipal Income Quality Trust',
    symbol: 'BYM',
  },
  {
    value: 'CAPE',
    label: 'CAPE,  DoubleLine Shiller CAPE U.S. Equities ETF',
    name: 'DoubleLine Shiller CAPE U.S. Equities ETF',
    symbol: 'CAPE',
  },
  {
    value: 'CASS',
    label: 'CASS,  Cass Information Systems, Inc.',
    name: 'Cass Information Systems, Inc.',
    symbol: 'CASS',
  },
  {
    value: 'CATH',
    label: 'CATH,  Global X S&P 500 Catholic Values ETF',
    name: 'Global X S&P 500 Catholic Values ETF',
    symbol: 'CATH',
  },
  {
    value: 'CHEK',
    label: 'CHEK,  Check-Cap Ltd.',
    name: 'Check-Cap Ltd.',
    symbol: 'CHEK',
  },
  {
    value: 'CHIX',
    label: 'CHIX,  Global X MSCI China Financials ETF',
    name: 'Global X MSCI China Financials ETF',
    symbol: 'CHIX',
  },
  {
    value: 'CHSCL',
    label: 'CHSCL,  CHS Inc.',
    name: 'CHS Inc.',
    symbol: 'CHSCL',
  },
  {
    value: 'CIDM',
    label: 'CIDM,  Cinedigm Corp.',
    name: 'Cinedigm Corp.',
    symbol: 'CIDM',
  },
  {
    value: 'CLWT',
    label: 'CLWT,  Euro Tech Holdings Company Limited',
    name: 'Euro Tech Holdings Company Limited',
    symbol: 'CLWT',
  },
  {
    value: 'CORN',
    label: 'CORN,  Teucrium Corn Fund',
    name: 'Teucrium Corn Fund',
    symbol: 'CORN',
  },
  {
    value: 'CPAC',
    label: 'CPAC,  Cementos Pacasmayo S.A.A.',
    name: 'Cementos Pacasmayo S.A.A.',
    symbol: 'CPAC',
  },
  {
    value: 'CSD',
    label: 'CSD,  Invesco S&P Spin-Off ETF',
    name: 'Invesco S&P Spin-Off ETF',
    symbol: 'CSD',
  },
  {
    value: 'CTS',
    label: 'CTS,  CTS Corporation',
    name: 'CTS Corporation',
    symbol: 'CTS',
  },
  {
    value: 'CUBA',
    label: 'CUBA,  The Herzfeld Caribbean Basin Fund Inc.',
    name: 'The Herzfeld Caribbean Basin Fund Inc.',
    symbol: 'CUBA',
  },
  {
    value: 'CULP',
    label: 'CULP,  Culp, Inc.',
    name: 'Culp, Inc.',
    symbol: 'CULP',
  },
  {
    value: 'CVV',
    label: 'CVV,  CVD Equipment Corporation',
    name: 'CVD Equipment Corporation',
    symbol: 'CVV',
  },
  {
    value: 'CYCC',
    label: 'CYCC,  Cyclacel Pharmaceuticals, Inc.',
    name: 'Cyclacel Pharmaceuticals, Inc.',
    symbol: 'CYCC',
  },
  {
    value: 'CZWI',
    label: 'CZWI,  Citizens Community Bancorp, Inc.',
    name: 'Citizens Community Bancorp, Inc.',
    symbol: 'CZWI',
  },
  {
    value: 'DBEM',
    label: 'DBEM,  Xtrackers MSCI Emerging Markets Hedged Equity ETF',
    name: 'Xtrackers MSCI Emerging Markets Hedged Equity ETF',
    symbol: 'DBEM',
  },
  {
    value: 'DFNL',
    label: 'DFNL,  Davis Select Financial ETF',
    name: 'Davis Select Financial ETF',
    symbol: 'DFNL',
  },
  {
    value: 'DHF',
    label: 'DHF,  BNY Mellon High Yield Strategies Fund',
    name: 'BNY Mellon High Yield Strategies Fund',
    symbol: 'DHF',
  },
  {
    value: 'DHS',
    label: 'DHS,  WisdomTree U.S. High Dividend Fund',
    name: 'WisdomTree U.S. High Dividend Fund',
    symbol: 'DHS',
  },
  {
    value: 'DIAL',
    label: 'DIAL,  Columbia Diversified Fixed Income Allocation ETF',
    name: 'Columbia Diversified Fixed Income Allocation ETF',
    symbol: 'DIAL',
  },
  {
    value: 'DINT',
    label: 'DINT,  Davis Select International ETF',
    name: 'Davis Select International ETF',
    symbol: 'DINT',
  },
  {
    value: 'DLA',
    label: 'DLA,  Delta Apparel, Inc.',
    name: 'Delta Apparel, Inc.',
    symbol: 'DLA',
  },
  {
    value: 'DRN',
    label: 'DRN,  Direxion Daily Real Estate Bull 3X Shares',
    name: 'Direxion Daily Real Estate Bull 3X Shares',
    symbol: 'DRN',
  },
  {
    value: 'DWAS',
    label: 'DWAS,  Invesco DWA SmallCap Momentum ETF',
    name: 'Invesco DWA SmallCap Momentum ETF',
    symbol: 'DWAS',
  },
  {
    value: 'EBTC',
    label: 'EBTC,  Enterprise Bancorp, Inc.',
    name: 'Enterprise Bancorp, Inc.',
    symbol: 'EBTC',
  },
  {
    value: 'EDAP',
    label: 'EDAP,  EDAP TMS S.A.',
    name: 'EDAP TMS S.A.',
    symbol: 'EDAP',
  },
  {
    value: 'EFAD',
    label: 'EFAD,  ProShares MSCI EAFE Dividend Growers ETF',
    name: 'ProShares MSCI EAFE Dividend Growers ETF',
    symbol: 'EFAD',
  },
  {
    value: 'EGF',
    label: 'EGF,  BlackRock Enhanced Government Fund, Inc.',
    name: 'BlackRock Enhanced Government Fund, Inc.',
    symbol: 'EGF',
  },
  {
    value: 'EIRL',
    label: 'EIRL,  iShares MSCI Ireland ETF',
    name: 'iShares MSCI Ireland ETF',
    symbol: 'EIRL',
  },
  {
    value: 'EKSO',
    label: 'EKSO,  Ekso Bionics Holdings, Inc.',
    name: 'Ekso Bionics Holdings, Inc.',
    symbol: 'EKSO',
  },
  {
    value: 'EMP',
    label: 'EMP,  Entergy Mississippi, Inc. 1M BD 66',
    name: 'Entergy Mississippi, Inc. 1M BD 66',
    symbol: 'EMP',
  },
  {
    value: 'ENJ',
    label: 'ENJ,  Entergy Utility Group, Inc. 1ST MTG 5% 52',
    name: 'Entergy Utility Group, Inc. 1ST MTG 5% 52',
    symbol: 'ENJ',
  },
  {
    value: 'EQWL',
    label: 'EQWL,  Invesco S&P 100 Equal Weight ETF',
    name: 'Invesco S&P 100 Equal Weight ETF',
    symbol: 'EQWL',
  },
  {
    value: 'ESGE',
    label: 'ESGE,  iShares ESG Aware MSCI EM ETF',
    name: 'iShares ESG Aware MSCI EM ETF',
    symbol: 'ESGE',
  },
  {
    value: 'ESP',
    label: 'ESP,  Espey Mfg. & Electronics Corp.',
    name: 'Espey Mfg. & Electronics Corp.',
    symbol: 'ESP',
  },
  {
    value: 'FCT',
    label: 'FCT,  First Trust Senior Floating Rate Income Fund II',
    name: 'First Trust Senior Floating Rate Income Fund II',
    symbol: 'FCT',
  },
  {
    value: 'FDIS',
    label: 'FDIS,  Fidelity MSCI Consumer Discretionary Index ETF',
    name: 'Fidelity MSCI Consumer Discretionary Index ETF',
    symbol: 'FDIS',
  },
  {
    value: 'FDL',
    label: 'FDL,  First Trust Morningstar Dividend Leaders Index Fund',
    name: 'First Trust Morningstar Dividend Leaders Index Fund',
    symbol: 'FDL',
  },
  {
    value: 'FDT',
    label: 'FDT,  First Trust Developed Markets Ex-US AlphaDEX Fund',
    name: 'First Trust Developed Markets Ex-US AlphaDEX Fund',
    symbol: 'FDT',
  },
  {
    value: 'FEMB',
    label: 'FEMB,  First Trust Emerging Markets Local Currency Bond ETF',
    name: 'First Trust Emerging Markets Local Currency Bond ETF',
    symbol: 'FEMB',
  },
  {
    value: 'FHLC',
    label: 'FHLC,  Fidelity MSCI Health Care Index ETF',
    name: 'Fidelity MSCI Health Care Index ETF',
    symbol: 'FHLC',
  },
  {
    value: 'FINX',
    label: 'FINX,  Global X FinTech ETF',
    name: 'Global X FinTech ETF',
    symbol: 'FINX',
  },
  {
    value: 'FLC',
    label: 'FLC,  Flaherty & Crumrine Total Return Fund Inc.',
    name: 'Flaherty & Crumrine Total Return Fund Inc.',
    symbol: 'FLC',
  },
  {
    value: 'FLL',
    label: 'FLL,  Full House Resorts, Inc.',
    name: 'Full House Resorts, Inc.',
    symbol: 'FLL',
  },
  {
    value: 'FLTB',
    label: 'FLTB,  Fidelity Limited Term Bond ETF',
    name: 'Fidelity Limited Term Bond ETF',
    symbol: 'FLTB',
  },
  {
    value: 'FMAO',
    label: 'FMAO,  Farmers & Merchants Bancorp, Inc.',
    name: 'Farmers & Merchants Bancorp, Inc.',
    symbol: 'FMAO',
  },
  {
    value: 'FMNB',
    label: 'FMNB,  Farmers National Banc Corp.',
    name: 'Farmers National Banc Corp.',
    symbol: 'FMNB',
  },
  {
    value: 'FNX',
    label: 'FNX,  First Trust Mid Cap Core AlphaDEX Fund',
    name: 'First Trust Mid Cap Core AlphaDEX Fund',
    symbol: 'FNX',
  },
  {
    value: 'FNY',
    label: 'FNY,  First Trust Mid Cap Growth AlphaDEX Fund',
    name: 'First Trust Mid Cap Growth AlphaDEX Fund',
    symbol: 'FNY',
  },
  {
    value: 'FRA',
    label: 'FRA,  BlackRock Floating Rate Income Strategies Fund, Inc.',
    name: 'BlackRock Floating Rate Income Strategies Fund, Inc.',
    symbol: 'FRA',
  },
  {
    value: 'FSTR',
    label: 'FSTR,  L.B. Foster Company',
    name: 'L.B. Foster Company',
    symbol: 'FSTR',
  },
  {
    value: 'FT',
    label: 'FT,  Franklin Universal Trust',
    name: 'Franklin Universal Trust',
    symbol: 'FT',
  },
  {
    value: 'FTC',
    label: 'FTC,  First Trust Large Cap Growth AlphaDEX Fund',
    name: 'First Trust Large Cap Growth AlphaDEX Fund',
    symbol: 'FTC',
  },
  {
    value: 'FTEK',
    label: 'FTEK,  Fuel Tech, Inc.',
    name: 'Fuel Tech, Inc.',
    symbol: 'FTEK',
  },
  {
    value: 'FTLS',
    label: 'FTLS,  First Trust Long/Short Equity ETF',
    name: 'First Trust Long/Short Equity ETF',
    symbol: 'FTLS',
  },
  {
    value: 'FXO',
    label: 'FXO,  First Trust Financials AlphaDEX Fund',
    name: 'First Trust Financials AlphaDEX Fund',
    symbol: 'FXO',
  },
  {
    value: 'GAL',
    label: 'GAL,  SPDR SSgA Global Allocation ETF',
    name: 'SPDR SSgA Global Allocation ETF',
    symbol: 'GAL',
  },
  {
    value: 'GCOW',
    label: 'GCOW,  Pacer Global Cash Cows Dividend ETF',
    name: 'Pacer Global Cash Cows Dividend ETF',
    symbol: 'GCOW',
  },
  {
    value: 'GDL',
    label: 'GDL,  The GDL Fund',
    name: 'The GDL Fund',
    symbol: 'GDL',
  },
  {
    value: 'GGT',
    label: 'GGT,  The Gabelli Multimedia Trust Inc.',
    name: 'The Gabelli Multimedia Trust Inc.',
    symbol: 'GGT',
  },
  {
    value: 'GII',
    label: 'GII,  SPDR S&P Global Infrastructure ETF',
    name: 'SPDR S&P Global Infrastructure ETF',
    symbol: 'GII',
  },
  {
    value: 'GLQ',
    label: 'GLQ,  Clough Global Equity Fund',
    name: 'Clough Global Equity Fund',
    symbol: 'GLQ',
  },
  {
    value: 'GLTR',
    label: 'GLTR,  abrdn Physical Precious Metals Basket Shares ETF',
    name: 'abrdn Physical Precious Metals Basket Shares ETF',
    symbol: 'GLTR',
  },
  {
    value: 'GNE',
    label: 'GNE,  Genie Energy Ltd.',
    name: 'Genie Energy Ltd.',
    symbol: 'GNE',
  },
  {
    value: 'GSBD',
    label: 'GSBD,  Goldman Sachs BDC, Inc.',
    name: 'Goldman Sachs BDC, Inc.',
    symbol: 'GSBD',
  },
  {
    value: 'GURE',
    label: 'GURE,  Gulf Resources, Inc.',
    name: 'Gulf Resources, Inc.',
    symbol: 'GURE',
  },
  {
    value: 'GVAL',
    label: 'GVAL,  Cambria Global Value ETF',
    name: 'Cambria Global Value ETF',
    symbol: 'GVAL',
  },
  {
    value: 'HALL',
    label: 'HALL,  Hallmark Financial Services, Inc.',
    name: 'Hallmark Financial Services, Inc.',
    symbol: 'HALL',
  },
  {
    value: 'HESM',
    label: 'HESM,  Hess Midstream LP',
    name: 'Hess Midstream LP',
    symbol: 'HESM',
  },
  {
    value: 'HIPS',
    label: 'HIPS,  GraniteShares HIPS US High Income ETF',
    name: 'GraniteShares HIPS US High Income ETF',
    symbol: 'HIPS',
  },
  {
    value: 'HNW',
    label: 'HNW,  Pioneer Diversified High Income Fund, Inc.',
    name: 'Pioneer Diversified High Income Fund, Inc.',
    symbol: 'HNW',
  },
  {
    value: 'HTD',
    label: 'HTD,  John Hancock Tax-Advantaged Dividend Income Fund',
    name: 'John Hancock Tax-Advantaged Dividend Income Fund',
    symbol: 'HTD',
  },
  {
    value: 'HUSV',
    label: 'HUSV,  First Trust Horizon Managed Volatility Domestic ETF',
    name: 'First Trust Horizon Managed Volatility Domestic ETF',
    symbol: 'HUSV',
  },
  {
    value: 'IAI',
    label: 'IAI,  iShares U.S. Broker-Dealers & Securities Exchanges ETF',
    name: 'iShares U.S. Broker-Dealers & Securities Exchanges ETF',
    symbol: 'IAI',
  },
  {
    value: 'IAK',
    label: 'IAK,  iShares U.S. Insurance ETF',
    name: 'iShares U.S. Insurance ETF',
    symbol: 'IAK',
  },
  {
    value: 'IAT',
    label: 'IAT,  iShares U.S. Regional Banks ETF',
    name: 'iShares U.S. Regional Banks ETF',
    symbol: 'IAT',
  },
  {
    value: 'IDHQ',
    label: 'IDHQ,  Invesco S&P International Developed Quality ETF',
    name: 'Invesco S&P International Developed Quality ETF',
    symbol: 'IDHQ',
  },
  {
    value: 'IEZ',
    label: 'IEZ,  iShares U.S. Oil Equipment & Services ETF',
    name: 'iShares U.S. Oil Equipment & Services ETF',
    symbol: 'IEZ',
  },
  {
    value: 'INCO',
    label: 'INCO,  Columbia India Consumer ETF',
    name: 'Columbia India Consumer ETF',
    symbol: 'INCO',
  },
  {
    value: 'INDL',
    label: 'INDL,  Direxion Daily MSCI India Bull 2X Shares',
    name: 'Direxion Daily MSCI India Bull 2X Shares',
    symbol: 'INDL',
  },
  {
    value: 'IOO',
    label: 'IOO,  iShares Global 100 ETF',
    name: 'iShares Global 100 ETF',
    symbol: 'IOO',
  },
  {
    value: 'IRMD',
    label: 'IRMD,  IRadimed Corporation',
    name: 'IRadimed Corporation',
    symbol: 'IRMD',
  },
  {
    value: 'IWL',
    label: 'IWL,  iShares Russell Top 200 ETF',
    name: 'iShares Russell Top 200 ETF',
    symbol: 'IWL',
  },
  {
    value: 'IZRL',
    label: 'IZRL,  ARK Israel Innovative Technology ETF',
    name: 'ARK Israel Innovative Technology ETF',
    symbol: 'IZRL',
  },
  {
    value: 'JJC',
    label: 'JJC,  iPath Series B Bloomberg Copper Subindex Total Return ETN',
    name: 'iPath Series B Bloomberg Copper Subindex Total Return ETN',
    symbol: 'JJC',
  },
  {
    value: 'JJG',
    label: 'JJG,  iPath Series B Bloomberg Grains Subindex Total Return ETN',
    name: 'iPath Series B Bloomberg Grains Subindex Total Return ETN',
    symbol: 'JJG',
  },
  {
    value: 'ILCB',
    label: 'ILCB,  iShares Morningstar U.S. Equity ETF',
    name: 'iShares Morningstar U.S. Equity ETF',
    symbol: 'ILCB',
  },
  {
    value: 'ILCG',
    label: 'ILCG,  iShares Morningstar Growth ETF',
    name: 'iShares Morningstar Growth ETF',
    symbol: 'ILCG',
  },
  {
    value: 'JPT',
    label: 'JPT,  Nuveen Preferred and Income Fund',
    name: 'Nuveen Preferred and Income Fund',
    symbol: 'JPT',
  },
  {
    value: 'JPXN',
    label: 'JPXN,  iShares JPX-Nikkei 400 ETF',
    name: 'iShares JPX-Nikkei 400 ETF',
    symbol: 'JPXN',
  },
  {
    value: 'KBWD',
    label: 'KBWD,  Invesco KBW High Dividend Yield Financial ETF',
    name: 'Invesco KBW High Dividend Yield Financial ETF',
    symbol: 'KBWD',
  },
  {
    value: 'KBWY',
    label: 'KBWY,  Invesco KBW Premium Yield Equity REIT ETF',
    name: 'Invesco KBW Premium Yield Equity REIT ETF',
    symbol: 'KBWY',
  },
  {
    value: 'KEN',
    label: 'KEN,  Kenon Holdings Ltd.',
    name: 'Kenon Holdings Ltd.',
    symbol: 'KEN',
  },
  {
    value: 'LAC',
    label: 'LAC,  Lithium Americas Corp.',
    name: 'Lithium Americas Corp.',
    symbol: 'LAC',
  },
  {
    value: 'LFVN',
    label: 'LFVN,  LifeVantage Corporation',
    name: 'LifeVantage Corporation',
    symbol: 'LFVN',
  },
  {
    value: 'LMB',
    label: 'LMB,  Limbach Holdings, Inc.',
    name: 'Limbach Holdings, Inc.',
    symbol: 'LMB',
  },
  {
    value: 'LOV',
    label: 'LOV,  Spark Networks SE',
    name: 'Spark Networks SE',
    symbol: 'LOV',
  },
  {
    value: 'LTPZ',
    label: 'LTPZ,  PIMCO 15+ Year U.S. TIPS Index Exchange-Traded Fund',
    name: 'PIMCO 15+ Year U.S. TIPS Index Exchange-Traded Fund',
    symbol: 'LTPZ',
  },
  {
    value: 'LYL',
    label: 'LYL,  Dragon Victory International Limited',
    name: 'Dragon Victory International Limited',
    symbol: 'LYL',
  },
  {
    value: 'MCB',
    label: 'MCB,  Metropolitan Bank Holding Corp.',
    name: 'Metropolitan Bank Holding Corp.',
    symbol: 'MCB',
  },
  {
    value: 'MGIC',
    label: 'MGIC,  Magic Software Enterprises Ltd.',
    name: 'Magic Software Enterprises Ltd.',
    symbol: 'MGIC',
  },
  {
    value: 'MHI',
    label: 'MHI,  Pioneer Municipal High Income Fund, Inc.',
    name: 'Pioneer Municipal High Income Fund, Inc.',
    symbol: 'MHI',
  },
  {
    value: 'MLN',
    label: 'MLN,  VanEck Long Muni ETF',
    name: 'VanEck Long Muni ETF',
    symbol: 'MLN',
  },
  {
    value: 'MLVF',
    label: 'MLVF,  Malvern Bancorp, Inc.',
    name: 'Malvern Bancorp, Inc.',
    symbol: 'MLVF',
  },
  {
    value: 'MTR',
    label: 'MTR,  Mesa Royalty Trust',
    name: 'Mesa Royalty Trust',
    symbol: 'MTR',
  },
  {
    value: 'MUA',
    label: 'MUA,  BlackRock MuniAssets Fund, Inc.',
    name: 'BlackRock MuniAssets Fund, Inc.',
    symbol: 'MUA',
  },
  {
    value: 'MVV',
    label: 'MVV,  ProShares Ultra MidCap400',
    name: 'ProShares Ultra MidCap400',
    symbol: 'MVV',
  },
  {
    value: 'MXI',
    label: 'MXI,  iShares Global Materials ETF',
    name: 'iShares Global Materials ETF',
    symbol: 'MXI',
  },
  {
    value: 'MYD',
    label: 'MYD,  BlackRock MuniYield Fund, Inc.',
    name: 'BlackRock MuniYield Fund, Inc.',
    symbol: 'MYD',
  },
  {
    value: 'NAII',
    label: 'NAII,  Natural Alternatives International, Inc.',
    name: 'Natural Alternatives International, Inc.',
    symbol: 'NAII',
  },
  {
    value: 'NGE',
    label: 'NGE,  Global X MSCI Nigeria ETF',
    name: 'Global X MSCI Nigeria ETF',
    symbol: 'NGE',
  },
  {
    value: 'NID',
    label: 'NID,  Nuveen Intermediate Duration Municipal Term Fund',
    name: 'Nuveen Intermediate Duration Municipal Term Fund',
    symbol: 'NID',
  },
  {
    value: 'NORW',
    label: 'NORW,  Global X MSCI Norway ETF',
    name: 'Global X MSCI Norway ETF',
    symbol: 'NORW',
  },
  {
    value: 'NVFY',
    label: 'NVFY,  Nova LifeStyle, Inc.',
    name: 'Nova LifeStyle, Inc.',
    symbol: 'NVFY',
  },
  {
    value: 'NXJ',
    label: 'NXJ,  Nuveen New Jersey Quality Municipal Income Fund',
    name: 'Nuveen New Jersey Quality Municipal Income Fund',
    symbol: 'NXJ',
  },
  {
    value: 'NXN',
    label: 'NXN,  Nuveen New York Select Tax-Free Income Portfolio',
    name: 'Nuveen New York Select Tax-Free Income Portfolio',
    symbol: 'NXN',
  },
  {
    value: 'OGEN',
    label: 'OGEN,  Oragenics, Inc.',
    name: 'Oragenics, Inc.',
    symbol: 'OGEN',
  },
  {
    value: 'OIIM',
    label: 'OIIM,  O2Micro International Limited',
    name: 'O2Micro International Limited',
    symbol: 'OIIM',
  },
  {
    value: 'OPNT',
    label: 'OPNT,  Opiant Pharmaceuticals, Inc.',
    name: 'Opiant Pharmaceuticals, Inc.',
    symbol: 'OPNT',
  },
  {
    value: 'PDN',
    label: 'PDN,  Invesco FTSE RAFI Developed Markets ex-U.S. Small-Mid ETF',
    name: 'Invesco FTSE RAFI Developed Markets ex-U.S. Small-Mid ETF',
    symbol: 'PDN',
  },
  {
    value: 'PERI',
    label: 'PERI,  Perion Network Ltd.',
    name: 'Perion Network Ltd.',
    symbol: 'PERI',
  },
  {
    value: 'PFIE',
    label: 'PFIE,  Profire Energy, Inc.',
    name: 'Profire Energy, Inc.',
    symbol: 'PFIE',
  },
  {
    value: 'PHX',
    label: 'PHX,  PHX Minerals Inc.',
    name: 'PHX Minerals Inc.',
    symbol: 'PHX',
  },
  {
    value: 'PKOH',
    label: 'PKOH,  Park-Ohio Holdings Corp.',
    name: 'Park-Ohio Holdings Corp.',
    symbol: 'PKOH',
  },
  {
    value: 'POLA',
    label: 'POLA,  Polar Power, Inc.',
    name: 'Polar Power, Inc.',
    symbol: 'POLA',
  },
  {
    value: 'PPIH',
    label: 'PPIH,  Perma-Pipe International Holdings, Inc.',
    name: 'Perma-Pipe International Holdings, Inc.',
    symbol: 'PPIH',
  },
  {
    value: 'PPSI',
    label: 'PPSI,  Pioneer Power Solutions, Inc.',
    name: 'Pioneer Power Solutions, Inc.',
    symbol: 'PPSI',
  },
  {
    value: 'PSCM',
    label: 'PSCM,  Invesco S&P SmallCap Materials ETF',
    name: 'Invesco S&P SmallCap Materials ETF',
    symbol: 'PSCM',
  },
  {
    value: 'PSJ',
    label: 'PSJ,  Invesco Dynamic Software ETF',
    name: 'Invesco Dynamic Software ETF',
    symbol: 'PSJ',
  },
  {
    value: 'PSK',
    label: 'PSK,  SPDR ICE Preferred Securities ETF',
    name: 'SPDR ICE Preferred Securities ETF',
    symbol: 'PSK',
  },
  {
    value: 'PST',
    label: 'PST,  ProShares UltraShort 7-10 Year Treasury',
    name: 'ProShares UltraShort 7-10 Year Treasury',
    symbol: 'PST',
  },
  {
    value: 'PTMC',
    label: 'PTMC,  Pacer Trendpilot US Mid Cap ETF',
    name: 'Pacer Trendpilot US Mid Cap ETF',
    symbol: 'PTMC',
  },
  {
    value: 'PUTW',
    label: 'PUTW,  WisdomTree PutWrite Strategy Fund',
    name: 'WisdomTree PutWrite Strategy Fund',
    symbol: 'PUTW',
  },
  {
    value: 'PXE',
    label: 'PXE,  Invesco Dynamic Energy Exploration & Production ETF',
    name: 'Invesco Dynamic Energy Exploration & Production ETF',
    symbol: 'PXE',
  },
  {
    value: 'PXF',
    label: 'PXF,  Invesco FTSE RAFI Developed Markets ex-U.S. ETF',
    name: 'Invesco FTSE RAFI Developed Markets ex-U.S. ETF',
    symbol: 'PXF',
  },
  {
    value: 'PXI',
    label: 'PXI,  Invesco DWA Energy Momentum ETF',
    name: 'Invesco DWA Energy Momentum ETF',
    symbol: 'PXI',
  },
  {
    value: 'QABA',
    label: 'QABA,  First Trust NASDAQ ABA Community Bank Index Fund',
    name: 'First Trust NASDAQ ABA Community Bank Index Fund',
    symbol: 'QABA',
  },
  {
    value: 'QDF',
    label: 'QDF,  FlexShares Quality Dividend Index Fund',
    name: 'FlexShares Quality Dividend Index Fund',
    symbol: 'QDF',
  },
  {
    value: 'QQQE',
    label: 'QQQE,  Direxion NASDAQ-100 Equal Weighted Index Shares',
    name: 'Direxion NASDAQ-100 Equal Weighted Index Shares',
    symbol: 'QQQE',
  },
  {
    value: 'RAVE',
    label: 'RAVE,  Rave Restaurant Group, Inc.',
    name: 'Rave Restaurant Group, Inc.',
    symbol: 'RAVE',
  },
  {
    value: 'REK',
    label: 'REK,  ProShares Short Real Estate',
    name: 'ProShares Short Real Estate',
    symbol: 'REK',
  },
  {
    value: 'RETO',
    label: 'RETO,  ReTo Eco-Solutions, Inc.',
    name: 'ReTo Eco-Solutions, Inc.',
    symbol: 'RETO',
  },
  {
    value: 'RFEM',
    label: 'RFEM,  First Trust RiverFront Dynamic Emerging Markets ETF',
    name: 'First Trust RiverFront Dynamic Emerging Markets ETF',
    symbol: 'RFEM',
  },
  {
    value: 'RFG',
    label: 'RFG,  Invesco S&P MidCap 400 Pure Growth ETF',
    name: 'Invesco S&P MidCap 400 Pure Growth ETF',
    symbol: 'RFG',
  },
  {
    value: 'RGLS',
    label: 'RGLS,  Regulus Therapeutics Inc.',
    name: 'Regulus Therapeutics Inc.',
    symbol: 'RGLS',
  },
  {
    value: 'RJN',
    label:
      'RJN,  ELEMENTS Linked to the Rogers International Commodity Index - Energy Total Return',
    name: 'ELEMENTS Linked to the Rogers International Commodity Index - Energy Total Return',
    symbol: 'RJN',
  },
  {
    value: 'RLY',
    label: 'RLY,  SPDR SSgA Multi-Asset Real Return ETF',
    name: 'SPDR SSgA Multi-Asset Real Return ETF',
    symbol: 'RLY',
  },
  {
    value: 'RVSB',
    label: 'RVSB,  Riverview Bancorp, Inc.',
    name: 'Riverview Bancorp, Inc.',
    symbol: 'RVSB',
  },
  {
    value: 'RZB',
    label: 'RZB,  Reinsurance Group of America, Incorporated',
    name: 'Reinsurance Group of America, Incorporated',
    symbol: 'RZB',
  },
  {
    value: 'SACH',
    label: 'SACH,  Sachem Capital Corp.',
    name: 'Sachem Capital Corp.',
    symbol: 'SACH',
  },
  {
    value: 'SANW',
    label: 'SANW,  S&W Seed Company',
    name: 'S&W Seed Company',
    symbol: 'SANW',
  },
  {
    value: 'SGBX',
    label: 'SGBX,  SG Blocks, Inc.',
    name: 'SG Blocks, Inc.',
    symbol: 'SGBX',
  },
  {
    value: 'SILJ',
    label: 'SILJ,  ETFMG Prime Junior Silver Miners ETF',
    name: 'ETFMG Prime Junior Silver Miners ETF',
    symbol: 'SILJ',
  },
  {
    value: 'SKF',
    label: 'SKF,  ProShares UltraShort Financials',
    name: 'ProShares UltraShort Financials',
    symbol: 'SKF',
  },
  {
    value: 'SKYY',
    label: 'SKYY,  First Trust Cloud Computing ETF',
    name: 'First Trust Cloud Computing ETF',
    symbol: 'SKYY',
  },
  {
    value: 'SLS',
    label: 'SLS,  SELLAS Life Sciences Group, Inc.',
    name: 'SELLAS Life Sciences Group, Inc.',
    symbol: 'SLS',
  },
  {
    value: 'SMB',
    label: 'SMB,  VanEck Short Muni ETF',
    name: 'VanEck Short Muni ETF',
    symbol: 'SMB',
  },
  {
    value: 'SMIT',
    label: 'SMIT,  Schmitt Industries, Inc.',
    name: 'Schmitt Industries, Inc.',
    symbol: 'SMIT',
  },
  {
    value: 'SMMF',
    label: 'SMMF,  Summit Financial Group, Inc.',
    name: 'Summit Financial Group, Inc.',
    symbol: 'SMMF',
  },
  {
    value: 'SOCL',
    label: 'SOCL,  Global X Social Media ETF',
    name: 'Global X Social Media ETF',
    symbol: 'SOCL',
  },
  {
    value: 'SPLP',
    label: 'SPLP,  Steel Partners Holdings L.P.',
    name: 'Steel Partners Holdings L.P.',
    symbol: 'SPLP',
  },
  {
    value: 'SRLP',
    label: 'SRLP,  Sprague Resources LP',
    name: 'Sprague Resources LP',
    symbol: 'SRLP',
  },
  {
    value: 'STAF',
    label: 'STAF,  Staffing 360 Solutions, Inc.',
    name: 'Staffing 360 Solutions, Inc.',
    symbol: 'STAF',
  },
  {
    value: 'STPZ',
    label: 'STPZ,  PIMCO 1-5 Year U.S. TIPS Index Exchange-Traded Fund',
    name: 'PIMCO 1-5 Year U.S. TIPS Index Exchange-Traded Fund',
    symbol: 'STPZ',
  },
  {
    value: 'STRM',
    label: 'STRM,  Streamline Health Solutions, Inc.',
    name: 'Streamline Health Solutions, Inc.',
    symbol: 'STRM',
  },
  {
    value: 'STRT',
    label: 'STRT,  Strattec Security Corporation',
    name: 'Strattec Security Corporation',
    symbol: 'STRT',
  },
  {
    value: 'TANNI',
    label: 'TANNI,  TravelCenters of America LLC SR NT 8.25%2028',
    name: 'TravelCenters of America LLC SR NT 8.25%2028',
    symbol: 'TANNI',
  },
  {
    value: 'TBB',
    label: 'TBB,  AT&T Inc. 5.35% GLB NTS 66',
    name: 'AT&T Inc. 5.35% GLB NTS 66',
    symbol: 'TBB',
  },
  {
    value: 'TDF',
    label: 'TDF,  Templeton Dragon Fund, Inc.',
    name: 'Templeton Dragon Fund, Inc.',
    symbol: 'TDF',
  },
  {
    value: 'TENX',
    label: 'TENX,  Tenax Therapeutics, Inc.',
    name: 'Tenax Therapeutics, Inc.',
    symbol: 'TENX',
  },
  {
    value: 'TESS',
    label: 'TESS,  TESSCO Technologies Incorporated',
    name: 'TESSCO Technologies Incorporated',
    symbol: 'TESS',
  },
  {
    value: 'THW',
    label: 'THW,  Tekla World Healthcare Fund',
    name: 'Tekla World Healthcare Fund',
    symbol: 'THW',
  },
  {
    value: 'TILT',
    label: 'TILT,  FlexShares Morningstar US Market Factors Tilt Index Fund',
    name: 'FlexShares Morningstar US Market Factors Tilt Index Fund',
    symbol: 'TILT',
  },
  {
    value: 'TNXP',
    label: 'TNXP,  Tonix Pharmaceuticals Holding Corp.',
    name: 'Tonix Pharmaceuticals Holding Corp.',
    symbol: 'TNXP',
  },
  {
    value: 'TPL',
    label: 'TPL,  Texas Pacific Land Corporation',
    name: 'Texas Pacific Land Corporation',
    symbol: 'TPL',
  },
  {
    value: 'TPOR',
    label: 'TPOR,  Direxion Daily Transportation Bull 3X Shares',
    name: 'Direxion Daily Transportation Bull 3X Shares',
    symbol: 'TPOR',
  },
  {
    value: 'TRIB',
    label: 'TRIB,  Trinity Biotech plc',
    name: 'Trinity Biotech plc',
    symbol: 'TRIB',
  },
  {
    value: 'TTP',
    label: 'TTP,  Tortoise Pipeline & Energy Fund, Inc.',
    name: 'Tortoise Pipeline & Energy Fund, Inc.',
    symbol: 'TTP',
  },
  {
    value: 'UFI',
    label: 'UFI,  Unifi, Inc.',
    name: 'Unifi, Inc.',
    symbol: 'UFI',
  },
  {
    value: 'URTY',
    label: 'URTY,  ProShares UltraPro Russell2000',
    name: 'ProShares UltraPro Russell2000',
    symbol: 'URTY',
  },
  {
    value: 'USAU',
    label: 'USAU,  U.S. Gold Corp.',
    name: 'U.S. Gold Corp.',
    symbol: 'USAU',
  },
  {
    value: 'UUU',
    label: 'UUU,  Universal Security Instruments, Inc.',
    name: 'Universal Security Instruments, Inc.',
    symbol: 'UUU',
  },
  {
    value: 'VAMO',
    label: 'VAMO,  Cambria Value and Momentum ETF',
    name: 'Cambria Value and Momentum ETF',
    symbol: 'VAMO',
  },
  {
    value: 'VAW',
    label: 'VAW,  Vanguard Materials Index Fund',
    name: 'Vanguard Materials Index Fund',
    symbol: 'VAW',
  },
  {
    value: 'VGI',
    label: 'VGI,  Virtus Global Multi-Sector Income Fund',
    name: 'Virtus Global Multi-Sector Income Fund',
    symbol: 'VGI',
  },
  {
    value: 'VIGI',
    label: 'VIGI,  Vanguard International Dividend Appreciation Index Fund',
    name: 'Vanguard International Dividend Appreciation Index Fund',
    symbol: 'VIGI',
  },
  {
    value: 'VIOG',
    label: 'VIOG,  Vanguard S&P Small-Cap 600 Growth Index Fund',
    name: 'Vanguard S&P Small-Cap 600 Growth Index Fund',
    symbol: 'VIOG',
  },
  {
    value: 'VJET',
    label: 'VJET,  voxeljet AG',
    name: 'voxeljet AG',
    symbol: 'VJET',
  },
  {
    value: 'VPV',
    label: 'VPV,  Invesco Pennsylvania Value Municipal Income Trust',
    name: 'Invesco Pennsylvania Value Municipal Income Trust',
    symbol: 'VPV',
  },
  {
    value: 'VVPR',
    label: 'VVPR,  VivoPower International PLC',
    name: 'VivoPower International PLC',
    symbol: 'VVPR',
  },
  {
    value: 'WDIV',
    label: 'WDIV,  SPDR S&P Global Dividend ETF',
    name: 'SPDR S&P Global Dividend ETF',
    symbol: 'WDIV',
  },
  {
    value: 'WEAT',
    label: 'WEAT,  Teucrium Wheat Fund',
    name: 'Teucrium Wheat Fund',
    symbol: 'WEAT',
  },
  {
    value: 'WHF',
    label: 'WHF,  WhiteHorse Finance, Inc.',
    name: 'WhiteHorse Finance, Inc.',
    symbol: 'WHF',
  },
  {
    value: 'WHLRP',
    label: 'WHLRP,  Wheeler Real Estate Investment Trust, Inc.',
    name: 'Wheeler Real Estate Investment Trust, Inc.',
    symbol: 'WHLRP',
  },
  {
    value: 'WYY',
    label: 'WYY,  WidePoint Corporation',
    name: 'WidePoint Corporation',
    symbol: 'WYY',
  },
  {
    value: 'XENE',
    label: 'XENE,  Xenon Pharmaceuticals Inc.',
    name: 'Xenon Pharmaceuticals Inc.',
    symbol: 'XENE',
  },
  {
    value: 'XHS',
    label: 'XHS,  SPDR S&P Health Care Services ETF',
    name: 'SPDR S&P Health Care Services ETF',
    symbol: 'XHS',
  },
  {
    value: 'XNTK',
    label: 'XNTK,  SPDR NYSE Technology ETF',
    name: 'SPDR NYSE Technology ETF',
    symbol: 'XNTK',
  },
  {
    value: 'XSOE',
    label: 'XSOE,  WisdomTree Emerging Markets ex-State-Owned Enterprises Fund',
    name: 'WisdomTree Emerging Markets ex-State-Owned Enterprises Fund',
    symbol: 'XSOE',
  },
  {
    value: 'XSW',
    label: 'XSW,  SPDR S&P Software & Services ETF',
    name: 'SPDR S&P Software & Services ETF',
    symbol: 'XSW',
  },
  {
    value: 'XTNT',
    label: 'XTNT,  Xtant Medical Holdings, Inc.',
    name: 'Xtant Medical Holdings, Inc.',
    symbol: 'XTNT',
  },
  {
    value: 'YCL',
    label: 'YCL,  ProShares Ultra Yen',
    name: 'ProShares Ultra Yen',
    symbol: 'YCL',
  },
  {
    value: 'YECO',
    label: 'YECO,  EV Biologics, Inc.',
    name: 'EV Biologics, Inc.',
    symbol: 'YECO',
  },
  {
    value: 'YGYI',
    label: 'YGYI,  Youngevity International, Inc.',
    name: 'Youngevity International, Inc.',
    symbol: 'YGYI',
  },
  {
    value: 'ZDGE',
    label: 'ZDGE,  Zedge, Inc.',
    name: 'Zedge, Inc.',
    symbol: 'ZDGE',
  },
  {
    value: 'ZNH',
    label: 'ZNH,  China Southern Airlines Company Limited',
    name: 'China Southern Airlines Company Limited',
    symbol: 'ZNH',
  },
  {
    value: 'MTLS',
    label: 'MTLS,  Materialise NV',
    name: 'Materialise NV',
    symbol: 'MTLS',
  },
  {
    value: 'RZG',
    label: 'RZG,  Invesco S&P SmallCap 600 Pure Growth ETF',
    name: 'Invesco S&P SmallCap 600 Pure Growth ETF',
    symbol: 'RZG',
  },
  {
    value: 'JJN',
    label: 'JJN,  iPath Series B Bloomberg Nickel Subindex Total Return ETN',
    name: 'iPath Series B Bloomberg Nickel Subindex Total Return ETN',
    symbol: 'JJN',
  },
  {
    value: 'CSSE',
    label: 'CSSE,  Chicken Soup for the Soul Entertainment, Inc.',
    name: 'Chicken Soup for the Soul Entertainment, Inc.',
    symbol: 'CSSE',
  },
  {
    value: 'MSEX',
    label: 'MSEX,  Middlesex Water Company',
    name: 'Middlesex Water Company',
    symbol: 'MSEX',
  },
  {
    value: 'EWGS',
    label: 'EWGS,  iShares MSCI Germany Small-Cap ETF',
    name: 'iShares MSCI Germany Small-Cap ETF',
    symbol: 'EWGS',
  },
  {
    value: 'RFEU',
    label: 'RFEU,  First Trust RiverFront Dynamic Europe ETF',
    name: 'First Trust RiverFront Dynamic Europe ETF',
    symbol: 'RFEU',
  },
  {
    value: 'ASHS',
    label: 'ASHS,  Xtrackers Harvest CSI 500 China-A Shares Small Cap ETF',
    name: 'Xtrackers Harvest CSI 500 China-A Shares Small Cap ETF',
    symbol: 'ASHS',
  },
  {
    value: 'DEEF',
    label: 'DEEF,  Xtrackers FTSE Developed ex US Multifactor ETF',
    name: 'Xtrackers FTSE Developed ex US Multifactor ETF',
    symbol: 'DEEF',
  },
  {
    value: 'JPUS',
    label: 'JPUS,  JPMorgan Diversified Return U.S. Equity ETF',
    name: 'JPMorgan Diversified Return U.S. Equity ETF',
    symbol: 'JPUS',
  },
  {
    value: 'KOIN',
    label: 'KOIN,  Capital Link Global Fintech Leaders ETF',
    name: 'Capital Link Global Fintech Leaders ETF',
    symbol: 'KOIN',
  },
  {
    value: 'PED',
    label: 'PED,  PEDEVCO Corp.',
    name: 'PEDEVCO Corp.',
    symbol: 'PED',
  },
  {
    value: 'SEB',
    label: 'SEB,  Seaboard Corporation',
    name: 'Seaboard Corporation',
    symbol: 'SEB',
  },
  {
    value: 'EMGF',
    label: 'EMGF,  iShares MSCI Emerging Markets Multifactor ETF',
    name: 'iShares MSCI Emerging Markets Multifactor ETF',
    symbol: 'EMGF',
  },
  {
    value: 'FVAL',
    label: 'FVAL,  Fidelity Value Factor ETF',
    name: 'Fidelity Value Factor ETF',
    symbol: 'FVAL',
  },
  {
    value: 'SFST',
    label: 'SFST,  Southern First Bancshares, Inc.',
    name: 'Southern First Bancshares, Inc.',
    symbol: 'SFST',
  },
  {
    value: 'DTH',
    label: 'DTH,  WisdomTree International High Dividend Fund',
    name: 'WisdomTree International High Dividend Fund',
    symbol: 'DTH',
  },
  {
    value: 'KXI',
    label: 'KXI,  iShares Global Consumer Staples ETF',
    name: 'iShares Global Consumer Staples ETF',
    symbol: 'KXI',
  },
  {
    value: 'PALL',
    label: 'PALL,  abrdn Physical Palladium Shares ETF',
    name: 'abrdn Physical Palladium Shares ETF',
    symbol: 'PALL',
  },
  {
    value: 'PEJ',
    label: 'PEJ,  Invesco Dynamic Leisure and Entertainment ETF',
    name: 'Invesco Dynamic Leisure and Entertainment ETF',
    symbol: 'PEJ',
  },
  {
    value: 'RA',
    label: 'RA,  Brookfield Real Assets Income Fund Inc.',
    name: 'Brookfield Real Assets Income Fund Inc.',
    symbol: 'RA',
  },
  {
    value: 'DLN',
    label: 'DLN,  WisdomTree U.S. LargeCap Dividend Fund',
    name: 'WisdomTree U.S. LargeCap Dividend Fund',
    symbol: 'DLN',
  },
  {
    value: 'GCC',
    label: 'GCC,  WisdomTree Enhanced Commodity Strategy Fund',
    name: 'WisdomTree Enhanced Commodity Strategy Fund',
    symbol: 'GCC',
  },
  {
    value: 'LCNB',
    label: 'LCNB,  LCNB Corp.',
    name: 'LCNB Corp.',
    symbol: 'LCNB',
  },
  {
    value: 'PBS',
    label: 'PBS,  Invesco Dynamic Media ETF',
    name: 'Invesco Dynamic Media ETF',
    symbol: 'PBS',
  },
  {
    value: 'SSG',
    label: 'SSG,  ProShares UltraShort Semiconductors',
    name: 'ProShares UltraShort Semiconductors',
    symbol: 'SSG',
  },
  {
    value: 'PICK',
    label: 'PICK,  iShares MSCI Global Metals & Mining Producers ETF',
    name: 'iShares MSCI Global Metals & Mining Producers ETF',
    symbol: 'PICK',
  },
  {
    value: 'RTH',
    label: 'RTH,  VanEck Retail ETF',
    name: 'VanEck Retail ETF',
    symbol: 'RTH',
  },
  {
    value: 'XLG',
    label: 'XLG,  Invesco S&P 500 Top 50 ETF',
    name: 'Invesco S&P 500 Top 50 ETF',
    symbol: 'XLG',
  },
  {
    value: 'FRI',
    label: 'FRI,  First Trust S&P REIT Index Fund',
    name: 'First Trust S&P REIT Index Fund',
    symbol: 'FRI',
  },
  {
    value: 'DPST',
    label: 'DPST,  Direxion Daily Regional Banks Bull 3X Shares',
    name: 'Direxion Daily Regional Banks Bull 3X Shares',
    symbol: 'DPST',
  },
  {
    value: 'PZG',
    label: 'PZG,  Paramount Gold Nevada Corp.',
    name: 'Paramount Gold Nevada Corp.',
    symbol: 'PZG',
  },
  {
    value: 'ROAM',
    label: 'ROAM,  Hartford Multifactor Emerging Markets ETF',
    name: 'Hartford Multifactor Emerging Markets ETF',
    symbol: 'ROAM',
  },
  {
    value: 'UG',
    label: 'UG,  United-Guardian, Inc.',
    name: 'United-Guardian, Inc.',
    symbol: 'UG',
  },
  {
    value: 'XTN',
    label: 'XTN,  SPDR S&P Transportation ETF',
    name: 'SPDR S&P Transportation ETF',
    symbol: 'XTN',
  },
  {
    value: 'GENY',
    label: 'GENY,  Principal Millennial Global Growth ETF',
    name: 'Principal Millennial Global Growth ETF',
    symbol: 'GENY',
  },
  {
    value: 'RIGS',
    label: 'RIGS,  RiverFront Strategic Income Fund',
    name: 'RiverFront Strategic Income Fund',
    symbol: 'RIGS',
  },
  {
    value: 'SMDD',
    label: 'SMDD,  ProShares UltraPro Short MidCap400',
    name: 'ProShares UltraPro Short MidCap400',
    symbol: 'SMDD',
  },
  {
    value: 'WLFC',
    label: 'WLFC,  Willis Lease Finance Corporation',
    name: 'Willis Lease Finance Corporation',
    symbol: 'WLFC',
  },
  {
    value: 'ACER',
    label: 'ACER,  Acer Therapeutics Inc.',
    name: 'Acer Therapeutics Inc.',
    symbol: 'ACER',
  },
  {
    value: 'EVBN',
    label: 'EVBN,  Evans Bancorp, Inc.',
    name: 'Evans Bancorp, Inc.',
    symbol: 'EVBN',
  },
  {
    value: 'FEUZ',
    label: 'FEUZ,  First Trust Eurozone AlphaDEX ETF',
    name: 'First Trust Eurozone AlphaDEX ETF',
    symbol: 'FEUZ',
  },
  {
    value: 'EDV',
    label: 'EDV,  Vanguard Extended Duration Treasury Index Fund',
    name: 'Vanguard Extended Duration Treasury Index Fund',
    symbol: 'EDV',
  },
  {
    value: 'FALN',
    label: 'FALN,  iShares Fallen Angels USD Bond ETF',
    name: 'iShares Fallen Angels USD Bond ETF',
    symbol: 'FALN',
  },
  {
    value: 'GCBC',
    label: 'GCBC,  Greene County Bancorp, Inc.',
    name: 'Greene County Bancorp, Inc.',
    symbol: 'GCBC',
  },
  {
    value: 'SOHOB',
    label: 'SOHOB,  Sotherly Hotels Inc.',
    name: 'Sotherly Hotels Inc.',
    symbol: 'SOHOB',
  },
  {
    value: 'PLPC',
    label: 'PLPC,  Preformed Line Products Company',
    name: 'Preformed Line Products Company',
    symbol: 'PLPC',
  },
  {
    value: 'FBIZ',
    label: 'FBIZ,  First Business Financial Services, Inc.',
    name: 'First Business Financial Services, Inc.',
    symbol: 'FBIZ',
  },
  {
    value: 'CLIR',
    label: 'CLIR,  ClearSign Technologies Corporation',
    name: 'ClearSign Technologies Corporation',
    symbol: 'CLIR',
  },
  {
    value: 'AMSWA',
    label: 'AMSWA,  American Software, Inc.',
    name: 'American Software, Inc.',
    symbol: 'AMSWA',
  },
  {
    value: 'JPEM',
    label: 'JPEM,  JPMorgan Diversified Return Emerging Markets Equity ETF',
    name: 'JPMorgan Diversified Return Emerging Markets Equity ETF',
    symbol: 'JPEM',
  },
  {
    value: 'RWK',
    label: 'RWK,  Invesco S&P MidCap 400 Revenue ETF',
    name: 'Invesco S&P MidCap 400 Revenue ETF',
    symbol: 'RWK',
  },
  {
    value: 'RZA',
    label: 'RZA,  Reinsurance Group of America, Incorporated',
    name: 'Reinsurance Group of America, Incorporated',
    symbol: 'RZA',
  },
  {
    value: 'CRAK',
    label: 'CRAK,  VanEck Oil Refiners ETF',
    name: 'VanEck Oil Refiners ETF',
    symbol: 'CRAK',
  },
  {
    value: 'MIN',
    label: 'MIN,  MFS Intermediate Income Trust',
    name: 'MFS Intermediate Income Trust',
    symbol: 'MIN',
  },
  {
    value: 'CORP',
    label:
      'CORP,  PIMCO Investment Grade Corporate Bond Index Exchange-Traded Fund',
    name: 'PIMCO Investment Grade Corporate Bond Index Exchange-Traded Fund',
    symbol: 'CORP',
  },
  {
    value: 'CXH',
    label: 'CXH,  MFS Investment Grade Municipal Trust',
    name: 'MFS Investment Grade Municipal Trust',
    symbol: 'CXH',
  },
  {
    value: 'FVC',
    label: 'FVC,  First Trust Dorsey Wright Dynamic Focus 5 ETF',
    name: 'First Trust Dorsey Wright Dynamic Focus 5 ETF',
    symbol: 'FVC',
  },
  {
    value: 'ROOF',
    label: 'ROOF,  IQ CBRE NextGen Real Estate ETF',
    name: 'IQ CBRE NextGen Real Estate ETF',
    symbol: 'ROOF',
  },
  {
    value: 'MIND',
    label: 'MIND,  MIND Technology, Inc.',
    name: 'MIND Technology, Inc.',
    symbol: 'MIND',
  },
  {
    value: 'OUSA',
    label: "OUSA,  ALPS O'Shares U.S. Quality Dividend ETF Shares",
    name: "ALPS O'Shares U.S. Quality Dividend ETF Shares",
    symbol: 'OUSA',
  },
  {
    value: 'RUSHB',
    label: 'RUSHB,  Rush Enterprises, Inc.',
    name: 'Rush Enterprises, Inc.',
    symbol: 'RUSHB',
  },
  {
    value: 'CMCT',
    label: 'CMCT,  Creative Media & Community Trust Corporation',
    name: 'Creative Media & Community Trust Corporation',
    symbol: 'CMCT',
  },
  {
    value: 'JJU',
    label: 'JJU,  iPath Series B Bloomberg Aluminum Subindex Total Return ETN',
    name: 'iPath Series B Bloomberg Aluminum Subindex Total Return ETN',
    symbol: 'JJU',
  },
  {
    value: 'FYT',
    label: 'FYT,  First Trust Small Cap Value AlphaDEX Fund',
    name: 'First Trust Small Cap Value AlphaDEX Fund',
    symbol: 'FYT',
  },
  {
    value: 'PBJ',
    label: 'PBJ,  Invesco Dynamic Food & Beverage ETF',
    name: 'Invesco Dynamic Food & Beverage ETF',
    symbol: 'PBJ',
  },
  {
    value: 'CXSE',
    label: 'CXSE,  WisdomTree China ex-State-Owned Enterprises Fund',
    name: 'WisdomTree China ex-State-Owned Enterprises Fund',
    symbol: 'CXSE',
  },
  {
    value: 'LWAY',
    label: 'LWAY,  Lifeway Foods, Inc.',
    name: 'Lifeway Foods, Inc.',
    symbol: 'LWAY',
  },
  {
    value: 'AGZD',
    label: 'AGZD,  WisdomTree Interest Rate Hedged U.S. Aggregate Bond Fund',
    name: 'WisdomTree Interest Rate Hedged U.S. Aggregate Bond Fund',
    symbol: 'AGZD',
  },
  {
    value: 'RWL',
    label: 'RWL,  Invesco S&P 500 Revenue ETF',
    name: 'Invesco S&P 500 Revenue ETF',
    symbol: 'RWL',
  },
  {
    value: 'VTWV',
    label: 'VTWV,  Vanguard Russell 2000 Value Index Fund',
    name: 'Vanguard Russell 2000 Value Index Fund',
    symbol: 'VTWV',
  },
  {
    value: 'FCCO',
    label: 'FCCO,  First Community Corporation',
    name: 'First Community Corporation',
    symbol: 'FCCO',
  },
  {
    value: 'WTFCM',
    label: 'WTFCM,  Wintrust Financial Corporation',
    name: 'Wintrust Financial Corporation',
    symbol: 'WTFCM',
  },
  {
    value: 'FLEE',
    label: 'FLEE,  Franklin FTSE Europe ETF',
    name: 'Franklin FTSE Europe ETF',
    symbol: 'FLEE',
  },
  {
    value: 'OFED',
    label: 'OFED,  Oconee Federal Financial Corp.',
    name: 'Oconee Federal Financial Corp.',
    symbol: 'OFED',
  },
  {
    value: 'EES',
    label: 'EES,  WisdomTree U.S. SmallCap Earnings Fund',
    name: 'WisdomTree U.S. SmallCap Earnings Fund',
    symbol: 'EES',
  },
  {
    value: 'FIEE',
    label: 'FIEE,  UBS AG FI Enhanced Europe 50 ETN',
    name: 'UBS AG FI Enhanced Europe 50 ETN',
    symbol: 'FIEE',
  },
  {
    value: 'NWLI',
    label: 'NWLI,  National Western Life Group, Inc.',
    name: 'National Western Life Group, Inc.',
    symbol: 'NWLI',
  },
  {
    value: 'CATC',
    label: 'CATC,  Cambridge Bancorp',
    name: 'Cambridge Bancorp',
    symbol: 'CATC',
  },
  {
    value: 'EDOG',
    label: 'EDOG,  ALPS Emerging Sector Dividend Dogs ETF',
    name: 'ALPS Emerging Sector Dividend Dogs ETF',
    symbol: 'EDOG',
  },
  {
    value: 'EWMC',
    label: 'EWMC,  Invesco S&P MidCap 400 Equal Weight ETF',
    name: 'Invesco S&P MidCap 400 Equal Weight ETF',
    symbol: 'EWMC',
  },
  {
    value: 'KORU',
    label: 'KORU,  Direxion Daily South Korea Bull 3X Shares',
    name: 'Direxion Daily South Korea Bull 3X Shares',
    symbol: 'KORU',
  },
  {
    value: 'SAFT',
    label: 'SAFT,  Safety Insurance Group, Inc.',
    name: 'Safety Insurance Group, Inc.',
    symbol: 'SAFT',
  },
  {
    value: 'PILL',
    label: 'PILL,  Direxion Daily Pharmaceutical & Medical Bull 3X Shares ETF',
    name: 'Direxion Daily Pharmaceutical & Medical Bull 3X Shares ETF',
    symbol: 'PILL',
  },
  {
    value: 'SGRP',
    label: 'SGRP,  SPAR Group, Inc.',
    name: 'SPAR Group, Inc.',
    symbol: 'SGRP',
  },
  {
    value: 'TPVG',
    label: 'TPVG,  TriplePoint Venture Growth BDC Corp.',
    name: 'TriplePoint Venture Growth BDC Corp.',
    symbol: 'TPVG',
  },
  {
    value: 'ICAD',
    label: 'ICAD,  iCAD, Inc.',
    name: 'iCAD, Inc.',
    symbol: 'ICAD',
  },
  {
    value: 'RXI',
    label: 'RXI,  iShares Global Consumer Discretionary ETF',
    name: 'iShares Global Consumer Discretionary ETF',
    symbol: 'RXI',
  },
  {
    value: 'DEW',
    label: 'DEW,  WisdomTree Global High Dividend Fund',
    name: 'WisdomTree Global High Dividend Fund',
    symbol: 'DEW',
  },
  {
    value: 'DVYE',
    label: 'DVYE,  iShares Emerging Markets Dividend ETF',
    name: 'iShares Emerging Markets Dividend ETF',
    symbol: 'DVYE',
  },
  {
    value: 'MICT',
    label: 'MICT,  MICT, Inc.',
    name: 'MICT, Inc.',
    symbol: 'MICT',
  },
  {
    value: 'NRIM',
    label: 'NRIM,  Northrim BanCorp, Inc.',
    name: 'Northrim BanCorp, Inc.',
    symbol: 'NRIM',
  },
  {
    value: 'TAIL',
    label: 'TAIL,  Cambria Tail Risk ETF',
    name: 'Cambria Tail Risk ETF',
    symbol: 'TAIL',
  },
  {
    value: 'EBMT',
    label: 'EBMT,  Eagle Bancorp Montana, Inc.',
    name: 'Eagle Bancorp Montana, Inc.',
    symbol: 'EBMT',
  },
  {
    value: 'BFS',
    label: 'BFS,  Saul Centers, Inc.',
    name: 'Saul Centers, Inc.',
    symbol: 'BFS',
  },
  {
    value: 'DEF',
    label: 'DEF,  Invesco Defensive Equity ETF',
    name: 'Invesco Defensive Equity ETF',
    symbol: 'DEF',
  },
  {
    value: 'DGL',
    label: 'DGL,  Invesco DB Gold Fund',
    name: 'Invesco DB Gold Fund',
    symbol: 'DGL',
  },
  {
    value: 'EMFM',
    label: 'EMFM,  Global X Next Emerging & Frontier ETF',
    name: 'Global X Next Emerging & Frontier ETF',
    symbol: 'EMFM',
  },
  {
    value: 'FGBI',
    label: 'FGBI,  First Guaranty Bancshares, Inc.',
    name: 'First Guaranty Bancshares, Inc.',
    symbol: 'FGBI',
  },
  {
    value: 'GRVY',
    label: 'GRVY,  Gravity Co., Ltd.',
    name: 'Gravity Co., Ltd.',
    symbol: 'GRVY',
  },
  {
    value: 'HIFS',
    label: 'HIFS,  Hingham Institution for Savings',
    name: 'Hingham Institution for Savings',
    symbol: 'HIFS',
  },
  {
    value: 'ITIC',
    label: 'ITIC,  Investors Title Company',
    name: 'Investors Title Company',
    symbol: 'ITIC',
  },
  {
    value: 'MLAB',
    label: 'MLAB,  Mesa Laboratories, Inc.',
    name: 'Mesa Laboratories, Inc.',
    symbol: 'MLAB',
  },
  {
    value: 'PEBK',
    label: 'PEBK,  Peoples Bancorp of North Carolina, Inc.',
    name: 'Peoples Bancorp of North Carolina, Inc.',
    symbol: 'PEBK',
  },
  {
    value: 'PVBC',
    label: 'PVBC,  Provident Bancorp, Inc.',
    name: 'Provident Bancorp, Inc.',
    symbol: 'PVBC',
  },
  {
    value: 'PYT',
    label: 'PYT,  PPLUS Trust Series GSC-2 GSC 2 CT FL RT',
    name: 'PPLUS Trust Series GSC-2 GSC 2 CT FL RT',
    symbol: 'PYT',
  },
  {
    value: 'SBFG',
    label: 'SBFG,  SB Financial Group, Inc.',
    name: 'SB Financial Group, Inc.',
    symbol: 'SBFG',
  },
  {
    value: 'SGMA',
    label: 'SGMA,  SigmaTron International, Inc.',
    name: 'SigmaTron International, Inc.',
    symbol: 'SGMA',
  },
  {
    value: 'SMMT',
    label: 'SMMT,  Summit Therapeutics Inc.',
    name: 'Summit Therapeutics Inc.',
    symbol: 'SMMT',
  },
  {
    value: 'TAC',
    label: 'TAC,  TransAlta Corporation',
    name: 'TransAlta Corporation',
    symbol: 'TAC',
  },
  {
    value: 'TCI',
    label: 'TCI,  Transcontinental Realty Investors, Inc.',
    name: 'Transcontinental Realty Investors, Inc.',
    symbol: 'TCI',
  },
  {
    value: 'AADR',
    label: 'AADR,  AdvisorShares Dorsey Wright ADR ETF',
    name: 'AdvisorShares Dorsey Wright ADR ETF',
    symbol: 'AADR',
  },
  {
    value: 'AAMC',
    label: 'AAMC,  Altisource Asset Management Corporation',
    name: 'Altisource Asset Management Corporation',
    symbol: 'AAMC',
  },
  {
    value: 'AAME',
    label: 'AAME,  Atlantic American Corporation',
    name: 'Atlantic American Corporation',
    symbol: 'AAME',
  },
  {
    value: 'AAU',
    label: 'AAU,  Almaden Minerals Ltd.',
    name: 'Almaden Minerals Ltd.',
    symbol: 'AAU',
  },
  {
    value: 'ACGLO',
    label: 'ACGLO,  Arch Capital Group Ltd.',
    name: 'Arch Capital Group Ltd.',
    symbol: 'ACGLO',
  },
  {
    value: 'ACNB',
    label: 'ACNB,  ACNB Corporation',
    name: 'ACNB Corporation',
    symbol: 'ACNB',
  },
  {
    value: 'ACSI',
    label: 'ACSI,  American Customer Satisfaction ETF',
    name: 'American Customer Satisfaction ETF',
    symbol: 'ACSI',
  },
  {
    value: 'ACU',
    label: 'ACU,  Acme United Corporation',
    name: 'Acme United Corporation',
    symbol: 'ACU',
  },
  {
    value: 'ACV',
    label: 'ACV,  Virtus Diversified Income & Convertible Fund',
    name: 'Virtus Diversified Income & Convertible Fund',
    symbol: 'ACV',
  },
  {
    value: 'ADRA',
    label: 'ADRA,  Adara Acquisition Corp.',
    name: 'Adara Acquisition Corp.',
    symbol: 'ADRA',
  },
  {
    value: 'AEY',
    label: 'AEY,  ADDvantage Technologies Group, Inc.',
    name: 'ADDvantage Technologies Group, Inc.',
    symbol: 'AEY',
  },
  {
    value: 'AEZS',
    label: 'AEZS,  Aeterna Zentaris Inc.',
    name: 'Aeterna Zentaris Inc.',
    symbol: 'AEZS',
  },
  {
    value: 'AFK',
    label: 'AFK,  VanEck Africa Index ETF',
    name: 'VanEck Africa Index ETF',
    symbol: 'AFK',
  },
  {
    value: 'AFTY',
    label: 'AFTY,  Pacer CSOP FTSE China A50 ETF',
    name: 'Pacer CSOP FTSE China A50 ETF',
    symbol: 'AFTY',
  },
  {
    value: 'AGD',
    label: 'AGD,  Abrdn Global Dynamic Dividend Fund',
    name: 'Abrdn Global Dynamic Dividend Fund',
    symbol: 'AGD',
  },
  {
    value: 'AGGY',
    label: 'AGGY,  WisdomTree Yield Enhanced U.S. Aggregate Bond Fund',
    name: 'WisdomTree Yield Enhanced U.S. Aggregate Bond Fund',
    symbol: 'AGGY',
  },
  {
    value: 'AGNCN',
    label: 'AGNCN,  AGNC Investment Corp.',
    name: 'AGNC Investment Corp.',
    symbol: 'AGNCN',
  },
  {
    value: 'AGZ',
    label: 'AGZ,  iShares Agency Bond ETF',
    name: 'iShares Agency Bond ETF',
    symbol: 'AGZ',
  },
  {
    value: 'AHPAU',
    label: 'AHPAU,  Avista Public Acquisition Corp. II',
    name: 'Avista Public Acquisition Corp. II',
    symbol: 'AHPAU',
  },
  {
    value: 'AIF',
    label: 'AIF,  Apollo Tactical Income Fund Inc.',
    name: 'Apollo Tactical Income Fund Inc.',
    symbol: 'AIF',
  },
  {
    value: 'AIHS',
    label: 'AIHS,  Senmiao Technology Limited',
    name: 'Senmiao Technology Limited',
    symbol: 'AIHS',
  },
  {
    value: 'AINC',
    label: 'AINC,  Ashford Inc.',
    name: 'Ashford Inc.',
    symbol: 'AINC',
  },
  {
    value: 'AIRI',
    label: 'AIRI,  Air Industries Group',
    name: 'Air Industries Group',
    symbol: 'AIRI',
  },
  {
    value: 'AIRT',
    label: 'AIRT,  Air T, Inc.',
    name: 'Air T, Inc.',
    symbol: 'AIRT',
  },
  {
    value: 'AJX',
    label: 'AJX,  Great Ajax Corp.',
    name: 'Great Ajax Corp.',
    symbol: 'AJX',
  },
  {
    value: 'AJXA',
    label: 'AJXA,  Great Ajax Corp. 7.25 CV SR NT 24',
    name: 'Great Ajax Corp. 7.25 CV SR NT 24',
    symbol: 'AJXA',
  },
  {
    value: 'AKTX',
    label: 'AKTX,  Akari Therapeutics, Plc',
    name: 'Akari Therapeutics, Plc',
    symbol: 'AKTX',
  },
  {
    value: 'ALCO',
    label: 'ALCO,  Alico, Inc.',
    name: 'Alico, Inc.',
    symbol: 'ALCO',
  },
  {
    value: 'ALJJ',
    label: 'ALJJ,  ALJ Regional Holdings, Inc.',
    name: 'ALJ Regional Holdings, Inc.',
    symbol: 'ALJJ',
  },
  {
    value: 'ALPN',
    label: 'ALPN,  Alpine Immune Sciences, Inc.',
    name: 'Alpine Immune Sciences, Inc.',
    symbol: 'ALPN',
  },
  {
    value: 'ALRN',
    label: 'ALRN,  Aileron Therapeutics, Inc.',
    name: 'Aileron Therapeutics, Inc.',
    symbol: 'ALRN',
  },
  {
    value: 'AMLX',
    label: 'AMLX,  Amylyx Pharmaceuticals, Inc.',
    name: 'Amylyx Pharmaceuticals, Inc.',
    symbol: 'AMLX',
  },
  {
    value: 'AMOV',
    label: 'AMOV,  América Móvil, S.A.B. de C.V.',
    name: 'América Móvil, S.A.B. de C.V.',
    symbol: 'AMOV',
  },
  {
    value: 'AMS',
    label: 'AMS,  American Shared Hospital Services',
    name: 'American Shared Hospital Services',
    symbol: 'AMS',
  },
  {
    value: 'AMUB',
    label: 'AMUB,  UBS ETRACS Alerian MLP Index ETN Series B',
    name: 'UBS ETRACS Alerian MLP Index ETN Series B',
    symbol: 'AMUB',
  },
  {
    value: 'APDN',
    label: 'APDN,  Applied DNA Sciences, Inc.',
    name: 'Applied DNA Sciences, Inc.',
    symbol: 'APDN',
  },
  {
    value: 'APEN',
    label: 'APEN,  Apollo Endosurgery, Inc.',
    name: 'Apollo Endosurgery, Inc.',
    symbol: 'APEN',
  },
  {
    value: 'AQB',
    label: 'AQB,  AquaBounty Technologies, Inc.',
    name: 'AquaBounty Technologies, Inc.',
    symbol: 'AQB',
  },
  {
    value: 'ARCM',
    label: 'ARCM,  Arrow Reserve Capital Management ETF',
    name: 'Arrow Reserve Capital Management ETF',
    symbol: 'ARCM',
  },
  {
    value: 'ARCW',
    label: 'ARCW,  ARC Group Worldwide, Inc.',
    name: 'ARC Group Worldwide, Inc.',
    symbol: 'ARCW',
  },
  {
    value: 'ARL',
    label: 'ARL,  American Realty Investors, Inc.',
    name: 'American Realty Investors, Inc.',
    symbol: 'ARL',
  },
  {
    value: 'ARTNA',
    label: 'ARTNA,  Artesian Resources Corporation',
    name: 'Artesian Resources Corporation',
    symbol: 'ARTNA',
  },
  {
    value: 'ARTW',
    label: "ARTW,  Art's-Way Manufacturing Co., Inc.",
    name: "Art's-Way Manufacturing Co., Inc.",
    symbol: 'ARTW',
  },
  {
    value: 'ASEA',
    label: 'ASEA,  Global X FTSE Southeast Asia ETF',
    name: 'Global X FTSE Southeast Asia ETF',
    symbol: 'ASEA',
  },
  {
    value: 'ASET',
    label: 'ASET,  FlexShares Real Assets Allocation Index Fund',
    name: 'FlexShares Real Assets Allocation Index Fund',
    symbol: 'ASET',
  },
  {
    value: 'ASHX',
    label: 'ASHX,  Xtrackers MSCI China A Inclusion Equity ETF',
    name: 'Xtrackers MSCI China A Inclusion Equity ETF',
    symbol: 'ASHX',
  },
  {
    value: 'ASM',
    label: 'ASM,  Avino Silver & Gold Mines Ltd.',
    name: 'Avino Silver & Gold Mines Ltd.',
    symbol: 'ASM',
  },
  {
    value: 'ASPU',
    label: 'ASPU,  Aspen Group, Inc.',
    name: 'Aspen Group, Inc.',
    symbol: 'ASPU',
  },
  {
    value: 'ASRV',
    label: 'ASRV,  AmeriServ Financial, Inc.',
    name: 'AmeriServ Financial, Inc.',
    symbol: 'ASRV',
  },
  {
    value: 'ASTC',
    label: 'ASTC,  Astrotech Corporation',
    name: 'Astrotech Corporation',
    symbol: 'ASTC',
  },
  {
    value: 'OWL',
    label: 'OWL,  Blue Owl Capital Inc.',
    name: 'Blue Owl Capital Inc.',
    symbol: 'OWL',
  },
  {
    value: 'ATEC',
    label: 'ATEC,  Alphatec Holdings, Inc.',
    name: 'Alphatec Holdings, Inc.',
    symbol: 'ATEC',
  },
  {
    value: 'ATLC',
    label: 'ATLC,  Atlanticus Holdings Corporation',
    name: 'Atlanticus Holdings Corporation',
    symbol: 'ATLC',
  },
  {
    value: 'ATLO',
    label: 'ATLO,  Ames National Corporation',
    name: 'Ames National Corporation',
    symbol: 'ATLO',
  },
  {
    value: 'ATMP',
    label: 'ATMP,  Barclays ETN+ Select MLP ETN',
    name: 'Barclays ETN+ Select MLP ETN',
    symbol: 'ATMP',
  },
  {
    value: 'AUDC',
    label: 'AUDC,  AudioCodes Ltd.',
    name: 'AudioCodes Ltd.',
    symbol: 'AUDC',
  },
  {
    value: 'AVNW',
    label: 'AVNW,  Aviat Networks, Inc.',
    name: 'Aviat Networks, Inc.',
    symbol: 'AVNW',
  },
  {
    value: 'AWX',
    label: 'AWX,  Avalon Holdings Corporation',
    name: 'Avalon Holdings Corporation',
    symbol: 'AWX',
  },
  {
    value: 'AXR',
    label: 'AXR,  AMREP Corporation',
    name: 'AMREP Corporation',
    symbol: 'AXR',
  },
  {
    value: 'BAL',
    label: 'BAL,  iPath Series B Bloomberg Cotton Subindex Total Return ETN',
    name: 'iPath Series B Bloomberg Cotton Subindex Total Return ETN',
    symbol: 'BAL',
  },
  {
    value: 'BANFP',
    label: 'BANFP,  BFC Capital Trust II PFD TR 7.20%',
    name: 'BFC Capital Trust II PFD TR 7.20%',
    symbol: 'BANFP',
  },
  {
    value: 'BAR',
    label: 'BAR,  GraniteShares Gold Trust',
    name: 'GraniteShares Gold Trust',
    symbol: 'BAR',
  },
  {
    value: 'NOTV',
    label: 'NOTV,  Inotiv, Inc.',
    name: 'Inotiv, Inc.',
    symbol: 'NOTV',
  },
  {
    value: 'BBDO',
    label: 'BBDO,  Banco Bradesco S.A.',
    name: 'Banco Bradesco S.A.',
    symbol: 'BBDO',
  },
  {
    value: 'BBP',
    label: 'BBP,  Virtus LifeSci Biotech Products ETF',
    name: 'Virtus LifeSci Biotech Products ETF',
    symbol: 'BBP',
  },
  {
    value: 'BCD',
    label:
      'BCD,  abrdn Bloomberg All Commodity Longer Dated Strategy K-1 Free ETF',
    name: 'abrdn Bloomberg All Commodity Longer Dated Strategy K-1 Free ETF',
    symbol: 'BCD',
  },
  {
    value: 'BCI',
    label: 'BCI,  abrdn Bloomberg All Commodity Strategy K-1 Free ETF',
    name: 'abrdn Bloomberg All Commodity Strategy K-1 Free ETF',
    symbol: 'BCI',
  },
  {
    value: 'BCLI',
    label: 'BCLI,  Brainstorm Cell Therapeutics Inc.',
    name: 'Brainstorm Cell Therapeutics Inc.',
    symbol: 'BCLI',
  },
  {
    value: 'BCM',
    label: 'BCM,  iPath Pure Beta Broad Commodity ETN',
    name: 'iPath Pure Beta Broad Commodity ETN',
    symbol: 'BCM',
  },
  {
    value: 'BCV',
    label: 'BCV,  Bancroft Fund Ltd.',
    name: 'Bancroft Fund Ltd.',
    symbol: 'BCV',
  },
  {
    value: 'BDCZ',
    label:
      'BDCZ,  UBS ETRACS Wells Fargo Business Development Company Index ETN',
    name: 'UBS ETRACS Wells Fargo Business Development Company Index ETN',
    symbol: 'BDCZ',
  },
  {
    value: 'BDL',
    label: "BDL,  Flanigan's Enterprises, Inc.",
    name: "Flanigan's Enterprises, Inc.",
    symbol: 'BDL',
  },
  {
    value: 'BDRY',
    label: 'BDRY,  Breakwave Dry Bulk Shipping ETF',
    name: 'Breakwave Dry Bulk Shipping ETF',
    symbol: 'BDRY',
  },
  {
    value: 'BELFA',
    label: 'BELFA,  Bel Fuse Inc.',
    name: 'Bel Fuse Inc.',
    symbol: 'BELFA',
  },
  {
    value: 'BEP',
    label: 'BEP,  Brookfield Renewable Partners L.P.',
    name: 'Brookfield Renewable Partners L.P.',
    symbol: 'BEP',
  },
  {
    value: 'BFIT',
    label: 'BFIT,  Global X Health & Wellness ETF',
    name: 'Global X Health & Wellness ETF',
    symbol: 'BFIT',
  },
  {
    value: 'BHACU',
    label: 'BHACU,  Crixus BH3 Acquisition Company',
    name: 'Crixus BH3 Acquisition Company',
    symbol: 'BHACU',
  },
  {
    value: 'BHV',
    label: 'BHV,  BlackRock Virginia Municipal Bond Trust',
    name: 'BlackRock Virginia Municipal Bond Trust',
    symbol: 'BHV',
  },
  {
    value: 'BIBL',
    label: 'BIBL,  Inspire 100 ETF',
    name: 'Inspire 100 ETF',
    symbol: 'BIBL',
  },
  {
    value: 'BICK',
    label: 'BICK,  First Trust BICK Index Fund',
    name: 'First Trust BICK Index Fund',
    symbol: 'BICK',
  },
  {
    value: 'BIS',
    label: 'BIS,  ProShares UltraShort Nasdaq Biotechnology',
    name: 'ProShares UltraShort Nasdaq Biotechnology',
    symbol: 'BIS',
  },
  {
    value: 'BJK',
    label: 'BJK,  VanEck Gaming ETF',
    name: 'VanEck Gaming ETF',
    symbol: 'BJK',
  },
  {
    value: 'BKN',
    label: 'BKN,  BlackRock Investment Quality Municipal Trust Inc.',
    name: 'BlackRock Investment Quality Municipal Trust Inc.',
    symbol: 'BKN',
  },
  {
    value: 'BKSC',
    label: 'BKSC,  Bank of South Carolina Corporation',
    name: 'Bank of South Carolina Corporation',
    symbol: 'BKSC',
  },
  {
    value: 'BKYI',
    label: 'BKYI,  BIO-key International, Inc.',
    name: 'BIO-key International, Inc.',
    symbol: 'BKYI',
  },
  {
    value: 'BLE',
    label: 'BLE,  BlackRock Municipal Income Trust II',
    name: 'BlackRock Municipal Income Trust II',
    symbol: 'BLE',
  },
  {
    value: 'BLES',
    label: 'BLES,  Inspire Global Hope ETF',
    name: 'Inspire Global Hope ETF',
    symbol: 'BLES',
  },
  {
    value: 'BLFS',
    label: 'BLFS,  BioLife Solutions, Inc.',
    name: 'BioLife Solutions, Inc.',
    symbol: 'BLFS',
  },
  {
    value: 'BLHY',
    label: 'BLHY,  Virtus Newfleet High Yield Bond ETF',
    name: 'Virtus Newfleet High Yield Bond ETF',
    symbol: 'BLHY',
  },
  {
    value: 'BLIN',
    label: 'BLIN,  Bridgeline Digital, Inc.',
    name: 'Bridgeline Digital, Inc.',
    symbol: 'BLIN',
  },
  {
    value: 'BLNG',
    label: 'BLNG,  Belong Acquisition Corp.',
    name: 'Belong Acquisition Corp.',
    symbol: 'BLNG',
  },
  {
    value: 'BLNK',
    label: 'BLNK,  Blink Charging Co.',
    name: 'Blink Charging Co.',
    symbol: 'BLNK',
  },
  {
    value: 'BLNKW',
    label: 'BLNKW,  Blink Charging Co',
    name: 'Blink Charging Co',
    symbol: 'BLNKW',
  },
  {
    value: 'BME',
    label: 'BME,  BlackRock Health Sciences Trust',
    name: 'BlackRock Health Sciences Trust',
    symbol: 'BME',
  },
  {
    value: 'BMRA',
    label: 'BMRA,  Biomerica, Inc.',
    name: 'Biomerica, Inc.',
    symbol: 'BMRA',
  },
  {
    value: 'BNDC',
    label: 'BNDC,  FlexShares Core Select Bond Fund',
    name: 'FlexShares Core Select Bond Fund',
    symbol: 'BNDC',
  },
  {
    value: 'BNTC',
    label: 'BNTC,  Benitec Biopharma Inc.',
    name: 'Benitec Biopharma Inc.',
    symbol: 'BNTC',
  },
  {
    value: 'BOSS',
    label: 'BOSS,  Global X Founder-Run Companies ETF',
    name: 'Global X Founder-Run Companies ETF',
    symbol: 'BOSS',
  },
  {
    value: 'BOTJ',
    label: 'BOTJ,  Bank of the James Financial Group, Inc.',
    name: 'Bank of the James Financial Group, Inc.',
    symbol: 'BOTJ',
  },
  {
    value: 'BOXL',
    label: 'BOXL,  Boxlight Corporation',
    name: 'Boxlight Corporation',
    symbol: 'BOXL',
  },
  {
    value: 'BPOPM',
    label: 'BPOPM,  Popular Capital Trust II PFD GTD 6.125%',
    name: 'Popular Capital Trust II PFD GTD 6.125%',
    symbol: 'BPOPM',
  },
  {
    value: 'BPTH',
    label: 'BPTH,  Bio-Path Holdings, Inc.',
    name: 'Bio-Path Holdings, Inc.',
    symbol: 'BPTH',
  },
  {
    value: 'BRN',
    label: 'BRN,  Barnwell Industries, Inc.',
    name: 'Barnwell Industries, Inc.',
    symbol: 'BRN',
  },
  {
    value: 'NRXP',
    label: 'NRXP,  NRx Pharmaceuticals, Inc.',
    name: 'NRx Pharmaceuticals, Inc.',
    symbol: 'NRXP',
  },
  {
    value: 'NRXPW',
    label: 'NRXPW,  NRx Pharmaceuticals, Inc.',
    name: 'NRx Pharmaceuticals, Inc.',
    symbol: 'NRXPW',
  },
  {
    value: 'BRQS',
    label: 'BRQS,  Borqs Technologies, Inc.',
    name: 'Borqs Technologies, Inc.',
    symbol: 'BRQS',
  },
  {
    value: 'BSCN',
    label: 'BSCN,  Invesco BulletShares 2023 Corporate Bond ETF',
    name: 'Invesco BulletShares 2023 Corporate Bond ETF',
    symbol: 'BSCN',
  },
  {
    value: 'BSCP',
    label: 'BSCP,  Invesco BulletShares 2025 Corporate Bond ETF',
    name: 'Invesco BulletShares 2025 Corporate Bond ETF',
    symbol: 'BSCP',
  },
  {
    value: 'BSCR',
    label: 'BSCR,  Invesco BulletShares 2027 Corporate Bond ETF',
    name: 'Invesco BulletShares 2027 Corporate Bond ETF',
    symbol: 'BSCR',
  },
  {
    value: 'BSJO',
    label: 'BSJO,  Invesco BulletShares 2024 High Yield Corporate Bond ETF',
    name: 'Invesco BulletShares 2024 High Yield Corporate Bond ETF',
    symbol: 'BSJO',
  },
  {
    value: 'BSJP',
    label: 'BSJP,  Invesco BulletShares 2025 High Yield Corporate Bond ETF',
    name: 'Invesco BulletShares 2025 High Yield Corporate Bond ETF',
    symbol: 'BSJP',
  },
  {
    value: 'BSQR',
    label: 'BSQR,  BSQUARE Corporation',
    name: 'BSQUARE Corporation',
    symbol: 'BSQR',
  },
  {
    value: 'BTA',
    label: 'BTA,  BlackRock Long-Term Municipal Advantage Trust',
    name: 'BlackRock Long-Term Municipal Advantage Trust',
    symbol: 'BTA',
  },
  {
    value: 'BTEC',
    label: 'BTEC,  Principal Healthcare Innovators ETF',
    name: 'Principal Healthcare Innovators ETF',
    symbol: 'BTEC',
  },
  {
    value: 'BTN',
    label: 'BTN,  Ballantyne Strong, Inc',
    name: 'Ballantyne Strong, Inc',
    symbol: 'BTN',
  },
  {
    value: 'BUR',
    label: 'BUR,  Burford Capital Limited',
    name: 'Burford Capital Limited',
    symbol: 'BUR',
  },
  {
    value: 'BVXV',
    label: 'BVXV,  BiondVax Pharmaceuticals Ltd.',
    name: 'BiondVax Pharmaceuticals Ltd.',
    symbol: 'BVXV',
  },
  {
    value: 'BWG',
    label: 'BWG,  Legg Mason BW Global Income Opportunities Fund Inc.',
    name: 'Legg Mason BW Global Income Opportunities Fund Inc.',
    symbol: 'BWG',
  },
  {
    value: 'BWV',
    label: 'BWV,  Blue Water Vaccines, Inc.',
    name: 'Blue Water Vaccines, Inc.',
    symbol: 'BWV',
  },
  {
    value: 'BYLD',
    label: 'BYLD,  iShares Yield Optimized Bond ETF',
    name: 'iShares Yield Optimized Bond ETF',
    symbol: 'BYLD',
  },
  {
    value: 'BYSI',
    label: 'BYSI,  BeyondSpring Inc.',
    name: 'BeyondSpring Inc.',
    symbol: 'BYSI',
  },
  {
    value: 'BZQ',
    label: 'BZQ,  ProShares UltraShort MSCI Brazil Capped',
    name: 'ProShares UltraShort MSCI Brazil Capped',
    symbol: 'BZQ',
  },
  {
    value: 'CAAS',
    label: 'CAAS,  China Automotive Systems, Inc.',
    name: 'China Automotive Systems, Inc.',
    symbol: 'CAAS',
  },
  {
    value: 'CACG',
    label: 'CACG,  ClearBridge All Cap Growth ESG ETF',
    name: 'ClearBridge All Cap Growth ESG ETF',
    symbol: 'CACG',
  },
  {
    value: 'CALF',
    label: 'CALF,  Pacer US Small Cap Cash Cows 100 ETF',
    name: 'Pacer US Small Cap Cash Cows 100 ETF',
    symbol: 'CALF',
  },
  {
    value: 'CANF',
    label: 'CANF,  Can-Fite BioPharma Ltd.',
    name: 'Can-Fite BioPharma Ltd.',
    symbol: 'CANF',
  },
  {
    value: 'CAPR',
    label: 'CAPR,  Capricor Therapeutics, Inc.',
    name: 'Capricor Therapeutics, Inc.',
    symbol: 'CAPR',
  },
  {
    value: 'CARV',
    label: 'CARV,  Carver Bancorp, Inc.',
    name: 'Carver Bancorp, Inc.',
    symbol: 'CARV',
  },
  {
    value: 'CARZ',
    label: 'CARZ,  First Trust S-Network Future Vehicles & Technology ETF',
    name: 'First Trust S-Network Future Vehicles & Technology ETF',
    symbol: 'CARZ',
  },
  {
    value: 'CBAN',
    label: 'CBAN,  Colony Bankcorp, Inc.',
    name: 'Colony Bankcorp, Inc.',
    symbol: 'CBAN',
  },
  {
    value: 'CBFV',
    label: 'CBFV,  CB Financial Services, Inc.',
    name: 'CB Financial Services, Inc.',
    symbol: 'CBFV',
  },
  {
    value: 'CBON',
    label: 'CBON,  VanEck China Bond ETF',
    name: 'VanEck China Bond ETF',
    symbol: 'CBON',
  },
  {
    value: 'CCM',
    label: 'CCM,  Concord Medical Services Holdings Limited',
    name: 'Concord Medical Services Holdings Limited',
    symbol: 'CCM',
  },
  {
    value: 'CCNE',
    label: 'CCNE,  CNB Financial Corporation',
    name: 'CNB Financial Corporation',
    symbol: 'CCNE',
  },
  {
    value: 'CCOR',
    label: 'CCOR,  Core Alternative ETF',
    name: 'Core Alternative ETF',
    symbol: 'CCOR',
  },
  {
    value: 'CCZ',
    label: 'CCZ,  Comcast Holdings Corp.',
    name: 'Comcast Holdings Corp.',
    symbol: 'CCZ',
  },
  {
    value: 'CDL',
    label: 'CDL,  VictoryShares US Large Cap High Div Volatility Wtd ETF',
    name: 'VictoryShares US Large Cap High Div Volatility Wtd ETF',
    symbol: 'CDL',
  },
  {
    value: 'CEFS',
    label: 'CEFS,  Saba Closed-End Funds ETF',
    name: 'Saba Closed-End Funds ETF',
    symbol: 'CEFS',
  },
  {
    value: 'CEMB',
    label: 'CEMB,  iShares J.P. Morgan EM Corporate Bond ETF',
    name: 'iShares J.P. Morgan EM Corporate Bond ETF',
    symbol: 'CEMB',
  },
  {
    value: 'CEMI',
    label: 'CEMI,  Chembio Diagnostics, Inc.',
    name: 'Chembio Diagnostics, Inc.',
    symbol: 'CEMI',
  },
  {
    value: 'CETXP',
    label: 'CETXP,  Cemtrex, Inc.',
    name: 'Cemtrex, Inc.',
    symbol: 'CETXP',
  },
  {
    value: 'CEW',
    label: 'CEW,  WisdomTree Emerging Currency Strategy Fund',
    name: 'WisdomTree Emerging Currency Strategy Fund',
    symbol: 'CEW',
  },
  {
    value: 'CEY',
    label: 'CEY,  VictoryShares Emerging Market High Div Volatility Wtd ETF',
    name: 'VictoryShares Emerging Market High Div Volatility Wtd ETF',
    symbol: 'CEY',
  },
  {
    value: 'CFA',
    label: 'CFA,  VictoryShares US 500 Volatility Wtd ETF',
    name: 'VictoryShares US 500 Volatility Wtd ETF',
    symbol: 'CFA',
  },
  {
    value: 'CFBK',
    label: 'CFBK,  CF Bankshares Inc.',
    name: 'CF Bankshares Inc.',
    symbol: 'CFBK',
  },
  {
    value: 'CGA',
    label: 'CGA,  China Green Agriculture, Inc.',
    name: 'China Green Agriculture, Inc.',
    symbol: 'CGA',
  },
  {
    value: 'CGO',
    label: 'CGO,  Calamos Global Total Return Fund',
    name: 'Calamos Global Total Return Fund',
    symbol: 'CGO',
  },
  {
    value: 'CGW',
    label: 'CGW,  Invesco S&P Global Water Index ETF',
    name: 'Invesco S&P Global Water Index ETF',
    symbol: 'CGW',
  },
  {
    value: 'CHCI',
    label: 'CHCI,  Comstock Holding Companies, Inc.',
    name: 'Comstock Holding Companies, Inc.',
    symbol: 'CHCI',
  },
  {
    value: 'CHGX',
    label: 'CHGX,  AXS Change Finance ESG ETF',
    name: 'AXS Change Finance ESG ETF',
    symbol: 'CHGX',
  },
  {
    value: 'CHIE',
    label: 'CHIE,  Global X MSCI China Energy ETF',
    name: 'Global X MSCI China Energy ETF',
    symbol: 'CHIE',
  },
  {
    value: 'CHII',
    label: 'CHII,  Global X MSCI China Industrials ETF',
    name: 'Global X MSCI China Industrials ETF',
    symbol: 'CHII',
  },
  {
    value: 'CHIM',
    label: 'CHIM,  Global X MSCI China Materials ETF',
    name: 'Global X MSCI China Materials ETF',
    symbol: 'CHIM',
  },
  {
    value: 'CHN',
    label: 'CHN,  The China Fund, Inc.',
    name: 'The China Fund, Inc.',
    symbol: 'CHN',
  },
  {
    value: 'CHNR',
    label: 'CHNR,  China Natural Resources, Inc.',
    name: 'China Natural Resources, Inc.',
    symbol: 'CHNR',
  },
  {
    value: 'CHSCO',
    label: 'CHSCO,  CHS Inc.',
    name: 'CHS Inc.',
    symbol: 'CHSCO',
  },
  {
    value: 'CHSCP',
    label: 'CHSCP,  CHS Inc.',
    name: 'CHS Inc.',
    symbol: 'CHSCP',
  },
  {
    value: 'CID',
    label: 'CID,  VictoryShares International High Div Volatility Wtd ETF',
    name: 'VictoryShares International High Div Volatility Wtd ETF',
    symbol: 'CID',
  },
  {
    value: 'CIL',
    label: 'CIL,  VictoryShares International Volatility Wtd ETF',
    name: 'VictoryShares International Volatility Wtd ETF',
    symbol: 'CIL',
  },
  {
    value: 'CIX',
    label: 'CIX,  CompX International Inc.',
    name: 'CompX International Inc.',
    symbol: 'CIX',
  },
  {
    value: 'CIZ',
    label: 'CIZ,  VictoryShares Developed Enhanced Volatility Wtd ETF',
    name: 'VictoryShares Developed Enhanced Volatility Wtd ETF',
    symbol: 'CIZ',
  },
  {
    value: 'CIZN',
    label: 'CIZN,  Citizens Holding Company',
    name: 'Citizens Holding Company',
    symbol: 'CIZN',
  },
  {
    value: 'CJJD',
    label: 'CJJD,  China Jo-Jo Drugstores, Inc.',
    name: 'China Jo-Jo Drugstores, Inc.',
    symbol: 'CJJD',
  },
  {
    value: 'CKX',
    label: 'CKX,  CKX Lands, Inc.',
    name: 'CKX Lands, Inc.',
    symbol: 'CKX',
  },
  {
    value: 'CLFD',
    label: 'CLFD,  Clearfield, Inc.',
    name: 'Clearfield, Inc.',
    symbol: 'CLFD',
  },
  {
    value: 'CLGN',
    label: 'CLGN,  CollPlant Biotechnologies Ltd.',
    name: 'CollPlant Biotechnologies Ltd.',
    symbol: 'CLGN',
  },
  {
    value: 'CLIX',
    label: 'CLIX,  ProShares Long Online/Short Stores ETF',
    name: 'ProShares Long Online/Short Stores ETF',
    symbol: 'CLIX',
  },
  {
    value: 'CLRB',
    label: 'CLRB,  Cellectar Biosciences, Inc.',
    name: 'Cellectar Biosciences, Inc.',
    symbol: 'CLRB',
  },
  {
    value: 'CLRG',
    label: 'CLRG,  IQ Chaikin U.S. Large Cap ETF',
    name: 'IQ Chaikin U.S. Large Cap ETF',
    symbol: 'CLRG',
  },
  {
    value: 'CLRO',
    label: 'CLRO,  ClearOne, Inc.',
    name: 'ClearOne, Inc.',
    symbol: 'CLRO',
  },
  {
    value: 'CLTL',
    label: 'CLTL,  Invesco Treasury Collateral ETF',
    name: 'Invesco Treasury Collateral ETF',
    symbol: 'CLTL',
  },
  {
    value: 'CMF',
    label: 'CMF,  iShares California Muni Bond ETF',
    name: 'iShares California Muni Bond ETF',
    symbol: 'CMF',
  },
  {
    value: 'CMU',
    label: 'CMU,  MFS High Yield Municipal Trust',
    name: 'MFS High Yield Municipal Trust',
    symbol: 'CMU',
  },
  {
    value: 'CN',
    label: 'CN,  Xtrackers MSCI All China Equity ETF',
    name: 'Xtrackers MSCI All China Equity ETF',
    symbol: 'CN',
  },
  {
    value: 'CNCR',
    label: 'CNCR,  Loncar Cancer Immunotherapy ETF',
    name: 'Loncar Cancer Immunotherapy ETF',
    symbol: 'CNCR',
  },
  {
    value: 'CNFR',
    label: 'CNFR,  Conifer Holdings, Inc.',
    name: 'Conifer Holdings, Inc.',
    symbol: 'CNFR',
  },
  {
    value: 'CNXT',
    label: 'CNXT,  VanEck ChiNext ETF',
    name: 'VanEck ChiNext ETF',
    symbol: 'CNXT',
  },
  {
    value: 'CNYA',
    label: 'CNYA,  iShares MSCI China A ETF',
    name: 'iShares MSCI China A ETF',
    symbol: 'CNYA',
  },
  {
    value: 'COCP',
    label: 'COCP,  Cocrystal Pharma, Inc.',
    name: 'Cocrystal Pharma, Inc.',
    symbol: 'COCP',
  },
  {
    value: 'CODA',
    label: 'CODA,  Coda Octopus Group, Inc.',
    name: 'Coda Octopus Group, Inc.',
    symbol: 'CODA',
  },
  {
    value: 'CODX',
    label: 'CODX,  Co-Diagnostics, Inc.',
    name: 'Co-Diagnostics, Inc.',
    symbol: 'CODX',
  },
  {
    value: 'COHN',
    label: 'COHN,  Cohen & Company Inc.',
    name: 'Cohen & Company Inc.',
    symbol: 'COHN',
  },
  {
    value: 'COM',
    label: 'COM,  Direxion Auspice Broad Commodity Strategy ETF',
    name: 'Direxion Auspice Broad Commodity Strategy ETF',
    symbol: 'COM',
  },
  {
    value: 'COMB',
    label: 'COMB,  GraniteShares Bloomberg Commodity Broad Strategy No K-1 ETF',
    name: 'GraniteShares Bloomberg Commodity Broad Strategy No K-1 ETF',
    symbol: 'COMB',
  },
  {
    value: 'COMT',
    label: 'COMT,  iShares GSCI Commodity Dynamic Roll Strategy ETF',
    name: 'iShares GSCI Commodity Dynamic Roll Strategy ETF',
    symbol: 'COMT',
  },
  {
    value: 'COW',
    label: 'COW,  iPath Series B Bloomberg Livestock Subindex Total Return ETN',
    name: 'iPath Series B Bloomberg Livestock Subindex Total Return ETN',
    symbol: 'COW',
  },
  {
    value: 'COWZ',
    label: 'COWZ,  Pacer US Cash Cows 100 ETF',
    name: 'Pacer US Cash Cows 100 ETF',
    symbol: 'COWZ',
  },
  {
    value: 'CPHC',
    label: 'CPHC,  Canterbury Park Holding Corporation',
    name: 'Canterbury Park Holding Corporation',
    symbol: 'CPHC',
  },
  {
    value: 'CPI',
    label: 'CPI,  IQ Real Return ETF',
    name: 'IQ Real Return ETF',
    symbol: 'CPI',
  },
  {
    value: 'CPSH',
    label: 'CPSH,  CPS Technologies Corporation',
    name: 'CPS Technologies Corporation',
    symbol: 'CPSH',
  },
  {
    value: 'CPSS',
    label: 'CPSS,  Consumer Portfolio Services, Inc.',
    name: 'Consumer Portfolio Services, Inc.',
    symbol: 'CPSS',
  },
  {
    value: 'CRT',
    label: 'CRT,  Cross Timbers Royalty Trust',
    name: 'Cross Timbers Royalty Trust',
    symbol: 'CRT',
  },
  {
    value: 'CSA',
    label: 'CSA,  VictoryShares US Small Cap Volatility Wtd ETF',
    name: 'VictoryShares US Small Cap Volatility Wtd ETF',
    symbol: 'CSA',
  },
  {
    value: 'CSB',
    label: 'CSB,  VictoryShares US Small Cap High Div Volatility Wtd ETF',
    name: 'VictoryShares US Small Cap High Div Volatility Wtd ETF',
    symbol: 'CSB',
  },
  {
    value: 'CSF',
    label: 'CSF,  VictoryShares US Discovery Enhanced Volatility Wtd ETF',
    name: 'VictoryShares US Discovery Enhanced Volatility Wtd ETF',
    symbol: 'CSF',
  },
  {
    value: 'CSM',
    label: 'CSM,  ProShares Large Cap Core Plus',
    name: 'ProShares Large Cap Core Plus',
    symbol: 'CSM',
  },
  {
    value: 'CSML',
    label: 'CSML,  IQ Chaikin U.S. Small Cap ETF',
    name: 'IQ Chaikin U.S. Small Cap ETF',
    symbol: 'CSML',
  },
  {
    value: 'CSPI',
    label: 'CSPI,  CSP Inc.',
    name: 'CSP Inc.',
    symbol: 'CSPI',
  },
  {
    value: 'CSWC',
    label: 'CSWC,  Capital Southwest Corporation',
    name: 'Capital Southwest Corporation',
    symbol: 'CSWC',
  },
  {
    value: 'CTDD',
    label: 'CTDD,  Qwest Corp. 6.75% NT 57',
    name: 'Qwest Corp. 6.75% NT 57',
    symbol: 'CTDD',
  },
  {
    value: 'CTHR',
    label: 'CTHR,  Charles & Colvard, Ltd.',
    name: 'Charles & Colvard, Ltd.',
    symbol: 'CTHR',
  },
  {
    value: 'CTIB',
    label: 'CTIB,  Yunhong CTI Ltd.',
    name: 'Yunhong CTI Ltd.',
    symbol: 'CTIB',
  },
  {
    value: 'CTXR',
    label: 'CTXR,  Citius Pharmaceuticals, Inc.',
    name: 'Citius Pharmaceuticals, Inc.',
    symbol: 'CTXR',
  },
  {
    value: 'CUT',
    label: 'CUT,  Invesco MSCI Global Timber ETF',
    name: 'Invesco MSCI Global Timber ETF',
    symbol: 'CUT',
  },
  {
    value: 'CVLY',
    label: 'CVLY,  Codorus Valley Bancorp, Inc.',
    name: 'Codorus Valley Bancorp, Inc.',
    symbol: 'CVLY',
  },
  {
    value: 'CVM',
    label: 'CVM,  CEL-SCI Corporation',
    name: 'CEL-SCI Corporation',
    symbol: 'CVM',
  },
  {
    value: 'CVR',
    label: 'CVR,  Chicago Rivet & Machine Co.',
    name: 'Chicago Rivet & Machine Co.',
    symbol: 'CVR',
  },
  {
    value: 'CWBC',
    label: 'CWBC,  Community West Bancshares',
    name: 'Community West Bancshares',
    symbol: 'CWBC',
  },
  {
    value: 'CWS',
    label: 'CWS,  AdvisorShares Focused Equity ETF',
    name: 'AdvisorShares Focused Equity ETF',
    symbol: 'CWS',
  },
  {
    value: 'CXDC',
    label: 'CXDC,  China XD Plastics Company Limited',
    name: 'China XD Plastics Company Limited',
    symbol: 'CXDC',
  },
  {
    value: 'CYAN',
    label: 'CYAN,  Cyanotech Corporation',
    name: 'Cyanotech Corporation',
    symbol: 'CYAN',
  },
  {
    value: 'CYCCP',
    label: 'CYCCP,  Cyclacel Pharmaceuticals, Inc.',
    name: 'Cyclacel Pharmaceuticals, Inc.',
    symbol: 'CYCCP',
  },
  {
    value: 'CYRN',
    label: 'CYRN,  Cyren Ltd.',
    name: 'Cyren Ltd.',
    symbol: 'CYRN',
  },
  {
    value: 'CZA',
    label: 'CZA,  Invesco Zacks Mid-Cap ETF',
    name: 'Invesco Zacks Mid-Cap ETF',
    symbol: 'CZA',
  },
  {
    value: 'CZNC',
    label: 'CZNC,  Citizens & Northern Corporation',
    name: 'Citizens & Northern Corporation',
    symbol: 'CZNC',
  },
  {
    value: 'DAC',
    label: 'DAC,  Danaos Corporation',
    name: 'Danaos Corporation',
    symbol: 'DAC',
  },
  {
    value: 'DALT',
    label: 'DALT,  Anfield Capital Diversified Alternatives ETF',
    name: 'Anfield Capital Diversified Alternatives ETF',
    symbol: 'DALT',
  },
  {
    value: 'DARE',
    label: 'DARE,  Daré Bioscience, Inc.',
    name: 'Daré Bioscience, Inc.',
    symbol: 'DARE',
  },
  {
    value: 'DBAW',
    label: 'DBAW,  Xtrackers MSCI All World ex U.S. Hedged Equity ETF',
    name: 'Xtrackers MSCI All World ex U.S. Hedged Equity ETF',
    symbol: 'DBAW',
  },
  {
    value: 'DBB',
    label: 'DBB,  Invesco DB Base Metals Fund',
    name: 'Invesco DB Base Metals Fund',
    symbol: 'DBB',
  },
  {
    value: 'DBE',
    label: 'DBE,  Invesco DB Energy Fund',
    name: 'Invesco DB Energy Fund',
    symbol: 'DBE',
  },
  {
    value: 'DBEZ',
    label: 'DBEZ,  Xtrackers MSCI Eurozone Hedged Equity ETF',
    name: 'Xtrackers MSCI Eurozone Hedged Equity ETF',
    symbol: 'DBEZ',
  },
  {
    value: 'DBP',
    label: 'DBP,  Invesco DB Precious Metals Fund',
    name: 'Invesco DB Precious Metals Fund',
    symbol: 'DBP',
  },
  {
    value: 'DBS',
    label: 'DBS,  Invesco DB Silver Fund',
    name: 'Invesco DB Silver Fund',
    symbol: 'DBS',
  },
  {
    value: 'DDF',
    label: 'DDF,  Delaware Investments Dividend and Income Fund, Inc.',
    name: 'Delaware Investments Dividend and Income Fund, Inc.',
    symbol: 'DDF',
  },
  {
    value: 'DDLS',
    label:
      'DDLS,  WisdomTree Dynamic Currency Hedged International SmallCap Equity Fund',
    name: 'WisdomTree Dynamic Currency Hedged International SmallCap Equity Fund',
    symbol: 'DDLS',
  },
  {
    value: 'DDT',
    label: 'DDT,  Dillards Capital Trust I CAP SECS 7.5%',
    name: 'Dillards Capital Trust I CAP SECS 7.5%',
    symbol: 'DDT',
  },
  {
    value: 'DEX',
    label: 'DEX,  Delaware Enhanced Global Dividend and Income Fund',
    name: 'Delaware Enhanced Global Dividend and Income Fund',
    symbol: 'DEX',
  },
  {
    value: 'DFND',
    label: 'DFND,  Siren DIVCON Dividend Defender ETF',
    name: 'Siren DIVCON Dividend Defender ETF',
    symbol: 'DFND',
  },
  {
    value: 'DGICB',
    label: 'DGICB,  Donegal Group Inc.',
    name: 'Donegal Group Inc.',
    symbol: 'DGICB',
  },
  {
    value: 'DGLY',
    label: 'DGLY,  Digital Ally, Inc.',
    name: 'Digital Ally, Inc.',
    symbol: 'DGLY',
  },
  {
    value: 'DGRS',
    label: 'DGRS,  WisdomTree U.S. SmallCap Quality Dividend Growth Fund',
    name: 'WisdomTree U.S. SmallCap Quality Dividend Growth Fund',
    symbol: 'DGRS',
  },
  {
    value: 'DGT',
    label: 'DGT,  SPDR Global Dow ETF',
    name: 'SPDR Global Dow ETF',
    symbol: 'DGT',
  },
  {
    value: 'DIG',
    label: 'DIG,  ProShares Ultra Oil & Gas',
    name: 'ProShares Ultra Oil & Gas',
    symbol: 'DIG',
  },
  {
    value: 'DIM',
    label: 'DIM,  WisdomTree International MidCap Dividend Fund',
    name: 'WisdomTree International MidCap Dividend Fund',
    symbol: 'DIM',
  },
  {
    value: 'DIT',
    label: 'DIT,  AMCON Distributing Company',
    name: 'AMCON Distributing Company',
    symbol: 'DIT',
  },
  {
    value: 'DIV',
    label: 'DIV,  Global X SuperDividend U.S. ETF',
    name: 'Global X SuperDividend U.S. ETF',
    symbol: 'DIV',
  },
  {
    value: 'DIVB',
    label: 'DIVB,  iShares Core Dividend ETF',
    name: 'iShares Core Dividend ETF',
    symbol: 'DIVB',
  },
  {
    value: 'DIVO',
    label: 'DIVO,  Amplify CWP Enhanced Dividend Income ETF',
    name: 'Amplify CWP Enhanced Dividend Income ETF',
    symbol: 'DIVO',
  },
  {
    value: 'DJCO',
    label: 'DJCO,  Daily Journal Corporation',
    name: 'Daily Journal Corporation',
    symbol: 'DJCO',
  },
  {
    value: 'DJD',
    label: 'DJD,  Invesco Dow Jones Industrial Average Dividend ETF',
    name: 'Invesco Dow Jones Industrial Average Dividend ETF',
    symbol: 'DJD',
  },
  {
    value: 'DLHC',
    label: 'DLHC,  DLH Holdings Corp.',
    name: 'DLH Holdings Corp.',
    symbol: 'DLHC',
  },
  {
    value: 'DLNG',
    label: 'DLNG,  Dynagas LNG Partners LP',
    name: 'Dynagas LNG Partners LP',
    symbol: 'DLNG',
  },
  {
    value: 'DLPN',
    label: 'DLPN,  Dolphin Entertainment, Inc.',
    name: 'Dolphin Entertainment, Inc.',
    symbol: 'DLPN',
  },
  {
    value: 'DMB',
    label: 'DMB,  BNY Mellon Municipal Bond Infrastructure Fund, Inc.',
    name: 'BNY Mellon Municipal Bond Infrastructure Fund, Inc.',
    symbol: 'DMB',
  },
  {
    value: 'DMLP',
    label: 'DMLP,  Dorchester Minerals, L.P.',
    name: 'Dorchester Minerals, L.P.',
    symbol: 'DMLP',
  },
  {
    value: 'DNL',
    label: 'DNL,  WisdomTree Global ex-U.S. Quality Dividend Growth Fund',
    name: 'WisdomTree Global ex-U.S. Quality Dividend Growth Fund',
    symbol: 'DNL',
  },
  {
    value: 'DOGZ',
    label: 'DOGZ,  Dogness (International) Corporation',
    name: 'Dogness (International) Corporation',
    symbol: 'DOGZ',
  },
  {
    value: 'DRD',
    label: 'DRD,  DRDGOLD Limited',
    name: 'DRDGOLD Limited',
    symbol: 'DRD',
  },
  {
    value: 'DSWL',
    label: 'DSWL,  Deswell Industries, Inc.',
    name: 'Deswell Industries, Inc.',
    symbol: 'DSWL',
  },
  {
    value: 'DTD',
    label: 'DTD,  WisdomTree U.S. Total Dividend Fund',
    name: 'WisdomTree U.S. Total Dividend Fund',
    symbol: 'DTD',
  },
  {
    value: 'DTEA',
    label: 'DTEA,  DAVIDsTEA Inc.',
    name: 'DAVIDsTEA Inc.',
    symbol: 'DTEA',
  },
  {
    value: 'DTEC',
    label: 'DTEC,  ALPS Disruptive Technologies ETF',
    name: 'ALPS Disruptive Technologies ETF',
    symbol: 'DTEC',
  },
  {
    value: 'DTF',
    label: 'DTF,  DTF Tax-Free Income 2028 Term Fund Inc.',
    name: 'DTF Tax-Free Income 2028 Term Fund Inc.',
    symbol: 'DTF',
  },
  {
    value: 'DUG',
    label: 'DUG,  ProShares UltraShort Oil & Gas',
    name: 'ProShares UltraShort Oil & Gas',
    symbol: 'DUG',
  },
  {
    value: 'DUSA',
    label: 'DUSA,  Davis Select U.S. Equity ETF',
    name: 'Davis Select U.S. Equity ETF',
    symbol: 'DUSA',
  },
  {
    value: 'DUSL',
    label: 'DUSL,  Direxion Daily Industrials Bull 3X Shares',
    name: 'Direxion Daily Industrials Bull 3X Shares',
    symbol: 'DUSL',
  },
  {
    value: 'DVYA',
    label: 'DVYA,  iShares Asia/Pacific Dividend ETF',
    name: 'iShares Asia/Pacific Dividend ETF',
    symbol: 'DVYA',
  },
  {
    value: 'DWAC',
    label: 'DWAC,  Digital World Acquisition Corp.',
    name: 'Digital World Acquisition Corp.',
    symbol: 'DWAC',
  },
  {
    value: 'DWAT',
    label: 'DWAT,  Arrow DWA Tactical: Macro ETF',
    name: 'Arrow DWA Tactical: Macro ETF',
    symbol: 'DWAT',
  },
  {
    value: 'DWCR',
    label: 'DWCR,  Arrow DWA Tactical: International ETF',
    name: 'Arrow DWA Tactical: International ETF',
    symbol: 'DWCR',
  },
  {
    value: 'DWLD',
    label: 'DWLD,  Davis Select Worldwide ETF',
    name: 'Davis Select Worldwide ETF',
    symbol: 'DWLD',
  },
  {
    value: 'DWPP',
    label: "DWPP,  First Trust Dorsey Wright People's Portfolio ETF",
    name: "First Trust Dorsey Wright People's Portfolio ETF",
    symbol: 'DWPP',
  },
  {
    value: 'DXF',
    label: 'DXF,  Dunxin Financial Holdings Limited',
    name: 'Dunxin Financial Holdings Limited',
    symbol: 'DXF',
  },
  {
    value: 'DYNT',
    label: 'DYNT,  Dynatronics Corporation',
    name: 'Dynatronics Corporation',
    symbol: 'DYNT',
  },
  {
    value: 'DYSL',
    label: 'DYSL,  Dynasil Corporation of America',
    name: 'Dynasil Corporation of America',
    symbol: 'DYSL',
  },
  {
    value: 'DZSI',
    label: 'DZSI,  DZS Inc.',
    name: 'DZS Inc.',
    symbol: 'DZSI',
  },
  {
    value: 'EAI',
    label: 'EAI,  Entergy Arkansas, Inc. 1M BD 4.875%66',
    name: 'Entergy Arkansas, Inc. 1M BD 4.875%66',
    symbol: 'EAI',
  },
  {
    value: 'EAST',
    label: 'EAST,  Eastside Distilling, Inc.',
    name: 'Eastside Distilling, Inc.',
    symbol: 'EAST',
  },
  {
    value: 'EBND',
    label: 'EBND,  SPDR Bloomberg Emerging Markets Local Bond ETF',
    name: 'SPDR Bloomberg Emerging Markets Local Bond ETF',
    symbol: 'EBND',
  },
  {
    value: 'ECF',
    label: 'ECF,  Ellsworth Growth and Income Fund Ltd.',
    name: 'Ellsworth Growth and Income Fund Ltd.',
    symbol: 'ECF',
  },
  {
    value: 'ECNS',
    label: 'ECNS,  iShares MSCI China Small-Cap ETF',
    name: 'iShares MSCI China Small-Cap ETF',
    symbol: 'ECNS',
  },
  {
    value: 'EDEN',
    label: 'EDEN,  iShares MSCI Denmark ETF',
    name: 'iShares MSCI Denmark ETF',
    symbol: 'EDEN',
  },
  {
    value: 'EDF',
    label: 'EDF,  Virtus Stone Harbor Emerging Markets Income Fund',
    name: 'Virtus Stone Harbor Emerging Markets Income Fund',
    symbol: 'EDF',
  },
  {
    value: 'EDOW',
    label: 'EDOW,  First Trust Dow 30 Equal Weight ETF',
    name: 'First Trust Dow 30 Equal Weight ETF',
    symbol: 'EDOW',
  },
  {
    value: 'EEA',
    label: 'EEA,  The European Equity Fund, Inc.',
    name: 'The European Equity Fund, Inc.',
    symbol: 'EEA',
  },
  {
    value: 'EEMD',
    label: 'EEMD,  AAM S&P Emerging Markets High Dividend Value ETF',
    name: 'AAM S&P Emerging Markets High Dividend Value ETF',
    symbol: 'EEMD',
  },
  {
    value: 'EEMO',
    label: 'EEMO,  Invesco S&P Emerging Markets Momentum ETF',
    name: 'Invesco S&P Emerging Markets Momentum ETF',
    symbol: 'EEMO',
  },
  {
    value: 'EEMS',
    label: 'EEMS,  iShares MSCI Emerging Markets Small-Cap ETF',
    name: 'iShares MSCI Emerging Markets Small-Cap ETF',
    symbol: 'EEMS',
  },
  {
    value: 'EEMX',
    label: 'EEMX,  SPDR MSCI Emerging Markets Fossil Fuel Reserves Free ETF',
    name: 'SPDR MSCI Emerging Markets Fossil Fuel Reserves Free ETF',
    symbol: 'EEMX',
  },
  {
    value: 'EET',
    label: 'EET,  ProShares Ultra MSCI Emerging Markets',
    name: 'ProShares Ultra MSCI Emerging Markets',
    symbol: 'EET',
  },
  {
    value: 'EFAS',
    label: 'EFAS,  Global X MSCI SuperDividend EAFE ETF',
    name: 'Global X MSCI SuperDividend EAFE ETF',
    symbol: 'EFAS',
  },
  {
    value: 'EFAX',
    label: 'EFAX,  SPDR MSCI EAFE Fossil Fuel Reserves Free ETF',
    name: 'SPDR MSCI EAFE Fossil Fuel Reserves Free ETF',
    symbol: 'EFAX',
  },
  {
    value: 'EFBI',
    label: 'EFBI,  Eagle Financial Bancorp, Inc.',
    name: 'Eagle Financial Bancorp, Inc.',
    symbol: 'EFBI',
  },
  {
    value: 'EFNL',
    label: 'EFNL,  iShares MSCI Finland ETF',
    name: 'iShares MSCI Finland ETF',
    symbol: 'EFNL',
  },
  {
    value: 'EFO',
    label: 'EFO,  ProShares Ultra MSCI EAFE',
    name: 'ProShares Ultra MSCI EAFE',
    symbol: 'EFO',
  },
  {
    value: 'EFOI',
    label: 'EFOI,  Energy Focus, Inc.',
    name: 'Energy Focus, Inc.',
    symbol: 'EFOI',
  },
  {
    value: 'EIS',
    label: 'EIS,  iShares MSCI Israel ETF',
    name: 'iShares MSCI Israel ETF',
    symbol: 'EIS',
  },
  {
    value: 'EKAR',
    label:
      'EKAR,  Capital Link Global Green Energy Transport & Technology Leaders ETF',
    name: 'Capital Link Global Green Energy Transport & Technology Leaders ETF',
    symbol: 'EKAR',
  },
  {
    value: 'ELC',
    label: 'ELC,  Entergy Louisiana, LLC COLLATERAL TR MT',
    name: 'Entergy Louisiana, LLC COLLATERAL TR MT',
    symbol: 'ELC',
  },
  {
    value: 'ELLO',
    label: 'ELLO,  Ellomay Capital Ltd.',
    name: 'Ellomay Capital Ltd.',
    symbol: 'ELLO',
  },
  {
    value: 'ELMD',
    label: 'ELMD,  Electromed, Inc.',
    name: 'Electromed, Inc.',
    symbol: 'ELMD',
  },
  {
    value: 'ELTK',
    label: 'ELTK,  Eltek Ltd.',
    name: 'Eltek Ltd.',
    symbol: 'ELTK',
  },
  {
    value: 'ELVT',
    label: 'ELVT,  Elevate Credit, Inc.',
    name: 'Elevate Credit, Inc.',
    symbol: 'ELVT',
  },
  {
    value: 'EMCF',
    label: 'EMCF,  Emclaire Financial Corp',
    name: 'Emclaire Financial Corp',
    symbol: 'EMCF',
  },
  {
    value: 'EMDV',
    label: 'EMDV,  ProShares MSCI Emerging Markets Dividend Growers ETF',
    name: 'ProShares MSCI Emerging Markets Dividend Growers ETF',
    symbol: 'EMDV',
  },
  {
    value: 'EMF',
    label: 'EMF,  Templeton Emerging Markets Fund',
    name: 'Templeton Emerging Markets Fund',
    symbol: 'EMF',
  },
  {
    value: 'EMIF',
    label: 'EMIF,  iShares Emerging Markets Infrastructure ETF',
    name: 'iShares Emerging Markets Infrastructure ETF',
    symbol: 'EMIF',
  },
  {
    value: 'EMITF',
    label: 'EMITF,  Elbit Imaging Ltd.',
    name: 'Elbit Imaging Ltd.',
    symbol: 'EMITF',
  },
  {
    value: 'EMMS',
    label: 'EMMS,  Emmis Corporation',
    name: 'Emmis Corporation',
    symbol: 'EMMS',
  },
  {
    value: 'EMTL',
    label: 'EMTL,  SPDR DoubleLine Emerging Markets Fixed Income ETF',
    name: 'SPDR DoubleLine Emerging Markets Fixed Income ETF',
    symbol: 'EMTL',
  },
  {
    value: 'EMTY',
    label: 'EMTY,  ProShares Decline of the Retail Store ETF',
    name: 'ProShares Decline of the Retail Store ETF',
    symbol: 'EMTY',
  },
  {
    value: 'EMX',
    label: 'EMX,  EMX Royalty Corporation',
    name: 'EMX Royalty Corporation',
    symbol: 'EMX',
  },
  {
    value: 'EMXC',
    label: 'EMXC,  iShares MSCI Emerging Markets ex China ETF',
    name: 'iShares MSCI Emerging Markets ex China ETF',
    symbol: 'EMXC',
  },
  {
    value: 'ENFR',
    label: 'ENFR,  Alerian Energy Infrastructure ETF',
    name: 'Alerian Energy Infrastructure ETF',
    symbol: 'ENFR',
  },
  {
    value: 'ENG',
    label: 'ENG,  ENGlobal Corporation',
    name: 'ENGlobal Corporation',
    symbol: 'ENG',
  },
  {
    value: 'ENO',
    label: 'ENO,  Entergy Utility Group, Inc. 1ST MTG BD 66',
    name: 'Entergy Utility Group, Inc. 1ST MTG BD 66',
    symbol: 'ENO',
  },
  {
    value: 'ENSV',
    label: 'ENSV,  Enservco Corporation',
    name: 'Enservco Corporation',
    symbol: 'ENSV',
  },
  {
    value: 'ENTR',
    label: 'ENTR,  ERShares Entrepreneur ETF',
    name: 'ERShares Entrepreneur ETF',
    symbol: 'ENTR',
  },
  {
    value: 'ENX',
    label: 'ENX,  Eaton Vance New York Municipal Bond Fund',
    name: 'Eaton Vance New York Municipal Bond Fund',
    symbol: 'ENX',
  },
  {
    value: 'ENZL',
    label: 'ENZL,  iShares MSCI New Zealand ETF',
    name: 'iShares MSCI New Zealand ETF',
    symbol: 'ENZL',
  },
  {
    value: 'EOT',
    label: 'EOT,  Eaton Vance National Municipal Opportunities Trust',
    name: 'Eaton Vance National Municipal Opportunities Trust',
    symbol: 'EOT',
  },
  {
    value: 'EPIX',
    label: 'EPIX,  ESSA Pharma Inc.',
    name: 'ESSA Pharma Inc.',
    symbol: 'EPIX',
  },
  {
    value: 'EPRF',
    label: 'EPRF,  Innovator S&P Investment Grade Preferred ETF',
    name: 'Innovator S&P Investment Grade Preferred ETF',
    symbol: 'EPRF',
  },
  {
    value: 'EPS',
    label: 'EPS,  WisdomTree U.S. Earnings 500 Fund',
    name: 'WisdomTree U.S. Earnings 500 Fund',
    symbol: 'EPS',
  },
  {
    value: 'EQAL',
    label: 'EQAL,  Invesco Russell 1000 Equal Weight ETF',
    name: 'Invesco Russell 1000 Equal Weight ETF',
    symbol: 'EQAL',
  },
  {
    value: 'EQFN',
    label: 'EQFN,  Equitable Financial Corp.',
    name: 'Equitable Financial Corp.',
    symbol: 'EQFN',
  },
  {
    value: 'EQL',
    label: 'EQL,  ALPS Equal Sector Weight ETF',
    name: 'ALPS Equal Sector Weight ETF',
    symbol: 'EQL',
  },
  {
    value: 'EQRR',
    label: 'EQRR,  ProShares Equities for Rising Rates ETF',
    name: 'ProShares Equities for Rising Rates ETF',
    symbol: 'EQRR',
  },
  {
    value: 'EQS',
    label: 'EQS,  Equus Total Return, Inc.',
    name: 'Equus Total Return, Inc.',
    symbol: 'EQS',
  },
  {
    value: 'ERH',
    label: 'ERH,  Allspring Utilities and High Income Fund',
    name: 'Allspring Utilities and High Income Fund',
    symbol: 'ERH',
  },
  {
    value: 'ERO',
    label: 'ERO,  Ero Copper Corp.',
    name: 'Ero Copper Corp.',
    symbol: 'ERO',
  },
  {
    value: 'ERYP',
    label: 'ERYP,  ERYTECH Pharma S.A.',
    name: 'ERYTECH Pharma S.A.',
    symbol: 'ERYP',
  },
  {
    value: 'ESBA',
    label: 'ESBA,  Empire State Realty OP, L.P.',
    name: 'Empire State Realty OP, L.P.',
    symbol: 'ESBA',
  },
  {
    value: 'ESCA',
    label: 'ESCA,  Escalade, Incorporated',
    name: 'Escalade, Incorporated',
    symbol: 'ESCA',
  },
  {
    value: 'ESEA',
    label: 'ESEA,  Euroseas Ltd.',
    name: 'Euroseas Ltd.',
    symbol: 'ESEA',
  },
  {
    value: 'ESG',
    label: 'ESG,  FlexShares STOXX US ESG Select Index Fund',
    name: 'FlexShares STOXX US ESG Select Index Fund',
    symbol: 'ESG',
  },
  {
    value: 'ESGD',
    label: 'ESGD,  iShares ESG Aware MSCI EAFE ETF',
    name: 'iShares ESG Aware MSCI EAFE ETF',
    symbol: 'ESGD',
  },
  {
    value: 'ESGG',
    label: 'ESGG,  FlexShares STOXX Global ESG Select Index Fund',
    name: 'FlexShares STOXX Global ESG Select Index Fund',
    symbol: 'ESGG',
  },
  {
    value: 'ESGN',
    label: 'ESGN,  Columbia International ESG Equity Income ETF',
    name: 'Columbia International ESG Equity Income ETF',
    symbol: 'ESGN',
  },
  {
    value: 'ESGS',
    label: 'ESGS,  Columbia U.S. ESG Equity Income ETF',
    name: 'Columbia U.S. ESG Equity Income ETF',
    symbol: 'ESGS',
  },
  {
    value: 'ESGU',
    label: 'ESGU,  iShares ESG Aware MSCI USA ETF',
    name: 'iShares ESG Aware MSCI USA ETF',
    symbol: 'ESGU',
  },
  {
    value: 'ESQ',
    label: 'ESQ,  Esquire Financial Holdings, Inc.',
    name: 'Esquire Financial Holdings, Inc.',
    symbol: 'ESQ',
  },
  {
    value: 'ETHO',
    label: 'ETHO,  Etho Climate Leadership U.S. ETF',
    name: 'Etho Climate Leadership U.S. ETF',
    symbol: 'ETHO',
  },
  {
    value: 'ETX',
    label: 'ETX,  Eaton Vance Municipal Income 2028 Term Trust',
    name: 'Eaton Vance Municipal Income 2028 Term Trust',
    symbol: 'ETX',
  },
  {
    value: 'EUDG',
    label: 'EUDG,  WisdomTree Europe Quality Dividend Growth Fund',
    name: 'WisdomTree Europe Quality Dividend Growth Fund',
    symbol: 'EUDG',
  },
  {
    value: 'EUDV',
    label: 'EUDV,  ProShares MSCI Europe Dividend Growers ETF',
    name: 'ProShares MSCI Europe Dividend Growers ETF',
    symbol: 'EUDV',
  },
  {
    value: 'EUO',
    label: 'EUO,  ProShares UltraShort Euro',
    name: 'ProShares UltraShort Euro',
    symbol: 'EUO',
  },
  {
    value: 'EURL',
    label: 'EURL,  Direxion Daily FTSE Europe Bull 3X Shares',
    name: 'Direxion Daily FTSE Europe Bull 3X Shares',
    symbol: 'EURL',
  },
  {
    value: 'EUSA',
    label: 'EUSA,  iShares MSCI USA Equal Weighted ETF',
    name: 'iShares MSCI USA Equal Weighted ETF',
    symbol: 'EUSA',
  },
  {
    value: 'EVFM',
    label: 'EVFM,  Evofem Biosciences, Inc.',
    name: 'Evofem Biosciences, Inc.',
    symbol: 'EVFM',
  },
  {
    value: 'EVG',
    label: 'EVG,  Eaton Vance Short Duration Diversified Income Fund',
    name: 'Eaton Vance Short Duration Diversified Income Fund',
    symbol: 'EVG',
  },
  {
    value: 'EVGN',
    label: 'EVGN,  Evogene Ltd.',
    name: 'Evogene Ltd.',
    symbol: 'EVGN',
  },
  {
    value: 'EVK',
    label: 'EVK,  Ever-Glory International Group, Inc.',
    name: 'Ever-Glory International Group, Inc.',
    symbol: 'EVK',
  },
  {
    value: 'EVLMC',
    label:
      'EVLMC,  Eaton Vance TABS 5-to-15 Year Laddered Municipal Bond NextShares',
    name: 'Eaton Vance TABS 5-to-15 Year Laddered Municipal Bond NextShares',
    symbol: 'EVLMC',
  },
  {
    value: 'EVN',
    label: 'EVN,  Eaton Vance Municipal Income Trust',
    name: 'Eaton Vance Municipal Income Trust',
    symbol: 'EVN',
  },
  {
    value: 'EVOK',
    label: 'EVOK,  Evoke Pharma, Inc.',
    name: 'Evoke Pharma, Inc.',
    symbol: 'EVOK',
  },
  {
    value: 'EVSTC',
    label: 'EVSTC,  Eaton Vance Stock NextShares',
    name: 'Eaton Vance Stock NextShares',
    symbol: 'EVSTC',
  },
  {
    value: 'EVX',
    label: 'EVX,  VanEck Environmental Services ETF',
    name: 'VanEck Environmental Services ETF',
    symbol: 'EVX',
  },
  {
    value: 'EWK',
    label: 'EWK,  iShares MSCI Belgium ETF',
    name: 'iShares MSCI Belgium ETF',
    symbol: 'EWK',
  },
  {
    value: 'EWRE',
    label: 'EWRE,  Invesco S&P 500 Equal Weight Real Estate ETF',
    name: 'Invesco S&P 500 Equal Weight Real Estate ETF',
    symbol: 'EWRE',
  },
  {
    value: 'EWSC',
    label: 'EWSC,  Invesco S&P SmallCap 600 Equal Weight ETF',
    name: 'Invesco S&P SmallCap 600 Equal Weight ETF',
    symbol: 'EWSC',
  },
  {
    value: 'EWUS',
    label: 'EWUS,  iShares MSCI United Kingdom Small-Cap ETF',
    name: 'iShares MSCI United Kingdom Small-Cap ETF',
    symbol: 'EWUS',
  },
  {
    value: 'EWV',
    label: 'EWV,  ProShares UltraShort MSCI Japan',
    name: 'ProShares UltraShort MSCI Japan',
    symbol: 'EWV',
  },
  {
    value: 'EXD',
    label: 'EXD,  Eaton Vance Tax-Managed Buy-Write Strategy Fund',
    name: 'Eaton Vance Tax-Managed Buy-Write Strategy Fund',
    symbol: 'EXD',
  },
  {
    value: 'EYEN',
    label: 'EYEN,  Eyenovia, Inc.',
    name: 'Eyenovia, Inc.',
    symbol: 'EYEN',
  },
  {
    value: 'EYLD',
    label: 'EYLD,  Cambria Emerging Shareholder Yield ETF',
    name: 'Cambria Emerging Shareholder Yield ETF',
    symbol: 'EYLD',
  },
  {
    value: 'EZJ',
    label: 'EZJ,  ProShares Ultra MSCI Japan',
    name: 'ProShares Ultra MSCI Japan',
    symbol: 'EZJ',
  },
  {
    value: 'FAAR',
    label: 'FAAR,  First Trust Alternative Absolute Return Strategy ETF',
    name: 'First Trust Alternative Absolute Return Strategy ETF',
    symbol: 'FAAR',
  },
  {
    value: 'FAB',
    label: 'FAB,  First Trust Multi Cap Value AlphaDEX Fund',
    name: 'First Trust Multi Cap Value AlphaDEX Fund',
    symbol: 'FAB',
  },
  {
    value: 'FAD',
    label: 'FAD,  First Trust Multi Cap Growth AlphaDEX Fund',
    name: 'First Trust Multi Cap Growth AlphaDEX Fund',
    symbol: 'FAD',
  },
  {
    value: 'FAMI',
    label: 'FAMI,  Farmmi, Inc.',
    name: 'Farmmi, Inc.',
    symbol: 'FAMI',
  },
  {
    value: 'FAN',
    label: 'FAN,  First Trust Global Wind Energy ETF',
    name: 'First Trust Global Wind Energy ETF',
    symbol: 'FAN',
  },
  {
    value: 'FAT',
    label: 'FAT,  FAT Brands Inc.',
    name: 'FAT Brands Inc.',
    symbol: 'FAT',
  },
  {
    value: 'FBGX',
    label: 'FBGX,  UBS AG FI Enhanced Large Cap Growth ETN',
    name: 'UBS AG FI Enhanced Large Cap Growth ETN',
    symbol: 'FBGX',
  },
  {
    value: 'FBIOP',
    label: 'FBIOP,  Fortress Biotech, Inc.',
    name: 'Fortress Biotech, Inc.',
    symbol: 'FBIOP',
  },
  {
    value: 'FBND',
    label: 'FBND,  Fidelity Total Bond ETF',
    name: 'Fidelity Total Bond ETF',
    symbol: 'FBND',
  },
  {
    value: 'FCA',
    label: 'FCA,  First Trust China AlphaDEX Fund',
    name: 'First Trust China AlphaDEX Fund',
    symbol: 'FCA',
  },
  {
    value: 'FCAL',
    label: 'FCAL,  First Trust California Municipal High Income ETF',
    name: 'First Trust California Municipal High Income ETF',
    symbol: 'FCAL',
  },
  {
    value: 'FCAP',
    label: 'FCAP,  First Capital, Inc.',
    name: 'First Capital, Inc.',
    symbol: 'FCAP',
  },
  {
    value: 'FCEF',
    label: 'FCEF,  First Trust Income Opportunity ETF',
    name: 'First Trust Income Opportunity ETF',
    symbol: 'FCEF',
  },
  {
    value: 'FCO',
    label: 'FCO,  abrdn Global Income Fund, Inc.',
    name: 'abrdn Global Income Fund, Inc.',
    symbol: 'FCO',
  },
  {
    value: 'FCOM',
    label: 'FCOM,  Fidelity MSCI Communication Services Index ETF',
    name: 'Fidelity MSCI Communication Services Index ETF',
    symbol: 'FCOM',
  },
  {
    value: 'FCOR',
    label: 'FCOR,  Fidelity Corporate Bond ETF',
    name: 'Fidelity Corporate Bond ETF',
    symbol: 'FCOR',
  },
  {
    value: 'FDBC',
    label: 'FDBC,  Fidelity D & D Bancorp, Inc.',
    name: 'Fidelity D & D Bancorp, Inc.',
    symbol: 'FDBC',
  },
  {
    value: 'FDD',
    label: 'FDD,  First Trust STOXX European Select Dividend Index Fund',
    name: 'First Trust STOXX European Select Dividend Index Fund',
    symbol: 'FDD',
  },
  {
    value: 'FDLO',
    label: 'FDLO,  Fidelity Low Volatility Factor ETF',
    name: 'Fidelity Low Volatility Factor ETF',
    symbol: 'FDLO',
  },
  {
    value: 'FDM',
    label: 'FDM,  First Trust Dow Jones Select MicroCap Index Fund',
    name: 'First Trust Dow Jones Select MicroCap Index Fund',
    symbol: 'FDM',
  },
  {
    value: 'FDMO',
    label: 'FDMO,  Fidelity Momentum Factor ETF',
    name: 'Fidelity Momentum Factor ETF',
    symbol: 'FDMO',
  },
  {
    value: 'FDRR',
    label: 'FDRR,  Fidelity Dividend ETF for Rising Rates',
    name: 'Fidelity Dividend ETF for Rising Rates',
    symbol: 'FDRR',
  },
  {
    value: 'FDTS',
    label: 'FDTS,  First Trust Developed Markets ex-US Small Cap AlphaDEX Fund',
    name: 'First Trust Developed Markets ex-US Small Cap AlphaDEX Fund',
    symbol: 'FDTS',
  },
  {
    value: 'FEIM',
    label: 'FEIM,  Frequency Electronics, Inc.',
    name: 'Frequency Electronics, Inc.',
    symbol: 'FEIM',
  },
  {
    value: 'FEM',
    label: 'FEM,  First Trust Emerging Markets AlphaDEX Fund',
    name: 'First Trust Emerging Markets AlphaDEX Fund',
    symbol: 'FEM',
  },
  {
    value: 'FEO',
    label: 'FEO,  First Trust/abrdn Emerging Opportunity Fund',
    name: 'First Trust/abrdn Emerging Opportunity Fund',
    symbol: 'FEO',
  },
  {
    value: 'FEP',
    label: 'FEP,  First Trust Europe AlphaDEX Fund',
    name: 'First Trust Europe AlphaDEX Fund',
    symbol: 'FEP',
  },
  {
    value: 'FEX',
    label: 'FEX,  First Trust Large Cap Core AlphaDEX Fund',
    name: 'First Trust Large Cap Core AlphaDEX Fund',
    symbol: 'FEX',
  },
  {
    value: 'FFA',
    label: 'FFA,  First Trust Enhanced Equity Income Fund',
    name: 'First Trust Enhanced Equity Income Fund',
    symbol: 'FFA',
  },
  {
    value: 'FFBW',
    label: 'FFBW,  FFBW, Inc.',
    name: 'FFBW, Inc.',
    symbol: 'FFBW',
  },
  {
    value: 'FFHG',
    label: 'FFHG,  FormulaFolios Hedged Growth ETF',
    name: 'FormulaFolios Hedged Growth ETF',
    symbol: 'FFHG',
  },
  {
    value: 'FFHL',
    label: 'FFHL,  Fuwei Films (Holdings) Co., Ltd.',
    name: 'Fuwei Films (Holdings) Co., Ltd.',
    symbol: 'FFHL',
  },
  {
    value: 'FFIU',
    label: 'FFIU,  Fieldstone UVA Unconstrained Medium-Term Fixed Income ETF',
    name: 'Fieldstone UVA Unconstrained Medium-Term Fixed Income ETF',
    symbol: 'FFIU',
  },
  {
    value: 'FFSG',
    label: 'FFSG,  FormulaFolios Smart Growth ETF',
    name: 'FormulaFolios Smart Growth ETF',
    symbol: 'FFSG',
  },
  {
    value: 'FFTG',
    label: 'FFTG,  FormulaFolios Tactical Growth ETF',
    name: 'FormulaFolios Tactical Growth ETF',
    symbol: 'FFTG',
  },
  {
    value: 'FFTI',
    label: 'FFTI,  FormulaFolios Tactical Income ETF',
    name: 'FormulaFolios Tactical Income ETF',
    symbol: 'FFTI',
  },
  {
    value: 'FIBR',
    label: 'FIBR,  iShares U.S. Fixed Income Balanced Risk Factor ETF',
    name: 'iShares U.S. Fixed Income Balanced Risk Factor ETF',
    symbol: 'FIBR',
  },
  {
    value: 'FIDI',
    label: 'FIDI,  Fidelity International High Dividend ETF',
    name: 'Fidelity International High Dividend ETF',
    symbol: 'FIDI',
  },
  {
    value: 'FISK',
    label: 'FISK,  Empire State Realty OP, L.P.',
    name: 'Empire State Realty OP, L.P.',
    symbol: 'FISK',
  },
  {
    value: 'FITBI',
    label: 'FITBI,  Fifth Third Bancorp',
    name: 'Fifth Third Bancorp',
    symbol: 'FITBI',
  },
  {
    value: 'FIVA',
    label: 'FIVA,  Fidelity International Value Factor ETF',
    name: 'Fidelity International Value Factor ETF',
    symbol: 'FIVA',
  },
  {
    value: 'FIW',
    label: 'FIW,  First Trust Water ETF',
    name: 'First Trust Water ETF',
    symbol: 'FIW',
  },
  {
    value: 'FIXD',
    label: 'FIXD,  First Trust TCW Opportunistic Fixed Income ETF',
    name: 'First Trust TCW Opportunistic Fixed Income ETF',
    symbol: 'FIXD',
  },
  {
    value: 'FJP',
    label: 'FJP,  First Trust Japan AlphaDEX Fund',
    name: 'First Trust Japan AlphaDEX Fund',
    symbol: 'FJP',
  },
  {
    value: 'FKU',
    label: 'FKU,  First Trust United Kingdom AlphaDEX Fund',
    name: 'First Trust United Kingdom AlphaDEX Fund',
    symbol: 'FKU',
  },
  {
    value: 'FLAU',
    label: 'FLAU,  Franklin FTSE Australia ETF',
    name: 'Franklin FTSE Australia ETF',
    symbol: 'FLAU',
  },
  {
    value: 'FLAX',
    label: 'FLAX,  Franklin FTSE Asia ex Japan ETF',
    name: 'Franklin FTSE Asia ex Japan ETF',
    symbol: 'FLAX',
  },
  {
    value: 'FLBR',
    label: 'FLBR,  Franklin FTSE Brazil ETF',
    name: 'Franklin FTSE Brazil ETF',
    symbol: 'FLBR',
  },
  {
    value: 'FLCA',
    label: 'FLCA,  Franklin FTSE Canada ETF',
    name: 'Franklin FTSE Canada ETF',
    symbol: 'FLCA',
  },
  {
    value: 'FLCH',
    label: 'FLCH,  Franklin FTSE China ETF',
    name: 'Franklin FTSE China ETF',
    symbol: 'FLCH',
  },
  {
    value: 'FLCO',
    label: 'FLCO,  Franklin Investment Grade Corporate ETF',
    name: 'Franklin Investment Grade Corporate ETF',
    symbol: 'FLCO',
  },
  {
    value: 'FLEH',
    label: 'FLEH,  Franklin FTSE Europe Hedged ETF',
    name: 'Franklin FTSE Europe Hedged ETF',
    symbol: 'FLEH',
  },
  {
    value: 'FLFR',
    label: 'FLFR,  Franklin FTSE France ETF',
    name: 'Franklin FTSE France ETF',
    symbol: 'FLFR',
  },
  {
    value: 'FLGB',
    label: 'FLGB,  Franklin FTSE United Kingdom ETF',
    name: 'Franklin FTSE United Kingdom ETF',
    symbol: 'FLGB',
  },
  {
    value: 'FLGR',
    label: 'FLGR,  Franklin FTSE Germany ETF',
    name: 'Franklin FTSE Germany ETF',
    symbol: 'FLGR',
  },
  {
    value: 'FLGT',
    label: 'FLGT,  Fulgent Genetics, Inc.',
    name: 'Fulgent Genetics, Inc.',
    symbol: 'FLGT',
  },
  {
    value: 'FLHK',
    label: 'FLHK,  Franklin FTSE Hong Kong ETF',
    name: 'Franklin FTSE Hong Kong ETF',
    symbol: 'FLHK',
  },
  {
    value: 'FLIN',
    label: 'FLIN,  Franklin FTSE India ETF',
    name: 'Franklin FTSE India ETF',
    symbol: 'FLIN',
  },
  {
    value: 'FLIY',
    label: 'FLIY,  Franklin FTSE Italy ETF',
    name: 'Franklin FTSE Italy ETF',
    symbol: 'FLIY',
  },
  {
    value: 'FLJH',
    label: 'FLJH,  Franklin FTSE Japan Hedged ETF',
    name: 'Franklin FTSE Japan Hedged ETF',
    symbol: 'FLJH',
  },
  {
    value: 'FLJP',
    label: 'FLJP,  Franklin FTSE Japan ETF',
    name: 'Franklin FTSE Japan ETF',
    symbol: 'FLJP',
  },
  {
    value: 'FLKR',
    label: 'FLKR,  Franklin FTSE South Korea ETF',
    name: 'Franklin FTSE South Korea ETF',
    symbol: 'FLKR',
  },
  {
    value: 'FLLV',
    label: 'FLLV,  Franklin U.S. Low Volatility ETF',
    name: 'Franklin U.S. Low Volatility ETF',
    symbol: 'FLLV',
  },
  {
    value: 'FLMB',
    label: 'FLMB,  Franklin Municipal Green Bond ETF',
    name: 'Franklin Municipal Green Bond ETF',
    symbol: 'FLMB',
  },
  {
    value: 'FLMI',
    label: 'FLMI,  Franklin Dynamic Municipal Bond ETF',
    name: 'Franklin Dynamic Municipal Bond ETF',
    symbol: 'FLMI',
  },
  {
    value: 'FLMX',
    label: 'FLMX,  Franklin FTSE Mexico ETF',
    name: 'Franklin FTSE Mexico ETF',
    symbol: 'FLMX',
  },
  {
    value: 'FLN',
    label: 'FLN,  First Trust Latin America AlphaDEX Fund',
    name: 'First Trust Latin America AlphaDEX Fund',
    symbol: 'FLN',
  },
  {
    value: 'FLQL',
    label: 'FLQL,  Franklin U.S. Large Cap Multifactor Index ETF',
    name: 'Franklin U.S. Large Cap Multifactor Index ETF',
    symbol: 'FLQL',
  },
  {
    value: 'FLQM',
    label: 'FLQM,  Franklin U.S. Mid Cap Multifactor Index ETF',
    name: 'Franklin U.S. Mid Cap Multifactor Index ETF',
    symbol: 'FLQM',
  },
  {
    value: 'FLQS',
    label: 'FLQS,  Franklin U.S. Small Cap Multifactor Index ETF',
    name: 'Franklin U.S. Small Cap Multifactor Index ETF',
    symbol: 'FLQS',
  },
  {
    value: 'FLRT',
    label: 'FLRT,  Pacer Pacific Asset Floating Rate High Income ETF',
    name: 'Pacer Pacific Asset Floating Rate High Income ETF',
    symbol: 'FLRT',
  },
  {
    value: 'FLRU',
    label: 'FLRU,  Franklin FTSE Russia ETF',
    name: 'Franklin FTSE Russia ETF',
    symbol: 'FLRU',
  },
  {
    value: 'FLSW',
    label: 'FLSW,  Franklin FTSE Switzerland ETF',
    name: 'Franklin FTSE Switzerland ETF',
    symbol: 'FLSW',
  },
  {
    value: 'FLTW',
    label: 'FLTW,  Franklin FTSE Taiwan ETF',
    name: 'Franklin FTSE Taiwan ETF',
    symbol: 'FLTW',
  },
  {
    value: 'FMAT',
    label: 'FMAT,  Fidelity MSCI Materials Index ETF',
    name: 'Fidelity MSCI Materials Index ETF',
    symbol: 'FMAT',
  },
  {
    value: 'FMB',
    label: 'FMB,  First Trust Managed Municipal ETF',
    name: 'First Trust Managed Municipal ETF',
    symbol: 'FMB',
  },
  {
    value: 'FMF',
    label: 'FMF,  First Trust Managed Futures Strategy Fund',
    name: 'First Trust Managed Futures Strategy Fund',
    symbol: 'FMF',
  },
  {
    value: 'FMN',
    label: 'FMN,  Federated Hermes Premier Municipal Income Fund',
    name: 'Federated Hermes Premier Municipal Income Fund',
    symbol: 'FMN',
  },
  {
    value: 'FMY',
    label: 'FMY,  First Trust Mortgage Income Fund',
    name: 'First Trust Mortgage Income Fund',
    symbol: 'FMY',
  },
  {
    value: 'FNCB',
    label: 'FNCB,  FNCB Bancorp, Inc.',
    name: 'FNCB Bancorp, Inc.',
    symbol: 'FNCB',
  },
  {
    value: 'FNDC',
    label: 'FNDC,  Schwab Fundamental International Small Company Index ETF',
    name: 'Schwab Fundamental International Small Company Index ETF',
    symbol: 'FNDC',
  },
  {
    value: 'FNGD',
    label: 'FNGD,  MicroSectors FANG+ Index -3X Inverse Leveraged ETN',
    name: 'MicroSectors FANG+ Index -3X Inverse Leveraged ETN',
    symbol: 'FNGD',
  },
  {
    value: 'FNK',
    label: 'FNK,  First Trust Mid Cap Value AlphaDEX Fund',
    name: 'First Trust Mid Cap Value AlphaDEX Fund',
    symbol: 'FNK',
  },
  {
    value: 'FORD',
    label: 'FORD,  Forward Industries, Inc.',
    name: 'Forward Industries, Inc.',
    symbol: 'FORD',
  },
  {
    value: 'FORTY',
    label: 'FORTY,  Formula Systems (1985) Ltd.',
    name: 'Formula Systems (1985) Ltd.',
    symbol: 'FORTY',
  },
  {
    value: 'FPA',
    label: 'FPA,  First Trust Asia Pacific Ex-Japan AlphaDEX Fund',
    name: 'First Trust Asia Pacific Ex-Japan AlphaDEX Fund',
    symbol: 'FPA',
  },
  {
    value: 'FPAY',
    label: 'FPAY,  FlexShopper, Inc.',
    name: 'FlexShopper, Inc.',
    symbol: 'FPAY',
  },
  {
    value: 'FPEI',
    label:
      'FPEI,  First Trust Institutional Preferred Securities and Income ETF',
    name: 'First Trust Institutional Preferred Securities and Income ETF',
    symbol: 'FPEI',
  },
  {
    value: 'FPXI',
    label: 'FPXI,  First Trust International Equity Opportunities ETF',
    name: 'First Trust International Equity Opportunities ETF',
    symbol: 'FPXI',
  },
  {
    value: 'FQAL',
    label: 'FQAL,  Fidelity Quality Factor ETF',
    name: 'Fidelity Quality Factor ETF',
    symbol: 'FQAL',
  },
  {
    value: 'FRD',
    label: 'FRD,  Friedman Industries, Incorporated',
    name: 'Friedman Industries, Incorporated',
    symbol: 'FRD',
  },
  {
    value: 'FSBW',
    label: 'FSBW,  FS Bancorp, Inc.',
    name: 'FS Bancorp, Inc.',
    symbol: 'FSBW',
  },
  {
    value: 'FSFG',
    label: 'FSFG,  First Savings Financial Group, Inc.',
    name: 'First Savings Financial Group, Inc.',
    symbol: 'FSFG',
  },
  {
    value: 'FSI',
    label: 'FSI,  Flexible Solutions International, Inc.',
    name: 'Flexible Solutions International, Inc.',
    symbol: 'FSI',
  },
  {
    value: 'FSTA',
    label: 'FSTA,  Fidelity MSCI Consumer Staples Index ETF',
    name: 'Fidelity MSCI Consumer Staples Index ETF',
    symbol: 'FSTA',
  },
  {
    value: 'FTA',
    label: 'FTA,  First Trust Large Cap Value AlphaDEX Fund',
    name: 'First Trust Large Cap Value AlphaDEX Fund',
    symbol: 'FTA',
  },
  {
    value: 'FTAG',
    label: 'FTAG,  First Trust Indxx Global Agriculture ETF',
    name: 'First Trust Indxx Global Agriculture ETF',
    symbol: 'FTAG',
  },
  {
    value: 'FTFT',
    label: 'FTFT,  Future FinTech Group Inc.',
    name: 'Future FinTech Group Inc.',
    symbol: 'FTFT',
  },
  {
    value: 'FTGC',
    label: 'FTGC,  First Trust Global Tactical Commodity Strategy Fund',
    name: 'First Trust Global Tactical Commodity Strategy Fund',
    symbol: 'FTGC',
  },
  {
    value: 'FTHI',
    label: 'FTHI,  First Trust BuyWrite Income ETF',
    name: 'First Trust BuyWrite Income ETF',
    symbol: 'FTHI',
  },
  {
    value: 'FTRI',
    label: 'FTRI,  First Trust Indxx Global Natural Resources Income ETF',
    name: 'First Trust Indxx Global Natural Resources Income ETF',
    symbol: 'FTRI',
  },
  {
    value: 'FTSD',
    label: 'FTSD,  Franklin Short Duration U.S. Government ETF',
    name: 'Franklin Short Duration U.S. Government ETF',
    symbol: 'FTSD',
  },
  {
    value: 'FTXG',
    label: 'FTXG,  First Trust Nasdaq Food & Beverage ETF',
    name: 'First Trust Nasdaq Food & Beverage ETF',
    symbol: 'FTXG',
  },
  {
    value: 'FTXH',
    label: 'FTXH,  First Trust Nasdaq Pharmaceuticals ETF',
    name: 'First Trust Nasdaq Pharmaceuticals ETF',
    symbol: 'FTXH',
  },
  {
    value: 'FTXL',
    label: 'FTXL,  First Trust Nasdaq Semiconductor ETF',
    name: 'First Trust Nasdaq Semiconductor ETF',
    symbol: 'FTXL',
  },
  {
    value: 'FTXN',
    label: 'FTXN,  First Trust Nasdaq Oil & Gas ETF',
    name: 'First Trust Nasdaq Oil & Gas ETF',
    symbol: 'FTXN',
  },
  {
    value: 'FTXR',
    label: 'FTXR,  First Trust Nasdaq Transportation ETF',
    name: 'First Trust Nasdaq Transportation ETF',
    symbol: 'FTXR',
  },
  {
    value: 'FUE',
    label:
      'FUE,  ELEMENTS Linked to the MLCX Biofuels Index (Exchange Series) - Total Return',
    name: 'ELEMENTS Linked to the MLCX Biofuels Index (Exchange Series) - Total Return',
    symbol: 'FUE',
  },
  {
    value: 'FUNC',
    label: 'FUNC,  First United Corporation',
    name: 'First United Corporation',
    symbol: 'FUNC',
  },
  {
    value: 'FUND',
    label: 'FUND,  Sprott Focus Trust, Inc.',
    name: 'Sprott Focus Trust, Inc.',
    symbol: 'FUND',
  },
  {
    value: 'FUSB',
    label: 'FUSB,  First US Bancshares, Inc.',
    name: 'First US Bancshares, Inc.',
    symbol: 'FUSB',
  },
  {
    value: 'FUTY',
    label: 'FUTY,  Fidelity MSCI Utilities Index ETF',
    name: 'Fidelity MSCI Utilities Index ETF',
    symbol: 'FUTY',
  },
  {
    value: 'FUV',
    label: 'FUV,  Arcimoto, Inc.',
    name: 'Arcimoto, Inc.',
    symbol: 'FUV',
  },
  {
    value: 'FV',
    label: 'FV,  First Trust Dorsey Wright Focus 5 ETF',
    name: 'First Trust Dorsey Wright Focus 5 ETF',
    symbol: 'FV',
  },
  {
    value: 'FXA',
    label: 'FXA,  Invesco CurrencyShares Australian Dollar Trust',
    name: 'Invesco CurrencyShares Australian Dollar Trust',
    symbol: 'FXA',
  },
  {
    value: 'FXB',
    label: 'FXB,  Invesco CurrencyShares British Pound Sterling Trust',
    name: 'Invesco CurrencyShares British Pound Sterling Trust',
    symbol: 'FXB',
  },
  {
    value: 'FXD',
    label: 'FXD,  First Trust Consumer Discretionary AlphaDEX Fund',
    name: 'First Trust Consumer Discretionary AlphaDEX Fund',
    symbol: 'FXD',
  },
  {
    value: 'FXG',
    label: 'FXG,  First Trust Consumer Staples AlphaDEX Fund',
    name: 'First Trust Consumer Staples AlphaDEX Fund',
    symbol: 'FXG',
  },
  {
    value: 'FXH',
    label: 'FXH,  First Trust Health Care AlphaDEX Fund',
    name: 'First Trust Health Care AlphaDEX Fund',
    symbol: 'FXH',
  },
  {
    value: 'FXL',
    label: 'FXL,  First Trust Technology AlphaDEX Fund',
    name: 'First Trust Technology AlphaDEX Fund',
    symbol: 'FXL',
  },
  {
    value: 'FYC',
    label: 'FYC,  First Trust Small Cap Growth AlphaDEX Fund',
    name: 'First Trust Small Cap Growth AlphaDEX Fund',
    symbol: 'FYC',
  },
  {
    value: 'FYLD',
    label: 'FYLD,  Cambria Foreign Shareholder Yield ETF',
    name: 'Cambria Foreign Shareholder Yield ETF',
    symbol: 'FYLD',
  },
  {
    value: 'FYX',
    label: 'FYX,  First Trust Small Cap Core AlphaDEX Fund',
    name: 'First Trust Small Cap Core AlphaDEX Fund',
    symbol: 'FYX',
  },
  {
    value: 'GAA',
    label: 'GAA,  Cambria Global Asset Allocation ETF',
    name: 'Cambria Global Asset Allocation ETF',
    symbol: 'GAA',
  },
  {
    value: 'GAINN',
    label: 'GAINN,  Gladstone Investment Corporation 5.00% Notes Due 2026',
    name: 'Gladstone Investment Corporation 5.00% Notes Due 2026',
    symbol: 'GAINN',
  },
  {
    value: 'GAM',
    label: 'GAM,  General American Investors Company, Inc.',
    name: 'General American Investors Company, Inc.',
    symbol: 'GAM',
  },
  {
    value: 'GAMR',
    label: 'GAMR,  Wedbush ETFMG Video Game Tech ETF',
    name: 'Wedbush ETFMG Video Game Tech ETF',
    symbol: 'GAMR',
  },
  {
    value: 'GBAB',
    label:
      'GBAB,  Guggenheim Taxable Municipal Bond & Investment Grade Debt Trust',
    name: 'Guggenheim Taxable Municipal Bond & Investment Grade Debt Trust',
    symbol: 'GBAB',
  },
  {
    value: 'GBF',
    label: 'GBF,  iShares Government/Credit Bond ETF',
    name: 'iShares Government/Credit Bond ETF',
    symbol: 'GBF',
  },
  {
    value: 'GBIL',
    label: 'GBIL,  Goldman Sachs Access Treasury 0-1 Year ETF',
    name: 'Goldman Sachs Access Treasury 0-1 Year ETF',
    symbol: 'GBIL',
  },
  {
    value: 'GBR',
    label: 'GBR,  New Concept Energy, Inc.',
    name: 'New Concept Energy, Inc.',
    symbol: 'GBR',
  },
  {
    value: 'GCV',
    label: 'GCV,  The Gabelli Convertible and Income Securities Fund Inc.',
    name: 'The Gabelli Convertible and Income Securities Fund Inc.',
    symbol: 'GCV',
  },
  {
    value: 'GDO',
    label: 'GDO,  Western Asset Global Corporate Defined Opportunity Fund Inc.',
    name: 'Western Asset Global Corporate Defined Opportunity Fund Inc.',
    symbol: 'GDO',
  },
  {
    value: 'GECCM',
    label: 'GECCM,  Great Elm Capital Corp. 6.75% Notes Due 2025',
    name: 'Great Elm Capital Corp. 6.75% Notes Due 2025',
    symbol: 'GECCM',
  },
  {
    value: 'GENE',
    label: 'GENE,  Genetic Technologies Limited',
    name: 'Genetic Technologies Limited',
    symbol: 'GENE',
  },
  {
    value: 'GEVO',
    label: 'GEVO,  Gevo, Inc.',
    name: 'Gevo, Inc.',
    symbol: 'GEVO',
  },
  {
    value: 'GGZ',
    label: 'GGZ,  The Gabelli Global Small and Mid Cap Value Trust',
    name: 'The Gabelli Global Small and Mid Cap Value Trust',
    symbol: 'GGZ',
  },
  {
    value: 'GHYB',
    label: 'GHYB,  Goldman Sachs Access High Yield Corporate Bond ETF',
    name: 'Goldman Sachs Access High Yield Corporate Bond ETF',
    symbol: 'GHYB',
  },
  {
    value: 'GHYG',
    label: 'GHYG,  iShares US & Intl High Yield Corp Bond ETF',
    name: 'iShares US & Intl High Yield Corp Bond ETF',
    symbol: 'GHYG',
  },
  {
    value: 'GIGB',
    label: 'GIGB,  Goldman Sachs Access Investment Grade Corporate Bond ETF',
    name: 'Goldman Sachs Access Investment Grade Corporate Bond ETF',
    symbol: 'GIGB',
  },
  {
    value: 'GIGM',
    label: 'GIGM,  GigaMedia Limited',
    name: 'GigaMedia Limited',
    symbol: 'GIGM',
  },
  {
    value: 'GILT',
    label: 'GILT,  Gilat Satellite Networks Ltd.',
    name: 'Gilat Satellite Networks Ltd.',
    symbol: 'GILT',
  },
  {
    value: 'GJH',
    label:
      'GJH,  STRATS Trust for United States Cellular Corp. Securities, Series 2004-6 STRATS 6.375',
    name: 'STRATS Trust for United States Cellular Corp. Securities, Series 2004-6 STRATS 6.375',
    symbol: 'GJH',
  },
  {
    value: 'GJO',
    label: 'GJO,  Strats Trust Wal Mart Stores Inc. STRT CTF 05-4',
    name: 'Strats Trust Wal Mart Stores Inc. STRT CTF 05-4',
    symbol: 'GJO',
  },
  {
    value: 'GJP',
    label:
      'GJP,  STRATS Trust For Dominion Resources Inc. Securities, Series 2005-6',
    name: 'STRATS Trust For Dominion Resources Inc. Securities, Series 2005-6',
    symbol: 'GJP',
  },
  {
    value: 'GJR',
    label: 'GJR,  Strats Trust For Procter & Gambel Security CTF 2006-1',
    name: 'Strats Trust For Procter & Gambel Security CTF 2006-1',
    symbol: 'GJR',
  },
  {
    value: 'GJS',
    label:
      'GJS,  STRATS Trust for Goldman Sachs Group Securities, Series 2006-2',
    name: 'STRATS Trust for Goldman Sachs Group Securities, Series 2006-2',
    symbol: 'GJS',
  },
  {
    value: 'GJT',
    label:
      'GJT,  Strats(SM) Trust For Allstate Corp Securities, Series 2006-3 06-3 ASSET BKD',
    name: 'Strats(SM) Trust For Allstate Corp Securities, Series 2006-3 06-3 ASSET BKD',
    symbol: 'GJT',
  },
  {
    value: 'GLBS',
    label: 'GLBS,  Globus Maritime Limited',
    name: 'Globus Maritime Limited',
    symbol: 'GLBS',
  },
  {
    value: 'GLBZ',
    label: 'GLBZ,  Glen Burnie Bancorp',
    name: 'Glen Burnie Bancorp',
    symbol: 'GLBZ',
  },
  {
    value: 'GLDI',
    label: 'GLDI,  Credit Suisse X-Links Gold Shares Covered Call ETN',
    name: 'Credit Suisse X-Links Gold Shares Covered Call ETN',
    symbol: 'GLDI',
  },
  {
    value: 'GLU',
    label: 'GLU,  The Gabelli Global Utility & Income Trust',
    name: 'The Gabelli Global Utility & Income Trust',
    symbol: 'GLU',
  },
  {
    value: 'GLV',
    label: 'GLV,  Clough Global Dividend and Income Fund',
    name: 'Clough Global Dividend and Income Fund',
    symbol: 'GLV',
  },
  {
    value: 'GMOM',
    label: 'GMOM,  Cambria Global Momentum ETF',
    name: 'Cambria Global Momentum ETF',
    symbol: 'GMOM',
  },
  {
    value: 'GNTY',
    label: 'GNTY,  Guaranty Bancshares, Inc.',
    name: 'Guaranty Bancshares, Inc.',
    symbol: 'GNTY',
  },
  {
    value: 'GNUS',
    label: 'GNUS,  Genius Brands International, Inc.',
    name: 'Genius Brands International, Inc.',
    symbol: 'GNUS',
  },
  {
    value: 'GOEX',
    label: 'GOEX,  Global X Gold Explorers ETF',
    name: 'Global X Gold Explorers ETF',
    symbol: 'GOEX',
  },
  {
    value: 'GQRE',
    label: 'GQRE,  FlexShares Global Quality Real Estate Index Fund',
    name: 'FlexShares Global Quality Real Estate Index Fund',
    symbol: 'GQRE',
  },
  {
    value: 'GRES',
    label: 'GRES,  IQ Global Resources ETF',
    name: 'IQ Global Resources ETF',
    symbol: 'GRES',
  },
  {
    value: 'GRF',
    label: 'GRF,  Eagle Capital Growth Fund, Inc.',
    name: 'Eagle Capital Growth Fund, Inc.',
    symbol: 'GRF',
  },
  {
    value: 'GRID',
    label:
      'GRID,  First Trust NASDAQ Clean Edge Smart Grid Infrastructure Index Fund',
    name: 'First Trust NASDAQ Clean Edge Smart Grid Infrastructure Index Fund',
    symbol: 'GRID',
  },
  {
    value: 'GRN',
    label: 'GRN,  iPath Series B Carbon ETN',
    name: 'iPath Series B Carbon ETN',
    symbol: 'GRN',
  },
  {
    value: 'GRNB',
    label: 'GRNB,  VanEck Green Bond ETF',
    name: 'VanEck Green Bond ETF',
    symbol: 'GRNB',
  },
  {
    value: 'GRU',
    label: 'GRU,  ELEMENTS Linked to the MLCX Grains Index - Total Return',
    name: 'ELEMENTS Linked to the MLCX Grains Index - Total Return',
    symbol: 'GRU',
  },
  {
    value: 'GSEU',
    label: 'GSEU,  Goldman Sachs ActiveBeta Europe Equity ETF',
    name: 'Goldman Sachs ActiveBeta Europe Equity ETF',
    symbol: 'GSEU',
  },
  {
    value: 'GSEW',
    label: 'GSEW,  Goldman Sachs Equal Weight U.S. Large Cap Equity ETF',
    name: 'Goldman Sachs Equal Weight U.S. Large Cap Equity ETF',
    symbol: 'GSEW',
  },
  {
    value: 'GSIT',
    label: 'GSIT,  GSI Technology, Inc.',
    name: 'GSI Technology, Inc.',
    symbol: 'GSIT',
  },
  {
    value: 'GSJY',
    label: 'GSJY,  Goldman Sachs ActiveBeta Japan Equity ETF',
    name: 'Goldman Sachs ActiveBeta Japan Equity ETF',
    symbol: 'GSJY',
  },
  {
    value: 'GSP',
    label: 'GSP,  iPath S&P GSCI Total Return Index ETN',
    name: 'iPath S&P GSCI Total Return Index ETN',
    symbol: 'GSP',
  },
  {
    value: 'GSSC',
    label: 'GSSC,  Goldman Sachs ActiveBeta U.S. Small Cap Equity ETF',
    name: 'Goldman Sachs ActiveBeta U.S. Small Cap Equity ETF',
    symbol: 'GSSC',
  },
  {
    value: 'GTIM',
    label: 'GTIM,  Good Times Restaurants Inc.',
    name: 'Good Times Restaurants Inc.',
    symbol: 'GTIM',
  },
  {
    value: 'GTO',
    label: 'GTO,  Invesco Total Return Bond ETF',
    name: 'Invesco Total Return Bond ETF',
    symbol: 'GTO',
  },
  {
    value: 'GVIP',
    label: 'GVIP,  Goldman Sachs Hedge Industry VIP ETF',
    name: 'Goldman Sachs Hedge Industry VIP ETF',
    symbol: 'GVIP',
  },
  {
    value: 'GVP',
    label: 'GVP,  GSE Systems, Inc.',
    name: 'GSE Systems, Inc.',
    symbol: 'GVP',
  },
  {
    value: 'GYLD',
    label: 'GYLD,  Arrow Dow Jones Global Yield ETF',
    name: 'Arrow Dow Jones Global Yield ETF',
    symbol: 'GYLD',
  },
  {
    value: 'GYRO',
    label: 'GYRO,  Gyrodyne, LLC',
    name: 'Gyrodyne, LLC',
    symbol: 'GYRO',
  },
  {
    value: 'HAP',
    label: 'HAP,  VanEck Natural Resources ETF',
    name: 'VanEck Natural Resources ETF',
    symbol: 'HAP',
  },
  {
    value: 'HBCP',
    label: 'HBCP,  Home Bancorp, Inc.',
    name: 'Home Bancorp, Inc.',
    symbol: 'HBCP',
  },
  {
    value: 'HDAW',
    label:
      'HDAW,  Xtrackers MSCI All World ex US High Dividend Yield Hedged Equity ETF',
    name: 'Xtrackers MSCI All World ex US High Dividend Yield Hedged Equity ETF',
    symbol: 'HDAW',
  },
  {
    value: 'HDEF',
    label: 'HDEF,  Xtrackers MSCI EAFE High Dividend Yield Equity ETF',
    name: 'Xtrackers MSCI EAFE High Dividend Yield Equity ETF',
    symbol: 'HDEF',
  },
  {
    value: 'HEEM',
    label: 'HEEM,  iShares Currency Hedged MSCI Emerging Markets ETF',
    name: 'iShares Currency Hedged MSCI Emerging Markets ETF',
    symbol: 'HEEM',
  },
  {
    value: 'HEWC',
    label: 'HEWC,  iShares Currency Hedged MSCI Canada ETF',
    name: 'iShares Currency Hedged MSCI Canada ETF',
    symbol: 'HEWC',
  },
  {
    value: 'HEWU',
    label: 'HEWU,  iShares Currency Hedged MSCI United Kingdom ETF',
    name: 'iShares Currency Hedged MSCI United Kingdom ETF',
    symbol: 'HEWU',
  },
  {
    value: 'HFBL',
    label: 'HFBL,  Home Federal Bancorp, Inc. of Louisiana',
    name: 'Home Federal Bancorp, Inc. of Louisiana',
    symbol: 'HFBL',
  },
  {
    value: 'HFXI',
    label: 'HFXI,  IQ FTSE International Equity Currency Neutral ETF',
    name: 'IQ FTSE International Equity Currency Neutral ETF',
    symbol: 'HFXI',
  },
  {
    value: 'HIE',
    label: 'HIE,  Miller/Howard High Income Equity Fund',
    name: 'Miller/Howard High Income Equity Fund',
    symbol: 'HIE',
  },
  {
    value: 'HIHO',
    label: 'HIHO,  Highway Holdings Limited',
    name: 'Highway Holdings Limited',
    symbol: 'HIHO',
  },
  {
    value: 'HMNF',
    label: 'HMNF,  HMN Financial, Inc.',
    name: 'HMN Financial, Inc.',
    symbol: 'HMNF',
  },
  {
    value: 'HMOP',
    label: 'HMOP,  Hartford Municipal Opportunities ETF',
    name: 'Hartford Municipal Opportunities ETF',
    symbol: 'HMOP',
  },
  {
    value: 'HNDL',
    label: 'HNDL,  Strategy Shares Nasdaq 7 Handl Index ETF',
    name: 'Strategy Shares Nasdaq 7 Handl Index ETF',
    symbol: 'HNDL',
  },
  {
    value: 'HNNA',
    label: 'HNNA,  Hennessy Advisors, Inc.',
    name: 'Hennessy Advisors, Inc.',
    symbol: 'HNNA',
  },
  {
    value: 'HNRG',
    label: 'HNRG,  Hallador Energy Company',
    name: 'Hallador Energy Company',
    symbol: 'HNRG',
  },
  {
    value: 'HOLD',
    label: 'HOLD,  AdvisorShares North Square McKee Core Reserves ETF',
    name: 'AdvisorShares North Square McKee Core Reserves ETF',
    symbol: 'HOLD',
  },
  {
    value: 'HOVNP',
    label: 'HOVNP,  Hovnanian Enterprises, Inc. PFD DEP1/1000A',
    name: 'Hovnanian Enterprises, Inc. PFD DEP1/1000A',
    symbol: 'HOVNP',
  },
  {
    value: 'HPF',
    label: 'HPF,  John Hancock Preferred Income Fund II',
    name: 'John Hancock Preferred Income Fund II',
    symbol: 'HPF',
  },
  {
    value: 'HPI',
    label: 'HPI,  John Hancock Preferred Income Fund',
    name: 'John Hancock Preferred Income Fund',
    symbol: 'HPI',
  },
  {
    value: 'HPS',
    label: 'HPS,  John Hancock Preferred Income Fund III',
    name: 'John Hancock Preferred Income Fund III',
    symbol: 'HPS',
  },
  {
    value: 'HRZN',
    label: 'HRZN,  Horizon Technology Finance Corporation',
    name: 'Horizon Technology Finance Corporation',
    symbol: 'HRZN',
  },
  {
    value: 'HSCZ',
    label: 'HSCZ,  iShares Currency Hedged MSCI EAFE Small-Cap ETF',
    name: 'iShares Currency Hedged MSCI EAFE Small-Cap ETF',
    symbol: 'HSCZ',
  },
  {
    value: 'HTRB',
    label: 'HTRB,  Hartford Total Return Bond ETF',
    name: 'Hartford Total Return Bond ETF',
    symbol: 'HTRB',
  },
  {
    value: 'HTUS',
    label: 'HTUS,  Hull Tactical US ETF',
    name: 'Hull Tactical US ETF',
    symbol: 'HTUS',
  },
  {
    value: 'HTY',
    label:
      'HTY,  John Hancock Investments - John Hancock Tax-Advantaged Global Shareholder Yield Fund',
    name: 'John Hancock Investments - John Hancock Tax-Advantaged Global Shareholder Yield Fund',
    symbol: 'HTY',
  },
  {
    value: 'HURC',
    label: 'HURC,  Hurco Companies, Inc.',
    name: 'Hurco Companies, Inc.',
    symbol: 'HURC',
  },
  {
    value: 'HUSA',
    label: 'HUSA,  Houston American Energy Corp.',
    name: 'Houston American Energy Corp.',
    symbol: 'HUSA',
  },
  {
    value: 'HVBC',
    label: 'HVBC,  HV Bancorp, Inc.',
    name: 'HV Bancorp, Inc.',
    symbol: 'HVBC',
  },
  {
    value: 'HWBK',
    label: 'HWBK,  Hawthorn Bancshares, Inc.',
    name: 'Hawthorn Bancshares, Inc.',
    symbol: 'HWBK',
  },
  {
    value: 'HYB',
    label: 'HYB,  The New America High Income Fund Inc.',
    name: 'The New America High Income Fund Inc.',
    symbol: 'HYB',
  },
  {
    value: 'HYDB',
    label: 'HYDB,  iShares High Yield Bond Factor ETF',
    name: 'iShares High Yield Bond Factor ETF',
    symbol: 'HYDB',
  },
  {
    value: 'HYDW',
    label: 'HYDW,  Xtrackers Low Beta High Yield Bond ETF',
    name: 'Xtrackers Low Beta High Yield Bond ETF',
    symbol: 'HYDW',
  },
  {
    value: 'HYGH',
    label: 'HYGH,  iShares Interest Rate Hedged High Yield Bond ETF',
    name: 'iShares Interest Rate Hedged High Yield Bond ETF',
    symbol: 'HYGH',
  },
  {
    value: 'HYHG',
    label: 'HYHG,  ProShares High Yield—Interest Rate Hedged',
    name: 'ProShares High Yield—Interest Rate Hedged',
    symbol: 'HYHG',
  },
  {
    value: 'HYLS',
    label: 'HYLS,  First Trust Tactical High Yield ETF',
    name: 'First Trust Tactical High Yield ETF',
    symbol: 'HYLS',
  },
  {
    value: 'HYLV',
    label: 'HYLV,  IQ S&P High Yield Low Volatility Bond ETF',
    name: 'IQ S&P High Yield Low Volatility Bond ETF',
    symbol: 'HYLV',
  },
  {
    value: 'HYUP',
    label: 'HYUP,  Xtrackers High Beta High Yield Bond ETF',
    name: 'Xtrackers High Beta High Yield Bond ETF',
    symbol: 'HYUP',
  },
  {
    value: 'HYXU',
    label: 'HYXU,  iShares International High Yield Bond ETF',
    name: 'iShares International High Yield Bond ETF',
    symbol: 'HYXU',
  },
  {
    value: 'IBA',
    label: 'IBA,  Industrias Bachoco, S.A.B. de C.V.',
    name: 'Industrias Bachoco, S.A.B. de C.V.',
    symbol: 'IBA',
  },
  {
    value: 'IBCE',
    label: 'IBCE,  iShares iBonds Mar 2023 Term Corporate ex-Financials ETF',
    name: 'iShares iBonds Mar 2023 Term Corporate ex-Financials ETF',
    symbol: 'IBCE',
  },
  {
    value: 'IBDN',
    label: 'IBDN,  iShares iBonds Dec 2022 Term Corporate ETF',
    name: 'iShares iBonds Dec 2022 Term Corporate ETF',
    symbol: 'IBDN',
  },
  {
    value: 'IBDO',
    label: 'IBDO,  iShares iBonds Dec 2023 Term Corporate ETF',
    name: 'iShares iBonds Dec 2023 Term Corporate ETF',
    symbol: 'IBDO',
  },
  {
    value: 'IBDP',
    label: 'IBDP,  iShares iBonds Dec 2024 Term Corporate ETF',
    name: 'iShares iBonds Dec 2024 Term Corporate ETF',
    symbol: 'IBDP',
  },
  {
    value: 'IBDR',
    label: 'IBDR,  iShares iBonds Dec 2026 Term Corporate ETF',
    name: 'iShares iBonds Dec 2026 Term Corporate ETF',
    symbol: 'IBDR',
  },
  {
    value: 'IBDS',
    label: 'IBDS,  iShares iBonds Dec 2027 Term Corporate ETF',
    name: 'iShares iBonds Dec 2027 Term Corporate ETF',
    symbol: 'IBDS',
  },
  {
    value: 'IBMK',
    label: 'IBMK,  iShares iBonds Dec 2022 Term Muni Bond ETF',
    name: 'iShares iBonds Dec 2022 Term Muni Bond ETF',
    symbol: 'IBMK',
  },
  {
    value: 'IBML',
    label: 'IBML,  iShares iBonds Dec 2023 Term Muni Bond ETF',
    name: 'iShares iBonds Dec 2023 Term Muni Bond ETF',
    symbol: 'IBML',
  },
  {
    value: 'ICCC',
    label: 'ICCC,  ImmuCell Corporation',
    name: 'ImmuCell Corporation',
    symbol: 'ICCC',
  },
  {
    value: 'ICCH',
    label: 'ICCH,  ICC Holdings, Inc.',
    name: 'ICC Holdings, Inc.',
    symbol: 'ICCH',
  },
  {
    value: 'ICOW',
    label: 'ICOW,  Pacer Developed Markets International Cash Cows 100 ETF',
    name: 'Pacer Developed Markets International Cash Cows 100 ETF',
    symbol: 'ICOW',
  },
  {
    value: 'ICSH',
    label: 'ICSH,  iShares Ultra Short-Term Bond ETF',
    name: 'iShares Ultra Short-Term Bond ETF',
    symbol: 'ICSH',
  },
  {
    value: 'ICVT',
    label: 'ICVT,  iShares Convertible Bond ETF',
    name: 'iShares Convertible Bond ETF',
    symbol: 'ICVT',
  },
  {
    value: 'IDEV',
    label: 'IDEV,  iShares Core MSCI International Developed Markets ETF',
    name: 'iShares Core MSCI International Developed Markets ETF',
    symbol: 'IDEV',
  },
  {
    value: 'IDHD',
    label:
      'IDHD,  Invesco S&P International Developed High Dividend Low Volatility ETF',
    name: 'Invesco S&P International Developed High Dividend Low Volatility ETF',
    symbol: 'IDHD',
  },
  {
    value: 'IDIV',
    label: 'IDIV,  Metaurus U.S. Equity Cumulative Dividends Fund-Series 2027',
    name: 'Metaurus U.S. Equity Cumulative Dividends Fund-Series 2027',
    symbol: 'IDIV',
  },
  {
    value: 'IDLB',
    label: 'IDLB,  Invesco FTSE International Low Beta Equal Weight ETF',
    name: 'Invesco FTSE International Low Beta Equal Weight ETF',
    symbol: 'IDLB',
  },
  {
    value: 'IDMO',
    label: 'IDMO,  Invesco S&P International Developed Momentum ETF',
    name: 'Invesco S&P International Developed Momentum ETF',
    symbol: 'IDMO',
  },
  {
    value: 'IDU',
    label: 'IDU,  iShares U.S. Utilities ETF',
    name: 'iShares U.S. Utilities ETF',
    symbol: 'IDU',
  },
  {
    value: 'IDXG',
    label: 'IDXG,  Interpace Biosciences, Inc.',
    name: 'Interpace Biosciences, Inc.',
    symbol: 'IDXG',
  },
  {
    value: 'IEO',
    label: 'IEO,  iShares U.S. Oil & Gas Exploration & Production ETF',
    name: 'iShares U.S. Oil & Gas Exploration & Production ETF',
    symbol: 'IEO',
  },
  {
    value: 'IFV',
    label: 'IFV,  First Trust Dorsey Wright International Focus 5 ETF',
    name: 'First Trust Dorsey Wright International Focus 5 ETF',
    symbol: 'IFV',
  },
  {
    value: 'IGC',
    label: 'IGC,  India Globalization Capital, Inc.',
    name: 'India Globalization Capital, Inc.',
    symbol: 'IGC',
  },
  {
    value: 'IGEB',
    label: 'IGEB,  iShares Investment Grade Bond Factor ETF',
    name: 'iShares Investment Grade Bond Factor ETF',
    symbol: 'IGEB',
  },
  {
    value: 'IGLD',
    label: 'IGLD,  FT Cboe Vest Gold Target Income ETF',
    name: 'FT Cboe Vest Gold Target Income ETF',
    symbol: 'IGLD',
  },
  {
    value: 'IGM',
    label: 'IGM,  iShares Expanded Tech Sector ETF',
    name: 'iShares Expanded Tech Sector ETF',
    symbol: 'IGM',
  },
  {
    value: 'IGRO',
    label: 'IGRO,  iShares International Dividend Growth ETF',
    name: 'iShares International Dividend Growth ETF',
    symbol: 'IGRO',
  },
  {
    value: 'IHE',
    label: 'IHE,  iShares U.S. Pharmaceuticals ETF',
    name: 'iShares U.S. Pharmaceuticals ETF',
    symbol: 'IHE',
  },
  {
    value: 'IHF',
    label: 'IHF,  iShares U.S. Healthcare Providers ETF',
    name: 'iShares U.S. Healthcare Providers ETF',
    symbol: 'IHF',
  },
  {
    value: 'IHIT',
    label: 'IHIT,  Invesco High Income 2023 Target Term Fund',
    name: 'Invesco High Income 2023 Target Term Fund',
    symbol: 'IHIT',
  },
  {
    value: 'IHT',
    label: 'IHT,  InnSuites Hospitality Trust',
    name: 'InnSuites Hospitality Trust',
    symbol: 'IHT',
  },
  {
    value: 'IHTA',
    label: 'IHTA,  Invesco High Income 2024 Target Term Fund',
    name: 'Invesco High Income 2024 Target Term Fund',
    symbol: 'IHTA',
  },
  {
    value: 'IHY',
    label: 'IHY,  VanEck International High Yield Bond ETF',
    name: 'VanEck International High Yield Bond ETF',
    symbol: 'IHY',
  },
  {
    value: 'IMLP',
    label: 'IMLP,  iPath S&P MLP ETN',
    name: 'iPath S&P MLP ETN',
    symbol: 'IMLP',
  },
  {
    value: 'IMMP',
    label: 'IMMP,  Immutep Limited',
    name: 'Immutep Limited',
    symbol: 'IMMP',
  },
  {
    value: 'IMOM',
    label: 'IMOM,  Alpha Architect International Quantitative Momentum ETF',
    name: 'Alpha Architect International Quantitative Momentum ETF',
    symbol: 'IMOM',
  },
  {
    value: 'IMRN',
    label: 'IMRN,  Immuron Limited',
    name: 'Immuron Limited',
    symbol: 'IMRN',
  },
  {
    value: 'IMTB',
    label: 'IMTB,  iShares Core 5-10 Year USD Bond ETF',
    name: 'iShares Core 5-10 Year USD Bond ETF',
    symbol: 'IMTB',
  },
  {
    value: 'IMTE',
    label: 'IMTE,  Integrated Media Technology Limited',
    name: 'Integrated Media Technology Limited',
    symbol: 'IMTE',
  },
  {
    value: 'INDF',
    label: 'INDF,  Nifty India Financials ETF',
    name: 'Nifty India Financials ETF',
    symbol: 'INDF',
  },
  {
    value: 'INKM',
    label: 'INKM,  SPDR SSgA Income Allocation ETF',
    name: 'SPDR SSgA Income Allocation ETF',
    symbol: 'INKM',
  },
  {
    value: 'INOD',
    label: 'INOD,  Innodata Inc.',
    name: 'Innodata Inc.',
    symbol: 'INOD',
  },
  {
    value: 'INSE',
    label: 'INSE,  Inspired Entertainment, Inc.',
    name: 'Inspired Entertainment, Inc.',
    symbol: 'INSE',
  },
  {
    value: 'INSI',
    label: 'INSI,  Insight Select Income Fund',
    name: 'Insight Select Income Fund',
    symbol: 'INSI',
  },
  {
    value: 'INTF',
    label: 'INTF,  iShares International Equity Factor ETF',
    name: 'iShares International Equity Factor ETF',
    symbol: 'INTF',
  },
  {
    value: 'INTG',
    label: 'INTG,  The InterGroup Corporation',
    name: 'The InterGroup Corporation',
    symbol: 'INTG',
  },
  {
    value: 'INVE',
    label: 'INVE,  Identiv, Inc.',
    name: 'Identiv, Inc.',
    symbol: 'INVE',
  },
  {
    value: 'IPB',
    label:
      'IPB,  Merrill Lynch Depositor Inc. 6.0518% Index Plus Trust Series 2003-1',
    name: 'Merrill Lynch Depositor Inc. 6.0518% Index Plus Trust Series 2003-1',
    symbol: 'IPB',
  },
  {
    value: 'IPIC',
    label: 'IPIC,  iPic Entertainment Inc.',
    name: 'iPic Entertainment Inc.',
    symbol: 'IPIC',
  },
  {
    value: 'IPO',
    label: 'IPO,  Renaissance IPO ETF',
    name: 'Renaissance IPO ETF',
    symbol: 'IPO',
  },
  {
    value: 'IPOS',
    label: 'IPOS,  Renaissance International IPO ETF',
    name: 'Renaissance International IPO ETF',
    symbol: 'IPOS',
  },
  {
    value: 'IPWR',
    label: 'IPWR,  Ideal Power Inc.',
    name: 'Ideal Power Inc.',
    symbol: 'IPWR',
  },
  {
    value: 'IQDE',
    label:
      'IQDE,  FlexShares International Quality Dividend Defensive Index Fund',
    name: 'FlexShares International Quality Dividend Defensive Index Fund',
    symbol: 'IQDE',
  },
  {
    value: 'IQDG',
    label: 'IQDG,  WisdomTree International Quality Dividend Growth Fund',
    name: 'WisdomTree International Quality Dividend Growth Fund',
    symbol: 'IQDG',
  },
  {
    value: 'IQDY',
    label:
      'IQDY,  FlexShares International Quality Dividend Dynamic Index Fund',
    name: 'FlexShares International Quality Dividend Dynamic Index Fund',
    symbol: 'IQDY',
  },
  {
    value: 'IQLT',
    label: 'IQLT,  iShares MSCI Intl Quality Factor ETF',
    name: 'iShares MSCI Intl Quality Factor ETF',
    symbol: 'IQLT',
  },
  {
    value: 'IROQ',
    label: 'IROQ,  IF Bancorp, Inc.',
    name: 'IF Bancorp, Inc.',
    symbol: 'IROQ',
  },
  {
    value: 'ISCF',
    label: 'ISCF,  iShares MSCI Intl Small-Cap Multifactor ETF',
    name: 'iShares MSCI Intl Small-Cap Multifactor ETF',
    symbol: 'ISCF',
  },
  {
    value: 'ISDR',
    label: 'ISDR,  Issuer Direct Corporation',
    name: 'Issuer Direct Corporation',
    symbol: 'ISDR',
  },
  {
    value: 'ISHG',
    label: 'ISHG,  iShares 1-3 Year International Treasury Bond ETF',
    name: 'iShares 1-3 Year International Treasury Bond ETF',
    symbol: 'ISHG',
  },
  {
    value: 'ISIG',
    label: 'ISIG,  Insignia Systems, Inc.',
    name: 'Insignia Systems, Inc.',
    symbol: 'ISIG',
  },
  {
    value: 'ISMD',
    label: 'ISMD,  Inspire Small/Mid Cap ETF',
    name: 'Inspire Small/Mid Cap ETF',
    symbol: 'ISMD',
  },
  {
    value: 'ISR',
    label: 'ISR,  Isoray, Inc.',
    name: 'Isoray, Inc.',
    symbol: 'ISR',
  },
  {
    value: 'ISRA',
    label: 'ISRA,  VanEck Israel ETF',
    name: 'VanEck Israel ETF',
    symbol: 'ISRA',
  },
  {
    value: 'ISSC',
    label: 'ISSC,  Innovative Solutions and Support, Inc.',
    name: 'Innovative Solutions and Support, Inc.',
    symbol: 'ISSC',
  },
  {
    value: 'ISZE',
    label: 'ISZE,  iShares MSCI Intl Size Factor ETF',
    name: 'iShares MSCI Intl Size Factor ETF',
    symbol: 'ISZE',
  },
  {
    value: 'ITCB',
    label: 'ITCB,  Itaú Corpbanca',
    name: 'Itaú Corpbanca',
    symbol: 'ITCB',
  },
  {
    value: 'ITEQ',
    label: 'ITEQ,  BlueStar Israel Technology ETF',
    name: 'BlueStar Israel Technology ETF',
    symbol: 'ITEQ',
  },
  {
    value: 'IUSB',
    label: 'IUSB,  iShares Core Total USD Bond Market ETF',
    name: 'iShares Core Total USD Bond Market ETF',
    symbol: 'IUSB',
  },
  {
    value: 'IVAL',
    label: 'IVAL,  Alpha Architect International Quantitative Value ETF',
    name: 'Alpha Architect International Quantitative Value ETF',
    symbol: 'IVAL',
  },
  {
    value: 'IVLU',
    label: 'IVLU,  iShares Edge MSCI Intl Value Factor ETF',
    name: 'iShares Edge MSCI Intl Value Factor ETF',
    symbol: 'IVLU',
  },
  {
    value: 'IVOG',
    label: 'IVOG,  Vanguard S&P Mid-Cap 400 Growth Index Fund',
    name: 'Vanguard S&P Mid-Cap 400 Growth Index Fund',
    symbol: 'IVOG',
  },
  {
    value: 'IVOO',
    label: 'IVOO,  Vanguard S&P Mid-Cap 400 Index Fund',
    name: 'Vanguard S&P Mid-Cap 400 Index Fund',
    symbol: 'IVOO',
  },
  {
    value: 'IVOV',
    label: 'IVOV,  Vanguard S&P Mid-Cap 400 Value Index Fund',
    name: 'Vanguard S&P Mid-Cap 400 Value Index Fund',
    symbol: 'IVOV',
  },
  {
    value: 'IXJ',
    label: 'IXJ,  iShares Global Healthcare ETF',
    name: 'iShares Global Healthcare ETF',
    symbol: 'IXJ',
  },
  {
    value: 'IXP',
    label: 'IXP,  iShares Global Comm Services ETF',
    name: 'iShares Global Comm Services ETF',
    symbol: 'IXP',
  },
  {
    value: 'IYC',
    label: 'IYC,  iShares US Consumer Discretionary ETF',
    name: 'iShares US Consumer Discretionary ETF',
    symbol: 'IYC',
  },
  {
    value: 'IYG',
    label: 'IYG,  iShares U.S. Financial Services ETF',
    name: 'iShares U.S. Financial Services ETF',
    symbol: 'IYG',
  },
  {
    value: 'IYH',
    label: 'IYH,  iShares U.S. Healthcare ETF',
    name: 'iShares U.S. Healthcare ETF',
    symbol: 'IYH',
  },
  {
    value: 'IYLD',
    label: 'IYLD,  iShares Morningstar Multi-Asset Income ETF',
    name: 'iShares Morningstar Multi-Asset Income ETF',
    symbol: 'IYLD',
  },
  {
    value: 'IYY',
    label: 'IYY,  iShares Dow Jones U.S. ETF',
    name: 'iShares Dow Jones U.S. ETF',
    symbol: 'IYY',
  },
  {
    value: 'IZEA',
    label: 'IZEA,  IZEA Worldwide, Inc.',
    name: 'IZEA Worldwide, Inc.',
    symbol: 'IZEA',
  },
  {
    value: 'JBK',
    label:
      'JBK,  Corporate Backed Trust Certificates, Goldman Sachs Capital I Securities-Backed Series 2004-6 04-6 A1 3.50',
    name: 'Corporate Backed Trust Certificates, Goldman Sachs Capital I Securities-Backed Series 2004-6 04-6 A1 3.50',
    symbol: 'JBK',
  },
  {
    value: 'JCTCF',
    label: 'JCTCF,  Jewett-Cameron Trading Company Ltd.',
    name: 'Jewett-Cameron Trading Company Ltd.',
    symbol: 'JCTCF',
  },
  {
    value: 'JEMD',
    label: 'JEMD,  Nuveen Emerging Markets Debt 2022 Target Term Fund',
    name: 'Nuveen Emerging Markets Debt 2022 Target Term Fund',
    symbol: 'JEMD',
  },
  {
    value: 'JEQ',
    label: 'JEQ,  Abrdn Japan Equity Fund Inc',
    name: 'Abrdn Japan Equity Fund Inc',
    symbol: 'JEQ',
  },
  {
    value: 'JHMA',
    label: 'JHMA,  John Hancock Multifactor Materials ETF',
    name: 'John Hancock Multifactor Materials ETF',
    symbol: 'JHMA',
  },
  {
    value: 'JHMC',
    label: 'JHMC,  John Hancock Multifactor Consumer Discretionary ETF',
    name: 'John Hancock Multifactor Consumer Discretionary ETF',
    symbol: 'JHMC',
  },
  {
    value: 'JHMD',
    label: 'JHMD,  John Hancock Multifactor Developed International ETF',
    name: 'John Hancock Multifactor Developed International ETF',
    symbol: 'JHMD',
  },
  {
    value: 'JHME',
    label: 'JHME,  John Hancock Multifactor Energy ETF',
    name: 'John Hancock Multifactor Energy ETF',
    symbol: 'JHME',
  },
  {
    value: 'JHMF',
    label: 'JHMF,  John Hancock Multifactor Financials ETF',
    name: 'John Hancock Multifactor Financials ETF',
    symbol: 'JHMF',
  },
  {
    value: 'JHMH',
    label: 'JHMH,  John Hancock Multifactor Health Care ETF',
    name: 'John Hancock Multifactor Health Care ETF',
    symbol: 'JHMH',
  },
  {
    value: 'JHMI',
    label: 'JHMI,  John Hancock Multifactor Industrials ETF',
    name: 'John Hancock Multifactor Industrials ETF',
    symbol: 'JHMI',
  },
  {
    value: 'JHML',
    label: 'JHML,  John Hancock Multifactor Large Cap ETF',
    name: 'John Hancock Multifactor Large Cap ETF',
    symbol: 'JHML',
  },
  {
    value: 'JHMS',
    label: 'JHMS,  John Hancock Multifactor Consumer Staples ETF',
    name: 'John Hancock Multifactor Consumer Staples ETF',
    symbol: 'JHMS',
  },
  {
    value: 'JHMU',
    label: 'JHMU,  John Hancock Multifactor Utilities ETF',
    name: 'John Hancock Multifactor Utilities ETF',
    symbol: 'JHMU',
  },
  {
    value: 'JHS',
    label: 'JHS,  John Hancock Income Securities Trust',
    name: 'John Hancock Income Securities Trust',
    symbol: 'JHS',
  },
  {
    value: 'JHSC',
    label: 'JHSC,  John Hancock Multifactor Small Cap ETF',
    name: 'John Hancock Multifactor Small Cap ETF',
    symbol: 'JHSC',
  },
  {
    value: 'JHX',
    label: 'JHX,  James Hardie Industries plc',
    name: 'James Hardie Industries plc',
    symbol: 'JHX',
  },
  {
    value: 'JJA',
    label:
      'JJA,  iPath Series B Bloomberg Agriculture Subindex Total Return ETN',
    name: 'iPath Series B Bloomberg Agriculture Subindex Total Return ETN',
    symbol: 'JJA',
  },
  {
    value: 'JJE',
    label: 'JJE,  iPath Series B Bloomberg Energy Subindex Total Return ETN',
    name: 'iPath Series B Bloomberg Energy Subindex Total Return ETN',
    symbol: 'JJE',
  },
  {
    value: 'JJM',
    label:
      'JJM,  iPath Series B Bloomberg Industrial Metals Subindex Total Return ETN',
    name: 'iPath Series B Bloomberg Industrial Metals Subindex Total Return ETN',
    symbol: 'JJM',
  },
  {
    value: 'JJP',
    label:
      'JJP,  iPath Series B Bloomberg Precious Metals Subindex Total Return ETN',
    name: 'iPath Series B Bloomberg Precious Metals Subindex Total Return ETN',
    symbol: 'JJP',
  },
  {
    value: 'JJS',
    label: 'JJS,  iPath Series B Bloomberg Softs Subindex Total Return ETN',
    name: 'iPath Series B Bloomberg Softs Subindex Total Return ETN',
    symbol: 'JJS',
  },
  {
    value: 'JJT',
    label: 'JJT,  iPath Series B Bloomberg Tin Subindex Total Return ETN',
    name: 'iPath Series B Bloomberg Tin Subindex Total Return ETN',
    symbol: 'JJT',
  },
  {
    value: 'ILCV',
    label: 'ILCV,  iShares Morningstar Value ETF',
    name: 'iShares Morningstar Value ETF',
    symbol: 'ILCV',
  },
  {
    value: 'IMCB',
    label: 'IMCB,  iShares Morningstar Mid-Cap ETF',
    name: 'iShares Morningstar Mid-Cap ETF',
    symbol: 'IMCB',
  },
  {
    value: 'IMCG',
    label: 'IMCG,  iShares Morningstar Mid-Cap Growth ETF',
    name: 'iShares Morningstar Mid-Cap Growth ETF',
    symbol: 'IMCG',
  },
  {
    value: 'IMCV',
    label: 'IMCV,  iShares Morningstar Mid-Cap Value ETF',
    name: 'iShares Morningstar Mid-Cap Value ETF',
    symbol: 'IMCV',
  },
  {
    value: 'ISCB',
    label: 'ISCB,  iShares Morningstar Small-Cap ETF',
    name: 'iShares Morningstar Small-Cap ETF',
    symbol: 'ISCB',
  },
  {
    value: 'ISCG',
    label: 'ISCG,  iShares Morningstar Small-Cap Growth ETF',
    name: 'iShares Morningstar Small-Cap Growth ETF',
    symbol: 'ISCG',
  },
  {
    value: 'ISCV',
    label: 'ISCV,  iShares Morningstar Small-Cap Value ETF',
    name: 'iShares Morningstar Small-Cap Value ETF',
    symbol: 'ISCV',
  },
  {
    value: 'JMM',
    label: 'JMM,  Nuveen Multi-Market Income Fund',
    name: 'Nuveen Multi-Market Income Fund',
    symbol: 'JMM',
  },
  {
    value: 'JMOM',
    label: 'JMOM,  JPMorgan U.S. Momentum Factor ETF',
    name: 'JPMorgan U.S. Momentum Factor ETF',
    symbol: 'JMOM',
  },
  {
    value: 'JOF',
    label: 'JOF,  Japan Smaller Capitalization Fund, Inc.',
    name: 'Japan Smaller Capitalization Fund, Inc.',
    symbol: 'JOF',
  },
  {
    value: 'JPHY',
    label: 'JPHY,  JPMorgan High Yield Research Enhanced ETF',
    name: 'JPMorgan High Yield Research Enhanced ETF',
    symbol: 'JPHY',
  },
  {
    value: 'JPI',
    label: 'JPI,  Nuveen Preferred and Income Term Fund',
    name: 'Nuveen Preferred and Income Term Fund',
    symbol: 'JPI',
  },
  {
    value: 'JPMB',
    label: 'JPMB,  JPMorgan USD Emerging Markets Sovereign Bond ETF',
    name: 'JPMorgan USD Emerging Markets Sovereign Bond ETF',
    symbol: 'JPMB',
  },
  {
    value: 'JPME',
    label: 'JPME,  JPMorgan Diversified Return U.S. Mid Cap Equity ETF',
    name: 'JPMorgan Diversified Return U.S. Mid Cap Equity ETF',
    symbol: 'JPME',
  },
  {
    value: 'JPSE',
    label: 'JPSE,  JPMorgan Diversified Return U.S. Small Cap Equity ETF',
    name: 'JPMorgan Diversified Return U.S. Small Cap Equity ETF',
    symbol: 'JPSE',
  },
  {
    value: 'JPST',
    label: 'JPST,  JPMorgan Ultra-Short Income ETF',
    name: 'JPMorgan Ultra-Short Income ETF',
    symbol: 'JPST',
  },
  {
    value: 'JQUA',
    label: 'JQUA,  JPMorgan U.S. Quality Factor ETF',
    name: 'JPMorgan U.S. Quality Factor ETF',
    symbol: 'JQUA',
  },
  {
    value: 'JSD',
    label: 'JSD,  Nuveen Short Duration Credit Opportunities Fund',
    name: 'Nuveen Short Duration Credit Opportunities Fund',
    symbol: 'JSD',
  },
  {
    value: 'JSM',
    label: 'JSM,  Navient Corporation SR NT 6% 121543',
    name: 'Navient Corporation SR NT 6% 121543',
    symbol: 'JSM',
  },
  {
    value: 'JSMD',
    label: 'JSMD,  Janus Henderson Small/Mid Cap Growth Alpha ETF',
    name: 'Janus Henderson Small/Mid Cap Growth Alpha ETF',
    symbol: 'JSMD',
  },
  {
    value: 'JSML',
    label: 'JSML,  Janus Henderson Small Cap Growth Alpha ETF',
    name: 'Janus Henderson Small Cap Growth Alpha ETF',
    symbol: 'JSML',
  },
  {
    value: 'JVA',
    label: 'JVA,  Coffee Holding Co., Inc.',
    name: 'Coffee Holding Co., Inc.',
    symbol: 'JVA',
  },
  {
    value: 'JVAL',
    label: 'JVAL,  JPMorgan U.S. Value Factor ETF',
    name: 'JPMorgan U.S. Value Factor ETF',
    symbol: 'JVAL',
  },
  {
    value: 'KARS',
    label: 'KARS,  KraneShares Electric Vehicles and Future Mobility Index ETF',
    name: 'KraneShares Electric Vehicles and Future Mobility Index ETF',
    symbol: 'KARS',
  },
  {
    value: 'KBA',
    label: 'KBA,  KraneShares Bosera MSCI China A 50 Connect Index ETF',
    name: 'KraneShares Bosera MSCI China A 50 Connect Index ETF',
    symbol: 'KBA',
  },
  {
    value: 'KBWP',
    label: 'KBWP,  Invesco KBW Property & Casualty Insurance ETF',
    name: 'Invesco KBW Property & Casualty Insurance ETF',
    symbol: 'KBWP',
  },
  {
    value: 'KBWR',
    label: 'KBWR,  Invesco KBW Regional Banking ETF',
    name: 'Invesco KBW Regional Banking ETF',
    symbol: 'KBWR',
  },
  {
    value: 'KELYB',
    label: 'KELYB,  Kelly Services, Inc.',
    name: 'Kelly Services, Inc.',
    symbol: 'KELYB',
  },
  {
    value: 'KFFB',
    label: 'KFFB,  Kentucky First Federal Bancorp',
    name: 'Kentucky First Federal Bancorp',
    symbol: 'KFFB',
  },
  {
    value: 'KFS',
    label: 'KFS,  Kingsway Financial Services Inc.',
    name: 'Kingsway Financial Services Inc.',
    symbol: 'KFS',
  },
  {
    value: 'KFYP',
    label: 'KFYP,  KraneShares CICC China Leaders 100 Index ETF',
    name: 'KraneShares CICC China Leaders 100 Index ETF',
    symbol: 'KFYP',
  },
  {
    value: 'KGJI',
    label: 'KGJI,  Kingold Jewelry, Inc.',
    name: 'Kingold Jewelry, Inc.',
    symbol: 'KGJI',
  },
  {
    value: 'KGRN',
    label: 'KGRN,  KraneShares MSCI China Clean Technology ETF',
    name: 'KraneShares MSCI China Clean Technology ETF',
    symbol: 'KGRN',
  },
  {
    value: 'KIQ',
    label: 'KIQ,  Kelso Technologies Inc.',
    name: 'Kelso Technologies Inc.',
    symbol: 'KIQ',
  },
  {
    value: 'KMDA',
    label: 'KMDA,  Kamada Ltd.',
    name: 'Kamada Ltd.',
    symbol: 'KMDA',
  },
  {
    value: 'KOLD',
    label: 'KOLD,  ProShares UltraShort Bloomberg Natural Gas',
    name: 'ProShares UltraShort Bloomberg Natural Gas',
    symbol: 'KOLD',
  },
  {
    value: 'KORP',
    label: 'KORP,  American Century Diversified Corporate Bond ETF',
    name: 'American Century Diversified Corporate Bond ETF',
    symbol: 'KORP',
  },
  {
    value: 'KOSS',
    label: 'KOSS,  Koss Corporation',
    name: 'Koss Corporation',
    symbol: 'KOSS',
  },
  {
    value: 'KRP',
    label: 'KRP,  Kimbell Royalty Partners, LP',
    name: 'Kimbell Royalty Partners, LP',
    symbol: 'KRP',
  },
  {
    value: 'KTCC',
    label: 'KTCC,  Key Tronic Corporation',
    name: 'Key Tronic Corporation',
    symbol: 'KTCC',
  },
  {
    value: 'KTH',
    label: 'KTH,  Corts Trust Peco Energy Capital Trust III',
    name: 'Corts Trust Peco Energy Capital Trust III',
    symbol: 'KTH',
  },
  {
    value: 'KTN',
    label: 'KTN,  Credit-Enhanced Corts Trust Aon',
    name: 'Credit-Enhanced Corts Trust Aon',
    symbol: 'KTN',
  },
  {
    value: 'KURE',
    label: 'KURE,  KraneShares MSCI All China Health Care Index ETF',
    name: 'KraneShares MSCI All China Health Care Index ETF',
    symbol: 'KURE',
  },
  {
    value: 'KZIA',
    label: 'KZIA,  Kazia Therapeutics Limited',
    name: 'Kazia Therapeutics Limited',
    symbol: 'KZIA',
  },
  {
    value: 'LAKE',
    label: 'LAKE,  Lakeland Industries, Inc.',
    name: 'Lakeland Industries, Inc.',
    symbol: 'LAKE',
  },
  {
    value: 'LARK',
    label: 'LARK,  Landmark Bancorp, Inc.',
    name: 'Landmark Bancorp, Inc.',
    symbol: 'LARK',
  },
  {
    value: 'LAZY',
    label: 'LAZY,  Lazydays Holdings, Inc.',
    name: 'Lazydays Holdings, Inc.',
    symbol: 'LAZY',
  },
  {
    value: 'LBTYB',
    label: 'LBTYB,  Liberty Global plc',
    name: 'Liberty Global plc',
    symbol: 'LBTYB',
  },
  {
    value: 'LCAHU',
    label: 'LCAHU,  Landcadia Holdings IV, Inc.',
    name: 'Landcadia Holdings IV, Inc.',
    symbol: 'LCAHU',
  },
  {
    value: 'LD',
    label: 'LD,  iPath Bloomberg Lead Subindex Total Return(SM) ETN',
    name: 'iPath Bloomberg Lead Subindex Total Return(SM) ETN',
    symbol: 'LD',
  },
  {
    value: 'LDUR',
    label: 'LDUR,  PIMCO Enhanced Low Duration Active Exchange-Traded Fund',
    name: 'PIMCO Enhanced Low Duration Active Exchange-Traded Fund',
    symbol: 'LDUR',
  },
  {
    value: 'LEAD',
    label: 'LEAD,  Siren DIVCON Leaders Dividend ETF',
    name: 'Siren DIVCON Leaders Dividend ETF',
    symbol: 'LEAD',
  },
  {
    value: 'LEDS',
    label: 'LEDS,  SemiLEDs Corporation',
    name: 'SemiLEDs Corporation',
    symbol: 'LEDS',
  },
  {
    value: 'LEGR',
    label: 'LEGR,  First Trust Indxx Innovative Transaction & Process ETF',
    name: 'First Trust Indxx Innovative Transaction & Process ETF',
    symbol: 'LEGR',
  },
  {
    value: 'LEU',
    label: 'LEU,  Centrus Energy Corp.',
    name: 'Centrus Energy Corp.',
    symbol: 'LEU',
  },
  {
    value: 'LFEQ',
    label: 'LFEQ,  VanEck Long/FlatTrend ETF',
    name: 'VanEck Long/FlatTrend ETF',
    symbol: 'LFEQ',
  },
  {
    value: 'LGL',
    label: 'LGL,  The LGL Group, Inc.',
    name: 'The LGL Group, Inc.',
    symbol: 'LGL',
  },
  {
    value: 'LGLV',
    label: 'LGLV,  SPDR SSGA US Large Cap Low Volatility Index ETF',
    name: 'SPDR SSGA US Large Cap Low Volatility Index ETF',
    symbol: 'LGLV',
  },
  {
    value: 'LINC',
    label: 'LINC,  Lincoln Educational Services Corporation',
    name: 'Lincoln Educational Services Corporation',
    symbol: 'LINC',
  },
  {
    value: 'LINK',
    label: 'LINK,  Interlink Electronics, Inc.',
    name: 'Interlink Electronics, Inc.',
    symbol: 'LINK',
  },
  {
    value: 'LIVE',
    label: 'LIVE,  Live Ventures Incorporated',
    name: 'Live Ventures Incorporated',
    symbol: 'LIVE',
  },
  {
    value: 'LKOR',
    label: 'LKOR,  FlexShares Credit-Scored US Long Corporate Bond Index Fund',
    name: 'FlexShares Credit-Scored US Long Corporate Bond Index Fund',
    symbol: 'LKOR',
  },
  {
    value: 'NEGG',
    label: 'NEGG,  Newegg Commerce, Inc.',
    name: 'Newegg Commerce, Inc.',
    symbol: 'NEGG',
  },
  {
    value: 'LND',
    label: 'LND,  BrasilAgro - Companhia Brasileira de Propriedades Agrícolas',
    name: 'BrasilAgro - Companhia Brasileira de Propriedades Agrícolas',
    symbol: 'LND',
  },
  {
    value: 'AGNG',
    label: 'AGNG,  Global X Aging Population ETF',
    name: 'Global X Aging Population ETF',
    symbol: 'AGNG',
  },
  {
    value: 'LOAN',
    label: 'LOAN,  Manhattan Bridge Capital, Inc.',
    name: 'Manhattan Bridge Capital, Inc.',
    symbol: 'LOAN',
  },
  {
    value: 'LOOP',
    label: 'LOOP,  Loop Industries, Inc.',
    name: 'Loop Industries, Inc.',
    symbol: 'LOOP',
  },
  {
    value: 'LQDH',
    label: 'LQDH,  iShares Interest Rate Hedged Corporate Bond ETF',
    name: 'iShares Interest Rate Hedged Corporate Bond ETF',
    symbol: 'LQDH',
  },
  {
    value: 'LRGE',
    label: 'LRGE,  ClearBridge Large Cap Growth ESG ETF',
    name: 'ClearBridge Large Cap Growth ESG ETF',
    symbol: 'LRGE',
  },
  {
    value: 'LSBK',
    label: 'LSBK,  Lake Shore Bancorp, Inc.',
    name: 'Lake Shore Bancorp, Inc.',
    symbol: 'LSBK',
  },
  {
    value: 'LSST',
    label: 'LSST,  Natixis Loomis Sayles Short Duration Income ETF',
    name: 'Natixis Loomis Sayles Short Duration Income ETF',
    symbol: 'LSST',
  },
  {
    value: 'LSXMB',
    label: 'LSXMB,  The Liberty SiriusXM Group',
    name: 'The Liberty SiriusXM Group',
    symbol: 'LSXMB',
  },
  {
    value: 'LTL',
    label: 'LTL,  ProShares Ultra Telecommunications',
    name: 'ProShares Ultra Telecommunications',
    symbol: 'LTL',
  },
  {
    value: 'LTRPB',
    label: 'LTRPB,  Liberty TripAdvisor Holdings, Inc.',
    name: 'Liberty TripAdvisor Holdings, Inc.',
    symbol: 'LTRPB',
  },
  {
    value: 'LTRX',
    label: 'LTRX,  Lantronix, Inc.',
    name: 'Lantronix, Inc.',
    symbol: 'LTRX',
  },
  {
    value: 'LTSL',
    label: 'LTSL,  Ladenburg Thalmann Financial Services Inc. 6.50% NT 27',
    name: 'Ladenburg Thalmann Financial Services Inc. 6.50% NT 27',
    symbol: 'LTSL',
  },
  {
    value: 'LVHD',
    label: 'LVHD,  Franklin U.S. Low Volatility High Dividend Index ETF',
    name: 'Franklin U.S. Low Volatility High Dividend Index ETF',
    symbol: 'LVHD',
  },
  {
    value: 'LXFR',
    label: 'LXFR,  Luxfer Holdings PLC',
    name: 'Luxfer Holdings PLC',
    symbol: 'LXFR',
  },
  {
    value: 'MAGA',
    label: 'MAGA,  Point Bridge America First ETF',
    name: 'Point Bridge America First ETF',
    symbol: 'MAGA',
  },
  {
    value: 'MANU',
    label: 'MANU,  Manchester United plc',
    name: 'Manchester United plc',
    symbol: 'MANU',
  },
  {
    value: 'MARPS',
    label: 'MARPS,  Marine Petroleum Trust',
    name: 'Marine Petroleum Trust',
    symbol: 'MARPS',
  },
  {
    value: 'MAYS',
    label: 'MAYS,  J.W. Mays, Inc.',
    name: 'J.W. Mays, Inc.',
    symbol: 'MAYS',
  },
  {
    value: 'MBSD',
    label: 'MBSD,  FlexShares Disciplined Duration MBS Index Fund',
    name: 'FlexShares Disciplined Duration MBS Index Fund',
    symbol: 'MBSD',
  },
  {
    value: 'MCI',
    label: 'MCI,  Barings Corporate Investors',
    name: 'Barings Corporate Investors',
    symbol: 'MCI',
  },
  {
    value: 'MCRO',
    label: 'MCRO,  IQ Hedge Macro Tracker ETF',
    name: 'IQ Hedge Macro Tracker ETF',
    symbol: 'MCRO',
  },
  {
    value: 'MEAR',
    label: 'MEAR,  iShares Short Maturity Municipal Bond ETF',
    name: 'iShares Short Maturity Municipal Bond ETF',
    symbol: 'MEAR',
  },
  {
    value: 'MESO',
    label: 'MESO,  Mesoblast Limited',
    name: 'Mesoblast Limited',
    symbol: 'MESO',
  },
  {
    value: 'MEXX',
    label: 'MEXX,  Direxion Daily MSCI Mexico Bull 3X Shares',
    name: 'Direxion Daily MSCI Mexico Bull 3X Shares',
    symbol: 'MEXX',
  },
  {
    value: 'MFD',
    label:
      'MFD,  Macquarie/First Trust Global Infrastructure/Utilities Dividend & Income Fund',
    name: 'Macquarie/First Trust Global Infrastructure/Utilities Dividend & Income Fund',
    symbol: 'MFD',
  },
  {
    value: 'MFDX',
    label: 'MFDX,  PIMCO RAFI Dynamic Multi-Factor International Equity ETF',
    name: 'PIMCO RAFI Dynamic Multi-Factor International Equity ETF',
    symbol: 'MFDX',
  },
  {
    value: 'MFEM',
    label: 'MFEM,  PIMCO RAFI Dynamic Multi-Factor Emerging Markets Equity ETF',
    name: 'PIMCO RAFI Dynamic Multi-Factor Emerging Markets Equity ETF',
    symbol: 'MFEM',
  },
  {
    value: 'MFUS',
    label: 'MFUS,  PIMCO RAFI Dynamic Multi-Factor U.S. Equity ETF',
    name: 'PIMCO RAFI Dynamic Multi-Factor U.S. Equity ETF',
    symbol: 'MFUS',
  },
  {
    value: 'MFV',
    label: 'MFV,  MFS Special Value Trust',
    name: 'MFS Special Value Trust',
    symbol: 'MFV',
  },
  {
    value: 'MGC',
    label: 'MGC,  Vanguard Mega Cap Index Fund',
    name: 'Vanguard Mega Cap Index Fund',
    symbol: 'MGC',
  },
  {
    value: 'MGF',
    label: 'MGF,  MFS Government Markets Income Trust',
    name: 'MFS Government Markets Income Trust',
    symbol: 'MGF',
  },
  {
    value: 'MGYR',
    label: 'MGYR,  Magyar Bancorp, Inc.',
    name: 'Magyar Bancorp, Inc.',
    symbol: 'MGYR',
  },
  {
    value: 'MHD',
    label: 'MHD,  BlackRock MuniHoldings Fund, Inc.',
    name: 'BlackRock MuniHoldings Fund, Inc.',
    symbol: 'MHD',
  },
  {
    value: 'MHF',
    label: 'MHF,  Western Asset Municipal High Income Fund Inc.',
    name: 'Western Asset Municipal High Income Fund Inc.',
    symbol: 'MHF',
  },
  {
    value: 'MHLA',
    label: 'MHLA,  Maiden Holdings, Ltd. 6.625 NT 2046',
    name: 'Maiden Holdings, Ltd. 6.625 NT 2046',
    symbol: 'MHLA',
  },
  {
    value: 'MHN',
    label: 'MHN,  BlackRock MuniHoldings New York Quality Fund, Inc.',
    name: 'BlackRock MuniHoldings New York Quality Fund, Inc.',
    symbol: 'MHN',
  },
  {
    value: 'MHNC',
    label: 'MHNC,  Maiden Holdings North America, Ltd.',
    name: 'Maiden Holdings North America, Ltd.',
    symbol: 'MHNC',
  },
  {
    value: 'MICR',
    label: 'MICR,  Micron Solutions, Inc.',
    name: 'Micron Solutions, Inc.',
    symbol: 'MICR',
  },
  {
    value: 'MIDU',
    label: 'MIDU,  Direxion Daily Mid Cap Bull 3X Shares',
    name: 'Direxion Daily Mid Cap Bull 3X Shares',
    symbol: 'MIDU',
  },
  {
    value: 'MINC',
    label: 'MINC,  AdvisorShares Newfleet Multi-Sector Income ETF',
    name: 'AdvisorShares Newfleet Multi-Sector Income ETF',
    symbol: 'MINC',
  },
  {
    value: 'MINDP',
    label: 'MINDP,  MIND Technology, Inc.',
    name: 'MIND Technology, Inc.',
    symbol: 'MINDP',
  },
  {
    value: 'MIY',
    label: 'MIY,  BlackRock MuniYield Michigan Quality Fund, Inc.',
    name: 'BlackRock MuniYield Michigan Quality Fund, Inc.',
    symbol: 'MIY',
  },
  {
    value: 'MLP',
    label: 'MLP,  Maui Land & Pineapple Company, Inc.',
    name: 'Maui Land & Pineapple Company, Inc.',
    symbol: 'MLP',
  },
  {
    value: 'MLPB',
    label: 'MLPB,  UBS ETRACS Alerian MLP Infrastructure Index ETN Series B',
    name: 'UBS ETRACS Alerian MLP Infrastructure Index ETN Series B',
    symbol: 'MLPB',
  },
  {
    value: 'MLPO',
    label: 'MLPO,  Credit Suisse S&P MLP Index ETN',
    name: 'Credit Suisse S&P MLP Index ETN',
    symbol: 'MLPO',
  },
  {
    value: 'MLSS',
    label: 'MLSS,  Milestone Scientific Inc.',
    name: 'Milestone Scientific Inc.',
    symbol: 'MLSS',
  },
  {
    value: 'MMD',
    label: 'MMD,  MainStay MacKay DefinedTerm Municipal Opportunities Fund',
    name: 'MainStay MacKay DefinedTerm Municipal Opportunities Fund',
    symbol: 'MMD',
  },
  {
    value: 'MMIN',
    label: 'MMIN,  IQ MacKay Municipal Insured ETF',
    name: 'IQ MacKay Municipal Insured ETF',
    symbol: 'MMIN',
  },
  {
    value: 'MMIT',
    label: 'MMIT,  IQ MacKay Municipal Intermediate ETF',
    name: 'IQ MacKay Municipal Intermediate ETF',
    symbol: 'MMIT',
  },
  {
    value: 'MMTM',
    label: 'MMTM,  SPDR S&P 1500 Momentum Tilt ETF',
    name: 'SPDR S&P 1500 Momentum Tilt ETF',
    symbol: 'MMTM',
  },
  {
    value: 'MORT',
    label: 'MORT,  VanEck Mortgage REIT Income ETF',
    name: 'VanEck Mortgage REIT Income ETF',
    symbol: 'MORT',
  },
  {
    value: 'MOXC',
    label: 'MOXC,  Moxian (BVI) Inc',
    name: 'Moxian (BVI) Inc',
    symbol: 'MOXC',
  },
  {
    value: 'MPAC',
    label: 'MPAC,  Model Performance Acquisition Corp.',
    name: 'Model Performance Acquisition Corp.',
    symbol: 'MPAC',
  },
  {
    value: 'MPACU',
    label: 'MPACU,  Model Performance Acquisition Corp.',
    name: 'Model Performance Acquisition Corp.',
    symbol: 'MPACU',
  },
  {
    value: 'MPACW',
    label: 'MPACW,  Model Performance Acquisition Corp.',
    name: 'Model Performance Acquisition Corp.',
    symbol: 'MPACW',
  },
  {
    value: 'MPB',
    label: 'MPB,  Mid Penn Bancorp, Inc.',
    name: 'Mid Penn Bancorp, Inc.',
    symbol: 'MPB',
  },
  {
    value: 'MPV',
    label: 'MPV,  Barings Participation Investors',
    name: 'Barings Participation Investors',
    symbol: 'MPV',
  },
  {
    value: 'MPVDF',
    label: 'MPVDF,  Mountain Province Diamonds Inc.',
    name: 'Mountain Province Diamonds Inc.',
    symbol: 'MPVDF',
  },
  {
    value: 'MRBK',
    label: 'MRBK,  Meridian Corporation',
    name: 'Meridian Corporation',
    symbol: 'MRBK',
  },
  {
    value: 'MRGR',
    label: 'MRGR,  ProShares Merger ETF',
    name: 'ProShares Merger ETF',
    symbol: 'MRGR',
  },
  {
    value: 'MSD',
    label: 'MSD,  Morgan Stanley Emerging Markets Debt Fund, Inc.',
    name: 'Morgan Stanley Emerging Markets Debt Fund, Inc.',
    symbol: 'MSD',
  },
  {
    value: 'MTBCP',
    label: 'MTBCP,  CareCloud, Inc.',
    name: 'CareCloud, Inc.',
    symbol: 'MTBCP',
  },
  {
    value: 'MTEX',
    label: 'MTEX,  Mannatech, Incorporated',
    name: 'Mannatech, Incorporated',
    symbol: 'MTEX',
  },
  {
    value: 'MTP',
    label: 'MTP,  Midatech Pharma plc',
    name: 'Midatech Pharma plc',
    symbol: 'MTP',
  },
  {
    value: 'MUE',
    label: 'MUE,  BlackRock MuniHoldings Quality Fund II, Inc.',
    name: 'BlackRock MuniHoldings Quality Fund II, Inc.',
    symbol: 'MUE',
  },
  {
    value: 'MUNI',
    label:
      'MUNI,  PIMCO Intermediate Municipal Bond Active Exchange-Traded Fund',
    name: 'PIMCO Intermediate Municipal Bond Active Exchange-Traded Fund',
    symbol: 'MUNI',
  },
  {
    value: 'MVBF',
    label: 'MVBF,  MVB Financial Corp.',
    name: 'MVB Financial Corp.',
    symbol: 'MVBF',
  },
  {
    value: 'MVT',
    label: 'MVT,  BlackRock MuniVest Fund II, Inc.',
    name: 'BlackRock MuniVest Fund II, Inc.',
    symbol: 'MVT',
  },
  {
    value: 'MXE',
    label: 'MXE,  Mexico Equity & Income Fund Inc.',
    name: 'Mexico Equity & Income Fund Inc.',
    symbol: 'MXE',
  },
  {
    value: 'MYY',
    label: 'MYY,  ProShares Short MidCap400',
    name: 'ProShares Short MidCap400',
    symbol: 'MYY',
  },
  {
    value: 'MZZ',
    label: 'MZZ,  ProShares UltraShort MidCap400',
    name: 'ProShares UltraShort MidCap400',
    symbol: 'MZZ',
  },
  {
    value: 'NAIL',
    label: 'NAIL,  Direxion Daily Homebuilders & Supplies Bull 3X Shares',
    name: 'Direxion Daily Homebuilders & Supplies Bull 3X Shares',
    symbol: 'NAIL',
  },
  {
    value: 'NANR',
    label: 'NANR,  SPDR S&P North American Natural Resources ETF',
    name: 'SPDR S&P North American Natural Resources ETF',
    symbol: 'NANR',
  },
  {
    value: 'NAOV',
    label: 'NAOV,  NanoVibronix, Inc.',
    name: 'NanoVibronix, Inc.',
    symbol: 'NAOV',
  },
  {
    value: 'NATR',
    label: "NATR,  Nature's Sunshine Products, Inc.",
    name: "Nature's Sunshine Products, Inc.",
    symbol: 'NATR',
  },
  {
    value: 'NAVB',
    label: 'NAVB,  Navidea Biopharmaceuticals, Inc.',
    name: 'Navidea Biopharmaceuticals, Inc.',
    symbol: 'NAVB',
  },
  {
    value: 'NAZ',
    label: 'NAZ,  Nuveen Arizona Quality Municipal Income Fund',
    name: 'Nuveen Arizona Quality Municipal Income Fund',
    symbol: 'NAZ',
  },
  {
    value: 'NBB',
    label: 'NBB,  Nuveen Taxable Municipal Income Fund',
    name: 'Nuveen Taxable Municipal Income Fund',
    symbol: 'NBB',
  },
  {
    value: 'NBH',
    label: 'NBH,  Neuberger Berman Municipal Fund Inc.',
    name: 'Neuberger Berman Municipal Fund Inc.',
    symbol: 'NBH',
  },
  {
    value: 'NBN',
    label: 'NBN,  Northeast Bank',
    name: 'Northeast Bank',
    symbol: 'NBN',
  },
  {
    value: 'NBO',
    label: 'NBO,  Neuberger Berman New York Municipal Fund, Inc.',
    name: 'Neuberger Berman New York Municipal Fund, Inc.',
    symbol: 'NBO',
  },
  {
    value: 'NBY',
    label: 'NBY,  NovaBay Pharmaceuticals, Inc.',
    name: 'NovaBay Pharmaceuticals, Inc.',
    symbol: 'NBY',
  },
  {
    value: 'NCTY',
    label: 'NCTY,  The9 Limited',
    name: 'The9 Limited',
    symbol: 'NCTY',
  },
  {
    value: 'NDRA',
    label: 'NDRA,  ENDRA Life Sciences Inc.',
    name: 'ENDRA Life Sciences Inc.',
    symbol: 'NDRA',
  },
  {
    value: 'NEN',
    label: 'NEN,  New England Realty Associates Limited Partnership',
    name: 'New England Realty Associates Limited Partnership',
    symbol: 'NEN',
  },
  {
    value: 'NESR',
    label: 'NESR,  National Energy Services Reunited Corp.',
    name: 'National Energy Services Reunited Corp.',
    symbol: 'NESR',
  },
  {
    value: 'NESRW',
    label: 'NESRW,  National Energy Services Reunited Corp',
    name: 'National Energy Services Reunited Corp',
    symbol: 'NESRW',
  },
  {
    value: 'NFLT',
    label: 'NFLT,  Virtus Newfleet Multi-Sector Bond ETF',
    name: 'Virtus Newfleet Multi-Sector Bond ETF',
    symbol: 'NFLT',
  },
  {
    value: 'NFRA',
    label: 'NFRA,  FlexShares STOXX Global Broad Infrastructure Index Fund',
    name: 'FlexShares STOXX Global Broad Infrastructure Index Fund',
    symbol: 'NFRA',
  },
  {
    value: 'NGVC',
    label: 'NGVC,  Natural Grocers by Vitamin Cottage, Inc.',
    name: 'Natural Grocers by Vitamin Cottage, Inc.',
    symbol: 'NGVC',
  },
  {
    value: 'NHTC',
    label: 'NHTC,  Natural Health Trends Corp.',
    name: 'Natural Health Trends Corp.',
    symbol: 'NHTC',
  },
  {
    value: 'NICK',
    label: 'NICK,  Nicholas Financial, Inc.',
    name: 'Nicholas Financial, Inc.',
    symbol: 'NICK',
  },
  {
    value: 'NKG',
    label: 'NKG,  Nuveen Georgia Quality Municipal Income Fund',
    name: 'Nuveen Georgia Quality Municipal Income Fund',
    symbol: 'NKG',
  },
  {
    value: 'NKX',
    label: 'NKX,  Nuveen California AMT-Free Quality Municipal Income Fund',
    name: 'Nuveen California AMT-Free Quality Municipal Income Fund',
    symbol: 'NKX',
  },
  {
    value: 'NLR',
    label: 'NLR,  VanEck Uranium+Nuclear Energy ETF',
    name: 'VanEck Uranium+Nuclear Energy ETF',
    symbol: 'NLR',
  },
  {
    value: 'NMI',
    label: 'NMI,  Nuveen Municipal Income Fund, Inc.',
    name: 'Nuveen Municipal Income Fund, Inc.',
    symbol: 'NMI',
  },
  {
    value: 'NMRD',
    label: 'NMRD,  Nemaura Medical Inc.',
    name: 'Nemaura Medical Inc.',
    symbol: 'NMRD',
  },
  {
    value: 'NMS',
    label: 'NMS,  Nuveen Minnesota Quality Municipal Income Fund',
    name: 'Nuveen Minnesota Quality Municipal Income Fund',
    symbol: 'NMS',
  },
  {
    value: 'NMT',
    label: 'NMT,  Nuveen Massachusetts Quality Municipal Income Fund',
    name: 'Nuveen Massachusetts Quality Municipal Income Fund',
    symbol: 'NMT',
  },
  {
    value: 'NNDM',
    label: 'NNDM,  Nano Dimension Ltd.',
    name: 'Nano Dimension Ltd.',
    symbol: 'NNDM',
  },
  {
    value: 'NNVC',
    label: 'NNVC,  NanoViricides, Inc.',
    name: 'NanoViricides, Inc.',
    symbol: 'NNVC',
  },
  {
    value: 'NNY',
    label: 'NNY,  Nuveen New York Municipal Value Fund',
    name: 'Nuveen New York Municipal Value Fund',
    symbol: 'NNY',
  },
  {
    value: 'NOM',
    label: 'NOM,  Nuveen Missouri Quality Municipal Income Fund',
    name: 'Nuveen Missouri Quality Municipal Income Fund',
    symbol: 'NOM',
  },
  {
    value: 'NPV',
    label: 'NPV,  Nuveen Virginia Quality Municipal Income Fund',
    name: 'Nuveen Virginia Quality Municipal Income Fund',
    symbol: 'NPV',
  },
  {
    value: 'NQP',
    label: 'NQP,  Nuveen Pennsylvania Quality Municipal Income Fund',
    name: 'Nuveen Pennsylvania Quality Municipal Income Fund',
    symbol: 'NQP',
  },
  {
    value: 'NRT',
    label: 'NRT,  North European Oil Royalty Trust',
    name: 'North European Oil Royalty Trust',
    symbol: 'NRT',
  },
  {
    value: 'NSS',
    label: 'NSS,  NuStar Logistics, L.P. SB NT FX/FL 43',
    name: 'NuStar Logistics, L.P. SB NT FX/FL 43',
    symbol: 'NSS',
  },
  {
    value: 'NSYS',
    label: 'NSYS,  Nortech Systems Incorporated',
    name: 'Nortech Systems Incorporated',
    symbol: 'NSYS',
  },
  {
    value: 'NTZ',
    label: 'NTZ,  Natuzzi S.p.A.',
    name: 'Natuzzi S.p.A.',
    symbol: 'NTZ',
  },
  {
    value: 'NUAG',
    label: 'NUAG,  Nuveen Enhanced Yield U.S. Aggregate Bond ETF',
    name: 'Nuveen Enhanced Yield U.S. Aggregate Bond ETF',
    symbol: 'NUAG',
  },
  {
    value: 'NUBD',
    label: 'NUBD,  Nuveen ESG U.S. Aggregate Bond ETF',
    name: 'Nuveen ESG U.S. Aggregate Bond ETF',
    symbol: 'NUBD',
  },
  {
    value: 'NULG',
    label: 'NULG,  Nuveen ESG Large-Cap Growth ETF',
    name: 'Nuveen ESG Large-Cap Growth ETF',
    symbol: 'NULG',
  },
  {
    value: 'NULV',
    label: 'NULV,  Nuveen ESG Large-Cap Value ETF',
    name: 'Nuveen ESG Large-Cap Value ETF',
    symbol: 'NULV',
  },
  {
    value: 'NUMG',
    label: 'NUMG,  Nuveen ESG Mid-Cap Growth ETF',
    name: 'Nuveen ESG Mid-Cap Growth ETF',
    symbol: 'NUMG',
  },
  {
    value: 'NUMV',
    label: 'NUMV,  Nuveen ESG Mid-Cap Value ETF',
    name: 'Nuveen ESG Mid-Cap Value ETF',
    symbol: 'NUMV',
  },
  {
    value: 'NUO',
    label: 'NUO,  Nuveen Ohio Quality Municipal Income Fund',
    name: 'Nuveen Ohio Quality Municipal Income Fund',
    symbol: 'NUO',
  },
  {
    value: 'NURE',
    label: 'NURE,  Nuveen Short-Term REIT ETF',
    name: 'Nuveen Short-Term REIT ETF',
    symbol: 'NURE',
  },
  {
    value: 'NUSA',
    label: 'NUSA,  Nuveen Enhanced Yield 1-5 Year U.S. Aggregate Bond ETF',
    name: 'Nuveen Enhanced Yield 1-5 Year U.S. Aggregate Bond ETF',
    symbol: 'NUSA',
  },
  {
    value: 'NUSC',
    label: 'NUSC,  Nuveen ESG Small-Cap ETF',
    name: 'Nuveen ESG Small-Cap ETF',
    symbol: 'NUSC',
  },
  {
    value: 'NUW',
    label: 'NUW,  Nuveen AMT-Free Municipal Value Fund',
    name: 'Nuveen AMT-Free Municipal Value Fund',
    symbol: 'NUW',
  },
  {
    value: 'NWFL',
    label: 'NWFL,  Norwood Financial Corp.',
    name: 'Norwood Financial Corp.',
    symbol: 'NWFL',
  },
  {
    value: 'NXC',
    label: 'NXC,  Nuveen California Select Tax-Free Income Portfolio',
    name: 'Nuveen California Select Tax-Free Income Portfolio',
    symbol: 'NXC',
  },
  {
    value: 'NXP',
    label: 'NXP,  Nuveen Select Tax-Free Income Portfolio',
    name: 'Nuveen Select Tax-Free Income Portfolio',
    symbol: 'NXP',
  },
  {
    value: 'NYMTN',
    label: 'NYMTN,  New York Mortgage Trust, Inc.',
    name: 'New York Mortgage Trust, Inc.',
    symbol: 'NYMTN',
  },
  {
    value: 'OBOR',
    label: 'OBOR,  KraneShares MSCI One Belt One Road Index ETF',
    name: 'KraneShares MSCI One Belt One Road Index ETF',
    symbol: 'OBOR',
  },
  {
    value: 'OBSV',
    label: 'OBSV,  ObsEva SA',
    name: 'ObsEva SA',
    symbol: 'OBSV',
  },
  {
    value: 'OCC',
    label: 'OCC,  Optical Cable Corporation',
    name: 'Optical Cable Corporation',
    symbol: 'OCC',
  },
  {
    value: 'OCIO',
    label: 'OCIO,  ClearShares OCIO ETF',
    name: 'ClearShares OCIO ETF',
    symbol: 'OCIO',
  },
  {
    value: 'ODC',
    label: 'ODC,  Oil-Dri Corporation of America',
    name: 'Oil-Dri Corporation of America',
    symbol: 'ODC',
  },
  {
    value: 'OESX',
    label: 'OESX,  Orion Energy Systems, Inc.',
    name: 'Orion Energy Systems, Inc.',
    symbol: 'OESX',
  },
  {
    value: 'OEUR',
    label: "OEUR,  ALPS O'Shares Europe Quality Dividend ETF Shares",
    name: "ALPS O'Shares Europe Quality Dividend ETF Shares",
    symbol: 'OEUR',
  },
  {
    value: 'OFLX',
    label: 'OFLX,  Omega Flex, Inc.',
    name: 'Omega Flex, Inc.',
    symbol: 'OFLX',
  },
  {
    value: 'OGCP',
    label: 'OGCP,  Empire State Realty OP, L.P.',
    name: 'Empire State Realty OP, L.P.',
    symbol: 'OGCP',
  },
  {
    value: 'OILK',
    label: 'OILK,  ProShares K-1 Free Crude Oil Strategy ETF',
    name: 'ProShares K-1 Free Crude Oil Strategy ETF',
    symbol: 'OILK',
  },
  {
    value: 'OLO',
    label: 'OLO,  Olo Inc.',
    name: 'Olo Inc.',
    symbol: 'OLO',
  },
  {
    value: 'OMFL',
    label: 'OMFL,  Invesco Russell 1000 Dynamic Multifactor ETF',
    name: 'Invesco Russell 1000 Dynamic Multifactor ETF',
    symbol: 'OMFL',
  },
  {
    value: 'OMFS',
    label: 'OMFS,  Invesco Russell 2000 Dynamic Multifactor ETF',
    name: 'Invesco Russell 2000 Dynamic Multifactor ETF',
    symbol: 'OMFS',
  },
  {
    value: 'ONEO',
    label: 'ONEO,  SPDR Russell 1000 Momentum Focus ETF',
    name: 'SPDR Russell 1000 Momentum Focus ETF',
    symbol: 'ONEO',
  },
  {
    value: 'ONEV',
    label: 'ONEV,  SPDR Russell 1000 Low Volatility Focus ETF',
    name: 'SPDR Russell 1000 Low Volatility Focus ETF',
    symbol: 'ONEV',
  },
  {
    value: 'ONEY',
    label: 'ONEY,  SPDR Russell 1000 Yield Focus ETF',
    name: 'SPDR Russell 1000 Yield Focus ETF',
    symbol: 'ONEY',
  },
  {
    value: 'ONG',
    label: 'ONG,  Direxion Daily Oil Services Bull 2X Shares',
    name: 'Direxion Daily Oil Services Bull 2X Shares',
    symbol: 'ONG',
  },
  {
    value: 'OPGN',
    label: 'OPGN,  OpGen, Inc.',
    name: 'OpGen, Inc.',
    symbol: 'OPGN',
  },
  {
    value: 'OPHC',
    label: 'OPHC,  OptimumBank Holdings, Inc.',
    name: 'OptimumBank Holdings, Inc.',
    symbol: 'OPHC',
  },
  {
    value: 'OPOF',
    label: 'OPOF,  Old Point Financial Corporation',
    name: 'Old Point Financial Corporation',
    symbol: 'OPOF',
  },
  {
    value: 'ORGS',
    label: 'ORGS,  Orgenesis Inc.',
    name: 'Orgenesis Inc.',
    symbol: 'ORGS',
  },
  {
    value: 'ORMP',
    label: 'ORMP,  Oramed Pharmaceuticals Inc.',
    name: 'Oramed Pharmaceuticals Inc.',
    symbol: 'ORMP',
  },
  {
    value: 'ORRF',
    label: 'ORRF,  Orrstown Financial Services, Inc.',
    name: 'Orrstown Financial Services, Inc.',
    symbol: 'ORRF',
  },
  {
    value: 'OTTW',
    label: 'OTTW,  Ottawa Bancorp, Inc.',
    name: 'Ottawa Bancorp, Inc.',
    symbol: 'OTTW',
  },
  {
    value: 'OUSM',
    label: "OUSM,  ALPS O'Shares U.S. Small-Cap Quality Dividend ETF Shares",
    name: "ALPS O'Shares U.S. Small-Cap Quality Dividend ETF Shares",
    symbol: 'OUSM',
  },
  {
    value: 'OVLY',
    label: 'OVLY,  Oak Valley Bancorp',
    name: 'Oak Valley Bancorp',
    symbol: 'OVLY',
  },
  {
    value: 'OXBR',
    label: 'OXBR,  Oxbridge Re Holdings Limited',
    name: 'Oxbridge Re Holdings Limited',
    symbol: 'OXBR',
  },
  {
    value: 'OXBRW',
    label: 'OXBRW,  Oxbridge Re Holdings Limited',
    name: 'Oxbridge Re Holdings Limited',
    symbol: 'OXBRW',
  },
  {
    value: 'OXLCM',
    label: 'OXLCM,  Oxford Lane Capital Corp.',
    name: 'Oxford Lane Capital Corp.',
    symbol: 'OXLCM',
  },
  {
    value: 'OXLCO',
    label: 'OXLCO,  Oxford Lane Capital Corp.',
    name: 'Oxford Lane Capital Corp.',
    symbol: 'OXLCO',
  },
  {
    value: 'PAI',
    label: 'PAI,  Western Asset Investment Grade Income Fund Inc.',
    name: 'Western Asset Investment Grade Income Fund Inc.',
    symbol: 'PAI',
  },
  {
    value: 'PAK',
    label: 'PAK,  Global X MSCI Pakistan ETF',
    name: 'Global X MSCI Pakistan ETF',
    symbol: 'PAK',
  },
  {
    value: 'PATI',
    label: 'PATI,  Patriot Transportation Holding, Inc.',
    name: 'Patriot Transportation Holding, Inc.',
    symbol: 'PATI',
  },
  {
    value: 'PAVE',
    label: 'PAVE,  Global X U.S. Infrastructure Development ETF',
    name: 'Global X U.S. Infrastructure Development ETF',
    symbol: 'PAVE',
  },
  {
    value: 'PAVM',
    label: 'PAVM,  PAVmed Inc.',
    name: 'PAVmed Inc.',
    symbol: 'PAVM',
  },
  {
    value: 'PBD',
    label: 'PBD,  Invesco Global Clean Energy ETF',
    name: 'Invesco Global Clean Energy ETF',
    symbol: 'PBD',
  },
  {
    value: 'PBDM',
    label: 'PBDM,  Invesco PureBeta FTSE Developed ex-North America ETF',
    name: 'Invesco PureBeta FTSE Developed ex-North America ETF',
    symbol: 'PBDM',
  },
  {
    value: 'PBEE',
    label: 'PBEE,  Invesco PureBeta FTSE Emerging Markets ETF',
    name: 'Invesco PureBeta FTSE Emerging Markets ETF',
    symbol: 'PBEE',
  },
  {
    value: 'PBHC',
    label: 'PBHC,  Pathfinder Bancorp, Inc.',
    name: 'Pathfinder Bancorp, Inc.',
    symbol: 'PBHC',
  },
  {
    value: 'PBND',
    label: 'PBND,  Invesco PureBeta US Aggregate Bond ETF',
    name: 'Invesco PureBeta US Aggregate Bond ETF',
    symbol: 'PBND',
  },
  {
    value: 'PBP',
    label: 'PBP,  Invesco S&P 500 BuyWrite ETF',
    name: 'Invesco S&P 500 BuyWrite ETF',
    symbol: 'PBP',
  },
  {
    value: 'PBSM',
    label: 'PBSM,  Invesco PureBeta MSCI USA Small Cap ETF',
    name: 'Invesco PureBeta MSCI USA Small Cap ETF',
    symbol: 'PBSM',
  },
  {
    value: 'PBTP',
    label: 'PBTP,  Invesco PureBeta 0-5 Yr US TIPS ETF',
    name: 'Invesco PureBeta 0-5 Yr US TIPS ETF',
    symbol: 'PBTP',
  },
  {
    value: 'PBUS',
    label: 'PBUS,  Invesco PureBeta MSCI USA ETF',
    name: 'Invesco PureBeta MSCI USA ETF',
    symbol: 'PBUS',
  },
  {
    value: 'PCM',
    label: 'PCM,  PCM Fund Inc.',
    name: 'PCM Fund Inc.',
    symbol: 'PCM',
  },
  {
    value: 'PCQ',
    label: 'PCQ,  PIMCO California Municipal Income Fund',
    name: 'PIMCO California Municipal Income Fund',
    symbol: 'PCQ',
  },
  {
    value: 'PDEX',
    label: 'PDEX,  Pro-Dex, Inc.',
    name: 'Pro-Dex, Inc.',
    symbol: 'PDEX',
  },
  {
    value: 'PDLB',
    label: 'PDLB,  Ponce Financial Group, Inc.',
    name: 'Ponce Financial Group, Inc.',
    symbol: 'PDLB',
  },
  {
    value: 'PESI',
    label: 'PESI,  Perma-Fix Environmental Services, Inc.',
    name: 'Perma-Fix Environmental Services, Inc.',
    symbol: 'PESI',
  },
  {
    value: 'PETZ',
    label: 'PETZ,  TDH Holdings, Inc.',
    name: 'TDH Holdings, Inc.',
    symbol: 'PETZ',
  },
  {
    value: 'PEX',
    label: 'PEX,  ProShares Global Listed Private Equity ETF',
    name: 'ProShares Global Listed Private Equity ETF',
    symbol: 'PEX',
  },
  {
    value: 'PEZ',
    label: 'PEZ,  Invesco DWA Consumer Cyclicals Momentum ETF',
    name: 'Invesco DWA Consumer Cyclicals Momentum ETF',
    symbol: 'PEZ',
  },
  {
    value: 'PFD',
    label: 'PFD,  Flaherty & Crumrine Preferred Income Fund Inc.',
    name: 'Flaherty & Crumrine Preferred Income Fund Inc.',
    symbol: 'PFD',
  },
  {
    value: 'PFFR',
    label: 'PFFR,  InfraCap REIT Preferred ETF',
    name: 'InfraCap REIT Preferred ETF',
    symbol: 'PFFR',
  },
  {
    value: 'PFH',
    label: 'PFH,  CABCO Trust for JC Penney Debentures',
    name: 'CABCO Trust for JC Penney Debentures',
    symbol: 'PFH',
  },
  {
    value: 'PFI',
    label: 'PFI,  Invesco DWA Financial Momentum ETF',
    name: 'Invesco DWA Financial Momentum ETF',
    symbol: 'PFI',
  },
  {
    value: 'PFIG',
    label: 'PFIG,  Invesco Fundamental Investment Grade Corporate Bond ETF',
    name: 'Invesco Fundamental Investment Grade Corporate Bond ETF',
    symbol: 'PFIG',
  },
  {
    value: 'PFIN',
    label: 'PFIN,  P&F Industries, Inc.',
    name: 'P&F Industries, Inc.',
    symbol: 'PFIN',
  },
  {
    value: 'PFIS',
    label: 'PFIS,  Peoples Financial Services Corp.',
    name: 'Peoples Financial Services Corp.',
    symbol: 'PFIS',
  },
  {
    value: 'PFM',
    label: 'PFM,  Invesco Dividend Achievers ETF',
    name: 'Invesco Dividend Achievers ETF',
    symbol: 'PFM',
  },
  {
    value: 'PGM',
    label: 'PGM,  iPath Series B Bloomberg Platinum Subindex Total Return ETN',
    name: 'iPath Series B Bloomberg Platinum Subindex Total Return ETN',
    symbol: 'PGM',
  },
  {
    value: 'PGP',
    label: 'PGP,  PIMCO Global StocksPLUS & Income Fund',
    name: 'PIMCO Global StocksPLUS & Income Fund',
    symbol: 'PGP',
  },
  {
    value: 'PHO',
    label: 'PHO,  Invesco Water Resources ETF',
    name: 'Invesco Water Resources ETF',
    symbol: 'PHO',
  },
  {
    value: 'PIE',
    label: 'PIE,  Invesco DWA Emerging Markets Momentum ETF',
    name: 'Invesco DWA Emerging Markets Momentum ETF',
    symbol: 'PIE',
  },
  {
    value: 'PIO',
    label: 'PIO,  Invesco Global Water ETF',
    name: 'Invesco Global Water ETF',
    symbol: 'PIO',
  },
  {
    value: 'PIZ',
    label: 'PIZ,  Invesco DWA Developed Markets Momentum ETF',
    name: 'Invesco DWA Developed Markets Momentum ETF',
    symbol: 'PIZ',
  },
  {
    value: 'PKBK',
    label: 'PKBK,  Parke Bancorp, Inc.',
    name: 'Parke Bancorp, Inc.',
    symbol: 'PKBK',
  },
  {
    value: 'PKW',
    label: 'PKW,  Invesco BuyBack Achievers ETF',
    name: 'Invesco BuyBack Achievers ETF',
    symbol: 'PKW',
  },
  {
    value: 'PLBC',
    label: 'PLBC,  Plumas Bancorp',
    name: 'Plumas Bancorp',
    symbol: 'PLBC',
  },
  {
    value: 'PLG',
    label: 'PLG,  Platinum Group Metals Ltd.',
    name: 'Platinum Group Metals Ltd.',
    symbol: 'PLG',
  },
  {
    value: 'PLTM',
    label: 'PLTM,  GraniteShares Platinum Trust',
    name: 'GraniteShares Platinum Trust',
    symbol: 'PLTM',
  },
  {
    value: 'PLW',
    label: 'PLW,  Invesco 1-30 Laddered Treasury ETF',
    name: 'Invesco 1-30 Laddered Treasury ETF',
    symbol: 'PLW',
  },
  {
    value: 'PLYM',
    label: 'PLYM,  Plymouth Industrial REIT, Inc.',
    name: 'Plymouth Industrial REIT, Inc.',
    symbol: 'PLYM',
  },
  {
    value: 'PMD',
    label: 'PMD,  Psychemedics Corporation',
    name: 'Psychemedics Corporation',
    symbol: 'PMD',
  },
  {
    value: 'PME',
    label: 'PME,  Pingtan Marine Enterprise Ltd.',
    name: 'Pingtan Marine Enterprise Ltd.',
    symbol: 'PME',
  },
  {
    value: 'PMO',
    label: 'PMO,  Putnam Municipal Opportunities Trust',
    name: 'Putnam Municipal Opportunities Trust',
    symbol: 'PMO',
  },
  {
    value: 'PNBK',
    label: 'PNBK,  Patriot National Bancorp, Inc.',
    name: 'Patriot National Bancorp, Inc.',
    symbol: 'PNBK',
  },
  {
    value: 'PNF',
    label: 'PNF,  PIMCO New York Municipal Income Fund',
    name: 'PIMCO New York Municipal Income Fund',
    symbol: 'PNF',
  },
  {
    value: 'PNRG',
    label: 'PNRG,  PrimeEnergy Resources Corporation',
    name: 'PrimeEnergy Resources Corporation',
    symbol: 'PNRG',
  },
  {
    value: 'PPH',
    label: 'PPH,  VanEck Pharmaceutical ETF',
    name: 'VanEck Pharmaceutical ETF',
    symbol: 'PPH',
  },
  {
    value: 'PREF',
    label: 'PREF,  Principal Spectrum Preferred Securities Active ETF',
    name: 'Principal Spectrum Preferred Securities Active ETF',
    symbol: 'PREF',
  },
  {
    value: 'PRKR',
    label: 'PRKR,  ParkerVision, Inc.',
    name: 'ParkerVision, Inc.',
    symbol: 'PRKR',
  },
  {
    value: 'PRN',
    label: 'PRN,  Invesco DWA Industrials Momentum ETF',
    name: 'Invesco DWA Industrials Momentum ETF',
    symbol: 'PRN',
  },
  {
    value: 'PRNT',
    label: 'PRNT,  The 3D Printing ETF',
    name: 'The 3D Printing ETF',
    symbol: 'PRNT',
  },
  {
    value: 'PROV',
    label: 'PROV,  Provident Financial Holdings, Inc.',
    name: 'Provident Financial Holdings, Inc.',
    symbol: 'PROV',
  },
  {
    value: 'PRPH',
    label: 'PRPH,  ProPhase Labs, Inc.',
    name: 'ProPhase Labs, Inc.',
    symbol: 'PRPH',
  },
  {
    value: 'PRPL',
    label: 'PRPL,  Purple Innovation, Inc.',
    name: 'Purple Innovation, Inc.',
    symbol: 'PRPL',
  },
  {
    value: 'PSC',
    label: 'PSC,  Principal U.S. Small-Cap Multi-Factor ETF',
    name: 'Principal U.S. Small-Cap Multi-Factor ETF',
    symbol: 'PSC',
  },
  {
    value: 'PSCC',
    label: 'PSCC,  Invesco S&P SmallCap Consumer Staples ETF',
    name: 'Invesco S&P SmallCap Consumer Staples ETF',
    symbol: 'PSCC',
  },
  {
    value: 'PSCF',
    label: 'PSCF,  Invesco S&P SmallCap Financials ETF',
    name: 'Invesco S&P SmallCap Financials ETF',
    symbol: 'PSCF',
  },
  {
    value: 'PSCH',
    label: 'PSCH,  Invesco S&P SmallCap Health Care ETF',
    name: 'Invesco S&P SmallCap Health Care ETF',
    symbol: 'PSCH',
  },
  {
    value: 'PSCT',
    label: 'PSCT,  Invesco S&P SmallCap Information Technology ETF',
    name: 'Invesco S&P SmallCap Information Technology ETF',
    symbol: 'PSCT',
  },
  {
    value: 'PSCU',
    label: 'PSCU,  Invesco S&P SmallCap Utilities & Communication Services ETF',
    name: 'Invesco S&P SmallCap Utilities & Communication Services ETF',
    symbol: 'PSCU',
  },
  {
    value: 'PSET',
    label: 'PSET,  Principal Quality ETF',
    name: 'Principal Quality ETF',
    symbol: 'PSET',
  },
  {
    value: 'PSF',
    label: 'PSF,  Cohen & Steers Select Preferred and Income Fund, Inc.',
    name: 'Cohen & Steers Select Preferred and Income Fund, Inc.',
    symbol: 'PSF',
  },
  {
    value: 'PSI',
    label: 'PSI,  Invesco Dynamic Semiconductors ETF',
    name: 'Invesco Dynamic Semiconductors ETF',
    symbol: 'PSI',
  },
  {
    value: 'PSL',
    label: 'PSL,  Invesco DWA Consumer Staples Momentum ETF',
    name: 'Invesco DWA Consumer Staples Momentum ETF',
    symbol: 'PSL',
  },
  {
    value: 'PSMB',
    label: 'PSMB,  Invesco Balanced Multi-Asset Allocation ETF',
    name: 'Invesco Balanced Multi-Asset Allocation ETF',
    symbol: 'PSMB',
  },
  {
    value: 'PSMC',
    label: 'PSMC,  Invesco Conservative Multi-Asset Allocation ETF',
    name: 'Invesco Conservative Multi-Asset Allocation ETF',
    symbol: 'PSMC',
  },
  {
    value: 'PSMG',
    label: 'PSMG,  Invesco Growth Multi-Asset Allocation ETF',
    name: 'Invesco Growth Multi-Asset Allocation ETF',
    symbol: 'PSMG',
  },
  {
    value: 'PSMM',
    label: 'PSMM,  Invesco Moderately Conservative Multi-Asset Allocation ETF',
    name: 'Invesco Moderately Conservative Multi-Asset Allocation ETF',
    symbol: 'PSMM',
  },
  {
    value: 'PSR',
    label: 'PSR,  Invesco Active U.S. Real Estate Fund',
    name: 'Invesco Active U.S. Real Estate Fund',
    symbol: 'PSR',
  },
  {
    value: 'PTF',
    label: 'PTF,  Invesco DWA Technology Momentum ETF',
    name: 'Invesco DWA Technology Momentum ETF',
    symbol: 'PTF',
  },
  {
    value: 'PTH',
    label: 'PTH,  Invesco DWA Healthcare Momentum ETF',
    name: 'Invesco DWA Healthcare Momentum ETF',
    symbol: 'PTH',
  },
  {
    value: 'PTLC',
    label: 'PTLC,  Pacer Trendpilot US Large Cap ETF',
    name: 'Pacer Trendpilot US Large Cap ETF',
    symbol: 'PTLC',
  },
  {
    value: 'PTNQ',
    label: 'PTNQ,  Pacer Trendpilot 100 ETF',
    name: 'Pacer Trendpilot 100 ETF',
    symbol: 'PTNQ',
  },
  {
    value: 'PTNR',
    label: 'PTNR,  Partner Communications Company Ltd.',
    name: 'Partner Communications Company Ltd.',
    symbol: 'PTNR',
  },
  {
    value: 'PTSI',
    label: 'PTSI,  P.A.M. Transportation Services, Inc.',
    name: 'P.A.M. Transportation Services, Inc.',
    symbol: 'PTSI',
  },
  {
    value: 'PUI',
    label: 'PUI,  Invesco DWA Utilities Momentum ETF',
    name: 'Invesco DWA Utilities Momentum ETF',
    symbol: 'PUI',
  },
  {
    value: 'PW',
    label: 'PW,  Power REIT',
    name: 'Power REIT',
    symbol: 'PW',
  },
  {
    value: 'PWB',
    label: 'PWB,  Invesco Dynamic Large Cap Growth ETF',
    name: 'Invesco Dynamic Large Cap Growth ETF',
    symbol: 'PWB',
  },
  {
    value: 'PWC',
    label: 'PWC,  Invesco Dynamic Market ETF',
    name: 'Invesco Dynamic Market ETF',
    symbol: 'PWC',
  },
  {
    value: 'PWS',
    label: 'PWS,  Pacer WealthShield ETF',
    name: 'Pacer WealthShield ETF',
    symbol: 'PWS',
  },
  {
    value: 'PWZ',
    label: 'PWZ,  Invesco California AMT-Free Municipal Bond ETF',
    name: 'Invesco California AMT-Free Municipal Bond ETF',
    symbol: 'PWZ',
  },
  {
    value: 'PXS',
    label: 'PXS,  Pyxis Tankers Inc.',
    name: 'Pyxis Tankers Inc.',
    symbol: 'PXS',
  },
  {
    value: 'PY',
    label: 'PY,  Principal Value ETF',
    name: 'Principal Value ETF',
    symbol: 'PY',
  },
  {
    value: 'PYS',
    label: 'PYS,  PPlus Trust Series RRD-1 RRD-1 CTF CL A',
    name: 'PPlus Trust Series RRD-1 RRD-1 CTF CL A',
    symbol: 'PYS',
  },
  {
    value: 'PYZ',
    label: 'PYZ,  Invesco DWA Basic Materials Momentum ETF',
    name: 'Invesco DWA Basic Materials Momentum ETF',
    symbol: 'PYZ',
  },
  {
    value: 'PZC',
    label: 'PZC,  PIMCO California Municipal Income Fund III',
    name: 'PIMCO California Municipal Income Fund III',
    symbol: 'PZC',
  },
  {
    value: 'ERTH',
    label: 'ERTH,  Invesco MSCI Sustainable Future ETF',
    name: 'Invesco MSCI Sustainable Future ETF',
    symbol: 'ERTH',
  },
  {
    value: 'PZT',
    label: 'PZT,  Invesco New York AMT-Free Municipal Bond ETF',
    name: 'Invesco New York AMT-Free Municipal Bond ETF',
    symbol: 'PZT',
  },
  {
    value: 'QAT',
    label: 'QAT,  iShares MSCI Qatar ETF',
    name: 'iShares MSCI Qatar ETF',
    symbol: 'QAT',
  },
  {
    value: 'QBAK',
    label: 'QBAK,  Qualstar Corporation',
    name: 'Qualstar Corporation',
    symbol: 'QBAK',
  },
  {
    value: 'QCLN',
    label: 'QCLN,  First Trust NASDAQ Clean Edge Green Energy Index Fund',
    name: 'First Trust NASDAQ Clean Edge Green Energy Index Fund',
    symbol: 'QCLN',
  },
  {
    value: 'QDEF',
    label: 'QDEF,  FlexShares Quality Dividend Defensive Index Fund',
    name: 'FlexShares Quality Dividend Defensive Index Fund',
    symbol: 'QDEF',
  },
  {
    value: 'QDYN',
    label: 'QDYN,  FlexShares Quality Dividend Dynamic Index Fund',
    name: 'FlexShares Quality Dividend Dynamic Index Fund',
    symbol: 'QDYN',
  },
  {
    value: 'QED',
    label: 'QED,  IQ Hedge Event-Driven Tracker ETF',
    name: 'IQ Hedge Event-Driven Tracker ETF',
    symbol: 'QED',
  },
  {
    value: 'QEMM',
    label: 'QEMM,  SPDR MSCI Emerging Markets StrategicFactors ETF',
    name: 'SPDR MSCI Emerging Markets StrategicFactors ETF',
    symbol: 'QEMM',
  },
  {
    value: 'QLC',
    label: 'QLC,  FlexShares US Quality Large Cap Index Fund',
    name: 'FlexShares US Quality Large Cap Index Fund',
    symbol: 'QLC',
  },
  {
    value: 'QLS',
    label: 'QLS,  IQ Hedge Long/Short Tracker ETF',
    name: 'IQ Hedge Long/Short Tracker ETF',
    symbol: 'QLS',
  },
  {
    value: 'QLTA',
    label: 'QLTA,  iShares Aaa - A Rated Corporate Bond ETF',
    name: 'iShares Aaa - A Rated Corporate Bond ETF',
    symbol: 'QLTA',
  },
  {
    value: 'QMN',
    label: 'QMN,  IQ Hedge Market Neutral Tracker ETF',
    name: 'IQ Hedge Market Neutral Tracker ETF',
    symbol: 'QMN',
  },
  {
    value: 'QMOM',
    label: 'QMOM,  Alpha Architect U.S. Quantitative Momentum ETF',
    name: 'Alpha Architect U.S. Quantitative Momentum ETF',
    symbol: 'QMOM',
  },
  {
    value: 'QQXT',
    label: 'QQXT,  First Trust NASDAQ-100 Ex-Technology Sector Index Fund',
    name: 'First Trust NASDAQ-100 Ex-Technology Sector Index Fund',
    symbol: 'QQXT',
  },
  {
    value: 'QRHC',
    label: 'QRHC,  Quest Resource Holding Corporation',
    name: 'Quest Resource Holding Corporation',
    symbol: 'QRHC',
  },
  {
    value: 'QRTEB',
    label: 'QRTEB,  Qurate Retail, Inc.',
    name: 'Qurate Retail, Inc.',
    symbol: 'QRTEB',
  },
  {
    value: 'QUS',
    label: 'QUS,  SPDR MSCI USA StrategicFactors ETF',
    name: 'SPDR MSCI USA StrategicFactors ETF',
    symbol: 'QUS',
  },
  {
    value: 'QWLD',
    label: 'QWLD,  SPDR MSCI World StrategicFactors ETF',
    name: 'SPDR MSCI World StrategicFactors ETF',
    symbol: 'QWLD',
  },
  {
    value: 'RAND',
    label: 'RAND,  Rand Capital Corporation',
    name: 'Rand Capital Corporation',
    symbol: 'RAND',
  },
  {
    value: 'RAVI',
    label: 'RAVI,  FlexShares Ready Access Variable Income Fund',
    name: 'FlexShares Ready Access Variable Income Fund',
    symbol: 'RAVI',
  },
  {
    value: 'RBCN',
    label: 'RBCN,  Rubicon Technology, Inc.',
    name: 'Rubicon Technology, Inc.',
    symbol: 'RBCN',
  },
  {
    value: 'RCD',
    label: 'RCD,  Invesco S&P 500 Equal Weight Consumer Discretionary ETF',
    name: 'Invesco S&P 500 Equal Weight Consumer Discretionary ETF',
    symbol: 'RCD',
  },
  {
    value: 'RCG',
    label: 'RCG,  RENN Fund, Inc.',
    name: 'RENN Fund, Inc.',
    symbol: 'RCG',
  },
  {
    value: 'RCMT',
    label: 'RCMT,  RCM Technologies, Inc.',
    name: 'RCM Technologies, Inc.',
    symbol: 'RCMT',
  },
  {
    value: 'RCON',
    label: 'RCON,  Recon Technology, Ltd.',
    name: 'Recon Technology, Ltd.',
    symbol: 'RCON',
  },
  {
    value: 'RDIB',
    label: 'RDIB,  Reading International, Inc.',
    name: 'Reading International, Inc.',
    symbol: 'RDIB',
  },
  {
    value: 'REED',
    label: "REED,  Reed's, Inc.",
    name: "Reed's, Inc.",
    symbol: 'REED',
  },
  {
    value: 'REFR',
    label: 'REFR,  Research Frontiers Incorporated',
    name: 'Research Frontiers Incorporated',
    symbol: 'REFR',
  },
  {
    value: 'RELV',
    label: "RELV,  Reliv' International, Inc.",
    name: "Reliv' International, Inc.",
    symbol: 'RELV',
  },
  {
    value: 'RETL',
    label: 'RETL,  Direxion Daily Retail Bull 3X Shares',
    name: 'Direxion Daily Retail Bull 3X Shares',
    symbol: 'RETL',
  },
  {
    value: 'REZ',
    label: 'REZ,  iShares Residential and Multisector Real Estate ETF',
    name: 'iShares Residential and Multisector Real Estate ETF',
    symbol: 'REZ',
  },
  {
    value: 'RFCI',
    label: 'RFCI,  RiverFront Dynamic Core Income ETF',
    name: 'RiverFront Dynamic Core Income ETF',
    symbol: 'RFCI',
  },
  {
    value: 'RFI',
    label: 'RFI,  Cohen & Steers Total Return Realty Fund, Inc.',
    name: 'Cohen & Steers Total Return Realty Fund, Inc.',
    symbol: 'RFI',
  },
  {
    value: 'RGCO',
    label: 'RGCO,  RGC Resources, Inc.',
    name: 'RGC Resources, Inc.',
    symbol: 'RGCO',
  },
  {
    value: 'RGT',
    label: 'RGT,  Royce Global Value Trust, Inc.',
    name: 'Royce Global Value Trust, Inc.',
    symbol: 'RGT',
  },
  {
    value: 'RHS',
    label: 'RHS,  Invesco S&P 500 Equal Weight Consumer Staples ETF',
    name: 'Invesco S&P 500 Equal Weight Consumer Staples ETF',
    symbol: 'RHS',
  },
  {
    value: 'RILYL',
    label: 'RILYL,  B. Riley Financial, Inc.',
    name: 'B. Riley Financial, Inc.',
    symbol: 'RILYL',
  },
  {
    value: 'RILYZ',
    label: 'RILYZ,  B. Riley Financial, Inc. 5.25% Senior Notes due 2028',
    name: 'B. Riley Financial, Inc. 5.25% Senior Notes due 2028',
    symbol: 'RILYZ',
  },
  {
    value: 'RINF',
    label: 'RINF,  ProShares Inflation Expectations ETF',
    name: 'ProShares Inflation Expectations ETF',
    symbol: 'RINF',
  },
  {
    value: 'RIV',
    label: 'RIV,  RiverNorth Opportunities Fund, Inc.',
    name: 'RiverNorth Opportunities Fund, Inc.',
    symbol: 'RIV',
  },
  {
    value: 'RJZ',
    label:
      'RJZ,  ELEMENTS Linked to the Rogers International Commodity Index - Metals Total Return',
    name: 'ELEMENTS Linked to the Rogers International Commodity Index - Metals Total Return',
    symbol: 'RJZ',
  },
  {
    value: 'RMBL',
    label: 'RMBL,  RumbleON, Inc.',
    name: 'RumbleON, Inc.',
    symbol: 'RMBL',
  },
  {
    value: 'RMCF',
    label: 'RMCF,  Rocky Mountain Chocolate Factory, Inc.',
    name: 'Rocky Mountain Chocolate Factory, Inc.',
    symbol: 'RMCF',
  },
  {
    value: 'RNDM',
    label: 'RNDM,  First Trust Developed International Equity Select ETF',
    name: 'First Trust Developed International Equity Select ETF',
    symbol: 'RNDM',
  },
  {
    value: 'RNDV',
    label: 'RNDV,  First Trust US Equity Dividend Select ETF',
    name: 'First Trust US Equity Dividend Select ETF',
    symbol: 'RNDV',
  },
  {
    value: 'RNEM',
    label: 'RNEM,  First Trust Emerging Markets Equity Select ETF',
    name: 'First Trust Emerging Markets Equity Select ETF',
    symbol: 'RNEM',
  },
  {
    value: 'RNLC',
    label: 'RNLC,  First Trust Large Cap US Equity Select ETF',
    name: 'First Trust Large Cap US Equity Select ETF',
    symbol: 'RNLC',
  },
  {
    value: 'RNMC',
    label: 'RNMC,  First Trust Mid Cap US Equity Select ETF',
    name: 'First Trust Mid Cap US Equity Select ETF',
    symbol: 'RNMC',
  },
  {
    value: 'RNSC',
    label: 'RNSC,  First Trust Small Cap US Equity Select ETF',
    name: 'First Trust Small Cap US Equity Select ETF',
    symbol: 'RNSC',
  },
  {
    value: 'ROBT',
    label: 'ROBT,  First Trust Nasdaq Artificial Intelligence and Robotics ETF',
    name: 'First Trust Nasdaq Artificial Intelligence and Robotics ETF',
    symbol: 'ROBT',
  },
  {
    value: 'RODM',
    label: 'RODM,  Hartford Multifactor Developed Markets (ex-US) ETF',
    name: 'Hartford Multifactor Developed Markets (ex-US) ETF',
    symbol: 'RODM',
  },
  {
    value: 'ROUS',
    label: 'ROUS,  Hartford Multifactor US Equity ETF',
    name: 'Hartford Multifactor US Equity ETF',
    symbol: 'ROUS',
  },
  {
    value: 'RSXJ',
    label: 'RSXJ,  VanEck Russia Small-Cap ETF',
    name: 'VanEck Russia Small-Cap ETF',
    symbol: 'RSXJ',
  },
  {
    value: 'RTM',
    label: 'RTM,  Invesco S&P 500 Equal Weight Materials ETF',
    name: 'Invesco S&P 500 Equal Weight Materials ETF',
    symbol: 'RTM',
  },
  {
    value: 'RVNU',
    label: 'RVNU,  Xtrackers Municipal Infrastructure Revenue Bond ETF',
    name: 'Xtrackers Municipal Infrastructure Revenue Bond ETF',
    symbol: 'RVNU',
  },
  {
    value: 'RWJ',
    label: 'RWJ,  Invesco S&P SmallCap 600 Revenue ETF',
    name: 'Invesco S&P SmallCap 600 Revenue ETF',
    symbol: 'RWJ',
  },
  {
    value: 'RXD',
    label: 'RXD,  ProShares UltraShort Health Care',
    name: 'ProShares UltraShort Health Care',
    symbol: 'RXD',
  },
  {
    value: 'RXL',
    label: 'RXL,  ProShares Ultra Health Care',
    name: 'ProShares Ultra Health Care',
    symbol: 'RXL',
  },
  {
    value: 'RYJ',
    label: 'RYJ,  Invesco Raymond James SB-1 Equity ETF',
    name: 'Invesco Raymond James SB-1 Equity ETF',
    symbol: 'RYJ',
  },
  {
    value: 'RYU',
    label: 'RYU,  Invesco S&P 500 Equal Weight Utilities ETF',
    name: 'Invesco S&P 500 Equal Weight Utilities ETF',
    symbol: 'RYU',
  },
  {
    value: 'RZV',
    label: 'RZV,  Invesco S&P SmallCap 600 Pure Value ETF',
    name: 'Invesco S&P SmallCap 600 Pure Value ETF',
    symbol: 'RZV',
  },
  {
    value: 'SAA',
    label: 'SAA,  ProShares Ultra SmallCap600',
    name: 'ProShares Ultra SmallCap600',
    symbol: 'SAA',
  },
  {
    value: 'SAMG',
    label: 'SAMG,  Silvercrest Asset Management Group Inc.',
    name: 'Silvercrest Asset Management Group Inc.',
    symbol: 'SAMG',
  },
  {
    value: 'SAR',
    label: 'SAR,  Saratoga Investment Corp.',
    name: 'Saratoga Investment Corp.',
    symbol: 'SAR',
  },
  {
    value: 'SBB',
    label: 'SBB,  ProShares Short SmallCap600',
    name: 'ProShares Short SmallCap600',
    symbol: 'SBB',
  },
  {
    value: 'SBIO',
    label: 'SBIO,  ALPS Medical Breakthroughs ETF',
    name: 'ALPS Medical Breakthroughs ETF',
    symbol: 'SBIO',
  },
  {
    value: 'SBR',
    label: 'SBR,  Sabine Royalty Trust',
    name: 'Sabine Royalty Trust',
    symbol: 'SBR',
  },
  {
    value: 'SCC',
    label: 'SCC,  ProShares UltraShort Consumer Services',
    name: 'ProShares UltraShort Consumer Services',
    symbol: 'SCC',
  },
  {
    value: 'SCD',
    label: 'SCD,  LMP Capital and Income Fund Inc.',
    name: 'LMP Capital and Income Fund Inc.',
    symbol: 'SCD',
  },
  {
    value: 'SCKT',
    label: 'SCKT,  Socket Mobile, Inc.',
    name: 'Socket Mobile, Inc.',
    symbol: 'SCKT',
  },
  {
    value: 'SCX',
    label: 'SCX,  The L.S. Starrett Company',
    name: 'The L.S. Starrett Company',
    symbol: 'SCX',
  },
  {
    value: 'SDD',
    label: 'SDD,  ProShares UltraShort SmallCap600',
    name: 'ProShares UltraShort SmallCap600',
    symbol: 'SDD',
  },
  {
    value: 'SDEM',
    label: 'SDEM,  Global X MSCI SuperDividend Emerging Markets ETF',
    name: 'Global X MSCI SuperDividend Emerging Markets ETF',
    symbol: 'SDEM',
  },
  {
    value: 'SDP',
    label: 'SDP,  ProShares UltraShort Utilities',
    name: 'ProShares UltraShort Utilities',
    symbol: 'SDP',
  },
  {
    value: 'SDVY',
    label: 'SDVY,  First Trust SMID Cap Rising Dividend Achievers ETF',
    name: 'First Trust SMID Cap Rising Dividend Achievers ETF',
    symbol: 'SDVY',
  },
  {
    value: 'SECT',
    label: 'SECT,  Main Sector Rotation ETF',
    name: 'Main Sector Rotation ETF',
    symbol: 'SECT',
  },
  {
    value: 'SEF',
    label: 'SEF,  ProShares Short Financials',
    name: 'ProShares Short Financials',
    symbol: 'SEF',
  },
  {
    value: 'SELF',
    label: 'SELF,  Global Self Storage, Inc.',
    name: 'Global Self Storage, Inc.',
    symbol: 'SELF',
  },
  {
    value: 'SENEB',
    label: 'SENEB,  Seneca Foods Corporation',
    name: 'Seneca Foods Corporation',
    symbol: 'SENEB',
  },
  {
    value: 'SFB',
    label: 'SFB,  Stifel Financial Corporation 5.20% Senior Notes due 2047',
    name: 'Stifel Financial Corporation 5.20% Senior Notes due 2047',
    symbol: 'SFB',
  },
  {
    value: 'SFBC',
    label: 'SFBC,  Sound Financial Bancorp, Inc.',
    name: 'Sound Financial Bancorp, Inc.',
    symbol: 'SFBC',
  },
  {
    value: 'SFIG',
    label: 'SFIG,  WisdomTree U.S. Short-Term Corporate Bond Fund',
    name: 'WisdomTree U.S. Short-Term Corporate Bond Fund',
    symbol: 'SFIG',
  },
  {
    value: 'SGA',
    label: 'SGA,  Saga Communications, Inc.',
    name: 'Saga Communications, Inc.',
    symbol: 'SGA',
  },
  {
    value: 'SGDM',
    label: 'SGDM,  Sprott Gold Miners ETF',
    name: 'Sprott Gold Miners ETF',
    symbol: 'SGDM',
  },
  {
    value: 'SHAG',
    label:
      'SHAG,  WisdomTree Yield Enhanced U.S. Short-Term Aggregate Bond Fund',
    name: 'WisdomTree Yield Enhanced U.S. Short-Term Aggregate Bond Fund',
    symbol: 'SHAG',
  },
  {
    value: 'SHE',
    label: 'SHE,  SPDR SSGA Gender Diversity Index ETF',
    name: 'SPDR SSGA Gender Diversity Index ETF',
    symbol: 'SHE',
  },
  {
    value: 'SHIP',
    label: 'SHIP,  Seanergy Maritime Holdings Corp.',
    name: 'Seanergy Maritime Holdings Corp.',
    symbol: 'SHIP',
  },
  {
    value: 'SHYL',
    label: 'SHYL,  Xtrackers Short Duration High Yield Bond ETF',
    name: 'Xtrackers Short Duration High Yield Bond ETF',
    symbol: 'SHYL',
  },
  {
    value: 'SIEB',
    label: 'SIEB,  Siebert Financial Corp.',
    name: 'Siebert Financial Corp.',
    symbol: 'SIEB',
  },
  {
    value: 'SIF',
    label: 'SIF,  SIFCO Industries, Inc.',
    name: 'SIFCO Industries, Inc.',
    symbol: 'SIF',
  },
  {
    value: 'SIFY',
    label: 'SIFY,  Sify Technologies Limited',
    name: 'Sify Technologies Limited',
    symbol: 'SIFY',
  },
  {
    value: 'SIJ',
    label: 'SIJ,  ProShares UltraShort Industrials',
    name: 'ProShares UltraShort Industrials',
    symbol: 'SIJ',
  },
  {
    value: 'SIM',
    label: 'SIM,  Grupo Simec, S.A.B. de C.V.',
    name: 'Grupo Simec, S.A.B. de C.V.',
    symbol: 'SIM',
  },
  {
    value: 'SIZE',
    label: 'SIZE,  iShares MSCI USA Size Factor ETF',
    name: 'iShares MSCI USA Size Factor ETF',
    symbol: 'SIZE',
  },
  {
    value: 'SKOR',
    label: 'SKOR,  FlexShares Credit-Scored US Corporate Bond Index Fund',
    name: 'FlexShares Credit-Scored US Corporate Bond Index Fund',
    symbol: 'SKOR',
  },
  {
    value: 'SLMBP',
    label: 'SLMBP,  SLM Corporation',
    name: 'SLM Corporation',
    symbol: 'SLMBP',
  },
  {
    value: 'SLNO',
    label: 'SLNO,  Soleno Therapeutics, Inc.',
    name: 'Soleno Therapeutics, Inc.',
    symbol: 'SLNO',
  },
  {
    value: 'SLVP',
    label: 'SLVP,  iShares MSCI Global Silver and Metals Miners ETF',
    name: 'iShares MSCI Global Silver and Metals Miners ETF',
    symbol: 'SLVP',
  },
  {
    value: 'SMCP',
    label: 'SMCP,  AlphaMark Actively Managed Small Cap ETF',
    name: 'AlphaMark Actively Managed Small Cap ETF',
    symbol: 'SMCP',
  },
  {
    value: 'SMDV',
    label: 'SMDV,  ProShares Russell 2000 Dividend Growers ETF',
    name: 'ProShares Russell 2000 Dividend Growers ETF',
    symbol: 'SMDV',
  },
  {
    value: 'SMLF',
    label: 'SMLF,  iShares MSCI USA Small-Cap Multifactor ETF',
    name: 'iShares MSCI USA Small-Cap Multifactor ETF',
    symbol: 'SMLF',
  },
  {
    value: 'SMLV',
    label: 'SMLV,  SPDR SSGA US Small Cap Low Volatility Index ETF',
    name: 'SPDR SSGA US Small Cap Low Volatility Index ETF',
    symbol: 'SMLV',
  },
  {
    value: 'SMMD',
    label: 'SMMD,  iShares Russell 2500 ETF',
    name: 'iShares Russell 2500 ETF',
    symbol: 'SMMD',
  },
  {
    value: 'SMMU',
    label: 'SMMU,  PIMCO Short Term Municipal Bond Active Exchange-Traded Fund',
    name: 'PIMCO Short Term Municipal Bond Active Exchange-Traded Fund',
    symbol: 'SMMU',
  },
  {
    value: 'SMMV',
    label: 'SMMV,  iShares MSCI USA Small-Cap Min Vol Factor ETF',
    name: 'iShares MSCI USA Small-Cap Min Vol Factor ETF',
    symbol: 'SMMV',
  },
  {
    value: 'SMSI',
    label: 'SMSI,  Smith Micro Software, Inc.',
    name: 'Smith Micro Software, Inc.',
    symbol: 'SMSI',
  },
  {
    value: 'SMTS',
    label: 'SMTS,  Sierra Metals Inc.',
    name: 'Sierra Metals Inc.',
    symbol: 'SMTS',
  },
  {
    value: 'SNES',
    label: 'SNES,  SenesTech, Inc.',
    name: 'SenesTech, Inc.',
    symbol: 'SNES',
  },
  {
    value: 'SNFCA',
    label: 'SNFCA,  Security National Financial Corporation',
    name: 'Security National Financial Corporation',
    symbol: 'SNFCA',
  },
  {
    value: 'SNMP',
    label: 'SNMP,  Evolve Transition Infrastructure LP',
    name: 'Evolve Transition Infrastructure LP',
    symbol: 'SNMP',
  },
  {
    value: 'SNSR',
    label: 'SNSR,  Global X Internet of Things ETF',
    name: 'Global X Internet of Things ETF',
    symbol: 'SNSR',
  },
  {
    value: 'SOFO',
    label: 'SOFO,  Sonic Foundry, Inc.',
    name: 'Sonic Foundry, Inc.',
    symbol: 'SOFO',
  },
  {
    value: 'SOHOO',
    label: 'SOHOO,  Sotherly Hotels Inc.',
    name: 'Sotherly Hotels Inc.',
    symbol: 'SOHOO',
  },
  {
    value: 'SOL',
    label: 'SOL,  ReneSola Ltd',
    name: 'ReneSola Ltd',
    symbol: 'SOL',
  },
  {
    value: 'FAIL',
    label: 'FAIL,  Cambria Global Tail Risk ETF',
    name: 'Cambria Global Tail Risk ETF',
    symbol: 'FAIL',
  },
  {
    value: 'SOYB',
    label: 'SOYB,  Teucrium Soybean Fund',
    name: 'Teucrium Soybean Fund',
    symbol: 'SOYB',
  },
  {
    value: 'SPCB',
    label: 'SPCB,  SuperCom Ltd.',
    name: 'SuperCom Ltd.',
    symbol: 'SPCB',
  },
  {
    value: 'SPDN',
    label: 'SPDN,  Direxion Daily S&P 500 Bear 1X Shares',
    name: 'Direxion Daily S&P 500 Bear 1X Shares',
    symbol: 'SPDN',
  },
  {
    value: 'SPDV',
    label: 'SPDV,  AAM S&P 500 High Dividend Value ETF',
    name: 'AAM S&P 500 High Dividend Value ETF',
    symbol: 'SPDV',
  },
  {
    value: 'SPE',
    label: 'SPE,  Special Opportunities Fund, Inc.',
    name: 'Special Opportunities Fund, Inc.',
    symbol: 'SPE',
  },
  {
    value: 'SPI',
    label: 'SPI,  SPI Energy Co., Ltd.',
    name: 'SPI Energy Co., Ltd.',
    symbol: 'SPI',
  },
  {
    value: 'SPMO',
    label: 'SPMO,  Invesco S&P 500 Momentum ETF',
    name: 'Invesco S&P 500 Momentum ETF',
    symbol: 'SPMO',
  },
  {
    value: 'SPMV',
    label: 'SPMV,  Invesco S&P 500 Minimum Variance ETF',
    name: 'Invesco S&P 500 Minimum Variance ETF',
    symbol: 'SPMV',
  },
  {
    value: 'SPPP',
    label: 'SPPP,  Sprott Physical Platinum and Palladium Trust',
    name: 'Sprott Physical Platinum and Palladium Trust',
    symbol: 'SPPP',
  },
  {
    value: 'SPRO',
    label: 'SPRO,  Spero Therapeutics, Inc.',
    name: 'Spero Therapeutics, Inc.',
    symbol: 'SPRO',
  },
  {
    value: 'SPTS',
    label: 'SPTS,  SPDR Portfolio Short Term Treasury ETF',
    name: 'SPDR Portfolio Short Term Treasury ETF',
    symbol: 'SPTS',
  },
  {
    value: 'SPUU',
    label: 'SPUU,  Direxion Daily S&P 500 Bull 2X Shares',
    name: 'Direxion Daily S&P 500 Bull 2X Shares',
    symbol: 'SPUU',
  },
  {
    value: 'SPVM',
    label: 'SPVM,  Invesco S&P 500 Value with Momentum ETF',
    name: 'Invesco S&P 500 Value with Momentum ETF',
    symbol: 'SPVM',
  },
  {
    value: 'SPVU',
    label: 'SPVU,  Invesco S&P 500 Enhanced Value ETF',
    name: 'Invesco S&P 500 Enhanced Value ETF',
    symbol: 'SPVU',
  },
  {
    value: 'SPXE',
    label: 'SPXE,  ProShares S&P 500 ex-Energy ETF',
    name: 'ProShares S&P 500 ex-Energy ETF',
    symbol: 'SPXE',
  },
  {
    value: 'SPXN',
    label: 'SPXN,  ProShares S&P 500 ex-Financials ETF',
    name: 'ProShares S&P 500 ex-Financials ETF',
    symbol: 'SPXN',
  },
  {
    value: 'SPXT',
    label: 'SPXT,  ProShares S&P 500 ex-Technology ETF',
    name: 'ProShares S&P 500 ex-Technology ETF',
    symbol: 'SPXT',
  },
  {
    value: 'SPXV',
    label: 'SPXV,  ProShares S&P 500 ex-Health Care ETF',
    name: 'ProShares S&P 500 ex-Health Care ETF',
    symbol: 'SPXV',
  },
  {
    value: 'SPYX',
    label: 'SPYX,  SPDR S&P 500 Fossil Fuel Reserves Free ETF',
    name: 'SPDR S&P 500 Fossil Fuel Reserves Free ETF',
    symbol: 'SPYX',
  },
  {
    value: 'SQLV',
    label: 'SQLV,  Royce Quant Small-Cap Quality Value ETF',
    name: 'Royce Quant Small-Cap Quality Value ETF',
    symbol: 'SQLV',
  },
  {
    value: 'SRET',
    label: 'SRET,  Global X SuperDividend REIT ETF',
    name: 'Global X SuperDividend REIT ETF',
    symbol: 'SRET',
  },
  {
    value: 'SRTS',
    label: 'SRTS,  Sensus Healthcare, Inc.',
    name: 'Sensus Healthcare, Inc.',
    symbol: 'SRTS',
  },
  {
    value: 'SSBI',
    label: 'SSBI,  Summit State Bank',
    name: 'Summit State Bank',
    symbol: 'SSBI',
  },
  {
    value: 'SSKN',
    label: 'SSKN,  STRATA Skin Sciences, Inc.',
    name: 'STRATA Skin Sciences, Inc.',
    symbol: 'SSKN',
  },
  {
    value: 'SSNT',
    label: 'SSNT,  SilverSun Technologies, Inc.',
    name: 'SilverSun Technologies, Inc.',
    symbol: 'SSNT',
  },
  {
    value: 'SSY',
    label: 'SSY,  SunLink Health Systems, Inc.',
    name: 'SunLink Health Systems, Inc.',
    symbol: 'SSY',
  },
  {
    value: 'STOT',
    label: 'STOT,  SPDR DoubleLine Short Duration Total Return Tactical ETF',
    name: 'SPDR DoubleLine Short Duration Total Return Tactical ETF',
    symbol: 'STOT',
  },
  {
    value: 'SUB',
    label: 'SUB,  iShares Short-Term National Muni Bond ETF',
    name: 'iShares Short-Term National Muni Bond ETF',
    symbol: 'SUB',
  },
  {
    value: 'SUNW',
    label: 'SUNW,  Sunworks, Inc.',
    name: 'Sunworks, Inc.',
    symbol: 'SUNW',
  },
  {
    value: 'SUSA',
    label: 'SUSA,  iShares MSCI USA ESG Select ETF',
    name: 'iShares MSCI USA ESG Select ETF',
    symbol: 'SUSA',
  },
  {
    value: 'SUSB',
    label: 'SUSB,  iShares ESG 1-5 Year USD Corporate Bond ETF',
    name: 'iShares ESG 1-5 Year USD Corporate Bond ETF',
    symbol: 'SUSB',
  },
  {
    value: 'SUSC',
    label: 'SUSC,  iShares ESG USD Corporate Bond ETF',
    name: 'iShares ESG USD Corporate Bond ETF',
    symbol: 'SUSC',
  },
  {
    value: 'SVT',
    label: 'SVT,  Servotronics, Inc.',
    name: 'Servotronics, Inc.',
    symbol: 'SVT',
  },
  {
    value: 'SWZ',
    label: 'SWZ,  The Swiss Helvetia Fund Inc.',
    name: 'The Swiss Helvetia Fund Inc.',
    symbol: 'SWZ',
  },
  {
    value: 'SXI',
    label: 'SXI,  Standex International Corporation',
    name: 'Standex International Corporation',
    symbol: 'SXI',
  },
  {
    value: 'SYPR',
    label: 'SYPR,  Sypris Solutions, Inc.',
    name: 'Sypris Solutions, Inc.',
    symbol: 'SYPR',
  },
  {
    value: 'SZK',
    label: 'SZK,  ProShares UltraShort Consumer Goods',
    name: 'ProShares UltraShort Consumer Goods',
    symbol: 'SZK',
  },
  {
    value: 'TACT',
    label: 'TACT,  TransAct Technologies Incorporated',
    name: 'TransAct Technologies Incorporated',
    symbol: 'TACT',
  },
  {
    value: 'TAGS',
    label: 'TAGS,  Teucrium Agricultural Fund',
    name: 'Teucrium Agricultural Fund',
    symbol: 'TAGS',
  },
  {
    value: 'TAIT',
    label: 'TAIT,  Taitron Components Incorporated',
    name: 'Taitron Components Incorporated',
    symbol: 'TAIT',
  },
  {
    value: 'TATT',
    label: 'TATT,  TAT Technologies Ltd.',
    name: 'TAT Technologies Ltd.',
    symbol: 'TATT',
  },
  {
    value: 'TAYD',
    label: 'TAYD,  Taylor Devices, Inc.',
    name: 'Taylor Devices, Inc.',
    symbol: 'TAYD',
  },
  {
    value: 'TBX',
    label: 'TBX,  ProShares Short 7-10 Year Treasury',
    name: 'ProShares Short 7-10 Year Treasury',
    symbol: 'TBX',
  },
  {
    value: 'TCCO',
    label: 'TCCO,  Technical Communications Corporation',
    name: 'Technical Communications Corporation',
    symbol: 'TCCO',
  },
  {
    value: 'TDTF',
    label: 'TDTF,  FlexShares iBoxx 5-Year Target Duration TIPS Index Fund',
    name: 'FlexShares iBoxx 5-Year Target Duration TIPS Index Fund',
    symbol: 'TDTF',
  },
  {
    value: 'TFLO',
    label: 'TFLO,  iShares Treasury Floating Rate Bond ETF',
    name: 'iShares Treasury Floating Rate Bond ETF',
    symbol: 'TFLO',
  },
  {
    value: 'REPX',
    label: 'REPX,  Riley Exploration Permian, Inc.',
    name: 'Riley Exploration Permian, Inc.',
    symbol: 'REPX',
  },
  {
    value: 'THST',
    label: 'THST,  Truett-Hurst, Inc.',
    name: 'Truett-Hurst, Inc.',
    symbol: 'THST',
  },
  {
    value: 'TIL',
    label: 'TIL,  Instil Bio, Inc.',
    name: 'Instil Bio, Inc.',
    symbol: 'TIL',
  },
  {
    value: 'TIPZ',
    label: 'TIPZ,  PIMCO Broad U.S. TIPS Index Exchange-Traded Fund',
    name: 'PIMCO Broad U.S. TIPS Index Exchange-Traded Fund',
    symbol: 'TIPZ',
  },
  {
    value: 'TKAT',
    label: 'TKAT,  Takung Art Co., Ltd.',
    name: 'Takung Art Co., Ltd.',
    symbol: 'TKAT',
  },
  {
    value: 'TLTD',
    label:
      'TLTD,  FlexShares Morningstar Developed Markets ex-US Factor Tilt Index Fund',
    name: 'FlexShares Morningstar Developed Markets ex-US Factor Tilt Index Fund',
    symbol: 'TLTD',
  },
  {
    value: 'TMFC',
    label: 'TMFC,  Motley Fool 100 Index ETF',
    name: 'Motley Fool 100 Index ETF',
    symbol: 'TMFC',
  },
  {
    value: 'TMQ',
    label: 'TMQ,  Trilogy Metals Inc.',
    name: 'Trilogy Metals Inc.',
    symbol: 'TMQ',
  },
  {
    value: 'TOK',
    label: 'TOK,  iShares MSCI Kokusai ETF',
    name: 'iShares MSCI Kokusai ETF',
    symbol: 'TOK',
  },
  {
    value: 'TOLZ',
    label: 'TOLZ,  ProShares DJ Brookfield Global Infrastructure ETF',
    name: 'ProShares DJ Brookfield Global Infrastructure ETF',
    symbol: 'TOLZ',
  },
  {
    value: 'TPYP',
    label: 'TPYP,  Tortoise North American Pipeline Fund',
    name: 'Tortoise North American Pipeline Fund',
    symbol: 'TPYP',
  },
  {
    value: 'TPZ',
    label: 'TPZ,  Tortoise Power and Energy Infrastructure Fund, Inc.',
    name: 'Tortoise Power and Energy Infrastructure Fund, Inc.',
    symbol: 'TPZ',
  },
  {
    value: 'TRMD',
    label: 'TRMD,  TORM plc',
    name: 'TORM plc',
    symbol: 'TRMD',
  },
  {
    value: 'TRNS',
    label: 'TRNS,  Transcat, Inc.',
    name: 'Transcat, Inc.',
    symbol: 'TRNS',
  },
  {
    value: 'TRX',
    label: 'TRX,  TRX Gold Corporation',
    name: 'TRX Gold Corporation',
    symbol: 'TRX',
  },
  {
    value: 'TSRI',
    label: 'TSRI,  TSR, Inc.',
    name: 'TSR, Inc.',
    symbol: 'TSRI',
  },
  {
    value: 'TTAC',
    label: 'TTAC,  FCF US Quality ETF',
    name: 'FCF US Quality ETF',
    symbol: 'TTAC',
  },
  {
    value: 'TTAI',
    label: 'TTAI,  FCF International Quality ETF',
    name: 'FCF International Quality ETF',
    symbol: 'TTAI',
  },
  {
    value: 'TURN',
    label: 'TURN,  180 Degree Capital Corp.',
    name: '180 Degree Capital Corp.',
    symbol: 'TURN',
  },
  {
    value: 'TVE',
    label: 'TVE,  Tennessee Valley Authority PARRS A 2029',
    name: 'Tennessee Valley Authority PARRS A 2029',
    symbol: 'TVE',
  },
  {
    value: 'TWN',
    label: 'TWN,  The Taiwan Fund, Inc.',
    name: 'The Taiwan Fund, Inc.',
    symbol: 'TWN',
  },
  {
    value: 'TY',
    label: 'TY,  Tri-Continental Corporation',
    name: 'Tri-Continental Corporation',
    symbol: 'TY',
  },
  {
    value: 'TYD',
    label: 'TYD,  Direxion Daily 7-10 Year Treasury Bull 3X Shares',
    name: 'Direxion Daily 7-10 Year Treasury Bull 3X Shares',
    symbol: 'TYD',
  },
  {
    value: 'TYO',
    label: 'TYO,  Direxion Daily 7-10 Year Treasury Bear 3X Shares',
    name: 'Direxion Daily 7-10 Year Treasury Bear 3X Shares',
    symbol: 'TYO',
  },
  {
    value: 'TZOO',
    label: 'TZOO,  Travelzoo',
    name: 'Travelzoo',
    symbol: 'TZOO',
  },
  {
    value: 'UAE',
    label: 'UAE,  iShares MSCI UAE ETF',
    name: 'iShares MSCI UAE ETF',
    symbol: 'UAE',
  },
  {
    value: 'UAMY',
    label: 'UAMY,  United States Antimony Corporation',
    name: 'United States Antimony Corporation',
    symbol: 'UAMY',
  },
  {
    value: 'UBCP',
    label: 'UBCP,  United Bancorp, Inc.',
    name: 'United Bancorp, Inc.',
    symbol: 'UBCP',
  },
  {
    value: 'UBOH',
    label: 'UBOH,  United Bancshares, Inc.',
    name: 'United Bancshares, Inc.',
    symbol: 'UBOH',
  },
  {
    value: 'UBP',
    label: 'UBP,  Urstadt Biddle Properties Inc.',
    name: 'Urstadt Biddle Properties Inc.',
    symbol: 'UBP',
  },
  {
    value: 'UBR',
    label: 'UBR,  ProShares Ultra MSCI Brazil Capped',
    name: 'ProShares Ultra MSCI Brazil Capped',
    symbol: 'UBR',
  },
  {
    value: 'UCC',
    label: 'UCC,  ProShares Ultra Consumer Services',
    name: 'ProShares Ultra Consumer Services',
    symbol: 'UCC',
  },
  {
    value: 'UCIB',
    label:
      'UCIB,  UBS ETRACS UBS Bloomberg Constant Maturity Commodity Index (CMCI) Total Return ETN Series B',
    name: 'UBS ETRACS UBS Bloomberg Constant Maturity Commodity Index (CMCI) Total Return ETN Series B',
    symbol: 'UCIB',
  },
  {
    value: 'UDN',
    label: 'UDN,  Invesco DB US Dollar Index Bearish Fund',
    name: 'Invesco DB US Dollar Index Bearish Fund',
    symbol: 'UDN',
  },
  {
    value: 'UEVM',
    label: 'UEVM,  VictoryShares Emerging Markets Value Momentum ETF',
    name: 'VictoryShares Emerging Markets Value Momentum ETF',
    symbol: 'UEVM',
  },
  {
    value: 'UGE',
    label: 'UGE,  ProShares Ultra Consumer Goods',
    name: 'ProShares Ultra Consumer Goods',
    symbol: 'UGE',
  },
  {
    value: 'UITB',
    label: 'UITB,  VictoryShares USAA Core Intermediate-Term Bond ETF',
    name: 'VictoryShares USAA Core Intermediate-Term Bond ETF',
    symbol: 'UITB',
  },
  {
    value: 'UIVM',
    label: 'UIVM,  VictoryShares International Value Momentum ETF',
    name: 'VictoryShares International Value Momentum ETF',
    symbol: 'UIVM',
  },
  {
    value: 'UJB',
    label: 'UJB,  ProShares Ultra High Yield',
    name: 'ProShares Ultra High Yield',
    symbol: 'UJB',
  },
  {
    value: 'ULE',
    label: 'ULE,  ProShares Ultra Euro',
    name: 'ProShares Ultra Euro',
    symbol: 'ULE',
  },
  {
    value: 'ULST',
    label: 'ULST,  SPDR SSgA Ultra Short Term Bond ETF',
    name: 'SPDR SSgA Ultra Short Term Bond ETF',
    symbol: 'ULST',
  },
  {
    value: 'ULVM',
    label: 'ULVM,  VictoryShares US Value Momentum ETF',
    name: 'VictoryShares US Value Momentum ETF',
    symbol: 'ULVM',
  },
  {
    value: 'UMDD',
    label: 'UMDD,  ProShares UltraPro MidCap400',
    name: 'ProShares UltraPro MidCap400',
    symbol: 'UMDD',
  },
  {
    value: 'UNAM',
    label: 'UNAM,  Unico American Corporation',
    name: 'Unico American Corporation',
    symbol: 'UNAM',
  },
  {
    value: 'UNB',
    label: 'UNB,  Union Bankshares, Inc.',
    name: 'Union Bankshares, Inc.',
    symbol: 'UNB',
  },
  {
    value: 'UNL',
    label: 'UNL,  United States 12 Month Natural Gas Fund, LP',
    name: 'United States 12 Month Natural Gas Fund, LP',
    symbol: 'UNL',
  },
  {
    value: 'UNTY',
    label: 'UNTY,  Unity Bancorp, Inc.',
    name: 'Unity Bancorp, Inc.',
    symbol: 'UNTY',
  },
  {
    value: 'UONE',
    label: 'UONE,  Urban One, Inc.',
    name: 'Urban One, Inc.',
    symbol: 'UONE',
  },
  {
    value: 'UONEK',
    label: 'UONEK,  Urban One, Inc.',
    name: 'Urban One, Inc.',
    symbol: 'UONEK',
  },
  {
    value: 'UPV',
    label: 'UPV,  ProShares Ultra FTSE Europe',
    name: 'ProShares Ultra FTSE Europe',
    symbol: 'UPV',
  },
  {
    value: 'UPW',
    label: 'UPW,  ProShares Ultra Utilities',
    name: 'ProShares Ultra Utilities',
    symbol: 'UPW',
  },
  {
    value: 'URTH',
    label: 'URTH,  iShares MSCI World ETF',
    name: 'iShares MSCI World ETF',
    symbol: 'URTH',
  },
  {
    value: 'USAI',
    label: 'USAI,  Pacer American Energy Independence ETF',
    name: 'Pacer American Energy Independence ETF',
    symbol: 'USAI',
  },
  {
    value: 'USAP',
    label: 'USAP,  Universal Stainless & Alloy Products, Inc.',
    name: 'Universal Stainless & Alloy Products, Inc.',
    symbol: 'USAP',
  },
  {
    value: 'USAS',
    label: 'USAS,  Americas Gold and Silver Corporation',
    name: 'Americas Gold and Silver Corporation',
    symbol: 'USAS',
  },
  {
    value: 'USDP',
    label: 'USDP,  USD Partners LP',
    name: 'USD Partners LP',
    symbol: 'USDP',
  },
  {
    value: 'USEQ',
    label: 'USEQ,  Invesco Russell 1000 Enhanced Equal Weight ETF',
    name: 'Invesco Russell 1000 Enhanced Equal Weight ETF',
    symbol: 'USEQ',
  },
  {
    value: 'USFR',
    label: 'USFR,  WisdomTree Floating Rate Treasury Fund',
    name: 'WisdomTree Floating Rate Treasury Fund',
    symbol: 'USFR',
  },
  {
    value: 'USHY',
    label: 'USHY,  iShares Broad USD High Yield Corporate Bond ETF',
    name: 'iShares Broad USD High Yield Corporate Bond ETF',
    symbol: 'USHY',
  },
  {
    value: 'USL',
    label: 'USL,  United States 12 Month Oil Fund, LP',
    name: 'United States 12 Month Oil Fund, LP',
    symbol: 'USL',
  },
  {
    value: 'USLB',
    label: 'USLB,  Invesco Russell 1000 Low Beta Equal Weight ETF',
    name: 'Invesco Russell 1000 Low Beta Equal Weight ETF',
    symbol: 'USLB',
  },
  {
    value: 'USLM',
    label: 'USLM,  United States Lime & Minerals, Inc.',
    name: 'United States Lime & Minerals, Inc.',
    symbol: 'USLM',
  },
  {
    value: 'USMC',
    label: 'USMC,  Principal U.S. Mega-Cap ETF',
    name: 'Principal U.S. Mega-Cap ETF',
    symbol: 'USMC',
  },
  {
    value: 'USMF',
    label: 'USMF,  WisdomTree U.S. Multifactor Fund',
    name: 'WisdomTree U.S. Multifactor Fund',
    symbol: 'USMF',
  },
  {
    value: 'USOI',
    label: 'USOI,  Credit Suisse X-Links Crude Oil Shares Covered Call ETNs',
    name: 'Credit Suisse X-Links Crude Oil Shares Covered Call ETNs',
    symbol: 'USOI',
  },
  {
    value: 'UST',
    label: 'UST,  ProShares Ultra 7-10 Year Treasury',
    name: 'ProShares Ultra 7-10 Year Treasury',
    symbol: 'UST',
  },
  {
    value: 'USTB',
    label: 'USTB,  VictoryShares USAA Core Short-Term Bond ETF',
    name: 'VictoryShares USAA Core Short-Term Bond ETF',
    symbol: 'USTB',
  },
  {
    value: 'USVM',
    label: 'USVM,  VictoryShares US Small Mid Cap Value Momentum ETF',
    name: 'VictoryShares US Small Mid Cap Value Momentum ETF',
    symbol: 'USVM',
  },
  {
    value: 'UTES',
    label: 'UTES,  Virtus Reaves Utilities ETF',
    name: 'Virtus Reaves Utilities ETF',
    symbol: 'UTES',
  },
  {
    value: 'UTSL',
    label: 'UTSL,  Direxion Daily Utilities Bull 3X Shares',
    name: 'Direxion Daily Utilities Bull 3X Shares',
    symbol: 'UTSL',
  },
  {
    value: 'UXI',
    label: 'UXI,  ProShares Ultra Industrials',
    name: 'ProShares Ultra Industrials',
    symbol: 'UXI',
  },
  {
    value: 'UYM',
    label: 'UYM,  ProShares Ultra Basic Materials',
    name: 'ProShares Ultra Basic Materials',
    symbol: 'UYM',
  },
  {
    value: 'VALQ',
    label: 'VALQ,  American Century STOXX U.S. Quality Value ETF',
    name: 'American Century STOXX U.S. Quality Value ETF',
    symbol: 'VALQ',
  },
  {
    value: 'VBF',
    label: 'VBF,  Invesco Bond Fund',
    name: 'Invesco Bond Fund',
    symbol: 'VBF',
  },
  {
    value: 'VBFC',
    label: 'VBFC,  Village Bank and Trust Financial Corp.',
    name: 'Village Bank and Trust Financial Corp.',
    symbol: 'VBFC',
  },
  {
    value: 'VBND',
    label: 'VBND,  Vident Core U.S. Bond Strategy ETF',
    name: 'Vident Core U.S. Bond Strategy ETF',
    symbol: 'VBND',
  },
  {
    value: 'VEGA',
    label: 'VEGA,  AdvisorShares STAR Global Buy-Write ETF',
    name: 'AdvisorShares STAR Global Buy-Write ETF',
    symbol: 'VEGA',
  },
  {
    value: 'VEGI',
    label: 'VEGI,  iShares MSCI Global Agriculture Producers ETF',
    name: 'iShares MSCI Global Agriculture Producers ETF',
    symbol: 'VEGI',
  },
  {
    value: 'VFL',
    label: 'VFL,  Delaware Investments National Municipal Income Fund',
    name: 'Delaware Investments National Municipal Income Fund',
    symbol: 'VFL',
  },
  {
    value: 'VFLQ',
    label: 'VFLQ,  Vanguard U.S. Liquidity Factor ETF',
    name: 'Vanguard U.S. Liquidity Factor ETF',
    symbol: 'VFLQ',
  },
  {
    value: 'VFMF',
    label: 'VFMF,  Vanguard U.S. Multifactor ETF',
    name: 'Vanguard U.S. Multifactor ETF',
    symbol: 'VFMF',
  },
  {
    value: 'VFMO',
    label: 'VFMO,  Vanguard U.S. Momentum Factor ETF',
    name: 'Vanguard U.S. Momentum Factor ETF',
    symbol: 'VFMO',
  },
  {
    value: 'VFMV',
    label: 'VFMV,  Vanguard U.S. Minimum Volatility ETF',
    name: 'Vanguard U.S. Minimum Volatility ETF',
    symbol: 'VFMV',
  },
  {
    value: 'VFQY',
    label: 'VFQY,  Vanguard U.S. Quality Factor ETF',
    name: 'Vanguard U.S. Quality Factor ETF',
    symbol: 'VFQY',
  },
  {
    value: 'VFVA',
    label: 'VFVA,  Vanguard U.S. Value Factor ETF',
    name: 'Vanguard U.S. Value Factor ETF',
    symbol: 'VFVA',
  },
  {
    value: 'VGLT',
    label: 'VGLT,  Vanguard Long-Term Treasury Index Fund',
    name: 'Vanguard Long-Term Treasury Index Fund',
    symbol: 'VGLT',
  },
  {
    value: 'VIDI',
    label: 'VIDI,  Vident International Equity Fund',
    name: 'Vident International Equity Fund',
    symbol: 'VIDI',
  },
  {
    value: 'VII',
    label: 'VII,  7GC & Co. Holdings Inc.',
    name: '7GC & Co. Holdings Inc.',
    symbol: 'VII',
  },
  {
    value: 'VIOO',
    label: 'VIOO,  Vanguard S&P Small-Cap 600 Index Fund',
    name: 'Vanguard S&P Small-Cap 600 Index Fund',
    symbol: 'VIOO',
  },
  {
    value: 'VIOV',
    label: 'VIOV,  Vanguard S&P Small-Cap 600 Value Index Fund',
    name: 'Vanguard S&P Small-Cap 600 Value Index Fund',
    symbol: 'VIOV',
  },
  {
    value: 'VIRC',
    label: 'VIRC,  Virco Mfg. Corporation',
    name: 'Virco Mfg. Corporation',
    symbol: 'VIRC',
  },
  {
    value: 'VLT',
    label: 'VLT,  Invesco High Income Trust II',
    name: 'Invesco High Income Trust II',
    symbol: 'VLT',
  },
  {
    value: 'VLU',
    label: 'VLU,  SPDR S&P 1500 Value Tilt ETF',
    name: 'SPDR S&P 1500 Value Tilt ETF',
    symbol: 'VLU',
  },
  {
    value: 'VMOT',
    label: 'VMOT,  Alpha Architect Value Momentum Trend ETF',
    name: 'Alpha Architect Value Momentum Trend ETF',
    symbol: 'VMOT',
  },
  {
    value: 'VNCE',
    label: 'VNCE,  Vince Holding Corp.',
    name: 'Vince Holding Corp.',
    symbol: 'VNCE',
  },
  {
    value: 'VNLA',
    label: 'VNLA,  Janus Henderson Short Duration Income ETF',
    name: 'Janus Henderson Short Duration Income ETF',
    symbol: 'VNLA',
  },
  {
    value: 'VONE',
    label: 'VONE,  Vanguard Russell 1000 Index Fund',
    name: 'Vanguard Russell 1000 Index Fund',
    symbol: 'VONE',
  },
  {
    value: 'VONG',
    label: 'VONG,  Vanguard Russell 1000 Growth Index Fund',
    name: 'Vanguard Russell 1000 Growth Index Fund',
    symbol: 'VONG',
  },
  {
    value: 'VOOG',
    label: 'VOOG,  Vanguard S&P 500 Growth Index Fund',
    name: 'Vanguard S&P 500 Growth Index Fund',
    symbol: 'VOOG',
  },
  {
    value: 'VOOV',
    label: 'VOOV,  Vanguard S&P 500 Value Index Fund',
    name: 'Vanguard S&P 500 Value Index Fund',
    symbol: 'VOOV',
  },
  {
    value: 'VPU',
    label: 'VPU,  Vanguard Utilities Index Fund',
    name: 'Vanguard Utilities Index Fund',
    symbol: 'VPU',
  },
  {
    value: 'VRNA',
    label: 'VRNA,  Verona Pharma plc',
    name: 'Verona Pharma plc',
    symbol: 'VRNA',
  },
  {
    value: 'VSDA',
    label: 'VSDA,  VictoryShares Dividend Accelerator ETF',
    name: 'VictoryShares Dividend Accelerator ETF',
    symbol: 'VSDA',
  },
  {
    value: 'VTC',
    label: 'VTC,  Vanguard Total Corporate Bond ETF',
    name: 'Vanguard Total Corporate Bond ETF',
    symbol: 'VTC',
  },
  {
    value: 'VTGN',
    label: 'VTGN,  VistaGen Therapeutics, Inc.',
    name: 'VistaGen Therapeutics, Inc.',
    symbol: 'VTGN',
  },
  {
    value: 'VTNR',
    label: 'VTNR,  Vertex Energy, Inc.',
    name: 'Vertex Energy, Inc.',
    symbol: 'VTNR',
  },
  {
    value: 'VTWG',
    label: 'VTWG,  Vanguard Russell 2000 Growth Index Fund',
    name: 'Vanguard Russell 2000 Growth Index Fund',
    symbol: 'VTWG',
  },
  {
    value: 'VTWO',
    label: 'VTWO,  Vanguard Russell 2000 Index Fund',
    name: 'Vanguard Russell 2000 Index Fund',
    symbol: 'VTWO',
  },
  {
    value: 'VXRT',
    label: 'VXRT,  Vaxart, Inc.',
    name: 'Vaxart, Inc.',
    symbol: 'VXRT',
  },
  {
    value: 'VYMI',
    label: 'VYMI,  Vanguard International High Dividend Yield Fund',
    name: 'Vanguard International High Dividend Yield Fund',
    symbol: 'VYMI',
  },
  {
    value: 'WBIF',
    label: 'WBIF,  WBI BullBear Value 3000 ETF',
    name: 'WBI BullBear Value 3000 ETF',
    symbol: 'WBIF',
  },
  {
    value: 'WBIG',
    label: 'WBIG,  WBI BullBear Yield 3000 ETF',
    name: 'WBI BullBear Yield 3000 ETF',
    symbol: 'WBIG',
  },
  {
    value: 'WBII',
    label: 'WBII,  WBI BullBear Global Income ETF',
    name: 'WBI BullBear Global Income ETF',
    symbol: 'WBII',
  },
  {
    value: 'WBIL',
    label: 'WBIL,  WBI BullBear Quality 3000 ETF',
    name: 'WBI BullBear Quality 3000 ETF',
    symbol: 'WBIL',
  },
  {
    value: 'WBIY',
    label: 'WBIY,  WBI Power Factor High Dividend ETF',
    name: 'WBI Power Factor High Dividend ETF',
    symbol: 'WBIY',
  },
  {
    value: 'WCFB',
    label: 'WCFB,  WCF Bancorp, Inc.',
    name: 'WCF Bancorp, Inc.',
    symbol: 'WCFB',
  },
  {
    value: 'WEA',
    label: 'WEA,  Western Asset Premier Bond Fund',
    name: 'Western Asset Premier Bond Fund',
    symbol: 'WEA',
  },
  {
    value: 'WFHY',
    label: 'WFHY,  WisdomTree U.S. High Yield Corporate Bond Fund',
    name: 'WisdomTree U.S. High Yield Corporate Bond Fund',
    symbol: 'WFHY',
  },
  {
    value: 'WFIG',
    label: 'WFIG,  WisdomTree U.S. Corporate Bond Fund',
    name: 'WisdomTree U.S. Corporate Bond Fund',
    symbol: 'WFIG',
  },
  {
    value: 'WHLM',
    label: 'WHLM,  Wilhelmina International, Inc.',
    name: 'Wilhelmina International, Inc.',
    symbol: 'WHLM',
  },
  {
    value: 'WHLRD',
    label: 'WHLRD,  Wheeler Real Estate Investment Trust, Inc.',
    name: 'Wheeler Real Estate Investment Trust, Inc.',
    symbol: 'WHLRD',
  },
  {
    value: 'WIL',
    label: 'WIL,  Barclays Women in Leadership ETN',
    name: 'Barclays Women in Leadership ETN',
    symbol: 'WIL',
  },
  {
    value: 'WILC',
    label: 'WILC,  G. Willi-Food International Ltd.',
    name: 'G. Willi-Food International Ltd.',
    symbol: 'WILC',
  },
  {
    value: 'WINA',
    label: 'WINA,  Winmark Corporation',
    name: 'Winmark Corporation',
    symbol: 'WINA',
  },
  {
    value: 'WLDR',
    label: 'WLDR,  Affinity World Leaders Equity ETF',
    name: 'Affinity World Leaders Equity ETF',
    symbol: 'WLDR',
  },
  {
    value: 'WOOD',
    label: 'WOOD,  iShares Global Timber & Forestry ETF',
    name: 'iShares Global Timber & Forestry ETF',
    symbol: 'WOOD',
  },
  {
    value: 'WPS',
    label: 'WPS,  iShares International Developed Property ETF',
    name: 'iShares International Developed Property ETF',
    symbol: 'WPS',
  },
  {
    value: 'WRN',
    label: 'WRN,  Western Copper and Gold Corporation',
    name: 'Western Copper and Gold Corporation',
    symbol: 'WRN',
  },
  {
    value: 'WTMF',
    label: 'WTMF,  WisdomTree Managed Futures Strategy Fund',
    name: 'WisdomTree Managed Futures Strategy Fund',
    symbol: 'WTMF',
  },
  {
    value: 'WTT',
    label: 'WTT,  Wireless Telecom Group, Inc.',
    name: 'Wireless Telecom Group, Inc.',
    symbol: 'WTT',
  },
  {
    value: 'WVFC',
    label: 'WVFC,  WVS Financial Corp.',
    name: 'WVS Financial Corp.',
    symbol: 'WVFC',
  },
  {
    value: 'WVVI',
    label: 'WVVI,  Willamette Valley Vineyards, Inc.',
    name: 'Willamette Valley Vineyards, Inc.',
    symbol: 'WVVI',
  },
  {
    value: 'WVVIP',
    label: 'WVVIP,  Willamette Valley Vineyards, Inc.',
    name: 'Willamette Valley Vineyards, Inc.',
    symbol: 'WVVIP',
  },
  {
    value: 'XBIO',
    label: 'XBIO,  Xenetic Biosciences, Inc.',
    name: 'Xenetic Biosciences, Inc.',
    symbol: 'XBIO',
  },
  {
    value: 'XBIT',
    label: 'XBIT,  XBiotech Inc.',
    name: 'XBiotech Inc.',
    symbol: 'XBIT',
  },
  {
    value: 'XCEM',
    label: 'XCEM,  Columbia EM Core ex-China ETF',
    name: 'Columbia EM Core ex-China ETF',
    symbol: 'XCEM',
  },
  {
    value: 'XELB',
    label: 'XELB,  Xcel Brands, Inc.',
    name: 'Xcel Brands, Inc.',
    symbol: 'XELB',
  },
  {
    value: 'XFLT',
    label: 'XFLT,  XAI Octagon Floating Rate & Alternative Income Term Trust',
    name: 'XAI Octagon Floating Rate & Alternative Income Term Trust',
    symbol: 'XFLT',
  },
  {
    value: 'XHE',
    label: 'XHE,  SPDR S&P Health Care Equipment ETF',
    name: 'SPDR S&P Health Care Equipment ETF',
    symbol: 'XHE',
  },
  {
    value: 'XITK',
    label: 'XITK,  SPDR FactSet Innovative Technology ETF',
    name: 'SPDR FactSet Innovative Technology ETF',
    symbol: 'XITK',
  },
  {
    value: 'XMPT',
    label: 'XMPT,  VanEck CEF Municipal Income ETF',
    name: 'VanEck CEF Municipal Income ETF',
    symbol: 'XMPT',
  },
  {
    value: 'XRLV',
    label: 'XRLV,  Invesco S&P 500 ex-Rate Sensitive Low Volatility ETF',
    name: 'Invesco S&P 500 ex-Rate Sensitive Low Volatility ETF',
    symbol: 'XRLV',
  },
  {
    value: 'XSHD',
    label: 'XSHD,  Invesco S&P SmallCap High Dividend Low Volatility ETF',
    name: 'Invesco S&P SmallCap High Dividend Low Volatility ETF',
    symbol: 'XSHD',
  },
  {
    value: 'XSHQ',
    label: 'XSHQ,  Invesco S&P SmallCap Quality ETF',
    name: 'Invesco S&P SmallCap Quality ETF',
    symbol: 'XSHQ',
  },
  {
    value: 'XTL',
    label: 'XTL,  SPDR S&P Telecom ETF',
    name: 'SPDR S&P Telecom ETF',
    symbol: 'XTL',
  },
  {
    value: 'XTLB',
    label: 'XTLB,  XTL Biopharmaceuticals Ltd.',
    name: 'XTL Biopharmaceuticals Ltd.',
    symbol: 'XTLB',
  },
  {
    value: 'XWEB',
    label: 'XWEB,  SPDR S&P Internet ETF',
    name: 'SPDR S&P Internet ETF',
    symbol: 'XWEB',
  },
  {
    value: 'YLD',
    label: 'YLD,  Principal Active High Yield ETF',
    name: 'Principal Active High Yield ETF',
    symbol: 'YLD',
  },
  {
    value: 'YLDE',
    label: 'YLDE,  ClearBridge Dividend Strategy ESG ETF',
    name: 'ClearBridge Dividend Strategy ESG ETF',
    symbol: 'YLDE',
  },
  {
    value: 'YRIV',
    label: 'YRIV,  Yangtze River Port and Logistics Limited',
    name: 'Yangtze River Port and Logistics Limited',
    symbol: 'YRIV',
  },
  {
    value: 'YTEN',
    label: 'YTEN,  Yield10 Bioscience, Inc.',
    name: 'Yield10 Bioscience, Inc.',
    symbol: 'YTEN',
  },
  {
    value: 'YXI',
    label: 'YXI,  ProShares Short FTSE China 50',
    name: 'ProShares Short FTSE China 50',
    symbol: 'YXI',
  },
  {
    value: 'ZKIN',
    label: 'ZKIN,  ZK International Group Co., Ltd.',
    name: 'ZK International Group Co., Ltd.',
    symbol: 'ZKIN',
  },
  {
    value: 'ZOM',
    label: 'ZOM,  Zomedica Corp.',
    name: 'Zomedica Corp.',
    symbol: 'ZOM',
  },
  {
    value: 'ZYME',
    label: 'ZYME,  Zymeworks Inc.',
    name: 'Zymeworks Inc.',
    symbol: 'ZYME',
  },
  {
    value: 'POLIX',
    label: 'POLIX,  Polen Growth Fund Institutional Class',
    name: 'Polen Growth Fund Institutional Class',
    symbol: 'POLIX',
  },
  {
    value: 'ALC',
    label: 'ALC,  Alcon Inc.',
    name: 'Alcon Inc.',
    symbol: 'ALC',
  },
  {
    value: 'AMCR',
    label: 'AMCR,  Amcor plc',
    name: 'Amcor plc',
    symbol: 'AMCR',
  },
  {
    value: 'AMRX',
    label: 'AMRX,  Amneal Pharmaceuticals, Inc.',
    name: 'Amneal Pharmaceuticals, Inc.',
    symbol: 'AMRX',
  },
  {
    value: 'FINS',
    label: 'FINS,  Angel Oak Financial Strategies Income Term Trust',
    name: 'Angel Oak Financial Strategies Income Term Trust',
    symbol: 'FINS',
  },
  {
    value: 'ACA',
    label: 'ACA,  Arcosa, Inc.',
    name: 'Arcosa, Inc.',
    symbol: 'ACA',
  },
  {
    value: 'ARGO',
    label: 'ARGO,  Argo Group International Holdings, Ltd.',
    name: 'Argo Group International Holdings, Ltd.',
    symbol: 'ARGO',
  },
  {
    value: 'ARLO',
    label: 'ARLO,  Arlo Technologies, Inc.',
    name: 'Arlo Technologies, Inc.',
    symbol: 'ARLO',
  },
  {
    value: 'AMK',
    label: 'AMK,  AssetMark Financial Holdings, Inc.',
    name: 'AssetMark Financial Holdings, Inc.',
    symbol: 'AMK',
  },
  {
    value: 'ACB',
    label: 'ACB,  Aurora Cannabis Inc.',
    name: 'Aurora Cannabis Inc.',
    symbol: 'ACB',
  },
  {
    value: 'AVNS',
    label: 'AVNS,  Avanos Medical, Inc.',
    name: 'Avanos Medical, Inc.',
    symbol: 'AVNS',
  },
  {
    value: 'AVTR',
    label: 'AVTR,  Avantor, Inc.',
    name: 'Avantor, Inc.',
    symbol: 'AVTR',
  },
  {
    value: 'EQH',
    label: 'EQH,  Equitable Holdings, Inc.',
    name: 'Equitable Holdings, Inc.',
    symbol: 'EQH',
  },
  {
    value: 'AX',
    label: 'AX,  Axos Financial, Inc.',
    name: 'Axos Financial, Inc.',
    symbol: 'AX',
  },
  {
    value: 'BCSF',
    label: 'BCSF,  Bain Capital Specialty Finance, Inc.',
    name: 'Bain Capital Specialty Finance, Inc.',
    symbol: 'BCSF',
  },
  {
    value: 'BBDC',
    label: 'BBDC,  Barings BDC, Inc.',
    name: 'Barings BDC, Inc.',
    symbol: 'BBDC',
  },
  {
    value: 'BHC',
    label: 'BHC,  Bausch Health Companies Inc.',
    name: 'Bausch Health Companies Inc.',
    symbol: 'BHC',
  },
  {
    value: 'BBAR',
    label: 'BBAR,  Banco BBVA Argentina S.A.',
    name: 'Banco BBVA Argentina S.A.',
    symbol: 'BBAR',
  },
  {
    value: 'BRK-B',
    label: 'BRK-B,  Berkshire Hathaway Inc.',
    name: 'Berkshire Hathaway Inc.',
    symbol: 'BRK-B',
  },
  {
    value: 'BEST',
    label: 'BEST,  BEST Inc.',
    name: 'BEST Inc.',
    symbol: 'BEST',
  },
  {
    value: 'BH-A',
    label: 'BH-A,  Biglari Holdings Inc.',
    name: 'Biglari Holdings Inc.',
    symbol: 'BH-A',
  },
  {
    value: 'BIO-B',
    label: 'BIO-B,  Bio-Rad Laboratories, Inc.',
    name: 'Bio-Rad Laboratories, Inc.',
    symbol: 'BIO-B',
  },
  {
    value: 'BJ',
    label: "BJ,  BJ's Wholesale Club Holdings, Inc.",
    name: "BJ's Wholesale Club Holdings, Inc.",
    symbol: 'BJ',
  },
  {
    value: 'BSTZ',
    label: 'BSTZ,  BlackRock Science and Technology Trust II',
    name: 'BlackRock Science and Technology Trust II',
    symbol: 'BSTZ',
  },
  {
    value: 'BE',
    label: 'BE,  Bloom Energy Corporation',
    name: 'Bloom Energy Corporation',
    symbol: 'BE',
  },
  {
    value: 'BORR',
    label: 'BORR,  Borr Drilling Limited',
    name: 'Borr Drilling Limited',
    symbol: 'BORR',
  },
  {
    value: 'BHR',
    label: 'BHR,  Braemar Hotels & Resorts Inc.',
    name: 'Braemar Hotels & Resorts Inc.',
    symbol: 'BHR',
  },
  {
    value: 'MNRL',
    label: 'MNRL,  Brigham Minerals, Inc.',
    name: 'Brigham Minerals, Inc.',
    symbol: 'MNRL',
  },
  {
    value: 'BSIG',
    label: 'BSIG,  BrightSphere Investment Group Inc.',
    name: 'BrightSphere Investment Group Inc.',
    symbol: 'BSIG',
  },
  {
    value: 'BV',
    label: 'BV,  BrightView Holdings, Inc.',
    name: 'BrightView Holdings, Inc.',
    symbol: 'BV',
  },
  {
    value: 'BF-A',
    label: 'BF-A,  Brown-Forman Corporation',
    name: 'Brown-Forman Corporation',
    symbol: 'BF-A',
  },
  {
    value: 'BF-B',
    label: 'BF-B,  Brown-Forman Corporation',
    name: 'Brown-Forman Corporation',
    symbol: 'BF-B',
  },
  {
    value: 'CANG',
    label: 'CANG,  Cango Inc.',
    name: 'Cango Inc.',
    symbol: 'CANG',
  },
  {
    value: 'CGC',
    label: 'CGC,  Canopy Growth Corporation',
    name: 'Canopy Growth Corporation',
    symbol: 'CGC',
  },
  {
    value: 'CPRI',
    label: 'CPRI,  Capri Holdings Limited',
    name: 'Capri Holdings Limited',
    symbol: 'CPRI',
  },
  {
    value: 'EBR-B',
    label: 'EBR-B,  Centrais Elétricas Brasileiras S.A. - Eletrobrás',
    name: 'Centrais Elétricas Brasileiras S.A. - Eletrobrás',
    symbol: 'EBR-B',
  },
  {
    value: 'CDAY',
    label: 'CDAY,  Ceridian HCM Holding Inc.',
    name: 'Ceridian HCM Holding Inc.',
    symbol: 'CDAY',
  },
  {
    value: 'CHRA',
    label: 'CHRA,  Charah Solutions, Inc.',
    name: 'Charah Solutions, Inc.',
    symbol: 'CHRA',
  },
  {
    value: 'CHWY',
    label: 'CHWY,  Chewy, Inc.',
    name: 'Chewy, Inc.',
    symbol: 'CHWY',
  },
  {
    value: 'CWEN',
    label: 'CWEN,  Clearway Energy, Inc.',
    name: 'Clearway Energy, Inc.',
    symbol: 'CWEN',
  },
  {
    value: 'CWEN-A',
    label: 'CWEN-A,  Clearway Energy, Inc.',
    name: 'Clearway Energy, Inc.',
    symbol: 'CWEN-A',
  },
  {
    value: 'CNF',
    label: 'CNF,  CNFinance Holdings Limited',
    name: 'CNFinance Holdings Limited',
    symbol: 'CNF',
  },
  {
    value: 'CCEP',
    label: 'CCEP,  Coca-Cola Europacific Partners PLC',
    name: 'Coca-Cola Europacific Partners PLC',
    symbol: 'CCEP',
  },
  {
    value: 'CIG-C',
    label: 'CIG-C,  Companhia Energética de Minas Gerais',
    name: 'Companhia Energética de Minas Gerais',
    symbol: 'CIG-C',
  },
  {
    value: 'STZ-B',
    label: 'STZ-B,  Constellation Brands, Inc.',
    name: 'Constellation Brands, Inc.',
    symbol: 'STZ-B',
  },
  {
    value: 'CTVA',
    label: 'CTVA,  Corteva, Inc.',
    name: 'Corteva, Inc.',
    symbol: 'CTVA',
  },
  {
    value: 'CRD-A',
    label: 'CRD-A,  Crawford & Company',
    name: 'Crawford & Company',
    symbol: 'CRD-A',
  },
  {
    value: 'CRD-B',
    label: 'CRD-B,  Crawford & Company',
    name: 'Crawford & Company',
    symbol: 'CRD-B',
  },
  {
    value: 'CWK',
    label: 'CWK,  Cushman & Wakefield plc',
    name: 'Cushman & Wakefield plc',
    symbol: 'CWK',
  },
  {
    value: 'DELL',
    label: 'DELL,  Dell Technologies Inc.',
    name: 'Dell Technologies Inc.',
    symbol: 'DELL',
  },
  {
    value: 'DBI',
    label: 'DBI,  Designer Brands Inc.',
    name: 'Designer Brands Inc.',
    symbol: 'DBI',
  },
  {
    value: 'DOW',
    label: 'DOW,  Dow Inc.',
    name: 'Dow Inc.',
    symbol: 'DOW',
  },
  {
    value: 'DD',
    label: 'DD,  DuPont de Nemours, Inc.',
    name: 'DuPont de Nemours, Inc.',
    symbol: 'DD',
  },
  {
    value: 'DT',
    label: 'DT,  Dynatrace, Inc.',
    name: 'Dynatrace, Inc.',
    symbol: 'DT',
  },
  {
    value: 'ELAN',
    label: 'ELAN,  Elanco Animal Health Incorporated',
    name: 'Elanco Animal Health Incorporated',
    symbol: 'ELAN',
  },
  {
    value: 'ESTC',
    label: 'ESTC,  Elastic N.V.',
    name: 'Elastic N.V.',
    symbol: 'ESTC',
  },
  {
    value: 'ESI',
    label: 'ESI,  Element Solutions Inc',
    name: 'Element Solutions Inc',
    symbol: 'ESI',
  },
  {
    value: 'AKO-A',
    label: 'AKO-A,  Embotelladora Andina S.A.',
    name: 'Embotelladora Andina S.A.',
    symbol: 'AKO-A',
  },
  {
    value: 'AKO-B',
    label: 'AKO-B,  Embotelladora Andina S.A.',
    name: 'Embotelladora Andina S.A.',
    symbol: 'AKO-B',
  },
  {
    value: 'DAVA',
    label: 'DAVA,  Endava plc',
    name: 'Endava plc',
    symbol: 'DAVA',
  },
  {
    value: 'ET',
    label: 'ET,  Energy Transfer LP',
    name: 'Energy Transfer LP',
    symbol: 'ET',
  },
  {
    value: 'EQNR',
    label: 'EQNR,  Equinor ASA',
    name: 'Equinor ASA',
    symbol: 'EQNR',
  },
  {
    value: 'ETRN',
    label: 'ETRN,  Equitrans Midstream Corporation',
    name: 'Equitrans Midstream Corporation',
    symbol: 'ETRN',
  },
  {
    value: 'EPRT',
    label: 'EPRT,  Essential Properties Realty Trust, Inc.',
    name: 'Essential Properties Realty Trust, Inc.',
    symbol: 'EPRT',
  },
  {
    value: 'EB',
    label: 'EB,  Eventbrite, Inc.',
    name: 'Eventbrite, Inc.',
    symbol: 'EB',
  },
  {
    value: 'EVRG',
    label: 'EVRG,  Evergy, Inc.',
    name: 'Evergy, Inc.',
    symbol: 'EVRG',
  },
  {
    value: 'ACR',
    label: 'ACR,  ACRES Commercial Realty Corp.',
    name: 'ACRES Commercial Realty Corp.',
    symbol: 'ACR',
  },
  {
    value: 'FPAC',
    label: 'FPAC,  Far Peak Acquisition Corporation',
    name: 'Far Peak Acquisition Corporation',
    symbol: 'FPAC',
  },
  {
    value: 'FTCH',
    label: 'FTCH,  Farfetch Limited',
    name: 'Farfetch Limited',
    symbol: 'FTCH',
  },
  {
    value: 'FSLY',
    label: 'FSLY,  Fastly, Inc.',
    name: 'Fastly, Inc.',
    symbol: 'FSLY',
  },
  {
    value: 'AGM-A',
    label: 'AGM-A,  Federal Agricultural Mortgage Corporation',
    name: 'Federal Agricultural Mortgage Corporation',
    symbol: 'AGM-A',
  },
  {
    value: 'FVRR',
    label: 'FVRR,  Fiverr International Ltd.',
    name: 'Fiverr International Ltd.',
    symbol: 'FVRR',
  },
  {
    value: 'FLNG',
    label: 'FLNG,  FLEX LNG Ltd.',
    name: 'FLEX LNG Ltd.',
    symbol: 'FLNG',
  },
  {
    value: 'FSK',
    label: 'FSK,  FS KKR Capital Corp.',
    name: 'FS KKR Capital Corp.',
    symbol: 'FSK',
  },
  {
    value: 'GL',
    label: 'GL,  Globe Life Inc.',
    name: 'Globe Life Inc.',
    symbol: 'GL',
  },
  {
    value: 'EAF',
    label: 'EAF,  GrafTech International Ltd.',
    name: 'GrafTech International Ltd.',
    symbol: 'EAF',
  },
  {
    value: 'GTN-A',
    label: 'GTN-A,  Gray Television, Inc.',
    name: 'Gray Television, Inc.',
    symbol: 'GTN-A',
  },
  {
    value: 'GHG',
    label: 'GHG,  GreenTree Hospitality Group Ltd.',
    name: 'GreenTree Hospitality Group Ltd.',
    symbol: 'GHG',
  },
  {
    value: 'GEF-B',
    label: 'GEF-B,  Greif, Inc.',
    name: 'Greif, Inc.',
    symbol: 'GEF-B',
  },
  {
    value: 'GOTU',
    label: 'GOTU,  Gaotu Techedu Inc.',
    name: 'Gaotu Techedu Inc.',
    symbol: 'GOTU',
  },
  {
    value: 'HVT-A',
    label: 'HVT-A,  Haverty Furniture Companies, Inc.',
    name: 'Haverty Furniture Companies, Inc.',
    symbol: 'HVT-A',
  },
  {
    value: 'HEI-A',
    label: 'HEI-A,  HEICO Corporation',
    name: 'HEICO Corporation',
    symbol: 'HEI-A',
  },
  {
    value: 'HEXO',
    label: 'HEXO,  HEXO Corp.',
    name: 'HEXO Corp.',
    symbol: 'HEXO',
  },
  {
    value: 'HGLB',
    label: 'HGLB,  Highland Global Allocation Fund',
    name: 'Highland Global Allocation Fund',
    symbol: 'HGLB',
  },
  {
    value: 'HUYA',
    label: 'HUYA,  HUYA Inc.',
    name: 'HUYA Inc.',
    symbol: 'HUYA',
  },
  {
    value: 'IAA',
    label: 'IAA,  IAA, Inc.',
    name: 'IAA, Inc.',
    symbol: 'IAA',
  },
  {
    value: 'INSP',
    label: 'INSP,  Inspire Medical Systems, Inc.',
    name: 'Inspire Medical Systems, Inc.',
    symbol: 'INSP',
  },
  {
    value: 'IFS',
    label: 'IFS,  Intercorp Financial Services Inc.',
    name: 'Intercorp Financial Services Inc.',
    symbol: 'IFS',
  },
  {
    value: 'JEF',
    label: 'JEF,  Jefferies Financial Group Inc.',
    name: 'Jefferies Financial Group Inc.',
    symbol: 'JEF',
  },
  {
    value: 'JMIA',
    label: 'JMIA,  Jumia Technologies AG',
    name: 'Jumia Technologies AG',
    symbol: 'JMIA',
  },
  {
    value: 'KDP',
    label: 'KDP,  Keurig Dr Pepper Inc.',
    name: 'Keurig Dr Pepper Inc.',
    symbol: 'KDP',
  },
  {
    value: 'KTB',
    label: 'KTB,  Kontoor Brands, Inc.',
    name: 'Kontoor Brands, Inc.',
    symbol: 'KTB',
  },
  {
    value: 'LHX',
    label: 'LHX,  L3Harris Technologies, Inc.',
    name: 'L3Harris Technologies, Inc.',
    symbol: 'LHX',
  },
  {
    value: 'LEN-B',
    label: 'LEN-B,  Lennar Corporation',
    name: 'Lennar Corporation',
    symbol: 'LEN-B',
  },
  {
    value: 'LHC',
    label: 'LHC,  Leo Holdings Corp. II',
    name: 'Leo Holdings Corp. II',
    symbol: 'LHC',
  },
  {
    value: 'LEVI',
    label: 'LEVI,  Levi Strauss & Co.',
    name: 'Levi Strauss & Co.',
    symbol: 'LEVI',
  },
  {
    value: 'LIN',
    label: 'LIN,  Linde plc',
    name: 'Linde plc',
    symbol: 'LIN',
  },
  {
    value: 'LGF-A',
    label: 'LGF-A,  Lions Gate Entertainment Corp.',
    name: 'Lions Gate Entertainment Corp.',
    symbol: 'LGF-A',
  },
  {
    value: 'LGF-B',
    label: 'LGF-B,  Lions Gate Entertainment Corp.',
    name: 'Lions Gate Entertainment Corp.',
    symbol: 'LGF-B',
  },
  {
    value: 'LTHM',
    label: 'LTHM,  Livent Corporation',
    name: 'Livent Corporation',
    symbol: 'LTHM',
  },
  {
    value: 'RAMP',
    label: 'RAMP,  LiveRamp Holdings, Inc.',
    name: 'LiveRamp Holdings, Inc.',
    symbol: 'RAMP',
  },
  {
    value: 'MGY',
    label: 'MGY,  Magnolia Oil & Gas Corporation',
    name: 'Magnolia Oil & Gas Corporation',
    symbol: 'MGY',
  },
  {
    value: 'MEC',
    label: 'MEC,  Mayville Engineering Company, Inc.',
    name: 'Mayville Engineering Company, Inc.',
    symbol: 'MEC',
  },
  {
    value: 'MKC-V',
    label: 'MKC-V,  McCormick & Company, Incorporated',
    name: 'McCormick & Company, Incorporated',
    symbol: 'MKC-V',
  },
  {
    value: 'AMPY',
    label: 'AMPY,  Amplify Energy Corp.',
    name: 'Amplify Energy Corp.',
    symbol: 'AMPY',
  },
  {
    value: 'MUFG',
    label: 'MUFG,  Mitsubishi UFJ Financial Group, Inc.',
    name: 'Mitsubishi UFJ Financial Group, Inc.',
    symbol: 'MUFG',
  },
  {
    value: 'MOGU',
    label: 'MOGU,  MOGU Inc.',
    name: 'MOGU Inc.',
    symbol: 'MOGU',
  },
  {
    value: 'TAP-A',
    label: 'TAP-A,  Molson Coors Beverage Company',
    name: 'Molson Coors Beverage Company',
    symbol: 'TAP-A',
  },
  {
    value: 'MOG-A',
    label: 'MOG-A,  Moog Inc.',
    name: 'Moog Inc.',
    symbol: 'MOG-A',
  },
  {
    value: 'MOG-B',
    label: 'MOG-B,  Moog Inc.',
    name: 'Moog Inc.',
    symbol: 'MOG-B',
  },
  {
    value: 'CTOS',
    label: 'CTOS,  Custom Truck One Source, Inc.',
    name: 'Custom Truck One Source, Inc.',
    symbol: 'CTOS',
  },
  {
    value: 'NIO',
    label: 'NIO,  NIO Inc.',
    name: 'NIO Inc.',
    symbol: 'NIO',
  },
  {
    value: 'JHAA',
    label: 'JHAA,  Nuveen Corporate Income 2023 Target Term Fund',
    name: 'Nuveen Corporate Income 2023 Target Term Fund',
    symbol: 'JHAA',
  },
  {
    value: 'NVT',
    label: 'NVT,  nVent Electric plc',
    name: 'nVent Electric plc',
    symbol: 'NVT',
  },
  {
    value: 'ORCC',
    label: 'ORCC,  Owl Rock Capital Corporation',
    name: 'Owl Rock Capital Corporation',
    symbol: 'ORCC',
  },
  {
    value: 'PD',
    label: 'PD,  PagerDuty, Inc.',
    name: 'PagerDuty, Inc.',
    symbol: 'PD',
  },
  {
    value: 'PSN',
    label: 'PSN,  Parsons Corporation',
    name: 'Parsons Corporation',
    symbol: 'PSN',
  },
  {
    value: 'PVL',
    label: 'PVL,  Permianville Royalty Trust',
    name: 'Permianville Royalty Trust',
    symbol: 'PVL',
  },
  {
    value: 'PRT',
    label: 'PRT,  PermRock Royalty Trust',
    name: 'PermRock Royalty Trust',
    symbol: 'PRT',
  },
  {
    value: 'PBR-A',
    label: 'PBR-A,  Petróleo Brasileiro S.A. - Petrobras',
    name: 'Petróleo Brasileiro S.A. - Petrobras',
    symbol: 'PBR-A',
  },
  {
    value: 'PHR',
    label: 'PHR,  Phreesia, Inc.',
    name: 'Phreesia, Inc.',
    symbol: 'PHR',
  },
  {
    value: 'NRGX',
    label: 'NRGX,  PIMCO Energy and Tactical Credit Opportunities Fund',
    name: 'PIMCO Energy and Tactical Credit Opportunities Fund',
    symbol: 'NRGX',
  },
  {
    value: 'PINS',
    label: 'PINS,  Pinterest, Inc.',
    name: 'Pinterest, Inc.',
    symbol: 'PINS',
  },
  {
    value: 'PSTL',
    label: 'PSTL,  Postal Realty Trust, Inc.',
    name: 'Postal Realty Trust, Inc.',
    symbol: 'PSTL',
  },
  {
    value: 'RMED',
    label: 'RMED,  Ra Medical Systems, Inc.',
    name: 'Ra Medical Systems, Inc.',
    symbol: 'RMED',
  },
  {
    value: 'PACK',
    label: 'PACK,  Ranpak Holdings Corp.',
    name: 'Ranpak Holdings Corp.',
    symbol: 'PACK',
  },
  {
    value: 'RC',
    label: 'RC,  Ready Capital Corporation',
    name: 'Ready Capital Corporation',
    symbol: 'RC',
  },
  {
    value: 'REZI',
    label: 'REZI,  Resideo Technologies, Inc.',
    name: 'Resideo Technologies, Inc.',
    symbol: 'REZI',
  },
  {
    value: 'RVLV',
    label: 'RVLV,  Revolve Group, Inc.',
    name: 'Revolve Group, Inc.',
    symbol: 'RVLV',
  },
  {
    value: 'RMM',
    label: 'RMM,  Rivernorth Managed Duration Municipal Income Fund Inc.',
    name: 'Rivernorth Managed Duration Municipal Income Fund Inc.',
    symbol: 'RMM',
  },
  {
    value: 'RSF',
    label: 'RSF,  RiverNorth Specialty Finance Corporation',
    name: 'RiverNorth Specialty Finance Corporation',
    symbol: 'RSF',
  },
  {
    value: 'RMI',
    label: 'RMI,  RiverNorth Opportunistic Municipal Income Fund, Inc.',
    name: 'RiverNorth Opportunistic Municipal Income Fund, Inc.',
    symbol: 'RMI',
  },
  {
    value: 'SRL',
    label: 'SRL,  Scully Royalty Ltd.',
    name: 'Scully Royalty Ltd.',
    symbol: 'SRL',
  },
  {
    value: 'SITC',
    label: 'SITC,  SITE Centers Corp.',
    name: 'SITE Centers Corp.',
    symbol: 'SITC',
  },
  {
    value: 'SMAR',
    label: 'SMAR,  Smartsheet Inc.',
    name: 'Smartsheet Inc.',
    symbol: 'SMAR',
  },
  {
    value: 'SWI',
    label: 'SWI,  SolarWinds Corporation',
    name: 'SolarWinds Corporation',
    symbol: 'SWI',
  },
  {
    value: 'SPOT',
    label: 'SPOT,  Spotify Technology S.A.',
    name: 'Spotify Technology S.A.',
    symbol: 'SPOT',
  },
  {
    value: 'MSC',
    label: 'MSC,  Studio City International Holdings Limited',
    name: 'Studio City International Holdings Limited',
    symbol: 'MSC',
  },
  {
    value: 'STG',
    label: 'STG,  Sunlands Technology Group',
    name: 'Sunlands Technology Group',
    symbol: 'STG',
  },
  {
    value: 'NOVA',
    label: 'NOVA,  Sunnova Energy International Inc.',
    name: 'Sunnova Energy International Inc.',
    symbol: 'NOVA',
  },
  {
    value: 'SUZ',
    label: 'SUZ,  Suzano S.A.',
    name: 'Suzano S.A.',
    symbol: 'SUZ',
  },
  {
    value: 'TAK',
    label: 'TAK,  Takeda Pharmaceutical Company Limited',
    name: 'Takeda Pharmaceutical Company Limited',
    symbol: 'TAK',
  },
  {
    value: 'TALO',
    label: 'TALO,  Talos Energy Inc.',
    name: 'Talos Energy Inc.',
    symbol: 'TALO',
  },
  {
    value: 'TME',
    label: 'TME,  Tencent Music Entertainment Group',
    name: 'Tencent Music Entertainment Group',
    symbol: 'TME',
  },
  {
    value: 'TEAF',
    label: 'TEAF,  Ecofin Sustainable and Social Impact Term Fund',
    name: 'Ecofin Sustainable and Social Impact Term Fund',
    symbol: 'TEAF',
  },
  {
    value: 'USX',
    label: 'USX,  U.S. Xpress Enterprises, Inc.',
    name: 'U.S. Xpress Enterprises, Inc.',
    symbol: 'USX',
  },
  {
    value: 'UBER',
    label: 'UBER,  Uber Technologies, Inc.',
    name: 'Uber Technologies, Inc.',
    symbol: 'UBER',
  },
  {
    value: 'UI',
    label: 'UI,  Ubiquiti Inc.',
    name: 'Ubiquiti Inc.',
    symbol: 'UI',
  },
  {
    value: 'VAPO',
    label: 'VAPO,  Vapotherm, Inc.',
    name: 'Vapotherm, Inc.',
    symbol: 'VAPO',
  },
  {
    value: 'VCIF',
    label: 'VCIF,  Vertical Capital Income Fund',
    name: 'Vertical Capital Income Fund',
    symbol: 'VCIF',
  },
  {
    value: 'VIST',
    label: 'VIST,  Vista Energy, S.A.B. de C.V.',
    name: 'Vista Energy, S.A.B. de C.V.',
    symbol: 'VIST',
  },
  {
    value: 'WH',
    label: 'WH,  Wyndham Hotels & Resorts, Inc.',
    name: 'Wyndham Hotels & Resorts, Inc.',
    symbol: 'WH',
  },
  {
    value: 'XYF',
    label: 'XYF,  X Financial',
    name: 'X Financial',
    symbol: 'XYF',
  },
  {
    value: 'YETI',
    label: 'YETI,  YETI Holdings, Inc.',
    name: 'YETI Holdings, Inc.',
    symbol: 'YETI',
  },
  {
    value: 'ZUO',
    label: 'ZUO,  Zuora, Inc.',
    name: 'Zuora, Inc.',
    symbol: 'ZUO',
  },
  {
    value: 'YI',
    label: 'YI,  111, Inc.',
    name: '111, Inc.',
    symbol: 'YI',
  },
  {
    value: 'BCOW',
    label: 'BCOW,  1895 Bancorp of Wisconsin, Inc.',
    name: '1895 Bancorp of Wisconsin, Inc.',
    symbol: 'BCOW',
  },
  {
    value: 'QFIN',
    label: 'QFIN,  360 DigiTech, Inc.',
    name: '360 DigiTech, Inc.',
    symbol: 'QFIN',
  },
  {
    value: 'JFU',
    label: 'JFU,  9F Inc.',
    name: '9F Inc.',
    symbol: 'JFU',
  },
  {
    value: 'ADPT',
    label: 'ADPT,  Adaptive Biotechnologies Corporation',
    name: 'Adaptive Biotechnologies Corporation',
    symbol: 'ADPT',
  },
  {
    value: 'ADIL',
    label: 'ADIL,  Adial Pharmaceuticals, Inc.',
    name: 'Adial Pharmaceuticals, Inc.',
    symbol: 'ADIL',
  },
  {
    value: 'DWMC',
    label: 'DWMC,  AdvisorShares Dorsey Wright Micro-Cap ETF',
    name: 'AdvisorShares Dorsey Wright Micro-Cap ETF',
    symbol: 'DWMC',
  },
  {
    value: 'DWSH',
    label: 'DWSH,  AdvisorShares Dorsey Wright Short ETF',
    name: 'AdvisorShares Dorsey Wright Short ETF',
    symbol: 'DWSH',
  },
  {
    value: 'AFYA',
    label: 'AFYA,  Afya Limited',
    name: 'Afya Limited',
    symbol: 'AFYA',
  },
  {
    value: 'AGBA',
    label: 'AGBA,  AGBA Acquisition Limited',
    name: 'AGBA Acquisition Limited',
    symbol: 'AGBA',
  },
  {
    value: 'AGBAU',
    label: 'AGBAU,  AGBA Acquisition Limited',
    name: 'AGBA Acquisition Limited',
    symbol: 'AGBAU',
  },
  {
    value: 'AGMH',
    label: 'AGMH,  AGM Group Holdings Inc.',
    name: 'AGM Group Holdings Inc.',
    symbol: 'AGMH',
  },
  {
    value: 'AGNCM',
    label: 'AGNCM,  AGNC Investment Corp.',
    name: 'AGNC Investment Corp.',
    symbol: 'AGNCM',
  },
  {
    value: 'AIRTP',
    label: 'AIRTP,  Air T, Inc.',
    name: 'Air T, Inc.',
    symbol: 'AIRTP',
  },
  {
    value: 'ANTE',
    label: 'ANTE,  AirNet Technology Inc.',
    name: 'AirNet Technology Inc.',
    symbol: 'ANTE',
  },
  {
    value: 'KERN',
    label: 'KERN,  Akerna Corp.',
    name: 'Akerna Corp.',
    symbol: 'KERN',
  },
  {
    value: 'AKRO',
    label: 'AKRO,  Akero Therapeutics, Inc.',
    name: 'Akero Therapeutics, Inc.',
    symbol: 'AKRO',
  },
  {
    value: 'ALEC',
    label: 'ALEC,  Alector, Inc.',
    name: 'Alector, Inc.',
    symbol: 'ALEC',
  },
  {
    value: 'ALYA',
    label: 'ALYA,  Alithya Group Inc.',
    name: 'Alithya Group Inc.',
    symbol: 'ALYA',
  },
  {
    value: 'ALLK',
    label: 'ALLK,  Allakos Inc.',
    name: 'Allakos Inc.',
    symbol: 'ALLK',
  },
  {
    value: 'AESE',
    label: 'AESE,  Allied Esports Entertainment Inc.',
    name: 'Allied Esports Entertainment Inc.',
    symbol: 'AESE',
  },
  {
    value: 'ALLO',
    label: 'ALLO,  Allogene Therapeutics, Inc.',
    name: 'Allogene Therapeutics, Inc.',
    symbol: 'ALLO',
  },
  {
    value: 'ATHE',
    label: 'ATHE,  Alterity Therapeutics Limited',
    name: 'Alterity Therapeutics Limited',
    symbol: 'ATHE',
  },
  {
    value: 'AMAL',
    label: 'AMAL,  Amalgamated Financial Corp.',
    name: 'Amalgamated Financial Corp.',
    symbol: 'AMAL',
  },
  {
    value: 'ADN',
    label: 'ADN,  Advent Technologies Holdings, Inc.',
    name: 'Advent Technologies Holdings, Inc.',
    symbol: 'ADN',
  },
  {
    value: 'AMTB',
    label: 'AMTB,  Amerant Bancorp Inc.',
    name: 'Amerant Bancorp Inc.',
    symbol: 'AMTB',
  },
  {
    value: 'AREC',
    label: 'AREC,  American Resources Corporation',
    name: 'American Resources Corporation',
    symbol: 'AREC',
  },
  {
    value: 'CMMB',
    label: 'CMMB,  Chemomab Therapeutics Ltd.',
    name: 'Chemomab Therapeutics Ltd.',
    symbol: 'CMMB',
  },
  {
    value: 'ANIX',
    label: 'ANIX,  Anixa Biosciences, Inc.',
    name: 'Anixa Biosciences, Inc.',
    symbol: 'ANIX',
  },
  {
    value: 'ATEX',
    label: 'ATEX,  Anterix Inc.',
    name: 'Anterix Inc.',
    symbol: 'ATEX',
  },
  {
    value: 'APLT',
    label: 'APLT,  Applied Therapeutics, Inc.',
    name: 'Applied Therapeutics, Inc.',
    symbol: 'APLT',
  },
  {
    value: 'APTX',
    label: 'APTX,  Aptinyx Inc.',
    name: 'Aptinyx Inc.',
    symbol: 'APTX',
  },
  {
    value: 'APM',
    label: 'APM,  Aptorum Group Limited',
    name: 'Aptorum Group Limited',
    symbol: 'APM',
  },
  {
    value: 'APYX',
    label: 'APYX,  Apyx Medical Corporation',
    name: 'Apyx Medical Corporation',
    symbol: 'APYX',
  },
  {
    value: 'AQST',
    label: 'AQST,  Aquestive Therapeutics, Inc.',
    name: 'Aquestive Therapeutics, Inc.',
    symbol: 'AQST',
  },
  {
    value: 'ARAV',
    label: 'ARAV,  Aravive, Inc.',
    name: 'Aravive, Inc.',
    symbol: 'ARAV',
  },
  {
    value: 'ARCE',
    label: 'ARCE,  Arco Platform Limited',
    name: 'Arco Platform Limited',
    symbol: 'ARCE',
  },
  {
    value: 'ARDS',
    label: 'ARDS,  Aridis Pharmaceuticals, Inc.',
    name: 'Aridis Pharmaceuticals, Inc.',
    symbol: 'ARDS',
  },
  {
    value: 'ARTL',
    label: 'ARTL,  Artelo Biosciences, Inc.',
    name: 'Artelo Biosciences, Inc.',
    symbol: 'ARTL',
  },
  {
    value: 'ARVN',
    label: 'ARVN,  Arvinas, Inc.',
    name: 'Arvinas, Inc.',
    symbol: 'ARVN',
  },
  {
    value: 'ASLN',
    label: 'ASLN,  ASLAN Pharmaceuticals Limited',
    name: 'ASLAN Pharmaceuticals Limited',
    symbol: 'ASLN',
  },
  {
    value: 'ASRT',
    label: 'ASRT,  Assertio Holdings, Inc.',
    name: 'Assertio Holdings, Inc.',
    symbol: 'ASRT',
  },
  {
    value: 'ATIF',
    label: 'ATIF,  ATIF Holdings Limited',
    name: 'ATIF Holdings Limited',
    symbol: 'ATIF',
  },
  {
    value: 'AUB',
    label: 'AUB,  Atlantic Union Bankshares Corporation',
    name: 'Atlantic Union Bankshares Corporation',
    symbol: 'AUB',
  },
  {
    value: 'BCEL',
    label: 'BCEL,  Atreca, Inc.',
    name: 'Atreca, Inc.',
    symbol: 'BCEL',
  },
  {
    value: 'AEYE',
    label: 'AEYE,  AudioEye, Inc.',
    name: 'AudioEye, Inc.',
    symbol: 'AEYE',
  },
  {
    value: 'JG',
    label: 'JG,  Aurora Mobile Limited',
    name: 'Aurora Mobile Limited',
    symbol: 'JG',
  },
  {
    value: 'AUTL',
    label: 'AUTL,  Autolus Therapeutics plc',
    name: 'Autolus Therapeutics plc',
    symbol: 'AUTL',
  },
  {
    value: 'AVCO',
    label: 'AVCO,  Avalon GloboCare Corp.',
    name: 'Avalon GloboCare Corp.',
    symbol: 'AVCO',
  },
  {
    value: 'AVRO',
    label: 'AVRO,  AVROBIO, Inc.',
    name: 'AVROBIO, Inc.',
    symbol: 'AVRO',
  },
  {
    value: 'AXLA',
    label: 'AXLA,  Axcella Health Inc.',
    name: 'Axcella Health Inc.',
    symbol: 'AXLA',
  },
  {
    value: 'AXNX',
    label: 'AXNX,  Axonics, Inc.',
    name: 'Axonics, Inc.',
    symbol: 'AXNX',
  },
  {
    value: 'BFC',
    label: 'BFC,  Bank First Corporation',
    name: 'Bank First Corporation',
    symbol: 'BFC',
  },
  {
    value: 'OZK',
    label: 'OZK,  Bank OZK',
    name: 'Bank OZK',
    symbol: 'OZK',
  },
  {
    value: 'BSVN',
    label: 'BSVN,  Bank7 Corp.',
    name: 'Bank7 Corp.',
    symbol: 'BSVN',
  },
  {
    value: 'GLG',
    label: 'GLG,  TD Holdings, Inc.',
    name: 'TD Holdings, Inc.',
    symbol: 'GLG',
  },
  {
    value: 'BCML',
    label: 'BCML,  BayCom Corp',
    name: 'BayCom Corp',
    symbol: 'BCML',
  },
  {
    value: 'BLU',
    label: 'BLU,  BELLUS Health Inc.',
    name: 'BELLUS Health Inc.',
    symbol: 'BLU',
  },
  {
    value: 'BRY',
    label: 'BRY,  Berry Corporation',
    name: 'Berry Corporation',
    symbol: 'BRY',
  },
  {
    value: 'XAIR',
    label: 'XAIR,  Beyond Air, Inc.',
    name: 'Beyond Air, Inc.',
    symbol: 'XAIR',
  },
  {
    value: 'BYND',
    label: 'BYND,  Beyond Meat, Inc.',
    name: 'Beyond Meat, Inc.',
    symbol: 'BYND',
  },
  {
    value: 'BCYC',
    label: 'BCYC,  Bicycle Therapeutics plc',
    name: 'Bicycle Therapeutics plc',
    symbol: 'BCYC',
  },
  {
    value: 'BILI',
    label: 'BILI,  Bilibili Inc.',
    name: 'Bilibili Inc.',
    symbol: 'BILI',
  },
  {
    value: 'BCDA',
    label: 'BCDA,  BioCardia, Inc.',
    name: 'BioCardia, Inc.',
    symbol: 'BCDA',
  },
  {
    value: 'BNGO',
    label: 'BNGO,  Bionano Genomics, Inc.',
    name: 'Bionano Genomics, Inc.',
    symbol: 'BNGO',
  },
  {
    value: 'BNKL',
    label: 'BNKL,  Bionik Laboratories Corp.',
    name: 'Bionik Laboratories Corp.',
    symbol: 'BNKL',
  },
  {
    value: 'BSGM',
    label: 'BSGM,  BioSig Technologies, Inc.',
    name: 'BioSig Technologies, Inc.',
    symbol: 'BSGM',
  },
  {
    value: 'BIVI',
    label: 'BIVI,  BioVie Inc.',
    name: 'BioVie Inc.',
    symbol: 'BIVI',
  },
  {
    value: 'BHAT',
    label: 'BHAT,  Fujian Blue Hat Interactive Entertainment Technology Ltd.',
    name: 'Fujian Blue Hat Interactive Entertainment Technology Ltd.',
    symbol: 'BHAT',
  },
  {
    value: 'BWAY',
    label: 'BWAY,  BrainsWay Ltd.',
    name: 'BrainsWay Ltd.',
    symbol: 'BWAY',
  },
  {
    value: 'BBIO',
    label: 'BBIO,  BridgeBio Pharma, Inc.',
    name: 'BridgeBio Pharma, Inc.',
    symbol: 'BBIO',
  },
  {
    value: 'BHFAP',
    label: 'BHFAP,  Brighthouse Financial, Inc.',
    name: 'Brighthouse Financial, Inc.',
    symbol: 'BHFAP',
  },
  {
    value: 'BPYPO',
    label: 'BPYPO,  Brookfield Property Partners L.P.',
    name: 'Brookfield Property Partners L.P.',
    symbol: 'BPYPO',
  },
  {
    value: 'BPYPP',
    label: 'BPYPP,  Brookfield Property Partners L.P.',
    name: 'Brookfield Property Partners L.P.',
    symbol: 'BPYPP',
  },
  {
    value: 'DOOO',
    label: 'DOOO,  BRP Inc.',
    name: 'BRP Inc.',
    symbol: 'DOOO',
  },
  {
    value: 'BFST',
    label: 'BFST,  Business First Bancshares, Inc.',
    name: 'Business First Bancshares, Inc.',
    symbol: 'BFST',
  },
  {
    value: 'CMBM',
    label: 'CMBM,  Cambium Networks Corporation',
    name: 'Cambium Networks Corporation',
    symbol: 'CMBM',
  },
  {
    value: 'CBNK',
    label: 'CBNK,  Capital Bancorp, Inc.',
    name: 'Capital Bancorp, Inc.',
    symbol: 'CBNK',
  },
  {
    value: 'CARE',
    label: 'CARE,  Carter Bankshares, Inc.',
    name: 'Carter Bankshares, Inc.',
    symbol: 'CARE',
  },
  {
    value: 'SAVA',
    label: 'SAVA,  Cassava Sciences, Inc.',
    name: 'Cassava Sciences, Inc.',
    symbol: 'SAVA',
  },
  {
    value: 'CSTL',
    label: 'CSTL,  Castle Biosciences, Inc.',
    name: 'Castle Biosciences, Inc.',
    symbol: 'CSTL',
  },
  {
    value: 'CTRM',
    label: 'CTRM,  Castor Maritime Inc.',
    name: 'Castor Maritime Inc.',
    symbol: 'CTRM',
  },
  {
    value: 'CBAT',
    label: 'CBAT,  CBAK Energy Technology, Inc.',
    name: 'CBAK Energy Technology, Inc.',
    symbol: 'CBAT',
  },
  {
    value: 'CSSEP',
    label: 'CSSEP,  Chicken Soup for the Soul Entertainment, Inc.',
    name: 'Chicken Soup for the Soul Entertainment, Inc.',
    symbol: 'CSSEP',
  },
  {
    value: 'CIH',
    label: 'CIH,  China Index Holdings Limited',
    name: 'China Index Holdings Limited',
    symbol: 'CIH',
  },
  {
    value: 'SXTC',
    label: 'SXTC,  China SXT Pharmaceuticals, Inc.',
    name: 'China SXT Pharmaceuticals, Inc.',
    symbol: 'SXTC',
  },
  {
    value: 'CMCTP',
    label: 'CMCTP,  Creative Media & Community Trust Corporation',
    name: 'Creative Media & Community Trust Corporation',
    symbol: 'CMCTP',
  },
  {
    value: 'CLPS',
    label: 'CLPS,  CLPS Incorporation',
    name: 'CLPS Incorporation',
    symbol: 'CLPS',
  },
  {
    value: 'CCB',
    label: 'CCB,  Coastal Financial Corporation',
    name: 'Coastal Financial Corporation',
    symbol: 'CCB',
  },
  {
    value: 'CLBK',
    label: 'CLBK,  Columbia Financial, Inc.',
    name: 'Columbia Financial, Inc.',
    symbol: 'CLBK',
  },
  {
    value: 'SCOR',
    label: 'SCOR,  comScore, Inc.',
    name: 'comScore, Inc.',
    symbol: 'SCOR',
  },
  {
    value: 'BBCP',
    label: 'BBCP,  Concrete Pumping Holdings, Inc.',
    name: 'Concrete Pumping Holdings, Inc.',
    symbol: 'BBCP',
  },
  {
    value: 'ROAD',
    label: 'ROAD,  Construction Partners, Inc.',
    name: 'Construction Partners, Inc.',
    symbol: 'ROAD',
  },
  {
    value: 'CREX',
    label: 'CREX,  Creative Realities, Inc.',
    name: 'Creative Realities, Inc.',
    symbol: 'CREX',
  },
  {
    value: 'CRNX',
    label: 'CRNX,  Crinetics Pharmaceuticals, Inc.',
    name: 'Crinetics Pharmaceuticals, Inc.',
    symbol: 'CRNX',
  },
  {
    value: 'CFB',
    label: 'CFB,  CrossFirst Bankshares, Inc.',
    name: 'CrossFirst Bankshares, Inc.',
    symbol: 'CFB',
  },
  {
    value: 'CRWD',
    label: 'CRWD,  CrowdStrike Holdings, Inc.',
    name: 'CrowdStrike Holdings, Inc.',
    symbol: 'CRWD',
  },
  {
    value: 'CMLS',
    label: 'CMLS,  Cumulus Media Inc.',
    name: 'Cumulus Media Inc.',
    symbol: 'CMLS',
  },
  {
    value: 'CYCN',
    label: 'CYCN,  Cyclerion Therapeutics, Inc.',
    name: 'Cyclerion Therapeutics, Inc.',
    symbol: 'CYCN',
  },
  {
    value: 'DTSS',
    label: 'DTSS,  Datasea Inc.',
    name: 'Datasea Inc.',
    symbol: 'DTSS',
  },
  {
    value: 'DMTK',
    label: 'DMTK,  DermTech, Inc.',
    name: 'DermTech, Inc.',
    symbol: 'DMTK',
  },
  {
    value: 'DMAC',
    label: 'DMAC,  DiaMedica Therapeutics Inc.',
    name: 'DiaMedica Therapeutics Inc.',
    symbol: 'DMAC',
  },
  {
    value: 'DOCU',
    label: 'DOCU,  DocuSign, Inc.',
    name: 'DocuSign, Inc.',
    symbol: 'DOCU',
  },
  {
    value: 'DOMO',
    label: 'DOMO,  Domo, Inc.',
    name: 'Domo, Inc.',
    symbol: 'DOMO',
  },
  {
    value: 'DOYU',
    label: 'DOYU,  DouYu International Holdings Limited',
    name: 'DouYu International Holdings Limited',
    symbol: 'DOYU',
  },
  {
    value: 'DBX',
    label: 'DBX,  Dropbox, Inc.',
    name: 'Dropbox, Inc.',
    symbol: 'DBX',
  },
  {
    value: 'DYAI',
    label: 'DYAI,  Dyadic International, Inc.',
    name: 'Dyadic International, Inc.',
    symbol: 'DYAI',
  },
  {
    value: 'EDSA',
    label: 'EDSA,  Edesa Biotech, Inc.',
    name: 'Edesa Biotech, Inc.',
    symbol: 'EDSA',
  },
  {
    value: 'EDTX',
    label: 'EDTX,  EdtechX Holdings Acquisition Corp. II',
    name: 'EdtechX Holdings Acquisition Corp. II',
    symbol: 'EDTX',
  },
  {
    value: 'EDTXU',
    label: 'EDTXU,  EdtechX Holdings Acquisition Corp. II',
    name: 'EdtechX Holdings Acquisition Corp. II',
    symbol: 'EDTXU',
  },
  {
    value: 'SOLO',
    label: 'SOLO,  Electrameccanica Vehicles Corp.',
    name: 'Electrameccanica Vehicles Corp.',
    symbol: 'SOLO',
  },
  {
    value: 'ECOR',
    label: 'ECOR,  electroCore, Inc.',
    name: 'electroCore, Inc.',
    symbol: 'ECOR',
  },
  {
    value: 'ELOX',
    label: 'ELOX,  Eloxx Pharmaceuticals, Inc.',
    name: 'Eloxx Pharmaceuticals, Inc.',
    symbol: 'ELOX',
  },
  {
    value: 'EMMA',
    label: 'EMMA,  Emmaus Life Sciences, Inc.',
    name: 'Emmaus Life Sciences, Inc.',
    symbol: 'EMMA',
  },
  {
    value: 'ENLV',
    label: 'ENLV,  Enlivex Therapeutics Ltd.',
    name: 'Enlivex Therapeutics Ltd.',
    symbol: 'ENLV',
  },
  {
    value: 'ENOB',
    label: 'ENOB,  Enochian Biosciences, Inc.',
    name: 'Enochian Biosciences, Inc.',
    symbol: 'ENOB',
  },
  {
    value: 'ESGRO',
    label: 'ESGRO,  Enstar Group Limited',
    name: 'Enstar Group Limited',
    symbol: 'ESGRO',
  },
  {
    value: 'ESGRP',
    label: 'ESGRP,  Enstar Group Limited',
    name: 'Enstar Group Limited',
    symbol: 'ESGRP',
  },
  {
    value: 'ENTX',
    label: 'ENTX,  Entera Bio Ltd.',
    name: 'Entera Bio Ltd.',
    symbol: 'ENTX',
  },
  {
    value: 'EPSN',
    label: 'EPSN,  Epsilon Energy Ltd.',
    name: 'Epsilon Energy Ltd.',
    symbol: 'EPSN',
  },
  {
    value: 'EQ',
    label: 'EQ,  Equillium, Inc.',
    name: 'Equillium, Inc.',
    symbol: 'EQ',
  },
  {
    value: 'ESTA',
    label: 'ESTA,  Establishment Labs Holdings Inc.',
    name: 'Establishment Labs Holdings Inc.',
    symbol: 'ESTA',
  },
  {
    value: 'ETON',
    label: 'ETON,  Eton Pharmaceuticals, Inc.',
    name: 'Eton Pharmaceuticals, Inc.',
    symbol: 'ETON',
  },
  {
    value: 'EDRY',
    label: 'EDRY,  EuroDry Ltd.',
    name: 'EuroDry Ltd.',
    symbol: 'EDRY',
  },
  {
    value: 'EVLO',
    label: 'EVLO,  Evelo Biosciences, Inc.',
    name: 'Evelo Biosciences, Inc.',
    symbol: 'EVLO',
  },
  {
    value: 'EVER',
    label: 'EVER,  EverQuote, Inc.',
    name: 'EverQuote, Inc.',
    symbol: 'EVER',
  },
  {
    value: 'EVOP',
    label: 'EVOP,  EVO Payments, Inc.',
    name: 'EVO Payments, Inc.',
    symbol: 'EVOP',
  },
  {
    value: 'XCUR',
    label: 'XCUR,  Exicure, Inc.',
    name: 'Exicure, Inc.',
    symbol: 'XCUR',
  },
  {
    value: 'EXPI',
    label: 'EXPI,  eXp World Holdings, Inc.',
    name: 'eXp World Holdings, Inc.',
    symbol: 'EXPI',
  },
  {
    value: 'EYPT',
    label: 'EYPT,  EyePoint Pharmaceuticals, Inc.',
    name: 'EyePoint Pharmaceuticals, Inc.',
    symbol: 'EYPT',
  },
  {
    value: 'FITBP',
    label: 'FITBP,  Fifth Third Bancorp',
    name: 'Fifth Third Bancorp',
    symbol: 'FITBP',
  },
  {
    value: 'FXNC',
    label: 'FXNC,  First National Corporation',
    name: 'First National Corporation',
    symbol: 'FXNC',
  },
  {
    value: 'FSEA',
    label: 'FSEA,  First Seacoast Bancorp, Inc.',
    name: 'First Seacoast Bancorp, Inc.',
    symbol: 'FSEA',
  },
  {
    value: 'DDIV',
    label: 'DDIV,  First Trust Dorsey Wright Momentum & Dividend ETF',
    name: 'First Trust Dorsey Wright Momentum & Dividend ETF',
    symbol: 'DDIV',
  },
  {
    value: 'DVOL',
    label: 'DVOL,  First Trust Dorsey Wright Momentum & Low Volatility ETF',
    name: 'First Trust Dorsey Wright Momentum & Low Volatility ETF',
    symbol: 'DVOL',
  },
  {
    value: 'DVLU',
    label: 'DVLU,  First Trust Dorsey Wright Momentum & Value ETF',
    name: 'First Trust Dorsey Wright Momentum & Value ETF',
    symbol: 'DVLU',
  },
  {
    value: 'DALI',
    label: 'DALI,  First Trust Dorsey Wright DALI 1 ETF',
    name: 'First Trust Dorsey Wright DALI 1 ETF',
    symbol: 'DALI',
  },
  {
    value: 'FDNI',
    label: 'FDNI,  First Trust Dow Jones International Internet ETF',
    name: 'First Trust Dow Jones International Internet ETF',
    symbol: 'FDNI',
  },
  {
    value: 'NFTY',
    label: 'NFTY,  First Trust India NIFTY 50 Equal Weight ETF',
    name: 'First Trust India NIFTY 50 Equal Weight ETF',
    symbol: 'NFTY',
  },
  {
    value: 'NXTG',
    label: 'NXTG,  First Trust IndXX NextG ETF',
    name: 'First Trust IndXX NextG ETF',
    symbol: 'NXTG',
  },
  {
    value: 'FPXE',
    label: 'FPXE,  First Trust IPOX Europe Equity Opportunities ETF',
    name: 'First Trust IPOX Europe Equity Opportunities ETF',
    symbol: 'FPXE',
  },
  {
    value: 'LDSF',
    label: 'LDSF,  First Trust Low Duration Strategic Focus ETF',
    name: 'First Trust Low Duration Strategic Focus ETF',
    symbol: 'LDSF',
  },
  {
    value: 'FID',
    label: 'FID,  First Trust S&P International Dividend Aristocrats Fund',
    name: 'First Trust S&P International Dividend Aristocrats Fund',
    symbol: 'FID',
  },
  {
    value: 'MYFW',
    label: 'MYFW,  First Western Financial, Inc.',
    name: 'First Western Financial, Inc.',
    symbol: 'MYFW',
  },
  {
    value: 'FLNT',
    label: 'FLNT,  Fluent, Inc.',
    name: 'Fluent, Inc.',
    symbol: 'FLNT',
  },
  {
    value: 'FLUX',
    label: 'FLUX,  Flux Power Holdings, Inc.',
    name: 'Flux Power Holdings, Inc.',
    symbol: 'FLUX',
  },
  {
    value: 'FOCS',
    label: 'FOCS,  Focus Financial Partners Inc.',
    name: 'Focus Financial Partners Inc.',
    symbol: 'FOCS',
  },
  {
    value: 'FRAF',
    label: 'FRAF,  Franklin Financial Services Corporation',
    name: 'Franklin Financial Services Corporation',
    symbol: 'FRAF',
  },
  {
    value: 'FTDR',
    label: 'FTDR,  Frontdoor, Inc.',
    name: 'Frontdoor, Inc.',
    symbol: 'FTDR',
  },
  {
    value: 'FULC',
    label: 'FULC,  Fulcrum Therapeutics, Inc.',
    name: 'Fulcrum Therapeutics, Inc.',
    symbol: 'FULC',
  },
  {
    value: 'FVCB',
    label: 'FVCB,  FVCBankcorp, Inc.',
    name: 'FVCBankcorp, Inc.',
    symbol: 'FVCB',
  },
  {
    value: 'GMDA',
    label: 'GMDA,  Gamida Cell Ltd.',
    name: 'Gamida Cell Ltd.',
    symbol: 'GMDA',
  },
  {
    value: 'GNFT',
    label: 'GNFT,  Genfit S.A.',
    name: 'Genfit S.A.',
    symbol: 'GNFT',
  },
  {
    value: 'GMAB',
    label: 'GMAB,  Genmab A/S',
    name: 'Genmab A/S',
    symbol: 'GMAB',
  },
  {
    value: 'GNPX',
    label: 'GNPX,  Genprex, Inc.',
    name: 'Genprex, Inc.',
    symbol: 'GNPX',
  },
  {
    value: 'DRIV',
    label: 'DRIV,  Global X Autonomous & Electric Vehicles ETF',
    name: 'Global X Autonomous & Electric Vehicles ETF',
    symbol: 'DRIV',
  },
  {
    value: 'CLOU',
    label: 'CLOU,  Global X Cloud Computing ETF',
    name: 'Global X Cloud Computing ETF',
    symbol: 'CLOU',
  },
  {
    value: 'EBIZ',
    label: 'EBIZ,  Global X E-commerce ETF',
    name: 'Global X E-commerce ETF',
    symbol: 'EBIZ',
  },
  {
    value: 'AIQ',
    label: 'AIQ,  Global X Artificial Intelligence & Technology ETF',
    name: 'Global X Artificial Intelligence & Technology ETF',
    symbol: 'AIQ',
  },
  {
    value: 'GNOM',
    label: 'GNOM,  Global X Genomics & Biotechnology ETF',
    name: 'Global X Genomics & Biotechnology ETF',
    symbol: 'GNOM',
  },
  {
    value: 'GSHD',
    label: 'GSHD,  Goosehead Insurance, Inc',
    name: 'Goosehead Insurance, Inc',
    symbol: 'GSHD',
  },
  {
    value: 'GOSS',
    label: 'GOSS,  Gossamer Bio, Inc.',
    name: 'Gossamer Bio, Inc.',
    symbol: 'GOSS',
  },
  {
    value: 'GNLN',
    label: 'GNLN,  Greenlane Holdings, Inc.',
    name: 'Greenlane Holdings, Inc.',
    symbol: 'GNLN',
  },
  {
    value: 'GRNQ',
    label: 'GRNQ,  Greenpro Capital Corp.',
    name: 'Greenpro Capital Corp.',
    symbol: 'GRNQ',
  },
  {
    value: 'GRIN',
    label: 'GRIN,  Grindrod Shipping Holdings Ltd.',
    name: 'Grindrod Shipping Holdings Ltd.',
    symbol: 'GRIN',
  },
  {
    value: 'GRTS',
    label: 'GRTS,  Gritstone bio, Inc.',
    name: 'Gritstone bio, Inc.',
    symbol: 'GRTS',
  },
  {
    value: 'GO',
    label: 'GO,  Grocery Outlet Holding Corp.',
    name: 'Grocery Outlet Holding Corp.',
    symbol: 'GO',
  },
  {
    value: 'GH',
    label: 'GH,  Guardant Health, Inc.',
    name: 'Guardant Health, Inc.',
    symbol: 'GH',
  },
  {
    value: 'GHSI',
    label: 'GHSI,  Guardion Health Sciences, Inc.',
    name: 'Guardion Health Sciences, Inc.',
    symbol: 'GHSI',
  },
  {
    value: 'HWC',
    label: 'HWC,  Hancock Whitney Corporation',
    name: 'Hancock Whitney Corporation',
    symbol: 'HWC',
  },
  {
    value: 'HARP',
    label: 'HARP,  Harpoon Therapeutics, Inc.',
    name: 'Harpoon Therapeutics, Inc.',
    symbol: 'HARP',
  },
  {
    value: 'HROW',
    label: 'HROW,  Harrow Health, Inc.',
    name: 'Harrow Health, Inc.',
    symbol: 'HROW',
  },
  {
    value: 'HHR',
    label: 'HHR,  HeadHunter Group PLC',
    name: 'HeadHunter Group PLC',
    symbol: 'HHR',
  },
  {
    value: 'HCAT',
    label: 'HCAT,  Health Catalyst, Inc.',
    name: 'Health Catalyst, Inc.',
    symbol: 'HCAT',
  },
  {
    value: 'HLIO',
    label: 'HLIO,  Helios Technologies, Inc.',
    name: 'Helios Technologies, Inc.',
    symbol: 'HLIO',
  },
  {
    value: 'HSDT',
    label: 'HSDT,  Helius Medical Technologies, Inc.',
    name: 'Helius Medical Technologies, Inc.',
    symbol: 'HSDT',
  },
  {
    value: 'HEPA',
    label: 'HEPA,  Hepion Pharmaceuticals, Inc.',
    name: 'Hepion Pharmaceuticals, Inc.',
    symbol: 'HEPA',
  },
  {
    value: 'HFFG',
    label: 'HFFG,  HF Foods Group Inc.',
    name: 'HF Foods Group Inc.',
    symbol: 'HFFG',
  },
  {
    value: 'FIXX',
    label: 'FIXX,  Homology Medicines, Inc.',
    name: 'Homology Medicines, Inc.',
    symbol: 'FIXX',
  },
  {
    value: 'HOOK',
    label: 'HOOK,  HOOKIPA Pharma Inc.',
    name: 'HOOKIPA Pharma Inc.',
    symbol: 'HOOK',
  },
  {
    value: 'HOTH',
    label: 'HOTH,  Hoth Therapeutics, Inc.',
    name: 'Hoth Therapeutics, Inc.',
    symbol: 'HOTH',
  },
  {
    value: 'HYRE',
    label: 'HYRE,  HyreCar Inc.',
    name: 'HyreCar Inc.',
    symbol: 'HYRE',
  },
  {
    value: 'IIIV',
    label: 'IIIV,  i3 Verticals, Inc.',
    name: 'i3 Verticals, Inc.',
    symbol: 'IIIV',
  },
  {
    value: 'IDEX',
    label: 'IDEX,  Ideanomics, Inc.',
    name: 'Ideanomics, Inc.',
    symbol: 'IDEX',
  },
  {
    value: 'IDYA',
    label: 'IDYA,  IDEAYA Biosciences, Inc.',
    name: 'IDEAYA Biosciences, Inc.',
    symbol: 'IDYA',
  },
  {
    value: 'IHRT',
    label: 'IHRT,  iHeartMedia, Inc.',
    name: 'iHeartMedia, Inc.',
    symbol: 'IHRT',
  },
  {
    value: 'IMBI',
    label: 'IMBI,  iMedia Brands, Inc.',
    name: 'iMedia Brands, Inc.',
    symbol: 'IMBI',
  },
  {
    value: 'IMUX',
    label: 'IMUX,  Immunic, Inc.',
    name: 'Immunic, Inc.',
    symbol: 'IMUX',
  },
  {
    value: 'IMV',
    label: 'IMV,  IMV Inc.',
    name: 'IMV Inc.',
    symbol: 'IMV',
  },
  {
    value: 'INMD',
    label: 'INMD,  InMode Ltd.',
    name: 'InMode Ltd.',
    symbol: 'INMD',
  },
  {
    value: 'INMB',
    label: 'INMB,  INmune Bio, Inc.',
    name: 'INmune Bio, Inc.',
    symbol: 'INMB',
  },
  {
    value: 'IMXI',
    label: 'IMXI,  International Money Express, Inc.',
    name: 'International Money Express, Inc.',
    symbol: 'IMXI',
  },
  {
    value: 'ISDX',
    label: 'ISDX,  Invesco RAFI Strategic Developed ex-US ETF',
    name: 'Invesco RAFI Strategic Developed ex-US ETF',
    symbol: 'ISDX',
  },
  {
    value: 'ISEM',
    label: 'ISEM,  Invesco RAFI Strategic Emerging Markets ETF',
    name: 'Invesco RAFI Strategic Emerging Markets ETF',
    symbol: 'ISEM',
  },
  {
    value: 'IUS',
    label: 'IUS,  Invesco RAFI Strategic US ETF',
    name: 'Invesco RAFI Strategic US ETF',
    symbol: 'IUS',
  },
  {
    value: 'IUSS',
    label: 'IUSS,  Invesco RAFI Strategic US Small Company ETF',
    name: 'Invesco RAFI Strategic US Small Company ETF',
    symbol: 'IUSS',
  },
  {
    value: 'ICMB',
    label: 'ICMB,  Investcorp Credit Management BDC, Inc.',
    name: 'Investcorp Credit Management BDC, Inc.',
    symbol: 'ICMB',
  },
  {
    value: 'IQ',
    label: 'IQ,  iQIYI, Inc.',
    name: 'iQIYI, Inc.',
    symbol: 'IQ',
  },
  {
    value: 'USIG',
    label: 'USIG,  iShares Broad USD Investment Grade Corporate Bond ETF',
    name: 'iShares Broad USD Investment Grade Corporate Bond ETF',
    symbol: 'USIG',
  },
  {
    value: 'SUSL',
    label: 'SUSL,  iShares ESG MSCI USA Leaders ETF',
    name: 'iShares ESG MSCI USA Leaders ETF',
    symbol: 'SUSL',
  },
  {
    value: 'BGRN',
    label: 'BGRN,  iShares USD Green Bond ETF',
    name: 'iShares USD Green Bond ETF',
    symbol: 'BGRN',
  },
  {
    value: 'IGIB',
    label: 'IGIB,  iShares 5-10 Year Investment Grade Corporate Bond ETF',
    name: 'iShares 5-10 Year Investment Grade Corporate Bond ETF',
    symbol: 'IGIB',
  },
  {
    value: 'SDG',
    label: 'SDG,  iShares MSCI Global Impact ETF',
    name: 'iShares MSCI Global Impact ETF',
    symbol: 'SDG',
  },
  {
    value: 'EWJV',
    label: 'EWJV,  iShares MSCI Japan Value ETF',
    name: 'iShares MSCI Japan Value ETF',
    symbol: 'EWJV',
  },
  {
    value: 'IGSB',
    label: 'IGSB,  iShares 1-5 Year Investment Grade Corporate Bond ETF',
    name: 'iShares 1-5 Year Investment Grade Corporate Bond ETF',
    symbol: 'IGSB',
  },
  {
    value: 'ITRM',
    label: 'ITRM,  Iterum Therapeutics plc',
    name: 'Iterum Therapeutics plc',
    symbol: 'ITRM',
  },
  {
    value: 'ISEE',
    label: 'ISEE,  IVERIC bio, Inc.',
    name: 'IVERIC bio, Inc.',
    symbol: 'ISEE',
  },
  {
    value: 'JRSH',
    label: 'JRSH,  Jerash Holdings (US), Inc.',
    name: 'Jerash Holdings (US), Inc.',
    symbol: 'JRSH',
  },
  {
    value: 'JFIN',
    label: 'JFIN,  Jiayin Group Inc.',
    name: 'Jiayin Group Inc.',
    symbol: 'JFIN',
  },
  {
    value: 'KXIN',
    label: 'KXIN,  Kaixin Auto Holdings',
    name: 'Kaixin Auto Holdings',
    symbol: 'KXIN',
  },
  {
    value: 'KLDO',
    label: 'KLDO,  Kaleido Biosciences, Inc.',
    name: 'Kaleido Biosciences, Inc.',
    symbol: 'KLDO',
  },
  {
    value: 'KRTX',
    label: 'KRTX,  Karuna Therapeutics, Inc.',
    name: 'Karuna Therapeutics, Inc.',
    symbol: 'KRTX',
  },
  {
    value: 'KZR',
    label: 'KZR,  Kezar Life Sciences, Inc.',
    name: 'Kezar Life Sciences, Inc.',
    symbol: 'KZR',
  },
  {
    value: 'KNSA',
    label: 'KNSA,  Kiniksa Pharmaceuticals, Ltd.',
    name: 'Kiniksa Pharmaceuticals, Ltd.',
    symbol: 'KNSA',
  },
  {
    value: 'KLXE',
    label: 'KLXE,  KLX Energy Services Holdings, Inc.',
    name: 'KLX Energy Services Holdings, Inc.',
    symbol: 'KLXE',
  },
  {
    value: 'KOD',
    label: 'KOD,  Kodiak Sciences Inc.',
    name: 'Kodiak Sciences Inc.',
    symbol: 'KOD',
  },
  {
    value: 'KRUS',
    label: 'KRUS,  Kura Sushi USA, Inc.',
    name: 'Kura Sushi USA, Inc.',
    symbol: 'KRUS',
  },
  {
    value: 'LEGH',
    label: 'LEGH,  Legacy Housing Corporation',
    name: 'Legacy Housing Corporation',
    symbol: 'LEGH',
  },
  {
    value: 'LMST',
    label: 'LMST,  Limestone Bancorp, Inc.',
    name: 'Limestone Bancorp, Inc.',
    symbol: 'LMST',
  },
  {
    value: 'YVR',
    label: 'YVR,  Liquid Media Group Ltd.',
    name: 'Liquid Media Group Ltd.',
    symbol: 'YVR',
  },
  {
    value: 'LQDA',
    label: 'LQDA,  Liquidia Corporation',
    name: 'Liquidia Corporation',
    symbol: 'LQDA',
  },
  {
    value: 'LOGC',
    label: 'LOGC,  LogicBio Therapeutics, Inc.',
    name: 'LogicBio Therapeutics, Inc.',
    symbol: 'LOGC',
  },
  {
    value: 'CHNA',
    label: 'CHNA,  Loncar China BioPharma ETF',
    name: 'Loncar China BioPharma ETF',
    symbol: 'CHNA',
  },
  {
    value: 'LKCO',
    label: 'LKCO,  Luokung Technology Corp.',
    name: 'Luokung Technology Corp.',
    symbol: 'LKCO',
  },
  {
    value: 'LYFT',
    label: 'LYFT,  Lyft, Inc.',
    name: 'Lyft, Inc.',
    symbol: 'LYFT',
  },
  {
    value: 'MGTA',
    label: 'MGTA,  Magenta Therapeutics, Inc.',
    name: 'Magenta Therapeutics, Inc.',
    symbol: 'MGTA',
  },
  {
    value: 'MNSB',
    label: 'MNSB,  MainStreet Bancshares, Inc.',
    name: 'MainStreet Bancshares, Inc.',
    symbol: 'MNSB',
  },
  {
    value: 'MRKR',
    label: 'MRKR,  Marker Therapeutics, Inc.',
    name: 'Marker Therapeutics, Inc.',
    symbol: 'MRKR',
  },
  {
    value: 'MDJH',
    label: 'MDJH,  MDJM Ltd',
    name: 'MDJM Ltd',
    symbol: 'MDJH',
  },
  {
    value: 'MGTX',
    label: 'MGTX,  MeiraGTx Holdings plc',
    name: 'MeiraGTx Holdings plc',
    symbol: 'MGTX',
  },
  {
    value: 'MBINO',
    label: 'MBINO,  Merchants Bancorp',
    name: 'Merchants Bancorp',
    symbol: 'MBINO',
  },
  {
    value: 'MBINP',
    label: 'MBINP,  Merchants Bancorp',
    name: 'Merchants Bancorp',
    symbol: 'MBINP',
  },
  {
    value: 'MREO',
    label: 'MREO,  Mereo BioPharma Group plc',
    name: 'Mereo BioPharma Group plc',
    symbol: 'MREO',
  },
  {
    value: 'MESA',
    label: 'MESA,  Mesa Air Group, Inc.',
    name: 'Mesa Air Group, Inc.',
    symbol: 'MESA',
  },
  {
    value: 'MSVB',
    label: 'MSVB,  Mid-Southern Bancorp, Inc.',
    name: 'Mid-Southern Bancorp, Inc.',
    symbol: 'MSVB',
  },
  {
    value: 'MIST',
    label: 'MIST,  Milestone Pharmaceuticals Inc.',
    name: 'Milestone Pharmaceuticals Inc.',
    symbol: 'MIST',
  },
  {
    value: 'TIGO',
    label: 'TIGO,  Millicom International Cellular S.A.',
    name: 'Millicom International Cellular S.A.',
    symbol: 'TIGO',
  },
  {
    value: 'MIRM',
    label: 'MIRM,  Mirum Pharmaceuticals, Inc.',
    name: 'Mirum Pharmaceuticals, Inc.',
    symbol: 'MIRM',
  },
  {
    value: 'MTC',
    label: 'MTC,  MMTec, Inc.',
    name: 'MMTec, Inc.',
    symbol: 'MTC',
  },
  {
    value: 'MRNA',
    label: 'MRNA,  Moderna, Inc.',
    name: 'Moderna, Inc.',
    symbol: 'MRNA',
  },
  {
    value: 'MOGO',
    label: 'MOGO,  Mogo Inc.',
    name: 'Mogo Inc.',
    symbol: 'MOGO',
  },
  {
    value: 'ATER',
    label: 'ATER,  Aterian, Inc.',
    name: 'Aterian, Inc.',
    symbol: 'ATER',
  },
  {
    value: 'MORF',
    label: 'MORF,  Morphic Holding, Inc.',
    name: 'Morphic Holding, Inc.',
    symbol: 'MORF',
  },
  {
    value: 'MOR',
    label: 'MOR,  MorphoSys AG',
    name: 'MorphoSys AG',
    symbol: 'MOR',
  },
  {
    value: 'COOP',
    label: 'COOP,  Mr. Cooper Group Inc.',
    name: 'Mr. Cooper Group Inc.',
    symbol: 'COOP',
  },
  {
    value: 'NRC',
    label: 'NRC,  National Research Corporation',
    name: 'National Research Corporation',
    symbol: 'NRC',
  },
  {
    value: 'NLTX',
    label: 'NLTX,  Neoleukin Therapeutics, Inc.',
    name: 'Neoleukin Therapeutics, Inc.',
    symbol: 'NLTX',
  },
  {
    value: 'NEPH',
    label: 'NEPH,  Nephros, Inc.',
    name: 'Nephros, Inc.',
    symbol: 'NEPH',
  },
  {
    value: 'NBSE',
    label: 'NBSE,  NeuBase Therapeutics, Inc.',
    name: 'NeuBase Therapeutics, Inc.',
    symbol: 'NBSE',
  },
  {
    value: 'STIM',
    label: 'STIM,  Neuronetics, Inc.',
    name: 'Neuronetics, Inc.',
    symbol: 'STIM',
  },
  {
    value: 'NFE',
    label: 'NFE,  New Fortress Energy Inc.',
    name: 'New Fortress Energy Inc.',
    symbol: 'NFE',
  },
  {
    value: 'NXTC',
    label: 'NXTC,  NextCure, Inc.',
    name: 'NextCure, Inc.',
    symbol: 'NXTC',
  },
  {
    value: 'NXGN',
    label: 'NXGN,  NextGen Healthcare, Inc.',
    name: 'NextGen Healthcare, Inc.',
    symbol: 'NXGN',
  },
  {
    value: 'BIMI',
    label: 'BIMI,  BIMI International Medical, Inc.',
    name: 'BIMI International Medical, Inc.',
    symbol: 'BIMI',
  },
  {
    value: 'NGM',
    label: 'NGM,  NGM Biopharmaceuticals, Inc.',
    name: 'NGM Biopharmaceuticals, Inc.',
    symbol: 'NGM',
  },
  {
    value: 'NIU',
    label: 'NIU,  Niu Technologies',
    name: 'Niu Technologies',
    symbol: 'NIU',
  },
  {
    value: 'LASR',
    label: 'LASR,  nLIGHT, Inc.',
    name: 'nLIGHT, Inc.',
    symbol: 'LASR',
  },
  {
    value: 'OPI',
    label: 'OPI,  Office Properties Income Trust',
    name: 'Office Properties Income Trust',
    symbol: 'OPI',
  },
  {
    value: 'OCCI',
    label: 'OCCI,  OFS Credit Company, Inc.',
    name: 'OFS Credit Company, Inc.',
    symbol: 'OCCI',
  },
  {
    value: 'ONCY',
    label: 'ONCY,  Oncolytics Biotech Inc.',
    name: 'Oncolytics Biotech Inc.',
    symbol: 'ONCY',
  },
  {
    value: 'ONCT',
    label: 'ONCT,  Oncternal Therapeutics, Inc.',
    name: 'Oncternal Therapeutics, Inc.',
    symbol: 'ONCT',
  },
  {
    value: 'OSPN',
    label: 'OSPN,  OneSpan Inc.',
    name: 'OneSpan Inc.',
    symbol: 'OSPN',
  },
  {
    value: 'OSW',
    label: 'OSW,  OneSpaWorld Holdings Limited',
    name: 'OneSpaWorld Holdings Limited',
    symbol: 'OSW',
  },
  {
    value: 'OPBK',
    label: 'OPBK,  OP Bancorp',
    name: 'OP Bancorp',
    symbol: 'OPBK',
  },
  {
    value: 'OPRA',
    label: 'OPRA,  Opera Limited',
    name: 'Opera Limited',
    symbol: 'OPRA',
  },
  {
    value: 'OPRX',
    label: 'OPRX,  OptimizeRx Corporation',
    name: 'OptimizeRx Corporation',
    symbol: 'OPRX',
  },
  {
    value: 'ORTX',
    label: 'ORTX,  Orchard Therapeutics plc',
    name: 'Orchard Therapeutics plc',
    symbol: 'ORTX',
  },
  {
    value: 'OGI',
    label: 'OGI,  OrganiGram Holdings Inc.',
    name: 'OrganiGram Holdings Inc.',
    symbol: 'OGI',
  },
  {
    value: 'ORGO',
    label: 'ORGO,  Organogenesis Holdings Inc.',
    name: 'Organogenesis Holdings Inc.',
    symbol: 'ORGO',
  },
  {
    value: 'OBNK',
    label: 'OBNK,  Origin Bancorp, Inc.',
    name: 'Origin Bancorp, Inc.',
    symbol: 'OBNK',
  },
  {
    value: 'OTLK',
    label: 'OTLK,  Outlook Therapeutics, Inc.',
    name: 'Outlook Therapeutics, Inc.',
    symbol: 'OTLK',
  },
  {
    value: 'OXSQ',
    label: 'OXSQ,  Oxford Square Capital Corp.',
    name: 'Oxford Square Capital Corp.',
    symbol: 'OXSQ',
  },
  {
    value: 'HERD',
    label: 'HERD,  Pacer Cash Cows Fund of Funds ETF',
    name: 'Pacer Cash Cows Fund of Funds ETF',
    symbol: 'HERD',
  },
  {
    value: 'ECOW',
    label: 'ECOW,  Pacer Emerging Markets Cash Cows 100 ETF',
    name: 'Pacer Emerging Markets Cash Cows 100 ETF',
    symbol: 'ECOW',
  },
  {
    value: 'PLMR',
    label: 'PLMR,  Palomar Holdings, Inc.',
    name: 'Palomar Holdings, Inc.',
    symbol: 'PLMR',
  },
  {
    value: 'PAYS',
    label: 'PAYS,  PaySign, Inc.',
    name: 'PaySign, Inc.',
    symbol: 'PAYS',
  },
  {
    value: 'PCB',
    label: 'PCB,  PCB Bancorp',
    name: 'PCB Bancorp',
    symbol: 'PCB',
  },
  {
    value: 'PDSB',
    label: 'PDSB,  PDS Biotechnology Corporation',
    name: 'PDS Biotechnology Corporation',
    symbol: 'PDSB',
  },
  {
    value: 'PSNL',
    label: 'PSNL,  Personalis, Inc.',
    name: 'Personalis, Inc.',
    symbol: 'PSNL',
  },
  {
    value: 'PHAS',
    label: 'PHAS,  PhaseBio Pharmaceuticals, Inc.',
    name: 'PhaseBio Pharmaceuticals, Inc.',
    symbol: 'PHAS',
  },
  {
    value: 'PHIO',
    label: 'PHIO,  Phio Pharmaceuticals Corp.',
    name: 'Phio Pharmaceuticals Corp.',
    symbol: 'PHIO',
  },
  {
    value: 'PHUN',
    label: 'PHUN,  Phunware, Inc.',
    name: 'Phunware, Inc.',
    symbol: 'PHUN',
  },
  {
    value: 'PLL',
    label: 'PLL,  Piedmont Lithium Inc.',
    name: 'Piedmont Lithium Inc.',
    symbol: 'PLL',
  },
  {
    value: 'PDD',
    label: 'PDD,  Pinduoduo Inc.',
    name: 'Pinduoduo Inc.',
    symbol: 'PDD',
  },
  {
    value: 'PT',
    label: 'PT,  Pintec Technology Holdings Limited',
    name: 'Pintec Technology Holdings Limited',
    symbol: 'PT',
  },
  {
    value: 'PBFS',
    label: 'PBFS,  Pioneer Bancorp, Inc.',
    name: 'Pioneer Bancorp, Inc.',
    symbol: 'PBFS',
  },
  {
    value: 'PSTV',
    label: 'PSTV,  Plus Therapeutics, Inc.',
    name: 'Plus Therapeutics, Inc.',
    symbol: 'PSTV',
  },
  {
    value: 'PTE',
    label: 'PTE,  PolarityTE, Inc.',
    name: 'PolarityTE, Inc.',
    symbol: 'PTE',
  },
  {
    value: 'PPHI',
    label: 'PPHI,  Positive Physicians Holdings, Inc.',
    name: 'Positive Physicians Holdings, Inc.',
    symbol: 'PPHI',
  },
  {
    value: 'PBTS',
    label: 'PBTS,  Powerbridge Technologies Co., Ltd.',
    name: 'Powerbridge Technologies Co., Ltd.',
    symbol: 'PBTS',
  },
  {
    value: 'DTIL',
    label: 'DTIL,  Precision BioSciences, Inc.',
    name: 'Precision BioSciences, Inc.',
    symbol: 'DTIL',
  },
  {
    value: 'POAI',
    label: 'POAI,  Predictive Oncology Inc.',
    name: 'Predictive Oncology Inc.',
    symbol: 'POAI',
  },
  {
    value: 'PBIO',
    label: 'PBIO,  Pressure BioSciences, Inc.',
    name: 'Pressure BioSciences, Inc.',
    symbol: 'PBIO',
  },
  {
    value: 'PRTH',
    label: 'PRTH,  Priority Technology Holdings, Inc.',
    name: 'Priority Technology Holdings, Inc.',
    symbol: 'PRTH',
  },
  {
    value: 'PRVB',
    label: 'PRVB,  Provention Bio, Inc.',
    name: 'Provention Bio, Inc.',
    symbol: 'PRVB',
  },
  {
    value: 'PHCF',
    label: 'PHCF,  Puhui Wealth Investment Management Co., Ltd.',
    name: 'Puhui Wealth Investment Management Co., Ltd.',
    symbol: 'PHCF',
  },
  {
    value: 'PUYI',
    label: 'PUYI,  Puyi Inc.',
    name: 'Puyi Inc.',
    symbol: 'PUYI',
  },
  {
    value: 'QTT',
    label: 'QTT,  Qutoutiao Inc.',
    name: 'Qutoutiao Inc.',
    symbol: 'QTT',
  },
  {
    value: 'RRBI',
    label: 'RRBI,  Red River Bancshares, Inc.',
    name: 'Red River Bancshares, Inc.',
    symbol: 'RRBI',
  },
  {
    value: 'RDVT',
    label: 'RDVT,  Red Violet, Inc.',
    name: 'Red Violet, Inc.',
    symbol: 'RDVT',
  },
  {
    value: 'REKR',
    label: 'REKR,  Rekor Systems, Inc.',
    name: 'Rekor Systems, Inc.',
    symbol: 'REKR',
  },
  {
    value: 'RPAY',
    label: 'RPAY,  Repay Holdings Corporation',
    name: 'Repay Holdings Corporation',
    symbol: 'RPAY',
  },
  {
    value: 'REPL',
    label: 'REPL,  Replimune Group, Inc.',
    name: 'Replimune Group, Inc.',
    symbol: 'REPL',
  },
  {
    value: 'RBKB',
    label: 'RBKB,  Rhinebeck Bancorp, Inc.',
    name: 'Rhinebeck Bancorp, Inc.',
    symbol: 'RBKB',
  },
  {
    value: 'RMBI',
    label: 'RMBI,  Richmond Mutual Bancorporation, Inc.',
    name: 'Richmond Mutual Bancorporation, Inc.',
    symbol: 'RMBI',
  },
  {
    value: 'RUBY',
    label: 'RUBY,  Rubius Therapeutics, Inc.',
    name: 'Rubius Therapeutics, Inc.',
    symbol: 'RUBY',
  },
  {
    value: 'SFET',
    label: 'SFET,  Safe-T Group Ltd',
    name: 'Safe-T Group Ltd',
    symbol: 'SFET',
  },
  {
    value: 'SLRX',
    label: 'SLRX,  Salarius Pharmaceuticals, Inc.',
    name: 'Salarius Pharmaceuticals, Inc.',
    symbol: 'SLRX',
  },
  {
    value: 'SRRK',
    label: 'SRRK,  Scholar Rock Holding Corporation',
    name: 'Scholar Rock Holding Corporation',
    symbol: 'SRRK',
  },
  {
    value: 'SCPL',
    label: 'SCPL,  SciPlay Corporation',
    name: 'SciPlay Corporation',
    symbol: 'SCPL',
  },
  {
    value: 'WORX',
    label: 'WORX,  SCWorx Corp.',
    name: 'SCWorx Corp.',
    symbol: 'WORX',
  },
  {
    value: 'SEEL',
    label: 'SEEL,  Seelos Therapeutics, Inc.',
    name: 'Seelos Therapeutics, Inc.',
    symbol: 'SEEL',
  },
  {
    value: 'SESN',
    label: 'SESN,  Sesen Bio, Inc.',
    name: 'Sesen Bio, Inc.',
    symbol: 'SESN',
  },
  {
    value: 'SWAV',
    label: 'SWAV,  ShockWave Medical, Inc.',
    name: 'ShockWave Medical, Inc.',
    symbol: 'SWAV',
  },
  {
    value: 'SIBN',
    label: 'SIBN,  SI-BONE, Inc.',
    name: 'SI-BONE, Inc.',
    symbol: 'SIBN',
  },
  {
    value: 'SIGA',
    label: 'SIGA,  SIGA Technologies, Inc.',
    name: 'SIGA Technologies, Inc.',
    symbol: 'SIGA',
  },
  {
    value: 'SILK',
    label: 'SILK,  Silk Road Medical, Inc',
    name: 'Silk Road Medical, Inc',
    symbol: 'SILK',
  },
  {
    value: 'SINT',
    label: 'SINT,  Sintx Technologies, Inc.',
    name: 'Sintx Technologies, Inc.',
    symbol: 'SINT',
  },
  {
    value: 'SONM',
    label: 'SONM,  Sonim Technologies, Inc.',
    name: 'Sonim Technologies, Inc.',
    symbol: 'SONM',
  },
  {
    value: 'SONO',
    label: 'SONO,  Sonos, Inc.',
    name: 'Sonos, Inc.',
    symbol: 'SONO',
  },
  {
    value: 'SOHON',
    label: 'SOHON,  Sotherly Hotels Inc.',
    name: 'Sotherly Hotels Inc.',
    symbol: 'SOHON',
  },
  {
    value: 'SPFI',
    label: 'SPFI,  South Plains Financial, Inc.',
    name: 'South Plains Financial, Inc.',
    symbol: 'SPFI',
  },
  {
    value: 'SY',
    label: 'SY,  So-Young International Inc.',
    name: 'So-Young International Inc.',
    symbol: 'SY',
  },
  {
    value: 'SLNG',
    label: 'SLNG,  Stabilis Solutions, Inc.',
    name: 'Stabilis Solutions, Inc.',
    symbol: 'SLNG',
  },
  {
    value: 'MITO',
    label: 'MITO,  Stealth BioTherapeutics Corp',
    name: 'Stealth BioTherapeutics Corp',
    symbol: 'MITO',
  },
  {
    value: 'STOK',
    label: 'STOK,  Stoke Therapeutics, Inc.',
    name: 'Stoke Therapeutics, Inc.',
    symbol: 'STOK',
  },
  {
    value: 'STNE',
    label: 'STNE,  StoneCo Ltd.',
    name: 'StoneCo Ltd.',
    symbol: 'STNE',
  },
  {
    value: 'WISA',
    label: 'WISA,  WiSA Technologies, Inc.',
    name: 'WiSA Technologies, Inc.',
    symbol: 'WISA',
  },
  {
    value: 'SNDL',
    label: 'SNDL,  SNDL Inc.',
    name: 'SNDL Inc.',
    symbol: 'SNDL',
  },
  {
    value: 'SLGG',
    label: 'SLGG,  Super League Gaming, Inc.',
    name: 'Super League Gaming, Inc.',
    symbol: 'SLGG',
  },
  {
    value: 'SURF',
    label: 'SURF,  Surface Oncology, Inc.',
    name: 'Surface Oncology, Inc.',
    symbol: 'SURF',
  },
  {
    value: 'STRO',
    label: 'STRO,  Sutro Biopharma, Inc.',
    name: 'Sutro Biopharma, Inc.',
    symbol: 'STRO',
  },
  {
    value: 'SSSS',
    label: 'SSSS,  SuRo Capital Corp.',
    name: 'SuRo Capital Corp.',
    symbol: 'SSSS',
  },
  {
    value: 'TAOP',
    label: 'TAOP,  Taoping Inc.',
    name: 'Taoping Inc.',
    symbol: 'TAOP',
  },
  {
    value: 'TH',
    label: 'TH,  Target Hospitality Corp.',
    name: 'Target Hospitality Corp.',
    symbol: 'TH',
  },
  {
    value: 'TCRR',
    label: 'TCRR,  TCR2 Therapeutics Inc.',
    name: 'TCR2 Therapeutics Inc.',
    symbol: 'TCRR',
  },
  {
    value: 'TECTP',
    label: 'TECTP,  Tectonic Financial, Inc.',
    name: 'Tectonic Financial, Inc.',
    symbol: 'TECTP',
  },
  {
    value: 'TENB',
    label: 'TENB,  Tenable Holdings, Inc.',
    name: 'Tenable Holdings, Inc.',
    symbol: 'TENB',
  },
  {
    value: 'WTER',
    label: 'WTER,  The Alkaline Water Company Inc.',
    name: 'The Alkaline Water Company Inc.',
    symbol: 'WTER',
  },
  {
    value: 'BPRN',
    label: 'BPRN,  The Bank of Princeton',
    name: 'The Bank of Princeton',
    symbol: 'BPRN',
  },
  {
    value: 'LOVE',
    label: 'LOVE,  The Lovesac Company',
    name: 'The Lovesac Company',
    symbol: 'LOVE',
  },
  {
    value: 'REAL',
    label: 'REAL,  The RealReal, Inc.',
    name: 'The RealReal, Inc.',
    symbol: 'REAL',
  },
  {
    value: 'TLRY',
    label: 'TLRY,  Tilray Brands, Inc.',
    name: 'Tilray Brands, Inc.',
    symbol: 'TLRY',
  },
  {
    value: 'TMDI',
    label: 'TMDI,  Titan Medical Inc.',
    name: 'Titan Medical Inc.',
    symbol: 'TMDI',
  },
  {
    value: 'TLSA',
    label: 'TLSA,  Tiziana Life Sciences Ltd',
    name: 'Tiziana Life Sciences Ltd',
    symbol: 'TLSA',
  },
  {
    value: 'TBLT',
    label: 'TBLT,  ToughBuilt Industries, Inc.',
    name: 'ToughBuilt Industries, Inc.',
    symbol: 'TBLT',
  },
  {
    value: 'TW',
    label: 'TW,  Tradeweb Markets Inc.',
    name: 'Tradeweb Markets Inc.',
    symbol: 'TW',
  },
  {
    value: 'TMDX',
    label: 'TMDX,  TransMedics Group, Inc.',
    name: 'TransMedics Group, Inc.',
    symbol: 'TMDX',
  },
  {
    value: 'TRVI',
    label: 'TRVI,  Trevi Therapeutics, Inc.',
    name: 'Trevi Therapeutics, Inc.',
    symbol: 'TRVI',
  },
  {
    value: 'TCDA',
    label: 'TCDA,  Tricida, Inc.',
    name: 'Tricida, Inc.',
    symbol: 'TCDA',
  },
  {
    value: 'TC',
    label: 'TC,  TuanChe Limited',
    name: 'TuanChe Limited',
    symbol: 'TC',
  },
  {
    value: 'THCA',
    label: 'THCA,  Tuscan Holdings Corp. II',
    name: 'Tuscan Holdings Corp. II',
    symbol: 'THCA',
  },
  {
    value: 'THCAU',
    label: 'THCAU,  Tuscan Holdings Corp. II',
    name: 'Tuscan Holdings Corp. II',
    symbol: 'THCAU',
  },
  {
    value: 'BROG',
    label: 'BROG,  Brooge Energy Limited',
    name: 'Brooge Energy Limited',
    symbol: 'BROG',
  },
  {
    value: 'BROGW',
    label: 'BROGW,  Brooge Energy Limited',
    name: 'Brooge Energy Limited',
    symbol: 'BROGW',
  },
  {
    value: 'TWST',
    label: 'TWST,  Twist Bioscience Corporation',
    name: 'Twist Bioscience Corporation',
    symbol: 'TWST',
  },
  {
    value: 'USWS',
    label: 'USWS,  U.S. Well Services, Inc.',
    name: 'U.S. Well Services, Inc.',
    symbol: 'USWS',
  },
  {
    value: 'UBX',
    label: 'UBX,  Unity Biotechnology, Inc.',
    name: 'Unity Biotechnology, Inc.',
    symbol: 'UBX',
  },
  {
    value: 'TIGR',
    label: 'TIGR,  UP Fintech Holding Limited',
    name: 'UP Fintech Holding Limited',
    symbol: 'TIGR',
  },
  {
    value: 'UPWK',
    label: 'UPWK,  Upwork Inc.',
    name: 'Upwork Inc.',
    symbol: 'UPWK',
  },
  {
    value: 'USIO',
    label: 'USIO,  Usio, Inc.',
    name: 'Usio, Inc.',
    symbol: 'USIO',
  },
  {
    value: 'UXIN',
    label: 'UXIN,  Uxin Limited',
    name: 'Uxin Limited',
    symbol: 'UXIN',
  },
  {
    value: 'VCNX',
    label: 'VCNX,  Vaccinex, Inc.',
    name: 'Vaccinex, Inc.',
    symbol: 'VCNX',
  },
  {
    value: 'VLYPO',
    label: 'VLYPO,  Valley National Bancorp',
    name: 'Valley National Bancorp',
    symbol: 'VLYPO',
  },
  {
    value: 'VLYPP',
    label: 'VLYPP,  Valley National Bancorp',
    name: 'Valley National Bancorp',
    symbol: 'VLYPP',
  },
  {
    value: 'BNDW',
    label: 'BNDW,  Vanguard Total World Bond ETF',
    name: 'Vanguard Total World Bond ETF',
    symbol: 'BNDW',
  },
  {
    value: 'VTIQ',
    label: 'VTIQ,  VectoIQ Acquisition Corp. II',
    name: 'VectoIQ Acquisition Corp. II',
    symbol: 'VTIQ',
  },
  {
    value: 'VTIQU',
    label: 'VTIQU,  VectoIQ Acquisition Corp. II',
    name: 'VectoIQ Acquisition Corp. II',
    symbol: 'VTIQU',
  },
  {
    value: 'VERB',
    label: 'VERB,  Verb Technology Company, Inc.',
    name: 'Verb Technology Company, Inc.',
    symbol: 'VERB',
  },
  {
    value: 'VERY',
    label: 'VERY,  Vericity, Inc.',
    name: 'Vericity, Inc.',
    symbol: 'VERY',
  },
  {
    value: 'VRRM',
    label: 'VRRM,  Verra Mobility Corporation',
    name: 'Verra Mobility Corporation',
    symbol: 'VRRM',
  },
  {
    value: 'VRCA',
    label: 'VRCA,  Verrica Pharmaceuticals Inc.',
    name: 'Verrica Pharmaceuticals Inc.',
    symbol: 'VRCA',
  },
  {
    value: 'VMD',
    label: 'VMD,  Viemed Healthcare, Inc.',
    name: 'Viemed Healthcare, Inc.',
    symbol: 'VMD',
  },
  {
    value: 'VFF',
    label: 'VFF,  Village Farms International, Inc.',
    name: 'Village Farms International, Inc.',
    symbol: 'VFF',
  },
  {
    value: 'VIOT',
    label: 'VIOT,  Viomi Technology Co., Ltd',
    name: 'Viomi Technology Co., Ltd',
    symbol: 'VIOT',
  },
  {
    value: 'VTSI',
    label: 'VTSI,  VirTra, Inc.',
    name: 'VirTra, Inc.',
    symbol: 'VTSI',
  },
  {
    value: 'VISL',
    label: 'VISL,  Vislink Technologies, Inc.',
    name: 'Vislink Technologies, Inc.',
    symbol: 'VISL',
  },
  {
    value: 'WAFU',
    label: 'WAFU,  Wah Fu Education Group Limited',
    name: 'Wah Fu Education Group Limited',
    symbol: 'WAFU',
  },
  {
    value: 'HLAL',
    label: 'HLAL,  Wahed FTSE USA Shariah ETF',
    name: 'Wahed FTSE USA Shariah ETF',
    symbol: 'HLAL',
  },
  {
    value: 'WTRH',
    label: 'WTRH,  ASAP, Inc.',
    name: 'ASAP, Inc.',
    symbol: 'WTRH',
  },
  {
    value: 'WTRE',
    label: 'WTRE,  WisdomTree New Economy Real Estate ETF',
    name: 'WisdomTree New Economy Real Estate ETF',
    symbol: 'WTRE',
  },
  {
    value: 'WW',
    label: 'WW,  WW International, Inc.',
    name: 'WW International, Inc.',
    symbol: 'WW',
  },
  {
    value: 'WINC',
    label: 'WINC,  Western Asset Short Duration Income ETF',
    name: 'Western Asset Short Duration Income ETF',
    symbol: 'WINC',
  },
  {
    value: 'WBND',
    label: 'WBND,  Western Asset Total Return ETF',
    name: 'Western Asset Total Return ETF',
    symbol: 'WBND',
  },
  {
    value: 'XFOR',
    label: 'XFOR,  X4 Pharmaceuticals, Inc.',
    name: 'X4 Pharmaceuticals, Inc.',
    symbol: 'XFOR',
  },
  {
    value: 'XERS',
    label: 'XERS,  Xeris Biopharma Holdings, Inc.',
    name: 'Xeris Biopharma Holdings, Inc.',
    symbol: 'XERS',
  },
  {
    value: 'XPEL',
    label: 'XPEL,  XPEL, Inc.',
    name: 'XPEL, Inc.',
    symbol: 'XPEL',
  },
  {
    value: 'YMAB',
    label: 'YMAB,  Y-mAbs Therapeutics, Inc.',
    name: 'Y-mAbs Therapeutics, Inc.',
    symbol: 'YMAB',
  },
  {
    value: 'YJ',
    label: 'YJ,  Yunji Inc.',
    name: 'Yunji Inc.',
    symbol: 'YJ',
  },
  {
    value: 'ZM',
    label: 'ZM,  Zoom Video Communications, Inc.',
    name: 'Zoom Video Communications, Inc.',
    symbol: 'ZM',
  },
  {
    value: 'ZVO',
    label: 'ZVO,  Zovio Inc',
    name: 'Zovio Inc',
    symbol: 'ZVO',
  },
  {
    value: 'ZYXI',
    label: 'ZYXI,  Zynex, Inc.',
    name: 'Zynex, Inc.',
    symbol: 'ZYXI',
  },
  {
    value: 'AEF',
    label: 'AEF,  Abrdn Emerging Markets Equity Income Fund Inc',
    name: 'Abrdn Emerging Markets Equity Income Fund Inc',
    symbol: 'AEF',
  },
  {
    value: 'UAVS',
    label: 'UAVS,  AgEagle Aerial Systems, Inc.',
    name: 'AgEagle Aerial Systems, Inc.',
    symbol: 'UAVS',
  },
  {
    value: 'AGE',
    label: 'AGE,  AgeX Therapeutics, Inc.',
    name: 'AgeX Therapeutics, Inc.',
    symbol: 'AGE',
  },
  {
    value: 'AMBO',
    label: 'AMBO,  Ambow Education Holding Ltd.',
    name: 'Ambow Education Holding Ltd.',
    symbol: 'AMBO',
  },
  {
    value: 'ARMP',
    label: 'ARMP,  Armata Pharmaceuticals, Inc.',
    name: 'Armata Pharmaceuticals, Inc.',
    symbol: 'ARMP',
  },
  {
    value: 'BIOX',
    label: 'BIOX,  Bioceres Crop Solutions Corp.',
    name: 'Bioceres Crop Solutions Corp.',
    symbol: 'BIOX',
  },
  {
    value: 'BKTI',
    label: 'BKTI,  BK Technologies Corporation',
    name: 'BK Technologies Corporation',
    symbol: 'BKTI',
  },
  {
    value: 'YCBD',
    label: 'YCBD,  cbdMD, Inc.',
    name: 'cbdMD, Inc.',
    symbol: 'YCBD',
  },
  {
    value: 'AIM',
    label: 'AIM,  AIM ImmunoTech Inc.',
    name: 'AIM ImmunoTech Inc.',
    symbol: 'AIM',
  },
  {
    value: 'ITP',
    label: 'ITP,  IT Tech Packaging, Inc.',
    name: 'IT Tech Packaging, Inc.',
    symbol: 'ITP',
  },
  {
    value: 'LCTX',
    label: 'LCTX,  Lineage Cell Therapeutics, Inc.',
    name: 'Lineage Cell Therapeutics, Inc.',
    symbol: 'LCTX',
  },
  {
    value: 'MMX',
    label: 'MMX,  Maverix Metals Inc.',
    name: 'Maverix Metals Inc.',
    symbol: 'MMX',
  },
  {
    value: 'PLAG',
    label: 'PLAG,  Planet Green Holdings Corp.',
    name: 'Planet Green Holdings Corp.',
    symbol: 'PLAG',
  },
  {
    value: 'SILV',
    label: 'SILV,  SilverCrest Metals Inc.',
    name: 'SilverCrest Metals Inc.',
    symbol: 'SILV',
  },
  {
    value: 'STXS',
    label: 'STXS,  Stereotaxis, Inc.',
    name: 'Stereotaxis, Inc.',
    symbol: 'STXS',
  },
  {
    value: 'ALMIL.PA',
    label: 'ALMIL.PA,  1000mercis',
    name: '1000mercis',
    symbol: 'ALMIL.PA',
  },
  {
    value: '2CRSI.PA',
    label: '2CRSI.PA,  2CRSI S.A.',
    name: '2CRSI S.A.',
    symbol: '2CRSI.PA',
  },
  {
    value: 'AALB.AS',
    label: 'AALB.AS,  Aalberts N.V.',
    name: 'Aalberts N.V.',
    symbol: 'AALB.AS',
  },
  {
    value: 'ABI.BR',
    label: 'ABI.BR,  Anheuser-Busch InBev SA/NV',
    name: 'Anheuser-Busch InBev SA/NV',
    symbol: 'ABI.BR',
  },
  {
    value: 'AB.PA',
    label: 'AB.PA,  AB Science S.A.',
    name: 'AB Science S.A.',
    symbol: 'AB.PA',
  },
  {
    value: 'ABCA.PA',
    label: 'ABCA.PA,  ABC arbitrage SA',
    name: 'ABC arbitrage SA',
    symbol: 'ABCA.PA',
  },
  {
    value: 'ABEO.PA',
    label: 'ABEO.PA,  Abéo SA',
    name: 'Abéo SA',
    symbol: 'ABEO.PA',
  },
  {
    value: 'ABVX.PA',
    label: 'ABVX.PA,  ABIVAX Société Anonyme',
    name: 'ABIVAX Société Anonyme',
    symbol: 'ABVX.PA',
  },
  {
    value: 'ABN.AS',
    label: 'ABN.AS,  ABN AMRO Bank N.V.',
    name: 'ABN AMRO Bank N.V.',
    symbol: 'ABN.AS',
  },
  {
    value: 'ABO.BR',
    label: 'ABO.BR,  ABO-Group Environment NV',
    name: 'ABO-Group Environment NV',
    symbol: 'ABO.BR',
  },
  {
    value: 'ACAN.PA',
    label: 'ACAN.PA,  Acanthe Développement',
    name: 'Acanthe Développement',
    symbol: 'ACAN.PA',
  },
  {
    value: 'ACCB.BR',
    label: 'ACCB.BR,  Accentis N.V.',
    name: 'Accentis N.V.',
    symbol: 'ACCB.BR',
  },
  {
    value: 'AC.PA',
    label: 'AC.PA,  Accor SA',
    name: 'Accor SA',
    symbol: 'AC.PA',
  },
  {
    value: 'AXS.AS',
    label: 'AXS.AS,  Accsys Technologies PLC',
    name: 'Accsys Technologies PLC',
    symbol: 'AXS.AS',
  },
  {
    value: 'ALALO.PA',
    label: 'ALALO.PA,  Acheter-Louer.Fr SA',
    name: 'Acheter-Louer.Fr SA',
    symbol: 'ALALO.PA',
  },
  {
    value: 'ACKB.BR',
    label: 'ACKB.BR,  Ackermans & Van Haaren NV',
    name: 'Ackermans & Van Haaren NV',
    symbol: 'ACKB.BR',
  },
  {
    value: 'EOS.PA',
    label: 'EOS.PA,  ACTEOS S.A.',
    name: 'ACTEOS S.A.',
    symbol: 'EOS.PA',
  },
  {
    value: 'ATI.PA',
    label: 'ATI.PA,  ACTIA Group S.A.',
    name: 'ACTIA Group S.A.',
    symbol: 'ATI.PA',
  },
  {
    value: 'ALACT.PA',
    label: 'ALACT.PA,  Acticor Biotech SAS',
    name: 'Acticor Biotech SAS',
    symbol: 'ALACT.PA',
  },
  {
    value: 'MLACT.PA',
    label: 'MLACT.PA,  Activium Group SAS',
    name: 'Activium Group SAS',
    symbol: 'MLACT.PA',
  },
  {
    value: 'ALDV.PA',
    label: 'ALDV.PA,  Alliance Developpement Capital SIIC',
    name: 'Alliance Developpement Capital SIIC',
    symbol: 'ALDV.PA',
  },
  {
    value: 'ALARF.PA',
    label: 'ALARF.PA,  Adeunis Société anonyme',
    name: 'Adeunis Société anonyme',
    symbol: 'ALARF.PA',
  },
  {
    value: 'ADOC.PA',
    label: 'ADOC.PA,  Adocia SA',
    name: 'Adocia SA',
    symbol: 'ADOC.PA',
  },
  {
    value: 'ALADO.PA',
    label: 'ALADO.PA,  Adomos S.A.',
    name: 'Adomos S.A.',
    symbol: 'ALADO.PA',
  },
  {
    value: 'ADP.PA',
    label: 'ADP.PA,  Aeroports de Paris SA',
    name: 'Aeroports de Paris SA',
    symbol: 'ADP.PA',
  },
  {
    value: 'ADUX.PA',
    label: 'ADUX.PA,  AdUX SA',
    name: 'AdUX SA',
    symbol: 'ADUX.PA',
  },
  {
    value: 'ADVI.PA',
    label: 'ADVI.PA,  AdVini S.A.',
    name: 'AdVini S.A.',
    symbol: 'ADVI.PA',
  },
  {
    value: 'ADYEN.AS',
    label: 'ADYEN.AS,  Adyen N.V.',
    name: 'Adyen N.V.',
    symbol: 'ADYEN.AS',
  },
  {
    value: 'AED.BR',
    label: 'AED.BR,  Aedifica SA',
    name: 'Aedifica SA',
    symbol: 'AED.BR',
  },
  {
    value: 'AGN.AS',
    label: 'AGN.AS,  Aegon N.V.',
    name: 'Aegon N.V.',
    symbol: 'AGN.AS',
  },
  {
    value: 'AKOM.PA',
    label: 'AKOM.PA,  Aerkomm Inc.',
    name: 'Aerkomm Inc.',
    symbol: 'AKOM.PA',
  },
  {
    value: 'AGS.BR',
    label: 'AGS.BR,  ageas SA/NV',
    name: 'ageas SA/NV',
    symbol: 'AGS.BR',
  },
  {
    value: 'AGFB.BR',
    label: 'AGFB.BR,  Agfa-Gevaert NV',
    name: 'Agfa-Gevaert NV',
    symbol: 'AGFB.BR',
  },
  {
    value: 'ALAGR.PA',
    label: 'ALAGR.PA,  AgroGeneration SA',
    name: 'AgroGeneration SA',
    symbol: 'ALAGR.PA',
  },
  {
    value: 'AD.AS',
    label: 'AD.AS,  Koninklijke Ahold Delhaize N.V.',
    name: 'Koninklijke Ahold Delhaize N.V.',
    symbol: 'AD.AS',
  },
  {
    value: 'AF.PA',
    label: 'AF.PA,  Air France-KLM SA',
    name: 'Air France-KLM SA',
    symbol: 'AF.PA',
  },
  {
    value: 'AI.PA',
    label: "AI.PA,  L'Air Liquide S.A.",
    name: "L'Air Liquide S.A.",
    symbol: 'AI.PA',
  },
  {
    value: 'AIR.PA',
    label: 'AIR.PA,  Airbus SE',
    name: 'Airbus SE',
    symbol: 'AIR.PA',
  },
  {
    value: 'AJAX.AS',
    label: 'AJAX.AS,  AFC Ajax NV',
    name: 'AFC Ajax NV',
    symbol: 'AJAX.AS',
  },
  {
    value: 'AKW.PA',
    label: 'AKW.PA,  AKWEL',
    name: 'AKWEL',
    symbol: 'AKW.PA',
  },
  {
    value: 'AKZA.AS',
    label: 'AKZA.AS,  Akzo Nobel N.V.',
    name: 'Akzo Nobel N.V.',
    symbol: 'AKZA.AS',
  },
  {
    value: 'ALD.PA',
    label: 'ALD.PA,  ALD S.A.',
    name: 'ALD S.A.',
    symbol: 'ALD.PA',
  },
  {
    value: 'ALFEN.AS',
    label: 'ALFEN.AS,  Alfen N.V.',
    name: 'Alfen N.V.',
    symbol: 'ALFEN.AS',
  },
  {
    value: 'CDA.PA',
    label: 'CDA.PA,  Compagnie des Alpes SA',
    name: 'Compagnie des Alpes SA',
    symbol: 'CDA.PA',
  },
  {
    value: 'ALO.PA',
    label: 'ALO.PA,  Alstom SA',
    name: 'Alstom SA',
    symbol: 'ALO.PA',
  },
  {
    value: 'ALTA.PA',
    label: 'ALTA.PA,  Altarea SCA',
    name: 'Altarea SCA',
    symbol: 'ALTA.PA',
  },
  {
    value: 'AREIT.PA',
    label: 'AREIT.PA,  Altareit SCA',
    name: 'Altareit SCA',
    symbol: 'AREIT.PA',
  },
  {
    value: 'ATE.PA',
    label: 'ATE.PA,  Alten S.A.',
    name: 'Alten S.A.',
    symbol: 'ATE.PA',
  },
  {
    value: 'ALTR.LS',
    label: 'ALTR.LS,  Altri, SGPS, S.A.',
    name: 'Altri, SGPS, S.A.',
    symbol: 'ALTR.LS',
  },
  {
    value: 'ALTUR.PA',
    label: 'ALTUR.PA,  Altur Investissement SCA, SCR',
    name: 'Altur Investissement SCA, SCR',
    symbol: 'ALTUR.PA',
  },
  {
    value: 'ALX.AS',
    label: 'ALX.AS,  Alumexx N.V.',
    name: 'Alumexx N.V.',
    symbol: 'ALX.AS',
  },
  {
    value: 'MLAAH.PA',
    label: 'MLAAH.PA,  Amatheon Agri Holding N.V.',
    name: 'Amatheon Agri Holding N.V.',
    symbol: 'MLAAH.PA',
  },
  {
    value: 'AMG.AS',
    label: 'AMG.AS,  AMG Advanced Metallurgical Group N.V.',
    name: 'AMG Advanced Metallurgical Group N.V.',
    symbol: 'AMG.AS',
  },
  {
    value: 'AMPLI.PA',
    label: 'AMPLI.PA,  Amplitude Surgical SA',
    name: 'Amplitude Surgical SA',
    symbol: 'AMPLI.PA',
  },
  {
    value: 'ACOMO.AS',
    label: 'ACOMO.AS,  Acomo N.V.',
    name: 'Acomo N.V.',
    symbol: 'ACOMO.AS',
  },
  {
    value: 'AMUN.PA',
    label: 'AMUN.PA,  Amundi S.A.',
    name: 'Amundi S.A.',
    symbol: 'AMUN.PA',
  },
  {
    value: 'APAM.AS',
    label: 'APAM.AS,  Aperam S.A.',
    name: 'Aperam S.A.',
    symbol: 'APAM.AS',
  },
  {
    value: 'ALAQU.PA',
    label: 'ALAQU.PA,  Aquila SA',
    name: 'Aquila SA',
    symbol: 'ALAQU.PA',
  },
  {
    value: 'ARCAD.AS',
    label: 'ARCAD.AS,  Arcadis NV',
    name: 'Arcadis NV',
    symbol: 'ARCAD.AS',
  },
  {
    value: 'MT.AS',
    label: 'MT.AS,  ArcelorMittal S.A.',
    name: 'ArcelorMittal S.A.',
    symbol: 'MT.AS',
  },
  {
    value: 'ALCUR.PA',
    label: 'ALCUR.PA,  Arcure S.A.',
    name: 'Arcure S.A.',
    symbol: 'ALCUR.PA',
  },
  {
    value: 'ARG.PA',
    label: 'ARG.PA,  Argan SA',
    name: 'Argan SA',
    symbol: 'ARG.PA',
  },
  {
    value: 'ARGX.BR',
    label: 'ARGX.BR,  argenx SE',
    name: 'argenx SE',
    symbol: 'ARGX.BR',
  },
  {
    value: 'AKE.PA',
    label: 'AKE.PA,  Arkema S.A.',
    name: 'Arkema S.A.',
    symbol: 'AKE.PA',
  },
  {
    value: 'ARTO.PA',
    label:
      "ARTO.PA,  Société Industrielle et Financière de l'Artois Société anonyme",
    name: "Société Industrielle et Financière de l'Artois Société anonyme",
    symbol: 'ARTO.PA',
  },
  {
    value: 'PRC.PA',
    label: 'PRC.PA,  Artmarket.com',
    name: 'Artmarket.com',
    symbol: 'PRC.PA',
  },
  {
    value: 'ASC.BR',
    label: 'ASC.BR,  Ascencio SCA',
    name: 'Ascencio SCA',
    symbol: 'ASC.BR',
  },
  {
    value: 'MLAEM.PA',
    label: 'MLAEM.PA,  Ashler et Manson SA',
    name: 'Ashler et Manson SA',
    symbol: 'MLAEM.PA',
  },
  {
    value: 'ASM.AS',
    label: 'ASM.AS,  ASM International NV',
    name: 'ASM International NV',
    symbol: 'ASM.AS',
  },
  {
    value: 'ASML.AS',
    label: 'ASML.AS,  ASML Holding N.V.',
    name: 'ASML Holding N.V.',
    symbol: 'ASML.AS',
  },
  {
    value: 'ASRNL.AS',
    label: 'ASRNL.AS,  ASR Nederland N.V.',
    name: 'ASR Nederland N.V.',
    symbol: 'ASRNL.AS',
  },
  {
    value: 'GEN.BR',
    label: 'GEN.BR,  Assicurazioni Generali S.p.A.',
    name: 'Assicurazioni Generali S.p.A.',
    symbol: 'GEN.BR',
  },
  {
    value: 'ASY.PA',
    label: 'ASY.PA,  Assystem S.A.',
    name: 'Assystem S.A.',
    symbol: 'ASY.PA',
  },
  {
    value: 'ATEME.PA',
    label: 'ATEME.PA,  ATEME SA',
    name: 'ATEME SA',
    symbol: 'ATEME.PA',
  },
  {
    value: 'ATEB.BR',
    label: 'ATEB.BR,  Atenor SA',
    name: 'Atenor SA',
    symbol: 'ATEB.BR',
  },
  {
    value: 'ATO.PA',
    label: 'ATO.PA,  Atos SE',
    name: 'Atos SE',
    symbol: 'ATO.PA',
  },
  {
    value: 'AUB.PA',
    label: 'AUB.PA,  Aubay Société Anonyme',
    name: 'Aubay Société Anonyme',
    symbol: 'AUB.PA',
  },
  {
    value: 'AUGR.PA',
    label: 'AUGR.PA,  Augros Cosmetic Packaging SA',
    name: 'Augros Cosmetic Packaging SA',
    symbol: 'AUGR.PA',
  },
  {
    value: 'ALAMG.PA',
    label: 'ALAMG.PA,  Auplata Mining Group',
    name: 'Auplata Mining Group',
    symbol: 'ALAMG.PA',
  },
  {
    value: 'AURE.PA',
    label: 'AURE.PA,  Aurea SA',
    name: 'Aurea SA',
    symbol: 'AURE.PA',
  },
  {
    value: 'AURS.PA',
    label: 'AURS.PA,  AURES Technologies S.A.',
    name: 'AURES Technologies S.A.',
    symbol: 'AURS.PA',
  },
  {
    value: 'AVTX.AS',
    label: 'AVTX.AS,  Avantium N.V.',
    name: 'Avantium N.V.',
    symbol: 'AVTX.AS',
  },
  {
    value: 'AVT.PA',
    label: 'AVT.PA,  Avenir Telecom S.A.',
    name: 'Avenir Telecom S.A.',
    symbol: 'AVT.PA',
  },
  {
    value: 'CS.PA',
    label: 'CS.PA,  AXA SA',
    name: 'AXA SA',
    symbol: 'CS.PA',
  },
  {
    value: 'AXW.PA',
    label: 'AXW.PA,  Axway Software SA',
    name: 'Axway Software SA',
    symbol: 'AXW.PA',
  },
  {
    value: 'MLAAT.PA',
    label: 'MLAAT.PA,  Azorean Aquatic Technologies, S.A.',
    name: 'Azorean Aquatic Technologies, S.A.',
    symbol: 'MLAAT.PA',
  },
  {
    value: 'BSGR.AS',
    label: 'BSGR.AS,  B&S Group S.A.',
    name: 'B&S Group S.A.',
    symbol: 'BSGR.AS',
  },
  {
    value: 'BCP.LS',
    label: 'BCP.LS,  Banco Comercial Português, S.A.',
    name: 'Banco Comercial Português, S.A.',
    symbol: 'BCP.LS',
  },
  {
    value: 'ALBKK.PA',
    label: 'ALBKK.PA,  Baikowski SA',
    name: 'Baikowski SA',
    symbol: 'ALBKK.PA',
  },
  {
    value: 'BAIN.PA',
    label:
      'BAIN.PA,  Société Anonyme des Bains de Mer et du Cercle des Étrangers à Monaco',
    name: 'Société Anonyme des Bains de Mer et du Cercle des Étrangers à Monaco',
    symbol: 'BAIN.PA',
  },
  {
    value: 'BALYO.PA',
    label: 'BALYO.PA,  Balyo SA',
    name: 'Balyo SA',
    symbol: 'BALYO.PA',
  },
  {
    value: 'BAMNB.AS',
    label: 'BAMNB.AS,  Koninklijke BAM Groep nv',
    name: 'Koninklijke BAM Groep nv',
    symbol: 'BAMNB.AS',
  },
  {
    value: 'BBV.BR',
    label: 'BBV.BR,  Banco Bilbao Vizcaya Argentaria, S.A.',
    name: 'Banco Bilbao Vizcaya Argentaria, S.A.',
    symbol: 'BBV.BR',
  },
  {
    value: 'SANTA.BR',
    label: 'SANTA.BR,  Banco Santander, S.A.',
    name: 'Banco Santander, S.A.',
    symbol: 'SANTA.BR',
  },
  {
    value: 'BANI.BR',
    label: 'BANI.BR,  Banimmo SA',
    name: 'Banimmo SA',
    symbol: 'BANI.BR',
  },
  {
    value: 'BUI.PA',
    label: 'BUI.PA,  Barbara Bui SA',
    name: 'Barbara Bui SA',
    symbol: 'BUI.PA',
  },
  {
    value: 'BAR.BR',
    label: 'BAR.BR,  Barco NV',
    name: 'Barco NV',
    symbol: 'BAR.BR',
  },
  {
    value: 'BFIT.AS',
    label: 'BFIT.AS,  Basic-Fit N.V.',
    name: 'Basic-Fit N.V.',
    symbol: 'BFIT.AS',
  },
  {
    value: 'BAS.BR',
    label: 'BAS.BR,  Basilix Cert',
    name: 'Basilix Cert',
    symbol: 'BAS.BR',
  },
  {
    value: 'BLC.PA',
    label: 'BLC.PA,  Bastide Le Confort Médical SA',
    name: 'Bastide Le Confort Médical SA',
    symbol: 'BLC.PA',
  },
  {
    value: 'MLBAT.PA',
    label: 'MLBAT.PA,  Batla Minerals SA',
    name: 'Batla Minerals SA',
    symbol: 'MLBAT.PA',
  },
  {
    value: 'ALBDM.PA',
    label: 'ALBDM.PA,  BD Multimedia SA',
    name: 'BD Multimedia SA',
    symbol: 'ALBDM.PA',
  },
  {
    value: 'BESI.AS',
    label: 'BESI.AS,  BE Semiconductor Industries N.V.',
    name: 'BE Semiconductor Industries N.V.',
    symbol: 'BESI.AS',
  },
  {
    value: 'BEAB.BR',
    label: 'BEAB.BR,  Immo-Beaulieu NV',
    name: 'Immo-Beaulieu NV',
    symbol: 'BEAB.BR',
  },
  {
    value: 'BEFB.BR',
    label: 'BEFB.BR,  Befimmo SA',
    name: 'Befimmo SA',
    symbol: 'BEFB.BR',
  },
  {
    value: 'BEKB.BR',
    label: 'BEKB.BR,  NV Bekaert SA',
    name: 'NV Bekaert SA',
    symbol: 'BEKB.BR',
  },
  {
    value: 'BELR.BR',
    label: 'BELR.BR,  Belgian Resources and Capital Company',
    name: 'Belgian Resources and Capital Company',
    symbol: 'BELR.BR',
  },
  {
    value: 'BEN.PA',
    label: 'BEN.PA,  Bénéteau S.A.',
    name: 'Bénéteau S.A.',
    symbol: 'BEN.PA',
  },
  {
    value: 'SLBEN.LS',
    label: 'SLBEN.LS,  Sport Lisboa e Benfica - Futebol, SAD',
    name: 'Sport Lisboa e Benfica - Futebol, SAD',
    symbol: 'SLBEN.LS',
  },
  {
    value: 'ALDBL.PA',
    label: 'ALDBL.PA,  Bernard Loiseau S.A.',
    name: 'Bernard Loiseau S.A.',
    symbol: 'ALDBL.PA',
  },
  {
    value: 'BBED.AS',
    label: 'BBED.AS,  Beter Bed Holding N.V.',
    name: 'Beter Bed Holding N.V.',
    symbol: 'BBED.AS',
  },
  {
    value: 'BEVER.AS',
    label: 'BEVER.AS,  Bever Holding NV',
    name: 'Bever Holding NV',
    symbol: 'BEVER.AS',
  },
  {
    value: 'BB.PA',
    label: 'BB.PA,  Société BIC SA',
    name: 'Société BIC SA',
    symbol: 'BB.PA',
  },
  {
    value: 'BIG.PA',
    label: 'BIG.PA,  BigBen Interactive',
    name: 'BigBen Interactive',
    symbol: 'BIG.PA',
  },
  {
    value: 'ALBLD.PA',
    label: 'ALBLD.PA,  Bilendi SA',
    name: 'Bilendi SA',
    symbol: 'ALBLD.PA',
  },
  {
    value: 'ALTUV.PA',
    label: 'ALTUV.PA,  BIO-UV Group S.A.',
    name: 'BIO-UV Group S.A.',
    symbol: 'ALTUV.PA',
  },
  {
    value: 'BCART.BR',
    label: 'BCART.BR,  Biocartis Group NV',
    name: 'Biocartis Group NV',
    symbol: 'BCART.BR',
  },
  {
    value: 'ALCOR.PA',
    label: 'ALCOR.PA,  Biocorp Production',
    name: 'Biocorp Production',
    symbol: 'ALCOR.PA',
  },
  {
    value: 'BIM.PA',
    label: 'BIM.PA,  bioMérieux S.A.',
    name: 'bioMérieux S.A.',
    symbol: 'BIM.PA',
  },
  {
    value: 'ALBPS.PA',
    label: 'ALBPS.PA,  Biophytis S.A.',
    name: 'Biophytis S.A.',
    symbol: 'ALBPS.PA',
  },
  {
    value: 'ALBIO.PA',
    label: 'ALBIO.PA,  Biosynex SA',
    name: 'Biosynex SA',
    symbol: 'ALBIO.PA',
  },
  {
    value: 'BLEE.PA',
    label: 'BLEE.PA,  Bleecker Société Anonyme',
    name: 'Bleecker Société Anonyme',
    symbol: 'BLEE.PA',
  },
  {
    value: 'BLUE.PA',
    label: 'BLUE.PA,  BNP Paribas Easy - ECPI Global ESG Blue Economy',
    name: 'BNP Paribas Easy - ECPI Global ESG Blue Economy',
    symbol: 'BLUE.PA',
  },
  {
    value: 'ALBLU.PA',
    label: 'ALBLU.PA,  Bluelinea Société Anonyme',
    name: 'Bluelinea Société Anonyme',
    symbol: 'ALBLU.PA',
  },
  {
    value: 'BNP.PA',
    label: 'BNP.PA,  BNP Paribas SA',
    name: 'BNP Paribas SA',
    symbol: 'BNP.PA',
  },
  {
    value: 'MLONE.PA',
    label: 'MLONE.PA,  Body One SA',
    name: 'Body One SA',
    symbol: 'MLONE.PA',
  },
  {
    value: 'BOEI.BR',
    label: 'BOEI.BR,  The Boeing Company',
    name: 'The Boeing Company',
    symbol: 'BOEI.BR',
  },
  {
    value: 'BOI.PA',
    label: 'BOI.PA,  Boiron SA',
    name: 'Boiron SA',
    symbol: 'BOI.PA',
  },
  {
    value: 'BOL.PA',
    label: 'BOL.PA,  Bolloré SE',
    name: 'Bolloré SE',
    symbol: 'BOL.PA',
  },
  {
    value: 'BON.PA',
    label: 'BON.PA,  Bonduelle SCA',
    name: 'Bonduelle SCA',
    symbol: 'BON.PA',
  },
  {
    value: 'BOKA.AS',
    label: 'BOKA.AS,  Royal Boskalis Westminster N.V.',
    name: 'Royal Boskalis Westminster N.V.',
    symbol: 'BOKA.AS',
  },
  {
    value: 'BSD.PA',
    label: 'BSD.PA,  Bourse Direct SA',
    name: 'Bourse Direct SA',
    symbol: 'BSD.PA',
  },
  {
    value: 'BGHL.AS',
    label: 'BGHL.AS,  Boussard & Gavaudan Holding Limited',
    name: 'Boussard & Gavaudan Holding Limited',
    symbol: 'BGHL.AS',
  },
  {
    value: 'EN.PA',
    label: 'EN.PA,  Bouygues SA',
    name: 'Bouygues SA',
    symbol: 'EN.PA',
  },
  {
    value: 'BPOST.BR',
    label: 'BPOST.BR,  bpost NV/SA',
    name: 'bpost NV/SA',
    symbol: 'BPOST.BR',
  },
  {
    value: 'BNB.BR',
    label: 'BNB.BR,  Banque nationale de Belgique SA',
    name: 'Banque nationale de Belgique SA',
    symbol: 'BNB.BR',
  },
  {
    value: 'BREB.BR',
    label: 'BREB.BR,  Brederode SA',
    name: 'Brederode SA',
    symbol: 'BREB.BR',
  },
  {
    value: 'BRILL.AS',
    label: 'BRILL.AS,  Koninklijke Brill NV',
    name: 'Koninklijke Brill NV',
    symbol: 'BRILL.AS',
  },
  {
    value: 'BRNL.AS',
    label: 'BRNL.AS,  Brunel International N.V.',
    name: 'Brunel International N.V.',
    symbol: 'BRNL.AS',
  },
  {
    value: 'ALBUD.PA',
    label: 'ALBUD.PA,  MINT Société anonyme',
    name: 'MINT Société anonyme',
    symbol: 'ALBUD.PA',
  },
  {
    value: 'BVI.PA',
    label: 'BVI.PA,  Bureau Veritas SA',
    name: 'Bureau Veritas SA',
    symbol: 'BVI.PA',
  },
  {
    value: 'BUR.PA',
    label: 'BUR.PA,  Burelle SA',
    name: 'Burelle SA',
    symbol: 'BUR.PA',
  },
  {
    value: 'CAT31.PA',
    label: 'CAT31.PA,  Caisse Regionale de Credit Agricole Mutuel Toulouse 31',
    name: 'Caisse Regionale de Credit Agricole Mutuel Toulouse 31',
    symbol: 'CAT31.PA',
  },
  {
    value: 'CAFO.PA',
    label:
      "CAFO.PA,  Centrale d'Achat Française pour l'Outre-Mer Société Anonyme",
    name: "Centrale d'Achat Française pour l'Outre-Mer Société Anonyme",
    symbol: 'CAFO.PA',
  },
  {
    value: 'MLAAE.PA',
    label: 'MLAAE.PA,  Caire',
    name: 'Caire',
    symbol: 'MLAAE.PA',
  },
  {
    value: 'CBDG.PA',
    label: 'CBDG.PA,  Compagnie du Cambodge',
    name: 'Compagnie du Cambodge',
    symbol: 'CBDG.PA',
  },
  {
    value: 'CAMB.BR',
    label: 'CAMB.BR,  Campine NV',
    name: 'Campine NV',
    symbol: 'CAMB.BR',
  },
  {
    value: 'CAND.BR',
    label: 'CAND.BR,  Candela Invest SA',
    name: 'Candela Invest SA',
    symbol: 'CAND.BR',
  },
  {
    value: 'CAP.PA',
    label: 'CAP.PA,  Capgemini SE',
    name: 'Capgemini SE',
    symbol: 'CAP.PA',
  },
  {
    value: 'ALCRB.PA',
    label: 'ALCRB.PA,  Carbios SAS',
    name: 'Carbios SAS',
    symbol: 'ALCRB.PA',
  },
  {
    value: 'CPINV.BR',
    label: 'CPINV.BR,  Care Property Invest NV',
    name: 'Care Property Invest NV',
    symbol: 'CPINV.BR',
  },
  {
    value: 'ALCAR.PA',
    label: 'ALCAR.PA,  Carmat SA',
    name: 'Carmat SA',
    symbol: 'ALCAR.PA',
  },
  {
    value: 'CARM.PA',
    label: 'CARM.PA,  Carmila S.A.',
    name: 'Carmila S.A.',
    symbol: 'CARM.PA',
  },
  {
    value: 'CARP.PA',
    label: 'CARP.PA,  Carpinienne de Participations',
    name: 'Carpinienne de Participations',
    symbol: 'CARP.PA',
  },
  {
    value: 'CA.PA',
    label: 'CA.PA,  Carrefour SA',
    name: 'Carrefour SA',
    symbol: 'CA.PA',
  },
  {
    value: 'CO.PA',
    label: 'CO.PA,  Casino, Guichard-Perrachon S.A.',
    name: 'Casino, Guichard-Perrachon S.A.',
    symbol: 'CO.PA',
  },
  {
    value: 'CAS.PA',
    label: 'CAS.PA,  Cast SA',
    name: 'Cast SA',
    symbol: 'CAS.PA',
  },
  {
    value: 'CATG.PA',
    label: 'CATG.PA,  SA Catana Group',
    name: 'SA Catana Group',
    symbol: 'CATG.PA',
  },
  {
    value: 'CTRG.PA',
    label: 'CTRG.PA,  Catering International & Services Société Anonyme',
    name: 'Catering International & Services Société Anonyme',
    symbol: 'CTRG.PA',
  },
  {
    value: 'CATR.PA',
    label: 'CATR.PA,  Caterpillar Inc.',
    name: 'Caterpillar Inc.',
    symbol: 'CATR.PA',
  },
  {
    value: 'CBOT.PA',
    label: 'CBOT.PA,  CBo Territoria SA',
    name: 'CBo Territoria SA',
    symbol: 'CBOT.PA',
  },
  {
    value: 'CGM.PA',
    label: 'CGM.PA,  Cegedim SA',
    name: 'Cegedim SA',
    symbol: 'CGM.PA',
  },
  {
    value: 'ALCLS.PA',
    label: 'ALCLS.PA,  Cellectis S.A.',
    name: 'Cellectis S.A.',
    symbol: 'ALCLS.PA',
  },
  {
    value: 'CYAD.BR',
    label: 'CYAD.BR,  Celyad Oncology SA',
    name: 'Celyad Oncology SA',
    symbol: 'CYAD.BR',
  },
  {
    value: 'CENER.BR',
    label: 'CENER.BR,  Cenergy Holdings SA',
    name: 'Cenergy Holdings SA',
    symbol: 'CENER.BR',
  },
  {
    value: 'ALPCV.PA',
    label: 'ALPCV.PA,  Cerinnov Group SA',
    name: 'Cerinnov Group SA',
    symbol: 'ALPCV.PA',
  },
  {
    value: 'ALCES.PA',
    label: 'ALCES.PA,  Cesar SA',
    name: 'Cesar SA',
    symbol: 'ALCES.PA',
  },
  {
    value: 'CFEB.BR',
    label: "CFEB.BR,  Compagnie d'Entreprises CFE SA",
    name: "Compagnie d'Entreprises CFE SA",
    symbol: 'CFEB.BR',
  },
  {
    value: 'CGG.PA',
    label: 'CGG.PA,  CGG',
    name: 'CGG',
    symbol: 'CGG.PA',
  },
  {
    value: 'CRI.PA',
    label: 'CRI.PA,  Chargeurs SA',
    name: 'Chargeurs SA',
    symbol: 'CRI.PA',
  },
  {
    value: 'CHSR.PA',
    label: 'CHSR.PA,  Chausseria',
    name: 'Chausseria',
    symbol: 'CHSR.PA',
  },
  {
    value: 'MLCHE.PA',
    label: 'MLCHE.PA,  Cheops Technology France SA',
    name: 'Cheops Technology France SA',
    symbol: 'MLCHE.PA',
  },
  {
    value: 'CHTEX.BR',
    label: 'CHTEX.BR,  Chevron Corporation',
    name: 'Chevron Corporation',
    symbol: 'CHTEX.BR',
  },
  {
    value: 'CDI.PA',
    label: 'CDI.PA,  Christian Dior SE',
    name: 'Christian Dior SE',
    symbol: 'CDI.PA',
  },
  {
    value: 'COMB.BR',
    label: 'COMB.BR,  Compagnie du Bois Sauvage S.A.',
    name: 'Compagnie du Bois Sauvage S.A.',
    symbol: 'COMB.BR',
  },
  {
    value: 'MLCMB.PA',
    label: 'MLCMB.PA,  Compagnie Du Mont-Blanc',
    name: 'Compagnie Du Mont-Blanc',
    symbol: 'MLCMB.PA',
  },
  {
    value: 'MLCIO.PA',
    label: 'MLCIO.PA,  Groupe CIOA',
    name: 'Groupe CIOA',
    symbol: 'MLCIO.PA',
  },
  {
    value: 'CIS.BR',
    label: 'CIS.BR,  Cisco Systems, Inc.',
    name: 'Cisco Systems, Inc.',
    symbol: 'CIS.BR',
  },
  {
    value: 'CLA.PA',
    label: 'CLA.PA,  Claranova SE',
    name: 'Claranova SE',
    symbol: 'CLA.PA',
  },
  {
    value: 'ALCLA.PA',
    label: 'ALCLA.PA,  Clasquin SA',
    name: 'Clasquin SA',
    symbol: 'ALCLA.PA',
  },
  {
    value: 'CNV.PA',
    label: 'CNV.PA,  Cnova N.V.',
    name: 'Cnova N.V.',
    symbol: 'CNV.PA',
  },
  {
    value: 'COBH.BR',
    label: 'COBH.BR,  Brouwerij Handelsmaatschappij NV',
    name: 'Brouwerij Handelsmaatschappij NV',
    symbol: 'COBH.BR',
  },
  {
    value: 'CCEP.AS',
    label: 'CCEP.AS,  Coca-Cola Europacific Partners PLC',
    name: 'Coca-Cola Europacific Partners PLC',
    symbol: 'CCEP.AS',
  },
  {
    value: 'COFA.PA',
    label: 'COFA.PA,  COFACE SA',
    name: 'COFACE SA',
    symbol: 'COFA.PA',
  },
  {
    value: 'ALCOF.PA',
    label: 'ALCOF.PA,  Cofidur S.A.',
    name: 'Cofidur S.A.',
    symbol: 'ALCOF.PA',
  },
  {
    value: 'CFN.LS',
    label: 'CFN.LS,  Cofina, SGPS, S.A.',
    name: 'Cofina, SGPS, S.A.',
    symbol: 'CFN.LS',
  },
  {
    value: 'COFB.BR',
    label: 'COFB.BR,  Cofinimmo SA',
    name: 'Cofinimmo SA',
    symbol: 'COFB.BR',
  },
  {
    value: 'ALCOG.PA',
    label: 'ALCOG.PA,  Cogra 48 Société Anonyme',
    name: 'Cogra 48 Société Anonyme',
    symbol: 'ALCOG.PA',
  },
  {
    value: 'COH.PA',
    label: 'COH.PA,  Coheris SA',
    name: 'Coheris SA',
    symbol: 'COH.PA',
  },
  {
    value: 'ALCOI.PA',
    label: 'ALCOI.PA,  Coil S.A./N.V.',
    name: 'Coil S.A./N.V.',
    symbol: 'ALCOI.PA',
  },
  {
    value: 'RE.PA',
    label: 'RE.PA,  Colas SA',
    name: 'Colas SA',
    symbol: 'RE.PA',
  },
  {
    value: 'MLCLP.PA',
    label: 'MLCLP.PA,  Colipays, S.A.',
    name: 'Colipays, S.A.',
    symbol: 'MLCLP.PA',
  },
  {
    value: 'COLR.BR',
    label: 'COLR.BR,  Etn. Fr. Colruyt NV',
    name: 'Etn. Fr. Colruyt NV',
    symbol: 'COLR.BR',
  },
  {
    value: 'CRBN.AS',
    label: 'CRBN.AS,  Corbion N.V.',
    name: 'Corbion N.V.',
    symbol: 'CRBN.AS',
  },
  {
    value: 'CLB.AS',
    label: 'CLB.AS,  Core Laboratories N.V.',
    name: 'Core Laboratories N.V.',
    symbol: 'CLB.AS',
  },
  {
    value: 'COR.LS',
    label: 'COR.LS,  Corticeira Amorim, S.G.P.S., S.A.',
    name: 'Corticeira Amorim, S.G.P.S., S.A.',
    symbol: 'COR.LS',
  },
  {
    value: 'COUR.PA',
    label: 'COUR.PA,  Courtois S.A.',
    name: 'Courtois S.A.',
    symbol: 'COUR.PA',
  },
  {
    value: 'COV.PA',
    label: 'COV.PA,  Covivio',
    name: 'Covivio',
    symbol: 'COV.PA',
  },
  {
    value: 'COVH.PA',
    label: 'COVH.PA,  Covivio Hotels',
    name: 'Covivio Hotels',
    symbol: 'COVH.PA',
  },
  {
    value: 'CRAP.PA',
    label:
      'CRAP.PA,  Caisse Régionale de Crédit Agricole Mutuel Alpes Provence Société coopérative',
    name: 'Caisse Régionale de Crédit Agricole Mutuel Alpes Provence Société coopérative',
    symbol: 'CRAP.PA',
  },
  {
    value: 'CRAV.PA',
    label:
      'CRAV.PA,  Caisse régionale de Crédit Agricole Mutuel Atlantique Vendée',
    name: 'Caisse régionale de Crédit Agricole Mutuel Atlantique Vendée',
    symbol: 'CRAV.PA',
  },
  {
    value: 'CRBP2.PA',
    label:
      'CRBP2.PA,  Caisse Régionale de Crédit Agricole Mutuel Brie Picardie Société coopérative',
    name: 'Caisse Régionale de Crédit Agricole Mutuel Brie Picardie Société coopérative',
    symbol: 'CRBP2.PA',
  },
  {
    value: 'CIV.PA',
    label:
      "CIV.PA,  Caisse régionale de Crédit Agricole Mutuel d'Ille-et-Vilaine Société coopérative",
    name: "Caisse régionale de Crédit Agricole Mutuel d'Ille-et-Vilaine Société coopérative",
    symbol: 'CIV.PA',
  },
  {
    value: 'CRLA.PA',
    label:
      'CRLA.PA,  Caisse Régionale de Crédit Agricole Mutuel du Languedoc Société coopérative',
    name: 'Caisse Régionale de Crédit Agricole Mutuel du Languedoc Société coopérative',
    symbol: 'CRLA.PA',
  },
  {
    value: 'CRLO.PA',
    label:
      'CRLO.PA,  Caisse régionale de Crédit Agricole Mutuel Loire Haute-Loire - Société coopérative',
    name: 'Caisse régionale de Crédit Agricole Mutuel Loire Haute-Loire - Société coopérative',
    symbol: 'CRLO.PA',
  },
  {
    value: 'CMO.PA',
    label: 'CMO.PA,  Caisse Régionale de Crédit Agricole du Morbihan',
    name: 'Caisse Régionale de Crédit Agricole du Morbihan',
    symbol: 'CMO.PA',
  },
  {
    value: 'CNF.PA',
    label:
      'CNF.PA,  Caisse Régionale de Crédit Agricole Mutuel Nord de France Société coopérative',
    name: 'Caisse Régionale de Crédit Agricole Mutuel Nord de France Société coopérative',
    symbol: 'CNF.PA',
  },
  {
    value: 'CCN.PA',
    label:
      'CCN.PA,  Caisse Régionale de Crédit Agricole Mutuel de Normandie-Seine Société coopérative',
    name: 'Caisse Régionale de Crédit Agricole Mutuel de Normandie-Seine Société coopérative',
    symbol: 'CCN.PA',
  },
  {
    value: 'CAF.PA',
    label:
      "CAF.PA,  Caisse Régionale de Crédit Agricole Mutuel de Paris et d'Ile-de-France",
    name: "Caisse Régionale de Crédit Agricole Mutuel de Paris et d'Ile-de-France",
    symbol: 'CAF.PA',
  },
  {
    value: 'CRSU.PA',
    label:
      'CRSU.PA,  Caisse Régionale de Crédit Agricole Mutuel Sud Rhône Alpes',
    name: 'Caisse Régionale de Crédit Agricole Mutuel Sud Rhône Alpes',
    symbol: 'CRSU.PA',
  },
  {
    value: 'CRTO.PA',
    label:
      'CRTO.PA,  Caisse Régionale de Crédit Agricole Mutuel de La Touraine et du Poitou Société Coopérative',
    name: 'Caisse Régionale de Crédit Agricole Mutuel de La Touraine et du Poitou Société Coopérative',
    symbol: 'CRTO.PA',
  },
  {
    value: 'ACA.PA',
    label: 'ACA.PA,  Crédit Agricole S.A.',
    name: 'Crédit Agricole S.A.',
    symbol: 'ACA.PA',
  },
  {
    value: 'OPTI.BR',
    label: 'OPTI.BR,  Crescent NV',
    name: 'Crescent NV',
    symbol: 'OPTI.BR',
  },
  {
    value: 'ALCJ.PA',
    label: 'ALCJ.PA,  Crossject Société Anonyme',
    name: 'Crossject Société Anonyme',
    symbol: 'ALCJ.PA',
  },
  {
    value: 'CROS.PA',
    label: 'CROS.PA,  Crosswood SA',
    name: 'Crosswood SA',
    symbol: 'CROS.PA',
  },
  {
    value: 'SX.PA',
    label: 'SX.PA,  CS Communication & Systemes SA',
    name: 'CS Communication & Systemes SA',
    symbol: 'SX.PA',
  },
  {
    value: 'CTAC.AS',
    label: 'CTAC.AS,  Ctac N.V.',
    name: 'Ctac N.V.',
    symbol: 'CTAC.AS',
  },
  {
    value: 'CTT.LS',
    label: 'CTT.LS,  CTT - Correios De Portugal, S.A.',
    name: 'CTT - Correios De Portugal, S.A.',
    symbol: 'CTT.LS',
  },
  {
    value: 'ALCYB.PA',
    label: 'ALCYB.PA,  Cybergun S.A.',
    name: 'Cybergun S.A.',
    symbol: 'ALCYB.PA',
  },
  {
    value: 'DIE.BR',
    label: "DIE.BR,  D'Ieteren Group SA",
    name: "D'Ieteren Group SA",
    symbol: 'DIE.BR',
  },
  {
    value: 'ALDLS.PA',
    label: 'ALDLS.PA,  DLSI',
    name: 'DLSI',
    symbol: 'ALDLS.PA',
  },
  {
    value: 'ALDAR.PA',
    label: 'ALDAR.PA,  Damartex',
    name: 'Damartex',
    symbol: 'ALDAR.PA',
  },
  {
    value: 'BN.PA',
    label: 'BN.PA,  Danone S.A.',
    name: 'Danone S.A.',
    symbol: 'BN.PA',
  },
  {
    value: 'AM.PA',
    label: 'AM.PA,  Dassault Aviation Société anonyme',
    name: 'Dassault Aviation Société anonyme',
    symbol: 'AM.PA',
  },
  {
    value: 'DSY.PA',
    label: 'DSY.PA,  Dassault Systèmes SE',
    name: 'Dassault Systèmes SE',
    symbol: 'DSY.PA',
  },
  {
    value: 'ALDBT.PA',
    label: 'ALDBT.PA,  DBT SA',
    name: 'DBT SA',
    symbol: 'ALDBT.PA',
  },
  {
    value: 'DBV.PA',
    label: 'DBV.PA,  DBV Technologies S.A.',
    name: 'DBV Technologies S.A.',
    symbol: 'DBV.PA',
  },
  {
    value: 'DECB.BR',
    label: 'DECB.BR,  Deceuninck NV',
    name: 'Deceuninck NV',
    symbol: 'DECB.BR',
  },
  {
    value: 'ALDEI.PA',
    label: 'ALDEI.PA,  Deinove SA',
    name: 'Deinove SA',
    symbol: 'ALDEI.PA',
  },
  {
    value: 'ALDEL.PA',
    label: 'ALDEL.PA,  Delfingen Industry S.A.',
    name: 'Delfingen Industry S.A.',
    symbol: 'ALDEL.PA',
  },
  {
    value: 'ALDR.PA',
    label: 'ALDR.PA,  Delta Drone SA',
    name: 'Delta Drone SA',
    symbol: 'ALDR.PA',
  },
  {
    value: 'DBG.PA',
    label: 'DBG.PA,  Derichebourg SA',
    name: 'Derichebourg SA',
    symbol: 'DBG.PA',
  },
  {
    value: 'DTEL.BR',
    label: 'DTEL.BR,  Deutsche Telekom AG',
    name: 'Deutsche Telekom AG',
    symbol: 'DTEL.BR',
  },
  {
    value: 'ALDEV.PA',
    label: 'ALDEV.PA,  Devernois S.A.',
    name: 'Devernois S.A.',
    symbol: 'ALDEV.PA',
  },
  {
    value: 'DGB.AS',
    label: 'DGB.AS,  DGB Group N.V.',
    name: 'DGB Group N.V.',
    symbol: 'DGB.AS',
  },
  {
    value: 'DGE.PA',
    label: 'DGE.PA,  Diageo plc',
    name: 'Diageo plc',
    symbol: 'DGE.PA',
  },
  {
    value: 'DIEG.BR',
    label: 'DIEG.BR,  Immolease Trust NV',
    name: 'Immolease Trust NV',
    symbol: 'DIEG.BR',
  },
  {
    value: 'DISL.BR',
    label: 'DISL.BR,  Immobiliere Distri-Land NV',
    name: 'Immobiliere Distri-Land NV',
    symbol: 'DISL.BR',
  },
  {
    value: 'DPAM.PA',
    label: "DPAM.PA,  Les Docks des Pétroles d'Ambès -SA",
    name: "Les Docks des Pétroles d'Ambès -SA",
    symbol: 'DPAM.PA',
  },
  {
    value: 'ALDNE.PA',
    label: "ALDNE.PA,  Don't Nod Entertainment S.A.",
    name: "Don't Nod Entertainment S.A.",
    symbol: 'ALDNE.PA',
  },
  {
    value: 'ALDRV.PA',
    label: 'ALDRV.PA,  Drone Volt SA',
    name: 'Drone Volt SA',
    symbol: 'ALDRV.PA',
  },
  {
    value: 'DSM.AS',
    label: 'DSM.AS,  Koninklijke DSM N.V.',
    name: 'Koninklijke DSM N.V.',
    symbol: 'DSM.AS',
  },
  {
    value: 'EON.BR',
    label: 'EON.BR,  E.ON SE',
    name: 'E.ON SE',
    symbol: 'EON.BR',
  },
  {
    value: 'EAS2P.AS',
    label: 'EAS2P.AS,  Ease2pay N.V.',
    name: 'Ease2pay N.V.',
    symbol: 'EAS2P.AS',
  },
  {
    value: 'MLEDR.PA',
    label: 'MLEDR.PA,  Compagnie des Eaux de Royan',
    name: 'Compagnie des Eaux de Royan',
    symbol: 'MLEDR.PA',
  },
  {
    value: 'ECONB.BR',
    label: 'ECONB.BR,  Econocom Group SE',
    name: 'Econocom Group SE',
    symbol: 'ECONB.BR',
  },
  {
    value: 'ALESA.PA',
    label: 'ALESA.PA,  Ecoslops S.A.',
    name: 'Ecoslops S.A.',
    symbol: 'ALESA.PA',
  },
  {
    value: 'EDEN.PA',
    label: 'EDEN.PA,  Edenred SA',
    name: 'Edenred SA',
    symbol: 'EDEN.PA',
  },
  {
    value: 'EDF.PA',
    label: 'EDF.PA,  Electricité de France S.A.',
    name: 'Electricité de France S.A.',
    symbol: 'EDF.PA',
  },
  {
    value: 'ALEAC.PA',
    label: 'ALEAC.PA,  EdiliziAcrobatica S.p.A.',
    name: 'EdiliziAcrobatica S.p.A.',
    symbol: 'ALEAC.PA',
  },
  {
    value: 'EDP.LS',
    label: 'EDP.LS,  EDP - Energias de Portugal, S.A.',
    name: 'EDP - Energias de Portugal, S.A.',
    symbol: 'EDP.LS',
  },
  {
    value: 'EDPR.LS',
    label: 'EDPR.LS,  EDP Renováveis, S.A.',
    name: 'EDP Renováveis, S.A.',
    symbol: 'EDPR.LS',
  },
  {
    value: 'FGR.PA',
    label: 'FGR.PA,  Eiffage SA',
    name: 'Eiffage SA',
    symbol: 'FGR.PA',
  },
  {
    value: 'EKI.PA',
    label: 'EKI.PA,  EKINOPS S.A.',
    name: 'EKINOPS S.A.',
    symbol: 'EKI.PA',
  },
  {
    value: 'ELEC.PA',
    label: 'ELEC.PA,  Électricite de Strasbourg Société Anonyme',
    name: 'Électricite de Strasbourg Société Anonyme',
    symbol: 'ELEC.PA',
  },
  {
    value: 'ELI.BR',
    label: 'ELI.BR,  Elia Group SA/NV',
    name: 'Elia Group SA/NV',
    symbol: 'ELI.BR',
  },
  {
    value: 'ELIOR.PA',
    label: 'ELIOR.PA,  Elior Group SA',
    name: 'Elior Group SA',
    symbol: 'ELIOR.PA',
  },
  {
    value: 'ELIS.PA',
    label: 'ELIS.PA,  Elis SA',
    name: 'Elis SA',
    symbol: 'ELIS.PA',
  },
  {
    value: 'ALDUB.PA',
    label: 'ALDUB.PA,  Encres Dubuit',
    name: 'Encres Dubuit',
    symbol: 'ALDUB.PA',
  },
  {
    value: 'ALENE.PA',
    label: 'ALENE.PA,  Enertime SA',
    name: 'Enertime SA',
    symbol: 'ALENE.PA',
  },
  {
    value: 'ENGI.PA',
    label: 'ENGI.PA,  ENGIE SA',
    name: 'ENGIE SA',
    symbol: 'ENGI.PA',
  },
  {
    value: 'ENI.BR',
    label: 'ENI.BR,  Eni S.p.A.',
    name: 'Eni S.p.A.',
    symbol: 'ENI.BR',
  },
  {
    value: 'ALENT.PA',
    label: 'ALENT.PA,  SA Entreparticuliers.com',
    name: 'SA Entreparticuliers.com',
    symbol: 'ALENT.PA',
  },
  {
    value: 'ALENR.PA',
    label: 'ALENR.PA,  Entreprendre S.A.',
    name: 'Entreprendre S.A.',
    symbol: 'ALENR.PA',
  },
  {
    value: 'ENVI.AS',
    label: 'ENVI.AS,  Envipco Holding N.V.',
    name: 'Envipco Holding N.V.',
    symbol: 'ENVI.AS',
  },
  {
    value: 'ALEO2.PA',
    label: 'ALEO2.PA,  Eo2 Société Anonyme',
    name: 'Eo2 Société Anonyme',
    symbol: 'ALEO2.PA',
  },
  {
    value: 'ERA.PA',
    label: 'ERA.PA,  ERAMET S.A.',
    name: 'ERAMET S.A.',
    symbol: 'ERA.PA',
  },
  {
    value: 'ERYP.PA',
    label: 'ERYP.PA,  ERYTECH Pharma S.A.',
    name: 'ERYTECH Pharma S.A.',
    symbol: 'ERYP.PA',
  },
  {
    value: 'ESI.PA',
    label: 'ESI.PA,  ESI Group SA',
    name: 'ESI Group SA',
    symbol: 'ESI.PA',
  },
  {
    value: 'ALESK.PA',
    label: 'ALESK.PA,  Esker SA',
    name: 'Esker SA',
    symbol: 'ALESK.PA',
  },
  {
    value: 'ESP.AS',
    label: 'ESP.AS,  Esperite N.V.',
    name: 'Esperite N.V.',
    symbol: 'ESP.AS',
  },
  {
    value: 'EL.PA',
    label: 'EL.PA,  EssilorLuxottica Société anonyme',
    name: 'EssilorLuxottica Société anonyme',
    symbol: 'EL.PA',
  },
  {
    value: 'ES.PA',
    label: 'ES.PA,  Esso S.A.F.',
    name: 'Esso S.A.F.',
    symbol: 'ES.PA',
  },
  {
    value: 'ESON.LS',
    label: 'ESON.LS,  Estoril Sol, SGPS, S.A.',
    name: 'Estoril Sol, SGPS, S.A.',
    symbol: 'ESON.LS',
  },
  {
    value: 'EFI.PA',
    label: 'EFI.PA,  Eurasia Fonciere Investissements Société Anonyme',
    name: 'Eurasia Fonciere Investissements Société Anonyme',
    symbol: 'EFI.PA',
  },
  {
    value: 'ALEUA.PA',
    label: 'ALEUA.PA,  Eurasia Groupe SA',
    name: 'Eurasia Groupe SA',
    symbol: 'ALEUA.PA',
  },
  {
    value: 'RF.PA',
    label: 'RF.PA,  Eurazeo SE',
    name: 'Eurazeo SE',
    symbol: 'RF.PA',
  },
  {
    value: 'EUR.PA',
    label: 'EUR.PA,  EURO Ressources S.A.',
    name: 'EURO Ressources S.A.',
    symbol: 'EUR.PA',
  },
  {
    value: 'ALERS.PA',
    label: 'ALERS.PA,  Eurobio Scientific Société anonyme',
    name: 'Eurobio Scientific Société anonyme',
    symbol: 'ALERS.PA',
  },
  {
    value: 'ECT.AS',
    label: 'ECT.AS,  Eurocastle Investment Limited',
    name: 'Eurocastle Investment Limited',
    symbol: 'ECT.AS',
  },
  {
    value: 'ECMPA.AS',
    label: 'ECMPA.AS,  Eurocommercial Properties N.V.',
    name: 'Eurocommercial Properties N.V.',
    symbol: 'ECMPA.AS',
  },
  {
    value: 'ERF.PA',
    label: 'ERF.PA,  Eurofins Scientific SE',
    name: 'Eurofins Scientific SE',
    symbol: 'ERF.PA',
  },
  {
    value: 'MLERO.PA',
    label: 'MLERO.PA,  Euroland Corporate Société anonyme',
    name: 'Euroland Corporate Société anonyme',
    symbol: 'MLERO.PA',
  },
  {
    value: 'EURN.BR',
    label: 'EURN.BR,  Euronav NV',
    name: 'Euronav NV',
    symbol: 'EURN.BR',
  },
  {
    value: 'ENX.PA',
    label: 'ENX.PA,  Euronext N.V.',
    name: 'Euronext N.V.',
    symbol: 'ENX.PA',
  },
  {
    value: 'ALEUP.PA',
    label: 'ALEUP.PA,  Europlasma S.A.',
    name: 'Europlasma S.A.',
    symbol: 'ALEUP.PA',
  },
  {
    value: 'ETL.PA',
    label: 'ETL.PA,  Eutelsat Communications S.A.',
    name: 'Eutelsat Communications S.A.',
    symbol: 'ETL.PA',
  },
  {
    value: 'ALTVO.PA',
    label: 'ALTVO.PA,  Evolis SA',
    name: 'Evolis SA',
    symbol: 'ALTVO.PA',
  },
  {
    value: 'EVS.BR',
    label: 'EVS.BR,  EVS Broadcast Equipment SA',
    name: 'EVS Broadcast Equipment SA',
    symbol: 'EVS.BR',
  },
  {
    value: 'EXE.PA',
    label: 'EXE.PA,  EXEL Industries SA',
    name: 'EXEL Industries SA',
    symbol: 'EXE.PA',
  },
  {
    value: 'EXM.BR',
    label: 'EXM.BR,  Exmar NV',
    name: 'Exmar NV',
    symbol: 'EXM.BR',
  },
  {
    value: 'EXPL.PA',
    label: 'EXPL.PA,  EPC Groupe',
    name: 'EPC Groupe',
    symbol: 'EXPL.PA',
  },
  {
    value: 'FAGR.BR',
    label: 'FAGR.BR,  Fagron NV',
    name: 'Fagron NV',
    symbol: 'FAGR.BR',
  },
  {
    value: 'ALFBA.PA',
    label: 'ALFBA.PA,  Fashion Bel Air S.A.',
    name: 'Fashion Bel Air S.A.',
    symbol: 'ALFBA.PA',
  },
  {
    value: 'FAST.AS',
    label: 'FAST.AS,  Fastned B.V.',
    name: 'Fastned B.V.',
    symbol: 'FAST.AS',
  },
  {
    value: 'EO.PA',
    label: 'EO.PA,  Faurecia S.E.',
    name: 'Faurecia S.E.',
    symbol: 'EO.PA',
  },
  {
    value: 'FAYE.PA',
    label:
      'FAYE.PA,  Fayenceries de Sarreguemines, Digoin & Vitry-le-Francois Société Anonyme',
    name: 'Fayenceries de Sarreguemines, Digoin & Vitry-le-Francois Société Anonyme',
    symbol: 'FAYE.PA',
  },
  {
    value: 'FCMC.PA',
    label: 'FCMC.PA,  Société Fermière du Casino Municipal de Cannes',
    name: 'Société Fermière du Casino Municipal de Cannes',
    symbol: 'FCMC.PA',
  },
  {
    value: 'FALG.PA',
    label: 'FALG.PA,  Fermentalg SA',
    name: 'Fermentalg SA',
    symbol: 'FALG.PA',
  },
  {
    value: 'SACI.PA',
    label: 'SACI.PA,  Fiducial Office Solutions',
    name: 'Fiducial Office Solutions',
    symbol: 'SACI.PA',
  },
  {
    value: 'ORIA.PA',
    label: 'ORIA.PA,  Fiducial Real Estate SA',
    name: 'Fiducial Real Estate SA',
    symbol: 'ORIA.PA',
  },
  {
    value: 'FGA.PA',
    label: 'FGA.PA,  Figeac Aero Société Anonyme',
    name: 'Figeac Aero Société Anonyme',
    symbol: 'FGA.PA',
  },
  {
    value: 'BERR.PA',
    label:
      "BERR.PA,  Financiere et Immobiliere de l'Etang de Berre et de la Mediterranee Société anonyme",
    name: "Financiere et Immobiliere de l'Etang de Berre et de la Mediterranee Société anonyme",
    symbol: 'BERR.PA',
  },
  {
    value: 'FOAF.PA',
    label: 'FOAF.PA,  Fin.Ouest Africain',
    name: 'Fin.Ouest Africain',
    symbol: 'FOAF.PA',
  },
  {
    value: 'FINM.PA',
    label: 'FINM.PA,  Financière Marjos SA',
    name: 'Financière Marjos SA',
    symbol: 'FINM.PA',
  },
  {
    value: 'ODET.PA',
    label: "ODET.PA,  Compagnie de l'Odet",
    name: "Compagnie de l'Odet",
    symbol: 'ODET.PA',
  },
  {
    value: 'FNTS.PA',
    label: 'FNTS.PA,  Finatis Société Anonyme',
    name: 'Finatis Société Anonyme',
    symbol: 'FNTS.PA',
  },
  {
    value: 'FIPP.PA',
    label: 'FIPP.PA,  FIPP S.A.',
    name: 'FIPP S.A.',
    symbol: 'FIPP.PA',
  },
  {
    value: 'FLOB.BR',
    label: 'FLOB.BR,  Floridienne S.A.',
    name: 'Floridienne S.A.',
    symbol: 'FLOB.BR',
  },
  {
    value: 'FLOW.AS',
    label: 'FLOW.AS,  Flow Traders N.V.',
    name: 'Flow Traders N.V.',
    symbol: 'FLOW.AS',
  },
  {
    value: 'FLUX.BR',
    label: 'FLUX.BR,  Fluxys Belgium SA',
    name: 'Fluxys Belgium SA',
    symbol: 'FLUX.BR',
  },
  {
    value: 'FNAC.PA',
    label: 'FNAC.PA,  Fnac Darty SA',
    name: 'Fnac Darty SA',
    symbol: 'FNAC.PA',
  },
  {
    value: 'ALFOC.PA',
    label: 'ALFOC.PA,  Focus Entertainment Société anonyme',
    name: 'Focus Entertainment Société anonyme',
    symbol: 'ALFOC.PA',
  },
  {
    value: 'LEBL.PA',
    label: 'LEBL.PA,  Foncière 7 investissement Société Anonyme',
    name: 'Foncière 7 investissement Société Anonyme',
    symbol: 'LEBL.PA',
  },
  {
    value: 'EURS.PA',
    label: 'EURS.PA,  Foncière Euris SA',
    name: 'Foncière Euris SA',
    symbol: 'EURS.PA',
  },
  {
    value: 'INEA.PA',
    label: 'INEA.PA,  Fonciere Inea S.A.',
    name: 'Fonciere Inea S.A.',
    symbol: 'INEA.PA',
  },
  {
    value: 'FLY.PA',
    label: 'FLY.PA,  Société Foncière Lyonnaise',
    name: 'Société Foncière Lyonnaise',
    symbol: 'FLY.PA',
  },
  {
    value: 'SPEL.PA',
    label: 'SPEL.PA,  Foncière Volta',
    name: 'Foncière Volta',
    symbol: 'SPEL.PA',
  },
  {
    value: 'FFARM.AS',
    label: 'FFARM.AS,  ForFarmers N.V.',
    name: 'ForFarmers N.V.',
    symbol: 'FFARM.AS',
  },
  {
    value: 'FOU.BR',
    label: 'FOU.BR,  Fountain S.A.',
    name: 'Fountain S.A.',
    symbol: 'FOU.BR',
  },
  {
    value: 'ALFPC.PA',
    label: 'ALFPC.PA,  S.A. Fountaine Pajot',
    name: 'S.A. Fountaine Pajot',
    symbol: 'ALFPC.PA',
  },
  {
    value: 'MLFTI.PA',
    label: 'MLFTI.PA,  France Tourisme Immobilier SA',
    name: 'France Tourisme Immobilier SA',
    symbol: 'MLFTI.PA',
  },
  {
    value: 'ALFRE.PA',
    label: 'ALFRE.PA,  Freelance.com SA',
    name: 'Freelance.com SA',
    symbol: 'ALFRE.PA',
  },
  {
    value: 'FREY.PA',
    label: 'FREY.PA,  Frey SA',
    name: 'Frey SA',
    symbol: 'FREY.PA',
  },
  {
    value: 'FUR.AS',
    label: 'FUR.AS,  Fugro N.V.',
    name: 'Fugro N.V.',
    symbol: 'FUR.AS',
  },
  {
    value: 'FCP.LS',
    label: 'FCP.LS,  Futebol Clube do Porto - Futebol, S.A.D.',
    name: 'Futebol Clube do Porto - Futebol, S.A.D.',
    symbol: 'FCP.LS',
  },
  {
    value: 'GLPG.AS',
    label: 'GLPG.AS,  Galapagos NV',
    name: 'Galapagos NV',
    symbol: 'GLPG.AS',
  },
  {
    value: 'MLGAL.PA',
    label: 'MLGAL.PA,  Galeo Concept SA',
    name: 'Galeo Concept SA',
    symbol: 'MLGAL.PA',
  },
  {
    value: 'GALIM.PA',
    label: 'GALIM.PA,  Galimmo SCA',
    name: 'Galimmo SCA',
    symbol: 'GALIM.PA',
  },
  {
    value: 'GALP.LS',
    label: 'GALP.LS,  Galp Energia, SGPS, S.A.',
    name: 'Galp Energia, SGPS, S.A.',
    symbol: 'GALP.LS',
  },
  {
    value: 'ALBI.PA',
    label: 'ALBI.PA,  Gascogne SA',
    name: 'Gascogne SA',
    symbol: 'ALBI.PA',
  },
  {
    value: 'GAM.PA',
    label: 'GAM.PA,  Gaumont SA',
    name: 'Gaumont SA',
    symbol: 'GAM.PA',
  },
  {
    value: 'ALGAU.PA',
    label: 'ALGAU.PA,  Gaussin SA',
    name: 'Gaussin SA',
    symbol: 'ALGAU.PA',
  },
  {
    value: 'GBLB.BR',
    label: 'GBLB.BR,  Groupe Bruxelles Lambert SA',
    name: 'Groupe Bruxelles Lambert SA',
    symbol: 'GBLB.BR',
  },
  {
    value: 'GEA.PA',
    label:
      "GEA.PA,  Grenobloise d'Electronique et d'Automatismes Société Anonyme",
    name: "Grenobloise d'Electronique et d'Automatismes Société Anonyme",
    symbol: 'GEA.PA',
  },
  {
    value: 'GFC.PA',
    label: 'GFC.PA,  Gecina SA',
    name: 'Gecina SA',
    symbol: 'GFC.PA',
  },
  {
    value: 'GNE.PA',
    label: 'GNE.PA,  General Electric Company',
    name: 'General Electric Company',
    symbol: 'GNE.PA',
  },
  {
    value: 'GNRO.PA',
    label: 'GNRO.PA,  GeNeuro SA',
    name: 'GeNeuro SA',
    symbol: 'GNRO.PA',
  },
  {
    value: 'GNFT.PA',
    label: 'GNFT.PA,  Genfit S.A.',
    name: 'Genfit S.A.',
    symbol: 'GNFT.PA',
  },
  {
    value: 'GV.PA',
    label: 'GV.PA,  Genomic Vision Société Anonyme',
    name: 'Genomic Vision Société Anonyme',
    symbol: 'GV.PA',
  },
  {
    value: 'ALGEN.PA',
    label: 'ALGEN.PA,  genOway Société anonyme',
    name: 'genOway Société anonyme',
    symbol: 'ALGEN.PA',
  },
  {
    value: 'SIGHT.PA',
    label: 'SIGHT.PA,  GenSight Biologics S.A.',
    name: 'GenSight Biologics S.A.',
    symbol: 'SIGHT.PA',
  },
  {
    value: 'GET.PA',
    label: 'GET.PA,  Getlink SE',
    name: 'Getlink SE',
    symbol: 'GET.PA',
  },
  {
    value: 'ALGEV.PA',
    label: 'ALGEV.PA,  Gévelot SA',
    name: 'Gévelot SA',
    symbol: 'ALGEV.PA',
  },
  {
    value: 'GIMB.BR',
    label: 'GIMB.BR,  Gimv NV',
    name: 'Gimv NV',
    symbol: 'GIMB.BR',
  },
  {
    value: 'GLO.PA',
    label: 'GLO.PA,  GL Events SA',
    name: 'GL Events SA',
    symbol: 'GLO.PA',
  },
  {
    value: 'GLINT.LS',
    label: 'GLINT.LS,  Glintt - Global Intelligent Technologies, S.A.',
    name: 'Glintt - Global Intelligent Technologies, S.A.',
    symbol: 'GLINT.LS',
  },
  {
    value: 'ALGBE.PA',
    label: 'ALGBE.PA,  Global Bioenergies SA',
    name: 'Global Bioenergies SA',
    symbol: 'ALGBE.PA',
  },
  {
    value: 'ALGLD.PA',
    label: 'ALGLD.PA,  Gold By Gold SA',
    name: 'Gold By Gold SA',
    symbol: 'ALGLD.PA',
  },
  {
    value: 'MLGML.PA',
    label: 'MLGML.PA,  Gour Medical SA',
    name: 'Gour Medical SA',
    symbol: 'MLGML.PA',
  },
  {
    value: 'GPE.PA',
    label: 'GPE.PA,  Groupe Pizzorno Environnement',
    name: 'Groupe Pizzorno Environnement',
    symbol: 'GPE.PA',
  },
  {
    value: 'ALPAR.PA',
    label: 'ALPAR.PA,  Groupe Parot SA',
    name: 'Groupe Parot SA',
    symbol: 'ALPAR.PA',
  },
  {
    value: 'GRVO.PA',
    label: 'GRVO.PA,  Graines Voltz S.A.',
    name: 'Graines Voltz S.A.',
    symbol: 'GRVO.PA',
  },
  {
    value: 'MLCMG.PA',
    label: 'MLCMG.PA,  CMG Cleantech S.A.',
    name: 'CMG Cleantech S.A.',
    symbol: 'MLCMG.PA',
  },
  {
    value: 'GREEN.BR',
    label: 'GREEN.BR,  Greenyard NV',
    name: 'Greenyard NV',
    symbol: 'GREEN.BR',
  },
  {
    value: 'MLGRC.PA',
    label: 'MLGRC.PA,  Groupe Carnivor Société Anonyme',
    name: 'Groupe Carnivor Société Anonyme',
    symbol: 'MLGRC.PA',
  },
  {
    value: 'CEN.PA',
    label: 'CEN.PA,  Groupe CRIT SA',
    name: 'Groupe CRIT SA',
    symbol: 'CEN.PA',
  },
  {
    value: 'FLO.PA',
    label: 'FLO.PA,  Groupe Flo SA',
    name: 'Groupe Flo SA',
    symbol: 'FLO.PA',
  },
  {
    value: 'GOE.PA',
    label: 'GOE.PA,  Groupe Gorgé SA',
    name: 'Groupe Gorgé SA',
    symbol: 'GOE.PA',
  },
  {
    value: 'ALGIL.PA',
    label: 'ALGIL.PA,  Groupe Guillin S.A.',
    name: 'Groupe Guillin S.A.',
    symbol: 'ALGIL.PA',
  },
  {
    value: 'IRD.PA',
    label: 'IRD.PA,  Groupe IRD SA',
    name: 'Groupe IRD SA',
    symbol: 'IRD.PA',
  },
  {
    value: 'GJAJ.PA',
    label: 'GJAJ.PA,  Groupe JAJ',
    name: 'Groupe JAJ',
    symbol: 'GJAJ.PA',
  },
  {
    value: 'ALLDL.PA',
    label: 'ALLDL.PA,  Groupe LDLC société anonyme',
    name: 'Groupe LDLC société anonyme',
    symbol: 'ALLDL.PA',
  },
  {
    value: 'PARP.PA',
    label: 'PARP.PA,  Groupe Partouche SA',
    name: 'Groupe Partouche SA',
    symbol: 'PARP.PA',
  },
  {
    value: 'SFPI.PA',
    label: 'SFPI.PA,  GROUPE SFPI SA',
    name: 'GROUPE SFPI SA',
    symbol: 'SFPI.PA',
  },
  {
    value: 'ALGTR.PA',
    label: 'ALGTR.PA,  Groupe Tera SA',
    name: 'Groupe Tera SA',
    symbol: 'ALGTR.PA',
  },
  {
    value: 'ALIMO.PA',
    label: 'ALIMO.PA,  Groupimo S.A.',
    name: 'Groupimo S.A.',
    symbol: 'ALIMO.PA',
  },
  {
    value: 'GTT.PA',
    label: 'GTT.PA,  Gaztransport & Technigaz SA',
    name: 'Gaztransport & Technigaz SA',
    symbol: 'GTT.PA',
  },
  {
    value: 'GBT.PA',
    label: 'GBT.PA,  Guerbet SA',
    name: 'Guerbet SA',
    symbol: 'GBT.PA',
  },
  {
    value: 'GUI.PA',
    label: 'GUI.PA,  Guillemot Corporation S.A.',
    name: 'Guillemot Corporation S.A.',
    symbol: 'GUI.PA',
  },
  {
    value: 'ALHEO.PA',
    label: 'ALHEO.PA,  H2O Innovation Inc.',
    name: 'H2O Innovation Inc.',
    symbol: 'ALHEO.PA',
  },
  {
    value: 'HAL.AS',
    label: 'HAL.AS,  HAL Trust',
    name: 'HAL Trust',
    symbol: 'HAL.AS',
  },
  {
    value: 'PIG.PA',
    label: 'PIG.PA,  Haulotte Group SA',
    name: 'Haulotte Group SA',
    symbol: 'PIG.PA',
  },
  {
    value: 'HEIJM.AS',
    label: 'HEIJM.AS,  Heijmans N.V.',
    name: 'Heijmans N.V.',
    symbol: 'HEIJM.AS',
  },
  {
    value: 'HEIA.AS',
    label: 'HEIA.AS,  Heineken N.V.',
    name: 'Heineken N.V.',
    symbol: 'HEIA.AS',
  },
  {
    value: 'HEIO.AS',
    label: 'HEIO.AS,  Heineken Holding N.V.',
    name: 'Heineken Holding N.V.',
    symbol: 'HEIO.AS',
  },
  {
    value: 'ALHRG.PA',
    label: 'ALHRG.PA,  Herige',
    name: 'Herige',
    symbol: 'ALHRG.PA',
  },
  {
    value: 'RMS.PA',
    label: 'RMS.PA,  Hermès International Société en commandite par actions',
    name: 'Hermès International Société en commandite par actions',
    symbol: 'RMS.PA',
  },
  {
    value: 'HEXA.PA',
    label: 'HEXA.PA,  Hexaom S.A.',
    name: 'Hexaom S.A.',
    symbol: 'HEXA.PA',
  },
  {
    value: 'HCO.PA',
    label: 'HCO.PA,  High Co. SA',
    name: 'High Co. SA',
    symbol: 'HCO.PA',
  },
  {
    value: 'ALHIT.PA',
    label: 'ALHIT.PA,  HITECHPROS Société anonyme',
    name: 'HITECHPROS Société anonyme',
    symbol: 'ALHIT.PA',
  },
  {
    value: 'MLHK.PA',
    label: 'MLHK.PA,  H&K AG',
    name: 'H&K AG',
    symbol: 'MLHK.PA',
  },
  {
    value: 'HOLCO.AS',
    label: 'HOLCO.AS,  Holland Colours N.V.',
    name: 'Holland Colours N.V.',
    symbol: 'HOLCO.AS',
  },
  {
    value: 'HOMI.BR',
    label: 'HOMI.BR,  Home Invest Belgium S.A.',
    name: 'Home Invest Belgium S.A.',
    symbol: 'HOMI.BR',
  },
  {
    value: 'MLHPE.PA',
    label: 'MLHPE.PA,  Hopening SA',
    name: 'Hopening SA',
    symbol: 'MLHPE.PA',
  },
  {
    value: 'HDP.PA',
    label: 'HDP.PA,  Les Hôtels de Paris SA',
    name: 'Les Hôtels de Paris SA',
    symbol: 'HDP.PA',
  },
  {
    value: 'MLHIN.PA',
    label: 'MLHIN.PA,  Société Hôtelière et Immobilière de Nice S.A.',
    name: 'Société Hôtelière et Immobilière de Nice S.A.',
    symbol: 'MLHIN.PA',
  },
  {
    value: 'ALHYG.PA',
    label: 'ALHYG.PA,  Hybrigenics SA',
    name: 'Hybrigenics SA',
    symbol: 'ALHYG.PA',
  },
  {
    value: 'HYDRA.AS',
    label: 'HYDRA.AS,  Hydratec Industries NV',
    name: 'Hydratec Industries NV',
    symbol: 'HYDRA.AS',
  },
  {
    value: 'MLHYE.PA',
    label: 'MLHYE.PA,  Hydro-Exploitations Société anonyme',
    name: 'Hydro-Exploitations Société anonyme',
    symbol: 'MLHYE.PA',
  },
  {
    value: 'ALICR.PA',
    label: 'ALICR.PA,  I.CERAM SA',
    name: 'I.CERAM SA',
    symbol: 'ALICR.PA',
  },
  {
    value: 'ALI2S.PA',
    label: 'ALI2S.PA,  i2S SA',
    name: 'i2S SA',
    symbol: 'ALI2S.PA',
  },
  {
    value: 'IBAB.BR',
    label: 'IBAB.BR,  Ion Beam Applications SA',
    name: 'Ion Beam Applications SA',
    symbol: 'IBAB.BR',
  },
  {
    value: 'IBS.LS',
    label: 'IBS.LS,  Ibersol, S.G.P.S., S.A.',
    name: 'Ibersol, S.G.P.S., S.A.',
    symbol: 'IBS.LS',
  },
  {
    value: 'ICAD.PA',
    label: 'ICAD.PA,  Icade SA',
    name: 'Icade SA',
    symbol: 'ICAD.PA',
  },
  {
    value: 'IDL.PA',
    label: 'IDL.PA,  ID Logistics Group SA',
    name: 'ID Logistics Group SA',
    symbol: 'IDL.PA',
  },
  {
    value: 'IDIP.PA',
    label: 'IDIP.PA,  IDI',
    name: 'IDI',
    symbol: 'IDIP.PA',
  },
  {
    value: 'MLIDS.PA',
    label: 'MLIDS.PA,  IDS France SAS',
    name: 'IDS France SAS',
    symbol: 'MLIDS.PA',
  },
  {
    value: 'ALIDS.PA',
    label: 'ALIDS.PA,  Idsud S.A.',
    name: 'Idsud S.A.',
    symbol: 'ALIDS.PA',
  },
  {
    value: 'IEP.BR',
    label: 'IEP.BR,  Iep Invest, NV',
    name: 'Iep Invest, NV',
    symbol: 'IEP.BR',
  },
  {
    value: 'IEX.AS',
    label: 'IEX.AS,  IEX Group N.V.',
    name: 'IEX Group N.V.',
    symbol: 'IEX.AS',
  },
  {
    value: 'MLIML.PA',
    label: 'MLIML.PA,  ImAlliance SA',
    name: 'ImAlliance SA',
    symbol: 'MLIML.PA',
  },
  {
    value: 'IMCD.AS',
    label: 'IMCD.AS,  IMCD N.V.',
    name: 'IMCD N.V.',
    symbol: 'IMCD.AS',
  },
  {
    value: 'NK.PA',
    label: 'NK.PA,  Imerys S.A.',
    name: 'Imerys S.A.',
    symbol: 'NK.PA',
  },
  {
    value: 'ALIMR.PA',
    label: 'ALIMR.PA,  Immersion SA',
    name: 'Immersion SA',
    symbol: 'ALIMR.PA',
  },
  {
    value: 'MCC.BR',
    label: 'MCC.BR,  Immo Mechelen City Center Naamloze vennootschap',
    name: 'Immo Mechelen City Center Naamloze vennootschap',
    symbol: 'MCC.BR',
  },
  {
    value: 'IMMOU.BR',
    label: 'IMMOU.BR,  Immo Moury SCA',
    name: 'Immo Moury SCA',
    symbol: 'IMMOU.BR',
  },
  {
    value: 'IMDA.PA',
    label: 'IMDA.PA,  Immobiliere Dassault SA',
    name: 'Immobiliere Dassault SA',
    symbol: 'IMDA.PA',
  },
  {
    value: 'IMMO.BR',
    label: 'IMMO.BR,  Immobel SA',
    name: 'Immobel SA',
    symbol: 'IMMO.BR',
  },
  {
    value: 'ALIMP.PA',
    label: 'ALIMP.PA,  Implanet S.A.',
    name: 'Implanet S.A.',
    symbol: 'ALIMP.PA',
  },
  {
    value: 'IPR.LS',
    label:
      'IPR.LS,  Impresa - Sociedade Gestora de Participações Sociais, S.A.',
    name: 'Impresa - Sociedade Gestora de Participações Sociais, S.A.',
    symbol: 'IPR.LS',
  },
  {
    value: 'MLIMP.PA',
    label: 'MLIMP.PA,  Imprimerie Chirat Société Anonyme',
    name: 'Imprimerie Chirat Société Anonyme',
    symbol: 'MLIMP.PA',
  },
  {
    value: 'INA.LS',
    label: 'INA.LS,  Inapa - Investimentos, Participações e Gestão, S.A.',
    name: 'Inapa - Investimentos, Participações e Gestão, S.A.',
    symbol: 'INA.LS',
  },
  {
    value: 'INFE.PA',
    label: "INFE.PA,  Compagnie Industrielle et Financière d'Entreprises SA",
    name: "Compagnie Industrielle et Financière d'Entreprises SA",
    symbol: 'INFE.PA',
  },
  {
    value: 'MLIFC.PA',
    label: 'MLIFC.PA,  Infoclip S.A.',
    name: 'Infoclip S.A.',
    symbol: 'MLIFC.PA',
  },
  {
    value: 'INF.PA',
    label: 'INF.PA,  Infotel SA',
    name: 'Infotel SA',
    symbol: 'INF.PA',
  },
  {
    value: 'INGA.AS',
    label: 'INGA.AS,  ING Groep N.V.',
    name: 'ING Groep N.V.',
    symbol: 'INGA.AS',
  },
  {
    value: 'IPH.PA',
    label: 'IPH.PA,  Innate Pharma S.A.',
    name: 'Innate Pharma S.A.',
    symbol: 'IPH.PA',
  },
  {
    value: 'ALINT.PA',
    label: 'ALINT.PA,  IntegraGen SA',
    name: 'IntegraGen SA',
    symbol: 'ALINT.PA',
  },
  {
    value: 'INCO.BR',
    label: 'INCO.BR,  Intel Corporation',
    name: 'Intel Corporation',
    symbol: 'INCO.BR',
  },
  {
    value: 'ITP.PA',
    label: 'ITP.PA,  Interparfums SA',
    name: 'Interparfums SA',
    symbol: 'ITP.PA',
  },
  {
    value: 'INTER.AS',
    label: 'INTER.AS,  Intertrust N.V.',
    name: 'Intertrust N.V.',
    symbol: 'INTER.AS',
  },
  {
    value: 'INTO.BR',
    label: 'INTO.BR,  Intervest Offices & Warehouses',
    name: 'Intervest Offices & Warehouses',
    symbol: 'INTO.BR',
  },
  {
    value: 'ALINS.PA',
    label: 'ALINS.PA,  Intrasense SA',
    name: 'Intrasense SA',
    symbol: 'ALINS.PA',
  },
  {
    value: 'IVA.PA',
    label: 'IVA.PA,  Inventiva S.A.',
    name: 'Inventiva S.A.',
    symbol: 'IVA.PA',
  },
  {
    value: 'ALINV.PA',
    label: 'ALINV.PA,  Invibes Advertising N.V.',
    name: 'Invibes Advertising N.V.',
    symbol: 'ALINV.PA',
  },
  {
    value: 'IPN.PA',
    label: 'IPN.PA,  Ipsen S.A.',
    name: 'Ipsen S.A.',
    symbol: 'IPN.PA',
  },
  {
    value: 'IPS.PA',
    label: 'IPS.PA,  Ipsos SA',
    name: 'Ipsos SA',
    symbol: 'IPS.PA',
  },
  {
    value: 'ITE.PA',
    label: 'ITE.PA,  Itesoft S.A.',
    name: 'Itesoft S.A.',
    symbol: 'ITE.PA',
  },
  {
    value: 'JMT.LS',
    label: 'JMT.LS,  Jerónimo Martins, SGPS, S.A.',
    name: 'Jerónimo Martins, SGPS, S.A.',
    symbol: 'JMT.LS',
  },
  {
    value: 'JBOG.PA',
    label: 'JBOG.PA,  Jacques Bogart S.A.',
    name: 'Jacques Bogart S.A.',
    symbol: 'JBOG.PA',
  },
  {
    value: 'JCQ.PA',
    label: 'JCQ.PA,  Jacquet Metals SA',
    name: 'Jacquet Metals SA',
    symbol: 'JCQ.PA',
  },
  {
    value: 'DEC.PA',
    label: 'DEC.PA,  JCDecaux SE',
    name: 'JCDecaux SE',
    symbol: 'DEC.PA',
  },
  {
    value: 'JEN.BR',
    label: 'JEN.BR,  Jensen-Group NV',
    name: 'Jensen-Group NV',
    symbol: 'JEN.BR',
  },
  {
    value: 'ALKAL.PA',
    label: 'ALKAL.PA,  Kalray S.A.',
    name: 'Kalray S.A.',
    symbol: 'ALKAL.PA',
  },
  {
    value: 'KOF.PA',
    label: 'KOF.PA,  Kaufman & Broad S.A.',
    name: 'Kaufman & Broad S.A.',
    symbol: 'KOF.PA',
  },
  {
    value: 'KBC.BR',
    label: 'KBC.BR,  KBC Group NV',
    name: 'KBC Group NV',
    symbol: 'KBC.BR',
  },
  {
    value: 'KBCA.BR',
    label: 'KBCA.BR,  KBC Ancora SCA',
    name: 'KBC Ancora SCA',
    symbol: 'KBCA.BR',
  },
  {
    value: 'KENDR.AS',
    label: 'KENDR.AS,  Kendrion N.V.',
    name: 'Kendrion N.V.',
    symbol: 'KENDR.AS',
  },
  {
    value: 'KER.PA',
    label: 'KER.PA,  Kering SA',
    name: 'Kering SA',
    symbol: 'KER.PA',
  },
  {
    value: 'ALKLK.PA',
    label: 'ALKLK.PA,  Kerlink SA',
    name: 'Kerlink SA',
    symbol: 'ALKLK.PA',
  },
  {
    value: 'KEYW.BR',
    label: 'KEYW.BR,  Keyware Technologies NV',
    name: 'Keyware Technologies NV',
    symbol: 'KEYW.BR',
  },
  {
    value: 'KIN.BR',
    label: 'KIN.BR,  Kinepolis Group NV',
    name: 'Kinepolis Group NV',
    symbol: 'KIN.BR',
  },
  {
    value: 'ALKKO.PA',
    label: 'ALKKO.PA,  KKO International SA',
    name: 'KKO International SA',
    symbol: 'ALKKO.PA',
  },
  {
    value: 'LI.PA',
    label: 'LI.PA,  Klépierre SA',
    name: 'Klépierre SA',
    symbol: 'LI.PA',
  },
  {
    value: 'KORI.PA',
    label: 'KORI.PA,  Korian',
    name: 'Korian',
    symbol: 'KORI.PA',
  },
  {
    value: 'KPN.AS',
    label: 'KPN.AS,  Koninklijke KPN N.V.',
    name: 'Koninklijke KPN N.V.',
    symbol: 'KPN.AS',
  },
  {
    value: 'OR.PA',
    label: "OR.PA,  L'Oréal S.A.",
    name: "L'Oréal S.A.",
    symbol: 'OR.PA',
  },
  {
    value: 'ALPER.PA',
    label: 'ALPER.PA,  La Perla Fashion Holding N.V.',
    name: 'La Perla Fashion Holding N.V.',
    symbol: 'ALPER.PA',
  },
  {
    value: 'LACR.PA',
    label: 'LACR.PA,  LACROIX Group SA',
    name: 'LACROIX Group SA',
    symbol: 'LACR.PA',
  },
  {
    value: 'MMB.PA',
    label: 'MMB.PA,  Lagardere SA',
    name: 'Lagardere SA',
    symbol: 'MMB.PA',
  },
  {
    value: 'ALLAN.PA',
    label: 'ALLAN.PA,  Lanson-BCC',
    name: 'Lanson-BCC',
    symbol: 'ALLAN.PA',
  },
  {
    value: 'LAT.PA',
    label: 'LAT.PA,  Latécoère S.A.',
    name: 'Latécoère S.A.',
    symbol: 'LAT.PA',
  },
  {
    value: 'LPE.PA',
    label: 'LPE.PA,  Laurent-Perrier S.A.',
    name: 'Laurent-Perrier S.A.',
    symbol: 'LPE.PA',
  },
  {
    value: 'LVIDE.AS',
    label: 'LVIDE.AS,  Lavide Holding N.V.',
    name: 'Lavide Holding N.V.',
    symbol: 'LVIDE.AS',
  },
  {
    value: 'LOUP.PA',
    label: 'LOUP.PA,  L.D.C. S.A.',
    name: 'L.D.C. S.A.',
    symbol: 'LOUP.PA',
  },
  {
    value: 'LSS.PA',
    label: 'LSS.PA,  Lectra SA',
    name: 'Lectra SA',
    symbol: 'LSS.PA',
  },
  {
    value: 'LR.PA',
    label: 'LR.PA,  Legrand SA',
    name: 'Legrand SA',
    symbol: 'LR.PA',
  },
  {
    value: 'ALLHB.PA',
    label: 'ALLHB.PA,  Les Hôtels Baverez S.A.',
    name: 'Les Hôtels Baverez S.A.',
    symbol: 'ALLHB.PA',
  },
  {
    value: 'ALLEX.PA',
    label: 'ALLEX.PA,  Lexibook - Linguistic Electronic System Société anonyme',
    name: 'Lexibook - Linguistic Electronic System Société anonyme',
    symbol: 'ALLEX.PA',
  },
  {
    value: 'LIN.PA',
    label: 'LIN.PA,  Linedata Services S.A.',
    name: 'Linedata Services S.A.',
    symbol: 'LIN.PA',
  },
  {
    value: 'LIG.LS',
    label: 'LIG.LS,  Lisgráfica - Impressão e Artes Gráficas, S.A.',
    name: 'Lisgráfica - Impressão e Artes Gráficas, S.A.',
    symbol: 'LIG.LS',
  },
  {
    value: 'FII.PA',
    label: 'FII.PA,  Lisi S.A.',
    name: 'Lisi S.A.',
    symbol: 'FII.PA',
  },
  {
    value: 'ALLLN.PA',
    label: 'ALLLN.PA,  LleidaNetworks Serveis Telemàtics, S.A.',
    name: 'LleidaNetworks Serveis Telemàtics, S.A.',
    symbol: 'ALLLN.PA',
  },
  {
    value: 'LNA.PA',
    label: 'LNA.PA,  LNA Santé SA',
    name: 'LNA Santé SA',
    symbol: 'LNA.PA',
  },
  {
    value: 'ALLOG.PA',
    label: 'ALLOG.PA,  Logic Instrument S.A.',
    name: 'Logic Instrument S.A.',
    symbol: 'ALLOG.PA',
  },
  {
    value: 'LOTB.BR',
    label: 'LOTB.BR,  Lotus Bakeries NV',
    name: 'Lotus Bakeries NV',
    symbol: 'LOTB.BR',
  },
  {
    value: 'BOLS.AS',
    label: 'BOLS.AS,  Lucas Bols N.V.',
    name: 'Lucas Bols N.V.',
    symbol: 'BOLS.AS',
  },
  {
    value: 'ALUCI.PA',
    label: 'ALUCI.PA,  Lucibel SA',
    name: 'Lucibel SA',
    symbol: 'ALUCI.PA',
  },
  {
    value: 'LBIRD.PA',
    label: 'LBIRD.PA,  Lumibird SA',
    name: 'Lumibird SA',
    symbol: 'LBIRD.PA',
  },
  {
    value: 'MC.PA',
    label: 'MC.PA,  LVMH Moët Hennessy - Louis Vuitton, Société Européenne',
    name: 'LVMH Moët Hennessy - Louis Vuitton, Société Européenne',
    symbol: 'MC.PA',
  },
  {
    value: 'LYS.PA',
    label: 'LYS.PA,  Lysogene S.A.',
    name: 'Lysogene S.A.',
    symbol: 'LYS.PA',
  },
  {
    value: 'MRM.PA',
    label: 'MRM.PA,  M.R.M. SA',
    name: 'M.R.M. SA',
    symbol: 'MRM.PA',
  },
  {
    value: 'ALMII.PA',
    label: 'ALMII.PA,  M2i Société anonyme',
    name: 'M2i Société anonyme',
    symbol: 'ALMII.PA',
  },
  {
    value: 'MLMAD.PA',
    label: 'MLMAD.PA,  Made SA',
    name: 'Made SA',
    symbol: 'MLMAD.PA',
  },
  {
    value: 'MLMAB.PA',
    label: 'MLMAB.PA,  Maison Antoine Baud Société Anonyme',
    name: 'Maison Antoine Baud Société Anonyme',
    symbol: 'MLMAB.PA',
  },
  {
    value: 'MDM.PA',
    label: 'MDM.PA,  Maisons du Monde S.A.',
    name: 'Maisons du Monde S.A.',
    symbol: 'MDM.PA',
  },
  {
    value: 'ALMAK.PA',
    label: 'ALMAK.PA,  Makheia Group Société anonyme',
    name: 'Makheia Group Société anonyme',
    symbol: 'ALMAK.PA',
  },
  {
    value: 'MALT.PA',
    label: 'MALT.PA,  Malteries Franco-Belges Société Anonyme',
    name: 'Malteries Franco-Belges Société Anonyme',
    symbol: 'MALT.PA',
  },
  {
    value: 'MTU.PA',
    label: 'MTU.PA,  Manitou BF SA',
    name: 'Manitou BF SA',
    symbol: 'MTU.PA',
  },
  {
    value: 'MAN.PA',
    label: 'MAN.PA,  Manutan International S.A.',
    name: 'Manutan International S.A.',
    symbol: 'MAN.PA',
  },
  {
    value: 'MAREL.AS',
    label: 'MAREL.AS,  Marel hf.',
    name: 'Marel hf.',
    symbol: 'MAREL.AS',
  },
  {
    value: 'IAM.PA',
    label: 'IAM.PA,  Itissalat Al-Maghrib (IAM) S.A.',
    name: 'Itissalat Al-Maghrib (IAM) S.A.',
    symbol: 'IAM.PA',
  },
  {
    value: 'MAR.LS',
    label: 'MAR.LS,  Martifer SGPS, S.A.',
    name: 'Martifer SGPS, S.A.',
    symbol: 'MAR.LS',
  },
  {
    value: 'ALMAS.PA',
    label: 'ALMAS.PA,  Mastrad Société Anonyme',
    name: 'Mastrad Société Anonyme',
    symbol: 'ALMAS.PA',
  },
  {
    value: 'MKEA.PA',
    label: 'MKEA.PA,  Mauna Kea Technologies SA',
    name: 'Mauna Kea Technologies SA',
    symbol: 'MKEA.PA',
  },
  {
    value: 'MAU.PA',
    label: 'MAU.PA,  Etablissements Maurel & Prom S.A.',
    name: 'Etablissements Maurel & Prom S.A.',
    symbol: 'MAU.PA',
  },
  {
    value: 'MBWS.PA',
    label: 'MBWS.PA,  Marie Brizard Wine & Spirits SA',
    name: 'Marie Brizard Wine & Spirits SA',
    symbol: 'MBWS.PA',
  },
  {
    value: 'MCPHY.PA',
    label: 'MCPHY.PA,  McPhy Energy S.A.',
    name: 'McPhy Energy S.A.',
    symbol: 'MCPHY.PA',
  },
  {
    value: 'MDXH.BR',
    label: 'MDXH.BR,  MDxHealth SA',
    name: 'MDxHealth SA',
    symbol: 'MDXH.BR',
  },
  {
    value: 'EDI.PA',
    label: 'EDI.PA,  Groupe MEDIA 6',
    name: 'Groupe MEDIA 6',
    symbol: 'EDI.PA',
  },
  {
    value: 'MLLAB.PA',
    label: 'MLLAB.PA,  Media Lab S.p.A.',
    name: 'Media Lab S.p.A.',
    symbol: 'MLLAB.PA',
  },
  {
    value: 'ALKER.PA',
    label: 'ALKER.PA,  Media-Maker S.p.A.',
    name: 'Media-Maker S.p.A.',
    symbol: 'ALKER.PA',
  },
  {
    value: 'ALMDT.PA',
    label: 'ALMDT.PA,  Median Technologies SA',
    name: 'Median Technologies SA',
    symbol: 'ALMDT.PA',
  },
  {
    value: 'MEDCL.PA',
    label: 'MEDCL.PA,  MedinCell S.A.',
    name: 'MedinCell S.A.',
    symbol: 'MEDCL.PA',
  },
  {
    value: 'MELE.BR',
    label: 'MELE.BR,  Melexis NV',
    name: 'Melexis NV',
    symbol: 'MELE.BR',
  },
  {
    value: 'MEMS.PA',
    label: 'MEMS.PA,  MEMSCAP, S.A.',
    name: 'MEMSCAP, S.A.',
    symbol: 'MEMS.PA',
  },
  {
    value: 'MERY.PA',
    label: 'MERY.PA,  Mercialys',
    name: 'Mercialys',
    symbol: 'MERY.PA',
  },
  {
    value: 'MRK.PA',
    label: 'MRK.PA,  Merck & Co., Inc.',
    name: 'Merck & Co., Inc.',
    symbol: 'MRK.PA',
  },
  {
    value: 'MRN.PA',
    label: 'MRN.PA,  Mersen S.A.',
    name: 'Mersen S.A.',
    symbol: 'MRN.PA',
  },
  {
    value: 'METEX.PA',
    label: 'METEX.PA,  METabolic EXplorer S.A.',
    name: 'METabolic EXplorer S.A.',
    symbol: 'METEX.PA',
  },
  {
    value: 'MLETA.PA',
    label: 'MLETA.PA,  Metalliance SA',
    name: 'Metalliance SA',
    symbol: 'MLETA.PA',
  },
  {
    value: 'ALMET.PA',
    label: 'ALMET.PA,  Méthanor SCA',
    name: 'Méthanor SCA',
    symbol: 'ALMET.PA',
  },
  {
    value: 'MLMIB.PA',
    label: 'MLMIB.PA,  Metrics in Balance N.V.',
    name: 'Metrics in Balance N.V.',
    symbol: 'MLMIB.PA',
  },
  {
    value: 'MMT.PA',
    label: 'MMT.PA,  Métropole Télévision S.A.',
    name: 'Métropole Télévision S.A.',
    symbol: 'MMT.PA',
  },
  {
    value: 'ALMGI.PA',
    label: 'ALMGI.PA,  MG International S.A.',
    name: 'MG International S.A.',
    symbol: 'ALMGI.PA',
  },
  {
    value: 'ALMDG.PA',
    label: 'ALMDG.PA,  MGI Digital Technology Société Anonyme',
    name: 'MGI Digital Technology Société Anonyme',
    symbol: 'ALMDG.PA',
  },
  {
    value: 'ML.PA',
    label:
      'ML.PA,  Compagnie Générale des Établissements Michelin Société en commandite par actions',
    name: 'Compagnie Générale des Établissements Michelin Société en commandite par actions',
    symbol: 'ML.PA',
  },
  {
    value: 'MLNMA.PA',
    label: 'MLNMA.PA,  Nicolas Miguet et Associes SA',
    name: 'Nicolas Miguet et Associes SA',
    symbol: 'MLNMA.PA',
  },
  {
    value: 'MIKO.BR',
    label: 'MIKO.BR,  Miko NV',
    name: 'Miko NV',
    symbol: 'MIKO.BR',
  },
  {
    value: 'ALMLB.PA',
    label: 'ALMLB.PA,  Miliboo Société anonyme',
    name: 'Miliboo Société anonyme',
    symbol: 'ALMLB.PA',
  },
  {
    value: 'MITRA.BR',
    label: 'MITRA.BR,  Mithra Pharmaceuticals SA',
    name: 'Mithra Pharmaceuticals SA',
    symbol: 'MITRA.BR',
  },
  {
    value: 'NEDSE.AS',
    label: 'NEDSE.AS,  MKB Nedsense NV',
    name: 'MKB Nedsense NV',
    symbol: 'NEDSE.AS',
  },
  {
    value: 'ALMND.PA',
    label: 'ALMND.PA,  Montagne et Neige Développement SA',
    name: 'Montagne et Neige Développement SA',
    symbol: 'ALMND.PA',
  },
  {
    value: 'MONC.PA',
    label: 'MONC.PA,  Financière Moncey Société anonyme',
    name: 'Financière Moncey Société anonyme',
    symbol: 'MONC.PA',
  },
  {
    value: 'MONT.BR',
    label: 'MONT.BR,  Montea Comm. VA',
    name: 'Montea Comm. VA',
    symbol: 'MONT.BR',
  },
  {
    value: 'MORE.AS',
    label: 'MORE.AS,  Morefield Group N.V.',
    name: 'Morefield Group N.V.',
    symbol: 'MORE.AS',
  },
  {
    value: 'EGL.LS',
    label: 'EGL.LS,  Mota-Engil, SGPS, S.A.',
    name: 'Mota-Engil, SGPS, S.A.',
    symbol: 'EGL.LS',
  },
  {
    value: 'ALMOU.PA',
    label: 'ALMOU.PA,  Moulinvest SA',
    name: 'Moulinvest SA',
    symbol: 'ALMOU.PA',
  },
  {
    value: 'MOUR.BR',
    label: 'MOUR.BR,  Moury Construct SA',
    name: 'Moury Construct SA',
    symbol: 'MOUR.BR',
  },
  {
    value: 'MEURV.BR',
    label:
      'MEURV.BR,  Münchener Rückversicherungs-Gesellschaft Aktiengesellschaft in München',
    name: 'Münchener Rückversicherungs-Gesellschaft Aktiengesellschaft in München',
    symbol: 'MEURV.BR',
  },
  {
    value: 'NANO.PA',
    label: 'NANO.PA,  Nanobiotix S.A.',
    name: 'Nanobiotix S.A.',
    symbol: 'NANO.PA',
  },
  {
    value: 'NAVYA.PA',
    label: 'NAVYA.PA,  Navya SA',
    name: 'Navya SA',
    symbol: 'NAVYA.PA',
  },
  {
    value: 'NEDAP.AS',
    label: 'NEDAP.AS,  Nedap N.V.',
    name: 'Nedap N.V.',
    symbol: 'NEDAP.AS',
  },
  {
    value: 'MLNEO.PA',
    label: 'MLNEO.PA,  Néocom Multimédia SA',
    name: 'Néocom Multimédia SA',
    symbol: 'MLNEO.PA',
  },
  {
    value: 'NEOEN.PA',
    label: 'NEOEN.PA,  Neoen S.A.',
    name: 'Neoen S.A.',
    symbol: 'NEOEN.PA',
  },
  {
    value: 'ALNLF.PA',
    label: 'ALNLF.PA,  Neolife SA',
    name: 'Neolife SA',
    symbol: 'ALNLF.PA',
  },
  {
    value: 'ALNEV.PA',
    label: 'ALNEV.PA,  Neovacs S.A.',
    name: 'Neovacs S.A.',
    symbol: 'ALNEV.PA',
  },
  {
    value: 'NRP.AS',
    label: 'NRP.AS,  NEPI Rockcastle N.V.',
    name: 'NEPI Rockcastle N.V.',
    symbol: 'NRP.AS',
  },
  {
    value: 'NRO.PA',
    label: 'NRO.PA,  Neurones S.A.',
    name: 'Neurones S.A.',
    symbol: 'NRO.PA',
  },
  {
    value: 'NSE.AS',
    label: 'NSE.AS,  New Sources Energy N.V.',
    name: 'New Sources Energy N.V.',
    symbol: 'NSE.AS',
  },
  {
    value: 'NEWT.BR',
    label: 'NEWT.BR,  Newtree S.A.',
    name: 'Newtree S.A.',
    symbol: 'NEWT.BR',
  },
  {
    value: 'NEX.PA',
    label: 'NEX.PA,  Nexans S.A.',
    name: 'Nexans S.A.',
    symbol: 'NEX.PA',
  },
  {
    value: 'NXI.PA',
    label: 'NXI.PA,  Nexity SA',
    name: 'Nexity SA',
    symbol: 'NXI.PA',
  },
  {
    value: 'ALNXT.PA',
    label: 'ALNXT.PA,  Nextedia S.A.',
    name: 'Nextedia S.A.',
    symbol: 'ALNXT.PA',
  },
  {
    value: 'NEXTS.PA',
    label: 'NEXTS.PA,  NextStage SCA',
    name: 'NextStage SCA',
    symbol: 'NEXTS.PA',
  },
  {
    value: 'COX.PA',
    label: 'COX.PA,  Nicox S.A.',
    name: 'Nicox S.A.',
    symbol: 'COX.PA',
  },
  {
    value: 'NN.AS',
    label: 'NN.AS,  NN Group N.V.',
    name: 'NN Group N.V.',
    symbol: 'NN.AS',
  },
  {
    value: 'NOKIA.PA',
    label: 'NOKIA.PA,  Nokia Oyj',
    name: 'Nokia Oyj',
    symbol: 'NOKIA.PA',
  },
  {
    value: 'NOS.LS',
    label: 'NOS.LS,  NOS, S.G.P.S., S.A.',
    name: 'NOS, S.G.P.S., S.A.',
    symbol: 'NOS.LS',
  },
  {
    value: 'NBA.LS',
    label: 'NBA.LS,  Novabase S.G.P.S., S.A.',
    name: 'Novabase S.G.P.S., S.A.',
    symbol: 'NBA.LS',
  },
  {
    value: 'ALNOV.PA',
    label: 'ALNOV.PA,  Novacyt S.A.',
    name: 'Novacyt S.A.',
    symbol: 'ALNOV.PA',
  },
  {
    value: 'NR21.PA',
    label: 'NR21.PA,  NR21 Société Anonyme',
    name: 'NR21 Société Anonyme',
    symbol: 'NR21.PA',
  },
  {
    value: 'NRG.PA',
    label: 'NRG.PA,  NRJ Group SA',
    name: 'NRJ Group SA',
    symbol: 'NRG.PA',
  },
  {
    value: 'ALNSC.PA',
    label: 'ALNSC.PA,  NSC Groupe SA',
    name: 'NSC Groupe SA',
    symbol: 'ALNSC.PA',
  },
  {
    value: 'ALNSE.PA',
    label: 'ALNSE.PA,  NSE S.A.',
    name: 'NSE S.A.',
    symbol: 'ALNSE.PA',
  },
  {
    value: 'NSI.AS',
    label: 'NSI.AS,  NSI N.V.',
    name: 'NSI N.V.',
    symbol: 'NSI.AS',
  },
  {
    value: 'NYR.BR',
    label: 'NYR.BR,  Nyrstar NV',
    name: 'Nyrstar NV',
    symbol: 'NYR.BR',
  },
  {
    value: 'MLOSA.PA',
    label: "MLOSA.PA,  O Sorbet D'amour SA",
    name: "O Sorbet D'amour SA",
    symbol: 'MLOSA.PA',
  },
  {
    value: 'ALOBR.PA',
    label: 'ALOBR.PA,  Ober SA',
    name: 'Ober SA',
    symbol: 'ALOBR.PA',
  },
  {
    value: 'OCI.AS',
    label: 'OCI.AS,  OCI N.V.',
    name: 'OCI N.V.',
    symbol: 'OCI.AS',
  },
  {
    value: 'MLOCT.PA',
    label: 'MLOCT.PA,  Octopus Biosafety SA',
    name: 'Octopus Biosafety SA',
    symbol: 'MLOCT.PA',
  },
  {
    value: 'SBT.PA',
    label: 'SBT.PA,  Oeneo SA',
    name: 'Oeneo SA',
    symbol: 'SBT.PA',
  },
  {
    value: 'OLG.PA',
    label: 'OLG.PA,  Olympique Lyonnais Groupe SA',
    name: 'Olympique Lyonnais Groupe SA',
    symbol: 'OLG.PA',
  },
  {
    value: 'ALONC.PA',
    label: 'ALONC.PA,  Oncodesign Société Anonyme',
    name: 'Oncodesign Société Anonyme',
    symbol: 'ALONC.PA',
  },
  {
    value: 'MLONL.PA',
    label: 'MLONL.PA,  SA Onlineformapro',
    name: 'SA Onlineformapro',
    symbol: 'MLONL.PA',
  },
  {
    value: 'ONTEX.BR',
    label: 'ONTEX.BR,  Ontex Group NV',
    name: 'Ontex Group NV',
    symbol: 'ONTEX.BR',
  },
  {
    value: 'ORA.PA',
    label: 'ORA.PA,  Orange S.A.',
    name: 'Orange S.A.',
    symbol: 'ORA.PA',
  },
  {
    value: 'OBEL.BR',
    label: 'OBEL.BR,  Orange Belgium S.A.',
    name: 'Orange Belgium S.A.',
    symbol: 'OBEL.BR',
  },
  {
    value: 'ORAP.PA',
    label: 'ORAP.PA,  Orapi SA',
    name: 'Orapi SA',
    symbol: 'ORAP.PA',
  },
  {
    value: 'ORDI.AS',
    label: 'ORDI.AS,  Ordina N.V.',
    name: 'Ordina N.V.',
    symbol: 'ORDI.AS',
  },
  {
    value: 'ALORD.PA',
    label: 'ALORD.PA,  Ordissimo SA',
    name: 'Ordissimo SA',
    symbol: 'ALORD.PA',
  },
  {
    value: 'OREGE.PA',
    label: 'OREGE.PA,  Orege Société Anonyme',
    name: 'Orege Société Anonyme',
    symbol: 'OREGE.PA',
  },
  {
    value: 'ORP.PA',
    label: 'ORP.PA,  Orpea SA',
    name: 'Orpea SA',
    symbol: 'ORP.PA',
  },
  {
    value: 'OSE.PA',
    label: 'OSE.PA,  OSE Immunotherapeutics SA',
    name: 'OSE Immunotherapeutics SA',
    symbol: 'OSE.PA',
  },
  {
    value: 'ALOSM.PA',
    label: 'ALOSM.PA,  Osmozis SA',
    name: 'Osmozis SA',
    symbol: 'ALOSM.PA',
  },
  {
    value: 'OXUR.BR',
    label: 'OXUR.BR,  Oxurion NV',
    name: 'Oxurion NV',
    symbol: 'OXUR.BR',
  },
  {
    value: 'MLPAC.PA',
    label: 'MLPAC.PA,  Pacte Novation',
    name: 'Pacte Novation',
    symbol: 'MLPAC.PA',
  },
  {
    value: 'PID.PA',
    label: 'PID.PA,  Paragon ID SA',
    name: 'Paragon ID SA',
    symbol: 'PID.PA',
  },
  {
    value: 'PAR.PA',
    label: 'PAR.PA,  Paref SA',
    name: 'Paref SA',
    symbol: 'PAR.PA',
  },
  {
    value: 'PARRO.PA',
    label: 'PARRO.PA,  Parrot S.A.',
    name: 'Parrot S.A.',
    symbol: 'PARRO.PA',
  },
  {
    value: 'MLHOP.PA',
    label: 'MLHOP.PA,  Participation Industrielle Miniere Société Anonyme',
    name: 'Participation Industrielle Miniere Société Anonyme',
    symbol: 'MLHOP.PA',
  },
  {
    value: 'MLPRX.PA',
    label: 'MLPRX.PA,  Parx Materials N.V.',
    name: 'Parx Materials N.V.',
    symbol: 'MLPRX.PA',
  },
  {
    value: 'PSAT.PA',
    label: 'PSAT.PA,  Passat Société Anonyme',
    name: 'Passat Société Anonyme',
    symbol: 'PSAT.PA',
  },
  {
    value: 'PAT.PA',
    label: 'PAT.PA,  Patrimoine et Commerce SA',
    name: 'Patrimoine et Commerce SA',
    symbol: 'PAT.PA',
  },
  {
    value: 'PAY.BR',
    label: 'PAY.BR,  Payton Planar Magnetics Ltd.',
    name: 'Payton Planar Magnetics Ltd.',
    symbol: 'PAY.BR',
  },
  {
    value: 'PCA.PA',
    label: 'PCA.PA,  PCAS SA',
    name: 'PCAS SA',
    symbol: 'PCA.PA',
  },
  {
    value: 'RI.PA',
    label: 'RI.PA,  Pernod Ricard SA',
    name: 'Pernod Ricard SA',
    symbol: 'RI.PA',
  },
  {
    value: 'PERR.PA',
    label: 'PERR.PA,  Gérard Perrier Industrie S.A.',
    name: 'Gérard Perrier Industrie S.A.',
    symbol: 'PERR.PA',
  },
  {
    value: 'PSH.AS',
    label: 'PSH.AS,  Pershing Square Holdings, Ltd.',
    name: 'Pershing Square Holdings, Ltd.',
    symbol: 'PSH.AS',
  },
  {
    value: 'ALPHS.PA',
    label: 'ALPHS.PA,  Pharmasimple SA',
    name: 'Pharmasimple SA',
    symbol: 'ALPHS.PA',
  },
  {
    value: 'PHARM.AS',
    label: 'PHARM.AS,  Pharming Group N.V.',
    name: 'Pharming Group N.V.',
    symbol: 'PHARM.AS',
  },
  {
    value: 'ALPHA.PA',
    label: 'ALPHA.PA,  Pharnext SA',
    name: 'Pharnext SA',
    symbol: 'ALPHA.PA',
  },
  {
    value: 'PHR.LS',
    label: 'PHR.LS,  Pharol, SGPS S.A.',
    name: 'Pharol, SGPS S.A.',
    symbol: 'PHR.LS',
  },
  {
    value: 'PHIA.AS',
    label: 'PHIA.AS,  Koninklijke Philips N.V.',
    name: 'Koninklijke Philips N.V.',
    symbol: 'PHIA.AS',
  },
  {
    value: 'MLPHW.PA',
    label: 'MLPHW.PA,  Phone Web SA',
    name: 'Phone Web SA',
    symbol: 'MLPHW.PA',
  },
  {
    value: 'MLPHO.PA',
    label: 'MLPHO.PA,  Photonike Capital SA',
    name: 'Photonike Capital SA',
    symbol: 'MLPHO.PA',
  },
  {
    value: 'PIC.BR',
    label: 'PIC.BR,  Picanol nv',
    name: 'Picanol nv',
    symbol: 'PIC.BR',
  },
  {
    value: 'VAC.PA',
    label: 'VAC.PA,  Pierre et Vacances SA',
    name: 'Pierre et Vacances SA',
    symbol: 'VAC.PA',
  },
  {
    value: 'ALPDX.PA',
    label: 'ALPDX.PA,  Piscines Desjoyaux SA',
    name: 'Piscines Desjoyaux SA',
    symbol: 'ALPDX.PA',
  },
  {
    value: 'ALPLA.PA',
    label: 'ALPLA.PA,  Erold SA',
    name: 'Erold SA',
    symbol: 'ALPLA.PA',
  },
  {
    value: 'ALPAT.PA',
    label: 'ALPAT.PA,  Plant Advanced Technologies SA',
    name: 'Plant Advanced Technologies SA',
    symbol: 'ALPAT.PA',
  },
  {
    value: 'PVL.PA',
    label: 'PVL.PA,  Plastiques du Val de Loire',
    name: 'Plastiques du Val de Loire',
    symbol: 'PVL.PA',
  },
  {
    value: 'POM.PA',
    label: 'POM.PA,  Compagnie Plastic Omnium SE',
    name: 'Compagnie Plastic Omnium SE',
    symbol: 'POM.PA',
  },
  {
    value: 'PORF.AS',
    label: 'PORF.AS,  Koninklijke Porceleyne Fles NV',
    name: 'Koninklijke Porceleyne Fles NV',
    symbol: 'PORF.AS',
  },
  {
    value: 'PNL.AS',
    label: 'PNL.AS,  PostNL N.V.',
    name: 'PostNL N.V.',
    symbol: 'PNL.AS',
  },
  {
    value: 'ALPJT.PA',
    label: 'ALPJT.PA,  Poujoulat SA',
    name: 'Poujoulat SA',
    symbol: 'ALPJT.PA',
  },
  {
    value: 'ALPOU.PA',
    label: 'ALPOU.PA,  Poulaillon SA',
    name: 'Poulaillon SA',
    symbol: 'ALPOU.PA',
  },
  {
    value: 'POXEL.PA',
    label: 'POXEL.PA,  Poxel S.A.',
    name: 'Poxel S.A.',
    symbol: 'POXEL.PA',
  },
  {
    value: 'PREC.PA',
    label: 'PREC.PA,  Precia S.A.',
    name: 'Precia S.A.',
    symbol: 'PREC.PA',
  },
  {
    value: 'ALPRE.PA',
    label: 'ALPRE.PA,  Predilife S.A.',
    name: 'Predilife S.A.',
    symbol: 'ALPRE.PA',
  },
  {
    value: 'ALPRI.PA',
    label: 'ALPRI.PA,  Prismaflex International, S.A.',
    name: 'Prismaflex International, S.A.',
    symbol: 'ALPRI.PA',
  },
  {
    value: 'PROAC.PA',
    label: 'PROAC.PA,  Proactis SA',
    name: 'Proactis SA',
    symbol: 'PROAC.PA',
  },
  {
    value: 'ALPRO.PA',
    label: 'ALPRO.PA,  Prodware S.A.',
    name: 'Prodware S.A.',
    symbol: 'ALPRO.PA',
  },
  {
    value: 'PWG.PA',
    label: 'PWG.PA,  Prodways Group SA',
    name: 'Prodways Group SA',
    symbol: 'PWG.PA',
  },
  {
    value: 'PROX.BR',
    label: 'PROX.BR,  Proximus PLC',
    name: 'Proximus PLC',
    symbol: 'PROX.BR',
  },
  {
    value: 'PUB.PA',
    label: 'PUB.PA,  Publicis Groupe S.A.',
    name: 'Publicis Groupe S.A.',
    symbol: 'PUB.PA',
  },
  {
    value: 'QRF.BR',
    label: 'QRF.BR,  Qrf Comm. VA',
    name: 'Qrf Comm. VA',
    symbol: 'QRF.BR',
  },
  {
    value: 'ALQGC.PA',
    label: 'ALQGC.PA,  Quantum Genomics Société Anonyme',
    name: 'Quantum Genomics Société Anonyme',
    symbol: 'ALQGC.PA',
  },
  {
    value: 'QFG.BR',
    label: 'QFG.BR,  Quest for Growth Belgium',
    name: 'Quest for Growth Belgium',
    symbol: 'QFG.BR',
  },
  {
    value: 'ALQWA.PA',
    label: 'ALQWA.PA,  Qwamplify',
    name: 'Qwamplify',
    symbol: 'ALQWA.PA',
  },
  {
    value: 'MLRZE.LS',
    label: 'MLRZE.LS,  Raize - Instituição de Pagamentos, S.A.',
    name: 'Raize - Instituição de Pagamentos, S.A.',
    symbol: 'MLRZE.LS',
  },
  {
    value: 'RAL.PA',
    label: 'RAL.PA,  Rallye SA',
    name: 'Rallye SA',
    symbol: 'RAL.PA',
  },
  {
    value: 'RAM.LS',
    label: 'RAM.LS,  Ramada Investimentos e Industria, S.A.',
    name: 'Ramada Investimentos e Industria, S.A.',
    symbol: 'RAM.LS',
  },
  {
    value: 'GDS.PA',
    label: 'GDS.PA,  Ramsay Générale de Santé SA',
    name: 'Ramsay Générale de Santé SA',
    symbol: 'GDS.PA',
  },
  {
    value: 'RAND.AS',
    label: 'RAND.AS,  Randstad N.V.',
    name: 'Randstad N.V.',
    symbol: 'RAND.AS',
  },
  {
    value: 'REAL.BR',
    label: 'REAL.BR,  Realco SA',
    name: 'Realco SA',
    symbol: 'REAL.BR',
  },
  {
    value: 'ALREA.PA',
    label: 'ALREA.PA,  Réalités S.A.',
    name: 'Réalités S.A.',
    symbol: 'ALREA.PA',
  },
  {
    value: 'REC.BR',
    label: 'REC.BR,  Recticel SA/NV',
    name: 'Recticel SA/NV',
    symbol: 'REC.BR',
  },
  {
    value: 'RED.LS',
    label: 'RED.LS,  Reditus, Sociedade Gestora de Participações Sociais, S.A.',
    name: 'Reditus, Sociedade Gestora de Participações Sociais, S.A.',
    symbol: 'RED.LS',
  },
  {
    value: 'REINA.AS',
    label: 'REINA.AS,  Reinet Investments S.C.A.',
    name: 'Reinet Investments S.C.A.',
    symbol: 'REINA.AS',
  },
  {
    value: 'REN.AS',
    label: 'REN.AS,  RELX PLC',
    name: 'RELX PLC',
    symbol: 'REN.AS',
  },
  {
    value: 'RCO.PA',
    label: 'RCO.PA,  Rémy Cointreau SA',
    name: 'Rémy Cointreau SA',
    symbol: 'RCO.PA',
  },
  {
    value: 'RENE.LS',
    label: 'RENE.LS,  REN - Redes Energéticas Nacionais, SGPS, S.A.',
    name: 'REN - Redes Energéticas Nacionais, SGPS, S.A.',
    symbol: 'RENE.LS',
  },
  {
    value: 'RNO.PA',
    label: 'RNO.PA,  Renault SA',
    name: 'Renault SA',
    symbol: 'RNO.PA',
  },
  {
    value: 'RET.BR',
    label: 'RET.BR,  Retail Estates N.V.',
    name: 'Retail Estates N.V.',
    symbol: 'RET.BR',
  },
  {
    value: 'ALREW.PA',
    label: 'ALREW.PA,  Reworld Media Société Anonyme',
    name: 'Reworld Media Société Anonyme',
    symbol: 'ALREW.PA',
  },
  {
    value: 'RXL.PA',
    label: 'RXL.PA,  Rexel S.A.',
    name: 'Rexel S.A.',
    symbol: 'RXL.PA',
  },
  {
    value: 'RBT.PA',
    label: 'RBT.PA,  Robertet SA',
    name: 'Robertet SA',
    symbol: 'RBT.PA',
  },
  {
    value: 'CBE.PA',
    label: 'CBE.PA,  Robertet SA',
    name: 'Robertet SA',
    symbol: 'CBE.PA',
  },
  {
    value: 'RBO.PA',
    label: 'RBO.PA,  Roche Bobois S.A.',
    name: 'Roche Bobois S.A.',
    symbol: 'RBO.PA',
  },
  {
    value: 'ALROC.PA',
    label: 'ALROC.PA,  RocTool S.A.',
    name: 'RocTool S.A.',
    symbol: 'ALROC.PA',
  },
  {
    value: 'ROOD.AS',
    label: 'ROOD.AS,  RoodMicrotec N.V.',
    name: 'RoodMicrotec N.V.',
    symbol: 'ROOD.AS',
  },
  {
    value: 'ENGB.BR',
    label: 'ENGB.BR,  Rosier SA',
    name: 'Rosier SA',
    symbol: 'ENGB.BR',
  },
  {
    value: 'ROTH.PA',
    label: 'ROTH.PA,  Rothschild & Co SCA',
    name: 'Rothschild & Co SCA',
    symbol: 'ROTH.PA',
  },
  {
    value: 'ROU.BR',
    label: 'ROU.BR,  Roularta Media Group NV',
    name: 'Roularta Media Group NV',
    symbol: 'ROU.BR',
  },
  {
    value: 'RUI.PA',
    label: 'RUI.PA,  Rubis',
    name: 'Rubis',
    symbol: 'RUI.PA',
  },
  {
    value: 'SCB.LS',
    label: 'SCB.LS,  Sporting Clube de Braga - Futebol, SAD',
    name: 'Sporting Clube de Braga - Futebol, SAD',
    symbol: 'SCB.LS',
  },
  {
    value: 'SK.PA',
    label: 'SK.PA,  SEB SA',
    name: 'SEB SA',
    symbol: 'SK.PA',
  },
  {
    value: 'ALSAF.PA',
    label: 'ALSAF.PA,  Safe Orthopaedics SA',
    name: 'Safe Orthopaedics SA',
    symbol: 'ALSAF.PA',
  },
  {
    value: 'SAF.PA',
    label: 'SAF.PA,  Safran SA',
    name: 'Safran SA',
    symbol: 'SAF.PA',
  },
  {
    value: 'SGO.PA',
    label: 'SGO.PA,  Compagnie de Saint-Gobain S.A.',
    name: 'Compagnie de Saint-Gobain S.A.',
    symbol: 'SGO.PA',
  },
  {
    value: 'SABE.PA',
    label: 'SABE.PA,  Saint Jean Groupe Société anonyme',
    name: 'Saint Jean Groupe Société anonyme',
    symbol: 'SABE.PA',
  },
  {
    value: 'SAMS.PA',
    label: 'SAMS.PA,  Samse SA',
    name: 'Samse SA',
    symbol: 'SAMS.PA',
  },
  {
    value: 'SAN.PA',
    label: 'SAN.PA,  Sanofi',
    name: 'Sanofi',
    symbol: 'SAN.PA',
  },
  {
    value: 'ALMER.PA',
    label: 'ALMER.PA,  Sapmer SA',
    name: 'Sapmer SA',
    symbol: 'ALMER.PA',
  },
  {
    value: 'DIM.PA',
    label: 'DIM.PA,  Sartorius Stedim Biotech S.A.',
    name: 'Sartorius Stedim Biotech S.A.',
    symbol: 'DIM.PA',
  },
  {
    value: 'SAVE.PA',
    label: 'SAVE.PA,  Savencia SA',
    name: 'Savencia SA',
    symbol: 'SAVE.PA',
  },
  {
    value: 'MLSDN.PA',
    label: 'MLSDN.PA,  La Savonnerie De Nyons',
    name: 'La Savonnerie De Nyons',
    symbol: 'MLSDN.PA',
  },
  {
    value: 'SBMO.AS',
    label: 'SBMO.AS,  SBM Offshore N.V.',
    name: 'SBM Offshore N.V.',
    symbol: 'SBMO.AS',
  },
  {
    value: 'CBSM.PA',
    label:
      'CBSM.PA,  Société Centrale des Bois et des Scieries de la Manche S.A.',
    name: 'Société Centrale des Bois et des Scieries de la Manche S.A.',
    symbol: 'CBSM.PA',
  },
  {
    value: 'SLB.PA',
    label: 'SLB.PA,  Schlumberger Limited',
    name: 'Schlumberger Limited',
    symbol: 'SLB.PA',
  },
  {
    value: 'SU.PA',
    label: 'SU.PA,  Schneider Electric S.E.',
    name: 'Schneider Electric S.E.',
    symbol: 'SU.PA',
  },
  {
    value: 'SCR.PA',
    label: 'SCR.PA,  SCOR SE',
    name: 'SCOR SE',
    symbol: 'SCR.PA',
  },
  {
    value: 'SCHP.PA',
    label: 'SCHP.PA,  Séché Environnement SA',
    name: 'Séché Environnement SA',
    symbol: 'SCHP.PA',
  },
  {
    value: 'ALSEI.PA',
    label: 'ALSEI.PA,  Società Editoriale Il Fatto S.p.A.',
    name: 'Società Editoriale Il Fatto S.p.A.',
    symbol: 'ALSEI.PA',
  },
  {
    value: 'SELER.PA',
    label: 'SELER.PA,  Selectirente',
    name: 'Selectirente',
    symbol: 'SELER.PA',
  },
  {
    value: 'SEM.LS',
    label: 'SEM.LS,  Semapa - Sociedade de Investimento e Gestão, SGPS, S.A.',
    name: 'Semapa - Sociedade de Investimento e Gestão, SGPS, S.A.',
    symbol: 'SEM.LS',
  },
  {
    value: 'ALSEN.PA',
    label: 'ALSEN.PA,  Sensorion SA',
    name: 'Sensorion SA',
    symbol: 'ALSEN.PA',
  },
  {
    value: 'MLSEQ.PA',
    label: 'MLSEQ.PA,  Sequa Petroleum N.V.',
    name: 'Sequa Petroleum N.V.',
    symbol: 'MLSEQ.PA',
  },
  {
    value: 'SEQUA.BR',
    label: 'SEQUA.BR,  Sequana Medical NV',
    name: 'Sequana Medical NV',
    symbol: 'SEQUA.BR',
  },
  {
    value: 'SEFER.PA',
    label: 'SEFER.PA,  SergeFerrari Group SA',
    name: 'SergeFerrari Group SA',
    symbol: 'SEFER.PA',
  },
  {
    value: 'SESG.PA',
    label: 'SESG.PA,  SES S.A.',
    name: 'SES S.A.',
    symbol: 'SESG.PA',
  },
  {
    value: 'SESL.PA',
    label: 'SESL.PA,  SES-imagotag Société Anonyme',
    name: 'SES-imagotag Société Anonyme',
    symbol: 'SESL.PA',
  },
  {
    value: 'SRP.PA',
    label: 'SRP.PA,  SRP Groupe S.A.',
    name: 'SRP Groupe S.A.',
    symbol: 'SRP.PA',
  },
  {
    value: 'SHUR.BR',
    label: 'SHUR.BR,  Shurgard Self Storage S.A.',
    name: 'Shurgard Self Storage S.A.',
    symbol: 'SHUR.BR',
  },
  {
    value: 'ALBFR.PA',
    label: 'ALBFR.PA,  Sidetrade SA',
    name: 'Sidetrade SA',
    symbol: 'ALBFR.PA',
  },
  {
    value: 'SIA.BR',
    label: 'SIA.BR,  Siemens Aktiengesellschaft',
    name: 'Siemens Aktiengesellschaft',
    symbol: 'SIA.BR',
  },
  {
    value: 'SIFG.AS',
    label: 'SIFG.AS,  Sif Holding N.V.',
    name: 'Sif Holding N.V.',
    symbol: 'SIFG.AS',
  },
  {
    value: 'LIGHT.AS',
    label: 'LIGHT.AS,  Signify N.V.',
    name: 'Signify N.V.',
    symbol: 'LIGHT.AS',
  },
  {
    value: 'SII.PA',
    label: 'SII.PA,  SII S.A.',
    name: 'SII S.A.',
    symbol: 'SII.PA',
  },
  {
    value: 'SIP.BR',
    label: 'SIP.BR,  Sipef NV',
    name: 'Sipef NV',
    symbol: 'SIP.BR',
  },
  {
    value: 'SLIGR.AS',
    label: 'SLIGR.AS,  Sligro Food Group N.V.',
    name: 'Sligro Food Group N.V.',
    symbol: 'SLIGR.AS',
  },
  {
    value: 'MLSML.PA',
    label: 'MLSML.PA,  Smalto Holding SA',
    name: 'Smalto Holding SA',
    symbol: 'MLSML.PA',
  },
  {
    value: 'SMAR.BR',
    label: 'SMAR.BR,  Smartphoto Group NV',
    name: 'Smartphoto Group NV',
    symbol: 'SMAR.BR',
  },
  {
    value: 'SMCP.PA',
    label: 'SMCP.PA,  SMCP S.A.',
    name: 'SMCP S.A.',
    symbol: 'SMCP.PA',
  },
  {
    value: 'SMTPC.PA',
    label: 'SMTPC.PA,  Société Marseillaise du Tunnel Prado Carénage',
    name: 'Société Marseillaise du Tunnel Prado Carénage',
    symbol: 'SMTPC.PA',
  },
  {
    value: 'SNOW.AS',
    label: 'SNOW.AS,  SnowWorld N.V.',
    name: 'SnowWorld N.V.',
    symbol: 'SNOW.AS',
  },
  {
    value: 'SFCA.PA',
    label: 'SFCA.PA,  Société Française de Casinos Société Anonyme',
    name: 'Société Française de Casinos Société Anonyme',
    symbol: 'SFCA.PA',
  },
  {
    value: 'GLE.PA',
    label: 'GLE.PA,  Société Générale Société anonyme',
    name: 'Société Générale Société anonyme',
    symbol: 'GLE.PA',
  },
  {
    value: 'SW.PA',
    label: 'SW.PA,  Sodexo S.A.',
    name: 'Sodexo S.A.',
    symbol: 'SW.PA',
  },
  {
    value: 'SEC.PA',
    label: 'SEC.PA,  Soditech SA',
    name: 'Soditech SA',
    symbol: 'SEC.PA',
  },
  {
    value: 'SOF.BR',
    label: 'SOF.BR,  Sofina Société Anonyme',
    name: 'Sofina Société Anonyme',
    symbol: 'SOF.BR',
  },
  {
    value: 'SOFT.BR',
    label: 'SOFT.BR,  Softimat S.A.',
    name: 'Softimat S.A.',
    symbol: 'SOFT.BR',
  },
  {
    value: 'SOG.PA',
    label: 'SOG.PA,  Sogeclair SA',
    name: 'Sogeclair SA',
    symbol: 'SOG.PA',
  },
  {
    value: 'SOI.PA',
    label: 'SOI.PA,  Soitec S.A.',
    name: 'Soitec S.A.',
    symbol: 'SOI.PA',
  },
  {
    value: 'LOCAL.PA',
    label: 'LOCAL.PA,  Solocal Group S.A.',
    name: 'Solocal Group S.A.',
    symbol: 'LOCAL.PA',
  },
  {
    value: 'SOLV.BR',
    label: 'SOLV.BR,  Solvac S.A.',
    name: 'Solvac S.A.',
    symbol: 'SOLV.BR',
  },
  {
    value: 'SOLB.BR',
    label: 'SOLB.BR,  Solvay SA',
    name: 'Solvay SA',
    symbol: 'SOLB.BR',
  },
  {
    value: 'SO.PA',
    label: 'SO.PA,  Somfy SA',
    name: 'Somfy SA',
    symbol: 'SO.PA',
  },
  {
    value: 'SON.LS',
    label: 'SON.LS,  Sonae, SGPS, S.A.',
    name: 'Sonae, SGPS, S.A.',
    symbol: 'SON.LS',
  },
  {
    value: 'SNC.LS',
    label: 'SNC.LS,  Sonaecom, S.G.P.S., S.A.',
    name: 'Sonaecom, S.G.P.S., S.A.',
    symbol: 'SNC.LS',
  },
  {
    value: 'SNG.LS',
    label: 'SNG.LS,  Sonagi, S.G.P.S., S.A.',
    name: 'Sonagi, S.G.P.S., S.A.',
    symbol: 'SNG.LS',
  },
  {
    value: 'SOP.PA',
    label: 'SOP.PA,  Sopra Steria Group SA',
    name: 'Sopra Steria Group SA',
    symbol: 'SOP.PA',
  },
  {
    value: 'SPA.BR',
    label:
      "SPA.BR,  Société de Services, de Participations, de Direction et d'Elaboration Société anonyme",
    name: "Société de Services, de Participations, de Direction et d'Elaboration Société anonyme",
    symbol: 'SPA.BR',
  },
  {
    value: 'MLSRP.PA',
    label: 'MLSRP.PA,  Speed Rabbit Pizza SA',
    name: 'Speed Rabbit Pizza SA',
    symbol: 'MLSRP.PA',
  },
  {
    value: 'SPIE.PA',
    label: 'SPIE.PA,  SPIE SA',
    name: 'SPIE SA',
    symbol: 'SPIE.PA',
  },
  {
    value: 'ALSGD.PA',
    label: 'ALSGD.PA,  SpineGuard SA',
    name: 'SpineGuard SA',
    symbol: 'ALSGD.PA',
  },
  {
    value: 'ALSPW.PA',
    label: 'ALSPW.PA,  Spineway SA',
    name: 'Spineway SA',
    symbol: 'ALSPW.PA',
  },
  {
    value: 'SCP.LS',
    label: 'SCP.LS,  Sporting Clube de Portugal - Futebol, SAD',
    name: 'Sporting Clube de Portugal - Futebol, SAD',
    symbol: 'SCP.LS',
  },
  {
    value: 'SQI.PA',
    label: 'SQI.PA,  SQLI SA',
    name: 'SQLI SA',
    symbol: 'SQI.PA',
  },
  {
    value: 'DPT.PA',
    label: 'DPT.PA,  S.T. Dupont S.A.',
    name: 'S.T. Dupont S.A.',
    symbol: 'DPT.PA',
  },
  {
    value: 'STF.PA',
    label: 'STF.PA,  STEF SA',
    name: 'STEF SA',
    symbol: 'STF.PA',
  },
  {
    value: 'STM.PA',
    label: 'STM.PA,  STMicroelectronics N.V.',
    name: 'STMicroelectronics N.V.',
    symbol: 'STM.PA',
  },
  {
    value: 'ALSAS.PA',
    label: 'ALSAS.PA,  Stradim Espace Finances SA',
    name: 'Stradim Espace Finances SA',
    symbol: 'ALSAS.PA',
  },
  {
    value: 'ALSTW.PA',
    label: 'ALSTW.PA,  Streamwide S.A.',
    name: 'Streamwide S.A.',
    symbol: 'ALSTW.PA',
  },
  {
    value: 'MLSTR.PA',
    label: 'MLSTR.PA,  Streit Mecanique SA',
    name: 'Streit Mecanique SA',
    symbol: 'MLSTR.PA',
  },
  {
    value: 'MLSUM.PA',
    label: 'MLSUM.PA,  Sumo Resources Plc',
    name: 'Sumo Resources Plc',
    symbol: 'MLSUM.PA',
  },
  {
    value: 'SWP.PA',
    label: 'SWP.PA,  Sword Group S.E.',
    name: 'Sword Group S.E.',
    symbol: 'SWP.PA',
  },
  {
    value: 'SDG.PA',
    label: 'SDG.PA,  Synergie SE',
    name: 'Synergie SE',
    symbol: 'SDG.PA',
  },
  {
    value: 'TKWY.AS',
    label: 'TKWY.AS,  Just Eat Takeaway.com N.V.',
    name: 'Just Eat Takeaway.com N.V.',
    symbol: 'TKWY.AS',
  },
  {
    value: 'TKTT.PA',
    label: 'TKTT.PA,  Tarkett S.A.',
    name: 'Tarkett S.A.',
    symbol: 'TKTT.PA',
  },
  {
    value: 'TAYN.PA',
    label: 'TAYN.PA,  Societe de Tayninh SA',
    name: 'Societe de Tayninh SA',
    symbol: 'TAYN.PA',
  },
  {
    value: 'MLTEA.PA',
    label: 'MLTEA.PA,  Team Société anonyme',
    name: 'Team Société anonyme',
    symbol: 'MLTEA.PA',
  },
  {
    value: 'TDSA.LS',
    label: 'TDSA.LS,  Teixeira Duarte, S.A.',
    name: 'Teixeira Duarte, S.A.',
    symbol: 'TDSA.LS',
  },
  {
    value: 'TIT.BR',
    label: 'TIT.BR,  Telecom Italia S.p.A.',
    name: 'Telecom Italia S.p.A.',
    symbol: 'TIT.BR',
  },
  {
    value: 'TFA.BR',
    label: 'TFA.BR,  Telefónica, S.A.',
    name: 'Telefónica, S.A.',
    symbol: 'TFA.BR',
  },
  {
    value: 'TNET.BR',
    label: 'TNET.BR,  Telenet Group Holding NV',
    name: 'Telenet Group Holding NV',
    symbol: 'TNET.BR',
  },
  {
    value: 'TEP.PA',
    label: 'TEP.PA,  Teleperformance SE',
    name: 'Teleperformance SE',
    symbol: 'TEP.PA',
  },
  {
    value: 'TVRB.PA',
    label: 'TVRB.PA,  Téléverbier SA',
    name: 'Téléverbier SA',
    symbol: 'TVRB.PA',
  },
  {
    value: 'MLVST.PA',
    label: 'MLVST.PA,  Televista S.A',
    name: 'Televista S.A',
    symbol: 'MLVST.PA',
  },
  {
    value: 'TERB.BR',
    label: 'TERB.BR,  Ter Beke NV',
    name: 'Ter Beke NV',
    symbol: 'TERB.BR',
  },
  {
    value: 'TESB.BR',
    label: 'TESB.BR,  Tessenderlo Group NV',
    name: 'Tessenderlo Group NV',
    symbol: 'TESB.BR',
  },
  {
    value: 'TEXF.BR',
    label: 'TEXF.BR,  Texaf S.A.',
    name: 'Texaf S.A.',
    symbol: 'TEXF.BR',
  },
  {
    value: 'TFI.PA',
    label: 'TFI.PA,  Télévision Française 1 Société anonyme',
    name: 'Télévision Française 1 Société anonyme',
    symbol: 'TFI.PA',
  },
  {
    value: 'TFF.PA',
    label: 'TFF.PA,  TFF Group',
    name: 'TFF Group',
    symbol: 'TFF.PA',
  },
  {
    value: 'HO.PA',
    label: 'HO.PA,  Thales S.A.',
    name: 'Thales S.A.',
    symbol: 'HO.PA',
  },
  {
    value: 'ALTBG.PA',
    label: 'ALTBG.PA,  The Blockchain Group',
    name: 'The Blockchain Group',
    symbol: 'ALTBG.PA',
  },
  {
    value: 'NVG.LS',
    label: 'NVG.LS,  The Navigator Company, S.A.',
    name: 'The Navigator Company, S.A.',
    symbol: 'NVG.LS',
  },
  {
    value: 'ALTHE.PA',
    label: 'ALTHE.PA,  Theraclion SA',
    name: 'Theraclion SA',
    symbol: 'ALTHE.PA',
  },
  {
    value: 'ALTER.PA',
    label: 'ALTER.PA,  Theradiag SA',
    name: 'Theradiag SA',
    symbol: 'ALTER.PA',
  },
  {
    value: 'ALTHX.PA',
    label: 'ALTHX.PA,  Theranexus Société Anonyme',
    name: 'Theranexus Société Anonyme',
    symbol: 'ALTHX.PA',
  },
  {
    value: 'THEP.PA',
    label: 'THEP.PA,  Thermador Groupe SA',
    name: 'Thermador Groupe SA',
    symbol: 'THEP.PA',
  },
  {
    value: 'TBIRD.AS',
    label: 'TBIRD.AS,  Thunderbird Resorts, Inc.',
    name: 'Thunderbird Resorts, Inc.',
    symbol: 'TBIRD.AS',
  },
  {
    value: 'TIE.AS',
    label: 'TIE.AS,  TIE Kinetix N.V.',
    name: 'TIE Kinetix N.V.',
    symbol: 'TIE.AS',
  },
  {
    value: 'TKO.PA',
    label: 'TKO.PA,  Tikehau Capital',
    name: 'Tikehau Capital',
    symbol: 'TKO.PA',
  },
  {
    value: 'TINC.BR',
    label: 'TINC.BR,  TINC NV',
    name: 'TINC NV',
    symbol: 'TINC.BR',
  },
  {
    value: 'TIPI.PA',
    label: 'TIPI.PA,  Tipiak Société Anonyme',
    name: 'Tipiak Société Anonyme',
    symbol: 'TIPI.PA',
  },
  {
    value: 'TISN.BR',
    label: 'TISN.BR,  Tiscali Spa',
    name: 'Tiscali Spa',
    symbol: 'TISN.BR',
  },
  {
    value: 'TITC.BR',
    label: 'TITC.BR,  Titan Cement International S.A.',
    name: 'Titan Cement International S.A.',
    symbol: 'TITC.BR',
  },
  {
    value: 'TWEKA.AS',
    label: 'TWEKA.AS,  TKH Group N.V.',
    name: 'TKH Group N.V.',
    symbol: 'TWEKA.AS',
  },
  {
    value: 'TOM2.AS',
    label: 'TOM2.AS,  TomTom N.V.',
    name: 'TomTom N.V.',
    symbol: 'TOM2.AS',
  },
  {
    value: 'EC.PA',
    label: 'EC.PA,  TotalEnergies EP Gabon Société anonyme',
    name: 'TotalEnergies EP Gabon Société anonyme',
    symbol: 'EC.PA',
  },
  {
    value: 'TOUP.PA',
    label: 'TOUP.PA,  Touax SCA',
    name: 'Touax SCA',
    symbol: 'TOUP.PA',
  },
  {
    value: 'EIFF.PA',
    label: 'EIFF.PA,  Société de la Tour Eiffel',
    name: 'Société de la Tour Eiffel',
    symbol: 'EIFF.PA',
  },
  {
    value: 'SCT.LS',
    label: 'SCT.LS,  Toyota Caetano Portugal, S.A.',
    name: 'Toyota Caetano Portugal, S.A.',
    symbol: 'SCT.LS',
  },
  {
    value: 'TNG.PA',
    label: 'TNG.PA,  Transgene SA',
    name: 'Transgene SA',
    symbol: 'TNG.PA',
  },
  {
    value: 'TRI.PA',
    label: 'TRI.PA,  Trigano S.A.',
    name: 'Trigano S.A.',
    symbol: 'TRI.PA',
  },
  {
    value: 'ALTRI.PA',
    label: 'ALTRI.PA,  Trilogiq S.A.',
    name: 'Trilogiq S.A.',
    symbol: 'ALTRI.PA',
  },
  {
    value: 'MLTRO.PA',
    label: "MLTRO.PA,  TROC DE L'ILE SA",
    name: "TROC DE L'ILE SA",
    symbol: 'MLTRO.PA',
  },
  {
    value: 'ALTRO.PA',
    label: "ALTRO.PA,  Tronic's Microsystems SA",
    name: "Tronic's Microsystems SA",
    symbol: 'ALTRO.PA',
  },
  {
    value: 'ALTTI.PA',
    label: 'ALTTI.PA,  Travel Technology Interactive',
    name: 'Travel Technology Interactive',
    symbol: 'ALTTI.PA',
  },
  {
    value: 'TUB.BR',
    label: 'TUB.BR,  Financière de Tubize SA',
    name: 'Financière de Tubize SA',
    symbol: 'TUB.BR',
  },
  {
    value: 'ALTXC.PA',
    label: 'ALTXC.PA,  TXCOM Société Anonyme',
    name: 'TXCOM Société Anonyme',
    symbol: 'ALTXC.PA',
  },
  {
    value: 'UNI.BR',
    label: 'UNI.BR,  Flowsparks NV',
    name: 'Flowsparks NV',
    symbol: 'UNI.BR',
  },
  {
    value: 'ALU10.PA',
    label: 'ALU10.PA,  U10 Corp',
    name: 'U10 Corp',
    symbol: 'ALU10.PA',
  },
  {
    value: 'UBI.PA',
    label: 'UBI.PA,  Ubisoft Entertainment SA',
    name: 'Ubisoft Entertainment SA',
    symbol: 'UBI.PA',
  },
  {
    value: 'PNSB.BR',
    label: 'PNSB.BR,  Personalized Nursing Services Société Anonyme',
    name: 'Personalized Nursing Services Société Anonyme',
    symbol: 'PNSB.BR',
  },
  {
    value: 'UCB.BR',
    label: 'UCB.BR,  UCB SA',
    name: 'UCB SA',
    symbol: 'UCB.BR',
  },
  {
    value: 'MLUMG.PA',
    label: 'MLUMG.PA,  Umalis Group',
    name: 'Umalis Group',
    symbol: 'MLUMG.PA',
  },
  {
    value: 'UMI.BR',
    label: 'UMI.BR,  Umicore SA',
    name: 'Umicore SA',
    symbol: 'UMI.BR',
  },
  {
    value: 'MLUMH.PA',
    label: 'MLUMH.PA,  Union Metallurgique de la Haute-Seine S.A.',
    name: 'Union Metallurgique de la Haute-Seine S.A.',
    symbol: 'MLUMH.PA',
  },
  {
    value: 'URW.AS',
    label: 'URW.AS,  Unibail-Rodamco-Westfield SE',
    name: 'Unibail-Rodamco-Westfield SE',
    symbol: 'URW.AS',
  },
  {
    value: 'UNBL.PA',
    label: 'UNBL.PA,  Unibel S.A.',
    name: 'Unibel S.A.',
    symbol: 'UNBL.PA',
  },
  {
    value: 'UNA.AS',
    label: 'UNA.AS,  Unilever PLC',
    name: 'Unilever PLC',
    symbol: 'UNA.AS',
  },
  {
    value: 'UFF.PA',
    label: 'UFF.PA,  Union Financière de France Banque SA',
    name: 'Union Financière de France Banque SA',
    symbol: 'UFF.PA',
  },
  {
    value: 'FPG.PA',
    label: 'FPG.PA,  Union Technologies Informatique Group S.A.',
    name: 'Union Technologies Informatique Group S.A.',
    symbol: 'FPG.PA',
  },
  {
    value: 'ALUNT.PA',
    label: 'ALUNT.PA,  Uniti S.A',
    name: 'Uniti S.A',
    symbol: 'ALUNT.PA',
  },
  {
    value: 'ALUPG.PA',
    label: 'ALUPG.PA,  UPERGY Société Anonyme',
    name: 'UPERGY Société Anonyme',
    symbol: 'ALUPG.PA',
  },
  {
    value: 'ALUVI.PA',
    label: 'ALUVI.PA,  UV Germi SA',
    name: 'UV Germi SA',
    symbol: 'ALUVI.PA',
  },
  {
    value: 'VLK.AS',
    label: 'VLK.AS,  Van Lanschot Kempen NV',
    name: 'Van Lanschot Kempen NV',
    symbol: 'VLK.AS',
  },
  {
    value: 'VAF.LS',
    label: 'VAF.LS,  Vista Alegre Atlantis, SGPS, S.A.',
    name: 'Vista Alegre Atlantis, SGPS, S.A.',
    symbol: 'VAF.LS',
  },
  {
    value: 'ALVAL.PA',
    label: 'ALVAL.PA,  Valbiotis SA',
    name: 'Valbiotis SA',
    symbol: 'ALVAL.PA',
  },
  {
    value: 'FR.PA',
    label: 'FR.PA,  Valeo SE',
    name: 'Valeo SE',
    symbol: 'FR.PA',
  },
  {
    value: 'VK.PA',
    label: 'VK.PA,  Vallourec S.A.',
    name: 'Vallourec S.A.',
    symbol: 'VK.PA',
  },
  {
    value: 'VLA.PA',
    label: 'VLA.PA,  Valneva SE',
    name: 'Valneva SE',
    symbol: 'VLA.PA',
  },
  {
    value: 'VALUE.AS',
    label: 'VALUE.AS,  Value8 N.V.',
    name: 'Value8 N.V.',
    symbol: 'VALUE.AS',
  },
  {
    value: 'PREVA.AS',
    label: 'PREVA.AS,  Value8 N.V.',
    name: 'Value8 N.V.',
    symbol: 'PREVA.AS',
  },
  {
    value: 'VAN.BR',
    label: 'VAN.BR,  Van de Velde NV',
    name: 'Van de Velde NV',
    symbol: 'VAN.BR',
  },
  {
    value: 'VASTN.AS',
    label: 'VASTN.AS,  Vastned Retail N.V.',
    name: 'Vastned Retail N.V.',
    symbol: 'VASTN.AS',
  },
  {
    value: 'VASTB.BR',
    label: 'VASTB.BR,  Vastned Belgium',
    name: 'Vastned Belgium',
    symbol: 'VASTB.BR',
  },
  {
    value: 'ALVU.PA',
    label: 'ALVU.PA,  Vente-Unique.com SA',
    name: 'Vente-Unique.com SA',
    symbol: 'ALVU.PA',
  },
  {
    value: 'VIE.PA',
    label: 'VIE.PA,  Veolia Environnement S.A.',
    name: 'Veolia Environnement S.A.',
    symbol: 'VIE.PA',
  },
  {
    value: 'VEON.AS',
    label: 'VEON.AS,  VEON Ltd.',
    name: 'VEON Ltd.',
    symbol: 'VEON.AS',
  },
  {
    value: 'ALVER.PA',
    label: 'ALVER.PA,  Vergnet SA',
    name: 'Vergnet SA',
    symbol: 'ALVER.PA',
  },
  {
    value: 'VMX.PA',
    label: 'VMX.PA,  Verimatrix Société anonyme',
    name: 'Verimatrix Société anonyme',
    symbol: 'VMX.PA',
  },
  {
    value: 'MLVER.PA',
    label: 'MLVER.PA,  Verney-Carron S.A.',
    name: 'Verney-Carron S.A.',
    symbol: 'MLVER.PA',
  },
  {
    value: 'VETO.PA',
    label: 'VETO.PA,  Vetoquinol SA',
    name: 'Vetoquinol SA',
    symbol: 'VETO.PA',
  },
  {
    value: 'VGP.BR',
    label: 'VGP.BR,  VGP NV',
    name: 'VGP NV',
    symbol: 'VGP.BR',
  },
  {
    value: 'VCT.PA',
    label: 'VCT.PA,  Vicat S.A.',
    name: 'Vicat S.A.',
    symbol: 'VCT.PA',
  },
  {
    value: 'VIL.PA',
    label: 'VIL.PA,  VIEL & Cie, société anonyme',
    name: 'VIEL & Cie, société anonyme',
    symbol: 'VIL.PA',
  },
  {
    value: 'RIN.PA',
    label: 'RIN.PA,  Vilmorin & Cie SA',
    name: 'Vilmorin & Cie SA',
    symbol: 'RIN.PA',
  },
  {
    value: 'DG.PA',
    label: 'DG.PA,  Vinci SA',
    name: 'Vinci SA',
    symbol: 'DG.PA',
  },
  {
    value: 'VIO.BR',
    label: 'VIO.BR,  Viohalco S.A.',
    name: 'Viohalco S.A.',
    symbol: 'VIO.BR',
  },
  {
    value: 'VIRP.PA',
    label: 'VIRP.PA,  Virbac SA',
    name: 'Virbac SA',
    symbol: 'VIRP.PA',
  },
  {
    value: 'ALVIV.PA',
    label: 'ALVIV.PA,  Visiativ SA',
    name: 'Visiativ SA',
    symbol: 'ALVIV.PA',
  },
  {
    value: 'ALVMG.PA',
    label: 'ALVMG.PA,  Visiomed Group SA',
    name: 'Visiomed Group SA',
    symbol: 'ALVMG.PA',
  },
  {
    value: 'VIV.PA',
    label: 'VIV.PA,  Vivendi SE',
    name: 'Vivendi SE',
    symbol: 'VIV.PA',
  },
  {
    value: 'ALVGO.PA',
    label: 'ALVGO.PA,  Vogo SA',
    name: 'Vogo SA',
    symbol: 'ALVGO.PA',
  },
  {
    value: 'VWA.BR',
    label: 'VWA.BR,  Volkswagen AG',
    name: 'Volkswagen AG',
    symbol: 'VWA.BR',
  },
  {
    value: 'VWAP.BR',
    label: 'VWAP.BR,  Volkswagen AG',
    name: 'Volkswagen AG',
    symbol: 'VWAP.BR',
  },
  {
    value: 'VLTSA.PA',
    label: 'VLTSA.PA,  Voltalia SA',
    name: 'Voltalia SA',
    symbol: 'VLTSA.PA',
  },
  {
    value: 'VPK.AS',
    label: 'VPK.AS,  Koninklijke Vopak N.V.',
    name: 'Koninklijke Vopak N.V.',
    symbol: 'VPK.AS',
  },
  {
    value: 'ALVDM.PA',
    label: 'ALVDM.PA,  Voyageurs du Monde SA',
    name: 'Voyageurs du Monde SA',
    symbol: 'ALVDM.PA',
  },
  {
    value: 'VRAP.PA',
    label: 'VRAP.PA,  Vranken-Pommery Monopole Société Anonyme',
    name: 'Vranken-Pommery Monopole Société Anonyme',
    symbol: 'VRAP.PA',
  },
  {
    value: 'ALLIX.PA',
    label: 'ALLIX.PA,  WALLIX GROUP SA',
    name: 'WALLIX GROUP SA',
    symbol: 'ALLIX.PA',
  },
  {
    value: 'WEB.BR',
    label: 'WEB.BR,  Warehouses Estates Belgium SCA',
    name: 'Warehouses Estates Belgium SCA',
    symbol: 'WEB.BR',
  },
  {
    value: 'WAVE.PA',
    label: 'WAVE.PA,  Wavestone SA',
    name: 'Wavestone SA',
    symbol: 'WAVE.PA',
  },
  {
    value: 'WDP.BR',
    label: 'WDP.BR,  Warehouses De Pauw NV',
    name: 'Warehouses De Pauw NV',
    symbol: 'WDP.BR',
  },
  {
    value: 'ALWEC.PA',
    label: 'ALWEC.PA,  We.Connect SA',
    name: 'We.Connect SA',
    symbol: 'ALWEC.PA',
  },
  {
    value: 'MLWEA.PA',
    label: 'MLWEA.PA,  Weaccess Group, Société Anonyme',
    name: 'Weaccess Group, Société Anonyme',
    symbol: 'MLWEA.PA',
  },
  {
    value: 'ALWED.PA',
    label: 'ALWED.PA,  Wedia SA',
    name: 'Wedia SA',
    symbol: 'ALWED.PA',
  },
  {
    value: 'MF.PA',
    label: 'MF.PA,  Wendel',
    name: 'Wendel',
    symbol: 'MF.PA',
  },
  {
    value: 'WHA.AS',
    label: 'WHA.AS,  Wereldhave N.V.',
    name: 'Wereldhave N.V.',
    symbol: 'WHA.AS',
  },
  {
    value: 'WEHB.BR',
    label: 'WEHB.BR,  Wereldhave Belgium',
    name: 'Wereldhave Belgium',
    symbol: 'WEHB.BR',
  },
  {
    value: 'MLWEY.PA',
    label: 'MLWEY.PA,  Weya SA',
    name: 'Weya SA',
    symbol: 'MLWEY.PA',
  },
  {
    value: 'ALWIT.PA',
    label: 'ALWIT.PA,  Witbe S.A.',
    name: 'Witbe S.A.',
    symbol: 'ALWIT.PA',
  },
  {
    value: 'WKL.AS',
    label: 'WKL.AS,  Wolters Kluwer N.V.',
    name: 'Wolters Kluwer N.V.',
    symbol: 'WKL.AS',
  },
  {
    value: 'WLN.PA',
    label: 'WLN.PA,  Worldline SA',
    name: 'Worldline SA',
    symbol: 'WLN.PA',
  },
  {
    value: 'XFAB.PA',
    label: 'XFAB.PA,  X-FAB Silicon Foundries SE',
    name: 'X-FAB Silicon Foundries SE',
    symbol: 'XFAB.PA',
  },
  {
    value: 'XIL.PA',
    label: 'XIL.PA,  Xilam Animation',
    name: 'Xilam Animation',
    symbol: 'XIL.PA',
  },
  {
    value: 'XIOR.BR',
    label: 'XIOR.BR,  Xior Student Housing NV',
    name: 'Xior Student Housing NV',
    symbol: 'XIOR.BR',
  },
  {
    value: 'MLZAM.PA',
    label: 'MLZAM.PA,  ZCCM Investments Holdings Plc',
    name: 'ZCCM Investments Holdings Plc',
    symbol: 'MLZAM.PA',
  },
  {
    value: 'ZEN.BR',
    label: 'ZEN.BR,  Immo-Zenobe Gramme SA',
    name: 'Immo-Zenobe Gramme SA',
    symbol: 'ZEN.BR',
  },
  {
    value: 'VNP.TO',
    label: 'VNP.TO,  5N Plus Inc.',
    name: '5N Plus Inc.',
    symbol: 'VNP.TO',
  },
  {
    value: 'AW-UN.TO',
    label: 'AW-UN.TO,  A&W Revenue Royalties Income Fund',
    name: 'A&W Revenue Royalties Income Fund',
    symbol: 'AW-UN.TO',
  },
  {
    value: 'FAP.TO',
    label: 'FAP.TO,  abrdn Asia-Pacific Income Fund VCC',
    name: 'abrdn Asia-Pacific Income Fund VCC',
    symbol: 'FAP.TO',
  },
  {
    value: 'AAB.TO',
    label: 'AAB.TO,  Aberdeen International Inc.',
    name: 'Aberdeen International Inc.',
    symbol: 'AAB.TO',
  },
  {
    value: 'ADN.TO',
    label: 'ADN.TO,  Acadian Timber Corp.',
    name: 'Acadian Timber Corp.',
    symbol: 'ADN.TO',
  },
  {
    value: 'ACD.TO',
    label: 'ACD.TO,  Accord Financial Corp.',
    name: 'Accord Financial Corp.',
    symbol: 'ACD.TO',
  },
  {
    value: 'ASP.TO',
    label: 'ASP.TO,  Acerus Pharmaceuticals Corporation',
    name: 'Acerus Pharmaceuticals Corporation',
    symbol: 'ASP.TO',
  },
  {
    value: 'DRX.TO',
    label: 'DRX.TO,  ADF Group Inc.',
    name: 'ADF Group Inc.',
    symbol: 'DRX.TO',
  },
  {
    value: 'AAV.TO',
    label: 'AAV.TO,  Advantage Energy Ltd.',
    name: 'Advantage Energy Ltd.',
    symbol: 'AAV.TO',
  },
  {
    value: 'ARE.TO',
    label: 'ARE.TO,  Aecon Group Inc.',
    name: 'Aecon Group Inc.',
    symbol: 'ARE.TO',
  },
  {
    value: 'AOI.TO',
    label: 'AOI.TO,  Africa Oil Corp.',
    name: 'Africa Oil Corp.',
    symbol: 'AOI.TO',
  },
  {
    value: 'AFN.TO',
    label: 'AFN.TO,  Ag Growth International Inc.',
    name: 'Ag Growth International Inc.',
    symbol: 'AFN.TO',
  },
  {
    value: 'AGF-B.TO',
    label: 'AGF-B.TO,  AGF Management Limited',
    name: 'AGF Management Limited',
    symbol: 'AGF-B.TO',
  },
  {
    value: 'AEM.TO',
    label: 'AEM.TO,  Agnico Eagle Mines Limited',
    name: 'Agnico Eagle Mines Limited',
    symbol: 'AEM.TO',
  },
  {
    value: 'AIM.TO',
    label: 'AIM.TO,  Aimia Inc.',
    name: 'Aimia Inc.',
    symbol: 'AIM.TO',
  },
  {
    value: 'AC.TO',
    label: 'AC.TO,  Air Canada',
    name: 'Air Canada',
    symbol: 'AC.TO',
  },
  {
    value: 'BOS.TO',
    label: 'BOS.TO,  AirBoss of America Corp.',
    name: 'AirBoss of America Corp.',
    symbol: 'BOS.TO',
  },
  {
    value: 'AKT-A.TO',
    label: 'AKT-A.TO,  AKITA Drilling Ltd.',
    name: 'AKITA Drilling Ltd.',
    symbol: 'AKT-A.TO',
  },
  {
    value: 'AGI.TO',
    label: 'AGI.TO,  Alamos Gold Inc.',
    name: 'Alamos Gold Inc.',
    symbol: 'AGI.TO',
  },
  {
    value: 'ALC.TO',
    label: 'ALC.TO,  Algoma Central Corporation',
    name: 'Algoma Central Corporation',
    symbol: 'ALC.TO',
  },
  {
    value: 'AQN.TO',
    label: 'AQN.TO,  Algonquin Power & Utilities Corp.',
    name: 'Algonquin Power & Utilities Corp.',
    symbol: 'AQN.TO',
  },
  {
    value: 'AP-UN.TO',
    label: 'AP-UN.TO,  Allied Properties Real Estate Investment Trust',
    name: 'Allied Properties Real Estate Investment Trust',
    symbol: 'AP-UN.TO',
  },
  {
    value: 'AMM.TO',
    label: 'AMM.TO,  Almaden Minerals Ltd.',
    name: 'Almaden Minerals Ltd.',
    symbol: 'AMM.TO',
  },
  {
    value: 'ALA.TO',
    label: 'ALA.TO,  AltaGas Ltd.',
    name: 'AltaGas Ltd.',
    symbol: 'ALA.TO',
  },
  {
    value: 'ALS.TO',
    label: 'ALS.TO,  Altius Minerals Corporation',
    name: 'Altius Minerals Corporation',
    symbol: 'ALS.TO',
  },
  {
    value: 'AIF.TO',
    label: 'AIF.TO,  Altus Group Limited',
    name: 'Altus Group Limited',
    symbol: 'AIF.TO',
  },
  {
    value: 'HOT-UN.TO',
    label: 'HOT-UN.TO,  American Hotel Income Properties REIT LP',
    name: 'American Hotel Income Properties REIT LP',
    symbol: 'HOT-UN.TO',
  },
  {
    value: 'USA.TO',
    label: 'USA.TO,  Americas Gold and Silver Corporation',
    name: 'Americas Gold and Silver Corporation',
    symbol: 'USA.TO',
  },
  {
    value: 'ARG.TO',
    label: 'ARG.TO,  Amerigo Resources Ltd.',
    name: 'Amerigo Resources Ltd.',
    symbol: 'ARG.TO',
  },
  {
    value: 'ADW-A.TO',
    label: 'ADW-A.TO,  Andrew Peller Limited',
    name: 'Andrew Peller Limited',
    symbol: 'ADW-A.TO',
  },
  {
    value: 'APS.TO',
    label: 'APS.TO,  Aptose Biosciences Inc.',
    name: 'Aptose Biosciences Inc.',
    symbol: 'APS.TO',
  },
  {
    value: 'ARX.TO',
    label: 'ARX.TO,  ARC Resources Ltd.',
    name: 'ARC Resources Ltd.',
    symbol: 'ARX.TO',
  },
  {
    value: 'AR.TO',
    label: 'AR.TO,  Argonaut Gold Inc.',
    name: 'Argonaut Gold Inc.',
    symbol: 'AR.TO',
  },
  {
    value: 'ATZ.TO',
    label: 'ATZ.TO,  Aritzia Inc.',
    name: 'Aritzia Inc.',
    symbol: 'ATZ.TO',
  },
  {
    value: 'AX-UN.TO',
    label: 'AX-UN.TO,  Artis Real Estate Investment Trust',
    name: 'Artis Real Estate Investment Trust',
    symbol: 'AX-UN.TO',
  },
  {
    value: 'AYM.TO',
    label: 'AYM.TO,  Atalaya Mining Plc',
    name: 'Atalaya Mining Plc',
    symbol: 'AYM.TO',
  },
  {
    value: 'ACO-X.TO',
    label: 'ACO-X.TO,  ATCO Ltd.',
    name: 'ATCO Ltd.',
    symbol: 'ACO-X.TO',
  },
  {
    value: 'ATH.TO',
    label: 'ATH.TO,  Athabasca Oil Corporation',
    name: 'Athabasca Oil Corporation',
    symbol: 'ATH.TO',
  },
  {
    value: 'AI.TO',
    label: 'AI.TO,  Atrium Mortgage Investment Corporation',
    name: 'Atrium Mortgage Investment Corporation',
    symbol: 'AI.TO',
  },
  {
    value: 'ATA.TO',
    label: 'ATA.TO,  ATS Automation Tooling Systems Inc.',
    name: 'ATS Automation Tooling Systems Inc.',
    symbol: 'ATA.TO',
  },
  {
    value: 'ORA.TO',
    label: 'ORA.TO,  Aura Minerals Inc.',
    name: 'Aura Minerals Inc.',
    symbol: 'ORA.TO',
  },
  {
    value: 'HRR-UN.TO',
    label: 'HRR-UN.TO,  Australian REIT Income Fund',
    name: 'Australian REIT Income Fund',
    symbol: 'HRR-UN.TO',
  },
  {
    value: 'ACQ.TO',
    label: 'ACQ.TO,  AutoCanada Inc.',
    name: 'AutoCanada Inc.',
    symbol: 'ACQ.TO',
  },
  {
    value: 'APR-UN.TO',
    label: 'APR-UN.TO,  Automotive Properties Real Estate Investment Trust',
    name: 'Automotive Properties Real Estate Investment Trust',
    symbol: 'APR-UN.TO',
  },
  {
    value: 'AVL.TO',
    label: 'AVL.TO,  Avalon Advanced Materials Inc.',
    name: 'Avalon Advanced Materials Inc.',
    symbol: 'AVL.TO',
  },
  {
    value: 'AVP.TO',
    label: 'AVP.TO,  Avcorp Industries Inc.',
    name: 'Avcorp Industries Inc.',
    symbol: 'AVP.TO',
  },
  {
    value: 'BTO.TO',
    label: 'BTO.TO,  B2Gold Corp.',
    name: 'B2Gold Corp.',
    symbol: 'BTO.TO',
  },
  {
    value: 'BMO.TO',
    label: 'BMO.TO,  Bank of Montreal',
    name: 'Bank of Montreal',
    symbol: 'BMO.TO',
  },
  {
    value: 'BNS.TO',
    label: 'BNS.TO,  The Bank of Nova Scotia',
    name: 'The Bank of Nova Scotia',
    symbol: 'BNS.TO',
  },
  {
    value: 'ABX.TO',
    label: 'ABX.TO,  Barrick Gold Corporation',
    name: 'Barrick Gold Corporation',
    symbol: 'ABX.TO',
  },
  {
    value: 'BYL.TO',
    label: 'BYL.TO,  Baylin Technologies Inc.',
    name: 'Baylin Technologies Inc.',
    symbol: 'BYL.TO',
  },
  {
    value: 'BTE.TO',
    label: 'BTE.TO,  Baytex Energy Corp.',
    name: 'Baytex Energy Corp.',
    symbol: 'BTE.TO',
  },
  {
    value: 'BCE.TO',
    label: 'BCE.TO,  BCE Inc.',
    name: 'BCE Inc.',
    symbol: 'BCE.TO',
  },
  {
    value: 'BEK-B.TO',
    label: 'BEK-B.TO,  The Becker Milk Company Limited',
    name: 'The Becker Milk Company Limited',
    symbol: 'BEK-B.TO',
  },
  {
    value: 'BLU.TO',
    label: 'BLU.TO,  BELLUS Health Inc.',
    name: 'BELLUS Health Inc.',
    symbol: 'BLU.TO',
  },
  {
    value: 'BSX.TO',
    label: 'BSX.TO,  Belo Sun Mining Corp',
    name: 'Belo Sun Mining Corp',
    symbol: 'BSX.TO',
  },
  {
    value: 'BNG.TO',
    label: 'BNG.TO,  Bengal Energy Ltd.',
    name: 'Bengal Energy Ltd.',
    symbol: 'BNG.TO',
  },
  {
    value: 'BR.TO',
    label: 'BR.TO,  Big Rock Brewery Inc.',
    name: 'Big Rock Brewery Inc.',
    symbol: 'BR.TO',
  },
  {
    value: 'BIR.TO',
    label: 'BIR.TO,  Birchcliff Energy Ltd.',
    name: 'Birchcliff Energy Ltd.',
    symbol: 'BIR.TO',
  },
  {
    value: 'BDT.TO',
    label: 'BDT.TO,  Bird Construction Inc.',
    name: 'Bird Construction Inc.',
    symbol: 'BDT.TO',
  },
  {
    value: 'BDI.TO',
    label: 'BDI.TO,  Black Diamond Group Limited',
    name: 'Black Diamond Group Limited',
    symbol: 'BDI.TO',
  },
  {
    value: 'BKI.TO',
    label: 'BKI.TO,  Black Iron Inc.',
    name: 'Black Iron Inc.',
    symbol: 'BKI.TO',
  },
  {
    value: 'BB.TO',
    label: 'BB.TO,  BlackBerry Limited',
    name: 'BlackBerry Limited',
    symbol: 'BB.TO',
  },
  {
    value: 'BLB-UN.TO',
    label: 'BLB-UN.TO,  Bloom Select Income Fund',
    name: 'Bloom Select Income Fund',
    symbol: 'BLB-UN.TO',
  },
  {
    value: 'RBN-UN.TO',
    label: 'RBN-UN.TO,  Blue Ribbon Income Fund',
    name: 'Blue Ribbon Income Fund',
    symbol: 'RBN-UN.TO',
  },
  {
    value: 'ZAG.TO',
    label: 'ZAG.TO,  BMO Aggregate Bond Index ETF',
    name: 'BMO Aggregate Bond Index ETF',
    symbol: 'ZAG.TO',
  },
  {
    value: 'ZDV.TO',
    label: 'ZDV.TO,  BMO Canadian Dividend ETF',
    name: 'BMO Canadian Dividend ETF',
    symbol: 'ZDV.TO',
  },
  {
    value: 'ZCH.TO',
    label: 'ZCH.TO,  BMO MSCI China ESG Leaders Index ETF',
    name: 'BMO MSCI China ESG Leaders Index ETF',
    symbol: 'ZCH.TO',
  },
  {
    value: 'ZWB.TO',
    label: 'ZWB.TO,  BMO Covered Call Canadian Banks ETF',
    name: 'BMO Covered Call Canadian Banks ETF',
    symbol: 'ZWB.TO',
  },
  {
    value: 'ZWA.TO',
    label:
      'ZWA.TO,  BMO Covered Call Dow Jones Industrial Average Hedged to CAD ETF',
    name: 'BMO Covered Call Dow Jones Industrial Average Hedged to CAD ETF',
    symbol: 'ZWA.TO',
  },
  {
    value: 'ZWU.TO',
    label: 'ZWU.TO,  BMO Covered Call Utilities ETF',
    name: 'BMO Covered Call Utilities ETF',
    symbol: 'ZWU.TO',
  },
  {
    value: 'ZDB.TO',
    label: 'ZDB.TO,  BMO Discount Bond Index ETF',
    name: 'BMO Discount Bond Index ETF',
    symbol: 'ZDB.TO',
  },
  {
    value: 'ZDJ.TO',
    label: 'ZDJ.TO,  BMO Dow Jones Industrial Average Hedged to CAD Index ETF',
    name: 'BMO Dow Jones Industrial Average Hedged to CAD Index ETF',
    symbol: 'ZDJ.TO',
  },
  {
    value: 'ZEF.TO',
    label: 'ZEF.TO,  BMO Emerging Markets Bond Hedged to CAD Index ETF',
    name: 'BMO Emerging Markets Bond Hedged to CAD Index ETF',
    symbol: 'ZEF.TO',
  },
  {
    value: 'ZRE.TO',
    label: 'ZRE.TO,  BMO Equal Weight REITs Index ETF',
    name: 'BMO Equal Weight REITs Index ETF',
    symbol: 'ZRE.TO',
  },
  {
    value: 'ZUB.TO',
    label: 'ZUB.TO,  BMO Equal Weight US Banks Hedged to CAD Index ETF',
    name: 'BMO Equal Weight US Banks Hedged to CAD Index ETF',
    symbol: 'ZUB.TO',
  },
  {
    value: 'ZBK.TO',
    label: 'ZBK.TO,  BMO Equal Weight US Banks Index ETF',
    name: 'BMO Equal Weight US Banks Index ETF',
    symbol: 'ZBK.TO',
  },
  {
    value: 'ZUH.TO',
    label: 'ZUH.TO,  BMO Equal Weight US Health Care Hedged to CAD Index ETF',
    name: 'BMO Equal Weight US Health Care Hedged to CAD Index ETF',
    symbol: 'ZUH.TO',
  },
  {
    value: 'ZUT.TO',
    label: 'ZUT.TO,  BMO Equal Weight Utilities Index ETF',
    name: 'BMO Equal Weight Utilities Index ETF',
    symbol: 'ZUT.TO',
  },
  {
    value: 'ZWE.TO',
    label: 'ZWE.TO,  BMO Europe High Dividend Covered Call Hedged to CAD ETF',
    name: 'BMO Europe High Dividend Covered Call Hedged to CAD ETF',
    symbol: 'ZWE.TO',
  },
  {
    value: 'ZFH.TO',
    label: 'ZFH.TO,  BMO Floating Rate High Yield ETF',
    name: 'BMO Floating Rate High Yield ETF',
    symbol: 'ZFH.TO',
  },
  {
    value: 'ZGI.TO',
    label: 'ZGI.TO,  BMO Global Infrastructure Index ETF',
    name: 'BMO Global Infrastructure Index ETF',
    symbol: 'ZGI.TO',
  },
  {
    value: 'ZHY.TO',
    label: 'ZHY.TO,  BMO High Yield US Corporate Bond Hedged to CAD Index ETF',
    name: 'BMO High Yield US Corporate Bond Hedged to CAD Index ETF',
    symbol: 'ZHY.TO',
  },
  {
    value: 'ZID.TO',
    label: 'ZID.TO,  BMO MSCI India ESG Leaders Index ETF',
    name: 'BMO MSCI India ESG Leaders Index ETF',
    symbol: 'ZID.TO',
  },
  {
    value: 'ZDI.TO',
    label: 'ZDI.TO,  BMO International Dividend ETF',
    name: 'BMO International Dividend ETF',
    symbol: 'ZDI.TO',
  },
  {
    value: 'ZDH.TO',
    label: 'ZDH.TO,  BMO International Dividend Hedged to CAD ETF',
    name: 'BMO International Dividend Hedged to CAD ETF',
    symbol: 'ZDH.TO',
  },
  {
    value: 'ZJG.TO',
    label: 'ZJG.TO,  BMO Junior Gold Index ETF',
    name: 'BMO Junior Gold Index ETF',
    symbol: 'ZJG.TO',
  },
  {
    value: 'ZPR.TO',
    label: 'ZPR.TO,  BMO Laddered Preferred Share Index ETF',
    name: 'BMO Laddered Preferred Share Index ETF',
    symbol: 'ZPR.TO',
  },
  {
    value: 'ZLC.TO',
    label: 'ZLC.TO,  BMO Long Corporate Bond Index ETF',
    name: 'BMO Long Corporate Bond Index ETF',
    symbol: 'ZLC.TO',
  },
  {
    value: 'ZFL.TO',
    label: 'ZFL.TO,  BMO Long Federal Bond Index ETF',
    name: 'BMO Long Federal Bond Index ETF',
    symbol: 'ZFL.TO',
  },
  {
    value: 'ZPL.TO',
    label: 'ZPL.TO,  BMO Long Provincial Bond Index ETF',
    name: 'BMO Long Provincial Bond Index ETF',
    symbol: 'ZPL.TO',
  },
  {
    value: 'ZLB.TO',
    label: 'ZLB.TO,  BMO Low Volatility Canadian Equity ETF',
    name: 'BMO Low Volatility Canadian Equity ETF',
    symbol: 'ZLB.TO',
  },
  {
    value: 'ZLE.TO',
    label: 'ZLE.TO,  BMO Low Volatility Emerging Markets Equity ETF',
    name: 'BMO Low Volatility Emerging Markets Equity ETF',
    symbol: 'ZLE.TO',
  },
  {
    value: 'ZLI.TO',
    label: 'ZLI.TO,  BMO Low Volatility International Equity ETF',
    name: 'BMO Low Volatility International Equity ETF',
    symbol: 'ZLI.TO',
  },
  {
    value: 'ZLD.TO',
    label: 'ZLD.TO,  BMO Low Volatility International Equity Hedged to CAD ETF',
    name: 'BMO Low Volatility International Equity Hedged to CAD ETF',
    symbol: 'ZLD.TO',
  },
  {
    value: 'ZLU.TO',
    label: 'ZLU.TO,  BMO Low Volatility US Equity ETF',
    name: 'BMO Low Volatility US Equity ETF',
    symbol: 'ZLU.TO',
  },
  {
    value: 'ZLH.TO',
    label: 'ZLH.TO,  BMO Low Volatility US Equity Hedged to CAD ETF',
    name: 'BMO Low Volatility US Equity Hedged to CAD ETF',
    symbol: 'ZLH.TO',
  },
  {
    value: 'ZCM.TO',
    label: 'ZCM.TO,  BMO Mid Corporate Bond Index ETF',
    name: 'BMO Mid Corporate Bond Index ETF',
    symbol: 'ZCM.TO',
  },
  {
    value: 'ZFM.TO',
    label: 'ZFM.TO,  BMO Mid Federal Bond Index ETF',
    name: 'BMO Mid Federal Bond Index ETF',
    symbol: 'ZFM.TO',
  },
  {
    value: 'ZMP.TO',
    label: 'ZMP.TO,  BMO Mid Provincial Bond Index ETF',
    name: 'BMO Mid Provincial Bond Index ETF',
    symbol: 'ZMP.TO',
  },
  {
    value: 'ZMU.TO',
    label: 'ZMU.TO,  BMO Mid-Term US IG Corporate Bond Hedged to CAD Index ETF',
    name: 'BMO Mid-Term US IG Corporate Bond Hedged to CAD Index ETF',
    symbol: 'ZMU.TO',
  },
  {
    value: 'ZIC.TO',
    label: 'ZIC.TO,  BMO Mid-Term US IG Corporate Bond Index ETF',
    name: 'BMO Mid-Term US IG Corporate Bond Index ETF',
    symbol: 'ZIC.TO',
  },
  {
    value: 'ZMI.TO',
    label: 'ZMI.TO,  BMO Monthly Income ETF',
    name: 'BMO Monthly Income ETF',
    symbol: 'ZMI.TO',
  },
  {
    value: 'ZGQ.TO',
    label: 'ZGQ.TO,  BMO MSCI All Country World High Quality Index ETF',
    name: 'BMO MSCI All Country World High Quality Index ETF',
    symbol: 'ZGQ.TO',
  },
  {
    value: 'ZDM.TO',
    label: 'ZDM.TO,  BMO MSCI EAFE Hedged to CAD Index ETF',
    name: 'BMO MSCI EAFE Hedged to CAD Index ETF',
    symbol: 'ZDM.TO',
  },
  {
    value: 'ZEA.TO',
    label: 'ZEA.TO,  BMO MSCI EAFE Index ETF',
    name: 'BMO MSCI EAFE Index ETF',
    symbol: 'ZEA.TO',
  },
  {
    value: 'ZEM.TO',
    label: 'ZEM.TO,  BMO MSCI Emerging Markets Index ETF',
    name: 'BMO MSCI Emerging Markets Index ETF',
    symbol: 'ZEM.TO',
  },
  {
    value: 'ZEQ.TO',
    label: 'ZEQ.TO,  BMO MSCI Europe High Quality Hedged to CAD Index ETF',
    name: 'BMO MSCI Europe High Quality Hedged to CAD Index ETF',
    symbol: 'ZEQ.TO',
  },
  {
    value: 'ZUQ.TO',
    label: 'ZUQ.TO,  BMO MSCI USA High Quality Index ETF',
    name: 'BMO MSCI USA High Quality Index ETF',
    symbol: 'ZUQ.TO',
  },
  {
    value: 'ZQQ.TO',
    label: 'ZQQ.TO,  BMO Nasdaq 100 Equity Hedged to CAD Index ETF',
    name: 'BMO Nasdaq 100 Equity Hedged to CAD Index ETF',
    symbol: 'ZQQ.TO',
  },
  {
    value: 'ZRR.TO',
    label: 'ZRR.TO,  BMO Real Return Bond Index ETF',
    name: 'BMO Real Return Bond Index ETF',
    symbol: 'ZRR.TO',
  },
  {
    value: 'ZUE.TO',
    label: 'ZUE.TO,  BMO S&P 500 Hedged to CAD Index ETF',
    name: 'BMO S&P 500 Hedged to CAD Index ETF',
    symbol: 'ZUE.TO',
  },
  {
    value: 'ZSP.TO',
    label: 'ZSP.TO,  BMO S&P 500 Index ETF (USD)',
    name: 'BMO S&P 500 Index ETF (USD)',
    symbol: 'ZSP.TO',
  },
  {
    value: 'ZCN.TO',
    label: 'ZCN.TO,  BMO S&P/TSX Capped Composite Index ETF',
    name: 'BMO S&P/TSX Capped Composite Index ETF',
    symbol: 'ZCN.TO',
  },
  {
    value: 'ZEB.TO',
    label: 'ZEB.TO,  BMO Equal Weight Banks Index ETF',
    name: 'BMO Equal Weight Banks Index ETF',
    symbol: 'ZEB.TO',
  },
  {
    value: 'ZMT.TO',
    label:
      'ZMT.TO,  BMO Equal Weight Global Base Metals Hedged to CAD Index ETF',
    name: 'BMO Equal Weight Global Base Metals Hedged to CAD Index ETF',
    symbol: 'ZMT.TO',
  },
  {
    value: 'ZGD.TO',
    label: 'ZGD.TO,  BMO Equal Weight Global Gold Index ETF',
    name: 'BMO Equal Weight Global Gold Index ETF',
    symbol: 'ZGD.TO',
  },
  {
    value: 'ZIN.TO',
    label: 'ZIN.TO,  BMO Equal Weight Industrials Index ETF',
    name: 'BMO Equal Weight Industrials Index ETF',
    symbol: 'ZIN.TO',
  },
  {
    value: 'ZEO.TO',
    label: 'ZEO.TO,  BMO Equal Weight Oil & Gas Index ETF',
    name: 'BMO Equal Weight Oil & Gas Index ETF',
    symbol: 'ZEO.TO',
  },
  {
    value: 'ZCS.TO',
    label: 'ZCS.TO,  BMO Short Corporate Bond Index ETF',
    name: 'BMO Short Corporate Bond Index ETF',
    symbol: 'ZCS.TO',
  },
  {
    value: 'ZFS.TO',
    label: 'ZFS.TO,  BMO Short Federal Bond Index ETF',
    name: 'BMO Short Federal Bond Index ETF',
    symbol: 'ZFS.TO',
  },
  {
    value: 'ZPS.TO',
    label: 'ZPS.TO,  BMO Short Provincial Bond Index ETF',
    name: 'BMO Short Provincial Bond Index ETF',
    symbol: 'ZPS.TO',
  },
  {
    value: 'ZSU.TO',
    label:
      'ZSU.TO,  BMO Short-Term US IG Corporate Bond Hedged to CAD Index ETF',
    name: 'BMO Short-Term US IG Corporate Bond Hedged to CAD Index ETF',
    symbol: 'ZSU.TO',
  },
  {
    value: 'ZST.TO',
    label: 'ZST.TO,  BMO Ultra Short-Term Bond ETF',
    name: 'BMO Ultra Short-Term Bond ETF',
    symbol: 'ZST.TO',
  },
  {
    value: 'ZDY.TO',
    label: 'ZDY.TO,  BMO US Dividend ETF',
    name: 'BMO US Dividend ETF',
    symbol: 'ZDY.TO',
  },
  {
    value: 'ZUD.TO',
    label: 'ZUD.TO,  BMO US Dividend Hedged to CAD ETF',
    name: 'BMO US Dividend Hedged to CAD ETF',
    symbol: 'ZUD.TO',
  },
  {
    value: 'ZWH.TO',
    label: 'ZWH.TO,  BMO US High Dividend Covered Call ETF',
    name: 'BMO US High Dividend Covered Call ETF',
    symbol: 'ZWH.TO',
  },
  {
    value: 'ZPW.TO',
    label: 'ZPW.TO,  BMO US Put Write ETF',
    name: 'BMO US Put Write ETF',
    symbol: 'ZPW.TO',
  },
  {
    value: 'GBT.TO',
    label: 'GBT.TO,  BMTC Group Inc.',
    name: 'BMTC Group Inc.',
    symbol: 'GBT.TO',
  },
  {
    value: 'BEI-UN.TO',
    label: 'BEI-UN.TO,  Boardwalk Real Estate Investment Trust',
    name: 'Boardwalk Real Estate Investment Trust',
    symbol: 'BEI-UN.TO',
  },
  {
    value: 'BBD-B.TO',
    label: 'BBD-B.TO,  Bombardier Inc.',
    name: 'Bombardier Inc.',
    symbol: 'BBD-B.TO',
  },
  {
    value: 'BNE.TO',
    label: 'BNE.TO,  Bonterra Energy Corp.',
    name: 'Bonterra Energy Corp.',
    symbol: 'BNE.TO',
  },
  {
    value: 'BLX.TO',
    label: 'BLX.TO,  Boralex Inc.',
    name: 'Boralex Inc.',
    symbol: 'BLX.TO',
  },
  {
    value: 'BPF-UN.TO',
    label: 'BPF-UN.TO,  Boston Pizza Royalties Income Fund',
    name: 'Boston Pizza Royalties Income Fund',
    symbol: 'BPF-UN.TO',
  },
  {
    value: 'HBF.TO',
    label: 'HBF.TO,  Harvest Brand Leaders Plus Income ETF',
    name: 'Harvest Brand Leaders Plus Income ETF',
    symbol: 'HBF.TO',
  },
  {
    value: 'BRY.TO',
    label: 'BRY.TO,  Bri-Chem Corp.',
    name: 'Bri-Chem Corp.',
    symbol: 'BRY.TO',
  },
  {
    value: 'LCS.TO',
    label: 'LCS.TO,  Brompton Lifeco Split Corp.',
    name: 'Brompton Lifeco Split Corp.',
    symbol: 'LCS.TO',
  },
  {
    value: 'OSP.TO',
    label: 'OSP.TO,  Brompton Oil Split Corp.',
    name: 'Brompton Oil Split Corp.',
    symbol: 'OSP.TO',
  },
  {
    value: 'SBC.TO',
    label: 'SBC.TO,  Brompton Split Banc Corp.',
    name: 'Brompton Split Banc Corp.',
    symbol: 'SBC.TO',
  },
  {
    value: 'BAM-A.TO',
    label: 'BAM-A.TO,  Brookfield Asset Management Inc.',
    name: 'Brookfield Asset Management Inc.',
    symbol: 'BAM-A.TO',
  },
  {
    value: 'BBU-UN.TO',
    label: 'BBU-UN.TO,  Brookfield Business Partners L.P.',
    name: 'Brookfield Business Partners L.P.',
    symbol: 'BBU-UN.TO',
  },
  {
    value: 'BGI-UN.TO',
    label:
      'BGI-UN.TO,  Brookfield Global Infrastructure Securities Income Fund',
    name: 'Brookfield Global Infrastructure Securities Income Fund',
    symbol: 'BGI-UN.TO',
  },
  {
    value: 'BIP-UN.TO',
    label: 'BIP-UN.TO,  Brookfield Infrastructure Partners L.P.',
    name: 'Brookfield Infrastructure Partners L.P.',
    symbol: 'BIP-UN.TO',
  },
  {
    value: 'BRE.TO',
    label: 'BRE.TO,  Bridgemarq Real Estate Services Inc.',
    name: 'Bridgemarq Real Estate Services Inc.',
    symbol: 'BRE.TO',
  },
  {
    value: 'BEP-UN.TO',
    label: 'BEP-UN.TO,  Brookfield Renewable Partners L.P.',
    name: 'Brookfield Renewable Partners L.P.',
    symbol: 'BEP-UN.TO',
  },
  {
    value: 'BSO-UN.TO',
    label: 'BSO-UN.TO,  Brookfield Select Opportunities Income Fund',
    name: 'Brookfield Select Opportunities Income Fund',
    symbol: 'BSO-UN.TO',
  },
  {
    value: 'DOO.TO',
    label: 'DOO.TO,  BRP Inc.',
    name: 'BRP Inc.',
    symbol: 'DOO.TO',
  },
  {
    value: 'BTB-UN.TO',
    label: 'BTB-UN.TO,  BTB Real Estate Investment Trust',
    name: 'BTB Real Estate Investment Trust',
    symbol: 'BTB-UN.TO',
  },
  {
    value: 'BUI.TO',
    label: 'BUI.TO,  Buhler Industries Inc.',
    name: 'Buhler Industries Inc.',
    symbol: 'BUI.TO',
  },
  {
    value: 'BU.TO',
    label: 'BU.TO,  Burcon NutraScience Corporation',
    name: 'Burcon NutraScience Corporation',
    symbol: 'BU.TO',
  },
  {
    value: 'CAE.TO',
    label: 'CAE.TO,  CAE Inc.',
    name: 'CAE Inc.',
    symbol: 'CAE.TO',
  },
  {
    value: 'CWL.TO',
    label: 'CWL.TO,  The Caldwell Partners International Inc.',
    name: 'The Caldwell Partners International Inc.',
    symbol: 'CWL.TO',
  },
  {
    value: 'CFW.TO',
    label: 'CFW.TO,  Calfrac Well Services Ltd.',
    name: 'Calfrac Well Services Ltd.',
    symbol: 'CFW.TO',
  },
  {
    value: 'CGY.TO',
    label: 'CGY.TO,  Calian Group Ltd.',
    name: 'Calian Group Ltd.',
    symbol: 'CGY.TO',
  },
  {
    value: 'CCO.TO',
    label: 'CCO.TO,  Cameco Corporation',
    name: 'Cameco Corporation',
    symbol: 'CCO.TO',
  },
  {
    value: 'CF.TO',
    label: 'CF.TO,  Canaccord Genuity Group Inc.',
    name: 'Canaccord Genuity Group Inc.',
    symbol: 'CF.TO',
  },
  {
    value: 'CNE.TO',
    label: 'CNE.TO,  Canacol Energy Ltd',
    name: 'Canacol Energy Ltd',
    symbol: 'CNE.TO',
  },
  {
    value: 'CAR-UN.TO',
    label:
      'CAR-UN.TO,  Canadian Apartment Properties Real Estate Investment Trust',
    name: 'Canadian Apartment Properties Real Estate Investment Trust',
    symbol: 'CAR-UN.TO',
  },
  {
    value: 'BK.TO',
    label: 'BK.TO,  Canadian Banc Corp.',
    name: 'Canadian Banc Corp.',
    symbol: 'BK.TO',
  },
  {
    value: 'CEU.TO',
    label: 'CEU.TO,  CES Energy Solutions Corp.',
    name: 'CES Energy Solutions Corp.',
    symbol: 'CEU.TO',
  },
  {
    value: 'CIQ-UN.TO',
    label: 'CIQ-UN.TO,  Canadian High Income Equity Fund',
    name: 'Canadian High Income Equity Fund',
    symbol: 'CIQ-UN.TO',
  },
  {
    value: 'CM.TO',
    label: 'CM.TO,  Canadian Imperial Bank of Commerce',
    name: 'Canadian Imperial Bank of Commerce',
    symbol: 'CM.TO',
  },
  {
    value: 'LFE.TO',
    label: 'LFE.TO,  Canadian Life Companies Split Corp.',
    name: 'Canadian Life Companies Split Corp.',
    symbol: 'LFE.TO',
  },
  {
    value: 'CNR.TO',
    label: 'CNR.TO,  Canadian National Railway Company',
    name: 'Canadian National Railway Company',
    symbol: 'CNR.TO',
  },
  {
    value: 'CNQ.TO',
    label: 'CNQ.TO,  Canadian Natural Resources Limited',
    name: 'Canadian Natural Resources Limited',
    symbol: 'CNQ.TO',
  },
  {
    value: 'CP.TO',
    label: 'CP.TO,  Canadian Pacific Railway Limited',
    name: 'Canadian Pacific Railway Limited',
    symbol: 'CP.TO',
  },
  {
    value: 'CTC-A.TO',
    label: 'CTC-A.TO,  Canadian Tire Corporation, Limited',
    name: 'Canadian Tire Corporation, Limited',
    symbol: 'CTC-A.TO',
  },
  {
    value: 'CU.TO',
    label: 'CU.TO,  Canadian Utilities Limited',
    name: 'Canadian Utilities Limited',
    symbol: 'CU.TO',
  },
  {
    value: 'CWB.TO',
    label: 'CWB.TO,  Canadian Western Bank',
    name: 'Canadian Western Bank',
    symbol: 'CWB.TO',
  },
  {
    value: 'CCM.TO',
    label: 'CCM.TO,  Canagold Resources Ltd.',
    name: 'Canagold Resources Ltd.',
    symbol: 'CCM.TO',
  },
  {
    value: 'DNT.TO',
    label: 'DNT.TO,  Candente Copper Corp.',
    name: 'Candente Copper Corp.',
    symbol: 'DNT.TO',
  },
  {
    value: 'CFP.TO',
    label: 'CFP.TO,  Canfor Corporation',
    name: 'Canfor Corporation',
    symbol: 'CFP.TO',
  },
  {
    value: 'CFX.TO',
    label: 'CFX.TO,  Canfor Pulp Products Inc.',
    name: 'Canfor Pulp Products Inc.',
    symbol: 'CFX.TO',
  },
  {
    value: 'ICE.TO',
    label: 'ICE.TO,  Canlan Ice Sports Corp.',
    name: 'Canlan Ice Sports Corp.',
    symbol: 'ICE.TO',
  },
  {
    value: 'EIT-UN.TO',
    label: 'EIT-UN.TO,  Canoe EIT Income Fund',
    name: 'Canoe EIT Income Fund',
    symbol: 'EIT-UN.TO',
  },
  {
    value: 'PBY-UN.TO',
    label: 'PBY-UN.TO,  Canso Credit Trust - Canso Credit Income Fund',
    name: 'Canso Credit Trust - Canso Credit Income Fund',
    symbol: 'PBY-UN.TO',
  },
  {
    value: 'CPX.TO',
    label: 'CPX.TO,  Capital Power Corporation',
    name: 'Capital Power Corporation',
    symbol: 'CPX.TO',
  },
  {
    value: 'CS.TO',
    label: 'CS.TO,  Capstone Copper Corp.',
    name: 'Capstone Copper Corp.',
    symbol: 'CS.TO',
  },
  {
    value: 'CJ.TO',
    label: 'CJ.TO,  Cardinal Energy Ltd.',
    name: 'Cardinal Energy Ltd.',
    symbol: 'CJ.TO',
  },
  {
    value: 'CJT.TO',
    label: 'CJT.TO,  Cargojet Inc.',
    name: 'Cargojet Inc.',
    symbol: 'CJT.TO',
  },
  {
    value: 'CAS.TO',
    label: 'CAS.TO,  Cascades Inc.',
    name: 'Cascades Inc.',
    symbol: 'CAS.TO',
  },
  {
    value: 'CET.TO',
    label: 'CET.TO,  Cathedral Energy Services Ltd.',
    name: 'Cathedral Energy Services Ltd.',
    symbol: 'CET.TO',
  },
  {
    value: 'CCL-B.TO',
    label: 'CCL-B.TO,  CCL Industries Inc.',
    name: 'CCL Industries Inc.',
    symbol: 'CCL-B.TO',
  },
  {
    value: 'CLS.TO',
    label: 'CLS.TO,  Celestica Inc.',
    name: 'Celestica Inc.',
    symbol: 'CLS.TO',
  },
  {
    value: 'CVE.TO',
    label: 'CVE.TO,  Cenovus Energy Inc.',
    name: 'Cenovus Energy Inc.',
    symbol: 'CVE.TO',
  },
  {
    value: 'CEE.TO',
    label: 'CEE.TO,  Centamin plc',
    name: 'Centamin plc',
    symbol: 'CEE.TO',
  },
  {
    value: 'CG.TO',
    label: 'CG.TO,  Centerra Gold Inc.',
    name: 'Centerra Gold Inc.',
    symbol: 'CG.TO',
  },
  {
    value: 'CNT.TO',
    label: 'CNT.TO,  Century Global Commodities Corporation',
    name: 'Century Global Commodities Corporation',
    symbol: 'CNT.TO',
  },
  {
    value: 'CRP.TO',
    label: 'CRP.TO,  Ceres Global Ag Corp.',
    name: 'Ceres Global Ag Corp.',
    symbol: 'CRP.TO',
  },
  {
    value: 'GIB-A.TO',
    label: 'GIB-A.TO,  CGI Inc.',
    name: 'CGI Inc.',
    symbol: 'GIB-A.TO',
  },
  {
    value: 'CIA.TO',
    label: 'CIA.TO,  Champion Iron Limited',
    name: 'Champion Iron Limited',
    symbol: 'CIA.TO',
  },
  {
    value: 'CSH-UN.TO',
    label: 'CSH-UN.TO,  Chartwell Retirement Residences',
    name: 'Chartwell Retirement Residences',
    symbol: 'CSH-UN.TO',
  },
  {
    value: 'CHE-UN.TO',
    label: 'CHE-UN.TO,  Chemtrade Logistics Income Fund',
    name: 'Chemtrade Logistics Income Fund',
    symbol: 'CHE-UN.TO',
  },
  {
    value: 'CHW.TO',
    label: 'CHW.TO,  Chesswood Group Limited',
    name: 'Chesswood Group Limited',
    symbol: 'CHW.TO',
  },
  {
    value: 'CGG.TO',
    label: 'CGG.TO,  China Gold International Resources Corp. Ltd.',
    name: 'China Gold International Resources Corp. Ltd.',
    symbol: 'CGG.TO',
  },
  {
    value: 'CHP-UN.TO',
    label: 'CHP-UN.TO,  Choice Properties Real Estate Investment Trust',
    name: 'Choice Properties Real Estate Investment Trust',
    symbol: 'CHP-UN.TO',
  },
  {
    value: 'CHR.TO',
    label: 'CHR.TO,  Chorus Aviation Inc.',
    name: 'Chorus Aviation Inc.',
    symbol: 'CHR.TO',
  },
  {
    value: 'CIX.TO',
    label: 'CIX.TO,  CI Financial Corp.',
    name: 'CI Financial Corp.',
    symbol: 'CIX.TO',
  },
  {
    value: 'MBA.TO',
    label: 'MBA.TO,  CIBT Education Group Inc.',
    name: 'CIBT Education Group Inc.',
    symbol: 'MBA.TO',
  },
  {
    value: 'CGX.TO',
    label: 'CGX.TO,  Cineplex Inc.',
    name: 'Cineplex Inc.',
    symbol: 'CGX.TO',
  },
  {
    value: 'CPH.TO',
    label: 'CPH.TO,  Cipher Pharmaceuticals Inc.',
    name: 'Cipher Pharmaceuticals Inc.',
    symbol: 'CPH.TO',
  },
  {
    value: 'CTF-UN.TO',
    label: 'CTF-UN.TO,  Citadel Income Fund',
    name: 'Citadel Income Fund',
    symbol: 'CTF-UN.TO',
  },
  {
    value: 'CVG.TO',
    label: 'CVG.TO,  Clairvest Group Inc.',
    name: 'Clairvest Group Inc.',
    symbol: 'CVG.TO',
  },
  {
    value: 'CKI.TO',
    label: 'CKI.TO,  Clarke Inc.',
    name: 'Clarke Inc.',
    symbol: 'CKI.TO',
  },
  {
    value: 'CSM.TO',
    label: 'CSM.TO,  ClearStream Energy Services Inc.',
    name: 'ClearStream Energy Services Inc.',
    symbol: 'CSM.TO',
  },
  {
    value: 'CCA.TO',
    label: 'CCA.TO,  Cogeco Communications Inc.',
    name: 'Cogeco Communications Inc.',
    symbol: 'CCA.TO',
  },
  {
    value: 'CGO.TO',
    label: 'CGO.TO,  Cogeco Inc.',
    name: 'Cogeco Inc.',
    symbol: 'CGO.TO',
  },
  {
    value: 'GCL.TO',
    label: 'GCL.TO,  Colabor Group Inc.',
    name: 'Colabor Group Inc.',
    symbol: 'GCL.TO',
  },
  {
    value: 'CIGI.TO',
    label: 'CIGI.TO,  Colliers International Group Inc.',
    name: 'Colliers International Group Inc.',
    symbol: 'CIGI.TO',
  },
  {
    value: 'YCM.TO',
    label: 'YCM.TO,  New Commerce Split Fund',
    name: 'New Commerce Split Fund',
    symbol: 'YCM.TO',
  },
  {
    value: 'CMG.TO',
    label: 'CMG.TO,  Computer Modelling Group Ltd.',
    name: 'Computer Modelling Group Ltd.',
    symbol: 'CMG.TO',
  },
  {
    value: 'CFF.TO',
    label: 'CFF.TO,  Conifex Timber Inc.',
    name: 'Conifex Timber Inc.',
    symbol: 'CFF.TO',
  },
  {
    value: 'CSU.TO',
    label: 'CSU.TO,  Constellation Software Inc.',
    name: 'Constellation Software Inc.',
    symbol: 'CSU.TO',
  },
  {
    value: 'CSW-A.TO',
    label: 'CSW-A.TO,  Corby Spirit and Wine Limited',
    name: 'Corby Spirit and Wine Limited',
    symbol: 'CSW-A.TO',
  },
  {
    value: 'CJR-B.TO',
    label: 'CJR-B.TO,  Corus Entertainment Inc.',
    name: 'Corus Entertainment Inc.',
    symbol: 'CJR-B.TO',
  },
  {
    value: 'CPG.TO',
    label: 'CPG.TO,  Crescent Point Energy Corp.',
    name: 'Crescent Point Energy Corp.',
    symbol: 'CPG.TO',
  },
  {
    value: 'CTX.TO',
    label: 'CTX.TO,  Crescita Therapeutics Inc.',
    name: 'Crescita Therapeutics Inc.',
    symbol: 'CTX.TO',
  },
  {
    value: 'CR.TO',
    label: 'CR.TO,  Crew Energy Inc.',
    name: 'Crew Energy Inc.',
    symbol: 'CR.TO',
  },
  {
    value: 'CRR-UN.TO',
    label: 'CRR-UN.TO,  Crombie Real Estate Investment Trust',
    name: 'Crombie Real Estate Investment Trust',
    symbol: 'CRR-UN.TO',
  },
  {
    value: 'CRT-UN.TO',
    label: 'CRT-UN.TO,  CT Real Estate Investment Trust',
    name: 'CT Real Estate Investment Trust',
    symbol: 'CRT-UN.TO',
  },
  {
    value: 'CXI.TO',
    label: 'CXI.TO,  Currency Exchange International, Corp.',
    name: 'Currency Exchange International, Corp.',
    symbol: 'CXI.TO',
  },
  {
    value: 'CYB.TO',
    label: 'CYB.TO,  Cymbria Corporation',
    name: 'Cymbria Corporation',
    symbol: 'CYB.TO',
  },
  {
    value: 'DBO.TO',
    label: 'DBO.TO,  D-BOX Technologies Inc.',
    name: 'D-BOX Technologies Inc.',
    symbol: 'DBO.TO',
  },
  {
    value: 'DCM.TO',
    label: 'DCM.TO,  DATA Communications Management Corp.',
    name: 'DATA Communications Management Corp.',
    symbol: 'DCM.TO',
  },
  {
    value: 'DSG.TO',
    label: 'DSG.TO,  The Descartes Systems Group Inc.',
    name: 'The Descartes Systems Group Inc.',
    symbol: 'DSG.TO',
  },
  {
    value: 'DRT.TO',
    label: 'DRT.TO,  DIRTT Environmental Solutions Ltd.',
    name: 'DIRTT Environmental Solutions Ltd.',
    symbol: 'DRT.TO',
  },
  {
    value: 'DIV.TO',
    label: 'DIV.TO,  Diversified Royalty Corp.',
    name: 'Diversified Royalty Corp.',
    symbol: 'DIV.TO',
  },
  {
    value: 'DFN.TO',
    label: 'DFN.TO,  Dividend 15 Split Corp.',
    name: 'Dividend 15 Split Corp.',
    symbol: 'DFN.TO',
  },
  {
    value: 'DF.TO',
    label: 'DF.TO,  Dividend 15 Split Corp. II',
    name: 'Dividend 15 Split Corp. II',
    symbol: 'DF.TO',
  },
  {
    value: 'DGS.TO',
    label: 'DGS.TO,  Dividend Growth Split Corp.',
    name: 'Dividend Growth Split Corp.',
    symbol: 'DGS.TO',
  },
  {
    value: 'DS.TO',
    label: 'DS.TO,  Dividend Select 15 Corp.',
    name: 'Dividend Select 15 Corp.',
    symbol: 'DS.TO',
  },
  {
    value: 'DOL.TO',
    label: 'DOL.TO,  Dollarama Inc.',
    name: 'Dollarama Inc.',
    symbol: 'DOL.TO',
  },
  {
    value: 'DII-B.TO',
    label: 'DII-B.TO,  Dorel Industries Inc.',
    name: 'Dorel Industries Inc.',
    symbol: 'DII-B.TO',
  },
  {
    value: 'DIR-UN.TO',
    label: 'DIR-UN.TO,  Dream Industrial Real Estate Investment Trust',
    name: 'Dream Industrial Real Estate Investment Trust',
    symbol: 'DIR-UN.TO',
  },
  {
    value: 'D-UN.TO',
    label: 'D-UN.TO,  Dream Office Real Estate Investment Trust',
    name: 'Dream Office Real Estate Investment Trust',
    symbol: 'D-UN.TO',
  },
  {
    value: 'DRM.TO',
    label: 'DRM.TO,  Dream Unlimited Corp.',
    name: 'Dream Unlimited Corp.',
    symbol: 'DRM.TO',
  },
  {
    value: 'DC-A.TO',
    label: 'DC-A.TO,  Dundee Corporation',
    name: 'Dundee Corporation',
    symbol: 'DC-A.TO',
  },
  {
    value: 'DPM.TO',
    label: 'DPM.TO,  Dundee Precious Metals Inc.',
    name: 'Dundee Precious Metals Inc.',
    symbol: 'DPM.TO',
  },
  {
    value: 'DNG.TO',
    label: 'DNG.TO,  Dynacor Group Inc.',
    name: 'Dynacor Group Inc.',
    symbol: 'DNG.TO',
  },
  {
    value: 'ELF.TO',
    label: 'ELF.TO,  E-L Financial Corporation Limited',
    name: 'E-L Financial Corporation Limited',
    symbol: 'ELF.TO',
  },
  {
    value: 'ELR.TO',
    label: 'ELR.TO,  Eastern Platinum Limited',
    name: 'Eastern Platinum Limited',
    symbol: 'ELR.TO',
  },
  {
    value: 'ECN.TO',
    label: 'ECN.TO,  ECN Capital Corp.',
    name: 'ECN Capital Corp.',
    symbol: 'ECN.TO',
  },
  {
    value: 'EVT.TO',
    label: 'EVT.TO,  Economic Investment Trust Limited',
    name: 'Economic Investment Trust Limited',
    symbol: 'EVT.TO',
  },
  {
    value: 'ECO.TO',
    label: 'ECO.TO,  EcoSynthetix Inc.',
    name: 'EcoSynthetix Inc.',
    symbol: 'ECO.TO',
  },
  {
    value: 'ELD.TO',
    label: 'ELD.TO,  Eldorado Gold Corporation',
    name: 'Eldorado Gold Corporation',
    symbol: 'ELD.TO',
  },
  {
    value: 'EFL.TO',
    label: 'EFL.TO,  Electrovaya Inc.',
    name: 'Electrovaya Inc.',
    symbol: 'EFL.TO',
  },
  {
    value: 'EFN.TO',
    label: 'EFN.TO,  Element Fleet Management Corp.',
    name: 'Element Fleet Management Corp.',
    symbol: 'EFN.TO',
  },
  {
    value: 'EMA.TO',
    label: 'EMA.TO,  Emera Incorporated',
    name: 'Emera Incorporated',
    symbol: 'EMA.TO',
  },
  {
    value: 'EMP-A.TO',
    label: 'EMP-A.TO,  Empire Company Limited',
    name: 'Empire Company Limited',
    symbol: 'EMP-A.TO',
  },
  {
    value: 'ENB.TO',
    label: 'ENB.TO,  Enbridge Inc.',
    name: 'Enbridge Inc.',
    symbol: 'ENB.TO',
  },
  {
    value: 'EDV.TO',
    label: 'EDV.TO,  Endeavour Mining plc',
    name: 'Endeavour Mining plc',
    symbol: 'EDV.TO',
  },
  {
    value: 'EDR.TO',
    label: 'EDR.TO,  Endeavour Silver Corp.',
    name: 'Endeavour Silver Corp.',
    symbol: 'EDR.TO',
  },
  {
    value: 'EFX.TO',
    label: 'EFX.TO,  Enerflex Ltd.',
    name: 'Enerflex Ltd.',
    symbol: 'EFX.TO',
  },
  {
    value: 'EFR.TO',
    label: 'EFR.TO,  Energy Fuels Inc.',
    name: 'Energy Fuels Inc.',
    symbol: 'EFR.TO',
  },
  {
    value: 'ENI-UN.TO',
    label: 'ENI-UN.TO,  Energy Income Fund',
    name: 'Energy Income Fund',
    symbol: 'ENI-UN.TO',
  },
  {
    value: 'HPF.TO',
    label: 'HPF.TO,  Harvest Energy Leaders Plus Income ETF',
    name: 'Harvest Energy Leaders Plus Income ETF',
    symbol: 'HPF.TO',
  },
  {
    value: 'ERF.TO',
    label: 'ERF.TO,  Enerplus Corporation',
    name: 'Enerplus Corporation',
    symbol: 'ERF.TO',
  },
  {
    value: 'ENGH.TO',
    label: 'ENGH.TO,  Enghouse Systems Limited',
    name: 'Enghouse Systems Limited',
    symbol: 'ENGH.TO',
  },
  {
    value: 'ESI.TO',
    label: 'ESI.TO,  Ensign Energy Services Inc.',
    name: 'Ensign Energy Services Inc.',
    symbol: 'ESI.TO',
  },
  {
    value: 'ETG.TO',
    label: 'ETG.TO,  Entrée Resources Ltd.',
    name: 'Entrée Resources Ltd.',
    symbol: 'ETG.TO',
  },
  {
    value: 'EQB.TO',
    label: 'EQB.TO,  EQB Inc.',
    name: 'EQB Inc.',
    symbol: 'EQB.TO',
  },
  {
    value: 'ERD.TO',
    label: 'ERD.TO,  Erdene Resource Development Corporation',
    name: 'Erdene Resource Development Corporation',
    symbol: 'ERD.TO',
  },
  {
    value: 'ESN.TO',
    label: 'ESN.TO,  Essential Energy Services Ltd.',
    name: 'Essential Energy Services Ltd.',
    symbol: 'ESN.TO',
  },
  {
    value: 'ESM.TO',
    label: 'ESM.TO,  Euro Sun Mining Inc.',
    name: 'Euro Sun Mining Inc.',
    symbol: 'ESM.TO',
  },
  {
    value: 'EOX.TO',
    label: 'EOX.TO,  Euromax Resources Ltd.',
    name: 'Euromax Resources Ltd.',
    symbol: 'EOX.TO',
  },
  {
    value: 'ET.TO',
    label: 'ET.TO,  Evertz Technologies Limited',
    name: 'Evertz Technologies Limited',
    symbol: 'ET.TO',
  },
  {
    value: 'EXN.TO',
    label: 'EXN.TO,  Excellon Resources Inc.',
    name: 'Excellon Resources Inc.',
    symbol: 'EXN.TO',
  },
  {
    value: 'EIF.TO',
    label: 'EIF.TO,  Exchange Income Corporation',
    name: 'Exchange Income Corporation',
    symbol: 'EIF.TO',
  },
  {
    value: 'XTC.TO',
    label: 'XTC.TO,  Exco Technologies Limited',
    name: 'Exco Technologies Limited',
    symbol: 'XTC.TO',
  },
  {
    value: 'EXE.TO',
    label: 'EXE.TO,  Extendicare Inc.',
    name: 'Extendicare Inc.',
    symbol: 'EXE.TO',
  },
  {
    value: 'FFH.TO',
    label: 'FFH.TO,  Fairfax Financial Holdings Limited',
    name: 'Fairfax Financial Holdings Limited',
    symbol: 'FFH.TO',
  },
  {
    value: 'FIH-U.TO',
    label: 'FIH-U.TO,  Fairfax India Holdings Corporation',
    name: 'Fairfax India Holdings Corporation',
    symbol: 'FIH-U.TO',
  },
  {
    value: 'FRX.TO',
    label: 'FRX.TO,  Fennec Pharmaceuticals Inc.',
    name: 'Fennec Pharmaceuticals Inc.',
    symbol: 'FRX.TO',
  },
  {
    value: 'FSZ.TO',
    label: 'FSZ.TO,  Fiera Capital Corporation',
    name: 'Fiera Capital Corporation',
    symbol: 'FSZ.TO',
  },
  {
    value: 'FTN.TO',
    label: 'FTN.TO,  Financial 15 Split Corp.',
    name: 'Financial 15 Split Corp.',
    symbol: 'FTN.TO',
  },
  {
    value: 'FTT.TO',
    label: 'FTT.TO,  Finning International Inc.',
    name: 'Finning International Inc.',
    symbol: 'FTT.TO',
  },
  {
    value: 'FTG.TO',
    label: 'FTG.TO,  Firan Technology Group Corporation',
    name: 'Firan Technology Group Corporation',
    symbol: 'FTG.TO',
  },
  {
    value: 'FC.TO',
    label: 'FC.TO,  Firm Capital Mortgage Investment Corporation',
    name: 'Firm Capital Mortgage Investment Corporation',
    symbol: 'FC.TO',
  },
  {
    value: 'BXF.TO',
    label: 'BXF.TO,  CI 1-5 Year Laddered Government Strip Bond Index ETF',
    name: 'CI 1-5 Year Laddered Government Strip Bond Index ETF',
    symbol: 'BXF.TO',
  },
  {
    value: 'CXF.TO',
    label: 'CXF.TO,  CI Canadian Convertible Bond ETF',
    name: 'CI Canadian Convertible Bond ETF',
    symbol: 'CXF.TO',
  },
  {
    value: 'RIT.TO',
    label: 'RIT.TO,  CI Canadian REIT ETF',
    name: 'CI Canadian REIT ETF',
    symbol: 'RIT.TO',
  },
  {
    value: 'CIC.TO',
    label: 'CIC.TO,  CI Canadian Banks Covered Call Income Class ETF',
    name: 'CI Canadian Banks Covered Call Income Class ETF',
    symbol: 'CIC.TO',
  },
  {
    value: 'NXF.TO',
    label: 'NXF.TO,  CI Energy Giants Covered Call ETF',
    name: 'CI Energy Giants Covered Call ETF',
    symbol: 'NXF.TO',
  },
  {
    value: 'FSF.TO',
    label: 'FSF.TO,  CI Global Financial Sector ETF',
    name: 'CI Global Financial Sector ETF',
    symbol: 'FSF.TO',
  },
  {
    value: 'WXM.TO',
    label: 'WXM.TO,  CI Morningstar Canada Momentum Index ETF',
    name: 'CI Morningstar Canada Momentum Index ETF',
    symbol: 'WXM.TO',
  },
  {
    value: 'FXM.TO',
    label: 'FXM.TO,  CI Morningstar Canada Value Index ETF',
    name: 'CI Morningstar Canada Value Index ETF',
    symbol: 'FXM.TO',
  },
  {
    value: 'ZXM.TO',
    label:
      'ZXM.TO,  CI Morningstar International Momentum Index ETF Common Units (CAD Hedged)',
    name: 'CI Morningstar International Momentum Index ETF Common Units (CAD Hedged)',
    symbol: 'ZXM.TO',
  },
  {
    value: 'VXM.TO',
    label: 'VXM.TO,  CI Morningstar International Value Index ETF',
    name: 'CI Morningstar International Value Index ETF',
    symbol: 'VXM.TO',
  },
  {
    value: 'QXM.TO',
    label: 'QXM.TO,  CI Morningstar National Bank Québec Index ETF',
    name: 'CI Morningstar National Bank Québec Index ETF',
    symbol: 'QXM.TO',
  },
  {
    value: 'YXM.TO',
    label: 'YXM.TO,  CI Morningstar US Momentum Index ETF (CAD Hedged) Common',
    name: 'CI Morningstar US Momentum Index ETF (CAD Hedged) Common',
    symbol: 'YXM.TO',
  },
  {
    value: 'XXM.TO',
    label: 'XXM.TO,  CI Morningstar US Value Index ETF (CAD Hedged) Common',
    name: 'CI Morningstar US Value Index ETF (CAD Hedged) Common',
    symbol: 'XXM.TO',
  },
  {
    value: 'FQC.TO',
    label: 'FQC.TO,  CI MSCI Canada Quality Index Class ETF',
    name: 'CI MSCI Canada Quality Index Class ETF',
    symbol: 'FQC.TO',
  },
  {
    value: 'RWE.TO',
    label: 'RWE.TO,  CI MSCI Europe Low Risk Weighted ETF',
    name: 'CI MSCI Europe Low Risk Weighted ETF',
    symbol: 'RWE.TO',
  },
  {
    value: 'RWW.TO',
    label: 'RWW.TO,  CI MSCI World Low Risk Weighted ETF',
    name: 'CI MSCI World Low Risk Weighted ETF',
    symbol: 'RWW.TO',
  },
  {
    value: 'FPR.TO',
    label: 'FPR.TO,  CI Preferred Share ETF',
    name: 'CI Preferred Share ETF',
    symbol: 'FPR.TO',
  },
  {
    value: 'FGB.TO',
    label: 'FGB.TO,  CI Short Term Government Bond Index',
    name: 'CI Short Term Government Bond Index',
    symbol: 'FGB.TO',
  },
  {
    value: 'TXF.TO',
    label: 'TXF.TO,  CI Tech Giants Covered Call ETF',
    name: 'CI Tech Giants Covered Call ETF',
    symbol: 'TXF.TO',
  },
  {
    value: 'FLI.TO',
    label:
      'FLI.TO,  CI U.S. & Canada Lifeco Covered Call ETF (Hedged Common Units)',
    name: 'CI U.S. & Canada Lifeco Covered Call ETF (Hedged Common Units)',
    symbol: 'FLI.TO',
  },
  {
    value: 'FR.TO',
    label: 'FR.TO,  First Majestic Silver Corp.',
    name: 'First Majestic Silver Corp.',
    symbol: 'FR.TO',
  },
  {
    value: 'FN.TO',
    label: 'FN.TO,  First National Financial Corporation',
    name: 'First National Financial Corporation',
    symbol: 'FN.TO',
  },
  {
    value: 'FM.TO',
    label: 'FM.TO,  First Quantum Minerals Ltd.',
    name: 'First Quantum Minerals Ltd.',
    symbol: 'FM.TO',
  },
  {
    value: 'FDE.TO',
    label:
      'FDE.TO,  First Trust AlphaDEX Emerging Market Dividend ETF (CAD-Hedged)',
    name: 'First Trust AlphaDEX Emerging Market Dividend ETF (CAD-Hedged)',
    symbol: 'FDE.TO',
  },
  {
    value: 'EUR.TO',
    label:
      'EUR.TO,  First Trust AlphaDEX European Dividend Index ETF (CAD-Hedged)',
    name: 'First Trust AlphaDEX European Dividend Index ETF (CAD-Hedged)',
    symbol: 'EUR.TO',
  },
  {
    value: 'FUD.TO',
    label: 'FUD.TO,  First Trust Value Line Dividend Index ETF (CAD-Hedged)',
    name: 'First Trust Value Line Dividend Index ETF (CAD-Hedged)',
    symbol: 'FUD.TO',
  },
  {
    value: 'FHH.TO',
    label: 'FHH.TO,  FT AlphaDEX U.S. Health Care Sector Index ETF',
    name: 'FT AlphaDEX U.S. Health Care Sector Index ETF',
    symbol: 'FHH.TO',
  },
  {
    value: 'FHG.TO',
    label: 'FHG.TO,  First Trust AlphaDEX U.S. Industrials Sector Index ETF',
    name: 'First Trust AlphaDEX U.S. Industrials Sector Index ETF',
    symbol: 'FHG.TO',
  },
  {
    value: 'FHQ.TO',
    label: 'FHQ.TO,  First Trust AlphaDEX U.S. Technology Sector Index ETF',
    name: 'First Trust AlphaDEX U.S. Technology Sector Index ETF',
    symbol: 'FHQ.TO',
  },
  {
    value: 'FST.TO',
    label: 'FST.TO,  First Trust Canadian Capital Strength ETF',
    name: 'First Trust Canadian Capital Strength ETF',
    symbol: 'FST.TO',
  },
  {
    value: 'ETP.TO',
    label: 'ETP.TO,  First Trust Global Risk Managed Income Index ETF',
    name: 'First Trust Global Risk Managed Income Index ETF',
    symbol: 'ETP.TO',
  },
  {
    value: 'FSL.TO',
    label: 'FSL.TO,  First Trust Senior Loan ETF (CAD-Hedged)',
    name: 'First Trust Senior Loan ETF (CAD-Hedged)',
    symbol: 'FSL.TO',
  },
  {
    value: 'FTB.TO',
    label: 'FTB.TO,  First Trust Tactical Bond Index ETF',
    name: 'First Trust Tactical Bond Index ETF',
    symbol: 'FTB.TO',
  },
  {
    value: 'FSV.TO',
    label: 'FSV.TO,  FirstService Corporation',
    name: 'FirstService Corporation',
    symbol: 'FSV.TO',
  },
  {
    value: 'FCU.TO',
    label: 'FCU.TO,  Fission Uranium Corp.',
    name: 'Fission Uranium Corp.',
    symbol: 'FCU.TO',
  },
  {
    value: 'FAR.TO',
    label: 'FAR.TO,  Foraco International SA',
    name: 'Foraco International SA',
    symbol: 'FAR.TO',
  },
  {
    value: 'FSY.TO',
    label: 'FSY.TO,  Forsys Metals Corp.',
    name: 'Forsys Metals Corp.',
    symbol: 'FSY.TO',
  },
  {
    value: 'FTS.TO',
    label: 'FTS.TO,  Fortis Inc.',
    name: 'Fortis Inc.',
    symbol: 'FTS.TO',
  },
  {
    value: 'FVI.TO',
    label: 'FVI.TO,  Fortuna Silver Mines Inc.',
    name: 'Fortuna Silver Mines Inc.',
    symbol: 'FVI.TO',
  },
  {
    value: 'FT.TO',
    label: 'FT.TO,  Fortune Minerals Limited',
    name: 'Fortune Minerals Limited',
    symbol: 'FT.TO',
  },
  {
    value: 'FNV.TO',
    label: 'FNV.TO,  Franco-Nevada Corporation',
    name: 'Franco-Nevada Corporation',
    symbol: 'FNV.TO',
  },
  {
    value: 'FVL.TO',
    label: 'FVL.TO,  Freegold Ventures Limited',
    name: 'Freegold Ventures Limited',
    symbol: 'FVL.TO',
  },
  {
    value: 'FRU.TO',
    label: 'FRU.TO,  Freehold Royalties Ltd.',
    name: 'Freehold Royalties Ltd.',
    symbol: 'FRU.TO',
  },
  {
    value: 'GH.TO',
    label: 'GH.TO,  Gamehost Inc.',
    name: 'Gamehost Inc.',
    symbol: 'GH.TO',
  },
  {
    value: 'GDI.TO',
    label: 'GDI.TO,  GDI Integrated Facility Services Inc.',
    name: 'GDI Integrated Facility Services Inc.',
    symbol: 'GDI.TO',
  },
  {
    value: 'GXE.TO',
    label: 'GXE.TO,  Gear Energy Ltd.',
    name: 'Gear Energy Ltd.',
    symbol: 'GXE.TO',
  },
  {
    value: 'GDC.TO',
    label: 'GDC.TO,  Genesis Land Development Corp.',
    name: 'Genesis Land Development Corp.',
    symbol: 'GDC.TO',
  },
  {
    value: 'GEO.TO',
    label: 'GEO.TO,  Geodrill Limited',
    name: 'Geodrill Limited',
    symbol: 'GEO.TO',
  },
  {
    value: 'WN.TO',
    label: 'WN.TO,  George Weston Limited',
    name: 'George Weston Limited',
    symbol: 'WN.TO',
  },
  {
    value: 'GEI.TO',
    label: 'GEI.TO,  Gibson Energy Inc.',
    name: 'Gibson Energy Inc.',
    symbol: 'GEI.TO',
  },
  {
    value: 'GIL.TO',
    label: 'GIL.TO,  Gildan Activewear Inc.',
    name: 'Gildan Activewear Inc.',
    symbol: 'GIL.TO',
  },
  {
    value: 'GVC.TO',
    label: 'GVC.TO,  Glacier Media Inc.',
    name: 'Glacier Media Inc.',
    symbol: 'GVC.TO',
  },
  {
    value: 'GLG.TO',
    label: 'GLG.TO,  GLG Life Tech Corporation',
    name: 'GLG Life Tech Corporation',
    symbol: 'GLG.TO',
  },
  {
    value: 'GMX.TO',
    label: 'GMX.TO,  Globex Mining Enterprises Inc.',
    name: 'Globex Mining Enterprises Inc.',
    symbol: 'GMX.TO',
  },
  {
    value: 'GSY.TO',
    label: 'GSY.TO,  goeasy Ltd.',
    name: 'goeasy Ltd.',
    symbol: 'GSY.TO',
  },
  {
    value: 'GGD.TO',
    label: 'GGD.TO,  GoGold Resources Inc.',
    name: 'GoGold Resources Inc.',
    symbol: 'GGD.TO',
  },
  {
    value: 'GGA.TO',
    label: 'GGA.TO,  Goldgroup Mining Inc.',
    name: 'Goldgroup Mining Inc.',
    symbol: 'GGA.TO',
  },
  {
    value: 'XAU.TO',
    label: 'XAU.TO,  Goldmoney Inc.',
    name: 'Goldmoney Inc.',
    symbol: 'XAU.TO',
  },
  {
    value: 'GDL.TO',
    label: 'GDL.TO,  Goodfellow Inc.',
    name: 'Goodfellow Inc.',
    symbol: 'GDL.TO',
  },
  {
    value: 'GTE.TO',
    label: 'GTE.TO,  Gran Tierra Energy Inc.',
    name: 'Gran Tierra Energy Inc.',
    symbol: 'GTE.TO',
  },
  {
    value: 'GRT-UN.TO',
    label: 'GRT-UN.TO,  Granite Real Estate Investment Trust',
    name: 'Granite Real Estate Investment Trust',
    symbol: 'GRT-UN.TO',
  },
  {
    value: 'GWO.TO',
    label: 'GWO.TO,  Great-West Lifeco Inc.',
    name: 'Great-West Lifeco Inc.',
    symbol: 'GWO.TO',
  },
  {
    value: 'GCG-A.TO',
    label: 'GCG-A.TO,  Guardian Capital Group Limited',
    name: 'Guardian Capital Group Limited',
    symbol: 'GCG-A.TO',
  },
  {
    value: 'HR-UN.TO',
    label: 'HR-UN.TO,  H&R Real Estate Investment Trust',
    name: 'H&R Real Estate Investment Trust',
    symbol: 'HR-UN.TO',
  },
  {
    value: 'HMM-A.TO',
    label: 'HMM-A.TO,  Hammond Manufacturing Company Limited',
    name: 'Hammond Manufacturing Company Limited',
    symbol: 'HMM-A.TO',
  },
  {
    value: 'HPS-A.TO',
    label: 'HPS-A.TO,  Hammond Power Solutions Inc.',
    name: 'Hammond Power Solutions Inc.',
    symbol: 'HPS-A.TO',
  },
  {
    value: 'HHL.TO',
    label: 'HHL.TO,  Harvest Healthcare Leaders Income ETF',
    name: 'Harvest Healthcare Leaders Income ETF',
    symbol: 'HHL.TO',
  },
  {
    value: 'MDS-UN.TO',
    label: 'MDS-UN.TO,  Healthcare Special Opportunities Fund',
    name: 'Healthcare Special Opportunities Fund',
    symbol: 'MDS-UN.TO',
  },
  {
    value: 'HBP.TO',
    label: 'HBP.TO,  Helix BioPharma Corp.',
    name: 'Helix BioPharma Corp.',
    symbol: 'HBP.TO',
  },
  {
    value: 'HRX.TO',
    label: 'HRX.TO,  Héroux-Devtek Inc.',
    name: 'Héroux-Devtek Inc.',
    symbol: 'HRX.TO',
  },
  {
    value: 'HWO.TO',
    label: 'HWO.TO,  High Arctic Energy Services Inc',
    name: 'High Arctic Energy Services Inc',
    symbol: 'HWO.TO',
  },
  {
    value: 'HLF.TO',
    label: 'HLF.TO,  High Liner Foods Incorporated',
    name: 'High Liner Foods Incorporated',
    symbol: 'HLF.TO',
  },
  {
    value: 'HCG.TO',
    label: 'HCG.TO,  Home Capital Group Inc.',
    name: 'Home Capital Group Inc.',
    symbol: 'HCG.TO',
  },
  {
    value: 'HAD.TO',
    label: 'HAD.TO,  Horizons Active Cdn Bond ETF',
    name: 'Horizons Active Cdn Bond ETF',
    symbol: 'HAD.TO',
  },
  {
    value: 'HAL.TO',
    label: 'HAL.TO,  Horizons Active Cdn Dividend ETF',
    name: 'Horizons Active Cdn Dividend ETF',
    symbol: 'HAL.TO',
  },
  {
    value: 'HMP.TO',
    label: 'HMP.TO,  Horizons Active Cdn Municipal Bond ETF',
    name: 'Horizons Active Cdn Municipal Bond ETF',
    symbol: 'HMP.TO',
  },
  {
    value: 'HAB.TO',
    label: 'HAB.TO,  Horizons Active Corporate Bond ETF',
    name: 'Horizons Active Corporate Bond ETF',
    symbol: 'HAB.TO',
  },
  {
    value: 'HFR.TO',
    label:
      'HFR.TO,  Horizons Active Ultra-Short Term Investment Grade Bond ETF',
    name: 'Horizons Active Ultra-Short Term Investment Grade Bond ETF',
    symbol: 'HFR.TO',
  },
  {
    value: 'HSL.TO',
    label: 'HSL.TO,  Horizons Active Floating Rate Senior Loan ETF',
    name: 'Horizons Active Floating Rate Senior Loan ETF',
    symbol: 'HSL.TO',
  },
  {
    value: 'HAZ.TO',
    label: 'HAZ.TO,  Horizons Active Global Dividend ETF',
    name: 'Horizons Active Global Dividend ETF',
    symbol: 'HAZ.TO',
  },
  {
    value: 'HAF.TO',
    label: 'HAF.TO,  Horizons Active Global Fixed Income ETF',
    name: 'Horizons Active Global Fixed Income ETF',
    symbol: 'HAF.TO',
  },
  {
    value: 'HYI.TO',
    label: 'HYI.TO,  Horizons Active High Yield Bond ETF',
    name: 'Horizons Active High Yield Bond ETF',
    symbol: 'HYI.TO',
  },
  {
    value: 'HPR.TO',
    label: 'HPR.TO,  Horizons Active Preferred Share ETF',
    name: 'Horizons Active Preferred Share ETF',
    symbol: 'HPR.TO',
  },
  {
    value: 'HUF.TO',
    label:
      'HUF.TO,  Horizons Active Ultra-Short Term US Investment Grade Bond ETF',
    name: 'Horizons Active Ultra-Short Term US Investment Grade Bond ETF',
    symbol: 'HUF.TO',
  },
  {
    value: 'HBD.TO',
    label: 'HBD.TO,  BetaPro Gold Bullion -2x Daily Bear ETF',
    name: 'BetaPro Gold Bullion -2x Daily Bear ETF',
    symbol: 'HBD.TO',
  },
  {
    value: 'HBU.TO',
    label: 'HBU.TO,  BetaPro Gold Bullion 2x Daily Bull ETF',
    name: 'BetaPro Gold Bullion 2x Daily Bull ETF',
    symbol: 'HBU.TO',
  },
  {
    value: 'HZD.TO',
    label: 'HZD.TO,  BetaPro Silver -2x Daily Bear ETF',
    name: 'BetaPro Silver -2x Daily Bear ETF',
    symbol: 'HZD.TO',
  },
  {
    value: 'HZU.TO',
    label: 'HZU.TO,  BetaPro Silver 2x Daily Bull ETF',
    name: 'BetaPro Silver 2x Daily Bull ETF',
    symbol: 'HZU.TO',
  },
  {
    value: 'HQU.TO',
    label: 'HQU.TO,  BetaPro NASDAQ-100 2x Daily Bull ETF',
    name: 'BetaPro NASDAQ-100 2x Daily Bull ETF',
    symbol: 'HQU.TO',
  },
  {
    value: 'HOD.TO',
    label: 'HOD.TO,  BetaPro Crude Oil Inverse Leveraged Daily Bear ETF',
    name: 'BetaPro Crude Oil Inverse Leveraged Daily Bear ETF',
    symbol: 'HOD.TO',
  },
  {
    value: 'HOU.TO',
    label: 'HOU.TO,  BetaPro Crude Oil Leveraged Daily Bull ETF',
    name: 'BetaPro Crude Oil Leveraged Daily Bull ETF',
    symbol: 'HOU.TO',
  },
  {
    value: 'HND.TO',
    label: 'HND.TO,  BetaPro Natural Gas Inverse Leveraged Daily Bear ETF',
    name: 'BetaPro Natural Gas Inverse Leveraged Daily Bear ETF',
    symbol: 'HND.TO',
  },
  {
    value: 'HNU.TO',
    label: 'HNU.TO,  BetaPro Natural Gas Leveraged Daily Bull ETF',
    name: 'BetaPro Natural Gas Leveraged Daily Bull ETF',
    symbol: 'HNU.TO',
  },
  {
    value: 'HSD.TO',
    label: 'HSD.TO,  BetaPro S&P 500 -2x Daily Bear ETF',
    name: 'BetaPro S&P 500 -2x Daily Bear ETF',
    symbol: 'HSD.TO',
  },
  {
    value: 'HSU.TO',
    label: 'HSU.TO,  BetaPro S&P 500 2x Daily Bull ETF',
    name: 'BetaPro S&P 500 2x Daily Bull ETF',
    symbol: 'HSU.TO',
  },
  {
    value: 'HIU.TO',
    label: 'HIU.TO,  BetaPro S&P 500 Daily Inverse ETF',
    name: 'BetaPro S&P 500 Daily Inverse ETF',
    symbol: 'HIU.TO',
  },
  {
    value: 'HUV.TO',
    label: 'HUV.TO,  BetaPro S&P 500 VIX Short-Term Futures ETF',
    name: 'BetaPro S&P 500 VIX Short-Term Futures ETF',
    symbol: 'HUV.TO',
  },
  {
    value: 'HXD.TO',
    label: 'HXD.TO,  BetaPro S&P/TSX 60 -2x Daily Bear ETF',
    name: 'BetaPro S&P/TSX 60 -2x Daily Bear ETF',
    symbol: 'HXD.TO',
  },
  {
    value: 'HXU.TO',
    label: 'HXU.TO,  BetaPro S&P/TSX 60 2x Daily Bull ETF',
    name: 'BetaPro S&P/TSX 60 2x Daily Bull ETF',
    symbol: 'HXU.TO',
  },
  {
    value: 'HIX.TO',
    label: 'HIX.TO,  BetaPro S&P/TSX 60 Daily Inverse ETF',
    name: 'BetaPro S&P/TSX 60 Daily Inverse ETF',
    symbol: 'HIX.TO',
  },
  {
    value: 'HED.TO',
    label: 'HED.TO,  BetaPro S&P/TSX Capped Energy -2x Daily Bear ETF',
    name: 'BetaPro S&P/TSX Capped Energy -2x Daily Bear ETF',
    symbol: 'HED.TO',
  },
  {
    value: 'HEU.TO',
    label: 'HEU.TO,  BetaPro S&P/TSX Capped Energy 2x Daily Bull ETF',
    name: 'BetaPro S&P/TSX Capped Energy 2x Daily Bull ETF',
    symbol: 'HEU.TO',
  },
  {
    value: 'HFD.TO',
    label: 'HFD.TO,  BetaPro S&P/TSX Capped Financials -2x Daily Bear ETF',
    name: 'BetaPro S&P/TSX Capped Financials -2x Daily Bear ETF',
    symbol: 'HFD.TO',
  },
  {
    value: 'HFU.TO',
    label: 'HFU.TO,  BetaPro S&P/TSX Capped Financials 2x Daily Bull ETF',
    name: 'BetaPro S&P/TSX Capped Financials 2x Daily Bull ETF',
    symbol: 'HFU.TO',
  },
  {
    value: 'HGD.TO',
    label: 'HGD.TO,  BetaPro Canadian Gold Miners -2x Daily Bear ETF',
    name: 'BetaPro Canadian Gold Miners -2x Daily Bear ETF',
    symbol: 'HGD.TO',
  },
  {
    value: 'HGU.TO',
    label: 'HGU.TO,  BetaPro Canadian Gold Miners 2x Daily Bull ETF',
    name: 'BetaPro Canadian Gold Miners 2x Daily Bull ETF',
    symbol: 'HGU.TO',
  },
  {
    value: 'HOG.TO',
    label: 'HOG.TO,  Horizons Pipelines & Energy Services Index ETF',
    name: 'Horizons Pipelines & Energy Services Index ETF',
    symbol: 'HOG.TO',
  },
  {
    value: 'HXH.TO',
    label: 'HXH.TO,  Horizons Cdn High Dividend Index ETF',
    name: 'Horizons Cdn High Dividend Index ETF',
    symbol: 'HXH.TO',
  },
  {
    value: 'HBB.TO',
    label: 'HBB.TO,  Horizons CDN Select Universe Bond ETF',
    name: 'Horizons CDN Select Universe Bond ETF',
    symbol: 'HBB.TO',
  },
  {
    value: 'HUG.TO',
    label: 'HUG.TO,  Horizons Gold ETF',
    name: 'Horizons Gold ETF',
    symbol: 'HUG.TO',
  },
  {
    value: 'HUZ.TO',
    label: 'HUZ.TO,  Horizons Silver ETF',
    name: 'Horizons Silver ETF',
    symbol: 'HUZ.TO',
  },
  {
    value: 'HGY.TO',
    label: 'HGY.TO,  Horizons Gold Yield ETF',
    name: 'Horizons Gold Yield ETF',
    symbol: 'HGY.TO',
  },
  {
    value: 'HXQ.TO',
    label: 'HXQ.TO,  Horizons NASDAQ-100 Index ETF',
    name: 'Horizons NASDAQ-100 Index ETF',
    symbol: 'HXQ.TO',
  },
  {
    value: 'HUC.TO',
    label: 'HUC.TO,  Horizons Crude Oil ETF',
    name: 'Horizons Crude Oil ETF',
    symbol: 'HUC.TO',
  },
  {
    value: 'HUN.TO',
    label: 'HUN.TO,  Horizons Natural Gas ETF',
    name: 'Horizons Natural Gas ETF',
    symbol: 'HUN.TO',
  },
  {
    value: 'HSH.TO',
    label: 'HSH.TO,  Horizons S&P 500 CAD Hedged Index ETF',
    name: 'Horizons S&P 500 CAD Hedged Index ETF',
    symbol: 'HSH.TO',
  },
  {
    value: 'HXE.TO',
    label: 'HXE.TO,  Horizons S&P/TSX Capped Energy Index ETF',
    name: 'Horizons S&P/TSX Capped Energy Index ETF',
    symbol: 'HXE.TO',
  },
  {
    value: 'HXF.TO',
    label: 'HXF.TO,  Horizons S&P/TSX Capped Financials Index ETF',
    name: 'Horizons S&P/TSX Capped Financials Index ETF',
    symbol: 'HXF.TO',
  },
  {
    value: 'HAC.TO',
    label: 'HAC.TO,  Horizons Seasonal Rotation ETF',
    name: 'Horizons Seasonal Rotation ETF',
    symbol: 'HAC.TO',
  },
  {
    value: 'HTB.TO',
    label: 'HTB.TO,  Horizons US 7-10 Year Treasury Bond ETF',
    name: 'Horizons US 7-10 Year Treasury Bond ETF',
    symbol: 'HTB.TO',
  },
  {
    value: 'DLR.TO',
    label: 'DLR.TO,  Horizons U.S. Dollar Currency ETF',
    name: 'Horizons U.S. Dollar Currency ETF',
    symbol: 'DLR.TO',
  },
  {
    value: 'HZM.TO',
    label: 'HZM.TO,  Horizonte Minerals Plc',
    name: 'Horizonte Minerals Plc',
    symbol: 'HZM.TO',
  },
  {
    value: 'HBM.TO',
    label: 'HBM.TO,  Hudbay Minerals Inc.',
    name: 'Hudbay Minerals Inc.',
    symbol: 'HBM.TO',
  },
  {
    value: 'H.TO',
    label: 'H.TO,  Hydro One Limited',
    name: 'Hydro One Limited',
    symbol: 'H.TO',
  },
  {
    value: 'IMG.TO',
    label: 'IMG.TO,  IAMGOLD Corporation',
    name: 'IAMGOLD Corporation',
    symbol: 'IMG.TO',
  },
  {
    value: 'IFA.TO',
    label: 'IFA.TO,  iFabric Corp.',
    name: 'iFabric Corp.',
    symbol: 'IFA.TO',
  },
  {
    value: 'IGM.TO',
    label: 'IGM.TO,  IGM Financial Inc.',
    name: 'IGM Financial Inc.',
    symbol: 'IGM.TO',
  },
  {
    value: 'IMV.TO',
    label: 'IMV.TO,  IMV Inc.',
    name: 'IMV Inc.',
    symbol: 'IMV.TO',
  },
  {
    value: 'III.TO',
    label: 'III.TO,  Imperial Metals Corporation',
    name: 'Imperial Metals Corporation',
    symbol: 'III.TO',
  },
  {
    value: 'IMO.TO',
    label: 'IMO.TO,  Imperial Oil Limited',
    name: 'Imperial Oil Limited',
    symbol: 'IMO.TO',
  },
  {
    value: 'INC-UN.TO',
    label: 'INC-UN.TO,  Income Financial Trust',
    name: 'Income Financial Trust',
    symbol: 'INC-UN.TO',
  },
  {
    value: 'IDG.TO',
    label: 'IDG.TO,  Indigo Books & Music Inc.',
    name: 'Indigo Books & Music Inc.',
    symbol: 'IDG.TO',
  },
  {
    value: 'IAG.TO',
    label: 'IAG.TO,  iA Financial Corporation Inc.',
    name: 'iA Financial Corporation Inc.',
    symbol: 'IAG.TO',
  },
  {
    value: 'ISV.TO',
    label: 'ISV.TO,  Information Services Corporation',
    name: 'Information Services Corporation',
    symbol: 'ISV.TO',
  },
  {
    value: 'INE.TO',
    label: 'INE.TO,  Innergex Renewable Energy Inc.',
    name: 'Innergex Renewable Energy Inc.',
    symbol: 'INE.TO',
  },
  {
    value: 'INO-UN.TO',
    label: 'INO-UN.TO,  Inovalis Real Estate Investment Trust',
    name: 'Inovalis Real Estate Investment Trust',
    symbol: 'INO-UN.TO',
  },
  {
    value: 'IPO.TO',
    label: 'IPO.TO,  InPlay Oil Corp.',
    name: 'InPlay Oil Corp.',
    symbol: 'IPO.TO',
  },
  {
    value: 'INQ.TO',
    label: 'INQ.TO,  Inscape Corporation',
    name: 'Inscape Corporation',
    symbol: 'INQ.TO',
  },
  {
    value: 'IFC.TO',
    label: 'IFC.TO,  Intact Financial Corporation',
    name: 'Intact Financial Corporation',
    symbol: 'IFC.TO',
  },
  {
    value: 'IFP.TO',
    label: 'IFP.TO,  Interfor Corporation',
    name: 'Interfor Corporation',
    symbol: 'IFP.TO',
  },
  {
    value: 'IMP.TO',
    label: 'IMP.TO,  Intermap Technologies Corporation',
    name: 'Intermap Technologies Corporation',
    symbol: 'IMP.TO',
  },
  {
    value: 'ITH.TO',
    label: 'ITH.TO,  International Tower Hill Mines Ltd.',
    name: 'International Tower Hill Mines Ltd.',
    symbol: 'ITH.TO',
  },
  {
    value: 'IIP-UN.TO',
    label: 'IIP-UN.TO,  InterRent Real Estate Investment Trust',
    name: 'InterRent Real Estate Investment Trust',
    symbol: 'IIP-UN.TO',
  },
  {
    value: 'CBH.TO',
    label: 'CBH.TO,  iShares 1-10 Year Laddered Corporate Bond Index ETF',
    name: 'iShares 1-10 Year Laddered Corporate Bond Index ETF',
    symbol: 'CBH.TO',
  },
  {
    value: 'CLG.TO',
    label: 'CLG.TO,  iShares 1-10 Year Laddered Government Bond Index ETF',
    name: 'iShares 1-10 Year Laddered Government Bond Index ETF',
    symbol: 'CLG.TO',
  },
  {
    value: 'CBO.TO',
    label: 'CBO.TO,  iShares 1-5 Year Laddered Corporate Bond Index ETF',
    name: 'iShares 1-5 Year Laddered Corporate Bond Index ETF',
    symbol: 'CBO.TO',
  },
  {
    value: 'CLF.TO',
    label: 'CLF.TO,  iShares 1-5 Year Laddered Government Bond Index ETF',
    name: 'iShares 1-5 Year Laddered Government Bond Index ETF',
    symbol: 'CLF.TO',
  },
  {
    value: 'XCB.TO',
    label: 'XCB.TO,  iShares Core Canadian Corporate Bond Index ETF',
    name: 'iShares Core Canadian Corporate Bond Index ETF',
    symbol: 'XCB.TO',
  },
  {
    value: 'FIE.TO',
    label: 'FIE.TO,  iShares Canadian Financial Monthly Income ETF',
    name: 'iShares Canadian Financial Monthly Income ETF',
    symbol: 'FIE.TO',
  },
  {
    value: 'XGB.TO',
    label: 'XGB.TO,  iShares Core Canadian Government Bond Index ETF',
    name: 'iShares Core Canadian Government Bond Index ETF',
    symbol: 'XGB.TO',
  },
  {
    value: 'XCG.TO',
    label: 'XCG.TO,  iShares Canadian Growth Index ETF',
    name: 'iShares Canadian Growth Index ETF',
    symbol: 'XCG.TO',
  },
  {
    value: 'XHB.TO',
    label: 'XHB.TO,  iShares Canadian HYBrid Corporate Bond Index ETF',
    name: 'iShares Canadian HYBrid Corporate Bond Index ETF',
    symbol: 'XHB.TO',
  },
  {
    value: 'XRB.TO',
    label: 'XRB.TO,  iShares Canadian Real Return Bond Index ETF',
    name: 'iShares Canadian Real Return Bond Index ETF',
    symbol: 'XRB.TO',
  },
  {
    value: 'XDV.TO',
    label: 'XDV.TO,  iShares Canadian Select Dividend Index ETF',
    name: 'iShares Canadian Select Dividend Index ETF',
    symbol: 'XDV.TO',
  },
  {
    value: 'XSB.TO',
    label: 'XSB.TO,  iShares Core Canadian Short Term Bond Index ETF',
    name: 'iShares Core Canadian Short Term Bond Index ETF',
    symbol: 'XSB.TO',
  },
  {
    value: 'XBB.TO',
    label: 'XBB.TO,  iShares Core Canadian Universe Bond Index ETF',
    name: 'iShares Core Canadian Universe Bond Index ETF',
    symbol: 'XBB.TO',
  },
  {
    value: 'XCV.TO',
    label: 'XCV.TO,  iShares Canadian Value Index ETF',
    name: 'iShares Canadian Value Index ETF',
    symbol: 'XCV.TO',
  },
  {
    value: 'XCH.TO',
    label: 'XCH.TO,  iShares China Index ETF',
    name: 'iShares China Index ETF',
    symbol: 'XCH.TO',
  },
  {
    value: 'XSC.TO',
    label:
      'XSC.TO,  iShares Conservative Short Term Strategic Fixed Income ETF',
    name: 'iShares Conservative Short Term Strategic Fixed Income ETF',
    symbol: 'XSC.TO',
  },
  {
    value: 'XSE.TO',
    label: 'XSE.TO,  iShares Conservative Strategic Fixed Income ETF',
    name: 'iShares Conservative Strategic Fixed Income ETF',
    symbol: 'XSE.TO',
  },
  {
    value: 'CVD.TO',
    label: 'CVD.TO,  iShares Convertible Bond Index ETF',
    name: 'iShares Convertible Bond Index ETF',
    symbol: 'CVD.TO',
  },
  {
    value: 'XLB.TO',
    label: 'XLB.TO,  iShares Core Canadian Long Term Bond Index ETF',
    name: 'iShares Core Canadian Long Term Bond Index ETF',
    symbol: 'XLB.TO',
  },
  {
    value: 'XSH.TO',
    label: 'XSH.TO,  iShares Core Canadian Short Term Corporate Bond Index ETF',
    name: 'iShares Core Canadian Short Term Corporate Bond Index ETF',
    symbol: 'XSH.TO',
  },
  {
    value: 'XQB.TO',
    label: 'XQB.TO,  iShares High Quality Canadian Bond Index ETF',
    name: 'iShares High Quality Canadian Bond Index ETF',
    symbol: 'XQB.TO',
  },
  {
    value: 'XAW.TO',
    label: 'XAW.TO,  iShares Core MSCI All Country World ex Canada Index ETF',
    name: 'iShares Core MSCI All Country World ex Canada Index ETF',
    symbol: 'XAW.TO',
  },
  {
    value: 'XFH.TO',
    label: 'XFH.TO,  iShares Core MSCI EAFE IMI Index ETF (CAD-Hedged)',
    name: 'iShares Core MSCI EAFE IMI Index ETF (CAD-Hedged)',
    symbol: 'XFH.TO',
  },
  {
    value: 'XEC.TO',
    label: 'XEC.TO,  iShares Core MSCI Emerging Markets IMI Index ETF',
    name: 'iShares Core MSCI Emerging Markets IMI Index ETF',
    symbol: 'XEC.TO',
  },
  {
    value: 'XSP.TO',
    label: 'XSP.TO,  iShares Core S&P 500 Index ETF (CAD-Hedged)',
    name: 'iShares Core S&P 500 Index ETF (CAD-Hedged)',
    symbol: 'XSP.TO',
  },
  {
    value: 'XUU.TO',
    label: 'XUU.TO,  iShares Core S&P U.S. Total Market Index ETF',
    name: 'iShares Core S&P U.S. Total Market Index ETF',
    symbol: 'XUU.TO',
  },
  {
    value: 'XUH.TO',
    label: 'XUH.TO,  iShares Core S&P U.S. Total Market Index ETF (CAD-Hedged)',
    name: 'iShares Core S&P U.S. Total Market Index ETF (CAD-Hedged)',
    symbol: 'XUH.TO',
  },
  {
    value: 'XIC.TO',
    label: 'XIC.TO,  iShares Core S&P/TSX Capped Composite Index ETF',
    name: 'iShares Core S&P/TSX Capped Composite Index ETF',
    symbol: 'XIC.TO',
  },
  {
    value: 'XEI.TO',
    label: 'XEI.TO,  iShares S&P/TSX Composite High Dividend Index ETF',
    name: 'iShares S&P/TSX Composite High Dividend Index ETF',
    symbol: 'XEI.TO',
  },
  {
    value: 'XSQ.TO',
    label: 'XSQ.TO,  iShares Short Term High Quality Canadian Bond Index ETF',
    name: 'iShares Short Term High Quality Canadian Bond Index ETF',
    symbol: 'XSQ.TO',
  },
  {
    value: 'XTR.TO',
    label: 'XTR.TO,  iShares Diversified Monthly Income ETF',
    name: 'iShares Diversified Monthly Income ETF',
    symbol: 'XTR.TO',
  },
  {
    value: 'XMV.TO',
    label: 'XMV.TO,  iShares MSCI Min Vol Canada Index ETF',
    name: 'iShares MSCI Min Vol Canada Index ETF',
    symbol: 'XMV.TO',
  },
  {
    value: 'XMI.TO',
    label: 'XMI.TO,  iShares MSCI Min Vol EAFE Index ETF',
    name: 'iShares MSCI Min Vol EAFE Index ETF',
    symbol: 'XMI.TO',
  },
  {
    value: 'XML.TO',
    label: 'XML.TO,  iSHARES MSCI MIN VOL EAFE INDEX ETF (CAD-HEDGED)',
    name: 'iSHARES MSCI MIN VOL EAFE INDEX ETF (CAD-HEDGED)',
    symbol: 'XML.TO',
  },
  {
    value: 'XMM.TO',
    label: 'XMM.TO,  iShares MSCI Min Vol Emerging Markets Index ETF',
    name: 'iShares MSCI Min Vol Emerging Markets Index ETF',
    symbol: 'XMM.TO',
  },
  {
    value: 'XMW.TO',
    label: 'XMW.TO,  iShares MSCI Min Vol Global Index ETF',
    name: 'iShares MSCI Min Vol Global Index ETF',
    symbol: 'XMW.TO',
  },
  {
    value: 'XMY.TO',
    label: 'XMY.TO,  iSHARES MSCI MIN VOL GLOBAL INDEX ETF (CAD-HEDGED)',
    name: 'iSHARES MSCI MIN VOL GLOBAL INDEX ETF (CAD-HEDGED)',
    symbol: 'XMY.TO',
  },
  {
    value: 'XMU.TO',
    label: 'XMU.TO,  iShares MSCI Min Vol USA Index ETF',
    name: 'iShares MSCI Min Vol USA Index ETF',
    symbol: 'XMU.TO',
  },
  {
    value: 'XMS.TO',
    label: 'XMS.TO,  iSHARES EDGE MSCI MIN VOL USA INDEX ETF (CAD-HEDGED)',
    name: 'iSHARES EDGE MSCI MIN VOL USA INDEX ETF (CAD-HEDGED)',
    symbol: 'XMS.TO',
  },
  {
    value: 'XFC.TO',
    label: 'XFC.TO,  iShares MSCI Multifactor Canada Index ETF',
    name: 'iShares MSCI Multifactor Canada Index ETF',
    symbol: 'XFC.TO',
  },
  {
    value: 'XFI.TO',
    label: 'XFI.TO,  iShares MSCI Multifactor EAFE Index ETF',
    name: 'iShares MSCI Multifactor EAFE Index ETF',
    symbol: 'XFI.TO',
  },
  {
    value: 'XFF.TO',
    label: 'XFF.TO,  iShares MSCI Multifactor EAFE Index ETF (CAD-Hedged)',
    name: 'iShares MSCI Multifactor EAFE Index ETF (CAD-Hedged)',
    symbol: 'XFF.TO',
  },
  {
    value: 'XFS.TO',
    label: 'XFS.TO,  iShares MSCI Multifactor USA Index ETF',
    name: 'iShares MSCI Multifactor USA Index ETF',
    symbol: 'XFS.TO',
  },
  {
    value: 'XFA.TO',
    label: 'XFA.TO,  iShares MSCI Multifactor USA Index ETF (CAD-Hedged)',
    name: 'iShares MSCI Multifactor USA Index ETF (CAD-Hedged)',
    symbol: 'XFA.TO',
  },
  {
    value: 'CEW.TO',
    label: 'CEW.TO,  iShares Equal Weight Banc & Lifeco ETF',
    name: 'iShares Equal Weight Banc & Lifeco ETF',
    symbol: 'CEW.TO',
  },
  {
    value: 'XFR.TO',
    label: 'XFR.TO,  iShares Floating Rate Index ETF',
    name: 'iShares Floating Rate Index ETF',
    symbol: 'XFR.TO',
  },
  {
    value: 'COW.TO',
    label: 'COW.TO,  iShares Global Agriculture Index ETF',
    name: 'iShares Global Agriculture Index ETF',
    symbol: 'COW.TO',
  },
  {
    value: 'XHC.TO',
    label: 'XHC.TO,  iShares Global Healthcare Index ETF (CAD-Hedged)',
    name: 'iShares Global Healthcare Index ETF (CAD-Hedged)',
    symbol: 'XHC.TO',
  },
  {
    value: 'CIF.TO',
    label: 'CIF.TO,  iShares Global Infrastructure Index ETF',
    name: 'iShares Global Infrastructure Index ETF',
    symbol: 'CIF.TO',
  },
  {
    value: 'CYH.TO',
    label: 'CYH.TO,  iShares Global Monthly Dividend Index ETF (CAD-Hedged)',
    name: 'iShares Global Monthly Dividend Index ETF (CAD-Hedged)',
    symbol: 'CYH.TO',
  },
  {
    value: 'CGR.TO',
    label: 'CGR.TO,  iShares Global Real Estate Index ETF',
    name: 'iShares Global Real Estate Index ETF',
    symbol: 'CGR.TO',
  },
  {
    value: 'CWW.TO',
    label: 'CWW.TO,  iShares Global Water Index ETF',
    name: 'iShares Global Water Index ETF',
    symbol: 'CWW.TO',
  },
  {
    value: 'CGL.TO',
    label: 'CGL.TO,  iShares Gold Bullion ETF',
    name: 'iShares Gold Bullion ETF',
    symbol: 'CGL.TO',
  },
  {
    value: 'XID.TO',
    label: 'XID.TO,  iShares India Index ETF',
    name: 'iShares India Index ETF',
    symbol: 'XID.TO',
  },
  {
    value: 'XEB.TO',
    label:
      'XEB.TO,  iShares J.P. Morgan USD Emerging Markets Bond Index ETF (CAD-Hedged)',
    name: 'iShares J.P. Morgan USD Emerging Markets Bond Index ETF (CAD-Hedged)',
    symbol: 'XEB.TO',
  },
  {
    value: 'XEN.TO',
    label: 'XEN.TO,  iShares Jantzi Social Index ETF',
    name: 'iShares Jantzi Social Index ETF',
    symbol: 'XEN.TO',
  },
  {
    value: 'XIN.TO',
    label: 'XIN.TO,  iShares MSCI EAFE Index ETF (CAD-Hedged)',
    name: 'iShares MSCI EAFE Index ETF (CAD-Hedged)',
    symbol: 'XIN.TO',
  },
  {
    value: 'XEM.TO',
    label: 'XEM.TO,  iShares MSCI Emerging Markets Index ETF',
    name: 'iShares MSCI Emerging Markets Index ETF',
    symbol: 'XEM.TO',
  },
  {
    value: 'XEU.TO',
    label: 'XEU.TO,  iShares MSCI Europe IMI Index ETF',
    name: 'iShares MSCI Europe IMI Index ETF',
    symbol: 'XEU.TO',
  },
  {
    value: 'XEH.TO',
    label: 'XEH.TO,  iShares MSCI Europe IMI Index ETF (CAD-Hedged)',
    name: 'iShares MSCI Europe IMI Index ETF (CAD-Hedged)',
    symbol: 'XEH.TO',
  },
  {
    value: 'XWD.TO',
    label: 'XWD.TO,  iShares MSCI World Index ETF',
    name: 'iShares MSCI World Index ETF',
    symbol: 'XWD.TO',
  },
  {
    value: 'XQQ.TO',
    label: 'XQQ.TO,  iShares NASDAQ 100 Index ETF (CAD-Hedged)',
    name: 'iShares NASDAQ 100 Index ETF (CAD-Hedged)',
    symbol: 'XQQ.TO',
  },
  {
    value: 'CMR.TO',
    label: 'CMR.TO,  iShares Premium Money Market ETF',
    name: 'iShares Premium Money Market ETF',
    symbol: 'CMR.TO',
  },
  {
    value: 'XCD.TO',
    label:
      'XCD.TO,  iShares S&P Global Consumer Discretionary Index ETF (CAD-Hedged)',
    name: 'iShares S&P Global Consumer Discretionary Index ETF (CAD-Hedged)',
    symbol: 'XCD.TO',
  },
  {
    value: 'XGI.TO',
    label: 'XGI.TO,  iShares S&P Global Industrials Index ETF (CAD-Hedged)',
    name: 'iShares S&P Global Industrials Index ETF (CAD-Hedged)',
    symbol: 'XGI.TO',
  },
  {
    value: 'XMC.TO',
    label: 'XMC.TO,  iShares S&P U.S. Mid-Cap Index ETF',
    name: 'iShares S&P U.S. Mid-Cap Index ETF',
    symbol: 'XMC.TO',
  },
  {
    value: 'XMH.TO',
    label: 'XMH.TO,  iShares S&P U.S. Mid-Cap Index ETF (CAD-Hedged)',
    name: 'iShares S&P U.S. Mid-Cap Index ETF (CAD-Hedged)',
    symbol: 'XMH.TO',
  },
  {
    value: 'XIU.TO',
    label: 'XIU.TO,  iShares S&P/TSX 60 Index ETF',
    name: 'iShares S&P/TSX 60 Index ETF',
    symbol: 'XIU.TO',
  },
  {
    value: 'CDZ.TO',
    label: 'CDZ.TO,  iShares S&P/TSX Canadian Dividend Aristocrats Index ETF',
    name: 'iShares S&P/TSX Canadian Dividend Aristocrats Index ETF',
    symbol: 'CDZ.TO',
  },
  {
    value: 'CPD.TO',
    label: 'CPD.TO,  iShares S&P/TSX Canadian Preferred Share Index ETF',
    name: 'iShares S&P/TSX Canadian Preferred Share Index ETF',
    symbol: 'CPD.TO',
  },
  {
    value: 'XST.TO',
    label: 'XST.TO,  iShares S&P/TSX Capped Consumer Staples Index ETF',
    name: 'iShares S&P/TSX Capped Consumer Staples Index ETF',
    symbol: 'XST.TO',
  },
  {
    value: 'XEG.TO',
    label: 'XEG.TO,  iShares S&P/TSX Capped Energy Index ETF',
    name: 'iShares S&P/TSX Capped Energy Index ETF',
    symbol: 'XEG.TO',
  },
  {
    value: 'XFN.TO',
    label: 'XFN.TO,  iShares S&P/TSX Capped Financials Index ETF',
    name: 'iShares S&P/TSX Capped Financials Index ETF',
    symbol: 'XFN.TO',
  },
  {
    value: 'XIT.TO',
    label: 'XIT.TO,  iShares S&P/TSX Capped Information Technology Index ETF',
    name: 'iShares S&P/TSX Capped Information Technology Index ETF',
    symbol: 'XIT.TO',
  },
  {
    value: 'XMA.TO',
    label: 'XMA.TO,  iShares S&P/TSX Capped Materials Index ETF',
    name: 'iShares S&P/TSX Capped Materials Index ETF',
    symbol: 'XMA.TO',
  },
  {
    value: 'XRE.TO',
    label: 'XRE.TO,  iShares S&P/TSX Capped REIT Index ETF',
    name: 'iShares S&P/TSX Capped REIT Index ETF',
    symbol: 'XRE.TO',
  },
  {
    value: 'XUT.TO',
    label: 'XUT.TO,  iShares S&P/TSX Capped Utilities Index ETF',
    name: 'iShares S&P/TSX Capped Utilities Index ETF',
    symbol: 'XUT.TO',
  },
  {
    value: 'XMD.TO',
    label: 'XMD.TO,  iShares S&P/TSX Completion Index ETF',
    name: 'iShares S&P/TSX Completion Index ETF',
    symbol: 'XMD.TO',
  },
  {
    value: 'XBM.TO',
    label: 'XBM.TO,  iShares S&P/TSX Global Base Metals Index ETF',
    name: 'iShares S&P/TSX Global Base Metals Index ETF',
    symbol: 'XBM.TO',
  },
  {
    value: 'XGD.TO',
    label: 'XGD.TO,  iShares S&P/TSX Global Gold Index ETF',
    name: 'iShares S&P/TSX Global Gold Index ETF',
    symbol: 'XGD.TO',
  },
  {
    value: 'XPF.TO',
    label:
      'XPF.TO,  iShares S&P/TSX North American Preferred Stock Index ETF (CAD-Hedged)',
    name: 'iShares S&P/TSX North American Preferred Stock Index ETF (CAD-Hedged)',
    symbol: 'XPF.TO',
  },
  {
    value: 'XCS.TO',
    label: 'XCS.TO,  iShares S&P/TSX Small Cap Index ETF',
    name: 'iShares S&P/TSX Small Cap Index ETF',
    symbol: 'XCS.TO',
  },
  {
    value: 'CSD.TO',
    label: 'CSD.TO,  iShares Short Duration High Income ETF (CAD-Hedged)',
    name: 'iShares Short Duration High Income ETF (CAD-Hedged)',
    symbol: 'CSD.TO',
  },
  {
    value: 'XSI.TO',
    label: 'XSI.TO,  iShares Short Term Strategic Fixed Income ETF',
    name: 'iShares Short Term Strategic Fixed Income ETF',
    symbol: 'XSI.TO',
  },
  {
    value: 'SVR.TO',
    label: 'SVR.TO,  iShares Silver Bullion ETF',
    name: 'iShares Silver Bullion ETF',
    symbol: 'SVR.TO',
  },
  {
    value: 'XHU.TO',
    label: 'XHU.TO,  iShares U.S. High Dividend Equity Index ETF',
    name: 'iShares U.S. High Dividend Equity Index ETF',
    symbol: 'XHU.TO',
  },
  {
    value: 'XHD.TO',
    label: 'XHD.TO,  iShares U.S. High Dividend Equity Index ETF (CAD-Hedged)',
    name: 'iShares U.S. High Dividend Equity Index ETF (CAD-Hedged)',
    symbol: 'XHD.TO',
  },
  {
    value: 'XHY.TO',
    label: 'XHY.TO,  iShares U.S. High Yield Bond Index ETF (CAD-Hedged)',
    name: 'iShares U.S. High Yield Bond Index ETF (CAD-Hedged)',
    symbol: 'XHY.TO',
  },
  {
    value: 'CHB.TO',
    label:
      'CHB.TO,  iShares U.S. High Yield Fixed Income Index ETF (CAD-Hedged)',
    name: 'iShares U.S. High Yield Fixed Income Index ETF (CAD-Hedged)',
    symbol: 'CHB.TO',
  },
  {
    value: 'XIG.TO',
    label: 'XIG.TO,  iShares U.S. IG Corporate Bond Index ETF (CAD-Hedged)',
    name: 'iShares U.S. IG Corporate Bond Index ETF (CAD-Hedged)',
    symbol: 'XIG.TO',
  },
  {
    value: 'XSU.TO',
    label: 'XSU.TO,  iShares U.S. Small Cap Index ETF (CAD-Hedged)',
    name: 'iShares U.S. Small Cap Index ETF (CAD-Hedged)',
    symbol: 'XSU.TO',
  },
  {
    value: 'CUD.TO',
    label: 'CUD.TO,  iShares US Dividend Growers Index ETF (CAD-Hedged)',
    name: 'iShares US Dividend Growers Index ETF (CAD-Hedged)',
    symbol: 'CUD.TO',
  },
  {
    value: 'IVN.TO',
    label: 'IVN.TO,  Ivanhoe Mines Ltd.',
    name: 'Ivanhoe Mines Ltd.',
    symbol: 'IVN.TO',
  },
  {
    value: 'JAG.TO',
    label: 'JAG.TO,  Jaguar Mining Inc.',
    name: 'Jaguar Mining Inc.',
    symbol: 'JAG.TO',
  },
  {
    value: 'JFS-UN.TO',
    label: 'JFS-UN.TO,  JFT Strategies Fund',
    name: 'JFT Strategies Fund',
    symbol: 'JFS-UN.TO',
  },
  {
    value: 'JOY.TO',
    label: 'JOY.TO,  Journey Energy Inc.',
    name: 'Journey Energy Inc.',
    symbol: 'JOY.TO',
  },
  {
    value: 'KBL.TO',
    label: 'KBL.TO,  K-Bro Linen Inc.',
    name: 'K-Bro Linen Inc.',
    symbol: 'KBL.TO',
  },
  {
    value: 'KRN.TO',
    label: 'KRN.TO,  Karnalyte Resources Inc.',
    name: 'Karnalyte Resources Inc.',
    symbol: 'KRN.TO',
  },
  {
    value: 'KEG-UN.TO',
    label: 'KEG-UN.TO,  The Keg Royalties Income Fund',
    name: 'The Keg Royalties Income Fund',
    symbol: 'KEG-UN.TO',
  },
  {
    value: 'KLS.TO',
    label: 'KLS.TO,  Kelso Technologies Inc.',
    name: 'Kelso Technologies Inc.',
    symbol: 'KLS.TO',
  },
  {
    value: 'KEL.TO',
    label: 'KEL.TO,  Kelt Exploration Ltd.',
    name: 'Kelt Exploration Ltd.',
    symbol: 'KEL.TO',
  },
  {
    value: 'KEY.TO',
    label: 'KEY.TO,  Keyera Corp.',
    name: 'Keyera Corp.',
    symbol: 'KEY.TO',
  },
  {
    value: 'KMP-UN.TO',
    label: 'KMP-UN.TO,  Killam Apartment REIT',
    name: 'Killam Apartment REIT',
    symbol: 'KMP-UN.TO',
  },
  {
    value: 'KXS.TO',
    label: 'KXS.TO,  Kinaxis Inc.',
    name: 'Kinaxis Inc.',
    symbol: 'KXS.TO',
  },
  {
    value: 'K.TO',
    label: 'K.TO,  Kinross Gold Corporation',
    name: 'Kinross Gold Corporation',
    symbol: 'K.TO',
  },
  {
    value: 'GUD.TO',
    label: 'GUD.TO,  Knight Therapeutics Inc.',
    name: 'Knight Therapeutics Inc.',
    symbol: 'GUD.TO',
  },
  {
    value: 'KPT.TO',
    label: 'KPT.TO,  KP Tissue Inc.',
    name: 'KP Tissue Inc.',
    symbol: 'KPT.TO',
  },
  {
    value: 'LIF.TO',
    label: 'LIF.TO,  Labrador Iron Ore Royalty Corporation',
    name: 'Labrador Iron Ore Royalty Corporation',
    symbol: 'LIF.TO',
  },
  {
    value: 'LAM.TO',
    label: 'LAM.TO,  Laramide Resources Ltd.',
    name: 'Laramide Resources Ltd.',
    symbol: 'LAM.TO',
  },
  {
    value: 'LGO.TO',
    label: 'LGO.TO,  Largo Inc.',
    name: 'Largo Inc.',
    symbol: 'LGO.TO',
  },
  {
    value: 'LAS-A.TO',
    label: 'LAS-A.TO,  Lassonde Industries Inc.',
    name: 'Lassonde Industries Inc.',
    symbol: 'LAS-A.TO',
  },
  {
    value: 'LB.TO',
    label: 'LB.TO,  Laurentian Bank of Canada',
    name: 'Laurentian Bank of Canada',
    symbol: 'LB.TO',
  },
  {
    value: 'LNF.TO',
    label: "LNF.TO,  Leon's Furniture Limited",
    name: "Leon's Furniture Limited",
    symbol: 'LNF.TO',
  },
  {
    value: 'LBS.TO',
    label: 'LBS.TO,  Life & Banc Split Corp.',
    name: 'Life & Banc Split Corp.',
    symbol: 'LBS.TO',
  },
  {
    value: 'LNR.TO',
    label: 'LNR.TO,  Linamar Corporation',
    name: 'Linamar Corporation',
    symbol: 'LNR.TO',
  },
  {
    value: 'LAC.TO',
    label: 'LAC.TO,  Lithium Americas Corp.',
    name: 'Lithium Americas Corp.',
    symbol: 'LAC.TO',
  },
  {
    value: 'L.TO',
    label: 'L.TO,  Loblaw Companies Limited',
    name: 'Loblaw Companies Limited',
    symbol: 'L.TO',
  },
  {
    value: 'LGT-B.TO',
    label: 'LGT-B.TO,  Logistec Corporation',
    name: 'Logistec Corporation',
    symbol: 'LGT-B.TO',
  },
  {
    value: 'LN.TO',
    label: 'LN.TO,  Loncor Gold Inc.',
    name: 'Loncor Gold Inc.',
    symbol: 'LN.TO',
  },
  {
    value: 'LUC.TO',
    label: 'LUC.TO,  Lucara Diamond Corp.',
    name: 'Lucara Diamond Corp.',
    symbol: 'LUC.TO',
  },
  {
    value: 'LUG.TO',
    label: 'LUG.TO,  Lundin Gold Inc.',
    name: 'Lundin Gold Inc.',
    symbol: 'LUG.TO',
  },
  {
    value: 'LUN.TO',
    label: 'LUN.TO,  Lundin Mining Corporation',
    name: 'Lundin Mining Corporation',
    symbol: 'LUN.TO',
  },
  {
    value: 'PR.TO',
    label: 'PR.TO,  Lysander-Slater Preferred Share ActivETF',
    name: 'Lysander-Slater Preferred Share ActivETF',
    symbol: 'PR.TO',
  },
  {
    value: 'XMF-A.TO',
    label: 'XMF-A.TO,  M Split Corp.',
    name: 'M Split Corp.',
    symbol: 'XMF-A.TO',
  },
  {
    value: 'MKB.TO',
    label: 'MKB.TO,  Mackenzie Core Plus Canadian Fixed Income ETF',
    name: 'Mackenzie Core Plus Canadian Fixed Income ETF',
    symbol: 'MKB.TO',
  },
  {
    value: 'MGB.TO',
    label: 'MGB.TO,  Mackenzie Core Plus Global Fixed Income ETF',
    name: 'Mackenzie Core Plus Global Fixed Income ETF',
    symbol: 'MGB.TO',
  },
  {
    value: 'MFT.TO',
    label: 'MFT.TO,  Mackenzie Floating Rate Income ETF',
    name: 'Mackenzie Floating Rate Income ETF',
    symbol: 'MFT.TO',
  },
  {
    value: 'MKZ-UN.TO',
    label: 'MKZ-UN.TO,  Mackenzie Master Limited Partnership',
    name: 'Mackenzie Master Limited Partnership',
    symbol: 'MKZ-UN.TO',
  },
  {
    value: 'MXU.TO',
    label:
      'MXU.TO,  Mackenzie Maximum Diversification All World Developed ex North America Index ETF',
    name: 'Mackenzie Maximum Diversification All World Developed ex North America Index ETF',
    symbol: 'MXU.TO',
  },
  {
    value: 'MWD.TO',
    label:
      'MWD.TO,  Mackenzie Maximum Diversification All World Developed Index ETF',
    name: 'Mackenzie Maximum Diversification All World Developed Index ETF',
    symbol: 'MWD.TO',
  },
  {
    value: 'MKC.TO',
    label: 'MKC.TO,  Mackenzie Maximum Diversification Canada Index ETF',
    name: 'Mackenzie Maximum Diversification Canada Index ETF',
    symbol: 'MKC.TO',
  },
  {
    value: 'MEU.TO',
    label:
      'MEU.TO,  Mackenzie Maximum Diversification Developed Europe Index ETF',
    name: 'Mackenzie Maximum Diversification Developed Europe Index ETF',
    symbol: 'MEU.TO',
  },
  {
    value: 'MUS.TO',
    label: 'MUS.TO,  Mackenzie Maximum Diversification US Index ETF',
    name: 'Mackenzie Maximum Diversification US Index ETF',
    symbol: 'MUS.TO',
  },
  {
    value: 'MUB.TO',
    label: 'MUB.TO,  Mackenzie Unconstrained Bond ETF',
    name: 'Mackenzie Unconstrained Bond ETF',
    symbol: 'MUB.TO',
  },
  {
    value: 'MPC.TO',
    label: 'MPC.TO,  Madison Pacific Properties Inc.',
    name: 'Madison Pacific Properties Inc.',
    symbol: 'MPC.TO',
  },
  {
    value: 'MAG.TO',
    label: 'MAG.TO,  MAG Silver Corp.',
    name: 'MAG Silver Corp.',
    symbol: 'MAG.TO',
  },
  {
    value: 'MAL.TO',
    label: 'MAL.TO,  Magellan Aerospace Corporation',
    name: 'Magellan Aerospace Corporation',
    symbol: 'MAL.TO',
  },
  {
    value: 'MG.TO',
    label: 'MG.TO,  Magna International Inc.',
    name: 'Magna International Inc.',
    symbol: 'MG.TO',
  },
  {
    value: 'MEQ.TO',
    label: 'MEQ.TO,  Mainstreet Equity Corp.',
    name: 'Mainstreet Equity Corp.',
    symbol: 'MEQ.TO',
  },
  {
    value: 'MDI.TO',
    label: 'MDI.TO,  Major Drilling Group International Inc.',
    name: 'Major Drilling Group International Inc.',
    symbol: 'MDI.TO',
  },
  {
    value: 'MND.TO',
    label: 'MND.TO,  Mandalay Resources Corporation',
    name: 'Mandalay Resources Corporation',
    symbol: 'MND.TO',
  },
  {
    value: 'MFC.TO',
    label: 'MFC.TO,  Manulife Financial Corporation',
    name: 'Manulife Financial Corporation',
    symbol: 'MFC.TO',
  },
  {
    value: 'MFI.TO',
    label: 'MFI.TO,  Maple Leaf Foods Inc.',
    name: 'Maple Leaf Foods Inc.',
    symbol: 'MFI.TO',
  },
  {
    value: 'MOZ.TO',
    label: 'MOZ.TO,  Marathon Gold Corporation',
    name: 'Marathon Gold Corporation',
    symbol: 'MOZ.TO',
  },
  {
    value: 'MRE.TO',
    label: 'MRE.TO,  Martinrea International Inc.',
    name: 'Martinrea International Inc.',
    symbol: 'MRE.TO',
  },
  {
    value: 'MAW.TO',
    label: 'MAW.TO,  Mawson Gold Limited',
    name: 'Mawson Gold Limited',
    symbol: 'MAW.TO',
  },
  {
    value: 'MXG.TO',
    label: 'MXG.TO,  Maxim Power Corp.',
    name: 'Maxim Power Corp.',
    symbol: 'MXG.TO',
  },
  {
    value: 'MKP.TO',
    label: 'MKP.TO,  MCAN Mortgage Corporation',
    name: 'MCAN Mortgage Corporation',
    symbol: 'MKP.TO',
  },
  {
    value: 'MCB.TO',
    label: 'MCB.TO,  McCoy Global Inc.',
    name: 'McCoy Global Inc.',
    symbol: 'MCB.TO',
  },
  {
    value: 'MUX.TO',
    label: 'MUX.TO,  McEwen Mining Inc.',
    name: 'McEwen Mining Inc.',
    symbol: 'MUX.TO',
  },
  {
    value: 'MDF.TO',
    label: 'MDF.TO,  mdf commerce inc.',
    name: 'mdf commerce inc.',
    symbol: 'MDF.TO',
  },
  {
    value: 'DR.TO',
    label: 'DR.TO,  Medical Facilities Corporation',
    name: 'Medical Facilities Corporation',
    symbol: 'DR.TO',
  },
  {
    value: 'MEG.TO',
    label: 'MEG.TO,  MEG Energy Corp.',
    name: 'MEG Energy Corp.',
    symbol: 'MEG.TO',
  },
  {
    value: 'MGA.TO',
    label: 'MGA.TO,  Mega Uranium Ltd.',
    name: 'Mega Uranium Ltd.',
    symbol: 'MGA.TO',
  },
  {
    value: 'MRD.TO',
    label: 'MRD.TO,  Melcor Developments Ltd.',
    name: 'Melcor Developments Ltd.',
    symbol: 'MRD.TO',
  },
  {
    value: 'MR-UN.TO',
    label: 'MR-UN.TO,  Melcor Real Estate Investment Trust',
    name: 'Melcor Real Estate Investment Trust',
    symbol: 'MR-UN.TO',
  },
  {
    value: 'MX.TO',
    label: 'MX.TO,  Methanex Corporation',
    name: 'Methanex Corporation',
    symbol: 'MX.TO',
  },
  {
    value: 'MRU.TO',
    label: 'MRU.TO,  Metro Inc.',
    name: 'Metro Inc.',
    symbol: 'MRU.TO',
  },
  {
    value: 'MBX.TO',
    label: 'MBX.TO,  Microbix Biosystems Inc.',
    name: 'Microbix Biosystems Inc.',
    symbol: 'MBX.TO',
  },
  {
    value: 'MSV.TO',
    label: 'MSV.TO,  Minco Silver Corporation',
    name: 'Minco Silver Corporation',
    symbol: 'MSV.TO',
  },
  {
    value: 'MID-UN.TO',
    label: 'MID-UN.TO,  MINT Income Fund',
    name: 'MINT Income Fund',
    symbol: 'MID-UN.TO',
  },
  {
    value: 'MOGO.TO',
    label: 'MOGO.TO,  Mogo Inc.',
    name: 'Mogo Inc.',
    symbol: 'MOGO.TO',
  },
  {
    value: 'TPX-B.TO',
    label: 'TPX-B.TO,  Molson Coors Canada Inc.',
    name: 'Molson Coors Canada Inc.',
    symbol: 'TPX-B.TO',
  },
  {
    value: 'ME.TO',
    label: 'ME.TO,  Moneta Gold Inc.',
    name: 'Moneta Gold Inc.',
    symbol: 'ME.TO',
  },
  {
    value: 'MRC.TO',
    label: 'MRC.TO,  Morguard Corporation',
    name: 'Morguard Corporation',
    symbol: 'MRC.TO',
  },
  {
    value: 'MRG-UN.TO',
    label:
      'MRG-UN.TO,  Morguard North American Residential Real Estate Investment Trust',
    name: 'Morguard North American Residential Real Estate Investment Trust',
    symbol: 'MRG-UN.TO',
  },
  {
    value: 'MRT-UN.TO',
    label: 'MRT-UN.TO,  Morguard Real Estate Investment Trust',
    name: 'Morguard Real Estate Investment Trust',
    symbol: 'MRT-UN.TO',
  },
  {
    value: 'MTY.TO',
    label: 'MTY.TO,  MTY Food Group Inc.',
    name: 'MTY Food Group Inc.',
    symbol: 'MTY.TO',
  },
  {
    value: 'MTL.TO',
    label: 'MTL.TO,  Mullen Group Ltd.',
    name: 'Mullen Group Ltd.',
    symbol: 'MTL.TO',
  },
  {
    value: 'NA.TO',
    label: 'NA.TO,  National Bank of Canada',
    name: 'National Bank of Canada',
    symbol: 'NA.TO',
  },
  {
    value: 'NCU.TO',
    label: 'NCU.TO,  Nevada Copper Corp.',
    name: 'Nevada Copper Corp.',
    symbol: 'NCU.TO',
  },
  {
    value: 'NFI.TO',
    label: 'NFI.TO,  NFI Group Inc.',
    name: 'NFI Group Inc.',
    symbol: 'NFI.TO',
  },
  {
    value: 'NGD.TO',
    label: 'NGD.TO,  New Gold Inc.',
    name: 'New Gold Inc.',
    symbol: 'NGD.TO',
  },
  {
    value: 'NXE.TO',
    label: 'NXE.TO,  NexGen Energy Ltd.',
    name: 'NexGen Energy Ltd.',
    symbol: 'NXE.TO',
  },
  {
    value: 'NXJ.TO',
    label: 'NXJ.TO,  NexJ Systems Inc.',
    name: 'NexJ Systems Inc.',
    symbol: 'NXJ.TO',
  },
  {
    value: 'NB.TO',
    label: 'NB.TO,  NioCorp Developments Ltd.',
    name: 'NioCorp Developments Ltd.',
    symbol: 'NB.TO',
  },
  {
    value: 'NIF-UN.TO',
    label: 'NIF-UN.TO,  Noranda Income Fund',
    name: 'Noranda Income Fund',
    symbol: 'NIF-UN.TO',
  },
  {
    value: 'FFN.TO',
    label: 'FFN.TO,  North American Financial 15 Split Corp.',
    name: 'North American Financial 15 Split Corp.',
    symbol: 'FFN.TO',
  },
  {
    value: 'NWC.TO',
    label: 'NWC.TO,  The North West Company Inc.',
    name: 'The North West Company Inc.',
    symbol: 'NWC.TO',
  },
  {
    value: 'NCF.TO',
    label: 'NCF.TO,  Northcliff Resources Ltd.',
    name: 'Northcliff Resources Ltd.',
    symbol: 'NCF.TO',
  },
  {
    value: 'NDM.TO',
    label: 'NDM.TO,  Northern Dynasty Minerals Ltd.',
    name: 'Northern Dynasty Minerals Ltd.',
    symbol: 'NDM.TO',
  },
  {
    value: 'NPI.TO',
    label: 'NPI.TO,  Northland Power Inc.',
    name: 'Northland Power Inc.',
    symbol: 'NPI.TO',
  },
  {
    value: 'NWH-UN.TO',
    label:
      'NWH-UN.TO,  NorthWest Healthcare Properties Real Estate Investment Trust',
    name: 'NorthWest Healthcare Properties Real Estate Investment Trust',
    symbol: 'NWH-UN.TO',
  },
  {
    value: 'NG.TO',
    label: 'NG.TO,  NovaGold Resources Inc.',
    name: 'NovaGold Resources Inc.',
    symbol: 'NG.TO',
  },
  {
    value: 'NVA.TO',
    label: 'NVA.TO,  NuVista Energy Ltd.',
    name: 'NuVista Energy Ltd.',
    symbol: 'NVA.TO',
  },
  {
    value: 'SFD.TO',
    label: 'SFD.TO,  NXT Energy Solutions Inc.',
    name: 'NXT Energy Solutions Inc.',
    symbol: 'SFD.TO',
  },
  {
    value: 'OGC.TO',
    label: 'OGC.TO,  OceanaGold Corporation',
    name: 'OceanaGold Corporation',
    symbol: 'OGC.TO',
  },
  {
    value: 'OLY.TO',
    label: 'OLY.TO,  Olympia Financial Group Inc.',
    name: 'Olympia Financial Group Inc.',
    symbol: 'OLY.TO',
  },
  {
    value: 'ONC.TO',
    label: 'ONC.TO,  Oncolytics Biotech Inc.',
    name: 'Oncolytics Biotech Inc.',
    symbol: 'ONC.TO',
  },
  {
    value: 'ONEX.TO',
    label: 'ONEX.TO,  Onex Corporation',
    name: 'Onex Corporation',
    symbol: 'ONEX.TO',
  },
  {
    value: 'OGD.TO',
    label: 'OGD.TO,  Orbit Garant Drilling Inc.',
    name: 'Orbit Garant Drilling Inc.',
    symbol: 'OGD.TO',
  },
  {
    value: 'ORV.TO',
    label: 'ORV.TO,  Orvana Minerals Corp.',
    name: 'Orvana Minerals Corp.',
    symbol: 'ORV.TO',
  },
  {
    value: 'OR.TO',
    label: 'OR.TO,  Osisko Gold Royalties Ltd',
    name: 'Osisko Gold Royalties Ltd',
    symbol: 'OR.TO',
  },
  {
    value: 'OSK.TO',
    label: 'OSK.TO,  Osisko Mining Inc.',
    name: 'Osisko Mining Inc.',
    symbol: 'OSK.TO',
  },
  {
    value: 'POU.TO',
    label: 'POU.TO,  Paramount Resources Ltd.',
    name: 'Paramount Resources Ltd.',
    symbol: 'POU.TO',
  },
  {
    value: 'PXT.TO',
    label: 'PXT.TO,  Parex Resources Inc.',
    name: 'Parex Resources Inc.',
    symbol: 'PXT.TO',
  },
  {
    value: 'PLC.TO',
    label: 'PLC.TO,  Park Lawn Corporation',
    name: 'Park Lawn Corporation',
    symbol: 'PLC.TO',
  },
  {
    value: 'PKI.TO',
    label: 'PKI.TO,  Parkland Corporation',
    name: 'Parkland Corporation',
    symbol: 'PKI.TO',
  },
  {
    value: 'PSI.TO',
    label: 'PSI.TO,  Pason Systems Inc.',
    name: 'Pason Systems Inc.',
    symbol: 'PSI.TO',
  },
  {
    value: 'PPL.TO',
    label: 'PPL.TO,  Pembina Pipeline Corporation',
    name: 'Pembina Pipeline Corporation',
    symbol: 'PPL.TO',
  },
  {
    value: 'PMT.TO',
    label: 'PMT.TO,  Perpetual Energy Inc.',
    name: 'Perpetual Energy Inc.',
    symbol: 'PMT.TO',
  },
  {
    value: 'PRU.TO',
    label: 'PRU.TO,  Perseus Mining Limited',
    name: 'Perseus Mining Limited',
    symbol: 'PRU.TO',
  },
  {
    value: 'PRQ.TO',
    label: 'PRQ.TO,  Petrus Resources Ltd.',
    name: 'Petrus Resources Ltd.',
    symbol: 'PRQ.TO',
  },
  {
    value: 'PEY.TO',
    label: 'PEY.TO,  Peyto Exploration & Development Corp.',
    name: 'Peyto Exploration & Development Corp.',
    symbol: 'PEY.TO',
  },
  {
    value: 'PHX.TO',
    label: 'PHX.TO,  PHX Energy Services Corp.',
    name: 'PHX Energy Services Corp.',
    symbol: 'PHX.TO',
  },
  {
    value: 'PMB-UN.TO',
    label: 'PMB-UN.TO,  Picton Mahoney Tactical Income Fund',
    name: 'Picton Mahoney Tactical Income Fund',
    symbol: 'PMB-UN.TO',
  },
  {
    value: 'PGI-UN.TO',
    label: 'PGI-UN.TO,  PIMCO Global Income Opportunities Fund',
    name: 'PIMCO Global Income Opportunities Fund',
    symbol: 'PGI-UN.TO',
  },
  {
    value: 'PNE.TO',
    label: 'PNE.TO,  Pine Cliff Energy Ltd.',
    name: 'Pine Cliff Energy Ltd.',
    symbol: 'PNE.TO',
  },
  {
    value: 'PNP.TO',
    label: 'PNP.TO,  Pinetree Capital Ltd.',
    name: 'Pinetree Capital Ltd.',
    symbol: 'PNP.TO',
  },
  {
    value: 'PZA.TO',
    label: 'PZA.TO,  Pizza Pizza Royalty Corp.',
    name: 'Pizza Pizza Royalty Corp.',
    symbol: 'PZA.TO',
  },
  {
    value: 'PTM.TO',
    label: 'PTM.TO,  Platinum Group Metals Ltd.',
    name: 'Platinum Group Metals Ltd.',
    symbol: 'PTM.TO',
  },
  {
    value: 'PLZ-UN.TO',
    label: 'PLZ-UN.TO,  Plaza Retail REIT',
    name: 'Plaza Retail REIT',
    symbol: 'PLZ-UN.TO',
  },
  {
    value: 'PIF.TO',
    label: 'PIF.TO,  Polaris Renewable Energy Inc.',
    name: 'Polaris Renewable Energy Inc.',
    symbol: 'PIF.TO',
  },
  {
    value: 'PBL.TO',
    label: 'PBL.TO,  Pollard Banknote Limited',
    name: 'Pollard Banknote Limited',
    symbol: 'PBL.TO',
  },
  {
    value: 'POM.TO',
    label: 'POM.TO,  PolyMet Mining Corp.',
    name: 'PolyMet Mining Corp.',
    symbol: 'POM.TO',
  },
  {
    value: 'PAY.TO',
    label: 'PAY.TO,  Payfare Inc.',
    name: 'Payfare Inc.',
    symbol: 'PAY.TO',
  },
  {
    value: 'PNC-B.TO',
    label: 'PNC-B.TO,  Postmedia Network Canada Corp.',
    name: 'Postmedia Network Canada Corp.',
    symbol: 'PNC-B.TO',
  },
  {
    value: 'POW.TO',
    label: 'POW.TO,  Power Corporation of Canada',
    name: 'Power Corporation of Canada',
    symbol: 'POW.TO',
  },
  {
    value: 'PFL.TO',
    label: 'PFL.TO,  Invesco 1-3 Year Laddered Floating Rate Note Index ETF',
    name: 'Invesco 1-3 Year Laddered Floating Rate Note Index ETF',
    symbol: 'PFL.TO',
  },
  {
    value: 'PSB.TO',
    label:
      'PSB.TO,  Invesco 1-5 Year Laddered Investment Grade Corporate Bond Index ETF',
    name: 'Invesco 1-5 Year Laddered Investment Grade Corporate Bond Index ETF',
    symbol: 'PSB.TO',
  },
  {
    value: 'PDC.TO',
    label: 'PDC.TO,  Invesco Canadian Dividend Index ETF',
    name: 'Invesco Canadian Dividend Index ETF',
    symbol: 'PDC.TO',
  },
  {
    value: 'PPS.TO',
    label: 'PPS.TO,  Invesco Canadian Preferred Share Index ETF',
    name: 'Invesco Canadian Preferred Share Index ETF',
    symbol: 'PPS.TO',
  },
  {
    value: 'PXC.TO',
    label: 'PXC.TO,  Invesco FTSE RAFI Canadian Index ETF',
    name: 'Invesco FTSE RAFI Canadian Index ETF',
    symbol: 'PXC.TO',
  },
  {
    value: 'PZC.TO',
    label: 'PZC.TO,  Invesco FTSE RAFI Canadian Small-Mid Index ETF',
    name: 'Invesco FTSE RAFI Canadian Small-Mid Index ETF',
    symbol: 'PZC.TO',
  },
  {
    value: 'PZW.TO',
    label: 'PZW.TO,  Invesco FTSE RAFI Global Small-Mid ETF',
    name: 'Invesco FTSE RAFI Global Small-Mid ETF',
    symbol: 'PZW.TO',
  },
  {
    value: 'PXG.TO',
    label: 'PXG.TO,  Invesco FTSE RAFI Global+ Index ETF',
    name: 'Invesco FTSE RAFI Global+ Index ETF',
    symbol: 'PXG.TO',
  },
  {
    value: 'PXS.TO',
    label: 'PXS.TO,  Invesco FTSE RAFI U.S. Index ETF II CAD',
    name: 'Invesco FTSE RAFI U.S. Index ETF II CAD',
    symbol: 'PXS.TO',
  },
  {
    value: 'PSY.TO',
    label: 'PSY.TO,  Invesco Global Shareholder Yield ETF CAD',
    name: 'Invesco Global Shareholder Yield ETF CAD',
    symbol: 'PSY.TO',
  },
  {
    value: 'USB.TO',
    label: 'USB.TO,  Invesco LadderRite U.S. 0-5 Year Corporate Bond Index ETF',
    name: 'Invesco LadderRite U.S. 0-5 Year Corporate Bond Index ETF',
    symbol: 'USB.TO',
  },
  {
    value: 'PLV.TO',
    label: 'PLV.TO,  Invesco Low Volatility Portfolio ETF',
    name: 'Invesco Low Volatility Portfolio ETF',
    symbol: 'PLV.TO',
  },
  {
    value: 'ELV.TO',
    label: 'ELV.TO,  Invesco S&P Emerging Markets Low Volatility Index ETF',
    name: 'Invesco S&P Emerging Markets Low Volatility Index ETF',
    symbol: 'ELV.TO',
  },
  {
    value: 'ILV.TO',
    label:
      'ILV.TO,  Invesco S&P International Developed Low Volatility Index ETF',
    name: 'Invesco S&P International Developed Low Volatility Index ETF',
    symbol: 'ILV.TO',
  },
  {
    value: 'TLV.TO',
    label: 'TLV.TO,  Invesco S&P/TSX Composite Low Volatility Index ETF',
    name: 'Invesco S&P/TSX Composite Low Volatility Index ETF',
    symbol: 'TLV.TO',
  },
  {
    value: 'PPR.TO',
    label: 'PPR.TO,  Prairie Provident Resources Inc.',
    name: 'Prairie Provident Resources Inc.',
    symbol: 'PPR.TO',
  },
  {
    value: 'PSK.TO',
    label: 'PSK.TO,  PrairieSky Royalty Ltd.',
    name: 'PrairieSky Royalty Ltd.',
    symbol: 'PSK.TO',
  },
  {
    value: 'MMP-UN.TO',
    label: 'MMP-UN.TO,  Precious Metals and Mining Trust',
    name: 'Precious Metals and Mining Trust',
    symbol: 'MMP-UN.TO',
  },
  {
    value: 'PD.TO',
    label: 'PD.TO,  Precision Drilling Corporation',
    name: 'Precision Drilling Corporation',
    symbol: 'PD.TO',
  },
  {
    value: 'PBH.TO',
    label: 'PBH.TO,  Premium Brands Holdings Corporation',
    name: 'Premium Brands Holdings Corporation',
    symbol: 'PBH.TO',
  },
  {
    value: 'PIC-A.TO',
    label: 'PIC-A.TO,  Premium Income Corporation',
    name: 'Premium Income Corporation',
    symbol: 'PIC-A.TO',
  },
  {
    value: 'PDV.TO',
    label: 'PDV.TO,  Prime Dividend Corp.',
    name: 'Prime Dividend Corp.',
    symbol: 'PDV.TO',
  },
  {
    value: 'PMN.TO',
    label: 'PMN.TO,  ProMIS Neurosciences, Inc.',
    name: 'ProMIS Neurosciences, Inc.',
    symbol: 'PMN.TO',
  },
  {
    value: 'PSD.TO',
    label: 'PSD.TO,  Pulse Seismic Inc.',
    name: 'Pulse Seismic Inc.',
    symbol: 'PSD.TO',
  },
  {
    value: 'PBI.TO',
    label: 'PBI.TO,  Purpose Best Ideas Fund',
    name: 'Purpose Best Ideas Fund',
    symbol: 'PBI.TO',
  },
  {
    value: 'BNC.TO',
    label: 'BNC.TO,  Purpose Canadian Financial Income Fund',
    name: 'Purpose Canadian Financial Income Fund',
    symbol: 'BNC.TO',
  },
  {
    value: 'PDF.TO',
    label: 'PDF.TO,  Purpose Core Dividend Fund Series ETF',
    name: 'Purpose Core Dividend Fund Series ETF',
    symbol: 'PDF.TO',
  },
  {
    value: 'PRA.TO',
    label: 'PRA.TO,  Purpose Diversified Real Asset Fund',
    name: 'Purpose Diversified Real Asset Fund',
    symbol: 'PRA.TO',
  },
  {
    value: 'PHR.TO',
    label: 'PHR.TO,  Purpose Real Estate Income Fund',
    name: 'Purpose Real Estate Income Fund',
    symbol: 'PHR.TO',
  },
  {
    value: 'PSA.TO',
    label: 'PSA.TO,  Purpose High Interest Savings ETF',
    name: 'Purpose High Interest Savings ETF',
    symbol: 'PSA.TO',
  },
  {
    value: 'PID.TO',
    label: 'PID.TO,  Purpose International Dividend Fund',
    name: 'Purpose International Dividend Fund',
    symbol: 'PID.TO',
  },
  {
    value: 'PIN.TO',
    label: 'PIN.TO,  Purpose Monthly Income Fund',
    name: 'Purpose Monthly Income Fund',
    symbol: 'PIN.TO',
  },
  {
    value: 'PMM.TO',
    label: 'PMM.TO,  Purpose Multi-Strategy Market Neutral Fund',
    name: 'Purpose Multi-Strategy Market Neutral Fund',
    symbol: 'PMM.TO',
  },
  {
    value: 'PYF.TO',
    label: 'PYF.TO,  Purpose Premium Yield Fund',
    name: 'Purpose Premium Yield Fund',
    symbol: 'PYF.TO',
  },
  {
    value: 'PHE.TO',
    label: 'PHE.TO,  Purpose Tactical Hedged Equity Fund',
    name: 'Purpose Tactical Hedged Equity Fund',
    symbol: 'PHE.TO',
  },
  {
    value: 'BND.TO',
    label: 'BND.TO,  Purpose Global Bond Fund',
    name: 'Purpose Global Bond Fund',
    symbol: 'BND.TO',
  },
  {
    value: 'PBD.TO',
    label: 'PBD.TO,  Purpose Total Return Bond Fund',
    name: 'Purpose Total Return Bond Fund',
    symbol: 'PBD.TO',
  },
  {
    value: 'PSU-U.TO',
    label: 'PSU-U.TO,  Purpose US Cash Fund',
    name: 'Purpose US Cash Fund',
    symbol: 'PSU-U.TO',
  },
  {
    value: 'PUD.TO',
    label: 'PUD.TO,  Purpose US Dividend Fund',
    name: 'Purpose US Dividend Fund',
    symbol: 'PUD.TO',
  },
  {
    value: 'QBR-B.TO',
    label: 'QBR-B.TO,  Quebecor Inc.',
    name: 'Quebecor Inc.',
    symbol: 'QBR-B.TO',
  },
  {
    value: 'QEC.TO',
    label: 'QEC.TO,  Questerre Energy Corporation',
    name: 'Questerre Energy Corporation',
    symbol: 'QEC.TO',
  },
  {
    value: 'RAV-UN.TO',
    label: 'RAV-UN.TO,  Ravensource Fund',
    name: 'Ravensource Fund',
    symbol: 'RAV-UN.TO',
  },
  {
    value: 'RLB.TO',
    label: 'RLB.TO,  RBC 1-5 Year Laddered Canadian Bond ETF',
    name: 'RBC 1-5 Year Laddered Canadian Bond ETF',
    symbol: 'RLB.TO',
  },
  {
    value: 'RBO.TO',
    label: 'RBO.TO,  RBC 1-5 Year Laddered Corporate Bond ETF',
    name: 'RBC 1-5 Year Laddered Corporate Bond ETF',
    symbol: 'RBO.TO',
  },
  {
    value: 'RPF.TO',
    label: 'RPF.TO,  RBC Canadian Preferred Share ETF',
    name: 'RBC Canadian Preferred Share ETF',
    symbol: 'RPF.TO',
  },
  {
    value: 'RCD.TO',
    label: 'RCD.TO,  RBC Quant Canadian Dividend Leaders ETF',
    name: 'RBC Quant Canadian Dividend Leaders ETF',
    symbol: 'RCD.TO',
  },
  {
    value: 'RID.TO',
    label: 'RID.TO,  RBC Quant EAFE Dividend Leaders ETF',
    name: 'RBC Quant EAFE Dividend Leaders ETF',
    symbol: 'RID.TO',
  },
  {
    value: 'RIE.TO',
    label: 'RIE.TO,  RBC Quant EAFE Equity Leaders ETF',
    name: 'RBC Quant EAFE Equity Leaders ETF',
    symbol: 'RIE.TO',
  },
  {
    value: 'RXD.TO',
    label: 'RXD.TO,  RBC Quant Emerging Markets Dividend Leaders ETF',
    name: 'RBC Quant Emerging Markets Dividend Leaders ETF',
    symbol: 'RXD.TO',
  },
  {
    value: 'RPD.TO',
    label: 'RPD.TO,  RBC Quant European Dividend Leaders ETF',
    name: 'RBC Quant European Dividend Leaders ETF',
    symbol: 'RPD.TO',
  },
  {
    value: 'RUD.TO',
    label: 'RUD.TO,  RBC Quant U.S. Dividend Leaders ETF',
    name: 'RBC Quant U.S. Dividend Leaders ETF',
    symbol: 'RUD.TO',
  },
  {
    value: 'RUE.TO',
    label: 'RUE.TO,  RBC Quant U.S. Equity Leaders ETF',
    name: 'RBC Quant U.S. Equity Leaders ETF',
    symbol: 'RUE.TO',
  },
  {
    value: 'RQJ.TO',
    label: 'RQJ.TO,  RBC Target 2022 Corporate Bond Index ETF',
    name: 'RBC Target 2022 Corporate Bond Index ETF',
    symbol: 'RQJ.TO',
  },
  {
    value: 'RQK.TO',
    label: 'RQK.TO,  RBC Target 2023 Corporate Bond Index ETF',
    name: 'RBC Target 2023 Corporate Bond Index ETF',
    symbol: 'RQK.TO',
  },
  {
    value: 'RFP.TO',
    label: 'RFP.TO,  Resolute Forest Products Inc.',
    name: 'Resolute Forest Products Inc.',
    symbol: 'RFP.TO',
  },
  {
    value: 'QSR.TO',
    label: 'QSR.TO,  Restaurant Brands International Inc.',
    name: 'Restaurant Brands International Inc.',
    symbol: 'QSR.TO',
  },
  {
    value: 'QSP-UN.TO',
    label: 'QSP-UN.TO,  Restaurant Brands International Limited Partnership',
    name: 'Restaurant Brands International Limited Partnership',
    symbol: 'QSP-UN.TO',
  },
  {
    value: 'RVX.TO',
    label: 'RVX.TO,  Resverlogix Corp.',
    name: 'Resverlogix Corp.',
    symbol: 'RVX.TO',
  },
  {
    value: 'RPI-UN.TO',
    label: 'RPI-UN.TO,  Richards Packaging Income Fund',
    name: 'Richards Packaging Income Fund',
    symbol: 'RPI-UN.TO',
  },
  {
    value: 'RCH.TO',
    label: 'RCH.TO,  Richelieu Hardware Ltd.',
    name: 'Richelieu Hardware Ltd.',
    symbol: 'RCH.TO',
  },
  {
    value: 'RIB-UN.TO',
    label: 'RIB-UN.TO,  Ridgewood Canadian Investment Grade Bond Fund',
    name: 'Ridgewood Canadian Investment Grade Bond Fund',
    symbol: 'RIB-UN.TO',
  },
  {
    value: 'REI-UN.TO',
    label: 'REI-UN.TO,  RioCan Real Estate Investment Trust',
    name: 'RioCan Real Estate Investment Trust',
    symbol: 'REI-UN.TO',
  },
  {
    value: 'RBA.TO',
    label: 'RBA.TO,  Ritchie Bros. Auctioneers Incorporated',
    name: 'Ritchie Bros. Auctioneers Incorporated',
    symbol: 'RBA.TO',
  },
  {
    value: 'RCI-B.TO',
    label: 'RCI-B.TO,  Rogers Communications Inc.',
    name: 'Rogers Communications Inc.',
    symbol: 'RCI-B.TO',
  },
  {
    value: 'RSI.TO',
    label: 'RSI.TO,  Rogers Sugar Inc.',
    name: 'Rogers Sugar Inc.',
    symbol: 'RSI.TO',
  },
  {
    value: 'RY.TO',
    label: 'RY.TO,  Royal Bank of Canada',
    name: 'Royal Bank of Canada',
    symbol: 'RY.TO',
  },
  {
    value: 'RTG.TO',
    label: 'RTG.TO,  RTG Mining Inc.',
    name: 'RTG Mining Inc.',
    symbol: 'RTG.TO',
  },
  {
    value: 'RUS.TO',
    label: 'RUS.TO,  Russel Metals Inc.',
    name: 'Russel Metals Inc.',
    symbol: 'RUS.TO',
  },
  {
    value: 'SBN.TO',
    label: 'SBN.TO,  Mulvihill S Split Corp.',
    name: 'Mulvihill S Split Corp.',
    symbol: 'SBN.TO',
  },
  {
    value: 'SBB.TO',
    label: 'SBB.TO,  Sabina Gold & Silver Corp.',
    name: 'Sabina Gold & Silver Corp.',
    symbol: 'SBB.TO',
  },
  {
    value: 'SSL.TO',
    label: 'SSL.TO,  Sandstorm Gold Ltd.',
    name: 'Sandstorm Gold Ltd.',
    symbol: 'SSL.TO',
  },
  {
    value: 'SAP.TO',
    label: 'SAP.TO,  Saputo Inc.',
    name: 'Saputo Inc.',
    symbol: 'SAP.TO',
  },
  {
    value: 'SIS.TO',
    label: 'SIS.TO,  Savaria Corporation',
    name: 'Savaria Corporation',
    symbol: 'SIS.TO',
  },
  {
    value: 'SCY.TO',
    label: 'SCY.TO,  Scandium International Mining Corp.',
    name: 'Scandium International Mining Corp.',
    symbol: 'SCY.TO',
  },
  {
    value: 'SEA.TO',
    label: 'SEA.TO,  Seabridge Gold Inc.',
    name: 'Seabridge Gold Inc.',
    symbol: 'SEA.TO',
  },
  {
    value: 'SES.TO',
    label: 'SES.TO,  Secure Energy Services Inc.',
    name: 'Secure Energy Services Inc.',
    symbol: 'SES.TO',
  },
  {
    value: 'PME.TO',
    label: 'PME.TO,  Sentry Select Primary Metals Corp.',
    name: 'Sentry Select Primary Metals Corp.',
    symbol: 'PME.TO',
  },
  {
    value: 'SEC.TO',
    label: 'SEC.TO,  Senvest Capital Inc.',
    name: 'Senvest Capital Inc.',
    symbol: 'SEC.TO',
  },
  {
    value: 'SBI.TO',
    label: 'SBI.TO,  Serabi Gold plc',
    name: 'Serabi Gold plc',
    symbol: 'SBI.TO',
  },
  {
    value: 'SJR-B.TO',
    label: 'SJR-B.TO,  Shaw Communications Inc.',
    name: 'Shaw Communications Inc.',
    symbol: 'SJR-B.TO',
  },
  {
    value: 'SCL.TO',
    label: 'SCL.TO,  Shawcor Ltd.',
    name: 'Shawcor Ltd.',
    symbol: 'SCL.TO',
  },
  {
    value: 'S.TO',
    label: 'S.TO,  Sherritt International Corporation',
    name: 'Sherritt International Corporation',
    symbol: 'S.TO',
  },
  {
    value: 'SHOP.TO',
    label: 'SHOP.TO,  Shopify Inc.',
    name: 'Shopify Inc.',
    symbol: 'SHOP.TO',
  },
  {
    value: 'SIA.TO',
    label: 'SIA.TO,  Sienna Senior Living Inc.',
    name: 'Sienna Senior Living Inc.',
    symbol: 'SIA.TO',
  },
  {
    value: 'SMT.TO',
    label: 'SMT.TO,  Sierra Metals Inc.',
    name: 'Sierra Metals Inc.',
    symbol: 'SMT.TO',
  },
  {
    value: 'SW.TO',
    label: 'SW.TO,  Sierra Wireless, Inc.',
    name: 'Sierra Wireless, Inc.',
    symbol: 'SW.TO',
  },
  {
    value: 'SVM.TO',
    label: 'SVM.TO,  Silvercorp Metals Inc.',
    name: 'Silvercorp Metals Inc.',
    symbol: 'SVM.TO',
  },
  {
    value: 'SRV-UN.TO',
    label: 'SRV-UN.TO,  SIR Royalty Income Fund',
    name: 'SIR Royalty Income Fund',
    symbol: 'SRV-UN.TO',
  },
  {
    value: 'SOT-UN.TO',
    label: 'SOT-UN.TO,  Slate Office REIT',
    name: 'Slate Office REIT',
    symbol: 'SOT-UN.TO',
  },
  {
    value: 'ZZZ.TO',
    label: 'ZZZ.TO,  Sleep Country Canada Holdings Inc.',
    name: 'Sleep Country Canada Holdings Inc.',
    symbol: 'ZZZ.TO',
  },
  {
    value: 'SRU-UN.TO',
    label: 'SRU-UN.TO,  SmartCentres Real Estate Investment Trust',
    name: 'SmartCentres Real Estate Investment Trust',
    symbol: 'SRU-UN.TO',
  },
  {
    value: 'SNC.TO',
    label: 'SNC.TO,  SNC-Lavalin Group Inc.',
    name: 'SNC-Lavalin Group Inc.',
    symbol: 'SNC.TO',
  },
  {
    value: 'SLR.TO',
    label: 'SLR.TO,  Solitario Zinc Corp.',
    name: 'Solitario Zinc Corp.',
    symbol: 'SLR.TO',
  },
  {
    value: 'SGQ.TO',
    label: 'SGQ.TO,  SouthGobi Resources Ltd.',
    name: 'SouthGobi Resources Ltd.',
    symbol: 'SGQ.TO',
  },
  {
    value: 'EDT.TO',
    label: 'EDT.TO,  Spectral Medical Inc.',
    name: 'Spectral Medical Inc.',
    symbol: 'EDT.TO',
  },
  {
    value: 'TOY.TO',
    label: 'TOY.TO,  Spin Master Corp.',
    name: 'Spin Master Corp.',
    symbol: 'TOY.TO',
  },
  {
    value: 'SII.TO',
    label: 'SII.TO,  Sprott Inc.',
    name: 'Sprott Inc.',
    symbol: 'SII.TO',
  },
  {
    value: 'SAU.TO',
    label: 'SAU.TO,  St. Augustine Gold and Copper Limited',
    name: 'St. Augustine Gold and Copper Limited',
    symbol: 'SAU.TO',
  },
  {
    value: 'STN.TO',
    label: 'STN.TO,  Stantec Inc.',
    name: 'Stantec Inc.',
    symbol: 'STN.TO',
  },
  {
    value: 'SAM.TO',
    label: 'SAM.TO,  Starcore International Mines Ltd.',
    name: 'Starcore International Mines Ltd.',
    symbol: 'SAM.TO',
  },
  {
    value: 'SJ.TO',
    label: 'SJ.TO,  Stella-Jones Inc.',
    name: 'Stella-Jones Inc.',
    symbol: 'SJ.TO',
  },
  {
    value: 'RAY-B.TO',
    label: 'RAY-B.TO,  Stingray Group Inc.',
    name: 'Stingray Group Inc.',
    symbol: 'RAY-B.TO',
  },
  {
    value: 'SMC.TO',
    label: 'SMC.TO,  Sulliden Mining Capital Inc.',
    name: 'Sulliden Mining Capital Inc.',
    symbol: 'SMC.TO',
  },
  {
    value: 'SMU-UN.TO',
    label: 'SMU-UN.TO,  Summit Industrial Income REIT',
    name: 'Summit Industrial Income REIT',
    symbol: 'SMU-UN.TO',
  },
  {
    value: 'SLF.TO',
    label: 'SLF.TO,  Sun Life Financial Inc.',
    name: 'Sun Life Financial Inc.',
    symbol: 'SLF.TO',
  },
  {
    value: 'SU.TO',
    label: 'SU.TO,  Suncor Energy Inc.',
    name: 'Suncor Energy Inc.',
    symbol: 'SU.TO',
  },
  {
    value: 'SOY.TO',
    label: 'SOY.TO,  SunOpta Inc.',
    name: 'SunOpta Inc.',
    symbol: 'SOY.TO',
  },
  {
    value: 'SPB.TO',
    label: 'SPB.TO,  Superior Plus Corp.',
    name: 'Superior Plus Corp.',
    symbol: 'SPB.TO',
  },
  {
    value: 'SXP.TO',
    label: 'SXP.TO,  Supremex Inc.',
    name: 'Supremex Inc.',
    symbol: 'SXP.TO',
  },
  {
    value: 'SGY.TO',
    label: 'SGY.TO,  Surge Energy Inc.',
    name: 'Surge Energy Inc.',
    symbol: 'SGY.TO',
  },
  {
    value: 'SSF-UN.TO',
    label: 'SSF-UN.TO,  Symphony Floating Rate Senior Loan Fund',
    name: 'Symphony Floating Rate Senior Loan Fund',
    symbol: 'SSF-UN.TO',
  },
  {
    value: 'SXI.TO',
    label: 'SXI.TO,  Synex Renewable Energy Corporation',
    name: 'Synex Renewable Energy Corporation',
    symbol: 'SXI.TO',
  },
  {
    value: 'TBL.TO',
    label: 'TBL.TO,  Taiga Building Products Ltd.',
    name: 'Taiga Building Products Ltd.',
    symbol: 'TBL.TO',
  },
  {
    value: 'TLO.TO',
    label: 'TLO.TO,  Talon Metals Corp.',
    name: 'Talon Metals Corp.',
    symbol: 'TLO.TO',
  },
  {
    value: 'TVE.TO',
    label: 'TVE.TO,  Tamarack Valley Energy Ltd.',
    name: 'Tamarack Valley Energy Ltd.',
    symbol: 'TVE.TO',
  },
  {
    value: 'TNX.TO',
    label: 'TNX.TO,  TRX Gold Corporation',
    name: 'TRX Gold Corporation',
    symbol: 'TNX.TO',
  },
  {
    value: 'TKO.TO',
    label: 'TKO.TO,  Taseko Mines Limited',
    name: 'Taseko Mines Limited',
    symbol: 'TKO.TO',
  },
  {
    value: 'TDB.TO',
    label: 'TDB.TO,  TD Canadian Aggregate Bond Index ETF',
    name: 'TD Canadian Aggregate Bond Index ETF',
    symbol: 'TDB.TO',
  },
  {
    value: 'THE.TO',
    label: 'THE.TO,  TD International Equity CAD Hedged Index ETF',
    name: 'TD International Equity CAD Hedged Index ETF',
    symbol: 'THE.TO',
  },
  {
    value: 'TPE.TO',
    label: 'TPE.TO,  TD International Equity Index ETF',
    name: 'TD International Equity Index ETF',
    symbol: 'TPE.TO',
  },
  {
    value: 'THU.TO',
    label: 'THU.TO,  TD U.S. Equity CAD Hedged Index ETF',
    name: 'TD U.S. Equity CAD Hedged Index ETF',
    symbol: 'THU.TO',
  },
  {
    value: 'TPU.TO',
    label: 'TPU.TO,  TD U.S. Equity Index ETF',
    name: 'TD U.S. Equity Index ETF',
    symbol: 'TPU.TO',
  },
  {
    value: 'TTP.TO',
    label: 'TTP.TO,  TD Canadian Equity Index ETF',
    name: 'TD Canadian Equity Index ETF',
    symbol: 'TTP.TO',
  },
  {
    value: 'XTD.TO',
    label: 'XTD.TO,  TDb Split Corp.',
    name: 'TDb Split Corp.',
    symbol: 'XTD.TO',
  },
  {
    value: 'TCS.TO',
    label: 'TCS.TO,  Tecsys Inc.',
    name: 'Tecsys Inc.',
    symbol: 'TCS.TO',
  },
  {
    value: 'T.TO',
    label: 'T.TO,  TELUS Corporation',
    name: 'TELUS Corporation',
    symbol: 'T.TO',
  },
  {
    value: 'TGO.TO',
    label: 'TGO.TO,  TeraGo Inc.',
    name: 'TeraGo Inc.',
    symbol: 'TGO.TO',
  },
  {
    value: 'TVK.TO',
    label: 'TVK.TO,  TerraVest Industries Inc.',
    name: 'TerraVest Industries Inc.',
    symbol: 'TVK.TO',
  },
  {
    value: 'TH.TO',
    label: 'TH.TO,  Theratechnologies Inc.',
    name: 'Theratechnologies Inc.',
    symbol: 'TH.TO',
  },
  {
    value: 'TRI.TO',
    label: 'TRI.TO,  Thomson Reuters Corporation',
    name: 'Thomson Reuters Corporation',
    symbol: 'TRI.TO',
  },
  {
    value: 'TF.TO',
    label: 'TF.TO,  Timbercreek Financial Corp.',
    name: 'Timbercreek Financial Corp.',
    symbol: 'TF.TO',
  },
  {
    value: 'TMD.TO',
    label: 'TMD.TO,  Titan Medical Inc.',
    name: 'Titan Medical Inc.',
    symbol: 'TMD.TO',
  },
  {
    value: 'X.TO',
    label: 'X.TO,  TMX Group Limited',
    name: 'TMX Group Limited',
    symbol: 'X.TO',
  },
  {
    value: 'TXT-UN.TO',
    label: 'TXT-UN.TO,  Top 10 Split Trust',
    name: 'Top 10 Split Trust',
    symbol: 'TXT-UN.TO',
  },
  {
    value: 'TXG.TO',
    label: 'TXG.TO,  Torex Gold Resources Inc.',
    name: 'Torex Gold Resources Inc.',
    symbol: 'TXG.TO',
  },
  {
    value: 'TIH.TO',
    label: 'TIH.TO,  Toromont Industries Ltd.',
    name: 'Toromont Industries Ltd.',
    symbol: 'TIH.TO',
  },
  {
    value: 'TD.TO',
    label: 'TD.TO,  The Toronto-Dominion Bank',
    name: 'The Toronto-Dominion Bank',
    symbol: 'TD.TO',
  },
  {
    value: 'TOT.TO',
    label: 'TOT.TO,  Total Energy Services Inc.',
    name: 'Total Energy Services Inc.',
    symbol: 'TOT.TO',
  },
  {
    value: 'TXP.TO',
    label: 'TXP.TO,  Touchstone Exploration Inc.',
    name: 'Touchstone Exploration Inc.',
    symbol: 'TXP.TO',
  },
  {
    value: 'TOU.TO',
    label: 'TOU.TO,  Tourmaline Oil Corp.',
    name: 'Tourmaline Oil Corp.',
    symbol: 'TOU.TO',
  },
  {
    value: 'TA.TO',
    label: 'TA.TO,  TransAlta Corporation',
    name: 'TransAlta Corporation',
    symbol: 'TA.TO',
  },
  {
    value: 'RNW.TO',
    label: 'RNW.TO,  TransAlta Renewables Inc.',
    name: 'TransAlta Renewables Inc.',
    symbol: 'RNW.TO',
  },
  {
    value: 'TRZ.TO',
    label: 'TRZ.TO,  Transat A.T. Inc.',
    name: 'Transat A.T. Inc.',
    symbol: 'TRZ.TO',
  },
  {
    value: 'TRP.TO',
    label: 'TRP.TO,  TC Energy Corporation',
    name: 'TC Energy Corporation',
    symbol: 'TRP.TO',
  },
  {
    value: 'TCL-A.TO',
    label: 'TCL-A.TO,  Transcontinental Inc.',
    name: 'Transcontinental Inc.',
    symbol: 'TCL-A.TO',
  },
  {
    value: 'TGL.TO',
    label: 'TGL.TO,  TransGlobe Energy Corporation',
    name: 'TransGlobe Energy Corporation',
    symbol: 'TGL.TO',
  },
  {
    value: 'TML.TO',
    label: 'TML.TO,  Treasury Metals Inc.',
    name: 'Treasury Metals Inc.',
    symbol: 'TML.TO',
  },
  {
    value: 'TSL.TO',
    label: 'TSL.TO,  Tree Island Steel Ltd.',
    name: 'Tree Island Steel Ltd.',
    symbol: 'TSL.TO',
  },
  {
    value: 'TCW.TO',
    label: 'TCW.TO,  Trican Well Service Ltd.',
    name: 'Trican Well Service Ltd.',
    symbol: 'TCW.TO',
  },
  {
    value: 'TCN.TO',
    label: 'TCN.TO,  Tricon Residential Inc.',
    name: 'Tricon Residential Inc.',
    symbol: 'TCN.TO',
  },
  {
    value: 'TMQ.TO',
    label: 'TMQ.TO,  Trilogy Metals Inc.',
    name: 'Trilogy Metals Inc.',
    symbol: 'TMQ.TO',
  },
  {
    value: 'TNT-UN.TO',
    label: 'TNT-UN.TO,  True North Commercial Real Estate Investment Trust',
    name: 'True North Commercial Real Estate Investment Trust',
    symbol: 'TNT-UN.TO',
  },
  {
    value: 'TC.TO',
    label: 'TC.TO,  Tucows Inc.',
    name: 'Tucows Inc.',
    symbol: 'TC.TO',
  },
  {
    value: 'TRQ.TO',
    label: 'TRQ.TO,  Turquoise Hill Resources Ltd.',
    name: 'Turquoise Hill Resources Ltd.',
    symbol: 'TRQ.TO',
  },
  {
    value: 'TVA-B.TO',
    label: 'TVA-B.TO,  TVA Group Inc.',
    name: 'TVA Group Inc.',
    symbol: 'TVA-B.TO',
  },
  {
    value: 'TWC.TO',
    label: 'TWC.TO,  TWC Enterprises Limited',
    name: 'TWC Enterprises Limited',
    symbol: 'TWC.TO',
  },
  {
    value: 'UNS.TO',
    label: 'UNS.TO,  Uni-Select Inc.',
    name: 'Uni-Select Inc.',
    symbol: 'UNS.TO',
  },
  {
    value: 'UNC.TO',
    label: 'UNC.TO,  United Corporations Limited',
    name: 'United Corporations Limited',
    symbol: 'UNC.TO',
  },
  {
    value: 'URE.TO',
    label: 'URE.TO,  Ur-Energy Inc.',
    name: 'Ur-Energy Inc.',
    symbol: 'URE.TO',
  },
  {
    value: 'URB.TO',
    label: 'URB.TO,  Urbana Corporation',
    name: 'Urbana Corporation',
    symbol: 'URB.TO',
  },
  {
    value: 'FTU.TO',
    label: 'FTU.TO,  US Financial 15 Split Corp',
    name: 'US Financial 15 Split Corp',
    symbol: 'FTU.TO',
  },
  {
    value: 'VLE.TO',
    label: 'VLE.TO,  Valeura Energy Inc.',
    name: 'Valeura Energy Inc.',
    symbol: 'VLE.TO',
  },
  {
    value: 'VAB.TO',
    label: 'VAB.TO,  Vanguard Canadian Aggregate Bond Index ETF',
    name: 'Vanguard Canadian Aggregate Bond Index ETF',
    symbol: 'VAB.TO',
  },
  {
    value: 'VSB.TO',
    label: 'VSB.TO,  Vanguard Canadian Short-Term Bond Index ETF',
    name: 'Vanguard Canadian Short-Term Bond Index ETF',
    symbol: 'VSB.TO',
  },
  {
    value: 'VSC.TO',
    label: 'VSC.TO,  Vanguard Canadian Short-Term Corporate Bond Index ETF',
    name: 'Vanguard Canadian Short-Term Corporate Bond Index ETF',
    symbol: 'VSC.TO',
  },
  {
    value: 'VCN.TO',
    label: 'VCN.TO,  Vanguard FTSE Canada All Cap Index ETF',
    name: 'Vanguard FTSE Canada All Cap Index ETF',
    symbol: 'VCN.TO',
  },
  {
    value: 'VCE.TO',
    label: 'VCE.TO,  Vanguard FTSE Canada Index ETF',
    name: 'Vanguard FTSE Canada Index ETF',
    symbol: 'VCE.TO',
  },
  {
    value: 'VRE.TO',
    label: 'VRE.TO,  Vanguard FTSE Canadian Capped REIT Index ETF',
    name: 'Vanguard FTSE Canadian Capped REIT Index ETF',
    symbol: 'VRE.TO',
  },
  {
    value: 'VDY.TO',
    label: 'VDY.TO,  Vanguard FTSE Canadian High Dividend Yield Index ETF',
    name: 'Vanguard FTSE Canadian High Dividend Yield Index ETF',
    symbol: 'VDY.TO',
  },
  {
    value: 'VDU.TO',
    label: 'VDU.TO,  Vanguard FTSE Developed All Cap ex U.S. Index ETF',
    name: 'Vanguard FTSE Developed All Cap ex U.S. Index ETF',
    symbol: 'VDU.TO',
  },
  {
    value: 'VEF.TO',
    label:
      'VEF.TO,  Vanguard FTSE Developed All Cap ex U.S. Index ETF (CAD-hedged)',
    name: 'Vanguard FTSE Developed All Cap ex U.S. Index ETF (CAD-hedged)',
    symbol: 'VEF.TO',
  },
  {
    value: 'VIU.TO',
    label: 'VIU.TO,  Vanguard FTSE Developed All Cap ex North Amer Idx ETF',
    name: 'Vanguard FTSE Developed All Cap ex North Amer Idx ETF',
    symbol: 'VIU.TO',
  },
  {
    value: 'VI.TO',
    label:
      'VI.TO,  Vanguard FTSE Developed All Cap ex Nrth Amer Idx ETF (CAD-Hdd)',
    name: 'Vanguard FTSE Developed All Cap ex Nrth Amer Idx ETF (CAD-Hdd)',
    symbol: 'VI.TO',
  },
  {
    value: 'VA.TO',
    label: 'VA.TO,  Vanguard FTSE Developed Asia Pacific All Cap Index ETF',
    name: 'Vanguard FTSE Developed Asia Pacific All Cap Index ETF',
    symbol: 'VA.TO',
  },
  {
    value: 'VE.TO',
    label: 'VE.TO,  Vanguard FTSE Developed Europe All Cap Index ETF',
    name: 'Vanguard FTSE Developed Europe All Cap Index ETF',
    symbol: 'VE.TO',
  },
  {
    value: 'VEE.TO',
    label: 'VEE.TO,  Vanguard FTSE Emerging Markets All Cap Index ETF',
    name: 'Vanguard FTSE Emerging Markets All Cap Index ETF',
    symbol: 'VEE.TO',
  },
  {
    value: 'VXC.TO',
    label: 'VXC.TO,  Vanguard FTSE Global All Cap ex Canada Index ETF',
    name: 'Vanguard FTSE Global All Cap ex Canada Index ETF',
    symbol: 'VXC.TO',
  },
  {
    value: 'VVO.TO',
    label: 'VVO.TO,  Vanguard Global Minimum Volatility ETF',
    name: 'Vanguard Global Minimum Volatility ETF',
    symbol: 'VVO.TO',
  },
  {
    value: 'VMO.TO',
    label: 'VMO.TO,  Vanguard Global Momentum Factor ETF',
    name: 'Vanguard Global Momentum Factor ETF',
    symbol: 'VMO.TO',
  },
  {
    value: 'VVL.TO',
    label: 'VVL.TO,  Vanguard Global Value Factor ETF',
    name: 'Vanguard Global Value Factor ETF',
    symbol: 'VVL.TO',
  },
  {
    value: 'VFV.TO',
    label: 'VFV.TO,  Vanguard S&P 500 Index ETF',
    name: 'Vanguard S&P 500 Index ETF',
    symbol: 'VFV.TO',
  },
  {
    value: 'VSP.TO',
    label: 'VSP.TO,  Vanguard S&P 500 Index ETF (CAD-hedged)',
    name: 'Vanguard S&P 500 Index ETF (CAD-hedged)',
    symbol: 'VSP.TO',
  },
  {
    value: 'VGG.TO',
    label: 'VGG.TO,  Vanguard U.S. Dividend Appreciation Index ETF',
    name: 'Vanguard U.S. Dividend Appreciation Index ETF',
    symbol: 'VGG.TO',
  },
  {
    value: 'VGH.TO',
    label:
      'VGH.TO,  Vanguard U.S. Dividend Appreciation Index ETF (CAD-hedged)',
    name: 'Vanguard U.S. Dividend Appreciation Index ETF (CAD-hedged)',
    symbol: 'VGH.TO',
  },
  {
    value: 'VUN.TO',
    label: 'VUN.TO,  Vanguard U.S. Total Market Index ETF',
    name: 'Vanguard U.S. Total Market Index ETF',
    symbol: 'VUN.TO',
  },
  {
    value: 'VUS.TO',
    label: 'VUS.TO,  Vanguard U.S. Total Market Index ETF (CAD-hedged)',
    name: 'Vanguard U.S. Total Market Index ETF (CAD-hedged)',
    symbol: 'VUS.TO',
  },
  {
    value: 'VCM.TO',
    label: 'VCM.TO,  Vecima Networks Inc.',
    name: 'Vecima Networks Inc.',
    symbol: 'VCM.TO',
  },
  {
    value: 'VLN.TO',
    label: 'VLN.TO,  Velan Inc.',
    name: 'Velan Inc.',
    symbol: 'VLN.TO',
  },
  {
    value: 'NPK.TO',
    label: 'NPK.TO,  Verde AgriTech Ltd',
    name: 'Verde AgriTech Ltd',
    symbol: 'NPK.TO',
  },
  {
    value: 'VET.TO',
    label: 'VET.TO,  Vermilion Energy Inc.',
    name: 'Vermilion Energy Inc.',
    symbol: 'VET.TO',
  },
  {
    value: 'VGZ.TO',
    label: 'VGZ.TO,  Vista Gold Corp.',
    name: 'Vista Gold Corp.',
    symbol: 'VGZ.TO',
  },
  {
    value: 'WJX.TO',
    label: 'WJX.TO,  Wajax Corporation',
    name: 'Wajax Corporation',
    symbol: 'WJX.TO',
  },
  {
    value: 'WFC.TO',
    label: 'WFC.TO,  Wall Financial Corporation',
    name: 'Wall Financial Corporation',
    symbol: 'WFC.TO',
  },
  {
    value: 'WM.TO',
    label: 'WM.TO,  Wallbridge Mining Company Limited',
    name: 'Wallbridge Mining Company Limited',
    symbol: 'WM.TO',
  },
  {
    value: 'WDO.TO',
    label: 'WDO.TO,  Wesdome Gold Mines Ltd.',
    name: 'Wesdome Gold Mines Ltd.',
    symbol: 'WDO.TO',
  },
  {
    value: 'WRN.TO',
    label: 'WRN.TO,  Western Copper and Gold Corporation',
    name: 'Western Copper and Gold Corporation',
    symbol: 'WRN.TO',
  },
  {
    value: 'WRG.TO',
    label: 'WRG.TO,  Western Energy Services Corp.',
    name: 'Western Energy Services Corp.',
    symbol: 'WRG.TO',
  },
  {
    value: 'WEF.TO',
    label: 'WEF.TO,  Western Forest Products Inc.',
    name: 'Western Forest Products Inc.',
    symbol: 'WEF.TO',
  },
  {
    value: 'WPRT.TO',
    label: 'WPRT.TO,  Westport Fuel Systems Inc.',
    name: 'Westport Fuel Systems Inc.',
    symbol: 'WPRT.TO',
  },
  {
    value: 'WTE.TO',
    label: 'WTE.TO,  Westshore Terminals Investment Corporation',
    name: 'Westshore Terminals Investment Corporation',
    symbol: 'WTE.TO',
  },
  {
    value: 'WCP.TO',
    label: 'WCP.TO,  Whitecap Resources Inc.',
    name: 'Whitecap Resources Inc.',
    symbol: 'WCP.TO',
  },
  {
    value: 'WPK.TO',
    label: 'WPK.TO,  Winpak Ltd.',
    name: 'Winpak Ltd.',
    symbol: 'WPK.TO',
  },
  {
    value: 'EHE.TO',
    label: 'EHE.TO,  CI WisdomTree Europe Hedged Equity Index ETF',
    name: 'CI WisdomTree Europe Hedged Equity Index ETF',
    symbol: 'EHE.TO',
  },
  {
    value: 'DQI.TO',
    label:
      'DQI.TO,  CI WisdomTree International Quality Dividend Growth Variably Hedged Index ETF',
    name: 'CI WisdomTree International Quality Dividend Growth Variably Hedged Index ETF',
    symbol: 'DQI.TO',
  },
  {
    value: 'IQD.TO',
    label:
      'IQD.TO,  CI WisdomTree International Quality Dividend Growth Index ETF',
    name: 'CI WisdomTree International Quality Dividend Growth Index ETF',
    symbol: 'IQD.TO',
  },
  {
    value: 'DQD.TO',
    label:
      'DQD.TO,  CI WisdomTree U.S. Quality Dividend Growth Variably Hedged Index ETF',
    name: 'CI WisdomTree U.S. Quality Dividend Growth Variably Hedged Index ETF',
    symbol: 'DQD.TO',
  },
  {
    value: 'DGR.TO',
    label: 'DGR.TO,  CI WisdomTree U.S. Quality Dividend Growth Index ETF',
    name: 'CI WisdomTree U.S. Quality Dividend Growth Index ETF',
    symbol: 'DGR.TO',
  },
  {
    value: 'WFS.TO',
    label: 'WFS.TO,  World Financial Split Corp.',
    name: 'World Financial Split Corp.',
    symbol: 'WFS.TO',
  },
  {
    value: 'WSP.TO',
    label: 'WSP.TO,  WSP Global Inc.',
    name: 'WSP Global Inc.',
    symbol: 'WSP.TO',
  },
  {
    value: 'YRI.TO',
    label: 'YRI.TO,  Yamana Gold Inc.',
    name: 'Yamana Gold Inc.',
    symbol: 'YRI.TO',
  },
  {
    value: 'YGR.TO',
    label: 'YGR.TO,  Yangarra Resources Ltd.',
    name: 'Yangarra Resources Ltd.',
    symbol: 'YGR.TO',
  },
  {
    value: 'Y.TO',
    label: 'Y.TO,  Yellow Pages Limited',
    name: 'Yellow Pages Limited',
    symbol: 'Y.TO',
  },
  {
    value: 'RCGTX',
    label: 'RCGTX,  Virtus Zevenbergen Technology Fund',
    name: 'Virtus Zevenbergen Technology Fund',
    symbol: 'RCGTX',
  },
  {
    value: 'LDVAX',
    label: 'LDVAX,  AXS Thomson Reuters Venture Capital Return Tracker Fund',
    name: 'AXS Thomson Reuters Venture Capital Return Tracker Fund',
    symbol: 'LDVAX',
  },
  {
    value: 'INPIX',
    label: 'INPIX,  ProFunds Internet UltraSector Fund Investor Class',
    name: 'ProFunds Internet UltraSector Fund Investor Class',
    symbol: 'INPIX',
  },
  {
    value: 'INPSX',
    label: 'INPSX,  ProFunds Internet UltraSector Fund',
    name: 'ProFunds Internet UltraSector Fund',
    symbol: 'INPSX',
  },
  {
    value: 'SCATX',
    label: 'SCATX,  Virtus Zevenbergen Innovative Growth Stock Fund Class I',
    name: 'Virtus Zevenbergen Innovative Growth Stock Fund Class I',
    symbol: 'SCATX',
  },
  {
    value: 'CBAIX',
    label: 'CBAIX,  Calvert Balanced Fund',
    name: 'Calvert Balanced Fund',
    symbol: 'CBAIX',
  },
  {
    value: 'BFOCX',
    label: 'BFOCX,  Berkshire Focus Fund',
    name: 'Berkshire Focus Fund',
    symbol: 'BFOCX',
  },
  {
    value: 'CSGCX',
    label: 'CSGCX,  Calvert Balanced Fund',
    name: 'Calvert Balanced Fund',
    symbol: 'CSGCX',
  },
  {
    value: 'USNQX',
    label: 'USNQX,  USAA Nasdaq 100 Index Fund',
    name: 'USAA Nasdaq 100 Index Fund',
    symbol: 'USNQX',
  },
  {
    value: 'MMCFX',
    label: 'MMCFX,  AMG Veritas China Fund Class N',
    name: 'AMG Veritas China Fund Class N',
    symbol: 'MMCFX',
  },
  {
    value: 'CGMRX',
    label: 'CGMRX,  CGM Realty Fund',
    name: 'CGM Realty Fund',
    symbol: 'CGMRX',
  },
  {
    value: 'CAMOX',
    label: 'CAMOX,  Cambiar Opportunity Portfolio',
    name: 'Cambiar Opportunity Portfolio',
    symbol: 'CAMOX',
  },
  {
    value: 'OTPIX',
    label: 'OTPIX,  ProFunds NASDAQ-100 Fund Investor Class',
    name: 'ProFunds NASDAQ-100 Fund Investor Class',
    symbol: 'OTPIX',
  },
  {
    value: 'MGOAX',
    label: 'MGOAX,  Victory Munder Mid-Cap Core Growth Fund',
    name: 'Victory Munder Mid-Cap Core Growth Fund',
    symbol: 'MGOAX',
  },
  {
    value: 'TEGYX',
    label: 'TEGYX,  Touchstone Mid Cap Growth Fund Class Y',
    name: 'Touchstone Mid Cap Growth Fund Class Y',
    symbol: 'TEGYX',
  },
  {
    value: 'OTPSX',
    label: 'OTPSX,  ProFunds NASDAQ-100 Fund',
    name: 'ProFunds NASDAQ-100 Fund',
    symbol: 'OTPSX',
  },
  {
    value: 'MMSRX',
    label: 'MMSRX,  Victory Munder Mid-Cap Core Growth Fund',
    name: 'Victory Munder Mid-Cap Core Growth Fund',
    symbol: 'MMSRX',
  },
  {
    value: 'RYCOX',
    label: 'RYCOX,  Rydex NASDAQ-100 Fund',
    name: 'Rydex NASDAQ-100 Fund',
    symbol: 'RYCOX',
  },
  {
    value: 'FGDKX',
    label: 'FGDKX,  Fidelity Growth Discovery Fund',
    name: 'Fidelity Growth Discovery Fund',
    symbol: 'FGDKX',
  },
  {
    value: 'TRLGX',
    label: 'TRLGX,  T. Rowe Price Large-Cap Growth Fund I Class',
    name: 'T. Rowe Price Large-Cap Growth Fund I Class',
    symbol: 'TRLGX',
  },
  {
    value: 'NEAGX',
    label: 'NEAGX,  Needham Aggressive Growth Fund Retail Class',
    name: 'Needham Aggressive Growth Fund Retail Class',
    symbol: 'NEAGX',
  },
  {
    value: 'RBRCX',
    label: 'RBRCX,  RBC Small Cap Core Fund',
    name: 'RBC Small Cap Core Fund',
    symbol: 'RBRCX',
  },
  {
    value: 'FDSVX',
    label: 'FDSVX,  Fidelity Growth Discovery Fund',
    name: 'Fidelity Growth Discovery Fund',
    symbol: 'FDSVX',
  },
  {
    value: 'TARKX',
    label: 'TARKX,  Tarkio Fund',
    name: 'Tarkio Fund',
    symbol: 'TARKX',
  },
  {
    value: 'ATACX',
    label: 'ATACX,  ATAC Rotation Fund',
    name: 'ATAC Rotation Fund',
    symbol: 'ATACX',
  },
  {
    value: 'DSMLX',
    label: 'DSMLX,  Touchstone Large Company Growth Fund Class Institutional',
    name: 'Touchstone Large Company Growth Fund Class Institutional',
    symbol: 'DSMLX',
  },
  {
    value: 'QLSCX',
    label: 'QLSCX,  Federated Hermes MDT Small Cap Core Fund',
    name: 'Federated Hermes MDT Small Cap Core Fund',
    symbol: 'QLSCX',
  },
  {
    value: 'GGGAX',
    label: 'GGGAX,  Gabelli Global Growth Fund',
    name: 'Gabelli Global Growth Fund',
    symbol: 'GGGAX',
  },
  {
    value: 'TEGIX',
    label: 'TEGIX,  Touchstone Mid Cap Growth Fund',
    name: 'Touchstone Mid Cap Growth Fund',
    symbol: 'TEGIX',
  },
  {
    value: 'WSMNX',
    label: 'WSMNX,  William Blair Small-Mid Cap Growth Fund Class N',
    name: 'William Blair Small-Mid Cap Growth Fund Class N',
    symbol: 'WSMNX',
  },
  {
    value: 'TSNIX',
    label: 'TSNIX,  T. Rowe Price Science And Technology Fund',
    name: 'T. Rowe Price Science And Technology Fund',
    symbol: 'TSNIX',
  },
  {
    value: 'PRSCX',
    label: 'PRSCX,  T. Rowe Price Science and Technology Fund',
    name: 'T. Rowe Price Science and Technology Fund',
    symbol: 'PRSCX',
  },
  {
    value: 'TEGAX',
    label: 'TEGAX,  Touchstone Mid Cap Growth Fund Class A',
    name: 'Touchstone Mid Cap Growth Fund Class A',
    symbol: 'TEGAX',
  },
  {
    value: 'GTCSX',
    label: 'GTCSX,  Glenmede Small Cap Equity Portfolio Class Advisor',
    name: 'Glenmede Small Cap Equity Portfolio Class Advisor',
    symbol: 'GTCSX',
  },
  {
    value: 'VSSCX',
    label: 'VSSCX,  JPMorgan Small Cap Sustainable Leaders Fund',
    name: 'JPMorgan Small Cap Sustainable Leaders Fund',
    symbol: 'VSSCX',
  },
  {
    value: 'WSMDX',
    label: 'WSMDX,  William Blair Small-Mid Cap Growth Fund',
    name: 'William Blair Small-Mid Cap Growth Fund',
    symbol: 'WSMDX',
  },
  {
    value: 'TOECX',
    label: 'TOECX,  Touchstone Mid Cap Growth Fund',
    name: 'Touchstone Mid Cap Growth Fund',
    symbol: 'TOECX',
  },
  {
    value: 'ZSCIX',
    label: 'ZSCIX,  Zacks Small-Cap Core Fund Institutional Class',
    name: 'Zacks Small-Cap Core Fund Institutional Class',
    symbol: 'ZSCIX',
  },
  {
    value: 'PRUFX',
    label: 'PRUFX,  T. Rowe Price Growth Stock Fund',
    name: 'T. Rowe Price Growth Stock Fund',
    symbol: 'PRUFX',
  },
  {
    value: 'TSAGX',
    label: 'TSAGX,  Touchstone Large Company Growth Fund',
    name: 'Touchstone Large Company Growth Fund',
    symbol: 'TSAGX',
  },
  {
    value: 'TILHX',
    label: 'TILHX,  TIAA-CREF Large Cap Growth Fund',
    name: 'TIAA-CREF Large Cap Growth Fund',
    symbol: 'TILHX',
  },
  {
    value: 'PRGFX',
    label: 'PRGFX,  T. Rowe Price Growth Stock Fund',
    name: 'T. Rowe Price Growth Stock Fund',
    symbol: 'PRGFX',
  },
  {
    value: 'ADCCX',
    label: 'ADCCX,  American Century Disciplined Growth Fund',
    name: 'American Century Disciplined Growth Fund',
    symbol: 'ADCCX',
  },
  {
    value: 'TILPX',
    label: 'TILPX,  TIAA-CREF Large-Cap Growth Fund Premier Class',
    name: 'TIAA-CREF Large-Cap Growth Fund Premier Class',
    symbol: 'TILPX',
  },
  {
    value: 'GTSCX',
    label: 'GTSCX,  Glenmede Small Cap Equity Portfolio',
    name: 'Glenmede Small Cap Equity Portfolio',
    symbol: 'GTSCX',
  },
  {
    value: 'TRSAX',
    label: 'TRSAX,  T. Rowe Price Growth Stock Fund Advisor Class',
    name: 'T. Rowe Price Growth Stock Fund Advisor Class',
    symbol: 'TRSAX',
  },
  {
    value: 'TIRTX',
    label: 'TIRTX,  TIAA-CREF Large Cap Growth Fund',
    name: 'TIAA-CREF Large Cap Growth Fund',
    symbol: 'TIRTX',
  },
  {
    value: 'NEAIX',
    label: 'NEAIX,  Needham Aggressive Growth Fund',
    name: 'Needham Aggressive Growth Fund',
    symbol: 'NEAIX',
  },
  {
    value: 'RRGSX',
    label: 'RRGSX,  T. Rowe Price Growth Stock Fund',
    name: 'T. Rowe Price Growth Stock Fund',
    symbol: 'RRGSX',
  },
  {
    value: 'TILRX',
    label: 'TILRX,  TIAA-CREF Large-Cap Growth Fund Retirement Class',
    name: 'TIAA-CREF Large-Cap Growth Fund Retirement Class',
    symbol: 'TILRX',
  },
  {
    value: 'HRCUX',
    label: 'HRCUX,  Carillon ClariVest Capital Appreciation Fund Class R6',
    name: 'Carillon ClariVest Capital Appreciation Fund Class R6',
    symbol: 'HRCUX',
  },
  {
    value: 'FLRYX',
    label: 'FLRYX,  Nuveen Large Cap Select Fund',
    name: 'Nuveen Large Cap Select Fund',
    symbol: 'FLRYX',
  },
  {
    value: 'LSSNX',
    label: 'LSSNX,  Loomis Sayles Small Cap Growth Fund Class N',
    name: 'Loomis Sayles Small Cap Growth Fund Class N',
    symbol: 'LSSNX',
  },
  {
    value: 'ZSCCX',
    label: 'ZSCCX,  Zacks Small-Cap Core Fund',
    name: 'Zacks Small-Cap Core Fund',
    symbol: 'ZSCCX',
  },
  {
    value: 'QMORX',
    label: 'QMORX,  AQR Large Cap Momentum Style Fund Class R6',
    name: 'AQR Large Cap Momentum Style Fund Class R6',
    symbol: 'QMORX',
  },
  {
    value: 'JMCRX',
    label: 'JMCRX,  James Micro Cap Fund',
    name: 'James Micro Cap Fund',
    symbol: 'JMCRX',
  },
  {
    value: 'DISIX',
    label: 'DISIX,  BNY Mellon Smallcap Stock Index Fund',
    name: 'BNY Mellon Smallcap Stock Index Fund',
    symbol: 'DISIX',
  },
  {
    value: 'DISSX',
    label: 'DISSX,  BNY Mellon Small Cap Stock Index Fund Investor Shares',
    name: 'BNY Mellon Small Cap Stock Index Fund Investor Shares',
    symbol: 'DISSX',
  },
  {
    value: 'FLRAX',
    label: 'FLRAX,  Nuveen Large Cap Select Fund',
    name: 'Nuveen Large Cap Select Fund',
    symbol: 'FLRAX',
  },
  {
    value: 'HRCMX',
    label: 'HRCMX,  Carillon ClariVest Capital Appreciation Fund Class R5',
    name: 'Carillon ClariVest Capital Appreciation Fund Class R5',
    symbol: 'HRCMX',
  },
  {
    value: 'FLYCX',
    label: 'FLYCX,  Nuveen Large Cap Select Fund Class C',
    name: 'Nuveen Large Cap Select Fund Class C',
    symbol: 'FLYCX',
  },
  {
    value: 'VMGMX',
    label: 'VMGMX,  Vanguard Mid-Cap Growth Index Fund',
    name: 'Vanguard Mid-Cap Growth Index Fund',
    symbol: 'VMGMX',
  },
  {
    value: 'USAAX',
    label: 'USAAX,  USAA Mutual Funds Trust - USAA Growth Fund',
    name: 'USAA Mutual Funds Trust - USAA Growth Fund',
    symbol: 'USAAX',
  },
  {
    value: 'FTQGX',
    label: 'FTQGX,  Fidelity Focused Stock Fund',
    name: 'Fidelity Focused Stock Fund',
    symbol: 'FTQGX',
  },
  {
    value: 'QLSGX',
    label: 'QLSGX,  Federated Hermes MDT Small Cap Growth Fund',
    name: 'Federated Hermes MDT Small Cap Growth Fund',
    symbol: 'QLSGX',
  },
  {
    value: 'QISGX',
    label:
      'QISGX,  Federated Hermes MDT Small Cap Growth Fund Institutional Shares',
    name: 'Federated Hermes MDT Small Cap Growth Fund Institutional Shares',
    symbol: 'QISGX',
  },
  {
    value: 'FSRFX',
    label: 'FSRFX,  Fidelity Select Transportation Portfolio',
    name: 'Fidelity Select Transportation Portfolio',
    symbol: 'FSRFX',
  },
  {
    value: 'QCSGX',
    label: 'QCSGX,  Federated Hermes MDT Small Cap Growth Fund Class C Shares',
    name: 'Federated Hermes MDT Small Cap Growth Fund Class C Shares',
    symbol: 'QCSGX',
  },
  {
    value: 'VMGIX',
    label: 'VMGIX,  Vanguard Mid-Cap Growth Index Fund',
    name: 'Vanguard Mid-Cap Growth Index Fund',
    symbol: 'VMGIX',
  },
  {
    value: 'DSEEX',
    label: 'DSEEX,  DoubleLine Funds Trust - DoubleLine Shiller Enhanced CAPE',
    name: 'DoubleLine Funds Trust - DoubleLine Shiller Enhanced CAPE',
    symbol: 'DSEEX',
  },
  {
    value: 'DSENX',
    label: 'DSENX,  DoubleLine Shiller Enhanced CAPE',
    name: 'DoubleLine Shiller Enhanced CAPE',
    symbol: 'DSENX',
  },
  {
    value: 'CCALX',
    label: 'CCALX,  Conestoga Small Cap',
    name: 'Conestoga Small Cap',
    symbol: 'CCALX',
  },
  {
    value: 'PFPWX',
    label: 'PFPWX,  Parnassus Funds - Parnassus Endeavor Fund',
    name: 'Parnassus Funds - Parnassus Endeavor Fund',
    symbol: 'PFPWX',
  },
  {
    value: 'QASGX',
    label: 'QASGX,  Federated Hermes MDT Small Cap Growth Fund Class A Shares',
    name: 'Federated Hermes MDT Small Cap Growth Fund Class A Shares',
    symbol: 'QASGX',
  },
  {
    value: 'EGFIX',
    label: 'EGFIX,  Edgewood Growth Fund Class Institutional',
    name: 'Edgewood Growth Fund Class Institutional',
    symbol: 'EGFIX',
  },
  {
    value: 'VTSIX',
    label:
      'VTSIX,  Vanguard Tax-Managed Funds - Vanguard Tax-Managed Small-Cap Fund',
    name: 'Vanguard Tax-Managed Funds - Vanguard Tax-Managed Small-Cap Fund',
    symbol: 'VTSIX',
  },
  {
    value: 'POGRX',
    label: 'POGRX,  PRIMECAP Odyssey Growth Fund',
    name: 'PRIMECAP Odyssey Growth Fund',
    symbol: 'POGRX',
  },
  {
    value: 'HACAX',
    label: 'HACAX,  Harbor Funds - Harbor Capital Appreciation Fund',
    name: 'Harbor Funds - Harbor Capital Appreciation Fund',
    symbol: 'HACAX',
  },
  {
    value: 'CCASX',
    label: 'CCASX,  Conestoga Small Cap Fund Investors Class',
    name: 'Conestoga Small Cap Fund Investors Class',
    symbol: 'CCASX',
  },
  {
    value: 'UIGRX',
    label: 'UIGRX,  USAA Growth Fund',
    name: 'USAA Growth Fund',
    symbol: 'UIGRX',
  },
  {
    value: 'HRCAX',
    label: 'HRCAX,  Harbor Capital Appreciation Fund Administrative Class',
    name: 'Harbor Capital Appreciation Fund Administrative Class',
    symbol: 'HRCAX',
  },
  {
    value: 'HCAIX',
    label: 'HCAIX,  Harbor Capital Appreciation Fund',
    name: 'Harbor Capital Appreciation Fund',
    symbol: 'HCAIX',
  },
  {
    value: 'FLGEX',
    label: 'FLGEX,  Fidelity Large Cap Growth Enhanced Index Fund',
    name: 'Fidelity Large Cap Growth Enhanced Index Fund',
    symbol: 'FLGEX',
  },
  {
    value: 'TPLGX',
    label: 'TPLGX,  T. Rowe Price Institutional Large Cap Core Growth Fund',
    name: 'T. Rowe Price Institutional Large Cap Core Growth Fund',
    symbol: 'TPLGX',
  },
  {
    value: 'VTMSX',
    label: 'VTMSX,  Vanguard Tax Managed Small Cap Fund',
    name: 'Vanguard Tax Managed Small Cap Fund',
    symbol: 'VTMSX',
  },
  {
    value: 'CSXCX',
    label:
      'CSXCX,  Calvert Responsible Index Series, Inc. - Calvert US Large-Cap Core Responsible Index Fund',
    name: 'Calvert Responsible Index Series, Inc. - Calvert US Large-Cap Core Responsible Index Fund',
    symbol: 'CSXCX',
  },
  {
    value: 'PARWX',
    label: 'PARWX,  Parnassus Endeavor Fund',
    name: 'Parnassus Endeavor Fund',
    symbol: 'PARWX',
  },
  {
    value: 'EGFFX',
    label: 'EGFFX,  Edgewood Growth Fund Retail Class',
    name: 'Edgewood Growth Fund Retail Class',
    symbol: 'EGFFX',
  },
  {
    value: 'CYPIX',
    label: 'CYPIX,  ProFunds Consumer Services UltraSector Fund Investor Class',
    name: 'ProFunds Consumer Services UltraSector Fund Investor Class',
    symbol: 'CYPIX',
  },
  {
    value: 'FBGRX',
    label: 'FBGRX,  Fidelity Securities Fund - Fidelity Blue Chip Growth Fund',
    name: 'Fidelity Securities Fund - Fidelity Blue Chip Growth Fund',
    symbol: 'FBGRX',
  },
  {
    value: 'TSCIX',
    label: 'TSCIX,  AMG TimesSquare Small Cap Growth Fund',
    name: 'AMG TimesSquare Small Cap Growth Fund',
    symbol: 'TSCIX',
  },
  {
    value: 'MECAX',
    label: 'MECAX,  AMG GW&K International Small Cap Fund',
    name: 'AMG GW&K International Small Cap Fund',
    symbol: 'MECAX',
  },
  {
    value: 'WBSIX',
    label: 'WBSIX,  William Blair Small Cap Growth Fund Class I',
    name: 'William Blair Small Cap Growth Fund Class I',
    symbol: 'WBSIX',
  },
  {
    value: 'MECIX',
    label: 'MECIX,  AMG GW&K International Small Cap Fund - Class I',
    name: 'AMG GW&K International Small Cap Fund - Class I',
    symbol: 'MECIX',
  },
  {
    value: 'PABGX',
    label: 'PABGX,  T. Rowe Price Blue Chip Growth Fund Advisor Class',
    name: 'T. Rowe Price Blue Chip Growth Fund Advisor Class',
    symbol: 'PABGX',
  },
  {
    value: 'TRBCX',
    label: 'TRBCX,  T. Rowe Price Blue Chip Growth Fund',
    name: 'T. Rowe Price Blue Chip Growth Fund',
    symbol: 'TRBCX',
  },
  {
    value: 'FBGKX',
    label: 'FBGKX,  Fidelity Blue Chip Growth Fund',
    name: 'Fidelity Blue Chip Growth Fund',
    symbol: 'FBGKX',
  },
  {
    value: 'RRBGX',
    label: 'RRBGX,  T. Rowe Price Blue Chip Growth Fund',
    name: 'T. Rowe Price Blue Chip Growth Fund',
    symbol: 'RRBGX',
  },
  {
    value: 'FSHOX',
    label: 'FSHOX,  Fidelity Select Construction & Housing Portfolio',
    name: 'Fidelity Select Construction & Housing Portfolio',
    symbol: 'FSHOX',
  },
  {
    value: 'WSMGX',
    label: 'WSMGX,  Wilshire Small Company Growth Portfolio',
    name: 'Wilshire Small Company Growth Portfolio',
    symbol: 'WSMGX',
  },
  {
    value: 'TBCIX',
    label: 'TBCIX,  T. Rowe Price Blue Chip Growth Fund',
    name: 'T. Rowe Price Blue Chip Growth Fund',
    symbol: 'TBCIX',
  },
  {
    value: 'CPECX',
    label: 'CPECX,  Catalyst Dynamic Alpha Fund',
    name: 'Catalyst Dynamic Alpha Fund',
    symbol: 'CPECX',
  },
  {
    value: 'WBSNX',
    label: 'WBSNX,  William Blair Small Cap Growth Fund Class N',
    name: 'William Blair Small Cap Growth Fund Class N',
    symbol: 'WBSNX',
  },
  {
    value: 'CYPSX',
    label: 'CYPSX,  ProFunds Consumer Services Ultra Sector Fund',
    name: 'ProFunds Consumer Services Ultra Sector Fund',
    symbol: 'CYPSX',
  },
  {
    value: 'DTSGX',
    label:
      'DTSGX,  Wilshire Small Company Growth Portfolio Investment Class Shares',
    name: 'Wilshire Small Company Growth Portfolio Investment Class Shares',
    symbol: 'DTSGX',
  },
  {
    value: 'CPEAX',
    label: 'CPEAX,  Catalyst Dynamic Alpha Fund',
    name: 'Catalyst Dynamic Alpha Fund',
    symbol: 'CPEAX',
  },
  {
    value: 'JLGAX',
    label: 'JLGAX,  JAG Large Cap Growth Fund',
    name: 'JAG Large Cap Growth Fund',
    symbol: 'JLGAX',
  },
  {
    value: 'GURIX',
    label: 'GURIX,  Guggenheim Risk Managed Real Estate Fund Institutional',
    name: 'Guggenheim Risk Managed Real Estate Fund Institutional',
    symbol: 'GURIX',
  },
  {
    value: 'ROGSX',
    label: 'ROGSX,  Red Oak Technology Select Fund',
    name: 'Red Oak Technology Select Fund',
    symbol: 'ROGSX',
  },
  {
    value: 'CVMIX',
    label: 'CVMIX,  Calvert Emerging Markets Equity Fund',
    name: 'Calvert Emerging Markets Equity Fund',
    symbol: 'CVMIX',
  },
  {
    value: 'BIPSX',
    label: 'BIPSX,  ProFunds Biotechnology UltraSector Fund Service Class',
    name: 'ProFunds Biotechnology UltraSector Fund Service Class',
    symbol: 'BIPSX',
  },
  {
    value: 'BIPIX',
    label: 'BIPIX,  ProFunds Biotechnology UltraSector Fund Investor Class',
    name: 'ProFunds Biotechnology UltraSector Fund Investor Class',
    symbol: 'BIPIX',
  },
  {
    value: 'ASMOX',
    label: 'ASMOX,  AQR Small Cap Momentum Style Fund Class I',
    name: 'AQR Small Cap Momentum Style Fund Class I',
    symbol: 'ASMOX',
  },
  {
    value: 'FMPFX',
    label: 'FMPFX,  Nuveen Small Cap Growth Opportunities Fund Class R6',
    name: 'Nuveen Small Cap Growth Opportunities Fund Class R6',
    symbol: 'FMPFX',
  },
  {
    value: 'TSCPX',
    label: 'TSCPX,  AMG TimesSquare Small Cap Growth Fund Class N',
    name: 'AMG TimesSquare Small Cap Growth Fund Class N',
    symbol: 'TSCPX',
  },
  {
    value: 'GURAX',
    label: 'GURAX,  Guggenheim Risk Managed Real Estate Fund',
    name: 'Guggenheim Risk Managed Real Estate Fund',
    symbol: 'GURAX',
  },
  {
    value: 'FFGRX',
    label: 'FFGRX,  Emerald Growth Fund',
    name: 'Emerald Growth Fund',
    symbol: 'FFGRX',
  },
  {
    value: 'FIMPX',
    label: 'FIMPX,  Nuveen Small Cap Growth Opportunities Fund Class I',
    name: 'Nuveen Small Cap Growth Opportunities Fund Class I',
    symbol: 'FIMPX',
  },
  {
    value: 'CPEIX',
    label: 'CPEIX,  Catalyst Dynamic Alpha Fund Class I',
    name: 'Catalyst Dynamic Alpha Fund Class I',
    symbol: 'CPEIX',
  },
  {
    value: 'WSCGX',
    label: 'WSCGX,  Wells Fargo Small Company Growth Fund - Class Inst',
    name: 'Wells Fargo Small Company Growth Fund - Class Inst',
    symbol: 'WSCGX',
  },
  {
    value: 'NVSCX',
    label: 'NVSCX,  Allspring Small Company Growth Fund',
    name: 'Allspring Small Company Growth Fund',
    symbol: 'NVSCX',
  },
  {
    value: 'SGPIX',
    label: 'SGPIX,  ProFunds Small Cap Growth Fund Investor Class',
    name: 'ProFunds Small Cap Growth Fund Investor Class',
    symbol: 'SGPIX',
  },
  {
    value: 'GLVNX',
    label: 'GLVNX,  Invesco Global Focus Fund',
    name: 'Invesco Global Focus Fund',
    symbol: 'GLVNX',
  },
  {
    value: 'FKKSX',
    label: 'FKKSX,  Federated Hermes Kaufmann Small Cap Fund',
    name: 'Federated Hermes Kaufmann Small Cap Fund',
    symbol: 'FKKSX',
  },
  {
    value: 'BMGCX',
    label:
      'BMGCX,  BlackRock Mid-Cap Growth Equity Portfolio Investor C Shares',
    name: 'BlackRock Mid-Cap Growth Equity Portfolio Investor C Shares',
    symbol: 'BMGCX',
  },
  {
    value: 'FTRNX',
    label: 'FTRNX,  Fidelity Trend Fund',
    name: 'Fidelity Trend Fund',
    symbol: 'FTRNX',
  },
  {
    value: 'HSPGX',
    label: 'HSPGX,  Emerald Growth Fund',
    name: 'Emerald Growth Fund',
    symbol: 'HSPGX',
  },
  {
    value: 'DFDMX',
    label: 'DFDMX,  DF Dent Midcap Growth Fund Investor Shares',
    name: 'DF Dent Midcap Growth Fund Investor Shares',
    symbol: 'DFDMX',
  },
  {
    value: 'HSPCX',
    label: 'HSPCX,  Emerald Growth Fund',
    name: 'Emerald Growth Fund',
    symbol: 'HSPCX',
  },
  {
    value: 'ALGRX',
    label: 'ALGRX,  Alger Focus Equity Fund Class I',
    name: 'Alger Focus Equity Fund Class I',
    symbol: 'ALGRX',
  },
  {
    value: 'JARTX',
    label: 'JARTX,  Janus Henderson Forty Fund',
    name: 'Janus Henderson Forty Fund',
    symbol: 'JARTX',
  },
  {
    value: 'JFRNX',
    label: 'JFRNX,  Janus Henderson Forty Fund',
    name: 'Janus Henderson Forty Fund',
    symbol: 'JFRNX',
  },
  {
    value: 'SGTTX',
    label:
      'SGTTX,  Columbia Seligman Global Technology Fund Institutional 2 Class',
    name: 'Columbia Seligman Global Technology Fund Institutional 2 Class',
    symbol: 'SGTTX',
  },
  {
    value: 'BMRRX',
    label: 'BMRRX,  BlackRock Mid-Cap Growth Equity Portfolio',
    name: 'BlackRock Mid-Cap Growth Equity Portfolio',
    symbol: 'BMRRX',
  },
  {
    value: 'JCAPX',
    label: 'JCAPX,  Janus Henderson Forty Fund Class I',
    name: 'Janus Henderson Forty Fund Class I',
    symbol: 'JCAPX',
  },
  {
    value: 'FMPCX',
    label: 'FMPCX,  Nuveen Small Cap Growth Opportunities Fund',
    name: 'Nuveen Small Cap Growth Opportunities Fund',
    symbol: 'FMPCX',
  },
  {
    value: 'SHTCX',
    label: 'SHTCX,  Columbia Seligman Global Technology Fund Class C',
    name: 'Columbia Seligman Global Technology Fund Class C',
    symbol: 'SHTCX',
  },
  {
    value: 'JDCAX',
    label: 'JDCAX,  Janus Henderson Forty Fund',
    name: 'Janus Henderson Forty Fund',
    symbol: 'JDCAX',
  },
  {
    value: 'ALZFX',
    label: 'ALZFX,  Alger Focus Equity Fund',
    name: 'Alger Focus Equity Fund',
    symbol: 'ALZFX',
  },
  {
    value: 'CSGZX',
    label: 'CSGZX,  Columbia Seligman Global Technology Fund',
    name: 'Columbia Seligman Global Technology Fund',
    symbol: 'CSGZX',
  },
  {
    value: 'JDCRX',
    label: 'JDCRX,  Janus Henderson Forty Fund',
    name: 'Janus Henderson Forty Fund',
    symbol: 'JDCRX',
  },
  {
    value: 'SGTRX',
    label: 'SGTRX,  Columbia Seligman Global Technology Fund',
    name: 'Columbia Seligman Global Technology Fund',
    symbol: 'SGTRX',
  },
  {
    value: 'KMKYX',
    label: 'KMKYX,  Kinetics Market Opportunities Fund',
    name: 'Kinetics Market Opportunities Fund',
    symbol: 'KMKYX',
  },
  {
    value: 'JACCX',
    label: 'JACCX,  Janus Henderson Forty Fund',
    name: 'Janus Henderson Forty Fund',
    symbol: 'JACCX',
  },
  {
    value: 'ALCFX',
    label: 'ALCFX,  Alger Focus Equity Fund',
    name: 'Alger Focus Equity Fund',
    symbol: 'ALCFX',
  },
  {
    value: 'BMGAX',
    label: 'BMGAX,  BlackRock Mid-Cap Growth Equity Portfolio',
    name: 'BlackRock Mid-Cap Growth Equity Portfolio',
    symbol: 'BMGAX',
  },
  {
    value: 'ALAFX',
    label: 'ALAFX,  Alger Focus Equity Fund',
    name: 'Alger Focus Equity Fund',
    symbol: 'ALAFX',
  },
  {
    value: 'JLGMX',
    label: 'JLGMX,  JPMorgan Large Cap Growth Fund',
    name: 'JPMorgan Large Cap Growth Fund',
    symbol: 'JLGMX',
  },
  {
    value: 'CAMAX',
    label: 'CAMAX,  Cambiar Aggressive Value Fund Investor Class',
    name: 'Cambiar Aggressive Value Fund Investor Class',
    symbol: 'CAMAX',
  },
  {
    value: 'CCHRX',
    label: 'CCHRX,  Columbia Seligman Global Technology Fund Advisor Class',
    name: 'Columbia Seligman Global Technology Fund Advisor Class',
    symbol: 'CCHRX',
  },
  {
    value: 'POAGX',
    label:
      'POAGX,  PRIMECAP Odyssey Funds - PRIMECAP Odyssey Aggressive Growth Fund',
    name: 'PRIMECAP Odyssey Funds - PRIMECAP Odyssey Aggressive Growth Fund',
    symbol: 'POAGX',
  },
  {
    value: 'WAAEX',
    label: 'WAAEX,  Wasatch Small Cap Growth Fund Investor Class',
    name: 'Wasatch Small Cap Growth Fund Investor Class',
    symbol: 'WAAEX',
  },
  {
    value: 'KMKNX',
    label: 'KMKNX,  Kinetics Market Opportunities Fund',
    name: 'Kinetics Market Opportunities Fund',
    symbol: 'KMKNX',
  },
  {
    value: 'MIGPX',
    label:
      'MIGPX,  Morgan Stanley Institutional Fund, Inc. Global Insight Portfolio Class A',
    name: 'Morgan Stanley Institutional Fund, Inc. Global Insight Portfolio Class A',
    symbol: 'MIGPX',
  },
  {
    value: 'VWUAX',
    label: 'VWUAX,  Vanguard World Fund - Vanguard U.S. Growth Fund',
    name: 'Vanguard World Fund - Vanguard U.S. Growth Fund',
    symbol: 'VWUAX',
  },
  {
    value: 'RIVBX',
    label: 'RIVBX,  Riverbridge Growth Fund',
    name: 'Riverbridge Growth Fund',
    symbol: 'RIVBX',
  },
  {
    value: 'UMPIX',
    label: 'UMPIX,  ProFunds UltraMid Cap Fund Investor Class',
    name: 'ProFunds UltraMid Cap Fund Investor Class',
    symbol: 'UMPIX',
  },
  {
    value: 'BGLUX',
    label: 'BGLUX,  Baron Global Advantage Fund',
    name: 'Baron Global Advantage Fund',
    symbol: 'BGLUX',
  },
  {
    value: 'TTMIX',
    label: 'TTMIX,  T. Rowe Price Communications & Technology Fund',
    name: 'T. Rowe Price Communications & Technology Fund',
    symbol: 'TTMIX',
  },
  {
    value: 'PRMTX',
    label:
      'PRMTX,  T. Rowe Price Communications & Technology Fund Investor Class',
    name: 'T. Rowe Price Communications & Technology Fund Investor Class',
    symbol: 'PRMTX',
  },
  {
    value: 'TCMSX',
    label: 'TCMSX,  TCM Small Cap Growth Fund',
    name: 'TCM Small Cap Growth Fund',
    symbol: 'TCMSX',
  },
  {
    value: 'MSPTX',
    label:
      'MSPTX,  Morgan Stanley Institutional Fund. Inc. Global Insight Portfolio',
    name: 'Morgan Stanley Institutional Fund. Inc. Global Insight Portfolio',
    symbol: 'MSPTX',
  },
  {
    value: 'MIGIX',
    label:
      'MIGIX,  Morgan Stanley Institutional Fund, Inc. Global Insight Portfolio Class I',
    name: 'Morgan Stanley Institutional Fund, Inc. Global Insight Portfolio Class I',
    symbol: 'MIGIX',
  },
  {
    value: 'UMPSX',
    label: 'UMPSX,  ProFunds UltraMid Cap Fund',
    name: 'ProFunds UltraMid Cap Fund',
    symbol: 'UMPSX',
  },
  {
    value: 'FSCSX',
    label: 'FSCSX,  Fidelity Select Software & IT Services Portfolio',
    name: 'Fidelity Select Software & IT Services Portfolio',
    symbol: 'FSCSX',
  },
  {
    value: 'PAREX',
    label: 'PAREX,  T. Rowe Price Real Estate Fund',
    name: 'T. Rowe Price Real Estate Fund',
    symbol: 'PAREX',
  },
  {
    value: 'SCMIX',
    label: 'SCMIX,  Columbia Seligman Technology and Information Fund',
    name: 'Columbia Seligman Technology and Information Fund',
    symbol: 'SCMIX',
  },
  {
    value: 'SCIOX',
    label:
      'SCIOX,  Columbia Seligman Technology and Information Fund Advisor Class',
    name: 'Columbia Seligman Technology and Information Fund Advisor Class',
    symbol: 'SCIOX',
  },
  {
    value: 'SLMCX',
    label: 'SLMCX,  Columbia Seligman Technology and Information Fund Class A',
    name: 'Columbia Seligman Technology and Information Fund Class A',
    symbol: 'SLMCX',
  },
  {
    value: 'SCIRX',
    label: 'SCIRX,  Columbia Seligman Technology and Information Fund Class R',
    name: 'Columbia Seligman Technology and Information Fund Class R',
    symbol: 'SCIRX',
  },
  {
    value: 'CISGX',
    label: 'CISGX,  Touchstone Sands Capital Select Growth Fund',
    name: 'Touchstone Sands Capital Select Growth Fund',
    symbol: 'CISGX',
  },
  {
    value: 'CCIZX',
    label: 'CCIZX,  Columbia Seligman Technology and Information Fund',
    name: 'Columbia Seligman Technology and Information Fund',
    symbol: 'CCIZX',
  },
  {
    value: 'FZAHX',
    label: 'FZAHX,  Fidelity Advisor Growth Opportunities Fund Class Z',
    name: 'Fidelity Advisor Growth Opportunities Fund Class Z',
    symbol: 'FZAHX',
  },
  {
    value: 'GSXIX',
    label: 'GSXIX,  abrdn U.S. Small Cap Equity Fund',
    name: 'abrdn U.S. Small Cap Equity Fund',
    symbol: 'GSXIX',
  },
  {
    value: 'PRJIX',
    label: 'PRJIX,  T. Rowe Price New Horizons Fund I Class',
    name: 'T. Rowe Price New Horizons Fund I Class',
    symbol: 'PRJIX',
  },
  {
    value: 'PVIVX',
    label: 'PVIVX,  Paradigm Micro-Cap Fund',
    name: 'Paradigm Micro-Cap Fund',
    symbol: 'PVIVX',
  },
  {
    value: 'REICX',
    label: 'REICX,  West Loop Realty Fund',
    name: 'West Loop Realty Fund',
    symbol: 'REICX',
  },
  {
    value: 'NWJFX',
    label:
      'NWJFX,  Nationwide NYSE Arca Tech 100 Index Fund Institutional Service Class',
    name: 'Nationwide NYSE Arca Tech 100 Index Fund Institutional Service Class',
    symbol: 'NWJFX',
  },
  {
    value: 'BDFIX',
    label: 'BDFIX,  Baron Discovery Fund Institutional Shares',
    name: 'Baron Discovery Fund Institutional Shares',
    symbol: 'BDFIX',
  },
  {
    value: 'FSPTX',
    label: 'FSPTX,  Fidelity Select Technology Portfolio',
    name: 'Fidelity Select Technology Portfolio',
    symbol: 'FSPTX',
  },
  {
    value: 'FATEX',
    label: 'FATEX,  Fidelity Advisor Technology Fund',
    name: 'Fidelity Advisor Technology Fund',
    symbol: 'FATEX',
  },
  {
    value: 'PRNHX',
    label: 'PRNHX,  T. Rowe Price New Horizons Fund',
    name: 'T. Rowe Price New Horizons Fund',
    symbol: 'PRNHX',
  },
  {
    value: 'FSRNX',
    label: 'FSRNX,  Fidelity Real Estate Index Fund',
    name: 'Fidelity Real Estate Index Fund',
    symbol: 'FSRNX',
  },
  {
    value: 'FELTX',
    label: 'FELTX,  Fidelity Advisor Semiconductors Fund Class M',
    name: 'Fidelity Advisor Semiconductors Fund Class M',
    symbol: 'FELTX',
  },
  {
    value: 'GSXCX',
    label: 'GSXCX,  abrdn U.S. Small Cap Equity Fund',
    name: 'abrdn U.S. Small Cap Equity Fund',
    symbol: 'GSXCX',
  },
  {
    value: 'FADTX',
    label: 'FADTX,  Fidelity Advisor Technology Fund',
    name: 'Fidelity Advisor Technology Fund',
    symbol: 'FADTX',
  },
  {
    value: 'FTHCX',
    label: 'FTHCX,  Fidelity Advisor Technology Fund Class C',
    name: 'Fidelity Advisor Technology Fund Class C',
    symbol: 'FTHCX',
  },
  {
    value: 'TGCNX',
    label: 'TGCNX,  TCW Funds, Inc. - TCW Select Equities Fund',
    name: 'TCW Funds, Inc. - TCW Select Equities Fund',
    symbol: 'TGCNX',
  },
  {
    value: 'BLYYX',
    label: 'BLYYX,  American Beacon Bridgeway Large Cap Growth Fund Y Class',
    name: 'American Beacon Bridgeway Large Cap Growth Fund Y Class',
    symbol: 'BLYYX',
  },
  {
    value: 'FELIX',
    label: 'FELIX,  Fidelity Advisor Semiconductors Fund',
    name: 'Fidelity Advisor Semiconductors Fund',
    symbol: 'FELIX',
  },
  {
    value: 'MNREX',
    label: 'MNREX,  Manning & Napier Real Estate Series',
    name: 'Manning & Napier Real Estate Series',
    symbol: 'MNREX',
  },
  {
    value: 'USSCX',
    label: 'USSCX,  USAA Science & Technology Fund',
    name: 'USAA Science & Technology Fund',
    symbol: 'USSCX',
  },
  {
    value: 'BLYAX',
    label: 'BLYAX,  American Beacon Bridgeway Large Cap Growth Fund A Class',
    name: 'American Beacon Bridgeway Large Cap Growth Fund A Class',
    symbol: 'BLYAX',
  },
  {
    value: 'PRJQX',
    label: 'PRJQX,  PGIM Jennison Global Opportunities Fund',
    name: 'PGIM Jennison Global Opportunities Fund',
    symbol: 'PRJQX',
  },
  {
    value: 'FELAX',
    label: 'FELAX,  Fidelity Advisor Semiconductors Fund',
    name: 'Fidelity Advisor Semiconductors Fund',
    symbol: 'FELAX',
  },
  {
    value: 'FRESX',
    label:
      'FRESX,  Fidelity Select Portfolios - Fidelity Real Estate Investment Portfolio',
    name: 'Fidelity Select Portfolios - Fidelity Real Estate Investment Portfolio',
    symbol: 'FRESX',
  },
  {
    value: 'FELCX',
    label: 'FELCX,  Fidelity Advisor Semiconductors Fund Class C',
    name: 'Fidelity Advisor Semiconductors Fund Class C',
    symbol: 'FELCX',
  },
  {
    value: 'CGTUX',
    label: 'CGTUX,  Columbia Global Technology Growth Fund',
    name: 'Columbia Global Technology Growth Fund',
    symbol: 'CGTUX',
  },
  {
    value: 'CTCAX',
    label: 'CTCAX,  Columbia Global Technology Growth Fund',
    name: 'Columbia Global Technology Growth Fund',
    symbol: 'CTCAX',
  },
  {
    value: 'CTHRX',
    label:
      'CTHRX,  Columbia Global Technology Growth Fund Institutional 2 Class',
    name: 'Columbia Global Technology Growth Fund Institutional 2 Class',
    symbol: 'CTHRX',
  },
  {
    value: 'PXSGX',
    label: 'PXSGX,  Virtus Equity Trust - Virtus KAR Small-Cap Growth Fund',
    name: 'Virtus Equity Trust - Virtus KAR Small-Cap Growth Fund',
    symbol: 'PXSGX',
  },
  {
    value: 'FREAX',
    label: 'FREAX,  Nuveen Real Estate Securities Fund',
    name: 'Nuveen Real Estate Securities Fund',
    symbol: 'FREAX',
  },
  {
    value: 'PSGCX',
    label: 'PSGCX,  Virtus KAR Small-Cap Growth Fund Class C',
    name: 'Virtus KAR Small-Cap Growth Fund Class C',
    symbol: 'PSGCX',
  },
  {
    value: 'CMTFX',
    label: 'CMTFX,  Columbia Global Technology Growth Fund',
    name: 'Columbia Global Technology Growth Fund',
    symbol: 'CMTFX',
  },
  {
    value: 'CTYRX',
    label: 'CTYRX,  Columbia Global Technology Growth Fund',
    name: 'Columbia Global Technology Growth Fund',
    symbol: 'CTYRX',
  },
  {
    value: 'CTHCX',
    label: 'CTHCX,  Columbia Global Technology Growth Fund',
    name: 'Columbia Global Technology Growth Fund',
    symbol: 'CTHCX',
  },
  {
    value: 'PSGAX',
    label: 'PSGAX,  Virtus KAR Small-Cap Growth Fund Class A',
    name: 'Virtus KAR Small-Cap Growth Fund Class A',
    symbol: 'PSGAX',
  },
  {
    value: 'FARCX',
    label: 'FARCX,  Nuveen Real Estate Securities Fund Class I',
    name: 'Nuveen Real Estate Securities Fund Class I',
    symbol: 'FARCX',
  },
  {
    value: 'HGOAX',
    label: 'HGOAX,  The Hartford Growth Opportunities Fund',
    name: 'The Hartford Growth Opportunities Fund',
    symbol: 'HGOAX',
  },
  {
    value: 'FRSSX',
    label: 'FRSSX,  Nuveen Real Estate Securities Fund Class R3',
    name: 'Nuveen Real Estate Securities Fund Class R3',
    symbol: 'FRSSX',
  },
  {
    value: 'HGOSX',
    label: 'HGOSX,  The Hartford Growth Opportunities Fund Class R4',
    name: 'The Hartford Growth Opportunities Fund Class R4',
    symbol: 'HGOSX',
  },
  {
    value: 'FBSOX',
    label: 'FBSOX,  Fidelity Select IT Services Portfolio',
    name: 'Fidelity Select IT Services Portfolio',
    symbol: 'FBSOX',
  },
  {
    value: 'ARYCX',
    label: 'ARYCX,  American Century Real Estate Fund',
    name: 'American Century Real Estate Fund',
    symbol: 'ARYCX',
  },
  {
    value: 'AIGYX',
    label: 'AIGYX,  abrdn Realty Income & Growth Fund Institutional Class',
    name: 'abrdn Realty Income & Growth Fund Institutional Class',
    symbol: 'AIGYX',
  },
  {
    value: 'AIAGX',
    label: 'AIAGX,  abrdn Realty Income & Growth Fund',
    name: 'abrdn Realty Income & Growth Fund',
    symbol: 'AIAGX',
  },
  {
    value: 'SGRHX',
    label: 'SGRHX,  Allspring Growth Fund - Class R6',
    name: 'Allspring Growth Fund - Class R6',
    symbol: 'SGRHX',
  },
  {
    value: 'SGRNX',
    label: 'SGRNX,  Allspring Growth Fund',
    name: 'Allspring Growth Fund',
    symbol: 'SGRNX',
  },
  {
    value: 'SGRKX',
    label: 'SGRKX,  Allspring Growth Fund',
    name: 'Allspring Growth Fund',
    symbol: 'SGRKX',
  },
  {
    value: 'SGRAX',
    label: 'SGRAX,  Allspring Growth Fund - Class A',
    name: 'Allspring Growth Fund - Class A',
    symbol: 'SGRAX',
  },
  {
    value: 'MGLBX',
    label: 'MGLBX,  Marsico Global Fund',
    name: 'Marsico Global Fund',
    symbol: 'MGLBX',
  },
  {
    value: 'NWSAX',
    label: 'NWSAX,  Nationwide Small Company Growth Fund Class A',
    name: 'Nationwide Small Company Growth Fund Class A',
    symbol: 'NWSAX',
  },
  {
    value: 'CSRSX',
    label: 'CSRSX,  Cohen & Steers Realty Shares Fund',
    name: 'Cohen & Steers Realty Shares Fund',
    symbol: 'CSRSX',
  },
  {
    value: 'CSRIX',
    label: 'CSRIX,  Cohen & Steers Institutional Realty Shares, Inc.',
    name: 'Cohen & Steers Institutional Realty Shares, Inc.',
    symbol: 'CSRIX',
  },
  {
    value: 'BCSIX',
    label: 'BCSIX,  Brown Capital Management Small Company Fund',
    name: 'Brown Capital Management Small Company Fund',
    symbol: 'BCSIX',
  },
  {
    value: 'BCSSX',
    label:
      'BCSSX,  Brown Capital Management Small Company Fund Institutional Shares',
    name: 'Brown Capital Management Small Company Fund Institutional Shares',
    symbol: 'BCSSX',
  },
  {
    value: 'PRGTX',
    label: 'PRGTX,  T. Rowe Price Global Technology Fund',
    name: 'T. Rowe Price Global Technology Fund',
    symbol: 'PRGTX',
  },
  {
    value: 'BFTIX',
    label: 'BFTIX,  Baron Fifth Avenue Growth Fund',
    name: 'Baron Fifth Avenue Growth Fund',
    symbol: 'BFTIX',
  },
  {
    value: 'SSETX',
    label: 'SSETX,  BNY Mellon Growth Fund Class I',
    name: 'BNY Mellon Growth Fund Class I',
    symbol: 'SSETX',
  },
  {
    value: 'FSDAX',
    label: 'FSDAX,  Fidelity Select Defense & Aerospace Portfolio',
    name: 'Fidelity Select Defense & Aerospace Portfolio',
    symbol: 'FSDAX',
  },
  {
    value: 'QUAYX',
    label: 'QUAYX,  AB Small Cap Growth Portfolio Advisor Class',
    name: 'AB Small Cap Growth Portfolio Advisor Class',
    symbol: 'QUAYX',
  },
  {
    value: 'AGOZX',
    label: 'AGOZX,  Alger Small Cap Focus Fund',
    name: 'Alger Small Cap Focus Fund',
    symbol: 'AGOZX',
  },
  {
    value: 'WHOSX',
    label: 'WHOSX,  Wasatch-Hoisington U.S. Treasury Fund',
    name: 'Wasatch-Hoisington U.S. Treasury Fund',
    symbol: 'WHOSX',
  },
  {
    value: 'CSXAX',
    label: 'CSXAX,  Calvert US Large-Cap Core Responsible Index Fund',
    name: 'Calvert US Large-Cap Core Responsible Index Fund',
    symbol: 'CSXAX',
  },
  {
    value: 'GLFOX',
    label: 'GLFOX,  Lazard Global Listed Infrastructure Portfolio',
    name: 'Lazard Global Listed Infrastructure Portfolio',
    symbol: 'GLFOX',
  },
  {
    value: 'GLIFX',
    label:
      'GLIFX,  Lazard Global Listed Infrastructure Portfolio Institutional Shares',
    name: 'Lazard Global Listed Infrastructure Portfolio Institutional Shares',
    symbol: 'GLIFX',
  },
  {
    value: 'RYCVX',
    label: 'RYCVX,  Rydex Dow 2x Strategy Fund Class H',
    name: 'Rydex Dow 2x Strategy Fund Class H',
    symbol: 'RYCVX',
  },
  {
    value: 'RYCYX',
    label: 'RYCYX,  Rydex Dow 2x Strategy Fund',
    name: 'Rydex Dow 2x Strategy Fund',
    symbol: 'RYCYX',
  },
  {
    value: 'RYLDX',
    label: 'RYLDX,  Rydex Dow 2x Strategy Fund Class A',
    name: 'Rydex Dow 2x Strategy Fund Class A',
    symbol: 'RYLDX',
  },
  {
    value: 'BSTSX',
    label: 'BSTSX,  BlackRock Technology Opportunities Fund',
    name: 'BlackRock Technology Opportunities Fund',
    symbol: 'BSTSX',
  },
  {
    value: 'CISIX',
    label: 'CISIX,  Calvert US Large-Cap Core Responsible Index Fund',
    name: 'Calvert US Large-Cap Core Responsible Index Fund',
    symbol: 'CISIX',
  },
  {
    value: 'DXQLX',
    label: 'DXQLX,  Direxion Monthly NASDAQ-100 Bull 2X Fund Investor Class',
    name: 'Direxion Monthly NASDAQ-100 Bull 2X Fund Investor Class',
    symbol: 'DXQLX',
  },
  {
    value: 'UOPIX',
    label: 'UOPIX,  ProFunds UltraNASDAQ-100 Fund Investor Class',
    name: 'ProFunds UltraNASDAQ-100 Fund Investor Class',
    symbol: 'UOPIX',
  },
  {
    value: 'UOPSX',
    label: 'UOPSX,  ProFunds UltraNASDAQ-100 Fund',
    name: 'ProFunds UltraNASDAQ-100 Fund',
    symbol: 'UOPSX',
  },
  {
    value: 'RYVLX',
    label: 'RYVLX,  Rydex NASDAQ-100 2x Strategy Fund',
    name: 'Rydex NASDAQ-100 2x Strategy Fund',
    symbol: 'RYVLX',
  },
  {
    value: 'RYVYX',
    label: 'RYVYX,  Rydex NASDAQ-100 2x Strategy Fund Class H',
    name: 'Rydex NASDAQ-100 2x Strategy Fund Class H',
    symbol: 'RYVYX',
  },
  {
    value: 'RYCCX',
    label: 'RYCCX,  Rydex NASDAQ-100 2x Strategy Fund',
    name: 'Rydex NASDAQ-100 2x Strategy Fund',
    symbol: 'RYCCX',
  },
  {
    value: 'FCPIX',
    label: 'FCPIX,  Fidelity Advisor International Cap Appreciation Fund',
    name: 'Fidelity Advisor International Cap Appreciation Fund',
    symbol: 'FCPIX',
  },
  {
    value: 'CAECX',
    label: 'CAECX,  Columbia Acorn European Fund',
    name: 'Columbia Acorn European Fund',
    symbol: 'CAECX',
  },
  {
    value: 'PASSX',
    label: 'PASSX,  T. Rowe Price Small-Cap Stock Fund',
    name: 'T. Rowe Price Small-Cap Stock Fund',
    symbol: 'PASSX',
  },
  {
    value: 'RRCOX',
    label: 'RRCOX,  T. Rowe Price U.S. Equity Research Fund',
    name: 'T. Rowe Price U.S. Equity Research Fund',
    symbol: 'RRCOX',
  },
  {
    value: 'TRSSX',
    label: 'TRSSX,  T. Rowe Price Institutional Small-Cap Stock Fund',
    name: 'T. Rowe Price Institutional Small-Cap Stock Fund',
    symbol: 'TRSSX',
  },
  {
    value: 'NSCCX',
    label: 'NSCCX,  Nuveen Small-Cap Value Opportunities Fund Class C',
    name: 'Nuveen Small-Cap Value Opportunities Fund Class C',
    symbol: 'NSCCX',
  },
  {
    value: 'VITNX',
    label:
      'VITNX,  Vanguard Institutional Total Stock Market Index Fund Institutional Shares',
    name: 'Vanguard Institutional Total Stock Market Index Fund Institutional Shares',
    symbol: 'VITNX',
  },
  {
    value: 'VITPX',
    label: 'VITPX,  Vanguard Institutional Total Stock Market Index Fund',
    name: 'Vanguard Institutional Total Stock Market Index Fund',
    symbol: 'VITPX',
  },
  {
    value: 'MFOMX',
    label: 'MFOMX,  BNY Mellon Focused Equity Opportunities Fund',
    name: 'BNY Mellon Focused Equity Opportunities Fund',
    symbol: 'MFOMX',
  },
  {
    value: 'MMECX',
    label: 'MMECX,  Victory Integrity Discovery Fund',
    name: 'Victory Integrity Discovery Fund',
    symbol: 'MMECX',
  },
  {
    value: 'OTCFX',
    label: 'OTCFX,  T. Rowe Price Small-Cap Stock Fund',
    name: 'T. Rowe Price Small-Cap Stock Fund',
    symbol: 'OTCFX',
  },
  {
    value: 'FSKAX',
    label: 'FSKAX,  Fidelity Total Market Index Fund',
    name: 'Fidelity Total Market Index Fund',
    symbol: 'FSKAX',
  },
  {
    value: 'NWGQX',
    label: 'NWGQX,  Nationwide WCM Focused Small Cap Fund Class C',
    name: 'Nationwide WCM Focused Small Cap Fund Class C',
    symbol: 'NWGQX',
  },
  {
    value: 'JPFAX',
    label: 'JPFAX,  JPMorgan Equity Focus Fund',
    name: 'JPMorgan Equity Focus Fund',
    symbol: 'JPFAX',
  },
  {
    value: 'JACRX',
    label: 'JACRX,  Easterly Global Real Estate Fund',
    name: 'Easterly Global Real Estate Fund',
    symbol: 'JACRX',
  },
  {
    value: 'PFPRX',
    label: 'PFPRX,  Parnassus Mid Cap Growth Fund',
    name: 'Parnassus Mid Cap Growth Fund',
    symbol: 'PFPRX',
  },
  {
    value: 'PARNX',
    label: 'PARNX,  Parnassus Mid Cap Growth Fund - Investor',
    name: 'Parnassus Mid Cap Growth Fund - Investor',
    symbol: 'PARNX',
  },
  {
    value: 'FWWFX',
    label: 'FWWFX,  Fidelity Worldwide Fund',
    name: 'Fidelity Worldwide Fund',
    symbol: 'FWWFX',
  },
  {
    value: 'GRISX',
    label: 'GRISX,  Nationwide S&P 500 Index Fund Institutional Service Class',
    name: 'Nationwide S&P 500 Index Fund Institutional Service Class',
    symbol: 'GRISX',
  },
  {
    value: 'FCPAX',
    label: 'FCPAX,  Fidelity Advisor International Cap Appreciation Fund',
    name: 'Fidelity Advisor International Cap Appreciation Fund',
    symbol: 'FCPAX',
  },
  {
    value: 'JAREX',
    label: 'JAREX,  Easterly Global Real Estate Fund Class A',
    name: 'Easterly Global Real Estate Fund Class A',
    symbol: 'JAREX',
  },
  {
    value: 'FWIFX',
    label: 'FWIFX,  Fidelity Worldwide Fund',
    name: 'Fidelity Worldwide Fund',
    symbol: 'FWIFX',
  },
  {
    value: 'FIVFX',
    label: 'FIVFX,  Fidelity International Capital Appreciation Fund',
    name: 'Fidelity International Capital Appreciation Fund',
    symbol: 'FIVFX',
  },
  {
    value: 'MFOIX',
    label:
      'MFOIX,  BNY Mellon Focused Equity Opportunities Fund Investor Shares',
    name: 'BNY Mellon Focused Equity Opportunities Fund Investor Shares',
    symbol: 'MFOIX',
  },
  {
    value: 'NEFOX',
    label: 'NEFOX,  Natixis Funds Trust II Oakmark Fund',
    name: 'Natixis Funds Trust II Oakmark Fund',
    symbol: 'NEFOX',
  },
  {
    value: 'PAGLX',
    label: 'PAGLX,  T. Rowe Price Global Growth Stock Fund',
    name: 'T. Rowe Price Global Growth Stock Fund',
    symbol: 'PAGLX',
  },
  {
    value: 'NEFJX',
    label: 'NEFJX,  Natixis Funds Trust I Vaughan Nelson Small Cap Value Fund',
    name: 'Natixis Funds Trust I Vaughan Nelson Small Cap Value Fund',
    symbol: 'NEFJX',
  },
  {
    value: 'VLAIX',
    label: 'VLAIX,  Value Line Asset Allocation Fund Institutional Class',
    name: 'Value Line Asset Allocation Fund Institutional Class',
    symbol: 'VLAIX',
  },
  {
    value: 'FWCFX',
    label: 'FWCFX,  Fidelity Worldwide Fund',
    name: 'Fidelity Worldwide Fund',
    symbol: 'FWCFX',
  },
  {
    value: 'TGIHX',
    label: 'TGIHX,  TIAA-CREF Growth & Income Fund',
    name: 'TIAA-CREF Growth & Income Fund',
    symbol: 'TGIHX',
  },
  {
    value: 'FWAFX',
    label: 'FWAFX,  Fidelity Advisor Worldwide Fund Class A',
    name: 'Fidelity Advisor Worldwide Fund Class A',
    symbol: 'FWAFX',
  },
  {
    value: 'RCLIX',
    label: 'RCLIX,  T. Rowe Price U.S. Large-Cap Core Fund',
    name: 'T. Rowe Price U.S. Large-Cap Core Fund',
    symbol: 'RCLIX',
  },
  {
    value: 'VLAAX',
    label: 'VLAAX,  Value Line Asset Allocation Fund Investor Class',
    name: 'Value Line Asset Allocation Fund Investor Class',
    symbol: 'VLAAX',
  },
  {
    value: 'TRULX',
    label: 'TRULX,  T. Rowe Price U.S. Large-Cap Core Fund',
    name: 'T. Rowe Price U.S. Large-Cap Core Fund',
    symbol: 'TRULX',
  },
  {
    value: 'WMLIX',
    label: 'WMLIX,  Wilmington Large-Cap Strategy Fund Class Institutional',
    name: 'Wilmington Large-Cap Strategy Fund Class Institutional',
    symbol: 'WMLIX',
  },
  {
    value: 'SCURX',
    label:
      'SCURX,  Hartford Schroders US Small Cap Opportunities Fund Class SDR',
    name: 'Hartford Schroders US Small Cap Opportunities Fund Class SDR',
    symbol: 'SCURX',
  },
  {
    value: 'PACOX',
    label: 'PACOX,  T. Rowe Price U.S. Equity Research Fund',
    name: 'T. Rowe Price U.S. Equity Research Fund',
    symbol: 'PACOX',
  },
  {
    value: 'POSKX',
    label: 'POSKX,  PRIMECAP Odyssey Stock Fund',
    name: 'PRIMECAP Odyssey Stock Fund',
    symbol: 'POSKX',
  },
  {
    value: 'PAULX',
    label: 'PAULX,  T. Rowe Price U.S. Large-Cap Core Fund',
    name: 'T. Rowe Price U.S. Large-Cap Core Fund',
    symbol: 'PAULX',
  },
  {
    value: 'SVFYX',
    label: 'SVFYX,  Smead Value Fund',
    name: 'Smead Value Fund',
    symbol: 'SVFYX',
  },
  {
    value: 'SCUIX',
    label: 'SCUIX,  Hartford Schroders US Small Cap Opportunities Fund',
    name: 'Hartford Schroders US Small Cap Opportunities Fund',
    symbol: 'SCUIX',
  },
  {
    value: 'HOOTX',
    label: 'HOOTX,  Hartford Schroders US Small Cap Opportunities Fund',
    name: 'Hartford Schroders US Small Cap Opportunities Fund',
    symbol: 'HOOTX',
  },
  {
    value: 'FSMDX',
    label: 'FSMDX,  Fidelity Mid Cap Index Fund',
    name: 'Fidelity Mid Cap Index Fund',
    symbol: 'FSMDX',
  },
  {
    value: 'HOOSX',
    label: 'HOOSX,  Hartford Schroders US Small Cap Opportunities Fund',
    name: 'Hartford Schroders US Small Cap Opportunities Fund',
    symbol: 'HOOSX',
  },
  {
    value: 'FCTGX',
    label: 'FCTGX,  Fidelity Advisor Small Cap Growth Fund Class M',
    name: 'Fidelity Advisor Small Cap Growth Fund Class M',
    symbol: 'FCTGX',
  },
  {
    value: 'FWTFX',
    label: 'FWTFX,  Fidelity Advisor Worldwide Fund Class M',
    name: 'Fidelity Advisor Worldwide Fund Class M',
    symbol: 'FWTFX',
  },
  {
    value: 'SVFDX',
    label: 'SVFDX,  Smead Value Fund Class R1',
    name: 'Smead Value Fund Class R1',
    symbol: 'SVFDX',
  },
  {
    value: 'BUFOX',
    label: 'BUFOX,  Buffalo Early Stage Growth Fund',
    name: 'Buffalo Early Stage Growth Fund',
    symbol: 'BUFOX',
  },
  {
    value: 'LSCRX',
    label: 'LSCRX,  Loomis Sayles Small Capital Value Fund Retail Class',
    name: 'Loomis Sayles Small Capital Value Fund Retail Class',
    symbol: 'LSCRX',
  },
  {
    value: 'SVFKX',
    label: 'SVFKX,  Smead Value Fund Class R2',
    name: 'Smead Value Fund Class R2',
    symbol: 'SVFKX',
  },
  {
    value: 'JARIX',
    label: 'JARIX,  Easterly Global Real Estate Fund Class I',
    name: 'Easterly Global Real Estate Fund Class I',
    symbol: 'JARIX',
  },
  {
    value: 'JMUEX',
    label: 'JMUEX,  JPMorgan U.S. Equity Fund',
    name: 'JPMorgan U.S. Equity Fund',
    symbol: 'JMUEX',
  },
  {
    value: 'FIDZX',
    label:
      'FIDZX,  Fidelity Advisor International Capital Appreciation Fund Class Z',
    name: 'Fidelity Advisor International Capital Appreciation Fund Class Z',
    symbol: 'FIDZX',
  },
  {
    value: 'SVFAX',
    label: 'SVFAX,  Smead Value Fund Class A',
    name: 'Smead Value Fund Class A',
    symbol: 'SVFAX',
  },
  {
    value: 'SCUVX',
    label: 'SCUVX,  Hartford Schroders US Small Cap Opportunities Fund Class A',
    name: 'Hartford Schroders US Small Cap Opportunities Fund Class A',
    symbol: 'SCUVX',
  },
  {
    value: 'FCPCX',
    label: 'FCPCX,  Fidelity Advisor International Cap Appreciation Fund',
    name: 'Fidelity Advisor International Cap Appreciation Fund',
    symbol: 'FCPCX',
  },
  {
    value: 'NEJYX',
    label:
      'NEJYX,  Natixis Funds Trust I Vaughan Nelson Small Cap Value Fund Class Y',
    name: 'Natixis Funds Trust I Vaughan Nelson Small Cap Value Fund Class Y',
    symbol: 'NEJYX',
  },
  {
    value: 'LSVAX',
    label: 'LSVAX,  Loomis Sayles Small Cap Value Fund',
    name: 'Loomis Sayles Small Cap Value Fund',
    symbol: 'LSVAX',
  },
  {
    value: 'LSCNX',
    label: 'LSCNX,  Loomis Sayles Small Cap Value Fund',
    name: 'Loomis Sayles Small Cap Value Fund',
    symbol: 'LSCNX',
  },
  {
    value: 'LSSCX',
    label: 'LSSCX,  Loomis Sayles Small Cap Value Fund',
    name: 'Loomis Sayles Small Cap Value Fund',
    symbol: 'LSSCX',
  },
  {
    value: 'VSEQX',
    label: 'VSEQX,  Vanguard Horizon Funds - Vanguard Strategic Equity Fund',
    name: 'Vanguard Horizon Funds - Vanguard Strategic Equity Fund',
    symbol: 'VSEQX',
  },
  {
    value: 'JUEZX',
    label: 'JUEZX,  JPMorgan U.S. Equity Fund',
    name: 'JPMorgan U.S. Equity Fund',
    symbol: 'JUEZX',
  },
  {
    value: 'MMDEX',
    label: 'MMDEX,  Praxis Mutual Funds - Praxis Growth Index Fund',
    name: 'Praxis Mutual Funds - Praxis Growth Index Fund',
    symbol: 'MMDEX',
  },
  {
    value: 'SMVLX',
    label: 'SMVLX,  Smead Value Fund Investor Class',
    name: 'Smead Value Fund Investor Class',
    symbol: 'SMVLX',
  },
  {
    value: 'PAVLX',
    label: 'PAVLX,  T. Rowe Price Value Fund',
    name: 'T. Rowe Price Value Fund',
    symbol: 'PAVLX',
  },
  {
    value: 'PRILX',
    label: 'PRILX,  Parnassus Core Equity Fund - Institutional Shares',
    name: 'Parnassus Core Equity Fund - Institutional Shares',
    symbol: 'PRILX',
  },
  {
    value: 'FIDGX',
    label: 'FIDGX,  Fidelity Securities Fund - Fidelity Small Cap Growth Fund',
    name: 'Fidelity Securities Fund - Fidelity Small Cap Growth Fund',
    symbol: 'FIDGX',
  },
  {
    value: 'DSCVX',
    label: 'DSCVX,  BNY Mellon Opportunistic Small Cap Fund',
    name: 'BNY Mellon Opportunistic Small Cap Fund',
    symbol: 'DSCVX',
  },
  {
    value: 'FCIGX',
    label: 'FCIGX,  Fidelity Small Cap Growth Fund',
    name: 'Fidelity Small Cap Growth Fund',
    symbol: 'FCIGX',
  },
  {
    value: 'TRVLX',
    label: 'TRVLX,  T. Rowe Price Value Fund, Inc.',
    name: 'T. Rowe Price Value Fund, Inc.',
    symbol: 'TRVLX',
  },
  {
    value: 'FTUCX',
    label: 'FTUCX,  Fidelity Advisor Telecommunications Fund Class C',
    name: 'Fidelity Advisor Telecommunications Fund Class C',
    symbol: 'FTUCX',
  },
  {
    value: 'FSTCX',
    label: 'FSTCX,  Fidelity Select Telecommunications Portfolio',
    name: 'Fidelity Select Telecommunications Portfolio',
    symbol: 'FSTCX',
  },
  {
    value: 'TIIRX',
    label: 'TIIRX,  TIAA-CREF Growth & Income Fund',
    name: 'TIAA-CREF Growth & Income Fund',
    symbol: 'TIIRX',
  },
  {
    value: 'FTUIX',
    label: 'FTUIX,  Fidelity Select Telecommunications Portfolio',
    name: 'Fidelity Select Telecommunications Portfolio',
    symbol: 'FTUIX',
  },
  {
    value: 'AIAFX',
    label: 'AIAFX,  abrdn Global Infrastructure Fund Class A',
    name: 'abrdn Global Infrastructure Fund Class A',
    symbol: 'AIAFX',
  },
  {
    value: 'HMDCX',
    label: 'HMDCX,  The Hartford MidCap Fund Class C',
    name: 'The Hartford MidCap Fund Class C',
    symbol: 'HMDCX',
  },
  {
    value: 'FTUAX',
    label: 'FTUAX,  Fidelity Select Telecommunications Portfolio',
    name: 'Fidelity Select Telecommunications Portfolio',
    symbol: 'FTUAX',
  },
  {
    value: 'TRGIX',
    label: 'TRGIX,  TIAA-CREF Growth & Income Fund',
    name: 'TIAA-CREF Growth & Income Fund',
    symbol: 'TRGIX',
  },
  {
    value: 'CLCRX',
    label:
      'CLCRX,  Columbia Funds Series Trust - Columbia Select Large Cap Equity Fund',
    name: 'Columbia Funds Series Trust - Columbia Select Large Cap Equity Fund',
    symbol: 'CLCRX',
  },
  {
    value: 'BUFIX',
    label: 'BUFIX,  Buffalo International Fund',
    name: 'Buffalo International Fund',
    symbol: 'BUFIX',
  },
  {
    value: 'FCAGX',
    label: 'FCAGX,  Fidelity Small Cap Growth Fund',
    name: 'Fidelity Small Cap Growth Fund',
    symbol: 'FCAGX',
  },
  {
    value: 'PRBLX',
    label: 'PRBLX,  Parnassus Core Equity Fund - Investor Shares',
    name: 'Parnassus Core Equity Fund - Investor Shares',
    symbol: 'PRBLX',
  },
  {
    value: 'CAMYX',
    label: 'CAMYX,  Cambiar International Equity Fund',
    name: 'Cambiar International Equity Fund',
    symbol: 'CAMYX',
  },
  {
    value: 'CAMIX',
    label: 'CAMIX,  Cambiar International Equity Fund Investor Class',
    name: 'Cambiar International Equity Fund Investor Class',
    symbol: 'CAMIX',
  },
  {
    value: 'DTSVX',
    label:
      'DTSVX,  Wilshire Mutual Funds, Inc. - Small Company Value Portfolio',
    name: 'Wilshire Mutual Funds, Inc. - Small Company Value Portfolio',
    symbol: 'DTSVX',
  },
  {
    value: 'WEMMX',
    label: 'WEMMX,  TETON Westwood Mighty Mites Fund',
    name: 'TETON Westwood Mighty Mites Fund',
    symbol: 'WEMMX',
  },
  {
    value: 'SMGIX',
    label: 'SMGIX,  Columbia Contrarian Core Fund',
    name: 'Columbia Contrarian Core Fund',
    symbol: 'SMGIX',
  },
  {
    value: 'HFMVX',
    label: 'HFMVX,  The Hartford MidCap Fund Class R6',
    name: 'The Hartford MidCap Fund Class R6',
    symbol: 'HFMVX',
  },
  {
    value: 'FCCGX',
    label: 'FCCGX,  Fidelity Advisor Small Cap Growth Fund Class C',
    name: 'Fidelity Advisor Small Cap Growth Fund Class C',
    symbol: 'FCCGX',
  },
  {
    value: 'CDOFX',
    label: 'CDOFX,  Crawford Small Cap Dividend Fund',
    name: 'Crawford Small Cap Dividend Fund',
    symbol: 'CDOFX',
  },
  {
    value: 'LCCCX',
    label: 'LCCCX,  Columbia Contrarian Core Fund',
    name: 'Columbia Contrarian Core Fund',
    symbol: 'LCCCX',
  },
  {
    value: 'LGRNX',
    label: 'LGRNX,  Loomis Sayles Funds II - Loomis Sayles Growth Fund',
    name: 'Loomis Sayles Funds II - Loomis Sayles Growth Fund',
    symbol: 'LGRNX',
  },
  {
    value: 'DSCYX',
    label: 'DSCYX,  BNY Mellon Opportunistic Small Cap Fund',
    name: 'BNY Mellon Opportunistic Small Cap Fund',
    symbol: 'DSCYX',
  },
  {
    value: 'WMMAX',
    label: 'WMMAX,  TETON Westwood Mighty Mites Fund',
    name: 'TETON Westwood Mighty Mites Fund',
    symbol: 'WMMAX',
  },
  {
    value: 'VQSRX',
    label: 'VQSRX,  Virtus KAR Small-Cap Value Fund',
    name: 'Virtus KAR Small-Cap Value Fund',
    symbol: 'VQSRX',
  },
  {
    value: 'TRPIX',
    label: 'TRPIX,  T. Rowe Price Value Fund',
    name: 'T. Rowe Price Value Fund',
    symbol: 'TRPIX',
  },
  {
    value: 'BARIX',
    label: 'BARIX,  Baron Asset Fund',
    name: 'Baron Asset Fund',
    symbol: 'BARIX',
  },
  {
    value: 'DOPIX',
    label: 'DOPIX,  BNY Mellon Opportunistic Small Cap Fund Class I',
    name: 'BNY Mellon Opportunistic Small Cap Fund Class I',
    symbol: 'DOPIX',
  },
  {
    value: 'VSTCX',
    label: 'VSTCX,  Vanguard Strategic Small-Cap Equity Fund Investor Shares',
    name: 'Vanguard Strategic Small-Cap Equity Fund Investor Shares',
    symbol: 'VSTCX',
  },
  {
    value: 'JSMTX',
    label: 'JSMTX,  Jackson Square SMID-Cap Growth Fund',
    name: 'Jackson Square SMID-Cap Growth Fund',
    symbol: 'JSMTX',
  },
  {
    value: 'MIMSX',
    label: 'MIMSX,  BNY Mellon Mid Cap Multi-Strategy Fund Class Investor',
    name: 'BNY Mellon Mid Cap Multi-Strategy Fund Class Investor',
    symbol: 'MIMSX',
  },
  {
    value: 'DCGTX',
    label: 'DCGTX,  Jackson Square SMID-Cap Growth Fund IS Class',
    name: 'Jackson Square SMID-Cap Growth Fund IS Class',
    symbol: 'DCGTX',
  },
  {
    value: 'WEIMX',
    label: 'WEIMX,  TETON Westwood Mighty Mites Class Institutional',
    name: 'TETON Westwood Mighty Mites Class Institutional',
    symbol: 'WEIMX',
  },
  {
    value: 'CCCRX',
    label: 'CCCRX,  Columbia Contrarian Core Fund',
    name: 'Columbia Contrarian Core Fund',
    symbol: 'CCCRX',
  },
  {
    value: 'JENHX',
    label: 'JENHX,  Johnson Enhanced Return Fund',
    name: 'Johnson Enhanced Return Fund',
    symbol: 'JENHX',
  },
  {
    value: 'CSVIX',
    label: 'CSVIX,  Calvert Small-Cap Fund',
    name: 'Calvert Small-Cap Fund',
    symbol: 'CSVIX',
  },
  {
    value: 'CSCCX',
    label: 'CSCCX,  Calvert Small-Cap Fund',
    name: 'Calvert Small-Cap Fund',
    symbol: 'CSCCX',
  },
  {
    value: 'BARAX',
    label: 'BARAX,  Baron Investment Funds Trust - Baron Asset Fund',
    name: 'Baron Investment Funds Trust - Baron Asset Fund',
    symbol: 'BARAX',
  },
  {
    value: 'TAREX',
    label: 'TAREX,  Third Avenue Real Estate Value Fund Institutional Class',
    name: 'Third Avenue Real Estate Value Fund Institutional Class',
    symbol: 'TAREX',
  },
  {
    value: 'VPCCX',
    label: 'VPCCX,  Vanguard PRIMECAP Core Fund Investor Shares',
    name: 'Vanguard PRIMECAP Core Fund Investor Shares',
    symbol: 'VPCCX',
  },
  {
    value: 'COFYX',
    label:
      'COFYX,  Columbia Funds Series Trust I - Columbia Contrarian Core Fund',
    name: 'Columbia Funds Series Trust I - Columbia Contrarian Core Fund',
    symbol: 'COFYX',
  },
  {
    value: 'CORRX',
    label: 'CORRX,  Columbia Contrarian Core Fund',
    name: 'Columbia Contrarian Core Fund',
    symbol: 'CORRX',
  },
  {
    value: 'JSMVX',
    label: 'JSMVX,  Jackson Square SMID-Cap Growth Fund',
    name: 'Jackson Square SMID-Cap Growth Fund',
    symbol: 'JSMVX',
  },
  {
    value: 'BARUX',
    label: 'BARUX,  Baron Asset Fund',
    name: 'Baron Asset Fund',
    symbol: 'BARUX',
  },
  {
    value: 'HFMSX',
    label: 'HFMSX,  The Hartford MidCap Fund',
    name: 'The Hartford MidCap Fund',
    symbol: 'HFMSX',
  },
  {
    value: 'CCVAX',
    label: 'CCVAX,  Calvert Small-Cap Fund',
    name: 'Calvert Small-Cap Fund',
    symbol: 'CCVAX',
  },
  {
    value: 'SGIEX',
    label: 'SGIEX,  Columbia Contrarian Core Fund',
    name: 'Columbia Contrarian Core Fund',
    symbol: 'SGIEX',
  },
  {
    value: 'WMMCX',
    label: 'WMMCX,  TETON Westwood Mighty Mites Fund Class C',
    name: 'TETON Westwood Mighty Mites Fund Class C',
    symbol: 'WMMCX',
  },
  {
    value: 'FSSNX',
    label: 'FSSNX,  Fidelity Small Cap Index Fund',
    name: 'Fidelity Small Cap Index Fund',
    symbol: 'FSSNX',
  },
  {
    value: 'WSMVX',
    label: 'WSMVX,  Wilshire Small Company Value Portfolio',
    name: 'Wilshire Small Company Value Portfolio',
    symbol: 'WSMVX',
  },
  {
    value: 'IMIDX',
    label: 'IMIDX,  Congress Mid Cap Growth Fund Institutional Class',
    name: 'Congress Mid Cap Growth Fund Institutional Class',
    symbol: 'IMIDX',
  },
  {
    value: 'JANEX',
    label: 'JANEX,  Janus Henderson Enterprise Fund Class D',
    name: 'Janus Henderson Enterprise Fund Class D',
    symbol: 'JANEX',
  },
  {
    value: 'JDMAX',
    label: 'JDMAX,  Janus Henderson Enterprise Fund Class A',
    name: 'Janus Henderson Enterprise Fund Class A',
    symbol: 'JDMAX',
  },
  {
    value: 'JMGRX',
    label: 'JMGRX,  Janus Henderson Enterprise Fund',
    name: 'Janus Henderson Enterprise Fund',
    symbol: 'JMGRX',
  },
  {
    value: 'HFMIX',
    label: 'HFMIX,  The Hartford MidCap Fund',
    name: 'The Hartford MidCap Fund',
    symbol: 'HFMIX',
  },
  {
    value: 'JDMNX',
    label: 'JDMNX,  Janus Investment Fund - Janus Henderson Enterprise Fund',
    name: 'Janus Investment Fund - Janus Henderson Enterprise Fund',
    symbol: 'JDMNX',
  },
  {
    value: 'SSPSX',
    label: 'SSPSX,  State Street Institutional Premier Growth Equity Fund',
    name: 'State Street Institutional Premier Growth Equity Fund',
    symbol: 'SSPSX',
  },
  {
    value: 'JGRTX',
    label: 'JGRTX,  Janus Henderson Enterprise Fund Class S',
    name: 'Janus Henderson Enterprise Fund Class S',
    symbol: 'JGRTX',
  },
  {
    value: 'JAENX',
    label: 'JAENX,  Janus Henderson Enterprise Fund Class T',
    name: 'Janus Henderson Enterprise Fund Class T',
    symbol: 'JAENX',
  },
  {
    value: 'JDMRX',
    label: 'JDMRX,  Janus Henderson Enterprise Fund',
    name: 'Janus Henderson Enterprise Fund',
    symbol: 'JDMRX',
  },
  {
    value: 'JGRCX',
    label: 'JGRCX,  Janus Henderson Enterprise Fund',
    name: 'Janus Henderson Enterprise Fund',
    symbol: 'JGRCX',
  },
  {
    value: 'CMIDX',
    label: 'CMIDX,  Congress Mid Cap Growth Fund',
    name: 'Congress Mid Cap Growth Fund',
    symbol: 'CMIDX',
  },
  {
    value: 'NEFSX',
    label:
      'NEFSX,  Natixis Funds Trust I U.S. Equity Opportunities Fund Class A',
    name: 'Natixis Funds Trust I U.S. Equity Opportunities Fund Class A',
    symbol: 'NEFSX',
  },
  {
    value: 'EAFGX',
    label: 'EAFGX,  Eaton Vance Focused Growth Opportunities Fund Class A',
    name: 'Eaton Vance Focused Growth Opportunities Fund Class A',
    symbol: 'EAFGX',
  },
  {
    value: 'FSPCX',
    label: 'FSPCX,  Fidelity Select Insurance Portfolio',
    name: 'Fidelity Select Insurance Portfolio',
    symbol: 'FSPCX',
  },
  {
    value: 'JMCEX',
    label: 'JMCEX,  JPMorgan Mid Cap Equity Fund',
    name: 'JPMorgan Mid Cap Equity Fund',
    symbol: 'JMCEX',
  },
  {
    value: 'NSDVX',
    label: 'NSDVX,  North Star Dividend Fund Class I',
    name: 'North Star Dividend Fund Class I',
    symbol: 'NSDVX',
  },
  {
    value: 'PRGSX',
    label: 'PRGSX,  T. Rowe Price Global Stock Fund',
    name: 'T. Rowe Price Global Stock Fund',
    symbol: 'PRGSX',
  },
  {
    value: 'PRDMX',
    label: 'PRDMX,  T. Rowe Price Diversified Mid Cap Growth Fund',
    name: 'T. Rowe Price Diversified Mid Cap Growth Fund',
    symbol: 'PRDMX',
  },
  {
    value: 'LGRCX',
    label: 'LGRCX,  Loomis Sayles Growth Fund Class C',
    name: 'Loomis Sayles Growth Fund Class C',
    symbol: 'LGRCX',
  },
  {
    value: 'TVRVX',
    label: 'TVRVX,  Third Avenue Real Estate Value Fund Investor Class',
    name: 'Third Avenue Real Estate Value Fund Investor Class',
    symbol: 'TVRVX',
  },
  {
    value: 'JCMAX',
    label: 'JCMAX,  JPMorgan Mid Cap Equity Fund',
    name: 'JPMorgan Mid Cap Equity Fund',
    symbol: 'JCMAX',
  },
  {
    value: 'VSNGX',
    label: 'VSNGX,  JPMorgan Mid Cap Equity Fund Class I',
    name: 'JPMorgan Mid Cap Equity Fund Class I',
    symbol: 'VSNGX',
  },
  {
    value: 'TWCAX',
    label: 'TWCAX,  American Century Select Fund',
    name: 'American Century Select Fund',
    symbol: 'TWCAX',
  },
  {
    value: 'LSGRX',
    label: 'LSGRX,  Loomis Sayles Growth Fund',
    name: 'Loomis Sayles Growth Fund',
    symbol: 'LSGRX',
  },
  {
    value: 'ASDEX',
    label: 'ASDEX,  American Century Select Fund R6 Class',
    name: 'American Century Select Fund R6 Class',
    symbol: 'ASDEX',
  },
  {
    value: 'TWSIX',
    label: 'TWSIX,  American Century Select Fund',
    name: 'American Century Select Fund',
    symbol: 'TWSIX',
  },
  {
    value: 'NESYX',
    label: 'NESYX,  Natixis Funds Trust I U.S. Equity Opportunities Fund',
    name: 'Natixis Funds Trust I U.S. Equity Opportunities Fund',
    symbol: 'NESYX',
  },
  {
    value: 'HILFX',
    label: 'HILFX,  Hennessy Large Cap Financial Fund Institutional Class',
    name: 'Hennessy Large Cap Financial Fund Institutional Class',
    symbol: 'HILFX',
  },
  {
    value: 'PAGSX',
    label: 'PAGSX,  T. Rowe Price Global Stock Fund',
    name: 'T. Rowe Price Global Stock Fund',
    symbol: 'PAGSX',
  },
  {
    value: 'CFIMX',
    label: 'CFIMX,  Clipper Fund',
    name: 'Clipper Fund',
    symbol: 'CFIMX',
  },
  {
    value: 'CCSGX',
    label: 'CCSGX,  Conestoga SMid Cap Fund Institutional Class',
    name: 'Conestoga SMid Cap Fund Institutional Class',
    symbol: 'CCSGX',
  },
  {
    value: 'BUFTX',
    label: 'BUFTX,  Buffalo Discovery Fund',
    name: 'Buffalo Discovery Fund',
    symbol: 'BUFTX',
  },
  {
    value: 'TWCIX',
    label: 'TWCIX,  American Century Select Fund Investor Class',
    name: 'American Century Select Fund Investor Class',
    symbol: 'TWCIX',
  },
  {
    value: 'GWETX',
    label: 'GWETX,  AMG GW&K Small Cap Core Fund',
    name: 'AMG GW&K Small Cap Core Fund',
    symbol: 'GWETX',
  },
  {
    value: 'RAAIX',
    label: 'RAAIX,  Altegris/AACA Opportunistic Real Estate Fund I',
    name: 'Altegris/AACA Opportunistic Real Estate Fund I',
    symbol: 'RAAIX',
  },
  {
    value: 'ACSLX',
    label: 'ACSLX,  American Century Select Fund',
    name: 'American Century Select Fund',
    symbol: 'ACSLX',
  },
  {
    value: 'ASERX',
    label: 'ASERX,  American Century Select Fund',
    name: 'American Century Select Fund',
    symbol: 'ASERX',
  },
  {
    value: 'PNAIX',
    label: 'PNAIX,  T. Rowe Price All-Cap Opportunities Fund',
    name: 'T. Rowe Price All-Cap Opportunities Fund',
    symbol: 'PNAIX',
  },
  {
    value: 'AULDX',
    label: 'AULDX,  American Century Ultra Fund',
    name: 'American Century Ultra Fund',
    symbol: 'AULDX',
  },
  {
    value: 'PRWAX',
    label: 'PRWAX,  T. Rowe Price All-Cap Opportunities Fund',
    name: 'T. Rowe Price All-Cap Opportunities Fund',
    symbol: 'PRWAX',
  },
  {
    value: 'SSPGX',
    label:
      'SSPGX,  State Street Institutional Premier Growth Equity Fund Investment Class',
    name: 'State Street Institutional Premier Growth Equity Fund Investment Class',
    symbol: 'SSPGX',
  },
  {
    value: 'LGRRX',
    label: 'LGRRX,  Loomis Sayles Growth Fund',
    name: 'Loomis Sayles Growth Fund',
    symbol: 'LGRRX',
  },
  {
    value: 'RAANX',
    label: 'RAANX,  Altegris/AACA Opportunistic Real Estate Fund N',
    name: 'Altegris/AACA Opportunistic Real Estate Fund N',
    symbol: 'RAANX',
  },
  {
    value: 'RAAAX',
    label: 'RAAAX,  Altegris/AACA Opportunistic Real Estate Fund',
    name: 'Altegris/AACA Opportunistic Real Estate Fund',
    symbol: 'RAAAX',
  },
  {
    value: 'IWIRX',
    label: 'IWIRX,  Guinness Atkinson Global Innovators Fund Investor Class',
    name: 'Guinness Atkinson Global Innovators Fund Investor Class',
    symbol: 'IWIRX',
  },
  {
    value: 'PGIUX',
    label: 'PGIUX,  Virtus Duff & Phelps Global Infrastructure Fund Class I',
    name: 'Virtus Duff & Phelps Global Infrastructure Fund Class I',
    symbol: 'PGIUX',
  },
  {
    value: 'PGUAX',
    label: 'PGUAX,  Virtus Duff & Phelps Global Infrastructure Fund',
    name: 'Virtus Duff & Phelps Global Infrastructure Fund',
    symbol: 'PGUAX',
  },
  {
    value: 'PAWAX',
    label: 'PAWAX,  T. Rowe Price All-Cap Opportunities Fund',
    name: 'T. Rowe Price All-Cap Opportunities Fund',
    symbol: 'PAWAX',
  },
  {
    value: 'BREFX',
    label: 'BREFX,  Baron Real Estate Fund',
    name: 'Baron Real Estate Fund',
    symbol: 'BREFX',
  },
  {
    value: 'PEXMX',
    label: 'PEXMX,  T. Rowe Price Extended Equity Market Index Fund',
    name: 'T. Rowe Price Extended Equity Market Index Fund',
    symbol: 'PEXMX',
  },
  {
    value: 'PGUCX',
    label: 'PGUCX,  Virtus Duff & Phelps Global Infrastructure Fund',
    name: 'Virtus Duff & Phelps Global Infrastructure Fund',
    symbol: 'PGUCX',
  },
  {
    value: 'GWEIX',
    label: 'GWEIX,  AMG GW&K Small Cap Core Fund Class I',
    name: 'AMG GW&K Small Cap Core Fund Class I',
    symbol: 'GWEIX',
  },
  {
    value: 'AWEIX',
    label: 'AWEIX,  CIBC Atlas Disciplined Equity Fund Institutional Class',
    name: 'CIBC Atlas Disciplined Equity Fund Institutional Class',
    symbol: 'AWEIX',
  },
  {
    value: 'JPDEX',
    label: 'JPDEX,  JPMorgan Tax Aware Equity Fund',
    name: 'JPMorgan Tax Aware Equity Fund',
    symbol: 'JPDEX',
  },
  {
    value: 'JVTNX',
    label: 'JVTNX,  Janus Henderson Venture Fund',
    name: 'Janus Henderson Venture Fund',
    symbol: 'JVTNX',
  },
  {
    value: 'JVTSX',
    label: 'JVTSX,  Janus Henderson Venture Fund Class S',
    name: 'Janus Henderson Venture Fund Class S',
    symbol: 'JVTSX',
  },
  {
    value: 'JPEAX',
    label: 'JPEAX,  JPMorgan Tax Aware Equity Fund Class A',
    name: 'JPMorgan Tax Aware Equity Fund Class A',
    symbol: 'JPEAX',
  },
  {
    value: 'CVMAX',
    label: 'CVMAX,  Calvert Emerging Markets Equity Fund Class A',
    name: 'Calvert Emerging Markets Equity Fund Class A',
    symbol: 'CVMAX',
  },
  {
    value: 'GINNX',
    label: 'GINNX,  Guinness Atkinson Global Innovators Fund',
    name: 'Guinness Atkinson Global Innovators Fund',
    symbol: 'GINNX',
  },
  {
    value: 'JAVTX',
    label: 'JAVTX,  Janus Henderson Venture Fund Class T',
    name: 'Janus Henderson Venture Fund Class T',
    symbol: 'JAVTX',
  },
  {
    value: 'TWCCX',
    label: 'TWCCX,  American Century Ultra Fund C Class',
    name: 'American Century Ultra Fund C Class',
    symbol: 'TWCCX',
  },
  {
    value: 'ICSCX',
    label: 'ICSCX,  William Blair Small Cap Value Fund',
    name: 'William Blair Small Cap Value Fund',
    symbol: 'ICSCX',
  },
  {
    value: 'JVTIX',
    label: 'JVTIX,  Janus Henderson Venture Fund',
    name: 'Janus Henderson Venture Fund',
    symbol: 'JVTIX',
  },
  {
    value: 'POLRX',
    label: 'POLRX,  Polen Growth Fund',
    name: 'Polen Growth Fund',
    symbol: 'POLRX',
  },
  {
    value: 'FSRPX',
    label: 'FSRPX,  Fidelity Select Retailing Portfolio',
    name: 'Fidelity Select Retailing Portfolio',
    symbol: 'FSRPX',
  },
  {
    value: 'JANVX',
    label: 'JANVX,  Janus Henderson Venture Fund Class D',
    name: 'Janus Henderson Venture Fund Class D',
    symbol: 'JANVX',
  },
  {
    value: 'HLFNX',
    label: 'HLFNX,  Hennessy Large Cap Financial Fund',
    name: 'Hennessy Large Cap Financial Fund',
    symbol: 'HLFNX',
  },
  {
    value: 'JVTCX',
    label: 'JVTCX,  Janus Henderson Venture Fund',
    name: 'Janus Henderson Venture Fund',
    symbol: 'JVTCX',
  },
  {
    value: 'NNLEX',
    label: 'NNLEX,  Nicholas Limited Edition Fund',
    name: 'Nicholas Limited Edition Fund',
    symbol: 'NNLEX',
  },
  {
    value: 'SCGVX',
    label: 'SCGVX,  Sands Capital Global Growth Fund Investor Shares',
    name: 'Sands Capital Global Growth Fund Investor Shares',
    symbol: 'SCGVX',
  },
  {
    value: 'JIGZX',
    label: 'JIGZX,  JPMorgan U.S. GARP Equity Fund',
    name: 'JPMorgan U.S. GARP Equity Fund',
    symbol: 'JIGZX',
  },
  {
    value: 'QASCX',
    label: 'QASCX,  Federated Hermes MDT Small Cap Core Fund Class A',
    name: 'Federated Hermes MDT Small Cap Core Fund Class A',
    symbol: 'QASCX',
  },
  {
    value: 'JPGSX',
    label: 'JPGSX,  JPMorgan U.S. GARP Equity Fund Class I',
    name: 'JPMorgan U.S. GARP Equity Fund Class I',
    symbol: 'JPGSX',
  },
  {
    value: 'JIGAX',
    label: 'JIGAX,  JPMorgan U.S. GARP Equity Fund Class A',
    name: 'JPMorgan U.S. GARP Equity Fund Class A',
    symbol: 'JIGAX',
  },
  {
    value: 'QISCX',
    label:
      'QISCX,  Federated Hermes MDT Small Cap Core Fund Institutional Class',
    name: 'Federated Hermes MDT Small Cap Core Fund Institutional Class',
    symbol: 'QISCX',
  },
  {
    value: 'SCMGX',
    label: 'SCMGX,  Sands Capital Global Growth Fund Institutional Shares',
    name: 'Sands Capital Global Growth Fund Institutional Shares',
    symbol: 'SCMGX',
  },
  {
    value: 'VHCOX',
    label: 'VHCOX,  Vanguard Capital Opportunity Fund Investor Shares',
    name: 'Vanguard Capital Opportunity Fund Investor Shares',
    symbol: 'VHCOX',
  },
  {
    value: 'BDSKX',
    label: 'BDSKX,  BlackRock Advantage Small Cap Core Fund',
    name: 'BlackRock Advantage Small Cap Core Fund',
    symbol: 'BDSKX',
  },
  {
    value: 'JCICX',
    label: 'JCICX,  JPMorgan U.S. GARP Equity Fund',
    name: 'JPMorgan U.S. GARP Equity Fund',
    symbol: 'JCICX',
  },
  {
    value: 'BDSIX',
    label:
      'BDSIX,  BlackRock Advantage Small Cap Core Fund Institutional Shares',
    name: 'BlackRock Advantage Small Cap Core Fund Institutional Shares',
    symbol: 'BDSIX',
  },
  {
    value: 'KLCIX',
    label:
      'KLCIX,  Federated Hermes Kaufmann Large Cap Fund Institutional Shares',
    name: 'Federated Hermes Kaufmann Large Cap Fund Institutional Shares',
    symbol: 'KLCIX',
  },
  {
    value: 'JGISX',
    label: 'JGISX,  JPMorgan U.S. GARP Equity Fund',
    name: 'JPMorgan U.S. GARP Equity Fund',
    symbol: 'JGISX',
  },
  {
    value: 'JGIRX',
    label: 'JGIRX,  JPMorgan U.S. GARP Equity Fund',
    name: 'JPMorgan U.S. GARP Equity Fund',
    symbol: 'JGIRX',
  },
  {
    value: 'VGSAX',
    label:
      'VGSAX,  Virtus Duff & Phelps Global Real Estate Securities Fund Class A',
    name: 'Virtus Duff & Phelps Global Real Estate Securities Fund Class A',
    symbol: 'VGSAX',
  },
  {
    value: 'WGROX',
    label: 'WGROX,  Wasatch Core Growth Fund',
    name: 'Wasatch Core Growth Fund',
    symbol: 'WGROX',
  },
  {
    value: 'AKREX',
    label: 'AKREX,  Akre Focus Fund',
    name: 'Akre Focus Fund',
    symbol: 'AKREX',
  },
  {
    value: 'RYOCX',
    label: 'RYOCX,  Rydex NASDAQ-100 Fund',
    name: 'Rydex NASDAQ-100 Fund',
    symbol: 'RYOCX',
  },
  {
    value: 'RYATX',
    label: 'RYATX,  Rydex NASDAQ-100 Fund Class A',
    name: 'Rydex NASDAQ-100 Fund Class A',
    symbol: 'RYATX',
  },
  {
    value: 'VHCAX',
    label: 'VHCAX,  Vanguard Capital Opportunity Fund',
    name: 'Vanguard Capital Opportunity Fund',
    symbol: 'VHCAX',
  },
  {
    value: 'FGSKX',
    label: 'FGSKX,  Federated Hermes MDT Mid Cap Growth Fund',
    name: 'Federated Hermes MDT Mid Cap Growth Fund',
    symbol: 'FGSKX',
  },
  {
    value: 'RYHOX',
    label: 'RYHOX,  Rydex NASDAQ-100 Fund',
    name: 'Rydex NASDAQ-100 Fund',
    symbol: 'RYHOX',
  },
  {
    value: 'TEEAX',
    label: 'TEEAX,  RBC Small Cap Core Fund',
    name: 'RBC Small Cap Core Fund',
    symbol: 'TEEAX',
  },
  {
    value: 'NWKCX',
    label: 'NWKCX,  Nationwide Geneva Small Cap Growth Fund',
    name: 'Nationwide Geneva Small Cap Growth Fund',
    symbol: 'NWKCX',
  },
  {
    value: 'VPMAX',
    label: 'VPMAX,  Vanguard PRIMECAP Fund Admiral Shares',
    name: 'Vanguard PRIMECAP Fund Admiral Shares',
    symbol: 'VPMAX',
  },
  {
    value: 'VPMCX',
    label: 'VPMCX,  Vanguard PrimeCap Fund',
    name: 'Vanguard PrimeCap Fund',
    symbol: 'VPMCX',
  },
  {
    value: 'NWHZX',
    label: 'NWHZX,  Nationwide Geneva Small Cap Growth Fund',
    name: 'Nationwide Geneva Small Cap Growth Fund',
    symbol: 'NWHZX',
  },
  {
    value: 'SEUPX',
    label: 'SEUPX,  Guggenheim StylePlus - Mid Growth Fund',
    name: 'Guggenheim StylePlus - Mid Growth Fund',
    symbol: 'SEUPX',
  },
  {
    value: 'TMDPX',
    label: 'TMDPX,  AMG TimesSquare Mid Cap Growth Fund',
    name: 'AMG TimesSquare Mid Cap Growth Fund',
    symbol: 'TMDPX',
  },
  {
    value: 'VRGEX',
    label: 'VRGEX,  Virtus Duff & Phelps Global Real Estate Securities Fund',
    name: 'Virtus Duff & Phelps Global Real Estate Securities Fund',
    symbol: 'VRGEX',
  },
  {
    value: 'AKRSX',
    label: 'AKRSX,  Akre Focus Fund',
    name: 'Akre Focus Fund',
    symbol: 'AKRSX',
  },
  {
    value: 'AVEGX',
    label: 'AVEGX,  Ave Maria Growth Fund',
    name: 'Ave Maria Growth Fund',
    symbol: 'AVEGX',
  },
  {
    value: 'FNCMX',
    label: 'FNCMX,  Fidelity NASDAQ Composite Index Fund',
    name: 'Fidelity NASDAQ Composite Index Fund',
    symbol: 'FNCMX',
  },
  {
    value: 'VGISX',
    label: 'VGISX,  Virtus Duff & Phelps Global Real Estate Securities Fund',
    name: 'Virtus Duff & Phelps Global Real Estate Securities Fund',
    symbol: 'VGISX',
  },
  {
    value: 'LCGFX',
    label: 'LCGFX,  William Blair Large Cap Growth Fund Class I',
    name: 'William Blair Large Cap Growth Fund Class I',
    symbol: 'LCGFX',
  },
  {
    value: 'AKRIX',
    label: 'AKRIX,  Akre Focus Fund Institutional Class',
    name: 'Akre Focus Fund Institutional Class',
    symbol: 'AKRIX',
  },
  {
    value: 'QCSCX',
    label: 'QCSCX,  Federated Hermes MDT Small Cap Core Fund',
    name: 'Federated Hermes MDT Small Cap Core Fund',
    symbol: 'QCSCX',
  },
  {
    value: 'VGSCX',
    label:
      'VGSCX,  Virtus Opportunities Trust - Virtus Duff & Phelps Global Real Estate Securities Fund',
    name: 'Virtus Opportunities Trust - Virtus Duff & Phelps Global Real Estate Securities Fund',
    symbol: 'VGSCX',
  },
  {
    value: 'TMDIX',
    label: 'TMDIX,  AMG TimesSquare Mid Cap Growth Fund',
    name: 'AMG TimesSquare Mid Cap Growth Fund',
    symbol: 'TMDIX',
  },
  {
    value: 'OMSIX',
    label: 'OMSIX,  Invesco Main Street Fund Class R6',
    name: 'Invesco Main Street Fund Class R6',
    symbol: 'OMSIX',
  },
  {
    value: 'TIQIX',
    label: 'TIQIX,  Touchstone Non-US ESG Equity Fund',
    name: 'Touchstone Non-US ESG Equity Fund',
    symbol: 'TIQIX',
  },
  {
    value: 'NWWOX',
    label: 'NWWOX,  Virtus Vontobel Global Opportunities Fund',
    name: 'Virtus Vontobel Global Opportunities Fund',
    symbol: 'NWWOX',
  },
  {
    value: 'PARDX',
    label: 'PARDX,  T. Rowe Price Retirement 2040 Fund',
    name: 'T. Rowe Price Retirement 2040 Fund',
    symbol: 'PARDX',
  },
  {
    value: 'NOLCX',
    label: 'NOLCX,  Northern Large Cap Core Fund',
    name: 'Northern Large Cap Core Fund',
    symbol: 'NOLCX',
  },
  {
    value: 'PYVSX',
    label: 'PYVSX,  Payden Equity Income Fund SI Class',
    name: 'Payden Equity Income Fund SI Class',
    symbol: 'PYVSX',
  },
  {
    value: 'GASFX',
    label: 'GASFX,  Hennessy Gas Utility Fund',
    name: 'Hennessy Gas Utility Fund',
    symbol: 'GASFX',
  },
  {
    value: 'PYVLX',
    label: 'PYVLX,  Payden Equity Income Fund',
    name: 'Payden Equity Income Fund',
    symbol: 'PYVLX',
  },
  {
    value: 'RRTFX',
    label: 'RRTFX,  T. Rowe Price Retirement 2050 Fund',
    name: 'T. Rowe Price Retirement 2050 Fund',
    symbol: 'RRTFX',
  },
  {
    value: 'SIBAX',
    label: 'SIBAX,  Sit Balanced Fund',
    name: 'Sit Balanced Fund',
    symbol: 'SIBAX',
  },
  {
    value: 'DGLCX',
    label: 'DGLCX,  BNY Mellon Global Stock Fund',
    name: 'BNY Mellon Global Stock Fund',
    symbol: 'DGLCX',
  },
  {
    value: 'FABLX',
    label: 'FABLX,  Fidelity Advisor Balanced Fund',
    name: 'Fidelity Advisor Balanced Fund',
    symbol: 'FABLX',
  },
  {
    value: 'FMIJX',
    label: 'FMIJX,  FMI International Fund',
    name: 'FMI International Fund',
    symbol: 'FMIJX',
  },
  {
    value: 'SGACX',
    label: 'SGACX,  Virtus SGA Global Growth Fund',
    name: 'Virtus SGA Global Growth Fund',
    symbol: 'SGACX',
  },
  {
    value: 'AVEDX',
    label: 'AVEDX,  Ave Maria Rising Dividend Fund',
    name: 'Ave Maria Rising Dividend Fund',
    symbol: 'AVEDX',
  },
  {
    value: 'TRRJX',
    label: 'TRRJX,  T. Rowe Price Retirement 2035 Fund',
    name: 'T. Rowe Price Retirement 2035 Fund',
    symbol: 'TRRJX',
  },
  {
    value: 'TFIFX',
    label: 'TFIFX,  T. Rowe Price Financial Services Fund',
    name: 'T. Rowe Price Financial Services Fund',
    symbol: 'TFIFX',
  },
  {
    value: 'AUEIX',
    label: 'AUEIX,  AQR Large Cap Defensive Style Fund',
    name: 'AQR Large Cap Defensive Style Fund',
    symbol: 'AUEIX',
  },
  {
    value: 'RRTDX',
    label: 'RRTDX,  T. Rowe Price Retirement 2040 Fund Class R',
    name: 'T. Rowe Price Retirement 2040 Fund Class R',
    symbol: 'RRTDX',
  },
  {
    value: 'QUERX',
    label: 'QUERX,  AQR Large Cap Defensive Style Fund Class R6',
    name: 'AQR Large Cap Defensive Style Fund Class R6',
    symbol: 'QUERX',
  },
  {
    value: 'DGLRX',
    label: 'DGLRX,  BNY Mellon Global Stock Fund - Class I',
    name: 'BNY Mellon Global Stock Fund - Class I',
    symbol: 'DGLRX',
  },
  {
    value: 'AUENX',
    label: 'AUENX,  AQR Large Cap Defensive Style Fund',
    name: 'AQR Large Cap Defensive Style Fund',
    symbol: 'AUENX',
  },
  {
    value: 'SGAPX',
    label: 'SGAPX,  Virtus SGA Global Growth I Class',
    name: 'Virtus SGA Global Growth I Class',
    symbol: 'SGAPX',
  },
  {
    value: 'VWILX',
    label: 'VWILX,  Vanguard World Fund - Vanguard International Growth Fund',
    name: 'Vanguard World Fund - Vanguard International Growth Fund',
    symbol: 'VWILX',
  },
  {
    value: 'TRACX',
    label: 'TRACX,  PGIM QMA Small-Cap Value Fund- Class C',
    name: 'PGIM QMA Small-Cap Value Fund- Class C',
    symbol: 'TRACX',
  },
  {
    value: 'SDVGX',
    label: 'SDVGX,  Sit Dividend Growth Fund Class I',
    name: 'Sit Dividend Growth Fund Class I',
    symbol: 'SDVGX',
  },
  {
    value: 'PARKX',
    label: 'PARKX,  T. Rowe Price Retirement 2035 Fund Advisor Class',
    name: 'T. Rowe Price Retirement 2035 Fund Advisor Class',
    symbol: 'PARKX',
  },
  {
    value: 'MGGIX',
    label:
      'MGGIX,  Morgan Stanley Institutional Fund, Inc. Global Opportunity Portfolio',
    name: 'Morgan Stanley Institutional Fund, Inc. Global Opportunity Portfolio',
    symbol: 'MGGIX',
  },
  {
    value: 'SDVSX',
    label: 'SDVSX,  Sit Dividend Growth Fund Class S',
    name: 'Sit Dividend Growth Fund Class S',
    symbol: 'SDVSX',
  },
  {
    value: 'SMDVX',
    label: 'SMDVX,  Hartford Schroders US MidCap Opportunities Fund Class A',
    name: 'Hartford Schroders US MidCap Opportunities Fund Class A',
    symbol: 'SMDVX',
  },
  {
    value: 'CSSZX',
    label: 'CSSZX,  Columbia Select Small Cap Value Fund',
    name: 'Columbia Select Small Cap Value Fund',
    symbol: 'CSSZX',
  },
  {
    value: 'VASVX',
    label: 'VASVX,  Vanguard Selected Value Fund Investor Shares',
    name: 'Vanguard Selected Value Fund Investor Shares',
    symbol: 'VASVX',
  },
  {
    value: 'DGIFX',
    label: 'DGIFX,  The Disciplined Growth Investors Fund',
    name: 'The Disciplined Growth Investors Fund',
    symbol: 'DGIFX',
  },
  {
    value: 'BPSIX',
    label: 'BPSIX,  Boston Partners Small Cap Value Fund II',
    name: 'Boston Partners Small Cap Value Fund II',
    symbol: 'BPSIX',
  },
  {
    value: 'TGIPX',
    label: 'TGIPX,  T. Rowe Price Spectrum Moderate Growth Allocation Fund',
    name: 'T. Rowe Price Spectrum Moderate Growth Allocation Fund',
    symbol: 'TGIPX',
  },
  {
    value: 'SSCVX',
    label: 'SSCVX,  Columbia Select Small Cap Value Fund Class A',
    name: 'Columbia Select Small Cap Value Fund Class A',
    symbol: 'SSCVX',
  },
  {
    value: 'DGLAX',
    label: 'DGLAX,  BNY Mellon Global Stock Fund',
    name: 'BNY Mellon Global Stock Fund',
    symbol: 'DGLAX',
  },
  {
    value: 'TRSGX',
    label: 'TRSGX,  T. Rowe Price Spectrum Moderate Growth Allocation Fund',
    name: 'T. Rowe Price Spectrum Moderate Growth Allocation Fund',
    symbol: 'TRSGX',
  },
  {
    value: 'FEYIX',
    label: 'FEYIX,  Fidelity Asset Manager 85% Fund',
    name: 'Fidelity Asset Manager 85% Fund',
    symbol: 'FEYIX',
  },
  {
    value: 'RPTIX',
    label: 'RPTIX,  T. Rowe Price Mid-Cap Growth Fund',
    name: 'T. Rowe Price Mid-Cap Growth Fund',
    symbol: 'RPTIX',
  },
  {
    value: 'CVTRX',
    label: 'CVTRX,  Calamos Growth and Income Fund',
    name: 'Calamos Growth and Income Fund',
    symbol: 'CVTRX',
  },
  {
    value: 'TEQAX',
    label: 'TEQAX,  Touchstone Global ESG Equity Fund - Class A',
    name: 'Touchstone Global ESG Equity Fund - Class A',
    symbol: 'TEQAX',
  },
  {
    value: 'MVALX',
    label: 'MVALX,  Meridian Contrarian Fund Legacy Class',
    name: 'Meridian Contrarian Fund Legacy Class',
    symbol: 'MVALX',
  },
  {
    value: 'TRRKX',
    label: 'TRRKX,  T. Rowe Price Retirement 2045 Fund',
    name: 'T. Rowe Price Retirement 2045 Fund',
    symbol: 'TRRKX',
  },
  {
    value: 'HWLCX',
    label: 'HWLCX,  Hotchkis & Wiley Large Cap Value Fund Class C',
    name: 'Hotchkis & Wiley Large Cap Value Fund Class C',
    symbol: 'HWLCX',
  },
  {
    value: 'CGIIX',
    label: 'CGIIX,  Calamos Growth & Income Fund Institutional Class',
    name: 'Calamos Growth & Income Fund Institutional Class',
    symbol: 'CGIIX',
  },
  {
    value: 'PAMCX',
    label: 'PAMCX,  T. Rowe Price Mid-Cap Growth Fund Advisor Class',
    name: 'T. Rowe Price Mid-Cap Growth Fund Advisor Class',
    symbol: 'PAMCX',
  },
  {
    value: 'JDNAX',
    label: 'JDNAX,  Janus Henderson Growth And Income Fund Class A',
    name: 'Janus Henderson Growth And Income Fund Class A',
    symbol: 'JDNAX',
  },
  {
    value: 'ABASX',
    label: 'ABASX,  AB Discovery Value Fund Class A',
    name: 'AB Discovery Value Fund Class A',
    symbol: 'ABASX',
  },
  {
    value: 'FCAZX',
    label: 'FCAZX,  Franklin Corefolio Allocation Fund Advisor Class',
    name: 'Franklin Corefolio Allocation Fund Advisor Class',
    symbol: 'FCAZX',
  },
  {
    value: 'GILIX',
    label: 'GILIX,  Guggenheim StylePlus - Large Core Fund Institutional',
    name: 'Guggenheim StylePlus - Large Core Fund Institutional',
    symbol: 'GILIX',
  },
  {
    value: 'HFCSX',
    label: 'HFCSX,  Hennessy Focus Fund Investor Class',
    name: 'Hennessy Focus Fund Investor Class',
    symbol: 'HFCSX',
  },
  {
    value: 'MIEIX',
    label:
      'MIEIX,  MFS Institutional Trust - MFS Institutional International Equity Fund',
    name: 'MFS Institutional Trust - MFS Institutional International Equity Fund',
    symbol: 'MIEIX',
  },
  {
    value: 'FMGIX',
    label: 'FMGIX,  Frontier MFG Core Infrastructure Fund Institutional Class',
    name: 'Frontier MFG Core Infrastructure Fund Institutional Class',
    symbol: 'FMGIX',
  },
  {
    value: 'HLMGX',
    label: 'HLMGX,  Harding Loevner Global Equity Portfolio',
    name: 'Harding Loevner Global Equity Portfolio',
    symbol: 'HLMGX',
  },
  {
    value: 'NMIAX',
    label: 'NMIAX,  Columbia Large Cap Enhanced Core Fund',
    name: 'Columbia Large Cap Enhanced Core Fund',
    symbol: 'NMIAX',
  },
  {
    value: 'NMIMX',
    label: 'NMIMX,  Columbia Large Cap Enhanced Core Fund',
    name: 'Columbia Large Cap Enhanced Core Fund',
    symbol: 'NMIMX',
  },
  {
    value: 'RPMGX',
    label: 'RPMGX,  T. Rowe Price Mid-Cap Growth Fund',
    name: 'T. Rowe Price Mid-Cap Growth Fund',
    symbol: 'RPMGX',
  },
  {
    value: 'MFCIX',
    label: 'MFCIX,  Meridian Contrarian Fund Investor Class',
    name: 'Meridian Contrarian Fund Investor Class',
    symbol: 'MFCIX',
  },
  {
    value: 'FCVIX',
    label: 'FCVIX,  Fidelity Advisor Small Cap Value Fund Class I',
    name: 'Fidelity Advisor Small Cap Value Fund Class I',
    symbol: 'FCVIX',
  },
  {
    value: 'PARLX',
    label: 'PARLX,  T. Rowe Price Retirement 2045 Fund',
    name: 'T. Rowe Price Retirement 2045 Fund',
    symbol: 'PARLX',
  },
  {
    value: 'SFEPX',
    label: 'SFEPX,  Guggenheim StylePlus - Large Core Fund Class P',
    name: 'Guggenheim StylePlus - Large Core Fund Class P',
    symbol: 'SFEPX',
  },
  {
    value: 'ABSIX',
    label: 'ABSIX,  AB Discovery Value Fund Class I',
    name: 'AB Discovery Value Fund Class I',
    symbol: 'ABSIX',
  },
  {
    value: 'RRMGX',
    label: 'RRMGX,  T. Rowe Price Mid-Cap Growth Fund',
    name: 'T. Rowe Price Mid-Cap Growth Fund',
    symbol: 'RRMGX',
  },
  {
    value: 'NNTWX',
    label: 'NNTWX,  Nicholas II Fund',
    name: 'Nicholas II Fund',
    symbol: 'NNTWX',
  },
  {
    value: 'ABSZX',
    label: 'ABSZX,  AB Discovery Value Fund',
    name: 'AB Discovery Value Fund',
    symbol: 'ABSZX',
  },
  {
    value: 'AOVIX',
    label:
      'AOVIX,  American Century Investments One Choice Portfolio: Very Aggressive Investor Class',
    name: 'American Century Investments One Choice Portfolio: Very Aggressive Investor Class',
    symbol: 'AOVIX',
  },
  {
    value: 'MFCCX',
    label: 'MFCCX,  Meridian Contrarian Fund',
    name: 'Meridian Contrarian Fund',
    symbol: 'MFCCX',
  },
  {
    value: 'HWAIX',
    label: 'HWAIX,  Hotchkis & Wiley Value Opportunities Fund',
    name: 'Hotchkis & Wiley Value Opportunities Fund',
    symbol: 'HWAIX',
  },
  {
    value: 'HFCIX',
    label: 'HFCIX,  Hennessy Funds Trust - Hennessy Focus Fund',
    name: 'Hennessy Funds Trust - Hennessy Focus Fund',
    symbol: 'HFCIX',
  },
  {
    value: 'TAAIX',
    label: 'TAAIX,  Thrivent Aggressive Allocation Fund Class S',
    name: 'Thrivent Aggressive Allocation Fund Class S',
    symbol: 'TAAIX',
  },
  {
    value: 'SFDYX',
    label: 'SFDYX,  SEI Institutional Managed Trust Mid-Cap Fund',
    name: 'SEI Institutional Managed Trust Mid-Cap Fund',
    symbol: 'SFDYX',
  },
  {
    value: 'SEMCX',
    label:
      'SEMCX,  SEI Institutional Managed Trust Mid-Cap Portfolio Fund Class F',
    name: 'SEI Institutional Managed Trust Mid-Cap Portfolio Fund Class F',
    symbol: 'SEMCX',
  },
  {
    value: 'FBIOX',
    label: 'FBIOX,  Fidelity Select Biotechnology Portfolio',
    name: 'Fidelity Select Biotechnology Portfolio',
    symbol: 'FBIOX',
  },
  {
    value: 'SIPIX',
    label:
      'SIPIX,  SEI Institutional Managed Trust Mid-Cap Portfolio Fund Class I',
    name: 'SEI Institutional Managed Trust Mid-Cap Portfolio Fund Class I',
    symbol: 'SIPIX',
  },
  {
    value: 'PRISX',
    label: 'PRISX,  T. Rowe Price Financial Services Fund, Inc.',
    name: 'T. Rowe Price Financial Services Fund, Inc.',
    symbol: 'PRISX',
  },
  {
    value: 'ABSKX',
    label: 'ABSKX,  AB Discovery Value Fund Class K',
    name: 'AB Discovery Value Fund Class K',
    symbol: 'ABSKX',
  },
  {
    value: 'HWSAX',
    label: 'HWSAX,  Hotchkis & Wiley Small Cap Value Fund Class A',
    name: 'Hotchkis & Wiley Small Cap Value Fund Class A',
    symbol: 'HWSAX',
  },
  {
    value: 'PASVX',
    label: 'PASVX,  T. Rowe Price Small-Cap Value Fund',
    name: 'T. Rowe Price Small-Cap Value Fund',
    symbol: 'PASVX',
  },
  {
    value: 'RRTRX',
    label: 'RRTRX,  T. Rowe Price Retirement 2045 Fund',
    name: 'T. Rowe Price Retirement 2045 Fund',
    symbol: 'RRTRX',
  },
  {
    value: 'SMDRX',
    label: 'SMDRX,  Hartford Schroders US MidCap Opportunities Fund Class SDR',
    name: 'Hartford Schroders US MidCap Opportunities Fund Class SDR',
    symbol: 'SMDRX',
  },
  {
    value: 'PAROX',
    label: 'PAROX,  T. Rowe Price Retirement 2055 Fund',
    name: 'T. Rowe Price Retirement 2055 Fund',
    symbol: 'PAROX',
  },
  {
    value: 'MGPIX',
    label: 'MGPIX,  ProFunds Mid Cap Growth Fund Investor Class',
    name: 'ProFunds Mid Cap Growth Fund Investor Class',
    symbol: 'MGPIX',
  },
  {
    value: 'HFDYX',
    label: 'HFDYX,  Hartford Schroders US MidCap Opportunities Fund',
    name: 'Hartford Schroders US MidCap Opportunities Fund',
    symbol: 'HFDYX',
  },
  {
    value: 'SMDIX',
    label: 'SMDIX,  Hartford Schroders US MidCap Opportunities Fund',
    name: 'Hartford Schroders US MidCap Opportunities Fund',
    symbol: 'SMDIX',
  },
  {
    value: 'CVTCX',
    label: 'CVTCX,  Calamos Growth and Income Fund',
    name: 'Calamos Growth and Income Fund',
    symbol: 'CVTCX',
  },
  {
    value: 'NCTWX',
    label: 'NCTWX,  Nicholas II Fund Class I',
    name: 'Nicholas II Fund Class I',
    symbol: 'NCTWX',
  },
  {
    value: 'TAAAX',
    label: 'TAAAX,  Thrivent Aggressive Allocation Fund',
    name: 'Thrivent Aggressive Allocation Fund',
    symbol: 'TAAAX',
  },
  {
    value: 'HWSCX',
    label: 'HWSCX,  Hotchkis & Wiley Small Cap Value Fund',
    name: 'Hotchkis & Wiley Small Cap Value Fund',
    symbol: 'HWSCX',
  },
  {
    value: 'HFDTX',
    label: 'HFDTX,  Hartford Schroders US MidCap Opportunities Fund Class R5',
    name: 'Hartford Schroders US MidCap Opportunities Fund Class R5',
    symbol: 'HFDTX',
  },
  {
    value: 'MINDX',
    label: 'MINDX,  Matthews India Fund',
    name: 'Matthews India Fund',
    symbol: 'MINDX',
  },
  {
    value: 'SDLAX',
    label:
      'SDLAX,  SEI Institutional Investments Trust Dynamic Asset Allocation Fund Class A',
    name: 'SEI Institutional Investments Trust Dynamic Asset Allocation Fund Class A',
    symbol: 'SDLAX',
  },
  {
    value: 'MFCAX',
    label: 'MFCAX,  Meridian Contrarian Fund',
    name: 'Meridian Contrarian Fund',
    symbol: 'MFCAX',
  },
  {
    value: 'FTCLX',
    label: 'FTCLX,  Franklin Corefolio Allocation Fund',
    name: 'Franklin Corefolio Allocation Fund',
    symbol: 'FTCLX',
  },
  {
    value: 'PMEGX',
    label: 'PMEGX,  T. Rowe Price Institutional Mid-Cap Equity Growth Fund',
    name: 'T. Rowe Price Institutional Mid-Cap Equity Growth Fund',
    symbol: 'PMEGX',
  },
  {
    value: 'PJOQX',
    label: 'PJOQX,  PGIM Jennison Focused Value Fund- Class R6',
    name: 'PGIM Jennison Focused Value Fund- Class R6',
    symbol: 'PJOQX',
  },
  {
    value: 'FAMWX',
    label: 'FAMWX,  FAM Value Fund',
    name: 'FAM Value Fund',
    symbol: 'FAMWX',
  },
  {
    value: 'FAMVX',
    label: 'FAMVX,  FAM Value Fund Investor Class',
    name: 'FAM Value Fund Investor Class',
    symbol: 'FAMVX',
  },
  {
    value: 'ABYSX',
    label: 'ABYSX,  AB Discovery Value Fund',
    name: 'AB Discovery Value Fund',
    symbol: 'ABYSX',
  },
  {
    value: 'HWSIX',
    label: 'HWSIX,  Hotchkis & Wiley Small Cap Value Fund',
    name: 'Hotchkis & Wiley Small Cap Value Fund',
    symbol: 'HWSIX',
  },
  {
    value: 'CAAPX',
    label: 'CAAPX,  Ariel Appreciation Fund Investor Class',
    name: 'Ariel Appreciation Fund Investor Class',
    symbol: 'CAAPX',
  },
  {
    value: 'BPSCX',
    label: 'BPSCX,  Boston Partners Small Cap Value Fund II Investor Class',
    name: 'Boston Partners Small Cap Value Fund II Investor Class',
    symbol: 'BPSCX',
  },
  {
    value: 'HLMVX',
    label: 'HLMVX,  Harding Loevner Global Equity Portfolio',
    name: 'Harding Loevner Global Equity Portfolio',
    symbol: 'HLMVX',
  },
  {
    value: 'GTLIX',
    label: 'GTLIX,  Glenmede Quantitative U.S. Large Cap Core Equity Portfolio',
    name: 'Glenmede Quantitative U.S. Large Cap Core Equity Portfolio',
    symbol: 'GTLIX',
  },
  {
    value: 'HGGAX',
    label: 'HGGAX,  Harbor Global Leaders Fund Institutional Class',
    name: 'Harbor Global Leaders Fund Institutional Class',
    symbol: 'HGGAX',
  },
  {
    value: 'MSFLX',
    label:
      'MSFLX,  Morgan Stanley Institutional Fund, Inc. Global Franchise Portfolio Class L',
    name: 'Morgan Stanley Institutional Fund, Inc. Global Franchise Portfolio Class L',
    symbol: 'MSFLX',
  },
  {
    value: 'PARFX',
    label: 'PARFX,  T. Rowe Price Retirement 2050 Fund',
    name: 'T. Rowe Price Retirement 2050 Fund',
    symbol: 'PARFX',
  },
  {
    value: 'ABSRX',
    label: 'ABSRX,  AB Discovery Value Fund',
    name: 'AB Discovery Value Fund',
    symbol: 'ABSRX',
  },
  {
    value: 'HFDRX',
    label: 'HFDRX,  Hartford Schroders US MidCap Opportunities Fund Class R3',
    name: 'Hartford Schroders US MidCap Opportunities Fund Class R3',
    symbol: 'HFDRX',
  },
  {
    value: 'PRVIX',
    label: 'PRVIX,  T. Rowe Price Small-Cap Value Fund I Class',
    name: 'T. Rowe Price Small-Cap Value Fund I Class',
    symbol: 'PRVIX',
  },
  {
    value: 'PRSVX',
    label: 'PRSVX,  T. Rowe Price Small-Cap Value Fund',
    name: 'T. Rowe Price Small-Cap Value Fund',
    symbol: 'PRSVX',
  },
  {
    value: 'PDGIX',
    label: 'PDGIX,  T. Rowe Price Dividend Growth Fund',
    name: 'T. Rowe Price Dividend Growth Fund',
    symbol: 'PDGIX',
  },
  {
    value: 'POGSX',
    label: 'POGSX,  Pin Oak Equity Fund',
    name: 'Pin Oak Equity Fund',
    symbol: 'POGSX',
  },
  {
    value: 'ODMCX',
    label: 'ODMCX,  JPMorgan SMID Cap Equity Fund',
    name: 'JPMorgan SMID Cap Equity Fund',
    symbol: 'ODMCX',
  },
  {
    value: 'ACMYX',
    label: 'ACMYX,  American Beacon Mid-Cap Value Fund',
    name: 'American Beacon Mid-Cap Value Fund',
    symbol: 'ACMYX',
  },
  {
    value: 'APGAX',
    label: 'APGAX,  AB Large Cap Growth Fund Class A',
    name: 'AB Large Cap Growth Fund Class A',
    symbol: 'APGAX',
  },
  {
    value: 'ALCKX',
    label: 'ALCKX,  AB Large Cap Growth Fund',
    name: 'AB Large Cap Growth Fund',
    symbol: 'ALCKX',
  },
  {
    value: 'SIVIX',
    label: 'SIVIX,  State Street Institutional Small-Cap Equity Fund',
    name: 'State Street Institutional Small-Cap Equity Fund',
    symbol: 'SIVIX',
  },
  {
    value: 'HFDCX',
    label: 'HFDCX,  Hartford Schroders US MidCap Opportunities Fund Class C',
    name: 'Hartford Schroders US MidCap Opportunities Fund Class C',
    symbol: 'HFDCX',
  },
  {
    value: 'SECEX',
    label: 'SECEX,  Guggenheim StylePlus - Large Core Fund Class A',
    name: 'Guggenheim StylePlus - Large Core Fund Class A',
    symbol: 'SECEX',
  },
  {
    value: 'APGYX',
    label: 'APGYX,  AB Large Cap Growth Fund Advisor Class',
    name: 'AB Large Cap Growth Fund Advisor Class',
    symbol: 'APGYX',
  },
  {
    value: 'SSQSX',
    label:
      'SSQSX,  State Street Institutional Small-Cap Equity Fund Service Class',
    name: 'State Street Institutional Small-Cap Equity Fund Service Class',
    symbol: 'SSQSX',
  },
  {
    value: 'HWAAX',
    label: 'HWAAX,  Hotchkis & Wiley Value Opportunities Fund Class A',
    name: 'Hotchkis & Wiley Value Opportunities Fund Class A',
    symbol: 'HWAAX',
  },
  {
    value: 'APGZX',
    label: 'APGZX,  AB Large Cap Growth Fund',
    name: 'AB Large Cap Growth Fund',
    symbol: 'APGZX',
  },
  {
    value: 'AUUCX',
    label: 'AUUCX,  AB Select US Equity Portfolio',
    name: 'AB Select US Equity Portfolio',
    symbol: 'AUUCX',
  },
  {
    value: 'APGCX',
    label: 'APGCX,  AB Large Cap Growth Fund',
    name: 'AB Large Cap Growth Fund',
    symbol: 'APGCX',
  },
  {
    value: 'PLBBX',
    label: 'PLBBX,  Plumb Balanced Fund',
    name: 'Plumb Balanced Fund',
    symbol: 'PLBBX',
  },
  {
    value: 'MIDNX',
    label: 'MIDNX,  Matthews India Fund Institutional Class',
    name: 'Matthews India Fund Institutional Class',
    symbol: 'MIDNX',
  },
  {
    value: 'PRDGX',
    label: 'PRDGX,  T. Rowe Price Dividend Growth Fund, Inc.',
    name: 'T. Rowe Price Dividend Growth Fund, Inc.',
    symbol: 'PRDGX',
  },
  {
    value: 'VDIGX',
    label: 'VDIGX,  Vanguard Dividend Growth Fund Investor Shares',
    name: 'Vanguard Dividend Growth Fund Investor Shares',
    symbol: 'VDIGX',
  },
  {
    value: 'ABMAX',
    label: 'ABMAX,  American Beacon Mid-Cap Value Fund',
    name: 'American Beacon Mid-Cap Value Fund',
    symbol: 'ABMAX',
  },
  {
    value: 'SICWX',
    label: 'SICWX,  Touchstone Large Cap Focused Fund Class Y',
    name: 'Touchstone Large Cap Focused Fund Class Y',
    symbol: 'SICWX',
  },
  {
    value: 'TADGX',
    label: 'TADGX,  T. Rowe Price Dividend Growth Fund',
    name: 'T. Rowe Price Dividend Growth Fund',
    symbol: 'TADGX',
  },
  {
    value: 'VQNPX',
    label: 'VQNPX,  Vanguard Growth and Income Fund',
    name: 'Vanguard Growth and Income Fund',
    symbol: 'VQNPX',
  },
  {
    value: 'HASCX',
    label: 'HASCX,  Harbor Small Cap Value Fund Institutional Class',
    name: 'Harbor Small Cap Value Fund Institutional Class',
    symbol: 'HASCX',
  },
  {
    value: 'ENTIX',
    label: 'ENTIX,  ERShares Global Fund Institutional Class',
    name: 'ERShares Global Fund Institutional Class',
    symbol: 'ENTIX',
  },
  {
    value: 'HNGIX',
    label: 'HNGIX,  Harbor Global Leaders Fund',
    name: 'Harbor Global Leaders Fund',
    symbol: 'HNGIX',
  },
  {
    value: 'GRMIX',
    label: 'GRMIX,  Nationwide S&P 500 Index Fund',
    name: 'Nationwide S&P 500 Index Fund',
    symbol: 'GRMIX',
  },
  {
    value: 'BBHLX',
    label:
      'BBHLX,  BBH Partner Fund - International Equity Class Institutional',
    name: 'BBH Partner Fund - International Equity Class Institutional',
    symbol: 'BBHLX',
  },
  {
    value: 'SEEKX',
    label: 'SEEKX,  Steward Large Cap Enhanced Index Fund A',
    name: 'Steward Large Cap Enhanced Index Fund A',
    symbol: 'SEEKX',
  },
  {
    value: 'SKSEX',
    label: 'SKSEX,  AMG GW&K Small Cap Value Fund',
    name: 'AMG GW&K Small Cap Value Fund',
    symbol: 'SKSEX',
  },
  {
    value: 'NBGNX',
    label: 'NBGNX,  Neuberger Berman Genesis Fund Investor Class',
    name: 'Neuberger Berman Genesis Fund Investor Class',
    symbol: 'NBGNX',
  },
  {
    value: 'NBGEX',
    label: 'NBGEX,  Neuberger Berman Genesis Fund Trust Class',
    name: 'Neuberger Berman Genesis Fund Trust Class',
    symbol: 'NBGEX',
  },
  {
    value: 'NOSIX',
    label: 'NOSIX,  Northern Stock Index Fund',
    name: 'Northern Stock Index Fund',
    symbol: 'NOSIX',
  },
  {
    value: 'NBGIX',
    label: 'NBGIX,  Neuberger Berman Genesis Fund',
    name: 'Neuberger Berman Genesis Fund',
    symbol: 'NBGIX',
  },
  {
    value: 'PJIAX',
    label: 'PJIAX,  PGIM Jennison Focused Value Fund',
    name: 'PGIM Jennison Focused Value Fund',
    symbol: 'PJIAX',
  },
  {
    value: 'GTLOX',
    label: 'GTLOX,  Glenmede Quantitative U.S. Large Cap Core Equity Portfolio',
    name: 'Glenmede Quantitative U.S. Large Cap Core Equity Portfolio',
    symbol: 'GTLOX',
  },
  {
    value: 'PDSIX',
    label: 'PDSIX,  PGIM Quant Solutions Stock Index Fund',
    name: 'PGIM Quant Solutions Stock Index Fund',
    symbol: 'PDSIX',
  },
  {
    value: 'PSIFX',
    label: 'PSIFX,  PGIM Quant Solutions Stock Index Fund',
    name: 'PGIM Quant Solutions Stock Index Fund',
    symbol: 'PSIFX',
  },
  {
    value: 'JGMNX',
    label: 'JGMNX,  Janus Investment Fund - Janus Henderson Triton Fund',
    name: 'Janus Investment Fund - Janus Henderson Triton Fund',
    symbol: 'JGMNX',
  },
  {
    value: 'NINDX',
    label: 'NINDX,  Columbia Large Cap Index Fund',
    name: 'Columbia Large Cap Index Fund',
    symbol: 'NINDX',
  },
  {
    value: 'VGIAX',
    label:
      'VGIAX,  Vanguard Quantitative Funds - Vanguard Growth and Income Fund',
    name: 'Vanguard Quantitative Funds - Vanguard Growth and Income Fund',
    symbol: 'VGIAX',
  },
  {
    value: 'NWKEX',
    label: 'NWKEX,  Nationwide WCM Focused Small Cap Fund Class R6',
    name: 'Nationwide WCM Focused Small Cap Fund Class R6',
    symbol: 'NWKEX',
  },
  {
    value: 'SVSPX',
    label: 'SVSPX,  State Street S&P 500 Index Fund Class N',
    name: 'State Street S&P 500 Index Fund Class N',
    symbol: 'SVSPX',
  },
  {
    value: 'GQETX',
    label: 'GQETX,  GMO Quality Fund Class III',
    name: 'GMO Quality Fund Class III',
    symbol: 'GQETX',
  },
  {
    value: 'GRMAX',
    label: 'GRMAX,  Nationwide S&P 500 Index Fund',
    name: 'Nationwide S&P 500 Index Fund',
    symbol: 'GRMAX',
  },
  {
    value: 'WOOPX',
    label: 'WOOPX,  JPMorgan SMID Cap Equity Fund Class I',
    name: 'JPMorgan SMID Cap Equity Fund Class I',
    symbol: 'WOOPX',
  },
  {
    value: 'MYSPX',
    label: 'MYSPX,  MainStay S&P 500 Index Fund Investor Class',
    name: 'MainStay S&P 500 Index Fund Investor Class',
    symbol: 'MYSPX',
  },
  {
    value: 'PSIAX',
    label: 'PSIAX,  PGIM Quant Solutions Stock Index Fund',
    name: 'PGIM Quant Solutions Stock Index Fund',
    symbol: 'PSIAX',
  },
  {
    value: 'PREIX',
    label:
      'PREIX,  T. Rowe Price Index Trust, Inc. - T. Rowe Price Equity Index 500 Fund',
    name: 'T. Rowe Price Index Trust, Inc. - T. Rowe Price Equity Index 500 Fund',
    symbol: 'PREIX',
  },
  {
    value: 'FXAIX',
    label: 'FXAIX,  Fidelity 500 Index Fund',
    name: 'Fidelity 500 Index Fund',
    symbol: 'FXAIX',
  },
  {
    value: 'FTHSX',
    label: 'FTHSX,  Fuller & Thaler Behavioral Small-Cap Equity Fund',
    name: 'Fuller & Thaler Behavioral Small-Cap Equity Fund',
    symbol: 'FTHSX',
  },
  {
    value: 'VIIIX',
    label: 'VIIIX,  Vanguard Institutional Index Fund',
    name: 'Vanguard Institutional Index Fund',
    symbol: 'VIIIX',
  },
  {
    value: 'VINIX',
    label: 'VINIX,  Vanguard Institutional Index Fund Institutional Shares',
    name: 'Vanguard Institutional Index Fund Institutional Shares',
    symbol: 'VINIX',
  },
  {
    value: 'JSMGX',
    label: 'JSMGX,  Janus Henderson Triton Fund',
    name: 'Janus Henderson Triton Fund',
    symbol: 'JSMGX',
  },
  {
    value: 'GEQYX',
    label: 'GEQYX,  GuideStone Funds Equity Index Fund Institutional Class',
    name: 'GuideStone Funds Equity Index Fund Institutional Class',
    symbol: 'GEQYX',
  },
  {
    value: 'SENCX',
    label: 'SENCX,  Touchstone Large Cap Focused Fund',
    name: 'Touchstone Large Cap Focused Fund',
    symbol: 'SENCX',
  },
  {
    value: 'PTEZX',
    label: 'PTEZX,  PGIM Quant Solutions Large-Cap Core Equity Fund',
    name: 'PGIM Quant Solutions Large-Cap Core Equity Fund',
    symbol: 'PTEZX',
  },
  {
    value: 'SXPAX',
    label: 'SXPAX,  DWS S&P 500 Index Fund',
    name: 'DWS S&P 500 Index Fund',
    symbol: 'SXPAX',
  },
  {
    value: 'GEQZX',
    label: 'GEQZX,  GuideStone Funds - GuideStone Funds Equity Index Fund',
    name: 'GuideStone Funds - GuideStone Funds Equity Index Fund',
    symbol: 'GEQZX',
  },
  {
    value: 'BTIEX',
    label: 'BTIEX,  DWS Equity 500 Index Fund',
    name: 'DWS Equity 500 Index Fund',
    symbol: 'BTIEX',
  },
  {
    value: 'BTIIX',
    label: 'BTIIX,  DWS Equity 500 Index Fund - Class Inst',
    name: 'DWS Equity 500 Index Fund - Class Inst',
    symbol: 'BTIIX',
  },
  {
    value: 'CLXRX',
    label: 'CLXRX,  Columbia Large Cap Index Fund Institutional 2 Class',
    name: 'Columbia Large Cap Index Fund Institutional 2 Class',
    symbol: 'CLXRX',
  },
  {
    value: 'DSPIX',
    label:
      'DSPIX,  BNY Mellon Investment Funds IV, Inc. - BNY Mellon Institutional S&P 500 Stock Index Fund',
    name: 'BNY Mellon Investment Funds IV, Inc. - BNY Mellon Institutional S&P 500 Stock Index Fund',
    symbol: 'DSPIX',
  },
  {
    value: 'NWGSX',
    label: 'NWGSX,  Nationwide WCM Focused Small Cap Fund',
    name: 'Nationwide WCM Focused Small Cap Fund',
    symbol: 'NWGSX',
  },
  {
    value: 'HWCCX',
    label: 'HWCCX,  Hotchkis & Wiley Diversified Value Fund Class C',
    name: 'Hotchkis & Wiley Diversified Value Fund Class C',
    symbol: 'HWCCX',
  },
  {
    value: 'SPIIX',
    label: 'SPIIX,  SEI Institutional Managed Trust S&P 500 Index Fund Class I',
    name: 'SEI Institutional Managed Trust S&P 500 Index Fund Class I',
    symbol: 'SPIIX',
  },
  {
    value: 'NEIAX',
    label: 'NEIAX,  Columbia Large Cap Index Fund',
    name: 'Columbia Large Cap Index Fund',
    symbol: 'NEIAX',
  },
  {
    value: 'MMEYX',
    label: 'MMEYX,  Victory Integrity Discovery Fund',
    name: 'Victory Integrity Discovery Fund',
    symbol: 'MMEYX',
  },
  {
    value: 'NWGPX',
    label: 'NWGPX,  Nationwide WCM Focused Small Cap Fund Class A',
    name: 'Nationwide WCM Focused Small Cap Fund Class A',
    symbol: 'NWGPX',
  },
  {
    value: 'SXPCX',
    label: 'SXPCX,  DWS S&P 500 Index Fund',
    name: 'DWS S&P 500 Index Fund',
    symbol: 'SXPCX',
  },
  {
    value: 'CAEEX',
    label: 'CAEEX,  Columbia Acorn European Fund',
    name: 'Columbia Acorn European Fund',
    symbol: 'CAEEX',
  },
  {
    value: 'JANIX',
    label: 'JANIX,  Janus Henderson Triton Fund Class D',
    name: 'Janus Henderson Triton Fund Class D',
    symbol: 'JANIX',
  },
  {
    value: 'GVMCX',
    label: 'GVMCX,  Government Street Mid-Cap Fund',
    name: 'Government Street Mid-Cap Fund',
    symbol: 'GVMCX',
  },
  {
    value: 'SSPIX',
    label: 'SSPIX,  SEI Institutional Managed Trust S&P 500 Index Fund Class F',
    name: 'SEI Institutional Managed Trust S&P 500 Index Fund Class F',
    symbol: 'SSPIX',
  },
  {
    value: 'JGMIX',
    label: 'JGMIX,  Janus Henderson Triton Fund',
    name: 'Janus Henderson Triton Fund',
    symbol: 'JGMIX',
  },
  {
    value: 'FZAMX',
    label: 'FZAMX,  Fidelity Advisor Mid Cap II Fund Class Z',
    name: 'Fidelity Advisor Mid Cap II Fund Class Z',
    symbol: 'FZAMX',
  },
  {
    value: 'JDEAX',
    label: 'JDEAX,  JPMorgan U.S. Research Enhanced Equity Fund Class A',
    name: 'JPMorgan U.S. Research Enhanced Equity Fund Class A',
    symbol: 'JDEAX',
  },
  {
    value: 'HISVX',
    label: 'HISVX,  Harbor Small Cap Value Fund',
    name: 'Harbor Small Cap Value Fund',
    symbol: 'HISVX',
  },
  {
    value: 'VMCCX',
    label: 'VMCCX,  Virtus KAR Mid-Cap Core Fund',
    name: 'Virtus KAR Mid-Cap Core Fund',
    symbol: 'VMCCX',
  },
  {
    value: 'FMFAX',
    label: 'FMFAX,  Fidelity Advisor Materials Fund Class A',
    name: 'Fidelity Advisor Materials Fund Class A',
    symbol: 'FMFAX',
  },
  {
    value: 'PSICX',
    label: 'PSICX,  PGIM Quant Solutions Stock Index Fund',
    name: 'PGIM Quant Solutions Stock Index Fund',
    symbol: 'PSICX',
  },
  {
    value: 'VTCIX',
    label:
      'VTCIX,  Vanguard Tax-Managed Funds - Vanguard Tax-Managed Capital Appreciation Fund',
    name: 'Vanguard Tax-Managed Funds - Vanguard Tax-Managed Capital Appreciation Fund',
    symbol: 'VTCIX',
  },
  {
    value: 'VIMCX',
    label: 'VIMCX,  Virtus KAR Mid-Cap Core Fund Class I',
    name: 'Virtus KAR Mid-Cap Core Fund Class I',
    symbol: 'VIMCX',
  },
  {
    value: 'PRUIX',
    label: 'PRUIX,  T. Rowe Price Equity Index 500 Fund I Class',
    name: 'T. Rowe Price Equity Index 500 Fund I Class',
    symbol: 'PRUIX',
  },
  {
    value: 'VTCLX',
    label: 'VTCLX,  Vanguard Tax-Managed Capital Appreciation Fund',
    name: 'Vanguard Tax-Managed Capital Appreciation Fund',
    symbol: 'VTCLX',
  },
  {
    value: 'BPTRX',
    label: 'BPTRX,  Baron Partners Fund',
    name: 'Baron Partners Fund',
    symbol: 'BPTRX',
  },
  {
    value: 'HNVRX',
    label: 'HNVRX,  Harbor Small Cap Value Fund',
    name: 'Harbor Small Cap Value Fund',
    symbol: 'HNVRX',
  },
  {
    value: 'FITIX',
    label: 'FITIX,  Fidelity Advisor Mid Cap II Fund',
    name: 'Fidelity Advisor Mid Cap II Fund',
    symbol: 'FITIX',
  },
  {
    value: 'AMPAX',
    label: 'AMPAX,  American Beacon Mid-Cap Value Fund Investor Class',
    name: 'American Beacon Mid-Cap Value Fund Investor Class',
    symbol: 'AMPAX',
  },
  {
    value: 'NESIX',
    label: 'NESIX,  Needham Small Cap Growth Fund',
    name: 'Needham Small Cap Growth Fund',
    symbol: 'NESIX',
  },
  {
    value: 'SCSCX',
    label: 'SCSCX,  Touchstone Large Cap Focused Fund Class C',
    name: 'Touchstone Large Cap Focused Fund Class C',
    symbol: 'SCSCX',
  },
  {
    value: 'JUSRX',
    label: 'JUSRX,  JPMorgan U.S. Equity Fund',
    name: 'JPMorgan U.S. Equity Fund',
    symbol: 'JUSRX',
  },
  {
    value: 'HSVRX',
    label: 'HSVRX,  Harbor Small Cap Value Fund',
    name: 'Harbor Small Cap Value Fund',
    symbol: 'HSVRX',
  },
  {
    value: 'BPTIX',
    label: 'BPTIX,  Baron Select Funds - Baron Partners Fund',
    name: 'Baron Select Funds - Baron Partners Fund',
    symbol: 'BPTIX',
  },
  {
    value: 'BPTUX',
    label: 'BPTUX,  Baron Partners Fund',
    name: 'Baron Partners Fund',
    symbol: 'BPTUX',
  },
  {
    value: 'FMFTX',
    label: 'FMFTX,  Fidelity Select Materials Portfolio',
    name: 'Fidelity Select Materials Portfolio',
    symbol: 'FMFTX',
  },
  {
    value: 'FSDPX',
    label: 'FSDPX,  Fidelity Select Materials Portfolio',
    name: 'Fidelity Select Materials Portfolio',
    symbol: 'FSDPX',
  },
  {
    value: 'JUESX',
    label: 'JUESX,  JPMorgan U.S. Equity Fund Class I',
    name: 'JPMorgan U.S. Equity Fund Class I',
    symbol: 'JUESX',
  },
  {
    value: 'FMFEX',
    label: 'FMFEX,  Fidelity Select Materials Portfolio',
    name: 'Fidelity Select Materials Portfolio',
    symbol: 'FMFEX',
  },
  {
    value: 'GRMCX',
    label: 'GRMCX,  Nationwide S&P 500 Index Fund',
    name: 'Nationwide S&P 500 Index Fund',
    symbol: 'GRMCX',
  },
  {
    value: 'USPRX',
    label: 'USPRX,  USAA 500 Index Fund',
    name: 'USAA 500 Index Fund',
    symbol: 'USPRX',
  },
  {
    value: 'USSPX',
    label: 'USSPX,  USAA Mutual Funds Trust - USAA 500 Index Fund',
    name: 'USAA Mutual Funds Trust - USAA 500 Index Fund',
    symbol: 'USSPX',
  },
  {
    value: 'POMIX',
    label: 'POMIX,  T. Rowe Price Total Equity Market Index Fund',
    name: 'T. Rowe Price Total Equity Market Index Fund',
    symbol: 'POMIX',
  },
  {
    value: 'JUEAX',
    label: 'JUEAX,  JPMorgan U.S. Equity Fund Class A',
    name: 'JPMorgan U.S. Equity Fund Class A',
    symbol: 'JUEAX',
  },
  {
    value: 'FACSX',
    label: 'FACSX,  Nuveen Mid Cap Value Fund Class C',
    name: 'Nuveen Mid Cap Value Fund Class C',
    symbol: 'FACSX',
  },
  {
    value: 'LCIAX',
    label:
      'LCIAX,  SEI Institutional Investments Trust Large Cap Index Fund Class A',
    name: 'SEI Institutional Investments Trust Large Cap Index Fund Class A',
    symbol: 'LCIAX',
  },
  {
    value: 'NSCFX',
    label: 'NSCFX,  Nuveen Small-Cap Value Opportunities Fund',
    name: 'Nuveen Small-Cap Value Opportunities Fund',
    symbol: 'NSCFX',
  },
  {
    value: 'JUEPX',
    label: 'JUEPX,  JPMorgan U.S. Equity Fund',
    name: 'JPMorgan U.S. Equity Fund',
    symbol: 'JUEPX',
  },
  {
    value: 'OTIIX',
    label: 'OTIIX,  T. Rowe Price Small-Cap Stock Fund',
    name: 'T. Rowe Price Small-Cap Stock Fund',
    symbol: 'OTIIX',
  },
  {
    value: 'VMACX',
    label: 'VMACX,  Virtus KAR Mid-Cap Core Fund',
    name: 'Virtus KAR Mid-Cap Core Fund',
    symbol: 'VMACX',
  },
  {
    value: 'NOSGX',
    label: 'NOSGX,  Northern Small Cap Value Fund',
    name: 'Northern Small Cap Value Fund',
    symbol: 'NOSGX',
  },
  {
    value: 'WESCX',
    label: 'WESCX,  TETON Westwood SmallCap Equity Fund',
    name: 'TETON Westwood SmallCap Equity Fund',
    symbol: 'WESCX',
  },
  {
    value: 'MMERX',
    label: 'MMERX,  Victory Integrity Discovery Fund',
    name: 'Victory Integrity Discovery Fund',
    symbol: 'MMERX',
  },
  {
    value: 'PCCOX',
    label: 'PCCOX,  T. Rowe Price U.S. Equity Research Fund I Class',
    name: 'T. Rowe Price U.S. Equity Research Fund I Class',
    symbol: 'PCCOX',
  },
  {
    value: 'JGMRX',
    label: 'JGMRX,  Janus Henderson Triton Fund',
    name: 'Janus Henderson Triton Fund',
    symbol: 'JGMRX',
  },
  {
    value: 'SPINX',
    label:
      'SPINX,  SEI Institutional Investments Trust S&P 500 Index Fund Class A',
    name: 'SEI Institutional Investments Trust S&P 500 Index Fund Class A',
    symbol: 'SPINX',
  },
  {
    value: 'PRCOX',
    label: 'PRCOX,  T. Rowe Price U.S. Equity Research Fund',
    name: 'T. Rowe Price U.S. Equity Research Fund',
    symbol: 'PRCOX',
  },
  {
    value: 'FMFCX',
    label: 'FMFCX,  Fidelity Select Materials Portfolio',
    name: 'Fidelity Select Materials Portfolio',
    symbol: 'FMFCX',
  },
  {
    value: 'FIATX',
    label:
      'FIATX,  Fidelity Advisor International Capital Appreciation Fund Class M',
    name: 'Fidelity Advisor International Capital Appreciation Fund Class M',
    symbol: 'FIATX',
  },
  {
    value: 'DODGX',
    label: 'DODGX,  Dodge & Cox Funds - Dodge & Cox Stock Fund',
    name: 'Dodge & Cox Funds - Dodge & Cox Stock Fund',
    symbol: 'DODGX',
  },
  {
    value: 'MMEAX',
    label: 'MMEAX,  Victory Integrity Discovery Fund',
    name: 'Victory Integrity Discovery Fund',
    symbol: 'MMEAX',
  },
  {
    value: 'BUFEX',
    label: 'BUFEX,  Buffalo Large Cap Fund',
    name: 'Buffalo Large Cap Fund',
    symbol: 'BUFEX',
  },
  {
    value: 'FMEIX',
    label: 'FMEIX,  Fidelity Mid Cap Enhanced Index Fund',
    name: 'Fidelity Mid Cap Enhanced Index Fund',
    symbol: 'FMEIX',
  },
  {
    value: 'FOSBX',
    label: 'FOSBX,  Tributary Small Company Fund Institutional Plus Class',
    name: 'Tributary Small Company Fund Institutional Plus Class',
    symbol: 'FOSBX',
  },
  {
    value: 'NSCRX',
    label: 'NSCRX,  Nuveen Small-Cap Value Opportunities Fund Class I',
    name: 'Nuveen Small-Cap Value Opportunities Fund Class I',
    symbol: 'NSCRX',
  },
  {
    value: 'ABINX',
    label: 'ABINX,  American Century Balanced Fund',
    name: 'American Century Balanced Fund',
    symbol: 'ABINX',
  },
  {
    value: 'TWBIX',
    label: 'TWBIX,  American Century Balanced Fund',
    name: 'American Century Balanced Fund',
    symbol: 'TWBIX',
  },
  {
    value: 'PABFX',
    label: 'PABFX,  PGIM Balanced Fund',
    name: 'PGIM Balanced Fund',
    symbol: 'PABFX',
  },
  {
    value: 'CHTTX',
    label: 'CHTTX,  AMG River Road Mid Cap Value Fund',
    name: 'AMG River Road Mid Cap Value Fund',
    symbol: 'CHTTX',
  },
  {
    value: 'PABCX',
    label: 'PABCX,  PGIM Balanced Fund- Class C',
    name: 'PGIM Balanced Fund- Class C',
    symbol: 'PABCX',
  },
  {
    value: 'TPPAX',
    label: 'TPPAX,  T. Rowe Price Spectrum Moderate Allocation Fund',
    name: 'T. Rowe Price Spectrum Moderate Allocation Fund',
    symbol: 'TPPAX',
  },
  {
    value: 'TRPBX',
    label: 'TRPBX,  T. Rowe Price Spectrum Moderate Allocation Fund',
    name: 'T. Rowe Price Spectrum Moderate Allocation Fund',
    symbol: 'TRPBX',
  },
  {
    value: 'RRTNX',
    label: 'RRTNX,  T. Rowe Price Retirement 2025 Fund',
    name: 'T. Rowe Price Retirement 2025 Fund',
    symbol: 'RRTNX',
  },
  {
    value: 'VASGX',
    label: 'VASGX,  Vanguard LifeStrategy Growth Fund Investor Shares',
    name: 'Vanguard LifeStrategy Growth Fund Investor Shares',
    symbol: 'VASGX',
  },
  {
    value: 'VEIRX',
    label: 'VEIRX,  Vanguard Equity-Income Fund Admiral Shares',
    name: 'Vanguard Equity-Income Fund Admiral Shares',
    symbol: 'VEIRX',
  },
  {
    value: 'FBTIX',
    label: 'FBTIX,  Fidelity Advisor Biotechnology Fund I Class',
    name: 'Fidelity Advisor Biotechnology Fund I Class',
    symbol: 'FBTIX',
  },
  {
    value: 'VTRIX',
    label: 'VTRIX,  Vanguard International Value Fund Investor Shares',
    name: 'Vanguard International Value Fund Investor Shares',
    symbol: 'VTRIX',
  },
  {
    value: 'POPFX',
    label: 'POPFX,  Prospector Opportunity Fund',
    name: 'Prospector Opportunity Fund',
    symbol: 'POPFX',
  },
  {
    value: 'LGMCX',
    label: 'LGMCX,  Loomis Sayles Global Allocation Fund Class C',
    name: 'Loomis Sayles Global Allocation Fund Class C',
    symbol: 'LGMCX',
  },
  {
    value: 'FCHKX',
    label: 'FCHKX,  Fidelity China Region Fund',
    name: 'Fidelity China Region Fund',
    symbol: 'FCHKX',
  },
  {
    value: 'DODFX',
    label: 'DODFX,  Dodge & Cox International Stock Fund',
    name: 'Dodge & Cox International Stock Fund',
    symbol: 'DODFX',
  },
  {
    value: 'WHGIX',
    label: 'WHGIX,  Westwood Income Opportunity Fund Institutional Class',
    name: 'Westwood Income Opportunity Fund Institutional Class',
    symbol: 'WHGIX',
  },
  {
    value: 'TIBIX',
    label: 'TIBIX,  Thornburg Investment Income Builder Fund',
    name: 'Thornburg Investment Income Builder Fund',
    symbol: 'TIBIX',
  },
  {
    value: 'ASPGX',
    label: 'ASPGX,  Astor Sector Allocation Fund',
    name: 'Astor Sector Allocation Fund',
    symbol: 'ASPGX',
  },
  {
    value: 'TIBMX',
    label: 'TIBMX,  Thornburg Investment Income Builder Fund Class R5',
    name: 'Thornburg Investment Income Builder Fund Class R5',
    symbol: 'TIBMX',
  },
  {
    value: 'WSBFX',
    label: 'WSBFX,  Boston Trust Walden Balanced Fund',
    name: 'Boston Trust Walden Balanced Fund',
    symbol: 'WSBFX',
  },
  {
    value: 'TIBGX',
    label: 'TIBGX,  Thornburg Investment Income Builder Fund Class R4',
    name: 'Thornburg Investment Income Builder Fund Class R4',
    symbol: 'TIBGX',
  },
  {
    value: 'GIOTX',
    label:
      'GIOTX,  GMO International Developed Equity Allocation Fund Class III',
    name: 'GMO International Developed Equity Allocation Fund Class III',
    symbol: 'GIOTX',
  },
  {
    value: 'TIBAX',
    label: 'TIBAX,  Thornburg Investment Income Builder Fund Class A',
    name: 'Thornburg Investment Income Builder Fund Class A',
    symbol: 'TIBAX',
  },
  {
    value: 'TCVIX',
    label: 'TCVIX,  Touchstone Mid Cap Value Fund',
    name: 'Touchstone Mid Cap Value Fund',
    symbol: 'TCVIX',
  },
  {
    value: 'ATERX',
    label: 'ATERX,  AB Sustainable Global Thematic Fund',
    name: 'AB Sustainable Global Thematic Fund',
    symbol: 'ATERX',
  },
  {
    value: 'HSFNX',
    label: 'HSFNX,  Hennessy Small Cap Financial Fund',
    name: 'Hennessy Small Cap Financial Fund',
    symbol: 'HSFNX',
  },
  {
    value: 'VEIPX',
    label: 'VEIPX,  Vanguard Equity Income Fund',
    name: 'Vanguard Equity Income Fund',
    symbol: 'VEIPX',
  },
  {
    value: 'CSERX',
    label: 'CSERX,  Columbia Select Large Cap Value Fund Advisor Class',
    name: 'Columbia Select Large Cap Value Fund Advisor Class',
    symbol: 'CSERX',
  },
  {
    value: 'CIVIX',
    label: 'CIVIX,  Causeway International Value Fund Class Institutional',
    name: 'Causeway International Value Fund Class Institutional',
    symbol: 'CIVIX',
  },
  {
    value: 'TGVIX',
    label: 'TGVIX,  Thornburg International Equity Fund',
    name: 'Thornburg International Equity Fund',
    symbol: 'TGVIX',
  },
  {
    value: 'GSMFX',
    label: 'GSMFX,  GMO SGM Major Markets Fund Class III',
    name: 'GMO SGM Major Markets Fund Class III',
    symbol: 'GSMFX',
  },
  {
    value: 'TIVRX',
    label: 'TIVRX,  Thornburg International Equity Fund',
    name: 'Thornburg International Equity Fund',
    symbol: 'TIVRX',
  },
  {
    value: 'AREVX',
    label: 'AREVX,  American Century Investments One Choice 2055 Portfolio',
    name: 'American Century Investments One Choice 2055 Portfolio',
    symbol: 'AREVX',
  },
  {
    value: 'SBACX',
    label: 'SBACX,  Touchstone Balanced Fund Class C',
    name: 'Touchstone Balanced Fund Class C',
    symbol: 'SBACX',
  },
  {
    value: 'TGIRX',
    label: 'TGIRX,  Thornburg International Equity Fund',
    name: 'Thornburg International Equity Fund',
    symbol: 'TGIRX',
  },
  {
    value: 'ADGYX',
    label: 'ADGYX,  AB Core Opportunities Fund',
    name: 'AB Core Opportunities Fund',
    symbol: 'ADGYX',
  },
  {
    value: 'SEBLX',
    label: 'SEBLX,  Touchstone Balanced Fund Class A',
    name: 'Touchstone Balanced Fund Class A',
    symbol: 'SEBLX',
  },
  {
    value: 'DODBX',
    label: 'DODBX,  Dodge & Cox Balanced Fund',
    name: 'Dodge & Cox Balanced Fund',
    symbol: 'DODBX',
  },
  {
    value: 'JVAAX',
    label: 'JVAAX,  JPMorgan Value Advantage Fund',
    name: 'JPMorgan Value Advantage Fund',
    symbol: 'JVAAX',
  },
  {
    value: 'RBAIX',
    label: 'RBAIX,  T. Rowe Price Balanced I Class',
    name: 'T. Rowe Price Balanced I Class',
    symbol: 'RBAIX',
  },
  {
    value: 'CIVVX',
    label: 'CIVVX,  Causeway International Value Fund',
    name: 'Causeway International Value Fund',
    symbol: 'CIVVX',
  },
  {
    value: 'VBAIX',
    label: 'VBAIX,  Vanguard Balanced Index Fund Institutional Shares',
    name: 'Vanguard Balanced Index Fund Institutional Shares',
    symbol: 'VBAIX',
  },
  {
    value: 'VBIAX',
    label: 'VBIAX,  Vanguard Balanced Index Fund',
    name: 'Vanguard Balanced Index Fund',
    symbol: 'VBIAX',
  },
  {
    value: 'BTBFX',
    label: 'BTBFX,  Boston Trust Asset Management Fund',
    name: 'Boston Trust Asset Management Fund',
    symbol: 'BTBFX',
  },
  {
    value: 'VBINX',
    label: 'VBINX,  Vanguard Balanced Index Fund',
    name: 'Vanguard Balanced Index Fund',
    symbol: 'VBINX',
  },
  {
    value: 'DHLAX',
    label: 'DHLAX,  Diamond Hill Large Cap Fund',
    name: 'Diamond Hill Large Cap Fund',
    symbol: 'DHLAX',
  },
  {
    value: 'CSVZX',
    label: 'CSVZX,  Columbia Select Large Cap Value Fund',
    name: 'Columbia Select Large Cap Value Fund',
    symbol: 'CSVZX',
  },
  {
    value: 'SIBLX',
    label: 'SIBLX,  Touchstone Balanced Fund Class Y',
    name: 'Touchstone Balanced Fund Class Y',
    symbol: 'SIBLX',
  },
  {
    value: 'FASGX',
    label: 'FASGX,  Fidelity Asset Manager 70% Fund',
    name: 'Fidelity Asset Manager 70% Fund',
    symbol: 'FASGX',
  },
  {
    value: 'FMSTX',
    label: 'FMSTX,  Federated Hermes MDT Large Cap Value Fund',
    name: 'Federated Hermes MDT Large Cap Value Fund',
    symbol: 'FMSTX',
  },
  {
    value: 'LSWWX',
    label:
      'LSWWX,  Loomis Sayles Funds II - Loomis Sayles Global Allocation Fund',
    name: 'Loomis Sayles Funds II - Loomis Sayles Global Allocation Fund',
    symbol: 'LSWWX',
  },
  {
    value: 'HAVLX',
    label: 'HAVLX,  Harbor Large Cap Value Fund Institutional Class',
    name: 'Harbor Large Cap Value Fund Institutional Class',
    symbol: 'HAVLX',
  },
  {
    value: 'HNLVX',
    label: 'HNLVX,  Harbor Large Cap Value Fund',
    name: 'Harbor Large Cap Value Fund',
    symbol: 'HNLVX',
  },
  {
    value: 'IBALX',
    label: 'IBALX,  Transamerica Multi-Managed Balanced Fund Class A',
    name: 'Transamerica Multi-Managed Balanced Fund Class A',
    symbol: 'IBALX',
  },
  {
    value: 'FTASX',
    label: 'FTASX,  Fidelity Advisor Asset Manager 70% Fund Class M',
    name: 'Fidelity Advisor Asset Manager 70% Fund Class M',
    symbol: 'FTASX',
  },
  {
    value: 'HRLVX',
    label: 'HRLVX,  Harbor Large Cap Value Fund',
    name: 'Harbor Large Cap Value Fund',
    symbol: 'HRLVX',
  },
  {
    value: 'NBQFX',
    label: 'NBQFX,  Nuveen International Growth Fund',
    name: 'Nuveen International Growth Fund',
    symbol: 'NBQFX',
  },
  {
    value: 'EGOIX',
    label: 'EGOIX,  Allspring Large Cap Core Fund',
    name: 'Allspring Large Cap Core Fund',
    symbol: 'EGOIX',
  },
  {
    value: 'EGORX',
    label: 'EGORX,  Allspring Large Cap Core Fund - Class R6',
    name: 'Allspring Large Cap Core Fund - Class R6',
    symbol: 'EGORX',
  },
  {
    value: 'LGMAX',
    label: 'LGMAX,  Loomis Sayles Global Allocation Fund Class A',
    name: 'Loomis Sayles Global Allocation Fund Class A',
    symbol: 'LGMAX',
  },
  {
    value: 'RRTCX',
    label: 'RRTCX,  T. Rowe Price Retirement 2030 Fund',
    name: 'T. Rowe Price Retirement 2030 Fund',
    symbol: 'RRTCX',
  },
  {
    value: 'FOSFX',
    label: 'FOSFX,  Fidelity Overseas Fund',
    name: 'Fidelity Overseas Fund',
    symbol: 'FOSFX',
  },
  {
    value: 'FOSKX',
    label: 'FOSKX,  Fidelity Overseas Fund',
    name: 'Fidelity Overseas Fund',
    symbol: 'FOSKX',
  },
  {
    value: 'SXMAX',
    label:
      'SXMAX,  SEI Asset Allocation Trust Moderate Strategy Allocation Fund Class F',
    name: 'SEI Asset Allocation Trust Moderate Strategy Allocation Fund Class F',
    symbol: 'SXMAX',
  },
  {
    value: 'CIPIX',
    label: 'CIPIX,  Champlain Mid Cap Fund',
    name: 'Champlain Mid Cap Fund',
    symbol: 'CIPIX',
  },
  {
    value: 'MCNVX',
    label: 'MCNVX,  MainStay MacKay Convertible Fund',
    name: 'MainStay MacKay Convertible Fund',
    symbol: 'MCNVX',
  },
  {
    value: 'PAITX',
    label: 'PAITX,  T. Rowe Price International Stock Fund',
    name: 'T. Rowe Price International Stock Fund',
    symbol: 'PAITX',
  },
  {
    value: 'ADGKX',
    label: 'ADGKX,  AB Core Opportunities Fund',
    name: 'AB Core Opportunities Fund',
    symbol: 'ADGKX',
  },
  {
    value: 'BPAIX',
    label: 'BPAIX,  Boston Partners All Cap Value Fund Institutional Class',
    name: 'Boston Partners All Cap Value Fund Institutional Class',
    symbol: 'BPAIX',
  },
  {
    value: 'TGVAX',
    label: 'TGVAX,  Thornburg International Equity Fund Class A',
    name: 'Thornburg International Equity Fund Class A',
    symbol: 'TGVAX',
  },
  {
    value: 'TGVRX',
    label: 'TGVRX,  Thornburg International Equity Fund',
    name: 'Thornburg International Equity Fund',
    symbol: 'TGVRX',
  },
  {
    value: 'VWNDX',
    label: 'VWNDX,  Vanguard Windsor Fund',
    name: 'Vanguard Windsor Fund',
    symbol: 'VWNDX',
  },
  {
    value: 'TRLCX',
    label: 'TRLCX,  TIAA-CREF Large Cap Value Fund',
    name: 'TIAA-CREF Large Cap Value Fund',
    symbol: 'TRLCX',
  },
  {
    value: 'FZABX',
    label: 'FZABX,  Fidelity Advisor Diversified International Fund Class Z',
    name: 'Fidelity Advisor Diversified International Fund Class Z',
    symbol: 'FZABX',
  },
  {
    value: 'JVAIX',
    label: 'JVAIX,  JPMorgan Trust I - JPMorgan Value Advantage Fund',
    name: 'JPMorgan Trust I - JPMorgan Value Advantage Fund',
    symbol: 'JVAIX',
  },
  {
    value: 'FADCX',
    label: 'FADCX,  Fidelity Advisor Diversified International Fund',
    name: 'Fidelity Advisor Diversified International Fund',
    symbol: 'FADCX',
  },
  {
    value: 'JVAYX',
    label: 'JVAYX,  JPMorgan Value Advantage Fund Class R6',
    name: 'JPMorgan Value Advantage Fund Class R6',
    symbol: 'JVAYX',
  },
  {
    value: 'JVASX',
    label: 'JVASX,  JPMorgan Value Advantage Fund',
    name: 'JPMorgan Value Advantage Fund',
    symbol: 'JVASX',
  },
  {
    value: 'JVARX',
    label: 'JVARX,  JPMorgan Value Advantage Fund Class R5',
    name: 'JPMorgan Value Advantage Fund Class R5',
    symbol: 'JVARX',
  },
  {
    value: 'BPAVX',
    label: 'BPAVX,  Boston Partners All Cap Value Fund',
    name: 'Boston Partners All Cap Value Fund',
    symbol: 'BPAVX',
  },
  {
    value: 'RRITX',
    label: 'RRITX,  T. Rowe Price International Stock Fund',
    name: 'T. Rowe Price International Stock Fund',
    symbol: 'RRITX',
  },
  {
    value: 'CIPSX',
    label: 'CIPSX,  Champlain Small Company Fund',
    name: 'Champlain Small Company Fund',
    symbol: 'CIPSX',
  },
  {
    value: 'DHTYX',
    label: 'DHTYX,  Diamond Hill All Cap Select Fund',
    name: 'Diamond Hill All Cap Select Fund',
    symbol: 'DHTYX',
  },
  {
    value: 'CSRYX',
    label: 'CSRYX,  Columbia Select Large Cap Value Fund Institutional 3 Class',
    name: 'Columbia Select Large Cap Value Fund Institutional 3 Class',
    symbol: 'CSRYX',
  },
  {
    value: 'QNTIX',
    label: 'QNTIX,  Meeder Quantex Fund Institutional Class',
    name: 'Meeder Quantex Fund Institutional Class',
    symbol: 'QNTIX',
  },
  {
    value: 'QNTAX',
    label: 'QNTAX,  Meeder Quantex Fund Adviser Class',
    name: 'Meeder Quantex Fund Adviser Class',
    symbol: 'QNTAX',
  },
  {
    value: 'DHLTX',
    label: 'DHLTX,  Diamond Hill All Cap Select Fund Class I',
    name: 'Diamond Hill All Cap Select Fund Class I',
    symbol: 'DHLTX',
  },
  {
    value: 'DHLYX',
    label: 'DHLYX,  Diamond Hill Large Cap Fund Class Y',
    name: 'Diamond Hill Large Cap Fund Class Y',
    symbol: 'DHLYX',
  },
  {
    value: 'SLVAX',
    label: 'SLVAX,  Columbia Select Large Cap Value Fund Class A',
    name: 'Columbia Select Large Cap Value Fund Class A',
    symbol: 'SLVAX',
  },
  {
    value: 'DHLRX',
    label: 'DHLRX,  Diamond Hill Large Cap Fund',
    name: 'Diamond Hill Large Cap Fund',
    symbol: 'DHLRX',
  },
  {
    value: 'CIPMX',
    label: 'CIPMX,  Champlain Mid Cap Fund Advisor Class',
    name: 'Champlain Mid Cap Fund Advisor Class',
    symbol: 'CIPMX',
  },
  {
    value: 'FLCGX',
    label: 'FLCGX,  Meeder Quantex Fund',
    name: 'Meeder Quantex Fund',
    symbol: 'FLCGX',
  },
  {
    value: 'LMVYX',
    label: 'LMVYX,  Lord Abbett Focused Small Cap Value Fund',
    name: 'Lord Abbett Focused Small Cap Value Fund',
    symbol: 'LMVYX',
  },
  {
    value: 'JVACX',
    label: 'JVACX,  JPMorgan Value Advantage Fund Class C',
    name: 'JPMorgan Value Advantage Fund Class C',
    symbol: 'JVACX',
  },
  {
    value: 'FDVAX',
    label: 'FDVAX,  Fidelity Advisor Diversified International Fund Class A',
    name: 'Fidelity Advisor Diversified International Fund Class A',
    symbol: 'FDVAX',
  },
  {
    value: 'ADGZX',
    label: 'ADGZX,  AB Core Opportunities Fund',
    name: 'AB Core Opportunities Fund',
    symbol: 'ADGZX',
  },
  {
    value: 'HSSAX',
    label: 'HSSAX,  Emerald Finance and Banking Innovation Fund',
    name: 'Emerald Finance and Banking Innovation Fund',
    symbol: 'HSSAX',
  },
  {
    value: 'TRRCX',
    label: 'TRRCX,  T. Rowe Price Retirement 2030 Fund',
    name: 'T. Rowe Price Retirement 2030 Fund',
    symbol: 'TRRCX',
  },
  {
    value: 'VINEX',
    label: 'VINEX,  Vanguard International Explorer Fund Investor Shares',
    name: 'Vanguard International Explorer Fund Investor Shares',
    symbol: 'VINEX',
  },
  {
    value: 'KMDVX',
    label: 'KMDVX,  Keeley Mid Cap Dividend Value Fund Class A',
    name: 'Keeley Mid Cap Dividend Value Fund Class A',
    symbol: 'KMDVX',
  },
  {
    value: 'FSTLX',
    label: 'FSTLX,  Federated Hermes MDT Large Cap Value Fund Class R6',
    name: 'Federated Hermes MDT Large Cap Value Fund Class R6',
    symbol: 'FSTLX',
  },
  {
    value: 'RPBAX',
    label: 'RPBAX,  T. Rowe Price Balanced Fund',
    name: 'T. Rowe Price Balanced Fund',
    symbol: 'RPBAX',
  },
  {
    value: 'FSTKX',
    label: 'FSTKX,  Federated Hermes MDT Large Cap Value Fund Service Shares',
    name: 'Federated Hermes MDT Large Cap Value Fund Service Shares',
    symbol: 'FSTKX',
  },
  {
    value: 'FFBFX',
    label: 'FFBFX,  Emerald Finance and Banking Innovation Fund',
    name: 'Emerald Finance and Banking Innovation Fund',
    symbol: 'FFBFX',
  },
  {
    value: 'HSSIX',
    label: 'HSSIX,  Emerald Banking and Finance Fund Institutional Class',
    name: 'Emerald Banking and Finance Fund Institutional Class',
    symbol: 'HSSIX',
  },
  {
    value: 'FSTRX',
    label: 'FSTRX,  Federated Hermes MDT Large Cap Value Fund',
    name: 'Federated Hermes MDT Large Cap Value Fund',
    symbol: 'FSTRX',
  },
  {
    value: 'PARCX',
    label: 'PARCX,  T. Rowe Price Retirement 2030 Fund',
    name: 'T. Rowe Price Retirement 2030 Fund',
    symbol: 'PARCX',
  },
  {
    value: 'PESPX',
    label: 'PESPX,  BNY Mellon MidCap Index Fund',
    name: 'BNY Mellon MidCap Index Fund',
    symbol: 'PESPX',
  },
  {
    value: 'BRLVX',
    label: 'BRLVX,  American Beacon Bridgeway Large Cap Value Fund R5 Class',
    name: 'American Beacon Bridgeway Large Cap Value Fund R5 Class',
    symbol: 'BRLVX',
  },
  {
    value: 'MYIMX',
    label: 'MYIMX,  Victory Integrity Mid-Cap Value Fund',
    name: 'Victory Integrity Mid-Cap Value Fund',
    symbol: 'MYIMX',
  },
  {
    value: 'FSCWX',
    label: 'FSCWX,  Nuveen Small Cap Value Fund',
    name: 'Nuveen Small Cap Value Fund',
    symbol: 'FSCWX',
  },
  {
    value: 'NWXQX',
    label: 'NWXQX,  Nationwide Mid Cap Market Index Fund',
    name: 'Nationwide Mid Cap Market Index Fund',
    symbol: 'NWXQX',
  },
  {
    value: 'IUESX',
    label: 'IUESX,  JPMorgan International Focus Fund',
    name: 'JPMorgan International Focus Fund',
    symbol: 'IUESX',
  },
  {
    value: 'FAAIX',
    label: 'FAAIX,  Fidelity Advisor Asset Manager 70% Fund Class I',
    name: 'Fidelity Advisor Asset Manager 70% Fund Class I',
    symbol: 'FAAIX',
  },
  {
    value: 'FSCCX',
    label: 'FSCCX,  Nuveen Small Cap Value Fund',
    name: 'Nuveen Small Cap Value Fund',
    symbol: 'FSCCX',
  },
  {
    value: 'PRDSX',
    label: 'PRDSX,  T. Rowe Price QM U.S. Small-Cap Growth Equity Fund',
    name: 'T. Rowe Price QM U.S. Small-Cap Growth Equity Fund',
    symbol: 'PRDSX',
  },
  {
    value: 'FBAKX',
    label: 'FBAKX,  Fidelity Balanced Fund',
    name: 'Fidelity Balanced Fund',
    symbol: 'FBAKX',
  },
  {
    value: 'FAASX',
    label: 'FAASX,  Fidelity Asset Manager 70% Fund',
    name: 'Fidelity Asset Manager 70% Fund',
    symbol: 'FAASX',
  },
  {
    value: 'HQIIX',
    label: 'HQIIX,  The Hartford Equity Income Fund',
    name: 'The Hartford Equity Income Fund',
    symbol: 'HQIIX',
  },
  {
    value: 'LYRIX',
    label: 'LYRIX,  Lyrical U.S. Value Equity Fund Institutional Class',
    name: 'Lyrical U.S. Value Equity Fund Institutional Class',
    symbol: 'LYRIX',
  },
  {
    value: 'HSSCX',
    label: 'HSSCX,  Emerald Finance and Banking Innovation Fund - C',
    name: 'Emerald Finance and Banking Innovation Fund - C',
    symbol: 'HSSCX',
  },
  {
    value: 'USWGX',
    label: 'USWGX,  USAA Sustainable World Fund',
    name: 'USAA Sustainable World Fund',
    symbol: 'USWGX',
  },
  {
    value: 'TCLCX',
    label: 'TCLCX,  TIAA-CREF Large Cap Value Fund',
    name: 'TIAA-CREF Large Cap Value Fund',
    symbol: 'TCLCX',
  },
  {
    value: 'SMTIX',
    label: 'SMTIX,  JPMorgan SmartRetirement 2040 Fund',
    name: 'JPMorgan SmartRetirement 2040 Fund',
    symbol: 'SMTIX',
  },
  {
    value: 'SMTYX',
    label: 'SMTYX,  JPMorgan SmartRetirement 2040 Fund Class R6',
    name: 'JPMorgan SmartRetirement 2040 Fund Class R6',
    symbol: 'SMTYX',
  },
  {
    value: 'FSCVX',
    label: 'FSCVX,  Nuveen Small Cap Value Fund Class C',
    name: 'Nuveen Small Cap Value Fund Class C',
    symbol: 'FSCVX',
  },
  {
    value: 'UBVSX',
    label: 'UBVSX,  Undiscovered Managers Behavioral Value Fund Class I',
    name: 'Undiscovered Managers Behavioral Value Fund Class I',
    symbol: 'UBVSX',
  },
  {
    value: 'CRMSX',
    label: 'CRMSX,  CRM Small Cap Value Fund',
    name: 'CRM Small Cap Value Fund',
    symbol: 'CRMSX',
  },
  {
    value: 'SMTSX',
    label: 'SMTSX,  JPMorgan SmartRetirement 2040 Fund Class I',
    name: 'JPMorgan SmartRetirement 2040 Fund Class I',
    symbol: 'SMTSX',
  },
  {
    value: 'LYRBX',
    label: 'LYRBX,  Lyrical U.S. Value Equity Fund Investor Class',
    name: 'Lyrical U.S. Value Equity Fund Investor Class',
    symbol: 'LYRBX',
  },
  {
    value: 'SSGAX',
    label: 'SSGAX,  SEI Asset Allocation Trust Aggressive Strategy Fund',
    name: 'SEI Asset Allocation Trust Aggressive Strategy Fund',
    symbol: 'SSGAX',
  },
  {
    value: 'FCASX',
    label: 'FCASX,  Fidelity Advisor Asset Manager 70% Fund Class C',
    name: 'Fidelity Advisor Asset Manager 70% Fund Class C',
    symbol: 'FCASX',
  },
  {
    value: 'SKTAX',
    label:
      'SKTAX,  SEI Asset Allocation Trust Core Market Strategy Allocation Fund Class F',
    name: 'SEI Asset Allocation Trust Core Market Strategy Allocation Fund Class F',
    symbol: 'SKTAX',
  },
  {
    value: 'SMTAX',
    label: 'SMTAX,  JPMorgan SmartRetirement 2040 Fund Class A',
    name: 'JPMorgan SmartRetirement 2040 Fund Class A',
    symbol: 'SMTAX',
  },
  {
    value: 'TILCX',
    label: 'TILCX,  T. Rowe Price Large-Cap Value Fund I Class',
    name: 'T. Rowe Price Large-Cap Value Fund I Class',
    symbol: 'TILCX',
  },
  {
    value: 'HILVX',
    label: 'HILVX,  Harbor Large Cap Value Fund',
    name: 'Harbor Large Cap Value Fund',
    symbol: 'HILVX',
  },
  {
    value: 'UBVRX',
    label: 'UBVRX,  Undiscovered Managers Behavioral Value Fund Class R2',
    name: 'Undiscovered Managers Behavioral Value Fund Class R2',
    symbol: 'UBVRX',
  },
  {
    value: 'GMXAX',
    label: 'GMXAX,  Nationwide Mid Cap Market Index Fund',
    name: 'Nationwide Mid Cap Market Index Fund',
    symbol: 'GMXAX',
  },
  {
    value: 'FMCLX',
    label: 'FMCLX,  Federated Hermes Mid Cap Index Fund',
    name: 'Federated Hermes Mid Cap Index Fund',
    symbol: 'FMCLX',
  },
  {
    value: 'FMDCX',
    label: 'FMDCX,  Federated Hermes Mid Cap Index Fund',
    name: 'Federated Hermes Mid Cap Index Fund',
    symbol: 'FMDCX',
  },
  {
    value: 'FDVIX',
    label: 'FDVIX,  Fidelity Advisor Diversified International Fund',
    name: 'Fidelity Advisor Diversified International Fund',
    symbol: 'FDVIX',
  },
  {
    value: 'HDGCX',
    label: 'HDGCX,  The Hartford Dividend and Growth Fund Class C',
    name: 'The Hartford Dividend and Growth Fund Class C',
    symbol: 'HDGCX',
  },
  {
    value: 'JENYX',
    label: 'JENYX,  Jensen Quality Growth Fund Class Y',
    name: 'Jensen Quality Growth Fund Class Y',
    symbol: 'JENYX',
  },
  {
    value: 'FMCRX',
    label: 'FMCRX,  Federated Hermes Mid-Cap Index Fund Institutional Shares',
    name: 'Federated Hermes Mid-Cap Index Fund Institutional Shares',
    symbol: 'FMCRX',
  },
  {
    value: 'TFSIX',
    label: 'TFSIX,  Franklin Mutual Financial Services Fund',
    name: 'Franklin Mutual Financial Services Fund',
    symbol: 'TFSIX',
  },
  {
    value: 'VHGEX',
    label: 'VHGEX,  Vanguard Global Equity Fund Investor Shares',
    name: 'Vanguard Global Equity Fund Investor Shares',
    symbol: 'VHGEX',
  },
  {
    value: 'UBVLX',
    label: 'UBVLX,  Undiscovered Managers Behavioral Value Fund',
    name: 'Undiscovered Managers Behavioral Value Fund',
    symbol: 'UBVLX',
  },
  {
    value: 'CIPNX',
    label:
      "CIPNX,  The Advisors' Inner Circle Fund II - Champlain Small Company Fund",
    name: "The Advisors' Inner Circle Fund II - Champlain Small Company Fund",
    symbol: 'CIPNX',
  },
  {
    value: 'FZAJX',
    label: 'FZAJX,  Fidelity Advisor International Growth Fund Class Z',
    name: 'Fidelity Advisor International Growth Fund Class Z',
    symbol: 'FZAJX',
  },
  {
    value: 'FIGFX',
    label: 'FIGFX,  Fidelity International Growth Fund',
    name: 'Fidelity International Growth Fund',
    symbol: 'FIGFX',
  },
  {
    value: 'WPSIX',
    label: 'WPSIX,  AB Concentrated Growth Fund',
    name: 'AB Concentrated Growth Fund',
    symbol: 'WPSIX',
  },
  {
    value: 'CLREX',
    label: 'CLREX,  Columbia Balanced Fund',
    name: 'Columbia Balanced Fund',
    symbol: 'CLREX',
  },
  {
    value: 'CBALX',
    label: 'CBALX,  Columbia Balanced Fund',
    name: 'Columbia Balanced Fund',
    symbol: 'CBALX',
  },
  {
    value: 'SMTCX',
    label: 'SMTCX,  JPMorgan SmartRetirement 2040 Fund',
    name: 'JPMorgan SmartRetirement 2040 Fund',
    symbol: 'SMTCX',
  },
  {
    value: 'PFPMX',
    label: 'PFPMX,  Parnassus Mid Cap Fund Institutional Shares',
    name: 'Parnassus Mid Cap Fund Institutional Shares',
    symbol: 'PFPMX',
  },
  {
    value: 'UBVAX',
    label: 'UBVAX,  Undiscovered Managers Behavioral Value Fund Class A',
    name: 'Undiscovered Managers Behavioral Value Fund Class A',
    symbol: 'UBVAX',
  },
  {
    value: 'SISAX',
    label:
      'SISAX,  SEI Asset Allocation Trust Tax-Managed Aggressive Strategy Fund Class F',
    name: 'SEI Asset Allocation Trust Tax-Managed Aggressive Strategy Fund Class F',
    symbol: 'SISAX',
  },
  {
    value: 'PARMX',
    label: 'PARMX,  Parnassus Mid Cap Fund',
    name: 'Parnassus Mid Cap Fund',
    symbol: 'PARMX',
  },
  {
    value: 'TPVIX',
    label: 'TPVIX,  Timothy Plan Small Cap Value Fund Class I',
    name: 'Timothy Plan Small Cap Value Fund Class I',
    symbol: 'TPVIX',
  },
  {
    value: 'HGIYX',
    label: 'HGIYX,  Hartford Core Equity Fund Class Y',
    name: 'Hartford Core Equity Fund Class Y',
    symbol: 'HGIYX',
  },
  {
    value: 'SGOAX',
    label:
      'SGOAX,  SEI Asset Allocation Trust Market Growth Strategy Allocation Fund Class F',
    name: 'SEI Asset Allocation Trust Market Growth Strategy Allocation Fund Class F',
    symbol: 'SGOAX',
  },
  {
    value: 'DMIDX',
    label: 'DMIDX,  BNY Mellon MidCap Index Fund Class I',
    name: 'BNY Mellon MidCap Index Fund Class I',
    symbol: 'DMIDX',
  },
  {
    value: 'TQAIX',
    label: 'TQAIX,  T. Rowe Price QM U.S. Small-Cap Growth Equity Fund I Class',
    name: 'T. Rowe Price QM U.S. Small-Cap Growth Equity Fund I Class',
    symbol: 'TQAIX',
  },
  {
    value: 'MCINX',
    label: 'MCINX,  MainStay MacKay Convertible Fund Investor Class',
    name: 'MainStay MacKay Convertible Fund Investor Class',
    symbol: 'MCINX',
  },
  {
    value: 'TRLIX',
    label: 'TRLIX,  TIAA-CREF Large-Cap Value Fund Institutional Class',
    name: 'TIAA-CREF Large-Cap Value Fund Institutional Class',
    symbol: 'TRLIX',
  },
  {
    value: 'TRLHX',
    label: 'TRLHX,  TIAA-CREF Large Cap Value Fund',
    name: 'TIAA-CREF Large Cap Value Fund',
    symbol: 'TRLHX',
  },
  {
    value: 'FFNOX',
    label: 'FFNOX,  Fidelity Four-in-One Index Fund',
    name: 'Fidelity Four-in-One Index Fund',
    symbol: 'FFNOX',
  },
  {
    value: 'FSCAX',
    label: 'FSCAX,  Nuveen Small Cap Value Fund Class A',
    name: 'Nuveen Small Cap Value Fund Class A',
    symbol: 'FSCAX',
  },
  {
    value: 'GMXRX',
    label:
      'GMXRX,  Nationwide Mutual Funds - Nationwide Mid Cap Market Index Fund',
    name: 'Nationwide Mutual Funds - Nationwide Mid Cap Market Index Fund',
    symbol: 'GMXRX',
  },
  {
    value: 'HGITX',
    label: 'HGITX,  Hartford Core Equity Fund Class R5',
    name: 'Hartford Core Equity Fund Class R5',
    symbol: 'HGITX',
  },
  {
    value: 'KIFCX',
    label: 'KIFCX,  Salient Select Income Fund Class C',
    name: 'Salient Select Income Fund Class C',
    symbol: 'KIFCX',
  },
  {
    value: 'SEAIX',
    label:
      'SEAIX,  SEI Asset Allocation Trust Aggressive Strategy Fund Class I',
    name: 'SEI Asset Allocation Trust Aggressive Strategy Fund Class I',
    symbol: 'SEAIX',
  },
  {
    value: 'TMMAX',
    label:
      'TMMAX,  SEI Institutional Managed Trust Tax-Managed Volatility Fund Class F',
    name: 'SEI Institutional Managed Trust Tax-Managed Volatility Fund Class F',
    symbol: 'TMMAX',
  },
  {
    value: 'STVYX',
    label:
      'STVYX,  SEI Institutional Managed Trust Tax-Managed Managed Volatility Fund',
    name: 'SEI Institutional Managed Trust Tax-Managed Managed Volatility Fund',
    symbol: 'STVYX',
  },
  {
    value: 'JORCX',
    label: 'JORCX,  Janus Henderson Global Select Fund Class C',
    name: 'Janus Henderson Global Select Fund Class C',
    symbol: 'JORCX',
  },
  {
    value: 'MPACX',
    label: 'MPACX,  Matthews Asia Growth Fund',
    name: 'Matthews Asia Growth Fund',
    symbol: 'MPACX',
  },
  {
    value: 'TQAAX',
    label: 'TQAAX,  T. Rowe Price QM U.S. Small-Cap Growth Equity Fund',
    name: 'T. Rowe Price QM U.S. Small-Cap Growth Equity Fund',
    symbol: 'TQAAX',
  },
  {
    value: 'MSCFX',
    label: 'MSCFX,  Mairs & Power Small Cap Fund',
    name: 'Mairs & Power Small Cap Fund',
    symbol: 'MSCFX',
  },
  {
    value: 'MCSVX',
    label: 'MCSVX,  MainStay MacKay Convertible Fund Class B',
    name: 'MainStay MacKay Convertible Fund Class B',
    symbol: 'MCSVX',
  },
  {
    value: 'FOVAX',
    label: 'FOVAX,  First Trust/Confluence Small Cap Value Fund',
    name: 'First Trust/Confluence Small Cap Value Fund',
    symbol: 'FOVAX',
  },
  {
    value: 'USAWX',
    label: 'USAWX,  USAA Sustainable World Fund Shares',
    name: 'USAA Sustainable World Fund Shares',
    symbol: 'USAWX',
  },
  {
    value: 'JTSYX',
    label: 'JTSYX,  JPMorgan SmartRetirement 2050 Fund Class R6',
    name: 'JPMorgan SmartRetirement 2050 Fund Class R6',
    symbol: 'JTSYX',
  },
  {
    value: 'JTSIX',
    label: 'JTSIX,  JPMorgan SmartRetirement 2050 Fund',
    name: 'JPMorgan SmartRetirement 2050 Fund',
    symbol: 'JTSIX',
  },
  {
    value: 'JENIX',
    label: 'JENIX,  Jensen Quality Growth Fund',
    name: 'Jensen Quality Growth Fund',
    symbol: 'JENIX',
  },
  {
    value: 'SSHVX',
    label: 'SSHVX,  Sound Shore Fund Institutional Class',
    name: 'Sound Shore Fund Institutional Class',
    symbol: 'SSHVX',
  },
  {
    value: 'JTSSX',
    label: 'JTSSX,  JPMorgan SmartRetirement 2050 Fund Class I',
    name: 'JPMorgan SmartRetirement 2050 Fund Class I',
    symbol: 'JTSSX',
  },
  {
    value: 'CBDYX',
    label: 'CBDYX,  Columbia Balanced Fund Institutional 3 Class',
    name: 'Columbia Balanced Fund Institutional 3 Class',
    symbol: 'CBDYX',
  },
  {
    value: 'CBDRX',
    label: 'CBDRX,  Columbia Balanced Fund',
    name: 'Columbia Balanced Fund',
    symbol: 'CBDRX',
  },
  {
    value: 'SGIDX',
    label: 'SGIDX,  Crossmark Steward Global Equity Income Fund',
    name: 'Crossmark Steward Global Equity Income Fund',
    symbol: 'SGIDX',
  },
  {
    value: 'FSPHX',
    label: 'FSPHX,  Fidelity Select Health Care Portfolio',
    name: 'Fidelity Select Health Care Portfolio',
    symbol: 'FSPHX',
  },
  {
    value: 'FABCX',
    label: 'FABCX,  Fidelity Advisor Balanced Fund',
    name: 'Fidelity Advisor Balanced Fund',
    symbol: 'FABCX',
  },
  {
    value: 'FCPVX',
    label: 'FCPVX,  Fidelity  Small Cap Value Fund',
    name: 'Fidelity  Small Cap Value Fund',
    symbol: 'FCPVX',
  },
  {
    value: 'NOMIX',
    label: 'NOMIX,  Northern Mid Cap Index Fund',
    name: 'Northern Mid Cap Index Fund',
    symbol: 'NOMIX',
  },
  {
    value: 'CBLAX',
    label: 'CBLAX,  Columbia Balanced Fund Class A',
    name: 'Columbia Balanced Fund Class A',
    symbol: 'CBLAX',
  },
  {
    value: 'RRTPX',
    label: 'RRTPX,  T. Rowe Price Retirement 2035 Fund',
    name: 'T. Rowe Price Retirement 2035 Fund',
    symbol: 'RRTPX',
  },
  {
    value: 'FBALX',
    label: 'FBALX,  Fidelity Balanced Fund',
    name: 'Fidelity Balanced Fund',
    symbol: 'FBALX',
  },
  {
    value: 'FAMRX',
    label: 'FAMRX,  Fidelity Asset Manager 85% Fund',
    name: 'Fidelity Asset Manager 85% Fund',
    symbol: 'FAMRX',
  },
  {
    value: 'BWLAX',
    label: 'BWLAX,  American Beacon Bridgeway Large Cap Value Fund A Class',
    name: 'American Beacon Bridgeway Large Cap Value Fund A Class',
    symbol: 'BWLAX',
  },
  {
    value: 'FCIVX',
    label: 'FCIVX,  Frontier MFG Core Infrastructure Fund',
    name: 'Frontier MFG Core Infrastructure Fund',
    symbol: 'FCIVX',
  },
  {
    value: 'FMIYX',
    label: 'FMIYX,  FMI International Fund Institutional Class',
    name: 'FMI International Fund Institutional Class',
    symbol: 'FMIYX',
  },
  {
    value: 'HQISX',
    label: 'HQISX,  The Hartford Equity Income Fund Class R4',
    name: 'The Hartford Equity Income Fund Class R4',
    symbol: 'HQISX',
  },
  {
    value: 'JTSCX',
    label: 'JTSCX,  JPMorgan SmartRetirement 2050 Fund Class C',
    name: 'JPMorgan SmartRetirement 2050 Fund Class C',
    symbol: 'JTSCX',
  },
  {
    value: 'IILGX',
    label: 'IILGX,  Thrivent Global Stock Fund Class S',
    name: 'Thrivent Global Stock Fund Class S',
    symbol: 'IILGX',
  },
  {
    value: 'FHCIX',
    label: 'FHCIX,  Fidelity Advisor Health Care Fund',
    name: 'Fidelity Advisor Health Care Fund',
    symbol: 'FHCIX',
  },
  {
    value: 'FSMEX',
    label:
      'FSMEX,  Fidelity Select Portfolios - Medical Technology and Devices Portfolio',
    name: 'Fidelity Select Portfolios - Medical Technology and Devices Portfolio',
    symbol: 'FSMEX',
  },
  {
    value: 'AASCX',
    label: 'AASCX,  Thrivent Mid Cap Stock Fund',
    name: 'Thrivent Mid Cap Stock Fund',
    symbol: 'AASCX',
  },
  {
    value: 'TRRNX',
    label: 'TRRNX,  T. Rowe Price Retirement 2055 Fund',
    name: 'T. Rowe Price Retirement 2055 Fund',
    symbol: 'TRRNX',
  },
  {
    value: 'FEYAX',
    label: 'FEYAX,  Fidelity Advisor Asset Manager 85% Fund Class A',
    name: 'Fidelity Advisor Asset Manager 85% Fund Class A',
    symbol: 'FEYAX',
  },
  {
    value: 'ARTGX',
    label: 'ARTGX,  Artisan Global Value Fund Investor Class',
    name: 'Artisan Global Value Fund Investor Class',
    symbol: 'ARTGX',
  },
  {
    value: 'FHCCX',
    label: 'FHCCX,  Fidelity Advisor Health Care Fund Class C',
    name: 'Fidelity Advisor Health Care Fund Class C',
    symbol: 'FHCCX',
  },
  {
    value: 'APHGX',
    label: 'APHGX,  Artisan Global Value Fund',
    name: 'Artisan Global Value Fund',
    symbol: 'APHGX',
  },
  {
    value: 'CAGIX',
    label: 'CAGIX,  Calvert Growth Allocation Fund',
    name: 'Calvert Growth Allocation Fund',
    symbol: 'CAGIX',
  },
  {
    value: 'APDGX',
    label: 'APDGX,  Artisan Global Value Fund',
    name: 'Artisan Global Value Fund',
    symbol: 'APDGX',
  },
  {
    value: 'BRLIX',
    label: 'BRLIX,  Bridgeway Blue Chip Fund',
    name: 'Bridgeway Blue Chip Fund',
    symbol: 'BRLIX',
  },
  {
    value: 'WPSKX',
    label: 'WPSKX,  AB Concentrated Growth Fund',
    name: 'AB Concentrated Growth Fund',
    symbol: 'WPSKX',
  },
  {
    value: 'VWIGX',
    label: 'VWIGX,  Vanguard International Growth Fund',
    name: 'Vanguard International Growth Fund',
    symbol: 'VWIGX',
  },
  {
    value: 'FEYTX',
    label: 'FEYTX,  Fidelity Asset Manager 85% Fund',
    name: 'Fidelity Asset Manager 85% Fund',
    symbol: 'FEYTX',
  },
  {
    value: 'FACTX',
    label:
      'FACTX,  Fidelity Advisor Series VII - Fidelity Advisor Health Care Fund',
    name: 'Fidelity Advisor Series VII - Fidelity Advisor Health Care Fund',
    symbol: 'FACTX',
  },
  {
    value: 'MPGFX',
    label: 'MPGFX,  Mairs & Power Growth Fund Investor Class',
    name: 'Mairs & Power Growth Fund Investor Class',
    symbol: 'MPGFX',
  },
  {
    value: 'TBGVX',
    label:
      'TBGVX,  Tweedy, Browne Fund Inc. - Tweedy, Browne International Value Fund',
    name: 'Tweedy, Browne Fund Inc. - Tweedy, Browne International Value Fund',
    symbol: 'TBGVX',
  },
  {
    value: 'OMGNX',
    label: 'OMGNX,  Invesco Main Street Fund',
    name: 'Invesco Main Street Fund',
    symbol: 'OMGNX',
  },
  {
    value: 'TRRMX',
    label: 'TRRMX,  T. Rowe Price Retirement 2050 Fund',
    name: 'T. Rowe Price Retirement 2050 Fund',
    symbol: 'TRRMX',
  },
  {
    value: 'LVOLX',
    label: 'LVOLX,  SGI U.S. Large Equity Fund',
    name: 'SGI U.S. Large Equity Fund',
    symbol: 'LVOLX',
  },
  {
    value: 'WHGSX',
    label: 'WHGSX,  Westwood Quality SmallCap Fund Institutional Class',
    name: 'Westwood Quality SmallCap Fund Institutional Class',
    symbol: 'WHGSX',
  },
  {
    value: 'FACDX',
    label: 'FACDX,  Fidelity Advisor Health Care Fund Class A',
    name: 'Fidelity Advisor Health Care Fund Class A',
    symbol: 'FACDX',
  },
  {
    value: 'FZAAX',
    label: 'FZAAX,  Fidelity Advisor Balanced Fund Class Z',
    name: 'Fidelity Advisor Balanced Fund Class Z',
    symbol: 'FZAAX',
  },
  {
    value: 'FAIOX',
    label: 'FAIOX,  Fidelity Advisor Balanced Fund',
    name: 'Fidelity Advisor Balanced Fund',
    symbol: 'FAIOX',
  },
  {
    value: 'SILVX',
    label: 'SILVX,  SGI U.S. Large Cap Equity Fund Class I',
    name: 'SGI U.S. Large Cap Equity Fund Class I',
    symbol: 'SILVX',
  },
  {
    value: 'RRTVX',
    label: 'RRTVX,  T. Rowe Price Retirement 2055 Fund',
    name: 'T. Rowe Price Retirement 2055 Fund',
    symbol: 'RRTVX',
  },
  {
    value: 'TMSIX',
    label: 'TMSIX,  Thrivent Mid Cap Stock Fund Class S',
    name: 'Thrivent Mid Cap Stock Fund Class S',
    symbol: 'TMSIX',
  },
  {
    value: 'TRRDX',
    label: 'TRRDX,  T. Rowe Price Retirement 2040 Fund',
    name: 'T. Rowe Price Retirement 2040 Fund',
    symbol: 'TRRDX',
  },
  {
    value: 'BTMFX',
    label: 'BTMFX,  Boston Trust Midcap Fund',
    name: 'Boston Trust Midcap Fund',
    symbol: 'BTMFX',
  },
  {
    value: 'CLDIX',
    label: 'CLDIX,  Calvert Core Bond Fund',
    name: 'Calvert Core Bond Fund',
    symbol: 'CLDIX',
  },
  {
    value: 'FEYCX',
    label: 'FEYCX,  Fidelity Asset Manager 85% Fund',
    name: 'Fidelity Asset Manager 85% Fund',
    symbol: 'FEYCX',
  },
  {
    value: 'FAIGX',
    label: 'FAIGX,  Fidelity Advisor Balanced Fund',
    name: 'Fidelity Advisor Balanced Fund',
    symbol: 'FAIGX',
  },
  {
    value: 'CLNCX',
    label:
      'CLNCX,  Columbia Funds Series Trust - Columbia Large Cap Enhanced Core Fund',
    name: 'Columbia Funds Series Trust - Columbia Large Cap Enhanced Core Fund',
    symbol: 'CLNCX',
  },
  {
    value: 'MIGYX',
    label: 'MIGYX,  Invesco Main Street Fund Class Y',
    name: 'Invesco Main Street Fund Class Y',
    symbol: 'MIGYX',
  },
  {
    value: 'WWOIX',
    label: 'WWOIX,  Virtus Vontobel Global Opportunities Fund',
    name: 'Virtus Vontobel Global Opportunities Fund',
    symbol: 'WWOIX',
  },
  {
    value: 'TROCX',
    label: 'TROCX,  Touchstone Global ESG Equity Fund - Class I',
    name: 'Touchstone Global ESG Equity Fund - Class I',
    symbol: 'TROCX',
  },
  {
    value: 'CALCX',
    label: 'CALCX,  Calvert Conservative Allocation Fund',
    name: 'Calvert Conservative Allocation Fund',
    symbol: 'CALCX',
  },
  {
    value: 'CFWCX',
    label: 'CFWCX,  Calvert Global Water Fund Class C',
    name: 'Calvert Global Water Fund Class C',
    symbol: 'CFWCX',
  },
  {
    value: 'RYLIX',
    label: 'RYLIX,  Rydex Leisure Fund',
    name: 'Rydex Leisure Fund',
    symbol: 'RYLIX',
  },
  {
    value: 'FARFX',
    label: 'FARFX,  Fidelity Advisor Managed Retirement 2025 Fund Class A',
    name: 'Fidelity Advisor Managed Retirement 2025 Fund Class A',
    symbol: 'FARFX',
  },
  {
    value: 'THORX',
    label: 'THORX,  Thornburg Global Opportunities Fund Class R3',
    name: 'Thornburg Global Opportunities Fund Class R3',
    symbol: 'THORX',
  },
  {
    value: 'FERIX',
    label: 'FERIX,  Fidelity Advisor Emerging Asia Fund',
    name: 'Fidelity Advisor Emerging Asia Fund',
    symbol: 'FERIX',
  },
  {
    value: 'CFWIX',
    label: 'CFWIX,  Calvert Global Water Fund Class I',
    name: 'Calvert Global Water Fund Class I',
    symbol: 'CFWIX',
  },
  {
    value: 'FEATX',
    label: 'FEATX,  Fidelity Advisor Emerging Asia Fund',
    name: 'Fidelity Advisor Emerging Asia Fund',
    symbol: 'FEATX',
  },
  {
    value: 'CFWAX',
    label: 'CFWAX,  Calvert Global Water Fund',
    name: 'Calvert Global Water Fund',
    symbol: 'CFWAX',
  },
  {
    value: 'BINIX',
    label: 'BINIX,  Baron International Growth Fund Institutional Shares',
    name: 'Baron International Growth Fund Institutional Shares',
    symbol: 'BINIX',
  },
  {
    value: 'RYLCX',
    label: 'RYLCX,  Rydex Leisure Fund Class C',
    name: 'Rydex Leisure Fund Class C',
    symbol: 'RYLCX',
  },
  {
    value: 'THOCX',
    label: 'THOCX,  Thornburg Global Opportunities Fund Class C',
    name: 'Thornburg Global Opportunities Fund Class C',
    symbol: 'THOCX',
  },
  {
    value: 'BIGFX',
    label: 'BIGFX,  Baron International Growth Fund',
    name: 'Baron International Growth Fund',
    symbol: 'BIGFX',
  },
  {
    value: 'FFFDX',
    label: 'FFFDX,  Fidelity Freedom 2020 Fund',
    name: 'Fidelity Freedom 2020 Fund',
    symbol: 'FFFDX',
  },
  {
    value: 'LCORX',
    label: 'LCORX,  Leuthold Core Investment Fund',
    name: 'Leuthold Core Investment Fund',
    symbol: 'LCORX',
  },
  {
    value: 'FIRFX',
    label: 'FIRFX,  Fidelity Advisor Managed Retirement 2025 Fund Class I',
    name: 'Fidelity Advisor Managed Retirement 2025 Fund Class I',
    symbol: 'FIRFX',
  },
  {
    value: 'SPVZX',
    label: 'SPVZX,  PGIM Quant Solutions Mid-Cap Value Fund',
    name: 'PGIM Quant Solutions Mid-Cap Value Fund',
    symbol: 'SPVZX',
  },
  {
    value: 'HAFCX',
    label: 'HAFCX,  Hartford Multi-Asset Income Fund Class C',
    name: 'Hartford Multi-Asset Income Fund Class C',
    symbol: 'HAFCX',
  },
  {
    value: 'PMVQX',
    label: 'PMVQX,  PGIM Quant Solutions Mid-Cap Value Fund',
    name: 'PGIM Quant Solutions Mid-Cap Value Fund',
    symbol: 'PMVQX',
  },
  {
    value: 'SDVRX',
    label: 'SDVRX,  PGIM Quant Solutions Mid-Cap Value Fund',
    name: 'PGIM Quant Solutions Mid-Cap Value Fund',
    symbol: 'SDVRX',
  },
  {
    value: 'GLRIX',
    label: 'GLRIX,  James Balanced: Golden Rainbow Fund Institutional Class',
    name: 'James Balanced: Golden Rainbow Fund Institutional Class',
    symbol: 'GLRIX',
  },
  {
    value: 'VASIX',
    label: 'VASIX,  Vanguard LifeStrategy Income Fund Investor Shares',
    name: 'Vanguard LifeStrategy Income Fund Investor Shares',
    symbol: 'VASIX',
  },
  {
    value: 'SPHY',
    label: 'SPHY,  SPDR Portfolio High Yield Bond ETF',
    name: 'SPDR Portfolio High Yield Bond ETF',
    symbol: 'SPHY',
  },
  {
    value: 'SPGP',
    label: 'SPGP,  Invesco S&P 500 GARP ETF',
    name: 'Invesco S&P 500 GARP ETF',
    symbol: 'SPGP',
  },
  {
    value: 'XSVM',
    label: 'XSVM,  Invesco S&P SmallCap Value with Momentum ETF',
    name: 'Invesco S&P SmallCap Value with Momentum ETF',
    symbol: 'XSVM',
  },
  {
    value: 'SPIP',
    label: 'SPIP,  SPDR Portfolio TIPS ETF',
    name: 'SPDR Portfolio TIPS ETF',
    symbol: 'SPIP',
  },
  {
    value: 'SPMB',
    label: 'SPMB,  SPDR Portfolio Mortgage Backed Bond ETF',
    name: 'SPDR Portfolio Mortgage Backed Bond ETF',
    symbol: 'SPMB',
  },
  {
    value: 'IGBH',
    label: 'IGBH,  iShares Interest Rate Hedged Long-Term Corporate Bond ETF',
    name: 'iShares Interest Rate Hedged Long-Term Corporate Bond ETF',
    symbol: 'IGBH',
  },
  {
    value: 'IGLB',
    label: 'IGLB,  iShares 10+ Year Investment Grade Corporate Bond ETF',
    name: 'iShares 10+ Year Investment Grade Corporate Bond ETF',
    symbol: 'IGLB',
  },
  {
    value: 'SPGM',
    label: 'SPGM,  SPDR Portfolio MSCI Global Stock Market ETF',
    name: 'SPDR Portfolio MSCI Global Stock Market ETF',
    symbol: 'SPGM',
  },
  {
    value: 'XMMO',
    label: 'XMMO,  Invesco S&P MidCap Momentum ETF',
    name: 'Invesco S&P MidCap Momentum ETF',
    symbol: 'XMMO',
  },
  {
    value: 'MFMPX',
    label:
      'MFMPX,  Morgan Stanley Institutional Fund, Inc. Next Gen Emerging Markets Portfolio Class A',
    name: 'Morgan Stanley Institutional Fund, Inc. Next Gen Emerging Markets Portfolio Class A',
    symbol: 'MFMPX',
  },
  {
    value: 'MSRFX',
    label:
      'MSRFX,  Morgan Stanley Institutional Fund, Inc. Next Gen Emerging Markets Portfolio Class R6',
    name: 'Morgan Stanley Institutional Fund, Inc. Next Gen Emerging Markets Portfolio Class R6',
    symbol: 'MSRFX',
  },
  {
    value: 'MBESX',
    label: 'MBESX,  AMG GW&K Global Allocation Fund',
    name: 'AMG GW&K Global Allocation Fund',
    symbol: 'MBESX',
  },
  {
    value: 'MBEAX',
    label: 'MBEAX,  AMG GW&K Global Allocation Fund Class N',
    name: 'AMG GW&K Global Allocation Fund Class N',
    symbol: 'MBEAX',
  },
  {
    value: 'EKGCX',
    label: 'EKGCX,  Allspring Global Small Cap Fund Class C',
    name: 'Allspring Global Small Cap Fund Class C',
    symbol: 'EKGCX',
  },
  {
    value: 'AMDVX',
    label: 'AMDVX,  American Century Mid Cap Value Fund',
    name: 'American Century Mid Cap Value Fund',
    symbol: 'AMDVX',
  },
  {
    value: 'ACMVX',
    label: 'ACMVX,  American Century Mid Cap Value Fund',
    name: 'American Century Mid Cap Value Fund',
    symbol: 'ACMVX',
  },
  {
    value: 'EKGYX',
    label: 'EKGYX,  Allspring Special Global Small Cap Fund',
    name: 'Allspring Special Global Small Cap Fund',
    symbol: 'EKGYX',
  },
  {
    value: 'FSCRX',
    label:
      'FSCRX,  Fidelity Concord Street Trust - Fidelity Small Cap Discovery Fund',
    name: 'Fidelity Concord Street Trust - Fidelity Small Cap Discovery Fund',
    symbol: 'FSCRX',
  },
  {
    value: 'EKGIX',
    label: 'EKGIX,  Allspring Special Global Small Cap Fund',
    name: 'Allspring Special Global Small Cap Fund',
    symbol: 'EKGIX',
  },
  {
    value: 'MICDX',
    label: 'MICDX,  Matthews China Dividend Fund',
    name: 'Matthews China Dividend Fund',
    symbol: 'MICDX',
  },
  {
    value: 'MCDFX',
    label: 'MCDFX,  Matthews China Dividend Fund Investor Class',
    name: 'Matthews China Dividend Fund Investor Class',
    symbol: 'MCDFX',
  },
  {
    value: 'GPRIX',
    label: 'GPRIX,  Grandeur Peak Global Reach Fund',
    name: 'Grandeur Peak Global Reach Fund',
    symbol: 'GPRIX',
  },
  {
    value: 'SAOIX',
    label: 'SAOIX,  Guggenheim Funds Trust - Guggenheim Alpha Opportunity Fund',
    name: 'Guggenheim Funds Trust - Guggenheim Alpha Opportunity Fund',
    symbol: 'SAOIX',
  },
  {
    value: 'EKGAX',
    label: 'EKGAX,  Allspring Global Small Cap Fund Class A',
    name: 'Allspring Global Small Cap Fund Class A',
    symbol: 'EKGAX',
  },
  {
    value: 'FPBFX',
    label: 'FPBFX,  Fidelity Pacific Basin Fund',
    name: 'Fidelity Pacific Basin Fund',
    symbol: 'FPBFX',
  },
  {
    value: 'HEIFX',
    label: 'HEIFX,  Hennessy Equity and Income Fund Investor Class',
    name: 'Hennessy Equity and Income Fund Investor Class',
    symbol: 'HEIFX',
  },
  {
    value: 'SAOCX',
    label: 'SAOCX,  Guggenheim Alpha Opportunity Fund Class C',
    name: 'Guggenheim Alpha Opportunity Fund Class C',
    symbol: 'SAOCX',
  },
  {
    value: 'FGTRX',
    label: 'FGTRX,  Franklin Growth Allocation Fund Class R',
    name: 'Franklin Growth Allocation Fund Class R',
    symbol: 'FGTRX',
  },
  {
    value: 'SGIIX',
    label: 'SGIIX,  First Eagle Global Fund',
    name: 'First Eagle Global Fund',
    symbol: 'SGIIX',
  },
  {
    value: 'FESOX',
    label: 'FESOX,  First Eagle Overseas Fund',
    name: 'First Eagle Overseas Fund',
    symbol: 'FESOX',
  },
  {
    value: 'SAOAX',
    label: 'SAOAX,  Guggenheim Alpha Opportunity Fund',
    name: 'Guggenheim Alpha Opportunity Fund',
    symbol: 'SAOAX',
  },
  {
    value: 'FESGX',
    label: 'FESGX,  First Eagle Global Fund Class C',
    name: 'First Eagle Global Fund Class C',
    symbol: 'FESGX',
  },
  {
    value: 'SGENX',
    label: 'SGENX,  First Eagle Global Fund',
    name: 'First Eagle Global Fund',
    symbol: 'SGENX',
  },
  {
    value: 'QFVOX',
    label: 'QFVOX,  Pear Tree Polaris Foreign Value Fund',
    name: 'Pear Tree Polaris Foreign Value Fund',
    symbol: 'QFVOX',
  },
  {
    value: 'QFVIX',
    label: 'QFVIX,  Pear Tree Polaris Foreign Value Fund',
    name: 'Pear Tree Polaris Foreign Value Fund',
    symbol: 'QFVIX',
  },
  {
    value: 'SGOIX',
    label: 'SGOIX,  First Eagle Overseas Fund',
    name: 'First Eagle Overseas Fund',
    symbol: 'SGOIX',
  },
  {
    value: 'TRAOX',
    label: 'TRAOX,  T. Rowe Price Asia Opportunities Fund',
    name: 'T. Rowe Price Asia Opportunities Fund',
    symbol: 'TRAOX',
  },
  {
    value: 'TESRX',
    label: 'TESRX,  Franklin Mutual Shares Fund',
    name: 'Franklin Mutual Shares Fund',
    symbol: 'TESRX',
  },
  {
    value: 'CNPIX',
    label: 'CNPIX,  ProFunds Consumer Goods Ultra Sector Fund Investor Class',
    name: 'ProFunds Consumer Goods Ultra Sector Fund Investor Class',
    symbol: 'CNPIX',
  },
  {
    value: 'CNPSX',
    label: 'CNPSX,  ProFunds Consumer Goods UltraSector Fund',
    name: 'ProFunds Consumer Goods UltraSector Fund',
    symbol: 'CNPSX',
  },
  {
    value: 'SGOVX',
    label: 'SGOVX,  First Eagle Overseas Fund Class A',
    name: 'First Eagle Overseas Fund Class A',
    symbol: 'SGOVX',
  },
  {
    value: 'CMAAX',
    label: 'CMAAX,  Calvert Moderate Allocation Fund',
    name: 'Calvert Moderate Allocation Fund',
    symbol: 'CMAAX',
  },
  {
    value: 'TESIX',
    label: 'TESIX,  Franklin Mutual Shares Fund',
    name: 'Franklin Mutual Shares Fund',
    symbol: 'TESIX',
  },
  {
    value: 'ENPSX',
    label: 'ENPSX,  ProFunds UltraSector Oil & Gas Fund',
    name: 'ProFunds UltraSector Oil & Gas Fund',
    symbol: 'ENPSX',
  },
  {
    value: 'NELAX',
    label: 'NELAX,  Nuveen Equity Long/Short Fund',
    name: 'Nuveen Equity Long/Short Fund',
    symbol: 'NELAX',
  },
  {
    value: 'FSBBX',
    label: 'FSBBX,  Federated Hermes Global Allocation Fund Class B Shares',
    name: 'Federated Hermes Global Allocation Fund Class B Shares',
    symbol: 'FSBBX',
  },
  {
    value: 'WIIGX',
    label: 'WIIGX,  Wasatch International Growth Fund',
    name: 'Wasatch International Growth Fund',
    symbol: 'WIIGX',
  },
  {
    value: 'HHHSX',
    label: 'HHHSX,  Hartford Schroders Emerging Markets Equity Fund Class R4',
    name: 'Hartford Schroders Emerging Markets Equity Fund Class R4',
    symbol: 'HHHSX',
  },
  {
    value: 'ENPIX',
    label: 'ENPIX,  ProFunds UltraSector Oil & Gas Fund Investor Class',
    name: 'ProFunds UltraSector Oil & Gas Fund Investor Class',
    symbol: 'ENPIX',
  },
  {
    value: 'TFSCX',
    label:
      'TFSCX,  Templeton Institutional Foreign Smaller Companies Series Advisor',
    name: 'Templeton Institutional Foreign Smaller Companies Series Advisor',
    symbol: 'TFSCX',
  },
  {
    value: 'SEMTX',
    label: 'SEMTX,  Hartford Schroders Emerging Markets Equity Fund',
    name: 'Hartford Schroders Emerging Markets Equity Fund',
    symbol: 'SEMTX',
  },
  {
    value: 'SEMNX',
    label: 'SEMNX,  Hartford Schroders Emerging Markets Equity Fund',
    name: 'Hartford Schroders Emerging Markets Equity Fund',
    symbol: 'SEMNX',
  },
  {
    value: 'NELIX',
    label: 'NELIX,  Nuveen Equity Long/Short Fund Class I',
    name: 'Nuveen Equity Long/Short Fund Class I',
    symbol: 'NELIX',
  },
  {
    value: 'NELCX',
    label: 'NELCX,  Nuveen Equity Long/Short Fund Class C',
    name: 'Nuveen Equity Long/Short Fund Class C',
    symbol: 'NELCX',
  },
  {
    value: 'HHHCX',
    label: 'HHHCX,  Hartford Schroders Emerging Markets Equity Fund Class C',
    name: 'Hartford Schroders Emerging Markets Equity Fund Class C',
    symbol: 'HHHCX',
  },
  {
    value: 'RMBKX',
    label: 'RMBKX,  RMB Mendon Financial Services Fund',
    name: 'RMB Mendon Financial Services Fund',
    symbol: 'RMBKX',
  },
  {
    value: 'PWGAX',
    label: 'PWGAX,  PACE International Equity Investments',
    name: 'PACE International Equity Investments',
    symbol: 'PWGAX',
  },
  {
    value: 'PWIYX',
    label: 'PWIYX,  PACE International Equity Investments Class Y',
    name: 'PACE International Equity Investments Class Y',
    symbol: 'PWIYX',
  },
  {
    value: 'PCIEX',
    label: 'PCIEX,  PACE International Equity Investments Class P',
    name: 'PACE International Equity Investments Class P',
    symbol: 'PCIEX',
  },
  {
    value: 'RMBNX',
    label: 'RMBNX,  RMB Mendon Financial Services Fund Class C',
    name: 'RMB Mendon Financial Services Fund Class C',
    symbol: 'RMBNX',
  },
  {
    value: 'RYBAX',
    label: 'RYBAX,  Rydex Basic Materials Fund Class H',
    name: 'Rydex Basic Materials Fund Class H',
    symbol: 'RYBAX',
  },
  {
    value: 'SEMVX',
    label: 'SEMVX,  Hartford Schroders Emerging Markets Equity Fund Class A',
    name: 'Hartford Schroders Emerging Markets Equity Fund Class A',
    symbol: 'SEMVX',
  },
  {
    value: 'RYBCX',
    label: 'RYBCX,  Rydex Basic Materials Fund',
    name: 'Rydex Basic Materials Fund',
    symbol: 'RYBCX',
  },
  {
    value: 'DHMYX',
    label: 'DHMYX,  Diamond Hill Small-Mid Cap Fund',
    name: 'Diamond Hill Small-Mid Cap Fund',
    symbol: 'DHMYX',
  },
  {
    value: 'DHMIX',
    label: 'DHMIX,  Diamond Hill Small Mid Cap Fund Class I',
    name: 'Diamond Hill Small Mid Cap Fund Class I',
    symbol: 'DHMIX',
  },
  {
    value: 'DHMAX',
    label: 'DHMAX,  Diamond Hill Small-Mid Cap Fund',
    name: 'Diamond Hill Small-Mid Cap Fund',
    symbol: 'DHMAX',
  },
  {
    value: 'FERCX',
    label: 'FERCX,  Fidelity Advisor Emerging Asia Fund Class C',
    name: 'Fidelity Advisor Emerging Asia Fund Class C',
    symbol: 'FERCX',
  },
  {
    value: 'RYBIX',
    label: 'RYBIX,  Rydex Basic Materials Fund',
    name: 'Rydex Basic Materials Fund',
    symbol: 'RYBIX',
  },
  {
    value: 'RYBMX',
    label: 'RYBMX,  Rydex Basic Materials Fund Class A',
    name: 'Rydex Basic Materials Fund Class A',
    symbol: 'RYBMX',
  },
  {
    value: 'WILJX',
    label: 'WILJX,  William Blair International Leaders Fund R6 Class Shares',
    name: 'William Blair International Leaders Fund R6 Class Shares',
    symbol: 'WILJX',
  },
  {
    value: 'WILNX',
    label: 'WILNX,  William Blair International Leaders Fund',
    name: 'William Blair International Leaders Fund',
    symbol: 'WILNX',
  },
  {
    value: 'BPLEX',
    label: 'BPLEX,  Boston Partners Long/Short Equity Fund Investor Class',
    name: 'Boston Partners Long/Short Equity Fund Investor Class',
    symbol: 'BPLEX',
  },
  {
    value: 'BPLSX',
    label: 'BPLSX,  Boston Partners Long/Short Equity Fund',
    name: 'Boston Partners Long/Short Equity Fund',
    symbol: 'BPLSX',
  },
  {
    value: 'RLUEX',
    label: 'RLUEX,  Lazard US Equity Concentrated Portfolio',
    name: 'Lazard US Equity Concentrated Portfolio',
    symbol: 'RLUEX',
  },
  {
    value: 'TDMTX',
    label: 'TDMTX,  Templeton Developing Markets Trust Class C',
    name: 'Templeton Developing Markets Trust Class C',
    symbol: 'TDMTX',
  },
  {
    value: 'MAPTX',
    label: 'MAPTX,  Matthews International Funds - Matthews Pacific Tiger Fund',
    name: 'Matthews International Funds - Matthews Pacific Tiger Fund',
    symbol: 'MAPTX',
  },
  {
    value: 'MIPTX',
    label: 'MIPTX,  Matthews Pacific Tiger Fund',
    name: 'Matthews Pacific Tiger Fund',
    symbol: 'MIPTX',
  },
  {
    value: 'PRASX',
    label: 'PRASX,  T. Rowe Price New Asia Fund',
    name: 'T. Rowe Price New Asia Fund',
    symbol: 'PRASX',
  },
  {
    value: 'WIGTX',
    label: 'WIGTX,  Seven Canyons World Innovators Fund',
    name: 'Seven Canyons World Innovators Fund',
    symbol: 'WIGTX',
  },
  {
    value: 'WAGTX',
    label: 'WAGTX,  Seven Canyons World Innovators Fund Investor Class',
    name: 'Seven Canyons World Innovators Fund Investor Class',
    symbol: 'WAGTX',
  },
  {
    value: 'RLITX',
    label: 'RLITX,  Lazard International Strategic Equity Portfolio R6',
    name: 'Lazard International Strategic Equity Portfolio R6',
    symbol: 'RLITX',
  },
  {
    value: 'GBFAX',
    label: 'GBFAX,  VanEck Emerging Markets Fund',
    name: 'VanEck Emerging Markets Fund',
    symbol: 'GBFAX',
  },
  {
    value: 'BPIRX',
    label: 'BPIRX,  Boston Partners Long/Short Research Fund',
    name: 'Boston Partners Long/Short Research Fund',
    symbol: 'BPIRX',
  },
  {
    value: 'LZOEX',
    label: 'LZOEX,  Lazard Emerging Markets Equity Portfolio',
    name: 'Lazard Emerging Markets Equity Portfolio',
    symbol: 'LZOEX',
  },
  {
    value: 'SHSCX',
    label:
      'SHSCX,  BlackRock Health Sciences Opportunities Portfolio Investor C Shares',
    name: 'BlackRock Health Sciences Opportunities Portfolio Investor C Shares',
    symbol: 'SHSCX',
  },
  {
    value: 'SHSKX',
    label: 'SHSKX,  BlackRock Health Sciences Opportunities Portfolio Class K',
    name: 'BlackRock Health Sciences Opportunities Portfolio Class K',
    symbol: 'SHSKX',
  },
  {
    value: 'SHSSX',
    label:
      'SHSSX,  BlackRock Health Sciences Opportunities Portfolio Institutional Shares',
    name: 'BlackRock Health Sciences Opportunities Portfolio Institutional Shares',
    symbol: 'SHSSX',
  },
  {
    value: 'SHISX',
    label:
      'SHISX,  BlackRock Health Sciences Opportunities Portfolio Service Shares',
    name: 'BlackRock Health Sciences Opportunities Portfolio Service Shares',
    symbol: 'SHISX',
  },
  {
    value: 'BHSRX',
    label: 'BHSRX,  BlackRock Health Sciences Opportunities Portfolio',
    name: 'BlackRock Health Sciences Opportunities Portfolio',
    symbol: 'BHSRX',
  },
  {
    value: 'SHSAX',
    label:
      'SHSAX,  BlackRock Health Sciences Opportunities Portfolio Investor A Shares',
    name: 'BlackRock Health Sciences Opportunities Portfolio Investor A Shares',
    symbol: 'SHSAX',
  },
  {
    value: 'RLEMX',
    label: 'RLEMX,  Lazard Emerging Markets Equity Portfolio R6 Shares',
    name: 'Lazard Emerging Markets Equity Portfolio R6 Shares',
    symbol: 'RLEMX',
  },
  {
    value: 'GBATX',
    label: 'GBATX,  GMO Strategic Opportunities Allocation Fund Class III',
    name: 'GMO Strategic Opportunities Allocation Fund Class III',
    symbol: 'GBATX',
  },
  {
    value: 'RISAX',
    label: 'RISAX,  Manning & Napier Rainier International Discovery Series',
    name: 'Manning & Napier Rainier International Discovery Series',
    symbol: 'RISAX',
  },
  {
    value: 'TCMIX',
    label: 'TCMIX,  AMG TimesSquare International Small Cap Fund',
    name: 'AMG TimesSquare International Small Cap Fund',
    symbol: 'TCMIX',
  },
  {
    value: 'TCMPX',
    label: 'TCMPX,  AMG TimesSquare International Small Cap Fund',
    name: 'AMG TimesSquare International Small Cap Fund',
    symbol: 'TCMPX',
  },
  {
    value: 'JEMSX',
    label: 'JEMSX,  JPMorgan Emerging Markets Equity Fund',
    name: 'JPMorgan Emerging Markets Equity Fund',
    symbol: 'JEMSX',
  },
  {
    value: 'DREGX',
    label: 'DREGX,  Driehaus Emerging Markets Growth Fund Investor Class',
    name: 'Driehaus Emerging Markets Growth Fund Investor Class',
    symbol: 'DREGX',
  },
  {
    value: 'RAIIX',
    label: 'RAIIX,  Manning & Napier Rainier International Discovery Series',
    name: 'Manning & Napier Rainier International Discovery Series',
    symbol: 'RAIIX',
  },
  {
    value: 'HLEMX',
    label: 'HLEMX,  Harding Loevner Emerging Markets Portfolio Advisor Class',
    name: 'Harding Loevner Emerging Markets Portfolio Advisor Class',
    symbol: 'HLEMX',
  },
  {
    value: 'HLMEX',
    label: 'HLMEX,  Harding Loevner Institutional Emerging Markets Portfolio',
    name: 'Harding Loevner Institutional Emerging Markets Portfolio',
    symbol: 'HLMEX',
  },
  {
    value: 'SBYEX',
    label: 'SBYEX,  BNY Mellon Diversified Emerging Markets Fund',
    name: 'BNY Mellon Diversified Emerging Markets Fund',
    symbol: 'SBYEX',
  },
  {
    value: 'DBECX',
    label: 'DBECX,  BNY Mellon Diversified Emerging Markets Fund',
    name: 'BNY Mellon Diversified Emerging Markets Fund',
    symbol: 'DBECX',
  },
  {
    value: 'IEMFX',
    label: 'IEMFX,  T. Rowe Price Institutional Emerging Markets Equity Fund',
    name: 'T. Rowe Price Institutional Emerging Markets Equity Fund',
    symbol: 'IEMFX',
  },
  {
    value: 'IHOAX',
    label: 'IHOAX,  The Hartford International Opportunities Fund Class A',
    name: 'The Hartford International Opportunities Fund Class A',
    symbol: 'IHOAX',
  },
  {
    value: 'AILCX',
    label: 'AILCX,  American Beacon International Equity Fund Class C',
    name: 'American Beacon International Equity Fund Class C',
    symbol: 'AILCX',
  },
  {
    value: 'CLIFX',
    label: 'CLIFX,  Clifford Capital Partners Fund Institutional Class',
    name: 'Clifford Capital Partners Fund Institutional Class',
    symbol: 'CLIFX',
  },
  {
    value: 'AAISX',
    label: 'AAISX,  American Beacon International Equity Fund Advisor Class',
    name: 'American Beacon International Equity Fund Advisor Class',
    symbol: 'AAISX',
  },
  {
    value: 'PRZIX',
    label: 'PRZIX,  T. Rowe Price Emerging Markets Stock Fund',
    name: 'T. Rowe Price Emerging Markets Stock Fund',
    symbol: 'PRZIX',
  },
  {
    value: 'IHOIX',
    label: 'IHOIX,  The Hartford International Opportunities Fund Class I',
    name: 'The Hartford International Opportunities Fund Class I',
    symbol: 'IHOIX',
  },
  {
    value: 'PRMSX',
    label:
      'PRMSX,  T. Rowe Price International Funds, Inc. - T. Rowe Price Emerging Markets Stock Fund',
    name: 'T. Rowe Price International Funds, Inc. - T. Rowe Price Emerging Markets Stock Fund',
    symbol: 'PRMSX',
  },
  {
    value: 'SBCEX',
    label: 'SBCEX,  BNY Mellon Diversified Emerging Markets Fund Class I',
    name: 'BNY Mellon Diversified Emerging Markets Fund Class I',
    symbol: 'SBCEX',
  },
  {
    value: 'AAIEX',
    label: 'AAIEX,  American Beacon International Equity Fund',
    name: 'American Beacon International Equity Fund',
    symbol: 'AAIEX',
  },
  {
    value: 'IHOTX',
    label: 'IHOTX,  The Hartford International Opportunities Fund',
    name: 'The Hartford International Opportunities Fund',
    symbol: 'IHOTX',
  },
  {
    value: 'AIEAX',
    label: 'AIEAX,  American Beacon International Equity Fund Class A',
    name: 'American Beacon International Equity Fund Class A',
    symbol: 'AIEAX',
  },
  {
    value: 'IHOSX',
    label: 'IHOSX,  The Hartford International Opportunities Fund',
    name: 'The Hartford International Opportunities Fund',
    symbol: 'IHOSX',
  },
  {
    value: 'IHORX',
    label: 'IHORX,  The Hartford International Opportunities Fund Class R3',
    name: 'The Hartford International Opportunities Fund Class R3',
    symbol: 'IHORX',
  },
  {
    value: 'SABTX',
    label: 'SABTX,  SA U.S. Value Fund',
    name: 'SA U.S. Value Fund',
    symbol: 'SABTX',
  },
  {
    value: 'NCBVX',
    label: 'NCBVX,  PGIM Quant Solutions Mid-Cap Value Fund',
    name: 'PGIM Quant Solutions Mid-Cap Value Fund',
    symbol: 'NCBVX',
  },
  {
    value: 'SPRAX',
    label: 'SPRAX,  PGIM Quant Solutions Mid-Cap Value Fund',
    name: 'PGIM Quant Solutions Mid-Cap Value Fund',
    symbol: 'SPRAX',
  },
  {
    value: 'HLMNX',
    label: 'HLMNX,  Harding Loevner International Equity Portfolio',
    name: 'Harding Loevner International Equity Portfolio',
    symbol: 'HLMNX',
  },
  {
    value: 'HLMIX',
    label: 'HLMIX,  Harding Loevner International Equity Portfolio',
    name: 'Harding Loevner International Equity Portfolio',
    symbol: 'HLMIX',
  },
  {
    value: 'HJPIX',
    label: 'HJPIX,  Hennessy Japan Fund Institutional Class',
    name: 'Hennessy Japan Fund Institutional Class',
    symbol: 'HJPIX',
  },
  {
    value: 'HJPNX',
    label: 'HJPNX,  Hennessy Japan Fund',
    name: 'Hennessy Japan Fund',
    symbol: 'HJPNX',
  },
  {
    value: 'AEMZX',
    label: 'AEMZX,  Acadian Emerging Markets Portfolio Class I',
    name: 'Acadian Emerging Markets Portfolio Class I',
    symbol: 'AEMZX',
  },
  {
    value: 'GEDTX',
    label: 'GEDTX,  GMO Emerging Markets Select Equity Fund Class II',
    name: 'GMO Emerging Markets Select Equity Fund Class II',
    symbol: 'GEDTX',
  },
  {
    value: 'HJPSX',
    label: 'HJPSX,  Hennessy Japan Small Cap Fund Investor Class',
    name: 'Hennessy Japan Small Cap Fund Investor Class',
    symbol: 'HJPSX',
  },
  {
    value: 'HJSIX',
    label: 'HJSIX,  Hennessy Japan Small Cap Fund',
    name: 'Hennessy Japan Small Cap Fund',
    symbol: 'HJSIX',
  },
  {
    value: 'PRLAX',
    label:
      'PRLAX,  T. Rowe Price International Funds, Inc. - T. Rowe Price Latin America Fund',
    name: 'T. Rowe Price International Funds, Inc. - T. Rowe Price Latin America Fund',
    symbol: 'PRLAX',
  },
  {
    value: 'WESJX',
    label:
      'WESJX,  William Blair Emerging Markets Small Cap Growth Fund R6 Class',
    name: 'William Blair Emerging Markets Small Cap Growth Fund R6 Class',
    symbol: 'WESJX',
  },
  {
    value: 'WESNX',
    label:
      'WESNX,  William Blair Emerging Markets Small Cap Growth Fund Class N',
    name: 'William Blair Emerging Markets Small Cap Growth Fund Class N',
    symbol: 'WESNX',
  },
  {
    value: 'BESIX',
    label: 'BESIX,  William Blair Emerging Markets Small Cap Growth Fund',
    name: 'William Blair Emerging Markets Small Cap Growth Fund',
    symbol: 'BESIX',
  },
  {
    value: 'FFFCX',
    label: 'FFFCX,  Fidelity Freedom 2010 Fund',
    name: 'Fidelity Freedom 2010 Fund',
    symbol: 'FFFCX',
  },
  {
    value: 'CTFAX',
    label: 'CTFAX,  Columbia Thermostat Fund',
    name: 'Columbia Thermostat Fund',
    symbol: 'CTFAX',
  },
  {
    value: 'FZAEX',
    label: 'FZAEX,  Fidelity Advisor Focused Emerging Markets Fund Class Z',
    name: 'Fidelity Advisor Focused Emerging Markets Fund Class Z',
    symbol: 'FZAEX',
  },
  {
    value: 'VWINX',
    label: 'VWINX,  Vanguard Wellesley Income Fund',
    name: 'Vanguard Wellesley Income Fund',
    symbol: 'VWINX',
  },
  {
    value: 'GIBRX',
    label: 'GIBRX,  Guggenheim Total Return Bond Fund',
    name: 'Guggenheim Total Return Bond Fund',
    symbol: 'GIBRX',
  },
  {
    value: 'MGBIX',
    label: 'MGBIX,  AMG GW&K ESG Bond Fund - Class I',
    name: 'AMG GW&K ESG Bond Fund - Class I',
    symbol: 'MGBIX',
  },
  {
    value: 'MGFIX',
    label: 'MGFIX,  AMG GW&K ESG Bond Fund',
    name: 'AMG GW&K ESG Bond Fund',
    symbol: 'MGFIX',
  },
  {
    value: 'GIBAX',
    label: 'GIBAX,  Guggenheim Total Return Bond Fund Class A',
    name: 'Guggenheim Total Return Bond Fund Class A',
    symbol: 'GIBAX',
  },
  {
    value: 'GMCDX',
    label: 'GMCDX,  GMO Emerging Country Debt Fund Class III',
    name: 'GMO Emerging Country Debt Fund Class III',
    symbol: 'GMCDX',
  },
  {
    value: 'GMDFX',
    label: 'GMDFX,  GMO Emerging Country Debt Fund Class IV',
    name: 'GMO Emerging Country Debt Fund Class IV',
    symbol: 'GMDFX',
  },
  {
    value: 'FMCKX',
    label: 'FMCKX,  Fidelity Advisor Focused Emerging Markets Fund',
    name: 'Fidelity Advisor Focused Emerging Markets Fund',
    symbol: 'FMCKX',
  },
  {
    value: 'BTTRX',
    label: 'BTTRX,  American Century Zero Coupon 2025 Fund Investor Class',
    name: 'American Century Zero Coupon 2025 Fund Investor Class',
    symbol: 'BTTRX',
  },
  {
    value: 'VWIAX',
    label: 'VWIAX,  Vanguard Wellesley Income Fund',
    name: 'Vanguard Wellesley Income Fund',
    symbol: 'VWIAX',
  },
  {
    value: 'TIDDX',
    label: 'TIDDX,  T. Rowe Price International Discovery Fund',
    name: 'T. Rowe Price International Discovery Fund',
    symbol: 'TIDDX',
  },
  {
    value: 'TIBRX',
    label: 'TIBRX,  Thornburg Investment Income Builder Fund Class R3',
    name: 'Thornburg Investment Income Builder Fund Class R3',
    symbol: 'TIBRX',
  },
  {
    value: 'TIBCX',
    label: 'TIBCX,  Thornburg Investment Income Builder Fund Class C',
    name: 'Thornburg Investment Income Builder Fund Class C',
    symbol: 'TIBCX',
  },
  {
    value: 'PRIDX',
    label:
      'PRIDX,  T. Rowe Price International Funds, Inc. - T. Rowe Price International Discovery Fund',
    name: 'T. Rowe Price International Funds, Inc. - T. Rowe Price International Discovery Fund',
    symbol: 'PRIDX',
  },
  {
    value: 'VMNVX',
    label:
      'VMNVX,  Vanguard Whitehall Funds - Vanguard Global Minimum Volatility Fund',
    name: 'Vanguard Whitehall Funds - Vanguard Global Minimum Volatility Fund',
    symbol: 'VMNVX',
  },
  {
    value: 'FIMKX',
    label: 'FIMKX,  Fidelity Advisor Focused Emerging Markets Fund',
    name: 'Fidelity Advisor Focused Emerging Markets Fund',
    symbol: 'FIMKX',
  },
  {
    value: 'FAMKX',
    label: 'FAMKX,  Fidelity Advisor Focused Emerging Markets Fund',
    name: 'Fidelity Advisor Focused Emerging Markets Fund',
    symbol: 'FAMKX',
  },
  {
    value: 'FTMKX',
    label: 'FTMKX,  Fidelity Advisor Focused Emerging Markets Fund',
    name: 'Fidelity Advisor Focused Emerging Markets Fund',
    symbol: 'FTMKX',
  },
  {
    value: 'APHKX',
    label: 'APHKX,  Artisan International Value Fund Institutional Class',
    name: 'Artisan International Value Fund Institutional Class',
    symbol: 'APHKX',
  },
  {
    value: 'ARTKX',
    label: 'ARTKX,  Artisan International Value Fund',
    name: 'Artisan International Value Fund',
    symbol: 'ARTKX',
  },
  {
    value: 'SVOAX',
    label:
      'SVOAX,  SEI Institutional Managed Trust - U.S. Managed Volatility Fund',
    name: 'SEI Institutional Managed Trust - U.S. Managed Volatility Fund',
    symbol: 'SVOAX',
  },
  {
    value: 'MIPIX',
    label: 'MIPIX,  Matthews Asia Dividend Fund Institutional Class',
    name: 'Matthews Asia Dividend Fund Institutional Class',
    symbol: 'MIPIX',
  },
  {
    value: 'DNINX',
    label: 'DNINX,  Dunham International Stock Fund Class N',
    name: 'Dunham International Stock Fund Class N',
    symbol: 'DNINX',
  },
  {
    value: 'RWDYX',
    label: 'RWDYX,  Redwood Managed Volatility Fund',
    name: 'Redwood Managed Volatility Fund',
    symbol: 'RWDYX',
  },
  {
    value: 'SIIDX',
    label: 'SIIDX,  Bernstein Intermediate Duration Institutional Portfolio',
    name: 'Bernstein Intermediate Duration Institutional Portfolio',
    symbol: 'SIIDX',
  },
  {
    value: 'RWDIX',
    label: 'RWDIX,  Redwood Managed Volatility Fund Class I',
    name: 'Redwood Managed Volatility Fund Class I',
    symbol: 'RWDIX',
  },
  {
    value: 'APDKX',
    label: 'APDKX,  Artisan International Value Fund',
    name: 'Artisan International Value Fund',
    symbol: 'APDKX',
  },
  {
    value: 'SGGDX',
    label: 'SGGDX,  First Eagle Gold Fund Class A',
    name: 'First Eagle Gold Fund Class A',
    symbol: 'SGGDX',
  },
  {
    value: 'VRISX',
    label: 'VRISX,  Virtus KAR International Small-Mid Cap Fund Class R6',
    name: 'Virtus KAR International Small-Mid Cap Fund Class R6',
    symbol: 'VRISX',
  },
  {
    value: 'VIISX',
    label: 'VIISX,  Virtus KAR International Small-Mid Cap Fund',
    name: 'Virtus KAR International Small-Mid Cap Fund',
    symbol: 'VIISX',
  },
  {
    value: 'FEGOX',
    label: 'FEGOX,  First Eagle Gold Fund Class C',
    name: 'First Eagle Gold Fund Class C',
    symbol: 'FEGOX',
  },
  {
    value: 'HFSAX',
    label: 'HFSAX,  Hundredfold Select Alternative Fund Investor Class',
    name: 'Hundredfold Select Alternative Fund Investor Class',
    symbol: 'HFSAX',
  },
  {
    value: 'JIBFX',
    label: 'JIBFX,  Johnson Institutional Core Bond Fund',
    name: 'Johnson Institutional Core Bond Fund',
    symbol: 'JIBFX',
  },
  {
    value: 'HRMVX',
    label: 'HRMVX,  Harbor Mid Cap Value Fund Administrative Class',
    name: 'Harbor Mid Cap Value Fund Administrative Class',
    symbol: 'HRMVX',
  },
  {
    value: 'TFESX',
    label:
      'TFESX,  Templeton Institutional Fund International Equity Series Service Shares',
    name: 'Templeton Institutional Fund International Equity Series Service Shares',
    symbol: 'TFESX',
  },
  {
    value: 'HIMVX',
    label: 'HIMVX,  Harbor Mid Cap Value Fund',
    name: 'Harbor Mid Cap Value Fund',
    symbol: 'HIMVX',
  },
  {
    value: 'HAMVX',
    label: 'HAMVX,  Harbor Mid Cap Value Fund',
    name: 'Harbor Mid Cap Value Fund',
    symbol: 'HAMVX',
  },
  {
    value: 'HNMVX',
    label: 'HNMVX,  Harbor Mid Cap Value Fund Retirement Class',
    name: 'Harbor Mid Cap Value Fund Retirement Class',
    symbol: 'HNMVX',
  },
  {
    value: 'TFEQX',
    label: 'TFEQX,  Templeton Institutional Fund International Equity Series',
    name: 'Templeton Institutional Fund International Equity Series',
    symbol: 'TFEQX',
  },
  {
    value: 'FEGIX',
    label: 'FEGIX,  First Eagle Gold Fund',
    name: 'First Eagle Gold Fund',
    symbol: 'FEGIX',
  },
  {
    value: 'FHKIX',
    label: 'FHKIX,  Fidelity China Region Fund',
    name: 'Fidelity China Region Fund',
    symbol: 'FHKIX',
  },
  {
    value: 'FHKAX',
    label: 'FHKAX,  Fidelity Advisor China Region Fund Class A',
    name: 'Fidelity Advisor China Region Fund Class A',
    symbol: 'FHKAX',
  },
  {
    value: 'RINPX',
    label: 'RINPX,  Royce International Premier Fund',
    name: 'Royce International Premier Fund',
    symbol: 'RINPX',
  },
  {
    value: 'DAMDX',
    label: 'DAMDX,  Dunham Monthly Distribution Fund Class A',
    name: 'Dunham Monthly Distribution Fund Class A',
    symbol: 'DAMDX',
  },
  {
    value: 'PRIUX',
    label: 'PRIUX,  T. Rowe Price International Stock Fund',
    name: 'T. Rowe Price International Stock Fund',
    symbol: 'PRIUX',
  },
  {
    value: 'IFPUX',
    label: 'IFPUX,  Independent Franchise Partners US Equity Fund',
    name: 'Independent Franchise Partners US Equity Fund',
    symbol: 'IFPUX',
  },
  {
    value: 'FSMVX',
    label: 'FSMVX,  Fidelity Mid Cap Value Fund',
    name: 'Fidelity Mid Cap Value Fund',
    symbol: 'FSMVX',
  },
  {
    value: 'FMPOX',
    label: 'FMPOX,  Fidelity Mid Cap Value Fund',
    name: 'Fidelity Mid Cap Value Fund',
    symbol: 'FMPOX',
  },
  {
    value: 'THOIX',
    label: 'THOIX,  Thornburg Global Opportunities Fund',
    name: 'Thornburg Global Opportunities Fund',
    symbol: 'THOIX',
  },
  {
    value: 'MBEYX',
    label: 'MBEYX,  AMG GW&K Global Allocation Fund Class Z',
    name: 'AMG GW&K Global Allocation Fund Class Z',
    symbol: 'MBEYX',
  },
  {
    value: 'DCMDX',
    label: 'DCMDX,  Dunham Monthly Distribution Fund Class C',
    name: 'Dunham Monthly Distribution Fund Class C',
    symbol: 'DCMDX',
  },
  {
    value: 'DNMDX',
    label: 'DNMDX,  Dunham Monthly Distribution Fund',
    name: 'Dunham Monthly Distribution Fund',
    symbol: 'DNMDX',
  },
  {
    value: 'AVUAX',
    label: 'AVUAX,  American Century Mid Cap Value Fund',
    name: 'American Century Mid Cap Value Fund',
    symbol: 'AVUAX',
  },
  {
    value: 'AMVRX',
    label: 'AMVRX,  American Century Mid Cap Value Fund',
    name: 'American Century Mid Cap Value Fund',
    symbol: 'AMVRX',
  },
  {
    value: 'ACCLX',
    label: 'ACCLX,  American Century Mid Cap Value Fund',
    name: 'American Century Mid Cap Value Fund',
    symbol: 'ACCLX',
  },
  {
    value: 'LVAEX',
    label: 'LVAEX,  LSV Value Equity Fund',
    name: 'LSV Value Equity Fund',
    symbol: 'LVAEX',
  },
  {
    value: 'LCRIX',
    label: 'LCRIX,  Leuthold Core Investment Fund Class Institutional',
    name: 'Leuthold Core Investment Fund Class Institutional',
    symbol: 'LCRIX',
  },
  {
    value: 'VAIPX',
    label: 'VAIPX,  Vanguard Inflation-Protected Securities Fund',
    name: 'Vanguard Inflation-Protected Securities Fund',
    symbol: 'VAIPX',
  },
  {
    value: 'SAOSX',
    label: 'SAOSX,  Guggenheim Alpha Opportunity Fund',
    name: 'Guggenheim Alpha Opportunity Fund',
    symbol: 'SAOSX',
  },
  {
    value: 'SSIRX',
    label: 'SSIRX,  Sierra Tactical Core Income Fund Institutional Class',
    name: 'Sierra Tactical Core Income Fund Institutional Class',
    symbol: 'SSIRX',
  },
  {
    value: 'RRTBX',
    label: 'RRTBX,  T. Rowe Price Retirement 2020 Fund',
    name: 'T. Rowe Price Retirement 2020 Fund',
    symbol: 'RRTBX',
  },
  {
    value: 'PARBX',
    label: 'PARBX,  T. Rowe Price Retirement 2020 Fund',
    name: 'T. Rowe Price Retirement 2020 Fund',
    symbol: 'PARBX',
  },
  {
    value: 'TRRBX',
    label: 'TRRBX,  T. Rowe Price Retirement 2020 Fund',
    name: 'T. Rowe Price Retirement 2020 Fund',
    symbol: 'TRRBX',
  },
  {
    value: 'FNORX',
    label: 'FNORX,  Fidelity Investment Trust - Fidelity Nordic Fund',
    name: 'Fidelity Investment Trust - Fidelity Nordic Fund',
    symbol: 'FNORX',
  },
  {
    value: 'GILPX',
    label: 'GILPX,  Guggenheim Limited Duration Fund Class P',
    name: 'Guggenheim Limited Duration Fund Class P',
    symbol: 'GILPX',
  },
  {
    value: 'GMODX',
    label: 'GMODX,  GMO Trust - GMO Debt Opportunities Fund',
    name: 'GMO Trust - GMO Debt Opportunities Fund',
    symbol: 'GMODX',
  },
  {
    value: 'PKSAX',
    label: 'PKSAX,  Virtus KAR Small-Cap Core Fund',
    name: 'Virtus KAR Small-Cap Core Fund',
    symbol: 'PKSAX',
  },
  {
    value: 'RRMVX',
    label: 'RRMVX,  T. Rowe Price Mid-Cap Value Fund',
    name: 'T. Rowe Price Mid-Cap Value Fund',
    symbol: 'RRMVX',
  },
  {
    value: 'PKSFX',
    label: 'PKSFX,  Virtus KAR Small-Cap Core Fund Class I',
    name: 'Virtus KAR Small-Cap Core Fund Class I',
    symbol: 'PKSFX',
  },
  {
    value: 'VSCRX',
    label: 'VSCRX,  Virtus KAR Small-Cap Core Fund',
    name: 'Virtus KAR Small-Cap Core Fund',
    symbol: 'VSCRX',
  },
  {
    value: 'TAMVX',
    label: 'TAMVX,  T. Rowe Price Mid-Cap Value Fund Advisor Class',
    name: 'T. Rowe Price Mid-Cap Value Fund Advisor Class',
    symbol: 'TAMVX',
  },
  {
    value: 'TRMIX',
    label: 'TRMIX,  T. Rowe Price Mid-Cap Value Fund, Inc.',
    name: 'T. Rowe Price Mid-Cap Value Fund, Inc.',
    symbol: 'TRMIX',
  },
  {
    value: 'TRMCX',
    label: 'TRMCX,  T. Rowe Price Mid-Cap Value Fund',
    name: 'T. Rowe Price Mid-Cap Value Fund',
    symbol: 'TRMCX',
  },
  {
    value: 'CUBAX',
    label: 'CUBAX,  Calvert Flexible Bond Fund Class A',
    name: 'Calvert Flexible Bond Fund Class A',
    symbol: 'CUBAX',
  },
  {
    value: 'TSSCX',
    label: 'TSSCX,  Thornburg Strategic Municipal Income Fund',
    name: 'Thornburg Strategic Municipal Income Fund',
    symbol: 'TSSCX',
  },
  {
    value: 'FDLSX',
    label: 'FDLSX,  Fidelity Select Leisure Portfolio',
    name: 'Fidelity Select Leisure Portfolio',
    symbol: 'FDLSX',
  },
  {
    value: 'AADBX',
    label: 'AADBX,  American Beacon Balanced Fund R5 Class',
    name: 'American Beacon Balanced Fund R5 Class',
    symbol: 'AADBX',
  },
  {
    value: 'PKSCX',
    label: 'PKSCX,  Virtus KAR Small-Cap Core Fund Class C',
    name: 'Virtus KAR Small-Cap Core Fund Class C',
    symbol: 'PKSCX',
  },
  {
    value: 'BPGIX',
    label: 'BPGIX,  Boston Partners Global Equity Institutional Class',
    name: 'Boston Partners Global Equity Institutional Class',
    symbol: 'BPGIX',
  },
  {
    value: 'NPSFX',
    label: 'NPSFX,  Nuveen Preferred Securities & Income Fund Class R6',
    name: 'Nuveen Preferred Securities & Income Fund Class R6',
    symbol: 'NPSFX',
  },
  {
    value: 'FFFEX',
    label: 'FFFEX,  Fidelity Freedom 2030 Fund',
    name: 'Fidelity Freedom 2030 Fund',
    symbol: 'FFFEX',
  },
  {
    value: 'RRTAX',
    label: 'RRTAX,  T. Rowe Price Retirement 2010 Fund',
    name: 'T. Rowe Price Retirement 2010 Fund',
    symbol: 'RRTAX',
  },
  {
    value: 'PARAX',
    label: 'PARAX,  T. Rowe Price Retirement 2010 Fund',
    name: 'T. Rowe Price Retirement 2010 Fund',
    symbol: 'PARAX',
  },
  {
    value: 'SUSYX',
    label:
      'SUSYX,  SEI Institutional Managed Trust U.S. Managed Volatility Fund',
    name: 'SEI Institutional Managed Trust U.S. Managed Volatility Fund',
    symbol: 'SUSYX',
  },
  {
    value: 'JIBBX',
    label: 'JIBBX,  JPMorgan SmartRetirement Blend Income Fund Class R5',
    name: 'JPMorgan SmartRetirement Blend Income Fund Class R5',
    symbol: 'JIBBX',
  },
  {
    value: 'JIJSX',
    label: 'JIJSX,  JPMorgan SmartRetirement Blend Income Fund Class I',
    name: 'JPMorgan SmartRetirement Blend Income Fund Class I',
    symbol: 'JIJSX',
  },
  {
    value: 'TRRAX',
    label: 'TRRAX,  T. Rowe Price Retirement 2010 Fund',
    name: 'T. Rowe Price Retirement 2010 Fund',
    symbol: 'TRRAX',
  },
  {
    value: 'NFRFX',
    label: 'NFRFX,  Nuveen Floating Rate Income Fund Class R6',
    name: 'Nuveen Floating Rate Income Fund Class R6',
    symbol: 'NFRFX',
  },
  {
    value: 'FSBCX',
    label: 'FSBCX,  Federated Hermes Global Allocation Fund Class C Shares',
    name: 'Federated Hermes Global Allocation Fund Class C Shares',
    symbol: 'FSBCX',
  },
  {
    value: 'FSBKX',
    label: 'FSBKX,  Federated Hermes Global Allocation Fund Class R Shares',
    name: 'Federated Hermes Global Allocation Fund Class R Shares',
    symbol: 'FSBKX',
  },
  {
    value: 'FSTBX',
    label: 'FSTBX,  Federated Hermes Global Allocation Fund',
    name: 'Federated Hermes Global Allocation Fund',
    symbol: 'FSTBX',
  },
  {
    value: 'PRSIX',
    label: 'PRSIX,  T. Rowe Price Spectrum Conservative Allocation Fund',
    name: 'T. Rowe Price Spectrum Conservative Allocation Fund',
    symbol: 'PRSIX',
  },
  {
    value: 'SBFIX',
    label: 'SBFIX,  Federated Hermes Global Allocation Fund',
    name: 'Federated Hermes Global Allocation Fund',
    symbol: 'SBFIX',
  },
  {
    value: 'RYHHX',
    label: 'RYHHX,  Rydex High Yield Strategy Fund',
    name: 'Rydex High Yield Strategy Fund',
    symbol: 'RYHHX',
  },
  {
    value: 'IPFPX',
    label: 'IPFPX,  Poplar Forest Partners Fund',
    name: 'Poplar Forest Partners Fund',
    symbol: 'IPFPX',
  },
  {
    value: 'PFPFX',
    label: 'PFPFX,  Poplar Forest Partners Fund Class A',
    name: 'Poplar Forest Partners Fund Class A',
    symbol: 'PFPFX',
  },
  {
    value: 'KWICX',
    label: 'KWICX,  Kinetics Alternative Income Fund',
    name: 'Kinetics Alternative Income Fund',
    symbol: 'KWICX',
  },
  {
    value: 'KWIAX',
    label: 'KWIAX,  Kinetics Alternative Income Fund',
    name: 'Kinetics Alternative Income Fund',
    symbol: 'KWIAX',
  },
  {
    value: 'GILDX',
    label: 'GILDX,  Guggenheim Limited Duration Fund A-Class',
    name: 'Guggenheim Limited Duration Fund A-Class',
    symbol: 'GILDX',
  },
  {
    value: 'RYHGX',
    label: 'RYHGX,  Rydex High Yield Strategy Fund',
    name: 'Rydex High Yield Strategy Fund',
    symbol: 'RYHGX',
  },
  {
    value: 'RYHDX',
    label: 'RYHDX,  Rydex High Yield Strategy Fund Class A',
    name: 'Rydex High Yield Strategy Fund Class A',
    symbol: 'RYHDX',
  },
  {
    value: 'GIFCX',
    label: 'GIFCX,  Guggenheim Floating Rate Strategies Fund',
    name: 'Guggenheim Floating Rate Strategies Fund',
    symbol: 'GIFCX',
  },
  {
    value: 'GIFAX',
    label: 'GIFAX,  Guggenheim Floating Rate Strategies Fund Class A',
    name: 'Guggenheim Floating Rate Strategies Fund Class A',
    symbol: 'GIFAX',
  },
  {
    value: 'GIFPX',
    label:
      'GIFPX,  Guggenheim Funds Trust - Guggenheim Floating Rate Strategies Fund',
    name: 'Guggenheim Funds Trust - Guggenheim Floating Rate Strategies Fund',
    symbol: 'GIFPX',
  },
  {
    value: 'GIFIX',
    label: 'GIFIX,  Guggenheim Floating Rate Strategies Fund',
    name: 'Guggenheim Floating Rate Strategies Fund',
    symbol: 'GIFIX',
  },
  {
    value: 'GIOCX',
    label: 'GIOCX,  Guggenheim Macro Opportunities Fund Class C',
    name: 'Guggenheim Macro Opportunities Fund Class C',
    symbol: 'GIOCX',
  },
  {
    value: 'GIOAX',
    label: 'GIOAX,  Guggenheim Macro Opportunities Fund Class A',
    name: 'Guggenheim Macro Opportunities Fund Class A',
    symbol: 'GIOAX',
  },
  {
    value: 'GIOPX',
    label: 'GIOPX,  Guggenheim Macro Opportunities Fund Class P',
    name: 'Guggenheim Macro Opportunities Fund Class P',
    symbol: 'GIOPX',
  },
  {
    value: 'GIOIX',
    label: 'GIOIX,  Guggenheim Macro Opportunities Fund',
    name: 'Guggenheim Macro Opportunities Fund',
    symbol: 'GIOIX',
  },
  {
    value: 'MIOPX',
    label:
      'MIOPX,  Morgan Stanley Institutional Fund, Inc. International Opportunity Portfolio Class A',
    name: 'Morgan Stanley Institutional Fund, Inc. International Opportunity Portfolio Class A',
    symbol: 'MIOPX',
  },
  {
    value: 'FIIVX',
    label: 'FIIVX,  Fidelity Advisor Managed Retirement 2020 Fund Class I',
    name: 'Fidelity Advisor Managed Retirement 2020 Fund Class I',
    symbol: 'FIIVX',
  },
  {
    value: 'FARVX',
    label: 'FARVX,  Fidelity Advisor Managed Retirement 2020 Fund Class A',
    name: 'Fidelity Advisor Managed Retirement 2020 Fund Class A',
    symbol: 'FARVX',
  },
  {
    value: 'FIRVX',
    label: 'FIRVX,  Fidelity Managed Retirement 2020 Fund',
    name: 'Fidelity Managed Retirement 2020 Fund',
    symbol: 'FIRVX',
  },
  {
    value: 'FIRSX',
    label: 'FIRSX,  Fidelity Managed Retirement 2015 Fund',
    name: 'Fidelity Managed Retirement 2015 Fund',
    symbol: 'FIRSX',
  },
  {
    value: 'NFFCX',
    label: 'NFFCX,  Nuveen Floating Rate Income Fund Class C',
    name: 'Nuveen Floating Rate Income Fund Class C',
    symbol: 'NFFCX',
  },
  {
    value: 'NFRIX',
    label: 'NFRIX,  Nuveen Floating Rate Income Fund',
    name: 'Nuveen Floating Rate Income Fund',
    symbol: 'NFRIX',
  },
  {
    value: 'NCOIX',
    label: 'NCOIX,  Nuveen High Yield Income Fund Class I',
    name: 'Nuveen High Yield Income Fund Class I',
    symbol: 'NCOIX',
  },
  {
    value: 'NFRAX',
    label: 'NFRAX,  Nuveen Floating Rate Income Fund Class A',
    name: 'Nuveen Floating Rate Income Fund Class A',
    symbol: 'NFRAX',
  },
  {
    value: 'FRQIX',
    label: 'FRQIX,  Fidelity Advisor Managed Retirement 2010 Fund Class I',
    name: 'Fidelity Advisor Managed Retirement 2010 Fund Class I',
    symbol: 'FRQIX',
  },
  {
    value: 'FIRRX',
    label: 'FIRRX,  Fidelity Simplicity RMD 2010 Fund',
    name: 'Fidelity Simplicity RMD 2010 Fund',
    symbol: 'FIRRX',
  },
  {
    value: 'SEGPX',
    label: 'SEGPX,  Guggenheim Large Cap Value Fund',
    name: 'Guggenheim Large Cap Value Fund',
    symbol: 'SEGPX',
  },
  {
    value: 'CBMSX',
    label: 'CBMSX,  Allspring C&B Mid Cap Value Fund',
    name: 'Allspring C&B Mid Cap Value Fund',
    symbol: 'CBMSX',
  },
  {
    value: 'VEVRX',
    label: 'VEVRX,  Victory Sycamore Established Value Fund',
    name: 'Victory Sycamore Established Value Fund',
    symbol: 'VEVRX',
  },
  {
    value: 'VEVIX',
    label: 'VEVIX,  Victory Sycamore Established Value Fund Class I',
    name: 'Victory Sycamore Established Value Fund Class I',
    symbol: 'VEVIX',
  },
  {
    value: 'FRIMX',
    label: 'FRIMX,  Fidelity Advisor Managed Retirement Income Fund Class I',
    name: 'Fidelity Advisor Managed Retirement Income Fund Class I',
    symbol: 'FRIMX',
  },
  {
    value: 'FIRMX',
    label: 'FIRMX,  Fidelity Managed Retirement Income Fund',
    name: 'Fidelity Managed Retirement Income Fund',
    symbol: 'FIRMX',
  },
  {
    value: 'FIXIX',
    label: 'FIXIX,  Fidelity International Small Cap Fund',
    name: 'Fidelity International Small Cap Fund',
    symbol: 'FIXIX',
  },
  {
    value: 'FISMX',
    label: 'FISMX,  Fidelity International Small Cap Fund',
    name: 'Fidelity International Small Cap Fund',
    symbol: 'FISMX',
  },
  {
    value: 'FIASX',
    label: 'FIASX,  Fidelity International Small Cap Fund',
    name: 'Fidelity International Small Cap Fund',
    symbol: 'FIASX',
  },
  {
    value: 'FTISX',
    label: 'FTISX,  Fidelity Advisor International Small Cap Fund Class M',
    name: 'Fidelity Advisor International Small Cap Fund Class M',
    symbol: 'FTISX',
  },
  {
    value: 'GIBIX',
    label: 'GIBIX,  Guggenheim Total Return Bond Fund',
    name: 'Guggenheim Total Return Bond Fund',
    symbol: 'GIBIX',
  },
  {
    value: 'GIBCX',
    label: 'GIBCX,  Guggenheim Total Return Bond Fund',
    name: 'Guggenheim Total Return Bond Fund',
    symbol: 'GIBCX',
  },
  {
    value: 'GIBLX',
    label: 'GIBLX,  Guggenheim Total Return Bond Fund Class P',
    name: 'Guggenheim Total Return Bond Fund Class P',
    symbol: 'GIBLX',
  },
  {
    value: 'FICSX',
    label: 'FICSX,  Fidelity Advisor International Small Cap Fund Class C',
    name: 'Fidelity Advisor International Small Cap Fund Class C',
    symbol: 'FICSX',
  },
  {
    value: 'PTIAX',
    label:
      'PTIAX,  Trust for Professional Managers - Performance Trust Strategic Bond Fund',
    name: 'Trust for Professional Managers - Performance Trust Strategic Bond Fund',
    symbol: 'PTIAX',
  },
  {
    value: 'NQWFX',
    label: 'NQWFX,  Nuveen Flexible Income Fund Class R6',
    name: 'Nuveen Flexible Income Fund Class R6',
    symbol: 'NQWFX',
  },
  {
    value: 'NWQIX',
    label: 'NWQIX,  Nuveen Flexible Income Fund Class I',
    name: 'Nuveen Flexible Income Fund Class I',
    symbol: 'NWQIX',
  },
  {
    value: 'NWQAX',
    label: 'NWQAX,  Nuveen Flexible Income Fund',
    name: 'Nuveen Flexible Income Fund',
    symbol: 'NWQAX',
  },
  {
    value: 'NWQCX',
    label: 'NWQCX,  Nuveen Flexible Income Fund Class C',
    name: 'Nuveen Flexible Income Fund Class C',
    symbol: 'NWQCX',
  },
  {
    value: 'GILCX',
    label: 'GILCX,  Guggenheim Large Cap Value Fund Class Institutional',
    name: 'Guggenheim Large Cap Value Fund Class Institutional',
    symbol: 'GILCX',
  },
  {
    value: 'VSCGX',
    label:
      'VSCGX,  Vanguard LifeStrategy Conservative Growth Fund Investor Shares',
    name: 'Vanguard LifeStrategy Conservative Growth Fund Investor Shares',
    symbol: 'VSCGX',
  },
  {
    value: 'FGADX',
    label: 'FGADX,  Franklin Gold and Precious Metals Fund Advisor Class',
    name: 'Franklin Gold and Precious Metals Fund Advisor Class',
    symbol: 'FGADX',
  },
  {
    value: 'ITTIX',
    label: 'ITTIX,  Hartford Multi-Asset Income Fund Class I',
    name: 'Hartford Multi-Asset Income Fund Class I',
    symbol: 'ITTIX',
  },
  {
    value: 'MAPOX',
    label: 'MAPOX,  Mairs & Power Balanced Fund Investor Class',
    name: 'Mairs & Power Balanced Fund Investor Class',
    symbol: 'MAPOX',
  },
  {
    value: 'FIRNX',
    label: 'FIRNX,  Fidelity Simplicity RMD Income Fund',
    name: 'Fidelity Simplicity RMD Income Fund',
    symbol: 'FIRNX',
  },
  {
    value: 'MTINX',
    label: 'MTINX,  MainStay Income Builder Fund Investor Class',
    name: 'MainStay Income Builder Fund Investor Class',
    symbol: 'MTINX',
  },
  {
    value: 'MTXVX',
    label: 'MTXVX,  MainStay Income Builder Fund Class R3',
    name: 'MainStay Income Builder Fund Class R3',
    symbol: 'MTXVX',
  },
  {
    value: 'GETGX',
    label: 'GETGX,  Victory Sycamore Established Value Fund',
    name: 'Victory Sycamore Established Value Fund',
    symbol: 'GETGX',
  },
  {
    value: 'VEVCX',
    label: 'VEVCX,  Victory Sycamore Established Value Fund',
    name: 'Victory Sycamore Established Value Fund',
    symbol: 'VEVCX',
  },
  {
    value: 'SIUPX',
    label: 'SIUPX,  Guggenheim Core Bond Fund Class P',
    name: 'Guggenheim Core Bond Fund Class P',
    symbol: 'SIUPX',
  },
  {
    value: 'SIUSX',
    label: 'SIUSX,  Guggenheim Core Bond Fund',
    name: 'Guggenheim Core Bond Fund',
    symbol: 'SIUSX',
  },
  {
    value: 'GIUSX',
    label: 'GIUSX,  Guggenheim Core Bond Fund',
    name: 'Guggenheim Core Bond Fund',
    symbol: 'GIUSX',
  },
  {
    value: 'SDICX',
    label:
      'SDICX,  Guggenheim Funds Trust - Guggenheim Investment Grade Bond Fund',
    name: 'Guggenheim Funds Trust - Guggenheim Investment Grade Bond Fund',
    symbol: 'SDICX',
  },
  {
    value: 'QGRNX',
    label: 'QGRNX,  Invesco Global Allocation Fund',
    name: 'Invesco Global Allocation Fund',
    symbol: 'QGRNX',
  },
  {
    value: 'SEVIX',
    label:
      'SEVIX,  SEI Institutional Managed Trust U.S. Managed Volatility Fund',
    name: 'SEI Institutional Managed Trust U.S. Managed Volatility Fund',
    symbol: 'SEVIX',
  },
  {
    value: 'QGRCX',
    label: 'QGRCX,  Invesco Global Allocation Fund',
    name: 'Invesco Global Allocation Fund',
    symbol: 'QGRCX',
  },
  {
    value: 'CFAIX',
    label: 'CFAIX,  Calvert Conservative Allocation Fund',
    name: 'Calvert Conservative Allocation Fund',
    symbol: 'CFAIX',
  },
  {
    value: 'CCLAX',
    label: 'CCLAX,  Calvert Conservative Allocation Fund',
    name: 'Calvert Conservative Allocation Fund',
    symbol: 'CCLAX',
  },
  {
    value: 'SBFCX',
    label: 'SBFCX,  Victory INCORE Investment Grade Convertible Fund',
    name: 'Victory INCORE Investment Grade Convertible Fund',
    symbol: 'SBFCX',
  },
  {
    value: 'HAOYX',
    label: 'HAOYX,  The Hartford International Opportunities Fund Class Y',
    name: 'The Hartford International Opportunities Fund Class Y',
    symbol: 'HAOYX',
  },
  {
    value: 'IVWAX',
    label: 'IVWAX,  IVA Worldwide Fund Class A',
    name: 'IVA Worldwide Fund Class A',
    symbol: 'IVWAX',
  },
  {
    value: 'VGIVX',
    label:
      'VGIVX,  Vanguard Emerging Markets Government Bond Index Fund Institutional Shares',
    name: 'Vanguard Emerging Markets Government Bond Index Fund Institutional Shares',
    symbol: 'VGIVX',
  },
  {
    value: 'JBFRX',
    label: 'JBFRX,  John Hancock Bond Fund Class R4',
    name: 'John Hancock Bond Fund Class R4',
    symbol: 'JBFRX',
  },
  {
    value: 'JHNBX',
    label: 'JHNBX,  John Hancock Bond Fund Class A',
    name: 'John Hancock Bond Fund Class A',
    symbol: 'JHNBX',
  },
  {
    value: 'CDICX',
    label: 'CDICX,  Calvert Short Duration Income Fund Class C',
    name: 'Calvert Short Duration Income Fund Class C',
    symbol: 'CDICX',
  },
  {
    value: 'DAINX',
    label: 'DAINX,  Dunham International Stock Fund',
    name: 'Dunham International Stock Fund',
    symbol: 'DAINX',
  },
  {
    value: 'JMBRX',
    label: 'JMBRX,  JPMorgan SmartRetirement Blend 2045 Fund Class R5',
    name: 'JPMorgan SmartRetirement Blend 2045 Fund Class R5',
    symbol: 'JMBRX',
  },
  {
    value: 'JMYAX',
    label: 'JMYAX,  JPMorgan SmartRetirement Blend 2045 Fund Class R6',
    name: 'JPMorgan SmartRetirement Blend 2045 Fund Class R6',
    symbol: 'JMYAX',
  },
  {
    value: 'FTSDX',
    label: 'FTSDX,  Fidelity Strategic Dividend & Income Fund',
    name: 'Fidelity Strategic Dividend & Income Fund',
    symbol: 'FTSDX',
  },
  {
    value: 'FASDX',
    label: 'FASDX,  Fidelity Strategic Dividend & Income Fund',
    name: 'Fidelity Strategic Dividend & Income Fund',
    symbol: 'FASDX',
  },
  {
    value: 'FSIDX',
    label: 'FSIDX,  Fidelity Strategic Dividend & Income Fund',
    name: 'Fidelity Strategic Dividend & Income Fund',
    symbol: 'FSIDX',
  },
  {
    value: 'FSDIX',
    label: 'FSDIX,  Fidelity Strategic Dividend & Income Fund',
    name: 'Fidelity Strategic Dividend & Income Fund',
    symbol: 'FSDIX',
  },
  {
    value: 'RYPDX',
    label: 'RYPDX,  Rydex Consumer Products Fund Class A',
    name: 'Rydex Consumer Products Fund Class A',
    symbol: 'RYPDX',
  },
  {
    value: 'ABLOX',
    label: 'ABLOX,  Alger Balanced Portfolio Class I-2',
    name: 'Alger Balanced Portfolio Class I-2',
    symbol: 'ABLOX',
  },
  {
    value: 'SIIEX',
    label: 'SIIEX,  Touchstone International Equity Fund Class Y',
    name: 'Touchstone International Equity Fund Class Y',
    symbol: 'SIIEX',
  },
  {
    value: 'RYCPX',
    label: 'RYCPX,  Rydex Consumer Products Fund',
    name: 'Rydex Consumer Products Fund',
    symbol: 'RYCPX',
  },
  {
    value: 'SWRLX',
    label: 'SWRLX,  Touchstone International Equity Fund Class A',
    name: 'Touchstone International Equity Fund Class A',
    symbol: 'SWRLX',
  },
  {
    value: 'SLVIX',
    label: 'SLVIX,  Columbia Select Large Cap Value Fund',
    name: 'Columbia Select Large Cap Value Fund',
    symbol: 'SLVIX',
  },
  {
    value: 'EGOAX',
    label: 'EGOAX,  Allspring Large Cap Core Fund - Class A',
    name: 'Allspring Large Cap Core Fund - Class A',
    symbol: 'EGOAX',
  },
  {
    value: 'EGOHX',
    label: 'EGOHX,  Allspring Large Cap Core Fund',
    name: 'Allspring Large Cap Core Fund',
    symbol: 'EGOHX',
  },
  {
    value: 'WFLLX',
    label: 'WFLLX,  Allspring Large Cap Core Fund',
    name: 'Allspring Large Cap Core Fund',
    symbol: 'WFLLX',
  },
  {
    value: 'BIECX',
    label: 'BIECX,  Brandes International Equity Fund',
    name: 'Brandes International Equity Fund',
    symbol: 'BIECX',
  },
  {
    value: 'WPLCX',
    label: 'WPLCX,  WP Large Cap Income Plus Fund Institutional Class',
    name: 'WP Large Cap Income Plus Fund Institutional Class',
    symbol: 'WPLCX',
  },
  {
    value: 'HCYAX',
    label: 'HCYAX,  Hilton Tactical Income Fund',
    name: 'Hilton Tactical Income Fund',
    symbol: 'HCYAX',
  },
  {
    value: 'HCYIX',
    label: 'HCYIX,  Hilton Tactical Income Fund Institutional Class',
    name: 'Hilton Tactical Income Fund Institutional Class',
    symbol: 'HCYIX',
  },
  {
    value: 'JOBBX',
    label: 'JOBBX,  JPMorgan SmartRetirement Blend 2040 Fund Class R5',
    name: 'JPMorgan SmartRetirement Blend 2040 Fund Class R5',
    symbol: 'JOBBX',
  },
  {
    value: 'OMOIX',
    label: 'OMOIX,  Vivaldi Multi-Strategy Fund Class I',
    name: 'Vivaldi Multi-Strategy Fund Class I',
    symbol: 'OMOIX',
  },
  {
    value: 'LOGBX',
    label: 'LOGBX,  Scharf Multi-Asset Opportunity Fund',
    name: 'Scharf Multi-Asset Opportunity Fund',
    symbol: 'LOGBX',
  },
  {
    value: 'FDIGX',
    label: 'FDIGX,  Fidelity Select Consumer Staples Portfolio',
    name: 'Fidelity Select Consumer Staples Portfolio',
    symbol: 'FDIGX',
  },
  {
    value: 'FDFAX',
    label: 'FDFAX,  Fidelity Select Consumer Staples Portfolio',
    name: 'Fidelity Select Consumer Staples Portfolio',
    symbol: 'FDFAX',
  },
  {
    value: 'FPACX',
    label: 'FPACX,  FPA Crescent Fund',
    name: 'FPA Crescent Fund',
    symbol: 'FPACX',
  },
  {
    value: 'ITTAX',
    label: 'ITTAX,  Hartford Multi-Asset Income Fund Class A',
    name: 'Hartford Multi-Asset Income Fund Class A',
    symbol: 'ITTAX',
  },
  {
    value: 'IHAYX',
    label: 'IHAYX,  Hartford Multi-Asset Income Fund Class Y',
    name: 'Hartford Multi-Asset Income Fund Class Y',
    symbol: 'IHAYX',
  },
  {
    value: 'FDCGX',
    label: 'FDCGX,  Fidelity Select Consumer Staples Portfolio',
    name: 'Fidelity Select Consumer Staples Portfolio',
    symbol: 'FDCGX',
  },
  {
    value: 'FGFRX',
    label: 'FGFRX,  Federated Hermes International Leaders Fund Class R',
    name: 'Federated Hermes International Leaders Fund Class R',
    symbol: 'FGFRX',
  },
  {
    value: 'FGRSX',
    label: 'FGRSX,  Federated Hermes International Leaders Fund',
    name: 'Federated Hermes International Leaders Fund',
    symbol: 'FGRSX',
  },
  {
    value: 'FGFAX',
    label: 'FGFAX,  Federated Hermes International Leaders Fund Class A Shares',
    name: 'Federated Hermes International Leaders Fund Class A Shares',
    symbol: 'FGFAX',
  },
  {
    value: 'FSPSX',
    label:
      'FSPSX,  Fidelity Concord Street Trust - Fidelity International Index Fund',
    name: 'Fidelity Concord Street Trust - Fidelity International Index Fund',
    symbol: 'FSPSX',
  },
  {
    value: 'SSIZX',
    label: 'SSIZX,  Sierra Tactical Core Income Fund Class A',
    name: 'Sierra Tactical Core Income Fund Class A',
    symbol: 'SSIZX',
  },
  {
    value: 'CYYYX',
    label: 'CYYYX,  Columbia Thermostat Fund Institutional 3 Class',
    name: 'Columbia Thermostat Fund Institutional 3 Class',
    symbol: 'CYYYX',
  },
  {
    value: 'CTORX',
    label: 'CTORX,  Columbia Thermostat Fund',
    name: 'Columbia Thermostat Fund',
    symbol: 'CTORX',
  },
  {
    value: 'RSMRX',
    label: 'RSMRX,  RBC SMID Cap Growth Fund',
    name: 'RBC SMID Cap Growth Fund',
    symbol: 'RSMRX',
  },
  {
    value: 'FCSDX',
    label: 'FCSDX,  Fidelity Advisor Strategic Dividend & Income Fund Class C',
    name: 'Fidelity Advisor Strategic Dividend & Income Fund Class C',
    symbol: 'FCSDX',
  },
  {
    value: 'JRBYX',
    label: 'JRBYX,  JPMorgan SmartRetirement Blend 2030 Fund',
    name: 'JPMorgan SmartRetirement Blend 2030 Fund',
    symbol: 'JRBYX',
  },
  {
    value: 'JPBRX',
    label: 'JPBRX,  JPMorgan SmartRetirement Blend 2035 Fund Class R5',
    name: 'JPMorgan SmartRetirement Blend 2035 Fund Class R5',
    symbol: 'JPBRX',
  },
  {
    value: 'JPYRX',
    label: 'JPYRX,  JPMorgan SmartRetirement Blend 2035 Fund Class R6',
    name: 'JPMorgan SmartRetirement Blend 2035 Fund Class R6',
    symbol: 'JPYRX',
  },
  {
    value: 'LKBAX',
    label: 'LKBAX,  LKCM Balanced Fund',
    name: 'LKCM Balanced Fund',
    symbol: 'LKBAX',
  },
  {
    value: 'PALRX',
    label: 'PALRX,  PGIM Balanced Fund- Class R',
    name: 'PGIM Balanced Fund- Class R',
    symbol: 'PALRX',
  },
  {
    value: 'JDVAX',
    label: 'JDVAX,  JPMorgan Diversified Fund Class A',
    name: 'JPMorgan Diversified Fund Class A',
    symbol: 'JDVAX',
  },
  {
    value: 'CDSIX',
    label: 'CDSIX,  Calvert Short Duration Income Fund',
    name: 'Calvert Short Duration Income Fund',
    symbol: 'CDSIX',
  },
  {
    value: 'JPDVX',
    label: 'JPDVX,  JPMorgan Diversified Fund',
    name: 'JPMorgan Diversified Fund',
    symbol: 'JPDVX',
  },
  {
    value: 'JDVSX',
    label: 'JDVSX,  JPMorgan Diversified Fund',
    name: 'JPMorgan Diversified Fund',
    symbol: 'JDVSX',
  },
  {
    value: 'GILHX',
    label: 'GILHX,  Guggenheim Limited Duration Fund',
    name: 'Guggenheim Limited Duration Fund',
    symbol: 'GILHX',
  },
  {
    value: 'VWENX',
    label: 'VWENX,  Vanguard Wellington Fund Admiral Shares',
    name: 'Vanguard Wellington Fund Admiral Shares',
    symbol: 'VWENX',
  },
  {
    value: 'PTIMX',
    label: 'PTIMX,  Performance Trust Municipal Bond Fund',
    name: 'Performance Trust Municipal Bond Fund',
    symbol: 'PTIMX',
  },
  {
    value: 'PTRMX',
    label: 'PTRMX,  Performance Trust Municipal Bond Fund - A Class',
    name: 'Performance Trust Municipal Bond Fund - A Class',
    symbol: 'PTRMX',
  },
  {
    value: 'FJSCX',
    label: 'FJSCX,  Fidelity Japan Smaller Companies Fund',
    name: 'Fidelity Japan Smaller Companies Fund',
    symbol: 'FJSCX',
  },
  {
    value: 'OMOAX',
    label: 'OMOAX,  Vivaldi Multi-Strategy Fund',
    name: 'Vivaldi Multi-Strategy Fund',
    symbol: 'OMOAX',
  },
  {
    value: 'VWELX',
    label: 'VWELX,  Vanguard Wellington Fund',
    name: 'Vanguard Wellington Fund',
    symbol: 'VWELX',
  },
  {
    value: 'PARJX',
    label: 'PARJX,  T. Rowe Price Retirement 2025 Fund Advisor Class',
    name: 'T. Rowe Price Retirement 2025 Fund Advisor Class',
    symbol: 'PARJX',
  },
  {
    value: 'TRRHX',
    label: 'TRRHX,  T. Rowe Price Retirement 2025 Fund',
    name: 'T. Rowe Price Retirement 2025 Fund',
    symbol: 'TRRHX',
  },
  {
    value: 'DHLSX',
    label: 'DHLSX,  Diamond Hill Long Short Fund Class I',
    name: 'Diamond Hill Long Short Fund Class I',
    symbol: 'DHLSX',
  },
  {
    value: 'DIAYX',
    label: 'DIAYX,  Diamond Hill Long-Short Fund',
    name: 'Diamond Hill Long-Short Fund',
    symbol: 'DIAYX',
  },
  {
    value: 'FFTMX',
    label: 'FFTMX,  Fidelity Asset Manager 50% Fund',
    name: 'Fidelity Asset Manager 50% Fund',
    symbol: 'FFTMX',
  },
  {
    value: 'PPIPX',
    label: 'PPIPX,  T. Rowe Price Spectrum Conservative Allocation Fund',
    name: 'T. Rowe Price Spectrum Conservative Allocation Fund',
    symbol: 'PPIPX',
  },
  {
    value: 'BSPIX',
    label: 'BSPIX,  iShares S&P 500 Index Fund Institutional Shares',
    name: 'iShares S&P 500 Index Fund Institutional Shares',
    symbol: 'BSPIX',
  },
  {
    value: 'MRAGX',
    label: 'MRAGX,  Meridian Growth Fund',
    name: 'Meridian Growth Fund',
    symbol: 'MRAGX',
  },
  {
    value: 'BSPAX',
    label: 'BSPAX,  iShares S&P 500 Index Fund Investor A Shares',
    name: 'iShares S&P 500 Index Fund Investor A Shares',
    symbol: 'BSPAX',
  },
  {
    value: 'MSGCX',
    label: 'MSGCX,  Meridian Small Cap Growth Fund',
    name: 'Meridian Small Cap Growth Fund',
    symbol: 'MSGCX',
  },
  {
    value: 'MIJFX',
    label: 'MIJFX,  Matthews Japan Fund',
    name: 'Matthews Japan Fund',
    symbol: 'MIJFX',
  },
  {
    value: 'LISIX',
    label:
      'LISIX,  Lazard International Strategic Equity Portfolio Institutional Shares',
    name: 'Lazard International Strategic Equity Portfolio Institutional Shares',
    symbol: 'LISIX',
  },
  {
    value: 'SMMOX',
    label: 'SMMOX,  Western Asset Managed Municipals Fund',
    name: 'Western Asset Managed Municipals Fund',
    symbol: 'SMMOX',
  },
  {
    value: 'SHMMX',
    label: 'SHMMX,  Western Asset Managed Municipals Fund',
    name: 'Western Asset Managed Municipals Fund',
    symbol: 'SHMMX',
  },
  {
    value: 'SMMCX',
    label: 'SMMCX,  Western Asset Managed Municipals Fund Class C',
    name: 'Western Asset Managed Municipals Fund Class C',
    symbol: 'SMMCX',
  },
  {
    value: 'FLCSX',
    label: 'FLCSX,  Fidelity Large Cap Stock Fund',
    name: 'Fidelity Large Cap Stock Fund',
    symbol: 'FLCSX',
  },
  {
    value: 'FLCCX',
    label: 'FLCCX,  Fidelity Advisor Large Cap Fund Class C',
    name: 'Fidelity Advisor Large Cap Fund Class C',
    symbol: 'FLCCX',
  },
  {
    value: 'FALAX',
    label: 'FALAX,  Fidelity Advisor Large Cap Fund Class A',
    name: 'Fidelity Advisor Large Cap Fund Class A',
    symbol: 'FALAX',
  },
  {
    value: 'PRWCX',
    label: 'PRWCX,  T. Rowe Price Capital Appreciation Fund',
    name: 'T. Rowe Price Capital Appreciation Fund',
    symbol: 'PRWCX',
  },
  {
    value: 'TRAIX',
    label: 'TRAIX,  T. Rowe Price Capital Appreciation Fund',
    name: 'T. Rowe Price Capital Appreciation Fund',
    symbol: 'TRAIX',
  },
  {
    value: 'MJFOX',
    label: 'MJFOX,  Matthews International Funds - Matthews Japan Fund',
    name: 'Matthews International Funds - Matthews Japan Fund',
    symbol: 'MJFOX',
  },
  {
    value: 'NHCCX',
    label: 'NHCCX,  Nuveen High Yield Municipal Bond Fund',
    name: 'Nuveen High Yield Municipal Bond Fund',
    symbol: 'NHCCX',
  },
  {
    value: 'NHMCX',
    label: 'NHMCX,  Nuveen High Yield Municipal Bond Fund Class C2',
    name: 'Nuveen High Yield Municipal Bond Fund Class C2',
    symbol: 'NHMCX',
  },
  {
    value: 'NHMAX',
    label: 'NHMAX,  Nuveen High Yield Municipal Bond Fund',
    name: 'Nuveen High Yield Municipal Bond Fund',
    symbol: 'NHMAX',
  },
  {
    value: 'NHMRX',
    label: 'NHMRX,  Nuveen High Yield Municipal Bond Fund Class I',
    name: 'Nuveen High Yield Municipal Bond Fund Class I',
    symbol: 'NHMRX',
  },
  {
    value: 'NHMFX',
    label: 'NHMFX,  Nuveen High Yield Municipal Bond Fund Class R6',
    name: 'Nuveen High Yield Municipal Bond Fund Class R6',
    symbol: 'NHMFX',
  },
  {
    value: 'FIDLX',
    label: 'FIDLX,  Fidelity Advisor Large Cap Fund',
    name: 'Fidelity Advisor Large Cap Fund',
    symbol: 'FIDLX',
  },
  {
    value: 'FALIX',
    label: 'FALIX,  Fidelity Advisor Large Cap Fund Class I',
    name: 'Fidelity Advisor Large Cap Fund Class I',
    symbol: 'FALIX',
  },
  {
    value: 'FETKX',
    label: 'FETKX,  Fidelity Equity Dividend Income Fund Class K',
    name: 'Fidelity Equity Dividend Income Fund Class K',
    symbol: 'FETKX',
  },
  {
    value: 'FVDKX',
    label: 'FVDKX,  Fidelity Value Discovery Fund',
    name: 'Fidelity Value Discovery Fund',
    symbol: 'FVDKX',
  },
  {
    value: 'PACLX',
    label: 'PACLX,  T. Rowe Price Capital Appreciation Fund',
    name: 'T. Rowe Price Capital Appreciation Fund',
    symbol: 'PACLX',
  },
  {
    value: 'MSGAX',
    label: 'MSGAX,  Meridian Small Cap Growth Fund',
    name: 'Meridian Small Cap Growth Fund',
    symbol: 'MSGAX',
  },
  {
    value: 'MSGGX',
    label: 'MSGGX,  Meridian Small Cap Growth Fund',
    name: 'Meridian Small Cap Growth Fund',
    symbol: 'MSGGX',
  },
  {
    value: 'MSGRX',
    label: 'MSGRX,  Meridian Small Cap Growth Fund',
    name: 'Meridian Small Cap Growth Fund',
    symbol: 'MSGRX',
  },
  {
    value: 'NRIAX',
    label: 'NRIAX,  Nuveen Real Asset Income Fund',
    name: 'Nuveen Real Asset Income Fund',
    symbol: 'NRIAX',
  },
  {
    value: 'NRIIX',
    label: 'NRIIX,  Nuveen Real Asset Income Fund Class I',
    name: 'Nuveen Real Asset Income Fund Class I',
    symbol: 'NRIIX',
  },
  {
    value: 'NRICX',
    label: 'NRICX,  Nuveen Real Asset Income Fund',
    name: 'Nuveen Real Asset Income Fund',
    symbol: 'NRICX',
  },
  {
    value: 'NRIFX',
    label: 'NRIFX,  Nuveen Real Asset Income Fund Class R6',
    name: 'Nuveen Real Asset Income Fund Class R6',
    symbol: 'NRIFX',
  },
  {
    value: 'JHQCX',
    label: 'JHQCX,  JPMorgan Hedged Equity Fund',
    name: 'JPMorgan Hedged Equity Fund',
    symbol: 'JHQCX',
  },
  {
    value: 'LEVIX',
    label: 'LEVIX,  Lazard US Equity Concentrated Portfolio',
    name: 'Lazard US Equity Concentrated Portfolio',
    symbol: 'LEVIX',
  },
  {
    value: 'LEVOX',
    label: 'LEVOX,  Lazard US Equity Concentrated Portfolio Open Shares',
    name: 'Lazard US Equity Concentrated Portfolio Open Shares',
    symbol: 'LEVOX',
  },
  {
    value: 'JHQPX',
    label: 'JHQPX,  JPMorgan Hedged Equity Fund',
    name: 'JPMorgan Hedged Equity Fund',
    symbol: 'JHQPX',
  },
  {
    value: 'JHQRX',
    label: 'JHQRX,  JPMorgan Hedged Equity Fund Class R6',
    name: 'JPMorgan Hedged Equity Fund Class R6',
    symbol: 'JHQRX',
  },
  {
    value: 'BIEAX',
    label: 'BIEAX,  Brandes International Equity Fund',
    name: 'Brandes International Equity Fund',
    symbol: 'BIEAX',
  },
  {
    value: 'BIIEX',
    label: 'BIIEX,  Brandes International Equity Fund Class I',
    name: 'Brandes International Equity Fund Class I',
    symbol: 'BIIEX',
  },
  {
    value: 'USBLX',
    label: 'USBLX,  USAA Growth and Tax Strategy Fund',
    name: 'USAA Growth and Tax Strategy Fund',
    symbol: 'USBLX',
  },
  {
    value: 'MXXVX',
    label: 'MXXVX,  Matthew 25 Fund',
    name: 'Matthew 25 Fund',
    symbol: 'MXXVX',
  },
  {
    value: 'BVEFX',
    label: 'BVEFX,  Becker Value Equity Fund Retail Class',
    name: 'Becker Value Equity Fund Retail Class',
    symbol: 'BVEFX',
  },
  {
    value: 'BVEIX',
    label: 'BVEIX,  Becker Value Equity Fund',
    name: 'Becker Value Equity Fund',
    symbol: 'BVEIX',
  },
  {
    value: 'FAMFX',
    label: 'FAMFX,  FAM Small Cap Fund Investor Class',
    name: 'FAM Small Cap Fund Investor Class',
    symbol: 'FAMFX',
  },
  {
    value: 'RYCIX',
    label: 'RYCIX,  Rydex Consumer Products Fund',
    name: 'Rydex Consumer Products Fund',
    symbol: 'RYCIX',
  },
  {
    value: 'VTMFX',
    label: 'VTMFX,  Vanguard Tax-Managed Balanced Fund Admiral Shares',
    name: 'Vanguard Tax-Managed Balanced Fund Admiral Shares',
    symbol: 'VTMFX',
  },
  {
    value: 'JOBEX',
    label: 'JOBEX,  JPMorgan SmartRetirement Blend 2040 Fund Class I',
    name: 'JPMorgan SmartRetirement Blend 2040 Fund Class I',
    symbol: 'JOBEX',
  },
  {
    value: 'VGENX',
    label: 'VGENX,  Vanguard Energy Fund',
    name: 'Vanguard Energy Fund',
    symbol: 'VGENX',
  },
  {
    value: 'VGELX',
    label: 'VGELX,  Vanguard Energy Fund Admiral Shares',
    name: 'Vanguard Energy Fund Admiral Shares',
    symbol: 'VGELX',
  },
  {
    value: 'FANIX',
    label: 'FANIX,  Fidelity Advisor Energy Fund',
    name: 'Fidelity Advisor Energy Fund',
    symbol: 'FANIX',
  },
  {
    value: 'FAGNX',
    label: 'FAGNX,  Fidelity Advisor Energy Fund Class M',
    name: 'Fidelity Advisor Energy Fund Class M',
    symbol: 'FAGNX',
  },
  {
    value: 'FSENX',
    label: 'FSENX,  Fidelity Select Portfolios - Energy Portfolio',
    name: 'Fidelity Select Portfolios - Energy Portfolio',
    symbol: 'FSENX',
  },
  {
    value: 'RYOIX',
    label: 'RYOIX,  Rydex Biotechnology Fund',
    name: 'Rydex Biotechnology Fund',
    symbol: 'RYOIX',
  },
  {
    value: 'PRHSX',
    label: 'PRHSX,  T. Rowe Price Health Sciences Fund',
    name: 'T. Rowe Price Health Sciences Fund',
    symbol: 'PRHSX',
  },
  {
    value: 'THISX',
    label: 'THISX,  T. Rowe Price Health Sciences Fund I Class',
    name: 'T. Rowe Price Health Sciences Fund I Class',
    symbol: 'THISX',
  },
  {
    value: 'JFNSX',
    label: 'JFNSX,  Janus Henderson Global Life Sciences Fund',
    name: 'Janus Henderson Global Life Sciences Fund',
    symbol: 'JFNSX',
  },
  {
    value: 'JAGLX',
    label: 'JAGLX,  Janus Henderson Global Life Sciences Fund',
    name: 'Janus Henderson Global Life Sciences Fund',
    symbol: 'JAGLX',
  },
  {
    value: 'JNGLX',
    label: 'JNGLX,  Janus Henderson Global Life Sciences Fund',
    name: 'Janus Henderson Global Life Sciences Fund',
    symbol: 'JNGLX',
  },
  {
    value: 'JFNIX',
    label: 'JFNIX,  Janus Henderson Global Life Sciences Fund',
    name: 'Janus Henderson Global Life Sciences Fund',
    symbol: 'JFNIX',
  },
  {
    value: 'JFNAX',
    label: 'JFNAX,  Janus Henderson Global Life Sciences Fund Class A',
    name: 'Janus Henderson Global Life Sciences Fund Class A',
    symbol: 'JFNAX',
  },
  {
    value: 'JFNCX',
    label: 'JFNCX,  Janus Henderson Global Life Sciences Fund Class C',
    name: 'Janus Henderson Global Life Sciences Fund Class C',
    symbol: 'JFNCX',
  },
  {
    value: 'FBTTX',
    label: 'FBTTX,  Fidelity Advisor Biotechnology Fund',
    name: 'Fidelity Advisor Biotechnology Fund',
    symbol: 'FBTTX',
  },
  {
    value: 'FBTAX',
    label: 'FBTAX,  Fidelity Advisor Biotechnology Fund Class A',
    name: 'Fidelity Advisor Biotechnology Fund Class A',
    symbol: 'FBTAX',
  },
  {
    value: 'DDDAX',
    label: 'DDDAX,  13D Activist Fund',
    name: '13D Activist Fund',
    symbol: 'DDDAX',
  },
  {
    value: 'DDDIX',
    label: 'DDDIX,  13D Activist Fund Class I',
    name: '13D Activist Fund Class I',
    symbol: 'DDDIX',
  },
  {
    value: 'DDDCX',
    label: 'DDDCX,  13D Activist Fund',
    name: '13D Activist Fund',
    symbol: 'DDDCX',
  },
  {
    value: 'ADKSX',
    label: 'ADKSX,  Adirondack Small Cap Fund',
    name: 'Adirondack Small Cap Fund',
    symbol: 'ADKSX',
  },
  {
    value: 'GMDZX',
    label: 'GMDZX,  GuideStone Funds - Medium-Duration Bond Fund',
    name: 'GuideStone Funds - Medium-Duration Bond Fund',
    symbol: 'GMDZX',
  },
  {
    value: 'MRIGX',
    label: 'MRIGX,  Meridian Growth Fund',
    name: 'Meridian Growth Fund',
    symbol: 'MRIGX',
  },
  {
    value: 'REVS',
    label: 'REVS,  Columbia Research Enhanced Value ETF',
    name: 'Columbia Research Enhanced Value ETF',
    symbol: 'REVS',
  },
  {
    value: 'LOUP',
    label: 'LOUP,  Innovator Loup Frontier Tech ETF',
    name: 'Innovator Loup Frontier Tech ETF',
    symbol: 'LOUP',
  },
  {
    value: 'UCON',
    label: 'UCON,  First Trust TCW Unconstrained Plus Bond ETF',
    name: 'First Trust TCW Unconstrained Plus Bond ETF',
    symbol: 'UCON',
  },
  {
    value: 'VRT',
    label: 'VRT,  Vertiv Holdings Co',
    name: 'Vertiv Holdings Co',
    symbol: 'VRT',
  },
  {
    value: 'KOMP',
    label: 'KOMP,  SPDR S&P Kensho New Economies Composite ETF',
    name: 'SPDR S&P Kensho New Economies Composite ETF',
    symbol: 'KOMP',
  },
  {
    value: 'TFII',
    label: 'TFII,  TFI International Inc.',
    name: 'TFI International Inc.',
    symbol: 'TFII',
  },
  {
    value: 'AAAU',
    label: 'AAAU,  Goldman Sachs Physical Gold ETF',
    name: 'Goldman Sachs Physical Gold ETF',
    symbol: 'AAAU',
  },
  {
    value: 'ARKF',
    label: 'ARKF,  ARK Fintech Innovation ETF',
    name: 'ARK Fintech Innovation ETF',
    symbol: 'ARKF',
  },
  {
    value: 'ABEQ',
    label: 'ABEQ,  Absolute Select Value ETF',
    name: 'Absolute Select Value ETF',
    symbol: 'ABEQ',
  },
  {
    value: 'AMOM',
    label: 'AMOM,  QRAFT AI-Enhanced U.S. Large Cap Momentum ETF',
    name: 'QRAFT AI-Enhanced U.S. Large Cap Momentum ETF',
    symbol: 'AMOM',
  },
  {
    value: 'ACEL',
    label: 'ACEL,  Accel Entertainment, Inc.',
    name: 'Accel Entertainment, Inc.',
    symbol: 'ACEL',
  },
  {
    value: 'AFSM',
    label: 'AFSM,  First Trust Active Factor Small Cap ETF',
    name: 'First Trust Active Factor Small Cap ETF',
    symbol: 'AFSM',
  },
  {
    value: 'AFMC',
    label: 'AFMC,  First Trust Active Factor Mid Cap ETF',
    name: 'First Trust Active Factor Mid Cap ETF',
    symbol: 'AFMC',
  },
  {
    value: 'AIO',
    label:
      'AIO,  Virtus Artificial Intelligence & Technology Opportunities Fund',
    name: 'Virtus Artificial Intelligence & Technology Opportunities Fund',
    symbol: 'AIO',
  },
  {
    value: 'AFLG',
    label: 'AFLG,  First Trust Active Factor Large Cap ETF',
    name: 'First Trust Active Factor Large Cap ETF',
    symbol: 'AFLG',
  },
  {
    value: 'AUSF',
    label: 'AUSF,  Global X Adaptive U.S. Factor ETF',
    name: 'Global X Adaptive U.S. Factor ETF',
    symbol: 'AUSF',
  },
  {
    value: 'BILL',
    label: 'BILL,  Bill.com Holdings, Inc.',
    name: 'Bill.com Holdings, Inc.',
    symbol: 'BILL',
  },
  {
    value: 'AVDV',
    label: 'AVDV,  Avantis International Small Cap Value ETF',
    name: 'Avantis International Small Cap Value ETF',
    symbol: 'AVDV',
  },
  {
    value: 'RFL',
    label: 'RFL,  Rafael Holdings, Inc.',
    name: 'Rafael Holdings, Inc.',
    symbol: 'RFL',
  },
  {
    value: 'BKR',
    label: 'BKR,  Baker Hughes Company',
    name: 'Baker Hughes Company',
    symbol: 'BKR',
  },
  {
    value: 'BOUT',
    label: 'BOUT,  Innovator IBD Breakout Opportunities ETF',
    name: 'Innovator IBD Breakout Opportunities ETF',
    symbol: 'BOUT',
  },
  {
    value: 'BRBR',
    label: 'BRBR,  BellRing Brands, Inc.',
    name: 'BellRing Brands, Inc.',
    symbol: 'BRBR',
  },
  {
    value: 'BMEZ',
    label: 'BMEZ,  BlackRock Health Sciences Trust II',
    name: 'BlackRock Health Sciences Trust II',
    symbol: 'BMEZ',
  },
  {
    value: 'BRK-A',
    label: 'BRK-A,  Berkshire Hathaway Inc.',
    name: 'Berkshire Hathaway Inc.',
    symbol: 'BRK-A',
  },
  {
    value: 'GLDM',
    label: 'GLDM,  SPDR Gold MiniShares',
    name: 'SPDR Gold MiniShares',
    symbol: 'GLDM',
  },
  {
    value: 'IDNA',
    label: 'IDNA,  iShares Genomics Immunology and Healthcare ETF',
    name: 'iShares Genomics Immunology and Healthcare ETF',
    symbol: 'IDNA',
  },
  {
    value: 'J',
    label: 'J,  Jacobs Solutions Inc.',
    name: 'Jacobs Solutions Inc.',
    symbol: 'J',
  },
  {
    value: 'CHIU',
    label: 'CHIU,  Global X MSCI China Utilities ETF',
    name: 'Global X MSCI China Utilities ETF',
    symbol: 'CHIU',
  },
  {
    value: 'EMNT',
    label:
      'EMNT,  PIMCO Enhanced Short Maturity Active ESG Exchange-Traded Fund',
    name: 'PIMCO Enhanced Short Maturity Active ESG Exchange-Traded Fund',
    symbol: 'EMNT',
  },
  {
    value: 'CHIS',
    label: 'CHIS,  Global X MSCI China Consumer Staples ETF',
    name: 'Global X MSCI China Consumer Staples ETF',
    symbol: 'CHIS',
  },
  {
    value: 'CHIR',
    label: 'CHIR,  Global X MSCI China Real Estate ETF',
    name: 'Global X MSCI China Real Estate ETF',
    symbol: 'CHIR',
  },
  {
    value: 'INDS',
    label: 'INDS,  Pacer Benchmark Industrial Real Estate SCTR ETF',
    name: 'Pacer Benchmark Industrial Real Estate SCTR ETF',
    symbol: 'INDS',
  },
  {
    value: 'CHIK',
    label: 'CHIK,  Global X MSCI China Information Technology ETF',
    name: 'Global X MSCI China Information Technology ETF',
    symbol: 'CHIK',
  },
  {
    value: 'CHIH',
    label: 'CHIH,  Global X MSCI China Health Care ETF',
    name: 'Global X MSCI China Health Care ETF',
    symbol: 'CHIH',
  },
  {
    value: 'IQIN',
    label: 'IQIN,  IQ 500 International ETF',
    name: 'IQ 500 International ETF',
    symbol: 'IQIN',
  },
  {
    value: 'JAGG',
    label: 'JAGG,  JPMorgan U.S. Aggregate Bond ETF',
    name: 'JPMorgan U.S. Aggregate Bond ETF',
    symbol: 'JAGG',
  },
  {
    value: 'GBUG',
    label: 'GBUG,  iPath Gold ETN',
    name: 'iPath Gold ETN',
    symbol: 'GBUG',
  },
  {
    value: 'FLLA',
    label: 'FLLA,  Franklin FTSE Latin America ETF',
    name: 'Franklin FTSE Latin America ETF',
    symbol: 'FLLA',
  },
  {
    value: 'IDRV',
    label: 'IDRV,  iShares Self-Driving EV and Tech ETF',
    name: 'iShares Self-Driving EV and Tech ETF',
    symbol: 'IDRV',
  },
  {
    value: 'EWCO',
    label: 'EWCO,  Invesco S&P 500 Equal Weight Communication Services ETF',
    name: 'Invesco S&P 500 Equal Weight Communication Services ETF',
    symbol: 'EWCO',
  },
  {
    value: 'EMSG',
    label: 'EMSG,  Xtrackers MSCI Emerging Markets ESG Leaders Equity ETF',
    name: 'Xtrackers MSCI Emerging Markets ESG Leaders Equity ETF',
    symbol: 'EMSG',
  },
  {
    value: 'GLIF',
    label: 'GLIF,  AGFiQ Global Infrastructure ETF',
    name: 'AGFiQ Global Infrastructure ETF',
    symbol: 'GLIF',
  },
  {
    value: 'EIC',
    label: 'EIC,  Eagle Point Income Company Inc.',
    name: 'Eagle Point Income Company Inc.',
    symbol: 'EIC',
  },
  {
    value: 'FOVL',
    label: 'FOVL,  iShares Focused Value Factor ETF',
    name: 'iShares Focused Value Factor ETF',
    symbol: 'FOVL',
  },
  {
    value: 'IG',
    label: 'IG,  Principal Investment Grade Corporate Active ETF',
    name: 'Principal Investment Grade Corporate Active ETF',
    symbol: 'IG',
  },
  {
    value: 'HIBL',
    label: 'HIBL,  Direxion Daily S&P 500 High Beta Bull 3X Shares',
    name: 'Direxion Daily S&P 500 High Beta Bull 3X Shares',
    symbol: 'HIBL',
  },
  {
    value: 'IJAN',
    label:
      'IJAN,  Innovator International Developed Power Buffer ETF - January',
    name: 'Innovator International Developed Power Buffer ETF - January',
    symbol: 'IJAN',
  },
  {
    value: 'HIBS',
    label: 'HIBS,  Direxion Daily S&P 500 High Beta Bear 3X Shares',
    name: 'Direxion Daily S&P 500 High Beta Bear 3X Shares',
    symbol: 'HIBS',
  },
  {
    value: 'IQSI',
    label: 'IQSI,  IQ Candriam ESG International Equity ETF',
    name: 'IQ Candriam ESG International Equity ETF',
    symbol: 'IQSI',
  },
  {
    value: 'EINC',
    label: 'EINC,  VanEck Energy Income ETF',
    name: 'VanEck Energy Income ETF',
    symbol: 'EINC',
  },
  {
    value: 'HAUZ',
    label: 'HAUZ,  Xtrackers International Real Estate ETF',
    name: 'Xtrackers International Real Estate ETF',
    symbol: 'HAUZ',
  },
  {
    value: 'FLSP',
    label: 'FLSP,  Franklin Systematic Style Premia ETF',
    name: 'Franklin Systematic Style Premia ETF',
    symbol: 'FLSP',
  },
  {
    value: 'FLSA',
    label: 'FLSA,  Franklin FTSE Saudi Arabia ETF',
    name: 'Franklin FTSE Saudi Arabia ETF',
    symbol: 'FLSA',
  },
  {
    value: 'IQSU',
    label: 'IQSU,  IQ Candriam ESG U.S. Large Cap Equity ETF',
    name: 'IQ Candriam ESG U.S. Large Cap Equity ETF',
    symbol: 'IQSU',
  },
  {
    value: 'IHAK',
    label: 'IHAK,  iShares Cybersecurity and Tech ETF',
    name: 'iShares Cybersecurity and Tech ETF',
    symbol: 'IHAK',
  },
  {
    value: 'DBEH',
    label: 'DBEH,  iMGP DBi Hedge Strategy ETF',
    name: 'iMGP DBi Hedge Strategy ETF',
    symbol: 'DBEH',
  },
  {
    value: 'EJUL',
    label: 'EJUL,  Innovator MSCI Emerging Markets Power Buffer ETF - July',
    name: 'Innovator MSCI Emerging Markets Power Buffer ETF - July',
    symbol: 'EJUL',
  },
  {
    value: 'IIGD',
    label: 'IIGD,  Invesco Investment Grade Defensive ETF',
    name: 'Invesco Investment Grade Defensive ETF',
    symbol: 'IIGD',
  },
  {
    value: 'HYGV',
    label: 'HYGV,  FlexShares High Yield Value-Scored Bond Index Fund',
    name: 'FlexShares High Yield Value-Scored Bond Index Fund',
    symbol: 'HYGV',
  },
  {
    value: 'FINV',
    label: 'FINV,  FinVolution Group',
    name: 'FinVolution Group',
    symbol: 'FINV',
  },
  {
    value: 'BRMK',
    label: 'BRMK,  Broadmark Realty Capital Inc.',
    name: 'Broadmark Realty Capital Inc.',
    symbol: 'BRMK',
  },
  {
    value: 'HTAB',
    label: 'HTAB,  Hartford Schroders Tax-Aware Bond ETF',
    name: 'Hartford Schroders Tax-Aware Bond ETF',
    symbol: 'HTAB',
  },
  {
    value: 'JHCS',
    label: 'JHCS,  John Hancock Multifactor Media and Communications ETF',
    name: 'John Hancock Multifactor Media and Communications ETF',
    symbol: 'JHCS',
  },
  {
    value: 'EPAC',
    label: 'EPAC,  Enerpac Tool Group Corp.',
    name: 'Enerpac Tool Group Corp.',
    symbol: 'EPAC',
  },
  {
    value: 'IIGV',
    label: 'IIGV,  Invesco Investment Grade Value ETF',
    name: 'Invesco Investment Grade Value ETF',
    symbol: 'IIGV',
  },
  {
    value: 'FISR',
    label: 'FISR,  SPDR SSGA Fixed Income Sector Rotation ETF',
    name: 'SPDR SSGA Fixed Income Sector Rotation ETF',
    symbol: 'FISR',
  },
  {
    value: 'JHEM',
    label: 'JHEM,  John Hancock Multifactor Emerging Markets ETF',
    name: 'John Hancock Multifactor Emerging Markets ETF',
    symbol: 'JHEM',
  },
  {
    value: 'FLZA',
    label: 'FLZA,  Franklin FTSE South Africa ETF',
    name: 'Franklin FTSE South Africa ETF',
    symbol: 'FLZA',
  },
  {
    value: 'EAGG',
    label: 'EAGG,  iShares ESG U.S. Aggregate Bond ETF',
    name: 'iShares ESG U.S. Aggregate Bond ETF',
    symbol: 'EAGG',
  },
  {
    value: 'CMDY',
    label: 'CMDY,  iShares Bloomberg Roll Select Broad Commodity ETF',
    name: 'iShares Bloomberg Roll Select Broad Commodity ETF',
    symbol: 'CMDY',
  },
  {
    value: 'FITE',
    label: 'FITE,  SPDR S&P Kensho Future Security ETF',
    name: 'SPDR S&P Kensho Future Security ETF',
    symbol: 'FITE',
  },
  {
    value: 'BUL',
    label: 'BUL,  Pacer US Cash Cows Growth ETF',
    name: 'Pacer US Cash Cows Growth ETF',
    symbol: 'BUL',
  },
  {
    value: 'FDHY',
    label: 'FDHY,  Fidelity High Yield Factor ETF',
    name: 'Fidelity High Yield Factor ETF',
    symbol: 'FDHY',
  },
  {
    value: 'JIGB',
    label: 'JIGB,  JPMorgan Corporate Bond Research Enhanced ETF',
    name: 'JPMorgan Corporate Bond Research Enhanced ETF',
    symbol: 'JIGB',
  },
  {
    value: 'ECLN',
    label: 'ECLN,  First Trust EIP Carbon Impact ETF',
    name: 'First Trust EIP Carbon Impact ETF',
    symbol: 'ECLN',
  },
  {
    value: 'FUMB',
    label: 'FUMB,  First Trust Ultra Short Duration Municipal ETF',
    name: 'First Trust Ultra Short Duration Municipal ETF',
    symbol: 'FUMB',
  },
  {
    value: 'DBMF',
    label: 'DBMF,  iMGP DBi Managed Futures Strategy ETF',
    name: 'iMGP DBi Managed Futures Strategy ETF',
    symbol: 'DBMF',
  },
  {
    value: 'HTEC',
    label: 'HTEC,  Robo Global Healthcare Technology and Innovation ETF',
    name: 'Robo Global Healthcare Technology and Innovation ETF',
    symbol: 'HTEC',
  },
  {
    value: 'FIVG',
    label: 'FIVG,  Defiance 5G Next Gen Connectivity ETF',
    name: 'Defiance 5G Next Gen Connectivity ETF',
    symbol: 'FIVG',
  },
  {
    value: 'GAZ',
    label:
      'GAZ,  iPath Series B Bloomberg Natural Gas Subindex Total ReturnSM ETN',
    name: 'iPath Series B Bloomberg Natural Gas Subindex Total ReturnSM ETN',
    symbol: 'GAZ',
  },
  {
    value: 'FSMD',
    label: 'FSMD,  Fidelity Small-Mid Factor ETF',
    name: 'Fidelity Small-Mid Factor ETF',
    symbol: 'FSMD',
  },
  {
    value: 'GDMA',
    label: 'GDMA,  Alpha Architect Gadsden Dynamic Multi-Asset ETF',
    name: 'Alpha Architect Gadsden Dynamic Multi-Asset ETF',
    symbol: 'GDMA',
  },
  {
    value: 'DMDV',
    label: 'DMDV,  AAM S&P Developed Markets High Dividend Value ETF',
    name: 'AAM S&P Developed Markets High Dividend Value ETF',
    symbol: 'DMDV',
  },
  {
    value: 'FSMB',
    label: 'FSMB,  First Trust Short Duration Managed Municipal ETF',
    name: 'First Trust Short Duration Managed Municipal ETF',
    symbol: 'FSMB',
  },
  {
    value: 'IRBO',
    label:
      'IRBO,  iShares Robotics and Artificial Intelligence Multisector ETF',
    name: 'iShares Robotics and Artificial Intelligence Multisector ETF',
    symbol: 'IRBO',
  },
  {
    value: 'IBDT',
    label: 'IBDT,  iShares iBonds Dec 2028 Term Corporate ETF',
    name: 'iShares iBonds Dec 2028 Term Corporate ETF',
    symbol: 'IBDT',
  },
  {
    value: 'IBDU',
    label: 'IBDU,  iShares iBonds Dec 2029 Term Corporate ETF',
    name: 'iShares iBonds Dec 2029 Term Corporate ETF',
    symbol: 'IBDU',
  },
  {
    value: 'IJUL',
    label: 'IJUL,  Innovator MSCI EAFE Power Buffer ETF - July',
    name: 'Innovator MSCI EAFE Power Buffer ETF - July',
    symbol: 'IJUL',
  },
  {
    value: 'DSTL',
    label: 'DSTL,  Distillate U.S. Fundamental Stability & Value ETF',
    name: 'Distillate U.S. Fundamental Stability & Value ETF',
    symbol: 'DSTL',
  },
  {
    value: 'HYTR',
    label: 'HYTR,  CP High Yield Trend ETF',
    name: 'CP High Yield Trend ETF',
    symbol: 'HYTR',
  },
  {
    value: 'DAO',
    label: 'DAO,  Youdao, Inc.',
    name: 'Youdao, Inc.',
    symbol: 'DAO',
  },
  {
    value: 'FLCB',
    label: 'FLCB,  Franklin U.S. Core Bond ETF',
    name: 'Franklin U.S. Core Bond ETF',
    symbol: 'FLCB',
  },
  {
    value: 'FNGO',
    label: 'FNGO,  MicroSectors FANG+ Index 2X Leveraged ETN',
    name: 'MicroSectors FANG+ Index 2X Leveraged ETN',
    symbol: 'FNGO',
  },
  {
    value: 'FNGS',
    label: 'FNGS,  MicroSectors FANG+ ETN',
    name: 'MicroSectors FANG+ ETN',
    symbol: 'FNGS',
  },
  {
    value: 'FNGU',
    label: 'FNGU,  MicroSectors FANG+ Index 3X Leveraged ETN',
    name: 'MicroSectors FANG+ Index 3X Leveraged ETN',
    symbol: 'FNGU',
  },
  {
    value: 'EJAN',
    label: 'EJAN,  Innovator Emerging Markets Power Buffer ETF - January',
    name: 'Innovator Emerging Markets Power Buffer ETF - January',
    symbol: 'EJAN',
  },
  {
    value: 'FHI',
    label: 'FHI,  Federated Hermes, Inc.',
    name: 'Federated Hermes, Inc.',
    symbol: 'FHI',
  },
  {
    value: 'EASG',
    label: 'EASG,  Xtrackers MSCI EAFE ESG Leaders Equity ETF',
    name: 'Xtrackers MSCI EAFE ESG Leaders Equity ETF',
    symbol: 'EASG',
  },
  {
    value: 'IXSE',
    label: 'IXSE,  WisdomTree India ex-State-Owned Enterprises Fund',
    name: 'WisdomTree India ex-State-Owned Enterprises Fund',
    symbol: 'IXSE',
  },
  {
    value: 'IVOL',
    label:
      'IVOL,  Quadratic Interest Rate Volatility and Inflation Hedge ETF New',
    name: 'Quadratic Interest Rate Volatility and Inflation Hedge ETF New',
    symbol: 'IVOL',
  },
  {
    value: 'ERSX',
    label: 'ERSX,  ERShares NextGen Entrepreneurs ETF',
    name: 'ERShares NextGen Entrepreneurs ETF',
    symbol: 'ERSX',
  },
  {
    value: 'HAIL',
    label: 'HAIL,  SPDR S&P Kensho Smart Mobility ETF',
    name: 'SPDR S&P Kensho Smart Mobility ETF',
    symbol: 'HAIL',
  },
  {
    value: 'CNRG',
    label: 'CNRG,  SPDR Kensho Clean Power ETF',
    name: 'SPDR Kensho Clean Power ETF',
    symbol: 'CNRG',
  },
  {
    value: 'SCU',
    label: 'SCU,  Sculptor Capital Management, Inc.',
    name: 'Sculptor Capital Management, Inc.',
    symbol: 'SCU',
  },
  {
    value: 'ROKT',
    label: 'ROKT,  SPDR Kensho Final Frontiers ETF',
    name: 'SPDR Kensho Final Frontiers ETF',
    symbol: 'ROKT',
  },
  {
    value: 'QARP',
    label: 'QARP,  Xtrackers Russell 1000 US Quality at a Reasonable Price ETF',
    name: 'Xtrackers Russell 1000 US Quality at a Reasonable Price ETF',
    symbol: 'QARP',
  },
  {
    value: 'LGOV',
    label: 'LGOV,  First Trust Long Duration Opportunities ETF',
    name: 'First Trust Long Duration Opportunities ETF',
    symbol: 'LGOV',
  },
  {
    value: 'SFYF',
    label: 'SFYF,  SoFi Social 50 ETF',
    name: 'SoFi Social 50 ETF',
    symbol: 'SFYF',
  },
  {
    value: 'NTCO',
    label: 'NTCO,  Natura &Co Holding S.A.',
    name: 'Natura &Co Holding S.A.',
    symbol: 'NTCO',
  },
  {
    value: 'PPTY',
    label: 'PPTY,  Vident U.S. Diversified Real Estate ETF',
    name: 'Vident U.S. Diversified Real Estate ETF',
    symbol: 'PPTY',
  },
  {
    value: 'KEMX',
    label: 'KEMX,  KraneShares MSCI Emerging Markets ex China Index ETF',
    name: 'KraneShares MSCI Emerging Markets ex China Index ETF',
    symbol: 'KEMX',
  },
  {
    value: 'ONTO',
    label: 'ONTO,  Onto Innovation Inc.',
    name: 'Onto Innovation Inc.',
    symbol: 'ONTO',
  },
  {
    value: 'JUST',
    label: 'JUST,  Goldman Sachs JUST U.S. Large Cap Equity ETF',
    name: 'Goldman Sachs JUST U.S. Large Cap Equity ETF',
    symbol: 'JUST',
  },
  {
    value: 'SMHB',
    label:
      'SMHB,  ETRACS 2xMonthly Pay Leveraged US Small Cap High Dividend ETN',
    name: 'ETRACS 2xMonthly Pay Leveraged US Small Cap High Dividend ETN',
    symbol: 'SMHB',
  },
  {
    value: 'MTGP',
    label: 'MTGP,  WisdomTree Mortgage Plus Bond Fund',
    name: 'WisdomTree Mortgage Plus Bond Fund',
    symbol: 'MTGP',
  },
  {
    value: 'JMBS',
    label: 'JMBS,  Janus Henderson Mortgage-Backed Securities ETF',
    name: 'Janus Henderson Mortgage-Backed Securities ETF',
    symbol: 'JMBS',
  },
  {
    value: 'PEAK',
    label: 'PEAK,  Healthpeak Properties, Inc.',
    name: 'Healthpeak Properties, Inc.',
    symbol: 'PEAK',
  },
  {
    value: 'SBUG',
    label: 'SBUG,  iPath Silver ETN',
    name: 'iPath Silver ETN',
    symbol: 'SBUG',
  },
  {
    value: 'PFFA',
    label: 'PFFA,  Virtus InfraCap U.S. Preferred Stock ETF',
    name: 'Virtus InfraCap U.S. Preferred Stock ETF',
    symbol: 'PFFA',
  },
  {
    value: 'PFFL',
    label: 'PFFL,  ETRACS 2xMonthly Pay Leveraged Preferred Stock Index ETN',
    name: 'ETRACS 2xMonthly Pay Leveraged Preferred Stock Index ETN',
    symbol: 'PFFL',
  },
  {
    value: 'NUHY',
    label: 'NUHY,  Nuveen ESG High Yield Corporate Bond ETF',
    name: 'Nuveen ESG High Yield Corporate Bond ETF',
    symbol: 'NUHY',
  },
  {
    value: 'NREF',
    label: 'NREF,  NexPoint Real Estate Finance, Inc.',
    name: 'NexPoint Real Estate Finance, Inc.',
    symbol: 'NREF',
  },
  {
    value: 'PINE',
    label: 'PINE,  Alpine Income Property Trust, Inc.',
    name: 'Alpine Income Property Trust, Inc.',
    symbol: 'PINE',
  },
  {
    value: 'PTBD',
    label: 'PTBD,  Pacer Trendpilot US Bond ETF',
    name: 'Pacer Trendpilot US Bond ETF',
    symbol: 'PTBD',
  },
  {
    value: 'ROSC',
    label: 'ROSC,  Hartford Multifactor Small Cap ETF',
    name: 'Hartford Multifactor Small Cap ETF',
    symbol: 'ROSC',
  },
  {
    value: 'OSCV',
    label: 'OSCV,  Opus Small Cap Value Plus ETF',
    name: 'Opus Small Cap Value Plus ETF',
    symbol: 'OSCV',
  },
  {
    value: 'KALL',
    label: 'KALL,  KraneShares MSCI All China Index ETF',
    name: 'KraneShares MSCI All China Index ETF',
    symbol: 'KALL',
  },
  {
    value: 'NET',
    label: 'NET,  Cloudflare, Inc.',
    name: 'Cloudflare, Inc.',
    symbol: 'NET',
  },
  {
    value: 'NEX',
    label: 'NEX,  NexTier Oilfield Solutions Inc.',
    name: 'NexTier Oilfield Solutions Inc.',
    symbol: 'NEX',
  },
  {
    value: 'NRGD',
    label: 'NRGD,  MicroSectors U.S. Big Oil Index -3X Inverse Leveraged ETNs',
    name: 'MicroSectors U.S. Big Oil Index -3X Inverse Leveraged ETNs',
    symbol: 'NRGD',
  },
  {
    value: 'OCFT',
    label: 'OCFT,  OneConnect Financial Technology Co., Ltd.',
    name: 'OneConnect Financial Technology Co., Ltd.',
    symbol: 'OCFT',
  },
  {
    value: 'SMOG',
    label: 'SMOG,  VanEck Low Carbon Energy ETF',
    name: 'VanEck Low Carbon Energy ETF',
    symbol: 'SMOG',
  },
  {
    value: 'NRGU',
    label: 'NRGU,  MicroSectors U.S. Big Oil Index 3X Leveraged ETNs',
    name: 'MicroSectors U.S. Big Oil Index 3X Leveraged ETNs',
    symbol: 'NRGU',
  },
  {
    value: 'QLV',
    label: 'QLV,  FlexShares US Quality Low Volatility Index Fund',
    name: 'FlexShares US Quality Low Volatility Index Fund',
    symbol: 'QLV',
  },
  {
    value: 'PYPE',
    label: 'PYPE,  UBS ETRACS NYSE Pickens Core Midstream Index ETN',
    name: 'UBS ETRACS NYSE Pickens Core Midstream Index ETN',
    symbol: 'PYPE',
  },
  {
    value: 'PIPR',
    label: 'PIPR,  Piper Sandler Companies',
    name: 'Piper Sandler Companies',
    symbol: 'PIPR',
  },
  {
    value: 'KLCD',
    label: 'KLCD,  KFA Large Cap Quality Dividend Index ETF',
    name: 'KFA Large Cap Quality Dividend Index ETF',
    symbol: 'KLCD',
  },
  {
    value: 'KMED',
    label: 'KMED,  KraneShares Emerging Markets Healthcare Index ETF',
    name: 'KraneShares Emerging Markets Healthcare Index ETF',
    symbol: 'KMED',
  },
  {
    value: 'SPTI',
    label: 'SPTI,  SPDR Portfolio Intermediate Term Treasury ETF',
    name: 'SPDR Portfolio Intermediate Term Treasury ETF',
    symbol: 'SPTI',
  },
  {
    value: 'SNPE',
    label: 'SNPE,  Xtrackers S&P 500 ESG ETF',
    name: 'Xtrackers S&P 500 ESG ETF',
    symbol: 'SNPE',
  },
  {
    value: 'LCR',
    label: 'LCR,  Leuthold Core ETF',
    name: 'Leuthold Core ETF',
    symbol: 'LCR',
  },
  {
    value: 'PFLD',
    label: 'PFLD,  AAM Low Duration Preferred and Income Securities ETF',
    name: 'AAM Low Duration Preferred and Income Securities ETF',
    symbol: 'PFLD',
  },
  {
    value: 'QINT',
    label: 'QINT,  American Century Quality Diversified International ETF',
    name: 'American Century Quality Diversified International ETF',
    symbol: 'QINT',
  },
  {
    value: 'PTIN',
    label: 'PTIN,  Pacer Trendpilot International ETF',
    name: 'Pacer Trendpilot International ETF',
    symbol: 'PTIN',
  },
  {
    value: 'NERD',
    label: 'NERD,  Roundhill Video Games ETF',
    name: 'Roundhill Video Games ETF',
    symbol: 'NERD',
  },
  {
    value: 'NVST',
    label: 'NVST,  Envista Holdings Corporation',
    name: 'Envista Holdings Corporation',
    symbol: 'NVST',
  },
  {
    value: 'SPXB',
    label: 'SPXB,  ProShares S&P 500 Bond ETF',
    name: 'ProShares S&P 500 Bond ETF',
    symbol: 'SPXB',
  },
  {
    value: 'QQH',
    label: 'QQH,  HCM Defender 100 Index ETF',
    name: 'HCM Defender 100 Index ETF',
    symbol: 'QQH',
  },
  {
    value: 'LGH',
    label: 'LGH,  HCM Defender 500 Index ETF',
    name: 'HCM Defender 500 Index ETF',
    symbol: 'LGH',
  },
  {
    value: 'QLVD',
    label:
      'QLVD,  FlexShares Developed Markets ex-US Quality Low Volatility Index Fund',
    name: 'FlexShares Developed Markets ex-US Quality Low Volatility Index Fund',
    symbol: 'QLVD',
  },
  {
    value: 'MUST',
    label: 'MUST,  Columbia Multi-Sector Municipal Income ETF',
    name: 'Columbia Multi-Sector Municipal Income ETF',
    symbol: 'MUST',
  },
  {
    value: 'QLVE',
    label:
      'QLVE,  FlexShares Emerging Markets Quality Low Volatility Index Fund',
    name: 'FlexShares Emerging Markets Quality Low Volatility Index Fund',
    symbol: 'QLVE',
  },
  {
    value: 'NETL',
    label: 'NETL,  Fundamental Income Net Lease Real Estate ETF',
    name: 'Fundamental Income Net Lease Real Estate ETF',
    symbol: 'NETL',
  },
  {
    value: 'NMCO',
    label: 'NMCO,  Nuveen Municipal Credit Opportunities Fund',
    name: 'Nuveen Municipal Credit Opportunities Fund',
    symbol: 'NMCO',
  },
  {
    value: 'SDCI',
    label: 'SDCI,  USCF SummerHaven Dynamic Commodity Strategy No K-1 Fund',
    name: 'USCF SummerHaven Dynamic Commodity Strategy No K-1 Fund',
    symbol: 'SDCI',
  },
  {
    value: 'SI',
    label: 'SI,  Silvergate Capital Corporation',
    name: 'Silvergate Capital Corporation',
    symbol: 'SI',
  },
  {
    value: 'SIMS',
    label: 'SIMS,  SPDR S&P Kensho Intelligent Structures ETF',
    name: 'SPDR S&P Kensho Intelligent Structures ETF',
    symbol: 'SIMS',
  },
  {
    value: 'PULS',
    label: 'PULS,  PGIM Ultra Short Bond ETF',
    name: 'PGIM Ultra Short Bond ETF',
    symbol: 'PULS',
  },
  {
    value: 'OPER',
    label: 'OPER,  ClearShares Ultra-Short Maturity ETF',
    name: 'ClearShares Ultra-Short Maturity ETF',
    symbol: 'OPER',
  },
  {
    value: 'NUSI',
    label: 'NUSI,  Nationwide Nasdaq-100 Risk-Managed Income ETF',
    name: 'Nationwide Nasdaq-100 Risk-Managed Income ETF',
    symbol: 'NUSI',
  },
  {
    value: 'RAAX',
    label: 'RAAX,  VanEck Inflation Allocation ETF',
    name: 'VanEck Inflation Allocation ETF',
    symbol: 'RAAX',
  },
  {
    value: 'QRFT',
    label: 'QRFT,  QRAFT AI-Enhanced U.S. Large Cap ETF',
    name: 'QRAFT AI-Enhanced U.S. Large Cap ETF',
    symbol: 'QRFT',
  },
  {
    value: 'NTSX',
    label: 'NTSX,  WisdomTree U.S. Efficient Core Fund',
    name: 'WisdomTree U.S. Efficient Core Fund',
    symbol: 'NTSX',
  },
  {
    value: 'QDIV',
    label: 'QDIV,  Global X S&P 500 Quality Dividend ETF',
    name: 'Global X S&P 500 Quality Dividend ETF',
    symbol: 'QDIV',
  },
  {
    value: 'PQIN',
    label:
      'PQIN,  PGIM Quant Solutions Strategic Alpha International Equity ETF',
    name: 'PGIM Quant Solutions Strategic Alpha International Equity ETF',
    symbol: 'PQIN',
  },
  {
    value: 'RPAR',
    label: 'RPAR,  RPAR Risk Parity ETF',
    name: 'RPAR Risk Parity ETF',
    symbol: 'RPAR',
  },
  {
    value: 'SDGA',
    label:
      'SDGA,  Impact Shares Sustainable Development Goals Global Equity ETF',
    name: 'Impact Shares Sustainable Development Goals Global Equity ETF',
    symbol: 'SDGA',
  },
  {
    value: 'MARB',
    label: 'MARB,  First Trust Vivaldi Merger Arbitrage ETF',
    name: 'First Trust Vivaldi Merger Arbitrage ETF',
    symbol: 'MARB',
  },
  {
    value: 'RAFE',
    label: 'RAFE,  PIMCO RAFI ESG U.S. ETF',
    name: 'PIMCO RAFI ESG U.S. ETF',
    symbol: 'RAFE',
  },
  {
    value: 'QGRO',
    label: 'QGRO,  American Century U.S. Quality Growth ETF',
    name: 'American Century U.S. Quality Growth ETF',
    symbol: 'QGRO',
  },
  {
    value: 'PLAT',
    label: 'PLAT,  WisdomTree Growth Leaders Fund',
    name: 'WisdomTree Growth Leaders Fund',
    symbol: 'PLAT',
  },
  {
    value: 'SEIX',
    label: 'SEIX,  Virtus Seix Senior Loan ETF',
    name: 'Virtus Seix Senior Loan ETF',
    symbol: 'SEIX',
  },
  {
    value: 'PHYL',
    label: 'PHYL,  PGIM Active High Yield Bond ETF',
    name: 'PGIM Active High Yield Bond ETF',
    symbol: 'PHYL',
  },
  {
    value: 'OVB',
    label: 'OVB,  Overlay Shares Core Bond ETF',
    name: 'Overlay Shares Core Bond ETF',
    symbol: 'OVB',
  },
  {
    value: 'OVF',
    label: 'OVF,  Overlay Shares Foreign Equity ETF',
    name: 'Overlay Shares Foreign Equity ETF',
    symbol: 'OVF',
  },
  {
    value: 'OVL',
    label: 'OVL,  Overlay Shares Large Cap Equity ETF',
    name: 'Overlay Shares Large Cap Equity ETF',
    symbol: 'OVL',
  },
  {
    value: 'OVM',
    label: 'OVM,  Overlay Shares Municipal Bond ETF',
    name: 'Overlay Shares Municipal Bond ETF',
    symbol: 'OVM',
  },
  {
    value: 'SPBO',
    label: 'SPBO,  SPDR Portfolio Corporate Bond ETF',
    name: 'SPDR Portfolio Corporate Bond ETF',
    symbol: 'SPBO',
  },
  {
    value: 'OVS',
    label: 'OVS,  Overlay Shares Small Cap Equity ETF',
    name: 'Overlay Shares Small Cap Equity ETF',
    symbol: 'OVS',
  },
  {
    value: 'OVV',
    label: 'OVV,  Ovintiv Inc.',
    name: 'Ovintiv Inc.',
    symbol: 'OVV',
  },
  {
    value: 'SPCE',
    label: 'SPCE,  Virgin Galactic Holdings, Inc.',
    name: 'Virgin Galactic Holdings, Inc.',
    symbol: 'SPCE',
  },
  {
    value: 'SCHJ',
    label: 'SCHJ,  Schwab 1-5 Year Corporate Bond ETF',
    name: 'Schwab 1-5 Year Corporate Bond ETF',
    symbol: 'SCHJ',
  },
  {
    value: 'SCHI',
    label: 'SCHI,  Schwab 5-10 Year Corporate Bond ETF',
    name: 'Schwab 5-10 Year Corporate Bond ETF',
    symbol: 'SCHI',
  },
  {
    value: 'SCHQ',
    label: 'SCHQ,  Schwab Long-Term U.S. Treasury ETF',
    name: 'Schwab Long-Term U.S. Treasury ETF',
    symbol: 'SCHQ',
  },
  {
    value: 'ONLN',
    label: 'ONLN,  ProShares Online Retail ETF',
    name: 'ProShares Online Retail ETF',
    symbol: 'ONLN',
  },
  {
    value: 'PEXL',
    label: 'PEXL,  Pacer US Export Leaders ETF',
    name: 'Pacer US Export Leaders ETF',
    symbol: 'PEXL',
  },
  {
    value: 'RDOG',
    label: 'RDOG,  ALPS REIT Dividend Dogs ETF',
    name: 'ALPS REIT Dividend Dogs ETF',
    symbol: 'RDOG',
  },
  {
    value: 'MOTO',
    label: 'MOTO,  SmartETFs Smart Transportation & Technology ETF',
    name: 'SmartETFs Smart Transportation & Technology ETF',
    symbol: 'MOTO',
  },
  {
    value: 'LSAF',
    label: 'LSAF,  LeaderSharesTM AlphaFactor US Core Equity ETF',
    name: 'LeaderSharesTM AlphaFactor US Core Equity ETF',
    symbol: 'LSAF',
  },
  {
    value: 'CHPT',
    label: 'CHPT,  ChargePoint Holdings, Inc.',
    name: 'ChargePoint Holdings, Inc.',
    symbol: 'CHPT',
  },
  {
    value: 'QTUM',
    label: 'QTUM,  Defiance Quantum ETF',
    name: 'Defiance Quantum ETF',
    symbol: 'QTUM',
  },
  {
    value: 'KSCD',
    label: 'KSCD,  KFA Small Cap Quality Dividend Index ETF',
    name: 'KFA Small Cap Quality Dividend Index ETF',
    symbol: 'KSCD',
  },
  {
    value: 'SPEU',
    label: 'SPEU,  SPDR Portfolio Europe ETF',
    name: 'SPDR Portfolio Europe ETF',
    symbol: 'SPEU',
  },
  {
    value: 'TFC',
    label: 'TFC,  Truist Financial Corporation',
    name: 'Truist Financial Corporation',
    symbol: 'TFC',
  },
  {
    value: 'WWJD',
    label: 'WWJD,  Inspire International ETF',
    name: 'Inspire International ETF',
    symbol: 'WWJD',
  },
  {
    value: 'WANT',
    label: 'WANT,  Direxion Daily Cnsmr Discret Bull 3XShrs',
    name: 'Direxion Daily Cnsmr Discret Bull 3XShrs',
    symbol: 'WANT',
  },
  {
    value: 'TAXF',
    label: 'TAXF,  American Century Diversified Municipal Bond ETF',
    name: 'American Century Diversified Municipal Bond ETF',
    symbol: 'TAXF',
  },
  {
    value: 'XMVM',
    label: 'XMVM,  Invesco S&P MidCap Value with Momentum ETF',
    name: 'Invesco S&P MidCap Value with Momentum ETF',
    symbol: 'XMVM',
  },
  {
    value: 'WOMN',
    label: "WOMN,  Impact Shares YWCA Women's Empowerment ETF",
    name: "Impact Shares YWCA Women's Empowerment ETF",
    symbol: 'WOMN',
  },
  {
    value: 'TPHD',
    label: 'TPHD,  Timothy Plan High Dividend Stock ETF',
    name: 'Timothy Plan High Dividend Stock ETF',
    symbol: 'TPHD',
  },
  {
    value: 'SZNE',
    label: 'SZNE,  Pacer CFRA-Stovall Equal Weight Seasonal Rotation Index ETF',
    name: 'Pacer CFRA-Stovall Equal Weight Seasonal Rotation Index ETF',
    symbol: 'SZNE',
  },
  {
    value: 'XLSR',
    label: 'XLSR,  SPDR SSGA U.S. Sector Rotation ETF',
    name: 'SPDR SSGA U.S. Sector Rotation ETF',
    symbol: 'XLSR',
  },
  {
    value: 'TRND',
    label: 'TRND,  Pacer Trendpilot Fund of Funds ETF',
    name: 'Pacer Trendpilot Fund of Funds ETF',
    symbol: 'TRND',
  },
  {
    value: 'XMHQ',
    label: 'XMHQ,  Invesco S&P MidCap Quality ETF',
    name: 'Invesco S&P MidCap Quality ETF',
    symbol: 'XMHQ',
  },
  {
    value: 'VVNT',
    label: 'VVNT,  Vivint Smart Home, Inc.',
    name: 'Vivint Smart Home, Inc.',
    symbol: 'VVNT',
  },
  {
    value: 'TECB',
    label: 'TECB,  iShares U.S. Tech Breakthrough Multisector ETF',
    name: 'iShares U.S. Tech Breakthrough Multisector ETF',
    symbol: 'TECB',
  },
  {
    value: 'TPIF',
    label: 'TPIF,  Timothy Plan International ETF',
    name: 'Timothy Plan International ETF',
    symbol: 'TPIF',
  },
  {
    value: 'VPC',
    label: 'VPC,  Virtus Private Credit ETF',
    name: 'Virtus Private Credit ETF',
    symbol: 'VPC',
  },
  {
    value: 'TPLC',
    label: 'TPLC,  Timothy Plan US Large/Mid Cap Core ETF',
    name: 'Timothy Plan US Large/Mid Cap Core ETF',
    symbol: 'TPLC',
  },
  {
    value: 'SSUS',
    label: 'SSUS,  Day Hagan/Ned Davis Research Smart Sector ETF',
    name: 'Day Hagan/Ned Davis Research Smart Sector ETF',
    symbol: 'SSUS',
  },
  {
    value: 'UBOT',
    label:
      'UBOT,  Daily Robotics, Artificial Intelligence & Automation Index Bull 2X Shares',
    name: 'Daily Robotics, Artificial Intelligence & Automation Index Bull 2X Shares',
    symbol: 'UBOT',
  },
  {
    value: 'WBIT',
    label: 'WBIT,  WBI BullBear Trend Switch US 3000 Total Return ETF',
    name: 'WBI BullBear Trend Switch US 3000 Total Return ETF',
    symbol: 'WBIT',
  },
  {
    value: 'URNM',
    label: 'URNM,  Sprott Uranium Miners ETF',
    name: 'Sprott Uranium Miners ETF',
    symbol: 'URNM',
  },
  {
    value: 'UTRN',
    label: 'UTRN,  Vesper U.S. Large Cap Short-Term Reversal Strategy ETF',
    name: 'Vesper U.S. Large Cap Short-Term Reversal Strategy ETF',
    symbol: 'UTRN',
  },
  {
    value: 'ZIG',
    label: 'ZIG,  The Acquirers Fund ETF',
    name: 'The Acquirers Fund ETF',
    symbol: 'ZIG',
  },
  {
    value: 'USSG',
    label: 'USSG,  Xtrackers MSCI USA ESG Leaders Equity ETF',
    name: 'Xtrackers MSCI USA ESG Leaders Equity ETF',
    symbol: 'USSG',
  },
  {
    value: 'VALT',
    label: 'VALT,  ETFMG Sit Ultra Short ETF',
    name: 'ETFMG Sit Ultra Short ETF',
    symbol: 'VALT',
  },
  {
    value: 'USI',
    label: 'USI,  Principal Ultra-Short Active Income ETF',
    name: 'Principal Ultra-Short Active Income ETF',
    symbol: 'USI',
  },
  {
    value: 'ULTR',
    label: 'ULTR,  IQ Ultra Short Duration ETF',
    name: 'IQ Ultra Short Duration ETF',
    symbol: 'ULTR',
  },
  {
    value: 'VEL',
    label: 'VEL,  Velocity Financial, Inc.',
    name: 'Velocity Financial, Inc.',
    symbol: 'VEL',
  },
  {
    value: 'SRVR',
    label: 'SRVR,  Pacer Benchmark Data & Infrastructure Real Estate SCTR ETF',
    name: 'Pacer Benchmark Data & Infrastructure Real Estate SCTR ETF',
    symbol: 'SRVR',
  },
  {
    value: 'WEBL',
    label: 'WEBL,  Direxion Daily Dow Jones Internet Bull 3X Shares',
    name: 'Direxion Daily Dow Jones Internet Bull 3X Shares',
    symbol: 'WEBL',
  },
  {
    value: 'SWAN',
    label: 'SWAN,  Amplify BlackSwan Growth & Treasury Core ETF',
    name: 'Amplify BlackSwan Growth & Treasury Core ETF',
    symbol: 'SWAN',
  },
  {
    value: 'WEBS',
    label: 'WEBS,  Direxion Daily Dow Jones Internet Bear 3X Shares',
    name: 'Direxion Daily Dow Jones Internet Bear 3X Shares',
    symbol: 'WEBS',
  },
  {
    value: 'WIZ',
    label: 'WIZ,  Alpha Architect Merlyn.AI Bull-Rider Bear-Fighter ETF',
    name: 'Alpha Architect Merlyn.AI Bull-Rider Bear-Fighter ETF',
    symbol: 'WIZ',
  },
  {
    value: 'VRAI',
    label: 'VRAI,  Virtus Real Asset Income ETF',
    name: 'Virtus Real Asset Income ETF',
    symbol: 'VRAI',
  },
  {
    value: 'THCX',
    label: 'THCX,  The Cannabis ETF',
    name: 'The Cannabis ETF',
    symbol: 'THCX',
  },
  {
    value: 'XLC',
    label: 'XLC,  Communication Services Select Sector SPDR Fund',
    name: 'Communication Services Select Sector SPDR Fund',
    symbol: 'XLC',
  },
  {
    value: 'TPSC',
    label: 'TPSC,  Timothy Plan US Small Cap Core ETF',
    name: 'Timothy Plan US Small Cap Core ETF',
    symbol: 'TPSC',
  },
  {
    value: 'XSMO',
    label: 'XSMO,  Invesco S&P SmallCap Momentum ETF',
    name: 'Invesco S&P SmallCap Momentum ETF',
    symbol: 'XSMO',
  },
  {
    value: 'WTRG',
    label: 'WTRG,  Essential Utilities, Inc.',
    name: 'Essential Utilities, Inc.',
    symbol: 'WTRG',
  },
  {
    value: 'VEGN',
    label: 'VEGN,  US Vegan Climate ETF',
    name: 'US Vegan Climate ETF',
    symbol: 'VEGN',
  },
  {
    value: 'XOUT',
    label: 'XOUT,  GraniteShares XOUT U.S. Large Cap ETF',
    name: 'GraniteShares XOUT U.S. Large Cap ETF',
    symbol: 'XOUT',
  },
  {
    value: 'COFS',
    label: 'COFS,  ChoiceOne Financial Services, Inc.',
    name: 'ChoiceOne Financial Services, Inc.',
    symbol: 'COFS',
  },
  {
    value: 'CLSK',
    label: 'CLSK,  CleanSpark, Inc.',
    name: 'CleanSpark, Inc.',
    symbol: 'CLSK',
  },
  {
    value: 'AGNCP',
    label: 'AGNCP,  AGNC Investment Corp.',
    name: 'AGNC Investment Corp.',
    symbol: 'AGNCP',
  },
  {
    value: 'DUOT',
    label: 'DUOT,  Duos Technologies Group, Inc.',
    name: 'Duos Technologies Group, Inc.',
    symbol: 'DUOT',
  },
  {
    value: 'HUIZ',
    label: 'HUIZ,  Huize Holding Limited',
    name: 'Huize Holding Limited',
    symbol: 'HUIZ',
  },
  {
    value: 'RVMD',
    label: 'RVMD,  Revolution Medicines, Inc.',
    name: 'Revolution Medicines, Inc.',
    symbol: 'RVMD',
  },
  {
    value: 'MDRRP',
    label: 'MDRRP,  Medalist Diversified REIT, Inc.',
    name: 'Medalist Diversified REIT, Inc.',
    symbol: 'MDRRP',
  },
  {
    value: 'ADXN',
    label: 'ADXN,  Addex Therapeutics Ltd',
    name: 'Addex Therapeutics Ltd',
    symbol: 'ADXN',
  },
  {
    value: 'AGNCO',
    label: 'AGNCO,  AGNC Investment Corp.',
    name: 'AGNC Investment Corp.',
    symbol: 'AGNCO',
  },
  {
    value: 'ARQT',
    label: 'ARQT,  Arcutis Biotherapeutics, Inc.',
    name: 'Arcutis Biotherapeutics, Inc.',
    symbol: 'ARQT',
  },
  {
    value: 'ALRS',
    label: 'ALRS,  Alerus Financial Corporation',
    name: 'Alerus Financial Corporation',
    symbol: 'ALRS',
  },
  {
    value: 'AACG',
    label: 'AACG,  ATA Creativity Global',
    name: 'ATA Creativity Global',
    symbol: 'AACG',
  },
  {
    value: 'APRE',
    label: 'APRE,  Aprea Therapeutics, Inc.',
    name: 'Aprea Therapeutics, Inc.',
    symbol: 'APRE',
  },
  {
    value: 'ANPC',
    label: 'ANPC,  AnPac Bio-Medical Science Co., Ltd.',
    name: 'AnPac Bio-Medical Science Co., Ltd.',
    symbol: 'ANPC',
  },
  {
    value: 'MDRR',
    label: 'MDRR,  Medalist Diversified REIT, Inc.',
    name: 'Medalist Diversified REIT, Inc.',
    symbol: 'MDRR',
  },
  {
    value: 'ASTS',
    label: 'ASTS,  AST SpaceMobile, Inc.',
    name: 'AST SpaceMobile, Inc.',
    symbol: 'ASTS',
  },
  {
    value: 'WCLD',
    label: 'WCLD,  WisdomTree Cloud Computing Fund',
    name: 'WisdomTree Cloud Computing Fund',
    symbol: 'WCLD',
  },
  {
    value: 'AHCO',
    label: 'AHCO,  AdaptHealth Corp.',
    name: 'AdaptHealth Corp.',
    symbol: 'AHCO',
  },
  {
    value: 'AIH',
    label: 'AIH,  Aesthetic Medical International Holdings Group Limited',
    name: 'Aesthetic Medical International Holdings Group Limited',
    symbol: 'AIH',
  },
  {
    value: 'BRP',
    label: 'BRP,  BRP Group, Inc.',
    name: 'BRP Group, Inc.',
    symbol: 'BRP',
  },
  {
    value: 'CNTG',
    label: 'CNTG,  Centogene N.V.',
    name: 'Centogene N.V.',
    symbol: 'CNTG',
  },
  {
    value: 'FITBO',
    label: 'FITBO,  Fifth Third Bancorp',
    name: 'Fifth Third Bancorp',
    symbol: 'FITBO',
  },
  {
    value: 'BSBK',
    label: 'BSBK,  Bogota Financial Corp.',
    name: 'Bogota Financial Corp.',
    symbol: 'BSBK',
  },
  {
    value: 'PHAT',
    label: 'PHAT,  Phathom Pharmaceuticals, Inc.',
    name: 'Phathom Pharmaceuticals, Inc.',
    symbol: 'PHAT',
  },
  {
    value: 'SDC',
    label: 'SDC,  SmileDirectClub, Inc.',
    name: 'SmileDirectClub, Inc.',
    symbol: 'SDC',
  },
  {
    value: 'BSBE',
    label: 'BSBE,  Invesco BulletShares (R) 2022 USD Emerging Markets Debt ETF',
    name: 'Invesco BulletShares (R) 2022 USD Emerging Markets Debt ETF',
    symbol: 'BSBE',
  },
  {
    value: 'OPCH',
    label: 'OPCH,  Option Care Health, Inc.',
    name: 'Option Care Health, Inc.',
    symbol: 'OPCH',
  },
  {
    value: 'PGNY',
    label: 'PGNY,  Progyny, Inc.',
    name: 'Progyny, Inc.',
    symbol: 'PGNY',
  },
  {
    value: 'PRDO',
    label: 'PRDO,  Perdoceo Education Corporation',
    name: 'Perdoceo Education Corporation',
    symbol: 'PRDO',
  },
  {
    value: 'DHC',
    label: 'DHC,  Diversified Healthcare Trust',
    name: 'Diversified Healthcare Trust',
    symbol: 'DHC',
  },
  {
    value: 'NLOK',
    label: 'NLOK,  NortonLifeLock Inc.',
    name: 'NortonLifeLock Inc.',
    symbol: 'NLOK',
  },
  {
    value: 'SVC',
    label: 'SVC,  Service Properties Trust',
    name: 'Service Properties Trust',
    symbol: 'SVC',
  },
  {
    value: 'IPHA',
    label: 'IPHA,  Innate Pharma S.A.',
    name: 'Innate Pharma S.A.',
    symbol: 'IPHA',
  },
  {
    value: 'TXG',
    label: 'TXG,  10x Genomics, Inc.',
    name: '10x Genomics, Inc.',
    symbol: 'TXG',
  },
  {
    value: 'MCBS',
    label: 'MCBS,  MetroCity Bankshares, Inc.',
    name: 'MetroCity Bankshares, Inc.',
    symbol: 'MCBS',
  },
  {
    value: 'DDOG',
    label: 'DDOG,  Datadog, Inc.',
    name: 'Datadog, Inc.',
    symbol: 'DDOG',
  },
  {
    value: 'HQI',
    label: 'HQI,  HireQuest, Inc.',
    name: 'HireQuest, Inc.',
    symbol: 'HQI',
  },
  {
    value: 'YAYO',
    label: 'YAYO,  EVmo, Inc.',
    name: 'EVmo, Inc.',
    symbol: 'YAYO',
  },
  {
    value: 'BDTX',
    label: 'BDTX,  Black Diamond Therapeutics, Inc.',
    name: 'Black Diamond Therapeutics, Inc.',
    symbol: 'BDTX',
  },
  {
    value: 'FREQ',
    label: 'FREQ,  Frequency Therapeutics, Inc.',
    name: 'Frequency Therapeutics, Inc.',
    symbol: 'FREQ',
  },
  {
    value: 'IMAB',
    label: 'IMAB,  I-Mab',
    name: 'I-Mab',
    symbol: 'IMAB',
  },
  {
    value: 'GTEC',
    label: 'GTEC,  Greenland Technologies Holding Corporation',
    name: 'Greenland Technologies Holding Corporation',
    symbol: 'GTEC',
  },
  {
    value: 'CRNC',
    label: 'CRNC,  Cerence Inc.',
    name: 'Cerence Inc.',
    symbol: 'CRNC',
  },
  {
    value: 'BSDE',
    label: 'BSDE,  Invesco BulletShares (R) 2024 Emerging Markets Debt ETF',
    name: 'Invesco BulletShares (R) 2024 Emerging Markets Debt ETF',
    symbol: 'BSDE',
  },
  {
    value: 'GRTX',
    label: 'GRTX,  Galera Therapeutics, Inc.',
    name: 'Galera Therapeutics, Inc.',
    symbol: 'GRTX',
  },
  {
    value: 'HBT',
    label: 'HBT,  HBT Financial, Inc.',
    name: 'HBT Financial, Inc.',
    symbol: 'HBT',
  },
  {
    value: 'BUG',
    label: 'BUG,  Global X Cybersecurity ETF',
    name: 'Global X Cybersecurity ETF',
    symbol: 'BUG',
  },
  {
    value: 'CNSP',
    label: 'CNSP,  CNS Pharmaceuticals, Inc.',
    name: 'CNS Pharmaceuticals, Inc.',
    symbol: 'CNSP',
  },
  {
    value: 'STSA',
    label: 'STSA,  Satsuma Pharmaceuticals, Inc.',
    name: 'Satsuma Pharmaceuticals, Inc.',
    symbol: 'STSA',
  },
  {
    value: 'TCOM',
    label: 'TCOM,  Trip.com Group Limited',
    name: 'Trip.com Group Limited',
    symbol: 'TCOM',
  },
  {
    value: 'REYN',
    label: 'REYN,  Reynolds Consumer Products Inc.',
    name: 'Reynolds Consumer Products Inc.',
    symbol: 'REYN',
  },
  {
    value: 'BLDEW',
    label: 'BLDEW,  Blade Air Mobility, Inc.',
    name: 'Blade Air Mobility, Inc.',
    symbol: 'BLDEW',
  },
  {
    value: 'PGEN',
    label: 'PGEN,  Precigen, Inc.',
    name: 'Precigen, Inc.',
    symbol: 'PGEN',
  },
  {
    value: 'MBNKP',
    label: 'MBNKP,  Medallion Bank',
    name: 'Medallion Bank',
    symbol: 'MBNKP',
  },
  {
    value: 'RILYP',
    label: 'RILYP,  B. Riley Financial, Inc.',
    name: 'B. Riley Financial, Inc.',
    symbol: 'RILYP',
  },
  {
    value: 'WKEY',
    label: 'WKEY,  WISeKey International Holding AG',
    name: 'WISeKey International Holding AG',
    symbol: 'WKEY',
  },
  {
    value: 'HUGE',
    label: 'HUGE,  FSD Pharma Inc.',
    name: 'FSD Pharma Inc.',
    symbol: 'HUGE',
  },
  {
    value: 'IMVT',
    label: 'IMVT,  Immunovant, Inc.',
    name: 'Immunovant, Inc.',
    symbol: 'IMVT',
  },
  {
    value: 'RCEL',
    label: 'RCEL,  AVITA Medical, Inc.',
    name: 'AVITA Medical, Inc.',
    symbol: 'RCEL',
  },
  {
    value: 'PTON',
    label: 'PTON,  Peloton Interactive, Inc.',
    name: 'Peloton Interactive, Inc.',
    symbol: 'PTON',
  },
  {
    value: 'TELA',
    label: 'TELA,  TELA Bio, Inc.',
    name: 'TELA Bio, Inc.',
    symbol: 'TELA',
  },
  {
    value: 'VERO',
    label: 'VERO,  Venus Concept Inc.',
    name: 'Venus Concept Inc.',
    symbol: 'VERO',
  },
  {
    value: 'PNTG',
    label: 'PNTG,  The Pennant Group, Inc.',
    name: 'The Pennant Group, Inc.',
    symbol: 'PNTG',
  },
  {
    value: 'NTRSO',
    label: 'NTRSO,  Northern Trust Corporation',
    name: 'Northern Trust Corporation',
    symbol: 'NTRSO',
  },
  {
    value: 'SDGR',
    label: 'SDGR,  Schrödinger, Inc.',
    name: 'Schrödinger, Inc.',
    symbol: 'SDGR',
  },
  {
    value: 'ONEM',
    label: 'ONEM,  1Life Healthcare, Inc.',
    name: '1Life Healthcare, Inc.',
    symbol: 'ONEM',
  },
  {
    value: 'BXRX',
    label: 'BXRX,  Baudax Bio, Inc.',
    name: 'Baudax Bio, Inc.',
    symbol: 'BXRX',
  },
  {
    value: 'LIZI',
    label: 'LIZI,  Lizhi Inc.',
    name: 'Lizhi Inc.',
    symbol: 'LIZI',
  },
  {
    value: 'XP',
    label: 'XP,  XP Inc.',
    name: 'XP Inc.',
    symbol: 'XP',
  },
  {
    value: 'CABA',
    label: 'CABA,  Cabaletta Bio, Inc.',
    name: 'Cabaletta Bio, Inc.',
    symbol: 'CABA',
  },
  {
    value: 'GOODN',
    label: 'GOODN,  Gladstone Commercial Corporation',
    name: 'Gladstone Commercial Corporation',
    symbol: 'GOODN',
  },
  {
    value: 'FRG',
    label: 'FRG,  Franchise Group, Inc.',
    name: 'Franchise Group, Inc.',
    symbol: 'FRG',
  },
  {
    value: 'PWFL',
    label: 'PWFL,  PowerFleet, Inc.',
    name: 'PowerFleet, Inc.',
    symbol: 'PWFL',
  },
  {
    value: 'PFHD',
    label: 'PFHD,  Professional Holding Corp.',
    name: 'Professional Holding Corp.',
    symbol: 'PFHD',
  },
  {
    value: 'KRKR',
    label: 'KRKR,  36Kr Holdings Inc.',
    name: '36Kr Holdings Inc.',
    symbol: 'KRKR',
  },
  {
    value: 'IGMS',
    label: 'IGMS,  IGM Biosciences, Inc.',
    name: 'IGM Biosciences, Inc.',
    symbol: 'IGMS',
  },
  {
    value: 'TARA',
    label: 'TARA,  Protara Therapeutics, Inc.',
    name: 'Protara Therapeutics, Inc.',
    symbol: 'TARA',
  },
  {
    value: 'OCGN',
    label: 'OCGN,  Ocugen, Inc.',
    name: 'Ocugen, Inc.',
    symbol: 'OCGN',
  },
  {
    value: 'CPZ',
    label: 'CPZ,  Calamos Long/Short Equity & Dynamic Income Term Trust',
    name: 'Calamos Long/Short Equity & Dynamic Income Term Trust',
    symbol: 'CPZ',
  },
  {
    value: 'ONEW',
    label: 'ONEW,  OneWater Marine Inc.',
    name: 'OneWater Marine Inc.',
    symbol: 'ONEW',
  },
  {
    value: 'ESPO',
    label: 'ESPO,  VanEck Video Gaming and eSports ETF',
    name: 'VanEck Video Gaming and eSports ETF',
    symbol: 'ESPO',
  },
  {
    value: 'XGN',
    label: 'XGN,  Exagen Inc.',
    name: 'Exagen Inc.',
    symbol: 'XGN',
  },
  {
    value: 'SWKH',
    label: 'SWKH,  SWK Holdings Corporation',
    name: 'SWK Holdings Corporation',
    symbol: 'SWKH',
  },
  {
    value: 'CNNB',
    label: 'CNNB,  Cincinnati Bancorp, Inc.',
    name: 'Cincinnati Bancorp, Inc.',
    symbol: 'CNNB',
  },
  {
    value: 'QMCO',
    label: 'QMCO,  Quantum Corporation',
    name: 'Quantum Corporation',
    symbol: 'QMCO',
  },
  {
    value: 'KRMD',
    label: 'KRMD,  KORU Medical Systems, Inc.',
    name: 'KORU Medical Systems, Inc.',
    symbol: 'KRMD',
  },
  {
    value: 'GNSS',
    label: 'GNSS,  Genasys Inc.',
    name: 'Genasys Inc.',
    symbol: 'GNSS',
  },
  {
    value: 'FRHC',
    label: 'FRHC,  Freedom Holding Corp.',
    name: 'Freedom Holding Corp.',
    symbol: 'FRHC',
  },
  {
    value: 'DRTT',
    label: 'DRTT,  DIRTT Environmental Solutions Ltd.',
    name: 'DIRTT Environmental Solutions Ltd.',
    symbol: 'DRTT',
  },
  {
    value: 'JAN',
    label: 'JAN,  JanOne Inc.',
    name: 'JanOne Inc.',
    symbol: 'JAN',
  },
  {
    value: 'NYMTM',
    label: 'NYMTM,  New York Mortgage Trust, Inc.',
    name: 'New York Mortgage Trust, Inc.',
    symbol: 'NYMTM',
  },
  {
    value: 'BEAM',
    label: 'BEAM,  Beam Therapeutics Inc.',
    name: 'Beam Therapeutics Inc.',
    symbol: 'BEAM',
  },
  {
    value: 'SIVBP',
    label: 'SIVBP,  SVB Financial Group',
    name: 'SVB Financial Group',
    symbol: 'SIVBP',
  },
  {
    value: 'OPRT',
    label: 'OPRT,  Oportun Financial Corporation',
    name: 'Oportun Financial Corporation',
    symbol: 'OPRT',
  },
  {
    value: 'FUTU',
    label: 'FUTU,  Futu Holdings Limited',
    name: 'Futu Holdings Limited',
    symbol: 'FUTU',
  },
  {
    value: 'BNTX',
    label: 'BNTX,  BioNTech SE',
    name: 'BioNTech SE',
    symbol: 'BNTX',
  },
  {
    value: 'SWTX',
    label: 'SWTX,  SpringWorks Therapeutics, Inc.',
    name: 'SpringWorks Therapeutics, Inc.',
    symbol: 'SWTX',
  },
  {
    value: 'HERO',
    label: 'HERO,  Global X Video Games & Esports ETF',
    name: 'Global X Video Games & Esports ETF',
    symbol: 'HERO',
  },
  {
    value: 'DHCNL',
    label: 'DHCNL,  Diversified Healthcare Trust',
    name: 'Diversified Healthcare Trust',
    symbol: 'DHCNL',
  },
  {
    value: 'OYST',
    label: 'OYST,  Oyster Point Pharma, Inc.',
    name: 'Oyster Point Pharma, Inc.',
    symbol: 'OYST',
  },
  {
    value: 'VIR',
    label: 'VIR,  Vir Biotechnology, Inc.',
    name: 'Vir Biotechnology, Inc.',
    symbol: 'VIR',
  },
  {
    value: 'ZIONO',
    label: 'ZIONO,  Zions Bancorporation, National Association',
    name: 'Zions Bancorporation, National Association',
    symbol: 'ZIONO',
  },
  {
    value: 'DUO',
    label: 'DUO,  Fangdd Network Group Ltd.',
    name: 'Fangdd Network Group Ltd.',
    symbol: 'DUO',
  },
  {
    value: 'YGYIP',
    label: 'YGYIP,  Youngevity International, Inc.',
    name: 'Youngevity International, Inc.',
    symbol: 'YGYIP',
  },
  {
    value: 'LDEM',
    label: 'LDEM,  iShares ESG MSCI EM Leaders ETF',
    name: 'iShares ESG MSCI EM Leaders ETF',
    symbol: 'LDEM',
  },
  {
    value: 'CCAP',
    label: 'CCAP,  Crescent Capital BDC, Inc.',
    name: 'Crescent Capital BDC, Inc.',
    symbol: 'CCAP',
  },
  {
    value: 'BSCT',
    label: 'BSCT,  Invesco BulletShares 2029 Corporate Bond ETF',
    name: 'Invesco BulletShares 2029 Corporate Bond ETF',
    symbol: 'BSCT',
  },
  {
    value: 'HAPP',
    label: 'HAPP,  Happiness Development Group Limited',
    name: 'Happiness Development Group Limited',
    symbol: 'HAPP',
  },
  {
    value: 'BSJR',
    label: 'BSJR,  Invesco BulletShares 2027 High Yield Corporate Bond ETF',
    name: 'Invesco BulletShares 2027 High Yield Corporate Bond ETF',
    symbol: 'BSJR',
  },
  {
    value: 'MDIA',
    label: 'MDIA,  MediaCo Holding Inc.',
    name: 'MediaCo Holding Inc.',
    symbol: 'MDIA',
  },
  {
    value: 'BSJQ',
    label: 'BSJQ,  Invesco BulletShares 2026 High Yield Corporate Bond ETF',
    name: 'Invesco BulletShares 2026 High Yield Corporate Bond ETF',
    symbol: 'BSJQ',
  },
  {
    value: 'EH',
    label: 'EH,  EHang Holdings Limited',
    name: 'EHang Holdings Limited',
    symbol: 'EH',
  },
  {
    value: 'GRWG',
    label: 'GRWG,  GrowGeneration Corp.',
    name: 'GrowGeneration Corp.',
    symbol: 'GRWG',
  },
  {
    value: 'POTX',
    label: 'POTX,  Global X Cannabis ETF',
    name: 'Global X Cannabis ETF',
    symbol: 'POTX',
  },
  {
    value: 'BSMS',
    label: 'BSMS,  Invesco BulletShares (R) 2028 Municipal Bond ETF',
    name: 'Invesco BulletShares (R) 2028 Municipal Bond ETF',
    symbol: 'BSMS',
  },
  {
    value: 'GXTG',
    label: 'GXTG,  Global X Thematic Growth ETF',
    name: 'Global X Thematic Growth ETF',
    symbol: 'GXTG',
  },
  {
    value: 'ZIONP',
    label: 'ZIONP,  Zions Bancorporation, National Association',
    name: 'Zions Bancorporation, National Association',
    symbol: 'ZIONP',
  },
  {
    value: 'HTIA',
    label: 'HTIA,  Healthcare Trust, Inc.',
    name: 'Healthcare Trust, Inc.',
    symbol: 'HTIA',
  },
  {
    value: 'SITM',
    label: 'SITM,  SiTime Corporation',
    name: 'SiTime Corporation',
    symbol: 'SITM',
  },
  {
    value: 'UFO',
    label: 'UFO,  Procure Space ETF',
    name: 'Procure Space ETF',
    symbol: 'UFO',
  },
  {
    value: 'ETNB',
    label: 'ETNB,  89bio, Inc.',
    name: '89bio, Inc.',
    symbol: 'ETNB',
  },
  {
    value: 'LMNL',
    label: 'LMNL,  Liminal BioSciences Inc.',
    name: 'Liminal BioSciences Inc.',
    symbol: 'LMNL',
  },
  {
    value: 'LMPX',
    label: 'LMPX,  LMP Automotive Holdings, Inc.',
    name: 'LMP Automotive Holdings, Inc.',
    symbol: 'LMPX',
  },
  {
    value: 'ASTSW',
    label: 'ASTSW,  AST SpaceMobile, Inc.',
    name: 'AST SpaceMobile, Inc.',
    symbol: 'ASTSW',
  },
  {
    value: 'RAPT',
    label: 'RAPT,  RAPT Therapeutics, Inc.',
    name: 'RAPT Therapeutics, Inc.',
    symbol: 'RAPT',
  },
  {
    value: 'BSCE',
    label: 'BSCE,  Invesco BulletShares (R) 2023 USD Emerging Markets Debt ETF',
    name: 'Invesco BulletShares (R) 2023 USD Emerging Markets Debt ETF',
    symbol: 'BSCE',
  },
  {
    value: 'BSCS',
    label: 'BSCS,  Invesco BulletShares 2028 Corporate Bond ETF',
    name: 'Invesco BulletShares 2028 Corporate Bond ETF',
    symbol: 'BSCS',
  },
  {
    value: 'RLMD',
    label: 'RLMD,  Relmada Therapeutics, Inc.',
    name: 'Relmada Therapeutics, Inc.',
    symbol: 'RLMD',
  },
  {
    value: 'SPT',
    label: 'SPT,  Sprout Social, Inc.',
    name: 'Sprout Social, Inc.',
    symbol: 'SPT',
  },
  {
    value: 'NRBO',
    label: 'NRBO,  NeuroBo Pharmaceuticals, Inc.',
    name: 'NeuroBo Pharmaceuticals, Inc.',
    symbol: 'NRBO',
  },
  {
    value: 'TFFP',
    label: 'TFFP,  TFF Pharmaceuticals, Inc.',
    name: 'TFF Pharmaceuticals, Inc.',
    symbol: 'TFFP',
  },
  {
    value: 'MNPR',
    label: 'MNPR,  Monopar Therapeutics Inc.',
    name: 'Monopar Therapeutics Inc.',
    symbol: 'MNPR',
  },
  {
    value: 'PROF',
    label: 'PROF,  Profound Medical Corp.',
    name: 'Profound Medical Corp.',
    symbol: 'PROF',
  },
  {
    value: 'THMO',
    label: 'THMO,  ThermoGenesis Holdings, Inc.',
    name: 'ThermoGenesis Holdings, Inc.',
    symbol: 'THMO',
  },
  {
    value: 'THTX',
    label: 'THTX,  Theratechnologies Inc.',
    name: 'Theratechnologies Inc.',
    symbol: 'THTX',
  },
  {
    value: 'GNRSU',
    label: 'GNRSU,  The Greenrose Holding Company Inc.',
    name: 'The Greenrose Holding Company Inc.',
    symbol: 'GNRSU',
  },
  {
    value: 'PALI',
    label: 'PALI,  Palisade Bio, Inc.',
    name: 'Palisade Bio, Inc.',
    symbol: 'PALI',
  },
  {
    value: 'CAN',
    label: 'CAN,  Canaan Inc.',
    name: 'Canaan Inc.',
    symbol: 'CAN',
  },
  {
    value: 'ANVS',
    label: 'ANVS,  Annovis Bio, Inc.',
    name: 'Annovis Bio, Inc.',
    symbol: 'ANVS',
  },
  {
    value: 'INDO',
    label: 'INDO,  Indonesia Energy Corporation Limited',
    name: 'Indonesia Energy Corporation Limited',
    symbol: 'INDO',
  },
  {
    value: 'PHGE',
    label: 'PHGE,  BiomX Inc.',
    name: 'BiomX Inc.',
    symbol: 'PHGE',
  },
  {
    value: 'ELA',
    label: 'ELA,  Envela Corporation',
    name: 'Envela Corporation',
    symbol: 'ELA',
  },
  {
    value: 'MTA',
    label: 'MTA,  Metalla Royalty & Streaming Ltd.',
    name: 'Metalla Royalty & Streaming Ltd.',
    symbol: 'MTA',
  },
  {
    value: 'BSMP',
    label: 'BSMP,  Invesco BulletShares (R) 2025 Municipal Bond ETF',
    name: 'Invesco BulletShares (R) 2025 Municipal Bond ETF',
    symbol: 'BSMP',
  },
  {
    value: 'ARTLW',
    label: 'ARTLW,  Artelo Biosciences, Inc.',
    name: 'Artelo Biosciences, Inc.',
    symbol: 'ARTLW',
  },
  {
    value: 'BHFAL',
    label: 'BHFAL,  Brighthouse Financial, Inc.',
    name: 'Brighthouse Financial, Inc.',
    symbol: 'BHFAL',
  },
  {
    value: 'COWNL',
    label: 'COWNL,  Cowen Inc. - 7.75% Senior Notes',
    name: 'Cowen Inc. - 7.75% Senior Notes',
    symbol: 'COWNL',
  },
  {
    value: 'BSMN',
    label: 'BSMN,  Invesco BulletShares (R) 2023 Municipal Bond ETF',
    name: 'Invesco BulletShares (R) 2023 Municipal Bond ETF',
    symbol: 'BSMN',
  },
  {
    value: 'PAVMZ',
    label: 'PAVMZ,  PAVmed Inc',
    name: 'PAVmed Inc',
    symbol: 'PAVMZ',
  },
  {
    value: 'SOLOW',
    label: 'SOLOW,  Electrameccanica Vehicles Corp. WT EXP 080323',
    name: 'Electrameccanica Vehicles Corp. WT EXP 080323',
    symbol: 'SOLOW',
  },
  {
    value: 'BSMM',
    label: 'BSMM,  Invesco BulletShares (R) 2022 Municipal Bond ETF',
    name: 'Invesco BulletShares (R) 2022 Municipal Bond ETF',
    symbol: 'BSMM',
  },
  {
    value: 'MEDS',
    label: 'MEDS,  TRxADE HEALTH, Inc.',
    name: 'TRxADE HEALTH, Inc.',
    symbol: 'MEDS',
  },
  {
    value: 'DCOMP',
    label: 'DCOMP,  Dime Community Bancshares, Inc.',
    name: 'Dime Community Bancshares, Inc.',
    symbol: 'DCOMP',
  },
  {
    value: 'MFH',
    label: 'MFH,  Mercurity Fintech Holding Inc.',
    name: 'Mercurity Fintech Holding Inc.',
    symbol: 'MFH',
  },
  {
    value: 'CLPT',
    label: 'CLPT,  ClearPoint Neuro, Inc.',
    name: 'ClearPoint Neuro, Inc.',
    symbol: 'CLPT',
  },
  {
    value: 'GRIL',
    label: 'GRIL,  Muscle Maker, Inc.',
    name: 'Muscle Maker, Inc.',
    symbol: 'GRIL',
  },
  {
    value: 'INBKZ',
    label: 'INBKZ,  First Internet Bancorp - Fixed-',
    name: 'First Internet Bancorp - Fixed-',
    symbol: 'INBKZ',
  },
  {
    value: 'XBIOW',
    label: 'XBIOW,  Xenetic Biosciences, Inc.',
    name: 'Xenetic Biosciences, Inc.',
    symbol: 'XBIOW',
  },
  {
    value: 'PTMN',
    label: 'PTMN,  Portman Ridge Finance Corporation',
    name: 'Portman Ridge Finance Corporation',
    symbol: 'PTMN',
  },
  {
    value: 'CREXW',
    label: 'CREXW,  Creative Realities, Inc. WT EXP 110922',
    name: 'Creative Realities, Inc. WT EXP 110922',
    symbol: 'CREXW',
  },
  {
    value: 'TANNZ',
    label: 'TANNZ,  TravelCenters of America LLC 8% SR NT 2030',
    name: 'TravelCenters of America LLC 8% SR NT 2030',
    symbol: 'TANNZ',
  },
  {
    value: 'PHUNW',
    label: 'PHUNW,  Phunware Inc.',
    name: 'Phunware Inc.',
    symbol: 'PHUNW',
  },
  {
    value: 'THCAW',
    label: 'THCAW,  Tuscan Holdings Corp II',
    name: 'Tuscan Holdings Corp II',
    symbol: 'THCAW',
  },
  {
    value: 'TANNL',
    label: 'TANNL,  TravelCenters of America LLC SR NT',
    name: 'TravelCenters of America LLC SR NT',
    symbol: 'TANNL',
  },
  {
    value: 'RILYM',
    label: 'RILYM,  B. Riley Financial, Inc. - 6.37',
    name: 'B. Riley Financial, Inc. - 6.37',
    symbol: 'RILYM',
  },
  {
    value: 'ADILW',
    label: 'ADILW,  Adial Pharmaceuticals, Inc. WT EXP 073123',
    name: 'Adial Pharmaceuticals, Inc. WT EXP 073123',
    symbol: 'ADILW',
  },
  {
    value: 'EDTXW',
    label: 'EDTXW,  EdtechX Holdings Acquisition Corp. II',
    name: 'EdtechX Holdings Acquisition Corp. II',
    symbol: 'EDTXW',
  },
  {
    value: 'MDGSW',
    label: 'MDGSW,  Medigus Ltd. WT C EXP 072323',
    name: 'Medigus Ltd. WT C EXP 072323',
    symbol: 'MDGSW',
  },
  {
    value: 'AGBAR',
    label: 'AGBAR,  AGBA Acquisition Limited',
    name: 'AGBA Acquisition Limited',
    symbol: 'AGBAR',
  },
  {
    value: 'BNGOW',
    label: 'BNGOW,  BioNano Genomics, Inc. WT EXP 082123',
    name: 'BioNano Genomics, Inc. WT EXP 082123',
    symbol: 'BNGOW',
  },
  {
    value: 'GECCN',
    label: 'GECCN,  Great Elm Capital Corp. - Notes',
    name: 'Great Elm Capital Corp. - Notes',
    symbol: 'GECCN',
  },
  {
    value: 'CHEKZ',
    label: 'CHEKZ,  Check-Cap Ltd',
    name: 'Check-Cap Ltd',
    symbol: 'CHEKZ',
  },
  {
    value: 'ENTXW',
    label: 'ENTXW,  Entera Bio Ltd. WT EXP 062723',
    name: 'Entera Bio Ltd. WT EXP 062723',
    symbol: 'ENTXW',
  },
  {
    value: 'VTIQW',
    label: 'VTIQW,  VectoIQ Acquisition Corp. II',
    name: 'VectoIQ Acquisition Corp. II',
    symbol: 'VTIQW',
  },
  {
    value: 'TBLTW',
    label: 'TBLTW,  ToughBuilt Industries, Inc. WT EXP 110923',
    name: 'ToughBuilt Industries, Inc. WT EXP 110923',
    symbol: 'TBLTW',
  },
  {
    value: 'OXSQL',
    label: 'OXSQL,  Oxford Square Capital Corp. 6.50% NT 24',
    name: 'Oxford Square Capital Corp. 6.50% NT 24',
    symbol: 'OXSQL',
  },
  {
    value: 'OXSQZ',
    label: 'OXSQZ,  Oxford Square Capital Corp. - 6',
    name: 'Oxford Square Capital Corp. - 6',
    symbol: 'OXSQZ',
  },
  {
    value: 'USWSW',
    label: 'USWSW,  U.S. Well Services, Inc. WT EXP 031524',
    name: 'U.S. Well Services, Inc. WT EXP 031524',
    symbol: 'USWSW',
  },
  {
    value: 'THWWW',
    label: 'THWWW,  Target Hospitality Corp.',
    name: 'Target Hospitality Corp.',
    symbol: 'THWWW',
  },
  {
    value: 'NEWTL',
    label: 'NEWTL,  Newtek Business Services Corp.',
    name: 'Newtek Business Services Corp.',
    symbol: 'NEWTL',
  },
  {
    value: 'BCDAW',
    label: 'BCDAW,  BioCardia, Inc.',
    name: 'BioCardia, Inc.',
    symbol: 'BCDAW',
  },
  {
    value: 'KERNW',
    label: 'KERNW,  Akerna Corp.',
    name: 'Akerna Corp.',
    symbol: 'KERNW',
  },
  {
    value: 'AGBAW',
    label: 'AGBAW,  AGBA Acquisition Limited',
    name: 'AGBA Acquisition Limited',
    symbol: 'AGBAW',
  },
  {
    value: 'IMACW',
    label: 'IMACW,  IMAC Holdings, Inc.',
    name: 'IMAC Holdings, Inc.',
    symbol: 'IMACW',
  },
  {
    value: 'RILYN',
    label: 'RILYN,  B. Riley Financial, Inc. - 6.50',
    name: 'B. Riley Financial, Inc. - 6.50',
    symbol: 'RILYN',
  },
  {
    value: 'BSMO',
    label: 'BSMO,  Invesco BulletShares (R) 2024 Municipal Bond ETF',
    name: 'Invesco BulletShares (R) 2024 Municipal Bond ETF',
    symbol: 'BSMO',
  },
  {
    value: 'ZIONL',
    label: 'ZIONL,  Zions Bancorporation N.A. - 6.9',
    name: 'Zions Bancorporation N.A. - 6.9',
    symbol: 'ZIONL',
  },
  {
    value: 'DHCNI',
    label: 'DHCNI,  Diversified Healthcare Trust  -',
    name: 'Diversified Healthcare Trust  -',
    symbol: 'DHCNI',
  },
  {
    value: 'VERBW',
    label: 'VERBW,  Verb Technology Company, Inc.',
    name: 'Verb Technology Company, Inc.',
    symbol: 'VERBW',
  },
  {
    value: 'CNFRL',
    label: 'CNFRL,  Conifer Holdings, Inc. - Senior',
    name: 'Conifer Holdings, Inc. - Senior',
    symbol: 'CNFRL',
  },
  {
    value: 'DWEQ',
    label: 'DWEQ,  AdvisorShares Dorsey Wright Alpha Equal Weight ETF',
    name: 'AdvisorShares Dorsey Wright Alpha Equal Weight ETF',
    symbol: 'DWEQ',
  },
  {
    value: 'BSMT',
    label: 'BSMT,  Invesco BulletShares 2029 Municipal Bond ETF',
    name: 'Invesco BulletShares 2029 Municipal Bond ETF',
    symbol: 'BSMT',
  },
  {
    value: 'BSMR',
    label: 'BSMR,  Invesco BulletShares (R) 2027 Municipal Bond ETF',
    name: 'Invesco BulletShares (R) 2027 Municipal Bond ETF',
    symbol: 'BSMR',
  },
  {
    value: 'BSMQ',
    label: 'BSMQ,  Invesco BulletShares (R) 2026 Municipal Bond ETF',
    name: 'Invesco BulletShares (R) 2026 Municipal Bond ETF',
    symbol: 'BSMQ',
  },
  {
    value: 'DWUS',
    label: 'DWUS,  AdvisorShares Dorsey Wright FSM US Core ETF',
    name: 'AdvisorShares Dorsey Wright FSM US Core ETF',
    symbol: 'DWUS',
  },
  {
    value: 'DWAW',
    label: 'DWAW,  AdvisorShares Dorsey Wright FSM All Cap World ETF',
    name: 'AdvisorShares Dorsey Wright FSM All Cap World ETF',
    symbol: 'DWAW',
  },
  {
    value: 'RILYO',
    label: 'RILYO,  B. Riley Financial, Inc. - 6.75',
    name: 'B. Riley Financial, Inc. - 6.75',
    symbol: 'RILYO',
  },
  {
    value: 'ADNWW',
    label: 'ADNWW,  Advent Technologies Holdings, Inc.',
    name: 'Advent Technologies Holdings, Inc.',
    symbol: 'ADNWW',
  },
  {
    value: 'SCCB',
    label: 'SCCB,  378',
    name: '378',
    symbol: 'SCCB',
  },
  {
    value: 'LTSK',
    label: 'LTSK,  LADENBURG THALMANN FINL SERVS I',
    name: 'LADENBURG THALMANN FINL SERVS I',
    symbol: 'LTSK',
  },
  {
    value: 'LTSF',
    label: 'LTSF,  Ladenburg Thalmann Financial Services Inc. 7.00% SN NT 28',
    name: 'Ladenburg Thalmann Financial Services Inc. 7.00% SN NT 28',
    symbol: 'LTSF',
  },
  {
    value: 'LTSH',
    label: 'LTSH,  LADENBURG THALMANN FINL SERVS I',
    name: 'LADENBURG THALMANN FINL SERVS I',
    symbol: 'LTSH',
  },
  {
    value: 'KLR',
    label: 'KLR,  Kaleyra, Inc.',
    name: 'Kaleyra, Inc.',
    symbol: 'KLR',
  },
  {
    value: 'SACC',
    label: 'SACC,  Sachem Capital Corp. 6.875% Not',
    name: 'Sachem Capital Corp. 6.875% Not',
    symbol: 'SACC',
  },
  {
    value: 'EQX',
    label: 'EQX,  Equinox Gold Corp.',
    name: 'Equinox Gold Corp.',
    symbol: 'EQX',
  },
  {
    value: 'BRBS',
    label: 'BRBS,  Blue Ridge Bankshares, Inc.',
    name: 'Blue Ridge Bankshares, Inc.',
    symbol: 'BRBS',
  },
  {
    value: 'PABSB.PA',
    label: 'PABSB.PA,  Parrot SA',
    name: 'Parrot SA',
    symbol: 'PABSB.PA',
  },
  {
    value: 'ALVIA.PA',
    label: 'ALVIA.PA,  Vialife SA',
    name: 'Vialife SA',
    symbol: 'ALVIA.PA',
  },
  {
    value: 'VVY.AS',
    label: 'VVY.AS,  Vivoryon Therapeutics N.V.',
    name: 'Vivoryon Therapeutics N.V.',
    symbol: 'VVY.AS',
  },
  {
    value: 'VTA.AS',
    label: 'VTA.AS,  Volta Finance Limited',
    name: 'Volta Finance Limited',
    symbol: 'VTA.AS',
  },
  {
    value: 'VMGBS.PA',
    label: 'VMGBS.PA,  Visiomed Group',
    name: 'Visiomed Group',
    symbol: 'VMGBS.PA',
  },
  {
    value: 'TFG.AS',
    label: 'TFG.AS,  Tetragon Financial Group Limited',
    name: 'Tetragon Financial Group Limited',
    symbol: 'TFG.AS',
  },
  {
    value: 'ALDOL.PA',
    label: 'ALDOL.PA,  Dolfines SA',
    name: 'Dolfines SA',
    symbol: 'ALDOL.PA',
  },
  {
    value: 'ALEMV.PA',
    label: 'ALEMV.PA,  Emova Group SA',
    name: 'Emova Group SA',
    symbol: 'ALEMV.PA',
  },
  {
    value: 'PABSA.PA',
    label: 'PABSA.PA,  Parrot SA',
    name: 'Parrot SA',
    symbol: 'PABSA.PA',
  },
  {
    value: 'MLJSA.PA',
    label: 'MLJSA.PA,  Jsa Technology Société anonyme',
    name: 'Jsa Technology Société anonyme',
    symbol: 'MLJSA.PA',
  },
  {
    value: 'PROBT.PA',
    label: 'PROBT.PA,  Prologue SA',
    name: 'Prologue SA',
    symbol: 'PROBT.PA',
  },
  {
    value: 'HUNDP.AS',
    label: 'HUNDP.AS,  Hunter Douglas NV',
    name: 'Hunter Douglas NV',
    symbol: 'HUNDP.AS',
  },
  {
    value: 'MLLOI.PA',
    label: 'MLLOI.PA,  Locasystem International SA',
    name: 'Locasystem International SA',
    symbol: 'MLLOI.PA',
  },
  {
    value: 'MLDAM.PA',
    label: 'MLDAM.PA,  Damaris SA',
    name: 'Damaris SA',
    symbol: 'MLDAM.PA',
  },
  {
    value: 'LTA.PA',
    label: 'LTA.PA,  Altamir SCA',
    name: 'Altamir SCA',
    symbol: 'LTA.PA',
  },
  {
    value: 'CGGBT.PA',
    label: 'CGGBT.PA,  CGG',
    name: 'CGG',
    symbol: 'CGGBT.PA',
  },
  {
    value: 'ABNX.PA',
    label: 'ABNX.PA,  ABIONYX Pharma SA',
    name: 'ABIONYX Pharma SA',
    symbol: 'ABNX.PA',
  },
  {
    value: 'ARTE.PA',
    label: 'ARTE.PA,  Artea SA',
    name: 'Artea SA',
    symbol: 'ARTE.PA',
  },
  {
    value: 'HXT.TO',
    label: 'HXT.TO,  Horizons S&P/TSX 60 Index ETF',
    name: 'Horizons S&P/TSX 60 Index ETF',
    symbol: 'HXT.TO',
  },
  {
    value: 'CGI.TO',
    label: 'CGI.TO,  Canadian General Investments, Limited',
    name: 'Canadian General Investments, Limited',
    symbol: 'CGI.TO',
  },
  {
    value: 'HQD.TO',
    label: 'HQD.TO,  BetaPro NASDAQ-100 -2x Daily Bear ETF',
    name: 'BetaPro NASDAQ-100 -2x Daily Bear ETF',
    symbol: 'HQD.TO',
  },
  {
    value: 'AYA.TO',
    label: 'AYA.TO,  Aya Gold & Silver Inc.',
    name: 'Aya Gold & Silver Inc.',
    symbol: 'AYA.TO',
  },
  {
    value: 'XUS.TO',
    label: 'XUS.TO,  iShares Core S&P 500 Index ETF',
    name: 'iShares Core S&P 500 Index ETF',
    symbol: 'XUS.TO',
  },
  {
    value: 'HXS.TO',
    label: 'HXS.TO,  Horizons S&P 500 Index ETF',
    name: 'Horizons S&P 500 Index ETF',
    symbol: 'HXS.TO',
  },
  {
    value: 'DML.TO',
    label: 'DML.TO,  Denison Mines Corp.',
    name: 'Denison Mines Corp.',
    symbol: 'DML.TO',
  },
  {
    value: 'FIG.TO',
    label: 'FIG.TO,  CI Investment Grade Bond ETF',
    name: 'CI Investment Grade Bond ETF',
    symbol: 'FIG.TO',
  },
  {
    value: 'XEF.TO',
    label: 'XEF.TO,  iShares Core MSCI EAFE IMI Index ETF',
    name: 'iShares Core MSCI EAFE IMI Index ETF',
    symbol: 'XEF.TO',
  },
  {
    value: 'XTG.TO',
    label: 'XTG.TO,  Xtra-Gold Resources Corp.',
    name: 'Xtra-Gold Resources Corp.',
    symbol: 'XTG.TO',
  },
  {
    value: 'MNT.TO',
    label: 'MNT.TO,  Royal Canadian Mint - Canadian Gold Reserves',
    name: 'Royal Canadian Mint - Canadian Gold Reserves',
    symbol: 'MNT.TO',
  },
  {
    value: 'MNS.TO',
    label: 'MNS.TO,  Royal Canadian Mint - Canadian Silver Reserves',
    name: 'Royal Canadian Mint - Canadian Silver Reserves',
    symbol: 'MNS.TO',
  },
  {
    value: 'RXE.TO',
    label: 'RXE.TO,  RBC Quant Emerging Markets Equity Leaders ETF',
    name: 'RBC Quant Emerging Markets Equity Leaders ETF',
    symbol: 'RXE.TO',
  },
  {
    value: 'NOA.TO',
    label: 'NOA.TO,  North American Construction Group Ltd.',
    name: 'North American Construction Group Ltd.',
    symbol: 'NOA.TO',
  },
  {
    value: 'SBT-B.TO',
    label: 'SBT-B.TO,  Silver Bullion Trust ETF Non-Currency Hedged',
    name: 'Silver Bullion Trust ETF Non-Currency Hedged',
    symbol: 'SBT-B.TO',
  },
  {
    value: 'CLFFX',
    label: 'CLFFX,  Clifford Capital Partners Fund Investor Class',
    name: 'Clifford Capital Partners Fund Investor Class',
    symbol: 'CLFFX',
  },
  {
    value: 'HBLRX',
    label: 'HBLRX,  The Hartford Balanced Income Fund',
    name: 'The Hartford Balanced Income Fund',
    symbol: 'HBLRX',
  },
  {
    value: 'FFTHX',
    label: 'FFTHX,  Fidelity Freedom 2035 Fund',
    name: 'Fidelity Freedom 2035 Fund',
    symbol: 'FFTHX',
  },
  {
    value: 'HBLTX',
    label: 'HBLTX,  The Hartford Balanced Income Fund Class R5',
    name: 'The Hartford Balanced Income Fund Class R5',
    symbol: 'HBLTX',
  },
  {
    value: 'HBLVX',
    label: 'HBLVX,  The Hartford Balanced Income Fund Class R6',
    name: 'The Hartford Balanced Income Fund Class R6',
    symbol: 'HBLVX',
  },
  {
    value: 'MTILX',
    label:
      'MTILX,  Morgan Stanley Institutional Fund, Inc. Global Infrastructure Portfolio Class L',
    name: 'Morgan Stanley Institutional Fund, Inc. Global Infrastructure Portfolio Class L',
    symbol: 'MTILX',
  },
  {
    value: 'JLVZX',
    label: 'JLVZX,  JPMorgan Large Cap Value Fund',
    name: 'JPMorgan Large Cap Value Fund',
    symbol: 'JLVZX',
  },
  {
    value: 'BDSCX',
    label: 'BDSCX,  BlackRock Advantage Small Cap Core Fund Investor C Shares',
    name: 'BlackRock Advantage Small Cap Core Fund Investor C Shares',
    symbol: 'BDSCX',
  },
  {
    value: 'LMIYX',
    label: 'LMIYX,  Lord Abbett Micro Cap Growth Fund',
    name: 'Lord Abbett Micro Cap Growth Fund',
    symbol: 'LMIYX',
  },
  {
    value: 'OLVAX',
    label: 'OLVAX,  JPMorgan Large Cap Value Fund Class A',
    name: 'JPMorgan Large Cap Value Fund Class A',
    symbol: 'OLVAX',
  },
  {
    value: 'DCINX',
    label: 'DCINX,  Dunham International Stock Fund Class C',
    name: 'Dunham International Stock Fund Class C',
    symbol: 'DCINX',
  },
  {
    value: 'MSGTX',
    label:
      'MSGTX,  Morgan Stanley Institutional Fund, Inc. Global Infrastructure Portfolio',
    name: 'Morgan Stanley Institutional Fund, Inc. Global Infrastructure Portfolio',
    symbol: 'MSGTX',
  },
  {
    value: 'HILRX',
    label: 'HILRX,  Hartford International Value Fund',
    name: 'Hartford International Value Fund',
    symbol: 'HILRX',
  },
  {
    value: 'SWFCX',
    label: 'SWFCX,  Touchstone International Equity Fund Class C',
    name: 'Touchstone International Equity Fund Class C',
    symbol: 'SWFCX',
  },
  {
    value: 'HLQVX',
    label: 'HLQVX,  JPMorgan Large Cap Value Fund Class I',
    name: 'JPMorgan Large Cap Value Fund Class I',
    symbol: 'HLQVX',
  },
  {
    value: 'DRNYX',
    label: 'DRNYX,  BNY Mellon New York Tax Exempt Bond Fund, Inc.',
    name: 'BNY Mellon New York Tax Exempt Bond Fund, Inc.',
    symbol: 'DRNYX',
  },
  {
    value: 'FNMIX',
    label: 'FNMIX,  Fidelity Advisor New Markets Income Fund',
    name: 'Fidelity Advisor New Markets Income Fund',
    symbol: 'FNMIX',
  },
  {
    value: 'JLVMX',
    label: 'JLVMX,  JPMorgan Large Cap Value Fund Class R6',
    name: 'JPMorgan Large Cap Value Fund Class R6',
    symbol: 'JLVMX',
  },
  {
    value: 'AMCCX',
    label: 'AMCCX,  American Beacon Mid-Cap Value Fund',
    name: 'American Beacon Mid-Cap Value Fund',
    symbol: 'AMCCX',
  },
  {
    value: 'SFECX',
    label: 'SFECX,  Guggenheim StylePlus - Large Core Fund',
    name: 'Guggenheim StylePlus - Large Core Fund',
    symbol: 'SFECX',
  },
  {
    value: 'NSEPX',
    label: 'NSEPX,  Columbia Select Large Cap Equity Fund',
    name: 'Columbia Select Large Cap Equity Fund',
    symbol: 'NSEPX',
  },
  {
    value: 'EIPIX',
    label: 'EIPIX,  EIP Growth and Income Fund (NEW)',
    name: 'EIP Growth and Income Fund (NEW)',
    symbol: 'EIPIX',
  },
  {
    value: 'EIPFX',
    label: 'EIPFX,  EIP Growth and Income Fund Investor Class',
    name: 'EIP Growth and Income Fund Investor Class',
    symbol: 'EIPFX',
  },
  {
    value: 'NSGAX',
    label: 'NSGAX,  Columbia Select Large Cap Equity Fund',
    name: 'Columbia Select Large Cap Equity Fund',
    symbol: 'NSGAX',
  },
  {
    value: 'ILLLX',
    label: 'ILLLX,  Transamerica Capital Growth Fund',
    name: 'Transamerica Capital Growth Fund',
    symbol: 'ILLLX',
  },
  {
    value: 'GEDIX',
    label: 'GEDIX,  GMO Trust - GMO Emerging Domestic Opportunities Fund',
    name: 'GMO Trust - GMO Emerging Domestic Opportunities Fund',
    symbol: 'GEDIX',
  },
  {
    value: 'GSMJX',
    label: 'GSMJX,  GMO Trust - GMO SGM Major Markets Fund',
    name: 'GMO Trust - GMO SGM Major Markets Fund',
    symbol: 'GSMJX',
  },
  {
    value: 'IBMN',
    label: 'IBMN,  iShares iBonds Dec 2025 Term Muni Bond ETF',
    name: 'iShares iBonds Dec 2025 Term Muni Bond ETF',
    symbol: 'IBMN',
  },
  {
    value: 'IBMP',
    label: 'IBMP,  iShares iBonds Dec 2027 Term Muni Bond ETF',
    name: 'iShares iBonds Dec 2027 Term Muni Bond ETF',
    symbol: 'IBMP',
  },
  {
    value: 'IBMQ',
    label: 'IBMQ,  iShares iBonds Dec 2028 Term Muni Bond ETF',
    name: 'iShares iBonds Dec 2028 Term Muni Bond ETF',
    symbol: 'IBMQ',
  },
  {
    value: 'CBECX',
    label: 'CBECX,  Allspring C&B Large Cap Value Fund',
    name: 'Allspring C&B Large Cap Value Fund',
    symbol: 'CBECX',
  },
  {
    value: 'ADME',
    label: 'ADME,  Aptus Drawdown Managed Equity ETF',
    name: 'Aptus Drawdown Managed Equity ETF',
    symbol: 'ADME',
  },
  {
    value: 'ACIO',
    label: 'ACIO,  Aptus Collared Income Opportunity ETF',
    name: 'Aptus Collared Income Opportunity ETF',
    symbol: 'ACIO',
  },
  {
    value: 'AFIF',
    label: 'AFIF,  Anfield Universal Fixed Income ETF',
    name: 'Anfield Universal Fixed Income ETF',
    symbol: 'AFIF',
  },
  {
    value: 'AESR',
    label: 'AESR,  Anfield U.S. Equity Sector Rotation ETF',
    name: 'Anfield U.S. Equity Sector Rotation ETF',
    symbol: 'AESR',
  },
  {
    value: 'ACES',
    label: 'ACES,  ALPS Clean Energy ETF',
    name: 'ALPS Clean Energy ETF',
    symbol: 'ACES',
  },
  {
    value: 'STARX',
    label: 'STARX,  Astor Sector Allocation Fund Class I',
    name: 'Astor Sector Allocation Fund Class I',
    symbol: 'STARX',
  },
  {
    value: 'TDEYX',
    label: 'TDEYX,  Touchstone Anti-Benchmark US Core Equity Fund',
    name: 'Touchstone Anti-Benchmark US Core Equity Fund',
    symbol: 'TDEYX',
  },
  {
    value: 'COTZX',
    label: 'COTZX,  Columbia Thermostat Fund',
    name: 'Columbia Thermostat Fund',
    symbol: 'COTZX',
  },
  {
    value: 'MCMVX',
    label: 'MCMVX,  Monongahela All Cap Value Fund',
    name: 'Monongahela All Cap Value Fund',
    symbol: 'MCMVX',
  },
  {
    value: 'NCVLX',
    label: 'NCVLX,  Nuance Concentrated Value Fund Institutional Class',
    name: 'Nuance Concentrated Value Fund Institutional Class',
    symbol: 'NCVLX',
  },
  {
    value: 'CBLSX',
    label: 'CBLSX,  Allspring C&B Large Cap Value Fund',
    name: 'Allspring C&B Large Cap Value Fund',
    symbol: 'CBLSX',
  },
  {
    value: 'TDELX',
    label: 'TDELX,  Touchstone Anti-Benchmark US Core Equity Fund',
    name: 'Touchstone Anti-Benchmark US Core Equity Fund',
    symbol: 'TDELX',
  },
  {
    value: 'CBLLX',
    label: 'CBLLX,  Allspring C&B Large Cap Value Fund',
    name: 'Allspring C&B Large Cap Value Fund',
    symbol: 'CBLLX',
  },
  {
    value: 'TDEAX',
    label: 'TDEAX,  Touchstone Anti-Benchmark US Core Equity Fund - A',
    name: 'Touchstone Anti-Benchmark US Core Equity Fund - A',
    symbol: 'TDEAX',
  },
  {
    value: 'PNOV',
    label: 'PNOV,  Innovator U.S. Equity Power Buffer ETF - November',
    name: 'Innovator U.S. Equity Power Buffer ETF - November',
    symbol: 'PNOV',
  },
  {
    value: 'IFRA',
    label: 'IFRA,  iShares U.S. Infrastructure ETF',
    name: 'iShares U.S. Infrastructure ETF',
    symbol: 'IFRA',
  },
  {
    value: 'ESGV',
    label: 'ESGV,  Vanguard ESG U.S. Stock ETF',
    name: 'Vanguard ESG U.S. Stock ETF',
    symbol: 'ESGV',
  },
  {
    value: 'PJUL',
    label: 'PJUL,  Innovator S&P 500 Power Buffer ETF — July',
    name: 'Innovator S&P 500 Power Buffer ETF — July',
    symbol: 'PJUL',
  },
  {
    value: 'PJUN',
    label: 'PJUN,  Innovator S&P 500 Power Buffer ETF - New',
    name: 'Innovator S&P 500 Power Buffer ETF - New',
    symbol: 'PJUN',
  },
  {
    value: 'UAUG',
    label: 'UAUG,  Innovator U.S. Equity Ultra Buffer ETF - August',
    name: 'Innovator U.S. Equity Ultra Buffer ETF - August',
    symbol: 'UAUG',
  },
  {
    value: 'VSGX',
    label: 'VSGX,  Vanguard ESG International Stock ETF',
    name: 'Vanguard ESG International Stock ETF',
    symbol: 'VSGX',
  },
  {
    value: 'IBMO',
    label: 'IBMO,  iShares iBonds Dec 2026 Term Muni Bond ETF',
    name: 'iShares iBonds Dec 2026 Term Muni Bond ETF',
    symbol: 'IBMO',
  },
  {
    value: 'IBMM',
    label: 'IBMM,  iShares iBonds Dec 2024 Term Muni Bond ETF',
    name: 'iShares iBonds Dec 2024 Term Muni Bond ETF',
    symbol: 'IBMM',
  },
  {
    value: 'PAUG',
    label: 'PAUG,  Innovator U.S. Equity Power Buffer ETF - August',
    name: 'Innovator U.S. Equity Power Buffer ETF - August',
    symbol: 'PAUG',
  },
  {
    value: 'UNOV',
    label: 'UNOV,  Innovator U.S. Equity Ultra Buffer ETF - November',
    name: 'Innovator U.S. Equity Ultra Buffer ETF - November',
    symbol: 'UNOV',
  },
  {
    value: 'BBAX',
    label: 'BBAX,  JPMorgan BetaBuilders Developed Asia Pacific ex-Japan ETF',
    name: 'JPMorgan BetaBuilders Developed Asia Pacific ex-Japan ETF',
    symbol: 'BBAX',
  },
  {
    value: 'BJAN',
    label: 'BJAN,  Innovator U.S. Equity Buffer ETF - January',
    name: 'Innovator U.S. Equity Buffer ETF - January',
    symbol: 'BJAN',
  },
  {
    value: 'FLHY',
    label: 'FLHY,  Franklin High Yield Corporate ETF',
    name: 'Franklin High Yield Corporate ETF',
    symbol: 'FLHY',
  },
  {
    value: 'FLIA',
    label: 'FLIA,  Franklin International Aggregate Bond ETF',
    name: 'Franklin International Aggregate Bond ETF',
    symbol: 'FLIA',
  },
  {
    value: 'BDEC',
    label: 'BDEC,  Innovator S&P 500 Buffer ETF - December',
    name: 'Innovator S&P 500 Buffer ETF - December',
    symbol: 'BDEC',
  },
  {
    value: 'BBCA',
    label: 'BBCA,  JPMorgan BetaBuilders Canada ETF',
    name: 'JPMorgan BetaBuilders Canada ETF',
    symbol: 'BBCA',
  },
  {
    value: 'IEDI',
    label: 'IEDI,  iShares Evolved U.S. Discretionary Spending ETF',
    name: 'iShares Evolved U.S. Discretionary Spending ETF',
    symbol: 'IEDI',
  },
  {
    value: 'FNOV',
    label: 'FNOV,  FT Cboe Vest U.S. Equity Buffer ETF - November',
    name: 'FT Cboe Vest U.S. Equity Buffer ETF - November',
    symbol: 'FNOV',
  },
  {
    value: 'BBSA',
    label: 'BBSA,  JPMorgan BetaBuilders 1-5 Year U.S. Aggregate Bond ETF',
    name: 'JPMorgan BetaBuilders 1-5 Year U.S. Aggregate Bond ETF',
    symbol: 'BBSA',
  },
  {
    value: 'POCT',
    label: 'POCT,  Innovator S&P 500 Power Buffer ETF - October',
    name: 'Innovator S&P 500 Power Buffer ETF - October',
    symbol: 'POCT',
  },
  {
    value: 'BAPR',
    label: 'BAPR,  Innovator S&P 500 Buffer ETF - April New',
    name: 'Innovator S&P 500 Buffer ETF - April New',
    symbol: 'BAPR',
  },
  {
    value: 'DURA',
    label: 'DURA,  VanEck Durable High Dividend ETF',
    name: 'VanEck Durable High Dividend ETF',
    symbol: 'DURA',
  },
  {
    value: 'BSEP',
    label: 'BSEP,  Innovator U.S. Equity Buffer ETF - September',
    name: 'Innovator U.S. Equity Buffer ETF - September',
    symbol: 'BSEP',
  },
  {
    value: 'IETC',
    label: 'IETC,  iShares Evolved U.S. Technology ETF',
    name: 'iShares Evolved U.S. Technology ETF',
    symbol: 'IETC',
  },
  {
    value: 'HSRT',
    label: 'HSRT,  Hartford Short Duration ETF',
    name: 'Hartford Short Duration ETF',
    symbol: 'HSRT',
  },
  {
    value: 'FAUG',
    label: 'FAUG,  FT Cboe Vest U.S. Equity Buffer ETF - August',
    name: 'FT Cboe Vest U.S. Equity Buffer ETF - August',
    symbol: 'FAUG',
  },
  {
    value: 'PAWZ',
    label: 'PAWZ,  ProShares Pet Care ETF',
    name: 'ProShares Pet Care ETF',
    symbol: 'PAWZ',
  },
  {
    value: 'ROMO',
    label: 'ROMO,  Strategy Shares Newfound/ReSolve Robust Momentum ETF',
    name: 'Strategy Shares Newfound/ReSolve Robust Momentum ETF',
    symbol: 'ROMO',
  },
  {
    value: 'BJUL',
    label: 'BJUL,  Innovator U.S. Equity Buffer ETF - July',
    name: 'Innovator U.S. Equity Buffer ETF - July',
    symbol: 'BJUL',
  },
  {
    value: 'BBUS',
    label: 'BBUS,  JPMorgan BetaBuilders U.S. Equity ETF',
    name: 'JPMorgan BetaBuilders U.S. Equity ETF',
    symbol: 'BBUS',
  },
  {
    value: 'JMST',
    label: 'JMST,  JPMorgan Ultra-Short Municipal Income ETF',
    name: 'JPMorgan Ultra-Short Municipal Income ETF',
    symbol: 'JMST',
  },
  {
    value: 'JCPB',
    label: 'JCPB,  JPMorgan Core Plus Bond ETF',
    name: 'JPMorgan Core Plus Bond ETF',
    symbol: 'JCPB',
  },
  {
    value: 'UFEB',
    label: 'UFEB,  Innovator U.S. Equity Ultra Buffer ETF - February',
    name: 'Innovator U.S. Equity Ultra Buffer ETF - February',
    symbol: 'UFEB',
  },
  {
    value: 'GSST',
    label: 'GSST,  Goldman Sachs Access Ultra Short Bond ETF',
    name: 'Goldman Sachs Access Ultra Short Bond ETF',
    symbol: 'GSST',
  },
  {
    value: 'FRDM',
    label: 'FRDM,  Freedom 100 Emerging Markets ETF',
    name: 'Freedom 100 Emerging Markets ETF',
    symbol: 'FRDM',
  },
  {
    value: 'UOCT',
    label: 'UOCT,  Innovator S&P 500 Ultra Buffer ETF - October',
    name: 'Innovator S&P 500 Ultra Buffer ETF - October',
    symbol: 'UOCT',
  },
  {
    value: 'NJAN',
    label: 'NJAN,  Innovator Growth-100 Power Buffer ETF - January',
    name: 'Innovator Growth-100 Power Buffer ETF - January',
    symbol: 'NJAN',
  },
  {
    value: 'MAAX',
    label: 'MAAX,  VanEck Muni Allocation ETF',
    name: 'VanEck Muni Allocation ETF',
    symbol: 'MAAX',
  },
  {
    value: 'LQDI',
    label: 'LQDI,  iShares Inflation Hedged Corporate Bond ETF',
    name: 'iShares Inflation Hedged Corporate Bond ETF',
    symbol: 'LQDI',
  },
  {
    value: 'TMDV',
    label: 'TMDV,  ProShares Russell US Dividend Growers ETF',
    name: 'ProShares Russell US Dividend Growers ETF',
    symbol: 'TMDV',
  },
  {
    value: 'ESML',
    label: 'ESML,  iShares ESG Aware MSCI USA Small-Cap ETF',
    name: 'iShares ESG Aware MSCI USA Small-Cap ETF',
    symbol: 'ESML',
  },
  {
    value: 'BBEU',
    label: 'BBEU,  JPMorgan BetaBuilders Europe ETF',
    name: 'JPMorgan BetaBuilders Europe ETF',
    symbol: 'BBEU',
  },
  {
    value: 'GTIP',
    label: 'GTIP,  Goldman Sachs Access Inflation Protected USD Bond ETF',
    name: 'Goldman Sachs Access Inflation Protected USD Bond ETF',
    symbol: 'GTIP',
  },
  {
    value: 'KOCT',
    label: 'KOCT,  Innovator U.S. Small Cap Power Buffer ETF - October',
    name: 'Innovator U.S. Small Cap Power Buffer ETF - October',
    symbol: 'KOCT',
  },
  {
    value: 'BJUN',
    label: 'BJUN,  Innovator S&P 500 Buffer ETF - June',
    name: 'Innovator S&P 500 Buffer ETF - June',
    symbol: 'BJUN',
  },
  {
    value: 'BAUG',
    label: 'BAUG,  Innovator U.S. Equity Buffer ETF - August',
    name: 'Innovator U.S. Equity Buffer ETF - August',
    symbol: 'BAUG',
  },
  {
    value: 'BNOV',
    label: 'BNOV,  Innovator U.S. Equity Buffer ETF - November',
    name: 'Innovator U.S. Equity Buffer ETF - November',
    symbol: 'BNOV',
  },
  {
    value: 'JMUB',
    label: 'JMUB,  JPMorgan Municipal ETF',
    name: 'JPMorgan Municipal ETF',
    symbol: 'JMUB',
  },
  {
    value: 'TOKE',
    label: 'TOKE,  Cambria Cannabis ETF',
    name: 'Cambria Cannabis ETF',
    symbol: 'TOKE',
  },
  {
    value: 'PFEB',
    label: 'PFEB,  Innovator U.S. Equity Power Buffer ETF - February',
    name: 'Innovator U.S. Equity Power Buffer ETF - February',
    symbol: 'PFEB',
  },
  {
    value: 'KNG',
    label: 'KNG,  FT Cboe Vest S&P 500 Dividend Aristocrats Target Income ETF',
    name: 'FT Cboe Vest S&P 500 Dividend Aristocrats Target Income ETF',
    symbol: 'KNG',
  },
  {
    value: 'DRSK',
    label: 'DRSK,  Aptus Defined Risk ETF',
    name: 'Aptus Defined Risk ETF',
    symbol: 'DRSK',
  },
  {
    value: 'UDEC',
    label: 'UDEC,  Innovator S&P 500 Ultra Buffer ETF - December',
    name: 'Innovator S&P 500 Ultra Buffer ETF - December',
    symbol: 'UDEC',
  },
  {
    value: 'FCPI',
    label: 'FCPI,  Fidelity Stocks for Inflation ETF',
    name: 'Fidelity Stocks for Inflation ETF',
    symbol: 'FCPI',
  },
  {
    value: 'RODE',
    label: 'RODE,  Hartford Multifactor Diversified International ETF',
    name: 'Hartford Multifactor Diversified International ETF',
    symbol: 'RODE',
  },
  {
    value: 'NULC',
    label: 'NULC,  Nuveen ESG Large-Cap ETF',
    name: 'Nuveen ESG Large-Cap ETF',
    symbol: 'NULC',
  },
  {
    value: 'BOCT',
    label: 'BOCT,  Innovator S&P 500 Buffer ETF - October',
    name: 'Innovator S&P 500 Buffer ETF - October',
    symbol: 'BOCT',
  },
  {
    value: 'BBIN',
    label: 'BBIN,  JPMorgan BetaBuilders International Equity ETF',
    name: 'JPMorgan BetaBuilders International Equity ETF',
    symbol: 'BBIN',
  },
  {
    value: 'PJAN',
    label: 'PJAN,  Innovator U.S. Equity Power Buffer ETF - January',
    name: 'Innovator U.S. Equity Power Buffer ETF - January',
    symbol: 'PJAN',
  },
  {
    value: 'STLV',
    label: 'STLV,  iShares Factors US Value Style ETF',
    name: 'iShares Factors US Value Style ETF',
    symbol: 'STLV',
  },
  {
    value: 'PAPR',
    label: 'PAPR,  Innovator S&P 500 Power Buffer ETF - April New',
    name: 'Innovator S&P 500 Power Buffer ETF - April New',
    symbol: 'PAPR',
  },
  {
    value: 'BBJP',
    label: 'BBJP,  JPMorgan BetaBuilders Japan ETF',
    name: 'JPMorgan BetaBuilders Japan ETF',
    symbol: 'BBJP',
  },
  {
    value: 'TRTY',
    label: 'TRTY,  Cambria Trinity ETF',
    name: 'Cambria Trinity ETF',
    symbol: 'TRTY',
  },
  {
    value: 'UJAN',
    label: 'UJAN,  Innovator U.S. Equity Ultra Buffer ETF - January',
    name: 'Innovator U.S. Equity Ultra Buffer ETF - January',
    symbol: 'UJAN',
  },
  {
    value: 'UAPR',
    label: 'UAPR,  Innovator S&P 500 Ultra Buffer ETF - April New',
    name: 'Innovator S&P 500 Ultra Buffer ETF - April New',
    symbol: 'UAPR',
  },
  {
    value: 'FLBL',
    label: 'FLBL,  Franklin Senior Loan ETF',
    name: 'Franklin Senior Loan ETF',
    symbol: 'FLBL',
  },
  {
    value: 'PDEC',
    label: 'PDEC,  Innovator U.S. Equity Power Buffer ETF - December',
    name: 'Innovator U.S. Equity Power Buffer ETF - December',
    symbol: 'PDEC',
  },
  {
    value: 'STLG',
    label: 'STLG,  iShares Factors US Growth Style ETF',
    name: 'iShares Factors US Growth Style ETF',
    symbol: 'STLG',
  },
  {
    value: 'FDEM',
    label: 'FDEM,  Fidelity Emerging Markets Multifactor ETF',
    name: 'Fidelity Emerging Markets Multifactor ETF',
    symbol: 'FDEM',
  },
  {
    value: 'UJUN',
    label: 'UJUN,  Innovator S&P 500 Ultra Buffer ETF - June',
    name: 'Innovator S&P 500 Ultra Buffer ETF - June',
    symbol: 'UJUN',
  },
  {
    value: 'PSEP',
    label: 'PSEP,  Innovator U.S. Equity Power Buffer ETF - September',
    name: 'Innovator U.S. Equity Power Buffer ETF - September',
    symbol: 'PSEP',
  },
  {
    value: 'UJUL',
    label: 'UJUL,  Innovator S&P 500 Ultra Buffer ETF — July',
    name: 'Innovator S&P 500 Ultra Buffer ETF — July',
    symbol: 'UJUL',
  },
  {
    value: 'FDEV',
    label: 'FDEV,  Fidelity International Multifactor ETF',
    name: 'Fidelity International Multifactor ETF',
    symbol: 'FDEV',
  },
  {
    value: 'DAUG',
    label: 'DAUG,  FT Cboe Vest U.S. Equity Deep Buffer ETF - August',
    name: 'FT Cboe Vest U.S. Equity Deep Buffer ETF - August',
    symbol: 'DAUG',
  },
  {
    value: 'DNOV',
    label: 'DNOV,  FT Cboe Vest U.S. Equity Deep Buffer ETF - November',
    name: 'FT Cboe Vest U.S. Equity Deep Buffer ETF - November',
    symbol: 'DNOV',
  },
  {
    value: 'NOCT',
    label: 'NOCT,  Innovator Growth-100 Power Buffer ETF - October',
    name: 'Innovator Growth-100 Power Buffer ETF - October',
    symbol: 'NOCT',
  },
  {
    value: 'RYLD',
    label: 'RYLD,  Global X Russell 2000 Covered Call ETF',
    name: 'Global X Russell 2000 Covered Call ETF',
    symbol: 'RYLD',
  },
  {
    value: 'TDV',
    label: 'TDV,  ProShares S&P Technology Dividend Aristocrats ETF',
    name: 'ProShares S&P Technology Dividend Aristocrats ETF',
    symbol: 'TDV',
  },
  {
    value: 'USEP',
    label: 'USEP,  Innovator U.S. Equity Ultra Buffer ETF - September',
    name: 'Innovator U.S. Equity Ultra Buffer ETF - September',
    symbol: 'USEP',
  },
  {
    value: 'KJAN',
    label: 'KJAN,  Innovator U.S. Small Cap Power Buffer ETF - January',
    name: 'Innovator U.S. Small Cap Power Buffer ETF - January',
    symbol: 'KJAN',
  },
  {
    value: 'IBHB',
    label: 'IBHB,  iShares iBonds 2022 Term High Yield and Income ETF',
    name: 'iShares iBonds 2022 Term High Yield and Income ETF',
    symbol: 'IBHB',
  },
  {
    value: 'FLDR',
    label: 'FLDR,  Fidelity Low Duration Bond Factor ETF',
    name: 'Fidelity Low Duration Bond Factor ETF',
    symbol: 'FLDR',
  },
  {
    value: 'IBHE',
    label: 'IBHE,  iShares iBonds 2025 Term High Yield and Income ETF',
    name: 'iShares iBonds 2025 Term High Yield and Income ETF',
    symbol: 'IBHE',
  },
  {
    value: 'IBHC',
    label: 'IBHC,  iShares iBonds 2023 Term High Yield and Income ETF',
    name: 'iShares iBonds 2023 Term High Yield and Income ETF',
    symbol: 'IBHC',
  },
  {
    value: 'IBHD',
    label: 'IBHD,  iShares iBonds 2024 Term High Yield and Income ETF',
    name: 'iShares iBonds 2024 Term High Yield and Income ETF',
    symbol: 'IBHD',
  },
  {
    value: 'IAUF',
    label: 'IAUF,  iShares Gold Strategy ETF',
    name: 'iShares Gold Strategy ETF',
    symbol: 'IAUF',
  },
  {
    value: 'FCTR',
    label: 'FCTR,  First Trust Lunt U.S. Factor Rotation ETF',
    name: 'First Trust Lunt U.S. Factor Rotation ETF',
    symbol: 'FCTR',
  },
  {
    value: 'BBRE',
    label: 'BBRE,  JPMorgan BetaBuilders MSCI U.S. REIT ETF',
    name: 'JPMorgan BetaBuilders MSCI U.S. REIT ETF',
    symbol: 'BBRE',
  },
  {
    value: 'BFEB',
    label: 'BFEB,  Innovator S&P 500 Buffer ETF - February',
    name: 'Innovator S&P 500 Buffer ETF - February',
    symbol: 'BFEB',
  },
  {
    value: 'HCXY',
    label: 'HCXY,  Hercules Capital, Inc.',
    name: 'Hercules Capital, Inc.',
    symbol: 'HCXY',
  },
  {
    value: 'NTEST',
    label: 'NTEST,  Tick Pilot Test Sym-Control',
    name: 'Tick Pilot Test Sym-Control',
    symbol: 'NTEST',
  },
  {
    value: 'ENBA',
    label: 'ENBA,  Enbridge Inc. 6.375 SNT18 B 78',
    name: 'Enbridge Inc. 6.375 SNT18 B 78',
    symbol: 'ENBA',
  },
  {
    value: 'NRUC',
    label: 'NRUC,  National Rural Utilities Cooper',
    name: 'National Rural Utilities Cooper',
    symbol: 'NRUC',
  },
  {
    value: 'PRS',
    label: 'PRS,  Prudential Financial, Inc. 5.62',
    name: 'Prudential Financial, Inc. 5.62',
    symbol: 'PRS',
  },
  {
    value: 'SREA',
    label: 'SREA,  SEMPRA ENERGY',
    name: 'SEMPRA ENERGY',
    symbol: 'SREA',
  },
  {
    value: 'AWAY',
    label: 'AWAY,  ETFMG Travel Tech ETF',
    name: 'ETFMG Travel Tech ETF',
    symbol: 'AWAY',
  },
  {
    value: 'CHIC',
    label: 'CHIC,  Global X MSCI China Communication Services ETF',
    name: 'Global X MSCI China Communication Services ETF',
    symbol: 'CHIC',
  },
  {
    value: 'AFGC',
    label: 'AFGC,  American Financial Group, Inc.',
    name: 'American Financial Group, Inc.',
    symbol: 'AFGC',
  },
  {
    value: 'AEFC',
    label: 'AEFC,  Aegon Funding Company LLC',
    name: 'Aegon Funding Company LLC',
    symbol: 'AEFC',
  },
  {
    value: 'AVEM',
    label: 'AVEM,  Avantis Emerging Markets Equity ETF',
    name: 'Avantis Emerging Markets Equity ETF',
    symbol: 'AVEM',
  },
  {
    value: 'AVUV',
    label: 'AVUV,  Avantis U.S. Small Cap Value ETF',
    name: 'Avantis U.S. Small Cap Value ETF',
    symbol: 'AVUV',
  },
  {
    value: 'AVUS',
    label: 'AVUS,  Avantis U.S. Equity ETF',
    name: 'Avantis U.S. Equity ETF',
    symbol: 'AVUS',
  },
  {
    value: 'AQNB',
    label: 'AQNB,  Algonquin Power & Utilities Cor',
    name: 'Algonquin Power & Utilities Cor',
    symbol: 'AQNB',
  },
  {
    value: 'AQNA',
    label: 'AQNA,  Algonquin Power & Utilities Cor',
    name: 'Algonquin Power & Utilities Cor',
    symbol: 'AQNA',
  },
  {
    value: 'BNKD',
    label:
      'BNKD,  MicroSectors U.S. Big Banks Index -3X Inverse Leveraged ETNs',
    name: 'MicroSectors U.S. Big Banks Index -3X Inverse Leveraged ETNs',
    symbol: 'BNKD',
  },
  {
    value: 'BATT',
    label: 'BATT,  Amplify Lithium & Battery Technology ETF',
    name: 'Amplify Lithium & Battery Technology ETF',
    symbol: 'BATT',
  },
  {
    value: 'AVDE',
    label: 'AVDE,  Avantis International Equity ETF',
    name: 'Avantis International Equity ETF',
    symbol: 'AVDE',
  },
  {
    value: 'CMSD',
    label: 'CMSD,  CMS Energy Corporation 5.875% J',
    name: 'CMS Energy Corporation 5.875% J',
    symbol: 'CMSD',
  },
  {
    value: 'CMSC',
    label: 'CMSC,  CMS Energy Corporation 5.875% J',
    name: 'CMS Energy Corporation 5.875% J',
    symbol: 'CMSC',
  },
  {
    value: 'UNMA',
    label: 'UNMA,  Unum Group 6.250% JR NT58',
    name: 'Unum Group 6.250% JR NT58',
    symbol: 'UNMA',
  },
  {
    value: 'ARGD',
    label: 'ARGD,  Argo Group US, Inc. 6.5% SR NTS 42',
    name: 'Argo Group US, Inc. 6.5% SR NTS 42',
    symbol: 'ARGD',
  },
  {
    value: 'CUBB',
    label: 'CUBB,  Customers Bancorp, Inc 5.375% S',
    name: 'Customers Bancorp, Inc 5.375% S',
    symbol: 'CUBB',
  },
  {
    value: 'EMMF',
    label: 'EMMF,  WisdomTree Emerging Markets Multifactor Fund',
    name: 'WisdomTree Emerging Markets Multifactor Fund',
    symbol: 'EMMF',
  },
  {
    value: 'DUKB',
    label: 'DUKB,  Duke Energy Corporation 5.625% ',
    name: 'Duke Energy Corporation 5.625% ',
    symbol: 'DUKB',
  },
  {
    value: 'ECCX',
    label: 'ECCX,  Eagle Point Credit Company Inc. 6.6875% NT 28',
    name: 'Eagle Point Credit Company Inc. 6.6875% NT 28',
    symbol: 'ECCX',
  },
  {
    value: 'MGR',
    label: 'MGR,  Affiliated Managers Group, Inc.',
    name: 'Affiliated Managers Group, Inc.',
    symbol: 'MGR',
  },
  {
    value: 'HOMZ',
    label: 'HOMZ,  The Hoya Capital Housing ETF',
    name: 'The Hoya Capital Housing ETF',
    symbol: 'HOMZ',
  },
  {
    value: 'TBC',
    label: 'TBC,  AT&T Inc. 5.625% Global Notes d',
    name: 'AT&T Inc. 5.625% Global Notes d',
    symbol: 'TBC',
  },
  {
    value: 'AFGE',
    label: 'AFGE,  American Financial Group, Inc.',
    name: 'American Financial Group, Inc.',
    symbol: 'AFGE',
  },
  {
    value: 'AFGB',
    label: 'AFGB,  American Financial Group, Inc. ',
    name: 'American Financial Group, Inc. ',
    symbol: 'AFGB',
  },
  {
    value: 'DYNF',
    label: 'DYNF,  BlackRock U.S. Equity Factor Rotation ETF',
    name: 'BlackRock U.S. Equity Factor Rotation ETF',
    symbol: 'DYNF',
  },
  {
    value: 'RCB',
    label: 'RCB,  Ready Capital Corporation',
    name: 'Ready Capital Corporation',
    symbol: 'RCB',
  },
  {
    value: 'RCA',
    label: 'RCA,  Ready Capital Corporation 7.00%',
    name: 'Ready Capital Corporation 7.00%',
    symbol: 'RCA',
  },
  {
    value: 'DWMF',
    label: 'DWMF,  WisdomTree International Multifactor Fund',
    name: 'WisdomTree International Multifactor Fund',
    symbol: 'DWMF',
  },
  {
    value: 'QVCD',
    label: 'QVCD,  QVC, Inc. 6.375% Senior Secured',
    name: 'QVC, Inc. 6.375% Senior Secured',
    symbol: 'QVCD',
  },
  {
    value: 'AIC',
    label: 'AIC,  Arlington Asset Investment Corp. 6.75% SR NT 25',
    name: 'Arlington Asset Investment Corp. 6.75% SR NT 25',
    symbol: 'AIC',
  },
  {
    value: 'DTP',
    label: 'DTP,  DTE Energy Co',
    name: 'DTE Energy Co',
    symbol: 'DTP',
  },
  {
    value: 'CNBS',
    label: 'CNBS,  Amplify Growth Opportunities ETF',
    name: 'Amplify Growth Opportunities ETF',
    symbol: 'CNBS',
  },
  {
    value: 'ELAT',
    label: 'ELAT,  Elanco Animal Health Inc',
    name: 'Elanco Animal Health Inc',
    symbol: 'ELAT',
  },
  {
    value: 'DJCB',
    label:
      'DJCB,  ETRACS Bloomberg Commodity Index Total ReturnSM ETN Series B',
    name: 'ETRACS Bloomberg Commodity Index Total ReturnSM ETN Series B',
    symbol: 'DJCB',
  },
  {
    value: 'HDLB',
    label:
      'HDLB,  ETRACS Monthly Pay 2xLeveraged US High Dividend Low Volatility ETN Series B',
    name: 'ETRACS Monthly Pay 2xLeveraged US High Dividend Low Volatility ETN Series B',
    symbol: 'HDLB',
  },
  {
    value: 'SMDY',
    label: 'SMDY,  Syntax Stratified MidCap',
    name: 'Syntax Stratified MidCap',
    symbol: 'SMDY',
  },
  {
    value: 'SFY',
    label: 'SFY,  SoFi Select 500 ETF',
    name: 'SoFi Select 500 ETF',
    symbol: 'SFY',
  },
  {
    value: 'SFYX',
    label: 'SFYX,  SoFi Next 500 ETF',
    name: 'SoFi Next 500 ETF',
    symbol: 'SFYX',
  },
  {
    value: 'SOJD',
    label: 'SOJD,  Southern Company (The) Series 2',
    name: 'Southern Company (The) Series 2',
    symbol: 'SOJD',
  },
  {
    value: 'OGIG',
    label: "OGIG,  ALPS O'Shares Global Internet Giants ETF Shares",
    name: "ALPS O'Shares Global Internet Giants ETF Shares",
    symbol: 'OGIG',
  },
  {
    value: 'NACP',
    label: 'NACP,  Impact Shares NAACP Minority Empowerment ETF',
    name: 'Impact Shares NAACP Minority Empowerment ETF',
    symbol: 'NACP',
  },
  {
    value: 'SPSK',
    label: 'SPSK,  SP Funds Dow Jones Global Sukuk ETF',
    name: 'SP Funds Dow Jones Global Sukuk ETF',
    symbol: 'SPSK',
  },
  {
    value: 'QVCC',
    label: 'QVCC,  QVC, Inc. 6.250% Senior Secured',
    name: 'QVC, Inc. 6.250% Senior Secured',
    symbol: 'QVCC',
  },
  {
    value: 'SPUS',
    label: 'SPUS,  SP Funds S&P 500 Sharia Industry Exclusions ETF',
    name: 'SP Funds S&P 500 Sharia Industry Exclusions ETF',
    symbol: 'SPUS',
  },
  {
    value: 'SJIJ',
    label: 'SJIJ,  South Jersey Industries, Inc',
    name: 'South Jersey Industries, Inc',
    symbol: 'SJIJ',
  },
  {
    value: 'RECS',
    label: 'RECS,  Columbia Research Enhanced Core ETF',
    name: 'Columbia Research Enhanced Core ETF',
    symbol: 'RECS',
  },
  {
    value: 'SSPY',
    label: 'SSPY,  Syntax Stratified LargeCap ETF',
    name: 'Syntax Stratified LargeCap ETF',
    symbol: 'SSPY',
  },
  {
    value: 'SWT',
    label: 'SWT,  Stanley Black & Decker Inc',
    name: 'Stanley Black & Decker Inc',
    symbol: 'SWT',
  },
  {
    value: 'YOLO',
    label: 'YOLO,  AdvisorShares Pure Cannabis ETF',
    name: 'AdvisorShares Pure Cannabis ETF',
    symbol: 'YOLO',
  },
  {
    value: 'BNKU',
    label: 'BNKU,  MicroSectors U.S. Big Banks Index 3X Leveraged ETNs',
    name: 'MicroSectors U.S. Big Banks Index 3X Leveraged ETNs',
    symbol: 'BNKU',
  },
  {
    value: 'ARMR',
    label: 'ARMR,  Armor US Equity Index ETF',
    name: 'Armor US Equity Index ETF',
    symbol: 'ARMR',
  },
  {
    value: 'DLY',
    label: 'DLY,  DoubleLine Yield Opportunities Fund',
    name: 'DoubleLine Yield Opportunities Fund',
    symbol: 'DLY',
  },
  {
    value: 'ATCO',
    label: 'ATCO,  Atlas Corp.',
    name: 'Atlas Corp.',
    symbol: 'ATCO',
  },
  {
    value: 'HDIV',
    label: 'HDIV,  QRAFT AI-Enhanced U.S. High Dividend ETF',
    name: 'QRAFT AI-Enhanced U.S. High Dividend ETF',
    symbol: 'HDIV',
  },
  {
    value: 'SBSW',
    label: 'SBSW,  Sibanye Stillwater Limited',
    name: 'Sibanye Stillwater Limited',
    symbol: 'SBSW',
  },
  {
    value: 'RESP',
    label: 'RESP,  WisdomTree U.S. ESG Fund',
    name: 'WisdomTree U.S. ESG Fund',
    symbol: 'RESP',
  },
  {
    value: 'TT',
    label: 'TT,  Trane Technologies plc',
    name: 'Trane Technologies plc',
    symbol: 'TT',
  },
  {
    value: 'ECOZ',
    label: 'ECOZ,  TrueShares ESG Active Opportunities ETF',
    name: 'TrueShares ESG Active Opportunities ETF',
    symbol: 'ECOZ',
  },
  {
    value: 'GFLU',
    label: 'GFLU,  GFL Environmental Inc.',
    name: 'GFL Environmental Inc.',
    symbol: 'GFLU',
  },
  {
    value: 'GFL',
    label: 'GFL,  GFL Environmental Inc.',
    name: 'GFL Environmental Inc.',
    symbol: 'GFL',
  },
  {
    value: 'LRNZ',
    label: 'LRNZ,  TrueShares Technology, AI and Deep Learning ETF',
    name: 'TrueShares Technology, AI and Deep Learning ETF',
    symbol: 'LRNZ',
  },
  {
    value: 'ALTG',
    label: 'ALTG,  Alta Equipment Group Inc.',
    name: 'Alta Equipment Group Inc.',
    symbol: 'ALTG',
  },
  {
    value: 'ZCMD',
    label: 'ZCMD,  Zhongchao Inc.',
    name: 'Zhongchao Inc.',
    symbol: 'ZCMD',
  },
  {
    value: 'SNUG',
    label: 'SNUG,  Alpha Architect Merlyn.AI Tactical Growth and Income ETF',
    name: 'Alpha Architect Merlyn.AI Tactical Growth and Income ETF',
    symbol: 'SNUG',
  },
  {
    value: 'ESSCU',
    label: 'ESSCU,  East Stone Acquisition Corporation',
    name: 'East Stone Acquisition Corporation',
    symbol: 'ESSCU',
  },
  {
    value: 'IBTB',
    label: 'IBTB,  iShares iBonds Dec 2022 Term Treasury ETF',
    name: 'iShares iBonds Dec 2022 Term Treasury ETF',
    symbol: 'IBTB',
  },
  {
    value: 'IBTF',
    label: 'IBTF,  iShares iBonds Dec 2025 Term Treasury ETF',
    name: 'iShares iBonds Dec 2025 Term Treasury ETF',
    symbol: 'IBTF',
  },
  {
    value: 'IBTI',
    label: 'IBTI,  iShares iBonds Dec 2028 Term Treasury ETF',
    name: 'iShares iBonds Dec 2028 Term Treasury ETF',
    symbol: 'IBTI',
  },
  {
    value: 'IBTJ',
    label: 'IBTJ,  iShares iBonds Dec 2029 Term Treasury ETF',
    name: 'iShares iBonds Dec 2029 Term Treasury ETF',
    symbol: 'IBTJ',
  },
  {
    value: 'LUMO',
    label: 'LUMO,  Lumos Pharma, Inc.',
    name: 'Lumos Pharma, Inc.',
    symbol: 'LUMO',
  },
  {
    value: 'GDYN',
    label: 'GDYN,  Grid Dynamics Holdings, Inc.',
    name: 'Grid Dynamics Holdings, Inc.',
    symbol: 'GDYN',
  },
  {
    value: 'IBTH',
    label: 'IBTH,  iShares iBonds Dec 2027 Term Treasury ETF',
    name: 'iShares iBonds Dec 2027 Term Treasury ETF',
    symbol: 'IBTH',
  },
  {
    value: 'IBTG',
    label: 'IBTG,  iShares iBonds Dec 2026 Term Treasury ETF',
    name: 'iShares iBonds Dec 2026 Term Treasury ETF',
    symbol: 'IBTG',
  },
  {
    value: 'IGICW',
    label: 'IGICW,  International General Insurance Holdings Ltd.',
    name: 'International General Insurance Holdings Ltd.',
    symbol: 'IGICW',
  },
  {
    value: 'IGIC',
    label: 'IGIC,  International General Insurance Holdings Ltd.',
    name: 'International General Insurance Holdings Ltd.',
    symbol: 'IGIC',
  },
  {
    value: 'ESSC',
    label: 'ESSC,  East Stone Acquisition Corporation',
    name: 'East Stone Acquisition Corporation',
    symbol: 'ESSC',
  },
  {
    value: 'ESSCR',
    label: 'ESSCR,  East Stone Acquisition Corporation',
    name: 'East Stone Acquisition Corporation',
    symbol: 'ESSCR',
  },
  {
    value: 'ESSCW',
    label: 'ESSCW,  East Stone Acquisition Corporation',
    name: 'East Stone Acquisition Corporation',
    symbol: 'ESSCW',
  },
  {
    value: 'IMRA',
    label: 'IMRA,  IMARA Inc.',
    name: 'IMARA Inc.',
    symbol: 'IMRA',
  },
  {
    value: 'FCNCP',
    label: 'FCNCP,  First Citizens BancShares, Inc.',
    name: 'First Citizens BancShares, Inc.',
    symbol: 'FCNCP',
  },
  {
    value: 'IBTE',
    label: 'IBTE,  iShares iBonds Dec 2024 Term Treasury ETF',
    name: 'iShares iBonds Dec 2024 Term Treasury ETF',
    symbol: 'IBTE',
  },
  {
    value: 'BPYPN',
    label: 'BPYPN,  Brookfield Property Partners L.P.',
    name: 'Brookfield Property Partners L.P.',
    symbol: 'BPYPN',
  },
  {
    value: 'OXLCP',
    label: 'OXLCP,  Oxford Lane Capital Corp.',
    name: 'Oxford Lane Capital Corp.',
    symbol: 'OXLCP',
  },
  {
    value: 'BWMX',
    label: 'BWMX,  Betterware de México, S.A.P.I. de C.V.',
    name: 'Betterware de México, S.A.P.I. de C.V.',
    symbol: 'BWMX',
  },
  {
    value: 'GSMGW',
    label: 'GSMGW,  Glory Star New Media Group Holdings Limited',
    name: 'Glory Star New Media Group Holdings Limited',
    symbol: 'GSMGW',
  },
  {
    value: 'AIKI',
    label: 'AIKI,  AIkido Pharma Inc.',
    name: 'AIkido Pharma Inc.',
    symbol: 'AIKI',
  },
  {
    value: 'IBTD',
    label: 'IBTD,  iShares iBonds Dec 2023 Term Treasury ETF',
    name: 'iShares iBonds Dec 2023 Term Treasury ETF',
    symbol: 'IBTD',
  },
  {
    value: 'ATCX',
    label: 'ATCX,  Atlas Technical Consultants, Inc.',
    name: 'Atlas Technical Consultants, Inc.',
    symbol: 'ATCX',
  },
  {
    value: 'GSMG',
    label: 'GSMG,  Glory Star New Media Group Holdings Limited',
    name: 'Glory Star New Media Group Holdings Limited',
    symbol: 'GSMG',
  },
  {
    value: 'RSSS',
    label: 'RSSS,  Research Solutions, Inc.',
    name: 'Research Solutions, Inc.',
    symbol: 'RSSS',
  },
  {
    value: 'CALB',
    label: 'CALB,  California BanCorp',
    name: 'California BanCorp',
    symbol: 'CALB',
  },
  {
    value: 'PASG',
    label: 'PASG,  Passage Bio, Inc.',
    name: 'Passage Bio, Inc.',
    symbol: 'PASG',
  },
  {
    value: 'OBLG',
    label: 'OBLG,  Oblong, Inc.',
    name: 'Oblong, Inc.',
    symbol: 'OBLG',
  },
  {
    value: 'BATL',
    label: 'BATL,  Battalion Oil Corporation',
    name: 'Battalion Oil Corporation',
    symbol: 'BATL',
  },
  {
    value: 'ARYN.SW',
    label: 'ARYN.SW,  ARYZTA AG',
    name: 'ARYZTA AG',
    symbol: 'ARYN.SW',
  },
  {
    value: 'VGRO.TO',
    label: 'VGRO.TO,  Vanguard Growth ETF Portfolio',
    name: 'Vanguard Growth ETF Portfolio',
    symbol: 'VGRO.TO',
  },
  {
    value: 'IQM',
    label: 'IQM,  Franklin Intelligent Machines ETF',
    name: 'Franklin Intelligent Machines ETF',
    symbol: 'IQM',
  },
  {
    value: 'BUYZ',
    label: 'BUYZ,  Franklin Disruptive Commerce ETF',
    name: 'Franklin Disruptive Commerce ETF',
    symbol: 'BUYZ',
  },
  {
    value: 'HELX',
    label: 'HELX,  Franklin Genomic Advancements ETF',
    name: 'Franklin Genomic Advancements ETF',
    symbol: 'HELX',
  },
  {
    value: 'RESD',
    label: 'RESD,  WisdomTree International ESG Fund',
    name: 'WisdomTree International ESG Fund',
    symbol: 'RESD',
  },
  {
    value: 'RESE',
    label: 'RESE,  WisdomTree Emerging Markets ESG Fund',
    name: 'WisdomTree Emerging Markets ESG Fund',
    symbol: 'RESE',
  },
  {
    value: 'UMAR',
    label: 'UMAR,  Innovator S&P 500 Ultra Buffer ETF - March',
    name: 'Innovator S&P 500 Ultra Buffer ETF - March',
    symbol: 'UMAR',
  },
  {
    value: 'BMAR',
    label: 'BMAR,  Innovator S&P 500 Buffer ETF - March',
    name: 'Innovator S&P 500 Buffer ETF - March',
    symbol: 'BMAR',
  },
  {
    value: 'PMAR',
    label: 'PMAR,  Innovator S&P 500 Power Buffer ETF - March',
    name: 'Innovator S&P 500 Power Buffer ETF - March',
    symbol: 'PMAR',
  },
  {
    value: 'HCRB',
    label: 'HCRB,  Hartford Core Bond ETF',
    name: 'Hartford Core Bond ETF',
    symbol: 'HCRB',
  },
  {
    value: 'DFEB',
    label: 'DFEB,  FT Cboe Vest U.S. Equity Deep Buffer ETF - February',
    name: 'FT Cboe Vest U.S. Equity Deep Buffer ETF - February',
    symbol: 'DFEB',
  },
  {
    value: 'FFEB',
    label: 'FFEB,  FT Cboe Vest U.S. Equity Buffer ETF - February',
    name: 'FT Cboe Vest U.S. Equity Buffer ETF - February',
    symbol: 'FFEB',
  },
  {
    value: 'RFM',
    label: 'RFM,  RiverNorth Flexible Municipal Income Fund, Inc.',
    name: 'RiverNorth Flexible Municipal Income Fund, Inc.',
    symbol: 'RFM',
  },
  {
    value: 'ALINN.PA',
    label: 'ALINN.PA,  Innelec Multimédia SA',
    name: 'Innelec Multimédia SA',
    symbol: 'ALINN.PA',
  },
  {
    value: 'G.TO',
    label: 'G.TO,  Augusta Gold Corp.',
    name: 'Augusta Gold Corp.',
    symbol: 'G.TO',
  },
  {
    value: 'BIPC',
    label: 'BIPC,  Brookfield Infrastructure Corporation',
    name: 'Brookfield Infrastructure Corporation',
    symbol: 'BIPC',
  },
  {
    value: 'HWM',
    label: 'HWM,  Howmet Aerospace Inc.',
    name: 'Howmet Aerospace Inc.',
    symbol: 'HWM',
  },
  {
    value: 'WIMI',
    label: 'WIMI,  WiMi Hologram Cloud Inc.',
    name: 'WiMi Hologram Cloud Inc.',
    symbol: 'WIMI',
  },
  {
    value: 'PSHG',
    label: 'PSHG,  Performance Shipping Inc.',
    name: 'Performance Shipping Inc.',
    symbol: 'PSHG',
  },
  {
    value: 'METX',
    label: 'METX,  Meten Holding Group Ltd.',
    name: 'Meten Holding Group Ltd.',
    symbol: 'METX',
  },
  {
    value: 'CARR',
    label: 'CARR,  Carrier Global Corporation',
    name: 'Carrier Global Corporation',
    symbol: 'CARR',
  },
  {
    value: 'RTX',
    label: 'RTX,  Raytheon Technologies Corporation',
    name: 'Raytheon Technologies Corporation',
    symbol: 'RTX',
  },
  {
    value: 'OTIS',
    label: 'OTIS,  Otis Worldwide Corporation',
    name: 'Otis Worldwide Corporation',
    symbol: 'OTIS',
  },
  {
    value: 'ZNTL',
    label: 'ZNTL,  Zentalis Pharmaceuticals, Inc.',
    name: 'Zentalis Pharmaceuticals, Inc.',
    symbol: 'ZNTL',
  },
  {
    value: 'RGP',
    label: 'RGP,  Resources Connection, Inc.',
    name: 'Resources Connection, Inc.',
    symbol: 'RGP',
  },
  {
    value: 'SONN',
    label: 'SONN,  Sonnet BioTherapeutics Holdings, Inc.',
    name: 'Sonnet BioTherapeutics Holdings, Inc.',
    symbol: 'SONN',
  },
  {
    value: 'HSMV',
    label: 'HSMV,  First Trust Horizon Managed Volatility Small/Mid ETF',
    name: 'First Trust Horizon Managed Volatility Small/Mid ETF',
    symbol: 'HSMV',
  },
  {
    value: 'IVES',
    label: 'IVES,  Wedbush ETFMG Global Cloud Technology ETF',
    name: 'Wedbush ETFMG Global Cloud Technology ETF',
    symbol: 'IVES',
  },
  {
    value: 'AVCT',
    label: 'AVCT,  American Virtual Cloud Technologies, Inc.',
    name: 'American Virtual Cloud Technologies, Inc.',
    symbol: 'AVCT',
  },
  {
    value: 'KROS',
    label: 'KROS,  Keros Therapeutics, Inc.',
    name: 'Keros Therapeutics, Inc.',
    symbol: 'KROS',
  },
  {
    value: 'AVCTW',
    label: 'AVCTW,  American Virtual Cloud Technologies Inc',
    name: 'American Virtual Cloud Technologies Inc',
    symbol: 'AVCTW',
  },
  {
    value: 'BKMC',
    label: 'BKMC,  BNY Mellon US Mid Cap Core Equity ETF',
    name: 'BNY Mellon US Mid Cap Core Equity ETF',
    symbol: 'BKMC',
  },
  {
    value: 'BKSE',
    label: 'BKSE,  BNY Mellon US Small Cap Core Equity ETF',
    name: 'BNY Mellon US Small Cap Core Equity ETF',
    symbol: 'BKSE',
  },
  {
    value: 'KOKU',
    label: 'KOKU,  Xtrackers MSCI Kokusai Equity ETF',
    name: 'Xtrackers MSCI Kokusai Equity ETF',
    symbol: 'KOKU',
  },
  {
    value: 'BKLC',
    label: 'BKLC,  BNY Mellon US Large Cap Core Equity ETF',
    name: 'BNY Mellon US Large Cap Core Equity ETF',
    symbol: 'BKLC',
  },
  {
    value: 'GMBL',
    label: 'GMBL,  Esports Entertainment Group, Inc.',
    name: 'Esports Entertainment Group, Inc.',
    symbol: 'GMBL',
  },
  {
    value: 'GMBLW',
    label: 'GMBLW,  Esports Entertainment Group, Inc.',
    name: 'Esports Entertainment Group, Inc.',
    symbol: 'GMBLW',
  },
  {
    value: 'NTR.TO',
    label: 'NTR.TO,  Nutrien Ltd.',
    name: 'Nutrien Ltd.',
    symbol: 'NTR.TO',
  },
  {
    value: 'BBMC',
    label: 'BBMC,  JPMorgan BetaBuilders U.S. Mid Cap Equity ETF',
    name: 'JPMorgan BetaBuilders U.S. Mid Cap Equity ETF',
    symbol: 'BBMC',
  },
  {
    value: 'MSGE',
    label: 'MSGE,  Madison Square Garden Entertainment Corp.',
    name: 'Madison Square Garden Entertainment Corp.',
    symbol: 'MSGE',
  },
  {
    value: 'MSGS',
    label: 'MSGS,  Madison Square Garden Sports Corp.',
    name: 'Madison Square Garden Sports Corp.',
    symbol: 'MSGS',
  },
  {
    value: 'BKSB',
    label: 'BKSB,  BNY Mellon Short Duration Corporate Bond ETF',
    name: 'BNY Mellon Short Duration Corporate Bond ETF',
    symbol: 'BKSB',
  },
  {
    value: 'DKNG',
    label: 'DKNG,  DraftKings Inc.',
    name: 'DraftKings Inc.',
    symbol: 'DKNG',
  },
  {
    value: 'ORIC',
    label: 'ORIC,  ORIC Pharmaceuticals, Inc.',
    name: 'ORIC Pharmaceuticals, Inc.',
    symbol: 'ORIC',
  },
  {
    value: 'BKAG',
    label: 'BKAG,  BNY Mellon Core Bond ETF',
    name: 'BNY Mellon Core Bond ETF',
    symbol: 'BKAG',
  },
  {
    value: 'BKEM',
    label: 'BKEM,  BNY Mellon Emerging Markets Equity ETF',
    name: 'BNY Mellon Emerging Markets Equity ETF',
    symbol: 'BKEM',
  },
  {
    value: 'FLV',
    label: 'FLV,  American Century Focused Large Cap Value ETF',
    name: 'American Century Focused Large Cap Value ETF',
    symbol: 'FLV',
  },
  {
    value: 'NAPR',
    label: 'NAPR,  Innovator Nasdaq-100 Power Buffer ETF - April',
    name: 'Innovator Nasdaq-100 Power Buffer ETF - April',
    symbol: 'NAPR',
  },
  {
    value: 'FDG',
    label: 'FDG,  American Century Focused Dynamic Growth ETF',
    name: 'American Century Focused Dynamic Growth ETF',
    symbol: 'FDG',
  },
  {
    value: 'WUGI',
    label: 'WUGI,  Esoterica NextG Economy ETF',
    name: 'Esoterica NextG Economy ETF',
    symbol: 'WUGI',
  },
  {
    value: 'KAPR',
    label: 'KAPR,  Innovator Russell 2000 Power Buffer ETF - April',
    name: 'Innovator Russell 2000 Power Buffer ETF - April',
    symbol: 'KAPR',
  },
  {
    value: 'BKHY',
    label: 'BKHY,  BNY Mellon High Yield Beta ETF',
    name: 'BNY Mellon High Yield Beta ETF',
    symbol: 'BKHY',
  },
  {
    value: 'BKIE',
    label: 'BKIE,  BNY Mellon International Equity ETF',
    name: 'BNY Mellon International Equity ETF',
    symbol: 'BKIE',
  },
  {
    value: 'APG',
    label: 'APG,  APi Group Corporation',
    name: 'APi Group Corporation',
    symbol: 'APG',
  },
  {
    value: 'DEED',
    label: 'DEED,  First Trust TCW Securitized Plus ETF',
    name: 'First Trust TCW Securitized Plus ETF',
    symbol: 'DEED',
  },
  {
    value: 'NVVE',
    label: 'NVVE,  Nuvve Holding Corp.',
    name: 'Nuvve Holding Corp.',
    symbol: 'NVVE',
  },
  {
    value: 'NVVEW',
    label: 'NVVEW,  Nuvve Holding Corp.',
    name: 'Nuvve Holding Corp.',
    symbol: 'NVVEW',
  },
  {
    value: 'GLCN',
    label: 'GLCN,  VanEck China Growth Leaders ETF',
    name: 'VanEck China Growth Leaders ETF',
    symbol: 'GLCN',
  },
  {
    value: 'GLIN',
    label: 'GLIN,  VanEck India Growth Leaders ETF',
    name: 'VanEck India Growth Leaders ETF',
    symbol: 'GLIN',
  },
  {
    value: 'DCTH',
    label: 'DCTH,  Delcath Systems, Inc.',
    name: 'Delcath Systems, Inc.',
    symbol: 'DCTH',
  },
  {
    value: 'LYRA',
    label: 'LYRA,  Lyra Therapeutics, Inc.',
    name: 'Lyra Therapeutics, Inc.',
    symbol: 'LYRA',
  },
  {
    value: 'PMAY',
    label: 'PMAY,  Innovator S&P 500 Power Buffer ETF - May',
    name: 'Innovator S&P 500 Power Buffer ETF - May',
    symbol: 'PMAY',
  },
  {
    value: 'UMAY',
    label: 'UMAY,  Innovator S&P 500 Ultra Buffer ETF - May',
    name: 'Innovator S&P 500 Ultra Buffer ETF - May',
    symbol: 'UMAY',
  },
  {
    value: 'BMAY',
    label: 'BMAY,  Innovator S&P 500 Buffer ETF - May',
    name: 'Innovator S&P 500 Buffer ETF - May',
    symbol: 'BMAY',
  },
  {
    value: 'ENB-PN.TO',
    label: 'ENB-PN.TO,  Enbridge Inc',
    name: 'Enbridge Inc',
    symbol: 'ENB-PN.TO',
  },
  {
    value: 'CM-PT.TO',
    label: 'CM-PT.TO,  Canadian Imperial Bank of Commerce',
    name: 'Canadian Imperial Bank of Commerce',
    symbol: 'CM-PT.TO',
  },
  {
    value: 'BIK-PA.TO',
    label: 'BIK-PA.TO,  BIP Investment Corporation',
    name: 'BIP Investment Corporation',
    symbol: 'BIK-PA.TO',
  },
  {
    value: 'BMO-PF.TO',
    label: 'BMO-PF.TO,  Bank of Montreal',
    name: 'Bank of Montreal',
    symbol: 'BMO-PF.TO',
  },
  {
    value: 'BCE-PY.TO',
    label: 'BCE-PY.TO,  BCE Inc.',
    name: 'BCE Inc.',
    symbol: 'BCE-PY.TO',
  },
  {
    value: 'BCE-PA.TO',
    label: 'BCE-PA.TO,  BCE Inc.',
    name: 'BCE Inc.',
    symbol: 'BCE-PA.TO',
  },
  {
    value: 'ENB-PA.TO',
    label: 'ENB-PA.TO,  Enbridge Inc',
    name: 'Enbridge Inc',
    symbol: 'ENB-PA.TO',
  },
  {
    value: 'ENB-PT.TO',
    label: 'ENB-PT.TO,  Enbridge Inc',
    name: 'Enbridge Inc',
    symbol: 'ENB-PT.TO',
  },
  {
    value: 'ENB-PFK.TO',
    label: 'ENB-PFK.TO,  Enbridge Inc. CUM RED PFD 19',
    name: 'Enbridge Inc. CUM RED PFD 19',
    symbol: 'ENB-PFK.TO',
  },
  {
    value: 'IFC-PE.TO',
    label: 'IFC-PE.TO,  Intact Financial Corporation',
    name: 'Intact Financial Corporation',
    symbol: 'IFC-PE.TO',
  },
  {
    value: 'IAF-PI.TO',
    label: 'IAF-PI.TO,  iA Financial Corporation Inc.',
    name: 'iA Financial Corporation Inc.',
    symbol: 'IAF-PI.TO',
  },
  {
    value: 'IFC-PG.TO',
    label: 'IFC-PG.TO,  Intact Financial Corporation',
    name: 'Intact Financial Corporation',
    symbol: 'IFC-PG.TO',
  },
  {
    value: 'MFC-PM.TO',
    label: 'MFC-PM.TO,  Manulife Financial Corp',
    name: 'Manulife Financial Corp',
    symbol: 'MFC-PM.TO',
  },
  {
    value: 'PWF-PZ.TO',
    label: 'PWF-PZ.TO,  Power Financial Corporation',
    name: 'Power Financial Corporation',
    symbol: 'PWF-PZ.TO',
  },
  {
    value: 'PPL-PQ.TO',
    label: 'PPL-PQ.TO,  Pembina Pipeline Corporation',
    name: 'Pembina Pipeline Corporation',
    symbol: 'PPL-PQ.TO',
  },
  {
    value: 'PPL-PA.TO',
    label: 'PPL-PA.TO,  Pembina Pipeline Corporation',
    name: 'Pembina Pipeline Corporation',
    symbol: 'PPL-PA.TO',
  },
  {
    value: 'PPL-PC.TO',
    label: 'PPL-PC.TO,  Pembina Pipeline Corporation',
    name: 'Pembina Pipeline Corporation',
    symbol: 'PPL-PC.TO',
  },
  {
    value: 'TD-PFK.TO',
    label: 'TD-PFK.TO,  The Toronto-Dominion Bank',
    name: 'The Toronto-Dominion Bank',
    symbol: 'TD-PFK.TO',
  },
  {
    value: 'TD-PFL.TO',
    label: 'TD-PFL.TO,  The Toronto-Dominion Bank',
    name: 'The Toronto-Dominion Bank',
    symbol: 'TD-PFL.TO',
  },
  {
    value: 'TRP-PD.TO',
    label: 'TRP-PD.TO,  TC ENERGY CORP PREF SERIES 7',
    name: 'TC ENERGY CORP PREF SERIES 7',
    symbol: 'TRP-PD.TO',
  },
  {
    value: 'GAN',
    label: 'GAN,  GAN Limited',
    name: 'GAN Limited',
    symbol: 'GAN',
  },
  {
    value: 'NMTR',
    label: 'NMTR,  9 Meters Biopharma, Inc.',
    name: '9 Meters Biopharma, Inc.',
    symbol: 'NMTR',
  },
  {
    value: 'GAU',
    label: 'GAU,  Galiano Gold Inc.',
    name: 'Galiano Gold Inc.',
    symbol: 'GAU',
  },
  {
    value: 'ENB-PFA.TO',
    label: 'ENB-PFA.TO,  Enbridge Inc.',
    name: 'Enbridge Inc.',
    symbol: 'ENB-PFA.TO',
  },
  {
    value: 'ARB',
    label: 'ARB,  AltShares Merger Arbitrage ETF',
    name: 'AltShares Merger Arbitrage ETF',
    symbol: 'ARB',
  },
  {
    value: 'AYLA',
    label: 'AYLA,  Ayala Pharmaceuticals, Inc.',
    name: 'Ayala Pharmaceuticals, Inc.',
    symbol: 'AYLA',
  },
  {
    value: 'CLEU',
    label: 'CLEU,  China Liberal Education Holdings Limited',
    name: 'China Liberal Education Holdings Limited',
    symbol: 'CLEU',
  },
  {
    value: 'OCFCP',
    label: 'OCFCP,  OceanFirst Financial Corp.',
    name: 'OceanFirst Financial Corp.',
    symbol: 'OCFCP',
  },
  {
    value: 'KC',
    label: 'KC,  Kingsoft Cloud Holdings Limited',
    name: 'Kingsoft Cloud Holdings Limited',
    symbol: 'KC',
  },
  {
    value: 'CRDF',
    label: 'CRDF,  Cardiff Oncology, Inc.',
    name: 'Cardiff Oncology, Inc.',
    symbol: 'CRDF',
  },
  {
    value: 'SIXH',
    label: 'SIXH,  ETC 6 Meridian Hedged Equity-Index Option Strategy ETF',
    name: 'ETC 6 Meridian Hedged Equity-Index Option Strategy ETF',
    symbol: 'SIXH',
  },
  {
    value: 'THNQ',
    label: 'THNQ,  Robo Global Artificial Intelligence ETF',
    name: 'Robo Global Artificial Intelligence ETF',
    symbol: 'THNQ',
  },
  {
    value: 'SIXS',
    label: 'SIXS,  ETC 6 Meridian Small Cap Equity ETF',
    name: 'ETC 6 Meridian Small Cap Equity ETF',
    symbol: 'SIXS',
  },
  {
    value: 'SIXA',
    label: 'SIXA,  ETC 6 Meridian Mega Cap Equity ETF',
    name: 'ETC 6 Meridian Mega Cap Equity ETF',
    symbol: 'SIXA',
  },
  {
    value: 'SIXL',
    label: 'SIXL,  ETC 6 Meridian Low Beta Equity Strategy ETF',
    name: 'ETC 6 Meridian Low Beta Equity Strategy ETF',
    symbol: 'SIXL',
  },
  {
    value: 'SJ',
    label: 'SJ,  Scienjoy Holding Corporation',
    name: 'Scienjoy Holding Corporation',
    symbol: 'SJ',
  },
  {
    value: 'ESEB',
    label: 'ESEB,  Xtrackers J.P. Morgan ESG Emerging Markets Sovereign ETF',
    name: 'Xtrackers J.P. Morgan ESG Emerging Markets Sovereign ETF',
    symbol: 'ESEB',
  },
  {
    value: 'ESCR',
    label: 'ESCR,  Xtrackers Bloomberg US Investment Grade Corporate ESG ETF',
    name: 'Xtrackers Bloomberg US Investment Grade Corporate ESG ETF',
    symbol: 'ESCR',
  },
  {
    value: 'ESHY',
    label: 'ESHY,  Xtrackers J.P. Morgan ESG USD High Yield Corporate Bond ETF',
    name: 'Xtrackers J.P. Morgan ESG USD High Yield Corporate Bond ETF',
    symbol: 'ESHY',
  },
  {
    value: 'SQEW',
    label: 'SQEW,  LeaderShares Equity Skew ETF',
    name: 'LeaderShares Equity Skew ETF',
    symbol: 'SQEW',
  },
  {
    value: 'GNRSW',
    label: 'GNRSW,  Greenrose Acquisition Corp.',
    name: 'Greenrose Acquisition Corp.',
    symbol: 'GNRSW',
  },
  {
    value: 'MSVX',
    label: 'MSVX,  LHA Market State Alpha Seeker ETF',
    name: 'LHA Market State Alpha Seeker ETF',
    symbol: 'MSVX',
  },
  {
    value: 'JEPI',
    label: 'JEPI,  JPMorgan Equity Premium Income ETF',
    name: 'JPMorgan Equity Premium Income ETF',
    symbol: 'JEPI',
  },
  {
    value: 'JIG',
    label: 'JIG,  JPMorgan International Growth ETF',
    name: 'JPMorgan International Growth ETF',
    symbol: 'JIG',
  },
  {
    value: 'ADCT',
    label: 'ADCT,  ADC Therapeutics SA',
    name: 'ADC Therapeutics SA',
    symbol: 'ADCT',
  },
  {
    value: 'SLQT',
    label: 'SLQT,  SelectQuote, Inc.',
    name: 'SelectQuote, Inc.',
    symbol: 'SLQT',
  },
  {
    value: 'WINT',
    label: 'WINT,  Windtree Therapeutics, Inc.',
    name: 'Windtree Therapeutics, Inc.',
    symbol: 'WINT',
  },
  {
    value: 'GNRS',
    label: 'GNRS,  The Greenrose Holding Company Inc.',
    name: 'The Greenrose Holding Company Inc.',
    symbol: 'GNRS',
  },
  {
    value: 'CCNC',
    label: 'CCNC,  Code Chain New Continent Limited',
    name: 'Code Chain New Continent Limited',
    symbol: 'CCNC',
  },
  {
    value: 'TMBR',
    label: 'TMBR,  Timber Pharmaceuticals, Inc.',
    name: 'Timber Pharmaceuticals, Inc.',
    symbol: 'TMBR',
  },
  {
    value: 'DMAY',
    label: 'DMAY,  FT Cboe Vest U.S. Equity Deep Buffer ETF - May',
    name: 'FT Cboe Vest U.S. Equity Deep Buffer ETF - May',
    symbol: 'DMAY',
  },
  {
    value: 'GSID',
    label: 'GSID,  Goldman Sachs MarketBeta International Equity ETF',
    name: 'Goldman Sachs MarketBeta International Equity ETF',
    symbol: 'GSID',
  },
  {
    value: 'GSUS',
    label: 'GSUS,  Goldman Sachs MarketBeta US Equity ETF',
    name: 'Goldman Sachs MarketBeta US Equity ETF',
    symbol: 'GSUS',
  },
  {
    value: 'GSEE',
    label: 'GSEE,  Goldman Sachs MarketBeta Emerging Markets Equity ETF',
    name: 'Goldman Sachs MarketBeta Emerging Markets Equity ETF',
    symbol: 'GSEE',
  },
  {
    value: 'FMAY',
    label: 'FMAY,  FT Cboe Vest U.S. Equity Buffer ETF - May',
    name: 'FT Cboe Vest U.S. Equity Buffer ETF - May',
    symbol: 'FMAY',
  },
  {
    value: 'FDJ.PA',
    label: 'FDJ.PA,  La Française des Jeux Société anonyme',
    name: 'La Française des Jeux Société anonyme',
    symbol: 'FDJ.PA',
  },
  {
    value: 'MTCN',
    label: 'MTCN,  ArcelorMittal 5.50% Mandatorily',
    name: 'ArcelorMittal 5.50% Mandatorily',
    symbol: 'MTCN',
  },
  {
    value: 'SGOV',
    label: 'SGOV,  iShares 0-3 Month Treasury Bond ETF',
    name: 'iShares 0-3 Month Treasury Bond ETF',
    symbol: 'SGOV',
  },
  {
    value: 'HSTO',
    label: 'HSTO,  Histogen Inc.',
    name: 'Histogen Inc.',
    symbol: 'HSTO',
  },
  {
    value: 'QLGN',
    label: 'QLGN,  Qualigen Therapeutics, Inc.',
    name: 'Qualigen Therapeutics, Inc.',
    symbol: 'QLGN',
  },
  {
    value: 'BHFAO',
    label: 'BHFAO,  Brighthouse Financial, Inc.',
    name: 'Brighthouse Financial, Inc.',
    symbol: 'BHFAO',
  },
  {
    value: 'NARI',
    label: 'NARI,  Inari Medical, Inc.',
    name: 'Inari Medical, Inc.',
    symbol: 'NARI',
  },
  {
    value: 'CFCV',
    label: 'CFCV,  Clearbridge Focus Value ESG ETF',
    name: 'Clearbridge Focus Value ESG ETF',
    symbol: 'CFCV',
  },
  {
    value: 'METXW',
    label: 'METXW,  Meten EdtechX Education Group Ltd.',
    name: 'Meten EdtechX Education Group Ltd.',
    symbol: 'METXW',
  },
  {
    value: 'WTFCP',
    label: 'WTFCP,  Wintrust Financial Corporation',
    name: 'Wintrust Financial Corporation',
    symbol: 'WTFCP',
  },
  {
    value: 'LRMR',
    label: 'LRMR,  Larimar Therapeutics, Inc.',
    name: 'Larimar Therapeutics, Inc.',
    symbol: 'LRMR',
  },
  {
    value: 'AYRO',
    label: 'AYRO,  Ayro, Inc.',
    name: 'Ayro, Inc.',
    symbol: 'AYRO',
  },
  {
    value: 'SSLY',
    label: 'SSLY,  Syntax Stratified SmallCap ETF',
    name: 'Syntax Stratified SmallCap ETF',
    symbol: 'SSLY',
  },
  {
    value: 'AZBA',
    label: 'AZBA,  AllianzIM U.S. Large Cap Buffer20 Apr ETF',
    name: 'AllianzIM U.S. Large Cap Buffer20 Apr ETF',
    symbol: 'AZBA',
  },
  {
    value: 'AZAA',
    label: 'AZAA,  AllianzIM U.S. Large Cap Buffer10 Apr ETF',
    name: 'AllianzIM U.S. Large Cap Buffer10 Apr ETF',
    symbol: 'AZAA',
  },
  {
    value: 'HYMCW',
    label: 'HYMCW,  Hycroft Mining Holding Corporation',
    name: 'Hycroft Mining Holding Corporation',
    symbol: 'HYMCW',
  },
  {
    value: 'SHYF',
    label: 'SHYF,  The Shyft Group, Inc.',
    name: 'The Shyft Group, Inc.',
    symbol: 'SHYF',
  },
  {
    value: 'HYMC',
    label: 'HYMC,  Hycroft Mining Holding Corporation',
    name: 'Hycroft Mining Holding Corporation',
    symbol: 'HYMC',
  },
  {
    value: 'SWBI',
    label: 'SWBI,  Smith & Wesson Brands, Inc.',
    name: 'Smith & Wesson Brands, Inc.',
    symbol: 'SWBI',
  },
  {
    value: 'BDXB',
    label: 'BDXB,  Becton, Dickinson and Company',
    name: 'Becton, Dickinson and Company',
    symbol: 'BDXB',
  },
  {
    value: 'BDCX',
    label:
      'BDCX,  ETRACS Quarterly Pay 1.5X Leveraged Wells Fargo BDC Index ETN',
    name: 'ETRACS Quarterly Pay 1.5X Leveraged Wells Fargo BDC Index ETN',
    symbol: 'BDCX',
  },
  {
    value: 'AFGD',
    label: 'AFGD,  American Financial Group, Inc.',
    name: 'American Financial Group, Inc.',
    symbol: 'AFGD',
  },
  {
    value: 'CEFD',
    label: 'CEFD,  ETRACS Monthly Pay 1.5X Leveraged Closed-End Fund Index ETN',
    name: 'ETRACS Monthly Pay 1.5X Leveraged Closed-End Fund Index ETN',
    symbol: 'CEFD',
  },
  {
    value: 'NKLA',
    label: 'NKLA,  Nikola Corporation',
    name: 'Nikola Corporation',
    symbol: 'NKLA',
  },
  {
    value: 'CHX',
    label: 'CHX,  ChampionX Corporation',
    name: 'ChampionX Corporation',
    symbol: 'CHX',
  },
  {
    value: 'MLPR',
    label: 'MLPR,  ETRACS Quarterly Pay 1.5X Leveraged Alerian MLP Index ETN',
    name: 'ETRACS Quarterly Pay 1.5X Leveraged Alerian MLP Index ETN',
    symbol: 'MLPR',
  },
  {
    value: 'BETZ',
    label: 'BETZ,  Roundhill Sports Betting & iGaming ETF',
    name: 'Roundhill Sports Betting & iGaming ETF',
    symbol: 'BETZ',
  },
  {
    value: 'FOUR',
    label: 'FOUR,  Shift4 Payments, Inc.',
    name: 'Shift4 Payments, Inc.',
    symbol: 'FOUR',
  },
  {
    value: 'MVRL',
    label: 'MVRL,  ETRACS Monthly Pay 1.5X Leveraged Mortgage REIT ETN',
    name: 'ETRACS Monthly Pay 1.5X Leveraged Mortgage REIT ETN',
    symbol: 'MVRL',
  },
  {
    value: 'SBBA',
    label: 'SBBA,  Scorpio Tankers Inc. 7.00% Seni',
    name: 'Scorpio Tankers Inc. 7.00% Seni',
    symbol: 'SBBA',
  },
  {
    value: 'EMBD',
    label: 'EMBD,  Global X Emerging Markets Bond ETF',
    name: 'Global X Emerging Markets Bond ETF',
    symbol: 'EMBD',
  },
  {
    value: 'WMG',
    label: 'WMG,  Warner Music Group Corp.',
    name: 'Warner Music Group Corp.',
    symbol: 'WMG',
  },
  {
    value: 'LEGN',
    label: 'LEGN,  Legend Biotech Corporation',
    name: 'Legend Biotech Corporation',
    symbol: 'LEGN',
  },
  {
    value: 'PNFPP',
    label: 'PNFPP,  Pinnacle Financial Partners, Inc.',
    name: 'Pinnacle Financial Partners, Inc.',
    symbol: 'PNFPP',
  },
  {
    value: 'DADA',
    label: 'DADA,  Dada Nexus Limited',
    name: 'Dada Nexus Limited',
    symbol: 'DADA',
  },
  {
    value: 'PLRX',
    label: 'PLRX,  Pliant Therapeutics, Inc.',
    name: 'Pliant Therapeutics, Inc.',
    symbol: 'PLRX',
  },
  {
    value: 'CALT',
    label: 'CALT,  Calliditas Therapeutics AB (publ)',
    name: 'Calliditas Therapeutics AB (publ)',
    symbol: 'CALT',
  },
  {
    value: 'AMTI',
    label: 'AMTI,  Applied Molecular Transport Inc.',
    name: 'Applied Molecular Transport Inc.',
    symbol: 'AMTI',
  },
  {
    value: 'ZI',
    label: 'ZI,  ZoomInfo Technologies Inc.',
    name: 'ZoomInfo Technologies Inc.',
    symbol: 'ZI',
  },
  {
    value: 'FBCG',
    label: 'FBCG,  Fidelity Blue Chip Growth ETF',
    name: 'Fidelity Blue Chip Growth ETF',
    symbol: 'FBCG',
  },
  {
    value: 'FMIL',
    label: 'FMIL,  Fidelity New Millennium ETF',
    name: 'Fidelity New Millennium ETF',
    symbol: 'FMIL',
  },
  {
    value: 'FBCV',
    label: 'FBCV,  Fidelity Blue Chip Value ETF',
    name: 'Fidelity Blue Chip Value ETF',
    symbol: 'FBCV',
  },
  {
    value: 'VRM',
    label: 'VRM,  Vroom, Inc.',
    name: 'Vroom, Inc.',
    symbol: 'VRM',
  },
  {
    value: 'FLGV',
    label: 'FLGV,  Franklin U.S. Treasury Bond ETF',
    name: 'Franklin U.S. Treasury Bond ETF',
    symbol: 'FLGV',
  },
  {
    value: 'LPRO',
    label: 'LPRO,  Open Lending Corporation',
    name: 'Open Lending Corporation',
    symbol: 'LPRO',
  },
  {
    value: 'AUBAP',
    label: 'AUBAP,  Atlantic Union Bankshares Corporation',
    name: 'Atlantic Union Bankshares Corporation',
    symbol: 'AUBAP',
  },
  {
    value: 'LTRN',
    label: 'LTRN,  Lantern Pharma Inc.',
    name: 'Lantern Pharma Inc.',
    symbol: 'LTRN',
  },
  {
    value: 'UCBIO',
    label: 'UCBIO,  United Community Banks, Inc.',
    name: 'United Community Banks, Inc.',
    symbol: 'UCBIO',
  },
  {
    value: 'UCL',
    label: 'UCL,  uCloudlink Group Inc.',
    name: 'uCloudlink Group Inc.',
    symbol: 'UCL',
  },
  {
    value: 'AZEK',
    label: 'AZEK,  The AZEK Company Inc.',
    name: 'The AZEK Company Inc.',
    symbol: 'AZEK',
  },
  {
    value: 'VTOL',
    label: 'VTOL,  Bristow Group Inc.',
    name: 'Bristow Group Inc.',
    symbol: 'VTOL',
  },
  {
    value: 'GBIO',
    label: 'GBIO,  Generation Bio Co.',
    name: 'Generation Bio Co.',
    symbol: 'GBIO',
  },
  {
    value: 'PCVX',
    label: 'PCVX,  Vaxcyte, Inc.',
    name: 'Vaxcyte, Inc.',
    symbol: 'PCVX',
  },
  {
    value: 'AWH',
    label: "AWH,  Aspira Women's Health Inc.",
    name: "Aspira Women's Health Inc.",
    symbol: 'AWH',
  },
  {
    value: 'BNR',
    label: 'BNR,  Burning Rock Biotech Limited',
    name: 'Burning Rock Biotech Limited',
    symbol: 'BNR',
  },
  {
    value: 'RNA',
    label: 'RNA,  Avidity Biosciences, Inc.',
    name: 'Avidity Biosciences, Inc.',
    symbol: 'RNA',
  },
  {
    value: 'ETWO',
    label: 'ETWO,  E2open Parent Holdings, Inc.',
    name: 'E2open Parent Holdings, Inc.',
    symbol: 'ETWO',
  },
  {
    value: 'FCNTX',
    label: 'FCNTX,  Fidelity Contrafund Fund',
    name: 'Fidelity Contrafund Fund',
    symbol: 'FCNTX',
  },
  {
    value: 'FOCPX',
    label: 'FOCPX,  Fidelity OTC Portfolio',
    name: 'Fidelity OTC Portfolio',
    symbol: 'FOCPX',
  },
  {
    value: 'FSCOX',
    label: 'FSCOX,  Fidelity International Small Cap Opportunities Fund',
    name: 'Fidelity International Small Cap Opportunities Fund',
    symbol: 'FSCOX',
  },
  {
    value: 'PQDI',
    label: 'PQDI,  Principal Spectrum Tax-Advantaged Dividend Active ETF',
    name: 'Principal Spectrum Tax-Advantaged Dividend Active ETF',
    symbol: 'PQDI',
  },
  {
    value: 'HWCPZ',
    label: 'HWCPZ,  Hancock Whitney Corporation - 6',
    name: 'Hancock Whitney Corporation - 6',
    symbol: 'HWCPZ',
  },
  {
    value: 'FBRX',
    label: 'FBRX,  Forte Biosciences, Inc.',
    name: 'Forte Biosciences, Inc.',
    symbol: 'FBRX',
  },
  {
    value: 'RPRX',
    label: 'RPRX,  Royalty Pharma plc',
    name: 'Royalty Pharma plc',
    symbol: 'RPRX',
  },
  {
    value: 'LGHLW',
    label: 'LGHLW,  Lion Financial Group Limited',
    name: 'Lion Financial Group Limited',
    symbol: 'LGHLW',
  },
  {
    value: 'LGHL',
    label: 'LGHL,  Lion Group Holding Ltd.',
    name: 'Lion Group Holding Ltd.',
    symbol: 'LGHL',
  },
  {
    value: 'GERM',
    label: 'GERM,  ETFMG Treatments, Testing and Advancements ETF',
    name: 'ETFMG Treatments, Testing and Advancements ETF',
    symbol: 'GERM',
  },
  {
    value: 'VRMEW',
    label: 'VRMEW,  VerifyMe, Inc.',
    name: 'VerifyMe, Inc.',
    symbol: 'VRMEW',
  },
  {
    value: 'RPTX',
    label: 'RPTX,  Repare Therapeutics Inc.',
    name: 'Repare Therapeutics Inc.',
    symbol: 'RPTX',
  },
  {
    value: 'NUZE',
    label: 'NUZE,  NuZee, Inc.',
    name: 'NuZee, Inc.',
    symbol: 'NUZE',
  },
  {
    value: 'DMXF',
    label: 'DMXF,  iShares ESG Advanced MSCI EAFE Index ETF',
    name: 'iShares ESG Advanced MSCI EAFE Index ETF',
    symbol: 'DMXF',
  },
  {
    value: 'USXF',
    label: 'USXF,  iShares ESG Advanced MSCI USA ETF',
    name: 'iShares ESG Advanced MSCI USA ETF',
    symbol: 'USXF',
  },
  {
    value: 'VRME',
    label: 'VRME,  VerifyMe, Inc.',
    name: 'VerifyMe, Inc.',
    symbol: 'VRME',
  },
  {
    value: 'GTH',
    label: 'GTH,  Genetron Holdings Limited',
    name: 'Genetron Holdings Limited',
    symbol: 'GTH',
  },
  {
    value: 'EAOK',
    label: 'EAOK,  iShares ESG Aware Conservative Allocation ETF',
    name: 'iShares ESG Aware Conservative Allocation ETF',
    symbol: 'EAOK',
  },
  {
    value: 'EAOM',
    label: 'EAOM,  iShares ESG Aware Moderate Allocation ETF',
    name: 'iShares ESG Aware Moderate Allocation ETF',
    symbol: 'EAOM',
  },
  {
    value: 'EAOA',
    label: 'EAOA,  iShares ESG Aware Aggressive Allocation ETF',
    name: 'iShares ESG Aware Aggressive Allocation ETF',
    symbol: 'EAOA',
  },
  {
    value: 'EAOR',
    label: 'EAOR,  iShares ESG Aware Growth Allocation ETF',
    name: 'iShares ESG Aware Growth Allocation ETF',
    symbol: 'EAOR',
  },
  {
    value: 'AMNA',
    label: 'AMNA,  ETRACS Alerian Midstream Energy Index ETN',
    name: 'ETRACS Alerian Midstream Energy Index ETN',
    symbol: 'AMNA',
  },
  {
    value: 'DEEP',
    label: 'DEEP,  Roundhill Acquirers Deep Value ETF',
    name: 'Roundhill Acquirers Deep Value ETF',
    symbol: 'DEEP',
  },
  {
    value: 'PFC',
    label: 'PFC,  Premier Financial Corp.',
    name: 'Premier Financial Corp.',
    symbol: 'PFC',
  },
  {
    value: 'FJUN',
    label: 'FJUN,  FT Cboe Vest U.S. Equity Buffer ETF - June',
    name: 'FT Cboe Vest U.S. Equity Buffer ETF - June',
    symbol: 'FJUN',
  },
  {
    value: 'DJUN',
    label: 'DJUN,  FT Cboe Vest U.S. Equity Deep Buffer ETF - June',
    name: 'FT Cboe Vest U.S. Equity Deep Buffer ETF - June',
    symbol: 'DJUN',
  },
  {
    value: 'PAMC',
    label: 'PAMC,  Pacer Lunt MidCap Multi-Factor Alternator ETF',
    name: 'Pacer Lunt MidCap Multi-Factor Alternator ETF',
    symbol: 'PAMC',
  },
  {
    value: 'WFH',
    label: 'WFH,  Direxion Work From Home ETF',
    name: 'Direxion Work From Home ETF',
    symbol: 'WFH',
  },
  {
    value: 'IBDV',
    label: 'IBDV,  iShares iBonds Dec 2030 Term Corporate ETF',
    name: 'iShares iBonds Dec 2030 Term Corporate ETF',
    symbol: 'IBDV',
  },
  {
    value: 'PALC',
    label: 'PALC,  Pacer Lunt Large Cap Multi-Factor Alternator ETF',
    name: 'Pacer Lunt Large Cap Multi-Factor Alternator ETF',
    symbol: 'PALC',
  },
  {
    value: 'EUSB',
    label: 'EUSB,  iShares ESG Advanced Total USD Bond Market ETF',
    name: 'iShares ESG Advanced Total USD Bond Market ETF',
    symbol: 'EUSB',
  },
  {
    value: 'BRLIU',
    label: 'BRLIU,  Brilliant Acquisition Corporation',
    name: 'Brilliant Acquisition Corporation',
    symbol: 'BRLIU',
  },
  {
    value: 'CEFA',
    label: 'CEFA,  Global X S&P Catholic Values Developed ex-U.S. ETF',
    name: 'Global X S&P Catholic Values Developed ex-U.S. ETF',
    symbol: 'CEFA',
  },
  {
    value: 'TBKCP',
    label: 'TBKCP,  Triumph Bancorp, Inc.',
    name: 'Triumph Bancorp, Inc.',
    symbol: 'TBKCP',
  },
  {
    value: 'OPINL',
    label: 'OPINL,  Office Properties Income Trust',
    name: 'Office Properties Income Trust',
    symbol: 'OPINL',
  },
  {
    value: 'PFFV',
    label: 'PFFV,  Global X Variable Rate Preferred ETF',
    name: 'Global X Variable Rate Preferred ETF',
    symbol: 'PFFV',
  },
  {
    value: 'ACI',
    label: 'ACI,  Albertsons Companies, Inc.',
    name: 'Albertsons Companies, Inc.',
    symbol: 'ACI',
  },
  {
    value: 'ALTL',
    label: 'ALTL,  Pacer Lunt Large Cap Alternator ETF',
    name: 'Pacer Lunt Large Cap Alternator ETF',
    symbol: 'ALTL',
  },
  {
    value: 'FTHY',
    label: 'FTHY,  First Trust High Yield Opportunities 2027 Term Fund',
    name: 'First Trust High Yield Opportunities 2027 Term Fund',
    symbol: 'FTHY',
  },
  {
    value: 'AKUS',
    label: 'AKUS,  Akouos, Inc.',
    name: 'Akouos, Inc.',
    symbol: 'AKUS',
  },
  {
    value: 'PYPD',
    label: 'PYPD,  PolyPid Ltd.',
    name: 'PolyPid Ltd.',
    symbol: 'PYPD',
  },
  {
    value: 'API',
    label: 'API,  Agora, Inc.',
    name: 'Agora, Inc.',
    symbol: 'API',
  },
  {
    value: 'FREEW',
    label: 'FREEW,  Whole Earth Brands Inc',
    name: 'Whole Earth Brands Inc',
    symbol: 'FREEW',
  },
  {
    value: 'FUSN',
    label: 'FUSN,  Fusion Pharmaceuticals Inc.',
    name: 'Fusion Pharmaceuticals Inc.',
    symbol: 'FUSN',
  },
  {
    value: 'EBON',
    label: 'EBON,  Ebang International Holdings Inc.',
    name: 'Ebang International Holdings Inc.',
    symbol: 'EBON',
  },
  {
    value: 'FREE',
    label: 'FREE,  Whole Earth Brands, Inc.',
    name: 'Whole Earth Brands, Inc.',
    symbol: 'FREE',
  },
  {
    value: 'THY',
    label: 'THY,  Toews Agility Shares Dynamic Tactical Income ETF',
    name: 'Toews Agility Shares Dynamic Tactical Income ETF',
    symbol: 'THY',
  },
  {
    value: 'MRSK',
    label: 'MRSK,  Toews Agility Shares Managed Risk ETF',
    name: 'Toews Agility Shares Managed Risk ETF',
    symbol: 'MRSK',
  },
  {
    value: 'VIRS',
    label: 'VIRS,  Pacer BioThreat Strategy ETF',
    name: 'Pacer BioThreat Strategy ETF',
    symbol: 'VIRS',
  },
  {
    value: 'SII',
    label: 'SII,  Sprott Inc.',
    name: 'Sprott Inc.',
    symbol: 'SII',
  },
  {
    value: 'HTLFP',
    label: 'HTLFP,  Heartland Financial USA, Inc.',
    name: 'Heartland Financial USA, Inc.',
    symbol: 'HTLFP',
  },
  {
    value: 'AZAL',
    label: 'AZAL,  AllianzIM U.S. Large Cap Buffer10 Jul ETF',
    name: 'AllianzIM U.S. Large Cap Buffer10 Jul ETF',
    symbol: 'AZAL',
  },
  {
    value: 'AZBL',
    label: 'AZBL,  AllianzIM U.S. Large Cap Buffer20 Jul ETF',
    name: 'AllianzIM U.S. Large Cap Buffer20 Jul ETF',
    symbol: 'AZBL',
  },
  {
    value: 'MGNI',
    label: 'MGNI,  Magnite, Inc.',
    name: 'Magnite, Inc.',
    symbol: 'MGNI',
  },
  {
    value: 'ADTX',
    label: 'ADTX,  Aditxt, Inc.',
    name: 'Aditxt, Inc.',
    symbol: 'ADTX',
  },
  {
    value: 'KJUL',
    label: 'KJUL,  Innovator Russell 2000 Power Buffer ETF - July',
    name: 'Innovator Russell 2000 Power Buffer ETF - July',
    symbol: 'KJUL',
  },
  {
    value: 'NJUL',
    label: 'NJUL,  Innovator Nasdaq-100 Power Buffer ETF - July',
    name: 'Innovator Nasdaq-100 Power Buffer ETF - July',
    symbol: 'NJUL',
  },
  {
    value: 'JULZ',
    label: 'JULZ,  Trueshares Structured Outcome (July) ETF',
    name: 'Trueshares Structured Outcome (July) ETF',
    symbol: 'JULZ',
  },
  {
    value: 'LMND',
    label: 'LMND,  Lemonade, Inc.',
    name: 'Lemonade, Inc.',
    symbol: 'LMND',
  },
  {
    value: 'ZEV',
    label: 'ZEV,  Lightning eMotors, Inc.',
    name: 'Lightning eMotors, Inc.',
    symbol: 'ZEV',
  },
  {
    value: 'HOFV',
    label: 'HOFV,  Hall of Fame Resort & Entertainment Company',
    name: 'Hall of Fame Resort & Entertainment Company',
    symbol: 'HOFV',
  },
  {
    value: 'IMTX',
    label: 'IMTX,  Immatics N.V.',
    name: 'Immatics N.V.',
    symbol: 'IMTX',
  },
  {
    value: 'HOFVW',
    label: 'HOFVW,  Hall of Fame Village, LLC',
    name: 'Hall of Fame Village, LLC',
    symbol: 'HOFVW',
  },
  {
    value: 'ACCD',
    label: 'ACCD,  Accolade, Inc.',
    name: 'Accolade, Inc.',
    symbol: 'ACCD',
  },
  {
    value: 'IMTXW',
    label: 'IMTXW,  immatics biotechnologies GmbH',
    name: 'immatics biotechnologies GmbH',
    symbol: 'IMTXW',
  },
  {
    value: 'CVLG',
    label: 'CVLG,  Covenant Logistics Group, Inc.',
    name: 'Covenant Logistics Group, Inc.',
    symbol: 'CVLG',
  },
  {
    value: 'SNEX',
    label: 'SNEX,  StoneX Group Inc.',
    name: 'StoneX Group Inc.',
    symbol: 'SNEX',
  },
  {
    value: 'PCGU',
    label: 'PCGU,  PG&E Corporation',
    name: 'PG&E Corporation',
    symbol: 'PCGU',
  },
  {
    value: 'AMYT',
    label: 'AMYT,  Amryt Pharma plc',
    name: 'Amryt Pharma plc',
    symbol: 'AMYT',
  },
  {
    value: 'OTRK',
    label: 'OTRK,  Ontrak, Inc.',
    name: 'Ontrak, Inc.',
    symbol: 'OTRK',
  },
  {
    value: 'CXDO',
    label: 'CXDO,  Crexendo, Inc.',
    name: 'Crexendo, Inc.',
    symbol: 'CXDO',
  },
  {
    value: 'RISN',
    label: 'RISN,  Inspire Tactical Balanced ETF',
    name: 'Inspire Tactical Balanced ETF',
    symbol: 'RISN',
  },
  {
    value: 'AVNT',
    label: 'AVNT,  Avient Corporation',
    name: 'Avient Corporation',
    symbol: 'AVNT',
  },
  {
    value: 'FLUD',
    label: 'FLUD,  Franklin Ultra Short Bond ETF',
    name: 'Franklin Ultra Short Bond ETF',
    symbol: 'FLUD',
  },
  {
    value: 'ESGA',
    label: 'ESGA,  American Century Sustainable Equity ETF',
    name: 'American Century Sustainable Equity ETF',
    symbol: 'ESGA',
  },
  {
    value: 'MID',
    label: 'MID,  American Century Mid Cap Growth Impact ETF',
    name: 'American Century Mid Cap Growth Impact ETF',
    symbol: 'MID',
  },
  {
    value: 'DMS',
    label: 'DMS,  Digital Media Solutions, Inc.',
    name: 'Digital Media Solutions, Inc.',
    symbol: 'DMS',
  },
  {
    value: 'GSIG',
    label:
      'GSIG,  Goldman Sachs Access Investment Grade Corporate 1-5 Year Bond ETF',
    name: 'Goldman Sachs Access Investment Grade Corporate 1-5 Year Bond ETF',
    symbol: 'GSIG',
  },
  {
    value: 'AMND',
    label: 'AMND,  ETRACS Alerian Midstream Energy High Dividend Index ETN',
    name: 'ETRACS Alerian Midstream Energy High Dividend Index ETN',
    symbol: 'AMND',
  },
  {
    value: 'PSTX',
    label: 'PSTX,  Poseida Therapeutics, Inc.',
    name: 'Poseida Therapeutics, Inc.',
    symbol: 'PSTX',
  },
  {
    value: 'NKTX',
    label: 'NKTX,  Nkarta, Inc.',
    name: 'Nkarta, Inc.',
    symbol: 'NKTX',
  },
  {
    value: 'IBTK',
    label: 'IBTK,  iShares iBonds Dec 2030 Term Treasury ETF',
    name: 'iShares iBonds Dec 2030 Term Treasury ETF',
    symbol: 'IBTK',
  },
  {
    value: 'NCNO',
    label: 'NCNO,  nCino, Inc.',
    name: 'nCino, Inc.',
    symbol: 'NCNO',
  },
  {
    value: 'MLACU',
    label: 'MLACU,  Malacca Straits Acquisition Company Limited',
    name: 'Malacca Straits Acquisition Company Limited',
    symbol: 'MLACU',
  },
  {
    value: 'RLAY',
    label: 'RLAY,  Relay Therapeutics, Inc.',
    name: 'Relay Therapeutics, Inc.',
    symbol: 'RLAY',
  },
  {
    value: 'GOCO',
    label: 'GOCO,  GoHealth, Inc.',
    name: 'GoHealth, Inc.',
    symbol: 'GOCO',
  },
  {
    value: 'IVA',
    label: 'IVA,  Inventiva S.A.',
    name: 'Inventiva S.A.',
    symbol: 'IVA',
  },
  {
    value: 'FATBW',
    label: 'FATBW,  FAT Brands Inc.',
    name: 'FAT Brands Inc.',
    symbol: 'FATBW',
  },
  {
    value: 'QH',
    label: 'QH,  Quhuo Limited',
    name: 'Quhuo Limited',
    symbol: 'QH',
  },
  {
    value: 'EDUT',
    label: 'EDUT,  Global X Education ETF',
    name: 'Global X Education ETF',
    symbol: 'EDUT',
  },
  {
    value: 'FATBP',
    label: 'FATBP,  FAT Brands Inc.',
    name: 'FAT Brands Inc.',
    symbol: 'FATBP',
  },
  {
    value: 'ALXO',
    label: 'ALXO,  ALX Oncology Holdings Inc.',
    name: 'ALX Oncology Holdings Inc.',
    symbol: 'ALXO',
  },
  {
    value: 'BLI',
    label: 'BLI,  Berkeley Lights, Inc.',
    name: 'Berkeley Lights, Inc.',
    symbol: 'BLI',
  },
  {
    value: 'RNLX',
    label: 'RNLX,  Renalytix Plc',
    name: 'Renalytix Plc',
    symbol: 'RNLX',
  },
  {
    value: 'SOS',
    label: 'SOS,  SOS Limited',
    name: 'SOS Limited',
    symbol: 'SOS',
  },
  {
    value: 'EFIV',
    label: 'EFIV,  SPDR S&P 500 ESG ETF',
    name: 'SPDR S&P 500 ESG ETF',
    symbol: 'EFIV',
  },
  {
    value: 'MEG',
    label: 'MEG,  Montrose Environmental Group, Inc.',
    name: 'Montrose Environmental Group, Inc.',
    symbol: 'MEG',
  },
  {
    value: 'MMLG',
    label: 'MMLG,  First Trust Multi-Manager Large Growth ETF',
    name: 'First Trust Multi-Manager Large Growth ETF',
    symbol: 'MMLG',
  },
  {
    value: 'NWG',
    label: 'NWG,  NatWest Group plc',
    name: 'NatWest Group plc',
    symbol: 'NWG',
  },
  {
    value: 'BRLIW',
    label: 'BRLIW,  Brilliant Acquisition Corporation',
    name: 'Brilliant Acquisition Corporation',
    symbol: 'BRLIW',
  },
  {
    value: 'INZY',
    label: 'INZY,  Inozyme Pharma, Inc.',
    name: 'Inozyme Pharma, Inc.',
    symbol: 'INZY',
  },
  {
    value: 'ERESU',
    label: 'ERESU,  East Resources Acquisition Company',
    name: 'East Resources Acquisition Company',
    symbol: 'ERESU',
  },
  {
    value: 'ANNX',
    label: 'ANNX,  Annexon, Inc.',
    name: 'Annexon, Inc.',
    symbol: 'ANNX',
  },
  {
    value: 'BRLIR',
    label: 'BRLIR,  Brilliant Acquisition Corporation',
    name: 'Brilliant Acquisition Corporation',
    symbol: 'BRLIR',
  },
  {
    value: 'ITOS',
    label: 'ITOS,  iTeos Therapeutics, Inc.',
    name: 'iTeos Therapeutics, Inc.',
    symbol: 'ITOS',
  },
  {
    value: 'ACEVU',
    label: 'ACEVU,  ACE Convergence Acquisition Corp.',
    name: 'ACE Convergence Acquisition Corp.',
    symbol: 'ACEVU',
  },
  {
    value: 'BRLI',
    label: 'BRLI,  Brilliant Acquisition Corporation',
    name: 'Brilliant Acquisition Corporation',
    symbol: 'BRLI',
  },
  {
    value: 'JAMF',
    label: 'JAMF,  Jamf Holding Corp.',
    name: 'Jamf Holding Corp.',
    symbol: 'JAMF',
  },
  {
    value: 'EDTK',
    label: 'EDTK,  Skillful Craftsman Education Technology Limited',
    name: 'Skillful Craftsman Education Technology Limited',
    symbol: 'EDTK',
  },
  {
    value: 'NRIX',
    label: 'NRIX,  Nurix Therapeutics, Inc.',
    name: 'Nurix Therapeutics, Inc.',
    symbol: 'NRIX',
  },
  {
    value: 'GRCYU',
    label: 'GRCYU,  Greencity Acquisition Corporation',
    name: 'Greencity Acquisition Corporation',
    symbol: 'GRCYU',
  },
  {
    value: 'CSSEN',
    label: 'CSSEN,  Chicken Soup for the Soul Enter',
    name: 'Chicken Soup for the Soul Enter',
    symbol: 'CSSEN',
  },
  {
    value: 'SRGA',
    label: 'SRGA,  Surgalign Holdings, Inc.',
    name: 'Surgalign Holdings, Inc.',
    symbol: 'SRGA',
  },
  {
    value: 'VWID',
    label: 'VWID,  Virtus WMC International Dividend ETF',
    name: 'Virtus WMC International Dividend ETF',
    symbol: 'VWID',
  },
  {
    value: 'FJUL',
    label: 'FJUL,  FT Cboe Vest U.S. Equity Buffer ETF - July',
    name: 'FT Cboe Vest U.S. Equity Buffer ETF - July',
    symbol: 'FJUL',
  },
  {
    value: 'DJUL',
    label: 'DJUL,  FT Cboe Vest U.S. Equity Deep Buffer ETF - July',
    name: 'FT Cboe Vest U.S. Equity Deep Buffer ETF - July',
    symbol: 'DJUL',
  },
  {
    value: 'BIGC',
    label: 'BIGC,  BigCommerce Holdings, Inc.',
    name: 'BigCommerce Holdings, Inc.',
    symbol: 'BIGC',
  },
  {
    value: 'CVAC',
    label: 'CVAC,  CureVac N.V.',
    name: 'CureVac N.V.',
    symbol: 'CVAC',
  },
  {
    value: 'BEKE',
    label: 'BEKE,  KE Holdings Inc.',
    name: 'KE Holdings Inc.',
    symbol: 'BEKE',
  },
  {
    value: 'NTST',
    label: 'NTST,  NETSTREIT Corp.',
    name: 'NETSTREIT Corp.',
    symbol: 'NTST',
  },
  {
    value: 'OSH',
    label: 'OSH,  Oak Street Health, Inc.',
    name: 'Oak Street Health, Inc.',
    symbol: 'OSH',
  },
  {
    value: 'TDVG',
    label: 'TDVG,  T. Rowe Price Dividend Growth ETF',
    name: 'T. Rowe Price Dividend Growth ETF',
    symbol: 'TDVG',
  },
  {
    value: 'BEPC',
    label: 'BEPC,  Brookfield Renewable Corporation',
    name: 'Brookfield Renewable Corporation',
    symbol: 'BEPC',
  },
  {
    value: 'ASGI',
    label: 'ASGI,  Abrdn Global Infrastructure Income Fund',
    name: 'Abrdn Global Infrastructure Income Fund',
    symbol: 'ASGI',
  },
  {
    value: 'KESG',
    label: 'KESG,  KraneShares MSCI China ESG Leaders Index ETF',
    name: 'KraneShares MSCI China ESG Leaders Index ETF',
    symbol: 'KESG',
  },
  {
    value: 'TEQI',
    label: 'TEQI,  T. Rowe Price Equity Income ETF',
    name: 'T. Rowe Price Equity Income ETF',
    symbol: 'TEQI',
  },
  {
    value: 'KRBN',
    label: 'KRBN,  KraneShares Global Carbon ETF',
    name: 'KraneShares Global Carbon ETF',
    symbol: 'KRBN',
  },
  {
    value: 'TCHP',
    label: 'TCHP,  T. Rowe Price Blue Chip Growth ETF',
    name: 'T. Rowe Price Blue Chip Growth ETF',
    symbol: 'TCHP',
  },
  {
    value: 'RKT',
    label: 'RKT,  Rocket Companies, Inc.',
    name: 'Rocket Companies, Inc.',
    symbol: 'RKT',
  },
  {
    value: 'TGRW',
    label: 'TGRW,  T. Rowe Price Growth Stock ETF',
    name: 'T. Rowe Price Growth Stock ETF',
    symbol: 'TGRW',
  },
  {
    value: 'VITL',
    label: 'VITL,  Vital Farms, Inc.',
    name: 'Vital Farms, Inc.',
    symbol: 'VITL',
  },
  {
    value: 'OLB',
    label: 'OLB,  The OLB Group, Inc.',
    name: 'The OLB Group, Inc.',
    symbol: 'OLB',
  },
  {
    value: 'FCRD',
    label: 'FCRD,  First Eagle Alternative Capital BDC, Inc.',
    name: 'First Eagle Alternative Capital BDC, Inc.',
    symbol: 'FCRD',
  },
  {
    value: 'MLACW',
    label: 'MLACW,  Malacca Straits Acquisition Company Limited',
    name: 'Malacca Straits Acquisition Company Limited',
    symbol: 'MLACW',
  },
  {
    value: 'HSAQ',
    label: 'HSAQ,  Health Sciences Acquisitions Corporation 2',
    name: 'Health Sciences Acquisitions Corporation 2',
    symbol: 'HSAQ',
  },
  {
    value: 'VERX',
    label: 'VERX,  Vertex, Inc.',
    name: 'Vertex, Inc.',
    symbol: 'VERX',
  },
  {
    value: 'AFIB',
    label: 'AFIB,  Acutus Medical, Inc.',
    name: 'Acutus Medical, Inc.',
    symbol: 'AFIB',
  },
  {
    value: 'WSBCP',
    label: 'WSBCP,  WesBanco, Inc.',
    name: 'WesBanco, Inc.',
    symbol: 'WSBCP',
  },
  {
    value: 'MLAC',
    label: 'MLAC,  Malacca Straits Acquisition Company Limited',
    name: 'Malacca Straits Acquisition Company Limited',
    symbol: 'MLAC',
  },
  {
    value: 'NHICU',
    label: 'NHICU,  NewHold Investment Corp. II',
    name: 'NewHold Investment Corp. II',
    symbol: 'NHICU',
  },
  {
    value: 'FTHM',
    label: 'FTHM,  Fathom Holdings Inc.',
    name: 'Fathom Holdings Inc.',
    symbol: 'FTHM',
  },
  {
    value: 'ALVR',
    label: 'ALVR,  AlloVir, Inc.',
    name: 'AlloVir, Inc.',
    symbol: 'ALVR',
  },
  {
    value: 'BTBT',
    label: 'BTBT,  Bit Digital, Inc.',
    name: 'Bit Digital, Inc.',
    symbol: 'BTBT',
  },
  {
    value: 'KBNT',
    label: 'KBNT,  Kubient, Inc.',
    name: 'Kubient, Inc.',
    symbol: 'KBNT',
  },
  {
    value: 'ITRG',
    label: 'ITRG,  Integra Resources Corp.',
    name: 'Integra Resources Corp.',
    symbol: 'ITRG',
  },
  {
    value: 'AUGZ',
    label: 'AUGZ,  TrueShares Structured Outcome (August) ETF',
    name: 'TrueShares Structured Outcome (August) ETF',
    symbol: 'AUGZ',
  },
  {
    value: 'RXT',
    label: 'RXT,  Rackspace Technology, Inc.',
    name: 'Rackspace Technology, Inc.',
    symbol: 'RXT',
  },
  {
    value: 'LRLCY',
    label: "LRLCY,  L'Oréal S.A.",
    name: "L'Oréal S.A.",
    symbol: 'LRLCY',
  },
  {
    value: 'NSRGY',
    label: 'NSRGY,  Nestlé S.A.',
    name: 'Nestlé S.A.',
    symbol: 'NSRGY',
  },
  {
    value: 'NPSNY',
    label: 'NPSNY,  Naspers Limited',
    name: 'Naspers Limited',
    symbol: 'NPSNY',
  },
  {
    value: 'TCEHY',
    label: 'TCEHY,  Tencent Holdings Limited',
    name: 'Tencent Holdings Limited',
    symbol: 'TCEHY',
  },
  {
    value: 'SFTBY',
    label: 'SFTBY,  SoftBank Group Corp.',
    name: 'SoftBank Group Corp.',
    symbol: 'SFTBY',
  },
  {
    value: 'ESLOY',
    label: 'ESLOY,  EssilorLuxottica Société anonyme',
    name: 'EssilorLuxottica Société anonyme',
    symbol: 'ESLOY',
  },
  {
    value: 'PROSY',
    label: 'PROSY,  Prosus N.V.',
    name: 'Prosus N.V.',
    symbol: 'PROSY',
  },
  {
    value: 'LVMUY',
    label: 'LVMUY,  LVMH Moët Hennessy - Louis Vuitton, Société Européenne',
    name: 'LVMH Moët Hennessy - Louis Vuitton, Société Européenne',
    symbol: 'LVMUY',
  },
  {
    value: 'VGHAX',
    label: 'VGHAX,  Vanguard Health Care Fund Admiral Shares',
    name: 'Vanguard Health Care Fund Admiral Shares',
    symbol: 'VGHAX',
  },
  {
    value: 'VGHCX',
    label: 'VGHCX,  Vanguard Health Care Fund',
    name: 'Vanguard Health Care Fund',
    symbol: 'VGHCX',
  },
  {
    value: 'SFE.DE',
    label: 'SFE.DE,  Synchrony Financial',
    name: 'Synchrony Financial',
    symbol: 'SFE.DE',
  },
  {
    value: '2X0.DE',
    label: '2X0.DE,  Corteva Inc',
    name: 'Corteva Inc',
    symbol: '2X0.DE',
  },
  {
    value: 'ART.DE',
    label: 'ART.DE,  artnet AG',
    name: 'artnet AG',
    symbol: 'ART.DE',
  },
  {
    value: 'SYT.DE',
    label: 'SYT.DE,  Softing AG',
    name: 'Softing AG',
    symbol: 'SYT.DE',
  },
  {
    value: '1N8.DE',
    label: '1N8.DE,  Adyen NV',
    name: 'Adyen NV',
    symbol: '1N8.DE',
  },
  {
    value: 'BCO.DE',
    label: 'BCO.DE,  The Boeing Company',
    name: 'The Boeing Company',
    symbol: 'BCO.DE',
  },
  {
    value: 'PCZ.DE',
    label: 'PCZ.DE,  ProCredit Holding AG & Co. KGaA',
    name: 'ProCredit Holding AG & Co. KGaA',
    symbol: 'PCZ.DE',
  },
  {
    value: 'UB5.DE',
    label: 'UB5.DE,  U.S. Bancorp',
    name: 'U.S. Bancorp',
    symbol: 'UB5.DE',
  },
  {
    value: 'ALG.DE',
    label: 'ALG.DE,  ALBIS Leasing AG',
    name: 'ALBIS Leasing AG',
    symbol: 'ALG.DE',
  },
  {
    value: 'M7U.DE',
    label: 'M7U.DE,  Nynomic AG',
    name: 'Nynomic AG',
    symbol: 'M7U.DE',
  },
  {
    value: 'ADN1.DE',
    label: 'ADN1.DE,  adesso SE',
    name: 'adesso SE',
    symbol: 'ADN1.DE',
  },
  {
    value: 'BNP.DE',
    label: 'BNP.DE,  BNP Paribas SA',
    name: 'BNP Paribas SA',
    symbol: 'BNP.DE',
  },
  {
    value: 'EQS.DE',
    label: 'EQS.DE,  EQS Group AG',
    name: 'EQS Group AG',
    symbol: 'EQS.DE',
  },
  {
    value: 'TA1.DE',
    label: 'TA1.DE,  Telekom Austria AG',
    name: 'Telekom Austria AG',
    symbol: 'TA1.DE',
  },
  {
    value: 'SGE.DE',
    label: 'SGE.DE,  Société Générale Société anonyme',
    name: 'Société Générale Société anonyme',
    symbol: 'SGE.DE',
  },
  {
    value: 'SF3.DE',
    label: 'SF3.DE,  STS Group AG',
    name: 'STS Group AG',
    symbol: 'SF3.DE',
  },
  {
    value: 'YSN.DE',
    label: 'YSN.DE,  secunet Security Networks Aktiengesellschaft',
    name: 'secunet Security Networks Aktiengesellschaft',
    symbol: 'YSN.DE',
  },
  {
    value: 'ABR.DE',
    label: 'ABR.DE,  Barrick Gold Corporation',
    name: 'Barrick Gold Corporation',
    symbol: 'ABR.DE',
  },
  {
    value: 'SMWN.DE',
    label: 'SMWN.DE,  SM Wirtschaftsberatungs AG',
    name: 'SM Wirtschaftsberatungs AG',
    symbol: 'SMWN.DE',
  },
  {
    value: 'ADL.DE',
    label: 'ADL.DE,  ADLER Real Estate AG',
    name: 'ADLER Real Estate AG',
    symbol: 'ADL.DE',
  },
  {
    value: 'ADM.DE',
    label: 'ADM.DE,  Archer-Daniels-Midland Company',
    name: 'Archer-Daniels-Midland Company',
    symbol: 'ADM.DE',
  },
  {
    value: 'TLX.DE',
    label: 'TLX.DE,  Talanx AG',
    name: 'Talanx AG',
    symbol: 'TLX.DE',
  },
  {
    value: 'TGHN.DE',
    label: 'TGHN.DE,  Logwin AG',
    name: 'Logwin AG',
    symbol: 'TGHN.DE',
  },
  {
    value: 'HRPK.DE',
    label: 'HRPK.DE,  7C Solarparken AG',
    name: '7C Solarparken AG',
    symbol: 'HRPK.DE',
  },
  {
    value: '1NBA.DE',
    label: '1NBA.DE,  Anheuser-Busch InBev SA/NV',
    name: 'Anheuser-Busch InBev SA/NV',
    symbol: '1NBA.DE',
  },
  {
    value: 'ST5.DE',
    label: 'ST5.DE,  STEICO SE',
    name: 'STEICO SE',
    symbol: 'ST5.DE',
  },
  {
    value: 'GKS.DE',
    label: 'GKS.DE,  GK Software SE',
    name: 'GK Software SE',
    symbol: 'GKS.DE',
  },
  {
    value: 'EXL.DE',
    label: 'EXL.DE,  Exasol AG',
    name: 'Exasol AG',
    symbol: 'EXL.DE',
  },
  {
    value: 'KFI1.DE',
    label: 'KFI1.DE,  Kingfisher plc',
    name: 'Kingfisher plc',
    symbol: 'KFI1.DE',
  },
  {
    value: '2PP.DE',
    label: '2PP.DE,  PayPal Holdings, Inc.',
    name: 'PayPal Holdings, Inc.',
    symbol: '2PP.DE',
  },
  {
    value: 'HMI.DE',
    label: 'HMI.DE,  Hermes International SA',
    name: 'Hermes International SA',
    symbol: 'HMI.DE',
  },
  {
    value: 'MUX.DE',
    label: 'MUX.DE,  Mutares SE & Co. KGaA',
    name: 'Mutares SE & Co. KGaA',
    symbol: 'MUX.DE',
  },
  {
    value: 'BIRG.DE',
    label: 'BIRG.DE,  Bank of Ireland Group plc',
    name: 'Bank of Ireland Group plc',
    symbol: 'BIRG.DE',
  },
  {
    value: 'LAR.DE',
    label: 'LAR.DE,  Lam Research Corporation',
    name: 'Lam Research Corporation',
    symbol: 'LAR.DE',
  },
  {
    value: 'AB9.DE',
    label: 'AB9.DE,  ABO Wind AG',
    name: 'ABO Wind AG',
    symbol: 'AB9.DE',
  },
  {
    value: '1COV.DE',
    label: '1COV.DE,  Covestro AG',
    name: 'Covestro AG',
    symbol: '1COV.DE',
  },
  {
    value: 'DAM.DE',
    label:
      'DAM.DE,  DATA MODUL Aktiengesellschaft, Produktion und Vertrieb von elektronischen Systemen',
    name: 'DATA MODUL Aktiengesellschaft, Produktion und Vertrieb von elektronischen Systemen',
    symbol: 'DAM.DE',
  },
  {
    value: 'DY2.DE',
    label: 'DY2.DE,  Dentsply Sirona Inc',
    name: 'Dentsply Sirona Inc',
    symbol: 'DY2.DE',
  },
  {
    value: '6D81.DE',
    label: '6D81.DE,  Dupont De Nemours Inc',
    name: 'Dupont De Nemours Inc',
    symbol: '6D81.DE',
  },
  {
    value: 'OSP2.DE',
    label: 'OSP2.DE,  USU Software AG',
    name: 'USU Software AG',
    symbol: 'OSP2.DE',
  },
  {
    value: 'AGB2.DE',
    label: 'AGB2.DE,  Agrana Beteiligungs AG',
    name: 'Agrana Beteiligungs AG',
    symbol: 'AGB2.DE',
  },
  {
    value: 'GTQ1.DE',
    label: 'GTQ1.DE,  Siemens Gamesa Renewable Energy, S.A.',
    name: 'Siemens Gamesa Renewable Energy, S.A.',
    symbol: 'GTQ1.DE',
  },
  {
    value: 'OEWA.DE',
    label: 'OEWA.DE,  Verbund AG',
    name: 'Verbund AG',
    symbol: 'OEWA.DE',
  },
  {
    value: 'TTK.DE',
    label: 'TTK.DE,  TAKKT AG',
    name: 'TAKKT AG',
    symbol: 'TTK.DE',
  },
  {
    value: 'NKE.DE',
    label: 'NKE.DE,  NIKE, Inc.',
    name: 'NIKE, Inc.',
    symbol: 'NKE.DE',
  },
  {
    value: 'K1R.DE',
    label: 'K1R.DE,  KROMI Logistik AG',
    name: 'KROMI Logistik AG',
    symbol: 'K1R.DE',
  },
  {
    value: 'TGT.DE',
    label: 'TGT.DE,  11880 Solutions AG',
    name: '11880 Solutions AG',
    symbol: 'TGT.DE',
  },
  {
    value: 'PPX.DE',
    label: 'PPX.DE,  Kering SA',
    name: 'Kering SA',
    symbol: 'PPX.DE',
  },
  {
    value: 'CCAP.DE',
    label: 'CCAP.DE,  CORESTATE Capital Holding S.A.',
    name: 'CORESTATE Capital Holding S.A.',
    symbol: 'CCAP.DE',
  },
  {
    value: 'S6P.DE',
    label: 'S6P.DE,  Spielvereinigung Unterhaching Fußball GmbH & Co. KGaA',
    name: 'Spielvereinigung Unterhaching Fußball GmbH & Co. KGaA',
    symbol: 'S6P.DE',
  },
  {
    value: '8TRA.DE',
    label: '8TRA.DE,  Traton SE',
    name: 'Traton SE',
    symbol: '8TRA.DE',
  },
  {
    value: 'VFP.DE',
    label: 'VFP.DE,  V.F. Corporation',
    name: 'V.F. Corporation',
    symbol: 'VFP.DE',
  },
  {
    value: 'DR0.DE',
    label: 'DR0.DE,  Deutsche Rohstoff AG',
    name: 'Deutsche Rohstoff AG',
    symbol: 'DR0.DE',
  },
  {
    value: 'L1OA.DE',
    label: 'L1OA.DE,  Lloyd Fonds AG',
    name: 'Lloyd Fonds AG',
    symbol: 'L1OA.DE',
  },
  {
    value: 'AEIN.DE',
    label: 'AEIN.DE,  Allgeier SE',
    name: 'Allgeier SE',
    symbol: 'AEIN.DE',
  },
  {
    value: 'B7E.DE',
    label: 'B7E.DE,  Blue Cap AG',
    name: 'Blue Cap AG',
    symbol: 'B7E.DE',
  },
  {
    value: 'NFN.DE',
    label: 'NFN.DE,  NFON AG',
    name: 'NFON AG',
    symbol: 'NFN.DE',
  },
  {
    value: 'DEQ.DE',
    label: 'DEQ.DE,  Deutsche EuroShop AG',
    name: 'Deutsche EuroShop AG',
    symbol: 'DEQ.DE',
  },
  {
    value: 'ABS2.DE',
    label: 'ABS2.DE,  PORR AG',
    name: 'PORR AG',
    symbol: 'ABS2.DE',
  },
  {
    value: 'MDG1.DE',
    label: 'MDG1.DE,  Medigene AG',
    name: 'Medigene AG',
    symbol: 'MDG1.DE',
  },
  {
    value: 'KCO.DE',
    label: 'KCO.DE,  Klöckner & Co SE',
    name: 'Klöckner & Co SE',
    symbol: 'KCO.DE',
  },
  {
    value: 'CPX.DE',
    label: 'CPX.DE,  capsensixx AG',
    name: 'capsensixx AG',
    symbol: 'CPX.DE',
  },
  {
    value: 'GZF.DE',
    label: 'GZF.DE,  ENGIE SA',
    name: 'ENGIE SA',
    symbol: 'GZF.DE',
  },
  {
    value: 'BSL.DE',
    label: 'BSL.DE,  Basler Aktiengesellschaft',
    name: 'Basler Aktiengesellschaft',
    symbol: 'BSL.DE',
  },
  {
    value: 'DAR.DE',
    label: 'DAR.DE,  DATRON AG',
    name: 'DATRON AG',
    symbol: 'DAR.DE',
  },
  {
    value: 'USX1.DE',
    label: 'USX1.DE,  United States Steel Corporation',
    name: 'United States Steel Corporation',
    symbol: 'USX1.DE',
  },
  {
    value: 'CSQ.DE',
    label: 'CSQ.DE,  creditshelf Aktiengesellschaft',
    name: 'creditshelf Aktiengesellschaft',
    symbol: 'CSQ.DE',
  },
  {
    value: 'V3S.DE',
    label: 'V3S.DE,  Vectron Systems AG',
    name: 'Vectron Systems AG',
    symbol: 'V3S.DE',
  },
  {
    value: 'BMW.DE',
    label: 'BMW.DE,  Bayerische Motoren Werke Aktiengesellschaft',
    name: 'Bayerische Motoren Werke Aktiengesellschaft',
    symbol: 'BMW.DE',
  },
  {
    value: 'CON.DE',
    label: 'CON.DE,  Continental Aktiengesellschaft',
    name: 'Continental Aktiengesellschaft',
    symbol: 'CON.DE',
  },
  {
    value: 'PNE3.DE',
    label: 'PNE3.DE,  PNE AG',
    name: 'PNE AG',
    symbol: 'PNE3.DE',
  },
  {
    value: 'ECX.DE',
    label: 'ECX.DE,  Epigenomics AG',
    name: 'Epigenomics AG',
    symbol: 'ECX.DE',
  },
  {
    value: 'XGR2.DE',
    label: 'XGR2.DE,  Compass Group PLC',
    name: 'Compass Group PLC',
    symbol: 'XGR2.DE',
  },
  {
    value: 'SLL.DE',
    label: 'SLL.DE,  Schoeller-Bleckmann Oilfield Equipment Aktiengesellschaft',
    name: 'Schoeller-Bleckmann Oilfield Equipment Aktiengesellschaft',
    symbol: 'SLL.DE',
  },
  {
    value: 'HYQ.DE',
    label: 'HYQ.DE,  Hypoport SE',
    name: 'Hypoport SE',
    symbol: 'HYQ.DE',
  },
  {
    value: 'KIN2.DE',
    label: 'KIN2.DE,  Kinross Gold Corporation',
    name: 'Kinross Gold Corporation',
    symbol: 'KIN2.DE',
  },
  {
    value: 'NIIN.DE',
    label: 'NIIN.DE,  niiio finance group AG',
    name: 'niiio finance group AG',
    symbol: 'NIIN.DE',
  },
  {
    value: 'WMB.DE',
    label: 'WMB.DE,  The Williams Companies, Inc.',
    name: 'The Williams Companies, Inc.',
    symbol: 'WMB.DE',
  },
  {
    value: 'TUI1.DE',
    label: 'TUI1.DE,  TUI AG',
    name: 'TUI AG',
    symbol: 'TUI1.DE',
  },
  {
    value: 'PFV.DE',
    label: 'PFV.DE,  Pfeiffer Vacuum Technology AG',
    name: 'Pfeiffer Vacuum Technology AG',
    symbol: 'PFV.DE',
  },
  {
    value: 'DPW.DE',
    label: 'DPW.DE,  Deutsche Post AG',
    name: 'Deutsche Post AG',
    symbol: 'DPW.DE',
  },
  {
    value: 'UBK.DE',
    label: 'UBK.DE,  UmweltBank AG',
    name: 'UmweltBank AG',
    symbol: 'UBK.DE',
  },
  {
    value: 'LOM.DE',
    label: 'LOM.DE,  Lockheed Martin Corporation',
    name: 'Lockheed Martin Corporation',
    symbol: 'LOM.DE',
  },
  {
    value: 'SGL.DE',
    label: 'SGL.DE,  SGL Carbon SE',
    name: 'SGL Carbon SE',
    symbol: 'SGL.DE',
  },
  {
    value: 'COK.DE',
    label: 'COK.DE,  Cancom SE',
    name: 'Cancom SE',
    symbol: 'COK.DE',
  },
  {
    value: 'EVT.DE',
    label: 'EVT.DE,  Evotec SE',
    name: 'Evotec SE',
    symbol: 'EVT.DE',
  },
  {
    value: 'ERCB.DE',
    label: 'ERCB.DE,  Telefonaktiebolaget LM Ericsson (publ)',
    name: 'Telefonaktiebolaget LM Ericsson (publ)',
    symbol: 'ERCB.DE',
  },
  {
    value: 'ACWN.DE',
    label: 'ACWN.DE,  A.S. Création Tapeten AG',
    name: 'A.S. Création Tapeten AG',
    symbol: 'ACWN.DE',
  },
  {
    value: 'LLD.DE',
    label: 'LLD.DE,  Lloyds Banking Group plc',
    name: 'Lloyds Banking Group plc',
    symbol: 'LLD.DE',
  },
  {
    value: 'LIN.DE',
    label: 'LIN.DE,  Linde plc',
    name: 'Linde plc',
    symbol: 'LIN.DE',
  },
  {
    value: 'T6A.DE',
    label: 'T6A.DE,  Tripadvisor, Inc.',
    name: 'Tripadvisor, Inc.',
    symbol: 'T6A.DE',
  },
  {
    value: 'UDC.DE',
    label: 'UDC.DE,  UniDevice AG',
    name: 'UniDevice AG',
    symbol: 'UDC.DE',
  },
  {
    value: 'IS7.DE',
    label: 'IS7.DE,  InTiCa Systems AG',
    name: 'InTiCa Systems AG',
    symbol: 'IS7.DE',
  },
  {
    value: 'CO3A.DE',
    label: 'CO3A.DE,  Coty Inc.',
    name: 'Coty Inc.',
    symbol: 'CO3A.DE',
  },
  {
    value: 'IXD1.DE',
    label: 'IXD1.DE,  Industria De Diseno Textil SA',
    name: 'Industria De Diseno Textil SA',
    symbol: 'IXD1.DE',
  },
  {
    value: 'ZIL2.DE',
    label: 'ZIL2.DE,  ElringKlinger AG',
    name: 'ElringKlinger AG',
    symbol: 'ZIL2.DE',
  },
  {
    value: 'PCX.DE',
    label: 'PCX.DE,  Paychex, Inc.',
    name: 'Paychex, Inc.',
    symbol: 'PCX.DE',
  },
  {
    value: 'SAP.DE',
    label: 'SAP.DE,  SAP SE',
    name: 'SAP SE',
    symbol: 'SAP.DE',
  },
  {
    value: 'NOEJ.DE',
    label: 'NOEJ.DE,  NORMA Group SE',
    name: 'NORMA Group SE',
    symbol: 'NOEJ.DE',
  },
  {
    value: 'MED.DE',
    label: 'MED.DE,  MEDICLIN Aktiengesellschaft',
    name: 'MEDICLIN Aktiengesellschaft',
    symbol: 'MED.DE',
  },
  {
    value: 'DTE.DE',
    label: 'DTE.DE,  Deutsche Telekom AG',
    name: 'Deutsche Telekom AG',
    symbol: 'DTE.DE',
  },
  {
    value: 'MBB.DE',
    label: 'MBB.DE,  MBB SE',
    name: 'MBB SE',
    symbol: 'MBB.DE',
  },
  {
    value: 'KBX.DE',
    label: 'KBX.DE,  Knorr-Bremse Aktiengesellschaft',
    name: 'Knorr-Bremse Aktiengesellschaft',
    symbol: 'KBX.DE',
  },
  {
    value: 'CQD.DE',
    label: 'CQD.DE,  Charter Communications Inc',
    name: 'Charter Communications Inc',
    symbol: 'CQD.DE',
  },
  {
    value: 'QIA.DE',
    label: 'QIA.DE,  Qiagen N.V.',
    name: 'Qiagen N.V.',
    symbol: 'QIA.DE',
  },
  {
    value: 'BDT.DE',
    label: 'BDT.DE,  Bertrandt Aktiengesellschaft',
    name: 'Bertrandt Aktiengesellschaft',
    symbol: 'BDT.DE',
  },
  {
    value: 'SCT.DE',
    label: 'SCT.DE,  SSE PLC',
    name: 'SSE PLC',
    symbol: 'SCT.DE',
  },
  {
    value: '2GB.DE',
    label: '2GB.DE,  2G Energy AG',
    name: '2G Energy AG',
    symbol: '2GB.DE',
  },
  {
    value: 'VVD.DE',
    label: 'VVD.DE,  Veolia Environnement S.A.',
    name: 'Veolia Environnement S.A.',
    symbol: 'VVD.DE',
  },
  {
    value: 'BEI.DE',
    label: 'BEI.DE,  Beiersdorf Aktiengesellschaft',
    name: 'Beiersdorf Aktiengesellschaft',
    symbol: 'BEI.DE',
  },
  {
    value: 'HEN3.DE',
    label: 'HEN3.DE,  Henkel AG & Co. KGaA',
    name: 'Henkel AG & Co. KGaA',
    symbol: 'HEN3.DE',
  },
  {
    value: 'KWS.DE',
    label: 'KWS.DE,  KWS SAAT SE & Co. KGaA',
    name: 'KWS SAAT SE & Co. KGaA',
    symbol: 'KWS.DE',
  },
  {
    value: 'VBK.DE',
    label: 'VBK.DE,  VERBIO Vereinigte BioEnergie AG',
    name: 'VERBIO Vereinigte BioEnergie AG',
    symbol: 'VBK.DE',
  },
  {
    value: 'WDP.DE',
    label: 'WDP.DE,  The Walt Disney Company',
    name: 'The Walt Disney Company',
    symbol: 'WDP.DE',
  },
  {
    value: 'M8H.DE',
    label: 'M8H.DE,  MBH Corporation PLC',
    name: 'MBH Corporation PLC',
    symbol: 'M8H.DE',
  },
  {
    value: 'NVJP.DE',
    label: 'NVJP.DE,  Umicore SA',
    name: 'Umicore SA',
    symbol: 'NVJP.DE',
  },
  {
    value: 'MZX.DE',
    label: 'MZX.DE,  Masterflex SE',
    name: 'Masterflex SE',
    symbol: 'MZX.DE',
  },
  {
    value: 'NEM.DE',
    label: 'NEM.DE,  Nemetschek SE',
    name: 'Nemetschek SE',
    symbol: 'NEM.DE',
  },
  {
    value: 'UT8.DE',
    label: 'UT8.DE,  Uber Technologies Inc',
    name: 'Uber Technologies Inc',
    symbol: 'UT8.DE',
  },
  {
    value: 'AMZ.DE',
    label: 'AMZ.DE,  Amazon.com, Inc.',
    name: 'Amazon.com, Inc.',
    symbol: 'AMZ.DE',
  },
  {
    value: 'T3T1.DE',
    label: 'T3T1.DE,  Seven Principles AG',
    name: 'Seven Principles AG',
    symbol: 'T3T1.DE',
  },
  {
    value: 'EXC.DE',
    label: 'EXC.DE,  exceet Group SCA',
    name: 'exceet Group SCA',
    symbol: 'EXC.DE',
  },
  {
    value: 'PAG.DE',
    label: 'PAG.DE,  PREOS Global Office Real Estate & Technology AG',
    name: 'PREOS Global Office Real Estate & Technology AG',
    symbol: 'PAG.DE',
  },
  {
    value: 'DMRE.DE',
    label: 'DMRE.DE,  DEMIRE Deutsche Mittelstand Real Estate AG',
    name: 'DEMIRE Deutsche Mittelstand Real Estate AG',
    symbol: 'DMRE.DE',
  },
  {
    value: 'DEX.DE',
    label: 'DEX.DE,  Delticom AG',
    name: 'Delticom AG',
    symbol: 'DEX.DE',
  },
  {
    value: 'T5O.DE',
    label: 'T5O.DE,  bioXXmed AG',
    name: 'bioXXmed AG',
    symbol: 'T5O.DE',
  },
  {
    value: 'YOC.DE',
    label: 'YOC.DE,  YOC AG',
    name: 'YOC AG',
    symbol: 'YOC.DE',
  },
  {
    value: 'SBS.DE',
    label: 'SBS.DE,  Stratec SE',
    name: 'Stratec SE',
    symbol: 'SBS.DE',
  },
  {
    value: 'BIO.DE',
    label: 'BIO.DE,  Biotest Aktiengesellschaft',
    name: 'Biotest Aktiengesellschaft',
    symbol: 'BIO.DE',
  },
  {
    value: 'BZF1.DE',
    label: 'BZF1.DE,  VMware, Inc.',
    name: 'VMware, Inc.',
    symbol: 'BZF1.DE',
  },
  {
    value: 'AFX.DE',
    label: 'AFX.DE,  Carl Zeiss Meditec AG',
    name: 'Carl Zeiss Meditec AG',
    symbol: 'AFX.DE',
  },
  {
    value: 'ALD.DE',
    label: 'ALD.DE,  Honeywell International Inc.',
    name: 'Honeywell International Inc.',
    symbol: 'ALD.DE',
  },
  {
    value: '185.DE',
    label: '185.DE,  Alcoa Corp',
    name: 'Alcoa Corp',
    symbol: '185.DE',
  },
  {
    value: 'VAR1.DE',
    label: 'VAR1.DE,  Varta AG',
    name: 'Varta AG',
    symbol: 'VAR1.DE',
  },
  {
    value: 'ITN.DE',
    label: 'ITN.DE,  Intertainment AG',
    name: 'Intertainment AG',
    symbol: 'ITN.DE',
  },
  {
    value: '3LN.DE',
    label: '3LN.DE,  Live Nation Entertainment Inc',
    name: 'Live Nation Entertainment Inc',
    symbol: '3LN.DE',
  },
  {
    value: '2HP.DE',
    label: '2HP.DE,  Hewlett Packard Enterprise Company',
    name: 'Hewlett Packard Enterprise Company',
    symbol: '2HP.DE',
  },
  {
    value: 'TM5.DE',
    label: 'TM5.DE,  T-Mobile US Inc',
    name: 'T-Mobile US Inc',
    symbol: 'TM5.DE',
  },
  {
    value: 'ADE.DE',
    label: 'ADE.DE,  Bitcoin Group SE',
    name: 'Bitcoin Group SE',
    symbol: 'ADE.DE',
  },
  {
    value: 'MSF.DE',
    label: 'MSF.DE,  Microsoft Corporation',
    name: 'Microsoft Corporation',
    symbol: 'MSF.DE',
  },
  {
    value: 'TKA.DE',
    label: 'TKA.DE,  thyssenkrupp AG',
    name: 'thyssenkrupp AG',
    symbol: 'TKA.DE',
  },
  {
    value: 'FDX.DE',
    label: 'FDX.DE,  FedEx Corporation',
    name: 'FedEx Corporation',
    symbol: 'FDX.DE',
  },
  {
    value: 'D6H.DE',
    label: 'D6H.DE,  DATAGROUP SE',
    name: 'DATAGROUP SE',
    symbol: 'D6H.DE',
  },
  {
    value: 'TNE5.DE',
    label: 'TNE5.DE,  Telefónica, S.A.',
    name: 'Telefónica, S.A.',
    symbol: 'TNE5.DE',
  },
  {
    value: 'HMU.DE',
    label: 'HMU.DE,  HMS Bergbau AG',
    name: 'HMS Bergbau AG',
    symbol: 'HMU.DE',
  },
  {
    value: '8GM.DE',
    label: '8GM.DE,  General Motors Company',
    name: 'General Motors Company',
    symbol: '8GM.DE',
  },
  {
    value: 'AIY.DE',
    label: 'AIY.DE,  Activision Blizzard Inc',
    name: 'Activision Blizzard Inc',
    symbol: 'AIY.DE',
  },
  {
    value: 'SD1.DE',
    label: 'SD1.DE,  Clean Logistics SE',
    name: 'Clean Logistics SE',
    symbol: 'SD1.DE',
  },
  {
    value: 'REP.DE',
    label: 'REP.DE,  Repsol, S.A.',
    name: 'Repsol, S.A.',
    symbol: 'REP.DE',
  },
  {
    value: 'HRS.DE',
    label: 'HRS.DE,  L3Harris Technologies Inc',
    name: 'L3Harris Technologies Inc',
    symbol: 'HRS.DE',
  },
  {
    value: 'BCY.DE',
    label: 'BCY.DE,  Barclays PLC',
    name: 'Barclays PLC',
    symbol: 'BCY.DE',
  },
  {
    value: 'VX1.DE',
    label: 'VX1.DE,  Vertex Pharmaceuticals Incorporated',
    name: 'Vertex Pharmaceuticals Incorporated',
    symbol: 'VX1.DE',
  },
  {
    value: 'NTA.DE',
    label: 'NTA.DE,  NetApp, Inc.',
    name: 'NetApp, Inc.',
    symbol: 'NTA.DE',
  },
  {
    value: 'TEG.DE',
    label: 'TEG.DE,  TAG Immobilien AG',
    name: 'TAG Immobilien AG',
    symbol: 'TEG.DE',
  },
  {
    value: 'INL.DE',
    label: 'INL.DE,  Intel Corporation',
    name: 'Intel Corporation',
    symbol: 'INL.DE',
  },
  {
    value: 'RIO1.DE',
    label: 'RIO1.DE,  Rio Tinto Group',
    name: 'Rio Tinto Group',
    symbol: 'RIO1.DE',
  },
  {
    value: 'SY1.DE',
    label: 'SY1.DE,  Symrise AG',
    name: 'Symrise AG',
    symbol: 'SY1.DE',
  },
  {
    value: 'AWC.DE',
    label: 'AWC.DE,  American Water Works Company, Inc.',
    name: 'American Water Works Company, Inc.',
    symbol: 'AWC.DE',
  },
  {
    value: 'CRZK.DE',
    label: 'CRZK.DE,  CR Capital AG',
    name: 'CR Capital AG',
    symbol: 'CRZK.DE',
  },
  {
    value: 'DMP.DE',
    label: 'DMP.DE,  Dermapharm Holding SE',
    name: 'Dermapharm Holding SE',
    symbol: 'DMP.DE',
  },
  {
    value: 'HLE.DE',
    label: 'HLE.DE,  HELLA GmbH & Co. KGaA',
    name: 'HELLA GmbH & Co. KGaA',
    symbol: 'HLE.DE',
  },
  {
    value: 'OMV.DE',
    label: 'OMV.DE,  OMV Aktiengesellschaft',
    name: 'OMV Aktiengesellschaft',
    symbol: 'OMV.DE',
  },
  {
    value: 'N4G.DE',
    label: 'N4G.DE,  The NAGA Group AG',
    name: 'The NAGA Group AG',
    symbol: 'N4G.DE',
  },
  {
    value: 'NMM.DE',
    label: 'NMM.DE,  Newmont Corporation',
    name: 'Newmont Corporation',
    symbol: 'NMM.DE',
  },
  {
    value: 'SJJ.DE',
    label: 'SJJ.DE,  Serviceware SE',
    name: 'Serviceware SE',
    symbol: 'SJJ.DE',
  },
  {
    value: 'PHM7.DE',
    label: 'PHM7.DE,  Altria Group, Inc.',
    name: 'Altria Group, Inc.',
    symbol: 'PHM7.DE',
  },
  {
    value: 'ABL.DE',
    label: 'ABL.DE,  Abbott Laboratories',
    name: 'Abbott Laboratories',
    symbol: 'ABL.DE',
  },
  {
    value: 'NXU.DE',
    label: 'NXU.DE,  Nexus AG',
    name: 'Nexus AG',
    symbol: 'NXU.DE',
  },
  {
    value: 'INS.DE',
    label: 'INS.DE,  Instone Real Estate Group SE',
    name: 'Instone Real Estate Group SE',
    symbol: 'INS.DE',
  },
  {
    value: 'PEO.DE',
    label: 'PEO.DE,  Exelon Corporation',
    name: 'Exelon Corporation',
    symbol: 'PEO.DE',
  },
  {
    value: 'CFC.DE',
    label: 'CFC.DE,  UET United Electronic Technology AG',
    name: 'UET United Electronic Technology AG',
    symbol: 'CFC.DE',
  },
  {
    value: 'APC.DE',
    label: 'APC.DE,  Apple Inc.',
    name: 'Apple Inc.',
    symbol: 'APC.DE',
  },
  {
    value: 'PHI1.DE',
    label: 'PHI1.DE,  Koninklijke Philips N.V.',
    name: 'Koninklijke Philips N.V.',
    symbol: 'PHI1.DE',
  },
  {
    value: 'FAS.DE',
    label: 'FAS.DE,  Fastenal Co',
    name: 'Fastenal Co',
    symbol: 'FAS.DE',
  },
  {
    value: 'B8A.DE',
    label: 'B8A.DE,  BAVARIA Industries Group AG',
    name: 'BAVARIA Industries Group AG',
    symbol: 'B8A.DE',
  },
  {
    value: 'AEC1.DE',
    label: 'AEC1.DE,  American Express Company',
    name: 'American Express Company',
    symbol: 'AEC1.DE',
  },
  {
    value: 'A7A.DE',
    label: 'A7A.DE,  FinLab AG',
    name: 'FinLab AG',
    symbol: 'A7A.DE',
  },
  {
    value: 'NB2.DE',
    label: 'NB2.DE,  Northern Data AG',
    name: 'Northern Data AG',
    symbol: 'NB2.DE',
  },
  {
    value: 'TPE.DE',
    label: 'TPE.DE,  PVA TePla AG',
    name: 'PVA TePla AG',
    symbol: 'TPE.DE',
  },
  {
    value: 'DKG.DE',
    label: 'DKG.DE,  Deutsche Konsum REIT-AG',
    name: 'Deutsche Konsum REIT-AG',
    symbol: 'DKG.DE',
  },
  {
    value: 'LSX.DE',
    label: 'LSX.DE,  LS telcom AG',
    name: 'LS telcom AG',
    symbol: 'LSX.DE',
  },
  {
    value: '7HP.DE',
    label: '7HP.DE,  HP Inc',
    name: 'HP Inc',
    symbol: '7HP.DE',
  },
  {
    value: 'AM3D.DE',
    label: 'AM3D.DE,  SLM Solutions Group AG',
    name: 'SLM Solutions Group AG',
    symbol: 'AM3D.DE',
  },
  {
    value: 'CIT.DE',
    label: 'CIT.DE,  Cintas Corp',
    name: 'Cintas Corp',
    symbol: 'CIT.DE',
  },
  {
    value: 'FIE.DE',
    label: 'FIE.DE,  Fielmann Aktiengesellschaft',
    name: 'Fielmann Aktiengesellschaft',
    symbol: 'FIE.DE',
  },
  {
    value: 'IXX.DE',
    label: 'IXX.DE,  init innovation in traffic systems SE',
    name: 'init innovation in traffic systems SE',
    symbol: 'IXX.DE',
  },
  {
    value: 'JEN.DE',
    label: 'JEN.DE,  Jenoptik AG',
    name: 'Jenoptik AG',
    symbol: 'JEN.DE',
  },
  {
    value: 'TIMA.DE',
    label: 'TIMA.DE,  ZEAL Network SE',
    name: 'ZEAL Network SE',
    symbol: 'TIMA.DE',
  },
  {
    value: 'LXS.DE',
    label: 'LXS.DE,  LANXESS Aktiengesellschaft',
    name: 'LANXESS Aktiengesellschaft',
    symbol: 'LXS.DE',
  },
  {
    value: '5G5.DE',
    label: '5G5.DE,  GoPro Inc',
    name: 'GoPro Inc',
    symbol: '5G5.DE',
  },
  {
    value: 'OPC.DE',
    label: 'OPC.DE,  Occidental Petroleum Corporation',
    name: 'Occidental Petroleum Corporation',
    symbol: 'OPC.DE',
  },
  {
    value: 'BLQA.DE',
    label: 'BLQA.DE,  BlackRock, Inc.',
    name: 'BlackRock, Inc.',
    symbol: 'BLQA.DE',
  },
  {
    value: 'MCE.DE',
    label: 'MCE.DE,  MEDIQON Group AG',
    name: 'MEDIQON Group AG',
    symbol: 'MCE.DE',
  },
  {
    value: 'NWT.DE',
    label: 'NWT.DE,  Wells Fargo & Company',
    name: 'Wells Fargo & Company',
    symbol: 'NWT.DE',
  },
  {
    value: 'SVE.DE',
    label: 'SVE.DE,  Shareholder Value Beteiligungen AG',
    name: 'Shareholder Value Beteiligungen AG',
    symbol: 'SVE.DE',
  },
  {
    value: 'GOB.DE',
    label: 'GOB.DE,  Compagnie de Saint-Gobain S.A.',
    name: 'Compagnie de Saint-Gobain S.A.',
    symbol: 'GOB.DE',
  },
  {
    value: 'SHL.DE',
    label: 'SHL.DE,  Siemens Healthineers AG',
    name: 'Siemens Healthineers AG',
    symbol: 'SHL.DE',
  },
  {
    value: 'NF4.DE',
    label: 'NF4.DE,  Netfonds AG',
    name: 'Netfonds AG',
    symbol: 'NF4.DE',
  },
  {
    value: 'POH1.DE',
    label: 'POH1.DE,  Carnival PLC',
    name: 'Carnival PLC',
    symbol: 'POH1.DE',
  },
  {
    value: 'BAS.DE',
    label: 'BAS.DE,  BASF SE',
    name: 'BASF SE',
    symbol: 'BAS.DE',
  },
  {
    value: 'FDO.DE',
    label: "FDO.DE,  Macy's Inc",
    name: "Macy's Inc",
    symbol: 'FDO.DE',
  },
  {
    value: 'SIS.DE',
    label: 'SIS.DE,  First Sensor AG',
    name: 'First Sensor AG',
    symbol: 'SIS.DE',
  },
  {
    value: 'PER.DE',
    label: 'PER.DE,  Pernod Ricard SA',
    name: 'Pernod Ricard SA',
    symbol: 'PER.DE',
  },
  {
    value: 'VNA.DE',
    label: 'VNA.DE,  Vonovia SE',
    name: 'Vonovia SE',
    symbol: 'VNA.DE',
  },
  {
    value: 'DEF.DE',
    label: 'DEF.DE,  DEFAMA Deutsche Fachmarkt AG',
    name: 'DEFAMA Deutsche Fachmarkt AG',
    symbol: 'DEF.DE',
  },
  {
    value: '0Q3.DE',
    label: '0Q3.DE,  Beyond Meat Inc',
    name: 'Beyond Meat Inc',
    symbol: '0Q3.DE',
  },
  {
    value: 'WSU.DE',
    label: 'WSU.DE,  WashTec AG',
    name: 'WashTec AG',
    symbol: 'WSU.DE',
  },
  {
    value: 'KWG.DE',
    label: 'KWG.DE,  KHD Humboldt Wedag International AG',
    name: 'KHD Humboldt Wedag International AG',
    symbol: 'KWG.DE',
  },
  {
    value: 'S9I.DE',
    label: 'S9I.DE,  Stemmer Imaging AG',
    name: 'Stemmer Imaging AG',
    symbol: 'S9I.DE',
  },
  {
    value: 'HPHA.DE',
    label: 'HPHA.DE,  Heidelberg Pharma AG',
    name: 'Heidelberg Pharma AG',
    symbol: 'HPHA.DE',
  },
  {
    value: 'GTY.DE',
    label: 'GTY.DE,  Gateway Real Estate AG',
    name: 'Gateway Real Estate AG',
    symbol: 'GTY.DE',
  },
  {
    value: 'LEG.DE',
    label: 'LEG.DE,  LEG Immobilien SE',
    name: 'LEG Immobilien SE',
    symbol: 'LEG.DE',
  },
  {
    value: 'ACX.DE',
    label: 'ACX.DE,  bet-at-home.com AG',
    name: 'bet-at-home.com AG',
    symbol: 'ACX.DE',
  },
  {
    value: 'CE2.DE',
    label: 'CE2.DE,  CropEnergies AG',
    name: 'CropEnergies AG',
    symbol: 'CE2.DE',
  },
  {
    value: '0YU.DE',
    label: '0YU.DE,  Yum China Holdings Inc',
    name: 'Yum China Holdings Inc',
    symbol: '0YU.DE',
  },
  {
    value: 'KHNZ.DE',
    label: 'KHNZ.DE,  The Kraft Heinz Company',
    name: 'The Kraft Heinz Company',
    symbol: 'KHNZ.DE',
  },
  {
    value: 'LPK.DE',
    label: 'LPK.DE,  LPKF Laser & Electronics AG',
    name: 'LPKF Laser & Electronics AG',
    symbol: 'LPK.DE',
  },
  {
    value: 'BYW6.DE',
    label: 'BYW6.DE,  BayWa Aktiengesellschaft',
    name: 'BayWa Aktiengesellschaft',
    symbol: 'BYW6.DE',
  },
  {
    value: '2M6.DE',
    label: '2M6.DE,  Medtronic plc',
    name: 'Medtronic plc',
    symbol: '2M6.DE',
  },
  {
    value: 'WIB.DE',
    label: 'WIB.DE,  Wienerberger AG',
    name: 'Wienerberger AG',
    symbol: 'WIB.DE',
  },
  {
    value: 'NGLB.DE',
    label: 'NGLB.DE,  Anglo American plc',
    name: 'Anglo American plc',
    symbol: 'NGLB.DE',
  },
  {
    value: 'CLIQ.DE',
    label: 'CLIQ.DE,  Cliq Digital AG',
    name: 'Cliq Digital AG',
    symbol: 'CLIQ.DE',
  },
  {
    value: '2XT.DE',
    label: '2XT.DE,  DXC Technology Co',
    name: 'DXC Technology Co',
    symbol: '2XT.DE',
  },
  {
    value: 'HEI.DE',
    label: 'HEI.DE,  HeidelbergCement AG',
    name: 'HeidelbergCement AG',
    symbol: 'HEI.DE',
  },
  {
    value: 'U9R.DE',
    label: 'U9R.DE,  Under Armour, Inc.',
    name: 'Under Armour, Inc.',
    symbol: 'U9R.DE',
  },
  {
    value: 'HDI.DE',
    label: 'HDI.DE,  The Home Depot, Inc.',
    name: 'The Home Depot, Inc.',
    symbol: 'HDI.DE',
  },
  {
    value: 'AHOG.DE',
    label: 'AHOG.DE,  Koninklijke Ahold Delhaize N.V.',
    name: 'Koninklijke Ahold Delhaize N.V.',
    symbol: 'AHOG.DE',
  },
  {
    value: 'INP.DE',
    label: 'INP.DE,  International Paper Company',
    name: 'International Paper Company',
    symbol: 'INP.DE',
  },
  {
    value: 'INN1.DE',
    label: 'INN1.DE,  ING Groep N.V.',
    name: 'ING Groep N.V.',
    symbol: 'INN1.DE',
  },
  {
    value: 'EBA.DE',
    label: 'EBA.DE,  eBay Inc.',
    name: 'eBay Inc.',
    symbol: 'EBA.DE',
  },
  {
    value: 'VIH1.DE',
    label: 'VIH1.DE,  VIB Vermögen AG',
    name: 'VIB Vermögen AG',
    symbol: 'VIH1.DE',
  },
  {
    value: 'BNN.DE',
    label: 'BNN.DE,  BRAIN Biotech AG',
    name: 'BRAIN Biotech AG',
    symbol: 'BNN.DE',
  },
  {
    value: 'AEP.DE',
    label: 'AEP.DE,  American Electric Power Co Inc',
    name: 'American Electric Power Co Inc',
    symbol: 'AEP.DE',
  },
  {
    value: 'G6P.DE',
    label: 'G6P.DE,  Going Public Media Aktiengesellschaft',
    name: 'Going Public Media Aktiengesellschaft',
    symbol: 'G6P.DE',
  },
  {
    value: 'ALS.DE',
    label: 'ALS.DE,  The Allstate Corporation',
    name: 'The Allstate Corporation',
    symbol: 'ALS.DE',
  },
  {
    value: 'AP2.DE',
    label: 'AP2.DE,  Applied Materials, Inc.',
    name: 'Applied Materials, Inc.',
    symbol: 'AP2.DE',
  },
  {
    value: 'ELB.DE',
    label: 'ELB.DE,  elumeo SE',
    name: 'elumeo SE',
    symbol: 'ELB.DE',
  },
  {
    value: 'DC7.DE',
    label: 'DC7.DE,  Discover Financial Services',
    name: 'Discover Financial Services',
    symbol: 'DC7.DE',
  },
  {
    value: 'UMDK.DE',
    label: 'UMDK.DE,  UMT United Mobility Technology AG',
    name: 'UMT United Mobility Technology AG',
    symbol: 'UMDK.DE',
  },
  {
    value: 'AK3.DE',
    label: 'AK3.DE,  Akamai Technologies, Inc.',
    name: 'Akamai Technologies, Inc.',
    symbol: 'AK3.DE',
  },
  {
    value: 'CRIN.DE',
    label: 'CRIN.DE,  UniCredit SpA',
    name: 'UniCredit SpA',
    symbol: 'CRIN.DE',
  },
  {
    value: '2FE.DE',
    label: '2FE.DE,  Ferrari N.V.',
    name: 'Ferrari N.V.',
    symbol: '2FE.DE',
  },
  {
    value: 'NCB.DE',
    label: 'NCB.DE,  Bank of America Corporation',
    name: 'Bank of America Corporation',
    symbol: 'NCB.DE',
  },
  {
    value: 'TOTB.DE',
    label: 'TOTB.DE,  TotalEnergies SE',
    name: 'TotalEnergies SE',
    symbol: 'TOTB.DE',
  },
  {
    value: 'V3V.DE',
    label: 'V3V.DE,  VITA 34 AG',
    name: 'VITA 34 AG',
    symbol: 'V3V.DE',
  },
  {
    value: 'PUM.DE',
    label: 'PUM.DE,  PUMA SE',
    name: 'PUMA SE',
    symbol: 'PUM.DE',
  },
  {
    value: 'GAG.DE',
    label: 'GAG.DE,  GORE German Office Real Estate AG',
    name: 'GORE German Office Real Estate AG',
    symbol: 'GAG.DE',
  },
  {
    value: 'NP5.DE',
    label: 'NP5.DE,  Newron Pharmaceuticals SpA',
    name: 'Newron Pharmaceuticals SpA',
    symbol: 'NP5.DE',
  },
  {
    value: 'FMV.DE',
    label: 'FMV.DE,  First Majestic Silver Corp',
    name: 'First Majestic Silver Corp',
    symbol: 'FMV.DE',
  },
  {
    value: 'TL0.DE',
    label: 'TL0.DE,  Tesla, Inc.',
    name: 'Tesla, Inc.',
    symbol: 'TL0.DE',
  },
  {
    value: 'GOS.DE',
    label: 'GOS.DE,  The Goldman Sachs Group, Inc.',
    name: 'The Goldman Sachs Group, Inc.',
    symbol: 'GOS.DE',
  },
  {
    value: 'OBS.DE',
    label: 'OBS.DE,  ORBIS AG',
    name: 'ORBIS AG',
    symbol: 'OBS.DE',
  },
  {
    value: 'VODI.DE',
    label: 'VODI.DE,  Vodafone Group Plc',
    name: 'Vodafone Group Plc',
    symbol: 'VODI.DE',
  },
  {
    value: 'BRM.DE',
    label: 'BRM.DE,  Bristol-Myers Squibb Company',
    name: 'Bristol-Myers Squibb Company',
    symbol: 'BRM.DE',
  },
  {
    value: 'H24.DE',
    label: 'H24.DE,  home24 SE',
    name: 'home24 SE',
    symbol: 'H24.DE',
  },
  {
    value: 'CDM1.DE',
    label: 'CDM1.DE,  Coeur Mining, Inc.',
    name: 'Coeur Mining, Inc.',
    symbol: 'CDM1.DE',
  },
  {
    value: 'CCC3.DE',
    label: 'CCC3.DE,  The Coca-Cola Company',
    name: 'The Coca-Cola Company',
    symbol: 'CCC3.DE',
  },
  {
    value: 'BFSA.DE',
    label: 'BFSA.DE,  Befesa S.A.',
    name: 'Befesa S.A.',
    symbol: 'BFSA.DE',
  },
  {
    value: '6MK.DE',
    label: '6MK.DE,  Merck & Co., Inc.',
    name: 'Merck & Co., Inc.',
    symbol: '6MK.DE',
  },
  {
    value: 'O2C.DE',
    label: 'O2C.DE,  Petro Welt Technologies AG',
    name: 'Petro Welt Technologies AG',
    symbol: 'O2C.DE',
  },
  {
    value: 'SWA.DE',
    label: 'SWA.DE,  Schloss Wachenheim AG',
    name: 'Schloss Wachenheim AG',
    symbol: 'SWA.DE',
  },
  {
    value: 'TTR1.DE',
    label: 'TTR1.DE,  technotrans SE',
    name: 'technotrans SE',
    symbol: 'TTR1.DE',
  },
  {
    value: 'GLJ.DE',
    label: 'GLJ.DE,  Grenke AG',
    name: 'Grenke AG',
    symbol: 'GLJ.DE',
  },
  {
    value: 'FMC1.DE',
    label: 'FMC1.DE,  Ford Motor Company',
    name: 'Ford Motor Company',
    symbol: 'FMC1.DE',
  },
  {
    value: 'AZ2.DE',
    label: 'AZ2.DE,  Andritz AG',
    name: 'Andritz AG',
    symbol: 'AZ2.DE',
  },
  {
    value: 'ERT.DE',
    label: 'ERT.DE,  Electronic Arts Inc',
    name: 'Electronic Arts Inc',
    symbol: 'ERT.DE',
  },
  {
    value: 'VIB3.DE',
    label: 'VIB3.DE,  Villeroy & Boch AG',
    name: 'Villeroy & Boch AG',
    symbol: 'VIB3.DE',
  },
  {
    value: 'E8X.DE',
    label: 'E8X.DE,  elexxion AG',
    name: 'elexxion AG',
    symbol: 'E8X.DE',
  },
  {
    value: 'IES.DE',
    label: 'IES.DE,  Intesa Sanpaolo S.p.A.',
    name: 'Intesa Sanpaolo S.p.A.',
    symbol: 'IES.DE',
  },
  {
    value: 'SDF.DE',
    label: 'SDF.DE,  K+S Aktiengesellschaft',
    name: 'K+S Aktiengesellschaft',
    symbol: 'SDF.DE',
  },
  {
    value: 'SYZ.DE',
    label: 'SYZ.DE,  SYZYGY AG',
    name: 'SYZYGY AG',
    symbol: 'SYZ.DE',
  },
  {
    value: 'A1G.DE',
    label: 'A1G.DE,  American Airlines Group Inc.',
    name: 'American Airlines Group Inc.',
    symbol: 'A1G.DE',
  },
  {
    value: 'HBC1.DE',
    label: 'HBC1.DE,  HSBC Holdings plc',
    name: 'HSBC Holdings plc',
    symbol: 'HBC1.DE',
  },
  {
    value: 'HS2.DE',
    label: 'HS2.DE,  Henry Schein Inc',
    name: 'Henry Schein Inc',
    symbol: 'HS2.DE',
  },
  {
    value: 'DLX.DE',
    label: 'DLX.DE,  Delignit AG',
    name: 'Delignit AG',
    symbol: 'DLX.DE',
  },
  {
    value: 'O3P.DE',
    label: 'O3P.DE,  Oesterreichische Post AG',
    name: 'Oesterreichische Post AG',
    symbol: 'O3P.DE',
  },
  {
    value: 'GXI.DE',
    label: 'GXI.DE,  Gerresheimer AG',
    name: 'Gerresheimer AG',
    symbol: 'GXI.DE',
  },
  {
    value: '1FC.DE',
    label: '1FC.DE,  FACC AG',
    name: 'FACC AG',
    symbol: '1FC.DE',
  },
  {
    value: 'CIS.DE',
    label: 'CIS.DE,  Cisco Systems, Inc.',
    name: 'Cisco Systems, Inc.',
    symbol: 'CIS.DE',
  },
  {
    value: 'AMMN.DE',
    label: 'AMMN.DE,  The Grounds Real Estate Development AG',
    name: 'The Grounds Real Estate Development AG',
    symbol: 'AMMN.DE',
  },
  {
    value: 'MTT.DE',
    label: 'MTT.DE,  Mattel, Inc.',
    name: 'Mattel, Inc.',
    symbol: 'MTT.DE',
  },
  {
    value: 'PAH3.DE',
    label: 'PAH3.DE,  Porsche Automobil Holding SE',
    name: 'Porsche Automobil Holding SE',
    symbol: 'PAH3.DE',
  },
  {
    value: 'CYR.DE',
    label: 'CYR.DE,  cyan AG',
    name: 'cyan AG',
    symbol: 'CYR.DE',
  },
  {
    value: 'HPBK.DE',
    label: 'HPBK.DE,  Heliad Equity Partners GmbH & Co. KGaA',
    name: 'Heliad Equity Partners GmbH & Co. KGaA',
    symbol: 'HPBK.DE',
  },
  {
    value: 'CTP2.DE',
    label: 'CTP2.DE,  Comcast Corporation',
    name: 'Comcast Corporation',
    symbol: 'CTP2.DE',
  },
  {
    value: 'ASG.DE',
    label: 'ASG.DE,  Assicurazioni Generali S.p.A.',
    name: 'Assicurazioni Generali S.p.A.',
    symbol: 'ASG.DE',
  },
  {
    value: 'HMSB.DE',
    label: 'HMSB.DE,  H & M Hennes & Mauritz AB (publ)',
    name: 'H & M Hennes & Mauritz AB (publ)',
    symbol: 'HMSB.DE',
  },
  {
    value: 'IS8.DE',
    label: 'IS8.DE,  ifa systems AG',
    name: 'ifa systems AG',
    symbol: 'IS8.DE',
  },
  {
    value: '2HRA.DE',
    label: '2HRA.DE,  H&R GmbH & Co. KGaA',
    name: 'H&R GmbH & Co. KGaA',
    symbol: '2HRA.DE',
  },
  {
    value: 'CEC.DE',
    label: 'CEC.DE,  Ceconomy AG',
    name: 'Ceconomy AG',
    symbol: 'CEC.DE',
  },
  {
    value: '3V64.DE',
    label: '3V64.DE,  Visa Inc.',
    name: 'Visa Inc.',
    symbol: '3V64.DE',
  },
  {
    value: 'HFG.DE',
    label: 'HFG.DE,  HelloFresh SE',
    name: 'HelloFresh SE',
    symbol: 'HFG.DE',
  },
  {
    value: 'PFE.DE',
    label: 'PFE.DE,  Pfizer Inc.',
    name: 'Pfizer Inc.',
    symbol: 'PFE.DE',
  },
  {
    value: 'SOBA.DE',
    label: 'SOBA.DE,  AT&T Inc.',
    name: 'AT&T Inc.',
    symbol: 'SOBA.DE',
  },
  {
    value: '2OY.DE',
    label: '2OY.DE,  Dow Inc',
    name: 'Dow Inc',
    symbol: '2OY.DE',
  },
  {
    value: 'SHA.DE',
    label: 'SHA.DE,  Schaeffler AG',
    name: 'Schaeffler AG',
    symbol: 'SHA.DE',
  },
  {
    value: 'AUD.DE',
    label: 'AUD.DE,  Autodesk, Inc.',
    name: 'Autodesk, Inc.',
    symbol: 'AUD.DE',
  },
  {
    value: 'ITB.DE',
    label: 'ITB.DE,  Imperial Brands PLC',
    name: 'Imperial Brands PLC',
    symbol: 'ITB.DE',
  },
  {
    value: 'SIE.DE',
    label: 'SIE.DE,  Siemens Aktiengesellschaft',
    name: 'Siemens Aktiengesellschaft',
    symbol: 'SIE.DE',
  },
  {
    value: 'AUS.DE',
    label:
      'AUS.DE,  AT & S Austria Technologie & Systemtechnik Aktiengesellschaft',
    name: 'AT & S Austria Technologie & Systemtechnik Aktiengesellschaft',
    symbol: 'AUS.DE',
  },
  {
    value: 'CSH.DE',
    label: 'CSH.DE,  CENIT Aktiengesellschaft',
    name: 'CENIT Aktiengesellschaft',
    symbol: 'CSH.DE',
  },
  {
    value: 'ERWE.DE',
    label: 'ERWE.DE,  ERWE Immobilien AG',
    name: 'ERWE Immobilien AG',
    symbol: 'ERWE.DE',
  },
  {
    value: 'EOAN.DE',
    label: 'EOAN.DE,  E.ON SE',
    name: 'E.ON SE',
    symbol: 'EOAN.DE',
  },
  {
    value: 'KGX.DE',
    label: 'KGX.DE,  KION GROUP AG',
    name: 'KION GROUP AG',
    symbol: 'KGX.DE',
  },
  {
    value: 'ADB.DE',
    label: 'ADB.DE,  Adobe Inc.',
    name: 'Adobe Inc.',
    symbol: 'ADB.DE',
  },
  {
    value: 'DCO.DE',
    label: 'DCO.DE,  Deere & Company',
    name: 'Deere & Company',
    symbol: 'DCO.DE',
  },
  {
    value: 'EFF.DE',
    label:
      'EFF.DE,  Deutsche Effecten- und Wechsel-Beteiligungsgesellschaft AG',
    name: 'Deutsche Effecten- und Wechsel-Beteiligungsgesellschaft AG',
    symbol: 'EFF.DE',
  },
  {
    value: 'NFC.DE',
    label: 'NFC.DE,  Netflix, Inc.',
    name: 'Netflix, Inc.',
    symbol: 'NFC.DE',
  },
  {
    value: 'GBF.DE',
    label: 'GBF.DE,  Bilfinger SE',
    name: 'Bilfinger SE',
    symbol: 'GBF.DE',
  },
  {
    value: 'ILU.DE',
    label: 'ILU.DE,  Illumina, Inc.',
    name: 'Illumina, Inc.',
    symbol: 'ILU.DE',
  },
  {
    value: 'CSC.DE',
    label: 'CSC.DE,  Campbell Soup Co',
    name: 'Campbell Soup Co',
    symbol: 'CSC.DE',
  },
  {
    value: 'ADJ.DE',
    label: 'ADJ.DE,  Adler Group S.A.',
    name: 'Adler Group S.A.',
    symbol: 'ADJ.DE',
  },
  {
    value: 'MOH.DE',
    label: 'MOH.DE,  LVMH Moët Hennessy - Louis Vuitton, Société Européenne',
    name: 'LVMH Moët Hennessy - Louis Vuitton, Société Européenne',
    symbol: 'MOH.DE',
  },
  {
    value: 'EBE.DE',
    label: 'EBE.DE,  aifinyo AG',
    name: 'aifinyo AG',
    symbol: 'EBE.DE',
  },
  {
    value: 'TTO.DE',
    label: 'TTO.DE,  TTL Beteiligungs- und Grundbesitz-AG',
    name: 'TTL Beteiligungs- und Grundbesitz-AG',
    symbol: 'TTO.DE',
  },
  {
    value: 'VVV3.DE',
    label: 'VVV3.DE,  ÖKOWORLD AG',
    name: 'ÖKOWORLD AG',
    symbol: 'VVV3.DE',
  },
  {
    value: 'HHFA.DE',
    label: 'HHFA.DE,  Hamburger Hafen und Logistik Aktiengesellschaft',
    name: 'Hamburger Hafen und Logistik Aktiengesellschaft',
    symbol: 'HHFA.DE',
  },
  {
    value: 'IFX.DE',
    label: 'IFX.DE,  Infineon Technologies AG',
    name: 'Infineon Technologies AG',
    symbol: 'IFX.DE',
  },
  {
    value: 'MUB.DE',
    label: 'MUB.DE,  Mühlbauer Holding AG',
    name: 'Mühlbauer Holding AG',
    symbol: 'MUB.DE',
  },
  {
    value: 'HBH.DE',
    label: 'HBH.DE,  HORNBACH Holding AG & Co. KGaA',
    name: 'HORNBACH Holding AG & Co. KGaA',
    symbol: 'HBH.DE',
  },
  {
    value: 'EIN3.DE',
    label: 'EIN3.DE,  Einhell Germany AG',
    name: 'Einhell Germany AG',
    symbol: 'EIN3.DE',
  },
  {
    value: 'AOX.DE',
    label: 'AOX.DE,  alstria office REIT-AG',
    name: 'alstria office REIT-AG',
    symbol: 'AOX.DE',
  },
  {
    value: 'AINN.DE',
    label: 'AINN.DE,  American International Group, Inc.',
    name: 'American International Group, Inc.',
    symbol: 'AINN.DE',
  },
  {
    value: 'FRU.DE',
    label: 'FRU.DE,  Multitude SE',
    name: 'Multitude SE',
    symbol: 'FRU.DE',
  },
  {
    value: 'GLW.DE',
    label: 'GLW.DE,  Corning Incorporated',
    name: 'Corning Incorporated',
    symbol: 'GLW.DE',
  },
  {
    value: 'C9F.DE',
    label: 'C9F.DE,  Chipotle Mexican Grill Inc',
    name: 'Chipotle Mexican Grill Inc',
    symbol: 'C9F.DE',
  },
  {
    value: 'BVB.DE',
    label:
      'BVB.DE,  Borussia Dortmund GmbH & Co. Kommanditgesellschaft auf Aktien',
    name: 'Borussia Dortmund GmbH & Co. Kommanditgesellschaft auf Aktien',
    symbol: 'BVB.DE',
  },
  {
    value: 'GYC.DE',
    label: 'GYC.DE,  Grand City Properties S.A.',
    name: 'Grand City Properties S.A.',
    symbol: 'GYC.DE',
  },
  {
    value: 'PSG.DE',
    label: 'PSG.DE,  PharmaSGP Holding SE',
    name: 'PharmaSGP Holding SE',
    symbol: 'PSG.DE',
  },
  {
    value: 'R66.DE',
    label: 'R66.DE,  Phillips 66',
    name: 'Phillips 66',
    symbol: 'R66.DE',
  },
  {
    value: 'WAH.DE',
    label: 'WAH.DE,  Wolftank-Adisa Holding AG',
    name: 'Wolftank-Adisa Holding AG',
    symbol: 'WAH.DE',
  },
  {
    value: 'FNTN.DE',
    label: 'FNTN.DE,  freenet AG',
    name: 'freenet AG',
    symbol: 'FNTN.DE',
  },
  {
    value: 'SYP.DE',
    label: 'SYP.DE,  Synopsys Inc',
    name: 'Synopsys Inc',
    symbol: 'SYP.DE',
  },
  {
    value: 'PAE.DE',
    label: 'PAE.DE,  PACCAR Inc',
    name: 'PACCAR Inc',
    symbol: 'PAE.DE',
  },
  {
    value: 'PDA.DE',
    label: 'PDA.DE,  PRO DV AG',
    name: 'PRO DV AG',
    symbol: 'PDA.DE',
  },
  {
    value: 'O5G.DE',
    label: 'O5G.DE,  CPI Property Group S.A.',
    name: 'CPI Property Group S.A.',
    symbol: 'O5G.DE',
  },
  {
    value: 'PSM.DE',
    label: 'PSM.DE,  ProSiebenSat.1 Media SE',
    name: 'ProSiebenSat.1 Media SE',
    symbol: 'PSM.DE',
  },
  {
    value: 'PES.DE',
    label: 'PES.DE,  Pearson plc',
    name: 'Pearson plc',
    symbol: 'PES.DE',
  },
  {
    value: 'ALV.DE',
    label: 'ALV.DE,  Allianz SE',
    name: 'Allianz SE',
    symbol: 'ALV.DE',
  },
  {
    value: 'EUZ.DE',
    label: 'EUZ.DE,  Eckert & Ziegler Strahlen- und Medizintechnik AG',
    name: 'Eckert & Ziegler Strahlen- und Medizintechnik AG',
    symbol: 'EUZ.DE',
  },
  {
    value: 'CVS.DE',
    label: 'CVS.DE,  CVS Health Corporation',
    name: 'CVS Health Corporation',
    symbol: 'CVS.DE',
  },
  {
    value: 'MGG.DE',
    label: 'MGG.DE,  MGM Resorts International',
    name: 'MGM Resorts International',
    symbol: 'MGG.DE',
  },
  {
    value: 'ECK.DE',
    label: 'ECK.DE,  LUDWIG BECK am Rathauseck - Textilhaus Feldmeier AG',
    name: 'LUDWIG BECK am Rathauseck - Textilhaus Feldmeier AG',
    symbol: 'ECK.DE',
  },
  {
    value: 'VOS.DE',
    label: 'VOS.DE,  Vossloh AG',
    name: 'Vossloh AG',
    symbol: 'VOS.DE',
  },
  {
    value: 'LEN.DE',
    label: 'LEN.DE,  Lenzing Aktiengesellschaft',
    name: 'Lenzing Aktiengesellschaft',
    symbol: 'LEN.DE',
  },
  {
    value: 'APM.DE',
    label: 'APM.DE,  ad pepper media International N.V.',
    name: 'ad pepper media International N.V.',
    symbol: 'APM.DE',
  },
  {
    value: 'HDD.DE',
    label: 'HDD.DE,  Heidelberger Druckmaschinen Aktiengesellschaft',
    name: 'Heidelberger Druckmaschinen Aktiengesellschaft',
    symbol: 'HDD.DE',
  },
  {
    value: 'DIC.DE',
    label: 'DIC.DE,  DIC Asset AG',
    name: 'DIC Asset AG',
    symbol: 'DIC.DE',
  },
  {
    value: 'WIG1.DE',
    label: 'WIG1.DE,  Sporttotal AG',
    name: 'Sporttotal AG',
    symbol: 'WIG1.DE',
  },
  {
    value: 'CEK.DE',
    label: 'CEK.DE,  CeoTronics AG',
    name: 'CeoTronics AG',
    symbol: 'CEK.DE',
  },
  {
    value: 'DAP.DE',
    label: 'DAP.DE,  Danaher Corporation',
    name: 'Danaher Corporation',
    symbol: 'DAP.DE',
  },
  {
    value: 'GIL.DE',
    label: 'GIL.DE,  DMG MORI AKTIENGESELLSCHAFT',
    name: 'DMG MORI AKTIENGESELLSCHAFT',
    symbol: 'GIL.DE',
  },
  {
    value: '1TY.DE',
    label: '1TY.DE,  Prosus N.V.',
    name: 'Prosus N.V.',
    symbol: '1TY.DE',
  },
  {
    value: 'CWC.DE',
    label: 'CWC.DE,  CEWE Stiftung & Co. KGaA',
    name: 'CEWE Stiftung & Co. KGaA',
    symbol: 'CWC.DE',
  },
  {
    value: 'HNM.DE',
    label: 'HNM.DE,  Ormat Technologies Inc',
    name: 'Ormat Technologies Inc',
    symbol: 'HNM.DE',
  },
  {
    value: 'BWB.DE',
    label: 'BWB.DE,  Baader Bank Aktiengesellschaft',
    name: 'Baader Bank Aktiengesellschaft',
    symbol: 'BWB.DE',
  },
  {
    value: 'HAW.DE',
    label: 'HAW.DE,  Hawesko Holding AG',
    name: 'Hawesko Holding AG',
    symbol: 'HAW.DE',
  },
  {
    value: 'B4B.DE',
    label: 'B4B.DE,  Metro AG',
    name: 'Metro AG',
    symbol: 'B4B.DE',
  },
  {
    value: 'CHV.DE',
    label: 'CHV.DE,  Chevron Corporation',
    name: 'Chevron Corporation',
    symbol: 'CHV.DE',
  },
  {
    value: 'A4Y.DE',
    label: 'A4Y.DE,  ACCENTRO Real Estate AG',
    name: 'ACCENTRO Real Estate AG',
    symbol: 'A4Y.DE',
  },
  {
    value: 'GFT.DE',
    label: 'GFT.DE,  GFT Technologies SE',
    name: 'GFT Technologies SE',
    symbol: 'GFT.DE',
  },
  {
    value: 'SQU.DE',
    label: 'SQU.DE,  Vinci SA',
    name: 'Vinci SA',
    symbol: 'SQU.DE',
  },
  {
    value: 'MXHN.DE',
    label: 'MXHN.DE,  MAX Automation SE',
    name: 'MAX Automation SE',
    symbol: 'MXHN.DE',
  },
  {
    value: 'NFS.DE',
    label: 'NFS.DE,  Norfolk Southern Corporation',
    name: 'Norfolk Southern Corporation',
    symbol: 'NFS.DE',
  },
  {
    value: 'DWD.DE',
    label: 'DWD.DE,  Morgan Stanley',
    name: 'Morgan Stanley',
    symbol: 'DWD.DE',
  },
  {
    value: 'B5A.DE',
    label: 'B5A.DE,  BAUER Aktiengesellschaft',
    name: 'BAUER Aktiengesellschaft',
    symbol: 'B5A.DE',
  },
  {
    value: 'MUV2.DE',
    label:
      'MUV2.DE,  Münchener Rückversicherungs-Gesellschaft Aktiengesellschaft in München',
    name: 'Münchener Rückversicherungs-Gesellschaft Aktiengesellschaft in München',
    symbol: 'MUV2.DE',
  },
  {
    value: 'DGR.DE',
    label: 'DGR.DE,  Deutsche Grundstücksauktionen AG',
    name: 'Deutsche Grundstücksauktionen AG',
    symbol: 'DGR.DE',
  },
  {
    value: 'RWE.DE',
    label: 'RWE.DE,  RWE Aktiengesellschaft',
    name: 'RWE Aktiengesellschaft',
    symbol: 'RWE.DE',
  },
  {
    value: 'KSB.DE',
    label: 'KSB.DE,  KSB SE & Co. KGaA',
    name: 'KSB SE & Co. KGaA',
    symbol: 'KSB.DE',
  },
  {
    value: 'MTX.DE',
    label: 'MTX.DE,  MTU Aero Engines AG',
    name: 'MTU Aero Engines AG',
    symbol: 'MTX.DE',
  },
  {
    value: 'IQL.DE',
    label: 'IQL.DE,  iQ International AG',
    name: 'iQ International AG',
    symbol: 'IQL.DE',
  },
  {
    value: 'NRA.DE',
    label: 'NRA.DE,  NRG Energy, Inc.',
    name: 'NRG Energy, Inc.',
    symbol: 'NRA.DE',
  },
  {
    value: 'CBHD.DE',
    label: 'CBHD.DE,  Coloplast A/S',
    name: 'Coloplast A/S',
    symbol: 'CBHD.DE',
  },
  {
    value: 'DRW8.DE',
    label: 'DRW8.DE,  Drägerwerk AG & Co. KGaA',
    name: 'Drägerwerk AG & Co. KGaA',
    symbol: 'DRW8.DE',
  },
  {
    value: 'MSN.DE',
    label: 'MSN.DE,  Marsh & McLennan Companies, Inc.',
    name: 'Marsh & McLennan Companies, Inc.',
    symbol: 'MSN.DE',
  },
  {
    value: 'SAE.DE',
    label: 'SAE.DE,  Shop Apotheke Europe N.V.',
    name: 'Shop Apotheke Europe N.V.',
    symbol: 'SAE.DE',
  },
  {
    value: 'CTO.DE',
    label: 'CTO.DE,  Costco Wholesale Corp',
    name: 'Costco Wholesale Corp',
    symbol: 'CTO.DE',
  },
  {
    value: 'F3C.DE',
    label: 'F3C.DE,  SFC Energy AG',
    name: 'SFC Energy AG',
    symbol: 'F3C.DE',
  },
  {
    value: 'FC9.DE',
    label: 'FC9.DE,  FCR Immobilien AG',
    name: 'FCR Immobilien AG',
    symbol: 'FC9.DE',
  },
  {
    value: 'UPAB.DE',
    label: 'UPAB.DE,  United Parcel Service, Inc.',
    name: 'United Parcel Service, Inc.',
    symbol: 'UPAB.DE',
  },
  {
    value: 'A6T.DE',
    label: 'A6T.DE,  artec technologies AG',
    name: 'artec technologies AG',
    symbol: 'A6T.DE',
  },
  {
    value: 'ABEA.DE',
    label: 'ABEA.DE,  Alphabet Inc.',
    name: 'Alphabet Inc.',
    symbol: 'ABEA.DE',
  },
  {
    value: 'IC8.DE',
    label: 'IC8.DE,  InCity Immobilien AG',
    name: 'InCity Immobilien AG',
    symbol: 'IC8.DE',
  },
  {
    value: 'O2D.DE',
    label: 'O2D.DE,  Telefónica Deutschland Holding AG',
    name: 'Telefónica Deutschland Holding AG',
    symbol: 'O2D.DE',
  },
  {
    value: 'GIS.DE',
    label: 'GIS.DE,  Gilead Sciences, Inc.',
    name: 'Gilead Sciences, Inc.',
    symbol: 'GIS.DE',
  },
  {
    value: 'DBAN.DE',
    label: 'DBAN.DE,  Deutsche Beteiligungs AG',
    name: 'Deutsche Beteiligungs AG',
    symbol: 'DBAN.DE',
  },
  {
    value: 'MVV1.DE',
    label: 'MVV1.DE,  MVV Energie AG',
    name: 'MVV Energie AG',
    symbol: 'MVV1.DE',
  },
  {
    value: 'WYR.DE',
    label: 'WYR.DE,  Wynn Resorts Ltd',
    name: 'Wynn Resorts Ltd',
    symbol: 'WYR.DE',
  },
  {
    value: 'EBO.DE',
    label: 'EBO.DE,  Erste Group Bank AG.',
    name: 'Erste Group Bank AG.',
    symbol: 'EBO.DE',
  },
  {
    value: 'AIR.DE',
    label: 'AIR.DE,  Airbus SE',
    name: 'Airbus SE',
    symbol: 'AIR.DE',
  },
  {
    value: 'WAF.DE',
    label: 'WAF.DE,  Siltronic AG',
    name: 'Siltronic AG',
    symbol: 'WAF.DE',
  },
  {
    value: 'XER2.DE',
    label: 'XER2.DE,  Xerox Holdings Corp',
    name: 'Xerox Holdings Corp',
    symbol: 'XER2.DE',
  },
  {
    value: 'H9W.DE',
    label: 'H9W.DE,  HWA AG',
    name: 'HWA AG',
    symbol: 'H9W.DE',
  },
  {
    value: 'ADV.DE',
    label: 'ADV.DE,  ADVA Optical Networking SE',
    name: 'ADVA Optical Networking SE',
    symbol: 'ADV.DE',
  },
  {
    value: 'T1L.DE',
    label: 'T1L.DE,  S IMMO AG',
    name: 'S IMMO AG',
    symbol: 'T1L.DE',
  },
  {
    value: 'G1A.DE',
    label: 'G1A.DE,  GEA Group Aktiengesellschaft',
    name: 'GEA Group Aktiengesellschaft',
    symbol: 'G1A.DE',
  },
  {
    value: 'ACT.DE',
    label: 'ACT.DE,  AlzChem Group AG',
    name: 'AlzChem Group AG',
    symbol: 'ACT.DE',
  },
  {
    value: 'MDN.DE',
    label: 'MDN.DE,  Medion AG',
    name: 'Medion AG',
    symbol: 'MDN.DE',
  },
  {
    value: 'FOO.DE',
    label: 'FOO.DE,  Salesforce, Inc.',
    name: 'Salesforce, Inc.',
    symbol: 'FOO.DE',
  },
  {
    value: 'M5Z.DE',
    label: 'M5Z.DE,  Manz AG',
    name: 'Manz AG',
    symbol: 'M5Z.DE',
  },
  {
    value: 'FPH.DE',
    label: 'FPH.DE,  Francotyp-Postalia Holding AG',
    name: 'Francotyp-Postalia Holding AG',
    symbol: 'FPH.DE',
  },
  {
    value: 'WCMK.DE',
    label: 'WCMK.DE,  WCM Beteiligungs- und Grundbesitz-AG',
    name: 'WCM Beteiligungs- und Grundbesitz-AG',
    symbol: 'WCMK.DE',
  },
  {
    value: 'SWF.DE',
    label: 'SWF.DE,  Stanley Black & Decker Inc',
    name: 'Stanley Black & Decker Inc',
    symbol: 'SWF.DE',
  },
  {
    value: 'FF24.DE',
    label: 'FF24.DE,  Fast Finance24 Holding AG',
    name: 'Fast Finance24 Holding AG',
    symbol: 'FF24.DE',
  },
  {
    value: 'IBM.DE',
    label: 'IBM.DE,  International Business Machines Corporation',
    name: 'International Business Machines Corporation',
    symbol: 'IBM.DE',
  },
  {
    value: 'PA8.DE',
    label: 'PA8.DE,  Paion AG',
    name: 'Paion AG',
    symbol: 'PA8.DE',
  },
  {
    value: 'IBE1.DE',
    label: 'IBE1.DE,  Iberdrola, S.A.',
    name: 'Iberdrola, S.A.',
    symbol: 'IBE1.DE',
  },
  {
    value: 'NC5A.DE',
    label: 'NC5A.DE,  NorCom Information Technology GmbH & Co. KGaA',
    name: 'NorCom Information Technology GmbH & Co. KGaA',
    symbol: 'NC5A.DE',
  },
  {
    value: 'DWS.DE',
    label: 'DWS.DE,  DWS Group GmbH & Co. KGaA',
    name: 'DWS Group GmbH & Co. KGaA',
    symbol: 'DWS.DE',
  },
  {
    value: 'CORE.DE',
    label: 'CORE.DE,  Coreo AG',
    name: 'Coreo AG',
    symbol: 'CORE.DE',
  },
  {
    value: 'SHF.DE',
    label: 'SHF.DE,  SNP Schneider-Neureither & Partner SE',
    name: 'SNP Schneider-Neureither & Partner SE',
    symbol: 'SHF.DE',
  },
  {
    value: 'EVK.DE',
    label: 'EVK.DE,  Evonik Industries AG',
    name: 'Evonik Industries AG',
    symbol: 'EVK.DE',
  },
  {
    value: 'FRS.DE',
    label: 'FRS.DE,  FORIS AG',
    name: 'FORIS AG',
    symbol: 'FRS.DE',
  },
  {
    value: 'MRK.DE',
    label: 'MRK.DE,  MERCK Kommanditgesellschaft auf Aktien',
    name: 'MERCK Kommanditgesellschaft auf Aktien',
    symbol: 'MRK.DE',
  },
  {
    value: 'RAW.DE',
    label: 'RAW.DE,  Raiffeisen Bank International AG',
    name: 'Raiffeisen Bank International AG',
    symbol: 'RAW.DE',
  },
  {
    value: 'KRN.DE',
    label: 'KRN.DE,  Krones AG',
    name: 'Krones AG',
    symbol: 'KRN.DE',
  },
  {
    value: 'DHER.DE',
    label: 'DHER.DE,  Delivery Hero SE',
    name: 'Delivery Hero SE',
    symbol: 'DHER.DE',
  },
  {
    value: 'WUW.DE',
    label: 'WUW.DE,  Wüstenrot & Württembergische AG',
    name: 'Wüstenrot & Württembergische AG',
    symbol: 'WUW.DE',
  },
  {
    value: 'SRB.DE',
    label: 'SRB.DE,  Starbucks Corporation',
    name: 'Starbucks Corporation',
    symbol: 'SRB.DE',
  },
  {
    value: 'SNG.DE',
    label: 'SNG.DE,  Singulus Technologies AG',
    name: 'Singulus Technologies AG',
    symbol: 'SNG.DE',
  },
  {
    value: 'TQI.DE',
    label: 'TQI.DE,  Telecom Italia S.p.A.',
    name: 'Telecom Italia S.p.A.',
    symbol: 'TQI.DE',
  },
  {
    value: 'OHB.DE',
    label: 'OHB.DE,  OHB SE',
    name: 'OHB SE',
    symbol: 'OHB.DE',
  },
  {
    value: '4DS.DE',
    label: '4DS.DE,  Daldrup & Söhne Aktiengesellschaft',
    name: 'Daldrup & Söhne Aktiengesellschaft',
    symbol: '4DS.DE',
  },
  {
    value: 'ECF.DE',
    label: 'ECF.DE,  Mountain Alliance AG',
    name: 'Mountain Alliance AG',
    symbol: 'ECF.DE',
  },
  {
    value: 'NOVC.DE',
    label: 'NOVC.DE,  Novo Nordisk A/S',
    name: 'Novo Nordisk A/S',
    symbol: 'NOVC.DE',
  },
  {
    value: 'ASME.DE',
    label: 'ASME.DE,  ASML Holding N.V.',
    name: 'ASML Holding N.V.',
    symbol: 'ASME.DE',
  },
  {
    value: '1YD.DE',
    label: '1YD.DE,  Broadcom Inc',
    name: 'Broadcom Inc',
    symbol: '1YD.DE',
  },
  {
    value: 'SKB.DE',
    label: 'SKB.DE,  Koenig & Bauer AG',
    name: 'Koenig & Bauer AG',
    symbol: 'SKB.DE',
  },
  {
    value: 'BIJ.DE',
    label: 'BIJ.DE,  Bijou Brigitte modische Accessoires Aktiengesellschaft',
    name: 'Bijou Brigitte modische Accessoires Aktiengesellschaft',
    symbol: 'BIJ.DE',
  },
  {
    value: 'PAT.DE',
    label: 'PAT.DE,  PATRIZIA SE',
    name: 'PATRIZIA SE',
    symbol: 'PAT.DE',
  },
  {
    value: 'NBG6.DE',
    label: 'NBG6.DE,  NÜRNBERGER Beteiligungs-AG',
    name: 'NÜRNBERGER Beteiligungs-AG',
    symbol: 'NBG6.DE',
  },
  {
    value: 'FIV.DE',
    label: 'FIV.DE,  Fiserv, Inc.',
    name: 'Fiserv, Inc.',
    symbol: 'FIV.DE',
  },
  {
    value: 'FRA.DE',
    label: 'FRA.DE,  Fraport AG',
    name: 'Fraport AG',
    symbol: 'FRA.DE',
  },
  {
    value: 'WCH.DE',
    label: 'WCH.DE,  Wacker Chemie AG',
    name: 'Wacker Chemie AG',
    symbol: 'WCH.DE',
  },
  {
    value: 'B8F.DE',
    label: 'B8F.DE,  Biofrontera AG',
    name: 'Biofrontera AG',
    symbol: 'B8F.DE',
  },
  {
    value: 'PAL.DE',
    label: 'PAL.DE,  Pantaflix AG',
    name: 'Pantaflix AG',
    symbol: 'PAL.DE',
  },
  {
    value: 'UTDI.DE',
    label: 'UTDI.DE,  United Internet AG',
    name: 'United Internet AG',
    symbol: 'UTDI.DE',
  },
  {
    value: '2HQ.DE',
    label: '2HQ.DE,  Tilray Brands, Inc.',
    name: 'Tilray Brands, Inc.',
    symbol: '2HQ.DE',
  },
  {
    value: 'MLP.DE',
    label: 'MLP.DE,  MLP SE',
    name: 'MLP SE',
    symbol: 'MLP.DE',
  },
  {
    value: 'VOW3.DE',
    label: 'VOW3.DE,  Volkswagen AG',
    name: 'Volkswagen AG',
    symbol: 'VOW3.DE',
  },
  {
    value: 'CH1A.DE',
    label: 'CH1A.DE,  C.H. Robinson Worldwide Inc',
    name: 'C.H. Robinson Worldwide Inc',
    symbol: 'CH1A.DE',
  },
  {
    value: 'M4I.DE',
    label: 'M4I.DE,  Mastercard Incorporated',
    name: 'Mastercard Incorporated',
    symbol: 'M4I.DE',
  },
  {
    value: 'SNW.DE',
    label: 'SNW.DE,  Sanofi',
    name: 'Sanofi',
    symbol: 'SNW.DE',
  },
  {
    value: 'KEL.DE',
    label: 'KEL.DE,  Kellogg Company',
    name: 'Kellogg Company',
    symbol: 'KEL.DE',
  },
  {
    value: 'DFV.DE',
    label: 'DFV.DE,  DFV Deutsche Familienversicherung AG',
    name: 'DFV Deutsche Familienversicherung AG',
    symbol: 'DFV.DE',
  },
  {
    value: 'HLAG.DE',
    label: 'HLAG.DE,  Hapag-Lloyd Aktiengesellschaft',
    name: 'Hapag-Lloyd Aktiengesellschaft',
    symbol: 'HLAG.DE',
  },
  {
    value: 'P4O.DE',
    label: 'P4O.DE,  Plan Optik AG',
    name: 'Plan Optik AG',
    symbol: 'P4O.DE',
  },
  {
    value: 'GDX.DE',
    label: 'GDX.DE,  General Dynamics Corporation',
    name: 'General Dynamics Corporation',
    symbol: 'GDX.DE',
  },
  {
    value: 'EKT.DE',
    label: 'EKT.DE,  Energiekontor AG',
    name: 'Energiekontor AG',
    symbol: 'EKT.DE',
  },
  {
    value: 'RSL2.DE',
    label: 'RSL2.DE,  R. STAHL AG',
    name: 'R. STAHL AG',
    symbol: 'RSL2.DE',
  },
  {
    value: 'JST.DE',
    label: 'JST.DE,  JOST Werke AG',
    name: 'JOST Werke AG',
    symbol: 'JST.DE',
  },
  {
    value: 'LU2.DE',
    label: 'LU2.DE,  Lumentum Holdings Inc',
    name: 'Lumentum Holdings Inc',
    symbol: 'LU2.DE',
  },
  {
    value: 'DYH.DE',
    label: 'DYH.DE,  Target Corporation',
    name: 'Target Corporation',
    symbol: 'DYH.DE',
  },
  {
    value: 'VNX.DE',
    label: 'VNX.DE,  NXP Semiconductors N.V.',
    name: 'NXP Semiconductors N.V.',
    symbol: 'VNX.DE',
  },
  {
    value: 'NN6.DE',
    label: 'NN6.DE,  NanoRepro AG',
    name: 'NanoRepro AG',
    symbol: 'NN6.DE',
  },
  {
    value: 'RCMN.DE',
    label: 'RCMN.DE,  RCM Beteiligungs AG',
    name: 'RCM Beteiligungs AG',
    symbol: 'RCMN.DE',
  },
  {
    value: 'FTE.DE',
    label: 'FTE.DE,  Orange S.A.',
    name: 'Orange S.A.',
    symbol: 'FTE.DE',
  },
  {
    value: 'DFTK.DE',
    label: 'DFTK.DE,  DF Deutsche Forfait AG',
    name: 'DF Deutsche Forfait AG',
    symbol: 'DFTK.DE',
  },
  {
    value: 'MSAG.DE',
    label: 'MSAG.DE,  MS Industrie AG',
    name: 'MS Industrie AG',
    symbol: 'MSAG.DE',
  },
  {
    value: 'BST.DE',
    label: 'BST.DE,  Bastei Lübbe AG',
    name: 'Bastei Lübbe AG',
    symbol: 'BST.DE',
  },
  {
    value: 'SIX2.DE',
    label: 'SIX2.DE,  Sixt SE',
    name: 'Sixt SE',
    symbol: 'SIX2.DE',
  },
  {
    value: 'VAS.DE',
    label: 'VAS.DE,  Voestalpine AG',
    name: 'Voestalpine AG',
    symbol: 'VAS.DE',
  },
  {
    value: 'GRM.DE',
    label: 'GRM.DE,  General Mills, Inc.',
    name: 'General Mills, Inc.',
    symbol: 'GRM.DE',
  },
  {
    value: '2QO.DE',
    label: '2QO.DE,  Qorvo, Inc.',
    name: 'Qorvo, Inc.',
    symbol: '2QO.DE',
  },
  {
    value: 'ANL.DE',
    label: 'ANL.DE,  Analog Devices, Inc.',
    name: 'Analog Devices, Inc.',
    symbol: 'ANL.DE',
  },
  {
    value: 'MDO.DE',
    label: "MDO.DE,  McDonald's Corporation",
    name: "McDonald's Corporation",
    symbol: 'MDO.DE',
  },
  {
    value: 'IVX.DE',
    label: 'IVX.DE,  InVision Aktiengesellschaft',
    name: 'InVision Aktiengesellschaft',
    symbol: 'IVX.DE',
  },
  {
    value: '12DA.DE',
    label: '12DA.DE,  Dell Technologies Inc',
    name: 'Dell Technologies Inc',
    symbol: '12DA.DE',
  },
  {
    value: 'MMM.DE',
    label: 'MMM.DE,  3M Company',
    name: '3M Company',
    symbol: 'MMM.DE',
  },
  {
    value: 'VVU.DE',
    label: 'VVU.DE,  Vivendi SE',
    name: 'Vivendi SE',
    symbol: 'VVU.DE',
  },
  {
    value: 'JNJ.DE',
    label: 'JNJ.DE,  Johnson & Johnson',
    name: 'Johnson & Johnson',
    symbol: 'JNJ.DE',
  },
  {
    value: 'GTR.DE',
    label: 'GTR.DE,  The Goodyear Tire & Rubber Company',
    name: 'The Goodyear Tire & Rubber Company',
    symbol: 'GTR.DE',
  },
  {
    value: 'ENI.DE',
    label: 'ENI.DE,  Eni S.p.A.',
    name: 'Eni S.p.A.',
    symbol: 'ENI.DE',
  },
  {
    value: 'PRG.DE',
    label: 'PRG.DE,  The Procter & Gamble Company',
    name: 'The Procter & Gamble Company',
    symbol: 'PRG.DE',
  },
  {
    value: 'SND.DE',
    label: 'SND.DE,  Schneider Electric S.E.',
    name: 'Schneider Electric S.E.',
    symbol: 'SND.DE',
  },
  {
    value: '02M.DE',
    label: '02M.DE,  The Mosaic Company',
    name: 'The Mosaic Company',
    symbol: '02M.DE',
  },
  {
    value: 'FPMB.DE',
    label: 'FPMB.DE,  Freeport-McMoRan Inc.',
    name: 'Freeport-McMoRan Inc.',
    symbol: 'FPMB.DE',
  },
  {
    value: 'KU2.DE',
    label: 'KU2.DE,  KUKA Aktiengesellschaft',
    name: 'KUKA Aktiengesellschaft',
    symbol: 'KU2.DE',
  },
  {
    value: 'AGJ.DE',
    label: 'AGJ.DE,  AGCO Corp',
    name: 'AGCO Corp',
    symbol: 'AGJ.DE',
  },
  {
    value: 'ARL.DE',
    label: 'ARL.DE,  Aareal Bank AG',
    name: 'Aareal Bank AG',
    symbol: 'ARL.DE',
  },
  {
    value: 'C1V.DE',
    label: 'C1V.DE,  mVISE AG',
    name: 'mVISE AG',
    symbol: 'C1V.DE',
  },
  {
    value: 'IVU.DE',
    label: 'IVU.DE,  IVU Traffic Technologies AG',
    name: 'IVU Traffic Technologies AG',
    symbol: 'IVU.DE',
  },
  {
    value: 'M3V.DE',
    label: 'M3V.DE,  MeVis Medical Solutions AG',
    name: 'MeVis Medical Solutions AG',
    symbol: 'M3V.DE',
  },
  {
    value: 'RAA.DE',
    label: 'RAA.DE,  RATIONAL Aktiengesellschaft',
    name: 'RATIONAL Aktiengesellschaft',
    symbol: 'RAA.DE',
  },
  {
    value: 'AJ91.DE',
    label: 'AJ91.DE,  DocCheck AG',
    name: 'DocCheck AG',
    symbol: 'AJ91.DE',
  },
  {
    value: 'LHA.DE',
    label: 'LHA.DE,  Deutsche Lufthansa AG',
    name: 'Deutsche Lufthansa AG',
    symbol: 'LHA.DE',
  },
  {
    value: '4AB.DE',
    label: '4AB.DE,  AbbVie Inc.',
    name: 'AbbVie Inc.',
    symbol: '4AB.DE',
  },
  {
    value: 'NTG.DE',
    label: 'NTG.DE,  Nabaltec AG',
    name: 'Nabaltec AG',
    symbol: 'NTG.DE',
  },
  {
    value: 'EDL.DE',
    label: 'EDL.DE,  Edel SE & Co. KGaA',
    name: 'Edel SE & Co. KGaA',
    symbol: 'EDL.DE',
  },
  {
    value: 'MWB.DE',
    label: 'MWB.DE,  mwb fairtrade Wertpapierhandelsbank AG',
    name: 'mwb fairtrade Wertpapierhandelsbank AG',
    symbol: 'MWB.DE',
  },
  {
    value: 'MLL.DE',
    label: 'MLL.DE,  Müller - Die lila Logistik AG',
    name: 'Müller - Die lila Logistik AG',
    symbol: 'MLL.DE',
  },
  {
    value: 'RY8.DE',
    label: 'RY8.DE,  ROY Asset Holding SE',
    name: 'ROY Asset Holding SE',
    symbol: 'RY8.DE',
  },
  {
    value: 'BOSS.DE',
    label: 'BOSS.DE,  Hugo Boss AG',
    name: 'Hugo Boss AG',
    symbol: 'BOSS.DE',
  },
  {
    value: 'ADS.DE',
    label: 'ADS.DE,  adidas AG',
    name: 'adidas AG',
    symbol: 'ADS.DE',
  },
  {
    value: 'LIK.DE',
    label: 'LIK.DE,  LIMES Schlosskliniken AG',
    name: 'LIMES Schlosskliniken AG',
    symbol: 'LIK.DE',
  },
  {
    value: 'GUI.DE',
    label: 'GUI.DE,  Diageo plc',
    name: 'Diageo plc',
    symbol: 'GUI.DE',
  },
  {
    value: 'XY6.DE',
    label: 'XY6.DE,  Xylem Inc.',
    name: 'Xylem Inc.',
    symbol: 'XY6.DE',
  },
  {
    value: 'ELG.DE',
    label: 'ELG.DE,  Elmos Semiconductor SE',
    name: 'Elmos Semiconductor SE',
    symbol: 'ELG.DE',
  },
  {
    value: 'SFD1.DE',
    label: 'SFD1.DE,  Softline AG',
    name: 'Softline AG',
    symbol: 'SFD1.DE',
  },
  {
    value: 'GTK.DE',
    label: 'GTK.DE,  Tonkens Agrar AG',
    name: 'Tonkens Agrar AG',
    symbol: 'GTK.DE',
  },
  {
    value: 'AIL.DE',
    label: "AIL.DE,  L'Air Liquide S.A.",
    name: "L'Air Liquide S.A.",
    symbol: 'AIL.DE',
  },
  {
    value: 'NWO.DE',
    label: 'NWO.DE,  New Work SE',
    name: 'New Work SE',
    symbol: 'NWO.DE',
  },
  {
    value: 'FYB.DE',
    label: 'FYB.DE,  Formycon AG',
    name: 'Formycon AG',
    symbol: 'FYB.DE',
  },
  {
    value: 'AEND.DE',
    label: 'AEND.DE,  Aegon N.V.',
    name: 'Aegon N.V.',
    symbol: 'AEND.DE',
  },
  {
    value: 'ULC.DE',
    label: 'ULC.DE,  UNITEDLABELS Aktiengesellschaft',
    name: 'UNITEDLABELS Aktiengesellschaft',
    symbol: 'ULC.DE',
  },
  {
    value: 'HXCK.DE',
    label: 'HXCK.DE,  Ernst Russ AG',
    name: 'Ernst Russ AG',
    symbol: 'HXCK.DE',
  },
  {
    value: 'IUR.DE',
    label: 'IUR.DE,  KAP AG',
    name: 'KAP AG',
    symbol: 'IUR.DE',
  },
  {
    value: 'M3BK.DE',
    label: 'M3BK.DE,  Pyramid AG',
    name: 'Pyramid AG',
    symbol: 'M3BK.DE',
  },
  {
    value: 'GGS.DE',
    label: 'GGS.DE,  Gigaset AG',
    name: 'Gigaset AG',
    symbol: 'GGS.DE',
  },
  {
    value: 'AOMD.DE',
    label: 'AOMD.DE,  Alstom SA',
    name: 'Alstom SA',
    symbol: 'AOMD.DE',
  },
  {
    value: 'AHLA.DE',
    label: 'AHLA.DE,  Alibaba Group Holding Limited',
    name: 'Alibaba Group Holding Limited',
    symbol: 'AHLA.DE',
  },
  {
    value: 'W3U.DE',
    label: 'W3U.DE,  The Western Union Co',
    name: 'The Western Union Co',
    symbol: 'W3U.DE',
  },
  {
    value: 'DTD2.DE',
    label: 'DTD2.DE,  B+S Banksysteme Aktiengesellschaft',
    name: 'B+S Banksysteme Aktiengesellschaft',
    symbol: 'DTD2.DE',
  },
  {
    value: 'DUE.DE',
    label: 'DUE.DE,  Dürr Aktiengesellschaft',
    name: 'Dürr Aktiengesellschaft',
    symbol: 'DUE.DE',
  },
  {
    value: 'BMT.DE',
    label: 'BMT.DE,  British American Tobacco p.l.c.',
    name: 'British American Tobacco p.l.c.',
    symbol: 'BMT.DE',
  },
  {
    value: '639.DE',
    label: '639.DE,  Spotify Technology S.A.',
    name: 'Spotify Technology S.A.',
    symbol: '639.DE',
  },
  {
    value: 'VSC.DE',
    label: 'VSC.DE,  4SC AG',
    name: '4SC AG',
    symbol: 'VSC.DE',
  },
  {
    value: 'LY0.DE',
    label: 'LY0.DE,  Lyft Inc',
    name: 'Lyft Inc',
    symbol: 'LY0.DE',
  },
  {
    value: 'MPCK.DE',
    label: 'MPCK.DE,  MPC Münchmeyer Petersen Capital AG',
    name: 'MPC Münchmeyer Petersen Capital AG',
    symbol: 'MPCK.DE',
  },
  {
    value: 'TRU.DE',
    label: 'TRU.DE,  Traumhaus AG',
    name: 'Traumhaus AG',
    symbol: 'TRU.DE',
  },
  {
    value: 'BNR.DE',
    label: 'BNR.DE,  Brenntag SE',
    name: 'Brenntag SE',
    symbol: 'BNR.DE',
  },
  {
    value: 'UNP.DE',
    label: 'UNP.DE,  Union Pacific Corporation',
    name: 'Union Pacific Corporation',
    symbol: 'UNP.DE',
  },
  {
    value: 'E4C.DE',
    label: 'E4C.DE,  ecotel communication ag',
    name: 'ecotel communication ag',
    symbol: 'E4C.DE',
  },
  {
    value: 'ENL.DE',
    label: 'ENL.DE,  Enel SpA',
    name: 'Enel SpA',
    symbol: 'ENL.DE',
  },
  {
    value: 'I8R.DE',
    label: 'I8R.DE,  iRobot Corporation',
    name: 'iRobot Corporation',
    symbol: 'I8R.DE',
  },
  {
    value: 'PZS.DE',
    label: 'PZS.DE,  Scherzer & Co. AG',
    name: 'Scherzer & Co. AG',
    symbol: 'PZS.DE',
  },
  {
    value: 'SZG.DE',
    label: 'SZG.DE,  Salzgitter AG',
    name: 'Salzgitter AG',
    symbol: 'SZG.DE',
  },
  {
    value: 'BAC.DE',
    label: 'BAC.DE,  Verizon Communications Inc.',
    name: 'Verizon Communications Inc.',
    symbol: 'BAC.DE',
  },
  {
    value: 'NTH.DE',
    label: 'NTH.DE,  Northrop Grumman Corporation',
    name: 'Northrop Grumman Corporation',
    symbol: 'NTH.DE',
  },
  {
    value: 'HCL.DE',
    label: 'HCL.DE,  Hecla Mining Company',
    name: 'Hecla Mining Company',
    symbol: 'HCL.DE',
  },
  {
    value: 'TMV.DE',
    label: 'TMV.DE,  TeamViewer AG',
    name: 'TeamViewer AG',
    symbol: 'TMV.DE',
  },
  {
    value: 'RHM.DE',
    label: 'RHM.DE,  Rheinmetall AG',
    name: 'Rheinmetall AG',
    symbol: 'RHM.DE',
  },
  {
    value: 'WAC.DE',
    label: 'WAC.DE,  Wacker Neuson SE',
    name: 'Wacker Neuson SE',
    symbol: 'WAC.DE',
  },
  {
    value: 'H9Y.DE',
    label: 'H9Y.DE,  HanseYachts AG',
    name: 'HanseYachts AG',
    symbol: 'H9Y.DE',
  },
  {
    value: 'PA9.DE',
    label: 'PA9.DE,  The Travelers Companies, Inc.',
    name: 'The Travelers Companies, Inc.',
    symbol: 'PA9.DE',
  },
  {
    value: 'LEI.DE',
    label: 'LEI.DE,  Leifheit Aktiengesellschaft',
    name: 'Leifheit Aktiengesellschaft',
    symbol: 'LEI.DE',
  },
  {
    value: 'YCP.DE',
    label: 'YCP.DE,  ConocoPhillips',
    name: 'ConocoPhillips',
    symbol: 'YCP.DE',
  },
  {
    value: 'HLG.DE',
    label: 'HLG.DE,  Highlight Communications AG',
    name: 'Highlight Communications AG',
    symbol: 'HLG.DE',
  },
  {
    value: 'EVD.DE',
    label: 'EVD.DE,  CTS Eventim AG & Co. KGaA',
    name: 'CTS Eventim AG & Co. KGaA',
    symbol: 'EVD.DE',
  },
  {
    value: 'QB7.DE',
    label: 'QB7.DE,  Quirin Privatbank AG',
    name: 'Quirin Privatbank AG',
    symbol: 'QB7.DE',
  },
  {
    value: 'MUM.DE',
    label: 'MUM.DE,  Mensch und Maschine Software SE',
    name: 'Mensch und Maschine Software SE',
    symbol: 'MUM.DE',
  },
  {
    value: 'SHWK.DE',
    label: 'SHWK.DE,  SHS VIVEON AG',
    name: 'SHS VIVEON AG',
    symbol: 'SHWK.DE',
  },
  {
    value: 'ITU.DE',
    label: 'ITU.DE,  Intuit Inc.',
    name: 'Intuit Inc.',
    symbol: 'ITU.DE',
  },
  {
    value: 'STO3.DE',
    label: 'STO3.DE,  Sto SE & Co. KGaA',
    name: 'Sto SE & Co. KGaA',
    symbol: 'STO3.DE',
  },
  {
    value: 'GME.DE',
    label: 'GME.DE,  Geratherm Medical AG',
    name: 'Geratherm Medical AG',
    symbol: 'GME.DE',
  },
  {
    value: 'RHK.DE',
    label: 'RHK.DE,  RHÖN-KLINIKUM Aktiengesellschaft',
    name: 'RHÖN-KLINIKUM Aktiengesellschaft',
    symbol: 'RHK.DE',
  },
  {
    value: 'ZEG.DE',
    label: 'ZEG.DE,  AstraZeneca PLC',
    name: 'AstraZeneca PLC',
    symbol: 'ZEG.DE',
  },
  {
    value: 'ISHA.DE',
    label: 'ISHA.DE,  INTERSHOP Communications Aktiengesellschaft',
    name: 'INTERSHOP Communications Aktiengesellschaft',
    symbol: 'ISHA.DE',
  },
  {
    value: 'FRE.DE',
    label: 'FRE.DE,  Fresenius SE & Co. KGaA',
    name: 'Fresenius SE & Co. KGaA',
    symbol: 'FRE.DE',
  },
  {
    value: 'IC2.DE',
    label: 'IC2.DE,  Intercontinental Exchange, Inc.',
    name: 'Intercontinental Exchange, Inc.',
    symbol: 'IC2.DE',
  },
  {
    value: 'TII.DE',
    label: 'TII.DE,  Texas Instruments Incorporated',
    name: 'Texas Instruments Incorporated',
    symbol: 'TII.DE',
  },
  {
    value: 'CAT1.DE',
    label: 'CAT1.DE,  Caterpillar Inc.',
    name: 'Caterpillar Inc.',
    symbol: 'CAT1.DE',
  },
  {
    value: 'NOA3.DE',
    label: 'NOA3.DE,  Nokia Oyj',
    name: 'Nokia Oyj',
    symbol: 'NOA3.DE',
  },
  {
    value: 'DB1.DE',
    label: 'DB1.DE,  Deutsche Börse AG',
    name: 'Deutsche Börse AG',
    symbol: 'DB1.DE',
  },
  {
    value: 'NUVA.DE',
    label: 'NUVA.DE,  Noratis AG',
    name: 'Noratis AG',
    symbol: 'NUVA.DE',
  },
  {
    value: 'CBK.DE',
    label: 'CBK.DE,  Commerzbank AG',
    name: 'Commerzbank AG',
    symbol: 'CBK.DE',
  },
  {
    value: 'CEA.DE',
    label: 'CEA.DE,  FRIWO AG',
    name: 'FRIWO AG',
    symbol: 'CEA.DE',
  },
  {
    value: 'BSD2.DE',
    label: 'BSD2.DE,  Banco Santander, S.A.',
    name: 'Banco Santander, S.A.',
    symbol: 'BSD2.DE',
  },
  {
    value: 'SUR.DE',
    label: 'SUR.DE,  Surteco Group SE',
    name: 'Surteco Group SE',
    symbol: 'SUR.DE',
  },
  {
    value: 'CIE1.DE',
    label: 'CIE1.DE,  Ciena Corporation',
    name: 'Ciena Corporation',
    symbol: 'CIE1.DE',
  },
  {
    value: 'LEO.DE',
    label: 'LEO.DE,  LEONI AG',
    name: 'LEONI AG',
    symbol: 'LEO.DE',
  },
  {
    value: 'F3A.DE',
    label: 'F3A.DE,  First Solar, Inc.',
    name: 'First Solar, Inc.',
    symbol: 'F3A.DE',
  },
  {
    value: 'NDA.DE',
    label: 'NDA.DE,  Aurubis AG',
    name: 'Aurubis AG',
    symbol: 'NDA.DE',
  },
  {
    value: 'BC8.DE',
    label: 'BC8.DE,  Bechtle AG',
    name: 'Bechtle AG',
    symbol: 'BC8.DE',
  },
  {
    value: 'BOY.DE',
    label: 'BOY.DE,  Banco Bilbao Vizcaya Argentaria, S.A.',
    name: 'Banco Bilbao Vizcaya Argentaria, S.A.',
    symbol: 'BOY.DE',
  },
  {
    value: 'HO7.DE',
    label: 'HO7.DE,  Hormel Foods Corporation',
    name: 'Hormel Foods Corporation',
    symbol: 'HO7.DE',
  },
  {
    value: 'GSC1.DE',
    label: 'GSC1.DE,  Gesco AG',
    name: 'Gesco AG',
    symbol: 'GSC1.DE',
  },
  {
    value: 'G24.DE',
    label: 'G24.DE,  Scout24 SE',
    name: 'Scout24 SE',
    symbol: 'G24.DE',
  },
  {
    value: 'HAL.DE',
    label: 'HAL.DE,  Halliburton Company',
    name: 'Halliburton Company',
    symbol: 'HAL.DE',
  },
  {
    value: 'SZU.DE',
    label: 'SZU.DE,  Südzucker AG',
    name: 'Südzucker AG',
    symbol: 'SZU.DE',
  },
  {
    value: 'KC4.DE',
    label: 'KC4.DE,  KONE Oyj',
    name: 'KONE Oyj',
    symbol: 'KC4.DE',
  },
  {
    value: 'BBZA.DE',
    label: 'BBZA.DE,  BB Biotech AG',
    name: 'BB Biotech AG',
    symbol: 'BBZA.DE',
  },
  {
    value: 'AOF.DE',
    label: 'AOF.DE,  ATOSS Software AG',
    name: 'ATOSS Software AG',
    symbol: 'AOF.DE',
  },
  {
    value: 'VROS.DE',
    label: 'VROS.DE,  Verianos SE',
    name: 'Verianos SE',
    symbol: 'VROS.DE',
  },
  {
    value: 'HOT.DE',
    label: 'HOT.DE,  HOCHTIEF Aktiengesellschaft',
    name: 'HOCHTIEF Aktiengesellschaft',
    symbol: 'HOT.DE',
  },
  {
    value: 'EBK.DE',
    label: 'EBK.DE,  EnBW Energie Baden-Württemberg AG',
    name: 'EnBW Energie Baden-Württemberg AG',
    symbol: 'EBK.DE',
  },
  {
    value: 'EJT1.DE',
    label: 'EJT1.DE,  easyJet PLC',
    name: 'easyJet PLC',
    symbol: 'EJT1.DE',
  },
  {
    value: 'AAG.DE',
    label: 'AAG.DE,  Aumann AG',
    name: 'Aumann AG',
    symbol: 'AAG.DE',
  },
  {
    value: 'MTE.DE',
    label: 'MTE.DE,  Micron Technology, Inc.',
    name: 'Micron Technology, Inc.',
    symbol: 'MTE.DE',
  },
  {
    value: 'EMH.DE',
    label: 'EMH.DE,  pferdewetten.de AG',
    name: 'pferdewetten.de AG',
    symbol: 'EMH.DE',
  },
  {
    value: 'KLA.DE',
    label: 'KLA.DE,  KLA Corp',
    name: 'KLA Corp',
    symbol: 'KLA.DE',
  },
  {
    value: 'NVD.DE',
    label: 'NVD.DE,  NVIDIA Corporation',
    name: 'NVIDIA Corporation',
    symbol: 'NVD.DE',
  },
  {
    value: 'SPM.DE',
    label: 'SPM.DE,  Splendid Medien AG',
    name: 'Splendid Medien AG',
    symbol: 'SPM.DE',
  },
  {
    value: 'GMM.DE',
    label: 'GMM.DE,  Grammer AG',
    name: 'Grammer AG',
    symbol: 'GMM.DE',
  },
  {
    value: '68V.DE',
    label: '68V.DE,  Baker Hughes Co',
    name: 'Baker Hughes Co',
    symbol: '68V.DE',
  },
  {
    value: 'AAD.DE',
    label: 'AAD.DE,  Amadeus FiRe AG',
    name: 'Amadeus FiRe AG',
    symbol: 'AAD.DE',
  },
  {
    value: 'UZU.DE',
    label: 'UZU.DE,  Uzin Utz AG',
    name: 'Uzin Utz AG',
    symbol: 'UZU.DE',
  },
  {
    value: 'PWO.DE',
    label: 'PWO.DE,  Progress-Werk Oberkirch AG',
    name: 'Progress-Werk Oberkirch AG',
    symbol: 'PWO.DE',
  },
  {
    value: 'WMT.DE',
    label: 'WMT.DE,  Walmart Inc.',
    name: 'Walmart Inc.',
    symbol: 'WMT.DE',
  },
  {
    value: 'BBY.DE',
    label: 'BBY.DE,  Bed Bath & Beyond Inc',
    name: 'Bed Bath & Beyond Inc',
    symbol: 'BBY.DE',
  },
  {
    value: 'SRT.DE',
    label: 'SRT.DE,  Sartorius Aktiengesellschaft',
    name: 'Sartorius Aktiengesellschaft',
    symbol: 'SRT.DE',
  },
  {
    value: 'DT3.DE',
    label: 'DT3.DE,  Dollar Tree Inc',
    name: 'Dollar Tree Inc',
    symbol: 'DT3.DE',
  },
  {
    value: 'IBU.DE',
    label: 'IBU.DE,  IBU-tec advanced materials AG',
    name: 'IBU-tec advanced materials AG',
    symbol: 'IBU.DE',
  },
  {
    value: 'M4N.DE',
    label: 'M4N.DE,  Muehlhan AG',
    name: 'Muehlhan AG',
    symbol: 'M4N.DE',
  },
  {
    value: 'HNL.DE',
    label: 'HNL.DE,  Dr. Hönle AG',
    name: 'Dr. Hönle AG',
    symbol: 'HNL.DE',
  },
  {
    value: 'STM.DE',
    label: 'STM.DE,  Stabilus S.A.',
    name: 'Stabilus S.A.',
    symbol: 'STM.DE',
  },
  {
    value: 'CPA.DE',
    label: 'CPA.DE,  Colgate-Palmolive Company',
    name: 'Colgate-Palmolive Company',
    symbol: 'CPA.DE',
  },
  {
    value: 'VRL.DE',
    label: 'VRL.DE,  Net-Digital AG',
    name: 'Net-Digital AG',
    symbol: 'VRL.DE',
  },
  {
    value: 'W8A.DE',
    label: 'W8A.DE,  Walgreens Boots Alliance Inc',
    name: 'Walgreens Boots Alliance Inc',
    symbol: 'W8A.DE',
  },
  {
    value: 'INR.DE',
    label: 'INR.DE,  Lyxor MSCI India UCITS ETF Acc EUR',
    name: 'Lyxor MSCI India UCITS ETF Acc EUR',
    symbol: 'INR.DE',
  },
  {
    value: 'MBQ.DE',
    label: 'MBQ.DE,  Mobotix AG',
    name: 'Mobotix AG',
    symbol: 'MBQ.DE',
  },
  {
    value: 'BEZ.DE',
    label: 'BEZ.DE,  Berentzen-Gruppe Aktiengesellschaft',
    name: 'Berentzen-Gruppe Aktiengesellschaft',
    symbol: 'BEZ.DE',
  },
  {
    value: 'AMG.DE',
    label: 'AMG.DE,  Amgen Inc.',
    name: 'Amgen Inc.',
    symbol: 'AMG.DE',
  },
  {
    value: 'HNR1.DE',
    label: 'HNR1.DE,  Hannover Rück SE',
    name: 'Hannover Rück SE',
    symbol: 'HNR1.DE',
  },
  {
    value: 'RNY.DE',
    label: 'RNY.DE,  Yamana Gold Inc.',
    name: 'Yamana Gold Inc.',
    symbol: 'RNY.DE',
  },
  {
    value: 'PEP.DE',
    label: 'PEP.DE,  PepsiCo, Inc.',
    name: 'PepsiCo, Inc.',
    symbol: 'PEP.DE',
  },
  {
    value: 'ETG.DE',
    label: 'ETG.DE,  EnviTec Biogas AG',
    name: 'EnviTec Biogas AG',
    symbol: 'ETG.DE',
  },
  {
    value: 'PGN.DE',
    label: 'PGN.DE,  paragon GmbH & Co. KGaA',
    name: 'paragon GmbH & Co. KGaA',
    symbol: 'PGN.DE',
  },
  {
    value: '1SI.DE',
    label: '1SI.DE,  Snap Inc.',
    name: 'Snap Inc.',
    symbol: '1SI.DE',
  },
  {
    value: 'SAX.DE',
    label: 'SAX.DE,  Ströer SE & Co. KGaA',
    name: 'Ströer SE & Co. KGaA',
    symbol: 'SAX.DE',
  },
  {
    value: 'AMD.DE',
    label: 'AMD.DE,  Advanced Micro Devices, Inc.',
    name: 'Advanced Micro Devices, Inc.',
    symbol: 'AMD.DE',
  },
  {
    value: 'CGN.DE',
    label: 'CGN.DE,  Cigna Corporation',
    name: 'Cigna Corporation',
    symbol: 'CGN.DE',
  },
  {
    value: 'DIS.DE',
    label: 'DIS.DE,  DVS Technology AG',
    name: 'DVS Technology AG',
    symbol: 'DIS.DE',
  },
  {
    value: 'UNVB.DE',
    label: 'UNVB.DE,  Unilever PLC',
    name: 'Unilever PLC',
    symbol: 'UNVB.DE',
  },
  {
    value: 'CMC.DE',
    label: 'CMC.DE,  JPMorgan Chase & Co.',
    name: 'JPMorgan Chase & Co.',
    symbol: 'CMC.DE',
  },
  {
    value: 'ED4.DE',
    label: 'ED4.DE,  EDAG Engineering Group AG',
    name: 'EDAG Engineering Group AG',
    symbol: 'ED4.DE',
  },
  {
    value: 'VQT.DE',
    label: 'VQT.DE,  va-Q-tec AG',
    name: 'va-Q-tec AG',
    symbol: 'VQT.DE',
  },
  {
    value: 'PMAG.DE',
    label: 'PMAG.DE,  PIERER Mobility AG',
    name: 'PIERER Mobility AG',
    symbol: 'PMAG.DE',
  },
  {
    value: 'DEZ.DE',
    label: 'DEZ.DE,  DEUTZ Aktiengesellschaft',
    name: 'DEUTZ Aktiengesellschaft',
    symbol: 'DEZ.DE',
  },
  {
    value: 'ARRD.DE',
    label: 'ARRD.DE,  ArcelorMittal S.A.',
    name: 'ArcelorMittal S.A.',
    symbol: 'ARRD.DE',
  },
  {
    value: 'M12.DE',
    label: 'M12.DE,  M1 Kliniken AG',
    name: 'M1 Kliniken AG',
    symbol: 'M12.DE',
  },
  {
    value: 'JNP.DE',
    label: 'JNP.DE,  Juniper Networks, Inc.',
    name: 'Juniper Networks, Inc.',
    symbol: 'JNP.DE',
  },
  {
    value: 'RDEB.DE',
    label: 'RDEB.DE,  RELX PLC',
    name: 'RELX PLC',
    symbol: 'RDEB.DE',
  },
  {
    value: 'S92.DE',
    label: 'S92.DE,  SMA Solar Technology AG',
    name: 'SMA Solar Technology AG',
    symbol: 'S92.DE',
  },
  {
    value: 'UN9.DE',
    label: 'UN9.DE,  UNIQA Insurance Group AG',
    name: 'UNIQA Insurance Group AG',
    symbol: 'UN9.DE',
  },
  {
    value: 'UNH.DE',
    label: 'UNH.DE,  UnitedHealth Group Incorporated',
    name: 'UnitedHealth Group Incorporated',
    symbol: 'UNH.DE',
  },
  {
    value: 'MOR.DE',
    label: 'MOR.DE,  MorphoSys AG',
    name: 'MorphoSys AG',
    symbol: 'MOR.DE',
  },
  {
    value: 'INH.DE',
    label: 'INH.DE,  INDUS Holding AG',
    name: 'INDUS Holding AG',
    symbol: 'INH.DE',
  },
  {
    value: 'ILM1.DE',
    label: 'ILM1.DE,  Medios AG',
    name: 'Medios AG',
    symbol: 'ILM1.DE',
  },
  {
    value: 'KTF.DE',
    label: 'KTF.DE,  Mondelez International Inc',
    name: 'Mondelez International Inc',
    symbol: 'KTF.DE',
  },
  {
    value: 'TGR.DE',
    label: 'TGR.DE,  Yum! Brands, Inc.',
    name: 'Yum! Brands, Inc.',
    symbol: 'TGR.DE',
  },
  {
    value: 'BSN.DE',
    label: 'BSN.DE,  Danone S.A.',
    name: 'Danone S.A.',
    symbol: 'BSN.DE',
  },
  {
    value: 'DWNI.DE',
    label: 'DWNI.DE,  Deutsche Wohnen SE',
    name: 'Deutsche Wohnen SE',
    symbol: 'DWNI.DE',
  },
  {
    value: 'DBK.DE',
    label: 'DBK.DE,  Deutsche Bank Aktiengesellschaft',
    name: 'Deutsche Bank Aktiengesellschaft',
    symbol: 'DBK.DE',
  },
  {
    value: 'ROI.DE',
    label: 'ROI.DE,  Rosenbauer International AG',
    name: 'Rosenbauer International AG',
    symbol: 'ROI.DE',
  },
  {
    value: 'RNL.DE',
    label: 'RNL.DE,  Renault SA',
    name: 'Renault SA',
    symbol: 'RNL.DE',
  },
  {
    value: 'CAR.DE',
    label: 'CAR.DE,  Carrefour SA',
    name: 'Carrefour SA',
    symbol: 'CAR.DE',
  },
  {
    value: 'KMY.DE',
    label: 'KMY.DE,  Kimberly-Clark Corporation',
    name: 'Kimberly-Clark Corporation',
    symbol: 'KMY.DE',
  },
  {
    value: 'UWS.DE',
    label: 'UWS.DE,  Waste Management, Inc.',
    name: 'Waste Management, Inc.',
    symbol: 'UWS.DE',
  },
  {
    value: 'HAR.DE',
    label: 'HAR.DE,  Harley-Davidson, Inc.',
    name: 'Harley-Davidson, Inc.',
    symbol: 'HAR.DE',
  },
  {
    value: 'PRU.DE',
    label: 'PRU.DE,  Prudential plc',
    name: 'Prudential plc',
    symbol: 'PRU.DE',
  },
  {
    value: 'RDO.DE',
    label: 'RDO.DE,  Sirius XM Holdings Inc',
    name: 'Sirius XM Holdings Inc',
    symbol: 'RDO.DE',
  },
  {
    value: 'MAK.DE',
    label: 'MAK.DE,  Maternus-Kliniken Aktiengesellschaft',
    name: 'Maternus-Kliniken Aktiengesellschaft',
    symbol: 'MAK.DE',
  },
  {
    value: 'AXA.DE',
    label: 'AXA.DE,  AXA SA',
    name: 'AXA SA',
    symbol: 'AXA.DE',
  },
  {
    value: 'FQT.DE',
    label: 'FQT.DE,  Frequentis AG',
    name: 'Frequentis AG',
    symbol: 'FQT.DE',
  },
  {
    value: 'SII.DE',
    label: 'SII.DE,  Wheaton Precious Metals Corp',
    name: 'Wheaton Precious Metals Corp',
    symbol: 'SII.DE',
  },
  {
    value: 'KSC.DE',
    label: 'KSC.DE,  KPS AG',
    name: 'KPS AG',
    symbol: 'KSC.DE',
  },
  {
    value: 'V6C.DE',
    label: 'V6C.DE,  Viscom AG',
    name: 'Viscom AG',
    symbol: 'V6C.DE',
  },
  {
    value: 'FB2A.DE',
    label: 'FB2A.DE,  Meta Platforms, Inc.',
    name: 'Meta Platforms, Inc.',
    symbol: 'FB2A.DE',
  },
  {
    value: 'RY4C.DE',
    label: 'RY4C.DE,  Ryanair Holdings plc',
    name: 'Ryanair Holdings plc',
    symbol: 'RY4C.DE',
  },
  {
    value: 'O4B.DE',
    label: 'O4B.DE,  OVB Holding AG',
    name: 'OVB Holding AG',
    symbol: 'O4B.DE',
  },
  {
    value: 'AR4.DE',
    label: 'AR4.DE,  AURELIUS Equity Opportunities SE & Co. KGaA',
    name: 'AURELIUS Equity Opportunities SE & Co. KGaA',
    symbol: 'AR4.DE',
  },
  {
    value: 'PBB.DE',
    label: 'PBB.DE,  Deutsche Pfandbriefbank AG',
    name: 'Deutsche Pfandbriefbank AG',
    symbol: 'PBB.DE',
  },
  {
    value: 'PBY.DE',
    label: 'PBY.DE,  Publity AG',
    name: 'Publity AG',
    symbol: 'PBY.DE',
  },
  {
    value: 'AHC.DE',
    label: 'AHC.DE,  Hess Corporation',
    name: 'Hess Corporation',
    symbol: 'AHC.DE',
  },
  {
    value: 'IDP.DE',
    label: 'IDP.DE,  Biogen Inc.',
    name: 'Biogen Inc.',
    symbol: 'IDP.DE',
  },
  {
    value: '2EM.DE',
    label: '2EM.DE,  Ergomed plc',
    name: 'Ergomed plc',
    symbol: '2EM.DE',
  },
  {
    value: 'CVA.DE',
    label: 'CVA.DE,  Cleveland-Cliffs Inc',
    name: 'Cleveland-Cliffs Inc',
    symbol: 'CVA.DE',
  },
  {
    value: 'SMHN.DE',
    label: 'SMHN.DE,  SÜSS MicroTec SE',
    name: 'SÜSS MicroTec SE',
    symbol: 'SMHN.DE',
  },
  {
    value: 'VG8.DE',
    label: 'VG8.DE,  Varengold Bank AG',
    name: 'Varengold Bank AG',
    symbol: 'VG8.DE',
  },
  {
    value: 'H5E.DE',
    label: 'H5E.DE,  HELMA Eigenheimbau Aktiengesellschaft',
    name: 'HELMA Eigenheimbau Aktiengesellschaft',
    symbol: 'H5E.DE',
  },
  {
    value: 'DIE.DE',
    label: 'DIE.DE,  Dierig Holding AG',
    name: 'Dierig Holding AG',
    symbol: 'DIE.DE',
  },
  {
    value: 'AIXA.DE',
    label: 'AIXA.DE,  AIXTRON SE',
    name: 'AIXTRON SE',
    symbol: 'AIXA.DE',
  },
  {
    value: 'FEV.DE',
    label: 'FEV.DE,  FORTEC Elektronik AG',
    name: 'FORTEC Elektronik AG',
    symbol: 'FEV.DE',
  },
  {
    value: 'ZAL.DE',
    label: 'ZAL.DE,  Zalando SE',
    name: 'Zalando SE',
    symbol: 'ZAL.DE',
  },
  {
    value: 'XONA.DE',
    label: 'XONA.DE,  Exxon Mobil Corporation',
    name: 'Exxon Mobil Corporation',
    symbol: 'XONA.DE',
  },
  {
    value: 'LLY.DE',
    label: 'LLY.DE,  Eli Lilly and Company',
    name: 'Eli Lilly and Company',
    symbol: 'LLY.DE',
  },
  {
    value: 'PLUN.DE',
    label: 'PLUN.DE,  Plug Power Inc.',
    name: 'Plug Power Inc.',
    symbol: 'PLUN.DE',
  },
  {
    value: 'MLB1.DE',
    label: 'MLB1.DE,  MercadoLibre, Inc.',
    name: 'MercadoLibre, Inc.',
    symbol: 'MLB1.DE',
  },
  {
    value: 'FAA.DE',
    label: 'FAA.DE,  Fabasoft AG',
    name: 'Fabasoft AG',
    symbol: 'FAA.DE',
  },
  {
    value: 'VRS.DE',
    label: 'VRS.DE,  VeriSign, Inc.',
    name: 'VeriSign, Inc.',
    symbol: 'VRS.DE',
  },
  {
    value: 'UUU.DE',
    label: 'UUU.DE,  3U Holding AG',
    name: '3U Holding AG',
    symbol: 'UUU.DE',
  },
  {
    value: 'NMI.DE',
    label: 'NMI.DE,  Aladdin Healthcare Technologies SE',
    name: 'Aladdin Healthcare Technologies SE',
    symbol: 'NMI.DE',
  },
  {
    value: 'UN01.DE',
    label: 'UN01.DE,  Uniper SE',
    name: 'Uniper SE',
    symbol: 'UN01.DE',
  },
  {
    value: 'SOW.DE',
    label: 'SOW.DE,  Software Aktiengesellschaft',
    name: 'Software Aktiengesellschaft',
    symbol: 'SOW.DE',
  },
  {
    value: 'WEW.DE',
    label: 'WEW.DE,  Westwing Group SE',
    name: 'Westwing Group SE',
    symbol: 'WEW.DE',
  },
  {
    value: 'FPE3.DE',
    label: 'FPE3.DE,  Fuchs Petrolub SE',
    name: 'Fuchs Petrolub SE',
    symbol: 'FPE3.DE',
  },
  {
    value: 'PSAN.DE',
    label: 'PSAN.DE,  PSI Software AG',
    name: 'PSI Software AG',
    symbol: 'PSAN.DE',
  },
  {
    value: 'LOR.DE',
    label: "LOR.DE,  L'Oréal S.A.",
    name: "L'Oréal S.A.",
    symbol: 'LOR.DE',
  },
  {
    value: 'GFG.DE',
    label: 'GFG.DE,  Global Fashion Group S.A.',
    name: 'Global Fashion Group S.A.',
    symbol: 'GFG.DE',
  },
  {
    value: 'AXJ.DE',
    label: 'AXJ.DE,  Equitable Holdings, Inc.',
    name: 'Equitable Holdings, Inc.',
    symbol: 'AXJ.DE',
  },
  {
    value: 'MBK.DE',
    label: 'MBK.DE,  Merkur PrivatBank KgaA',
    name: 'Merkur PrivatBank KgaA',
    symbol: 'MBK.DE',
  },
  {
    value: 'PU11.DE',
    label: 'PU11.DE,  The Social Chain AG',
    name: 'The Social Chain AG',
    symbol: 'PU11.DE',
  },
  {
    value: '4I1.DE',
    label: '4I1.DE,  Philip Morris International Inc.',
    name: 'Philip Morris International Inc.',
    symbol: '4I1.DE',
  },
  {
    value: 'BAYN.DE',
    label: 'BAYN.DE,  Bayer Aktiengesellschaft',
    name: 'Bayer Aktiengesellschaft',
    symbol: 'BAYN.DE',
  },
  {
    value: 'E3X1.DE',
    label: 'E3X1.DE,  Expedia Group Inc',
    name: 'Expedia Group Inc',
    symbol: 'E3X1.DE',
  },
  {
    value: 'NDX1.DE',
    label: 'NDX1.DE,  Nordex SE',
    name: 'Nordex SE',
    symbol: 'NDX1.DE',
  },
  {
    value: 'A1OS.DE',
    label: 'A1OS.DE,  All for One Group SE',
    name: 'All for One Group SE',
    symbol: 'A1OS.DE',
  },
  {
    value: 'FTK.DE',
    label: 'FTK.DE,  flatexDEGIRO AG',
    name: 'flatexDEGIRO AG',
    symbol: 'FTK.DE',
  },
  {
    value: 'RTC.DE',
    label: 'RTC.DE,  RealTech AG',
    name: 'RealTech AG',
    symbol: 'RTC.DE',
  },
  {
    value: 'PCE1.DE',
    label: 'PCE1.DE,  Booking Holdings Inc',
    name: 'Booking Holdings Inc',
    symbol: 'PCE1.DE',
  },
  {
    value: 'ORC.DE',
    label: 'ORC.DE,  Oracle Corporation',
    name: 'Oracle Corporation',
    symbol: 'ORC.DE',
  },
  {
    value: 'FME.DE',
    label: 'FME.DE,  Fresenius Medical Care AG & Co. KGaA',
    name: 'Fresenius Medical Care AG & Co. KGaA',
    symbol: 'FME.DE',
  },
  {
    value: 'TRVC.DE',
    label: 'TRVC.DE,  Citigroup Inc.',
    name: 'Citigroup Inc.',
    symbol: 'TRVC.DE',
  },
  {
    value: 'QCI.DE',
    label: 'QCI.DE,  QUALCOMM Incorporated',
    name: 'QUALCOMM Incorporated',
    symbol: 'QCI.DE',
  },
  {
    value: 'ZOE.DE',
    label: 'ZOE.DE,  Zoetis Inc.',
    name: 'Zoetis Inc.',
    symbol: 'ZOE.DE',
  },
  {
    value: 'SYV.DE',
    label: 'SYV.DE,  3D Systems Corporation',
    name: '3D Systems Corporation',
    symbol: 'SYV.DE',
  },
  {
    value: 'T9Z.DE',
    label: 'T9Z.DE,  Zumtobel Group AG',
    name: 'Zumtobel Group AG',
    symbol: 'T9Z.DE',
  },
  {
    value: 'C6O.DE',
    label: 'C6O.DE,  Clovis Oncology, Inc.',
    name: 'Clovis Oncology, Inc.',
    symbol: 'C6O.DE',
  },
  {
    value: 'E2F.DE',
    label: 'E2F.DE,  Electricité de France S.A.',
    name: 'Electricité de France S.A.',
    symbol: 'E2F.DE',
  },
  {
    value: 'COP.DE',
    label: 'COP.DE,  CompuGroup Medical SE & Co. KGaA',
    name: 'CompuGroup Medical SE & Co. KGaA',
    symbol: 'COP.DE',
  },
  {
    value: 'ADANIPORTS.NS',
    label: 'ADANIPORTS.NS,  Adani Ports and Special Economic Zone Limited',
    name: 'Adani Ports and Special Economic Zone Limited',
    symbol: 'ADANIPORTS.NS',
  },
  {
    value: 'ASIANPAINT.NS',
    label: 'ASIANPAINT.NS,  Asian Paints Limited',
    name: 'Asian Paints Limited',
    symbol: 'ASIANPAINT.NS',
  },
  {
    value: 'AXISBANK.NS',
    label: 'AXISBANK.NS,  Axis Bank Limited',
    name: 'Axis Bank Limited',
    symbol: 'AXISBANK.NS',
  },
  {
    value: 'BAJAJFINSV.NS',
    label: 'BAJAJFINSV.NS,  Bajaj Finserv Ltd.',
    name: 'Bajaj Finserv Ltd.',
    symbol: 'BAJAJFINSV.NS',
  },
  {
    value: 'BAJFINANCE.NS',
    label: 'BAJFINANCE.NS,  Bajaj Finance Limited',
    name: 'Bajaj Finance Limited',
    symbol: 'BAJFINANCE.NS',
  },
  {
    value: 'BHARTIARTL.NS',
    label: 'BHARTIARTL.NS,  Bharti Airtel Limited',
    name: 'Bharti Airtel Limited',
    symbol: 'BHARTIARTL.NS',
  },
  {
    value: 'BPCL.NS',
    label: 'BPCL.NS,  Bharat Petroleum Corporation Limited',
    name: 'Bharat Petroleum Corporation Limited',
    symbol: 'BPCL.NS',
  },
  {
    value: 'BRITANNIA.NS',
    label: 'BRITANNIA.NS,  Britannia Industries Limited',
    name: 'Britannia Industries Limited',
    symbol: 'BRITANNIA.NS',
  },
  {
    value: 'CIPLA.NS',
    label: 'CIPLA.NS,  Cipla Limited',
    name: 'Cipla Limited',
    symbol: 'CIPLA.NS',
  },
  {
    value: 'COALINDIA.NS',
    label: 'COALINDIA.NS,  Coal India Limited',
    name: 'Coal India Limited',
    symbol: 'COALINDIA.NS',
  },
  {
    value: 'DRREDDY.NS',
    label: "DRREDDY.NS,  Dr. Reddy's Laboratories Limited",
    name: "Dr. Reddy's Laboratories Limited",
    symbol: 'DRREDDY.NS',
  },
  {
    value: 'EICHERMOT.NS',
    label: 'EICHERMOT.NS,  Eicher Motors Limited',
    name: 'Eicher Motors Limited',
    symbol: 'EICHERMOT.NS',
  },
  {
    value: 'GAIL.NS',
    label: 'GAIL.NS,  GAIL (India) Limited',
    name: 'GAIL (India) Limited',
    symbol: 'GAIL.NS',
  },
  {
    value: 'GRASIM.NS',
    label: 'GRASIM.NS,  Grasim Industries Limited',
    name: 'Grasim Industries Limited',
    symbol: 'GRASIM.NS',
  },
  {
    value: 'HCLTECH.NS',
    label: 'HCLTECH.NS,  HCL Technologies Limited',
    name: 'HCL Technologies Limited',
    symbol: 'HCLTECH.NS',
  },
  {
    value: 'HDFC.NS',
    label: 'HDFC.NS,  Housing Development Finance Corporation Limited',
    name: 'Housing Development Finance Corporation Limited',
    symbol: 'HDFC.NS',
  },
  {
    value: 'HDFCBANK.NS',
    label: 'HDFCBANK.NS,  HDFC Bank Limited',
    name: 'HDFC Bank Limited',
    symbol: 'HDFCBANK.NS',
  },
  {
    value: 'HEROMOTOCO.NS',
    label: 'HEROMOTOCO.NS,  Hero MotoCorp Limited',
    name: 'Hero MotoCorp Limited',
    symbol: 'HEROMOTOCO.NS',
  },
  {
    value: 'HINDALCO.NS',
    label: 'HINDALCO.NS,  Hindalco Industries Limited',
    name: 'Hindalco Industries Limited',
    symbol: 'HINDALCO.NS',
  },
  {
    value: 'HINDUNILVR.NS',
    label: 'HINDUNILVR.NS,  Hindustan Unilever Limited',
    name: 'Hindustan Unilever Limited',
    symbol: 'HINDUNILVR.NS',
  },
  {
    value: 'ICICIBANK.NS',
    label: 'ICICIBANK.NS,  ICICI Bank Limited',
    name: 'ICICI Bank Limited',
    symbol: 'ICICIBANK.NS',
  },
  {
    value: 'INDUSINDBK.NS',
    label: 'INDUSINDBK.NS,  IndusInd Bank Limited',
    name: 'IndusInd Bank Limited',
    symbol: 'INDUSINDBK.NS',
  },
  {
    value: 'INFY.NS',
    label: 'INFY.NS,  Infosys Limited',
    name: 'Infosys Limited',
    symbol: 'INFY.NS',
  },
  {
    value: 'IOC.NS',
    label: 'IOC.NS,  Indian Oil Corporation Limited',
    name: 'Indian Oil Corporation Limited',
    symbol: 'IOC.NS',
  },
  {
    value: 'ITC.NS',
    label: 'ITC.NS,  ITC Limited',
    name: 'ITC Limited',
    symbol: 'ITC.NS',
  },
  {
    value: 'JSWSTEEL.NS',
    label: 'JSWSTEEL.NS,  JSW Steel Limited',
    name: 'JSW Steel Limited',
    symbol: 'JSWSTEEL.NS',
  },
  {
    value: 'KOTAKBANK.NS',
    label: 'KOTAKBANK.NS,  Kotak Mahindra Bank Limited',
    name: 'Kotak Mahindra Bank Limited',
    symbol: 'KOTAKBANK.NS',
  },
  {
    value: 'LT.NS',
    label: 'LT.NS,  Larsen & Toubro Limited',
    name: 'Larsen & Toubro Limited',
    symbol: 'LT.NS',
  },
  {
    value: 'M&M.NS',
    label: 'M&M.NS,  Mahindra & Mahindra Limited',
    name: 'Mahindra & Mahindra Limited',
    symbol: 'M&M.NS',
  },
  {
    value: 'MARUTI.NS',
    label: 'MARUTI.NS,  Maruti Suzuki India Limited',
    name: 'Maruti Suzuki India Limited',
    symbol: 'MARUTI.NS',
  },
  {
    value: 'NESTLEIND.NS',
    label: 'NESTLEIND.NS,  Nestlé India Limited',
    name: 'Nestlé India Limited',
    symbol: 'NESTLEIND.NS',
  },
  {
    value: 'NTPC.NS',
    label: 'NTPC.NS,  NTPC Limited',
    name: 'NTPC Limited',
    symbol: 'NTPC.NS',
  },
  {
    value: 'ONGC.NS',
    label: 'ONGC.NS,  Oil and Natural Gas Corporation Limited',
    name: 'Oil and Natural Gas Corporation Limited',
    symbol: 'ONGC.NS',
  },
  {
    value: 'POWERGRID.NS',
    label: 'POWERGRID.NS,  Power Grid Corporation of India Limited',
    name: 'Power Grid Corporation of India Limited',
    symbol: 'POWERGRID.NS',
  },
  {
    value: 'RELIANCE.NS',
    label: 'RELIANCE.NS,  Reliance Industries Limited',
    name: 'Reliance Industries Limited',
    symbol: 'RELIANCE.NS',
  },
  {
    value: 'SBIN.NS',
    label: 'SBIN.NS,  State Bank of India',
    name: 'State Bank of India',
    symbol: 'SBIN.NS',
  },
  {
    value: 'SHREECEM.NS',
    label: 'SHREECEM.NS,  Shree Cement Limited',
    name: 'Shree Cement Limited',
    symbol: 'SHREECEM.NS',
  },
  {
    value: 'SUNPHARMA.NS',
    label: 'SUNPHARMA.NS,  Sun Pharmaceutical Industries Limited',
    name: 'Sun Pharmaceutical Industries Limited',
    symbol: 'SUNPHARMA.NS',
  },
  {
    value: 'TATAMOTORS.NS',
    label: 'TATAMOTORS.NS,  Tata Motors Limited',
    name: 'Tata Motors Limited',
    symbol: 'TATAMOTORS.NS',
  },
  {
    value: 'TATASTEEL.NS',
    label: 'TATASTEEL.NS,  Tata Steel Limited',
    name: 'Tata Steel Limited',
    symbol: 'TATASTEEL.NS',
  },
  {
    value: 'TCS.NS',
    label: 'TCS.NS,  Tata Consultancy Services Limited',
    name: 'Tata Consultancy Services Limited',
    symbol: 'TCS.NS',
  },
  {
    value: 'TECHM.NS',
    label: 'TECHM.NS,  Tech Mahindra Limited',
    name: 'Tech Mahindra Limited',
    symbol: 'TECHM.NS',
  },
  {
    value: 'TITAN.NS',
    label: 'TITAN.NS,  Titan Company Limited',
    name: 'Titan Company Limited',
    symbol: 'TITAN.NS',
  },
  {
    value: 'ULTRACEMCO.NS',
    label: 'ULTRACEMCO.NS,  UltraTech Cement Limited',
    name: 'UltraTech Cement Limited',
    symbol: 'ULTRACEMCO.NS',
  },
  {
    value: 'UPL.NS',
    label: 'UPL.NS,  UPL Limited',
    name: 'UPL Limited',
    symbol: 'UPL.NS',
  },
  {
    value: 'VEDL.NS',
    label: 'VEDL.NS,  Vedanta Limited',
    name: 'Vedanta Limited',
    symbol: 'VEDL.NS',
  },
  {
    value: 'WIPRO.NS',
    label: 'WIPRO.NS,  Wipro Limited',
    name: 'Wipro Limited',
    symbol: 'WIPRO.NS',
  },
  {
    value: 'ZEEL.NS',
    label: 'ZEEL.NS,  Zee Entertainment Enterprises Limited',
    name: 'Zee Entertainment Enterprises Limited',
    symbol: 'ZEEL.NS',
  },
  {
    value: 'ABBOTINDIA.NS',
    label: 'ABBOTINDIA.NS,  Abbott India Limited',
    name: 'Abbott India Limited',
    symbol: 'ABBOTINDIA.NS',
  },
  {
    value: 'ACC.NS',
    label: 'ACC.NS,  ACC Limited',
    name: 'ACC Limited',
    symbol: 'ACC.NS',
  },
  {
    value: 'ADANITRANS.NS',
    label: 'ADANITRANS.NS,  Adani Transmission Limited',
    name: 'Adani Transmission Limited',
    symbol: 'ADANITRANS.NS',
  },
  {
    value: 'AMBUJACEM.NS',
    label: 'AMBUJACEM.NS,  Ambuja Cements Limited',
    name: 'Ambuja Cements Limited',
    symbol: 'AMBUJACEM.NS',
  },
  {
    value: 'AUROPHARMA.NS',
    label: 'AUROPHARMA.NS,  Aurobindo Pharma Limited',
    name: 'Aurobindo Pharma Limited',
    symbol: 'AUROPHARMA.NS',
  },
  {
    value: 'BAJAJHLDNG.NS',
    label: 'BAJAJHLDNG.NS,  Bajaj Holdings & Investment Limited',
    name: 'Bajaj Holdings & Investment Limited',
    symbol: 'BAJAJHLDNG.NS',
  },
  {
    value: 'BANDHANBNK.NS',
    label: 'BANDHANBNK.NS,  Bandhan Bank Limited',
    name: 'Bandhan Bank Limited',
    symbol: 'BANDHANBNK.NS',
  },
  {
    value: 'BANKBARODA.NS',
    label: 'BANKBARODA.NS,  Bank of Baroda',
    name: 'Bank of Baroda',
    symbol: 'BANKBARODA.NS',
  },
  {
    value: 'BERGEPAINT.NS',
    label: 'BERGEPAINT.NS,  Berger Paints India Limited',
    name: 'Berger Paints India Limited',
    symbol: 'BERGEPAINT.NS',
  },
  {
    value: 'BIOCON.NS',
    label: 'BIOCON.NS,  Biocon Limited',
    name: 'Biocon Limited',
    symbol: 'BIOCON.NS',
  },
  {
    value: 'BOSCHLTD.NS',
    label: 'BOSCHLTD.NS,  Bosch Limited',
    name: 'Bosch Limited',
    symbol: 'BOSCHLTD.NS',
  },
  {
    value: 'COLPAL.NS',
    label: 'COLPAL.NS,  Colgate-Palmolive (India) Limited',
    name: 'Colgate-Palmolive (India) Limited',
    symbol: 'COLPAL.NS',
  },
  {
    value: 'CONCOR.NS',
    label: 'CONCOR.NS,  Container Corporation of India Limited',
    name: 'Container Corporation of India Limited',
    symbol: 'CONCOR.NS',
  },
  {
    value: 'DABUR.NS',
    label: 'DABUR.NS,  Dabur India Limited',
    name: 'Dabur India Limited',
    symbol: 'DABUR.NS',
  },
  {
    value: 'DIVISLAB.NS',
    label: "DIVISLAB.NS,  Divi's Laboratories Limited",
    name: "Divi's Laboratories Limited",
    symbol: 'DIVISLAB.NS',
  },
  {
    value: 'DLF.NS',
    label: 'DLF.NS,  DLF Limited',
    name: 'DLF Limited',
    symbol: 'DLF.NS',
  },
  {
    value: 'DMART.NS',
    label: 'DMART.NS,  Avenue Supermarts Limited',
    name: 'Avenue Supermarts Limited',
    symbol: 'DMART.NS',
  },
  {
    value: 'GICRE.NS',
    label: 'GICRE.NS,  General Insurance Corporation of India',
    name: 'General Insurance Corporation of India',
    symbol: 'GICRE.NS',
  },
  {
    value: 'GODREJCP.NS',
    label: 'GODREJCP.NS,  Godrej Consumer Products Limited',
    name: 'Godrej Consumer Products Limited',
    symbol: 'GODREJCP.NS',
  },
  {
    value: 'HAVELLS.NS',
    label: 'HAVELLS.NS,  Havells India Limited',
    name: 'Havells India Limited',
    symbol: 'HAVELLS.NS',
  },
  {
    value: 'HDFCAMC.NS',
    label: 'HDFCAMC.NS,  HDFC Asset Management Company Limited',
    name: 'HDFC Asset Management Company Limited',
    symbol: 'HDFCAMC.NS',
  },
  {
    value: 'HDFCLIFE.NS',
    label: 'HDFCLIFE.NS,  HDFC Life Insurance Company Limited',
    name: 'HDFC Life Insurance Company Limited',
    symbol: 'HDFCLIFE.NS',
  },
  {
    value: 'HINDPETRO.NS',
    label: 'HINDPETRO.NS,  Hindustan Petroleum Corporation Limited',
    name: 'Hindustan Petroleum Corporation Limited',
    symbol: 'HINDPETRO.NS',
  },
  {
    value: 'HINDZINC.NS',
    label: 'HINDZINC.NS,  Hindustan Zinc Limited',
    name: 'Hindustan Zinc Limited',
    symbol: 'HINDZINC.NS',
  },
  {
    value: 'ICICIGI.NS',
    label: 'ICICIGI.NS,  ICICI Lombard General Insurance Company Limited',
    name: 'ICICI Lombard General Insurance Company Limited',
    symbol: 'ICICIGI.NS',
  },
  {
    value: 'ICICIPRULI.NS',
    label: 'ICICIPRULI.NS,  ICICI Prudential Life Insurance Company Limited',
    name: 'ICICI Prudential Life Insurance Company Limited',
    symbol: 'ICICIPRULI.NS',
  },
  {
    value: 'IGL.NS',
    label: 'IGL.NS,  Indraprastha Gas Limited',
    name: 'Indraprastha Gas Limited',
    symbol: 'IGL.NS',
  },
  {
    value: 'INDIGO.NS',
    label: 'INDIGO.NS,  InterGlobe Aviation Limited',
    name: 'InterGlobe Aviation Limited',
    symbol: 'INDIGO.NS',
  },
  {
    value: 'LUPIN.NS',
    label: 'LUPIN.NS,  Lupin Limited',
    name: 'Lupin Limited',
    symbol: 'LUPIN.NS',
  },
  {
    value: 'MARICO.NS',
    label: 'MARICO.NS,  Marico Limited',
    name: 'Marico Limited',
    symbol: 'MARICO.NS',
  },
  {
    value: 'MCDOWELL-N.NS',
    label: 'MCDOWELL-N.NS,  United Spirits Limited',
    name: 'United Spirits Limited',
    symbol: 'MCDOWELL-N.NS',
  },
  {
    value: 'MUTHOOTFIN.NS',
    label: 'MUTHOOTFIN.NS,  Muthoot Finance Limited',
    name: 'Muthoot Finance Limited',
    symbol: 'MUTHOOTFIN.NS',
  },
  {
    value: 'NAUKRI.NS',
    label: 'NAUKRI.NS,  Info Edge (India) Limited',
    name: 'Info Edge (India) Limited',
    symbol: 'NAUKRI.NS',
  },
  {
    value: 'NHPC.NS',
    label: 'NHPC.NS,  NHPC Limited',
    name: 'NHPC Limited',
    symbol: 'NHPC.NS',
  },
  {
    value: 'NMDC.NS',
    label: 'NMDC.NS,  NMDC Limited',
    name: 'NMDC Limited',
    symbol: 'NMDC.NS',
  },
  {
    value: 'OFSS.NS',
    label: 'OFSS.NS,  Oracle Financial Services Software Limited',
    name: 'Oracle Financial Services Software Limited',
    symbol: 'OFSS.NS',
  },
  {
    value: 'PAGEIND.NS',
    label: 'PAGEIND.NS,  Page Industries Limited',
    name: 'Page Industries Limited',
    symbol: 'PAGEIND.NS',
  },
  {
    value: 'PEL.NS',
    label: 'PEL.NS,  Piramal Enterprises Limited',
    name: 'Piramal Enterprises Limited',
    symbol: 'PEL.NS',
  },
  {
    value: 'PETRONET.NS',
    label: 'PETRONET.NS,  Petronet LNG Limited',
    name: 'Petronet LNG Limited',
    symbol: 'PETRONET.NS',
  },
  {
    value: 'PFC.NS',
    label: 'PFC.NS,  Power Finance Corporation Limited',
    name: 'Power Finance Corporation Limited',
    symbol: 'PFC.NS',
  },
  {
    value: 'PGHH.NS',
    label: 'PGHH.NS,  Procter & Gamble Hygiene and Health Care Limited',
    name: 'Procter & Gamble Hygiene and Health Care Limited',
    symbol: 'PGHH.NS',
  },
  {
    value: 'PIDILITIND.NS',
    label: 'PIDILITIND.NS,  Pidilite Industries Limited',
    name: 'Pidilite Industries Limited',
    symbol: 'PIDILITIND.NS',
  },
  {
    value: 'PNB.NS',
    label: 'PNB.NS,  Punjab National Bank',
    name: 'Punjab National Bank',
    symbol: 'PNB.NS',
  },
  {
    value: 'SBILIFE.NS',
    label: 'SBILIFE.NS,  SBI Life Insurance Company Limited',
    name: 'SBI Life Insurance Company Limited',
    symbol: 'SBILIFE.NS',
  },
  {
    value: 'SIEMENS.NS',
    label: 'SIEMENS.NS,  Siemens Limited',
    name: 'Siemens Limited',
    symbol: 'SIEMENS.NS',
  },
  {
    value: 'SRTRANSFIN.NS',
    label: 'SRTRANSFIN.NS,  Shriram Transport Finance Company Limited',
    name: 'Shriram Transport Finance Company Limited',
    symbol: 'SRTRANSFIN.NS',
  },
  {
    value: 'TORNTPHARM.NS',
    label: 'TORNTPHARM.NS,  Torrent Pharmaceuticals Limited',
    name: 'Torrent Pharmaceuticals Limited',
    symbol: 'TORNTPHARM.NS',
  },
  {
    value: 'UBL.NS',
    label: 'UBL.NS,  United Breweries Limited',
    name: 'United Breweries Limited',
    symbol: 'UBL.NS',
  },
  {
    value: '20MICRONS.NS',
    label: '20MICRONS.NS,  20 Microns Limited',
    name: '20 Microns Limited',
    symbol: '20MICRONS.NS',
  },
  {
    value: '21STCENMGM.NS',
    label: '21STCENMGM.NS,  Twentyfirst Century Management Services Limited',
    name: 'Twentyfirst Century Management Services Limited',
    symbol: '21STCENMGM.NS',
  },
  {
    value: '3MINDIA.NS',
    label: '3MINDIA.NS,  3M India Limited',
    name: '3M India Limited',
    symbol: '3MINDIA.NS',
  },
  {
    value: '3PLAND.NS',
    label: '3PLAND.NS,  3P Land Holdings Ltd',
    name: '3P Land Holdings Ltd',
    symbol: '3PLAND.NS',
  },
  {
    value: '5PAISA.NS',
    label: '5PAISA.NS,  5paisa Capital Limited',
    name: '5paisa Capital Limited',
    symbol: '5PAISA.NS',
  },
  {
    value: 'A2ZINFRA.NS',
    label: 'A2ZINFRA.NS,  A2Z Infra Engineering Limited',
    name: 'A2Z Infra Engineering Limited',
    symbol: 'A2ZINFRA.NS',
  },
  {
    value: 'AARTIDRUGS.NS',
    label: 'AARTIDRUGS.NS,  Aarti Drugs Limited',
    name: 'Aarti Drugs Limited',
    symbol: 'AARTIDRUGS.NS',
  },
  {
    value: 'AARTIIND.NS',
    label: 'AARTIIND.NS,  Aarti Industries Limited',
    name: 'Aarti Industries Limited',
    symbol: 'AARTIIND.NS',
  },
  {
    value: 'AARVI.NS',
    label: 'AARVI.NS,  Aarvi Encon Limited',
    name: 'Aarvi Encon Limited',
    symbol: 'AARVI.NS',
  },
  {
    value: 'AAVAS.NS',
    label: 'AAVAS.NS,  Aavas Financiers Limited',
    name: 'Aavas Financiers Limited',
    symbol: 'AAVAS.NS',
  },
  {
    value: 'ABAN.NS',
    label: 'ABAN.NS,  Aban Offshore Limited',
    name: 'Aban Offshore Limited',
    symbol: 'ABAN.NS',
  },
  {
    value: 'ABB.NS',
    label: 'ABB.NS,  ABB India Limited',
    name: 'ABB India Limited',
    symbol: 'ABB.NS',
  },
  {
    value: 'ABCAPITAL.NS',
    label: 'ABCAPITAL.NS,  Aditya Birla Capital Limited',
    name: 'Aditya Birla Capital Limited',
    symbol: 'ABCAPITAL.NS',
  },
  {
    value: 'ABFRL.NS',
    label: 'ABFRL.NS,  Aditya Birla Fashion and Retail Limited',
    name: 'Aditya Birla Fashion and Retail Limited',
    symbol: 'ABFRL.NS',
  },
  {
    value: 'ACCELYA.NS',
    label: 'ACCELYA.NS,  Accelya Solutions India Limited',
    name: 'Accelya Solutions India Limited',
    symbol: 'ACCELYA.NS',
  },
  {
    value: 'ACE.NS',
    label: 'ACE.NS,  Action Construction Equipment Limited',
    name: 'Action Construction Equipment Limited',
    symbol: 'ACE.NS',
  },
  {
    value: 'ADANIENT.NS',
    label: 'ADANIENT.NS,  Adani Enterprises Limited',
    name: 'Adani Enterprises Limited',
    symbol: 'ADANIENT.NS',
  },
  {
    value: 'ADANIGREEN.NS',
    label: 'ADANIGREEN.NS,  Adani Green Energy Limited',
    name: 'Adani Green Energy Limited',
    symbol: 'ADANIGREEN.NS',
  },
  {
    value: 'ADANIPOWER.NS',
    label: 'ADANIPOWER.NS,  Adani Power Limited',
    name: 'Adani Power Limited',
    symbol: 'ADANIPOWER.NS',
  },
  {
    value: 'ADFFOODS.NS',
    label: 'ADFFOODS.NS,  ADF Foods Limited',
    name: 'ADF Foods Limited',
    symbol: 'ADFFOODS.NS',
  },
  {
    value: 'ADORWELD.NS',
    label: 'ADORWELD.NS,  Ador Welding Limited',
    name: 'Ador Welding Limited',
    symbol: 'ADORWELD.NS',
  },
  {
    value: 'ADROITINFO.NS',
    label: 'ADROITINFO.NS,  Adroit Infotech Limited',
    name: 'Adroit Infotech Limited',
    symbol: 'ADROITINFO.NS',
  },
  {
    value: 'ADSL.NS',
    label: 'ADSL.NS,  Allied Digital Services Limited',
    name: 'Allied Digital Services Limited',
    symbol: 'ADSL.NS',
  },
  {
    value: 'ADVANIHOTR.NS',
    label: 'ADVANIHOTR.NS,  Advani Hotels & Resorts (India) Limited',
    name: 'Advani Hotels & Resorts (India) Limited',
    symbol: 'ADVANIHOTR.NS',
  },
  {
    value: 'ADVENZYMES.NS',
    label: 'ADVENZYMES.NS,  Advanced Enzyme Technologies Limited',
    name: 'Advanced Enzyme Technologies Limited',
    symbol: 'ADVENZYMES.NS',
  },
  {
    value: 'AEGISCHEM.NS',
    label: 'AEGISCHEM.NS,  Aegis Logistics Limited',
    name: 'Aegis Logistics Limited',
    symbol: 'AEGISCHEM.NS',
  },
  {
    value: 'AFFLE.NS',
    label: 'AFFLE.NS,  Affle (India) Limited',
    name: 'Affle (India) Limited',
    symbol: 'AFFLE.NS',
  },
  {
    value: 'AGARIND.NS',
    label: 'AGARIND.NS,  Agarwal Industrial Corporation Limited',
    name: 'Agarwal Industrial Corporation Limited',
    symbol: 'AGARIND.NS',
  },
  {
    value: 'AGROPHOS.NS',
    label: 'AGROPHOS.NS,  Agro Phos (India) Limited',
    name: 'Agro Phos (India) Limited',
    symbol: 'AGROPHOS.NS',
  },
  {
    value: 'AHLEAST.NS',
    label: 'AHLEAST.NS,  Asian Hotels (East) Limited',
    name: 'Asian Hotels (East) Limited',
    symbol: 'AHLEAST.NS',
  },
  {
    value: 'AHLUCONT.NS',
    label: 'AHLUCONT.NS,  Ahluwalia Contracts (India) Limited',
    name: 'Ahluwalia Contracts (India) Limited',
    symbol: 'AHLUCONT.NS',
  },
  {
    value: 'AIAENG.NS',
    label: 'AIAENG.NS,  AIA Engineering Limited',
    name: 'AIA Engineering Limited',
    symbol: 'AIAENG.NS',
  },
  {
    value: 'AJANTPHARM.NS',
    label: 'AJANTPHARM.NS,  Ajanta Pharma Limited',
    name: 'Ajanta Pharma Limited',
    symbol: 'AJANTPHARM.NS',
  },
  {
    value: 'AJMERA.NS',
    label: 'AJMERA.NS,  Ajmera Realty & Infra India Limited',
    name: 'Ajmera Realty & Infra India Limited',
    symbol: 'AJMERA.NS',
  },
  {
    value: 'AKSHARCHEM.NS',
    label: 'AKSHARCHEM.NS,  AksharChem (India) Limited',
    name: 'AksharChem (India) Limited',
    symbol: 'AKSHARCHEM.NS',
  },
  {
    value: 'AKZOINDIA.NS',
    label: 'AKZOINDIA.NS,  Akzo Nobel India Limited',
    name: 'Akzo Nobel India Limited',
    symbol: 'AKZOINDIA.NS',
  },
  {
    value: 'ALANKIT.NS',
    label: 'ALANKIT.NS,  Alankit Limited',
    name: 'Alankit Limited',
    symbol: 'ALANKIT.NS',
  },
  {
    value: 'ALBERTDAVD.NS',
    label: 'ALBERTDAVD.NS,  Albert David Limited',
    name: 'Albert David Limited',
    symbol: 'ALBERTDAVD.NS',
  },
  {
    value: 'ALEMBICLTD.NS',
    label: 'ALEMBICLTD.NS,  Alembic Limited',
    name: 'Alembic Limited',
    symbol: 'ALEMBICLTD.NS',
  },
  {
    value: 'ALICON.NS',
    label: 'ALICON.NS,  Alicon Castalloy Limited',
    name: 'Alicon Castalloy Limited',
    symbol: 'ALICON.NS',
  },
  {
    value: 'ALKALI.NS',
    label: 'ALKALI.NS,  Alkali Metals Limited',
    name: 'Alkali Metals Limited',
    symbol: 'ALKALI.NS',
  },
  {
    value: 'ALKEM.NS',
    label: 'ALKEM.NS,  Alkem Laboratories Limited',
    name: 'Alkem Laboratories Limited',
    symbol: 'ALKEM.NS',
  },
  {
    value: 'ALKYLAMINE.NS',
    label: 'ALKYLAMINE.NS,  Alkyl Amines Chemicals Limited',
    name: 'Alkyl Amines Chemicals Limited',
    symbol: 'ALKYLAMINE.NS',
  },
  {
    value: 'ALLCARGO.NS',
    label: 'ALLCARGO.NS,  Allcargo Logistics Limited',
    name: 'Allcargo Logistics Limited',
    symbol: 'ALLCARGO.NS',
  },
  {
    value: 'ALLSEC.NS',
    label: 'ALLSEC.NS,  Allsec Technologies Limited',
    name: 'Allsec Technologies Limited',
    symbol: 'ALLSEC.NS',
  },
  {
    value: 'ALMONDZ.NS',
    label: 'ALMONDZ.NS,  Almondz Global Securities Limited',
    name: 'Almondz Global Securities Limited',
    symbol: 'ALMONDZ.NS',
  },
  {
    value: 'ALOKINDS.NS',
    label: 'ALOKINDS.NS,  Alok Industries Limited',
    name: 'Alok Industries Limited',
    symbol: 'ALOKINDS.NS',
  },
  {
    value: 'ALPA.NS',
    label: 'ALPA.NS,  Alpa Laboratories Limited',
    name: 'Alpa Laboratories Limited',
    symbol: 'ALPA.NS',
  },
  {
    value: 'ALPHAGEO.NS',
    label: 'ALPHAGEO.NS,  Alphageo (India) Limited',
    name: 'Alphageo (India) Limited',
    symbol: 'ALPHAGEO.NS',
  },
  {
    value: 'AMARAJABAT.NS',
    label: 'AMARAJABAT.NS,  Amara Raja Batteries Limited',
    name: 'Amara Raja Batteries Limited',
    symbol: 'AMARAJABAT.NS',
  },
  {
    value: 'AMBER.NS',
    label: 'AMBER.NS,  Amber Enterprises India Limited',
    name: 'Amber Enterprises India Limited',
    symbol: 'AMBER.NS',
  },
  {
    value: 'AMBIKCO.NS',
    label: 'AMBIKCO.NS,  Ambika Cotton Mills Limited',
    name: 'Ambika Cotton Mills Limited',
    symbol: 'AMBIKCO.NS',
  },
  {
    value: 'AMDIND.NS',
    label: 'AMDIND.NS,  AMD Industries Limited',
    name: 'AMD Industries Limited',
    symbol: 'AMDIND.NS',
  },
  {
    value: 'AMJLAND.NS',
    label: 'AMJLAND.NS,  AMJ Land Holdings Limited',
    name: 'AMJ Land Holdings Limited',
    symbol: 'AMJLAND.NS',
  },
  {
    value: 'AMRUTANJAN.NS',
    label: 'AMRUTANJAN.NS,  Amrutanjan Health Care Limited',
    name: 'Amrutanjan Health Care Limited',
    symbol: 'AMRUTANJAN.NS',
  },
  {
    value: 'ANANTRAJ.NS',
    label: 'ANANTRAJ.NS,  Anant Raj Limited',
    name: 'Anant Raj Limited',
    symbol: 'ANANTRAJ.NS',
  },
  {
    value: 'ANDHRAPAP.NS',
    label: 'ANDHRAPAP.NS,  Andhra Paper Limited',
    name: 'Andhra Paper Limited',
    symbol: 'ANDHRAPAP.NS',
  },
  {
    value: 'ANDHRSUGAR.NS',
    label: 'ANDHRSUGAR.NS,  The Andhra Sugars Limited',
    name: 'The Andhra Sugars Limited',
    symbol: 'ANDHRSUGAR.NS',
  },
  {
    value: 'ANIKINDS.NS',
    label: 'ANIKINDS.NS,  Anik Industries Limited',
    name: 'Anik Industries Limited',
    symbol: 'ANIKINDS.NS',
  },
  {
    value: 'ANSALHSG.NS',
    label: 'ANSALHSG.NS,  Ansal Housing Limited',
    name: 'Ansal Housing Limited',
    symbol: 'ANSALHSG.NS',
  },
  {
    value: 'ANUP.NS',
    label: 'ANUP.NS,  The Anup Engineering Limited',
    name: 'The Anup Engineering Limited',
    symbol: 'ANUP.NS',
  },
  {
    value: 'APARINDS.NS',
    label: 'APARINDS.NS,  Apar Industries Limited',
    name: 'Apar Industries Limited',
    symbol: 'APARINDS.NS',
  },
  {
    value: 'APCL.NS',
    label: 'APCL.NS,  Anjani Portland Cement Limited',
    name: 'Anjani Portland Cement Limited',
    symbol: 'APCL.NS',
  },
  {
    value: 'APCOTEXIND.NS',
    label: 'APCOTEXIND.NS,  Apcotex Industries Limited',
    name: 'Apcotex Industries Limited',
    symbol: 'APCOTEXIND.NS',
  },
  {
    value: 'APEX.NS',
    label: 'APEX.NS,  Apex Frozen Foods Limited',
    name: 'Apex Frozen Foods Limited',
    symbol: 'APEX.NS',
  },
  {
    value: 'APLAPOLLO.NS',
    label: 'APLAPOLLO.NS,  APL Apollo Tubes Limited',
    name: 'APL Apollo Tubes Limited',
    symbol: 'APLAPOLLO.NS',
  },
  {
    value: 'APLLTD.NS',
    label: 'APLLTD.NS,  Alembic Pharmaceuticals Limited',
    name: 'Alembic Pharmaceuticals Limited',
    symbol: 'APLLTD.NS',
  },
  {
    value: 'APOLLO.NS',
    label: 'APOLLO.NS,  Apollo Micro Systems Limited',
    name: 'Apollo Micro Systems Limited',
    symbol: 'APOLLO.NS',
  },
  {
    value: 'APOLLOHOSP.NS',
    label: 'APOLLOHOSP.NS,  Apollo Hospitals Enterprise Limited',
    name: 'Apollo Hospitals Enterprise Limited',
    symbol: 'APOLLOHOSP.NS',
  },
  {
    value: 'APOLLOPIPE.NS',
    label: 'APOLLOPIPE.NS,  Apollo Pipes Limited',
    name: 'Apollo Pipes Limited',
    symbol: 'APOLLOPIPE.NS',
  },
  {
    value: 'APOLLOTYRE.NS',
    label: 'APOLLOTYRE.NS,  Apollo Tyres Limited',
    name: 'Apollo Tyres Limited',
    symbol: 'APOLLOTYRE.NS',
  },
  {
    value: 'APOLSINHOT.NS',
    label: 'APOLSINHOT.NS,  Apollo Sindoori Hotels Limited',
    name: 'Apollo Sindoori Hotels Limited',
    symbol: 'APOLSINHOT.NS',
  },
  {
    value: 'APTECHT.NS',
    label: 'APTECHT.NS,  Aptech Limited',
    name: 'Aptech Limited',
    symbol: 'APTECHT.NS',
  },
  {
    value: 'ARCHIDPLY.NS',
    label: 'ARCHIDPLY.NS,  Archidply Industries Limited',
    name: 'Archidply Industries Limited',
    symbol: 'ARCHIDPLY.NS',
  },
  {
    value: 'ARCHIES.NS',
    label: 'ARCHIES.NS,  Archies Limited',
    name: 'Archies Limited',
    symbol: 'ARCHIES.NS',
  },
  {
    value: 'ARENTERP.NS',
    label: 'ARENTERP.NS,  Rajdarshan Industries Limited',
    name: 'Rajdarshan Industries Limited',
    symbol: 'ARENTERP.NS',
  },
  {
    value: 'ARIES.NS',
    label: 'ARIES.NS,  Aries Agro Limited',
    name: 'Aries Agro Limited',
    symbol: 'ARIES.NS',
  },
  {
    value: 'ARMANFIN.NS',
    label: 'ARMANFIN.NS,  Arman Financial Services Limited',
    name: 'Arman Financial Services Limited',
    symbol: 'ARMANFIN.NS',
  },
  {
    value: 'AROGRANITE.NS',
    label: 'AROGRANITE.NS,  Aro Granite Industries Limited',
    name: 'Aro Granite Industries Limited',
    symbol: 'AROGRANITE.NS',
  },
  {
    value: 'ARSSINFRA.NS',
    label: 'ARSSINFRA.NS,  ARSS Infrastructure Projects Limited',
    name: 'ARSS Infrastructure Projects Limited',
    symbol: 'ARSSINFRA.NS',
  },
  {
    value: 'ARTEMISMED.NS',
    label: 'ARTEMISMED.NS,  Artemis Medicare Services Limited',
    name: 'Artemis Medicare Services Limited',
    symbol: 'ARTEMISMED.NS',
  },
  {
    value: 'ARVIND.NS',
    label: 'ARVIND.NS,  Arvind Limited',
    name: 'Arvind Limited',
    symbol: 'ARVIND.NS',
  },
  {
    value: 'ARVINDFASN.NS',
    label: 'ARVINDFASN.NS,  Arvind Fashions Limited',
    name: 'Arvind Fashions Limited',
    symbol: 'ARVINDFASN.NS',
  },
  {
    value: 'ARVSMART.NS',
    label: 'ARVSMART.NS,  Arvind SmartSpaces Limited',
    name: 'Arvind SmartSpaces Limited',
    symbol: 'ARVSMART.NS',
  },
  {
    value: 'ASAHIINDIA.NS',
    label: 'ASAHIINDIA.NS,  Asahi India Glass Limited',
    name: 'Asahi India Glass Limited',
    symbol: 'ASAHIINDIA.NS',
  },
  {
    value: 'ASAHISONG.NS',
    label: 'ASAHISONG.NS,  Asahi Songwon Colors Limited',
    name: 'Asahi Songwon Colors Limited',
    symbol: 'ASAHISONG.NS',
  },
  {
    value: 'ASHAPURMIN.NS',
    label: 'ASHAPURMIN.NS,  Ashapura Minechem Limited',
    name: 'Ashapura Minechem Limited',
    symbol: 'ASHAPURMIN.NS',
  },
  {
    value: 'ASHIANA.NS',
    label: 'ASHIANA.NS,  Ashiana Housing Limited',
    name: 'Ashiana Housing Limited',
    symbol: 'ASHIANA.NS',
  },
  {
    value: 'ASHIMASYN.NS',
    label: 'ASHIMASYN.NS,  Ashima Limited',
    name: 'Ashima Limited',
    symbol: 'ASHIMASYN.NS',
  },
  {
    value: 'ASHOKA.NS',
    label: 'ASHOKA.NS,  Ashoka Buildcon Limited',
    name: 'Ashoka Buildcon Limited',
    symbol: 'ASHOKA.NS',
  },
  {
    value: 'ASHOKLEY.NS',
    label: 'ASHOKLEY.NS,  Ashok Leyland Limited',
    name: 'Ashok Leyland Limited',
    symbol: 'ASHOKLEY.NS',
  },
  {
    value: 'ASIANHOTNR.NS',
    label: 'ASIANHOTNR.NS,  Asian Hotels (North) Limited',
    name: 'Asian Hotels (North) Limited',
    symbol: 'ASIANHOTNR.NS',
  },
  {
    value: 'ASIANTILES.NS',
    label: 'ASIANTILES.NS,  Asian Granito India Limited',
    name: 'Asian Granito India Limited',
    symbol: 'ASIANTILES.NS',
  },
  {
    value: 'ASPINWALL.NS',
    label: 'ASPINWALL.NS,  Aspinwall and Company Limited',
    name: 'Aspinwall and Company Limited',
    symbol: 'ASPINWALL.NS',
  },
  {
    value: 'ASTEC.NS',
    label: 'ASTEC.NS,  Astec LifeSciences Limited',
    name: 'Astec LifeSciences Limited',
    symbol: 'ASTEC.NS',
  },
  {
    value: 'ASTERDM.NS',
    label: 'ASTERDM.NS,  Aster DM Healthcare Limited',
    name: 'Aster DM Healthcare Limited',
    symbol: 'ASTERDM.NS',
  },
  {
    value: 'ASTRAL.NS',
    label: 'ASTRAL.NS,  Astral Limited',
    name: 'Astral Limited',
    symbol: 'ASTRAL.NS',
  },
  {
    value: 'ASTRAMICRO.NS',
    label: 'ASTRAMICRO.NS,  Astra Microwave Products Limited',
    name: 'Astra Microwave Products Limited',
    symbol: 'ASTRAMICRO.NS',
  },
  {
    value: 'ASTRAZEN.NS',
    label: 'ASTRAZEN.NS,  AstraZeneca Pharma India Limited',
    name: 'AstraZeneca Pharma India Limited',
    symbol: 'ASTRAZEN.NS',
  },
  {
    value: 'ASTRON.NS',
    label: 'ASTRON.NS,  Astron Paper & Board Mill Limited',
    name: 'Astron Paper & Board Mill Limited',
    symbol: 'ASTRON.NS',
  },
  {
    value: 'ATFL.NS',
    label: 'ATFL.NS,  Agro Tech Foods Limited',
    name: 'Agro Tech Foods Limited',
    symbol: 'ATFL.NS',
  },
  {
    value: 'ATLANTA.NS',
    label: 'ATLANTA.NS,  Atlanta Limited',
    name: 'Atlanta Limited',
    symbol: 'ATLANTA.NS',
  },
  {
    value: 'ATUL.NS',
    label: 'ATUL.NS,  Atul Ltd',
    name: 'Atul Ltd',
    symbol: 'ATUL.NS',
  },
  {
    value: 'ATULAUTO.NS',
    label: 'ATULAUTO.NS,  Atul Auto Limited',
    name: 'Atul Auto Limited',
    symbol: 'ATULAUTO.NS',
  },
  {
    value: 'AUBANK.NS',
    label: 'AUBANK.NS,  AU Small Finance Bank Limited',
    name: 'AU Small Finance Bank Limited',
    symbol: 'AUBANK.NS',
  },
  {
    value: 'AURIONPRO.NS',
    label: 'AURIONPRO.NS,  Aurionpro Solutions Limited',
    name: 'Aurionpro Solutions Limited',
    symbol: 'AURIONPRO.NS',
  },
  {
    value: 'AUSOMENT.NS',
    label: 'AUSOMENT.NS,  AuSom Enterprise Limited',
    name: 'AuSom Enterprise Limited',
    symbol: 'AUSOMENT.NS',
  },
  {
    value: 'AUTOAXLES.NS',
    label: 'AUTOAXLES.NS,  Automotive Axles Limited',
    name: 'Automotive Axles Limited',
    symbol: 'AUTOAXLES.NS',
  },
  {
    value: 'AVADHSUGAR.NS',
    label: 'AVADHSUGAR.NS,  Avadh Sugar & Energy Limited',
    name: 'Avadh Sugar & Energy Limited',
    symbol: 'AVADHSUGAR.NS',
  },
  {
    value: 'AVANTIFEED.NS',
    label: 'AVANTIFEED.NS,  Avanti Feeds Limited',
    name: 'Avanti Feeds Limited',
    symbol: 'AVANTIFEED.NS',
  },
  {
    value: 'AVTNPL.NS',
    label: 'AVTNPL.NS,  AVT Natural Products Limited',
    name: 'AVT Natural Products Limited',
    symbol: 'AVTNPL.NS',
  },
  {
    value: 'AXISCADES.NS',
    label: 'AXISCADES.NS,  Axiscades Technologies Limited',
    name: 'Axiscades Technologies Limited',
    symbol: 'AXISCADES.NS',
  },
  {
    value: 'AYMSYNTEX.NS',
    label: 'AYMSYNTEX.NS,  AYM Syntex Limited',
    name: 'AYM Syntex Limited',
    symbol: 'AYMSYNTEX.NS',
  },
  {
    value: 'BAJAJCON.NS',
    label: 'BAJAJCON.NS,  Bajaj Consumer Care Limited',
    name: 'Bajaj Consumer Care Limited',
    symbol: 'BAJAJCON.NS',
  },
  {
    value: 'BAJAJELEC.NS',
    label: 'BAJAJELEC.NS,  Bajaj Electricals Limited',
    name: 'Bajaj Electricals Limited',
    symbol: 'BAJAJELEC.NS',
  },
  {
    value: 'BAJAJHIND.NS',
    label: 'BAJAJHIND.NS,  Bajaj Hindusthan Sugar Limited',
    name: 'Bajaj Hindusthan Sugar Limited',
    symbol: 'BAJAJHIND.NS',
  },
  {
    value: 'BALAJITELE.NS',
    label: 'BALAJITELE.NS,  Balaji Telefilms Limited',
    name: 'Balaji Telefilms Limited',
    symbol: 'BALAJITELE.NS',
  },
  {
    value: 'BALAMINES.NS',
    label: 'BALAMINES.NS,  Balaji Amines Limited',
    name: 'Balaji Amines Limited',
    symbol: 'BALAMINES.NS',
  },
  {
    value: 'BALAXI.NS',
    label: 'BALAXI.NS,  Balaxi Pharmaceuticals Limited',
    name: 'Balaxi Pharmaceuticals Limited',
    symbol: 'BALAXI.NS',
  },
  {
    value: 'BALKRISHNA.NS',
    label: 'BALKRISHNA.NS,  Balkrishna Paper Mills Limited',
    name: 'Balkrishna Paper Mills Limited',
    symbol: 'BALKRISHNA.NS',
  },
  {
    value: 'BALKRISIND.NS',
    label: 'BALKRISIND.NS,  Balkrishna Industries Limited',
    name: 'Balkrishna Industries Limited',
    symbol: 'BALKRISIND.NS',
  },
  {
    value: 'BALMLAWRIE.NS',
    label: 'BALMLAWRIE.NS,  Balmer Lawrie & Co. Ltd.',
    name: 'Balmer Lawrie & Co. Ltd.',
    symbol: 'BALMLAWRIE.NS',
  },
  {
    value: 'BALPHARMA.NS',
    label: 'BALPHARMA.NS,  Bal Pharma Limited',
    name: 'Bal Pharma Limited',
    symbol: 'BALPHARMA.NS',
  },
  {
    value: 'BALRAMCHIN.NS',
    label: 'BALRAMCHIN.NS,  Balrampur Chini Mills Limited',
    name: 'Balrampur Chini Mills Limited',
    symbol: 'BALRAMCHIN.NS',
  },
  {
    value: 'BANARBEADS.NS',
    label: 'BANARBEADS.NS,  Banaras Beads Limited',
    name: 'Banaras Beads Limited',
    symbol: 'BANARBEADS.NS',
  },
  {
    value: 'BANARISUG.NS',
    label: 'BANARISUG.NS,  Bannari Amman Sugars Limited',
    name: 'Bannari Amman Sugars Limited',
    symbol: 'BANARISUG.NS',
  },
  {
    value: 'BANCOINDIA.NS',
    label: 'BANCOINDIA.NS,  Banco Products (India) Limited',
    name: 'Banco Products (India) Limited',
    symbol: 'BANCOINDIA.NS',
  },
  {
    value: 'BANG.NS',
    label: 'BANG.NS,  Bang Overseas Limited',
    name: 'Bang Overseas Limited',
    symbol: 'BANG.NS',
  },
  {
    value: 'BANKINDIA.NS',
    label: 'BANKINDIA.NS,  Bank of India Limited',
    name: 'Bank of India Limited',
    symbol: 'BANKINDIA.NS',
  },
  {
    value: 'BANSWRAS.NS',
    label: 'BANSWRAS.NS,  Banswara Syntex Limited',
    name: 'Banswara Syntex Limited',
    symbol: 'BANSWRAS.NS',
  },
  {
    value: 'BASF.NS',
    label: 'BASF.NS,  BASF India Limited',
    name: 'BASF India Limited',
    symbol: 'BASF.NS',
  },
  {
    value: 'BASML.NS',
    label: 'BASML.NS,  Bannari Amman Spinning Mills Ltd',
    name: 'Bannari Amman Spinning Mills Ltd',
    symbol: 'BASML.NS',
  },
  {
    value: 'BATAINDIA.NS',
    label: 'BATAINDIA.NS,  Bata India Limited',
    name: 'Bata India Limited',
    symbol: 'BATAINDIA.NS',
  },
  {
    value: 'BAYERCROP.NS',
    label: 'BAYERCROP.NS,  Bayer CropScience Limited',
    name: 'Bayer CropScience Limited',
    symbol: 'BAYERCROP.NS',
  },
  {
    value: 'BBL.NS',
    label: 'BBL.NS,  Bharat Bijlee Limited',
    name: 'Bharat Bijlee Limited',
    symbol: 'BBL.NS',
  },
  {
    value: 'BBTC.NS',
    label: 'BBTC.NS,  The Bombay Burmah Trading Corporation, Limited',
    name: 'The Bombay Burmah Trading Corporation, Limited',
    symbol: 'BBTC.NS',
  },
  {
    value: 'BCP.NS',
    label: 'BCP.NS,  B.C. Power Controls Limited',
    name: 'B.C. Power Controls Limited',
    symbol: 'BCP.NS',
  },
  {
    value: 'BDL.NS',
    label: 'BDL.NS,  Bharat Dynamics Limited',
    name: 'Bharat Dynamics Limited',
    symbol: 'BDL.NS',
  },
  {
    value: 'BEDMUTHA.NS',
    label: 'BEDMUTHA.NS,  Bedmutha Industries Limited',
    name: 'Bedmutha Industries Limited',
    symbol: 'BEDMUTHA.NS',
  },
  {
    value: 'BEL.NS',
    label: 'BEL.NS,  Bharat Electronics Limited',
    name: 'Bharat Electronics Limited',
    symbol: 'BEL.NS',
  },
  {
    value: 'BEML.NS',
    label: 'BEML.NS,  BEML Limited',
    name: 'BEML Limited',
    symbol: 'BEML.NS',
  },
  {
    value: 'BEPL.NS',
    label: 'BEPL.NS,  Bhansali Engineering Polymers Limited',
    name: 'Bhansali Engineering Polymers Limited',
    symbol: 'BEPL.NS',
  },
  {
    value: 'BFINVEST.NS',
    label: 'BFINVEST.NS,  BF Investment Limited',
    name: 'BF Investment Limited',
    symbol: 'BFINVEST.NS',
  },
  {
    value: 'BFUTILITIE.NS',
    label: 'BFUTILITIE.NS,  BF Utilities Limited',
    name: 'BF Utilities Limited',
    symbol: 'BFUTILITIE.NS',
  },
  {
    value: 'BGRENERGY.NS',
    label: 'BGRENERGY.NS,  BGR Energy Systems Limited',
    name: 'BGR Energy Systems Limited',
    symbol: 'BGRENERGY.NS',
  },
  {
    value: 'BHAGERIA.NS',
    label: 'BHAGERIA.NS,  Bhageria Industries Limited',
    name: 'Bhageria Industries Limited',
    symbol: 'BHAGERIA.NS',
  },
  {
    value: 'BHAGYANGR.NS',
    label: 'BHAGYANGR.NS,  Bhagyanagar India Limited',
    name: 'Bhagyanagar India Limited',
    symbol: 'BHAGYANGR.NS',
  },
  {
    value: 'BHAGYAPROP.NS',
    label: 'BHAGYAPROP.NS,  Bhagyanagar Properties Limited',
    name: 'Bhagyanagar Properties Limited',
    symbol: 'BHAGYAPROP.NS',
  },
  {
    value: 'BHANDARI.NS',
    label: 'BHANDARI.NS,  Bhandari Hosiery Exports Limited',
    name: 'Bhandari Hosiery Exports Limited',
    symbol: 'BHANDARI.NS',
  },
  {
    value: 'BHARATFORG.NS',
    label: 'BHARATFORG.NS,  Bharat Forge Limited',
    name: 'Bharat Forge Limited',
    symbol: 'BHARATFORG.NS',
  },
  {
    value: 'BHARATGEAR.NS',
    label: 'BHARATGEAR.NS,  Bharat Gears Limited',
    name: 'Bharat Gears Limited',
    symbol: 'BHARATGEAR.NS',
  },
  {
    value: 'BHARATRAS.NS',
    label: 'BHARATRAS.NS,  Bharat Rasayan Limited',
    name: 'Bharat Rasayan Limited',
    symbol: 'BHARATRAS.NS',
  },
  {
    value: 'BHARATWIRE.NS',
    label: 'BHARATWIRE.NS,  Bharat Wire Ropes Limited',
    name: 'Bharat Wire Ropes Limited',
    symbol: 'BHARATWIRE.NS',
  },
  {
    value: 'BHEL.NS',
    label: 'BHEL.NS,  Bharat Heavy Electricals Limited',
    name: 'Bharat Heavy Electricals Limited',
    symbol: 'BHEL.NS',
  },
  {
    value: 'BIGBLOC.NS',
    label: 'BIGBLOC.NS,  Bigbloc Construction Limited',
    name: 'Bigbloc Construction Limited',
    symbol: 'BIGBLOC.NS',
  },
  {
    value: 'BIL.NS',
    label: 'BIL.NS,  Bhartiya International Ltd.',
    name: 'Bhartiya International Ltd.',
    symbol: 'BIL.NS',
  },
  {
    value: 'BINDALAGRO.NS',
    label: 'BINDALAGRO.NS,  Oswal Greentech Limited',
    name: 'Oswal Greentech Limited',
    symbol: 'BINDALAGRO.NS',
  },
  {
    value: 'BIRLACABLE.NS',
    label: 'BIRLACABLE.NS,  Birla Cable Limited',
    name: 'Birla Cable Limited',
    symbol: 'BIRLACABLE.NS',
  },
  {
    value: 'BIRLACORPN.NS',
    label: 'BIRLACORPN.NS,  Birla Corporation Limited',
    name: 'Birla Corporation Limited',
    symbol: 'BIRLACORPN.NS',
  },
  {
    value: 'BIRLAMONEY.NS',
    label: 'BIRLAMONEY.NS,  Aditya Birla Money Limited',
    name: 'Aditya Birla Money Limited',
    symbol: 'BIRLAMONEY.NS',
  },
  {
    value: 'BIRLATYRE.NS',
    label: 'BIRLATYRE.NS,  Birla Tyres Limited',
    name: 'Birla Tyres Limited',
    symbol: 'BIRLATYRE.NS',
  },
  {
    value: 'BLBLIMITED.NS',
    label: 'BLBLIMITED.NS,  BLB Limited',
    name: 'BLB Limited',
    symbol: 'BLBLIMITED.NS',
  },
  {
    value: 'BLISSGVS.NS',
    label: 'BLISSGVS.NS,  Bliss GVS Pharma Limited',
    name: 'Bliss GVS Pharma Limited',
    symbol: 'BLISSGVS.NS',
  },
  {
    value: 'BLKASHYAP.NS',
    label: 'BLKASHYAP.NS,  B.L. Kashyap and Sons Limited',
    name: 'B.L. Kashyap and Sons Limited',
    symbol: 'BLKASHYAP.NS',
  },
  {
    value: 'BLS.NS',
    label: 'BLS.NS,  BLS International Services Limited',
    name: 'BLS International Services Limited',
    symbol: 'BLS.NS',
  },
  {
    value: 'BLUEDART.NS',
    label: 'BLUEDART.NS,  Blue Dart Express Limited',
    name: 'Blue Dart Express Limited',
    symbol: 'BLUEDART.NS',
  },
  {
    value: 'BLUESTARCO.NS',
    label: 'BLUESTARCO.NS,  Blue Star Limited',
    name: 'Blue Star Limited',
    symbol: 'BLUESTARCO.NS',
  },
  {
    value: 'BODALCHEM.NS',
    label: 'BODALCHEM.NS,  Bodal Chemicals Limited',
    name: 'Bodal Chemicals Limited',
    symbol: 'BODALCHEM.NS',
  },
  {
    value: 'BOMDYEING.NS',
    label: 'BOMDYEING.NS,  The Bombay Dyeing and Manufacturing Company Limited',
    name: 'The Bombay Dyeing and Manufacturing Company Limited',
    symbol: 'BOMDYEING.NS',
  },
  {
    value: 'BORORENEW.NS',
    label: 'BORORENEW.NS,  Borosil Renewables Limited',
    name: 'Borosil Renewables Limited',
    symbol: 'BORORENEW.NS',
  },
  {
    value: 'BPL.NS',
    label: 'BPL.NS,  BPL Limited',
    name: 'BPL Limited',
    symbol: 'BPL.NS',
  },
  {
    value: 'BRFL.NS',
    label: 'BRFL.NS,  Bombay Rayon Fashions Limited',
    name: 'Bombay Rayon Fashions Limited',
    symbol: 'BRFL.NS',
  },
  {
    value: 'BRIGADE.NS',
    label: 'BRIGADE.NS,  Brigade Enterprises Limited',
    name: 'Brigade Enterprises Limited',
    symbol: 'BRIGADE.NS',
  },
  {
    value: 'BRNL.NS',
    label: 'BRNL.NS,  Bharat Road Network Limited',
    name: 'Bharat Road Network Limited',
    symbol: 'BRNL.NS',
  },
  {
    value: 'BSE.NS',
    label: 'BSE.NS,  BSE Limited',
    name: 'BSE Limited',
    symbol: 'BSE.NS',
  },
  {
    value: 'BSL.NS',
    label: 'BSL.NS,  BSL Limited',
    name: 'BSL Limited',
    symbol: 'BSL.NS',
  },
  {
    value: 'BSOFT.NS',
    label: 'BSOFT.NS,  Birlasoft Limited',
    name: 'Birlasoft Limited',
    symbol: 'BSOFT.NS',
  },
  {
    value: 'BUTTERFLY.NS',
    label: 'BUTTERFLY.NS,  Butterfly Gandhimathi Appliances Limited',
    name: 'Butterfly Gandhimathi Appliances Limited',
    symbol: 'BUTTERFLY.NS',
  },
  {
    value: 'BYKE.NS',
    label: 'BYKE.NS,  The Byke Hospitality Limited',
    name: 'The Byke Hospitality Limited',
    symbol: 'BYKE.NS',
  },
  {
    value: 'CALSOFT.NS',
    label: 'CALSOFT.NS,  California Software Company Limited',
    name: 'California Software Company Limited',
    symbol: 'CALSOFT.NS',
  },
  {
    value: 'CAMLINFINE.NS',
    label: 'CAMLINFINE.NS,  Camlin Fine Sciences Limited',
    name: 'Camlin Fine Sciences Limited',
    symbol: 'CAMLINFINE.NS',
  },
  {
    value: 'CANBK.NS',
    label: 'CANBK.NS,  Canara Bank',
    name: 'Canara Bank',
    symbol: 'CANBK.NS',
  },
  {
    value: 'CANFINHOME.NS',
    label: 'CANFINHOME.NS,  Can Fin Homes Limited',
    name: 'Can Fin Homes Limited',
    symbol: 'CANFINHOME.NS',
  },
  {
    value: 'CANTABIL.NS',
    label: 'CANTABIL.NS,  Cantabil Retail India Limited',
    name: 'Cantabil Retail India Limited',
    symbol: 'CANTABIL.NS',
  },
  {
    value: 'CAPACITE.NS',
    label: "CAPACITE.NS,  Capacit'e Infraprojects Limited",
    name: "Capacit'e Infraprojects Limited",
    symbol: 'CAPACITE.NS',
  },
  {
    value: 'CAPLIPOINT.NS',
    label: 'CAPLIPOINT.NS,  Caplin Point Laboratories Limited',
    name: 'Caplin Point Laboratories Limited',
    symbol: 'CAPLIPOINT.NS',
  },
  {
    value: 'CAPTRUST.NS',
    label: 'CAPTRUST.NS,  Capital Trust Limited',
    name: 'Capital Trust Limited',
    symbol: 'CAPTRUST.NS',
  },
  {
    value: 'CARBORUNIV.NS',
    label: 'CARBORUNIV.NS,  Carborundum Universal Limited',
    name: 'Carborundum Universal Limited',
    symbol: 'CARBORUNIV.NS',
  },
  {
    value: 'CAREERP.NS',
    label: 'CAREERP.NS,  Career Point Limited',
    name: 'Career Point Limited',
    symbol: 'CAREERP.NS',
  },
  {
    value: 'CARERATING.NS',
    label: 'CARERATING.NS,  CARE Ratings Limited',
    name: 'CARE Ratings Limited',
    symbol: 'CARERATING.NS',
  },
  {
    value: 'CASTROLIND.NS',
    label: 'CASTROLIND.NS,  Castrol India Limited',
    name: 'Castrol India Limited',
    symbol: 'CASTROLIND.NS',
  },
  {
    value: 'CCL.NS',
    label: 'CCL.NS,  CCL Products (India) Limited',
    name: 'CCL Products (India) Limited',
    symbol: 'CCL.NS',
  },
  {
    value: 'CDSL.NS',
    label: 'CDSL.NS,  Central Depository Services (India) Limited',
    name: 'Central Depository Services (India) Limited',
    symbol: 'CDSL.NS',
  },
  {
    value: 'CEATLTD.NS',
    label: 'CEATLTD.NS,  CEAT Limited',
    name: 'CEAT Limited',
    symbol: 'CEATLTD.NS',
  },
  {
    value: 'CELEBRITY.NS',
    label: 'CELEBRITY.NS,  Celebrity Fashions Limited',
    name: 'Celebrity Fashions Limited',
    symbol: 'CELEBRITY.NS',
  },
  {
    value: 'CENTENKA.NS',
    label: 'CENTENKA.NS,  Century Enka Limited',
    name: 'Century Enka Limited',
    symbol: 'CENTENKA.NS',
  },
  {
    value: 'CENTEXT.NS',
    label: 'CENTEXT.NS,  Century Extrusions Limited',
    name: 'Century Extrusions Limited',
    symbol: 'CENTEXT.NS',
  },
  {
    value: 'CENTRALBK.NS',
    label: 'CENTRALBK.NS,  Central Bank of India',
    name: 'Central Bank of India',
    symbol: 'CENTRALBK.NS',
  },
  {
    value: 'CENTRUM.NS',
    label: 'CENTRUM.NS,  Centrum Capital Limited',
    name: 'Centrum Capital Limited',
    symbol: 'CENTRUM.NS',
  },
  {
    value: 'CENTUM.NS',
    label: 'CENTUM.NS,  Centum Electronics Limited',
    name: 'Centum Electronics Limited',
    symbol: 'CENTUM.NS',
  },
  {
    value: 'CENTURYPLY.NS',
    label: 'CENTURYPLY.NS,  Century Plyboards (India) Limited',
    name: 'Century Plyboards (India) Limited',
    symbol: 'CENTURYPLY.NS',
  },
  {
    value: 'CENTURYTEX.NS',
    label: 'CENTURYTEX.NS,  Century Textiles and Industries Limited',
    name: 'Century Textiles and Industries Limited',
    symbol: 'CENTURYTEX.NS',
  },
  {
    value: 'CERA.NS',
    label: 'CERA.NS,  Cera Sanitaryware Limited',
    name: 'Cera Sanitaryware Limited',
    symbol: 'CERA.NS',
  },
  {
    value: 'CEREBRAINT.NS',
    label: 'CEREBRAINT.NS,  Cerebra Integrated Technologies Limited',
    name: 'Cerebra Integrated Technologies Limited',
    symbol: 'CEREBRAINT.NS',
  },
  {
    value: 'CESC.NS',
    label: 'CESC.NS,  CESC Limited',
    name: 'CESC Limited',
    symbol: 'CESC.NS',
  },
  {
    value: 'CGCL.NS',
    label: 'CGCL.NS,  Capri Global Capital Limited',
    name: 'Capri Global Capital Limited',
    symbol: 'CGCL.NS',
  },
  {
    value: 'CGPOWER.NS',
    label: 'CGPOWER.NS,  CG Power and Industrial Solutions Limited',
    name: 'CG Power and Industrial Solutions Limited',
    symbol: 'CGPOWER.NS',
  },
  {
    value: 'CHALET.NS',
    label: 'CHALET.NS,  Chalet Hotels Limited',
    name: 'Chalet Hotels Limited',
    symbol: 'CHALET.NS',
  },
  {
    value: 'CHAMBLFERT.NS',
    label: 'CHAMBLFERT.NS,  Chambal Fertilisers and Chemicals Limited',
    name: 'Chambal Fertilisers and Chemicals Limited',
    symbol: 'CHAMBLFERT.NS',
  },
  {
    value: 'CHEMBOND.NS',
    label: 'CHEMBOND.NS,  Chembond Chemicals Limited',
    name: 'Chembond Chemicals Limited',
    symbol: 'CHEMBOND.NS',
  },
  {
    value: 'CHEMFAB.NS',
    label: 'CHEMFAB.NS,  Chemfab Alkalis Limited',
    name: 'Chemfab Alkalis Limited',
    symbol: 'CHEMFAB.NS',
  },
  {
    value: 'CHENNPETRO.NS',
    label: 'CHENNPETRO.NS,  Chennai Petroleum Corporation Limited',
    name: 'Chennai Petroleum Corporation Limited',
    symbol: 'CHENNPETRO.NS',
  },
  {
    value: 'CHOLAFIN.NS',
    label: 'CHOLAFIN.NS,  Cholamandalam Investment and Finance Company Limited',
    name: 'Cholamandalam Investment and Finance Company Limited',
    symbol: 'CHOLAFIN.NS',
  },
  {
    value: 'CHOLAHLDNG.NS',
    label: 'CHOLAHLDNG.NS,  Cholamandalam Financial Holdings Limited',
    name: 'Cholamandalam Financial Holdings Limited',
    symbol: 'CHOLAHLDNG.NS',
  },
  {
    value: 'CIGNITITEC.NS',
    label: 'CIGNITITEC.NS,  Cigniti Technologies Limited',
    name: 'Cigniti Technologies Limited',
    symbol: 'CIGNITITEC.NS',
  },
  {
    value: 'CINELINE.NS',
    label: 'CINELINE.NS,  Cineline India Limited',
    name: 'Cineline India Limited',
    symbol: 'CINELINE.NS',
  },
  {
    value: 'CINEVISTA.NS',
    label: 'CINEVISTA.NS,  Cinevista Limited',
    name: 'Cinevista Limited',
    symbol: 'CINEVISTA.NS',
  },
  {
    value: 'CLEDUCATE.NS',
    label: 'CLEDUCATE.NS,  CL Educate Limited',
    name: 'CL Educate Limited',
    symbol: 'CLEDUCATE.NS',
  },
  {
    value: 'CLNINDIA.NS',
    label: 'CLNINDIA.NS,  Heubach Colorants India Limited',
    name: 'Heubach Colorants India Limited',
    symbol: 'CLNINDIA.NS',
  },
  {
    value: 'CMICABLES.NS',
    label: 'CMICABLES.NS,  CMI Limited',
    name: 'CMI Limited',
    symbol: 'CMICABLES.NS',
  },
  {
    value: 'COCHINSHIP.NS',
    label: 'COCHINSHIP.NS,  Cochin Shipyard Limited',
    name: 'Cochin Shipyard Limited',
    symbol: 'COCHINSHIP.NS',
  },
  {
    value: 'COMPINFO.NS',
    label: 'COMPINFO.NS,  Compuage Infocom Limited',
    name: 'Compuage Infocom Limited',
    symbol: 'COMPINFO.NS',
  },
  {
    value: 'CONFIPET.NS',
    label: 'CONFIPET.NS,  Confidence Petroleum India Limited',
    name: 'Confidence Petroleum India Limited',
    symbol: 'CONFIPET.NS',
  },
  {
    value: 'CONSOFINVT.NS',
    label: 'CONSOFINVT.NS,  Consolidated Finvest & Holdings Limited',
    name: 'Consolidated Finvest & Holdings Limited',
    symbol: 'CONSOFINVT.NS',
  },
  {
    value: 'CONTROLPR.NS',
    label: 'CONTROLPR.NS,  Control Print Limited',
    name: 'Control Print Limited',
    symbol: 'CONTROLPR.NS',
  },
  {
    value: 'CORALFINAC.NS',
    label: 'CORALFINAC.NS,  Coral India Finance and Housing Limited',
    name: 'Coral India Finance and Housing Limited',
    symbol: 'CORALFINAC.NS',
  },
  {
    value: 'CORDSCABLE.NS',
    label: 'CORDSCABLE.NS,  Cords Cable Industries Limited',
    name: 'Cords Cable Industries Limited',
    symbol: 'CORDSCABLE.NS',
  },
  {
    value: 'COROMANDEL.NS',
    label: 'COROMANDEL.NS,  Coromandel International Limited',
    name: 'Coromandel International Limited',
    symbol: 'COROMANDEL.NS',
  },
  {
    value: 'COUNCODOS.NS',
    label: "COUNCODOS.NS,  Country Condo's Limited",
    name: "Country Condo's Limited",
    symbol: 'COUNCODOS.NS',
  },
  {
    value: 'CREATIVE.NS',
    label: 'CREATIVE.NS,  Creative Newtech Limited',
    name: 'Creative Newtech Limited',
    symbol: 'CREATIVE.NS',
  },
  {
    value: 'CREDITACC.NS',
    label: 'CREDITACC.NS,  CreditAccess Grameen Limited',
    name: 'CreditAccess Grameen Limited',
    symbol: 'CREDITACC.NS',
  },
  {
    value: 'CREST.NS',
    label: 'CREST.NS,  Crest Ventures Limited',
    name: 'Crest Ventures Limited',
    symbol: 'CREST.NS',
  },
  {
    value: 'CRISIL.NS',
    label: 'CRISIL.NS,  CRISIL Limited',
    name: 'CRISIL Limited',
    symbol: 'CRISIL.NS',
  },
  {
    value: 'CROMPTON.NS',
    label: 'CROMPTON.NS,  Crompton Greaves Consumer Electricals Limited',
    name: 'Crompton Greaves Consumer Electricals Limited',
    symbol: 'CROMPTON.NS',
  },
  {
    value: 'CSBBANK.NS',
    label: 'CSBBANK.NS,  CSB Bank Limited',
    name: 'CSB Bank Limited',
    symbol: 'CSBBANK.NS',
  },
  {
    value: 'CTE.NS',
    label: 'CTE.NS,  Cambridge Technology Enterprises Limited',
    name: 'Cambridge Technology Enterprises Limited',
    symbol: 'CTE.NS',
  },
  {
    value: 'CUB.NS',
    label: 'CUB.NS,  City Union Bank Limited',
    name: 'City Union Bank Limited',
    symbol: 'CUB.NS',
  },
  {
    value: 'CUBEXTUB.NS',
    label: 'CUBEXTUB.NS,  Cubex Tubings Limited',
    name: 'Cubex Tubings Limited',
    symbol: 'CUBEXTUB.NS',
  },
  {
    value: 'CUMMINSIND.NS',
    label: 'CUMMINSIND.NS,  Cummins India Limited',
    name: 'Cummins India Limited',
    symbol: 'CUMMINSIND.NS',
  },
  {
    value: 'CUPID.NS',
    label: 'CUPID.NS,  Cupid Limited',
    name: 'Cupid Limited',
    symbol: 'CUPID.NS',
  },
  {
    value: 'CYBERTECH.NS',
    label: 'CYBERTECH.NS,  CyberTech Systems and Software Limited',
    name: 'CyberTech Systems and Software Limited',
    symbol: 'CYBERTECH.NS',
  },
  {
    value: 'CYIENT.NS',
    label: 'CYIENT.NS,  Cyient Limited',
    name: 'Cyient Limited',
    symbol: 'CYIENT.NS',
  },
  {
    value: 'DAAWAT.NS',
    label: 'DAAWAT.NS,  LT Foods Limited',
    name: 'LT Foods Limited',
    symbol: 'DAAWAT.NS',
  },
  {
    value: 'DALBHARAT.NS',
    label: 'DALBHARAT.NS,  Dalmia Bharat Limited',
    name: 'Dalmia Bharat Limited',
    symbol: 'DALBHARAT.NS',
  },
  {
    value: 'DALMIASUG.NS',
    label: 'DALMIASUG.NS,  Dalmia Bharat Sugar and Industries Limited',
    name: 'Dalmia Bharat Sugar and Industries Limited',
    symbol: 'DALMIASUG.NS',
  },
  {
    value: 'DAMODARIND.NS',
    label: 'DAMODARIND.NS,  Damodar Industries Limited',
    name: 'Damodar Industries Limited',
    symbol: 'DAMODARIND.NS',
  },
  {
    value: 'DATAMATICS.NS',
    label: 'DATAMATICS.NS,  Datamatics Global Services Limited',
    name: 'Datamatics Global Services Limited',
    symbol: 'DATAMATICS.NS',
  },
  {
    value: 'DBCORP.NS',
    label: 'DBCORP.NS,  D. B. Corp Limited',
    name: 'D. B. Corp Limited',
    symbol: 'DBCORP.NS',
  },
  {
    value: 'DBL.NS',
    label: 'DBL.NS,  Dilip Buildcon Limited',
    name: 'Dilip Buildcon Limited',
    symbol: 'DBL.NS',
  },
  {
    value: 'DBREALTY.NS',
    label: 'DBREALTY.NS,  D B Realty Limited',
    name: 'D B Realty Limited',
    symbol: 'DBREALTY.NS',
  },
  {
    value: 'DBSTOCKBRO.NS',
    label: 'DBSTOCKBRO.NS,  DB (International) Stock Brokers Limited',
    name: 'DB (International) Stock Brokers Limited',
    symbol: 'DBSTOCKBRO.NS',
  },
  {
    value: 'DCAL.NS',
    label: 'DCAL.NS,  Dishman Carbogen Amcis Limited',
    name: 'Dishman Carbogen Amcis Limited',
    symbol: 'DCAL.NS',
  },
  {
    value: 'DCBBANK.NS',
    label: 'DCBBANK.NS,  DCB Bank Limited',
    name: 'DCB Bank Limited',
    symbol: 'DCBBANK.NS',
  },
  {
    value: 'DCM.NS',
    label: 'DCM.NS,  DCM Limited',
    name: 'DCM Limited',
    symbol: 'DCM.NS',
  },
  {
    value: 'DCMNVL.NS',
    label: 'DCMNVL.NS,  DCM Nouvelle Limited',
    name: 'DCM Nouvelle Limited',
    symbol: 'DCMNVL.NS',
  },
  {
    value: 'DCMSHRIRAM.NS',
    label: 'DCMSHRIRAM.NS,  DCM Shriram Limited',
    name: 'DCM Shriram Limited',
    symbol: 'DCMSHRIRAM.NS',
  },
  {
    value: 'DCW.NS',
    label: 'DCW.NS,  DCW Limited',
    name: 'DCW Limited',
    symbol: 'DCW.NS',
  },
  {
    value: 'DECCANCE.NS',
    label: 'DECCANCE.NS,  Deccan Cements Limited',
    name: 'Deccan Cements Limited',
    symbol: 'DECCANCE.NS',
  },
  {
    value: 'DEEPAKFERT.NS',
    label:
      'DEEPAKFERT.NS,  Deepak Fertilisers And Petrochemicals Corporation Limited',
    name: 'Deepak Fertilisers And Petrochemicals Corporation Limited',
    symbol: 'DEEPAKFERT.NS',
  },
  {
    value: 'DEEPAKNTR.NS',
    label: 'DEEPAKNTR.NS,  Deepak Nitrite Limited',
    name: 'Deepak Nitrite Limited',
    symbol: 'DEEPAKNTR.NS',
  },
  {
    value: 'DELTACORP.NS',
    label: 'DELTACORP.NS,  Delta Corp Limited',
    name: 'Delta Corp Limited',
    symbol: 'DELTACORP.NS',
  },
  {
    value: 'DELTAMAGNT.NS',
    label: 'DELTAMAGNT.NS,  Delta Manufacturing Limited',
    name: 'Delta Manufacturing Limited',
    symbol: 'DELTAMAGNT.NS',
  },
  {
    value: 'DEN.NS',
    label: 'DEN.NS,  DEN Networks Limited',
    name: 'DEN Networks Limited',
    symbol: 'DEN.NS',
  },
  {
    value: 'DENORA.NS',
    label: 'DENORA.NS,  De Nora India Limited',
    name: 'De Nora India Limited',
    symbol: 'DENORA.NS',
  },
  {
    value: 'DFMFOODS.NS',
    label: 'DFMFOODS.NS,  DFM Foods Limited',
    name: 'DFM Foods Limited',
    symbol: 'DFMFOODS.NS',
  },
  {
    value: 'DGCONTENT.NS',
    label: 'DGCONTENT.NS,  Digicontent Limited',
    name: 'Digicontent Limited',
    symbol: 'DGCONTENT.NS',
  },
  {
    value: 'DHAMPURSUG.NS',
    label: 'DHAMPURSUG.NS,  Dhampur Sugar Mills Limited',
    name: 'Dhampur Sugar Mills Limited',
    symbol: 'DHAMPURSUG.NS',
  },
  {
    value: 'DHANBANK.NS',
    label: 'DHANBANK.NS,  Dhanlaxmi Bank Limited',
    name: 'Dhanlaxmi Bank Limited',
    symbol: 'DHANBANK.NS',
  },
  {
    value: 'DHANUKA.NS',
    label: 'DHANUKA.NS,  Dhanuka Agritech Limited',
    name: 'Dhanuka Agritech Limited',
    symbol: 'DHANUKA.NS',
  },
  {
    value: 'DHUNINV.NS',
    label: 'DHUNINV.NS,  Dhunseri Investments Limited',
    name: 'Dhunseri Investments Limited',
    symbol: 'DHUNINV.NS',
  },
  {
    value: 'DIAMONDYD.NS',
    label: 'DIAMONDYD.NS,  Prataap Snacks Limited',
    name: 'Prataap Snacks Limited',
    symbol: 'DIAMONDYD.NS',
  },
  {
    value: 'DICIND.NS',
    label: 'DICIND.NS,  DIC India Limited',
    name: 'DIC India Limited',
    symbol: 'DICIND.NS',
  },
  {
    value: 'DISHTV.NS',
    label: 'DISHTV.NS,  Dish TV India Limited',
    name: 'Dish TV India Limited',
    symbol: 'DISHTV.NS',
  },
  {
    value: 'DIXON.NS',
    label: 'DIXON.NS,  Dixon Technologies (India) Limited',
    name: 'Dixon Technologies (India) Limited',
    symbol: 'DIXON.NS',
  },
  {
    value: 'DLINKINDIA.NS',
    label: 'DLINKINDIA.NS,  D-Link (India) Limited',
    name: 'D-Link (India) Limited',
    symbol: 'DLINKINDIA.NS',
  },
  {
    value: 'DOLLAR.NS',
    label: 'DOLLAR.NS,  Dollar Industries Limited',
    name: 'Dollar Industries Limited',
    symbol: 'DOLLAR.NS',
  },
  {
    value: 'DONEAR.NS',
    label: 'DONEAR.NS,  Donear Industries Limited',
    name: 'Donear Industries Limited',
    symbol: 'DONEAR.NS',
  },
  {
    value: 'DPSCLTD.NS',
    label: 'DPSCLTD.NS,  India Power Corporation Limited',
    name: 'India Power Corporation Limited',
    symbol: 'DPSCLTD.NS',
  },
  {
    value: 'DREDGECORP.NS',
    label: 'DREDGECORP.NS,  Dredging Corporation of India Limited',
    name: 'Dredging Corporation of India Limited',
    symbol: 'DREDGECORP.NS',
  },
  {
    value: 'DSSL.NS',
    label: 'DSSL.NS,  Dynacons Systems & Solutions Limited',
    name: 'Dynacons Systems & Solutions Limited',
    symbol: 'DSSL.NS',
  },
  {
    value: 'DTIL.NS',
    label: 'DTIL.NS,  Dhunseri Tea & Industries Limited',
    name: 'Dhunseri Tea & Industries Limited',
    symbol: 'DTIL.NS',
  },
  {
    value: 'DUCON.NS',
    label: 'DUCON.NS,  Ducon Infratechnologies Limited',
    name: 'Ducon Infratechnologies Limited',
    symbol: 'DUCON.NS',
  },
  {
    value: 'DVL.NS',
    label: 'DVL.NS,  Dhunseri Ventures Limited',
    name: 'Dhunseri Ventures Limited',
    symbol: 'DVL.NS',
  },
  {
    value: 'DWARKESH.NS',
    label: 'DWARKESH.NS,  Dwarikesh Sugar Industries Limited',
    name: 'Dwarikesh Sugar Industries Limited',
    symbol: 'DWARKESH.NS',
  },
  {
    value: 'DYNAMATECH.NS',
    label: 'DYNAMATECH.NS,  Dynamatic Technologies Limited',
    name: 'Dynamatic Technologies Limited',
    symbol: 'DYNAMATECH.NS',
  },
  {
    value: 'DYNPRO.NS',
    label: 'DYNPRO.NS,  Dynemic Products Limited',
    name: 'Dynemic Products Limited',
    symbol: 'DYNPRO.NS',
  },
  {
    value: 'EASTSILK.NS',
    label: 'EASTSILK.NS,  Eastern Silk Industries Limited',
    name: 'Eastern Silk Industries Limited',
    symbol: 'EASTSILK.NS',
  },
  {
    value: 'ECLERX.NS',
    label: 'ECLERX.NS,  eClerx Services Limited',
    name: 'eClerx Services Limited',
    symbol: 'ECLERX.NS',
  },
  {
    value: 'EDELWEISS.NS',
    label: 'EDELWEISS.NS,  Edelweiss Financial Services Limited',
    name: 'Edelweiss Financial Services Limited',
    symbol: 'EDELWEISS.NS',
  },
  {
    value: 'EIDPARRY.NS',
    label: 'EIDPARRY.NS,  E.I.D.- Parry (India) Limited',
    name: 'E.I.D.- Parry (India) Limited',
    symbol: 'EIDPARRY.NS',
  },
  {
    value: 'EIHAHOTELS.NS',
    label: 'EIHAHOTELS.NS,  EIH Associated Hotels Limited',
    name: 'EIH Associated Hotels Limited',
    symbol: 'EIHAHOTELS.NS',
  },
  {
    value: 'EIHOTEL.NS',
    label: 'EIHOTEL.NS,  EIH Limited',
    name: 'EIH Limited',
    symbol: 'EIHOTEL.NS',
  },
  {
    value: 'EIMCOELECO.NS',
    label: 'EIMCOELECO.NS,  Eimco Elecon (India) Limited',
    name: 'Eimco Elecon (India) Limited',
    symbol: 'EIMCOELECO.NS',
  },
  {
    value: 'EKC.NS',
    label: 'EKC.NS,  Everest Kanto Cylinder Limited',
    name: 'Everest Kanto Cylinder Limited',
    symbol: 'EKC.NS',
  },
  {
    value: 'ELECON.NS',
    label: 'ELECON.NS,  Elecon Engineering Company Limited',
    name: 'Elecon Engineering Company Limited',
    symbol: 'ELECON.NS',
  },
  {
    value: 'ELECTCAST.NS',
    label: 'ELECTCAST.NS,  Electrosteel Castings Limited',
    name: 'Electrosteel Castings Limited',
    symbol: 'ELECTCAST.NS',
  },
  {
    value: 'ELECTHERM.NS',
    label: 'ELECTHERM.NS,  Electrotherm (India) Limited',
    name: 'Electrotherm (India) Limited',
    symbol: 'ELECTHERM.NS',
  },
  {
    value: 'ELGIEQUIP.NS',
    label: 'ELGIEQUIP.NS,  Elgi Equipments Limited',
    name: 'Elgi Equipments Limited',
    symbol: 'ELGIEQUIP.NS',
  },
  {
    value: 'ELGIRUBCO.NS',
    label: 'ELGIRUBCO.NS,  Elgi Rubber Company Limited',
    name: 'Elgi Rubber Company Limited',
    symbol: 'ELGIRUBCO.NS',
  },
  {
    value: 'EMAMILTD.NS',
    label: 'EMAMILTD.NS,  Emami Limited',
    name: 'Emami Limited',
    symbol: 'EMAMILTD.NS',
  },
  {
    value: 'EMAMIPAP.NS',
    label: 'EMAMIPAP.NS,  Emami Paper Mills Limited',
    name: 'Emami Paper Mills Limited',
    symbol: 'EMAMIPAP.NS',
  },
  {
    value: 'EMAMIREAL.NS',
    label: 'EMAMIREAL.NS,  Emami Realty Limited',
    name: 'Emami Realty Limited',
    symbol: 'EMAMIREAL.NS',
  },
  {
    value: 'EMKAY.NS',
    label: 'EMKAY.NS,  Emkay Global Financial Services Limited',
    name: 'Emkay Global Financial Services Limited',
    symbol: 'EMKAY.NS',
  },
  {
    value: 'EMMBI.NS',
    label: 'EMMBI.NS,  Emmbi Industries Limited',
    name: 'Emmbi Industries Limited',
    symbol: 'EMMBI.NS',
  },
  {
    value: 'ENDURANCE.NS',
    label: 'ENDURANCE.NS,  Endurance Technologies Limited',
    name: 'Endurance Technologies Limited',
    symbol: 'ENDURANCE.NS',
  },
  {
    value: 'ENERGYDEV.NS',
    label: 'ENERGYDEV.NS,  Energy Development Company Limited',
    name: 'Energy Development Company Limited',
    symbol: 'ENERGYDEV.NS',
  },
  {
    value: 'ENGINERSIN.NS',
    label: 'ENGINERSIN.NS,  Engineers India Limited',
    name: 'Engineers India Limited',
    symbol: 'ENGINERSIN.NS',
  },
  {
    value: 'ENIL.NS',
    label: 'ENIL.NS,  Entertainment Network (India) Limited',
    name: 'Entertainment Network (India) Limited',
    symbol: 'ENIL.NS',
  },
  {
    value: 'EQUITAS.NS',
    label: 'EQUITAS.NS,  Equitas Holdings Limited',
    name: 'Equitas Holdings Limited',
    symbol: 'EQUITAS.NS',
  },
  {
    value: 'ERIS.NS',
    label: 'ERIS.NS,  Eris Lifesciences Limited',
    name: 'Eris Lifesciences Limited',
    symbol: 'ERIS.NS',
  },
  {
    value: 'EROSMEDIA.NS',
    label: 'EROSMEDIA.NS,  Eros International Media Limited',
    name: 'Eros International Media Limited',
    symbol: 'EROSMEDIA.NS',
  },
  {
    value: 'ESABINDIA.NS',
    label: 'ESABINDIA.NS,  ESAB India Limited',
    name: 'ESAB India Limited',
    symbol: 'ESABINDIA.NS',
  },
  {
    value: 'ESCORTS.NS',
    label: 'ESCORTS.NS,  Escorts Kubota Limited',
    name: 'Escorts Kubota Limited',
    symbol: 'ESCORTS.NS',
  },
  {
    value: 'ESSARSHPNG.NS',
    label: 'ESSARSHPNG.NS,  Essar Shipping Limited',
    name: 'Essar Shipping Limited',
    symbol: 'ESSARSHPNG.NS',
  },
  {
    value: 'ESTER.NS',
    label: 'ESTER.NS,  Ester Industries Limited',
    name: 'Ester Industries Limited',
    symbol: 'ESTER.NS',
  },
  {
    value: 'EUROTEXIND.NS',
    label: 'EUROTEXIND.NS,  Eurotex Industries and Exports Limited',
    name: 'Eurotex Industries and Exports Limited',
    symbol: 'EUROTEXIND.NS',
  },
  {
    value: 'EVEREADY.NS',
    label: 'EVEREADY.NS,  Eveready Industries India Limited',
    name: 'Eveready Industries India Limited',
    symbol: 'EVEREADY.NS',
  },
  {
    value: 'EVERESTIND.NS',
    label: 'EVERESTIND.NS,  Everest Industries Limited',
    name: 'Everest Industries Limited',
    symbol: 'EVERESTIND.NS',
  },
  {
    value: 'EXCEL.NS',
    label: 'EXCEL.NS,  Excel Realty N Infra Limited',
    name: 'Excel Realty N Infra Limited',
    symbol: 'EXCEL.NS',
  },
  {
    value: 'EXCELINDUS.NS',
    label: 'EXCELINDUS.NS,  Excel Industries Limited',
    name: 'Excel Industries Limited',
    symbol: 'EXCELINDUS.NS',
  },
  {
    value: 'EXIDEIND.NS',
    label: 'EXIDEIND.NS,  Exide Industries Limited',
    name: 'Exide Industries Limited',
    symbol: 'EXIDEIND.NS',
  },
  {
    value: 'EXPLEOSOL.NS',
    label: 'EXPLEOSOL.NS,  Expleo Solutions Limited',
    name: 'Expleo Solutions Limited',
    symbol: 'EXPLEOSOL.NS',
  },
  {
    value: 'FACT.NS',
    label: 'FACT.NS,  The Fertilisers And Chemicals Travancore Limited',
    name: 'The Fertilisers And Chemicals Travancore Limited',
    symbol: 'FACT.NS',
  },
  {
    value: 'FCL.NS',
    label: 'FCL.NS,  Fineotex Chemical Limited',
    name: 'Fineotex Chemical Limited',
    symbol: 'FCL.NS',
  },
  {
    value: 'FCONSUMER.NS',
    label: 'FCONSUMER.NS,  Future Consumer Limited',
    name: 'Future Consumer Limited',
    symbol: 'FCONSUMER.NS',
  },
  {
    value: 'FDC.NS',
    label: 'FDC.NS,  FDC Limited',
    name: 'FDC Limited',
    symbol: 'FDC.NS',
  },
  {
    value: 'FEDERALBNK.NS',
    label: 'FEDERALBNK.NS,  The Federal Bank Limited',
    name: 'The Federal Bank Limited',
    symbol: 'FEDERALBNK.NS',
  },
  {
    value: 'FEL.NS',
    label: 'FEL.NS,  Future Enterprises Limited',
    name: 'Future Enterprises Limited',
    symbol: 'FEL.NS',
  },
  {
    value: 'FIEMIND.NS',
    label: 'FIEMIND.NS,  Fiem Industries Limited',
    name: 'Fiem Industries Limited',
    symbol: 'FIEMIND.NS',
  },
  {
    value: 'FILATEX.NS',
    label: 'FILATEX.NS,  Filatex India Limited',
    name: 'Filatex India Limited',
    symbol: 'FILATEX.NS',
  },
  {
    value: 'FINCABLES.NS',
    label: 'FINCABLES.NS,  Finolex Cables Limited',
    name: 'Finolex Cables Limited',
    symbol: 'FINCABLES.NS',
  },
  {
    value: 'FINEORG.NS',
    label: 'FINEORG.NS,  Fine Organic Industries Limited',
    name: 'Fine Organic Industries Limited',
    symbol: 'FINEORG.NS',
  },
  {
    value: 'FINPIPE.NS',
    label: 'FINPIPE.NS,  Finolex Industries Limited',
    name: 'Finolex Industries Limited',
    symbol: 'FINPIPE.NS',
  },
  {
    value: 'FLFL.NS',
    label: 'FLFL.NS,  Future Lifestyle Fashions Limited',
    name: 'Future Lifestyle Fashions Limited',
    symbol: 'FLFL.NS',
  },
  {
    value: 'FLUOROCHEM.NS',
    label: 'FLUOROCHEM.NS,  Gujarat Fluorochemicals Limited',
    name: 'Gujarat Fluorochemicals Limited',
    symbol: 'FLUOROCHEM.NS',
  },
  {
    value: 'FMGOETZE.NS',
    label: 'FMGOETZE.NS,  Federal-Mogul Goetze (India) Limited',
    name: 'Federal-Mogul Goetze (India) Limited',
    symbol: 'FMGOETZE.NS',
  },
  {
    value: 'FMNL.NS',
    label: 'FMNL.NS,  Future Market Networks Limited',
    name: 'Future Market Networks Limited',
    symbol: 'FMNL.NS',
  },
  {
    value: 'FORCEMOT.NS',
    label: 'FORCEMOT.NS,  Force Motors Limited',
    name: 'Force Motors Limited',
    symbol: 'FORCEMOT.NS',
  },
  {
    value: 'FORTIS.NS',
    label: 'FORTIS.NS,  Fortis Healthcare Limited',
    name: 'Fortis Healthcare Limited',
    symbol: 'FORTIS.NS',
  },
  {
    value: 'FOSECOIND.NS',
    label: 'FOSECOIND.NS,  Foseco India Limited',
    name: 'Foseco India Limited',
    symbol: 'FOSECOIND.NS',
  },
  {
    value: 'FRETAIL.NS',
    label: 'FRETAIL.NS,  Future Retail Limited',
    name: 'Future Retail Limited',
    symbol: 'FRETAIL.NS',
  },
  {
    value: 'FSC.NS',
    label: 'FSC.NS,  Future Supply Chain Solutions Limited',
    name: 'Future Supply Chain Solutions Limited',
    symbol: 'FSC.NS',
  },
  {
    value: 'FSL.NS',
    label: 'FSL.NS,  Firstsource Solutions Limited',
    name: 'Firstsource Solutions Limited',
    symbol: 'FSL.NS',
  },
  {
    value: 'GABRIEL.NS',
    label: 'GABRIEL.NS,  Gabriel India Limited',
    name: 'Gabriel India Limited',
    symbol: 'GABRIEL.NS',
  },
  {
    value: 'GAEL.NS',
    label: 'GAEL.NS,  Gujarat Ambuja Exports Limited',
    name: 'Gujarat Ambuja Exports Limited',
    symbol: 'GAEL.NS',
  },
  {
    value: 'GALAXYSURF.NS',
    label: 'GALAXYSURF.NS,  Galaxy Surfactants Limited',
    name: 'Galaxy Surfactants Limited',
    symbol: 'GALAXYSURF.NS',
  },
  {
    value: 'GALLANTT.NS',
    label: 'GALLANTT.NS,  Gallantt Ispat Limited',
    name: 'Gallantt Ispat Limited',
    symbol: 'GALLANTT.NS',
  },
  {
    value: 'GANDHITUBE.NS',
    label: 'GANDHITUBE.NS,  Gandhi Special Tubes Limited',
    name: 'Gandhi Special Tubes Limited',
    symbol: 'GANDHITUBE.NS',
  },
  {
    value: 'GANECOS.NS',
    label: 'GANECOS.NS,  Ganesha Ecosphere Limited',
    name: 'Ganesha Ecosphere Limited',
    symbol: 'GANECOS.NS',
  },
  {
    value: 'GANESHHOUC.NS',
    label: 'GANESHHOUC.NS,  Ganesh Housing Corporation Limited',
    name: 'Ganesh Housing Corporation Limited',
    symbol: 'GANESHHOUC.NS',
  },
  {
    value: 'GANGESSECU.NS',
    label: 'GANGESSECU.NS,  Ganges Securities Limited',
    name: 'Ganges Securities Limited',
    symbol: 'GANGESSECU.NS',
  },
  {
    value: 'GARFIBRES.NS',
    label: 'GARFIBRES.NS,  Garware Technical Fibres Limited',
    name: 'Garware Technical Fibres Limited',
    symbol: 'GARFIBRES.NS',
  },
  {
    value: 'GATI.NS',
    label: 'GATI.NS,  Gati Limited',
    name: 'Gati Limited',
    symbol: 'GATI.NS',
  },
  {
    value: 'GEECEE.NS',
    label: 'GEECEE.NS,  GeeCee Ventures Limited',
    name: 'GeeCee Ventures Limited',
    symbol: 'GEECEE.NS',
  },
  {
    value: 'GEEKAYWIRE.NS',
    label: 'GEEKAYWIRE.NS,  Geekay Wires Limited',
    name: 'Geekay Wires Limited',
    symbol: 'GEEKAYWIRE.NS',
  },
  {
    value: 'GENESYS.NS',
    label: 'GENESYS.NS,  Genesys International Corporation Limited',
    name: 'Genesys International Corporation Limited',
    symbol: 'GENESYS.NS',
  },
  {
    value: 'GENUSPAPER.NS',
    label: 'GENUSPAPER.NS,  Genus Paper & Boards Limited',
    name: 'Genus Paper & Boards Limited',
    symbol: 'GENUSPAPER.NS',
  },
  {
    value: 'GENUSPOWER.NS',
    label: 'GENUSPOWER.NS,  Genus Power Infrastructures Limited',
    name: 'Genus Power Infrastructures Limited',
    symbol: 'GENUSPOWER.NS',
  },
  {
    value: 'GEOJITFSL.NS',
    label: 'GEOJITFSL.NS,  Geojit Financial Services Limited',
    name: 'Geojit Financial Services Limited',
    symbol: 'GEOJITFSL.NS',
  },
  {
    value: 'GEPIL.NS',
    label: 'GEPIL.NS,  GE Power India Limited',
    name: 'GE Power India Limited',
    symbol: 'GEPIL.NS',
  },
  {
    value: 'GESHIP.NS',
    label: 'GESHIP.NS,  The Great Eastern Shipping Company Limited',
    name: 'The Great Eastern Shipping Company Limited',
    symbol: 'GESHIP.NS',
  },
  {
    value: 'GFLLIMITED.NS',
    label: 'GFLLIMITED.NS,  GFL Limited',
    name: 'GFL Limited',
    symbol: 'GFLLIMITED.NS',
  },
  {
    value: 'GHCL.NS',
    label: 'GHCL.NS,  GHCL Limited',
    name: 'GHCL Limited',
    symbol: 'GHCL.NS',
  },
  {
    value: 'GICHSGFIN.NS',
    label: 'GICHSGFIN.NS,  GIC Housing Finance Limited',
    name: 'GIC Housing Finance Limited',
    symbol: 'GICHSGFIN.NS',
  },
  {
    value: 'GILLETTE.NS',
    label: 'GILLETTE.NS,  Gillette India Limited',
    name: 'Gillette India Limited',
    symbol: 'GILLETTE.NS',
  },
  {
    value: 'GINNIFILA.NS',
    label: 'GINNIFILA.NS,  Ginni Filaments Limited',
    name: 'Ginni Filaments Limited',
    symbol: 'GINNIFILA.NS',
  },
  {
    value: 'GIPCL.NS',
    label: 'GIPCL.NS,  Gujarat Industries Power Company Limited',
    name: 'Gujarat Industries Power Company Limited',
    symbol: 'GIPCL.NS',
  },
  {
    value: 'GKWLIMITED.NS',
    label: 'GKWLIMITED.NS,  GKW Limited',
    name: 'GKW Limited',
    symbol: 'GKWLIMITED.NS',
  },
  {
    value: 'GLAXO.NS',
    label: 'GLAXO.NS,  GlaxoSmithKline Pharmaceuticals Limited',
    name: 'GlaxoSmithKline Pharmaceuticals Limited',
    symbol: 'GLAXO.NS',
  },
  {
    value: 'GLENMARK.NS',
    label: 'GLENMARK.NS,  Glenmark Pharmaceuticals Limited',
    name: 'Glenmark Pharmaceuticals Limited',
    symbol: 'GLENMARK.NS',
  },
  {
    value: 'GLOBALVECT.NS',
    label: 'GLOBALVECT.NS,  Global Vectra Helicorp Limited',
    name: 'Global Vectra Helicorp Limited',
    symbol: 'GLOBALVECT.NS',
  },
  {
    value: 'GLOBUSSPR.NS',
    label: 'GLOBUSSPR.NS,  Globus Spirits Limited',
    name: 'Globus Spirits Limited',
    symbol: 'GLOBUSSPR.NS',
  },
  {
    value: 'GMBREW.NS',
    label: 'GMBREW.NS,  G.M. Breweries Limited',
    name: 'G.M. Breweries Limited',
    symbol: 'GMBREW.NS',
  },
  {
    value: 'GMDCLTD.NS',
    label: 'GMDCLTD.NS,  Gujarat Mineral Development Corporation Limited',
    name: 'Gujarat Mineral Development Corporation Limited',
    symbol: 'GMDCLTD.NS',
  },
  {
    value: 'GMMPFAUDLR.NS',
    label: 'GMMPFAUDLR.NS,  GMM Pfaudler Limited',
    name: 'GMM Pfaudler Limited',
    symbol: 'GMMPFAUDLR.NS',
  },
  {
    value: 'GMRINFRA.NS',
    label: 'GMRINFRA.NS,  GMR Airports Infrastructure Limited',
    name: 'GMR Airports Infrastructure Limited',
    symbol: 'GMRINFRA.NS',
  },
  {
    value: 'GNA.NS',
    label: 'GNA.NS,  G N A Axles Limited',
    name: 'G N A Axles Limited',
    symbol: 'GNA.NS',
  },
  {
    value: 'GNFC.NS',
    label: 'GNFC.NS,  Gujarat Narmada Valley Fertilizers & Chemicals Limited',
    name: 'Gujarat Narmada Valley Fertilizers & Chemicals Limited',
    symbol: 'GNFC.NS',
  },
  {
    value: 'GOACARBON.NS',
    label: 'GOACARBON.NS,  Goa Carbon Limited',
    name: 'Goa Carbon Limited',
    symbol: 'GOACARBON.NS',
  },
  {
    value: 'GOCLCORP.NS',
    label: 'GOCLCORP.NS,  GOCL Corporation Limited',
    name: 'GOCL Corporation Limited',
    symbol: 'GOCLCORP.NS',
  },
  {
    value: 'GODFRYPHLP.NS',
    label: 'GODFRYPHLP.NS,  Godfrey Phillips India Limited',
    name: 'Godfrey Phillips India Limited',
    symbol: 'GODFRYPHLP.NS',
  },
  {
    value: 'GODREJAGRO.NS',
    label: 'GODREJAGRO.NS,  Godrej Agrovet Limited',
    name: 'Godrej Agrovet Limited',
    symbol: 'GODREJAGRO.NS',
  },
  {
    value: 'GODREJIND.NS',
    label: 'GODREJIND.NS,  Godrej Industries Limited',
    name: 'Godrej Industries Limited',
    symbol: 'GODREJIND.NS',
  },
  {
    value: 'GODREJPROP.NS',
    label: 'GODREJPROP.NS,  Godrej Properties Limited',
    name: 'Godrej Properties Limited',
    symbol: 'GODREJPROP.NS',
  },
  {
    value: 'GOKEX.NS',
    label: 'GOKEX.NS,  Gokaldas Exports Limited',
    name: 'Gokaldas Exports Limited',
    symbol: 'GOKEX.NS',
  },
  {
    value: 'GOKUL.NS',
    label: 'GOKUL.NS,  Gokul Refoils & Solvent Limited',
    name: 'Gokul Refoils & Solvent Limited',
    symbol: 'GOKUL.NS',
  },
  {
    value: 'GOKULAGRO.NS',
    label: 'GOKULAGRO.NS,  Gokul Agro Resources Limited',
    name: 'Gokul Agro Resources Limited',
    symbol: 'GOKULAGRO.NS',
  },
  {
    value: 'GOLDENTOBC.NS',
    label: 'GOLDENTOBC.NS,  Golden Tobacco Limited',
    name: 'Golden Tobacco Limited',
    symbol: 'GOLDENTOBC.NS',
  },
  {
    value: 'GOLDIAM.NS',
    label: 'GOLDIAM.NS,  Goldiam International Limited',
    name: 'Goldiam International Limited',
    symbol: 'GOLDIAM.NS',
  },
  {
    value: 'GOLDTECH.NS',
    label: 'GOLDTECH.NS,  Goldstone Technologies Limited',
    name: 'Goldstone Technologies Limited',
    symbol: 'GOLDTECH.NS',
  },
  {
    value: 'GOODLUCK.NS',
    label: 'GOODLUCK.NS,  Goodluck India Limited',
    name: 'Goodluck India Limited',
    symbol: 'GOODLUCK.NS',
  },
  {
    value: 'GPIL.NS',
    label: 'GPIL.NS,  Godawari Power & Ispat Limited',
    name: 'Godawari Power & Ispat Limited',
    symbol: 'GPIL.NS',
  },
  {
    value: 'GPPL.NS',
    label: 'GPPL.NS,  Gujarat Pipavav Port Limited',
    name: 'Gujarat Pipavav Port Limited',
    symbol: 'GPPL.NS',
  },
  {
    value: 'GPTINFRA.NS',
    label: 'GPTINFRA.NS,  GPT Infraprojects Limited',
    name: 'GPT Infraprojects Limited',
    symbol: 'GPTINFRA.NS',
  },
  {
    value: 'GRANULES.NS',
    label: 'GRANULES.NS,  Granules India Limited',
    name: 'Granules India Limited',
    symbol: 'GRANULES.NS',
  },
  {
    value: 'GRAPHITE.NS',
    label: 'GRAPHITE.NS,  Graphite India Limited',
    name: 'Graphite India Limited',
    symbol: 'GRAPHITE.NS',
  },
  {
    value: 'GRAVITA.NS',
    label: 'GRAVITA.NS,  Gravita India Limited',
    name: 'Gravita India Limited',
    symbol: 'GRAVITA.NS',
  },
  {
    value: 'GREAVESCOT.NS',
    label: 'GREAVESCOT.NS,  Greaves Cotton Limited',
    name: 'Greaves Cotton Limited',
    symbol: 'GREAVESCOT.NS',
  },
  {
    value: 'GREENLAM.NS',
    label: 'GREENLAM.NS,  Greenlam Industries Limited',
    name: 'Greenlam Industries Limited',
    symbol: 'GREENLAM.NS',
  },
  {
    value: 'GREENPANEL.NS',
    label: 'GREENPANEL.NS,  Greenpanel Industries Limited',
    name: 'Greenpanel Industries Limited',
    symbol: 'GREENPANEL.NS',
  },
  {
    value: 'GREENPLY.NS',
    label: 'GREENPLY.NS,  Greenply Industries Limited',
    name: 'Greenply Industries Limited',
    symbol: 'GREENPLY.NS',
  },
  {
    value: 'GREENPOWER.NS',
    label: 'GREENPOWER.NS,  Orient Green Power Company Limited',
    name: 'Orient Green Power Company Limited',
    symbol: 'GREENPOWER.NS',
  },
  {
    value: 'GRINDWELL.NS',
    label: 'GRINDWELL.NS,  Grindwell Norton Limited',
    name: 'Grindwell Norton Limited',
    symbol: 'GRINDWELL.NS',
  },
  {
    value: 'GROBTEA.NS',
    label: 'GROBTEA.NS,  The Grob Tea Company Limited',
    name: 'The Grob Tea Company Limited',
    symbol: 'GROBTEA.NS',
  },
  {
    value: 'GRPLTD.NS',
    label: 'GRPLTD.NS,  GRP Limited',
    name: 'GRP Limited',
    symbol: 'GRPLTD.NS',
  },
  {
    value: 'GRSE.NS',
    label: 'GRSE.NS,  Garden Reach Shipbuilders & Engineers Limited',
    name: 'Garden Reach Shipbuilders & Engineers Limited',
    symbol: 'GRSE.NS',
  },
  {
    value: 'GSCLCEMENT.NS',
    label: 'GSCLCEMENT.NS,  Gujarat Sidhee Cement Limited',
    name: 'Gujarat Sidhee Cement Limited',
    symbol: 'GSCLCEMENT.NS',
  },
  {
    value: 'GSFC.NS',
    label: 'GSFC.NS,  Gujarat State Fertilizers & Chemicals Limited',
    name: 'Gujarat State Fertilizers & Chemicals Limited',
    symbol: 'GSFC.NS',
  },
  {
    value: 'GSPL.NS',
    label: 'GSPL.NS,  Gujarat State Petronet Limited',
    name: 'Gujarat State Petronet Limited',
    symbol: 'GSPL.NS',
  },
  {
    value: 'GSS.NS',
    label: 'GSS.NS,  GSS Infotech Limited',
    name: 'GSS Infotech Limited',
    symbol: 'GSS.NS',
  },
  {
    value: 'GTLINFRA.NS',
    label: 'GTLINFRA.NS,  GTL Infrastructure Limited',
    name: 'GTL Infrastructure Limited',
    symbol: 'GTLINFRA.NS',
  },
  {
    value: 'GTPL.NS',
    label: 'GTPL.NS,  GTPL Hathway Limited',
    name: 'GTPL Hathway Limited',
    symbol: 'GTPL.NS',
  },
  {
    value: 'GUFICBIO.NS',
    label: 'GUFICBIO.NS,  Gufic Biosciences Limited',
    name: 'Gufic Biosciences Limited',
    symbol: 'GUFICBIO.NS',
  },
  {
    value: 'GUJALKALI.NS',
    label: 'GUJALKALI.NS,  Gujarat Alkalies and Chemicals Limited',
    name: 'Gujarat Alkalies and Chemicals Limited',
    symbol: 'GUJALKALI.NS',
  },
  {
    value: 'GUJAPOLLO.NS',
    label: 'GUJAPOLLO.NS,  Gujarat Apollo Industries Limited',
    name: 'Gujarat Apollo Industries Limited',
    symbol: 'GUJAPOLLO.NS',
  },
  {
    value: 'GUJGASLTD.NS',
    label: 'GUJGASLTD.NS,  Gujarat Gas Limited',
    name: 'Gujarat Gas Limited',
    symbol: 'GUJGASLTD.NS',
  },
  {
    value: 'GULFOILLUB.NS',
    label: 'GULFOILLUB.NS,  Gulf Oil Lubricants India Limited',
    name: 'Gulf Oil Lubricants India Limited',
    symbol: 'GULFOILLUB.NS',
  },
  {
    value: 'GULFPETRO.NS',
    label: 'GULFPETRO.NS,  GP Petroleums Limited',
    name: 'GP Petroleums Limited',
    symbol: 'GULFPETRO.NS',
  },
  {
    value: 'GULPOLY.NS',
    label: 'GULPOLY.NS,  Gulshan Polyols Limited',
    name: 'Gulshan Polyols Limited',
    symbol: 'GULPOLY.NS',
  },
  {
    value: 'GVKPIL.NS',
    label: 'GVKPIL.NS,  GVK Power & Infrastructure Limited',
    name: 'GVK Power & Infrastructure Limited',
    symbol: 'GVKPIL.NS',
  },
  {
    value: 'HAL.NS',
    label: 'HAL.NS,  Hindustan Aeronautics Limited',
    name: 'Hindustan Aeronautics Limited',
    symbol: 'HAL.NS',
  },
  {
    value: 'HARRMALAYA.NS',
    label: 'HARRMALAYA.NS,  Harrisons Malayalam Limited',
    name: 'Harrisons Malayalam Limited',
    symbol: 'HARRMALAYA.NS',
  },
  {
    value: 'HATHWAY.NS',
    label: 'HATHWAY.NS,  Hathway Cable and Datacom Limited',
    name: 'Hathway Cable and Datacom Limited',
    symbol: 'HATHWAY.NS',
  },
  {
    value: 'HATSUN.NS',
    label: 'HATSUN.NS,  Hatsun Agro Product Limited',
    name: 'Hatsun Agro Product Limited',
    symbol: 'HATSUN.NS',
  },
  {
    value: 'HBLPOWER.NS',
    label: 'HBLPOWER.NS,  HBL Power Systems Limited',
    name: 'HBL Power Systems Limited',
    symbol: 'HBLPOWER.NS',
  },
  {
    value: 'HBSL.NS',
    label: 'HBSL.NS,  HB Stockholdings Limited',
    name: 'HB Stockholdings Limited',
    symbol: 'HBSL.NS',
  },
  {
    value: 'HCC.NS',
    label: 'HCC.NS,  Hindustan Construction Company Limited',
    name: 'Hindustan Construction Company Limited',
    symbol: 'HCC.NS',
  },
  {
    value: 'HCG.NS',
    label: 'HCG.NS,  HealthCare Global Enterprises Limited',
    name: 'HealthCare Global Enterprises Limited',
    symbol: 'HCG.NS',
  },
  {
    value: 'HEG.NS',
    label: 'HEG.NS,  HEG Limited',
    name: 'HEG Limited',
    symbol: 'HEG.NS',
  },
  {
    value: 'HEIDELBERG.NS',
    label: 'HEIDELBERG.NS,  HeidelbergCement India Limited',
    name: 'HeidelbergCement India Limited',
    symbol: 'HEIDELBERG.NS',
  },
  {
    value: 'HERCULES.NS',
    label: 'HERCULES.NS,  Hercules Hoists Limited',
    name: 'Hercules Hoists Limited',
    symbol: 'HERCULES.NS',
  },
  {
    value: 'HERITGFOOD.NS',
    label: 'HERITGFOOD.NS,  Heritage Foods Limited',
    name: 'Heritage Foods Limited',
    symbol: 'HERITGFOOD.NS',
  },
  {
    value: 'HESTERBIO.NS',
    label: 'HESTERBIO.NS,  Hester Biosciences Limited',
    name: 'Hester Biosciences Limited',
    symbol: 'HESTERBIO.NS',
  },
  {
    value: 'HFCL.NS',
    label: 'HFCL.NS,  HFCL Limited',
    name: 'HFCL Limited',
    symbol: 'HFCL.NS',
  },
  {
    value: 'HGINFRA.NS',
    label: 'HGINFRA.NS,  H.G. Infra Engineering Limited',
    name: 'H.G. Infra Engineering Limited',
    symbol: 'HGINFRA.NS',
  },
  {
    value: 'HGS.NS',
    label: 'HGS.NS,  Hinduja Global Solutions Limited',
    name: 'Hinduja Global Solutions Limited',
    symbol: 'HGS.NS',
  },
  {
    value: 'HIKAL.NS',
    label: 'HIKAL.NS,  Hikal Limited',
    name: 'Hikal Limited',
    symbol: 'HIKAL.NS',
  },
  {
    value: 'HIL.NS',
    label: 'HIL.NS,  HIL Limited',
    name: 'HIL Limited',
    symbol: 'HIL.NS',
  },
  {
    value: 'HILTON.NS',
    label: 'HILTON.NS,  Hilton Metal Forging Limited',
    name: 'Hilton Metal Forging Limited',
    symbol: 'HILTON.NS',
  },
  {
    value: 'HIMATSEIDE.NS',
    label: 'HIMATSEIDE.NS,  Himatsingka Seide Limited',
    name: 'Himatsingka Seide Limited',
    symbol: 'HIMATSEIDE.NS',
  },
  {
    value: 'HINDCOMPOS.NS',
    label: 'HINDCOMPOS.NS,  Hindustan Composites Limited',
    name: 'Hindustan Composites Limited',
    symbol: 'HINDCOMPOS.NS',
  },
  {
    value: 'HINDCOPPER.NS',
    label: 'HINDCOPPER.NS,  Hindustan Copper Limited',
    name: 'Hindustan Copper Limited',
    symbol: 'HINDCOPPER.NS',
  },
  {
    value: 'HINDMOTORS.NS',
    label: 'HINDMOTORS.NS,  Hindustan Motors Limited',
    name: 'Hindustan Motors Limited',
    symbol: 'HINDMOTORS.NS',
  },
  {
    value: 'HINDOILEXP.NS',
    label: 'HINDOILEXP.NS,  Hindustan Oil Exploration Company Limited',
    name: 'Hindustan Oil Exploration Company Limited',
    symbol: 'HINDOILEXP.NS',
  },
  {
    value: 'HIRECT.NS',
    label: 'HIRECT.NS,  Hind Rectifiers Limited',
    name: 'Hind Rectifiers Limited',
    symbol: 'HIRECT.NS',
  },
  {
    value: 'HISARMETAL.NS',
    label: 'HISARMETAL.NS,  Hisar Metal Industries Limited',
    name: 'Hisar Metal Industries Limited',
    symbol: 'HISARMETAL.NS',
  },
  {
    value: 'HITECH.NS',
    label: 'HITECH.NS,  Hi-Tech Pipes Limited',
    name: 'Hi-Tech Pipes Limited',
    symbol: 'HITECH.NS',
  },
  {
    value: 'HITECHCORP.NS',
    label: 'HITECHCORP.NS,  Hitech Corporation Limited',
    name: 'Hitech Corporation Limited',
    symbol: 'HITECHCORP.NS',
  },
  {
    value: 'HITECHGEAR.NS',
    label: 'HITECHGEAR.NS,  The Hi-Tech Gears Limited',
    name: 'The Hi-Tech Gears Limited',
    symbol: 'HITECHGEAR.NS',
  },
  {
    value: 'HLVLTD.NS',
    label: 'HLVLTD.NS,  HLV Limited',
    name: 'HLV Limited',
    symbol: 'HLVLTD.NS',
  },
  {
    value: 'HMVL.NS',
    label: 'HMVL.NS,  Hindustan Media Ventures Limited',
    name: 'Hindustan Media Ventures Limited',
    symbol: 'HMVL.NS',
  },
  {
    value: 'HNDFDS.NS',
    label: 'HNDFDS.NS,  Hindustan Foods Limited',
    name: 'Hindustan Foods Limited',
    symbol: 'HNDFDS.NS',
  },
  {
    value: 'HONAUT.NS',
    label: 'HONAUT.NS,  Honeywell Automation India Limited',
    name: 'Honeywell Automation India Limited',
    symbol: 'HONAUT.NS',
  },
  {
    value: 'HONDAPOWER.NS',
    label: 'HONDAPOWER.NS,  Honda India Power Products Limited',
    name: 'Honda India Power Products Limited',
    symbol: 'HONDAPOWER.NS',
  },
  {
    value: 'HOTELRUGBY.NS',
    label: 'HOTELRUGBY.NS,  Hotel Rugby Limited',
    name: 'Hotel Rugby Limited',
    symbol: 'HOTELRUGBY.NS',
  },
  {
    value: 'HOVS.NS',
    label: 'HOVS.NS,  HOV Services Limited',
    name: 'HOV Services Limited',
    symbol: 'HOVS.NS',
  },
  {
    value: 'HPL.NS',
    label: 'HPL.NS,  HPL Electric & Power Limited',
    name: 'HPL Electric & Power Limited',
    symbol: 'HPL.NS',
  },
  {
    value: 'HSCL.NS',
    label: 'HSCL.NS,  Himadri Speciality Chemical Limited',
    name: 'Himadri Speciality Chemical Limited',
    symbol: 'HSCL.NS',
  },
  {
    value: 'HTMEDIA.NS',
    label: 'HTMEDIA.NS,  HT Media Limited',
    name: 'HT Media Limited',
    symbol: 'HTMEDIA.NS',
  },
  {
    value: 'HUBTOWN.NS',
    label: 'HUBTOWN.NS,  Hubtown Limited',
    name: 'Hubtown Limited',
    symbol: 'HUBTOWN.NS',
  },
  {
    value: 'HUDCO.NS',
    label: 'HUDCO.NS,  Housing and Urban Development Corporation Limited',
    name: 'Housing and Urban Development Corporation Limited',
    symbol: 'HUDCO.NS',
  },
  {
    value: 'IBREALEST.NS',
    label: 'IBREALEST.NS,  Indiabulls Real Estate Limited',
    name: 'Indiabulls Real Estate Limited',
    symbol: 'IBREALEST.NS',
  },
  {
    value: 'IBULHSGFIN.NS',
    label: 'IBULHSGFIN.NS,  Indiabulls Housing Finance Limited',
    name: 'Indiabulls Housing Finance Limited',
    symbol: 'IBULHSGFIN.NS',
  },
  {
    value: 'ICIL.NS',
    label: 'ICIL.NS,  Indo Count Industries Limited',
    name: 'Indo Count Industries Limited',
    symbol: 'ICIL.NS',
  },
  {
    value: 'ICRA.NS',
    label: 'ICRA.NS,  ICRA Limited',
    name: 'ICRA Limited',
    symbol: 'ICRA.NS',
  },
  {
    value: 'IDBI.NS',
    label: 'IDBI.NS,  IDBI Bank Limited',
    name: 'IDBI Bank Limited',
    symbol: 'IDBI.NS',
  },
  {
    value: 'IDEA.NS',
    label: 'IDEA.NS,  Vodafone Idea Limited',
    name: 'Vodafone Idea Limited',
    symbol: 'IDEA.NS',
  },
  {
    value: 'IDFC.NS',
    label: 'IDFC.NS,  IDFC Limited',
    name: 'IDFC Limited',
    symbol: 'IDFC.NS',
  },
  {
    value: 'IDFCFIRSTB.NS',
    label: 'IDFCFIRSTB.NS,  IDFC First Bank Limited',
    name: 'IDFC First Bank Limited',
    symbol: 'IDFCFIRSTB.NS',
  },
  {
    value: 'IEX.NS',
    label: 'IEX.NS,  Indian Energy Exchange Limited',
    name: 'Indian Energy Exchange Limited',
    symbol: 'IEX.NS',
  },
  {
    value: 'IFBAGRO.NS',
    label: 'IFBAGRO.NS,  IFB Agro Industries Limited',
    name: 'IFB Agro Industries Limited',
    symbol: 'IFBAGRO.NS',
  },
  {
    value: 'IFBIND.NS',
    label: 'IFBIND.NS,  IFB Industries Limited',
    name: 'IFB Industries Limited',
    symbol: 'IFBIND.NS',
  },
  {
    value: 'IFCI.NS',
    label: 'IFCI.NS,  IFCI Limited',
    name: 'IFCI Limited',
    symbol: 'IFCI.NS',
  },
  {
    value: 'IFGLEXPOR.NS',
    label: 'IFGLEXPOR.NS,  IFGL Refractories Limited',
    name: 'IFGL Refractories Limited',
    symbol: 'IFGLEXPOR.NS',
  },
  {
    value: 'IGARASHI.NS',
    label: 'IGARASHI.NS,  Igarashi Motors India Limited',
    name: 'Igarashi Motors India Limited',
    symbol: 'IGARASHI.NS',
  },
  {
    value: 'IGPL.NS',
    label: 'IGPL.NS,  I G Petrochemicals Limited',
    name: 'I G Petrochemicals Limited',
    symbol: 'IGPL.NS',
  },
  {
    value: 'IIFL.NS',
    label: 'IIFL.NS,  IIFL Finance Limited',
    name: 'IIFL Finance Limited',
    symbol: 'IIFL.NS',
  },
  {
    value: 'IIFLSEC.NS',
    label: 'IIFLSEC.NS,  IIFL Securities Limited',
    name: 'IIFL Securities Limited',
    symbol: 'IIFLSEC.NS',
  },
  {
    value: 'IIFLWAM.NS',
    label: 'IIFLWAM.NS,  IIFL Wealth Management Limited',
    name: 'IIFL Wealth Management Limited',
    symbol: 'IIFLWAM.NS',
  },
  {
    value: 'IITL.NS',
    label: 'IITL.NS,  Industrial Investment Trust Limited',
    name: 'Industrial Investment Trust Limited',
    symbol: 'IITL.NS',
  },
  {
    value: 'IMAGICAA.NS',
    label: 'IMAGICAA.NS,  Imagicaaworld Entertainment Limited',
    name: 'Imagicaaworld Entertainment Limited',
    symbol: 'IMAGICAA.NS',
  },
  {
    value: 'IMFA.NS',
    label: 'IMFA.NS,  Indian Metals and Ferro Alloys Limited',
    name: 'Indian Metals and Ferro Alloys Limited',
    symbol: 'IMFA.NS',
  },
  {
    value: 'IMPAL.NS',
    label: 'IMPAL.NS,  India Motor Parts & Accessories Limited',
    name: 'India Motor Parts & Accessories Limited',
    symbol: 'IMPAL.NS',
  },
  {
    value: 'INDBANK.NS',
    label: 'INDBANK.NS,  Indbank Merchant Banking Services Limited',
    name: 'Indbank Merchant Banking Services Limited',
    symbol: 'INDBANK.NS',
  },
  {
    value: 'INDHOTEL.NS',
    label: 'INDHOTEL.NS,  The Indian Hotels Company Limited',
    name: 'The Indian Hotels Company Limited',
    symbol: 'INDHOTEL.NS',
  },
  {
    value: 'INDIACEM.NS',
    label: 'INDIACEM.NS,  The India Cements Limited',
    name: 'The India Cements Limited',
    symbol: 'INDIACEM.NS',
  },
  {
    value: 'INDIAGLYCO.NS',
    label: 'INDIAGLYCO.NS,  India Glycols Limited',
    name: 'India Glycols Limited',
    symbol: 'INDIAGLYCO.NS',
  },
  {
    value: 'INDIAMART.NS',
    label: 'INDIAMART.NS,  IndiaMART InterMESH Limited',
    name: 'IndiaMART InterMESH Limited',
    symbol: 'INDIAMART.NS',
  },
  {
    value: 'INDIANB.NS',
    label: 'INDIANB.NS,  Indian Bank',
    name: 'Indian Bank',
    symbol: 'INDIANB.NS',
  },
  {
    value: 'INDIANCARD.NS',
    label: 'INDIANCARD.NS,  The Indian Card Clothing Company Limited',
    name: 'The Indian Card Clothing Company Limited',
    symbol: 'INDIANCARD.NS',
  },
  {
    value: 'INDIANHUME.NS',
    label: 'INDIANHUME.NS,  The Indian Hume Pipe Company Limited',
    name: 'The Indian Hume Pipe Company Limited',
    symbol: 'INDIANHUME.NS',
  },
  {
    value: 'INDNIPPON.NS',
    label: 'INDNIPPON.NS,  India Nippon Electricals Limited',
    name: 'India Nippon Electricals Limited',
    symbol: 'INDNIPPON.NS',
  },
  {
    value: 'INDOCO.NS',
    label: 'INDOCO.NS,  Indoco Remedies Limited',
    name: 'Indoco Remedies Limited',
    symbol: 'INDOCO.NS',
  },
  {
    value: 'INDORAMA.NS',
    label: 'INDORAMA.NS,  Indo Rama Synthetics (India) Limited',
    name: 'Indo Rama Synthetics (India) Limited',
    symbol: 'INDORAMA.NS',
  },
  {
    value: 'INDOSTAR.NS',
    label: 'INDOSTAR.NS,  IndoStar Capital Finance Limited',
    name: 'IndoStar Capital Finance Limited',
    symbol: 'INDOSTAR.NS',
  },
  {
    value: 'INDOTECH.NS',
    label: 'INDOTECH.NS,  Indo Tech Transformers Limited',
    name: 'Indo Tech Transformers Limited',
    symbol: 'INDOTECH.NS',
  },
  {
    value: 'INDRAMEDCO.NS',
    label: 'INDRAMEDCO.NS,  Indraprastha Medical Corporation Limited',
    name: 'Indraprastha Medical Corporation Limited',
    symbol: 'INDRAMEDCO.NS',
  },
  {
    value: 'INDSWFTLAB.NS',
    label: 'INDSWFTLAB.NS,  Ind-Swift Laboratories Limited',
    name: 'Ind-Swift Laboratories Limited',
    symbol: 'INDSWFTLAB.NS',
  },
  {
    value: 'INDTERRAIN.NS',
    label: 'INDTERRAIN.NS,  Indian Terrain Fashions Limited',
    name: 'Indian Terrain Fashions Limited',
    symbol: 'INDTERRAIN.NS',
  },
  {
    value: 'INEOSSTYRO.NS',
    label: 'INEOSSTYRO.NS,  INEOS Styrolution India Limited',
    name: 'INEOS Styrolution India Limited',
    symbol: 'INEOSSTYRO.NS',
  },
  {
    value: 'INFIBEAM.NS',
    label: 'INFIBEAM.NS,  Infibeam Avenues Limited',
    name: 'Infibeam Avenues Limited',
    symbol: 'INFIBEAM.NS',
  },
  {
    value: 'INFOBEAN.NS',
    label: 'INFOBEAN.NS,  InfoBeans Technologies Limited',
    name: 'InfoBeans Technologies Limited',
    symbol: 'INFOBEAN.NS',
  },
  {
    value: 'INGERRAND.NS',
    label: 'INGERRAND.NS,  Ingersoll-Rand (India) Limited',
    name: 'Ingersoll-Rand (India) Limited',
    symbol: 'INGERRAND.NS',
  },
  {
    value: 'INOXLEISUR.NS',
    label: 'INOXLEISUR.NS,  INOX Leisure Limited',
    name: 'INOX Leisure Limited',
    symbol: 'INOXLEISUR.NS',
  },
  {
    value: 'INOXWIND.NS',
    label: 'INOXWIND.NS,  Inox Wind Limited',
    name: 'Inox Wind Limited',
    symbol: 'INOXWIND.NS',
  },
  {
    value: 'INSECTICID.NS',
    label: 'INSECTICID.NS,  Insecticides (India) Limited',
    name: 'Insecticides (India) Limited',
    symbol: 'INSECTICID.NS',
  },
  {
    value: 'INSPIRISYS.NS',
    label: 'INSPIRISYS.NS,  Inspirisys Solutions Limited',
    name: 'Inspirisys Solutions Limited',
    symbol: 'INSPIRISYS.NS',
  },
  {
    value: 'INTELLECT.NS',
    label: 'INTELLECT.NS,  Intellect Design Arena Limited',
    name: 'Intellect Design Arena Limited',
    symbol: 'INTELLECT.NS',
  },
  {
    value: 'INTENTECH.NS',
    label: 'INTENTECH.NS,  Intense Technologies Limited',
    name: 'Intense Technologies Limited',
    symbol: 'INTENTECH.NS',
  },
  {
    value: 'INVENTURE.NS',
    label: 'INVENTURE.NS,  Inventure Growth & Securities Limited',
    name: 'Inventure Growth & Securities Limited',
    symbol: 'INVENTURE.NS',
  },
  {
    value: 'IOB.NS',
    label: 'IOB.NS,  Indian Overseas Bank',
    name: 'Indian Overseas Bank',
    symbol: 'IOB.NS',
  },
  {
    value: 'IOLCP.NS',
    label: 'IOLCP.NS,  IOL Chemicals and Pharmaceuticals Limited',
    name: 'IOL Chemicals and Pharmaceuticals Limited',
    symbol: 'IOLCP.NS',
  },
  {
    value: 'IPCALAB.NS',
    label: 'IPCALAB.NS,  Ipca Laboratories Limited',
    name: 'Ipca Laboratories Limited',
    symbol: 'IPCALAB.NS',
  },
  {
    value: 'IRB.NS',
    label: 'IRB.NS,  IRB Infrastructure Developers Limited',
    name: 'IRB Infrastructure Developers Limited',
    symbol: 'IRB.NS',
  },
  {
    value: 'IRCON.NS',
    label: 'IRCON.NS,  Ircon International Limited',
    name: 'Ircon International Limited',
    symbol: 'IRCON.NS',
  },
  {
    value: 'IRCTC.NS',
    label: 'IRCTC.NS,  Indian Railway Catering & Tourism Corporation Limited',
    name: 'Indian Railway Catering & Tourism Corporation Limited',
    symbol: 'IRCTC.NS',
  },
  {
    value: 'ISEC.NS',
    label: 'ISEC.NS,  ICICI Securities Limited',
    name: 'ICICI Securities Limited',
    symbol: 'ISEC.NS',
  },
  {
    value: 'ITDC.NS',
    label: 'ITDC.NS,  India Tourism Development Corporation Limited',
    name: 'India Tourism Development Corporation Limited',
    symbol: 'ITDC.NS',
  },
  {
    value: 'ITDCEM.NS',
    label: 'ITDCEM.NS,  ITD Cementation India Limited',
    name: 'ITD Cementation India Limited',
    symbol: 'ITDCEM.NS',
  },
  {
    value: 'ITI.NS',
    label: 'ITI.NS,  ITI Limited',
    name: 'ITI Limited',
    symbol: 'ITI.NS',
  },
  {
    value: 'IVP.NS',
    label: 'IVP.NS,  IVP Limited',
    name: 'IVP Limited',
    symbol: 'IVP.NS',
  },
  {
    value: 'IZMO.NS',
    label: 'IZMO.NS,  IZMO Limited',
    name: 'IZMO Limited',
    symbol: 'IZMO.NS',
  },
  {
    value: 'J&KBANK.NS',
    label: 'J&KBANK.NS,  The Jammu and Kashmir Bank Limited',
    name: 'The Jammu and Kashmir Bank Limited',
    symbol: 'J&KBANK.NS',
  },
  {
    value: 'JAGRAN.NS',
    label: 'JAGRAN.NS,  Jagran Prakashan Limited',
    name: 'Jagran Prakashan Limited',
    symbol: 'JAGRAN.NS',
  },
  {
    value: 'JAGSNPHARM.NS',
    label: 'JAGSNPHARM.NS,  Jagsonpal Pharmaceuticals Limited',
    name: 'Jagsonpal Pharmaceuticals Limited',
    symbol: 'JAGSNPHARM.NS',
  },
  {
    value: 'JAIBALAJI.NS',
    label: 'JAIBALAJI.NS,  Jai Balaji Industries Limited',
    name: 'Jai Balaji Industries Limited',
    symbol: 'JAIBALAJI.NS',
  },
  {
    value: 'JAICORPLTD.NS',
    label: 'JAICORPLTD.NS,  Jai Corp Limited',
    name: 'Jai Corp Limited',
    symbol: 'JAICORPLTD.NS',
  },
  {
    value: 'JAMNAAUTO.NS',
    label: 'JAMNAAUTO.NS,  Jamna Auto Industries Limited',
    name: 'Jamna Auto Industries Limited',
    symbol: 'JAMNAAUTO.NS',
  },
  {
    value: 'JASH.NS',
    label: 'JASH.NS,  Jash Engineering Limited',
    name: 'Jash Engineering Limited',
    symbol: 'JASH.NS',
  },
  {
    value: 'JAYAGROGN.NS',
    label: 'JAYAGROGN.NS,  Jayant Agro-Organics Limited',
    name: 'Jayant Agro-Organics Limited',
    symbol: 'JAYAGROGN.NS',
  },
  {
    value: 'JAYBARMARU.NS',
    label: 'JAYBARMARU.NS,  Jay Bharat Maruti Limited',
    name: 'Jay Bharat Maruti Limited',
    symbol: 'JAYBARMARU.NS',
  },
  {
    value: 'JAYSREETEA.NS',
    label: 'JAYSREETEA.NS,  Jay Shree Tea & Industries Limited',
    name: 'Jay Shree Tea & Industries Limited',
    symbol: 'JAYSREETEA.NS',
  },
  {
    value: 'JBCHEPHARM.NS',
    label: 'JBCHEPHARM.NS,  J. B. Chemicals & Pharmaceuticals Limited',
    name: 'J. B. Chemicals & Pharmaceuticals Limited',
    symbol: 'JBCHEPHARM.NS',
  },
  {
    value: 'JBMA.NS',
    label: 'JBMA.NS,  JBM Auto Limited',
    name: 'JBM Auto Limited',
    symbol: 'JBMA.NS',
  },
  {
    value: 'JCHAC.NS',
    label: 'JCHAC.NS,  Johnson Controls-Hitachi Air Conditioning India Limited',
    name: 'Johnson Controls-Hitachi Air Conditioning India Limited',
    symbol: 'JCHAC.NS',
  },
  {
    value: 'JINDALPHOT.NS',
    label: 'JINDALPHOT.NS,  Jindal Photo Limited',
    name: 'Jindal Photo Limited',
    symbol: 'JINDALPHOT.NS',
  },
  {
    value: 'JINDALPOLY.NS',
    label: 'JINDALPOLY.NS,  Jindal Poly Films Limited',
    name: 'Jindal Poly Films Limited',
    symbol: 'JINDALPOLY.NS',
  },
  {
    value: 'JINDALSAW.NS',
    label: 'JINDALSAW.NS,  Jindal Saw Limited',
    name: 'Jindal Saw Limited',
    symbol: 'JINDALSAW.NS',
  },
  {
    value: 'JINDALSTEL.NS',
    label: 'JINDALSTEL.NS,  Jindal Steel & Power Limited',
    name: 'Jindal Steel & Power Limited',
    symbol: 'JINDALSTEL.NS',
  },
  {
    value: 'JINDWORLD.NS',
    label: 'JINDWORLD.NS,  Jindal Worldwide Limited',
    name: 'Jindal Worldwide Limited',
    symbol: 'JINDWORLD.NS',
  },
  {
    value: 'JKCEMENT.NS',
    label: 'JKCEMENT.NS,  J.K. Cement Limited',
    name: 'J.K. Cement Limited',
    symbol: 'JKCEMENT.NS',
  },
  {
    value: 'JKIL.NS',
    label: 'JKIL.NS,  J. Kumar Infraprojects Limited',
    name: 'J. Kumar Infraprojects Limited',
    symbol: 'JKIL.NS',
  },
  {
    value: 'JKLAKSHMI.NS',
    label: 'JKLAKSHMI.NS,  JK Lakshmi Cement Limited',
    name: 'JK Lakshmi Cement Limited',
    symbol: 'JKLAKSHMI.NS',
  },
  {
    value: 'JKPAPER.NS',
    label: 'JKPAPER.NS,  JK Paper Limited',
    name: 'JK Paper Limited',
    symbol: 'JKPAPER.NS',
  },
  {
    value: 'JKTYRE.NS',
    label: 'JKTYRE.NS,  JK Tyre & Industries Limited',
    name: 'JK Tyre & Industries Limited',
    symbol: 'JKTYRE.NS',
  },
  {
    value: 'JMA.NS',
    label: 'JMA.NS,  Jullundur Motor Agency (Delhi) Limited',
    name: 'Jullundur Motor Agency (Delhi) Limited',
    symbol: 'JMA.NS',
  },
  {
    value: 'JMCPROJECT.NS',
    label: 'JMCPROJECT.NS,  JMC Projects (India) Limited',
    name: 'JMC Projects (India) Limited',
    symbol: 'JMCPROJECT.NS',
  },
  {
    value: 'JMFINANCIL.NS',
    label: 'JMFINANCIL.NS,  JM Financial Limited',
    name: 'JM Financial Limited',
    symbol: 'JMFINANCIL.NS',
  },
  {
    value: 'JOCIL.NS',
    label: 'JOCIL.NS,  Jocil Limited',
    name: 'Jocil Limited',
    symbol: 'JOCIL.NS',
  },
  {
    value: 'JPASSOCIAT.NS',
    label: 'JPASSOCIAT.NS,  Jaiprakash Associates Limited',
    name: 'Jaiprakash Associates Limited',
    symbol: 'JPASSOCIAT.NS',
  },
  {
    value: 'JPPOWER.NS',
    label: 'JPPOWER.NS,  Jaiprakash Power Ventures Limited',
    name: 'Jaiprakash Power Ventures Limited',
    symbol: 'JPPOWER.NS',
  },
  {
    value: 'JSL.NS',
    label: 'JSL.NS,  Jindal Stainless Limited',
    name: 'Jindal Stainless Limited',
    symbol: 'JSL.NS',
  },
  {
    value: 'JSLHISAR.NS',
    label: 'JSLHISAR.NS,  Jindal Stainless (Hisar) Limited',
    name: 'Jindal Stainless (Hisar) Limited',
    symbol: 'JSLHISAR.NS',
  },
  {
    value: 'JSWENERGY.NS',
    label: 'JSWENERGY.NS,  JSW Energy Limited',
    name: 'JSW Energy Limited',
    symbol: 'JSWENERGY.NS',
  },
  {
    value: 'JSWHL.NS',
    label: 'JSWHL.NS,  JSW Holdings Limited',
    name: 'JSW Holdings Limited',
    symbol: 'JSWHL.NS',
  },
  {
    value: 'JTEKTINDIA.NS',
    label: 'JTEKTINDIA.NS,  JTEKT India Limited',
    name: 'JTEKT India Limited',
    symbol: 'JTEKTINDIA.NS',
  },
  {
    value: 'JUBLFOOD.NS',
    label: 'JUBLFOOD.NS,  Jubilant FoodWorks Limited',
    name: 'Jubilant FoodWorks Limited',
    symbol: 'JUBLFOOD.NS',
  },
  {
    value: 'JUBLINDS.NS',
    label: 'JUBLINDS.NS,  Jubilant Industries Limited',
    name: 'Jubilant Industries Limited',
    symbol: 'JUBLINDS.NS',
  },
  {
    value: 'JUSTDIAL.NS',
    label: 'JUSTDIAL.NS,  Just Dial Limited',
    name: 'Just Dial Limited',
    symbol: 'JUSTDIAL.NS',
  },
  {
    value: 'JYOTHYLAB.NS',
    label: 'JYOTHYLAB.NS,  Jyothy Labs Limited',
    name: 'Jyothy Labs Limited',
    symbol: 'JYOTHYLAB.NS',
  },
  {
    value: 'KABRAEXTRU.NS',
    label: 'KABRAEXTRU.NS,  Kabra Extrusiontechnik Limited',
    name: 'Kabra Extrusiontechnik Limited',
    symbol: 'KABRAEXTRU.NS',
  },
  {
    value: 'KAJARIACER.NS',
    label: 'KAJARIACER.NS,  Kajaria Ceramics Limited',
    name: 'Kajaria Ceramics Limited',
    symbol: 'KAJARIACER.NS',
  },
  {
    value: 'KAKATCEM.NS',
    label: 'KAKATCEM.NS,  Kakatiya Cement Sugar and Industries Limited',
    name: 'Kakatiya Cement Sugar and Industries Limited',
    symbol: 'KAKATCEM.NS',
  },
  {
    value: 'KALPATPOWR.NS',
    label: 'KALPATPOWR.NS,  Kalpataru Power Transmission Limited',
    name: 'Kalpataru Power Transmission Limited',
    symbol: 'KALPATPOWR.NS',
  },
  {
    value: 'KAMATHOTEL.NS',
    label: 'KAMATHOTEL.NS,  Kamat Hotels (India) Limited',
    name: 'Kamat Hotels (India) Limited',
    symbol: 'KAMATHOTEL.NS',
  },
  {
    value: 'KANORICHEM.NS',
    label: 'KANORICHEM.NS,  Kanoria Chemicals & Industries Limited',
    name: 'Kanoria Chemicals & Industries Limited',
    symbol: 'KANORICHEM.NS',
  },
  {
    value: 'KANSAINER.NS',
    label: 'KANSAINER.NS,  Kansai Nerolac Paints Limited',
    name: 'Kansai Nerolac Paints Limited',
    symbol: 'KANSAINER.NS',
  },
  {
    value: 'KAPSTON.NS',
    label: 'KAPSTON.NS,  Kapston Services Limited',
    name: 'Kapston Services Limited',
    symbol: 'KAPSTON.NS',
  },
  {
    value: 'KARMAENG.NS',
    label: 'KARMAENG.NS,  Karma Energy Limited',
    name: 'Karma Energy Limited',
    symbol: 'KARMAENG.NS',
  },
  {
    value: 'KARURVYSYA.NS',
    label: 'KARURVYSYA.NS,  The Karur Vysya Bank Limited',
    name: 'The Karur Vysya Bank Limited',
    symbol: 'KARURVYSYA.NS',
  },
  {
    value: 'KCP.NS',
    label: 'KCP.NS,  The KCP Limited',
    name: 'The KCP Limited',
    symbol: 'KCP.NS',
  },
  {
    value: 'KCPSUGIND.NS',
    label: 'KCPSUGIND.NS,  K.C.P. Sugar and Industries Corporation Limited',
    name: 'K.C.P. Sugar and Industries Corporation Limited',
    symbol: 'KCPSUGIND.NS',
  },
  {
    value: 'KEC.NS',
    label: 'KEC.NS,  KEC International Limited',
    name: 'KEC International Limited',
    symbol: 'KEC.NS',
  },
  {
    value: 'KECL.NS',
    label: 'KECL.NS,  Kirloskar Electric Company Limited',
    name: 'Kirloskar Electric Company Limited',
    symbol: 'KECL.NS',
  },
  {
    value: 'KEI.NS',
    label: 'KEI.NS,  KEI Industries Limited',
    name: 'KEI Industries Limited',
    symbol: 'KEI.NS',
  },
  {
    value: 'KENNAMET.NS',
    label: 'KENNAMET.NS,  Kennametal India Limited',
    name: 'Kennametal India Limited',
    symbol: 'KENNAMET.NS',
  },
  {
    value: 'KESORAMIND.NS',
    label: 'KESORAMIND.NS,  Kesoram Industries Limited',
    name: 'Kesoram Industries Limited',
    symbol: 'KESORAMIND.NS',
  },
  {
    value: 'KEYFINSERV.NS',
    label: 'KEYFINSERV.NS,  Keynote Financial Services Limited',
    name: 'Keynote Financial Services Limited',
    symbol: 'KEYFINSERV.NS',
  },
  {
    value: 'KHANDSE.NS',
    label: 'KHANDSE.NS,  Khandwala Securities Limited',
    name: 'Khandwala Securities Limited',
    symbol: 'KHANDSE.NS',
  },
  {
    value: 'KICL.NS',
    label: 'KICL.NS,  Kalyani Investment Company Limited',
    name: 'Kalyani Investment Company Limited',
    symbol: 'KICL.NS',
  },
  {
    value: 'KINGFA.NS',
    label: 'KINGFA.NS,  Kingfa Science & Technology (India) Limited',
    name: 'Kingfa Science & Technology (India) Limited',
    symbol: 'KINGFA.NS',
  },
  {
    value: 'KIOCL.NS',
    label: 'KIOCL.NS,  KIOCL Limited',
    name: 'KIOCL Limited',
    symbol: 'KIOCL.NS',
  },
  {
    value: 'KIRIINDUS.NS',
    label: 'KIRIINDUS.NS,  Kiri Industries Limited',
    name: 'Kiri Industries Limited',
    symbol: 'KIRIINDUS.NS',
  },
  {
    value: 'KIRLFER.NS',
    label: 'KIRLFER.NS,  Kirloskar Ferrous Industries Limited',
    name: 'Kirloskar Ferrous Industries Limited',
    symbol: 'KIRLFER.NS',
  },
  {
    value: 'KIRLOSBROS.NS',
    label: 'KIRLOSBROS.NS,  Kirloskar Brothers Limited',
    name: 'Kirloskar Brothers Limited',
    symbol: 'KIRLOSBROS.NS',
  },
  {
    value: 'KIRLOSENG.NS',
    label: 'KIRLOSENG.NS,  Kirloskar Oil Engines Limited',
    name: 'Kirloskar Oil Engines Limited',
    symbol: 'KIRLOSENG.NS',
  },
  {
    value: 'KIRLOSIND.NS',
    label: 'KIRLOSIND.NS,  Kirloskar Industries Limited',
    name: 'Kirloskar Industries Limited',
    symbol: 'KIRLOSIND.NS',
  },
  {
    value: 'KITEX.NS',
    label: 'KITEX.NS,  Kitex Garments Limited',
    name: 'Kitex Garments Limited',
    symbol: 'KITEX.NS',
  },
  {
    value: 'KKCL.NS',
    label: 'KKCL.NS,  Kewal Kiran Clothing Limited',
    name: 'Kewal Kiran Clothing Limited',
    symbol: 'KKCL.NS',
  },
  {
    value: 'KMSUGAR.NS',
    label: 'KMSUGAR.NS,  K.M. Sugar Mills Limited',
    name: 'K.M. Sugar Mills Limited',
    symbol: 'KMSUGAR.NS',
  },
  {
    value: 'KNRCON.NS',
    label: 'KNRCON.NS,  KNR Constructions Limited',
    name: 'KNR Constructions Limited',
    symbol: 'KNRCON.NS',
  },
  {
    value: 'KOKUYOCMLN.NS',
    label: 'KOKUYOCMLN.NS,  Kokuyo Camlin Limited',
    name: 'Kokuyo Camlin Limited',
    symbol: 'KOKUYOCMLN.NS',
  },
  {
    value: 'KOLTEPATIL.NS',
    label: 'KOLTEPATIL.NS,  Kolte-Patil Developers Limited',
    name: 'Kolte-Patil Developers Limited',
    symbol: 'KOLTEPATIL.NS',
  },
  {
    value: 'KOPRAN.NS',
    label: 'KOPRAN.NS,  Kopran Limited',
    name: 'Kopran Limited',
    symbol: 'KOPRAN.NS',
  },
  {
    value: 'KOTARISUG.NS',
    label: 'KOTARISUG.NS,  Kothari Sugars and Chemicals Limited',
    name: 'Kothari Sugars and Chemicals Limited',
    symbol: 'KOTARISUG.NS',
  },
  {
    value: 'KOTHARIPET.NS',
    label: 'KOTHARIPET.NS,  Kothari Petrochemicals Limited',
    name: 'Kothari Petrochemicals Limited',
    symbol: 'KOTHARIPET.NS',
  },
  {
    value: 'KOTHARIPRO.NS',
    label: 'KOTHARIPRO.NS,  Kothari Products Limited',
    name: 'Kothari Products Limited',
    symbol: 'KOTHARIPRO.NS',
  },
  {
    value: 'KPITTECH.NS',
    label: 'KPITTECH.NS,  KPIT Technologies Limited',
    name: 'KPIT Technologies Limited',
    symbol: 'KPITTECH.NS',
  },
  {
    value: 'KPRMILL.NS',
    label: 'KPRMILL.NS,  K.P.R. Mill Limited',
    name: 'K.P.R. Mill Limited',
    symbol: 'KPRMILL.NS',
  },
  {
    value: 'KRBL.NS',
    label: 'KRBL.NS,  KRBL Limited',
    name: 'KRBL Limited',
    symbol: 'KRBL.NS',
  },
  {
    value: 'KREBSBIO.NS',
    label: 'KREBSBIO.NS,  Krebs Biochemicals & Industries Limited',
    name: 'Krebs Biochemicals & Industries Limited',
    symbol: 'KREBSBIO.NS',
  },
  {
    value: 'KSB.NS',
    label: 'KSB.NS,  KSB Limited',
    name: 'KSB Limited',
    symbol: 'KSB.NS',
  },
  {
    value: 'KSCL.NS',
    label: 'KSCL.NS,  Kaveri Seed Company Limited',
    name: 'Kaveri Seed Company Limited',
    symbol: 'KSCL.NS',
  },
  {
    value: 'KSL.NS',
    label: 'KSL.NS,  Kalyani Steels Limited',
    name: 'Kalyani Steels Limited',
    symbol: 'KSL.NS',
  },
  {
    value: 'KTKBANK.NS',
    label: 'KTKBANK.NS,  The Karnataka Bank Limited',
    name: 'The Karnataka Bank Limited',
    symbol: 'KTKBANK.NS',
  },
  {
    value: 'KUANTUM.NS',
    label: 'KUANTUM.NS,  Kuantum Papers Limited',
    name: 'Kuantum Papers Limited',
    symbol: 'KUANTUM.NS',
  },
  {
    value: 'L&TFH.NS',
    label: 'L&TFH.NS,  L&T Finance Holdings Limited',
    name: 'L&T Finance Holdings Limited',
    symbol: 'L&TFH.NS',
  },
  {
    value: 'LALPATHLAB.NS',
    label: 'LALPATHLAB.NS,  Dr. Lal PathLabs Limited',
    name: 'Dr. Lal PathLabs Limited',
    symbol: 'LALPATHLAB.NS',
  },
  {
    value: 'LAMBODHARA.NS',
    label: 'LAMBODHARA.NS,  Lambodhara Textiles Limited',
    name: 'Lambodhara Textiles Limited',
    symbol: 'LAMBODHARA.NS',
  },
  {
    value: 'LAOPALA.NS',
    label: 'LAOPALA.NS,  La Opala RG Limited',
    name: 'La Opala RG Limited',
    symbol: 'LAOPALA.NS',
  },
  {
    value: 'LASA.NS',
    label: 'LASA.NS,  Lasa Supergenerics Limited',
    name: 'Lasa Supergenerics Limited',
    symbol: 'LASA.NS',
  },
  {
    value: 'LAURUSLABS.NS',
    label: 'LAURUSLABS.NS,  Laurus Labs Limited',
    name: 'Laurus Labs Limited',
    symbol: 'LAURUSLABS.NS',
  },
  {
    value: 'LAXMIMACH.NS',
    label: 'LAXMIMACH.NS,  Lakshmi Machine Works Limited',
    name: 'Lakshmi Machine Works Limited',
    symbol: 'LAXMIMACH.NS',
  },
  {
    value: 'LEMONTREE.NS',
    label: 'LEMONTREE.NS,  Lemon Tree Hotels Limited',
    name: 'Lemon Tree Hotels Limited',
    symbol: 'LEMONTREE.NS',
  },
  {
    value: 'LFIC.NS',
    label: 'LFIC.NS,  Lakshmi Finance & Industrial Corporation Limited',
    name: 'Lakshmi Finance & Industrial Corporation Limited',
    symbol: 'LFIC.NS',
  },
  {
    value: 'LGBBROSLTD.NS',
    label: 'LGBBROSLTD.NS,  L.G. Balakrishnan & Bros Limited',
    name: 'L.G. Balakrishnan & Bros Limited',
    symbol: 'LGBBROSLTD.NS',
  },
  {
    value: 'LGBFORGE.NS',
    label: 'LGBFORGE.NS,  LGB Forge Limited',
    name: 'LGB Forge Limited',
    symbol: 'LGBFORGE.NS',
  },
  {
    value: 'LIBAS.NS',
    label: 'LIBAS.NS,  Libas Consumer Products Limited',
    name: 'Libas Consumer Products Limited',
    symbol: 'LIBAS.NS',
  },
  {
    value: 'LIBERTSHOE.NS',
    label: 'LIBERTSHOE.NS,  Liberty Shoes Ltd.',
    name: 'Liberty Shoes Ltd.',
    symbol: 'LIBERTSHOE.NS',
  },
  {
    value: 'LICHSGFIN.NS',
    label: 'LICHSGFIN.NS,  LIC Housing Finance Limited',
    name: 'LIC Housing Finance Limited',
    symbol: 'LICHSGFIN.NS',
  },
  {
    value: 'LINCOLN.NS',
    label: 'LINCOLN.NS,  Lincoln Pharmaceuticals Limited',
    name: 'Lincoln Pharmaceuticals Limited',
    symbol: 'LINCOLN.NS',
  },
  {
    value: 'LINDEINDIA.NS',
    label: 'LINDEINDIA.NS,  Linde India Limited',
    name: 'Linde India Limited',
    symbol: 'LINDEINDIA.NS',
  },
  {
    value: 'LOKESHMACH.NS',
    label: 'LOKESHMACH.NS,  Lokesh Machines Limited',
    name: 'Lokesh Machines Limited',
    symbol: 'LOKESHMACH.NS',
  },
  {
    value: 'LOTUSEYE.NS',
    label: 'LOTUSEYE.NS,  Lotus Eye Hospital and Institute Limited',
    name: 'Lotus Eye Hospital and Institute Limited',
    symbol: 'LOTUSEYE.NS',
  },
  {
    value: 'LOVABLE.NS',
    label: 'LOVABLE.NS,  Lovable Lingerie Limited',
    name: 'Lovable Lingerie Limited',
    symbol: 'LOVABLE.NS',
  },
  {
    value: 'LSIL.NS',
    label: 'LSIL.NS,  Lloyds Steels Industries Limited',
    name: 'Lloyds Steels Industries Limited',
    symbol: 'LSIL.NS',
  },
  {
    value: 'LTI.NS',
    label: 'LTI.NS,  Larsen & Toubro Infotech Limited',
    name: 'Larsen & Toubro Infotech Limited',
    symbol: 'LTI.NS',
  },
  {
    value: 'LTTS.NS',
    label: 'LTTS.NS,  L&T Technology Services Limited',
    name: 'L&T Technology Services Limited',
    symbol: 'LTTS.NS',
  },
  {
    value: 'LUMAXIND.NS',
    label: 'LUMAXIND.NS,  Lumax Industries Limited',
    name: 'Lumax Industries Limited',
    symbol: 'LUMAXIND.NS',
  },
  {
    value: 'LUMAXTECH.NS',
    label: 'LUMAXTECH.NS,  Lumax Auto Technologies Limited',
    name: 'Lumax Auto Technologies Limited',
    symbol: 'LUMAXTECH.NS',
  },
  {
    value: 'LUXIND.NS',
    label: 'LUXIND.NS,  Lux Industries Limited',
    name: 'Lux Industries Limited',
    symbol: 'LUXIND.NS',
  },
  {
    value: 'LYKALABS.NS',
    label: 'LYKALABS.NS,  Lyka Labs Limited',
    name: 'Lyka Labs Limited',
    symbol: 'LYKALABS.NS',
  },
  {
    value: 'LYPSAGEMS.NS',
    label: 'LYPSAGEMS.NS,  Lypsa Gems & Jewellery Limited',
    name: 'Lypsa Gems & Jewellery Limited',
    symbol: 'LYPSAGEMS.NS',
  },
  {
    value: 'M&MFIN.NS',
    label: 'M&MFIN.NS,  Mahindra & Mahindra Financial Services Limited',
    name: 'Mahindra & Mahindra Financial Services Limited',
    symbol: 'M&MFIN.NS',
  },
  {
    value: 'MAANALU.NS',
    label: 'MAANALU.NS,  Maan Aluminium Limited',
    name: 'Maan Aluminium Limited',
    symbol: 'MAANALU.NS',
  },
  {
    value: 'MADHAV.NS',
    label: 'MADHAV.NS,  Madhav Marbles and Granites Limited',
    name: 'Madhav Marbles and Granites Limited',
    symbol: 'MADHAV.NS',
  },
  {
    value: 'MADRASFERT.NS',
    label: 'MADRASFERT.NS,  Madras Fertilizers Limited',
    name: 'Madras Fertilizers Limited',
    symbol: 'MADRASFERT.NS',
  },
  {
    value: 'MAGADSUGAR.NS',
    label: 'MAGADSUGAR.NS,  Magadh Sugar & Energy Limited',
    name: 'Magadh Sugar & Energy Limited',
    symbol: 'MAGADSUGAR.NS',
  },
  {
    value: 'MAGNUM.NS',
    label: 'MAGNUM.NS,  Magnum Ventures Limited',
    name: 'Magnum Ventures Limited',
    symbol: 'MAGNUM.NS',
  },
  {
    value: 'MAHABANK.NS',
    label: 'MAHABANK.NS,  Bank of Maharashtra',
    name: 'Bank of Maharashtra',
    symbol: 'MAHABANK.NS',
  },
  {
    value: 'MAHASTEEL.NS',
    label: 'MAHASTEEL.NS,  Mahamaya Steel Industries Limited',
    name: 'Mahamaya Steel Industries Limited',
    symbol: 'MAHASTEEL.NS',
  },
  {
    value: 'MAHESHWARI.NS',
    label: 'MAHESHWARI.NS,  Maheshwari Logistics Limited',
    name: 'Maheshwari Logistics Limited',
    symbol: 'MAHESHWARI.NS',
  },
  {
    value: 'MAHINDCIE.NS',
    label: 'MAHINDCIE.NS,  Mahindra CIE Automotive Limited',
    name: 'Mahindra CIE Automotive Limited',
    symbol: 'MAHINDCIE.NS',
  },
  {
    value: 'MAHLIFE.NS',
    label: 'MAHLIFE.NS,  Mahindra Lifespace Developers Limited',
    name: 'Mahindra Lifespace Developers Limited',
    symbol: 'MAHLIFE.NS',
  },
  {
    value: 'MAHLOG.NS',
    label: 'MAHLOG.NS,  Mahindra Logistics Limited',
    name: 'Mahindra Logistics Limited',
    symbol: 'MAHLOG.NS',
  },
  {
    value: 'MAHSCOOTER.NS',
    label: 'MAHSCOOTER.NS,  Maharashtra Scooters Ltd.',
    name: 'Maharashtra Scooters Ltd.',
    symbol: 'MAHSCOOTER.NS',
  },
  {
    value: 'MAHSEAMLES.NS',
    label: 'MAHSEAMLES.NS,  Maharashtra Seamless Limited',
    name: 'Maharashtra Seamless Limited',
    symbol: 'MAHSEAMLES.NS',
  },
  {
    value: 'MAITHANALL.NS',
    label: 'MAITHANALL.NS,  Maithan Alloys Limited',
    name: 'Maithan Alloys Limited',
    symbol: 'MAITHANALL.NS',
  },
  {
    value: 'MALUPAPER.NS',
    label: 'MALUPAPER.NS,  Malu Paper Mills Limited',
    name: 'Malu Paper Mills Limited',
    symbol: 'MALUPAPER.NS',
  },
  {
    value: 'MANAKALUCO.NS',
    label: 'MANAKALUCO.NS,  Manaksia Aluminium Company Limited',
    name: 'Manaksia Aluminium Company Limited',
    symbol: 'MANAKALUCO.NS',
  },
  {
    value: 'MANAKCOAT.NS',
    label: 'MANAKCOAT.NS,  Manaksia Coated Metals & Industries Limited',
    name: 'Manaksia Coated Metals & Industries Limited',
    symbol: 'MANAKCOAT.NS',
  },
  {
    value: 'MANAKSIA.NS',
    label: 'MANAKSIA.NS,  Manaksia Limited',
    name: 'Manaksia Limited',
    symbol: 'MANAKSIA.NS',
  },
  {
    value: 'MANAKSTEEL.NS',
    label: 'MANAKSTEEL.NS,  Manaksia Steels Limited',
    name: 'Manaksia Steels Limited',
    symbol: 'MANAKSTEEL.NS',
  },
  {
    value: 'MANALIPETC.NS',
    label: 'MANALIPETC.NS,  Manali Petrochemicals Limited',
    name: 'Manali Petrochemicals Limited',
    symbol: 'MANALIPETC.NS',
  },
  {
    value: 'MANAPPURAM.NS',
    label: 'MANAPPURAM.NS,  Manappuram Finance Limited',
    name: 'Manappuram Finance Limited',
    symbol: 'MANAPPURAM.NS',
  },
  {
    value: 'MANGCHEFER.NS',
    label: 'MANGCHEFER.NS,  Mangalore Chemicals & Fertilizers Limited',
    name: 'Mangalore Chemicals & Fertilizers Limited',
    symbol: 'MANGCHEFER.NS',
  },
  {
    value: 'MANGLMCEM.NS',
    label: 'MANGLMCEM.NS,  Mangalam Cement Limited',
    name: 'Mangalam Cement Limited',
    symbol: 'MANGLMCEM.NS',
  },
  {
    value: 'MANINDS.NS',
    label: 'MANINDS.NS,  Man Industries (India) Limited',
    name: 'Man Industries (India) Limited',
    symbol: 'MANINDS.NS',
  },
  {
    value: 'MANINFRA.NS',
    label: 'MANINFRA.NS,  Man Infraconstruction Limited',
    name: 'Man Infraconstruction Limited',
    symbol: 'MANINFRA.NS',
  },
  {
    value: 'MARALOVER.NS',
    label: 'MARALOVER.NS,  Maral Overseas Limited',
    name: 'Maral Overseas Limited',
    symbol: 'MARALOVER.NS',
  },
  {
    value: 'MARATHON.NS',
    label: 'MARATHON.NS,  Marathon Nextgen Realty Limited',
    name: 'Marathon Nextgen Realty Limited',
    symbol: 'MARATHON.NS',
  },
  {
    value: 'MARKSANS.NS',
    label: 'MARKSANS.NS,  Marksans Pharma Limited',
    name: 'Marksans Pharma Limited',
    symbol: 'MARKSANS.NS',
  },
  {
    value: 'MASFIN.NS',
    label: 'MASFIN.NS,  MAS Financial Services Limited',
    name: 'MAS Financial Services Limited',
    symbol: 'MASFIN.NS',
  },
  {
    value: 'MASTEK.NS',
    label: 'MASTEK.NS,  Mastek Limited',
    name: 'Mastek Limited',
    symbol: 'MASTEK.NS',
  },
  {
    value: 'MATRIMONY.NS',
    label: 'MATRIMONY.NS,  Matrimony.com Limited',
    name: 'Matrimony.com Limited',
    symbol: 'MATRIMONY.NS',
  },
  {
    value: 'MAXVIL.NS',
    label: 'MAXVIL.NS,  Max Ventures and Industries Limited',
    name: 'Max Ventures and Industries Limited',
    symbol: 'MAXVIL.NS',
  },
  {
    value: 'MAYURUNIQ.NS',
    label: 'MAYURUNIQ.NS,  Mayur Uniquoters Limited',
    name: 'Mayur Uniquoters Limited',
    symbol: 'MAYURUNIQ.NS',
  },
  {
    value: 'MAZDA.NS',
    label: 'MAZDA.NS,  Mazda Limited',
    name: 'Mazda Limited',
    symbol: 'MAZDA.NS',
  },
  {
    value: 'MCX.NS',
    label: 'MCX.NS,  Multi Commodity Exchange of India Limited',
    name: 'Multi Commodity Exchange of India Limited',
    symbol: 'MCX.NS',
  },
  {
    value: 'MEGASOFT.NS',
    label: 'MEGASOFT.NS,  Megasoft Limited',
    name: 'Megasoft Limited',
    symbol: 'MEGASOFT.NS',
  },
  {
    value: 'MENONBE.NS',
    label: 'MENONBE.NS,  Menon Bearings Limited',
    name: 'Menon Bearings Limited',
    symbol: 'MENONBE.NS',
  },
  {
    value: 'METROPOLIS.NS',
    label: 'METROPOLIS.NS,  Metropolis Healthcare Limited',
    name: 'Metropolis Healthcare Limited',
    symbol: 'METROPOLIS.NS',
  },
  {
    value: 'MFSL.NS',
    label: 'MFSL.NS,  Max Financial Services Limited',
    name: 'Max Financial Services Limited',
    symbol: 'MFSL.NS',
  },
  {
    value: 'MGL.NS',
    label: 'MGL.NS,  Mahanagar Gas Limited',
    name: 'Mahanagar Gas Limited',
    symbol: 'MGL.NS',
  },
  {
    value: 'MHRIL.NS',
    label: 'MHRIL.NS,  Mahindra Holidays & Resorts India Limited',
    name: 'Mahindra Holidays & Resorts India Limited',
    symbol: 'MHRIL.NS',
  },
  {
    value: 'MIDHANI.NS',
    label: 'MIDHANI.NS,  Mishra Dhatu Nigam Limited',
    name: 'Mishra Dhatu Nigam Limited',
    symbol: 'MIDHANI.NS',
  },
  {
    value: 'MINDACORP.NS',
    label: 'MINDACORP.NS,  Minda Corporation Limited',
    name: 'Minda Corporation Limited',
    symbol: 'MINDACORP.NS',
  },
  {
    value: 'MINDTECK.NS',
    label: 'MINDTECK.NS,  Mindteck (India) Limited',
    name: 'Mindteck (India) Limited',
    symbol: 'MINDTECK.NS',
  },
  {
    value: 'MINDTREE.NS',
    label: 'MINDTREE.NS,  Mindtree Limited',
    name: 'Mindtree Limited',
    symbol: 'MINDTREE.NS',
  },
  {
    value: 'MIRZAINT.NS',
    label: 'MIRZAINT.NS,  Mirza International Limited',
    name: 'Mirza International Limited',
    symbol: 'MIRZAINT.NS',
  },
  {
    value: 'MITTAL.NS',
    label: 'MITTAL.NS,  Mittal Life Style Limited',
    name: 'Mittal Life Style Limited',
    symbol: 'MITTAL.NS',
  },
  {
    value: 'MMFL.NS',
    label: 'MMFL.NS,  M M Forgings Limited',
    name: 'M M Forgings Limited',
    symbol: 'MMFL.NS',
  },
  {
    value: 'MMP.NS',
    label: 'MMP.NS,  MMP Industries Limited',
    name: 'MMP Industries Limited',
    symbol: 'MMP.NS',
  },
  {
    value: 'MMTC.NS',
    label: 'MMTC.NS,  MMTC Limited',
    name: 'MMTC Limited',
    symbol: 'MMTC.NS',
  },
  {
    value: 'MOHITIND.NS',
    label: 'MOHITIND.NS,  Mohit Industries Limited',
    name: 'Mohit Industries Limited',
    symbol: 'MOHITIND.NS',
  },
  {
    value: 'MOIL.NS',
    label: 'MOIL.NS,  MOIL Limited',
    name: 'MOIL Limited',
    symbol: 'MOIL.NS',
  },
  {
    value: 'MOLDTECH.NS',
    label: 'MOLDTECH.NS,  Mold-Tek Technologies Limited',
    name: 'Mold-Tek Technologies Limited',
    symbol: 'MOLDTECH.NS',
  },
  {
    value: 'MOLDTKPAC.NS',
    label: 'MOLDTKPAC.NS,  Mold-Tek Packaging Limited',
    name: 'Mold-Tek Packaging Limited',
    symbol: 'MOLDTKPAC.NS',
  },
  {
    value: 'MONTECARLO.NS',
    label: 'MONTECARLO.NS,  Monte Carlo Fashions Limited',
    name: 'Monte Carlo Fashions Limited',
    symbol: 'MONTECARLO.NS',
  },
  {
    value: 'MORARJEE.NS',
    label: 'MORARJEE.NS,  Morarjee Textiles Limited',
    name: 'Morarjee Textiles Limited',
    symbol: 'MORARJEE.NS',
  },
  {
    value: 'MOREPENLAB.NS',
    label: 'MOREPENLAB.NS,  Morepen Laboratories Limited',
    name: 'Morepen Laboratories Limited',
    symbol: 'MOREPENLAB.NS',
  },
  {
    value: 'MOTILALOFS.NS',
    label: 'MOTILALOFS.NS,  Motilal Oswal Financial Services Limited',
    name: 'Motilal Oswal Financial Services Limited',
    symbol: 'MOTILALOFS.NS',
  },
  {
    value: 'MPHASIS.NS',
    label: 'MPHASIS.NS,  Mphasis Limited',
    name: 'Mphasis Limited',
    symbol: 'MPHASIS.NS',
  },
  {
    value: 'MPSLTD.NS',
    label: 'MPSLTD.NS,  MPS Limited',
    name: 'MPS Limited',
    symbol: 'MPSLTD.NS',
  },
  {
    value: 'MRF.NS',
    label: 'MRF.NS,  MRF Limited',
    name: 'MRF Limited',
    symbol: 'MRF.NS',
  },
  {
    value: 'MRO-TEK.NS',
    label: 'MRO-TEK.NS,  MRO-TEK Realty Limited',
    name: 'MRO-TEK Realty Limited',
    symbol: 'MRO-TEK.NS',
  },
  {
    value: 'MRPL.NS',
    label: 'MRPL.NS,  Mangalore Refinery and Petrochemicals Limited',
    name: 'Mangalore Refinery and Petrochemicals Limited',
    symbol: 'MRPL.NS',
  },
  {
    value: 'MSTCLTD.NS',
    label: 'MSTCLTD.NS,  MSTC Limited',
    name: 'MSTC Limited',
    symbol: 'MSTCLTD.NS',
  },
  {
    value: 'MTEDUCARE.NS',
    label: 'MTEDUCARE.NS,  MT Educare Limited',
    name: 'MT Educare Limited',
    symbol: 'MTEDUCARE.NS',
  },
  {
    value: 'MTNL.NS',
    label: 'MTNL.NS,  Mahanagar Telephone Nigam Limited',
    name: 'Mahanagar Telephone Nigam Limited',
    symbol: 'MTNL.NS',
  },
  {
    value: 'MUKANDLTD.NS',
    label: 'MUKANDLTD.NS,  Mukand Limited',
    name: 'Mukand Limited',
    symbol: 'MUKANDLTD.NS',
  },
  {
    value: 'MUNJALAU.NS',
    label: 'MUNJALAU.NS,  Munjal Auto Industries Limited',
    name: 'Munjal Auto Industries Limited',
    symbol: 'MUNJALAU.NS',
  },
  {
    value: 'MUNJALSHOW.NS',
    label: 'MUNJALSHOW.NS,  Munjal Showa Limited',
    name: 'Munjal Showa Limited',
    symbol: 'MUNJALSHOW.NS',
  },
  {
    value: 'MURUDCERA.NS',
    label: 'MURUDCERA.NS,  Murudeshwar Ceramics Ltd.',
    name: 'Murudeshwar Ceramics Ltd.',
    symbol: 'MURUDCERA.NS',
  },
  {
    value: 'MUTHOOTCAP.NS',
    label: 'MUTHOOTCAP.NS,  Muthoot Capital Services Limited',
    name: 'Muthoot Capital Services Limited',
    symbol: 'MUTHOOTCAP.NS',
  },
  {
    value: 'NACLIND.NS',
    label: 'NACLIND.NS,  NACL Industries Limited',
    name: 'NACL Industries Limited',
    symbol: 'NACLIND.NS',
  },
  {
    value: 'NAGREEKEXP.NS',
    label: 'NAGREEKEXP.NS,  Nagreeka Exports Limited',
    name: 'Nagreeka Exports Limited',
    symbol: 'NAGREEKEXP.NS',
  },
  {
    value: 'NAHARCAP.NS',
    label: 'NAHARCAP.NS,  Nahar Capital and Financial Services Limited',
    name: 'Nahar Capital and Financial Services Limited',
    symbol: 'NAHARCAP.NS',
  },
  {
    value: 'NAHARINDUS.NS',
    label: 'NAHARINDUS.NS,  Nahar Industrial Enterprises Limited',
    name: 'Nahar Industrial Enterprises Limited',
    symbol: 'NAHARINDUS.NS',
  },
  {
    value: 'NAHARPOLY.NS',
    label: 'NAHARPOLY.NS,  Nahar Poly Films Limited',
    name: 'Nahar Poly Films Limited',
    symbol: 'NAHARPOLY.NS',
  },
  {
    value: 'NAHARSPING.NS',
    label: 'NAHARSPING.NS,  Nahar Spinning Mills Limited',
    name: 'Nahar Spinning Mills Limited',
    symbol: 'NAHARSPING.NS',
  },
  {
    value: 'NAM-INDIA.NS',
    label: 'NAM-INDIA.NS,  Nippon Life India Asset Management Limited',
    name: 'Nippon Life India Asset Management Limited',
    symbol: 'NAM-INDIA.NS',
  },
  {
    value: 'NATCOPHARM.NS',
    label: 'NATCOPHARM.NS,  NATCO Pharma Limited',
    name: 'NATCO Pharma Limited',
    symbol: 'NATCOPHARM.NS',
  },
  {
    value: 'NATHBIOGEN.NS',
    label: 'NATHBIOGEN.NS,  Nath Bio-Genes (India) Limited',
    name: 'Nath Bio-Genes (India) Limited',
    symbol: 'NATHBIOGEN.NS',
  },
  {
    value: 'NATIONALUM.NS',
    label: 'NATIONALUM.NS,  National Aluminium Company Limited',
    name: 'National Aluminium Company Limited',
    symbol: 'NATIONALUM.NS',
  },
  {
    value: 'NAVINFLUOR.NS',
    label: 'NAVINFLUOR.NS,  Navin Fluorine International Limited',
    name: 'Navin Fluorine International Limited',
    symbol: 'NAVINFLUOR.NS',
  },
  {
    value: 'NAVKARCORP.NS',
    label: 'NAVKARCORP.NS,  Navkar Corporation Limited',
    name: 'Navkar Corporation Limited',
    symbol: 'NAVKARCORP.NS',
  },
  {
    value: 'NAVNETEDUL.NS',
    label: 'NAVNETEDUL.NS,  Navneet Education Limited',
    name: 'Navneet Education Limited',
    symbol: 'NAVNETEDUL.NS',
  },
  {
    value: 'NBCC.NS',
    label: 'NBCC.NS,  NBCC (India) Limited',
    name: 'NBCC (India) Limited',
    symbol: 'NBCC.NS',
  },
  {
    value: 'NBIFIN.NS',
    label: 'NBIFIN.NS,  N.B.I. Industrial Finance Company Limited',
    name: 'N.B.I. Industrial Finance Company Limited',
    symbol: 'NBIFIN.NS',
  },
  {
    value: 'NCC.NS',
    label: 'NCC.NS,  NCC Limited',
    name: 'NCC Limited',
    symbol: 'NCC.NS',
  },
  {
    value: 'NCLIND.NS',
    label: 'NCLIND.NS,  NCL Industries Limited',
    name: 'NCL Industries Limited',
    symbol: 'NCLIND.NS',
  },
  {
    value: 'NDGL.NS',
    label: 'NDGL.NS,  Naga Dhunseri Group Limited',
    name: 'Naga Dhunseri Group Limited',
    symbol: 'NDGL.NS',
  },
  {
    value: 'NDL.NS',
    label: 'NDL.NS,  Nandan Denim Limited',
    name: 'Nandan Denim Limited',
    symbol: 'NDL.NS',
  },
  {
    value: 'NECCLTD.NS',
    label: 'NECCLTD.NS,  North Eastern Carrying Corporation Limited',
    name: 'North Eastern Carrying Corporation Limited',
    symbol: 'NECCLTD.NS',
  },
  {
    value: 'NECLIFE.NS',
    label: 'NECLIFE.NS,  Nectar Lifesciences Limited',
    name: 'Nectar Lifesciences Limited',
    symbol: 'NECLIFE.NS',
  },
  {
    value: 'NELCAST.NS',
    label: 'NELCAST.NS,  Nelcast Limited',
    name: 'Nelcast Limited',
    symbol: 'NELCAST.NS',
  },
  {
    value: 'NELCO.NS',
    label: 'NELCO.NS,  Nelco Limited',
    name: 'Nelco Limited',
    symbol: 'NELCO.NS',
  },
  {
    value: 'NEOGEN.NS',
    label: 'NEOGEN.NS,  Neogen Chemicals Limited',
    name: 'Neogen Chemicals Limited',
    symbol: 'NEOGEN.NS',
  },
  {
    value: 'NESCO.NS',
    label: 'NESCO.NS,  Nesco Limited',
    name: 'Nesco Limited',
    symbol: 'NESCO.NS',
  },
  {
    value: 'NETWORK18.NS',
    label: 'NETWORK18.NS,  Network18 Media & Investments Limited',
    name: 'Network18 Media & Investments Limited',
    symbol: 'NETWORK18.NS',
  },
  {
    value: 'NEULANDLAB.NS',
    label: 'NEULANDLAB.NS,  Neuland Laboratories Limited',
    name: 'Neuland Laboratories Limited',
    symbol: 'NEULANDLAB.NS',
  },
  {
    value: 'NEWGEN.NS',
    label: 'NEWGEN.NS,  Newgen Software Technologies Limited',
    name: 'Newgen Software Technologies Limited',
    symbol: 'NEWGEN.NS',
  },
  {
    value: 'NFL.NS',
    label: 'NFL.NS,  National Fertilizers Limited',
    name: 'National Fertilizers Limited',
    symbol: 'NFL.NS',
  },
  {
    value: 'NH.NS',
    label: 'NH.NS,  Narayana Hrudayalaya Limited',
    name: 'Narayana Hrudayalaya Limited',
    symbol: 'NH.NS',
  },
  {
    value: 'NIACL.NS',
    label: 'NIACL.NS,  The New India Assurance Company Limited',
    name: 'The New India Assurance Company Limited',
    symbol: 'NIACL.NS',
  },
  {
    value: 'NIBL.NS',
    label: 'NIBL.NS,  NRB Industrial Bearings Limited',
    name: 'NRB Industrial Bearings Limited',
    symbol: 'NIBL.NS',
  },
  {
    value: 'NIITLTD.NS',
    label: 'NIITLTD.NS,  NIIT Limited',
    name: 'NIIT Limited',
    symbol: 'NIITLTD.NS',
  },
  {
    value: 'NILAINFRA.NS',
    label: 'NILAINFRA.NS,  Nila Infrastructures Limited',
    name: 'Nila Infrastructures Limited',
    symbol: 'NILAINFRA.NS',
  },
  {
    value: 'NILASPACES.NS',
    label: 'NILASPACES.NS,  Nila Spaces Limited',
    name: 'Nila Spaces Limited',
    symbol: 'NILASPACES.NS',
  },
  {
    value: 'NILKAMAL.NS',
    label: 'NILKAMAL.NS,  Nilkamal Limited',
    name: 'Nilkamal Limited',
    symbol: 'NILKAMAL.NS',
  },
  {
    value: 'NIPPOBATRY.NS',
    label: 'NIPPOBATRY.NS,  Indo National Limited',
    name: 'Indo National Limited',
    symbol: 'NIPPOBATRY.NS',
  },
  {
    value: 'NITCO.NS',
    label: 'NITCO.NS,  NITCO Limited',
    name: 'NITCO Limited',
    symbol: 'NITCO.NS',
  },
  {
    value: 'NITINSPIN.NS',
    label: 'NITINSPIN.NS,  Nitin Spinners Limited',
    name: 'Nitin Spinners Limited',
    symbol: 'NITINSPIN.NS',
  },
  {
    value: 'NLCINDIA.NS',
    label: 'NLCINDIA.NS,  NLC India Limited',
    name: 'NLC India Limited',
    symbol: 'NLCINDIA.NS',
  },
  {
    value: 'NOCIL.NS',
    label: 'NOCIL.NS,  NOCIL Limited',
    name: 'NOCIL Limited',
    symbol: 'NOCIL.NS',
  },
  {
    value: 'NRAIL.NS',
    label: 'NRAIL.NS,  N R Agarwal Industries Limited',
    name: 'N R Agarwal Industries Limited',
    symbol: 'NRAIL.NS',
  },
  {
    value: 'NRBBEARING.NS',
    label: 'NRBBEARING.NS,  NRB Bearings Limited',
    name: 'NRB Bearings Limited',
    symbol: 'NRBBEARING.NS',
  },
  {
    value: 'NSIL.NS',
    label: 'NSIL.NS,  Nalwa Sons Investments Limited',
    name: 'Nalwa Sons Investments Limited',
    symbol: 'NSIL.NS',
  },
  {
    value: 'NUCLEUS.NS',
    label: 'NUCLEUS.NS,  Nucleus Software Exports Limited',
    name: 'Nucleus Software Exports Limited',
    symbol: 'NUCLEUS.NS',
  },
  {
    value: 'NXTDIGITAL.NS',
    label: 'NXTDIGITAL.NS,  Nxtdigital Limited',
    name: 'Nxtdigital Limited',
    symbol: 'NXTDIGITAL.NS',
  },
  {
    value: 'OAL.NS',
    label: 'OAL.NS,  Oriental Aromatics Limited',
    name: 'Oriental Aromatics Limited',
    symbol: 'OAL.NS',
  },
  {
    value: 'OBEROIRLTY.NS',
    label: 'OBEROIRLTY.NS,  Oberoi Realty Limited',
    name: 'Oberoi Realty Limited',
    symbol: 'OBEROIRLTY.NS',
  },
  {
    value: 'OCCL.NS',
    label: 'OCCL.NS,  Oriental Carbon & Chemicals Limited',
    name: 'Oriental Carbon & Chemicals Limited',
    symbol: 'OCCL.NS',
  },
  {
    value: 'OIL.NS',
    label: 'OIL.NS,  Oil India Limited',
    name: 'Oil India Limited',
    symbol: 'OIL.NS',
  },
  {
    value: 'OLECTRA.NS',
    label: 'OLECTRA.NS,  Olectra Greentech Limited',
    name: 'Olectra Greentech Limited',
    symbol: 'OLECTRA.NS',
  },
  {
    value: 'OMAXAUTO.NS',
    label: 'OMAXAUTO.NS,  Omax Autos Limited',
    name: 'Omax Autos Limited',
    symbol: 'OMAXAUTO.NS',
  },
  {
    value: 'OMAXE.NS',
    label: 'OMAXE.NS,  Omaxe Limited',
    name: 'Omaxe Limited',
    symbol: 'OMAXE.NS',
  },
  {
    value: 'ONELIFECAP.NS',
    label: 'ONELIFECAP.NS,  Onelife Capital Advisors Limited',
    name: 'Onelife Capital Advisors Limited',
    symbol: 'ONELIFECAP.NS',
  },
  {
    value: 'ONEPOINT.NS',
    label: 'ONEPOINT.NS,  One Point One Solutions Limited',
    name: 'One Point One Solutions Limited',
    symbol: 'ONEPOINT.NS',
  },
  {
    value: 'ONMOBILE.NS',
    label: 'ONMOBILE.NS,  OnMobile Global Limited',
    name: 'OnMobile Global Limited',
    symbol: 'ONMOBILE.NS',
  },
  {
    value: 'ONWARDTEC.NS',
    label: 'ONWARDTEC.NS,  Onward Technologies Limited',
    name: 'Onward Technologies Limited',
    symbol: 'ONWARDTEC.NS',
  },
  {
    value: 'ORBTEXP.NS',
    label: 'ORBTEXP.NS,  Orbit Exports Limited',
    name: 'Orbit Exports Limited',
    symbol: 'ORBTEXP.NS',
  },
  {
    value: 'ORICONENT.NS',
    label: 'ORICONENT.NS,  Oricon Enterprises Limited',
    name: 'Oricon Enterprises Limited',
    symbol: 'ORICONENT.NS',
  },
  {
    value: 'ORIENTABRA.NS',
    label: 'ORIENTABRA.NS,  Orient Abrasives Limited',
    name: 'Orient Abrasives Limited',
    symbol: 'ORIENTABRA.NS',
  },
  {
    value: 'ORIENTBELL.NS',
    label: 'ORIENTBELL.NS,  Orient Bell Limited',
    name: 'Orient Bell Limited',
    symbol: 'ORIENTBELL.NS',
  },
  {
    value: 'ORIENTCEM.NS',
    label: 'ORIENTCEM.NS,  Orient Cement Limited',
    name: 'Orient Cement Limited',
    symbol: 'ORIENTCEM.NS',
  },
  {
    value: 'ORIENTELEC.NS',
    label: 'ORIENTELEC.NS,  Orient Electric Limited',
    name: 'Orient Electric Limited',
    symbol: 'ORIENTELEC.NS',
  },
  {
    value: 'ORIENTPPR.NS',
    label: 'ORIENTPPR.NS,  Orient Paper & Industries Limited',
    name: 'Orient Paper & Industries Limited',
    symbol: 'ORIENTPPR.NS',
  },
  {
    value: 'ORISSAMINE.NS',
    label: 'ORISSAMINE.NS,  The Orissa Minerals Development Company Limited',
    name: 'The Orissa Minerals Development Company Limited',
    symbol: 'ORISSAMINE.NS',
  },
  {
    value: 'OSWALAGRO.NS',
    label: 'OSWALAGRO.NS,  Oswal Agro Mills Limited',
    name: 'Oswal Agro Mills Limited',
    symbol: 'OSWALAGRO.NS',
  },
  {
    value: 'PAISALO.NS',
    label: 'PAISALO.NS,  Paisalo Digital Limited',
    name: 'Paisalo Digital Limited',
    symbol: 'PAISALO.NS',
  },
  {
    value: 'PANACEABIO.NS',
    label: 'PANACEABIO.NS,  Panacea Biotec Limited',
    name: 'Panacea Biotec Limited',
    symbol: 'PANACEABIO.NS',
  },
  {
    value: 'PANACHE.NS',
    label: 'PANACHE.NS,  Panache Digilife Limited',
    name: 'Panache Digilife Limited',
    symbol: 'PANACHE.NS',
  },
  {
    value: 'PANAMAPET.NS',
    label: 'PANAMAPET.NS,  Panama Petrochem Limited',
    name: 'Panama Petrochem Limited',
    symbol: 'PANAMAPET.NS',
  },
  {
    value: 'PARACABLES.NS',
    label: 'PARACABLES.NS,  Paramount Communications Limited',
    name: 'Paramount Communications Limited',
    symbol: 'PARACABLES.NS',
  },
  {
    value: 'PARAGMILK.NS',
    label: 'PARAGMILK.NS,  Parag Milk Foods Limited',
    name: 'Parag Milk Foods Limited',
    symbol: 'PARAGMILK.NS',
  },
  {
    value: 'PATELENG.NS',
    label: 'PATELENG.NS,  Patel Engineering Limited',
    name: 'Patel Engineering Limited',
    symbol: 'PATELENG.NS',
  },
  {
    value: 'PATINTLOG.NS',
    label: 'PATINTLOG.NS,  Patel Integrated Logistics Limited',
    name: 'Patel Integrated Logistics Limited',
    symbol: 'PATINTLOG.NS',
  },
  {
    value: 'PCJEWELLER.NS',
    label: 'PCJEWELLER.NS,  PC Jeweller Limited',
    name: 'PC Jeweller Limited',
    symbol: 'PCJEWELLER.NS',
  },
  {
    value: 'PDMJEPAPER.NS',
    label: 'PDMJEPAPER.NS,  Pudumjee Paper Products Limited',
    name: 'Pudumjee Paper Products Limited',
    symbol: 'PDMJEPAPER.NS',
  },
  {
    value: 'PEARLPOLY.NS',
    label: 'PEARLPOLY.NS,  Pearl Polymers Limited',
    name: 'Pearl Polymers Limited',
    symbol: 'PEARLPOLY.NS',
  },
  {
    value: 'PENIND.NS',
    label: 'PENIND.NS,  Pennar Industries Limited',
    name: 'Pennar Industries Limited',
    symbol: 'PENIND.NS',
  },
  {
    value: 'PENINLAND.NS',
    label: 'PENINLAND.NS,  Peninsula Land Limited',
    name: 'Peninsula Land Limited',
    symbol: 'PENINLAND.NS',
  },
  {
    value: 'PERSISTENT.NS',
    label: 'PERSISTENT.NS,  Persistent Systems Limited',
    name: 'Persistent Systems Limited',
    symbol: 'PERSISTENT.NS',
  },
  {
    value: 'PFIZER.NS',
    label: 'PFIZER.NS,  Pfizer Limited',
    name: 'Pfizer Limited',
    symbol: 'PFIZER.NS',
  },
  {
    value: 'PFOCUS.NS',
    label: 'PFOCUS.NS,  Prime Focus Limited',
    name: 'Prime Focus Limited',
    symbol: 'PFOCUS.NS',
  },
  {
    value: 'PFS.NS',
    label: 'PFS.NS,  PTC India Financial Services Limited',
    name: 'PTC India Financial Services Limited',
    symbol: 'PFS.NS',
  },
  {
    value: 'PGEL.NS',
    label: 'PGEL.NS,  PG Electroplast Limited',
    name: 'PG Electroplast Limited',
    symbol: 'PGEL.NS',
  },
  {
    value: 'PGHL.NS',
    label: 'PGHL.NS,  Procter & Gamble Health Limited',
    name: 'Procter & Gamble Health Limited',
    symbol: 'PGHL.NS',
  },
  {
    value: 'PGIL.NS',
    label: 'PGIL.NS,  Pearl Global Industries Limited',
    name: 'Pearl Global Industries Limited',
    symbol: 'PGIL.NS',
  },
  {
    value: 'PHOENIXLTD.NS',
    label: 'PHOENIXLTD.NS,  The Phoenix Mills Limited',
    name: 'The Phoenix Mills Limited',
    symbol: 'PHOENIXLTD.NS',
  },
  {
    value: 'PIIND.NS',
    label: 'PIIND.NS,  PI Industries Limited',
    name: 'PI Industries Limited',
    symbol: 'PIIND.NS',
  },
  {
    value: 'PILANIINVS.NS',
    label:
      'PILANIINVS.NS,  Pilani Investment and Industries Corporation Limited',
    name: 'Pilani Investment and Industries Corporation Limited',
    symbol: 'PILANIINVS.NS',
  },
  {
    value: 'PILITA.NS',
    label: 'PILITA.NS,  Pil Italica Lifestyle Limited',
    name: 'Pil Italica Lifestyle Limited',
    symbol: 'PILITA.NS',
  },
  {
    value: 'PIONDIST.NS',
    label: 'PIONDIST.NS,  Pioneer Distilleries Limited',
    name: 'Pioneer Distilleries Limited',
    symbol: 'PIONDIST.NS',
  },
  {
    value: 'PIONEEREMB.NS',
    label: 'PIONEEREMB.NS,  Pioneer Embroideries Limited',
    name: 'Pioneer Embroideries Limited',
    symbol: 'PIONEEREMB.NS',
  },
  {
    value: 'PITTIENG.NS',
    label: 'PITTIENG.NS,  Pitti Engineering Limited',
    name: 'Pitti Engineering Limited',
    symbol: 'PITTIENG.NS',
  },
  {
    value: 'PLASTIBLEN.NS',
    label: 'PLASTIBLEN.NS,  Plastiblends India Limited',
    name: 'Plastiblends India Limited',
    symbol: 'PLASTIBLEN.NS',
  },
  {
    value: 'PNBGILTS.NS',
    label: 'PNBGILTS.NS,  PNB Gilts Ltd.',
    name: 'PNB Gilts Ltd.',
    symbol: 'PNBGILTS.NS',
  },
  {
    value: 'PNBHOUSING.NS',
    label: 'PNBHOUSING.NS,  PNB Housing Finance Limited',
    name: 'PNB Housing Finance Limited',
    symbol: 'PNBHOUSING.NS',
  },
  {
    value: 'PNC.NS',
    label: 'PNC.NS,  Pritish Nandy Communications Ltd',
    name: 'Pritish Nandy Communications Ltd',
    symbol: 'PNC.NS',
  },
  {
    value: 'PNCINFRA.NS',
    label: 'PNCINFRA.NS,  PNC Infratech Limited',
    name: 'PNC Infratech Limited',
    symbol: 'PNCINFRA.NS',
  },
  {
    value: 'PODDARHOUS.NS',
    label: 'PODDARHOUS.NS,  Poddar Housing and Development Limited',
    name: 'Poddar Housing and Development Limited',
    symbol: 'PODDARHOUS.NS',
  },
  {
    value: 'PODDARMENT.NS',
    label: 'PODDARMENT.NS,  Poddar Pigments Limited',
    name: 'Poddar Pigments Limited',
    symbol: 'PODDARMENT.NS',
  },
  {
    value: 'POLYCAB.NS',
    label: 'POLYCAB.NS,  Polycab India Limited',
    name: 'Polycab India Limited',
    symbol: 'POLYCAB.NS',
  },
  {
    value: 'POLYMED.NS',
    label: 'POLYMED.NS,  Poly Medicure Limited',
    name: 'Poly Medicure Limited',
    symbol: 'POLYMED.NS',
  },
  {
    value: 'POLYPLEX.NS',
    label: 'POLYPLEX.NS,  Polyplex Corporation Limited',
    name: 'Polyplex Corporation Limited',
    symbol: 'POLYPLEX.NS',
  },
  {
    value: 'PONNIERODE.NS',
    label: 'PONNIERODE.NS,  Ponni Sugars (Erode) Limited',
    name: 'Ponni Sugars (Erode) Limited',
    symbol: 'PONNIERODE.NS',
  },
  {
    value: 'POWERINDIA.NS',
    label: 'POWERINDIA.NS,  Hitachi Energy India Limited',
    name: 'Hitachi Energy India Limited',
    symbol: 'POWERINDIA.NS',
  },
  {
    value: 'POWERMECH.NS',
    label: 'POWERMECH.NS,  Power Mech Projects Limited',
    name: 'Power Mech Projects Limited',
    symbol: 'POWERMECH.NS',
  },
  {
    value: 'PPAP.NS',
    label: 'PPAP.NS,  PPAP Automotive Limited',
    name: 'PPAP Automotive Limited',
    symbol: 'PPAP.NS',
  },
  {
    value: 'PPL.NS',
    label: 'PPL.NS,  Prakash Pipes Limited',
    name: 'Prakash Pipes Limited',
    symbol: 'PPL.NS',
  },
  {
    value: 'PRAENG.NS',
    label: 'PRAENG.NS,  Prajay Engineers Syndicate Limited',
    name: 'Prajay Engineers Syndicate Limited',
    symbol: 'PRAENG.NS',
  },
  {
    value: 'PRAJIND.NS',
    label: 'PRAJIND.NS,  Praj Industries Limited',
    name: 'Praj Industries Limited',
    symbol: 'PRAJIND.NS',
  },
  {
    value: 'PRAKASH.NS',
    label: 'PRAKASH.NS,  Prakash Industries Limited',
    name: 'Prakash Industries Limited',
    symbol: 'PRAKASH.NS',
  },
  {
    value: 'PRAXIS.NS',
    label: 'PRAXIS.NS,  Praxis Home Retail Limited',
    name: 'Praxis Home Retail Limited',
    symbol: 'PRAXIS.NS',
  },
  {
    value: 'PRECAM.NS',
    label: 'PRECAM.NS,  Precision Camshafts Limited',
    name: 'Precision Camshafts Limited',
    symbol: 'PRECAM.NS',
  },
  {
    value: 'PRECOT.NS',
    label: 'PRECOT.NS,  Precot Limited',
    name: 'Precot Limited',
    symbol: 'PRECOT.NS',
  },
  {
    value: 'PRECWIRE.NS',
    label: 'PRECWIRE.NS,  Precision Wires India Limited',
    name: 'Precision Wires India Limited',
    symbol: 'PRECWIRE.NS',
  },
  {
    value: 'PREMEXPLN.NS',
    label: 'PREMEXPLN.NS,  Premier Explosives Limited',
    name: 'Premier Explosives Limited',
    symbol: 'PREMEXPLN.NS',
  },
  {
    value: 'PREMIERPOL.NS',
    label: 'PREMIERPOL.NS,  Premier Polyfilm Ltd.',
    name: 'Premier Polyfilm Ltd.',
    symbol: 'PREMIERPOL.NS',
  },
  {
    value: 'PRESSMN.NS',
    label: 'PRESSMN.NS,  Pressman Advertising Limited',
    name: 'Pressman Advertising Limited',
    symbol: 'PRESSMN.NS',
  },
  {
    value: 'PRESTIGE.NS',
    label: 'PRESTIGE.NS,  Prestige Estates Projects Limited',
    name: 'Prestige Estates Projects Limited',
    symbol: 'PRESTIGE.NS',
  },
  {
    value: 'PRICOLLTD.NS',
    label: 'PRICOLLTD.NS,  Pricol Limited',
    name: 'Pricol Limited',
    symbol: 'PRICOLLTD.NS',
  },
  {
    value: 'PRIMESECU.NS',
    label: 'PRIMESECU.NS,  Prime Securities Limited',
    name: 'Prime Securities Limited',
    symbol: 'PRIMESECU.NS',
  },
  {
    value: 'PRINCEPIPE.NS',
    label: 'PRINCEPIPE.NS,  Prince Pipes and Fittings Limited',
    name: 'Prince Pipes and Fittings Limited',
    symbol: 'PRINCEPIPE.NS',
  },
  {
    value: 'PROZONINTU.NS',
    label: 'PROZONINTU.NS,  Prozone Intu Properties Limited',
    name: 'Prozone Intu Properties Limited',
    symbol: 'PROZONINTU.NS',
  },
  {
    value: 'PRSMJOHNSN.NS',
    label: 'PRSMJOHNSN.NS,  Prism Johnson Limited',
    name: 'Prism Johnson Limited',
    symbol: 'PRSMJOHNSN.NS',
  },
  {
    value: 'PSB.NS',
    label: 'PSB.NS,  Punjab & Sind Bank',
    name: 'Punjab & Sind Bank',
    symbol: 'PSB.NS',
  },
  {
    value: 'PSPPROJECT.NS',
    label: 'PSPPROJECT.NS,  PSP Projects Limited',
    name: 'PSP Projects Limited',
    symbol: 'PSPPROJECT.NS',
  },
  {
    value: 'PTC.NS',
    label: 'PTC.NS,  PTC India Limited',
    name: 'PTC India Limited',
    symbol: 'PTC.NS',
  },
  {
    value: 'PTL.NS',
    label: 'PTL.NS,  PTL Enterprises Limited',
    name: 'PTL Enterprises Limited',
    symbol: 'PTL.NS',
  },
  {
    value: 'PUNJABCHEM.NS',
    label: 'PUNJABCHEM.NS,  Punjab Chemicals and Crop Protection Limited',
    name: 'Punjab Chemicals and Crop Protection Limited',
    symbol: 'PUNJABCHEM.NS',
  },
  {
    value: 'PURVA.NS',
    label: 'PURVA.NS,  Puravankara Limited',
    name: 'Puravankara Limited',
    symbol: 'PURVA.NS',
  },
  {
    value: 'PVR.NS',
    label: 'PVR.NS,  PVR Limited',
    name: 'PVR Limited',
    symbol: 'PVR.NS',
  },
  {
    value: 'QUESS.NS',
    label: 'QUESS.NS,  Quess Corp Limited',
    name: 'Quess Corp Limited',
    symbol: 'QUESS.NS',
  },
  {
    value: 'QUICKHEAL.NS',
    label: 'QUICKHEAL.NS,  Quick Heal Technologies Limited',
    name: 'Quick Heal Technologies Limited',
    symbol: 'QUICKHEAL.NS',
  },
  {
    value: 'RADAAN.NS',
    label: 'RADAAN.NS,  Radaan Mediaworks India Limited',
    name: 'Radaan Mediaworks India Limited',
    symbol: 'RADAAN.NS',
  },
  {
    value: 'RADICO.NS',
    label: 'RADICO.NS,  Radico Khaitan Limited',
    name: 'Radico Khaitan Limited',
    symbol: 'RADICO.NS',
  },
  {
    value: 'RADIOCITY.NS',
    label: 'RADIOCITY.NS,  Music Broadcast Limited',
    name: 'Music Broadcast Limited',
    symbol: 'RADIOCITY.NS',
  },
  {
    value: 'RAIN.NS',
    label: 'RAIN.NS,  Rain Industries Limited',
    name: 'Rain Industries Limited',
    symbol: 'RAIN.NS',
  },
  {
    value: 'RAJESHEXPO.NS',
    label: 'RAJESHEXPO.NS,  Rajesh Exports Limited',
    name: 'Rajesh Exports Limited',
    symbol: 'RAJESHEXPO.NS',
  },
  {
    value: 'RAJRATAN.NS',
    label: 'RAJRATAN.NS,  Rajratan Global Wire Limited',
    name: 'Rajratan Global Wire Limited',
    symbol: 'RAJRATAN.NS',
  },
  {
    value: 'RAJTV.NS',
    label: 'RAJTV.NS,  Raj Television Network Limited',
    name: 'Raj Television Network Limited',
    symbol: 'RAJTV.NS',
  },
  {
    value: 'RALLIS.NS',
    label: 'RALLIS.NS,  Rallis India Limited',
    name: 'Rallis India Limited',
    symbol: 'RALLIS.NS',
  },
  {
    value: 'RAMANEWS.NS',
    label: 'RAMANEWS.NS,  Shree Rama Newsprint Limited',
    name: 'Shree Rama Newsprint Limited',
    symbol: 'RAMANEWS.NS',
  },
  {
    value: 'RAMASTEEL.NS',
    label: 'RAMASTEEL.NS,  Rama Steel Tubes Limited',
    name: 'Rama Steel Tubes Limited',
    symbol: 'RAMASTEEL.NS',
  },
  {
    value: 'RAMCOCEM.NS',
    label: 'RAMCOCEM.NS,  The Ramco Cements Limited',
    name: 'The Ramco Cements Limited',
    symbol: 'RAMCOCEM.NS',
  },
  {
    value: 'RAMCOIND.NS',
    label: 'RAMCOIND.NS,  Ramco Industries Limited',
    name: 'Ramco Industries Limited',
    symbol: 'RAMCOIND.NS',
  },
  {
    value: 'RAMCOSYS.NS',
    label: 'RAMCOSYS.NS,  Ramco Systems Limited',
    name: 'Ramco Systems Limited',
    symbol: 'RAMCOSYS.NS',
  },
  {
    value: 'RAMKY.NS',
    label: 'RAMKY.NS,  Ramky Infrastructure Limited',
    name: 'Ramky Infrastructure Limited',
    symbol: 'RAMKY.NS',
  },
  {
    value: 'RANEENGINE.NS',
    label: 'RANEENGINE.NS,  Rane Engine Valve Limited',
    name: 'Rane Engine Valve Limited',
    symbol: 'RANEENGINE.NS',
  },
  {
    value: 'RANEHOLDIN.NS',
    label: 'RANEHOLDIN.NS,  Rane Holdings Limited',
    name: 'Rane Holdings Limited',
    symbol: 'RANEHOLDIN.NS',
  },
  {
    value: 'RATNAMANI.NS',
    label: 'RATNAMANI.NS,  Ratnamani Metals & Tubes Limited',
    name: 'Ratnamani Metals & Tubes Limited',
    symbol: 'RATNAMANI.NS',
  },
  {
    value: 'RAYMOND.NS',
    label: 'RAYMOND.NS,  Raymond Limited',
    name: 'Raymond Limited',
    symbol: 'RAYMOND.NS',
  },
  {
    value: 'RBL.NS',
    label: 'RBL.NS,  Rane Brake Lining Limited',
    name: 'Rane Brake Lining Limited',
    symbol: 'RBL.NS',
  },
  {
    value: 'RBLBANK.NS',
    label: 'RBLBANK.NS,  RBL Bank Limited',
    name: 'RBL Bank Limited',
    symbol: 'RBLBANK.NS',
  },
  {
    value: 'RCF.NS',
    label: 'RCF.NS,  Rashtriya Chemicals and Fertilizers Limited',
    name: 'Rashtriya Chemicals and Fertilizers Limited',
    symbol: 'RCF.NS',
  },
  {
    value: 'RECLTD.NS',
    label: 'RECLTD.NS,  REC Limited',
    name: 'REC Limited',
    symbol: 'RECLTD.NS',
  },
  {
    value: 'REDINGTON.NS',
    label: 'REDINGTON.NS,  Redington Limited',
    name: 'Redington Limited',
    symbol: 'REDINGTON.NS',
  },
  {
    value: 'REFEX.NS',
    label: 'REFEX.NS,  Refex Industries Limited',
    name: 'Refex Industries Limited',
    symbol: 'REFEX.NS',
  },
  {
    value: 'RELAXO.NS',
    label: 'RELAXO.NS,  Relaxo Footwears Limited',
    name: 'Relaxo Footwears Limited',
    symbol: 'RELAXO.NS',
  },
  {
    value: 'RELIGARE.NS',
    label: 'RELIGARE.NS,  Religare Enterprises Limited',
    name: 'Religare Enterprises Limited',
    symbol: 'RELIGARE.NS',
  },
  {
    value: 'RELINFRA.NS',
    label: 'RELINFRA.NS,  Reliance Infrastructure Limited',
    name: 'Reliance Infrastructure Limited',
    symbol: 'RELINFRA.NS',
  },
  {
    value: 'REMSONSIND.NS',
    label: 'REMSONSIND.NS,  Remsons Industries Limited',
    name: 'Remsons Industries Limited',
    symbol: 'REMSONSIND.NS',
  },
  {
    value: 'RENUKA.NS',
    label: 'RENUKA.NS,  Shree Renuka Sugars Limited',
    name: 'Shree Renuka Sugars Limited',
    symbol: 'RENUKA.NS',
  },
  {
    value: 'REPCOHOME.NS',
    label: 'REPCOHOME.NS,  Repco Home Finance Limited',
    name: 'Repco Home Finance Limited',
    symbol: 'REPCOHOME.NS',
  },
  {
    value: 'REPRO.NS',
    label: 'REPRO.NS,  Repro India Limited',
    name: 'Repro India Limited',
    symbol: 'REPRO.NS',
  },
  {
    value: 'RESPONIND.NS',
    label: 'RESPONIND.NS,  Responsive Industries Limited',
    name: 'Responsive Industries Limited',
    symbol: 'RESPONIND.NS',
  },
  {
    value: 'REVATHI.NS',
    label: 'REVATHI.NS,  Revathi Equipment Limited',
    name: 'Revathi Equipment Limited',
    symbol: 'REVATHI.NS',
  },
  {
    value: 'RGL.NS',
    label: 'RGL.NS,  Renaissance Global Limited',
    name: 'Renaissance Global Limited',
    symbol: 'RGL.NS',
  },
  {
    value: 'RICOAUTO.NS',
    label: 'RICOAUTO.NS,  Rico Auto Industries Limited',
    name: 'Rico Auto Industries Limited',
    symbol: 'RICOAUTO.NS',
  },
  {
    value: 'RIIL.NS',
    label: 'RIIL.NS,  Reliance Industrial Infrastructure Limited',
    name: 'Reliance Industrial Infrastructure Limited',
    symbol: 'RIIL.NS',
  },
  {
    value: 'RITES.NS',
    label: 'RITES.NS,  RITES Limited',
    name: 'RITES Limited',
    symbol: 'RITES.NS',
  },
  {
    value: 'RKDL.NS',
    label: 'RKDL.NS,  Ravi Kumar Distilleries Limited',
    name: 'Ravi Kumar Distilleries Limited',
    symbol: 'RKDL.NS',
  },
  {
    value: 'RKFORGE.NS',
    label: 'RKFORGE.NS,  Ramkrishna Forgings Limited',
    name: 'Ramkrishna Forgings Limited',
    symbol: 'RKFORGE.NS',
  },
  {
    value: 'RML.NS',
    label: 'RML.NS,  Rane (Madras) Limited',
    name: 'Rane (Madras) Limited',
    symbol: 'RML.NS',
  },
  {
    value: 'ROHLTD.NS',
    label: 'ROHLTD.NS,  Royal Orchid Hotels Limited',
    name: 'Royal Orchid Hotels Limited',
    symbol: 'ROHLTD.NS',
  },
  {
    value: 'ROLTA.NS',
    label: 'ROLTA.NS,  Rolta India Limited',
    name: 'Rolta India Limited',
    symbol: 'ROLTA.NS',
  },
  {
    value: 'ROSSELLIND.NS',
    label: 'ROSSELLIND.NS,  Rossell India Limited',
    name: 'Rossell India Limited',
    symbol: 'ROSSELLIND.NS',
  },
  {
    value: 'RPGLIFE.NS',
    label: 'RPGLIFE.NS,  RPG Life Sciences Limited',
    name: 'RPG Life Sciences Limited',
    symbol: 'RPGLIFE.NS',
  },
  {
    value: 'RPOWER.NS',
    label: 'RPOWER.NS,  Reliance Power Limited',
    name: 'Reliance Power Limited',
    symbol: 'RPOWER.NS',
  },
  {
    value: 'RPPINFRA.NS',
    label: 'RPPINFRA.NS,  R.P.P. Infra Projects Limited',
    name: 'R.P.P. Infra Projects Limited',
    symbol: 'RPPINFRA.NS',
  },
  {
    value: 'RSSOFTWARE.NS',
    label: 'RSSOFTWARE.NS,  R S Software (India) Limited',
    name: 'R S Software (India) Limited',
    symbol: 'RSSOFTWARE.NS',
  },
  {
    value: 'RSWM.NS',
    label: 'RSWM.NS,  RSWM Limited',
    name: 'RSWM Limited',
    symbol: 'RSWM.NS',
  },
  {
    value: 'RSYSTEMS.NS',
    label: 'RSYSTEMS.NS,  R Systems International Limited',
    name: 'R Systems International Limited',
    symbol: 'RSYSTEMS.NS',
  },
  {
    value: 'RTNPOWER.NS',
    label: 'RTNPOWER.NS,  RattanIndia Power Limited',
    name: 'RattanIndia Power Limited',
    symbol: 'RTNPOWER.NS',
  },
  {
    value: 'RUBYMILLS.NS',
    label: 'RUBYMILLS.NS,  The Ruby Mills Limited',
    name: 'The Ruby Mills Limited',
    symbol: 'RUBYMILLS.NS',
  },
  {
    value: 'RUCHIRA.NS',
    label: 'RUCHIRA.NS,  Ruchira Papers Limited',
    name: 'Ruchira Papers Limited',
    symbol: 'RUCHIRA.NS',
  },
  {
    value: 'RUPA.NS',
    label: 'RUPA.NS,  Rupa & Company Limited',
    name: 'Rupa & Company Limited',
    symbol: 'RUPA.NS',
  },
  {
    value: 'RUSHIL.NS',
    label: 'RUSHIL.NS,  Rushil Décor Limited',
    name: 'Rushil Décor Limited',
    symbol: 'RUSHIL.NS',
  },
  {
    value: 'RVNL.NS',
    label: 'RVNL.NS,  Rail Vikas Nigam Limited',
    name: 'Rail Vikas Nigam Limited',
    symbol: 'RVNL.NS',
  },
  {
    value: 'SADBHAV.NS',
    label: 'SADBHAV.NS,  Sadbhav Engineering Limited',
    name: 'Sadbhav Engineering Limited',
    symbol: 'SADBHAV.NS',
  },
  {
    value: 'SADBHIN.NS',
    label: 'SADBHIN.NS,  Sadbhav Infrastructure Project Limited',
    name: 'Sadbhav Infrastructure Project Limited',
    symbol: 'SADBHIN.NS',
  },
  {
    value: 'SAFARI.NS',
    label: 'SAFARI.NS,  Safari Industries (India) Limited',
    name: 'Safari Industries (India) Limited',
    symbol: 'SAFARI.NS',
  },
  {
    value: 'SAGARDEEP.NS',
    label: 'SAGARDEEP.NS,  Sagardeep Alloys Limited',
    name: 'Sagardeep Alloys Limited',
    symbol: 'SAGARDEEP.NS',
  },
  {
    value: 'SAGCEM.NS',
    label: 'SAGCEM.NS,  Sagar Cements Limited',
    name: 'Sagar Cements Limited',
    symbol: 'SAGCEM.NS',
  },
  {
    value: 'SAIL.NS',
    label: 'SAIL.NS,  Steel Authority of India Limited',
    name: 'Steel Authority of India Limited',
    symbol: 'SAIL.NS',
  },
  {
    value: 'SAKAR.NS',
    label: 'SAKAR.NS,  Sakar Healthcare Limited',
    name: 'Sakar Healthcare Limited',
    symbol: 'SAKAR.NS',
  },
  {
    value: 'SAKSOFT.NS',
    label: 'SAKSOFT.NS,  Saksoft Limited',
    name: 'Saksoft Limited',
    symbol: 'SAKSOFT.NS',
  },
  {
    value: 'SALONA.NS',
    label: 'SALONA.NS,  Salona Cotspin Limited',
    name: 'Salona Cotspin Limited',
    symbol: 'SALONA.NS',
  },
  {
    value: 'SALZERELEC.NS',
    label: 'SALZERELEC.NS,  Salzer Electronics Limited',
    name: 'Salzer Electronics Limited',
    symbol: 'SALZERELEC.NS',
  },
  {
    value: 'SAMBHAAV.NS',
    label: 'SAMBHAAV.NS,  Sambhaav Media Limited',
    name: 'Sambhaav Media Limited',
    symbol: 'SAMBHAAV.NS',
  },
  {
    value: 'SANCO.NS',
    label: 'SANCO.NS,  Sanco Industries Limited',
    name: 'Sanco Industries Limited',
    symbol: 'SANCO.NS',
  },
  {
    value: 'SANDESH.NS',
    label: 'SANDESH.NS,  The Sandesh Limited',
    name: 'The Sandesh Limited',
    symbol: 'SANDESH.NS',
  },
  {
    value: 'SANDHAR.NS',
    label: 'SANDHAR.NS,  Sandhar Technologies Limited',
    name: 'Sandhar Technologies Limited',
    symbol: 'SANDHAR.NS',
  },
  {
    value: 'SANGAMIND.NS',
    label: 'SANGAMIND.NS,  Sangam (India) Limited',
    name: 'Sangam (India) Limited',
    symbol: 'SANGAMIND.NS',
  },
  {
    value: 'SANGHIIND.NS',
    label: 'SANGHIIND.NS,  Sanghi Industries Limited',
    name: 'Sanghi Industries Limited',
    symbol: 'SANGHIIND.NS',
  },
  {
    value: 'SANGHVIMOV.NS',
    label: 'SANGHVIMOV.NS,  Sanghvi Movers Limited',
    name: 'Sanghvi Movers Limited',
    symbol: 'SANGHVIMOV.NS',
  },
  {
    value: 'SANGINITA.NS',
    label: 'SANGINITA.NS,  Sanginita Chemicals Limited',
    name: 'Sanginita Chemicals Limited',
    symbol: 'SANGINITA.NS',
  },
  {
    value: 'SANOFI.NS',
    label: 'SANOFI.NS,  Sanofi India Limited',
    name: 'Sanofi India Limited',
    symbol: 'SANOFI.NS',
  },
  {
    value: 'SARDAEN.NS',
    label: 'SARDAEN.NS,  Sarda Energy & Minerals Limited',
    name: 'Sarda Energy & Minerals Limited',
    symbol: 'SARDAEN.NS',
  },
  {
    value: 'SAREGAMA.NS',
    label: 'SAREGAMA.NS,  Saregama India Limited',
    name: 'Saregama India Limited',
    symbol: 'SAREGAMA.NS',
  },
  {
    value: 'SARLAPOLY.NS',
    label: 'SARLAPOLY.NS,  Sarla Performance Fibers Limited',
    name: 'Sarla Performance Fibers Limited',
    symbol: 'SARLAPOLY.NS',
  },
  {
    value: 'SASKEN.NS',
    label: 'SASKEN.NS,  Sasken Technologies Limited',
    name: 'Sasken Technologies Limited',
    symbol: 'SASKEN.NS',
  },
  {
    value: 'SASTASUNDR.NS',
    label: 'SASTASUNDR.NS,  Sastasundar Ventures Limited',
    name: 'Sastasundar Ventures Limited',
    symbol: 'SASTASUNDR.NS',
  },
  {
    value: 'SATIA.NS',
    label: 'SATIA.NS,  Satia Industries Limited',
    name: 'Satia Industries Limited',
    symbol: 'SATIA.NS',
  },
  {
    value: 'SATIN.NS',
    label: 'SATIN.NS,  Satin Creditcare Network Limited',
    name: 'Satin Creditcare Network Limited',
    symbol: 'SATIN.NS',
  },
  {
    value: 'SBICARD.NS',
    label: 'SBICARD.NS,  SBI Cards and Payment Services Limited',
    name: 'SBI Cards and Payment Services Limited',
    symbol: 'SBICARD.NS',
  },
  {
    value: 'SCHAEFFLER.NS',
    label: 'SCHAEFFLER.NS,  Schaeffler India Limited',
    name: 'Schaeffler India Limited',
    symbol: 'SCHAEFFLER.NS',
  },
  {
    value: 'SCHNEIDER.NS',
    label: 'SCHNEIDER.NS,  Schneider Electric Infrastructure Limited',
    name: 'Schneider Electric Infrastructure Limited',
    symbol: 'SCHNEIDER.NS',
  },
  {
    value: 'SCI.NS',
    label: 'SCI.NS,  The Shipping Corporation of India Limited',
    name: 'The Shipping Corporation of India Limited',
    symbol: 'SCI.NS',
  },
  {
    value: 'SDBL.NS',
    label: 'SDBL.NS,  Som Distilleries & Breweries Limited',
    name: 'Som Distilleries & Breweries Limited',
    symbol: 'SDBL.NS',
  },
  {
    value: 'SEAMECLTD.NS',
    label: 'SEAMECLTD.NS,  Seamec Limited',
    name: 'Seamec Limited',
    symbol: 'SEAMECLTD.NS',
  },
  {
    value: 'SELAN.NS',
    label: 'SELAN.NS,  Selan Exploration Technology Limited',
    name: 'Selan Exploration Technology Limited',
    symbol: 'SELAN.NS',
  },
  {
    value: 'SEQUENT.NS',
    label: 'SEQUENT.NS,  Sequent Scientific Limited',
    name: 'Sequent Scientific Limited',
    symbol: 'SEQUENT.NS',
  },
  {
    value: 'SESHAPAPER.NS',
    label: 'SESHAPAPER.NS,  Seshasayee Paper and Boards Limited',
    name: 'Seshasayee Paper and Boards Limited',
    symbol: 'SESHAPAPER.NS',
  },
  {
    value: 'SETCO.NS',
    label: 'SETCO.NS,  Setco Automotive Limited',
    name: 'Setco Automotive Limited',
    symbol: 'SETCO.NS',
  },
  {
    value: 'SEYAIND.NS',
    label: 'SEYAIND.NS,  Seya Industries Limited',
    name: 'Seya Industries Limited',
    symbol: 'SEYAIND.NS',
  },
  {
    value: 'SFL.NS',
    label: 'SFL.NS,  Sheela Foam Limited',
    name: 'Sheela Foam Limited',
    symbol: 'SFL.NS',
  },
  {
    value: 'SGL.NS',
    label: 'SGL.NS,  STL Global Limited',
    name: 'STL Global Limited',
    symbol: 'SGL.NS',
  },
  {
    value: 'SHALBY.NS',
    label: 'SHALBY.NS,  Shalby Limited',
    name: 'Shalby Limited',
    symbol: 'SHALBY.NS',
  },
  {
    value: 'SHALPAINTS.NS',
    label: 'SHALPAINTS.NS,  Shalimar Paints Limited',
    name: 'Shalimar Paints Limited',
    symbol: 'SHALPAINTS.NS',
  },
  {
    value: 'SHANKARA.NS',
    label: 'SHANKARA.NS,  Shankara Building Products Limited',
    name: 'Shankara Building Products Limited',
    symbol: 'SHANKARA.NS',
  },
  {
    value: 'SHANTIGEAR.NS',
    label: 'SHANTIGEAR.NS,  Shanthi Gears Limited',
    name: 'Shanthi Gears Limited',
    symbol: 'SHANTIGEAR.NS',
  },
  {
    value: 'SHARDACROP.NS',
    label: 'SHARDACROP.NS,  Sharda Cropchem Limited',
    name: 'Sharda Cropchem Limited',
    symbol: 'SHARDACROP.NS',
  },
  {
    value: 'SHARDAMOTR.NS',
    label: 'SHARDAMOTR.NS,  Sharda Motor Industries Limited',
    name: 'Sharda Motor Industries Limited',
    symbol: 'SHARDAMOTR.NS',
  },
  {
    value: 'SHEMAROO.NS',
    label: 'SHEMAROO.NS,  Shemaroo Entertainment Limited',
    name: 'Shemaroo Entertainment Limited',
    symbol: 'SHEMAROO.NS',
  },
  {
    value: 'SHILPAMED.NS',
    label: 'SHILPAMED.NS,  Shilpa Medicare Limited',
    name: 'Shilpa Medicare Limited',
    symbol: 'SHILPAMED.NS',
  },
  {
    value: 'SHIVAMILLS.NS',
    label: 'SHIVAMILLS.NS,  Shiva Mills Limited',
    name: 'Shiva Mills Limited',
    symbol: 'SHIVAMILLS.NS',
  },
  {
    value: 'SHIVATEX.NS',
    label: 'SHIVATEX.NS,  Shiva Texyarn Limited',
    name: 'Shiva Texyarn Limited',
    symbol: 'SHIVATEX.NS',
  },
  {
    value: 'SHK.NS',
    label: 'SHK.NS,  S H Kelkar and Company Limited',
    name: 'S H Kelkar and Company Limited',
    symbol: 'SHK.NS',
  },
  {
    value: 'SHOPERSTOP.NS',
    label: 'SHOPERSTOP.NS,  Shoppers Stop Limited',
    name: 'Shoppers Stop Limited',
    symbol: 'SHOPERSTOP.NS',
  },
  {
    value: 'SHREDIGCEM.NS',
    label: 'SHREDIGCEM.NS,  Shree Digvijay Cement Company Ltd.',
    name: 'Shree Digvijay Cement Company Ltd.',
    symbol: 'SHREDIGCEM.NS',
  },
  {
    value: 'SHREEPUSHK.NS',
    label: 'SHREEPUSHK.NS,  Shree Pushkar Chemicals & Fertilisers Limited',
    name: 'Shree Pushkar Chemicals & Fertilisers Limited',
    symbol: 'SHREEPUSHK.NS',
  },
  {
    value: 'SHREERAMA.NS',
    label: 'SHREERAMA.NS,  Shree Rama Multi-Tech Limited',
    name: 'Shree Rama Multi-Tech Limited',
    symbol: 'SHREERAMA.NS',
  },
  {
    value: 'SHRENIK.NS',
    label: 'SHRENIK.NS,  Shrenik Limited',
    name: 'Shrenik Limited',
    symbol: 'SHRENIK.NS',
  },
  {
    value: 'SHREYANIND.NS',
    label: 'SHREYANIND.NS,  Shreyans Industries Limited',
    name: 'Shreyans Industries Limited',
    symbol: 'SHREYANIND.NS',
  },
  {
    value: 'SHREYAS.NS',
    label: 'SHREYAS.NS,  Shreyas Shipping and Logistics Limited',
    name: 'Shreyas Shipping and Logistics Limited',
    symbol: 'SHREYAS.NS',
  },
  {
    value: 'SHRIRAMCIT.NS',
    label: 'SHRIRAMCIT.NS,  Shriram City Union Finance Limited',
    name: 'Shriram City Union Finance Limited',
    symbol: 'SHRIRAMCIT.NS',
  },
  {
    value: 'SICAL.NS',
    label: 'SICAL.NS,  Sical Logistics Limited',
    name: 'Sical Logistics Limited',
    symbol: 'SICAL.NS',
  },
  {
    value: 'SIGIND.NS',
    label: 'SIGIND.NS,  Signet Industries Limited',
    name: 'Signet Industries Limited',
    symbol: 'SIGIND.NS',
  },
  {
    value: 'SILINV.NS',
    label: 'SILINV.NS,  SIL Investments Limited',
    name: 'SIL Investments Limited',
    symbol: 'SILINV.NS',
  },
  {
    value: 'SIMPLEXINF.NS',
    label: 'SIMPLEXINF.NS,  Simplex Infrastructures Limited',
    name: 'Simplex Infrastructures Limited',
    symbol: 'SIMPLEXINF.NS',
  },
  {
    value: 'SIRCA.NS',
    label: 'SIRCA.NS,  Sirca Paints India Limited',
    name: 'Sirca Paints India Limited',
    symbol: 'SIRCA.NS',
  },
  {
    value: 'SIS.NS',
    label: 'SIS.NS,  SIS Limited',
    name: 'SIS Limited',
    symbol: 'SIS.NS',
  },
  {
    value: 'SIYSIL.NS',
    label: 'SIYSIL.NS,  Siyaram Silk Mills Limited',
    name: 'Siyaram Silk Mills Limited',
    symbol: 'SIYSIL.NS',
  },
  {
    value: 'SJVN.NS',
    label: 'SJVN.NS,  SJVN Limited',
    name: 'SJVN Limited',
    symbol: 'SJVN.NS',
  },
  {
    value: 'SKFINDIA.NS',
    label: 'SKFINDIA.NS,  SKF India Limited',
    name: 'SKF India Limited',
    symbol: 'SKFINDIA.NS',
  },
  {
    value: 'SKIPPER.NS',
    label: 'SKIPPER.NS,  Skipper Limited',
    name: 'Skipper Limited',
    symbol: 'SKIPPER.NS',
  },
  {
    value: 'SKMEGGPROD.NS',
    label: 'SKMEGGPROD.NS,  SKM Egg Products Export (India) Limited',
    name: 'SKM Egg Products Export (India) Limited',
    symbol: 'SKMEGGPROD.NS',
  },
  {
    value: 'SMARTLINK.NS',
    label: 'SMARTLINK.NS,  Smartlink Holdings Limited',
    name: 'Smartlink Holdings Limited',
    symbol: 'SMARTLINK.NS',
  },
  {
    value: 'SMLISUZU.NS',
    label: 'SMLISUZU.NS,  SML Isuzu Limited',
    name: 'SML Isuzu Limited',
    symbol: 'SMLISUZU.NS',
  },
  {
    value: 'SMSLIFE.NS',
    label: 'SMSLIFE.NS,  SMS Lifesciences India Limited',
    name: 'SMS Lifesciences India Limited',
    symbol: 'SMSLIFE.NS',
  },
  {
    value: 'SMSPHARMA.NS',
    label: 'SMSPHARMA.NS,  SMS Pharmaceuticals Limited',
    name: 'SMS Pharmaceuticals Limited',
    symbol: 'SMSPHARMA.NS',
  },
  {
    value: 'SNOWMAN.NS',
    label: 'SNOWMAN.NS,  Snowman Logistics Limited',
    name: 'Snowman Logistics Limited',
    symbol: 'SNOWMAN.NS',
  },
  {
    value: 'SOBHA.NS',
    label: 'SOBHA.NS,  Sobha Limited',
    name: 'Sobha Limited',
    symbol: 'SOBHA.NS',
  },
  {
    value: 'SOLARA.NS',
    label: 'SOLARA.NS,  Solara Active Pharma Sciences Limited',
    name: 'Solara Active Pharma Sciences Limited',
    symbol: 'SOLARA.NS',
  },
  {
    value: 'SOLARINDS.NS',
    label: 'SOLARINDS.NS,  Solar Industries India Limited',
    name: 'Solar Industries India Limited',
    symbol: 'SOLARINDS.NS',
  },
  {
    value: 'SOMANYCERA.NS',
    label: 'SOMANYCERA.NS,  Somany Ceramics Limited',
    name: 'Somany Ceramics Limited',
    symbol: 'SOMANYCERA.NS',
  },
  {
    value: 'SOMICONVEY.NS',
    label: 'SOMICONVEY.NS,  Somi Conveyor Beltings Limited',
    name: 'Somi Conveyor Beltings Limited',
    symbol: 'SOMICONVEY.NS',
  },
  {
    value: 'SONATSOFTW.NS',
    label: 'SONATSOFTW.NS,  Sonata Software Limited',
    name: 'Sonata Software Limited',
    symbol: 'SONATSOFTW.NS',
  },
  {
    value: 'SOTL.NS',
    label: 'SOTL.NS,  Savita Oil Technologies Limited',
    name: 'Savita Oil Technologies Limited',
    symbol: 'SOTL.NS',
  },
  {
    value: 'SOUTHBANK.NS',
    label: 'SOUTHBANK.NS,  The South Indian Bank Limited',
    name: 'The South Indian Bank Limited',
    symbol: 'SOUTHBANK.NS',
  },
  {
    value: 'SPAL.NS',
    label: 'SPAL.NS,  S.P. Apparels Limited',
    name: 'S.P. Apparels Limited',
    symbol: 'SPAL.NS',
  },
  {
    value: 'SPANDANA.NS',
    label: 'SPANDANA.NS,  Spandana Sphoorty Financial Limited',
    name: 'Spandana Sphoorty Financial Limited',
    symbol: 'SPANDANA.NS',
  },
  {
    value: 'SPARC.NS',
    label: 'SPARC.NS,  Sun Pharma Advanced Research Company Limited',
    name: 'Sun Pharma Advanced Research Company Limited',
    symbol: 'SPARC.NS',
  },
  {
    value: 'SPECIALITY.NS',
    label: 'SPECIALITY.NS,  Speciality Restaurants Limited',
    name: 'Speciality Restaurants Limited',
    symbol: 'SPECIALITY.NS',
  },
  {
    value: 'SPENCERS.NS',
    label: "SPENCERS.NS,  Spencer's Retail Limited",
    name: "Spencer's Retail Limited",
    symbol: 'SPENCERS.NS',
  },
  {
    value: 'SPIC.NS',
    label: 'SPIC.NS,  Southern Petrochemical Industries Corporation Limited',
    name: 'Southern Petrochemical Industries Corporation Limited',
    symbol: 'SPIC.NS',
  },
  {
    value: 'SPICEJET.NS',
    label: 'SPICEJET.NS,  SpiceJet Limited',
    name: 'SpiceJet Limited',
    symbol: 'SPICEJET.NS',
  },
  {
    value: 'SPLIL.NS',
    label: 'SPLIL.NS,  SPL Industries Limited',
    name: 'SPL Industries Limited',
    symbol: 'SPLIL.NS',
  },
  {
    value: 'SPMLINFRA.NS',
    label: 'SPMLINFRA.NS,  SPML Infra Limited',
    name: 'SPML Infra Limited',
    symbol: 'SPMLINFRA.NS',
  },
  {
    value: 'SREEL.NS',
    label: 'SREEL.NS,  Sreeleathers Limited',
    name: 'Sreeleathers Limited',
    symbol: 'SREEL.NS',
  },
  {
    value: 'SREINFRA.NS',
    label: 'SREINFRA.NS,  Srei Infrastructure Finance Limited',
    name: 'Srei Infrastructure Finance Limited',
    symbol: 'SREINFRA.NS',
  },
  {
    value: 'SRF.NS',
    label: 'SRF.NS,  SRF Limited',
    name: 'SRF Limited',
    symbol: 'SRF.NS',
  },
  {
    value: 'SRHHYPOLTD.NS',
    label: 'SRHHYPOLTD.NS,  Sree Rayalaseema Hi-Strength Hypo Limited',
    name: 'Sree Rayalaseema Hi-Strength Hypo Limited',
    symbol: 'SRHHYPOLTD.NS',
  },
  {
    value: 'SSWL.NS',
    label: 'SSWL.NS,  Steel Strips Wheels Limited',
    name: 'Steel Strips Wheels Limited',
    symbol: 'SSWL.NS',
  },
  {
    value: 'STAR.NS',
    label: 'STAR.NS,  Strides Pharma Science Limited',
    name: 'Strides Pharma Science Limited',
    symbol: 'STAR.NS',
  },
  {
    value: 'STARCEMENT.NS',
    label: 'STARCEMENT.NS,  Star Cement Limited',
    name: 'Star Cement Limited',
    symbol: 'STARCEMENT.NS',
  },
  {
    value: 'STARPAPER.NS',
    label: 'STARPAPER.NS,  Star Paper Mills Limited',
    name: 'Star Paper Mills Limited',
    symbol: 'STARPAPER.NS',
  },
  {
    value: 'STCINDIA.NS',
    label: 'STCINDIA.NS,  The State Trading Corporation of India Ltd.',
    name: 'The State Trading Corporation of India Ltd.',
    symbol: 'STCINDIA.NS',
  },
  {
    value: 'STEELCITY.NS',
    label: 'STEELCITY.NS,  Steel City Securities Limited',
    name: 'Steel City Securities Limited',
    symbol: 'STEELCITY.NS',
  },
  {
    value: 'STEL.NS',
    label: 'STEL.NS,  STEL Holdings Limited',
    name: 'STEL Holdings Limited',
    symbol: 'STEL.NS',
  },
  {
    value: 'STERTOOLS.NS',
    label: 'STERTOOLS.NS,  Sterling Tools Limited',
    name: 'Sterling Tools Limited',
    symbol: 'STERTOOLS.NS',
  },
  {
    value: 'SUBROS.NS',
    label: 'SUBROS.NS,  Subros Limited',
    name: 'Subros Limited',
    symbol: 'SUBROS.NS',
  },
  {
    value: 'SUDARSCHEM.NS',
    label: 'SUDARSCHEM.NS,  Sudarshan Chemical Industries Limited',
    name: 'Sudarshan Chemical Industries Limited',
    symbol: 'SUDARSCHEM.NS',
  },
  {
    value: 'SUMEETINDS.NS',
    label: 'SUMEETINDS.NS,  Sumeet Industries Limited',
    name: 'Sumeet Industries Limited',
    symbol: 'SUMEETINDS.NS',
  },
  {
    value: 'SUMICHEM.NS',
    label: 'SUMICHEM.NS,  Sumitomo Chemical India Limited',
    name: 'Sumitomo Chemical India Limited',
    symbol: 'SUMICHEM.NS',
  },
  {
    value: 'SUMMITSEC.NS',
    label: 'SUMMITSEC.NS,  Summit Securities Limited',
    name: 'Summit Securities Limited',
    symbol: 'SUMMITSEC.NS',
  },
  {
    value: 'SUNCLAYLTD.NS',
    label: 'SUNCLAYLTD.NS,  Sundaram-Clayton Limited',
    name: 'Sundaram-Clayton Limited',
    symbol: 'SUNCLAYLTD.NS',
  },
  {
    value: 'SUNDARAM.NS',
    label: 'SUNDARAM.NS,  Sundaram Multi Pap Limited',
    name: 'Sundaram Multi Pap Limited',
    symbol: 'SUNDARAM.NS',
  },
  {
    value: 'SUNDARMFIN.NS',
    label: 'SUNDARMFIN.NS,  Sundaram Finance Limited',
    name: 'Sundaram Finance Limited',
    symbol: 'SUNDARMFIN.NS',
  },
  {
    value: 'SUNDARMHLD.NS',
    label: 'SUNDARMHLD.NS,  Sundaram Finance Holdings Limited',
    name: 'Sundaram Finance Holdings Limited',
    symbol: 'SUNDARMHLD.NS',
  },
  {
    value: 'SUNDRMBRAK.NS',
    label: 'SUNDRMBRAK.NS,  Sundaram Brake Linings Limited',
    name: 'Sundaram Brake Linings Limited',
    symbol: 'SUNDRMBRAK.NS',
  },
  {
    value: 'SUNDRMFAST.NS',
    label: 'SUNDRMFAST.NS,  Sundram Fasteners Limited',
    name: 'Sundram Fasteners Limited',
    symbol: 'SUNDRMFAST.NS',
  },
  {
    value: 'SUNFLAG.NS',
    label: 'SUNFLAG.NS,  Sunflag Iron and Steel Company Limited',
    name: 'Sunflag Iron and Steel Company Limited',
    symbol: 'SUNFLAG.NS',
  },
  {
    value: 'SUNTECK.NS',
    label: 'SUNTECK.NS,  Sunteck Realty Limited',
    name: 'Sunteck Realty Limited',
    symbol: 'SUNTECK.NS',
  },
  {
    value: 'SUNTV.NS',
    label: 'SUNTV.NS,  Sun TV Network Limited',
    name: 'Sun TV Network Limited',
    symbol: 'SUNTV.NS',
  },
  {
    value: 'SUPERHOUSE.NS',
    label: 'SUPERHOUSE.NS,  Superhouse Limited',
    name: 'Superhouse Limited',
    symbol: 'SUPERHOUSE.NS',
  },
  {
    value: 'SUPRAJIT.NS',
    label: 'SUPRAJIT.NS,  Suprajit Engineering Limited',
    name: 'Suprajit Engineering Limited',
    symbol: 'SUPRAJIT.NS',
  },
  {
    value: 'SUPREMEIND.NS',
    label: 'SUPREMEIND.NS,  The Supreme Industries Limited',
    name: 'The Supreme Industries Limited',
    symbol: 'SUPREMEIND.NS',
  },
  {
    value: 'SURYAROSNI.NS',
    label: 'SURYAROSNI.NS,  Surya Roshni Limited',
    name: 'Surya Roshni Limited',
    symbol: 'SURYAROSNI.NS',
  },
  {
    value: 'SUTLEJTEX.NS',
    label: 'SUTLEJTEX.NS,  Sutlej Textiles and Industries Limited',
    name: 'Sutlej Textiles and Industries Limited',
    symbol: 'SUTLEJTEX.NS',
  },
  {
    value: 'SUVEN.NS',
    label: 'SUVEN.NS,  Suven Life Sciences Limited',
    name: 'Suven Life Sciences Limited',
    symbol: 'SUVEN.NS',
  },
  {
    value: 'SUVENPHAR.NS',
    label: 'SUVENPHAR.NS,  Suven Pharmaceuticals Limited',
    name: 'Suven Pharmaceuticals Limited',
    symbol: 'SUVENPHAR.NS',
  },
  {
    value: 'SUZLON.NS',
    label: 'SUZLON.NS,  Suzlon Energy Limited',
    name: 'Suzlon Energy Limited',
    symbol: 'SUZLON.NS',
  },
  {
    value: 'SWANENERGY.NS',
    label: 'SWANENERGY.NS,  Swan Energy Limited',
    name: 'Swan Energy Limited',
    symbol: 'SWANENERGY.NS',
  },
  {
    value: 'SWARAJENG.NS',
    label: 'SWARAJENG.NS,  Swaraj Engines Limited',
    name: 'Swaraj Engines Limited',
    symbol: 'SWARAJENG.NS',
  },
  {
    value: 'SWELECTES.NS',
    label: 'SWELECTES.NS,  Swelect Energy Systems Limited',
    name: 'Swelect Energy Systems Limited',
    symbol: 'SWELECTES.NS',
  },
  {
    value: 'SWSOLAR.NS',
    label: 'SWSOLAR.NS,  Sterling and Wilson Renewable Energy Limited',
    name: 'Sterling and Wilson Renewable Energy Limited',
    symbol: 'SWSOLAR.NS',
  },
  {
    value: 'SYMPHONY.NS',
    label: 'SYMPHONY.NS,  Symphony Limited',
    name: 'Symphony Limited',
    symbol: 'SYMPHONY.NS',
  },
  {
    value: 'SYNGENE.NS',
    label: 'SYNGENE.NS,  Syngene International Limited',
    name: 'Syngene International Limited',
    symbol: 'SYNGENE.NS',
  },
  {
    value: 'TAINWALCHM.NS',
    label: 'TAINWALCHM.NS,  Tainwala Chemicals and Plastics (India) Limited',
    name: 'Tainwala Chemicals and Plastics (India) Limited',
    symbol: 'TAINWALCHM.NS',
  },
  {
    value: 'TAJGVK.NS',
    label: 'TAJGVK.NS,  TAJGVK Hotels & Resorts Limited',
    name: 'TAJGVK Hotels & Resorts Limited',
    symbol: 'TAJGVK.NS',
  },
  {
    value: 'TAKE.NS',
    label: 'TAKE.NS,  TAKE Solutions Limited',
    name: 'TAKE Solutions Limited',
    symbol: 'TAKE.NS',
  },
  {
    value: 'TALBROAUTO.NS',
    label: 'TALBROAUTO.NS,  Talbros Automotive Components Limited',
    name: 'Talbros Automotive Components Limited',
    symbol: 'TALBROAUTO.NS',
  },
  {
    value: 'TANLA.NS',
    label: 'TANLA.NS,  Tanla Platforms Limited',
    name: 'Tanla Platforms Limited',
    symbol: 'TANLA.NS',
  },
  {
    value: 'TARMAT.NS',
    label: 'TARMAT.NS,  Tarmat Limited',
    name: 'Tarmat Limited',
    symbol: 'TARMAT.NS',
  },
  {
    value: 'TASTYBITE.NS',
    label: 'TASTYBITE.NS,  Tasty Bite Eatables Limited',
    name: 'Tasty Bite Eatables Limited',
    symbol: 'TASTYBITE.NS',
  },
  {
    value: 'TATACHEM.NS',
    label: 'TATACHEM.NS,  Tata Chemicals Limited',
    name: 'Tata Chemicals Limited',
    symbol: 'TATACHEM.NS',
  },
  {
    value: 'TATACOFFEE.NS',
    label: 'TATACOFFEE.NS,  Tata Coffee Limited',
    name: 'Tata Coffee Limited',
    symbol: 'TATACOFFEE.NS',
  },
  {
    value: 'TATACOMM.NS',
    label: 'TATACOMM.NS,  Tata Communications Limited',
    name: 'Tata Communications Limited',
    symbol: 'TATACOMM.NS',
  },
  {
    value: 'TATACONSUM.NS',
    label: 'TATACONSUM.NS,  Tata Consumer Products Limited',
    name: 'Tata Consumer Products Limited',
    symbol: 'TATACONSUM.NS',
  },
  {
    value: 'TATAELXSI.NS',
    label: 'TATAELXSI.NS,  Tata Elxsi Limited',
    name: 'Tata Elxsi Limited',
    symbol: 'TATAELXSI.NS',
  },
  {
    value: 'TATAINVEST.NS',
    label: 'TATAINVEST.NS,  Tata Investment Corporation Limited',
    name: 'Tata Investment Corporation Limited',
    symbol: 'TATAINVEST.NS',
  },
  {
    value: 'TATAMETALI.NS',
    label: 'TATAMETALI.NS,  Tata Metaliks Limited',
    name: 'Tata Metaliks Limited',
    symbol: 'TATAMETALI.NS',
  },
  {
    value: 'TATAPOWER.NS',
    label: 'TATAPOWER.NS,  The Tata Power Company Limited',
    name: 'The Tata Power Company Limited',
    symbol: 'TATAPOWER.NS',
  },
  {
    value: 'TATASTLLP.NS',
    label: 'TATASTLLP.NS,  Tata Steel Long Products Limited',
    name: 'Tata Steel Long Products Limited',
    symbol: 'TATASTLLP.NS',
  },
  {
    value: 'TBZ.NS',
    label: 'TBZ.NS,  Tribhovandas Bhimji Zaveri Limited',
    name: 'Tribhovandas Bhimji Zaveri Limited',
    symbol: 'TBZ.NS',
  },
  {
    value: 'TCI.NS',
    label: 'TCI.NS,  Transport Corporation of India Limited',
    name: 'Transport Corporation of India Limited',
    symbol: 'TCI.NS',
  },
  {
    value: 'TCIDEVELOP.NS',
    label: 'TCIDEVELOP.NS,  TCI Developers Limited',
    name: 'TCI Developers Limited',
    symbol: 'TCIDEVELOP.NS',
  },
  {
    value: 'TCIEXP.NS',
    label: 'TCIEXP.NS,  TCI Express Limited',
    name: 'TCI Express Limited',
    symbol: 'TCIEXP.NS',
  },
  {
    value: 'TCIFINANCE.NS',
    label: 'TCIFINANCE.NS,  TCI Finance Limited',
    name: 'TCI Finance Limited',
    symbol: 'TCIFINANCE.NS',
  },
  {
    value: 'TCNSBRANDS.NS',
    label: 'TCNSBRANDS.NS,  TCNS Clothing Co. Limited',
    name: 'TCNS Clothing Co. Limited',
    symbol: 'TCNSBRANDS.NS',
  },
  {
    value: 'TCPLPACK.NS',
    label: 'TCPLPACK.NS,  TCPL Packaging Limited',
    name: 'TCPL Packaging Limited',
    symbol: 'TCPLPACK.NS',
  },
  {
    value: 'TDPOWERSYS.NS',
    label: 'TDPOWERSYS.NS,  TD Power Systems Limited',
    name: 'TD Power Systems Limited',
    symbol: 'TDPOWERSYS.NS',
  },
  {
    value: 'TEAMLEASE.NS',
    label: 'TEAMLEASE.NS,  TeamLease Services Limited',
    name: 'TeamLease Services Limited',
    symbol: 'TEAMLEASE.NS',
  },
  {
    value: 'TECHNOE.NS',
    label: 'TECHNOE.NS,  Techno Electric & Engineering Company Limited',
    name: 'Techno Electric & Engineering Company Limited',
    symbol: 'TECHNOE.NS',
  },
  {
    value: 'TERASOFT.NS',
    label: 'TERASOFT.NS,  Tera Software Limited',
    name: 'Tera Software Limited',
    symbol: 'TERASOFT.NS',
  },
  {
    value: 'TEXINFRA.NS',
    label: 'TEXINFRA.NS,  Texmaco Infrastructure & Holdings Limited',
    name: 'Texmaco Infrastructure & Holdings Limited',
    symbol: 'TEXINFRA.NS',
  },
  {
    value: 'TEXMOPIPES.NS',
    label: 'TEXMOPIPES.NS,  Texmo Pipes and Products Limited',
    name: 'Texmo Pipes and Products Limited',
    symbol: 'TEXMOPIPES.NS',
  },
  {
    value: 'TEXRAIL.NS',
    label: 'TEXRAIL.NS,  Texmaco Rail & Engineering Limited',
    name: 'Texmaco Rail & Engineering Limited',
    symbol: 'TEXRAIL.NS',
  },
  {
    value: 'TFCILTD.NS',
    label: 'TFCILTD.NS,  Tourism Finance Corporation of India Limited',
    name: 'Tourism Finance Corporation of India Limited',
    symbol: 'TFCILTD.NS',
  },
  {
    value: 'THANGAMAYL.NS',
    label: 'THANGAMAYL.NS,  Thangamayil Jewellery Limited',
    name: 'Thangamayil Jewellery Limited',
    symbol: 'THANGAMAYL.NS',
  },
  {
    value: 'THEINVEST.NS',
    label: 'THEINVEST.NS,  The Investment Trust of India Limited',
    name: 'The Investment Trust of India Limited',
    symbol: 'THEINVEST.NS',
  },
  {
    value: 'THEMISMED.NS',
    label: 'THEMISMED.NS,  Themis Medicare Limited',
    name: 'Themis Medicare Limited',
    symbol: 'THEMISMED.NS',
  },
  {
    value: 'THERMAX.NS',
    label: 'THERMAX.NS,  Thermax Limited',
    name: 'Thermax Limited',
    symbol: 'THERMAX.NS',
  },
  {
    value: 'THOMASCOOK.NS',
    label: 'THOMASCOOK.NS,  Thomas Cook (India) Limited',
    name: 'Thomas Cook (India) Limited',
    symbol: 'THOMASCOOK.NS',
  },
  {
    value: 'THYROCARE.NS',
    label: 'THYROCARE.NS,  Thyrocare Technologies Limited',
    name: 'Thyrocare Technologies Limited',
    symbol: 'THYROCARE.NS',
  },
  {
    value: 'TI.NS',
    label: 'TI.NS,  Tilaknagar Industries Ltd.',
    name: 'Tilaknagar Industries Ltd.',
    symbol: 'TI.NS',
  },
  {
    value: 'TIDEWATER.NS',
    label: 'TIDEWATER.NS,  Tide Water Oil Co. (India) Limited',
    name: 'Tide Water Oil Co. (India) Limited',
    symbol: 'TIDEWATER.NS',
  },
  {
    value: 'TIIL.NS',
    label: 'TIIL.NS,  Technocraft Industries (India) Limited',
    name: 'Technocraft Industries (India) Limited',
    symbol: 'TIIL.NS',
  },
  {
    value: 'TIINDIA.NS',
    label: 'TIINDIA.NS,  Tube Investments of India Limited',
    name: 'Tube Investments of India Limited',
    symbol: 'TIINDIA.NS',
  },
  {
    value: 'TIJARIA.NS',
    label: 'TIJARIA.NS,  Tijaria Polypipes Limited',
    name: 'Tijaria Polypipes Limited',
    symbol: 'TIJARIA.NS',
  },
  {
    value: 'TIL.NS',
    label: 'TIL.NS,  TIL Limited',
    name: 'TIL Limited',
    symbol: 'TIL.NS',
  },
  {
    value: 'TIMESGTY.NS',
    label: 'TIMESGTY.NS,  Times Guaranty Limited',
    name: 'Times Guaranty Limited',
    symbol: 'TIMESGTY.NS',
  },
  {
    value: 'TIMETECHNO.NS',
    label: 'TIMETECHNO.NS,  Time Technoplast Limited',
    name: 'Time Technoplast Limited',
    symbol: 'TIMETECHNO.NS',
  },
  {
    value: 'TIMKEN.NS',
    label: 'TIMKEN.NS,  Timken India Limited',
    name: 'Timken India Limited',
    symbol: 'TIMKEN.NS',
  },
  {
    value: 'TINPLATE.NS',
    label: 'TINPLATE.NS,  The Tinplate Company of India Limited',
    name: 'The Tinplate Company of India Limited',
    symbol: 'TINPLATE.NS',
  },
  {
    value: 'TIPSINDLTD.NS',
    label: 'TIPSINDLTD.NS,  Tips Industries Limited',
    name: 'Tips Industries Limited',
    symbol: 'TIPSINDLTD.NS',
  },
  {
    value: 'TIRUMALCHM.NS',
    label: 'TIRUMALCHM.NS,  Thirumalai Chemicals Limited',
    name: 'Thirumalai Chemicals Limited',
    symbol: 'TIRUMALCHM.NS',
  },
  {
    value: 'TNPETRO.NS',
    label: 'TNPETRO.NS,  Tamilnadu Petroproducts Limited',
    name: 'Tamilnadu Petroproducts Limited',
    symbol: 'TNPETRO.NS',
  },
  {
    value: 'TNPL.NS',
    label: 'TNPL.NS,  Tamil Nadu Newsprint and Papers Limited',
    name: 'Tamil Nadu Newsprint and Papers Limited',
    symbol: 'TNPL.NS',
  },
  {
    value: 'TOKYOPLAST.NS',
    label: 'TOKYOPLAST.NS,  Tokyo Plast International Limited',
    name: 'Tokyo Plast International Limited',
    symbol: 'TOKYOPLAST.NS',
  },
  {
    value: 'TORNTPOWER.NS',
    label: 'TORNTPOWER.NS,  Torrent Power Limited',
    name: 'Torrent Power Limited',
    symbol: 'TORNTPOWER.NS',
  },
  {
    value: 'TPLPLASTEH.NS',
    label: 'TPLPLASTEH.NS,  TPL Plastech Limited',
    name: 'TPL Plastech Limited',
    symbol: 'TPLPLASTEH.NS',
  },
  {
    value: 'TREEHOUSE.NS',
    label: 'TREEHOUSE.NS,  Tree House Education & Accessories Limited',
    name: 'Tree House Education & Accessories Limited',
    symbol: 'TREEHOUSE.NS',
  },
  {
    value: 'TREJHARA.NS',
    label: 'TREJHARA.NS,  Trejhara Solutions Limited',
    name: 'Trejhara Solutions Limited',
    symbol: 'TREJHARA.NS',
  },
  {
    value: 'TRENT.NS',
    label: 'TRENT.NS,  Trent Limited',
    name: 'Trent Limited',
    symbol: 'TRENT.NS',
  },
  {
    value: 'TRF.NS',
    label: 'TRF.NS,  TRF Limited',
    name: 'TRF Limited',
    symbol: 'TRF.NS',
  },
  {
    value: 'TRIDENT.NS',
    label: 'TRIDENT.NS,  Trident Limited',
    name: 'Trident Limited',
    symbol: 'TRIDENT.NS',
  },
  {
    value: 'TRIGYN.NS',
    label: 'TRIGYN.NS,  Trigyn Technologies Limited',
    name: 'Trigyn Technologies Limited',
    symbol: 'TRIGYN.NS',
  },
  {
    value: 'TRIL.NS',
    label: 'TRIL.NS,  Transformers and Rectifiers (India) Limited',
    name: 'Transformers and Rectifiers (India) Limited',
    symbol: 'TRIL.NS',
  },
  {
    value: 'TRITURBINE.NS',
    label: 'TRITURBINE.NS,  Triveni Turbine Limited',
    name: 'Triveni Turbine Limited',
    symbol: 'TRITURBINE.NS',
  },
  {
    value: 'TRIVENI.NS',
    label: 'TRIVENI.NS,  Triveni Engineering & Industries Limited',
    name: 'Triveni Engineering & Industries Limited',
    symbol: 'TRIVENI.NS',
  },
  {
    value: 'TTKHLTCARE.NS',
    label: 'TTKHLTCARE.NS,  TTK Healthcare Limited',
    name: 'TTK Healthcare Limited',
    symbol: 'TTKHLTCARE.NS',
  },
  {
    value: 'TTKPRESTIG.NS',
    label: 'TTKPRESTIG.NS,  TTK Prestige Limited',
    name: 'TTK Prestige Limited',
    symbol: 'TTKPRESTIG.NS',
  },
  {
    value: 'TTL.NS',
    label: 'TTL.NS,  T.T. Limited',
    name: 'T.T. Limited',
    symbol: 'TTL.NS',
  },
  {
    value: 'TTML.NS',
    label: 'TTML.NS,  Tata Teleservices (Maharashtra) Limited',
    name: 'Tata Teleservices (Maharashtra) Limited',
    symbol: 'TTML.NS',
  },
  {
    value: 'TV18BRDCST.NS',
    label: 'TV18BRDCST.NS,  TV18 Broadcast Limited',
    name: 'TV18 Broadcast Limited',
    symbol: 'TV18BRDCST.NS',
  },
  {
    value: 'TVSMOTOR.NS',
    label: 'TVSMOTOR.NS,  TVS Motor Company Limited',
    name: 'TVS Motor Company Limited',
    symbol: 'TVSMOTOR.NS',
  },
  {
    value: 'TVSSRICHAK.NS',
    label: 'TVSSRICHAK.NS,  TVS Srichakra Limited',
    name: 'TVS Srichakra Limited',
    symbol: 'TVSSRICHAK.NS',
  },
  {
    value: 'TVTODAY.NS',
    label: 'TVTODAY.NS,  T.V. Today Network Limited',
    name: 'T.V. Today Network Limited',
    symbol: 'TVTODAY.NS',
  },
  {
    value: 'TVVISION.NS',
    label: 'TVVISION.NS,  TV Vision Limited',
    name: 'TV Vision Limited',
    symbol: 'TVVISION.NS',
  },
  {
    value: 'TWL.NS',
    label: 'TWL.NS,  Titagarh Wagons Limited',
    name: 'Titagarh Wagons Limited',
    symbol: 'TWL.NS',
  },
  {
    value: 'UCALFUEL.NS',
    label: 'UCALFUEL.NS,  Ucal Fuel Systems Limited',
    name: 'Ucal Fuel Systems Limited',
    symbol: 'UCALFUEL.NS',
  },
  {
    value: 'UCOBANK.NS',
    label: 'UCOBANK.NS,  UCO Bank',
    name: 'UCO Bank',
    symbol: 'UCOBANK.NS',
  },
  {
    value: 'UFLEX.NS',
    label: 'UFLEX.NS,  Uflex Limited',
    name: 'Uflex Limited',
    symbol: 'UFLEX.NS',
  },
  {
    value: 'UFO.NS',
    label: 'UFO.NS,  UFO Moviez India Limited',
    name: 'UFO Moviez India Limited',
    symbol: 'UFO.NS',
  },
  {
    value: 'UGARSUGAR.NS',
    label: 'UGARSUGAR.NS,  The Ugar Sugar Works Limited',
    name: 'The Ugar Sugar Works Limited',
    symbol: 'UGARSUGAR.NS',
  },
  {
    value: 'UJAAS.NS',
    label: 'UJAAS.NS,  Ujaas Energy Limited',
    name: 'Ujaas Energy Limited',
    symbol: 'UJAAS.NS',
  },
  {
    value: 'UJJIVAN.NS',
    label: 'UJJIVAN.NS,  Ujjivan Financial Services Limited',
    name: 'Ujjivan Financial Services Limited',
    symbol: 'UJJIVAN.NS',
  },
  {
    value: 'UJJIVANSFB.NS',
    label: 'UJJIVANSFB.NS,  Ujjivan Small Finance Bank Limited',
    name: 'Ujjivan Small Finance Bank Limited',
    symbol: 'UJJIVANSFB.NS',
  },
  {
    value: 'UMANGDAIRY.NS',
    label: 'UMANGDAIRY.NS,  Umang Dairies Limited',
    name: 'Umang Dairies Limited',
    symbol: 'UMANGDAIRY.NS',
  },
  {
    value: 'UNICHEMLAB.NS',
    label: 'UNICHEMLAB.NS,  Unichem Laboratories Limited',
    name: 'Unichem Laboratories Limited',
    symbol: 'UNICHEMLAB.NS',
  },
  {
    value: 'UNIENTER.NS',
    label: 'UNIENTER.NS,  Uniphos Enterprises Limited',
    name: 'Uniphos Enterprises Limited',
    symbol: 'UNIENTER.NS',
  },
  {
    value: 'UNIONBANK.NS',
    label: 'UNIONBANK.NS,  Union Bank of India',
    name: 'Union Bank of India',
    symbol: 'UNIONBANK.NS',
  },
  {
    value: 'UNITEDTEA.NS',
    label: 'UNITEDTEA.NS,  The United Nilgiri Tea Estates Company Limited',
    name: 'The United Nilgiri Tea Estates Company Limited',
    symbol: 'UNITEDTEA.NS',
  },
  {
    value: 'UNIVASTU.NS',
    label: 'UNIVASTU.NS,  Univastu India Limited',
    name: 'Univastu India Limited',
    symbol: 'UNIVASTU.NS',
  },
  {
    value: 'UNIVCABLES.NS',
    label: 'UNIVCABLES.NS,  Universal Cables Limited',
    name: 'Universal Cables Limited',
    symbol: 'UNIVCABLES.NS',
  },
  {
    value: 'UNIVPHOTO.NS',
    label: 'UNIVPHOTO.NS,  Universus Photo Imagings Limited',
    name: 'Universus Photo Imagings Limited',
    symbol: 'UNIVPHOTO.NS',
  },
  {
    value: 'USHAMART.NS',
    label: 'USHAMART.NS,  Usha Martin Limited',
    name: 'Usha Martin Limited',
    symbol: 'USHAMART.NS',
  },
  {
    value: 'UTTAMSTL.NS',
    label: 'UTTAMSTL.NS,  Uttam Galva Steels Limited',
    name: 'Uttam Galva Steels Limited',
    symbol: 'UTTAMSTL.NS',
  },
  {
    value: 'UTTAMSUGAR.NS',
    label: 'UTTAMSUGAR.NS,  Uttam Sugar Mills Limited',
    name: 'Uttam Sugar Mills Limited',
    symbol: 'UTTAMSUGAR.NS',
  },
  {
    value: 'V2RETAIL.NS',
    label: 'V2RETAIL.NS,  V2 Retail Limited',
    name: 'V2 Retail Limited',
    symbol: 'V2RETAIL.NS',
  },
  {
    value: 'VADILALIND.NS',
    label: 'VADILALIND.NS,  Vadilal Industries Limited',
    name: 'Vadilal Industries Limited',
    symbol: 'VADILALIND.NS',
  },
  {
    value: 'VAIBHAVGBL.NS',
    label: 'VAIBHAVGBL.NS,  Vaibhav Global Limited',
    name: 'Vaibhav Global Limited',
    symbol: 'VAIBHAVGBL.NS',
  },
  {
    value: 'VAISHALI.NS',
    label: 'VAISHALI.NS,  Vaishali Pharma Limited',
    name: 'Vaishali Pharma Limited',
    symbol: 'VAISHALI.NS',
  },
  {
    value: 'VAKRANGEE.NS',
    label: 'VAKRANGEE.NS,  Vakrangee Limited',
    name: 'Vakrangee Limited',
    symbol: 'VAKRANGEE.NS',
  },
  {
    value: 'VARDHACRLC.NS',
    label: 'VARDHACRLC.NS,  Vardhman Acrylics Limited',
    name: 'Vardhman Acrylics Limited',
    symbol: 'VARDHACRLC.NS',
  },
  {
    value: 'VARROC.NS',
    label: 'VARROC.NS,  Varroc Engineering Limited',
    name: 'Varroc Engineering Limited',
    symbol: 'VARROC.NS',
  },
  {
    value: 'VASCONEQ.NS',
    label: 'VASCONEQ.NS,  Vascon Engineers Limited',
    name: 'Vascon Engineers Limited',
    symbol: 'VASCONEQ.NS',
  },
  {
    value: 'VBL.NS',
    label: 'VBL.NS,  Varun Beverages Limited',
    name: 'Varun Beverages Limited',
    symbol: 'VBL.NS',
  },
  {
    value: 'VENKEYS.NS',
    label: "VENKEYS.NS,  Venky's (India) Limited",
    name: "Venky's (India) Limited",
    symbol: 'VENKEYS.NS',
  },
  {
    value: 'VERTOZ.NS',
    label: 'VERTOZ.NS,  Vertoz Advertising Limited',
    name: 'Vertoz Advertising Limited',
    symbol: 'VERTOZ.NS',
  },
  {
    value: 'VESUVIUS.NS',
    label: 'VESUVIUS.NS,  Vesuvius India Limited',
    name: 'Vesuvius India Limited',
    symbol: 'VESUVIUS.NS',
  },
  {
    value: 'VETO.NS',
    label: 'VETO.NS,  Veto Switchgears and Cables Limited',
    name: 'Veto Switchgears and Cables Limited',
    symbol: 'VETO.NS',
  },
  {
    value: 'VGUARD.NS',
    label: 'VGUARD.NS,  V-Guard Industries Limited',
    name: 'V-Guard Industries Limited',
    symbol: 'VGUARD.NS',
  },
  {
    value: 'VHL.NS',
    label: 'VHL.NS,  Vardhman Holdings Limited',
    name: 'Vardhman Holdings Limited',
    symbol: 'VHL.NS',
  },
  {
    value: 'VIDHIING.NS',
    label: 'VIDHIING.NS,  Vidhi Specialty Food Ingredients Limited',
    name: 'Vidhi Specialty Food Ingredients Limited',
    symbol: 'VIDHIING.NS',
  },
  {
    value: 'VIJIFIN.NS',
    label: 'VIJIFIN.NS,  Viji Finance Limited',
    name: 'Viji Finance Limited',
    symbol: 'VIJIFIN.NS',
  },
  {
    value: 'VIKASECO.NS',
    label: 'VIKASECO.NS,  Vikas Ecotech Limited',
    name: 'Vikas Ecotech Limited',
    symbol: 'VIKASECO.NS',
  },
  {
    value: 'VIKASPROP.NS',
    label: 'VIKASPROP.NS,  Vikas Proppant & Granite Limited',
    name: 'Vikas Proppant & Granite Limited',
    symbol: 'VIKASPROP.NS',
  },
  {
    value: 'VIKASWSP.NS',
    label: 'VIKASWSP.NS,  Vikas WSP Limited',
    name: 'Vikas WSP Limited',
    symbol: 'VIKASWSP.NS',
  },
  {
    value: 'VIMTALABS.NS',
    label: 'VIMTALABS.NS,  Vimta Labs Limited',
    name: 'Vimta Labs Limited',
    symbol: 'VIMTALABS.NS',
  },
  {
    value: 'VINATIORGA.NS',
    label: 'VINATIORGA.NS,  Vinati Organics Limited',
    name: 'Vinati Organics Limited',
    symbol: 'VINATIORGA.NS',
  },
  {
    value: 'VINDHYATEL.NS',
    label: 'VINDHYATEL.NS,  Vindhya Telelinks Limited',
    name: 'Vindhya Telelinks Limited',
    symbol: 'VINDHYATEL.NS',
  },
  {
    value: 'VINYLINDIA.NS',
    label: 'VINYLINDIA.NS,  Vinyl Chemicals (India) Limited',
    name: 'Vinyl Chemicals (India) Limited',
    symbol: 'VINYLINDIA.NS',
  },
  {
    value: 'VIPIND.NS',
    label: 'VIPIND.NS,  V.I.P. Industries Limited',
    name: 'V.I.P. Industries Limited',
    symbol: 'VIPIND.NS',
  },
  {
    value: 'VIPULLTD.NS',
    label: 'VIPULLTD.NS,  Vipul Limited',
    name: 'Vipul Limited',
    symbol: 'VIPULLTD.NS',
  },
  {
    value: 'VISAKAIND.NS',
    label: 'VISAKAIND.NS,  Visaka Industries Limited',
    name: 'Visaka Industries Limited',
    symbol: 'VISAKAIND.NS',
  },
  {
    value: 'VISASTEEL.NS',
    label: 'VISASTEEL.NS,  VISA Steel Limited',
    name: 'VISA Steel Limited',
    symbol: 'VISASTEEL.NS',
  },
  {
    value: 'VISHNU.NS',
    label: 'VISHNU.NS,  Vishnu Chemicals Limited',
    name: 'Vishnu Chemicals Limited',
    symbol: 'VISHNU.NS',
  },
  {
    value: 'VISHWARAJ.NS',
    label: 'VISHWARAJ.NS,  Vishwaraj Sugar Industries Limited',
    name: 'Vishwaraj Sugar Industries Limited',
    symbol: 'VISHWARAJ.NS',
  },
  {
    value: 'VLSFINANCE.NS',
    label: 'VLSFINANCE.NS,  VLS Finance Limited',
    name: 'VLS Finance Limited',
    symbol: 'VLSFINANCE.NS',
  },
  {
    value: 'VMART.NS',
    label: 'VMART.NS,  V-Mart Retail Limited',
    name: 'V-Mart Retail Limited',
    symbol: 'VMART.NS',
  },
  {
    value: 'VOLTAMP.NS',
    label: 'VOLTAMP.NS,  Voltamp Transformers Limited',
    name: 'Voltamp Transformers Limited',
    symbol: 'VOLTAMP.NS',
  },
  {
    value: 'VOLTAS.NS',
    label: 'VOLTAS.NS,  Voltas Limited',
    name: 'Voltas Limited',
    symbol: 'VOLTAS.NS',
  },
  {
    value: 'VRLLOG.NS',
    label: 'VRLLOG.NS,  VRL Logistics Limited',
    name: 'VRL Logistics Limited',
    symbol: 'VRLLOG.NS',
  },
  {
    value: 'VSTIND.NS',
    label: 'VSTIND.NS,  VST Industries Limited',
    name: 'VST Industries Limited',
    symbol: 'VSTIND.NS',
  },
  {
    value: 'VSTTILLERS.NS',
    label: 'VSTTILLERS.NS,  V.S.T. Tillers Tractors Limited',
    name: 'V.S.T. Tillers Tractors Limited',
    symbol: 'VSTTILLERS.NS',
  },
  {
    value: 'VTL.NS',
    label: 'VTL.NS,  Vardhman Textiles Limited',
    name: 'Vardhman Textiles Limited',
    symbol: 'VTL.NS',
  },
  {
    value: 'WABAG.NS',
    label: 'WABAG.NS,  VA Tech Wabag Limited',
    name: 'VA Tech Wabag Limited',
    symbol: 'WABAG.NS',
  },
  {
    value: 'WALCHANNAG.NS',
    label: 'WALCHANNAG.NS,  Walchandnagar Industries Limited',
    name: 'Walchandnagar Industries Limited',
    symbol: 'WALCHANNAG.NS',
  },
  {
    value: 'WATERBASE.NS',
    label: 'WATERBASE.NS,  The Waterbase Limited',
    name: 'The Waterbase Limited',
    symbol: 'WATERBASE.NS',
  },
  {
    value: 'WEBELSOLAR.NS',
    label: 'WEBELSOLAR.NS,  Websol Energy System Limited',
    name: 'Websol Energy System Limited',
    symbol: 'WEBELSOLAR.NS',
  },
  {
    value: 'WEIZMANIND.NS',
    label: 'WEIZMANIND.NS,  Weizmann Limited',
    name: 'Weizmann Limited',
    symbol: 'WEIZMANIND.NS',
  },
  {
    value: 'WELCORP.NS',
    label: 'WELCORP.NS,  Welspun Corp Limited',
    name: 'Welspun Corp Limited',
    symbol: 'WELCORP.NS',
  },
  {
    value: 'WELENT.NS',
    label: 'WELENT.NS,  Welspun Enterprises Limited',
    name: 'Welspun Enterprises Limited',
    symbol: 'WELENT.NS',
  },
  {
    value: 'WELINV.NS',
    label: 'WELINV.NS,  Welspun Investments and Commercials Limited',
    name: 'Welspun Investments and Commercials Limited',
    symbol: 'WELINV.NS',
  },
  {
    value: 'WELSPUNIND.NS',
    label: 'WELSPUNIND.NS,  Welspun India Limited',
    name: 'Welspun India Limited',
    symbol: 'WELSPUNIND.NS',
  },
  {
    value: 'WENDT.NS',
    label: 'WENDT.NS,  Wendt (India) Limited',
    name: 'Wendt (India) Limited',
    symbol: 'WENDT.NS',
  },
  {
    value: 'WESTLIFE.NS',
    label: 'WESTLIFE.NS,  Westlife Foodworld Limited',
    name: 'Westlife Foodworld Limited',
    symbol: 'WESTLIFE.NS',
  },
  {
    value: 'WHEELS.NS',
    label: 'WHEELS.NS,  Wheels India Limited',
    name: 'Wheels India Limited',
    symbol: 'WHEELS.NS',
  },
  {
    value: 'WHIRLPOOL.NS',
    label: 'WHIRLPOOL.NS,  Whirlpool of India Limited',
    name: 'Whirlpool of India Limited',
    symbol: 'WHIRLPOOL.NS',
  },
  {
    value: 'WILLAMAGOR.NS',
    label: 'WILLAMAGOR.NS,  Williamson Magor & Co. Limited',
    name: 'Williamson Magor & Co. Limited',
    symbol: 'WILLAMAGOR.NS',
  },
  {
    value: 'WINDMACHIN.NS',
    label: 'WINDMACHIN.NS,  Windsor Machines Limited',
    name: 'Windsor Machines Limited',
    symbol: 'WINDMACHIN.NS',
  },
  {
    value: 'WOCKPHARMA.NS',
    label: 'WOCKPHARMA.NS,  Wockhardt Limited',
    name: 'Wockhardt Limited',
    symbol: 'WOCKPHARMA.NS',
  },
  {
    value: 'WONDERLA.NS',
    label: 'WONDERLA.NS,  Wonderla Holidays Limited',
    name: 'Wonderla Holidays Limited',
    symbol: 'WONDERLA.NS',
  },
  {
    value: 'WSTCSTPAPR.NS',
    label: 'WSTCSTPAPR.NS,  West Coast Paper Mills Limited',
    name: 'West Coast Paper Mills Limited',
    symbol: 'WSTCSTPAPR.NS',
  },
  {
    value: 'XCHANGING.NS',
    label: 'XCHANGING.NS,  Xchanging Solutions Limited',
    name: 'Xchanging Solutions Limited',
    symbol: 'XCHANGING.NS',
  },
  {
    value: 'XPROINDIA.NS',
    label: 'XPROINDIA.NS,  Xpro India Limited',
    name: 'Xpro India Limited',
    symbol: 'XPROINDIA.NS',
  },
  {
    value: 'YESBANK.NS',
    label: 'YESBANK.NS,  Yes Bank Limited',
    name: 'Yes Bank Limited',
    symbol: 'YESBANK.NS',
  },
  {
    value: 'ZEELEARN.NS',
    label: 'ZEELEARN.NS,  Zee Learn Limited',
    name: 'Zee Learn Limited',
    symbol: 'ZEELEARN.NS',
  },
  {
    value: 'ZENITHEXPO.NS',
    label: 'ZENITHEXPO.NS,  Zenith Exports Limited',
    name: 'Zenith Exports Limited',
    symbol: 'ZENITHEXPO.NS',
  },
  {
    value: 'ZENSARTECH.NS',
    label: 'ZENSARTECH.NS,  Zensar Technologies Limited',
    name: 'Zensar Technologies Limited',
    symbol: 'ZENSARTECH.NS',
  },
  {
    value: 'ZENTEC.NS',
    label: 'ZENTEC.NS,  Zen Technologies Limited',
    name: 'Zen Technologies Limited',
    symbol: 'ZENTEC.NS',
  },
  {
    value: 'ZODIACLOTH.NS',
    label: 'ZODIACLOTH.NS,  Zodiac Clothing Company Limited',
    name: 'Zodiac Clothing Company Limited',
    symbol: 'ZODIACLOTH.NS',
  },
  {
    value: 'ZOTA.NS',
    label: 'ZOTA.NS,  Zota Health Care Limited',
    name: 'Zota Health Care Limited',
    symbol: 'ZOTA.NS',
  },
  {
    value: 'ZUARI.NS',
    label: 'ZUARI.NS,  Zuari Agro Chemicals Limited',
    name: 'Zuari Agro Chemicals Limited',
    symbol: 'ZUARI.NS',
  },
  {
    value: 'ZYDUSWELL.NS',
    label: 'ZYDUSWELL.NS,  Zydus Wellness Limited',
    name: 'Zydus Wellness Limited',
    symbol: 'ZYDUSWELL.NS',
  },
  {
    value: '63MOONS.NS',
    label: '63MOONS.NS,  63 Moons Technologies Ltd',
    name: '63 Moons Technologies Ltd',
    symbol: '63MOONS.NS',
  },
  {
    value: 'AARVEEDEN.NS',
    label: 'AARVEEDEN.NS,  Aarvee Denims and Exports Ltd.',
    name: 'Aarvee Denims and Exports Ltd.',
    symbol: 'AARVEEDEN.NS',
  },
  {
    value: 'AGRITECH.NS',
    label: 'AGRITECH.NS,  Agri-tech (India) Limited',
    name: 'Agri-tech (India) Limited',
    symbol: 'AGRITECH.NS',
  },
  {
    value: 'AIRAN.NS',
    label: 'AIRAN.NS,  Airan Limited',
    name: 'Airan Limited',
    symbol: 'AIRAN.NS',
  },
  {
    value: 'AKASH.NS',
    label: 'AKASH.NS,  Akash Infra-Projects Limited',
    name: 'Akash Infra-Projects Limited',
    symbol: 'AKASH.NS',
  },
  {
    value: 'AKSHOPTFBR.NS',
    label: 'AKSHOPTFBR.NS,  Aksh Optifibre Limited',
    name: 'Aksh Optifibre Limited',
    symbol: 'AKSHOPTFBR.NS',
  },
  {
    value: 'ALPSINDUS.NS',
    label: 'ALPSINDUS.NS,  Alps Industries Limited',
    name: 'Alps Industries Limited',
    symbol: 'ALPSINDUS.NS',
  },
  {
    value: 'ANDHRACEMT.NS',
    label: 'ANDHRACEMT.NS,  Andhra Cements Limited',
    name: 'Andhra Cements Limited',
    symbol: 'ANDHRACEMT.NS',
  },
  {
    value: 'ANKITMETAL.NS',
    label: 'ANKITMETAL.NS,  Ankit Metal & Power Limited',
    name: 'Ankit Metal & Power Limited',
    symbol: 'ANKITMETAL.NS',
  },
  {
    value: 'ANSALAPI.NS',
    label: 'ANSALAPI.NS,  Ansal Properties & Infrastructure Limited',
    name: 'Ansal Properties & Infrastructure Limited',
    symbol: 'ANSALAPI.NS',
  },
  {
    value: 'ARIHANTSUP.NS',
    label: 'ARIHANTSUP.NS,  Arihant Superstructures Limited',
    name: 'Arihant Superstructures Limited',
    symbol: 'ARIHANTSUP.NS',
  },
  {
    value: 'ARROWGREEN.NS',
    label: 'ARROWGREEN.NS,  Arrow Greentech Limited',
    name: 'Arrow Greentech Limited',
    symbol: 'ARROWGREEN.NS',
  },
  {
    value: 'ARSHIYA.NS',
    label: 'ARSHIYA.NS,  Arshiya Limited',
    name: 'Arshiya Limited',
    symbol: 'ARSHIYA.NS',
  },
  {
    value: 'ASAL.NS',
    label: 'ASAL.NS,  Automotive Stampings and Assemblies Limited',
    name: 'Automotive Stampings and Assemblies Limited',
    symbol: 'ASAL.NS',
  },
  {
    value: 'AUTOIND.NS',
    label: 'AUTOIND.NS,  Autoline Industries Limited',
    name: 'Autoline Industries Limited',
    symbol: 'AUTOIND.NS',
  },
  {
    value: 'BAFNAPH.NS',
    label: 'BAFNAPH.NS,  Bafna Pharmaceuticals Limited',
    name: 'Bafna Pharmaceuticals Limited',
    symbol: 'BAFNAPH.NS',
  },
  {
    value: 'BAGFILMS.NS',
    label: 'BAGFILMS.NS,  B.A.G. Films and Media Limited',
    name: 'B.A.G. Films and Media Limited',
    symbol: 'BAGFILMS.NS',
  },
  {
    value: 'BALLARPUR.NS',
    label: 'BALLARPUR.NS,  Ballarpur Industries Limited',
    name: 'Ballarpur Industries Limited',
    symbol: 'BALLARPUR.NS',
  },
  {
    value: 'BCG.NS',
    label: 'BCG.NS,  Brightcom Group Limited',
    name: 'Brightcom Group Limited',
    symbol: 'BCG.NS',
  },
  {
    value: 'BEARDSELL.NS',
    label: 'BEARDSELL.NS,  Beardsell Limited',
    name: 'Beardsell Limited',
    symbol: 'BEARDSELL.NS',
  },
  {
    value: 'BIOFILCHEM.NS',
    label: 'BIOFILCHEM.NS,  Biofil Chemicals and Pharmaceuticals Limited',
    name: 'Biofil Chemicals and Pharmaceuticals Limited',
    symbol: 'BIOFILCHEM.NS',
  },
  {
    value: 'BKMINDST.NS',
    label: 'BKMINDST.NS,  BKM Industries Limited',
    name: 'BKM Industries Limited',
    symbol: 'BKMINDST.NS',
  },
  {
    value: 'BLUECHIP.NS',
    label: 'BLUECHIP.NS,  Blue Chip India Limited',
    name: 'Blue Chip India Limited',
    symbol: 'BLUECHIP.NS',
  },
  {
    value: 'BROOKS.NS',
    label: 'BROOKS.NS,  Brooks Laboratories Limited',
    name: 'Brooks Laboratories Limited',
    symbol: 'BROOKS.NS',
  },
  {
    value: 'BURNPUR.NS',
    label: 'BURNPUR.NS,  Burnpur Cement Limited',
    name: 'Burnpur Cement Limited',
    symbol: 'BURNPUR.NS',
  },
  {
    value: 'BVCL.NS',
    label: 'BVCL.NS,  Barak Valley Cements Limited',
    name: 'Barak Valley Cements Limited',
    symbol: 'BVCL.NS',
  },
  {
    value: 'CCCL.NS',
    label: 'CCCL.NS,  Consolidated Construction Consortium Limited',
    name: 'Consolidated Construction Consortium Limited',
    symbol: 'CCCL.NS',
  },
  {
    value: 'CCHHL.NS',
    label: 'CCHHL.NS,  Country Club Hospitality & Holidays Limited',
    name: 'Country Club Hospitality & Holidays Limited',
    symbol: 'CCHHL.NS',
  },
  {
    value: 'COMPUSOFT.NS',
    label: 'COMPUSOFT.NS,  Compucom Software Limited',
    name: 'Compucom Software Limited',
    symbol: 'COMPUSOFT.NS',
  },
  {
    value: 'DCMFINSERV.NS',
    label: 'DCMFINSERV.NS,  DCM Financial Services Limited',
    name: 'DCM Financial Services Limited',
    symbol: 'DCMFINSERV.NS',
  },
  {
    value: 'DHARSUGAR.NS',
    label: 'DHARSUGAR.NS,  Dharani Sugars and Chemicals Limited',
    name: 'Dharani Sugars and Chemicals Limited',
    symbol: 'DHARSUGAR.NS',
  },
  {
    value: 'DIGISPICE.NS',
    label: 'DIGISPICE.NS,  DiGiSPICE Technologies Limited',
    name: 'DiGiSPICE Technologies Limited',
    symbol: 'DIGISPICE.NS',
  },
  {
    value: 'DNAMEDIA.NS',
    label: 'DNAMEDIA.NS,  Diligent Media Corporation Limited',
    name: 'Diligent Media Corporation Limited',
    symbol: 'DNAMEDIA.NS',
  },
  {
    value: 'DPWIRES.NS',
    label: 'DPWIRES.NS,  D.P. Wires Limited',
    name: 'D.P. Wires Limited',
    symbol: 'DPWIRES.NS',
  },
  {
    value: 'FCSSOFT.NS',
    label: 'FCSSOFT.NS,  FCS Software Solutions Limited',
    name: 'FCS Software Solutions Limited',
    symbol: 'FCSSOFT.NS',
  },
  {
    value: 'FLEXITUFF.NS',
    label: 'FLEXITUFF.NS,  Flexituff Ventures International Limited',
    name: 'Flexituff Ventures International Limited',
    symbol: 'FLEXITUFF.NS',
  },
  {
    value: 'GAL.NS',
    label: 'GAL.NS,  Gyscoal Alloys Limited',
    name: 'Gyscoal Alloys Limited',
    symbol: 'GAL.NS',
  },
  {
    value: 'GAYAHWS.NS',
    label: 'GAYAHWS.NS,  Gayatri Highways Limited',
    name: 'Gayatri Highways Limited',
    symbol: 'GAYAHWS.NS',
  },
  {
    value: 'GAYAPROJ.NS',
    label: 'GAYAPROJ.NS,  Gayatri Projects Limited',
    name: 'Gayatri Projects Limited',
    symbol: 'GAYAPROJ.NS',
  },
  {
    value: 'GFSTEELS.NS',
    label: 'GFSTEELS.NS,  Grand Foundry Limited',
    name: 'Grand Foundry Limited',
    symbol: 'GFSTEELS.NS',
  },
  {
    value: 'GILLANDERS.NS',
    label: 'GILLANDERS.NS,  Gillanders Arbuthnot and Company Limited',
    name: 'Gillanders Arbuthnot and Company Limited',
    symbol: 'GILLANDERS.NS',
  },
  {
    value: 'GLFL.NS',
    label: 'GLFL.NS,  Gujarat Lease Financing Limited',
    name: 'Gujarat Lease Financing Limited',
    symbol: 'GLFL.NS',
  },
  {
    value: 'GTL.NS',
    label: 'GTL.NS,  GTL Limited',
    name: 'GTL Limited',
    symbol: 'GTL.NS',
  },
  {
    value: 'GUJRAFFIA.NS',
    label: 'GUJRAFFIA.NS,  Gujarat Raffia Industries Limited',
    name: 'Gujarat Raffia Industries Limited',
    symbol: 'GUJRAFFIA.NS',
  },
  {
    value: 'HAVISHA.NS',
    label: 'HAVISHA.NS,  Sri Havisha Hospitality and Infrastructure Limited',
    name: 'Sri Havisha Hospitality and Infrastructure Limited',
    symbol: 'HAVISHA.NS',
  },
  {
    value: 'HCL-INSYS.NS',
    label: 'HCL-INSYS.NS,  HCL Infosystems Limited',
    name: 'HCL Infosystems Limited',
    symbol: 'HCL-INSYS.NS',
  },
  {
    value: 'HEXATRADEX.NS',
    label: 'HEXATRADEX.NS,  Hexa Tradex Limited',
    name: 'Hexa Tradex Limited',
    symbol: 'HEXATRADEX.NS',
  },
  {
    value: 'HINDNATGLS.NS',
    label: 'HINDNATGLS.NS,  Hindusthan National Glass & Industries Limited',
    name: 'Hindusthan National Glass & Industries Limited',
    symbol: 'HINDNATGLS.NS',
  },
  {
    value: 'IMPEXFERRO.NS',
    label: 'IMPEXFERRO.NS,  Impex Ferro Tech Limited',
    name: 'Impex Ferro Tech Limited',
    symbol: 'IMPEXFERRO.NS',
  },
  {
    value: 'INDLMETER.NS',
    label: 'INDLMETER.NS,  IMP Powers Limited',
    name: 'IMP Powers Limited',
    symbol: 'INDLMETER.NS',
  },
  {
    value: 'INDOTHAI.NS',
    label: 'INDOTHAI.NS,  Indo Thai Securities Limited',
    name: 'Indo Thai Securities Limited',
    symbol: 'INDOTHAI.NS',
  },
  {
    value: 'INDOWIND.NS',
    label: 'INDOWIND.NS,  Indowind Energy Limited',
    name: 'Indowind Energy Limited',
    symbol: 'INDOWIND.NS',
  },
  {
    value: 'INDSWFTLTD.NS',
    label: 'INDSWFTLTD.NS,  Ind-Swift Limited',
    name: 'Ind-Swift Limited',
    symbol: 'INDSWFTLTD.NS',
  },
  {
    value: 'INFOMEDIA.NS',
    label: 'INFOMEDIA.NS,  Infomedia Press Limited',
    name: 'Infomedia Press Limited',
    symbol: 'INFOMEDIA.NS',
  },
  {
    value: 'ISFT.NS',
    label: 'ISFT.NS,  IntraSoft Technologies Limited',
    name: 'IntraSoft Technologies Limited',
    symbol: 'ISFT.NS',
  },
  {
    value: 'ISMTLTD.NS',
    label: 'ISMTLTD.NS,  ISMT Limited',
    name: 'ISMT Limited',
    symbol: 'ISMTLTD.NS',
  },
  {
    value: 'IVC.NS',
    label: 'IVC.NS,  IL&FS Investment Managers Limited',
    name: 'IL&FS Investment Managers Limited',
    symbol: 'IVC.NS',
  },
  {
    value: 'JAYNECOIND.NS',
    label: 'JAYNECOIND.NS,  Jayaswal Neco Industries Limited',
    name: 'Jayaswal Neco Industries Limited',
    symbol: 'JAYNECOIND.NS',
  },
  {
    value: 'JBFIND.NS',
    label: 'JBFIND.NS,  JBF Industries Limited',
    name: 'JBF Industries Limited',
    symbol: 'JBFIND.NS',
  },
  {
    value: 'JHS.NS',
    label: 'JHS.NS,  JHS Svendgaard Laboratories Limited',
    name: 'JHS Svendgaard Laboratories Limited',
    symbol: 'JHS.NS',
  },
  {
    value: 'JIKIND.NS',
    label: 'JIKIND.NS,  JIK Industries Limited',
    name: 'JIK Industries Limited',
    symbol: 'JIKIND.NS',
  },
  {
    value: 'JINDRILL.NS',
    label: 'JINDRILL.NS,  Jindal Drilling & Industries Limited',
    name: 'Jindal Drilling & Industries Limited',
    symbol: 'JINDRILL.NS',
  },
  {
    value: 'JISLJALEQS.NS',
    label: 'JISLJALEQS.NS,  Jain Irrigation Systems Limited',
    name: 'Jain Irrigation Systems Limited',
    symbol: 'JISLJALEQS.NS',
  },
  {
    value: 'JITFINFRA.NS',
    label: 'JITFINFRA.NS,  JITF Infralogistics Limited',
    name: 'JITF Infralogistics Limited',
    symbol: 'JITFINFRA.NS',
  },
  {
    value: 'JPINFRATEC.NS',
    label: 'JPINFRATEC.NS,  Jaypee Infratech Limited',
    name: 'Jaypee Infratech Limited',
    symbol: 'JPINFRATEC.NS',
  },
  {
    value: 'JPOLYINVST.NS',
    label: 'JPOLYINVST.NS,  Jindal Poly Investment and Finance Company Limited',
    name: 'Jindal Poly Investment and Finance Company Limited',
    symbol: 'JPOLYINVST.NS',
  },
  {
    value: 'KALYANIFRG.NS',
    label: 'KALYANIFRG.NS,  Kalyani Forge Limited',
    name: 'Kalyani Forge Limited',
    symbol: 'KALYANIFRG.NS',
  },
  {
    value: 'KAMDHENU.NS',
    label: 'KAMDHENU.NS,  Kamdhenu Limited',
    name: 'Kamdhenu Limited',
    symbol: 'KAMDHENU.NS',
  },
  {
    value: 'KANANIIND.NS',
    label: 'KANANIIND.NS,  Kanani Industries Limited',
    name: 'Kanani Industries Limited',
    symbol: 'KANANIIND.NS',
  },
  {
    value: 'KAUSHALYA.NS',
    label:
      'KAUSHALYA.NS,  Kaushalya Infrastructure Development Corporation Limited',
    name: 'Kaushalya Infrastructure Development Corporation Limited',
    symbol: 'KAUSHALYA.NS',
  },
  {
    value: 'KAYA.NS',
    label: 'KAYA.NS,  Kaya Limited',
    name: 'Kaya Limited',
    symbol: 'KAYA.NS',
  },
  {
    value: 'KDDL.NS',
    label: 'KDDL.NS,  KDDL Limited',
    name: 'KDDL Limited',
    symbol: 'KDDL.NS',
  },
  {
    value: 'KELLTONTEC.NS',
    label: 'KELLTONTEC.NS,  Kellton Tech Solutions Limited',
    name: 'Kellton Tech Solutions Limited',
    symbol: 'KELLTONTEC.NS',
  },
  {
    value: 'KERNEX.NS',
    label: 'KERNEX.NS,  Kernex Microsystems (India) Limited',
    name: 'Kernex Microsystems (India) Limited',
    symbol: 'KERNEX.NS',
  },
  {
    value: 'KHADIM.NS',
    label: 'KHADIM.NS,  Khadim India Limited',
    name: 'Khadim India Limited',
    symbol: 'KHADIM.NS',
  },
  {
    value: 'KILITCH.NS',
    label: 'KILITCH.NS,  Kilitch Drugs (India) Limited',
    name: 'Kilitch Drugs (India) Limited',
    symbol: 'KILITCH.NS',
  },
  {
    value: 'KRIDHANINF.NS',
    label: 'KRIDHANINF.NS,  Kridhan Infra Limited',
    name: 'Kridhan Infra Limited',
    symbol: 'KRIDHANINF.NS',
  },
  {
    value: 'KRISHANA.NS',
    label: 'KRISHANA.NS,  Krishana Phoschem Limited',
    name: 'Krishana Phoschem Limited',
    symbol: 'KRISHANA.NS',
  },
  {
    value: 'LPDC.NS',
    label: 'LPDC.NS,  Landmark Property Development Company Limited',
    name: 'Landmark Property Development Company Limited',
    symbol: 'LPDC.NS',
  },
  {
    value: 'MADHUCON.NS',
    label: 'MADHUCON.NS,  Madhucon Projects Limited',
    name: 'Madhucon Projects Limited',
    symbol: 'MADHUCON.NS',
  },
  {
    value: 'MAHAPEXLTD.NS',
    label: 'MAHAPEXLTD.NS,  Maha Rashtra Apex Corporation Limited',
    name: 'Maha Rashtra Apex Corporation Limited',
    symbol: 'MAHAPEXLTD.NS',
  },
  {
    value: 'MANGALAM.NS',
    label: 'MANGALAM.NS,  Mangalam Drugs & Organics Limited',
    name: 'Mangalam Drugs & Organics Limited',
    symbol: 'MANGALAM.NS',
  },
  {
    value: 'MANUGRAPH.NS',
    label: 'MANUGRAPH.NS,  Manugraph India Limited',
    name: 'Manugraph India Limited',
    symbol: 'MANUGRAPH.NS',
  },
  {
    value: 'MASKINVEST.NS',
    label: 'MASKINVEST.NS,  Mask Investments Limited',
    name: 'Mask Investments Limited',
    symbol: 'MASKINVEST.NS',
  },
  {
    value: 'MAWANASUG.NS',
    label: 'MAWANASUG.NS,  Mawana Sugars Limited',
    name: 'Mawana Sugars Limited',
    symbol: 'MAWANASUG.NS',
  },
  {
    value: 'MBAPL.NS',
    label: 'MBAPL.NS,  Madhya Bharat Agro Products Limited',
    name: 'Madhya Bharat Agro Products Limited',
    symbol: 'MBAPL.NS',
  },
  {
    value: 'MBECL.NS',
    label: 'MBECL.NS,  McNally Bharat Engineering Company Limited',
    name: 'McNally Bharat Engineering Company Limited',
    symbol: 'MBECL.NS',
  },
  {
    value: 'MBLINFRA.NS',
    label: 'MBLINFRA.NS,  MBL Infrastructures Limited',
    name: 'MBL Infrastructures Limited',
    symbol: 'MBLINFRA.NS',
  },
  {
    value: 'MCLEODRUSS.NS',
    label: 'MCLEODRUSS.NS,  McLeod Russel India Limited',
    name: 'McLeod Russel India Limited',
    symbol: 'MCLEODRUSS.NS',
  },
  {
    value: 'MEP.NS',
    label: 'MEP.NS,  MEP Infrastructure Developers Limited',
    name: 'MEP Infrastructure Developers Limited',
    symbol: 'MEP.NS',
  },
  {
    value: 'MERCATOR.NS',
    label: 'MERCATOR.NS,  Mercator Limited',
    name: 'Mercator Limited',
    symbol: 'MERCATOR.NS',
  },
  {
    value: 'METALFORGE.NS',
    label: 'METALFORGE.NS,  Metalyst Forgings Limited',
    name: 'Metalyst Forgings Limited',
    symbol: 'METALFORGE.NS',
  },
  {
    value: 'MIRCELECTR.NS',
    label: 'MIRCELECTR.NS,  MIRC Electronics Limited',
    name: 'MIRC Electronics Limited',
    symbol: 'MIRCELECTR.NS',
  },
  {
    value: 'MODIRUBBER.NS',
    label: 'MODIRUBBER.NS,  Modi Rubber Limited',
    name: 'Modi Rubber Limited',
    symbol: 'MODIRUBBER.NS',
  },
  {
    value: 'MOHOTAIND.NS',
    label: 'MOHOTAIND.NS,  Mohota Industries Limited',
    name: 'Mohota Industries Limited',
    symbol: 'MOHOTAIND.NS',
  },
  {
    value: 'MOTOGENFIN.NS',
    label: 'MOTOGENFIN.NS,  The Motor & General Finance Limited',
    name: 'The Motor & General Finance Limited',
    symbol: 'MOTOGENFIN.NS',
  },
  {
    value: 'MSPL.NS',
    label: 'MSPL.NS,  MSP Steel & Power Limited',
    name: 'MSP Steel & Power Limited',
    symbol: 'MSPL.NS',
  },
  {
    value: 'MUKTAARTS.NS',
    label: 'MUKTAARTS.NS,  Mukta Arts Limited',
    name: 'Mukta Arts Limited',
    symbol: 'MUKTAARTS.NS',
  },
  {
    value: 'NAGAFERT.NS',
    label: 'NAGAFERT.NS,  Nagarjuna Fertilizers and Chemicals Limited',
    name: 'Nagarjuna Fertilizers and Chemicals Limited',
    symbol: 'NAGAFERT.NS',
  },
  {
    value: 'NATNLSTEEL.NS',
    label: 'NATNLSTEEL.NS,  National Steel and Agro Industries Limited',
    name: 'National Steel and Agro Industries Limited',
    symbol: 'NATNLSTEEL.NS',
  },
  {
    value: 'NDTV.NS',
    label: 'NDTV.NS,  New Delhi Television Limited',
    name: 'New Delhi Television Limited',
    symbol: 'NDTV.NS',
  },
  {
    value: 'NEXTMEDIA.NS',
    label: 'NEXTMEDIA.NS,  Next Mediaworks Limited',
    name: 'Next Mediaworks Limited',
    symbol: 'NEXTMEDIA.NS',
  },
  {
    value: 'NKIND.NS',
    label: 'NKIND.NS,  N.K Industries Limited',
    name: 'N.K Industries Limited',
    symbol: 'NKIND.NS',
  },
  {
    value: 'NOIDATOLL.NS',
    label: 'NOIDATOLL.NS,  Noida Toll Bridge Company Limited',
    name: 'Noida Toll Bridge Company Limited',
    symbol: 'NOIDATOLL.NS',
  },
  {
    value: 'OILCOUNTUB.NS',
    label: 'OILCOUNTUB.NS,  Oil Country Tubular Limited',
    name: 'Oil Country Tubular Limited',
    symbol: 'OILCOUNTUB.NS',
  },
  {
    value: 'OMKARCHEM.NS',
    label: 'OMKARCHEM.NS,  Omkar Speciality Chemicals Limited',
    name: 'Omkar Speciality Chemicals Limited',
    symbol: 'OMKARCHEM.NS',
  },
  {
    value: 'OPTIEMUS.NS',
    label: 'OPTIEMUS.NS,  Optiemus Infracom Limited',
    name: 'Optiemus Infracom Limited',
    symbol: 'OPTIEMUS.NS',
  },
  {
    value: 'ORIENTALTL.NS',
    label: 'ORIENTALTL.NS,  Oriental Trimex Limited',
    name: 'Oriental Trimex Limited',
    symbol: 'ORIENTALTL.NS',
  },
  {
    value: 'ORIENTHOT.NS',
    label: 'ORIENTHOT.NS,  Oriental Hotels Limited',
    name: 'Oriental Hotels Limited',
    symbol: 'ORIENTHOT.NS',
  },
  {
    value: 'ORIENTLTD.NS',
    label: 'ORIENTLTD.NS,  Orient Press Limited',
    name: 'Orient Press Limited',
    symbol: 'ORIENTLTD.NS',
  },
  {
    value: 'PALASHSECU.NS',
    label: 'PALASHSECU.NS,  Palash Securities Limited',
    name: 'Palash Securities Limited',
    symbol: 'PALASHSECU.NS',
  },
  {
    value: 'PALREDTEC.NS',
    label: 'PALREDTEC.NS,  Palred Technologies Limited',
    name: 'Palred Technologies Limited',
    symbol: 'PALREDTEC.NS',
  },
  {
    value: 'PARSVNATH.NS',
    label: 'PARSVNATH.NS,  Parsvnath Developers Limited',
    name: 'Parsvnath Developers Limited',
    symbol: 'PARSVNATH.NS',
  },
  {
    value: 'PKTEA.NS',
    label: 'PKTEA.NS,  The Peria Karamalai Tea and Produce Company Limited',
    name: 'The Peria Karamalai Tea and Produce Company Limited',
    symbol: 'PKTEA.NS',
  },
  {
    value: 'POKARNA.NS',
    label: 'POKARNA.NS,  Pokarna Limited',
    name: 'Pokarna Limited',
    symbol: 'POKARNA.NS',
  },
  {
    value: 'PRAKASHSTL.NS',
    label: 'PRAKASHSTL.NS,  Prakash Steelage Limited',
    name: 'Prakash Steelage Limited',
    symbol: 'PRAKASHSTL.NS',
  },
  {
    value: 'RAJSREESUG.NS',
    label: 'RAJSREESUG.NS,  Rajshree Sugars and Chemicals Limited',
    name: 'Rajshree Sugars and Chemicals Limited',
    symbol: 'RAJSREESUG.NS',
  },
  {
    value: 'RANASUG.NS',
    label: 'RANASUG.NS,  Rana Sugars Limited',
    name: 'Rana Sugars Limited',
    symbol: 'RANASUG.NS',
  },
  {
    value: 'RCOM.NS',
    label: 'RCOM.NS,  Reliance Communications Limited',
    name: 'Reliance Communications Limited',
    symbol: 'RCOM.NS',
  },
  {
    value: 'RELCAPITAL.NS',
    label: 'RELCAPITAL.NS,  Reliance Capital Limited',
    name: 'Reliance Capital Limited',
    symbol: 'RELCAPITAL.NS',
  },
  {
    value: 'RHFL.NS',
    label: 'RHFL.NS,  Reliance Home Finance Limited',
    name: 'Reliance Home Finance Limited',
    symbol: 'RHFL.NS',
  },
  {
    value: 'RMCL.NS',
    label: 'RMCL.NS,  Radha Madhav Corporation Limited',
    name: 'Radha Madhav Corporation Limited',
    symbol: 'RMCL.NS',
  },
  {
    value: 'RNAVAL.NS',
    label: 'RNAVAL.NS,  Reliance Naval and Engineering Limited',
    name: 'Reliance Naval and Engineering Limited',
    symbol: 'RNAVAL.NS',
  },
  {
    value: 'ROLLT.NS',
    label: 'ROLLT.NS,  Rollatainers Limited',
    name: 'Rollatainers Limited',
    symbol: 'ROLLT.NS',
  },
  {
    value: 'RUCHINFRA.NS',
    label: 'RUCHINFRA.NS,  Ruchi Infrastructure Limited',
    name: 'Ruchi Infrastructure Limited',
    symbol: 'RUCHINFRA.NS',
  },
  {
    value: 'SABEVENTS.NS',
    label: 'SABEVENTS.NS,  SAB Events & Governance Now Media Limited',
    name: 'SAB Events & Governance Now Media Limited',
    symbol: 'SABEVENTS.NS',
  },
  {
    value: 'SABTN.NS',
    label: 'SABTN.NS,  Sri Adhikari Brothers Television Network Limited',
    name: 'Sri Adhikari Brothers Television Network Limited',
    symbol: 'SABTN.NS',
  },
  {
    value: 'SAKHTISUG.NS',
    label: 'SAKHTISUG.NS,  Sakthi Sugars Limited',
    name: 'Sakthi Sugars Limited',
    symbol: 'SAKHTISUG.NS',
  },
  {
    value: 'SAKUMA.NS',
    label: 'SAKUMA.NS,  Sakuma Exports Limited',
    name: 'Sakuma Exports Limited',
    symbol: 'SAKUMA.NS',
  },
  {
    value: 'SALASAR.NS',
    label: 'SALASAR.NS,  Salasar Techno Engineering Limited',
    name: 'Salasar Techno Engineering Limited',
    symbol: 'SALASAR.NS',
  },
  {
    value: 'SALSTEEL.NS',
    label: 'SALSTEEL.NS,  S.A.L. Steel Limited',
    name: 'S.A.L. Steel Limited',
    symbol: 'SALSTEEL.NS',
  },
  {
    value: 'SANWARIA.NS',
    label: 'SANWARIA.NS,  Sanwaria Consumer Limited',
    name: 'Sanwaria Consumer Limited',
    symbol: 'SANWARIA.NS',
  },
  {
    value: 'SATHAISPAT.NS',
    label: 'SATHAISPAT.NS,  Sathavahana Ispat Limited',
    name: 'Sathavahana Ispat Limited',
    symbol: 'SATHAISPAT.NS',
  },
  {
    value: 'SCHAND.NS',
    label: 'SCHAND.NS,  S Chand and Company Limited',
    name: 'S Chand and Company Limited',
    symbol: 'SCHAND.NS',
  },
  {
    value: 'SEPOWER.NS',
    label: 'SEPOWER.NS,  S. E. Power Limited',
    name: 'S. E. Power Limited',
    symbol: 'SEPOWER.NS',
  },
  {
    value: 'SETUINFRA.NS',
    label: 'SETUINFRA.NS,  Setubandhan Infrastructure Limited',
    name: 'Setubandhan Infrastructure Limited',
    symbol: 'SETUINFRA.NS',
  },
  {
    value: 'SHAHALLOYS.NS',
    label: 'SHAHALLOYS.NS,  Shah Alloys Limited',
    name: 'Shah Alloys Limited',
    symbol: 'SHAHALLOYS.NS',
  },
  {
    value: 'SHAKTIPUMP.NS',
    label: 'SHAKTIPUMP.NS,  Shakti Pumps (India) Limited',
    name: 'Shakti Pumps (India) Limited',
    symbol: 'SHAKTIPUMP.NS',
  },
  {
    value: 'SHIVAMAUTO.NS',
    label: 'SHIVAMAUTO.NS,  Shivam Autotech Limited',
    name: 'Shivam Autotech Limited',
    symbol: 'SHIVAMAUTO.NS',
  },
  {
    value: 'SHRIPISTON.NS',
    label: 'SHRIPISTON.NS,  Shriram Pistons & Rings Limited',
    name: 'Shriram Pistons & Rings Limited',
    symbol: 'SHRIPISTON.NS',
  },
  {
    value: 'SHYAMCENT.NS',
    label: 'SHYAMCENT.NS,  Shyam Century Ferrous Limited',
    name: 'Shyam Century Ferrous Limited',
    symbol: 'SHYAMCENT.NS',
  },
  {
    value: 'SIL.NS',
    label: 'SIL.NS,  Standard Industries Limited',
    name: 'Standard Industries Limited',
    symbol: 'SIL.NS',
  },
  {
    value: 'SIMBHALS.NS',
    label: 'SIMBHALS.NS,  Simbhaoli Sugars Limited',
    name: 'Simbhaoli Sugars Limited',
    symbol: 'SIMBHALS.NS',
  },
  {
    value: 'SITINET.NS',
    label: 'SITINET.NS,  SITI Networks Limited',
    name: 'SITI Networks Limited',
    symbol: 'SITINET.NS',
  },
  {
    value: 'SKIL.NS',
    label: 'SKIL.NS,  SKIL Infrastructure Limited',
    name: 'SKIL Infrastructure Limited',
    symbol: 'SKIL.NS',
  },
  {
    value: 'SOMATEX.NS',
    label: 'SOMATEX.NS,  Soma Textiles & Industries Limited',
    name: 'Soma Textiles & Industries Limited',
    symbol: 'SOMATEX.NS',
  },
  {
    value: 'SOUTHWEST.NS',
    label: 'SOUTHWEST.NS,  South West Pinnacle Exploration Limited',
    name: 'South West Pinnacle Exploration Limited',
    symbol: 'SOUTHWEST.NS',
  },
  {
    value: 'SPENTEX.NS',
    label: 'SPENTEX.NS,  CLC Industries Limited',
    name: 'CLC Industries Limited',
    symbol: 'SPENTEX.NS',
  },
  {
    value: 'SPTL.NS',
    label: 'SPTL.NS,  Sintex Plastics Technology Limited',
    name: 'Sintex Plastics Technology Limited',
    symbol: 'SPTL.NS',
  },
  {
    value: 'SPYL.NS',
    label: 'SPYL.NS,  Shekhawati Poly-Yarn Limited',
    name: 'Shekhawati Poly-Yarn Limited',
    symbol: 'SPYL.NS',
  },
  {
    value: 'STAMPEDE.NS',
    label: 'STAMPEDE.NS,  Stampede Capital Limited',
    name: 'Stampede Capital Limited',
    symbol: 'STAMPEDE.NS',
  },
  {
    value: 'STEELXIND.NS',
    label: 'STEELXIND.NS,  Steel Exchange India Limited',
    name: 'Steel Exchange India Limited',
    symbol: 'STEELXIND.NS',
  },
  {
    value: 'SUMIT.NS',
    label: 'SUMIT.NS,  Sumit Woods Limited',
    name: 'Sumit Woods Limited',
    symbol: 'SUMIT.NS',
  },
  {
    value: 'SUPERSPIN.NS',
    label: 'SUPERSPIN.NS,  Super Spinning Mills Limited',
    name: 'Super Spinning Mills Limited',
    symbol: 'SUPERSPIN.NS',
  },
  {
    value: 'SURANASOL.NS',
    label: 'SURANASOL.NS,  Surana Solar Limited',
    name: 'Surana Solar Limited',
    symbol: 'SURANASOL.NS',
  },
  {
    value: 'SURYALAXMI.NS',
    label: 'SURYALAXMI.NS,  Suryalakshmi Cotton Mills Limited',
    name: 'Suryalakshmi Cotton Mills Limited',
    symbol: 'SURYALAXMI.NS',
  },
  {
    value: 'TEJASNET.NS',
    label: 'TEJASNET.NS,  Tejas Networks Limited',
    name: 'Tejas Networks Limited',
    symbol: 'TEJASNET.NS',
  },
  {
    value: 'TGBHOTELS.NS',
    label: 'TGBHOTELS.NS,  TGB Banquets and Hotels Limited',
    name: 'TGB Banquets and Hotels Limited',
    symbol: 'TGBHOTELS.NS',
  },
  {
    value: 'THOMASCOTT.NS',
    label: 'THOMASCOTT.NS,  Thomas Scott (India) Limited',
    name: 'Thomas Scott (India) Limited',
    symbol: 'THOMASCOTT.NS',
  },
  {
    value: 'TOUCHWOOD.NS',
    label: 'TOUCHWOOD.NS,  Touchwood Entertainment Limited',
    name: 'Touchwood Entertainment Limited',
    symbol: 'TOUCHWOOD.NS',
  },
  {
    value: 'TVSELECT.NS',
    label: 'TVSELECT.NS,  TVS Electronics Limited',
    name: 'TVS Electronics Limited',
    symbol: 'TVSELECT.NS',
  },
  {
    value: 'UMESLTD.NS',
    label: 'UMESLTD.NS,  Usha Martin Education & Solutions Limited',
    name: 'Usha Martin Education & Solutions Limited',
    symbol: 'UMESLTD.NS',
  },
  {
    value: 'URJA.NS',
    label: 'URJA.NS,  Urja Global Limited',
    name: 'Urja Global Limited',
    symbol: 'URJA.NS',
  },
  {
    value: 'VARDMNPOLY.NS',
    label: 'VARDMNPOLY.NS,  Vardhman Polytex Limited',
    name: 'Vardhman Polytex Limited',
    symbol: 'VARDMNPOLY.NS',
  },
  {
    value: 'VASWANI.NS',
    label: 'VASWANI.NS,  Vaswani Industries Limited',
    name: 'Vaswani Industries Limited',
    symbol: 'VASWANI.NS',
  },
  {
    value: 'VENUSREM.NS',
    label: 'VENUSREM.NS,  Venus Remedies Limited',
    name: 'Venus Remedies Limited',
    symbol: 'VENUSREM.NS',
  },
  {
    value: 'VICEROY.NS',
    label: 'VICEROY.NS,  Viceroy Hotels Limited',
    name: 'Viceroy Hotels Limited',
    symbol: 'VICEROY.NS',
  },
  {
    value: 'VIPCLOTHNG.NS',
    label: 'VIPCLOTHNG.NS,  VIP Clothing Limited',
    name: 'VIP Clothing Limited',
    symbol: 'VIPCLOTHNG.NS',
  },
  {
    value: 'VISHAL.NS',
    label: 'VISHAL.NS,  Vishal Fabrics Limited',
    name: 'Vishal Fabrics Limited',
    symbol: 'VISHAL.NS',
  },
  {
    value: 'VIVIDHA.NS',
    label: 'VIVIDHA.NS,  Visagar Polytex Limited',
    name: 'Visagar Polytex Limited',
    symbol: 'VIVIDHA.NS',
  },
  {
    value: 'VIVIMEDLAB.NS',
    label: 'VIVIMEDLAB.NS,  Vivimed Labs Limited',
    name: 'Vivimed Labs Limited',
    symbol: 'VIVIMEDLAB.NS',
  },
  {
    value: 'VSSL.NS',
    label: 'VSSL.NS,  Vardhman Special Steels Limited',
    name: 'Vardhman Special Steels Limited',
    symbol: 'VSSL.NS',
  },
  {
    value: 'WANBURY.NS',
    label: 'WANBURY.NS,  Wanbury Limited',
    name: 'Wanbury Limited',
    symbol: 'WANBURY.NS',
  },
  {
    value: 'WIPL.NS',
    label: 'WIPL.NS,  The Western India Plywoods Limited',
    name: 'The Western India Plywoods Limited',
    symbol: 'WIPL.NS',
  },
  {
    value: 'WSI.NS',
    label: 'WSI.NS,  W.S. Industries (India) Limited',
    name: 'W.S. Industries (India) Limited',
    symbol: 'WSI.NS',
  },
  {
    value: 'XELPMOC.NS',
    label: 'XELPMOC.NS,  Xelpmoc Design and Tech Limited',
    name: 'Xelpmoc Design and Tech Limited',
    symbol: 'XELPMOC.NS',
  },
  {
    value: 'ZEEMEDIA.NS',
    label: 'ZEEMEDIA.NS,  Zee Media Corporation Limited',
    name: 'Zee Media Corporation Limited',
    symbol: 'ZEEMEDIA.NS',
  },
  {
    value: 'BARTRONICS.NS',
    label: 'BARTRONICS.NS,  Bartronics India Limited',
    name: 'Bartronics India Limited',
    symbol: 'BARTRONICS.NS',
  },
  {
    value: 'CANDC.NS',
    label: 'CANDC.NS,  C & C Constructions Limited',
    name: 'C & C Constructions Limited',
    symbol: 'CANDC.NS',
  },
  {
    value: 'EASUNREYRL.NS',
    label: 'EASUNREYRL.NS,  Easun Reyrolle Limited',
    name: 'Easun Reyrolle Limited',
    symbol: 'EASUNREYRL.NS',
  },
  {
    value: 'EDUCOMP.NS',
    label: 'EDUCOMP.NS,  Educomp Solutions Limited',
    name: 'Educomp Solutions Limited',
    symbol: 'EDUCOMP.NS',
  },
  {
    value: 'GOENKA.NS',
    label: 'GOENKA.NS,  Goenka Diamond and Jewels Limited',
    name: 'Goenka Diamond and Jewels Limited',
    symbol: 'GOENKA.NS',
  },
  {
    value: 'HDIL.NS',
    label: 'HDIL.NS,  Housing Development and Infrastructure Limited',
    name: 'Housing Development and Infrastructure Limited',
    symbol: 'HDIL.NS',
  },
  {
    value: 'HMT.NS',
    label: 'HMT.NS,  HMT Limited',
    name: 'HMT Limited',
    symbol: 'HMT.NS',
  },
  {
    value: 'JETAIRWAYS.NS',
    label: 'JETAIRWAYS.NS,  Jet Airways (India) Limited',
    name: 'Jet Airways (India) Limited',
    symbol: 'JETAIRWAYS.NS',
  },
  {
    value: 'JYOTISTRUC.NS',
    label: 'JYOTISTRUC.NS,  Jyoti Structures Limited',
    name: 'Jyoti Structures Limited',
    symbol: 'JYOTISTRUC.NS',
  },
  {
    value: 'KOHINOOR.NS',
    label: 'KOHINOOR.NS,  Kohinoor Foods Limited',
    name: 'Kohinoor Foods Limited',
    symbol: 'KOHINOOR.NS',
  },
  {
    value: 'MELSTAR.NS',
    label: 'MELSTAR.NS,  Melstar Information Technologies Limited',
    name: 'Melstar Information Technologies Limited',
    symbol: 'MELSTAR.NS',
  },
  {
    value: 'ORTEL.NS',
    label: 'ORTEL.NS,  Ortel Communications Limited',
    name: 'Ortel Communications Limited',
    symbol: 'ORTEL.NS',
  },
  {
    value: 'PUNJLLOYD.NS',
    label: 'PUNJLLOYD.NS,  Punj Lloyd Limited',
    name: 'Punj Lloyd Limited',
    symbol: 'PUNJLLOYD.NS',
  },
  {
    value: 'SUPREMEINF.NS',
    label: 'SUPREMEINF.NS,  Supreme Infrastructure India Limited',
    name: 'Supreme Infrastructure India Limited',
    symbol: 'SUPREMEINF.NS',
  },
  {
    value: 'UNITECH.NS',
    label: 'UNITECH.NS,  Unitech Limited',
    name: 'Unitech Limited',
    symbol: 'UNITECH.NS',
  },
  {
    value: 'LAKPRE.NS',
    label: 'LAKPRE.NS,  Lakshmi Precision Screws Limited',
    name: 'Lakshmi Precision Screws Limited',
    symbol: 'LAKPRE.NS',
  },
  {
    value: 'SUBCAPCITY.NS',
    label: 'SUBCAPCITY.NS,  International Constructions Limited',
    name: 'International Constructions Limited',
    symbol: 'SUBCAPCITY.NS',
  },
  {
    value: 'TANTIACONS.NS',
    label: 'TANTIACONS.NS,  Tantia Constructions Limited',
    name: 'Tantia Constructions Limited',
    symbol: 'TANTIACONS.NS',
  },
  {
    value: 'TNTELE.NS',
    label: 'TNTELE.NS,  Tamilnadu Telecommunications Limited',
    name: 'Tamilnadu Telecommunications Limited',
    symbol: 'TNTELE.NS',
  },
  {
    value: 'GISOLUTION.NS',
    label: 'GISOLUTION.NS,  GI Engineering Solutions Limited',
    name: 'GI Engineering Solutions Limited',
    symbol: 'GISOLUTION.NS',
  },
  {
    value: 'NAGREEKCAP.NS',
    label: 'NAGREEKCAP.NS,  Nagreeka Capital & Infrastructure Ltd.',
    name: 'Nagreeka Capital & Infrastructure Ltd.',
    symbol: 'NAGREEKCAP.NS',
  },
  {
    value: 'NORBTEAEXP.NS',
    label: 'NORBTEAEXP.NS,  Norben Tea & Exports Limited',
    name: 'Norben Tea & Exports Limited',
    symbol: 'NORBTEAEXP.NS',
  },
  {
    value: 'TECHIN.NS',
    label: 'TECHIN.NS,  Techindia Nirman Limited',
    name: 'Techindia Nirman Limited',
    symbol: 'TECHIN.NS',
  },
  {
    value: 'TFL.NS',
    label: 'TFL.NS,  Transwarranty Finance Limited',
    name: 'Transwarranty Finance Limited',
    symbol: 'TFL.NS',
  },
  {
    value: 'SHYAMTEL.NS',
    label: 'SHYAMTEL.NS,  Shyam Telecom Limited',
    name: 'Shyam Telecom Limited',
    symbol: 'SHYAMTEL.NS',
  },
  {
    value: 'CREATIVEYE.NS',
    label: 'CREATIVEYE.NS,  Creative Eye Limited',
    name: 'Creative Eye Limited',
    symbol: 'CREATIVEYE.NS',
  },
  {
    value: 'PREMIER.NS',
    label: 'PREMIER.NS,  Premier Limited',
    name: 'Premier Limited',
    symbol: 'PREMIER.NS',
  },
  {
    value: '0A0C.L',
    label: '0A0C.L,  Stadler Rail AG',
    name: 'Stadler Rail AG',
    symbol: '0A0C.L',
  },
  {
    value: '0A1K.L',
    label: '0A1K.L,  NIO Inc.',
    name: 'NIO Inc.',
    symbol: '0A1K.L',
  },
  {
    value: '0A1O.L',
    label: '0A1O.L,  Zoom Video Communications, Inc.',
    name: 'Zoom Video Communications, Inc.',
    symbol: '0A1O.L',
  },
  {
    value: '0AH7.L',
    label: '0AH7.L,  BayWa Aktiengesellschaft',
    name: 'BayWa Aktiengesellschaft',
    symbol: '0AH7.L',
  },
  {
    value: '0AR9.L',
    label: '0AR9.L,  Mynaric AG',
    name: 'Mynaric AG',
    symbol: '0AR9.L',
  },
  {
    value: '0DP4.L',
    label: '0DP4.L,  Basware Oyj',
    name: 'Basware Oyj',
    symbol: '0DP4.L',
  },
  {
    value: '0DQK.L',
    label: '0DQK.L,  Beter Bed Holding N.V.',
    name: 'Beter Bed Holding N.V.',
    symbol: '0DQK.L',
  },
  {
    value: '0DXG.L',
    label: '0DXG.L,  CropEnergies AG',
    name: 'CropEnergies AG',
    symbol: '0DXG.L',
  },
  {
    value: '0DYQ.L',
    label: '0DYQ.L,  Cegedim SA',
    name: 'Cegedim SA',
    symbol: '0DYQ.L',
  },
  {
    value: '0EAQ.L',
    label: '0EAQ.L,  Teekay Tankers Ltd.',
    name: 'Teekay Tankers Ltd.',
    symbol: '0EAQ.L',
  },
  {
    value: '0EDD.L',
    label: '0EDD.L,  LyondellBasell Industries N.V.',
    name: 'LyondellBasell Industries N.V.',
    symbol: '0EDD.L',
  },
  {
    value: '0EDE.L',
    label: '0EDE.L,  NXP Semiconductors N.V.',
    name: 'NXP Semiconductors N.V.',
    symbol: '0EDE.L',
  },
  {
    value: '0EEV.L',
    label: '0EEV.L,  Exmar NV',
    name: 'Exmar NV',
    symbol: '0EEV.L',
  },
  {
    value: '0ENN.L',
    label: '0ENN.L,  HORNBACH Baumarkt AG',
    name: 'HORNBACH Baumarkt AG',
    symbol: '0ENN.L',
  },
  {
    value: '0EV1.L',
    label: '0EV1.L,  Carnival Corporation & plc',
    name: 'Carnival Corporation & plc',
    symbol: '0EV1.L',
  },
  {
    value: '0F4O.L',
    label: '0F4O.L,  Lotus Bakeries NV',
    name: 'Lotus Bakeries NV',
    symbol: '0F4O.L',
  },
  {
    value: '0FH7.L',
    label: '0FH7.L,  OHB SE',
    name: 'OHB SE',
    symbol: '0FH7.L',
  },
  {
    value: '0G5B.L',
    label: '0G5B.L,  Sto SE & Co. KGaA',
    name: 'Sto SE & Co. KGaA',
    symbol: '0G5B.L',
  },
  {
    value: '0G68.L',
    label: '0G68.L,  Kendrion N.V.',
    name: 'Kendrion N.V.',
    symbol: '0G68.L',
  },
  {
    value: '0GEG.L',
    label: '0GEG.L,  Vaisala Oyj',
    name: 'Vaisala Oyj',
    symbol: '0GEG.L',
  },
  {
    value: '0GP7.L',
    label: '0GP7.L,  Medivir AB (publ)',
    name: 'Medivir AB (publ)',
    symbol: '0GP7.L',
  },
  {
    value: '0H6E.L',
    label: '0H6E.L,  AGNC Investment Corp.',
    name: 'AGNC Investment Corp.',
    symbol: '0H6E.L',
  },
  {
    value: '0H6G.L',
    label: '0H6G.L,  The AES Corporation',
    name: 'The AES Corporation',
    symbol: '0H6G.L',
  },
  {
    value: '0H8I.L',
    label: '0H8I.L,  Accuray Incorporated',
    name: 'Accuray Incorporated',
    symbol: '0H8I.L',
  },
  {
    value: '0H9N.L',
    label: '0H9N.L,  AECOM',
    name: 'AECOM',
    symbol: '0H9N.L',
  },
  {
    value: '0HA5.L',
    label: '0HA5.L,  Aerojet Rocketdyne Holdings, Inc.',
    name: 'Aerojet Rocketdyne Holdings, Inc.',
    symbol: '0HA5.L',
  },
  {
    value: '0HAL.L',
    label: '0HAL.L,  AeroVironment, Inc.',
    name: 'AeroVironment, Inc.',
    symbol: '0HAL.L',
  },
  {
    value: '0HB0.L',
    label: '0HB0.L,  Agios Pharmaceuticals, Inc.',
    name: 'Agios Pharmaceuticals, Inc.',
    symbol: '0HB0.L',
  },
  {
    value: '0HBQ.L',
    label: '0HBQ.L,  Akamai Technologies, Inc.',
    name: 'Akamai Technologies, Inc.',
    symbol: '0HBQ.L',
  },
  {
    value: '0HC3.L',
    label: '0HC3.L,  Alaska Air Group, Inc.',
    name: 'Alaska Air Group, Inc.',
    symbol: '0HC3.L',
  },
  {
    value: '0HC7.L',
    label: '0HC7.L,  Albemarle Corporation',
    name: 'Albemarle Corporation',
    symbol: '0HC7.L',
  },
  {
    value: '0HCB.L',
    label: '0HCB.L,  Alcoa Corporation',
    name: 'Alcoa Corporation',
    symbol: '0HCB.L',
  },
  {
    value: '0HCR.L',
    label: '0HCR.L,  Bread Financial Holdings, Inc.',
    name: 'Bread Financial Holdings, Inc.',
    symbol: '0HCR.L',
  },
  {
    value: '0HD0.L',
    label: '0HD0.L,  Ally Financial Inc.',
    name: 'Ally Financial Inc.',
    symbol: '0HD0.L',
  },
  {
    value: '0HDD.L',
    label: '0HDD.L,  Alteryx, Inc.',
    name: 'Alteryx, Inc.',
    symbol: '0HDD.L',
  },
  {
    value: '0HDY.L',
    label: '0HDY.L,  Golar LNG Limited',
    name: 'Golar LNG Limited',
    symbol: '0HDY.L',
  },
  {
    value: '0HEC.L',
    label: '0HEC.L,  American Electric Power Company, Inc.',
    name: 'American Electric Power Company, Inc.',
    symbol: '0HEC.L',
  },
  {
    value: '0HEU.L',
    label: '0HEU.L,  American Tower Corporation',
    name: 'American Tower Corporation',
    symbol: '0HEU.L',
  },
  {
    value: '0HFB.L',
    label: '0HFB.L,  Amphenol Corporation',
    name: 'Amphenol Corporation',
    symbol: '0HFB.L',
  },
  {
    value: '0HFN.L',
    label: '0HFN.L,  Analog Devices, Inc.',
    name: 'Analog Devices, Inc.',
    symbol: '0HFN.L',
  },
  {
    value: '0HFR.L',
    label: '0HFR.L,  Anavex Life Sciences Corp.',
    name: 'Anavex Life Sciences Corp.',
    symbol: '0HFR.L',
  },
  {
    value: '0HG3.L',
    label: '0HG3.L,  ANSYS, Inc.',
    name: 'ANSYS, Inc.',
    symbol: '0HG3.L',
  },
  {
    value: '0HGC.L',
    label: '0HGC.L,  APA Corporation',
    name: 'APA Corporation',
    symbol: '0HGC.L',
  },
  {
    value: '0HGM.L',
    label: '0HGM.L,  Appian Corporation',
    name: 'Appian Corporation',
    symbol: '0HGM.L',
  },
  {
    value: '0HHB.L',
    label: '0HHB.L,  Aramark',
    name: 'Aramark',
    symbol: '0HHB.L',
  },
  {
    value: '0HHP.L',
    label: '0HHP.L,  Ares Capital Corporation',
    name: 'Ares Capital Corporation',
    symbol: '0HHP.L',
  },
  {
    value: '0HHR.L',
    label: '0HHR.L,  Arista Networks, Inc.',
    name: 'Arista Networks, Inc.',
    symbol: '0HHR.L',
  },
  {
    value: '0HI1.L',
    label: '0HI1.L,  Arrow Electronics, Inc.',
    name: 'Arrow Electronics, Inc.',
    symbol: '0HI1.L',
  },
  {
    value: '0HIY.L',
    label: '0HIY.L,  Atara Biotherapeutics, Inc.',
    name: 'Atara Biotherapeutics, Inc.',
    symbol: '0HIY.L',
  },
  {
    value: '0HJF.L',
    label: '0HJF.L,  Autodesk, Inc.',
    name: 'Autodesk, Inc.',
    symbol: '0HJF.L',
  },
  {
    value: '0HJI.L',
    label: '0HJI.L,  Automatic Data Processing, Inc.',
    name: 'Automatic Data Processing, Inc.',
    symbol: '0HJI.L',
  },
  {
    value: '0HJO.L',
    label: '0HJO.L,  AvalonBay Communities, Inc.',
    name: 'AvalonBay Communities, Inc.',
    symbol: '0HJO.L',
  },
  {
    value: '0HK4.L',
    label: '0HK4.L,  Avis Budget Group, Inc.',
    name: 'Avis Budget Group, Inc.',
    symbol: '0HK4.L',
  },
  {
    value: '0HKD.L',
    label: '0HKD.L,  AxoGen, Inc.',
    name: 'AxoGen, Inc.',
    symbol: '0HKD.L',
  },
  {
    value: '0HKE.L',
    label: '0HKE.L,  Axon Enterprise, Inc.',
    name: 'Axon Enterprise, Inc.',
    symbol: '0HKE.L',
  },
  {
    value: '0HKF.L',
    label: '0HKF.L,  Axsome Therapeutics, Inc.',
    name: 'Axsome Therapeutics, Inc.',
    symbol: '0HKF.L',
  },
  {
    value: '0HL9.L',
    label: '0HL9.L,  Affimed N.V.',
    name: 'Affimed N.V.',
    symbol: '0HL9.L',
  },
  {
    value: '0HLQ.L',
    label: '0HLQ.L,  The Bank of New York Mellon Corporation',
    name: 'The Bank of New York Mellon Corporation',
    symbol: '0HLQ.L',
  },
  {
    value: '0HM0.L',
    label: '0HM0.L,  VGP NV',
    name: 'VGP NV',
    symbol: '0HM0.L',
  },
  {
    value: '0HMI.L',
    label: '0HMI.L,  Bed Bath & Beyond Inc',
    name: 'Bed Bath & Beyond Inc',
    symbol: '0HMI.L',
  },
  {
    value: '0HNC.L',
    label: '0HNC.L,  BioMarin Pharmaceutical Inc.',
    name: 'BioMarin Pharmaceutical Inc.',
    symbol: '0HNC.L',
  },
  {
    value: '0HOH.L',
    label: '0HOH.L,  bluebird bio, Inc.',
    name: 'bluebird bio, Inc.',
    symbol: '0HOH.L',
  },
  {
    value: '0HOT.L',
    label: '0HOT.L,  Booz Allen Hamilton Holding Corporation',
    name: 'Booz Allen Hamilton Holding Corporation',
    symbol: '0HOT.L',
  },
  {
    value: '0HOU.L',
    label: '0HOU.L,  BorgWarner Inc.',
    name: 'BorgWarner Inc.',
    symbol: '0HOU.L',
  },
  {
    value: '0HOY.L',
    label: '0HOY.L,  Boston Scientific Corporation',
    name: 'Boston Scientific Corporation',
    symbol: '0HOY.L',
  },
  {
    value: '0HP1.L',
    label: '0HP1.L,  Box, Inc.',
    name: 'Box, Inc.',
    symbol: '0HP1.L',
  },
  {
    value: '0HPW.L',
    label: '0HPW.L,  Broadridge Financial Solutions, Inc.',
    name: 'Broadridge Financial Solutions, Inc.',
    symbol: '0HPW.L',
  },
  {
    value: '0HQN.L',
    label: '0HQN.L,  Cboe Global Markets, Inc.',
    name: 'Cboe Global Markets, Inc.',
    symbol: '0HQN.L',
  },
  {
    value: '0HR2.L',
    label: '0HR2.L,  CME Group Inc.',
    name: 'CME Group Inc.',
    symbol: '0HR2.L',
  },
  {
    value: '0HRJ.L',
    label: '0HRJ.L,  CSX Corporation',
    name: 'CSX Corporation',
    symbol: '0HRJ.L',
  },
  {
    value: '0HRS.L',
    label: '0HRS.L,  CVS Health Corporation',
    name: 'CVS Health Corporation',
    symbol: '0HRS.L',
  },
  {
    value: '0HRZ.L',
    label: '0HRZ.L,  Coterra Energy Inc.',
    name: 'Coterra Energy Inc.',
    symbol: '0HRZ.L',
  },
  {
    value: '0HS2.L',
    label: '0HS2.L,  Cadence Design Systems, Inc.',
    name: 'Cadence Design Systems, Inc.',
    symbol: '0HS2.L',
  },
  {
    value: '0HTG.L',
    label: '0HTG.L,  Cardinal Health, Inc.',
    name: 'Cardinal Health, Inc.',
    symbol: '0HTG.L',
  },
  {
    value: '0HUZ.L',
    label: '0HUZ.L,  Imunon, Inc.',
    name: 'Imunon, Inc.',
    symbol: '0HUZ.L',
  },
  {
    value: '0HVB.L',
    label: '0HVB.L,  Centene Corporation',
    name: 'Centene Corporation',
    symbol: '0HVB.L',
  },
  {
    value: '0HVF.L',
    label: '0HVF.L,  CenterPoint Energy, Inc.',
    name: 'CenterPoint Energy, Inc.',
    symbol: '0HVF.L',
  },
  {
    value: '0HVP.L',
    label: '0HVP.L,  Lumen Technologies, Inc.',
    name: 'Lumen Technologies, Inc.',
    symbol: '0HVP.L',
  },
  {
    value: '0HW0.L',
    label: '0HW0.L,  Kongsberg Automotive ASA',
    name: 'Kongsberg Automotive ASA',
    symbol: '0HW0.L',
  },
  {
    value: '0HX7.L',
    label: '0HX7.L,  Adicet Bio, Inc.',
    name: 'Adicet Bio, Inc.',
    symbol: '0HX7.L',
  },
  {
    value: '0HXW.L',
    label: '0HXW.L,  Chipotle Mexican Grill, Inc.',
    name: 'Chipotle Mexican Grill, Inc.',
    symbol: '0HXW.L',
  },
  {
    value: '0HYA.L',
    label: '0HYA.L,  Ciena Corp',
    name: 'Ciena Corp',
    symbol: '0HYA.L',
  },
  {
    value: '0HYI.L',
    label: '0HYI.L,  Cirrus Logic, Inc.',
    name: 'Cirrus Logic, Inc.',
    symbol: '0HYI.L',
  },
  {
    value: '0HYJ.L',
    label: '0HYJ.L,  Cintas Corporation',
    name: 'Cintas Corporation',
    symbol: '0HYJ.L',
  },
  {
    value: '0I0H.L',
    label: '0I0H.L,  Cleveland-Cliffs Inc.',
    name: 'Cleveland-Cliffs Inc.',
    symbol: '0I0H.L',
  },
  {
    value: '0I0J.L',
    label: '0I0J.L,  The Clorox Company',
    name: 'The Clorox Company',
    symbol: '0I0J.L',
  },
  {
    value: '0I14.L',
    label: '0I14.L,  Cognex Corporation',
    name: 'Cognex Corporation',
    symbol: '0I14.L',
  },
  {
    value: '0I47.L',
    label: '0I47.L,  Costco Wholesale Corporation',
    name: 'Costco Wholesale Corporation',
    symbol: '0I47.L',
  },
  {
    value: '0I4A.L',
    label: '0I4A.L,  Coty Inc.',
    name: 'Coty Inc.',
    symbol: '0I4A.L',
  },
  {
    value: '0I4W.L',
    label: '0I4W.L,  Crown Castle Inc.',
    name: 'Crown Castle Inc.',
    symbol: '0I4W.L',
  },
  {
    value: '0I4X.L',
    label: '0I4X.L,  Crown Holdings, Inc.',
    name: 'Crown Holdings, Inc.',
    symbol: '0I4X.L',
  },
  {
    value: '0I58.L',
    label: '0I58.L,  Cummins Inc.',
    name: 'Cummins Inc.',
    symbol: '0I58.L',
  },
  {
    value: '0I6K.L',
    label: '0I6K.L,  D.R. Horton, Inc.',
    name: 'D.R. Horton, Inc.',
    symbol: '0I6K.L',
  },
  {
    value: '0I6Q.L',
    label: '0I6Q.L,  DTE Energy Company',
    name: 'DTE Energy Company',
    symbol: '0I6Q.L',
  },
  {
    value: '0I8A.L',
    label: '0I8A.L,  Denbury Inc.',
    name: 'Denbury Inc.',
    symbol: '0I8A.L',
  },
  {
    value: '0I9F.L',
    label: '0I9F.L,  Digital Realty Trust, Inc.',
    name: 'Digital Realty Trust, Inc.',
    symbol: '0I9F.L',
  },
  {
    value: '0IBG.L',
    label: '0IBG.L,  DISH Network Corporation',
    name: 'DISH Network Corporation',
    symbol: '0IBG.L',
  },
  {
    value: '0IC7.L',
    label: '0IC7.L,  Dollar General Corporation',
    name: 'Dollar General Corporation',
    symbol: '0IC7.L',
  },
  {
    value: '0IC8.L',
    label: '0IC8.L,  Dollar Tree, Inc.',
    name: 'Dollar Tree, Inc.',
    symbol: '0IC8.L',
  },
  {
    value: '0IC9.L',
    label: '0IC9.L,  Dominion Energy, Inc.',
    name: 'Dominion Energy, Inc.',
    symbol: '0IC9.L',
  },
  {
    value: '0IDA.L',
    label: '0IDA.L,  Dynavax Technologies Corporation',
    name: 'Dynavax Technologies Corporation',
    symbol: '0IDA.L',
  },
  {
    value: '0IDR.L',
    label: '0IDR.L,  EOG Resources, Inc.',
    name: 'EOG Resources, Inc.',
    symbol: '0IDR.L',
  },
  {
    value: '0IF3.L',
    label: '0IF3.L,  Eastman Chemical Company',
    name: 'Eastman Chemical Company',
    symbol: '0IF3.L',
  },
  {
    value: '0IFA.L',
    label: '0IFA.L,  Ecolab Inc.',
    name: 'Ecolab Inc.',
    symbol: '0IFA.L',
  },
  {
    value: '0IFK.L',
    label: '0IFK.L,  Editas Medicine, Inc.',
    name: 'Editas Medicine, Inc.',
    symbol: '0IFK.L',
  },
  {
    value: '0IFX.L',
    label: '0IFX.L,  Electronic Arts Inc.',
    name: 'Electronic Arts Inc.',
    symbol: '0IFX.L',
  },
  {
    value: '0IGA.L',
    label: '0IGA.L,  Emergent BioSolutions Inc.',
    name: 'Emergent BioSolutions Inc.',
    symbol: '0IGA.L',
  },
  {
    value: '0II4.L',
    label: '0II4.L,  Equinix, Inc.',
    name: 'Equinix, Inc.',
    symbol: '0II4.L',
  },
  {
    value: '0IIM.L',
    label: '0IIM.L,  Esperion Therapeutics, Inc.',
    name: 'Esperion Therapeutics, Inc.',
    symbol: '0IIM.L',
  },
  {
    value: '0IIW.L',
    label: '0IIW.L,  Etsy, Inc.',
    name: 'Etsy, Inc.',
    symbol: '0IIW.L',
  },
  {
    value: '0IJV.L',
    label: '0IJV.L,  Extra Space Storage Inc.',
    name: 'Extra Space Storage Inc.',
    symbol: '0IJV.L',
  },
  {
    value: '0IJW.L',
    label: '0IJW.L,  Extreme Networks, Inc.',
    name: 'Extreme Networks, Inc.',
    symbol: '0IJW.L',
  },
  {
    value: '0IK3.L',
    label: '0IK3.L,  FMC Corporation',
    name: 'FMC Corporation',
    symbol: '0IK3.L',
  },
  {
    value: '0IKW.L',
    label: '0IKW.L,  Fastenal Company',
    name: 'Fastenal Company',
    symbol: '0IKW.L',
  },
  {
    value: '0ILW.L',
    label: '0ILW.L,  Fidelity National Information Services, Inc.',
    name: 'Fidelity National Information Services, Inc.',
    symbol: '0ILW.L',
  },
  {
    value: '0IP9.L',
    label: '0IP9.L,  Fiserv, Inc.',
    name: 'Fiserv, Inc.',
    symbol: '0IP9.L',
  },
  {
    value: '0IQC.L',
    label: '0IQC.L,  Fluor Corporation',
    name: 'Fluor Corporation',
    symbol: '0IQC.L',
  },
  {
    value: '0IR9.L',
    label: '0IR9.L,  Fortinet, Inc.',
    name: 'Fortinet, Inc.',
    symbol: '0IR9.L',
  },
  {
    value: '0IRE.L',
    label: '0IRE.L,  Fortive Corporation',
    name: 'Fortive Corporation',
    symbol: '0IRE.L',
  },
  {
    value: '0ITL.L',
    label: '0ITL.L,  Arthur J. Gallagher & Co.',
    name: 'Arthur J. Gallagher & Co.',
    symbol: '0ITL.L',
  },
  {
    value: '0ITS.L',
    label: '0ITS.L,  The Gap, Inc.',
    name: 'The Gap, Inc.',
    symbol: '0ITS.L',
  },
  {
    value: '0IUC.L',
    label: '0IUC.L,  General Dynamics Corporation',
    name: 'General Dynamics Corporation',
    symbol: '0IUC.L',
  },
  {
    value: '0IV3.L',
    label: '0IV3.L,  Geron Corporation',
    name: 'Geron Corporation',
    symbol: '0IV3.L',
  },
  {
    value: '0IW7.L',
    label: '0IW7.L,  Global Payments Inc.',
    name: 'Global Payments Inc.',
    symbol: '0IW7.L',
  },
  {
    value: '0IYQ.L',
    label: '0IYQ.L,  Gogo Inc.',
    name: 'Gogo Inc.',
    symbol: '0IYQ.L',
  },
  {
    value: '0IYS.L',
    label: '0IYS.L,  Gold Resource Corporation',
    name: 'Gold Resource Corporation',
    symbol: '0IYS.L',
  },
  {
    value: '0IYU.L',
    label: '0IYU.L,  Golden Minerals Company',
    name: 'Golden Minerals Company',
    symbol: '0IYU.L',
  },
  {
    value: '0IZI.L',
    label: '0IZI.L,  W.W. Grainger, Inc.',
    name: 'W.W. Grainger, Inc.',
    symbol: '0IZI.L',
  },
  {
    value: '0J0N.L',
    label: '0J0N.L,  Green Dot Corporation',
    name: 'Green Dot Corporation',
    symbol: '0J0N.L',
  },
  {
    value: '0J2E.L',
    label: '0J2E.L,  HP Inc.',
    name: 'HP Inc.',
    symbol: '0J2E.L',
  },
  {
    value: '0J2O.L',
    label: '0J2O.L,  Halozyme Therapeutics, Inc.',
    name: 'Halozyme Therapeutics, Inc.',
    symbol: '0J2O.L',
  },
  {
    value: '0J3K.L',
    label: '0J3K.L,  Hasbro, Inc.',
    name: 'Hasbro, Inc.',
    symbol: '0J3K.L',
  },
  {
    value: '0J46.L',
    label: '0J46.L,  HEICO Corporation',
    name: 'HEICO Corporation',
    symbol: '0J46.L',
  },
  {
    value: '0J4V.L',
    label: '0J4V.L,  Heron Therapeutics, Inc.',
    name: 'Heron Therapeutics, Inc.',
    symbol: '0J4V.L',
  },
  {
    value: '0J4X.L',
    label: '0J4X.L,  The Hershey Company',
    name: 'The Hershey Company',
    symbol: '0J4X.L',
  },
  {
    value: '0J5H.L',
    label: '0J5H.L,  Himax Technologies, Inc.',
    name: 'Himax Technologies, Inc.',
    symbol: '0J5H.L',
  },
  {
    value: '0J5I.L',
    label: '0J5I.L,  Hilton Worldwide Holdings Inc.',
    name: 'Hilton Worldwide Holdings Inc.',
    symbol: '0J5I.L',
  },
  {
    value: '0J5Q.L',
    label: '0J5Q.L,  Hologic, Inc.',
    name: 'Hologic, Inc.',
    symbol: '0J5Q.L',
  },
  {
    value: '0J86.L',
    label: '0J86.L,  IPG Photonics Corporation',
    name: 'IPG Photonics Corporation',
    symbol: '0J86.L',
  },
  {
    value: '0J8P.L',
    label: '0J8P.L,  IDEXX Laboratories, Inc.',
    name: 'IDEXX Laboratories, Inc.',
    symbol: '0J8P.L',
  },
  {
    value: '0J8Z.L',
    label: '0J8Z.L,  Illumina, Inc.',
    name: 'Illumina, Inc.',
    symbol: '0J8Z.L',
  },
  {
    value: '0J9J.L',
    label: '0J9J.L,  Impinj, Inc.',
    name: 'Impinj, Inc.',
    symbol: '0J9J.L',
  },
  {
    value: '0JBD.L',
    label: '0JBD.L,  Innovative Industrial Properties, Inc.',
    name: 'Innovative Industrial Properties, Inc.',
    symbol: '0JBD.L',
  },
  {
    value: '0JBU.L',
    label: '0JBU.L,  Intellia Therapeutics, Inc.',
    name: 'Intellia Therapeutics, Inc.',
    symbol: '0JBU.L',
  },
  {
    value: '0JC3.L',
    label: '0JC3.L,  Intercontinental Exchange, Inc.',
    name: 'Intercontinental Exchange, Inc.',
    symbol: '0JC3.L',
  },
  {
    value: '0JCT.L',
    label: '0JCT.L,  Intuit Inc.',
    name: 'Intuit Inc.',
    symbol: '0JCT.L',
  },
  {
    value: '0JD3.L',
    label: '0JD3.L,  Invesco Mortgage Capital Inc.',
    name: 'Invesco Mortgage Capital Inc.',
    symbol: '0JD3.L',
  },
  {
    value: '0JDB.L',
    label: '0JDB.L,  Invitae Corporation',
    name: 'Invitae Corporation',
    symbol: '0JDB.L',
  },
  {
    value: '0JDI.L',
    label: '0JDI.L,  Ionis Pharmaceuticals, Inc.',
    name: 'Ionis Pharmaceuticals, Inc.',
    symbol: '0JDI.L',
  },
  {
    value: '0JDK.L',
    label: '0JDK.L,  Iovance Biotherapeutics, Inc.',
    name: 'Iovance Biotherapeutics, Inc.',
    symbol: '0JDK.L',
  },
  {
    value: '0JDP.L',
    label: '0JDP.L,  Iron Mountain Incorporated',
    name: 'Iron Mountain Incorporated',
    symbol: '0JDP.L',
  },
  {
    value: '0JOT.L',
    label: '0JOT.L,  JetBlue Airways Corporation',
    name: 'JetBlue Airways Corporation',
    symbol: '0JOT.L',
  },
  {
    value: '0JPO.L',
    label: '0JPO.L,  KLA Corporation',
    name: 'KLA Corporation',
    symbol: '0JPO.L',
  },
  {
    value: '0JQZ.L',
    label: '0JQZ.L,  Kimberly-Clark Corporation',
    name: 'Kimberly-Clark Corporation',
    symbol: '0JQZ.L',
  },
  {
    value: '0JRR.L',
    label: '0JRR.L,  Kopin Corporation',
    name: 'Kopin Corporation',
    symbol: '0JRR.L',
  },
  {
    value: '0JS0.L',
    label: '0JS0.L,  Kratos Defense & Security Solutions, Inc.',
    name: 'Kratos Defense & Security Solutions, Inc.',
    symbol: '0JS0.L',
  },
  {
    value: '0JS2.L',
    label: '0JS2.L,  The Kroger Co.',
    name: 'The Kroger Co.',
    symbol: '0JS2.L',
  },
  {
    value: '0JSY.L',
    label: '0JSY.L,  Laboratory Corporation of America Holdings',
    name: 'Laboratory Corporation of America Holdings',
    symbol: '0JSY.L',
  },
  {
    value: '0JSZ.L',
    label: '0JSZ.L,  Ladder Capital Corp',
    name: 'Ladder Capital Corp',
    symbol: '0JSZ.L',
  },
  {
    value: '0JT5.L',
    label: '0JT5.L,  Lam Research Corporation',
    name: 'Lam Research Corporation',
    symbol: '0JT5.L',
  },
  {
    value: '0JTM.L',
    label: '0JTM.L,  The Estée Lauder Companies Inc.',
    name: 'The Estée Lauder Companies Inc.',
    symbol: '0JTM.L',
  },
  {
    value: '0JTT.L',
    label: '0JTT.L,  Leggett & Platt, Incorporated',
    name: 'Leggett & Platt, Incorporated',
    symbol: '0JTT.L',
  },
  {
    value: '0JUJ.L',
    label: '0JUJ.L,  Formula One Group',
    name: 'Formula One Group',
    symbol: '0JUJ.L',
  },
  {
    value: '0JUZ.L',
    label: '0JUZ.L,  Edgio, Inc.',
    name: 'Edgio, Inc.',
    symbol: '0JUZ.L',
  },
  {
    value: '0JVD.L',
    label: '0JVD.L,  Live Nation Entertainment, Inc.',
    name: 'Live Nation Entertainment, Inc.',
    symbol: '0JVD.L',
  },
  {
    value: '0JVQ.L',
    label: "0JVQ.L,  Lowe's Companies, Inc.",
    name: "Lowe's Companies, Inc.",
    symbol: '0JVQ.L',
  },
  {
    value: '0JVT.L',
    label: '0JVT.L,  Lululemon Athletica Inc.',
    name: 'Lululemon Athletica Inc.',
    symbol: '0JVT.L',
  },
  {
    value: '0JVV.L',
    label: '0JVV.L,  Lumentum Holdings Inc.',
    name: 'Lumentum Holdings Inc.',
    symbol: '0JVV.L',
  },
  {
    value: '0JWC.L',
    label: '0JWC.L,  MGM Resorts International',
    name: 'MGM Resorts International',
    symbol: '0JWC.L',
  },
  {
    value: '0JX5.L',
    label: '0JX5.L,  The Macerich Company',
    name: 'The Macerich Company',
    symbol: '0JX5.L',
  },
  {
    value: '0JXD.L',
    label: "0JXD.L,  Macy's, Inc.",
    name: "Macy's, Inc.",
    symbol: '0JXD.L',
  },
  {
    value: '0JY9.L',
    label: '0JY9.L,  Marathon Oil Corporation',
    name: 'Marathon Oil Corporation',
    symbol: '0JY9.L',
  },
  {
    value: '0JYW.L',
    label: '0JYW.L,  Marriott International, Inc.',
    name: 'Marriott International, Inc.',
    symbol: '0JYW.L',
  },
  {
    value: '0JZ2.L',
    label: '0JZ2.L,  Masimo Corporation',
    name: 'Masimo Corporation',
    symbol: '0JZ2.L',
  },
  {
    value: '0JZ8.L',
    label: '0JZ8.L,  Greenyard NV',
    name: 'Greenyard NV',
    symbol: '0JZ8.L',
  },
  {
    value: '0JZS.L',
    label: '0JZS.L,  McCormick & Company, Incorporated',
    name: 'McCormick & Company, Incorporated',
    symbol: '0JZS.L',
  },
  {
    value: '0JZT.L',
    label: '0JZT.L,  McEwen Mining Inc.',
    name: 'McEwen Mining Inc.',
    symbol: '0JZT.L',
  },
  {
    value: '0JZZ.L',
    label: '0JZZ.L,  Medical Properties Trust, Inc.',
    name: 'Medical Properties Trust, Inc.',
    symbol: '0JZZ.L',
  },
  {
    value: '0K05.L',
    label: '0K05.L,  Medifast, Inc.',
    name: 'Medifast, Inc.',
    symbol: '0K05.L',
  },
  {
    value: '0K0E.L',
    label: '0K0E.L,  MercadoLibre, Inc.',
    name: 'MercadoLibre, Inc.',
    symbol: '0K0E.L',
  },
  {
    value: '0K0K.L',
    label: '0K0K.L,  Meridian Bioscience, Inc.',
    name: 'Meridian Bioscience, Inc.',
    symbol: '0K0K.L',
  },
  {
    value: '0K0X.L',
    label: '0K0X.L,  MetLife, Inc.',
    name: 'MetLife, Inc.',
    symbol: '0K0X.L',
  },
  {
    value: '0K16.L',
    label: '0K16.L,  Evolus, Inc.',
    name: 'Evolus, Inc.',
    symbol: '0K16.L',
  },
  {
    value: '0K17.L',
    label: '0K17.L,  MicroVision, Inc.',
    name: 'MicroVision, Inc.',
    symbol: '0K17.L',
  },
  {
    value: '0K1W.L',
    label: '0K1W.L,  Mitek Systems, Inc.',
    name: 'Mitek Systems, Inc.',
    symbol: '0K1W.L',
  },
  {
    value: '0K2K.L',
    label: '0K2K.L,  Molson Coors Beverage Company',
    name: 'Molson Coors Beverage Company',
    symbol: '0K2K.L',
  },
  {
    value: '0K34.L',
    label: '0K34.L,  Monster Beverage Corporation',
    name: 'Monster Beverage Corporation',
    symbol: '0K34.L',
  },
  {
    value: '0K3B.L',
    label: '0K3B.L,  The Mosaic Company',
    name: 'The Mosaic Company',
    symbol: '0K3B.L',
  },
  {
    value: '0K3S.L',
    label: '0K3S.L,  Murphy Oil Corporation',
    name: 'Murphy Oil Corporation',
    symbol: '0K3S.L',
  },
  {
    value: '0K4T.L',
    label: '0K4T.L,  Nasdaq, Inc.',
    name: 'Nasdaq, Inc.',
    symbol: '0K4T.L',
  },
  {
    value: '0K6O.L',
    label: '0K6O.L,  Farfetch Limited',
    name: 'Farfetch Limited',
    symbol: '0K6O.L',
  },
  {
    value: '0K6R.L',
    label: '0K6R.L,  Neurocrine Biosciences, Inc.',
    name: 'Neurocrine Biosciences, Inc.',
    symbol: '0K6R.L',
  },
  {
    value: '0K76.L',
    label: '0K76.L,  Rithm Capital Corp.',
    name: 'Rithm Capital Corp.',
    symbol: '0K76.L',
  },
  {
    value: '0K80.L',
    label: '0K80.L,  NextEra Energy, Inc.',
    name: 'NextEra Energy, Inc.',
    symbol: '0K80.L',
  },
  {
    value: '0K8J.L',
    label: '0K8J.L,  Nordstrom, Inc.',
    name: 'Nordstrom, Inc.',
    symbol: '0K8J.L',
  },
  {
    value: '0K91.L',
    label: '0K91.L,  Northern Trust Corporation',
    name: 'Northern Trust Corporation',
    symbol: '0K91.L',
  },
  {
    value: '0K92.L',
    label: '0K92.L,  Northrop Grumman Corporation',
    name: 'Northrop Grumman Corporation',
    symbol: '0K92.L',
  },
  {
    value: '0K9L.L',
    label: '0K9L.L,  Nucor Corporation',
    name: 'Nucor Corporation',
    symbol: '0K9L.L',
  },
  {
    value: '0K9O.L',
    label: '0K9O.L,  Nutanix, Inc.',
    name: 'Nutanix, Inc.',
    symbol: '0K9O.L',
  },
  {
    value: '0KAB.L',
    label: "0KAB.L,  O'Reilly Automotive, Inc.",
    name: "O'Reilly Automotive, Inc.",
    symbol: '0KAB.L',
  },
  {
    value: '0KAK.L',
    label: '0KAK.L,  Occidental Petroleum Corporation',
    name: 'Occidental Petroleum Corporation',
    symbol: '0KAK.L',
  },
  {
    value: '0KB7.L',
    label: '0KB7.L,  Okta, Inc.',
    name: 'Okta, Inc.',
    symbol: '0KB7.L',
  },
  {
    value: '0KBI.L',
    label: '0KBI.L,  Knorr-Bremse Aktiengesellschaft',
    name: 'Knorr-Bremse Aktiengesellschaft',
    symbol: '0KBI.L',
  },
  {
    value: '0KCI.L',
    label: '0KCI.L,  ONEOK, Inc.',
    name: 'ONEOK, Inc.',
    symbol: '0KCI.L',
  },
  {
    value: '0KCS.L',
    label: '0KCS.L,  OPKO Health, Inc.',
    name: 'OPKO Health, Inc.',
    symbol: '0KCS.L',
  },
  {
    value: '0KDI.L',
    label: '0KDI.L,  Oshkosh Corporation',
    name: 'Oshkosh Corporation',
    symbol: '0KDI.L',
  },
  {
    value: '0KDU.L',
    label: '0KDU.L,  Overstock.com, Inc.',
    name: 'Overstock.com, Inc.',
    symbol: '0KDU.L',
  },
  {
    value: '0KE0.L',
    label: '0KE0.L,  PBF Energy Inc.',
    name: 'PBF Energy Inc.',
    symbol: '0KE0.L',
  },
  {
    value: '0KEQ.L',
    label: '0KEQ.L,  PVH Corp.',
    name: 'PVH Corp.',
    symbol: '0KEQ.L',
  },
  {
    value: '0KEZ.L',
    label: '0KEZ.L,  Packaging Corporation of America',
    name: 'Packaging Corporation of America',
    symbol: '0KEZ.L',
  },
  {
    value: '0KF5.L',
    label: '0KF5.L,  Palo Alto Networks, Inc.',
    name: 'Palo Alto Networks, Inc.',
    symbol: '0KF5.L',
  },
  {
    value: '0KFU.L',
    label: '0KFU.L,  Park Hotels & Resorts Inc.',
    name: 'Park Hotels & Resorts Inc.',
    symbol: '0KFU.L',
  },
  {
    value: '0KFZ.L',
    label: '0KFZ.L,  Parker-Hannifin Corporation',
    name: 'Parker-Hannifin Corporation',
    symbol: '0KFZ.L',
  },
  {
    value: '0KGH.L',
    label: '0KGH.L,  Paycom Software, Inc.',
    name: 'Paycom Software, Inc.',
    symbol: '0KGH.L',
  },
  {
    value: '0KGS.L',
    label: '0KGS.L,  Pegasystems Inc.',
    name: 'Pegasystems Inc.',
    symbol: '0KGS.L',
  },
  {
    value: '0KHZ.L',
    label: '0KHZ.L,  Phillips 66',
    name: 'Phillips 66',
    symbol: '0KHZ.L',
  },
  {
    value: '0KIZ.L',
    label: '0KIZ.L,  New Wave Group AB (publ)',
    name: 'New Wave Group AB (publ)',
    symbol: '0KIZ.L',
  },
  {
    value: '0KKZ.L',
    label: '0KKZ.L,  MongoDB, Inc.',
    name: 'MongoDB, Inc.',
    symbol: '0KKZ.L',
  },
  {
    value: '0KNY.L',
    label: '0KNY.L,  T. Rowe Price Group, Inc.',
    name: 'T. Rowe Price Group, Inc.',
    symbol: '0KNY.L',
  },
  {
    value: '0KS2.L',
    label: '0KS2.L,  Public Service Enterprise Group Incorporated',
    name: 'Public Service Enterprise Group Incorporated',
    symbol: '0KS2.L',
  },
  {
    value: '0KS3.L',
    label: '0KS3.L,  Public Storage',
    name: 'Public Storage',
    symbol: '0KS3.L',
  },
  {
    value: '0KSA.L',
    label: '0KSA.L,  Pure Storage, Inc.',
    name: 'Pure Storage, Inc.',
    symbol: '0KSA.L',
  },
  {
    value: '0KSJ.L',
    label: '0KSJ.L,  Qorvo, Inc.',
    name: 'Qorvo, Inc.',
    symbol: '0KSJ.L',
  },
  {
    value: '0KSX.L',
    label: '0KSX.L,  Quest Diagnostics Incorporated',
    name: 'Quest Diagnostics Incorporated',
    symbol: '0KSX.L',
  },
  {
    value: '0KTS.L',
    label: '0KTS.L,  Ralph Lauren Corporation',
    name: 'Ralph Lauren Corporation',
    symbol: '0KTS.L',
  },
  {
    value: '0KUE.L',
    label: '0KUE.L,  Realty Income Corporation',
    name: 'Realty Income Corporation',
    symbol: '0KUE.L',
  },
  {
    value: '0KW4.L',
    label: '0KW4.L,  ResMed Inc.',
    name: 'ResMed Inc.',
    symbol: '0KW4.L',
  },
  {
    value: '0KX9.L',
    label: '0KX9.L,  Robert Half International Inc.',
    name: 'Robert Half International Inc.',
    symbol: '0KX9.L',
  },
  {
    value: '0KXA.L',
    label: '0KXA.L,  Rockwell Automation, Inc.',
    name: 'Rockwell Automation, Inc.',
    symbol: '0KXA.L',
  },
  {
    value: '0KXS.L',
    label: '0KXS.L,  Royal Gold, Inc.',
    name: 'Royal Gold, Inc.',
    symbol: '0KXS.L',
  },
  {
    value: '0KYY.L',
    label: '0KYY.L,  S&P Global Inc.',
    name: 'S&P Global Inc.',
    symbol: '0KYY.L',
  },
  {
    value: '0KYZ.L',
    label: '0KYZ.L,  SBA Communications Corporation',
    name: 'SBA Communications Corporation',
    symbol: '0KYZ.L',
  },
  {
    value: '0KZA.L',
    label: '0KZA.L,  SM Energy Company',
    name: 'SM Energy Company',
    symbol: '0KZA.L',
  },
  {
    value: '0L1G.L',
    label: '0L1G.L,  SS&C Technologies Holdings, Inc.',
    name: 'SS&C Technologies Holdings, Inc.',
    symbol: '0L1G.L',
  },
  {
    value: '0L35.L',
    label: '0L35.L,  Sarepta Therapeutics, Inc.',
    name: 'Sarepta Therapeutics, Inc.',
    symbol: '0L35.L',
  },
  {
    value: '0L3C.L',
    label: '0L3C.L,  Henry Schein, Inc.',
    name: 'Henry Schein, Inc.',
    symbol: '0L3C.L',
  },
  {
    value: '0L3H.L',
    label: '0L3H.L,  L3Harris Technologies, Inc.',
    name: 'L3Harris Technologies, Inc.',
    symbol: '0L3H.L',
  },
  {
    value: '0L3I.L',
    label: '0L3I.L,  The Charles Schwab Corporation',
    name: 'The Charles Schwab Corporation',
    symbol: '0L3I.L',
  },
  {
    value: '0L45.L',
    label: '0L45.L,  The Scotts Miracle-Gro Company',
    name: 'The Scotts Miracle-Gro Company',
    symbol: '0L45.L',
  },
  {
    value: '0L5A.L',
    label: '0L5A.L,  Sempra',
    name: 'Sempra',
    symbol: '0L5A.L',
  },
  {
    value: '0L5N.L',
    label: '0L5N.L,  ServiceNow, Inc.',
    name: 'ServiceNow, Inc.',
    symbol: '0L5N.L',
  },
  {
    value: '0L5V.L',
    label: '0L5V.L,  The Sherwin-Williams Company',
    name: 'The Sherwin-Williams Company',
    symbol: '0L5V.L',
  },
  {
    value: '0L6P.L',
    label: '0L6P.L,  Simon Property Group, Inc.',
    name: 'Simon Property Group, Inc.',
    symbol: '0L6P.L',
  },
  {
    value: '0L6Z.L',
    label: '0L6Z.L,  Sirius XM Holdings Inc.',
    name: 'Sirius XM Holdings Inc.',
    symbol: '0L6Z.L',
  },
  {
    value: '0L77.L',
    label: '0L77.L,  Skyworks Solutions, Inc.',
    name: 'Skyworks Solutions, Inc.',
    symbol: '0L77.L',
  },
  {
    value: '0L7A.L',
    label: '0L7A.L,  A. O. Smith Corporation',
    name: 'A. O. Smith Corporation',
    symbol: '0L7A.L',
  },
  {
    value: '0L7G.L',
    label: '0L7G.L,  Snap-on Incorporated',
    name: 'Snap-on Incorporated',
    symbol: '0L7G.L',
  },
  {
    value: '0L7S.L',
    label: '0L7S.L,  SolarEdge Technologies, Inc.',
    name: 'SolarEdge Technologies, Inc.',
    symbol: '0L7S.L',
  },
  {
    value: '0L85.L',
    label: '0L85.L,  Sorrento Therapeutics, Inc.',
    name: 'Sorrento Therapeutics, Inc.',
    symbol: '0L85.L',
  },
  {
    value: '0L8A.L',
    label: '0L8A.L,  The Southern Company',
    name: 'The Southern Company',
    symbol: '0L8A.L',
  },
  {
    value: '0L8B.L',
    label: '0L8B.L,  Southern Copper Corporation',
    name: 'Southern Copper Corporation',
    symbol: '0L8B.L',
  },
  {
    value: '0L8F.L',
    label: '0L8F.L,  Southwest Airlines Co.',
    name: 'Southwest Airlines Co.',
    symbol: '0L8F.L',
  },
  {
    value: '0L8U.L',
    label: '0L8U.L,  Spirit Airlines, Inc.',
    name: 'Spirit Airlines, Inc.',
    symbol: '0L8U.L',
  },
  {
    value: '0L9E.L',
    label: '0L9E.L,  Stanley Black & Decker, Inc.',
    name: 'Stanley Black & Decker, Inc.',
    symbol: '0L9E.L',
  },
  {
    value: '0L9G.L',
    label: '0L9G.L,  State Street Corporation',
    name: 'State Street Corporation',
    symbol: '0L9G.L',
  },
  {
    value: '0L9Q.L',
    label: '0L9Q.L,  Fiskars Oyj Abp',
    name: 'Fiskars Oyj Abp',
    symbol: '0L9Q.L',
  },
  {
    value: '0L9X.L',
    label: '0L9X.L,  Stitch Fix, Inc.',
    name: 'Stitch Fix, Inc.',
    symbol: '0L9X.L',
  },
  {
    value: '0LA6.L',
    label: '0LA6.L,  STORE Capital Corporation',
    name: 'STORE Capital Corporation',
    symbol: '0LA6.L',
  },
  {
    value: '0LBP.L',
    label: '0LBP.L,  Synopsys, Inc.',
    name: 'Synopsys, Inc.',
    symbol: '0LBP.L',
  },
  {
    value: '0LC3.L',
    label: '0LC3.L,  Synchrony Financial',
    name: 'Synchrony Financial',
    symbol: '0LC3.L',
  },
  {
    value: '0LC6.L',
    label: '0LC6.L,  Sysco Corporation',
    name: 'Sysco Corporation',
    symbol: '0LC6.L',
  },
  {
    value: '0LCE.L',
    label: '0LCE.L,  The TJX Companies, Inc.',
    name: 'The TJX Companies, Inc.',
    symbol: '0LCE.L',
  },
  {
    value: '0LCX.L',
    label: '0LCX.L,  Take-Two Interactive Software, Inc.',
    name: 'Take-Two Interactive Software, Inc.',
    symbol: '0LCX.L',
  },
  {
    value: '0LD5.L',
    label: '0LD5.L,  Tapestry, Inc.',
    name: 'Tapestry, Inc.',
    symbol: '0LD5.L',
  },
  {
    value: '0LD8.L',
    label: '0LD8.L,  Target Corporation',
    name: 'Target Corporation',
    symbol: '0LD8.L',
  },
  {
    value: '0LDR.L',
    label: '0LDR.L,  Teladoc Health, Inc.',
    name: 'Teladoc Health, Inc.',
    symbol: '0LDR.L',
  },
  {
    value: '0LE3.L',
    label: '0LE3.L,  Tellurian Inc.',
    name: 'Tellurian Inc.',
    symbol: '0LE3.L',
  },
  {
    value: '0LEC.L',
    label: '0LEC.L,  Cardlytics, Inc.',
    name: 'Cardlytics, Inc.',
    symbol: '0LEC.L',
  },
  {
    value: '0LEF.L',
    label: '0LEF.L,  Teradyne, Inc.',
    name: 'Teradyne, Inc.',
    symbol: '0LEF.L',
  },
  {
    value: '0LF5.L',
    label: '0LF5.L,  The Trade Desk, Inc.',
    name: 'The Trade Desk, Inc.',
    symbol: '0LF5.L',
  },
  {
    value: '0LH0.L',
    label: '0LH0.L,  Trupanion, Inc.',
    name: 'Trupanion, Inc.',
    symbol: '0LH0.L',
  },
  {
    value: '0LHL.L',
    label: '0LHL.L,  Twilio Inc.',
    name: 'Twilio Inc.',
    symbol: '0LHL.L',
  },
  {
    value: '0LHO.L',
    label: '0LHO.L,  Coherent Corp.',
    name: 'Coherent Corp.',
    symbol: '0LHO.L',
  },
  {
    value: '0LHP.L',
    label: '0LHP.L,  2U, Inc.',
    name: '2U, Inc.',
    symbol: '0LHP.L',
  },
  {
    value: '0LI9.L',
    label: '0LI9.L,  Ubiquiti Inc.',
    name: 'Ubiquiti Inc.',
    symbol: '0LI9.L',
  },
  {
    value: '0LIU.L',
    label: '0LIU.L,  United Airlines Holdings, Inc.',
    name: 'United Airlines Holdings, Inc.',
    symbol: '0LIU.L',
  },
  {
    value: '0LJB.L',
    label: '0LJB.L,  Uniti Group Inc.',
    name: 'Uniti Group Inc.',
    symbol: '0LJB.L',
  },
  {
    value: '0LJE.L',
    label: '0LJE.L,  Universal Display Corporation',
    name: 'Universal Display Corporation',
    symbol: '0LJE.L',
  },
  {
    value: '0LJN.L',
    label: '0LJN.L,  Unum Group',
    name: 'Unum Group',
    symbol: '0LJN.L',
  },
  {
    value: '0LJQ.L',
    label: '0LJQ.L,  Uranium Energy Corp.',
    name: 'Uranium Energy Corp.',
    symbol: '0LJQ.L',
  },
  {
    value: '0LK6.L',
    label: '0LK6.L,  Valero Energy Corporation',
    name: 'Valero Energy Corporation',
    symbol: '0LK6.L',
  },
  {
    value: '0LNT.L',
    label: '0LNT.L,  Fugro N.V.',
    name: 'Fugro N.V.',
    symbol: '0LNT.L',
  },
  {
    value: '0LO3.L',
    label: '0LO3.L,  Veeva Systems Inc.',
    name: 'Veeva Systems Inc.',
    symbol: '0LO3.L',
  },
  {
    value: '0LO4.L',
    label: '0LO4.L,  Ventas, Inc.',
    name: 'Ventas, Inc.',
    symbol: '0LO4.L',
  },
  {
    value: '0LOV.L',
    label: '0LOV.L,  Verastem, Inc.',
    name: 'Verastem, Inc.',
    symbol: '0LOV.L',
  },
  {
    value: '0LP5.L',
    label: '0LP5.L,  Veritone, Inc.',
    name: 'Veritone, Inc.',
    symbol: '0LP5.L',
  },
  {
    value: '0LQO.L',
    label: '0LQO.L,  VMware, Inc.',
    name: 'VMware, Inc.',
    symbol: '0LQO.L',
  },
  {
    value: '0LR2.L',
    label: '0LR2.L,  Vornado Realty Trust',
    name: 'Vornado Realty Trust',
    symbol: '0LR2.L',
  },
  {
    value: '0LRL.L',
    label: '0LRL.L,  Vuzix Corporation',
    name: 'Vuzix Corporation',
    symbol: '0LRL.L',
  },
  {
    value: '0LS8.L',
    label: '0LS8.L,  W. P. Carey Inc.',
    name: 'W. P. Carey Inc.',
    symbol: '0LS8.L',
  },
  {
    value: '0LSZ.L',
    label: '0LSZ.L,  Walgreens Boots Alliance, Inc.',
    name: 'Walgreens Boots Alliance, Inc.',
    symbol: '0LSZ.L',
  },
  {
    value: '0LTG.L',
    label: '0LTG.L,  Waste Management, Inc.',
    name: 'Waste Management, Inc.',
    symbol: '0LTG.L',
  },
  {
    value: '0LTI.L',
    label: '0LTI.L,  Waters Corporation',
    name: 'Waters Corporation',
    symbol: '0LTI.L',
  },
  {
    value: '0LXB.L',
    label: '0LXB.L,  The Williams Companies, Inc.',
    name: 'The Williams Companies, Inc.',
    symbol: '0LXB.L',
  },
  {
    value: '0M18.L',
    label: '0M18.L,  Workday, Inc.',
    name: 'Workday, Inc.',
    symbol: '0M18.L',
  },
  {
    value: '0M29.L',
    label: '0M29.L,  Xylem Inc.',
    name: 'Xylem Inc.',
    symbol: '0M29.L',
  },
  {
    value: '0M3F.L',
    label: '0M3F.L,  Zendesk, Inc.',
    name: 'Zendesk, Inc.',
    symbol: '0M3F.L',
  },
  {
    value: '0M3Q.L',
    label: '0M3Q.L,  Zoetis Inc.',
    name: 'Zoetis Inc.',
    symbol: '0M3Q.L',
  },
  {
    value: '0M40.L',
    label: '0M40.L,  Zynerba Pharmaceuticals, Inc.',
    name: 'Zynerba Pharmaceuticals, Inc.',
    symbol: '0M40.L',
  },
  {
    value: '0M6I.L',
    label: '0M6I.L,  Heijmans N.V.',
    name: 'Heijmans N.V.',
    symbol: '0M6I.L',
  },
  {
    value: '0MCG.L',
    label: '0MCG.L,  Hamborner REIT AG',
    name: 'Hamborner REIT AG',
    symbol: '0MCG.L',
  },
  {
    value: '0MEL.L',
    label: '0MEL.L,  Deceuninck NV',
    name: 'Deceuninck NV',
    symbol: '0MEL.L',
  },
  {
    value: '0MJH.L',
    label: '0MJH.L,  Zumtobel Group AG',
    name: 'Zumtobel Group AG',
    symbol: '0MJH.L',
  },
  {
    value: '0MRM.L',
    label: '0MRM.L,  Bang & Olufsen a/s',
    name: 'Bang & Olufsen a/s',
    symbol: '0MRM.L',
  },
  {
    value: '0MT8.L',
    label: '0MT8.L,  Drägerwerk AG & Co. KGaA',
    name: 'Drägerwerk AG & Co. KGaA',
    symbol: '0MT8.L',
  },
  {
    value: '0MTP.L',
    label: '0MTP.L,  IDEX Biometrics ASA',
    name: 'IDEX Biometrics ASA',
    symbol: '0MTP.L',
  },
  {
    value: '0MV8.L',
    label: '0MV8.L,  Vantiva S.A.',
    name: 'Vantiva S.A.',
    symbol: '0MV8.L',
  },
  {
    value: '0N4A.L',
    label: '0N4A.L,  Vivesto AB',
    name: 'Vivesto AB',
    symbol: '0N4A.L',
  },
  {
    value: '0N66.L',
    label: '0N66.L,  ATOSS Software AG',
    name: 'ATOSS Software AG',
    symbol: '0N66.L',
  },
  {
    value: '0N7N.L',
    label: '0N7N.L,  Compagnie des Alpes SA',
    name: 'Compagnie des Alpes SA',
    symbol: '0N7N.L',
  },
  {
    value: '0NC0.L',
    label: '0NC0.L,  Immobel SA',
    name: 'Immobel SA',
    symbol: '0NC0.L',
  },
  {
    value: '0NES.L',
    label: '0NES.L,  Oriola Oyj',
    name: 'Oriola Oyj',
    symbol: '0NES.L',
  },
  {
    value: '0NHS.L',
    label: '0NHS.L,  Nutrien Ltd.',
    name: 'Nutrien Ltd.',
    symbol: '0NHS.L',
  },
  {
    value: '0NIQ.L',
    label: '0NIQ.L,  Sartorius Aktiengesellschaft',
    name: 'Sartorius Aktiengesellschaft',
    symbol: '0NIQ.L',
  },
  {
    value: '0NZN.L',
    label: '0NZN.L,  Robertet SA',
    name: 'Robertet SA',
    symbol: '0NZN.L',
  },
  {
    value: '0O0E.L',
    label: '0O0E.L,  BigBen Interactive',
    name: 'BigBen Interactive',
    symbol: '0O0E.L',
  },
  {
    value: '0O27.L',
    label: '0O27.L,  Dr. Hönle AG',
    name: 'Dr. Hönle AG',
    symbol: '0O27.L',
  },
  {
    value: '0OAL.L',
    label: '0OAL.L,  American International Group, Inc.',
    name: 'American International Group, Inc.',
    symbol: '0OAL.L',
  },
  {
    value: '0OB3.L',
    label: '0OB3.L,  Valneva SE',
    name: 'Valneva SE',
    symbol: '0OB3.L',
  },
  {
    value: '0OC9.L',
    label: '0OC9.L,  Stemmer Imaging AG',
    name: 'Stemmer Imaging AG',
    symbol: '0OC9.L',
  },
  {
    value: '0OOJ.L',
    label: '0OOJ.L,  Ordina N.V.',
    name: 'Ordina N.V.',
    symbol: '0OOJ.L',
  },
  {
    value: '0OPY.L',
    label: '0OPY.L,  Parrot S.A.',
    name: 'Parrot S.A.',
    symbol: '0OPY.L',
  },
  {
    value: '0P07.L',
    label: '0P07.L,  First Mining Gold Corp.',
    name: 'First Mining Gold Corp.',
    symbol: '0P07.L',
  },
  {
    value: '0P59.L',
    label: '0P59.L,  Colgate-Palmolive Company',
    name: 'Colgate-Palmolive Company',
    symbol: '0P59.L',
  },
  {
    value: '0PMJ.L',
    label: '0PMJ.L,  Siemens Healthineers AG',
    name: 'Siemens Healthineers AG',
    symbol: '0PMJ.L',
  },
  {
    value: '0Q19.L',
    label: '0Q19.L,  CEVA, Inc.',
    name: 'CEVA, Inc.',
    symbol: '0Q19.L',
  },
  {
    value: '0Q3C.L',
    label: '0Q3C.L,  SÜSS MicroTec SE',
    name: 'SÜSS MicroTec SE',
    symbol: '0Q3C.L',
  },
  {
    value: '0Q92.L',
    label: '0Q92.L,  Selvaag Bolig ASA',
    name: 'Selvaag Bolig ASA',
    symbol: '0Q92.L',
  },
  {
    value: '0QB4.L',
    label: '0QB4.L,  Medios AG',
    name: 'Medios AG',
    symbol: '0QB4.L',
  },
  {
    value: '0QC9.L',
    label: '0QC9.L,  LEG Immobilien SE',
    name: 'LEG Immobilien SE',
    symbol: '0QC9.L',
  },
  {
    value: '0QFK.L',
    label: '0QFK.L,  Celyad Oncology SA',
    name: 'Celyad Oncology SA',
    symbol: '0QFK.L',
  },
  {
    value: '0QJX.L',
    label: '0QJX.L,  EFG International AG',
    name: 'EFG International AG',
    symbol: '0QJX.L',
  },
  {
    value: '0QK5.L',
    label: '0QK5.L,  INFICON Holding AG',
    name: 'INFICON Holding AG',
    symbol: '0QK5.L',
  },
  {
    value: '0QK8.L',
    label: '0QK8.L,  Baxter International Inc.',
    name: 'Baxter International Inc.',
    symbol: '0QK8.L',
  },
  {
    value: '0QKA.L',
    label: '0QKA.L,  Rieter Holding AG',
    name: 'Rieter Holding AG',
    symbol: '0QKA.L',
  },
  {
    value: '0QKB.L',
    label: '0QKB.L,  LEM Holding SA',
    name: 'LEM Holding SA',
    symbol: '0QKB.L',
  },
  {
    value: '0QKD.L',
    label: '0QKD.L,  Forbo Holding AG',
    name: 'Forbo Holding AG',
    symbol: '0QKD.L',
  },
  {
    value: '0QKJ.L',
    label: '0QKJ.L,  Bobst Group SA',
    name: 'Bobst Group SA',
    symbol: '0QKJ.L',
  },
  {
    value: '0QKL.L',
    label: '0QKL.L,  Komax Holding AG',
    name: 'Komax Holding AG',
    symbol: '0QKL.L',
  },
  {
    value: '0QKR.L',
    label: '0QKR.L,  Arbonia AG',
    name: 'Arbonia AG',
    symbol: '0QKR.L',
  },
  {
    value: '0QLD.L',
    label: '0QLD.L,  Swissquote Group Holding Ltd',
    name: 'Swissquote Group Holding Ltd',
    symbol: '0QLD.L',
  },
  {
    value: '0QLE.L',
    label: '0QLE.L,  Valora Holding AG',
    name: 'Valora Holding AG',
    symbol: '0QLE.L',
  },
  {
    value: '0QLN.L',
    label: '0QLN.L,  Tecan Group AG',
    name: 'Tecan Group AG',
    symbol: '0QLN.L',
  },
  {
    value: '0QLQ.L',
    label: '0QLQ.L,  Ypsomed Holding AG',
    name: 'Ypsomed Holding AG',
    symbol: '0QLQ.L',
  },
  {
    value: '0QLW.L',
    label: '0QLW.L,  ALSO Holding AG',
    name: 'ALSO Holding AG',
    symbol: '0QLW.L',
  },
  {
    value: '0QLZ.L',
    label: '0QLZ.L,  Bellevue Group AG',
    name: 'Bellevue Group AG',
    symbol: '0QLZ.L',
  },
  {
    value: '0QM5.L',
    label: '0QM5.L,  Emmi AG',
    name: 'Emmi AG',
    symbol: '0QM5.L',
  },
  {
    value: '0QMR.L',
    label: '0QMR.L,  BELIMO Holding AG',
    name: 'BELIMO Holding AG',
    symbol: '0QMR.L',
  },
  {
    value: '0QMS.L',
    label: '0QMS.L,  dormakaba Holding AG',
    name: 'dormakaba Holding AG',
    symbol: '0QMS.L',
  },
  {
    value: '0QN2.L',
    label: '0QN2.L,  Interroll Holding AG',
    name: 'Interroll Holding AG',
    symbol: '0QN2.L',
  },
  {
    value: '0QND.L',
    label: '0QND.L,  Bachem Holding AG',
    name: 'Bachem Holding AG',
    symbol: '0QND.L',
  },
  {
    value: '0QNE.L',
    label: '0QNE.L,  Leonteq AG',
    name: 'Leonteq AG',
    symbol: '0QNE.L',
  },
  {
    value: '0QNG.L',
    label: '0QNG.L,  Jungfraubahn Holding AG',
    name: 'Jungfraubahn Holding AG',
    symbol: '0QNG.L',
  },
  {
    value: '0QNH.L',
    label: '0QNH.L,  Huber+Suhner AG',
    name: 'Huber+Suhner AG',
    symbol: '0QNH.L',
  },
  {
    value: '0QNM.L',
    label: '0QNM.L,  Adecco Group AG',
    name: 'Adecco Group AG',
    symbol: '0QNM.L',
  },
  {
    value: '0QNO.L',
    label: '0QNO.L,  Lonza Group AG',
    name: 'Lonza Group AG',
    symbol: '0QNO.L',
  },
  {
    value: '0QNU.L',
    label: '0QNU.L,  Luzerner Kantonalbank AG',
    name: 'Luzerner Kantonalbank AG',
    symbol: '0QNU.L',
  },
  {
    value: '0QO2.L',
    label: '0QO2.L,  Burkhalter Holding AG',
    name: 'Burkhalter Holding AG',
    symbol: '0QO2.L',
  },
  {
    value: '0QO8.L',
    label: '0QO8.L,  PSP Swiss Property AG',
    name: 'PSP Swiss Property AG',
    symbol: '0QO8.L',
  },
  {
    value: '0QOB.L',
    label: '0QOB.L,  Autoneum Holding AG',
    name: 'Autoneum Holding AG',
    symbol: '0QOB.L',
  },
  {
    value: '0QOC.L',
    label: '0QOC.L,  HBM Healthcare Investments AG',
    name: 'HBM Healthcare Investments AG',
    symbol: '0QOC.L',
  },
  {
    value: '0QOL.L',
    label: '0QOL.L,  Kardex Holding AG',
    name: 'Kardex Holding AG',
    symbol: '0QOL.L',
  },
  {
    value: '0QON.L',
    label: '0QON.L,  Ascom Holding AG',
    name: 'Ascom Holding AG',
    symbol: '0QON.L',
  },
  {
    value: '0QOQ.L',
    label: '0QOQ.L,  Partners Group Holding AG',
    name: 'Partners Group Holding AG',
    symbol: '0QOQ.L',
  },
  {
    value: '0QP6.L',
    label: '0QP6.L,  Leclanché SA',
    name: 'Leclanché SA',
    symbol: '0QP6.L',
  },
  {
    value: '0QP7.L',
    label: '0QP7.L,  COLTENE Holding AG',
    name: 'COLTENE Holding AG',
    symbol: '0QP7.L',
  },
  {
    value: '0QPD.L',
    label: '0QPD.L,  Allreal Holding AG',
    name: 'Allreal Holding AG',
    symbol: '0QPD.L',
  },
  {
    value: '0QPJ.L',
    label: '0QPJ.L,  Cembra Money Bank AG',
    name: 'Cembra Money Bank AG',
    symbol: '0QPJ.L',
  },
  {
    value: '0QPU.L',
    label: '0QPU.L,  Valiant Holding AG',
    name: 'Valiant Holding AG',
    symbol: '0QPU.L',
  },
  {
    value: '0QQ0.L',
    label: '0QQ0.L,  BKW AG',
    name: 'BKW AG',
    symbol: '0QQ0.L',
  },
  {
    value: '0QQ7.L',
    label: '0QQ7.L,  Meyer Burger Technology AG',
    name: 'Meyer Burger Technology AG',
    symbol: '0QQ7.L',
  },
  {
    value: '0QQE.L',
    label: '0QQE.L,  DKSH Holding AG',
    name: 'DKSH Holding AG',
    symbol: '0QQE.L',
  },
  {
    value: '0QQI.L',
    label: '0QQI.L,  HOCHDORF Holding AG',
    name: 'HOCHDORF Holding AG',
    symbol: '0QQI.L',
  },
  {
    value: '0QQJ.L',
    label: '0QQJ.L,  Evolva Holding SA',
    name: 'Evolva Holding SA',
    symbol: '0QQJ.L',
  },
  {
    value: '0QQN.L',
    label: '0QQN.L,  Bucher Industries AG',
    name: 'Bucher Industries AG',
    symbol: '0QQN.L',
  },
  {
    value: '0QQO.L',
    label: '0QQO.L,  Siegfried Holding AG',
    name: 'Siegfried Holding AG',
    symbol: '0QQO.L',
  },
  {
    value: '0QQR.L',
    label: '0QQR.L,  Gurit Holding AG',
    name: 'Gurit Holding AG',
    symbol: '0QQR.L',
  },
  {
    value: '0QQZ.L',
    label: '0QQZ.L,  St. Galler Kantonalbank AG',
    name: 'St. Galler Kantonalbank AG',
    symbol: '0QQZ.L',
  },
  {
    value: '0QR1.L',
    label: '0QR1.L,  Schweiter Technologies AG',
    name: 'Schweiter Technologies AG',
    symbol: '0QR1.L',
  },
  {
    value: '0QR3.L',
    label: '0QR3.L,  PG&E Corporation',
    name: 'PG&E Corporation',
    symbol: '0QR3.L',
  },
  {
    value: '0QS5.L',
    label: '0QS5.L,  Bossard Holding AG',
    name: 'Bossard Holding AG',
    symbol: '0QS5.L',
  },
  {
    value: '0QTF.L',
    label: '0QTF.L,  McPhy Energy S.A.',
    name: 'McPhy Energy S.A.',
    symbol: '0QTF.L',
  },
  {
    value: '0QUC.L',
    label: '0QUC.L,  SLM Solutions Group AG',
    name: 'SLM Solutions Group AG',
    symbol: '0QUC.L',
  },
  {
    value: '0QW1.L',
    label: '0QW1.L,  Bystronic AG',
    name: 'Bystronic AG',
    symbol: '0QW1.L',
  },
  {
    value: '0QY4.L',
    label: '0QY4.L,  Las Vegas Sands Corp.',
    name: 'Las Vegas Sands Corp.',
    symbol: '0QY4.L',
  },
  {
    value: '0QYD.L',
    label: '0QYD.L,  Yum! Brands, Inc.',
    name: 'Yum! Brands, Inc.',
    symbol: '0QYD.L',
  },
  {
    value: '0QYE.L',
    label: '0QYE.L,  Enphase Energy, Inc.',
    name: 'Enphase Energy, Inc.',
    symbol: '0QYE.L',
  },
  {
    value: '0QYH.L',
    label: '0QYH.L,  3D Systems Corporation',
    name: '3D Systems Corporation',
    symbol: '0QYH.L',
  },
  {
    value: '0QYK.L',
    label: '0QYK.L,  Wynn Resorts, Limited',
    name: 'Wynn Resorts, Limited',
    symbol: '0QYK.L',
  },
  {
    value: '0QYL.L',
    label: '0QYL.L,  Rambus Inc.',
    name: 'Rambus Inc.',
    symbol: '0QYL.L',
  },
  {
    value: '0QYM.L',
    label: '0QYM.L,  Fortuna Silver Mines Inc.',
    name: 'Fortuna Silver Mines Inc.',
    symbol: '0QYM.L',
  },
  {
    value: '0QYN.L',
    label: '0QYN.L,  B2Gold Corp.',
    name: 'B2Gold Corp.',
    symbol: '0QYN.L',
  },
  {
    value: '0QYR.L',
    label: '0QYR.L,  Panasonic Corp',
    name: 'Panasonic Corp',
    symbol: '0QYR.L',
  },
  {
    value: '0QYU.L',
    label: '0QYU.L,  Morgan Stanley',
    name: 'Morgan Stanley',
    symbol: '0QYU.L',
  },
  {
    value: '0QZ4.L',
    label: '0QZ4.L,  Delta Air Lines, Inc.',
    name: 'Delta Air Lines, Inc.',
    symbol: '0QZ4.L',
  },
  {
    value: '0QZ5.L',
    label: '0QZ5.L,  Cognizant Technology Solutions Corporation',
    name: 'Cognizant Technology Solutions Corporation',
    symbol: '0QZ5.L',
  },
  {
    value: '0QZ7.L',
    label: '0QZ7.L,  Kandi Technologies Group, Inc.',
    name: 'Kandi Technologies Group, Inc.',
    symbol: '0QZ7.L',
  },
  {
    value: '0QZA.L',
    label: '0QZA.L,  ConocoPhillips',
    name: 'ConocoPhillips',
    symbol: '0QZA.L',
  },
  {
    value: '0QZF.L',
    label: '0QZF.L,  Western Digital Corporation',
    name: 'Western Digital Corporation',
    symbol: '0QZF.L',
  },
  {
    value: '0QZS.L',
    label: '0QZS.L,  Tripadvisor, Inc.',
    name: 'Tripadvisor, Inc.',
    symbol: '0QZS.L',
  },
  {
    value: '0QZU.L',
    label: '0QZU.L,  Vertex Pharmaceuticals Incorporated',
    name: 'Vertex Pharmaceuticals Incorporated',
    symbol: '0QZU.L',
  },
  {
    value: '0QZX.L',
    label: '0QZX.L,  FedEx Corporation',
    name: 'FedEx Corporation',
    symbol: '0QZX.L',
  },
  {
    value: '0QZZ.L',
    label: '0QZZ.L,  BlackRock, Inc.',
    name: 'BlackRock, Inc.',
    symbol: '0QZZ.L',
  },
  {
    value: '0R03.L',
    label: '0R03.L,  The Travelers Companies, Inc.',
    name: 'The Travelers Companies, Inc.',
    symbol: '0R03.L',
  },
  {
    value: '0R07.L',
    label: '0R07.L,  Pan American Silver Corp.',
    name: 'Pan American Silver Corp.',
    symbol: '0R07.L',
  },
  {
    value: '0R08.L',
    label: '0R08.L,  United Parcel Service, Inc.',
    name: 'United Parcel Service, Inc.',
    symbol: '0R08.L',
  },
  {
    value: '0R09.L',
    label: '0R09.L,  Splunk Inc.',
    name: 'Splunk Inc.',
    symbol: '0R09.L',
  },
  {
    value: '0R0A.L',
    label: '0R0A.L,  Hecla Mining Company',
    name: 'Hecla Mining Company',
    symbol: '0R0A.L',
  },
  {
    value: '0R0G.L',
    label: '0R0G.L,  Mondelez International, Inc.',
    name: 'Mondelez International, Inc.',
    symbol: '0R0G.L',
  },
  {
    value: '0R0H.L',
    label: '0R0H.L,  Thermo Fisher Scientific Inc.',
    name: 'Thermo Fisher Scientific Inc.',
    symbol: '0R0H.L',
  },
  {
    value: '0R0P.L',
    label: '0R0P.L,  BlackBerry Limited',
    name: 'BlackBerry Limited',
    symbol: '0R0P.L',
  },
  {
    value: '0R0U.L',
    label: '0R0U.L,  Coeur Mining, Inc.',
    name: 'Coeur Mining, Inc.',
    symbol: '0R0U.L',
  },
  {
    value: '0R0V.L',
    label: '0R0V.L,  SunPower Corporation',
    name: 'SunPower Corporation',
    symbol: '0R0V.L',
  },
  {
    value: '0R13.L',
    label: '0R13.L,  Church & Dwight Co., Inc.',
    name: 'Church & Dwight Co., Inc.',
    symbol: '0R13.L',
  },
  {
    value: '0R15.L',
    label: '0R15.L,  SoftBank Group Corp',
    name: 'SoftBank Group Corp',
    symbol: '0R15.L',
  },
  {
    value: '0R18.L',
    label: '0R18.L,  Best Buy Co., Inc.',
    name: 'Best Buy Co., Inc.',
    symbol: '0R18.L',
  },
  {
    value: '0R19.L',
    label: '0R19.L,  Becton, Dickinson and Company',
    name: 'Becton, Dickinson and Company',
    symbol: '0R19.L',
  },
  {
    value: '0R1B.L',
    label: '0R1B.L,  Biogen Inc',
    name: 'Biogen Inc',
    symbol: '0R1B.L',
  },
  {
    value: '0R1C.L',
    label: '0R1C.L,  GoPro, Inc.',
    name: 'GoPro, Inc.',
    symbol: '0R1C.L',
  },
  {
    value: '0R1D.L',
    label: '0R1D.L,  Sangamo Therapeutics, Inc.',
    name: 'Sangamo Therapeutics, Inc.',
    symbol: '0R1D.L',
  },
  {
    value: '0R1E.L',
    label: '0R1E.L,  Nintendo Co Ltd',
    name: 'Nintendo Co Ltd',
    symbol: '0R1E.L',
  },
  {
    value: '0R1R.L',
    label: '0R1R.L,  Kellogg Company',
    name: 'Kellogg Company',
    symbol: '0R1R.L',
  },
  {
    value: '0R1T.L',
    label: '0R1T.L,  Expedia Group, Inc.',
    name: 'Expedia Group, Inc.',
    symbol: '0R1T.L',
  },
  {
    value: '0R1X.L',
    label: '0R1X.L,  General Mills, Inc.',
    name: 'General Mills, Inc.',
    symbol: '0R1X.L',
  },
  {
    value: '0R20.L',
    label: '0R20.L,  Santacruz Silver Mining Ltd.',
    name: 'Santacruz Silver Mining Ltd.',
    symbol: '0R20.L',
  },
  {
    value: '0R23.L',
    label: '0R23.L,  Halliburton Company',
    name: 'Halliburton Company',
    symbol: '0R23.L',
  },
  {
    value: '0R25.L',
    label: '0R25.L,  Prospect Capital Corporation',
    name: 'Prospect Capital Corporation',
    symbol: '0R25.L',
  },
  {
    value: '0R29.L',
    label: '0R29.L,  Intuitive Surgical, Inc.',
    name: 'Intuitive Surgical, Inc.',
    symbol: '0R29.L',
  },
  {
    value: '0R2B.L',
    label: '0R2B.L,  Danaher Corporation',
    name: 'Danaher Corporation',
    symbol: '0R2B.L',
  },
  {
    value: '0R2C.L',
    label: '0R2C.L,  Endeavour Silver Corp.',
    name: 'Endeavour Silver Corp.',
    symbol: '0R2C.L',
  },
  {
    value: '0R2E.L',
    label: '0R2E.L,  Union Pacific Corporation',
    name: 'Union Pacific Corporation',
    symbol: '0R2E.L',
  },
  {
    value: '0R2H.L',
    label: '0R2H.L,  Texas Instruments Incorporated',
    name: 'Texas Instruments Incorporated',
    symbol: '0R2H.L',
  },
  {
    value: '0R2I.L',
    label: '0R2I.L,  Under Armour, Inc.',
    name: 'Under Armour, Inc.',
    symbol: '0R2I.L',
  },
  {
    value: '0R2J.L',
    label: '0R2J.L,  Agnico Eagle Mines Limited',
    name: 'Agnico Eagle Mines Limited',
    symbol: '0R2J.L',
  },
  {
    value: '0R2L.L',
    label: '0R2L.L,  T-Mobile US, Inc.',
    name: 'T-Mobile US, Inc.',
    symbol: '0R2L.L',
  },
  {
    value: '0R2M.L',
    label: '0R2M.L,  Regeneron Pharmaceuticals, Inc.',
    name: 'Regeneron Pharmaceuticals, Inc.',
    symbol: '0R2M.L',
  },
  {
    value: '0R2P.L',
    label: '0R2P.L,  Deere & Company',
    name: 'Deere & Company',
    symbol: '0R2P.L',
  },
  {
    value: '0R2X.L',
    label: '0R2X.L,  Corning Incorporated',
    name: 'Corning Incorporated',
    symbol: '0R2X.L',
  },
  {
    value: '0R2Y.L',
    label: '0R2Y.L,  Adobe Inc',
    name: 'Adobe Inc',
    symbol: '0R2Y.L',
  },
  {
    value: '0R32.L',
    label: '0R32.L,  Abercrombie & Fitch Co.',
    name: 'Abercrombie & Fitch Co.',
    symbol: '0R32.L',
  },
  {
    value: '0R33.L',
    label: '0R33.L,  Emerson Electric Co.',
    name: 'Emerson Electric Co.',
    symbol: '0R33.L',
  },
  {
    value: '0R35.L',
    label: '0R35.L,  Cameco Corporation',
    name: 'Cameco Corporation',
    symbol: '0R35.L',
  },
  {
    value: '0R3E.L',
    label: '0R3E.L,  Lockheed Martin Corporation',
    name: 'Lockheed Martin Corporation',
    symbol: '0R3E.L',
  },
  {
    value: '0R6M.L',
    label: '0R6M.L,  Intershop Holding AG',
    name: 'Intershop Holding AG',
    symbol: '0R6M.L',
  },
  {
    value: '0R6V.L',
    label: '0R6V.L,  mobilezone holding ag',
    name: 'mobilezone holding ag',
    symbol: '0R6V.L',
  },
  {
    value: '0R9H.L',
    label: '0R9H.L,  Banca Sistema S.p.A.',
    name: 'Banca Sistema S.p.A.',
    symbol: '0R9H.L',
  },
  {
    value: '0RAR.L',
    label: '0RAR.L,  Stratec SE',
    name: 'Stratec SE',
    symbol: '0RAR.L',
  },
  {
    value: '0RD1.L',
    label: '0RD1.L,  Camurus AB (publ)',
    name: 'Camurus AB (publ)',
    symbol: '0RD1.L',
  },
  {
    value: '0RDG.L',
    label: '0RDG.L,  Steinhoff International Holdings N.V.',
    name: 'Steinhoff International Holdings N.V.',
    symbol: '0RDG.L',
  },
  {
    value: '0REK.L',
    label: '0REK.L,  TransDigm Group Incorporated',
    name: 'TransDigm Group Incorporated',
    symbol: '0REK.L',
  },
  {
    value: '0RFX.L',
    label: '0RFX.L,  Bell Food Group AG',
    name: 'Bell Food Group AG',
    symbol: '0RFX.L',
  },
  {
    value: '0RG5.L',
    label: '0RG5.L,  Qt Group Oyj',
    name: 'Qt Group Oyj',
    symbol: '0RG5.L',
  },
  {
    value: '0RGI.L',
    label: '0RGI.L,  Cosmo Pharmaceuticals N.V.',
    name: 'Cosmo Pharmaceuticals N.V.',
    symbol: '0RGI.L',
  },
  {
    value: '0RH1.L',
    label: '0RH1.L,  Kinnevik AB',
    name: 'Kinnevik AB',
    symbol: '0RH1.L',
  },
  {
    value: '0RHU.L',
    label: '0RHU.L,  Flughafen Wien Aktiengesellschaft',
    name: 'Flughafen Wien Aktiengesellschaft',
    symbol: '0RHU.L',
  },
  {
    value: '0RNP.L',
    label: '0RNP.L,  Avantium N.V.',
    name: 'Avantium N.V.',
    symbol: '0RNP.L',
  },
  {
    value: '0ROG.L',
    label: '0ROG.L,  Galenica AG',
    name: 'Galenica AG',
    symbol: '0ROG.L',
  },
  {
    value: '0ROQ.L',
    label: '0ROQ.L,  Comet Holding AG',
    name: 'Comet Holding AG',
    symbol: '0ROQ.L',
  },
  {
    value: '0ROZ.L',
    label: '0ROZ.L,  X-FAB Silicon Foundries SE',
    name: 'X-FAB Silicon Foundries SE',
    symbol: '0ROZ.L',
  },
  {
    value: '0RP8.L',
    label: '0RP8.L,  Liechtensteinische Landesbank Aktiengesellschaft',
    name: 'Liechtensteinische Landesbank Aktiengesellschaft',
    symbol: '0RP8.L',
  },
  {
    value: '0RPW.L',
    label: '0RPW.L,  Janus Henderson Group plc',
    name: 'Janus Henderson Group plc',
    symbol: '0RPW.L',
  },
  {
    value: '0RPY.L',
    label: '0RPY.L,  Boozt AB (publ)',
    name: 'Boozt AB (publ)',
    symbol: '0RPY.L',
  },
  {
    value: '0RQK.L',
    label: '0RQK.L,  Belysse Group NV',
    name: 'Belysse Group NV',
    symbol: '0RQK.L',
  },
  {
    value: '0RRB.L',
    label: '0RRB.L,  Zur Rose Group AG',
    name: 'Zur Rose Group AG',
    symbol: '0RRB.L',
  },
  {
    value: '0RS2.L',
    label: '0RS2.L,  Mensch und Maschine Software SE',
    name: 'Mensch und Maschine Software SE',
    symbol: '0RS2.L',
  },
  {
    value: '0RTL.L',
    label: '0RTL.L,  Landis+Gyr Group AG',
    name: 'Landis+Gyr Group AG',
    symbol: '0RTL.L',
  },
  {
    value: '0RU5.L',
    label: '0RU5.L,  BerGenBio ASA',
    name: 'BerGenBio ASA',
    symbol: '0RU5.L',
  },
  {
    value: '0RU6.L',
    label: '0RU6.L,  SpareBank 1 Ostlandet',
    name: 'SpareBank 1 Ostlandet',
    symbol: '0RU6.L',
  },
  {
    value: '0SAY.L',
    label: '0SAY.L,  DWS Group GmbH & Co. KGaA',
    name: 'DWS Group GmbH & Co. KGaA',
    symbol: '0SAY.L',
  },
  {
    value: '0SD7.L',
    label: '0SD7.L,  Kabel Deutschland Holding AG',
    name: 'Kabel Deutschland Holding AG',
    symbol: '0SD7.L',
  },
  {
    value: '0SGO.L',
    label: '0SGO.L,  Dropbox, Inc.',
    name: 'Dropbox, Inc.',
    symbol: '0SGO.L',
  },
  {
    value: '0SOM.L',
    label: '0SOM.L,  BHG Group AB (publ)',
    name: 'BHG Group AB (publ)',
    symbol: '0SOM.L',
  },
  {
    value: '0SZI.L',
    label: '0SZI.L,  Corbus Pharmaceuticals Holdings, Inc.',
    name: 'Corbus Pharmaceuticals Holdings, Inc.',
    symbol: '0SZI.L',
  },
  {
    value: '0T6G.L',
    label: '0T6G.L,  Homology Medicines, Inc.',
    name: 'Homology Medicines, Inc.',
    symbol: '0T6G.L',
  },
  {
    value: '0TMV.L',
    label: '0TMV.L,  Five9, Inc.',
    name: 'Five9, Inc.',
    symbol: '0TMV.L',
  },
  {
    value: '0TZZ.L',
    label: '0TZZ.L,  HubSpot, Inc.',
    name: 'HubSpot, Inc.',
    symbol: '0TZZ.L',
  },
  {
    value: '0U3Q.L',
    label: '0U3Q.L,  InterDigital, Inc.',
    name: 'InterDigital, Inc.',
    symbol: '0U3Q.L',
  },
  {
    value: '0U6C.L',
    label: '0U6C.L,  Axalta Coating Systems Ltd.',
    name: 'Axalta Coating Systems Ltd.',
    symbol: '0U6C.L',
  },
  {
    value: '0U6R.L',
    label: '0U6R.L,  Bunge Limited',
    name: 'Bunge Limited',
    symbol: '0U6R.L',
  },
  {
    value: '0UC0.L',
    label: '0UC0.L,  Nordic American Tankers Limited',
    name: 'Nordic American Tankers Limited',
    symbol: '0UC0.L',
  },
  {
    value: '0UC3.L',
    label: '0UC3.L,  Norwegian Cruise Line Holdings Ltd.',
    name: 'Norwegian Cruise Line Holdings Ltd.',
    symbol: '0UC3.L',
  },
  {
    value: '0UGS.L',
    label: '0UGS.L,  Alamos Gold Inc.',
    name: 'Alamos Gold Inc.',
    symbol: '0UGS.L',
  },
  {
    value: '0UH8.L',
    label: '0UH8.L,  Almaden Minerals Ltd.',
    name: 'Almaden Minerals Ltd.',
    symbol: '0UH8.L',
  },
  {
    value: '0UKH.L',
    label: '0UKH.L,  Bank of Montreal',
    name: 'Bank of Montreal',
    symbol: '0UKH.L',
  },
  {
    value: '0UKI.L',
    label: '0UKI.L,  The Bank of Nova Scotia',
    name: 'The Bank of Nova Scotia',
    symbol: '0UKI.L',
  },
  {
    value: '0URY.L',
    label: '0URY.L,  Denison Mines Corp.',
    name: 'Denison Mines Corp.',
    symbol: '0URY.L',
  },
  {
    value: '0UW4.L',
    label: '0UW4.L,  Fission Uranium Corp.',
    name: 'Fission Uranium Corp.',
    symbol: '0UW4.L',
  },
  {
    value: '0UWL.L',
    label: '0UWL.L,  Freehold Royalties Ltd.',
    name: 'Freehold Royalties Ltd.',
    symbol: '0UWL.L',
  },
  {
    value: '0UXL.L',
    label: '0UXL.L,  Giga Metals Corporation',
    name: 'Giga Metals Corporation',
    symbol: '0UXL.L',
  },
  {
    value: '0UYN.L',
    label: '0UYN.L,  GoldMining Inc.',
    name: 'GoldMining Inc.',
    symbol: '0UYN.L',
  },
  {
    value: '0UYO.L',
    label: '0UYO.L,  Goldsource Mines Inc.',
    name: 'Goldsource Mines Inc.',
    symbol: '0UYO.L',
  },
  {
    value: '0UYS.L',
    label: '0UYS.L,  GoviEx Uranium Inc.',
    name: 'GoviEx Uranium Inc.',
    symbol: '0UYS.L',
  },
  {
    value: '0V46.L',
    label: '0V46.L,  Liberty Gold Corp.',
    name: 'Liberty Gold Corp.',
    symbol: '0V46.L',
  },
  {
    value: '0V50.L',
    label: '0V50.L,  RingCentral, Inc.',
    name: 'RingCentral, Inc.',
    symbol: '0V50.L',
  },
  {
    value: '0V9D.L',
    label: '0V9D.L,  NexGen Energy Ltd.',
    name: 'NexGen Energy Ltd.',
    symbol: '0V9D.L',
  },
  {
    value: '0V9N.L',
    label: '0V9N.L,  Science Applications International Corporation',
    name: 'Science Applications International Corporation',
    symbol: '0V9N.L',
  },
  {
    value: '0VGE.L',
    label: '0VGE.L,  SSR Mining Inc.',
    name: 'SSR Mining Inc.',
    symbol: '0VGE.L',
  },
  {
    value: '0VGI.L',
    label: '0VGI.L,  TG Therapeutics, Inc.',
    name: 'TG Therapeutics, Inc.',
    symbol: '0VGI.L',
  },
  {
    value: '0VGM.L',
    label: '0VGM.L,  Sandstorm Gold Ltd.',
    name: 'Sandstorm Gold Ltd.',
    symbol: '0VGM.L',
  },
  {
    value: '0VHA.L',
    label: '0VHA.L,  Shopify Inc.',
    name: 'Shopify Inc.',
    symbol: '0VHA.L',
  },
  {
    value: '0VHI.L',
    label: '0VHI.L,  SilverCrest Metals Inc.',
    name: 'SilverCrest Metals Inc.',
    symbol: '0VHI.L',
  },
  {
    value: '0VIF.L',
    label: '0VIF.L,  Sprott Physical Silver Trust',
    name: 'Sprott Physical Silver Trust',
    symbol: '0VIF.L',
  },
  {
    value: '0VOA.L',
    label: '0VOA.L,  Wesdome Gold Mines Ltd.',
    name: 'Wesdome Gold Mines Ltd.',
    symbol: '0VOA.L',
  },
  {
    value: '0W19.L',
    label: '0W19.L,  DATAGROUP SE',
    name: 'DATAGROUP SE',
    symbol: '0W19.L',
  },
  {
    value: '0XL5.L',
    label: '0XL5.L,  Zuora, Inc.',
    name: 'Zuora, Inc.',
    symbol: '0XL5.L',
  },
  {
    value: '0XNH.L',
    label: '0XNH.L,  DocuSign, Inc.',
    name: 'DocuSign, Inc.',
    symbol: '0XNH.L',
  },
  {
    value: '0XVU.L',
    label: '0XVU.L,  Zscaler, Inc.',
    name: 'Zscaler, Inc.',
    symbol: '0XVU.L',
  },
  {
    value: '0Y0Y.L',
    label: '0Y0Y.L,  Accenture plc',
    name: 'Accenture plc',
    symbol: '0Y0Y.L',
  },
  {
    value: '0Y4Q.L',
    label: '0Y4Q.L,  Willis Towers Watson Public Limited Company',
    name: 'Willis Towers Watson Public Limited Company',
    symbol: '0Y4Q.L',
  },
  {
    value: '0Y5E.L',
    label: '0Y5E.L,  Perrigo Company plc',
    name: 'Perrigo Company plc',
    symbol: '0Y5E.L',
  },
  {
    value: '0Y6X.L',
    label: '0Y6X.L,  Medtronic plc',
    name: 'Medtronic plc',
    symbol: '0Y6X.L',
  },
  {
    value: '0Y9S.L',
    label: '0Y9S.L,  Check Point Software Technologies Ltd.',
    name: 'Check Point Software Technologies Ltd.',
    symbol: '0Y9S.L',
  },
  {
    value: '0YCP.L',
    label: '0YCP.L,  Aptiv PLC',
    name: 'Aptiv PLC',
    symbol: '0YCP.L',
  },
  {
    value: '0YG7.L',
    label: '0YG7.L,  Beijer Alma AB (publ)',
    name: 'Beijer Alma AB (publ)',
    symbol: '0YG7.L',
  },
  {
    value: '0YXG.L',
    label: '0YXG.L,  Broadcom Inc.',
    name: 'Broadcom Inc.',
    symbol: '0YXG.L',
  },
  {
    value: '0Z1Q.L',
    label: '0Z1Q.L,  Manchester United plc',
    name: 'Manchester United plc',
    symbol: '0Z1Q.L',
  },
  {
    value: '0ZFN.L',
    label: '0ZFN.L,  Sonos, Inc.',
    name: 'Sonos, Inc.',
    symbol: '0ZFN.L',
  },
  {
    value: '0ZQ7.L',
    label: '0ZQ7.L,  Instone Real Estate Group SE',
    name: 'Instone Real Estate Group SE',
    symbol: '0ZQ7.L',
  },
  {
    value: '3IN.L',
    label: '3IN.L,  3i Infrastructure plc',
    name: '3i Infrastructure plc',
    symbol: '3IN.L',
  },
  {
    value: '3UKS.L',
    label: '3UKS.L,  WisdomTree FTSE 100 3x Daily Short',
    name: 'WisdomTree FTSE 100 3x Daily Short',
    symbol: '3UKS.L',
  },
  {
    value: '7DIG.L',
    label: '7DIG.L,  7digital Group plc',
    name: '7digital Group plc',
    symbol: '7DIG.L',
  },
  {
    value: '888.L',
    label: '888.L,  888 Holdings plc',
    name: '888 Holdings plc',
    symbol: '888.L',
  },
  {
    value: '88E.L',
    label: '88E.L,  88 Energy Limited',
    name: '88 Energy Limited',
    symbol: '88E.L',
  },
  {
    value: 'AA4.L',
    label: 'AA4.L,  Amedeo Air Four Plus Limited',
    name: 'Amedeo Air Four Plus Limited',
    symbol: 'AA4.L',
  },
  {
    value: 'AAAP.L',
    label: 'AAAP.L,  Anglo African Agriculture Plc',
    name: 'Anglo African Agriculture Plc',
    symbol: 'AAAP.L',
  },
  {
    value: 'AADV.L',
    label: 'AADV.L,  Albion Development VCT PLC',
    name: 'Albion Development VCT PLC',
    symbol: 'AADV.L',
  },
  {
    value: 'AAEV.L',
    label: 'AAEV.L,  Albion Enterprise VCT PLC',
    name: 'Albion Enterprise VCT PLC',
    symbol: 'AAEV.L',
  },
  {
    value: 'AAF.L',
    label: 'AAF.L,  Airtel Africa Plc',
    name: 'Airtel Africa Plc',
    symbol: 'AAF.L',
  },
  {
    value: 'AAIF.L',
    label: 'AAIF.L,  abrdn Asian Income Fund Limited',
    name: 'abrdn Asian Income Fund Limited',
    symbol: 'AAIF.L',
  },
  {
    value: 'AAL.L',
    label: 'AAL.L,  Anglo American plc',
    name: 'Anglo American plc',
    symbol: 'AAL.L',
  },
  {
    value: 'AAS.L',
    label: 'AAS.L,  abrdn Asia Focus plc',
    name: 'abrdn Asia Focus plc',
    symbol: 'AAS.L',
  },
  {
    value: 'AATG.L',
    label: 'AATG.L,  Albion Technology & General VCT PLC',
    name: 'Albion Technology & General VCT PLC',
    symbol: 'AATG.L',
  },
  {
    value: 'AAU.L',
    label: 'AAU.L,  Ariana Resources plc',
    name: 'Ariana Resources plc',
    symbol: 'AAU.L',
  },
  {
    value: 'AAVC.L',
    label: 'AAVC.L,  Albion Venture Capital Trust PLC',
    name: 'Albion Venture Capital Trust PLC',
    symbol: 'AAVC.L',
  },
  {
    value: 'AAZ.L',
    label: 'AAZ.L,  Anglo Asian Mining PLC',
    name: 'Anglo Asian Mining PLC',
    symbol: 'AAZ.L',
  },
  {
    value: 'ABC.L',
    label: 'ABC.L,  Abcam plc',
    name: 'Abcam plc',
    symbol: 'ABC.L',
  },
  {
    value: 'ABD.L',
    label: 'ABD.L,  abrdn New Dawn Investment Trust plc',
    name: 'abrdn New Dawn Investment Trust plc',
    symbol: 'ABD.L',
  },
  {
    value: 'ABDP.L',
    label: 'ABDP.L,  AB Dynamics plc',
    name: 'AB Dynamics plc',
    symbol: 'ABDP.L',
  },
  {
    value: 'ABF.L',
    label: 'ABF.L,  Associated British Foods plc',
    name: 'Associated British Foods plc',
    symbol: 'ABF.L',
  },
  {
    value: 'ACC.L',
    label: 'ACC.L,  Access Intelligence Plc',
    name: 'Access Intelligence Plc',
    symbol: 'ACC.L',
  },
  {
    value: 'ACP.L',
    label: 'ACP.L,  Armadale Capital Plc',
    name: 'Armadale Capital Plc',
    symbol: 'ACP.L',
  },
  {
    value: 'ACRL.L',
    label: 'ACRL.L,  Accrol Group Holdings plc',
    name: 'Accrol Group Holdings plc',
    symbol: 'ACRL.L',
  },
  {
    value: 'ACSO.L',
    label: 'ACSO.L,  accesso Technology Group plc',
    name: 'accesso Technology Group plc',
    symbol: 'ACSO.L',
  },
  {
    value: 'ACT.L',
    label: 'ACT.L,  Actual Experience plc',
    name: 'Actual Experience plc',
    symbol: 'ACT.L',
  },
  {
    value: 'ADA.L',
    label: 'ADA.L,  Adams Plc',
    name: 'Adams Plc',
    symbol: 'ADA.L',
  },
  {
    value: 'ADIG.L',
    label: 'ADIG.L,  Aberdeen Diversified Income and Growth Trust plc',
    name: 'Aberdeen Diversified Income and Growth Trust plc',
    symbol: 'ADIG.L',
  },
  {
    value: 'ADM.L',
    label: 'ADM.L,  Admiral Group plc',
    name: 'Admiral Group plc',
    symbol: 'ADM.L',
  },
  {
    value: 'ADME.L',
    label: 'ADME.L,  ADM Energy plc',
    name: 'ADM Energy plc',
    symbol: 'ADME.L',
  },
  {
    value: 'ADT.L',
    label: 'ADT.L,  AdEPT Technology Group plc',
    name: 'AdEPT Technology Group plc',
    symbol: 'ADT.L',
  },
  {
    value: 'ADT1.L',
    label: 'ADT1.L,  Adriatic Metals PLC',
    name: 'Adriatic Metals PLC',
    symbol: 'ADT1.L',
  },
  {
    value: 'AEG.L',
    label: 'AEG.L,  Active Energy Group Plc',
    name: 'Active Energy Group Plc',
    symbol: 'AEG.L',
  },
  {
    value: 'AEO.L',
    label: 'AEO.L,  Aeorema Communications plc',
    name: 'Aeorema Communications plc',
    symbol: 'AEO.L',
  },
  {
    value: 'AEP.L',
    label: 'AEP.L,  Anglo-Eastern Plantations Plc',
    name: 'Anglo-Eastern Plantations Plc',
    symbol: 'AEP.L',
  },
  {
    value: 'AERI.L',
    label: 'AERI.L,  Aquila European Renewables Income PLC',
    name: 'Aquila European Renewables Income PLC',
    symbol: 'AERI.L',
  },
  {
    value: 'AERS.L',
    label: 'AERS.L,  Aquila European Renewables Income PLC',
    name: 'Aquila European Renewables Income PLC',
    symbol: 'AERS.L',
  },
  {
    value: 'AEWU.L',
    label: 'AEWU.L,  AEW UK REIT plc',
    name: 'AEW UK REIT plc',
    symbol: 'AEWU.L',
  },
  {
    value: 'AEX.L',
    label: 'AEX.L,  Aminex PLC',
    name: 'Aminex PLC',
    symbol: 'AEX.L',
  },
  {
    value: 'AFC.L',
    label: 'AFC.L,  AFC Energy plc',
    name: 'AFC Energy plc',
    symbol: 'AFC.L',
  },
  {
    value: 'AFM.L',
    label: 'AFM.L,  Alpha Financial Markets Consulting plc',
    name: 'Alpha Financial Markets Consulting plc',
    symbol: 'AFM.L',
  },
  {
    value: 'AFN.L',
    label: 'AFN.L,  ADVFN Plc',
    name: 'ADVFN Plc',
    symbol: 'AFN.L',
  },
  {
    value: 'AFX.L',
    label: 'AFX.L,  Alpha FX Group plc',
    name: 'Alpha FX Group plc',
    symbol: 'AFX.L',
  },
  {
    value: 'AGFX.L',
    label: 'AGFX.L,  Argentex Group PLC',
    name: 'Argentex Group PLC',
    symbol: 'AGFX.L',
  },
  {
    value: 'AGL.L',
    label: 'AGL.L,  ANGLE plc',
    name: 'ANGLE plc',
    symbol: 'AGL.L',
  },
  {
    value: 'AGM.L',
    label: 'AGM.L,  Applied Graphene Materials plc',
    name: 'Applied Graphene Materials plc',
    symbol: 'AGM.L',
  },
  {
    value: 'AGR.L',
    label: 'AGR.L,  Assura Plc',
    name: 'Assura Plc',
    symbol: 'AGR.L',
  },
  {
    value: 'AGT.L',
    label: 'AGT.L,  AVI Global Trust plc',
    name: 'AVI Global Trust plc',
    symbol: 'AGT.L',
  },
  {
    value: 'AGTA.L',
    label: 'AGTA.L,  Agriterra Limited',
    name: 'Agriterra Limited',
    symbol: 'AGTA.L',
  },
  {
    value: 'AGY.L',
    label: 'AGY.L,  Allergy Therapeutics plc',
    name: 'Allergy Therapeutics plc',
    symbol: 'AGY.L',
  },
  {
    value: 'AHT.L',
    label: 'AHT.L,  Ashtead Group plc',
    name: 'Ashtead Group plc',
    symbol: 'AHT.L',
  },
  {
    value: 'AIBG.L',
    label: 'AIBG.L,  AIB Group plc',
    name: 'AIB Group plc',
    symbol: 'AIBG.L',
  },
  {
    value: 'AIE.L',
    label: 'AIE.L,  Ashoka India Equity Investment Trust Plc',
    name: 'Ashoka India Equity Investment Trust Plc',
    symbol: 'AIE.L',
  },
  {
    value: 'AIEA.L',
    label: 'AIEA.L,  AIREA plc',
    name: 'AIREA plc',
    symbol: 'AIEA.L',
  },
  {
    value: 'AIGA.L',
    label: 'AIGA.L,  WisdomTree Agriculture',
    name: 'WisdomTree Agriculture',
    symbol: 'AIGA.L',
  },
  {
    value: 'AIQ.L',
    label: 'AIQ.L,  AIQ Limited',
    name: 'AIQ Limited',
    symbol: 'AIQ.L',
  },
  {
    value: 'AIRE.L',
    label: 'AIRE.L,  Alternative Income REIT PLC',
    name: 'Alternative Income REIT PLC',
    symbol: 'AIRE.L',
  },
  {
    value: 'AJB.L',
    label: 'AJB.L,  AJ Bell plc',
    name: 'AJ Bell plc',
    symbol: 'AJB.L',
  },
  {
    value: 'AJG.L',
    label: 'AJG.L,  Atlantis Japan Growth Fund',
    name: 'Atlantis Japan Growth Fund',
    symbol: 'AJG.L',
  },
  {
    value: 'AJIT.L',
    label: 'AJIT.L,  abrdn Japan Investment Trust plc',
    name: 'abrdn Japan Investment Trust plc',
    symbol: 'AJIT.L',
  },
  {
    value: 'AJOT.L',
    label: 'AJOT.L,  AVI Japan Opportunity Trust PLC',
    name: 'AVI Japan Opportunity Trust PLC',
    symbol: 'AJOT.L',
  },
  {
    value: 'ALAI.L',
    label: 'ALAI.L,  abrdn Latin American Income Fund Limited',
    name: 'abrdn Latin American Income Fund Limited',
    symbol: 'ALAI.L',
  },
  {
    value: 'ALBA.L',
    label: 'ALBA.L,  Alba Mineral Resources plc',
    name: 'Alba Mineral Resources plc',
    symbol: 'ALBA.L',
  },
  {
    value: 'ALFA.L',
    label: 'ALFA.L,  Alfa Financial Software Holdings PLC',
    name: 'Alfa Financial Software Holdings PLC',
    symbol: 'ALFA.L',
  },
  {
    value: 'ALGW.L',
    label: 'ALGW.L,  Alpha Growth plc',
    name: 'Alpha Growth plc',
    symbol: 'ALGW.L',
  },
  {
    value: 'ALM.L',
    label: 'ALM.L,  Allied Minds plc',
    name: 'Allied Minds plc',
    symbol: 'ALM.L',
  },
  {
    value: 'ALT.L',
    label: 'ALT.L,  Altitude Group plc',
    name: 'Altitude Group plc',
    symbol: 'ALT.L',
  },
  {
    value: 'ALTN.L',
    label: 'ALTN.L,  AltynGold plc',
    name: 'AltynGold plc',
    symbol: 'ALTN.L',
  },
  {
    value: 'ALU.L',
    label: 'ALU.L,  The Alumasc Group plc',
    name: 'The Alumasc Group plc',
    symbol: 'ALU.L',
  },
  {
    value: 'AMAT.L',
    label: 'AMAT.L,  Amati AIM VCT plc',
    name: 'Amati AIM VCT plc',
    symbol: 'AMAT.L',
  },
  {
    value: 'AMC.L',
    label: 'AMC.L,  Amur Minerals Corporation',
    name: 'Amur Minerals Corporation',
    symbol: 'AMC.L',
  },
  {
    value: 'AMGO.L',
    label: 'AMGO.L,  Amigo Holdings PLC',
    name: 'Amigo Holdings PLC',
    symbol: 'AMGO.L',
  },
  {
    value: 'AML.L',
    label: 'AML.L,  Aston Martin Lagonda Global Holdings plc',
    name: 'Aston Martin Lagonda Global Holdings plc',
    symbol: 'AML.L',
  },
  {
    value: 'AMS.L',
    label: 'AMS.L,  Advanced Medical Solutions Group plc',
    name: 'Advanced Medical Solutions Group plc',
    symbol: 'AMS.L',
  },
  {
    value: 'ANCR.L',
    label: 'ANCR.L,  Animalcare Group plc',
    name: 'Animalcare Group plc',
    symbol: 'ANCR.L',
  },
  {
    value: 'ANG.L',
    label: 'ANG.L,  Angling Direct PLC',
    name: 'Angling Direct PLC',
    symbol: 'ANG.L',
  },
  {
    value: 'ANGS.L',
    label: 'ANGS.L,  Angus Energy plc',
    name: 'Angus Energy plc',
    symbol: 'ANGS.L',
  },
  {
    value: 'ANIC.L',
    label: 'ANIC.L,  Agronomics Limited',
    name: 'Agronomics Limited',
    symbol: 'ANIC.L',
  },
  {
    value: 'ANII.L',
    label: 'ANII.L,  Aberdeen New India Investment Trust PLC',
    name: 'Aberdeen New India Investment Trust PLC',
    symbol: 'ANII.L',
  },
  {
    value: 'ANP.L',
    label: 'ANP.L,  Anpario plc',
    name: 'Anpario plc',
    symbol: 'ANP.L',
  },
  {
    value: 'ANTO.L',
    label: 'ANTO.L,  Antofagasta plc',
    name: 'Antofagasta plc',
    symbol: 'ANTO.L',
  },
  {
    value: 'ANX.L',
    label: 'ANX.L,  Anexo Group Plc',
    name: 'Anexo Group Plc',
    symbol: 'ANX.L',
  },
  {
    value: 'AOF.L',
    label: 'AOF.L,  Africa Opportunity Fund Limited',
    name: 'Africa Opportunity Fund Limited',
    symbol: 'AOF.L',
  },
  {
    value: 'APAX.L',
    label: 'APAX.L,  Apax Global Alpha Limited',
    name: 'Apax Global Alpha Limited',
    symbol: 'APAX.L',
  },
  {
    value: 'APH.L',
    label: 'APH.L,  Alliance Pharma plc',
    name: 'Alliance Pharma plc',
    symbol: 'APH.L',
  },
  {
    value: 'APP.L',
    label: 'APP.L,  Appreciate Group plc',
    name: 'Appreciate Group plc',
    symbol: 'APP.L',
  },
  {
    value: 'APQ.L',
    label: 'APQ.L,  APQ Global Limited',
    name: 'APQ Global Limited',
    symbol: 'APQ.L',
  },
  {
    value: 'APTD.L',
    label: 'APTD.L,  Aptitude Software Group plc',
    name: 'Aptitude Software Group plc',
    symbol: 'APTD.L',
  },
  {
    value: 'AQSG.L',
    label: 'AQSG.L,  Aquila Services Group plc',
    name: 'Aquila Services Group plc',
    symbol: 'AQSG.L',
  },
  {
    value: 'AQX.L',
    label: 'AQX.L,  Aquis Exchange PLC',
    name: 'Aquis Exchange PLC',
    symbol: 'AQX.L',
  },
  {
    value: 'ARB.L',
    label: 'ARB.L,  Argo Blockchain plc',
    name: 'Argo Blockchain plc',
    symbol: 'ARB.L',
  },
  {
    value: 'ARBB.L',
    label: 'ARBB.L,  Arbuthnot Banking Group PLC',
    name: 'Arbuthnot Banking Group PLC',
    symbol: 'ARBB.L',
  },
  {
    value: 'ARC.L',
    label: 'ARC.L,  Arcontech Group plc',
    name: 'Arcontech Group plc',
    symbol: 'ARC.L',
  },
  {
    value: 'ARCM.L',
    label: 'ARCM.L,  Arc Minerals Limited',
    name: 'Arc Minerals Limited',
    symbol: 'ARCM.L',
  },
  {
    value: 'ARG.L',
    label: 'ARG.L,  Argos Resources Ltd',
    name: 'Argos Resources Ltd',
    symbol: 'ARG.L',
  },
  {
    value: 'ARGO.L',
    label: 'ARGO.L,  Argo Group Limited',
    name: 'Argo Group Limited',
    symbol: 'ARGO.L',
  },
  {
    value: 'ARIX.L',
    label: 'ARIX.L,  Arix Bioscience plc',
    name: 'Arix Bioscience plc',
    symbol: 'ARIX.L',
  },
  {
    value: 'ARK.L',
    label: 'ARK.L,  Arkle Resources PLC',
    name: 'Arkle Resources PLC',
    symbol: 'ARK.L',
  },
  {
    value: 'ARR.L',
    label: 'ARR.L,  Aurora Investment Trust plc',
    name: 'Aurora Investment Trust plc',
    symbol: 'ARR.L',
  },
  {
    value: 'ARS.L',
    label: 'ARS.L,  Asiamet Resources Limited',
    name: 'Asiamet Resources Limited',
    symbol: 'ARS.L',
  },
  {
    value: 'ARTL.L',
    label: 'ARTL.L,  Alpha Real Trust Limited',
    name: 'Alpha Real Trust Limited',
    symbol: 'ARTL.L',
  },
  {
    value: 'ASAI.L',
    label: 'ASAI.L,  ASA International Group PLC',
    name: 'ASA International Group PLC',
    symbol: 'ASAI.L',
  },
  {
    value: 'ASC.L',
    label: 'ASC.L,  ASOS Plc',
    name: 'ASOS Plc',
    symbol: 'ASC.L',
  },
  {
    value: 'ASCI.L',
    label: 'ASCI.L,  abrdn Smaller Companies Income Trust plc',
    name: 'abrdn Smaller Companies Income Trust plc',
    symbol: 'ASCI.L',
  },
  {
    value: 'ASCL.L',
    label: 'ASCL.L,  Ascential plc',
    name: 'Ascential plc',
    symbol: 'ASCL.L',
  },
  {
    value: 'ASHM.L',
    label: 'ASHM.L,  Ashmore Group PLC',
    name: 'Ashmore Group PLC',
    symbol: 'ASHM.L',
  },
  {
    value: 'ASIT.L',
    label: 'ASIT.L,  Aberforth Split Level Income Trust PLC',
    name: 'Aberforth Split Level Income Trust PLC',
    symbol: 'ASIT.L',
  },
  {
    value: 'ASL.L',
    label: 'ASL.L,  Aberforth Smaller Companies Trust Plc',
    name: 'Aberforth Smaller Companies Trust Plc',
    symbol: 'ASL.L',
  },
  {
    value: 'ASLI.L',
    label: 'ASLI.L,  abrdn European Logistics Income plc',
    name: 'abrdn European Logistics Income plc',
    symbol: 'ASLI.L',
  },
  {
    value: 'AST.L',
    label: 'AST.L,  Ascent Resources Plc',
    name: 'Ascent Resources Plc',
    symbol: 'AST.L',
  },
  {
    value: 'ASTO.L',
    label: 'ASTO.L,  AssetCo plc',
    name: 'AssetCo plc',
    symbol: 'ASTO.L',
  },
  {
    value: 'ASY.L',
    label: 'ASY.L,  Andrews Sykes Group plc',
    name: 'Andrews Sykes Group plc',
    symbol: 'ASY.L',
  },
  {
    value: 'ATM.L',
    label: 'ATM.L,  AfriTin Mining Limited',
    name: 'AfriTin Mining Limited',
    symbol: 'ATM.L',
  },
  {
    value: 'ATQT.L',
    label: 'ATQT.L,  ATTRAQT Group plc',
    name: 'ATTRAQT Group plc',
    symbol: 'ATQT.L',
  },
  {
    value: 'ATR.L',
    label:
      'ATR.L,  Schroders Investment Trusts - Schroder Asian Total Return Investment Company plc',
    name: 'Schroders Investment Trusts - Schroder Asian Total Return Investment Company plc',
    symbol: 'ATR.L',
  },
  {
    value: 'ATS.L',
    label: 'ATS.L,  Artemis Alpha Trust plc',
    name: 'Artemis Alpha Trust plc',
    symbol: 'ATS.L',
  },
  {
    value: 'ATST.L',
    label: 'ATST.L,  Alliance Trust PLC',
    name: 'Alliance Trust PLC',
    symbol: 'ATST.L',
  },
  {
    value: 'ATT.L',
    label: 'ATT.L,  Allianz Technology Trust PLC',
    name: 'Allianz Technology Trust PLC',
    symbol: 'ATT.L',
  },
  {
    value: 'ATY.L',
    label: 'ATY.L,  Athelney Trust plc',
    name: 'Athelney Trust plc',
    symbol: 'ATY.L',
  },
  {
    value: 'ATYM.L',
    label: 'ATYM.L,  Atalaya Mining Plc',
    name: 'Atalaya Mining Plc',
    symbol: 'ATYM.L',
  },
  {
    value: 'AUGM.L',
    label: 'AUGM.L,  Augmentum Fintech PLC',
    name: 'Augmentum Fintech PLC',
    symbol: 'AUGM.L',
  },
  {
    value: 'AUK.L',
    label: 'AUK.L,  Aukett Swanke Group Plc',
    name: 'Aukett Swanke Group Plc',
    symbol: 'AUK.L',
  },
  {
    value: 'AURA.L',
    label: 'AURA.L,  Aura Energy Limited',
    name: 'Aura Energy Limited',
    symbol: 'AURA.L',
  },
  {
    value: 'AUTG.L',
    label: 'AUTG.L,  Autins Group plc',
    name: 'Autins Group plc',
    symbol: 'AUTG.L',
  },
  {
    value: 'AUTO.L',
    label: 'AUTO.L,  Auto Trader Group plc',
    name: 'Auto Trader Group plc',
    symbol: 'AUTO.L',
  },
  {
    value: 'AV.L',
    label: 'AV.L,  Aviva plc',
    name: 'Aviva plc',
    symbol: 'AV.L',
  },
  {
    value: 'AVAP.L',
    label: 'AVAP.L,  Avation PLC',
    name: 'Avation PLC',
    symbol: 'AVAP.L',
  },
  {
    value: 'AVCT.L',
    label: 'AVCT.L,  Avacta Group Plc',
    name: 'Avacta Group Plc',
    symbol: 'AVCT.L',
  },
  {
    value: 'AVG.L',
    label: 'AVG.L,  Avingtrans plc',
    name: 'Avingtrans plc',
    symbol: 'AVG.L',
  },
  {
    value: 'AVO.L',
    label: 'AVO.L,  Advanced Oncotherapy plc',
    name: 'Advanced Oncotherapy plc',
    symbol: 'AVO.L',
  },
  {
    value: 'AVON.L',
    label: 'AVON.L,  Avon Protection plc',
    name: 'Avon Protection plc',
    symbol: 'AVON.L',
  },
  {
    value: 'AVV.L',
    label: 'AVV.L,  AVEVA Group plc',
    name: 'AVEVA Group plc',
    symbol: 'AVV.L',
  },
  {
    value: 'AXI.L',
    label: 'AXI.L,  Axiom European Financial Debt Limited',
    name: 'Axiom European Financial Debt Limited',
    symbol: 'AXI.L',
  },
  {
    value: 'AXS.L',
    label: 'AXS.L,  Accsys Technologies PLC',
    name: 'Accsys Technologies PLC',
    symbol: 'AXS.L',
  },
  {
    value: 'AYM.L',
    label: 'AYM.L,  Anglesey Mining plc',
    name: 'Anglesey Mining plc',
    symbol: 'AYM.L',
  },
  {
    value: 'AZN.L',
    label: 'AZN.L,  AstraZeneca PLC',
    name: 'AstraZeneca PLC',
    symbol: 'AZN.L',
  },
  {
    value: 'BAB.L',
    label: 'BAB.L,  Babcock International Group PLC',
    name: 'Babcock International Group PLC',
    symbol: 'BAB.L',
  },
  {
    value: 'BAF.L',
    label: 'BAF.L,  British & American Investment Trust PLC',
    name: 'British & American Investment Trust PLC',
    symbol: 'BAF.L',
  },
  {
    value: 'BAG.L',
    label: 'BAG.L,  A.G. BARR p.l.c.',
    name: 'A.G. BARR p.l.c.',
    symbol: 'BAG.L',
  },
  {
    value: 'BAKK.L',
    label: 'BAKK.L,  Bakkavor Group plc',
    name: 'Bakkavor Group plc',
    symbol: 'BAKK.L',
  },
  {
    value: 'BAR.L',
    label: 'BAR.L,  Brand Architekts Group plc',
    name: 'Brand Architekts Group plc',
    symbol: 'BAR.L',
  },
  {
    value: 'BARC.L',
    label: 'BARC.L,  Barclays PLC',
    name: 'Barclays PLC',
    symbol: 'BARC.L',
  },
  {
    value: 'BATS.L',
    label: 'BATS.L,  British American Tobacco p.l.c.',
    name: 'British American Tobacco p.l.c.',
    symbol: 'BATS.L',
  },
  {
    value: 'BBB.L',
    label: 'BBB.L,  Bigblu Broadband plc',
    name: 'Bigblu Broadband plc',
    symbol: 'BBB.L',
  },
  {
    value: 'BBGI.L',
    label: 'BBGI.L,  BBGI Global Infrastructure S.A.',
    name: 'BBGI Global Infrastructure S.A.',
    symbol: 'BBGI.L',
  },
  {
    value: 'BBH.L',
    label: 'BBH.L,  Bellevue Healthcare Trust plc',
    name: 'Bellevue Healthcare Trust plc',
    symbol: 'BBH.L',
  },
  {
    value: 'BBOX.L',
    label: 'BBOX.L,  Tritax Big Box REIT plc',
    name: 'Tritax Big Box REIT plc',
    symbol: 'BBOX.L',
  },
  {
    value: 'BBSN.L',
    label: 'BBSN.L,  Brave Bison Group plc',
    name: 'Brave Bison Group plc',
    symbol: 'BBSN.L',
  },
  {
    value: 'BBY.L',
    label: 'BBY.L,  Balfour Beatty plc',
    name: 'Balfour Beatty plc',
    symbol: 'BBY.L',
  },
  {
    value: 'BCPT.L',
    label: 'BCPT.L,  Balanced Commercial Property Trust Ltd',
    name: 'Balanced Commercial Property Trust Ltd',
    symbol: 'BCPT.L',
  },
  {
    value: 'BDEV.L',
    label: 'BDEV.L,  Barratt Developments plc',
    name: 'Barratt Developments plc',
    symbol: 'BDEV.L',
  },
  {
    value: 'BEG.L',
    label: 'BEG.L,  Begbies Traynor Group plc',
    name: 'Begbies Traynor Group plc',
    symbol: 'BEG.L',
  },
  {
    value: 'BEM.L',
    label: 'BEM.L,  Beowulf Mining plc',
    name: 'Beowulf Mining plc',
    symbol: 'BEM.L',
  },
  {
    value: 'BERI.L',
    label: 'BERI.L,  BlackRock Energy and Resources Income Trust plc',
    name: 'BlackRock Energy and Resources Income Trust plc',
    symbol: 'BERI.L',
  },
  {
    value: 'BEZ.L',
    label: 'BEZ.L,  Beazley plc',
    name: 'Beazley plc',
    symbol: 'BEZ.L',
  },
  {
    value: 'BGEO.L',
    label: 'BGEO.L,  Bank of Georgia Group PLC',
    name: 'Bank of Georgia Group PLC',
    symbol: 'BGEO.L',
  },
  {
    value: 'BGEU.L',
    label: 'BGEU.L,  Baillie Gifford European Growth Trust plc',
    name: 'Baillie Gifford European Growth Trust plc',
    symbol: 'BGEU.L',
  },
  {
    value: 'BGFD.L',
    label: 'BGFD.L,  The Baillie Gifford Japan Trust PLC',
    name: 'The Baillie Gifford Japan Trust PLC',
    symbol: 'BGFD.L',
  },
  {
    value: 'BGHL.L',
    label: 'BGHL.L,  Boussard & Gavaudan Holding Limited',
    name: 'Boussard & Gavaudan Holding Limited',
    symbol: 'BGHL.L',
  },
  {
    value: 'BGLF.L',
    label: 'BGLF.L,  Blackstone Loan Financing Limited',
    name: 'Blackstone Loan Financing Limited',
    symbol: 'BGLF.L',
  },
  {
    value: 'BGLP.L',
    label: 'BGLP.L,  Blackstone Loan Financing Limited',
    name: 'Blackstone Loan Financing Limited',
    symbol: 'BGLP.L',
  },
  {
    value: 'BGO.L',
    label: 'BGO.L,  Bango PLC',
    name: 'Bango PLC',
    symbol: 'BGO.L',
  },
  {
    value: 'BGS.L',
    label: 'BGS.L,  Baillie Gifford Shin Nippon PLC',
    name: 'Baillie Gifford Shin Nippon PLC',
    symbol: 'BGS.L',
  },
  {
    value: 'BGUK.L',
    label: 'BGUK.L,  Baillie Gifford UK Growth Trust plc',
    name: 'Baillie Gifford UK Growth Trust plc',
    symbol: 'BGUK.L',
  },
  {
    value: 'BHMG.L',
    label: 'BHMG.L,  BH Macro Limited',
    name: 'BH Macro Limited',
    symbol: 'BHMG.L',
  },
  {
    value: 'BHP.L',
    label: 'BHP.L,  BHP Group Limited',
    name: 'BHP Group Limited',
    symbol: 'BHP.L',
  },
  {
    value: 'BIDS.L',
    label: 'BIDS.L,  Bidstack Group Plc',
    name: 'Bidstack Group Plc',
    symbol: 'BIDS.L',
  },
  {
    value: 'BIFF.L',
    label: 'BIFF.L,  Biffa plc',
    name: 'Biffa plc',
    symbol: 'BIFF.L',
  },
  {
    value: 'BILN.L',
    label: 'BILN.L,  Billington Holdings Plc',
    name: 'Billington Holdings Plc',
    symbol: 'BILN.L',
  },
  {
    value: 'BIOG.L',
    label: 'BIOG.L,  The Biotech Growth Trust PLC',
    name: 'The Biotech Growth Trust PLC',
    symbol: 'BIOG.L',
  },
  {
    value: 'BIOM.L',
    label: 'BIOM.L,  Biome Technologies plc',
    name: 'Biome Technologies plc',
    symbol: 'BIOM.L',
  },
  {
    value: 'BION.L',
    label: 'BION.L,  BiON plc',
    name: 'BiON plc',
    symbol: 'BION.L',
  },
  {
    value: 'BIRD.L',
    label: 'BIRD.L,  Blackbird plc',
    name: 'Blackbird plc',
    symbol: 'BIRD.L',
  },
  {
    value: 'BIRG.L',
    label: 'BIRG.L,  Bank of Ireland Group plc',
    name: 'Bank of Ireland Group plc',
    symbol: 'BIRG.L',
  },
  {
    value: 'BISI.L',
    label: 'BISI.L,  Bisichi PLC',
    name: 'Bisichi PLC',
    symbol: 'BISI.L',
  },
  {
    value: 'BKG.L',
    label: 'BKG.L,  The Berkeley Group Holdings plc',
    name: 'The Berkeley Group Holdings plc',
    symbol: 'BKG.L',
  },
  {
    value: 'BKS.L',
    label: 'BKS.L,  Beeks Trading Corporation Ltd.',
    name: 'Beeks Trading Corporation Ltd.',
    symbol: 'BKS.L',
  },
  {
    value: 'BKY.L',
    label: 'BKY.L,  Berkeley Energia Limited',
    name: 'Berkeley Energia Limited',
    symbol: 'BKY.L',
  },
  {
    value: 'BLND.L',
    label: 'BLND.L,  British Land Company Plc',
    name: 'British Land Company Plc',
    symbol: 'BLND.L',
  },
  {
    value: 'BLOE.L',
    label: 'BLOE.L,  Block Energy Plc',
    name: 'Block Energy Plc',
    symbol: 'BLOE.L',
  },
  {
    value: 'BLP.L',
    label: 'BLP.L,  Blue Planet Investment Trust plc',
    name: 'Blue Planet Investment Trust plc',
    symbol: 'BLP.L',
  },
  {
    value: 'BLTG.L',
    label: 'BLTG.L,  Blancco Technology Group plc',
    name: 'Blancco Technology Group plc',
    symbol: 'BLTG.L',
  },
  {
    value: 'BLU.L',
    label: 'BLU.L,  Blue Star Capital plc',
    name: 'Blue Star Capital plc',
    symbol: 'BLU.L',
  },
  {
    value: 'BLV.L',
    label: 'BLV.L,  Belvoir Group PLC',
    name: 'Belvoir Group PLC',
    symbol: 'BLV.L',
  },
  {
    value: 'BLVN.L',
    label: 'BLVN.L,  Bowleven plc',
    name: 'Bowleven plc',
    symbol: 'BLVN.L',
  },
  {
    value: 'BMD.L',
    label: 'BMD.L,  Baronsmead Second Venture Trust plc',
    name: 'Baronsmead Second Venture Trust plc',
    symbol: 'BMD.L',
  },
  {
    value: 'BME.L',
    label: 'BME.L,  B&M European Value Retail S.A.',
    name: 'B&M European Value Retail S.A.',
    symbol: 'BME.L',
  },
  {
    value: 'BMK.L',
    label: 'BMK.L,  Benchmark Holdings plc',
    name: 'Benchmark Holdings plc',
    symbol: 'BMK.L',
  },
  {
    value: 'BMN.L',
    label: 'BMN.L,  Bushveld Minerals Limited',
    name: 'Bushveld Minerals Limited',
    symbol: 'BMN.L',
  },
  {
    value: 'BMS.L',
    label: 'BMS.L,  Braemar Plc',
    name: 'Braemar Plc',
    symbol: 'BMS.L',
  },
  {
    value: 'BMV.L',
    label: 'BMV.L,  Bluebird Merchant Ventures Limited',
    name: 'Bluebird Merchant Ventures Limited',
    symbol: 'BMV.L',
  },
  {
    value: 'BMY.L',
    label: 'BMY.L,  Bloomsbury Publishing Plc',
    name: 'Bloomsbury Publishing Plc',
    symbol: 'BMY.L',
  },
  {
    value: 'BNC.L',
    label: 'BNC.L,  Banco Santander, S.A.',
    name: 'Banco Santander, S.A.',
    symbol: 'BNC.L',
  },
  {
    value: 'BNKR.L',
    label: 'BNKR.L,  The Bankers Investment Trust PLC',
    name: 'The Bankers Investment Trust PLC',
    symbol: 'BNKR.L',
  },
  {
    value: 'BNZL.L',
    label: 'BNZL.L,  Bunzl plc',
    name: 'Bunzl plc',
    symbol: 'BNZL.L',
  },
  {
    value: 'BOCH.L',
    label: 'BOCH.L,  Bank of Cyprus Holdings Public Limited Company',
    name: 'Bank of Cyprus Holdings Public Limited Company',
    symbol: 'BOCH.L',
  },
  {
    value: 'BOD.L',
    label: 'BOD.L,  Botswana Diamonds plc',
    name: 'Botswana Diamonds plc',
    symbol: 'BOD.L',
  },
  {
    value: 'BOE.L',
    label: 'BOE.L,  The Boeing Company',
    name: 'The Boeing Company',
    symbol: 'BOE.L',
  },
  {
    value: 'BOIL.L',
    label: 'BOIL.L,  Baron Oil Plc',
    name: 'Baron Oil Plc',
    symbol: 'BOIL.L',
  },
  {
    value: 'BOKU.L',
    label: 'BOKU.L,  Boku, Inc.',
    name: 'Boku, Inc.',
    symbol: 'BOKU.L',
  },
  {
    value: 'BONH.L',
    label: 'BONH.L,  Bonhill Group Plc',
    name: 'Bonhill Group Plc',
    symbol: 'BONH.L',
  },
  {
    value: 'BOO.L',
    label: 'BOO.L,  boohoo group plc',
    name: 'boohoo group plc',
    symbol: 'BOO.L',
  },
  {
    value: 'BOOM.L',
    label: 'BOOM.L,  Audioboom Group plc',
    name: 'Audioboom Group plc',
    symbol: 'BOOM.L',
  },
  {
    value: 'BOOT.L',
    label: 'BOOT.L,  Henry Boot PLC',
    name: 'Henry Boot PLC',
    symbol: 'BOOT.L',
  },
  {
    value: 'BOR.L',
    label: 'BOR.L,  Borders & Southern Petroleum plc',
    name: 'Borders & Southern Petroleum plc',
    symbol: 'BOR.L',
  },
  {
    value: 'BOTB.L',
    label: 'BOTB.L,  Best of the Best PLC',
    name: 'Best of the Best PLC',
    symbol: 'BOTB.L',
  },
  {
    value: 'BOWL.L',
    label: 'BOWL.L,  Hollywood Bowl Group plc',
    name: 'Hollywood Bowl Group plc',
    symbol: 'BOWL.L',
  },
  {
    value: 'BOXE.L',
    label: 'BOXE.L,  Tritax EuroBox plc',
    name: 'Tritax EuroBox plc',
    symbol: 'BOXE.L',
  },
  {
    value: 'BOY.L',
    label: 'BOY.L,  Bodycote plc',
    name: 'Bodycote plc',
    symbol: 'BOY.L',
  },
  {
    value: 'BPCR.L',
    label: 'BPCR.L,  BioPharma Credit PLC',
    name: 'BioPharma Credit PLC',
    symbol: 'BPCR.L',
  },
  {
    value: 'BPM.L',
    label: 'BPM.L,  B.P. Marsh & Partners PLC',
    name: 'B.P. Marsh & Partners PLC',
    symbol: 'BPM.L',
  },
  {
    value: 'BRBY.L',
    label: 'BRBY.L,  Burberry Group plc',
    name: 'Burberry Group plc',
    symbol: 'BRBY.L',
  },
  {
    value: 'BRCK.L',
    label: 'BRCK.L,  Brickability Group Plc',
    name: 'Brickability Group Plc',
    symbol: 'BRCK.L',
  },
  {
    value: 'BRD.L',
    label: 'BRD.L,  BlueRock Diamonds PLC',
    name: 'BlueRock Diamonds PLC',
    symbol: 'BRD.L',
  },
  {
    value: 'BREE.L',
    label: 'BREE.L,  Breedon Group plc',
    name: 'Breedon Group plc',
    symbol: 'BREE.L',
  },
  {
    value: 'BRES.L',
    label: 'BRES.L,  Blencowe Resources Plc',
    name: 'Blencowe Resources Plc',
    symbol: 'BRES.L',
  },
  {
    value: 'BRFI.L',
    label: 'BRFI.L,  BlackRock Frontiers Investment Trust plc',
    name: 'BlackRock Frontiers Investment Trust plc',
    symbol: 'BRFI.L',
  },
  {
    value: 'BRGE.L',
    label: 'BRGE.L,  BlackRock Greater Europe Investment Trust plc',
    name: 'BlackRock Greater Europe Investment Trust plc',
    symbol: 'BRGE.L',
  },
  {
    value: 'BRH.L',
    label: 'BRH.L,  Braveheart Investment Group plc',
    name: 'Braveheart Investment Group plc',
    symbol: 'BRH.L',
  },
  {
    value: 'BRIG.L',
    label: 'BRIG.L,  BlackRock Income and Growth Investment Trust plc',
    name: 'BlackRock Income and Growth Investment Trust plc',
    symbol: 'BRIG.L',
  },
  {
    value: 'BRK.L',
    label: 'BRK.L,  Brooks Macdonald Group plc',
    name: 'Brooks Macdonald Group plc',
    symbol: 'BRK.L',
  },
  {
    value: 'BRLA.L',
    label: 'BRLA.L,  BlackRock Latin American Investment Trust plc',
    name: 'BlackRock Latin American Investment Trust plc',
    symbol: 'BRLA.L',
  },
  {
    value: 'BRSC.L',
    label: 'BRSC.L,  BlackRock Smaller Companies Trust plc',
    name: 'BlackRock Smaller Companies Trust plc',
    symbol: 'BRSC.L',
  },
  {
    value: 'BRWM.L',
    label: 'BRWM.L,  BlackRock World Mining Trust plc',
    name: 'BlackRock World Mining Trust plc',
    symbol: 'BRWM.L',
  },
  {
    value: 'BSC.L',
    label: 'BSC.L,  British Smaller Companies VCT 2 plc',
    name: 'British Smaller Companies VCT 2 plc',
    symbol: 'BSC.L',
  },
  {
    value: 'BSFA.L',
    label: 'BSFA.L,  BSF Enterprise Plc',
    name: 'BSF Enterprise Plc',
    symbol: 'BSFA.L',
  },
  {
    value: 'BSIF.L',
    label: 'BSIF.L,  Bluefield Solar Income Fund Limited',
    name: 'Bluefield Solar Income Fund Limited',
    symbol: 'BSIF.L',
  },
  {
    value: 'BSRT.L',
    label: 'BSRT.L,  Baker Steel Resources Trust Ltd.',
    name: 'Baker Steel Resources Trust Ltd.',
    symbol: 'BSRT.L',
  },
  {
    value: 'BSV.L',
    label: 'BSV.L,  British Smaller Companies VCT plc',
    name: 'British Smaller Companies VCT plc',
    symbol: 'BSV.L',
  },
  {
    value: 'BUR.L',
    label: 'BUR.L,  Burford Capital Limited',
    name: 'Burford Capital Limited',
    symbol: 'BUR.L',
  },
  {
    value: 'BUT.L',
    label: 'BUT.L,  The Brunner Investment Trust PLC',
    name: 'The Brunner Investment Trust PLC',
    symbol: 'BUT.L',
  },
  {
    value: 'BVA.L',
    label: 'BVA.L,  Banco Bilbao Vizcaya Argentaria, S.A.',
    name: 'Banco Bilbao Vizcaya Argentaria, S.A.',
    symbol: 'BVA.L',
  },
  {
    value: 'BVC.L',
    label: 'BVC.L,  BATM Advanced Communications Ltd.',
    name: 'BATM Advanced Communications Ltd.',
    symbol: 'BVC.L',
  },
  {
    value: 'BVIC.L',
    label: 'BVIC.L,  Britvic plc',
    name: 'Britvic plc',
    symbol: 'BVIC.L',
  },
  {
    value: 'BVT.L',
    label: 'BVT.L,  Baronsmead Venture Trust Plc',
    name: 'Baronsmead Venture Trust Plc',
    symbol: 'BVT.L',
  },
  {
    value: 'BVXP.L',
    label: 'BVXP.L,  Bioventix PLC',
    name: 'Bioventix PLC',
    symbol: 'BVXP.L',
  },
  {
    value: 'BWNG.L',
    label: 'BWNG.L,  N Brown Group plc',
    name: 'N Brown Group plc',
    symbol: 'BWNG.L',
  },
  {
    value: 'BWY.L',
    label: 'BWY.L,  Bellway p.l.c.',
    name: 'Bellway p.l.c.',
    symbol: 'BWY.L',
  },
  {
    value: 'BYG.L',
    label: 'BYG.L,  Big Yellow Group Plc',
    name: 'Big Yellow Group Plc',
    symbol: 'BYG.L',
  },
  {
    value: 'BYOT.L',
    label: 'BYOT.L,  Byotrol plc',
    name: 'Byotrol plc',
    symbol: 'BYOT.L',
  },
  {
    value: 'BZT.L',
    label: 'BZT.L,  Bezant Resources Plc',
    name: 'Bezant Resources Plc',
    symbol: 'BZT.L',
  },
  {
    value: 'C4XD.L',
    label: 'C4XD.L,  C4X Discovery Holdings plc',
    name: 'C4X Discovery Holdings plc',
    symbol: 'C4XD.L',
  },
  {
    value: 'CAD.L',
    label: 'CAD.L,  Cadogan Petroleum plc',
    name: 'Cadogan Petroleum plc',
    symbol: 'CAD.L',
  },
  {
    value: 'CAL.L',
    label: 'CAL.L,  Capital & Regional Plc',
    name: 'Capital & Regional Plc',
    symbol: 'CAL.L',
  },
  {
    value: 'CAM.L',
    label: 'CAM.L,  Camellia Plc',
    name: 'Camellia Plc',
    symbol: 'CAM.L',
  },
  {
    value: 'CAML.L',
    label: 'CAML.L,  Central Asia Metals plc',
    name: 'Central Asia Metals plc',
    symbol: 'CAML.L',
  },
  {
    value: 'CAPC.L',
    label: 'CAPC.L,  Capital & Counties Properties PLC',
    name: 'Capital & Counties Properties PLC',
    symbol: 'CAPC.L',
  },
  {
    value: 'CAPD.L',
    label: 'CAPD.L,  Capital Limited',
    name: 'Capital Limited',
    symbol: 'CAPD.L',
  },
  {
    value: 'CAR.L',
    label: 'CAR.L,  Carclo plc',
    name: 'Carclo plc',
    symbol: 'CAR.L',
  },
  {
    value: 'CARD.L',
    label: 'CARD.L,  Card Factory plc',
    name: 'Card Factory plc',
    symbol: 'CARD.L',
  },
  {
    value: 'CARR.L',
    label: "CARR.L,  Carr's Group plc",
    name: "Carr's Group plc",
    symbol: 'CARR.L',
  },
  {
    value: 'CASP.L',
    label: 'CASP.L,  Caspian Sunrise plc',
    name: 'Caspian Sunrise plc',
    symbol: 'CASP.L',
  },
  {
    value: 'CAT.L',
    label: 'CAT.L,  CATCo Reinsurance Opportunities Fund Ltd.',
    name: 'CATCo Reinsurance Opportunities Fund Ltd.',
    symbol: 'CAT.L',
  },
  {
    value: 'CAU.L',
    label: 'CAU.L,  Centaur Media Plc',
    name: 'Centaur Media Plc',
    symbol: 'CAU.L',
  },
  {
    value: 'CBG.L',
    label: 'CBG.L,  Close Brothers Group plc',
    name: 'Close Brothers Group plc',
    symbol: 'CBG.L',
  },
  {
    value: 'CBOX.L',
    label: 'CBOX.L,  Cake Box Holdings Plc',
    name: 'Cake Box Holdings Plc',
    symbol: 'CBOX.L',
  },
  {
    value: 'CBP.L',
    label: 'CBP.L,  Curtis Banks Group PLC',
    name: 'Curtis Banks Group PLC',
    symbol: 'CBP.L',
  },
  {
    value: 'CCC.L',
    label: 'CCC.L,  Computacenter plc',
    name: 'Computacenter plc',
    symbol: 'CCC.L',
  },
  {
    value: 'CCEP.L',
    label: 'CCEP.L,  Coca-Cola Europacific Partners PLC',
    name: 'Coca-Cola Europacific Partners PLC',
    symbol: 'CCEP.L',
  },
  {
    value: 'CCH.L',
    label: 'CCH.L,  Coca-Cola HBC AG',
    name: 'Coca-Cola HBC AG',
    symbol: 'CCH.L',
  },
  {
    value: 'CCJI.L',
    label: 'CCJI.L,  CC Japan Income & Growth Trust plc',
    name: 'CC Japan Income & Growth Trust plc',
    symbol: 'CCJI.L',
  },
  {
    value: 'CCL.L',
    label: 'CCL.L,  Carnival Corporation & plc',
    name: 'Carnival Corporation & plc',
    symbol: 'CCL.L',
  },
  {
    value: 'CCP.L',
    label: 'CCP.L,  Celtic plc',
    name: 'Celtic plc',
    symbol: 'CCP.L',
  },
  {
    value: 'CCR.L',
    label: 'CCR.L,  C&C Group plc',
    name: 'C&C Group plc',
    symbol: 'CCR.L',
  },
  {
    value: 'CCT.L',
    label: 'CCT.L,  The Character Group plc',
    name: 'The Character Group plc',
    symbol: 'CCT.L',
  },
  {
    value: 'CDFF.L',
    label: 'CDFF.L,  Cardiff Property Plc',
    name: 'Cardiff Property Plc',
    symbol: 'CDFF.L',
  },
  {
    value: 'CER.L',
    label: 'CER.L,  Cerillion Plc',
    name: 'Cerillion Plc',
    symbol: 'CER.L',
  },
  {
    value: 'CEY.L',
    label: 'CEY.L,  Centamin plc',
    name: 'Centamin plc',
    symbol: 'CEY.L',
  },
  {
    value: 'CFX.L',
    label: 'CFX.L,  Colefax Group PLC',
    name: 'Colefax Group PLC',
    symbol: 'CFX.L',
  },
  {
    value: 'CFYN.L',
    label: 'CFYN.L,  Caffyns plc',
    name: 'Caffyns plc',
    symbol: 'CFYN.L',
  },
  {
    value: 'CGEO.L',
    label: 'CGEO.L,  Georgia Capital PLC',
    name: 'Georgia Capital PLC',
    symbol: 'CGEO.L',
  },
  {
    value: 'CGH.L',
    label: 'CGH.L,  Chaarat Gold Holdings Limited',
    name: 'Chaarat Gold Holdings Limited',
    symbol: 'CGH.L',
  },
  {
    value: 'CGNR.L',
    label: 'CGNR.L,  Conroy Gold and Natural Resources plc',
    name: 'Conroy Gold and Natural Resources plc',
    symbol: 'CGNR.L',
  },
  {
    value: 'CGO.L',
    label: 'CGO.L,  Contango Holdings plc',
    name: 'Contango Holdings plc',
    symbol: 'CGO.L',
  },
  {
    value: 'CGS.L',
    label: 'CGS.L,  Castings P.L.C.',
    name: 'Castings P.L.C.',
    symbol: 'CGS.L',
  },
  {
    value: 'CGT.L',
    label: 'CGT.L,  Capital Gearing Trust p.l.c',
    name: 'Capital Gearing Trust p.l.c',
    symbol: 'CGT.L',
  },
  {
    value: 'CGW.L',
    label: 'CGW.L,  Chelverton Growth Trust PLC',
    name: 'Chelverton Growth Trust PLC',
    symbol: 'CGW.L',
  },
  {
    value: 'CHAR.L',
    label: 'CHAR.L,  Chariot Limited',
    name: 'Chariot Limited',
    symbol: 'CHAR.L',
  },
  {
    value: 'CHF.L',
    label: 'CHF.L,  Chesterfield Resources plc',
    name: 'Chesterfield Resources plc',
    symbol: 'CHF.L',
  },
  {
    value: 'CHG.L',
    label: 'CHG.L,  Chemring Group PLC',
    name: 'Chemring Group PLC',
    symbol: 'CHG.L',
  },
  {
    value: 'CHH.L',
    label: 'CHH.L,  Churchill China plc',
    name: 'Churchill China plc',
    symbol: 'CHH.L',
  },
  {
    value: 'CHRT.L',
    label: 'CHRT.L,  Cohort plc',
    name: 'Cohort plc',
    symbol: 'CHRT.L',
  },
  {
    value: 'CIC.L',
    label: 'CIC.L,  The Conygar Investment Company PLC',
    name: 'The Conygar Investment Company PLC',
    symbol: 'CIC.L',
  },
  {
    value: 'CIN.L',
    label: 'CIN.L,  City of London Group plc',
    name: 'City of London Group plc',
    symbol: 'CIN.L',
  },
  {
    value: 'CINE.L',
    label: 'CINE.L,  Cineworld Group plc',
    name: 'Cineworld Group plc',
    symbol: 'CINE.L',
  },
  {
    value: 'CKN.L',
    label: 'CKN.L,  Clarkson PLC',
    name: 'Clarkson PLC',
    symbol: 'CKN.L',
  },
  {
    value: 'CKT.L',
    label: 'CKT.L,  Checkit plc',
    name: 'Checkit plc',
    symbol: 'CKT.L',
  },
  {
    value: 'CLCO.L',
    label: 'CLCO.L,  CloudCoCo Group plc',
    name: 'CloudCoCo Group plc',
    symbol: 'CLCO.L',
  },
  {
    value: 'CLDN.L',
    label: 'CLDN.L,  Caledonia Investments plc',
    name: 'Caledonia Investments plc',
    symbol: 'CLDN.L',
  },
  {
    value: 'CLI.L',
    label: 'CLI.L,  CLS Holdings plc',
    name: 'CLS Holdings plc',
    symbol: 'CLI.L',
  },
  {
    value: 'CLIG.L',
    label: 'CLIG.L,  City of London Investment Group PLC',
    name: 'City of London Investment Group PLC',
    symbol: 'CLIG.L',
  },
  {
    value: 'CLON.L',
    label: 'CLON.L,  Clontarf Energy plc',
    name: 'Clontarf Energy plc',
    symbol: 'CLON.L',
  },
  {
    value: 'CMB.L',
    label: 'CMB.L,  Cambria Africa plc',
    name: 'Cambria Africa plc',
    symbol: 'CMB.L',
  },
  {
    value: 'CMCX.L',
    label: 'CMCX.L,  CMC Markets plc',
    name: 'CMC Markets plc',
    symbol: 'CMCX.L',
  },
  {
    value: 'CML.L',
    label: 'CML.L,  CML Microsystems plc',
    name: 'CML Microsystems plc',
    symbol: 'CML.L',
  },
  {
    value: 'CMX.L',
    label: 'CMX.L,  Catalyst Media Group plc',
    name: 'Catalyst Media Group plc',
    symbol: 'CMX.L',
  },
  {
    value: 'CNA.L',
    label: 'CNA.L,  Centrica plc',
    name: 'Centrica plc',
    symbol: 'CNA.L',
  },
  {
    value: 'CNC.L',
    label: 'CNC.L,  Concurrent Technologies Plc',
    name: 'Concurrent Technologies Plc',
    symbol: 'CNC.L',
  },
  {
    value: 'CNE.L',
    label: 'CNE.L,  Capricorn Energy PLC',
    name: 'Capricorn Energy PLC',
    symbol: 'CNE.L',
  },
  {
    value: 'CNG.L',
    label: 'CNG.L,  China Nonferrous Gold Limited',
    name: 'China Nonferrous Gold Limited',
    symbol: 'CNG.L',
  },
  {
    value: 'CNIC.L',
    label: 'CNIC.L,  CentralNic Group Plc',
    name: 'CentralNic Group Plc',
    symbol: 'CNIC.L',
  },
  {
    value: 'CNKS.L',
    label: 'CNKS.L,  Cenkos Securities plc',
    name: 'Cenkos Securities plc',
    symbol: 'CNKS.L',
  },
  {
    value: 'CNN.L',
    label: 'CNN.L,  Caledonian Trust PLC',
    name: 'Caledonian Trust PLC',
    symbol: 'CNN.L',
  },
  {
    value: 'CNR.L',
    label: 'CNR.L,  Condor Gold Plc',
    name: 'Condor Gold Plc',
    symbol: 'CNR.L',
  },
  {
    value: 'CNS.L',
    label: 'CNS.L,  Corero Network Security plc',
    name: 'Corero Network Security plc',
    symbol: 'CNS.L',
  },
  {
    value: 'COA.L',
    label: 'COA.L,  Coats Group plc',
    name: 'Coats Group plc',
    symbol: 'COA.L',
  },
  {
    value: 'COBR.L',
    label: 'COBR.L,  Cobra Resources plc',
    name: 'Cobra Resources plc',
    symbol: 'COBR.L',
  },
  {
    value: 'COD.L',
    label: 'COD.L,  Compagnie de Saint-Gobain S.A.',
    name: 'Compagnie de Saint-Gobain S.A.',
    symbol: 'COD.L',
  },
  {
    value: 'COG.L',
    label: 'COG.L,  Cambridge Cognition Holdings Plc',
    name: 'Cambridge Cognition Holdings Plc',
    symbol: 'COG.L',
  },
  {
    value: 'COM.L',
    label: 'COM.L,  Comptoir Group PLC',
    name: 'Comptoir Group PLC',
    symbol: 'COM.L',
  },
  {
    value: 'COPL.L',
    label: 'COPL.L,  Canadian Overseas Petroleum Limited',
    name: 'Canadian Overseas Petroleum Limited',
    symbol: 'COPL.L',
  },
  {
    value: 'CORA.L',
    label: 'CORA.L,  Cora Gold Limited',
    name: 'Cora Gold Limited',
    symbol: 'CORA.L',
  },
  {
    value: 'CORO.L',
    label: 'CORO.L,  Coro Energy plc',
    name: 'Coro Energy plc',
    symbol: 'CORO.L',
  },
  {
    value: 'COST.L',
    label: 'COST.L,  Costain Group PLC',
    name: 'Costain Group PLC',
    symbol: 'COST.L',
  },
  {
    value: 'CPC.L',
    label: 'CPC.L,  The City Pub Group plc',
    name: 'The City Pub Group plc',
    symbol: 'CPC.L',
  },
  {
    value: 'CPG.L',
    label: 'CPG.L,  Compass Group PLC',
    name: 'Compass Group PLC',
    symbol: 'CPG.L',
  },
  {
    value: 'CPI.L',
    label: 'CPI.L,  Capita plc',
    name: 'Capita plc',
    symbol: 'CPI.L',
  },
  {
    value: 'CPP.L',
    label: 'CPP.L,  CPPGroup Plc',
    name: 'CPPGroup Plc',
    symbol: 'CPP.L',
  },
  {
    value: 'CPX.L',
    label: 'CPX.L,  CAP-XX Limited',
    name: 'CAP-XX Limited',
    symbol: 'CPX.L',
  },
  {
    value: 'CRC.L',
    label: 'CRC.L,  Circle Property Plc',
    name: 'Circle Property Plc',
    symbol: 'CRC.L',
  },
  {
    value: 'CRDA.L',
    label: 'CRDA.L,  Croda International Plc',
    name: 'Croda International Plc',
    symbol: 'CRDA.L',
  },
  {
    value: 'CREI.L',
    label: 'CREI.L,  Custodian REIT Plc',
    name: 'Custodian REIT Plc',
    symbol: 'CREI.L',
  },
  {
    value: 'CREO.L',
    label: 'CREO.L,  Creo Medical Group PLC',
    name: 'Creo Medical Group PLC',
    symbol: 'CREO.L',
  },
  {
    value: 'CRH.L',
    label: 'CRH.L,  CRH plc',
    name: 'CRH plc',
    symbol: 'CRH.L',
  },
  {
    value: 'CRL.L',
    label: 'CRL.L,  Creightons Plc',
    name: 'Creightons Plc',
    symbol: 'CRL.L',
  },
  {
    value: 'CRN.L',
    label: 'CRN.L,  Cairn Homes plc',
    name: 'Cairn Homes plc',
    symbol: 'CRN.L',
  },
  {
    value: 'CRPR.L',
    label: 'CRPR.L,  James Cropper PLC',
    name: 'James Cropper PLC',
    symbol: 'CRPR.L',
  },
  {
    value: 'CRS.L',
    label: 'CRS.L,  Crystal Amber Fund Limited',
    name: 'Crystal Amber Fund Limited',
    symbol: 'CRS.L',
  },
  {
    value: 'CRST.L',
    label: 'CRST.L,  Crest Nicholson Holdings plc',
    name: 'Crest Nicholson Holdings plc',
    symbol: 'CRST.L',
  },
  {
    value: 'CRU.L',
    label: 'CRU.L,  Coral Products plc',
    name: 'Coral Products plc',
    symbol: 'CRU.L',
  },
  {
    value: 'CRV.L',
    label: 'CRV.L,  Craven House Capital Plc',
    name: 'Craven House Capital Plc',
    symbol: 'CRV.L',
  },
  {
    value: 'CRW.L',
    label: 'CRW.L,  Craneware plc',
    name: 'Craneware plc',
    symbol: 'CRW.L',
  },
  {
    value: 'CRWN.L',
    label: 'CRWN.L,  Crown Place VCT PLC',
    name: 'Crown Place VCT PLC',
    symbol: 'CRWN.L',
  },
  {
    value: 'CSH.L',
    label: 'CSH.L,  Civitas Social Housing PLC',
    name: 'Civitas Social Housing PLC',
    symbol: 'CSH.L',
  },
  {
    value: 'CSN.L',
    label: 'CSN.L,  Chesnara plc',
    name: 'Chesnara plc',
    symbol: 'CSN.L',
  },
  {
    value: 'CSP.L',
    label: 'CSP.L,  Countryside Partnerships PLC',
    name: 'Countryside Partnerships PLC',
    symbol: 'CSP.L',
  },
  {
    value: 'CSSG.L',
    label: 'CSSG.L,  Croma Security Solutions Group plc',
    name: 'Croma Security Solutions Group plc',
    symbol: 'CSSG.L',
  },
  {
    value: 'CTEA.L',
    label: 'CTEA.L,  Catenae Innovation Plc',
    name: 'Catenae Innovation Plc',
    symbol: 'CTEA.L',
  },
  {
    value: 'CTEC.L',
    label: 'CTEC.L,  ConvaTec Group Plc',
    name: 'ConvaTec Group Plc',
    symbol: 'CTEC.L',
  },
  {
    value: 'CTG.L',
    label: 'CTG.L,  Christie Group plc',
    name: 'Christie Group plc',
    symbol: 'CTG.L',
  },
  {
    value: 'CTO.L',
    label: 'CTO.L,  TClarke plc',
    name: 'TClarke plc',
    symbol: 'CTO.L',
  },
  {
    value: 'CTY.L',
    label: 'CTY.L,  The City of London Investment Trust plc',
    name: 'The City of London Investment Trust plc',
    symbol: 'CTY.L',
  },
  {
    value: 'CVSG.L',
    label: 'CVSG.L,  CVS Group plc',
    name: 'CVS Group plc',
    symbol: 'CVSG.L',
  },
  {
    value: 'CWK.L',
    label: 'CWK.L,  Cranswick plc',
    name: 'Cranswick plc',
    symbol: 'CWK.L',
  },
  {
    value: 'CWR.L',
    label: 'CWR.L,  Ceres Power Holdings plc',
    name: 'Ceres Power Holdings plc',
    symbol: 'CWR.L',
  },
  {
    value: 'CYAN.L',
    label: 'CYAN.L,  CyanConnode Holdings plc',
    name: 'CyanConnode Holdings plc',
    symbol: 'CYAN.L',
  },
  {
    value: 'CYN.L',
    label: 'CYN.L,  CQS Natural Resources Growth and Income plc',
    name: 'CQS Natural Resources Growth and Income plc',
    symbol: 'CYN.L',
  },
  {
    value: 'D4T4.L',
    label: 'D4T4.L,  D4t4 Solutions Plc',
    name: 'D4t4 Solutions Plc',
    symbol: 'D4T4.L',
  },
  {
    value: 'DAL.L',
    label: 'DAL.L,  Dalata Hotel Group plc',
    name: 'Dalata Hotel Group plc',
    symbol: 'DAL.L',
  },
  {
    value: 'DATA.L',
    label: 'DATA.L,  GlobalData Plc',
    name: 'GlobalData Plc',
    symbol: 'DATA.L',
  },
  {
    value: 'DBOX.L',
    label: 'DBOX.L,  Digitalbox plc',
    name: 'Digitalbox plc',
    symbol: 'DBOX.L',
  },
  {
    value: 'DCC.L',
    label: 'DCC.L,  DCC plc',
    name: 'DCC plc',
    symbol: 'DCC.L',
  },
  {
    value: 'DCI.L',
    label: 'DCI.L,  Dolphin Capital Investors Limited',
    name: 'Dolphin Capital Investors Limited',
    symbol: 'DCI.L',
  },
  {
    value: 'DCTA.L',
    label: 'DCTA.L,  Directa Plus Plc',
    name: 'Directa Plus Plc',
    symbol: 'DCTA.L',
  },
  {
    value: 'DDDD.L',
    label: 'DDDD.L,  4D pharma plc',
    name: '4D pharma plc',
    symbol: 'DDDD.L',
  },
  {
    value: 'DELT.L',
    label: 'DELT.L,  Deltic Energy Plc',
    name: 'Deltic Energy Plc',
    symbol: 'DELT.L',
  },
  {
    value: 'DEMG.L',
    label: 'DEMG.L,  Deltex Medical Group plc',
    name: 'Deltex Medical Group plc',
    symbol: 'DEMG.L',
  },
  {
    value: 'DEST.L',
    label: 'DEST.L,  Destiny Pharma plc',
    name: 'Destiny Pharma plc',
    symbol: 'DEST.L',
  },
  {
    value: 'DEV.L',
    label: 'DEV.L,  Dev Clever Holdings Plc',
    name: 'Dev Clever Holdings Plc',
    symbol: 'DEV.L',
  },
  {
    value: 'DFCH.L',
    label: 'DFCH.L,  Distribution Finance Capital Holdings plc',
    name: 'Distribution Finance Capital Holdings plc',
    symbol: 'DFCH.L',
  },
  {
    value: 'DFS.L',
    label: 'DFS.L,  DFS Furniture plc',
    name: 'DFS Furniture plc',
    symbol: 'DFS.L',
  },
  {
    value: 'DGE.L',
    label: 'DGE.L,  Diageo plc',
    name: 'Diageo plc',
    symbol: 'DGE.L',
  },
  {
    value: 'DGN.L',
    label: 'DGN.L,  Asia Dragon Trust plc',
    name: 'Asia Dragon Trust plc',
    symbol: 'DGN.L',
  },
  {
    value: 'DIA.L',
    label: 'DIA.L,  Dialight plc',
    name: 'Dialight plc',
    symbol: 'DIA.L',
  },
  {
    value: 'DIG.L',
    label: 'DIG.L,  Dunedin Income Growth Investment Trust PLC',
    name: 'Dunedin Income Growth Investment Trust PLC',
    symbol: 'DIG.L',
  },
  {
    value: 'DIS.L',
    label: 'DIS.L,  Distil Plc',
    name: 'Distil Plc',
    symbol: 'DIS.L',
  },
  {
    value: 'DIVI.L',
    label: 'DIVI.L,  The Diverse Income Trust plc',
    name: 'The Diverse Income Trust plc',
    symbol: 'DIVI.L',
  },
  {
    value: 'DKE.L',
    label: 'DKE.L,  Dukemount Capital Plc',
    name: 'Dukemount Capital Plc',
    symbol: 'DKE.L',
  },
  {
    value: 'DKL.L',
    label: 'DKL.L,  Dekel Agri-Vision plc',
    name: 'Dekel Agri-Vision plc',
    symbol: 'DKL.L',
  },
  {
    value: 'DLAR.L',
    label: 'DLAR.L,  De La Rue plc',
    name: 'De La Rue plc',
    symbol: 'DLAR.L',
  },
  {
    value: 'DLG.L',
    label: 'DLG.L,  Direct Line Insurance Group plc',
    name: 'Direct Line Insurance Group plc',
    symbol: 'DLG.L',
  },
  {
    value: 'DLN.L',
    label: 'DLN.L,  Derwent London Plc',
    name: 'Derwent London Plc',
    symbol: 'DLN.L',
  },
  {
    value: 'DMTR.L',
    label: 'DMTR.L,  DeepMatter Group Plc',
    name: 'DeepMatter Group Plc',
    symbol: 'DMTR.L',
  },
  {
    value: 'DNA2.L',
    label: 'DNA2.L,  Doric Nimrod Air Two Limited',
    name: 'Doric Nimrod Air Two Limited',
    symbol: 'DNA2.L',
  },
  {
    value: 'DNA3.L',
    label: 'DNA3.L,  Doric Nimrod Air Three Limited',
    name: 'Doric Nimrod Air Three Limited',
    symbol: 'DNA3.L',
  },
  {
    value: 'DNE.L',
    label: 'DNE.L,  Dunedin Enterprise Investment Trust PLC',
    name: 'Dunedin Enterprise Investment Trust PLC',
    symbol: 'DNE.L',
  },
  {
    value: 'DNLM.L',
    label: 'DNLM.L,  Dunelm Group plc',
    name: 'Dunelm Group plc',
    symbol: 'DNLM.L',
  },
  {
    value: 'DOM.L',
    label: "DOM.L,  Domino's Pizza Group plc",
    name: "Domino's Pizza Group plc",
    symbol: 'DOM.L',
  },
  {
    value: 'DOTD.L',
    label: 'DOTD.L,  dotdigital Group Plc',
    name: 'dotdigital Group Plc',
    symbol: 'DOTD.L',
  },
  {
    value: 'DPA.L',
    label: 'DPA.L,  DP Aircraft I Limited',
    name: 'DP Aircraft I Limited',
    symbol: 'DPA.L',
  },
  {
    value: 'DPEU.L',
    label: 'DPEU.L,  DP Eurasia N.V.',
    name: 'DP Eurasia N.V.',
    symbol: 'DPEU.L',
  },
  {
    value: 'DPH.L',
    label: 'DPH.L,  Dechra Pharmaceuticals PLC',
    name: 'Dechra Pharmaceuticals PLC',
    symbol: 'DPH.L',
  },
  {
    value: 'DPLM.L',
    label: 'DPLM.L,  Diploma PLC',
    name: 'Diploma PLC',
    symbol: 'DPLM.L',
  },
  {
    value: 'DPP.L',
    label: 'DPP.L,  DP Poland Plc',
    name: 'DP Poland Plc',
    symbol: 'DPP.L',
  },
  {
    value: 'DRV.L',
    label: 'DRV.L,  Driver Group plc',
    name: 'Driver Group plc',
    symbol: 'DRV.L',
  },
  {
    value: 'DRX.L',
    label: 'DRX.L,  Drax Group plc',
    name: 'Drax Group plc',
    symbol: 'DRX.L',
  },
  {
    value: 'DSCV.L',
    label: 'DSCV.L,  discoverIE Group plc',
    name: 'discoverIE Group plc',
    symbol: 'DSCV.L',
  },
  {
    value: 'DSG.L',
    label: 'DSG.L,  Dillistone Group Plc',
    name: 'Dillistone Group Plc',
    symbol: 'DSG.L',
  },
  {
    value: 'DSM.L',
    label: 'DSM.L,  Downing Strategic Micro-Cap Investment Trust PLC',
    name: 'Downing Strategic Micro-Cap Investment Trust PLC',
    symbol: 'DSM.L',
  },
  {
    value: 'DTY.L',
    label: 'DTY.L,  Dignity plc',
    name: 'Dignity plc',
    symbol: 'DTY.L',
  },
  {
    value: 'DUKE.L',
    label: 'DUKE.L,  Duke Royalty Limited',
    name: 'Duke Royalty Limited',
    symbol: 'DUKE.L',
  },
  {
    value: 'DVO.L',
    label: 'DVO.L,  Devro plc',
    name: 'Devro plc',
    symbol: 'DVO.L',
  },
  {
    value: 'DWF.L',
    label: 'DWF.L,  DWF Group plc',
    name: 'DWF Group plc',
    symbol: 'DWF.L',
  },
  {
    value: 'DWHT.L',
    label: 'DWHT.L,  Dewhurst Group Plc',
    name: 'Dewhurst Group Plc',
    symbol: 'DWHT.L',
  },
  {
    value: 'DXRX.L',
    label: 'DXRX.L,  Diaceutics PLC',
    name: 'Diaceutics PLC',
    symbol: 'DXRX.L',
  },
  {
    value: 'EAAS.L',
    label: 'EAAS.L,  eEnergy Group Plc',
    name: 'eEnergy Group Plc',
    symbol: 'EAAS.L',
  },
  {
    value: 'EAH.L',
    label: 'EAH.L,  ECO Animal Health Group plc',
    name: 'ECO Animal Health Group plc',
    symbol: 'EAH.L',
  },
  {
    value: 'EAT.L',
    label: 'EAT.L,  European Assets Trust PLC',
    name: 'European Assets Trust PLC',
    symbol: 'EAT.L',
  },
  {
    value: 'EBOX.L',
    label: 'EBOX.L,  Tritax EuroBox plc',
    name: 'Tritax EuroBox plc',
    symbol: 'EBOX.L',
  },
  {
    value: 'EBQ.L',
    label: 'EBQ.L,  Ebiquity plc',
    name: 'Ebiquity plc',
    symbol: 'EBQ.L',
  },
  {
    value: 'ECEL.L',
    label: 'ECEL.L,  Eurocell plc',
    name: 'Eurocell plc',
    symbol: 'ECEL.L',
  },
  {
    value: 'ECHO.L',
    label: 'ECHO.L,  Echo Energy plc',
    name: 'Echo Energy plc',
    symbol: 'ECHO.L',
  },
  {
    value: 'ECK.L',
    label: 'ECK.L,  Eckoh plc',
    name: 'Eckoh plc',
    symbol: 'ECK.L',
  },
  {
    value: 'ECO.L',
    label: 'ECO.L,  Eco (Atlantic) Oil & Gas Ltd.',
    name: 'Eco (Atlantic) Oil & Gas Ltd.',
    symbol: 'ECO.L',
  },
  {
    value: 'ECR.L',
    label: 'ECR.L,  ECR Minerals plc',
    name: 'ECR Minerals plc',
    symbol: 'ECR.L',
  },
  {
    value: 'ECSC.L',
    label: 'ECSC.L,  ECSC Group plc',
    name: 'ECSC Group plc',
    symbol: 'ECSC.L',
  },
  {
    value: 'EDEN.L',
    label: 'EDEN.L,  Eden Research plc',
    name: 'Eden Research plc',
    symbol: 'EDEN.L',
  },
  {
    value: 'EDIN.L',
    label: 'EDIN.L,  The Edinburgh Investment Trust plc',
    name: 'The Edinburgh Investment Trust plc',
    symbol: 'EDIN.L',
  },
  {
    value: 'EDL.L',
    label: 'EDL.L,  Edenville Energy Plc',
    name: 'Edenville Energy Plc',
    symbol: 'EDL.L',
  },
  {
    value: 'EDR.L',
    label: 'EDR.L,  Egdon Resources plc',
    name: 'Egdon Resources plc',
    symbol: 'EDR.L',
  },
  {
    value: 'EEE.L',
    label: 'EEE.L,  Empire Metals Limited',
    name: 'Empire Metals Limited',
    symbol: 'EEE.L',
  },
  {
    value: 'EGL.L',
    label: 'EGL.L,  Ecofin Global Utilities and Infrastructure Trust plc',
    name: 'Ecofin Global Utilities and Infrastructure Trust plc',
    symbol: 'EGL.L',
  },
  {
    value: 'EJFI.L',
    label: 'EJFI.L,  EJF Investments Limited',
    name: 'EJF Investments Limited',
    symbol: 'EJFI.L',
  },
  {
    value: 'EKF.L',
    label: 'EKF.L,  EKF Diagnostics Holdings plc',
    name: 'EKF Diagnostics Holdings plc',
    symbol: 'EKF.L',
  },
  {
    value: 'ELCO.L',
    label: 'ELCO.L,  Eleco Plc',
    name: 'Eleco Plc',
    symbol: 'ELCO.L',
  },
  {
    value: 'ELM.L',
    label: 'ELM.L,  Elementis plc',
    name: 'Elementis plc',
    symbol: 'ELM.L',
  },
  {
    value: 'EMAN.L',
    label: 'EMAN.L,  Everyman Media Group plc',
    name: 'Everyman Media Group plc',
    symbol: 'EMAN.L',
  },
  {
    value: 'EME.L',
    label: 'EME.L,  Empyrean Energy Plc',
    name: 'Empyrean Energy Plc',
    symbol: 'EME.L',
  },
  {
    value: 'EMG.L',
    label: 'EMG.L,  Man Group Limited',
    name: 'Man Group Limited',
    symbol: 'EMG.L',
  },
  {
    value: 'EMH.L',
    label: 'EMH.L,  European Metals Holdings Limited',
    name: 'European Metals Holdings Limited',
    symbol: 'EMH.L',
  },
  {
    value: 'EMIS.L',
    label: 'EMIS.L,  EMIS Group plc',
    name: 'EMIS Group plc',
    symbol: 'EMIS.L',
  },
  {
    value: 'EML.L',
    label: 'EML.L,  Emmerson PLC',
    name: 'Emmerson PLC',
    symbol: 'EML.L',
  },
  {
    value: 'EMR.L',
    label: 'EMR.L,  Empresaria Group plc',
    name: 'Empresaria Group plc',
    symbol: 'EMR.L',
  },
  {
    value: 'ENET.L',
    label: 'ENET.L,  Ethernity Networks Ltd.',
    name: 'Ethernity Networks Ltd.',
    symbol: 'ENET.L',
  },
  {
    value: 'ENOG.L',
    label: 'ENOG.L,  Energean plc',
    name: 'Energean plc',
    symbol: 'ENOG.L',
  },
  {
    value: 'ENQ.L',
    label: 'ENQ.L,  EnQuest PLC',
    name: 'EnQuest PLC',
    symbol: 'ENQ.L',
  },
  {
    value: 'ENW.L',
    label: 'ENW.L,  Enwell Energy plc',
    name: 'Enwell Energy plc',
    symbol: 'ENW.L',
  },
  {
    value: 'EOG.L',
    label: 'EOG.L,  Europa Oil & Gas (Holdings) plc',
    name: 'Europa Oil & Gas (Holdings) plc',
    symbol: 'EOG.L',
  },
  {
    value: 'EPIC.L',
    label: 'EPIC.L,  Ediston Property Investment Company plc',
    name: 'Ediston Property Investment Company plc',
    symbol: 'EPIC.L',
  },
  {
    value: 'EPWN.L',
    label: 'EPWN.L,  Epwin Group Plc',
    name: 'Epwin Group Plc',
    symbol: 'EPWN.L',
  },
  {
    value: 'EQLS.L',
    label: 'EQLS.L,  Equals Group plc',
    name: 'Equals Group plc',
    symbol: 'EQLS.L',
  },
  {
    value: 'EQT.L',
    label: 'EQT.L,  EQTEC plc',
    name: 'EQTEC plc',
    symbol: 'EQT.L',
  },
  {
    value: 'ERGO.L',
    label: 'ERGO.L,  Ergomed plc',
    name: 'Ergomed plc',
    symbol: 'ERGO.L',
  },
  {
    value: 'ERM.L',
    label: 'ERM.L,  Euromoney Institutional Investor PLC',
    name: 'Euromoney Institutional Investor PLC',
    symbol: 'ERM.L',
  },
  {
    value: 'ESNT.L',
    label: 'ESNT.L,  Essentra plc',
    name: 'Essentra plc',
    symbol: 'ESNT.L',
  },
  {
    value: 'ESO.L',
    label: 'ESO.L,  EPE Special Opportunities Limited',
    name: 'EPE Special Opportunities Limited',
    symbol: 'ESO.L',
  },
  {
    value: 'ESP.L',
    label: 'ESP.L,  Empiric Student Property plc',
    name: 'Empiric Student Property plc',
    symbol: 'ESP.L',
  },
  {
    value: 'ESYS.L',
    label: 'ESYS.L,  essensys plc',
    name: 'essensys plc',
    symbol: 'ESYS.L',
  },
  {
    value: 'ETX.L',
    label: 'ETX.L,  e-therapeutics plc',
    name: 'e-therapeutics plc',
    symbol: 'ETX.L',
  },
  {
    value: 'EUZ.L',
    label: 'EUZ.L,  Europa Metals Limited',
    name: 'Europa Metals Limited',
    symbol: 'EUZ.L',
  },
  {
    value: 'EVE.L',
    label: 'EVE.L,  eve Sleep plc',
    name: 'eve Sleep plc',
    symbol: 'EVE.L',
  },
  {
    value: 'EVG.L',
    label: 'EVG.L,  Evgen Pharma plc',
    name: 'Evgen Pharma plc',
    symbol: 'EVG.L',
  },
  {
    value: 'EWI.L',
    label: 'EWI.L,  Edinburgh Worldwide Investment Trust plc',
    name: 'Edinburgh Worldwide Investment Trust plc',
    symbol: 'EWI.L',
  },
  {
    value: 'EXPN.L',
    label: 'EXPN.L,  Experian plc',
    name: 'Experian plc',
    symbol: 'EXPN.L',
  },
  {
    value: 'EYE.L',
    label: 'EYE.L,  Eagle Eye Solutions Group plc',
    name: 'Eagle Eye Solutions Group plc',
    symbol: 'EYE.L',
  },
  {
    value: 'EZJ.L',
    label: 'EZJ.L,  easyJet plc',
    name: 'easyJet plc',
    symbol: 'EZJ.L',
  },
  {
    value: 'FAB.L',
    label: 'FAB.L,  Fusion Antibodies plc',
    name: 'Fusion Antibodies plc',
    symbol: 'FAB.L',
  },
  {
    value: 'FAIR.L',
    label: 'FAIR.L,  Fair Oaks Income Limited',
    name: 'Fair Oaks Income Limited',
    symbol: 'FAIR.L',
  },
  {
    value: 'FAN.L',
    label: 'FAN.L,  Volution Group plc',
    name: 'Volution Group plc',
    symbol: 'FAN.L',
  },
  {
    value: 'FAR.L',
    label: 'FAR.L,  Ferro-Alloy Resources Limited',
    name: 'Ferro-Alloy Resources Limited',
    symbol: 'FAR.L',
  },
  {
    value: 'FARN.L',
    label: 'FARN.L,  Faron Pharmaceuticals Oy',
    name: 'Faron Pharmaceuticals Oy',
    symbol: 'FARN.L',
  },
  {
    value: 'FAS.L',
    label: 'FAS.L,  Fidelity Asian Values PLC',
    name: 'Fidelity Asian Values PLC',
    symbol: 'FAS.L',
  },
  {
    value: 'FBH.L',
    label: 'FBH.L,  FBD Holdings plc',
    name: 'FBD Holdings plc',
    symbol: 'FBH.L',
  },
  {
    value: 'FCAP.L',
    label: 'FCAP.L,  finnCap Group plc',
    name: 'finnCap Group plc',
    symbol: 'FCAP.L',
  },
  {
    value: 'FCH.L',
    label: 'FCH.L,  Funding Circle Holdings plc',
    name: 'Funding Circle Holdings plc',
    symbol: 'FCH.L',
  },
  {
    value: 'FCIT.L',
    label: 'FCIT.L,  F&C Investment Trust PLC',
    name: 'F&C Investment Trust PLC',
    symbol: 'FCIT.L',
  },
  {
    value: 'FCRM.L',
    label: 'FCRM.L,  Fulcrum Utility Services Limited',
    name: 'Fulcrum Utility Services Limited',
    symbol: 'FCRM.L',
  },
  {
    value: 'FCSS.L',
    label: 'FCSS.L,  Fidelity China Special Situations PLC',
    name: 'Fidelity China Special Situations PLC',
    symbol: 'FCSS.L',
  },
  {
    value: 'FDBK.L',
    label: 'FDBK.L,  Feedback plc',
    name: 'Feedback plc',
    symbol: 'FDBK.L',
  },
  {
    value: 'FDEV.L',
    label: 'FDEV.L,  Frontier Developments plc',
    name: 'Frontier Developments plc',
    symbol: 'FDEV.L',
  },
  {
    value: 'FDM.L',
    label: 'FDM.L,  FDM Group (Holdings) plc',
    name: 'FDM Group (Holdings) plc',
    symbol: 'FDM.L',
  },
  {
    value: 'FDP.L',
    label: 'FDP.L,  FD Technologies Plc',
    name: 'FD Technologies Plc',
    symbol: 'FDP.L',
  },
  {
    value: 'FEET.L',
    label: 'FEET.L,  Fundsmith Emerging Equities Trust plc',
    name: 'Fundsmith Emerging Equities Trust plc',
    symbol: 'FEET.L',
  },
  {
    value: 'FEN.L',
    label: 'FEN.L,  Frenkel Topping Group Plc',
    name: 'Frenkel Topping Group Plc',
    symbol: 'FEN.L',
  },
  {
    value: 'FERG.L',
    label: 'FERG.L,  Ferguson plc',
    name: 'Ferguson plc',
    symbol: 'FERG.L',
  },
  {
    value: 'FEV.L',
    label: 'FEV.L,  Fidelity European Trust PLC',
    name: 'Fidelity European Trust PLC',
    symbol: 'FEV.L',
  },
  {
    value: 'FEVR.L',
    label: 'FEVR.L,  Fevertree Drinks PLC',
    name: 'Fevertree Drinks PLC',
    symbol: 'FEVR.L',
  },
  {
    value: 'FGP.L',
    label: 'FGP.L,  FirstGroup plc',
    name: 'FirstGroup plc',
    symbol: 'FGP.L',
  },
  {
    value: 'FGT.L',
    label: 'FGT.L,  Finsbury Growth & Income Trust PLC',
    name: 'Finsbury Growth & Income Trust PLC',
    symbol: 'FGT.L',
  },
  {
    value: 'FIF.L',
    label: 'FIF.L,  Finsbury Food Group Plc',
    name: 'Finsbury Food Group Plc',
    symbol: 'FIF.L',
  },
  {
    value: 'FIH.L',
    label: 'FIH.L,  FIH group plc',
    name: 'FIH group plc',
    symbol: 'FIH.L',
  },
  {
    value: 'FIPP.L',
    label: 'FIPP.L,  Frontier IP Group Plc',
    name: 'Frontier IP Group Plc',
    symbol: 'FIPP.L',
  },
  {
    value: 'FJV.L',
    label: 'FJV.L,  Fidelity Japan Trust PLC',
    name: 'Fidelity Japan Trust PLC',
    symbol: 'FJV.L',
  },
  {
    value: 'FKE.L',
    label: 'FKE.L,  Fiske plc',
    name: 'Fiske plc',
    symbol: 'FKE.L',
  },
  {
    value: 'FLO.L',
    label: 'FLO.L,  Flowtech Fluidpower plc',
    name: 'Flowtech Fluidpower plc',
    symbol: 'FLO.L',
  },
  {
    value: 'FLTR.L',
    label: 'FLTR.L,  Flutter Entertainment plc',
    name: 'Flutter Entertainment plc',
    symbol: 'FLTR.L',
  },
  {
    value: 'FLX.L',
    label: 'FLX.L,  Falanx Group Limited',
    name: 'Falanx Group Limited',
    symbol: 'FLX.L',
  },
  {
    value: 'FOG.L',
    label: 'FOG.L,  Falcon Oil & Gas Ltd.',
    name: 'Falcon Oil & Gas Ltd.',
    symbol: 'FOG.L',
  },
  {
    value: 'FORT.L',
    label: 'FORT.L,  Forterra plc',
    name: 'Forterra plc',
    symbol: 'FORT.L',
  },
  {
    value: 'FOUR.L',
    label: 'FOUR.L,  4imprint Group plc',
    name: '4imprint Group plc',
    symbol: 'FOUR.L',
  },
  {
    value: 'FOXT.L',
    label: 'FOXT.L,  Foxtons Group plc',
    name: 'Foxtons Group plc',
    symbol: 'FOXT.L',
  },
  {
    value: 'FPO.L',
    label: 'FPO.L,  First Property Group plc',
    name: 'First Property Group plc',
    symbol: 'FPO.L',
  },
  {
    value: 'FPP.L',
    label: 'FPP.L,  Fragrant Prosperity Holdings Limited',
    name: 'Fragrant Prosperity Holdings Limited',
    symbol: 'FPP.L',
  },
  {
    value: 'FRAN.L',
    label: 'FRAN.L,  Franchise Brands plc',
    name: 'Franchise Brands plc',
    symbol: 'FRAN.L',
  },
  {
    value: 'FRAS.L',
    label: 'FRAS.L,  Frasers Group plc',
    name: 'Frasers Group plc',
    symbol: 'FRAS.L',
  },
  {
    value: 'FRES.L',
    label: 'FRES.L,  Fresnillo plc',
    name: 'Fresnillo plc',
    symbol: 'FRES.L',
  },
  {
    value: 'FRP.L',
    label: 'FRP.L,  FRP Advisory Group plc',
    name: 'FRP Advisory Group plc',
    symbol: 'FRP.L',
  },
  {
    value: 'FSFL.L',
    label: 'FSFL.L,  Foresight Solar Fund Limited',
    name: 'Foresight Solar Fund Limited',
    symbol: 'FSFL.L',
  },
  {
    value: 'FSJ.L',
    label: 'FSJ.L,  James Fisher and Sons plc',
    name: 'James Fisher and Sons plc',
    symbol: 'FSJ.L',
  },
  {
    value: 'FSTA.L',
    label: 'FSTA.L,  Fuller, Smith & Turner P.L.C.',
    name: 'Fuller, Smith & Turner P.L.C.',
    symbol: 'FSTA.L',
  },
  {
    value: 'FSV.L',
    label: 'FSV.L,  Fidelity Investment Trust - Fidelity Special Values PLC',
    name: 'Fidelity Investment Trust - Fidelity Special Values PLC',
    symbol: 'FSV.L',
  },
  {
    value: 'FTC.L',
    label: 'FTC.L,  Filtronic plc',
    name: 'Filtronic plc',
    symbol: 'FTC.L',
  },
  {
    value: 'FTF.L',
    label: 'FTF.L,  Foresight Enterprise VCT Plc',
    name: 'Foresight Enterprise VCT Plc',
    symbol: 'FTF.L',
  },
  {
    value: 'FTSV.L',
    label: 'FTSV.L,  Foresight Solar & Technology VCT plc',
    name: 'Foresight Solar & Technology VCT plc',
    symbol: 'FTSV.L',
  },
  {
    value: 'FTV.L',
    label: 'FTV.L,  Foresight VCT Plc',
    name: 'Foresight VCT Plc',
    symbol: 'FTV.L',
  },
  {
    value: 'FUL.L',
    label: 'FUL.L,  The Fulham Shore PLC',
    name: 'The Fulham Shore PLC',
    symbol: 'FUL.L',
  },
  {
    value: 'FUM.L',
    label: 'FUM.L,  Futura Medical plc',
    name: 'Futura Medical plc',
    symbol: 'FUM.L',
  },
  {
    value: 'FUTR.L',
    label: 'FUTR.L,  Future plc',
    name: 'Future plc',
    symbol: 'FUTR.L',
  },
  {
    value: 'FXPO.L',
    label: 'FXPO.L,  Ferrexpo plc',
    name: 'Ferrexpo plc',
    symbol: 'FXPO.L',
  },
  {
    value: 'G4M.L',
    label: 'G4M.L,  Gear4music (Holdings) plc',
    name: 'Gear4music (Holdings) plc',
    symbol: 'G4M.L',
  },
  {
    value: 'GABI.L',
    label: 'GABI.L,  GCP Asset Backed Income Fund Limited',
    name: 'GCP Asset Backed Income Fund Limited',
    symbol: 'GABI.L',
  },
  {
    value: 'GAMA.L',
    label: 'GAMA.L,  Gamma Communications plc',
    name: 'Gamma Communications plc',
    symbol: 'GAMA.L',
  },
  {
    value: 'GATC.L',
    label: 'GATC.L,  Gattaca plc',
    name: 'Gattaca plc',
    symbol: 'GATC.L',
  },
  {
    value: 'GAW.L',
    label: 'GAW.L,  Games Workshop Group PLC',
    name: 'Games Workshop Group PLC',
    symbol: 'GAW.L',
  },
  {
    value: 'GBG.L',
    label: 'GBG.L,  GB Group plc',
    name: 'GB Group plc',
    symbol: 'GBG.L',
  },
  {
    value: 'GCL.L',
    label: 'GCL.L,  Geiger Counter Limited',
    name: 'Geiger Counter Limited',
    symbol: 'GCL.L',
  },
  {
    value: 'GCM.L',
    label: 'GCM.L,  GCM Resources Plc',
    name: 'GCM Resources Plc',
    symbol: 'GCM.L',
  },
  {
    value: 'GCP.L',
    label: 'GCP.L,  GCP Infrastructure Investments Limited',
    name: 'GCP Infrastructure Investments Limited',
    symbol: 'GCP.L',
  },
  {
    value: 'GDP.L',
    label: 'GDP.L,  Goldplat PLC',
    name: 'Goldplat PLC',
    symbol: 'GDP.L',
  },
  {
    value: 'GDR.L',
    label: 'GDR.L,  genedrive plc',
    name: 'genedrive plc',
    symbol: 'GDR.L',
  },
  {
    value: 'GDWN.L',
    label: 'GDWN.L,  Goodwin PLC',
    name: 'Goodwin PLC',
    symbol: 'GDWN.L',
  },
  {
    value: 'GEC.L',
    label: 'GEC.L,  General Electric Company',
    name: 'General Electric Company',
    symbol: 'GEC.L',
  },
  {
    value: 'GEEC.L',
    label: 'GEEC.L,  Great Eastern Energy Corporation Limited',
    name: 'Great Eastern Energy Corporation Limited',
    symbol: 'GEEC.L',
  },
  {
    value: 'GEM.L',
    label: 'GEM.L,  Gemfields Group Limited',
    name: 'Gemfields Group Limited',
    symbol: 'GEM.L',
  },
  {
    value: 'GEMD.L',
    label: 'GEMD.L,  Gem Diamonds Limited',
    name: 'Gem Diamonds Limited',
    symbol: 'GEMD.L',
  },
  {
    value: 'GENL.L',
    label: 'GENL.L,  Genel Energy plc',
    name: 'Genel Energy plc',
    symbol: 'GENL.L',
  },
  {
    value: 'GETB.L',
    label: 'GETB.L,  GetBusy plc',
    name: 'GetBusy plc',
    symbol: 'GETB.L',
  },
  {
    value: 'GFHG.L',
    label: 'GFHG.L,  Grand Fortune High Grade Limited',
    name: 'Grand Fortune High Grade Limited',
    symbol: 'GFHG.L',
  },
  {
    value: 'GFIN.L',
    label: 'GFIN.L,  Gfinity plc',
    name: 'Gfinity plc',
    symbol: 'GFIN.L',
  },
  {
    value: 'GFM.L',
    label: 'GFM.L,  Griffin Mining Limited',
    name: 'Griffin Mining Limited',
    symbol: 'GFM.L',
  },
  {
    value: 'GFRD.L',
    label: 'GFRD.L,  Galliford Try Holdings plc',
    name: 'Galliford Try Holdings plc',
    symbol: 'GFRD.L',
  },
  {
    value: 'GFTU.L',
    label: 'GFTU.L,  Grafton Group plc',
    name: 'Grafton Group plc',
    symbol: 'GFTU.L',
  },
  {
    value: 'GGP.L',
    label: 'GGP.L,  Greatland Gold plc',
    name: 'Greatland Gold plc',
    symbol: 'GGP.L',
  },
  {
    value: 'GHE.L',
    label: 'GHE.L,  Gresham House plc',
    name: 'Gresham House plc',
    symbol: 'GHE.L',
  },
  {
    value: 'GHH.L',
    label: 'GHH.L,  Gooch & Housego PLC',
    name: 'Gooch & Housego PLC',
    symbol: 'GHH.L',
  },
  {
    value: 'GHT.L',
    label: 'GHT.L,  Gresham Technologies plc',
    name: 'Gresham Technologies plc',
    symbol: 'GHT.L',
  },
  {
    value: 'GIF.L',
    label: 'GIF.L,  Gulf Investment Fund plc',
    name: 'Gulf Investment Fund plc',
    symbol: 'GIF.L',
  },
  {
    value: 'GINV.L',
    label: 'GINV.L,  Global Invacom Group Limited',
    name: 'Global Invacom Group Limited',
    symbol: 'GINV.L',
  },
  {
    value: 'GKP.L',
    label: 'GKP.L,  Gulf Keystone Petroleum Limited',
    name: 'Gulf Keystone Petroleum Limited',
    symbol: 'GKP.L',
  },
  {
    value: 'GLB.L',
    label: 'GLB.L,  Glanbia plc',
    name: 'Glanbia plc',
    symbol: 'GLB.L',
  },
  {
    value: 'GLE.L',
    label: 'GLE.L,  MJ Gleeson plc',
    name: 'MJ Gleeson plc',
    symbol: 'GLE.L',
  },
  {
    value: 'GLEN.L',
    label: 'GLEN.L,  Glencore plc',
    name: 'Glencore plc',
    symbol: 'GLEN.L',
  },
  {
    value: 'GLO.L',
    label: 'GLO.L,  ContourGlobal plc',
    name: 'ContourGlobal plc',
    symbol: 'GLO.L',
  },
  {
    value: 'GLR.L',
    label: 'GLR.L,  Galileo Resources Plc',
    name: 'Galileo Resources Plc',
    symbol: 'GLR.L',
  },
  {
    value: 'GLV.L',
    label: 'GLV.L,  Glenveagh Properties PLC',
    name: 'Glenveagh Properties PLC',
    symbol: 'GLV.L',
  },
  {
    value: 'GMAA.L',
    label: 'GMAA.L,  Gama Aviation Plc',
    name: 'Gama Aviation Plc',
    symbol: 'GMAA.L',
  },
  {
    value: 'GMR.L',
    label: 'GMR.L,  Gaming Realms plc',
    name: 'Gaming Realms plc',
    symbol: 'GMR.L',
  },
  {
    value: 'GMS.L',
    label: 'GMS.L,  Gulf Marine Services PLC',
    name: 'Gulf Marine Services PLC',
    symbol: 'GMS.L',
  },
  {
    value: 'GNC.L',
    label: 'GNC.L,  Greencore Group plc',
    name: 'Greencore Group plc',
    symbol: 'GNC.L',
  },
  {
    value: 'GNS.L',
    label: 'GNS.L,  Genus plc',
    name: 'Genus plc',
    symbol: 'GNS.L',
  },
  {
    value: 'GOOD.L',
    label: 'GOOD.L,  Good Energy Group PLC',
    name: 'Good Energy Group PLC',
    symbol: 'GOOD.L',
  },
  {
    value: 'GPH.L',
    label: 'GPH.L,  Global Ports Holding Plc',
    name: 'Global Ports Holding Plc',
    symbol: 'GPH.L',
  },
  {
    value: 'GPM.L',
    label: 'GPM.L,  Golden Prospect Precious Metals Ltd.',
    name: 'Golden Prospect Precious Metals Ltd.',
    symbol: 'GPM.L',
  },
  {
    value: 'GR1T.L',
    label: 'GR1T.L,  Grit Real Estate Income Group Limited',
    name: 'Grit Real Estate Income Group Limited',
    symbol: 'GR1T.L',
  },
  {
    value: 'GRA.L',
    label: 'GRA.L,  Grafenia Plc',
    name: 'Grafenia Plc',
    symbol: 'GRA.L',
  },
  {
    value: 'GRC.L',
    label: 'GRC.L,  GRC International Group plc',
    name: 'GRC International Group plc',
    symbol: 'GRC.L',
  },
  {
    value: 'GRG.L',
    label: 'GRG.L,  Greggs plc',
    name: 'Greggs plc',
    symbol: 'GRG.L',
  },
  {
    value: 'GRI.L',
    label: 'GRI.L,  Grainger plc',
    name: 'Grainger plc',
    symbol: 'GRI.L',
  },
  {
    value: 'GRID.L',
    label: 'GRID.L,  Gresham House Energy Storage Fund plc',
    name: 'Gresham House Energy Storage Fund plc',
    symbol: 'GRID.L',
  },
  {
    value: 'GRIO.L',
    label: 'GRIO.L,  Ground Rents Income Fund PLC',
    name: 'Ground Rents Income Fund PLC',
    symbol: 'GRIO.L',
  },
  {
    value: 'GRL.L',
    label: 'GRL.L,  Goldstone Resources Limited',
    name: 'Goldstone Resources Limited',
    symbol: 'GRL.L',
  },
  {
    value: 'GROW.L',
    label: 'GROW.L,  Molten Ventures Plc',
    name: 'Molten Ventures Plc',
    symbol: 'GROW.L',
  },
  {
    value: 'GRP.L',
    label: 'GRP.L,  Greencoat Renewables PLC',
    name: 'Greencoat Renewables PLC',
    symbol: 'GRP.L',
  },
  {
    value: 'GSF.L',
    label: 'GSF.L,  Gore Street Energy Storage Fund Plc',
    name: 'Gore Street Energy Storage Fund Plc',
    symbol: 'GSF.L',
  },
  {
    value: 'GSK.L',
    label: 'GSK.L,  GSK plc',
    name: 'GSK plc',
    symbol: 'GSK.L',
  },
  {
    value: 'GST.L',
    label: 'GST.L,  GSTechnologies Ltd.',
    name: 'GSTechnologies Ltd.',
    symbol: 'GST.L',
  },
  {
    value: 'GTC.L',
    label: 'GTC.L,  Getech Group plc',
    name: 'Getech Group plc',
    symbol: 'GTC.L',
  },
  {
    value: 'GTLY.L',
    label: 'GTLY.L,  Gateley (Holdings) Plc',
    name: 'Gateley (Holdings) Plc',
    symbol: 'GTLY.L',
  },
  {
    value: 'GUN.L',
    label: 'GUN.L,  Gunsynd Plc',
    name: 'Gunsynd Plc',
    symbol: 'GUN.L',
  },
  {
    value: 'GUS.L',
    label: 'GUS.L,  Gusbourne PLC',
    name: 'Gusbourne PLC',
    symbol: 'GUS.L',
  },
  {
    value: 'GWI.L',
    label: 'GWI.L,  Globalworth Real Estate Investments Limited',
    name: 'Globalworth Real Estate Investments Limited',
    symbol: 'GWI.L',
  },
  {
    value: 'GWMO.L',
    label: 'GWMO.L,  Great Western Mining Corporation PLC',
    name: 'Great Western Mining Corporation PLC',
    symbol: 'GWMO.L',
  },
  {
    value: 'GYM.L',
    label: 'GYM.L,  The Gym Group plc',
    name: 'The Gym Group plc',
    symbol: 'GYM.L',
  },
  {
    value: 'HAN.L',
    label: 'HAN.L,  Hansa Investment Company Limited',
    name: 'Hansa Investment Company Limited',
    symbol: 'HAN.L',
  },
  {
    value: 'HAS.L',
    label: 'HAS.L,  Hays plc',
    name: 'Hays plc',
    symbol: 'HAS.L',
  },
  {
    value: 'HAT.L',
    label: 'HAT.L,  H&T Group plc',
    name: 'H&T Group plc',
    symbol: 'HAT.L',
  },
  {
    value: 'HAYD.L',
    label: 'HAYD.L,  Haydale Graphene Industries plc',
    name: 'Haydale Graphene Industries plc',
    symbol: 'HAYD.L',
  },
  {
    value: 'HCFT.L',
    label: 'HCFT.L,  Highcroft Investments Plc',
    name: 'Highcroft Investments Plc',
    symbol: 'HCFT.L',
  },
  {
    value: 'HCM.L',
    label: 'HCM.L,  HUTCHMED (China) Limited',
    name: 'HUTCHMED (China) Limited',
    symbol: 'HCM.L',
  },
  {
    value: 'HDD.L',
    label: 'HDD.L,  Hardide plc',
    name: 'Hardide plc',
    symbol: 'HDD.L',
  },
  {
    value: 'HDT.L',
    label: 'HDT.L,  Holders Technology plc',
    name: 'Holders Technology plc',
    symbol: 'HDT.L',
  },
  {
    value: 'HEAD.L',
    label: 'HEAD.L,  Headlam Group plc',
    name: 'Headlam Group plc',
    symbol: 'HEAD.L',
  },
  {
    value: 'HEFT.L',
    label: 'HEFT.L,  Henderson European Focus Trust plc',
    name: 'Henderson European Focus Trust plc',
    symbol: 'HEFT.L',
  },
  {
    value: 'HEMO.L',
    label: 'HEMO.L,  Hemogenyx Pharmaceuticals Plc',
    name: 'Hemogenyx Pharmaceuticals Plc',
    symbol: 'HEMO.L',
  },
  {
    value: 'HFD.L',
    label: 'HFD.L,  Halfords Group plc',
    name: 'Halfords Group plc',
    symbol: 'HFD.L',
  },
  {
    value: 'HFEL.L',
    label: 'HFEL.L,  Henderson Far East Income Limited',
    name: 'Henderson Far East Income Limited',
    symbol: 'HFEL.L',
  },
  {
    value: 'HFG.L',
    label: 'HFG.L,  Hilton Food Group plc',
    name: 'Hilton Food Group plc',
    symbol: 'HFG.L',
  },
  {
    value: 'HGT.L',
    label: 'HGT.L,  HgCapital Trust plc',
    name: 'HgCapital Trust plc',
    symbol: 'HGT.L',
  },
  {
    value: 'HHI.L',
    label: 'HHI.L,  Henderson High Income Trust plc',
    name: 'Henderson High Income Trust plc',
    symbol: 'HHI.L',
  },
  {
    value: 'HHV.L',
    label: 'HHV.L,  Hargreave Hale AIM VCT plc',
    name: 'Hargreave Hale AIM VCT plc',
    symbol: 'HHV.L',
  },
  {
    value: 'HICL.L',
    label: 'HICL.L,  HICL Infrastructure PLC',
    name: 'HICL Infrastructure PLC',
    symbol: 'HICL.L',
  },
  {
    value: 'HIK.L',
    label: 'HIK.L,  Hikma Pharmaceuticals PLC',
    name: 'Hikma Pharmaceuticals PLC',
    symbol: 'HIK.L',
  },
  {
    value: 'HILS.L',
    label: 'HILS.L,  Hill & Smith PLC',
    name: 'Hill & Smith PLC',
    symbol: 'HILS.L',
  },
  {
    value: 'HINT.L',
    label: 'HINT.L,  Henderson International Income Trust plc',
    name: 'Henderson International Income Trust plc',
    symbol: 'HINT.L',
  },
  {
    value: 'HLCL.L',
    label: 'HLCL.L,  Helical plc',
    name: 'Helical plc',
    symbol: 'HLCL.L',
  },
  {
    value: 'HLMA.L',
    label: 'HLMA.L,  Halma plc',
    name: 'Halma plc',
    symbol: 'HLMA.L',
  },
  {
    value: 'HMI.L',
    label: 'HMI.L,  Harvest Minerals Limited',
    name: 'Harvest Minerals Limited',
    symbol: 'HMI.L',
  },
  {
    value: 'HMSO.L',
    label: 'HMSO.L,  Hammerson plc',
    name: 'Hammerson plc',
    symbol: 'HMSO.L',
  },
  {
    value: 'HNE.L',
    label: 'HNE.L,  Henderson EuroTrust plc',
    name: 'Henderson EuroTrust plc',
    symbol: 'HNE.L',
  },
  {
    value: 'HOC.L',
    label: 'HOC.L,  Hochschild Mining plc',
    name: 'Hochschild Mining plc',
    symbol: 'HOC.L',
  },
  {
    value: 'HOT.L',
    label: 'HOT.L,  Henderson Opportunities Trust plc',
    name: 'Henderson Opportunities Trust plc',
    symbol: 'HOT.L',
  },
  {
    value: 'HOTC.L',
    label: 'HOTC.L,  Hotel Chocolat Group plc',
    name: 'Hotel Chocolat Group plc',
    symbol: 'HOTC.L',
  },
  {
    value: 'HPAC.L',
    label: 'HPAC.L,  Hermes Pacific Investments plc',
    name: 'Hermes Pacific Investments plc',
    symbol: 'HPAC.L',
  },
  {
    value: 'HRI.L',
    label: 'HRI.L,  Herald Investment Trust PLC',
    name: 'Herald Investment Trust PLC',
    symbol: 'HRI.L',
  },
  {
    value: 'HRN.L',
    label: 'HRN.L,  Hornby PLC',
    name: 'Hornby PLC',
    symbol: 'HRN.L',
  },
  {
    value: 'HSBA.L',
    label: 'HSBA.L,  HSBC Holdings plc',
    name: 'HSBC Holdings plc',
    symbol: 'HSBA.L',
  },
  {
    value: 'HSD.L',
    label: 'HSD.L,  Hansard Global plc',
    name: 'Hansard Global plc',
    symbol: 'HSD.L',
  },
  {
    value: 'HSL.L',
    label: 'HSL.L,  The Henderson Smaller Companies Investment Trust plc',
    name: 'The Henderson Smaller Companies Investment Trust plc',
    symbol: 'HSL.L',
  },
  {
    value: 'HSM.L',
    label: 'HSM.L,  Samuel Heath & Sons plc',
    name: 'Samuel Heath & Sons plc',
    symbol: 'HSM.L',
  },
  {
    value: 'HSP.L',
    label: 'HSP.L,  Hargreaves Services Plc',
    name: 'Hargreaves Services Plc',
    symbol: 'HSP.L',
  },
  {
    value: 'HSS.L',
    label: 'HSS.L,  HSS Hire Group plc',
    name: 'HSS Hire Group plc',
    symbol: 'HSS.L',
  },
  {
    value: 'HSV.L',
    label: 'HSV.L,  HomeServe plc',
    name: 'HomeServe plc',
    symbol: 'HSV.L',
  },
  {
    value: 'HSW.L',
    label: 'HSW.L,  Hostelworld Group plc',
    name: 'Hostelworld Group plc',
    symbol: 'HSW.L',
  },
  {
    value: 'HSX.L',
    label: 'HSX.L,  Hiscox Ltd',
    name: 'Hiscox Ltd',
    symbol: 'HSX.L',
  },
  {
    value: 'HTCF.L',
    label: 'HTCF.L,  Highbridge Tactical Credit Fund Limited',
    name: 'Highbridge Tactical Credit Fund Limited',
    symbol: 'HTCF.L',
  },
  {
    value: 'HTG.L',
    label: 'HTG.L,  Hunting PLC',
    name: 'Hunting PLC',
    symbol: 'HTG.L',
  },
  {
    value: 'HTWS.L',
    label: 'HTWS.L,  Helios Towers plc',
    name: 'Helios Towers plc',
    symbol: 'HTWS.L',
  },
  {
    value: 'HUM.L',
    label: 'HUM.L,  Hummingbird Resources PLC',
    name: 'Hummingbird Resources PLC',
    symbol: 'HUM.L',
  },
  {
    value: 'HUR.L',
    label: 'HUR.L,  Hurricane Energy plc',
    name: 'Hurricane Energy plc',
    symbol: 'HUR.L',
  },
  {
    value: 'HUW.L',
    label: 'HUW.L,  Helios Underwriting plc',
    name: 'Helios Underwriting plc',
    symbol: 'HUW.L',
  },
  {
    value: 'HVPD.L',
    label: 'HVPD.L,  HarbourVest Global Private Equity Ltd.',
    name: 'HarbourVest Global Private Equity Ltd.',
    symbol: 'HVPD.L',
  },
  {
    value: 'HVPE.L',
    label: 'HVPE.L,  HarbourVest Global Private Equity Ltd.',
    name: 'HarbourVest Global Private Equity Ltd.',
    symbol: 'HVPE.L',
  },
  {
    value: 'HVT.L',
    label: 'HVT.L,  The Heavitree Brewery PLC',
    name: 'The Heavitree Brewery PLC',
    symbol: 'HVT.L',
  },
  {
    value: 'HWDN.L',
    label: 'HWDN.L,  Howden Joinery Group Plc',
    name: 'Howden Joinery Group Plc',
    symbol: 'HWDN.L',
  },
  {
    value: 'HWG.L',
    label: 'HWG.L,  Harworth Group plc',
    name: 'Harworth Group plc',
    symbol: 'HWG.L',
  },
  {
    value: 'HYG.L',
    label: 'HYG.L,  Seneca Growth Capital VCT plc',
    name: 'Seneca Growth Capital VCT plc',
    symbol: 'HYG.L',
  },
  {
    value: 'HYVE.L',
    label: 'HYVE.L,  Hyve Group Plc',
    name: 'Hyve Group Plc',
    symbol: 'HYVE.L',
  },
  {
    value: 'HZM.L',
    label: 'HZM.L,  Horizonte Minerals Plc',
    name: 'Horizonte Minerals Plc',
    symbol: 'HZM.L',
  },
  {
    value: 'IAG.L',
    label: 'IAG.L,  International Consolidated Airlines Group S.A.',
    name: 'International Consolidated Airlines Group S.A.',
    symbol: 'IAG.L',
  },
  {
    value: 'IAT.L',
    label: 'IAT.L,  Invesco Asia Trust plc',
    name: 'Invesco Asia Trust plc',
    symbol: 'IAT.L',
  },
  {
    value: 'IBM.L',
    label: 'IBM.L,  International Business Machines Corporation',
    name: 'International Business Machines Corporation',
    symbol: 'IBM.L',
  },
  {
    value: 'IBPO.L',
    label: 'IBPO.L,  iEnergizer Limited',
    name: 'iEnergizer Limited',
    symbol: 'IBPO.L',
  },
  {
    value: 'IBST.L',
    label: 'IBST.L,  Ibstock plc',
    name: 'Ibstock plc',
    symbol: 'IBST.L',
  },
  {
    value: 'IBT.L',
    label: 'IBT.L,  International Biotechnology Trust plc',
    name: 'International Biotechnology Trust plc',
    symbol: 'IBT.L',
  },
  {
    value: 'ICGC.L',
    label: 'ICGC.L,  Irish Continental Group plc',
    name: 'Irish Continental Group plc',
    symbol: 'ICGC.L',
  },
  {
    value: 'ICGT.L',
    label: 'ICGT.L,  ICG Enterprise Trust PLC',
    name: 'ICG Enterprise Trust PLC',
    symbol: 'ICGT.L',
  },
  {
    value: 'ICP.L',
    label: 'ICP.L,  Intermediate Capital Group plc',
    name: 'Intermediate Capital Group plc',
    symbol: 'ICP.L',
  },
  {
    value: 'IDE.L',
    label: 'IDE.L,  IDE Group Holdings plc',
    name: 'IDE Group Holdings plc',
    symbol: 'IDE.L',
  },
  {
    value: 'IDHC.L',
    label: 'IDHC.L,  Integrated Diagnostics Holdings plc',
    name: 'Integrated Diagnostics Holdings plc',
    symbol: 'IDHC.L',
  },
  {
    value: 'IDOX.L',
    label: 'IDOX.L,  IDOX plc',
    name: 'IDOX plc',
    symbol: 'IDOX.L',
  },
  {
    value: 'IEM.L',
    label: 'IEM.L,  Impax Environmental Markets plc',
    name: 'Impax Environmental Markets plc',
    symbol: 'IEM.L',
  },
  {
    value: 'IES.L',
    label: 'IES.L,  Invinity Energy Systems plc',
    name: 'Invinity Energy Systems plc',
    symbol: 'IES.L',
  },
  {
    value: 'IGAS.L',
    label: 'IGAS.L,  IGas Energy plc',
    name: 'IGas Energy plc',
    symbol: 'IGAS.L',
  },
  {
    value: 'IGC.L',
    label: 'IGC.L,  India Capital Growth Fund Limited',
    name: 'India Capital Growth Fund Limited',
    symbol: 'IGC.L',
  },
  {
    value: 'IGE.L',
    label: 'IGE.L,  Image Scan Holdings Plc',
    name: 'Image Scan Holdings Plc',
    symbol: 'IGE.L',
  },
  {
    value: 'IGG.L',
    label: 'IGG.L,  IG Group Holdings plc',
    name: 'IG Group Holdings plc',
    symbol: 'IGG.L',
  },
  {
    value: 'IGP.L',
    label: 'IGP.L,  Intercede Group plc',
    name: 'Intercede Group plc',
    symbol: 'IGP.L',
  },
  {
    value: 'IGR.L',
    label: 'IGR.L,  IG Design Group plc',
    name: 'IG Design Group plc',
    symbol: 'IGR.L',
  },
  {
    value: 'IGV.L',
    label: 'IGV.L,  The Income & Growth VCT plc',
    name: 'The Income & Growth VCT plc',
    symbol: 'IGV.L',
  },
  {
    value: 'IHC.L',
    label: 'IHC.L,  Inspiration Healthcare Group plc',
    name: 'Inspiration Healthcare Group plc',
    symbol: 'IHC.L',
  },
  {
    value: 'IHG.L',
    label: 'IHG.L,  InterContinental Hotels Group PLC',
    name: 'InterContinental Hotels Group PLC',
    symbol: 'IHG.L',
  },
  {
    value: 'IHP.L',
    label: 'IHP.L,  IntegraFin Holdings plc',
    name: 'IntegraFin Holdings plc',
    symbol: 'IHP.L',
  },
  {
    value: 'IHR.L',
    label: 'IHR.L,  Impact Healthcare REIT PLC',
    name: 'Impact Healthcare REIT PLC',
    symbol: 'IHR.L',
  },
  {
    value: 'III.L',
    label: 'III.L,  3i Group plc',
    name: '3i Group plc',
    symbol: 'III.L',
  },
  {
    value: 'IIP.L',
    label: 'IIP.L,  Infrastructure India PLC',
    name: 'Infrastructure India PLC',
    symbol: 'IIP.L',
  },
  {
    value: 'IIT.L',
    label: 'IIT.L,  The Independent Investment Trust PLC',
    name: 'The Independent Investment Trust PLC',
    symbol: 'IIT.L',
  },
  {
    value: 'IKA.L',
    label: 'IKA.L,  Ilika plc',
    name: 'Ilika plc',
    symbol: 'IKA.L',
  },
  {
    value: 'IL0A.L',
    label: 'IL0A.L,  Permanent TSB Group Holdings plc',
    name: 'Permanent TSB Group Holdings plc',
    symbol: 'IL0A.L',
  },
  {
    value: 'IMB.L',
    label: 'IMB.L,  Imperial Brands PLC',
    name: 'Imperial Brands PLC',
    symbol: 'IMB.L',
  },
  {
    value: 'IMI.L',
    label: 'IMI.L,  IMI plc',
    name: 'IMI plc',
    symbol: 'IMI.L',
  },
  {
    value: 'IMM.L',
    label: 'IMM.L,  ImmuPharma plc',
    name: 'ImmuPharma plc',
    symbol: 'IMM.L',
  },
  {
    value: 'IMMO.L',
    label: 'IMMO.L,  Immotion Group Plc',
    name: 'Immotion Group Plc',
    symbol: 'IMMO.L',
  },
  {
    value: 'INCE.L',
    label: 'INCE.L,  The Ince Group plc',
    name: 'The Ince Group plc',
    symbol: 'INCE.L',
  },
  {
    value: 'INCH.L',
    label: 'INCH.L,  Inchcape plc',
    name: 'Inchcape plc',
    symbol: 'INCH.L',
  },
  {
    value: 'INDI.L',
    label: 'INDI.L,  Indus Gas Limited',
    name: 'Indus Gas Limited',
    symbol: 'INDI.L',
  },
  {
    value: 'INDV.L',
    label: 'INDV.L,  Indivior PLC',
    name: 'Indivior PLC',
    symbol: 'INDV.L',
  },
  {
    value: 'INF.L',
    label: 'INF.L,  Informa plc',
    name: 'Informa plc',
    symbol: 'INF.L',
  },
  {
    value: 'ING.L',
    label: 'ING.L,  Ingenta plc',
    name: 'Ingenta plc',
    symbol: 'ING.L',
  },
  {
    value: 'INHC.L',
    label: 'INHC.L,  Induction Healthcare Group PLC',
    name: 'Induction Healthcare Group PLC',
    symbol: 'INHC.L',
  },
  {
    value: 'INL.L',
    label: 'INL.L,  Inland Homes plc',
    name: 'Inland Homes plc',
    symbol: 'INL.L',
  },
  {
    value: 'INLZ.L',
    label: 'INLZ.L,  Inland ZDP Plc',
    name: 'Inland ZDP Plc',
    symbol: 'INLZ.L',
  },
  {
    value: 'INPP.L',
    label: 'INPP.L,  International Public Partnerships Limited',
    name: 'International Public Partnerships Limited',
    symbol: 'INPP.L',
  },
  {
    value: 'INS.L',
    label: 'INS.L,  Instem plc',
    name: 'Instem plc',
    symbol: 'INS.L',
  },
  {
    value: 'INSE.L',
    label: 'INSE.L,  Inspired Plc',
    name: 'Inspired Plc',
    symbol: 'INSE.L',
  },
  {
    value: 'INSP.L',
    label: 'INSP.L,  Inspirit Energy Holdings Plc',
    name: 'Inspirit Energy Holdings Plc',
    symbol: 'INSP.L',
  },
  {
    value: 'INV.L',
    label: 'INV.L,  The Investment Company plc',
    name: 'The Investment Company plc',
    symbol: 'INV.L',
  },
  {
    value: 'INVP.L',
    label: 'INVP.L,  Investec Group',
    name: 'Investec Group',
    symbol: 'INVP.L',
  },
  {
    value: 'INX.L',
    label: 'INX.L,  i-nexus Global plc',
    name: 'i-nexus Global plc',
    symbol: 'INX.L',
  },
  {
    value: 'IOF.L',
    label: 'IOF.L,  Iofina plc',
    name: 'Iofina plc',
    symbol: 'IOF.L',
  },
  {
    value: 'IOG.L',
    label: 'IOG.L,  IOG plc',
    name: 'IOG plc',
    symbol: 'IOG.L',
  },
  {
    value: 'IOM.L',
    label: 'IOM.L,  iomart Group plc',
    name: 'iomart Group plc',
    symbol: 'IOM.L',
  },
  {
    value: 'IPEL.L',
    label: 'IPEL.L,  Impellam Group plc',
    name: 'Impellam Group plc',
    symbol: 'IPEL.L',
  },
  {
    value: 'IPF.L',
    label: 'IPF.L,  International Personal Finance plc',
    name: 'International Personal Finance plc',
    symbol: 'IPF.L',
  },
  {
    value: 'IPO.L',
    label: 'IPO.L,  IP Group Plc',
    name: 'IP Group Plc',
    symbol: 'IPO.L',
  },
  {
    value: 'IPU.L',
    label:
      'IPU.L,  INVESCO Perpetual UK Smaller Companies Investment Trust plc',
    name: 'INVESCO Perpetual UK Smaller Companies Investment Trust plc',
    symbol: 'IPU.L',
  },
  {
    value: 'IPX.L',
    label: 'IPX.L,  Impax Asset Management Group plc',
    name: 'Impax Asset Management Group plc',
    symbol: 'IPX.L',
  },
  {
    value: 'IQAI.L',
    label: 'IQAI.L,  IQ-AI Limited',
    name: 'IQ-AI Limited',
    symbol: 'IQAI.L',
  },
  {
    value: 'IQE.L',
    label: 'IQE.L,  IQE plc',
    name: 'IQE plc',
    symbol: 'IQE.L',
  },
  {
    value: 'IQG.L',
    label: 'IQG.L,  IQGeo Group plc',
    name: 'IQGeo Group plc',
    symbol: 'IQG.L',
  },
  {
    value: 'IRON.L',
    label: 'IRON.L,  Ironveld Plc',
    name: 'Ironveld Plc',
    symbol: 'IRON.L',
  },
  {
    value: 'ITM.L',
    label: 'ITM.L,  ITM Power Plc',
    name: 'ITM Power Plc',
    symbol: 'ITM.L',
  },
  {
    value: 'ITRK.L',
    label: 'ITRK.L,  Intertek Group plc',
    name: 'Intertek Group plc',
    symbol: 'ITRK.L',
  },
  {
    value: 'ITV.L',
    label: 'ITV.L,  ITV plc',
    name: 'ITV plc',
    symbol: 'ITV.L',
  },
  {
    value: 'ITX.L',
    label: 'ITX.L,  Itaconix plc',
    name: 'Itaconix plc',
    symbol: 'ITX.L',
  },
  {
    value: 'IVPU.L',
    label:
      'IVPU.L,  Invesco Perpetual Select Trust plc - UK Equity Share Portfolio',
    name: 'Invesco Perpetual Select Trust plc - UK Equity Share Portfolio',
    symbol: 'IVPU.L',
  },
  {
    value: 'IWG.L',
    label: 'IWG.L,  IWG plc',
    name: 'IWG plc',
    symbol: 'IWG.L',
  },
  {
    value: 'IXI.L',
    label: 'IXI.L,  IXICO plc',
    name: 'IXICO plc',
    symbol: 'IXI.L',
  },
  {
    value: 'JAGI.L',
    label: 'JAGI.L,  JPMorgan Asia Growth & Income plc',
    name: 'JPMorgan Asia Growth & Income plc',
    symbol: 'JAGI.L',
  },
  {
    value: 'JAM.L',
    label: 'JAM.L,  JPMorgan American Investment Trust plc',
    name: 'JPMorgan American Investment Trust plc',
    symbol: 'JAM.L',
  },
  {
    value: 'JAN.L',
    label: 'JAN.L,  Jangada Mines Plc',
    name: 'Jangada Mines Plc',
    symbol: 'JAN.L',
  },
  {
    value: 'JARA.L',
    label: 'JARA.L,  JPMorgan Global Core Real Assets Limited',
    name: 'JPMorgan Global Core Real Assets Limited',
    symbol: 'JARA.L',
  },
  {
    value: 'JAY.L',
    label: 'JAY.L,  Bluejay Mining plc',
    name: 'Bluejay Mining plc',
    symbol: 'JAY.L',
  },
  {
    value: 'JCGI.L',
    label: 'JCGI.L,  JPMorgan China Growth & Income plc',
    name: 'JPMorgan China Growth & Income plc',
    symbol: 'JCGI.L',
  },
  {
    value: 'JCH.L',
    label: 'JCH.L,  JPMorgan Claverhouse Investment Trust plc',
    name: 'JPMorgan Claverhouse Investment Trust plc',
    symbol: 'JCH.L',
  },
  {
    value: 'JDG.L',
    label: 'JDG.L,  Judges Scientific plc',
    name: 'Judges Scientific plc',
    symbol: 'JDG.L',
  },
  {
    value: 'JDW.L',
    label: 'JDW.L,  J D Wetherspoon plc',
    name: 'J D Wetherspoon plc',
    symbol: 'JDW.L',
  },
  {
    value: 'JEL.L',
    label: 'JEL.L,  Jersey Electricity plc',
    name: 'Jersey Electricity plc',
    symbol: 'JEL.L',
  },
  {
    value: 'JEMI.L',
    label: 'JEMI.L,  JPMorgan Global Emerging Markets Income Trust plc',
    name: 'JPMorgan Global Emerging Markets Income Trust plc',
    symbol: 'JEMI.L',
  },
  {
    value: 'JET.L',
    label: 'JET.L,  Just Eat Takeaway.com N.V.',
    name: 'Just Eat Takeaway.com N.V.',
    symbol: 'JET.L',
  },
  {
    value: 'JFJ.L',
    label: 'JFJ.L,  JPMorgan Japanese Investment Trust plc',
    name: 'JPMorgan Japanese Investment Trust plc',
    symbol: 'JFJ.L',
  },
  {
    value: 'JGC.L',
    label: 'JGC.L,  Jupiter Green Investment Trust PLC',
    name: 'Jupiter Green Investment Trust PLC',
    symbol: 'JGC.L',
  },
  {
    value: 'JGGI.L',
    label: 'JGGI.L,  JPMorgan Global Growth & Income plc',
    name: 'JPMorgan Global Growth & Income plc',
    symbol: 'JGGI.L',
  },
  {
    value: 'JHD.L',
    label: 'JHD.L,  James Halstead plc',
    name: 'James Halstead plc',
    symbol: 'JHD.L',
  },
  {
    value: 'JII.L',
    label: 'JII.L,  JPMorgan Indian Investment Trust plc',
    name: 'JPMorgan Indian Investment Trust plc',
    symbol: 'JII.L',
  },
  {
    value: 'JIM.L',
    label: 'JIM.L,  Jarvis Securities plc',
    name: 'Jarvis Securities plc',
    symbol: 'JIM.L',
  },
  {
    value: 'JLEN.L',
    label: 'JLEN.L,  JLEN Environmental Assets Group Limited',
    name: 'JLEN Environmental Assets Group Limited',
    symbol: 'JLEN.L',
  },
  {
    value: 'JLH.L',
    label: 'JLH.L,  John Lewis of Hungerford plc',
    name: 'John Lewis of Hungerford plc',
    symbol: 'JLH.L',
  },
  {
    value: 'JLP.L',
    label: 'JLP.L,  Jubilee Metals Group PLC',
    name: 'Jubilee Metals Group PLC',
    symbol: 'JLP.L',
  },
  {
    value: 'JMAT.L',
    label: 'JMAT.L,  Johnson Matthey Plc',
    name: 'Johnson Matthey Plc',
    symbol: 'JMAT.L',
  },
  {
    value: 'JMF.L',
    label: 'JMF.L,  JPMorgan Mid Cap Investment Trust plc',
    name: 'JPMorgan Mid Cap Investment Trust plc',
    symbol: 'JMF.L',
  },
  {
    value: 'JMG.L',
    label: 'JMG.L,  JPMorgan Emerging Markets Investment Trust plc',
    name: 'JPMorgan Emerging Markets Investment Trust plc',
    symbol: 'JMG.L',
  },
  {
    value: 'JMI.L',
    label: 'JMI.L,  JPMorgan UK Smaller Companies Investment Trust Plc',
    name: 'JPMorgan UK Smaller Companies Investment Trust Plc',
    symbol: 'JMI.L',
  },
  {
    value: 'JNEO.L',
    label: 'JNEO.L,  Journeo plc',
    name: 'Journeo plc',
    symbol: 'JNEO.L',
  },
  {
    value: 'JOG.L',
    label: 'JOG.L,  Jersey Oil and Gas Plc',
    name: 'Jersey Oil and Gas Plc',
    symbol: 'JOG.L',
  },
  {
    value: 'JOUL.L',
    label: 'JOUL.L,  Joules Group Plc',
    name: 'Joules Group Plc',
    symbol: 'JOUL.L',
  },
  {
    value: 'JPEI.L',
    label: 'JPEI.L,  JPMorgan Elect plc - Managed Income',
    name: 'JPMorgan Elect plc - Managed Income',
    symbol: 'JPEI.L',
  },
  {
    value: 'JPEL.L',
    label: 'JPEL.L,  JPEL Private Equity Limited',
    name: 'JPEL Private Equity Limited',
    symbol: 'JPEL.L',
  },
  {
    value: 'JRS.L',
    label: 'JRS.L,  JPMorgan Russian Securities plc',
    name: 'JPMorgan Russian Securities plc',
    symbol: 'JRS.L',
  },
  {
    value: 'JSG.L',
    label: 'JSG.L,  Johnson Service Group PLC',
    name: 'Johnson Service Group PLC',
    symbol: 'JSG.L',
  },
  {
    value: 'JTC.L',
    label: 'JTC.L,  JTC PLC',
    name: 'JTC PLC',
    symbol: 'JTC.L',
  },
  {
    value: 'JUP.L',
    label: 'JUP.L,  Jupiter Fund Management Plc',
    name: 'Jupiter Fund Management Plc',
    symbol: 'JUP.L',
  },
  {
    value: 'JUSC.L',
    label: 'JUSC.L,  JPMorgan US Smaller Companies Investment Trust plc',
    name: 'JPMorgan US Smaller Companies Investment Trust plc',
    symbol: 'JUSC.L',
  },
  {
    value: 'JUST.L',
    label: 'JUST.L,  Just Group plc',
    name: 'Just Group plc',
    symbol: 'JUST.L',
  },
  {
    value: 'JWNG.L',
    label: 'JWNG.L,  Jaywing plc',
    name: 'Jaywing plc',
    symbol: 'JWNG.L',
  },
  {
    value: 'JZCP.L',
    label: 'JZCP.L,  JZ Capital Partners Limited',
    name: 'JZ Capital Partners Limited',
    symbol: 'JZCP.L',
  },
  {
    value: 'K3C.L',
    label: 'K3C.L,  K3 Capital Group PLC',
    name: 'K3 Capital Group PLC',
    symbol: 'K3C.L',
  },
  {
    value: 'KAPE.L',
    label: 'KAPE.L,  Kape Technologies PLC',
    name: 'Kape Technologies PLC',
    symbol: 'KAPE.L',
  },
  {
    value: 'KAT.L',
    label: 'KAT.L,  Katoro Gold plc',
    name: 'Katoro Gold plc',
    symbol: 'KAT.L',
  },
  {
    value: 'KAV.L',
    label: 'KAV.L,  Kavango Resources Plc',
    name: 'Kavango Resources Plc',
    symbol: 'KAV.L',
  },
  {
    value: 'KAY.L',
    label: 'KAY.L,  Kings Arms Yard VCT PLC',
    name: 'Kings Arms Yard VCT PLC',
    symbol: 'KAY.L',
  },
  {
    value: 'KBT.L',
    label: 'KBT.L,  K3 Business Technology Group plc',
    name: 'K3 Business Technology Group plc',
    symbol: 'KBT.L',
  },
  {
    value: 'KCR.L',
    label: 'KCR.L,  KCR Residential REIT plc',
    name: 'KCR Residential REIT plc',
    symbol: 'KCR.L',
  },
  {
    value: 'KCT.L',
    label: 'KCT.L,  Kin and Carta plc',
    name: 'Kin and Carta plc',
    symbol: 'KCT.L',
  },
  {
    value: 'KDNC.L',
    label: 'KDNC.L,  Cadence Minerals Plc',
    name: 'Cadence Minerals Plc',
    symbol: 'KDNC.L',
  },
  {
    value: 'KDR.L',
    label: 'KDR.L,  Karelian Diamond Resources Plc',
    name: 'Karelian Diamond Resources Plc',
    symbol: 'KDR.L',
  },
  {
    value: 'KEFI.L',
    label: 'KEFI.L,  KEFI Gold and Copper Plc',
    name: 'KEFI Gold and Copper Plc',
    symbol: 'KEFI.L',
  },
  {
    value: 'KETL.L',
    label: 'KETL.L,  Strix Group Plc',
    name: 'Strix Group Plc',
    symbol: 'KETL.L',
  },
  {
    value: 'KEYS.L',
    label: 'KEYS.L,  Keystone Law Group plc',
    name: 'Keystone Law Group plc',
    symbol: 'KEYS.L',
  },
  {
    value: 'KGF.L',
    label: 'KGF.L,  Kingfisher plc',
    name: 'Kingfisher plc',
    symbol: 'KGF.L',
  },
  {
    value: 'KGH.L',
    label: 'KGH.L,  Knights Group Holdings plc',
    name: 'Knights Group Holdings plc',
    symbol: 'KGH.L',
  },
  {
    value: 'KGP.L',
    label: 'KGP.L,  Kingspan Group plc',
    name: 'Kingspan Group plc',
    symbol: 'KGP.L',
  },
  {
    value: 'KIBO.L',
    label: 'KIBO.L,  Kibo Energy PLC',
    name: 'Kibo Energy PLC',
    symbol: 'KIBO.L',
  },
  {
    value: 'KIE.L',
    label: 'KIE.L,  Kier Group plc',
    name: 'Kier Group plc',
    symbol: 'KIE.L',
  },
  {
    value: 'KLR.L',
    label: 'KLR.L,  Keller Group plc',
    name: 'Keller Group plc',
    symbol: 'KLR.L',
  },
  {
    value: 'KMK.L',
    label: 'KMK.L,  Kromek Group plc',
    name: 'Kromek Group plc',
    symbol: 'KMK.L',
  },
  {
    value: 'KMR.L',
    label: 'KMR.L,  Kenmare Resources plc',
    name: 'Kenmare Resources plc',
    symbol: 'KMR.L',
  },
  {
    value: 'KNOS.L',
    label: 'KNOS.L,  Kainos Group plc',
    name: 'Kainos Group plc',
    symbol: 'KNOS.L',
  },
  {
    value: 'KOD.L',
    label: 'KOD.L,  Kodal Minerals Plc',
    name: 'Kodal Minerals Plc',
    symbol: 'KOD.L',
  },
  {
    value: 'KOS.L',
    label: 'KOS.L,  Kosmos Energy Ltd.',
    name: 'Kosmos Energy Ltd.',
    symbol: 'KOS.L',
  },
  {
    value: 'KP2.L',
    label: 'KP2.L,  Kore Potash plc',
    name: 'Kore Potash plc',
    symbol: 'KP2.L',
  },
  {
    value: 'KRM.L',
    label: 'KRM.L,  KRM22 Plc',
    name: 'KRM22 Plc',
    symbol: 'KRM.L',
  },
  {
    value: 'KRPZ.L',
    label: 'KRPZ.L,  Kropz plc',
    name: 'Kropz plc',
    symbol: 'KRPZ.L',
  },
  {
    value: 'KRS.L',
    label: 'KRS.L,  Keras Resources Plc',
    name: 'Keras Resources Plc',
    symbol: 'KRS.L',
  },
  {
    value: 'KWG.L',
    label: 'KWG.L,  Kingswood Holdings Limited',
    name: 'Kingswood Holdings Limited',
    symbol: 'KWG.L',
  },
  {
    value: 'KWS.L',
    label: 'KWS.L,  Keywords Studios plc',
    name: 'Keywords Studios plc',
    symbol: 'KWS.L',
  },
  {
    value: 'KYGA.L',
    label: 'KYGA.L,  Kerry Group plc',
    name: 'Kerry Group plc',
    symbol: 'KYGA.L',
  },
  {
    value: 'KZG.L',
    label: 'KZG.L,  Kazera Global plc',
    name: 'Kazera Global plc',
    symbol: 'KZG.L',
  },
  {
    value: 'LAND.L',
    label: 'LAND.L,  Land Securities Group plc',
    name: 'Land Securities Group plc',
    symbol: 'LAND.L',
  },
  {
    value: 'LAS.L',
    label: 'LAS.L,  London & Associated Properties PLC',
    name: 'London & Associated Properties PLC',
    symbol: 'LAS.L',
  },
  {
    value: 'LBE.L',
    label: 'LBE.L,  Longboat Energy plc',
    name: 'Longboat Energy plc',
    symbol: 'LBE.L',
  },
  {
    value: 'LBOW.L',
    label:
      'LBOW.L,  ICG-Longbow Senior Secured UK Property Debt Investments Ltd',
    name: 'ICG-Longbow Senior Secured UK Property Debt Investments Ltd',
    symbol: 'LBOW.L',
  },
  {
    value: 'LDSG.L',
    label: 'LDSG.L,  Leeds Group plc',
    name: 'Leeds Group plc',
    symbol: 'LDSG.L',
  },
  {
    value: 'LFI.L',
    label: 'LFI.L,  London Finance & Investment Group P.L.C.',
    name: 'London Finance & Investment Group P.L.C.',
    symbol: 'LFI.L',
  },
  {
    value: 'LGEN.L',
    label: 'LGEN.L,  Legal & General Group Plc',
    name: 'Legal & General Group Plc',
    symbol: 'LGEN.L',
  },
  {
    value: 'LGRS.L',
    label: 'LGRS.L,  Loungers plc',
    name: 'Loungers plc',
    symbol: 'LGRS.L',
  },
  {
    value: 'LIO.L',
    label: 'LIO.L,  Liontrust Asset Management PLC',
    name: 'Liontrust Asset Management PLC',
    symbol: 'LIO.L',
  },
  {
    value: 'LIT.L',
    label: 'LIT.L,  Litigation Capital Management Limited',
    name: 'Litigation Capital Management Limited',
    symbol: 'LIT.L',
  },
  {
    value: 'LIV.L',
    label: 'LIV.L,  Livermore Investments Group Limited',
    name: 'Livermore Investments Group Limited',
    symbol: 'LIV.L',
  },
  {
    value: 'LLOY.L',
    label: 'LLOY.L,  Lloyds Banking Group plc',
    name: 'Lloyds Banking Group plc',
    symbol: 'LLOY.L',
  },
  {
    value: 'LME.L',
    label: 'LME.L,  Limitless Earth plc',
    name: 'Limitless Earth plc',
    symbol: 'LME.L',
  },
  {
    value: 'LMP.L',
    label: 'LMP.L,  LondonMetric Property Plc',
    name: 'LondonMetric Property Plc',
    symbol: 'LMP.L',
  },
  {
    value: 'LMS.L',
    label: 'LMS.L,  LMS Capital plc',
    name: 'LMS Capital plc',
    symbol: 'LMS.L',
  },
  {
    value: 'LND.L',
    label: 'LND.L,  Landore Resources Limited',
    name: 'Landore Resources Limited',
    symbol: 'LND.L',
  },
  {
    value: 'LOGP.L',
    label: 'LOGP.L,  Lansdowne Oil & Gas plc',
    name: 'Lansdowne Oil & Gas plc',
    symbol: 'LOGP.L',
  },
  {
    value: 'LOK.L',
    label: "LOK.L,  Lok'nStore Group Plc",
    name: "Lok'nStore Group Plc",
    symbol: 'LOK.L',
  },
  {
    value: 'LOOK.L',
    label: 'LOOK.L,  Lookers plc',
    name: 'Lookers plc',
    symbol: 'LOOK.L',
  },
  {
    value: 'LOOP.L',
    label: 'LOOP.L,  LoopUp Group plc',
    name: 'LoopUp Group plc',
    symbol: 'LOOP.L',
  },
  {
    value: 'LPA.L',
    label: 'LPA.L,  LPA Group Plc',
    name: 'LPA Group Plc',
    symbol: 'LPA.L',
  },
  {
    value: 'LRE.L',
    label: 'LRE.L,  Lancashire Holdings Limited',
    name: 'Lancashire Holdings Limited',
    symbol: 'LRE.L',
  },
  {
    value: 'LSAI.L',
    label: 'LSAI.L,  Location Sciences Group PLC',
    name: 'Location Sciences Group PLC',
    symbol: 'LSAI.L',
  },
  {
    value: 'LSC.L',
    label: 'LSC.L,  London Security plc',
    name: 'London Security plc',
    symbol: 'LSC.L',
  },
  {
    value: 'LSL.L',
    label: 'LSL.L,  LSL Property Services plc',
    name: 'LSL Property Services plc',
    symbol: 'LSL.L',
  },
  {
    value: 'LTG.L',
    label: 'LTG.L,  Learning Technologies Group plc',
    name: 'Learning Technologies Group plc',
    symbol: 'LTG.L',
  },
  {
    value: 'LTHM.L',
    label: 'LTHM.L,  James Latham plc',
    name: 'James Latham plc',
    symbol: 'LTHM.L',
  },
  {
    value: 'LTI.L',
    label: 'LTI.L,  Lindsell Train Investment Trust Plc',
    name: 'Lindsell Train Investment Trust Plc',
    symbol: 'LTI.L',
  },
  {
    value: 'LUCE.L',
    label: 'LUCE.L,  Luceco plc',
    name: 'Luceco plc',
    symbol: 'LUCE.L',
  },
  {
    value: 'LVCG.L',
    label: 'LVCG.L,  Live Company Group Plc',
    name: 'Live Company Group Plc',
    symbol: 'LVCG.L',
  },
  {
    value: 'LWDB.L',
    label: 'LWDB.L,  The Law Debenture Corporation p.l.c.',
    name: 'The Law Debenture Corporation p.l.c.',
    symbol: 'LWDB.L',
  },
  {
    value: 'LWI.L',
    label: 'LWI.L,  Lowland Investment Company plc',
    name: 'Lowland Investment Company plc',
    symbol: 'LWI.L',
  },
  {
    value: 'LXI.L',
    label: 'LXI.L,  LXI REIT plc',
    name: 'LXI REIT plc',
    symbol: 'LXI.L',
  },
  {
    value: 'MAB.L',
    label: 'MAB.L,  Mitchells & Butlers plc',
    name: 'Mitchells & Butlers plc',
    symbol: 'MAB.L',
  },
  {
    value: 'MAB1.L',
    label: 'MAB1.L,  Mortgage Advice Bureau (Holdings) plc',
    name: 'Mortgage Advice Bureau (Holdings) plc',
    symbol: 'MAB1.L',
  },
  {
    value: 'MAC.L',
    label: 'MAC.L,  Marechale Capital Plc',
    name: 'Marechale Capital Plc',
    symbol: 'MAC.L',
  },
  {
    value: 'MACF.L',
    label: 'MACF.L,  Macfarlane Group PLC',
    name: 'Macfarlane Group PLC',
    symbol: 'MACF.L',
  },
  {
    value: 'MAFL.L',
    label: 'MAFL.L,  Mineral & Financial Investments Limited',
    name: 'Mineral & Financial Investments Limited',
    symbol: 'MAFL.L',
  },
  {
    value: 'MAI.L',
    label: 'MAI.L,  Maintel Holdings Plc',
    name: 'Maintel Holdings Plc',
    symbol: 'MAI.L',
  },
  {
    value: 'MAJE.L',
    label: 'MAJE.L,  Majedie Investments PLC',
    name: 'Majedie Investments PLC',
    symbol: 'MAJE.L',
  },
  {
    value: 'MANO.L',
    label: 'MANO.L,  Manolete Partners Plc',
    name: 'Manolete Partners Plc',
    symbol: 'MANO.L',
  },
  {
    value: 'MARS.L',
    label: "MARS.L,  Marston's PLC",
    name: "Marston's PLC",
    symbol: 'MARS.L',
  },
  {
    value: 'MATD.L',
    label: 'MATD.L,  Petro Matad Limited',
    name: 'Petro Matad Limited',
    symbol: 'MATD.L',
  },
  {
    value: 'MATE.L',
    label: 'MATE.L,  JPMorgan Multi-Asset Growth & Income plc',
    name: 'JPMorgan Multi-Asset Growth & Income plc',
    symbol: 'MATE.L',
  },
  {
    value: 'MAV4.L',
    label: 'MAV4.L,  Maven Income and Growth VCT 4 PLC',
    name: 'Maven Income and Growth VCT 4 PLC',
    symbol: 'MAV4.L',
  },
  {
    value: 'MBH.L',
    label: 'MBH.L,  Michelmersh Brick Holdings plc',
    name: 'Michelmersh Brick Holdings plc',
    symbol: 'MBH.L',
  },
  {
    value: 'MBO.L',
    label: 'MBO.L,  MobilityOne Limited',
    name: 'MobilityOne Limited',
    symbol: 'MBO.L',
  },
  {
    value: 'MBT.L',
    label: 'MBT.L,  Mobile Tornado Group plc',
    name: 'Mobile Tornado Group plc',
    symbol: 'MBT.L',
  },
  {
    value: 'MCB.L',
    label: 'MCB.L,  McBride plc',
    name: 'McBride plc',
    symbol: 'MCB.L',
  },
  {
    value: 'MCL.L',
    label: 'MCL.L,  Morses Club PLC',
    name: 'Morses Club PLC',
    symbol: 'MCL.L',
  },
  {
    value: 'MCLS.L',
    label: "MCLS.L,  McColl's Retail Group plc",
    name: "McColl's Retail Group plc",
    symbol: 'MCLS.L',
  },
  {
    value: 'MCM.L',
    label: 'MCM.L,  MC Mining Limited',
    name: 'MC Mining Limited',
    symbol: 'MCM.L',
  },
  {
    value: 'MCON.L',
    label: 'MCON.L,  Mincon Group plc',
    name: 'Mincon Group plc',
    symbol: 'MCON.L',
  },
  {
    value: 'MCRO.L',
    label: 'MCRO.L,  Micro Focus International plc',
    name: 'Micro Focus International plc',
    symbol: 'MCRO.L',
  },
  {
    value: 'MCT.L',
    label:
      'MCT.L,  Middlefield Canadian Income PCC - Middlefield Canadian Income - GBP PC',
    name: 'Middlefield Canadian Income PCC - Middlefield Canadian Income - GBP PC',
    symbol: 'MCT.L',
  },
  {
    value: 'MDC.L',
    label: 'MDC.L,  Mediclinic International plc',
    name: 'Mediclinic International plc',
    symbol: 'MDC.L',
  },
  {
    value: 'MDZ.L',
    label: 'MDZ.L,  MediaZest plc',
    name: 'MediaZest plc',
    symbol: 'MDZ.L',
  },
  {
    value: 'MER.L',
    label: 'MER.L,  Mears Group plc',
    name: 'Mears Group plc',
    symbol: 'MER.L',
  },
  {
    value: 'MERC.L',
    label: 'MERC.L,  Mercia Asset Management PLC',
    name: 'Mercia Asset Management PLC',
    symbol: 'MERC.L',
  },
  {
    value: 'MFX.L',
    label: 'MFX.L,  Manx Financial Group PLC',
    name: 'Manx Financial Group PLC',
    symbol: 'MFX.L',
  },
  {
    value: 'MGAM.L',
    label: 'MGAM.L,  Morgan Advanced Materials plc',
    name: 'Morgan Advanced Materials plc',
    symbol: 'MGAM.L',
  },
  {
    value: 'MGCI.L',
    label: 'MGCI.L,  M&G Credit Income Investment Trust plc',
    name: 'M&G Credit Income Investment Trust plc',
    symbol: 'MGCI.L',
  },
  {
    value: 'MGNS.L',
    label: 'MGNS.L,  Morgan Sindall Group plc',
    name: 'Morgan Sindall Group plc',
    symbol: 'MGNS.L',
  },
  {
    value: 'MGP.L',
    label: 'MGP.L,  Medica Group Plc',
    name: 'Medica Group Plc',
    symbol: 'MGP.L',
  },
  {
    value: 'MHM.L',
    label: 'MHM.L,  Marsh & McLennan Companies, Inc.',
    name: 'Marsh & McLennan Companies, Inc.',
    symbol: 'MHM.L',
  },
  {
    value: 'MHN.L',
    label: 'MHN.L,  Menhaden Resource Efficiency PLC',
    name: 'Menhaden Resource Efficiency PLC',
    symbol: 'MHN.L',
  },
  {
    value: 'MIDW.L',
    label: 'MIDW.L,  Midwich Group plc',
    name: 'Midwich Group plc',
    symbol: 'MIDW.L',
  },
  {
    value: 'MIG.L',
    label: 'MIG.L,  Mobeus Income & Growth 2 VCT Plc',
    name: 'Mobeus Income & Growth 2 VCT Plc',
    symbol: 'MIG.L',
  },
  {
    value: 'MIG1.L',
    label: 'MIG1.L,  Maven Income and Growth VCT PLC',
    name: 'Maven Income and Growth VCT PLC',
    symbol: 'MIG1.L',
  },
  {
    value: 'MIG3.L',
    label: 'MIG3.L,  Maven Income and Growth VCT 3 PLC',
    name: 'Maven Income and Growth VCT 3 PLC',
    symbol: 'MIG3.L',
  },
  {
    value: 'MIG4.L',
    label: 'MIG4.L,  Mobeus Income & Growth 4 VCT plc',
    name: 'Mobeus Income & Growth 4 VCT plc',
    symbol: 'MIG4.L',
  },
  {
    value: 'MIG5.L',
    label: 'MIG5.L,  Maven Income and Growth VCT 5 PLC',
    name: 'Maven Income and Growth VCT 5 PLC',
    symbol: 'MIG5.L',
  },
  {
    value: 'MIGO.L',
    label: 'MIGO.L,  MIGO Opportunities Trust plc',
    name: 'MIGO Opportunities Trust plc',
    symbol: 'MIGO.L',
  },
  {
    value: 'MIL.L',
    label: 'MIL.L,  Myanmar Investments International Limited',
    name: 'Myanmar Investments International Limited',
    symbol: 'MIL.L',
  },
  {
    value: 'MIN.L',
    label: 'MIN.L,  Minoan Group Plc',
    name: 'Minoan Group Plc',
    symbol: 'MIN.L',
  },
  {
    value: 'MIND.L',
    label: 'MIND.L,  Mind Gym plc',
    name: 'Mind Gym plc',
    symbol: 'MIND.L',
  },
  {
    value: 'MINI.L',
    label: 'MINI.L,  Miton UK MicroCap Trust plc',
    name: 'Miton UK MicroCap Trust plc',
    symbol: 'MINI.L',
  },
  {
    value: 'MIRA.L',
    label: 'MIRA.L,  Mirada Plc',
    name: 'Mirada Plc',
    symbol: 'MIRA.L',
  },
  {
    value: 'MIRI.L',
    label: 'MIRI.L,  Mirriad Advertising plc',
    name: 'Mirriad Advertising plc',
    symbol: 'MIRI.L',
  },
  {
    value: 'MIX.L',
    label: 'MIX.L,  Mobeus Income & Growth VCT Plc',
    name: 'Mobeus Income & Growth VCT Plc',
    symbol: 'MIX.L',
  },
  {
    value: 'MJH.L',
    label: 'MJH.L,  MJ Hudson Group plc',
    name: 'MJ Hudson Group plc',
    symbol: 'MJH.L',
  },
  {
    value: 'MKA.L',
    label: 'MKA.L,  Mkango Resources Ltd.',
    name: 'Mkango Resources Ltd.',
    symbol: 'MKA.L',
  },
  {
    value: 'MKS.L',
    label: 'MKS.L,  Marks and Spencer Group plc',
    name: 'Marks and Spencer Group plc',
    symbol: 'MKS.L',
  },
  {
    value: 'MLVN.L',
    label: 'MLVN.L,  Malvern International Plc',
    name: 'Malvern International Plc',
    symbol: 'MLVN.L',
  },
  {
    value: 'MMIT.L',
    label: 'MMIT.L,  Mobius Investment Trust plc',
    name: 'Mobius Investment Trust plc',
    symbol: 'MMIT.L',
  },
  {
    value: 'MNDI.L',
    label: 'MNDI.L,  Mondi plc',
    name: 'Mondi plc',
    symbol: 'MNDI.L',
  },
  {
    value: 'MNG.L',
    label: 'MNG.L,  M&G plc',
    name: 'M&G plc',
    symbol: 'MNG.L',
  },
  {
    value: 'MNKS.L',
    label: 'MNKS.L,  The Monks Investment Trust PLC',
    name: 'The Monks Investment Trust PLC',
    symbol: 'MNKS.L',
  },
  {
    value: 'MNL.L',
    label: 'MNL.L,  Manchester & London Investment Trust plc',
    name: 'Manchester & London Investment Trust plc',
    symbol: 'MNL.L',
  },
  {
    value: 'MNP.L',
    label: 'MNP.L,  Martin Currie Global Portfolio Trust plc',
    name: 'Martin Currie Global Portfolio Trust plc',
    symbol: 'MNP.L',
  },
  {
    value: 'MNRG.L',
    label: 'MNRG.L,  MetalNRG plc',
    name: 'MetalNRG plc',
    symbol: 'MNRG.L',
  },
  {
    value: 'MNTN.L',
    label: 'MNTN.L,  The Schiehallion Fund Limited',
    name: 'The Schiehallion Fund Limited',
    symbol: 'MNTN.L',
  },
  {
    value: 'MONY.L',
    label: 'MONY.L,  Moneysupermarket.com Group PLC',
    name: 'Moneysupermarket.com Group PLC',
    symbol: 'MONY.L',
  },
  {
    value: 'MOS.L',
    label: 'MOS.L,  Mobile Streams Plc',
    name: 'Mobile Streams Plc',
    symbol: 'MOS.L',
  },
  {
    value: 'MOTR.L',
    label: 'MOTR.L,  Motorpoint Group Plc',
    name: 'Motorpoint Group Plc',
    symbol: 'MOTR.L',
  },
  {
    value: 'MPAC.L',
    label: 'MPAC.L,  Mpac Group plc',
    name: 'Mpac Group plc',
    symbol: 'MPAC.L',
  },
  {
    value: 'MPE.L',
    label: 'MPE.L,  M.P. Evans Group PLC',
    name: 'M.P. Evans Group PLC',
    symbol: 'MPE.L',
  },
  {
    value: 'MPL.L',
    label: 'MPL.L,  Mercantile Ports & Logistics Limited',
    name: 'Mercantile Ports & Logistics Limited',
    symbol: 'MPL.L',
  },
  {
    value: 'MPLF.L',
    label: 'MPLF.L,  Marble Point Loan Financing Limited',
    name: 'Marble Point Loan Financing Limited',
    symbol: 'MPLF.L',
  },
  {
    value: 'MPO.L',
    label: 'MPO.L,  Macau Property Opportunities Fund Limited',
    name: 'Macau Property Opportunities Fund Limited',
    symbol: 'MPO.L',
  },
  {
    value: 'MRC.L',
    label: 'MRC.L,  The Mercantile Investment Trust plc',
    name: 'The Mercantile Investment Trust plc',
    symbol: 'MRC.L',
  },
  {
    value: 'MRCH.L',
    label: 'MRCH.L,  The Merchants Trust Plc',
    name: 'The Merchants Trust Plc',
    symbol: 'MRCH.L',
  },
  {
    value: 'MRL.L',
    label: 'MRL.L,  Marlowe plc',
    name: 'Marlowe plc',
    symbol: 'MRL.L',
  },
  {
    value: 'MRO.L',
    label: 'MRO.L,  Melrose Industries PLC',
    name: 'Melrose Industries PLC',
    symbol: 'MRO.L',
  },
  {
    value: 'MSI.L',
    label: 'MSI.L,  MS INTERNATIONAL plc',
    name: 'MS INTERNATIONAL plc',
    symbol: 'MSI.L',
  },
  {
    value: 'MSLH.L',
    label: 'MSLH.L,  Marshalls plc',
    name: 'Marshalls plc',
    symbol: 'MSLH.L',
  },
  {
    value: 'MSMN.L',
    label: 'MSMN.L,  Mosman Oil and Gas Limited',
    name: 'Mosman Oil and Gas Limited',
    symbol: 'MSMN.L',
  },
  {
    value: 'MSYS.L',
    label: 'MSYS.L,  Microsaic Systems plc',
    name: 'Microsaic Systems plc',
    symbol: 'MSYS.L',
  },
  {
    value: 'MTC.L',
    label: 'MTC.L,  Mothercare plc',
    name: 'Mothercare plc',
    symbol: 'MTC.L',
  },
  {
    value: 'MTE.L',
    label: 'MTE.L,  Montanaro European Smaller Companies Trust plc',
    name: 'Montanaro European Smaller Companies Trust plc',
    symbol: 'MTE.L',
  },
  {
    value: 'MTO.L',
    label: 'MTO.L,  Mitie Group plc',
    name: 'Mitie Group plc',
    symbol: 'MTO.L',
  },
  {
    value: 'MTPH.L',
    label: 'MTPH.L,  Midatech Pharma plc',
    name: 'Midatech Pharma plc',
    symbol: 'MTPH.L',
  },
  {
    value: 'MTR.L',
    label: 'MTR.L,  Metal Tiger plc',
    name: 'Metal Tiger plc',
    symbol: 'MTR.L',
  },
  {
    value: 'MTRO.L',
    label: 'MTRO.L,  Metro Bank PLC',
    name: 'Metro Bank PLC',
    symbol: 'MTRO.L',
  },
  {
    value: 'MTU.L',
    label: 'MTU.L,  Montanaro UK Smaller Companies Investment Trust PLC',
    name: 'Montanaro UK Smaller Companies Investment Trust PLC',
    symbol: 'MTU.L',
  },
  {
    value: 'MTVW.L',
    label: 'MTVW.L,  Mountview Estates P.L.C.',
    name: 'Mountview Estates P.L.C.',
    symbol: 'MTVW.L',
  },
  {
    value: 'MTW.L',
    label: 'MTW.L,  Mattioli Woods plc',
    name: 'Mattioli Woods plc',
    symbol: 'MTW.L',
  },
  {
    value: 'MUL.L',
    label: 'MUL.L,  Mulberry Group plc',
    name: 'Mulberry Group plc',
    symbol: 'MUL.L',
  },
  {
    value: 'MUT.L',
    label: 'MUT.L,  Murray Income Trust PLC',
    name: 'Murray Income Trust PLC',
    symbol: 'MUT.L',
  },
  {
    value: 'MWE.L',
    label: 'MWE.L,  M.T.I Wireless Edge Ltd.',
    name: 'M.T.I Wireless Edge Ltd.',
    symbol: 'MWE.L',
  },
  {
    value: 'MWY.L',
    label: 'MWY.L,  Mid Wynd International Investment Trust plc',
    name: 'Mid Wynd International Investment Trust plc',
    symbol: 'MWY.L',
  },
  {
    value: 'MXCT.L',
    label: 'MXCT.L,  MaxCyte, Inc.',
    name: 'MaxCyte, Inc.',
    symbol: 'MXCT.L',
  },
  {
    value: 'MYI.L',
    label: 'MYI.L,  Murray International Trust PLC',
    name: 'Murray International Trust PLC',
    symbol: 'MYI.L',
  },
  {
    value: 'MYSL.L',
    label: 'MYSL.L,  MySale Group plc',
    name: 'MySale Group plc',
    symbol: 'MYSL.L',
  },
  {
    value: 'MYX.L',
    label: 'MYX.L,  MYCELX Technologies Corporation',
    name: 'MYCELX Technologies Corporation',
    symbol: 'MYX.L',
  },
  {
    value: 'N4P.L',
    label: 'N4P.L,  N4 Pharma Plc',
    name: 'N4 Pharma Plc',
    symbol: 'N4P.L',
  },
  {
    value: 'N91.L',
    label: 'N91.L,  Ninety One Group',
    name: 'Ninety One Group',
    symbol: 'N91.L',
  },
  {
    value: 'NAH.L',
    label: 'NAH.L,  NAHL Group Plc',
    name: 'NAHL Group Plc',
    symbol: 'NAH.L',
  },
  {
    value: 'NAIT.L',
    label: 'NAIT.L,  The North American Income Trust plc',
    name: 'The North American Income Trust plc',
    symbol: 'NAIT.L',
  },
  {
    value: 'NANO.L',
    label: 'NANO.L,  Nanoco Group plc',
    name: 'Nanoco Group plc',
    symbol: 'NANO.L',
  },
  {
    value: 'NAR.L',
    label: 'NAR.L,  Northamber plc',
    name: 'Northamber plc',
    symbol: 'NAR.L',
  },
  {
    value: 'NAS.L',
    label: 'NAS.L,  North Atlantic Smaller Companies Investment Trust PLC',
    name: 'North Atlantic Smaller Companies Investment Trust PLC',
    symbol: 'NAS.L',
  },
  {
    value: 'NAVF.L',
    label: 'NAVF.L,  Nippon Active Value Fund plc',
    name: 'Nippon Active Value Fund plc',
    symbol: 'NAVF.L',
  },
  {
    value: 'NBB.L',
    label: 'NBB.L,  Norman Broadbent plc',
    name: 'Norman Broadbent plc',
    symbol: 'NBB.L',
  },
  {
    value: 'NBDD.L',
    label: 'NBDD.L,  NB Distressed Debt Investment Fund Limited',
    name: 'NB Distressed Debt Investment Fund Limited',
    symbol: 'NBDD.L',
  },
  {
    value: 'NBPE.L',
    label: 'NBPE.L,  NB Private Equity Partners Limited',
    name: 'NB Private Equity Partners Limited',
    symbol: 'NBPE.L',
  },
  {
    value: 'NBPU.L',
    label: 'NBPU.L,  NB Private Equity Partners Limited',
    name: 'NB Private Equity Partners Limited',
    symbol: 'NBPU.L',
  },
  {
    value: 'NCA.L',
    label: 'NCA.L,  New Century AIM VCT Plc',
    name: 'New Century AIM VCT Plc',
    symbol: 'NCA.L',
  },
  {
    value: 'NCA2.L',
    label: 'NCA2.L,  New Century AIM VCT 2 PLC',
    name: 'New Century AIM VCT 2 PLC',
    symbol: 'NCA2.L',
  },
  {
    value: 'NCC.L',
    label: 'NCC.L,  NCC Group plc',
    name: 'NCC Group plc',
    symbol: 'NCC.L',
  },
  {
    value: 'NCCL.L',
    label: 'NCCL.L,  Ncondezi Energy Limited',
    name: 'Ncondezi Energy Limited',
    symbol: 'NCCL.L',
  },
  {
    value: 'NCYF.L',
    label: 'NCYF.L,  CQS New City High Yield Fund Limited',
    name: 'CQS New City High Yield Fund Limited',
    symbol: 'NCYF.L',
  },
  {
    value: 'NCYT.L',
    label: 'NCYT.L,  Novacyt S.A.',
    name: 'Novacyt S.A.',
    symbol: 'NCYT.L',
  },
  {
    value: 'NESF.L',
    label: 'NESF.L,  NextEnergy Solar Fund Limited',
    name: 'NextEnergy Solar Fund Limited',
    symbol: 'NESF.L',
  },
  {
    value: 'NET.L',
    label: 'NET.L,  Netcall plc',
    name: 'Netcall plc',
    symbol: 'NET.L',
  },
  {
    value: 'NETW.L',
    label: 'NETW.L,  Network International Holdings plc',
    name: 'Network International Holdings plc',
    symbol: 'NETW.L',
  },
  {
    value: 'NEX.L',
    label: 'NEX.L,  National Express Group PLC',
    name: 'National Express Group PLC',
    symbol: 'NEX.L',
  },
  {
    value: 'NEXS.L',
    label: 'NEXS.L,  Nexus Infrastructure plc',
    name: 'Nexus Infrastructure plc',
    symbol: 'NEXS.L',
  },
  {
    value: 'NFC.L',
    label: 'NFC.L,  Next Fifteen Communications Group plc',
    name: 'Next Fifteen Communications Group plc',
    symbol: 'NFC.L',
  },
  {
    value: 'NFX.L',
    label: 'NFX.L,  Nuformix plc',
    name: 'Nuformix plc',
    symbol: 'NFX.L',
  },
  {
    value: 'NICL.L',
    label: 'NICL.L,  Nichols plc',
    name: 'Nichols plc',
    symbol: 'NICL.L',
  },
  {
    value: 'NOG.L',
    label: 'NOG.L,  Nostrum Oil & Gas PLC',
    name: 'Nostrum Oil & Gas PLC',
    symbol: 'NOG.L',
  },
  {
    value: 'NRR.L',
    label: 'NRR.L,  NewRiver REIT plc',
    name: 'NewRiver REIT plc',
    symbol: 'NRR.L',
  },
  {
    value: 'NSCI.L',
    label: 'NSCI.L,  NetScientific plc',
    name: 'NetScientific plc',
    symbol: 'NSCI.L',
  },
  {
    value: 'NSF.L',
    label: 'NSF.L,  Non-Standard Finance plc',
    name: 'Non-Standard Finance plc',
    symbol: 'NSF.L',
  },
  {
    value: 'NSI.L',
    label: 'NSI.L,  New Star Investment Trust plc',
    name: 'New Star Investment Trust plc',
    symbol: 'NSI.L',
  },
  {
    value: 'NTBR.L',
    label: 'NTBR.L,  Northern Bear PLC',
    name: 'Northern Bear PLC',
    symbol: 'NTBR.L',
  },
  {
    value: 'NTN.L',
    label: 'NTN.L,  Northern 3 VCT PLC',
    name: 'Northern 3 VCT PLC',
    symbol: 'NTN.L',
  },
  {
    value: 'NTOG.L',
    label: 'NTOG.L,  Nostra Terra Oil and Gas Company plc',
    name: 'Nostra Terra Oil and Gas Company plc',
    symbol: 'NTOG.L',
  },
  {
    value: 'NTQ.L',
    label: 'NTQ.L,  Enteq Technologies Plc',
    name: 'Enteq Technologies Plc',
    symbol: 'NTQ.L',
  },
  {
    value: 'NTV.L',
    label: 'NTV.L,  Northern 2 VCT PLC',
    name: 'Northern 2 VCT PLC',
    symbol: 'NTV.L',
  },
  {
    value: 'NUM.L',
    label: 'NUM.L,  Numis Corporation Plc',
    name: 'Numis Corporation Plc',
    symbol: 'NUM.L',
  },
  {
    value: 'NVT.L',
    label: 'NVT.L,  Northern Venture Trust PLC',
    name: 'Northern Venture Trust PLC',
    symbol: 'NVT.L',
  },
  {
    value: 'NWF.L',
    label: 'NWF.L,  NWF Group plc',
    name: 'NWF Group plc',
    symbol: 'NWF.L',
  },
  {
    value: 'NWT.L',
    label: 'NWT.L,  Newmark Security plc',
    name: 'Newmark Security plc',
    symbol: 'NWT.L',
  },
  {
    value: 'NXR.L',
    label: 'NXR.L,  Norcros plc',
    name: 'Norcros plc',
    symbol: 'NXR.L',
  },
  {
    value: 'NXT.L',
    label: 'NXT.L,  NEXT plc',
    name: 'NEXT plc',
    symbol: 'NXT.L',
  },
  {
    value: 'OAP3.L',
    label: 'OAP3.L,  Octopus Apollo VCT plc',
    name: 'Octopus Apollo VCT plc',
    symbol: 'OAP3.L',
  },
  {
    value: 'OBC.L',
    label: 'OBC.L,  Online Blockchain Plc',
    name: 'Online Blockchain Plc',
    symbol: 'OBC.L',
  },
  {
    value: 'OBD.L',
    label: 'OBD.L,  Oxford BioDynamics Plc',
    name: 'Oxford BioDynamics Plc',
    symbol: 'OBD.L',
  },
  {
    value: 'OCDO.L',
    label: 'OCDO.L,  Ocado Group plc',
    name: 'Ocado Group plc',
    symbol: 'OCDO.L',
  },
  {
    value: 'OCI.L',
    label: 'OCI.L,  Oakley Capital Investments Limited',
    name: 'Oakley Capital Investments Limited',
    symbol: 'OCI.L',
  },
  {
    value: 'OCN.L',
    label: 'OCN.L,  Ocean Wilsons Holdings Limited',
    name: 'Ocean Wilsons Holdings Limited',
    symbol: 'OCN.L',
  },
  {
    value: 'ODX.L',
    label: 'ODX.L,  Omega Diagnostics Group PLC',
    name: 'Omega Diagnostics Group PLC',
    symbol: 'ODX.L',
  },
  {
    value: 'OGN.L',
    label: 'OGN.L,  Origin Enterprises plc',
    name: 'Origin Enterprises plc',
    symbol: 'OGN.L',
  },
  {
    value: 'OIG.L',
    label: 'OIG.L,  Oryx International Growth Fund Limited',
    name: 'Oryx International Growth Fund Limited',
    symbol: 'OIG.L',
  },
  {
    value: 'OIT.L',
    label: 'OIT.L,  Odyssean Investment Trust PLC',
    name: 'Odyssean Investment Trust PLC',
    symbol: 'OIT.L',
  },
  {
    value: 'OKYO.L',
    label: 'OKYO.L,  OKYO Pharma Limited',
    name: 'OKYO Pharma Limited',
    symbol: 'OKYO.L',
  },
  {
    value: 'OMG.L',
    label: 'OMG.L,  Oxford Metrics plc',
    name: 'Oxford Metrics plc',
    symbol: 'OMG.L',
  },
  {
    value: 'OMI.L',
    label: 'OMI.L,  Orosur Mining Inc.',
    name: 'Orosur Mining Inc.',
    symbol: 'OMI.L',
  },
  {
    value: 'OMIP.L',
    label: 'OMIP.L,  One Media iP Group Plc',
    name: 'One Media iP Group Plc',
    symbol: 'OMIP.L',
  },
  {
    value: 'OMU.L',
    label: 'OMU.L,  Old Mutual Limited',
    name: 'Old Mutual Limited',
    symbol: 'OMU.L',
  },
  {
    value: 'ONC.L',
    label: 'ONC.L,  Oncimmune Holdings plc',
    name: 'Oncimmune Holdings plc',
    symbol: 'ONC.L',
  },
  {
    value: 'OOA.L',
    label: 'OOA.L,  Octopus AIM VCT PLC',
    name: 'Octopus AIM VCT PLC',
    symbol: 'OOA.L',
  },
  {
    value: 'OPG.L',
    label: 'OPG.L,  OPG Power Ventures Plc',
    name: 'OPG Power Ventures Plc',
    symbol: 'OPG.L',
  },
  {
    value: 'OPTI.L',
    label: 'OPTI.L,  OptiBiotix Health Plc',
    name: 'OptiBiotix Health Plc',
    symbol: 'OPTI.L',
  },
  {
    value: 'ORCH.L',
    label: 'ORCH.L,  Orchard Funding Group plc',
    name: 'Orchard Funding Group plc',
    symbol: 'ORCH.L',
  },
  {
    value: 'ORCP.L',
    label: 'ORCP.L,  Oracle Power plc',
    name: 'Oracle Power plc',
    symbol: 'ORCP.L',
  },
  {
    value: 'ORIT.L',
    label: 'ORIT.L,  Octopus Renewables Infrastructure Trust plc',
    name: 'Octopus Renewables Infrastructure Trust plc',
    symbol: 'ORIT.L',
  },
  {
    value: 'ORM.L',
    label: 'ORM.L,  Ormonde Mining plc',
    name: 'Ormonde Mining plc',
    symbol: 'ORM.L',
  },
  {
    value: 'ORR.L',
    label: 'ORR.L,  Oriole Resources PLC',
    name: 'Oriole Resources PLC',
    symbol: 'ORR.L',
  },
  {
    value: 'OSB.L',
    label: 'OSB.L,  OSB Group Plc',
    name: 'OSB Group Plc',
    symbol: 'OSB.L',
  },
  {
    value: 'OSEC.L',
    label: 'OSEC.L,  Octopus AIM VCT 2 plc',
    name: 'Octopus AIM VCT 2 plc',
    symbol: 'OSEC.L',
  },
  {
    value: 'OSI.L',
    label: 'OSI.L,  Osirium Technologies PLC',
    name: 'Osirium Technologies PLC',
    symbol: 'OSI.L',
  },
  {
    value: 'OTAQ.L',
    label: 'OTAQ.L,  OTAQ plc',
    name: 'OTAQ plc',
    symbol: 'OTAQ.L',
  },
  {
    value: 'OTB.L',
    label: 'OTB.L,  On the Beach Group plc',
    name: 'On the Beach Group plc',
    symbol: 'OTB.L',
  },
  {
    value: 'OTMP.L',
    label: 'OTMP.L,  OnTheMarket plc',
    name: 'OnTheMarket plc',
    symbol: 'OTMP.L',
  },
  {
    value: 'OTV2.L',
    label: 'OTV2.L,  Octopus Titan VCT plc',
    name: 'Octopus Titan VCT plc',
    symbol: 'OTV2.L',
  },
  {
    value: 'OVB.L',
    label: 'OVB.L,  Ovoca Bio plc',
    name: 'Ovoca Bio plc',
    symbol: 'OVB.L',
  },
  {
    value: 'OXB.L',
    label: 'OXB.L,  Oxford Biomedica plc',
    name: 'Oxford Biomedica plc',
    symbol: 'OXB.L',
  },
  {
    value: 'OXIG.L',
    label: 'OXIG.L,  Oxford Instruments plc',
    name: 'Oxford Instruments plc',
    symbol: 'OXIG.L',
  },
  {
    value: 'PAC.L',
    label: 'PAC.L,  Pacific Assets Trust plc',
    name: 'Pacific Assets Trust plc',
    symbol: 'PAC.L',
  },
  {
    value: 'PAF.L',
    label: 'PAF.L,  Pan African Resources PLC',
    name: 'Pan African Resources PLC',
    symbol: 'PAF.L',
  },
  {
    value: 'PAG.L',
    label: 'PAG.L,  Paragon Banking Group PLC',
    name: 'Paragon Banking Group PLC',
    symbol: 'PAG.L',
  },
  {
    value: 'PAGE.L',
    label: 'PAGE.L,  PageGroup plc',
    name: 'PageGroup plc',
    symbol: 'PAGE.L',
  },
  {
    value: 'PALM.L',
    label: 'PALM.L,  Panther Metals PLC',
    name: 'Panther Metals PLC',
    symbol: 'PALM.L',
  },
  {
    value: 'PANR.L',
    label: 'PANR.L,  Pantheon Resources Plc',
    name: 'Pantheon Resources Plc',
    symbol: 'PANR.L',
  },
  {
    value: 'PAT.L',
    label: 'PAT.L,  Panthera Resources PLC',
    name: 'Panthera Resources PLC',
    symbol: 'PAT.L',
  },
  {
    value: 'PAY.L',
    label: 'PAY.L,  PayPoint plc',
    name: 'PayPoint plc',
    symbol: 'PAY.L',
  },
  {
    value: 'PCA.L',
    label: 'PCA.L,  Palace Capital Plc',
    name: 'Palace Capital Plc',
    symbol: 'PCA.L',
  },
  {
    value: 'PCF.L',
    label: 'PCF.L,  PCF Group plc',
    name: 'PCF Group plc',
    symbol: 'PCF.L',
  },
  {
    value: 'PCFT.L',
    label: 'PCFT.L,  Polar Capital Global Financials Trust plc',
    name: 'Polar Capital Global Financials Trust plc',
    symbol: 'PCFT.L',
  },
  {
    value: 'PCGH.L',
    label: 'PCGH.L,  Polar Capital Global Healthcare Trust plc',
    name: 'Polar Capital Global Healthcare Trust plc',
    symbol: 'PCGH.L',
  },
  {
    value: 'PCIP.L',
    label: 'PCIP.L,  PCI-PAL PLC',
    name: 'PCI-PAL PLC',
    symbol: 'PCIP.L',
  },
  {
    value: 'PCT.L',
    label: 'PCT.L,  Polar Capital Technology Trust plc',
    name: 'Polar Capital Technology Trust plc',
    symbol: 'PCT.L',
  },
  {
    value: 'PCTN.L',
    label: 'PCTN.L,  Picton Property Income Limited',
    name: 'Picton Property Income Limited',
    symbol: 'PCTN.L',
  },
  {
    value: 'PDG.L',
    label: 'PDG.L,  Pendragon PLC',
    name: 'Pendragon PLC',
    symbol: 'PDG.L',
  },
  {
    value: 'PDL.L',
    label: 'PDL.L,  Petra Diamonds Limited',
    name: 'Petra Diamonds Limited',
    symbol: 'PDL.L',
  },
  {
    value: 'PEB.L',
    label: 'PEB.L,  Pebble Beach Systems Group plc',
    name: 'Pebble Beach Systems Group plc',
    symbol: 'PEB.L',
  },
  {
    value: 'PEBB.L',
    label: 'PEBB.L,  The Pebble Group plc',
    name: 'The Pebble Group plc',
    symbol: 'PEBB.L',
  },
  {
    value: 'PEG.L',
    label: 'PEG.L,  Petards Group plc',
    name: 'Petards Group plc',
    symbol: 'PEG.L',
  },
  {
    value: 'PEN.L',
    label: 'PEN.L,  Pennant International Group plc',
    name: 'Pennant International Group plc',
    symbol: 'PEN.L',
  },
  {
    value: 'PERE.L',
    label: 'PERE.L,  Pembridge Resources plc',
    name: 'Pembridge Resources plc',
    symbol: 'PERE.L',
  },
  {
    value: 'PET.L',
    label: 'PET.L,  Petrel Resources Plc',
    name: 'Petrel Resources Plc',
    symbol: 'PET.L',
  },
  {
    value: 'PETS.L',
    label: 'PETS.L,  Pets at Home Group Plc',
    name: 'Pets at Home Group Plc',
    symbol: 'PETS.L',
  },
  {
    value: 'PEY.L',
    label: 'PEY.L,  Princess Private Equity Holding Limited',
    name: 'Princess Private Equity Holding Limited',
    symbol: 'PEY.L',
  },
  {
    value: 'PEYS.L',
    label: 'PEYS.L,  Princess Private Equity Holding Limited',
    name: 'Princess Private Equity Holding Limited',
    symbol: 'PEYS.L',
  },
  {
    value: 'PFC.L',
    label: 'PFC.L,  Petrofac Limited',
    name: 'Petrofac Limited',
    symbol: 'PFC.L',
  },
  {
    value: 'PFD.L',
    label: 'PFD.L,  Premier Foods plc',
    name: 'Premier Foods plc',
    symbol: 'PFD.L',
  },
  {
    value: 'PFG.L',
    label: 'PFG.L,  Provident Financial plc',
    name: 'Provident Financial plc',
    symbol: 'PFG.L',
  },
  {
    value: 'PFP.L',
    label: 'PFP.L,  Pathfinder Minerals plc',
    name: 'Pathfinder Minerals plc',
    symbol: 'PFP.L',
  },
  {
    value: 'PGH.L',
    label: 'PGH.L,  Personal Group Holdings Plc',
    name: 'Personal Group Holdings Plc',
    symbol: 'PGH.L',
  },
  {
    value: 'PGHZ.L',
    label: 'PGHZ.L,  Pcgh Zdp Plc',
    name: 'Pcgh Zdp Plc',
    symbol: 'PGHZ.L',
  },
  {
    value: 'PGOO.L',
    label: 'PGOO.L,  ProVen Growth & Income VCT PLC',
    name: 'ProVen Growth & Income VCT PLC',
    symbol: 'PGOO.L',
  },
  {
    value: 'PHAR.L',
    label: 'PHAR.L,  Pharos Energy plc',
    name: 'Pharos Energy plc',
    symbol: 'PHAR.L',
  },
  {
    value: 'PHC.L',
    label: 'PHC.L,  Plant Health Care plc',
    name: 'Plant Health Care plc',
    symbol: 'PHC.L',
  },
  {
    value: 'PHE.L',
    label: 'PHE.L,  PowerHouse Energy Group Plc',
    name: 'PowerHouse Energy Group Plc',
    symbol: 'PHE.L',
  },
  {
    value: 'PHI.L',
    label: 'PHI.L,  Pacific Horizon Investment Trust PLC',
    name: 'Pacific Horizon Investment Trust PLC',
    symbol: 'PHI.L',
  },
  {
    value: 'PHNX.L',
    label: 'PHNX.L,  Phoenix Group Holdings plc',
    name: 'Phoenix Group Holdings plc',
    symbol: 'PHNX.L',
  },
  {
    value: 'PHP.L',
    label: 'PHP.L,  Primary Health Properties PLC',
    name: 'Primary Health Properties PLC',
    symbol: 'PHP.L',
  },
  {
    value: 'PHSC.L',
    label: 'PHSC.L,  PHSC plc',
    name: 'PHSC plc',
    symbol: 'PHSC.L',
  },
  {
    value: 'PIER.L',
    label: 'PIER.L,  The Brighton Pier Group PLC',
    name: 'The Brighton Pier Group PLC',
    symbol: 'PIER.L',
  },
  {
    value: 'PIN.L',
    label: 'PIN.L,  Pantheon International PLC',
    name: 'Pantheon International PLC',
    symbol: 'PIN.L',
  },
  {
    value: 'PIP.L',
    label: 'PIP.L,  PipeHawk plc',
    name: 'PipeHawk plc',
    symbol: 'PIP.L',
  },
  {
    value: 'PIRI.L',
    label: 'PIRI.L,  Pires Investments plc',
    name: 'Pires Investments plc',
    symbol: 'PIRI.L',
  },
  {
    value: 'PLUS.L',
    label: 'PLUS.L,  Plus500 Ltd.',
    name: 'Plus500 Ltd.',
    symbol: 'PLUS.L',
  },
  {
    value: 'PMG.L',
    label: 'PMG.L,  The Parkmead Group plc',
    name: 'The Parkmead Group plc',
    symbol: 'PMG.L',
  },
  {
    value: 'PMI.L',
    label: 'PMI.L,  Premier Miton Group plc',
    name: 'Premier Miton Group plc',
    symbol: 'PMI.L',
  },
  {
    value: 'PMP.L',
    label: 'PMP.L,  Portmeirion Group PLC',
    name: 'Portmeirion Group PLC',
    symbol: 'PMP.L',
  },
  {
    value: 'PNL.L',
    label: 'PNL.L,  Personal Assets Trust plc',
    name: 'Personal Assets Trust plc',
    symbol: 'PNL.L',
  },
  {
    value: 'PNN.L',
    label: 'PNN.L,  Pennon Group Plc',
    name: 'Pennon Group Plc',
    symbol: 'PNN.L',
  },
  {
    value: 'PNS.L',
    label: 'PNS.L,  Panther Securities PLC',
    name: 'Panther Securities PLC',
    symbol: 'PNS.L',
  },
  {
    value: 'POLR.L',
    label: 'POLR.L,  Polar Capital Holdings plc',
    name: 'Polar Capital Holdings plc',
    symbol: 'POLR.L',
  },
  {
    value: 'POLX.L',
    label: 'POLX.L,  Polarean Imaging plc',
    name: 'Polarean Imaging plc',
    symbol: 'POLX.L',
  },
  {
    value: 'POLY.L',
    label: 'POLY.L,  Polymetal International plc',
    name: 'Polymetal International plc',
    symbol: 'POLY.L',
  },
  {
    value: 'POS.L',
    label: 'POS.L,  Plexus Holdings plc',
    name: 'Plexus Holdings plc',
    symbol: 'POS.L',
  },
  {
    value: 'POW.L',
    label: 'POW.L,  Power Metal Resources plc',
    name: 'Power Metal Resources plc',
    symbol: 'POW.L',
  },
  {
    value: 'PPH.L',
    label: 'PPH.L,  PPHE Hotel Group Limited',
    name: 'PPHE Hotel Group Limited',
    symbol: 'PPH.L',
  },
  {
    value: 'PPP.L',
    label: 'PPP.L,  Pennpetro Energy Plc',
    name: 'Pennpetro Energy Plc',
    symbol: 'PPP.L',
  },
  {
    value: 'PPS.L',
    label: 'PPS.L,  Proton Motor Power Systems Plc',
    name: 'Proton Motor Power Systems Plc',
    symbol: 'PPS.L',
  },
  {
    value: 'PRD.L',
    label: 'PRD.L,  Predator Oil & Gas Holdings Plc',
    name: 'Predator Oil & Gas Holdings Plc',
    symbol: 'PRD.L',
  },
  {
    value: 'PREM.L',
    label: 'PREM.L,  Premier African Minerals Limited',
    name: 'Premier African Minerals Limited',
    symbol: 'PREM.L',
  },
  {
    value: 'PRES.L',
    label: 'PRES.L,  Pressure Technologies plc',
    name: 'Pressure Technologies plc',
    symbol: 'PRES.L',
  },
  {
    value: 'PRIM.L',
    label: 'PRIM.L,  Primorus Investments plc',
    name: 'Primorus Investments plc',
    symbol: 'PRIM.L',
  },
  {
    value: 'PRM.L',
    label: 'PRM.L,  Proteome Sciences plc',
    name: 'Proteome Sciences plc',
    symbol: 'PRM.L',
  },
  {
    value: 'PRSR.L',
    label: 'PRSR.L,  The PRS REIT plc',
    name: 'The PRS REIT plc',
    symbol: 'PRSR.L',
  },
  {
    value: 'PRTC.L',
    label: 'PRTC.L,  PureTech Health plc',
    name: 'PureTech Health plc',
    symbol: 'PRTC.L',
  },
  {
    value: 'PRU.L',
    label: 'PRU.L,  Prudential plc',
    name: 'Prudential plc',
    symbol: 'PRU.L',
  },
  {
    value: 'PRV.L',
    label: 'PRV.L,  Porvair plc',
    name: 'Porvair plc',
    symbol: 'PRV.L',
  },
  {
    value: 'PSDL.L',
    label: 'PSDL.L,  Phoenix Spree Deutschland Limited',
    name: 'Phoenix Spree Deutschland Limited',
    symbol: 'PSDL.L',
  },
  {
    value: 'PSH.L',
    label: 'PSH.L,  Pershing Square Holdings, Ltd.',
    name: 'Pershing Square Holdings, Ltd.',
    symbol: 'PSH.L',
  },
  {
    value: 'PSHD.L',
    label: 'PSHD.L,  Pershing Square Holdings, Ltd.',
    name: 'Pershing Square Holdings, Ltd.',
    symbol: 'PSHD.L',
  },
  {
    value: 'PSN.L',
    label: 'PSN.L,  Persimmon Plc',
    name: 'Persimmon Plc',
    symbol: 'PSN.L',
  },
  {
    value: 'PSON.L',
    label: 'PSON.L,  Pearson plc',
    name: 'Pearson plc',
    symbol: 'PSON.L',
  },
  {
    value: 'PTAL.L',
    label: 'PTAL.L,  PetroTal Corp.',
    name: 'PetroTal Corp.',
    symbol: 'PTAL.L',
  },
  {
    value: 'PTD.L',
    label: 'PTD.L,  Pittards plc',
    name: 'Pittards plc',
    symbol: 'PTD.L',
  },
  {
    value: 'PTEC.L',
    label: 'PTEC.L,  Playtech plc',
    name: 'Playtech plc',
    symbol: 'PTEC.L',
  },
  {
    value: 'PTR.L',
    label: 'PTR.L,  PetroNeft Resources plc',
    name: 'PetroNeft Resources plc',
    symbol: 'PTR.L',
  },
  {
    value: 'PTRO.L',
    label: 'PTRO.L,  Pelatro Plc',
    name: 'Pelatro Plc',
    symbol: 'PTRO.L',
  },
  {
    value: 'PTY.L',
    label: 'PTY.L,  Parity Group plc',
    name: 'Parity Group plc',
    symbol: 'PTY.L',
  },
  {
    value: 'PUR.L',
    label: 'PUR.L,  Pure Gold Mining Inc.',
    name: 'Pure Gold Mining Inc.',
    symbol: 'PUR.L',
  },
  {
    value: 'PURP.L',
    label: 'PURP.L,  Purplebricks Group plc',
    name: 'Purplebricks Group plc',
    symbol: 'PURP.L',
  },
  {
    value: 'PVN.L',
    label: 'PVN.L,  ProVen VCT plc',
    name: 'ProVen VCT plc',
    symbol: 'PVN.L',
  },
  {
    value: 'PXC.L',
    label: 'PXC.L,  Phoenix Copper Limited',
    name: 'Phoenix Copper Limited',
    symbol: 'PXC.L',
  },
  {
    value: 'PXS.L',
    label: 'PXS.L,  Provexis plc',
    name: 'Provexis plc',
    symbol: 'PXS.L',
  },
  {
    value: 'PYC.L',
    label: 'PYC.L,  Physiomics Plc',
    name: 'Physiomics Plc',
    symbol: 'PYC.L',
  },
  {
    value: 'PZC.L',
    label: 'PZC.L,  PZ Cussons plc',
    name: 'PZ Cussons plc',
    symbol: 'PZC.L',
  },
  {
    value: 'QFI.L',
    label: 'QFI.L,  Quadrise Fuels International plc',
    name: 'Quadrise Fuels International plc',
    symbol: 'QFI.L',
  },
  {
    value: 'QLT.L',
    label: 'QLT.L,  Quilter plc',
    name: 'Quilter plc',
    symbol: 'QLT.L',
  },
  {
    value: 'QRT.L',
    label: 'QRT.L,  The Quarto Group, Inc.',
    name: 'The Quarto Group, Inc.',
    symbol: 'QRT.L',
  },
  {
    value: 'QTX.L',
    label: 'QTX.L,  Quartix Technologies Plc',
    name: 'Quartix Technologies Plc',
    symbol: 'QTX.L',
  },
  {
    value: 'QUIZ.L',
    label: 'QUIZ.L,  QUIZ plc',
    name: 'QUIZ plc',
    symbol: 'QUIZ.L',
  },
  {
    value: 'QXT.L',
    label: 'QXT.L,  Quixant Plc',
    name: 'Quixant Plc',
    symbol: 'QXT.L',
  },
  {
    value: 'RAI.L',
    label: 'RAI.L,  RA International Group plc',
    name: 'RA International Group plc',
    symbol: 'RAI.L',
  },
  {
    value: 'RAT.L',
    label: 'RAT.L,  Rathbones Group Plc',
    name: 'Rathbones Group Plc',
    symbol: 'RAT.L',
  },
  {
    value: 'RBD.L',
    label: 'RBD.L,  Reabold Resources Plc',
    name: 'Reabold Resources Plc',
    symbol: 'RBD.L',
  },
  {
    value: 'RBG.L',
    label: 'RBG.L,  Revolution Bars Group plc',
    name: 'Revolution Bars Group plc',
    symbol: 'RBG.L',
  },
  {
    value: 'RBGP.L',
    label: 'RBGP.L,  RBG Holdings plc',
    name: 'RBG Holdings plc',
    symbol: 'RBGP.L',
  },
  {
    value: 'RBN.L',
    label: 'RBN.L,  Robinson plc',
    name: 'Robinson plc',
    symbol: 'RBN.L',
  },
  {
    value: 'RBW.L',
    label: 'RBW.L,  Rainbow Rare Earths Limited',
    name: 'Rainbow Rare Earths Limited',
    symbol: 'RBW.L',
  },
  {
    value: 'RCDO.L',
    label: 'RCDO.L,  Ricardo plc',
    name: 'Ricardo plc',
    symbol: 'RCDO.L',
  },
  {
    value: 'RCH.L',
    label: 'RCH.L,  Reach plc',
    name: 'Reach plc',
    symbol: 'RCH.L',
  },
  {
    value: 'RCN.L',
    label: 'RCN.L,  Redcentric plc',
    name: 'Redcentric plc',
    symbol: 'RCN.L',
  },
  {
    value: 'RCOI.L',
    label: 'RCOI.L,  Riverstone Credit Opportunities Income Plc',
    name: 'Riverstone Credit Opportunities Income Plc',
    symbol: 'RCOI.L',
  },
  {
    value: 'RCP.L',
    label: 'RCP.L,  RIT Capital Partners plc',
    name: 'RIT Capital Partners plc',
    symbol: 'RCP.L',
  },
  {
    value: 'RDT.L',
    label: 'RDT.L,  Rosslyn Data Technologies plc',
    name: 'Rosslyn Data Technologies plc',
    symbol: 'RDT.L',
  },
  {
    value: 'RDW.L',
    label: 'RDW.L,  Redrow plc',
    name: 'Redrow plc',
    symbol: 'RDW.L',
  },
  {
    value: 'REAT.L',
    label: 'REAT.L,  REACT Group PLC',
    name: 'REACT Group PLC',
    symbol: 'REAT.L',
  },
  {
    value: 'REC.L',
    label: 'REC.L,  Record plc',
    name: 'Record plc',
    symbol: 'REC.L',
  },
  {
    value: 'RECI.L',
    label: 'RECI.L,  Real Estate Credit Investments Limited',
    name: 'Real Estate Credit Investments Limited',
    symbol: 'RECI.L',
  },
  {
    value: 'REDD.L',
    label: 'REDD.L,  Redde Northgate plc',
    name: 'Redde Northgate plc',
    symbol: 'REDD.L',
  },
  {
    value: 'REDX.L',
    label: 'REDX.L,  Redx Pharma Plc',
    name: 'Redx Pharma Plc',
    symbol: 'REDX.L',
  },
  {
    value: 'REL.L',
    label: 'REL.L,  RELX PLC',
    name: 'RELX PLC',
    symbol: 'REL.L',
  },
  {
    value: 'RENE.L',
    label: 'RENE.L,  ReNeuron Group plc',
    name: 'ReNeuron Group plc',
    symbol: 'RENE.L',
  },
  {
    value: 'RENX.L',
    label: 'RENX.L,  Renalytix Plc',
    name: 'Renalytix Plc',
    symbol: 'RENX.L',
  },
  {
    value: 'RESI.L',
    label: 'RESI.L,  Residential Secure Income plc',
    name: 'Residential Secure Income plc',
    symbol: 'RESI.L',
  },
  {
    value: 'RFX.L',
    label: 'RFX.L,  Ramsdens Holdings PLC',
    name: 'Ramsdens Holdings PLC',
    symbol: 'RFX.L',
  },
  {
    value: 'RGD.L',
    label: 'RGD.L,  Real Good Food plc',
    name: 'Real Good Food plc',
    symbol: 'RGD.L',
  },
  {
    value: 'RGL.L',
    label: 'RGL.L,  Regional REIT Limited',
    name: 'Regional REIT Limited',
    symbol: 'RGL.L',
  },
  {
    value: 'RGO.L',
    label: 'RGO.L,  RiverFort Global Opportunities plc',
    name: 'RiverFort Global Opportunities plc',
    symbol: 'RGO.L',
  },
  {
    value: 'RGP.L',
    label: 'RGP.L,  Ross Group Plc',
    name: 'Ross Group Plc',
    symbol: 'RGP.L',
  },
  {
    value: 'RHIM.L',
    label: 'RHIM.L,  RHI Magnesita N.V.',
    name: 'RHI Magnesita N.V.',
    symbol: 'RHIM.L',
  },
  {
    value: 'RICA.L',
    label: 'RICA.L,  Ruffer Investment Company Limited',
    name: 'Ruffer Investment Company Limited',
    symbol: 'RICA.L',
  },
  {
    value: 'RICO.L',
    label: 'RICO.L,  Ricoh Company, Ltd.',
    name: 'Ricoh Company, Ltd.',
    symbol: 'RICO.L',
  },
  {
    value: 'RIII.L',
    label: 'RIII.L,  Rights and Issues Investment Trust Public Limited Company',
    name: 'Rights and Issues Investment Trust Public Limited Company',
    symbol: 'RIII.L',
  },
  {
    value: 'RIO.L',
    label: 'RIO.L,  Rio Tinto Group',
    name: 'Rio Tinto Group',
    symbol: 'RIO.L',
  },
  {
    value: 'RKH.L',
    label: 'RKH.L,  Rockhopper Exploration plc',
    name: 'Rockhopper Exploration plc',
    symbol: 'RKH.L',
  },
  {
    value: 'RLE.L',
    label: 'RLE.L,  Real Estate Investors plc',
    name: 'Real Estate Investors plc',
    symbol: 'RLE.L',
  },
  {
    value: 'RMM.L',
    label: 'RMM.L,  Rambler Metals and Mining Plc',
    name: 'Rambler Metals and Mining Plc',
    symbol: 'RMM.L',
  },
  {
    value: 'RMMC.L',
    label:
      'RMMC.L,  River and Mercantile UK Micro Cap Investment Company Limited',
    name: 'River and Mercantile UK Micro Cap Investment Company Limited',
    symbol: 'RMMC.L',
  },
  {
    value: 'RMV.L',
    label: 'RMV.L,  Rightmove plc',
    name: 'Rightmove plc',
    symbol: 'RMV.L',
  },
  {
    value: 'RNK.L',
    label: 'RNK.L,  The Rank Group Plc',
    name: 'The Rank Group Plc',
    symbol: 'RNK.L',
  },
  {
    value: 'RNO.L',
    label: 'RNO.L,  Renold plc',
    name: 'Renold plc',
    symbol: 'RNO.L',
  },
  {
    value: 'RNWH.L',
    label: 'RNWH.L,  Renew Holdings plc',
    name: 'Renew Holdings plc',
    symbol: 'RNWH.L',
  },
  {
    value: 'ROCK.L',
    label: 'ROCK.L,  Rockfire Resources plc',
    name: 'Rockfire Resources plc',
    symbol: 'ROCK.L',
  },
  {
    value: 'ROL.L',
    label: 'ROL.L,  Rotala PLC',
    name: 'Rotala PLC',
    symbol: 'ROL.L',
  },
  {
    value: 'ROR.L',
    label: 'ROR.L,  Rotork plc',
    name: 'Rotork plc',
    symbol: 'ROR.L',
  },
  {
    value: 'RPS.L',
    label: 'RPS.L,  RPS Group plc',
    name: 'RPS Group plc',
    symbol: 'RPS.L',
  },
  {
    value: 'RQIH.L',
    label: 'RQIH.L,  R&Q Insurance Holdings Ltd.',
    name: 'R&Q Insurance Holdings Ltd.',
    symbol: 'RQIH.L',
  },
  {
    value: 'RRR.L',
    label: 'RRR.L,  Red Rock Resources plc',
    name: 'Red Rock Resources plc',
    symbol: 'RRR.L',
  },
  {
    value: 'RSE.L',
    label: 'RSE.L,  Riverstone Energy Limited',
    name: 'Riverstone Energy Limited',
    symbol: 'RSE.L',
  },
  {
    value: 'RSG.L',
    label: 'RSG.L,  Resolute Mining Limited',
    name: 'Resolute Mining Limited',
    symbol: 'RSG.L',
  },
  {
    value: 'RST.L',
    label: 'RST.L,  Restore plc',
    name: 'Restore plc',
    symbol: 'RST.L',
  },
  {
    value: 'RSW.L',
    label: 'RSW.L,  Renishaw plc',
    name: 'Renishaw plc',
    symbol: 'RSW.L',
  },
  {
    value: 'RTC.L',
    label: 'RTC.L,  RTC Group plc',
    name: 'RTC Group plc',
    symbol: 'RTC.L',
  },
  {
    value: 'RTN.L',
    label: 'RTN.L,  The Restaurant Group plc',
    name: 'The Restaurant Group plc',
    symbol: 'RTN.L',
  },
  {
    value: 'RTO.L',
    label: 'RTO.L,  Rentokil Initial plc',
    name: 'Rentokil Initial plc',
    symbol: 'RTO.L',
  },
  {
    value: 'RTW.L',
    label: 'RTW.L,  RTW Venture Fund Limited',
    name: 'RTW Venture Fund Limited',
    symbol: 'RTW.L',
  },
  {
    value: 'RUA.L',
    label: 'RUA.L,  RUA Life Sciences plc',
    name: 'RUA Life Sciences plc',
    symbol: 'RUA.L',
  },
  {
    value: 'RUR.L',
    label: 'RUR.L,  Rurelec PLC',
    name: 'Rurelec PLC',
    symbol: 'RUR.L',
  },
  {
    value: 'RWA.L',
    label: 'RWA.L,  Robert Walters plc',
    name: 'Robert Walters plc',
    symbol: 'RWA.L',
  },
  {
    value: 'RWI.L',
    label: 'RWI.L,  Renewi plc',
    name: 'Renewi plc',
    symbol: 'RWI.L',
  },
  {
    value: 'RWS.L',
    label: 'RWS.L,  RWS Holdings plc',
    name: 'RWS Holdings plc',
    symbol: 'RWS.L',
  },
  {
    value: 'S32.L',
    label: 'S32.L,  South32 Limited',
    name: 'South32 Limited',
    symbol: 'S32.L',
  },
  {
    value: 'SAA.L',
    label: 'SAA.L,  M&C Saatchi plc',
    name: 'M&C Saatchi plc',
    symbol: 'SAA.L',
  },
  {
    value: 'SAE.L',
    label: 'SAE.L,  SIMEC Atlantis Energy Limited',
    name: 'SIMEC Atlantis Energy Limited',
    symbol: 'SAE.L',
  },
  {
    value: 'SAFE.L',
    label: 'SAFE.L,  Safestore Holdings plc',
    name: 'Safestore Holdings plc',
    symbol: 'SAFE.L',
  },
  {
    value: 'SAG.L',
    label: 'SAG.L,  Science Group plc',
    name: 'Science Group plc',
    symbol: 'SAG.L',
  },
  {
    value: 'SAGA.L',
    label: 'SAGA.L,  Saga plc',
    name: 'Saga plc',
    symbol: 'SAGA.L',
  },
  {
    value: 'SAIN.L',
    label: 'SAIN.L,  The Scottish American Investment Company P.L.C.',
    name: 'The Scottish American Investment Company P.L.C.',
    symbol: 'SAIN.L',
  },
  {
    value: 'SAL.L',
    label: 'SAL.L,  SpaceandPeople plc',
    name: 'SpaceandPeople plc',
    symbol: 'SAL.L',
  },
  {
    value: 'SAR.L',
    label: 'SAR.L,  Sareum Holdings plc',
    name: 'Sareum Holdings plc',
    symbol: 'SAR.L',
  },
  {
    value: 'SAV.L',
    label: 'SAV.L,  Savannah Resources Plc',
    name: 'Savannah Resources Plc',
    symbol: 'SAV.L',
  },
  {
    value: 'SAVE.L',
    label: 'SAVE.L,  Savannah Energy PLC',
    name: 'Savannah Energy PLC',
    symbol: 'SAVE.L',
  },
  {
    value: 'SBRE.L',
    label: 'SBRE.L,  Sabre Insurance Group plc',
    name: 'Sabre Insurance Group plc',
    symbol: 'SBRE.L',
  },
  {
    value: 'SBRY.L',
    label: 'SBRY.L,  J Sainsbury plc',
    name: 'J Sainsbury plc',
    symbol: 'SBRY.L',
  },
  {
    value: 'SBTX.L',
    label: 'SBTX.L,  SkinBioTherapeutics Plc',
    name: 'SkinBioTherapeutics Plc',
    symbol: 'SBTX.L',
  },
  {
    value: 'SCE.L',
    label: 'SCE.L,  Surface Transforms Plc',
    name: 'Surface Transforms Plc',
    symbol: 'SCE.L',
  },
  {
    value: 'SCF.L',
    label: 'SCF.L,  Schroder Income Growth Fund plc',
    name: 'Schroder Income Growth Fund plc',
    symbol: 'SCF.L',
  },
  {
    value: 'SCGL.L',
    label: 'SCGL.L,  Sealand Capital Galaxy Limited',
    name: 'Sealand Capital Galaxy Limited',
    symbol: 'SCGL.L',
  },
  {
    value: 'SCHO.L',
    label: 'SCHO.L,  Scholium Group Plc',
    name: 'Scholium Group Plc',
    symbol: 'SCHO.L',
  },
  {
    value: 'SCLP.L',
    label: 'SCLP.L,  Scancell Holdings plc',
    name: 'Scancell Holdings plc',
    symbol: 'SCLP.L',
  },
  {
    value: 'SCP.L',
    label: 'SCP.L,  Schroder UK Mid Cap Fund plc',
    name: 'Schroder UK Mid Cap Fund plc',
    symbol: 'SCP.L',
  },
  {
    value: 'SCRF.L',
    label: 'SCRF.L,  SME Credit Realisation Fund Limited',
    name: 'SME Credit Realisation Fund Limited',
    symbol: 'SCRF.L',
  },
  {
    value: 'SCS.L',
    label: 'SCS.L,  ScS Group plc',
    name: 'ScS Group plc',
    symbol: 'SCS.L',
  },
  {
    value: 'SCT.L',
    label: 'SCT.L,  Softcat plc',
    name: 'Softcat plc',
    symbol: 'SCT.L',
  },
  {
    value: 'SDI.L',
    label: 'SDI.L,  SDI Group plc',
    name: 'SDI Group plc',
    symbol: 'SDI.L',
  },
  {
    value: 'SDP.L',
    label: 'SDP.L,  Schroder Investment Trust - Schroder AsiaPacific Fund plc',
    name: 'Schroder Investment Trust - Schroder AsiaPacific Fund plc',
    symbol: 'SDP.L',
  },
  {
    value: 'SDR.L',
    label: 'SDR.L,  Schroders plc',
    name: 'Schroders plc',
    symbol: 'SDR.L',
  },
  {
    value: 'SDRY.L',
    label: 'SDRY.L,  Superdry plc',
    name: 'Superdry plc',
    symbol: 'SDRY.L',
  },
  {
    value: 'SDV.L',
    label: 'SDV.L,  Chelverton UK Dividend Trust PLC',
    name: 'Chelverton UK Dividend Trust PLC',
    symbol: 'SDV.L',
  },
  {
    value: 'SDVP.L',
    label: 'SDVP.L,  SDV 2025 ZDP Plc',
    name: 'SDV 2025 ZDP Plc',
    symbol: 'SDVP.L',
  },
  {
    value: 'SDX.L',
    label: 'SDX.L,  SDX Energy plc',
    name: 'SDX Energy plc',
    symbol: 'SDX.L',
  },
  {
    value: 'SDY.L',
    label: 'SDY.L,  Speedy Hire Plc',
    name: 'Speedy Hire Plc',
    symbol: 'SDY.L',
  },
  {
    value: 'SEC.L',
    label: 'SEC.L,  Strategic Equity Capital plc',
    name: 'Strategic Equity Capital plc',
    symbol: 'SEC.L',
  },
  {
    value: 'SEE.L',
    label: 'SEE.L,  Seeing Machines Limited',
    name: 'Seeing Machines Limited',
    symbol: 'SEE.L',
  },
  {
    value: 'SEEN.L',
    label: 'SEEN.L,  SEEEN plc',
    name: 'SEEEN plc',
    symbol: 'SEEN.L',
  },
  {
    value: 'SEIT.L',
    label: 'SEIT.L,  SDCL Energy Efficiency Income Trust Plc',
    name: 'SDCL Energy Efficiency Income Trust Plc',
    symbol: 'SEIT.L',
  },
  {
    value: 'SENX.L',
    label: 'SENX.L,  Serinus Energy plc',
    name: 'Serinus Energy plc',
    symbol: 'SENX.L',
  },
  {
    value: 'SEPL.L',
    label: 'SEPL.L,  Seplat Energy Plc',
    name: 'Seplat Energy Plc',
    symbol: 'SEPL.L',
  },
  {
    value: 'SEQI.L',
    label: 'SEQI.L,  Sequoia Economic Infrastructure Income Fund Limited',
    name: 'Sequoia Economic Infrastructure Income Fund Limited',
    symbol: 'SEQI.L',
  },
  {
    value: 'SERE.L',
    label: 'SERE.L,  Schroder European Real Estate Investment Trust Plc',
    name: 'Schroder European Real Estate Investment Trust Plc',
    symbol: 'SERE.L',
  },
  {
    value: 'SFE.L',
    label: 'SFE.L,  Safestyle UK plc',
    name: 'Safestyle UK plc',
    symbol: 'SFE.L',
  },
  {
    value: 'SFOR.L',
    label: 'SFOR.L,  S4 Capital plc',
    name: 'S4 Capital plc',
    symbol: 'SFOR.L',
  },
  {
    value: 'SFR.L',
    label: 'SFR.L,  Severfield plc',
    name: 'Severfield plc',
    symbol: 'SFR.L',
  },
  {
    value: 'SGE.L',
    label: 'SGE.L,  The Sage Group plc',
    name: 'The Sage Group plc',
    symbol: 'SGE.L',
  },
  {
    value: 'SGLN.L',
    label: 'SGLN.L,  iShares Physical Gold ETC',
    name: 'iShares Physical Gold ETC',
    symbol: 'SGLN.L',
  },
  {
    value: 'SGRO.L',
    label: 'SGRO.L,  SEGRO Plc',
    name: 'SEGRO Plc',
    symbol: 'SGRO.L',
  },
  {
    value: 'SGZ.L',
    label: 'SGZ.L,  Scotgold Resources Limited',
    name: 'Scotgold Resources Limited',
    symbol: 'SGZ.L',
  },
  {
    value: 'SHB.L',
    label: 'SHB.L,  Shaftesbury PLC',
    name: 'Shaftesbury PLC',
    symbol: 'SHB.L',
  },
  {
    value: 'SHED.L',
    label: 'SHED.L,  Urban Logistics REIT plc',
    name: 'Urban Logistics REIT plc',
    symbol: 'SHED.L',
  },
  {
    value: 'SHG.L',
    label: 'SHG.L,  Shanta Gold Limited',
    name: 'Shanta Gold Limited',
    symbol: 'SHG.L',
  },
  {
    value: 'SHI.L',
    label: 'SHI.L,  SIG plc',
    name: 'SIG plc',
    symbol: 'SHI.L',
  },
  {
    value: 'SHIP.L',
    label: 'SHIP.L,  Tufton Oceanic Assets Limited',
    name: 'Tufton Oceanic Assets Limited',
    symbol: 'SHIP.L',
  },
  {
    value: 'SHOE.L',
    label: 'SHOE.L,  Shoe Zone plc',
    name: 'Shoe Zone plc',
    symbol: 'SHOE.L',
  },
  {
    value: 'SHRS.L',
    label: 'SHRS.L,  Shires Income Plc',
    name: 'Shires Income Plc',
    symbol: 'SHRS.L',
  },
  {
    value: 'SIGC.L',
    label: 'SIGC.L,  Sherborne Investors (Guernsey) C Limited',
    name: 'Sherborne Investors (Guernsey) C Limited',
    symbol: 'SIGC.L',
  },
  {
    value: 'SIHL.L',
    label: 'SIHL.L,  Symphony International Holdings Limited',
    name: 'Symphony International Holdings Limited',
    symbol: 'SIHL.L',
  },
  {
    value: 'SIS.L',
    label: 'SIS.L,  Science in Sport plc',
    name: 'Science in Sport plc',
    symbol: 'SIS.L',
  },
  {
    value: 'SIXH.L',
    label: 'SIXH.L,  The 600 Group PLC',
    name: 'The 600 Group PLC',
    symbol: 'SIXH.L',
  },
  {
    value: 'SJG.L',
    label: 'SJG.L,  Schroder Japan Growth Fund plc',
    name: 'Schroder Japan Growth Fund plc',
    symbol: 'SJG.L',
  },
  {
    value: 'SKG.L',
    label: 'SKG.L,  Smurfit Kappa Group Plc',
    name: 'Smurfit Kappa Group Plc',
    symbol: 'SKG.L',
  },
  {
    value: 'SLE.L',
    label: 'SLE.L,  San Leon Energy plc',
    name: 'San Leon Energy plc',
    symbol: 'SLE.L',
  },
  {
    value: 'SLNG.L',
    label: 'SLNG.L,  H C Slingsby plc',
    name: 'H C Slingsby plc',
    symbol: 'SLNG.L',
  },
  {
    value: 'SLP.L',
    label: 'SLP.L,  Sylvania Platinum Limited',
    name: 'Sylvania Platinum Limited',
    symbol: 'SLP.L',
  },
  {
    value: 'SMDS.L',
    label: 'SMDS.L,  DS Smith Plc',
    name: 'DS Smith Plc',
    symbol: 'SMDS.L',
  },
  {
    value: 'SMIF.L',
    label: 'SMIF.L,  TwentyFour Select Monthly Income Fund Limited',
    name: 'TwentyFour Select Monthly Income Fund Limited',
    symbol: 'SMIF.L',
  },
  {
    value: 'SMIN.L',
    label: 'SMIN.L,  Smiths Group plc',
    name: 'Smiths Group plc',
    symbol: 'SMIN.L',
  },
  {
    value: 'SMJ.L',
    label: 'SMJ.L,  J. Smart & Co. (Contractors) PLC',
    name: 'J. Smart & Co. (Contractors) PLC',
    symbol: 'SMJ.L',
  },
  {
    value: 'SML.L',
    label: 'SML.L,  Strategic Minerals Plc',
    name: 'Strategic Minerals Plc',
    symbol: 'SML.L',
  },
  {
    value: 'SMRT.L',
    label: 'SMRT.L,  Smartspace Software plc',
    name: 'Smartspace Software plc',
    symbol: 'SMRT.L',
  },
  {
    value: 'SMS.L',
    label: 'SMS.L,  Smart Metering Systems plc',
    name: 'Smart Metering Systems plc',
    symbol: 'SMS.L',
  },
  {
    value: 'SMT.L',
    label: 'SMT.L,  Scottish Mortgage Investment Trust PLC',
    name: 'Scottish Mortgage Investment Trust PLC',
    symbol: 'SMT.L',
  },
  {
    value: 'SMWH.L',
    label: 'SMWH.L,  WH Smith PLC',
    name: 'WH Smith PLC',
    symbol: 'SMWH.L',
  },
  {
    value: 'SNG.L',
    label: 'SNG.L,  Synairgen plc',
    name: 'Synairgen plc',
    symbol: 'SNG.L',
  },
  {
    value: 'SNR.L',
    label: 'SNR.L,  Senior plc',
    name: 'Senior plc',
    symbol: 'SNR.L',
  },
  {
    value: 'SNX.L',
    label: 'SNX.L,  Synectics plc',
    name: 'Synectics plc',
    symbol: 'SNX.L',
  },
  {
    value: 'SOHO.L',
    label: 'SOHO.L,  Triple Point Social Housing REIT plc',
    name: 'Triple Point Social Housing REIT plc',
    symbol: 'SOHO.L',
  },
  {
    value: 'SOLG.L',
    label: 'SOLG.L,  SolGold Plc',
    name: 'SolGold Plc',
    symbol: 'SOLG.L',
  },
  {
    value: 'SOLI.L',
    label: 'SOLI.L,  Solid State plc',
    name: 'Solid State plc',
    symbol: 'SOLI.L',
  },
  {
    value: 'SOM.L',
    label: 'SOM.L,  Somero Enterprises, Inc.',
    name: 'Somero Enterprises, Inc.',
    symbol: 'SOM.L',
  },
  {
    value: 'SOND.L',
    label: 'SOND.L,  Hipgnosis Songs Fund Limited',
    name: 'Hipgnosis Songs Fund Limited',
    symbol: 'SOND.L',
  },
  {
    value: 'SONG.L',
    label: 'SONG.L,  Hipgnosis Songs Fund Limited',
    name: 'Hipgnosis Songs Fund Limited',
    symbol: 'SONG.L',
  },
  {
    value: 'SOS.L',
    label: 'SOS.L,  Sosandar Plc',
    name: 'Sosandar Plc',
    symbol: 'SOS.L',
  },
  {
    value: 'SOU.L',
    label: 'SOU.L,  Sound Energy plc',
    name: 'Sound Energy plc',
    symbol: 'SOU.L',
  },
  {
    value: 'SPA.L',
    label: 'SPA.L,  1Spatial Plc',
    name: '1Spatial Plc',
    symbol: 'SPA.L',
  },
  {
    value: 'SPDI.L',
    label: 'SPDI.L,  Secure Property Development & Investment Plc',
    name: 'Secure Property Development & Investment Plc',
    symbol: 'SPDI.L',
  },
  {
    value: 'SPE.L',
    label: 'SPE.L,  Sopheon plc',
    name: 'Sopheon plc',
    symbol: 'SPE.L',
  },
  {
    value: 'SPEC.L',
    label: 'SPEC.L,  INSPECS Group plc',
    name: 'INSPECS Group plc',
    symbol: 'SPEC.L',
  },
  {
    value: 'SPI.L',
    label: 'SPI.L,  Spire Healthcare Group plc',
    name: 'Spire Healthcare Group plc',
    symbol: 'SPI.L',
  },
  {
    value: 'SPO.L',
    label: 'SPO.L,  Sportech PLC',
    name: 'Sportech PLC',
    symbol: 'SPO.L',
  },
  {
    value: 'SPR.L',
    label: 'SPR.L,  Springfield Properties Plc',
    name: 'Springfield Properties Plc',
    symbol: 'SPR.L',
  },
  {
    value: 'SPSY.L',
    label: 'SPSY.L,  Spectra Systems Corporation',
    name: 'Spectra Systems Corporation',
    symbol: 'SPSY.L',
  },
  {
    value: 'SPT.L',
    label: 'SPT.L,  Spirent Communications plc',
    name: 'Spirent Communications plc',
    symbol: 'SPT.L',
  },
  {
    value: 'SPX.L',
    label: 'SPX.L,  Spirax-Sarco Engineering plc',
    name: 'Spirax-Sarco Engineering plc',
    symbol: 'SPX.L',
  },
  {
    value: 'SQZ.L',
    label: 'SQZ.L,  Serica Energy plc',
    name: 'Serica Energy plc',
    symbol: 'SQZ.L',
  },
  {
    value: 'SRB.L',
    label: 'SRB.L,  Serabi Gold plc',
    name: 'Serabi Gold plc',
    symbol: 'SRB.L',
  },
  {
    value: 'SRC.L',
    label: 'SRC.L,  SigmaRoc plc',
    name: 'SigmaRoc plc',
    symbol: 'SRC.L',
  },
  {
    value: 'SRE.L',
    label: 'SRE.L,  Sirius Real Estate Limited',
    name: 'Sirius Real Estate Limited',
    symbol: 'SRE.L',
  },
  {
    value: 'SREI.L',
    label: 'SREI.L,  Schroder Real Estate Investment Trust Limited',
    name: 'Schroder Real Estate Investment Trust Limited',
    symbol: 'SREI.L',
  },
  {
    value: 'SRES.L',
    label: 'SRES.L,  Sunrise Resources plc',
    name: 'Sunrise Resources plc',
    symbol: 'SRES.L',
  },
  {
    value: 'SRP.L',
    label: 'SRP.L,  Serco Group plc',
    name: 'Serco Group plc',
    symbol: 'SRP.L',
  },
  {
    value: 'SRT.L',
    label: 'SRT.L,  SRT Marine Systems plc',
    name: 'SRT Marine Systems plc',
    symbol: 'SRT.L',
  },
  {
    value: 'SSE.L',
    label: 'SSE.L,  SSE plc',
    name: 'SSE plc',
    symbol: 'SSE.L',
  },
  {
    value: 'SSIF.L',
    label: 'SSIF.L,  Secured Income Fund Plc',
    name: 'Secured Income Fund Plc',
    symbol: 'SSIF.L',
  },
  {
    value: 'SSON.L',
    label: 'SSON.L,  Smithson Investment Trust PLC',
    name: 'Smithson Investment Trust PLC',
    symbol: 'SSON.L',
  },
  {
    value: 'SSPG.L',
    label: 'SSPG.L,  SSP Group plc',
    name: 'SSP Group plc',
    symbol: 'SSPG.L',
  },
  {
    value: 'SST.L',
    label: 'SST.L,  The Scottish Oriental Smaller Companies Trust plc',
    name: 'The Scottish Oriental Smaller Companies Trust plc',
    symbol: 'SST.L',
  },
  {
    value: 'SSTY.L',
    label: 'SSTY.L,  Safestay plc',
    name: 'Safestay plc',
    symbol: 'SSTY.L',
  },
  {
    value: 'STA.L',
    label: 'STA.L,  Star Phoenix Group Ltd',
    name: 'Star Phoenix Group Ltd',
    symbol: 'STA.L',
  },
  {
    value: 'STAF.L',
    label: 'STAF.L,  Staffline Group plc',
    name: 'Staffline Group plc',
    symbol: 'STAF.L',
  },
  {
    value: 'STAN.L',
    label: 'STAN.L,  Standard Chartered PLC',
    name: 'Standard Chartered PLC',
    symbol: 'STAN.L',
  },
  {
    value: 'STB.L',
    label: 'STB.L,  Secure Trust Bank PLC',
    name: 'Secure Trust Bank PLC',
    symbol: 'STB.L',
  },
  {
    value: 'STCM.L',
    label: 'STCM.L,  Steppe Cement Ltd.',
    name: 'Steppe Cement Ltd.',
    symbol: 'STCM.L',
  },
  {
    value: 'STEM.L',
    label: 'STEM.L,  SThree plc',
    name: 'SThree plc',
    symbol: 'STEM.L',
  },
  {
    value: 'STJ.L',
    label: "STJ.L,  St. James's Place plc",
    name: "St. James's Place plc",
    symbol: 'STJ.L',
  },
  {
    value: 'STM.L',
    label: 'STM.L,  STM Group Plc',
    name: 'STM Group Plc',
    symbol: 'STM.L',
  },
  {
    value: 'STS.L',
    label: 'STS.L,  Securities Trust of Scotland plc',
    name: 'Securities Trust of Scotland plc',
    symbol: 'STS.L',
  },
  {
    value: 'STVG.L',
    label: 'STVG.L,  STV Group plc',
    name: 'STV Group plc',
    symbol: 'STVG.L',
  },
  {
    value: 'STX.L',
    label: 'STX.L,  Shield Therapeutics plc',
    name: 'Shield Therapeutics plc',
    symbol: 'STX.L',
  },
  {
    value: 'SUH.L',
    label: 'SUH.L,  Sutton Harbour Group plc',
    name: 'Sutton Harbour Group plc',
    symbol: 'SUH.L',
  },
  {
    value: 'SUN.L',
    label: 'SUN.L,  Surgical Innovations Group plc',
    name: 'Surgical Innovations Group plc',
    symbol: 'SUN.L',
  },
  {
    value: 'SUPP.L',
    label: 'SUPP.L,  Schroder UK Public Private Trust plc',
    name: 'Schroder UK Public Private Trust plc',
    symbol: 'SUPP.L',
  },
  {
    value: 'SUPR.L',
    label: 'SUPR.L,  Supermarket Income REIT plc',
    name: 'Supermarket Income REIT plc',
    symbol: 'SUPR.L',
  },
  {
    value: 'SUR.L',
    label: 'SUR.L,  Sureserve Group plc',
    name: 'Sureserve Group plc',
    symbol: 'SUR.L',
  },
  {
    value: 'SURE.L',
    label: 'SURE.L,  Sure Ventures Plc',
    name: 'Sure Ventures Plc',
    symbol: 'SURE.L',
  },
  {
    value: 'SUS.L',
    label: 'SUS.L,  S&U plc',
    name: 'S&U plc',
    symbol: 'SUS.L',
  },
  {
    value: 'SVE.L',
    label: 'SVE.L,  Starvest plc',
    name: 'Starvest plc',
    symbol: 'SVE.L',
  },
  {
    value: 'SVM.L',
    label: 'SVM.L,  SVM UK Emerging Fund plc',
    name: 'SVM UK Emerging Fund plc',
    symbol: 'SVM.L',
  },
  {
    value: 'SVS.L',
    label: 'SVS.L,  Savills plc',
    name: 'Savills plc',
    symbol: 'SVS.L',
  },
  {
    value: 'SVT.L',
    label: 'SVT.L,  Severn Trent Plc',
    name: 'Severn Trent Plc',
    symbol: 'SVT.L',
  },
  {
    value: 'SWEF.L',
    label: 'SWEF.L,  Starwood European Real Estate Finance Ltd.',
    name: 'Starwood European Real Estate Finance Ltd.',
    symbol: 'SWEF.L',
  },
  {
    value: 'SWG.L',
    label: 'SWG.L,  Shearwater Group plc',
    name: 'Shearwater Group plc',
    symbol: 'SWG.L',
  },
  {
    value: 'SXS.L',
    label: 'SXS.L,  Spectris plc',
    name: 'Spectris plc',
    symbol: 'SXS.L',
  },
  {
    value: 'SYM.L',
    label: 'SYM.L,  Symphony Environmental Technologies plc',
    name: 'Symphony Environmental Technologies plc',
    symbol: 'SYM.L',
  },
  {
    value: 'SYME.L',
    label: 'SYME.L,  Supply@ME Capital plc',
    name: 'Supply@ME Capital plc',
    symbol: 'SYME.L',
  },
  {
    value: 'SYNC.L',
    label: 'SYNC.L,  Syncona Limited',
    name: 'Syncona Limited',
    symbol: 'SYNC.L',
  },
  {
    value: 'SYNT.L',
    label: 'SYNT.L,  Synthomer plc',
    name: 'Synthomer plc',
    symbol: 'SYNT.L',
  },
  {
    value: 'SYS.L',
    label: 'SYS.L,  SysGroup plc',
    name: 'SysGroup plc',
    symbol: 'SYS.L',
  },
  {
    value: 'SYS1.L',
    label: 'SYS1.L,  System1 Group PLC',
    name: 'System1 Group PLC',
    symbol: 'SYS1.L',
  },
  {
    value: 'TAM.L',
    label: 'TAM.L,  Tatton Asset Management plc',
    name: 'Tatton Asset Management plc',
    symbol: 'TAM.L',
  },
  {
    value: 'TAN.L',
    label: 'TAN.L,  Tanfield Group PLC',
    name: 'Tanfield Group PLC',
    symbol: 'TAN.L',
  },
  {
    value: 'TAST.L',
    label: 'TAST.L,  Tasty plc',
    name: 'Tasty plc',
    symbol: 'TAST.L',
  },
  {
    value: 'TATE.L',
    label: 'TATE.L,  Tate & Lyle plc',
    name: 'Tate & Lyle plc',
    symbol: 'TATE.L',
  },
  {
    value: 'TAVI.L',
    label: 'TAVI.L,  Tavistock Investments Plc',
    name: 'Tavistock Investments Plc',
    symbol: 'TAVI.L',
  },
  {
    value: 'TBCG.L',
    label: 'TBCG.L,  TBC Bank Group PLC',
    name: 'TBC Bank Group PLC',
    symbol: 'TBCG.L',
  },
  {
    value: 'TCAP.L',
    label: 'TCAP.L,  TP ICAP Group PLC',
    name: 'TP ICAP Group PLC',
    symbol: 'TCAP.L',
  },
  {
    value: 'TEG.L',
    label: 'TEG.L,  Ten Entertainment Group plc',
    name: 'Ten Entertainment Group plc',
    symbol: 'TEG.L',
  },
  {
    value: 'TEK.L',
    label: 'TEK.L,  Tekcapital plc',
    name: 'Tekcapital plc',
    symbol: 'TEK.L',
  },
  {
    value: 'TEM.L',
    label: 'TEM.L,  Templeton Emerging Markets Investment Trust plc',
    name: 'Templeton Emerging Markets Investment Trust plc',
    symbol: 'TEM.L',
  },
  {
    value: 'TENG.L',
    label: 'TENG.L,  Ten Lifestyle Group Plc',
    name: 'Ten Lifestyle Group Plc',
    symbol: 'TENG.L',
  },
  {
    value: 'TEP.L',
    label: 'TEP.L,  Telecom Plus Plc',
    name: 'Telecom Plus Plc',
    symbol: 'TEP.L',
  },
  {
    value: 'TERN.L',
    label: 'TERN.L,  Tern Plc',
    name: 'Tern Plc',
    symbol: 'TERN.L',
  },
  {
    value: 'TET.L',
    label: 'TET.L,  Treatt plc',
    name: 'Treatt plc',
    symbol: 'TET.L',
  },
  {
    value: 'TFG.L',
    label: 'TFG.L,  Tetragon Financial Group Limited',
    name: 'Tetragon Financial Group Limited',
    symbol: 'TFG.L',
  },
  {
    value: 'TFGS.L',
    label: 'TFGS.L,  Tetragon Financial Group Limited',
    name: 'Tetragon Financial Group Limited',
    symbol: 'TFGS.L',
  },
  {
    value: 'TFIF.L',
    label: 'TFIF.L,  TwentyFour Income Fund Limited',
    name: 'TwentyFour Income Fund Limited',
    symbol: 'TFIF.L',
  },
  {
    value: 'TFW.L',
    label: 'TFW.L,  FW Thorpe Plc',
    name: 'FW Thorpe Plc',
    symbol: 'TFW.L',
  },
  {
    value: 'TGP.L',
    label: 'TGP.L,  Tekmar Group plc',
    name: 'Tekmar Group plc',
    symbol: 'TGP.L',
  },
  {
    value: 'THAL.L',
    label: 'THAL.L,  Thalassa Holdings Limited',
    name: 'Thalassa Holdings Limited',
    symbol: 'THAL.L',
  },
  {
    value: 'THR.L',
    label: 'THR.L,  Thor Mining PLC',
    name: 'Thor Mining PLC',
    symbol: 'THR.L',
  },
  {
    value: 'THRG.L',
    label: 'THRG.L,  BlackRock Throgmorton Trust plc',
    name: 'BlackRock Throgmorton Trust plc',
    symbol: 'THRG.L',
  },
  {
    value: 'THRL.L',
    label: 'THRL.L,  Target Healthcare REIT PLC',
    name: 'Target Healthcare REIT PLC',
    symbol: 'THRL.L',
  },
  {
    value: 'THRU.L',
    label: 'THRU.L,  Thruvision Group plc',
    name: 'Thruvision Group plc',
    symbol: 'THRU.L',
  },
  {
    value: 'THS.L',
    label: 'THS.L,  Tharisa plc',
    name: 'Tharisa plc',
    symbol: 'THS.L',
  },
  {
    value: 'TI1.L',
    label: 'TI1.L,  Trian Investors 1 Limited',
    name: 'Trian Investors 1 Limited',
    symbol: 'TI1.L',
  },
  {
    value: 'TIDE.L',
    label: 'TIDE.L,  Crimson Tide plc',
    name: 'Crimson Tide plc',
    symbol: 'TIDE.L',
  },
  {
    value: 'TIFS.L',
    label: 'TIFS.L,  TI Fluid Systems plc',
    name: 'TI Fluid Systems plc',
    symbol: 'TIFS.L',
  },
  {
    value: 'TIGT.L',
    label: 'TIGT.L,  Troy Income & Growth Trust Plc',
    name: 'Troy Income & Growth Trust Plc',
    symbol: 'TIGT.L',
  },
  {
    value: 'TIR.L',
    label: 'TIR.L,  Tiger Royalties and investments Plc',
    name: 'Tiger Royalties and investments Plc',
    symbol: 'TIR.L',
  },
  {
    value: 'TKO.L',
    label: 'TKO.L,  Taseko Mines Limited',
    name: 'Taseko Mines Limited',
    symbol: 'TKO.L',
  },
  {
    value: 'TLOU.L',
    label: 'TLOU.L,  Tlou Energy Limited',
    name: 'Tlou Energy Limited',
    symbol: 'TLOU.L',
  },
  {
    value: 'TLW.L',
    label: 'TLW.L,  Tullow Oil plc',
    name: 'Tullow Oil plc',
    symbol: 'TLW.L',
  },
  {
    value: 'TLY.L',
    label: 'TLY.L,  Totally plc',
    name: 'Totally plc',
    symbol: 'TLY.L',
  },
  {
    value: 'TM17.L',
    label: 'TM17.L,  Team17 Group plc',
    name: 'Team17 Group plc',
    symbol: 'TM17.L',
  },
  {
    value: 'TMG.L',
    label: 'TMG.L,  The Mission Group plc',
    name: 'The Mission Group plc',
    symbol: 'TMG.L',
  },
  {
    value: 'TMO.L',
    label: 'TMO.L,  Time Out Group plc',
    name: 'Time Out Group plc',
    symbol: 'TMO.L',
  },
  {
    value: 'TMPL.L',
    label: 'TMPL.L,  Temple Bar Investment Trust PLC',
    name: 'Temple Bar Investment Trust PLC',
    symbol: 'TMPL.L',
  },
  {
    value: 'TMT.L',
    label: 'TMT.L,  TMT Investments PLC',
    name: 'TMT Investments PLC',
    symbol: 'TMT.L',
  },
  {
    value: 'TND.L',
    label: 'TND.L,  Tandem Group plc',
    name: 'Tandem Group plc',
    symbol: 'TND.L',
  },
  {
    value: 'TOM.L',
    label: 'TOM.L,  TomCo Energy Plc',
    name: 'TomCo Energy Plc',
    symbol: 'TOM.L',
  },
  {
    value: 'TON.L',
    label: 'TON.L,  Titon Holdings Plc',
    name: 'Titon Holdings Plc',
    symbol: 'TON.L',
  },
  {
    value: 'TOOP.L',
    label: 'TOOP.L,  Toople Plc',
    name: 'Toople Plc',
    symbol: 'TOOP.L',
  },
  {
    value: 'TORO.L',
    label: 'TORO.L,  Chenavari Toro Income Fund Limited',
    name: 'Chenavari Toro Income Fund Limited',
    symbol: 'TORO.L',
  },
  {
    value: 'TOWN.L',
    label: 'TOWN.L,  Town Centre Securities PLC',
    name: 'Town Centre Securities PLC',
    symbol: 'TOWN.L',
  },
  {
    value: 'TPFG.L',
    label: 'TPFG.L,  The Property Franchise Group PLC',
    name: 'The Property Franchise Group PLC',
    symbol: 'TPFG.L',
  },
  {
    value: 'TPG.L',
    label: 'TPG.L,  TP Group plc',
    name: 'TP Group plc',
    symbol: 'TPG.L',
  },
  {
    value: 'TPK.L',
    label: 'TPK.L,  Travis Perkins plc',
    name: 'Travis Perkins plc',
    symbol: 'TPK.L',
  },
  {
    value: 'TPOS.L',
    label: 'TPOS.L,  Third Point Investors Limited',
    name: 'Third Point Investors Limited',
    symbol: 'TPOS.L',
  },
  {
    value: 'TPOU.L',
    label: 'TPOU.L,  Third Point Investors Limited',
    name: 'Third Point Investors Limited',
    symbol: 'TPOU.L',
  },
  {
    value: 'TPT.L',
    label: 'TPT.L,  Topps Tiles Plc',
    name: 'Topps Tiles Plc',
    symbol: 'TPT.L',
  },
  {
    value: 'TPX.L',
    label: 'TPX.L,  TPXimpact Holdings plc',
    name: 'TPXimpact Holdings plc',
    symbol: 'TPX.L',
  },
  {
    value: 'TRAF.L',
    label: 'TRAF.L,  Trafalgar Property Group plc',
    name: 'Trafalgar Property Group plc',
    symbol: 'TRAF.L',
  },
  {
    value: 'TRAK.L',
    label: 'TRAK.L,  Trakm8 Holdings PLC',
    name: 'Trakm8 Holdings PLC',
    symbol: 'TRAK.L',
  },
  {
    value: 'TRB.L',
    label: 'TRB.L,  Tribal Group plc',
    name: 'Tribal Group plc',
    symbol: 'TRB.L',
  },
  {
    value: 'TRCS.L',
    label: 'TRCS.L,  Tracsis plc',
    name: 'Tracsis plc',
    symbol: 'TRCS.L',
  },
  {
    value: 'TRD.L',
    label: 'TRD.L,  Triad Group plc',
    name: 'Triad Group plc',
    symbol: 'TRD.L',
  },
  {
    value: 'TRI.L',
    label: 'TRI.L,  Trifast plc',
    name: 'Trifast plc',
    symbol: 'TRI.L',
  },
  {
    value: 'TRIG.L',
    label: 'TRIG.L,  The Renewables Infrastructure Group Limited',
    name: 'The Renewables Infrastructure Group Limited',
    symbol: 'TRIG.L',
  },
  {
    value: 'TRIN.L',
    label: 'TRIN.L,  Trinity Exploration & Production plc',
    name: 'Trinity Exploration & Production plc',
    symbol: 'TRIN.L',
  },
  {
    value: 'TRMR.L',
    label: 'TRMR.L,  Tremor International Ltd',
    name: 'Tremor International Ltd',
    symbol: 'TRMR.L',
  },
  {
    value: 'TRN.L',
    label: 'TRN.L,  Trainline Plc',
    name: 'Trainline Plc',
    symbol: 'TRN.L',
  },
  {
    value: 'TRP.L',
    label: 'TRP.L,  Tower Resources plc',
    name: 'Tower Resources plc',
    symbol: 'TRP.L',
  },
  {
    value: 'TRR.L',
    label: 'TRR.L,  Trident Royalties Plc',
    name: 'Trident Royalties Plc',
    symbol: 'TRR.L',
  },
  {
    value: 'TRT.L',
    label: 'TRT.L,  Transense Technologies plc',
    name: 'Transense Technologies plc',
    symbol: 'TRT.L',
  },
  {
    value: 'TRU.L',
    label: 'TRU.L,  TruFin plc',
    name: 'TruFin plc',
    symbol: 'TRU.L',
  },
  {
    value: 'TRX.L',
    label: 'TRX.L,  Tissue Regenix Group plc',
    name: 'Tissue Regenix Group plc',
    symbol: 'TRX.L',
  },
  {
    value: 'TRY.L',
    label: 'TRY.L,  TR Property Investment Trust plc',
    name: 'TR Property Investment Trust plc',
    symbol: 'TRY.L',
  },
  {
    value: 'TSCO.L',
    label: 'TSCO.L,  Tesco PLC',
    name: 'Tesco PLC',
    symbol: 'TSCO.L',
  },
  {
    value: 'TSL.L',
    label: 'TSL.L,  ThinkSmart Limited',
    name: 'ThinkSmart Limited',
    symbol: 'TSL.L',
  },
  {
    value: 'TST.L',
    label: 'TST.L,  Touchstar plc',
    name: 'Touchstar plc',
    symbol: 'TST.L',
  },
  {
    value: 'TSTL.L',
    label: 'TSTL.L,  Tristel plc',
    name: 'Tristel plc',
    symbol: 'TSTL.L',
  },
  {
    value: 'TTG.L',
    label: 'TTG.L,  TT Electronics plc',
    name: 'TT Electronics plc',
    symbol: 'TTG.L',
  },
  {
    value: 'TUI.L',
    label: 'TUI.L,  TUI AG',
    name: 'TUI AG',
    symbol: 'TUI.L',
  },
  {
    value: 'TUNE.L',
    label: 'TUNE.L,  Focusrite plc',
    name: 'Focusrite plc',
    symbol: 'TUNE.L',
  },
  {
    value: 'TWD.L',
    label: 'TWD.L,  Trackwise Designs plc',
    name: 'Trackwise Designs plc',
    symbol: 'TWD.L',
  },
  {
    value: 'TXP.L',
    label: 'TXP.L,  Touchstone Exploration Inc.',
    name: 'Touchstone Exploration Inc.',
    symbol: 'TXP.L',
  },
  {
    value: 'TYM.L',
    label: 'TYM.L,  Tertiary Minerals plc',
    name: 'Tertiary Minerals plc',
    symbol: 'TYM.L',
  },
  {
    value: 'TYMN.L',
    label: 'TYMN.L,  Tyman plc',
    name: 'Tyman plc',
    symbol: 'TYMN.L',
  },
  {
    value: 'TYT.L',
    label: 'TYT.L,  Toyota Motor Corporation',
    name: 'Toyota Motor Corporation',
    symbol: 'TYT.L',
  },
  {
    value: 'UAV.L',
    label: 'UAV.L,  Unicorn AIM VCT plc',
    name: 'Unicorn AIM VCT plc',
    symbol: 'UAV.L',
  },
  {
    value: 'UEM.L',
    label: 'UEM.L,  Utilico Emerging Markets Trust PLC',
    name: 'Utilico Emerging Markets Trust PLC',
    symbol: 'UEM.L',
  },
  {
    value: 'UFO.L',
    label: 'UFO.L,  Alien Metals Ltd',
    name: 'Alien Metals Ltd',
    symbol: 'UFO.L',
  },
  {
    value: 'UJO.L',
    label: 'UJO.L,  Union Jack Oil plc',
    name: 'Union Jack Oil plc',
    symbol: 'UJO.L',
  },
  {
    value: 'UKCM.L',
    label: 'UKCM.L,  UK Commercial Property REIT Limited',
    name: 'UK Commercial Property REIT Limited',
    symbol: 'UKCM.L',
  },
  {
    value: 'UKOG.L',
    label: 'UKOG.L,  UK Oil & Gas PLC',
    name: 'UK Oil & Gas PLC',
    symbol: 'UKOG.L',
  },
  {
    value: 'UKR.L',
    label: 'UKR.L,  Ukrproduct Group Limited',
    name: 'Ukrproduct Group Limited',
    symbol: 'UKR.L',
  },
  {
    value: 'UKW.L',
    label: 'UKW.L,  Greencoat UK Wind PLC',
    name: 'Greencoat UK Wind PLC',
    symbol: 'UKW.L',
  },
  {
    value: 'ULVR.L',
    label: 'ULVR.L,  Unilever PLC',
    name: 'Unilever PLC',
    symbol: 'ULVR.L',
  },
  {
    value: 'UOG.L',
    label: 'UOG.L,  United Oil & Gas Plc',
    name: 'United Oil & Gas Plc',
    symbol: 'UOG.L',
  },
  {
    value: 'UPGS.L',
    label: 'UPGS.L,  UP Global Sourcing Holdings plc',
    name: 'UP Global Sourcing Holdings plc',
    symbol: 'UPGS.L',
  },
  {
    value: 'UPL.L',
    label: 'UPL.L,  Upland Resources Limited',
    name: 'Upland Resources Limited',
    symbol: 'UPL.L',
  },
  {
    value: 'UPR.L',
    label: 'UPR.L,  Uniphar plc',
    name: 'Uniphar plc',
    symbol: 'UPR.L',
  },
  {
    value: 'URU.L',
    label: 'URU.L,  URU Metals Limited',
    name: 'URU Metals Limited',
    symbol: 'URU.L',
  },
  {
    value: 'USA.L',
    label: 'USA.L,  Baillie Gifford US Growth Trust PLC',
    name: 'Baillie Gifford US Growth Trust PLC',
    symbol: 'USA.L',
  },
  {
    value: 'USF.L',
    label: 'USF.L,  US Solar Fund Plc',
    name: 'US Solar Fund Plc',
    symbol: 'USF.L',
  },
  {
    value: 'USFP.L',
    label: 'USFP.L,  US Solar Fund Plc',
    name: 'US Solar Fund Plc',
    symbol: 'USFP.L',
  },
  {
    value: 'UTG.L',
    label: 'UTG.L,  Unite Group Plc',
    name: 'Unite Group Plc',
    symbol: 'UTG.L',
  },
  {
    value: 'UTL.L',
    label: 'UTL.L,  UIL Limited',
    name: 'UIL Limited',
    symbol: 'UTL.L',
  },
  {
    value: 'VAL.L',
    label: 'VAL.L,  ValiRx plc',
    name: 'ValiRx plc',
    symbol: 'VAL.L',
  },
  {
    value: 'VANL.L',
    label: 'VANL.L,  Van Elle Holdings plc',
    name: 'Van Elle Holdings plc',
    symbol: 'VANL.L',
  },
  {
    value: 'VAST.L',
    label: 'VAST.L,  Vast Resources plc',
    name: 'Vast Resources plc',
    symbol: 'VAST.L',
  },
  {
    value: 'VCP.L',
    label: 'VCP.L,  Victoria PLC',
    name: 'Victoria PLC',
    symbol: 'VCP.L',
  },
  {
    value: 'VCT.L',
    label: 'VCT.L,  Victrex plc',
    name: 'Victrex plc',
    symbol: 'VCT.L',
  },
  {
    value: 'VDTK.L',
    label: 'VDTK.L,  Verditek PLC',
    name: 'Verditek PLC',
    symbol: 'VDTK.L',
  },
  {
    value: 'VEIL.L',
    label: 'VEIL.L,  Vietnam Enterprise Investments Limited',
    name: 'Vietnam Enterprise Investments Limited',
    symbol: 'VEIL.L',
  },
  {
    value: 'VEL.L',
    label: 'VEL.L,  Velocity Composites plc',
    name: 'Velocity Composites plc',
    symbol: 'VEL.L',
  },
  {
    value: 'VELA.L',
    label: 'VELA.L,  Vela Technologies PLC',
    name: 'Vela Technologies PLC',
    symbol: 'VELA.L',
  },
  {
    value: 'VLE.L',
    label: 'VLE.L,  Volvere plc',
    name: 'Volvere plc',
    symbol: 'VLE.L',
  },
  {
    value: 'VLG.L',
    label: 'VLG.L,  Venture Life Group plc',
    name: 'Venture Life Group plc',
    symbol: 'VLG.L',
  },
  {
    value: 'VLS.L',
    label: 'VLS.L,  Velocys plc',
    name: 'Velocys plc',
    symbol: 'VLS.L',
  },
  {
    value: 'VLX.L',
    label: 'VLX.L,  Volex plc',
    name: 'Volex plc',
    symbol: 'VLX.L',
  },
  {
    value: 'VMUK.L',
    label: 'VMUK.L,  Virgin Money UK PLC',
    name: 'Virgin Money UK PLC',
    symbol: 'VMUK.L',
  },
  {
    value: 'VNET.L',
    label: 'VNET.L,  Vianet Group plc',
    name: 'Vianet Group plc',
    symbol: 'VNET.L',
  },
  {
    value: 'VNH.L',
    label: 'VNH.L,  VietNam Holding Limited',
    name: 'VietNam Holding Limited',
    symbol: 'VNH.L',
  },
  {
    value: 'VOD.L',
    label: 'VOD.L,  Vodafone Group Public Limited Company',
    name: 'Vodafone Group Public Limited Company',
    symbol: 'VOD.L',
  },
  {
    value: 'VOF.L',
    label: 'VOF.L,  VinaCapital Vietnam Opportunity Fund Limited',
    name: 'VinaCapital Vietnam Opportunity Fund Limited',
    symbol: 'VOF.L',
  },
  {
    value: 'VRS.L',
    label: 'VRS.L,  Versarien plc',
    name: 'Versarien plc',
    symbol: 'VRS.L',
  },
  {
    value: 'VSL.L',
    label: 'VSL.L,  VPC Specialty Lending Investments PLC',
    name: 'VPC Specialty Lending Investments PLC',
    symbol: 'VSL.L',
  },
  {
    value: 'VSVS.L',
    label: 'VSVS.L,  Vesuvius plc',
    name: 'Vesuvius plc',
    symbol: 'VSVS.L',
  },
  {
    value: 'VTA.L',
    label: 'VTA.L,  Volta Finance Limited',
    name: 'Volta Finance Limited',
    symbol: 'VTA.L',
  },
  {
    value: 'VTAS.L',
    label: 'VTAS.L,  Volta Finance Limited',
    name: 'Volta Finance Limited',
    symbol: 'VTAS.L',
  },
  {
    value: 'VTU.L',
    label: 'VTU.L,  Vertu Motors plc',
    name: 'Vertu Motors plc',
    symbol: 'VTU.L',
  },
  {
    value: 'VTY.L',
    label: 'VTY.L,  Vistry Group PLC',
    name: 'Vistry Group PLC',
    symbol: 'VTY.L',
  },
  {
    value: 'W7L.L',
    label: 'W7L.L,  Warpaint London PLC',
    name: 'Warpaint London PLC',
    symbol: 'W7L.L',
  },
  {
    value: 'WAND.L',
    label: 'WAND.L,  WANdisco plc',
    name: 'WANdisco plc',
    symbol: 'WAND.L',
  },
  {
    value: 'WATR.L',
    label: 'WATR.L,  Water Intelligence plc',
    name: 'Water Intelligence plc',
    symbol: 'WATR.L',
  },
  {
    value: 'WBI.L',
    label: 'WBI.L,  Woodbois Limited',
    name: 'Woodbois Limited',
    symbol: 'WBI.L',
  },
  {
    value: 'WCW.L',
    label: 'WCW.L,  Walker Crips Group plc',
    name: 'Walker Crips Group plc',
    symbol: 'WCW.L',
  },
  {
    value: 'WEB.L',
    label: 'WEB.L,  Webis Holdings plc',
    name: 'Webis Holdings plc',
    symbol: 'WEB.L',
  },
  {
    value: 'WEIR.L',
    label: 'WEIR.L,  The Weir Group PLC',
    name: 'The Weir Group PLC',
    symbol: 'WEIR.L',
  },
  {
    value: 'WEN.L',
    label: 'WEN.L,  Wentworth Resources plc',
    name: 'Wentworth Resources plc',
    symbol: 'WEN.L',
  },
  {
    value: 'WHI.L',
    label: 'WHI.L,  WH Ireland Group plc',
    name: 'WH Ireland Group plc',
    symbol: 'WHI.L',
  },
  {
    value: 'WHR.L',
    label: 'WHR.L,  Warehouse REIT plc',
    name: 'Warehouse REIT plc',
    symbol: 'WHR.L',
  },
  {
    value: 'WIL.L',
    label: 'WIL.L,  Wilmington plc',
    name: 'Wilmington plc',
    symbol: 'WIL.L',
  },
  {
    value: 'WIN.L',
    label: 'WIN.L,  Wincanton plc',
    name: 'Wincanton plc',
    symbol: 'WIN.L',
  },
  {
    value: 'WINE.L',
    label: 'WINE.L,  Naked Wines plc',
    name: 'Naked Wines plc',
    symbol: 'WINE.L',
  },
  {
    value: 'WINK.L',
    label: 'WINK.L,  M Winkworth PLC',
    name: 'M Winkworth PLC',
    symbol: 'WINK.L',
  },
  {
    value: 'WINV.L',
    label: 'WINV.L,  Worsley Investors Limited',
    name: 'Worsley Investors Limited',
    symbol: 'WINV.L',
  },
  {
    value: 'WIZZ.L',
    label: 'WIZZ.L,  Wizz Air Holdings Plc',
    name: 'Wizz Air Holdings Plc',
    symbol: 'WIZZ.L',
  },
  {
    value: 'WJG.L',
    label: 'WJG.L,  Watkin Jones Plc',
    name: 'Watkin Jones Plc',
    symbol: 'WJG.L',
  },
  {
    value: 'WKOF.L',
    label: 'WKOF.L,  Weiss Korea Opportunity Fund Ltd.',
    name: 'Weiss Korea Opportunity Fund Ltd.',
    symbol: 'WKOF.L',
  },
  {
    value: 'WKP.L',
    label: 'WKP.L,  Workspace Group plc',
    name: 'Workspace Group plc',
    symbol: 'WKP.L',
  },
  {
    value: 'WOSG.L',
    label: 'WOSG.L,  Watches of Switzerland Group plc',
    name: 'Watches of Switzerland Group plc',
    symbol: 'WOSG.L',
  },
  {
    value: 'WPP.L',
    label: 'WPP.L,  WPP plc',
    name: 'WPP plc',
    symbol: 'WPP.L',
  },
  {
    value: 'WRKS.L',
    label: 'WRKS.L,  TheWorks.co.uk plc',
    name: 'TheWorks.co.uk plc',
    symbol: 'WRKS.L',
  },
  {
    value: 'WSBN.L',
    label: 'WSBN.L,  Wishbone Gold Plc',
    name: 'Wishbone Gold Plc',
    symbol: 'WSBN.L',
  },
  {
    value: 'WSG.L',
    label: 'WSG.L,  Westminster Group PLC',
    name: 'Westminster Group PLC',
    symbol: 'WSG.L',
  },
  {
    value: 'WSP.L',
    label: 'WSP.L,  Wynnstay Properties Plc',
    name: 'Wynnstay Properties Plc',
    symbol: 'WSP.L',
  },
  {
    value: 'WTAN.L',
    label: 'WTAN.L,  Witan Investment Trust plc',
    name: 'Witan Investment Trust plc',
    symbol: 'WTAN.L',
  },
  {
    value: 'WTB.L',
    label: 'WTB.L,  Whitbread plc',
    name: 'Whitbread plc',
    symbol: 'WTB.L',
  },
  {
    value: 'WTE.L',
    label: 'WTE.L,  Westmount Energy Limited',
    name: 'Westmount Energy Limited',
    symbol: 'WTE.L',
  },
  {
    value: 'WWH.L',
    label: 'WWH.L,  Worldwide Healthcare Trust PLC',
    name: 'Worldwide Healthcare Trust PLC',
    symbol: 'WWH.L',
  },
  {
    value: 'WYN.L',
    label: 'WYN.L,  Wynnstay Group Plc',
    name: 'Wynnstay Group Plc',
    symbol: 'WYN.L',
  },
  {
    value: 'XAR.L',
    label: 'XAR.L,  Xaar plc',
    name: 'Xaar plc',
    symbol: 'XAR.L',
  },
  {
    value: 'XLM.L',
    label: 'XLM.L,  XLMedia PLC',
    name: 'XLMedia PLC',
    symbol: 'XLM.L',
  },
  {
    value: 'XPD.L',
    label: 'XPD.L,  Xpediator Plc',
    name: 'Xpediator Plc',
    symbol: 'XPD.L',
  },
  {
    value: 'XPP.L',
    label: 'XPP.L,  XP Power Limited',
    name: 'XP Power Limited',
    symbol: 'XPP.L',
  },
  {
    value: 'XPS.L',
    label: 'XPS.L,  XPS Pensions Group plc',
    name: 'XPS Pensions Group plc',
    symbol: 'XPS.L',
  },
  {
    value: 'XSG.L',
    label: 'XSG.L,  Xeros Technology Group plc',
    name: 'Xeros Technology Group plc',
    symbol: 'XSG.L',
  },
  {
    value: 'XTR.L',
    label: 'XTR.L,  Xtract Resources Plc',
    name: 'Xtract Resources Plc',
    symbol: 'XTR.L',
  },
  {
    value: 'YCA.L',
    label: 'YCA.L,  Yellow Cake plc',
    name: 'Yellow Cake plc',
    symbol: 'YCA.L',
  },
  {
    value: 'YGEN.L',
    label: 'YGEN.L,  Yourgene Health Plc',
    name: 'Yourgene Health Plc',
    symbol: 'YGEN.L',
  },
  {
    value: 'YNGA.L',
    label: "YNGA.L,  Young & Co.'s Brewery, P.L.C.",
    name: "Young & Co.'s Brewery, P.L.C.",
    symbol: 'YNGA.L',
  },
  {
    value: 'YOU.L',
    label: 'YOU.L,  YouGov plc',
    name: 'YouGov plc',
    symbol: 'YOU.L',
  },
  {
    value: 'ZAM.L',
    label: 'ZAM.L,  Zambeef Products PLC',
    name: 'Zambeef Products PLC',
    symbol: 'ZAM.L',
  },
  {
    value: 'ZEG.L',
    label: 'ZEG.L,  Zegona Communications plc',
    name: 'Zegona Communications plc',
    symbol: 'ZEG.L',
  },
  {
    value: 'ZIN.L',
    label: 'ZIN.L,  Zinc Media Group plc',
    name: 'Zinc Media Group plc',
    symbol: 'ZIN.L',
  },
  {
    value: 'ZIOC.L',
    label: 'ZIOC.L,  Zanaga Iron Ore Company Limited',
    name: 'Zanaga Iron Ore Company Limited',
    symbol: 'ZIOC.L',
  },
  {
    value: 'ZOO.L',
    label: 'ZOO.L,  ZOO Digital Group plc',
    name: 'ZOO Digital Group plc',
    symbol: 'ZOO.L',
  },
  {
    value: 'ZTF.L',
    label: 'ZTF.L,  Zotefoams plc',
    name: 'Zotefoams plc',
    symbol: 'ZTF.L',
  },
  {
    value: 'ZYT.L',
    label: 'ZYT.L,  Zytronic plc',
    name: 'Zytronic plc',
    symbol: 'ZYT.L',
  },
  {
    value: '0A18.L',
    label: '0A18.L,  CGI Inc.',
    name: 'CGI Inc.',
    symbol: '0A18.L',
  },
  {
    value: '0A23.L',
    label: '0A23.L,  Phreesia, Inc.',
    name: 'Phreesia, Inc.',
    symbol: '0A23.L',
  },
  {
    value: '0AH3.L',
    label: '0AH3.L,  Great-West Lifeco Inc.',
    name: 'Great-West Lifeco Inc.',
    symbol: '0AH3.L',
  },
  {
    value: '0DLI.L',
    label: '0DLI.L,  Acomo N.V.',
    name: 'Acomo N.V.',
    symbol: '0DLI.L',
  },
  {
    value: '0ELV.L',
    label: '0ELV.L,  Guerbet SA',
    name: 'Guerbet SA',
    symbol: '0ELV.L',
  },
  {
    value: '0EWR.L',
    label: '0EWR.L,  init innovation in traffic systems SE',
    name: 'init innovation in traffic systems SE',
    symbol: '0EWR.L',
  },
  {
    value: '0F2S.L',
    label: '0F2S.L,  Linedata Services S.A.',
    name: 'Linedata Services S.A.',
    symbol: '0F2S.L',
  },
  {
    value: '0G67.L',
    label: '0G67.L,  Sparebanken Vest',
    name: 'Sparebanken Vest',
    symbol: '0G67.L',
  },
  {
    value: '0GFE.L',
    label: '0GFE.L,  Embracer Group AB (publ)',
    name: 'Embracer Group AB (publ)',
    symbol: '0GFE.L',
  },
  {
    value: '0H68.L',
    label: '0H68.L,  Aflac Incorporated',
    name: 'Aflac Incorporated',
    symbol: '0H68.L',
  },
  {
    value: '0H7S.L',
    label: '0H7S.L,  Abiomed, Inc.',
    name: 'Abiomed, Inc.',
    symbol: '0H7S.L',
  },
  {
    value: '0HA8.L',
    label: '0HA8.L,  Corporación Financiera Alba, S.A.',
    name: 'Corporación Financiera Alba, S.A.',
    symbol: '0HA8.L',
  },
  {
    value: '0HBH.L',
    label: '0HBH.L,  Air Products and Chemicals, Inc.',
    name: 'Air Products and Chemicals, Inc.',
    symbol: '0HBH.L',
  },
  {
    value: '0HCH.L',
    label: '0HCH.L,  Alexandria Real Estate Equities, Inc.',
    name: 'Alexandria Real Estate Equities, Inc.',
    symbol: '0HCH.L',
  },
  {
    value: '0HCZ.L',
    label: '0HCZ.L,  The Allstate Corporation',
    name: 'The Allstate Corporation',
    symbol: '0HCZ.L',
  },
  {
    value: '0HD2.L',
    label: '0HD2.L,  Alnylam Pharmaceuticals, Inc.',
    name: 'Alnylam Pharmaceuticals, Inc.',
    symbol: '0HD2.L',
  },
  {
    value: '0HF7.L',
    label: '0HF7.L,  AMETEK, Inc.',
    name: 'AMETEK, Inc.',
    symbol: '0HF7.L',
  },
  {
    value: '0HG8.L',
    label: '0HG8.L,  Elevance Health Inc.',
    name: 'Elevance Health Inc.',
    symbol: '0HG8.L',
  },
  {
    value: '0HGV.L',
    label: '0HGV.L,  Applied Optoelectronics, Inc.',
    name: 'Applied Optoelectronics, Inc.',
    symbol: '0HGV.L',
  },
  {
    value: '0HH6.L',
    label: '0HH6.L,  Aqua Metals, Inc.',
    name: 'Aqua Metals, Inc.',
    symbol: '0HH6.L',
  },
  {
    value: '0HJL.L',
    label: '0HJL.L,  AutoZone, Inc.',
    name: 'AutoZone, Inc.',
    symbol: '0HJL.L',
  },
  {
    value: '0HJR.L',
    label: '0HJR.L,  Avery Dennison Corporation',
    name: 'Avery Dennison Corporation',
    symbol: '0HJR.L',
  },
  {
    value: '0HL5.L',
    label: '0HL5.L,  Ball Corporation',
    name: 'Ball Corporation',
    symbol: '0HL5.L',
  },
  {
    value: '0HOB.L',
    label: '0HOB.L,  H&R Block, Inc.',
    name: 'H&R Block, Inc.',
    symbol: '0HOB.L',
  },
  {
    value: '0HOJ.L',
    label: '0HOJ.L,  Blueprint Medicines Corporation',
    name: 'Blueprint Medicines Corporation',
    symbol: '0HOJ.L',
  },
  {
    value: '0HQ3.L',
    label: '0HQ3.L,  Brown-Forman Corporation',
    name: 'Brown-Forman Corporation',
    symbol: '0HQ3.L',
  },
  {
    value: '0HST.L',
    label: '0HST.L,  Campbell Soup Company',
    name: 'Campbell Soup Company',
    symbol: '0HST.L',
  },
  {
    value: '0HSU.L',
    label: '0HSU.L,  Camping World Holdings, Inc.',
    name: 'Camping World Holdings, Inc.',
    symbol: '0HSU.L',
  },
  {
    value: '0HTC.L',
    label: '0HTC.L,  Cara Therapeutics, Inc.',
    name: 'Cara Therapeutics, Inc.',
    symbol: '0HTC.L',
  },
  {
    value: '0HTF.L',
    label: '0HTF.L,  Norwegian Energy Company ASA',
    name: 'Norwegian Energy Company ASA',
    symbol: '0HTF.L',
  },
  {
    value: '0HUR.L',
    label: '0HUR.L,  Celanese Corporation',
    name: 'Celanese Corporation',
    symbol: '0HUR.L',
  },
  {
    value: '0HW4.L',
    label: '0HW4.L,  Charter Communications, Inc.',
    name: 'Charter Communications, Inc.',
    symbol: '0HW4.L',
  },
  {
    value: '0HWH.L',
    label: '0HWH.L,  Cheniere Energy, Inc.',
    name: 'Cheniere Energy, Inc.',
    symbol: '0HWH.L',
  },
  {
    value: '0HYP.L',
    label: '0HYP.L,  Citizens Financial Group, Inc.',
    name: 'Citizens Financial Group, Inc.',
    symbol: '0HYP.L',
  },
  {
    value: '0I04.L',
    label: '0I04.L,  Clean Energy Fuels Corp.',
    name: 'Clean Energy Fuels Corp.',
    symbol: '0I04.L',
  },
  {
    value: '0I35.L',
    label: '0I35.L,  Consolidated Edison, Inc.',
    name: 'Consolidated Edison, Inc.',
    symbol: '0I35.L',
  },
  {
    value: '0I3Q.L',
    label: '0I3Q.L,  Corcept Therapeutics Incorporated',
    name: 'Corcept Therapeutics Incorporated',
    symbol: '0I3Q.L',
  },
  {
    value: '0I4Q.L',
    label: '0I4Q.L,  Wolfspeed, Inc.',
    name: 'Wolfspeed, Inc.',
    symbol: '0I4Q.L',
  },
  {
    value: '0I6U.L',
    label: '0I6U.L,  DXC Technology Company',
    name: 'DXC Technology Company',
    symbol: '0I6U.L',
  },
  {
    value: '0I77.L',
    label: '0I77.L,  Darden Restaurants, Inc.',
    name: 'Darden Restaurants, Inc.',
    symbol: '0I77.L',
  },
  {
    value: '0I7E.L',
    label: '0I7E.L,  DaVita Inc.',
    name: 'DaVita Inc.',
    symbol: '0I7E.L',
  },
  {
    value: '0IBC.L',
    label: '0IBC.L,  Discover Financial Services',
    name: 'Discover Financial Services',
    symbol: '0IBC.L',
  },
  {
    value: '0ID1.L',
    label: '0ID1.L,  Duke Energy Corporation',
    name: 'Duke Energy Corporation',
    symbol: '0ID1.L',
  },
  {
    value: '0IH3.L',
    label: '0IH3.L,  Energous Corporation',
    name: 'Energous Corporation',
    symbol: '0IH3.L',
  },
  {
    value: '0IHP.L',
    label: '0IHP.L,  Entergy Corporation',
    name: 'Entergy Corporation',
    symbol: '0IHP.L',
  },
  {
    value: '0IIB.L',
    label: '0IIB.L,  Equity Residential',
    name: 'Equity Residential',
    symbol: '0IIB.L',
  },
  {
    value: '0IJR.L',
    label: '0IJR.L,  Expeditors International of Washington, Inc.',
    name: 'Expeditors International of Washington, Inc.',
    symbol: '0IJR.L',
  },
  {
    value: '0ILL.L',
    label: '0ILL.L,  Laboratorios Farmaceuticos Rovi, S.A.',
    name: 'Laboratorios Farmaceuticos Rovi, S.A.',
    symbol: '0ILL.L',
  },
  {
    value: '0IM1.L',
    label: '0IM1.L,  Fifth Third Bancorp',
    name: 'Fifth Third Bancorp',
    symbol: '0IM1.L',
  },
  {
    value: '0IMT.L',
    label: '0IMT.L,  Photocure ASA',
    name: 'Photocure ASA',
    symbol: '0IMT.L',
  },
  {
    value: '0IPD.L',
    label: '0IPD.L,  Five Below, Inc.',
    name: 'Five Below, Inc.',
    symbol: '0IPD.L',
  },
  {
    value: '0IPN.L',
    label: '0IPN.L,  FLEETCOR Technologies, Inc.',
    name: 'FLEETCOR Technologies, Inc.',
    symbol: '0IPN.L',
  },
  {
    value: '0IPT.L',
    label: '0IPT.L,  Akastor ASA',
    name: 'Akastor ASA',
    symbol: '0IPT.L',
  },
  {
    value: '0IPY.L',
    label: '0IPY.L,  Aubay Société Anonyme',
    name: 'Aubay Société Anonyme',
    symbol: '0IPY.L',
  },
  {
    value: '0IRK.L',
    label: '0IRK.L,  Altarea SCA',
    name: 'Altarea SCA',
    symbol: '0IRK.L',
  },
  {
    value: '0IUX.L',
    label: '0IUX.L,  Genuine Parts Company',
    name: 'Genuine Parts Company',
    symbol: '0IUX.L',
  },
  {
    value: '0J1R.L',
    label: '0J1R.L,  HCA Healthcare, Inc.',
    name: 'HCA Healthcare, Inc.',
    symbol: '0J1R.L',
  },
  {
    value: '0J2X.L',
    label: '0J2X.L,  Hanesbrands Inc.',
    name: 'Hanesbrands Inc.',
    symbol: '0J2X.L',
  },
  {
    value: '0J2Z.L',
    label: '0J2Z.L,  Hannon Armstrong Sustainable Infrastructure Capital, Inc.',
    name: 'Hannon Armstrong Sustainable Infrastructure Capital, Inc.',
    symbol: '0J2Z.L',
  },
  {
    value: '0J4G.L',
    label: '0J4G.L,  Helmerich & Payne, Inc.',
    name: 'Helmerich & Payne, Inc.',
    symbol: '0J4G.L',
  },
  {
    value: '0J51.L',
    label: '0J51.L,  Hewlett Packard Enterprise Company',
    name: 'Hewlett Packard Enterprise Company',
    symbol: '0J51.L',
  },
  {
    value: '0J5Z.L',
    label: '0J5Z.L,  Hormel Foods Corporation',
    name: 'Hormel Foods Corporation',
    symbol: '0J5Z.L',
  },
  {
    value: '0J66.L',
    label: '0J66.L,  Host Hotels & Resorts, Inc.',
    name: 'Host Hotels & Resorts, Inc.',
    symbol: '0J66.L',
  },
  {
    value: '0J6Z.L',
    label: '0J6Z.L,  Humana Inc.',
    name: 'Humana Inc.',
    symbol: '0J6Z.L',
  },
  {
    value: '0J76.L',
    label: '0J76.L,  Huntington Ingalls Industries, Inc.',
    name: 'Huntington Ingalls Industries, Inc.',
    symbol: '0J76.L',
  },
  {
    value: '0J8W.L',
    label: '0J8W.L,  Illinois Tool Works Inc.',
    name: 'Illinois Tool Works Inc.',
    symbol: '0J8W.L',
  },
  {
    value: '0J9P.L',
    label: '0J9P.L,  Incyte Corporation',
    name: 'Incyte Corporation',
    symbol: '0J9P.L',
  },
  {
    value: '0JAV.L',
    label: '0JAV.L,  Insmed Incorporated',
    name: 'Insmed Incorporated',
    symbol: '0JAV.L',
  },
  {
    value: '0JDO.L',
    label: '0JDO.L,  Iridium Communications Inc.',
    name: 'Iridium Communications Inc.',
    symbol: '0JDO.L',
  },
  {
    value: '0JPH.L',
    label: '0JPH.L,  Juniper Networks, Inc.',
    name: 'Juniper Networks, Inc.',
    symbol: '0JPH.L',
  },
  {
    value: '0JQR.L',
    label: '0JQR.L,  KeyCorp',
    name: 'KeyCorp',
    symbol: '0JQR.L',
  },
  {
    value: '0JR1.L',
    label: '0JR1.L,  Kimco Realty Corporation',
    name: 'Kimco Realty Corporation',
    symbol: '0JR1.L',
  },
  {
    value: '0JR2.L',
    label: '0JR2.L,  Kinder Morgan, Inc.',
    name: 'Kinder Morgan, Inc.',
    symbol: '0JR2.L',
  },
  {
    value: '0JSI.L',
    label: '0JSI.L,  LGI Homes, Inc.',
    name: 'LGI Homes, Inc.',
    symbol: '0JSI.L',
  },
  {
    value: '0JU0.L',
    label: '0JU0.L,  Lennar Corporation',
    name: 'Lennar Corporation',
    symbol: '0JU0.L',
  },
  {
    value: '0JW9.L',
    label: '0JW9.L,  MEI Pharma, Inc.',
    name: 'MEI Pharma, Inc.',
    symbol: '0JW9.L',
  },
  {
    value: '0JXQ.L',
    label: '0JXQ.L,  Main Street Capital Corporation',
    name: 'Main Street Capital Corporation',
    symbol: '0JXQ.L',
  },
  {
    value: '0JYA.L',
    label: '0JYA.L,  Marathon Petroleum Corporation',
    name: 'Marathon Petroleum Corporation',
    symbol: '0JYA.L',
  },
  {
    value: '0JYM.L',
    label: '0JYM.L,  Markel Corporation',
    name: 'Markel Corporation',
    symbol: '0JYM.L',
  },
  {
    value: '0JZ1.L',
    label: '0JZ1.L,  Masco Corporation',
    name: 'Masco Corporation',
    symbol: '0JZ1.L',
  },
  {
    value: '0K1E.L',
    label: '0K1E.L,  Mid-America Apartment Communities, Inc.',
    name: 'Mid-America Apartment Communities, Inc.',
    symbol: '0K1E.L',
  },
  {
    value: '0K36.L',
    label: "0K36.L,  Moody's Corporation",
    name: "Moody's Corporation",
    symbol: '0K36.L',
  },
  {
    value: '0K3H.L',
    label: '0K3H.L,  Motorola Solutions, Inc.',
    name: 'Motorola Solutions, Inc.',
    symbol: '0K3H.L',
  },
  {
    value: '0K45.L',
    label: '0K45.L,  NCR Corporation',
    name: 'NCR Corporation',
    symbol: '0K45.L',
  },
  {
    value: '0K4C.L',
    label: '0K4C.L,  NRG Energy, Inc.',
    name: 'NRG Energy, Inc.',
    symbol: '0K4C.L',
  },
  {
    value: '0K50.L',
    label: '0K50.L,  National Beverage Corp.',
    name: 'National Beverage Corp.',
    symbol: '0K50.L',
  },
  {
    value: '0K6F.L',
    label: '0K6F.L,  NetApp, Inc.',
    name: 'NetApp, Inc.',
    symbol: '0K6F.L',
  },
  {
    value: '0K6M.L',
    label: '0K6M.L,  Netlist, Inc.',
    name: 'Netlist, Inc.',
    symbol: '0K6M.L',
  },
  {
    value: '0K8M.L',
    label: '0K8M.L,  Norfolk Southern Corporation',
    name: 'Norfolk Southern Corporation',
    symbol: '0K8M.L',
  },
  {
    value: '0KA8.L',
    label: '0KA8.L,  LogicMark, Inc.',
    name: 'LogicMark, Inc.',
    symbol: '0KA8.L',
  },
  {
    value: '0KBK.L',
    label: '0KBK.L,  Omnicom Group Inc.',
    name: 'Omnicom Group Inc.',
    symbol: '0KBK.L',
  },
  {
    value: '0KBL.L',
    label: '0KBL.L,  Omega Healthcare Investors, Inc.',
    name: 'Omega Healthcare Investors, Inc.',
    symbol: '0KBL.L',
  },
  {
    value: '0KC4.L',
    label: '0KC4.L,  ON Semiconductor Corporation',
    name: 'ON Semiconductor Corporation',
    symbol: '0KC4.L',
  },
  {
    value: '0KDH.L',
    label: '0KDH.L,  Ormat Technologies, Inc.',
    name: 'Ormat Technologies, Inc.',
    symbol: '0KDH.L',
  },
  {
    value: '0KEI.L',
    label: '0KEI.L,  PPG Industries, Inc.',
    name: 'PPG Industries, Inc.',
    symbol: '0KEI.L',
  },
  {
    value: '0KET.L',
    label: '0KET.L,  PACCAR Inc',
    name: 'PACCAR Inc',
    symbol: '0KET.L',
  },
  {
    value: '0KFH.L',
    label: '0KFH.L,  Revenio Group Oyj',
    name: 'Revenio Group Oyj',
    symbol: '0KFH.L',
  },
  {
    value: '0KIX.L',
    label: '0KIX.L,  Pioneer Natural Resources Company',
    name: 'Pioneer Natural Resources Company',
    symbol: '0KIX.L',
  },
  {
    value: '0KJD.L',
    label: '0KJD.L,  Planet Fitness, Inc.',
    name: 'Planet Fitness, Inc.',
    symbol: '0KJD.L',
  },
  {
    value: '0KOC.L',
    label: '0KOC.L,  The Progressive Corporation',
    name: 'The Progressive Corporation',
    symbol: '0KOC.L',
  },
  {
    value: '0KRX.L',
    label: '0KRX.L,  Prudential Financial, Inc.',
    name: 'Prudential Financial, Inc.',
    symbol: '0KRX.L',
  },
  {
    value: '0KS6.L',
    label: '0KS6.L,  PulteGroup, Inc.',
    name: 'PulteGroup, Inc.',
    symbol: '0KS6.L',
  },
  {
    value: '0KSR.L',
    label: '0KSR.L,  Quanta Services, Inc.',
    name: 'Quanta Services, Inc.',
    symbol: '0KSR.L',
  },
  {
    value: '0KTF.L',
    label: '0KTF.L,  RH',
    name: 'RH',
    symbol: '0KTF.L',
  },
  {
    value: '0KTI.L',
    label: '0KTI.L,  Enbridge Inc.',
    name: 'Enbridge Inc.',
    symbol: '0KTI.L',
  },
  {
    value: '0KTW.L',
    label: '0KTW.L,  Range Resources Corporation',
    name: 'Range Resources Corporation',
    symbol: '0KTW.L',
  },
  {
    value: '0KV3.L',
    label: '0KV3.L,  Regions Financial Corporation',
    name: 'Regions Financial Corporation',
    symbol: '0KV3.L',
  },
  {
    value: '0KW1.L',
    label: '0KW1.L,  Republic Services, Inc.',
    name: 'Republic Services, Inc.',
    symbol: '0KW1.L',
  },
  {
    value: '0KW8.L',
    label: '0KW8.L,  Resolute Forest Products Inc.',
    name: 'Resolute Forest Products Inc.',
    symbol: '0KW8.L',
  },
  {
    value: '0KXM.L',
    label: '0KXM.L,  Roper Technologies, Inc.',
    name: 'Roper Technologies, Inc.',
    symbol: '0KXM.L',
  },
  {
    value: '0KXO.L',
    label: '0KXO.L,  Ross Stores, Inc.',
    name: 'Ross Stores, Inc.',
    symbol: '0KXO.L',
  },
  {
    value: '0L98.L',
    label: '0L98.L,  STAG Industrial, Inc.',
    name: 'STAG Industrial, Inc.',
    symbol: '0L98.L',
  },
  {
    value: '0L9F.L',
    label: '0L9F.L,  Starwood Property Trust, Inc.',
    name: 'Starwood Property Trust, Inc.',
    symbol: '0L9F.L',
  },
  {
    value: '0LF8.L',
    label: '0LF8.L,  THOR Industries, Inc.',
    name: 'THOR Industries, Inc.',
    symbol: '0LF8.L',
  },
  {
    value: '0LFS.L',
    label: '0LFS.L,  Toll Brothers, Inc.',
    name: 'Toll Brothers, Inc.',
    symbol: '0LFS.L',
  },
  {
    value: '0LHR.L',
    label: '0LHR.L,  Tyson Foods, Inc.',
    name: 'Tyson Foods, Inc.',
    symbol: '0LHR.L',
  },
  {
    value: '0LHS.L',
    label: '0LHS.L,  UDR, Inc.',
    name: 'UDR, Inc.',
    symbol: '0LHS.L',
  },
  {
    value: '0LHY.L',
    label: '0LHY.L,  U.S. Bancorp',
    name: 'U.S. Bancorp',
    symbol: '0LHY.L',
  },
  {
    value: '0LIB.L',
    label: '0LIB.L,  Ulta Beauty, Inc.',
    name: 'Ulta Beauty, Inc.',
    symbol: '0LIB.L',
  },
  {
    value: '0LIY.L',
    label: '0LIY.L,  United Rentals, Inc.',
    name: 'United Rentals, Inc.',
    symbol: '0LIY.L',
  },
  {
    value: '0LJL.L',
    label: '0LJL.L,  Universal Health Services, Inc.',
    name: 'Universal Health Services, Inc.',
    symbol: '0LJL.L',
  },
  {
    value: '0LOZ.L',
    label: '0LOZ.L,  VeriSign, Inc.',
    name: 'VeriSign, Inc.',
    symbol: '0LOZ.L',
  },
  {
    value: '0LP3.L',
    label: '0LP3.L,  Verisk Analytics, Inc.',
    name: 'Verisk Analytics, Inc.',
    symbol: '0LP3.L',
  },
  {
    value: '0LVJ.L',
    label: '0LVJ.L,  The Western Union Company',
    name: 'The Western Union Company',
    symbol: '0LVJ.L',
  },
  {
    value: '0LW9.L',
    label: '0LW9.L,  WestRock Company',
    name: 'WestRock Company',
    symbol: '0LW9.L',
  },
  {
    value: '0M1R.L',
    label: '0M1R.L,  Xcel Energy Inc.',
    name: 'Xcel Energy Inc.',
    symbol: '0M1R.L',
  },
  {
    value: '0M2Q.L',
    label: '0M2Q.L,  Yext, Inc.',
    name: 'Yext, Inc.',
    symbol: '0M2Q.L',
  },
  {
    value: '0M58.L',
    label: '0M58.L,  Hollysys Automation Technologies Ltd.',
    name: 'Hollysys Automation Technologies Ltd.',
    symbol: '0M58.L',
  },
  {
    value: '0N8R.L',
    label: '0N8R.L,  Digital Bros S.p.A.',
    name: 'Digital Bros S.p.A.',
    symbol: '0N8R.L',
  },
  {
    value: '0NF3.L',
    label: '0NF3.L,  Paion AG',
    name: 'Paion AG',
    symbol: '0NF3.L',
  },
  {
    value: '0OHG.L',
    label: '0OHG.L,  NH Hotel Group, S.A.',
    name: 'NH Hotel Group, S.A.',
    symbol: '0OHG.L',
  },
  {
    value: '0OQQ.L',
    label: '0OQQ.L,  Infotel SA',
    name: 'Infotel SA',
    symbol: '0OQQ.L',
  },
  {
    value: '0OR2.L',
    label: '0OR2.L,  LNA Santé SA',
    name: 'LNA Santé SA',
    symbol: '0OR2.L',
  },
  {
    value: '0Q76.L',
    label: '0Q76.L,  CBo Territoria SA',
    name: 'CBo Territoria SA',
    symbol: '0Q76.L',
  },
  {
    value: '0Q82.L',
    label: '0Q82.L,  Core Laboratories N.V.',
    name: 'Core Laboratories N.V.',
    symbol: '0Q82.L',
  },
  {
    value: '0QHK.L',
    label: '0QHK.L,  SeSa S.p.A.',
    name: 'SeSa S.p.A.',
    symbol: '0QHK.L',
  },
  {
    value: '0QM2.L',
    label: '0QM2.L,  Berner Kantonalbank AG',
    name: 'Berner Kantonalbank AG',
    symbol: '0QM2.L',
  },
  {
    value: '0QN7.L',
    label: '0QN7.L,  Vaudoise Assurances Holding SA',
    name: 'Vaudoise Assurances Holding SA',
    symbol: '0QN7.L',
  },
  {
    value: '0QNA.L',
    label: '0QNA.L,  Basilea Pharmaceutica AG',
    name: 'Basilea Pharmaceutica AG',
    symbol: '0QNA.L',
  },
  {
    value: '0QNQ.L',
    label: '0QNQ.L,  Kudelski SA',
    name: 'Kudelski SA',
    symbol: '0QNQ.L',
  },
  {
    value: '0QOW.L',
    label: '0QOW.L,  Transocean Ltd.',
    name: 'Transocean Ltd.',
    symbol: '0QOW.L',
  },
  {
    value: '0QPH.L',
    label: '0QPH.L,  Swiss Steel Holding AG',
    name: 'Swiss Steel Holding AG',
    symbol: '0QPH.L',
  },
  {
    value: '0QS9.L',
    label: '0QS9.L,  eDreams ODIGEO S.A.',
    name: 'eDreams ODIGEO S.A.',
    symbol: '0QS9.L',
  },
  {
    value: '0QU6.L',
    label: '0QU6.L,  HIAG Immobilien Holding AG',
    name: 'HIAG Immobilien Holding AG',
    symbol: '0QU6.L',
  },
  {
    value: '0QUS.L',
    label: '0QUS.L,  G5 Entertainment AB (publ)',
    name: 'G5 Entertainment AB (publ)',
    symbol: '0QUS.L',
  },
  {
    value: '0QV2.L',
    label: '0QV2.L,  Bactiguard Holding AB (publ)',
    name: 'Bactiguard Holding AB (publ)',
    symbol: '0QV2.L',
  },
  {
    value: '0QV6.L',
    label: '0QV6.L,  7C Solarparken AG',
    name: '7C Solarparken AG',
    symbol: '0QV6.L',
  },
  {
    value: '0QZ2.L',
    label: '0QZ2.L,  Silvercorp Metals Inc.',
    name: 'Silvercorp Metals Inc.',
    symbol: '0QZ2.L',
  },
  {
    value: '0QZP.L',
    label: '0QZP.L,  Bombardier Inc.',
    name: 'Bombardier Inc.',
    symbol: '0QZP.L',
  },
  {
    value: '0R2S.L',
    label: '0R2S.L,  Stryker Corporation',
    name: 'Stryker Corporation',
    symbol: '0R2S.L',
  },
  {
    value: '0R30.L',
    label: '0R30.L,  V.F. Corporation',
    name: 'V.F. Corporation',
    symbol: '0R30.L',
  },
  {
    value: '0R38.L',
    label: '0R38.L,  iRobot Corporation',
    name: 'iRobot Corporation',
    symbol: '0R38.L',
  },
  {
    value: '0R3M.L',
    label: '0R3M.L,  Vivoryon Therapeutics N.V.',
    name: 'Vivoryon Therapeutics N.V.',
    symbol: '0R3M.L',
  },
  {
    value: '0R4W.L',
    label: '0R4W.L,  Multitude SE',
    name: 'Multitude SE',
    symbol: '0R4W.L',
  },
  {
    value: '0R6S.L',
    label: '0R6S.L,  Zehnder Group AG',
    name: 'Zehnder Group AG',
    symbol: '0R6S.L',
  },
  {
    value: '0RA9.L',
    label: '0RA9.L,  ABIVAX Société Anonyme',
    name: 'ABIVAX Société Anonyme',
    symbol: '0RA9.L',
  },
  {
    value: '0RF1.L',
    label: '0RF1.L,  WISeKey International Holding AG',
    name: 'WISeKey International Holding AG',
    symbol: '0RF1.L',
  },
  {
    value: '0RG7.L',
    label: '0RG7.L,  VP Bank AG',
    name: 'VP Bank AG',
    symbol: '0RG7.L',
  },
  {
    value: '0RJD.L',
    label: '0RJD.L,  va-Q-tec AG',
    name: 'va-Q-tec AG',
    symbol: '0RJD.L',
  },
  {
    value: '0RPS.L',
    label: '0RPS.L,  Medicover AB (publ)',
    name: 'Medicover AB (publ)',
    symbol: '0RPS.L',
  },
  {
    value: '0RPZ.L',
    label: '0RPZ.L,  HMS Networks AB (publ)',
    name: 'HMS Networks AB (publ)',
    symbol: '0RPZ.L',
  },
  {
    value: '0RQO.L',
    label: '0RQO.L,  Bonesupport Holding AB (publ)',
    name: 'Bonesupport Holding AB (publ)',
    symbol: '0RQO.L',
  },
  {
    value: '0RUU.L',
    label: '0RUU.L,  Starbreeze AB (publ)',
    name: 'Starbreeze AB (publ)',
    symbol: '0RUU.L',
  },
  {
    value: '0S10.L',
    label: '0S10.L,  BP Prudhoe Bay Royalty Trust',
    name: 'BP Prudhoe Bay Royalty Trust',
    symbol: '0S10.L',
  },
  {
    value: '0TIQ.L',
    label: '0TIQ.L,  Fair Isaac Corporation',
    name: 'Fair Isaac Corporation',
    symbol: '0TIQ.L',
  },
  {
    value: '0UAN.L',
    label: '0UAN.L,  Invesco Ltd.',
    name: 'Invesco Ltd.',
    symbol: '0UAN.L',
  },
  {
    value: '0UIK.L',
    label: '0UIK.L,  Argonaut Gold Inc.',
    name: 'Argonaut Gold Inc.',
    symbol: '0UIK.L',
  },
  {
    value: '0UNV.L',
    label: '0UNV.L,  CanAlaska Uranium Ltd.',
    name: 'CanAlaska Uranium Ltd.',
    symbol: '0UNV.L',
  },
  {
    value: '0UU0.L',
    label: '0UU0.L,  Energy Fuels Inc.',
    name: 'Energy Fuels Inc.',
    symbol: '0UU0.L',
  },
  {
    value: '0UVX.L',
    label: '0UVX.L,  Electra Battery Materials Corporation',
    name: 'Electra Battery Materials Corporation',
    symbol: '0UVX.L',
  },
  {
    value: '0V5H.L',
    label: '0V5H.L,  Manulife Financial Corporation',
    name: 'Manulife Financial Corporation',
    symbol: '0V5H.L',
  },
  {
    value: '0VBE.L',
    label: '0VBE.L,  Osisko Gold Royalties Ltd',
    name: 'Osisko Gold Royalties Ltd',
    symbol: '0VBE.L',
  },
  {
    value: '0VIK.L',
    label: '0VIK.L,  Standard Lithium Ltd.',
    name: 'Standard Lithium Ltd.',
    symbol: '0VIK.L',
  },
  {
    value: '0VL5.L',
    label: '0VL5.L,  Torex Gold Resources Inc.',
    name: 'Torex Gold Resources Inc.',
    symbol: '0VL5.L',
  },
  {
    value: '0VL8.L',
    label: '0VL8.L,  The Toronto-Dominion Bank',
    name: 'The Toronto-Dominion Bank',
    symbol: '0VL8.L',
  },
  {
    value: '0VNO.L',
    label: '0VNO.L,  Vista Gold Corp.',
    name: 'Vista Gold Corp.',
    symbol: '0VNO.L',
  },
  {
    value: '0VOU.L',
    label: '0VOU.L,  Varonis Systems, Inc.',
    name: 'Varonis Systems, Inc.',
    symbol: '0VOU.L',
  },
  {
    value: '0VQA.L',
    label: '0VQA.L,  Viking Therapeutics, Inc.',
    name: 'Viking Therapeutics, Inc.',
    symbol: '0VQA.L',
  },
  {
    value: '0Y5C.L',
    label: '0Y5C.L,  Allegion plc',
    name: 'Allegion plc',
    symbol: '0Y5C.L',
  },
  {
    value: '0Y5F.L',
    label: '0Y5F.L,  Endo International plc',
    name: 'Endo International plc',
    symbol: '0Y5F.L',
  },
  {
    value: '0Y7S.L',
    label: '0Y7S.L,  Johnson Controls International plc',
    name: 'Johnson Controls International plc',
    symbol: '0Y7S.L',
  },
  {
    value: '0YB1.L',
    label: '0YB1.L,  HUYA Inc.',
    name: 'HUYA Inc.',
    symbol: '0YB1.L',
  },
  {
    value: '0YU1.L',
    label: '0YU1.L,  Ambarella, Inc.',
    name: 'Ambarella, Inc.',
    symbol: '0YU1.L',
  },
  {
    value: '0ZNF.L',
    label: '0ZNF.L,  Turtle Beach Corporation',
    name: 'Turtle Beach Corporation',
    symbol: '0ZNF.L',
  },
  {
    value: 'KNM.L',
    label: 'KNM.L,  Konami Group Corporation',
    name: 'Konami Group Corporation',
    symbol: 'KNM.L',
  },
  {
    value: 'MEL.L',
    label: 'MEL.L,  Mitsubishi Electric Corporation',
    name: 'Mitsubishi Electric Corporation',
    symbol: 'MEL.L',
  },
  {
    value: '0CHZ.L',
    label: '0CHZ.L,  q.beyond AG',
    name: 'q.beyond AG',
    symbol: '0CHZ.L',
  },
  {
    value: '0DH7.L',
    label: '0DH7.L,  AF Gruppen ASA',
    name: 'AF Gruppen ASA',
    symbol: '0DH7.L',
  },
  {
    value: '0DI7.L',
    label: '0DI7.L,  AFC Ajax NV',
    name: 'AFC Ajax NV',
    symbol: '0DI7.L',
  },
  {
    value: '0DZJ.L',
    label: '0DZJ.L,  Groupe CRIT SA',
    name: 'Groupe CRIT SA',
    symbol: '0DZJ.L',
  },
  {
    value: '0E1L.L',
    label: '0E1L.L,  CapMan Oyj',
    name: 'CapMan Oyj',
    symbol: '0E1L.L',
  },
  {
    value: '0F6L.L',
    label: '0F6L.L,  Etablissements Maurel & Prom S.A.',
    name: 'Etablissements Maurel & Prom S.A.',
    symbol: '0F6L.L',
  },
  {
    value: '0FS8.L',
    label: '0FS8.L,  REC Silicon ASA',
    name: 'REC Silicon ASA',
    symbol: '0FS8.L',
  },
  {
    value: '0GZB.L',
    label: '0GZB.L,  Probi AB (publ)',
    name: 'Probi AB (publ)',
    symbol: '0GZB.L',
  },
  {
    value: '0HDK.L',
    label: '0HDK.L,  Systemair AB (publ)',
    name: 'Systemair AB (publ)',
    symbol: '0HDK.L',
  },
  {
    value: '0HJC.L',
    label: '0HJC.L,  Vilmorin & Cie SA',
    name: 'Vilmorin & Cie SA',
    symbol: '0HJC.L',
  },
  {
    value: '0HKM.L',
    label: '0HKM.L,  BGC Partners, Inc.',
    name: 'BGC Partners, Inc.',
    symbol: '0HKM.L',
  },
  {
    value: '0HN5.L',
    label: '0HN5.L,  Big Lots, Inc.',
    name: 'Big Lots, Inc.',
    symbol: '0HN5.L',
  },
  {
    value: '0HT4.L',
    label: '0HT4.L,  Capital One Financial Corporation',
    name: 'Capital One Financial Corporation',
    symbol: '0HT4.L',
  },
  {
    value: '0HTQ.L',
    label: '0HTQ.L,  CarMax, Inc.',
    name: 'CarMax, Inc.',
    symbol: '0HTQ.L',
  },
  {
    value: '0I1W.L',
    label: '0I1W.L,  Royal Caribbean Cruises Ltd.',
    name: 'Royal Caribbean Cruises Ltd.',
    symbol: '0I1W.L',
  },
  {
    value: '0IDU.L',
    label: '0IDU.L,  EQT Corporation',
    name: 'EQT Corporation',
    symbol: '0IDU.L',
  },
  {
    value: '0IJN.L',
    label: '0IJN.L,  Exelon Corporation',
    name: 'Exelon Corporation',
    symbol: '0IJN.L',
  },
  {
    value: '0IL6.L',
    label: '0IL6.L,  F5, Inc.',
    name: 'F5, Inc.',
    symbol: '0IL6.L',
  },
  {
    value: '0IPB.L',
    label: '0IPB.L,  FirstEnergy Corp.',
    name: 'FirstEnergy Corp.',
    symbol: '0IPB.L',
  },
  {
    value: '0IQE.L',
    label: '0IQE.L,  Flowserve Corporation',
    name: 'Flowserve Corporation',
    symbol: '0IQE.L',
  },
  {
    value: '0ISM.L',
    label: '0ISM.L,  HKScan Oyj',
    name: 'HKScan Oyj',
    symbol: '0ISM.L',
  },
  {
    value: '0ITV.L',
    label: '0ITV.L,  Gartner, Inc.',
    name: 'Gartner, Inc.',
    symbol: '0ITV.L',
  },
  {
    value: '0IUV.L',
    label: '0IUV.L,  TotalEnergies EP Gabon Société anonyme',
    name: 'TotalEnergies EP Gabon Société anonyme',
    symbol: '0IUV.L',
  },
  {
    value: '0IW3.L',
    label: '0IW3.L,  Global Net Lease, Inc.',
    name: 'Global Net Lease, Inc.',
    symbol: '0IW3.L',
  },
  {
    value: '0IWV.L',
    label: '0IWV.L,  Van de Velde NV',
    name: 'Van de Velde NV',
    symbol: '0IWV.L',
  },
  {
    value: '0JCK.L',
    label: '0JCK.L,  The Interpublic Group of Companies, Inc.',
    name: 'The Interpublic Group of Companies, Inc.',
    symbol: '0JCK.L',
  },
  {
    value: '0JV3.L',
    label: '0JV3.L,  Lincoln National Corporation',
    name: 'Lincoln National Corporation',
    symbol: '0JV3.L',
  },
  {
    value: '0K7U.L',
    label: '0K7U.L,  News Corporation',
    name: 'News Corporation',
    symbol: '0K7U.L',
  },
  {
    value: '0KRR.L',
    label: '0KRR.L,  Proto Labs, Inc.',
    name: 'Proto Labs, Inc.',
    symbol: '0KRR.L',
  },
  {
    value: '0LD9.L',
    label: '0LD9.L,  Targa Resources Corp.',
    name: 'Targa Resources Corp.',
    symbol: '0LD9.L',
  },
  {
    value: '0LJ9.L',
    label: '0LJ9.L,  United States Steel Corporation',
    name: 'United States Steel Corporation',
    symbol: '0LJ9.L',
  },
  {
    value: '0M1O.L',
    label: '0M1O.L,  XPO Logistics, Inc.',
    name: 'XPO Logistics, Inc.',
    symbol: '0M1O.L',
  },
  {
    value: '0MKP.L',
    label: '0MKP.L,  Strabag SE',
    name: 'Strabag SE',
    symbol: '0MKP.L',
  },
  {
    value: '0N0B.L',
    label: '0N0B.L,  Wallenius Wilhelmsen ASA',
    name: 'Wallenius Wilhelmsen ASA',
    symbol: '0N0B.L',
  },
  {
    value: '0N5I.L',
    label: '0N5I.L,  adesso SE',
    name: 'adesso SE',
    symbol: '0N5I.L',
  },
  {
    value: '0NNU.L',
    label: '0NNU.L,  Nedap N.V.',
    name: 'Nedap N.V.',
    symbol: '0NNU.L',
  },
  {
    value: '0O1O.L',
    label: '0O1O.L,  Vetoquinol SA',
    name: 'Vetoquinol SA',
    symbol: '0O1O.L',
  },
  {
    value: '0O2W.L',
    label: '0O2W.L,  GFT Technologies SE',
    name: 'GFT Technologies SE',
    symbol: '0O2W.L',
  },
  {
    value: '0ONY.L',
    label: '0ONY.L,  Tecnotree Oyj',
    name: 'Tecnotree Oyj',
    symbol: '0ONY.L',
  },
  {
    value: '0P3V.L',
    label: '0P3V.L,  Canada Silver Cobalt Works Inc.',
    name: 'Canada Silver Cobalt Works Inc.',
    symbol: '0P3V.L',
  },
  {
    value: '0Q77.L',
    label: '0Q77.L,  AB Science S.A.',
    name: 'AB Science S.A.',
    symbol: '0Q77.L',
  },
  {
    value: '0QF8.L',
    label: '0QF8.L,  Aktia Pankki Oyj',
    name: 'Aktia Pankki Oyj',
    symbol: '0QF8.L',
  },
  {
    value: '0QI9.L',
    label: '0QI9.L,  Creades AB',
    name: 'Creades AB',
    symbol: '0QI9.L',
  },
  {
    value: '0QKU.L',
    label: '0QKU.L,  Royal Bank of Canada',
    name: 'Royal Bank of Canada',
    symbol: '0QKU.L',
  },
  {
    value: '0QLL.L',
    label: '0QLL.L,  The Goodyear Tire & Rubber Company',
    name: 'The Goodyear Tire & Rubber Company',
    symbol: '0QLL.L',
  },
  {
    value: '0QLM.L',
    label: '0QLM.L,  Feintool International Holding AG',
    name: 'Feintool International Holding AG',
    symbol: '0QLM.L',
  },
  {
    value: '0QN1.L',
    label: '0QN1.L,  Santhera Pharmaceuticals Holding AG',
    name: 'Santhera Pharmaceuticals Holding AG',
    symbol: '0QN1.L',
  },
  {
    value: '0QNN.L',
    label: '0QNN.L,  Burckhardt Compression Holding AG',
    name: 'Burckhardt Compression Holding AG',
    symbol: '0QNN.L',
  },
  {
    value: '0QPR.L',
    label: '0QPR.L,  Cicor Technologies Ltd.',
    name: 'Cicor Technologies Ltd.',
    symbol: '0QPR.L',
  },
  {
    value: '0QSS.L',
    label: '0QSS.L,  ERYTECH Pharma S.A.',
    name: 'ERYTECH Pharma S.A.',
    symbol: '0QSS.L',
  },
  {
    value: '0QT0.L',
    label: '0QT0.L,  lastminute.com N.V.',
    name: 'lastminute.com N.V.',
    symbol: '0QT0.L',
  },
  {
    value: '0QYY.L',
    label: '0QYY.L,  Harley-Davidson, Inc.',
    name: 'Harley-Davidson, Inc.',
    symbol: '0QYY.L',
  },
  {
    value: '0R06.L',
    label: '0R06.L,  First Solar, Inc.',
    name: 'First Solar, Inc.',
    symbol: '0R06.L',
  },
  {
    value: '0R6E.L',
    label: '0R6E.L,  Lucas Bols N.V.',
    name: 'Lucas Bols N.V.',
    symbol: '0R6E.L',
  },
  {
    value: '0R8F.L',
    label: '0R8F.L,  Eolus Vind AB (publ)',
    name: 'Eolus Vind AB (publ)',
    symbol: '0R8F.L',
  },
  {
    value: '0R9T.L',
    label: '0R9T.L,  Equasens Société anonyme',
    name: 'Equasens Société anonyme',
    symbol: '0R9T.L',
  },
  {
    value: '0RDB.L',
    label: '0RDB.L,  Oryzon Genomics S.A.',
    name: 'Oryzon Genomics S.A.',
    symbol: '0RDB.L',
  },
  {
    value: '0VCO.L',
    label: '0VCO.L,  Peyto Exploration & Development Corp.',
    name: 'Peyto Exploration & Development Corp.',
    symbol: '0VCO.L',
  },
  {
    value: '0VFA.L',
    label: '0VFA.L,  Restaurant Brands International Inc.',
    name: 'Restaurant Brands International Inc.',
    symbol: '0VFA.L',
  },
  {
    value: '0VJA.L',
    label: '0VJA.L,  Sun Life Financial Inc.',
    name: 'Sun Life Financial Inc.',
    symbol: '0VJA.L',
  },
  {
    value: 'PXEN.L',
    label: 'PXEN.L,  Prospex Energy PLC',
    name: 'Prospex Energy PLC',
    symbol: 'PXEN.L',
  },
  {
    value: '0A05.L',
    label: '0A05.L,  Medacta Group SA',
    name: 'Medacta Group SA',
    symbol: '0A05.L',
  },
  {
    value: '0A39.L',
    label: '0A39.L,  Karnov Group AB (publ)',
    name: 'Karnov Group AB (publ)',
    symbol: '0A39.L',
  },
  {
    value: '0DK7.L',
    label: '0DK7.L,  eQ Oyj',
    name: 'eQ Oyj',
    symbol: '0DK7.L',
  },
  {
    value: '0DSJ.L',
    label: '0DSJ.L,  NRC Group ASA',
    name: 'NRC Group ASA',
    symbol: '0DSJ.L',
  },
  {
    value: '0E9V.L',
    label: '0E9V.L,  Energiekontor AG',
    name: 'Energiekontor AG',
    symbol: '0E9V.L',
  },
  {
    value: '0EE0.L',
    label: '0EE0.L,  uniQure N.V.',
    name: 'uniQure N.V.',
    symbol: '0EE0.L',
  },
  {
    value: '0F0J.L',
    label: '0F0J.L,  Kitron ASA',
    name: 'Kitron ASA',
    symbol: '0F0J.L',
  },
  {
    value: '0F8V.L',
    label: '0F8V.L,  AKWEL',
    name: 'AKWEL',
    symbol: '0F8V.L',
  },
  {
    value: '0FHP.L',
    label: '0FHP.L,  Olav Thon Eiendomsselskap ASA',
    name: 'Olav Thon Eiendomsselskap ASA',
    symbol: '0FHP.L',
  },
  {
    value: '0FM2.L',
    label: '0FM2.L,  Picanol nv',
    name: 'Picanol nv',
    symbol: '0FM2.L',
  },
  {
    value: '0FQ8.L',
    label: '0FQ8.L,  Pharol, SGPS S.A.',
    name: 'Pharol, SGPS S.A.',
    symbol: '0FQ8.L',
  },
  {
    value: '0G99.L',
    label: '0G99.L,  Oxurion NV',
    name: 'Oxurion NV',
    symbol: '0G99.L',
  },
  {
    value: '0GMG.L',
    label: '0GMG.L,  Addnode Group AB (publ)',
    name: 'Addnode Group AB (publ)',
    symbol: '0GMG.L',
  },
  {
    value: '0GN6.L',
    label: '0GN6.L,  Argan SA',
    name: 'Argan SA',
    symbol: '0GN6.L',
  },
  {
    value: '0H14.L',
    label: '0H14.L,  Net Insight AB (publ)',
    name: 'Net Insight AB (publ)',
    symbol: '0H14.L',
  },
  {
    value: '0H38.L',
    label: '0H38.L,  Pricer AB (publ)',
    name: 'Pricer AB (publ)',
    symbol: '0H38.L',
  },
  {
    value: '0H7R.L',
    label: '0H7R.L,  Abeona Therapeutics Inc.',
    name: 'Abeona Therapeutics Inc.',
    symbol: '0H7R.L',
  },
  {
    value: '0H9G.L',
    label: '0H9G.L,  Advance Auto Parts, Inc.',
    name: 'Advance Auto Parts, Inc.',
    symbol: '0H9G.L',
  },
  {
    value: '0HAV.L',
    label: '0HAV.L,  Agilent Technologies, Inc.',
    name: 'Agilent Technologies, Inc.',
    symbol: '0HAV.L',
  },
  {
    value: '0HC2.L',
    label: '0HC2.L,  Alarm.com Holdings, Inc.',
    name: 'Alarm.com Holdings, Inc.',
    symbol: '0HC2.L',
  },
  {
    value: '0HCK.L',
    label: '0HCK.L,  Align Technology, Inc.',
    name: 'Align Technology, Inc.',
    symbol: '0HCK.L',
  },
  {
    value: '0HCT.L',
    label: '0HCT.L,  Alliant Energy Corporation',
    name: 'Alliant Energy Corporation',
    symbol: '0HCT.L',
  },
  {
    value: '0HDQ.L',
    label: '0HDQ.L,  Synergie SE',
    name: 'Synergie SE',
    symbol: '0HDQ.L',
  },
  {
    value: '0HE2.L',
    label: '0HE2.L,  Ameren Corporation',
    name: 'Ameren Corporation',
    symbol: '0HE2.L',
  },
  {
    value: '0HEW.L',
    label: '0HEW.L,  American Water Works Company, Inc.',
    name: 'American Water Works Company, Inc.',
    symbol: '0HEW.L',
  },
  {
    value: '0HMZ.L',
    label: '0HMZ.L,  W. R. Berkley Corporation',
    name: 'W. R. Berkley Corporation',
    symbol: '0HMZ.L',
  },
  {
    value: '0HOX.L',
    label: '0HOX.L,  Boston Properties, Inc.',
    name: 'Boston Properties, Inc.',
    symbol: '0HOX.L',
  },
  {
    value: '0HQ7.L',
    label: '0HQ7.L,  The Buckle, Inc.',
    name: 'The Buckle, Inc.',
    symbol: '0HQ7.L',
  },
  {
    value: '0HQP.L',
    label: '0HQP.L,  CBRE Group, Inc.',
    name: 'CBRE Group, Inc.',
    symbol: '0HQP.L',
  },
  {
    value: '0HQU.L',
    label: '0HQU.L,  CF Industries Holdings, Inc.',
    name: 'CF Industries Holdings, Inc.',
    symbol: '0HQU.L',
  },
  {
    value: '0HR4.L',
    label: '0HR4.L,  CMS Energy Corporation',
    name: 'CMS Energy Corporation',
    symbol: '0HR4.L',
  },
  {
    value: '0HVA.L',
    label: '0HVA.L,  Haulotte Group SA',
    name: 'Haulotte Group SA',
    symbol: '0HVA.L',
  },
  {
    value: '0HYE.L',
    label: '0HYE.L,  Cincinnati Financial Corporation',
    name: 'Cincinnati Financial Corporation',
    symbol: '0HYE.L',
  },
  {
    value: '0I1P.L',
    label: '0I1P.L,  Comerica Incorporated',
    name: 'Comerica Incorporated',
    symbol: '0I1P.L',
  },
  {
    value: '0I2P.L',
    label: '0I2P.L,  Conagra Brands, Inc.',
    name: 'Conagra Brands, Inc.',
    symbol: '0I2P.L',
  },
  {
    value: '0I4T.L',
    label: '0I4T.L,  Criteo S.A.',
    name: 'Criteo S.A.',
    symbol: '0I4T.L',
  },
  {
    value: '0I5P.L',
    label: '0I5P.L,  CymaBay Therapeutics, Inc.',
    name: 'CymaBay Therapeutics, Inc.',
    symbol: '0I5P.L',
  },
  {
    value: '0I74.L',
    label: '0I74.L,  Daqo New Energy Corp.',
    name: 'Daqo New Energy Corp.',
    symbol: '0I74.L',
  },
  {
    value: '0I8W.L',
    label: '0I8W.L,  Devon Energy Corporation',
    name: 'Devon Energy Corporation',
    symbol: '0I8W.L',
  },
  {
    value: '0IFJ.L',
    label: '0IFJ.L,  Edison International',
    name: 'Edison International',
    symbol: '0IFJ.L',
  },
  {
    value: '0II3.L',
    label: '0II3.L,  Equifax Inc.',
    name: 'Equifax Inc.',
    symbol: '0II3.L',
  },
  {
    value: '0IJ2.L',
    label: '0IJ2.L,  Eversource Energy',
    name: 'Eversource Energy',
    symbol: '0IJ2.L',
  },
  {
    value: '0IJO.L',
    label: '0IJO.L,  Exelixis, Inc.',
    name: 'Exelixis, Inc.',
    symbol: '0IJO.L',
  },
  {
    value: '0IQK.L',
    label: '0IQK.L,  Foot Locker, Inc.',
    name: 'Foot Locker, Inc.',
    symbol: '0IQK.L',
  },
  {
    value: '0IX0.L',
    label: '0IX0.L,  GL Events SA',
    name: 'GL Events SA',
    symbol: '0IX0.L',
  },
  {
    value: '0J3H.L',
    label: '0J3H.L,  The Hartford Financial Services Group, Inc.',
    name: 'The Hartford Financial Services Group, Inc.',
    symbol: '0J3H.L',
  },
  {
    value: '0J4M.L',
    label: '0J4M.L,  Hercules Capital, Inc.',
    name: 'Hercules Capital, Inc.',
    symbol: '0J4M.L',
  },
  {
    value: '0J50.L',
    label: '0J50.L,  Hess Corporation',
    name: 'Hess Corporation',
    symbol: '0J50.L',
  },
  {
    value: '0J72.L',
    label: '0J72.L,  Huntington Bancshares Incorporated',
    name: 'Huntington Bancshares Incorporated',
    symbol: '0J72.L',
  },
  {
    value: '0JCB.L',
    label: '0JCB.L,  International Paper Company',
    name: 'International Paper Company',
    symbol: '0JCB.L',
  },
  {
    value: '0JDM.L',
    label: '0JDM.L,  IQVIA Holdings Inc.',
    name: 'IQVIA Holdings Inc.',
    symbol: '0JDM.L',
  },
  {
    value: '0JE5.L',
    label: '0JE5.L,  AMSC ASA',
    name: 'AMSC ASA',
    symbol: '0JE5.L',
  },
  {
    value: '0JI9.L',
    label: '0JI9.L,  Ensurge Micropower ASA',
    name: 'Ensurge Micropower ASA',
    symbol: '0JI9.L',
  },
  {
    value: '0JOI.L',
    label: '0JOI.L,  Jacobs Solutions Inc.',
    name: 'Jacobs Solutions Inc.',
    symbol: '0JOI.L',
  },
  {
    value: '0JQQ.L',
    label: '0JQQ.L,  Archer-Daniels-Midland Company',
    name: 'Archer-Daniels-Midland Company',
    symbol: '0JQQ.L',
  },
  {
    value: '0JRL.L',
    label: "0JRL.L,  Kohl's Corporation",
    name: "Kohl's Corporation",
    symbol: '0JRL.L',
  },
  {
    value: '0JSC.L',
    label: '0JSC.L,  Bath & Body Works, Inc.',
    name: 'Bath & Body Works, Inc.',
    symbol: '0JSC.L',
  },
  {
    value: '0JSJ.L',
    label: '0JSJ.L,  LKQ Corporation',
    name: 'LKQ Corporation',
    symbol: '0JSJ.L',
  },
  {
    value: '0JSP.L',
    label: '0JSP.L,  LTC Properties, Inc.',
    name: 'LTC Properties, Inc.',
    symbol: '0JSP.L',
  },
  {
    value: '0JVI.L',
    label: '0JVI.L,  Loews Corporation',
    name: 'Loews Corporation',
    symbol: '0JVI.L',
  },
  {
    value: '0JZ0.L',
    label: '0JZ0.L,  Martin Marietta Materials, Inc.',
    name: 'Martin Marietta Materials, Inc.',
    symbol: '0JZ0.L',
  },
  {
    value: '0JZU.L',
    label: '0JZU.L,  McKesson Corporation',
    name: 'McKesson Corporation',
    symbol: '0JZU.L',
  },
  {
    value: '0K10.L',
    label: '0K10.L,  Mettler-Toledo International Inc.',
    name: 'Mettler-Toledo International Inc.',
    symbol: '0K10.L',
  },
  {
    value: '0K19.L',
    label: '0K19.L,  Microchip Technology Incorporated',
    name: 'Microchip Technology Incorporated',
    symbol: '0K19.L',
  },
  {
    value: '0K2F.L',
    label: '0K2F.L,  Mohawk Industries, Inc.',
    name: 'Mohawk Industries, Inc.',
    symbol: '0K2F.L',
  },
  {
    value: '0K79.L',
    label: '0K79.L,  New Relic, Inc.',
    name: 'New Relic, Inc.',
    symbol: '0K79.L',
  },
  {
    value: '0K7J.L',
    label: '0K7J.L,  Newell Brands Inc.',
    name: 'Newell Brands Inc.',
    symbol: '0K7J.L',
  },
  {
    value: '0K87.L',
    label: '0K87.L,  NiSource Inc.',
    name: 'NiSource Inc.',
    symbol: '0K87.L',
  },
  {
    value: '0KAN.L',
    label: '0KAN.L,  Oceaneering International, Inc.',
    name: 'Oceaneering International, Inc.',
    symbol: '0KAN.L',
  },
  {
    value: '0KAV.L',
    label: '0KAV.L,  Arnoldo Mondadori Editore S.p.A.',
    name: 'Arnoldo Mondadori Editore S.p.A.',
    symbol: '0KAV.L',
  },
  {
    value: '0KCC.L',
    label: '0KCC.L,  OncoCyte Corporation',
    name: 'OncoCyte Corporation',
    symbol: '0KCC.L',
  },
  {
    value: '0KD1.L',
    label: '0KD1.L,  Tubacex, S.A.',
    name: 'Tubacex, S.A.',
    symbol: '0KD1.L',
  },
  {
    value: '0KEJ.L',
    label: '0KEJ.L,  PPL Corporation',
    name: 'PPL Corporation',
    symbol: '0KEJ.L',
  },
  {
    value: '0KF3.L',
    label: '0KF3.L,  Palatin Technologies, Inc.',
    name: 'Palatin Technologies, Inc.',
    symbol: '0KF3.L',
  },
  {
    value: '0KGE.L',
    label: '0KGE.L,  Paychex, Inc.',
    name: 'Paychex, Inc.',
    symbol: '0KGE.L',
  },
  {
    value: '0KJQ.L',
    label: '0KJQ.L,  Polaris Inc.',
    name: 'Polaris Inc.',
    symbol: '0KJQ.L',
  },
  {
    value: '0KU1.L',
    label: '0KU1.L,  Raymond James Financial, Inc.',
    name: 'Raymond James Financial, Inc.',
    symbol: '0KU1.L',
  },
  {
    value: '0L4F.L',
    label: '0L4F.L,  Sealed Air Corporation',
    name: 'Sealed Air Corporation',
    symbol: '0L4F.L',
  },
  {
    value: '0L7F.L',
    label: '0L7F.L,  The J. M. Smucker Company',
    name: 'The J. M. Smucker Company',
    symbol: '0L7F.L',
  },
  {
    value: '0LB2.L',
    label: '0LB2.L,  Supernus Pharmaceuticals, Inc.',
    name: 'Supernus Pharmaceuticals, Inc.',
    symbol: '0LB2.L',
  },
  {
    value: '0LD4.L',
    label: '0LD4.L,  Tanger Factory Outlet Centers, Inc.',
    name: 'Tanger Factory Outlet Centers, Inc.',
    symbol: '0LD4.L',
  },
  {
    value: '0LF0.L',
    label: '0LF0.L,  Textron Inc.',
    name: 'Textron Inc.',
    symbol: '0LF0.L',
  },
  {
    value: '0LRK.L',
    label: '0LRK.L,  Vulcan Materials Company',
    name: 'Vulcan Materials Company',
    symbol: '0LRK.L',
  },
  {
    value: '0LSL.L',
    label: '0LSL.L,  WEC Energy Group, Inc.',
    name: 'WEC Energy Group, Inc.',
    symbol: '0LSL.L',
  },
  {
    value: '0LUS.L',
    label: '0LUS.L,  Welltower Inc.',
    name: 'Welltower Inc.',
    symbol: '0LUS.L',
  },
  {
    value: '0LVK.L',
    label: '0LVK.L,  Westlake Corporation',
    name: 'Westlake Corporation',
    symbol: '0LVK.L',
  },
  {
    value: '0LWG.L',
    label: '0LWG.L,  Weyerhaeuser Company',
    name: 'Weyerhaeuser Company',
    symbol: '0LWG.L',
  },
  {
    value: '0LWH.L',
    label: '0LWH.L,  Whirlpool Corporation',
    name: 'Whirlpool Corporation',
    symbol: '0LWH.L',
  },
  {
    value: '0LXC.L',
    label: '0LXC.L,  Williams-Sonoma, Inc.',
    name: 'Williams-Sonoma, Inc.',
    symbol: '0LXC.L',
  },
  {
    value: '0M3L.L',
    label: '0M3L.L,  Zions Bancorporation, National Association',
    name: 'Zions Bancorporation, National Association',
    symbol: '0M3L.L',
  },
  {
    value: '0N08.L',
    label: '0N08.L,  Panoro Energy ASA',
    name: 'Panoro Energy ASA',
    symbol: '0N08.L',
  },
  {
    value: '0N9V.L',
    label: '0N9V.L,  Esso S.A.F.',
    name: 'Esso S.A.F.',
    symbol: '0N9V.L',
  },
  {
    value: '0NDP.L',
    label: '0NDP.L,  MLP SE',
    name: 'MLP SE',
    symbol: '0NDP.L',
  },
  {
    value: '0NFS.L',
    label: '0NFS.L,  Esprinet S.p.A.',
    name: 'Esprinet S.p.A.',
    symbol: '0NFS.L',
  },
  {
    value: '0NJB.L',
    label: '0NJB.L,  SNP Schneider-Neureither & Partner SE',
    name: 'SNP Schneider-Neureither & Partner SE',
    symbol: '0NJB.L',
  },
  {
    value: '0NWC.L',
    label: '0NWC.L,  secunet Security Networks Aktiengesellschaft',
    name: 'secunet Security Networks Aktiengesellschaft',
    symbol: '0NWC.L',
  },
  {
    value: '0NY3.L',
    label: '0NY3.L,  STEF SA',
    name: 'STEF SA',
    symbol: '0NY3.L',
  },
  {
    value: '0O2B.L',
    label: '0O2B.L,  Gruppo MutuiOnline S.p.A',
    name: 'Gruppo MutuiOnline S.p.A',
    symbol: '0O2B.L',
  },
  {
    value: '0O8G.L',
    label: '0O8G.L,  MDxHealth SA',
    name: 'MDxHealth SA',
    symbol: '0O8G.L',
  },
  {
    value: '0OCQ.L',
    label: '0OCQ.L,  Transgene SA',
    name: 'Transgene SA',
    symbol: '0OCQ.L',
  },
  {
    value: '0OGG.L',
    label: '0OGG.L,  SA Catana Group',
    name: 'SA Catana Group',
    symbol: '0OGG.L',
  },
  {
    value: '0OPJ.L',
    label: '0OPJ.L,  ABC arbitrage SA',
    name: 'ABC arbitrage SA',
    symbol: '0OPJ.L',
  },
  {
    value: '0OQX.L',
    label: '0OQX.L,  Grammer AG',
    name: 'Grammer AG',
    symbol: '0OQX.L',
  },
  {
    value: '0P2J.L',
    label: '0P2J.L,  Ascencio SCA',
    name: 'Ascencio SCA',
    symbol: '0P2J.L',
  },
  {
    value: '0QGJ.L',
    label: '0QGJ.L,  Medigene AG',
    name: 'Medigene AG',
    symbol: '0QGJ.L',
  },
  {
    value: '0QIH.L',
    label: '0QIH.L,  Grupo Ezentis, S.A.',
    name: 'Grupo Ezentis, S.A.',
    symbol: '0QIH.L',
  },
  {
    value: '0QJQ.L',
    label: '0QJQ.L,  ZEAL Network SE',
    name: 'ZEAL Network SE',
    symbol: '0QJQ.L',
  },
  {
    value: '0QM6.L',
    label: '0QM6.L,  ORIOR AG',
    name: 'ORIOR AG',
    symbol: '0QM6.L',
  },
  {
    value: '0QN0.L',
    label: '0QN0.L,  APG|SGA SA',
    name: 'APG|SGA SA',
    symbol: '0QN0.L',
  },
  {
    value: '0QO9.L',
    label: '0QO9.L,  TX Group AG',
    name: 'TX Group AG',
    symbol: '0QO9.L',
  },
  {
    value: '0QRA.L',
    label: '0QRA.L,  Bufab AB (publ)',
    name: 'Bufab AB (publ)',
    symbol: '0QRA.L',
  },
  {
    value: '0QSV.L',
    label: '0QSV.L,  EKINOPS S.A.',
    name: 'EKINOPS S.A.',
    symbol: '0QSV.L',
  },
  {
    value: '0QVR.L',
    label: '0QVR.L,  Scandi Standard AB (publ)',
    name: 'Scandi Standard AB (publ)',
    symbol: '0QVR.L',
  },
  {
    value: '0QXX.L',
    label: '0QXX.L,  Molecular Partners AG',
    name: 'Molecular Partners AG',
    symbol: '0QXX.L',
  },
  {
    value: '0R43.L',
    label: '0R43.L,  NP3 Fastigheter AB (publ)',
    name: 'NP3 Fastigheter AB (publ)',
    symbol: '0R43.L',
  },
  {
    value: '0R6H.L',
    label: '0R6H.L,  Care Property Invest NV',
    name: 'Care Property Invest NV',
    symbol: '0R6H.L',
  },
  {
    value: '0RCW.L',
    label: '0RCW.L,  Kid ASA',
    name: 'Kid ASA',
    symbol: '0RCW.L',
  },
  {
    value: '0RDI.L',
    label: '0RDI.L,  Vitec Software Group AB (publ)',
    name: 'Vitec Software Group AB (publ)',
    symbol: '0RDI.L',
  },
  {
    value: '0REZ.L',
    label: '0REZ.L,  AddLife AB (publ)',
    name: 'AddLife AB (publ)',
    symbol: '0REZ.L',
  },
  {
    value: '0RH8.L',
    label: '0RH8.L,  Nyrstar NV',
    name: 'Nyrstar NV',
    symbol: '0RH8.L',
  },
  {
    value: '0RHT.L',
    label: '0RHT.L,  Sif Holding N.V.',
    name: 'Sif Holding N.V.',
    symbol: '0RHT.L',
  },
  {
    value: '0RJ6.L',
    label: '0RJ6.L,  L.D.C. S.A.',
    name: 'L.D.C. S.A.',
    symbol: '0RJ6.L',
  },
  {
    value: '0RKL.L',
    label: '0RKL.L,  Xvivo Perfusion AB (publ)',
    name: 'Xvivo Perfusion AB (publ)',
    symbol: '0RKL.L',
  },
  {
    value: '0RL4.L',
    label: '0RL4.L,  Catella AB (publ)',
    name: 'Catella AB (publ)',
    symbol: '0RL4.L',
  },
  {
    value: '0RP0.L',
    label: '0RP0.L,  Tikehau Capital',
    name: 'Tikehau Capital',
    symbol: '0RP0.L',
  },
  {
    value: '0RP4.L',
    label: '0RP4.L,  Italmobiliare S.p.A.',
    name: 'Italmobiliare S.p.A.',
    symbol: '0RP4.L',
  },
  {
    value: '0RP6.L',
    label: '0RP6.L,  Enea AB (publ)',
    name: 'Enea AB (publ)',
    symbol: '0RP6.L',
  },
  {
    value: '0RQB.L',
    label: '0RQB.L,  Prodways Group SA',
    name: 'Prodways Group SA',
    symbol: '0RQB.L',
  },
  {
    value: '0RQH.L',
    label: '0RQH.L,  AB Fagerhult (publ.)',
    name: 'AB Fagerhult (publ.)',
    symbol: '0RQH.L',
  },
  {
    value: '0RR8.L',
    label: '0RR8.L,  Baker Hughes Company',
    name: 'Baker Hughes Company',
    symbol: '0RR8.L',
  },
  {
    value: '0RRC.L',
    label: '0RRC.L,  H&R GmbH & Co. KGaA',
    name: 'H&R GmbH & Co. KGaA',
    symbol: '0RRC.L',
  },
  {
    value: '0RSL.L',
    label: '0RSL.L,  Esker SA',
    name: 'Esker SA',
    symbol: '0RSL.L',
  },
  {
    value: '0RT6.L',
    label: '0RT6.L,  Franklin Resources, Inc.',
    name: 'Franklin Resources, Inc.',
    symbol: '0RT6.L',
  },
  {
    value: '0RUM.L',
    label: '0RUM.L,  Allgeier SE',
    name: 'Allgeier SE',
    symbol: '0RUM.L',
  },
  {
    value: '0SE5.L',
    label: '0SE5.L,  Sensirion Holding AG',
    name: 'Sensirion Holding AG',
    symbol: '0SE5.L',
  },
  {
    value: '0U8N.L',
    label: '0U8N.L,  Credicorp Ltd.',
    name: 'Credicorp Ltd.',
    symbol: '0U8N.L',
  },
  {
    value: '0U96.L',
    label: '0U96.L,  Everest Re Group, Ltd.',
    name: 'Everest Re Group, Ltd.',
    symbol: '0U96.L',
  },
  {
    value: '0UGP.L',
    label: '0UGP.L,  Aimia Inc.',
    name: 'Aimia Inc.',
    symbol: '0UGP.L',
  },
  {
    value: '0USB.L',
    label: '0USB.L,  Dolly Varden Silver Corporation',
    name: 'Dolly Varden Silver Corporation',
    symbol: '0USB.L',
  },
  {
    value: '0V7X.L',
    label: '0V7X.L,  Mountain Province Diamonds Inc.',
    name: 'Mountain Province Diamonds Inc.',
    symbol: '0V7X.L',
  },
  {
    value: '0Y3K.L',
    label: '0Y3K.L,  Eaton Corporation plc',
    name: 'Eaton Corporation plc',
    symbol: '0Y3K.L',
  },
  {
    value: '0Y5X.L',
    label: '0Y5X.L,  Pentair plc',
    name: 'Pentair plc',
    symbol: '0Y5X.L',
  },
  {
    value: '0Z1W.L',
    label: '0Z1W.L,  KKR & Co. Inc.',
    name: 'KKR & Co. Inc.',
    symbol: '0Z1W.L',
  },
  {
    value: '0Z62.L',
    label: '0Z62.L,  Keurig Dr Pepper Inc.',
    name: 'Keurig Dr Pepper Inc.',
    symbol: '0Z62.L',
  },
  {
    value: '0A1V.L',
    label: '0A1V.L,  Tethys Oil AB (publ)',
    name: 'Tethys Oil AB (publ)',
    symbol: '0A1V.L',
  },
  {
    value: '0AHJ.L',
    label: '0AHJ.L,  Hudbay Minerals Inc.',
    name: 'Hudbay Minerals Inc.',
    symbol: '0AHJ.L',
  },
  {
    value: '0DTF.L',
    label: '0DTF.L,  Boiron SA',
    name: 'Boiron SA',
    symbol: '0DTF.L',
  },
  {
    value: '0DTK.L',
    label: '0DTK.L,  Savencia SA',
    name: 'Savencia SA',
    symbol: '0DTK.L',
  },
  {
    value: '0DUI.L',
    label: '0DUI.L,  Basler Aktiengesellschaft',
    name: 'Basler Aktiengesellschaft',
    symbol: '0DUI.L',
  },
  {
    value: '0EFW.L',
    label: '0EFW.L,  Komplett Bank ASA',
    name: 'Komplett Bank ASA',
    symbol: '0EFW.L',
  },
  {
    value: '0F29.L',
    label: '0F29.L,  Lassila & Tikanoja Oyj',
    name: 'Lassila & Tikanoja Oyj',
    symbol: '0F29.L',
  },
  {
    value: '0FGL.L',
    label: '0FGL.L,  Nexus AG',
    name: 'Nexus AG',
    symbol: '0FGL.L',
  },
  {
    value: '0H0U.L',
    label: '0H0U.L,  Sensys Gatso Group AB (publ)',
    name: 'Sensys Gatso Group AB (publ)',
    symbol: '0H0U.L',
  },
  {
    value: '0HDU.L',
    label: '0HDU.L,  Bouvet ASA',
    name: 'Bouvet ASA',
    symbol: '0HDU.L',
  },
  {
    value: '0HF9.L',
    label: '0HF9.L,  Amicus Therapeutics, Inc.',
    name: 'Amicus Therapeutics, Inc.',
    symbol: '0HF9.L',
  },
  {
    value: '0HIN.L',
    label: '0HIN.L,  Assurant, Inc.',
    name: 'Assurant, Inc.',
    symbol: '0HIN.L',
  },
  {
    value: '0HJH.L',
    label: '0HJH.L,  Autoliv, Inc.',
    name: 'Autoliv, Inc.',
    symbol: '0HJH.L',
  },
  {
    value: '0HQW.L',
    label: '0HQW.L,  C.H. Robinson Worldwide, Inc.',
    name: 'C.H. Robinson Worldwide, Inc.',
    symbol: '0HQW.L',
  },
  {
    value: '0HR3.L',
    label: '0HR3.L,  Duni AB (publ)',
    name: 'Duni AB (publ)',
    symbol: '0HR3.L',
  },
  {
    value: '0I0B.L',
    label: '0I0B.L,  ClearSign Technologies Corporation',
    name: 'ClearSign Technologies Corporation',
    symbol: '0I0B.L',
  },
  {
    value: '0I1B.L',
    label: '0I1B.L,  Enovis Corporation',
    name: 'Enovis Corporation',
    symbol: '0I1B.L',
  },
  {
    value: '0IF4.L',
    label: '0IF4.L,  Eastman Kodak Company',
    name: 'Eastman Kodak Company',
    symbol: '0IF4.L',
  },
  {
    value: '0IIR.L',
    label: '0IIR.L,  Essex Property Trust, Inc.',
    name: 'Essex Property Trust, Inc.',
    symbol: '0IIR.L',
  },
  {
    value: '0IL8.L',
    label: '0IL8.L,  FibroGen, Inc.',
    name: 'FibroGen, Inc.',
    symbol: '0IL8.L',
  },
  {
    value: '0IP1.L',
    label: '0IP1.L,  Bastide Le Confort Médical SA',
    name: 'Bastide Le Confort Médical SA',
    symbol: '0IP1.L',
  },
  {
    value: '0J0P.L',
    label: '0J0P.L,  Green Plains Inc.',
    name: 'Green Plains Inc.',
    symbol: '0J0P.L',
  },
  {
    value: '0J1G.L',
    label: '0J1G.L,  Guidewire Software, Inc.',
    name: 'Guidewire Software, Inc.',
    symbol: '0J1G.L',
  },
  {
    value: '0J4L.L',
    label: '0J4L.L,  Herc Holdings Inc.',
    name: 'Herc Holdings Inc.',
    symbol: '0J4L.L',
  },
  {
    value: '0JG5.L',
    label: '0JG5.L,  Bittium Oyj',
    name: 'Bittium Oyj',
    symbol: '0JG5.L',
  },
  {
    value: '0JMU.L',
    label: '0JMU.L,  technotrans SE',
    name: 'technotrans SE',
    symbol: '0JMU.L',
  },
  {
    value: '0JOE.L',
    label: '0JOE.L,  KUKA Aktiengesellschaft',
    name: 'KUKA Aktiengesellschaft',
    symbol: '0JOE.L',
  },
  {
    value: '0JSU.L',
    label: '0JSU.L,  Sipef NV',
    name: 'Sipef NV',
    symbol: '0JSU.L',
  },
  {
    value: '0JTQ.L',
    label: '0JTQ.L,  Lear Corporation',
    name: 'Lear Corporation',
    symbol: '0JTQ.L',
  },
  {
    value: '0JTZ.L',
    label: '0JTZ.L,  LendingTree, Inc.',
    name: 'LendingTree, Inc.',
    symbol: '0JTZ.L',
  },
  {
    value: '0K3W.L',
    label: '0K3W.L,  Myriad Genetics, Inc.',
    name: 'Myriad Genetics, Inc.',
    symbol: '0K3W.L',
  },
  {
    value: '0K9J.L',
    label: '0K9J.L,  NOW Inc.',
    name: 'NOW Inc.',
    symbol: '0K9J.L',
  },
  {
    value: '0KGY.L',
    label: '0KGY.L,  BTS Group AB (publ)',
    name: 'BTS Group AB (publ)',
    symbol: '0KGY.L',
  },
  {
    value: '0KJZ.L',
    label: '0KJZ.L,  Post Holdings, Inc.',
    name: 'Post Holdings, Inc.',
    symbol: '0KJZ.L',
  },
  {
    value: '0KUV.L',
    label: '0KUV.L,  Atari SA',
    name: 'Atari SA',
    symbol: '0KUV.L',
  },
  {
    value: '0L73.L',
    label: '0L73.L,  Skechers U.S.A., Inc.',
    name: 'Skechers U.S.A., Inc.',
    symbol: '0L73.L',
  },
  {
    value: '0LK3.L',
    label: '0LK3.L,  Vail Resorts, Inc.',
    name: 'Vail Resorts, Inc.',
    symbol: '0LK3.L',
  },
  {
    value: '0LPE.L',
    label: '0LPE.L,  Viasat, Inc.',
    name: 'Viasat, Inc.',
    symbol: '0LPE.L',
  },
  {
    value: '0M30.L',
    label: '0M30.L,  Yum China Holdings, Inc.',
    name: 'Yum China Holdings, Inc.',
    symbol: '0M30.L',
  },
  {
    value: '0MN5.L',
    label: '0MN5.L,  Sword Group S.E.',
    name: 'Sword Group S.E.',
    symbol: '0MN5.L',
  },
  {
    value: '0N2Q.L',
    label: '0N2Q.L,  Bijou Brigitte modische Accessoires Aktiengesellschaft',
    name: 'Bijou Brigitte modische Accessoires Aktiengesellschaft',
    symbol: '0N2Q.L',
  },
  {
    value: '0N5Z.L',
    label: '0N5Z.L,  Altamir SCA',
    name: 'Altamir SCA',
    symbol: '0N5Z.L',
  },
  {
    value: '0NCA.L',
    label: '0NCA.L,  IVU Traffic Technologies AG',
    name: 'IVU Traffic Technologies AG',
    symbol: '0NCA.L',
  },
  {
    value: '0NL1.L',
    label: '0NL1.L,  PVA TePla AG',
    name: 'PVA TePla AG',
    symbol: '0NL1.L',
  },
  {
    value: '0NLY.L',
    label: '0NLY.L,  VERBIO Vereinigte BioEnergie AG',
    name: 'VERBIO Vereinigte BioEnergie AG',
    symbol: '0NLY.L',
  },
  {
    value: '0NUT.L',
    label: '0NUT.L,  Nextensa',
    name: 'Nextensa',
    symbol: '0NUT.L',
  },
  {
    value: '0O33.L',
    label: '0O33.L,  Oeneo SA',
    name: 'Oeneo SA',
    symbol: '0O33.L',
  },
  {
    value: '0O80.L',
    label:
      '0O80.L,  Société Anonyme des Bains de Mer et du Cercle des Étrangers à Monaco',
    name: 'Société Anonyme des Bains de Mer et du Cercle des Étrangers à Monaco',
    symbol: '0O80.L',
  },
  {
    value: '0OA7.L',
    label: '0OA7.L,  Assystem S.A.',
    name: 'Assystem S.A.',
    symbol: '0OA7.L',
  },
  {
    value: '0OCD.L',
    label: '0OCD.L,  Medistim ASA',
    name: 'Medistim ASA',
    symbol: '0OCD.L',
  },
  {
    value: '0OHK.L',
    label: '0OHK.L,  Stolt-Nielsen Limited',
    name: 'Stolt-Nielsen Limited',
    symbol: '0OHK.L',
  },
  {
    value: '0OPN.L',
    label: "0OPN.L,  Compagnie de l'Odet",
    name: "Compagnie de l'Odet",
    symbol: '0OPN.L',
  },
  {
    value: '0OQ0.L',
    label: '0OQ0.L,  Pierre et Vacances SA',
    name: 'Pierre et Vacances SA',
    symbol: '0OQ0.L',
  },
  {
    value: '0Q0C.L',
    label: '0Q0C.L,  Toshiba Corporation',
    name: 'Toshiba Corporation',
    symbol: '0Q0C.L',
  },
  {
    value: '0Q4C.L',
    label: '0Q4C.L,  Gesco AG',
    name: 'Gesco AG',
    symbol: '0Q4C.L',
  },
  {
    value: '0QAG.L',
    label: '0QAG.L,  ID Logistics Group SA',
    name: 'ID Logistics Group SA',
    symbol: '0QAG.L',
  },
  {
    value: '0QGK.L',
    label: '0QGK.L,  WCM Beteiligungs- und Grundbesitz-AG',
    name: 'WCM Beteiligungs- und Grundbesitz-AG',
    symbol: '0QGK.L',
  },
  {
    value: '0QVG.L',
    label: '0QVG.L,  SergeFerrari Group SA',
    name: 'SergeFerrari Group SA',
    symbol: '0QVG.L',
  },
  {
    value: '0QW7.L',
    label: '0QW7.L,  Voltalia SA',
    name: 'Voltalia SA',
    symbol: '0QW7.L',
  },
  {
    value: '0R11.L',
    label: '0R11.L,  Sabina Gold & Silver Corp.',
    name: 'Sabina Gold & Silver Corp.',
    symbol: '0R11.L',
  },
  {
    value: '0R7T.L',
    label: '0R7T.L,  TINC NV',
    name: 'TINC NV',
    symbol: '0R7T.L',
  },
  {
    value: '0R86.L',
    label: '0R86.L,  Invisio AB (publ)',
    name: 'Invisio AB (publ)',
    symbol: '0R86.L',
  },
  {
    value: '0RHV.L',
    label: '0RHV.L,  Investis Holding SA',
    name: 'Investis Holding SA',
    symbol: '0RHV.L',
  },
  {
    value: '0RNK.L',
    label: '0RNK.L,  Inventiva S.A.',
    name: 'Inventiva S.A.',
    symbol: '0RNK.L',
  },
  {
    value: '0RP3.L',
    label: '0RP3.L,  Kamux Oyj',
    name: 'Kamux Oyj',
    symbol: '0RP3.L',
  },
  {
    value: '0RQI.L',
    label: '0RQI.L,  Talenom Oyj',
    name: 'Talenom Oyj',
    symbol: '0RQI.L',
  },
  {
    value: '0RR3.L',
    label: '0RR3.L,  Somfy SA',
    name: 'Somfy SA',
    symbol: '0RR3.L',
  },
  {
    value: '0RTK.L',
    label: '0RTK.L,  Alligo AB (publ)',
    name: 'Alligo AB (publ)',
    symbol: '0RTK.L',
  },
  {
    value: '0RUJ.L',
    label: '0RUJ.L,  Delta Plus Group',
    name: 'Delta Plus Group',
    symbol: '0RUJ.L',
  },
  {
    value: '0RV4.L',
    label: '0RV4.L,  Voltabox AG',
    name: 'Voltabox AG',
    symbol: '0RV4.L',
  },
  {
    value: '0RWM.L',
    label: '0RWM.L,  Bitcoin Group SE',
    name: 'Bitcoin Group SE',
    symbol: '0RWM.L',
  },
  {
    value: '0TWH.L',
    label: '0TWH.L,  Allkem Limited',
    name: 'Allkem Limited',
    symbol: '0TWH.L',
  },
  {
    value: '0W0T.L',
    label: '0W0T.L,  HELMA Eigenheimbau Aktiengesellschaft',
    name: 'HELMA Eigenheimbau Aktiengesellschaft',
    symbol: '0W0T.L',
  },
  {
    value: '0W4N.L',
    label: '0W4N.L,  Formycon AG',
    name: 'Formycon AG',
    symbol: '0W4N.L',
  },
  {
    value: '0XWD.L',
    label: '0XWD.L,  Elevate Credit, Inc.',
    name: 'Elevate Credit, Inc.',
    symbol: '0XWD.L',
  },
  {
    value: '0YQA.L',
    label: '0YQA.L,  Carel Industries S.p.A.',
    name: 'Carel Industries S.p.A.',
    symbol: '0YQA.L',
  },
  {
    value: 'I3E.L',
    label: 'I3E.L,  i3 Energy Plc',
    name: 'i3 Energy Plc',
    symbol: 'I3E.L',
  },
  {
    value: 'USY.L',
    label: 'USY.L,  Unisys Corporation',
    name: 'Unisys Corporation',
    symbol: 'USY.L',
  },
  {
    value: '0EIE.L',
    label: '0EIE.L,  WithSecure Oyj',
    name: 'WithSecure Oyj',
    symbol: '0EIE.L',
  },
  {
    value: '0F7F.L',
    label: '0F7F.L,  Duro Felguera, S.A.',
    name: 'Duro Felguera, S.A.',
    symbol: '0F7F.L',
  },
  {
    value: '0FRI.L',
    label: '0FRI.L,  Quantel',
    name: 'Quantel',
    symbol: '0FRI.L',
  },
  {
    value: '0G1T.L',
    label: '0G1T.L,  Wavestone SA',
    name: 'Wavestone SA',
    symbol: '0G1T.L',
  },
  {
    value: '0GXK.L',
    label: '0GXK.L,  VBG Group AB (publ)',
    name: 'VBG Group AB (publ)',
    symbol: '0GXK.L',
  },
  {
    value: '0HTZ.L',
    label: '0HTZ.L,  Cars.com Inc.',
    name: 'Cars.com Inc.',
    symbol: '0HTZ.L',
  },
  {
    value: '0J3N.L',
    label: '0J3N.L,  Hawaiian Holdings, Inc.',
    name: 'Hawaiian Holdings, Inc.',
    symbol: '0J3N.L',
  },
  {
    value: '0JX9.L',
    label: '0JX9.L,  Marimekko Oyj',
    name: 'Marimekko Oyj',
    symbol: '0JX9.L',
  },
  {
    value: '0JZH.L',
    label: '0JZH.L,  Mattel, Inc.',
    name: 'Mattel, Inc.',
    symbol: '0JZH.L',
  },
  {
    value: '0K1G.L',
    label: '0K1G.L,  The Middleby Corporation',
    name: 'The Middleby Corporation',
    symbol: '0K1G.L',
  },
  {
    value: '0LID.L',
    label: '0LID.L,  Ultra Clean Holdings, Inc.',
    name: 'Ultra Clean Holdings, Inc.',
    symbol: '0LID.L',
  },
  {
    value: '0M0Q.L',
    label: '0M0Q.L,  Deoleo, S.A.',
    name: 'Deoleo, S.A.',
    symbol: '0M0Q.L',
  },
  {
    value: '0MUF.L',
    label: '0MUF.L,  CENIT Aktiengesellschaft',
    name: 'CENIT Aktiengesellschaft',
    symbol: '0MUF.L',
  },
  {
    value: '0OA4.L',
    label: '0OA4.L,  SES-imagotag Société Anonyme',
    name: 'SES-imagotag Société Anonyme',
    symbol: '0OA4.L',
  },
  {
    value: '0OPA.L',
    label: '0OPA.L,  Villeroy & Boch AG',
    name: 'Villeroy & Boch AG',
    symbol: '0OPA.L',
  },
  {
    value: '0QAU.L',
    label: '0QAU.L,  Verimatrix Société anonyme',
    name: 'Verimatrix Société anonyme',
    symbol: '0QAU.L',
  },
  {
    value: '0QAV.L',
    label: '0QAV.L,  Nanobiotix S.A.',
    name: 'Nanobiotix S.A.',
    symbol: '0QAV.L',
  },
  {
    value: '0QDT.L',
    label: '0QDT.L,  Asetek A/S',
    name: 'Asetek A/S',
    symbol: '0QDT.L',
  },
  {
    value: '0QKQ.L',
    label: '0QKQ.L,  Relief Therapeutics Holding AG',
    name: 'Relief Therapeutics Holding AG',
    symbol: '0QKQ.L',
  },
  {
    value: '0QLX.L',
    label: '0QLX.L,  Metall Zug AG',
    name: 'Metall Zug AG',
    symbol: '0QLX.L',
  },
  {
    value: '0Z0F.L',
    label: '0Z0F.L,  Ichor Holdings, Ltd.',
    name: 'Ichor Holdings, Ltd.',
    symbol: '0Z0F.L',
  },
  {
    value: '0ZC0.L',
    label: '0ZC0.L,  Tenable Holdings, Inc.',
    name: 'Tenable Holdings, Inc.',
    symbol: '0ZC0.L',
  },
  {
    value: '0A26.L',
    label: '0A26.L,  Ellington Financial Inc.',
    name: 'Ellington Financial Inc.',
    symbol: '0A26.L',
  },
  {
    value: '0DWL.L',
    label: '0DWL.L,  Adecoagro S.A.',
    name: 'Adecoagro S.A.',
    symbol: '0DWL.L',
  },
  {
    value: '0HHU.L',
    label: '0HHU.L,  ARMOUR Residential REIT, Inc.',
    name: 'ARMOUR Residential REIT, Inc.',
    symbol: '0HHU.L',
  },
  {
    value: '0HWG.L',
    label: '0HWG.L,  The Chemours Company',
    name: 'The Chemours Company',
    symbol: '0HWG.L',
  },
  {
    value: '0IJU.L',
    label: '0IJU.L,  Express, Inc.',
    name: 'Express, Inc.',
    symbol: '0IJU.L',
  },
  {
    value: '0J7X.L',
    label: '0J7X.L,  MBB SE',
    name: 'MBB SE',
    symbol: '0J7X.L',
  },
  {
    value: '0KH0.L',
    label: '0KH0.L,  PennantPark Floating Rate Capital Ltd.',
    name: 'PennantPark Floating Rate Capital Ltd.',
    symbol: '0KH0.L',
  },
  {
    value: '0KUR.L',
    label: '0KUR.L,  PSI Software AG',
    name: 'PSI Software AG',
    symbol: '0KUR.L',
  },
  {
    value: '0LEE.L',
    label: '0LEE.L,  Teradata Corporation',
    name: 'Teradata Corporation',
    symbol: '0LEE.L',
  },
  {
    value: '0M6J.L',
    label: '0M6J.L,  H+H International A/S',
    name: 'H+H International A/S',
    symbol: '0M6J.L',
  },
  {
    value: '0N9Z.L',
    label: '0N9Z.L,  EVS Broadcast Equipment SA',
    name: 'EVS Broadcast Equipment SA',
    symbol: '0N9Z.L',
  },
  {
    value: '0OG6.L',
    label: '0OG6.L,  Séché Environnement SA',
    name: 'Séché Environnement SA',
    symbol: '0OG6.L',
  },
  {
    value: '0RQF.L',
    label: '0RQF.L,  Balyo SA',
    name: 'Balyo SA',
    symbol: '0RQF.L',
  },
  {
    value: '0Z4I.L',
    label: '0Z4I.L,  Sunny Optical Technology (Group) Company Limited',
    name: 'Sunny Optical Technology (Group) Company Limited',
    symbol: '0Z4I.L',
  },
  {
    value: '0F2Z.L',
    label: '0F2Z.L,  Leifheit Aktiengesellschaft',
    name: 'Leifheit Aktiengesellschaft',
    symbol: '0F2Z.L',
  },
  {
    value: '0G8Z.L',
    label: '0G8Z.L,  HolidayCheck Group AG',
    name: 'HolidayCheck Group AG',
    symbol: '0G8Z.L',
  },
  {
    value: '0GOX.L',
    label: '0GOX.L,  Polytec Holding AG',
    name: 'Polytec Holding AG',
    symbol: '0GOX.L',
  },
  {
    value: '0HO6.L',
    label: '0HO6.L,  BlackRock Capital Investment Corporation',
    name: 'BlackRock Capital Investment Corporation',
    symbol: '0HO6.L',
  },
  {
    value: '0I5O.L',
    label: '0I5O.L,  Bergman & Beving AB (publ)',
    name: 'Bergman & Beving AB (publ)',
    symbol: '0I5O.L',
  },
  {
    value: '0IVQ.L',
    label: '0IVQ.L,  Gladstone Commercial Corporation',
    name: 'Gladstone Commercial Corporation',
    symbol: '0IVQ.L',
  },
  {
    value: '0NFG.L',
    label: '0NFG.L,  paragon GmbH & Co. KGaA',
    name: 'paragon GmbH & Co. KGaA',
    symbol: '0NFG.L',
  },
  {
    value: '0YAA.L',
    label: '0YAA.L,  Nova Ltd.',
    name: 'Nova Ltd.',
    symbol: '0YAA.L',
  },
  {
    value: '0CIJ.L',
    label: '0CIJ.L,  Raisio plc',
    name: 'Raisio plc',
    symbol: '0CIJ.L',
  },
  {
    value: '0FNZ.L',
    label: '0FNZ.L,  Ponsse Oyj',
    name: 'Ponsse Oyj',
    symbol: '0FNZ.L',
  },
  {
    value: '0GX2.L',
    label: '0GX2.L,  Neurones S.A.',
    name: 'Neurones S.A.',
    symbol: '0GX2.L',
  },
  {
    value: '0HQ1.L',
    label: '0HQ1.L,  Azenta, Inc.',
    name: 'Azenta, Inc.',
    symbol: '0HQ1.L',
  },
  {
    value: '0HRR.L',
    label: '0HRR.L,  CVR Energy, Inc.',
    name: 'CVR Energy, Inc.',
    symbol: '0HRR.L',
  },
  {
    value: '0IZM.L',
    label: '0IZM.L,  ABG Sundal Collier Holding ASA',
    name: 'ABG Sundal Collier Holding ASA',
    symbol: '0IZM.L',
  },
  {
    value: '0K5R.L',
    label: '0K5R.L,  Navient Corporation',
    name: 'Navient Corporation',
    symbol: '0K5R.L',
  },
  {
    value: '0KT6.L',
    label: '0KT6.L,  RCI Hospitality Holdings, Inc.',
    name: 'RCI Hospitality Holdings, Inc.',
    symbol: '0KT6.L',
  },
  {
    value: '0O6D.L',
    label: '0O6D.L,  Miquel y Costas & Miquel, S.A.',
    name: 'Miquel y Costas & Miquel, S.A.',
    symbol: '0O6D.L',
  },
  {
    value: '0P49.L',
    label: '0P49.L,  Bulten AB (publ)',
    name: 'Bulten AB (publ)',
    symbol: '0P49.L',
  },
  {
    value: '0QVW.L',
    label: '0QVW.L,  ATEME SA',
    name: 'ATEME SA',
    symbol: '0QVW.L',
  },
  {
    value: '0R55.L',
    label: '0R55.L,  BioSenic S.A.',
    name: 'BioSenic S.A.',
    symbol: '0R55.L',
  },
  {
    value: '0RGB.L',
    label: '0RGB.L,  Investment AB Öresund (publ)',
    name: 'Investment AB Öresund (publ)',
    symbol: '0RGB.L',
  },
  {
    value: '0RT7.L',
    label: '0RT7.L,  Tenneco Inc.',
    name: 'Tenneco Inc.',
    symbol: '0RT7.L',
  },
  {
    value: '0UX6.L',
    label: '0UX6.L,  Garibaldi Resources Corp.',
    name: 'Garibaldi Resources Corp.',
    symbol: '0UX6.L',
  },
  {
    value: '0HN1.L',
    label: '0HN1.L,  Berry Global Group, Inc.',
    name: 'Berry Global Group, Inc.',
    symbol: '0HN1.L',
  },
  {
    value: '0MMJ.L',
    label: '0MMJ.L,  Tom Tailor Holding SE',
    name: 'Tom Tailor Holding SE',
    symbol: '0MMJ.L',
  },
  {
    value: '0RKK.L',
    label: '0RKK.L,  Volati AB',
    name: 'Volati AB',
    symbol: '0RKK.L',
  },
  {
    value: '0H8T.L',
    label: '0H8T.L,  Aclaris Therapeutics, Inc.',
    name: 'Aclaris Therapeutics, Inc.',
    symbol: '0H8T.L',
  },
  {
    value: '0LHX.L',
    label: '0LHX.L,  U.S. Global Investors, Inc.',
    name: 'U.S. Global Investors, Inc.',
    symbol: '0LHX.L',
  },
  {
    value: '0S9E.L',
    label: '0S9E.L,  Extendicare Inc.',
    name: 'Extendicare Inc.',
    symbol: '0S9E.L',
  },
  {
    value: '0R40.L',
    label: '0R40.L,  Rai Way S.p.A.',
    name: 'Rai Way S.p.A.',
    symbol: '0R40.L',
  },
  {
    value: '0HEJ.L',
    label: '0HEJ.L,  American Homes 4 Rent',
    name: 'American Homes 4 Rent',
    symbol: '0HEJ.L',
  },
  {
    value: '0IWU.L',
    label: '0IWU.L,  Fabasoft AG',
    name: 'Fabasoft AG',
    symbol: '0IWU.L',
  },
  {
    value: '0KGB.L',
    label: '0KGB.L,  Patterson Companies, Inc.',
    name: 'Patterson Companies, Inc.',
    symbol: '0KGB.L',
  },
  {
    value: '0NZ1.L',
    label: '0NZ1.L,  Colas SA',
    name: 'Colas SA',
    symbol: '0NZ1.L',
  },
  {
    value: '0UNL.L',
    label: '0UNL.L,  Nektar Therapeutics',
    name: 'Nektar Therapeutics',
    symbol: '0UNL.L',
  },
  {
    value: '0K7X.L',
    label: '0K7X.L,  Newtek Business Services Corp.',
    name: 'Newtek Business Services Corp.',
    symbol: '0K7X.L',
  },
  {
    value: '0RI3.L',
    label: '0RI3.L,  Pareto Bank ASA',
    name: 'Pareto Bank ASA',
    symbol: '0RI3.L',
  },
  {
    value: 'ADXN.SW',
    label: 'ADXN.SW,  Addex Therapeutics Ltd',
    name: 'Addex Therapeutics Ltd',
    symbol: 'ADXN.SW',
  },
  {
    value: 'ADVN.SW',
    label: 'ADVN.SW,  Adval Tech Holding AG',
    name: 'Adval Tech Holding AG',
    symbol: 'ADVN.SW',
  },
  {
    value: 'AEVS.SW',
    label: 'AEVS.SW,  Aevis Victoria SA',
    name: 'Aevis Victoria SA',
    symbol: 'AEVS.SW',
  },
  {
    value: 'AIRE.SW',
    label: 'AIRE.SW,  Airesis SA',
    name: 'Airesis SA',
    symbol: 'AIRE.SW',
  },
  {
    value: 'ALLN.SW',
    label: 'ALLN.SW,  Allreal Holding AG',
    name: 'Allreal Holding AG',
    symbol: 'ALLN.SW',
  },
  {
    value: 'ALPN.SW',
    label: 'ALPN.SW,  Alpine Select AG',
    name: 'Alpine Select AG',
    symbol: 'ALPN.SW',
  },
  {
    value: 'ALSN.SW',
    label: 'ALSN.SW,  ALSO Holding AG',
    name: 'ALSO Holding AG',
    symbol: 'ALSN.SW',
  },
  {
    value: 'AFP.SW',
    label: 'AFP.SW,  Aluflexpack AG',
    name: 'Aluflexpack AG',
    symbol: 'AFP.SW',
  },
  {
    value: 'APGN.SW',
    label: 'APGN.SW,  APG|SGA SA',
    name: 'APG|SGA SA',
    symbol: 'APGN.SW',
  },
  {
    value: 'ARBN.SW',
    label: 'ARBN.SW,  Arbonia AG',
    name: 'Arbonia AG',
    symbol: 'ARBN.SW',
  },
  {
    value: 'ARON.SW',
    label: 'ARON.SW,  Arundel AG',
    name: 'Arundel AG',
    symbol: 'ARON.SW',
  },
  {
    value: 'ASCN.SW',
    label: 'ASCN.SW,  Ascom Holding AG',
    name: 'Ascom Holding AG',
    symbol: 'ASCN.SW',
  },
  {
    value: 'ASWN.SW',
    label: 'ASWN.SW,  Asmallworld AG',
    name: 'Asmallworld AG',
    symbol: 'ASWN.SW',
  },
  {
    value: 'AUTN.SW',
    label: 'AUTN.SW,  Autoneum Holding AG',
    name: 'Autoneum Holding AG',
    symbol: 'AUTN.SW',
  },
  {
    value: 'BANB.SW',
    label: 'BANB.SW,  Bachem Holding AG',
    name: 'Bachem Holding AG',
    symbol: 'BANB.SW',
  },
  {
    value: 'BALN.SW',
    label: 'BALN.SW,  Bâloise Holding AG',
    name: 'Bâloise Holding AG',
    symbol: 'BALN.SW',
  },
  {
    value: 'BARN.SW',
    label: 'BARN.SW,  Barry Callebaut AG',
    name: 'Barry Callebaut AG',
    symbol: 'BARN.SW',
  },
  {
    value: 'BLKB.SW',
    label: 'BLKB.SW,  Basellandschaftliche Kantonalbank',
    name: 'Basellandschaftliche Kantonalbank',
    symbol: 'BLKB.SW',
  },
  {
    value: 'BSLN.SW',
    label: 'BSLN.SW,  Basilea Pharmaceutica AG',
    name: 'Basilea Pharmaceutica AG',
    symbol: 'BSLN.SW',
  },
  {
    value: 'BSKP.SW',
    label: 'BSKP.SW,  Basler Kantonalbank',
    name: 'Basler Kantonalbank',
    symbol: 'BSKP.SW',
  },
  {
    value: 'BION.SW',
    label: 'BION.SW,  BB Biotech AG',
    name: 'BB Biotech AG',
    symbol: 'BION.SW',
  },
  {
    value: 'BCGE.SW',
    label: 'BCGE.SW,  Banque Cantonale de Genève SA',
    name: 'Banque Cantonale de Genève SA',
    symbol: 'BCGE.SW',
  },
  {
    value: 'BCJ.SW',
    label: 'BCJ.SW,  Banque Cantonale du Jura SA',
    name: 'Banque Cantonale du Jura SA',
    symbol: 'BCJ.SW',
  },
  {
    value: 'BCVN.SW',
    label: 'BCVN.SW,  Banque Cantonale Vaudoise',
    name: 'Banque Cantonale Vaudoise',
    symbol: 'BCVN.SW',
  },
  {
    value: 'BEKN.SW',
    label: 'BEKN.SW,  Berner Kantonalbank AG',
    name: 'Berner Kantonalbank AG',
    symbol: 'BEKN.SW',
  },
  {
    value: 'BEAN.SW',
    label: 'BEAN.SW,  BELIMO Holding AG',
    name: 'BELIMO Holding AG',
    symbol: 'BEAN.SW',
  },
  {
    value: 'BELL.SW',
    label: 'BELL.SW,  Bell Food Group AG',
    name: 'Bell Food Group AG',
    symbol: 'BELL.SW',
  },
  {
    value: 'BBN.SW',
    label: 'BBN.SW,  Bellevue Group AG',
    name: 'Bellevue Group AG',
    symbol: 'BBN.SW',
  },
  {
    value: 'LINN.SW',
    label: 'LINN.SW,  Bank Linth LLB AG',
    name: 'Bank Linth LLB AG',
    symbol: 'LINN.SW',
  },
  {
    value: 'BKW.SW',
    label: 'BKW.SW,  BKW AG',
    name: 'BKW AG',
    symbol: 'BKW.SW',
  },
  {
    value: 'BOBNN.SW',
    label: 'BOBNN.SW,  Bobst Group SA',
    name: 'Bobst Group SA',
    symbol: 'BOBNN.SW',
  },
  {
    value: 'BOSN.SW',
    label: 'BOSN.SW,  Bossard Holding AG',
    name: 'Bossard Holding AG',
    symbol: 'BOSN.SW',
  },
  {
    value: 'BUCN.SW',
    label: 'BUCN.SW,  Bucher Industries AG',
    name: 'Bucher Industries AG',
    symbol: 'BUCN.SW',
  },
  {
    value: 'BCHN.SW',
    label: 'BCHN.SW,  Burckhardt Compression Holding AG',
    name: 'Burckhardt Compression Holding AG',
    symbol: 'BCHN.SW',
  },
  {
    value: 'BRKN.SW',
    label: 'BRKN.SW,  Burkhalter Holding AG',
    name: 'Burkhalter Holding AG',
    symbol: 'BRKN.SW',
  },
  {
    value: 'BVZN.SW',
    label: 'BVZN.SW,  BVZ Holding AG',
    name: 'BVZ Holding AG',
    symbol: 'BVZN.SW',
  },
  {
    value: 'CALN.SW',
    label: 'CALN.SW,  CALIDA Holding AG',
    name: 'CALIDA Holding AG',
    symbol: 'CALN.SW',
  },
  {
    value: 'CPEN.SW',
    label: 'CPEN.SW,  Castle Private Equity AG',
    name: 'Castle Private Equity AG',
    symbol: 'CPEN.SW',
  },
  {
    value: 'CMBN.SW',
    label: 'CMBN.SW,  Cembra Money Bank AG',
    name: 'Cembra Money Bank AG',
    symbol: 'CMBN.SW',
  },
  {
    value: 'CIE.SW',
    label: 'CIE.SW,  Compagnie Internationale pour la Communication',
    name: 'Compagnie Internationale pour la Communication',
    symbol: 'CIE.SW',
  },
  {
    value: 'CICN.SW',
    label: 'CICN.SW,  Cicor Technologies Ltd.',
    name: 'Cicor Technologies Ltd.',
    symbol: 'CICN.SW',
  },
  {
    value: 'CFT.SW',
    label: 'CFT.SW,  Compagnie Financière Tradition SA',
    name: 'Compagnie Financière Tradition SA',
    symbol: 'CFT.SW',
  },
  {
    value: 'CLTN.SW',
    label: 'CLTN.SW,  COLTENE Holding AG',
    name: 'COLTENE Holding AG',
    symbol: 'CLTN.SW',
  },
  {
    value: 'COTN.SW',
    label: 'COTN.SW,  Comet Holding AG',
    name: 'Comet Holding AG',
    symbol: 'COTN.SW',
  },
  {
    value: 'CPHN.SW',
    label: 'CPHN.SW,  CPH Chemie + Papier Holding AG',
    name: 'CPH Chemie + Papier Holding AG',
    symbol: 'CPHN.SW',
  },
  {
    value: 'CLXN.SW',
    label: 'CLXN.SW,  Crealogix Holding AG',
    name: 'Crealogix Holding AG',
    symbol: 'CLXN.SW',
  },
  {
    value: 'DAE.SW',
    label: 'DAE.SW,  Dätwyler Holding AG',
    name: 'Dätwyler Holding AG',
    symbol: 'DAE.SW',
  },
  {
    value: 'DCN.SW',
    label: 'DCN.SW,  Datacolor AG',
    name: 'Datacolor AG',
    symbol: 'DCN.SW',
  },
  {
    value: 'DKSH.SW',
    label: 'DKSH.SW,  DKSH Holding AG',
    name: 'DKSH Holding AG',
    symbol: 'DKSH.SW',
  },
  {
    value: 'DOKA.SW',
    label: 'DOKA.SW,  dormakaba Holding AG',
    name: 'dormakaba Holding AG',
    symbol: 'DOKA.SW',
  },
  {
    value: 'DESN.SW',
    label: 'DESN.SW,  Dottikon Es Holding AG',
    name: 'Dottikon Es Holding AG',
    symbol: 'DESN.SW',
  },
  {
    value: 'DUFN.SW',
    label: 'DUFN.SW,  Dufry AG',
    name: 'Dufry AG',
    symbol: 'DUFN.SW',
  },
  {
    value: 'ESUN.SW',
    label: 'ESUN.SW,  Edisun Power Europe AG',
    name: 'Edisun Power Europe AG',
    symbol: 'ESUN.SW',
  },
  {
    value: 'EFGN.SW',
    label: 'EFGN.SW,  EFG International AG',
    name: 'EFG International AG',
    symbol: 'EFGN.SW',
  },
  {
    value: 'ELMN.SW',
    label: 'ELMN.SW,  Elma Electronic AG',
    name: 'Elma Electronic AG',
    symbol: 'ELMN.SW',
  },
  {
    value: 'EMMN.SW',
    label: 'EMMN.SW,  Emmi AG',
    name: 'Emmi AG',
    symbol: 'EMMN.SW',
  },
  {
    value: 'EMSN.SW',
    label: 'EMSN.SW,  EMS-CHEMIE HOLDING AG',
    name: 'EMS-CHEMIE HOLDING AG',
    symbol: 'EMSN.SW',
  },
  {
    value: 'EDHN.SW',
    label: 'EDHN.SW,  Energiedienst Holding AG',
    name: 'Energiedienst Holding AG',
    symbol: 'EDHN.SW',
  },
  {
    value: 'EVE.SW',
    label: 'EVE.SW,  Evolva Holding SA',
    name: 'Evolva Holding SA',
    symbol: 'EVE.SW',
  },
  {
    value: 'FTON.SW',
    label: 'FTON.SW,  Feintool International Holding AG',
    name: 'Feintool International Holding AG',
    symbol: 'FTON.SW',
  },
  {
    value: 'FHZN.SW',
    label: 'FHZN.SW,  Flughafen Zürich AG',
    name: 'Flughafen Zürich AG',
    symbol: 'FHZN.SW',
  },
  {
    value: 'FORN.SW',
    label: 'FORN.SW,  Forbo Holding AG',
    name: 'Forbo Holding AG',
    symbol: 'FORN.SW',
  },
  {
    value: 'FREN.SW',
    label: 'FREN.SW,  Fundamenta Real Estate AG',
    name: 'Fundamenta Real Estate AG',
    symbol: 'FREN.SW',
  },
  {
    value: 'GALE.SW',
    label: 'GALE.SW,  Galenica AG',
    name: 'Galenica AG',
    symbol: 'GALE.SW',
  },
  {
    value: 'GAM.SW',
    label: 'GAM.SW,  GAM Holding AG',
    name: 'GAM Holding AG',
    symbol: 'GAM.SW',
  },
  {
    value: 'GAV.SW',
    label: 'GAV.SW,  Carlo Gavazzi Holding AG',
    name: 'Carlo Gavazzi Holding AG',
    symbol: 'GAV.SW',
  },
  {
    value: 'GLKBN.SW',
    label: 'GLKBN.SW,  Glarner Kantonalbank',
    name: 'Glarner Kantonalbank',
    symbol: 'GLKBN.SW',
  },
  {
    value: 'GMI.SW',
    label: 'GMI.SW,  Groupe Minoteries SA',
    name: 'Groupe Minoteries SA',
    symbol: 'GMI.SW',
  },
  {
    value: 'GRKP.SW',
    label: 'GRKP.SW,  Graubündner Kantonalbank',
    name: 'Graubündner Kantonalbank',
    symbol: 'GRKP.SW',
  },
  {
    value: 'HBMN.SW',
    label: 'HBMN.SW,  HBM Healthcare Investments AG',
    name: 'HBM Healthcare Investments AG',
    symbol: 'HBMN.SW',
  },
  {
    value: 'HELN.SW',
    label: 'HELN.SW,  Helvetia Holding AG',
    name: 'Helvetia Holding AG',
    symbol: 'HELN.SW',
  },
  {
    value: 'HIAG.SW',
    label: 'HIAG.SW,  HIAG Immobilien Holding AG',
    name: 'HIAG Immobilien Holding AG',
    symbol: 'HIAG.SW',
  },
  {
    value: 'HLEE.SW',
    label: 'HLEE.SW,  Highlight Event and Entertainment AG',
    name: 'Highlight Event and Entertainment AG',
    symbol: 'HLEE.SW',
  },
  {
    value: 'HOCN.SW',
    label: 'HOCN.SW,  HOCHDORF Holding AG',
    name: 'HOCHDORF Holding AG',
    symbol: 'HOCN.SW',
  },
  {
    value: 'HUBN.SW',
    label: 'HUBN.SW,  Huber+Suhner AG',
    name: 'Huber+Suhner AG',
    symbol: 'HUBN.SW',
  },
  {
    value: 'HBLN.SW',
    label: 'HBLN.SW,  Hypothekarbank Lenzburg AG',
    name: 'Hypothekarbank Lenzburg AG',
    symbol: 'HBLN.SW',
  },
  {
    value: 'IDIA.SW',
    label: 'IDIA.SW,  Idorsia Ltd',
    name: 'Idorsia Ltd',
    symbol: 'IDIA.SW',
  },
  {
    value: 'IMPN.SW',
    label: 'IMPN.SW,  Implenia AG',
    name: 'Implenia AG',
    symbol: 'IMPN.SW',
  },
  {
    value: 'INA.SW',
    label: 'INA.SW,  Ina Invest Holding AG',
    name: 'Ina Invest Holding AG',
    symbol: 'INA.SW',
  },
  {
    value: 'IFCN.SW',
    label: 'IFCN.SW,  INFICON Holding AG',
    name: 'INFICON Holding AG',
    symbol: 'IFCN.SW',
  },
  {
    value: 'INRN.SW',
    label: 'INRN.SW,  Interroll Holding AG',
    name: 'Interroll Holding AG',
    symbol: 'INRN.SW',
  },
  {
    value: 'ISN.SW',
    label: 'ISN.SW,  Intershop Holding AG',
    name: 'Intershop Holding AG',
    symbol: 'ISN.SW',
  },
  {
    value: 'IREN.SW',
    label: 'IREN.SW,  Investis Holding SA',
    name: 'Investis Holding SA',
    symbol: 'IREN.SW',
  },
  {
    value: 'VBSN.SW',
    label: 'VBSN.SW,  IVF Hartmann Holding AG',
    name: 'IVF Hartmann Holding AG',
    symbol: 'VBSN.SW',
  },
  {
    value: 'JFN.SW',
    label: 'JFN.SW,  Jungfraubahn Holding AG',
    name: 'Jungfraubahn Holding AG',
    symbol: 'JFN.SW',
  },
  {
    value: 'KARN.SW',
    label: 'KARN.SW,  Kardex Holding AG',
    name: 'Kardex Holding AG',
    symbol: 'KARN.SW',
  },
  {
    value: 'KLIN.SW',
    label: 'KLIN.SW,  Klingelnberg AG',
    name: 'Klingelnberg AG',
    symbol: 'KLIN.SW',
  },
  {
    value: 'KOMN.SW',
    label: 'KOMN.SW,  Komax Holding AG',
    name: 'Komax Holding AG',
    symbol: 'KOMN.SW',
  },
  {
    value: 'KUD.SW',
    label: 'KUD.SW,  Kudelski SA',
    name: 'Kudelski SA',
    symbol: 'KUD.SW',
  },
  {
    value: 'KURN.SW',
    label: 'KURN.SW,  Kuros Biosciences AG',
    name: 'Kuros Biosciences AG',
    symbol: 'KURN.SW',
  },
  {
    value: 'LLQ.SW',
    label: 'LLQ.SW,  Lalique Group SA',
    name: 'Lalique Group SA',
    symbol: 'LLQ.SW',
  },
  {
    value: 'LAND.SW',
    label: 'LAND.SW,  Landis+Gyr Group AG',
    name: 'Landis+Gyr Group AG',
    symbol: 'LAND.SW',
  },
  {
    value: 'LECN.SW',
    label: 'LECN.SW,  Leclanché SA',
    name: 'Leclanché SA',
    symbol: 'LECN.SW',
  },
  {
    value: 'LEHN.SW',
    label: 'LEHN.SW,  LEM Holding SA',
    name: 'LEM Holding SA',
    symbol: 'LEHN.SW',
  },
  {
    value: 'LEON.SW',
    label: 'LEON.SW,  Leonteq AG',
    name: 'Leonteq AG',
    symbol: 'LEON.SW',
  },
  {
    value: 'LISN.SW',
    label: 'LISN.SW,  Chocoladefabriken Lindt & Sprüngli AG',
    name: 'Chocoladefabriken Lindt & Sprüngli AG',
    symbol: 'LISN.SW',
  },
  {
    value: 'LUKN.SW',
    label: 'LUKN.SW,  Luzerner Kantonalbank AG',
    name: 'Luzerner Kantonalbank AG',
    symbol: 'LUKN.SW',
  },
  {
    value: 'MCHN.SW',
    label: 'MCHN.SW,  MCH Group AG',
    name: 'MCH Group AG',
    symbol: 'MCHN.SW',
  },
  {
    value: 'MOVE.SW',
    label: 'MOVE.SW,  Medacta Group SA',
    name: 'Medacta Group SA',
    symbol: 'MOVE.SW',
  },
  {
    value: 'MED.SW',
    label: 'MED.SW,  Medartis Holding AG',
    name: 'Medartis Holding AG',
    symbol: 'MED.SW',
  },
  {
    value: 'MTG.SW',
    label: 'MTG.SW,  Meier Tobler Group AG',
    name: 'Meier Tobler Group AG',
    symbol: 'MTG.SW',
  },
  {
    value: 'METN.SW',
    label: 'METN.SW,  Metall Zug AG',
    name: 'Metall Zug AG',
    symbol: 'METN.SW',
  },
  {
    value: 'MBTN.SW',
    label: 'MBTN.SW,  Meyer Burger Technology AG',
    name: 'Meyer Burger Technology AG',
    symbol: 'MBTN.SW',
  },
  {
    value: 'MIKN.SW',
    label: 'MIKN.SW,  Mikron Holding AG',
    name: 'Mikron Holding AG',
    symbol: 'MIKN.SW',
  },
  {
    value: 'MOZN.SW',
    label: 'MOZN.SW,  mobilezone holding ag',
    name: 'mobilezone holding ag',
    symbol: 'MOZN.SW',
  },
  {
    value: 'MOBN.SW',
    label: 'MOBN.SW,  Mobimo Holding AG',
    name: 'Mobimo Holding AG',
    symbol: 'MOBN.SW',
  },
  {
    value: 'MOLN.SW',
    label: 'MOLN.SW,  Molecular Partners AG',
    name: 'Molecular Partners AG',
    symbol: 'MOLN.SW',
  },
  {
    value: 'NBEN.SW',
    label: 'NBEN.SW,  nebag ag',
    name: 'nebag ag',
    symbol: 'NBEN.SW',
  },
  {
    value: 'NREN.SW',
    label: 'NREN.SW,  Novavest Real Estate AG',
    name: 'Novavest Real Estate AG',
    symbol: 'NREN.SW',
  },
  {
    value: 'OFN.SW',
    label: 'OFN.SW,  Orell Füssli AG',
    name: 'Orell Füssli AG',
    symbol: 'OFN.SW',
  },
  {
    value: 'OBSN.SW',
    label: 'OBSN.SW,  ObsEva SA',
    name: 'ObsEva SA',
    symbol: 'OBSN.SW',
  },
  {
    value: 'OERL.SW',
    label: 'OERL.SW,  OC Oerlikon Corporation AG',
    name: 'OC Oerlikon Corporation AG',
    symbol: 'OERL.SW',
  },
  {
    value: 'ODHN.SW',
    label: 'ODHN.SW,  Orascom Development Holding AG',
    name: 'Orascom Development Holding AG',
    symbol: 'ODHN.SW',
  },
  {
    value: 'ORON.SW',
    label: 'ORON.SW,  ORIOR AG',
    name: 'ORIOR AG',
    symbol: 'ORON.SW',
  },
  {
    value: 'PEAN.SW',
    label: 'PEAN.SW,  Peach Property Group AG',
    name: 'Peach Property Group AG',
    symbol: 'PEAN.SW',
  },
  {
    value: 'PEDU.SW',
    label: 'PEDU.SW,  Perrot Duval Holding S.A.',
    name: 'Perrot Duval Holding S.A.',
    symbol: 'PEDU.SW',
  },
  {
    value: 'PM.SW',
    label: 'PM.SW,  Phoenix Mecano AG',
    name: 'Phoenix Mecano AG',
    symbol: 'PM.SW',
  },
  {
    value: 'PLAN.SW',
    label: 'PLAN.SW,  Plazza AG',
    name: 'Plazza AG',
    symbol: 'PLAN.SW',
  },
  {
    value: 'PEHN.SW',
    label: 'PEHN.SW,  Private Equity Holding AG',
    name: 'Private Equity Holding AG',
    symbol: 'PEHN.SW',
  },
  {
    value: 'PSPN.SW',
    label: 'PSPN.SW,  PSP Swiss Property AG',
    name: 'PSP Swiss Property AG',
    symbol: 'PSPN.SW',
  },
  {
    value: 'RLF.SW',
    label: 'RLF.SW,  Relief Therapeutics Holding AG',
    name: 'Relief Therapeutics Holding AG',
    symbol: 'RLF.SW',
  },
  {
    value: 'RIEN.SW',
    label: 'RIEN.SW,  Rieter Holding AG',
    name: 'Rieter Holding AG',
    symbol: 'RIEN.SW',
  },
  {
    value: 'HREN.SW',
    label: 'HREN.SW,  Romande Energie Holding SA',
    name: 'Romande Energie Holding SA',
    symbol: 'HREN.SW',
  },
  {
    value: 'SANN.SW',
    label: 'SANN.SW,  Santhera Pharmaceuticals Holding AG',
    name: 'Santhera Pharmaceuticals Holding AG',
    symbol: 'SANN.SW',
  },
  {
    value: 'SAHN.SW',
    label: 'SAHN.SW,  Schaffner Holding AG',
    name: 'Schaffner Holding AG',
    symbol: 'SAHN.SW',
  },
  {
    value: 'STRN.SW',
    label: 'STRN.SW,  Schlatter Industries AG',
    name: 'Schlatter Industries AG',
    symbol: 'STRN.SW',
  },
  {
    value: 'STLN.SW',
    label: 'STLN.SW,  Swiss Steel Holding AG',
    name: 'Swiss Steel Holding AG',
    symbol: 'STLN.SW',
  },
  {
    value: 'SWTQ.SW',
    label: 'SWTQ.SW,  Schweiter Technologies AG',
    name: 'Schweiter Technologies AG',
    symbol: 'SWTQ.SW',
  },
  {
    value: 'SENS.SW',
    label: 'SENS.SW,  Sensirion Holding AG',
    name: 'Sensirion Holding AG',
    symbol: 'SENS.SW',
  },
  {
    value: 'SFPN.SW',
    label: 'SFPN.SW,  SF Urban Properties AG',
    name: 'SF Urban Properties AG',
    symbol: 'SFPN.SW',
  },
  {
    value: 'SFSN.SW',
    label: 'SFSN.SW,  SFS Group AG',
    name: 'SFS Group AG',
    symbol: 'SFSN.SW',
  },
  {
    value: 'SFZN.SW',
    label: 'SFZN.SW,  Siegfried Holding AG',
    name: 'Siegfried Holding AG',
    symbol: 'SFZN.SW',
  },
  {
    value: 'SIGN.SW',
    label: 'SIGN.SW,  SIG Group AG',
    name: 'SIG Group AG',
    symbol: 'SIGN.SW',
  },
  {
    value: 'SNBN.SW',
    label: 'SNBN.SW,  Schweizerische Nationalbank',
    name: 'Schweizerische Nationalbank',
    symbol: 'SNBN.SW',
  },
  {
    value: 'SWON.SW',
    label: 'SWON.SW,  SoftwareONE Holding AG',
    name: 'SoftwareONE Holding AG',
    symbol: 'SWON.SW',
  },
  {
    value: 'SPCE.SW',
    label: 'SPCE.SW,  Spice Private Equity AG',
    name: 'Spice Private Equity AG',
    symbol: 'SPCE.SW',
  },
  {
    value: 'SGKN.SW',
    label: 'SGKN.SW,  St. Galler Kantonalbank AG',
    name: 'St. Galler Kantonalbank AG',
    symbol: 'SGKN.SW',
  },
  {
    value: 'SRAIL.SW',
    label: 'SRAIL.SW,  Stadler Rail AG',
    name: 'Stadler Rail AG',
    symbol: 'SRAIL.SW',
  },
  {
    value: 'STGN.SW',
    label: 'STGN.SW,  Starrag Group Holding AG',
    name: 'Starrag Group Holding AG',
    symbol: 'STGN.SW',
  },
  {
    value: 'STMN.SW',
    label: 'STMN.SW,  Straumann Holding AG',
    name: 'Straumann Holding AG',
    symbol: 'STMN.SW',
  },
  {
    value: 'SUN.SW',
    label: 'SUN.SW,  Sulzer Ltd',
    name: 'Sulzer Ltd',
    symbol: 'SUN.SW',
  },
  {
    value: 'SPSN.SW',
    label: 'SPSN.SW,  Swiss Prime Site AG',
    name: 'Swiss Prime Site AG',
    symbol: 'SPSN.SW',
  },
  {
    value: 'SQN.SW',
    label: 'SQN.SW,  Swissquote Group Holding Ltd',
    name: 'Swissquote Group Holding Ltd',
    symbol: 'SQN.SW',
  },
  {
    value: 'TECN.SW',
    label: 'TECN.SW,  Tecan Group AG',
    name: 'Tecan Group AG',
    symbol: 'TECN.SW',
  },
  {
    value: 'TKBP.SW',
    label: 'TKBP.SW,  Thurgauer Kantonalbank',
    name: 'Thurgauer Kantonalbank',
    symbol: 'TKBP.SW',
  },
  {
    value: 'TIBN.SW',
    label: 'TIBN.SW,  Bergbahnen Engelberg-Trübsee-Titlis AG',
    name: 'Bergbahnen Engelberg-Trübsee-Titlis AG',
    symbol: 'TIBN.SW',
  },
  {
    value: 'TOHN.SW',
    label: 'TOHN.SW,  Tornos Holding AG',
    name: 'Tornos Holding AG',
    symbol: 'TOHN.SW',
  },
  {
    value: 'TXGN.SW',
    label: 'TXGN.SW,  TX Group AG',
    name: 'TX Group AG',
    symbol: 'TXGN.SW',
  },
  {
    value: 'UBXN.SW',
    label: 'UBXN.SW,  u-blox Holding AG',
    name: 'u-blox Holding AG',
    symbol: 'UBXN.SW',
  },
  {
    value: 'VZUG.SW',
    label: 'VZUG.SW,  V-ZUG Holding AG',
    name: 'V-ZUG Holding AG',
    symbol: 'VZUG.SW',
  },
  {
    value: 'VLRT.SW',
    label: 'VLRT.SW,  Valartis Group AG',
    name: 'Valartis Group AG',
    symbol: 'VLRT.SW',
  },
  {
    value: 'VATN.SW',
    label: 'VATN.SW,  Valiant Holding AG',
    name: 'Valiant Holding AG',
    symbol: 'VATN.SW',
  },
  {
    value: 'VALN.SW',
    label: 'VALN.SW,  Valora Holding AG',
    name: 'Valora Holding AG',
    symbol: 'VALN.SW',
  },
  {
    value: 'VARN.SW',
    label: 'VARN.SW,  Varia US Properties AG',
    name: 'Varia US Properties AG',
    symbol: 'VARN.SW',
  },
  {
    value: 'VACN.SW',
    label: 'VACN.SW,  VAT Group AG',
    name: 'VAT Group AG',
    symbol: 'VACN.SW',
  },
  {
    value: 'VAHN.SW',
    label: 'VAHN.SW,  Vaudoise Assurances Holding SA',
    name: 'Vaudoise Assurances Holding SA',
    symbol: 'VAHN.SW',
  },
  {
    value: 'VETN.SW',
    label: 'VETN.SW,  Vetropack Holding AG',
    name: 'Vetropack Holding AG',
    symbol: 'VETN.SW',
  },
  {
    value: 'VILN.SW',
    label: 'VILN.SW,  Villars Holding S.A.',
    name: 'Villars Holding S.A.',
    symbol: 'VILN.SW',
  },
  {
    value: 'ROL.SW',
    label: 'ROL.SW,  Von Roll Holding AG',
    name: 'Von Roll Holding AG',
    symbol: 'ROL.SW',
  },
  {
    value: 'VONN.SW',
    label: 'VONN.SW,  Vontobel Holding AG',
    name: 'Vontobel Holding AG',
    symbol: 'VONN.SW',
  },
  {
    value: 'VZN.SW',
    label: 'VZN.SW,  VZ Holding AG',
    name: 'VZ Holding AG',
    symbol: 'VZN.SW',
  },
  {
    value: 'WKBN.SW',
    label: 'WKBN.SW,  Walliser Kantonalbank',
    name: 'Walliser Kantonalbank',
    symbol: 'WKBN.SW',
  },
  {
    value: 'WARN.SW',
    label: 'WARN.SW,  Warteck Invest AG',
    name: 'Warteck Invest AG',
    symbol: 'WARN.SW',
  },
  {
    value: 'WIHN.SW',
    label: 'WIHN.SW,  WISeKey International Holding AG',
    name: 'WISeKey International Holding AG',
    symbol: 'WIHN.SW',
  },
  {
    value: 'YPSN.SW',
    label: 'YPSN.SW,  Ypsomed Holding AG',
    name: 'Ypsomed Holding AG',
    symbol: 'YPSN.SW',
  },
  {
    value: 'ZEHN.SW',
    label: 'ZEHN.SW,  Zehnder Group AG',
    name: 'Zehnder Group AG',
    symbol: 'ZEHN.SW',
  },
  {
    value: 'ZUBN.SW',
    label: 'ZUBN.SW,  Züblin Immobilien Holding AG',
    name: 'Züblin Immobilien Holding AG',
    symbol: 'ZUBN.SW',
  },
  {
    value: 'ZUGN.SW',
    label: 'ZUGN.SW,  Zug Estates Holding AG',
    name: 'Zug Estates Holding AG',
    symbol: 'ZUGN.SW',
  },
  {
    value: 'ZUGER.SW',
    label: 'ZUGER.SW,  Zuger Kantonalbank',
    name: 'Zuger Kantonalbank',
    symbol: 'ZUGER.SW',
  },
  {
    value: 'ROSE.SW',
    label: 'ROSE.SW,  Zur Rose Group AG',
    name: 'Zur Rose Group AG',
    symbol: 'ROSE.SW',
  },
  {
    value: 'ZWM.SW',
    label: 'ZWM.SW,  Zwahlen & Mayr SA',
    name: 'Zwahlen & Mayr SA',
    symbol: 'ZWM.SW',
  },
  {
    value: 'ABBN.SW',
    label: 'ABBN.SW,  ABB Ltd',
    name: 'ABB Ltd',
    symbol: 'ABBN.SW',
  },
  {
    value: 'ADEN.SW',
    label: 'ADEN.SW,  Adecco Group AG',
    name: 'Adecco Group AG',
    symbol: 'ADEN.SW',
  },
  {
    value: 'ALC.SW',
    label: 'ALC.SW,  Alcon Inc.',
    name: 'Alcon Inc.',
    symbol: 'ALC.SW',
  },
  {
    value: 'AMS.SW',
    label: 'AMS.SW,  ams-OSRAM AG',
    name: 'ams-OSRAM AG',
    symbol: 'AMS.SW',
  },
  {
    value: 'CLN.SW',
    label: 'CLN.SW,  Clariant AG',
    name: 'Clariant AG',
    symbol: 'CLN.SW',
  },
  {
    value: 'CSGN.SW',
    label: 'CSGN.SW,  Credit Suisse Group AG',
    name: 'Credit Suisse Group AG',
    symbol: 'CSGN.SW',
  },
  {
    value: 'GEBN.SW',
    label: 'GEBN.SW,  Geberit AG',
    name: 'Geberit AG',
    symbol: 'GEBN.SW',
  },
  {
    value: 'GIVN.SW',
    label: 'GIVN.SW,  Givaudan SA',
    name: 'Givaudan SA',
    symbol: 'GIVN.SW',
  },
  {
    value: 'BAER.SW',
    label: 'BAER.SW,  Julius Bär Gruppe AG',
    name: 'Julius Bär Gruppe AG',
    symbol: 'BAER.SW',
  },
  {
    value: 'KNIN.SW',
    label: 'KNIN.SW,  Kuehne + Nagel International AG',
    name: 'Kuehne + Nagel International AG',
    symbol: 'KNIN.SW',
  },
  {
    value: 'LOGN.SW',
    label: 'LOGN.SW,  Logitech International S.A.',
    name: 'Logitech International S.A.',
    symbol: 'LOGN.SW',
  },
  {
    value: 'LONN.SW',
    label: 'LONN.SW,  Lonza Group AG',
    name: 'Lonza Group AG',
    symbol: 'LONN.SW',
  },
  {
    value: 'NESN.SW',
    label: 'NESN.SW,  Nestlé S.A.',
    name: 'Nestlé S.A.',
    symbol: 'NESN.SW',
  },
  {
    value: 'NOVN.SW',
    label: 'NOVN.SW,  Novartis AG',
    name: 'Novartis AG',
    symbol: 'NOVN.SW',
  },
  {
    value: 'PGHN.SW',
    label: 'PGHN.SW,  Partners Group Holding AG',
    name: 'Partners Group Holding AG',
    symbol: 'PGHN.SW',
  },
  {
    value: 'CFR.SW',
    label: 'CFR.SW,  Compagnie Financière Richemont SA',
    name: 'Compagnie Financière Richemont SA',
    symbol: 'CFR.SW',
  },
  {
    value: 'ROG.SW',
    label: 'ROG.SW,  Roche Holding AG',
    name: 'Roche Holding AG',
    symbol: 'ROG.SW',
  },
  {
    value: 'SCHP.SW',
    label: 'SCHP.SW,  Schindler Holding AG',
    name: 'Schindler Holding AG',
    symbol: 'SCHP.SW',
  },
  {
    value: 'SGSN.SW',
    label: 'SGSN.SW,  SGS SA',
    name: 'SGS SA',
    symbol: 'SGSN.SW',
  },
  {
    value: 'SIKA.SW',
    label: 'SIKA.SW,  Sika AG',
    name: 'Sika AG',
    symbol: 'SIKA.SW',
  },
  {
    value: 'SOON.SW',
    label: 'SOON.SW,  Sonova Holding AG',
    name: 'Sonova Holding AG',
    symbol: 'SOON.SW',
  },
  {
    value: 'UHR.SW',
    label: 'UHR.SW,  The Swatch Group AG',
    name: 'The Swatch Group AG',
    symbol: 'UHR.SW',
  },
  {
    value: 'SLHN.SW',
    label: 'SLHN.SW,  Swiss Life Holding AG',
    name: 'Swiss Life Holding AG',
    symbol: 'SLHN.SW',
  },
  {
    value: 'SREN.SW',
    label: 'SREN.SW,  Swiss Re AG',
    name: 'Swiss Re AG',
    symbol: 'SREN.SW',
  },
  {
    value: 'SCMN.SW',
    label: 'SCMN.SW,  Swisscom AG',
    name: 'Swisscom AG',
    symbol: 'SCMN.SW',
  },
  {
    value: 'TEMN.SW',
    label: 'TEMN.SW,  Temenos AG',
    name: 'Temenos AG',
    symbol: 'TEMN.SW',
  },
  {
    value: 'UBSG.SW',
    label: 'UBSG.SW,  UBS Group AG',
    name: 'UBS Group AG',
    symbol: 'UBSG.SW',
  },
  {
    value: 'VIFN.SW',
    label: 'VIFN.SW,  Vifor Pharma AG',
    name: 'Vifor Pharma AG',
    symbol: 'VIFN.SW',
  },
  {
    value: 'ZURN.SW',
    label: 'ZURN.SW,  Zurich Insurance Group AG',
    name: 'Zurich Insurance Group AG',
    symbol: 'ZURN.SW',
  },
  {
    value: 'MMM.SW',
    label: 'MMM.SW,  3M Company',
    name: '3M Company',
    symbol: 'MMM.SW',
  },
  {
    value: 'ABT.SW',
    label: 'ABT.SW,  Abbott Laboratories',
    name: 'Abbott Laboratories',
    symbol: 'ABT.SW',
  },
  {
    value: 'AAM.SW',
    label: 'AAM.SW,  Anglo American plc',
    name: 'Anglo American plc',
    symbol: 'AAM.SW',
  },
  {
    value: 'COPN.SW',
    label: 'COPN.SW,  Cosmo Pharmaceuticals N.V.',
    name: 'Cosmo Pharmaceuticals N.V.',
    symbol: 'COPN.SW',
  },
  {
    value: 'EPH.SW',
    label: 'EPH.SW,  EPH European Property Holdings Limited',
    name: 'EPH European Property Holdings Limited',
    symbol: 'EPH.SW',
  },
  {
    value: 'GE.SW',
    label: 'GE.SW,  General Electric Company',
    name: 'General Electric Company',
    symbol: 'GE.SW',
  },
  {
    value: 'GT.SW',
    label: 'GT.SW,  The Goodyear Tire & Rubber Company',
    name: 'The Goodyear Tire & Rubber Company',
    symbol: 'GT.SW',
  },
  {
    value: 'IGPH.SW',
    label: 'IGPH.SW,  IGEA Pharma N.V.',
    name: 'IGEA Pharma N.V.',
    symbol: 'IGPH.SW',
  },
  {
    value: 'LMN.SW',
    label: 'LMN.SW,  lastminute.com N.V.',
    name: 'lastminute.com N.V.',
    symbol: 'LMN.SW',
  },
  {
    value: 'LLBN.SW',
    label: 'LLBN.SW,  Liechtensteinische Landesbank Aktiengesellschaft',
    name: 'Liechtensteinische Landesbank Aktiengesellschaft',
    symbol: 'LLBN.SW',
  },
  {
    value: 'LLY.SW',
    label: 'LLY.SW,  Eli Lilly and Company',
    name: 'Eli Lilly and Company',
    symbol: 'LLY.SW',
  },
  {
    value: 'NWRN.SW',
    label: 'NWRN.SW,  Newron Pharmaceuticals S.p.A.',
    name: 'Newron Pharmaceuticals S.p.A.',
    symbol: 'NWRN.SW',
  },
  {
    value: 'PEP.SW',
    label: 'PEP.SW,  PepsiCo, Inc.',
    name: 'PepsiCo, Inc.',
    symbol: 'PEP.SW',
  },
  {
    value: 'PMAG.SW',
    label: 'PMAG.SW,  PIERER Mobility AG',
    name: 'PIERER Mobility AG',
    symbol: 'PMAG.SW',
  },
  {
    value: 'SHLTN.SW',
    label: 'SHLTN.SW,  SHL Telemedicine Ltd.',
    name: 'SHL Telemedicine Ltd.',
    symbol: 'SHLTN.SW',
  },
  {
    value: 'VPBN.SW',
    label: 'VPBN.SW,  VP Bank AG',
    name: 'VP Bank AG',
    symbol: 'VPBN.SW',
  },
  {
    value: 'ZBH.SW',
    label: 'ZBH.SW,  Zimmer Biomet Holdings, Inc.',
    name: 'Zimmer Biomet Holdings, Inc.',
    symbol: 'ZBH.SW',
  },
  {
    value: '1001.HK',
    label: '1001.HK,  Hong Kong Shanghai Alliance Holdings Limited',
    name: 'Hong Kong Shanghai Alliance Holdings Limited',
    symbol: '1001.HK',
  },
  {
    value: '1002.HK',
    label: '1002.HK,  V.S. International Group Limited',
    name: 'V.S. International Group Limited',
    symbol: '1002.HK',
  },
  {
    value: '1003.HK',
    label: '1003.HK,  Huanxi Media Group Limited',
    name: 'Huanxi Media Group Limited',
    symbol: '1003.HK',
  },
  {
    value: '1005.HK',
    label: '1005.HK,  Matrix Holdings Limited',
    name: 'Matrix Holdings Limited',
    symbol: '1005.HK',
  },
  {
    value: '1007.HK',
    label: '1007.HK,  Longhui International Holdings Limited',
    name: 'Longhui International Holdings Limited',
    symbol: '1007.HK',
  },
  {
    value: '1008.HK',
    label: '1008.HK,  Litu Holdings Limited',
    name: 'Litu Holdings Limited',
    symbol: '1008.HK',
  },
  {
    value: '1009.HK',
    label: '1009.HK,  International Entertainment Corporation',
    name: 'International Entertainment Corporation',
    symbol: '1009.HK',
  },
  {
    value: '1010.HK',
    label: '1010.HK,  PacRay International Holdings Limited',
    name: 'PacRay International Holdings Limited',
    symbol: '1010.HK',
  },
  {
    value: '1011.HK',
    label: '1011.HK,  China NT Pharma Group Company Limited',
    name: 'China NT Pharma Group Company Limited',
    symbol: '1011.HK',
  },
  {
    value: '1013.HK',
    label: '1013.HK,  Wai Chun Group Holdings Limited',
    name: 'Wai Chun Group Holdings Limited',
    symbol: '1013.HK',
  },
  {
    value: '1020.HK',
    label: '1020.HK,  Cybernaut International Holdings Company Limited',
    name: 'Cybernaut International Holdings Company Limited',
    symbol: '1020.HK',
  },
  {
    value: '1022.HK',
    label: '1022.HK,  Feiyu Technology International Company Ltd.',
    name: 'Feiyu Technology International Company Ltd.',
    symbol: '1022.HK',
  },
  {
    value: '1023.HK',
    label: '1023.HK,  Sitoy Group Holdings Limited',
    name: 'Sitoy Group Holdings Limited',
    symbol: '1023.HK',
  },
  {
    value: '1025.HK',
    label: '1025.HK,  KNT Holdings Limited',
    name: 'KNT Holdings Limited',
    symbol: '1025.HK',
  },
  {
    value: '1026.HK',
    label: '1026.HK,  Universal Technologies Holdings Limited',
    name: 'Universal Technologies Holdings Limited',
    symbol: '1026.HK',
  },
  {
    value: '1027.HK',
    label: '1027.HK,  China Jicheng Holdings Limited',
    name: 'China Jicheng Holdings Limited',
    symbol: '1027.HK',
  },
  {
    value: '1028.HK',
    label: '1028.HK,  C.banner International Holdings Limited',
    name: 'C.banner International Holdings Limited',
    symbol: '1028.HK',
  },
  {
    value: '1029.HK',
    label: '1029.HK,  IRC Limited',
    name: 'IRC Limited',
    symbol: '1029.HK',
  },
  {
    value: '1030.HK',
    label: '1030.HK,  Seazen Group Limited',
    name: 'Seazen Group Limited',
    symbol: '1030.HK',
  },
  {
    value: '1031.HK',
    label: '1031.HK,  Kingston Financial Group Limited',
    name: 'Kingston Financial Group Limited',
    symbol: '1031.HK',
  },
  {
    value: '1034.HK',
    label: '1034.HK,  Fullwealth International Group Holdings Limited',
    name: 'Fullwealth International Group Holdings Limited',
    symbol: '1034.HK',
  },
  {
    value: '1036.HK',
    label: '1036.HK,  Vanke Overseas Investment Holding Company Limited',
    name: 'Vanke Overseas Investment Holding Company Limited',
    symbol: '1036.HK',
  },
  {
    value: '1037.HK',
    label: '1037.HK,  Maxnerva Technology Services Limited',
    name: 'Maxnerva Technology Services Limited',
    symbol: '1037.HK',
  },
  {
    value: '1038.HK',
    label: '1038.HK,  CK Infrastructure Holdings Limited',
    name: 'CK Infrastructure Holdings Limited',
    symbol: '1038.HK',
  },
  {
    value: '1039.HK',
    label: '1039.HK,  Changyou Alliance Group Limited',
    name: 'Changyou Alliance Group Limited',
    symbol: '1039.HK',
  },
  {
    value: '1044.HK',
    label: '1044.HK,  Hengan International Group Company Limited',
    name: 'Hengan International Group Company Limited',
    symbol: '1044.HK',
  },
  {
    value: '1045.HK',
    label: '1045.HK,  APT Satellite Holdings Limited',
    name: 'APT Satellite Holdings Limited',
    symbol: '1045.HK',
  },
  {
    value: '1046.HK',
    label: '1046.HK,  Universe Entertainment and Culture Group Company Limited',
    name: 'Universe Entertainment and Culture Group Company Limited',
    symbol: '1046.HK',
  },
  {
    value: '1047.HK',
    label: '1047.HK,  Ngai Hing Hong Company Limited',
    name: 'Ngai Hing Hong Company Limited',
    symbol: '1047.HK',
  },
  {
    value: '1049.HK',
    label: '1049.HK,  Celestial Asia Securities Holdings Limited',
    name: 'Celestial Asia Securities Holdings Limited',
    symbol: '1049.HK',
  },
  {
    value: '1050.HK',
    label: '1050.HK,  Karrie International Holdings Limited',
    name: 'Karrie International Holdings Limited',
    symbol: '1050.HK',
  },
  {
    value: '1051.HK',
    label: '1051.HK,  G-Resources Group Limited',
    name: 'G-Resources Group Limited',
    symbol: '1051.HK',
  },
  {
    value: '1052.HK',
    label: '1052.HK,  Yuexiu Transport Infrastructure Limited',
    name: 'Yuexiu Transport Infrastructure Limited',
    symbol: '1052.HK',
  },
  {
    value: '1058.HK',
    label: '1058.HK,  Namyue Holdings Limited',
    name: 'Namyue Holdings Limited',
    symbol: '1058.HK',
  },
  {
    value: '1059.HK',
    label: '1059.HK,  Kantone Holdings Limited',
    name: 'Kantone Holdings Limited',
    symbol: '1059.HK',
  },
  {
    value: '1060.HK',
    label: '1060.HK,  Alibaba Pictures Group Limited',
    name: 'Alibaba Pictures Group Limited',
    symbol: '1060.HK',
  },
  {
    value: '1061.HK',
    label: '1061.HK,  Essex Bio-Technology Limited',
    name: 'Essex Bio-Technology Limited',
    symbol: '1061.HK',
  },
  {
    value: '1062.HK',
    label: '1062.HK,  China Development Bank International Investment Ltd.',
    name: 'China Development Bank International Investment Ltd.',
    symbol: '1062.HK',
  },
  {
    value: '1063.HK',
    label: '1063.HK,  Suncorp Technologies Limited',
    name: 'Suncorp Technologies Limited',
    symbol: '1063.HK',
  },
  {
    value: '1064.HK',
    label: '1064.HK,  Zhong Hua International Holdings Limited',
    name: 'Zhong Hua International Holdings Limited',
    symbol: '1064.HK',
  },
  {
    value: '1066.HK',
    label: '1066.HK,  Shandong Weigao Group Medical Polymer Company Limited',
    name: 'Shandong Weigao Group Medical Polymer Company Limited',
    symbol: '1066.HK',
  },
  {
    value: '1068.HK',
    label: '1068.HK,  China Yurun Food Group Limited',
    name: 'China Yurun Food Group Limited',
    symbol: '1068.HK',
  },
  {
    value: '1070.HK',
    label: '1070.HK,  TCL Electronics Holdings Limited',
    name: 'TCL Electronics Holdings Limited',
    symbol: '1070.HK',
  },
  {
    value: '1073.HK',
    label: '1073.HK,  Da Yu Financial Holdings Limited',
    name: 'Da Yu Financial Holdings Limited',
    symbol: '1073.HK',
  },
  {
    value: '1075.HK',
    label: '1075.HK,  Capinfo Company Limited',
    name: 'Capinfo Company Limited',
    symbol: '1075.HK',
  },
  {
    value: '1079.HK',
    label: '1079.HK,  PINE Technology Holdings Limited',
    name: 'PINE Technology Holdings Limited',
    symbol: '1079.HK',
  },
  {
    value: '1080.HK',
    label: '1080.HK,  Shengli Oil & Gas Pipe Holdings Limited',
    name: 'Shengli Oil & Gas Pipe Holdings Limited',
    symbol: '1080.HK',
  },
  {
    value: '1082.HK',
    label: "1082.HK,  Hong Kong Education (Int'l) Investments Limited",
    name: "Hong Kong Education (Int'l) Investments Limited",
    symbol: '1082.HK',
  },
  {
    value: '1083.HK',
    label: '1083.HK,  Towngas Smart Energy Company Limited',
    name: 'Towngas Smart Energy Company Limited',
    symbol: '1083.HK',
  },
  {
    value: '1084.HK',
    label:
      '1084.HK,  Green Future Food Hydrocolloid Marine Science Company Limited',
    name: 'Green Future Food Hydrocolloid Marine Science Company Limited',
    symbol: '1084.HK',
  },
  {
    value: '1085.HK',
    label: '1085.HK,  Hengxin Technology Ltd.',
    name: 'Hengxin Technology Ltd.',
    symbol: '1085.HK',
  },
  {
    value: '1086.HK',
    label: '1086.HK,  Goodbaby International Holdings Limited',
    name: 'Goodbaby International Holdings Limited',
    symbol: '1086.HK',
  },
  {
    value: '1087.HK',
    label: '1087.HK,  InvesTech Holdings Limited',
    name: 'InvesTech Holdings Limited',
    symbol: '1087.HK',
  },
  {
    value: '1090.HK',
    label: '1090.HK,  Da Ming International Holdings Limited',
    name: 'Da Ming International Holdings Limited',
    symbol: '1090.HK',
  },
  {
    value: '1091.HK',
    label: '1091.HK,  South Manganese Investment Limited',
    name: 'South Manganese Investment Limited',
    symbol: '1091.HK',
  },
  {
    value: '1093.HK',
    label: '1093.HK,  CSPC Pharmaceutical Group Limited',
    name: 'CSPC Pharmaceutical Group Limited',
    symbol: '1093.HK',
  },
  {
    value: '1094.HK',
    label: '1094.HK,  Cherish Sunshine International Limited',
    name: 'Cherish Sunshine International Limited',
    symbol: '1094.HK',
  },
  {
    value: '1097.HK',
    label: '1097.HK,  i-CABLE Communications Limited',
    name: 'i-CABLE Communications Limited',
    symbol: '1097.HK',
  },
  {
    value: '1098.HK',
    label: '1098.HK,  Road King Infrastructure Limited',
    name: 'Road King Infrastructure Limited',
    symbol: '1098.HK',
  },
  {
    value: '1099.HK',
    label: '1099.HK,  Sinopharm Group Co. Ltd.',
    name: 'Sinopharm Group Co. Ltd.',
    symbol: '1099.HK',
  },
  {
    value: '1100.HK',
    label: '1100.HK,  Mainland Headwear Holdings Limited',
    name: 'Mainland Headwear Holdings Limited',
    symbol: '1100.HK',
  },
  {
    value: '1101.HK',
    label: '1101.HK,  China Huarong Energy Company Limited',
    name: 'China Huarong Energy Company Limited',
    symbol: '1101.HK',
  },
  {
    value: '1104.HK',
    label: '1104.HK,  APAC Resources Limited',
    name: 'APAC Resources Limited',
    symbol: '1104.HK',
  },
  {
    value: '1105.HK',
    label: '1105.HK,  Sing Tao News Corporation Limited',
    name: 'Sing Tao News Corporation Limited',
    symbol: '1105.HK',
  },
  {
    value: '1109.HK',
    label: '1109.HK,  China Resources Land Limited',
    name: 'China Resources Land Limited',
    symbol: '1109.HK',
  },
  {
    value: '1110.HK',
    label: '1110.HK,  Kingworld Medicines Group Limited',
    name: 'Kingworld Medicines Group Limited',
    symbol: '1110.HK',
  },
  {
    value: '1112.HK',
    label:
      '1112.HK,  Health and Happiness (H&H) International Holdings Limited',
    name: 'Health and Happiness (H&H) International Holdings Limited',
    symbol: '1112.HK',
  },
  {
    value: '1113.HK',
    label: '1113.HK,  CK Asset Holdings Limited',
    name: 'CK Asset Holdings Limited',
    symbol: '1113.HK',
  },
  {
    value: '1114.HK',
    label: '1114.HK,  Brilliance China Automotive Holdings Limited',
    name: 'Brilliance China Automotive Holdings Limited',
    symbol: '1114.HK',
  },
  {
    value: '1115.HK',
    label: '1115.HK,  Tibet Water Resources Ltd.',
    name: 'Tibet Water Resources Ltd.',
    symbol: '1115.HK',
  },
  {
    value: '1116.HK',
    label: '1116.HK,  Mayer Holdings Limited',
    name: 'Mayer Holdings Limited',
    symbol: '1116.HK',
  },
  {
    value: '1117.HK',
    label: '1117.HK,  China Modern Dairy Holdings Ltd.',
    name: 'China Modern Dairy Holdings Ltd.',
    symbol: '1117.HK',
  },
  {
    value: '1118.HK',
    label: '1118.HK,  Golik Holdings Limited',
    name: 'Golik Holdings Limited',
    symbol: '1118.HK',
  },
  {
    value: '1119.HK',
    label: '1119.HK,  iDreamSky Technology Holdings Limited',
    name: 'iDreamSky Technology Holdings Limited',
    symbol: '1119.HK',
  },
  {
    value: '1120.HK',
    label: '1120.HK,  Arts Optical International Holdings Limited',
    name: 'Arts Optical International Holdings Limited',
    symbol: '1120.HK',
  },
  {
    value: '1121.HK',
    label: '1121.HK,  Golden Solar New Energy Technology Holdings Limited',
    name: 'Golden Solar New Energy Technology Holdings Limited',
    symbol: '1121.HK',
  },
  {
    value: '1122.HK',
    label: '1122.HK,  Qingling Motors Co., Ltd.',
    name: 'Qingling Motors Co., Ltd.',
    symbol: '1122.HK',
  },
  {
    value: '1123.HK',
    label: '1123.HK,  China-Hongkong Photo Products Holdings Limited',
    name: 'China-Hongkong Photo Products Holdings Limited',
    symbol: '1123.HK',
  },
  {
    value: '1124.HK',
    label: '1124.HK,  Coastal Greenland Limited',
    name: 'Coastal Greenland Limited',
    symbol: '1124.HK',
  },
  {
    value: '1126.HK',
    label: '1126.HK,  Dream International Limited',
    name: 'Dream International Limited',
    symbol: '1126.HK',
  },
  {
    value: '1127.HK',
    label: '1127.HK,  Lion Rock Group Limited',
    name: 'Lion Rock Group Limited',
    symbol: '1127.HK',
  },
  {
    value: '1128.HK',
    label: '1128.HK,  Wynn Macau, Limited',
    name: 'Wynn Macau, Limited',
    symbol: '1128.HK',
  },
  {
    value: '1129.HK',
    label: '1129.HK,  China Water Industry Group Limited',
    name: 'China Water Industry Group Limited',
    symbol: '1129.HK',
  },
  {
    value: '1130.HK',
    label: '1130.HK,  China Environmental Resources Group Limited',
    name: 'China Environmental Resources Group Limited',
    symbol: '1130.HK',
  },
  {
    value: '1132.HK',
    label:
      '1132.HK,  Orange Sky Golden Harvest Entertainment (Holdings) Limited',
    name: 'Orange Sky Golden Harvest Entertainment (Holdings) Limited',
    symbol: '1132.HK',
  },
  {
    value: '1133.HK',
    label: '1133.HK,  Harbin Electric Company Limited',
    name: 'Harbin Electric Company Limited',
    symbol: '1133.HK',
  },
  {
    value: '1134.HK',
    label: '1134.HK,  Kelfred Holdings Limited',
    name: 'Kelfred Holdings Limited',
    symbol: '1134.HK',
  },
  {
    value: '1137.HK',
    label: '1137.HK,  Hong Kong Technology Venture Company Limited',
    name: 'Hong Kong Technology Venture Company Limited',
    symbol: '1137.HK',
  },
  {
    value: '1140.HK',
    label: '1140.HK,  Wealthking Investments Limited',
    name: 'Wealthking Investments Limited',
    symbol: '1140.HK',
  },
  {
    value: '1141.HK',
    label: '1141.HK,  CMBC Capital Holdings Limited',
    name: 'CMBC Capital Holdings Limited',
    symbol: '1141.HK',
  },
  {
    value: '1142.HK',
    label: '1142.HK,  E&P Global Holdings Limited',
    name: 'E&P Global Holdings Limited',
    symbol: '1142.HK',
  },
  {
    value: '1143.HK',
    label: '1143.HK,  Link-Asia International MedTech Group Limited',
    name: 'Link-Asia International MedTech Group Limited',
    symbol: '1143.HK',
  },
  {
    value: '1145.HK',
    label: '1145.HK,  Courage Investment Group Limited',
    name: 'Courage Investment Group Limited',
    symbol: '1145.HK',
  },
  {
    value: '1147.HK',
    label: '1147.HK,  Edensoft Holdings Limited',
    name: 'Edensoft Holdings Limited',
    symbol: '1147.HK',
  },
  {
    value: '1148.HK',
    label: '1148.HK,  Xinchen China Power Holdings Limited',
    name: 'Xinchen China Power Holdings Limited',
    symbol: '1148.HK',
  },
  {
    value: '1150.HK',
    label: '1150.HK,  Milan Station Holdings Limited',
    name: 'Milan Station Holdings Limited',
    symbol: '1150.HK',
  },
  {
    value: '1159.HK',
    label: '1159.HK,  Starlight Culture Entertainment Group Limited',
    name: 'Starlight Culture Entertainment Group Limited',
    symbol: '1159.HK',
  },
  {
    value: '1161.HK',
    label: '1161.HK,  Water Oasis Group Limited',
    name: 'Water Oasis Group Limited',
    symbol: '1161.HK',
  },
  {
    value: '1162.HK',
    label: '1162.HK,  Lumina Group Limited',
    name: 'Lumina Group Limited',
    symbol: '1162.HK',
  },
  {
    value: '1164.HK',
    label: '1164.HK,  CGN Mining Company Limited',
    name: 'CGN Mining Company Limited',
    symbol: '1164.HK',
  },
  {
    value: '1165.HK',
    label: '1165.HK,  Shunfeng International Clean Energy Limited',
    name: 'Shunfeng International Clean Energy Limited',
    symbol: '1165.HK',
  },
  {
    value: '1166.HK',
    label: '1166.HK,  Solartech International Holdings Limited',
    name: 'Solartech International Holdings Limited',
    symbol: '1166.HK',
  },
  {
    value: '1168.HK',
    label: '1168.HK,  Sinolink Worldwide Holdings Limited',
    name: 'Sinolink Worldwide Holdings Limited',
    symbol: '1168.HK',
  },
  {
    value: '1170.HK',
    label: '1170.HK,  Kingmaker Footwear Holdings Limited',
    name: 'Kingmaker Footwear Holdings Limited',
    symbol: '1170.HK',
  },
  {
    value: '1172.HK',
    label: '1172.HK,  Magnus Concordia Group Limited',
    name: 'Magnus Concordia Group Limited',
    symbol: '1172.HK',
  },
  {
    value: '1173.HK',
    label: '1173.HK,  Veeko International Holdings Limited',
    name: 'Veeko International Holdings Limited',
    symbol: '1173.HK',
  },
  {
    value: '1176.HK',
    label: '1176.HK,  Zhuguang Holdings Group Company Limited',
    name: 'Zhuguang Holdings Group Company Limited',
    symbol: '1176.HK',
  },
  {
    value: '1177.HK',
    label: '1177.HK,  Sino Biopharmaceutical Limited',
    name: 'Sino Biopharmaceutical Limited',
    symbol: '1177.HK',
  },
  {
    value: '1180.HK',
    label: '1180.HK,  Paradise Entertainment Limited',
    name: 'Paradise Entertainment Limited',
    symbol: '1180.HK',
  },
  {
    value: '1181.HK',
    label: '1181.HK,  Tang Palace (China) Holdings Limited',
    name: 'Tang Palace (China) Holdings Limited',
    symbol: '1181.HK',
  },
  {
    value: '1182.HK',
    label: '1182.HK,  Success Dragon International Holdings Limited',
    name: 'Success Dragon International Holdings Limited',
    symbol: '1182.HK',
  },
  {
    value: '1183.HK',
    label: '1183.HK,  MECOM Power and Construction Limited',
    name: 'MECOM Power and Construction Limited',
    symbol: '1183.HK',
  },
  {
    value: '1184.HK',
    label: '1184.HK,  S.A.S. Dragon Holdings Limited',
    name: 'S.A.S. Dragon Holdings Limited',
    symbol: '1184.HK',
  },
  {
    value: '1185.HK',
    label: '1185.HK,  China Energine International (Holdings) Limited',
    name: 'China Energine International (Holdings) Limited',
    symbol: '1185.HK',
  },
  {
    value: '1193.HK',
    label: '1193.HK,  China Resources Gas Group Limited',
    name: 'China Resources Gas Group Limited',
    symbol: '1193.HK',
  },
  {
    value: '1195.HK',
    label: '1195.HK,  Kingwell Group Limited',
    name: 'Kingwell Group Limited',
    symbol: '1195.HK',
  },
  {
    value: '1196.HK',
    label: '1196.HK,  Realord Group Holdings Limited',
    name: 'Realord Group Holdings Limited',
    symbol: '1196.HK',
  },
  {
    value: '1198.HK',
    label: '1198.HK,  Royale Home Holdings Limited',
    name: 'Royale Home Holdings Limited',
    symbol: '1198.HK',
  },
  {
    value: '1199.HK',
    label: '1199.HK,  COSCO SHIPPING Ports Limited',
    name: 'COSCO SHIPPING Ports Limited',
    symbol: '1199.HK',
  },
  {
    value: '1200.HK',
    label: '1200.HK,  Midland Holdings Limited',
    name: 'Midland Holdings Limited',
    symbol: '1200.HK',
  },
  {
    value: '1201.HK',
    label: '1201.HK,  Tesson Holdings Limited',
    name: 'Tesson Holdings Limited',
    symbol: '1201.HK',
  },
  {
    value: '1202.HK',
    label: '1202.HK,  Chengdu SIWI Science and Technology Company Limited',
    name: 'Chengdu SIWI Science and Technology Company Limited',
    symbol: '1202.HK',
  },
  {
    value: '1203.HK',
    label: '1203.HK,  GDH Guangnan (Holdings) Limited',
    name: 'GDH Guangnan (Holdings) Limited',
    symbol: '1203.HK',
  },
  {
    value: '1205.HK',
    label: '1205.HK,  CITIC Resources Holdings Limited',
    name: 'CITIC Resources Holdings Limited',
    symbol: '1205.HK',
  },
  {
    value: '1206.HK',
    label: '1206.HK,  Technovator International Limited',
    name: 'Technovator International Limited',
    symbol: '1206.HK',
  },
  {
    value: '1207.HK',
    label: '1207.HK,  SRE Group Limited',
    name: 'SRE Group Limited',
    symbol: '1207.HK',
  },
  {
    value: '1208.HK',
    label: '1208.HK,  MMG Limited',
    name: 'MMG Limited',
    symbol: '1208.HK',
  },
  {
    value: '1210.HK',
    label: '1210.HK,  Christine International Holdings Limited',
    name: 'Christine International Holdings Limited',
    symbol: '1210.HK',
  },
  {
    value: '1212.HK',
    label: '1212.HK,  Lifestyle International Holdings Limited',
    name: 'Lifestyle International Holdings Limited',
    symbol: '1212.HK',
  },
  {
    value: '1215.HK',
    label: '1215.HK,  Kai Yuan Holdings Limited',
    name: 'Kai Yuan Holdings Limited',
    symbol: '1215.HK',
  },
  {
    value: '1216.HK',
    label: '1216.HK,  Zhongyuan Bank Co., Ltd.',
    name: 'Zhongyuan Bank Co., Ltd.',
    symbol: '1216.HK',
  },
  {
    value: '1217.HK',
    label: '1217.HK,  China Innovation Investment Limited',
    name: 'China Innovation Investment Limited',
    symbol: '1217.HK',
  },
  {
    value: '1218.HK',
    label: '1218.HK,  Easyknit International Holdings Limited',
    name: 'Easyknit International Holdings Limited',
    symbol: '1218.HK',
  },
  {
    value: '1221.HK',
    label: '1221.HK,  Sino Hotels (Holdings) Limited',
    name: 'Sino Hotels (Holdings) Limited',
    symbol: '1221.HK',
  },
  {
    value: '1222.HK',
    label: '1222.HK,  Wang On Group Limited',
    name: 'Wang On Group Limited',
    symbol: '1222.HK',
  },
  {
    value: '1223.HK',
    label: '1223.HK,  Symphony Holdings Limited',
    name: 'Symphony Holdings Limited',
    symbol: '1223.HK',
  },
  {
    value: '1224.HK',
    label: '1224.HK,  C C Land Holdings Limited',
    name: 'C C Land Holdings Limited',
    symbol: '1224.HK',
  },
  {
    value: '1229.HK',
    label: '1229.HK,  Nan Nan Resources Enterprise Limited',
    name: 'Nan Nan Resources Enterprise Limited',
    symbol: '1229.HK',
  },
  {
    value: '1230.HK',
    label: '1230.HK,  Yashili International Holdings Ltd',
    name: 'Yashili International Holdings Ltd',
    symbol: '1230.HK',
  },
  {
    value: '1232.HK',
    label: '1232.HK,  Golden Wheel Tiandi Holdings Company Limited',
    name: 'Golden Wheel Tiandi Holdings Company Limited',
    symbol: '1232.HK',
  },
  {
    value: '1233.HK',
    label: '1233.HK,  Times China Holdings Limited',
    name: 'Times China Holdings Limited',
    symbol: '1233.HK',
  },
  {
    value: '1234.HK',
    label: '1234.HK,  China Lilang Limited',
    name: 'China Lilang Limited',
    symbol: '1234.HK',
  },
  {
    value: '1235.HK',
    label: '1235.HK,  Travel Expert (Asia) Enterprises Limited',
    name: 'Travel Expert (Asia) Enterprises Limited',
    symbol: '1235.HK',
  },
  {
    value: '1237.HK',
    label:
      '1237.HK,  China Environmental Technology and Bioenergy Holdings Limited',
    name: 'China Environmental Technology and Bioenergy Holdings Limited',
    symbol: '1237.HK',
  },
  {
    value: '1238.HK',
    label: '1238.HK,  Powerlong Real Estate Holdings Limited',
    name: 'Powerlong Real Estate Holdings Limited',
    symbol: '1238.HK',
  },
  {
    value: '1239.HK',
    label: '1239.HK,  Teamway International Group Holdings Limited',
    name: 'Teamway International Group Holdings Limited',
    symbol: '1239.HK',
  },
  {
    value: '1240.HK',
    label: '1240.HK,  CNQC International Holdings Limited',
    name: 'CNQC International Holdings Limited',
    symbol: '1240.HK',
  },
  {
    value: '1241.HK',
    label: '1241.HK,  Shuanghua Holdings Limited',
    name: 'Shuanghua Holdings Limited',
    symbol: '1241.HK',
  },
  {
    value: '1243.HK',
    label: '1243.HK,  Wang On Properties Limited',
    name: 'Wang On Properties Limited',
    symbol: '1243.HK',
  },
  {
    value: '1245.HK',
    label: '1245.HK,  Niraku GC Holdings, Inc.',
    name: 'Niraku GC Holdings, Inc.',
    symbol: '1245.HK',
  },
  {
    value: '1246.HK',
    label: '1246.HK,  Boill Healthcare Holdings Limited',
    name: 'Boill Healthcare Holdings Limited',
    symbol: '1246.HK',
  },
  {
    value: '1247.HK',
    label: '1247.HK,  Miko International Holdings Limited',
    name: 'Miko International Holdings Limited',
    symbol: '1247.HK',
  },
  {
    value: '1250.HK',
    label: '1250.HK,  Shandong Hi-Speed New Energy Group Limited',
    name: 'Shandong Hi-Speed New Energy Group Limited',
    symbol: '1250.HK',
  },
  {
    value: '1251.HK',
    label: '1251.HK,  SPT Energy Group Inc.',
    name: 'SPT Energy Group Inc.',
    symbol: '1251.HK',
  },
  {
    value: '1252.HK',
    label: '1252.HK,  China Tianrui Group Cement Company Limited',
    name: 'China Tianrui Group Cement Company Limited',
    symbol: '1252.HK',
  },
  {
    value: '1253.HK',
    label: '1253.HK,  China Greenland Broad Greenstate Group Company Limited',
    name: 'China Greenland Broad Greenstate Group Company Limited',
    symbol: '1253.HK',
  },
  {
    value: '1255.HK',
    label: '1255.HK,  TATA Health International Holdings Limited',
    name: 'TATA Health International Holdings Limited',
    symbol: '1255.HK',
  },
  {
    value: '1257.HK',
    label: '1257.HK,  China Everbright Greentech Limited',
    name: 'China Everbright Greentech Limited',
    symbol: '1257.HK',
  },
  {
    value: '1258.HK',
    label: '1258.HK,  China Nonferrous Mining Corporation Limited',
    name: 'China Nonferrous Mining Corporation Limited',
    symbol: '1258.HK',
  },
  {
    value: '1259.HK',
    label: '1259.HK,  Prosperous Future Holdings Limited',
    name: 'Prosperous Future Holdings Limited',
    symbol: '1259.HK',
  },
  {
    value: '1260.HK',
    label: '1260.HK,  Wonderful Sky Financial Group Holdings Limited',
    name: 'Wonderful Sky Financial Group Holdings Limited',
    symbol: '1260.HK',
  },
  {
    value: '1262.HK',
    label: '1262.HK,  Labixiaoxin Snacks Group Limited',
    name: 'Labixiaoxin Snacks Group Limited',
    symbol: '1262.HK',
  },
  {
    value: '1263.HK',
    label: '1263.HK,  PC Partner Group Limited',
    name: 'PC Partner Group Limited',
    symbol: '1263.HK',
  },
  {
    value: '1265.HK',
    label: '1265.HK,  Tianjin Jinran Public Utilities Company Limited',
    name: 'Tianjin Jinran Public Utilities Company Limited',
    symbol: '1265.HK',
  },
  {
    value: '1266.HK',
    label: '1266.HK,  Xiwang Special Steel Company Limited',
    name: 'Xiwang Special Steel Company Limited',
    symbol: '1266.HK',
  },
  {
    value: '1268.HK',
    label: '1268.HK,  China MeiDong Auto Holdings Limited',
    name: 'China MeiDong Auto Holdings Limited',
    symbol: '1268.HK',
  },
  {
    value: '1269.HK',
    label: '1269.HK,  China First Capital Group Limited',
    name: 'China First Capital Group Limited',
    symbol: '1269.HK',
  },
  {
    value: '1270.HK',
    label: '1270.HK,  Langham Hospitality Investments Limited',
    name: 'Langham Hospitality Investments Limited',
    symbol: '1270.HK',
  },
  {
    value: '1271.HK',
    label: '1271.HK,  Grand Ming Group Holdings Limited',
    name: 'Grand Ming Group Holdings Limited',
    symbol: '1271.HK',
  },
  {
    value: '1272.HK',
    label: '1272.HK,  Datang Environment Industry Group Co., Ltd.',
    name: 'Datang Environment Industry Group Co., Ltd.',
    symbol: '1272.HK',
  },
  {
    value: '1273.HK',
    label: '1273.HK,  Hong Kong Finance Group Limited',
    name: 'Hong Kong Finance Group Limited',
    symbol: '1273.HK',
  },
  {
    value: '1277.HK',
    label: '1277.HK,  Kinetic Development Group Limited',
    name: 'Kinetic Development Group Limited',
    symbol: '1277.HK',
  },
  {
    value: '1278.HK',
    label: '1278.HK,  China New Town Development Company Limited',
    name: 'China New Town Development Company Limited',
    symbol: '1278.HK',
  },
  {
    value: '1280.HK',
    label: '1280.HK,  Qidian International Co., Ltd.',
    name: 'Qidian International Co., Ltd.',
    symbol: '1280.HK',
  },
  {
    value: '1281.HK',
    label: '1281.HK,  Xinda Investment Holdings Limited',
    name: 'Xinda Investment Holdings Limited',
    symbol: '1281.HK',
  },
  {
    value: '1282.HK',
    label: '1282.HK,  Glory Sun Financial Group Limited',
    name: 'Glory Sun Financial Group Limited',
    symbol: '1282.HK',
  },
  {
    value: '1283.HK',
    label: '1283.HK,  Accel Group Holdings Limited',
    name: 'Accel Group Holdings Limited',
    symbol: '1283.HK',
  },
  {
    value: '1285.HK',
    label: '1285.HK,  Jiashili Group Limited',
    name: 'Jiashili Group Limited',
    symbol: '1285.HK',
  },
  {
    value: '1286.HK',
    label: '1286.HK,  Impro Precision Industries Limited',
    name: 'Impro Precision Industries Limited',
    symbol: '1286.HK',
  },
  {
    value: '1289.HK',
    label: '1289.HK,  Wuxi Sunlit Science and Technology Company Limited',
    name: 'Wuxi Sunlit Science and Technology Company Limited',
    symbol: '1289.HK',
  },
  {
    value: '1290.HK',
    label: '1290.HK,  China Huirong Financial Holdings Limited',
    name: 'China Huirong Financial Holdings Limited',
    symbol: '1290.HK',
  },
  {
    value: '1292.HK',
    label: '1292.HK,  Changan Minsheng APLL Logistics Co., Ltd.',
    name: 'Changan Minsheng APLL Logistics Co., Ltd.',
    symbol: '1292.HK',
  },
  {
    value: '1293.HK',
    label: '1293.HK,  Grand Baoxin Auto Group Limited',
    name: 'Grand Baoxin Auto Group Limited',
    symbol: '1293.HK',
  },
  {
    value: '1297.HK',
    label: '1297.HK,  Sinosoft Technology Group Limited',
    name: 'Sinosoft Technology Group Limited',
    symbol: '1297.HK',
  },
  {
    value: '1299.HK',
    label: '1299.HK,  AIA Group Limited',
    name: 'AIA Group Limited',
    symbol: '1299.HK',
  },
  {
    value: '1300.HK',
    label: '1300.HK,  Trigiant Group Limited',
    name: 'Trigiant Group Limited',
    symbol: '1300.HK',
  },
  {
    value: '1301.HK',
    label: '1301.HK,  D&G Technology Holding Company Limited',
    name: 'D&G Technology Holding Company Limited',
    symbol: '1301.HK',
  },
  {
    value: '1302.HK',
    label: '1302.HK,  LifeTech Scientific Corporation',
    name: 'LifeTech Scientific Corporation',
    symbol: '1302.HK',
  },
  {
    value: '1303.HK',
    label: '1303.HK,  Huili Resources (Group) Limited',
    name: 'Huili Resources (Group) Limited',
    symbol: '1303.HK',
  },
  {
    value: '1305.HK',
    label: '1305.HK,  Wai Chi Holdings Company Limited',
    name: 'Wai Chi Holdings Company Limited',
    symbol: '1305.HK',
  },
  {
    value: '1308.HK',
    label: '1308.HK,  SITC International Holdings Company Limited',
    name: 'SITC International Holdings Company Limited',
    symbol: '1308.HK',
  },
  {
    value: '1310.HK',
    label: '1310.HK,  HKBN Ltd.',
    name: 'HKBN Ltd.',
    symbol: '1310.HK',
  },
  {
    value: '1312.HK',
    label: '1312.HK,  Kontafarma China Holdings Limited',
    name: 'Kontafarma China Holdings Limited',
    symbol: '1312.HK',
  },
  {
    value: '1313.HK',
    label: '1313.HK,  China Resources Cement Holdings Limited',
    name: 'China Resources Cement Holdings Limited',
    symbol: '1313.HK',
  },
  {
    value: '1314.HK',
    label: '1314.HK,  Tsui Wah Holdings Limited',
    name: 'Tsui Wah Holdings Limited',
    symbol: '1314.HK',
  },
  {
    value: '1316.HK',
    label: '1316.HK,  Nexteer Automotive Group Limited',
    name: 'Nexteer Automotive Group Limited',
    symbol: '1316.HK',
  },
  {
    value: '1319.HK',
    label: '1319.HK,  Oi Wah Pawnshop Credit Holdings Limited',
    name: 'Oi Wah Pawnshop Credit Holdings Limited',
    symbol: '1319.HK',
  },
  {
    value: '1321.HK',
    label: '1321.HK,  China New City Commercial Development Limited',
    name: 'China New City Commercial Development Limited',
    symbol: '1321.HK',
  },
  {
    value: '1323.HK',
    label: '1323.HK,  Huasheng International Holding Limited',
    name: 'Huasheng International Holding Limited',
    symbol: '1323.HK',
  },
  {
    value: '1326.HK',
    label: '1326.HK,  Transmit Entertainment Limited',
    name: 'Transmit Entertainment Limited',
    symbol: '1326.HK',
  },
  {
    value: '1327.HK',
    label: '1327.HK,  Luxxu Group Limited',
    name: 'Luxxu Group Limited',
    symbol: '1327.HK',
  },
  {
    value: '1328.HK',
    label: '1328.HK,  Goldstream Investment Limited',
    name: 'Goldstream Investment Limited',
    symbol: '1328.HK',
  },
  {
    value: '1329.HK',
    label: '1329.HK,  Beijing Capital Grand Limited',
    name: 'Beijing Capital Grand Limited',
    symbol: '1329.HK',
  },
  {
    value: '1332.HK',
    label: '1332.HK,  Touyun Biotech Group Limited',
    name: 'Touyun Biotech Group Limited',
    symbol: '1332.HK',
  },
  {
    value: '1338.HK',
    label: '1338.HK,  BaWang International (Group) Holding Limited',
    name: 'BaWang International (Group) Holding Limited',
    symbol: '1338.HK',
  },
  {
    value: '1340.HK',
    label: '1340.HK,  Huisheng International Holdings Limited',
    name: 'Huisheng International Holdings Limited',
    symbol: '1340.HK',
  },
  {
    value: '1341.HK',
    label:
      '1341.HK,  Hao Tian International Construction Investment Group Limited',
    name: 'Hao Tian International Construction Investment Group Limited',
    symbol: '1341.HK',
  },
  {
    value: '1343.HK',
    label: '1343.HK,  Wei Yuan Holdings Limited',
    name: 'Wei Yuan Holdings Limited',
    symbol: '1343.HK',
  },
  {
    value: '1345.HK',
    label: '1345.HK,  Shanghai Pioneer Holding Ltd',
    name: 'Shanghai Pioneer Holding Ltd',
    symbol: '1345.HK',
  },
  {
    value: '1346.HK',
    label: '1346.HK,  Lever Style Corporation',
    name: 'Lever Style Corporation',
    symbol: '1346.HK',
  },
  {
    value: '1347.HK',
    label: '1347.HK,  Hua Hong Semiconductor Limited',
    name: 'Hua Hong Semiconductor Limited',
    symbol: '1347.HK',
  },
  {
    value: '1348.HK',
    label: '1348.HK,  Quali-Smart Holdings Limited',
    name: 'Quali-Smart Holdings Limited',
    symbol: '1348.HK',
  },
  {
    value: '1355.HK',
    label:
      '1355.HK,  Legend Strategy International Holdings Group Company Limited',
    name: 'Legend Strategy International Holdings Group Company Limited',
    symbol: '1355.HK',
  },
  {
    value: '1357.HK',
    label: '1357.HK,  Meitu, Inc.',
    name: 'Meitu, Inc.',
    symbol: '1357.HK',
  },
  {
    value: '1358.HK',
    label: '1358.HK,  PW Medtech Group Limited',
    name: 'PW Medtech Group Limited',
    symbol: '1358.HK',
  },
  {
    value: '1359.HK',
    label: '1359.HK,  China Cinda Asset Management Co., Ltd.',
    name: 'China Cinda Asset Management Co., Ltd.',
    symbol: '1359.HK',
  },
  {
    value: '1360.HK',
    label: '1360.HK,  NOVA Group Holdings Limited',
    name: 'NOVA Group Holdings Limited',
    symbol: '1360.HK',
  },
  {
    value: '1361.HK',
    label: '1361.HK,  361 Degrees International Limited',
    name: '361 Degrees International Limited',
    symbol: '1361.HK',
  },
  {
    value: '1362.HK',
    label: '1362.HK,  SiS Mobile Holdings Limited',
    name: 'SiS Mobile Holdings Limited',
    symbol: '1362.HK',
  },
  {
    value: '1366.HK',
    label: '1366.HK,  Jiangnan Group Limited',
    name: 'Jiangnan Group Limited',
    symbol: '1366.HK',
  },
  {
    value: '1368.HK',
    label: '1368.HK,  Xtep International Holdings Limited',
    name: 'Xtep International Holdings Limited',
    symbol: '1368.HK',
  },
  {
    value: '1371.HK',
    label: '1371.HK,  China Ecotourism Group Limited',
    name: 'China Ecotourism Group Limited',
    symbol: '1371.HK',
  },
  {
    value: '1372.HK',
    label: '1372.HK,  China Carbon Neutral Development Group Limited',
    name: 'China Carbon Neutral Development Group Limited',
    symbol: '1372.HK',
  },
  {
    value: '1373.HK',
    label: '1373.HK,  International Housewares Retail Company Limited',
    name: 'International Housewares Retail Company Limited',
    symbol: '1373.HK',
  },
  {
    value: '1376.HK',
    label: '1376.HK,  Raffles Interior Limited',
    name: 'Raffles Interior Limited',
    symbol: '1376.HK',
  },
  {
    value: '1378.HK',
    label: '1378.HK,  China Hongqiao Group Limited',
    name: 'China Hongqiao Group Limited',
    symbol: '1378.HK',
  },
  {
    value: '1380.HK',
    label: '1380.HK,  China Kingstone Mining Holdings Limited',
    name: 'China Kingstone Mining Holdings Limited',
    symbol: '1380.HK',
  },
  {
    value: '1381.HK',
    label: '1381.HK,  Canvest Environmental Protection Group Company Limited',
    name: 'Canvest Environmental Protection Group Company Limited',
    symbol: '1381.HK',
  },
  {
    value: '1382.HK',
    label: '1382.HK,  Pacific Textiles Holdings Limited',
    name: 'Pacific Textiles Holdings Limited',
    symbol: '1382.HK',
  },
  {
    value: '1383.HK',
    label: '1383.HK,  LET Group Holdings Limited',
    name: 'LET Group Holdings Limited',
    symbol: '1383.HK',
  },
  {
    value: '1385.HK',
    label: '1385.HK,  Shanghai Fudan Microelectronics Group Company Limited',
    name: 'Shanghai Fudan Microelectronics Group Company Limited',
    symbol: '1385.HK',
  },
  {
    value: '1387.HK',
    label: '1387.HK,  China Dili Group',
    name: 'China Dili Group',
    symbol: '1387.HK',
  },
  {
    value: '1388.HK',
    label: '1388.HK,  Embry Holdings Limited',
    name: 'Embry Holdings Limited',
    symbol: '1388.HK',
  },
  {
    value: '1389.HK',
    label: '1389.HK,  Major Holdings Limited',
    name: 'Major Holdings Limited',
    symbol: '1389.HK',
  },
  {
    value: '1393.HK',
    label: '1393.HK,  Hidili Industry International Development Limited',
    name: 'Hidili Industry International Development Limited',
    symbol: '1393.HK',
  },
  {
    value: '1395.HK',
    label: '1395.HK,  ELL Environmental Holdings Limited',
    name: 'ELL Environmental Holdings Limited',
    symbol: '1395.HK',
  },
  {
    value: '1396.HK',
    label: '1396.HK,  Guangdong - Hong Kong Greater Bay Area Holdings Limited',
    name: 'Guangdong - Hong Kong Greater Bay Area Holdings Limited',
    symbol: '1396.HK',
  },
  {
    value: '1397.HK',
    label: '1397.HK,  Baguio Green Group Limited',
    name: 'Baguio Green Group Limited',
    symbol: '1397.HK',
  },
  {
    value: '1399.HK',
    label: '1399.HK,  Veson Holdings Limited',
    name: 'Veson Holdings Limited',
    symbol: '1399.HK',
  },
  {
    value: '1400.HK',
    label: '1400.HK,  Moody Technology Holdings Limited',
    name: 'Moody Technology Holdings Limited',
    symbol: '1400.HK',
  },
  {
    value: '1401.HK',
    label: '1401.HK,  Sprocomm Intelligence Limited',
    name: 'Sprocomm Intelligence Limited',
    symbol: '1401.HK',
  },
  {
    value: '1402.HK',
    label: '1402.HK,  i-Control Holdings Limited',
    name: 'i-Control Holdings Limited',
    symbol: '1402.HK',
  },
  {
    value: '1410.HK',
    label: '1410.HK,  Edvance International Holdings Limited',
    name: 'Edvance International Holdings Limited',
    symbol: '1410.HK',
  },
  {
    value: '1412.HK',
    label: '1412.HK,  Q P Group Holdings Limited',
    name: 'Q P Group Holdings Limited',
    symbol: '1412.HK',
  },
  {
    value: '1415.HK',
    label: '1415.HK,  Cowell e Holdings Inc.',
    name: 'Cowell e Holdings Inc.',
    symbol: '1415.HK',
  },
  {
    value: '1416.HK',
    label: '1416.HK,  CTR Holdings Limited',
    name: 'CTR Holdings Limited',
    symbol: '1416.HK',
  },
  {
    value: '1417.HK',
    label: '1417.HK,  Riverine China Holdings Limited',
    name: 'Riverine China Holdings Limited',
    symbol: '1417.HK',
  },
  {
    value: '1418.HK',
    label: '1418.HK,  Sinomax Group Limited',
    name: 'Sinomax Group Limited',
    symbol: '1418.HK',
  },
  {
    value: '1419.HK',
    label: '1419.HK,  Human Health Holdings Limited',
    name: 'Human Health Holdings Limited',
    symbol: '1419.HK',
  },
  {
    value: '1420.HK',
    label: '1420.HK,  Chuan Holdings Limited',
    name: 'Chuan Holdings Limited',
    symbol: '1420.HK',
  },
  {
    value: '1421.HK',
    label: '1421.HK,  Kingbo Strike Limited',
    name: 'Kingbo Strike Limited',
    symbol: '1421.HK',
  },
  {
    value: '1425.HK',
    label: '1425.HK,  Justin Allen Holdings Limited',
    name: 'Justin Allen Holdings Limited',
    symbol: '1425.HK',
  },
  {
    value: '1426.HK',
    label: '1426.HK,  Spring Real Estate Investment Trust',
    name: 'Spring Real Estate Investment Trust',
    symbol: '1426.HK',
  },
  {
    value: '1427.HK',
    label: '1427.HK,  China Tianbao Group Development Company Limited',
    name: 'China Tianbao Group Development Company Limited',
    symbol: '1427.HK',
  },
  {
    value: '1428.HK',
    label: '1428.HK,  Bright Smart Securities & Commodities Group Limited',
    name: 'Bright Smart Securities & Commodities Group Limited',
    symbol: '1428.HK',
  },
  {
    value: '1431.HK',
    label: '1431.HK,  YuanShengTai Dairy Farm Limited',
    name: 'YuanShengTai Dairy Farm Limited',
    symbol: '1431.HK',
  },
  {
    value: '1432.HK',
    label: '1432.HK,  China Shengmu Organic Milk Limited',
    name: 'China Shengmu Organic Milk Limited',
    symbol: '1432.HK',
  },
  {
    value: '1433.HK',
    label: '1433.HK,  Cirtek Holdings Limited',
    name: 'Cirtek Holdings Limited',
    symbol: '1433.HK',
  },
  {
    value: '1442.HK',
    label: '1442.HK,  Infinity Logistics and Transport Ventures Limited',
    name: 'Infinity Logistics and Transport Ventures Limited',
    symbol: '1442.HK',
  },
  {
    value: '1443.HK',
    label: '1443.HK,  Fulum Group Holdings Limited',
    name: 'Fulum Group Holdings Limited',
    symbol: '1443.HK',
  },
  {
    value: '1446.HK',
    label: '1446.HK,  Hung Fook Tong Group Holdings Limited',
    name: 'Hung Fook Tong Group Holdings Limited',
    symbol: '1446.HK',
  },
  {
    value: '1447.HK',
    label: '1447.HK,  SFK Construction Holdings Limited',
    name: 'SFK Construction Holdings Limited',
    symbol: '1447.HK',
  },
  {
    value: '1448.HK',
    label: '1448.HK,  Fu Shou Yuan International Group Limited',
    name: 'Fu Shou Yuan International Group Limited',
    symbol: '1448.HK',
  },
  {
    value: '1450.HK',
    label: '1450.HK,  Century Sage Scientific Holdings Limited',
    name: 'Century Sage Scientific Holdings Limited',
    symbol: '1450.HK',
  },
  {
    value: '1451.HK',
    label: '1451.HK,  MS Group Holdings Limited',
    name: 'MS Group Holdings Limited',
    symbol: '1451.HK',
  },
  {
    value: '1452.HK',
    label: '1452.HK,  Denox Environmental & Technology Holdings Limited',
    name: 'Denox Environmental & Technology Holdings Limited',
    symbol: '1452.HK',
  },
  {
    value: '1456.HK',
    label: '1456.HK,  Guolian Securities Co., Ltd.',
    name: 'Guolian Securities Co., Ltd.',
    symbol: '1456.HK',
  },
  {
    value: '1458.HK',
    label: '1458.HK,  Zhou Hei Ya International Holdings Company Limited',
    name: 'Zhou Hei Ya International Holdings Company Limited',
    symbol: '1458.HK',
  },
  {
    value: '1459.HK',
    label: '1459.HK,  Jujiang Construction Group Co., Ltd.',
    name: 'Jujiang Construction Group Co., Ltd.',
    symbol: '1459.HK',
  },
  {
    value: '1460.HK',
    label: '1460.HK,  ICO Group Limited',
    name: 'ICO Group Limited',
    symbol: '1460.HK',
  },
  {
    value: '1461.HK',
    label: '1461.HK,  ZHONGTAI FUTURES Company Limited',
    name: 'ZHONGTAI FUTURES Company Limited',
    symbol: '1461.HK',
  },
  {
    value: '1463.HK',
    label: '1463.HK,  C-Link Squared Limited',
    name: 'C-Link Squared Limited',
    symbol: '1463.HK',
  },
  {
    value: '1466.HK',
    label: '1466.HK,  Affluent Partners Holdings Limited',
    name: 'Affluent Partners Holdings Limited',
    symbol: '1466.HK',
  },
  {
    value: '1468.HK',
    label: '1468.HK,  Kingkey Financial International (Holdings) Limited',
    name: 'Kingkey Financial International (Holdings) Limited',
    symbol: '1468.HK',
  },
  {
    value: '1469.HK',
    label: '1469.HK,  Get Nice Financial Group Limited',
    name: 'Get Nice Financial Group Limited',
    symbol: '1469.HK',
  },
  {
    value: '1470.HK',
    label: '1470.HK,  Prosper One International Holdings Company Limited',
    name: 'Prosper One International Holdings Company Limited',
    symbol: '1470.HK',
  },
  {
    value: '1472.HK',
    label: '1472.HK,  Sang Hing Holdings (International) Limited',
    name: 'Sang Hing Holdings (International) Limited',
    symbol: '1472.HK',
  },
  {
    value: '1475.HK',
    label: '1475.HK,  Nissin Foods Company Limited',
    name: 'Nissin Foods Company Limited',
    symbol: '1475.HK',
  },
  {
    value: '1476.HK',
    label: '1476.HK,  Hengtai Securities Co., Ltd',
    name: 'Hengtai Securities Co., Ltd',
    symbol: '1476.HK',
  },
  {
    value: '1478.HK',
    label: '1478.HK,  Q Technology (Group) Company Limited',
    name: 'Q Technology (Group) Company Limited',
    symbol: '1478.HK',
  },
  {
    value: '1480.HK',
    label: '1480.HK,  Yan Tat Group Holdings Limited',
    name: 'Yan Tat Group Holdings Limited',
    symbol: '1480.HK',
  },
  {
    value: '1483.HK',
    label: '1483.HK,  Net-a-Go Technology Company Limited',
    name: 'Net-a-Go Technology Company Limited',
    symbol: '1483.HK',
  },
  {
    value: '1486.HK',
    label: '1486.HK,  C Cheng Holdings Limited',
    name: 'C Cheng Holdings Limited',
    symbol: '1486.HK',
  },
  {
    value: '1488.HK',
    label: '1488.HK,  Best Food Holding Company Limited',
    name: 'Best Food Holding Company Limited',
    symbol: '1488.HK',
  },
  {
    value: '1495.HK',
    label: '1495.HK,  Jiyi Holdings Limited',
    name: 'Jiyi Holdings Limited',
    symbol: '1495.HK',
  },
  {
    value: '1496.HK',
    label: '1496.HK,  AP Rentals Holdings Limited',
    name: 'AP Rentals Holdings Limited',
    symbol: '1496.HK',
  },
  {
    value: '1498.HK',
    label: '1498.HK,  PuraPharm Corporation Limited',
    name: 'PuraPharm Corporation Limited',
    symbol: '1498.HK',
  },
  {
    value: '1499.HK',
    label: '1499.HK,  OKG Technology Holdings Limited',
    name: 'OKG Technology Holdings Limited',
    symbol: '1499.HK',
  },
  {
    value: '1500.HK',
    label: '1500.HK,  In Construction Holdings Limited',
    name: 'In Construction Holdings Limited',
    symbol: '1500.HK',
  },
  {
    value: '1501.HK',
    label: '1501.HK,  Shanghai Kindly Medical Instruments Co., Ltd.',
    name: 'Shanghai Kindly Medical Instruments Co., Ltd.',
    symbol: '1501.HK',
  },
  {
    value: '1503.HK',
    label: '1503.HK,  China Merchants Commercial Real Estate Investment Trust',
    name: 'China Merchants Commercial Real Estate Investment Trust',
    symbol: '1503.HK',
  },
  {
    value: '1508.HK',
    label: '1508.HK,  China Reinsurance (Group) Corporation',
    name: 'China Reinsurance (Group) Corporation',
    symbol: '1508.HK',
  },
  {
    value: '1515.HK',
    label: '1515.HK,  China Resources Medical Holdings Company Limited',
    name: 'China Resources Medical Holdings Company Limited',
    symbol: '1515.HK',
  },
  {
    value: '1518.HK',
    label: '1518.HK,  New Century Healthcare Holding Co. Limited',
    name: 'New Century Healthcare Holding Co. Limited',
    symbol: '1518.HK',
  },
  {
    value: '1520.HK',
    label: '1520.HK,  Virtual Mind Holding Company Limited',
    name: 'Virtual Mind Holding Company Limited',
    symbol: '1520.HK',
  },
  {
    value: '1521.HK',
    label: '1521.HK,  Frontage Holdings Corporation',
    name: 'Frontage Holdings Corporation',
    symbol: '1521.HK',
  },
  {
    value: '1522.HK',
    label:
      '1522.HK,  BII Railway Transportation Technology Holdings Company Limited',
    name: 'BII Railway Transportation Technology Holdings Company Limited',
    symbol: '1522.HK',
  },
  {
    value: '1523.HK',
    label: '1523.HK,  Plover Bay Technologies Limited',
    name: 'Plover Bay Technologies Limited',
    symbol: '1523.HK',
  },
  {
    value: '1525.HK',
    label: '1525.HK,  Shanghai Gench Education Group Limited',
    name: 'Shanghai Gench Education Group Limited',
    symbol: '1525.HK',
  },
  {
    value: '1526.HK',
    label: '1526.HK,  Rici Healthcare Holdings Limited',
    name: 'Rici Healthcare Holdings Limited',
    symbol: '1526.HK',
  },
  {
    value: '1527.HK',
    label: '1527.HK,  Zhejiang Tengy Environmental Technology Co., Ltd',
    name: 'Zhejiang Tengy Environmental Technology Co., Ltd',
    symbol: '1527.HK',
  },
  {
    value: '1529.HK',
    label: '1529.HK,  Yues International Holdings Group Limited',
    name: 'Yues International Holdings Group Limited',
    symbol: '1529.HK',
  },
  {
    value: '1530.HK',
    label: '1530.HK,  3SBio Inc.',
    name: '3SBio Inc.',
    symbol: '1530.HK',
  },
  {
    value: '1532.HK',
    label: '1532.HK,  China Partytime Culture Holdings Limited',
    name: 'China Partytime Culture Holdings Limited',
    symbol: '1532.HK',
  },
  {
    value: '1536.HK',
    label: '1536.HK,  Yuk Wing Group Holdings Limited',
    name: 'Yuk Wing Group Holdings Limited',
    symbol: '1536.HK',
  },
  {
    value: '1538.HK',
    label: '1538.HK,  Zhong Ao Home Group Limited',
    name: 'Zhong Ao Home Group Limited',
    symbol: '1538.HK',
  },
  {
    value: '1539.HK',
    label: '1539.HK,  Unity Group Holdings International Limited',
    name: 'Unity Group Holdings International Limited',
    symbol: '1539.HK',
  },
  {
    value: '1540.HK',
    label: '1540.HK,  Left Field Printing Group Limited',
    name: 'Left Field Printing Group Limited',
    symbol: '1540.HK',
  },
  {
    value: '1542.HK',
    label: '1542.HK,  Taizhou Water Group Co., Ltd.',
    name: 'Taizhou Water Group Co., Ltd.',
    symbol: '1542.HK',
  },
  {
    value: '1543.HK',
    label:
      '1543.HK,  Guangdong Join-Share Financing Guarantee Investment Co., Ltd.',
    name: 'Guangdong Join-Share Financing Guarantee Investment Co., Ltd.',
    symbol: '1543.HK',
  },
  {
    value: '1545.HK',
    label: '1545.HK,  Design Capital Limited',
    name: 'Design Capital Limited',
    symbol: '1545.HK',
  },
  {
    value: '1546.HK',
    label: '1546.HK,  Thelloy Development Group Limited',
    name: 'Thelloy Development Group Limited',
    symbol: '1546.HK',
  },
  {
    value: '1547.HK',
    label: '1547.HK,  IBI Group Holdings Limited',
    name: 'IBI Group Holdings Limited',
    symbol: '1547.HK',
  },
  {
    value: '1548.HK',
    label: '1548.HK,  Genscript Biotech Corporation',
    name: 'Genscript Biotech Corporation',
    symbol: '1548.HK',
  },
  {
    value: '1549.HK',
    label: '1549.HK,  Ever Harvest Group Holdings Limited',
    name: 'Ever Harvest Group Holdings Limited',
    symbol: '1549.HK',
  },
  {
    value: '1551.HK',
    label: '1551.HK,  Guangzhou Rural Commercial Bank Co., Ltd.',
    name: 'Guangzhou Rural Commercial Bank Co., Ltd.',
    symbol: '1551.HK',
  },
  {
    value: '1552.HK',
    label: '1552.HK,  BHCC Holding Limited',
    name: 'BHCC Holding Limited',
    symbol: '1552.HK',
  },
  {
    value: '1553.HK',
    label: '1553.HK,  Maike Tube Industry Holdings Limited',
    name: 'Maike Tube Industry Holdings Limited',
    symbol: '1553.HK',
  },
  {
    value: '1556.HK',
    label: '1556.HK,  Chinney Kin Wing Holdings Limited',
    name: 'Chinney Kin Wing Holdings Limited',
    symbol: '1556.HK',
  },
  {
    value: '1557.HK',
    label: '1557.HK,  K. H. Group Holdings Limited',
    name: 'K. H. Group Holdings Limited',
    symbol: '1557.HK',
  },
  {
    value: '1558.HK',
    label: '1558.HK,  YiChang HEC ChangJiang Pharmaceutical Co., Ltd.',
    name: 'YiChang HEC ChangJiang Pharmaceutical Co., Ltd.',
    symbol: '1558.HK',
  },
  {
    value: '1559.HK',
    label: '1559.HK,  Kwan On Holdings Limited',
    name: 'Kwan On Holdings Limited',
    symbol: '1559.HK',
  },
  {
    value: '1560.HK',
    label: '1560.HK,  Star Group Company Limited',
    name: 'Star Group Company Limited',
    symbol: '1560.HK',
  },
  {
    value: '1561.HK',
    label: '1561.HK,  Pan Asia Data Holdings Inc.',
    name: 'Pan Asia Data Holdings Inc.',
    symbol: '1561.HK',
  },
  {
    value: '1563.HK',
    label: '1563.HK,  International Alliance Financial Leasing Co., Ltd.',
    name: 'International Alliance Financial Leasing Co., Ltd.',
    symbol: '1563.HK',
  },
  {
    value: '1565.HK',
    label: '1565.HK,  Virscend Education Company Limited',
    name: 'Virscend Education Company Limited',
    symbol: '1565.HK',
  },
  {
    value: '1566.HK',
    label: '1566.HK,  CA Cultural Technology Group Limited',
    name: 'CA Cultural Technology Group Limited',
    symbol: '1566.HK',
  },
  {
    value: '1568.HK',
    label: '1568.HK,  Sundart Holdings Limited',
    name: 'Sundart Holdings Limited',
    symbol: '1568.HK',
  },
  {
    value: '1569.HK',
    label: '1569.HK,  Minsheng Education Group Company Limited',
    name: 'Minsheng Education Group Company Limited',
    symbol: '1569.HK',
  },
  {
    value: '1571.HK',
    label: '1571.HK,  Xin Point Holdings Limited',
    name: 'Xin Point Holdings Limited',
    symbol: '1571.HK',
  },
  {
    value: '1572.HK',
    label: '1572.HK,  China Art Financial Holdings Limited',
    name: 'China Art Financial Holdings Limited',
    symbol: '1572.HK',
  },
  {
    value: '1575.HK',
    label: '1575.HK,  Morris Home Holdings Limited',
    name: 'Morris Home Holdings Limited',
    symbol: '1575.HK',
  },
  {
    value: '1576.HK',
    label: '1576.HK,  Qilu Expressway Company Limited',
    name: 'Qilu Expressway Company Limited',
    symbol: '1576.HK',
  },
  {
    value: '1578.HK',
    label: '1578.HK,  Bank of Tianjin Co., Ltd.',
    name: 'Bank of Tianjin Co., Ltd.',
    symbol: '1578.HK',
  },
  {
    value: '1579.HK',
    label: '1579.HK,  Yihai International Holding Ltd.',
    name: 'Yihai International Holding Ltd.',
    symbol: '1579.HK',
  },
  {
    value: '1580.HK',
    label: '1580.HK,  Da Sen Holdings Group Limited',
    name: 'Da Sen Holdings Group Limited',
    symbol: '1580.HK',
  },
  {
    value: '1581.HK',
    label: '1581.HK,  Progressive Path Group Holdings Limited',
    name: 'Progressive Path Group Holdings Limited',
    symbol: '1581.HK',
  },
  {
    value: '1582.HK',
    label: '1582.HK,  CR Construction Group Holdings Limited',
    name: 'CR Construction Group Holdings Limited',
    symbol: '1582.HK',
  },
  {
    value: '1583.HK',
    label: '1583.HK,  Qinqin Foodstuffs Group (Cayman) Company Limited',
    name: 'Qinqin Foodstuffs Group (Cayman) Company Limited',
    symbol: '1583.HK',
  },
  {
    value: '1585.HK',
    label: '1585.HK,  Yadea Group Holdings Ltd.',
    name: 'Yadea Group Holdings Ltd.',
    symbol: '1585.HK',
  },
  {
    value: '1586.HK',
    label: '1586.HK,  China Leon Inspection Holding Limited',
    name: 'China Leon Inspection Holding Limited',
    symbol: '1586.HK',
  },
  {
    value: '1587.HK',
    label: '1587.HK,  Shineroad International Holdings Limited',
    name: 'Shineroad International Holdings Limited',
    symbol: '1587.HK',
  },
  {
    value: '1588.HK',
    label: '1588.HK,  Chanjet Information Technology Company Limited',
    name: 'Chanjet Information Technology Company Limited',
    symbol: '1588.HK',
  },
  {
    value: '1591.HK',
    label: '1591.HK,  Shun Wo Group Holdings Limited',
    name: 'Shun Wo Group Holdings Limited',
    symbol: '1591.HK',
  },
  {
    value: '1592.HK',
    label: '1592.HK,  Anchorstone Holdings Limited',
    name: 'Anchorstone Holdings Limited',
    symbol: '1592.HK',
  },
  {
    value: '1593.HK',
    label: '1593.HK,  Chen Lin Education Group Holdings Limited',
    name: 'Chen Lin Education Group Holdings Limited',
    symbol: '1593.HK',
  },
  {
    value: '1596.HK',
    label: '1596.HK,  Hebei Yichen Industrial Group Corporation Limited',
    name: 'Hebei Yichen Industrial Group Corporation Limited',
    symbol: '1596.HK',
  },
  {
    value: '1598.HK',
    label: '1598.HK,  China 21st Century Education Group Limited',
    name: 'China 21st Century Education Group Limited',
    symbol: '1598.HK',
  },
  {
    value: '1599.HK',
    label:
      '1599.HK,  Beijing Urban Construction Design & Development Group Co., Limited',
    name: 'Beijing Urban Construction Design & Development Group Co., Limited',
    symbol: '1599.HK',
  },
  {
    value: '1600.HK',
    label: '1600.HK,  Tian Lun Gas Holdings Limited',
    name: 'Tian Lun Gas Holdings Limited',
    symbol: '1600.HK',
  },
  {
    value: '1601.HK',
    label: '1601.HK,  Zhongguancun Science-Tech Leasing Co., Ltd.',
    name: 'Zhongguancun Science-Tech Leasing Co., Ltd.',
    symbol: '1601.HK',
  },
  {
    value: '1606.HK',
    label: '1606.HK,  China Development Bank Financial Leasing Co., Ltd.',
    name: 'China Development Bank Financial Leasing Co., Ltd.',
    symbol: '1606.HK',
  },
  {
    value: '1608.HK',
    label: '1608.HK,  VPower Group International Holdings Limited',
    name: 'VPower Group International Holdings Limited',
    symbol: '1608.HK',
  },
  {
    value: '1610.HK',
    label: '1610.HK,  COFCO Joycome Foods Limited',
    name: 'COFCO Joycome Foods Limited',
    symbol: '1610.HK',
  },
  {
    value: '1611.HK',
    label: '1611.HK,  New Huo Technology Holdings Limited',
    name: 'New Huo Technology Holdings Limited',
    symbol: '1611.HK',
  },
  {
    value: '1612.HK',
    label: '1612.HK,  Vincent Medical Holdings Limited',
    name: 'Vincent Medical Holdings Limited',
    symbol: '1612.HK',
  },
  {
    value: '1613.HK',
    label: '1613.HK,  Synertone Communication Corporation',
    name: 'Synertone Communication Corporation',
    symbol: '1613.HK',
  },
  {
    value: '1615.HK',
    label: '1615.HK,  AB Builders Group Limited',
    name: 'AB Builders Group Limited',
    symbol: '1615.HK',
  },
  {
    value: '1617.HK',
    label: '1617.HK,  Nanfang Communication Holdings Limited',
    name: 'Nanfang Communication Holdings Limited',
    symbol: '1617.HK',
  },
  {
    value: '1620.HK',
    label: '1620.HK,  Cinese International Group Holdings Limited',
    name: 'Cinese International Group Holdings Limited',
    symbol: '1620.HK',
  },
  {
    value: '1621.HK',
    label: '1621.HK,  Vico International Holdings Limited',
    name: 'Vico International Holdings Limited',
    symbol: '1621.HK',
  },
  {
    value: '1622.HK',
    label: '1622.HK,  Redco Properties Group Limited',
    name: 'Redco Properties Group Limited',
    symbol: '1622.HK',
  },
  {
    value: '1623.HK',
    label: '1623.HK,  Hilong Holding Limited',
    name: 'Hilong Holding Limited',
    symbol: '1623.HK',
  },
  {
    value: '1626.HK',
    label: '1626.HK,  Jia Yao Holdings Limited',
    name: 'Jia Yao Holdings Limited',
    symbol: '1626.HK',
  },
  {
    value: '1627.HK',
    label: '1627.HK,  Able Engineering Holdings Limited',
    name: 'Able Engineering Holdings Limited',
    symbol: '1627.HK',
  },
  {
    value: '1628.HK',
    label: '1628.HK,  Yuzhou Group Holdings Company Limited',
    name: 'Yuzhou Group Holdings Company Limited',
    symbol: '1628.HK',
  },
  {
    value: '1630.HK',
    label: '1630.HK,  Kin Shing Holdings Limited',
    name: 'Kin Shing Holdings Limited',
    symbol: '1630.HK',
  },
  {
    value: '1631.HK',
    label: '1631.HK,  REF Holdings Limited',
    name: 'REF Holdings Limited',
    symbol: '1631.HK',
  },
  {
    value: '1632.HK',
    label: '1632.HK,  Minshang Creative Technology Holdings Limited',
    name: 'Minshang Creative Technology Holdings Limited',
    symbol: '1632.HK',
  },
  {
    value: '1633.HK',
    label: '1633.HK,  Sheung Yue Group Holdings Limited',
    name: 'Sheung Yue Group Holdings Limited',
    symbol: '1633.HK',
  },
  {
    value: '1636.HK',
    label: '1636.HK,  China Metal Resources Utilization Limited',
    name: 'China Metal Resources Utilization Limited',
    symbol: '1636.HK',
  },
  {
    value: '1637.HK',
    label: '1637.HK,  SH Group (Holdings) Limited',
    name: 'SH Group (Holdings) Limited',
    symbol: '1637.HK',
  },
  {
    value: '1640.HK',
    label: '1640.HK,  Ruicheng (China) Media Group Limited',
    name: 'Ruicheng (China) Media Group Limited',
    symbol: '1640.HK',
  },
  {
    value: '1645.HK',
    label:
      '1645.HK,  Haina Intelligent Equipment International Holdings Limited',
    name: 'Haina Intelligent Equipment International Holdings Limited',
    symbol: '1645.HK',
  },
  {
    value: '1647.HK',
    label: '1647.HK,  Grandshores Technology Group Limited',
    name: 'Grandshores Technology Group Limited',
    symbol: '1647.HK',
  },
  {
    value: '1651.HK',
    label: '1651.HK,  Precision Tsugami (China) Corporation Limited',
    name: 'Precision Tsugami (China) Corporation Limited',
    symbol: '1651.HK',
  },
  {
    value: '1652.HK',
    label: '1652.HK,  Fusen Pharmaceutical Company Limited',
    name: 'Fusen Pharmaceutical Company Limited',
    symbol: '1652.HK',
  },
  {
    value: '1653.HK',
    label: '1653.HK,  MOS House Group Limited',
    name: 'MOS House Group Limited',
    symbol: '1653.HK',
  },
  {
    value: '1655.HK',
    label: '1655.HK,  Okura Holdings Limited',
    name: 'Okura Holdings Limited',
    symbol: '1655.HK',
  },
  {
    value: '1660.HK',
    label: '1660.HK,  Zhaobangji Properties Holdings Limited',
    name: 'Zhaobangji Properties Holdings Limited',
    symbol: '1660.HK',
  },
  {
    value: '1661.HK',
    label: '1661.HK,  Wisdom Sports Group',
    name: 'Wisdom Sports Group',
    symbol: '1661.HK',
  },
  {
    value: '1662.HK',
    label: '1662.HK,  Yee Hop Holdings Limited',
    name: 'Yee Hop Holdings Limited',
    symbol: '1662.HK',
  },
  {
    value: '1663.HK',
    label: '1663.HK,  Sino Harbour Holdings Group Limited',
    name: 'Sino Harbour Holdings Group Limited',
    symbol: '1663.HK',
  },
  {
    value: '1665.HK',
    label: '1665.HK,  Pentamaster International Limited',
    name: 'Pentamaster International Limited',
    symbol: '1665.HK',
  },
  {
    value: '1666.HK',
    label: '1666.HK,  Tong Ren Tang Technologies Co. Ltd.',
    name: 'Tong Ren Tang Technologies Co. Ltd.',
    symbol: '1666.HK',
  },
  {
    value: '1667.HK',
    label: '1667.HK,  Milestone Builder Holdings Limited',
    name: 'Milestone Builder Holdings Limited',
    symbol: '1667.HK',
  },
  {
    value: '1668.HK',
    label: '1668.HK,  China South City Holdings Limited',
    name: 'China South City Holdings Limited',
    symbol: '1668.HK',
  },
  {
    value: '1669.HK',
    label: '1669.HK,  Global International Credit Group Limited',
    name: 'Global International Credit Group Limited',
    symbol: '1669.HK',
  },
  {
    value: '1671.HK',
    label: '1671.HK,  Tianjin Tianbao Energy Co., Ltd.',
    name: 'Tianjin Tianbao Energy Co., Ltd.',
    symbol: '1671.HK',
  },
  {
    value: '1672.HK',
    label: '1672.HK,  Ascletis Pharma Inc.',
    name: 'Ascletis Pharma Inc.',
    symbol: '1672.HK',
  },
  {
    value: '1675.HK',
    label: '1675.HK,  Asiainfo Technologies Limited',
    name: 'Asiainfo Technologies Limited',
    symbol: '1675.HK',
  },
  {
    value: '1676.HK',
    label: '1676.HK,  China Shenghai Group Limited',
    name: 'China Shenghai Group Limited',
    symbol: '1676.HK',
  },
  {
    value: '1679.HK',
    label: '1679.HK,  Risecomm Group Holdings Limited',
    name: 'Risecomm Group Holdings Limited',
    symbol: '1679.HK',
  },
  {
    value: '1680.HK',
    label: '1680.HK,  Macau Legend Development Limited',
    name: 'Macau Legend Development Limited',
    symbol: '1680.HK',
  },
  {
    value: '1681.HK',
    label: '1681.HK,  Consun Pharmaceutical Group Limited',
    name: 'Consun Pharmaceutical Group Limited',
    symbol: '1681.HK',
  },
  {
    value: '1682.HK',
    label: '1682.HK,  Hang Pin Living Technology Company Limited',
    name: 'Hang Pin Living Technology Company Limited',
    symbol: '1682.HK',
  },
  {
    value: '1683.HK',
    label: '1683.HK,  Hope Life International Holdings Limited',
    name: 'Hope Life International Holdings Limited',
    symbol: '1683.HK',
  },
  {
    value: '1685.HK',
    label: '1685.HK,  Boer Power Holdings Limited',
    name: 'Boer Power Holdings Limited',
    symbol: '1685.HK',
  },
  {
    value: '1686.HK',
    label: '1686.HK,  SUNeVision Holdings Ltd.',
    name: 'SUNeVision Holdings Ltd.',
    symbol: '1686.HK',
  },
  {
    value: '1689.HK',
    label: '1689.HK,  Huaxi Holdings Company Limited',
    name: 'Huaxi Holdings Company Limited',
    symbol: '1689.HK',
  },
  {
    value: '1690.HK',
    label: '1690.HK,  Lap Kei Engineering (Holdings) Limited',
    name: 'Lap Kei Engineering (Holdings) Limited',
    symbol: '1690.HK',
  },
  {
    value: '1691.HK',
    label: '1691.HK,  JS Global Lifestyle Company Limited',
    name: 'JS Global Lifestyle Company Limited',
    symbol: '1691.HK',
  },
  {
    value: '1692.HK',
    label: '1692.HK,  Town Ray Holdings Limited',
    name: 'Town Ray Holdings Limited',
    symbol: '1692.HK',
  },
  {
    value: '1695.HK',
    label: '1695.HK,  S&P International Holding Limited',
    name: 'S&P International Holding Limited',
    symbol: '1695.HK',
  },
  {
    value: '1696.HK',
    label: '1696.HK,  Sisram Medical Ltd',
    name: 'Sisram Medical Ltd',
    symbol: '1696.HK',
  },
  {
    value: '1697.HK',
    label: '1697.HK,  Shandong International Trust Co., Ltd.',
    name: 'Shandong International Trust Co., Ltd.',
    symbol: '1697.HK',
  },
  {
    value: '1701.HK',
    label: '1701.HK,  Tu Yi Holding Company Limited',
    name: 'Tu Yi Holding Company Limited',
    symbol: '1701.HK',
  },
  {
    value: '1702.HK',
    label: '1702.HK,  Dongguang Chemical Limited',
    name: 'Dongguang Chemical Limited',
    symbol: '1702.HK',
  },
  {
    value: '1703.HK',
    label: '1703.HK,  Palace Banquet Holdings Limited',
    name: 'Palace Banquet Holdings Limited',
    symbol: '1703.HK',
  },
  {
    value: '1705.HK',
    label: '1705.HK,  B & S International Holdings Ltd.',
    name: 'B & S International Holdings Ltd.',
    symbol: '1705.HK',
  },
  {
    value: '1706.HK',
    label: '1706.HK,  Shuang Yun Holdings Limited',
    name: 'Shuang Yun Holdings Limited',
    symbol: '1706.HK',
  },
  {
    value: '1707.HK',
    label: '1707.HK,  Geotech Holdings Ltd.',
    name: 'Geotech Holdings Ltd.',
    symbol: '1707.HK',
  },
  {
    value: '1709.HK',
    label: '1709.HK,  DL Holdings Group Limited',
    name: 'DL Holdings Group Limited',
    symbol: '1709.HK',
  },
  {
    value: '1710.HK',
    label: '1710.HK,  Trio Industrial Electronics Group Limited',
    name: 'Trio Industrial Electronics Group Limited',
    symbol: '1710.HK',
  },
  {
    value: '1711.HK',
    label: '1711.HK,  Ulferts International Limited',
    name: 'Ulferts International Limited',
    symbol: '1711.HK',
  },
  {
    value: '1712.HK',
    label: '1712.HK,  Dragon Mining Limited',
    name: 'Dragon Mining Limited',
    symbol: '1712.HK',
  },
  {
    value: '1713.HK',
    label: '1713.HK,  Sichuan Energy Investment Development Co., Ltd.',
    name: 'Sichuan Energy Investment Development Co., Ltd.',
    symbol: '1713.HK',
  },
  {
    value: '1715.HK',
    label: '1715.HK,  Miji International Holdings Limited',
    name: 'Miji International Holdings Limited',
    symbol: '1715.HK',
  },
  {
    value: '1716.HK',
    label: '1716.HK,  Most Kwai Chung Limited',
    name: 'Most Kwai Chung Limited',
    symbol: '1716.HK',
  },
  {
    value: '1717.HK',
    label: '1717.HK,  Ausnutria Dairy Corporation Ltd',
    name: 'Ausnutria Dairy Corporation Ltd',
    symbol: '1717.HK',
  },
  {
    value: '1718.HK',
    label: '1718.HK,  Wan Kei Group Holdings Limited',
    name: 'Wan Kei Group Holdings Limited',
    symbol: '1718.HK',
  },
  {
    value: '1719.HK',
    label: '1719.HK,  China Infrastructure & Logistics Group Ltd.',
    name: 'China Infrastructure & Logistics Group Ltd.',
    symbol: '1719.HK',
  },
  {
    value: '1720.HK',
    label: '1720.HK,  Putian Communication Group Limited',
    name: 'Putian Communication Group Limited',
    symbol: '1720.HK',
  },
  {
    value: '1721.HK',
    label: '1721.HK,  FSM Holdings Limited',
    name: 'FSM Holdings Limited',
    symbol: '1721.HK',
  },
  {
    value: '1722.HK',
    label: '1722.HK,  Kin Pang Holdings Limited',
    name: 'Kin Pang Holdings Limited',
    symbol: '1722.HK',
  },
  {
    value: '1723.HK',
    label: '1723.HK,  HK Asia Holdings Limited',
    name: 'HK Asia Holdings Limited',
    symbol: '1723.HK',
  },
  {
    value: '1725.HK',
    label: '1725.HK,  Hong Kong Aerospace Technology Group Limited',
    name: 'Hong Kong Aerospace Technology Group Limited',
    symbol: '1725.HK',
  },
  {
    value: '1726.HK',
    label: '1726.HK,  HKE Holdings Limited',
    name: 'HKE Holdings Limited',
    symbol: '1726.HK',
  },
  {
    value: '1727.HK',
    label: '1727.HK,  Hebei Construction Group Corporation Limited',
    name: 'Hebei Construction Group Corporation Limited',
    symbol: '1727.HK',
  },
  {
    value: '1728.HK',
    label: '1728.HK,  China ZhengTong Auto Services Holdings Limited',
    name: 'China ZhengTong Auto Services Holdings Limited',
    symbol: '1728.HK',
  },
  {
    value: '1729.HK',
    label: '1729.HK,  Time Interconnect Technology Limited',
    name: 'Time Interconnect Technology Limited',
    symbol: '1729.HK',
  },
  {
    value: '1730.HK',
    label: '1730.HK,  LHN Limited',
    name: 'LHN Limited',
    symbol: '1730.HK',
  },
  {
    value: '1731.HK',
    label: '1731.HK,  Prosperous Industrial (Holdings) Limited',
    name: 'Prosperous Industrial (Holdings) Limited',
    symbol: '1731.HK',
  },
  {
    value: '1732.HK',
    label: '1732.HK,  Xiangxing International Holding Limited',
    name: 'Xiangxing International Holding Limited',
    symbol: '1732.HK',
  },
  {
    value: '1733.HK',
    label: '1733.HK,  E-Commodities Holdings Limited',
    name: 'E-Commodities Holdings Limited',
    symbol: '1733.HK',
  },
  {
    value: '1735.HK',
    label: '1735.HK,  Central Holding Group Co. Ltd.',
    name: 'Central Holding Group Co. Ltd.',
    symbol: '1735.HK',
  },
  {
    value: '1736.HK',
    label: '1736.HK,  China Parenting Network Holdings Limited',
    name: 'China Parenting Network Holdings Limited',
    symbol: '1736.HK',
  },
  {
    value: '1737.HK',
    label: '1737.HK,  A & S Group (Holdings) Limited',
    name: 'A & S Group (Holdings) Limited',
    symbol: '1737.HK',
  },
  {
    value: '1738.HK',
    label: '1738.HK,  Feishang Anthracite Resources Limited',
    name: 'Feishang Anthracite Resources Limited',
    symbol: '1738.HK',
  },
  {
    value: '1739.HK',
    label: '1739.HK,  Qeeka Home (Cayman) Inc.',
    name: 'Qeeka Home (Cayman) Inc.',
    symbol: '1739.HK',
  },
  {
    value: '1740.HK',
    label: '1740.HK,  Values Cultural Investment Limited',
    name: 'Values Cultural Investment Limited',
    symbol: '1740.HK',
  },
  {
    value: '1741.HK',
    label: '1741.HK,  Ri Ying Holdings Limited',
    name: 'Ri Ying Holdings Limited',
    symbol: '1741.HK',
  },
  {
    value: '1742.HK',
    label: '1742.HK,  HPC Holdings Limited',
    name: 'HPC Holdings Limited',
    symbol: '1742.HK',
  },
  {
    value: '1745.HK',
    label: '1745.HK,  Lvji Technology Holdings Inc.',
    name: 'Lvji Technology Holdings Inc.',
    symbol: '1745.HK',
  },
  {
    value: '1746.HK',
    label: '1746.HK,  Man Shun Group (Holdings) Limited',
    name: 'Man Shun Group (Holdings) Limited',
    symbol: '1746.HK',
  },
  {
    value: '1747.HK',
    label: '1747.HK,  Home Control International Limited',
    name: 'Home Control International Limited',
    symbol: '1747.HK',
  },
  {
    value: '1748.HK',
    label: '1748.HK,  Xin Yuan Enterprises Group Limited',
    name: 'Xin Yuan Enterprises Group Limited',
    symbol: '1748.HK',
  },
  {
    value: '1749.HK',
    label: '1749.HK,  Shanshan Brand Management Co., Ltd.',
    name: 'Shanshan Brand Management Co., Ltd.',
    symbol: '1749.HK',
  },
  {
    value: '1750.HK',
    label: '1750.HK,  REM Group (Holdings) Limited',
    name: 'REM Group (Holdings) Limited',
    symbol: '1750.HK',
  },
  {
    value: '1751.HK',
    label: '1751.HK,  Kingland Group Holdings Limited',
    name: 'Kingland Group Holdings Limited',
    symbol: '1751.HK',
  },
  {
    value: '1752.HK',
    label: '1752.HK,  Top Education Group Ltd',
    name: 'Top Education Group Ltd',
    symbol: '1752.HK',
  },
  {
    value: '1753.HK',
    label: '1753.HK,  Duiba Group Limited',
    name: 'Duiba Group Limited',
    symbol: '1753.HK',
  },
  {
    value: '1755.HK',
    label: '1755.HK,  S-Enjoy Service Group Co., Limited',
    name: 'S-Enjoy Service Group Co., Limited',
    symbol: '1755.HK',
  },
  {
    value: '1756.HK',
    label: '1756.HK,  China Vocational Education Holdings Limited',
    name: 'China Vocational Education Holdings Limited',
    symbol: '1756.HK',
  },
  {
    value: '1757.HK',
    label: '1757.HK,  Affluent Foundation Holdings Limited',
    name: 'Affluent Foundation Holdings Limited',
    symbol: '1757.HK',
  },
  {
    value: '1759.HK',
    label: '1759.HK,  Sino Gas Holdings Group Limited',
    name: 'Sino Gas Holdings Group Limited',
    symbol: '1759.HK',
  },
  {
    value: '1760.HK',
    label: '1760.HK,  Intron Technology Holdings Limited',
    name: 'Intron Technology Holdings Limited',
    symbol: '1760.HK',
  },
  {
    value: '1761.HK',
    label: '1761.HK,  BabyTree Group',
    name: 'BabyTree Group',
    symbol: '1761.HK',
  },
  {
    value: '1762.HK',
    label: '1762.HK,  Wanka Online Inc.',
    name: 'Wanka Online Inc.',
    symbol: '1762.HK',
  },
  {
    value: '1763.HK',
    label: '1763.HK,  China Isotope & Radiation Corporation',
    name: 'China Isotope & Radiation Corporation',
    symbol: '1763.HK',
  },
  {
    value: '1765.HK',
    label: '1765.HK,  Hope Education Group Co., Ltd.',
    name: 'Hope Education Group Co., Ltd.',
    symbol: '1765.HK',
  },
  {
    value: '1767.HK',
    label: '1767.HK,  TS Wonders Holding Limited',
    name: 'TS Wonders Holding Limited',
    symbol: '1767.HK',
  },
  {
    value: '1769.HK',
    label: '1769.HK,  Scholar Education Group',
    name: 'Scholar Education Group',
    symbol: '1769.HK',
  },
  {
    value: '1771.HK',
    label: '1771.HK,  Sunfonda Group Holdings Limited',
    name: 'Sunfonda Group Holdings Limited',
    symbol: '1771.HK',
  },
  {
    value: '1773.HK',
    label: '1773.HK,  Tianli International Holdings Limited',
    name: 'Tianli International Holdings Limited',
    symbol: '1773.HK',
  },
  {
    value: '1775.HK',
    label: '1775.HK,  BExcellent Group Holdings Limited',
    name: 'BExcellent Group Holdings Limited',
    symbol: '1775.HK',
  },
  {
    value: '1780.HK',
    label: '1780.HK,  B & D Strategic Holdings Limited',
    name: 'B & D Strategic Holdings Limited',
    symbol: '1780.HK',
  },
  {
    value: '1782.HK',
    label: '1782.HK,  Vixtel Technologies Holdings Limited',
    name: 'Vixtel Technologies Holdings Limited',
    symbol: '1782.HK',
  },
  {
    value: '1783.HK',
    label: '1783.HK,  Golden Ponder Holdings Limited',
    name: 'Golden Ponder Holdings Limited',
    symbol: '1783.HK',
  },
  {
    value: '1785.HK',
    label: '1785.HK,  Chengdu Expressway Co., Ltd.',
    name: 'Chengdu Expressway Co., Ltd.',
    symbol: '1785.HK',
  },
  {
    value: '1786.HK',
    label: '1786.HK,  CRCC High-Tech Equipment Corporation Limited',
    name: 'CRCC High-Tech Equipment Corporation Limited',
    symbol: '1786.HK',
  },
  {
    value: '1788.HK',
    label: '1788.HK,  Guotai Junan International Holdings Limited',
    name: 'Guotai Junan International Holdings Limited',
    symbol: '1788.HK',
  },
  {
    value: '1789.HK',
    label: '1789.HK,  AK Medical Holdings Limited',
    name: 'AK Medical Holdings Limited',
    symbol: '1789.HK',
  },
  {
    value: '1790.HK',
    label: '1790.HK,  TIL Enviro Limited',
    name: 'TIL Enviro Limited',
    symbol: '1790.HK',
  },
  {
    value: '1793.HK',
    label: '1793.HK,  Wecon Holdings Limited',
    name: 'Wecon Holdings Limited',
    symbol: '1793.HK',
  },
  {
    value: '1796.HK',
    label: '1796.HK,  Yield Go Holdings Ltd.',
    name: 'Yield Go Holdings Ltd.',
    symbol: '1796.HK',
  },
  {
    value: '1797.HK',
    label: '1797.HK,  Koolearn Technology Holding Limited',
    name: 'Koolearn Technology Holding Limited',
    symbol: '1797.HK',
  },
  {
    value: '1798.HK',
    label: '1798.HK,  China Datang Corporation Renewable Power Co., Limited',
    name: 'China Datang Corporation Renewable Power Co., Limited',
    symbol: '1798.HK',
  },
  {
    value: '1799.HK',
    label: '1799.HK,  Xinte Energy Co., Ltd.',
    name: 'Xinte Energy Co., Ltd.',
    symbol: '1799.HK',
  },
  {
    value: '1801.HK',
    label: '1801.HK,  Innovent Biologics, Inc.',
    name: 'Innovent Biologics, Inc.',
    symbol: '1801.HK',
  },
  {
    value: '1803.HK',
    label: '1803.HK,  Beijing Sports and Entertainment Industry Group Limited',
    name: 'Beijing Sports and Entertainment Industry Group Limited',
    symbol: '1803.HK',
  },
  {
    value: '1808.HK',
    label: '1808.HK,  Enterprise Development Holdings Limited',
    name: 'Enterprise Development Holdings Limited',
    symbol: '1808.HK',
  },
  {
    value: '1809.HK',
    label: '1809.HK,  Prinx Chengshan Holdings Limited',
    name: 'Prinx Chengshan Holdings Limited',
    symbol: '1809.HK',
  },
  {
    value: '1810.HK',
    label: '1810.HK,  Xiaomi Corporation',
    name: 'Xiaomi Corporation',
    symbol: '1810.HK',
  },
  {
    value: '1811.HK',
    label: '1811.HK,  CGN New Energy Holdings Co., Ltd.',
    name: 'CGN New Energy Holdings Co., Ltd.',
    symbol: '1811.HK',
  },
  {
    value: '1813.HK',
    label: '1813.HK,  KWG Group Holdings Limited',
    name: 'KWG Group Holdings Limited',
    symbol: '1813.HK',
  },
  {
    value: '1815.HK',
    label: '1815.HK,  CSMall Group Limited',
    name: 'CSMall Group Limited',
    symbol: '1815.HK',
  },
  {
    value: '1817.HK',
    label: '1817.HK,  Mulsanne Group Holding Limited',
    name: 'Mulsanne Group Holding Limited',
    symbol: '1817.HK',
  },
  {
    value: '1818.HK',
    label: '1818.HK,  Zhaojin Mining Industry Company Limited',
    name: 'Zhaojin Mining Industry Company Limited',
    symbol: '1818.HK',
  },
  {
    value: '1820.HK',
    label: '1820.HK,  Pacific Millennium Packaging Group Corporation',
    name: 'Pacific Millennium Packaging Group Corporation',
    symbol: '1820.HK',
  },
  {
    value: '1821.HK',
    label: '1821.HK,  ESR Group Limited',
    name: 'ESR Group Limited',
    symbol: '1821.HK',
  },
  {
    value: '1823.HK',
    label: '1823.HK,  Huayu Expressway Group Limited',
    name: 'Huayu Expressway Group Limited',
    symbol: '1823.HK',
  },
  {
    value: '1825.HK',
    label: '1825.HK,  Sterling Group Holdings Limited',
    name: 'Sterling Group Holdings Limited',
    symbol: '1825.HK',
  },
  {
    value: '1827.HK',
    label: '1827.HK,  Miricor Enterprises Holdings Limited',
    name: 'Miricor Enterprises Holdings Limited',
    symbol: '1827.HK',
  },
  {
    value: '1830.HK',
    label: '1830.HK,  Perfect Medical Health Management Limited',
    name: 'Perfect Medical Health Management Limited',
    symbol: '1830.HK',
  },
  {
    value: '1831.HK',
    label: '1831.HK,  ShiFang Holding Limited',
    name: 'ShiFang Holding Limited',
    symbol: '1831.HK',
  },
  {
    value: '1832.HK',
    label: '1832.HK,  S.A.I. Leisure Group Company Limited',
    name: 'S.A.I. Leisure Group Company Limited',
    symbol: '1832.HK',
  },
  {
    value: '1833.HK',
    label: '1833.HK,  Ping An Healthcare and Technology Company Limited',
    name: 'Ping An Healthcare and Technology Company Limited',
    symbol: '1833.HK',
  },
  {
    value: '1835.HK',
    label: '1835.HK,  Shanghai Realway Capital Assets Management Co., Ltd.',
    name: 'Shanghai Realway Capital Assets Management Co., Ltd.',
    symbol: '1835.HK',
  },
  {
    value: '1836.HK',
    label: '1836.HK,  Stella International Holdings Limited',
    name: 'Stella International Holdings Limited',
    symbol: '1836.HK',
  },
  {
    value: '1837.HK',
    label: '1837.HK,  Natural Food International Holding Limited',
    name: 'Natural Food International Holding Limited',
    symbol: '1837.HK',
  },
  {
    value: '1839.HK',
    label: '1839.HK,  CIMC Vehicles (Group) Co., Ltd.',
    name: 'CIMC Vehicles (Group) Co., Ltd.',
    symbol: '1839.HK',
  },
  {
    value: '1841.HK',
    label: '1841.HK,  A.Plus Group Holdings Limited',
    name: 'A.Plus Group Holdings Limited',
    symbol: '1841.HK',
  },
  {
    value: '1842.HK',
    label: '1842.HK,  Grown Up Group Investment Holdings Limited',
    name: 'Grown Up Group Investment Holdings Limited',
    symbol: '1842.HK',
  },
  {
    value: '1843.HK',
    label: '1843.HK,  Snack Empire Holdings Limited',
    name: 'Snack Empire Holdings Limited',
    symbol: '1843.HK',
  },
  {
    value: '1845.HK',
    label: '1845.HK,  Weigang Environmental Technology Holding Group Limited',
    name: 'Weigang Environmental Technology Holding Group Limited',
    symbol: '1845.HK',
  },
  {
    value: '1846.HK',
    label: '1846.HK,  EuroEyes International Eye Clinic Limited',
    name: 'EuroEyes International Eye Clinic Limited',
    symbol: '1846.HK',
  },
  {
    value: '1847.HK',
    label: '1847.HK,  YCIH Green High-Performance Concrete Company Limited',
    name: 'YCIH Green High-Performance Concrete Company Limited',
    symbol: '1847.HK',
  },
  {
    value: '1848.HK',
    label: '1848.HK,  China Aircraft Leasing Group Holdings Limited',
    name: 'China Aircraft Leasing Group Holdings Limited',
    symbol: '1848.HK',
  },
  {
    value: '1849.HK',
    label: '1849.HK,  AM Group Holdings Limited',
    name: 'AM Group Holdings Limited',
    symbol: '1849.HK',
  },
  {
    value: '1851.HK',
    label: '1851.HK,  China Gingko Education Group Company Limited',
    name: 'China Gingko Education Group Company Limited',
    symbol: '1851.HK',
  },
  {
    value: '1853.HK',
    label: '1853.HK,  Jilin Province Chuncheng Heating Company Limited',
    name: 'Jilin Province Chuncheng Heating Company Limited',
    symbol: '1853.HK',
  },
  {
    value: '1854.HK',
    label: '1854.HK,  China Wantian Holdings Limited',
    name: 'China Wantian Holdings Limited',
    symbol: '1854.HK',
  },
  {
    value: '1856.HK',
    label: '1856.HK,  Ernest Borel Holdings Limited',
    name: 'Ernest Borel Holdings Limited',
    symbol: '1856.HK',
  },
  {
    value: '1857.HK',
    label: '1857.HK,  China Everbright Water Limited',
    name: 'China Everbright Water Limited',
    symbol: '1857.HK',
  },
  {
    value: '1858.HK',
    label: '1858.HK,  Beijing Chunlizhengda Medical Instruments Co., Ltd.',
    name: 'Beijing Chunlizhengda Medical Instruments Co., Ltd.',
    symbol: '1858.HK',
  },
  {
    value: '1859.HK',
    label: '1859.HK,  China Bright Culture Group',
    name: 'China Bright Culture Group',
    symbol: '1859.HK',
  },
  {
    value: '1860.HK',
    label: '1860.HK,  Mobvista Inc.',
    name: 'Mobvista Inc.',
    symbol: '1860.HK',
  },
  {
    value: '1861.HK',
    label: '1861.HK,  Precious Dragon Technology Holdings Limited',
    name: 'Precious Dragon Technology Holdings Limited',
    symbol: '1861.HK',
  },
  {
    value: '1865.HK',
    label: '1865.HK,  Trendzon Holdings Group Limited',
    name: 'Trendzon Holdings Group Limited',
    symbol: '1865.HK',
  },
  {
    value: '1866.HK',
    label: '1866.HK,  China XLX Fertiliser Ltd.',
    name: 'China XLX Fertiliser Ltd.',
    symbol: '1866.HK',
  },
  {
    value: '1867.HK',
    label: '1867.HK,  Standard Development Group Limited',
    name: 'Standard Development Group Limited',
    symbol: '1867.HK',
  },
  {
    value: '1868.HK',
    label: '1868.HK,  Neo-Neon Holdings Limited',
    name: 'Neo-Neon Holdings Limited',
    symbol: '1868.HK',
  },
  {
    value: '1869.HK',
    label: '1869.HK,  Li Bao Ge Group Limited',
    name: 'Li Bao Ge Group Limited',
    symbol: '1869.HK',
  },
  {
    value: '1870.HK',
    label: '1870.HK,  Acme International Holdings Limited',
    name: 'Acme International Holdings Limited',
    symbol: '1870.HK',
  },
  {
    value: '1871.HK',
    label: '1871.HK,  China Oriented International Holdings Limited',
    name: 'China Oriented International Holdings Limited',
    symbol: '1871.HK',
  },
  {
    value: '1872.HK',
    label: '1872.HK,  Guan Chao Holdings Limited',
    name: 'Guan Chao Holdings Limited',
    symbol: '1872.HK',
  },
  {
    value: '1873.HK',
    label: '1873.HK,  Viva Biotech Holdings',
    name: 'Viva Biotech Holdings',
    symbol: '1873.HK',
  },
  {
    value: '1875.HK',
    label: '1875.HK,  TOT BIOPHARM International Company Limited',
    name: 'TOT BIOPHARM International Company Limited',
    symbol: '1875.HK',
  },
  {
    value: '1876.HK',
    label: '1876.HK,  Budweiser Brewing Company APAC Limited',
    name: 'Budweiser Brewing Company APAC Limited',
    symbol: '1876.HK',
  },
  {
    value: '1878.HK',
    label: '1878.HK,  SouthGobi Resources Ltd.',
    name: 'SouthGobi Resources Ltd.',
    symbol: '1878.HK',
  },
  {
    value: '1881.HK',
    label: '1881.HK,  Regal Real Estate Investment Trust',
    name: 'Regal Real Estate Investment Trust',
    symbol: '1881.HK',
  },
  {
    value: '1882.HK',
    label: '1882.HK,  Haitian International Holdings Limited',
    name: 'Haitian International Holdings Limited',
    symbol: '1882.HK',
  },
  {
    value: '1883.HK',
    label: '1883.HK,  CITIC Telecom International Holdings Limited',
    name: 'CITIC Telecom International Holdings Limited',
    symbol: '1883.HK',
  },
  {
    value: '1884.HK',
    label: '1884.HK,  eprint Group Limited',
    name: 'eprint Group Limited',
    symbol: '1884.HK',
  },
  {
    value: '1888.HK',
    label: '1888.HK,  Kingboard Laminates Holdings Limited',
    name: 'Kingboard Laminates Holdings Limited',
    symbol: '1888.HK',
  },
  {
    value: '1890.HK',
    label: '1890.HK,  China Kepei Education Group Limited',
    name: 'China Kepei Education Group Limited',
    symbol: '1890.HK',
  },
  {
    value: '1891.HK',
    label: '1891.HK,  Heng Hup Holdings Limited',
    name: 'Heng Hup Holdings Limited',
    symbol: '1891.HK',
  },
  {
    value: '1895.HK',
    label: '1895.HK,  Xinyuan Property Management Service (Cayman) Ltd.',
    name: 'Xinyuan Property Management Service (Cayman) Ltd.',
    symbol: '1895.HK',
  },
  {
    value: '1896.HK',
    label: '1896.HK,  Maoyan Entertainment',
    name: 'Maoyan Entertainment',
    symbol: '1896.HK',
  },
  {
    value: '1897.HK',
    label: '1897.HK,  Million Hope Industries Holdings Limited',
    name: 'Million Hope Industries Holdings Limited',
    symbol: '1897.HK',
  },
  {
    value: '1899.HK',
    label: '1899.HK,  Xingda International Holdings Limited',
    name: 'Xingda International Holdings Limited',
    symbol: '1899.HK',
  },
  {
    value: '1900.HK',
    label: '1900.HK,  China ITS (Holdings) Co., Ltd.',
    name: 'China ITS (Holdings) Co., Ltd.',
    symbol: '1900.HK',
  },
  {
    value: '1901.HK',
    label: '1901.HK,  Feiyang International Holdings Group Limited',
    name: 'Feiyang International Holdings Group Limited',
    symbol: '1901.HK',
  },
  {
    value: '1902.HK',
    label: '1902.HK,  Yincheng International Holding Co., Ltd.',
    name: 'Yincheng International Holding Co., Ltd.',
    symbol: '1902.HK',
  },
  {
    value: '1903.HK',
    label: '1903.HK,  JBB Builders International Limited',
    name: 'JBB Builders International Limited',
    symbol: '1903.HK',
  },
  {
    value: '1905.HK',
    label:
      '1905.HK,  Haitong Unitrust International Financial Leasing Co., Ltd.',
    name: 'Haitong Unitrust International Financial Leasing Co., Ltd.',
    symbol: '1905.HK',
  },
  {
    value: '1906.HK',
    label: '1906.HK,  Bonny International Holding Limited',
    name: 'Bonny International Holding Limited',
    symbol: '1906.HK',
  },
  {
    value: '1907.HK',
    label: '1907.HK,  China Risun Group Limited',
    name: 'China Risun Group Limited',
    symbol: '1907.HK',
  },
  {
    value: '1908.HK',
    label: '1908.HK,  C&D International Investment Group Limited',
    name: 'C&D International Investment Group Limited',
    symbol: '1908.HK',
  },
  {
    value: '1910.HK',
    label: '1910.HK,  Samsonite International S.A.',
    name: 'Samsonite International S.A.',
    symbol: '1910.HK',
  },
  {
    value: '1911.HK',
    label: '1911.HK,  China Renaissance Holdings Limited',
    name: 'China Renaissance Holdings Limited',
    symbol: '1911.HK',
  },
  {
    value: '1912.HK',
    label: '1912.HK,  Contel Technology Company Limited',
    name: 'Contel Technology Company Limited',
    symbol: '1912.HK',
  },
  {
    value: '1913.HK',
    label: '1913.HK,  Prada S.p.A.',
    name: 'Prada S.p.A.',
    symbol: '1913.HK',
  },
  {
    value: '1915.HK',
    label:
      '1915.HK,  Yangzhou Guangling District Taihe Rural Micro-finance Company Limited',
    name: 'Yangzhou Guangling District Taihe Rural Micro-finance Company Limited',
    symbol: '1915.HK',
  },
  {
    value: '1916.HK',
    label: '1916.HK,  Jiangxi Bank Co., Ltd.',
    name: 'Jiangxi Bank Co., Ltd.',
    symbol: '1916.HK',
  },
  {
    value: '1917.HK',
    label: '1917.HK,  Doumob',
    name: 'Doumob',
    symbol: '1917.HK',
  },
  {
    value: '1920.HK',
    label: '1920.HK,  Hands Form Holdings Limited',
    name: 'Hands Form Holdings Limited',
    symbol: '1920.HK',
  },
  {
    value: '1921.HK',
    label: '1921.HK,  Dalipal Holdings Limited',
    name: 'Dalipal Holdings Limited',
    symbol: '1921.HK',
  },
  {
    value: '1922.HK',
    label: '1922.HK,  Yincheng Life Service CO., Ltd.',
    name: 'Yincheng Life Service CO., Ltd.',
    symbol: '1922.HK',
  },
  {
    value: '1925.HK',
    label: "1925.HK,  Kwung's Holdings Limited",
    name: "Kwung's Holdings Limited",
    symbol: '1925.HK',
  },
  {
    value: '1928.HK',
    label: '1928.HK,  Sands China Ltd.',
    name: 'Sands China Ltd.',
    symbol: '1928.HK',
  },
  {
    value: '1929.HK',
    label: '1929.HK,  Chow Tai Fook Jewellery Group Limited',
    name: 'Chow Tai Fook Jewellery Group Limited',
    symbol: '1929.HK',
  },
  {
    value: '1930.HK',
    label: '1930.HK,  Shinelong Automotive Lightweight Application Limited',
    name: 'Shinelong Automotive Lightweight Application Limited',
    symbol: '1930.HK',
  },
  {
    value: '1931.HK',
    label: '1931.HK,  IVD Medical Holding Limited',
    name: 'IVD Medical Holding Limited',
    symbol: '1931.HK',
  },
  {
    value: '1932.HK',
    label: '1932.HK,  CPM Group Limited',
    name: 'CPM Group Limited',
    symbol: '1932.HK',
  },
  {
    value: '1933.HK',
    label: '1933.HK,  OneForce Holdings Limited',
    name: 'OneForce Holdings Limited',
    symbol: '1933.HK',
  },
  {
    value: '1935.HK',
    label: '1935.HK,  JH Educational Technology INC.',
    name: 'JH Educational Technology INC.',
    symbol: '1935.HK',
  },
  {
    value: '1936.HK',
    label: '1936.HK,  Ritamix Global Limited',
    name: 'Ritamix Global Limited',
    symbol: '1936.HK',
  },
  {
    value: '1937.HK',
    label: '1937.HK,  JiaChen Holding Group Limited',
    name: 'JiaChen Holding Group Limited',
    symbol: '1937.HK',
  },
  {
    value: '1938.HK',
    label:
      '1938.HK,  Chu Kong Petroleum and Natural Gas Steel Pipe Holdings Limited',
    name: 'Chu Kong Petroleum and Natural Gas Steel Pipe Holdings Limited',
    symbol: '1938.HK',
  },
  {
    value: '1939.HK',
    label: '1939.HK,  Tokyo Chuo Auction Holdings Limited',
    name: 'Tokyo Chuo Auction Holdings Limited',
    symbol: '1939.HK',
  },
  {
    value: '1941.HK',
    label: '1941.HK,  Ye Xing Group Holdings Limited',
    name: 'Ye Xing Group Holdings Limited',
    symbol: '1941.HK',
  },
  {
    value: '1942.HK',
    label: '1942.HK,  MOG Holdings Limited',
    name: 'MOG Holdings Limited',
    symbol: '1942.HK',
  },
  {
    value: '1943.HK',
    label: '1943.HK,  Silver Tide Holdings Limited',
    name: 'Silver Tide Holdings Limited',
    symbol: '1943.HK',
  },
  {
    value: '1949.HK',
    label: '1949.HK,  Platt Nera International Limited',
    name: 'Platt Nera International Limited',
    symbol: '1949.HK',
  },
  {
    value: '1950.HK',
    label: '1950.HK,  Diwang Industrial Holdings Limited',
    name: 'Diwang Industrial Holdings Limited',
    symbol: '1950.HK',
  },
  {
    value: '1951.HK',
    label: '1951.HK,  Jinxin Fertility Group Limited',
    name: 'Jinxin Fertility Group Limited',
    symbol: '1951.HK',
  },
  {
    value: '1953.HK',
    label: '1953.HK,  Rimbaco Group Global Limited',
    name: 'Rimbaco Group Global Limited',
    symbol: '1953.HK',
  },
  {
    value: '1955.HK',
    label: '1955.HK,  Hong Kong Johnson Holdings Co., Ltd.',
    name: 'Hong Kong Johnson Holdings Co., Ltd.',
    symbol: '1955.HK',
  },
  {
    value: '1958.HK',
    label: '1958.HK,  BAIC Motor Corporation Limited',
    name: 'BAIC Motor Corporation Limited',
    symbol: '1958.HK',
  },
  {
    value: '1959.HK',
    label: '1959.HK,  Centenary United Holdings Limited',
    name: 'Centenary United Holdings Limited',
    symbol: '1959.HK',
  },
  {
    value: '1960.HK',
    label: '1960.HK,  TBK & Sons Holdings Limited',
    name: 'TBK & Sons Holdings Limited',
    symbol: '1960.HK',
  },
  {
    value: '1961.HK',
    label:
      '1961.HK,  Infinities Technology International (Cayman) Holding Limited',
    name: 'Infinities Technology International (Cayman) Holding Limited',
    symbol: '1961.HK',
  },
  {
    value: '1962.HK',
    label: '1962.HK,  Evergreen Products Group Limited',
    name: 'Evergreen Products Group Limited',
    symbol: '1962.HK',
  },
  {
    value: '1963.HK',
    label: '1963.HK,  Bank of Chongqing Co., Ltd.',
    name: 'Bank of Chongqing Co., Ltd.',
    symbol: '1963.HK',
  },
  {
    value: '1966.HK',
    label: '1966.HK,  China SCE Group Holdings Limited',
    name: 'China SCE Group Holdings Limited',
    symbol: '1966.HK',
  },
  {
    value: '1967.HK',
    label: '1967.HK,  Confidence Intelligence Holdings Limited',
    name: 'Confidence Intelligence Holdings Limited',
    symbol: '1967.HK',
  },
  {
    value: '1968.HK',
    label: '1968.HK,  Hingtex Holdings Limited',
    name: 'Hingtex Holdings Limited',
    symbol: '1968.HK',
  },
  {
    value: '1970.HK',
    label: '1970.HK,  IMAX China Holding, Inc.',
    name: 'IMAX China Holding, Inc.',
    symbol: '1970.HK',
  },
  {
    value: '1972.HK',
    label: '1972.HK,  Swire Properties Limited',
    name: 'Swire Properties Limited',
    symbol: '1972.HK',
  },
  {
    value: '1975.HK',
    label: '1975.HK,  Sun Hing Printing Holdings Limited',
    name: 'Sun Hing Printing Holdings Limited',
    symbol: '1975.HK',
  },
  {
    value: '1977.HK',
    label: '1977.HK,  Analogue Holdings Limited',
    name: 'Analogue Holdings Limited',
    symbol: '1977.HK',
  },
  {
    value: '1978.HK',
    label: '1978.HK,  LH Group Limited',
    name: 'LH Group Limited',
    symbol: '1978.HK',
  },
  {
    value: '1979.HK',
    label: '1979.HK,  Ten Pao Group Holdings Limited',
    name: 'Ten Pao Group Holdings Limited',
    symbol: '1979.HK',
  },
  {
    value: '1980.HK',
    label: '1980.HK,  Tian Ge Interactive Holdings Limited',
    name: 'Tian Ge Interactive Holdings Limited',
    symbol: '1980.HK',
  },
  {
    value: '1982.HK',
    label: '1982.HK,  Nameson Holdings Limited',
    name: 'Nameson Holdings Limited',
    symbol: '1982.HK',
  },
  {
    value: '1985.HK',
    label: '1985.HK,  Microware Group Limited',
    name: 'Microware Group Limited',
    symbol: '1985.HK',
  },
  {
    value: '1986.HK',
    label: '1986.HK,  Tsaker New Energy Tech Co., Limited',
    name: 'Tsaker New Energy Tech Co., Limited',
    symbol: '1986.HK',
  },
  {
    value: '1987.HK',
    label: '1987.HK,  Beng Soon Machinery Holdings Limited',
    name: 'Beng Soon Machinery Holdings Limited',
    symbol: '1987.HK',
  },
  {
    value: '1991.HK',
    label: '1991.HK,  Ta Yang Group Holdings Limited',
    name: 'Ta Yang Group Holdings Limited',
    symbol: '1991.HK',
  },
  {
    value: '1992.HK',
    label: '1992.HK,  Fosun Tourism Group',
    name: 'Fosun Tourism Group',
    symbol: '1992.HK',
  },
  {
    value: '1993.HK',
    label: '1993.HK,  Asiaray Media Group Limited',
    name: 'Asiaray Media Group Limited',
    symbol: '1993.HK',
  },
  {
    value: '1995.HK',
    label: '1995.HK,  CIFI Ever Sunshine Services Group Limited',
    name: 'CIFI Ever Sunshine Services Group Limited',
    symbol: '1995.HK',
  },
  {
    value: '1996.HK',
    label: '1996.HK,  Redsun Properties Group Limited',
    name: 'Redsun Properties Group Limited',
    symbol: '1996.HK',
  },
  {
    value: '1997.HK',
    label: '1997.HK,  Wharf Real Estate Investment Company Limited',
    name: 'Wharf Real Estate Investment Company Limited',
    symbol: '1997.HK',
  },
  {
    value: '1999.HK',
    label: '1999.HK,  Man Wah Holdings Limited',
    name: 'Man Wah Holdings Limited',
    symbol: '1999.HK',
  },
  {
    value: '2000.HK',
    label: '2000.HK,  SIM Technology Group Limited',
    name: 'SIM Technology Group Limited',
    symbol: '2000.HK',
  },
  {
    value: '2001.HK',
    label: '2001.HK,  China New Higher Education Group Limited',
    name: 'China New Higher Education Group Limited',
    symbol: '2001.HK',
  },
  {
    value: '2002.HK',
    label: '2002.HK,  China Sunshine Paper Holdings Company Limited',
    name: 'China Sunshine Paper Holdings Company Limited',
    symbol: '2002.HK',
  },
  {
    value: '2003.HK',
    label: '2003.HK,  VCREDIT Holdings Limited',
    name: 'VCREDIT Holdings Limited',
    symbol: '2003.HK',
  },
  {
    value: '2005.HK',
    label: '2005.HK,  SSY Group Limited',
    name: 'SSY Group Limited',
    symbol: '2005.HK',
  },
  {
    value: '2007.HK',
    label: '2007.HK,  Country Garden Holdings Company Limited',
    name: 'Country Garden Holdings Company Limited',
    symbol: '2007.HK',
  },
  {
    value: '2008.HK',
    label: '2008.HK,  Phoenix Media Investment (Holdings) Limited',
    name: 'Phoenix Media Investment (Holdings) Limited',
    symbol: '2008.HK',
  },
  {
    value: '2011.HK',
    label: '2011.HK,  China Apex Group Limited',
    name: 'China Apex Group Limited',
    symbol: '2011.HK',
  },
  {
    value: '2012.HK',
    label: '2012.HK,  Sunshine Oilsands Ltd.',
    name: 'Sunshine Oilsands Ltd.',
    symbol: '2012.HK',
  },
  {
    value: '2013.HK',
    label: '2013.HK,  Weimob Inc.',
    name: 'Weimob Inc.',
    symbol: '2013.HK',
  },
  {
    value: '2017.HK',
    label: '2017.HK,  Chanhigh Holdings Limited',
    name: 'Chanhigh Holdings Limited',
    symbol: '2017.HK',
  },
  {
    value: '2018.HK',
    label: '2018.HK,  AAC Technologies Holdings Inc.',
    name: 'AAC Technologies Holdings Inc.',
    symbol: '2018.HK',
  },
  {
    value: '2019.HK',
    label: '2019.HK,  Dexin China Holdings Company Limited',
    name: 'Dexin China Holdings Company Limited',
    symbol: '2019.HK',
  },
  {
    value: '2020.HK',
    label: '2020.HK,  ANTA Sports Products Limited',
    name: 'ANTA Sports Products Limited',
    symbol: '2020.HK',
  },
  {
    value: '2022.HK',
    label: '2022.HK,  Digital Hollywood Interactive Limited',
    name: 'Digital Hollywood Interactive Limited',
    symbol: '2022.HK',
  },
  {
    value: '2023.HK',
    label: '2023.HK,  China Ludao Technology Company Limited',
    name: 'China Ludao Technology Company Limited',
    symbol: '2023.HK',
  },
  {
    value: '2025.HK',
    label: '2025.HK,  Ruifeng Power Group Company Limited',
    name: 'Ruifeng Power Group Company Limited',
    symbol: '2025.HK',
  },
  {
    value: '2028.HK',
    label: '2028.HK,  Jolimark Holdings Limited',
    name: 'Jolimark Holdings Limited',
    symbol: '2028.HK',
  },
  {
    value: '2030.HK',
    label: '2030.HK,  Cabbeen Fashion Limited',
    name: 'Cabbeen Fashion Limited',
    symbol: '2030.HK',
  },
  {
    value: '2033.HK',
    label: '2033.HK,  Time Watch Investments Limited',
    name: 'Time Watch Investments Limited',
    symbol: '2033.HK',
  },
  {
    value: '2038.HK',
    label: '2038.HK,  FIH Mobile Limited',
    name: 'FIH Mobile Limited',
    symbol: '2038.HK',
  },
  {
    value: '2051.HK',
    label: '2051.HK,  51 Credit Card Inc.',
    name: '51 Credit Card Inc.',
    symbol: '2051.HK',
  },
  {
    value: '2060.HK',
    label: '2060.HK,  Pujiang International Group Limited',
    name: 'Pujiang International Group Limited',
    symbol: '2060.HK',
  },
  {
    value: '2066.HK',
    label: '2066.HK,  Shengjing Bank Co., Ltd.',
    name: 'Shengjing Bank Co., Ltd.',
    symbol: '2066.HK',
  },
  {
    value: '2078.HK',
    label: '2078.HK,  PanAsialum Holdings Company Limited',
    name: 'PanAsialum Holdings Company Limited',
    symbol: '2078.HK',
  },
  {
    value: '2080.HK',
    label: '2080.HK,  AUX International Holdings Limited',
    name: 'AUX International Holdings Limited',
    symbol: '2080.HK',
  },
  {
    value: '2086.HK',
    label: '2086.HK,  Leadway Technology Investment Group Limited',
    name: 'Leadway Technology Investment Group Limited',
    symbol: '2086.HK',
  },
  {
    value: '2088.HK',
    label: '2088.HK,  Xiwang Property Holdings Company Limited',
    name: 'Xiwang Property Holdings Company Limited',
    symbol: '2088.HK',
  },
  {
    value: '2098.HK',
    label: '2098.HK,  Zall Smart Commerce Group Ltd.',
    name: 'Zall Smart Commerce Group Ltd.',
    symbol: '2098.HK',
  },
  {
    value: '2099.HK',
    label: '2099.HK,  China Gold International Resources Corp. Ltd.',
    name: 'China Gold International Resources Corp. Ltd.',
    symbol: '2099.HK',
  },
  {
    value: '2100.HK',
    label: '2100.HK,  BAIOO Family Interactive Limited',
    name: 'BAIOO Family Interactive Limited',
    symbol: '2100.HK',
  },
  {
    value: '2108.HK',
    label: '2108.HK,  K2 F&B Holdings Limited',
    name: 'K2 F&B Holdings Limited',
    symbol: '2108.HK',
  },
  {
    value: '2111.HK',
    label: '2111.HK,  Best Pacific International Holdings Limited',
    name: 'Best Pacific International Holdings Limited',
    symbol: '2111.HK',
  },
  {
    value: '2112.HK',
    label: '2112.HK,  CAA Resources Limited',
    name: 'CAA Resources Limited',
    symbol: '2112.HK',
  },
  {
    value: '2113.HK',
    label: '2113.HK,  Century Group International Holdings Limited',
    name: 'Century Group International Holdings Limited',
    symbol: '2113.HK',
  },
  {
    value: '2116.HK',
    label: '2116.HK,  Jiangsu Innovative Ecological New Materials Limited',
    name: 'Jiangsu Innovative Ecological New Materials Limited',
    symbol: '2116.HK',
  },
  {
    value: '2119.HK',
    label: '2119.HK,  Tsit Wing International Holdings Limited',
    name: 'Tsit Wing International Holdings Limited',
    symbol: '2119.HK',
  },
  {
    value: '2122.HK',
    label: '2122.HK,  Kidsland International Holdings Limited',
    name: 'Kidsland International Holdings Limited',
    symbol: '2122.HK',
  },
  {
    value: '2128.HK',
    label: '2128.HK,  China Lesso Group Holdings Limited',
    name: 'China Lesso Group Holdings Limited',
    symbol: '2128.HK',
  },
  {
    value: '2136.HK',
    label: '2136.HK,  Lifestyle China Group Limited',
    name: 'Lifestyle China Group Limited',
    symbol: '2136.HK',
  },
  {
    value: '2138.HK',
    label: '2138.HK,  EC Healthcare',
    name: 'EC Healthcare',
    symbol: '2138.HK',
  },
  {
    value: '2139.HK',
    label: '2139.HK,  Bank of Gansu Co., Ltd.',
    name: 'Bank of Gansu Co., Ltd.',
    symbol: '2139.HK',
  },
  {
    value: '2163.HK',
    label: '2163.HK,  Changsha Broad Homes Industrial Group Co., Ltd.',
    name: 'Changsha Broad Homes Industrial Group Co., Ltd.',
    symbol: '2163.HK',
  },
  {
    value: '2166.HK',
    label: '2166.HK,  Smart-Core Holdings Limited',
    name: 'Smart-Core Holdings Limited',
    symbol: '2166.HK',
  },
  {
    value: '2168.HK',
    label: '2168.HK,  Kaisa Prosperity Holdings Limited',
    name: 'Kaisa Prosperity Holdings Limited',
    symbol: '2168.HK',
  },
  {
    value: '2178.HK',
    label: '2178.HK,  Petro-king Oilfield Services Limited',
    name: 'Petro-king Oilfield Services Limited',
    symbol: '2178.HK',
  },
  {
    value: '2180.HK',
    label: '2180.HK,  ManpowerGroup Greater China Limited',
    name: 'ManpowerGroup Greater China Limited',
    symbol: '2180.HK',
  },
  {
    value: '2181.HK',
    label: '2181.HK,  Mabpharm Limited',
    name: 'Mabpharm Limited',
    symbol: '2181.HK',
  },
  {
    value: '2182.HK',
    label: '2182.HK,  Tian Chang Group Holdings Ltd.',
    name: 'Tian Chang Group Holdings Ltd.',
    symbol: '2182.HK',
  },
  {
    value: '2186.HK',
    label: '2186.HK,  Luye Pharma Group Ltd.',
    name: 'Luye Pharma Group Ltd.',
    symbol: '2186.HK',
  },
  {
    value: '2188.HK',
    label: '2188.HK,  China Titans Energy Technology Group Co., Limited',
    name: 'China Titans Energy Technology Group Co., Limited',
    symbol: '2188.HK',
  },
  {
    value: '2189.HK',
    label: '2189.HK,  Kato (Hong Kong) Holdings Limited',
    name: 'Kato (Hong Kong) Holdings Limited',
    symbol: '2189.HK',
  },
  {
    value: '2193.HK',
    label: '2193.HK,  Man King Holdings Limited',
    name: 'Man King Holdings Limited',
    symbol: '2193.HK',
  },
  {
    value: '2198.HK',
    label: '2198.HK,  China Sanjiang Fine Chemicals Company Limited',
    name: 'China Sanjiang Fine Chemicals Company Limited',
    symbol: '2198.HK',
  },
  {
    value: '2199.HK',
    label: '2199.HK,  Regina Miracle International (Holdings) Limited',
    name: 'Regina Miracle International (Holdings) Limited',
    symbol: '2199.HK',
  },
  {
    value: '2203.HK',
    label: '2203.HK,  Brainhole Technology Limited',
    name: 'Brainhole Technology Limited',
    symbol: '2203.HK',
  },
  {
    value: '2211.HK',
    label: '2211.HK,  Universal Health International Group Holding Limited',
    name: 'Universal Health International Group Holding Limited',
    symbol: '2211.HK',
  },
  {
    value: '2212.HK',
    label: '2212.HK,  Future Bright Mining Holdings Limited',
    name: 'Future Bright Mining Holdings Limited',
    symbol: '2212.HK',
  },
  {
    value: '2218.HK',
    label: '2218.HK,  Yantai North Andre Juice Co.,Ltd.',
    name: 'Yantai North Andre Juice Co.,Ltd.',
    symbol: '2218.HK',
  },
  {
    value: '2221.HK',
    label: '2221.HK,  New Concepts Holdings Limited',
    name: 'New Concepts Holdings Limited',
    symbol: '2221.HK',
  },
  {
    value: '2222.HK',
    label: '2222.HK,  NVC International Holdings Limited',
    name: 'NVC International Holdings Limited',
    symbol: '2222.HK',
  },
  {
    value: '2223.HK',
    label: '2223.HK,  Casablanca Group Limited',
    name: 'Casablanca Group Limited',
    symbol: '2223.HK',
  },
  {
    value: '2225.HK',
    label: '2225.HK,  Jinhai International Group Holdings Limited',
    name: 'Jinhai International Group Holdings Limited',
    symbol: '2225.HK',
  },
  {
    value: '2227.HK',
    label: '2227.HK,  Solis Holdings Limited',
    name: 'Solis Holdings Limited',
    symbol: '2227.HK',
  },
  {
    value: '2230.HK',
    label: '2230.HK,  Medialink Group Limited',
    name: 'Medialink Group Limited',
    symbol: '2230.HK',
  },
  {
    value: '2231.HK',
    label: '2231.HK,  JY Grandmark Holdings Limited',
    name: 'JY Grandmark Holdings Limited',
    symbol: '2231.HK',
  },
  {
    value: '2232.HK',
    label: '2232.HK,  Crystal International Group Limited',
    name: 'Crystal International Group Limited',
    symbol: '2232.HK',
  },
  {
    value: '2233.HK',
    label: '2233.HK,  West China Cement Limited',
    name: 'West China Cement Limited',
    symbol: '2233.HK',
  },
  {
    value: '2236.HK',
    label: '2236.HK,  Wison Engineering Services Co. Ltd.',
    name: 'Wison Engineering Services Co. Ltd.',
    symbol: '2236.HK',
  },
  {
    value: '2239.HK',
    label: '2239.HK,  SMIT Holdings Limited',
    name: 'SMIT Holdings Limited',
    symbol: '2239.HK',
  },
  {
    value: '2255.HK',
    label: '2255.HK,  Haichang Ocean Park Holdings Ltd.',
    name: 'Haichang Ocean Park Holdings Ltd.',
    symbol: '2255.HK',
  },
  {
    value: '2258.HK',
    label: '2258.HK,  Watts International Maritime Company Limited',
    name: 'Watts International Maritime Company Limited',
    symbol: '2258.HK',
  },
  {
    value: '2262.HK',
    label: '2262.HK,  Steve Leung Design Group Limited',
    name: 'Steve Leung Design Group Limited',
    symbol: '2262.HK',
  },
  {
    value: '2263.HK',
    label: '2263.HK,  Fu Shek Financial Holdings Limited',
    name: 'Fu Shek Financial Holdings Limited',
    symbol: '2263.HK',
  },
  {
    value: '2266.HK',
    label: '2266.HK,  Lai Si Enterprise Holding Limited',
    name: 'Lai Si Enterprise Holding Limited',
    symbol: '2266.HK',
  },
  {
    value: '2269.HK',
    label: '2269.HK,  WuXi Biologics (Cayman) Inc.',
    name: 'WuXi Biologics (Cayman) Inc.',
    symbol: '2269.HK',
  },
  {
    value: '2278.HK',
    label: '2278.HK,  Hailan Holdings Limited',
    name: 'Hailan Holdings Limited',
    symbol: '2278.HK',
  },
  {
    value: '2280.HK',
    label: '2280.HK,  HC Group Inc.',
    name: 'HC Group Inc.',
    symbol: '2280.HK',
  },
  {
    value: '2281.HK',
    label: '2281.HK,  Luzhou Xinglu Water (Group) Co., Ltd.',
    name: 'Luzhou Xinglu Water (Group) Co., Ltd.',
    symbol: '2281.HK',
  },
  {
    value: '2282.HK',
    label: '2282.HK,  MGM China Holdings Limited',
    name: 'MGM China Holdings Limited',
    symbol: '2282.HK',
  },
  {
    value: '2283.HK',
    label: '2283.HK,  TK Group (Holdings) Limited',
    name: 'TK Group (Holdings) Limited',
    symbol: '2283.HK',
  },
  {
    value: '2286.HK',
    label: '2286.HK,  Chen Xing Development Holdings Limited',
    name: 'Chen Xing Development Holdings Limited',
    symbol: '2286.HK',
  },
  {
    value: '2288.HK',
    label: '2288.HK,  Rykadan Capital Limited',
    name: 'Rykadan Capital Limited',
    symbol: '2288.HK',
  },
  {
    value: '2289.HK',
    label: '2289.HK,  Charmacy Pharmaceutical Co., Ltd.',
    name: 'Charmacy Pharmaceutical Co., Ltd.',
    symbol: '2289.HK',
  },
  {
    value: '2292.HK',
    label: '2292.HK,  Thing On Enterprise Limited',
    name: 'Thing On Enterprise Limited',
    symbol: '2292.HK',
  },
  {
    value: '2293.HK',
    label: '2293.HK,  Bamboos Health Care Holdings Limited',
    name: 'Bamboos Health Care Holdings Limited',
    symbol: '2293.HK',
  },
  {
    value: '2298.HK',
    label: '2298.HK,  Cosmo Lady (China) Holdings Company Limited',
    name: 'Cosmo Lady (China) Holdings Company Limited',
    symbol: '2298.HK',
  },
  {
    value: '2299.HK',
    label: '2299.HK,  Billion Industrial Holdings Limited',
    name: 'Billion Industrial Holdings Limited',
    symbol: '2299.HK',
  },
  {
    value: '2302.HK',
    label: '2302.HK,  CNNC International Limited',
    name: 'CNNC International Limited',
    symbol: '2302.HK',
  },
  {
    value: '2307.HK',
    label: '2307.HK,  Kam Hing International Holdings Limited',
    name: 'Kam Hing International Holdings Limited',
    symbol: '2307.HK',
  },
  {
    value: '2309.HK',
    label: '2309.HK,  Birmingham Sports Holdings Limited',
    name: 'Birmingham Sports Holdings Limited',
    symbol: '2309.HK',
  },
  {
    value: '2310.HK',
    label: '2310.HK,  Times Universal Group Holdings Limited',
    name: 'Times Universal Group Holdings Limited',
    symbol: '2310.HK',
  },
  {
    value: '2312.HK',
    label: '2312.HK,  China Financial Leasing Group Limited',
    name: 'China Financial Leasing Group Limited',
    symbol: '2312.HK',
  },
  {
    value: '2313.HK',
    label: '2313.HK,  Shenzhou International Group Holdings Limited',
    name: 'Shenzhou International Group Holdings Limited',
    symbol: '2313.HK',
  },
  {
    value: '2314.HK',
    label: '2314.HK,  Lee & Man Paper Manufacturing Limited',
    name: 'Lee & Man Paper Manufacturing Limited',
    symbol: '2314.HK',
  },
  {
    value: '2317.HK',
    label: '2317.HK,  Vedan International (Holdings) Limited',
    name: 'Vedan International (Holdings) Limited',
    symbol: '2317.HK',
  },
  {
    value: '2319.HK',
    label: '2319.HK,  China Mengniu Dairy Company Limited',
    name: 'China Mengniu Dairy Company Limited',
    symbol: '2319.HK',
  },
  {
    value: '2320.HK',
    label: '2320.HK,  Hop Fung Group Holdings Limited',
    name: 'Hop Fung Group Holdings Limited',
    symbol: '2320.HK',
  },
  {
    value: '2322.HK',
    label: '2322.HK,  Hong Kong ChaoShang Group Limited',
    name: 'Hong Kong ChaoShang Group Limited',
    symbol: '2322.HK',
  },
  {
    value: '2323.HK',
    label: '2323.HK,  Renco Holdings Group Limited',
    name: 'Renco Holdings Group Limited',
    symbol: '2323.HK',
  },
  {
    value: '2324.HK',
    label: '2324.HK,  Capital VC Limited',
    name: 'Capital VC Limited',
    symbol: '2324.HK',
  },
  {
    value: '2326.HK',
    label: '2326.HK,  New Provenance Everlasting Holdings Limited',
    name: 'New Provenance Everlasting Holdings Limited',
    symbol: '2326.HK',
  },
  {
    value: '2327.HK',
    label: '2327.HK,  Meilleure Health International Industry Group Limited',
    name: 'Meilleure Health International Industry Group Limited',
    symbol: '2327.HK',
  },
  {
    value: '2328.HK',
    label: '2328.HK,  PICC Property and Casualty Company Limited',
    name: 'PICC Property and Casualty Company Limited',
    symbol: '2328.HK',
  },
  {
    value: '2329.HK',
    label: '2329.HK,  Glory Health Industry Limited',
    name: 'Glory Health Industry Limited',
    symbol: '2329.HK',
  },
  {
    value: '2331.HK',
    label: '2331.HK,  Li Ning Company Limited',
    name: 'Li Ning Company Limited',
    symbol: '2331.HK',
  },
  {
    value: '2336.HK',
    label: '2336.HK,  Hailiang International Holdings Limited',
    name: 'Hailiang International Holdings Limited',
    symbol: '2336.HK',
  },
  {
    value: '2337.HK',
    label: '2337.HK,  United Strength Power Holdings Limited',
    name: 'United Strength Power Holdings Limited',
    symbol: '2337.HK',
  },
  {
    value: '2339.HK',
    label: '2339.HK,  BeijingWest Industries International Limited',
    name: 'BeijingWest Industries International Limited',
    symbol: '2339.HK',
  },
  {
    value: '2340.HK',
    label: '2340.HK,  ISP Holdings Limited',
    name: 'ISP Holdings Limited',
    symbol: '2340.HK',
  },
  {
    value: '2342.HK',
    label: '2342.HK,  Comba Telecom Systems Holdings Limited',
    name: 'Comba Telecom Systems Holdings Limited',
    symbol: '2342.HK',
  },
  {
    value: '2343.HK',
    label: '2343.HK,  Pacific Basin Shipping Limited',
    name: 'Pacific Basin Shipping Limited',
    symbol: '2343.HK',
  },
  {
    value: '2348.HK',
    label: '2348.HK,  Dawnrays Pharmaceutical (Holdings) Limited',
    name: 'Dawnrays Pharmaceutical (Holdings) Limited',
    symbol: '2348.HK',
  },
  {
    value: '2349.HK',
    label: '2349.HK,  China City Infrastructure Group Limited',
    name: 'China City Infrastructure Group Limited',
    symbol: '2349.HK',
  },
  {
    value: '2355.HK',
    label: '2355.HK,  Baoye Group Company Limited',
    name: 'Baoye Group Company Limited',
    symbol: '2355.HK',
  },
  {
    value: '2356.HK',
    label: '2356.HK,  Dah Sing Banking Group Limited',
    name: 'Dah Sing Banking Group Limited',
    symbol: '2356.HK',
  },
  {
    value: '2357.HK',
    label: '2357.HK,  AviChina Industry & Technology Company Limited',
    name: 'AviChina Industry & Technology Company Limited',
    symbol: '2357.HK',
  },
  {
    value: '2358.HK',
    label: '2358.HK,  Jiu Rong Holdings Limited',
    name: 'Jiu Rong Holdings Limited',
    symbol: '2358.HK',
  },
  {
    value: '2360.HK',
    label: '2360.HK,  Best Mart 360 Holdings Limited',
    name: 'Best Mart 360 Holdings Limited',
    symbol: '2360.HK',
  },
  {
    value: '2362.HK',
    label: '2362.HK,  Jinchuan Group International Resources Co. Ltd',
    name: 'Jinchuan Group International Resources Co. Ltd',
    symbol: '2362.HK',
  },
  {
    value: '2363.HK',
    label: '2363.HK,  Tongda Hong Tai Holdings Limited',
    name: 'Tongda Hong Tai Holdings Limited',
    symbol: '2363.HK',
  },
  {
    value: '2368.HK',
    label: '2368.HK,  Eagle Nice (International) Holdings Limited',
    name: 'Eagle Nice (International) Holdings Limited',
    symbol: '2368.HK',
  },
  {
    value: '2369.HK',
    label: '2369.HK,  Coolpad Group Limited',
    name: 'Coolpad Group Limited',
    symbol: '2369.HK',
  },
  {
    value: '2371.HK',
    label: '2371.HK,  Chuanglian Holdings Limited',
    name: 'Chuanglian Holdings Limited',
    symbol: '2371.HK',
  },
  {
    value: '2377.HK',
    label: '2377.HK,  China Boqi Environmental (Holding) Co., Ltd.',
    name: 'China Boqi Environmental (Holding) Co., Ltd.',
    symbol: '2377.HK',
  },
  {
    value: '2378.HK',
    label: '2378.HK,  Prudential plc',
    name: 'Prudential plc',
    symbol: '2378.HK',
  },
  {
    value: '2380.HK',
    label: '2380.HK,  China Power International Development Limited',
    name: 'China Power International Development Limited',
    symbol: '2380.HK',
  },
  {
    value: '2381.HK',
    label: '2381.HK,  SMC Electric Limited',
    name: 'SMC Electric Limited',
    symbol: '2381.HK',
  },
  {
    value: '2382.HK',
    label: '2382.HK,  Sunny Optical Technology (Group) Company Limited',
    name: 'Sunny Optical Technology (Group) Company Limited',
    symbol: '2382.HK',
  },
  {
    value: '2383.HK',
    label: '2383.HK,  TOM Group Limited',
    name: 'TOM Group Limited',
    symbol: '2383.HK',
  },
  {
    value: '2386.HK',
    label: '2386.HK,  SINOPEC Engineering (Group) Co., Ltd.',
    name: 'SINOPEC Engineering (Group) Co., Ltd.',
    symbol: '2386.HK',
  },
  {
    value: '2388.HK',
    label: '2388.HK,  BOC Hong Kong (Holdings) Limited',
    name: 'BOC Hong Kong (Holdings) Limited',
    symbol: '2388.HK',
  },
  {
    value: '2389.HK',
    label: '2389.HK,  Beijing Health (Holdings) Limited',
    name: 'Beijing Health (Holdings) Limited',
    symbol: '2389.HK',
  },
  {
    value: '2393.HK',
    label: '2393.HK,  Yestar Healthcare Holdings Company Limited',
    name: 'Yestar Healthcare Holdings Company Limited',
    symbol: '2393.HK',
  },
  {
    value: '2399.HK',
    label: '2399.HK,  China Anchu Energy Storage Group Limited',
    name: 'China Anchu Energy Storage Group Limited',
    symbol: '2399.HK',
  },
  {
    value: '2400.HK',
    label: '2400.HK,  XD Inc.',
    name: 'XD Inc.',
    symbol: '2400.HK',
  },
  {
    value: '2448.HK',
    label: '2448.HK,  Space Group Holdings Limited',
    name: 'Space Group Holdings Limited',
    symbol: '2448.HK',
  },
  {
    value: '2488.HK',
    label: '2488.HK,  Launch Tech Company Limited',
    name: 'Launch Tech Company Limited',
    symbol: '2488.HK',
  },
  {
    value: '2500.HK',
    label: '2500.HK,  Venus Medtech (Hangzhou) Inc.',
    name: 'Venus Medtech (Hangzhou) Inc.',
    symbol: '2500.HK',
  },
  {
    value: '2528.HK',
    label: '2528.HK,  Forward Fashion (International) Holdings Company Limited',
    name: 'Forward Fashion (International) Holdings Company Limited',
    symbol: '2528.HK',
  },
  {
    value: '2552.HK',
    label: '2552.HK,  Hua Medicine (Shanghai) Ltd.',
    name: 'Hua Medicine (Shanghai) Ltd.',
    symbol: '2552.HK',
  },
  {
    value: '2558.HK',
    label: '2558.HK,  Jinshang Bank Co., Ltd.',
    name: 'Jinshang Bank Co., Ltd.',
    symbol: '2558.HK',
  },
  {
    value: '2588.HK',
    label: '2588.HK,  BOC Aviation Limited',
    name: 'BOC Aviation Limited',
    symbol: '2588.HK',
  },
  {
    value: '2616.HK',
    label: '2616.HK,  CStone Pharmaceuticals',
    name: 'CStone Pharmaceuticals',
    symbol: '2616.HK',
  },
  {
    value: '2623.HK',
    label: '2623.HK,  Add New Energy Investment Holdings Group Limited',
    name: 'Add New Energy Investment Holdings Group Limited',
    symbol: '2623.HK',
  },
  {
    value: '2633.HK',
    label: '2633.HK,  Jacobson Pharma Corporation Limited',
    name: 'Jacobson Pharma Corporation Limited',
    symbol: '2633.HK',
  },
  {
    value: '2638.HK',
    label:
      '2638.HK,  HK Electric Investments and HK Electric Investments Limited',
    name: 'HK Electric Investments and HK Electric Investments Limited',
    symbol: '2638.HK',
  },
  {
    value: '2660.HK',
    label: '2660.HK,  Zengame Technology Holding Limited',
    name: 'Zengame Technology Holding Limited',
    symbol: '2660.HK',
  },
  {
    value: '2663.HK',
    label: '2663.HK,  KPa-BM Holdings Limited',
    name: 'KPa-BM Holdings Limited',
    symbol: '2663.HK',
  },
  {
    value: '2666.HK',
    label: '2666.HK,  Genertec Universal Medical Group Company Limited',
    name: 'Genertec Universal Medical Group Company Limited',
    symbol: '2666.HK',
  },
  {
    value: '2668.HK',
    label: '2668.HK,  Pak Tak International Limited',
    name: 'Pak Tak International Limited',
    symbol: '2668.HK',
  },
  {
    value: '2669.HK',
    label: '2669.HK,  China Overseas Property Holdings Limited',
    name: 'China Overseas Property Holdings Limited',
    symbol: '2669.HK',
  },
  {
    value: '2678.HK',
    label: '2678.HK,  Texhong Textile Group Limited',
    name: 'Texhong Textile Group Limited',
    symbol: '2678.HK',
  },
  {
    value: '2680.HK',
    label: '2680.HK,  Innovax Holdings Limited',
    name: 'Innovax Holdings Limited',
    symbol: '2680.HK',
  },
  {
    value: '2682.HK',
    label: '2682.HK,  Yun Lee Marine Group Holdings Limited',
    name: 'Yun Lee Marine Group Holdings Limited',
    symbol: '2682.HK',
  },
  {
    value: '2683.HK',
    label: '2683.HK,  Wah Sun Handbags International Holdings Limited',
    name: 'Wah Sun Handbags International Holdings Limited',
    symbol: '2683.HK',
  },
  {
    value: '2686.HK',
    label: '2686.HK,  AAG Energy Holdings Limited',
    name: 'AAG Energy Holdings Limited',
    symbol: '2686.HK',
  },
  {
    value: '2688.HK',
    label: '2688.HK,  ENN Energy Holdings Limited',
    name: 'ENN Energy Holdings Limited',
    symbol: '2688.HK',
  },
  {
    value: '2689.HK',
    label: '2689.HK,  Nine Dragons Paper (Holdings) Limited',
    name: 'Nine Dragons Paper (Holdings) Limited',
    symbol: '2689.HK',
  },
  {
    value: '2696.HK',
    label: '2696.HK,  Shanghai Henlius Biotech, Inc.',
    name: 'Shanghai Henlius Biotech, Inc.',
    symbol: '2696.HK',
  },
  {
    value: '2698.HK',
    label: '2698.HK,  Weiqiao Textile Company Limited',
    name: 'Weiqiao Textile Company Limited',
    symbol: '2698.HK',
  },
  {
    value: '2699.HK',
    label: '2699.HK,  Xinming China Holdings Limited',
    name: 'Xinming China Holdings Limited',
    symbol: '2699.HK',
  },
  {
    value: '2700.HK',
    label: '2700.HK,  Green International Holdings Limited',
    name: 'Green International Holdings Limited',
    symbol: '2700.HK',
  },
  {
    value: '2708.HK',
    label: '2708.HK,  IBO Technology Company Limited',
    name: 'IBO Technology Company Limited',
    symbol: '2708.HK',
  },
  {
    value: '2718.HK',
    label: '2718.HK,  Shanghai Dongzheng Automotive Finance Co., Ltd.',
    name: 'Shanghai Dongzheng Automotive Finance Co., Ltd.',
    symbol: '2718.HK',
  },
  {
    value: '2722.HK',
    label: '2722.HK,  Chongqing Machinery & Electric Co., Ltd.',
    name: 'Chongqing Machinery & Electric Co., Ltd.',
    symbol: '2722.HK',
  },
  {
    value: '2728.HK',
    label: '2728.HK,  Jintai Energy Holdings Limited',
    name: 'Jintai Energy Holdings Limited',
    symbol: '2728.HK',
  },
  {
    value: '2738.HK',
    label: '2738.HK,  Huajin International Holdings Limited',
    name: 'Huajin International Holdings Limited',
    symbol: '2738.HK',
  },
  {
    value: '2768.HK',
    label: '2768.HK,  Jiayuan International Group Limited',
    name: 'Jiayuan International Group Limited',
    symbol: '2768.HK',
  },
  {
    value: '2772.HK',
    label: '2772.HK,  Zhongliang Holdings Group Company Limited',
    name: 'Zhongliang Holdings Group Company Limited',
    symbol: '2772.HK',
  },
  {
    value: '2777.HK',
    label: '2777.HK,  Guangzhou R&F Properties Co., Ltd.',
    name: 'Guangzhou R&F Properties Co., Ltd.',
    symbol: '2777.HK',
  },
  {
    value: '2778.HK',
    label: '2778.HK,  Champion Real Estate Investment Trust',
    name: 'Champion Real Estate Investment Trust',
    symbol: '2778.HK',
  },
  {
    value: '2779.HK',
    label: '2779.HK,  China Xinhua Education Group Limited',
    name: 'China Xinhua Education Group Limited',
    symbol: '2779.HK',
  },
  {
    value: '2789.HK',
    label: '2789.HK,  Yuanda China Holdings Limited',
    name: 'Yuanda China Holdings Limited',
    symbol: '2789.HK',
  },
  {
    value: '2798.HK',
    label: '2798.HK,  Perennial Energy Holdings Limited',
    name: 'Perennial Energy Holdings Limited',
    symbol: '2798.HK',
  },
  {
    value: '2799.HK',
    label: '2799.HK,  China Huarong Asset Management Co., Ltd.',
    name: 'China Huarong Asset Management Co., Ltd.',
    symbol: '2799.HK',
  },
  {
    value: '2858.HK',
    label: '2858.HK,  Yixin Group Limited',
    name: 'Yixin Group Limited',
    symbol: '2858.HK',
  },
  {
    value: '2863.HK',
    label: '2863.HK,  Golden Faith Group Holdings Limited',
    name: 'Golden Faith Group Holdings Limited',
    symbol: '2863.HK',
  },
  {
    value: '2869.HK',
    label: '2869.HK,  Greentown Service Group Co. Ltd.',
    name: 'Greentown Service Group Co. Ltd.',
    symbol: '2869.HK',
  },
  {
    value: '2877.HK',
    label: '2877.HK,  China Shineway Pharmaceutical Group Limited',
    name: 'China Shineway Pharmaceutical Group Limited',
    symbol: '2877.HK',
  },
  {
    value: '2878.HK',
    label: '2878.HK,  Solomon Systech (International) Limited',
    name: 'Solomon Systech (International) Limited',
    symbol: '2878.HK',
  },
  {
    value: '2882.HK',
    label: '2882.HK,  Hong Kong Resources Holdings Company Limited',
    name: 'Hong Kong Resources Holdings Company Limited',
    symbol: '2882.HK',
  },
  {
    value: '2885.HK',
    label: '2885.HK,  Peiport Holdings Ltd.',
    name: 'Peiport Holdings Ltd.',
    symbol: '2885.HK',
  },
  {
    value: '2886.HK',
    label: '2886.HK,  Binhai Investment Company Limited',
    name: 'Binhai Investment Company Limited',
    symbol: '2886.HK',
  },
  {
    value: '2888.HK',
    label: '2888.HK,  Standard Chartered PLC',
    name: 'Standard Chartered PLC',
    symbol: '2888.HK',
  },
  {
    value: '3300.HK',
    label: '3300.HK,  China Glass Holdings Limited',
    name: 'China Glass Holdings Limited',
    symbol: '3300.HK',
  },
  {
    value: '3301.HK',
    label: '3301.HK,  Ronshine China Holdings Limited',
    name: 'Ronshine China Holdings Limited',
    symbol: '3301.HK',
  },
  {
    value: '3302.HK',
    label: '3302.HK,  Kinergy Corporation Ltd.',
    name: 'Kinergy Corporation Ltd.',
    symbol: '3302.HK',
  },
  {
    value: '3303.HK',
    label: '3303.HK,  Jutal Offshore Oil Services Limited',
    name: 'Jutal Offshore Oil Services Limited',
    symbol: '3303.HK',
  },
  {
    value: '3306.HK',
    label: '3306.HK,  JNBY Design Limited',
    name: 'JNBY Design Limited',
    symbol: '3306.HK',
  },
  {
    value: '3308.HK',
    label: '3308.HK,  Golden Eagle Retail Group Limited',
    name: 'Golden Eagle Retail Group Limited',
    symbol: '3308.HK',
  },
  {
    value: '3309.HK',
    label: '3309.HK,  C-MER Eye Care Holdings Limited',
    name: 'C-MER Eye Care Holdings Limited',
    symbol: '3309.HK',
  },
  {
    value: '3311.HK',
    label: '3311.HK,  China State Construction International Holdings Limited',
    name: 'China State Construction International Holdings Limited',
    symbol: '3311.HK',
  },
  {
    value: '3313.HK',
    label: '3313.HK,  ArtGo Holdings Limited',
    name: 'ArtGo Holdings Limited',
    symbol: '3313.HK',
  },
  {
    value: '3315.HK',
    label: '3315.HK,  Goldpac Group Limited',
    name: 'Goldpac Group Limited',
    symbol: '3315.HK',
  },
  {
    value: '3316.HK',
    label: '3316.HK,  Binjiang Service Group Co. Ltd.',
    name: 'Binjiang Service Group Co. Ltd.',
    symbol: '3316.HK',
  },
  {
    value: '3318.HK',
    label: '3318.HK,  China Boton Group Company Limited',
    name: 'China Boton Group Company Limited',
    symbol: '3318.HK',
  },
  {
    value: '3319.HK',
    label: '3319.HK,  A-Living Smart City Services Co., Ltd.',
    name: 'A-Living Smart City Services Co., Ltd.',
    symbol: '3319.HK',
  },
  {
    value: '3320.HK',
    label: '3320.HK,  China Resources Pharmaceutical Group Limited',
    name: 'China Resources Pharmaceutical Group Limited',
    symbol: '3320.HK',
  },
  {
    value: '3322.HK',
    label: '3322.HK,  Win Hanverky Holdings Limited',
    name: 'Win Hanverky Holdings Limited',
    symbol: '3322.HK',
  },
  {
    value: '3323.HK',
    label: '3323.HK,  China National Building Material Company Limited',
    name: 'China National Building Material Company Limited',
    symbol: '3323.HK',
  },
  {
    value: '3326.HK',
    label: '3326.HK,  Perfect Group International Holdings Limited',
    name: 'Perfect Group International Holdings Limited',
    symbol: '3326.HK',
  },
  {
    value: '3329.HK',
    label: '3329.HK,  BOCOM International Holdings Company Limited',
    name: 'BOCOM International Holdings Company Limited',
    symbol: '3329.HK',
  },
  {
    value: '3330.HK',
    label: '3330.HK,  Lingbao Gold Group Company Ltd.',
    name: 'Lingbao Gold Group Company Ltd.',
    symbol: '3330.HK',
  },
  {
    value: '3331.HK',
    label: '3331.HK,  Vinda International Holdings Limited',
    name: 'Vinda International Holdings Limited',
    symbol: '3331.HK',
  },
  {
    value: '3332.HK',
    label: '3332.HK,  Nanjing Sinolife United Company Limited',
    name: 'Nanjing Sinolife United Company Limited',
    symbol: '3332.HK',
  },
  {
    value: '3336.HK',
    label: '3336.HK,  Ju Teng International Holdings Limited',
    name: 'Ju Teng International Holdings Limited',
    symbol: '3336.HK',
  },
  {
    value: '3337.HK',
    label: '3337.HK,  Anton Oilfield Services Group',
    name: 'Anton Oilfield Services Group',
    symbol: '3337.HK',
  },
  {
    value: '3339.HK',
    label: '3339.HK,  Lonking Holdings Limited',
    name: 'Lonking Holdings Limited',
    symbol: '3339.HK',
  },
  {
    value: '3348.HK',
    label: '3348.HK,  China PengFei Group Limited',
    name: 'China PengFei Group Limited',
    symbol: '3348.HK',
  },
  {
    value: '3360.HK',
    label: '3360.HK,  Far East Horizon Limited',
    name: 'Far East Horizon Limited',
    symbol: '3360.HK',
  },
  {
    value: '3363.HK',
    label: '3363.HK,  Zhengye International Holdings Company Limited',
    name: 'Zhengye International Holdings Company Limited',
    symbol: '3363.HK',
  },
  {
    value: '3366.HK',
    label: '3366.HK,  Overseas Chinese Town (Asia) Holdings Limited',
    name: 'Overseas Chinese Town (Asia) Holdings Limited',
    symbol: '3366.HK',
  },
  {
    value: '3368.HK',
    label: '3368.HK,  Parkson Retail Group Limited',
    name: 'Parkson Retail Group Limited',
    symbol: '3368.HK',
  },
  {
    value: '3377.HK',
    label: '3377.HK,  Sino-Ocean Group Holding Limited',
    name: 'Sino-Ocean Group Holding Limited',
    symbol: '3377.HK',
  },
  {
    value: '3380.HK',
    label: '3380.HK,  Logan Group Company Limited',
    name: 'Logan Group Company Limited',
    symbol: '3380.HK',
  },
  {
    value: '3382.HK',
    label: '3382.HK,  Tianjin Port Development Holdings Limited',
    name: 'Tianjin Port Development Holdings Limited',
    symbol: '3382.HK',
  },
  {
    value: '3383.HK',
    label: '3383.HK,  Agile Group Holdings Limited',
    name: 'Agile Group Holdings Limited',
    symbol: '3383.HK',
  },
  {
    value: '3389.HK',
    label: '3389.HK,  Hengdeli Holdings Limited',
    name: 'Hengdeli Holdings Limited',
    symbol: '3389.HK',
  },
  {
    value: '3390.HK',
    label: '3390.HK,  Tycoon Group Holdings Limited',
    name: 'Tycoon Group Holdings Limited',
    symbol: '3390.HK',
  },
  {
    value: '3393.HK',
    label: '3393.HK,  Wasion Holdings Limited',
    name: 'Wasion Holdings Limited',
    symbol: '3393.HK',
  },
  {
    value: '3395.HK',
    label: '3395.HK,  JX Energy Ltd.',
    name: 'JX Energy Ltd.',
    symbol: '3395.HK',
  },
  {
    value: '3396.HK',
    label: '3396.HK,  Legend Holdings Corporation',
    name: 'Legend Holdings Corporation',
    symbol: '3396.HK',
  },
  {
    value: '3398.HK',
    label: '3398.HK,  China Ting Group Holdings Limited',
    name: 'China Ting Group Holdings Limited',
    symbol: '3398.HK',
  },
  {
    value: '3399.HK',
    label: '3399.HK,  Guangdong Yueyun Transportation Company Limited',
    name: 'Guangdong Yueyun Transportation Company Limited',
    symbol: '3399.HK',
  },
  {
    value: '3600.HK',
    label: '3600.HK,  Modern Dental Group Limited',
    name: 'Modern Dental Group Limited',
    symbol: '3600.HK',
  },
  {
    value: '3601.HK',
    label: '3601.HK,  360 Ludashi Holdings Limited',
    name: '360 Ludashi Holdings Limited',
    symbol: '3601.HK',
  },
  {
    value: '3603.HK',
    label: '3603.HK,  Xinji Shaxi Group Co., Ltd',
    name: 'Xinji Shaxi Group Co., Ltd',
    symbol: '3603.HK',
  },
  {
    value: '3608.HK',
    label: '3608.HK,  Yongsheng Advanced Materials Company Limited',
    name: 'Yongsheng Advanced Materials Company Limited',
    symbol: '3608.HK',
  },
  {
    value: '3613.HK',
    label: '3613.HK,  Beijing Tong Ren Tang Chinese Medicine Company Limited',
    name: 'Beijing Tong Ren Tang Chinese Medicine Company Limited',
    symbol: '3613.HK',
  },
  {
    value: '3616.HK',
    label: '3616.HK,  Ever Reach Group (Holdings) Company Limited',
    name: 'Ever Reach Group (Holdings) Company Limited',
    symbol: '3616.HK',
  },
  {
    value: '3623.HK',
    label: '3623.HK,  China Success Finance Group Holdings Limited',
    name: 'China Success Finance Group Holdings Limited',
    symbol: '3623.HK',
  },
  {
    value: '3626.HK',
    label: '3626.HK,  Hang Sang (Siu Po) International Holding Company Limited',
    name: 'Hang Sang (Siu Po) International Holding Company Limited',
    symbol: '3626.HK',
  },
  {
    value: '3628.HK',
    label: '3628.HK,  RENHENG Enterprise Holdings Limited',
    name: 'RENHENG Enterprise Holdings Limited',
    symbol: '3628.HK',
  },
  {
    value: '3633.HK',
    label: '3633.HK,  Zhongyu Energy Holdings Limited',
    name: 'Zhongyu Energy Holdings Limited',
    symbol: '3633.HK',
  },
  {
    value: '3636.HK',
    label: '3636.HK,  Poly Culture Group Corporation Limited',
    name: 'Poly Culture Group Corporation Limited',
    symbol: '3636.HK',
  },
  {
    value: '3638.HK',
    label: '3638.HK,  Huabang Technology Holdings Limited',
    name: 'Huabang Technology Holdings Limited',
    symbol: '3638.HK',
  },
  {
    value: '3666.HK',
    label: '3666.HK,  Shanghai XNG Holdings Limited',
    name: 'Shanghai XNG Holdings Limited',
    symbol: '3666.HK',
  },
  {
    value: '3668.HK',
    label: '3668.HK,  Yancoal Australia Ltd',
    name: 'Yancoal Australia Ltd',
    symbol: '3668.HK',
  },
  {
    value: '3669.HK',
    label: '3669.HK,  China Yongda Automobiles Services Holdings Limited',
    name: 'China Yongda Automobiles Services Holdings Limited',
    symbol: '3669.HK',
  },
  {
    value: '3678.HK',
    label: '3678.HK,  Holly Futures Co., Ltd.',
    name: 'Holly Futures Co., Ltd.',
    symbol: '3678.HK',
  },
  {
    value: '3680.HK',
    label: '3680.HK,  Suoxinda Holdings Limited',
    name: 'Suoxinda Holdings Limited',
    symbol: '3680.HK',
  },
  {
    value: '3681.HK',
    label: '3681.HK,  SinoMab BioScience Limited',
    name: 'SinoMab BioScience Limited',
    symbol: '3681.HK',
  },
  {
    value: '3683.HK',
    label: '3683.HK,  Great Harvest Maeta Holdings Limited',
    name: 'Great Harvest Maeta Holdings Limited',
    symbol: '3683.HK',
  },
  {
    value: '3686.HK',
    label: '3686.HK,  Clifford Modern Living Holdings Limited',
    name: 'Clifford Modern Living Holdings Limited',
    symbol: '3686.HK',
  },
  {
    value: '3688.HK',
    label: '3688.HK,  Top Spring International Holdings Limited',
    name: 'Top Spring International Holdings Limited',
    symbol: '3688.HK',
  },
  {
    value: '3689.HK',
    label: '3689.HK,  Guangdong Kanghua Healthcare Co., Ltd.',
    name: 'Guangdong Kanghua Healthcare Co., Ltd.',
    symbol: '3689.HK',
  },
  {
    value: '3690.HK',
    label: '3690.HK,  Meituan',
    name: 'Meituan',
    symbol: '3690.HK',
  },
  {
    value: '3692.HK',
    label: '3692.HK,  Hansoh Pharmaceutical Group Company Limited',
    name: 'Hansoh Pharmaceutical Group Company Limited',
    symbol: '3692.HK',
  },
  {
    value: '3698.HK',
    label: '3698.HK,  Huishang Bank Corporation Limited',
    name: 'Huishang Bank Corporation Limited',
    symbol: '3698.HK',
  },
  {
    value: '3699.HK',
    label: '3699.HK,  Everbright Grand China Assets Limited',
    name: 'Everbright Grand China Assets Limited',
    symbol: '3699.HK',
  },
  {
    value: '3700.HK',
    label: '3700.HK,  Inkeverse Group Limited',
    name: 'Inkeverse Group Limited',
    symbol: '3700.HK',
  },
  {
    value: '3709.HK',
    label: '3709.HK,  EEKA Fashion Holdings Limited',
    name: 'EEKA Fashion Holdings Limited',
    symbol: '3709.HK',
  },
  {
    value: '3718.HK',
    label: '3718.HK,  Beijing Enterprises Urban Resources Group Limited',
    name: 'Beijing Enterprises Urban Resources Group Limited',
    symbol: '3718.HK',
  },
  {
    value: '3728.HK',
    label: '3728.HK,  Ching Lee Holdings Limited',
    name: 'Ching Lee Holdings Limited',
    symbol: '3728.HK',
  },
  {
    value: '3737.HK',
    label: '3737.HK,  Zhongzhi Pharmaceutical Holdings Limited',
    name: 'Zhongzhi Pharmaceutical Holdings Limited',
    symbol: '3737.HK',
  },
  {
    value: '3738.HK',
    label: '3738.HK,  Vobile Group Limited',
    name: 'Vobile Group Limited',
    symbol: '3738.HK',
  },
  {
    value: '3768.HK',
    label: '3768.HK,  Kunming Dianchi Water Treatment Co., Ltd.',
    name: 'Kunming Dianchi Water Treatment Co., Ltd.',
    symbol: '3768.HK',
  },
  {
    value: '3773.HK',
    label: '3773.HK,  NNK Group Limited',
    name: 'NNK Group Limited',
    symbol: '3773.HK',
  },
  {
    value: '3778.HK',
    label: '3778.HK,  China Weaving Materials Holdings Limited',
    name: 'China Weaving Materials Holdings Limited',
    symbol: '3778.HK',
  },
  {
    value: '3788.HK',
    label: '3788.HK,  China Hanking Holdings Limited',
    name: 'China Hanking Holdings Limited',
    symbol: '3788.HK',
  },
  {
    value: '3789.HK',
    label: '3789.HK,  Royal Deluxe Holdings Limited',
    name: 'Royal Deluxe Holdings Limited',
    symbol: '3789.HK',
  },
  {
    value: '3798.HK',
    label: '3798.HK,  Homeland Interactive Technology Ltd.',
    name: 'Homeland Interactive Technology Ltd.',
    symbol: '3798.HK',
  },
  {
    value: '3799.HK',
    label: '3799.HK,  Dali Foods Group Company Limited',
    name: 'Dali Foods Group Company Limited',
    symbol: '3799.HK',
  },
  {
    value: '3800.HK',
    label: '3800.HK,  GCL Technology Holdings Limited',
    name: 'GCL Technology Holdings Limited',
    symbol: '3800.HK',
  },
  {
    value: '3808.HK',
    label: '3808.HK,  Sinotruk (Hong Kong) Limited',
    name: 'Sinotruk (Hong Kong) Limited',
    symbol: '3808.HK',
  },
  {
    value: '3813.HK',
    label: '3813.HK,  Pou Sheng International (Holdings) Limited',
    name: 'Pou Sheng International (Holdings) Limited',
    symbol: '3813.HK',
  },
  {
    value: '3818.HK',
    label: '3818.HK,  China Dongxiang (Group) Co., Ltd.',
    name: 'China Dongxiang (Group) Co., Ltd.',
    symbol: '3818.HK',
  },
  {
    value: '3822.HK',
    label: '3822.HK,  Sam Woo Construction Group Limited',
    name: 'Sam Woo Construction Group Limited',
    symbol: '3822.HK',
  },
  {
    value: '3828.HK',
    label: '3828.HK,  Ming Fai International Holdings Limited',
    name: 'Ming Fai International Holdings Limited',
    symbol: '3828.HK',
  },
  {
    value: '3830.HK',
    label: '3830.HK,  Kiddieland International Limited',
    name: 'Kiddieland International Limited',
    symbol: '3830.HK',
  },
  {
    value: '3833.HK',
    label: '3833.HK,  Xinjiang Xinxin Mining Industry Co., Ltd.',
    name: 'Xinjiang Xinxin Mining Industry Co., Ltd.',
    symbol: '3833.HK',
  },
  {
    value: '3836.HK',
    label: '3836.HK,  China Harmony Auto Holding Limited',
    name: 'China Harmony Auto Holding Limited',
    symbol: '3836.HK',
  },
  {
    value: '3838.HK',
    label: '3838.HK,  China Starch Holdings Limited',
    name: 'China Starch Holdings Limited',
    symbol: '3838.HK',
  },
  {
    value: '3839.HK',
    label: '3839.HK,  Chia Tai Enterprises International Limited',
    name: 'Chia Tai Enterprises International Limited',
    symbol: '3839.HK',
  },
  {
    value: '3848.HK',
    label: '3848.HK,  Wealthy Way Group Limited',
    name: 'Wealthy Way Group Limited',
    symbol: '3848.HK',
  },
  {
    value: '3860.HK',
    label: '3860.HK,  EPS Creative Health Technology Group Limited',
    name: 'EPS Creative Health Technology Group Limited',
    symbol: '3860.HK',
  },
  {
    value: '3868.HK',
    label: '3868.HK,  Xinyi Energy Holdings Limited',
    name: 'Xinyi Energy Holdings Limited',
    symbol: '3868.HK',
  },
  {
    value: '3877.HK',
    label: '3877.HK,  CSSC (Hong Kong) Shipping Company Limited',
    name: 'CSSC (Hong Kong) Shipping Company Limited',
    symbol: '3877.HK',
  },
  {
    value: '3878.HK',
    label: '3878.HK,  Vicon Holdings Limited',
    name: 'Vicon Holdings Limited',
    symbol: '3878.HK',
  },
  {
    value: '3882.HK',
    label: '3882.HK,  Sky Light Holdings Limited',
    name: 'Sky Light Holdings Limited',
    symbol: '3882.HK',
  },
  {
    value: '3888.HK',
    label: '3888.HK,  Kingsoft Corporation Limited',
    name: 'Kingsoft Corporation Limited',
    symbol: '3888.HK',
  },
  {
    value: '3889.HK',
    label: '3889.HK,  Global Sweeteners Holdings Limited',
    name: 'Global Sweeteners Holdings Limited',
    symbol: '3889.HK',
  },
  {
    value: '3893.HK',
    label: '3893.HK,  Crosstec Group Holdings Limited',
    name: 'Crosstec Group Holdings Limited',
    symbol: '3893.HK',
  },
  {
    value: '3898.HK',
    label: '3898.HK,  Zhuzhou CRRC Times Electric Co., Ltd.',
    name: 'Zhuzhou CRRC Times Electric Co., Ltd.',
    symbol: '3898.HK',
  },
  {
    value: '3899.HK',
    label: '3899.HK,  CIMC Enric Holdings Limited',
    name: 'CIMC Enric Holdings Limited',
    symbol: '3899.HK',
  },
  {
    value: '3900.HK',
    label: '3900.HK,  Greentown China Holdings Limited',
    name: 'Greentown China Holdings Limited',
    symbol: '3900.HK',
  },
  {
    value: '3903.HK',
    label: '3903.HK,  Hanhua Financial Holding Co., Ltd.',
    name: 'Hanhua Financial Holding Co., Ltd.',
    symbol: '3903.HK',
  },
  {
    value: '3908.HK',
    label: '3908.HK,  China International Capital Corporation Limited',
    name: 'China International Capital Corporation Limited',
    symbol: '3908.HK',
  },
  {
    value: '3918.HK',
    label: '3918.HK,  NagaCorp Ltd.',
    name: 'NagaCorp Ltd.',
    symbol: '3918.HK',
  },
  {
    value: '3919.HK',
    label: '3919.HK,  Golden Power Group Holdings Limited',
    name: 'Golden Power Group Holdings Limited',
    symbol: '3919.HK',
  },
  {
    value: '3933.HK',
    label: '3933.HK,  The United Laboratories International Holdings Limited',
    name: 'The United Laboratories International Holdings Limited',
    symbol: '3933.HK',
  },
  {
    value: '3938.HK',
    label: '3938.HK,  LFG Investment Holdings Limited',
    name: 'LFG Investment Holdings Limited',
    symbol: '3938.HK',
  },
  {
    value: '3939.HK',
    label: '3939.HK,  Wanguo International Mining Group Limited',
    name: 'Wanguo International Mining Group Limited',
    symbol: '3939.HK',
  },
  {
    value: '3978.HK',
    label: '3978.HK,  China Beststudy Education Group',
    name: 'China Beststudy Education Group',
    symbol: '3978.HK',
  },
  {
    value: '3983.HK',
    label: '3983.HK,  China BlueChemical Ltd.',
    name: 'China BlueChemical Ltd.',
    symbol: '3983.HK',
  },
  {
    value: '3989.HK',
    label: '3989.HK,  Capital Environment Holdings Limited',
    name: 'Capital Environment Holdings Limited',
    symbol: '3989.HK',
  },
  {
    value: '3990.HK',
    label: '3990.HK,  Midea Real Estate Holding Limited',
    name: 'Midea Real Estate Holding Limited',
    symbol: '3990.HK',
  },
  {
    value: '3991.HK',
    label: '3991.HK,  Changhong Jiahua Holdings Limited',
    name: 'Changhong Jiahua Holdings Limited',
    symbol: '3991.HK',
  },
  {
    value: '3996.HK',
    label: '3996.HK,  China Energy Engineering Corporation Limited',
    name: 'China Energy Engineering Corporation Limited',
    symbol: '3996.HK',
  },
  {
    value: '3997.HK',
    label: '3997.HK,  Telecom Service One Holdings Limited',
    name: 'Telecom Service One Holdings Limited',
    symbol: '3997.HK',
  },
  {
    value: '3998.HK',
    label: '3998.HK,  Bosideng International Holdings Limited',
    name: 'Bosideng International Holdings Limited',
    symbol: '3998.HK',
  },
  {
    value: '3999.HK',
    label: '3999.HK,  DaChan Food (Asia) Limited',
    name: 'DaChan Food (Asia) Limited',
    symbol: '3999.HK',
  },
  {
    value: '6033.HK',
    label: '6033.HK,  Telecom Digital Holdings Limited',
    name: 'Telecom Digital Holdings Limited',
    symbol: '6033.HK',
  },
  {
    value: '6036.HK',
    label: '6036.HK,  Apex Ace Holding Limited',
    name: 'Apex Ace Holding Limited',
    symbol: '6036.HK',
  },
  {
    value: '6038.HK',
    label: '6038.HK,  G & M Holdings Limited',
    name: 'G & M Holdings Limited',
    symbol: '6038.HK',
  },
  {
    value: '6049.HK',
    label: '6049.HK,  Poly Property Services Co., Ltd.',
    name: 'Poly Property Services Co., Ltd.',
    symbol: '6049.HK',
  },
  {
    value: '6055.HK',
    label: '6055.HK,  China Tobacco International (HK) Company Limited',
    name: 'China Tobacco International (HK) Company Limited',
    symbol: '6055.HK',
  },
  {
    value: '6058.HK',
    label:
      '6058.HK,  China Industrial Securities International Financial Group Limited',
    name: 'China Industrial Securities International Financial Group Limited',
    symbol: '6058.HK',
  },
  {
    value: '6060.HK',
    label: '6060.HK,  ZhongAn Online P & C Insurance Co., Ltd.',
    name: 'ZhongAn Online P & C Insurance Co., Ltd.',
    symbol: '6060.HK',
  },
  {
    value: '6063.HK',
    label: '6063.HK,  Lotus Horizon Holdings Limited',
    name: 'Lotus Horizon Holdings Limited',
    symbol: '6063.HK',
  },
  {
    value: '6068.HK',
    label: '6068.HK,  Wisdom Education International Holdings Company Limited',
    name: 'Wisdom Education International Holdings Company Limited',
    symbol: '6068.HK',
  },
  {
    value: '6069.HK',
    label: '6069.HK,  SY Holdings Group Limited',
    name: 'SY Holdings Group Limited',
    symbol: '6069.HK',
  },
  {
    value: '6078.HK',
    label: '6078.HK,  Hygeia Healthcare Holdings Co., Limited',
    name: 'Hygeia Healthcare Holdings Co., Limited',
    symbol: '6078.HK',
  },
  {
    value: '6080.HK',
    label: '6080.HK,  Wing Chi Holdings Limited',
    name: 'Wing Chi Holdings Limited',
    symbol: '6080.HK',
  },
  {
    value: '6083.HK',
    label: '6083.HK,  World-Link Logistics (Asia) Holding Limited',
    name: 'World-Link Logistics (Asia) Holding Limited',
    symbol: '6083.HK',
  },
  {
    value: '6088.HK',
    label: '6088.HK,  FIT Hon Teng Limited',
    name: 'FIT Hon Teng Limited',
    symbol: '6088.HK',
  },
  {
    value: '6090.HK',
    label: '6090.HK,  Centurion Corporation Limited',
    name: 'Centurion Corporation Limited',
    symbol: '6090.HK',
  },
  {
    value: '6093.HK',
    label: '6093.HK,  Hevol Services Group Co. Limited',
    name: 'Hevol Services Group Co. Limited',
    symbol: '6093.HK',
  },
  {
    value: '6098.HK',
    label: '6098.HK,  Country Garden Services Holdings Company Limited',
    name: 'Country Garden Services Holdings Company Limited',
    symbol: '6098.HK',
  },
  {
    value: '6100.HK',
    label: '6100.HK,  Tongdao Liepin Group',
    name: 'Tongdao Liepin Group',
    symbol: '6100.HK',
  },
  {
    value: '6110.HK',
    label: '6110.HK,  Topsports International Holdings Limited',
    name: 'Topsports International Holdings Limited',
    symbol: '6110.HK',
  },
  {
    value: '6111.HK',
    label: '6111.HK,  DaFa Properties Group Limited',
    name: 'DaFa Properties Group Limited',
    symbol: '6111.HK',
  },
  {
    value: '6113.HK',
    label: '6113.HK,  UTS Marketing Solutions Holdings Limited',
    name: 'UTS Marketing Solutions Holdings Limited',
    symbol: '6113.HK',
  },
  {
    value: '6117.HK',
    label: '6117.HK,  Rizhao Port Jurong Co., Ltd.',
    name: 'Rizhao Port Jurong Co., Ltd.',
    symbol: '6117.HK',
  },
  {
    value: '6118.HK',
    label: '6118.HK,  Austar Lifesciences Limited',
    name: 'Austar Lifesciences Limited',
    symbol: '6118.HK',
  },
  {
    value: '6119.HK',
    label: '6119.HK,  Tian Yuan Group Holdings Limited',
    name: 'Tian Yuan Group Holdings Limited',
    symbol: '6119.HK',
  },
  {
    value: '6122.HK',
    label: '6122.HK,  Jilin Jiutai Rural Commercial Bank Corporation Limited',
    name: 'Jilin Jiutai Rural Commercial Bank Corporation Limited',
    symbol: '6122.HK',
  },
  {
    value: '6123.HK',
    label: '6123.HK,  YTO Express (International) Holdings Limited',
    name: 'YTO Express (International) Holdings Limited',
    symbol: '6123.HK',
  },
  {
    value: '6128.HK',
    label: '6128.HK,  Graphex Group Limited',
    name: 'Graphex Group Limited',
    symbol: '6128.HK',
  },
  {
    value: '6133.HK',
    label: '6133.HK,  Vital Innovations Holdings Limited',
    name: 'Vital Innovations Holdings Limited',
    symbol: '6133.HK',
  },
  {
    value: '6136.HK',
    label: '6136.HK,  Kangda International Environmental Company Limited',
    name: 'Kangda International Environmental Company Limited',
    symbol: '6136.HK',
  },
  {
    value: '6138.HK',
    label: '6138.HK,  Harbin Bank Co., Ltd.',
    name: 'Harbin Bank Co., Ltd.',
    symbol: '6138.HK',
  },
  {
    value: '6158.HK',
    label: '6158.HK,  Zhenro Properties Group Limited',
    name: 'Zhenro Properties Group Limited',
    symbol: '6158.HK',
  },
  {
    value: '6160.HK',
    label: '6160.HK,  BeiGene, Ltd.',
    name: 'BeiGene, Ltd.',
    symbol: '6160.HK',
  },
  {
    value: '6162.HK',
    label: '6162.HK,  China Tianrui Automotive Interiors Co., LTD',
    name: 'China Tianrui Automotive Interiors Co., LTD',
    symbol: '6162.HK',
  },
  {
    value: '6163.HK',
    label: '6163.HK,  Gemilang International Limited',
    name: 'Gemilang International Limited',
    symbol: '6163.HK',
  },
  {
    value: '6166.HK',
    label: '6166.HK,  China VAST Industrial Urban Development Company Limited',
    name: 'China VAST Industrial Urban Development Company Limited',
    symbol: '6166.HK',
  },
  {
    value: '6169.HK',
    label: '6169.HK,  China YuHua Education Corporation Limited',
    name: 'China YuHua Education Corporation Limited',
    symbol: '6169.HK',
  },
  {
    value: '6182.HK',
    label: '6182.HK,  Twintek Investment Holdings Limited',
    name: 'Twintek Investment Holdings Limited',
    symbol: '6182.HK',
  },
  {
    value: '6185.HK',
    label: '6185.HK,  CanSino Biologics Inc.',
    name: 'CanSino Biologics Inc.',
    symbol: '6185.HK',
  },
  {
    value: '6186.HK',
    label: '6186.HK,  China Feihe Limited',
    name: 'China Feihe Limited',
    symbol: '6186.HK',
  },
  {
    value: '6193.HK',
    label: '6193.HK,  Tailam Tech Construction Holdings Limited',
    name: 'Tailam Tech Construction Holdings Limited',
    symbol: '6193.HK',
  },
  {
    value: '6199.HK',
    label: '6199.HK,  Bank of Guizhou Co., Ltd.',
    name: 'Bank of Guizhou Co., Ltd.',
    symbol: '6199.HK',
  },
  {
    value: '6805.HK',
    label: '6805.HK,  Kimou Environmental Holding Limited',
    name: 'Kimou Environmental Holding Limited',
    symbol: '6805.HK',
  },
  {
    value: '6808.HK',
    label: '6808.HK,  Sun Art Retail Group Limited',
    name: 'Sun Art Retail Group Limited',
    symbol: '6808.HK',
  },
  {
    value: '6811.HK',
    label: '6811.HK,  Tai Hing Group Holdings Limited',
    name: 'Tai Hing Group Holdings Limited',
    symbol: '6811.HK',
  },
  {
    value: '6812.HK',
    label: '6812.HK,  Winson Holdings Hong Kong Limited',
    name: 'Winson Holdings Hong Kong Limited',
    symbol: '6812.HK',
  },
  {
    value: '6816.HK',
    label: '6816.HK,  Prosper Construction Holdings Limited',
    name: 'Prosper Construction Holdings Limited',
    symbol: '6816.HK',
  },
  {
    value: '6819.HK',
    label: '6819.HK,  IntelliCentrics Global Holdings Ltd.',
    name: 'IntelliCentrics Global Holdings Ltd.',
    symbol: '6819.HK',
  },
  {
    value: '6820.HK',
    label: '6820.HK,  FriendTimes Inc.',
    name: 'FriendTimes Inc.',
    symbol: '6820.HK',
  },
  {
    value: '6822.HK',
    label: "6822.HK,  King's Flair International (Holdings) Limited",
    name: "King's Flair International (Holdings) Limited",
    symbol: '6822.HK',
  },
  {
    value: '6823.HK',
    label: '6823.HK,  HKT Trust and HKT Limited',
    name: 'HKT Trust and HKT Limited',
    symbol: '6823.HK',
  },
  {
    value: '6829.HK',
    label: '6829.HK,  Dragon Rise Group Holdings Limited',
    name: 'Dragon Rise Group Holdings Limited',
    symbol: '6829.HK',
  },
  {
    value: '6830.HK',
    label: '6830.HK,  Huazhong In-Vehicle Holdings Company Limited',
    name: 'Huazhong In-Vehicle Holdings Company Limited',
    symbol: '6830.HK',
  },
  {
    value: '6833.HK',
    label: '6833.HK,  Sinco Pharmaceuticals Holdings Limited',
    name: 'Sinco Pharmaceuticals Holdings Limited',
    symbol: '6833.HK',
  },
  {
    value: '6838.HK',
    label: '6838.HK,  Winox Holdings Limited',
    name: 'Winox Holdings Limited',
    symbol: '6838.HK',
  },
  {
    value: '6839.HK',
    label: '6839.HK,  Yunnan Water Investment Co., Limited',
    name: 'Yunnan Water Investment Co., Limited',
    symbol: '6839.HK',
  },
  {
    value: '6855.HK',
    label: '6855.HK,  Ascentage Pharma Group International',
    name: 'Ascentage Pharma Group International',
    symbol: '6855.HK',
  },
  {
    value: '6858.HK',
    label: '6858.HK,  Honma Golf Limited',
    name: 'Honma Golf Limited',
    symbol: '6858.HK',
  },
  {
    value: '6860.HK',
    label: '6860.HK,  FingerTango Inc.',
    name: 'FingerTango Inc.',
    symbol: '6860.HK',
  },
  {
    value: '6862.HK',
    label: '6862.HK,  Haidilao International Holding Ltd.',
    name: 'Haidilao International Holding Ltd.',
    symbol: '6862.HK',
  },
  {
    value: '6866.HK',
    label: '6866.HK,  Zuoli Kechuang Micro-finance Company Limited',
    name: 'Zuoli Kechuang Micro-finance Company Limited',
    symbol: '6866.HK',
  },
  {
    value: '6868.HK',
    label: '6868.HK,  Tenfu (Cayman) Holdings Company Limited',
    name: 'Tenfu (Cayman) Holdings Company Limited',
    symbol: '6868.HK',
  },
  {
    value: '6877.HK',
    label: '6877.HK,  CLSA Premium Limited',
    name: 'CLSA Premium Limited',
    symbol: '6877.HK',
  },
  {
    value: '6878.HK',
    label: '6878.HK,  Differ Group Auto Limited',
    name: 'Differ Group Auto Limited',
    symbol: '6878.HK',
  },
  {
    value: '6880.HK',
    label: '6880.HK,  Tempus Holdings Limited',
    name: 'Tempus Holdings Limited',
    symbol: '6880.HK',
  },
  {
    value: '6882.HK',
    label: '6882.HK,  EGL Holdings Company Limited',
    name: 'EGL Holdings Company Limited',
    symbol: '6882.HK',
  },
  {
    value: '6885.HK',
    label: '6885.HK,  Henan Jinma Energy Company Limited',
    name: 'Henan Jinma Energy Company Limited',
    symbol: '6885.HK',
  },
  {
    value: '6888.HK',
    label: '6888.HK,  Freetech Road Recycling Technology (Holdings) Limited',
    name: 'Freetech Road Recycling Technology (Holdings) Limited',
    symbol: '6888.HK',
  },
  {
    value: '6889.HK',
    label: '6889.HK,  DYNAM JAPAN HOLDINGS Co., Ltd.',
    name: 'DYNAM JAPAN HOLDINGS Co., Ltd.',
    symbol: '6889.HK',
  },
  {
    value: '6890.HK',
    label: '6890.HK,  KangLi International Holdings Limited',
    name: 'KangLi International Holdings Limited',
    symbol: '6890.HK',
  },
  {
    value: '6893.HK',
    label: '6893.HK,  Hin Sang Group (International) Holding Co. Ltd.',
    name: 'Hin Sang Group (International) Holding Co. Ltd.',
    symbol: '6893.HK',
  },
  {
    value: '6896.HK',
    label: '6896.HK,  Golden Throat Holdings Group Company Limited',
    name: 'Golden Throat Holdings Group Company Limited',
    symbol: '6896.HK',
  },
  {
    value: '6898.HK',
    label: '6898.HK,  China Aluminum Cans Holdings Limited',
    name: 'China Aluminum Cans Holdings Limited',
    symbol: '6898.HK',
  },
  {
    value: '6899.HK',
    label: '6899.HK,  Ourgame International Holdings Limited',
    name: 'Ourgame International Holdings Limited',
    symbol: '6899.HK',
  },
  {
    value: '6908.HK',
    label: '6908.HK,  HG Semiconductor Limited',
    name: 'HG Semiconductor Limited',
    symbol: '6908.HK',
  },
  {
    value: '6918.HK',
    label: '6918.HK,  Kidztech Holdings Limited',
    name: 'Kidztech Holdings Limited',
    symbol: '6918.HK',
  },
  {
    value: '6919.HK',
    label: '6919.HK,  Renrui Human Resources Technology Holdings Limited',
    name: 'Renrui Human Resources Technology Holdings Limited',
    symbol: '6919.HK',
  },
  {
    value: '6928.HK',
    label: '6928.HK,  TOMO Holdings Limited',
    name: 'TOMO Holdings Limited',
    symbol: '6928.HK',
  },
  {
    value: '6966.HK',
    label: '6966.HK,  China Wan Tong Yuan (Holdings) Limited',
    name: 'China Wan Tong Yuan (Holdings) Limited',
    symbol: '6966.HK',
  },
  {
    value: '8003.HK',
    label: '8003.HK,  Great World Company Holdings Ltd',
    name: 'Great World Company Holdings Ltd',
    symbol: '8003.HK',
  },
  {
    value: '8005.HK',
    label: '8005.HK,  Yuxing InfoTech Investment Holdings Limited',
    name: 'Yuxing InfoTech Investment Holdings Limited',
    symbol: '8005.HK',
  },
  {
    value: '8006.HK',
    label: '8006.HK,  Sino Splendid Holdings Limited',
    name: 'Sino Splendid Holdings Limited',
    symbol: '8006.HK',
  },
  {
    value: '8007.HK',
    label: '8007.HK,  Global Strategic Group Limited',
    name: 'Global Strategic Group Limited',
    symbol: '8007.HK',
  },
  {
    value: '8011.HK',
    label: '8011.HK,  Polyard Petroleum International Group Limited',
    name: 'Polyard Petroleum International Group Limited',
    symbol: '8011.HK',
  },
  {
    value: '8013.HK',
    label: '8013.HK,  ECI Technology Holdings Limited',
    name: 'ECI Technology Holdings Limited',
    symbol: '8013.HK',
  },
  {
    value: '8017.HK',
    label: '8017.HK,  TradeGo FinTech Limited',
    name: 'TradeGo FinTech Limited',
    symbol: '8017.HK',
  },
  {
    value: '8018.HK',
    label: '8018.HK,  Finsoft Financial Investment Holdings Limited',
    name: 'Finsoft Financial Investment Holdings Limited',
    symbol: '8018.HK',
  },
  {
    value: '8019.HK',
    label: '8019.HK,  Hao Wen Holdings Limited',
    name: 'Hao Wen Holdings Limited',
    symbol: '8019.HK',
  },
  {
    value: '8021.HK',
    label: '8021.HK,  WLS Holdings Limited',
    name: 'WLS Holdings Limited',
    symbol: '8021.HK',
  },
  {
    value: '8023.HK',
    label: '8023.HK,  Kwong Man Kee Group Limited',
    name: 'Kwong Man Kee Group Limited',
    symbol: '8023.HK',
  },
  {
    value: '8026.HK',
    label: '8026.HK,  China Brilliant Global Limited',
    name: 'China Brilliant Global Limited',
    symbol: '8026.HK',
  },
  {
    value: '8028.HK',
    label: '8028.HK,  Timeless Software Limited',
    name: 'Timeless Software Limited',
    symbol: '8028.HK',
  },
  {
    value: '8029.HK',
    label: '8029.HK,  Imperium Financial Group Limited',
    name: 'Imperium Financial Group Limited',
    symbol: '8029.HK',
  },
  {
    value: '8032.HK',
    label: '8032.HK,  Viva China Holdings Limited',
    name: 'Viva China Holdings Limited',
    symbol: '8032.HK',
  },
  {
    value: '8033.HK',
    label: '8033.HK,  Vodatel Networks Holdings Limited',
    name: 'Vodatel Networks Holdings Limited',
    symbol: '8033.HK',
  },
  {
    value: '8035.HK',
    label: '8035.HK,  Janco Holdings Limited',
    name: 'Janco Holdings Limited',
    symbol: '8035.HK',
  },
  {
    value: '8036.HK',
    label: '8036.HK,  eBroker Group Limited',
    name: 'eBroker Group Limited',
    symbol: '8036.HK',
  },
  {
    value: '8037.HK',
    label: '8037.HK,  China Biotech Services Holdings Limited',
    name: 'China Biotech Services Holdings Limited',
    symbol: '8037.HK',
  },
  {
    value: '8039.HK',
    label: '8039.HK,  China Come Ride New Energy Group Limited',
    name: 'China Come Ride New Energy Group Limited',
    symbol: '8039.HK',
  },
  {
    value: '8040.HK',
    label: '8040.HK,  Coolpoint Innonism Holding Limited',
    name: 'Coolpoint Innonism Holding Limited',
    symbol: '8040.HK',
  },
  {
    value: '8041.HK',
    label: '8041.HK,  Luxey International (Holdings) Limited',
    name: 'Luxey International (Holdings) Limited',
    symbol: '8041.HK',
  },
  {
    value: '8042.HK',
    label: '8042.HK,  KOS International Holdings Limited',
    name: 'KOS International Holdings Limited',
    symbol: '8042.HK',
  },
  {
    value: '8043.HK',
    label: '8043.HK,  Atlinks Group Limited',
    name: 'Atlinks Group Limited',
    symbol: '8043.HK',
  },
  {
    value: '8045.HK',
    label: '8045.HK,  Jiangsu NandaSoft Technology Company Limited',
    name: 'Jiangsu NandaSoft Technology Company Limited',
    symbol: '8045.HK',
  },
  {
    value: '8048.HK',
    label: '8048.HK,  Yu Tak International Holdings Limited',
    name: 'Yu Tak International Holdings Limited',
    symbol: '8048.HK',
  },
  {
    value: '8049.HK',
    label:
      '8049.HK,  Jilin Province Huinan Changlong Bio-pharmacy Company Limited',
    name: 'Jilin Province Huinan Changlong Bio-pharmacy Company Limited',
    symbol: '8049.HK',
  },
  {
    value: '8050.HK',
    label: '8050.HK,  Quantum Thinking Limited',
    name: 'Quantum Thinking Limited',
    symbol: '8050.HK',
  },
  {
    value: '8051.HK',
    label: '8051.HK,  CircuTech International Holdings Limited',
    name: 'CircuTech International Holdings Limited',
    symbol: '8051.HK',
  },
  {
    value: '8052.HK',
    label: '8052.HK,  Luk Hing Entertainment Group Holdings Limited',
    name: 'Luk Hing Entertainment Group Holdings Limited',
    symbol: '8052.HK',
  },
  {
    value: '8053.HK',
    label: '8053.HK,  Pizu Group Holdings Limited',
    name: 'Pizu Group Holdings Limited',
    symbol: '8053.HK',
  },
  {
    value: '8056.HK',
    label: '8056.HK,  Life Concepts Holdings Limited',
    name: 'Life Concepts Holdings Limited',
    symbol: '8056.HK',
  },
  {
    value: '8057.HK',
    label: '8057.HK,  Madison Holdings Group Limited',
    name: 'Madison Holdings Group Limited',
    symbol: '8057.HK',
  },
  {
    value: '8059.HK',
    label: '8059.HK,  Glory Flame Holdings Limited',
    name: 'Glory Flame Holdings Limited',
    symbol: '8059.HK',
  },
  {
    value: '8062.HK',
    label: '8062.HK,  EFT Solutions Holdings Limited',
    name: 'EFT Solutions Holdings Limited',
    symbol: '8062.HK',
  },
  {
    value: '8063.HK',
    label: '8063.HK,  Global Mastermind Holdings Limited',
    name: 'Global Mastermind Holdings Limited',
    symbol: '8063.HK',
  },
  {
    value: '8065.HK',
    label: '8065.HK,  KML Technology Group Limited',
    name: 'KML Technology Group Limited',
    symbol: '8065.HK',
  },
  {
    value: '8066.HK',
    label: '8066.HK,  Phoenitron Holdings Limited',
    name: 'Phoenitron Holdings Limited',
    symbol: '8066.HK',
  },
  {
    value: '8069.HK',
    label: '8069.HK,  WWPKG Holdings Company Limited',
    name: 'WWPKG Holdings Company Limited',
    symbol: '8069.HK',
  },
  {
    value: '8070.HK',
    label: '8070.HK,  Keen Ocean International Holding Limited',
    name: 'Keen Ocean International Holding Limited',
    symbol: '8070.HK',
  },
  {
    value: '8071.HK',
    label: '8071.HK,  China Netcom Technology Holdings Limited',
    name: 'China Netcom Technology Holdings Limited',
    symbol: '8071.HK',
  },
  {
    value: '8072.HK',
    label: '8072.HK,  Roma (meta) Group Limited',
    name: 'Roma (meta) Group Limited',
    symbol: '8072.HK',
  },
  {
    value: '8073.HK',
    label: '8073.HK,  China Singyes New Materials Holdings Limited',
    name: 'China Singyes New Materials Holdings Limited',
    symbol: '8073.HK',
  },
  {
    value: '8075.HK',
    label: '8075.HK,  Media Asia Group Holdings Limited',
    name: 'Media Asia Group Holdings Limited',
    symbol: '8075.HK',
  },
  {
    value: '8076.HK',
    label: '8076.HK,  Sing Lee Software (Group) Limited',
    name: 'Sing Lee Software (Group) Limited',
    symbol: '8076.HK',
  },
  {
    value: '8079.HK',
    label: '8079.HK,  Easy Repay Finance & Investment Limited',
    name: 'Easy Repay Finance & Investment Limited',
    symbol: '8079.HK',
  },
  {
    value: '8080.HK',
    label: '8080.HK,  North Asia Strategic Holdings Limited',
    name: 'North Asia Strategic Holdings Limited',
    symbol: '8080.HK',
  },
  {
    value: '8081.HK',
    label: '8081.HK,  Hang Tai Yue Group Holdings Limited',
    name: 'Hang Tai Yue Group Holdings Limited',
    symbol: '8081.HK',
  },
  {
    value: '8082.HK',
    label: '8082.HK,  Yeah Yeah Group Holdings Limited',
    name: 'Yeah Yeah Group Holdings Limited',
    symbol: '8082.HK',
  },
  {
    value: '8083.HK',
    label: '8083.HK,  China Youzan Limited',
    name: 'China Youzan Limited',
    symbol: '8083.HK',
  },
  {
    value: '8087.HK',
    label: '8087.HK,  China 33 Media Group Limited',
    name: 'China 33 Media Group Limited',
    symbol: '8087.HK',
  },
  {
    value: '8091.HK',
    label: '8091.HK,  OOH Holdings Limited',
    name: 'OOH Holdings Limited',
    symbol: '8091.HK',
  },
  {
    value: '8092.HK',
    label: '8092.HK,  ITE (Holdings) Limited',
    name: 'ITE (Holdings) Limited',
    symbol: '8092.HK',
  },
  {
    value: '8093.HK',
    label: '8093.HK,  Million Stars Holdings Limited',
    name: 'Million Stars Holdings Limited',
    symbol: '8093.HK',
  },
  {
    value: '8095.HK',
    label:
      '8095.HK,  Beijing Beida Jade Bird Universal Sci-Tech Company Limited',
    name: 'Beijing Beida Jade Bird Universal Sci-Tech Company Limited',
    symbol: '8095.HK',
  },
  {
    value: '8096.HK',
    label: '8096.HK,  Tasty Concepts Holding Limited',
    name: 'Tasty Concepts Holding Limited',
    symbol: '8096.HK',
  },
  {
    value: '8098.HK',
    label: '8098.HK,  CL Group (Holdings) Limited',
    name: 'CL Group (Holdings) Limited',
    symbol: '8098.HK',
  },
  {
    value: '8100.HK',
    label: '8100.HK,  GET Holdings Limited',
    name: 'GET Holdings Limited',
    symbol: '8100.HK',
  },
  {
    value: '8106.HK',
    label: '8106.HK,  Shenghua Lande Scitech Limited',
    name: 'Shenghua Lande Scitech Limited',
    symbol: '8106.HK',
  },
  {
    value: '8107.HK',
    label: '8107.HK,  Vision International Holdings Limited',
    name: 'Vision International Holdings Limited',
    symbol: '8107.HK',
  },
  {
    value: '8111.HK',
    label: '8111.HK,  China Technology Industry Group Limited',
    name: 'China Technology Industry Group Limited',
    symbol: '8111.HK',
  },
  {
    value: '8112.HK',
    label: '8112.HK,  Cornerstone Financial Holdings Limited',
    name: 'Cornerstone Financial Holdings Limited',
    symbol: '8112.HK',
  },
  {
    value: '8113.HK',
    label: '8113.HK,  Hi-Level Technology Holdings Limited',
    name: 'Hi-Level Technology Holdings Limited',
    symbol: '8113.HK',
  },
  {
    value: '8115.HK',
    label: '8115.HK,  Shanghai Qingpu Fire-Fighting Equipment Co., Ltd.',
    name: 'Shanghai Qingpu Fire-Fighting Equipment Co., Ltd.',
    symbol: '8115.HK',
  },
  {
    value: '8118.HK',
    label: '8118.HK,  Bortex Global Limited',
    name: 'Bortex Global Limited',
    symbol: '8118.HK',
  },
  {
    value: '8120.HK',
    label: '8120.HK,  China Demeter Financial Investments Limited',
    name: 'China Demeter Financial Investments Limited',
    symbol: '8120.HK',
  },
  {
    value: '8121.HK',
    label: '8121.HK,  Guru Online (Holdings) Limited',
    name: 'Guru Online (Holdings) Limited',
    symbol: '8121.HK',
  },
  {
    value: '8123.HK',
    label: '8123.HK,  Sinofortune Financial Holdings Limited',
    name: 'Sinofortune Financial Holdings Limited',
    symbol: '8123.HK',
  },
  {
    value: '8125.HK',
    label: '8125.HK,  Royal Century Resources Holdings Limited',
    name: 'Royal Century Resources Holdings Limited',
    symbol: '8125.HK',
  },
  {
    value: '8126.HK',
    label: '8126.HK,  G.A. Holdings Limited',
    name: 'G.A. Holdings Limited',
    symbol: '8126.HK',
  },
  {
    value: '8128.HK',
    label: '8128.HK,  CHYY Development Group Limited',
    name: 'CHYY Development Group Limited',
    symbol: '8128.HK',
  },
  {
    value: '8130.HK',
    label: '8130.HK,  Dadi International Group Limited',
    name: 'Dadi International Group Limited',
    symbol: '8130.HK',
  },
  {
    value: '8131.HK',
    label: '8131.HK,  abc Multiactive Limited',
    name: 'abc Multiactive Limited',
    symbol: '8131.HK',
  },
  {
    value: '8133.HK',
    label: '8133.HK,  Solomon Worldwide Holdings Limited',
    name: 'Solomon Worldwide Holdings Limited',
    symbol: '8133.HK',
  },
  {
    value: '8136.HK',
    label: '8136.HK,  IMS Group Holdings Limited',
    name: 'IMS Group Holdings Limited',
    symbol: '8136.HK',
  },
  {
    value: '8137.HK',
    label: '8137.HK,  Honbridge Holdings Limited',
    name: 'Honbridge Holdings Limited',
    symbol: '8137.HK',
  },
  {
    value: '8139.HK',
    label: "8139.HK,  Zhejiang Chang'an Renheng Technology Co., Ltd.",
    name: "Zhejiang Chang'an Renheng Technology Co., Ltd.",
    symbol: '8139.HK',
  },
  {
    value: '8140.HK',
    label: '8140.HK,  BOSA Technology Holdings Limited',
    name: 'BOSA Technology Holdings Limited',
    symbol: '8140.HK',
  },
  {
    value: '8143.HK',
    label: '8143.HK,  Good Fellow Healthcare Holdings Limited',
    name: 'Good Fellow Healthcare Holdings Limited',
    symbol: '8143.HK',
  },
  {
    value: '8146.HK',
    label: '8146.HK,  Grace Wine Holdings Limited',
    name: 'Grace Wine Holdings Limited',
    symbol: '8146.HK',
  },
  {
    value: '8147.HK',
    label: '8147.HK,  Millennium Pacific Group Holdings Limited',
    name: 'Millennium Pacific Group Holdings Limited',
    symbol: '8147.HK',
  },
  {
    value: '8148.HK',
    label: '8148.HK,  Aurum Pacific (China) Group Limited',
    name: 'Aurum Pacific (China) Group Limited',
    symbol: '8148.HK',
  },
  {
    value: '8149.HK',
    label: '8149.HK,  Altus Holdings Limited',
    name: 'Altus Holdings Limited',
    symbol: '8149.HK',
  },
  {
    value: '8150.HK',
    label: '8150.HK,  Seamless Green China (Holdings) Limited',
    name: 'Seamless Green China (Holdings) Limited',
    symbol: '8150.HK',
  },
  {
    value: '8151.HK',
    label: '8151.HK,  Bao Shen Holdings Limited',
    name: 'Bao Shen Holdings Limited',
    symbol: '8151.HK',
  },
  {
    value: '8152.HK',
    label: '8152.HK,  M&L Holdings Group Limited',
    name: 'M&L Holdings Group Limited',
    symbol: '8152.HK',
  },
  {
    value: '8153.HK',
    label: '8153.HK,  Farnova Group Holdings Limited',
    name: 'Farnova Group Holdings Limited',
    symbol: '8153.HK',
  },
  {
    value: '8156.HK',
    label: '8156.HK,  Sinopharm Tech Holdings Limited',
    name: 'Sinopharm Tech Holdings Limited',
    symbol: '8156.HK',
  },
  {
    value: '8158.HK',
    label: '8158.HK,  China Regenerative Medicine International Limited',
    name: 'China Regenerative Medicine International Limited',
    symbol: '8158.HK',
  },
  {
    value: '8159.HK',
    label: '8159.HK,  China United Venture Investment Limited',
    name: 'China United Venture Investment Limited',
    symbol: '8159.HK',
  },
  {
    value: '8160.HK',
    label: '8160.HK,  Goldway Education Group Limited',
    name: 'Goldway Education Group Limited',
    symbol: '8160.HK',
  },
  {
    value: '8161.HK',
    label: '8161.HK,  MediNet Group Limited',
    name: 'MediNet Group Limited',
    symbol: '8161.HK',
  },
  {
    value: '8162.HK',
    label: '8162.HK,  Loco Hong Kong Holdings Limited',
    name: 'Loco Hong Kong Holdings Limited',
    symbol: '8162.HK',
  },
  {
    value: '8163.HK',
    label: '8163.HK,  Merdeka Financial Group Limited',
    name: 'Merdeka Financial Group Limited',
    symbol: '8163.HK',
  },
  {
    value: '8166.HK',
    label: '8166.HK,  China Eco-Farming Limited',
    name: 'China Eco-Farming Limited',
    symbol: '8166.HK',
  },
  {
    value: '8167.HK',
    label: '8167.HK,  Neo Telemedia Limited',
    name: 'Neo Telemedia Limited',
    symbol: '8167.HK',
  },
  {
    value: '8168.HK',
    label: '8168.HK,  Amasse Capital Holdings Limited',
    name: 'Amasse Capital Holdings Limited',
    symbol: '8168.HK',
  },
  {
    value: '8169.HK',
    label: '8169.HK,  Eco-Tek Holdings Limited',
    name: 'Eco-Tek Holdings Limited',
    symbol: '8169.HK',
  },
  {
    value: '8172.HK',
    label: '8172.HK,  Lajin Entertainment Network Group Limited',
    name: 'Lajin Entertainment Network Group Limited',
    symbol: '8172.HK',
  },
  {
    value: '8173.HK',
    label: '8173.HK,  Hephaestus Holdings Limited',
    name: 'Hephaestus Holdings Limited',
    symbol: '8173.HK',
  },
  {
    value: '8178.HK',
    label: '8178.HK,  China Information Technology Development Limited',
    name: 'China Information Technology Development Limited',
    symbol: '8178.HK',
  },
  {
    value: '8179.HK',
    label: '8179.HK,  Palinda Group Holdings Limited',
    name: 'Palinda Group Holdings Limited',
    symbol: '8179.HK',
  },
  {
    value: '8181.HK',
    label: '8181.HK,  Shi Shi Services Limited',
    name: 'Shi Shi Services Limited',
    symbol: '8181.HK',
  },
  {
    value: '8186.HK',
    label: '8186.HK,  M-Resources Group Limited',
    name: 'M-Resources Group Limited',
    symbol: '8186.HK',
  },
  {
    value: '8187.HK',
    label: '8187.HK,  Jimu Group Limited',
    name: 'Jimu Group Limited',
    symbol: '8187.HK',
  },
  {
    value: '8188.HK',
    label: '8188.HK,  GME Group Holdings Limited',
    name: 'GME Group Holdings Limited',
    symbol: '8188.HK',
  },
  {
    value: '8191.HK',
    label: '8191.HK,  Hong Wei (Asia) Holdings Company Limited',
    name: 'Hong Wei (Asia) Holdings Company Limited',
    symbol: '8191.HK',
  },
  {
    value: '8195.HK',
    label: '8195.HK,  Legendary Group Limited',
    name: 'Legendary Group Limited',
    symbol: '8195.HK',
  },
  {
    value: '8196.HK',
    label: '8196.HK,  China TianYF Holdings Group Limited',
    name: 'China TianYF Holdings Group Limited',
    symbol: '8196.HK',
  },
  {
    value: '8198.HK',
    label: '8198.HK,  Crypto Flow Technology Limited',
    name: 'Crypto Flow Technology Limited',
    symbol: '8198.HK',
  },
  {
    value: '8200.HK',
    label: '8200.HK,  Sau San Tong Holdings Limited',
    name: 'Sau San Tong Holdings Limited',
    symbol: '8200.HK',
  },
  {
    value: '8201.HK',
    label: '8201.HK,  PPS International (Holdings) Limited',
    name: 'PPS International (Holdings) Limited',
    symbol: '8201.HK',
  },
  {
    value: '8203.HK',
    label: '8203.HK,  Kaisun Holdings Limited',
    name: 'Kaisun Holdings Limited',
    symbol: '8203.HK',
  },
  {
    value: '8206.HK',
    label: '8206.HK,  Shentong Robot Education Group Company Limited',
    name: 'Shentong Robot Education Group Company Limited',
    symbol: '8206.HK',
  },
  {
    value: '8208.HK',
    label: '8208.HK,  WMCH Global Investment Limited',
    name: 'WMCH Global Investment Limited',
    symbol: '8208.HK',
  },
  {
    value: '8210.HK',
    label: '8210.HK,  DLC Asia Limited',
    name: 'DLC Asia Limited',
    symbol: '8210.HK',
  },
  {
    value: '8211.HK',
    label: '8211.HK,  Zhejiang Yongan Rongtong Holdings Co., Ltd.',
    name: 'Zhejiang Yongan Rongtong Holdings Co., Ltd.',
    symbol: '8211.HK',
  },
  {
    value: '8213.HK',
    label: '8213.HK,  StarGlory Holdings Company Limited',
    name: 'StarGlory Holdings Company Limited',
    symbol: '8213.HK',
  },
  {
    value: '8217.HK',
    label: '8217.HK,  Luen Wong Group Holdings Limited',
    name: 'Luen Wong Group Holdings Limited',
    symbol: '8217.HK',
  },
  {
    value: '8218.HK',
    label: '8218.HK,  Echo International Holdings Group Limited',
    name: 'Echo International Holdings Group Limited',
    symbol: '8218.HK',
  },
  {
    value: '8219.HK',
    label: '8219.HK,  Hanvey Group Holdings Limited',
    name: 'Hanvey Group Holdings Limited',
    symbol: '8219.HK',
  },
  {
    value: '8220.HK',
    label: '8220.HK,  Bingo Group Holdings Limited',
    name: 'Bingo Group Holdings Limited',
    symbol: '8220.HK',
  },
  {
    value: '8221.HK',
    label: '8221.HK,  PF Group Holdings Limited',
    name: 'PF Group Holdings Limited',
    symbol: '8221.HK',
  },
  {
    value: '8222.HK',
    label: '8222.HK,  E Lighting Group Holdings Limited',
    name: 'E Lighting Group Holdings Limited',
    symbol: '8222.HK',
  },
  {
    value: '8223.HK',
    label: '8223.HK,  Ziyuanyuan Holdings Group Limited',
    name: 'Ziyuanyuan Holdings Group Limited',
    symbol: '8223.HK',
  },
  {
    value: '8225.HK',
    label: '8225.HK,  China Health Group Inc.',
    name: 'China Health Group Inc.',
    symbol: '8225.HK',
  },
  {
    value: '8226.HK',
    label: '8226.HK,  KOALA Financial Group Limited',
    name: 'KOALA Financial Group Limited',
    symbol: '8226.HK',
  },
  {
    value: '8227.HK',
    label: "8227.HK,  Xi'an Haitian Antenna Technologies Co., Ltd.",
    name: "Xi'an Haitian Antenna Technologies Co., Ltd.",
    symbol: '8227.HK',
  },
  {
    value: '8229.HK',
    label: '8229.HK,  Future Data Group Limited',
    name: 'Future Data Group Limited',
    symbol: '8229.HK',
  },
  {
    value: '8232.HK',
    label: '8232.HK,  Classified Group (Holdings) Limited',
    name: 'Classified Group (Holdings) Limited',
    symbol: '8232.HK',
  },
  {
    value: '8237.HK',
    label: '8237.HK,  Link Holdings Limited',
    name: 'Link Holdings Limited',
    symbol: '8237.HK',
  },
  {
    value: '8239.HK',
    label: '8239.HK,  Capital Finance Holdings Limited',
    name: 'Capital Finance Holdings Limited',
    symbol: '8239.HK',
  },
  {
    value: '8241.HK',
    label: '8241.HK,  Ying Kee Tea House Group Limited',
    name: 'Ying Kee Tea House Group Limited',
    symbol: '8241.HK',
  },
  {
    value: '8246.HK',
    label: '8246.HK,  Zhonghua Gas Holdings Limited',
    name: 'Zhonghua Gas Holdings Limited',
    symbol: '8246.HK',
  },
  {
    value: '8249.HK',
    label:
      '8249.HK,  Zhejiang RuiYuan Intelligent Control Technology Company Limited',
    name: 'Zhejiang RuiYuan Intelligent Control Technology Company Limited',
    symbol: '8249.HK',
  },
  {
    value: '8250.HK',
    label: '8250.HK,  Silk Road Energy Services Group Limited',
    name: 'Silk Road Energy Services Group Limited',
    symbol: '8250.HK',
  },
  {
    value: '8255.HK',
    label: '8255.HK,  China Binary New Fintech Group',
    name: 'China Binary New Fintech Group',
    symbol: '8255.HK',
  },
  {
    value: '8257.HK',
    label: '8257.HK,  Genes Tech Group Holdings Company Limited',
    name: 'Genes Tech Group Holdings Company Limited',
    symbol: '8257.HK',
  },
  {
    value: '8262.HK',
    label: '8262.HK,  Super Strong Holdings Limited',
    name: 'Super Strong Holdings Limited',
    symbol: '8262.HK',
  },
  {
    value: '8267.HK',
    label: '8267.HK,  Linekong Interactive Group Co., Ltd.',
    name: 'Linekong Interactive Group Co., Ltd.',
    symbol: '8267.HK',
  },
  {
    value: '8268.HK',
    label: '8268.HK,  Smart City Development Holdings Limited',
    name: 'Smart City Development Holdings Limited',
    symbol: '8268.HK',
  },
  {
    value: '8269.HK',
    label: '8269.HK,  Wealth Glory Holdings Limited',
    name: 'Wealth Glory Holdings Limited',
    symbol: '8269.HK',
  },
  {
    value: '8270.HK',
    label: '8270.HK,  China CBM Group Company Limited',
    name: 'China CBM Group Company Limited',
    symbol: '8270.HK',
  },
  {
    value: '8271.HK',
    label: '8271.HK,  Global Digital Creations Holdings Limited',
    name: 'Global Digital Creations Holdings Limited',
    symbol: '8271.HK',
  },
  {
    value: '8275.HK',
    label: '8275.HK,  State Innovation Holdings Limited',
    name: 'State Innovation Holdings Limited',
    symbol: '8275.HK',
  },
  {
    value: '8277.HK',
    label: '8277.HK,  Steed Oriental (Holdings) Company Limited',
    name: 'Steed Oriental (Holdings) Company Limited',
    symbol: '8277.HK',
  },
  {
    value: '8279.HK',
    label: '8279.HK,  AGTech Holdings Limited',
    name: 'AGTech Holdings Limited',
    symbol: '8279.HK',
  },
  {
    value: '8280.HK',
    label: '8280.HK,  China Digital Video Holdings Limited',
    name: 'China Digital Video Holdings Limited',
    symbol: '8280.HK',
  },
  {
    value: '8285.HK',
    label: '8285.HK,  Sling Group Holdings Limited',
    name: 'Sling Group Holdings Limited',
    symbol: '8285.HK',
  },
  {
    value: '8287.HK',
    label: '8287.HK,  Zioncom Holdings Limited',
    name: 'Zioncom Holdings Limited',
    symbol: '8287.HK',
  },
  {
    value: '8290.HK',
    label: '8290.HK,  Ahsay Backup Software Development Company Limited',
    name: 'Ahsay Backup Software Development Company Limited',
    symbol: '8290.HK',
  },
  {
    value: '8291.HK',
    label: '8291.HK,  Wan Cheng Metal Packaging Company Limited',
    name: 'Wan Cheng Metal Packaging Company Limited',
    symbol: '8291.HK',
  },
  {
    value: '8292.HK',
    label: '8292.HK,  Worldgate Global Logistics Ltd',
    name: 'Worldgate Global Logistics Ltd',
    symbol: '8292.HK',
  },
  {
    value: '8293.HK',
    label: '8293.HK,  SingAsia Holdings Limited',
    name: 'SingAsia Holdings Limited',
    symbol: '8293.HK',
  },
  {
    value: '8295.HK',
    label: '8295.HK,  Kingwisoft Technology Group Company Limited',
    name: 'Kingwisoft Technology Group Company Limited',
    symbol: '8295.HK',
  },
  {
    value: '8296.HK',
    label: '8296.HK,  Sino-Life Group Limited',
    name: 'Sino-Life Group Limited',
    symbol: '8296.HK',
  },
  {
    value: '8297.HK',
    label: '8297.HK,  Ocean Star Technology Group Limited',
    name: 'Ocean Star Technology Group Limited',
    symbol: '8297.HK',
  },
  {
    value: '8299.HK',
    label: '8299.HK,  Grand T G Gold Holdings Limited',
    name: 'Grand T G Gold Holdings Limited',
    symbol: '8299.HK',
  },
  {
    value: '8300.HK',
    label: '8300.HK,  Royal Group Holdings International Company Limited',
    name: 'Royal Group Holdings International Company Limited',
    symbol: '8300.HK',
  },
  {
    value: '8305.HK',
    label: '8305.HK,  Tong Kee (Holding) Limited',
    name: 'Tong Kee (Holding) Limited',
    symbol: '8305.HK',
  },
  {
    value: '8307.HK',
    label: '8307.HK,  Medicskin Holdings Limited',
    name: 'Medicskin Holdings Limited',
    symbol: '8307.HK',
  },
  {
    value: '8308.HK',
    label: '8308.HK,  Gudou Holdings Limited',
    name: 'Gudou Holdings Limited',
    symbol: '8308.HK',
  },
  {
    value: '8309.HK',
    label: '8309.HK,  Man Shing Global Holdings Limited',
    name: 'Man Shing Global Holdings Limited',
    symbol: '8309.HK',
  },
  {
    value: '8313.HK',
    label: '8313.HK,  ZACD Group Ltd.',
    name: 'ZACD Group Ltd.',
    symbol: '8313.HK',
  },
  {
    value: '8315.HK',
    label: '8315.HK,  Greatwalle Inc.',
    name: 'Greatwalle Inc.',
    symbol: '8315.HK',
  },
  {
    value: '8316.HK',
    label: '8316.HK,  China Hongbao Holdings Limited',
    name: 'China Hongbao Holdings Limited',
    symbol: '8316.HK',
  },
  {
    value: '8317.HK',
    label: '8317.HK,  Finet Group Limited',
    name: 'Finet Group Limited',
    symbol: '8317.HK',
  },
  {
    value: '8319.HK',
    label: '8319.HK,  Expert Systems Holdings Limited',
    name: 'Expert Systems Holdings Limited',
    symbol: '8319.HK',
  },
  {
    value: '8320.HK',
    label:
      '8320.HK,  Allied Sustainability and Environmental Consultants Group Limited',
    name: 'Allied Sustainability and Environmental Consultants Group Limited',
    symbol: '8320.HK',
  },
  {
    value: '8321.HK',
    label: '8321.HK,  Tai Kam Holdings Limited',
    name: 'Tai Kam Holdings Limited',
    symbol: '8321.HK',
  },
  {
    value: '8325.HK',
    label: '8325.HK,  China Smartpay Group Holdings Limited',
    name: 'China Smartpay Group Holdings Limited',
    symbol: '8325.HK',
  },
  {
    value: '8326.HK',
    label: '8326.HK,  Tonking New Energy Group Holdings Limited',
    name: 'Tonking New Energy Group Holdings Limited',
    symbol: '8326.HK',
  },
  {
    value: '8328.HK',
    label: '8328.HK,  Xinyi Electric Storage Holdings Limited',
    name: 'Xinyi Electric Storage Holdings Limited',
    symbol: '8328.HK',
  },
  {
    value: '8329.HK',
    label:
      '8329.HK,  Shenzhen Neptunus Interlong Bio-technique Company Limited',
    name: 'Shenzhen Neptunus Interlong Bio-technique Company Limited',
    symbol: '8329.HK',
  },
  {
    value: '8331.HK',
    label: '8331.HK,  P.B. Group Limited',
    name: 'P.B. Group Limited',
    symbol: '8331.HK',
  },
  {
    value: '8333.HK',
    label: '8333.HK,  Astrum Financial Holdings Limited',
    name: 'Astrum Financial Holdings Limited',
    symbol: '8333.HK',
  },
  {
    value: '8340.HK',
    label: '8340.HK,  Zijing International Financial Holdings Limited',
    name: 'Zijing International Financial Holdings Limited',
    symbol: '8340.HK',
  },
  {
    value: '8341.HK',
    label: '8341.HK,  Aeso Holding Limited',
    name: 'Aeso Holding Limited',
    symbol: '8341.HK',
  },
  {
    value: '8347.HK',
    label: '8347.HK,  F8 Enterprises (Holdings) Group Limited',
    name: 'F8 Enterprises (Holdings) Group Limited',
    symbol: '8347.HK',
  },
  {
    value: '8348.HK',
    label:
      '8348.HK,  Tianjin Binhai Teda Logistics (Group) Corporation Limited',
    name: 'Tianjin Binhai Teda Logistics (Group) Corporation Limited',
    symbol: '8348.HK',
  },
  {
    value: '8349.HK',
    label: '8349.HK,  Yunhong Guixin Group Holdings Limited',
    name: 'Yunhong Guixin Group Holdings Limited',
    symbol: '8349.HK',
  },
  {
    value: '8350.HK',
    label: '8350.HK,  Excalibur Global Financial Holdings Limited',
    name: 'Excalibur Global Financial Holdings Limited',
    symbol: '8350.HK',
  },
  {
    value: '8357.HK',
    label: '8357.HK,  Republic Healthcare Limited',
    name: 'Republic Healthcare Limited',
    symbol: '8357.HK',
  },
  {
    value: '8360.HK',
    label: '8360.HK,  AL Group Limited',
    name: 'AL Group Limited',
    symbol: '8360.HK',
  },
  {
    value: '8362.HK',
    label: '8362.HK,  Winning Tower Group Holdings Limited',
    name: 'Winning Tower Group Holdings Limited',
    symbol: '8362.HK',
  },
  {
    value: '8363.HK',
    label: '8363.HK,  SDM Education Group Holdings Limited',
    name: 'SDM Education Group Holdings Limited',
    symbol: '8363.HK',
  },
  {
    value: '8365.HK',
    label: '8365.HK,  Hatcher Group Limited',
    name: 'Hatcher Group Limited',
    symbol: '8365.HK',
  },
  {
    value: '8367.HK',
    label: '8367.HK,  Simplicity Holding Limited',
    name: 'Simplicity Holding Limited',
    symbol: '8367.HK',
  },
  {
    value: '8368.HK',
    label: '8368.HK,  Creative China Holdings Limited',
    name: 'Creative China Holdings Limited',
    symbol: '8368.HK',
  },
  {
    value: '8370.HK',
    label: '8370.HK,  Zhi Sheng Group Holdings Limited',
    name: 'Zhi Sheng Group Holdings Limited',
    symbol: '8370.HK',
  },
  {
    value: '8371.HK',
    label: '8371.HK,  Taste Gourmet Group Limited',
    name: 'Taste Gourmet Group Limited',
    symbol: '8371.HK',
  },
  {
    value: '8372.HK',
    label: '8372.HK,  Grand Brilliance Group Holdings Limited',
    name: 'Grand Brilliance Group Holdings Limited',
    symbol: '8372.HK',
  },
  {
    value: '8373.HK',
    label: '8373.HK,  Indigo Star Holdings Limited',
    name: 'Indigo Star Holdings Limited',
    symbol: '8373.HK',
  },
  {
    value: '8375.HK',
    label: '8375.HK,  Vertical International Holdings Limited',
    name: 'Vertical International Holdings Limited',
    symbol: '8375.HK',
  },
  {
    value: '8377.HK',
    label: '8377.HK,  Shen You Holdings Limited',
    name: 'Shen You Holdings Limited',
    symbol: '8377.HK',
  },
  {
    value: '8379.HK',
    label: '8379.HK,  Prime Intelligence Solutions Group Limited',
    name: 'Prime Intelligence Solutions Group Limited',
    symbol: '8379.HK',
  },
  {
    value: '8383.HK',
    label: '8383.HK,  Linocraft Holdings Limited',
    name: 'Linocraft Holdings Limited',
    symbol: '8383.HK',
  },
  {
    value: '8385.HK',
    label: '8385.HK,  Prosperous Printing Company Limited',
    name: 'Prosperous Printing Company Limited',
    symbol: '8385.HK',
  },
  {
    value: '8391.HK',
    label: '8391.HK,  Cornerstone Technologies Holdings Limited',
    name: 'Cornerstone Technologies Holdings Limited',
    symbol: '8391.HK',
  },
  {
    value: '8392.HK',
    label: '8392.HK,  Satu Holdings Limited',
    name: 'Satu Holdings Limited',
    symbol: '8392.HK',
  },
  {
    value: '8395.HK',
    label: '8395.HK,  Tree Holdings Limited',
    name: 'Tree Holdings Limited',
    symbol: '8395.HK',
  },
  {
    value: '8400.HK',
    label: '8400.HK,  Asia Pioneer Entertainment Holdings Limited',
    name: 'Asia Pioneer Entertainment Holdings Limited',
    symbol: '8400.HK',
  },
  {
    value: '8401.HK',
    label: '8401.HK,  Stream Ideas Group Limited',
    name: 'Stream Ideas Group Limited',
    symbol: '8401.HK',
  },
  {
    value: '8403.HK',
    label: '8403.HK,  Dowway Holdings Limited',
    name: 'Dowway Holdings Limited',
    symbol: '8403.HK',
  },
  {
    value: '8405.HK',
    label: '8405.HK,  Hang Chi Holdings Limited',
    name: 'Hang Chi Holdings Limited',
    symbol: '8405.HK',
  },
  {
    value: '8406.HK',
    label: '8406.HK,  China Oral Industry Group Holdings Limited',
    name: 'China Oral Industry Group Holdings Limited',
    symbol: '8406.HK',
  },
  {
    value: '8411.HK',
    label: '8411.HK,  K W Nelson Interior Design and Contracting Group Limited',
    name: 'K W Nelson Interior Design and Contracting Group Limited',
    symbol: '8411.HK',
  },
  {
    value: '8412.HK',
    label: '8412.HK,  BCI Group Holdings Limited',
    name: 'BCI Group Holdings Limited',
    symbol: '8412.HK',
  },
  {
    value: '8413.HK',
    label: '8413.HK,  Asia Grocery Distribution Limited',
    name: 'Asia Grocery Distribution Limited',
    symbol: '8413.HK',
  },
  {
    value: '8416.HK',
    label: '8416.HK,  HM International Holdings Limited',
    name: 'HM International Holdings Limited',
    symbol: '8416.HK',
  },
  {
    value: '8417.HK',
    label: '8417.HK,  Dadi Education Holdings Limited',
    name: 'Dadi Education Holdings Limited',
    symbol: '8417.HK',
  },
  {
    value: '8418.HK',
    label: '8418.HK,  Optima Automobile Group Holdings Limited',
    name: 'Optima Automobile Group Holdings Limited',
    symbol: '8418.HK',
  },
  {
    value: '8419.HK',
    label: '8419.HK,  AV Promotions Holdings Limited',
    name: 'AV Promotions Holdings Limited',
    symbol: '8419.HK',
  },
  {
    value: '8420.HK',
    label: '8420.HK,  Nexion Technologies Limited',
    name: 'Nexion Technologies Limited',
    symbol: '8420.HK',
  },
  {
    value: '8422.HK',
    label: '8422.HK,  WT Group Holdings Limited',
    name: 'WT Group Holdings Limited',
    symbol: '8422.HK',
  },
  {
    value: '8423.HK',
    label: '8423.HK,  Chi Ho Development Holdings Limited',
    name: 'Chi Ho Development Holdings Limited',
    symbol: '8423.HK',
  },
  {
    value: '8425.HK',
    label: '8425.HK,  Hing Ming Holdings Limited',
    name: 'Hing Ming Holdings Limited',
    symbol: '8425.HK',
  },
  {
    value: '8426.HK',
    label: '8426.HK,  Modern Living Investments Holdings Limited',
    name: 'Modern Living Investments Holdings Limited',
    symbol: '8426.HK',
  },
  {
    value: '8427.HK',
    label: '8427.HK,  SK Target Group Limited',
    name: 'SK Target Group Limited',
    symbol: '8427.HK',
  },
  {
    value: '8428.HK',
    label: '8428.HK,  CBK Holdings Limited',
    name: 'CBK Holdings Limited',
    symbol: '8428.HK',
  },
  {
    value: '8429.HK',
    label: '8429.HK,  SV Vision Limited',
    name: 'SV Vision Limited',
    symbol: '8429.HK',
  },
  {
    value: '8430.HK',
    label: '8430.HK,  C&N Holdings Limited',
    name: 'C&N Holdings Limited',
    symbol: '8430.HK',
  },
  {
    value: '8431.HK',
    label: '8431.HK,  Hao Bai International (Cayman) Limited',
    name: 'Hao Bai International (Cayman) Limited',
    symbol: '8431.HK',
  },
  {
    value: '8432.HK',
    label: '8432.HK,  Bar Pacific Group Holdings Limited',
    name: 'Bar Pacific Group Holdings Limited',
    symbol: '8432.HK',
  },
  {
    value: '8436.HK',
    label: '8436.HK,  Takbo Group Holdings Limited',
    name: 'Takbo Group Holdings Limited',
    symbol: '8436.HK',
  },
  {
    value: '8437.HK',
    label: '8437.HK,  RMH Holdings Limited',
    name: 'RMH Holdings Limited',
    symbol: '8437.HK',
  },
  {
    value: '8439.HK',
    label: '8439.HK,  Somerley Capital Holdings Limited',
    name: 'Somerley Capital Holdings Limited',
    symbol: '8439.HK',
  },
  {
    value: '8445.HK',
    label: '8445.HK,  Noble Engineering Group Holdings Limited',
    name: 'Noble Engineering Group Holdings Limited',
    symbol: '8445.HK',
  },
  {
    value: '8446.HK',
    label: '8446.HK,  In Technical Productions Holdings Limited',
    name: 'In Technical Productions Holdings Limited',
    symbol: '8446.HK',
  },
  {
    value: '8447.HK',
    label: '8447.HK,  MS Concept Limited',
    name: 'MS Concept Limited',
    symbol: '8447.HK',
  },
  {
    value: '8448.HK',
    label: '8448.HK,  Universe Printshop Holdings Limited',
    name: 'Universe Printshop Holdings Limited',
    symbol: '8448.HK',
  },
  {
    value: '8450.HK',
    label: '8450.HK,  EDICO Holdings Limited',
    name: 'EDICO Holdings Limited',
    symbol: '8450.HK',
  },
  {
    value: '8451.HK',
    label: '8451.HK,  Sunlight (1977) Holdings Limited',
    name: 'Sunlight (1977) Holdings Limited',
    symbol: '8451.HK',
  },
  {
    value: '8452.HK',
    label: '8452.HK,  FY Financial (Shenzhen) Co., Ltd.',
    name: 'FY Financial (Shenzhen) Co., Ltd.',
    symbol: '8452.HK',
  },
  {
    value: '8455.HK',
    label: '8455.HK,  Lai Group Holding Company Limited',
    name: 'Lai Group Holding Company Limited',
    symbol: '8455.HK',
  },
  {
    value: '8456.HK',
    label: '8456.HK,  Mansion International Holdings Limited',
    name: 'Mansion International Holdings Limited',
    symbol: '8456.HK',
  },
  {
    value: '8460.HK',
    label: '8460.HK,  Basetrophy Group Holdings Limited',
    name: 'Basetrophy Group Holdings Limited',
    symbol: '8460.HK',
  },
  {
    value: '8462.HK',
    label: '8462.HK,  Omnibridge Holdings Limited',
    name: 'Omnibridge Holdings Limited',
    symbol: '8462.HK',
  },
  {
    value: '8471.HK',
    label: '8471.HK,  Reach New Holdings Limited',
    name: 'Reach New Holdings Limited',
    symbol: '8471.HK',
  },
  {
    value: '8472.HK',
    label: '8472.HK,  Lapco Holdings Limited',
    name: 'Lapco Holdings Limited',
    symbol: '8472.HK',
  },
  {
    value: '8473.HK',
    label: '8473.HK,  Mi Ming Mart Holdings Limited',
    name: 'Mi Ming Mart Holdings Limited',
    symbol: '8473.HK',
  },
  {
    value: '8475.HK',
    label: '8475.HK,  K Group Holdings Limited',
    name: 'K Group Holdings Limited',
    symbol: '8475.HK',
  },
  {
    value: '8476.HK',
    label: '8476.HK,  Ocean One Holding Ltd.',
    name: 'Ocean One Holding Ltd.',
    symbol: '8476.HK',
  },
  {
    value: '8479.HK',
    label: '8479.HK,  JTF International Holdings Limited',
    name: 'JTF International Holdings Limited',
    symbol: '8479.HK',
  },
  {
    value: '8480.HK',
    label: '8480.HK,  Furniweb Holdings Limited',
    name: 'Furniweb Holdings Limited',
    symbol: '8480.HK',
  },
  {
    value: '8482.HK',
    label: '8482.HK,  Wan Leader International Limited',
    name: 'Wan Leader International Limited',
    symbol: '8482.HK',
  },
  {
    value: '8483.HK',
    label: '8483.HK,  Max Sight Group Holdings Limited',
    name: 'Max Sight Group Holdings Limited',
    symbol: '8483.HK',
  },
  {
    value: '8487.HK',
    label: '8487.HK,  ISP Global Limited',
    name: 'ISP Global Limited',
    symbol: '8487.HK',
  },
  {
    value: '8490.HK',
    label: '8490.HK,  Niche-Tech Semiconductor Materials Limited',
    name: 'Niche-Tech Semiconductor Materials Limited',
    symbol: '8490.HK',
  },
  {
    value: '8491.HK',
    label: '8491.HK,  Cool Link (Holdings) Limited',
    name: 'Cool Link (Holdings) Limited',
    symbol: '8491.HK',
  },
  {
    value: '8493.HK',
    label: '8493.HK,  Dragon King Group Holdings Limited',
    name: 'Dragon King Group Holdings Limited',
    symbol: '8493.HK',
  },
  {
    value: '8495.HK',
    label: '8495.HK,  1957 & Co. (Hospitality) Limited',
    name: '1957 & Co. (Hospitality) Limited',
    symbol: '8495.HK',
  },
  {
    value: '8496.HK',
    label: '8496.HK,  Global Dining Holdings Limited',
    name: 'Global Dining Holdings Limited',
    symbol: '8496.HK',
  },
  {
    value: '8500.HK',
    label: '8500.HK,  Icon Culture Global Company Limited',
    name: 'Icon Culture Global Company Limited',
    symbol: '8500.HK',
  },
  {
    value: '8501.HK',
    label: '8501.HK,  Sanbase Corporation Limited',
    name: 'Sanbase Corporation Limited',
    symbol: '8501.HK',
  },
  {
    value: '8502.HK',
    label: '8502.HK,  Ocean Line Port Development Limited',
    name: 'Ocean Line Port Development Limited',
    symbol: '8502.HK',
  },
  {
    value: '8507.HK',
    label: '8507.HK,  i.century Holding Limited',
    name: 'i.century Holding Limited',
    symbol: '8507.HK',
  },
  {
    value: '8509.HK',
    label: "8509.HK,  Wine's Link International Holdings Limited",
    name: "Wine's Link International Holdings Limited",
    symbol: '8509.HK',
  },
  {
    value: '8510.HK',
    label: '8510.HK,  Top Standard Corporation',
    name: 'Top Standard Corporation',
    symbol: '8510.HK',
  },
  {
    value: '8511.HK',
    label: '8511.HK,  Min Fu International Holding Limited',
    name: 'Min Fu International Holding Limited',
    symbol: '8511.HK',
  },
  {
    value: '8512.HK',
    label: '8512.HK,  Hyfusin Group Holdings Limited',
    name: 'Hyfusin Group Holdings Limited',
    symbol: '8512.HK',
  },
  {
    value: '8513.HK',
    label: '8513.HK,  IAG Holdings Limited',
    name: 'IAG Holdings Limited',
    symbol: '8513.HK',
  },
  {
    value: '8516.HK',
    label: '8516.HK,  Grand Talents Group Holdings Limited',
    name: 'Grand Talents Group Holdings Limited',
    symbol: '8516.HK',
  },
  {
    value: '8519.HK',
    label: '8519.HK,  Jia Group Holdings Limited',
    name: 'Jia Group Holdings Limited',
    symbol: '8519.HK',
  },
  {
    value: '8521.HK',
    label: '8521.HK,  ST International Holdings Company Limited',
    name: 'ST International Holdings Company Limited',
    symbol: '8521.HK',
  },
  {
    value: '8525.HK',
    label: '8525.HK,  Baiying Holdings Group Limited',
    name: 'Baiying Holdings Group Limited',
    symbol: '8525.HK',
  },
  {
    value: '8526.HK',
    label: '8526.HK,  Wing Fung Group Asia Limited',
    name: 'Wing Fung Group Asia Limited',
    symbol: '8526.HK',
  },
  {
    value: '8527.HK',
    label: '8527.HK,  JLogo Holdings Limited',
    name: 'JLogo Holdings Limited',
    symbol: '8527.HK',
  },
  {
    value: '8532.HK',
    label: '8532.HK,  Polyfair Holdings Limited',
    name: 'Polyfair Holdings Limited',
    symbol: '8532.HK',
  },
  {
    value: '8535.HK',
    label: '8535.HK,  Vistar Holdings Limited',
    name: 'Vistar Holdings Limited',
    symbol: '8535.HK',
  },
  {
    value: '8536.HK',
    label: '8536.HK,  TL Natural Gas Holdings Limited',
    name: 'TL Natural Gas Holdings Limited',
    symbol: '8536.HK',
  },
  {
    value: '8537.HK',
    label: '8537.HK,  Chong Fai Jewellery Group Holdings Company Limited',
    name: 'Chong Fai Jewellery Group Holdings Company Limited',
    symbol: '8537.HK',
  },
  {
    value: '8540.HK',
    label: '8540.HK,  Victory Securities (Holdings) Company Limited',
    name: 'Victory Securities (Holdings) Company Limited',
    symbol: '8540.HK',
  },
  {
    value: '8545.HK',
    label: '8545.HK,  Amuse Group Holding Limited',
    name: 'Amuse Group Holding Limited',
    symbol: '8545.HK',
  },
  {
    value: '8547.HK',
    label: '8547.HK,  Pacific Legend Group Limited',
    name: 'Pacific Legend Group Limited',
    symbol: '8547.HK',
  },
  {
    value: '8601.HK',
    label: '8601.HK,  Boltek Holdings Limited',
    name: 'Boltek Holdings Limited',
    symbol: '8601.HK',
  },
  {
    value: '8603.HK',
    label: '8603.HK,  Fameglow Holdings Limited',
    name: 'Fameglow Holdings Limited',
    symbol: '8603.HK',
  },
  {
    value: '8606.HK',
    label: '8606.HK,  Kinetix Systems Holdings Limited',
    name: 'Kinetix Systems Holdings Limited',
    symbol: '8606.HK',
  },
  {
    value: '8607.HK',
    label: '8607.HK,  Narnia (Hong Kong) Group Company Limited',
    name: 'Narnia (Hong Kong) Group Company Limited',
    symbol: '8607.HK',
  },
  {
    value: '8609.HK',
    label: '8609.HK,  Eggriculture Foods Ltd.',
    name: 'Eggriculture Foods Ltd.',
    symbol: '8609.HK',
  },
  {
    value: '8611.HK',
    label: '8611.HK,  Mindtell Technology Limited',
    name: 'Mindtell Technology Limited',
    symbol: '8611.HK',
  },
  {
    value: '8612.HK',
    label: '8612.HK,  World Super Holdings Limited',
    name: 'World Super Holdings Limited',
    symbol: '8612.HK',
  },
  {
    value: '8613.HK',
    label: '8613.HK,  Oriental Payment Group Holdings Limited',
    name: 'Oriental Payment Group Holdings Limited',
    symbol: '8613.HK',
  },
  {
    value: '8616.HK',
    label: '8616.HK,  Sunray Engineering Group Limited',
    name: 'Sunray Engineering Group Limited',
    symbol: '8616.HK',
  },
  {
    value: '8617.HK',
    label: '8617.HK,  Best Linking Group Holdings Limited',
    name: 'Best Linking Group Holdings Limited',
    symbol: '8617.HK',
  },
  {
    value: '8619.HK',
    label: '8619.HK,  WAC Holdings Limited',
    name: 'WAC Holdings Limited',
    symbol: '8619.HK',
  },
  {
    value: '8620.HK',
    label: '8620.HK,  Asia-express Logistics Holdings Limited',
    name: 'Asia-express Logistics Holdings Limited',
    symbol: '8620.HK',
  },
  {
    value: '8621.HK',
    label: '8621.HK,  Metropolis Capital Holdings Limited',
    name: 'Metropolis Capital Holdings Limited',
    symbol: '8621.HK',
  },
  {
    value: '8622.HK',
    label: '8622.HK,  Huakang Biomedical Holdings Company Limited',
    name: 'Huakang Biomedical Holdings Company Limited',
    symbol: '8622.HK',
  },
  {
    value: '8627.HK',
    label: '8627.HK,  Orange Tour Cultural Holding Limited',
    name: 'Orange Tour Cultural Holding Limited',
    symbol: '8627.HK',
  },
  {
    value: '8631.HK',
    label: '8631.HK,  Sun Kong Holdings Limited',
    name: 'Sun Kong Holdings Limited',
    symbol: '8631.HK',
  },
  {
    value: '8635.HK',
    label: '8635.HK,  Novacon Technology Group Limited',
    name: 'Novacon Technology Group Limited',
    symbol: '8635.HK',
  },
  {
    value: '8645.HK',
    label: '8645.HK,  Michong Metaverse (China) Holdings Group Limited',
    name: 'Michong Metaverse (China) Holdings Group Limited',
    symbol: '8645.HK',
  },
  {
    value: '8646.HK',
    label: '8646.HK,  China Hongguang Holdings Limited',
    name: 'China Hongguang Holdings Limited',
    symbol: '8646.HK',
  },
  {
    value: '8668.HK',
    label: '8668.HK,  Ying Hai Group Holdings Company Limited',
    name: 'Ying Hai Group Holdings Company Limited',
    symbol: '8668.HK',
  },
  {
    value: '9618.HK',
    label: '9618.HK,  JD.com, Inc.',
    name: 'JD.com, Inc.',
    symbol: '9618.HK',
  },
  {
    value: '9900.HK',
    label: '9900.HK,  Gain Plus Holdings Limited',
    name: 'Gain Plus Holdings Limited',
    symbol: '9900.HK',
  },
  {
    value: '9909.HK',
    label: '9909.HK,  Powerlong Commercial Management Holdings Limited',
    name: 'Powerlong Commercial Management Holdings Limited',
    symbol: '9909.HK',
  },
  {
    value: '9911.HK',
    label: '9911.HK,  Newborn Town Inc.',
    name: 'Newborn Town Inc.',
    symbol: '9911.HK',
  },
  {
    value: '9916.HK',
    label: '9916.HK,  Xingye Wulian Service Group Co. Ltd.',
    name: 'Xingye Wulian Service Group Co. Ltd.',
    symbol: '9916.HK',
  },
  {
    value: '9918.HK',
    label: '9918.HK,  Wise Ally International Holdings Limited',
    name: 'Wise Ally International Holdings Limited',
    symbol: '9918.HK',
  },
  {
    value: '9919.HK',
    label: '9919.HK,  Activation Group Holdings Limited',
    name: 'Activation Group Holdings Limited',
    symbol: '9919.HK',
  },
  {
    value: '9922.HK',
    label: '9922.HK,  Jiumaojiu International Holdings Limited',
    name: 'Jiumaojiu International Holdings Limited',
    symbol: '9922.HK',
  },
  {
    value: '9923.HK',
    label: '9923.HK,  Yeahka Limited',
    name: 'Yeahka Limited',
    symbol: '9923.HK',
  },
  {
    value: '9926.HK',
    label: '9926.HK,  Akeso, Inc.',
    name: 'Akeso, Inc.',
    symbol: '9926.HK',
  },
  {
    value: '9928.HK',
    label: '9928.HK,  Times Neighborhood Holdings Limited',
    name: 'Times Neighborhood Holdings Limited',
    symbol: '9928.HK',
  },
  {
    value: '9929.HK',
    label: '9929.HK,  SEM Holdings Limited',
    name: 'SEM Holdings Limited',
    symbol: '9929.HK',
  },
  {
    value: '9933.HK',
    label: '9933.HK,  GHW International',
    name: 'GHW International',
    symbol: '9933.HK',
  },
  {
    value: '9936.HK',
    label: '9936.HK,  Ximei Resources Holding Limited',
    name: 'Ximei Resources Holding Limited',
    symbol: '9936.HK',
  },
  {
    value: '9938.HK',
    label: '9938.HK,  Wah Wo Holdings Group Limited',
    name: 'Wah Wo Holdings Group Limited',
    symbol: '9938.HK',
  },
  {
    value: '9939.HK',
    label: '9939.HK,  Kintor Pharmaceutical Limited',
    name: 'Kintor Pharmaceutical Limited',
    symbol: '9939.HK',
  },
  {
    value: '9958.HK',
    label: '9958.HK,  Litian Pictures Holdings Limited',
    name: 'Litian Pictures Holdings Limited',
    symbol: '9958.HK',
  },
  {
    value: '9966.HK',
    label: '9966.HK,  Alphamab Oncology',
    name: 'Alphamab Oncology',
    symbol: '9966.HK',
  },
  {
    value: '9968.HK',
    label: '9968.HK,  Huijing Holdings Company Limited',
    name: 'Huijing Holdings Company Limited',
    symbol: '9968.HK',
  },
  {
    value: '9969.HK',
    label: '9969.HK,  InnoCare Pharma Limited',
    name: 'InnoCare Pharma Limited',
    symbol: '9969.HK',
  },
  {
    value: '9978.HK',
    label: '9978.HK,  Fineland Living Services Group Limited',
    name: 'Fineland Living Services Group Limited',
    symbol: '9978.HK',
  },
  {
    value: '9983.HK',
    label: '9983.HK,  Central China New Life Limited',
    name: 'Central China New Life Limited',
    symbol: '9983.HK',
  },
  {
    value: '9996.HK',
    label: '9996.HK,  Peijia Medical Limited',
    name: 'Peijia Medical Limited',
    symbol: '9996.HK',
  },
  {
    value: '9997.HK',
    label: '9997.HK,  Kangji Medical Holdings Limited',
    name: 'Kangji Medical Holdings Limited',
    symbol: '9997.HK',
  },
  {
    value: '9998.HK',
    label: '9998.HK,  Kwan Yong Holdings Limited',
    name: 'Kwan Yong Holdings Limited',
    symbol: '9998.HK',
  },
  {
    value: '9999.HK',
    label: '9999.HK,  NetEase, Inc.',
    name: 'NetEase, Inc.',
    symbol: '9999.HK',
  },
  {
    value: '87001.HK',
    label: '87001.HK,  Hui Xian Real Estate Investment Trust',
    name: 'Hui Xian Real Estate Investment Trust',
    symbol: '87001.HK',
  },
  {
    value: '1708.HK',
    label: '1708.HK,  Nanjing Sample Technology Company Limited',
    name: 'Nanjing Sample Technology Company Limited',
    symbol: '1708.HK',
  },
  {
    value: 'AFK.OL',
    label: 'AFK.OL,  Arendals Fossekompani ASA',
    name: 'Arendals Fossekompani ASA',
    symbol: 'AFK.OL',
  },
  {
    value: 'NRC.OL',
    label: 'NRC.OL,  NRC Group ASA',
    name: 'NRC Group ASA',
    symbol: 'NRC.OL',
  },
  {
    value: 'BOR.OL',
    label: 'BOR.OL,  Borgestad ASA',
    name: 'Borgestad ASA',
    symbol: 'BOR.OL',
  },
  {
    value: 'DNB.OL',
    label: 'DNB.OL,  DNB Bank ASA',
    name: 'DNB Bank ASA',
    symbol: 'DNB.OL',
  },
  {
    value: 'DNO.OL',
    label: 'DNO.OL,  DNO ASA',
    name: 'DNO ASA',
    symbol: 'DNO.OL',
  },
  {
    value: 'SPOG.OL',
    label: 'SPOG.OL,  Sparebanken Øst',
    name: 'Sparebanken Øst',
    symbol: 'SPOG.OL',
  },
  {
    value: 'GOD.OL',
    label: 'GOD.OL,  Goodtech ASA',
    name: 'Goodtech ASA',
    symbol: 'GOD.OL',
  },
  {
    value: 'GYL.OL',
    label: 'GYL.OL,  Gyldendal ASA',
    name: 'Gyldendal ASA',
    symbol: 'GYL.OL',
  },
  {
    value: 'ATEA.OL',
    label: 'ATEA.OL,  Atea ASA',
    name: 'Atea ASA',
    symbol: 'ATEA.OL',
  },
  {
    value: 'MORG.OL',
    label: 'MORG.OL,  Sparebanken Møre',
    name: 'Sparebanken Møre',
    symbol: 'MORG.OL',
  },
  {
    value: 'NTS.OL',
    label: 'NTS.OL,  NTS ASA',
    name: 'NTS ASA',
    symbol: 'NTS.OL',
  },
  {
    value: 'KOG.OL',
    label: 'KOG.OL,  Kongsberg Gruppen ASA',
    name: 'Kongsberg Gruppen ASA',
    symbol: 'KOG.OL',
  },
  {
    value: 'NHY.OL',
    label: 'NHY.OL,  Norsk Hydro ASA',
    name: 'Norsk Hydro ASA',
    symbol: 'NHY.OL',
  },
  {
    value: 'REACH.OL',
    label: 'REACH.OL,  Reach Subsea ASA',
    name: 'Reach Subsea ASA',
    symbol: 'REACH.OL',
  },
  {
    value: 'OLT.OL',
    label: 'OLT.OL,  Olav Thon Eiendomsselskap ASA',
    name: 'Olav Thon Eiendomsselskap ASA',
    symbol: 'OLT.OL',
  },
  {
    value: 'ORK.OL',
    label: 'ORK.OL,  Orkla ASA',
    name: 'Orkla ASA',
    symbol: 'ORK.OL',
  },
  {
    value: 'PGS.OL',
    label: 'PGS.OL,  PGS ASA',
    name: 'PGS ASA',
    symbol: 'PGS.OL',
  },
  {
    value: 'SCHA.OL',
    label: 'SCHA.OL,  Schibsted ASA',
    name: 'Schibsted ASA',
    symbol: 'SCHA.OL',
  },
  {
    value: 'ODF.OL',
    label: 'ODF.OL,  Odfjell SE',
    name: 'Odfjell SE',
    symbol: 'ODF.OL',
  },
  {
    value: 'TOM.OL',
    label: 'TOM.OL,  Tomra Systems ASA',
    name: 'Tomra Systems ASA',
    symbol: 'TOM.OL',
  },
  {
    value: 'STB.OL',
    label: 'STB.OL,  Storebrand ASA',
    name: 'Storebrand ASA',
    symbol: 'STB.OL',
  },
  {
    value: 'VEI.OL',
    label: 'VEI.OL,  Veidekke ASA',
    name: 'Veidekke ASA',
    symbol: 'VEI.OL',
  },
  {
    value: 'VVL.OL',
    label: 'VVL.OL,  Voss Veksel- og Landmandsbank ASA',
    name: 'Voss Veksel- og Landmandsbank ASA',
    symbol: 'VVL.OL',
  },
  {
    value: 'WWI.OL',
    label: 'WWI.OL,  Wilh. Wilhelmsen Holding ASA',
    name: 'Wilh. Wilhelmsen Holding ASA',
    symbol: 'WWI.OL',
  },
  {
    value: 'MING.OL',
    label: 'MING.OL,  SpareBank 1 SMN',
    name: 'SpareBank 1 SMN',
    symbol: 'MING.OL',
  },
  {
    value: 'NONG.OL',
    label: 'NONG.OL,  SpareBank 1 Nord-Norge',
    name: 'SpareBank 1 Nord-Norge',
    symbol: 'NONG.OL',
  },
  {
    value: 'JIN.OL',
    label: 'JIN.OL,  Jinhui Shipping and Transportation Limited',
    name: 'Jinhui Shipping and Transportation Limited',
    symbol: 'JIN.OL',
  },
  {
    value: 'EQNR.OL',
    label: 'EQNR.OL,  Equinor ASA',
    name: 'Equinor ASA',
    symbol: 'EQNR.OL',
  },
  {
    value: 'SVEG.OL',
    label: 'SVEG.OL,  Sparebanken Vest',
    name: 'Sparebanken Vest',
    symbol: 'SVEG.OL',
  },
  {
    value: 'NKR.OL',
    label: 'NKR.OL,  Nekkar ASA',
    name: 'Nekkar ASA',
    symbol: 'NKR.OL',
  },
  {
    value: 'SADG.OL',
    label: 'SADG.OL,  Sandnes Sparebank',
    name: 'Sandnes Sparebank',
    symbol: 'SADG.OL',
  },
  {
    value: 'TOTG.OL',
    label: 'TOTG.OL,  Totens Sparebank',
    name: 'Totens Sparebank',
    symbol: 'TOTG.OL',
  },
  {
    value: 'SCANA.OL',
    label: 'SCANA.OL,  Scana ASA',
    name: 'Scana ASA',
    symbol: 'SCANA.OL',
  },
  {
    value: 'MOWI.OL',
    label: 'MOWI.OL,  Mowi ASA',
    name: 'Mowi ASA',
    symbol: 'MOWI.OL',
  },
  {
    value: 'SNI.OL',
    label: 'SNI.OL,  Stolt-Nielsen Limited',
    name: 'Stolt-Nielsen Limited',
    symbol: 'SNI.OL',
  },
  {
    value: 'NOD.OL',
    label: 'NOD.OL,  Nordic Semiconductor ASA',
    name: 'Nordic Semiconductor ASA',
    symbol: 'NOD.OL',
  },
  {
    value: 'RING.OL',
    label: 'RING.OL,  SpareBank 1 Ringerike Hadeland',
    name: 'SpareBank 1 Ringerike Hadeland',
    symbol: 'RING.OL',
  },
  {
    value: 'SOGN.OL',
    label: 'SOGN.OL,  Sogn Sparebank',
    name: 'Sogn Sparebank',
    symbol: 'SOGN.OL',
  },
  {
    value: 'HEX.OL',
    label: 'HEX.OL,  Hexagon Composites ASA',
    name: 'Hexagon Composites ASA',
    symbol: 'HEX.OL',
  },
  {
    value: 'PRS.OL',
    label: 'PRS.OL,  Prosafe SE',
    name: 'Prosafe SE',
    symbol: 'PRS.OL',
  },
  {
    value: 'PSE.OL',
    label: 'PSE.OL,  Petrolia SE',
    name: 'Petrolia SE',
    symbol: 'PSE.OL',
  },
  {
    value: 'SUBC.OL',
    label: 'SUBC.OL,  Subsea 7 S.A.',
    name: 'Subsea 7 S.A.',
    symbol: 'SUBC.OL',
  },
  {
    value: 'BMA.OL',
    label: 'BMA.OL,  Byggma ASA',
    name: 'Byggma ASA',
    symbol: 'BMA.OL',
  },
  {
    value: 'AFG.OL',
    label: 'AFG.OL,  AF Gruppen ASA',
    name: 'AF Gruppen ASA',
    symbol: 'AFG.OL',
  },
  {
    value: 'SOFF.OL',
    label: 'SOFF.OL,  Solstad Offshore ASA',
    name: 'Solstad Offshore ASA',
    symbol: 'SOFF.OL',
  },
  {
    value: 'TGS.OL',
    label: 'TGS.OL,  TGS ASA',
    name: 'TGS ASA',
    symbol: 'TGS.OL',
  },
  {
    value: 'KIT.OL',
    label: 'KIT.OL,  Kitron ASA',
    name: 'Kitron ASA',
    symbol: 'KIT.OL',
  },
  {
    value: 'SOR.OL',
    label: 'SOR.OL,  Sparebanken Sør',
    name: 'Sparebanken Sør',
    symbol: 'SOR.OL',
  },
  {
    value: 'AURG.OL',
    label: 'AURG.OL,  Aurskog Sparebank',
    name: 'Aurskog Sparebank',
    symbol: 'AURG.OL',
  },
  {
    value: 'SKUE.OL',
    label: 'SKUE.OL,  Skue Sparebank',
    name: 'Skue Sparebank',
    symbol: 'SKUE.OL',
  },
  {
    value: 'MELG.OL',
    label: 'MELG.OL,  Melhus Sparebank',
    name: 'Melhus Sparebank',
    symbol: 'MELG.OL',
  },
  {
    value: 'FRO.OL',
    label: 'FRO.OL,  Frontline Ltd.',
    name: 'Frontline Ltd.',
    symbol: 'FRO.OL',
  },
  {
    value: 'HSPG.OL',
    label: 'HSPG.OL,  Høland og Setskog Sparebank',
    name: 'Høland og Setskog Sparebank',
    symbol: 'HSPG.OL',
  },
  {
    value: 'HELG.OL',
    label: 'HELG.OL,  SpareBank 1 Helgeland',
    name: 'SpareBank 1 Helgeland',
    symbol: 'HELG.OL',
  },
  {
    value: 'PHO.OL',
    label: 'PHO.OL,  Photocure ASA',
    name: 'Photocure ASA',
    symbol: 'PHO.OL',
  },
  {
    value: 'DOF.OL',
    label: 'DOF.OL,  DOF ASA',
    name: 'DOF ASA',
    symbol: 'DOF.OL',
  },
  {
    value: 'TEL.OL',
    label: 'TEL.OL,  Telenor ASA',
    name: 'Telenor ASA',
    symbol: 'TEL.OL',
  },
  {
    value: 'QFR.OL',
    label: 'QFR.OL,  Q-Free ASA',
    name: 'Q-Free ASA',
    symbol: 'QFR.OL',
  },
  {
    value: 'CARA.OL',
    label: 'CARA.OL,  Carasent ASA',
    name: 'Carasent ASA',
    symbol: 'CARA.OL',
  },
  {
    value: 'LSG.OL',
    label: 'LSG.OL,  Lerøy Seafood Group ASA',
    name: 'Lerøy Seafood Group ASA',
    symbol: 'LSG.OL',
  },
  {
    value: 'TECH.OL',
    label: 'TECH.OL,  Techstep ASA',
    name: 'Techstep ASA',
    symbol: 'TECH.OL',
  },
  {
    value: 'NAS.OL',
    label: 'NAS.OL,  Norwegian Air Shuttle ASA',
    name: 'Norwegian Air Shuttle ASA',
    symbol: 'NAS.OL',
  },
  {
    value: 'YAR.OL',
    label: 'YAR.OL,  Yara International ASA',
    name: 'Yara International ASA',
    symbol: 'YAR.OL',
  },
  {
    value: 'MEDI.OL',
    label: 'MEDI.OL,  Medistim ASA',
    name: 'Medistim ASA',
    symbol: 'MEDI.OL',
  },
  {
    value: 'NEL.OL',
    label: 'NEL.OL,  Nel ASA',
    name: 'Nel ASA',
    symbol: 'NEL.OL',
  },
  {
    value: 'AKER.OL',
    label: 'AKER.OL,  Aker ASA',
    name: 'Aker ASA',
    symbol: 'AKER.OL',
  },
  {
    value: 'MGN.OL',
    label: 'MGN.OL,  Magnora ASA',
    name: 'Magnora ASA',
    symbol: 'MGN.OL',
  },
  {
    value: 'GIG.OL',
    label: 'GIG.OL,  Gaming Innovation Group Inc.',
    name: 'Gaming Innovation Group Inc.',
    symbol: 'GIG.OL',
  },
  {
    value: 'RISH.OL',
    label: 'RISH.OL,  GC Rieber Shipping ASA',
    name: 'GC Rieber Shipping ASA',
    symbol: 'RISH.OL',
  },
  {
    value: 'WILS.OL',
    label: 'WILS.OL,  Wilson ASA',
    name: 'Wilson ASA',
    symbol: 'WILS.OL',
  },
  {
    value: 'HAVI.OL',
    label: 'HAVI.OL,  Havila Shipping ASA',
    name: 'Havila Shipping ASA',
    symbol: 'HAVI.OL',
  },
  {
    value: 'QEC.OL',
    label: 'QEC.OL,  Questerre Energy Corporation',
    name: 'Questerre Energy Corporation',
    symbol: 'QEC.OL',
  },
  {
    value: 'KOA.OL',
    label: 'KOA.OL,  Kongsberg Automotive ASA',
    name: 'Kongsberg Automotive ASA',
    symbol: 'KOA.OL',
  },
  {
    value: 'EIOF.OL',
    label: 'EIOF.OL,  Eidesvik Offshore ASA',
    name: 'Eidesvik Offshore ASA',
    symbol: 'EIOF.OL',
  },
  {
    value: 'AMSC.OL',
    label: 'AMSC.OL,  AMSC ASA',
    name: 'AMSC ASA',
    symbol: 'AMSC.OL',
  },
  {
    value: 'SIOFF.OL',
    label: 'SIOFF.OL,  Siem Offshore Inc.',
    name: 'Siem Offshore Inc.',
    symbol: 'SIOFF.OL',
  },
  {
    value: 'AZT.OL',
    label: 'AZT.OL,  ArcticZymes Technologies ASA',
    name: 'ArcticZymes Technologies ASA',
    symbol: 'AZT.OL',
  },
  {
    value: 'SOAG.OL',
    label: 'SOAG.OL,  SpareBank 1 Østfold Akershus',
    name: 'SpareBank 1 Østfold Akershus',
    symbol: 'SOAG.OL',
  },
  {
    value: 'SBX.OL',
    label: 'SBX.OL,  SeaBird Exploration Plc',
    name: 'SeaBird Exploration Plc',
    symbol: 'SBX.OL',
  },
  {
    value: 'NAVA.OL',
    label: 'NAVA.OL,  Navamedic ASA',
    name: 'Navamedic ASA',
    symbol: 'NAVA.OL',
  },
  {
    value: 'BWO.OL',
    label: 'BWO.OL,  BW Offshore Limited',
    name: 'BW Offshore Limited',
    symbol: 'BWO.OL',
  },
  {
    value: 'IOX.OL',
    label: 'IOX.OL,  Interoil Exploration and Production ASA',
    name: 'Interoil Exploration and Production ASA',
    symbol: 'IOX.OL',
  },
  {
    value: 'AUSS.OL',
    label: 'AUSS.OL,  Austevoll Seafood ASA',
    name: 'Austevoll Seafood ASA',
    symbol: 'AUSS.OL',
  },
  {
    value: 'AKVA.OL',
    label: 'AKVA.OL,  AKVA group ASA',
    name: 'AKVA group ASA',
    symbol: 'AKVA.OL',
  },
  {
    value: 'OTS.OL',
    label: 'OTS.OL,  Oceanteam ASA',
    name: 'Oceanteam ASA',
    symbol: 'OTS.OL',
  },
  {
    value: 'EMGS.OL',
    label: 'EMGS.OL,  Electromagnetic Geoservices ASA',
    name: 'Electromagnetic Geoservices ASA',
    symbol: 'EMGS.OL',
  },
  {
    value: 'SALM.OL',
    label: 'SALM.OL,  SalMar ASA',
    name: 'SalMar ASA',
    symbol: 'SALM.OL',
  },
  {
    value: 'GSF.OL',
    label: 'GSF.OL,  Grieg Seafood ASA',
    name: 'Grieg Seafood ASA',
    symbol: 'GSF.OL',
  },
  {
    value: 'NOR.OL',
    label: 'NOR.OL,  Norwegian Energy Company ASA',
    name: 'Norwegian Energy Company ASA',
    symbol: 'NOR.OL',
  },
  {
    value: 'ENDUR.OL',
    label: 'ENDUR.OL,  Endúr ASA',
    name: 'Endúr ASA',
    symbol: 'ENDUR.OL',
  },
  {
    value: 'PCIB.OL',
    label: 'PCIB.OL,  PCI Biotech Holding ASA',
    name: 'PCI Biotech Holding ASA',
    symbol: 'PCIB.OL',
  },
  {
    value: 'POL.OL',
    label: 'POL.OL,  Polaris Media ASA',
    name: 'Polaris Media ASA',
    symbol: 'POL.OL',
  },
  {
    value: 'FLNG.OL',
    label: 'FLNG.OL,  FLEX LNG Ltd.',
    name: 'FLEX LNG Ltd.',
    symbol: 'FLNG.OL',
  },
  {
    value: 'IDEX.OL',
    label: 'IDEX.OL,  IDEX Biometrics ASA',
    name: 'IDEX Biometrics ASA',
    symbol: 'IDEX.OL',
  },
  {
    value: 'PEN.OL',
    label: 'PEN.OL,  Panoro Energy ASA',
    name: 'Panoro Energy ASA',
    symbol: 'PEN.OL',
  },
  {
    value: 'BAKKA.OL',
    label: 'BAKKA.OL,  P/F Bakkafrost',
    name: 'P/F Bakkafrost',
    symbol: 'BAKKA.OL',
  },
  {
    value: 'GJF.OL',
    label: 'GJF.OL,  Gjensidige Forsikring ASA',
    name: 'Gjensidige Forsikring ASA',
    symbol: 'GJF.OL',
  },
  {
    value: 'SDSD.OL',
    label: 'SDSD.OL,  S.D. Standard ETC Plc',
    name: 'S.D. Standard ETC Plc',
    symbol: 'SDSD.OL',
  },
  {
    value: 'NRS.OL',
    label: 'NRS.OL,  Norway Royal Salmon AS',
    name: 'Norway Royal Salmon AS',
    symbol: 'NRS.OL',
  },
  {
    value: 'AWDR.OL',
    label: 'AWDR.OL,  Awilco Drilling PLC',
    name: 'Awilco Drilling PLC',
    symbol: 'AWDR.OL',
  },
  {
    value: 'SBO.OL',
    label: 'SBO.OL,  Selvaag Bolig ASA',
    name: 'Selvaag Bolig ASA',
    symbol: 'SBO.OL',
  },
  {
    value: 'BRG.OL',
    label: 'BRG.OL,  Borregaard ASA',
    name: 'Borregaard ASA',
    symbol: 'BRG.OL',
  },
  {
    value: 'ODL.OL',
    label: 'ODL.OL,  Odfjell Drilling Ltd.',
    name: 'Odfjell Drilling Ltd.',
    symbol: 'ODL.OL',
  },
  {
    value: 'BWLPG.OL',
    label: 'BWLPG.OL,  BW LPG Limited',
    name: 'BW LPG Limited',
    symbol: 'BWLPG.OL',
  },
  {
    value: 'NAPA.OL',
    label: 'NAPA.OL,  Napatech A/S',
    name: 'Napatech A/S',
    symbol: 'NAPA.OL',
  },
  {
    value: 'VOW.OL',
    label: 'VOW.OL,  Vow ASA',
    name: 'Vow ASA',
    symbol: 'VOW.OL',
  },
  {
    value: 'MSEIS.OL',
    label: 'MSEIS.OL,  Magseis Fairfield ASA',
    name: 'Magseis Fairfield ASA',
    symbol: 'MSEIS.OL',
  },
  {
    value: 'ZAL.OL',
    label: 'ZAL.OL,  Zalaris ASA',
    name: 'Zalaris ASA',
    symbol: 'ZAL.OL',
  },
  {
    value: 'NEXT.OL',
    label: 'NEXT.OL,  NEXT Biometrics Group ASA',
    name: 'NEXT Biometrics Group ASA',
    symbol: 'NEXT.OL',
  },
  {
    value: 'HYARD.OL',
    label: 'HYARD.OL,  Havyard Group ASA',
    name: 'Havyard Group ASA',
    symbol: 'HYARD.OL',
  },
  {
    value: 'AKSO.OL',
    label: 'AKSO.OL,  Aker Solutions ASA',
    name: 'Aker Solutions ASA',
    symbol: 'AKSO.OL',
  },
  {
    value: 'XXL.OL',
    label: 'XXL.OL,  XXL ASA',
    name: 'XXL ASA',
    symbol: 'XXL.OL',
  },
  {
    value: 'ENTRA.OL',
    label: 'ENTRA.OL,  Entra ASA',
    name: 'Entra ASA',
    symbol: 'ENTRA.OL',
  },
  {
    value: 'GOGL.OL',
    label: 'GOGL.OL,  Golden Ocean Group Limited',
    name: 'Golden Ocean Group Limited',
    symbol: 'GOGL.OL',
  },
  {
    value: 'MULTI.OL',
    label: 'MULTI.OL,  Multiconsult ASA',
    name: 'Multiconsult ASA',
    symbol: 'MULTI.OL',
  },
  {
    value: 'EPR.OL',
    label: 'EPR.OL,  Europris ASA',
    name: 'Europris ASA',
    symbol: 'EPR.OL',
  },
  {
    value: 'KID.OL',
    label: 'KID.OL,  Kid ASA',
    name: 'Kid ASA',
    symbol: 'KID.OL',
  },
  {
    value: 'PARB.OL',
    label: 'PARB.OL,  Pareto Bank ASA',
    name: 'Pareto Bank ASA',
    symbol: 'PARB.OL',
  },
  {
    value: 'TRE.OL',
    label: 'TRE.OL,  Treasure ASA',
    name: 'Treasure ASA',
    symbol: 'TRE.OL',
  },
  {
    value: 'B2H.OL',
    label: 'B2H.OL,  B2Holding ASA',
    name: 'B2Holding ASA',
    symbol: 'B2H.OL',
  },
  {
    value: 'TRVX.OL',
    label: 'TRVX.OL,  Targovax ASA',
    name: 'Targovax ASA',
    symbol: 'TRVX.OL',
  },
  {
    value: 'BGBIO.OL',
    label: 'BGBIO.OL,  BerGenBio ASA',
    name: 'BerGenBio ASA',
    symbol: 'BGBIO.OL',
  },
  {
    value: 'MPCC.OL',
    label: 'MPCC.OL,  MPC Container Ships ASA',
    name: 'MPC Container Ships ASA',
    symbol: 'MPCC.OL',
  },
  {
    value: 'SPOL.OL',
    label: 'SPOL.OL,  SpareBank 1 Ostlandet',
    name: 'SpareBank 1 Ostlandet',
    symbol: 'SPOL.OL',
  },
  {
    value: 'SNOR.OL',
    label: 'SNOR.OL,  SpareBank 1 Nordmøre',
    name: 'SpareBank 1 Nordmøre',
    symbol: 'SNOR.OL',
  },
  {
    value: 'WSTEP.OL',
    label: 'WSTEP.OL,  Webstep ASA',
    name: 'Webstep ASA',
    symbol: 'WSTEP.OL',
  },
  {
    value: 'NODL.OL',
    label: 'NODL.OL,  Northern Drilling Ltd.',
    name: 'Northern Drilling Ltd.',
    symbol: 'NODL.OL',
  },
  {
    value: 'SSG.OL',
    label: 'SSG.OL,  Self Storage Group ASA',
    name: 'Self Storage Group ASA',
    symbol: 'SSG.OL',
  },
  {
    value: 'KOMP.OL',
    label: 'KOMP.OL,  Komplett Bank ASA',
    name: 'Komplett Bank ASA',
    symbol: 'KOMP.OL',
  },
  {
    value: 'ELK.OL',
    label: 'ELK.OL,  Elkem ASA',
    name: 'Elkem ASA',
    symbol: 'ELK.OL',
  },
  {
    value: 'ASA.OL',
    label: 'ASA.OL,  Atlantic Sapphire ASA',
    name: 'Atlantic Sapphire ASA',
    symbol: 'ASA.OL',
  },
  {
    value: 'SHLF.OL',
    label: 'SHLF.OL,  Shelf Drilling, Ltd.',
    name: 'Shelf Drilling, Ltd.',
    symbol: 'SHLF.OL',
  },
  {
    value: 'PLT.OL',
    label: 'PLT.OL,  poLight ASA',
    name: 'poLight ASA',
    symbol: 'PLT.OL',
  },
  {
    value: 'ADE.OL',
    label: 'ADE.OL,  Adevinta ASA',
    name: 'Adevinta ASA',
    symbol: 'ADE.OL',
  },
  {
    value: 'OKEA.OL',
    label: 'OKEA.OL,  OKEA ASA',
    name: 'OKEA ASA',
    symbol: 'OKEA.OL',
  },
  {
    value: 'NSKOG.OL',
    label: 'NSKOG.OL,  Norske Skog ASA',
    name: 'Norske Skog ASA',
    symbol: 'NSKOG.OL',
  },
  {
    value: 'SATS.OL',
    label: 'SATS.OL,  Sats ASA',
    name: 'Sats ASA',
    symbol: 'SATS.OL',
  },
  {
    value: 'BWE.OL',
    label: 'BWE.OL,  BW Energy Limited',
    name: 'BW Energy Limited',
    symbol: 'BWE.OL',
  },
  {
    value: 'NOL.OL',
    label: 'NOL.OL,  Northern Ocean Ltd.',
    name: 'Northern Ocean Ltd.',
    symbol: 'NOL.OL',
  },
  {
    value: 'PEXIP.OL',
    label: 'PEXIP.OL,  Pexip Holding ASA',
    name: 'Pexip Holding ASA',
    symbol: 'PEXIP.OL',
  },
  {
    value: 'KDNY',
    label: 'KDNY,  Chinook Therapeutics, Inc.',
    name: 'Chinook Therapeutics, Inc.',
    symbol: 'KDNY',
  },
  {
    value: 'PLTR',
    label: 'PLTR,  Palantir Technologies Inc.',
    name: 'Palantir Technologies Inc.',
    symbol: 'PLTR',
  },
  {
    value: 'U',
    label: 'U,  Unity Software Inc.',
    name: 'Unity Software Inc.',
    symbol: 'U',
  },
  {
    value: 'OZON',
    label: 'OZON,  Ozon Holdings PLC',
    name: 'Ozon Holdings PLC',
    symbol: 'OZON',
  },
  {
    value: 'BWACU',
    label: 'BWACU,  Better World Acquisition Corp.',
    name: 'Better World Acquisition Corp.',
    symbol: 'BWACU',
  },
  {
    value: 'TLS',
    label: 'TLS,  Telos Corporation',
    name: 'Telos Corporation',
    symbol: 'TLS',
  },
  {
    value: 'MRVI',
    label: 'MRVI,  Maravai LifeSciences Holdings, Inc.',
    name: 'Maravai LifeSciences Holdings, Inc.',
    symbol: 'MRVI',
  },
  {
    value: 'SHC',
    label: 'SHC,  Sotera Health Company',
    name: 'Sotera Health Company',
    symbol: 'SHC',
  },
  {
    value: 'YSG',
    label: 'YSG,  Yatsen Holding Limited',
    name: 'Yatsen Holding Limited',
    symbol: 'YSG',
  },
  {
    value: 'HAACU',
    label: 'HAACU,  Health Assurance Acquisition Corp.',
    name: 'Health Assurance Acquisition Corp.',
    symbol: 'HAACU',
  },
  {
    value: 'ARBGU',
    label: 'ARBGU,  Aequi Acquisition Corp.',
    name: 'Aequi Acquisition Corp.',
    symbol: 'ARBGU',
  },
  {
    value: '1516.HK',
    label: '1516.HK,  Sunac Services Holdings Limited',
    name: 'Sunac Services Holdings Limited',
    symbol: '1516.HK',
  },
  {
    value: '6996.HK',
    label: '6996.HK,  Antengene Corporation Limited',
    name: 'Antengene Corporation Limited',
    symbol: '6996.HK',
  },
  {
    value: 'RRRP3.SA',
    label: 'RRRP3.SA,  3R Petroleum Óleo e Gás S.A.',
    name: '3R Petroleum Óleo e Gás S.A.',
    symbol: 'RRRP3.SA',
  },
  {
    value: '2599.HK',
    label: '2599.HK,  Shinsun Holdings (Group) Co., Ltd.',
    name: 'Shinsun Holdings (Group) Co., Ltd.',
    symbol: '2599.HK',
  },
  {
    value: '6900.HK',
    label: '6900.HK,  Sunkwan Properties Group Limited',
    name: 'Sunkwan Properties Group Limited',
    symbol: '6900.HK',
  },
  {
    value: '9666.HK',
    label: '9666.HK,  Jinke Smart Services Group Co., Ltd.',
    name: 'Jinke Smart Services Group Co., Ltd.',
    symbol: '9666.HK',
  },
  {
    value: 'ENJU3.SA',
    label: 'ENJU3.SA,  Enjoei S.A.',
    name: 'Enjoei S.A.',
    symbol: 'ENJU3.SA',
  },
  {
    value: '1795.HK',
    label: '1795.HK,  Yadong Group Holdings Limited',
    name: 'Yadong Group Holdings Limited',
    symbol: '1795.HK',
  },
  {
    value: 'AVIR',
    label: 'AVIR,  Atea Pharmaceuticals, Inc.',
    name: 'Atea Pharmaceuticals, Inc.',
    symbol: 'AVIR',
  },
  {
    value: 'LU',
    label: 'LU,  Lufax Holding Ltd',
    name: 'Lufax Holding Ltd',
    symbol: 'LU',
  },
  {
    value: 'CONXU',
    label: 'CONXU,  CONX Corp.',
    name: 'CONX Corp.',
    symbol: 'CONXU',
  },
  {
    value: 'JUPW',
    label: 'JUPW,  Jupiter Wellness, Inc.',
    name: 'Jupiter Wellness, Inc.',
    symbol: 'JUPW',
  },
  {
    value: 'MZH.SI',
    label: 'MZH.SI,  Nanofilm Technologies International Limited',
    name: 'Nanofilm Technologies International Limited',
    symbol: 'MZH.SI',
  },
  {
    value: 'PVT.V',
    label: 'PVT.V,  Pivotree Inc.',
    name: 'Pivotree Inc.',
    symbol: 'PVT.V',
  },
  {
    value: 'SQZ',
    label: 'SQZ,  SQZ Biotechnologies Company',
    name: 'SQZ Biotechnologies Company',
    symbol: 'SQZ',
  },
  {
    value: 'KDSX.V',
    label: 'KDSX.V,  Kadestone Capital Corp.',
    name: 'Kadestone Capital Corp.',
    symbol: 'KDSX.V',
  },
  {
    value: 'LESL',
    label: "LESL,  Leslie's, Inc.",
    name: "Leslie's, Inc.",
    symbol: 'LESL',
  },
  {
    value: 'FSNT.F',
    label: 'FSNT.F,  Fashionette AG',
    name: 'Fashionette AG',
    symbol: 'FSNT.F',
  },
  {
    value: 'SBI.L',
    label: 'SBI.L,  Sourcebio International Plc',
    name: 'Sourcebio International Plc',
    symbol: 'SBI.L',
  },
  {
    value: 'GLTO',
    label: 'GLTO,  Galecto, Inc.',
    name: 'Galecto, Inc.',
    symbol: 'GLTO',
  },
  {
    value: 'ALGM',
    label: 'ALGM,  Allegro MicroSystems, Inc.',
    name: 'Allegro MicroSystems, Inc.',
    symbol: 'ALGM',
  },
  {
    value: 'ROOT',
    label: 'ROOT,  Root, Inc.',
    name: 'Root, Inc.',
    symbol: 'ROOT',
  },
  {
    value: 'BDSX',
    label: 'BDSX,  Biodesix, Inc.',
    name: 'Biodesix, Inc.',
    symbol: 'BDSX',
  },
  {
    value: '2126.HK',
    label: '2126.HK,  JW (Cayman) Therapeutics Co. Ltd',
    name: 'JW (Cayman) Therapeutics Co. Ltd',
    symbol: '2126.HK',
  },
  {
    value: 'MAX',
    label: 'MAX,  MediaAlpha, Inc.',
    name: 'MediaAlpha, Inc.',
    symbol: 'MAX',
  },
  {
    value: 'GATO',
    label: 'GATO,  Gatos Silver, Inc.',
    name: 'Gatos Silver, Inc.',
    symbol: 'GATO',
  },
  {
    value: '9995.HK',
    label: '9995.HK,  RemeGen Co., Ltd.',
    name: 'RemeGen Co., Ltd.',
    symbol: '9995.HK',
  },
  {
    value: 'LUXAU',
    label: 'LUXAU,  Lux Health Tech Acquisition Corp.',
    name: 'Lux Health Tech Acquisition Corp.',
    symbol: 'LUXAU',
  },
  {
    value: 'TFCO4.SA',
    label: 'TFCO4.SA,  Track & Field Co S.A.',
    name: 'Track & Field Co S.A.',
    symbol: 'TFCO4.SA',
  },
  {
    value: 'SOL.MC',
    label: 'SOL.MC,  Soltec Power Holdings, S.A.',
    name: 'Soltec Power Holdings, S.A.',
    symbol: 'SOL.MC',
  },
  {
    value: 'YGMZ',
    label: 'YGMZ,  MingZhu Logistics Holdings Limited',
    name: 'MingZhu Logistics Holdings Limited',
    symbol: 'YGMZ',
  },
  {
    value: 'PRAX',
    label: 'PRAX,  Praxis Precision Medicines, Inc.',
    name: 'Praxis Precision Medicines, Inc.',
    symbol: 'PRAX',
  },
  {
    value: 'EAR',
    label: 'EAR,  Eargo, Inc.',
    name: 'Eargo, Inc.',
    symbol: 'EAR',
  },
  {
    value: 'TARS',
    label: 'TARS,  Tarsus Pharmaceuticals, Inc.',
    name: 'Tarsus Pharmaceuticals, Inc.',
    symbol: 'TARS',
  },
  {
    value: 'ALGS',
    label: 'ALGS,  Aligos Therapeutics, Inc.',
    name: 'Aligos Therapeutics, Inc.',
    symbol: 'ALGS',
  },
  {
    value: 'BTWNU',
    label: 'BTWNU,  Bridgetown Holdings Limited',
    name: 'Bridgetown Holdings Limited',
    symbol: 'BTWNU',
  },
  {
    value: 'OPT',
    label: 'OPT,  Opthea Limited',
    name: 'Opthea Limited',
    symbol: 'OPT',
  },
  {
    value: 'KRBP',
    label: 'KRBP,  Kiromic BioPharma, Inc.',
    name: 'Kiromic BioPharma, Inc.',
    symbol: 'KRBP',
  },
  {
    value: 'EBC',
    label: 'EBC,  Eastern Bankshares, Inc.',
    name: 'Eastern Bankshares, Inc.',
    symbol: 'EBC',
  },
  {
    value: 'MNSO',
    label: 'MNSO,  MINISO Group Holding Limited',
    name: 'MINISO Group Holding Limited',
    symbol: 'MNSO',
  },
  {
    value: 'FVAM',
    label: 'FVAM,  5:01 Acquisition Corp.',
    name: '5:01 Acquisition Corp.',
    symbol: 'FVAM',
  },
  {
    value: 'CDAK',
    label: 'CDAK,  Codiak BioSciences, Inc.',
    name: 'Codiak BioSciences, Inc.',
    symbol: 'CDAK',
  },
  {
    value: 'STTK',
    label: 'STTK,  Shattuck Labs, Inc.',
    name: 'Shattuck Labs, Inc.',
    symbol: 'STTK',
  },
  {
    value: 'SPRB',
    label: 'SPRB,  Spruce Biosciences, Inc.',
    name: 'Spruce Biosciences, Inc.',
    symbol: 'SPRB',
  },
  {
    value: 'KRON',
    label: 'KRON,  Kronos Bio, Inc.',
    name: 'Kronos Bio, Inc.',
    symbol: 'KRON',
  },
  {
    value: 'IH',
    label: 'IH,  iHuman Inc.',
    name: 'iHuman Inc.',
    symbol: 'IH',
  },
  {
    value: 'AZYO',
    label: 'AZYO,  Aziyo Biologics, Inc.',
    name: 'Aziyo Biologics, Inc.',
    symbol: 'AZYO',
  },
  {
    value: 'FUBO',
    label: 'FUBO,  fuboTV Inc.',
    name: 'fuboTV Inc.',
    symbol: 'FUBO',
  },
  {
    value: 'SQFT',
    label: 'SQFT,  Presidio Property Trust, Inc.',
    name: 'Presidio Property Trust, Inc.',
    symbol: 'SQFT',
  },
  {
    value: 'EUCRU',
    label: 'EUCRU,  Eucrates Biomedical Acquisition Corp.',
    name: 'Eucrates Biomedical Acquisition Corp.',
    symbol: 'EUCRU',
  },
  {
    value: 'ACACU',
    label: 'ACACU,  Acri Capital Acquisition Corporation',
    name: 'Acri Capital Acquisition Corporation',
    symbol: 'ACACU',
  },
  {
    value: 'CCCC',
    label: 'CCCC,  C4 Therapeutics, Inc.',
    name: 'C4 Therapeutics, Inc.',
    symbol: 'CCCC',
  },
  {
    value: 'ONCR',
    label: 'ONCR,  Oncorus, Inc.',
    name: 'Oncorus, Inc.',
    symbol: 'ONCR',
  },
  {
    value: 'IMNM',
    label: 'IMNM,  Immunome, Inc.',
    name: 'Immunome, Inc.',
    symbol: 'IMNM',
  },
  {
    value: 'ASO',
    label: 'ASO,  Academy Sports and Outdoors, Inc.',
    name: 'Academy Sports and Outdoors, Inc.',
    symbol: 'ASO',
  },
  {
    value: 'ABCM',
    label: 'ABCM,  Abcam plc',
    name: 'Abcam plc',
    symbol: 'ABCM',
  },
  {
    value: 'FHTX',
    label: 'FHTX,  Foghorn Therapeutics Inc.',
    name: 'Foghorn Therapeutics Inc.',
    symbol: 'FHTX',
  },
  {
    value: 'BLSA',
    label: 'BLSA,  BCLS Acquisition Corp.',
    name: 'BCLS Acquisition Corp.',
    symbol: 'BLSA',
  },
  {
    value: 'LFTRU',
    label: 'LFTRU,  Lefteris Acquisition Corp.',
    name: 'Lefteris Acquisition Corp.',
    symbol: 'LFTRU',
  },
  {
    value: 'LXEH',
    label: 'LXEH,  Lixiang Education Holding Co., Ltd.',
    name: 'Lixiang Education Holding Co., Ltd.',
    symbol: 'LXEH',
  },
  {
    value: 'IGACU',
    label: 'IGACU,  IG Acquisition Corp.',
    name: 'IG Acquisition Corp.',
    symbol: 'IGACU',
  },
  {
    value: 'AVO',
    label: 'AVO,  Mission Produce, Inc.',
    name: 'Mission Produce, Inc.',
    symbol: 'AVO',
  },
  {
    value: 'LUNG',
    label: 'LUNG,  Pulmonx Corporation',
    name: 'Pulmonx Corporation',
    symbol: 'LUNG',
  },
  {
    value: 'THRY',
    label: 'THRY,  Thryv Holdings, Inc.',
    name: 'Thryv Holdings, Inc.',
    symbol: 'THRY',
  },
  {
    value: 'GHLD',
    label: 'GHLD,  Guild Holdings Company',
    name: 'Guild Holdings Company',
    symbol: 'GHLD',
  },
  {
    value: 'ASAN',
    label: 'ASAN,  Asana, Inc.',
    name: 'Asana, Inc.',
    symbol: 'ASAN',
  },
  {
    value: 'SRSAU',
    label: 'SRSAU,  Sarissa Capital Acquisition Corp.',
    name: 'Sarissa Capital Acquisition Corp.',
    symbol: 'SRSAU',
  },
  {
    value: 'SYTA',
    label: 'SYTA,  Siyata Mobile Inc.',
    name: 'Siyata Mobile Inc.',
    symbol: 'SYTA',
  },
  {
    value: 'AMST',
    label: 'AMST,  Amesite Inc.',
    name: 'Amesite Inc.',
    symbol: 'AMST',
  },
  {
    value: 'GLSI',
    label: 'GLSI,  Greenwich LifeSciences, Inc.',
    name: 'Greenwich LifeSciences, Inc.',
    symbol: 'GLSI',
  },
  {
    value: 'TEKKU',
    label: 'TEKKU,  Tekkorp Digital Acquisition Corp.',
    name: 'Tekkorp Digital Acquisition Corp.',
    symbol: 'TEKKU',
  },
  {
    value: 'LSF',
    label: 'LSF,  Laird Superfood, Inc.',
    name: 'Laird Superfood, Inc.',
    symbol: 'LSF',
  },
  {
    value: 'GDRX',
    label: 'GDRX,  GoodRx Holdings, Inc.',
    name: 'GoodRx Holdings, Inc.',
    symbol: 'GDRX',
  },
  {
    value: 'BSY',
    label: 'BSY,  Bentley Systems, Incorporated',
    name: 'Bentley Systems, Incorporated',
    symbol: 'BSY',
  },
  {
    value: 'CRSR',
    label: 'CRSR,  Corsair Gaming, Inc.',
    name: 'Corsair Gaming, Inc.',
    symbol: 'CRSR',
  },
  {
    value: 'CMPS',
    label: 'CMPS,  COMPASS Pathways plc',
    name: 'COMPASS Pathways plc',
    symbol: 'CMPS',
  },
  {
    value: 'ATHA',
    label: 'ATHA,  Athira Pharma, Inc.',
    name: 'Athira Pharma, Inc.',
    symbol: 'ATHA',
  },
  {
    value: 'VTRU',
    label: 'VTRU,  Vitru Limited',
    name: 'Vitru Limited',
    symbol: 'VTRU',
  },
  {
    value: 'PTVE',
    label: 'PTVE,  Pactiv Evergreen Inc.',
    name: 'Pactiv Evergreen Inc.',
    symbol: 'PTVE',
  },
  {
    value: 'SUMO',
    label: 'SUMO,  Sumo Logic, Inc.',
    name: 'Sumo Logic, Inc.',
    symbol: 'SUMO',
  },
  {
    value: 'AMWL',
    label: 'AMWL,  American Well Corporation',
    name: 'American Well Corporation',
    symbol: 'AMWL',
  },
  {
    value: 'BNL',
    label: 'BNL,  Broadstone Net Lease, Inc.',
    name: 'Broadstone Net Lease, Inc.',
    symbol: 'BNL',
  },
  {
    value: 'FROG',
    label: 'FROG,  JFrog Ltd.',
    name: 'JFrog Ltd.',
    symbol: 'FROG',
  },
  {
    value: 'SNOW',
    label: 'SNOW,  Snowflake Inc.',
    name: 'Snowflake Inc.',
    symbol: 'SNOW',
  },
  {
    value: 'MTCR',
    label: 'MTCR,  Metacrine, Inc.',
    name: 'Metacrine, Inc.',
    symbol: 'MTCR',
  },
  {
    value: 'STEP',
    label: 'STEP,  StepStone Group Inc.',
    name: 'StepStone Group Inc.',
    symbol: 'STEP',
  },
  {
    value: 'OM',
    label: 'OM,  Outset Medical, Inc.',
    name: 'Outset Medical, Inc.',
    symbol: 'OM',
  },
  {
    value: 'BQ',
    label: 'BQ,  Boqii Holding Limited',
    name: 'Boqii Holding Limited',
    symbol: 'BQ',
  },
  {
    value: 'YALA',
    label: 'YALA,  Yalla Group Limited',
    name: 'Yalla Group Limited',
    symbol: 'YALA',
  },
  {
    value: 'CD',
    label: 'CD,  Chindata Group Holdings Limited',
    name: 'Chindata Group Holdings Limited',
    symbol: 'CD',
  },
  {
    value: 'VIAO',
    label: 'VIAO,  VIA optronics AG',
    name: 'VIA optronics AG',
    symbol: 'VIAO',
  },
  {
    value: 'GRAY',
    label: 'GRAY,  Graybug Vision, Inc.',
    name: 'Graybug Vision, Inc.',
    symbol: 'GRAY',
  },
  {
    value: 'PMVP',
    label: 'PMVP,  PMV Pharmaceuticals, Inc.',
    name: 'PMV Pharmaceuticals, Inc.',
    symbol: 'PMVP',
  },
  {
    value: 'PRLD',
    label: 'PRLD,  Prelude Therapeutics Incorporated',
    name: 'Prelude Therapeutics Incorporated',
    symbol: 'PRLD',
  },
  {
    value: 'TSHA',
    label: 'TSHA,  Taysha Gene Therapies, Inc.',
    name: 'Taysha Gene Therapies, Inc.',
    symbol: 'TSHA',
  },
  {
    value: 'PRFX',
    label: 'PRFX,  PainReform Ltd.',
    name: 'PainReform Ltd.',
    symbol: 'PRFX',
  },
  {
    value: 'AUVI',
    label: 'AUVI,  Applied UV, Inc.',
    name: 'Applied UV, Inc.',
    symbol: 'AUVI',
  },
  {
    value: 'HCDI',
    label: 'HCDI,  Harbor Custom Development, Inc.',
    name: 'Harbor Custom Development, Inc.',
    symbol: 'HCDI',
  },
  {
    value: 'XPEV',
    label: 'XPEV,  XPeng Inc.',
    name: 'XPeng Inc.',
    symbol: 'XPEV',
  },
  {
    value: 'FRLN',
    label: 'FRLN,  Freeline Therapeutics Holdings plc',
    name: 'Freeline Therapeutics Holdings plc',
    symbol: 'FRLN',
  },
  {
    value: 'IBEX',
    label: 'IBEX,  IBEX Limited',
    name: 'IBEX Limited',
    symbol: 'IBEX',
  },
  {
    value: 'VSTA',
    label: 'VSTA,  Vasta Platform Limited',
    name: 'Vasta Platform Limited',
    symbol: 'VSTA',
  },
  {
    value: 'INM',
    label: 'INM,  InMed Pharmaceuticals Inc.',
    name: 'InMed Pharmaceuticals Inc.',
    symbol: 'INM',
  },
  {
    value: 'HDGE.TO',
    label: 'HDGE.TO,  Accelerate Absolute Return Hedge Fund',
    name: 'Accelerate Absolute Return Hedge Fund',
    symbol: 'HDGE.TO',
  },
  {
    value: 'ARB.TO',
    label: 'ARB.TO,  Accelerate Arbitrage Fund',
    name: 'Accelerate Arbitrage Fund',
    symbol: 'ARB.TO',
  },
  {
    value: 'ATSX.TO',
    label: 'ATSX.TO,  Accelerate Enhanced Canadian Benchmark Alternative Fund',
    name: 'Accelerate Enhanced Canadian Benchmark Alternative Fund',
    symbol: 'ATSX.TO',
  },
  {
    value: 'AT.TO',
    label: 'AT.TO,  AcuityAds Holdings Inc.',
    name: 'AcuityAds Holdings Inc.',
    symbol: 'AT.TO',
  },
  {
    value: 'AEZS.TO',
    label: 'AEZS.TO,  Aeterna Zentaris Inc.',
    name: 'Aeterna Zentaris Inc.',
    symbol: 'AEZS.TO',
  },
  {
    value: 'QCD.TO',
    label: 'QCD.TO,  AGFiQ Canadian Equity ETF',
    name: 'AGFiQ Canadian Equity ETF',
    symbol: 'QCD.TO',
  },
  {
    value: 'QEM.TO',
    label: 'QEM.TO,  AGFiQ Emerging Markets Equity ETF',
    name: 'AGFiQ Emerging Markets Equity ETF',
    symbol: 'QEM.TO',
  },
  {
    value: 'QIE.TO',
    label: 'QIE.TO,  AGFiQ International Equity ETF',
    name: 'AGFiQ International Equity ETF',
    symbol: 'QIE.TO',
  },
  {
    value: 'QUS.TO',
    label: 'QUS.TO,  AGFiQ U.S. Equity ETF',
    name: 'AGFiQ U.S. Equity ETF',
    symbol: 'QUS.TO',
  },
  {
    value: 'QBTL.TO',
    label: 'QBTL.TO,  AGFiQ US Market Neutral Anti-Beta CAD-Hedged ETF',
    name: 'AGFiQ US Market Neutral Anti-Beta CAD-Hedged ETF',
    symbol: 'QBTL.TO',
  },
  {
    value: 'AKU.TO',
    label: 'AKU.TO,  Akumin Inc.',
    name: 'Akumin Inc.',
    symbol: 'AKU.TO',
  },
  {
    value: 'AH.TO',
    label: 'AH.TO,  Aleafia Health Inc.',
    name: 'Aleafia Health Inc.',
    symbol: 'AH.TO',
  },
  {
    value: 'ALYA.TO',
    label: 'ALYA.TO,  Alithya Group Inc.',
    name: 'Alithya Group Inc.',
    symbol: 'ALYA.TO',
  },
  {
    value: 'AII.TO',
    label: 'AII.TO,  Almonty Industries Inc.',
    name: 'Almonty Industries Inc.',
    symbol: 'AII.TO',
  },
  {
    value: 'AND.TO',
    label: 'AND.TO,  Andlauer Healthcare Group Inc.',
    name: 'Andlauer Healthcare Group Inc.',
    symbol: 'AND.TO',
  },
  {
    value: 'APLI.TO',
    label: 'APLI.TO,  Appili Therapeutics Inc.',
    name: 'Appili Therapeutics Inc.',
    symbol: 'APLI.TO',
  },
  {
    value: 'ASND.TO',
    label: 'ASND.TO,  Ascendant Resources Inc.',
    name: 'Ascendant Resources Inc.',
    symbol: 'ASND.TO',
  },
  {
    value: 'AOT.TO',
    label: 'AOT.TO,  Ascot Resources Ltd.',
    name: 'Ascot Resources Ltd.',
    symbol: 'AOT.TO',
  },
  {
    value: 'ACB.TO',
    label: 'ACB.TO,  Aurora Cannabis Inc.',
    name: 'Aurora Cannabis Inc.',
    symbol: 'ACB.TO',
  },
  {
    value: 'AVCN.TO',
    label: 'AVCN.TO,  Avicanna Inc.',
    name: 'Avicanna Inc.',
    symbol: 'AVCN.TO',
  },
  {
    value: 'ASM.TO',
    label: 'ASM.TO,  Avino Silver & Gold Mines Ltd.',
    name: 'Avino Silver & Gold Mines Ltd.',
    symbol: 'ASM.TO',
  },
  {
    value: 'BLDP.TO',
    label: 'BLDP.TO,  Ballard Power Systems Inc.',
    name: 'Ballard Power Systems Inc.',
    symbol: 'BLDP.TO',
  },
  {
    value: 'BHC.TO',
    label: 'BHC.TO,  Bausch Health Companies Inc.',
    name: 'Bausch Health Companies Inc.',
    symbol: 'BHC.TO',
  },
  {
    value: 'HMJU.TO',
    label: 'HMJU.TO,  BetaPro Marijuana Companies 2x Daily Bull ETF',
    name: 'BetaPro Marijuana Companies 2x Daily Bull ETF',
    symbol: 'HMJU.TO',
  },
  {
    value: 'HMJI.TO',
    label: 'HMJI.TO,  BetaPro Marijuana Companies Inverse ETF',
    name: 'BetaPro Marijuana Companies Inverse ETF',
    symbol: 'HMJI.TO',
  },
  {
    value: 'BNK.TO',
    label: 'BNK.TO,  Big Banc Split Corp.',
    name: 'Big Banc Split Corp.',
    symbol: 'BNK.TO',
  },
  {
    value: 'PRM.TO',
    label: 'PRM.TO,  Big Pharma Split Corp.',
    name: 'Big Pharma Split Corp.',
    symbol: 'PRM.TO',
  },
  {
    value: 'HBLK.TO',
    label: 'HBLK.TO,  Blockchain Technologies ETF',
    name: 'Blockchain Technologies ETF',
    symbol: 'HBLK.TO',
  },
  {
    value: 'ZESG.TO',
    label: 'ZESG.TO,  BMO Balanced ESG ETF',
    name: 'BMO Balanced ESG ETF',
    symbol: 'ZESG.TO',
  },
  {
    value: 'ZBAL.TO',
    label: 'ZBAL.TO,  BMO Balanced ETF',
    name: 'BMO Balanced ETF',
    symbol: 'ZBAL.TO',
  },
  {
    value: 'ZBBB.TO',
    label: 'ZBBB.TO,  BMO BBB Corporate Bond Index ETF',
    name: 'BMO BBB Corporate Bond Index ETF',
    symbol: 'ZBBB.TO',
  },
  {
    value: 'ZWC.TO',
    label: 'ZWC.TO,  BMO CA High Dividend Covered Call ETF',
    name: 'BMO CA High Dividend Covered Call ETF',
    symbol: 'ZWC.TO',
  },
  {
    value: 'ZMBS.TO',
    label: 'ZMBS.TO,  BMO Canadian MBS Index ETF',
    name: 'BMO Canadian MBS Index ETF',
    symbol: 'ZMBS.TO',
  },
  {
    value: 'ZCON.TO',
    label: 'ZCON.TO,  BMO Conservative ETF',
    name: 'BMO Conservative ETF',
    symbol: 'ZCON.TO',
  },
  {
    value: 'ZCPB.TO',
    label: 'ZCPB.TO,  BMO Core Plus Bond Fund',
    name: 'BMO Core Plus Bond Fund',
    symbol: 'ZCPB.TO',
  },
  {
    value: 'ZCB.TO',
    label: 'ZCB.TO,  BMO Corporate Bond Index ETF',
    name: 'BMO Corporate Bond Index ETF',
    symbol: 'ZCB.TO',
  },
  {
    value: 'ZWK.TO',
    label: 'ZWK.TO,  BMO Covered Call US Banks ETF',
    name: 'BMO Covered Call US Banks ETF',
    symbol: 'ZWK.TO',
  },
  {
    value: 'ZHU.TO',
    label: 'ZHU.TO,  BMO Equal Weight US Health Care Index ETF',
    name: 'BMO Equal Weight US Health Care Index ETF',
    symbol: 'ZHU.TO',
  },
  {
    value: 'ESGB.TO',
    label: 'ESGB.TO,  BMO ESG Corporate Bond Index ETF',
    name: 'BMO ESG Corporate Bond Index ETF',
    symbol: 'ESGB.TO',
  },
  {
    value: 'ESGF.TO',
    label: 'ESGF.TO,  BMO ESG US Corporate Bond Hedged to CAD Index ETF',
    name: 'BMO ESG US Corporate Bond Hedged to CAD Index ETF',
    symbol: 'ESGF.TO',
  },
  {
    value: 'ZWP.TO',
    label: 'ZWP.TO,  BMO Europe High Dividend Covered Call ETF',
    name: 'BMO Europe High Dividend Covered Call ETF',
    symbol: 'ZWP.TO',
  },
  {
    value: 'COMM.TO',
    label: 'COMM.TO,  BMO Global Communications Index ETF',
    name: 'BMO Global Communications Index ETF',
    symbol: 'COMM.TO',
  },
  {
    value: 'DISC.TO',
    label:
      'DISC.TO,  BMO Global Consumer Discretionary Hedged to CAD Index ETF',
    name: 'BMO Global Consumer Discretionary Hedged to CAD Index ETF',
    symbol: 'DISC.TO',
  },
  {
    value: 'STPL.TO',
    label: 'STPL.TO,  BMO Global Consumer Staples Hedged to CAD Index ETF',
    name: 'BMO Global Consumer Staples Hedged to CAD Index ETF',
    symbol: 'STPL.TO',
  },
  {
    value: 'ZWG.TO',
    label: 'ZWG.TO,  BMO Global High Dividend Covered Call ETF',
    name: 'BMO Global High Dividend Covered Call ETF',
    symbol: 'ZWG.TO',
  },
  {
    value: 'ZMSB.TO',
    label: 'ZMSB.TO,  BMO Sustainable Global Multi-Sector Bond Fund',
    name: 'BMO Sustainable Global Multi-Sector Bond Fund',
    symbol: 'ZMSB.TO',
  },
  {
    value: 'ZGSB.TO',
    label: 'ZGSB.TO,  BMO Global Strategic Bond Fund',
    name: 'BMO Global Strategic Bond Fund',
    symbol: 'ZGSB.TO',
  },
  {
    value: 'ZGB.TO',
    label: 'ZGB.TO,  BMO Government Bond Index ETF',
    name: 'BMO Government Bond Index ETF',
    symbol: 'ZGB.TO',
  },
  {
    value: 'ZGRO.TO',
    label: 'ZGRO.TO,  BMO Growth ETF',
    name: 'BMO Growth ETF',
    symbol: 'ZGRO.TO',
  },
  {
    value: 'ZJK.TO',
    label: 'ZJK.TO,  BMO High Yield US Corporate Bond Index ETF',
    name: 'BMO High Yield US Corporate Bond Index ETF',
    symbol: 'ZJK.TO',
  },
  {
    value: 'ESGA.TO',
    label: 'ESGA.TO,  BMO MSCI Canada ESG Leaders Index ETF',
    name: 'BMO MSCI Canada ESG Leaders Index ETF',
    symbol: 'ESGA.TO',
  },
  {
    value: 'ZVC.TO',
    label: 'ZVC.TO,  BMO MSCI Canada Value Index ETF',
    name: 'BMO MSCI Canada Value Index ETF',
    symbol: 'ZVC.TO',
  },
  {
    value: 'ESGE.TO',
    label: 'ESGE.TO,  BMO MSCI EAFE ESG Leaders Index ETF',
    name: 'BMO MSCI EAFE ESG Leaders Index ETF',
    symbol: 'ESGE.TO',
  },
  {
    value: 'ESGG.TO',
    label: 'ESGG.TO,  BMO MSCI Global ESG Leaders Index ETF',
    name: 'BMO MSCI Global ESG Leaders Index ETF',
    symbol: 'ESGG.TO',
  },
  {
    value: 'ESGY.TO',
    label: 'ESGY.TO,  BMO MSCI USA ESG Leaders Index ETF',
    name: 'BMO MSCI USA ESG Leaders Index ETF',
    symbol: 'ESGY.TO',
  },
  {
    value: 'ZVU.TO',
    label: 'ZVU.TO,  BMO MSCI USA Value Index ETF',
    name: 'BMO MSCI USA Value Index ETF',
    symbol: 'ZVU.TO',
  },
  {
    value: 'ZNQ.TO',
    label: 'ZNQ.TO,  BMO NASDAQ 100 Equity Index ETF',
    name: 'BMO NASDAQ 100 Equity Index ETF',
    symbol: 'ZNQ.TO',
  },
  {
    value: 'ZPAY.TO',
    label: 'ZPAY.TO,  BMO Premium Yield ETF',
    name: 'BMO Premium Yield ETF',
    symbol: 'ZPAY.TO',
  },
  {
    value: 'ZMID.TO',
    label: 'ZMID.TO,  BMO S&P US Mid Cap Index ETF',
    name: 'BMO S&P US Mid Cap Index ETF',
    symbol: 'ZMID.TO',
  },
  {
    value: 'ZSML.TO',
    label: 'ZSML.TO,  BMO S&P US Small Cap Index ETF',
    name: 'BMO S&P US Small Cap Index ETF',
    symbol: 'ZSML.TO',
  },
  {
    value: 'ZSB.TO',
    label: 'ZSB.TO,  BMO Short-Term Bond Index ETF',
    name: 'BMO Short-Term Bond Index ETF',
    symbol: 'ZSB.TO',
  },
  {
    value: 'ZFC.TO',
    label: 'ZFC.TO,  BMO SIA Focused Canadian Equity Fund',
    name: 'BMO SIA Focused Canadian Equity Fund',
    symbol: 'ZFC.TO',
  },
  {
    value: 'ZFN.TO',
    label: 'ZFN.TO,  BMO SIA Focused North American Equity Fund',
    name: 'BMO SIA Focused North American Equity Fund',
    symbol: 'ZFN.TO',
  },
  {
    value: 'ZZZD.TO',
    label: 'ZZZD.TO,  BMO Tactical Dividend ETF Fund',
    name: 'BMO Tactical Dividend ETF Fund',
    symbol: 'ZZZD.TO',
  },
  {
    value: 'ZWS.TO',
    label: 'ZWS.TO,  BMO US High Dividend Covered Call Hedged to CAD ETF',
    name: 'BMO US High Dividend Covered Call Hedged to CAD ETF',
    symbol: 'ZWS.TO',
  },
  {
    value: 'ZHP.TO',
    label: 'ZHP.TO,  BMO US Preferred Share Hedged to CAD Index ETF',
    name: 'BMO US Preferred Share Hedged to CAD Index ETF',
    symbol: 'ZHP.TO',
  },
  {
    value: 'ZUP.TO',
    label: 'ZUP.TO,  BMO US Preferred Share Index ETF',
    name: 'BMO US Preferred Share Index ETF',
    symbol: 'ZUP.TO',
  },
  {
    value: 'ZPH.TO',
    label: 'ZPH.TO,  BMO US Put Write Hedged to CAD ETF',
    name: 'BMO US Put Write Hedged to CAD ETF',
    symbol: 'ZPH.TO',
  },
  {
    value: 'WOMN.TO',
    label: 'WOMN.TO,  BMO Women in Leadership Fund',
    name: 'BMO Women in Leadership Fund',
    symbol: 'WOMN.TO',
  },
  {
    value: 'BYD.TO',
    label: 'BYD.TO,  Boyd Group Services Inc.',
    name: 'Boyd Group Services Inc.',
    symbol: 'BYD.TO',
  },
  {
    value: 'BGC.TO',
    label: 'BGC.TO,  Bristol Gate Concentrated Canadian Equity ETF',
    name: 'Bristol Gate Concentrated Canadian Equity ETF',
    symbol: 'BGC.TO',
  },
  {
    value: 'BGU.TO',
    label: 'BGU.TO,  Bristol Gate Concentrated US Equity ETF',
    name: 'Bristol Gate Concentrated US Equity ETF',
    symbol: 'BGU.TO',
  },
  {
    value: 'EDGF.TO',
    label: 'EDGF.TO,  Brompton European Dividend Growth ETF',
    name: 'Brompton European Dividend Growth ETF',
    symbol: 'EDGF.TO',
  },
  {
    value: 'BPRF.TO',
    label:
      'BPRF.TO,  Brompton Flaherty & Crumrine Investment Grade Preferred ETF',
    name: 'Brompton Flaherty & Crumrine Investment Grade Preferred ETF',
    symbol: 'BPRF.TO',
  },
  {
    value: 'BDIV.TO',
    label: 'BDIV.TO,  Brompton Global Dividend Growth ETF',
    name: 'Brompton Global Dividend Growth ETF',
    symbol: 'BDIV.TO',
  },
  {
    value: 'HIG.TO',
    label: 'HIG.TO,  Brompton Global Healthcare Income & Growth ETF',
    name: 'Brompton Global Healthcare Income & Growth ETF',
    symbol: 'HIG.TO',
  },
  {
    value: 'BREA.TO',
    label: 'BREA.TO,  Brompton Sustainable Real Assets Dividend ETF',
    name: 'Brompton Sustainable Real Assets Dividend ETF',
    symbol: 'BREA.TO',
  },
  {
    value: 'BFIN.TO',
    label: 'BFIN.TO,  Brompton North American Financials Dividend ETF',
    name: 'Brompton North American Financials Dividend ETF',
    symbol: 'BFIN.TO',
  },
  {
    value: 'BLOV.TO',
    label: 'BLOV.TO,  Brompton North American Low Volatility Dividend ETF',
    name: 'Brompton North American Low Volatility Dividend ETF',
    symbol: 'BLOV.TO',
  },
  {
    value: 'TLF.TO',
    label: 'TLF.TO,  Brompton Tech Leaders Income ETF',
    name: 'Brompton Tech Leaders Income ETF',
    symbol: 'TLF.TO',
  },
  {
    value: 'BIPC.TO',
    label: 'BIPC.TO,  Brookfield Infrastructure Corporation',
    name: 'Brookfield Infrastructure Corporation',
    symbol: 'BIPC.TO',
  },
  {
    value: 'BEPC.TO',
    label: 'BEPC.TO,  Brookfield Renewable Corporation',
    name: 'Brookfield Renewable Corporation',
    symbol: 'BEPC.TO',
  },
  {
    value: 'UDA.TO',
    label: 'UDA.TO,  Caldwell US Dividend Advantage Fund',
    name: 'Caldwell US Dividend Advantage Fund',
    symbol: 'UDA.TO',
  },
  {
    value: 'CXB.TO',
    label: 'CXB.TO,  Calibre Mining Corp.',
    name: 'Calibre Mining Corp.',
    symbol: 'CXB.TO',
  },
  {
    value: 'GOOS.TO',
    label: 'GOOS.TO,  Canada Goose Holdings Inc.',
    name: 'Canada Goose Holdings Inc.',
    symbol: 'GOOS.TO',
  },
  {
    value: 'CTC.TO',
    label: 'CTC.TO,  Canadian Tire Corporation, Limited',
    name: 'Canadian Tire Corporation, Limited',
    symbol: 'CTC.TO',
  },
  {
    value: 'WEED.TO',
    label: 'WEED.TO,  Canopy Growth Corporation',
    name: 'Canopy Growth Corporation',
    symbol: 'WEED.TO',
  },
  {
    value: 'CRDL.TO',
    label: 'CRDL.TO,  Cardiol Therapeutics Inc.',
    name: 'Cardiol Therapeutics Inc.',
    symbol: 'CRDL.TO',
  },
  {
    value: 'CRRX.TO',
    label: 'CRRX.TO,  CareRx Corporation',
    name: 'CareRx Corporation',
    symbol: 'CRRX.TO',
  },
  {
    value: 'CDAY.TO',
    label: 'CDAY.TO,  Ceridian HCM Holding Inc.',
    name: 'Ceridian HCM Holding Inc.',
    symbol: 'CDAY.TO',
  },
  {
    value: 'CWEB.TO',
    label: "CWEB.TO,  Charlotte's Web Holdings, Inc.",
    name: "Charlotte's Web Holdings, Inc.",
    symbol: 'CWEB.TO',
  },
  {
    value: 'CCOR.TO',
    label:
      'CCOR.TO,  CI DoubleLine Core Plus Fixed Income US$ Fund ETF C$ Hedged Series',
    name: 'CI DoubleLine Core Plus Fixed Income US$ Fund ETF C$ Hedged Series',
    symbol: 'CCOR.TO',
  },
  {
    value: 'CINC.TO',
    label: 'CINC.TO,  CI DoubleLine Income US$ Fund',
    name: 'CI DoubleLine Income US$ Fund',
    symbol: 'CINC.TO',
  },
  {
    value: 'CDLB.TO',
    label: 'CDLB.TO,  CI DoubleLine Total Return Bond US$ Fund',
    name: 'CI DoubleLine Total Return Bond US$ Fund',
    symbol: 'CDLB.TO',
  },
  {
    value: 'FGO.TO',
    label: 'FGO.TO,  CI Enhanced Government Bond ETF',
    name: 'CI Enhanced Government Bond ETF',
    symbol: 'FGO.TO',
  },
  {
    value: 'FSB.TO',
    label: 'FSB.TO,  CI First Asset Enhanced Short Duration Bond Fund ETF',
    name: 'CI First Asset Enhanced Short Duration Bond Fund ETF',
    symbol: 'FSB.TO',
  },
  {
    value: 'CGXF.TO',
    label: 'CGXF.TO,  CI Gold+ Giants Covered Call ETF',
    name: 'CI Gold+ Giants Covered Call ETF',
    symbol: 'CGXF.TO',
  },
  {
    value: 'FHI.TO',
    label: 'FHI.TO,  CI Health Care Giants Covered Call ETF',
    name: 'CI Health Care Giants Covered Call ETF',
    symbol: 'FHI.TO',
  },
  {
    value: 'CSAV.TO',
    label: 'CSAV.TO,  CI High Interest Savings ETF',
    name: 'CI High Interest Savings ETF',
    symbol: 'CSAV.TO',
  },
  {
    value: 'SID.TO',
    label: 'SID.TO,  CI U.S. Trendleaders ETF',
    name: 'CI U.S. Trendleaders ETF',
    symbol: 'SID.TO',
  },
  {
    value: 'CGAA.TO',
    label: 'CGAA.TO,  CI Global Asset Allocation Private Pool ETF',
    name: 'CI Global Asset Allocation Private Pool ETF',
    symbol: 'CGAA.TO',
  },
  {
    value: 'CINF.TO',
    label: 'CINF.TO,  CI Global Infrastructure Private Pool',
    name: 'CI Global Infrastructure Private Pool',
    symbol: 'CINF.TO',
  },
  {
    value: 'CGRA.TO',
    label: 'CGRA.TO,  CI Global Real Asset Private Pool',
    name: 'CI Global Real Asset Private Pool',
    symbol: 'CGRA.TO',
  },
  {
    value: 'CGRE.TO',
    label: 'CGRE.TO,  CI Global REIT Private Pool',
    name: 'CI Global REIT Private Pool',
    symbol: 'CGRE.TO',
  },
  {
    value: 'CRED.TO',
    label: 'CRED.TO,  CI Lawrence Park Alternative Investment Grade Credit ETF',
    name: 'CI Lawrence Park Alternative Investment Grade Credit ETF',
    symbol: 'CRED.TO',
  },
  {
    value: 'CMAR.TO',
    label: 'CMAR.TO,  CI Marret Alternative Absolute Return Bond ETF',
    name: 'CI Marret Alternative Absolute Return Bond ETF',
    symbol: 'CMAR.TO',
  },
  {
    value: 'CMEY.TO',
    label: 'CMEY.TO,  CI Marret Alternative Enhanced Yield Fund',
    name: 'CI Marret Alternative Enhanced Yield Fund',
    symbol: 'CMEY.TO',
  },
  {
    value: 'CMAG.TO',
    label: 'CMAG.TO,  CI Munro Alternative Global Growth ETF',
    name: 'CI Munro Alternative Global Growth ETF',
    symbol: 'CMAG.TO',
  },
  {
    value: 'ONEQ.TO',
    label: 'ONEQ.TO,  CI ONE Global Equity ETF',
    name: 'CI ONE Global Equity ETF',
    symbol: 'ONEQ.TO',
  },
  {
    value: 'ONEB.TO',
    label: 'ONEB.TO,  CI ONE North American Core Plus Bond ETF',
    name: 'CI ONE North American Core Plus Bond ETF',
    symbol: 'ONEB.TO',
  },
  {
    value: 'DGRC.TO',
    label: 'DGRC.TO,  CI WisdomTree Canada Quality Dividend Growth Index ETF',
    name: 'CI WisdomTree Canada Quality Dividend Growth Index ETF',
    symbol: 'DGRC.TO',
  },
  {
    value: 'JAPN.TO',
    label: 'JAPN.TO,  CI WisdomTree Japan Equity Index ETF Hedged',
    name: 'CI WisdomTree Japan Equity Index ETF Hedged',
    symbol: 'JAPN.TO',
  },
  {
    value: 'UMI.TO',
    label: 'UMI.TO,  CI WisdomTree U.S. MidCap Dividend Index ETF',
    name: 'CI WisdomTree U.S. MidCap Dividend Index ETF',
    symbol: 'UMI.TO',
  },
  {
    value: 'CAGG.TO',
    label: 'CAGG.TO,  CI Yield Enhanced Canada Aggregate Bond Index ETF',
    name: 'CI Yield Enhanced Canada Aggregate Bond Index ETF',
    symbol: 'CAGG.TO',
  },
  {
    value: 'CAGS.TO',
    label:
      'CAGS.TO,  CI Yield Enhanced Canada Short-Term Aggregate Bond Index ETF',
    name: 'CI Yield Enhanced Canada Short-Term Aggregate Bond Index ETF',
    symbol: 'CAGS.TO',
  },
  {
    value: 'CACB.TO',
    label: 'CACB.TO,  CIBC Active Investment Grade Corporate Bond ETF',
    name: 'CIBC Active Investment Grade Corporate Bond ETF',
    symbol: 'CACB.TO',
  },
  {
    value: 'CAFR.TO',
    label: 'CAFR.TO,  CIBC Active Investment Grade Floating Rate Bond ETF',
    name: 'CIBC Active Investment Grade Floating Rate Bond ETF',
    symbol: 'CAFR.TO',
  },
  {
    value: 'CFLX.TO',
    label: 'CFLX.TO,  CIBC Flexible Yield ETF (CAD-Hedged)',
    name: 'CIBC Flexible Yield ETF (CAD-Hedged)',
    symbol: 'CFLX.TO',
  },
  {
    value: 'CGLO.TO',
    label: 'CGLO.TO,  CIBC Global Growth ETF',
    name: 'CIBC Global Growth ETF',
    symbol: 'CGLO.TO',
  },
  {
    value: 'CINT.TO',
    label: 'CINT.TO,  CIBC International Equity ETF',
    name: 'CIBC International Equity ETF',
    symbol: 'CINT.TO',
  },
  {
    value: 'COG.TO',
    label: 'COG.TO,  Condor Gold Plc',
    name: 'Condor Gold Plc',
    symbol: 'COG.TO',
  },
  {
    value: 'CMMC.TO',
    label: 'CMMC.TO,  Copper Mountain Mining Corporation',
    name: 'Copper Mountain Mining Corporation',
    symbol: 'CMMC.TO',
  },
  {
    value: 'CRON.TO',
    label: 'CRON.TO,  Cronos Group Inc.',
    name: 'Cronos Group Inc.',
    symbol: 'CRON.TO',
  },
  {
    value: 'CRWN.TO',
    label: 'CRWN.TO,  Crown Capital Partners Inc.',
    name: 'Crown Capital Partners Inc.',
    symbol: 'CRWN.TO',
  },
  {
    value: 'DN.TO',
    label: 'DN.TO,  Delta 9 Cannabis Inc.',
    name: 'Delta 9 Cannabis Inc.',
    symbol: 'DN.TO',
  },
  {
    value: 'DCC.TO',
    label:
      'DCC.TO,  Desjardins 1-5 Year Laddered Canadian Corporate Bond Index ETF',
    name: 'Desjardins 1-5 Year Laddered Canadian Corporate Bond Index ETF',
    symbol: 'DCC.TO',
  },
  {
    value: 'DCG.TO',
    label:
      'DCG.TO,  Desjardins 1-5 Year Laddered Canadian Government Bond Index ETF',
    name: 'Desjardins 1-5 Year Laddered Canadian Government Bond Index ETF',
    symbol: 'DCG.TO',
  },
  {
    value: 'DANC.TO',
    label: 'DANC.TO,  Desjardins Alt Long/Short Equity Market Neutral ETF',
    name: 'Desjardins Alt Long/Short Equity Market Neutral ETF',
    symbol: 'DANC.TO',
  },
  {
    value: 'DCP.TO',
    label: 'DCP.TO,  Desjardins Canadian Preferred Share Index ETF',
    name: 'Desjardins Canadian Preferred Share Index ETF',
    symbol: 'DCP.TO',
  },
  {
    value: 'DCS.TO',
    label: 'DCS.TO,  Desjardins Canadian Short Term Bond Index ETF',
    name: 'Desjardins Canadian Short Term Bond Index ETF',
    symbol: 'DCS.TO',
  },
  {
    value: 'DCU.TO',
    label: 'DCU.TO,  Desjardins Canadian Universe Bond Index ETF',
    name: 'Desjardins Canadian Universe Bond Index ETF',
    symbol: 'DCU.TO',
  },
  {
    value: 'DRCU.TO',
    label: 'DRCU.TO,  Desjardins RI Active Canadian Bond',
    name: 'Desjardins RI Active Canadian Bond',
    symbol: 'DRCU.TO',
  },
  {
    value: 'DRMC.TO',
    label: 'DRMC.TO,  Desjardins RI Canada',
    name: 'Desjardins RI Canada',
    symbol: 'DRMC.TO',
  },
  {
    value: 'DRFC.TO',
    label: 'DRFC.TO,  Desjardins RI Canada Multifactor',
    name: 'Desjardins RI Canada Multifactor',
    symbol: 'DRFC.TO',
  },
  {
    value: 'DRMD.TO',
    label:
      'DRMD.TO,  Desjardins RI Developed ex-USA ex-Canada Low CO2 Index ETF',
    name: 'Desjardins RI Developed ex-USA ex-Canada Low CO2 Index ETF',
    symbol: 'DRMD.TO',
  },
  {
    value: 'DRFD.TO',
    label: 'DRFD.TO,  Desjardins RI Developed ex-USA ex-Canada Multifactor',
    name: 'Desjardins RI Developed ex-USA ex-Canada Multifactor',
    symbol: 'DRFD.TO',
  },
  {
    value: 'DRFE.TO',
    label: 'DRFE.TO,  Desjardins RI Emerging Markets Multifactor',
    name: 'Desjardins RI Emerging Markets Multifactor',
    symbol: 'DRFE.TO',
  },
  {
    value: 'DRFG.TO',
    label: 'DRFG.TO,  Desjardins RI Global Multifactor',
    name: 'Desjardins RI Global Multifactor',
    symbol: 'DRFG.TO',
  },
  {
    value: 'DRMU.TO',
    label: 'DRMU.TO,  Desjardins RI USA',
    name: 'Desjardins RI USA',
    symbol: 'DRMU.TO',
  },
  {
    value: 'DRFU.TO',
    label: 'DRFU.TO,  Desjardins RI USA Multifactor',
    name: 'Desjardins RI USA Multifactor',
    symbol: 'DRFU.TO',
  },
  {
    value: 'DCBO.TO',
    label: 'DCBO.TO,  Docebo Inc.',
    name: 'Docebo Inc.',
    symbol: 'DCBO.TO',
  },
  {
    value: 'DND.TO',
    label: 'DND.TO,  Dye & Durham Limited',
    name: 'Dye & Durham Limited',
    symbol: 'DND.TO',
  },
  {
    value: 'DYA.TO',
    label: 'DYA.TO,  dynaCERT Inc.',
    name: 'dynaCERT Inc.',
    symbol: 'DYA.TO',
  },
  {
    value: 'DXC.TO',
    label: 'DXC.TO,  Dynamic Active Canadian Dividend ETF',
    name: 'Dynamic Active Canadian Dividend ETF',
    symbol: 'DXC.TO',
  },
  {
    value: 'DXO.TO',
    label: 'DXO.TO,  Dynamic Active Crossover Bond ETF',
    name: 'Dynamic Active Crossover Bond ETF',
    symbol: 'DXO.TO',
  },
  {
    value: 'DXG.TO',
    label: 'DXG.TO,  Dynamic Active Global Dividend ETF',
    name: 'Dynamic Active Global Dividend ETF',
    symbol: 'DXG.TO',
  },
  {
    value: 'DXF.TO',
    label: 'DXF.TO,  Dynamic Active Global Financial Services ETF',
    name: 'Dynamic Active Global Financial Services ETF',
    symbol: 'DXF.TO',
  },
  {
    value: 'DXN.TO',
    label: 'DXN.TO,  Dynamic Active Global Infrastructure ETF',
    name: 'Dynamic Active Global Infrastructure ETF',
    symbol: 'DXN.TO',
  },
  {
    value: 'DXW.TO',
    label: 'DXW.TO,  Dynamic Active International Dividend ETF',
    name: 'Dynamic Active International Dividend ETF',
    symbol: 'DXW.TO',
  },
  {
    value: 'DXV.TO',
    label: 'DXV.TO,  Dynamic Active Investment Grade Floating Rate ETF',
    name: 'Dynamic Active Investment Grade Floating Rate ETF',
    symbol: 'DXV.TO',
  },
  {
    value: 'DXP.TO',
    label: 'DXP.TO,  Dynamic Active Preferred Shares ETF',
    name: 'Dynamic Active Preferred Shares ETF',
    symbol: 'DXP.TO',
  },
  {
    value: 'DXB.TO',
    label: 'DXB.TO,  Dynamic Active Tactical Bond ETF',
    name: 'Dynamic Active Tactical Bond ETF',
    symbol: 'DXB.TO',
  },
  {
    value: 'DXU.TO',
    label: 'DXU.TO,  Dynamic Active U.S. Dividend ETF',
    name: 'Dynamic Active U.S. Dividend ETF',
    symbol: 'DXU.TO',
  },
  {
    value: 'DXZ.TO',
    label: 'DXZ.TO,  Dynamic Active U.S. Mid-Cap ETF',
    name: 'Dynamic Active U.S. Mid-Cap ETF',
    symbol: 'DXZ.TO',
  },
  {
    value: 'ENS.TO',
    label: 'ENS.TO,  E Split Corp.',
    name: 'E Split Corp.',
    symbol: 'ENS.TO',
  },
  {
    value: 'E.TO',
    label: 'E.TO,  Enterprise Group, Inc.',
    name: 'Enterprise Group, Inc.',
    symbol: 'E.TO',
  },
  {
    value: 'EGLX.TO',
    label: 'EGLX.TO,  Enthusiast Gaming Holdings Inc.',
    name: 'Enthusiast Gaming Holdings Inc.',
    symbol: 'EGLX.TO',
  },
  {
    value: 'EQX.TO',
    label: 'EQX.TO,  Equinox Gold Corp.',
    name: 'Equinox Gold Corp.',
    symbol: 'EQX.TO',
  },
  {
    value: 'ERO.TO',
    label: 'ERO.TO,  Ero Copper Corp.',
    name: 'Ero Copper Corp.',
    symbol: 'ERO.TO',
  },
  {
    value: 'DIVS.TO',
    label: 'DIVS.TO,  Evolve Active Canadian Preferred Share Fund',
    name: 'Evolve Active Canadian Preferred Share Fund',
    symbol: 'DIVS.TO',
  },
  {
    value: 'EARN.TO',
    label: 'EARN.TO,  Evolve Active Global Fixed Income Fund',
    name: 'Evolve Active Global Fixed Income Fund',
    symbol: 'EARN.TO',
  },
  {
    value: 'CARS.TO',
    label: 'CARS.TO,  Evolve Automobile Innovation Index Fund',
    name: 'Evolve Automobile Innovation Index Fund',
    symbol: 'CARS.TO',
  },
  {
    value: 'CYBR.TO',
    label: 'CYBR.TO,  Evolve Cyber Security Index Fund - Hedged Units',
    name: 'Evolve Cyber Security Index Fund - Hedged Units',
    symbol: 'CYBR.TO',
  },
  {
    value: 'HERO.TO',
    label: 'HERO.TO,  Evolve E-Gaming Index ETF',
    name: 'Evolve E-Gaming Index ETF',
    symbol: 'HERO.TO',
  },
  {
    value: 'LEAD.TO',
    label: 'LEAD.TO,  Evolve Future Leadership Hedged',
    name: 'Evolve Future Leadership Hedged',
    symbol: 'LEAD.TO',
  },
  {
    value: 'LIFE.TO',
    label: 'LIFE.TO,  Evolve Global Healthcare Enhanced Yield Fund',
    name: 'Evolve Global Healthcare Enhanced Yield Fund',
    symbol: 'LIFE.TO',
  },
  {
    value: 'BASE.TO',
    label:
      'BASE.TO,  Evolve Global Materials & Mining Enhanced Yield Index ETF',
    name: 'Evolve Global Materials & Mining Enhanced Yield Index ETF',
    symbol: 'BASE.TO',
  },
  {
    value: 'EDGE.TO',
    label: 'EDGE.TO,  Evolve Innovation Index Fund',
    name: 'Evolve Innovation Index Fund',
    symbol: 'EDGE.TO',
  },
  {
    value: 'CALL.TO',
    label: 'CALL.TO,  Evolve US Banks Enhanced Yield Fund Hedged Units',
    name: 'Evolve US Banks Enhanced Yield Fund Hedged Units',
    symbol: 'CALL.TO',
  },
  {
    value: 'MIN.TO',
    label: 'MIN.TO,  Excelsior Mining Corp.',
    name: 'Excelsior Mining Corp.',
    symbol: 'MIN.TO',
  },
  {
    value: 'EGIF.TO',
    label: 'EGIF.TO,  Exemplar Growth and Income Fund',
    name: 'Exemplar Growth and Income Fund',
    symbol: 'EGIF.TO',
  },
  {
    value: 'FCCD.TO',
    label: 'FCCD.TO,  Fidelity Canadian High Dividend Index ETF',
    name: 'Fidelity Canadian High Dividend Index ETF',
    symbol: 'FCCD.TO',
  },
  {
    value: 'FCCQ.TO',
    label: 'FCCQ.TO,  Fidelity Canadian High Quality Index ETF',
    name: 'Fidelity Canadian High Quality Index ETF',
    symbol: 'FCCQ.TO',
  },
  {
    value: 'FCCL.TO',
    label: 'FCCL.TO,  Fidelity Canadian Low Volatility Index ETF',
    name: 'Fidelity Canadian Low Volatility Index ETF',
    symbol: 'FCCL.TO',
  },
  {
    value: 'FCCM.TO',
    label: 'FCCM.TO,  Fidelity Canadian Momentum Index ETF',
    name: 'Fidelity Canadian Momentum Index ETF',
    symbol: 'FCCM.TO',
  },
  {
    value: 'FCMI.TO',
    label: 'FCMI.TO,  Fidelity Canadian Monthly High Income ETF',
    name: 'Fidelity Canadian Monthly High Income ETF',
    symbol: 'FCMI.TO',
  },
  {
    value: 'FCSB.TO',
    label: 'FCSB.TO,  Fidelity Canadian Short Term Corporate Bond ETF',
    name: 'Fidelity Canadian Short Term Corporate Bond ETF',
    symbol: 'FCSB.TO',
  },
  {
    value: 'FCCV.TO',
    label: 'FCCV.TO,  Fidelity Canadian Value Index ETF',
    name: 'Fidelity Canadian Value Index ETF',
    symbol: 'FCCV.TO',
  },
  {
    value: 'FCGB.TO',
    label: 'FCGB.TO,  Fidelity Global Core Plus Bond ETF',
    name: 'Fidelity Global Core Plus Bond ETF',
    symbol: 'FCGB.TO',
  },
  {
    value: 'FCIG.TO',
    label: 'FCIG.TO,  Fidelity Global Investment Grade Bond ETF',
    name: 'Fidelity Global Investment Grade Bond ETF',
    symbol: 'FCIG.TO',
  },
  {
    value: 'FCGI.TO',
    label: 'FCGI.TO,  Fidelity Global Monthly High Income ETF',
    name: 'Fidelity Global Monthly High Income ETF',
    symbol: 'FCGI.TO',
  },
  {
    value: 'FCID.TO',
    label: 'FCID.TO,  Fidelity International High Dividend Index ETF',
    name: 'Fidelity International High Dividend Index ETF',
    symbol: 'FCID.TO',
  },
  {
    value: 'FCIQ.TO',
    label: 'FCIQ.TO,  Fidelity International High Quality Index ETF',
    name: 'Fidelity International High Quality Index ETF',
    symbol: 'FCIQ.TO',
  },
  {
    value: 'FCIL.TO',
    label: 'FCIL.TO,  Fidelity International Low Volatility Index ETF',
    name: 'Fidelity International Low Volatility Index ETF',
    symbol: 'FCIL.TO',
  },
  {
    value: 'FCIM.TO',
    label: 'FCIM.TO,  Fidelity International Momentum Index ETF',
    name: 'Fidelity International Momentum Index ETF',
    symbol: 'FCIM.TO',
  },
  {
    value: 'FCIV.TO',
    label: 'FCIV.TO,  Fidelity International Value Index ETF',
    name: 'Fidelity International Value Index ETF',
    symbol: 'FCIV.TO',
  },
  {
    value: 'FCCB.TO',
    label: 'FCCB.TO,  Fidelity Systematic Canadian Bond Index ETF',
    name: 'Fidelity Systematic Canadian Bond Index ETF',
    symbol: 'FCCB.TO',
  },
  {
    value: 'FCRH.TO',
    label:
      'FCRH.TO,  Fidelity U.S. Dividend for Rising Rates Currency Neutral Index ETF',
    name: 'Fidelity U.S. Dividend for Rising Rates Currency Neutral Index ETF',
    symbol: 'FCRH.TO',
  },
  {
    value: 'FCRR.TO',
    label: 'FCRR.TO,  Fidelity U.S. Dividend for Rising Rates Index ETF',
    name: 'Fidelity U.S. Dividend for Rising Rates Index ETF',
    symbol: 'FCRR.TO',
  },
  {
    value: 'FCUH.TO',
    label: 'FCUH.TO,  Fidelity U.S. High Dividend Currency Neutral Index ETF',
    name: 'Fidelity U.S. High Dividend Currency Neutral Index ETF',
    symbol: 'FCUH.TO',
  },
  {
    value: 'FCUD.TO',
    label: 'FCUD.TO,  Fidelity U.S. High Dividend Index ETF',
    name: 'Fidelity U.S. High Dividend Index ETF',
    symbol: 'FCUD.TO',
  },
  {
    value: 'FCQH.TO',
    label: 'FCQH.TO,  Fidelity U.S. High Quality Currency Neutral Index ETF',
    name: 'Fidelity U.S. High Quality Currency Neutral Index ETF',
    symbol: 'FCQH.TO',
  },
  {
    value: 'FCUQ.TO',
    label: 'FCUQ.TO,  Fidelity U.S. High Quality Index ETF',
    name: 'Fidelity U.S. High Quality Index ETF',
    symbol: 'FCUQ.TO',
  },
  {
    value: 'FCUL.TO',
    label: 'FCUL.TO,  Fidelity U.S. Low Volatility Index ETF',
    name: 'Fidelity U.S. Low Volatility Index ETF',
    symbol: 'FCUL.TO',
  },
  {
    value: 'FCMO.TO',
    label: 'FCMO.TO,  Fidelity U.S. Momentum Index ETF',
    name: 'Fidelity U.S. Momentum Index ETF',
    symbol: 'FCMO.TO',
  },
  {
    value: 'FCVH.TO',
    label: 'FCVH.TO,  Fidelity U.S. Value Currency Neutral Index ETF',
    name: 'Fidelity U.S. Value Currency Neutral Index ETF',
    symbol: 'FCVH.TO',
  },
  {
    value: 'FCUV.TO',
    label: 'FCUV.TO,  Fidelity U.S. Value Index ETF',
    name: 'Fidelity U.S. Value Index ETF',
    symbol: 'FCUV.TO',
  },
  {
    value: 'FAF.TO',
    label: 'FAF.TO,  Fire & Flower Holdings Corp.',
    name: 'Fire & Flower Holdings Corp.',
    symbol: 'FAF.TO',
  },
  {
    value: 'FF.TO',
    label: 'FF.TO,  First Mining Gold Corp.',
    name: 'First Mining Gold Corp.',
    symbol: 'FF.TO',
  },
  {
    value: 'FINT.TO',
    label: 'FINT.TO,  First Trust International Capital Strength ETF',
    name: 'First Trust International Capital Strength ETF',
    symbol: 'FINT.TO',
  },
  {
    value: 'FLCI.TO',
    label: 'FLCI.TO,  Franklin Bissett Corporate Bond Active ETF',
    name: 'Franklin Bissett Corporate Bond Active ETF',
    symbol: 'FLCI.TO',
  },
  {
    value: 'FLBA.TO',
    label: 'FLBA.TO,  Franklin Core Balanced Active ETF',
    name: 'Franklin Core Balanced Active ETF',
    symbol: 'FLBA.TO',
  },
  {
    value: 'FLCP.TO',
    label: 'FLCP.TO,  Franklin Bissett Core Plus Bond Act ETF',
    name: 'Franklin Bissett Core Plus Bond Act ETF',
    symbol: 'FLCP.TO',
  },
  {
    value: 'FLGA.TO',
    label: 'FLGA.TO,  Franklin Global Aggregate Bond Active ETF (CAD-Hedged)',
    name: 'Franklin Global Aggregate Bond Active ETF (CAD-Hedged)',
    symbol: 'FLGA.TO',
  },
  {
    value: 'FLRM.TO',
    label: 'FLRM.TO,  Franklin Risk Managed Canadian Equity Active ETF',
    name: 'Franklin Risk Managed Canadian Equity Active ETF',
    symbol: 'FLRM.TO',
  },
  {
    value: 'FLSD.TO',
    label: 'FLSD.TO,  Franklin Bissett Short Duration Bond Active ETF',
    name: 'Franklin Bissett Short Duration Bond Active ETF',
    symbol: 'FLSD.TO',
  },
  {
    value: 'FLEM.TO',
    label: 'FLEM.TO,  Franklin Emerging Markets Multifactor Index ETF',
    name: 'Franklin Emerging Markets Multifactor Index ETF',
    symbol: 'FLEM.TO',
  },
  {
    value: 'FLGD.TO',
    label: 'FLGD.TO,  Franklin Global Dividend Quality Index ETF',
    name: 'Franklin Global Dividend Quality Index ETF',
    symbol: 'FLGD.TO',
  },
  {
    value: 'FLDM.TO',
    label: 'FLDM.TO,  Franklin International Multifactor Index ETF',
    name: 'Franklin International Multifactor Index ETF',
    symbol: 'FLDM.TO',
  },
  {
    value: 'FLUS.TO',
    label: 'FLUS.TO,  Franklin U.S. Large Cap Multifactor Index ETF',
    name: 'Franklin U.S. Large Cap Multifactor Index ETF',
    symbol: 'FLUS.TO',
  },
  {
    value: 'FRII.TO',
    label: 'FRII.TO,  Freshii Inc.',
    name: 'Freshii Inc.',
    symbol: 'FRII.TO',
  },
  {
    value: 'FEC.TO',
    label: 'FEC.TO,  Frontera Energy Corporation',
    name: 'Frontera Energy Corporation',
    symbol: 'FEC.TO',
  },
  {
    value: 'GLXY.TO',
    label: 'GLXY.TO,  Galaxy Digital Holdings Ltd.',
    name: 'Galaxy Digital Holdings Ltd.',
    symbol: 'GLXY.TO',
  },
  {
    value: 'GAU.TO',
    label: 'GAU.TO,  Galiano Gold Inc.',
    name: 'Galiano Gold Inc.',
    symbol: 'GAU.TO',
  },
  {
    value: 'GENM.TO',
    label: 'GENM.TO,  Generation Mining Limited',
    name: 'Generation Mining Limited',
    symbol: 'GENM.TO',
  },
  {
    value: 'GFL.TO',
    label: 'GFL.TO,  GFL Environmental Inc.',
    name: 'GFL Environmental Inc.',
    symbol: 'GFL.TO',
  },
  {
    value: 'GLO.TO',
    label: 'GLO.TO,  Global Atomic Corporation',
    name: 'Global Atomic Corporation',
    symbol: 'GLO.TO',
  },
  {
    value: 'GDV.TO',
    label: 'GDV.TO,  Global Dividend Growth Split Corp.',
    name: 'Global Dividend Growth Split Corp.',
    symbol: 'GDV.TO',
  },
  {
    value: 'GWR.TO',
    label: 'GWR.TO,  Global Water Resources, Inc.',
    name: 'Global Water Resources, Inc.',
    symbol: 'GWR.TO',
  },
  {
    value: 'GRC.TO',
    label: 'GRC.TO,  Gold Springs Resource Corp.',
    name: 'Gold Springs Resource Corp.',
    symbol: 'GRC.TO',
  },
  {
    value: 'AUMN.TO',
    label: 'AUMN.TO,  Golden Minerals Company',
    name: 'Golden Minerals Company',
    symbol: 'AUMN.TO',
  },
  {
    value: 'GOLD.TO',
    label: 'GOLD.TO,  GoldMining Inc.',
    name: 'GoldMining Inc.',
    symbol: 'GOLD.TO',
  },
  {
    value: 'FOOD.TO',
    label: 'FOOD.TO,  Goodfood Market Corp.',
    name: 'Goodfood Market Corp.',
    symbol: 'FOOD.TO',
  },
  {
    value: 'GTMS.TO',
    label: 'GTMS.TO,  Greenbrook TMS Inc.',
    name: 'Greenbrook TMS Inc.',
    symbol: 'GTMS.TO',
  },
  {
    value: 'GCG.TO',
    label: 'GCG.TO,  Guardian Capital Group Limited',
    name: 'Guardian Capital Group Limited',
    symbol: 'GCG.TO',
  },
  {
    value: 'GDEP.TO',
    label: 'GDEP.TO,  Guardian Directed Equity Path ETF Hedged',
    name: 'Guardian Directed Equity Path ETF Hedged',
    symbol: 'GDEP.TO',
  },
  {
    value: 'GDPY.TO',
    label: 'GDPY.TO,  Guardian Directed Premium Yield ETF Unhedged',
    name: 'Guardian Directed Premium Yield ETF Unhedged',
    symbol: 'GDPY.TO',
  },
  {
    value: 'GIQG.TO',
    label: 'GIQG.TO,  Guardian i3 Global Quality Growth ETF Unhedged',
    name: 'Guardian i3 Global Quality Growth ETF Unhedged',
    symbol: 'GIQG.TO',
  },
  {
    value: 'GIGR.TO',
    label: 'GIGR.TO,  Guardian i3 Global REIT ETF Unhedged',
    name: 'Guardian i3 Global REIT ETF Unhedged',
    symbol: 'GIGR.TO',
  },
  {
    value: 'GIQU.TO',
    label: 'GIQU.TO,  Guardian i3 US Quality Growth ETF Hedged',
    name: 'Guardian i3 US Quality Growth ETF Hedged',
    symbol: 'GIQU.TO',
  },
  {
    value: 'HBA.TO',
    label: 'HBA.TO,  Hamilton Australian Bank Equal-Weight Index ETF',
    name: 'Hamilton Australian Bank Equal-Weight Index ETF',
    symbol: 'HBA.TO',
  },
  {
    value: 'HCA.TO',
    label: 'HCA.TO,  Hamilton Canadian Bank Mean Reversion Index ETF',
    name: 'Hamilton Canadian Bank Mean Reversion Index ETF',
    symbol: 'HCA.TO',
  },
  {
    value: 'HFT.TO',
    label: 'HFT.TO,  Hamilton Financials Innovation ETF',
    name: 'Hamilton Financials Innovation ETF',
    symbol: 'HFT.TO',
  },
  {
    value: 'HFG.TO',
    label: 'HFG.TO,  Hamilton Global Financials ETF',
    name: 'Hamilton Global Financials ETF',
    symbol: 'HFG.TO',
  },
  {
    value: 'HUM.TO',
    label: 'HUM.TO,  Hamilton U.S. Mid/Small-Cap Financials ETF',
    name: 'Hamilton U.S. Mid/Small-Cap Financials ETF',
    symbol: 'HUM.TO',
  },
  {
    value: 'HDI.TO',
    label: 'HDI.TO,  Hardwoods Distribution Inc.',
    name: 'Hardwoods Distribution Inc.',
    symbol: 'HDI.TO',
  },
  {
    value: 'HUTL.TO',
    label: 'HUTL.TO,  Harvest Equal Weight Global Utilities Income ETF',
    name: 'Harvest Equal Weight Global Utilities Income ETF',
    symbol: 'HUTL.TO',
  },
  {
    value: 'HGGG.TO',
    label: 'HGGG.TO,  Harvest Global Gold Giants Index ETF',
    name: 'Harvest Global Gold Giants Index ETF',
    symbol: 'HGGG.TO',
  },
  {
    value: 'HGR.TO',
    label: 'HGR.TO,  Harvest Global REIT Leaders Income ETF',
    name: 'Harvest Global REIT Leaders Income ETF',
    symbol: 'HGR.TO',
  },
  {
    value: 'HTA.TO',
    label: 'HTA.TO,  Harvest Tech Achievers Growth & Income ETF',
    name: 'Harvest Tech Achievers Growth & Income ETF',
    symbol: 'HTA.TO',
  },
  {
    value: 'HUBL.TO',
    label: 'HUBL.TO,  Harvest US Bank Leaders Income ETF Class A',
    name: 'Harvest US Bank Leaders Income ETF Class A',
    symbol: 'HUBL.TO',
  },
  {
    value: 'HWX.TO',
    label: 'HWX.TO,  Headwater Exploration Inc.',
    name: 'Headwater Exploration Inc.',
    symbol: 'HWX.TO',
  },
  {
    value: 'HEXO.TO',
    label: 'HEXO.TO,  HEXO Corp.',
    name: 'HEXO Corp.',
    symbol: 'HEXO.TO',
  },
  {
    value: 'HLS.TO',
    label: 'HLS.TO,  HLS Therapeutics Inc.',
    name: 'HLS Therapeutics Inc.',
    symbol: 'HLS.TO',
  },
  {
    value: 'HBAL.TO',
    label: 'HBAL.TO,  Horizons Balanced TRI ETF Portfolio',
    name: 'Horizons Balanced TRI ETF Portfolio',
    symbol: 'HBAL.TO',
  },
  {
    value: 'HBGD.TO',
    label: 'HBGD.TO,  Horizons Big Data & Hardware Index ETF',
    name: 'Horizons Big Data & Hardware Index ETF',
    symbol: 'HBGD.TO',
  },
  {
    value: 'HSAV.TO',
    label: 'HSAV.TO,  Horizons Cash Maximizer ETF',
    name: 'Horizons Cash Maximizer ETF',
    symbol: 'HSAV.TO',
  },
  {
    value: 'HCON.TO',
    label: 'HCON.TO,  Horizons Conservative TRI ETF Portfolio',
    name: 'Horizons Conservative TRI ETF Portfolio',
    symbol: 'HCON.TO',
  },
  {
    value: 'HXEM.TO',
    label: 'HXEM.TO,  Horizons Emerging Markets Equity Index ETF',
    name: 'Horizons Emerging Markets Equity Index ETF',
    symbol: 'HXEM.TO',
  },
  {
    value: 'HEWB.TO',
    label: 'HEWB.TO,  Horizons Equal Weight Canada Banks Index ETF',
    name: 'Horizons Equal Weight Canada Banks Index ETF',
    symbol: 'HEWB.TO',
  },
  {
    value: 'HCRE.TO',
    label: 'HCRE.TO,  Horizons Equal Weight Canada REIT Index ETF',
    name: 'Horizons Equal Weight Canada REIT Index ETF',
    symbol: 'HCRE.TO',
  },
  {
    value: 'HXX.TO',
    label: 'HXX.TO,  Horizons Europe 50 Index ETF',
    name: 'Horizons Europe 50 Index ETF',
    symbol: 'HXX.TO',
  },
  {
    value: 'ETHI.TO',
    label: 'ETHI.TO,  Horizons Global Sustainability Leaders Index ETF',
    name: 'Horizons Global Sustainability Leaders Index ETF',
    symbol: 'ETHI.TO',
  },
  {
    value: 'HURA.TO',
    label: 'HURA.TO,  Horizons Global Uranium Index ETF',
    name: 'Horizons Global Uranium Index ETF',
    symbol: 'HURA.TO',
  },
  {
    value: 'HGRO.TO',
    label: 'HGRO.TO,  Horizons Growth TRI ETF Portfolio',
    name: 'Horizons Growth TRI ETF Portfolio',
    symbol: 'HGRO.TO',
  },
  {
    value: 'FOUR.TO',
    label: 'FOUR.TO,  Horizons Industry 4.0 Index ETF',
    name: 'Horizons Industry 4.0 Index ETF',
    symbol: 'FOUR.TO',
  },
  {
    value: 'INOC.TO',
    label: 'INOC.TO,  Horizons Inovestor Canadian Equity Index ETF',
    name: 'Horizons Inovestor Canadian Equity Index ETF',
    symbol: 'INOC.TO',
  },
  {
    value: 'HXDM.TO',
    label: 'HXDM.TO,  Horizons Intl Developed Markets Equity Index ETF',
    name: 'Horizons Intl Developed Markets Equity Index ETF',
    symbol: 'HXDM.TO',
  },
  {
    value: 'HLPR.TO',
    label: 'HLPR.TO,  Horizons Laddered Canadian Preferred Share Index ETF',
    name: 'Horizons Laddered Canadian Preferred Share Index ETF',
    symbol: 'HLPR.TO',
  },
  {
    value: 'HMMJ.TO',
    label: 'HMMJ.TO,  Horizons Marijuana Life Sciences Index ETF',
    name: 'Horizons Marijuana Life Sciences Index ETF',
    symbol: 'HMMJ.TO',
  },
  {
    value: 'HRAA.TO',
    label: 'HRAA.TO,  Horizons ReSolve Adaptive Asset Allocation ETF',
    name: 'Horizons ReSolve Adaptive Asset Allocation ETF',
    symbol: 'HRAA.TO',
  },
  {
    value: 'RBOT.TO',
    label: 'RBOT.TO,  Horizons Robotics and Automation Index ETF',
    name: 'Horizons Robotics and Automation Index ETF',
    symbol: 'RBOT.TO',
  },
  {
    value: 'HXCN.TO',
    label: 'HXCN.TO,  Horizons S&P/TSX Capped Composite Index ETF',
    name: 'Horizons S&P/TSX Capped Composite Index ETF',
    symbol: 'HXCN.TO',
  },
  {
    value: 'HULC.TO',
    label: 'HULC.TO,  Horizons US Large Cap Index ETF',
    name: 'Horizons US Large Cap Index ETF',
    symbol: 'HULC.TO',
  },
  {
    value: 'HUT.TO',
    label: 'HUT.TO,  Hut 8 Mining Corp.',
    name: 'Hut 8 Mining Corp.',
    symbol: 'HUT.TO',
  },
  {
    value: 'ICPB.TO',
    label: 'ICPB.TO,  IA Clarington Core Plus Bond Fund Series ETF',
    name: 'IA Clarington Core Plus Bond Fund Series ETF',
    symbol: 'ICPB.TO',
  },
  {
    value: 'IFRF.TO',
    label: 'IFRF.TO,  IA Clarington Floating Rate Income Fund',
    name: 'IA Clarington Floating Rate Income Fund',
    symbol: 'IFRF.TO',
  },
  {
    value: 'IGAF.TO',
    label: 'IGAF.TO,  IA Clarington Loomis Global Allocation Fund',
    name: 'IA Clarington Loomis Global Allocation Fund',
    symbol: 'IGAF.TO',
  },
  {
    value: 'ISIF.TO',
    label: 'ISIF.TO,  IA Clarington Strategic Income Fund',
    name: 'IA Clarington Strategic Income Fund',
    symbol: 'ISIF.TO',
  },
  {
    value: 'IPCI.TO',
    label: 'IPCI.TO,  Intellipharmaceutics International Inc.',
    name: 'Intellipharmaceutics International Inc.',
    symbol: 'IPCI.TO',
  },
  {
    value: 'IPCO.TO',
    label: 'IPCO.TO,  International Petroleum Corporation',
    name: 'International Petroleum Corporation',
    symbol: 'IPCO.TO',
  },
  {
    value: 'EQL.TO',
    label: 'EQL.TO,  Invesco S&P 500 Equal Weight Index ETF',
    name: 'Invesco S&P 500 Equal Weight Index ETF',
    symbol: 'EQL.TO',
  },
  {
    value: 'ESG.TO',
    label: 'ESG.TO,  Invesco S&P 500 ESG Index ETF',
    name: 'Invesco S&P 500 ESG Index ETF',
    symbol: 'ESG.TO',
  },
  {
    value: 'REIT.TO',
    label: 'REIT.TO,  Invesco S&P/TSX REIT Income Index ETF',
    name: 'Invesco S&P/TSX REIT Income Index ETF',
    symbol: 'REIT.TO',
  },
  {
    value: 'IVQ.TO',
    label: 'IVQ.TO,  Invesque Inc.',
    name: 'Invesque Inc.',
    symbol: 'IVQ.TO',
  },
  {
    value: 'XBAL.TO',
    label: 'XBAL.TO,  iShares Core Balanced ETF Portfolio',
    name: 'iShares Core Balanced ETF Portfolio',
    symbol: 'XBAL.TO',
  },
  {
    value: 'XCNS.TO',
    label: 'XCNS.TO,  iShares Core Conservative Balanced ETF Portfolio',
    name: 'iShares Core Conservative Balanced ETF Portfolio',
    symbol: 'XCNS.TO',
  },
  {
    value: 'XEQT.TO',
    label: 'XEQT.TO,  iShares Core Equity ETF Portfolio',
    name: 'iShares Core Equity ETF Portfolio',
    symbol: 'XEQT.TO',
  },
  {
    value: 'XGRO.TO',
    label: 'XGRO.TO,  iShares Core Growth ETF Portfolio',
    name: 'iShares Core Growth ETF Portfolio',
    symbol: 'XGRO.TO',
  },
  {
    value: 'XINC.TO',
    label: 'XINC.TO,  iShares Core Income Balanced ETF Portfolio',
    name: 'iShares Core Income Balanced ETF Portfolio',
    symbol: 'XINC.TO',
  },
  {
    value: 'XDIV.TO',
    label: 'XDIV.TO,  iShares Core MSCI Canadian Quality Dividend Index ETF',
    name: 'iShares Core MSCI Canadian Quality Dividend Index ETF',
    symbol: 'XDIV.TO',
  },
  {
    value: 'XDG.TO',
    label: 'XDG.TO,  iShares Core MSCI Global Quality Dividend Index ETF',
    name: 'iShares Core MSCI Global Quality Dividend Index ETF',
    symbol: 'XDG.TO',
  },
  {
    value: 'XDGH.TO',
    label:
      'XDGH.TO,  iShares Core MSCI Global Quality Dividend Index ETF (CAD-Hedged)',
    name: 'iShares Core MSCI Global Quality Dividend Index ETF (CAD-Hedged)',
    symbol: 'XDGH.TO',
  },
  {
    value: 'XDU.TO',
    label: 'XDU.TO,  iShares Core MSCI US Quality Dividend Index ETF',
    name: 'iShares Core MSCI US Quality Dividend Index ETF',
    symbol: 'XDU.TO',
  },
  {
    value: 'XDUH.TO',
    label:
      'XDUH.TO,  iShares Core MSCI US Quality Dividend Index ETF (CAD-Hedged)',
    name: 'iShares Core MSCI US Quality Dividend Index ETF (CAD-Hedged)',
    symbol: 'XDUH.TO',
  },
  {
    value: 'XCSR.TO',
    label: 'XCSR.TO,  iShares ESG Advanced MSCI Canada Index ETF',
    name: 'iShares ESG Advanced MSCI Canada Index ETF',
    symbol: 'XCSR.TO',
  },
  {
    value: 'XDSR.TO',
    label: 'XDSR.TO,  iShares ESG Advanced MSCI EAFE Index ETF',
    name: 'iShares ESG Advanced MSCI EAFE Index ETF',
    symbol: 'XDSR.TO',
  },
  {
    value: 'XUSR.TO',
    label: 'XUSR.TO,  iShares ESG Advanced MSCI USA Index ETF',
    name: 'iShares ESG Advanced MSCI USA Index ETF',
    symbol: 'XUSR.TO',
  },
  {
    value: 'XESG.TO',
    label: 'XESG.TO,  iShares ESG Aware MSCI Canada Index ETF',
    name: 'iShares ESG Aware MSCI Canada Index ETF',
    symbol: 'XESG.TO',
  },
  {
    value: 'XSEA.TO',
    label: 'XSEA.TO,  iShares ESG Aware MSCI EAFE Index ETF',
    name: 'iShares ESG Aware MSCI EAFE Index ETF',
    symbol: 'XSEA.TO',
  },
  {
    value: 'XSEM.TO',
    label: 'XSEM.TO,  iShares ESG Aware MSCI Emerging Markets Index ETF',
    name: 'iShares ESG Aware MSCI Emerging Markets Index ETF',
    symbol: 'XSEM.TO',
  },
  {
    value: 'XSUS.TO',
    label: 'XSUS.TO,  iShares ESG Aware MSCI USA Index ETF',
    name: 'iShares ESG Aware MSCI USA Index ETF',
    symbol: 'XSUS.TO',
  },
  {
    value: 'GBAL.TO',
    label: 'GBAL.TO,  iShares ESG Balanced ETF Portfolio',
    name: 'iShares ESG Balanced ETF Portfolio',
    symbol: 'GBAL.TO',
  },
  {
    value: 'XSAB.TO',
    label: 'XSAB.TO,  iShares ESG Aware Canadian Aggregate Bond Index ETF',
    name: 'iShares ESG Aware Canadian Aggregate Bond Index ETF',
    symbol: 'XSAB.TO',
  },
  {
    value: 'XSTB.TO',
    label: 'XSTB.TO,  iShares ESG Aware Canadian Short Term Bond Index ETF',
    name: 'iShares ESG Aware Canadian Short Term Bond Index ETF',
    symbol: 'XSTB.TO',
  },
  {
    value: 'GCNS.TO',
    label: 'GCNS.TO,  iShares ESG Conservative Balanced ETF Port',
    name: 'iShares ESG Conservative Balanced ETF Port',
    symbol: 'GCNS.TO',
  },
  {
    value: 'GEQT.TO',
    label: 'GEQT.TO,  iShares ESG Equity ETF Portfolio',
    name: 'iShares ESG Equity ETF Portfolio',
    symbol: 'GEQT.TO',
  },
  {
    value: 'GGRO.TO',
    label: 'GGRO.TO,  iShares ESG Growth ETF Portfolio',
    name: 'iShares ESG Growth ETF Portfolio',
    symbol: 'GGRO.TO',
  },
  {
    value: 'XMTM.TO',
    label: 'XMTM.TO,  iShares MSCI USA Momentum Factor Index ETF',
    name: 'iShares MSCI USA Momentum Factor Index ETF',
    symbol: 'XMTM.TO',
  },
  {
    value: 'XQLT.TO',
    label: 'XQLT.TO,  iShares MSCI USA Quality Factor Index ETF',
    name: 'iShares MSCI USA Quality Factor Index ETF',
    symbol: 'XQLT.TO',
  },
  {
    value: 'XVLU.TO',
    label: 'XVLU.TO,  iShares MSCI USA Value Factor Index ETF',
    name: 'iShares MSCI USA Value Factor Index ETF',
    symbol: 'XVLU.TO',
  },
  {
    value: 'XSMC.TO',
    label: 'XSMC.TO,  iShares S&P U.S. Small-Cap Index ETF',
    name: 'iShares S&P U.S. Small-Cap Index ETF',
    symbol: 'XSMC.TO',
  },
  {
    value: 'XSMH.TO',
    label: 'XSMH.TO,  iShares S&P U.S. Small-Cap Index ETF (CAD-Hedged)',
    name: 'iShares S&P U.S. Small-Cap Index ETF (CAD-Hedged)',
    symbol: 'XSMH.TO',
  },
  {
    value: 'JWEL.TO',
    label: 'JWEL.TO,  Jamieson Wellness Inc.',
    name: 'Jamieson Wellness Inc.',
    symbol: 'JWEL.TO',
  },
  {
    value: 'KRR.TO',
    label: 'KRR.TO,  Karora Resources Inc.',
    name: 'Karora Resources Inc.',
    symbol: 'KRR.TO',
  },
  {
    value: 'LGD.TO',
    label: 'LGD.TO,  Liberty Gold Corp.',
    name: 'Liberty Gold Corp.',
    symbol: 'LGD.TO',
  },
  {
    value: 'LSPD.TO',
    label: 'LSPD.TO,  Lightspeed Commerce Inc.',
    name: 'Lightspeed Commerce Inc.',
    symbol: 'LSPD.TO',
  },
  {
    value: 'LXR.TO',
    label: 'LXR.TO,  LXRandCo, Inc.',
    name: 'LXRandCo, Inc.',
    symbol: 'LXR.TO',
  },
  {
    value: 'QBB.TO',
    label: 'QBB.TO,  Mackenzie Canadian Aggregate Bond Index ETF',
    name: 'Mackenzie Canadian Aggregate Bond Index ETF',
    symbol: 'QBB.TO',
  },
  {
    value: 'QCN.TO',
    label: 'QCN.TO,  Mackenzie Canadian Equity Index ETF',
    name: 'Mackenzie Canadian Equity Index ETF',
    symbol: 'QCN.TO',
  },
  {
    value: 'QCE.TO',
    label: 'QCE.TO,  Mackenzie Canadian Large Cap Equity Index ETF',
    name: 'Mackenzie Canadian Large Cap Equity Index ETF',
    symbol: 'QCE.TO',
  },
  {
    value: 'MCSB.TO',
    label: 'MCSB.TO,  Mackenzie Canadian Short Term Fixed Income ETF',
    name: 'Mackenzie Canadian Short Term Fixed Income ETF',
    symbol: 'MCSB.TO',
  },
  {
    value: 'QSB.TO',
    label: 'QSB.TO,  Mackenzie Canadian Short-Term Bond Index ETF',
    name: 'Mackenzie Canadian Short-Term Bond Index ETF',
    symbol: 'QSB.TO',
  },
  {
    value: 'QDX.TO',
    label: 'QDX.TO,  Mackenzie International Equity Index ETF',
    name: 'Mackenzie International Equity Index ETF',
    symbol: 'QDX.TO',
  },
  {
    value: 'MIVG.TO',
    label: 'MIVG.TO,  Mackenzie Ivy Global Equity ETF',
    name: 'Mackenzie Ivy Global Equity ETF',
    symbol: 'MIVG.TO',
  },
  {
    value: 'MEE.TO',
    label:
      'MEE.TO,  Mackenzie Maximum Diversification Emerging Markets Index ETF',
    name: 'Mackenzie Maximum Diversification Emerging Markets Index ETF',
    symbol: 'MEE.TO',
  },
  {
    value: 'MPCF.TO',
    label: 'MPCF.TO,  Mackenzie Portfolio Completion ETF',
    name: 'Mackenzie Portfolio Completion ETF',
    symbol: 'MPCF.TO',
  },
  {
    value: 'QHY.TO',
    label: 'QHY.TO,  Mackenzie US High Yield Bond Index ETF (CAD-Hedged)',
    name: 'Mackenzie US High Yield Bond Index ETF (CAD-Hedged)',
    symbol: 'QHY.TO',
  },
  {
    value: 'QUIG.TO',
    label:
      'QUIG.TO,  Mackenzie US Investment Grade Corporate Bond Index ETF (CAD-Hedged)',
    name: 'Mackenzie US Investment Grade Corporate Bond Index ETF (CAD-Hedged)',
    symbol: 'QUIG.TO',
  },
  {
    value: 'QUU.TO',
    label: 'QUU.TO,  Mackenzie US Large Cap Equity Index ETF',
    name: 'Mackenzie US Large Cap Equity Index ETF',
    symbol: 'QUU.TO',
  },
  {
    value: 'QAH.TO',
    label: 'QAH.TO,  Mackenzie US Large Cap Equity Index ETF (CAD-Hedged)',
    name: 'Mackenzie US Large Cap Equity Index ETF (CAD-Hedged)',
    symbol: 'QAH.TO',
  },
  {
    value: 'MCLC.TO',
    label: 'MCLC.TO,  Manulife Multifactor Canadian Large Cap Index ETF',
    name: 'Manulife Multifactor Canadian Large Cap Index ETF',
    symbol: 'MCLC.TO',
  },
  {
    value: 'MCSM.TO',
    label: 'MCSM.TO,  Manulife Multifactor Canadian SMID Cap Index ETF',
    name: 'Manulife Multifactor Canadian SMID Cap Index ETF',
    symbol: 'MCSM.TO',
  },
  {
    value: 'MINT.TO',
    label: 'MINT.TO,  Manulife Multifactor Developed International Index ETF',
    name: 'Manulife Multifactor Developed International Index ETF',
    symbol: 'MINT.TO',
  },
  {
    value: 'MULC.TO',
    label: 'MULC.TO,  Manulife Multifactor U.S. Large Cap Index ETF',
    name: 'Manulife Multifactor U.S. Large Cap Index ETF',
    symbol: 'MULC.TO',
  },
  {
    value: 'MUMC.TO',
    label: 'MUMC.TO,  Manulife Multifactor U.S. Mid Cap Index ETF',
    name: 'Manulife Multifactor U.S. Mid Cap Index ETF',
    symbol: 'MUMC.TO',
  },
  {
    value: 'MUSC.TO',
    label: 'MUSC.TO,  Manulife Multifactor U.S. Small Cap Index ETF',
    name: 'Manulife Multifactor U.S. Small Cap Index ETF',
    symbol: 'MUSC.TO',
  },
  {
    value: 'MARI.TO',
    label: 'MARI.TO,  Marimaca Copper Corp.',
    name: 'Marimaca Copper Corp.',
    symbol: 'MARI.TO',
  },
  {
    value: 'MAV.TO',
    label: 'MAV.TO,  MAV Beauty Brands Inc.',
    name: 'MAV Beauty Brands Inc.',
    symbol: 'MAV.TO',
  },
  {
    value: 'MMX.TO',
    label: 'MMX.TO,  Maverix Metals Inc.',
    name: 'Maverix Metals Inc.',
    symbol: 'MMX.TO',
  },
  {
    value: 'MAXR.TO',
    label: 'MAXR.TO,  Maxar Technologies Inc.',
    name: 'Maxar Technologies Inc.',
    symbol: 'MAXR.TO',
  },
  {
    value: 'MDNA.TO',
    label: 'MDNA.TO,  Medicenna Therapeutics Corp.',
    name: 'Medicenna Therapeutics Corp.',
    symbol: 'MDNA.TO',
  },
  {
    value: 'LABS.TO',
    label: 'LABS.TO,  MediPharm Labs Corp.',
    name: 'MediPharm Labs Corp.',
    symbol: 'LABS.TO',
  },
  {
    value: 'HWF.TO',
    label: 'HWF.TO,  Middlefield Health & Wellness ETF',
    name: 'Middlefield Health & Wellness ETF',
    symbol: 'HWF.TO',
  },
  {
    value: 'MPVD.TO',
    label: 'MPVD.TO,  Mountain Province Diamonds Inc.',
    name: 'Mountain Province Diamonds Inc.',
    symbol: 'MPVD.TO',
  },
  {
    value: 'NPRF.TO',
    label: 'NPRF.TO,  NBI Active Canadian Preferred Shares ETF',
    name: 'NBI Active Canadian Preferred Shares ETF',
    symbol: 'NPRF.TO',
  },
  {
    value: 'NFAM.TO',
    label: 'NFAM.TO,  NBI Canadian Family Business ETF',
    name: 'NBI Canadian Family Business ETF',
    symbol: 'NFAM.TO',
  },
  {
    value: 'NGPE.TO',
    label: 'NGPE.TO,  NBI Global Private Equity ETF',
    name: 'NBI Global Private Equity ETF',
    symbol: 'NGPE.TO',
  },
  {
    value: 'NREA.TO',
    label: 'NREA.TO,  NBI Global Real Assets Income ETF',
    name: 'NBI Global Real Assets Income ETF',
    symbol: 'NREA.TO',
  },
  {
    value: 'NHYB.TO',
    label: 'NHYB.TO,  NBI High Yield Bond ETF',
    name: 'NBI High Yield Bond ETF',
    symbol: 'NHYB.TO',
  },
  {
    value: 'NALT.TO',
    label: 'NALT.TO,  NBI Liquid Alternatives ETF',
    name: 'NBI Liquid Alternatives ETF',
    symbol: 'NALT.TO',
  },
  {
    value: 'NSCB.TO',
    label: 'NSCB.TO,  NBI Sustainable Canadian Bond ETF',
    name: 'NBI Sustainable Canadian Bond ETF',
    symbol: 'NSCB.TO',
  },
  {
    value: 'NSCE.TO',
    label: 'NSCE.TO,  NBI Sustainable Canadian Equity ETF',
    name: 'NBI Sustainable Canadian Equity ETF',
    symbol: 'NSCE.TO',
  },
  {
    value: 'NSGE.TO',
    label: 'NSGE.TO,  NBI Sustainable Global Equity ETF',
    name: 'NBI Sustainable Global Equity ETF',
    symbol: 'NSGE.TO',
  },
  {
    value: 'NUBF.TO',
    label: 'NUBF.TO,  NBI Unconstrained Fixed Income ETF',
    name: 'NBI Unconstrained Fixed Income ETF',
    symbol: 'NUBF.TO',
  },
  {
    value: 'NEO.TO',
    label: 'NEO.TO,  Neo Performance Materials Inc.',
    name: 'Neo Performance Materials Inc.',
    symbol: 'NEO.TO',
  },
  {
    value: 'NVCN.TO',
    label: 'NVCN.TO,  Neovasc Inc.',
    name: 'Neovasc Inc.',
    symbol: 'NVCN.TO',
  },
  {
    value: 'NUAG.TO',
    label: 'NUAG.TO,  New Pacific Metals Corp.',
    name: 'New Pacific Metals Corp.',
    symbol: 'NUAG.TO',
  },
  {
    value: 'NGT.TO',
    label: 'NGT.TO,  Newmont Corporation',
    name: 'Newmont Corporation',
    symbol: 'NGT.TO',
  },
  {
    value: 'NEXT.TO',
    label: 'NEXT.TO,  NextSource Materials Inc.',
    name: 'NextSource Materials Inc.',
    symbol: 'NEXT.TO',
  },
  {
    value: 'NCP.TO',
    label: 'NCP.TO,  Nickel Creek Platinum Corp.',
    name: 'Nickel Creek Platinum Corp.',
    symbol: 'NCP.TO',
  },
  {
    value: 'NHK.TO',
    label: 'NHK.TO,  Nighthawk Gold Corp.',
    name: 'Nighthawk Gold Corp.',
    symbol: 'NHK.TO',
  },
  {
    value: 'NZC.TO',
    label: 'NZC.TO,  NorZinc Ltd.',
    name: 'NorZinc Ltd.',
    symbol: 'NZC.TO',
  },
  {
    value: 'NVEI.TO',
    label: 'NVEI.TO,  Nuvei Corporation',
    name: 'Nuvei Corporation',
    symbol: 'NVEI.TO',
  },
  {
    value: 'OBE.TO',
    label: 'OBE.TO,  Obsidian Energy Ltd.',
    name: 'Obsidian Energy Ltd.',
    symbol: 'OBE.TO',
  },
  {
    value: 'OTEX.TO',
    label: 'OTEX.TO,  Open Text Corporation',
    name: 'Open Text Corporation',
    symbol: 'OTEX.TO',
  },
  {
    value: 'OPS.TO',
    label: 'OPS.TO,  Opsens Inc.',
    name: 'Opsens Inc.',
    symbol: 'OPS.TO',
  },
  {
    value: 'OPT.TO',
    label: 'OPT.TO,  Optiva Inc.',
    name: 'Optiva Inc.',
    symbol: 'OPT.TO',
  },
  {
    value: 'OREA.TO',
    label: 'OREA.TO,  Orea Mining Corp.',
    name: 'Orea Mining Corp.',
    symbol: 'OREA.TO',
  },
  {
    value: 'OGI.TO',
    label: 'OGI.TO,  OrganiGram Holdings Inc.',
    name: 'OrganiGram Holdings Inc.',
    symbol: 'OGI.TO',
  },
  {
    value: 'OLA.TO',
    label: 'OLA.TO,  Orla Mining Ltd.',
    name: 'Orla Mining Ltd.',
    symbol: 'OLA.TO',
  },
  {
    value: 'OVV.TO',
    label: 'OVV.TO,  Ovintiv Inc.',
    name: 'Ovintiv Inc.',
    symbol: 'OVV.TO',
  },
  {
    value: 'PAAS.TO',
    label: 'PAAS.TO,  Pan American Silver Corp.',
    name: 'Pan American Silver Corp.',
    symbol: 'PAAS.TO',
  },
  {
    value: 'PAT.TO',
    label: 'PAT.TO,  Patriot One Technologies Inc.',
    name: 'Patriot One Technologies Inc.',
    symbol: 'PAT.TO',
  },
  {
    value: 'PFAE.TO',
    label:
      'PFAE.TO,  Picton Mahoney Fortified Active Extension Alternative Fund',
    name: 'Picton Mahoney Fortified Active Extension Alternative Fund',
    symbol: 'PFAE.TO',
  },
  {
    value: 'PFIA.TO',
    label: 'PFIA.TO,  Picton Mahoney Fortified Income Alternative Fund',
    name: 'Picton Mahoney Fortified Income Alternative Fund',
    symbol: 'PFIA.TO',
  },
  {
    value: 'PFLS.TO',
    label: 'PFLS.TO,  Picton Mahoney Fortified Long Short Alternative Fund',
    name: 'Picton Mahoney Fortified Long Short Alternative Fund',
    symbol: 'PFLS.TO',
  },
  {
    value: 'PFMN.TO',
    label: 'PFMN.TO,  Picton Mahoney Fortified Market Neutral Alternative Fund',
    name: 'Picton Mahoney Fortified Market Neutral Alternative Fund',
    symbol: 'PFMN.TO',
  },
  {
    value: 'PFMS.TO',
    label: 'PFMS.TO,  Picton Mahoney Fortified Multi-Strategy Alternative Fund',
    name: 'Picton Mahoney Fortified Multi-Strategy Alternative Fund',
    symbol: 'PFMS.TO',
  },
  {
    value: 'PEA.TO',
    label: 'PEA.TO,  Pieridae Energy Limited',
    name: 'Pieridae Energy Limited',
    symbol: 'PEA.TO',
  },
  {
    value: 'PMNT.TO',
    label: 'PMNT.TO,  PIMCO Global Short Maturity Fund (Canada)',
    name: 'PIMCO Global Short Maturity Fund (Canada)',
    symbol: 'PMNT.TO',
  },
  {
    value: 'IGCF.TO',
    label: 'IGCF.TO,  PIMCO Investment Grade Credit Fund',
    name: 'PIMCO Investment Grade Credit Fund',
    symbol: 'IGCF.TO',
  },
  {
    value: 'PCON.TO',
    label: 'PCON.TO,  PIMCO Managed Conservative Bond Pool',
    name: 'PIMCO Managed Conservative Bond Pool',
    symbol: 'PCON.TO',
  },
  {
    value: 'PCOR.TO',
    label: 'PCOR.TO,  PIMCO Managed Core Bond Pool',
    name: 'PIMCO Managed Core Bond Pool',
    symbol: 'PCOR.TO',
  },
  {
    value: 'PMIF.TO',
    label: 'PMIF.TO,  PIMCO Monthly Income Fund (Canada)',
    name: 'PIMCO Monthly Income Fund (Canada)',
    symbol: 'PMIF.TO',
  },
  {
    value: 'PRMW.TO',
    label: 'PRMW.TO,  Primo Water Corporation',
    name: 'Primo Water Corporation',
    symbol: 'PRMW.TO',
  },
  {
    value: 'PRN.TO',
    label: 'PRN.TO,  Profound Medical Corp.',
    name: 'Profound Medical Corp.',
    symbol: 'PRN.TO',
  },
  {
    value: 'PDIV.TO',
    label: 'PDIV.TO,  Purpose Enhanced Dividend Fund',
    name: 'Purpose Enhanced Dividend Fund',
    symbol: 'PDIV.TO',
  },
  {
    value: 'PAYF.TO',
    label: 'PAYF.TO,  Purpose Enhanced Premium Yield Fund',
    name: 'Purpose Enhanced Premium Yield Fund',
    symbol: 'PAYF.TO',
  },
  {
    value: 'IGB.TO',
    label: 'IGB.TO,  Purpose Global Bond Class',
    name: 'Purpose Global Bond Class',
    symbol: 'IGB.TO',
  },
  {
    value: 'PINV.TO',
    label: 'PINV.TO,  Purpose Global Innovators Fd',
    name: 'Purpose Global Innovators Fd',
    symbol: 'PINV.TO',
  },
  {
    value: 'KILO.TO',
    label: 'KILO.TO,  Purpose Gold Bullion Fund',
    name: 'Purpose Gold Bullion Fund',
    symbol: 'KILO.TO',
  },
  {
    value: 'PINC.TO',
    label: 'PINC.TO,  Purpose Multi-Asset Income Fund',
    name: 'Purpose Multi-Asset Income Fund',
    symbol: 'PINC.TO',
  },
  {
    value: 'SBT.TO',
    label: 'SBT.TO,  Silver Bullion Trust ETF Currency Hedged',
    name: 'Silver Bullion Trust ETF Currency Hedged',
    symbol: 'SBT.TO',
  },
  {
    value: 'SYLD.TO',
    label: 'SYLD.TO,  Purpose Strategic Yield Fund',
    name: 'Purpose Strategic Yield Fund',
    symbol: 'SYLD.TO',
  },
  {
    value: 'QTRH.TO',
    label: 'QTRH.TO,  Quarterhill Inc.',
    name: 'Quarterhill Inc.',
    symbol: 'QTRH.TO',
  },
  {
    value: 'RBNK.TO',
    label: 'RBNK.TO,  RBC Canadian Bank Yield Index ETF',
    name: 'RBC Canadian Bank Yield Index ETF',
    symbol: 'RBNK.TO',
  },
  {
    value: 'RPSB.TO',
    label: 'RPSB.TO,  RBC PH&N Short Term Canadian Bond ETF',
    name: 'RBC PH&N Short Term Canadian Bond ETF',
    symbol: 'RPSB.TO',
  },
  {
    value: 'RIDH.TO',
    label: 'RIDH.TO,  RBC Quant EAFE Dividend Leaders (CAD Hedged) ETF',
    name: 'RBC Quant EAFE Dividend Leaders (CAD Hedged) ETF',
    symbol: 'RIDH.TO',
  },
  {
    value: 'RPDH.TO',
    label: 'RPDH.TO,  RBC Quant European Dividend Leaders (CAD Hedged) ETF',
    name: 'RBC Quant European Dividend Leaders (CAD Hedged) ETF',
    symbol: 'RPDH.TO',
  },
  {
    value: 'RUDH.TO',
    label: 'RUDH.TO,  RBC Quant U.S. Dividend Leaders (CAD Hedged) ETF',
    name: 'RBC Quant U.S. Dividend Leaders (CAD Hedged) ETF',
    symbol: 'RUDH.TO',
  },
  {
    value: 'RUEH.TO',
    label: 'RUEH.TO,  RBC Quant U.S. Equity Leaders (CAD Hedged) ETF',
    name: 'RBC Quant U.S. Equity Leaders (CAD Hedged) ETF',
    symbol: 'RUEH.TO',
  },
  {
    value: 'RUSB.TO',
    label: 'RUSB.TO,  RBC Short Term U.S. Corporate Bond ETF',
    name: 'RBC Short Term U.S. Corporate Bond ETF',
    symbol: 'RUSB.TO',
  },
  {
    value: 'RQL.TO',
    label: 'RQL.TO,  RBC Target 2024 Corporate Bond Index ETF',
    name: 'RBC Target 2024 Corporate Bond Index ETF',
    symbol: 'RQL.TO',
  },
  {
    value: 'RQN.TO',
    label: 'RQN.TO,  RBC Target 2025 Corporate Bond Index ETF',
    name: 'RBC Target 2025 Corporate Bond Index ETF',
    symbol: 'RQN.TO',
  },
  {
    value: 'RQO.TO',
    label: 'RQO.TO,  RBC Target 2026 Corporate Bond Index ETF',
    name: 'RBC Target 2026 Corporate Bond Index ETF',
    symbol: 'RQO.TO',
  },
  {
    value: 'RUBH.TO',
    label: 'RUBH.TO,  RBC U.S. Banks Yield (CAD Hedged) Index ETF',
    name: 'RBC U.S. Banks Yield (CAD Hedged) Index ETF',
    symbol: 'RUBH.TO',
  },
  {
    value: 'RUBY.TO',
    label: 'RUBY.TO,  RBC U.S. Banks Yield Index ETF',
    name: 'RBC U.S. Banks Yield Index ETF',
    symbol: 'RUBY.TO',
  },
  {
    value: 'REAL.TO',
    label: 'REAL.TO,  Real Matters Inc.',
    name: 'Real Matters Inc.',
    symbol: 'REAL.TO',
  },
  {
    value: 'ROOT.TO',
    label: 'ROOT.TO,  Roots Corporation',
    name: 'Roots Corporation',
    symbol: 'ROOT.TO',
  },
  {
    value: 'RIFI.TO',
    label: 'RIFI.TO,  Russell Investments Fixed Income Pool',
    name: 'Russell Investments Fixed Income Pool',
    symbol: 'RIFI.TO',
  },
  {
    value: 'RIIN.TO',
    label: 'RIIN.TO,  Russell Investments Global Infrastructure Pool',
    name: 'Russell Investments Global Infrastructure Pool',
    symbol: 'RIIN.TO',
  },
  {
    value: 'RIRA.TO',
    label: 'RIRA.TO,  Russell Investments Real Assets',
    name: 'Russell Investments Real Assets',
    symbol: 'RIRA.TO',
  },
  {
    value: 'SFC.TO',
    label: 'SFC.TO,  Sagicor Financial Company Ltd.',
    name: 'Sagicor Financial Company Ltd.',
    symbol: 'SFC.TO',
  },
  {
    value: 'SVB.TO',
    label: 'SVB.TO,  Silver Bull Resources, Inc.',
    name: 'Silver Bull Resources, Inc.',
    symbol: 'SVB.TO',
  },
  {
    value: 'ELEF.TO',
    label: 'ELEF.TO,  Silver Elephant Mining Corp.',
    name: 'Silver Elephant Mining Corp.',
    symbol: 'ELEF.TO',
  },
  {
    value: 'SIL.TO',
    label: 'SIL.TO,  SilverCrest Metals Inc.',
    name: 'SilverCrest Metals Inc.',
    symbol: 'SIL.TO',
  },
  {
    value: 'SKE.TO',
    label: 'SKE.TO,  Skeena Resources Limited',
    name: 'Skeena Resources Limited',
    symbol: 'SKE.TO',
  },
  {
    value: 'SOLG.TO',
    label: 'SOLG.TO,  SolGold Plc',
    name: 'SolGold Plc',
    symbol: 'SOLG.TO',
  },
  {
    value: 'SHLE.TO',
    label: 'SHLE.TO,  Source Energy Services Ltd.',
    name: 'Source Energy Services Ltd.',
    symbol: 'SHLE.TO',
  },
  {
    value: 'SPG.TO',
    label: 'SPG.TO,  Spark Power Group Inc.',
    name: 'Spark Power Group Inc.',
    symbol: 'SPG.TO',
  },
  {
    value: 'SSRM.TO',
    label: 'SSRM.TO,  SSR Mining Inc.',
    name: 'SSR Mining Inc.',
    symbol: 'SSRM.TO',
  },
  {
    value: 'SZLS.TO',
    label: 'SZLS.TO,  StageZero Life Sciences Ltd.',
    name: 'StageZero Life Sciences Ltd.',
    symbol: 'SZLS.TO',
  },
  {
    value: 'DIAM.TO',
    label: 'DIAM.TO,  Star Diamond Corporation',
    name: 'Star Diamond Corporation',
    symbol: 'DIAM.TO',
  },
  {
    value: 'STLC.TO',
    label: 'STLC.TO,  Stelco Holdings Inc.',
    name: 'Stelco Holdings Inc.',
    symbol: 'STLC.TO',
  },
  {
    value: 'STEP.TO',
    label: 'STEP.TO,  STEP Energy Services Ltd.',
    name: 'STEP Energy Services Ltd.',
    symbol: 'STEP.TO',
  },
  {
    value: 'STGO.TO',
    label: 'STGO.TO,  Steppe Gold Ltd.',
    name: 'Steppe Gold Ltd.',
    symbol: 'STGO.TO',
  },
  {
    value: 'SWP.TO',
    label: 'SWP.TO,  Swiss Water Decaffeinated Coffee Inc.',
    name: 'Swiss Water Decaffeinated Coffee Inc.',
    symbol: 'SWP.TO',
  },
  {
    value: 'TGED.TO',
    label: 'TGED.TO,  TD Active Global Enhanced Dividend ETF',
    name: 'TD Active Global Enhanced Dividend ETF',
    symbol: 'TGED.TO',
  },
  {
    value: 'TGGR.TO',
    label: 'TGGR.TO,  TD Active Global Equity Growth ETF',
    name: 'TD Active Global Equity Growth ETF',
    symbol: 'TGGR.TO',
  },
  {
    value: 'TGFI.TO',
    label: 'TGFI.TO,  TD Active Global Income ETF',
    name: 'TD Active Global Income ETF',
    symbol: 'TGFI.TO',
  },
  {
    value: 'TINF.TO',
    label: 'TINF.TO,  TD Active Global Infrastructure Equity ETF',
    name: 'TD Active Global Infrastructure Equity ETF',
    symbol: 'TINF.TO',
  },
  {
    value: 'TGRE.TO',
    label: 'TGRE.TO,  TD Active Global Real Estate Equity ETF',
    name: 'TD Active Global Real Estate Equity ETF',
    symbol: 'TGRE.TO',
  },
  {
    value: 'TPRF.TO',
    label: 'TPRF.TO,  TD Active Preferred Share ETF',
    name: 'TD Active Preferred Share ETF',
    symbol: 'TPRF.TO',
  },
  {
    value: 'TUED.TO',
    label: 'TUED.TO,  TD Active U.S. Enhanced Dividend ETF',
    name: 'TD Active U.S. Enhanced Dividend ETF',
    symbol: 'TUED.TO',
  },
  {
    value: 'TUHY.TO',
    label: 'TUHY.TO,  TD Active U.S. High Yield Bond ETF',
    name: 'TD Active U.S. High Yield Bond ETF',
    symbol: 'TUHY.TO',
  },
  {
    value: 'TCLB.TO',
    label: 'TCLB.TO,  TD Canadian Long Term Federal Bond ETF',
    name: 'TD Canadian Long Term Federal Bond ETF',
    symbol: 'TCLB.TO',
  },
  {
    value: 'TEC.TO',
    label: 'TEC.TO,  TD Global Technology Leaders Index ETF',
    name: 'TD Global Technology Leaders Index ETF',
    symbol: 'TEC.TO',
  },
  {
    value: 'TPAY.TO',
    label: 'TPAY.TO,  TD Income Builder ETF',
    name: 'TD Income Builder ETF',
    symbol: 'TPAY.TO',
  },
  {
    value: 'TOCA.TO',
    label: 'TOCA.TO,  TD One-Click Aggressive ETF Portfolio',
    name: 'TD One-Click Aggressive ETF Portfolio',
    symbol: 'TOCA.TO',
  },
  {
    value: 'TOCC.TO',
    label: 'TOCC.TO,  TD One-Click Conservative ETF Portfolio',
    name: 'TD One-Click Conservative ETF Portfolio',
    symbol: 'TOCC.TO',
  },
  {
    value: 'TOCM.TO',
    label: 'TOCM.TO,  TD One-Click Moderate ETF Portfolio',
    name: 'TD One-Click Moderate ETF Portfolio',
    symbol: 'TOCM.TO',
  },
  {
    value: 'TQCD.TO',
    label: 'TQCD.TO,  TD Q Canadian Dividend ETF',
    name: 'TD Q Canadian Dividend ETF',
    symbol: 'TQCD.TO',
  },
  {
    value: 'TCLV.TO',
    label: 'TCLV.TO,  TD Q Canadian Low Volatility ETF',
    name: 'TD Q Canadian Low Volatility ETF',
    symbol: 'TCLV.TO',
  },
  {
    value: 'TQGD.TO',
    label: 'TQGD.TO,  TD Q Global Dividend ETF',
    name: 'TD Q Global Dividend ETF',
    symbol: 'TQGD.TO',
  },
  {
    value: 'TQGM.TO',
    label: 'TQGM.TO,  TD Q Global Multifactor ETF',
    name: 'TD Q Global Multifactor ETF',
    symbol: 'TQGM.TO',
  },
  {
    value: 'TILV.TO',
    label: 'TILV.TO,  TD Q International Low Volatility ETF',
    name: 'TD Q International Low Volatility ETF',
    symbol: 'TILV.TO',
  },
  {
    value: 'TQSM.TO',
    label: 'TQSM.TO,  TD Q U.S. Small-Mid-Cap Equity ETF',
    name: 'TD Q U.S. Small-Mid-Cap Equity ETF',
    symbol: 'TQSM.TO',
  },
  {
    value: 'TCSB.TO',
    label: 'TCSB.TO,  TD Select Short Term Corporate Bond Ladder ETF',
    name: 'TD Select Short Term Corporate Bond Ladder ETF',
    symbol: 'TCSB.TO',
  },
  {
    value: 'TUSB.TO',
    label: 'TUSB.TO,  TD Select U.S. Short Term Corporate Bond Ladder ETF',
    name: 'TD Select U.S. Short Term Corporate Bond Ladder ETF',
    symbol: 'TUSB.TO',
  },
  {
    value: 'TULB.TO',
    label: 'TULB.TO,  TD U.S. Long Term Treasury Bond ETF',
    name: 'TD U.S. Long Term Treasury Bond ETF',
    symbol: 'TULB.TO',
  },
  {
    value: 'TBP.TO',
    label: 'TBP.TO,  Tetra Bio-Pharma Inc.',
    name: 'Tetra Bio-Pharma Inc.',
    symbol: 'TBP.TO',
  },
  {
    value: 'TFII.TO',
    label: 'TFII.TO,  TFI International Inc.',
    name: 'TFI International Inc.',
    symbol: 'TFII.TO',
  },
  {
    value: 'QBTC.TO',
    label: 'QBTC.TO,  The Bitcoin Fund',
    name: 'The Bitcoin Fund',
    symbol: 'QBTC.TO',
  },
  {
    value: 'VLNS.TO',
    label: 'VLNS.TO,  The Valens Company Inc.',
    name: 'The Valens Company Inc.',
    symbol: 'VLNS.TO',
  },
  {
    value: 'TWM.TO',
    label: 'TWM.TO,  Tidewater Midstream and Infrastructure Ltd.',
    name: 'Tidewater Midstream and Infrastructure Ltd.',
    symbol: 'TWM.TO',
  },
  {
    value: 'TI.TO',
    label: 'TI.TO,  Titan Mining Corporation',
    name: 'Titan Mining Corporation',
    symbol: 'TI.TO',
  },
  {
    value: 'TRL.TO',
    label: 'TRL.TO,  Trilogy International Partners Inc.',
    name: 'Trilogy International Partners Inc.',
    symbol: 'TRL.TO',
  },
  {
    value: 'TSU.TO',
    label: 'TSU.TO,  Trisura Group Ltd.',
    name: 'Trisura Group Ltd.',
    symbol: 'TSU.TO',
  },
  {
    value: 'TLG.TO',
    label: 'TLG.TO,  Troilus Gold Corp.',
    name: 'Troilus Gold Corp.',
    symbol: 'TLG.TO',
  },
  {
    value: '0005.HK',
    label: '0005.HK,  HSBC Holdings plc',
    name: 'HSBC Holdings plc',
    symbol: '0005.HK',
  },
  {
    value: '0001.HK',
    label: '0001.HK,  CK Hutchison Holdings Limited',
    name: 'CK Hutchison Holdings Limited',
    symbol: '0001.HK',
  },
  {
    value: 'UNI.TO',
    label: 'UNI.TO,  Unisync Corp.',
    name: 'Unisync Corp.',
    symbol: 'UNI.TO',
  },
  {
    value: 'VEQT.TO',
    label: 'VEQT.TO,  Vanguard All-Equity ETF Portfolio',
    name: 'Vanguard All-Equity ETF Portfolio',
    symbol: 'VEQT.TO',
  },
  {
    value: 'VBAL.TO',
    label: 'VBAL.TO,  Vanguard Balanced ETF Portfolio',
    name: 'Vanguard Balanced ETF Portfolio',
    symbol: 'VBAL.TO',
  },
  {
    value: 'VCB.TO',
    label: 'VCB.TO,  Vanguard Canadian Corporate Bond Index ETF',
    name: 'Vanguard Canadian Corporate Bond Index ETF',
    symbol: 'VCB.TO',
  },
  {
    value: 'VGV.TO',
    label: 'VGV.TO,  Vanguard Canadian Government Bond Index ETF',
    name: 'Vanguard Canadian Government Bond Index ETF',
    symbol: 'VGV.TO',
  },
  {
    value: 'VLB.TO',
    label: 'VLB.TO,  Vanguard Canadian Long-Term Bond Index ETF',
    name: 'Vanguard Canadian Long-Term Bond Index ETF',
    symbol: 'VLB.TO',
  },
  {
    value: 'VCNS.TO',
    label: 'VCNS.TO,  Vanguard Conservative ETF Portfolio',
    name: 'Vanguard Conservative ETF Portfolio',
    symbol: 'VCNS.TO',
  },
  {
    value: 'VCIP.TO',
    label: 'VCIP.TO,  Vanguard Conservative Income ETF Portfolio',
    name: 'Vanguard Conservative Income ETF Portfolio',
    symbol: 'VCIP.TO',
  },
  {
    value: 'VIDY.TO',
    label:
      'VIDY.TO,  Vanguard FTSE Developed ex North America High Dividend Yield Index ETF',
    name: 'Vanguard FTSE Developed ex North America High Dividend Yield Index ETF',
    symbol: 'VIDY.TO',
  },
  {
    value: 'VRIF.TO',
    label: 'VRIF.TO,  Vanguard Retirement Income ETF Portfolio',
    name: 'Vanguard Retirement Income ETF Portfolio',
    symbol: 'VRIF.TO',
  },
  {
    value: 'VGCX.TO',
    label: 'VGCX.TO,  Victoria Gold Corp.',
    name: 'Victoria Gold Corp.',
    symbol: 'VGCX.TO',
  },
  {
    value: 'VMD.TO',
    label: 'VMD.TO,  Viemed Healthcare, Inc.',
    name: 'Viemed Healthcare, Inc.',
    symbol: 'VMD.TO',
  },
  {
    value: 'VIVO.TO',
    label: 'VIVO.TO,  VIVO Cannabis Inc.',
    name: 'VIVO Cannabis Inc.',
    symbol: 'VIVO.TO',
  },
  {
    value: 'WCN.TO',
    label: 'WCN.TO,  Waste Connections, Inc.',
    name: 'Waste Connections, Inc.',
    symbol: 'WCN.TO',
  },
  {
    value: 'WBR.TO',
    label: 'WBR.TO,  Waterloo Brewing Ltd.',
    name: 'Waterloo Brewing Ltd.',
    symbol: 'WBR.TO',
  },
  {
    value: 'WSRD.TO',
    label:
      'WSRD.TO,  Wealthsimple Developed Markets ex North America Socially Responsible Index ETF',
    name: 'Wealthsimple Developed Markets ex North America Socially Responsible Index ETF',
    symbol: 'WSRD.TO',
  },
  {
    value: 'WSRI.TO',
    label:
      'WSRI.TO,  Wealthsimple North America Socially Responsible Index ETF',
    name: 'Wealthsimple North America Socially Responsible Index ETF',
    symbol: 'WSRI.TO',
  },
  {
    value: 'WELL.TO',
    label: 'WELL.TO,  WELL Health Technologies Corp.',
    name: 'WELL Health Technologies Corp.',
    symbol: 'WELL.TO',
  },
  {
    value: 'WRX.TO',
    label: 'WRX.TO,  Western Resources Corp.',
    name: 'Western Resources Corp.',
    symbol: 'WRX.TO',
  },
  {
    value: 'WPM.TO',
    label: 'WPM.TO,  Wheaton Precious Metals Corp.',
    name: 'Wheaton Precious Metals Corp.',
    symbol: 'WPM.TO',
  },
  {
    value: 'WILD.TO',
    label: 'WILD.TO,  WildBrain Ltd.',
    name: 'WildBrain Ltd.',
    symbol: 'WILD.TO',
  },
  {
    value: 'WLLW.TO',
    label: 'WLLW.TO,  Willow Biosciences Inc.',
    name: 'Willow Biosciences Inc.',
    symbol: 'WLLW.TO',
  },
  {
    value: 'XAM.TO',
    label: 'XAM.TO,  Xanadu Mines Limited',
    name: 'Xanadu Mines Limited',
    symbol: 'XAM.TO',
  },
  {
    value: 'YRB.TO',
    label: 'YRB.TO,  Yorbeau Resources Inc.',
    name: 'Yorbeau Resources Inc.',
    symbol: 'YRB.TO',
  },
  {
    value: 'ATE.TO',
    label: 'ATE.TO,  Antibe Therapeutics Inc.',
    name: 'Antibe Therapeutics Inc.',
    symbol: 'ATE.TO',
  },
  {
    value: 'PYR.TO',
    label: 'PYR.TO,  PyroGenesis Canada Inc.',
    name: 'PyroGenesis Canada Inc.',
    symbol: 'PYR.TO',
  },
  {
    value: 'CADLR.OL',
    label: 'CADLR.OL,  Cadeler A/S',
    name: 'Cadeler A/S',
    symbol: 'CADLR.OL',
  },
  {
    value: 'ADV',
    label: 'ADV,  Advantage Solutions Inc.',
    name: 'Advantage Solutions Inc.',
    symbol: 'ADV',
  },
  {
    value: 'CRHC',
    label: 'CRHC,  Cohn Robbins Holdings Corp.',
    name: 'Cohn Robbins Holdings Corp.',
    symbol: 'CRHC',
  },
  {
    value: 'EOSE',
    label: 'EOSE,  Eos Energy Enterprises, Inc.',
    name: 'Eos Energy Enterprises, Inc.',
    symbol: 'EOSE',
  },
  {
    value: 'FSR',
    label: 'FSR,  Fisker Inc.',
    name: 'Fisker Inc.',
    symbol: 'FSR',
  },
  {
    value: 'GCMG',
    label: 'GCMG,  Grosvenor Capital Management, L.P.',
    name: 'Grosvenor Capital Management, L.P.',
    symbol: 'GCMG',
  },
  {
    value: 'HYLN',
    label: 'HYLN,  Hyliion Holdings Corp.',
    name: 'Hyliion Holdings Corp.',
    symbol: 'HYLN',
  },
  {
    value: 'MP',
    label: 'MP,  MP Materials Corp.',
    name: 'MP Materials Corp.',
    symbol: 'MP',
  },
  {
    value: 'NGMS',
    label: 'NGMS,  NeoGames S.A.',
    name: 'NeoGames S.A.',
    symbol: 'NGMS',
  },
  {
    value: 'NHIC',
    label: 'NHIC,  NewHold Investment Corp. II',
    name: 'NewHold Investment Corp. II',
    symbol: 'NHIC',
  },
  {
    value: 'OLMA',
    label: 'OLMA,  Olema Pharmaceuticals, Inc.',
    name: 'Olema Pharmaceuticals, Inc.',
    symbol: 'OLMA',
  },
  {
    value: 'NUVB',
    label: 'NUVB,  Nuvation Bio Inc.',
    name: 'Nuvation Bio Inc.',
    symbol: 'NUVB',
  },
  {
    value: 'PAYA',
    label: 'PAYA,  Paya Holdings Inc.',
    name: 'Paya Holdings Inc.',
    symbol: 'PAYA',
  },
  {
    value: 'PIAI',
    label: 'PIAI,  Prime Impact Acquisition I',
    name: 'Prime Impact Acquisition I',
    symbol: 'PIAI',
  },
  {
    value: 'PMVC',
    label: 'PMVC,  PMV Consumer Acquisition Corp.',
    name: 'PMV Consumer Acquisition Corp.',
    symbol: 'PMVC',
  },
  {
    value: 'RIDE',
    label: 'RIDE,  Lordstown Motors Corp.',
    name: 'Lordstown Motors Corp.',
    symbol: 'RIDE',
  },
  {
    value: 'STEM',
    label: 'STEM,  Stem, Inc.',
    name: 'Stem, Inc.',
    symbol: 'STEM',
  },
  {
    value: 'TTCF',
    label: 'TTCF,  Tattooed Chef, Inc.',
    name: 'Tattooed Chef, Inc.',
    symbol: 'TTCF',
  },
  {
    value: 'TWND',
    label: 'TWND,  Tailwind Acquisition Corp.',
    name: 'Tailwind Acquisition Corp.',
    symbol: 'TWND',
  },
  {
    value: 'VLDR',
    label: 'VLDR,  Velodyne Lidar, Inc.',
    name: 'Velodyne Lidar, Inc.',
    symbol: 'VLDR',
  },
  {
    value: 'VTRS',
    label: 'VTRS,  Viatris Inc.',
    name: 'Viatris Inc.',
    symbol: 'VTRS',
  },
  {
    value: 'LUMN',
    label: 'LUMN,  Lumen Technologies, Inc.',
    name: 'Lumen Technologies, Inc.',
    symbol: 'LUMN',
  },
  {
    value: 'VNT',
    label: 'VNT,  Vontier Corporation',
    name: 'Vontier Corporation',
    symbol: 'VNT',
  },
  {
    value: '1209.HK',
    label: '1209.HK,  China Resources Mixc Lifestyle Services Limited',
    name: 'China Resources Mixc Lifestyle Services Limited',
    symbol: '1209.HK',
  },
  {
    value: '6999.HK',
    label: '6999.HK,  Leading Holdings Group Limited',
    name: 'Leading Holdings Group Limited',
    symbol: '6999.HK',
  },
  {
    value: 'PTICU',
    label: 'PTICU,  PropTech Investment Corporation II',
    name: 'PropTech Investment Corporation II',
    symbol: 'PTICU',
  },
  {
    value: 'QS',
    label: 'QS,  QuantumScape Corporation',
    name: 'QuantumScape Corporation',
    symbol: 'QS',
  },
  {
    value: 'KNTE',
    label: 'KNTE,  Kinnate Biopharma Inc.',
    name: 'Kinnate Biopharma Inc.',
    symbol: 'KNTE',
  },
  {
    value: 'SEER',
    label: 'SEER,  Seer, Inc.',
    name: 'Seer, Inc.',
    symbol: 'SEER',
  },
  {
    value: 'SGTX',
    label: 'SGTX,  Sigilon Therapeutics, Inc.',
    name: 'Sigilon Therapeutics, Inc.',
    symbol: 'SGTX',
  },
  {
    value: 'YQ',
    label: 'YQ,  17 Education & Technology Group Inc.',
    name: '17 Education & Technology Group Inc.',
    symbol: 'YQ',
  },
  {
    value: '9992.HK',
    label: '9992.HK,  Pop Mart International Group Limited',
    name: 'Pop Mart International Group Limited',
    symbol: '9992.HK',
  },
  {
    value: 'ALMIB.PA',
    label: 'ALMIB.PA,  Amoéba S.A.',
    name: 'Amoéba S.A.',
    symbol: 'ALMIB.PA',
  },
  {
    value: 'ECU.V',
    label: 'ECU.V,  Element 29 Resources Inc.',
    name: 'Element 29 Resources Inc.',
    symbol: 'ECU.V',
  },
  {
    value: 'ALTUU',
    label: 'ALTUU,  Altitude Acquisition Corp.',
    name: 'Altitude Acquisition Corp.',
    symbol: 'ALTUU',
  },
  {
    value: 'DASH',
    label: 'DASH,  DoorDash, Inc.',
    name: 'DoorDash, Inc.',
    symbol: 'DASH',
  },
  {
    value: 'FLACU',
    label: 'FLACU,  Frazier Lifesciences Acquisition Corporation',
    name: 'Frazier Lifesciences Acquisition Corporation',
    symbol: 'FLACU',
  },
  {
    value: 'PUBM',
    label: 'PUBM,  PubMatic, Inc.',
    name: 'PubMatic, Inc.',
    symbol: 'PUBM',
  },
  {
    value: 'CND-UN',
    label: 'CND-UN,  Concord Acquisition Corp',
    name: 'Concord Acquisition Corp',
    symbol: 'CND-UN',
  },
  {
    value: 'ABNB',
    label: 'ABNB,  Airbnb, Inc.',
    name: 'Airbnb, Inc.',
    symbol: 'ABNB',
  },
  {
    value: 'CTAQU',
    label: 'CTAQU,  Carney Technology Acquisition Corp. II',
    name: 'Carney Technology Acquisition Corp. II',
    symbol: 'CTAQU',
  },
  {
    value: 'HMCOU',
    label: 'HMCOU,  HumanCo Acquisition Corp.',
    name: 'HumanCo Acquisition Corp.',
    symbol: 'HMCOU',
  },
  {
    value: 'CERT',
    label: 'CERT,  Certara, Inc.',
    name: 'Certara, Inc.',
    symbol: 'CERT',
  },
  {
    value: 'HYFM',
    label: 'HYFM,  Hydrofarm Holdings Group, Inc.',
    name: 'Hydrofarm Holdings Group, Inc.',
    symbol: 'HYFM',
  },
  {
    value: 'ABCL',
    label: 'ABCL,  AbCellera Biologics Inc.',
    name: 'AbCellera Biologics Inc.',
    symbol: 'ABCL',
  },
  {
    value: 'VVOS',
    label: 'VVOS,  Vivos Therapeutics, Inc.',
    name: 'Vivos Therapeutics, Inc.',
    symbol: 'VVOS',
  },
  {
    value: 'PRG',
    label: 'PRG,  PROG Holdings, Inc.',
    name: 'PROG Holdings, Inc.',
    symbol: 'PRG',
  },
  {
    value: 'WISH',
    label: 'WISH,  ContextLogic Inc.',
    name: 'ContextLogic Inc.',
    symbol: 'WISH',
  },
  {
    value: 'UPST',
    label: 'UPST,  Upstart Holdings, Inc.',
    name: 'Upstart Holdings, Inc.',
    symbol: 'UPST',
  },
  {
    value: 'BCAB',
    label: 'BCAB,  BioAtla, Inc.',
    name: 'BioAtla, Inc.',
    symbol: 'BCAB',
  },
  {
    value: 'ELIMP.OL',
    label: 'ELIMP.OL,  Elektroimportøren AS',
    name: 'Elektroimportøren AS',
    symbol: 'ELIMP.OL',
  },
  {
    value: 'SCPS',
    label: 'SCPS,  Scopus BioPharma Inc.',
    name: 'Scopus BioPharma Inc.',
    symbol: 'SCPS',
  },
  {
    value: 'OCG',
    label: 'OCG,  Oriental Culture Holding LTD',
    name: 'Oriental Culture Holding LTD',
    symbol: 'OCG',
  },
  {
    value: 'WNW',
    label: 'WNW,  Meiwu Technology Company Limited',
    name: 'Meiwu Technology Company Limited',
    symbol: 'WNW',
  },
  {
    value: 'COOLU',
    label: 'COOLU,  Corner Growth Acquisition Corp.',
    name: 'Corner Growth Acquisition Corp.',
    symbol: 'COOLU',
  },
  {
    value: 'SCOAU',
    label: 'SCOAU,  ScION Tech Growth I',
    name: 'ScION Tech Growth I',
    symbol: 'SCOAU',
  },
  {
    value: 'VIRI',
    label: 'VIRI,  Virios Therapeutics, Inc.',
    name: 'Virios Therapeutics, Inc.',
    symbol: 'VIRI',
  },
  {
    value: 'CCV-UN',
    label: 'CCV-UN,  Churchill Capital Corp V',
    name: 'Churchill Capital Corp V',
    symbol: 'CCV-UN',
  },
  {
    value: '2135.HK',
    label: '2135.HK,  Raily Aesthetic Medicine International Holdings Limited',
    name: 'Raily Aesthetic Medicine International Holdings Limited',
    symbol: '2135.HK',
  },
  {
    value: 'MTACU',
    label: 'MTACU,  MedTech Acquisition Corporation',
    name: 'MedTech Acquisition Corporation',
    symbol: 'MTACU',
  },
  {
    value: 'DUNEU',
    label: 'DUNEU,  Dune Acquisition Corporation',
    name: 'Dune Acquisition Corporation',
    symbol: 'DUNEU',
  },
  {
    value: 'AIRC',
    label: 'AIRC,  Apartment Income REIT Corp.',
    name: 'Apartment Income REIT Corp.',
    symbol: 'AIRC',
  },
  {
    value: 'BOAC',
    label: 'BOAC,  Bluescape Opportunities Acquisition Corp.',
    name: 'Bluescape Opportunities Acquisition Corp.',
    symbol: 'BOAC',
  },
  {
    value: 'EMPW',
    label: 'EMPW,  Emerge EMPWR Unified Sustainable Equity ETF',
    name: 'Emerge EMPWR Unified Sustainable Equity ETF',
    symbol: 'EMPW',
  },
  {
    value: 'HZON',
    label: 'HZON,  Horizon Acquisition Corporation II',
    name: 'Horizon Acquisition Corporation II',
    symbol: 'HZON',
  },
  {
    value: 'SDHY',
    label: 'SDHY,  PGIM Short Duration High Yield Opportunities Fund',
    name: 'PGIM Short Duration High Yield Opportunities Fund',
    symbol: 'SDHY',
  },
  {
    value: 'SKLZ',
    label: 'SKLZ,  Skillz Inc.',
    name: 'Skillz Inc.',
    symbol: 'SKLZ',
  },
  {
    value: 'BARK',
    label: 'BARK,  BARK, Inc.',
    name: 'BARK, Inc.',
    symbol: 'BARK',
  },
  {
    value: '1945.HK',
    label: '1945.HK,  Zero2IPO Holdings Inc.',
    name: 'Zero2IPO Holdings Inc.',
    symbol: '1945.HK',
  },
  {
    value: 'NNOX',
    label: 'NNOX,  Nano-X Imaging Ltd.',
    name: 'Nano-X Imaging Ltd.',
    symbol: 'NNOX',
  },
  {
    value: 'IKT',
    label: 'IKT,  Inhibikase Therapeutics, Inc.',
    name: 'Inhibikase Therapeutics, Inc.',
    symbol: 'IKT',
  },
  {
    value: 'BENEU',
    label: 'BENEU,  Benessere Capital Acquisition Corp.',
    name: 'Benessere Capital Acquisition Corp.',
    symbol: 'BENEU',
  },
  {
    value: 'HCARU',
    label: 'HCARU,  Healthcare Services Acquisition Corporation',
    name: 'Healthcare Services Acquisition Corporation',
    symbol: 'HCARU',
  },
  {
    value: 'PHAR',
    label: 'PHAR,  Pharming Group N.V.',
    name: 'Pharming Group N.V.',
    symbol: 'PHAR',
  },
  {
    value: 'ZEN.L',
    label: 'ZEN.L,  Zenith Energy Ltd.',
    name: 'Zenith Energy Ltd.',
    symbol: 'ZEN.L',
  },
  {
    value: 'MRM',
    label: 'MRM,  MEDIROM Healthcare Technologies Inc.',
    name: 'MEDIROM Healthcare Technologies Inc.',
    symbol: 'MRM',
  },
  {
    value: '0267.HK',
    label: '0267.HK,  CITIC Limited',
    name: 'CITIC Limited',
    symbol: '0267.HK',
  },
  {
    value: '0998.HK',
    label: '0998.HK,  China CITIC Bank Corporation Limited',
    name: 'China CITIC Bank Corporation Limited',
    symbol: '0998.HK',
  },
  {
    value: '6030.HK',
    label: '6030.HK,  CITIC Securities Company Limited',
    name: 'CITIC Securities Company Limited',
    symbol: '6030.HK',
  },
  {
    value: '6066.HK',
    label: '6066.HK,  CSC Financial Co., Ltd.',
    name: 'CSC Financial Co., Ltd.',
    symbol: '6066.HK',
  },
  {
    value: '0500.HK',
    label: '0500.HK,  Frontier Services Group Limited',
    name: 'Frontier Services Group Limited',
    symbol: '0500.HK',
  },
  {
    value: '2129.HK',
    label: '2129.HK,  Legion Consortium Limited',
    name: 'Legion Consortium Limited',
    symbol: '2129.HK',
  },
  {
    value: 'NOU.V',
    label: 'NOU.V,  Nouveau Monde Graphite Inc.',
    name: 'Nouveau Monde Graphite Inc.',
    symbol: 'NOU.V',
  },
  {
    value: '2158.HK',
    label: '2158.HK,  Yidu Tech Inc.',
    name: 'Yidu Tech Inc.',
    symbol: '2158.HK',
  },
  {
    value: '9608.HK',
    label: '9608.HK,  Sundy Service Group Co. Ltd',
    name: 'Sundy Service Group Co. Ltd',
    symbol: '9608.HK',
  },
  {
    value: '1440.HK',
    label: '1440.HK,  Deyun Holding Ltd.',
    name: 'Deyun Holding Ltd.',
    symbol: '1440.HK',
  },
  {
    value: 'FVT-UN',
    label: 'FVT-UN,  Fortress Value Acquisition Corp. III',
    name: 'Fortress Value Acquisition Corp. III',
    symbol: 'FVT-UN',
  },
  {
    value: '2153.HK',
    label: '2153.HK,  Tat Hong Equipment Service Co., Ltd.',
    name: 'Tat Hong Equipment Service Co., Ltd.',
    symbol: '2153.HK',
  },
  {
    value: 'OPEN',
    label: 'OPEN,  Opendoor Technologies Inc.',
    name: 'Opendoor Technologies Inc.',
    symbol: 'OPEN',
  },
  {
    value: 'LJAQU',
    label: 'LJAQU,  LightJump Acquisition Corporation',
    name: 'LightJump Acquisition Corporation',
    symbol: 'LJAQU',
  },
  {
    value: 'AKICU',
    label: 'AKICU,  Sports Ventures Acquisition Corp.',
    name: 'Sports Ventures Acquisition Corp.',
    symbol: 'AKICU',
  },
  {
    value: 'BLTSU',
    label: 'BLTSU,  Bright Lights Acquisition Corp.',
    name: 'Bright Lights Acquisition Corp.',
    symbol: 'BLTSU',
  },
  {
    value: 'INKAU',
    label: 'INKAU,  KludeIn I Acquisition Corp.',
    name: 'KludeIn I Acquisition Corp.',
    symbol: 'INKAU',
  },
  {
    value: 'VCKAU',
    label: 'VCKAU,  Vickers Vantage Corp. I',
    name: 'Vickers Vantage Corp. I',
    symbol: 'VCKAU',
  },
  {
    value: '1643.HK',
    label: '1643.HK,  Modern Chinese Medicine Group Co., Ltd.',
    name: 'Modern Chinese Medicine Group Co., Ltd.',
    symbol: '1643.HK',
  },
  {
    value: 'OMEG',
    label: 'OMEG,  Omega Alpha SPAC',
    name: 'Omega Alpha SPAC',
    symbol: 'OMEG',
  },
  {
    value: 'AGCB',
    label: 'AGCB,  Altimeter Growth Corp. 2',
    name: 'Altimeter Growth Corp. 2',
    symbol: 'AGCB',
  },
  {
    value: 'KAIRU',
    label: 'KAIRU,  Kairos Acquisition Corp.',
    name: 'Kairos Acquisition Corp.',
    symbol: 'KAIRU',
  },
  {
    value: 'DRDR.TO',
    label: 'DRDR.TO,  MCI Onehealth Technologies Inc.',
    name: 'MCI Onehealth Technologies Inc.',
    symbol: 'DRDR.TO',
  },
  {
    value: '1490.HK',
    label: '1490.HK,  Cheshi Technology Inc.',
    name: 'Cheshi Technology Inc.',
    symbol: '1490.HK',
  },
  {
    value: '2146.HK',
    label: '2146.HK,  Roiserv Lifestyle Services Co., Ltd.',
    name: 'Roiserv Lifestyle Services Co., Ltd.',
    symbol: '2146.HK',
  },
  {
    value: '2125.HK',
    label: '2125.HK,  Strawbear Entertainment Group',
    name: 'Strawbear Entertainment Group',
    symbol: '2125.HK',
  },
  {
    value: 'GRCL',
    label: 'GRCL,  Gracell Biotechnologies Inc.',
    name: 'Gracell Biotechnologies Inc.',
    symbol: 'GRCL',
  },
  {
    value: 'PAQCU',
    label: 'PAQCU,  Provident Acquisition Corp.',
    name: 'Provident Acquisition Corp.',
    symbol: 'PAQCU',
  },
  {
    value: 'CGEM',
    label: 'CGEM,  Cullinan Oncology, Inc.',
    name: 'Cullinan Oncology, Inc.',
    symbol: 'CGEM',
  },
  {
    value: 'SVFAU',
    label: 'SVFAU,  SVF Investment Corp.',
    name: 'SVF Investment Corp.',
    symbol: 'SVFAU',
  },
  {
    value: 'PRSRU',
    label: 'PRSRU,  Prospector Capital Corp.',
    name: 'Prospector Capital Corp.',
    symbol: 'PRSRU',
  },
  {
    value: 'KLAQU',
    label: 'KLAQU,  KL Acquisition Corp',
    name: 'KL Acquisition Corp',
    symbol: 'KLAQU',
  },
  {
    value: 'PACXU',
    label: 'PACXU,  Pioneer Merger Corp.',
    name: 'Pioneer Merger Corp.',
    symbol: 'PACXU',
  },
  {
    value: 'EPHYU',
    label: 'EPHYU,  Epiphany Technology Acquisition Corp.',
    name: 'Epiphany Technology Acquisition Corp.',
    symbol: 'EPHYU',
  },
  {
    value: 'POWRU',
    label: 'POWRU,  Powered Brands',
    name: 'Powered Brands',
    symbol: 'POWRU',
  },
  {
    value: 'TMKRU',
    label: 'TMKRU,  Tastemaker Acquisition Corp.',
    name: 'Tastemaker Acquisition Corp.',
    symbol: 'TMKRU',
  },
  {
    value: 'QLI',
    label: 'QLI,  Qilian International Holding Group Limited',
    name: 'Qilian International Holding Group Limited',
    symbol: 'QLI',
  },
  {
    value: '0700.HK',
    label: '0700.HK,  Tencent Holdings Limited',
    name: 'Tencent Holdings Limited',
    symbol: '0700.HK',
  },
  {
    value: '9988.HK',
    label: '9988.HK,  Alibaba Group Holding Limited',
    name: 'Alibaba Group Holding Limited',
    symbol: '9988.HK',
  },
  {
    value: '9987.HK',
    label: '9987.HK,  Yum China Holdings, Inc.',
    name: 'Yum China Holdings, Inc.',
    symbol: '9987.HK',
  },
  {
    value: '0992.HK',
    label: '0992.HK,  Lenovo Group Limited',
    name: 'Lenovo Group Limited',
    symbol: '0992.HK',
  },
  {
    value: '9901.HK',
    label: '9901.HK,  New Oriental Education & Technology Group Inc.',
    name: 'New Oriental Education & Technology Group Inc.',
    symbol: '9901.HK',
  },
  {
    value: '9698.HK',
    label: '9698.HK,  GDS Holdings Limited',
    name: 'GDS Holdings Limited',
    symbol: '9698.HK',
  },
  {
    value: '0981.HK',
    label: '0981.HK,  Semiconductor Manufacturing International Corporation',
    name: 'Semiconductor Manufacturing International Corporation',
    symbol: '0981.HK',
  },
  {
    value: '0968.HK',
    label: '0968.HK,  Xinyi Solar Holdings Limited',
    name: 'Xinyi Solar Holdings Limited',
    symbol: '0968.HK',
  },
  {
    value: '9633.HK',
    label: '9633.HK,  Nongfu Spring Co., Ltd.',
    name: 'Nongfu Spring Co., Ltd.',
    symbol: '9633.HK',
  },
  {
    value: '0941.HK',
    label: '0941.HK,  China Mobile Limited',
    name: 'China Mobile Limited',
    symbol: '0941.HK',
  },
  {
    value: '0939.HK',
    label: '0939.HK,  China Construction Bank Corporation',
    name: 'China Construction Bank Corporation',
    symbol: '0939.HK',
  },
  {
    value: '0909.HK',
    label: '0909.HK,  Ming Yuan Cloud Group Holdings Limited',
    name: 'Ming Yuan Cloud Group Holdings Limited',
    symbol: '0909.HK',
  },
  {
    value: '0914.HK',
    label: '0914.HK,  Anhui Conch Cement Company Limited',
    name: 'Anhui Conch Cement Company Limited',
    symbol: '0914.HK',
  },
  {
    value: '0883.HK',
    label: '0883.HK,  CNOOC Limited',
    name: 'CNOOC Limited',
    symbol: '0883.HK',
  },
  {
    value: '0873.HK',
    label: '0873.HK,  Shimao Services Holdings Limited',
    name: 'Shimao Services Holdings Limited',
    symbol: '0873.HK',
  },
  {
    value: '0857.HK',
    label: '0857.HK,  PetroChina Company Limited',
    name: 'PetroChina Company Limited',
    symbol: '0857.HK',
  },
  {
    value: '0853.HK',
    label: '0853.HK,  MicroPort Scientific Corporation',
    name: 'MicroPort Scientific Corporation',
    symbol: '0853.HK',
  },
  {
    value: '0823.HK',
    label: '0823.HK,  Link Real Estate Investment Trust',
    name: 'Link Real Estate Investment Trust',
    symbol: '0823.HK',
  },
  {
    value: '0772.HK',
    label: '0772.HK,  China Literature Limited',
    name: 'China Literature Limited',
    symbol: '0772.HK',
  },
  {
    value: '0763.HK',
    label: '0763.HK,  ZTE Corporation',
    name: 'ZTE Corporation',
    symbol: '0763.HK',
  },
  {
    value: '3913.HK',
    label: '3913.HK,  KWG Living Group Holdings Limited',
    name: 'KWG Living Group Holdings Limited',
    symbol: '3913.HK',
  },
  {
    value: '0762.HK',
    label: '0762.HK,  China Unicom (Hong Kong) Limited',
    name: 'China Unicom (Hong Kong) Limited',
    symbol: '0762.HK',
  },
  {
    value: '0388.HK',
    label: '0388.HK,  Hong Kong Exchanges and Clearing Limited',
    name: 'Hong Kong Exchanges and Clearing Limited',
    symbol: '0388.HK',
  },
  {
    value: '6988.HK',
    label: '6988.HK,  Joy Spreader Group Inc.',
    name: 'Joy Spreader Group Inc.',
    symbol: '6988.HK',
  },
  {
    value: '6969.HK',
    label: '6969.HK,  Smoore International Holdings Limited',
    name: 'Smoore International Holdings Limited',
    symbol: '6969.HK',
  },
  {
    value: '0386.HK',
    label: '0386.HK,  China Petroleum & Chemical Corporation',
    name: 'China Petroleum & Chemical Corporation',
    symbol: '0386.HK',
  },
  {
    value: '0688.HK',
    label: '0688.HK,  China Overseas Land & Investment Limited',
    name: 'China Overseas Land & Investment Limited',
    symbol: '0688.HK',
  },
  {
    value: '0003.HK',
    label: '0003.HK,  The Hong Kong and China Gas Company Limited',
    name: 'The Hong Kong and China Gas Company Limited',
    symbol: '0003.HK',
  },
  {
    value: '0669.HK',
    label: '0669.HK,  Techtronic Industries Company Limited',
    name: 'Techtronic Industries Company Limited',
    symbol: '0669.HK',
  },
  {
    value: '0291.HK',
    label: '0291.HK,  China Resources Beer (Holdings) Company Limited',
    name: 'China Resources Beer (Holdings) Company Limited',
    symbol: '0291.HK',
  },
  {
    value: '2899.HK',
    label: '2899.HK,  Zijin Mining Group Company Limited',
    name: 'Zijin Mining Group Company Limited',
    symbol: '2899.HK',
  },
  {
    value: '0288.HK',
    label: '0288.HK,  WH Group Limited',
    name: 'WH Group Limited',
    symbol: '0288.HK',
  },
  {
    value: '0285.HK',
    label: '0285.HK,  BYD Electronic (International) Company Limited',
    name: 'BYD Electronic (International) Company Limited',
    symbol: '0285.HK',
  },
  {
    value: '3988.HK',
    label: '3988.HK,  Bank of China Limited',
    name: 'Bank of China Limited',
    symbol: '3988.HK',
  },
  {
    value: '2801.HK',
    label: '2801.HK,  iShares Core MSCI China ETF',
    name: 'iShares Core MSCI China ETF',
    symbol: '2801.HK',
  },
  {
    value: '0027.HK',
    label: '0027.HK,  Galaxy Entertainment Group Limited',
    name: 'Galaxy Entertainment Group Limited',
    symbol: '0027.HK',
  },
  {
    value: '3968.HK',
    label: '3968.HK,  China Merchants Bank Co., Ltd.',
    name: 'China Merchants Bank Co., Ltd.',
    symbol: '3968.HK',
  },
  {
    value: '2628.HK',
    label: '2628.HK,  China Life Insurance Company Limited',
    name: 'China Life Insurance Company Limited',
    symbol: '2628.HK',
  },
  {
    value: '2601.HK',
    label: '2601.HK,  China Pacific Insurance (Group) Co., Ltd.',
    name: 'China Pacific Insurance (Group) Co., Ltd.',
    symbol: '2601.HK',
  },
  {
    value: '0241.HK',
    label: '0241.HK,  Alibaba Health Information Technology Limited',
    name: 'Alibaba Health Information Technology Limited',
    symbol: '0241.HK',
  },
  {
    value: '2333.HK',
    label: '2333.HK,  Great Wall Motor Company Limited',
    name: 'Great Wall Motor Company Limited',
    symbol: '2333.HK',
  },
  {
    value: '2318.HK',
    label: '2318.HK,  Ping An Insurance (Group) Company of China, Ltd.',
    name: 'Ping An Insurance (Group) Company of China, Ltd.',
    symbol: '2318.HK',
  },
  {
    value: '2238.HK',
    label: '2238.HK,  Guangzhou Automobile Group Co., Ltd.',
    name: 'Guangzhou Automobile Group Co., Ltd.',
    symbol: '2238.HK',
  },
  {
    value: '2202.HK',
    label: '2202.HK,  China Vanke Co., Ltd.',
    name: 'China Vanke Co., Ltd.',
    symbol: '2202.HK',
  },
  {
    value: '0002.HK',
    label: '0002.HK,  CLP Holdings Limited',
    name: 'CLP Holdings Limited',
    symbol: '0002.HK',
  },
  {
    value: '1952.HK',
    label: '1952.HK,  Everest Medicines Limited',
    name: 'Everest Medicines Limited',
    symbol: '1952.HK',
  },
  {
    value: '0175.HK',
    label: '0175.HK,  Geely Automobile Holdings Limited',
    name: 'Geely Automobile Holdings Limited',
    symbol: '0175.HK',
  },
  {
    value: '0016.HK',
    label: '0016.HK,  Sun Hung Kai Properties Limited',
    name: 'Sun Hung Kai Properties Limited',
    symbol: '0016.HK',
  },
  {
    value: '1398.HK',
    label: '1398.HK,  Industrial and Commercial Bank of China Limited',
    name: 'Industrial and Commercial Bank of China Limited',
    symbol: '1398.HK',
  },
  {
    value: '1336.HK',
    label: '1336.HK,  New China Life Insurance Company Ltd.',
    name: 'New China Life Insurance Company Ltd.',
    symbol: '1336.HK',
  },
  {
    value: '1288.HK',
    label: '1288.HK,  Agricultural Bank of China Limited',
    name: 'Agricultural Bank of China Limited',
    symbol: '1288.HK',
  },
  {
    value: '1211.HK',
    label: '1211.HK,  BYD Company Limited',
    name: 'BYD Company Limited',
    symbol: '1211.HK',
  },
  {
    value: '0011.HK',
    label: '0011.HK,  Hang Seng Bank Limited',
    name: 'Hang Seng Bank Limited',
    symbol: '0011.HK',
  },
  {
    value: '1088.HK',
    label: '1088.HK,  China Shenhua Energy Company Limited',
    name: 'China Shenhua Energy Company Limited',
    symbol: '1088.HK',
  },
  {
    value: 'MAXN',
    label: 'MAXN,  Maxeon Solar Technologies, Ltd.',
    name: 'Maxeon Solar Technologies, Ltd.',
    symbol: 'MAXN',
  },
  {
    value: '83188.HK',
    label: '83188.HK,  ChinaAMC CSI 300 Index ETF',
    name: 'ChinaAMC CSI 300 Index ETF',
    symbol: '83188.HK',
  },
  {
    value: '82822.HK',
    label: '82822.HK,  CSOP FTSE China A50 ETF',
    name: 'CSOP FTSE China A50 ETF',
    symbol: '82822.HK',
  },
  {
    value: 'XIN9.FGI',
    label: 'XIN9.FGI,  FTSE China A50 Index',
    name: 'FTSE China A50 Index',
    symbol: 'XIN9.FGI',
  },
  {
    value: 'MXAPX',
    label: 'MXAPX,  Empower Aggressive Profile Fund',
    name: 'Empower Aggressive Profile Fund',
    symbol: 'MXAPX',
  },
  {
    value: 'MSGM',
    label: 'MSGM,  Motorsport Games Inc.',
    name: 'Motorsport Games Inc.',
    symbol: 'MSGM',
  },
  {
    value: 'GPACU',
    label: 'GPACU,  Global Partner Acquisition Corp II',
    name: 'Global Partner Acquisition Corp II',
    symbol: 'GPACU',
  },
  {
    value: 'SWETU',
    label: 'SWETU,  Athlon Acquisition Corp.',
    name: 'Athlon Acquisition Corp.',
    symbol: 'SWETU',
  },
  {
    value: 'DLCAU',
    label: 'DLCAU,  Deep Lake Capital Acquisition Corp.',
    name: 'Deep Lake Capital Acquisition Corp.',
    symbol: 'DLCAU',
  },
  {
    value: 'TZPSU',
    label: 'TZPSU,  TZP Strategies Acquisition Corp.',
    name: 'TZP Strategies Acquisition Corp.',
    symbol: 'TZPSU',
  },
  {
    value: 'AFRM',
    label: 'AFRM,  Affirm Holdings, Inc.',
    name: 'Affirm Holdings, Inc.',
    symbol: 'AFRM',
  },
  {
    value: 'FINMU',
    label: 'FINMU,  Marlin Technology Corporation',
    name: 'Marlin Technology Corporation',
    symbol: 'FINMU',
  },
  {
    value: 'HLAHU',
    label: 'HLAHU,  Hamilton Lane Alliance Holdings I, Inc.',
    name: 'Hamilton Lane Alliance Holdings I, Inc.',
    symbol: 'HLAHU',
  },
  {
    value: 'MONCU',
    label: 'MONCU,  Monument Circle Acquisition Corp.',
    name: 'Monument Circle Acquisition Corp.',
    symbol: 'MONCU',
  },
  {
    value: 'ASLE',
    label: 'ASLE,  AerSale Corporation',
    name: 'AerSale Corporation',
    symbol: 'ASLE',
  },
  {
    value: 'POSH',
    label: 'POSH,  Poshmark, Inc.',
    name: 'Poshmark, Inc.',
    symbol: 'POSH',
  },
  {
    value: 'AEACU',
    label: 'AEACU,  Authentic Equity Acquisition Corp.',
    name: 'Authentic Equity Acquisition Corp.',
    symbol: 'AEACU',
  },
  {
    value: 'WOOF',
    label: 'WOOF,  Petco Health and Wellness Company, Inc.',
    name: 'Petco Health and Wellness Company, Inc.',
    symbol: 'WOOF',
  },
  {
    value: 'EACPU',
    label: 'EACPU,  Edify Acquisition Corp.',
    name: 'Edify Acquisition Corp.',
    symbol: 'EACPU',
  },
  {
    value: 'PLTK',
    label: 'PLTK,  Playtika Holding Corp.',
    name: 'Playtika Holding Corp.',
    symbol: 'PLTK',
  },
  {
    value: 'DRVN',
    label: 'DRVN,  Driven Brands Holdings Inc.',
    name: 'Driven Brands Holdings Inc.',
    symbol: 'DRVN',
  },
  {
    value: 'HCICU',
    label: 'HCICU,  Hennessy Capital Investment Corp. V',
    name: 'Hennessy Capital Investment Corp. V',
    symbol: 'HCICU',
  },
  {
    value: 'OCAXU',
    label: 'OCAXU,  OCA Acquisition Corp.',
    name: 'OCA Acquisition Corp.',
    symbol: 'OCAXU',
  },
  {
    value: 'GNACU',
    label: 'GNACU,  Group Nine Acquisition Corp.',
    name: 'Group Nine Acquisition Corp.',
    symbol: 'GNACU',
  },
  {
    value: 'ADERU',
    label: 'ADERU,  26 Capital Acquisition Corp.',
    name: '26 Capital Acquisition Corp.',
    symbol: 'ADERU',
  },
  {
    value: 'BEEM',
    label: 'BEEM,  Beam Global',
    name: 'Beam Global',
    symbol: 'BEEM',
  },
  {
    value: 'AI',
    label: 'AI,  C3.ai, Inc.',
    name: 'C3.ai, Inc.',
    symbol: 'AI',
  },
  {
    value: 'LI',
    label: 'LI,  Li Auto Inc.',
    name: 'Li Auto Inc.',
    symbol: 'LI',
  },
  {
    value: 'FOXWU',
    label: 'FOXWU,  FoxWayne Enterprises Acquisition Corp.',
    name: 'FoxWayne Enterprises Acquisition Corp.',
    symbol: 'FOXWU',
  },
  {
    value: 'DFH',
    label: 'DFH,  Dream Finders Homes, Inc.',
    name: 'Dream Finders Homes, Inc.',
    symbol: 'DFH',
  },
  {
    value: 'ABST.TO',
    label: 'ABST.TO,  Absolute Software Corporation',
    name: 'Absolute Software Corporation',
    symbol: 'ABST.TO',
  },
  {
    value: 'MYTE',
    label: 'MYTE,  MYT Netherlands Parent B.V.',
    name: 'MYT Netherlands Parent B.V.',
    symbol: 'MYTE',
  },
  {
    value: 'NAACU',
    label: 'NAACU,  North Atlantic Acquisition Corporation',
    name: 'North Atlantic Acquisition Corporation',
    symbol: 'NAACU',
  },
  {
    value: 'HUDI',
    label: 'HUDI,  Huadi International Group Co., Ltd.',
    name: 'Huadi International Group Co., Ltd.',
    symbol: 'HUDI',
  },
  {
    value: 'MNTK',
    label: 'MNTK,  Montauk Renewables, Inc.',
    name: 'Montauk Renewables, Inc.',
    symbol: 'MNTK',
  },
  {
    value: 'BIOTU',
    label: 'BIOTU,  Biotech Acquisition Company',
    name: 'Biotech Acquisition Company',
    symbol: 'BIOTU',
  },
  {
    value: 'LMACU',
    label: 'LMACU,  Liberty Media Acquisition Corporation',
    name: 'Liberty Media Acquisition Corporation',
    symbol: 'LMACU',
  },
  {
    value: 'PAX',
    label: 'PAX,  Patria Investments Limited',
    name: 'Patria Investments Limited',
    symbol: 'PAX',
  },
  {
    value: 'AASB.OL',
    label: 'AASB.OL,  Aasen Sparebank',
    name: 'Aasen Sparebank',
    symbol: 'AASB.OL',
  },
  {
    value: 'AGLX.OL',
    label: 'AGLX.OL,  Agilyx ASA',
    name: 'Agilyx ASA',
    symbol: 'AGLX.OL',
  },
  {
    value: 'ALAGP.PA',
    label: 'ALAGP.PA,  Agripower France SA',
    name: 'Agripower France SA',
    symbol: 'ALAGP.PA',
  },
  {
    value: 'AIRX.OL',
    label: 'AIRX.OL,  Airthings ASA',
    name: 'Airthings ASA',
    symbol: 'AIRX.OL',
  },
  {
    value: 'AKBM.OL',
    label: 'AKBM.OL,  Aker BioMarine AS',
    name: 'Aker BioMarine AS',
    symbol: 'AKBM.OL',
  },
  {
    value: 'ACC.OL',
    label: 'ACC.OL,  Aker Carbon Capture ASA',
    name: 'Aker Carbon Capture ASA',
    symbol: 'ACC.OL',
  },
  {
    value: 'ALCHI.PA',
    label: 'ALCHI.PA,  Alchimie S.A.S.',
    name: 'Alchimie S.A.S.',
    symbol: 'ALCHI.PA',
  },
  {
    value: 'ANDF.OL',
    label: 'ANDF.OL,  Andfjord Salmon AS',
    name: 'Andfjord Salmon AS',
    symbol: 'ANDF.OL',
  },
  {
    value: 'ALJXR.PA',
    label: 'ALJXR.PA,  Archos S.A.',
    name: 'Archos S.A.',
    symbol: 'ALJXR.PA',
  },
  {
    value: 'AYFIE.OL',
    label: 'AYFIE.OL,  ayfie Group AS',
    name: 'ayfie Group AS',
    symbol: 'AYFIE.OL',
  },
  {
    value: 'BSP.OL',
    label: 'BSP.OL,  Black Sea Property AS',
    name: 'Black Sea Property AS',
    symbol: 'BSP.OL',
  },
  {
    value: 'ALCG.PA',
    label: 'ALCG.PA,  Cabasse Group',
    name: 'Cabasse Group',
    symbol: 'ALCG.PA',
  },
  {
    value: 'ALLEC.PA',
    label: 'ALLEC.PA,  Cogelec SA',
    name: 'Cogelec SA',
    symbol: 'ALLEC.PA',
  },
  {
    value: 'CSAM.OL',
    label: 'CSAM.OL,  CSAM Health Group AS',
    name: 'CSAM Health Group AS',
    symbol: 'CSAM.OL',
  },
  {
    value: 'CYVIZ.OL',
    label: 'CYVIZ.OL,  Cyviz AS',
    name: 'Cyviz AS',
    symbol: 'CYVIZ.OL',
  },
  {
    value: 'ALDNX.PA',
    label: 'ALDNX.PA,  DNXCorp SE',
    name: 'DNXCorp SE',
    symbol: 'ALDNX.PA',
  },
  {
    value: 'ALECO.PA',
    label: 'ALECO.PA,  écomiam SA',
    name: 'écomiam SA',
    symbol: 'ALECO.PA',
  },
  {
    value: 'ELABS.OL',
    label: 'ELABS.OL,  Elliptic Laboratories ASA',
    name: 'Elliptic Laboratories ASA',
    symbol: 'ELABS.OL',
  },
  {
    value: 'ELOP.OL',
    label: 'ELOP.OL,  Elop AS',
    name: 'Elop AS',
    symbol: 'ELOP.OL',
  },
  {
    value: 'ALNRG.PA',
    label: 'ALNRG.PA,  SA Energisme',
    name: 'SA Energisme',
    symbol: 'ALNRG.PA',
  },
  {
    value: 'JCICU',
    label: 'JCICU,  Jack Creek Investment Corp.',
    name: 'Jack Creek Investment Corp.',
    symbol: 'JCICU',
  },
  {
    value: 'SHACU',
    label: 'SHACU,  SCP & CO Healthcare Acquisition Company',
    name: 'SCP & CO Healthcare Acquisition Company',
    symbol: 'SHACU',
  },
  {
    value: 'HCIIU',
    label: 'HCIIU,  Hudson Executive Investment Corp. II',
    name: 'Hudson Executive Investment Corp. II',
    symbol: 'HCIIU',
  },
  {
    value: 'OEPWU',
    label: 'OEPWU,  One Equity Partners Open Water I Corp.',
    name: 'One Equity Partners Open Water I Corp.',
    symbol: 'OEPWU',
  },
  {
    value: 'COMS',
    label: 'COMS,  COMSovereign Holding Corp.',
    name: 'COMSovereign Holding Corp.',
    symbol: 'COMS',
  },
  {
    value: 'COMSW',
    label: 'COMSW,  COMSovereign Holding Corp.',
    name: 'COMSovereign Holding Corp.',
    symbol: 'COMSW',
  },
  {
    value: 'AASU.L',
    label: 'AASU.L,  Amundi Index Solutions - Amundi MSCI Em Asia',
    name: 'Amundi Index Solutions - Amundi MSCI Em Asia',
    symbol: 'AASU.L',
  },
  {
    value: 'AEXK.L',
    label: 'AEXK.L,  Amundi Index Solutions - Amundi MSCI Europe Ex UK',
    name: 'Amundi Index Solutions - Amundi MSCI Europe Ex UK',
    symbol: 'AEXK.L',
  },
  {
    value: 'ALAU.L',
    label: 'ALAU.L,  Amundi Index Solutions - Amundi MSCI Em Latin America',
    name: 'Amundi Index Solutions - Amundi MSCI Em Latin America',
    symbol: 'ALAU.L',
  },
  {
    value: 'ANRJ.L',
    label: 'ANRJ.L,  Amundi ETF MSCI Europe Energy UCITS ETF',
    name: 'Amundi ETF MSCI Europe Energy UCITS ETF',
    symbol: 'ANRJ.L',
  },
  {
    value: 'ANXU.L',
    label: 'ANXU.L,  Amundi Index Solutions - Amundi Nasdaq-100',
    name: 'Amundi Index Solutions - Amundi Nasdaq-100',
    symbol: 'ANXU.L',
  },
  {
    value: 'AO.L',
    label: 'AO.L,  AO World plc',
    name: 'AO World plc',
    symbol: 'AO.L',
  },
  {
    value: 'ASIU.L',
    label: 'ASIU.L,  Lyxor MSCI China ESG Leaders Extra (DR) UCITS ETF',
    name: 'Lyxor MSCI China ESG Leaders Extra (DR) UCITS ETF',
    symbol: 'ASIU.L',
  },
  {
    value: 'AUEM.L',
    label: 'AUEM.L,  Amundi Index Solutions - Amundi MSCI Emerging Markets',
    name: 'Amundi Index Solutions - Amundi MSCI Emerging Markets',
    symbol: 'AUEM.L',
  },
  {
    value: 'BA.L',
    label: 'BA.L,  BAE Systems plc',
    name: 'BAE Systems plc',
    symbol: 'BA.L',
  },
  {
    value: 'BENE.L',
    label: 'BENE.L,  WisdomTree Energy Enhanced',
    name: 'WisdomTree Energy Enhanced',
    symbol: 'BENE.L',
  },
  {
    value: 'BMT.L',
    label: 'BMT.L,  Braime Group PLC',
    name: 'Braime Group PLC',
    symbol: 'BMT.L',
  },
  {
    value: 'BNKE.L',
    label: 'BNKE.L,  Lyxor Euro Stoxx Banks (Dr) Ucits ETF',
    name: 'Lyxor Euro Stoxx Banks (Dr) Ucits ETF',
    symbol: 'BNKE.L',
  },
  {
    value: 'BP.L',
    label: 'BP.L,  BP p.l.c.',
    name: 'BP p.l.c.',
    symbol: 'BP.L',
  },
  {
    value: 'BRZ.L',
    label: 'BRZ.L,  Amundi Index Solutions - Amundi ETF MSCI Brazil UCITS ETF',
    name: 'Amundi Index Solutions - Amundi ETF MSCI Brazil UCITS ETF',
    symbol: 'BRZ.L',
  },
  {
    value: 'BSE.L',
    label: 'BSE.L,  Base Resources Limited',
    name: 'Base Resources Limited',
    symbol: 'BSE.L',
  },
  {
    value: 'BXP.L',
    label: 'BXP.L,  Beximco Pharmaceuticals Limited',
    name: 'Beximco Pharmaceuticals Limited',
    symbol: 'BXP.L',
  },
  {
    value: 'CACX.L',
    label: 'CACX.L,  Lyxor CAC 40 (DR) UCITS ETF',
    name: 'Lyxor CAC 40 (DR) UCITS ETF',
    symbol: 'CACX.L',
  },
  {
    value: 'CCBO.L',
    label: 'CCBO.L,  WisdomTree AT1 CoCo Bond UCITS ETF',
    name: 'WisdomTree AT1 CoCo Bond UCITS ETF',
    symbol: 'CCBO.L',
  },
  {
    value: 'CCS.L',
    label: 'CCS.L,  Crossword Cybersecurity Plc',
    name: 'Crossword Cybersecurity Plc',
    symbol: 'CCS.L',
  },
  {
    value: 'CEUR.L',
    label: 'CEUR.L,  Amundi Index Solutions - Amundi MSCI Europe ESG Broad CTB',
    name: 'Amundi Index Solutions - Amundi MSCI Europe ESG Broad CTB',
    symbol: 'CEUR.L',
  },
  {
    value: 'CLMU.L',
    label: 'CLMU.L,  Lyxor Green Bond (DR) UCITS ETF',
    name: 'Lyxor Green Bond (DR) UCITS ETF',
    symbol: 'CLMU.L',
  },
  {
    value: 'CMCL.L',
    label: 'CMCL.L,  Caledonia Mining Corporation Plc',
    name: 'Caledonia Mining Corporation Plc',
    symbol: 'CMCL.L',
  },
  {
    value: 'CMU.L',
    label: 'CMU.L,  Amundi Index Solutions-Amundi MSCI EMU ESG Leaders Select',
    name: 'Amundi Index Solutions-Amundi MSCI EMU ESG Leaders Select',
    symbol: 'CMU.L',
  },
  {
    value: 'COCB.L',
    label: 'COCB.L,  WisdomTree AT1 CoCo Bond UCITS ETF',
    name: 'WisdomTree AT1 CoCo Bond UCITS ETF',
    symbol: 'COCB.L',
  },
  {
    value: 'COUK.L',
    label: 'COUK.L,  Lyxor iBoxx £ Liquid Corporates Long Dated UCITS ETF',
    name: 'Lyxor iBoxx £ Liquid Corporates Long Dated UCITS ETF',
    symbol: 'COUK.L',
  },
  {
    value: 'CRPX.L',
    label: 'CRPX.L,  Lyxor ESG Euro Corporate Bond UCITS ETF - Acc',
    name: 'Lyxor ESG Euro Corporate Bond UCITS ETF - Acc',
    symbol: 'CRPX.L',
  },
  {
    value: 'CSHD.L',
    label: 'CSHD.L,  Lyxor Euro Overnight Return UCITS ETF',
    name: 'Lyxor Euro Overnight Return UCITS ETF',
    symbol: 'CSHD.L',
  },
  {
    value: 'CUK.L',
    label: 'CUK.L,  Amundi ETF MSCI UK UCITS ETF',
    name: 'Amundi ETF MSCI UK UCITS ETF',
    symbol: 'CUK.L',
  },
  {
    value: 'DAXX.L',
    label: 'DAXX.L,  MULTI-UNITS LUXEMBOURG - Lyxor DAX (DR) UCITS ETF',
    name: 'MULTI-UNITS LUXEMBOURG - Lyxor DAX (DR) UCITS ETF',
    symbol: 'DAXX.L',
  },
  {
    value: 'DSUS.L',
    label: 'DSUS.L,  Lyxor 10Y US Treasury Daily (-2x) Inverse UCITS ETF',
    name: 'Lyxor 10Y US Treasury Daily (-2x) Inverse UCITS ETF',
    symbol: 'DSUS.L',
  },
  {
    value: 'DX.L',
    label: 'DX.L,  DX (Group) plc',
    name: 'DX (Group) plc',
    symbol: 'DX.L',
  },
  {
    value: 'EESG.L',
    label: 'EESG.L,  Lyxor MSCI EMU ESG Leaders Extra (DR) UCITS ETF',
    name: 'Lyxor MSCI EMU ESG Leaders Extra (DR) UCITS ETF',
    symbol: 'EESG.L',
  },
  {
    value: 'ELLA.L',
    label: 'ELLA.L,  Ecclesiastical Insurance Office plc',
    name: 'Ecclesiastical Insurance Office plc',
    symbol: 'ELLA.L',
  },
  {
    value: 'ESGL.L',
    label: 'ESGL.L,  Lyxor MSCI Europe ESG Leaders (DR) UCITS ETF - Acc',
    name: 'Lyxor MSCI Europe ESG Leaders (DR) UCITS ETF - Acc',
    symbol: 'ESGL.L',
  },
  {
    value: 'FBT.L',
    label: 'FBT.L,  First Trust NYSE Arca Biotechnology UCITS ETF',
    name: 'First Trust NYSE Arca Biotechnology UCITS ETF',
    symbol: 'FBT.L',
  },
  {
    value: 'FEUI.L',
    label: 'FEUI.L,  Fidelity Europe Quality Income UCITS ETF EUR Inc',
    name: 'Fidelity Europe Quality Income UCITS ETF EUR Inc',
    symbol: 'FEUI.L',
  },
  {
    value: 'FLRK.L',
    label: 'FLRK.L,  Franklin FTSE Korea UCITS ETF',
    name: 'Franklin FTSE Korea UCITS ETF',
    symbol: 'FLRK.L',
  },
  {
    value: 'FLUC.L',
    label:
      'FLUC.L,  Franklin Liberty USD Investment Grade Corporate Bond UCITS ETF',
    name: 'Franklin Liberty USD Investment Grade Corporate Bond UCITS ETF',
    symbol: 'FLUC.L',
  },
  {
    value: 'FRCH.L',
    label: 'FRCH.L,  Franklin FTSE China UCITS ETF',
    name: 'Franklin FTSE China UCITS ETF',
    symbol: 'FRCH.L',
  },
  {
    value: 'GBP.L',
    label: 'GBP.L,  Global Petroleum Limited',
    name: 'Global Petroleum Limited',
    symbol: 'GBP.L',
  },
  {
    value: 'GIL5.L',
    label: 'GIL5.L,  Lyxor UK Government Bond 0-5Y (DR) UCITS ETF',
    name: 'Lyxor UK Government Bond 0-5Y (DR) UCITS ETF',
    symbol: 'GIL5.L',
  },
  {
    value: 'GIST.L',
    label:
      'GIST.L,  Lyxor Core Global Inflation-Linked 1-10Y Bond (DR) UCITS ETF Distribution Dis',
    name: 'Lyxor Core Global Inflation-Linked 1-10Y Bond (DR) UCITS ETF Distribution Dis',
    symbol: 'GIST.L',
  },
  {
    value: 'GOLB.L',
    label: 'GOLB.L,  Market Access NYSE Arca Gold BUGS Index UCITS ETF',
    name: 'Market Access NYSE Arca Gold BUGS Index UCITS ETF',
    symbol: 'GOLB.L',
  },
  {
    value: 'HL.L',
    label: 'HL.L,  Hargreaves Lansdown plc',
    name: 'Hargreaves Lansdown plc',
    symbol: 'HL.L',
  },
  {
    value: 'HLTW.L',
    label: 'HLTW.L,  Lyxor MSCI World Health Care TR UCITS ETF',
    name: 'Lyxor MSCI World Health Care TR UCITS ETF',
    symbol: 'HLTW.L',
  },
  {
    value: 'IMC.L',
    label: 'IMC.L,  IMC Exploration Group Public Limited Company',
    name: 'IMC Exploration Group Public Limited Company',
    symbol: 'IMC.L',
  },
  {
    value: 'INDW.L',
    label: 'INDW.L,  Lyxor MSCI World Industrials TR UCITS ETF',
    name: 'Lyxor MSCI World Industrials TR UCITS ETF',
    symbol: 'INDW.L',
  },
  {
    value: 'INTL.L',
    label: 'INTL.L,  WisdomTree Artificial Intelligence UCITS ETF',
    name: 'WisdomTree Artificial Intelligence UCITS ETF',
    symbol: 'INTL.L',
  },
  {
    value: 'JD.L',
    label: 'JD.L,  JD Sports Fashion plc',
    name: 'JD Sports Fashion plc',
    symbol: 'JD.L',
  },
  {
    value: 'JSE.L',
    label: 'JSE.L,  Jadestone Energy plc',
    name: 'Jadestone Energy plc',
    symbol: 'JSE.L',
  },
  {
    value: 'KLWD.L',
    label: 'KLWD.L,  WisdomTree Cloud Computing UCITS ETF',
    name: 'WisdomTree Cloud Computing UCITS ETF',
    symbol: 'KLWD.L',
  },
  {
    value: 'KWEB.L',
    label: 'KWEB.L,  KraneShares CSI China Internet UCITS ETF',
    name: 'KraneShares CSI China Internet UCITS ETF',
    symbol: 'KWEB.L',
  },
  {
    value: 'L100.L',
    label: 'L100.L,  MULTI-UNITS LUXEMBOURG - Lyxor FTSE 100 UCITS ETF',
    name: 'MULTI-UNITS LUXEMBOURG - Lyxor FTSE 100 UCITS ETF',
    symbol: 'L100.L',
  },
  {
    value: 'L6EW.L',
    label: 'L6EW.L,  Ossiam STOXX Europe 600 Equal Weight NR UCITS ETF',
    name: 'Ossiam STOXX Europe 600 Equal Weight NR UCITS ETF',
    symbol: 'L6EW.L',
  },
  {
    value: 'LCPE.L',
    label: 'LCPE.L,  Ossiam Shiller Barclays Cape Europe Sector Value TR',
    name: 'Ossiam Shiller Barclays Cape Europe Sector Value TR',
    symbol: 'LCPE.L',
  },
  {
    value: 'LDCU.L',
    label: 'LDCU.L,  PIMCO Low Duration US Corporate Bond Source Ucits ETF',
    name: 'PIMCO Low Duration US Corporate Bond Source Ucits ETF',
    symbol: 'LDCU.L',
  },
  {
    value: 'LEMB.L',
    label:
      'LEMB.L,  Lyxor Index Fund - Lyxor iBoxx $ Liquid Emerging Markets Sovereign UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor iBoxx $ Liquid Emerging Markets Sovereign UCITS ETF',
    symbol: 'LEMB.L',
  },
  {
    value: 'LEMV.L',
    label: 'LEMV.L,  Ossiam Europe ESG Machine Learning',
    name: 'Ossiam Europe ESG Machine Learning',
    symbol: 'LEMV.L',
  },
  {
    value: 'MESG.L',
    label: 'MESG.L,  Lyxor MSCI EM ESG Leaders Extra UCITS ETF',
    name: 'Lyxor MSCI EM ESG Leaders Extra UCITS ETF',
    symbol: 'MESG.L',
  },
  {
    value: 'MIBX.L',
    label: 'MIBX.L,  Lyxor FTSE MIB (DR) UCITS ETF',
    name: 'Lyxor FTSE MIB (DR) UCITS ETF',
    symbol: 'MIBX.L',
  },
  {
    value: 'MTIX.L',
    label:
      'MTIX.L,  Lyxor Core Euro Government Inflation-Linked Bond (DR) UCITS ETF',
    name: 'Lyxor Core Euro Government Inflation-Linked Bond (DR) UCITS ETF',
    symbol: 'MTIX.L',
  },
  {
    value: 'MTXX.L',
    label:
      'MTXX.L,  MULTI-UNITS LUXEMBOURG - Lyxor Euro Government Bond (DR) UCITS ETF',
    name: 'MULTI-UNITS LUXEMBOURG - Lyxor Euro Government Bond (DR) UCITS ETF',
    symbol: 'MTXX.L',
  },
  {
    value: 'NBS.L',
    label: 'NBS.L,  Nationwide Building Society',
    name: 'Nationwide Building Society',
    symbol: 'NBS.L',
  },
  {
    value: 'NG.L',
    label: 'NG.L,  National Grid plc',
    name: 'National Grid plc',
    symbol: 'NG.L',
  },
  {
    value: 'NRGW.L',
    label: 'NRGW.L,  Lyxor MSCI World Energy TR UCITS ETF',
    name: 'Lyxor MSCI World Energy TR UCITS ETF',
    symbol: 'NRGW.L',
  },
  {
    value: 'PRE.L',
    label: 'PRE.L,  Pensana Plc',
    name: 'Pensana Plc',
    symbol: 'PRE.L',
  },
  {
    value: 'QQ.L',
    label: 'QQ.L,  QinetiQ Group plc',
    name: 'QinetiQ Group plc',
    symbol: 'QQ.L',
  },
  {
    value: 'RE.L',
    label: 'RE.L,  R.E.A. Holdings plc',
    name: 'R.E.A. Holdings plc',
    symbol: 'RE.L',
  },
  {
    value: 'RICI.L',
    label:
      'RICI.L,  Market Access Rogers International Commodity Index UCITS ETF',
    name: 'Market Access Rogers International Commodity Index UCITS ETF',
    symbol: 'RICI.L',
  },
  {
    value: 'RM.L',
    label: 'RM.L,  RM plc',
    name: 'RM plc',
    symbol: 'RM.L',
  },
  {
    value: 'RSGL.L',
    label: 'RSGL.L,  Lyxor Russell 1000 Growth UCITS ETF',
    name: 'Lyxor Russell 1000 Growth UCITS ETF',
    symbol: 'RSGL.L',
  },
  {
    value: 'SN.L',
    label: 'SN.L,  Smith & Nephew plc',
    name: 'Smith & Nephew plc',
    symbol: 'SN.L',
  },
  {
    value: 'STHE.L',
    label:
      'STHE.L,  PIMCO Short-Term High Yield Corporate Bond Index Source UCITS ETF',
    name: 'PIMCO Short-Term High Yield Corporate Bond Index Source UCITS ETF',
    symbol: 'STHE.L',
  },
  {
    value: 'STPU.L',
    label:
      'STPU.L,  MULTI-UNITS LUXEMBOURG - Lyxor US Curve Steepening 2-10 UCITS ETF',
    name: 'MULTI-UNITS LUXEMBOURG - Lyxor US Curve Steepening 2-10 UCITS ETF',
    symbol: 'STPU.L',
  },
  {
    value: 'STYC.L',
    label:
      'STYC.L,  PIMCO Short-Term High Yield Corporate Bond Index Source UCITS ETF USD Acc',
    name: 'PIMCO Short-Term High Yield Corporate Bond Index Source UCITS ETF USD Acc',
    symbol: 'STYC.L',
  },
  {
    value: 'TFRN.L',
    label: 'TFRN.L,  WisdomTree USD Floating Rate Treasury Bond UCITS ETF',
    name: 'WisdomTree USD Floating Rate Treasury Bond UCITS ETF',
    symbol: 'TFRN.L',
  },
  {
    value: 'TNOW.L',
    label: 'TNOW.L,  Lyxor MSCI World Information Technology TR UCITS ETF',
    name: 'Lyxor MSCI World Information Technology TR UCITS ETF',
    symbol: 'TNOW.L',
  },
  {
    value: 'TSWE.L',
    label: 'TSWE.L,  VanEck Sustainable World Equal Weight UCITS ETF',
    name: 'VanEck Sustainable World Equal Weight UCITS ETF',
    symbol: 'TSWE.L',
  },
  {
    value: 'TW.L',
    label: 'TW.L,  Taylor Wimpey plc',
    name: 'Taylor Wimpey plc',
    symbol: 'TW.L',
  },
  {
    value: 'U71G.L',
    label: 'U71G.L,  Lyxor Core US Treasury 7-10Y (DR) UCITS ETF',
    name: 'Lyxor Core US Treasury 7-10Y (DR) UCITS ETF',
    symbol: 'U71G.L',
  },
  {
    value: 'UCAP.L',
    label: 'UCAP.L,  Ossiam Shiller Barclays Cape US Sector Value TR',
    name: 'Ossiam Shiller Barclays Cape US Sector Value TR',
    symbol: 'UCAP.L',
  },
  {
    value: 'UESD.L',
    label: 'UESD.L,  iShares £ Ultrashort Bond ESG UCITS ETF',
    name: 'iShares £ Ultrashort Bond ESG UCITS ETF',
    symbol: 'UESD.L',
  },
  {
    value: 'UESG.L',
    label: 'UESG.L,  Lyxor MSCI USA ESG Leaders Extra (DR) UCITS ETF',
    name: 'Lyxor MSCI USA ESG Leaders Extra (DR) UCITS ETF',
    symbol: 'UESG.L',
  },
  {
    value: 'USFR.L',
    label: 'USFR.L,  WisdomTree USD Floating Rate Treasury Bond UCITS ETF',
    name: 'WisdomTree USD Floating Rate Treasury Bond UCITS ETF',
    symbol: 'USFR.L',
  },
  {
    value: 'USMV.L',
    label: 'USMV.L,  Ossiam US Minimum Variance ESG NR UCITS ETF',
    name: 'Ossiam US Minimum Variance ESG NR UCITS ETF',
    symbol: 'USMV.L',
  },
  {
    value: 'UTIW.L',
    label: 'UTIW.L,  Lyxor MSCI World Utilities TR UCITS ETF',
    name: 'Lyxor MSCI World Utilities TR UCITS ETF',
    symbol: 'UTIW.L',
  },
  {
    value: 'UU.L',
    label: 'UU.L,  United Utilities Group PLC',
    name: 'United Utilities Group PLC',
    symbol: 'UU.L',
  },
  {
    value: 'VP.L',
    label: 'VP.L,  Vp plc',
    name: 'Vp plc',
    symbol: 'VP.L',
  },
  {
    value: 'WATL.L',
    label: 'WATL.L,  Lyxor World Water (DR) UCITS ETF Dist',
    name: 'Lyxor World Water (DR) UCITS ETF Dist',
    symbol: 'WATL.L',
  },
  {
    value: 'WESG.L',
    label: 'WESG.L,  Lyxor MSCI World ESG Leaders Extra (DR) UCITS ETF',
    name: 'Lyxor MSCI World ESG Leaders Extra (DR) UCITS ETF',
    symbol: 'WESG.L',
  },
  {
    value: 'WG.L',
    label: 'WG.L,  John Wood Group PLC',
    name: 'John Wood Group PLC',
    symbol: 'WG.L',
  },
  {
    value: 'WTAI.L',
    label: 'WTAI.L,  WisdomTree Artificial Intelligence UCITS ETF',
    name: 'WisdomTree Artificial Intelligence UCITS ETF',
    symbol: 'WTAI.L',
  },
  {
    value: 'WUKD.L',
    label: 'WUKD.L,  WisdomTree UK Equity Income UCITS ETF',
    name: 'WisdomTree UK Equity Income UCITS ETF',
    symbol: 'WUKD.L',
  },
  {
    value: 'XRH0.L',
    label: 'XRH0.L,  db Physical Rhodium ETC',
    name: 'db Physical Rhodium ETC',
    symbol: 'XRH0.L',
  },
  {
    value: 'YIEL.L',
    label:
      'YIEL.L,  Lyxor Index Fund - Lyxor ESG Euro High Yield (DR) UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor ESG Euro High Yield (DR) UCITS ETF',
    symbol: 'YIEL.L',
  },
  {
    value: 'YU.L',
    label: 'YU.L,  Yü Group PLC',
    name: 'Yü Group PLC',
    symbol: 'YU.L',
  },
  {
    value: 'ALPHI.PA',
    label: 'ALPHI.PA,  Facephi Biometria, S.A.',
    name: 'Facephi Biometria, S.A.',
    symbol: 'ALPHI.PA',
  },
  {
    value: 'ALFLE.PA',
    label: 'ALFLE.PA,  Fleury Michon SA',
    name: 'Fleury Michon SA',
    symbol: 'ALFLE.PA',
  },
  {
    value: 'ALMRB.PA',
    label: 'ALMRB.PA,  Mr.Bricolage S.A.',
    name: 'Mr.Bricolage S.A.',
    symbol: 'ALMRB.PA',
  },
  {
    value: 'ALMUN.PA',
    label: 'ALMUN.PA,  Munic S.A.',
    name: 'Munic S.A.',
    symbol: 'ALMUN.PA',
  },
  {
    value: 'NACON.PA',
    label: 'NACON.PA,  Nacon S.A.',
    name: 'Nacon S.A.',
    symbol: 'NACON.PA',
  },
  {
    value: 'ALPAU.PA',
    label: 'ALPAU.PA,  PAULIC Meunerie SA',
    name: 'PAULIC Meunerie SA',
    symbol: 'ALPAU.PA',
  },
  {
    value: 'ALPIX.PA',
    label: 'ALPIX.PA,  Pixium Vision SA',
    name: 'Pixium Vision SA',
    symbol: 'ALPIX.PA',
  },
  {
    value: 'QDT.PA',
    label: 'QDT.PA,  Quadient S.A.',
    name: 'Quadient S.A.',
    symbol: 'QDT.PA',
  },
  {
    value: 'ALQP.PA',
    label: 'ALQP.PA,  Quadpack Industries, S.A.',
    name: 'Quadpack Industries, S.A.',
    symbol: 'ALQP.PA',
  },
  {
    value: 'VRLA.PA',
    label: 'VRLA.PA,  Verallia Société Anonyme',
    name: 'Verallia Société Anonyme',
    symbol: 'VRLA.PA',
  },
  {
    value: 'A5G.IR',
    label: 'A5G.IR,  AIB Group plc',
    name: 'AIB Group plc',
    symbol: 'A5G.IR',
  },
  {
    value: 'BIRG.IR',
    label: 'BIRG.IR,  Bank of Ireland Group plc',
    name: 'Bank of Ireland Group plc',
    symbol: 'BIRG.IR',
  },
  {
    value: 'C5H.IR',
    label: 'C5H.IR,  Cairn Homes plc',
    name: 'Cairn Homes plc',
    symbol: 'C5H.IR',
  },
  {
    value: 'CMCOM.AS',
    label: 'CMCOM.AS,  CM.com N.V.',
    name: 'CM.com N.V.',
    symbol: 'CMCOM.AS',
  },
  {
    value: 'CRG.IR',
    label: 'CRG.IR,  CRH plc',
    name: 'CRH plc',
    symbol: 'CRG.IR',
  },
  {
    value: 'DHG.IR',
    label: 'DHG.IR,  Dalata Hotel Group plc',
    name: 'Dalata Hotel Group plc',
    symbol: 'DHG.IR',
  },
  {
    value: 'DLE.IR',
    label: 'DLE.IR,  Datalex plc',
    name: 'Datalex plc',
    symbol: 'DLE.IR',
  },
  {
    value: 'GRW.IR',
    label: 'GRW.IR,  Molten Ventures Plc',
    name: 'Molten Ventures Plc',
    symbol: 'GRW.IR',
  },
  {
    value: 'FLTR.IR',
    label: 'FLTR.IR,  Flutter Entertainment plc',
    name: 'Flutter Entertainment plc',
    symbol: 'FLTR.IR',
  },
  {
    value: 'GL9.IR',
    label: 'GL9.IR,  Glanbia plc',
    name: 'Glanbia plc',
    symbol: 'GL9.IR',
  },
  {
    value: '8GW.IR',
    label: '8GW.IR,  Great Western Mining Corporation PLC',
    name: 'Great Western Mining Corporation PLC',
    symbol: '8GW.IR',
  },
  {
    value: 'HSW.IR',
    label: 'HSW.IR,  Hostelworld Group plc',
    name: 'Hostelworld Group plc',
    symbol: 'HSW.IR',
  },
  {
    value: 'IR5B.IR',
    label: 'IR5B.IR,  Irish Continental Group plc',
    name: 'Irish Continental Group plc',
    symbol: 'IR5B.IR',
  },
  {
    value: 'IRES.IR',
    label: 'IRES.IR,  Irish Residential Properties REIT Plc',
    name: 'Irish Residential Properties REIT Plc',
    symbol: 'IRES.IR',
  },
  {
    value: 'KRZ.IR',
    label: 'KRZ.IR,  Kerry Group plc',
    name: 'Kerry Group plc',
    symbol: 'KRZ.IR',
  },
  {
    value: 'KRX.IR',
    label: 'KRX.IR,  Kingspan Group plc',
    name: 'Kingspan Group plc',
    symbol: 'KRX.IR',
  },
  {
    value: 'MLC.IR',
    label: 'MLC.IR,  Malin Corporation plc',
    name: 'Malin Corporation plc',
    symbol: 'MLC.IR',
  },
  {
    value: 'MRL.LS',
    label: 'MRL.LS,  MERLIN Properties SOCIMI, S.A.',
    name: 'MERLIN Properties SOCIMI, S.A.',
    symbol: 'MRL.LS',
  },
  {
    value: 'MIO.IR',
    label: 'MIO.IR,  Mincon Group plc',
    name: 'Mincon Group plc',
    symbol: 'MIO.IR',
  },
  {
    value: 'ORPH.IR',
    label: 'ORPH.IR,  Open Orphan Plc',
    name: 'Open Orphan Plc',
    symbol: 'ORPH.IR',
  },
  {
    value: 'OIZ.IR',
    label: 'OIZ.IR,  Origin Enterprises plc',
    name: 'Origin Enterprises plc',
    symbol: 'OIZ.IR',
  },
  {
    value: 'ORQ1.IR',
    label: 'ORQ1.IR,  Ormonde Mining plc',
    name: 'Ormonde Mining plc',
    symbol: 'ORQ1.IR',
  },
  {
    value: 'OVXA.IR',
    label: 'OVXA.IR,  Ovoca Bio plc',
    name: 'Ovoca Bio plc',
    symbol: 'OVXA.IR',
  },
  {
    value: 'IL0A.IR',
    label: 'IL0A.IR,  Permanent TSB Group Holdings plc',
    name: 'Permanent TSB Group Holdings plc',
    symbol: 'IL0A.IR',
  },
  {
    value: 'P8ET.IR',
    label: 'P8ET.IR,  PetroNeft Resources plc',
    name: 'PetroNeft Resources plc',
    symbol: 'P8ET.IR',
  },
  {
    value: 'SK3.IR',
    label: 'SK3.IR,  Smurfit Kappa Group Plc',
    name: 'Smurfit Kappa Group Plc',
    symbol: 'SK3.IR',
  },
  {
    value: 'UPR.IR',
    label: 'UPR.IR,  Uniphar plc',
    name: 'Uniphar plc',
    symbol: 'UPR.IR',
  },
  {
    value: 'BMW3.DE',
    label: 'BMW3.DE,  Bayerische Motoren Werke Aktiengesellschaft',
    name: 'Bayerische Motoren Werke Aktiengesellschaft',
    symbol: 'BMW3.DE',
  },
  {
    value: 'SRT3.DE',
    label: 'SRT3.DE,  Sartorius Aktiengesellschaft',
    name: 'Sartorius Aktiengesellschaft',
    symbol: 'SRT3.DE',
  },
  {
    value: 'KA8.DE',
    label: 'KA8.DE,  Klassik Radio AG',
    name: 'Klassik Radio AG',
    symbol: 'KA8.DE',
  },
  {
    value: 'FPE.DE',
    label: 'FPE.DE,  Fuchs Petrolub SE',
    name: 'Fuchs Petrolub SE',
    symbol: 'FPE.DE',
  },
  {
    value: '690D.DE',
    label: '690D.DE,  Haier Smart Home Co., Ltd.',
    name: 'Haier Smart Home Co., Ltd.',
    symbol: '690D.DE',
  },
  {
    value: 'BYW.DE',
    label: 'BYW.DE,  BayWa Aktiengesellschaft',
    name: 'BayWa Aktiengesellschaft',
    symbol: 'BYW.DE',
  },
  {
    value: 'JUN3.DE',
    label: 'JUN3.DE,  Jungheinrich Aktiengesellschaft',
    name: 'Jungheinrich Aktiengesellschaft',
    symbol: 'JUN3.DE',
  },
  {
    value: 'KSB3.DE',
    label: 'KSB3.DE,  KSB SE & Co. KGaA',
    name: 'KSB SE & Co. KGaA',
    symbol: 'KSB3.DE',
  },
  {
    value: 'SFQ.DE',
    label: 'SFQ.DE,  SAF-Holland SE',
    name: 'SAF-Holland SE',
    symbol: 'SFQ.DE',
  },
  {
    value: 'DRW3.DE',
    label: 'DRW3.DE,  Drägerwerk AG & Co. KGaA',
    name: 'Drägerwerk AG & Co. KGaA',
    symbol: 'DRW3.DE',
  },
  {
    value: 'HEN.DE',
    label: 'HEN.DE,  Henkel AG & Co. KGaA',
    name: 'Henkel AG & Co. KGaA',
    symbol: 'HEN.DE',
  },
  {
    value: 'B4B3.DE',
    label: 'B4B3.DE,  Metro AG',
    name: 'Metro AG',
    symbol: 'B4B3.DE',
  },
  {
    value: 'SCE.DE',
    label: 'SCE.DE,  Schweizer Electronic AG',
    name: 'Schweizer Electronic AG',
    symbol: 'SCE.DE',
  },
  {
    value: 'SIX3.DE',
    label: 'SIX3.DE,  Sixt SE',
    name: 'Sixt SE',
    symbol: 'SIX3.DE',
  },
  {
    value: 'PTICW',
    label: 'PTICW,  PropTech Investment Corporation II',
    name: 'PropTech Investment Corporation II',
    symbol: 'PTICW',
  },
  {
    value: 'PTIC',
    label: 'PTIC,  PropTech Investment Corporation II',
    name: 'PropTech Investment Corporation II',
    symbol: 'PTIC',
  },
  {
    value: 'FTCVW',
    label: 'FTCVW,  Fintech Acquisition Corp. V',
    name: 'Fintech Acquisition Corp. V',
    symbol: 'FTCVW',
  },
  {
    value: 'LANDM',
    label: 'LANDM,  Gladstone Land Corporation',
    name: 'Gladstone Land Corporation',
    symbol: 'LANDM',
  },
  {
    value: 'ENR.DE',
    label: 'ENR.DE,  Siemens Energy AG',
    name: 'Siemens Energy AG',
    symbol: 'ENR.DE',
  },
  {
    value: 'HYMCL',
    label: 'HYMCL,  Hycroft Mining Holding Corporation',
    name: 'Hycroft Mining Holding Corporation',
    symbol: 'HYMCL',
  },
  {
    value: 'FTCV',
    label: 'FTCV,  Fintech Acquisition Corp. V',
    name: 'Fintech Acquisition Corp. V',
    symbol: 'FTCV',
  },
  {
    value: '1349.HK',
    label: '1349.HK,  Shanghai Fudan-Zhangjiang Bio-Pharmaceutical Co.,Ltd.',
    name: 'Shanghai Fudan-Zhangjiang Bio-Pharmaceutical Co.,Ltd.',
    symbol: '1349.HK',
  },
  {
    value: '1379.HK',
    label:
      '1379.HK,  Wenling Zhejiang Measuring and Cutting Tools Trading Centre Company Limited',
    name: 'Wenling Zhejiang Measuring and Cutting Tools Trading Centre Company Limited',
    symbol: '1379.HK',
  },
  {
    value: '1877.HK',
    label: '1877.HK,  Shanghai Junshi Biosciences Co., Ltd.',
    name: 'Shanghai Junshi Biosciences Co., Ltd.',
    symbol: '1877.HK',
  },
  {
    value: '3369.HK',
    label: '3369.HK,  Qinhuangdao Port Co., Ltd.',
    name: 'Qinhuangdao Port Co., Ltd.',
    symbol: '3369.HK',
  },
  {
    value: '3RB.DE',
    label: '3RB.DE,  Reckitt Benckiser Group plc',
    name: 'Reckitt Benckiser Group plc',
    symbol: '3RB.DE',
  },
  {
    value: '6116.HK',
    label: '6116.HK,  Xinjiang La Chapelle Fashion Co., Ltd.',
    name: 'Xinjiang La Chapelle Fashion Co., Ltd.',
    symbol: '6116.HK',
  },
  {
    value: 'AAGH',
    label: 'AAGH,  America Great Health',
    name: 'America Great Health',
    symbol: 'AAGH',
  },
  {
    value: 'AAIC-PC',
    label: 'AAIC-PC,  Arlington Asset Investment Corp.',
    name: 'Arlington Asset Investment Corp.',
    symbol: 'AAIC-PC',
  },
  {
    value: 'AASP',
    label: 'AASP,  Global Acquisitions Corporation',
    name: 'Global Acquisitions Corporation',
    symbol: 'AASP',
  },
  {
    value: 'ABCP',
    label: 'ABCP,  AmBase Corporation',
    name: 'AmBase Corporation',
    symbol: 'ABCP',
  },
  {
    value: 'ABILF',
    label: 'ABILF,  Ability Inc.',
    name: 'Ability Inc.',
    symbol: 'ABILF',
  },
  {
    value: 'ABMC',
    label: 'ABMC,  American Bio Medica Corporation',
    name: 'American Bio Medica Corporation',
    symbol: 'ABMC',
  },
  {
    value: 'ABML',
    label: 'ABML,  American Battery Technology Company',
    name: 'American Battery Technology Company',
    symbol: 'ABML',
  },
  {
    value: 'ABMT',
    label: 'ABMT,  Advanced Biomedical Technologies, Inc.',
    name: 'Advanced Biomedical Technologies, Inc.',
    symbol: 'ABMT',
  },
  {
    value: 'ABQQ',
    label: 'ABQQ,  AB International Group Corp.',
    name: 'AB International Group Corp.',
    symbol: 'ABQQ',
  },
  {
    value: 'ABST',
    label: 'ABST,  Absolute Software Corporation',
    name: 'Absolute Software Corporation',
    symbol: 'ABST',
  },
  {
    value: 'ABTI',
    label: 'ABTI,  Alterola Biotech, Inc.',
    name: 'Alterola Biotech, Inc.',
    symbol: 'ABTI',
  },
  {
    value: 'ABVC',
    label: 'ABVC,  ABVC BioPharma, Inc.',
    name: 'ABVC BioPharma, Inc.',
    symbol: 'ABVC',
  },
  {
    value: 'ACAN',
    label: 'ACAN,  AmeriCann, Inc.',
    name: 'AmeriCann, Inc.',
    symbol: 'ACAN',
  },
  {
    value: 'ACBM',
    label: 'ACBM,  Acro Biomedical Co., Ltd.',
    name: 'Acro Biomedical Co., Ltd.',
    symbol: 'ACBM',
  },
  {
    value: 'ACFN',
    label: 'ACFN,  Acorn Energy, Inc.',
    name: 'Acorn Energy, Inc.',
    symbol: 'ACFN',
  },
  {
    value: 'ACMC',
    label: 'ACMC,  American Church Mortgage Company',
    name: 'American Church Mortgage Company',
    symbol: 'ACMC',
  },
  {
    value: 'ACUR',
    label: 'ACUR,  Acura Pharmaceuticals, Inc.',
    name: 'Acura Pharmaceuticals, Inc.',
    symbol: 'ACUR',
  },
  {
    value: 'ADMT',
    label: 'ADMT,  ADM Tronics Unlimited, Inc.',
    name: 'ADM Tronics Unlimited, Inc.',
    symbol: 'ADMT',
  },
  {
    value: 'ADS.OL',
    label: 'ADS.OL,  ADS Maritime Holding Plc',
    name: 'ADS Maritime Holding Plc',
    symbol: 'ADS.OL',
  },
  {
    value: 'ADYEY',
    label: 'ADYEY,  Adyen N.V.',
    name: 'Adyen N.V.',
    symbol: 'ADYEY',
  },
  {
    value: 'AEPPZ',
    label: 'AEPPZ,  American Electric Power Company, Inc.',
    name: 'American Electric Power Company, Inc.',
    symbol: 'AEPPZ',
  },
  {
    value: 'AERG',
    label: 'AERG,  Applied Energetics, Inc.',
    name: 'Applied Energetics, Inc.',
    symbol: 'AERG',
  },
  {
    value: 'AFBI',
    label: 'AFBI,  Affinity Bancshares, Inc.',
    name: 'Affinity Bancshares, Inc.',
    symbol: 'AFBI',
  },
  {
    value: 'AFTM',
    label: 'AFTM,  AfterMaster, Inc.',
    name: 'AfterMaster, Inc.',
    symbol: 'AFTM',
  },
  {
    value: 'AGM-PE',
    label: 'AGM-PE,  Federal Agricultural Mortgage Corporation',
    name: 'Federal Agricultural Mortgage Corporation',
    symbol: 'AGM-PE',
  },
  {
    value: 'AGM-PF',
    label: 'AGM-PF,  Federal Agricultural Mortgage Corporation',
    name: 'Federal Agricultural Mortgage Corporation',
    symbol: 'AGM-PF',
  },
  {
    value: 'AGTX',
    label: 'AGTX,  Agentix Corp.',
    name: 'Agentix Corp.',
    symbol: 'AGTX',
  },
  {
    value: 'AHL-PE',
    label: 'AHL-PE,  Aspen Insurance Holdings Limited',
    name: 'Aspen Insurance Holdings Limited',
    symbol: 'AHL-PE',
  },
  {
    value: 'AHNR',
    label: 'AHNR,  Athena Gold Corporation',
    name: 'Athena Gold Corporation',
    symbol: 'AHNR',
  },
  {
    value: 'AHT-PD',
    label: 'AHT-PD,  Ashford Hospitality Trust, Inc.',
    name: 'Ashford Hospitality Trust, Inc.',
    symbol: 'AHT-PD',
  },
  {
    value: 'AHT-PI',
    label: 'AHT-PI,  Ashford Hospitality Trust, Inc.',
    name: 'Ashford Hospitality Trust, Inc.',
    symbol: 'AHT-PI',
  },
  {
    value: 'AITX',
    label: 'AITX,  Artificial Intelligence Technology Solutions Inc.',
    name: 'Artificial Intelligence Technology Solutions Inc.',
    symbol: 'AITX',
  },
  {
    value: 'AKU',
    label: 'AKU,  Akumin Inc.',
    name: 'Akumin Inc.',
    symbol: 'AKU',
  },
  {
    value: 'ALDA',
    label: 'ALDA,  Atlantica, Inc.',
    name: 'Atlantica, Inc.',
    symbol: 'ALDA',
  },
  {
    value: 'ALDS',
    label: 'ALDS,  APPlife Digital Solutions, Inc.',
    name: 'APPlife Digital Solutions, Inc.',
    symbol: 'ALDS',
  },
  {
    value: 'ALHGR.PA',
    label: 'ALHGR.PA,  Hoffmann Green Cement Technologies Société anonyme',
    name: 'Hoffmann Green Cement Technologies Société anonyme',
    symbol: 'ALHGR.PA',
  },
  {
    value: 'ALID',
    label: 'ALID,  Allied Corp.',
    name: 'Allied Corp.',
    symbol: 'ALID',
  },
  {
    value: 'ALL-PI',
    label: 'ALL-PI,  The Allstate Corporation',
    name: 'The Allstate Corporation',
    symbol: 'ALL-PI',
  },
  {
    value: 'ALMAR.PA',
    label: 'ALMAR.PA,  Mare Nostrum Société anonyme',
    name: 'Mare Nostrum Société anonyme',
    symbol: 'ALMAR.PA',
  },
  {
    value: 'AL-PA',
    label: 'AL-PA,  Air Lease Corporation',
    name: 'Air Lease Corporation',
    symbol: 'AL-PA',
  },
  {
    value: 'ALPP',
    label: 'ALPP,  Alpine 4 Holdings, Inc.',
    name: 'Alpine 4 Holdings, Inc.',
    symbol: 'ALPP',
  },
  {
    value: 'ALRT',
    label: 'ALRT,  ALR Technologies Inc.',
    name: 'ALR Technologies Inc.',
    symbol: 'ALRT',
  },
  {
    value: 'ALTD',
    label: 'ALTD,  Altitude International Holdings, Inc.',
    name: 'Altitude International Holdings, Inc.',
    symbol: 'ALTD',
  },
  {
    value: 'ALTX',
    label: 'ALTX,  Altex Industries, Inc.',
    name: 'Altex Industries, Inc.',
    symbol: 'ALTX',
  },
  {
    value: 'AMHG',
    label: 'AMHG,  Amergent Hospitality Group, Inc.',
    name: 'Amergent Hospitality Group, Inc.',
    symbol: 'AMHG',
  },
  {
    value: 'AMH-PH',
    label: 'AMH-PH,  American Homes 4 Rent',
    name: 'American Homes 4 Rent',
    symbol: 'AMH-PH',
  },
  {
    value: 'AMIH',
    label: 'AMIH,  American International Holdings Corp.',
    name: 'American International Holdings Corp.',
    symbol: 'AMIH',
  },
  {
    value: 'AMMJ',
    label: 'AMMJ,  American Cannabis Company, Inc.',
    name: 'American Cannabis Company, Inc.',
    symbol: 'AMMJ',
  },
  {
    value: 'AMMX',
    label: 'AMMX,  AmeraMex International, Inc.',
    name: 'AmeraMex International, Inc.',
    symbol: 'AMMX',
  },
  {
    value: 'AMNL',
    label: 'AMNL,  Applied Minerals, Inc.',
    name: 'Applied Minerals, Inc.',
    symbol: 'AMNL',
  },
  {
    value: 'AMPG',
    label: 'AMPG,  AmpliTech Group, Inc.',
    name: 'AmpliTech Group, Inc.',
    symbol: 'AMPG',
  },
  {
    value: 'AMSU',
    label: 'AMSU,  Amanasu Environment Corporation',
    name: 'Amanasu Environment Corporation',
    symbol: 'AMSU',
  },
  {
    value: 'AMTY',
    label: 'AMTY,  Amerityre Corporation',
    name: 'Amerityre Corporation',
    symbol: 'AMTY',
  },
  {
    value: 'ANDR',
    label: 'ANDR,  Andrea Electronics Corporation',
    name: 'Andrea Electronics Corporation',
    symbol: 'ANDR',
  },
  {
    value: 'ANSU',
    label: 'ANSU,  Amanasu Techno Holdings Corporation',
    name: 'Amanasu Techno Holdings Corporation',
    symbol: 'ANSU',
  },
  {
    value: 'AOUT',
    label: 'AOUT,  American Outdoor Brands, Inc.',
    name: 'American Outdoor Brands, Inc.',
    symbol: 'AOUT',
  },
  {
    value: 'AOXY',
    label: 'AOXY,  Advanced Oxygen Technologies, Inc.',
    name: 'Advanced Oxygen Technologies, Inc.',
    symbol: 'AOXY',
  },
  {
    value: 'APCX',
    label: 'APCX,  AppTech Payments Corp.',
    name: 'AppTech Payments Corp.',
    symbol: 'APCX',
  },
  {
    value: 'APHE',
    label: 'APHE,  Alpha Energy, Inc.',
    name: 'Alpha Energy, Inc.',
    symbol: 'APHE',
  },
  {
    value: 'APTV-PA',
    label: 'APTV-PA,  Aptiv PLC',
    name: 'Aptiv PLC',
    symbol: 'APTV-PA',
  },
  {
    value: 'ARAO',
    label: 'ARAO,  AuraSource, Inc.',
    name: 'AuraSource, Inc.',
    symbol: 'ARAO',
  },
  {
    value: 'AREB',
    label: 'AREB,  American Rebel Holdings, Inc.',
    name: 'American Rebel Holdings, Inc.',
    symbol: 'AREB',
  },
  {
    value: 'ARGO-PA',
    label: 'ARGO-PA,  Argo Group International Holdings, Ltd.',
    name: 'Argo Group International Holdings, Ltd.',
    symbol: 'ARGO-PA',
  },
  {
    value: 'ARGQ',
    label: 'ARGQ,  Argentum 47, Inc.',
    name: 'Argentum 47, Inc.',
    symbol: 'ARGQ',
  },
  {
    value: 'ARR-PC',
    label: 'ARR-PC,  ARMOUR Residential REIT, Inc.',
    name: 'ARMOUR Residential REIT, Inc.',
    symbol: 'ARR-PC',
  },
  {
    value: 'ARTH',
    label: 'ARTH,  Arch Therapeutics, Inc.',
    name: 'Arch Therapeutics, Inc.',
    symbol: 'ARTH',
  },
  {
    value: 'ASALCBR.NS',
    label: 'ASALCBR.NS,  Associated Alcohols & Breweries Limited',
    name: 'Associated Alcohols & Breweries Limited',
    symbol: 'ASALCBR.NS',
  },
  {
    value: 'ASCK',
    label: 'ASCK,  Auscrete Corporation',
    name: 'Auscrete Corporation',
    symbol: 'ASCK',
  },
  {
    value: 'ASDN',
    label: 'ASDN,  Astro Aerospace Ltd.',
    name: 'Astro Aerospace Ltd.',
    symbol: 'ASDN',
  },
  {
    value: 'ASFT',
    label: 'ASFT,  AppSoft Technologies, Inc.',
    name: 'AppSoft Technologies, Inc.',
    symbol: 'ASFT',
  },
  {
    value: 'ASNB',
    label: 'ASNB,  EKIMAS Corporation',
    name: 'EKIMAS Corporation',
    symbol: 'ASNB',
  },
  {
    value: 'ASTI',
    label: 'ASTI,  Ascent Solar Technologies, Inc.',
    name: 'Ascent Solar Technologies, Inc.',
    symbol: 'ASTI',
  },
  {
    value: 'AT1.DE',
    label: 'AT1.DE,  Aroundtown SA',
    name: 'Aroundtown SA',
    symbol: 'AT1.DE',
  },
  {
    value: 'ATAO',
    label: 'ATAO,  Altair International Corp.',
    name: 'Altair International Corp.',
    symbol: 'ATAO',
  },
  {
    value: 'ATCO-PI',
    label: 'ATCO-PI,  Atlas Corp.',
    name: 'Atlas Corp.',
    symbol: 'ATCO-PI',
  },
  {
    value: 'ATDS',
    label: 'ATDS,  Data443 Risk Mitigation, Inc.',
    name: 'Data443 Risk Mitigation, Inc.',
    symbol: 'ATDS',
  },
  {
    value: 'ATH-PC',
    label: 'ATH-PC,  Athene Holding Ltd.',
    name: 'Athene Holding Ltd.',
    symbol: 'ATH-PC',
  },
  {
    value: 'ATMS',
    label: 'ATMS,  Artemis Therapeutics Inc.',
    name: 'Artemis Therapeutics Inc.',
    symbol: 'ATMS',
  },
  {
    value: 'ATRX',
    label: 'ATRX,  Adhera Therapeutics, Inc.',
    name: 'Adhera Therapeutics, Inc.',
    symbol: 'ATRX',
  },
  {
    value: 'ATXG',
    label: 'ATXG,  Addentax Group Corp.',
    name: 'Addentax Group Corp.',
    symbol: 'ATXG',
  },
  {
    value: 'AUSI',
    label: 'AUSI,  Aura Systems, Inc.',
    name: 'Aura Systems, Inc.',
    symbol: 'AUSI',
  },
  {
    value: 'AVHHL',
    label: 'AVHHL,  AVITA Medical, Inc.',
    name: 'AVITA Medical, Inc.',
    symbol: 'AVHHL',
  },
  {
    value: 'AVLNF',
    label: 'AVLNF,  Avalon Advanced Materials Inc.',
    name: 'Avalon Advanced Materials Inc.',
    symbol: 'AVLNF',
  },
  {
    value: 'AVNI',
    label: 'AVNI,  Arvana Inc.',
    name: 'Arvana Inc.',
    symbol: 'AVNI',
  },
  {
    value: 'AVOI',
    label: 'AVOI,  Advanced Voice Recognition Systems, Inc.',
    name: 'Advanced Voice Recognition Systems, Inc.',
    symbol: 'AVOI',
  },
  {
    value: 'AXIM',
    label: 'AXIM,  AXIM Biotechnologies, Inc.',
    name: 'AXIM Biotechnologies, Inc.',
    symbol: 'AXIM',
  },
  {
    value: 'AXS-PE',
    label: 'AXS-PE,  AXIS Capital Holdings Limited',
    name: 'AXIS Capital Holdings Limited',
    symbol: 'AXS-PE',
  },
  {
    value: 'BABB',
    label: 'BABB,  BAB, Inc.',
    name: 'BAB, Inc.',
    symbol: 'BABB',
  },
  {
    value: 'BANT',
    label: 'BANT,  Bantec, Inc.',
    name: 'Bantec, Inc.',
    symbol: 'BANT',
  },
  {
    value: 'BASA',
    label: 'BASA,  Basanite, Inc.',
    name: 'Basanite, Inc.',
    symbol: 'BASA',
  },
  {
    value: 'BASS.PA',
    label: 'BASS.PA,  BASSAC Société anonyme',
    name: 'BASSAC Société anonyme',
    symbol: 'BASS.PA',
  },
  {
    value: 'BBXIB',
    label: 'BBXIB,  BBX Capital, Inc.',
    name: 'BBX Capital, Inc.',
    symbol: 'BBXIB',
  },
  {
    value: 'BDCO',
    label: 'BDCO,  Blue Dolphin Energy Company',
    name: 'Blue Dolphin Energy Company',
    symbol: 'BDCO',
  },
  {
    value: 'BDGY',
    label: 'BDGY,  Bridgeway National Corp.',
    name: 'Bridgeway National Corp.',
    symbol: 'BDGY',
  },
  {
    value: 'BDPT',
    label: 'BDPT,  BioAdaptives, Inc.',
    name: 'BioAdaptives, Inc.',
    symbol: 'BDPT',
  },
  {
    value: 'BEBE',
    label: 'BEBE,  bebe stores, inc.',
    name: 'bebe stores, inc.',
    symbol: 'BEBE',
  },
  {
    value: 'BEGI',
    label: 'BEGI,  BlackStar Enterprise Group, Inc.',
    name: 'BlackStar Enterprise Group, Inc.',
    symbol: 'BEGI',
  },
  {
    value: 'BEP-PA',
    label: 'BEP-PA,  Brookfield Renewable Partners L.P.',
    name: 'Brookfield Renewable Partners L.P.',
    symbol: 'BEP-PA',
  },
  {
    value: 'BFNH',
    label: 'BFNH,  BioForce Nanosciences Holdings, Inc.',
    name: 'BioForce Nanosciences Holdings, Inc.',
    symbol: 'BFNH',
  },
  {
    value: 'BFS-PE',
    label: 'BFS-PE,  Saul Centers, Inc.',
    name: 'Saul Centers, Inc.',
    symbol: 'BFS-PE',
  },
  {
    value: 'BHLL',
    label: 'BHLL,  Bunker Hill Mining Corp.',
    name: 'Bunker Hill Mining Corp.',
    symbol: 'BHLL',
  },
  {
    value: 'BHR-PD',
    label: 'BHR-PD,  Braemar Hotels & Resorts Inc.',
    name: 'Braemar Hotels & Resorts Inc.',
    symbol: 'BHR-PD',
  },
  {
    value: 'BICX',
    label: 'BICX,  BioCorRx Inc.',
    name: 'BioCorRx Inc.',
    symbol: 'BICX',
  },
  {
    value: 'BIIO',
    label: 'BIIO,  Bionovate Technologies Corp',
    name: 'Bionovate Technologies Corp',
    symbol: 'BIIO',
  },
  {
    value: 'BIO3.DE',
    label: 'BIO3.DE,  Biotest Aktiengesellschaft',
    name: 'Biotest Aktiengesellschaft',
    symbol: 'BIO3.DE',
  },
  {
    value: 'BIP-PA',
    label: 'BIP-PA,  Brookfield Infrastructure Partners L.P.',
    name: 'Brookfield Infrastructure Partners L.P.',
    symbol: 'BIP-PA',
  },
  {
    value: 'BIXT',
    label: 'BIXT,  Bioxytran, Inc.',
    name: 'Bioxytran, Inc.',
    symbol: 'BIXT',
  },
  {
    value: 'BKRP',
    label: 'BKRP,  Black Rock Petroleum Company',
    name: 'Black Rock Petroleum Company',
    symbol: 'BKRP',
  },
  {
    value: 'BKUH',
    label: 'BKUH,  Bakhu Holdings, Corp.',
    name: 'Bakhu Holdings, Corp.',
    symbol: 'BKUH',
  },
  {
    value: 'BLBX',
    label: 'BLBX,  Blackboxstocks Inc.',
    name: 'Blackboxstocks Inc.',
    symbol: 'BLBX',
  },
  {
    value: 'BLGI',
    label: 'BLGI,  BLGI, Inc.',
    name: 'BLGI, Inc.',
    symbol: 'BLGI',
  },
  {
    value: 'BLGO',
    label: 'BLGO,  BioLargo, Inc.',
    name: 'BioLargo, Inc.',
    symbol: 'BLGO',
  },
  {
    value: 'BLIS',
    label: 'BLIS,  Treasure & Shipwreck Recovery, Inc.',
    name: 'Treasure & Shipwreck Recovery, Inc.',
    symbol: 'BLIS',
  },
  {
    value: 'BLNC',
    label: 'BLNC,  Balance Labs, Inc.',
    name: 'Balance Labs, Inc.',
    symbol: 'BLNC',
  },
  {
    value: 'BLPG',
    label: 'BLPG,  Blue Line Protection Group, Inc.',
    name: 'Blue Line Protection Group, Inc.',
    symbol: 'BLPG',
  },
  {
    value: 'BLXX',
    label: 'BLXX,  Blox, Inc.',
    name: 'Blox, Inc.',
    symbol: 'BLXX',
  },
  {
    value: 'BLYQ',
    label: 'BLYQ,  Bally, Corp.',
    name: 'Bally, Corp.',
    symbol: 'BLYQ',
  },
  {
    value: 'BMMJ',
    label: 'BMMJ,  Body and Mind Inc.',
    name: 'Body and Mind Inc.',
    symbol: 'BMMJ',
  },
  {
    value: 'BMNM',
    label: 'BMNM,  Bimini Capital Management, Inc.',
    name: 'Bimini Capital Management, Inc.',
    symbol: 'BMNM',
  },
  {
    value: 'BMTM',
    label: 'BMTM,  Bright Mountain Media, Inc.',
    name: 'Bright Mountain Media, Inc.',
    symbol: 'BMTM',
  },
  {
    value: 'BNET',
    label: 'BNET,  Bion Environmental Technologies, Inc.',
    name: 'Bion Environmental Technologies, Inc.',
    symbol: 'BNET',
  },
  {
    value: 'BOAC-UN',
    label: 'BOAC-UN,  Bluescape Opportunities Acquisition Corp.',
    name: 'Bluescape Opportunities Acquisition Corp.',
    symbol: 'BOAC-UN',
  },
  {
    value: 'BOMH',
    label: 'BOMH,  Boomer Holdings Inc.',
    name: 'Boomer Holdings Inc.',
    symbol: 'BOMH',
  },
  {
    value: 'BOXS',
    label: 'BOXS,  BoxScore Brands, Inc.',
    name: 'BoxScore Brands, Inc.',
    symbol: 'BOXS',
  },
  {
    value: 'BPE5.DE',
    label: 'BPE5.DE,  BP p.l.c.',
    name: 'BP p.l.c.',
    symbol: 'BPE5.DE',
  },
  {
    value: 'BQST',
    label: 'BQST,  BioQuest Corp.',
    name: 'BioQuest Corp.',
    symbol: 'BQST',
  },
  {
    value: 'BRFH',
    label: 'BRFH,  Barfresh Food Group, Inc.',
    name: 'Barfresh Food Group, Inc.',
    symbol: 'BRFH',
  },
  {
    value: 'BRGO',
    label: 'BRGO,  Bergio International, Inc.',
    name: 'Bergio International, Inc.',
    symbol: 'BRGO',
  },
  {
    value: 'BRSF',
    label: 'BRSF,  Brain Scientific Inc.',
    name: 'Brain Scientific Inc.',
    symbol: 'BRSF',
  },
  {
    value: 'BRST',
    label: 'BRST,  Broad Street Realty, Inc.',
    name: 'Broad Street Realty, Inc.',
    symbol: 'BRST',
  },
  {
    value: 'BRVO',
    label: 'BRVO,  Bravo Multinational Incorporated',
    name: 'Bravo Multinational Incorporated',
    symbol: 'BRVO',
  },
  {
    value: 'BSFC',
    label: 'BSFC,  Blue Star Foods Corp.',
    name: 'Blue Star Foods Corp.',
    symbol: 'BSFC',
  },
  {
    value: 'BSP.DE',
    label: 'BSP.DE,  BAE Systems plc',
    name: 'BAE Systems plc',
    symbol: 'BSP.DE',
  },
  {
    value: 'BSPK',
    label: 'BSPK,  Bespoke Extracts, Inc.',
    name: 'Bespoke Extracts, Inc.',
    symbol: 'BSPK',
  },
  {
    value: 'BSTG',
    label: 'BSTG,  Biostage, Inc.',
    name: 'Biostage, Inc.',
    symbol: 'BSTG',
  },
  {
    value: 'BTCS',
    label: 'BTCS,  BTCS Inc.',
    name: 'BTCS Inc.',
    symbol: 'BTCS',
  },
  {
    value: 'BTCY',
    label: 'BTCY,  Biotricity, Inc.',
    name: 'Biotricity, Inc.',
    symbol: 'BTCY',
  },
  {
    value: 'BTDG',
    label: 'BTDG,  B2Digital, Incorporated',
    name: 'B2Digital, Incorporated',
    symbol: 'BTDG',
  },
  {
    value: 'BTIM',
    label: 'BTIM,  Boatim Inc.',
    name: 'Boatim Inc.',
    symbol: 'BTIM',
  },
  {
    value: 'BTTR',
    label: 'BTTR,  Better Choice Company Inc.',
    name: 'Better Choice Company Inc.',
    symbol: 'BTTR',
  },
  {
    value: 'BUDZ',
    label: 'BUDZ,  WEED, Inc.',
    name: 'WEED, Inc.',
    symbol: 'BUDZ',
  },
  {
    value: 'BUKS',
    label: 'BUKS,  Butler National Corporation',
    name: 'Butler National Corporation',
    symbol: 'BUKS',
  },
  {
    value: 'BWMG',
    label: "BWMG,  Brownie's Marine Group, Inc.",
    name: "Brownie's Marine Group, Inc.",
    symbol: 'BWMG',
  },
  {
    value: 'BWMY',
    label: 'BWMY,  BorrowMoney.com, Inc.',
    name: 'BorrowMoney.com, Inc.',
    symbol: 'BWMY',
  },
  {
    value: 'BYOC',
    label: 'BYOC,  Beyond Commerce, Inc.',
    name: 'Beyond Commerce, Inc.',
    symbol: 'BYOC',
  },
  {
    value: 'BYRN',
    label: 'BYRN,  Byrna Technologies Inc.',
    name: 'Byrna Technologies Inc.',
    symbol: 'BYRN',
  },
  {
    value: 'BZRD',
    label: 'BZRD,  Blubuzzard, Inc.',
    name: 'Blubuzzard, Inc.',
    symbol: 'BZRD',
  },
  {
    value: 'BZYR',
    label: 'BZYR,  Burzynski Research Institute, Inc.',
    name: 'Burzynski Research Institute, Inc.',
    symbol: 'BZYR',
  },
  {
    value: 'CANB',
    label: 'CANB,  Can B Corp.',
    name: 'Can B Corp.',
    symbol: 'CANB',
  },
  {
    value: 'CANN',
    label: 'CANN,  Trees Corporation',
    name: 'Trees Corporation',
    symbol: 'CANN',
  },
  {
    value: 'CAPC',
    label: 'CAPC,  Capstone Companies, Inc.',
    name: 'Capstone Companies, Inc.',
    symbol: 'CAPC',
  },
  {
    value: 'CBDS',
    label: 'CBDS,  Cannabis Sativa, Inc.',
    name: 'Cannabis Sativa, Inc.',
    symbol: 'CBDS',
  },
  {
    value: 'CBDY',
    label: 'CBDY,  Target Group Inc.',
    name: 'Target Group Inc.',
    symbol: 'CBDY',
  },
  {
    value: 'CBKM',
    label: 'CBKM,  Consumers Bancorp, Inc.',
    name: 'Consumers Bancorp, Inc.',
    symbol: 'CBKM',
  },
  {
    value: 'CBNT',
    label: 'CBNT,  C-Bond Systems, Inc.',
    name: 'C-Bond Systems, Inc.',
    symbol: 'CBNT',
  },
  {
    value: 'CCEL',
    label: 'CCEL,  Cryo-Cell International, Inc.',
    name: 'Cryo-Cell International, Inc.',
    symbol: 'CCEL',
  },
  {
    value: 'CCNEP',
    label: 'CCNEP,  CNB Financial Corporation',
    name: 'CNB Financial Corporation',
    symbol: 'CCNEP',
  },
  {
    value: 'CCTC',
    label: 'CCTC,  Clean Coal Technologies, Inc.',
    name: 'Clean Coal Technologies, Inc.',
    symbol: 'CCTC',
  },
  {
    value: 'CDIX',
    label: 'CDIX,  Cardiff Lexington Corp.',
    name: 'Cardiff Lexington Corp.',
    symbol: 'CDIX',
  },
  {
    value: 'CDJM',
    label: 'CDJM,  Carnegie Development, Inc.',
    name: 'Carnegie Development, Inc.',
    symbol: 'CDJM',
  },
  {
    value: 'CDR-PC',
    label: 'CDR-PC,  Cedar Realty Trust, Inc.',
    name: 'Cedar Realty Trust, Inc.',
    symbol: 'CDR-PC',
  },
  {
    value: 'CELZ',
    label: 'CELZ,  Creative Medical Technology Holdings, Inc.',
    name: 'Creative Medical Technology Holdings, Inc.',
    symbol: 'CELZ',
  },
  {
    value: 'CEQP-P',
    label: 'CEQP-P,  Crestwood Equity Partners LP',
    name: 'Crestwood Equity Partners LP',
    symbol: 'CEQP-P',
  },
  {
    value: 'CERE',
    label: 'CERE,  Cerevel Therapeutics Holdings, Inc.',
    name: 'Cerevel Therapeutics Holdings, Inc.',
    symbol: 'CERE',
  },
  {
    value: 'CETY',
    label: 'CETY,  Clean Energy Technologies, Inc.',
    name: 'Clean Energy Technologies, Inc.',
    symbol: 'CETY',
  },
  {
    value: 'CFG-PE',
    label: 'CFG-PE,  Citizens Financial Group, Inc.',
    name: 'Citizens Financial Group, Inc.',
    symbol: 'CFG-PE',
  },
  {
    value: 'CFNB',
    label: 'CFNB,  California First Leasing Corporation',
    name: 'California First Leasing Corporation',
    symbol: 'CFNB',
  },
  {
    value: 'CGLO',
    label: 'CGLO,  Coro Global Inc.',
    name: 'Coro Global Inc.',
    symbol: 'CGLO',
  },
  {
    value: 'CHCR',
    label: 'CHCR,  Advanzeon Solutions, Inc.',
    name: 'Advanzeon Solutions, Inc.',
    symbol: 'CHCR',
  },
  {
    value: 'CHGI',
    label: 'CHGI,  China Carbon Graphite Group, Inc.',
    name: 'China Carbon Graphite Group, Inc.',
    symbol: 'CHGI',
  },
  {
    value: 'CHHE',
    label: 'CHHE,  China Health Industries Holdings, Inc.',
    name: 'China Health Industries Holdings, Inc.',
    symbol: 'CHHE',
  },
  {
    value: 'CHND',
    label: 'CHND,  China Media Inc.',
    name: 'China Media Inc.',
    symbol: 'CHND',
  },
  {
    value: 'CHUC',
    label: "CHUC,  Charlie's Holdings, Inc.",
    name: "Charlie's Holdings, Inc.",
    symbol: 'CHUC',
  },
  {
    value: 'CIIT',
    label: 'CIIT,  Tianci International, Inc.',
    name: 'Tianci International, Inc.',
    symbol: 'CIIT',
  },
  {
    value: 'CIRX',
    label: 'CIRX,  CirTran Corporation',
    name: 'CirTran Corporation',
    symbol: 'CIRX',
  },
  {
    value: 'CISO',
    label: 'CISO,  Cerberus Cyber Sentinel Corporation',
    name: 'Cerberus Cyber Sentinel Corporation',
    symbol: 'CISO',
  },
  {
    value: 'CIXX',
    label: 'CIXX,  CI Financial Corp.',
    name: 'CI Financial Corp.',
    symbol: 'CIXX',
  },
  {
    value: 'CLCS',
    label: 'CLCS,  Cell Source, Inc.',
    name: 'Cell Source, Inc.',
    symbol: 'CLCS',
  },
  {
    value: 'CLEV',
    label: 'CLEV,  Concrete Leveling Systems, Inc.',
    name: 'Concrete Leveling Systems, Inc.',
    symbol: 'CLEV',
  },
  {
    value: 'CLOQ',
    label: 'CLOQ,  Cyberloq Technologies, Inc.',
    name: 'Cyberloq Technologies, Inc.',
    symbol: 'CLOQ',
  },
  {
    value: 'CLOUD.OL',
    label: 'CLOUD.OL,  Cloudberry Clean Energy ASA',
    name: 'Cloudberry Clean Energy ASA',
    symbol: 'CLOUD.OL',
  },
  {
    value: 'CLOW',
    label: 'CLOW,  Cloudweb, Inc.',
    name: 'Cloudweb, Inc.',
    symbol: 'CLOW',
  },
  {
    value: 'CLRI',
    label: 'CLRI,  Cleartronic, Inc.',
    name: 'Cleartronic, Inc.',
    symbol: 'CLRI',
  },
  {
    value: 'CLSH',
    label: 'CLSH,  CLS Holdings USA, Inc.',
    name: 'CLS Holdings USA, Inc.',
    symbol: 'CLSH',
  },
  {
    value: 'CLTS',
    label: 'CLTS,  Earth Life Sciences Inc.',
    name: 'Earth Life Sciences Inc.',
    symbol: 'CLTS',
  },
  {
    value: 'CMGO',
    label: 'CMGO,  CMG Holdings Group, Inc.',
    name: 'CMG Holdings Group, Inc.',
    symbol: 'CMGO',
  },
  {
    value: 'CMRE-PE',
    label: 'CMRE-PE,  Costamare Inc.',
    name: 'Costamare Inc.',
    symbol: 'CMRE-PE',
  },
  {
    value: 'CMS-PB',
    label: 'CMS-PB,  Consumers Energy Company',
    name: 'Consumers Energy Company',
    symbol: 'CMS-PB',
  },
  {
    value: 'CMTV',
    label: 'CMTV,  Community Bancorp',
    name: 'Community Bancorp',
    symbol: 'CMTV',
  },
  {
    value: 'CMXC',
    label: 'CMXC,  Cell MedX Corp.',
    name: 'Cell MedX Corp.',
    symbol: 'CMXC',
  },
  {
    value: 'CNBX',
    label: 'CNBX,  CNBX Pharmaceuticals Inc.',
    name: 'CNBX Pharmaceuticals Inc.',
    symbol: 'CNBX',
  },
  {
    value: 'CNFN',
    label: 'CNFN,  CFN Enterprises Inc.',
    name: 'CFN Enterprises Inc.',
    symbol: 'CNFN',
  },
  {
    value: 'CNGT',
    label: 'CNGT,  Cannagistics, Inc.',
    name: 'Cannagistics, Inc.',
    symbol: 'CNGT',
  },
  {
    value: 'CNNC',
    label: 'CNNC,  Cannonau Corp.',
    name: 'Cannonau Corp.',
    symbol: 'CNNC',
  },
  {
    value: 'CODI-PC',
    label: 'CODI-PC,  Compass Diversified',
    name: 'Compass Diversified',
    symbol: 'CODI-PC',
  },
  {
    value: 'COF-PI',
    label: 'COF-PI,  Capital One Financial Corporation',
    name: 'Capital One Financial Corporation',
    symbol: 'COF-PI',
  },
  {
    value: 'COSM',
    label: 'COSM,  Cosmos Holdings Inc.',
    name: 'Cosmos Holdings Inc.',
    symbol: 'COSM',
  },
  {
    value: 'CPMD',
    label: 'CPMD,  CannaPharmaRX, Inc.',
    name: 'CannaPharmaRX, Inc.',
    symbol: 'CPMD',
  },
  {
    value: 'CPTP',
    label: 'CPTP,  Capital Properties, Inc.',
    name: 'Capital Properties, Inc.',
    symbol: 'CPTP',
  },
  {
    value: 'CPWR',
    label: 'CPWR,  Ocean Thermal Energy Corporation',
    name: 'Ocean Thermal Energy Corporation',
    symbol: 'CPWR',
  },
  {
    value: 'CRAWA',
    label: 'CRAWA,  Crawford United Corporation',
    name: 'Crawford United Corporation',
    symbol: 'CRAWA',
  },
  {
    value: 'CRCUF',
    label: 'CRCUF,  Canagold Resources Ltd.',
    name: 'Canagold Resources Ltd.',
    symbol: 'CRCUF',
  },
  {
    value: 'CRCW',
    label: 'CRCW,  The Crypto Company',
    name: 'The Crypto Company',
    symbol: 'CRCW',
  },
  {
    value: 'CRMZ',
    label: 'CRMZ,  CreditRiskMonitor.com, Inc.',
    name: 'CreditRiskMonitor.com, Inc.',
    symbol: 'CRMZ',
  },
  {
    value: 'CRTG',
    label: 'CRTG,  The Coretec Group Inc.',
    name: 'The Coretec Group Inc.',
    symbol: 'CRTG',
  },
  {
    value: 'CRVW',
    label: 'CRVW,  CareView Communications, Inc.',
    name: 'CareView Communications, Inc.',
    symbol: 'CRVW',
  },
  {
    value: 'CRWE',
    label: 'CRWE,  Crown Equity Holdings Inc.',
    name: 'Crown Equity Holdings Inc.',
    symbol: 'CRWE',
  },
  {
    value: 'CRYO',
    label: 'CRYO,  American CryoStem Corporation',
    name: 'American CryoStem Corporation',
    symbol: 'CRYO',
  },
  {
    value: 'CSBB',
    label: 'CSBB,  CSB Bancorp, Inc.',
    name: 'CSB Bancorp, Inc.',
    symbol: 'CSBB',
  },
  {
    value: 'CSUI',
    label: 'CSUI,  Cannabis Suisse Corp.',
    name: 'Cannabis Suisse Corp.',
    symbol: 'CSUI',
  },
  {
    value: 'CTA-PB',
    label: 'CTA-PB,  E. I. du Pont de Nemours and Company',
    name: 'E. I. du Pont de Nemours and Company',
    symbol: 'CTA-PB',
  },
  {
    value: 'CTGO',
    label: 'CTGO,  Contango Ore, Inc.',
    name: 'Contango Ore, Inc.',
    symbol: 'CTGO',
  },
  {
    value: 'CUBI-PF',
    label: 'CUBI-PF,  Customers Bancorp, Inc.',
    name: 'Customers Bancorp, Inc.',
    symbol: 'CUBI-PF',
  },
  {
    value: 'CUEN',
    label: 'CUEN,  Cuentas Inc.',
    name: 'Cuentas Inc.',
    symbol: 'CUEN',
  },
  {
    value: 'CURLF',
    label: 'CURLF,  Curaleaf Holdings, Inc.',
    name: 'Curaleaf Holdings, Inc.',
    symbol: 'CURLF',
  },
  {
    value: 'CVAT',
    label: 'CVAT,  Cavitation Technologies, Inc.',
    name: 'Cavitation Technologies, Inc.',
    symbol: 'CVAT',
  },
  {
    value: 'LFMD',
    label: 'LFMD,  LifeMD, Inc.',
    name: 'LifeMD, Inc.',
    symbol: 'LFMD',
  },
  {
    value: 'CVSI',
    label: 'CVSI,  CV Sciences, Inc.',
    name: 'CV Sciences, Inc.',
    symbol: 'CVSI',
  },
  {
    value: 'CWGL',
    label: 'CWGL,  Crimson Wine Group, Ltd.',
    name: 'Crimson Wine Group, Ltd.',
    symbol: 'CWGL',
  },
  {
    value: 'CXKJ',
    label: 'CXKJ,  CX Network Group, Inc.',
    name: 'CX Network Group, Inc.',
    symbol: 'CXKJ',
  },
  {
    value: 'CXXIF',
    label: 'CXXIF,  C21 Investments Inc.',
    name: 'C21 Investments Inc.',
    symbol: 'CXXIF',
  },
  {
    value: 'CYDY',
    label: 'CYDY,  CytoDyn Inc.',
    name: 'CytoDyn Inc.',
    symbol: 'CYDY',
  },
  {
    value: 'CZFS',
    label: 'CZFS,  Citizens Financial Services, Inc.',
    name: 'Citizens Financial Services, Inc.',
    symbol: 'CZFS',
  },
  {
    value: 'DBMM',
    label: 'DBMM,  Digital Brand Media & Marketing Group, Inc.',
    name: 'Digital Brand Media & Marketing Group, Inc.',
    symbol: 'DBMM',
  },
  {
    value: 'DBRM',
    label: 'DBRM,  Daybreak Oil and Gas, Inc.',
    name: 'Daybreak Oil and Gas, Inc.',
    symbol: 'DBRM',
  },
  {
    value: 'DCBO',
    label: 'DCBO,  Docebo Inc.',
    name: 'Docebo Inc.',
    symbol: 'DCBO',
  },
  {
    value: 'DCLT',
    label: 'DCLT,  Data Call Technologies, Inc.',
    name: 'Data Call Technologies, Inc.',
    symbol: 'DCLT',
  },
  {
    value: 'DFCO',
    label: 'DFCO,  Dalrada Financial Corporation',
    name: 'Dalrada Financial Corporation',
    symbol: 'DFCO',
  },
  {
    value: 'DGWR',
    label: 'DGWR,  Deep Green Waste & Recycling, Inc.',
    name: 'Deep Green Waste & Recycling, Inc.',
    symbol: 'DGWR',
  },
  {
    value: 'DHR-PB',
    label: 'DHR-PB,  Danaher Corporation',
    name: 'Danaher Corporation',
    symbol: 'DHR-PB',
  },
  {
    value: 'DIGP',
    label: 'DIGP,  Digipath, Inc.',
    name: 'Digipath, Inc.',
    symbol: 'DIGP',
  },
  {
    value: 'DLCR',
    label: 'DLCR,  Kibush Capital Corporation',
    name: 'Kibush Capital Corporation',
    symbol: 'DLCR',
  },
  {
    value: 'DLNG-PB',
    label: 'DLNG-PB,  Dynagas LNG Partners LP',
    name: 'Dynagas LNG Partners LP',
    symbol: 'DLNG-PB',
  },
  {
    value: 'DLOC',
    label: 'DLOC,  Digital Locations, Inc.',
    name: 'Digital Locations, Inc.',
    symbol: 'DLOC',
  },
  {
    value: 'DLR-PL',
    label: 'DLR-PL,  Digital Realty Trust, Inc.',
    name: 'Digital Realty Trust, Inc.',
    symbol: 'DLR-PL',
  },
  {
    value: 'DLTI',
    label: 'DLTI,  DLT Resolution Inc.',
    name: 'DLT Resolution Inc.',
    symbol: 'DLTI',
  },
  {
    value: 'DLYT',
    label: 'DLYT,  Dais Corporation',
    name: 'Dais Corporation',
    symbol: 'DLYT',
  },
  {
    value: 'DPDW',
    label: 'DPDW,  Koil Energy Solutions, Inc.',
    name: 'Koil Energy Solutions, Inc.',
    symbol: 'DPDW',
  },
  {
    value: 'DPLS',
    label: 'DPLS,  DarkPulse, Inc.',
    name: 'DarkPulse, Inc.',
    symbol: 'DPLS',
  },
  {
    value: 'DPWW',
    label: 'DPWW,  Diego Pellicer Worldwide, Inc.',
    name: 'Diego Pellicer Worldwide, Inc.',
    symbol: 'DPWW',
  },
  {
    value: 'DQWS',
    label: 'DQWS,  DSwiss, Inc.',
    name: 'DSwiss, Inc.',
    symbol: 'DQWS',
  },
  {
    value: 'DREM',
    label: 'DREM,  Dream Homes & Development Corporation',
    name: 'Dream Homes & Development Corporation',
    symbol: 'DREM',
  },
  {
    value: 'DRH-PA',
    label: 'DRH-PA,  DiamondRock Hospitality Company',
    name: 'DiamondRock Hospitality Company',
    symbol: 'DRH-PA',
  },
  {
    value: 'DSGT',
    label: 'DSGT,  DSG Global Inc.',
    name: 'DSG Global Inc.',
    symbol: 'DSGT',
  },
  {
    value: 'DSNY',
    label: 'DSNY,  Destiny Media Technologies Inc.',
    name: 'Destiny Media Technologies Inc.',
    symbol: 'DSNY',
  },
  {
    value: 'DTGI',
    label: 'DTGI,  Digerati Technologies, Inc.',
    name: 'Digerati Technologies, Inc.',
    symbol: 'DTGI',
  },
  {
    value: 'DTII',
    label: 'DTII,  Defense Technologies International Corp.',
    name: 'Defense Technologies International Corp.',
    symbol: 'DTII',
  },
  {
    value: 'DTLA-P',
    label: 'DTLA-P,  Brookfield DTLA Fund Office Trust Investor Inc.',
    name: 'Brookfield DTLA Fund Office Trust Investor Inc.',
    symbol: 'DTLA-P',
  },
  {
    value: 'DTST',
    label: 'DTST,  Data Storage Corporation',
    name: 'Data Storage Corporation',
    symbol: 'DTST',
  },
  {
    value: 'DUK-PA',
    label: 'DUK-PA,  Duke Energy Corporation',
    name: 'Duke Energy Corporation',
    symbol: 'DUK-PA',
  },
  {
    value: 'DUUO',
    label: 'DUUO,  Duo World, Inc.',
    name: 'Duo World, Inc.',
    symbol: 'DUUO',
  },
  {
    value: 'DYNR',
    label: 'DYNR,  DynaResource, Inc.',
    name: 'DynaResource, Inc.',
    symbol: 'DYNR',
  },
  {
    value: 'EACO',
    label: 'EACO,  EACO Corporation',
    name: 'EACO Corporation',
    symbol: 'EACO',
  },
  {
    value: 'EAWD',
    label: 'EAWD,  Energy and Water Development Corp.',
    name: 'Energy and Water Development Corp.',
    symbol: 'EAWD',
  },
  {
    value: 'ECIA',
    label: 'ECIA,  Encision Inc.',
    name: 'Encision Inc.',
    symbol: 'ECIA',
  },
  {
    value: 'EFC-PA',
    label: 'EFC-PA,  Ellington Financial Inc.',
    name: 'Ellington Financial Inc.',
    symbol: 'EFC-PA',
  },
  {
    value: 'EFSH',
    label: 'EFSH,  1847 Holdings LLC',
    name: '1847 Holdings LLC',
    symbol: 'EFSH',
  },
  {
    value: 'EFSI',
    label: 'EFSI,  Eagle Financial Services, Inc.',
    name: 'Eagle Financial Services, Inc.',
    symbol: 'EFSI',
  },
  {
    value: 'EG7.IR',
    label: 'EG7.IR,  FBD Holdings plc',
    name: 'FBD Holdings plc',
    symbol: 'EG7.IR',
  },
  {
    value: 'ELST',
    label: 'ELST,  Electronic Systems Technology, Inc.',
    name: 'Electronic Systems Technology, Inc.',
    symbol: 'ELST',
  },
  {
    value: 'ELTP',
    label: 'ELTP,  Elite Pharmaceuticals, Inc.',
    name: 'Elite Pharmaceuticals, Inc.',
    symbol: 'ELTP',
  },
  {
    value: 'ELVG',
    label: 'ELVG,  Elvictor Group, Inc.',
    name: 'Elvictor Group, Inc.',
    symbol: 'ELVG',
  },
  {
    value: 'EMYB',
    label: 'EMYB,  Embassy Bancorp, Inc.',
    name: 'Embassy Bancorp, Inc.',
    symbol: 'EMYB',
  },
  {
    value: 'ENBP',
    label: 'ENBP,  ENB Financial Corp',
    name: 'ENB Financial Corp',
    symbol: 'ENBP',
  },
  {
    value: 'ENDV',
    label: 'ENDV,  Endonovo Therapeutics, Inc.',
    name: 'Endonovo Therapeutics, Inc.',
    symbol: 'ENDV',
  },
  {
    value: 'ENRT',
    label: 'ENRT,  Enertopia Corp.',
    name: 'Enertopia Corp.',
    symbol: 'ENRT',
  },
  {
    value: 'ENTI',
    label: 'ENTI,  Encounter Development Technologies Inc.',
    name: 'Encounter Development Technologies Inc.',
    symbol: 'ENTI',
  },
  {
    value: 'ENZN',
    label: 'ENZN,  Enzon Pharmaceuticals, Inc.',
    name: 'Enzon Pharmaceuticals, Inc.',
    symbol: 'ENZN',
  },
  {
    value: 'EOSS',
    label: 'EOSS,  EOS, Inc.',
    name: 'EOS, Inc.',
    symbol: 'EOSS',
  },
  {
    value: 'EPGG',
    label: 'EPGG,  Empire Global Gaming, Inc.',
    name: 'Empire Global Gaming, Inc.',
    symbol: 'EPGG',
  },
  {
    value: 'EQC-PD',
    label: 'EQC-PD,  Equity Commonwealth',
    name: 'Equity Commonwealth',
    symbol: 'EQC-PD',
  },
  {
    value: 'EQH-PA',
    label: 'EQH-PA,  Equitable Holdings, Inc.',
    name: 'Equitable Holdings, Inc.',
    symbol: 'EQH-PA',
  },
  {
    value: 'EQUR',
    label: 'EQUR,  E-Qure Corp.',
    name: 'E-Qure Corp.',
    symbol: 'EQUR',
  },
  {
    value: 'ERKH',
    label: 'ERKH,  Eureka Homestead Bancorp, Inc.',
    name: 'Eureka Homestead Bancorp, Inc.',
    symbol: 'ERKH',
  },
  {
    value: 'ERLFF',
    label: 'ERLFF,  Entrée Resources Ltd.',
    name: 'Entrée Resources Ltd.',
    symbol: 'ERLFF',
  },
  {
    value: 'ESMC',
    label: 'ESMC,  Escalon Medical Corp.',
    name: 'Escalon Medical Corp.',
    symbol: 'ESMC',
  },
  {
    value: 'ESOA',
    label: 'ESOA,  Energy Services of America Corporation',
    name: 'Energy Services of America Corporation',
    symbol: 'ESOA',
  },
  {
    value: 'ETCK',
    label: 'ETCK,  EnerTeck Corporation',
    name: 'EnerTeck Corporation',
    symbol: 'ETCK',
  },
  {
    value: 'EVIO',
    label: 'EVIO,  EVIO, Inc.',
    name: 'EVIO, Inc.',
    symbol: 'EVIO',
  },
  {
    value: 'EVTN',
    label: 'EVTN,  Enviro Technologies U.S., Inc.',
    name: 'Enviro Technologies U.S., Inc.',
    symbol: 'EVTN',
  },
  {
    value: 'EWLL',
    label: 'EWLL,  eWellness Healthcare Corporation',
    name: 'eWellness Healthcare Corporation',
    symbol: 'EWLL',
  },
  {
    value: 'EWLU',
    label: 'EWLU,  Merion, Inc.',
    name: 'Merion, Inc.',
    symbol: 'EWLU',
  },
  {
    value: 'EXEO',
    label: 'EXEO,  Exeo Entertainment, Inc.',
    name: 'Exeo Entertainment, Inc.',
    symbol: 'EXEO',
  },
  {
    value: 'EZOO',
    label: 'EZOO,  Ezagoo Limited',
    name: 'Ezagoo Limited',
    symbol: 'EZOO',
  },
  {
    value: 'FALC',
    label: 'FALC,  FalconStor Software, Inc.',
    name: 'FalconStor Software, Inc.',
    symbol: 'FALC',
  },
  {
    value: 'FCCN',
    label: 'FCCN,  Spectral Capital Corporation',
    name: 'Spectral Capital Corporation',
    symbol: 'FCCN',
  },
  {
    value: 'FCIC',
    label: 'FCIC,  FCCC, Inc.',
    name: 'FCCC, Inc.',
    symbol: 'FCIC',
  },
  {
    value: 'FCUV',
    label: 'FCUV,  Focus Universal Inc.',
    name: 'Focus Universal Inc.',
    symbol: 'FCUV',
  },
  {
    value: 'FDBL',
    label: 'FDBL,  Friendable, Inc.',
    name: 'Friendable, Inc.',
    symbol: 'FDBL',
  },
  {
    value: 'FDCT',
    label: 'FDCT,  FDCTech, Inc.',
    name: 'FDCTech, Inc.',
    symbol: 'FDCT',
  },
  {
    value: 'FERL',
    label: 'FERL,  Fearless Films, Inc.',
    name: 'Fearless Films, Inc.',
    symbol: 'FERL',
  },
  {
    value: 'FFLO',
    label: 'FFLO,  Free Flow, Inc.',
    name: 'Free Flow, Inc.',
    symbol: 'FFLO',
  },
  {
    value: 'FFMGF',
    label: 'FFMGF,  First Mining Gold Corp.',
    name: 'First Mining Gold Corp.',
    symbol: 'FFMGF',
  },
  {
    value: 'FFNTF',
    label: 'FFNTF,  4Front Ventures Corp.',
    name: '4Front Ventures Corp.',
    symbol: 'FFNTF',
  },
  {
    value: 'FGPR',
    label: 'FGPR,  Ferrellgas Partners, L.P.',
    name: 'Ferrellgas Partners, L.P.',
    symbol: 'FGPR',
  },
  {
    value: 'FHN-PE',
    label: 'FHN-PE,  First Horizon Corporation',
    name: 'First Horizon Corporation',
    symbol: 'FHN-PE',
  },
  {
    value: 'FIFG',
    label: 'FIFG,  First Foods Group, Inc.',
    name: 'First Foods Group, Inc.',
    symbol: 'FIFG',
  },
  {
    value: 'FKWL',
    label: 'FKWL,  Franklin Wireless Corp.',
    name: 'Franklin Wireless Corp.',
    symbol: 'FKWL',
  },
  {
    value: 'FKYS',
    label: 'FKYS,  First Keystone Corporation',
    name: 'First Keystone Corporation',
    symbol: 'FKYS',
  },
  {
    value: 'FLCX',
    label: 'FLCX,  flooidCX Corp.',
    name: 'flooidCX Corp.',
    symbol: 'FLCX',
  },
  {
    value: 'FLES',
    label: 'FLES,  Auto Parts 4Less Group, Inc.',
    name: 'Auto Parts 4Less Group, Inc.',
    symbol: 'FLES',
  },
  {
    value: 'FLSS',
    label: 'FLSS,  Forbes Energy Services Ltd.',
    name: 'Forbes Energy Services Ltd.',
    symbol: 'FLSS',
  },
  {
    value: 'FLXT',
    label: 'FLXT,  Flexpoint Sensor Systems, Inc.',
    name: 'Flexpoint Sensor Systems, Inc.',
    symbol: 'FLXT',
  },
  {
    value: 'FMBM',
    label: 'FMBM,  F & M Bank Corp.',
    name: 'F & M Bank Corp.',
    symbol: 'FMBM',
  },
  {
    value: 'FMCB',
    label: 'FMCB,  Farmers & Merchants Bancorp',
    name: 'Farmers & Merchants Bancorp',
    symbol: 'FMCB',
  },
  {
    value: 'FMCC',
    label: 'FMCC,  Federal Home Loan Mortgage Corporation',
    name: 'Federal Home Loan Mortgage Corporation',
    symbol: 'FMCC',
  },
  {
    value: 'FMFG',
    label: 'FMFG,  Farmers and Merchants Bancshares, Inc.',
    name: 'Farmers and Merchants Bancshares, Inc.',
    symbol: 'FMFG',
  },
  {
    value: 'FNGR',
    label: 'FNGR,  FingerMotion, Inc.',
    name: 'FingerMotion, Inc.',
    symbol: 'FNGR',
  },
  {
    value: 'FNMAP',
    label: 'FNMAP,  Federal National Mortgage Association',
    name: 'Federal National Mortgage Association',
    symbol: 'FNMAP',
  },
  {
    value: 'FNRN',
    label: 'FNRN,  First Northern Community Bancorp',
    name: 'First Northern Community Bancorp',
    symbol: 'FNRN',
  },
  {
    value: 'FREJN',
    label: 'FREJN,  Federal Home Loan Mortgage Corporation',
    name: 'Federal Home Loan Mortgage Corporation',
    symbol: 'FREJN',
  },
  {
    value: 'FREVS',
    label: 'FREVS,  First Real Estate Investment Trust of New Jersey, Inc.',
    name: 'First Real Estate Investment Trust of New Jersey, Inc.',
    symbol: 'FREVS',
  },
  {
    value: 'FRFR',
    label: 'FRFR,  Fritzy Tech Inc.',
    name: 'Fritzy Tech Inc.',
    symbol: 'FRFR',
  },
  {
    value: 'FRT-PC',
    label: 'FRT-PC,  Federal Realty Investment Trust',
    name: 'Federal Realty Investment Trust',
    symbol: 'FRT-PC',
  },
  {
    value: 'FRZT',
    label: 'FRZT,  Freeze Tag, Inc.',
    name: 'Freeze Tag, Inc.',
    symbol: 'FRZT',
  },
  {
    value: 'FSSN',
    label: 'FSSN,  Fision Corporation',
    name: 'Fision Corporation',
    symbol: 'FSSN',
  },
  {
    value: 'FSTJ',
    label: 'FSTJ,  First America Resources Corporation',
    name: 'First America Resources Corporation',
    symbol: 'FSTJ',
  },
  {
    value: 'FTLF',
    label: 'FTLF,  FitLife Brands, Inc.',
    name: 'FitLife Brands, Inc.',
    symbol: 'FTLF',
  },
  {
    value: 'FULO',
    label: 'FULO,  FullNet Communications, Inc.',
    name: 'FullNet Communications, Inc.',
    symbol: 'FULO',
  },
  {
    value: 'FVTI',
    label: 'FVTI,  Fortune Valley Treasures, Inc.',
    name: 'Fortune Valley Treasures, Inc.',
    symbol: 'FVTI',
  },
  {
    value: 'FZMD',
    label: 'FZMD,  Fuse Medical, Inc.',
    name: 'Fuse Medical, Inc.',
    symbol: 'FZMD',
  },
  {
    value: 'GAHC',
    label: 'GAHC,  Global Arena Holding Inc.',
    name: 'Global Arena Holding Inc.',
    symbol: 'GAHC',
  },
  {
    value: 'GAXY',
    label: 'GAXY,  Galaxy Next Generation, Inc.',
    name: 'Galaxy Next Generation, Inc.',
    symbol: 'GAXY',
  },
  {
    value: 'GBBT',
    label: 'GBBT,  Global Boatworks Holdings, Inc.',
    name: 'Global Boatworks Holdings, Inc.',
    symbol: 'GBBT',
  },
  {
    value: 'GBCS',
    label: 'GBCS,  Selectis Health, Inc.',
    name: 'Selectis Health, Inc.',
    symbol: 'GBCS',
  },
  {
    value: 'GBIM',
    label: 'GBIM,  GlobeImmune, Inc.',
    name: 'GlobeImmune, Inc.',
    symbol: 'GBIM',
  },
  {
    value: 'GBLX',
    label: 'GBLX,  GB Sciences, Inc.',
    name: 'GB Sciences, Inc.',
    symbol: 'GBLX',
  },
  {
    value: 'GBNY',
    label: 'GBNY,  Generations Bancorp NY, Inc.',
    name: 'Generations Bancorp NY, Inc.',
    symbol: 'GBNY',
  },
  {
    value: 'GBTC',
    label: 'GBTC,  Grayscale Bitcoin Trust (BTC)',
    name: 'Grayscale Bitcoin Trust (BTC)',
    symbol: 'GBTC',
  },
  {
    value: 'GCAN',
    label: 'GCAN,  The Greater Cannabis Company, Inc.',
    name: 'The Greater Cannabis Company, Inc.',
    symbol: 'GCAN',
  },
  {
    value: 'GDSI',
    label: 'GDSI,  Global Digital Solutions, Inc.',
    name: 'Global Digital Solutions, Inc.',
    symbol: 'GDSI',
  },
  {
    value: 'GENH',
    label: 'GENH,  Generation Hemp, Inc.',
    name: 'Generation Hemp, Inc.',
    symbol: 'GENH',
  },
  {
    value: 'GFASY',
    label: 'GFASY,  Gafisa S.A.',
    name: 'Gafisa S.A.',
    symbol: 'GFASY',
  },
  {
    value: 'GFMH',
    label: 'GFMH,  Goliath Film and Media Holdings',
    name: 'Goliath Film and Media Holdings',
    symbol: 'GFMH',
  },
  {
    value: 'GHAV',
    label: 'GHAV,  Grand Havana, Inc.',
    name: 'Grand Havana, Inc.',
    symbol: 'GHAV',
  },
  {
    value: 'GHMP',
    label: 'GHMP,  Good Hemp, Inc.',
    name: 'Good Hemp, Inc.',
    symbol: 'GHMP',
  },
  {
    value: 'GIGA',
    label: 'GIGA,  Giga-tronics Incorporated',
    name: 'Giga-tronics Incorporated',
    symbol: 'GIGA',
  },
  {
    value: 'GLAE',
    label: 'GLAE,  GlassBridge Enterprises, Inc.',
    name: 'GlassBridge Enterprises, Inc.',
    symbol: 'GLAE',
  },
  {
    value: 'GLGI',
    label: 'GLGI,  Greystone Logistics, Inc.',
    name: 'Greystone Logistics, Inc.',
    symbol: 'GLGI',
  },
  {
    value: 'GLOG-PA',
    label: 'GLOG-PA,  GasLog Ltd.',
    name: 'GasLog Ltd.',
    symbol: 'GLOG-PA',
  },
  {
    value: 'GLOP-PB',
    label: 'GLOP-PB,  GasLog Partners LP',
    name: 'GasLog Partners LP',
    symbol: 'GLOP-PB',
  },
  {
    value: 'GLOP-PC',
    label: 'GLOP-PC,  GasLog Partners LP',
    name: 'GasLog Partners LP',
    symbol: 'GLOP-PC',
  },
  {
    value: 'GLRI',
    label: 'GLRI,  Glori Energy Inc.',
    name: 'Glori Energy Inc.',
    symbol: 'GLRI',
  },
  {
    value: 'GLXZ',
    label: 'GLXZ,  Galaxy Gaming, Inc.',
    name: 'Galaxy Gaming, Inc.',
    symbol: 'GLXZ',
  },
  {
    value: 'GMER',
    label: 'GMER,  Good Gaming, Inc.',
    name: 'Good Gaming, Inc.',
    symbol: 'GMER',
  },
  {
    value: 'GMGI',
    label: 'GMGI,  Golden Matrix Group, Inc.',
    name: 'Golden Matrix Group, Inc.',
    symbol: 'GMGI',
  },
  {
    value: 'GMPW',
    label: 'GMPW,  GiveMePower Corporation',
    name: 'GiveMePower Corporation',
    symbol: 'GMPW',
  },
  {
    value: 'GMRE-PA',
    label: 'GMRE-PA,  Global Medical REIT Inc.',
    name: 'Global Medical REIT Inc.',
    symbol: 'GMRE-PA',
  },
  {
    value: 'GNAL',
    label: 'GNAL,  Generation Alpha, Inc.',
    name: 'Generation Alpha, Inc.',
    symbol: 'GNAL',
  },
  {
    value: 'GNL-PB',
    label: 'GNL-PB,  Global Net Lease, Inc.',
    name: 'Global Net Lease, Inc.',
    symbol: 'GNL-PB',
  },
  {
    value: 'GOVX',
    label: 'GOVX,  GeoVax Labs, Inc.',
    name: 'GeoVax Labs, Inc.',
    symbol: 'GOVX',
  },
  {
    value: 'GPFT',
    label: 'GPFT,  Grapefruit USA, Inc.',
    name: 'Grapefruit USA, Inc.',
    symbol: 'GPFT',
  },
  {
    value: 'GRDAF',
    label: 'GRDAF,  Grounded Lithium Corp.',
    name: 'Grounded Lithium Corp.',
    symbol: 'GRDAF',
  },
  {
    value: 'GRMC',
    label: 'GRMC,  Goldrich Mining Company',
    name: 'Goldrich Mining Company',
    symbol: 'GRMC',
  },
  {
    value: 'GRNF',
    label: 'GRNF,  GRN Holding Corporation',
    name: 'GRN Holding Corporation',
    symbol: 'GRNF',
  },
  {
    value: 'GRP-UN',
    label: 'GRP-UN,  Granite Real Estate Investment Trust',
    name: 'Granite Real Estate Investment Trust',
    symbol: 'GRP-UN',
  },
  {
    value: 'GRST',
    label: 'GRST,  Ethema Health Corporation',
    name: 'Ethema Health Corporation',
    symbol: 'GRST',
  },
  {
    value: 'GRUSF',
    label: 'GRUSF,  Grown Rogue International Inc.',
    name: 'Grown Rogue International Inc.',
    symbol: 'GRUSF',
  },
  {
    value: 'GRYN',
    label: 'GRYN,  Green Hygienics Holdings Inc.',
    name: 'Green Hygienics Holdings Inc.',
    symbol: 'GRYN',
  },
  {
    value: 'GSFI',
    label: 'GSFI,  Green Stream Holdings Inc.',
    name: 'Green Stream Holdings Inc.',
    symbol: 'GSFI',
  },
  {
    value: 'GSHN',
    label: 'GSHN,  Gushen, Inc.',
    name: 'Gushen, Inc.',
    symbol: 'GSHN',
  },
  {
    value: 'GSPE',
    label: 'GSPE,  GulfSlope Energy, Inc',
    name: 'GulfSlope Energy, Inc',
    symbol: 'GSPE',
  },
  {
    value: 'GSRX',
    label: 'GSRX,  GSRX Industries Inc.',
    name: 'GSRX Industries Inc.',
    symbol: 'GSRX',
  },
  {
    value: 'GSTX',
    label: 'GSTX,  Graphene & Solar Technologies Limited',
    name: 'Graphene & Solar Technologies Limited',
    symbol: 'GSTX',
  },
  {
    value: 'GTBIF',
    label: 'GTBIF,  Green Thumb Industries Inc.',
    name: 'Green Thumb Industries Inc.',
    symbol: 'GTBIF',
  },
  {
    value: 'GTBP',
    label: 'GTBP,  GT Biopharma, Inc.',
    name: 'GT Biopharma, Inc.',
    symbol: 'GTBP',
  },
  {
    value: 'GTCH',
    label: 'GTCH,  GBT Technologies Inc.',
    name: 'GBT Technologies Inc.',
    symbol: 'GTCH',
  },
  {
    value: 'GTHP',
    label: 'GTHP,  Guided Therapeutics, Inc.',
    name: 'Guided Therapeutics, Inc.',
    symbol: 'GTHP',
  },
  {
    value: 'GTHR',
    label: 'GTHR,  GeneThera, Inc.',
    name: 'GeneThera, Inc.',
    symbol: 'GTHR',
  },
  {
    value: 'GTII',
    label: 'GTII,  Global Tech Industries Group, Inc.',
    name: 'Global Tech Industries Group, Inc.',
    symbol: 'GTII',
  },
  {
    value: 'GTLL',
    label: 'GTLL,  Global Technologies, Ltd.',
    name: 'Global Technologies, Ltd.',
    symbol: 'GTLL',
  },
  {
    value: 'GTMAY',
    label: 'GTMAY,  Grupo TMM, S.A.B.',
    name: 'Grupo TMM, S.A.B.',
    symbol: 'GTMAY',
  },
  {
    value: 'GTVI',
    label: 'GTVI,  Joway Health Industries Group Inc.',
    name: 'Joway Health Industries Group Inc.',
    symbol: 'GTVI',
  },
  {
    value: 'GULTU',
    label: 'GULTU,  Gulf Coast Ultra Deep Royalty Trust',
    name: 'Gulf Coast Ultra Deep Royalty Trust',
    symbol: 'GULTU',
  },
  {
    value: 'GVR.IR',
    label: 'GVR.IR,  Glenveagh Properties PLC',
    name: 'Glenveagh Properties PLC',
    symbol: 'GVR.IR',
  },
  {
    value: 'GWHP',
    label: 'GWHP,  Global WholeHealth Partners Corporation',
    name: 'Global WholeHealth Partners Corporation',
    symbol: 'GWHP',
  },
  {
    value: 'GWTI',
    label: 'GWTI,  Greenway Technologies, Inc.',
    name: 'Greenway Technologies, Inc.',
    symbol: 'GWTI',
  },
  {
    value: 'GXXM',
    label: 'GXXM,  GEX Management, Inc.',
    name: 'GEX Management, Inc.',
    symbol: 'GXXM',
  },
  {
    value: 'HBIA',
    label: 'HBIA,  Hills Bancorporation',
    name: 'Hills Bancorporation',
    symbol: 'HBIA',
  },
  {
    value: 'HCMC',
    label: 'HCMC,  Healthier Choices Management Corp.',
    name: 'Healthier Choices Management Corp.',
    symbol: 'HCMC',
  },
  {
    value: 'HCYT',
    label: 'HCYT,  H-CYTE, Inc.',
    name: 'H-CYTE, Inc.',
    symbol: 'HCYT',
  },
  {
    value: 'HDVY',
    label: 'HDVY,  Health Discovery Corporation',
    name: 'Health Discovery Corporation',
    symbol: 'HDVY',
  },
  {
    value: 'HGBL',
    label: 'HGBL,  Heritage Global Inc.',
    name: 'Heritage Global Inc.',
    symbol: 'HGBL',
  },
  {
    value: 'HGEN',
    label: 'HGEN,  Humanigen, Inc.',
    name: 'Humanigen, Inc.',
    symbol: 'HGEN',
  },
  {
    value: 'HGLD',
    label: 'HGLD,  Patagonia Gold Corp.',
    name: 'Patagonia Gold Corp.',
    symbol: 'HGLD',
  },
  {
    value: 'HHSE',
    label: 'HHSE,  Hannover House, Inc.',
    name: 'Hannover House, Inc.',
    symbol: 'HHSE',
  },
  {
    value: 'HIG-PG',
    label: 'HIG-PG,  The Hartford Financial Services Group, Inc.',
    name: 'The Hartford Financial Services Group, Inc.',
    symbol: 'HIG-PG',
  },
  {
    value: 'HIGR',
    label: 'HIGR,  Hi-Great Group Holding Company',
    name: 'Hi-Great Group Holding Company',
    symbol: 'HIGR',
  },
  {
    value: 'HITC',
    label: 'HITC,  Healthcare Integrated Technologies, Inc.',
    name: 'Healthcare Integrated Technologies, Inc.',
    symbol: 'HITC',
  },
  {
    value: 'HL-PB',
    label: 'HL-PB,  Hecla Mining Company',
    name: 'Hecla Mining Company',
    symbol: 'HL-PB',
  },
  {
    value: 'HLYK',
    label: 'HLYK,  HealthLynked Corp.',
    name: 'HealthLynked Corp.',
    symbol: 'HLYK',
  },
  {
    value: 'HMMR',
    label: 'HMMR,  Hammer Technology Holdings',
    name: 'Hammer Technology Holdings',
    symbol: 'HMMR',
  },
  {
    value: 'HNCKF',
    label: 'HNCKF,  Giga Metals Corporation',
    name: 'Giga Metals Corporation',
    symbol: 'HNCKF',
  },
  {
    value: 'HPTO',
    label: 'HPTO,  hopTo Inc.',
    name: 'hopTo Inc.',
    symbol: 'HPTO',
  },
  {
    value: 'HPX',
    label: 'HPX,  HPX Corp.',
    name: 'HPX Corp.',
    symbol: 'HPX',
  },
  {
    value: 'HQDA',
    label: 'HQDA,  HQDA Elderly Life Network Corp.',
    name: 'HQDA Elderly Life Network Corp.',
    symbol: 'HQDA',
  },
  {
    value: 'HRBR',
    label: 'HRBR,  Harbor Diversified, Inc.',
    name: 'Harbor Diversified, Inc.',
    symbol: 'HRBR',
  },
  {
    value: 'HRMY',
    label: 'HRMY,  Harmony Biosciences Holdings, Inc.',
    name: 'Harmony Biosciences Holdings, Inc.',
    symbol: 'HRMY',
  },
  {
    value: 'HRNNF',
    label: 'HRNNF,  Hydro One Limited',
    name: 'Hydro One Limited',
    symbol: 'HRNNF',
  },
  {
    value: 'HSTC',
    label: 'HSTC,  HST Global, Inc.',
    name: 'HST Global, Inc.',
    symbol: 'HSTC',
  },
  {
    value: 'HSTI',
    label: 'HSTI,  High Sierra Technologies, Inc.',
    name: 'High Sierra Technologies, Inc.',
    symbol: 'HSTI',
  },
  {
    value: 'HWIN',
    label: 'HWIN,  Hometown International, Inc.',
    name: 'Hometown International, Inc.',
    symbol: 'HWIN',
  },
  {
    value: 'HWKE',
    label: 'HWKE,  Hawkeye Systems, Inc.',
    name: 'Hawkeye Systems, Inc.',
    symbol: 'HWKE',
  },
  {
    value: 'HYBT',
    label: 'HYBT,  Heyu Biological Technology Corporation',
    name: 'Heyu Biological Technology Corporation',
    symbol: 'HYBT',
  },
  {
    value: 'HYL.BR',
    label: 'HYL.BR,  Hyloris Pharmaceuticals SA',
    name: 'Hyloris Pharmaceuticals SA',
    symbol: 'HYL.BR',
  },
  {
    value: 'HYSR',
    label: 'HYSR,  SunHydrogen, Inc.',
    name: 'SunHydrogen, Inc.',
    symbol: 'HYSR',
  },
  {
    value: 'HZON-UN',
    label: 'HZON-UN,  Horizon Acquisition Corporation II',
    name: 'Horizon Acquisition Corporation II',
    symbol: 'HZON-UN',
  },
  {
    value: 'ICCT',
    label: 'ICCT,  iCoreConnect Inc.',
    name: 'iCoreConnect Inc.',
    symbol: 'ICCT',
  },
  {
    value: 'ICLD',
    label: 'ICLD,  InterCloud Systems, Inc.',
    name: 'InterCloud Systems, Inc.',
    symbol: 'ICLD',
  },
  {
    value: 'ICNB',
    label: 'ICNB,  Iconic Brands, Inc.',
    name: 'Iconic Brands, Inc.',
    symbol: 'ICNB',
  },
  {
    value: 'ICTSF',
    label: 'ICTSF,  ICTS International N.V.',
    name: 'ICTS International N.V.',
    symbol: 'ICTSF',
  },
  {
    value: 'IEHC',
    label: 'IEHC,  IEH Corporation',
    name: 'IEH Corporation',
    symbol: 'IEHC',
  },
  {
    value: 'IFNY',
    label: 'IFNY,  American Noble Gas, Inc.',
    name: 'American Noble Gas, Inc.',
    symbol: 'IFNY',
  },
  {
    value: 'IGEN',
    label: 'IGEN,  IGEN Networks Corp.',
    name: 'IGEN Networks Corp.',
    symbol: 'IGEN',
  },
  {
    value: 'IGXT',
    label: 'IGXT,  IntelGenx Technologies Corp.',
    name: 'IntelGenx Technologies Corp.',
    symbol: 'IGXT',
  },
  {
    value: 'IINX',
    label: 'IINX,  Ionix Technology, Inc.',
    name: 'Ionix Technology, Inc.',
    symbol: 'IINX',
  },
  {
    value: 'IIPR-PA',
    label: 'IIPR-PA,  Innovative Industrial Properties, Inc.',
    name: 'Innovative Industrial Properties, Inc.',
    symbol: 'IIPR-PA',
  },
  {
    value: 'IIVIP',
    label: 'IIVIP,  Coherent Corp.',
    name: 'Coherent Corp.',
    symbol: 'IIVIP',
  },
  {
    value: 'ILAL',
    label: 'ILAL,  International Land Alliance, Inc.',
    name: 'International Land Alliance, Inc.',
    symbol: 'ILAL',
  },
  {
    value: 'IMII',
    label: 'IMII,  Inception Mining, Inc.',
    name: 'Inception Mining, Inc.',
    symbol: 'IMII',
  },
  {
    value: 'IMUN',
    label: 'IMUN,  Immune Therapeutics, Inc.',
    name: 'Immune Therapeutics, Inc.',
    symbol: 'IMUN',
  },
  {
    value: 'INBP',
    label: 'INBP,  Integrated BioPharma, Inc.',
    name: 'Integrated BioPharma, Inc.',
    symbol: 'INBP',
  },
  {
    value: 'INBX',
    label: 'INBX,  Inhibrx, Inc.',
    name: 'Inhibrx, Inc.',
    symbol: 'INBX',
  },
  {
    value: 'INIS',
    label: 'INIS,  International Isotopes Inc.',
    name: 'International Isotopes Inc.',
    symbol: 'INIS',
  },
  {
    value: 'INLB',
    label: 'INLB,  Item 9 Labs Corp.',
    name: 'Item 9 Labs Corp.',
    symbol: 'INLB',
  },
  {
    value: 'INLX',
    label: 'INLX,  Intellinetics, Inc.',
    name: 'Intellinetics, Inc.',
    symbol: 'INLX',
  },
  {
    value: 'INQD',
    label: 'INQD,  Indoor Harvest Corp',
    name: 'Indoor Harvest Corp',
    symbol: 'INQD',
  },
  {
    value: 'INRD',
    label: 'INRD,  Inrad Optics, Inc.',
    name: 'Inrad Optics, Inc.',
    symbol: 'INRD',
  },
  {
    value: 'INTI',
    label: 'INTI,  Inhibitor Therapeutics, Inc.',
    name: 'Inhibitor Therapeutics, Inc.',
    symbol: 'INTI',
  },
  {
    value: 'INTV',
    label: 'INTV,  Integrated Ventures, Inc.',
    name: 'Integrated Ventures, Inc.',
    symbol: 'INTV',
  },
  {
    value: 'INTZ',
    label: 'INTZ,  Intrusion Inc.',
    name: 'Intrusion Inc.',
    symbol: 'INTZ',
  },
  {
    value: 'INVU',
    label: 'INVU,  Investview, Inc.',
    name: 'Investview, Inc.',
    symbol: 'INVU',
  },
  {
    value: 'IONI',
    label: 'IONI,  I-ON Digital Corp.',
    name: 'I-ON Digital Corp.',
    symbol: 'IONI',
  },
  {
    value: 'IPIX',
    label: 'IPIX,  Innovation Pharmaceuticals Inc.',
    name: 'Innovation Pharmaceuticals Inc.',
    symbol: 'IPIX',
  },
  {
    value: 'IPSI',
    label: 'IPSI,  Innovative Payment Solutions, Inc.',
    name: 'Innovative Payment Solutions, Inc.',
    symbol: 'IPSI',
  },
  {
    value: 'IPTK',
    label: 'IPTK,  AS-IP Tech, Inc.',
    name: 'AS-IP Tech, Inc.',
    symbol: 'IPTK',
  },
  {
    value: 'IQST',
    label: 'IQST,  iQSTEL Inc.',
    name: 'iQSTEL Inc.',
    symbol: 'IQST',
  },
  {
    value: 'ISBA',
    label: 'ISBA,  Isabella Bank Corporation',
    name: 'Isabella Bank Corporation',
    symbol: 'ISBA',
  },
  {
    value: 'ISCO',
    label: 'ISCO,  International Stem Cell Corporation',
    name: 'International Stem Cell Corporation',
    symbol: 'ISCO',
  },
  {
    value: 'ISGN',
    label: 'ISGN,  iSign Solutions Inc.',
    name: 'iSign Solutions Inc.',
    symbol: 'ISGN',
  },
  {
    value: 'ITDN',
    label: 'ITDN,  Interdyne Company',
    name: 'Interdyne Company',
    symbol: 'ITDN',
  },
  {
    value: 'ITOX',
    label: 'ITOX,  IIOT-OXYS, Inc.',
    name: 'IIOT-OXYS, Inc.',
    symbol: 'ITOX',
  },
  {
    value: 'IVDN',
    label: 'IVDN,  Innovative Designs, Inc.',
    name: 'Innovative Designs, Inc.',
    symbol: 'IVDN',
  },
  {
    value: 'IVFH',
    label: 'IVFH,  Innovative Food Holdings, Inc.',
    name: 'Innovative Food Holdings, Inc.',
    symbol: 'IVFH',
  },
  {
    value: 'IVR-PC',
    label: 'IVR-PC,  Invesco Mortgage Capital Inc.',
    name: 'Invesco Mortgage Capital Inc.',
    symbol: 'IVR-PC',
  },
  {
    value: 'IWSY',
    label: 'IWSY,  ImageWare Systems, Inc.',
    name: 'ImageWare Systems, Inc.',
    symbol: 'IWSY',
  },
  {
    value: 'JANL',
    label: 'JANL,  Janel Corporation',
    name: 'Janel Corporation',
    symbol: 'JANL',
  },
  {
    value: 'JDCMF',
    label: 'JDCMF,  JD.com, Inc.',
    name: 'JD.com, Inc.',
    symbol: 'JDCMF',
  },
  {
    value: 'JDEP.AS',
    label: "JDEP.AS,  JDE Peet's N.V.",
    name: "JDE Peet's N.V.",
    symbol: 'JDEP.AS',
  },
  {
    value: 'JFIL',
    label: 'JFIL,  Jubilant Flame International, Ltd.',
    name: 'Jubilant Flame International, Ltd.',
    symbol: 'JFIL',
  },
  {
    value: 'JKSM',
    label: 'JKSM,  Jacksam Corporation',
    name: 'Jacksam Corporation',
    symbol: 'JKSM',
  },
  {
    value: 'JRSS',
    label: 'JRSS,  JRSIS Health Care Corporation',
    name: 'JRSIS Health Care Corporation',
    symbol: 'JRSS',
  },
  {
    value: 'JRVS',
    label: 'JRVS,  iMine Corporation',
    name: 'iMine Corporation',
    symbol: 'JRVS',
  },
  {
    value: 'JSDA',
    label: 'JSDA,  Jones Soda Co.',
    name: 'Jones Soda Co.',
    symbol: 'JSDA',
  },
  {
    value: 'JSHG',
    label: 'JSHG,  Joshua Gold Resources Inc.',
    name: 'Joshua Gold Resources Inc.',
    symbol: 'JSHG',
  },
  {
    value: 'JUVF',
    label: 'JUVF,  Juniata Valley Financial Corp.',
    name: 'Juniata Valley Financial Corp.',
    symbol: 'JUVF',
  },
  {
    value: 'KALO',
    label: 'KALO,  Kallo Inc.',
    name: 'Kallo Inc.',
    symbol: 'KALO',
  },
  {
    value: 'KANP',
    label: 'KANP,  Kaanapali Land, LLC',
    name: 'Kaanapali Land, LLC',
    symbol: 'KANP',
  },
  {
    value: 'KAVL',
    label: 'KAVL,  Kaival Brands Innovations Group, Inc.',
    name: 'Kaival Brands Innovations Group, Inc.',
    symbol: 'KAVL',
  },
  {
    value: 'KAYS',
    label: 'KAYS,  Kaya Holdings, Inc.',
    name: 'Kaya Holdings, Inc.',
    symbol: 'KAYS',
  },
  {
    value: 'KBLB',
    label: 'KBLB,  Kraig Biocraft Laboratories, Inc.',
    name: 'Kraig Biocraft Laboratories, Inc.',
    symbol: 'KBLB',
  },
  {
    value: 'KDOZF',
    label: 'KDOZF,  Kidoz Inc.',
    name: 'Kidoz Inc.',
    symbol: 'KDOZF',
  },
  {
    value: 'KEGX',
    label: 'KEGX,  Key Energy Services, Inc.',
    name: 'Key Energy Services, Inc.',
    symbol: 'KEGX',
  },
  {
    value: 'KIM-PL',
    label: 'KIM-PL,  Kimco Realty Corporation',
    name: 'Kimco Realty Corporation',
    symbol: 'KIM-PL',
  },
  {
    value: 'KIM-PM',
    label: 'KIM-PM,  Kimco Realty Corporation',
    name: 'Kimco Realty Corporation',
    symbol: 'KIM-PM',
  },
  {
    value: 'KITL',
    label: 'KITL,  Kisses From Italy Inc.',
    name: 'Kisses From Italy Inc.',
    symbol: 'KITL',
  },
  {
    value: 'KLDI',
    label: 'KLDI,  KLDiscovery Inc.',
    name: 'KLDiscovery Inc.',
    symbol: 'KLDI',
  },
  {
    value: 'KMR.IR',
    label: 'KMR.IR,  Kenmare Resources plc',
    name: 'Kenmare Resources plc',
    symbol: 'KMR.IR',
  },
  {
    value: 'KOAN',
    label: 'KOAN,  Resonate Blends, Inc.',
    name: 'Resonate Blends, Inc.',
    symbol: 'KOAN',
  },
  {
    value: 'KTEL',
    label: 'KTEL,  KonaTel, Inc.',
    name: 'KonaTel, Inc.',
    symbol: 'KTEL',
  },
  {
    value: 'KULR',
    label: 'KULR,  KULR Technology Group, Inc.',
    name: 'KULR Technology Group, Inc.',
    symbol: 'KULR',
  },
  {
    value: 'KYMR',
    label: 'KYMR,  Kymera Therapeutics, Inc.',
    name: 'Kymera Therapeutics, Inc.',
    symbol: 'KYMR',
  },
  {
    value: 'LAAB',
    label: 'LAAB,  Startech Labs, Inc.',
    name: 'Startech Labs, Inc.',
    symbol: 'LAAB',
  },
  {
    value: 'LANDO',
    label: 'LANDO,  Gladstone Land Corporation',
    name: 'Gladstone Land Corporation',
    symbol: 'LANDO',
  },
  {
    value: 'LBSR',
    label: 'LBSR,  Liberty Star Uranium & Metals Corp.',
    name: 'Liberty Star Uranium & Metals Corp.',
    symbol: 'LBSR',
  },
  {
    value: 'LBTI',
    label: 'LBTI,  Lithium & Boron Technology, Inc.',
    name: 'Lithium & Boron Technology, Inc.',
    symbol: 'LBTI',
  },
  {
    value: 'LBUY',
    label: 'LBUY,  Leafbuyer Technologies, Inc.',
    name: 'Leafbuyer Technologies, Inc.',
    symbol: 'LBUY',
  },
  {
    value: 'LCLP',
    label: 'LCLP,  Life Clips, Inc.',
    name: 'Life Clips, Inc.',
    symbol: 'LCLP',
  },
  {
    value: 'LCTC',
    label: 'LCTC,  Lifeloc Technologies, Inc.',
    name: 'Lifeloc Technologies, Inc.',
    symbol: 'LCTC',
  },
  {
    value: 'LDSN',
    label: 'LDSN,  Luduson G Inc.',
    name: 'Luduson G Inc.',
    symbol: 'LDSN',
  },
  {
    value: 'LEAI',
    label: 'LEAI,  Legacy Education Alliance, Inc.',
    name: 'Legacy Education Alliance, Inc.',
    symbol: 'LEAI',
  },
  {
    value: 'LEAT',
    label: 'LEAT,  Leatt Corporation',
    name: 'Leatt Corporation',
    symbol: 'LEAT',
  },
  {
    value: 'LFAP',
    label: 'LFAP,  LGBTQ Loyalty Holdings, Inc.',
    name: 'LGBTQ Loyalty Holdings, Inc.',
    symbol: 'LFAP',
  },
  {
    value: 'LFER',
    label: 'LFER,  Life On Earth, Inc.',
    name: 'Life On Earth, Inc.',
    symbol: 'LFER',
  },
  {
    value: 'LIVC',
    label: 'LIVC,  Live Current Media, Inc.',
    name: 'Live Current Media, Inc.',
    symbol: 'LIVC',
  },
  {
    value: 'LIXT',
    label: 'LIXT,  Lixte Biotechnology Holdings, Inc.',
    name: 'Lixte Biotechnology Holdings, Inc.',
    symbol: 'LIXT',
  },
  {
    value: 'LKCRU',
    label: 'LKCRU,  Lake Area Corn Processors, LLC',
    name: 'Lake Area Corn Processors, LLC',
    symbol: 'LKCRU',
  },
  {
    value: 'LNSR',
    label: 'LNSR,  LENSAR, Inc.',
    name: 'LENSAR, Inc.',
    symbol: 'LNSR',
  },
  {
    value: 'LOGQ',
    label: 'LOGQ,  Logicquest Technology, Inc.',
    name: 'Logicquest Technology, Inc.',
    symbol: 'LOGQ',
  },
  {
    value: 'LPTV',
    label: 'LPTV,  Loop Media, Inc.',
    name: 'Loop Media, Inc.',
    symbol: 'LPTV',
  },
  {
    value: 'LQMT',
    label: 'LQMT,  Liquidmetal Technologies, Inc.',
    name: 'Liquidmetal Technologies, Inc.',
    symbol: 'LQMT',
  },
  {
    value: 'LRDC',
    label: 'LRDC,  Laredo Oil, Inc.',
    name: 'Laredo Oil, Inc.',
    symbol: 'LRDC',
  },
  {
    value: 'LSMG',
    label: 'LSMG,  Lode-Star Mining Inc.',
    name: 'Lode-Star Mining Inc.',
    symbol: 'LSMG',
  },
  {
    value: 'LSPD',
    label: 'LSPD,  Lightspeed Commerce Inc.',
    name: 'Lightspeed Commerce Inc.',
    symbol: 'LSPD',
  },
  {
    value: 'LTGJ',
    label: 'LTGJ,  Xiamen Lutong International Travel Agency Co. Ltd.',
    name: 'Xiamen Lutong International Travel Agency Co. Ltd.',
    symbol: 'LTGJ',
  },
  {
    value: 'LTMAQ',
    label: 'LTMAQ,  LATAM Airlines Group S.A.',
    name: 'LATAM Airlines Group S.A.',
    symbol: 'LTMAQ',
  },
  {
    value: 'LTUM',
    label: 'LTUM,  Lithium Corporation',
    name: 'Lithium Corporation',
    symbol: 'LTUM',
  },
  {
    value: 'LUVU',
    label: 'LUVU,  Luvu Brands, Inc.',
    name: 'Luvu Brands, Inc.',
    symbol: 'LUVU',
  },
  {
    value: 'LWLG',
    label: 'LWLG,  Lightwave Logic, Inc.',
    name: 'Lightwave Logic, Inc.',
    symbol: 'LWLG',
  },
  {
    value: 'LXP-PC',
    label: 'LXP-PC,  LXP Industrial Trust',
    name: 'LXP Industrial Trust',
    symbol: 'LXP-PC',
  },
  {
    value: 'MAA-PI',
    label: 'MAA-PI,  Mid-America Apartment Communities, Inc.',
    name: 'Mid-America Apartment Communities, Inc.',
    symbol: 'MAA-PI',
  },
  {
    value: 'CRKN',
    label: 'CRKN,  Crown ElectroKinetics Corp.',
    name: 'Crown ElectroKinetics Corp.',
    symbol: 'CRKN',
  },
  {
    value: 'MAGE',
    label: 'MAGE,  Magellan Gold Corporation',
    name: 'Magellan Gold Corporation',
    symbol: 'MAGE',
  },
  {
    value: 'MAHEPC.NS',
    label: 'MAHEPC.NS,  Mahindra EPC Irrigation Limited',
    name: 'Mahindra EPC Irrigation Limited',
    symbol: 'MAHEPC.NS',
  },
  {
    value: 'MAXD',
    label: 'MAXD,  Max Sound Corporation',
    name: 'Max Sound Corporation',
    symbol: 'MAXD',
  },
  {
    value: 'MCCX',
    label: 'MCCX,  MCX Technologies Corporation',
    name: 'MCX Technologies Corporation',
    symbol: 'MCCX',
  },
  {
    value: 'MCOA',
    label: 'MCOA,  Marijuana Company of America, Inc.',
    name: 'Marijuana Company of America, Inc.',
    symbol: 'MCOA',
  },
  {
    value: 'MCUJF',
    label: 'MCUJF,  Medicure Inc.',
    name: 'Medicure Inc.',
    symbol: 'MCUJF',
  },
  {
    value: 'MDEX',
    label: 'MDEX,  Madison Technologies Inc.',
    name: 'Madison Technologies Inc.',
    symbol: 'MDEX',
  },
  {
    value: 'MDWT',
    label: 'MDWT,  Midwest Holding Inc.',
    name: 'Midwest Holding Inc.',
    symbol: 'MDWT',
  },
  {
    value: 'MDXL',
    label: 'MDXL,  MediXall Group, Inc.',
    name: 'MediXall Group, Inc.',
    symbol: 'MDXL',
  },
  {
    value: 'MEEC',
    label: 'MEEC,  Midwest Energy Emissions Corp.',
    name: 'Midwest Energy Emissions Corp.',
    symbol: 'MEEC',
  },
  {
    value: 'MEPW',
    label: 'MEPW,  ME Renewable Power Corporation',
    name: 'ME Renewable Power Corporation',
    symbol: 'MEPW',
  },
  {
    value: 'MET-PF',
    label: 'MET-PF,  MetLife, Inc.',
    name: 'MetLife, Inc.',
    symbol: 'MET-PF',
  },
  {
    value: 'MFON',
    label: 'MFON,  Mobivity Holdings Corp.',
    name: 'Mobivity Holdings Corp.',
    symbol: 'MFON',
  },
  {
    value: 'MGHL',
    label: 'MGHL,  Morgan Group Holding Co.',
    name: 'Morgan Group Holding Co.',
    symbol: 'MGHL',
  },
  {
    value: 'MGTI',
    label: 'MGTI,  MGT Capital Investments, Inc.',
    name: 'MGT Capital Investments, Inc.',
    symbol: 'MGTI',
  },
  {
    value: 'MHPC',
    label: 'MHPC,  Manufactured Housing Properties Inc.',
    name: 'Manufactured Housing Properties Inc.',
    symbol: 'MHPC',
  },
  {
    value: 'MHTX',
    label: 'MHTX,  Manhattan Scientifics, Inc.',
    name: 'Manhattan Scientifics, Inc.',
    symbol: 'MHTX',
  },
  {
    value: 'MITI',
    label: 'MITI,  Mitesco, Inc.',
    name: 'Mitesco, Inc.',
    symbol: 'MITI',
  },
  {
    value: 'MJHI',
    label: 'MJHI,  MJ Harvest, Inc.',
    name: 'MJ Harvest, Inc.',
    symbol: 'MJHI',
  },
  {
    value: 'MJNE',
    label: 'MJNE,  MJ Holdings, Inc.',
    name: 'MJ Holdings, Inc.',
    symbol: 'MJNE',
  },
  {
    value: 'MLFB',
    label: 'MLFB,  Major League Football, Inc.',
    name: 'Major League Football, Inc.',
    symbol: 'MLFB',
  },
  {
    value: 'MMEX',
    label: 'MMEX,  MMEX Resources Corporation',
    name: 'MMEX Resources Corporation',
    symbol: 'MMEX',
  },
  {
    value: 'MMMB',
    label: "MMMB,  MamaMancini's Holdings, Inc.",
    name: "MamaMancini's Holdings, Inc.",
    symbol: 'MMMB',
  },
  {
    value: 'MMMM',
    label: 'MMMM,  Quad M Solutions, Inc.',
    name: 'Quad M Solutions, Inc.',
    symbol: 'MMMM',
  },
  {
    value: 'MMND',
    label: 'MMND,  Mastermind, Inc.',
    name: 'Mastermind, Inc.',
    symbol: 'MMND',
  },
  {
    value: 'MMNFF',
    label: 'MMNFF,  MedMen Enterprises Inc.',
    name: 'MedMen Enterprises Inc.',
    symbol: 'MMNFF',
  },
  {
    value: 'MNSBP',
    label: 'MNSBP,  MainStreet Bancshares, Inc.',
    name: 'MainStreet Bancshares, Inc.',
    symbol: 'MNSBP',
  },
  {
    value: 'MNTR',
    label: 'MNTR,  Mentor Capital, Inc.',
    name: 'Mentor Capital, Inc.',
    symbol: 'MNTR',
  },
  {
    value: 'MOBQ',
    label: 'MOBQ,  Mobiquity Technologies, Inc.',
    name: 'Mobiquity Technologies, Inc.',
    symbol: 'MOBQ',
  },
  {
    value: 'MODD',
    label: 'MODD,  Modular Medical, Inc.',
    name: 'Modular Medical, Inc.',
    symbol: 'MODD',
  },
  {
    value: 'MOJO',
    label: 'MOJO,  Equator Beverage Company',
    name: 'Equator Beverage Company',
    symbol: 'MOJO',
  },
  {
    value: 'MPAD',
    label: 'MPAD,  Micropac Industries, Inc.',
    name: 'Micropac Industries, Inc.',
    symbol: 'MPAD',
  },
  {
    value: 'MRGE',
    label: 'MRGE,  Mirage Energy Corporation',
    name: 'Mirage Energy Corporation',
    symbol: 'MRGE',
  },
  {
    value: 'MRMD',
    label: 'MRMD,  MariMed Inc.',
    name: 'MariMed Inc.',
    symbol: 'MRMD',
  },
  {
    value: 'MSLP',
    label: 'MSLP,  MusclePharm Corporation',
    name: 'MusclePharm Corporation',
    symbol: 'MSLP',
  },
  {
    value: 'MSSV',
    label: 'MSSV,  Meso Numismatics, Inc.',
    name: 'Meso Numismatics, Inc.',
    symbol: 'MSSV',
  },
  {
    value: 'MXSG',
    label: 'MXSG,  Mexus Gold US',
    name: 'Mexus Gold US',
    symbol: 'MXSG',
  },
  {
    value: 'MYHI',
    label: 'MYHI,  Mountain High Acquisitions Corp.',
    name: 'Mountain High Acquisitions Corp.',
    symbol: 'MYHI',
  },
  {
    value: 'MYMX',
    label: 'MYMX,  Mymetics Corporation',
    name: 'Mymetics Corporation',
    symbol: 'MYMX',
  },
  {
    value: 'NANX',
    label: 'NANX,  Nanophase Technologies Corporation',
    name: 'Nanophase Technologies Corporation',
    symbol: 'NANX',
  },
  {
    value: 'NBIO',
    label: 'NBIO,  Nascent Biotech, Inc.',
    name: 'Nascent Biotech, Inc.',
    symbol: 'NBIO',
  },
  {
    value: 'NBTX',
    label: 'NBTX,  Nanobiotix S.A.',
    name: 'Nanobiotix S.A.',
    symbol: 'NBTX',
  },
  {
    value: 'NCRA',
    label: 'NCRA,  Nocera, Inc.',
    name: 'Nocera, Inc.',
    symbol: 'NCRA',
  },
  {
    value: 'NGLD',
    label: 'NGLD,  Nevada Canyon Gold Corp.',
    name: 'Nevada Canyon Gold Corp.',
    symbol: 'NGLD',
  },
  {
    value: 'NGL-PB',
    label: 'NGL-PB,  NGL Energy Partners LP',
    name: 'NGL Energy Partners LP',
    symbol: 'NGL-PB',
  },
  {
    value: 'NGL-PC',
    label: 'NGL-PC,  NGL Energy Partners LP',
    name: 'NGL Energy Partners LP',
    symbol: 'NGL-PC',
  },
  {
    value: 'NGTF',
    label: 'NGTF,  Nightfood Holdings, Inc.',
    name: 'Nightfood Holdings, Inc.',
    symbol: 'NGTF',
  },
  {
    value: 'NIHK',
    label: 'NIHK,  Video River Networks, Inc.',
    name: 'Video River Networks, Inc.',
    symbol: 'NIHK',
  },
  {
    value: 'NIMU',
    label: 'NIMU,  Non-Invasive Monitoring Systems, Inc.',
    name: 'Non-Invasive Monitoring Systems, Inc.',
    symbol: 'NIMU',
  },
  {
    value: 'NIOBF',
    label: 'NIOBF,  NioCorp Developments Ltd.',
    name: 'NioCorp Developments Ltd.',
    symbol: 'NIOBF',
  },
  {
    value: 'NI-PB',
    label: 'NI-PB,  NiSource Inc.',
    name: 'NiSource Inc.',
    symbol: 'NI-PB',
  },
  {
    value: 'NLY-PI',
    label: 'NLY-PI,  Annaly Capital Management, Inc.',
    name: 'Annaly Capital Management, Inc.',
    symbol: 'NLY-PI',
  },
  {
    value: 'NMEX',
    label: 'NMEX,  Northern Minerals & Exploration Ltd.',
    name: 'Northern Minerals & Exploration Ltd.',
    symbol: 'NMEX',
  },
  {
    value: 'NMGX',
    label: 'NMGX,  Nano Magic Holdings Inc.',
    name: 'Nano Magic Holdings Inc.',
    symbol: 'NMGX',
  },
  {
    value: 'NM-PG',
    label: 'NM-PG,  Navios Maritime Holdings Inc.',
    name: 'Navios Maritime Holdings Inc.',
    symbol: 'NM-PG',
  },
  {
    value: 'NNGF.DE',
    label: 'NNGF.DE,  National Grid plc',
    name: 'National Grid plc',
    symbol: 'NNGF.DE',
  },
  {
    value: 'NNUP',
    label: 'NNUP,  Nocopi Technologies, Inc.',
    name: 'Nocopi Technologies, Inc.',
    symbol: 'NNUP',
  },
  {
    value: 'NOBH',
    label: 'NOBH,  Nobility Homes, Inc.',
    name: 'Nobility Homes, Inc.',
    symbol: 'NOBH',
  },
  {
    value: 'NOVC',
    label: 'NOVC,  Novation Companies, Inc.',
    name: 'Novation Companies, Inc.',
    symbol: 'NOVC',
  },
  {
    value: 'NREF-PA',
    label: 'NREF-PA,  NexPoint Real Estate Finance, Inc.',
    name: 'NexPoint Real Estate Finance, Inc.',
    symbol: 'NREF-PA',
  },
  {
    value: 'NRIS',
    label: 'NRIS,  Norris Industries, Inc.',
    name: 'Norris Industries, Inc.',
    symbol: 'NRIS',
  },
  {
    value: 'NROM',
    label: "NROM,  Noble Roman's, Inc.",
    name: "Noble Roman's, Inc.",
    symbol: 'NROM',
  },
  {
    value: 'NSA-PA',
    label: 'NSA-PA,  National Storage Affiliates Trust',
    name: 'National Storage Affiliates Trust',
    symbol: 'NSA-PA',
  },
  {
    value: 'NSFDF',
    label: 'NSFDF,  NXT Energy Solutions Inc.',
    name: 'NXT Energy Solutions Inc.',
    symbol: 'NSFDF',
  },
  {
    value: 'NTPY',
    label: 'NTPY,  NetPay International, Inc.',
    name: 'NetPay International, Inc.',
    symbol: 'NTPY',
  },
  {
    value: 'NTRB',
    label: 'NTRB,  Nutriband Inc.',
    name: 'Nutriband Inc.',
    symbol: 'NTRB',
  },
  {
    value: 'NTRR',
    label: 'NTRR,  Neutra Corp.',
    name: 'Neutra Corp.',
    symbol: 'NTRR',
  },
  {
    value: 'NUKK',
    label: 'NUKK,  Nukkleus Inc.',
    name: 'Nukkleus Inc.',
    symbol: 'NUKK',
  },
  {
    value: 'NUMD',
    label: 'NUMD,  Nu-Med Plus, Inc.',
    name: 'Nu-Med Plus, Inc.',
    symbol: 'NUMD',
  },
  {
    value: 'NUVR',
    label: 'NUVR,  Nuvera Communications, Inc.',
    name: 'Nuvera Communications, Inc.',
    symbol: 'NUVR',
  },
  {
    value: 'NVGI',
    label: 'NVGI,  Noble Vici Group, Inc.',
    name: 'Noble Vici Group, Inc.',
    symbol: 'NVGI',
  },
  {
    value: 'NVNT',
    label: 'NVNT,  Novint Technologies, Inc.',
    name: 'Novint Technologies, Inc.',
    symbol: 'NVNT',
  },
  {
    value: 'NVOS',
    label: 'NVOS,  Novo Integrated Sciences, Inc.',
    name: 'Novo Integrated Sciences, Inc.',
    symbol: 'NVOS',
  },
  {
    value: 'NWBO',
    label: 'NWBO,  Northwest Biotherapeutics, Inc.',
    name: 'Northwest Biotherapeutics, Inc.',
    symbol: 'NWBO',
  },
  {
    value: 'NWPP',
    label: 'NWPP,  New Peoples Bankshares, Inc.',
    name: 'New Peoples Bankshares, Inc.',
    symbol: 'NWPP',
  },
  {
    value: 'NWYU',
    label: 'NWYU,  New You, Inc.',
    name: 'New You, Inc.',
    symbol: 'NWYU',
  },
  {
    value: 'NXEN',
    label: 'NXEN,  Nexien BioPharma, Inc.',
    name: 'Nexien BioPharma, Inc.',
    symbol: 'NXEN',
  },
  {
    value: 'NYC',
    label: 'NYC,  New York City REIT, Inc.',
    name: 'New York City REIT, Inc.',
    symbol: 'NYC',
  },
  {
    value: 'OBTX',
    label: 'OBTX,  Everything Blockchain, Inc.',
    name: 'Everything Blockchain, Inc.',
    symbol: 'OBTX',
  },
  {
    value: 'OCLN',
    label: 'OCLN,  OriginClear, Inc.',
    name: 'OriginClear, Inc.',
    symbol: 'OCLN',
  },
  {
    value: 'ODII',
    label: 'ODII,  Odyssey Semiconductor Technologies, Inc.',
    name: 'Odyssey Semiconductor Technologies, Inc.',
    symbol: 'ODII',
  },
  {
    value: 'ODYY',
    label: 'ODYY,  Odyssey Health, Inc.',
    name: 'Odyssey Health, Inc.',
    symbol: 'ODYY',
  },
  {
    value: 'OGAA',
    label: 'OGAA,  Organic Agricultural Company Limited',
    name: 'Organic Agricultural Company Limited',
    symbol: 'OGAA',
  },
  {
    value: 'OIBRQ',
    label: 'OIBRQ,  Oi S.A.',
    name: 'Oi S.A.',
    symbol: 'OIBRQ',
  },
  {
    value: 'OMQS',
    label: 'OMQS,  OMNIQ Corp.',
    name: 'OMNIQ Corp.',
    symbol: 'OMQS',
  },
  {
    value: 'OMTK',
    label: 'OMTK,  Omnitek Engineering Corp.',
    name: 'Omnitek Engineering Corp.',
    symbol: 'OMTK',
  },
  {
    value: 'OMWS',
    label: 'OMWS,  Omnia Wellness Inc.',
    name: 'Omnia Wellness Inc.',
    symbol: 'OMWS',
  },
  {
    value: 'ONDS',
    label: 'ONDS,  Ondas Holdings Inc.',
    name: 'Ondas Holdings Inc.',
    symbol: 'ONDS',
  },
  {
    value: 'OPXS',
    label: 'OPXS,  Optex Systems Holdings, Inc',
    name: 'Optex Systems Holdings, Inc',
    symbol: 'OPXS',
  },
  {
    value: 'ORLA',
    label: 'ORLA,  Orla Mining Ltd.',
    name: 'Orla Mining Ltd.',
    symbol: 'ORLA',
  },
  {
    value: 'OTRKP',
    label: 'OTRKP,  Ontrak, Inc.',
    name: 'Ontrak, Inc.',
    symbol: 'OTRKP',
  },
  {
    value: 'OVTZ',
    label: 'OVTZ,  Oculus VisionTech, Inc.',
    name: 'Oculus VisionTech, Inc.',
    symbol: 'OVTZ',
  },
  {
    value: 'OWPC',
    label: 'OWPC,  One World Products, Inc.',
    name: 'One World Products, Inc.',
    symbol: 'OWPC',
  },
  {
    value: 'OZSC',
    label: 'OZSC,  Ozop Energy Solutions, Inc.',
    name: 'Ozop Energy Solutions, Inc.',
    symbol: 'OZSC',
  },
  {
    value: 'PACV',
    label: 'PACV,  Pacific Ventures Group, Inc.',
    name: 'Pacific Ventures Group, Inc.',
    symbol: 'PACV',
  },
  {
    value: 'PAYD',
    label: 'PAYD,  PAID, Inc.',
    name: 'PAID, Inc.',
    symbol: 'PAYD',
  },
  {
    value: 'PBSV',
    label: 'PBSV,  Pharma-Bio Serv, Inc.',
    name: 'Pharma-Bio Serv, Inc.',
    symbol: 'PBSV',
  },
  {
    value: 'PCSA',
    label: 'PCSA,  Processa Pharmaceuticals, Inc.',
    name: 'Processa Pharmaceuticals, Inc.',
    symbol: 'PCSA',
  },
  {
    value: 'PCTL',
    label: 'PCTL,  PCT Ltd',
    name: 'PCT Ltd',
    symbol: 'PCTL',
  },
  {
    value: 'PCYN',
    label: 'PCYN,  Procyon Corporation',
    name: 'Procyon Corporation',
    symbol: 'PCYN',
  },
  {
    value: 'PEB-PF',
    label: 'PEB-PF,  Pebblebrook Hotel Trust',
    name: 'Pebblebrook Hotel Trust',
    symbol: 'PEB-PF',
  },
  {
    value: 'PEYE',
    label: 'PEYE,  Precision Optics Corporation, Inc.',
    name: 'Precision Optics Corporation, Inc.',
    symbol: 'PEYE',
  },
  {
    value: 'PFBX',
    label: 'PFBX,  Peoples Financial Corporation',
    name: 'Peoples Financial Corporation',
    symbol: 'PFBX',
  },
  {
    value: 'PFHO',
    label: 'PFHO,  Pacific Health Care Organization, Inc.',
    name: 'Pacific Health Care Organization, Inc.',
    symbol: 'PFHO',
  },
  {
    value: 'PFX',
    label: 'PFX,  PhenixFIN Corporation',
    name: 'PhenixFIN Corporation',
    symbol: 'PFX',
  },
  {
    value: 'PGAI',
    label: 'PGAI,  PGI Incorporated',
    name: 'PGI Incorporated',
    symbol: 'PGAI',
  },
  {
    value: 'PGAS',
    label: 'PGAS,  Petrogress, Inc.',
    name: 'Petrogress, Inc.',
    symbol: 'PGAS',
  },
  {
    value: 'PGOL',
    label: 'PGOL,  Patriot Gold Corp.',
    name: 'Patriot Gold Corp.',
    symbol: 'PGOL',
  },
  {
    value: 'PGTK',
    label: 'PGTK,  Pacific Green Technologies Inc.',
    name: 'Pacific Green Technologies Inc.',
    symbol: 'PGTK',
  },
  {
    value: 'PHBI',
    label: 'PHBI,  Pharmagreen Biotech Inc.',
    name: 'Pharmagreen Biotech Inc.',
    symbol: 'PHBI',
  },
  {
    value: 'PHCG',
    label: 'PHCG,  Pure Harvest Corporate Group, Inc.',
    name: 'Pure Harvest Corporate Group, Inc.',
    symbol: 'PHCG',
  },
  {
    value: 'PHGE-UN',
    label: 'PHGE-UN,  BiomX Inc.',
    name: 'BiomX Inc.',
    symbol: 'PHGE-UN',
  },
  {
    value: 'PHOT',
    label: 'PHOT,  GrowLife, Inc.',
    name: 'GrowLife, Inc.',
    symbol: 'PHOT',
  },
  {
    value: 'PIAC',
    label: 'PIAC,  Princeton Capital Corporation',
    name: 'Princeton Capital Corporation',
    symbol: 'PIAC',
  },
  {
    value: 'PKKW',
    label: 'PKKW,  Parkway Acquisition Corp.',
    name: 'Parkway Acquisition Corp.',
    symbol: 'PKKW',
  },
  {
    value: 'PKTX',
    label: 'PKTX,  ProtoKinetix, Incorporated',
    name: 'ProtoKinetix, Incorporated',
    symbol: 'PKTX',
  },
  {
    value: 'PLYN',
    label: 'PLYN,  Palayan Resources, Inc.',
    name: 'Palayan Resources, Inc.',
    symbol: 'PLYN',
  },
  {
    value: 'PMCB',
    label: 'PMCB,  PharmaCyte Biotech, Inc.',
    name: 'PharmaCyte Biotech, Inc.',
    symbol: 'PMCB',
  },
  {
    value: 'PMHG',
    label: 'PMHG,  Prime Meridian Holding Company',
    name: 'Prime Meridian Holding Company',
    symbol: 'PMHG',
  },
  {
    value: 'PMPG',
    label: 'PMPG,  Premier Products Group, Inc.',
    name: 'Premier Products Group, Inc.',
    symbol: 'PMPG',
  },
  {
    value: 'PMT-PB',
    label: 'PMT-PB,  PennyMac Mortgage Investment Trust',
    name: 'PennyMac Mortgage Investment Trust',
    symbol: 'PMT-PB',
  },
  {
    value: 'POTN',
    label: 'POTN,  PotNetwork Holdings, Inc.',
    name: 'PotNetwork Holdings, Inc.',
    symbol: 'POTN',
  },
  {
    value: 'POWW',
    label: 'POWW,  AMMO, Inc.',
    name: 'AMMO, Inc.',
    symbol: 'POWW',
  },
  {
    value: 'PPBN',
    label: 'PPBN,  Pinnacle Bankshares Corporation',
    name: 'Pinnacle Bankshares Corporation',
    symbol: 'PPBN',
  },
  {
    value: 'PPCB',
    label: 'PPCB,  Propanc Biopharma, Inc.',
    name: 'Propanc Biopharma, Inc.',
    symbol: 'PPCB',
  },
  {
    value: 'PQEFF',
    label: 'PQEFF,  Petroteq Energy Inc.',
    name: 'Petroteq Energy Inc.',
    symbol: 'PQEFF',
  },
  {
    value: 'PRED',
    label: 'PRED,  Predictive Technology Group, Inc.',
    name: 'Predictive Technology Group, Inc.',
    symbol: 'PRED',
  },
  {
    value: 'PRKA',
    label: 'PRKA,  Parks! America, Inc.',
    name: 'Parks! America, Inc.',
    symbol: 'PRKA',
  },
  {
    value: 'PRSI',
    label: 'PRSI,  Portsmouth Square, Inc.',
    name: 'Portsmouth Square, Inc.',
    symbol: 'PRSI',
  },
  {
    value: 'PRX.AS',
    label: 'PRX.AS,  Prosus N.V.',
    name: 'Prosus N.V.',
    symbol: 'PRX.AS',
  },
  {
    value: 'PSA-PG',
    label: 'PSA-PG,  Public Storage',
    name: 'Public Storage',
    symbol: 'PSA-PG',
  },
  {
    value: 'PSB-PZ',
    label: 'PSB-PZ,  PS Business Parks, Inc.',
    name: 'PS Business Parks, Inc.',
    symbol: 'PSB-PZ',
  },
  {
    value: 'PSIX',
    label: 'PSIX,  Power Solutions International, Inc.',
    name: 'Power Solutions International, Inc.',
    symbol: 'PSIX',
  },
  {
    value: 'PSSR',
    label: 'PSSR,  PASSUR Aerospace, Inc.',
    name: 'PASSUR Aerospace, Inc.',
    symbol: 'PSSR',
  },
  {
    value: 'PTCO',
    label: 'PTCO,  PetroGas Company',
    name: 'PetroGas Company',
    symbol: 'PTCO',
  },
  {
    value: 'PTIX',
    label: 'PTIX,  Protagenic Therapeutics, Inc.',
    name: 'Protagenic Therapeutics, Inc.',
    symbol: 'PTIX',
  },
  {
    value: 'PTZH',
    label: 'PTZH,  Photozou Holdings, Inc.',
    name: 'Photozou Holdings, Inc.',
    symbol: 'PTZH',
  },
  {
    value: 'PUBC',
    label: 'PUBC,  PureBase Corporation',
    name: 'PureBase Corporation',
    symbol: 'PUBC',
  },
  {
    value: 'PURE',
    label: 'PURE,  PURE Bioscience, Inc.',
    name: 'PURE Bioscience, Inc.',
    symbol: 'PURE',
  },
  {
    value: 'PVEG',
    label: 'PVEG,  Pacific Vegas Global Strategies, Inc.',
    name: 'Pacific Vegas Global Strategies, Inc.',
    symbol: 'PVEG',
  },
  {
    value: 'PVNNF',
    label: 'PVNNF,  P.V. Nano Cell Ltd.',
    name: 'P.V. Nano Cell Ltd.',
    symbol: 'PVNNF',
  },
  {
    value: 'PWCO',
    label: 'PWCO,  PwrCor, Inc.',
    name: 'PwrCor, Inc.',
    symbol: 'PWCO',
  },
  {
    value: 'QBIO',
    label: 'QBIO,  Q BioMed Inc.',
    name: 'Q BioMed Inc.',
    symbol: 'QBIO',
  },
  {
    value: 'QCCO',
    label: 'QCCO,  QC Holdings, Inc.',
    name: 'QC Holdings, Inc.',
    symbol: 'QCCO',
  },
  {
    value: 'QKLS',
    label: 'QKLS,  QKL Stores Inc.',
    name: 'QKL Stores Inc.',
    symbol: 'QKLS',
  },
  {
    value: 'QMCI',
    label: 'QMCI,  QuoteMedia, Inc.',
    name: 'QuoteMedia, Inc.',
    symbol: 'QMCI',
  },
  {
    value: 'QNBC',
    label: 'QNBC,  QNB Corp.',
    name: 'QNB Corp.',
    symbol: 'QNBC',
  },
  {
    value: 'QNTA',
    label: 'QNTA,  Quanta, Inc.',
    name: 'Quanta, Inc.',
    symbol: 'QNTA',
  },
  {
    value: 'QNTO',
    label: 'QNTO,  Quaint Oak Bancorp, Inc.',
    name: 'Quaint Oak Bancorp, Inc.',
    symbol: 'QNTO',
  },
  {
    value: 'QPRC',
    label: 'QPRC,  Quest Patent Research Corporation',
    name: 'Quest Patent Research Corporation',
    symbol: 'QPRC',
  },
  {
    value: 'QRON',
    label: 'QRON,  Qrons Inc.',
    name: 'Qrons Inc.',
    symbol: 'QRON',
  },
  {
    value: 'QSEP',
    label: 'QSEP,  QS Energy, Inc.',
    name: 'QS Energy, Inc.',
    symbol: 'QSEP',
  },
  {
    value: 'QTXB',
    label: 'QTXB,  QuantRx Biomedical Corporation',
    name: 'QuantRx Biomedical Corporation',
    symbol: 'QTXB',
  },
  {
    value: 'QUBT',
    label: 'QUBT,  Quantum Computing, Inc.',
    name: 'Quantum Computing, Inc.',
    symbol: 'QUBT',
  },
  {
    value: 'RASP',
    label: 'RASP,  Rasna Therapeutics, Inc.',
    name: 'Rasna Therapeutics, Inc.',
    symbol: 'RASP',
  },
  {
    value: 'RAYT',
    label: 'RAYT,  Rayont Inc.',
    name: 'Rayont Inc.',
    symbol: 'RAYT',
  },
  {
    value: 'RCAR',
    label: 'RCAR,  RenovaCare, Inc.',
    name: 'RenovaCare, Inc.',
    symbol: 'RCAR',
  },
  {
    value: 'RCAT',
    label: 'RCAT,  Red Cat Holdings, Inc.',
    name: 'Red Cat Holdings, Inc.',
    symbol: 'RCAT',
  },
  {
    value: 'RCRT',
    label: 'RCRT,  Recruiter.com Group, Inc.',
    name: 'Recruiter.com Group, Inc.',
    symbol: 'RCRT',
  },
  {
    value: 'RDGL',
    label: 'RDGL,  Vivos Inc.',
    name: 'Vivos Inc.',
    symbol: 'RDGL',
  },
  {
    value: 'REEMF',
    label: 'REEMF,  Rare Element Resources Ltd.',
    name: 'Rare Element Resources Ltd.',
    symbol: 'REEMF',
  },
  {
    value: 'REMI',
    label: 'REMI,  Remedent, Inc.',
    name: 'Remedent, Inc.',
    symbol: 'REMI',
  },
  {
    value: 'REPCF',
    label: 'REPCF,  RepliCel Life Sciences Inc.',
    name: 'RepliCel Life Sciences Inc.',
    symbol: 'REPCF',
  },
  {
    value: 'RETC',
    label: 'RETC,  12 ReTech Corporation',
    name: '12 ReTech Corporation',
    symbol: 'RETC',
  },
  {
    value: 'REXR-PC',
    label: 'REXR-PC,  Rexford Industrial Realty, Inc.',
    name: 'Rexford Industrial Realty, Inc.',
    symbol: 'REXR-PC',
  },
  {
    value: 'RGIN',
    label: 'RGIN,  Regenicin, Inc.',
    name: 'Regenicin, Inc.',
    symbol: 'RGIN',
  },
  {
    value: 'RGMP',
    label: 'RGMP,  Regnum Corp.',
    name: 'Regnum Corp.',
    symbol: 'RGMP',
  },
  {
    value: 'RGRX',
    label: 'RGRX,  RegeneRx Biopharmaceuticals, Inc.',
    name: 'RegeneRx Biopharmaceuticals, Inc.',
    symbol: 'RGRX',
  },
  {
    value: 'RHE-PA',
    label: 'RHE-PA,  Regional Health Properties, Inc.',
    name: 'Regional Health Properties, Inc.',
    symbol: 'RHE-PA',
  },
  {
    value: 'RKFL',
    label: 'RKFL,  RocketFuel Blockchain, Inc.',
    name: 'RocketFuel Blockchain, Inc.',
    symbol: 'RKFL',
  },
  {
    value: 'RLBY',
    label: 'RLBY,  Reliability Incorporated',
    name: 'Reliability Incorporated',
    symbol: 'RLBY',
  },
  {
    value: 'RMHB',
    label: 'RMHB,  Rocky Mountain High Brands, Inc.',
    name: 'Rocky Mountain High Brands, Inc.',
    symbol: 'RMHB',
  },
  {
    value: 'RMSL',
    label: 'RMSL,  REMSleep Holdings, Inc.',
    name: 'REMSleep Holdings, Inc.',
    symbol: 'RMSL',
  },
  {
    value: 'RNR-PF',
    label: 'RNR-PF,  RenaissanceRe Holdings Ltd.',
    name: 'RenaissanceRe Holdings Ltd.',
    symbol: 'RNR-PF',
  },
  {
    value: 'RNVA',
    label: 'RNVA,  Rennova Health, Inc.',
    name: 'Rennova Health, Inc.',
    symbol: 'RNVA',
  },
  {
    value: 'RO.SW',
    label: 'RO.SW,  Roche Holding AG',
    name: 'Roche Holding AG',
    symbol: 'RO.SW',
  },
  {
    value: 'ROYL',
    label: 'ROYL,  Royale Energy, Inc.',
    name: 'Royale Energy, Inc.',
    symbol: 'ROYL',
  },
  {
    value: 'RPMT',
    label: 'RPMT,  Rego Payment Architectures, Inc.',
    name: 'Rego Payment Architectures, Inc.',
    symbol: 'RPMT',
  },
  {
    value: 'RRTL.DE',
    label: 'RRTL.DE,  RTL Group S.A.',
    name: 'RTL Group S.A.',
    symbol: 'RRTL.DE',
  },
  {
    value: 'RSKIA',
    label: 'RSKIA,  George Risk Industries, Inc.',
    name: 'George Risk Industries, Inc.',
    symbol: 'RSKIA',
  },
  {
    value: 'RSRV',
    label: 'RSRV,  The Reserve Petroleum Company',
    name: 'The Reserve Petroleum Company',
    symbol: 'RSRV',
  },
  {
    value: 'RTNTF',
    label: 'RTNTF,  Rio Tinto Group',
    name: 'Rio Tinto Group',
    symbol: 'RTNTF',
  },
  {
    value: 'RTSL',
    label: 'RTSL,  Rapid Therapeutic Science Laboratories, Inc.',
    name: 'Rapid Therapeutic Science Laboratories, Inc.',
    symbol: 'RTSL',
  },
  {
    value: 'RVIV',
    label: 'RVIV,  Reviv3 Procare Company',
    name: 'Reviv3 Procare Company',
    symbol: 'RVIV',
  },
  {
    value: 'RWI.AS',
    label: 'RWI.AS,  Renewi plc',
    name: 'Renewi plc',
    symbol: 'RWI.AS',
  },
  {
    value: 'RYES',
    label: 'RYES,  Rise Gold Corp.',
    name: 'Rise Gold Corp.',
    symbol: 'RYES',
  },
  {
    value: 'RY-PT',
    label: 'RY-PT,  Royal Bank of Canada',
    name: 'Royal Bank of Canada',
    symbol: 'RY-PT',
  },
  {
    value: 'RZLT',
    label: 'RZLT,  Rezolute, Inc.',
    name: 'Rezolute, Inc.',
    symbol: 'RZLT',
  },
  {
    value: 'SABRP',
    label: 'SABRP,  Sabre Corporation',
    name: 'Sabre Corporation',
    symbol: 'SABRP',
  },
  {
    value: 'SBEV',
    label: 'SBEV,  Splash Beverage Group, Inc.',
    name: 'Splash Beverage Group, Inc.',
    symbol: 'SBEV',
  },
  {
    value: 'SBFM',
    label: 'SBFM,  Sunshine Biopharma, Inc.',
    name: 'Sunshine Biopharma, Inc.',
    symbol: 'SBFM',
  },
  {
    value: 'SB-PD',
    label: 'SB-PD,  Safe Bulkers, Inc.',
    name: 'Safe Bulkers, Inc.',
    symbol: 'SB-PD',
  },
  {
    value: 'SBSAA',
    label: 'SBSAA,  Spanish Broadcasting System, Inc.',
    name: 'Spanish Broadcasting System, Inc.',
    symbol: 'SBSAA',
  },
  {
    value: 'SBYSF',
    label: 'SBYSF,  Sibanye Stillwater Limited',
    name: 'Sibanye Stillwater Limited',
    symbol: 'SBYSF',
  },
  {
    value: 'SCHW-PD',
    label: 'SCHW-PD,  The Charles Schwab Corporation',
    name: 'The Charles Schwab Corporation',
    symbol: 'SCHW-PD',
  },
  {
    value: 'SCIA',
    label: 'SCIA,  SCI Engineered Materials, Inc.',
    name: 'SCI Engineered Materials, Inc.',
    symbol: 'SCIA',
  },
  {
    value: 'SCND',
    label: 'SCND,  Scientific Industries, Inc.',
    name: 'Scientific Industries, Inc.',
    symbol: 'SCND',
  },
  {
    value: 'SCTC',
    label: 'SCTC,  ScoutCam Inc.',
    name: 'ScoutCam Inc.',
    symbol: 'SCTC',
  },
  {
    value: 'SCTY',
    label: 'SCTY,  Monitronics International, Inc.',
    name: 'Monitronics International, Inc.',
    symbol: 'SCTY',
  },
  {
    value: 'SCYYF',
    label: 'SCYYF,  Scandium International Mining Corp.',
    name: 'Scandium International Mining Corp.',
    symbol: 'SCYYF',
  },
  {
    value: 'SDON',
    label: 'SDON,  Sandston Corporation',
    name: 'Sandston Corporation',
    symbol: 'SDON',
  },
  {
    value: 'SDRL.OL',
    label: 'SDRL.OL,  Seadrill Limited',
    name: 'Seadrill Limited',
    symbol: 'SDRL.OL',
  },
  {
    value: 'SDSYA',
    label: 'SDSYA,  South Dakota Soybean Processors, LLC',
    name: 'South Dakota Soybean Processors, LLC',
    symbol: 'SDSYA',
  },
  {
    value: 'SEAV',
    label: 'SEAV,  SEATech Ventures Corp.',
    name: 'SEATech Ventures Corp.',
    symbol: 'SEAV',
  },
  {
    value: 'SENR',
    label: 'SENR,  Strategic Environmental & Energy Resources, Inc.',
    name: 'Strategic Environmental & Energy Resources, Inc.',
    symbol: 'SENR',
  },
  {
    value: 'SFDL',
    label: 'SFDL,  Security Federal Corporation',
    name: 'Security Federal Corporation',
    symbol: 'SFDL',
  },
  {
    value: 'SF-PC',
    label: 'SF-PC,  Stifel Financial Corp.',
    name: 'Stifel Financial Corp.',
    symbol: 'SF-PC',
  },
  {
    value: 'SFRX',
    label: 'SFRX,  Seafarer Exploration Corp.',
    name: 'Seafarer Exploration Corp.',
    symbol: 'SFRX',
  },
  {
    value: 'SGBI',
    label: 'SGBI,  Sangui Biotech International, Inc.',
    name: 'Sangui Biotech International, Inc.',
    symbol: 'SGBI',
  },
  {
    value: 'SGMD',
    label: 'SGMD,  Sugarmade, Inc.',
    name: 'Sugarmade, Inc.',
    symbol: 'SGMD',
  },
  {
    value: 'SGM.DE',
    label: 'SGM.DE,  STMicroelectronics N.V.',
    name: 'STMicroelectronics N.V.',
    symbol: 'SGM.DE',
  },
  {
    value: 'SHMP',
    label: 'SHMP,  NaturalShrimp Incorporated',
    name: 'NaturalShrimp Incorporated',
    symbol: 'SHMP',
  },
  {
    value: 'SHRG',
    label: 'SHRG,  Sharing Services Global Corporation',
    name: 'Sharing Services Global Corporation',
    symbol: 'SHRG',
  },
  {
    value: 'SHVLF',
    label: 'SHVLF,  Starcore International Mines Ltd.',
    name: 'Starcore International Mines Ltd.',
    symbol: 'SHVLF',
  },
  {
    value: 'SHWZ',
    label: 'SHWZ,  Medicine Man Technologies, Inc.',
    name: 'Medicine Man Technologies, Inc.',
    symbol: 'SHWZ',
  },
  {
    value: 'SKAS',
    label: 'SKAS,  Saker Aviation Services, Inc.',
    name: 'Saker Aviation Services, Inc.',
    symbol: 'SKAS',
  },
  {
    value: 'SKKY',
    label: 'SKKY,  Skkynet Cloud Systems, Inc.',
    name: 'Skkynet Cloud Systems, Inc.',
    symbol: 'SKKY',
  },
  {
    value: 'SKVI',
    label: 'SKVI,  Skinvisible, Inc.',
    name: 'Skinvisible, Inc.',
    symbol: 'SKVI',
  },
  {
    value: 'SLGD',
    label: "SLGD,  Scott's Liquid Gold-Inc.",
    name: "Scott's Liquid Gold-Inc.",
    symbol: 'SLGD',
  },
  {
    value: 'SLN',
    label: 'SLN,  Silence Therapeutics plc',
    name: 'Silence Therapeutics plc',
    symbol: 'SLN',
  },
  {
    value: 'SMGI',
    label: 'SMGI,  SMG Industries Inc.',
    name: 'SMG Industries Inc.',
    symbol: 'SMGI',
  },
  {
    value: 'SMID',
    label: 'SMID,  Smith-Midland Corporation',
    name: 'Smith-Midland Corporation',
    symbol: 'SMID',
  },
  {
    value: 'SMLR',
    label: 'SMLR,  Semler Scientific, Inc.',
    name: 'Semler Scientific, Inc.',
    symbol: 'SMLR',
  },
  {
    value: 'SMME',
    label: 'SMME,  SmartMetric, Inc.',
    name: 'SmartMetric, Inc.',
    symbol: 'SMME',
  },
  {
    value: 'SMTI',
    label: 'SMTI,  Sanara MedTech Inc.',
    name: 'Sanara MedTech Inc.',
    symbol: 'SMTI',
  },
  {
    value: 'SNDD',
    label: 'SNDD,  RedHawk Holdings Corp.',
    name: 'RedHawk Holdings Corp.',
    symbol: 'SNDD',
  },
  {
    value: 'SNNF',
    label: 'SNNF,  Seneca Financial Corp.',
    name: 'Seneca Financial Corp.',
    symbol: 'SNNF',
  },
  {
    value: 'SNPW',
    label: 'SNPW,  Sun Pacific Holding Corp.',
    name: 'Sun Pacific Holding Corp.',
    symbol: 'SNPW',
  },
  {
    value: 'SNRG',
    label: 'SNRG,  SusGlobal Energy Corp.',
    name: 'SusGlobal Energy Corp.',
    symbol: 'SNRG',
  },
  {
    value: 'SNRHU',
    label: 'SNRHU,  Senior Connect Acquisition Corp. I',
    name: 'Senior Connect Acquisition Corp. I',
    symbol: 'SNRHU',
  },
  {
    value: 'SNTW',
    label: 'SNTW,  Summit Networks Inc.',
    name: 'Summit Networks Inc.',
    symbol: 'SNTW',
  },
  {
    value: 'SNV-PD',
    label: 'SNV-PD,  Synovus Financial Corp.',
    name: 'Synovus Financial Corp.',
    symbol: 'SNV-PD',
  },
  {
    value: 'SNWV',
    label: 'SNWV,  SANUWAVE Health, Inc.',
    name: 'SANUWAVE Health, Inc.',
    symbol: 'SNWV',
  },
  {
    value: 'SOBR',
    label: 'SOBR,  SOBR Safe, Inc.',
    name: 'SOBR Safe, Inc.',
    symbol: 'SOBR',
  },
  {
    value: 'SOTK',
    label: 'SOTK,  Sono-Tek Corporation',
    name: 'Sono-Tek Corporation',
    symbol: 'SOTK',
  },
  {
    value: 'SPDC',
    label: 'SPDC,  Speed Commerce, Inc.',
    name: 'Speed Commerce, Inc.',
    symbol: 'SPDC',
  },
  {
    value: 'SPG-PJ',
    label: 'SPG-PJ,  Simon Property Group, Inc.',
    name: 'Simon Property Group, Inc.',
    symbol: 'SPG-PJ',
  },
  {
    value: 'SHLS',
    label: 'SHLS,  Shoals Technologies Group, Inc.',
    name: 'Shoals Technologies Group, Inc.',
    symbol: 'SHLS',
  },
  {
    value: 'SPLP-PA',
    label: 'SPLP-PA,  Steel Partners Holdings L.P.',
    name: 'Steel Partners Holdings L.P.',
    symbol: 'SPLP-PA',
  },
  {
    value: 'SPND',
    label: 'SPND,  Spindletop Oil & Gas Co.',
    name: 'Spindletop Oil & Gas Co.',
    symbol: 'SPND',
  },
  {
    value: 'SPRS',
    label: 'SPRS,  Surge Components, Inc.',
    name: 'Surge Components, Inc.',
    symbol: 'SPRS',
  },
  {
    value: 'SPTJF',
    label: 'SPTJF,  Sinopec Shanghai Petrochemical Company Limited',
    name: 'Sinopec Shanghai Petrochemical Company Limited',
    symbol: 'SPTJF',
  },
  {
    value: 'SPYR',
    label: 'SPYR,  SPYR, Inc.',
    name: 'SPYR, Inc.',
    symbol: 'SPYR',
  },
  {
    value: 'SRGZ',
    label: 'SRGZ,  Star Gold Corp.',
    name: 'Star Gold Corp.',
    symbol: 'SRGZ',
  },
  {
    value: 'SR-PA',
    label: 'SR-PA,  Spire Inc.',
    name: 'Spire Inc.',
    symbol: 'SR-PA',
  },
  {
    value: 'SRRE',
    label: 'SRRE,  Sunrise Real Estate Group, Inc.',
    name: 'Sunrise Real Estate Group, Inc.',
    symbol: 'SRRE',
  },
  {
    value: 'SRSG',
    label: 'SRSG,  Spirits Time International, Inc.',
    name: 'Spirits Time International, Inc.',
    symbol: 'SRSG',
  },
  {
    value: 'SRUS',
    label: 'SRUS,  Stratus Capital Corp.',
    name: 'Stratus Capital Corp.',
    symbol: 'SRUS',
  },
  {
    value: 'SSBP',
    label: 'SSBP,  SSB Bancorp, Inc.',
    name: 'SSB Bancorp, Inc.',
    symbol: 'SSBP',
  },
  {
    value: 'SSOK',
    label: 'SSOK,  Sunstock, Inc.',
    name: 'Sunstock, Inc.',
    symbol: 'SSOK',
  },
  {
    value: 'S&SPOWER.NS',
    label: 'S&SPOWER.NS,  S&S Power Switchgear Limited',
    name: 'S&S Power Switchgear Limited',
    symbol: 'S&SPOWER.NS',
  },
  {
    value: 'STCB',
    label: 'STCB,  Starco Brands, Inc.',
    name: 'Starco Brands, Inc.',
    symbol: 'STCB',
  },
  {
    value: 'STCC',
    label: 'STCC,  Sterling Consolidated Corp.',
    name: 'Sterling Consolidated Corp.',
    symbol: 'STCC',
  },
  {
    value: 'STLY',
    label: 'STLY,  HG Holdings, Inc.',
    name: 'HG Holdings, Inc.',
    symbol: 'STLY',
  },
  {
    value: 'STMH',
    label: 'STMH,  Stem Holdings, Inc.',
    name: 'Stem Holdings, Inc.',
    symbol: 'STMH',
  },
  {
    value: 'STT-PD',
    label: 'STT-PD,  State Street Corporation',
    name: 'State Street Corporation',
    symbol: 'STT-PD',
  },
  {
    value: 'STT-PG',
    label: 'STT-PG,  State Street Corporation',
    name: 'State Street Corporation',
    symbol: 'STT-PG',
  },
  {
    value: 'SUIC',
    label: 'SUIC,  Sino United Worldwide Consolidated Ltd.',
    name: 'Sino United Worldwide Consolidated Ltd.',
    symbol: 'SUIC',
  },
  {
    value: 'SUME',
    label: 'SUME,  Summer Energy Holdings, Inc.',
    name: 'Summer Energy Holdings, Inc.',
    symbol: 'SUME',
  },
  {
    value: 'SUND',
    label: 'SUND,  Sundance Strategies, Inc.',
    name: 'Sundance Strategies, Inc.',
    symbol: 'SUND',
  },
  {
    value: 'SURG',
    label: 'SURG,  SurgePays, Inc.',
    name: 'SurgePays, Inc.',
    symbol: 'SURG',
  },
  {
    value: 'SUWN',
    label: 'SUWN,  Sunwin Stevia International, Inc.',
    name: 'Sunwin Stevia International, Inc.',
    symbol: 'SUWN',
  },
  {
    value: 'SVBL',
    label: 'SVBL,  Silver Bull Resources, Inc.',
    name: 'Silver Bull Resources, Inc.',
    symbol: 'SVBL',
  },
  {
    value: 'SYF-PA',
    label: 'SYF-PA,  Synchrony Financial',
    name: 'Synchrony Financial',
    symbol: 'SYF-PA',
  },
  {
    value: 'SYSX',
    label: 'SYSX,  Sysorex, Inc.',
    name: 'Sysorex, Inc.',
    symbol: 'SYSX',
  },
  {
    value: 'TAUG',
    label: 'TAUG,  Tauriga Sciences, Inc.',
    name: 'Tauriga Sciences, Inc.',
    symbol: 'TAUG',
  },
  {
    value: 'TBBA',
    label: 'TBBA,  TEB Bancorp, Inc',
    name: 'TEB Bancorp, Inc',
    symbol: 'TBBA',
  },
  {
    value: 'TBTC',
    label: 'TBTC,  Table Trac, Inc.',
    name: 'Table Trac, Inc.',
    symbol: 'TBTC',
  },
  {
    value: 'TCMFF',
    label: 'TCMFF,  Telecom Argentina S.A.',
    name: 'Telecom Argentina S.A.',
    symbol: 'TCMFF',
  },
  {
    value: 'TDNT',
    label: 'TDNT,  Trident Brands Incorporated',
    name: 'Trident Brands Incorporated',
    symbol: 'TDNT',
  },
  {
    value: 'TEGY',
    label: 'TEGY,  TransAct Energy Corp.',
    name: 'TransAct Energy Corp.',
    symbol: 'TEGY',
  },
  {
    value: 'TFC-PR',
    label: 'TFC-PR,  Truist Financial Corporation',
    name: 'Truist Financial Corporation',
    symbol: 'TFC-PR',
  },
  {
    value: 'TGAN',
    label: 'TGAN,  Transphorm, Inc.',
    name: 'Transphorm, Inc.',
    symbol: 'TGAN',
  },
  {
    value: 'TGHI',
    label: 'TGHI,  Touchpoint Group Holdings, Inc.',
    name: 'Touchpoint Group Holdings, Inc.',
    symbol: 'TGHI',
  },
  {
    value: 'TGLO',
    label: 'TGLO,  theglobe.com, inc.',
    name: 'theglobe.com, inc.',
    symbol: 'TGLO',
  },
  {
    value: 'THCT',
    label: 'THCT,  THC Therapeutics, Inc.',
    name: 'THC Therapeutics, Inc.',
    symbol: 'THCT',
  },
  {
    value: 'THMG',
    label: 'THMG,  Thunder Mountain Gold, Inc.',
    name: 'Thunder Mountain Gold, Inc.',
    symbol: 'THMG',
  },
  {
    value: 'TIKK',
    label: 'TIKK,  Tel-Instrument Electronics Corp.',
    name: 'Tel-Instrument Electronics Corp.',
    symbol: 'TIKK',
  },
  {
    value: 'TIMB',
    label: 'TIMB,  TIM S.A.',
    name: 'TIM S.A.',
    symbol: 'TIMB',
  },
  {
    value: 'TINV',
    label: 'TINV,  Tiga Acquisition Corp.',
    name: 'Tiga Acquisition Corp.',
    symbol: 'TINV',
  },
  {
    value: 'TKLS',
    label: 'TKLS,  Trutankless, Inc.',
    name: 'Trutankless, Inc.',
    symbol: 'TKLS',
  },
  {
    value: 'TKOI',
    label: 'TKOI,  Telkonet, Inc.',
    name: 'Telkonet, Inc.',
    symbol: 'TKOI',
  },
  {
    value: 'TLCC',
    label: 'TLCC,  Twinlab Consolidated Holdings, Inc.',
    name: 'Twinlab Consolidated Holdings, Inc.',
    symbol: 'TLCC',
  },
  {
    value: 'TLLYF',
    label: 'TLLYF,  Trilogy International Partners Inc.',
    name: 'Trilogy International Partners Inc.',
    symbol: 'TLLYF',
  },
  {
    value: 'TLOG',
    label: 'TLOG,  TetraLogic Pharmaceuticals Corporation',
    name: 'TetraLogic Pharmaceuticals Corporation',
    symbol: 'TLOG',
  },
  {
    value: 'TLRS',
    label: 'TLRS,  Timberline Resources Corporation',
    name: 'Timberline Resources Corporation',
    symbol: 'TLRS',
  },
  {
    value: 'TLSS',
    label: 'TLSS,  Transportation and Logistics Systems, Inc.',
    name: 'Transportation and Logistics Systems, Inc.',
    symbol: 'TLSS',
  },
  {
    value: 'TMGI',
    label: 'TMGI,  The Marquie Group, Inc.',
    name: 'The Marquie Group, Inc.',
    symbol: 'TMGI',
  },
  {
    value: 'TMRC',
    label: 'TMRC,  Texas Mineral Resources Corp.',
    name: 'Texas Mineral Resources Corp.',
    symbol: 'TMRC',
  },
  {
    value: 'TMRR',
    label: 'TMRR,  Temir Corp.',
    name: 'Temir Corp.',
    symbol: 'TMRR',
  },
  {
    value: 'TNLX',
    label: 'TNLX,  Trans-Lux Corporation',
    name: 'Trans-Lux Corporation',
    symbol: 'TNLX',
  },
  {
    value: 'TNRG',
    label: 'TNRG,  Thunder Energies Corporation',
    name: 'Thunder Energies Corporation',
    symbol: 'TNRG',
  },
  {
    value: 'TOFB',
    label: 'TOFB,  Tofutti Brands Inc.',
    name: 'Tofutti Brands Inc.',
    symbol: 'TOFB',
  },
  {
    value: 'TOMDF',
    label: 'TOMDF,  Todos Medical Ltd.',
    name: 'Todos Medical Ltd.',
    symbol: 'TOMDF',
  },
  {
    value: 'TOMZ',
    label: 'TOMZ,  TOMI Environmental Solutions, Inc.',
    name: 'TOMI Environmental Solutions, Inc.',
    symbol: 'TOMZ',
  },
  {
    value: 'TORM',
    label: 'TORM,  TOR Minerals International, Inc.',
    name: 'TOR Minerals International, Inc.',
    symbol: 'TORM',
  },
  {
    value: 'T-PC',
    label: 'T-PC,  AT&T Inc.',
    name: 'AT&T Inc.',
    symbol: 'T-PC',
  },
  {
    value: 'TPCS',
    label: 'TPCS,  TechPrecision Corporation',
    name: 'TechPrecision Corporation',
    symbol: 'TPCS',
  },
  {
    value: 'TPTW',
    label: 'TPTW,  TPT Global Tech, Inc.',
    name: 'TPT Global Tech, Inc.',
    symbol: 'TPTW',
  },
  {
    value: 'TRCK',
    label: 'TRCK,  Track Group, Inc.',
    name: 'Track Group, Inc.',
    symbol: 'TRCK',
  },
  {
    value: 'TREN',
    label: 'TREN,  Trend Innovations Holding Inc.',
    name: 'Trend Innovations Holding Inc.',
    symbol: 'TREN',
  },
  {
    value: 'TRIQ',
    label: 'TRIQ,  TraqIQ, Inc.',
    name: 'TraqIQ, Inc.',
    symbol: 'TRIQ',
  },
  {
    value: 'TRTN-PD',
    label: 'TRTN-PD,  Triton International Limited',
    name: 'Triton International Limited',
    symbol: 'TRTN-PD',
  },
  {
    value: 'TSOI',
    label: 'TSOI,  Therapeutic Solutions International, Inc.',
    name: 'Therapeutic Solutions International, Inc.',
    symbol: 'TSOI',
  },
  {
    value: 'TSSI',
    label: 'TSSI,  TSS, Inc.',
    name: 'TSS, Inc.',
    symbol: 'TSSI',
  },
  {
    value: 'TTCM',
    label: 'TTCM,  Tautachrome, Inc.',
    name: 'Tautachrome, Inc.',
    symbol: 'TTCM',
  },
  {
    value: 'TWER',
    label: 'TWER,  Towerstream Corporation',
    name: 'Towerstream Corporation',
    symbol: 'TWER',
  },
  {
    value: 'TWND-UN',
    label: 'TWND-UN,  Tailwind Acquisition Corp.',
    name: 'Tailwind Acquisition Corp.',
    symbol: 'TWND-UN',
  },
  {
    value: 'TWOH',
    label: 'TWOH,  Two Hands Corporation',
    name: 'Two Hands Corporation',
    symbol: 'TWOH',
  },
  {
    value: 'TWO-PB',
    label: 'TWO-PB,  Two Harbors Investment Corp.',
    name: 'Two Harbors Investment Corp.',
    symbol: 'TWO-PB',
  },
  {
    value: 'UCLE',
    label: 'UCLE,  US Nuclear Corp.',
    name: 'US Nuclear Corp.',
    symbol: 'UCLE',
  },
  {
    value: 'UEEC',
    label: 'UEEC,  United Health Products, Inc.',
    name: 'United Health Products, Inc.',
    symbol: 'UEEC',
  },
  {
    value: 'UEPEP',
    label: 'UEPEP,  Union Electric Company',
    name: 'Union Electric Company',
    symbol: 'UEPEP',
  },
  {
    value: 'ULNV',
    label: 'ULNV,  Porter Holding International, Inc.',
    name: 'Porter Holding International, Inc.',
    symbol: 'ULNV',
  },
  {
    value: 'UMHL',
    label: 'UMHL,  Umatrin Holding Limited',
    name: 'Umatrin Holding Limited',
    symbol: 'UMHL',
  },
  {
    value: 'UMH-PD',
    label: 'UMH-PD,  UMH Properties, Inc.',
    name: 'UMH Properties, Inc.',
    symbol: 'UMH-PD',
  },
  {
    value: 'UNIR',
    label: 'UNIR,  Uniroyal Global Engineered Products, Inc.',
    name: 'Uniroyal Global Engineered Products, Inc.',
    symbol: 'UNIR',
  },
  {
    value: 'UPDC',
    label: 'UPDC,  UPD Holding Corp.',
    name: 'UPD Holding Corp.',
    symbol: 'UPDC',
  },
  {
    value: 'URYL',
    label: 'URYL,  United Royale Holdings Corp.',
    name: 'United Royale Holdings Corp.',
    symbol: 'URYL',
  },
  {
    value: 'USAQ',
    label: 'USAQ,  QHSLab, Inc.',
    name: 'QHSLab, Inc.',
    symbol: 'USAQ',
  },
  {
    value: 'USBL',
    label: 'USBL,  United States Basketball League, Inc.',
    name: 'United States Basketball League, Inc.',
    symbol: 'USBL',
  },
  {
    value: 'USB-PP',
    label: 'USB-PP,  U.S. Bancorp',
    name: 'U.S. Bancorp',
    symbol: 'USB-PP',
  },
  {
    value: 'USDR',
    label: 'USDR,  UAS Drone Corp.',
    name: 'UAS Drone Corp.',
    symbol: 'USDR',
  },
  {
    value: 'USLG',
    label: 'USLG,  US Lighting Group, Inc.',
    name: 'US Lighting Group, Inc.',
    symbol: 'USLG',
  },
  {
    value: 'USNU',
    label: 'USNU,  U.S. NeuroSurgical Holdings, Inc.',
    name: 'U.S. NeuroSurgical Holdings, Inc.',
    symbol: 'USNU',
  },
  {
    value: 'UTGN',
    label: 'UTGN,  UTG, Inc.',
    name: 'UTG, Inc.',
    symbol: 'UTGN',
  },
  {
    value: 'UWHR',
    label: 'UWHR,  Uwharrie Capital Corp',
    name: 'Uwharrie Capital Corp',
    symbol: 'UWHR',
  },
  {
    value: 'VABK',
    label: 'VABK,  Virginia National Bankshares Corporation',
    name: 'Virginia National Bankshares Corporation',
    symbol: 'VABK',
  },
  {
    value: 'VASO',
    label: 'VASO,  Vaso Corporation',
    name: 'Vaso Corporation',
    symbol: 'VASO',
  },
  {
    value: 'VBHI',
    label: 'VBHI,  Verde Bio Holdings, Inc.',
    name: 'Verde Bio Holdings, Inc.',
    symbol: 'VBHI',
  },
  {
    value: 'VBIX',
    label: 'VBIX,  Viewbix Inc.',
    name: 'Viewbix Inc.',
    symbol: 'VBIX',
  },
  {
    value: 'VEII',
    label: 'VEII,  Value Exchange International, Inc.',
    name: 'Value Exchange International, Inc.',
    symbol: 'VEII',
  },
  {
    value: 'VFRM',
    label: 'VFRM,  Veritas Farms, Inc.',
    name: 'Veritas Farms, Inc.',
    symbol: 'VFRM',
  },
  {
    value: 'VIDE',
    label: 'VIDE,  Video Display Corporation',
    name: 'Video Display Corporation',
    symbol: 'VIDE',
  },
  {
    value: 'VINO',
    label: 'VINO,  Gaucho Group Holdings, Inc.',
    name: 'Gaucho Group Holdings, Inc.',
    symbol: 'VINO',
  },
  {
    value: 'VISM',
    label: 'VISM,  Visium Technologies, Inc.',
    name: 'Visium Technologies, Inc.',
    symbol: 'VISM',
  },
  {
    value: 'VIVC',
    label: 'VIVC,  Vivic Corp.',
    name: 'Vivic Corp.',
    symbol: 'VIVC',
  },
  {
    value: 'VKIN',
    label: 'VKIN,  Viking Energy Group, Inc.',
    name: 'Viking Energy Group, Inc.',
    symbol: 'VKIN',
  },
  {
    value: 'VMAR',
    label: 'VMAR,  Vision Marine Technologies Inc.',
    name: 'Vision Marine Technologies Inc.',
    symbol: 'VMAR',
  },
  {
    value: 'VNO-PL',
    label: 'VNO-PL,  Vornado Realty Trust',
    name: 'Vornado Realty Trust',
    symbol: 'VNO-PL',
  },
  {
    value: 'VNO-PM',
    label: 'VNO-PM,  Vornado Realty Trust',
    name: 'Vornado Realty Trust',
    symbol: 'VNO-PM',
  },
  {
    value: 'VNUE',
    label: 'VNUE,  VNUE, Inc.',
    name: 'VNUE, Inc.',
    symbol: 'VNUE',
  },
  {
    value: 'VOW.DE',
    label: 'VOW.DE,  Volkswagen AG',
    name: 'Volkswagen AG',
    symbol: 'VOW.DE',
  },
  {
    value: 'VOYA-PB',
    label: 'VOYA-PB,  Voya Financial, Inc.',
    name: 'Voya Financial, Inc.',
    symbol: 'VOYA-PB',
  },
  {
    value: 'VPLM',
    label: 'VPLM,  Voip-Pal.com Inc.',
    name: 'Voip-Pal.com Inc.',
    symbol: 'VPLM',
  },
  {
    value: 'VPRB',
    label: 'VPRB,  VPR Brands, LP',
    name: 'VPR Brands, LP',
    symbol: 'VPRB',
  },
  {
    value: 'VRDR',
    label: 'VRDR,  Verde Resources, Inc.',
    name: 'Verde Resources, Inc.',
    symbol: 'VRDR',
  },
  {
    value: 'VRTB',
    label: 'VRTB,  Vestin Realty Mortgage II, Inc.',
    name: 'Vestin Realty Mortgage II, Inc.',
    symbol: 'VRTB',
  },
  {
    value: 'VRTC',
    label: 'VRTC,  Veritec, Inc.',
    name: 'Veritec, Inc.',
    symbol: 'VRTC',
  },
  {
    value: 'VRUS',
    label: 'VRUS,  Verus International, Inc.',
    name: 'Verus International, Inc.',
    symbol: 'VRUS',
  },
  {
    value: 'VRVR',
    label: 'VRVR,  Virtual Interactive Technologies Corp.',
    name: 'Virtual Interactive Technologies Corp.',
    symbol: 'VRVR',
  },
  {
    value: 'VSBC',
    label: 'VSBC,  VitaSpring Biomedical Co. Ltd.',
    name: 'VitaSpring Biomedical Co. Ltd.',
    symbol: 'VSBC',
  },
  {
    value: 'VTDRF',
    label: 'VTDRF,  Vantage Drilling International',
    name: 'Vantage Drilling International',
    symbol: 'VTDRF',
  },
  {
    value: 'VYCO',
    label: 'VYCO,  Vycor Medical, Inc.',
    name: 'Vycor Medical, Inc.',
    symbol: 'VYCO',
  },
  {
    value: 'VYEY',
    label: 'VYEY,  Victory Oilfield Tech, Inc.',
    name: 'Victory Oilfield Tech, Inc.',
    symbol: 'VYEY',
  },
  {
    value: 'VYST',
    label: 'VYST,  Vystar Corporation',
    name: 'Vystar Corporation',
    symbol: 'VYST',
  },
  {
    value: 'WARM',
    label: 'WARM,  Cool Technologies, Inc.',
    name: 'Cool Technologies, Inc.',
    symbol: 'WARM',
  },
  {
    value: 'WAYN',
    label: 'WAYN,  Wayne Savings Bancshares, Inc.',
    name: 'Wayne Savings Bancshares, Inc.',
    symbol: 'WAYN',
  },
  {
    value: 'WBQNL',
    label: 'WBQNL,  Woodbridge Liquidation Trust',
    name: 'Woodbridge Liquidation Trust',
    symbol: 'WBQNL',
  },
  {
    value: 'WCC-PA',
    label: 'WCC-PA,  WESCO International, Inc.',
    name: 'WESCO International, Inc.',
    symbol: 'WCC-PA',
  },
  {
    value: 'WCRS',
    label: 'WCRS,  Western Capital Resources, Inc.',
    name: 'Western Capital Resources, Inc.',
    symbol: 'WCRS',
  },
  {
    value: 'WCUI',
    label: 'WCUI,  Wellness Center USA, Inc.',
    name: 'Wellness Center USA, Inc.',
    symbol: 'WCUI',
  },
  {
    value: 'WDDD',
    label: 'WDDD,  Worlds Inc.',
    name: 'Worlds Inc.',
    symbol: 'WDDD',
  },
  {
    value: 'WDLF',
    label: 'WDLF,  Decentral Life, Inc.',
    name: 'Decentral Life, Inc.',
    symbol: 'WDLF',
  },
  {
    value: 'WESC',
    label: 'WESC,  W&E Source Corp.',
    name: 'W&E Source Corp.',
    symbol: 'WESC',
  },
  {
    value: 'WETG',
    label: 'WETG,  WeTrade Group, Inc.',
    name: 'WeTrade Group, Inc.',
    symbol: 'WETG',
  },
  {
    value: 'WFCF',
    label: 'WFCF,  Where Food Comes From, Inc.',
    name: 'Where Food Comes From, Inc.',
    symbol: 'WFCF',
  },
  {
    value: 'WFC-PZ',
    label: 'WFC-PZ,  Wells Fargo & Company',
    name: 'Wells Fargo & Company',
    symbol: 'WFC-PZ',
  },
  {
    value: 'WHEN',
    label: 'WHEN,  World Health Energy Holdings, Inc.',
    name: 'World Health Energy Holdings, Inc.',
    symbol: 'WHEN',
  },
  {
    value: 'WHSI',
    label: 'WHSI,  Wearable Health Solutions, Inc.',
    name: 'Wearable Health Solutions, Inc.',
    symbol: 'WHSI',
  },
  {
    value: 'WINR',
    label: 'WINR,  Simplicity Esports and Gaming Company',
    name: 'Simplicity Esports and Gaming Company',
    symbol: 'WINR',
  },
  {
    value: 'WLMS',
    label: 'WLMS,  Williams Industrial Services Group Inc.',
    name: 'Williams Industrial Services Group Inc.',
    symbol: 'WLMS',
  },
  {
    value: 'WNDW',
    label: 'WNDW,  SolarWindow Technologies, Inc.',
    name: 'SolarWindow Technologies, Inc.',
    symbol: 'WNDW',
  },
  {
    value: 'WSTRF',
    label: 'WSTRF,  Western Uranium & Vanadium Corp.',
    name: 'Western Uranium & Vanadium Corp.',
    symbol: 'WSTRF',
  },
  {
    value: 'WTNW',
    label: 'WTNW,  Water Now, Inc.',
    name: 'Water Now, Inc.',
    symbol: 'WTNW',
  },
  {
    value: 'XDSL',
    label: 'XDSL,  mPhase Technologies, Inc.',
    name: 'mPhase Technologies, Inc.',
    symbol: 'XDSL',
  },
  {
    value: 'XITO',
    label: 'XITO,  Xenous Holdings, Inc.',
    name: 'Xenous Holdings, Inc.',
    symbol: 'XITO',
  },
  {
    value: 'XSNX',
    label: 'XSNX,  NovAccess Global Inc.',
    name: 'NovAccess Global Inc.',
    symbol: 'XSNX',
  },
  {
    value: 'YCBD-PA',
    label: 'YCBD-PA,  cbdMD, Inc.',
    name: 'cbdMD, Inc.',
    symbol: 'YCBD-PA',
  },
  {
    value: 'YEWB',
    label: 'YEWB,  Yew Bio-Pharm Group, Inc.',
    name: 'Yew Bio-Pharm Group, Inc.',
    symbol: 'YEWB',
  },
  {
    value: 'ZDPY',
    label: 'ZDPY,  Zoned Properties, Inc.',
    name: 'Zoned Properties, Inc.',
    symbol: 'ZDPY',
  },
  {
    value: 'ZEST',
    label: 'ZEST,  Ecoark Holdings, Inc.',
    name: 'Ecoark Holdings, Inc.',
    symbol: 'ZEST',
  },
  {
    value: 'ZIVO',
    label: 'ZIVO,  ZIVO Bioscience, Inc.',
    name: 'ZIVO Bioscience, Inc.',
    symbol: 'ZIVO',
  },
  {
    value: 'ZPAS',
    label: 'ZPAS,  Zoompass Holdings, Inc.',
    name: 'Zoompass Holdings, Inc.',
    symbol: 'ZPAS',
  },
  {
    value: 'ZZLL',
    label: 'ZZLL,  ZZLL Information Technology, Inc.',
    name: 'ZZLL Information Technology, Inc.',
    symbol: 'ZZLL',
  },
  {
    value: 'EZGO',
    label: 'EZGO,  EZGO Technologies Ltd.',
    name: 'EZGO Technologies Ltd.',
    symbol: 'EZGO',
  },
  {
    value: 'HIMS',
    label: 'HIMS,  Hims & Hers Health, Inc.',
    name: 'Hims & Hers Health, Inc.',
    symbol: 'HIMS',
  },
  {
    value: 'LMAOU',
    label: 'LMAOU,  LMF Acquisition Opportunities, Inc.',
    name: 'LMF Acquisition Opportunities, Inc.',
    symbol: 'LMAOU',
  },
  {
    value: 'AGFY',
    label: 'AGFY,  Agrify Corporation',
    name: 'Agrify Corporation',
    symbol: 'AGFY',
  },
  {
    value: 'DHHCU',
    label: 'DHHCU,  DiamondHead Holdings Corp.',
    name: 'DiamondHead Holdings Corp.',
    symbol: 'DHHCU',
  },
  {
    value: 'SSAAU',
    label: 'SSAAU,  Science Strategic Acquisition Corp. Alpha',
    name: 'Science Strategic Acquisition Corp. Alpha',
    symbol: 'SSAAU',
  },
  {
    value: '0004.HK',
    label: '0004.HK,  Wharf (Holdings) Limited',
    name: 'Wharf (Holdings) Limited',
    symbol: '0004.HK',
  },
  {
    value: '0006.HK',
    label: '0006.HK,  Power Assets Holdings Limited',
    name: 'Power Assets Holdings Limited',
    symbol: '0006.HK',
  },
  {
    value: '0007.HK',
    label: '0007.HK,  Wisdom Wealth Resources Investment Holding Group Limited',
    name: 'Wisdom Wealth Resources Investment Holding Group Limited',
    symbol: '0007.HK',
  },
  {
    value: '0008.HK',
    label: '0008.HK,  PCCW Limited',
    name: 'PCCW Limited',
    symbol: '0008.HK',
  },
  {
    value: '0009.HK',
    label: '0009.HK,  Keyne Ltd',
    name: 'Keyne Ltd',
    symbol: '0009.HK',
  },
  {
    value: '0010.HK',
    label: '0010.HK,  Hang Lung Group Limited',
    name: 'Hang Lung Group Limited',
    symbol: '0010.HK',
  },
  {
    value: '0012.HK',
    label: '0012.HK,  Henderson Land Development Company Limited',
    name: 'Henderson Land Development Company Limited',
    symbol: '0012.HK',
  },
  {
    value: '0014.HK',
    label: '0014.HK,  Hysan Development Company Limited',
    name: 'Hysan Development Company Limited',
    symbol: '0014.HK',
  },
  {
    value: '0017.HK',
    label: '0017.HK,  New World Development Company Limited',
    name: 'New World Development Company Limited',
    symbol: '0017.HK',
  },
  {
    value: '0018.HK',
    label: '0018.HK,  Oriental Enterprise Holdings Limited',
    name: 'Oriental Enterprise Holdings Limited',
    symbol: '0018.HK',
  },
  {
    value: '0019.HK',
    label: '0019.HK,  Swire Pacific Limited',
    name: 'Swire Pacific Limited',
    symbol: '0019.HK',
  },
  {
    value: '0021.HK',
    label: '0021.HK,  Great China Holdings (Hong Kong) Limited',
    name: 'Great China Holdings (Hong Kong) Limited',
    symbol: '0021.HK',
  },
  {
    value: '0022.HK',
    label: '0022.HK,  Mexan Limited',
    name: 'Mexan Limited',
    symbol: '0022.HK',
  },
  {
    value: '0023.HK',
    label: '0023.HK,  The Bank of East Asia, Limited',
    name: 'The Bank of East Asia, Limited',
    symbol: '0023.HK',
  },
  {
    value: '0025.HK',
    label: '0025.HK,  Chevalier International Holdings Limited',
    name: 'Chevalier International Holdings Limited',
    symbol: '0025.HK',
  },
  {
    value: '0026.HK',
    label: '0026.HK,  China Motor Bus Company, Limited',
    name: 'China Motor Bus Company, Limited',
    symbol: '0026.HK',
  },
  {
    value: '0028.HK',
    label: '0028.HK,  Tian An China Investments Company Limited',
    name: 'Tian An China Investments Company Limited',
    symbol: '0028.HK',
  },
  {
    value: '0029.HK',
    label: '0029.HK,  Dynamic Holdings Limited',
    name: 'Dynamic Holdings Limited',
    symbol: '0029.HK',
  },
  {
    value: '0031.HK',
    label: '0031.HK,  China Aerospace International Holdings Limited',
    name: 'China Aerospace International Holdings Limited',
    symbol: '0031.HK',
  },
  {
    value: '0032.HK',
    label: '0032.HK,  The Cross-Harbour (Holdings) Limited',
    name: 'The Cross-Harbour (Holdings) Limited',
    symbol: '0032.HK',
  },
  {
    value: '0033.HK',
    label: '0033.HK,  International Genius Company',
    name: 'International Genius Company',
    symbol: '0033.HK',
  },
  {
    value: '0034.HK',
    label: '0034.HK,  Kowloon Development Company Limited',
    name: 'Kowloon Development Company Limited',
    symbol: '0034.HK',
  },
  {
    value: '0035.HK',
    label: '0035.HK,  Far East Consortium International Limited',
    name: 'Far East Consortium International Limited',
    symbol: '0035.HK',
  },
  {
    value: '0036.HK',
    label: '0036.HK,  Far East Holdings International Limited',
    name: 'Far East Holdings International Limited',
    symbol: '0036.HK',
  },
  {
    value: '0037.HK',
    label: '0037.HK,  Far East Hotels and Entertainment Limited',
    name: 'Far East Hotels and Entertainment Limited',
    symbol: '0037.HK',
  },
  {
    value: '0038.HK',
    label: '0038.HK,  First Tractor Company Limited',
    name: 'First Tractor Company Limited',
    symbol: '0038.HK',
  },
  {
    value: '0039.HK',
    label: '0039.HK,  China Beidahuang Industry Group Holdings Limited',
    name: 'China Beidahuang Industry Group Holdings Limited',
    symbol: '0039.HK',
  },
  {
    value: '0040.HK',
    label: '0040.HK,  Gold Peak Technology Group Limited',
    name: 'Gold Peak Technology Group Limited',
    symbol: '0040.HK',
  },
  {
    value: '0041.HK',
    label: '0041.HK,  Great Eagle Holdings Limited',
    name: 'Great Eagle Holdings Limited',
    symbol: '0041.HK',
  },
  {
    value: '0042.HK',
    label: '0042.HK,  Northeast Electric Development Company Limited',
    name: 'Northeast Electric Development Company Limited',
    symbol: '0042.HK',
  },
  {
    value: '0045.HK',
    label: '0045.HK,  The Hongkong and Shanghai Hotels, Limited',
    name: 'The Hongkong and Shanghai Hotels, Limited',
    symbol: '0045.HK',
  },
  {
    value: '0046.HK',
    label: '0046.HK,  Computer And Technologies Holdings Limited',
    name: 'Computer And Technologies Holdings Limited',
    symbol: '0046.HK',
  },
  {
    value: '0048.HK',
    label: '0048.HK,  China Automotive Interior Decoration Holdings Limited',
    name: 'China Automotive Interior Decoration Holdings Limited',
    symbol: '0048.HK',
  },
  {
    value: '0050.HK',
    label: '0050.HK,  Hong Kong Ferry (Holdings) Company Limited',
    name: 'Hong Kong Ferry (Holdings) Company Limited',
    symbol: '0050.HK',
  },
  {
    value: '0051.HK',
    label: '0051.HK,  Harbour Centre Development Limited',
    name: 'Harbour Centre Development Limited',
    symbol: '0051.HK',
  },
  {
    value: '0052.HK',
    label: '0052.HK,  Fairwood Holdings Limited',
    name: 'Fairwood Holdings Limited',
    symbol: '0052.HK',
  },
  {
    value: '0053.HK',
    label: '0053.HK,  Guoco Group Limited',
    name: 'Guoco Group Limited',
    symbol: '0053.HK',
  },
  {
    value: '0055.HK',
    label: '0055.HK,  Neway Group Holdings Limited',
    name: 'Neway Group Holdings Limited',
    symbol: '0055.HK',
  },
  {
    value: '0057.HK',
    label: '0057.HK,  Chen Hsong Holdings Limited',
    name: 'Chen Hsong Holdings Limited',
    symbol: '0057.HK',
  },
  {
    value: '0058.HK',
    label: '0058.HK,  Sunway International Holdings Limited',
    name: 'Sunway International Holdings Limited',
    symbol: '0058.HK',
  },
  {
    value: '0059.HK',
    label: '0059.HK,  Skyfame Realty (Holdings) Limited',
    name: 'Skyfame Realty (Holdings) Limited',
    symbol: '0059.HK',
  },
  {
    value: '0060.HK',
    label: '0060.HK,  Hong Kong Food Investment Holdings Limited',
    name: 'Hong Kong Food Investment Holdings Limited',
    symbol: '0060.HK',
  },
  {
    value: '0061.HK',
    label: '0061.HK,  Green Leader Holdings Group Limited',
    name: 'Green Leader Holdings Group Limited',
    symbol: '0061.HK',
  },
  {
    value: '0062.HK',
    label: '0062.HK,  Transport International Holdings Limited',
    name: 'Transport International Holdings Limited',
    symbol: '0062.HK',
  },
  {
    value: '0063.HK',
    label: '0063.HK,  China Asia Valley Group Limited',
    name: 'China Asia Valley Group Limited',
    symbol: '0063.HK',
  },
  {
    value: '0064.HK',
    label: '0064.HK,  Get Nice Holdings Limited',
    name: 'Get Nice Holdings Limited',
    symbol: '0064.HK',
  },
  {
    value: '0065.HK',
    label: '0065.HK,  Grand Ocean Advanced Resources Company Limited',
    name: 'Grand Ocean Advanced Resources Company Limited',
    symbol: '0065.HK',
  },
  {
    value: '0066.HK',
    label: '0066.HK,  MTR Corporation Limited',
    name: 'MTR Corporation Limited',
    symbol: '0066.HK',
  },
  {
    value: '0069.HK',
    label: '0069.HK,  Shangri-La Asia Limited',
    name: 'Shangri-La Asia Limited',
    symbol: '0069.HK',
  },
  {
    value: '0070.HK',
    label: '0070.HK,  Rich Goldman Holdings Limited',
    name: 'Rich Goldman Holdings Limited',
    symbol: '0070.HK',
  },
  {
    value: '0071.HK',
    label: '0071.HK,  Miramar Hotel and Investment Company, Limited',
    name: 'Miramar Hotel and Investment Company, Limited',
    symbol: '0071.HK',
  },
  {
    value: '0072.HK',
    label: '0072.HK,  Meta Media Holdings Limited',
    name: 'Meta Media Holdings Limited',
    symbol: '0072.HK',
  },
  {
    value: '0073.HK',
    label: '0073.HK,  Asian Citrus Holdings Limited',
    name: 'Asian Citrus Holdings Limited',
    symbol: '0073.HK',
  },
  {
    value: '0075.HK',
    label: '0075.HK,  Y. T. Realty Group Limited',
    name: 'Y. T. Realty Group Limited',
    symbol: '0075.HK',
  },
  {
    value: '0076.HK',
    label: '0076.HK,  Elate Holdings Limited',
    name: 'Elate Holdings Limited',
    symbol: '0076.HK',
  },
  {
    value: '0077.HK',
    label: '0077.HK,  AMS Public Transport Holdings Limited',
    name: 'AMS Public Transport Holdings Limited',
    symbol: '0077.HK',
  },
  {
    value: '0078.HK',
    label: '0078.HK,  Regal Hotels International Holdings Limited',
    name: 'Regal Hotels International Holdings Limited',
    symbol: '0078.HK',
  },
  {
    value: '0079.HK',
    label: '0079.HK,  Century Legend (Holdings) Limited',
    name: 'Century Legend (Holdings) Limited',
    symbol: '0079.HK',
  },
  {
    value: '0080.HK',
    label: '0080.HK,  China New Economy Fund Limited',
    name: 'China New Economy Fund Limited',
    symbol: '0080.HK',
  },
  {
    value: '0081.HK',
    label: '0081.HK,  China Overseas Grand Oceans Group Limited',
    name: 'China Overseas Grand Oceans Group Limited',
    symbol: '0081.HK',
  },
  {
    value: '0082.HK',
    label: '0082.HK,  Crazy Sports Group Limited',
    name: 'Crazy Sports Group Limited',
    symbol: '0082.HK',
  },
  {
    value: '0083.HK',
    label: '0083.HK,  Sino Land Company Limited',
    name: 'Sino Land Company Limited',
    symbol: '0083.HK',
  },
  {
    value: '0084.HK',
    label: '0084.HK,  Stelux Holdings International Limited',
    name: 'Stelux Holdings International Limited',
    symbol: '0084.HK',
  },
  {
    value: '0085.HK',
    label: '0085.HK,  China Electronics Huada Technology Company Limited',
    name: 'China Electronics Huada Technology Company Limited',
    symbol: '0085.HK',
  },
  {
    value: '0086.HK',
    label: '0086.HK,  Sun Hung Kai & Co. Limited',
    name: 'Sun Hung Kai & Co. Limited',
    symbol: '0086.HK',
  },
  {
    value: '0087.HK',
    label: '0087.HK,  Swire Pacific Limited',
    name: 'Swire Pacific Limited',
    symbol: '0087.HK',
  },
  {
    value: '0088.HK',
    label: '0088.HK,  Tai Cheung Holdings Limited',
    name: 'Tai Cheung Holdings Limited',
    symbol: '0088.HK',
  },
  {
    value: '0089.HK',
    label: '0089.HK,  Tai Sang Land Development Limited',
    name: 'Tai Sang Land Development Limited',
    symbol: '0089.HK',
  },
  {
    value: '0090.HK',
    label: '0090.HK,  Puxing Energy Limited',
    name: 'Puxing Energy Limited',
    symbol: '0090.HK',
  },
  {
    value: '0091.HK',
    label: '0091.HK,  Golden Century International Holdings Group Limited',
    name: 'Golden Century International Holdings Group Limited',
    symbol: '0091.HK',
  },
  {
    value: '0092.HK',
    label: '0092.HK,  Champion Technology Holdings Limited',
    name: 'Champion Technology Holdings Limited',
    symbol: '0092.HK',
  },
  {
    value: '0093.HK',
    label: '0093.HK,  Termbray Industries International (Holdings) Limited',
    name: 'Termbray Industries International (Holdings) Limited',
    symbol: '0093.HK',
  },
  {
    value: '0094.HK',
    label: '0094.HK,  Greenheart Group Limited',
    name: 'Greenheart Group Limited',
    symbol: '0094.HK',
  },
  {
    value: '0095.HK',
    label: '0095.HK,  LVGEM (China) Real Estate Investment Company Limited',
    name: 'LVGEM (China) Real Estate Investment Company Limited',
    symbol: '0095.HK',
  },
  {
    value: '0096.HK',
    label: '0096.HK,  Yusei Holdings Limited',
    name: 'Yusei Holdings Limited',
    symbol: '0096.HK',
  },
  {
    value: '0097.HK',
    label: '0097.HK,  Henderson Investment Limited',
    name: 'Henderson Investment Limited',
    symbol: '0097.HK',
  },
  {
    value: '0098.HK',
    label: '0098.HK,  Xingfa Aluminium Holdings Limited',
    name: 'Xingfa Aluminium Holdings Limited',
    symbol: '0098.HK',
  },
  {
    value: '0099.HK',
    label: "0099.HK,  Wong's International Holdings Limited",
    name: "Wong's International Holdings Limited",
    symbol: '0099.HK',
  },
  {
    value: '2160.HK',
    label: '2160.HK,  MicroPort CardioFlow Medtech Corporation',
    name: 'MicroPort CardioFlow Medtech Corporation',
    symbol: '2160.HK',
  },
  {
    value: '1024.HK',
    label: '1024.HK,  Kuaishou Technology',
    name: 'Kuaishou Technology',
    symbol: '1024.HK',
  },
  {
    value: '2170.HK',
    label: '2170.HK,  Suzhou Basecare Medical Corporation Limited',
    name: 'Suzhou Basecare Medical Corporation Limited',
    symbol: '2170.HK',
  },
  {
    value: '0101.HK',
    label: '0101.HK,  Hang Lung Properties Limited',
    name: 'Hang Lung Properties Limited',
    symbol: '0101.HK',
  },
  {
    value: '0102.HK',
    label: '0102.HK,  Summit Ascent Holdings Limited',
    name: 'Summit Ascent Holdings Limited',
    symbol: '0102.HK',
  },
  {
    value: '0103.HK',
    label: '0103.HK,  Shougang Century Holdings Limited',
    name: 'Shougang Century Holdings Limited',
    symbol: '0103.HK',
  },
  {
    value: '0104.HK',
    label: '0104.HK,  Asia Commercial Holdings Limited',
    name: 'Asia Commercial Holdings Limited',
    symbol: '0104.HK',
  },
  {
    value: '0105.HK',
    label: '0105.HK,  Associated International Hotels Limited',
    name: 'Associated International Hotels Limited',
    symbol: '0105.HK',
  },
  {
    value: '0106.HK',
    label: '0106.HK,  Landsea Green Management Limited',
    name: 'Landsea Green Management Limited',
    symbol: '0106.HK',
  },
  {
    value: '0107.HK',
    label: '0107.HK,  Sichuan Expressway Company Limited',
    name: 'Sichuan Expressway Company Limited',
    symbol: '0107.HK',
  },
  {
    value: '0108.HK',
    label: '0108.HK,  GR Properties Limited',
    name: 'GR Properties Limited',
    symbol: '0108.HK',
  },
  {
    value: '0110.HK',
    label: '0110.HK,  China Fortune Holdings Limited',
    name: 'China Fortune Holdings Limited',
    symbol: '0110.HK',
  },
  {
    value: '0111.HK',
    label: '0111.HK,  Cinda International Holdings Limited',
    name: 'Cinda International Holdings Limited',
    symbol: '0111.HK',
  },
  {
    value: '0113.HK',
    label: '0113.HK,  Dickson Concepts (International) Limited',
    name: 'Dickson Concepts (International) Limited',
    symbol: '0113.HK',
  },
  {
    value: '0114.HK',
    label: '0114.HK,  Herald Holdings Limited',
    name: 'Herald Holdings Limited',
    symbol: '0114.HK',
  },
  {
    value: '0116.HK',
    label: '0116.HK,  Chow Sang Sang Holdings International Limited',
    name: 'Chow Sang Sang Holdings International Limited',
    symbol: '0116.HK',
  },
  {
    value: '0117.HK',
    label: '0117.HK,  Tianli Holdings Group Limited',
    name: 'Tianli Holdings Group Limited',
    symbol: '0117.HK',
  },
  {
    value: '0118.HK',
    label: '0118.HK,  Cosmos Machinery Enterprises Limited',
    name: 'Cosmos Machinery Enterprises Limited',
    symbol: '0118.HK',
  },
  {
    value: '0119.HK',
    label: '0119.HK,  Poly Property Group Co., Limited',
    name: 'Poly Property Group Co., Limited',
    symbol: '0119.HK',
  },
  {
    value: '0120.HK',
    label: '0120.HK,  Cosmopolitan International Holdings Limited',
    name: 'Cosmopolitan International Holdings Limited',
    symbol: '0120.HK',
  },
  {
    value: '0122.HK',
    label: '0122.HK,  Crocodile Garments Limited',
    name: 'Crocodile Garments Limited',
    symbol: '0122.HK',
  },
  {
    value: '0123.HK',
    label: '0123.HK,  Yuexiu Property Company Limited',
    name: 'Yuexiu Property Company Limited',
    symbol: '0123.HK',
  },
  {
    value: '0124.HK',
    label: '0124.HK,  Guangdong Land Holdings Limited',
    name: 'Guangdong Land Holdings Limited',
    symbol: '0124.HK',
  },
  {
    value: '0125.HK',
    label: '0125.HK,  Sun Hing Vision Group Holdings Limited',
    name: 'Sun Hing Vision Group Holdings Limited',
    symbol: '0125.HK',
  },
  {
    value: '0126.HK',
    label: '0126.HK,  Carrianna Group Holdings Company Limited',
    name: 'Carrianna Group Holdings Company Limited',
    symbol: '0126.HK',
  },
  {
    value: '0127.HK',
    label: '0127.HK,  Chinese Estates Holdings Limited',
    name: 'Chinese Estates Holdings Limited',
    symbol: '0127.HK',
  },
  {
    value: '0128.HK',
    label: '0128.HK,  ENM Holdings Limited',
    name: 'ENM Holdings Limited',
    symbol: '0128.HK',
  },
  {
    value: '0129.HK',
    label: '0129.HK,  Asia Standard International Group Limited',
    name: 'Asia Standard International Group Limited',
    symbol: '0129.HK',
  },
  {
    value: '0130.HK',
    label: '0130.HK,  Moiselle International Holdings Limited',
    name: 'Moiselle International Holdings Limited',
    symbol: '0130.HK',
  },
  {
    value: '0131.HK',
    label: '0131.HK,  Cheuk Nang (Holdings) Limited',
    name: 'Cheuk Nang (Holdings) Limited',
    symbol: '0131.HK',
  },
  {
    value: '0132.HK',
    label: '0132.HK,  China Investments Holdings Limited',
    name: 'China Investments Holdings Limited',
    symbol: '0132.HK',
  },
  {
    value: '0133.HK',
    label: '0133.HK,  China Merchants China Direct Investments Limited',
    name: 'China Merchants China Direct Investments Limited',
    symbol: '0133.HK',
  },
  {
    value: '0135.HK',
    label: '0135.HK,  Kunlun Energy Company Limited',
    name: 'Kunlun Energy Company Limited',
    symbol: '0135.HK',
  },
  {
    value: '0136.HK',
    label: '0136.HK,  China Ruyi Holdings Limited',
    name: 'China Ruyi Holdings Limited',
    symbol: '0136.HK',
  },
  {
    value: '0137.HK',
    label: '0137.HK,  Jinhui Holdings Company Limited',
    name: 'Jinhui Holdings Company Limited',
    symbol: '0137.HK',
  },
  {
    value: '0138.HK',
    label: '0138.HK,  CCT Fortis Holdings Limited',
    name: 'CCT Fortis Holdings Limited',
    symbol: '0138.HK',
  },
  {
    value: '0139.HK',
    label: '0139.HK,  Central Wealth Group Holdings Limited',
    name: 'Central Wealth Group Holdings Limited',
    symbol: '0139.HK',
  },
  {
    value: '0142.HK',
    label: '0142.HK,  First Pacific Company Limited',
    name: 'First Pacific Company Limited',
    symbol: '0142.HK',
  },
  {
    value: '0144.HK',
    label: '0144.HK,  China Merchants Port Holdings Company Limited',
    name: 'China Merchants Port Holdings Company Limited',
    symbol: '0144.HK',
  },
  {
    value: '0145.HK',
    label: '0145.HK,  CCIAM Future Energy Limited',
    name: 'CCIAM Future Energy Limited',
    symbol: '0145.HK',
  },
  {
    value: '0146.HK',
    label: '0146.HK,  Tai Ping Carpets International Limited',
    name: 'Tai Ping Carpets International Limited',
    symbol: '0146.HK',
  },
  {
    value: '0147.HK',
    label: '0147.HK,  International Business Settlement Holdings Limited',
    name: 'International Business Settlement Holdings Limited',
    symbol: '0147.HK',
  },
  {
    value: '0148.HK',
    label: '0148.HK,  Kingboard Holdings Limited',
    name: 'Kingboard Holdings Limited',
    symbol: '0148.HK',
  },
  {
    value: '0149.HK',
    label: '0149.HK,  China Agri-Products Exchange Limited',
    name: 'China Agri-Products Exchange Limited',
    symbol: '0149.HK',
  },
  {
    value: '0150.HK',
    label: '0150.HK,  Hypebeast Limited',
    name: 'Hypebeast Limited',
    symbol: '0150.HK',
  },
  {
    value: '0151.HK',
    label: '0151.HK,  Want Want China Holdings Limited',
    name: 'Want Want China Holdings Limited',
    symbol: '0151.HK',
  },
  {
    value: '0152.HK',
    label: '0152.HK,  Shenzhen International Holdings Limited',
    name: 'Shenzhen International Holdings Limited',
    symbol: '0152.HK',
  },
  {
    value: '0154.HK',
    label: '0154.HK,  Beijing Enterprises Environment Group Limited',
    name: 'Beijing Enterprises Environment Group Limited',
    symbol: '0154.HK',
  },
  {
    value: '0156.HK',
    label: '0156.HK,  Lippo China Resources Limited',
    name: 'Lippo China Resources Limited',
    symbol: '0156.HK',
  },
  {
    value: '0157.HK',
    label: '0157.HK,  Natural Beauty Bio-Technology Limited',
    name: 'Natural Beauty Bio-Technology Limited',
    symbol: '0157.HK',
  },
  {
    value: '0158.HK',
    label: '0158.HK,  Melbourne Enterprises Limited',
    name: 'Melbourne Enterprises Limited',
    symbol: '0158.HK',
  },
  {
    value: '0159.HK',
    label: '0159.HK,  Brockman Mining Limited',
    name: 'Brockman Mining Limited',
    symbol: '0159.HK',
  },
  {
    value: '0160.HK',
    label: '0160.HK,  Hon Kwok Land Investment Company, Limited',
    name: 'Hon Kwok Land Investment Company, Limited',
    symbol: '0160.HK',
  },
  {
    value: '0162.HK',
    label: '0162.HK,  Century Ginwa Retail Holdings Limited',
    name: 'Century Ginwa Retail Holdings Limited',
    symbol: '0162.HK',
  },
  {
    value: '0163.HK',
    label: '0163.HK,  Emperor International Holdings Limited',
    name: 'Emperor International Holdings Limited',
    symbol: '0163.HK',
  },
  {
    value: '0165.HK',
    label: '0165.HK,  China Everbright Limited',
    name: 'China Everbright Limited',
    symbol: '0165.HK',
  },
  {
    value: '0166.HK',
    label: '0166.HK,  New Times Energy Corporation Limited',
    name: 'New Times Energy Corporation Limited',
    symbol: '0166.HK',
  },
  {
    value: '0167.HK',
    label: '0167.HK,  IDT International Limited',
    name: 'IDT International Limited',
    symbol: '0167.HK',
  },
  {
    value: '0168.HK',
    label: '0168.HK,  Tsingtao Brewery Company Limited',
    name: 'Tsingtao Brewery Company Limited',
    symbol: '0168.HK',
  },
  {
    value: '0169.HK',
    label: '0169.HK,  Wanda Hotel Development Company Limited',
    name: 'Wanda Hotel Development Company Limited',
    symbol: '0169.HK',
  },
  {
    value: '0171.HK',
    label: '0171.HK,  Silver Grant International Holdings Group Limited',
    name: 'Silver Grant International Holdings Group Limited',
    symbol: '0171.HK',
  },
  {
    value: '0173.HK',
    label: '0173.HK,  K. Wah International Holdings Limited',
    name: 'K. Wah International Holdings Limited',
    symbol: '0173.HK',
  },
  {
    value: '0174.HK',
    label: '0174.HK,  Gemini Investments (Holdings) Limited',
    name: 'Gemini Investments (Holdings) Limited',
    symbol: '0174.HK',
  },
  {
    value: '0176.HK',
    label: '0176.HK,  Superactive Group Company Limited',
    name: 'Superactive Group Company Limited',
    symbol: '0176.HK',
  },
  {
    value: '0177.HK',
    label: '0177.HK,  Jiangsu Expressway Company Limited',
    name: 'Jiangsu Expressway Company Limited',
    symbol: '0177.HK',
  },
  {
    value: '0178.HK',
    label: '0178.HK,  Sa Sa International Holdings Limited',
    name: 'Sa Sa International Holdings Limited',
    symbol: '0178.HK',
  },
  {
    value: '0179.HK',
    label: '0179.HK,  Johnson Electric Holdings Limited',
    name: 'Johnson Electric Holdings Limited',
    symbol: '0179.HK',
  },
  {
    value: '0180.HK',
    label: '0180.HK,  Kader Holdings Company Limited',
    name: 'Kader Holdings Company Limited',
    symbol: '0180.HK',
  },
  {
    value: '0181.HK',
    label: '0181.HK,  Fujian Holdings Limited',
    name: 'Fujian Holdings Limited',
    symbol: '0181.HK',
  },
  {
    value: '0182.HK',
    label: '0182.HK,  Concord New Energy Group Limited',
    name: 'Concord New Energy Group Limited',
    symbol: '0182.HK',
  },
  {
    value: '0183.HK',
    label: '0183.HK,  Winfull Group Holdings Limited',
    name: 'Winfull Group Holdings Limited',
    symbol: '0183.HK',
  },
  {
    value: '0184.HK',
    label: '0184.HK,  Keck Seng Investments (Hong Kong) Limited',
    name: 'Keck Seng Investments (Hong Kong) Limited',
    symbol: '0184.HK',
  },
  {
    value: '0185.HK',
    label: '0185.HK,  Zensun Enterprises Limited',
    name: 'Zensun Enterprises Limited',
    symbol: '0185.HK',
  },
  {
    value: '0186.HK',
    label: '0186.HK,  Nimble Holdings Company Limited',
    name: 'Nimble Holdings Company Limited',
    symbol: '0186.HK',
  },
  {
    value: '0187.HK',
    label: '0187.HK,  Beijing Jingcheng Machinery Electric Company Limited',
    name: 'Beijing Jingcheng Machinery Electric Company Limited',
    symbol: '0187.HK',
  },
  {
    value: '0188.HK',
    label: '0188.HK,  Sunwah Kingsway Capital Holdings Limited',
    name: 'Sunwah Kingsway Capital Holdings Limited',
    symbol: '0188.HK',
  },
  {
    value: '0189.HK',
    label: '0189.HK,  Dongyue Group Limited',
    name: 'Dongyue Group Limited',
    symbol: '0189.HK',
  },
  {
    value: '0191.HK',
    label: '0191.HK,  Lai Sun Garment (International) Limited',
    name: 'Lai Sun Garment (International) Limited',
    symbol: '0191.HK',
  },
  {
    value: '0193.HK',
    label: '0193.HK,  Capital Estate Limited',
    name: 'Capital Estate Limited',
    symbol: '0193.HK',
  },
  {
    value: '0194.HK',
    label: '0194.HK,  Liu Chong Hing Investment Limited',
    name: 'Liu Chong Hing Investment Limited',
    symbol: '0194.HK',
  },
  {
    value: '0195.HK',
    label: '0195.HK,  Greentech Technology International Limited',
    name: 'Greentech Technology International Limited',
    symbol: '0195.HK',
  },
  {
    value: '0196.HK',
    label: '0196.HK,  Honghua Group Limited',
    name: 'Honghua Group Limited',
    symbol: '0196.HK',
  },
  {
    value: '0197.HK',
    label: '0197.HK,  Heng Tai Consumables Group Limited',
    name: 'Heng Tai Consumables Group Limited',
    symbol: '0197.HK',
  },
  {
    value: '0199.HK',
    label: '0199.HK,  ITC Properties Group Limited',
    name: 'ITC Properties Group Limited',
    symbol: '0199.HK',
  },
  {
    value: '0200.HK',
    label: '0200.HK,  Melco International Development Limited',
    name: 'Melco International Development Limited',
    symbol: '0200.HK',
  },
  {
    value: '0201.HK',
    label: '0201.HK,  Magnificent Hotel Investments Limited',
    name: 'Magnificent Hotel Investments Limited',
    symbol: '0201.HK',
  },
  {
    value: '0202.HK',
    label: "0202.HK,  EverChina Int'l Holdings Company Limited",
    name: "EverChina Int'l Holdings Company Limited",
    symbol: '0202.HK',
  },
  {
    value: '0204.HK',
    label: '0204.HK,  China Investment Development Limited',
    name: 'China Investment Development Limited',
    symbol: '0204.HK',
  },
  {
    value: '0205.HK',
    label: '0205.HK,  SEEC Media Group Limited',
    name: 'SEEC Media Group Limited',
    symbol: '0205.HK',
  },
  {
    value: '0206.HK',
    label: '0206.HK,  CMIC Ocean En-Tech Holding Co., Ltd.',
    name: 'CMIC Ocean En-Tech Holding Co., Ltd.',
    symbol: '0206.HK',
  },
  {
    value: '0207.HK',
    label: '0207.HK,  Joy City Property Limited',
    name: 'Joy City Property Limited',
    symbol: '0207.HK',
  },
  {
    value: '0209.HK',
    label: '0209.HK,  Winshine Science Company Limited',
    name: 'Winshine Science Company Limited',
    symbol: '0209.HK',
  },
  {
    value: '0210.HK',
    label: '0210.HK,  Daphne International Holdings Limited',
    name: 'Daphne International Holdings Limited',
    symbol: '0210.HK',
  },
  {
    value: '0211.HK',
    label: '0211.HK,  Styland Holdings Limited',
    name: 'Styland Holdings Limited',
    symbol: '0211.HK',
  },
  {
    value: '0212.HK',
    label: '0212.HK,  Nanyang Holdings Limited',
    name: 'Nanyang Holdings Limited',
    symbol: '0212.HK',
  },
  {
    value: '0213.HK',
    label: '0213.HK,  National Electronics Holdings Limited',
    name: 'National Electronics Holdings Limited',
    symbol: '0213.HK',
  },
  {
    value: '0214.HK',
    label: '0214.HK,  Asia Orient Holdings Limited',
    name: 'Asia Orient Holdings Limited',
    symbol: '0214.HK',
  },
  {
    value: '0215.HK',
    label: '0215.HK,  Hutchison Telecommunications Hong Kong Holdings Limited',
    name: 'Hutchison Telecommunications Hong Kong Holdings Limited',
    symbol: '0215.HK',
  },
  {
    value: '0216.HK',
    label: '0216.HK,  Chinney Investments, Limited',
    name: 'Chinney Investments, Limited',
    symbol: '0216.HK',
  },
  {
    value: '0217.HK',
    label: '0217.HK,  China Chengtong Development Group Limited',
    name: 'China Chengtong Development Group Limited',
    symbol: '0217.HK',
  },
  {
    value: '0218.HK',
    label: '0218.HK,  Shenwan Hongyuan (H.K.) Limited',
    name: 'Shenwan Hongyuan (H.K.) Limited',
    symbol: '0218.HK',
  },
  {
    value: '0219.HK',
    label: '0219.HK,  Shun Ho Property Investments Limited',
    name: 'Shun Ho Property Investments Limited',
    symbol: '0219.HK',
  },
  {
    value: '0220.HK',
    label: '0220.HK,  Uni-President China Holdings Ltd',
    name: 'Uni-President China Holdings Ltd',
    symbol: '0220.HK',
  },
  {
    value: '0222.HK',
    label: '0222.HK,  Min Xin Holdings Limited',
    name: 'Min Xin Holdings Limited',
    symbol: '0222.HK',
  },
  {
    value: '0223.HK',
    label: '0223.HK,  Elife Holdings Limited',
    name: 'Elife Holdings Limited',
    symbol: '0223.HK',
  },
  {
    value: '0225.HK',
    label: '0225.HK,  Pokfulam Development Company Limited',
    name: 'Pokfulam Development Company Limited',
    symbol: '0225.HK',
  },
  {
    value: '0226.HK',
    label: '0226.HK,  Lippo Limited',
    name: 'Lippo Limited',
    symbol: '0226.HK',
  },
  {
    value: '0227.HK',
    label: '0227.HK,  First Shanghai Investments Limited',
    name: 'First Shanghai Investments Limited',
    symbol: '0227.HK',
  },
  {
    value: '0228.HK',
    label: '0228.HK,  China Energy Development Holdings Limited',
    name: 'China Energy Development Holdings Limited',
    symbol: '0228.HK',
  },
  {
    value: '0229.HK',
    label: '0229.HK,  Raymond Industrial Limited',
    name: 'Raymond Industrial Limited',
    symbol: '0229.HK',
  },
  {
    value: '0230.HK',
    label: '0230.HK,  Minmetals Land Limited',
    name: 'Minmetals Land Limited',
    symbol: '0230.HK',
  },
  {
    value: '0232.HK',
    label: '0232.HK,  Continental Aerospace Technologies Holding Limited',
    name: 'Continental Aerospace Technologies Holding Limited',
    symbol: '0232.HK',
  },
  {
    value: '0234.HK',
    label: '0234.HK,  New Century Group Hong Kong Limited',
    name: 'New Century Group Hong Kong Limited',
    symbol: '0234.HK',
  },
  {
    value: '0235.HK',
    label: '0235.HK,  China Strategic Holdings Limited',
    name: 'China Strategic Holdings Limited',
    symbol: '0235.HK',
  },
  {
    value: '0236.HK',
    label: '0236.HK,  San Miguel Brewery Hong Kong Limited',
    name: 'San Miguel Brewery Hong Kong Limited',
    symbol: '0236.HK',
  },
  {
    value: '0237.HK',
    label: '0237.HK,  Safety Godown Company, Limited',
    name: 'Safety Godown Company, Limited',
    symbol: '0237.HK',
  },
  {
    value: '0239.HK',
    label: '0239.HK,  Pak Fah Yeow International Limited',
    name: 'Pak Fah Yeow International Limited',
    symbol: '0239.HK',
  },
  {
    value: '0240.HK',
    label: '0240.HK,  Build King Holdings Limited',
    name: 'Build King Holdings Limited',
    symbol: '0240.HK',
  },
  {
    value: '0242.HK',
    label: '0242.HK,  Shun Tak Holdings Limited',
    name: 'Shun Tak Holdings Limited',
    symbol: '0242.HK',
  },
  {
    value: '0243.HK',
    label: '0243.HK,  QPL International Holdings Limited',
    name: 'QPL International Holdings Limited',
    symbol: '0243.HK',
  },
  {
    value: '0244.HK',
    label: '0244.HK,  The Sincere Company, Limited',
    name: 'The Sincere Company, Limited',
    symbol: '0244.HK',
  },
  {
    value: '0248.HK',
    label: '0248.HK,  HKC International Holdings Limited',
    name: 'HKC International Holdings Limited',
    symbol: '0248.HK',
  },
  {
    value: '0251.HK',
    label: '0251.HK,  S E A Holdings Limited',
    name: 'S E A Holdings Limited',
    symbol: '0251.HK',
  },
  {
    value: '0253.HK',
    label: '0253.HK,  Shun Ho Holdings Limited',
    name: 'Shun Ho Holdings Limited',
    symbol: '0253.HK',
  },
  {
    value: '0255.HK',
    label: '0255.HK,  Lung Kee (Bermuda) Holdings Limited',
    name: 'Lung Kee (Bermuda) Holdings Limited',
    symbol: '0255.HK',
  },
  {
    value: '0256.HK',
    label: '0256.HK,  Citychamp Watch & Jewellery Group Limited',
    name: 'Citychamp Watch & Jewellery Group Limited',
    symbol: '0256.HK',
  },
  {
    value: '0257.HK',
    label: '0257.HK,  China Everbright Environment Group Limited',
    name: 'China Everbright Environment Group Limited',
    symbol: '0257.HK',
  },
  {
    value: '0258.HK',
    label: '0258.HK,  Tomson Group Limited',
    name: 'Tomson Group Limited',
    symbol: '0258.HK',
  },
  {
    value: '0259.HK',
    label: '0259.HK,  Yeebo (International Holdings) Limited',
    name: 'Yeebo (International Holdings) Limited',
    symbol: '0259.HK',
  },
  {
    value: '0260.HK',
    label: '0260.HK,  AVIC Joy Holdings (HK) Limited',
    name: 'AVIC Joy Holdings (HK) Limited',
    symbol: '0260.HK',
  },
  {
    value: 'CTAQW',
    label: 'CTAQW,  Carney Technology Acquisition Corp. II',
    name: 'Carney Technology Acquisition Corp. II',
    symbol: 'CTAQW',
  },
  {
    value: '0261.HK',
    label: '0261.HK,  GBA Holdings Limited',
    name: 'GBA Holdings Limited',
    symbol: '0261.HK',
  },
  {
    value: '0262.HK',
    label: '0262.HK,  Deson Development International Holdings Limited',
    name: 'Deson Development International Holdings Limited',
    symbol: '0262.HK',
  },
  {
    value: '0264.HK',
    label: '0264.HK,  China International Development Corporation Limited',
    name: 'China International Development Corporation Limited',
    symbol: '0264.HK',
  },
  {
    value: '0265.HK',
    label: '0265.HK,  Orient Victory Smart urban Services Holding Limited',
    name: 'Orient Victory Smart urban Services Holding Limited',
    symbol: '0265.HK',
  },
  {
    value: '0266.HK',
    label: '0266.HK,  Tian Teck Land Limited',
    name: 'Tian Teck Land Limited',
    symbol: '0266.HK',
  },
  {
    value: '0268.HK',
    label: '0268.HK,  Kingdee International Software Group Company Limited',
    name: 'Kingdee International Software Group Company Limited',
    symbol: '0268.HK',
  },
  {
    value: '0269.HK',
    label: '0269.HK,  China Resources and Transportation Group Limited',
    name: 'China Resources and Transportation Group Limited',
    symbol: '0269.HK',
  },
  {
    value: '0270.HK',
    label: '0270.HK,  Guangdong Investment Limited',
    name: 'Guangdong Investment Limited',
    symbol: '0270.HK',
  },
  {
    value: '0271.HK',
    label: '0271.HK,  Asiasec Properties Limited',
    name: 'Asiasec Properties Limited',
    symbol: '0271.HK',
  },
  {
    value: '0272.HK',
    label: '0272.HK,  Shui On Land Limited',
    name: 'Shui On Land Limited',
    symbol: '0272.HK',
  },
  {
    value: '0273.HK',
    label: '0273.HK,  Mason Group Holdings Limited',
    name: 'Mason Group Holdings Limited',
    symbol: '0273.HK',
  },
  {
    value: '0276.HK',
    label: '0276.HK,  Mongolia Energy Corporation Limited',
    name: 'Mongolia Energy Corporation Limited',
    symbol: '0276.HK',
  },
  {
    value: '0280.HK',
    label: '0280.HK,  King Fook Holdings Limited',
    name: 'King Fook Holdings Limited',
    symbol: '0280.HK',
  },
  {
    value: '0286.HK',
    label: '0286.HK,  Aidigong Maternal & Child Health Limited',
    name: 'Aidigong Maternal & Child Health Limited',
    symbol: '0286.HK',
  },
  {
    value: '0289.HK',
    label: '0289.HK,  Wing On Company International Limited',
    name: 'Wing On Company International Limited',
    symbol: '0289.HK',
  },
  {
    value: '0290.HK',
    label: '0290.HK,  China Fortune Financial Group Limited',
    name: 'China Fortune Financial Group Limited',
    symbol: '0290.HK',
  },
  {
    value: '0292.HK',
    label: '0292.HK,  Asia Standard Hotel Group Limited',
    name: 'Asia Standard Hotel Group Limited',
    symbol: '0292.HK',
  },
  {
    value: '0293.HK',
    label: '0293.HK,  Cathay Pacific Airways Limited',
    name: 'Cathay Pacific Airways Limited',
    symbol: '0293.HK',
  },
  {
    value: '0294.HK',
    label: '0294.HK,  Yangtzekiang Garment Limited',
    name: 'Yangtzekiang Garment Limited',
    symbol: '0294.HK',
  },
  {
    value: '0295.HK',
    label: '0295.HK,  Kong Sun Holdings Limited',
    name: 'Kong Sun Holdings Limited',
    symbol: '0295.HK',
  },
  {
    value: '0296.HK',
    label: '0296.HK,  Emperor Entertainment Hotel Limited',
    name: 'Emperor Entertainment Hotel Limited',
    symbol: '0296.HK',
  },
  {
    value: '0297.HK',
    label: '0297.HK,  Sinofert Holdings Limited',
    name: 'Sinofert Holdings Limited',
    symbol: '0297.HK',
  },
  {
    value: '0298.HK',
    label: "0298.HK,  Chuang's China Investments Limited",
    name: "Chuang's China Investments Limited",
    symbol: '0298.HK',
  },
  {
    value: '0299.HK',
    label: '0299.HK,  Glory Sun Land Group Limited',
    name: 'Glory Sun Land Group Limited',
    symbol: '0299.HK',
  },
  {
    value: '0301.HK',
    label: '0301.HK,  SANVO Fine Chemicals Group Limited',
    name: 'SANVO Fine Chemicals Group Limited',
    symbol: '0301.HK',
  },
  {
    value: '0302.HK',
    label: '0302.HK,  CMGE Technology Group Limited',
    name: 'CMGE Technology Group Limited',
    symbol: '0302.HK',
  },
  {
    value: '0303.HK',
    label: '0303.HK,  Vtech Holdings Limited',
    name: 'Vtech Holdings Limited',
    symbol: '0303.HK',
  },
  {
    value: '0305.HK',
    label: '0305.HK,  Wuling Motors Holdings Limited',
    name: 'Wuling Motors Holdings Limited',
    symbol: '0305.HK',
  },
  {
    value: '0306.HK',
    label: '0306.HK,  Kwoon Chung Bus Holdings Limited',
    name: 'Kwoon Chung Bus Holdings Limited',
    symbol: '0306.HK',
  },
  {
    value: '0308.HK',
    label: '0308.HK,  China Travel International Investment Hong Kong Limited',
    name: 'China Travel International Investment Hong Kong Limited',
    symbol: '0308.HK',
  },
  {
    value: '0309.HK',
    label: '0309.HK,  Xinhua News Media Holdings Limited',
    name: 'Xinhua News Media Holdings Limited',
    symbol: '0309.HK',
  },
  {
    value: '0310.HK',
    label: '0310.HK,  Prosperity Investment Holdings Limited',
    name: 'Prosperity Investment Holdings Limited',
    symbol: '0310.HK',
  },
  {
    value: '0311.HK',
    label: '0311.HK,  Luen Thai Holdings Limited',
    name: 'Luen Thai Holdings Limited',
    symbol: '0311.HK',
  },
  {
    value: '0312.HK',
    label: '0312.HK,  Shirble Department Store Holdings (China) Limited',
    name: 'Shirble Department Store Holdings (China) Limited',
    symbol: '0312.HK',
  },
  {
    value: '0313.HK',
    label: '0313.HK,  Richly Field China Development Limited',
    name: 'Richly Field China Development Limited',
    symbol: '0313.HK',
  },
  {
    value: '0315.HK',
    label: '0315.HK,  SmarTone Telecommunications Holdings Limited',
    name: 'SmarTone Telecommunications Holdings Limited',
    symbol: '0315.HK',
  },
  {
    value: '0316.HK',
    label: '0316.HK,  Orient Overseas (International) Limited',
    name: 'Orient Overseas (International) Limited',
    symbol: '0316.HK',
  },
  {
    value: '0317.HK',
    label:
      '0317.HK,  CSSC Offshore & Marine Engineering (Group) Company Limited',
    name: 'CSSC Offshore & Marine Engineering (Group) Company Limited',
    symbol: '0317.HK',
  },
  {
    value: '0318.HK',
    label: '0318.HK,  Vongroup Limited',
    name: 'Vongroup Limited',
    symbol: '0318.HK',
  },
  {
    value: '0320.HK',
    label: '0320.HK,  Computime Group Limited',
    name: 'Computime Group Limited',
    symbol: '0320.HK',
  },
  {
    value: '0321.HK',
    label: '0321.HK,  Texwinca Holdings Limited',
    name: 'Texwinca Holdings Limited',
    symbol: '0321.HK',
  },
  {
    value: '0322.HK',
    label: '0322.HK,  Tingyi (Cayman Islands) Holding Corp.',
    name: 'Tingyi (Cayman Islands) Holding Corp.',
    symbol: '0322.HK',
  },
  {
    value: '0323.HK',
    label: '0323.HK,  Maanshan Iron & Steel Company Limited',
    name: 'Maanshan Iron & Steel Company Limited',
    symbol: '0323.HK',
  },
  {
    value: '0326.HK',
    label: '0326.HK,  China Star Entertainment Limited',
    name: 'China Star Entertainment Limited',
    symbol: '0326.HK',
  },
  {
    value: '0327.HK',
    label: '0327.HK,  PAX Global Technology Limited',
    name: 'PAX Global Technology Limited',
    symbol: '0327.HK',
  },
  {
    value: '0328.HK',
    label: '0328.HK,  Alco Holdings Limited',
    name: 'Alco Holdings Limited',
    symbol: '0328.HK',
  },
  {
    value: '0329.HK',
    label: '0329.HK,  OCI International Holdings Limited',
    name: 'OCI International Holdings Limited',
    symbol: '0329.HK',
  },
  {
    value: '0330.HK',
    label: '0330.HK,  Esprit Holdings Limited',
    name: 'Esprit Holdings Limited',
    symbol: '0330.HK',
  },
  {
    value: '0331.HK',
    label: '0331.HK,  FSE Lifestyle Services Limited',
    name: 'FSE Lifestyle Services Limited',
    symbol: '0331.HK',
  },
  {
    value: '0332.HK',
    label: '0332.HK,  Yuan Heng Gas Holdings Limited',
    name: 'Yuan Heng Gas Holdings Limited',
    symbol: '0332.HK',
  },
  {
    value: '0333.HK',
    label: '0333.HK,  Top Form International Limited',
    name: 'Top Form International Limited',
    symbol: '0333.HK',
  },
  {
    value: '0334.HK',
    label:
      '0334.HK,  China Display Optoelectronics Technology Holdings Limited',
    name: 'China Display Optoelectronics Technology Holdings Limited',
    symbol: '0334.HK',
  },
  {
    value: '0335.HK',
    label: '0335.HK,  Upbest Group Limited',
    name: 'Upbest Group Limited',
    symbol: '0335.HK',
  },
  {
    value: '0336.HK',
    label: '0336.HK,  Huabao International Holdings Limited',
    name: 'Huabao International Holdings Limited',
    symbol: '0336.HK',
  },
  {
    value: '0337.HK',
    label: '0337.HK,  Greenland Hong Kong Holdings Limited',
    name: 'Greenland Hong Kong Holdings Limited',
    symbol: '0337.HK',
  },
  {
    value: '0338.HK',
    label: '0338.HK,  Sinopec Shanghai Petrochemical Company Limited',
    name: 'Sinopec Shanghai Petrochemical Company Limited',
    symbol: '0338.HK',
  },
  {
    value: '0339.HK',
    label: '0339.HK,  Core Economy Investment Group Limited',
    name: 'Core Economy Investment Group Limited',
    symbol: '0339.HK',
  },
  {
    value: '0340.HK',
    label: '0340.HK,  Tongguan Gold Group Limited',
    name: 'Tongguan Gold Group Limited',
    symbol: '0340.HK',
  },
  {
    value: '0341.HK',
    label: '0341.HK,  Café de Coral Holdings Limited',
    name: 'Café de Coral Holdings Limited',
    symbol: '0341.HK',
  },
  {
    value: '0343.HK',
    label: '0343.HK,  Culturecom Holdings Limited',
    name: 'Culturecom Holdings Limited',
    symbol: '0343.HK',
  },
  {
    value: '0345.HK',
    label: '0345.HK,  Vitasoy International Holdings Limited',
    name: 'Vitasoy International Holdings Limited',
    symbol: '0345.HK',
  },
  {
    value: '0346.HK',
    label: '0346.HK,  Yanchang Petroleum International Limited',
    name: 'Yanchang Petroleum International Limited',
    symbol: '0346.HK',
  },
  {
    value: '0347.HK',
    label: '0347.HK,  Angang Steel Company Limited',
    name: 'Angang Steel Company Limited',
    symbol: '0347.HK',
  },
  {
    value: '0348.HK',
    label: '0348.HK,  China Healthwise Holdings Limited',
    name: 'China Healthwise Holdings Limited',
    symbol: '0348.HK',
  },
  {
    value: '0351.HK',
    label: '0351.HK,  Asia Energy Logistics Group Limited',
    name: 'Asia Energy Logistics Group Limited',
    symbol: '0351.HK',
  },
  {
    value: '0352.HK',
    label: '0352.HK,  Fortune Sun (China) Holdings Limited',
    name: 'Fortune Sun (China) Holdings Limited',
    symbol: '0352.HK',
  },
  {
    value: '0353.HK',
    label: '0353.HK,  Energy International Investments Holdings Limited',
    name: 'Energy International Investments Holdings Limited',
    symbol: '0353.HK',
  },
  {
    value: '0354.HK',
    label: '0354.HK,  Chinasoft International Limited',
    name: 'Chinasoft International Limited',
    symbol: '0354.HK',
  },
  {
    value: '0355.HK',
    label: '0355.HK,  Century City International Holdings Limited',
    name: 'Century City International Holdings Limited',
    symbol: '0355.HK',
  },
  {
    value: '0356.HK',
    label: '0356.HK,  DT Capital Limited',
    name: 'DT Capital Limited',
    symbol: '0356.HK',
  },
  {
    value: '0357.HK',
    label: '0357.HK,  Hainan Meilan International Airport Company Limited',
    name: 'Hainan Meilan International Airport Company Limited',
    symbol: '0357.HK',
  },
  {
    value: '0358.HK',
    label: '0358.HK,  Jiangxi Copper Company Limited',
    name: 'Jiangxi Copper Company Limited',
    symbol: '0358.HK',
  },
  {
    value: '0360.HK',
    label: '0360.HK,  New Focus Auto Tech Holdings Limited',
    name: 'New Focus Auto Tech Holdings Limited',
    symbol: '0360.HK',
  },
  {
    value: '0361.HK',
    label: '0361.HK,  Sino Golf Holdings Limited',
    name: 'Sino Golf Holdings Limited',
    symbol: '0361.HK',
  },
  {
    value: '0362.HK',
    label: '0362.HK,  China Zenith Chemical Group Limited',
    name: 'China Zenith Chemical Group Limited',
    symbol: '0362.HK',
  },
  {
    value: '0363.HK',
    label: '0363.HK,  Shanghai Industrial Holdings Limited',
    name: 'Shanghai Industrial Holdings Limited',
    symbol: '0363.HK',
  },
  {
    value: '0365.HK',
    label: '0365.HK,  Sino ICT Holdings Limited',
    name: 'Sino ICT Holdings Limited',
    symbol: '0365.HK',
  },
  {
    value: '0366.HK',
    label: '0366.HK,  Luks Group (Vietnam Holdings) Company Limited',
    name: 'Luks Group (Vietnam Holdings) Company Limited',
    symbol: '0366.HK',
  },
  {
    value: '0367.HK',
    label: "0367.HK,  Chuang's Consortium International Limited",
    name: "Chuang's Consortium International Limited",
    symbol: '0367.HK',
  },
  {
    value: '0368.HK',
    label: '0368.HK,  Superland Group Holdings Limited',
    name: 'Superland Group Holdings Limited',
    symbol: '0368.HK',
  },
  {
    value: '0369.HK',
    label: '0369.HK,  Wing Tai Properties Limited',
    name: 'Wing Tai Properties Limited',
    symbol: '0369.HK',
  },
  {
    value: '0370.HK',
    label: '0370.HK,  China Best Group Holding Limited',
    name: 'China Best Group Holding Limited',
    symbol: '0370.HK',
  },
  {
    value: '0371.HK',
    label: '0371.HK,  Beijing Enterprises Water Group Limited',
    name: 'Beijing Enterprises Water Group Limited',
    symbol: '0371.HK',
  },
  {
    value: '0372.HK',
    label: '0372.HK,  PT International Development Corporation Limited',
    name: 'PT International Development Corporation Limited',
    symbol: '0372.HK',
  },
  {
    value: '0373.HK',
    label: '0373.HK,  Allied Group Limited',
    name: 'Allied Group Limited',
    symbol: '0373.HK',
  },
  {
    value: '0374.HK',
    label: '0374.HK,  Four Seas Mercantile Holdings Limited',
    name: 'Four Seas Mercantile Holdings Limited',
    symbol: '0374.HK',
  },
  {
    value: '0375.HK',
    label: '0375.HK,  YGM Trading Limited',
    name: 'YGM Trading Limited',
    symbol: '0375.HK',
  },
  {
    value: '0376.HK',
    label: '0376.HK,  Yunfeng Financial Group Limited',
    name: 'Yunfeng Financial Group Limited',
    symbol: '0376.HK',
  },
  {
    value: '0377.HK',
    label: '0377.HK,  China Huajun Group Limited',
    name: 'China Huajun Group Limited',
    symbol: '0377.HK',
  },
  {
    value: '0380.HK',
    label: '0380.HK,  China Pipe Group Limited',
    name: 'China Pipe Group Limited',
    symbol: '0380.HK',
  },
  {
    value: '0382.HK',
    label: '0382.HK,  Edvantage Group Holdings Limited',
    name: 'Edvantage Group Holdings Limited',
    symbol: '0382.HK',
  },
  {
    value: '0383.HK',
    label: '0383.HK,  China Medical & HealthCare Group Limited',
    name: 'China Medical & HealthCare Group Limited',
    symbol: '0383.HK',
  },
  {
    value: '0384.HK',
    label: '0384.HK,  China Gas Holdings Limited',
    name: 'China Gas Holdings Limited',
    symbol: '0384.HK',
  },
  {
    value: '0385.HK',
    label: '0385.HK,  Chinney Alliance Group Limited',
    name: 'Chinney Alliance Group Limited',
    symbol: '0385.HK',
  },
  {
    value: '0387.HK',
    label: '0387.HK,  Leeport (Holdings) Limited',
    name: 'Leeport (Holdings) Limited',
    symbol: '0387.HK',
  },
  {
    value: '0389.HK',
    label: '0389.HK,  China Tontine Wines Group Limited',
    name: 'China Tontine Wines Group Limited',
    symbol: '0389.HK',
  },
  {
    value: '0390.HK',
    label: '0390.HK,  China Railway Group Limited',
    name: 'China Railway Group Limited',
    symbol: '0390.HK',
  },
  {
    value: '0391.HK',
    label: '0391.HK,  Mei Ah Entertainment Group Limited',
    name: 'Mei Ah Entertainment Group Limited',
    symbol: '0391.HK',
  },
  {
    value: '0392.HK',
    label: '0392.HK,  Beijing Enterprises Holdings Limited',
    name: 'Beijing Enterprises Holdings Limited',
    symbol: '0392.HK',
  },
  {
    value: '0393.HK',
    label: '0393.HK,  Glorious Sun Enterprises Limited',
    name: 'Glorious Sun Enterprises Limited',
    symbol: '0393.HK',
  },
  {
    value: '0397.HK',
    label: '0397.HK,  Power Financial Group Limited',
    name: 'Power Financial Group Limited',
    symbol: '0397.HK',
  },
  {
    value: '0398.HK',
    label: '0398.HK,  Oriental Watch Holdings Limited',
    name: 'Oriental Watch Holdings Limited',
    symbol: '0398.HK',
  },
  {
    value: '0399.HK',
    label: '0399.HK,  Innovative Pharmaceutical Biotech Limited',
    name: 'Innovative Pharmaceutical Biotech Limited',
    symbol: '0399.HK',
  },
  {
    value: '0400.HK',
    label: '0400.HK,  Ingdan, Inc.',
    name: 'Ingdan, Inc.',
    symbol: '0400.HK',
  },
  {
    value: '0401.HK',
    label: '0401.HK,  Wanjia Group Holdings Limited',
    name: 'Wanjia Group Holdings Limited',
    symbol: '0401.HK',
  },
  {
    value: '0403.HK',
    label: '0403.HK,  Starlite Holdings Limited',
    name: 'Starlite Holdings Limited',
    symbol: '0403.HK',
  },
  {
    value: '0405.HK',
    label: '0405.HK,  Yuexiu Real Estate Investment Trust',
    name: 'Yuexiu Real Estate Investment Trust',
    symbol: '0405.HK',
  },
  {
    value: '0406.HK',
    label: '0406.HK,  Yau Lee Holdings Limited',
    name: 'Yau Lee Holdings Limited',
    symbol: '0406.HK',
  },
  {
    value: '0408.HK',
    label: "0408.HK,  Yip's Chemical Holdings Limited",
    name: "Yip's Chemical Holdings Limited",
    symbol: '0408.HK',
  },
  {
    value: '0410.HK',
    label: '0410.HK,  SOHO China Limited',
    name: 'SOHO China Limited',
    symbol: '0410.HK',
  },
  {
    value: '0411.HK',
    label: '0411.HK,  Lam Soon (Hong Kong) Limited',
    name: 'Lam Soon (Hong Kong) Limited',
    symbol: '0411.HK',
  },
  {
    value: '0412.HK',
    label: '0412.HK,  Shandong Hi-Speed Holdings Group Limited',
    name: 'Shandong Hi-Speed Holdings Group Limited',
    symbol: '0412.HK',
  },
  {
    value: '0413.HK',
    label: '0413.HK,  South China Holdings Company Limited',
    name: 'South China Holdings Company Limited',
    symbol: '0413.HK',
  },
  {
    value: '0416.HK',
    label: '0416.HK,  Bank of Jinzhou Co., Ltd.',
    name: 'Bank of Jinzhou Co., Ltd.',
    symbol: '0416.HK',
  },
  {
    value: '0417.HK',
    label: '0417.HK,  Tse Sui Luen Jewellery (International) Limited',
    name: 'Tse Sui Luen Jewellery (International) Limited',
    symbol: '0417.HK',
  },
  {
    value: '0418.HK',
    label: '0418.HK,  Founder Holdings Limited',
    name: 'Founder Holdings Limited',
    symbol: '0418.HK',
  },
  {
    value: '0419.HK',
    label: '0419.HK,  Huayi Tencent Entertainment Company Limited',
    name: 'Huayi Tencent Entertainment Company Limited',
    symbol: '0419.HK',
  },
  {
    value: '0420.HK',
    label: '0420.HK,  Fountain Set (Holdings) Limited',
    name: 'Fountain Set (Holdings) Limited',
    symbol: '0420.HK',
  },
  {
    value: '0422.HK',
    label:
      '0422.HK,  Vietnam Manufacturing and Export Processing (Holdings) Limited',
    name: 'Vietnam Manufacturing and Export Processing (Holdings) Limited',
    symbol: '0422.HK',
  },
  {
    value: '0423.HK',
    label: '0423.HK,  Hong Kong Economic Times Holdings Limited',
    name: 'Hong Kong Economic Times Holdings Limited',
    symbol: '0423.HK',
  },
  {
    value: '0425.HK',
    label: '0425.HK,  Minth Group Limited',
    name: 'Minth Group Limited',
    symbol: '0425.HK',
  },
  {
    value: '0426.HK',
    label: '0426.HK,  One Media Group Limited',
    name: 'One Media Group Limited',
    symbol: '0426.HK',
  },
  {
    value: '0428.HK',
    label: '0428.HK,  Cocoon Holdings Limited',
    name: 'Cocoon Holdings Limited',
    symbol: '0428.HK',
  },
  {
    value: '0430.HK',
    label: '0430.HK,  Oriental Explorer Holdings Limited',
    name: 'Oriental Explorer Holdings Limited',
    symbol: '0430.HK',
  },
  {
    value: '0431.HK',
    label: '0431.HK,  Greater China Financial Holdings Limited',
    name: 'Greater China Financial Holdings Limited',
    symbol: '0431.HK',
  },
  {
    value: '0432.HK',
    label: '0432.HK,  Pacific Century Premium Developments Limited',
    name: 'Pacific Century Premium Developments Limited',
    symbol: '0432.HK',
  },
  {
    value: '0434.HK',
    label: '0434.HK,  Boyaa Interactive International Limited',
    name: 'Boyaa Interactive International Limited',
    symbol: '0434.HK',
  },
  {
    value: '0435.HK',
    label: '0435.HK,  Sunlight Real Estate Investment Trust',
    name: 'Sunlight Real Estate Investment Trust',
    symbol: '0435.HK',
  },
  {
    value: '0436.HK',
    label: '0436.HK,  New Universe Environmental Group Limited',
    name: 'New Universe Environmental Group Limited',
    symbol: '0436.HK',
  },
  {
    value: '0439.HK',
    label: '0439.HK,  KuangChi Science Limited',
    name: 'KuangChi Science Limited',
    symbol: '0439.HK',
  },
  {
    value: '0440.HK',
    label: '0440.HK,  Dah Sing Financial Holdings Limited',
    name: 'Dah Sing Financial Holdings Limited',
    symbol: '0440.HK',
  },
  {
    value: '0442.HK',
    label: '0442.HK,  Domaine Power Holdings Limited',
    name: 'Domaine Power Holdings Limited',
    symbol: '0442.HK',
  },
  {
    value: 'UGRO',
    label: 'UGRO,  urban-gro, Inc.',
    name: 'urban-gro, Inc.',
    symbol: 'UGRO',
  },
  {
    value: '0444.HK',
    label: '0444.HK,  Sincere Watch (Hong Kong) Limited',
    name: 'Sincere Watch (Hong Kong) Limited',
    symbol: '0444.HK',
  },
  {
    value: '0450.HK',
    label: '0450.HK,  Hung Hing Printing Group Limited',
    name: 'Hung Hing Printing Group Limited',
    symbol: '0450.HK',
  },
  {
    value: '0451.HK',
    label: '0451.HK,  GCL New Energy Holdings Limited',
    name: 'GCL New Energy Holdings Limited',
    symbol: '0451.HK',
  },
  {
    value: '0455.HK',
    label: '0455.HK,  Tianda Pharmaceuticals Limited',
    name: 'Tianda Pharmaceuticals Limited',
    symbol: '0455.HK',
  },
  {
    value: '0456.HK',
    label: '0456.HK,  New City Development Group Limited',
    name: 'New City Development Group Limited',
    symbol: '0456.HK',
  },
  {
    value: '0459.HK',
    label: '0459.HK,  Legend Upstar Holdings Limited',
    name: 'Legend Upstar Holdings Limited',
    symbol: '0459.HK',
  },
  {
    value: '0460.HK',
    label: '0460.HK,  Sihuan Pharmaceutical Holdings Group Ltd.',
    name: 'Sihuan Pharmaceutical Holdings Group Ltd.',
    symbol: '0460.HK',
  },
  {
    value: '0464.HK',
    label: '0464.HK,  China Overseas Nuoxin International Holdings Limited',
    name: 'China Overseas Nuoxin International Holdings Limited',
    symbol: '0464.HK',
  },
  {
    value: '0465.HK',
    label: '0465.HK,  Futong Technology Development Holdings Limited',
    name: 'Futong Technology Development Holdings Limited',
    symbol: '0465.HK',
  },
  {
    value: '0467.HK',
    label: '0467.HK,  United Energy Group Limited',
    name: 'United Energy Group Limited',
    symbol: '0467.HK',
  },
  {
    value: '0468.HK',
    label: '0468.HK,  Greatview Aseptic Packaging Company Limited',
    name: 'Greatview Aseptic Packaging Company Limited',
    symbol: '0468.HK',
  },
  {
    value: '0471.HK',
    label: '0471.HK,  Silkwave Inc',
    name: 'Silkwave Inc',
    symbol: '0471.HK',
  },
  {
    value: '0472.HK',
    label: '0472.HK,  New Silkroad Culturaltainment Limited',
    name: 'New Silkroad Culturaltainment Limited',
    symbol: '0472.HK',
  },
  {
    value: '0474.HK',
    label: '0474.HK,  Aceso Life Science Group Limited',
    name: 'Aceso Life Science Group Limited',
    symbol: '0474.HK',
  },
  {
    value: '0475.HK',
    label: '0475.HK,  Central Development Holdings Limited',
    name: 'Central Development Holdings Limited',
    symbol: '0475.HK',
  },
  {
    value: '0476.HK',
    label: '0476.HK,  Ev Dynamics (Holdings) Limited',
    name: 'Ev Dynamics (Holdings) Limited',
    symbol: '0476.HK',
  },
  {
    value: '0480.HK',
    label: '0480.HK,  HKR International Limited',
    name: 'HKR International Limited',
    symbol: '0480.HK',
  },
  {
    value: '0482.HK',
    label: '0482.HK,  Sandmartin International Holdings Limited',
    name: 'Sandmartin International Holdings Limited',
    symbol: '0482.HK',
  },
  {
    value: '0483.HK',
    label: '0483.HK,  Bauhaus International (Holdings) Limited',
    name: 'Bauhaus International (Holdings) Limited',
    symbol: '0483.HK',
  },
  {
    value: '0484.HK',
    label: '0484.HK,  Forgame Holdings Limited',
    name: 'Forgame Holdings Limited',
    symbol: '0484.HK',
  },
  {
    value: '0485.HK',
    label: '0485.HK,  China Sinostar Group Company Limited',
    name: 'China Sinostar Group Company Limited',
    symbol: '0485.HK',
  },
  {
    value: '0486.HK',
    label:
      '0486.HK,  United Company RUSAL, International Public Joint-Stock Company',
    name: 'United Company RUSAL, International Public Joint-Stock Company',
    symbol: '0486.HK',
  },
  {
    value: '0487.HK',
    label: '0487.HK,  Success Universe Group Limited',
    name: 'Success Universe Group Limited',
    symbol: '0487.HK',
  },
  {
    value: '0488.HK',
    label: '0488.HK,  Lai Sun Development Company Limited',
    name: 'Lai Sun Development Company Limited',
    symbol: '0488.HK',
  },
  {
    value: '0489.HK',
    label: '0489.HK,  Dongfeng Motor Group Company Limited',
    name: 'Dongfeng Motor Group Company Limited',
    symbol: '0489.HK',
  },
  {
    value: '0491.HK',
    label: '0491.HK,  Emperor Culture Group Limited',
    name: 'Emperor Culture Group Limited',
    symbol: '0491.HK',
  },
  {
    value: 'SNRHW',
    label: 'SNRHW,  Senior Connect Acquisition Corp. I',
    name: 'Senior Connect Acquisition Corp. I',
    symbol: 'SNRHW',
  },
  {
    value: '0493.HK',
    label: '0493.HK,  GOME Retail Holdings Limited',
    name: 'GOME Retail Holdings Limited',
    symbol: '0493.HK',
  },
  {
    value: '0495.HK',
    label: '0495.HK,  Paladin Limited',
    name: 'Paladin Limited',
    symbol: '0495.HK',
  },
  {
    value: '0496.HK',
    label: '0496.HK,  Kasen International Holdings Limited',
    name: 'Kasen International Holdings Limited',
    symbol: '0496.HK',
  },
  {
    value: '0497.HK',
    label: '0497.HK,  CSI Properties Limited',
    name: 'CSI Properties Limited',
    symbol: '0497.HK',
  },
  {
    value: '0498.HK',
    label: '0498.HK,  Blue River Holdings Limited',
    name: 'Blue River Holdings Limited',
    symbol: '0498.HK',
  },
  {
    value: '0499.HK',
    label: '0499.HK,  Qingdao Holdings International Limited',
    name: 'Qingdao Holdings International Limited',
    symbol: '0499.HK',
  },
  {
    value: '0503.HK',
    label: '0503.HK,  Lansen Pharmaceutical Holdings Limited',
    name: 'Lansen Pharmaceutical Holdings Limited',
    symbol: '0503.HK',
  },
  {
    value: '0505.HK',
    label: '0505.HK,  Xingye Alloy Materials Group Limited',
    name: 'Xingye Alloy Materials Group Limited',
    symbol: '0505.HK',
  },
  {
    value: '0506.HK',
    label: '0506.HK,  China Foods Limited',
    name: 'China Foods Limited',
    symbol: '0506.HK',
  },
  {
    value: '0508.HK',
    label: '0508.HK,  Dingyi Group Investment Limited',
    name: 'Dingyi Group Investment Limited',
    symbol: '0508.HK',
  },
  {
    value: '0509.HK',
    label: '0509.HK,  Century Sunshine Group Holdings Limited',
    name: 'Century Sunshine Group Holdings Limited',
    symbol: '0509.HK',
  },
  {
    value: '0510.HK',
    label: '0510.HK,  CASH Financial Services Group Limited',
    name: 'CASH Financial Services Group Limited',
    symbol: '0510.HK',
  },
  {
    value: '0511.HK',
    label: '0511.HK,  Television Broadcasts Limited',
    name: 'Television Broadcasts Limited',
    symbol: '0511.HK',
  },
  {
    value: '0512.HK',
    label: '0512.HK,  Grand Pharmaceutical Group Limited',
    name: 'Grand Pharmaceutical Group Limited',
    symbol: '0512.HK',
  },
  {
    value: '0513.HK',
    label: '0513.HK,  Continental Holdings Limited',
    name: 'Continental Holdings Limited',
    symbol: '0513.HK',
  },
  {
    value: '0515.HK',
    label: '0515.HK,  China Silver Technology Holdings Limited',
    name: 'China Silver Technology Holdings Limited',
    symbol: '0515.HK',
  },
  {
    value: '0517.HK',
    label: '0517.HK,  COSCO SHIPPING International (Hong Kong) Co., Ltd.',
    name: 'COSCO SHIPPING International (Hong Kong) Co., Ltd.',
    symbol: '0517.HK',
  },
  {
    value: '0518.HK',
    label: '0518.HK,  Tungtex (Holdings) Company Limited',
    name: 'Tungtex (Holdings) Company Limited',
    symbol: '0518.HK',
  },
  {
    value: '0519.HK',
    label: '0519.HK,  Applied Development Holdings Limited',
    name: 'Applied Development Holdings Limited',
    symbol: '0519.HK',
  },
  {
    value: '0520.HK',
    label:
      '0520.HK,  Xiabuxiabu Catering Management (China) Holdings Co., Ltd.',
    name: 'Xiabuxiabu Catering Management (China) Holdings Co., Ltd.',
    symbol: '0520.HK',
  },
  {
    value: '0521.HK',
    label: '0521.HK,  CWT International Limited',
    name: 'CWT International Limited',
    symbol: '0521.HK',
  },
  {
    value: '0522.HK',
    label: '0522.HK,  ASMPT Limited',
    name: 'ASMPT Limited',
    symbol: '0522.HK',
  },
  {
    value: '0524.HK',
    label: '0524.HK,  Great Wall Terroir Holdings Limited',
    name: 'Great Wall Terroir Holdings Limited',
    symbol: '0524.HK',
  },
  {
    value: '0525.HK',
    label: '0525.HK,  Guangshen Railway Company Limited',
    name: 'Guangshen Railway Company Limited',
    symbol: '0525.HK',
  },
  {
    value: '0526.HK',
    label: '0526.HK,  Lisi Group (Holdings) Limited',
    name: 'Lisi Group (Holdings) Limited',
    symbol: '0526.HK',
  },
  {
    value: '0527.HK',
    label: '0527.HK,  China Ruifeng Renewable Energy Holdings Limited',
    name: 'China Ruifeng Renewable Energy Holdings Limited',
    symbol: '0527.HK',
  },
  {
    value: '0528.HK',
    label: '0528.HK,  Kingdom Holdings Limited',
    name: 'Kingdom Holdings Limited',
    symbol: '0528.HK',
  },
  {
    value: '0531.HK',
    label: '0531.HK,  Samson Holding Ltd.',
    name: 'Samson Holding Ltd.',
    symbol: '0531.HK',
  },
  {
    value: '0532.HK',
    label: "0532.HK,  Wong's Kong King International (Holdings) Limited",
    name: "Wong's Kong King International (Holdings) Limited",
    symbol: '0532.HK',
  },
  {
    value: '0533.HK',
    label: '0533.HK,  Goldlion Holdings Limited',
    name: 'Goldlion Holdings Limited',
    symbol: '0533.HK',
  },
  {
    value: '0535.HK',
    label: '0535.HK,  Gemdale Properties and Investment Corporation Limited',
    name: 'Gemdale Properties and Investment Corporation Limited',
    symbol: '0535.HK',
  },
  {
    value: '0536.HK',
    label: '0536.HK,  Tradelink Electronic Commerce Limited',
    name: 'Tradelink Electronic Commerce Limited',
    symbol: '0536.HK',
  },
  {
    value: '0538.HK',
    label: '0538.HK,  Ajisen (China) Holdings Limited',
    name: 'Ajisen (China) Holdings Limited',
    symbol: '0538.HK',
  },
  {
    value: '0540.HK',
    label: '0540.HK,  Speedy Global Holdings Limited',
    name: 'Speedy Global Holdings Limited',
    symbol: '0540.HK',
  },
  {
    value: '0542.HK',
    label: '0542.HK,  TFG International Group Limited',
    name: 'TFG International Group Limited',
    symbol: '0542.HK',
  },
  {
    value: '0543.HK',
    label: '0543.HK,  Pacific Online Limited',
    name: 'Pacific Online Limited',
    symbol: '0543.HK',
  },
  {
    value: '0544.HK',
    label: '0544.HK,  Daido Group Limited',
    name: 'Daido Group Limited',
    symbol: '0544.HK',
  },
  {
    value: '0546.HK',
    label: '0546.HK,  Fufeng Group Limited',
    name: 'Fufeng Group Limited',
    symbol: '0546.HK',
  },
  {
    value: '0547.HK',
    label: '0547.HK,  Digital Domain Holdings Limited',
    name: 'Digital Domain Holdings Limited',
    symbol: '0547.HK',
  },
  {
    value: '0548.HK',
    label: '0548.HK,  Shenzhen Expressway Corporation Limited',
    name: 'Shenzhen Expressway Corporation Limited',
    symbol: '0548.HK',
  },
  {
    value: '0550.HK',
    label: '0550.HK,  Kingkey Intelligence Culture Holdings Limited',
    name: 'Kingkey Intelligence Culture Holdings Limited',
    symbol: '0550.HK',
  },
  {
    value: '0551.HK',
    label: '0551.HK,  Yue Yuen Industrial (Holdings) Limited',
    name: 'Yue Yuen Industrial (Holdings) Limited',
    symbol: '0551.HK',
  },
  {
    value: '0552.HK',
    label: '0552.HK,  China Communications Services Corporation Limited',
    name: 'China Communications Services Corporation Limited',
    symbol: '0552.HK',
  },
  {
    value: '0553.HK',
    label: '0553.HK,  Nanjing Panda Electronics Company Limited',
    name: 'Nanjing Panda Electronics Company Limited',
    symbol: '0553.HK',
  },
  {
    value: '0554.HK',
    label: '0554.HK,  Hans Energy Company Limited',
    name: 'Hans Energy Company Limited',
    symbol: '0554.HK',
  },
  {
    value: '0556.HK',
    label: '0556.HK,  Pan Asia Environmental Protection Group Limited',
    name: 'Pan Asia Environmental Protection Group Limited',
    symbol: '0556.HK',
  },
  {
    value: '0558.HK',
    label: '0558.HK,  L.K. Technology Holdings Limited',
    name: 'L.K. Technology Holdings Limited',
    symbol: '0558.HK',
  },
  {
    value: '0559.HK',
    label: '0559.HK,  DeTai New Energy Group Limited',
    name: 'DeTai New Energy Group Limited',
    symbol: '0559.HK',
  },
  {
    value: '0560.HK',
    label: '0560.HK,  Chu Kong Shipping Enterprises (Group) Company Limited',
    name: 'Chu Kong Shipping Enterprises (Group) Company Limited',
    symbol: '0560.HK',
  },
  {
    value: '0563.HK',
    label: '0563.HK,  Shanghai Industrial Urban Development Group Limited',
    name: 'Shanghai Industrial Urban Development Group Limited',
    symbol: '0563.HK',
  },
  {
    value: '0564.HK',
    label: '0564.HK,  Zhengzhou Coal Mining Machinery Group Company Limited',
    name: 'Zhengzhou Coal Mining Machinery Group Company Limited',
    symbol: '0564.HK',
  },
  {
    value: '0565.HK',
    label: '0565.HK,  Art Group Holdings Limited',
    name: 'Art Group Holdings Limited',
    symbol: '0565.HK',
  },
  {
    value: '0567.HK',
    label: '0567.HK,  Daisho Microline Holdings Limited',
    name: 'Daisho Microline Holdings Limited',
    symbol: '0567.HK',
  },
  {
    value: '0568.HK',
    label: '0568.HK,  Shandong Molong Petroleum Machinery Company Limited',
    name: 'Shandong Molong Petroleum Machinery Company Limited',
    symbol: '0568.HK',
  },
  {
    value: '0570.HK',
    label: '0570.HK,  China Traditional Chinese Medicine Holdings Co. Limited',
    name: 'China Traditional Chinese Medicine Holdings Co. Limited',
    symbol: '0570.HK',
  },
  {
    value: '0572.HK',
    label: '0572.HK,  Future World Holdings Limited',
    name: 'Future World Holdings Limited',
    symbol: '0572.HK',
  },
  {
    value: '0573.HK',
    label: '0573.HK,  Tao Heung Holdings Limited',
    name: 'Tao Heung Holdings Limited',
    symbol: '0573.HK',
  },
  {
    value: '0575.HK',
    label: '0575.HK,  Endurance RP Limited',
    name: 'Endurance RP Limited',
    symbol: '0575.HK',
  },
  {
    value: '0576.HK',
    label: '0576.HK,  Zhejiang Expressway Co., Ltd.',
    name: 'Zhejiang Expressway Co., Ltd.',
    symbol: '0576.HK',
  },
  {
    value: '0579.HK',
    label: '0579.HK,  Beijing Jingneng Clean Energy Co., Limited',
    name: 'Beijing Jingneng Clean Energy Co., Limited',
    symbol: '0579.HK',
  },
  {
    value: '0580.HK',
    label: '0580.HK,  Sun.King Technology Group Limited',
    name: 'Sun.King Technology Group Limited',
    symbol: '0580.HK',
  },
  {
    value: '0581.HK',
    label: '0581.HK,  China Oriental Group Company Limited',
    name: 'China Oriental Group Company Limited',
    symbol: '0581.HK',
  },
  {
    value: '0582.HK',
    label: '0582.HK,  Landing International Development Limited',
    name: 'Landing International Development Limited',
    symbol: '0582.HK',
  },
  {
    value: '0583.HK',
    label: '0583.HK,  Great Wall Pan Asia Holdings Limited',
    name: 'Great Wall Pan Asia Holdings Limited',
    symbol: '0583.HK',
  },
  {
    value: '0585.HK',
    label: '0585.HK,  Imagi International Holdings Limited',
    name: 'Imagi International Holdings Limited',
    symbol: '0585.HK',
  },
  {
    value: '0586.HK',
    label: '0586.HK,  China Conch Venture Holdings Limited',
    name: 'China Conch Venture Holdings Limited',
    symbol: '0586.HK',
  },
  {
    value: '0588.HK',
    label: '0588.HK,  Beijing North Star Company Limited',
    name: 'Beijing North Star Company Limited',
    symbol: '0588.HK',
  },
  {
    value: '0589.HK',
    label: '0589.HK,  Jianzhong Construction Development Limited',
    name: 'Jianzhong Construction Development Limited',
    symbol: '0589.HK',
  },
  {
    value: '0590.HK',
    label: '0590.HK,  Luk Fook Holdings (International) Limited',
    name: 'Luk Fook Holdings (International) Limited',
    symbol: '0590.HK',
  },
  {
    value: '0592.HK',
    label: '0592.HK,  Bossini International Holdings Limited',
    name: 'Bossini International Holdings Limited',
    symbol: '0592.HK',
  },
  {
    value: '0593.HK',
    label: '0593.HK,  DreamEast Group Limited',
    name: 'DreamEast Group Limited',
    symbol: '0593.HK',
  },
  {
    value: '0595.HK',
    label: '0595.HK,  AV Concept Holdings Limited',
    name: 'AV Concept Holdings Limited',
    symbol: '0595.HK',
  },
  {
    value: '0596.HK',
    label: '0596.HK,  Inspur International Limited',
    name: 'Inspur International Limited',
    symbol: '0596.HK',
  },
  {
    value: '0598.HK',
    label: '0598.HK,  Sinotrans Limited',
    name: 'Sinotrans Limited',
    symbol: '0598.HK',
  },
  {
    value: '0599.HK',
    label: '0599.HK,  E. Bon Holdings Limited',
    name: 'E. Bon Holdings Limited',
    symbol: '0599.HK',
  },
  {
    value: '0601.HK',
    label: '0601.HK,  Rare Earth Magnesium Technology Group Holdings Limited',
    name: 'Rare Earth Magnesium Technology Group Holdings Limited',
    symbol: '0601.HK',
  },
  {
    value: '0602.HK',
    label: '0602.HK,  Jiahua Stores Holdings Limited',
    name: 'Jiahua Stores Holdings Limited',
    symbol: '0602.HK',
  },
  {
    value: '0603.HK',
    label: '0603.HK,  China Oil And Gas Group Limited',
    name: 'China Oil And Gas Group Limited',
    symbol: '0603.HK',
  },
  {
    value: '0604.HK',
    label: '0604.HK,  Shenzhen Investment Limited',
    name: 'Shenzhen Investment Limited',
    symbol: '0604.HK',
  },
  {
    value: '0607.HK',
    label: '0607.HK,  Fullshare Holdings Limited',
    name: 'Fullshare Holdings Limited',
    symbol: '0607.HK',
  },
  {
    value: '0608.HK',
    label: '0608.HK,  High Fashion International Limited',
    name: 'High Fashion International Limited',
    symbol: '0608.HK',
  },
  {
    value: '0609.HK',
    label: '0609.HK,  Tiande Chemical Holdings Limited',
    name: 'Tiande Chemical Holdings Limited',
    symbol: '0609.HK',
  },
  {
    value: '0610.HK',
    label: '0610.HK,  Wai Kee Holdings Limited',
    name: 'Wai Kee Holdings Limited',
    symbol: '0610.HK',
  },
  {
    value: '0611.HK',
    label: '0611.HK,  China Nuclear Energy Technology Corporation Limited',
    name: 'China Nuclear Energy Technology Corporation Limited',
    symbol: '0611.HK',
  },
  {
    value: '0612.HK',
    label: '0612.HK,  China Investment Fund Company Limited',
    name: 'China Investment Fund Company Limited',
    symbol: '0612.HK',
  },
  {
    value: '0613.HK',
    label: '0613.HK,  Planetree International Development Limited',
    name: 'Planetree International Development Limited',
    symbol: '0613.HK',
  },
  {
    value: '0616.HK',
    label: '0616.HK,  Eminence Enterprise Limited',
    name: 'Eminence Enterprise Limited',
    symbol: '0616.HK',
  },
  {
    value: '0617.HK',
    label: '0617.HK,  Paliburg Holdings Limited',
    name: 'Paliburg Holdings Limited',
    symbol: '0617.HK',
  },
  {
    value: '0618.HK',
    label: '0618.HK,  Peking University Resources (Holdings) Company Limited',
    name: 'Peking University Resources (Holdings) Company Limited',
    symbol: '0618.HK',
  },
  {
    value: '0619.HK',
    label: '0619.HK,  South China Financial Holdings Limited',
    name: 'South China Financial Holdings Limited',
    symbol: '0619.HK',
  },
  {
    value: '0620.HK',
    label: '0620.HK,  DTXS Silk Road Investment Holdings Company Limited',
    name: 'DTXS Silk Road Investment Holdings Company Limited',
    symbol: '0620.HK',
  },
  {
    value: '0621.HK',
    label: '0621.HK,  Taung Gold International Limited',
    name: 'Taung Gold International Limited',
    symbol: '0621.HK',
  },
  {
    value: '0622.HK',
    label: '0622.HK,  Oshidori International Holdings Limited',
    name: 'Oshidori International Holdings Limited',
    symbol: '0622.HK',
  },
  {
    value: '0623.HK',
    label: '0623.HK,  SinoMedia Holding Limited',
    name: 'SinoMedia Holding Limited',
    symbol: '0623.HK',
  },
  {
    value: '0626.HK',
    label: '0626.HK,  Public Financial Holdings Limited',
    name: 'Public Financial Holdings Limited',
    symbol: '0626.HK',
  },
  {
    value: '0628.HK',
    label: '0628.HK,  Gome Finance Technology Co., Ltd.',
    name: 'Gome Finance Technology Co., Ltd.',
    symbol: '0628.HK',
  },
  {
    value: '0629.HK',
    label: '0629.HK,  Yue Da International Holdings Limited',
    name: 'Yue Da International Holdings Limited',
    symbol: '0629.HK',
  },
  {
    value: '0630.HK',
    label: '0630.HK,  AMCO United Holding Limited',
    name: 'AMCO United Holding Limited',
    symbol: '0630.HK',
  },
  {
    value: '0631.HK',
    label:
      '0631.HK,  Sany Heavy Equipment International Holdings Company Limited',
    name: 'Sany Heavy Equipment International Holdings Company Limited',
    symbol: '0631.HK',
  },
  {
    value: '0632.HK',
    label: '0632.HK,  CHK Oil Limited',
    name: 'CHK Oil Limited',
    symbol: '0632.HK',
  },
  {
    value: '0635.HK',
    label: '0635.HK,  Playmates Holdings Limited',
    name: 'Playmates Holdings Limited',
    symbol: '0635.HK',
  },
  {
    value: '0636.HK',
    label: '0636.HK,  Kerry Logistics Network Limited',
    name: 'Kerry Logistics Network Limited',
    symbol: '0636.HK',
  },
  {
    value: '0637.HK',
    label: '0637.HK,  Lee Kee Holdings Limited',
    name: 'Lee Kee Holdings Limited',
    symbol: '0637.HK',
  },
  {
    value: '0638.HK',
    label: '0638.HK,  Kin Yat Holdings Limited',
    name: 'Kin Yat Holdings Limited',
    symbol: '0638.HK',
  },
  {
    value: '0639.HK',
    label: '0639.HK,  Shougang Fushan Resources Group Limited',
    name: 'Shougang Fushan Resources Group Limited',
    symbol: '0639.HK',
  },
  {
    value: '0640.HK',
    label: '0640.HK,  Infinity Development Holdings Company Limited',
    name: 'Infinity Development Holdings Company Limited',
    symbol: '0640.HK',
  },
  {
    value: '0641.HK',
    label: "0641.HK,  CHTC Fong's International Company Limited",
    name: "CHTC Fong's International Company Limited",
    symbol: '0641.HK',
  },
  {
    value: '0643.HK',
    label: '0643.HK,  Carry Wealth Holdings Limited',
    name: 'Carry Wealth Holdings Limited',
    symbol: '0643.HK',
  },
  {
    value: '0646.HK',
    label: '0646.HK,  China Environmental Technology Holdings Limited',
    name: 'China Environmental Technology Holdings Limited',
    symbol: '0646.HK',
  },
  {
    value: '0650.HK',
    label: '0650.HK,  Productive Technologies Company Limited',
    name: 'Productive Technologies Company Limited',
    symbol: '0650.HK',
  },
  {
    value: '0655.HK',
    label: '0655.HK,  Hongkong Chinese Limited',
    name: 'Hongkong Chinese Limited',
    symbol: '0655.HK',
  },
  {
    value: '0656.HK',
    label: '0656.HK,  Fosun International Limited',
    name: 'Fosun International Limited',
    symbol: '0656.HK',
  },
  {
    value: '0657.HK',
    label: '0657.HK,  G-Vision International (Holdings) Limited',
    name: 'G-Vision International (Holdings) Limited',
    symbol: '0657.HK',
  },
  {
    value: '0658.HK',
    label: '0658.HK,  China High Speed Transmission Equipment Group Co., Ltd.',
    name: 'China High Speed Transmission Equipment Group Co., Ltd.',
    symbol: '0658.HK',
  },
  {
    value: '0659.HK',
    label: '0659.HK,  NWS Holdings Limited',
    name: 'NWS Holdings Limited',
    symbol: '0659.HK',
  },
  {
    value: '0660.HK',
    label: '0660.HK,  Wai Chun Bio-Technology Limited',
    name: 'Wai Chun Bio-Technology Limited',
    symbol: '0660.HK',
  },
  {
    value: '0661.HK',
    label: '0661.HK,  China Daye Non-Ferrous Metals Mining Limited',
    name: 'China Daye Non-Ferrous Metals Mining Limited',
    symbol: '0661.HK',
  },
  {
    value: '0662.HK',
    label: '0662.HK,  Asia Financial Holdings Limited',
    name: 'Asia Financial Holdings Limited',
    symbol: '0662.HK',
  },
  {
    value: '0663.HK',
    label: '0663.HK,  King Stone Energy Group Limited',
    name: 'King Stone Energy Group Limited',
    symbol: '0663.HK',
  },
  {
    value: '0665.HK',
    label: '0665.HK,  Haitong International Securities Group Limited',
    name: 'Haitong International Securities Group Limited',
    symbol: '0665.HK',
  },
  {
    value: '0667.HK',
    label: '0667.HK,  China East Education Holdings Limited',
    name: 'China East Education Holdings Limited',
    symbol: '0667.HK',
  },
  {
    value: '0668.HK',
    label: '0668.HK,  Doyen International Holdings Limited',
    name: 'Doyen International Holdings Limited',
    symbol: '0668.HK',
  },
  {
    value: '0670.HK',
    label: '0670.HK,  China Eastern Airlines Corporation Limited',
    name: 'China Eastern Airlines Corporation Limited',
    symbol: '0670.HK',
  },
  {
    value: '0672.HK',
    label: '0672.HK,  Zhong An Group Limited',
    name: 'Zhong An Group Limited',
    symbol: '0672.HK',
  },
  {
    value: '0673.HK',
    label: '0673.HK,  China Health Group Limited',
    name: 'China Health Group Limited',
    symbol: '0673.HK',
  },
  {
    value: '0674.HK',
    label: '0674.HK,  China Tangshang Holdings Limited',
    name: 'China Tangshang Holdings Limited',
    symbol: '0674.HK',
  },
  {
    value: '0675.HK',
    label: '0675.HK,  K & P International Holdings Limited',
    name: 'K & P International Holdings Limited',
    symbol: '0675.HK',
  },
  {
    value: '0677.HK',
    label: '0677.HK,  Golden Resources Development International Limited',
    name: 'Golden Resources Development International Limited',
    symbol: '0677.HK',
  },
  {
    value: '0679.HK',
    label: '0679.HK,  Asia Tele-Net and Technology Corporation Limited',
    name: 'Asia Tele-Net and Technology Corporation Limited',
    symbol: '0679.HK',
  },
  {
    value: '0681.HK',
    label: '0681.HK,  Chinese People Holdings Company Limited',
    name: 'Chinese People Holdings Company Limited',
    symbol: '0681.HK',
  },
  {
    value: '0682.HK',
    label: '0682.HK,  Chaoda Modern Agriculture (Holdings) Limited',
    name: 'Chaoda Modern Agriculture (Holdings) Limited',
    symbol: '0682.HK',
  },
  {
    value: '0683.HK',
    label: '0683.HK,  Kerry Properties Limited',
    name: 'Kerry Properties Limited',
    symbol: '0683.HK',
  },
  {
    value: '0684.HK',
    label: '0684.HK,  Allan International Holdings Limited',
    name: 'Allan International Holdings Limited',
    symbol: '0684.HK',
  },
  {
    value: '0685.HK',
    label: '0685.HK,  Media Chinese International Limited',
    name: 'Media Chinese International Limited',
    symbol: '0685.HK',
  },
  {
    value: '0686.HK',
    label: '0686.HK,  Beijing Energy International Holding Co., Ltd.',
    name: 'Beijing Energy International Holding Co., Ltd.',
    symbol: '0686.HK',
  },
  {
    value: '0687.HK',
    label: '0687.HK,  Tysan Holdings Limited',
    name: 'Tysan Holdings Limited',
    symbol: '0687.HK',
  },
  {
    value: '0689.HK',
    label: '0689.HK,  EPI (Holdings) Limited',
    name: 'EPI (Holdings) Limited',
    symbol: '0689.HK',
  },
  {
    value: '0690.HK',
    label: '0690.HK,  Uni-Bio Science Group Limited',
    name: 'Uni-Bio Science Group Limited',
    symbol: '0690.HK',
  },
  {
    value: '0691.HK',
    label: '0691.HK,  China Shanshui Cement Group Limited',
    name: 'China Shanshui Cement Group Limited',
    symbol: '0691.HK',
  },
  {
    value: '0693.HK',
    label: '0693.HK,  Tan Chong International Limited',
    name: 'Tan Chong International Limited',
    symbol: '0693.HK',
  },
  {
    value: '0694.HK',
    label: '0694.HK,  Beijing Capital International Airport Company Limited',
    name: 'Beijing Capital International Airport Company Limited',
    symbol: '0694.HK',
  },
  {
    value: '0695.HK',
    label: '0695.HK,  Dongwu Cement International Limited',
    name: 'Dongwu Cement International Limited',
    symbol: '0695.HK',
  },
  {
    value: '0696.HK',
    label: '0696.HK,  TravelSky Technology Limited',
    name: 'TravelSky Technology Limited',
    symbol: '0696.HK',
  },
  {
    value: '0697.HK',
    label: '0697.HK,  Shoucheng Holdings Limited',
    name: 'Shoucheng Holdings Limited',
    symbol: '0697.HK',
  },
  {
    value: '0698.HK',
    label: '0698.HK,  Tongda Group Holdings Limited',
    name: 'Tongda Group Holdings Limited',
    symbol: '0698.HK',
  },
  {
    value: '0701.HK',
    label: '0701.HK,  CNT Group Limited',
    name: 'CNT Group Limited',
    symbol: '0701.HK',
  },
  {
    value: '0702.HK',
    label: '0702.HK,  Sino Oil and Gas Holdings Limited',
    name: 'Sino Oil and Gas Holdings Limited',
    symbol: '0702.HK',
  },
  {
    value: '0703.HK',
    label: '0703.HK,  Future Bright Holdings Limited',
    name: 'Future Bright Holdings Limited',
    symbol: '0703.HK',
  },
  {
    value: '0707.HK',
    label: '0707.HK,  Asia Television Holdings Limited',
    name: 'Asia Television Holdings Limited',
    symbol: '0707.HK',
  },
  {
    value: '0709.HK',
    label: '0709.HK,  Giordano International Limited',
    name: 'Giordano International Limited',
    symbol: '0709.HK',
  },
  {
    value: '0710.HK',
    label: '0710.HK,  BOE Varitronix Limited',
    name: 'BOE Varitronix Limited',
    symbol: '0710.HK',
  },
  {
    value: '0711.HK',
    label: '0711.HK,  Asia Allied Infrastructure Holdings Limited',
    name: 'Asia Allied Infrastructure Holdings Limited',
    symbol: '0711.HK',
  },
  {
    value: '0712.HK',
    label: '0712.HK,  Comtec Solar Systems Group Limited',
    name: 'Comtec Solar Systems Group Limited',
    symbol: '0712.HK',
  },
  {
    value: '0713.HK',
    label: '0713.HK,  World Houseware (Holdings) Limited',
    name: 'World Houseware (Holdings) Limited',
    symbol: '0713.HK',
  },
  {
    value: '0715.HK',
    label: '0715.HK,  China Oceanwide Holdings Limited',
    name: 'China Oceanwide Holdings Limited',
    symbol: '0715.HK',
  },
  {
    value: '0716.HK',
    label: '0716.HK,  Singamas Container Holdings Limited',
    name: 'Singamas Container Holdings Limited',
    symbol: '0716.HK',
  },
  {
    value: '0717.HK',
    label: '0717.HK,  Emperor Capital Group Limited',
    name: 'Emperor Capital Group Limited',
    symbol: '0717.HK',
  },
  {
    value: '0718.HK',
    label: '0718.HK,  Tai United Holdings Limited',
    name: 'Tai United Holdings Limited',
    symbol: '0718.HK',
  },
  {
    value: '0719.HK',
    label: '0719.HK,  Shandong Xinhua Pharmaceutical Company Limited',
    name: 'Shandong Xinhua Pharmaceutical Company Limited',
    symbol: '0719.HK',
  },
  {
    value: '0720.HK',
    label: '0720.HK,  Auto Italia Holdings Limited',
    name: 'Auto Italia Holdings Limited',
    symbol: '0720.HK',
  },
  {
    value: '0721.HK',
    label: '0721.HK,  China Financial International Investments Limited',
    name: 'China Financial International Investments Limited',
    symbol: '0721.HK',
  },
  {
    value: '0722.HK',
    label: '0722.HK,  UMP Healthcare Holdings Limited',
    name: 'UMP Healthcare Holdings Limited',
    symbol: '0722.HK',
  },
  {
    value: '0724.HK',
    label: '0724.HK,  Ruixin International Holdings Limited',
    name: 'Ruixin International Holdings Limited',
    symbol: '0724.HK',
  },
  {
    value: '0725.HK',
    label: '0725.HK,  Perennial International Limited',
    name: 'Perennial International Limited',
    symbol: '0725.HK',
  },
  {
    value: '0726.HK',
    label: '0726.HK,  DIT Group Limited',
    name: 'DIT Group Limited',
    symbol: '0726.HK',
  },
  {
    value: 'SNRH',
    label: 'SNRH,  Senior Connect Acquisition Corp. I',
    name: 'Senior Connect Acquisition Corp. I',
    symbol: 'SNRH',
  },
  {
    value: '0727.HK',
    label: '0727.HK,  Crown International Corporation Limited',
    name: 'Crown International Corporation Limited',
    symbol: '0727.HK',
  },
  {
    value: '0728.HK',
    label: '0728.HK,  China Telecom Corporation Limited',
    name: 'China Telecom Corporation Limited',
    symbol: '0728.HK',
  },
  {
    value: '0730.HK',
    label: '0730.HK,  Capital Industrial Financial Services Group Limited',
    name: 'Capital Industrial Financial Services Group Limited',
    symbol: '0730.HK',
  },
  {
    value: '0732.HK',
    label: '0732.HK,  Truly International Holdings Limited',
    name: 'Truly International Holdings Limited',
    symbol: '0732.HK',
  },
  {
    value: '0733.HK',
    label: '0733.HK,  Hopefluent Group Holdings Limited',
    name: 'Hopefluent Group Holdings Limited',
    symbol: '0733.HK',
  },
  {
    value: '0736.HK',
    label: '0736.HK,  China Properties Investment Holdings Limited',
    name: 'China Properties Investment Holdings Limited',
    symbol: '0736.HK',
  },
  {
    value: '0737.HK',
    label:
      '0737.HK,  Shenzhen Investment Holdings Bay Area Development Company Limited',
    name: 'Shenzhen Investment Holdings Bay Area Development Company Limited',
    symbol: '0737.HK',
  },
  {
    value: '0738.HK',
    label: '0738.HK,  Le Saunda Holdings Limited',
    name: 'Le Saunda Holdings Limited',
    symbol: '0738.HK',
  },
  {
    value: '0743.HK',
    label: '0743.HK,  Asia Cement (China) Holdings Corporation',
    name: 'Asia Cement (China) Holdings Corporation',
    symbol: '0743.HK',
  },
  {
    value: '0745.HK',
    label: '0745.HK,  China National Culture Group Limited',
    name: 'China National Culture Group Limited',
    symbol: '0745.HK',
  },
  {
    value: '0746.HK',
    label: '0746.HK,  Lee & Man Chemical Company Limited',
    name: 'Lee & Man Chemical Company Limited',
    symbol: '0746.HK',
  },
  {
    value: '0747.HK',
    label: '0747.HK,  Shenyang Public Utility Holdings Company Limited',
    name: 'Shenyang Public Utility Holdings Company Limited',
    symbol: '0747.HK',
  },
  {
    value: '0750.HK',
    label: '0750.HK,  China Shuifa Singyes Energy Holdings Limited',
    name: 'China Shuifa Singyes Energy Holdings Limited',
    symbol: '0750.HK',
  },
  {
    value: '0751.HK',
    label: '0751.HK,  Skyworth Group Limited',
    name: 'Skyworth Group Limited',
    symbol: '0751.HK',
  },
  {
    value: '0752.HK',
    label: '0752.HK,  Pico Far East Holdings Limited',
    name: 'Pico Far East Holdings Limited',
    symbol: '0752.HK',
  },
  {
    value: '0753.HK',
    label: '0753.HK,  Air China Limited',
    name: 'Air China Limited',
    symbol: '0753.HK',
  },
  {
    value: '0754.HK',
    label: '0754.HK,  Hopson Development Holdings Limited',
    name: 'Hopson Development Holdings Limited',
    symbol: '0754.HK',
  },
  {
    value: '0755.HK',
    label: '0755.HK,  Shanghai Zendai Property Limited',
    name: 'Shanghai Zendai Property Limited',
    symbol: '0755.HK',
  },
  {
    value: '0756.HK',
    label: '0756.HK,  Summi (Group) Holdings Limited',
    name: 'Summi (Group) Holdings Limited',
    symbol: '0756.HK',
  },
  {
    value: '0757.HK',
    label: '0757.HK,  Solargiga Energy Holdings Limited',
    name: 'Solargiga Energy Holdings Limited',
    symbol: '0757.HK',
  },
  {
    value: '0759.HK',
    label: '0759.HK,  CEC International Holdings Limited',
    name: 'CEC International Holdings Limited',
    symbol: '0759.HK',
  },
  {
    value: '0760.HK',
    label: '0760.HK,  Talent Property Group Limited',
    name: 'Talent Property Group Limited',
    symbol: '0760.HK',
  },
  {
    value: '0764.HK',
    label: '0764.HK,  Eternity Investment Limited',
    name: 'Eternity Investment Limited',
    symbol: '0764.HK',
  },
  {
    value: '0765.HK',
    label: '0765.HK,  Perfectech International Holdings Limited',
    name: 'Perfectech International Holdings Limited',
    symbol: '0765.HK',
  },
  {
    value: '0767.HK',
    label: '0767.HK,  Zhong Ji Longevity Science Group Limited',
    name: 'Zhong Ji Longevity Science Group Limited',
    symbol: '0767.HK',
  },
  {
    value: '0768.HK',
    label: '0768.HK,  UBA Investments Limited',
    name: 'UBA Investments Limited',
    symbol: '0768.HK',
  },
  {
    value: '0769.HK',
    label: '0769.HK,  China Rare Earth Holdings Limited',
    name: 'China Rare Earth Holdings Limited',
    symbol: '0769.HK',
  },
  {
    value: '0771.HK',
    label: '0771.HK,  Automated Systems Holdings Limited',
    name: 'Automated Systems Holdings Limited',
    symbol: '0771.HK',
  },
  {
    value: '0775.HK',
    label: "0775.HK,  CK Life Sciences Int'l., (Holdings) Inc.",
    name: "CK Life Sciences Int'l., (Holdings) Inc.",
    symbol: '0775.HK',
  },
  {
    value: '0776.HK',
    label: '0776.HK,  Imperium Technology Group Limited',
    name: 'Imperium Technology Group Limited',
    symbol: '0776.HK',
  },
  {
    value: '0777.HK',
    label: '0777.HK,  NetDragon Websoft Holdings Limited',
    name: 'NetDragon Websoft Holdings Limited',
    symbol: '0777.HK',
  },
  {
    value: '0778.HK',
    label: '0778.HK,  Fortune Real Estate Investment Trust',
    name: 'Fortune Real Estate Investment Trust',
    symbol: '0778.HK',
  },
  {
    value: '0780.HK',
    label: '0780.HK,  Tongcheng Travel Holdings Limited',
    name: 'Tongcheng Travel Holdings Limited',
    symbol: '0780.HK',
  },
  {
    value: '0784.HK',
    label: '0784.HK,  Ling Yui Holdings Limited',
    name: 'Ling Yui Holdings Limited',
    symbol: '0784.HK',
  },
  {
    value: '0788.HK',
    label: '0788.HK,  China Tower Corporation Limited',
    name: 'China Tower Corporation Limited',
    symbol: '0788.HK',
  },
  {
    value: '0789.HK',
    label: '0789.HK,  Artini Holdings Limited',
    name: 'Artini Holdings Limited',
    symbol: '0789.HK',
  },
  {
    value: '0798.HK',
    label:
      '0798.HK,  China Electronics Optics Valley Union Holding Company Limited',
    name: 'China Electronics Optics Valley Union Holding Company Limited',
    symbol: '0798.HK',
  },
  {
    value: '0799.HK',
    label: '0799.HK,  IGG Inc',
    name: 'IGG Inc',
    symbol: '0799.HK',
  },
  {
    value: '0800.HK',
    label: '0800.HK,  A8 New Media Group Limited',
    name: 'A8 New Media Group Limited',
    symbol: '0800.HK',
  },
  {
    value: '0802.HK',
    label: '0802.HK,  China e-Wallet Payment Group Limited',
    name: 'China e-Wallet Payment Group Limited',
    symbol: '0802.HK',
  },
  {
    value: '0804.HK',
    label: '0804.HK,  Pinestone Capital Limited',
    name: 'Pinestone Capital Limited',
    symbol: '0804.HK',
  },
  {
    value: '0806.HK',
    label: '0806.HK,  Value Partners Group Limited',
    name: 'Value Partners Group Limited',
    symbol: '0806.HK',
  },
  {
    value: '0807.HK',
    label: '0807.HK,  SIIC Environment Holdings Ltd.',
    name: 'SIIC Environment Holdings Ltd.',
    symbol: '0807.HK',
  },
  {
    value: '0808.HK',
    label: '0808.HK,  Prosperity Real Estate Investment Trust',
    name: 'Prosperity Real Estate Investment Trust',
    symbol: '0808.HK',
  },
  {
    value: '0809.HK',
    label: '0809.HK,  Global Bio-chem Technology Group Company Limited',
    name: 'Global Bio-chem Technology Group Company Limited',
    symbol: '0809.HK',
  },
  {
    value: '0810.HK',
    label: '0810.HK,  China Internet Investment Finance Holdings Limited',
    name: 'China Internet Investment Finance Holdings Limited',
    symbol: '0810.HK',
  },
  {
    value: '0811.HK',
    label: '0811.HK,  Xinhua Winshare Publishing and Media Co., Ltd.',
    name: 'Xinhua Winshare Publishing and Media Co., Ltd.',
    symbol: '0811.HK',
  },
  {
    value: '0812.HK',
    label: '0812.HK,  Southwest Securities International Securities Limited',
    name: 'Southwest Securities International Securities Limited',
    symbol: '0812.HK',
  },
  {
    value: '0814.HK',
    label: '0814.HK,  Beijing Jingkelong Company Limited',
    name: 'Beijing Jingkelong Company Limited',
    symbol: '0814.HK',
  },
  {
    value: '0815.HK',
    label: '0815.HK,  China Silver Group Limited',
    name: 'China Silver Group Limited',
    symbol: '0815.HK',
  },
  {
    value: '0817.HK',
    label: '0817.HK,  China Jinmao Holdings Group Limited',
    name: 'China Jinmao Holdings Group Limited',
    symbol: '0817.HK',
  },
  {
    value: '0818.HK',
    label: '0818.HK,  Hi Sun Technology (China) Limited',
    name: 'Hi Sun Technology (China) Limited',
    symbol: '0818.HK',
  },
  {
    value: '0819.HK',
    label: '0819.HK,  Tianneng Power International Limited',
    name: 'Tianneng Power International Limited',
    symbol: '0819.HK',
  },
  {
    value: '0821.HK',
    label: '0821.HK,  Value Convergence Holdings Limited',
    name: 'Value Convergence Holdings Limited',
    symbol: '0821.HK',
  },
  {
    value: '0822.HK',
    label: '0822.HK,  Ka Shui International Holdings Limited',
    name: 'Ka Shui International Holdings Limited',
    symbol: '0822.HK',
  },
  {
    value: '0825.HK',
    label: '0825.HK,  New World Department Store China Limited',
    name: 'New World Department Store China Limited',
    symbol: '0825.HK',
  },
  {
    value: '0826.HK',
    label: '0826.HK,  Tiangong International Company Limited',
    name: 'Tiangong International Company Limited',
    symbol: '0826.HK',
  },
  {
    value: '0827.HK',
    label: '0827.HK,  Ko Yo Chemical (Group) Limited',
    name: 'Ko Yo Chemical (Group) Limited',
    symbol: '0827.HK',
  },
  {
    value: '0828.HK',
    label: '0828.HK,  Dynasty Fine Wines Group Limited',
    name: 'Dynasty Fine Wines Group Limited',
    symbol: '0828.HK',
  },
  {
    value: '0829.HK',
    label: '0829.HK,  Shenguan Holdings (Group) Limited',
    name: 'Shenguan Holdings (Group) Limited',
    symbol: '0829.HK',
  },
  {
    value: '0830.HK',
    label: '0830.HK,  China State Construction Development Holdings Limited',
    name: 'China State Construction Development Holdings Limited',
    symbol: '0830.HK',
  },
  {
    value: '0831.HK',
    label: '0831.HK,  Convenience Retail Asia Limited',
    name: 'Convenience Retail Asia Limited',
    symbol: '0831.HK',
  },
  {
    value: '0832.HK',
    label: '0832.HK,  Central China Real Estate Limited',
    name: 'Central China Real Estate Limited',
    symbol: '0832.HK',
  },
  {
    value: '0833.HK',
    label: '0833.HK,  Alltronics Holdings Limited',
    name: 'Alltronics Holdings Limited',
    symbol: '0833.HK',
  },
  {
    value: '0836.HK',
    label: '0836.HK,  China Resources Power Holdings Company Limited',
    name: 'China Resources Power Holdings Company Limited',
    symbol: '0836.HK',
  },
  {
    value: '0837.HK',
    label: '0837.HK,  Carpenter Tan Holdings Limited',
    name: 'Carpenter Tan Holdings Limited',
    symbol: '0837.HK',
  },
  {
    value: '0838.HK',
    label: '0838.HK,  EVA Precision Industrial Holdings Limited',
    name: 'EVA Precision Industrial Holdings Limited',
    symbol: '0838.HK',
  },
  {
    value: '0839.HK',
    label: '0839.HK,  China Education Group Holdings Limited',
    name: 'China Education Group Holdings Limited',
    symbol: '0839.HK',
  },
  {
    value: '0840.HK',
    label:
      '0840.HK,  Xinjiang Tianye Water Saving Irrigation System Company Limited',
    name: 'Xinjiang Tianye Water Saving Irrigation System Company Limited',
    symbol: '0840.HK',
  },
  {
    value: '0841.HK',
    label: '0841.HK,  Asia Cassava Resources Holdings Limited',
    name: 'Asia Cassava Resources Holdings Limited',
    symbol: '0841.HK',
  },
  {
    value: '0842.HK',
    label: '0842.HK,  Leoch International Technology Limited',
    name: 'Leoch International Technology Limited',
    symbol: '0842.HK',
  },
  {
    value: '0844.HK',
    label: '0844.HK,  Greatime International Holdings Limited',
    name: 'Greatime International Holdings Limited',
    symbol: '0844.HK',
  },
  {
    value: '0845.HK',
    label: '0845.HK,  Glorious Property Holdings Limited',
    name: 'Glorious Property Holdings Limited',
    symbol: '0845.HK',
  },
  {
    value: '0846.HK',
    label: '0846.HK,  Mingfa Group (International) Company Limited',
    name: 'Mingfa Group (International) Company Limited',
    symbol: '0846.HK',
  },
  {
    value: '0848.HK',
    label: '0848.HK,  Maoye International Holdings Limited',
    name: 'Maoye International Holdings Limited',
    symbol: '0848.HK',
  },
  {
    value: '0851.HK',
    label: '0851.HK,  Sheng Yuan Holdings Limited',
    name: 'Sheng Yuan Holdings Limited',
    symbol: '0851.HK',
  },
  {
    value: '0852.HK',
    label: '0852.HK,  Strong Petrochemical Holdings Limited',
    name: 'Strong Petrochemical Holdings Limited',
    symbol: '0852.HK',
  },
  {
    value: '0854.HK',
    label: '0854.HK,  Willas-Array Electronics (Holdings) Limited',
    name: 'Willas-Array Electronics (Holdings) Limited',
    symbol: '0854.HK',
  },
  {
    value: '0855.HK',
    label: '0855.HK,  China Water Affairs Group Limited',
    name: 'China Water Affairs Group Limited',
    symbol: '0855.HK',
  },
  {
    value: '0856.HK',
    label: '0856.HK,  VSTECS Holdings Limited',
    name: 'VSTECS Holdings Limited',
    symbol: '0856.HK',
  },
  {
    value: '0858.HK',
    label: '0858.HK,  Extrawell Pharmaceutical Holdings Limited',
    name: 'Extrawell Pharmaceutical Holdings Limited',
    symbol: '0858.HK',
  },
  {
    value: '0860.HK',
    label: '0860.HK,  Apollo Future Mobility Group Limited',
    name: 'Apollo Future Mobility Group Limited',
    symbol: '0860.HK',
  },
  {
    value: '0861.HK',
    label: '0861.HK,  Digital China Holdings Limited',
    name: 'Digital China Holdings Limited',
    symbol: '0861.HK',
  },
  {
    value: '0862.HK',
    label: '0862.HK,  Vision Values Holdings Limited',
    name: 'Vision Values Holdings Limited',
    symbol: '0862.HK',
  },
  {
    value: '0863.HK',
    label: '0863.HK,  BC Technology Group Limited',
    name: 'BC Technology Group Limited',
    symbol: '0863.HK',
  },
  {
    value: '0864.HK',
    label: '0864.HK,  Wing Lee Property Investments Limited',
    name: 'Wing Lee Property Investments Limited',
    symbol: '0864.HK',
  },
  {
    value: '0865.HK',
    label: '0865.HK,  Jiande International Holdings Limited',
    name: 'Jiande International Holdings Limited',
    symbol: '0865.HK',
  },
  {
    value: '0866.HK',
    label: '0866.HK,  China Qinfa Group Limited',
    name: 'China Qinfa Group Limited',
    symbol: '0866.HK',
  },
  {
    value: '0867.HK',
    label: '0867.HK,  China Medical System Holdings Limited',
    name: 'China Medical System Holdings Limited',
    symbol: '0867.HK',
  },
  {
    value: '0868.HK',
    label: '0868.HK,  Xinyi Glass Holdings Limited',
    name: 'Xinyi Glass Holdings Limited',
    symbol: '0868.HK',
  },
  {
    value: '0869.HK',
    label: '0869.HK,  Playmates Toys Limited',
    name: 'Playmates Toys Limited',
    symbol: '0869.HK',
  },
  {
    value: '0871.HK',
    label: '0871.HK,  China Dredging Environment Protection Holdings Limited',
    name: 'China Dredging Environment Protection Holdings Limited',
    symbol: '0871.HK',
  },
  {
    value: '0874.HK',
    label:
      '0874.HK,  Guangzhou Baiyunshan Pharmaceutical Holdings Company Limited',
    name: 'Guangzhou Baiyunshan Pharmaceutical Holdings Company Limited',
    symbol: '0874.HK',
  },
  {
    value: '0875.HK',
    label: '0875.HK,  China Finance Investment Holdings Limited',
    name: 'China Finance Investment Holdings Limited',
    symbol: '0875.HK',
  },
  {
    value: '0876.HK',
    label: '0876.HK,  Kaisa Health Group Holdings Limited',
    name: 'Kaisa Health Group Holdings Limited',
    symbol: '0876.HK',
  },
  {
    value: '0878.HK',
    label: '0878.HK,  Soundwill Holdings Limited',
    name: 'Soundwill Holdings Limited',
    symbol: '0878.HK',
  },
  {
    value: '0880.HK',
    label: '0880.HK,  SJM Holdings Limited',
    name: 'SJM Holdings Limited',
    symbol: '0880.HK',
  },
  {
    value: '0881.HK',
    label: '0881.HK,  Zhongsheng Group Holdings Limited',
    name: 'Zhongsheng Group Holdings Limited',
    symbol: '0881.HK',
  },
  {
    value: '0882.HK',
    label: '0882.HK,  Tianjin Development Holdings Limited',
    name: 'Tianjin Development Holdings Limited',
    symbol: '0882.HK',
  },
  {
    value: '0884.HK',
    label: '0884.HK,  CIFI Holdings (Group) Co. Ltd.',
    name: 'CIFI Holdings (Group) Co. Ltd.',
    symbol: '0884.HK',
  },
  {
    value: '0887.HK',
    label: '0887.HK,  Emperor Watch & Jewellery Limited',
    name: 'Emperor Watch & Jewellery Limited',
    symbol: '0887.HK',
  },
  {
    value: '0888.HK',
    label: '0888.HK,  Bison Finance Group Limited',
    name: 'Bison Finance Group Limited',
    symbol: '0888.HK',
  },
  {
    value: '0889.HK',
    label: '0889.HK,  Datronix Holdings Limited',
    name: 'Datronix Holdings Limited',
    symbol: '0889.HK',
  },
  {
    value: '0893.HK',
    label: '0893.HK,  China Vanadium Titano-Magnetite Mining Company Limited',
    name: 'China Vanadium Titano-Magnetite Mining Company Limited',
    symbol: '0893.HK',
  },
  {
    value: '0894.HK',
    label: '0894.HK,  Man Yue Technology Holdings Limited',
    name: 'Man Yue Technology Holdings Limited',
    symbol: '0894.HK',
  },
  {
    value: '0895.HK',
    label: '0895.HK,  Dongjiang Environmental Company Limited',
    name: 'Dongjiang Environmental Company Limited',
    symbol: '0895.HK',
  },
  {
    value: '0896.HK',
    label: '0896.HK,  Hanison Construction Holdings Limited',
    name: 'Hanison Construction Holdings Limited',
    symbol: '0896.HK',
  },
  {
    value: '0897.HK',
    label: '0897.HK,  Wai Yuen Tong Medicine Holdings Limited',
    name: 'Wai Yuen Tong Medicine Holdings Limited',
    symbol: '0897.HK',
  },
  {
    value: '0898.HK',
    label: '0898.HK,  Multifield International Holdings Limited',
    name: 'Multifield International Holdings Limited',
    symbol: '0898.HK',
  },
  {
    value: '0899.HK',
    label: '0899.HK,  Asia Resources Holdings Limited',
    name: 'Asia Resources Holdings Limited',
    symbol: '0899.HK',
  },
  {
    value: '0900.HK',
    label: '0900.HK,  AEON Credit Service (Asia) Company Limited',
    name: 'AEON Credit Service (Asia) Company Limited',
    symbol: '0900.HK',
  },
  {
    value: '0901.HK',
    label: '0901.HK,  Goldstone Investment Group Limited',
    name: 'Goldstone Investment Group Limited',
    symbol: '0901.HK',
  },
  {
    value: '0902.HK',
    label: '0902.HK,  Huaneng Power International, Inc.',
    name: 'Huaneng Power International, Inc.',
    symbol: '0902.HK',
  },
  {
    value: '0905.HK',
    label: '0905.HK,  Walnut Capital Limited',
    name: 'Walnut Capital Limited',
    symbol: '0905.HK',
  },
  {
    value: '0906.HK',
    label: '0906.HK,  CPMC Holdings Limited',
    name: 'CPMC Holdings Limited',
    symbol: '0906.HK',
  },
  {
    value: '0907.HK',
    label: '0907.HK,  Elegance Optical International Holdings Limited',
    name: 'Elegance Optical International Holdings Limited',
    symbol: '0907.HK',
  },
  {
    value: '0910.HK',
    label: '0910.HK,  China Sandi Holdings Limited',
    name: 'China Sandi Holdings Limited',
    symbol: '0910.HK',
  },
  {
    value: '0911.HK',
    label: '0911.HK,  Qianhai Health Holdings Limited',
    name: 'Qianhai Health Holdings Limited',
    symbol: '0911.HK',
  },
  {
    value: '0912.HK',
    label: '0912.HK,  Suga International Holdings Limited',
    name: 'Suga International Holdings Limited',
    symbol: '0912.HK',
  },
  {
    value: '0913.HK',
    label: '0913.HK,  Harbour Digital Asset Capital Limited',
    name: 'Harbour Digital Asset Capital Limited',
    symbol: '0913.HK',
  },
  {
    value: '0915.HK',
    label: '0915.HK,  Daohe Global Group Limited',
    name: 'Daohe Global Group Limited',
    symbol: '0915.HK',
  },
  {
    value: '0916.HK',
    label: '0916.HK,  China Longyuan Power Group Corporation Limited',
    name: 'China Longyuan Power Group Corporation Limited',
    symbol: '0916.HK',
  },
  {
    value: '0918.HK',
    label: '0918.HK,  State Energy Group International Assets Holdings Limited',
    name: 'State Energy Group International Assets Holdings Limited',
    symbol: '0918.HK',
  },
  {
    value: '0919.HK',
    label: '0919.HK,  Modern Healthcare Technology Holdings Limited',
    name: 'Modern Healthcare Technology Holdings Limited',
    symbol: '0919.HK',
  },
  {
    value: '0921.HK',
    label: '0921.HK,  Hisense Home Appliances Group Co., Ltd.',
    name: 'Hisense Home Appliances Group Co., Ltd.',
    symbol: '0921.HK',
  },
  {
    value: '0922.HK',
    label: '0922.HK,  Anxian Yuan China Holdings Limited',
    name: 'Anxian Yuan China Holdings Limited',
    symbol: '0922.HK',
  },
  {
    value: '0923.HK',
    label: '0923.HK,  Integrated Waste Solutions Group Holdings Limited',
    name: 'Integrated Waste Solutions Group Holdings Limited',
    symbol: '0923.HK',
  },
  {
    value: '0924.HK',
    label: '0924.HK,  Khoon Group Limited',
    name: 'Khoon Group Limited',
    symbol: '0924.HK',
  },
  {
    value: '0925.HK',
    label: '0925.HK,  Beijing Properties (Holdings) Limited',
    name: 'Beijing Properties (Holdings) Limited',
    symbol: '0925.HK',
  },
  {
    value: '0926.HK',
    label: '0926.HK,  Besunyen Holdings Company Limited',
    name: 'Besunyen Holdings Company Limited',
    symbol: '0926.HK',
  },
  {
    value: '0927.HK',
    label: '0927.HK,  Fujikon Industrial Holdings Limited',
    name: 'Fujikon Industrial Holdings Limited',
    symbol: '0927.HK',
  },
  {
    value: '0928.HK',
    label: '0928.HK,  Life Healthcare Group Limited',
    name: 'Life Healthcare Group Limited',
    symbol: '0928.HK',
  },
  {
    value: '0929.HK',
    label: '0929.HK,  IPE Group Limited',
    name: 'IPE Group Limited',
    symbol: '0929.HK',
  },
  {
    value: '0931.HK',
    label: '0931.HK,  China LNG Group Limited',
    name: 'China LNG Group Limited',
    symbol: '0931.HK',
  },
  {
    value: '0932.HK',
    label: '0932.HK,  Shunten International (Holdings) Limited',
    name: 'Shunten International (Holdings) Limited',
    symbol: '0932.HK',
  },
  {
    value: '0934.HK',
    label: '0934.HK,  Sinopec Kantons Holdings Limited',
    name: 'Sinopec Kantons Holdings Limited',
    symbol: '0934.HK',
  },
  {
    value: '0936.HK',
    label: '0936.HK,  Kaisa Capital Investment Holdings Limited',
    name: 'Kaisa Capital Investment Holdings Limited',
    symbol: '0936.HK',
  },
  {
    value: '0938.HK',
    label: '0938.HK,  Man Sang International Limited',
    name: 'Man Sang International Limited',
    symbol: '0938.HK',
  },
  {
    value: '0945.HK',
    label: '0945.HK,  Manulife Financial Corporation',
    name: 'Manulife Financial Corporation',
    symbol: '0945.HK',
  },
  {
    value: '0947.HK',
    label: '0947.HK,  MOBI Development Co., Ltd.',
    name: 'MOBI Development Co., Ltd.',
    symbol: '0947.HK',
  },
  {
    value: '0948.HK',
    label: '0948.HK,  Alpha Professional Holdings Limited',
    name: 'Alpha Professional Holdings Limited',
    symbol: '0948.HK',
  },
  {
    value: '0950.HK',
    label: "0950.HK,  Lee's Pharmaceutical Holdings Limited",
    name: "Lee's Pharmaceutical Holdings Limited",
    symbol: '0950.HK',
  },
  {
    value: '0951.HK',
    label: '0951.HK,  Chaowei Power Holdings Limited',
    name: 'Chaowei Power Holdings Limited',
    symbol: '0951.HK',
  },
  {
    value: '0952.HK',
    label: '0952.HK,  China Tonghai International Financial Limited',
    name: 'China Tonghai International Financial Limited',
    symbol: '0952.HK',
  },
  {
    value: '0953.HK',
    label: '0953.HK,  Shaw Brothers Holdings Limited',
    name: 'Shaw Brothers Holdings Limited',
    symbol: '0953.HK',
  },
  {
    value: '0954.HK',
    label: '0954.HK,  Changmao Biochemical Engineering Company Limited',
    name: 'Changmao Biochemical Engineering Company Limited',
    symbol: '0954.HK',
  },
  {
    value: '0956.HK',
    label: '0956.HK,  China Suntien Green Energy Corporation Limited',
    name: 'China Suntien Green Energy Corporation Limited',
    symbol: '0956.HK',
  },
  {
    value: '0959.HK',
    label: '0959.HK,  Century Entertainment International Holdings Limited',
    name: 'Century Entertainment International Holdings Limited',
    symbol: '0959.HK',
  },
  {
    value: '0960.HK',
    label: '0960.HK,  Longfor Group Holdings Limited',
    name: 'Longfor Group Holdings Limited',
    symbol: '0960.HK',
  },
  {
    value: '0966.HK',
    label: '0966.HK,  China Taiping Insurance Holdings Company Limited',
    name: 'China Taiping Insurance Holdings Company Limited',
    symbol: '0966.HK',
  },
  {
    value: '0969.HK',
    label: '0969.HK,  Hua Lien International (Holding) Company Limited',
    name: 'Hua Lien International (Holding) Company Limited',
    symbol: '0969.HK',
  },
  {
    value: '0970.HK',
    label: '0970.HK,  New Sparkle Roll International Group Limited',
    name: 'New Sparkle Roll International Group Limited',
    symbol: '0970.HK',
  },
  {
    value: '0973.HK',
    label: "0973.HK,  L'Occitane International S.A.",
    name: "L'Occitane International S.A.",
    symbol: '0973.HK',
  },
  {
    value: '0974.HK',
    label: '0974.HK,  China Shun Ke Long Holdings Limited',
    name: 'China Shun Ke Long Holdings Limited',
    symbol: '0974.HK',
  },
  {
    value: '0975.HK',
    label: '0975.HK,  Mongolian Mining Corporation',
    name: 'Mongolian Mining Corporation',
    symbol: '0975.HK',
  },
  {
    value: '0976.HK',
    label: '0976.HK,  Chiho Environmental Group Limited',
    name: 'Chiho Environmental Group Limited',
    symbol: '0976.HK',
  },
  {
    value: '0978.HK',
    label: '0978.HK,  China Merchants Land Limited',
    name: 'China Merchants Land Limited',
    symbol: '0978.HK',
  },
  {
    value: '0979.HK',
    label: '0979.HK,  Green Energy Group Limited',
    name: 'Green Energy Group Limited',
    symbol: '0979.HK',
  },
  {
    value: '0980.HK',
    label: '0980.HK,  Lianhua Supermarket Holdings Co., Ltd.',
    name: 'Lianhua Supermarket Holdings Co., Ltd.',
    symbol: '0980.HK',
  },
  {
    value: '0982.HK',
    label: '0982.HK,  Huafa Property Services Group Company Limited',
    name: 'Huafa Property Services Group Company Limited',
    symbol: '0982.HK',
  },
  {
    value: '0983.HK',
    label: '0983.HK,  SOCAM Development Limited',
    name: 'SOCAM Development Limited',
    symbol: '0983.HK',
  },
  {
    value: '0984.HK',
    label: '0984.HK,  AEON Stores (Hong Kong) Co., Limited',
    name: 'AEON Stores (Hong Kong) Co., Limited',
    symbol: '0984.HK',
  },
  {
    value: '0985.HK',
    label: '0985.HK,  CST Group Limited',
    name: 'CST Group Limited',
    symbol: '0985.HK',
  },
  {
    value: '0986.HK',
    label: '0986.HK,  China Environmental Energy Investment Limited',
    name: 'China Environmental Energy Investment Limited',
    symbol: '0986.HK',
  },
  {
    value: '0987.HK',
    label: '0987.HK,  China Renewable Energy Investment Limited',
    name: 'China Renewable Energy Investment Limited',
    symbol: '0987.HK',
  },
  {
    value: '0989.HK',
    label: '0989.HK,  Hua Yin International Holdings Limited',
    name: 'Hua Yin International Holdings Limited',
    symbol: '0989.HK',
  },
  {
    value: '0990.HK',
    label: '0990.HK,  Theme International Holdings Limited',
    name: 'Theme International Holdings Limited',
    symbol: '0990.HK',
  },
  {
    value: '0991.HK',
    label: '0991.HK,  Datang International Power Generation Co., Ltd.',
    name: 'Datang International Power Generation Co., Ltd.',
    symbol: '0991.HK',
  },
  {
    value: '0993.HK',
    label: '0993.HK,  Huarong International Financial Holdings Limited',
    name: 'Huarong International Financial Holdings Limited',
    symbol: '0993.HK',
  },
  {
    value: '0994.HK',
    label: '0994.HK,  CT Vision S.L. (International) Holdings Limited',
    name: 'CT Vision S.L. (International) Holdings Limited',
    symbol: '0994.HK',
  },
  {
    value: '0995.HK',
    label: '0995.HK,  Anhui Expressway Company Limited',
    name: 'Anhui Expressway Company Limited',
    symbol: '0995.HK',
  },
  {
    value: '0997.HK',
    label: '0997.HK,  Chinlink International Holdings Limited',
    name: 'Chinlink International Holdings Limited',
    symbol: '0997.HK',
  },
  {
    value: 'ROUTE.NS',
    label: 'ROUTE.NS,  Route Mobile Limited',
    name: 'Route Mobile Limited',
    symbol: 'ROUTE.NS',
  },
  {
    value: 'LDI',
    label: 'LDI,  loanDepot, Inc.',
    name: 'loanDepot, Inc.',
    symbol: 'LDI',
  },
  {
    value: 'JOFFU',
    label: 'JOFFU,  JOFF Fintech Acquisition Corp.',
    name: 'JOFF Fintech Acquisition Corp.',
    symbol: 'JOFFU',
  },
  {
    value: 'ASAXU',
    label: 'ASAXU,  Astrea Acquisition Corp.',
    name: 'Astrea Acquisition Corp.',
    symbol: 'ASAXU',
  },
  {
    value: 'SNSE',
    label: 'SNSE,  Sensei Biotherapeutics, Inc.',
    name: 'Sensei Biotherapeutics, Inc.',
    symbol: 'SNSE',
  },
  {
    value: 'KINZ',
    label: 'KINZ,  KINS Technology Group Inc.',
    name: 'KINS Technology Group Inc.',
    symbol: 'KINZ',
  },
  {
    value: 'SANA',
    label: 'SANA,  Sana Biotechnology, Inc.',
    name: 'Sana Biotechnology, Inc.',
    symbol: 'SANA',
  },
  {
    value: 'LABP',
    label: 'LABP,  Landos Biopharma, Inc.',
    name: 'Landos Biopharma, Inc.',
    symbol: 'LABP',
  },
  {
    value: 'KINZW',
    label: 'KINZW,  KINS Technology Group Inc.',
    name: 'KINS Technology Group Inc.',
    symbol: 'KINZW',
  },
  {
    value: 'CVX.V',
    label: 'CVX.V,  CEMATRIX Corporation',
    name: 'CEMATRIX Corporation',
    symbol: 'CVX.V',
  },
  {
    value: 'DOC.V',
    label: 'DOC.V,  CloudMD Software & Services Inc.',
    name: 'CloudMD Software & Services Inc.',
    symbol: 'DOC.V',
  },
  {
    value: 'VXL.V',
    label: 'VXL.V,  Vaxil Bio Ltd.',
    name: 'Vaxil Bio Ltd.',
    symbol: 'VXL.V',
  },
  {
    value: 'ITQRU',
    label: 'ITQRU,  Itiquira Acquisition Corp.',
    name: 'Itiquira Acquisition Corp.',
    symbol: 'ITQRU',
  },
  {
    value: 'INTB3.SA',
    label:
      'INTB3.SA,  Intelbras S.A. - Indústria de Telecomunicação Eletrônica Brasileira',
    name: 'Intelbras S.A. - Indústria de Telecomunicação Eletrônica Brasileira',
    symbol: 'INTB3.SA',
  },
  {
    value: 'LGACU',
    label: 'LGACU,  Lazard Growth Acquisition Corp. I',
    name: 'Lazard Growth Acquisition Corp. I',
    symbol: 'LGACU',
  },
  {
    value: 'BPTS',
    label: 'BPTS,  Biophytis S.A.',
    name: 'Biophytis S.A.',
    symbol: 'BPTS',
  },
  {
    value: 'VLON',
    label: 'VLON,  Vallon Pharmaceuticals, Inc.',
    name: 'Vallon Pharmaceuticals, Inc.',
    symbol: 'VLON',
  },
  {
    value: 'CHKEL',
    label: 'CHKEL,  Chesapeake Energy Corporation',
    name: 'Chesapeake Energy Corporation',
    symbol: 'CHKEL',
  },
  {
    value: '6606.HK',
    label: '6606.HK,  New Horizon Health Limited',
    name: 'New Horizon Health Limited',
    symbol: '6606.HK',
  },
  {
    value: 'DSP',
    label: 'DSP,  Viant Technology Inc.',
    name: 'Viant Technology Inc.',
    symbol: 'DSP',
  },
  {
    value: 'CHKEZ',
    label: 'CHKEZ,  Chesapeake Energy Corporation',
    name: 'Chesapeake Energy Corporation',
    symbol: 'CHKEZ',
  },
  {
    value: 'CHKEW',
    label: 'CHKEW,  Chesapeake Energy Corporation',
    name: 'Chesapeake Energy Corporation',
    symbol: 'CHKEW',
  },
  {
    value: 'SCOBU',
    label: 'SCOBU,  ScION Tech Growth II',
    name: 'ScION Tech Growth II',
    symbol: 'SCOBU',
  },
  {
    value: 'BMOB3.SA',
    label: 'BMOB3.SA,  Bemobi Mobile Tech S.A.',
    name: 'Bemobi Mobile Tech S.A.',
    symbol: 'BMOB3.SA',
  },
  {
    value: 'PMGMU',
    label: 'PMGMU,  Priveterra Acquisition Corp.',
    name: 'Priveterra Acquisition Corp.',
    symbol: 'PMGMU',
  },
  {
    value: 'PGRWU',
    label: 'PGRWU,  Progress Acquisition Corp.',
    name: 'Progress Acquisition Corp.',
    symbol: 'PGRWU',
  },
  {
    value: 'RELI',
    label: 'RELI,  Reliance Global Group, Inc.',
    name: 'Reliance Global Group, Inc.',
    symbol: 'RELI',
  },
  {
    value: 'ADAG',
    label: 'ADAG,  Adagene Inc.',
    name: 'Adagene Inc.',
    symbol: 'ADAG',
  },
  {
    value: 'VENAU',
    label: 'VENAU,  Venus Acquisition Corporation',
    name: 'Venus Acquisition Corporation',
    symbol: 'VENAU',
  },
  {
    value: 'RELIW',
    label: 'RELIW,  Reliance Global Group, Inc.',
    name: 'Reliance Global Group, Inc.',
    symbol: 'RELIW',
  },
  {
    value: 'EVOJU',
    label: 'EVOJU,  Evo Acquisition Corp.',
    name: 'Evo Acquisition Corp.',
    symbol: 'EVOJU',
  },
  {
    value: 'BMBL',
    label: 'BMBL,  Bumble Inc.',
    name: 'Bumble Inc.',
    symbol: 'BMBL',
  },
  {
    value: 'AFCG',
    label: 'AFCG,  AFC Gamma, Inc.',
    name: 'AFC Gamma, Inc.',
    symbol: 'AFCG',
  },
  {
    value: 'SGFY',
    label: 'SGFY,  Signify Health, Inc.',
    name: 'Signify Health, Inc.',
    symbol: 'SGFY',
  },
  {
    value: 'PUCKU',
    label: 'PUCKU,  Goal Acquisitions Corp.',
    name: 'Goal Acquisitions Corp.',
    symbol: 'PUCKU',
  },
  {
    value: 'MOON',
    label: 'MOON,  Direxion Moonshot Innovators ETF',
    name: 'Direxion Moonshot Innovators ETF',
    symbol: 'MOON',
  },
  {
    value: 'XM',
    label: 'XM,  Qualtrics International Inc.',
    name: 'Qualtrics International Inc.',
    symbol: 'XM',
  },
  {
    value: 'BVS',
    label: 'BVS,  Bioventus Inc.',
    name: 'Bioventus Inc.',
    symbol: 'BVS',
  },
  {
    value: 'WEST3.SA',
    label: 'WEST3.SA,  Westwing Comércio Varejista S.A.',
    name: 'Westwing Comércio Varejista S.A.',
    symbol: 'WEST3.SA',
  },
  {
    value: 'VIIAW',
    label: 'VIIAW,  7GC & Co. Holdings Inc.',
    name: '7GC & Co. Holdings Inc.',
    symbol: 'VIIAW',
  },
  {
    value: 'TLIS',
    label: 'TLIS,  Talis Biomedical Corporation',
    name: 'Talis Biomedical Corporation',
    symbol: 'TLIS',
  },
  {
    value: 'RCLFU',
    label: 'RCLFU,  Rosecliff Acquisition Corp I',
    name: 'Rosecliff Acquisition Corp I',
    symbol: 'RCLFU',
  },
  {
    value: 'DBTX',
    label: 'DBTX,  Decibel Therapeutics, Inc.',
    name: 'Decibel Therapeutics, Inc.',
    symbol: 'DBTX',
  },
  {
    value: 'NEXI',
    label: 'NEXI,  NexImmune, Inc.',
    name: 'NexImmune, Inc.',
    symbol: 'NEXI',
  },
  {
    value: 'SCLEU',
    label: 'SCLEU,  Broadscale Acquisition Corp.',
    name: 'Broadscale Acquisition Corp.',
    symbol: 'SCLEU',
  },
  {
    value: 'TSIBU',
    label: 'TSIBU,  Tishman Speyer Innovation Corp. II',
    name: 'Tishman Speyer Innovation Corp. II',
    symbol: 'TSIBU',
  },
  {
    value: 'OPCT3.SA',
    label: 'OPCT3.SA,  OceanPact Serviços Marítimos S.A.',
    name: 'OceanPact Serviços Marítimos S.A.',
    symbol: 'OPCT3.SA',
  },
  {
    value: 'LGVN',
    label: 'LGVN,  Longeveron Inc.',
    name: 'Longeveron Inc.',
    symbol: 'LGVN',
  },
  {
    value: 'HUNT.CN',
    label: 'HUNT.CN,  Gold Hunter Resources Inc.',
    name: 'Gold Hunter Resources Inc.',
    symbol: 'HUNT.CN',
  },
  {
    value: 'APPH',
    label: 'APPH,  AppHarvest, Inc.',
    name: 'AppHarvest, Inc.',
    symbol: 'APPH',
  },
  {
    value: 'LAZR',
    label: 'LAZR,  Luminar Technologies, Inc.',
    name: 'Luminar Technologies, Inc.',
    symbol: 'LAZR',
  },
  {
    value: 'ORVR3.SA',
    label: 'ORVR3.SA,  Orizon Valorização de Resíduos S.A.',
    name: 'Orizon Valorização de Resíduos S.A.',
    symbol: 'ORVR3.SA',
  },
  {
    value: 'ELMD3.SA',
    label: 'ELMD3.SA,  Eletromidia S.A.',
    name: 'Eletromidia S.A.',
    symbol: 'ELMD3.SA',
  },
  {
    value: 'PFDRU',
    label: 'PFDRU,  Pathfinder Acquisition Corporation',
    name: 'Pathfinder Acquisition Corporation',
    symbol: 'PFDRU',
  },
  {
    value: 'AEI',
    label: 'AEI,  Alset Inc.',
    name: 'Alset Inc.',
    symbol: 'AEI',
  },
  {
    value: 'ABGI',
    label: 'ABGI,  ABG Acquisition Corp. I',
    name: 'ABG Acquisition Corp. I',
    symbol: 'ABGI',
  },
  {
    value: 'MACAU',
    label: 'MACAU,  Moringa Acquisition Corp',
    name: 'Moringa Acquisition Corp',
    symbol: 'MACAU',
  },
  {
    value: 'NVSAU',
    label: 'NVSAU,  New Vista Acquisition Corp',
    name: 'New Vista Acquisition Corp',
    symbol: 'NVSAU',
  },
  {
    value: 'VRPX',
    label: 'VRPX,  Virpax Pharmaceuticals, Inc.',
    name: 'Virpax Pharmaceuticals, Inc.',
    symbol: 'VRPX',
  },
  {
    value: 'CFIV',
    label: 'CFIV,  CF Acquisition Corp. IV',
    name: 'CF Acquisition Corp. IV',
    symbol: 'CFIV',
  },
  {
    value: 'CFIVW',
    label: 'CFIVW,  CF Acquisition Corp. IV',
    name: 'CF Acquisition Corp. IV',
    symbol: 'CFIVW',
  },
  {
    value: 'CORD.L',
    label: 'CORD.L,  Cordiant Digital Infrastructure Limited',
    name: 'Cordiant Digital Infrastructure Limited',
    symbol: 'CORD.L',
  },
  {
    value: 'FSRXU',
    label: 'FSRXU,  FinServ Acquisition Corp. II',
    name: 'FinServ Acquisition Corp. II',
    symbol: 'FSRXU',
  },
  {
    value: 'KAIIU',
    label: 'KAIIU,  Kismet Acquisition Two Corp.',
    name: 'Kismet Acquisition Two Corp.',
    symbol: 'KAIIU',
  },
  {
    value: 'PPTA',
    label: 'PPTA,  Perpetua Resources Corp.',
    name: 'Perpetua Resources Corp.',
    symbol: 'PPTA',
  },
  {
    value: 'KIIIU',
    label: 'KIIIU,  Kismet Acquisition Three Corp.',
    name: 'Kismet Acquisition Three Corp.',
    symbol: 'KIIIU',
  },
  {
    value: 'CMIN3.SA',
    label: 'CMIN3.SA,  CSN Mineração S.A.',
    name: 'CSN Mineração S.A.',
    symbol: 'CMIN3.SA',
  },
  {
    value: 'AUUDW',
    label: 'AUUDW,  Auddia Inc.',
    name: 'Auddia Inc.',
    symbol: 'AUUDW',
  },
  {
    value: 'AMPGW',
    label: 'AMPGW,  AmpliTech Group, Inc.',
    name: 'AmpliTech Group, Inc.',
    symbol: 'AMPGW',
  },
  {
    value: 'AUUD',
    label: 'AUUD,  Auddia Inc.',
    name: 'Auddia Inc.',
    symbol: 'AUUD',
  },
  {
    value: 'SPTKU',
    label: 'SPTKU,  SportsTek Acquisition Corp.',
    name: 'SportsTek Acquisition Corp.',
    symbol: 'SPTKU',
  },
  {
    value: 'AFISH.OL',
    label: 'AFISH.OL,  Arctic Fish Holding AS',
    name: 'Arctic Fish Holding AS',
    symbol: 'AFISH.OL',
  },
  {
    value: 'GOEV',
    label: 'GOEV,  Canoo Inc.',
    name: 'Canoo Inc.',
    symbol: 'GOEV',
  },
  {
    value: 'DUNEW',
    label: 'DUNEW,  Dune Acquisition Corporation',
    name: 'Dune Acquisition Corporation',
    symbol: 'DUNEW',
  },
  {
    value: 'COOLW',
    label: 'COOLW,  Corner Growth Acquisition Corp.',
    name: 'Corner Growth Acquisition Corp.',
    symbol: 'COOLW',
  },
  {
    value: 'DUNE',
    label: 'DUNE,  Dune Acquisition Corporation',
    name: 'Dune Acquisition Corporation',
    symbol: 'DUNE',
  },
  {
    value: 'DM',
    label: 'DM,  Desktop Metal, Inc.',
    name: 'Desktop Metal, Inc.',
    symbol: 'DM',
  },
  {
    value: 'HLFFF',
    label: 'HLFFF,  HelloFresh SE',
    name: 'HelloFresh SE',
    symbol: 'HLFFF',
  },
  {
    value: 'BFLY',
    label: 'BFLY,  Butterfly Network, Inc.',
    name: 'Butterfly Network, Inc.',
    symbol: 'BFLY',
  },
  {
    value: 'SLAMU',
    label: 'SLAMU,  Slam Corp.',
    name: 'Slam Corp.',
    symbol: 'SLAMU',
  },
  {
    value: 'GSEVU',
    label: 'GSEVU,  Gores Holdings VII, Inc.',
    name: 'Gores Holdings VII, Inc.',
    symbol: 'GSEVU',
  },
  {
    value: 'GIIXU',
    label: 'GIIXU,  Gores Holdings VIII, Inc.',
    name: 'Gores Holdings VIII, Inc.',
    symbol: 'GIIXU',
  },
  {
    value: 'VELOU',
    label: 'VELOU,  Velocity Acquisition Corp.',
    name: 'Velocity Acquisition Corp.',
    symbol: 'VELOU',
  },
  {
    value: 'FTAAU',
    label: 'FTAAU,  FTAC Athena Acquisition Corp.',
    name: 'FTAC Athena Acquisition Corp.',
    symbol: 'FTAAU',
  },
  {
    value: 'ABCT.TO',
    label: 'ABCT.TO,  ABC Technologies Holdings Inc.',
    name: 'ABC Technologies Holdings Inc.',
    symbol: 'ABCT.TO',
  },
  {
    value: 'COLIU',
    label: 'COLIU,  Colicity Inc.',
    name: 'Colicity Inc.',
    symbol: 'COLIU',
  },
  {
    value: 'GLBLU',
    label: 'GLBLU,  Cartesian Growth Corporation',
    name: 'Cartesian Growth Corporation',
    symbol: 'GLBLU',
  },
  {
    value: 'KEJI',
    label: 'KEJI,  Global X China Innovation ETF',
    name: 'Global X China Innovation ETF',
    symbol: 'KEJI',
  },
  {
    value: 'HIIIU',
    label: 'HIIIU,  Hudson Executive Investment Corp. III',
    name: 'Hudson Executive Investment Corp. III',
    symbol: 'HIIIU',
  },
  {
    value: '6600.HK',
    label: '6600.HK,  SciClone Pharmaceuticals (Holdings) Limited',
    name: 'SciClone Pharmaceuticals (Holdings) Limited',
    symbol: '6600.HK',
  },
  {
    value: 'IDBA',
    label: 'IDBA,  IDEX Biometrics ASA',
    name: 'IDEX Biometrics ASA',
    symbol: 'IDBA',
  },
  {
    value: 'REIT',
    label: 'REIT,  ALPS Active REIT ETF',
    name: 'ALPS Active REIT ETF',
    symbol: 'REIT',
  },
  {
    value: 'AKICW',
    label: 'AKICW,  Sports Ventures Acquisition Corp.',
    name: 'Sports Ventures Acquisition Corp.',
    symbol: 'AKICW',
  },
  {
    value: 'MFB.NZ',
    label: 'MFB.NZ,  My Food Bag Group Limited',
    name: 'My Food Bag Group Limited',
    symbol: 'MFB.NZ',
  },
  {
    value: 'KAIR',
    label: 'KAIR,  Kairos Acquisition Corp.',
    name: 'Kairos Acquisition Corp.',
    symbol: 'KAIR',
  },
  {
    value: 'AKIC',
    label: 'AKIC,  Sports Ventures Acquisition Corp.',
    name: 'Sports Ventures Acquisition Corp.',
    symbol: 'AKIC',
  },
  {
    value: 'TWLVU',
    label: 'TWLVU,  Twelve Seas Investment Company II',
    name: 'Twelve Seas Investment Company II',
    symbol: 'TWLVU',
  },
  {
    value: 'KAIRW',
    label: 'KAIRW,  Kairos Acquisition Corp.',
    name: 'Kairos Acquisition Corp.',
    symbol: 'KAIRW',
  },
  {
    value: 'ARYD',
    label: 'ARYD,  ARYA Sciences Acquisition Corp IV',
    name: 'ARYA Sciences Acquisition Corp IV',
    symbol: 'ARYD',
  },
  {
    value: 'ASZ-UN',
    label: 'ASZ-UN,  Austerlitz Acquisition Corporation II',
    name: 'Austerlitz Acquisition Corporation II',
    symbol: 'ASZ-UN',
  },
  {
    value: 'AUS-UN',
    label: 'AUS-UN,  Austerlitz Acquisition Corporation I',
    name: 'Austerlitz Acquisition Corporation I',
    symbol: 'AUS-UN',
  },
  {
    value: 'EACPW',
    label: 'EACPW,  Edify Acquisition Corp.',
    name: 'Edify Acquisition Corp.',
    symbol: 'EACPW',
  },
  {
    value: 'TMKR',
    label: 'TMKR,  Tastemaker Acquisition Corp.',
    name: 'Tastemaker Acquisition Corp.',
    symbol: 'TMKR',
  },
  {
    value: 'PRSR',
    label: 'PRSR,  Prospector Capital Corp.',
    name: 'Prospector Capital Corp.',
    symbol: 'PRSR',
  },
  {
    value: 'KLAQW',
    label: 'KLAQW,  KL Acquisition Corp',
    name: 'KL Acquisition Corp',
    symbol: 'KLAQW',
  },
  {
    value: 'KLAQ',
    label: 'KLAQ,  KL Acquisition Corp',
    name: 'KL Acquisition Corp',
    symbol: 'KLAQ',
  },
  {
    value: 'PRSRW',
    label: 'PRSRW,  Prospector Capital Corp.',
    name: 'Prospector Capital Corp.',
    symbol: 'PRSRW',
  },
  {
    value: 'EPHY',
    label: 'EPHY,  Epiphany Technology Acquisition Corp.',
    name: 'Epiphany Technology Acquisition Corp.',
    symbol: 'EPHY',
  },
  {
    value: 'EPHYW',
    label: 'EPHYW,  Epiphany Technology Acquisition Corp.',
    name: 'Epiphany Technology Acquisition Corp.',
    symbol: 'EPHYW',
  },
  {
    value: 'TMKRW',
    label: 'TMKRW,  Tastemaker Acquisition Corp.',
    name: 'Tastemaker Acquisition Corp.',
    symbol: 'TMKRW',
  },
  {
    value: 'DHBCU',
    label: 'DHBCU,  DHB Capital Corp.',
    name: 'DHB Capital Corp.',
    symbol: 'DHBCU',
  },
  {
    value: 'DHCAU',
    label: 'DHCAU,  DHC Acquisition Corp.',
    name: 'DHC Acquisition Corp.',
    symbol: 'DHCAU',
  },
  {
    value: 'OHPAU',
    label: 'OHPAU,  Orion Acquisition Corp.',
    name: 'Orion Acquisition Corp.',
    symbol: 'OHPAU',
  },
  {
    value: 'RNRG',
    label: 'RNRG,  Global X Renewable Energy Producers ETF',
    name: 'Global X Renewable Energy Producers ETF',
    symbol: 'RNRG',
  },
  {
    value: 'KFVG',
    label: 'KFVG,  KraneShares CICC China 5G & Semiconductor Index ETF',
    name: 'KraneShares CICC China 5G & Semiconductor Index ETF',
    symbol: 'KFVG',
  },
  {
    value: 'ACAHU',
    label: 'ACAHU,  Atlantic Coastal Acquisition Corp.',
    name: 'Atlantic Coastal Acquisition Corp.',
    symbol: 'ACAHU',
  },
  {
    value: 'AURCU',
    label: 'AURCU,  Aurora Acquisition Corp.',
    name: 'Aurora Acquisition Corp.',
    symbol: 'AURCU',
  },
  {
    value: 'NDACU',
    label: 'NDACU,  NightDragon Acquisition Corp.',
    name: 'NightDragon Acquisition Corp.',
    symbol: 'NDACU',
  },
  {
    value: 'ARRWU',
    label: 'ARRWU,  Arrowroot Acquisition Corp.',
    name: 'Arrowroot Acquisition Corp.',
    symbol: 'ARRWU',
  },
  {
    value: 'GBRGU',
    label: 'GBRGU,  Goldenbridge Acquisition Limited',
    name: 'Goldenbridge Acquisition Limited',
    symbol: 'GBRGU',
  },
  {
    value: 'LJAQW',
    label: 'LJAQW,  LightJump Acquisition Corporation',
    name: 'LightJump Acquisition Corporation',
    symbol: 'LJAQW',
  },
  {
    value: 'LJAQ',
    label: 'LJAQ,  LightJump Acquisition Corporation',
    name: 'LightJump Acquisition Corporation',
    symbol: 'LJAQ',
  },
  {
    value: 'IMCC',
    label: 'IMCC,  IM Cannabis Corp.',
    name: 'IM Cannabis Corp.',
    symbol: 'IMCC',
  },
  {
    value: 'BLTSW',
    label: 'BLTSW,  Bright Lights Acquisition Corp.',
    name: 'Bright Lights Acquisition Corp.',
    symbol: 'BLTSW',
  },
  {
    value: 'EAC',
    label: 'EAC,  Edify Acquisition Corp.',
    name: 'Edify Acquisition Corp.',
    symbol: 'EAC',
  },
  {
    value: 'PACXW',
    label: 'PACXW,  Pioneer Merger Corp.',
    name: 'Pioneer Merger Corp.',
    symbol: 'PACXW',
  },
  {
    value: 'INKA',
    label: 'INKA,  KludeIn I Acquisition Corp.',
    name: 'KludeIn I Acquisition Corp.',
    symbol: 'INKA',
  },
  {
    value: 'PACX',
    label: 'PACX,  Pioneer Merger Corp.',
    name: 'Pioneer Merger Corp.',
    symbol: 'PACX',
  },
  {
    value: 'INKAW',
    label: 'INKAW,  Kludein I Acquisition Corp.',
    name: 'Kludein I Acquisition Corp.',
    symbol: 'INKAW',
  },
  {
    value: 'OSCR',
    label: 'OSCR,  Oscar Health, Inc.',
    name: 'Oscar Health, Inc.',
    symbol: 'OSCR',
  },
  {
    value: '6601.HK',
    label: '6601.HK,  Cheerwin Group Limited',
    name: 'Cheerwin Group Limited',
    symbol: '6601.HK',
  },
  {
    value: 'POW',
    label: 'POW,  Powered Brands',
    name: 'Powered Brands',
    symbol: 'POW',
  },
  {
    value: 'VCKA',
    label: 'VCKA,  Vickers Vantage Corp. I',
    name: 'Vickers Vantage Corp. I',
    symbol: 'VCKA',
  },
  {
    value: 'VCKAW',
    label: 'VCKAW,  Vickers Vantage Corp. I',
    name: 'Vickers Vantage Corp. I',
    symbol: 'VCKAW',
  },
  {
    value: 'KVSA',
    label: 'KVSA,  Khosla Ventures Acquisition Co.',
    name: 'Khosla Ventures Acquisition Co.',
    symbol: 'KVSA',
  },
  {
    value: 'POWRW',
    label: 'POWRW,  Powered Brands',
    name: 'Powered Brands',
    symbol: 'POWRW',
  },
  {
    value: 'ANZUU',
    label: 'ANZUU,  Anzu Special Acquisition Corp I',
    name: 'Anzu Special Acquisition Corp I',
    symbol: 'ANZUU',
  },
  {
    value: 'PSAGU',
    label: 'PSAGU,  Property Solutions Acquisition Corp. II',
    name: 'Property Solutions Acquisition Corp. II',
    symbol: 'PSAGU',
  },
  {
    value: 'HERAU',
    label: 'HERAU,  FTAC Hera Acquisition Corp.',
    name: 'FTAC Hera Acquisition Corp.',
    symbol: 'HERAU',
  },
  {
    value: 'RXRAU',
    label: 'RXRAU,  RXR Acquisition Corp.',
    name: 'RXR Acquisition Corp.',
    symbol: 'RXRAU',
  },
  {
    value: 'INNV',
    label: 'INNV,  InnovAge Holding Corp.',
    name: 'InnovAge Holding Corp.',
    symbol: 'INNV',
  },
  {
    value: 'LHAA',
    label: 'LHAA,  Lerer Hippeau Acquisition Corp.',
    name: 'Lerer Hippeau Acquisition Corp.',
    symbol: 'LHAA',
  },
  {
    value: 'GPACW',
    label: 'GPACW,  Global Partner Acquisition Corp II',
    name: 'Global Partner Acquisition Corp II',
    symbol: 'GPACW',
  },
  {
    value: 'GPAC',
    label: 'GPAC,  Global Partner Acquisition Corp II',
    name: 'Global Partner Acquisition Corp II',
    symbol: 'GPAC',
  },
  {
    value: 'BUZZ',
    label: 'BUZZ,  VanEck Social Sentiment ETF',
    name: 'VanEck Social Sentiment ETF',
    symbol: 'BUZZ',
  },
  {
    value: '1413.HK',
    label: '1413.HK,  Kwong Luen Engineering Holdings Limited',
    name: 'Kwong Luen Engineering Holdings Limited',
    symbol: '1413.HK',
  },
  {
    value: '1927.HK',
    label: '1927.HK,  Jiujiuwang Food International Limited',
    name: 'Jiujiuwang Food International Limited',
    symbol: '1927.HK',
  },
  {
    value: 'SVFB',
    label: 'SVFB,  SVF Investment Corp. 2',
    name: 'SVF Investment Corp. 2',
    symbol: 'SVFB',
  },
  {
    value: 'SWETW',
    label: 'SWETW,  Athlon Acquisition Corp.',
    name: 'Athlon Acquisition Corp.',
    symbol: 'SWETW',
  },
  {
    value: 'SWET',
    label: 'SWET,  Athlon Acquisition Corp.',
    name: 'Athlon Acquisition Corp.',
    symbol: 'SWET',
  },
  {
    value: 'DLCA',
    label: 'DLCA,  Deep Lake Capital Acquisition Corp.',
    name: 'Deep Lake Capital Acquisition Corp.',
    symbol: 'DLCA',
  },
  {
    value: 'DLCAW',
    label: 'DLCAW,  Deep Lake Capital Acquisition Corp.',
    name: 'Deep Lake Capital Acquisition Corp.',
    symbol: 'DLCAW',
  },
  {
    value: 'ACQRU',
    label: 'ACQRU,  Independence Holdings Corp.',
    name: 'Independence Holdings Corp.',
    symbol: 'ACQRU',
  },
  {
    value: 'VPCBU',
    label: 'VPCBU,  VPC Impact Acquisition Holdings II',
    name: 'VPC Impact Acquisition Holdings II',
    symbol: 'VPCBU',
  },
  {
    value: 'IPVIU',
    label: 'IPVIU,  InterPrivate IV InfraTech Partners Inc.',
    name: 'InterPrivate IV InfraTech Partners Inc.',
    symbol: 'IPVIU',
  },
  {
    value: 'FINM',
    label: 'FINM,  Marlin Technology Corporation',
    name: 'Marlin Technology Corporation',
    symbol: 'FINM',
  },
  {
    value: 'FINMW',
    label: 'FINMW,  Marlin Technology Corporation',
    name: 'Marlin Technology Corporation',
    symbol: 'FINMW',
  },
  {
    value: 'HLAH',
    label: 'HLAH,  Hamilton Lane Alliance Holdings I, Inc.',
    name: 'Hamilton Lane Alliance Holdings I, Inc.',
    symbol: 'HLAH',
  },
  {
    value: 'FRSGU',
    label: 'FRSGU,  First Reserve Sustainable Growth Corp.',
    name: 'First Reserve Sustainable Growth Corp.',
    symbol: 'FRSGU',
  },
  {
    value: 'GNAC',
    label: 'GNAC,  Group Nine Acquisition Corp.',
    name: 'Group Nine Acquisition Corp.',
    symbol: 'GNAC',
  },
  {
    value: 'AEAC',
    label: 'AEAC,  Authentic Equity Acquisition Corp.',
    name: 'Authentic Equity Acquisition Corp.',
    symbol: 'AEAC',
  },
  {
    value: 'GNACW',
    label: 'GNACW,  Group Nine Acquisition Corp.',
    name: 'Group Nine Acquisition Corp.',
    symbol: 'GNACW',
  },
  {
    value: 'HCIC',
    label: 'HCIC,  Hennessy Capital Investment Corp. V',
    name: 'Hennessy Capital Investment Corp. V',
    symbol: 'HCIC',
  },
  {
    value: 'HCICW',
    label: 'HCICW,  Hennessy Capital Investment Corp. V',
    name: 'Hennessy Capital Investment Corp. V',
    symbol: 'HCICW',
  },
  {
    value: 'ADERW',
    label: 'ADERW,  26 Capital Acquisition Corp.',
    name: '26 Capital Acquisition Corp.',
    symbol: 'ADERW',
  },
  {
    value: 'ADER',
    label: 'ADER,  26 Capital Acquisition Corp.',
    name: '26 Capital Acquisition Corp.',
    symbol: 'ADER',
  },
  {
    value: 'AGGRU',
    label: 'AGGRU,  Agile Growth Corp.',
    name: 'Agile Growth Corp.',
    symbol: 'AGGRU',
  },
  {
    value: 'MNOW.V',
    label: 'MNOW.V,  Mednow Inc.',
    name: 'Mednow Inc.',
    symbol: 'MNOW.V',
  },
  {
    value: 'VAQC',
    label: 'VAQC,  Vector Acquisition Corporation II',
    name: 'Vector Acquisition Corporation II',
    symbol: 'VAQC',
  },
  {
    value: 'RBLX',
    label: 'RBLX,  Roblox Corporation',
    name: 'Roblox Corporation',
    symbol: 'RBLX',
  },
  {
    value: 'OCAXW',
    label: 'OCAXW,  OCA Acquisition Corp.',
    name: 'OCA Acquisition Corp.',
    symbol: 'OCAXW',
  },
  {
    value: 'OCAX',
    label: 'OCAX,  OCA Acquisition Corp.',
    name: 'OCA Acquisition Corp.',
    symbol: 'OCAX',
  },
  {
    value: 'AEACW',
    label: 'AEACW,  Authentic Equity Acquisition Corp.',
    name: 'Authentic Equity Acquisition Corp.',
    symbol: 'AEACW',
  },
  {
    value: 'RSF.CN',
    label: 'RSF.CN,  Rritual Superfoods Inc.',
    name: 'Rritual Superfoods Inc.',
    symbol: 'RSF.CN',
  },
  {
    value: 'DTOCU',
    label: 'DTOCU,  Digital Transformation Opportunities Corp.',
    name: 'Digital Transformation Opportunities Corp.',
    symbol: 'DTOCU',
  },
  {
    value: 'FRWAU',
    label: 'FRWAU,  PWP Forward Acquisition Corp. I',
    name: 'PWP Forward Acquisition Corp. I',
    symbol: 'FRWAU',
  },
  {
    value: 'JWEL',
    label: 'JWEL,  Jowell Global Ltd.',
    name: 'Jowell Global Ltd.',
    symbol: 'JWEL',
  },
  {
    value: 'EPRX.TO',
    label: 'EPRX.TO,  Eupraxia Pharmaceuticals Inc.',
    name: 'Eupraxia Pharmaceuticals Inc.',
    symbol: 'EPRX.TO',
  },
  {
    value: 'FHS',
    label: 'FHS,  First High-School Education Group Co., Ltd.',
    name: 'First High-School Education Group Co., Ltd.',
    symbol: 'FHS',
  },
  {
    value: 'CPNG',
    label: 'CPNG,  Coupang, Inc.',
    name: 'Coupang, Inc.',
    symbol: 'CPNG',
  },
  {
    value: 'PTOCU',
    label: 'PTOCU,  Pine Technology Acquisition Corp.',
    name: 'Pine Technology Acquisition Corp.',
    symbol: 'PTOCU',
  },
  {
    value: 'RXDX',
    label: 'RXDX,  Prometheus Biosciences, Inc.',
    name: 'Prometheus Biosciences, Inc.',
    symbol: 'RXDX',
  },
  {
    value: 'ASXC',
    label: 'ASXC,  Asensus Surgical, Inc.',
    name: 'Asensus Surgical, Inc.',
    symbol: 'ASXC',
  },
  {
    value: 'PYR',
    label: 'PYR,  PyroGenesis Canada Inc.',
    name: 'PyroGenesis Canada Inc.',
    symbol: 'PYR',
  },
  {
    value: 'SPKBU',
    label: 'SPKBU,  Silver Spike Acquisition Corp II',
    name: 'Silver Spike Acquisition Corp II',
    symbol: 'SPKBU',
  },
  {
    value: 'LCAAU',
    label: 'LCAAU,  L Catterton Asia Acquisition Corp',
    name: 'L Catterton Asia Acquisition Corp',
    symbol: 'LCAAU',
  },
  {
    value: 'FRONU',
    label: 'FRONU,  Frontier Acquisition Corp.',
    name: 'Frontier Acquisition Corp.',
    symbol: 'FRONU',
  },
  {
    value: 'ESM-UN',
    label: 'ESM-UN,  ESM Acquisition Corporation',
    name: 'ESM Acquisition Corporation',
    symbol: 'ESM-UN',
  },
  {
    value: 'HAYW',
    label: 'HAYW,  Hayward Holdings, Inc.',
    name: 'Hayward Holdings, Inc.',
    symbol: 'HAYW',
  },
  {
    value: 'GTPAU',
    label: 'GTPAU,  Gores Technology Partners, Inc.',
    name: 'Gores Technology Partners, Inc.',
    symbol: 'GTPAU',
  },
  {
    value: 'JOAN',
    label: 'JOAN,  JOANN Inc.',
    name: 'JOANN Inc.',
    symbol: 'JOAN',
  },
  {
    value: 'SCAQU',
    label: 'SCAQU,  Stratim Cloud Acquisition Corp.',
    name: 'Stratim Cloud Acquisition Corp.',
    symbol: 'SCAQU',
  },
  {
    value: 'TETCU',
    label: 'TETCU,  Tech and Energy Transition Corporation',
    name: 'Tech and Energy Transition Corporation',
    symbol: 'TETCU',
  },
  {
    value: 'GLHAU',
    label: 'GLHAU,  Glass Houses Acquisition Corp.',
    name: 'Glass Houses Acquisition Corp.',
    symbol: 'GLHAU',
  },
  {
    value: 'SNCY',
    label: 'SNCY,  Sun Country Airlines Holdings, Inc.',
    name: 'Sun Country Airlines Holdings, Inc.',
    symbol: 'SNCY',
  },
  {
    value: 'GTPBU',
    label: 'GTPBU,  Gores Technology Partners II, Inc.',
    name: 'Gores Technology Partners II, Inc.',
    symbol: 'GTPBU',
  },
  {
    value: 'CFFEU',
    label: 'CFFEU,  CF Acquisition Corp. VIII',
    name: 'CF Acquisition Corp. VIII',
    symbol: 'CFFEU',
  },
  {
    value: 'FTPAU',
    label: 'FTPAU,  FTAC Parnassus Acquisition Corp.',
    name: 'FTAC Parnassus Acquisition Corp.',
    symbol: 'FTPAU',
  },
  {
    value: 'LBPH',
    label: 'LBPH,  Longboard Pharmaceuticals, Inc.',
    name: 'Longboard Pharmaceuticals, Inc.',
    symbol: 'LBPH',
  },
  {
    value: 'TZPSW',
    label: 'TZPSW,  TZP Strategies Acquisition Corp.',
    name: 'TZP Strategies Acquisition Corp.',
    symbol: 'TZPSW',
  },
  {
    value: 'OSTRW',
    label: 'OSTRW,  Oyster Enterprises Acquisition Corp.',
    name: 'Oyster Enterprises Acquisition Corp.',
    symbol: 'OSTRW',
  },
  {
    value: 'TZPS',
    label: 'TZPS,  TZP Strategies Acquisition Corp.',
    name: 'TZP Strategies Acquisition Corp.',
    symbol: 'TZPS',
  },
  {
    value: 'IGNYW',
    label: 'IGNYW,  Ignyte Acquisition Corp.',
    name: 'Ignyte Acquisition Corp.',
    symbol: 'IGNYW',
  },
  {
    value: 'MONCW',
    label: 'MONCW,  Monument Circle Acquisition Corporation',
    name: 'Monument Circle Acquisition Corporation',
    symbol: 'MONCW',
  },
  {
    value: 'OSTR',
    label: 'OSTR,  Oyster Enterprises Acquisition Corp.',
    name: 'Oyster Enterprises Acquisition Corp.',
    symbol: 'OSTR',
  },
  {
    value: 'AMTE.L',
    label: 'AMTE.L,  AMTE Power plc',
    name: 'AMTE Power plc',
    symbol: 'AMTE.L',
  },
  {
    value: 'CNXC',
    label: 'CNXC,  Concentrix Corporation',
    name: 'Concentrix Corporation',
    symbol: 'CNXC',
  },
  {
    value: 'CSR',
    label: 'CSR,  Centerspace',
    name: 'Centerspace',
    symbol: 'CSR',
  },
  {
    value: 'MODV',
    label: 'MODV,  ModivCare Inc.',
    name: 'ModivCare Inc.',
    symbol: 'MODV',
  },
  {
    value: 'TNL',
    label: 'TNL,  Travel + Leisure Co.',
    name: 'Travel + Leisure Co.',
    symbol: 'TNL',
  },
  {
    value: 'INDIGOPNTS.NS',
    label: 'INDIGOPNTS.NS,  Indigo Paints Limited',
    name: 'Indigo Paints Limited',
    symbol: 'INDIGOPNTS.NS',
  },
  {
    value: 'RR.L',
    label: 'RR.L,  Rolls-Royce Holdings plc',
    name: 'Rolls-Royce Holdings plc',
    symbol: 'RR.L',
  },
  {
    value: 'PLMIU',
    label: 'PLMIU,  Plum Acquisition Corp. I',
    name: 'Plum Acquisition Corp. I',
    symbol: 'PLMIU',
  },
  {
    value: 'ARRY',
    label: 'ARRY,  Array Technologies, Inc.',
    name: 'Array Technologies, Inc.',
    symbol: 'ARRY',
  },
  {
    value: 'GBNH',
    label: 'GBNH,  Greenbrook TMS Inc.',
    name: 'Greenbrook TMS Inc.',
    symbol: 'GBNH',
  },
  {
    value: 'EBACU',
    label: 'EBACU,  European Biotech Acquisition Corp.',
    name: 'European Biotech Acquisition Corp.',
    symbol: 'EBACU',
  },
  {
    value: 'ASAX',
    label: 'ASAX,  Astrea Acquisition Corp.',
    name: 'Astrea Acquisition Corp.',
    symbol: 'ASAX',
  },
  {
    value: 'SHACW',
    label: 'SHACW,  SCP & CO Healthcare Acquisition Company',
    name: 'SCP & CO Healthcare Acquisition Company',
    symbol: 'SHACW',
  },
  {
    value: 'JCIC',
    label: 'JCIC,  Jack Creek Investment Corp.',
    name: 'Jack Creek Investment Corp.',
    symbol: 'JCIC',
  },
  {
    value: 'NAACW',
    label: 'NAACW,  North Atlantic Acquisition Corporation',
    name: 'North Atlantic Acquisition Corporation',
    symbol: 'NAACW',
  },
  {
    value: 'OEPWW',
    label: 'OEPWW,  One Equity Partners Open Water I Corp.',
    name: 'One Equity Partners Open Water I Corp.',
    symbol: 'OEPWW',
  },
  {
    value: 'ASAXW',
    label: 'ASAXW,  Astrea Acquisition Corp.',
    name: 'Astrea Acquisition Corp.',
    symbol: 'ASAXW',
  },
  {
    value: 'LMACW',
    label: 'LMACW,  Liberty Media Acquisition Corporation',
    name: 'Liberty Media Acquisition Corporation',
    symbol: 'LMACW',
  },
  {
    value: 'OEPW',
    label: 'OEPW,  One Equity Partners Open Water I Corp.',
    name: 'One Equity Partners Open Water I Corp.',
    symbol: 'OEPW',
  },
  {
    value: 'NAAC',
    label: 'NAAC,  North Atlantic Acquisition Corporation',
    name: 'North Atlantic Acquisition Corporation',
    symbol: 'NAAC',
  },
  {
    value: 'LMACA',
    label: 'LMACA,  Liberty Media Acquisition Corporation',
    name: 'Liberty Media Acquisition Corporation',
    symbol: 'LMACA',
  },
  {
    value: 'JCICW',
    label: 'JCICW,  Jack Creek Investment Corp.',
    name: 'Jack Creek Investment Corp.',
    symbol: 'JCICW',
  },
  {
    value: 'SHAC',
    label: 'SHAC,  SCP & CO Healthcare Acquisition Company',
    name: 'SCP & CO Healthcare Acquisition Company',
    symbol: 'SHAC',
  },
  {
    value: 'GAMCU',
    label: 'GAMCU,  Golden Arrow Merger Corp.',
    name: 'Golden Arrow Merger Corp.',
    symbol: 'GAMCU',
  },
  {
    value: 'DGNU',
    label: 'DGNU,  Dragoneer Growth Opportunities Corp. III',
    name: 'Dragoneer Growth Opportunities Corp. III',
    symbol: 'DGNU',
  },
  {
    value: 'GXIIU',
    label: 'GXIIU,  GX Acquisition Corp. II',
    name: 'GX Acquisition Corp. II',
    symbol: 'GXIIU',
  },
  {
    value: 'OUST',
    label: 'OUST,  Ouster, Inc.',
    name: 'Ouster, Inc.',
    symbol: 'OUST',
  },
  {
    value: 'KSICU',
    label: 'KSICU,  Kadem Sustainable Impact Corporation',
    name: 'Kadem Sustainable Impact Corporation',
    symbol: 'KSICU',
  },
  {
    value: 'PGRW',
    label: 'PGRW,  Progress Acquisition Corp.',
    name: 'Progress Acquisition Corp.',
    symbol: 'PGRW',
  },
  {
    value: 'PGRWW',
    label: 'PGRWW,  Progress Acquisition Corp.',
    name: 'Progress Acquisition Corp.',
    symbol: 'PGRWW',
  },
  {
    value: 'AMAOU',
    label: 'AMAOU,  American Acquisition Opportunity Inc.',
    name: 'American Acquisition Opportunity Inc.',
    symbol: 'AMAOU',
  },
  {
    value: 'BYTSU',
    label: 'BYTSU,  BYTE Acquisition Corp.',
    name: 'BYTE Acquisition Corp.',
    symbol: 'BYTSU',
  },
  {
    value: 'HCIIW',
    label: 'HCIIW,  Hudson Executive Investment Corp. II',
    name: 'Hudson Executive Investment Corp. II',
    symbol: 'HCIIW',
  },
  {
    value: 'TUYA',
    label: 'TUYA,  Tuya Inc.',
    name: 'Tuya Inc.',
    symbol: 'TUYA',
  },
  {
    value: 'HCII',
    label: 'HCII,  Hudson Executive Investment Corp. II',
    name: 'Hudson Executive Investment Corp. II',
    symbol: 'HCII',
  },
  {
    value: 'BWIDL.OL',
    label: 'BWIDL.OL,  BW Ideol AS',
    name: 'BW Ideol AS',
    symbol: 'BWIDL.OL',
  },
  {
    value: 'DHHCW',
    label: 'DHHCW,  DiamondHead Holdings Corp.',
    name: 'DiamondHead Holdings Corp.',
    symbol: 'DHHCW',
  },
  {
    value: 'DHHC',
    label: 'DHHC,  DiamondHead Holdings Corp.',
    name: 'DiamondHead Holdings Corp.',
    symbol: 'DHHC',
  },
  {
    value: 'GANX',
    label: 'GANX,  Gain Therapeutics, Inc.',
    name: 'Gain Therapeutics, Inc.',
    symbol: 'GANX',
  },
  {
    value: 'LDHAU',
    label: 'LDHAU,  LDH Growth Corp I',
    name: 'LDH Growth Corp I',
    symbol: 'LDHAU',
  },
  {
    value: 'RACB',
    label: 'RACB,  Research Alliance Corp. II',
    name: 'Research Alliance Corp. II',
    symbol: 'RACB',
  },
  {
    value: 'REVHU',
    label: 'REVHU,  Revolution Healthcare Acquisition Corp.',
    name: 'Revolution Healthcare Acquisition Corp.',
    symbol: 'REVHU',
  },
  {
    value: 'GGMCU',
    label: 'GGMCU,  Glenfarne Merger Corp.',
    name: 'Glenfarne Merger Corp.',
    symbol: 'GGMCU',
  },
  {
    value: 'FMIVU',
    label: 'FMIVU,  Forum Merger IV Corporation',
    name: 'Forum Merger IV Corporation',
    symbol: 'FMIVU',
  },
  {
    value: 'GDXU',
    label: 'GDXU,  MicroSectors Gold Miners 3X Leveraged ETNs',
    name: 'MicroSectors Gold Miners 3X Leveraged ETNs',
    symbol: 'GDXU',
  },
  {
    value: 'GDXD',
    label: 'GDXD,  MicroSectors Gold Miners -3X Inverse Leveraged ETNs',
    name: 'MicroSectors Gold Miners -3X Inverse Leveraged ETNs',
    symbol: 'GDXD',
  },
  {
    value: 'NAPA',
    label: 'NAPA,  The Duckhorn Portfolio, Inc.',
    name: 'The Duckhorn Portfolio, Inc.',
    symbol: 'NAPA',
  },
  {
    value: 'PCTTU',
    label: 'PCTTU,  PureCycle Technologies, Inc.',
    name: 'PureCycle Technologies, Inc.',
    symbol: 'PCTTU',
  },
  {
    value: 'SSAA',
    label: 'SSAA,  Science Strategic Acquisition Corp. Alpha',
    name: 'Science Strategic Acquisition Corp. Alpha',
    symbol: 'SSAA',
  },
  {
    value: 'SSAAW',
    label: 'SSAAW,  Science Strategic Acquisition Corp. Alpha',
    name: 'Science Strategic Acquisition Corp. Alpha',
    symbol: 'SSAAW',
  },
  {
    value: 'BIOTW',
    label: 'BIOTW,  Biotech Acquisition Company',
    name: 'Biotech Acquisition Company',
    symbol: 'BIOTW',
  },
  {
    value: 'BIOT',
    label: 'BIOT,  Biotech Acquisition Company',
    name: 'Biotech Acquisition Company',
    symbol: 'BIOT',
  },
  {
    value: 'PCT',
    label: 'PCT,  PureCycle Technologies, Inc.',
    name: 'PureCycle Technologies, Inc.',
    symbol: 'PCT',
  },
  {
    value: 'PCTTW',
    label: 'PCTTW,  PureCycle Technologies, Inc.',
    name: 'PureCycle Technologies, Inc.',
    symbol: 'PCTTW',
  },
  {
    value: '2187.HK',
    label: '2187.HK,  Zhixin Group Holding Limited',
    name: 'Zhixin Group Holding Limited',
    symbol: '2187.HK',
  },
  {
    value: 'FNCH',
    label: 'FNCH,  Finch Therapeutics Group, Inc.',
    name: 'Finch Therapeutics Group, Inc.',
    symbol: 'FNCH',
  },
  {
    value: 'CNTB',
    label: 'CNTB,  Connect Biopharma Holdings Limited',
    name: 'Connect Biopharma Holdings Limited',
    symbol: 'CNTB',
  },
  {
    value: 'FSSI',
    label: 'FSSI,  Fortistar Sustainable Solutions Corp.',
    name: 'Fortistar Sustainable Solutions Corp.',
    symbol: 'FSSI',
  },
  {
    value: 'FSSIW',
    label: 'FSSIW,  Fortistar Sustainable Solutions Corp.',
    name: 'Fortistar Sustainable Solutions Corp.',
    symbol: 'FSSIW',
  },
  {
    value: 'LVRAU',
    label: 'LVRAU,  Levere Holdings Corp.',
    name: 'Levere Holdings Corp.',
    symbol: 'LVRAU',
  },
  {
    value: 'AFAQU',
    label: 'AFAQU,  AF Acquisition Corp.',
    name: 'AF Acquisition Corp.',
    symbol: 'AFAQU',
  },
  {
    value: '6939.HK',
    label: '6939.HK,  MEGAIN Holding (Cayman) Co., Ltd.',
    name: 'MEGAIN Holding (Cayman) Co., Ltd.',
    symbol: '6939.HK',
  },
  {
    value: 'VTWR.F',
    label: 'VTWR.F,  Vantage Towers AG',
    name: 'Vantage Towers AG',
    symbol: 'VTWR.F',
  },
  {
    value: 'ADFI',
    label: 'ADFI,  Anfield Dynamic Fixed Income ETF',
    name: 'Anfield Dynamic Fixed Income ETF',
    symbol: 'ADFI',
  },
  {
    value: 'AMER',
    label: 'AMER,  Emles Made in America ETF',
    name: 'Emles Made in America ETF',
    symbol: 'AMER',
  },
  {
    value: 'AVMU',
    label: 'AVMU,  Avantis Core Municipal Fixed Income ETF',
    name: 'Avantis Core Municipal Fixed Income ETF',
    symbol: 'AVMU',
  },
  {
    value: 'AZAJ',
    label: 'AZAJ,  AllianzIM U.S. Large Cap Buffer10 Jan ETF',
    name: 'AllianzIM U.S. Large Cap Buffer10 Jan ETF',
    symbol: 'AZAJ',
  },
  {
    value: 'AZBJ',
    label: 'AZBJ,  AllianzIM U.S. Large Cap Buffer20 Jan ETF',
    name: 'AllianzIM U.S. Large Cap Buffer20 Jan ETF',
    symbol: 'AZBJ',
  },
  {
    value: 'BFTR',
    label: 'BFTR,  BlackRock Future Innovators ETF',
    name: 'BlackRock Future Innovators ETF',
    symbol: 'BFTR',
  },
  {
    value: 'BGLD',
    label: 'BGLD,  FT Cboe Vest Gold Tactical Buffer ETF',
    name: 'FT Cboe Vest Gold Tactical Buffer ETF',
    symbol: 'BGLD',
  },
  {
    value: 'BLDG',
    label: 'BLDG,  Cambria Global Real Estate ETF',
    name: 'Cambria Global Real Estate ETF',
    symbol: 'BLDG',
  },
  {
    value: 'BMED',
    label: 'BMED,  BlackRock Future Health ETF',
    name: 'BlackRock Future Health ETF',
    symbol: 'BMED',
  },
  {
    value: 'BNE',
    label: 'BNE,  Blue Horizon BNE ETF',
    name: 'Blue Horizon BNE ETF',
    symbol: 'BNE',
  },
  {
    value: 'BOB',
    label: 'BOB,  Alpha Architect Merlyn.AI Best-of-Breed Core Momentum ETF',
    name: 'Alpha Architect Merlyn.AI Best-of-Breed Core Momentum ETF',
    symbol: 'BOB',
  },
  {
    value: 'BTEK',
    label: 'BTEK,  BlackRock Future Tech ETF',
    name: 'BlackRock Future Tech ETF',
    symbol: 'BTEK',
  },
  {
    value: 'BUFD',
    label: 'BUFD,  FT Cboe Vest Fund of Deep Buffer ETFs',
    name: 'FT Cboe Vest Fund of Deep Buffer ETFs',
    symbol: 'BUFD',
  },
  {
    value: 'BUFR',
    label: 'BUFR,  First Trust Cboe Vest Fund of Buffer ETFs',
    name: 'First Trust Cboe Vest Fund of Buffer ETFs',
    symbol: 'BUFR',
  },
  {
    value: 'CBSE',
    label: 'CBSE,  Changebridge Capital Sustainable Equity ETF',
    name: 'Changebridge Capital Sustainable Equity ETF',
    symbol: 'CBSE',
  },
  {
    value: 'CCRV',
    label: 'CCRV,  iShares Commodity Curve Carry Strategy ETF',
    name: 'iShares Commodity Curve Carry Strategy ETF',
    symbol: 'CCRV',
  },
  {
    value: 'CLDL',
    label: 'CLDL,  Direxion Daily Cloud Computing Bull 2X Shares',
    name: 'Direxion Daily Cloud Computing Bull 2X Shares',
    symbol: 'CLDL',
  },
  {
    value: 'DBJA',
    label: 'DBJA,  Innovator Double Stacker 9 Buffer ETF - January',
    name: 'Innovator Double Stacker 9 Buffer ETF - January',
    symbol: 'DBJA',
  },
  {
    value: 'DBOC',
    label: 'DBOC,  Innovator Double Stacker 9 Buffer ETF – October',
    name: 'Innovator Double Stacker 9 Buffer ETF – October',
    symbol: 'DBOC',
  },
  {
    value: 'DDEC',
    label: 'DDEC,  FT Cboe Vest U.S. Equity Deep Buffer ETF - December',
    name: 'FT Cboe Vest U.S. Equity Deep Buffer ETF - December',
    symbol: 'DDEC',
  },
  {
    value: 'DECZ',
    label: 'DECZ,  TrueShares Structured Outcome (December) ETF',
    name: 'TrueShares Structured Outcome (December) ETF',
    symbol: 'DECZ',
  },
  {
    value: 'DFAE',
    label: 'DFAE,  Dimensional Emerging Core Equity Market ETF',
    name: 'Dimensional Emerging Core Equity Market ETF',
    symbol: 'DFAE',
  },
  {
    value: 'DFAI',
    label: 'DFAI,  Dimensional International Core Equity Market ETF',
    name: 'Dimensional International Core Equity Market ETF',
    symbol: 'DFAI',
  },
  {
    value: 'DFAU',
    label: 'DFAU,  Dimensional US Core Equity Market ETF',
    name: 'Dimensional US Core Equity Market ETF',
    symbol: 'DFAU',
  },
  {
    value: 'DFHY',
    label: 'DFHY,  Donoghue Forlines Tactical High Yield ETF',
    name: 'Donoghue Forlines Tactical High Yield ETF',
    symbol: 'DFHY',
  },
  {
    value: 'DFNV',
    label: 'DFNV,  Donoghue Forlines Risk Managed Innovation ETF',
    name: 'Donoghue Forlines Risk Managed Innovation ETF',
    symbol: 'DFNV',
  },
  {
    value: 'DIVZ',
    label: 'DIVZ,  TrueShares Low Volatility Equity Income ETF',
    name: 'TrueShares Low Volatility Equity Income ETF',
    symbol: 'DIVZ',
  },
  {
    value: 'DJAN',
    label: 'DJAN,  FT Cboe Vest U.S. Equity Deep Buffer ETF - January',
    name: 'FT Cboe Vest U.S. Equity Deep Buffer ETF - January',
    symbol: 'DJAN',
  },
  {
    value: 'DOCT',
    label: 'DOCT,  FT Cboe Vest U.S. Equity Deep Buffer ETF - October',
    name: 'FT Cboe Vest U.S. Equity Deep Buffer ETF - October',
    symbol: 'DOCT',
  },
  {
    value: 'DSEP',
    label: 'DSEP,  FT Cboe Vest U.S. Equity Deep Buffer ETF - September',
    name: 'FT Cboe Vest U.S. Equity Deep Buffer ETF - September',
    symbol: 'DSEP',
  },
  {
    value: 'DSJA',
    label: 'DSJA,  Innovator Double Stacker ETF - January',
    name: 'Innovator Double Stacker ETF - January',
    symbol: 'DSJA',
  },
  {
    value: 'DSOC',
    label: 'DSOC,  Innovator Double Stacker ETF – October',
    name: 'Innovator Double Stacker ETF – October',
    symbol: 'DSOC',
  },
  {
    value: 'DSTX',
    label: 'DSTX,  Distillate International Fundamental Stability & Value ETF',
    name: 'Distillate International Fundamental Stability & Value ETF',
    symbol: 'DSTX',
  },
  {
    value: 'DUDE',
    label: 'DUDE,  Merlyn.AI SectorSurfer Momentum ETF',
    name: 'Merlyn.AI SectorSurfer Momentum ETF',
    symbol: 'DUDE',
  },
  {
    value: 'EBLU',
    label: 'EBLU,  Ecofin Global Water ESG Fund',
    name: 'Ecofin Global Water ESG Fund',
    symbol: 'EBLU',
  },
  {
    value: 'EFIX',
    label: 'EFIX,  First Trust TCW Emerging Markets Debt ETF',
    name: 'First Trust TCW Emerging Markets Debt ETF',
    symbol: 'EFIX',
  },
  {
    value: 'EGIS',
    label: 'EGIS,  2ndVote Society Defended ETF',
    name: '2ndVote Society Defended ETF',
    symbol: 'EGIS',
  },
  {
    value: 'FDEC',
    label: 'FDEC,  FT Cboe Vest U.S. Equity Buffer ETF - December',
    name: 'FT Cboe Vest U.S. Equity Buffer ETF - December',
    symbol: 'FDEC',
  },
  {
    value: 'FEBZ',
    label: 'FEBZ,  TrueShares Structured Outcome (February) ETF',
    name: 'TrueShares Structured Outcome (February) ETF',
    symbol: 'FEBZ',
  },
  {
    value: 'FEDX',
    label: 'FEDX,  Emles Federal Contractors ETF',
    name: 'Emles Federal Contractors ETF',
    symbol: 'FEDX',
  },
  {
    value: 'FEVR',
    label: 'FEVR,  Inspire Faithward Large Cap Momentum ETF',
    name: 'Inspire Faithward Large Cap Momentum ETF',
    symbol: 'FEVR',
  },
  {
    value: 'FGRO',
    label: 'FGRO,  Fidelity Growth Opportunities ETF',
    name: 'Fidelity Growth Opportunities ETF',
    symbol: 'FGRO',
  },
  {
    value: 'FICS',
    label: 'FICS,  First Trust International Developed Cap Strength ETF',
    name: 'First Trust International Developed Cap Strength ETF',
    symbol: 'FICS',
  },
  {
    value: 'FJAN',
    label: 'FJAN,  FT Cboe Vest U.S. Equity Buffer ETF - January',
    name: 'FT Cboe Vest U.S. Equity Buffer ETF - January',
    symbol: 'FJAN',
  },
  {
    value: 'FMAG',
    label: 'FMAG,  Fidelity Magellan ETF',
    name: 'Fidelity Magellan ETF',
    symbol: 'FMAG',
  },
  {
    value: 'FOCT',
    label: 'FOCT,  FT Cboe Vest U.S. Equity Buffer ETF - October',
    name: 'FT Cboe Vest U.S. Equity Buffer ETF - October',
    symbol: 'FOCT',
  },
  {
    value: 'FPRO',
    label: 'FPRO,  Fidelity Real Estate Investment ETF',
    name: 'Fidelity Real Estate Investment ETF',
    symbol: 'FPRO',
  },
  {
    value: 'FRTY',
    label: 'FRTY,  Alger Mid Cap 40 ETF',
    name: 'Alger Mid Cap 40 ETF',
    symbol: 'FRTY',
  },
  {
    value: 'FSEP',
    label: 'FSEP,  FT Cboe Vest U.S. Equity Buffer ETF - September',
    name: 'FT Cboe Vest U.S. Equity Buffer ETF - September',
    symbol: 'FSEP',
  },
  {
    value: 'FSMO',
    label: 'FSMO,  Fidelity Small-Mid Cap Opportunities ETF',
    name: 'Fidelity Small-Mid Cap Opportunities ETF',
    symbol: 'FSMO',
  },
  {
    value: 'FUNL',
    label: 'FUNL,  CornerCap Fundametrics Large-Cap ETF FUNL',
    name: 'CornerCap Fundametrics Large-Cap ETF FUNL',
    symbol: 'FUNL',
  },
  {
    value: 'GLRY',
    label: 'GLRY,  Inspire Faithward Mid Cap Momentum ETF',
    name: 'Inspire Faithward Mid Cap Momentum ETF',
    symbol: 'GLRY',
  },
  {
    value: 'GOVZ',
    label: 'GOVZ,  iShares 25+ Year Treasury STRIPS Bond ETF',
    name: 'iShares 25+ Year Treasury STRIPS Bond ETF',
    symbol: 'GOVZ',
  },
  {
    value: 'GSPY',
    label: 'GSPY,  Gotham Enhanced 500 ETF',
    name: 'Gotham Enhanced 500 ETF',
    symbol: 'GSPY',
  },
  {
    value: 'HART',
    label: 'HART,  IQ Healthy Hearts ETF',
    name: 'IQ Healthy Hearts ETF',
    symbol: 'HART',
  },
  {
    value: 'HEGD',
    label: 'HEGD,  Swan Hedged Equity US Large Cap ETF',
    name: 'Swan Hedged Equity US Large Cap ETF',
    symbol: 'HEGD',
  },
  {
    value: 'HKND',
    label: 'HKND,  Humankind US Stock ETF',
    name: 'Humankind US Stock ETF',
    symbol: 'HKND',
  },
  {
    value: 'IBHF',
    label: 'IBHF,  iShares iBonds 2026 Term High Yield and Income ETF',
    name: 'iShares iBonds 2026 Term High Yield and Income ETF',
    symbol: 'IBHF',
  },
  {
    value: 'IHYF',
    label: 'IHYF,  Invesco High Yield Bond Factor ETF',
    name: 'Invesco High Yield Bond Factor ETF',
    symbol: 'IHYF',
  },
  {
    value: 'INFL',
    label: 'INFL,  Horizon Kinetics Inflation Beneficiaries ETF',
    name: 'Horizon Kinetics Inflation Beneficiaries ETF',
    symbol: 'INFL',
  },
  {
    value: 'ISWN',
    label: 'ISWN,  Amplify BlackSwan ISWN ETF',
    name: 'Amplify BlackSwan ISWN ETF',
    symbol: 'ISWN',
  },
  {
    value: 'IVDG',
    label: 'IVDG,  Invesco Focused Discovery Growth ETF',
    name: 'Invesco Focused Discovery Growth ETF',
    symbol: 'IVDG',
  },
  {
    value: 'IVLC',
    label: 'IVLC,  Invesco US Large Cap Core ESG ETF',
    name: 'Invesco US Large Cap Core ESG ETF',
    symbol: 'IVLC',
  },
  {
    value: 'IVRA',
    label: 'IVRA,  Invesco Real Assets ESG ETF',
    name: 'Invesco Real Assets ESG ETF',
    symbol: 'IVRA',
  },
  {
    value: 'IVSG',
    label: 'IVSG,  Invesco Select Growth ETF',
    name: 'Invesco Select Growth ETF',
    symbol: 'IVSG',
  },
  {
    value: 'JANZ',
    label: 'JANZ,  TrueShares Structured Outcome (January) ETF',
    name: 'TrueShares Structured Outcome (January) ETF',
    symbol: 'JANZ',
  },
  {
    value: 'JOET',
    label: 'JOET,  Virtus Terranova U.S. Quality Momentum ETF',
    name: 'Virtus Terranova U.S. Quality Momentum ETF',
    symbol: 'JOET',
  },
  {
    value: 'JPIB',
    label: 'JPIB,  JPMorgan International Bond Opportunities ETF',
    name: 'JPMorgan International Bond Opportunities ETF',
    symbol: 'JPIB',
  },
  {
    value: 'JSCP',
    label: 'JSCP,  JPMorgan Short Duration Core Plus ETF',
    name: 'JPMorgan Short Duration Core Plus ETF',
    symbol: 'JSCP',
  },
  {
    value: 'JSTC',
    label: 'JSTC,  Adasina Social Justice All Cap Global ETF',
    name: 'Adasina Social Justice All Cap Global ETF',
    symbol: 'JSTC',
  },
  {
    value: 'KBUY',
    label: 'KBUY,  KraneShares CICC China Consumer Leaders Index ETF',
    name: 'KraneShares CICC China Consumer Leaders Index ETF',
    symbol: 'KBUY',
  },
  {
    value: 'KMLM',
    label: 'KMLM,  KFA Mount Lucas Index Strategy ETF',
    name: 'KFA Mount Lucas Index Strategy ETF',
    symbol: 'KMLM',
  },
  {
    value: 'KSTR',
    label: 'KSTR,  KraneShares SSE STAR Market 50 Index ETF',
    name: 'KraneShares SSE STAR Market 50 Index ETF',
    symbol: 'KSTR',
  },
  {
    value: 'KVLE',
    label: 'KVLE,  KFA Value Line Dynamic Core Equity Index ETF',
    name: 'KFA Value Line Dynamic Core Equity Index ETF',
    symbol: 'KVLE',
  },
  {
    value: 'KWT',
    label: 'KWT,  iShares MSCI Kuwait ETF',
    name: 'iShares MSCI Kuwait ETF',
    symbol: 'KWT',
  },
  {
    value: 'LIV',
    label: 'LIV,  Emles @Home ETF',
    name: 'Emles @Home ETF',
    symbol: 'LIV',
  },
  {
    value: 'LUXE',
    label: 'LUXE,  Emles Luxury Goods ETF',
    name: 'Emles Luxury Goods ETF',
    symbol: 'LUXE',
  },
  {
    value: 'LVOL',
    label: 'LVOL,  American Century Low Volatility ETF',
    name: 'American Century Low Volatility ETF',
    symbol: 'LVOL',
  },
  {
    value: 'LYFE',
    label: 'LYFE,  2ndVote Life Neutral Plus ETF',
    name: '2ndVote Life Neutral Plus ETF',
    symbol: 'LYFE',
  },
  {
    value: 'MARZ',
    label: 'MARZ,  TrueShares Structured Outcome (March) ETF',
    name: 'TrueShares Structured Outcome (March) ETF',
    symbol: 'MARZ',
  },
  {
    value: 'MBBB',
    label: "MBBB,  VanEck Moody's Analytics BBB Corporate Bond ETF",
    name: "VanEck Moody's Analytics BBB Corporate Bond ETF",
    symbol: 'MBBB',
  },
  {
    value: 'MBND',
    label: 'MBND,  SPDR Nuveen Municipal Bond ETF',
    name: 'SPDR Nuveen Municipal Bond ETF',
    symbol: 'MBND',
  },
  {
    value: 'MGMT',
    label: 'MGMT,  Ballast Small/Mid Cap ETF',
    name: 'Ballast Small/Mid Cap ETF',
    symbol: 'MGMT',
  },
  {
    value: 'MIDE',
    label: 'MIDE,  Xtrackers S&P MidCap 400 ESG ETF',
    name: 'Xtrackers S&P MidCap 400 ESG ETF',
    symbol: 'MIDE',
  },
  {
    value: 'MIG',
    label: "MIG,  VanEck Moody's Analytics IG Corporate Bond ETF",
    name: "VanEck Moody's Analytics IG Corporate Bond ETF",
    symbol: 'MIG',
  },
  {
    value: 'MRAD',
    label: 'MRAD,  Guinness Atkinson SmartETFs Marketing Technology ETF',
    name: 'Guinness Atkinson SmartETFs Marketing Technology ETF',
    symbol: 'MRAD',
  },
  {
    value: 'MSTB',
    label: 'MSTB,  LHA Market State Tactical Beta ETF',
    name: 'LHA Market State Tactical Beta ETF',
    symbol: 'MSTB',
  },
  {
    value: 'NVQ',
    label: 'NVQ,  QRAFT AI-Enhanced U.S. Next Value ETF',
    name: 'QRAFT AI-Enhanced U.S. Next Value ETF',
    symbol: 'NVQ',
  },
  {
    value: 'OCTZ',
    label: 'OCTZ,  TrueShares Structured Outcome (October) ETF',
    name: 'TrueShares Structured Outcome (October) ETF',
    symbol: 'OCTZ',
  },
  {
    value: 'ONOF',
    label: 'ONOF,  Global X Adaptive U.S. Risk Management ETF',
    name: 'Global X Adaptive U.S. Risk Management ETF',
    symbol: 'ONOF',
  },
  {
    value: 'OVLH',
    label: 'OVLH,  Overlay Shares Hedged Large Cap Equity ETF',
    name: 'Overlay Shares Hedged Large Cap Equity ETF',
    symbol: 'OVLH',
  },
  {
    value: 'OVT',
    label: 'OVT,  Overlay Shares Short Term Bond ETF',
    name: 'Overlay Shares Short Term Bond ETF',
    symbol: 'OVT',
  },
  {
    value: 'PIFI',
    label: 'PIFI,  ClearShares Piton Intermediate Fixed Income ETF',
    name: 'ClearShares Piton Intermediate Fixed Income ETF',
    symbol: 'PIFI',
  },
  {
    value: 'PSCX',
    label: 'PSCX,  Pacer Swan SOS Conservative (January) ETF',
    name: 'Pacer Swan SOS Conservative (January) ETF',
    symbol: 'PSCX',
  },
  {
    value: 'PSFD',
    label: 'PSFD,  Pacer Swan SOS Flex (January) ETF',
    name: 'Pacer Swan SOS Flex (January) ETF',
    symbol: 'PSFD',
  },
  {
    value: 'PSFF',
    label: 'PSFF,  Pacer Swan SOS Fund of Funds ETF',
    name: 'Pacer Swan SOS Fund of Funds ETF',
    symbol: 'PSFF',
  },
  {
    value: 'PSMD',
    label: 'PSMD,  Pacer Swan SOS Moderate (January) ETF',
    name: 'Pacer Swan SOS Moderate (January) ETF',
    symbol: 'PSMD',
  },
  {
    value: 'QDEC',
    label: 'QDEC,  FT Cboe Vest Nasdaq-100 Buffer ETF – December',
    name: 'FT Cboe Vest Nasdaq-100 Buffer ETF – December',
    symbol: 'QDEC',
  },
  {
    value: 'QPT',
    label: 'QPT,  AdvisorShares Q Portfolio Blended Allocation ETF',
    name: 'AdvisorShares Q Portfolio Blended Allocation ETF',
    symbol: 'QPT',
  },
  {
    value: 'QPX',
    label: 'QPX,  AdvisorShares Q Dynamic Growth ETF',
    name: 'AdvisorShares Q Dynamic Growth ETF',
    symbol: 'QPX',
  },
  {
    value: 'QQC',
    label: 'QQC,  Simplify Nasdaq 100 plus convexity ETF',
    name: 'Simplify Nasdaq 100 plus convexity ETF',
    symbol: 'QQC',
  },
  {
    value: 'QQD',
    label: 'QQD,  Simplify Nasdaq 100 plus Downside  convexity ETF',
    name: 'Simplify Nasdaq 100 plus Downside  convexity ETF',
    symbol: 'QQD',
  },
  {
    value: 'RAYC',
    label: 'RAYC,  Rayliant Quantamental China Equity ETF',
    name: 'Rayliant Quantamental China Equity ETF',
    symbol: 'RAYC',
  },
  {
    value: 'RDFI',
    label: 'RDFI,  Rareview Dynamic Fixed Income ETF',
    name: 'Rareview Dynamic Fixed Income ETF',
    symbol: 'RDFI',
  },
  {
    value: 'REC',
    label: 'REC,  Emles Real Estate Credit ETF',
    name: 'Emles Real Estate Credit ETF',
    symbol: 'REC',
  },
  {
    value: 'RORO',
    label: 'RORO,  ATAC US Rotation ETF',
    name: 'ATAC US Rotation ETF',
    symbol: 'RORO',
  },
  {
    value: 'RTAI',
    label: 'RTAI,  Rareview Tax Advantaged Income ETF',
    name: 'Rareview Tax Advantaged Income ETF',
    symbol: 'RTAI',
  },
  {
    value: 'SDEF',
    label: 'SDEF,  Sound Enhanced Fixed Income ETF',
    name: 'Sound Enhanced Fixed Income ETF',
    symbol: 'SDEF',
  },
  {
    value: 'SDEI',
    label: 'SDEI,  Sound Equity Income ETF',
    name: 'Sound Equity Income ETF',
    symbol: 'SDEI',
  },
  {
    value: 'SENT',
    label: 'SENT,  AdvisorShares Alpha DNA Equity Sentiment ETF',
    name: 'AdvisorShares Alpha DNA Equity Sentiment ETF',
    symbol: 'SENT',
  },
  {
    value: 'SEPZ',
    label: 'SEPZ,  TrueShares Structured Outcome (September) ETF',
    name: 'TrueShares Structured Outcome (September) ETF',
    symbol: 'SEPZ',
  },
  {
    value: 'SKYU',
    label: 'SKYU,  ProShares Ultra Cloud Computing',
    name: 'ProShares Ultra Cloud Computing',
    symbol: 'SKYU',
  },
  {
    value: 'SMLE',
    label: 'SMLE,  Xtrackers S&P SmallCap 600 ESG ETF',
    name: 'Xtrackers S&P SmallCap 600 ESG ETF',
    symbol: 'SMLE',
  },
  {
    value: 'SPRE',
    label: 'SPRE,  SP Funds S&P Global REIT Sharia ETF',
    name: 'SP Funds S&P Global REIT Sharia ETF',
    symbol: 'SPRE',
  },
  {
    value: 'SVAL',
    label: 'SVAL,  iShares US Small Cap Value Factor ETF',
    name: 'iShares US Small Cap Value Factor ETF',
    symbol: 'SVAL',
  },
  {
    value: 'TBJL',
    label: 'TBJL,  Innovator 20+ Year Treasury Bond Buffer ETF – July',
    name: 'Innovator 20+ Year Treasury Bond Buffer ETF – July',
    symbol: 'TBJL',
  },
  {
    value: 'TDSE',
    label: 'TDSE,  Cabana Target Drawdown 16 ETF',
    name: 'Cabana Target Drawdown 16 ETF',
    symbol: 'TDSE',
  },
  {
    value: 'TFJL',
    label: 'TFJL,  Innovator 20+ Year Treasury Bond 5 Floor ETF – Quarterly',
    name: 'Innovator 20+ Year Treasury Bond 5 Floor ETF – Quarterly',
    symbol: 'TFJL',
  },
  {
    value: 'TGIF',
    label: 'TGIF,  SoFi Weekly Income ETF',
    name: 'SoFi Weekly Income ETF',
    symbol: 'TGIF',
  },
  {
    value: 'TMAT',
    label: 'TMAT,  Main Thematic Innovation ETF',
    name: 'Main Thematic Innovation ETF',
    symbol: 'TMAT',
  },
  {
    value: 'TSJA',
    label: 'TSJA,  Innovator Triple Stacker ETF - January',
    name: 'Innovator Triple Stacker ETF - January',
    symbol: 'TSJA',
  },
  {
    value: 'TSOC',
    label: 'TSOC,  Innovator Triple Stacker ETF – October',
    name: 'Innovator Triple Stacker ETF – October',
    symbol: 'TSOC',
  },
  {
    value: 'UCYB',
    label: 'UCYB,  ProShares Ultra Nasdaq Cybersecurity',
    name: 'ProShares Ultra Nasdaq Cybersecurity',
    symbol: 'UCYB',
  },
  {
    value: 'VABS',
    label: 'VABS,  Virtus Newfleet ABS/MBS ETF',
    name: 'Virtus Newfleet ABS/MBS ETF',
    symbol: 'VABS',
  },
  {
    value: 'VCAR',
    label: 'VCAR,  Simplify Volt RoboCar Disruption and Tech ETF',
    name: 'Simplify Volt RoboCar Disruption and Tech ETF',
    symbol: 'VCAR',
  },
  {
    value: 'VCEB',
    label: 'VCEB,  Vanguard ESG U.S. Corporate Bond ETF',
    name: 'Vanguard ESG U.S. Corporate Bond ETF',
    symbol: 'VCEB',
  },
  {
    value: 'VCLO',
    label: 'VCLO,  Simplify Volt Cloud and Cybersecurity Disruption ETF',
    name: 'Simplify Volt Cloud and Cybersecurity Disruption ETF',
    symbol: 'VCLO',
  },
  {
    value: 'VNMC',
    label: 'VNMC,  Natixis Vaughan Nelson Mid Cap ETF',
    name: 'Natixis Vaughan Nelson Mid Cap ETF',
    symbol: 'VNMC',
  },
  {
    value: 'WCBR',
    label: 'WCBR,  WisdomTree Cybersecurity Fund',
    name: 'WisdomTree Cybersecurity Fund',
    symbol: 'WCBR',
  },
  {
    value: 'XDAT',
    label: 'XDAT,  Franklin Exponential Data ETF',
    name: 'Franklin Exponential Data ETF',
    symbol: 'XDAT',
  },
  {
    value: 'XJH',
    label: 'XJH,  iShares ESG Screened S&P Mid-Cap ETF',
    name: 'iShares ESG Screened S&P Mid-Cap ETF',
    symbol: 'XJH',
  },
  {
    value: 'XJR',
    label: 'XJR,  iShares ESG Screened S&P Small-Cap ETF',
    name: 'iShares ESG Screened S&P Small-Cap ETF',
    symbol: 'XJR',
  },
  {
    value: 'XVV',
    label: 'XVV,  iShares ESG Screened S&P 500 ETF',
    name: 'iShares ESG Screened S&P 500 ETF',
    symbol: 'XVV',
  },
  {
    value: 'YDEC',
    label: 'YDEC,  FT Cboe Vest International Equity Buffer ETF - December',
    name: 'FT Cboe Vest International Equity Buffer ETF - December',
    symbol: 'YDEC',
  },
  {
    value: '3023.HK',
    label: '3023.HK,  Ping An Nasdaq AI and Robotics ETF',
    name: 'Ping An Nasdaq AI and Robotics ETF',
    symbol: '3023.HK',
  },
  {
    value: '3187.HK',
    label: '3187.HK,  Samsung S&P High Dividend APAC ex NZ REITs ETF',
    name: 'Samsung S&P High Dividend APAC ex NZ REITs ETF',
    symbol: '3187.HK',
  },
  {
    value: 'FIGB',
    label: 'FIGB,  Fidelity Investment Grade Bond ETF',
    name: 'Fidelity Investment Grade Bond ETF',
    symbol: 'FIGB',
  },
  {
    value: 'FSEC',
    label: 'FSEC,  Fidelity Investment Grade Securitized ETF',
    name: 'Fidelity Investment Grade Securitized ETF',
    symbol: 'FSEC',
  },
  {
    value: '2802.HK',
    label: '2802.HK,  iShares MSCI Emerging Asia ETF',
    name: 'iShares MSCI Emerging Asia ETF',
    symbol: '2802.HK',
  },
  {
    value: '2803.HK',
    label: '2803.HK,  Premia CSI Caixin China Bedrock Economy ETF',
    name: 'Premia CSI Caixin China Bedrock Economy ETF',
    symbol: '2803.HK',
  },
  {
    value: '2806.HK',
    label: '2806.HK,  Global X China Consumer Brand ETF',
    name: 'Global X China Consumer Brand ETF',
    symbol: '2806.HK',
  },
  {
    value: '2807.HK',
    label: '2807.HK,  Global X China Robotics and AI ETF',
    name: 'Global X China Robotics and AI ETF',
    symbol: '2807.HK',
  },
  {
    value: '2819.HK',
    label: '2819.HK,  ABF Hong Kong Bond Index Fund',
    name: 'ABF Hong Kong Bond Index Fund',
    symbol: '2819.HK',
  },
  {
    value: '2821.HK',
    label: '2821.HK,  ABF Pan Asia Bond Index',
    name: 'ABF Pan Asia Bond Index',
    symbol: '2821.HK',
  },
  {
    value: '2825.HK',
    label: '2825.HK,  W.I.S.E. - CSI HK 100 Tracker',
    name: 'W.I.S.E. - CSI HK 100 Tracker',
    symbol: '2825.HK',
  },
  {
    value: '2827.HK',
    label: '2827.HK,  W.I.S.E. - CSI 300 China Tracker',
    name: 'W.I.S.E. - CSI 300 China Tracker',
    symbol: '2827.HK',
  },
  {
    value: '2836.HK',
    label: '2836.HK,  iShares Core S&P BSE SENSEX India ETF',
    name: 'iShares Core S&P BSE SENSEX India ETF',
    symbol: '2836.HK',
  },
  {
    value: '2846.HK',
    label: '2846.HK,  iShares Core CSI 300 ETF',
    name: 'iShares Core CSI 300 ETF',
    symbol: '2846.HK',
  },
  {
    value: '3024.HK',
    label: '3024.HK,  W.I.S.E. - SSE 50 China Tracker',
    name: 'W.I.S.E. - SSE 50 China Tracker',
    symbol: '3024.HK',
  },
  {
    value: '3031.HK',
    label: '3031.HK,  Haitong MSCI China A ESG ETF',
    name: 'Haitong MSCI China A ESG ETF',
    symbol: '3031.HK',
  },
  {
    value: '3033.HK',
    label: '3033.HK,  CSOP Hang Seng TECH Index ETF',
    name: 'CSOP Hang Seng TECH Index ETF',
    symbol: '3033.HK',
  },
  {
    value: '3040.HK',
    label: '3040.HK,  Global X MSCI China ETF',
    name: 'Global X MSCI China ETF',
    symbol: '3040.HK',
  },
  {
    value: '3053.HK',
    label: '3053.HK,  CSOP Hong Kong Dollar Money Market ETF',
    name: 'CSOP Hong Kong Dollar Money Market ETF',
    symbol: '3053.HK',
  },
  {
    value: '3070.HK',
    label: '3070.HK,  Ping An of China CSI HK Dividend ETF',
    name: 'Ping An of China CSI HK Dividend ETF',
    symbol: '3070.HK',
  },
  {
    value: '3071.HK',
    label: '3071.HK,  CICC HKD Money Market ETF',
    name: 'CICC HKD Money Market ETF',
    symbol: '3071.HK',
  },
  {
    value: '3073.HK',
    label: '3073.HK,  SPDR FTSE Greater China ETF',
    name: 'SPDR FTSE Greater China ETF',
    symbol: '3073.HK',
  },
  {
    value: '3080.HK',
    label: '3080.HK,  Ping An of China CSI 5-10Y CGB ETF',
    name: 'Ping An of China CSI 5-10Y CGB ETF',
    symbol: '3080.HK',
  },
  {
    value: '3081.HK',
    label: '3081.HK,  Value Gold ETF',
    name: 'Value Gold ETF',
    symbol: '3081.HK',
  },
  {
    value: '3086.HK',
    label: '3086.HK,  ChinaAMC NASDAQ 100 ETF',
    name: 'ChinaAMC NASDAQ 100 ETF',
    symbol: '3086.HK',
  },
  {
    value: '3097.HK',
    label: '3097.HK,  Global X S&P Crude Oil Futures Enhanced ER ETF',
    name: 'Global X S&P Crude Oil Futures Enhanced ER ETF',
    symbol: '3097.HK',
  },
  {
    value: '3109.HK',
    label: '3109.HK,  CSOP ETF Series OFC - CSOP STAR 50 Index ETF',
    name: 'CSOP ETF Series OFC - CSOP STAR 50 Index ETF',
    symbol: '3109.HK',
  },
  {
    value: '3110.HK',
    label: '3110.HK,  Global X Hang Seng High Dividend Yield ETF',
    name: 'Global X Hang Seng High Dividend Yield ETF',
    symbol: '3110.HK',
  },
  {
    value: '3115.HK',
    label: '3115.HK,  iShares Core Hang Seng Index ETF',
    name: 'iShares Core Hang Seng Index ETF',
    symbol: '3115.HK',
  },
  {
    value: '3121.HK',
    label: '3121.HK,  ChinaAMC MSCI Asia Pacific Real Estate ETF',
    name: 'ChinaAMC MSCI Asia Pacific Real Estate ETF',
    symbol: '3121.HK',
  },
  {
    value: '3130.HK',
    label: '3130.HK,  Hang Seng Harvest CSI 300 Index ETF',
    name: 'Hang Seng Harvest CSI 300 Index ETF',
    symbol: '3130.HK',
  },
  {
    value: '3141.HK',
    label: '3141.HK,  ChinaAMC Asia USD Investment Grade Bond ETF',
    name: 'ChinaAMC Asia USD Investment Grade Bond ETF',
    symbol: '3141.HK',
  },
  {
    value: '3143.HK',
    label: '3143.HK,  ChinaAMC Hong Kong Banks ETF',
    name: 'ChinaAMC Hong Kong Banks ETF',
    symbol: '3143.HK',
  },
  {
    value: '3160.HK',
    label: '3160.HK,  ChinaAMC MSCI Japan Hedged to USD ETF',
    name: 'ChinaAMC MSCI Japan Hedged to USD ETF',
    symbol: '3160.HK',
  },
  {
    value: '3165.HK',
    label: '3165.HK,  ChinaAMC MSCI Europe Quality Hedged to USD ETF',
    name: 'ChinaAMC MSCI Europe Quality Hedged to USD ETF',
    symbol: '3165.HK',
  },
  {
    value: '3167.HK',
    label: '3167.HK,  CSOP S&P New China Sectors ETF',
    name: 'CSOP S&P New China Sectors ETF',
    symbol: '3167.HK',
  },
  {
    value: '3175.HK',
    label: '3175.HK,  Samsung S&P GSCI Crude Oil ER Futures ETF',
    name: 'Samsung S&P GSCI Crude Oil ER Futures ETF',
    symbol: '3175.HK',
  },
  {
    value: '7200.HK',
    label: '7200.HK,  CSOP Hang Seng Index Daily (2x) Leveraged Product',
    name: 'CSOP Hang Seng Index Daily (2x) Leveraged Product',
    symbol: '7200.HK',
  },
  {
    value: '7226.HK',
    label: '7226.HK,  CSOP Hang Seng TECH Index Daily (2x) Leveraged Product',
    name: 'CSOP Hang Seng TECH Index Daily (2x) Leveraged Product',
    symbol: '7226.HK',
  },
  {
    value: '7230.HK',
    label:
      '7230.HK,  Global X Hang Seng China Enterprises Index Daily (2x) Leveraged Product',
    name: 'Global X Hang Seng China Enterprises Index Daily (2x) Leveraged Product',
    symbol: '7230.HK',
  },
  {
    value: '7231.HK',
    label: '7231.HK,  Global X Hang Seng Index Daily (2x) Leveraged Product',
    name: 'Global X Hang Seng Index Daily (2x) Leveraged Product',
    symbol: '7231.HK',
  },
  {
    value: '7233.HK',
    label: '7233.HK,  CSOP CSI 300 Index Daily (2x) Leveraged Product',
    name: 'CSOP CSI 300 Index Daily (2x) Leveraged Product',
    symbol: '7233.HK',
  },
  {
    value: '7248.HK',
    label: '7248.HK,  CSOP FTSE China A50 Index Daily (2x) Leveraged Product',
    name: 'CSOP FTSE China A50 Index Daily (2x) Leveraged Product',
    symbol: '7248.HK',
  },
  {
    value: '7261.HK',
    label: '7261.HK,  ChinaAMC NASDAQ-100 Index Daily (2x) Leveraged Product',
    name: 'ChinaAMC NASDAQ-100 Index Daily (2x) Leveraged Product',
    symbol: '7261.HK',
  },
  {
    value: '7266.HK',
    label: '7266.HK,  CSOP NASDAQ-100 Index Daily (2x) Leveraged Product',
    name: 'CSOP NASDAQ-100 Index Daily (2x) Leveraged Product',
    symbol: '7266.HK',
  },
  {
    value: '7272.HK',
    label:
      '7272.HK,  ChinaAMC Direxion CSI 300 Index Daily (2x) Leveraged Product',
    name: 'ChinaAMC Direxion CSI 300 Index Daily (2x) Leveraged Product',
    symbol: '7272.HK',
  },
  {
    value: '7288.HK',
    label:
      '7288.HK,  CSOP Hang Seng China Enterprises Index Daily (2x) Leveraged Product',
    name: 'CSOP Hang Seng China Enterprises Index Daily (2x) Leveraged Product',
    symbol: '7288.HK',
  },
  {
    value: '7299.HK',
    label: '7299.HK,  CSOP Gold Futures Daily (2x) Leveraged Product',
    name: 'CSOP Gold Futures Daily (2x) Leveraged Product',
    symbol: '7299.HK',
  },
  {
    value: '7300.HK',
    label: '7300.HK,  CSOP Hang Seng Index Daily (-1x) Inverse Product',
    name: 'CSOP Hang Seng Index Daily (-1x) Inverse Product',
    symbol: '7300.HK',
  },
  {
    value: '7322.HK',
    label: '7322.HK,  Global X S&P 500 Daily (-1x) Inverse Product',
    name: 'Global X S&P 500 Daily (-1x) Inverse Product',
    symbol: '7322.HK',
  },
  {
    value: '7331.HK',
    label: '7331.HK,  ChinaAMC Direxion NASDAQ-100 Daily (-1x) Inverse Product',
    name: 'ChinaAMC Direxion NASDAQ-100 Daily (-1x) Inverse Product',
    symbol: '7331.HK',
  },
  {
    value: '7333.HK',
    label: '7333.HK,  CSOP CSI 300 Index Daily (-1x) Inverse Product',
    name: 'CSOP CSI 300 Index Daily (-1x) Inverse Product',
    symbol: '7333.HK',
  },
  {
    value: '7336.HK',
    label: '7336.HK,  Global X Hang Seng Index Daily (-1x) Inverse Product',
    name: 'Global X Hang Seng Index Daily (-1x) Inverse Product',
    symbol: '7336.HK',
  },
  {
    value: '7348.HK',
    label: '7348.HK,  CSOP FTSE China A50 Index Daily (-1x) Inverse Product',
    name: 'CSOP FTSE China A50 Index Daily (-1x) Inverse Product',
    symbol: '7348.HK',
  },
  {
    value: '7362.HK',
    label:
      '7362.HK,  Global X Hang Seng China Enterprises Index Daily (-1x) Inverse Product',
    name: 'Global X Hang Seng China Enterprises Index Daily (-1x) Inverse Product',
    symbol: '7362.HK',
  },
  {
    value: '7373.HK',
    label:
      '7373.HK,  ChinaAMC Direxion CSI 300 Index Daily (-1x) Inverse Product',
    name: 'ChinaAMC Direxion CSI 300 Index Daily (-1x) Inverse Product',
    symbol: '7373.HK',
  },
  {
    value: '7500.HK',
    label: '7500.HK,  CSOP Hang Seng Index Daily (-2x) Inverse Product',
    name: 'CSOP Hang Seng Index Daily (-2x) Inverse Product',
    symbol: '7500.HK',
  },
  {
    value: '7522.HK',
    label: '7522.HK,  ChinaAMC Direxion NASDAQ-100 Daily (-2x) Inverse Product',
    name: 'ChinaAMC Direxion NASDAQ-100 Daily (-2x) Inverse Product',
    symbol: '7522.HK',
  },
  {
    value: '7552.HK',
    label: '7552.HK,  CSOP Hang Seng TECH Index Daily (-2x) Inverse Product',
    name: 'CSOP Hang Seng TECH Index Daily (-2x) Inverse Product',
    symbol: '7552.HK',
  },
  {
    value: '7568.HK',
    label: '7568.HK,  CSOP NASDAQ-100 Index Daily (-2x) Inverse Product',
    name: 'CSOP NASDAQ-100 Index Daily (-2x) Inverse Product',
    symbol: '7568.HK',
  },
  {
    value: '7588.HK',
    label:
      '7588.HK,  CSOP Hang Seng China Enterprises Index Daily (-2x) Inverse Product',
    name: 'CSOP Hang Seng China Enterprises Index Daily (-2x) Inverse Product',
    symbol: '7588.HK',
  },
  {
    value: '82811.HK',
    label: '82811.HK,  Haitong CSI300 Index ETF',
    name: 'Haitong CSI300 Index ETF',
    symbol: '82811.HK',
  },
  {
    value: '82843.HK',
    label: '82843.HK,  AMUNDI FTSE China A50 Index ETF',
    name: 'AMUNDI FTSE China A50 Index ETF',
    symbol: '82843.HK',
  },
  {
    value: '83012.HK',
    label: '83012.HK,  Amundi Hang Seng HK 35 Index ETF',
    name: 'Amundi Hang Seng HK 35 Index ETF',
    symbol: '83012.HK',
  },
  {
    value: '83079.HK',
    label: '83079.HK,  CICC Bloomberg Barclays China Treasury 1-10 Years ETF',
    name: 'CICC Bloomberg Barclays China Treasury 1-10 Years ETF',
    symbol: '83079.HK',
  },
  {
    value: '83100.HK',
    label: '83100.HK,  E Fund (HK) CSI 300 A-Share Index ETF',
    name: 'E Fund (HK) CSI 300 A-Share Index ETF',
    symbol: '83100.HK',
  },
  {
    value: '83118.HK',
    label:
      '83118.HK,  Harvest Funds (Hong Kong) ETF - Harvest MSCI China A Index ETF',
    name: 'Harvest Funds (Hong Kong) ETF - Harvest MSCI China A Index ETF',
    symbol: '83118.HK',
  },
  {
    value: '83127.HK',
    label: '83127.HK,  Global X CSI 300 ETF',
    name: 'Global X CSI 300 ETF',
    symbol: '83127.HK',
  },
  {
    value: '83128.HK',
    label: '83128.HK,  Hang Seng China A Industry Top Index ETF',
    name: 'Hang Seng China A Industry Top Index ETF',
    symbol: '83128.HK',
  },
  {
    value: '83147.HK',
    label: '83147.HK,  CSOP SZSE ChiNext ETF',
    name: 'CSOP SZSE ChiNext ETF',
    symbol: '83147.HK',
  },
  {
    value: '83168.HK',
    label: '83168.HK,  Hang Seng RMB Gold ETF',
    name: 'Hang Seng RMB Gold ETF',
    symbol: '83168.HK',
  },
  {
    value: '83199.HK',
    label:
      '83199.HK,  CSOP Bloomberg Barclays China Treasury + Policy Bank Bond Index ETF',
    name: 'CSOP Bloomberg Barclays China Treasury + Policy Bank Bond Index ETF',
    symbol: '83199.HK',
  },
  {
    value: '9074.HK',
    label: '9074.HK,  iShares Core MSCI Taiwan ETF',
    name: 'iShares Core MSCI Taiwan ETF',
    symbol: '9074.HK',
  },
  {
    value: '9077.HK',
    label: '9077.HK,  Premia US Treasury Floating Rate ETF',
    name: 'Premia US Treasury Floating Rate ETF',
    symbol: '9077.HK',
  },
  {
    value: '9181.HK',
    label:
      '9181.HK,  Premia Asia Innovative Technology and Metaverse Theme ETF',
    name: 'Premia Asia Innovative Technology and Metaverse Theme ETF',
    symbol: '9181.HK',
  },
  {
    value: '9804.HK',
    label: '9804.HK,  Premia MSCI Vietnam ETF',
    name: 'Premia MSCI Vietnam ETF',
    symbol: '9804.HK',
  },
  {
    value: '9810.HK',
    label: '9810.HK,  Premia Dow Jones Emerging ASEAN Titans 100 ETF',
    name: 'Premia Dow Jones Emerging ASEAN Titans 100 ETF',
    symbol: '9810.HK',
  },
  {
    value: 'ZWRK',
    label: 'ZWRK,  Z-Work Acquisition Corp.',
    name: 'Z-Work Acquisition Corp.',
    symbol: 'ZWRK',
  },
  {
    value: 'CLRMW',
    label: 'CLRMW,  Clarim Acquisition Corp.',
    name: 'Clarim Acquisition Corp.',
    symbol: 'CLRMW',
  },
  {
    value: 'ZWRKW',
    label: 'ZWRKW,  Z-Work Acquisition Corp.',
    name: 'Z-Work Acquisition Corp.',
    symbol: 'ZWRKW',
  },
  {
    value: 'CLRM',
    label: 'CLRM,  Clarim Acquisition Corp.',
    name: 'Clarim Acquisition Corp.',
    symbol: 'CLRM',
  },
  {
    value: 'QQQJ',
    label: 'QQQJ,  Invesco NASDAQ Next Gen 100 ETF',
    name: 'Invesco NASDAQ Next Gen 100 ETF',
    symbol: 'QQQJ',
  },
  {
    value: 'MSOS',
    label: 'MSOS,  AdvisorShares Pure US Cannabis ETF',
    name: 'AdvisorShares Pure US Cannabis ETF',
    symbol: 'MSOS',
  },
  {
    value: 'EDOC',
    label: 'EDOC,  Global X Telemedicine & Digital Health ETF',
    name: 'Global X Telemedicine & Digital Health ETF',
    symbol: 'EDOC',
  },
  {
    value: 'QQQM',
    label: 'QQQM,  Invesco NASDAQ 100 ETF',
    name: 'Invesco NASDAQ 100 ETF',
    symbol: 'QQQM',
  },
  {
    value: 'TDSC',
    label: 'TDSC,  Cabana Target Drawdown 10 ETF',
    name: 'Cabana Target Drawdown 10 ETF',
    symbol: 'TDSC',
  },
  {
    value: 'GINN',
    label: 'GINN,  Goldman Sachs Innovate Equity ETF',
    name: 'Goldman Sachs Innovate Equity ETF',
    symbol: 'GINN',
  },
  {
    value: 'TDSB',
    label: 'TDSB,  Cabana Target Drawdown 7 ETF',
    name: 'Cabana Target Drawdown 7 ETF',
    symbol: 'TDSB',
  },
  {
    value: 'TDSD',
    label: 'TDSD,  Cabana Target Drawdown 13 ETF',
    name: 'Cabana Target Drawdown 13 ETF',
    symbol: 'TDSD',
  },
  {
    value: 'GCOR',
    label: 'GCOR,  Goldman Sachs Access U.S. Aggregate Bond ETF',
    name: 'Goldman Sachs Access U.S. Aggregate Bond ETF',
    symbol: 'GCOR',
  },
  {
    value: 'CTEC',
    label: 'CTEC,  Global X CleanTech ETF',
    name: 'Global X CleanTech ETF',
    symbol: 'CTEC',
  },
  {
    value: 'XYLD',
    label: 'XYLD,  Global X S&P 500 Covered Call ETF',
    name: 'Global X S&P 500 Covered Call ETF',
    symbol: 'XYLD',
  },
  {
    value: 'QQQN',
    label: 'QQQN,  VictoryShares Nasdaq Next 50 ETF',
    name: 'VictoryShares Nasdaq Next 50 ETF',
    symbol: 'QQQN',
  },
  {
    value: 'JAAA',
    label: 'JAAA,  Janus Henderson AAA CLO ETF',
    name: 'Janus Henderson AAA CLO ETF',
    symbol: 'JAAA',
  },
  {
    value: 'HYBB',
    label: 'HYBB,  iShares BB Rated Corporate Bond ETF',
    name: 'iShares BB Rated Corporate Bond ETF',
    symbol: 'HYBB',
  },
  {
    value: 'SPD',
    label: 'SPD,  Simplify US Equity PLUS Downside Convexity ETF',
    name: 'Simplify US Equity PLUS Downside Convexity ETF',
    symbol: 'SPD',
  },
  {
    value: 'HYXF',
    label: 'HYXF,  iShares ESG Advanced High Yield Corporate Bond ETF',
    name: 'iShares ESG Advanced High Yield Corporate Bond ETF',
    symbol: 'HYXF',
  },
  {
    value: 'SPYC',
    label: 'SPYC,  Simplify US Equity PLUS Convexity ETF',
    name: 'Simplify US Equity PLUS Convexity ETF',
    symbol: 'SPYC',
  },
  {
    value: 'LSAT',
    label: 'LSAT,  LeaderShares AlphaFactor Tactical Focused ETF',
    name: 'LeaderShares AlphaFactor Tactical Focused ETF',
    symbol: 'LSAT',
  },
  {
    value: 'ACTV',
    label: 'ACTV,  LeaderShares Activist Leaders ETF',
    name: 'LeaderShares Activist Leaders ETF',
    symbol: 'ACTV',
  },
  {
    value: 'UPC',
    label: 'UPC,  Universe Pharmaceuticals INC',
    name: 'Universe Pharmaceuticals INC',
    symbol: 'UPC',
  },
  {
    value: 'NBSTU',
    label: 'NBSTU,  Newbury Street Acquisition Corporation',
    name: 'Newbury Street Acquisition Corporation',
    symbol: 'NBSTU',
  },
  {
    value: 'MOVE',
    label: 'MOVE,  Movano Inc.',
    name: 'Movano Inc.',
    symbol: 'MOVE',
  },
  {
    value: 'AZAO',
    label: 'AZAO,  AllianzIM U.S. Large Cap Buffer10 Oct ETF',
    name: 'AllianzIM U.S. Large Cap Buffer10 Oct ETF',
    symbol: 'AZAO',
  },
  {
    value: 'ANEW',
    label: 'ANEW,  ProShares MSCI Transformational Changes ETF',
    name: 'ProShares MSCI Transformational Changes ETF',
    symbol: 'ANEW',
  },
  {
    value: 'AZBO',
    label: 'AZBO,  AllianzIM U.S. Large Cap Buffer20 Oct ETF',
    name: 'AllianzIM U.S. Large Cap Buffer20 Oct ETF',
    symbol: 'AZBO',
  },
  {
    value: 'RBND',
    label: 'RBND,  SPDR Bloomberg SASB Corporate Bond ESG Select ETF',
    name: 'SPDR Bloomberg SASB Corporate Bond ESG Select ETF',
    symbol: 'RBND',
  },
  {
    value: 'SCDL',
    label: 'SCDL,  ETRACS 2x Leveraged US Dividend Factor TR ETN',
    name: 'ETRACS 2x Leveraged US Dividend Factor TR ETN',
    symbol: 'SCDL',
  },
  {
    value: 'IWDL',
    label: 'IWDL,  ETRACS 2x Leveraged US Value Factor TR ETN',
    name: 'ETRACS 2x Leveraged US Value Factor TR ETN',
    symbol: 'IWDL',
  },
  {
    value: 'IWML',
    label: 'IWML,  ETRACS 2x Leveraged US Size Factor TR ETN',
    name: 'ETRACS 2x Leveraged US Size Factor TR ETN',
    symbol: 'IWML',
  },
  {
    value: 'QULL',
    label: 'QULL,  ETRACS 2x Leveraged MSCI US Quality Factor TR ETN',
    name: 'ETRACS 2x Leveraged MSCI US Quality Factor TR ETN',
    symbol: 'QULL',
  },
  {
    value: 'USML',
    label:
      'USML,  ETRACS 2x Leveraged MSCI US Minimum Volatility Factor TR ETN',
    name: 'ETRACS 2x Leveraged MSCI US Minimum Volatility Factor TR ETN',
    symbol: 'USML',
  },
  {
    value: 'LCG',
    label: 'LCG,  Sterling Capital Focus Equity ETF',
    name: 'Sterling Capital Focus Equity ETF',
    symbol: 'LCG',
  },
  {
    value: 'AVIG',
    label: 'AVIG,  Avantis Core Fixed Income ETF',
    name: 'Avantis Core Fixed Income ETF',
    symbol: 'AVIG',
  },
  {
    value: 'IWFL',
    label: 'IWFL,  ETRACS 2x Leveraged US Growth Factor TR ETN',
    name: 'ETRACS 2x Leveraged US Growth Factor TR ETN',
    symbol: 'IWFL',
  },
  {
    value: 'MTUL',
    label: 'MTUL,  ETRACS 2x Leveraged MSCI US Momentum Factor TR ETN',
    name: 'ETRACS 2x Leveraged MSCI US Momentum Factor TR ETN',
    symbol: 'MTUL',
  },
  {
    value: 'BSCU',
    label: 'BSCU,  Invesco BulletShares 2030 Corporate Bond ETF',
    name: 'Invesco BulletShares 2030 Corporate Bond ETF',
    symbol: 'BSCU',
  },
  {
    value: 'AVSF',
    label: 'AVSF,  Avantis Short-Term Fixed Income ETF',
    name: 'Avantis Short-Term Fixed Income ETF',
    symbol: 'AVSF',
  },
  {
    value: 'VPN',
    label: 'VPN,  Global X Data Center REITs & Digital Infrastructure ETF',
    name: 'Global X Data Center REITs & Digital Infrastructure ETF',
    symbol: 'VPN',
  },
  {
    value: 'TDSA',
    label: 'TDSA,  Cabana Target Drawdown 5 ETF',
    name: 'Cabana Target Drawdown 5 ETF',
    symbol: 'TDSA',
  },
  {
    value: 'VICE',
    label: 'VICE,  AdvisorShares Vice ETF',
    name: 'AdvisorShares Vice ETF',
    symbol: 'VICE',
  },
  {
    value: 'EQOP',
    label: 'EQOP,  Natixis U.S. Equity Opportunities ETF',
    name: 'Natixis U.S. Equity Opportunities ETF',
    symbol: 'EQOP',
  },
  {
    value: 'EMXF',
    label: 'EMXF,  iShares ESG Advanced MSCI EM ETF',
    name: 'iShares ESG Advanced MSCI EM ETF',
    symbol: 'EMXF',
  },
  {
    value: 'QYLG',
    label: 'QYLG,  Global X Nasdaq 100 Covered Call & Growth ETF',
    name: 'Global X Nasdaq 100 Covered Call & Growth ETF',
    symbol: 'QYLG',
  },
  {
    value: 'BSJS',
    label: 'BSJS,  Invesco BulletShares 2028 High Yield Corporate Bond ETF',
    name: 'Invesco BulletShares 2028 High Yield Corporate Bond ETF',
    symbol: 'BSJS',
  },
  {
    value: 'AAA',
    label: 'AAA,  AXS First Priority CLO Bond ETF',
    name: 'AXS First Priority CLO Bond ETF',
    symbol: 'AAA',
  },
  {
    value: 'BILS',
    label: 'BILS,  SPDR Bloomberg 3-12 Month T-Bill ETF',
    name: 'SPDR Bloomberg 3-12 Month T-Bill ETF',
    symbol: 'BILS',
  },
  {
    value: 'SPUC',
    label: 'SPUC,  Simplify US Equity PLUS Upside Convexity ETF',
    name: 'Simplify US Equity PLUS Upside Convexity ETF',
    symbol: 'SPUC',
  },
  {
    value: 'FLRG',
    label: 'FLRG,  Fidelity U.S. Multifactor ETF',
    name: 'Fidelity U.S. Multifactor ETF',
    symbol: 'FLRG',
  },
  {
    value: 'EWEB',
    label: 'EWEB,  Global X Emerging Markets Internet & E-commerce ETF',
    name: 'Global X Emerging Markets Internet & E-commerce ETF',
    symbol: 'EWEB',
  },
  {
    value: 'VNSE',
    label: 'VNSE,  Natixis Vaughan Nelson Select ETF',
    name: 'Natixis Vaughan Nelson Select ETF',
    symbol: 'VNSE',
  },
  {
    value: 'CBLS',
    label: 'CBLS,  Changebridge Capital Long/Short Equity ETF',
    name: 'Changebridge Capital Long/Short Equity ETF',
    symbol: 'CBLS',
  },
  {
    value: 'IWFH',
    label: 'IWFH,  iShares Virtual Work and Life Multisector ETF',
    name: 'iShares Virtual Work and Life Multisector ETF',
    symbol: 'IWFH',
  },
  {
    value: 'ACVF',
    label: 'ACVF,  American Conservative Values ETF',
    name: 'American Conservative Values ETF',
    symbol: 'ACVF',
  },
  {
    value: 'BSMU',
    label: 'BSMU,  Invesco BulletShares 2030 Municipal Bond ETF',
    name: 'Invesco BulletShares 2030 Municipal Bond ETF',
    symbol: 'BSMU',
  },
  {
    value: 'CHB',
    label: 'CHB,  Global X China Biotech Innovation ETF',
    name: 'Global X China Biotech Innovation ETF',
    symbol: 'CHB',
  },
  {
    value: 'LBAY',
    label: 'LBAY,  Leatherback Long/Short Alternative Yield ETF',
    name: 'Leatherback Long/Short Alternative Yield ETF',
    symbol: 'LBAY',
  },
  {
    value: 'XYLG',
    label: 'XYLG,  Global X S&P 500 Covered Call & Growth ETF',
    name: 'Global X S&P 500 Covered Call & Growth ETF',
    symbol: 'XYLG',
  },
  {
    value: 'DEMZ',
    label: 'DEMZ,  Democratic Large Cap Core ETF',
    name: 'Democratic Large Cap Core ETF',
    symbol: 'DEMZ',
  },
  {
    value: 'ASPY',
    label: 'ASPY,  ASYMshares ASYMmetric S&P 500 ETF',
    name: 'ASYMshares ASYMmetric S&P 500 ETF',
    symbol: 'ASPY',
  },
  {
    value: 'HDRO',
    label: 'HDRO,  Defiance Next Gen H2 ETF',
    name: 'Defiance Next Gen H2 ETF',
    symbol: 'HDRO',
  },
  {
    value: 'JEMA',
    label: 'JEMA,  JPMorgan ActiveBuilders Emerging Markets Equity ETF',
    name: 'JPMorgan ActiveBuilders Emerging Markets Equity ETF',
    symbol: 'JEMA',
  },
  {
    value: 'TBSAU',
    label: 'TBSAU,  TB SA Acquisition Corp',
    name: 'TB SA Acquisition Corp',
    symbol: 'TBSAU',
  },
  {
    value: 'LEGAU',
    label: 'LEGAU,  Lead Edge Growth Opportunities, Ltd',
    name: 'Lead Edge Growth Opportunities, Ltd',
    symbol: 'LEGAU',
  },
  {
    value: 'MBINN',
    label: 'MBINN,  Merchants Bancorp',
    name: 'Merchants Bancorp',
    symbol: 'MBINN',
  },
  {
    value: 'KVSC',
    label: 'KVSC,  Khosla Ventures Acquisition Co. III',
    name: 'Khosla Ventures Acquisition Co. III',
    symbol: 'KVSC',
  },
  {
    value: 'DISAU',
    label: 'DISAU,  Disruptive Acquisition Corporation I',
    name: 'Disruptive Acquisition Corporation I',
    symbol: 'DISAU',
  },
  {
    value: 'ACVA',
    label: 'ACVA,  ACV Auctions Inc.',
    name: 'ACV Auctions Inc.',
    symbol: 'ACVA',
  },
  {
    value: '2195.HK',
    label: '2195.HK,  Unity Enterprise Holdings Limited',
    name: 'Unity Enterprise Holdings Limited',
    symbol: '2195.HK',
  },
  {
    value: 'DOCN',
    label: 'DOCN,  DigitalOcean Holdings, Inc.',
    name: 'DigitalOcean Holdings, Inc.',
    symbol: 'DOCN',
  },
  {
    value: 'CRZNU',
    label: 'CRZNU,  Corazon Capital V838 Monoceros Corp',
    name: 'Corazon Capital V838 Monoceros Corp',
    symbol: 'CRZNU',
  },
  {
    value: 'NGC-UN',
    label: 'NGC-UN,  Northern Genesis Acquisition Corp. III',
    name: 'Northern Genesis Acquisition Corp. III',
    symbol: 'NGC-UN',
  },
  {
    value: 'KYOTO.OL',
    label: 'KYOTO.OL,  Kyoto Group AS',
    name: 'Kyoto Group AS',
    symbol: 'KYOTO.OL',
  },
  {
    value: 'TRST.L',
    label: 'TRST.L,  Trustpilot Group plc',
    name: 'Trustpilot Group plc',
    symbol: 'TRST.L',
  },
  {
    value: 'ALPAU',
    label: 'ALPAU,  Alpha Healthcare Acquisition Corp. III',
    name: 'Alpha Healthcare Acquisition Corp. III',
    symbol: 'ALPAU',
  },
  {
    value: 'OLK',
    label: 'OLK,  Olink Holding AB (publ)',
    name: 'Olink Holding AB (publ)',
    symbol: 'OLK',
  },
  {
    value: 'WMPN',
    label: 'WMPN,  William Penn Bancorporation',
    name: 'William Penn Bancorporation',
    symbol: 'WMPN',
  },
  {
    value: 'DSEY',
    label: 'DSEY,  Diversey Holdings, Ltd.',
    name: 'Diversey Holdings, Ltd.',
    symbol: 'DSEY',
  },
  {
    value: 'EEIQ',
    label: 'EEIQ,  EpicQuest Education Group International Limited',
    name: 'EpicQuest Education Group International Limited',
    symbol: 'EEIQ',
  },
  {
    value: 'MSDAU',
    label: 'MSDAU,  MSD Acquisition Corp.',
    name: 'MSD Acquisition Corp.',
    symbol: 'MSDAU',
  },
  {
    value: 'IWS.OL',
    label: 'IWS.OL,  Integrated Wind Solutions ASA',
    name: 'Integrated Wind Solutions ASA',
    symbol: 'IWS.OL',
  },
  {
    value: 'VZIO',
    label: 'VZIO,  VIZIO Holding Corp.',
    name: 'VIZIO Holding Corp.',
    symbol: 'VZIO',
  },
  {
    value: 'SEMR',
    label: 'SEMR,  Semrush Holdings, Inc.',
    name: 'Semrush Holdings, Inc.',
    symbol: 'SEMR',
  },
  {
    value: 'CRCT',
    label: 'CRCT,  Cricut, Inc.',
    name: 'Cricut, Inc.',
    symbol: 'CRCT',
  },
  {
    value: 'LVTX',
    label: 'LVTX,  LAVA Therapeutics N.V.',
    name: 'LAVA Therapeutics N.V.',
    symbol: 'LVTX',
  },
  {
    value: 'EWTX',
    label: 'EWTX,  Edgewise Therapeutics, Inc.',
    name: 'Edgewise Therapeutics, Inc.',
    symbol: 'EWTX',
  },
  {
    value: 'IKNA',
    label: 'IKNA,  Ikena Oncology, Inc.',
    name: 'Ikena Oncology, Inc.',
    symbol: 'IKNA',
  },
  {
    value: 'ALHC',
    label: 'ALHC,  Alignment Healthcare, Inc.',
    name: 'Alignment Healthcare, Inc.',
    symbol: 'ALHC',
  },
  {
    value: 'HYW',
    label: 'HYW,  Hywin Holdings Ltd.',
    name: 'Hywin Holdings Ltd.',
    symbol: 'HYW',
  },
  {
    value: 'ZH',
    label: 'ZH,  Zhihu Inc.',
    name: 'Zhihu Inc.',
    symbol: 'ZH',
  },
  {
    value: 'DSGN',
    label: 'DSGN,  Design Therapeutics, Inc.',
    name: 'Design Therapeutics, Inc.',
    symbol: 'DSGN',
  },
  {
    value: 'TDUP',
    label: 'TDUP,  ThredUp Inc.',
    name: 'ThredUp Inc.',
    symbol: 'TDUP',
  },
  {
    value: 'KRNLW',
    label: 'KRNLW,  Kernel Group Holdings, Inc.',
    name: 'Kernel Group Holdings, Inc.',
    symbol: 'KRNLW',
  },
  {
    value: 'RCLFW',
    label: 'RCLFW,  Rosecliff Acquisition Corp I',
    name: 'Rosecliff Acquisition Corp I',
    symbol: 'RCLFW',
  },
  {
    value: 'GHAC',
    label: 'GHAC,  Gaming & Hospitality Acquisition Corp.',
    name: 'Gaming & Hospitality Acquisition Corp.',
    symbol: 'GHAC',
  },
  {
    value: 'PUCK',
    label: 'PUCK,  Goal Acquisitions Corp.',
    name: 'Goal Acquisitions Corp.',
    symbol: 'PUCK',
  },
  {
    value: 'KRNL',
    label: 'KRNL,  Kernel Group Holdings, Inc.',
    name: 'Kernel Group Holdings, Inc.',
    symbol: 'KRNL',
  },
  {
    value: 'GHACW',
    label: 'GHACW,  Gaming & Hospitality Acquisition Corp.',
    name: 'Gaming & Hospitality Acquisition Corp.',
    symbol: 'GHACW',
  },
  {
    value: 'RCLF',
    label: 'RCLF,  Rosecliff Acquisition Corp I',
    name: 'Rosecliff Acquisition Corp I',
    symbol: 'RCLF',
  },
  {
    value: 'PUCKW',
    label: 'PUCKW,  Goal Acquisitions Corp.',
    name: 'Goal Acquisitions Corp.',
    symbol: 'PUCKW',
  },
  {
    value: 'IBRX',
    label: 'IBRX,  ImmunityBio, Inc.',
    name: 'ImmunityBio, Inc.',
    symbol: 'IBRX',
  },
  {
    value: 'ARVL',
    label: 'ARVL,  Arrival',
    name: 'Arrival',
    symbol: 'ARVL',
  },
  {
    value: 'AEVA',
    label: 'AEVA,  Aeva Technologies, Inc.',
    name: 'Aeva Technologies, Inc.',
    symbol: 'AEVA',
  },
  {
    value: 'BAJAJ-AUTO.NS',
    label: 'BAJAJ-AUTO.NS,  Bajaj Auto Limited',
    name: 'Bajaj Auto Limited',
    symbol: 'BAJAJ-AUTO.NS',
  },
  {
    value: 'COFORGE.NS',
    label: 'COFORGE.NS,  Coforge Limited',
    name: 'Coforge Limited',
    symbol: 'COFORGE.NS',
  },
  {
    value: 'INDUSTOWER.NS',
    label: 'INDUSTOWER.NS,  Indus Towers Limited',
    name: 'Indus Towers Limited',
    symbol: 'INDUSTOWER.NS',
  },
  {
    value: 'STLTECH.NS',
    label: 'STLTECH.NS,  Sterlite Technologies Limited',
    name: 'Sterlite Technologies Limited',
    symbol: 'STLTECH.NS',
  },
  {
    value: 'TATAMTRDVR.NS',
    label: 'TATAMTRDVR.NS,  Tata Motors Limited',
    name: 'Tata Motors Limited',
    symbol: 'TATAMTRDVR.NS',
  },
  {
    value: 'SDAC',
    label: 'SDAC,  Sustainable Development Acquisition I Corp.',
    name: 'Sustainable Development Acquisition I Corp.',
    symbol: 'SDAC',
  },
  {
    value: 'RMGCW',
    label: 'RMGCW,  RMG Acquisition Corp. III',
    name: 'RMG Acquisition Corp. III',
    symbol: 'RMGCW',
  },
  {
    value: 'FROY.OL',
    label: 'FROY.OL,  Frøy ASA',
    name: 'Frøy ASA',
    symbol: 'FROY.OL',
  },
  {
    value: 'ITQRW',
    label: 'ITQRW,  Itiquira Acquisition Corp.',
    name: 'Itiquira Acquisition Corp.',
    symbol: 'ITQRW',
  },
  {
    value: 'ITQ',
    label: 'ITQ,  Itiquira Acquisition Corp.',
    name: 'Itiquira Acquisition Corp.',
    symbol: 'ITQ',
  },
  {
    value: 'RMGC',
    label: 'RMGC,  RMG Acquisition Corp. III',
    name: 'RMG Acquisition Corp. III',
    symbol: 'RMGC',
  },
  {
    value: 'JOFFW',
    label: 'JOFFW,  JOFF Fintech Acquisition Corp.',
    name: 'JOFF Fintech Acquisition Corp.',
    symbol: 'JOFFW',
  },
  {
    value: 'TBCP',
    label: 'TBCP,  Thunder Bridge Capital Partners III Inc.',
    name: 'Thunder Bridge Capital Partners III Inc.',
    symbol: 'TBCP',
  },
  {
    value: 'SDACW',
    label: 'SDACW,  Sustainable Development Acquisition I Corp.',
    name: 'Sustainable Development Acquisition I Corp.',
    symbol: 'SDACW',
  },
  {
    value: 'TBCPW',
    label: 'TBCPW,  Thunder Bridge Capital Partners III Inc.',
    name: 'Thunder Bridge Capital Partners III Inc.',
    symbol: 'TBCPW',
  },
  {
    value: 'JOFF',
    label: 'JOFF,  JOFF Fintech Acquisition Corp.',
    name: 'JOFF Fintech Acquisition Corp.',
    symbol: 'JOFF',
  },
  {
    value: 'CARE.TO',
    label: 'CARE.TO,  Dialogue Health Technologies Inc.',
    name: 'Dialogue Health Technologies Inc.',
    symbol: 'CARE.TO',
  },
  {
    value: 'COVA',
    label: 'COVA,  COVA Acquisition Corp.',
    name: 'COVA Acquisition Corp.',
    symbol: 'COVA',
  },
  {
    value: 'COVAW',
    label: 'COVAW,  COVA Acquisition Corp.',
    name: 'COVA Acquisition Corp.',
    symbol: 'COVAW',
  },
  {
    value: 'COUR',
    label: 'COUR,  Coursera, Inc.',
    name: 'Coursera, Inc.',
    symbol: 'COUR',
  },
  {
    value: 'ACHL',
    label: 'ACHL,  Achilles Therapeutics plc',
    name: 'Achilles Therapeutics plc',
    symbol: 'ACHL',
  },
  {
    value: 'ROO.L',
    label: 'ROO.L,  Deliveroo plc',
    name: 'Deliveroo plc',
    symbol: 'ROO.L',
  },
  {
    value: 'MBTCU',
    label: 'MBTCU,  Nocturne Acquisition Corporation',
    name: 'Nocturne Acquisition Corporation',
    symbol: 'MBTCU',
  },
  {
    value: 'EVOJW',
    label: 'EVOJW,  Evo Acquisition Corp.',
    name: 'Evo Acquisition Corp.',
    symbol: 'EVOJW',
  },
  {
    value: 'EVOJ',
    label: 'EVOJ,  Evo Acquisition Corp.',
    name: 'Evo Acquisition Corp.',
    symbol: 'EVOJ',
  },
  {
    value: 'ULCC',
    label: 'ULCC,  Frontier Group Holdings, Inc.',
    name: 'Frontier Group Holdings, Inc.',
    symbol: 'ULCC',
  },
  {
    value: 'COMP',
    label: 'COMP,  Compass, Inc.',
    name: 'Compass, Inc.',
    symbol: 'COMP',
  },
  {
    value: 'UTME',
    label: 'UTME,  UTime Limited',
    name: 'UTime Limited',
    symbol: 'UTME',
  },
  {
    value: 'KARO',
    label: 'KARO,  Karooooo Ltd.',
    name: 'Karooooo Ltd.',
    symbol: 'KARO',
  },
  {
    value: 'ACBAU',
    label: 'ACBAU,  Ace Global Business Acquisition Limited',
    name: 'Ace Global Business Acquisition Limited',
    symbol: 'ACBAU',
  },
  {
    value: 'EM',
    label: 'EM,  Smart Share Global Limited',
    name: 'Smart Share Global Limited',
    symbol: 'EM',
  },
  {
    value: 'EKOP.BR',
    label: 'EKOP.BR,  Ekopak NV',
    name: 'Ekopak NV',
    symbol: 'EKOP.BR',
  },
  {
    value: 'LGACW',
    label: 'LGACW,  Lazard Growth Acquisition Corp. I',
    name: 'Lazard Growth Acquisition Corp. I',
    symbol: 'LGACW',
  },
  {
    value: 'SCOB',
    label: 'SCOB,  ScION Tech Growth II',
    name: 'ScION Tech Growth II',
    symbol: 'SCOB',
  },
  {
    value: 'SCOBW',
    label: 'SCOBW,  ScION Tech Growth II',
    name: 'ScION Tech Growth II',
    symbol: 'SCOBW',
  },
  {
    value: 'LGAC',
    label: 'LGAC,  Lazard Growth Acquisition Corp. I',
    name: 'Lazard Growth Acquisition Corp. I',
    symbol: 'LGAC',
  },
  {
    value: 'MSAC',
    label: 'MSAC,  Medicus Sciences Acquisition Corp.',
    name: 'Medicus Sciences Acquisition Corp.',
    symbol: 'MSAC',
  },
  {
    value: 'SCLEW',
    label: 'SCLEW,  Broadscale Acquisition Corp.',
    name: 'Broadscale Acquisition Corp.',
    symbol: 'SCLEW',
  },
  {
    value: 'SCLE',
    label: 'SCLE,  Broadscale Acquisition Corp.',
    name: 'Broadscale Acquisition Corp.',
    symbol: 'SCLE',
  },
  {
    value: 'MSACW',
    label: 'MSACW,  Medicus Sciences Acquisition Corp.',
    name: 'Medicus Sciences Acquisition Corp.',
    symbol: 'MSACW',
  },
  {
    value: 'TSIB',
    label: 'TSIB,  Tishman Speyer Innovation Corp. II',
    name: 'Tishman Speyer Innovation Corp. II',
    symbol: 'TSIB',
  },
  {
    value: 'TSIBW',
    label: 'TSIBW,  Tishman Speyer Innovation Corp. II',
    name: 'Tishman Speyer Innovation Corp. II',
    symbol: 'TSIBW',
  },
  {
    value: 'INVZW',
    label: 'INVZW,  Innoviz Technologies Ltd.',
    name: 'Innoviz Technologies Ltd.',
    symbol: 'INVZW',
  },
  {
    value: 'INVZ',
    label: 'INVZ,  Innoviz Technologies Ltd.',
    name: 'Innoviz Technologies Ltd.',
    symbol: 'INVZ',
  },
  {
    value: 'MACA',
    label: 'MACA,  Moringa Acquisition Corp',
    name: 'Moringa Acquisition Corp',
    symbol: 'MACA',
  },
  {
    value: 'MACAW',
    label: 'MACAW,  Moringa Acquisition Corp',
    name: 'Moringa Acquisition Corp',
    symbol: 'MACAW',
  },
  {
    value: 'PANA',
    label: 'PANA,  Panacea Acquisition Corp. II',
    name: 'Panacea Acquisition Corp. II',
    symbol: 'PANA',
  },
  {
    value: 'ACEV',
    label: 'ACEV,  ACE Convergence Acquisition Corp.',
    name: 'ACE Convergence Acquisition Corp.',
    symbol: 'ACEV',
  },
  {
    value: 'BMTX',
    label: 'BMTX,  BM Technologies, Inc.',
    name: 'BM Technologies, Inc.',
    symbol: 'BMTX',
  },
  {
    value: 'BTRS',
    label: 'BTRS,  BTRS Holdings Inc.',
    name: 'BTRS Holdings Inc.',
    symbol: 'BTRS',
  },
  {
    value: 'ELYS',
    label: 'ELYS,  Elys Game Technology, Corp.',
    name: 'Elys Game Technology, Corp.',
    symbol: 'ELYS',
  },
  {
    value: 'IGAC',
    label: 'IGAC,  IG Acquisition Corp.',
    name: 'IG Acquisition Corp.',
    symbol: 'IGAC',
  },
  {
    value: 'LOTZ',
    label: 'LOTZ,  CarLotz, Inc.',
    name: 'CarLotz, Inc.',
    symbol: 'LOTZ',
  },
  {
    value: 'LSEA',
    label: 'LSEA,  Landsea Homes Corporation',
    name: 'Landsea Homes Corporation',
    symbol: 'LSEA',
  },
  {
    value: 'MASS',
    label: 'MASS,  908 Devices Inc.',
    name: '908 Devices Inc.',
    symbol: 'MASS',
  },
  {
    value: 'PLBY',
    label: 'PLBY,  PLBY Group, Inc.',
    name: 'PLBY Group, Inc.',
    symbol: 'PLBY',
  },
  {
    value: 'PSFE',
    label: 'PSFE,  Paysafe Limited',
    name: 'Paysafe Limited',
    symbol: 'PSFE',
  },
  {
    value: 'SBTX',
    label: 'SBTX,  Silverback Therapeutics, Inc.',
    name: 'Silverback Therapeutics, Inc.',
    symbol: 'SBTX',
  },
  {
    value: 'STLA',
    label: 'STLA,  Stellantis N.V.',
    name: 'Stellantis N.V.',
    symbol: 'STLA',
  },
  {
    value: 'UWMC',
    label: 'UWMC,  UWM Holdings Corporation',
    name: 'UWM Holdings Corporation',
    symbol: 'UWMC',
  },
  {
    value: 'VIEW',
    label: 'VIEW,  View, Inc.',
    name: 'View, Inc.',
    symbol: 'VIEW',
  },
  {
    value: 'VINC',
    label: 'VINC,  Vincerx Pharma, Inc.',
    name: 'Vincerx Pharma, Inc.',
    symbol: 'VINC',
  },
  {
    value: 'VRDN',
    label: 'VRDN,  Viridian Therapeutics, Inc.',
    name: 'Viridian Therapeutics, Inc.',
    symbol: 'VRDN',
  },
  {
    value: 'SKIN',
    label: 'SKIN,  The Beauty Health Company',
    name: 'The Beauty Health Company',
    symbol: 'SKIN',
  },
  {
    value: 'MDA.TO',
    label: 'MDA.TO,  MDA Ltd.',
    name: 'MDA Ltd.',
    symbol: 'MDA.TO',
  },
  {
    value: 'CSFS.L',
    label: 'CSFS.L,  Cornerstone FS plc',
    name: 'Cornerstone FS plc',
    symbol: 'CSFS.L',
  },
  {
    value: 'TIOAU',
    label: 'TIOAU,  Tio Tech A',
    name: 'Tio Tech A',
    symbol: 'TIOAU',
  },
  {
    value: 'BSKYU',
    label: 'BSKYU,  Big Sky Growth Partners, Inc.',
    name: 'Big Sky Growth Partners, Inc.',
    symbol: 'BSKYU',
  },
  {
    value: 'RPHM',
    label: 'RPHM,  Reneo Pharmaceuticals, Inc.',
    name: 'Reneo Pharmaceuticals, Inc.',
    symbol: 'RPHM',
  },
  {
    value: 'AQWA',
    label: 'AQWA,  Global X Clean Water ETF',
    name: 'Global X Clean Water ETF',
    symbol: 'AQWA',
  },
  {
    value: 'VENAR',
    label: 'VENAR,  Venus Acquisition Corporation',
    name: 'Venus Acquisition Corporation',
    symbol: 'VENAR',
  },
  {
    value: 'VENAW',
    label: 'VENAW,  Venus Acquisition Corporation',
    name: 'Venus Acquisition Corporation',
    symbol: 'VENAW',
  },
  {
    value: 'VENA',
    label: 'VENA,  Venus Acquisition Corporation',
    name: 'Venus Acquisition Corporation',
    symbol: 'VENA',
  },
  {
    value: 'XOMAO',
    label: 'XOMAO,  XOMA Corporation',
    name: 'XOMA Corporation',
    symbol: 'XOMAO',
  },
  {
    value: 'NVSAW',
    label: 'NVSAW,  New Vista Acquisition Corp',
    name: 'New Vista Acquisition Corp',
    symbol: 'NVSAW',
  },
  {
    value: 'NVSA',
    label: 'NVSA,  New Vista Acquisition Corp',
    name: 'New Vista Acquisition Corp',
    symbol: 'NVSA',
  },
  {
    value: 'PMGM',
    label: 'PMGM,  Priveterra Acquisition Corp.',
    name: 'Priveterra Acquisition Corp.',
    symbol: 'PMGM',
  },
  {
    value: 'PMGMW',
    label: 'PMGMW,  Priveterra Acquisition Corp.',
    name: 'Priveterra Acquisition Corp.',
    symbol: 'PMGMW',
  },
  {
    value: 'ALKT',
    label: 'ALKT,  Alkami Technology, Inc.',
    name: 'Alkami Technology, Inc.',
    symbol: 'ALKT',
  },
  {
    value: 'KIII',
    label: 'KIII,  Kismet Acquisition Three Corp.',
    name: 'Kismet Acquisition Three Corp.',
    symbol: 'KIII',
  },
  {
    value: 'FSRX',
    label: 'FSRX,  FinServ Acquisition Corp. II',
    name: 'FinServ Acquisition Corp. II',
    symbol: 'FSRX',
  },
  {
    value: 'KIIIW',
    label: 'KIIIW,  Kismet Acquisition Three Corp.',
    name: 'Kismet Acquisition Three Corp.',
    symbol: 'KIIIW',
  },
  {
    value: 'KAIIW',
    label: 'KAIIW,  Kismet Acquisition Two Corp.',
    name: 'Kismet Acquisition Two Corp.',
    symbol: 'KAIIW',
  },
  {
    value: 'KAII',
    label: 'KAII,  Kismet Acquisition Two Corp.',
    name: 'Kismet Acquisition Two Corp.',
    symbol: 'KAII',
  },
  {
    value: 'FSRXW',
    label: 'FSRXW,  FinServ Acquisition Corp. II',
    name: 'FinServ Acquisition Corp. II',
    symbol: 'FSRXW',
  },
  {
    value: 'DAPP',
    label: 'DAPP,  VanEck Digital Transformation ETF',
    name: 'VanEck Digital Transformation ETF',
    symbol: 'DAPP',
  },
  {
    value: 'TCVA',
    label: 'TCVA,  TCV Acquisition Corp.',
    name: 'TCV Acquisition Corp.',
    symbol: 'TCVA',
  },
  {
    value: 'EBET',
    label: 'EBET,  EBET, Inc.',
    name: 'EBET, Inc.',
    symbol: 'EBET',
  },
  {
    value: 'APP',
    label: 'APP,  AppLovin Corporation',
    name: 'AppLovin Corporation',
    symbol: 'APP',
  },
  {
    value: 'AGL',
    label: 'AGL,  agilon health, inc.',
    name: 'agilon health, inc.',
    symbol: 'AGL',
  },
  {
    value: 'KRT',
    label: 'KRT,  Karat Packaging Inc.',
    name: 'Karat Packaging Inc.',
    symbol: 'KRT',
  },
  {
    value: 'TSP',
    label: 'TSP,  TuSimple Holdings Inc.',
    name: 'TuSimple Holdings Inc.',
    symbol: 'TSP',
  },
  {
    value: 'GSEV',
    label: 'GSEV,  Gores Holdings VII, Inc.',
    name: 'Gores Holdings VII, Inc.',
    symbol: 'GSEV',
  },
  {
    value: 'GSEVW',
    label: 'GSEVW,  Gores Holdings VII, Inc.',
    name: 'Gores Holdings VII, Inc.',
    symbol: 'GSEVW',
  },
  {
    value: 'SLAM',
    label: 'SLAM,  Slam Corp.',
    name: 'Slam Corp.',
    symbol: 'SLAM',
  },
  {
    value: 'SLAMW',
    label: 'SLAMW,  Slam Corp.',
    name: 'Slam Corp.',
    symbol: 'SLAMW',
  },
  {
    value: 'VELOW',
    label: 'VELOW,  Velocity Acquisition Corp.',
    name: 'Velocity Acquisition Corp.',
    symbol: 'VELOW',
  },
  {
    value: 'VELO',
    label: 'VELO,  Velocity Acquisition Corp.',
    name: 'Velocity Acquisition Corp.',
    symbol: 'VELO',
  },
  {
    value: 'FTAA',
    label: 'FTAA,  FTAC Athena Acquisition Corp.',
    name: 'FTAC Athena Acquisition Corp.',
    symbol: 'FTAA',
  },
  {
    value: 'FTAAW',
    label: 'FTAAW,  FTAC Athena Acquisition Corp.',
    name: 'FTAC Athena Acquisition Corp.',
    symbol: 'FTAAW',
  },
  {
    value: 'NBSTW',
    label: 'NBSTW,  Newbury Street Acquisition Corporation',
    name: 'Newbury Street Acquisition Corporation',
    symbol: 'NBSTW',
  },
  {
    value: 'BMEA',
    label: 'BMEA,  Biomea Fusion, Inc.',
    name: 'Biomea Fusion, Inc.',
    symbol: 'BMEA',
  },
  {
    value: 'TRKA',
    label: 'TRKA,  Troika Media Group, Inc.',
    name: 'Troika Media Group, Inc.',
    symbol: 'TRKA',
  },
  {
    value: 'GLBL',
    label: 'GLBL,  Cartesian Growth Corporation',
    name: 'Cartesian Growth Corporation',
    symbol: 'GLBL',
  },
  {
    value: 'AKYA',
    label: 'AKYA,  Akoya Biosciences, Inc.',
    name: 'Akoya Biosciences, Inc.',
    symbol: 'AKYA',
  },
  {
    value: 'HIII',
    label: 'HIII,  Hudson Executive Investment Corp. III',
    name: 'Hudson Executive Investment Corp. III',
    symbol: 'HIII',
  },
  {
    value: 'HIIIW',
    label: 'HIIIW,  Hudson Executive Investment Corp. III',
    name: 'Hudson Executive Investment Corp. III',
    symbol: 'HIIIW',
  },
  {
    value: 'GLBLW',
    label: 'GLBLW,  Cartesian Growth Corporation',
    name: 'Cartesian Growth Corporation',
    symbol: 'GLBLW',
  },
  {
    value: 'GBRG',
    label: 'GBRG,  Goldenbridge Acquisition Limited',
    name: 'Goldenbridge Acquisition Limited',
    symbol: 'GBRG',
  },
  {
    value: 'COLI',
    label: 'COLI,  Colicity Inc.',
    name: 'Colicity Inc.',
    symbol: 'COLI',
  },
  {
    value: 'NBST',
    label: 'NBST,  Newbury Street Acquisition Corporation',
    name: 'Newbury Street Acquisition Corporation',
    symbol: 'NBST',
  },
  {
    value: 'RXRX',
    label: 'RXRX,  Recursion Pharmaceuticals, Inc.',
    name: 'Recursion Pharmaceuticals, Inc.',
    symbol: 'RXRX',
  },
  {
    value: 'GBRGR',
    label: 'GBRGR,  Goldenbridge Acquisition Limited',
    name: 'Goldenbridge Acquisition Limited',
    symbol: 'GBRGR',
  },
  {
    value: 'GBRGW',
    label: 'GBRGW,  Goldenbridge Acquisition Limited',
    name: 'Goldenbridge Acquisition Limited',
    symbol: 'GBRGW',
  },
  {
    value: 'CS',
    label: 'CS,  Credit Suisse Group AG',
    name: 'Credit Suisse Group AG',
    symbol: 'CS',
  },
  {
    value: 'CLOV',
    label: 'CLOV,  Clover Health Investments, Corp.',
    name: 'Clover Health Investments, Corp.',
    symbol: 'CLOV',
  },
  {
    value: 'IFBD',
    label: 'IFBD,  Infobird Co., Ltd',
    name: 'Infobird Co., Ltd',
    symbol: 'IFBD',
  },
  {
    value: 'TRKAW',
    label: 'TRKAW,  Troika Media Group, Inc.',
    name: 'Troika Media Group, Inc.',
    symbol: 'TRKAW',
  },
  {
    value: 'LGO',
    label: 'LGO,  Largo Inc.',
    name: 'Largo Inc.',
    symbol: 'LGO',
  },
  {
    value: 'GIIXW',
    label: 'GIIXW,  Gores Holdings VIII, Inc.',
    name: 'Gores Holdings VIII, Inc.',
    symbol: 'GIIXW',
  },
  {
    value: 'TWLV',
    label: 'TWLV,  Twelve Seas Investment Company II',
    name: 'Twelve Seas Investment Company II',
    symbol: 'TWLV',
  },
  {
    value: 'GIIX',
    label: 'GIIX,  Gores Holdings VIII, Inc.',
    name: 'Gores Holdings VIII, Inc.',
    symbol: 'GIIX',
  },
  {
    value: 'TWLVW',
    label: 'TWLVW,  Twelve Seas Investment Company II',
    name: 'Twelve Seas Investment Company II',
    symbol: 'TWLVW',
  },
  {
    value: 'SKYT',
    label: 'SKYT,  SkyWater Technology, Inc.',
    name: 'SkyWater Technology, Inc.',
    symbol: 'SKYT',
  },
  {
    value: '6622.HK',
    label: '6622.HK,  Zhaoke Ophthalmology Limited',
    name: 'Zhaoke Ophthalmology Limited',
    symbol: '6622.HK',
  },
  {
    value: 'NPCE',
    label: 'NPCE,  NeuroPace, Inc.',
    name: 'NeuroPace, Inc.',
    symbol: 'NPCE',
  },
  {
    value: 'PATH',
    label: 'PATH,  UiPath Inc.',
    name: 'UiPath Inc.',
    symbol: 'PATH',
  },
  {
    value: 'DV',
    label: 'DV,  DoubleVerify Holdings, Inc.',
    name: 'DoubleVerify Holdings, Inc.',
    symbol: 'DV',
  },
  {
    value: 'EGLX',
    label: 'EGLX,  Enthusiast Gaming Holdings Inc.',
    name: 'Enthusiast Gaming Holdings Inc.',
    symbol: 'EGLX',
  },
  {
    value: 'KNBE',
    label: 'KNBE,  KnowBe4, Inc.',
    name: 'KnowBe4, Inc.',
    symbol: 'KNBE',
  },
  {
    value: 'AXON',
    label: 'AXON,  Axon Enterprise, Inc.',
    name: 'Axon Enterprise, Inc.',
    symbol: 'AXON',
  },
  {
    value: 'NDACW',
    label: 'NDACW,  NightDragon Acquisition Corp.',
    name: 'NightDragon Acquisition Corp.',
    symbol: 'NDACW',
  },
  {
    value: 'DHCAW',
    label: 'DHCAW,  DHC Acquisition Corp.',
    name: 'DHC Acquisition Corp.',
    symbol: 'DHCAW',
  },
  {
    value: 'NDAC',
    label: 'NDAC,  NightDragon Acquisition Corp.',
    name: 'NightDragon Acquisition Corp.',
    symbol: 'NDAC',
  },
  {
    value: 'ANZUW',
    label: 'ANZUW,  Anzu Special Acquisition Corp I',
    name: 'Anzu Special Acquisition Corp I',
    symbol: 'ANZUW',
  },
  {
    value: 'OHPA',
    label: 'OHPA,  Orion Acquisition Corp.',
    name: 'Orion Acquisition Corp.',
    symbol: 'OHPA',
  },
  {
    value: 'ANZU',
    label: 'ANZU,  Anzu Special Acquisition Corp I',
    name: 'Anzu Special Acquisition Corp I',
    symbol: 'ANZU',
  },
  {
    value: 'ARRW',
    label: 'ARRW,  Arrowroot Acquisition Corp.',
    name: 'Arrowroot Acquisition Corp.',
    symbol: 'ARRW',
  },
  {
    value: 'DHBC',
    label: 'DHBC,  DHB Capital Corp.',
    name: 'DHB Capital Corp.',
    symbol: 'DHBC',
  },
  {
    value: 'DHCA',
    label: 'DHCA,  DHC Acquisition Corp.',
    name: 'DHC Acquisition Corp.',
    symbol: 'DHCA',
  },
  {
    value: 'ARRWW',
    label: 'ARRWW,  Arrowroot Acquisition Corp.',
    name: 'Arrowroot Acquisition Corp.',
    symbol: 'ARRWW',
  },
  {
    value: 'DHBCW',
    label: 'DHBCW,  DHB Capital Corp.',
    name: 'DHB Capital Corp.',
    symbol: 'DHBCW',
  },
  {
    value: 'OHPAW',
    label: 'OHPAW,  Orion Acquisition Corp.',
    name: 'Orion Acquisition Corp.',
    symbol: 'OHPAW',
  },
  {
    value: 'TMCI',
    label: 'TMCI,  Treace Medical Concepts, Inc.',
    name: 'Treace Medical Concepts, Inc.',
    symbol: 'TMCI',
  },
  {
    value: 'IMPL',
    label: 'IMPL,  Impel Pharmaceuticals Inc.',
    name: 'Impel Pharmaceuticals Inc.',
    symbol: 'IMPL',
  },
  {
    value: 'RAIN',
    label: 'RAIN,  Rain Therapeutics Inc.',
    name: 'Rain Therapeutics Inc.',
    symbol: 'RAIN',
  },
  {
    value: 'SWIM',
    label: 'SWIM,  Latham Group, Inc.',
    name: 'Latham Group, Inc.',
    symbol: 'SWIM',
  },
  {
    value: 'AGTI',
    label: 'AGTI,  Agiliti, Inc.',
    name: 'Agiliti, Inc.',
    symbol: 'AGTI',
  },
  {
    value: 'AURC',
    label: 'AURC,  Aurora Acquisition Corp.',
    name: 'Aurora Acquisition Corp.',
    symbol: 'AURC',
  },
  {
    value: 'RXRAW',
    label: 'RXRAW,  RXR Acquisition Corp.',
    name: 'RXR Acquisition Corp.',
    symbol: 'RXRAW',
  },
  {
    value: 'ACAHW',
    label: 'ACAHW,  Atlantic Coastal Acquisition Corp.',
    name: 'Atlantic Coastal Acquisition Corp.',
    symbol: 'ACAHW',
  },
  {
    value: 'FRSG',
    label: 'FRSG,  First Reserve Sustainable Growth Corp.',
    name: 'First Reserve Sustainable Growth Corp.',
    symbol: 'FRSG',
  },
  {
    value: 'HERA',
    label: 'HERA,  FTAC Hera Acquisition Corp.',
    name: 'FTAC Hera Acquisition Corp.',
    symbol: 'HERA',
  },
  {
    value: 'HERAW',
    label: 'HERAW,  FTAC Hera Acquisition Corp.',
    name: 'FTAC Hera Acquisition Corp.',
    symbol: 'HERAW',
  },
  {
    value: 'AURCW',
    label: 'AURCW,  Aurora Acquisition Corp.',
    name: 'Aurora Acquisition Corp.',
    symbol: 'AURCW',
  },
  {
    value: 'FRSGW',
    label: 'FRSGW,  First Reserve Sustainable Growth Corp.',
    name: 'First Reserve Sustainable Growth Corp.',
    symbol: 'FRSGW',
  },
  {
    value: 'IPVI',
    label: 'IPVI,  InterPrivate IV InfraTech Partners Inc.',
    name: 'InterPrivate IV InfraTech Partners Inc.',
    symbol: 'IPVI',
  },
  {
    value: 'ACAH',
    label: 'ACAH,  Atlantic Coastal Acquisition Corp.',
    name: 'Atlantic Coastal Acquisition Corp.',
    symbol: 'ACAH',
  },
  {
    value: 'RXRA',
    label: 'RXRA,  RXR Acquisition Corp.',
    name: 'RXR Acquisition Corp.',
    symbol: 'RXRA',
  },
  {
    value: 'IPVIW',
    label: 'IPVIW,  InterPrivate IV InfraTech Partners Inc.',
    name: 'InterPrivate IV InfraTech Partners Inc.',
    symbol: 'IPVIW',
  },
  {
    value: 'VPCBW',
    label: 'VPCBW,  VPC Impact Acquisition Holdings II',
    name: 'VPC Impact Acquisition Holdings II',
    symbol: 'VPCBW',
  },
  {
    value: 'VPCB',
    label: 'VPCB,  VPC Impact Acquisition Holdings II',
    name: 'VPC Impact Acquisition Holdings II',
    symbol: 'VPCB',
  },
  {
    value: 'COIN',
    label: 'COIN,  Coinbase Global, Inc.',
    name: 'Coinbase Global, Inc.',
    symbol: 'COIN',
  },
  {
    value: 'PTIXW',
    label: 'PTIXW,  Protagenic Therapeutics, Inc.',
    name: 'Protagenic Therapeutics, Inc.',
    symbol: 'PTIXW',
  },
  {
    value: 'GGPS3.SA',
    label: 'GGPS3.SA,  GPS Participações e Empreendimentos S.A.',
    name: 'GPS Participações e Empreendimentos S.A.',
    symbol: 'GGPS3.SA',
  },
  {
    value: 'AKMY.V',
    label: 'AKMY.V,  K.B. Recycling Industries Ltd.',
    name: 'K.B. Recycling Industries Ltd.',
    symbol: 'AKMY.V',
  },
  {
    value: 'ARGEO.OL',
    label: 'ARGEO.OL,  Argeo AS',
    name: 'Argeo AS',
    symbol: 'ARGEO.OL',
  },
  {
    value: 'RNAZ',
    label: 'RNAZ,  TransCode Therapeutics, Inc.',
    name: 'TransCode Therapeutics, Inc.',
    symbol: 'RNAZ',
  },
  {
    value: 'AVAH',
    label: 'AVAH,  Aveanna Healthcare Holdings Inc.',
    name: 'Aveanna Healthcare Holdings Inc.',
    symbol: 'AVAH',
  },
  {
    value: 'UROY',
    label: 'UROY,  Uranium Royalty Corp.',
    name: 'Uranium Royalty Corp.',
    symbol: 'UROY',
  },
  {
    value: 'PRVA',
    label: 'PRVA,  Privia Health Group, Inc.',
    name: 'Privia Health Group, Inc.',
    symbol: 'PRVA',
  },
  {
    value: 'EDR',
    label: 'EDR,  Endeavor Group Holdings, Inc.',
    name: 'Endeavor Group Holdings, Inc.',
    symbol: 'EDR',
  },
  {
    value: 'ACQRW',
    label: 'ACQRW,  Independence Holdings Corp.',
    name: 'Independence Holdings Corp.',
    symbol: 'ACQRW',
  },
  {
    value: 'ACQR',
    label: 'ACQR,  Independence Holdings Corp.',
    name: 'Independence Holdings Corp.',
    symbol: 'ACQR',
  },
  {
    value: 'AMAOW',
    label: 'AMAOW,  American Acquisition Opportunity Inc.',
    name: 'American Acquisition Opportunity Inc.',
    symbol: 'AMAOW',
  },
  {
    value: 'AMAO',
    label: 'AMAO,  American Acquisition Opportunity Inc.',
    name: 'American Acquisition Opportunity Inc.',
    symbol: 'AMAO',
  },
  {
    value: 'OCCIO',
    label: 'OCCIO,  OFS Credit Company, Inc.',
    name: 'OFS Credit Company, Inc.',
    symbol: 'OCCIO',
  },
  {
    value: 'FTCI',
    label: 'FTCI,  FTC Solar, Inc.',
    name: 'FTC Solar, Inc.',
    symbol: 'FTCI',
  },
  {
    value: 'HOWL',
    label: 'HOWL,  Werewolf Therapeutics, Inc.',
    name: 'Werewolf Therapeutics, Inc.',
    symbol: 'HOWL',
  },
  {
    value: 'FRW',
    label: 'FRW,  PWP Forward Acquisition Corp. I',
    name: 'PWP Forward Acquisition Corp. I',
    symbol: 'FRW',
  },
  {
    value: 'VACC',
    label: 'VACC,  Vaccitech plc',
    name: 'Vaccitech plc',
    symbol: 'VACC',
  },
  {
    value: 'AGGR',
    label: 'AGGR,  Agile Growth Corp.',
    name: 'Agile Growth Corp.',
    symbol: 'AGGR',
  },
  {
    value: 'CXSE3.SA',
    label: 'CXSE3.SA,  Caixa Seguridade Participações S.A.',
    name: 'Caixa Seguridade Participações S.A.',
    symbol: 'CXSE3.SA',
  },
  {
    value: 'DTOCW',
    label: 'DTOCW,  Digital Transformation Opportunities Corp.',
    name: 'Digital Transformation Opportunities Corp.',
    symbol: 'DTOCW',
  },
  {
    value: 'FRWAW',
    label: 'FRWAW,  PWP Forward Acquisition Corp. I',
    name: 'PWP Forward Acquisition Corp. I',
    symbol: 'FRWAW',
  },
  {
    value: 'AGGRW',
    label: 'AGGRW,  Agile Growth Corp.',
    name: 'Agile Growth Corp.',
    symbol: 'AGGRW',
  },
  {
    value: 'DTOC',
    label: 'DTOC,  Digital Transformation Opportunities Corp.',
    name: 'Digital Transformation Opportunities Corp.',
    symbol: 'DTOC',
  },
  {
    value: 'FRONW',
    label: 'FRONW,  Frontier Acquisition Corp.',
    name: 'Frontier Acquisition Corp.',
    symbol: 'FRONW',
  },
  {
    value: 'MBTCR',
    label: 'MBTCR,  Nocturne Acquisition Corporation',
    name: 'Nocturne Acquisition Corporation',
    symbol: 'MBTCR',
  },
  {
    value: 'GTPAW',
    label: 'GTPAW,  Gores Technology Partners, Inc.',
    name: 'Gores Technology Partners, Inc.',
    symbol: 'GTPAW',
  },
  {
    value: 'SCAQW',
    label: 'SCAQW,  Stratim Cloud Acquisition Corp.',
    name: 'Stratim Cloud Acquisition Corp.',
    symbol: 'SCAQW',
  },
  {
    value: 'PTOCW',
    label: 'PTOCW,  Pine Technology Acquisition Corp.',
    name: 'Pine Technology Acquisition Corp.',
    symbol: 'PTOCW',
  },
  {
    value: 'LCAA',
    label: 'LCAA,  L Catterton Asia Acquisition Corp',
    name: 'L Catterton Asia Acquisition Corp',
    symbol: 'LCAA',
  },
  {
    value: 'MBTC',
    label: 'MBTC,  Nocturne Acquisition Corporation',
    name: 'Nocturne Acquisition Corporation',
    symbol: 'MBTC',
  },
  {
    value: 'CFFE',
    label: 'CFFE,  CF Acquisition Corp. VIII',
    name: 'CF Acquisition Corp. VIII',
    symbol: 'CFFE',
  },
  {
    value: 'GTPBW',
    label: 'GTPBW,  Gores Technology Partners II, Inc.',
    name: 'Gores Technology Partners II, Inc.',
    symbol: 'GTPBW',
  },
  {
    value: 'CFFEW',
    label: 'CFFEW,  CF Acquisition Corp. VIII',
    name: 'CF Acquisition Corp. VIII',
    symbol: 'CFFEW',
  },
  {
    value: 'FTPA',
    label: 'FTPA,  FTAC Parnassus Acquisition Corp.',
    name: 'FTAC Parnassus Acquisition Corp.',
    symbol: 'FTPA',
  },
  {
    value: 'FGBIP',
    label: 'FGBIP,  First Guaranty Bancshares, Inc.',
    name: 'First Guaranty Bancshares, Inc.',
    symbol: 'FGBIP',
  },
  {
    value: 'PTOC',
    label: 'PTOC,  Pine Technology Acquisition Corp.',
    name: 'Pine Technology Acquisition Corp.',
    symbol: 'PTOC',
  },
  {
    value: 'GTPA',
    label: 'GTPA,  Gores Technology Partners, Inc.',
    name: 'Gores Technology Partners, Inc.',
    symbol: 'GTPA',
  },
  {
    value: 'LCAAW',
    label: 'LCAAW,  L Catterton Asia Acquisition Corp.',
    name: 'L Catterton Asia Acquisition Corp.',
    symbol: 'LCAAW',
  },
  {
    value: 'SCAQ',
    label: 'SCAQ,  Stratim Cloud Acquisition Corp.',
    name: 'Stratim Cloud Acquisition Corp.',
    symbol: 'SCAQ',
  },
  {
    value: 'FTPAW',
    label: 'FTPAW,  FTAC Parnassus Acquisition Corp.',
    name: 'FTAC Parnassus Acquisition Corp.',
    symbol: 'FTPAW',
  },
  {
    value: 'FRON',
    label: 'FRON,  Frontier Acquisition Corp.',
    name: 'Frontier Acquisition Corp.',
    symbol: 'FRON',
  },
  {
    value: 'GTPB',
    label: 'GTPB,  Gores Technology Partners II, Inc.',
    name: 'Gores Technology Partners II, Inc.',
    symbol: 'GTPB',
  },
  {
    value: 'SPKB',
    label: 'SPKB,  Silver Spike Acquisition Corp II',
    name: 'Silver Spike Acquisition Corp II',
    symbol: 'SPKB',
  },
  {
    value: 'MNMD',
    label: 'MNMD,  Mind Medicine (MindMed) Inc.',
    name: 'Mind Medicine (MindMed) Inc.',
    symbol: 'MNMD',
  },
  {
    value: 'SPKBW',
    label: 'SPKBW,  Silver Spike Acquisition Corp II',
    name: 'Silver Spike Acquisition Corp II',
    symbol: 'SPKBW',
  },
  {
    value: 'GTX',
    label: 'GTX,  Garrett Motion Inc.',
    name: 'Garrett Motion Inc.',
    symbol: 'GTX',
  },
  {
    value: 'ETHE',
    label: 'ETHE,  Grayscale Ethereum Trust (ETH)',
    name: 'Grayscale Ethereum Trust (ETH)',
    symbol: 'ETHE',
  },
  {
    value: 'FYBR',
    label: 'FYBR,  Frontier Communications Parent, Inc.',
    name: 'Frontier Communications Parent, Inc.',
    symbol: 'FYBR',
  },
  {
    value: 'VLATU',
    label: 'VLATU,  Valor Latitude Acquisition Corp.',
    name: 'Valor Latitude Acquisition Corp.',
    symbol: 'VLATU',
  },
  {
    value: 'IFCM3.SA',
    label: 'IFCM3.SA,  Infracommerce CXaaS S.A.',
    name: 'Infracommerce CXaaS S.A.',
    symbol: 'IFCM3.SA',
  },
  {
    value: 'EST.L',
    label: 'EST.L,  East Star Resources Plc',
    name: 'East Star Resources Plc',
    symbol: 'EST.L',
  },
  {
    value: 'HNST',
    label: 'HNST,  The Honest Company, Inc.',
    name: 'The Honest Company, Inc.',
    symbol: 'HNST',
  },
  {
    value: 'FSBC',
    label: 'FSBC,  Five Star Bancorp',
    name: 'Five Star Bancorp',
    symbol: 'FSBC',
  },
  {
    value: 'MAQCU',
    label: 'MAQCU,  Maquia Capital Acquisition Corporation',
    name: 'Maquia Capital Acquisition Corporation',
    symbol: 'MAQCU',
  },
  {
    value: 'RECV3.SA',
    label: 'RECV3.SA,  Petroreconcavo S.A.',
    name: 'Petroreconcavo S.A.',
    symbol: 'RECV3.SA',
  },
  {
    value: 'BWMN',
    label: 'BWMN,  Bowman Consulting Group Ltd.',
    name: 'Bowman Consulting Group Ltd.',
    symbol: 'BWMN',
  },
  {
    value: 'BRIVU',
    label: 'BRIVU,  B. Riley Principal 250 Merger Corp.',
    name: 'B. Riley Principal 250 Merger Corp.',
    symbol: 'BRIVU',
  },
  {
    value: 'LVRA',
    label: 'LVRA,  Levere Holdings Corp.',
    name: 'Levere Holdings Corp.',
    symbol: 'LVRA',
  },
  {
    value: 'LVRAW',
    label: 'LVRAW,  Levere Holdings Corp.',
    name: 'Levere Holdings Corp.',
    symbol: 'LVRAW',
  },
  {
    value: 'ORIAU',
    label: 'ORIAU,  Orion Biotech Opportunities Corp.',
    name: 'Orion Biotech Opportunities Corp.',
    symbol: 'ORIAU',
  },
  {
    value: 'FLGC',
    label: 'FLGC,  Flora Growth Corp.',
    name: 'Flora Growth Corp.',
    symbol: 'FLGC',
  },
  {
    value: 'GGMC',
    label: 'GGMC,  Glenfarne Merger Corp.',
    name: 'Glenfarne Merger Corp.',
    symbol: 'GGMC',
  },
  {
    value: 'GGMCW',
    label: 'GGMCW,  Glenfarne Merger Corp.',
    name: 'Glenfarne Merger Corp.',
    symbol: 'GGMCW',
  },
  {
    value: 'BYTS',
    label: 'BYTS,  BYTE Acquisition Corp.',
    name: 'BYTE Acquisition Corp.',
    symbol: 'BYTS',
  },
  {
    value: 'WALD',
    label: 'WALD,  Waldencast plc',
    name: 'Waldencast plc',
    symbol: 'WALD',
  },
  {
    value: 'GXII',
    label: 'GXII,  GX Acquisition Corp. II',
    name: 'GX Acquisition Corp. II',
    symbol: 'GXII',
  },
  {
    value: 'REVHW',
    label: 'REVHW,  Revolution Healthcare Acquisition Corp.',
    name: 'Revolution Healthcare Acquisition Corp.',
    symbol: 'REVHW',
  },
  {
    value: 'LDHAW',
    label: 'LDHAW,  LDH Growth Corp I',
    name: 'LDH Growth Corp I',
    symbol: 'LDHAW',
  },
  {
    value: 'WALDW',
    label: 'WALDW,  Waldencast plc',
    name: 'Waldencast plc',
    symbol: 'WALDW',
  },
  {
    value: 'REVH',
    label: 'REVH,  Revolution Healthcare Acquisition Corp.',
    name: 'Revolution Healthcare Acquisition Corp.',
    symbol: 'REVH',
  },
  {
    value: 'GXIIW',
    label: 'GXIIW,  GX Acquisition Corp. II',
    name: 'GX Acquisition Corp. II',
    symbol: 'GXIIW',
  },
  {
    value: 'BYTSW',
    label: 'BYTSW,  BYTE Acquisition Corp.',
    name: 'BYTE Acquisition Corp.',
    symbol: 'BYTSW',
  },
  {
    value: 'LDHA',
    label: 'LDHA,  LDH Growth Corp I',
    name: 'LDH Growth Corp I',
    symbol: 'LDHA',
  },
  {
    value: 'GLBE',
    label: 'GLBE,  Global-e Online Ltd.',
    name: 'Global-e Online Ltd.',
    symbol: 'GLBE',
  },
  {
    value: 'SHQAU',
    label: 'SHQAU,  Shelter Acquisition Corporation I',
    name: 'Shelter Acquisition Corporation I',
    symbol: 'SHQAU',
  },
  {
    value: 'LEV',
    label: 'LEV,  The Lion Electric Company',
    name: 'The Lion Electric Company',
    symbol: 'LEV',
  },
  {
    value: 'IPW',
    label: 'IPW,  iPower Inc.',
    name: 'iPower Inc.',
    symbol: 'IPW',
  },
  {
    value: 'SMWB',
    label: 'SMWB,  Similarweb Ltd.',
    name: 'Similarweb Ltd.',
    symbol: 'SMWB',
  },
  {
    value: 'EBACW',
    label: 'EBACW,  European Biotech Acquisition Corp.',
    name: 'European Biotech Acquisition Corp.',
    symbol: 'EBACW',
  },
  {
    value: 'EBAC',
    label: 'EBAC,  European Biotech Acquisition Corp.',
    name: 'European Biotech Acquisition Corp.',
    symbol: 'EBAC',
  },
  {
    value: 'OTECU',
    label: 'OTECU,  OceanTech Acquisitions I Corp.',
    name: 'OceanTech Acquisitions I Corp.',
    symbol: 'OTECU',
  },
  {
    value: 'CPARU',
    label: 'CPARU,  Catalyst Partners Acquisition Corp.',
    name: 'Catalyst Partners Acquisition Corp.',
    symbol: 'CPARU',
  },
  {
    value: 'FMIV',
    label: 'FMIV,  Forum Merger IV Corporation',
    name: 'Forum Merger IV Corporation',
    symbol: 'FMIV',
  },
  {
    value: 'ACT',
    label: 'ACT,  Enact Holdings, Inc.',
    name: 'Enact Holdings, Inc.',
    symbol: 'ACT',
  },
  {
    value: 'GLHA',
    label: 'GLHA,  Glass Houses Acquisition Corp.',
    name: 'Glass Houses Acquisition Corp.',
    symbol: 'GLHA',
  },
  {
    value: 'ZENV',
    label: 'ZENV,  Zenvia Inc.',
    name: 'Zenvia Inc.',
    symbol: 'ZENV',
  },
  {
    value: 'VERA',
    label: 'VERA,  Vera Therapeutics, Inc.',
    name: 'Vera Therapeutics, Inc.',
    symbol: 'VERA',
  },
  {
    value: 'EJH',
    label: 'EJH,  E-Home Household Service Holdings Limited',
    name: 'E-Home Household Service Holdings Limited',
    symbol: 'EJH',
  },
  {
    value: 'MCAEU',
    label: 'MCAEU,  Mountain Crest Acquisition Corp. III',
    name: 'Mountain Crest Acquisition Corp. III',
    symbol: 'MCAEU',
  },
  {
    value: 'SPKAU',
    label: 'SPKAU,  SPK Acquisition Corp.',
    name: 'SPK Acquisition Corp.',
    symbol: 'SPKAU',
  },
  {
    value: 'MPLN',
    label: 'MPLN,  MultiPlan Corporation',
    name: 'MultiPlan Corporation',
    symbol: 'MPLN',
  },
  {
    value: 'FMIVW',
    label: 'FMIVW,  Forum Merger IV Corporation',
    name: 'Forum Merger IV Corporation',
    symbol: 'FMIVW',
  },
  {
    value: 'LEGAW',
    label: 'LEGAW,  Lead Edge Growth Opportunities, Ltd',
    name: 'Lead Edge Growth Opportunities, Ltd',
    symbol: 'LEGAW',
  },
  {
    value: 'LEGA',
    label: 'LEGA,  Lead Edge Growth Opportunities, Ltd',
    name: 'Lead Edge Growth Opportunities, Ltd',
    symbol: 'LEGA',
  },
  {
    value: 'TBSAW',
    label: 'TBSAW,  TB SA Acquisition Corp',
    name: 'TB SA Acquisition Corp',
    symbol: 'TBSAW',
  },
  {
    value: 'TBSA',
    label: 'TBSA,  TB SA Acquisition Corp',
    name: 'TB SA Acquisition Corp',
    symbol: 'TBSA',
  },
  {
    value: 'GLHAW',
    label: 'GLHAW,  Glass Houses Acquisition Corp.',
    name: 'Glass Houses Acquisition Corp.',
    symbol: 'GLHAW',
  },
  {
    value: 'AWE.L',
    label: 'AWE.L,  Alphawave IP Group plc',
    name: 'Alphawave IP Group plc',
    symbol: 'AWE.L',
  },
  {
    value: 'DISA',
    label: 'DISA,  Disruptive Acquisition Corporation I',
    name: 'Disruptive Acquisition Corporation I',
    symbol: 'DISA',
  },
  {
    value: 'CRZN',
    label: 'CRZN,  Corazon Capital V838 Monoceros Corp',
    name: 'Corazon Capital V838 Monoceros Corp',
    symbol: 'CRZN',
  },
  {
    value: 'DTSTW',
    label: 'DTSTW,  Data Storage Corporation',
    name: 'Data Storage Corporation',
    symbol: 'DTSTW',
  },
  {
    value: 'CRZNW',
    label: 'CRZNW,  Corazon Capital V838 Monoceros Corp',
    name: 'Corazon Capital V838 Monoceros Corp',
    symbol: 'CRZNW',
  },
  {
    value: 'DISAW',
    label: 'DISAW,  Disruptive Acquisition Corporation I',
    name: 'Disruptive Acquisition Corporation I',
    symbol: 'DISAW',
  },
  {
    value: 'SVFD',
    label: 'SVFD,  Save Foods, Inc.',
    name: 'Save Foods, Inc.',
    symbol: 'SVFD',
  },
  {
    value: 'DBGIW',
    label: 'DBGIW,  Digital Brands Group, Inc.',
    name: 'Digital Brands Group, Inc.',
    symbol: 'DBGIW',
  },
  {
    value: 'DBGI',
    label: 'DBGI,  Digital Brands Group, Inc.',
    name: 'Digital Brands Group, Inc.',
    symbol: 'DBGI',
  },
  {
    value: 'MSDAW',
    label: 'MSDAW,  MSD Acquisition Corp.',
    name: 'MSD Acquisition Corp.',
    symbol: 'MSDAW',
  },
  {
    value: 'MSDA',
    label: 'MSDA,  MSD Acquisition Corp.',
    name: 'MSD Acquisition Corp.',
    symbol: 'MSDA',
  },
  {
    value: 'ARKX',
    label: 'ARKX,  ARK Space Exploration & Innovation ETF',
    name: 'ARK Space Exploration & Innovation ETF',
    symbol: 'ARKX',
  },
  {
    value: 'SKYAU',
    label: 'SKYAU,  Skydeck Acquisition Corp.',
    name: 'Skydeck Acquisition Corp.',
    symbol: 'SKYAU',
  },
  {
    value: 'GIWWU',
    label: 'GIWWU,  GigInternational1, Inc.',
    name: 'GigInternational1, Inc.',
    symbol: 'GIWWU',
  },
  {
    value: 'FWAC',
    label: 'FWAC,  Fifth Wall Acquisition Corp. III',
    name: 'Fifth Wall Acquisition Corp. III',
    symbol: 'FWAC',
  },
  {
    value: 'RAMMU',
    label: 'RAMMU,  Aries I Acquisition Corporation',
    name: 'Aries I Acquisition Corporation',
    symbol: 'RAMMU',
  },
  {
    value: 'SQSP',
    label: 'SQSP,  Squarespace, Inc.',
    name: 'Squarespace, Inc.',
    symbol: 'SQSP',
  },
  {
    value: 'AFAQW',
    label: 'AFAQW,  AF Acquisition Corp.',
    name: 'AF Acquisition Corp.',
    symbol: 'AFAQW',
  },
  {
    value: 'AFAQ',
    label: 'AFAQ,  AF Acquisition Corp.',
    name: 'AF Acquisition Corp.',
    symbol: 'AFAQ',
  },
  {
    value: 'VMEO',
    label: 'VMEO,  Vimeo, Inc.',
    name: 'Vimeo, Inc.',
    symbol: 'VMEO',
  },
  {
    value: '9982.HK',
    label: '9982.HK,  Central China Management Company Limited',
    name: 'Central China Management Company Limited',
    symbol: '9982.HK',
  },
  {
    value: 'HOPE.JK',
    label: 'HOPE.JK,  PT Harapan Duta Pertiwi Tbk',
    name: 'PT Harapan Duta Pertiwi Tbk',
    symbol: 'HOPE.JK',
  },
  {
    value: 'JZXN',
    label: 'JZXN,  Jiuzi Holdings, Inc.',
    name: 'Jiuzi Holdings, Inc.',
    symbol: 'JZXN',
  },
  {
    value: 'TWCBU',
    label: 'TWCBU,  Bilander Acquisition Corp.',
    name: 'Bilander Acquisition Corp.',
    symbol: 'TWCBU',
  },
  {
    value: 'ECIT.OL',
    label: 'ECIT.OL,  ECIT AS',
    name: 'ECIT AS',
    symbol: 'ECIT.OL',
  },
  {
    value: 'QQQA',
    label: 'QQQA,  ProShares Nasdaq-100 Dorsey Wright Momentum ETF',
    name: 'ProShares Nasdaq-100 Dorsey Wright Momentum ETF',
    symbol: 'QQQA',
  },
  {
    value: 'ALBIZ.PA',
    label: 'ALBIZ.PA,  Obiz Concept SAS',
    name: 'Obiz Concept SAS',
    symbol: 'ALBIZ.PA',
  },
  {
    value: 'OTLY',
    label: 'OTLY,  Oatly Group AB',
    name: 'Oatly Group AB',
    symbol: 'OTLY',
  },
  {
    value: '2618.HK',
    label: '2618.HK,  JD Logistics, Inc.',
    name: 'JD Logistics, Inc.',
    symbol: '2618.HK',
  },
  {
    value: 'ACBAW',
    label: 'ACBAW,  Ace Global Business Acquisition Limited',
    name: 'Ace Global Business Acquisition Limited',
    symbol: 'ACBAW',
  },
  {
    value: 'ACBA',
    label: 'ACBA,  Ace Global Business Acquisition Limited',
    name: 'Ace Global Business Acquisition Limited',
    symbol: 'ACBA',
  },
  {
    value: 'PWI.TO',
    label: 'PWI.TO,  Sustainable Power & Infrastructure Split Corp.',
    name: 'Sustainable Power & Infrastructure Split Corp.',
    symbol: 'PWI.TO',
  },
  {
    value: 'WDGY.CN',
    label: 'WDGY.CN,  Wedgemount Resources Corp.',
    name: 'Wedgemount Resources Corp.',
    symbol: 'WDGY.CN',
  },
  {
    value: 'PCOR',
    label: 'PCOR,  Procore Technologies, Inc.',
    name: 'Procore Technologies, Inc.',
    symbol: 'PCOR',
  },
  {
    value: 'TFPM.TO',
    label: 'TFPM.TO,  Triple Flag Precious Metals Corp.',
    name: 'Triple Flag Precious Metals Corp.',
    symbol: 'TFPM.TO',
  },
  {
    value: 'ATS.VI',
    label:
      'ATS.VI,  AT & S Austria Technologie & Systemtechnik Aktiengesellschaft',
    name: 'AT & S Austria Technologie & Systemtechnik Aktiengesellschaft',
    symbol: 'ATS.VI',
  },
  {
    value: 'KTCG.VI',
    label: 'KTCG.VI,  Kapsch TrafficCom AG',
    name: 'Kapsch TrafficCom AG',
    symbol: 'KTCG.VI',
  },
  {
    value: 'MLTV.BR',
    label: 'MLTV.BR,  Choice NV',
    name: 'Choice NV',
    symbol: 'MLTV.BR',
  },
  {
    value: 'CASH3.SA',
    label: 'CASH3.SA,  Méliuz S.A.',
    name: 'Méliuz S.A.',
    symbol: 'CASH3.SA',
  },
  {
    value: 'DTCY3.SA',
    label: 'DTCY3.SA,  DTCOM - Direct to Company S.A.',
    name: 'DTCOM - Direct to Company S.A.',
    symbol: 'DTCY3.SA',
  },
  {
    value: 'LWSA3.SA',
    label: 'LWSA3.SA,  Locaweb Serviços de Internet S.A.',
    name: 'Locaweb Serviços de Internet S.A.',
    symbol: 'LWSA3.SA',
  },
  {
    value: 'MBLY3.SA',
    label: 'MBLY3.SA,  Mobly S.A.',
    name: 'Mobly S.A.',
    symbol: 'MBLY3.SA',
  },
  {
    value: 'NGRD3.SA',
    label: 'NGRD3.SA,  Neogrid Participações S.A.',
    name: 'Neogrid Participações S.A.',
    symbol: 'NGRD3.SA',
  },
  {
    value: 'POSI3.SA',
    label: 'POSI3.SA,  Positivo Tecnologia S.A.',
    name: 'Positivo Tecnologia S.A.',
    symbol: 'POSI3.SA',
  },
  {
    value: 'SQIA3.SA',
    label: 'SQIA3.SA,  Sinqia S.A.',
    name: 'Sinqia S.A.',
    symbol: 'SQIA3.SA',
  },
  {
    value: 'CTS.TO',
    label: 'CTS.TO,  Converge Technology Solutions Corp.',
    name: 'Converge Technology Solutions Corp.',
    symbol: 'CTS.TO',
  },
  {
    value: 'MLBMD.PA',
    label: 'MLBMD.PA,  beaconsmind AG',
    name: 'beaconsmind AG',
    symbol: 'MLBMD.PA',
  },
  {
    value: 'SONDA.SN',
    label: 'SONDA.SN,  Sonda S.A.',
    name: 'Sonda S.A.',
    symbol: 'SONDA.SN',
  },
  {
    value: '002174.SZ',
    label: '002174.SZ,  YOOZOO Interactive Co., Ltd.',
    name: 'YOOZOO Interactive Co., Ltd.',
    symbol: '002174.SZ',
  },
  {
    value: '002230.SZ',
    label: '002230.SZ,  iFLYTEK CO.,LTD',
    name: 'iFLYTEK CO.,LTD',
    symbol: '002230.SZ',
  },
  {
    value: '300359.SZ',
    label: '300359.SZ,  Qtone Education Group (Guangdong) Co.,Ltd',
    name: 'Qtone Education Group (Guangdong) Co.,Ltd',
    symbol: '300359.SZ',
  },
  {
    value: '603486.SS',
    label: '603486.SS,  Ecovacs Robotics Co., Ltd.',
    name: 'Ecovacs Robotics Co., Ltd.',
    symbol: '603486.SS',
  },
  {
    value: '688561.SS',
    label: '688561.SS,  Qi An Xin Technology Group Inc.',
    name: 'Qi An Xin Technology Group Inc.',
    symbol: '688561.SS',
  },
  {
    value: 'MSGL.HM',
    label: 'MSGL.HM,  msg life ag',
    name: 'msg life ag',
    symbol: 'MSGL.HM',
  },
  {
    value: 'QBY.DE',
    label: 'QBY.DE,  q.beyond AG',
    name: 'q.beyond AG',
    symbol: 'QBY.DE',
  },
  {
    value: 'NB2.F',
    label: 'NB2.F,  Northern Data AG',
    name: 'Northern Data AG',
    symbol: 'NB2.F',
  },
  {
    value: 'AG1.DE',
    label: 'AG1.DE,  AUTO1 Group SE',
    name: 'AUTO1 Group SE',
    symbol: 'AG1.DE',
  },
  {
    value: 'WIRTEK.CO',
    label: 'WIRTEK.CO,  Wirtek A/S',
    name: 'Wirtek A/S',
    symbol: 'WIRTEK.CO',
  },
  {
    value: 'MONSO.CO',
    label: 'MONSO.CO,  Monsenso A/S',
    name: 'Monsenso A/S',
    symbol: 'MONSO.CO',
  },
  {
    value: 'PENNEO.CO',
    label: 'PENNEO.CO,  Penneo A/S',
    name: 'Penneo A/S',
    symbol: 'PENNEO.CO',
  },
  {
    value: 'MDUNDO.CO',
    label: 'MDUNDO.CO,  Mdundo.com A/S',
    name: 'Mdundo.com A/S',
    symbol: 'MDUNDO.CO',
  },
  {
    value: 'DATA.CO',
    label: 'DATA.CO,  Dataproces Group A/S',
    name: 'Dataproces Group A/S',
    symbol: 'DATA.CO',
  },
  {
    value: 'ACT.CO',
    label: 'ACT.CO,  DecideAct A/S',
    name: 'DecideAct A/S',
    symbol: 'ACT.CO',
  },
  {
    value: 'MAKS.MC',
    label: 'MAKS.MC,  Making Science Group, S.A.',
    name: 'Making Science Group, S.A.',
    symbol: 'MAKS.MC',
  },
  {
    value: 'DIGIA.HE',
    label: 'DIGIA.HE,  Digia Oyj',
    name: 'Digia Oyj',
    symbol: 'DIGIA.HE',
  },
  {
    value: 'BAS1V.HE',
    label: 'BAS1V.HE,  Basware Oyj',
    name: 'Basware Oyj',
    symbol: 'BAS1V.HE',
  },
  {
    value: 'VERK.HE',
    label: 'VERK.HE,  Verkkokauppa.com Oyj',
    name: 'Verkkokauppa.com Oyj',
    symbol: 'VERK.HE',
  },
  {
    value: 'MODE.L',
    label: 'MODE.L,  Mode Global Holdings PLC',
    name: 'Mode Global Holdings PLC',
    symbol: 'MODE.L',
  },
  {
    value: 'CLX.L',
    label: 'CLX.L,  Calnex Solutions plc',
    name: 'Calnex Solutions plc',
    symbol: 'CLX.L',
  },
  {
    value: 'KOO.L',
    label: 'KOO.L,  Kooth plc',
    name: 'Kooth plc',
    symbol: 'KOO.L',
  },
  {
    value: 'THG.L',
    label: 'THG.L,  THG Plc',
    name: 'THG Plc',
    symbol: 'THG.L',
  },
  {
    value: 'GILD.L',
    label: 'GILD.L,  Guild Esports Plc',
    name: 'Guild Esports Plc',
    symbol: 'GILD.L',
  },
  {
    value: 'FNX.L',
    label: 'FNX.L,  Fonix Mobile plc',
    name: 'Fonix Mobile plc',
    symbol: 'FNX.L',
  },
  {
    value: 'PBEE.L',
    label: 'PBEE.L,  PensionBee Group plc',
    name: 'PensionBee Group plc',
    symbol: 'PBEE.L',
  },
  {
    value: 'INLOT.AT',
    label: 'INLOT.AT,  Intralot S.A. Integrated Lottery Systems and Services',
    name: 'Intralot S.A. Integrated Lottery Systems and Services',
    symbol: 'INLOT.AT',
  },
  {
    value: 'PROF.AT',
    label: 'PROF.AT,  Profile Systems & Software SA',
    name: 'Profile Systems & Software SA',
    symbol: 'PROF.AT',
  },
  {
    value: 'ENTER.AT',
    label: 'ENTER.AT,  Entersoft S.A.',
    name: 'Entersoft S.A.',
    symbol: 'ENTER.AT',
  },
  {
    value: 'GPI.MI',
    label: 'GPI.MI,  GPI S.p.A.',
    name: 'GPI S.p.A.',
    symbol: 'GPI.MI',
  },
  {
    value: 'DGV.MI',
    label: 'DGV.MI,  Digital Value S.p.A.',
    name: 'Digital Value S.p.A.',
    symbol: 'DGV.MI',
  },
  {
    value: '6656.T',
    label: '6656.T,  inspec Inc.',
    name: 'inspec Inc.',
    symbol: '6656.T',
  },
  {
    value: '4385.T',
    label: '4385.T,  Mercari, Inc.',
    name: 'Mercari, Inc.',
    symbol: '4385.T',
  },
  {
    value: '035420.KS',
    label: '035420.KS,  NAVER Corporation',
    name: 'NAVER Corporation',
    symbol: '035420.KS',
  },
  {
    value: '053800.KQ',
    label: '053800.KQ,  AhnLab, Inc.',
    name: 'AhnLab, Inc.',
    symbol: '053800.KQ',
  },
  {
    value: 'M8G.DE',
    label: 'M8G.DE,  Media and Games Invest SE',
    name: 'Media and Games Invest SE',
    symbol: 'M8G.DE',
  },
  {
    value: 'OTEC.OL',
    label: 'OTEC.OL,  Otello Corporation ASA',
    name: 'Otello Corporation ASA',
    symbol: 'OTEC.OL',
  },
  {
    value: 'PPH.NZ',
    label: 'PPH.NZ,  Pushpay Holdings Limited',
    name: 'Pushpay Holdings Limited',
    symbol: 'PPH.NZ',
  },
  {
    value: 'TOBII.ST',
    label: 'TOBII.ST,  Tobii AB (publ)',
    name: 'Tobii AB (publ)',
    symbol: 'TOBII.ST',
  },
  {
    value: 'BOOZT.ST',
    label: 'BOOZT.ST,  Boozt AB (publ)',
    name: 'Boozt AB (publ)',
    symbol: 'BOOZT.ST',
  },
  {
    value: 'BHG.ST',
    label: 'BHG.ST,  BHG Group AB (publ)',
    name: 'BHG Group AB (publ)',
    symbol: 'BHG.ST',
  },
  {
    value: 'NCAB.ST',
    label: 'NCAB.ST,  NCAB Group AB (publ)',
    name: 'NCAB Group AB (publ)',
    symbol: 'NCAB.ST',
  },
  {
    value: 'BETS-B.ST',
    label: 'BETS-B.ST,  Betsson AB',
    name: 'Betsson AB',
    symbol: 'BETS-B.ST',
  },
  {
    value: 'NELLY.ST',
    label: 'NELLY.ST,  Nelly Group AB (publ)',
    name: 'Nelly Group AB (publ)',
    symbol: 'NELLY.ST',
  },
  {
    value: 'ARENA.IS',
    label: 'ARENA.IS,  Arena Bilgisayar Sanayi ve Ticaret A.S.',
    name: 'Arena Bilgisayar Sanayi ve Ticaret A.S.',
    symbol: 'ARENA.IS',
  },
  {
    value: 'ARMDA.IS',
    label: 'ARMDA.IS,  Armada Bilgisayar Sistemleri Sanayi ve Ticaret A.S.',
    name: 'Armada Bilgisayar Sistemleri Sanayi ve Ticaret A.S.',
    symbol: 'ARMDA.IS',
  },
  {
    value: 'INDES.IS',
    label:
      'INDES.IS,  Indeks Bilgisayar Sistemleri Mühendislik Sanayi ve Ticaret Anonim Sirketi',
    name: 'Indeks Bilgisayar Sistemleri Mühendislik Sanayi ve Ticaret Anonim Sirketi',
    symbol: 'INDES.IS',
  },
  {
    value: '2332.TW',
    label: '2332.TW,  D-Link Corporation',
    name: 'D-Link Corporation',
    symbol: '2332.TW',
  },
  {
    value: '2345.TW',
    label: '2345.TW,  Accton Technology Corporation',
    name: 'Accton Technology Corporation',
    symbol: '2345.TW',
  },
  {
    value: '3138.TW',
    label: '3138.TW,  Auden Techno Corp.',
    name: 'Auden Techno Corp.',
    symbol: '3138.TW',
  },
  {
    value: '3714.TW',
    label: '3714.TW,  ENNOSTAR Inc.',
    name: 'ENNOSTAR Inc.',
    symbol: '3714.TW',
  },
  {
    value: '6438.TW',
    label: '6438.TW,  Symtek Automation Asia Co., Ltd.',
    name: 'Symtek Automation Asia Co., Ltd.',
    symbol: '6438.TW',
  },
  {
    value: '6515.TW',
    label: '6515.TW,  WinWay Technology Co., Ltd.',
    name: 'WinWay Technology Co., Ltd.',
    symbol: '6515.TW',
  },
  {
    value: '6756.TW',
    label: '6756.TW,  VIA Labs, Inc.',
    name: 'VIA Labs, Inc.',
    symbol: '6756.TW',
  },
  {
    value: 'CURI',
    label: 'CURI,  CuriosityStream Inc.',
    name: 'CuriosityStream Inc.',
    symbol: 'CURI',
  },
  {
    value: 'GDSN.MX',
    label: 'GDSN.MX,  GDS Holdings Limited',
    name: 'GDS Holdings Limited',
    symbol: 'GDSN.MX',
  },
  {
    value: 'HY9H.F',
    label: 'HY9H.F,  SK hynix Inc.',
    name: 'SK hynix Inc.',
    symbol: 'HY9H.F',
  },
  {
    value: '217A.F',
    label: '217A.F,  VNET Group, Inc.',
    name: 'VNET Group, Inc.',
    symbol: '217A.F',
  },
  {
    value: 'NLTA.V',
    label: 'NLTA.V,  Enlighta Inc.',
    name: 'Enlighta Inc.',
    symbol: 'NLTA.V',
  },
  {
    value: 'TSF.CN',
    label: 'TSF.CN,  TraceSafe Inc.',
    name: 'TraceSafe Inc.',
    symbol: 'TSF.CN',
  },
  {
    value: 'MST.JO',
    label: 'MST.JO,  Mustek Limited',
    name: 'Mustek Limited',
    symbol: 'MST.JO',
  },
  {
    value: 'AEL.JO',
    label: 'AEL.JO,  Altron Limited',
    name: 'Altron Limited',
    symbol: 'AEL.JO',
  },
  {
    value: 'AYO.JO',
    label: 'AYO.JO,  AYO Technology Solutions Limited',
    name: 'AYO Technology Solutions Limited',
    symbol: 'AYO.JO',
  },
  {
    value: 'S30.PA',
    label: 'S30.PA,  Solutions 30 SE',
    name: 'Solutions 30 SE',
    symbol: 'S30.PA',
  },
  {
    value: 'TUEM',
    label: 'TUEM,  Tuesday Morning Corporation',
    name: 'Tuesday Morning Corporation',
    symbol: 'TUEM',
  },
  {
    value: 'SPBC',
    label: 'SPBC,  Simplify U.S. Equity PLUS GBTC ETF',
    name: 'Simplify U.S. Equity PLUS GBTC ETF',
    symbol: 'SPBC',
  },
  {
    value: 'PAY',
    label: 'PAY,  Paymentus Holdings, Inc.',
    name: 'Paymentus Holdings, Inc.',
    symbol: 'PAY',
  },
  {
    value: 'ZIP',
    label: 'ZIP,  ZipRecruiter, Inc.',
    name: 'ZipRecruiter, Inc.',
    symbol: 'ZIP',
  },
  {
    value: 'RSI',
    label: 'RSI,  Rush Street Interactive, Inc.',
    name: 'Rush Street Interactive, Inc.',
    symbol: 'RSI',
  },
  {
    value: 'NMTC',
    label: 'NMTC,  NeuroOne Medical Technologies Corporation',
    name: 'NeuroOne Medical Technologies Corporation',
    symbol: 'NMTC',
  },
  {
    value: 'FNMA',
    label: 'FNMA,  Federal National Mortgage Association',
    name: 'Federal National Mortgage Association',
    symbol: 'FNMA',
  },
  {
    value: 'DOTZ3.SA',
    label: 'DOTZ3.SA,  Dotz S.A.',
    name: 'Dotz S.A.',
    symbol: 'DOTZ3.SA',
  },
  {
    value: 'TIOAW',
    label: 'TIOAW,  Tio Tech A',
    name: 'Tio Tech A',
    symbol: 'TIOAW',
  },
  {
    value: 'TIOA',
    label: 'TIOA,  Tio Tech A',
    name: 'Tio Tech A',
    symbol: 'TIOA',
  },
  {
    value: 'SOFI',
    label: 'SOFI,  SoFi Technologies, Inc.',
    name: 'SoFi Technologies, Inc.',
    symbol: 'SOFI',
  },
  {
    value: 'PRR.CN',
    label: 'PRR.CN,  Prospect Ridge Resources Corp.',
    name: 'Prospect Ridge Resources Corp.',
    symbol: 'PRR.CN',
  },
  {
    value: 'DLO',
    label: 'DLO,  DLocal Limited',
    name: 'DLocal Limited',
    symbol: 'DLO',
  },
  {
    value: 'OWN.V',
    label: 'OWN.V,  Rex Resources Corp.',
    name: 'Rex Resources Corp.',
    symbol: 'OWN.V',
  },
  {
    value: 'MPACR',
    label: 'MPACR,  Model Performance Acquisition Corp.',
    name: 'Model Performance Acquisition Corp.',
    symbol: 'MPACR',
  },
  {
    value: 'HCNEU',
    label: 'HCNEU,  JAWS Hurricane Acquisition Corporation',
    name: 'JAWS Hurricane Acquisition Corporation',
    symbol: 'HCNEU',
  },
  {
    value: 'TTE.PA',
    label: 'TTE.PA,  TotalEnergies SE',
    name: 'TotalEnergies SE',
    symbol: 'TTE.PA',
  },
  {
    value: 'OGN',
    label: 'OGN,  Organon & Co.',
    name: 'Organon & Co.',
    symbol: 'OGN',
  },
  {
    value: '0115.HK',
    label: '0115.HK,  Grand Field Group Holdings Limited',
    name: 'Grand Field Group Holdings Limited',
    symbol: '0115.HK',
  },
  {
    value: '0224.HK',
    label: '0224.HK,  Pioneer Global Group Limited',
    name: 'Pioneer Global Group Limited',
    symbol: '0224.HK',
  },
  {
    value: '0252.HK',
    label: '0252.HK,  Southeast Asia Properties & Finance Limited',
    name: 'Southeast Asia Properties & Finance Limited',
    symbol: '0252.HK',
  },
  {
    value: '0274.HK',
    label: '0274.HK,  Renaissance Asia Silk Road Group Limited',
    name: 'Renaissance Asia Silk Road Group Limited',
    symbol: '0274.HK',
  },
  {
    value: '0379.HK',
    label: '0379.HK,  China Ever Grand Financial Leasing Group Co., Ltd.',
    name: 'China Ever Grand Financial Leasing Group Co., Ltd.',
    symbol: '0379.HK',
  },
  {
    value: '0381.HK',
    label: '0381.HK,  Kiu Hung International Holdings Limited',
    name: 'Kiu Hung International Holdings Limited',
    symbol: '0381.HK',
  },
  {
    value: '0396.HK',
    label: '0396.HK,  Hing Lee (HK) Holdings Limited',
    name: 'Hing Lee (HK) Holdings Limited',
    symbol: '0396.HK',
  },
  {
    value: '0438.HK',
    label: '0438.HK,  IRICO Group New Energy Company Limited',
    name: 'IRICO Group New Energy Company Limited',
    symbol: '0438.HK',
  },
  {
    value: '0458.HK',
    label: '0458.HK,  Tristate Holdings Limited',
    name: 'Tristate Holdings Limited',
    symbol: '0458.HK',
  },
  {
    value: '0529.HK',
    label: '0529.HK,  SiS International Holdings Limited',
    name: 'SiS International Holdings Limited',
    symbol: '0529.HK',
  },
  {
    value: '0676.HK',
    label: '0676.HK,  Pegasus International Holdings Limited',
    name: 'Pegasus International Holdings Limited',
    symbol: '0676.HK',
  },
  {
    value: '0770.HK',
    label:
      '0770.HK,  Shanghai International Shanghai Growth Investment Limited',
    name: 'Shanghai International Shanghai Growth Investment Limited',
    symbol: '0770.HK',
  },
  {
    value: '0794.HK',
    label: '0794.HK,  Come Sure Group (Holdings) Limited',
    name: 'Come Sure Group (Holdings) Limited',
    symbol: '0794.HK',
  },
  {
    value: '0834.HK',
    label: '0834.HK,  China Kangda Food Company Limited',
    name: 'China Kangda Food Company Limited',
    symbol: '0834.HK',
  },
  {
    value: '0859.HK',
    label: '0859.HK,  Zhongchang International Holdings Group Limited',
    name: 'Zhongchang International Holdings Group Limited',
    symbol: '0859.HK',
  },
  {
    value: '1000.HK',
    label: '1000.HK,  Beijing Media Corporation Limited',
    name: 'Beijing Media Corporation Limited',
    symbol: '1000.HK',
  },
  {
    value: '1033.HK',
    label: '1033.HK,  Sinopec Oilfield Service Corporation',
    name: 'Sinopec Oilfield Service Corporation',
    symbol: '1033.HK',
  },
  {
    value: '1053.HK',
    label: '1053.HK,  Chongqing Iron & Steel Company Limited',
    name: 'Chongqing Iron & Steel Company Limited',
    symbol: '1053.HK',
  },
  {
    value: '1055.HK',
    label: '1055.HK,  China Southern Airlines Company Limited',
    name: 'China Southern Airlines Company Limited',
    symbol: '1055.HK',
  },
  {
    value: '1057.HK',
    label: '1057.HK,  Zhejiang Shibao Company Limited',
    name: 'Zhejiang Shibao Company Limited',
    symbol: '1057.HK',
  },
  {
    value: '1065.HK',
    label:
      '1065.HK,  Tianjin Capital Environmental Protection Group Company Limited',
    name: 'Tianjin Capital Environmental Protection Group Company Limited',
    symbol: '1065.HK',
  },
  {
    value: '1071.HK',
    label: '1071.HK,  Huadian Power International Corporation Limited',
    name: 'Huadian Power International Corporation Limited',
    symbol: '1071.HK',
  },
  {
    value: '1072.HK',
    label: '1072.HK,  Dongfang Electric Corporation Limited',
    name: 'Dongfang Electric Corporation Limited',
    symbol: '1072.HK',
  },
  {
    value: '1108.HK',
    label: '1108.HK,  Luoyang Glass Company Limited',
    name: 'Luoyang Glass Company Limited',
    symbol: '1108.HK',
  },
  {
    value: '1138.HK',
    label: '1138.HK,  COSCO SHIPPING Energy Transportation Co., Ltd.',
    name: 'COSCO SHIPPING Energy Transportation Co., Ltd.',
    symbol: '1138.HK',
  },
  {
    value: '1146.HK',
    label: '1146.HK,  China Outfitters Holdings Limited',
    name: 'China Outfitters Holdings Limited',
    symbol: '1146.HK',
  },
  {
    value: '1153.HK',
    label: '1153.HK,  Jiayuan Services Holdings Limited',
    name: 'Jiayuan Services Holdings Limited',
    symbol: '1153.HK',
  },
  {
    value: '1157.HK',
    label: '1157.HK,  Zoomlion Heavy Industry Science and Technology Co., Ltd.',
    name: 'Zoomlion Heavy Industry Science and Technology Co., Ltd.',
    symbol: '1157.HK',
  },
  {
    value: '1160.HK',
    label: '1160.HK,  Goldstone Capital Group Limited',
    name: 'Goldstone Capital Group Limited',
    symbol: '1160.HK',
  },
  {
    value: '1163.HK',
    label: '1163.HK,  Adtiger Corporations Limited',
    name: 'Adtiger Corporations Limited',
    symbol: '1163.HK',
  },
  {
    value: '1167.HK',
    label: '1167.HK,  Jacobio Pharmaceuticals Group Co., Ltd.',
    name: 'Jacobio Pharmaceuticals Group Co., Ltd.',
    symbol: '1167.HK',
  },
  {
    value: '1171.HK',
    label: '1171.HK,  Yankuang Energy Group Company Limited',
    name: 'Yankuang Energy Group Company Limited',
    symbol: '1171.HK',
  },
  {
    value: '1179.HK',
    label: '1179.HK,  H World Group Limited',
    name: 'H World Group Limited',
    symbol: '1179.HK',
  },
  {
    value: '1186.HK',
    label: '1186.HK,  China Railway Construction Corporation Limited',
    name: 'China Railway Construction Corporation Limited',
    symbol: '1186.HK',
  },
  {
    value: '1189.HK',
    label: '1189.HK,  Greater Bay Area Dynamic Growth Holding Limited',
    name: 'Greater Bay Area Dynamic Growth Holding Limited',
    symbol: '1189.HK',
  },
  {
    value: '1213.HK',
    label: '1213.HK,  Mobicon Group Limited',
    name: 'Mobicon Group Limited',
    symbol: '1213.HK',
  },
  {
    value: '1226.HK',
    label: '1226.HK,  China Investment and Finance Group Limited',
    name: 'China Investment and Finance Group Limited',
    symbol: '1226.HK',
  },
  {
    value: '1330.HK',
    label: '1330.HK,  Dynagreen Environmental Protection Group Co., Ltd.',
    name: 'Dynagreen Environmental Protection Group Co., Ltd.',
    symbol: '1330.HK',
  },
  {
    value: '1339.HK',
    label: "1339.HK,  The People's Insurance Company (Group) of China Limited",
    name: "The People's Insurance Company (Group) of China Limited",
    symbol: '1339.HK',
  },
  {
    value: '1351.HK',
    label: '1351.HK,  Bright Future Technology Holdings Limited',
    name: 'Bright Future Technology Holdings Limited',
    symbol: '1351.HK',
  },
  {
    value: '1375.HK',
    label: '1375.HK,  Central China Securities Co., Ltd.',
    name: 'Central China Securities Co., Ltd.',
    symbol: '1375.HK',
  },
  {
    value: '1408.HK',
    label: '1408.HK,  Macau E&M Holding Limited',
    name: 'Macau E&M Holding Limited',
    symbol: '1408.HK',
  },
  {
    value: '1429.HK',
    label: '1429.HK,  Skymission Group Holdings Limited',
    name: 'Skymission Group Holdings Limited',
    symbol: '1429.HK',
  },
  {
    value: '1449.HK',
    label: '1449.HK,  Leader Education Limited',
    name: 'Leader Education Limited',
    symbol: '1449.HK',
  },
  {
    value: '1455.HK',
    label: '1455.HK,  Fourace Industries Group Holdings Limited',
    name: 'Fourace Industries Group Holdings Limited',
    symbol: '1455.HK',
  },
  {
    value: '1473.HK',
    label: '1473.HK,  Pangaea Connectivity Technology Limited',
    name: 'Pangaea Connectivity Technology Limited',
    symbol: '1473.HK',
  },
  {
    value: '1477.HK',
    label: '1477.HK,  Ocumension Therapeutics',
    name: 'Ocumension Therapeutics',
    symbol: '1477.HK',
  },
  {
    value: '1481.HK',
    label: '1481.HK,  Smart Globe Holdings Limited',
    name: 'Smart Globe Holdings Limited',
    symbol: '1481.HK',
  },
  {
    value: '1513.HK',
    label: '1513.HK,  Livzon Pharmaceutical Group Inc.',
    name: 'Livzon Pharmaceutical Group Inc.',
    symbol: '1513.HK',
  },
  {
    value: '1528.HK',
    label: '1528.HK,  Red Star Macalline Group Corporation Ltd.',
    name: 'Red Star Macalline Group Corporation Ltd.',
    symbol: '1528.HK',
  },
  {
    value: '1555.HK',
    label: '1555.HK,  MIE Holdings Corporation',
    name: 'MIE Holdings Corporation',
    symbol: '1555.HK',
  },
  {
    value: '1577.HK',
    label: '1577.HK,  Quanzhou Huixin Micro-credit Co., Ltd.',
    name: 'Quanzhou Huixin Micro-credit Co., Ltd.',
    symbol: '1577.HK',
  },
  {
    value: '1597.HK',
    label: '1597.HK,  China Nature Energy Technology Holdings Limited',
    name: 'China Nature Energy Technology Holdings Limited',
    symbol: '1597.HK',
  },
  {
    value: '1618.HK',
    label: '1618.HK,  Metallurgical Corporation of China Ltd.',
    name: 'Metallurgical Corporation of China Ltd.',
    symbol: '1618.HK',
  },
  {
    value: '1629.HK',
    label: '1629.HK,  Champion Alliance International Holdings Limited',
    name: 'Champion Alliance International Holdings Limited',
    symbol: '1629.HK',
  },
  {
    value: '1635.HK',
    label: '1635.HK,  Shanghai Dazhong Public Utilities(Group) Co.,Ltd.',
    name: 'Shanghai Dazhong Public Utilities(Group) Co.,Ltd.',
    symbol: '1635.HK',
  },
  {
    value: '1657.HK',
    label: '1657.HK,  SG Group Holdings Limited',
    name: 'SG Group Holdings Limited',
    symbol: '1657.HK',
  },
  {
    value: '1658.HK',
    label: '1658.HK,  Postal Savings Bank of China Co., Ltd.',
    name: 'Postal Savings Bank of China Co., Ltd.',
    symbol: '1658.HK',
  },
  {
    value: '1766.HK',
    label: '1766.HK,  CRRC Corporation Limited',
    name: 'CRRC Corporation Limited',
    symbol: '1766.HK',
  },
  {
    value: '1772.HK',
    label: '1772.HK,  Ganfeng Lithium Group Co., Ltd.',
    name: 'Ganfeng Lithium Group Co., Ltd.',
    symbol: '1772.HK',
  },
  {
    value: '1776.HK',
    label: '1776.HK,  GF Securities Co., Ltd.',
    name: 'GF Securities Co., Ltd.',
    symbol: '1776.HK',
  },
  {
    value: '1787.HK',
    label: '1787.HK,  Shandong Gold Mining Co., Ltd.',
    name: 'Shandong Gold Mining Co., Ltd.',
    symbol: '1787.HK',
  },
  {
    value: '1800.HK',
    label: '1800.HK,  China Communications Construction Company Limited',
    name: 'China Communications Construction Company Limited',
    symbol: '1800.HK',
  },
  {
    value: '1812.HK',
    label: '1812.HK,  Shandong Chenming Paper Holdings Limited',
    name: 'Shandong Chenming Paper Holdings Limited',
    symbol: '1812.HK',
  },
  {
    value: '1816.HK',
    label: '1816.HK,  CGN Power Co., Ltd.',
    name: 'CGN Power Co., Ltd.',
    symbol: '1816.HK',
  },
  {
    value: '1850.HK',
    label: '1850.HK,  WINDMILL Group Limited',
    name: 'WINDMILL Group Limited',
    symbol: '1850.HK',
  },
  {
    value: '1855.HK',
    label: '1855.HK,  ZONBONG LANDSCAPE Environmental Limited',
    name: 'ZONBONG LANDSCAPE Environmental Limited',
    symbol: '1855.HK',
  },
  {
    value: '1898.HK',
    label: '1898.HK,  China Coal Energy Company Limited',
    name: 'China Coal Energy Company Limited',
    symbol: '1898.HK',
  },
  {
    value: '1919.HK',
    label: '1919.HK,  COSCO SHIPPING Holdings Co., Ltd.',
    name: 'COSCO SHIPPING Holdings Co., Ltd.',
    symbol: '1919.HK',
  },
  {
    value: '1981.HK',
    label: '1981.HK,  Cathay Media and Education Group Inc.',
    name: 'Cathay Media and Education Group Inc.',
    symbol: '1981.HK',
  },
  {
    value: '1988.HK',
    label: '1988.HK,  China Minsheng Banking Corp., Ltd.',
    name: 'China Minsheng Banking Corp., Ltd.',
    symbol: '1988.HK',
  },
  {
    value: '1989.HK',
    label: '1989.HK,  Pine Care Group Limited',
    name: 'Pine Care Group Limited',
    symbol: '1989.HK',
  },
  {
    value: '2009.HK',
    label: '2009.HK,  BBMG Corporation',
    name: 'BBMG Corporation',
    symbol: '2009.HK',
  },
  {
    value: '2016.HK',
    label: '2016.HK,  China Zheshang Bank Co., Ltd',
    name: 'China Zheshang Bank Co., Ltd',
    symbol: '2016.HK',
  },
  {
    value: '2039.HK',
    label: '2039.HK,  China International Marine Containers (Group) Co., Ltd.',
    name: 'China International Marine Containers (Group) Co., Ltd.',
    symbol: '2039.HK',
  },
  {
    value: '2057.HK',
    label: '2057.HK,  ZTO Express (Cayman) Inc.',
    name: 'ZTO Express (Cayman) Inc.',
    symbol: '2057.HK',
  },
  {
    value: '2068.HK',
    label:
      '2068.HK,  China Aluminum International Engineering Corporation Limited',
    name: 'China Aluminum International Engineering Corporation Limited',
    symbol: '2068.HK',
  },
  {
    value: '2096.HK',
    label: '2096.HK,  Simcere Pharmaceutical Group Limited',
    name: 'Simcere Pharmaceutical Group Limited',
    symbol: '2096.HK',
  },
  {
    value: '2101.HK',
    label: '2101.HK,  Fulu Holdings Limited',
    name: 'Fulu Holdings Limited',
    symbol: '2101.HK',
  },
  {
    value: '2102.HK',
    label: '2102.HK,  Tak Lee Machinery Holdings Limited',
    name: 'Tak Lee Machinery Holdings Limited',
    symbol: '2102.HK',
  },
  {
    value: '2110.HK',
    label: '2110.HK,  Yue Kan Holdings Limited',
    name: 'Yue Kan Holdings Limited',
    symbol: '2110.HK',
  },
  {
    value: '2117.HK',
    label: '2117.HK,  Datang Group Holdings Limited',
    name: 'Datang Group Holdings Limited',
    symbol: '2117.HK',
  },
  {
    value: '2127.HK',
    label: '2127.HK,  Huisen Household International Group Limited',
    name: 'Huisen Household International Group Limited',
    symbol: '2127.HK',
  },
  {
    value: '2130.HK',
    label: '2130.HK,  CN Logistics International Holdings Limited',
    name: 'CN Logistics International Holdings Limited',
    symbol: '2130.HK',
  },
  {
    value: '2131.HK',
    label: '2131.HK,  Netjoy Holdings Limited',
    name: 'Netjoy Holdings Limited',
    symbol: '2131.HK',
  },
  {
    value: '2142.HK',
    label: '2142.HK,  HBM Holdings Limited',
    name: 'HBM Holdings Limited',
    symbol: '2142.HK',
  },
  {
    value: '2148.HK',
    label: '2148.HK,  Vesync Co., Ltd',
    name: 'Vesync Co., Ltd',
    symbol: '2148.HK',
  },
  {
    value: '2156.HK',
    label: '2156.HK,  C&D Property Management Group Co. Limited',
    name: 'C&D Property Management Group Co. Limited',
    symbol: '2156.HK',
  },
  {
    value: '2159.HK',
    label: '2159.HK,  Mediwelcome Healthcare Management & Technology Inc.',
    name: 'Mediwelcome Healthcare Management & Technology Inc.',
    symbol: '2159.HK',
  },
  {
    value: '2161.HK',
    label: '2161.HK,  JBM (Healthcare) Limited',
    name: 'JBM (Healthcare) Limited',
    symbol: '2161.HK',
  },
  {
    value: '2169.HK',
    label: '2169.HK,  Canggang Railway Limited',
    name: 'Canggang Railway Limited',
    symbol: '2169.HK',
  },
  {
    value: '2176.HK',
    label: '2176.HK,  CCID Consulting Company Limited',
    name: 'CCID Consulting Company Limited',
    symbol: '2176.HK',
  },
  {
    value: '2196.HK',
    label: '2196.HK,  Shanghai Fosun Pharmaceutical (Group) Co., Ltd.',
    name: 'Shanghai Fosun Pharmaceutical (Group) Co., Ltd.',
    symbol: '2196.HK',
  },
  {
    value: '2208.HK',
    label: '2208.HK,  Xinjiang Goldwind Science & Technology Co., Ltd.',
    name: 'Xinjiang Goldwind Science & Technology Co., Ltd.',
    symbol: '2208.HK',
  },
  {
    value: '2338.HK',
    label: '2338.HK,  Weichai Power Co., Ltd.',
    name: 'Weichai Power Co., Ltd.',
    symbol: '2338.HK',
  },
  {
    value: '2359.HK',
    label: '2359.HK,  WuXi AppTec Co., Ltd.',
    name: 'WuXi AppTec Co., Ltd.',
    symbol: '2359.HK',
  },
  {
    value: '2518.HK',
    label: '2518.HK,  Autohome Inc.',
    name: 'Autohome Inc.',
    symbol: '2518.HK',
  },
  {
    value: '2600.HK',
    label: '2600.HK,  Aluminum Corporation of China Limited',
    name: 'Aluminum Corporation of China Limited',
    symbol: '2600.HK',
  },
  {
    value: '2607.HK',
    label: '2607.HK,  Shanghai Pharmaceuticals Holding Co., Ltd',
    name: 'Shanghai Pharmaceuticals Holding Co., Ltd',
    symbol: '2607.HK',
  },
  {
    value: '2611.HK',
    label: '2611.HK,  Guotai Junan Securities Co., Ltd.',
    name: 'Guotai Junan Securities Co., Ltd.',
    symbol: '2611.HK',
  },
  {
    value: '2727.HK',
    label: '2727.HK,  Shanghai Electric Group Company Limited',
    name: 'Shanghai Electric Group Company Limited',
    symbol: '2727.HK',
  },
  {
    value: '2866.HK',
    label: '2866.HK,  COSCO SHIPPING Development Co., Ltd.',
    name: 'COSCO SHIPPING Development Co., Ltd.',
    symbol: '2866.HK',
  },
  {
    value: '2880.HK',
    label: '2880.HK,  Liaoning Port Co., Ltd.',
    name: 'Liaoning Port Co., Ltd.',
    symbol: '2880.HK',
  },
  {
    value: '2883.HK',
    label: '2883.HK,  China Oilfield Services Limited',
    name: 'China Oilfield Services Limited',
    symbol: '2883.HK',
  },
  {
    value: '3328.HK',
    label: '3328.HK,  Bank of Communications Co., Ltd.',
    name: 'Bank of Communications Co., Ltd.',
    symbol: '3328.HK',
  },
  {
    value: '3347.HK',
    label: '3347.HK,  Hangzhou Tigermed Consulting Co., Ltd.',
    name: 'Hangzhou Tigermed Consulting Co., Ltd.',
    symbol: '3347.HK',
  },
  {
    value: '3606.HK',
    label: '3606.HK,  Fuyao Glass Industry Group Co., Ltd.',
    name: 'Fuyao Glass Industry Group Co., Ltd.',
    symbol: '3606.HK',
  },
  {
    value: '3618.HK',
    label: '3618.HK,  Chongqing Rural Commercial Bank Co., Ltd.',
    name: 'Chongqing Rural Commercial Bank Co., Ltd.',
    symbol: '3618.HK',
  },
  {
    value: '3658.HK',
    label: '3658.HK,  New Hope Service Holdings Limited',
    name: 'New Hope Service Holdings Limited',
    symbol: '3658.HK',
  },
  {
    value: '3759.HK',
    label: '3759.HK,  Pharmaron Beijing Co., Ltd.',
    name: 'Pharmaron Beijing Co., Ltd.',
    symbol: '3759.HK',
  },
  {
    value: '3816.HK',
    label: '3816.HK,  KFM Kingdom Holdings Limited',
    name: 'KFM Kingdom Holdings Limited',
    symbol: '3816.HK',
  },
  {
    value: '3866.HK',
    label: '3866.HK,  Bank of Qingdao Co., Ltd.',
    name: 'Bank of Qingdao Co., Ltd.',
    symbol: '3866.HK',
  },
  {
    value: '3948.HK',
    label: '3948.HK,  Inner Mongolia Yitai Coal Co., Ltd.',
    name: 'Inner Mongolia Yitai Coal Co., Ltd.',
    symbol: '3948.HK',
  },
  {
    value: '3958.HK',
    label: '3958.HK,  Orient Securities Company Limited',
    name: 'Orient Securities Company Limited',
    symbol: '3958.HK',
  },
  {
    value: '3969.HK',
    label: '3969.HK,  China Railway Signal & Communication Corporation Limited',
    name: 'China Railway Signal & Communication Corporation Limited',
    symbol: '3969.HK',
  },
  {
    value: '3993.HK',
    label: '3993.HK,  CMOC Group Limited',
    name: 'CMOC Group Limited',
    symbol: '3993.HK',
  },
  {
    value: '6099.HK',
    label: '6099.HK,  China Merchants Securities Co., Ltd.',
    name: 'China Merchants Securities Co., Ltd.',
    symbol: '6099.HK',
  },
  {
    value: '6127.HK',
    label: '6127.HK,  Joinn Laboratories(China)Co.,Ltd.',
    name: 'Joinn Laboratories(China)Co.,Ltd.',
    symbol: '6127.HK',
  },
  {
    value: '6178.HK',
    label: '6178.HK,  Everbright Securities Company Limited',
    name: 'Everbright Securities Company Limited',
    symbol: '6178.HK',
  },
  {
    value: '6190.HK',
    label: '6190.HK,  Bank of Jiujiang Co., Ltd.',
    name: 'Bank of Jiujiang Co., Ltd.',
    symbol: '6190.HK',
  },
  {
    value: '6196.HK',
    label: '6196.HK,  Bank of Zhengzhou Co., Ltd.',
    name: 'Bank of Zhengzhou Co., Ltd.',
    symbol: '6196.HK',
  },
  {
    value: '6198.HK',
    label: '6198.HK,  Qingdao Port International Co., Ltd.',
    name: 'Qingdao Port International Co., Ltd.',
    symbol: '6198.HK',
  },
  {
    value: '6288.HK',
    label: '6288.HK,  Fast Retailing Co., Ltd.',
    name: 'Fast Retailing Co., Ltd.',
    symbol: '6288.HK',
  },
  {
    value: '6618.HK',
    label: '6618.HK,  JD Health International Inc.',
    name: 'JD Health International Inc.',
    symbol: '6618.HK',
  },
  {
    value: '6668.HK',
    label: '6668.HK,  E-Star Commercial Management Company Limited',
    name: 'E-Star Commercial Management Company Limited',
    symbol: '6668.HK',
  },
  {
    value: '6677.HK',
    label: '6677.HK,  Sino-Ocean Service Holding Limited',
    name: 'Sino-Ocean Service Holding Limited',
    symbol: '6677.HK',
  },
  {
    value: '6690.HK',
    label: '6690.HK,  Haier Smart Home Co., Ltd.',
    name: 'Haier Smart Home Co., Ltd.',
    symbol: '6690.HK',
  },
  {
    value: '6806.HK',
    label: '6806.HK,  Shenwan Hongyuan Group Co., Ltd.',
    name: 'Shenwan Hongyuan Group Co., Ltd.',
    symbol: '6806.HK',
  },
  {
    value: '6818.HK',
    label: '6818.HK,  China Everbright Bank Company Limited',
    name: 'China Everbright Bank Company Limited',
    symbol: '6818.HK',
  },
  {
    value: '6826.HK',
    label: '6826.HK,  Shanghai Haohai Biological Technology Co., Ltd.',
    name: 'Shanghai Haohai Biological Technology Co., Ltd.',
    symbol: '6826.HK',
  },
  {
    value: '6837.HK',
    label: '6837.HK,  Haitong Securities Co., Ltd.',
    name: 'Haitong Securities Co., Ltd.',
    symbol: '6837.HK',
  },
  {
    value: '6865.HK',
    label: '6865.HK,  Flat Glass Group Co., Ltd.',
    name: 'Flat Glass Group Co., Ltd.',
    symbol: '6865.HK',
  },
  {
    value: '6869.HK',
    label:
      '6869.HK,  Yangtze Optical Fibre And Cable Joint Stock Limited Company',
    name: 'Yangtze Optical Fibre And Cable Joint Stock Limited Company',
    symbol: '6869.HK',
  },
  {
    value: '6881.HK',
    label: '6881.HK,  China Galaxy Securities Co., Ltd.',
    name: 'China Galaxy Securities Co., Ltd.',
    symbol: '6881.HK',
  },
  {
    value: '6886.HK',
    label: '6886.HK,  Huatai Securities Co., Ltd.',
    name: 'Huatai Securities Co., Ltd.',
    symbol: '6886.HK',
  },
  {
    value: '6933.HK',
    label: '6933.HK,  Sino-Entertainment Technology Holdings Limited',
    name: 'Sino-Entertainment Technology Holdings Limited',
    symbol: '6933.HK',
  },
  {
    value: '6958.HK',
    label: '6958.HK,  Zhenro Services Group Limited',
    name: 'Zhenro Services Group Limited',
    symbol: '6958.HK',
  },
  {
    value: '6968.HK',
    label: '6968.HK,  Ganglong China Property Group Limited',
    name: 'Ganglong China Property Group Limited',
    symbol: '6968.HK',
  },
  {
    value: '6978.HK',
    label: '6978.HK,  Immunotech Biopharm Ltd',
    name: 'Immunotech Biopharm Ltd',
    symbol: '6978.HK',
  },
  {
    value: '6989.HK',
    label:
      '6989.HK,  Excellence Commercial Property & Facilities Management Group Limited',
    name: 'Excellence Commercial Property & Facilities Management Group Limited',
    symbol: '6989.HK',
  },
  {
    value: '6993.HK',
    label: '6993.HK,  Blue Moon Group Holdings Limited',
    name: 'Blue Moon Group Holdings Limited',
    symbol: '6993.HK',
  },
  {
    value: '6998.HK',
    label: '6998.HK,  Genor Biopharma Holdings Limited',
    name: 'Genor Biopharma Holdings Limited',
    symbol: '6998.HK',
  },
  {
    value: '8060.HK',
    label: '8060.HK,  Global Link Communications Holdings Limited',
    name: 'Global Link Communications Holdings Limited',
    symbol: '8060.HK',
  },
  {
    value: '8067.HK',
    label: '8067.HK,  Oriental University City Holdings (H.K.) Limited',
    name: 'Oriental University City Holdings (H.K.) Limited',
    symbol: '8067.HK',
  },
  {
    value: '8189.HK',
    label: '8189.HK,  Tianjin TEDA Biomedical Engineering Company Limited',
    name: 'Tianjin TEDA Biomedical Engineering Company Limited',
    symbol: '8189.HK',
  },
  {
    value: '8205.HK',
    label:
      '8205.HK,  Shanghai Jiaoda Withub Information Industrial Company Limited',
    name: 'Shanghai Jiaoda Withub Information Industrial Company Limited',
    symbol: '8205.HK',
  },
  {
    value: '8245.HK',
    label:
      '8245.HK,  Zhao Xian Business Ecology International Holdings Limited',
    name: 'Zhao Xian Business Ecology International Holdings Limited',
    symbol: '8245.HK',
  },
  {
    value: '8282.HK',
    label: '8282.HK,  Gameone Holdings Limited',
    name: 'Gameone Holdings Limited',
    symbol: '8282.HK',
  },
  {
    value: '8310.HK',
    label: '8310.HK,  Dafeng Port Heshun Technology Company Limited',
    name: 'Dafeng Port Heshun Technology Company Limited',
    symbol: '8310.HK',
  },
  {
    value: '8353.HK',
    label: '8353.HK,  Anacle Systems Limited',
    name: 'Anacle Systems Limited',
    symbol: '8353.HK',
  },
  {
    value: '8366.HK',
    label: '8366.HK,  Zhejiang United Investment Holdings Group Limited',
    name: 'Zhejiang United Investment Holdings Group Limited',
    symbol: '8366.HK',
  },
  {
    value: '8489.HK',
    label: '8489.HK,  Grand Power Logistics Group Limited',
    name: 'Grand Power Logistics Group Limited',
    symbol: '8489.HK',
  },
  {
    value: '8623.HK',
    label: '8623.HK,  China Saftower International Holding Group Limited',
    name: 'China Saftower International Holding Group Limited',
    symbol: '8623.HK',
  },
  {
    value: '8657.HK',
    label: '8657.HK,  True Partner Capital Holding Limited',
    name: 'True Partner Capital Holding Limited',
    symbol: '8657.HK',
  },
  {
    value: '8659.HK',
    label: '8659.HK,  Yik Wo International Holdings Limited',
    name: 'Yik Wo International Holdings Limited',
    symbol: '8659.HK',
  },
  {
    value: '9600.HK',
    label: '9600.HK,  Newlink Technology Inc.',
    name: 'Newlink Technology Inc.',
    symbol: '9600.HK',
  },
  {
    value: '9626.HK',
    label: '9626.HK,  Bilibili Inc.',
    name: 'Bilibili Inc.',
    symbol: '9626.HK',
  },
  {
    value: '9668.HK',
    label: '9668.HK,  China Bohai Bank Co., Ltd.',
    name: 'China Bohai Bank Co., Ltd.',
    symbol: '9668.HK',
  },
  {
    value: '9688.HK',
    label: '9688.HK,  Zai Lab Limited',
    name: 'Zai Lab Limited',
    symbol: '9688.HK',
  },
  {
    value: '9888.HK',
    label: '9888.HK,  Baidu, Inc.',
    name: 'Baidu, Inc.',
    symbol: '9888.HK',
  },
  {
    value: '9906.HK',
    label: '9906.HK,  Honliv Healthcare Management Group Company Limited',
    name: 'Honliv Healthcare Management Group Company Limited',
    symbol: '9906.HK',
  },
  {
    value: '9908.HK',
    label: '9908.HK,  JiaXing Gas Group Co., Ltd.',
    name: 'JiaXing Gas Group Co., Ltd.',
    symbol: '9908.HK',
  },
  {
    value: '9913.HK',
    label: '9913.HK,  Chi Kan Holdings Limited',
    name: 'Chi Kan Holdings Limited',
    symbol: '9913.HK',
  },
  {
    value: '9961.HK',
    label: '9961.HK,  Trip.com Group Limited',
    name: 'Trip.com Group Limited',
    symbol: '9961.HK',
  },
  {
    value: '9963.HK',
    label: '9963.HK,  Transtech Optelecom Science Holdings Limited',
    name: 'Transtech Optelecom Science Holdings Limited',
    symbol: '9963.HK',
  },
  {
    value: '9979.HK',
    label: '9979.HK,  Greentown Management Holdings Company Limited',
    name: 'Greentown Management Holdings Company Limited',
    symbol: '9979.HK',
  },
  {
    value: '9989.HK',
    label: '9989.HK,  Shenzhen Hepalink Pharmaceutical Group Co., Ltd.',
    name: 'Shenzhen Hepalink Pharmaceutical Group Co., Ltd.',
    symbol: '9989.HK',
  },
  {
    value: '9990.HK',
    label: '9990.HK,  Archosaur Games Inc.',
    name: 'Archosaur Games Inc.',
    symbol: '9990.HK',
  },
  {
    value: '9991.HK',
    label: '9991.HK,  Baozun Inc.',
    name: 'Baozun Inc.',
    symbol: '9991.HK',
  },
  {
    value: '9993.HK',
    label: '9993.HK,  Radiance Holdings (Group) Company Limited',
    name: 'Radiance Holdings (Group) Company Limited',
    symbol: '9993.HK',
  },
  {
    value: '80737.HK',
    label:
      '80737.HK,  Shenzhen Investment Holdings Bay Area Development Company Limited',
    name: 'Shenzhen Investment Holdings Bay Area Development Company Limited',
    symbol: '80737.HK',
  },
  {
    value: 'ACXP',
    label: 'ACXP,  Acurx Pharmaceuticals, Inc.',
    name: 'Acurx Pharmaceuticals, Inc.',
    symbol: 'ACXP',
  },
  {
    value: 'ALZN',
    label: 'ALZN,  Alzamend Neuro, Inc.',
    name: 'Alzamend Neuro, Inc.',
    symbol: 'ALZN',
  },
  {
    value: 'WKME',
    label: 'WKME,  WalkMe Ltd.',
    name: 'WalkMe Ltd.',
    symbol: 'WKME',
  },
  {
    value: 'TECK-A.TO',
    label: 'TECK-A.TO,  Teck Resources Limited',
    name: 'Teck Resources Limited',
    symbol: 'TECK-A.TO',
  },
  {
    value: 'TECK-B.TO',
    label: 'TECK-B.TO,  Teck Resources Limited',
    name: 'Teck Resources Limited',
    symbol: 'TECK-B.TO',
  },
  {
    value: 'STCK.TO',
    label: 'STCK.TO,  Stack Capital Group Inc.',
    name: 'Stack Capital Group Inc.',
    symbol: 'STCK.TO',
  },
  {
    value: 'REAX',
    label: 'REAX,  The Real Brokerage Inc.',
    name: 'The Real Brokerage Inc.',
    symbol: 'REAX',
  },
  {
    value: 'HUT',
    label: 'HUT,  Hut 8 Mining Corp.',
    name: 'Hut 8 Mining Corp.',
    symbol: 'HUT',
  },
  {
    value: 'AFME.PA',
    label: 'AFME.PA,  Affluent Medical SA',
    name: 'Affluent Medical SA',
    symbol: 'AFME.PA',
  },
  {
    value: 'ISPC',
    label: 'ISPC,  iSpecimen Inc.',
    name: 'iSpecimen Inc.',
    symbol: 'ISPC',
  },
  {
    value: 'LYEL',
    label: 'LYEL,  Lyell Immunopharma, Inc.',
    name: 'Lyell Immunopharma, Inc.',
    symbol: 'LYEL',
  },
  {
    value: 'TTSH',
    label: 'TTSH,  Tile Shop Holdings, Inc.',
    name: 'Tile Shop Holdings, Inc.',
    symbol: 'TTSH',
  },
  {
    value: 'ME',
    label: 'ME,  23andMe Holding Co.',
    name: '23andMe Holding Co.',
    symbol: 'ME',
  },
  {
    value: 'GAME',
    label: 'GAME,  Engine Gaming and Media, Inc.',
    name: 'Engine Gaming and Media, Inc.',
    symbol: 'GAME',
  },
  {
    value: 'MCAE',
    label: 'MCAE,  Mountain Crest Acquisition Corp. III',
    name: 'Mountain Crest Acquisition Corp. III',
    symbol: 'MCAE',
  },
  {
    value: 'ELO.OL',
    label: 'ELO.OL,  Elopak ASA',
    name: 'Elopak ASA',
    symbol: 'ELO.OL',
  },
  {
    value: 'ALVET.PA',
    label: 'ALVET.PA,  TheraVet SA',
    name: 'TheraVet SA',
    symbol: 'ALVET.PA',
  },
  {
    value: 'BITF',
    label: 'BITF,  Bitfarms Ltd.',
    name: 'Bitfarms Ltd.',
    symbol: 'BITF',
  },
  {
    value: 'SFT',
    label: 'SFT,  Shift Technologies, Inc.',
    name: 'Shift Technologies, Inc.',
    symbol: 'SFT',
  },
  {
    value: 'BBIG',
    label: 'BBIG,  Vinco Ventures, Inc.',
    name: 'Vinco Ventures, Inc.',
    symbol: 'BBIG',
  },
  {
    value: 'CXM',
    label: 'CXM,  Sprinklr, Inc.',
    name: 'Sprinklr, Inc.',
    symbol: 'CXM',
  },
  {
    value: 'YMM',
    label: 'YMM,  Full Truck Alliance Co. Ltd.',
    name: 'Full Truck Alliance Co. Ltd.',
    symbol: 'YMM',
  },
  {
    value: 'MIRO',
    label: 'MIRO,  Miromatrix Medical Inc.',
    name: 'Miromatrix Medical Inc.',
    symbol: 'MIRO',
  },
  {
    value: 'BHG',
    label: 'BHG,  Bright Health Group, Inc.',
    name: 'Bright Health Group, Inc.',
    symbol: 'BHG',
  },
  {
    value: '5CV.F',
    label: '5CV.F,  CureVac N.V.',
    name: 'CureVac N.V.',
    symbol: '5CV.F',
  },
  {
    value: '22UA.F',
    label: '22UA.F,  BioNTech SE',
    name: 'BioNTech SE',
    symbol: '22UA.F',
  },
  {
    value: 'GLUE',
    label: 'GLUE,  Monte Rosa Therapeutics, Inc.',
    name: 'Monte Rosa Therapeutics, Inc.',
    symbol: 'GLUE',
  },
  {
    value: 'VLATW',
    label: 'VLATW,  Valor Latitude Acquisition Corp.',
    name: 'Valor Latitude Acquisition Corp.',
    symbol: 'VLATW',
  },
  {
    value: 'VLAT',
    label: 'VLAT,  Valor Latitude Acquisition Corp.',
    name: 'Valor Latitude Acquisition Corp.',
    symbol: 'VLAT',
  },
  {
    value: 'BON',
    label: 'BON,  Bon Natural Life Limited',
    name: 'Bon Natural Life Limited',
    symbol: 'BON',
  },
  {
    value: 'CFLT',
    label: 'CFLT,  Confluent, Inc.',
    name: 'Confluent, Inc.',
    symbol: 'CFLT',
  },
  {
    value: 'DKDCA',
    label: 'DKDCA,  Data Knights Acquisition Corp.',
    name: 'Data Knights Acquisition Corp.',
    symbol: 'DKDCA',
  },
  {
    value: 'DKDCW',
    label: 'DKDCW,  Data Knights Acquisition Corp.',
    name: 'Data Knights Acquisition Corp.',
    symbol: 'DKDCW',
  },
  {
    value: 'MITAU',
    label: 'MITAU,  Coliseum Acquisition Corp.',
    name: 'Coliseum Acquisition Corp.',
    symbol: 'MITAU',
  },
  {
    value: 'FA',
    label: 'FA,  First Advantage Corporation',
    name: 'First Advantage Corporation',
    symbol: 'FA',
  },
  {
    value: 'GHRS',
    label: 'GHRS,  GH Research PLC',
    name: 'GH Research PLC',
    symbol: 'GHRS',
  },
  {
    value: 'TKNO',
    label: 'TKNO,  Alpha Teknova, Inc.',
    name: 'Alpha Teknova, Inc.',
    symbol: 'TKNO',
  },
  {
    value: 'GRPH',
    label: 'GRPH,  Graphite Bio, Inc.',
    name: 'Graphite Bio, Inc.',
    symbol: 'GRPH',
  },
  {
    value: 'ELEV',
    label: 'ELEV,  Elevation Oncology, Inc.',
    name: 'Elevation Oncology, Inc.',
    symbol: 'ELEV',
  },
  {
    value: 'MF',
    label: 'MF,  Missfresh Limited',
    name: 'Missfresh Limited',
    symbol: 'MF',
  },
  {
    value: 'WGRO',
    label: 'WGRO,  WisdomTree U.S. Growth & Momentum Fund',
    name: 'WisdomTree U.S. Growth & Momentum Fund',
    symbol: 'WGRO',
  },
  {
    value: 'DOCS',
    label: 'DOCS,  Doximity, Inc.',
    name: 'Doximity, Inc.',
    symbol: 'DOCS',
  },
  {
    value: 'SMCRT.OL',
    label: 'SMCRT.OL,  SmartCraft ASA',
    name: 'SmartCraft ASA',
    symbol: 'SMCRT.OL',
  },
  {
    value: 'FTVIU',
    label: 'FTVIU,  FinTech Acquisition Corp. VI',
    name: 'FinTech Acquisition Corp. VI',
    symbol: 'FTVIU',
  },
  {
    value: 'PET.TO',
    label: 'PET.TO,  Pet Valu Holdings Ltd.',
    name: 'Pet Valu Holdings Ltd.',
    symbol: 'PET.TO',
  },
  {
    value: 'HDF.PA',
    label: 'HDF.PA,  Hydrogène de France Société anonyme',
    name: 'Hydrogène de France Société anonyme',
    symbol: 'HDF.PA',
  },
  {
    value: 'ATIP',
    label: 'ATIP,  ATI Physical Therapy, Inc.',
    name: 'ATI Physical Therapy, Inc.',
    symbol: 'ATIP',
  },
  {
    value: 'MCW',
    label: 'MCW,  Mister Car Wash, Inc.',
    name: 'Mister Car Wash, Inc.',
    symbol: 'MCW',
  },
  {
    value: 'GMVD',
    label: 'GMVD,  G Medical Innovations Holdings Ltd',
    name: 'G Medical Innovations Holdings Ltd',
    symbol: 'GMVD',
  },
  {
    value: 'GMVDW',
    label: 'GMVDW,  G Medical Innovations Holdings Ltd',
    name: 'G Medical Innovations Holdings Ltd',
    symbol: 'GMVDW',
  },
  {
    value: 'BFI',
    label: 'BFI,  BurgerFi International, Inc.',
    name: 'BurgerFi International, Inc.',
    symbol: 'BFI',
  },
  {
    value: 'DDL',
    label: 'DDL,  Dingdong (Cayman) Limited',
    name: 'Dingdong (Cayman) Limited',
    symbol: 'DDL',
  },
  {
    value: 'ASTR',
    label: 'ASTR,  Astra Space, Inc.',
    name: 'Astra Space, Inc.',
    symbol: 'ASTR',
  },
  {
    value: 'DALN',
    label: 'DALN,  DallasNews Corporation',
    name: 'DallasNews Corporation',
    symbol: 'DALN',
  },
  {
    value: 'PAYO',
    label: 'PAYO,  Payoneer Global Inc.',
    name: 'Payoneer Global Inc.',
    symbol: 'PAYO',
  },
  {
    value: 'BRIV',
    label: 'BRIV,  B. Riley Principal 250 Merger Corp.',
    name: 'B. Riley Principal 250 Merger Corp.',
    symbol: 'BRIV',
  },
  {
    value: 'BRIVW',
    label: 'BRIVW,  B. Riley Principal 250 Merger Corp. Warrant',
    name: 'B. Riley Principal 250 Merger Corp. Warrant',
    symbol: 'BRIVW',
  },
  {
    value: 'LZ',
    label: 'LZ,  LegalZoom.com, Inc.',
    name: 'LegalZoom.com, Inc.',
    symbol: 'LZ',
  },
  {
    value: 'SBDS.L',
    label: 'SBDS.L,  Silver Bullet Data Services Group PLC',
    name: 'Silver Bullet Data Services Group PLC',
    symbol: 'SBDS.L',
  },
  {
    value: 'XMTR',
    label: 'XMTR,  Xometry, Inc.',
    name: 'Xometry, Inc.',
    symbol: 'XMTR',
  },
  {
    value: 'S',
    label: 'S,  SentinelOne, Inc.',
    name: 'SentinelOne, Inc.',
    symbol: 'S',
  },
  {
    value: 'AVTE',
    label: 'AVTE,  Aerovate Therapeutics, Inc.',
    name: 'Aerovate Therapeutics, Inc.',
    symbol: 'AVTE',
  },
  {
    value: 'FICVU',
    label: 'FICVU,  Frontier Investment Corp',
    name: 'Frontier Investment Corp',
    symbol: 'FICVU',
  },
  {
    value: 'MCAFU',
    label: 'MCAFU,  Mountain Crest Acquisition Corp. IV',
    name: 'Mountain Crest Acquisition Corp. IV',
    symbol: 'MCAFU',
  },
  {
    value: 'THCPU',
    label: 'THCPU,  Thunder Bridge Capital Partners IV Inc.',
    name: 'Thunder Bridge Capital Partners IV Inc.',
    symbol: 'THCPU',
  },
  {
    value: 'BYDDF',
    label: 'BYDDF,  BYD Company Limited',
    name: 'BYD Company Limited',
    symbol: 'BYDDF',
  },
  {
    value: '3CP.F',
    label: '3CP.F,  Xiaomi Corporation',
    name: 'Xiaomi Corporation',
    symbol: '3CP.F',
  },
  {
    value: 'XIACF',
    label: 'XIACF,  Xiaomi Corporation',
    name: 'Xiaomi Corporation',
    symbol: 'XIACF',
  },
  {
    value: 'ENI.F',
    label: 'ENI.F,  Eni S.p.A.',
    name: 'Eni S.p.A.',
    symbol: 'ENI.F',
  },
  {
    value: 'MINM',
    label: 'MINM,  Minim, Inc.',
    name: 'Minim, Inc.',
    symbol: 'MINM',
  },
  {
    value: 'LSPK.TO',
    label: 'LSPK.TO,  LifeSpeak Inc.',
    name: 'LifeSpeak Inc.',
    symbol: 'LSPK.TO',
  },
  {
    value: 'CRTD',
    label: 'CRTD,  Creatd, Inc.',
    name: 'Creatd, Inc.',
    symbol: 'CRTD',
  },
  {
    value: 'TBLA',
    label: 'TBLA,  Taboola.com Ltd.',
    name: 'Taboola.com Ltd.',
    symbol: 'TBLA',
  },
  {
    value: 'IS',
    label: 'IS,  ironSource Ltd.',
    name: 'ironSource Ltd.',
    symbol: 'IS',
  },
  {
    value: 'MITQ',
    label: 'MITQ,  Moving iMage Technologies, Inc.',
    name: 'Moving iMage Technologies, Inc.',
    symbol: 'MITQ',
  },
  {
    value: 'AGRI',
    label: 'AGRI,  AgriFORCE Growing Systems Ltd.',
    name: 'AgriFORCE Growing Systems Ltd.',
    symbol: 'AGRI',
  },
  {
    value: 'QSI',
    label: 'QSI,  Quantum-Si incorporated',
    name: 'Quantum-Si incorporated',
    symbol: 'QSI',
  },
  {
    value: 'SNTG',
    label: 'SNTG,  Sentage Holdings Inc.',
    name: 'Sentage Holdings Inc.',
    symbol: 'SNTG',
  },
  {
    value: 'RAMMW',
    label: 'RAMMW,  Aries I Acquisition Corporation',
    name: 'Aries I Acquisition Corporation',
    symbol: 'RAMMW',
  },
  {
    value: 'RAM',
    label: 'RAM,  Aries I Acquisition Corporation',
    name: 'Aries I Acquisition Corporation',
    symbol: 'RAM',
  },
  {
    value: 'BURY.CN',
    label: 'BURY.CN,  Burrell Resources Inc.',
    name: 'Burrell Resources Inc.',
    symbol: 'BURY.CN',
  },
  {
    value: 'NECB',
    label: 'NECB,  Northeast Community Bancorp, Inc.',
    name: 'Northeast Community Bancorp, Inc.',
    symbol: 'NECB',
  },
  {
    value: 'UNCY',
    label: 'UNCY,  Unicycive Therapeutics, Inc.',
    name: 'Unicycive Therapeutics, Inc.',
    symbol: 'UNCY',
  },
  {
    value: 'ARYE',
    label: 'ARYE,  ARYA Sciences Acquisition Corp V',
    name: 'ARYA Sciences Acquisition Corp V',
    symbol: 'ARYE',
  },
  {
    value: 'BUMP.L',
    label: 'BUMP.L,  Seraphine Group PLC',
    name: 'Seraphine Group PLC',
    symbol: 'BUMP.L',
  },
  {
    value: 'TTEN3.SA',
    label: 'TTEN3.SA,  Três Tentos Agroindustrial S/A',
    name: 'Três Tentos Agroindustrial S/A',
    symbol: 'TTEN3.SA',
  },
  {
    value: 'NXTP',
    label: 'NXTP,  NextPlay Technologies, Inc.',
    name: 'NextPlay Technologies, Inc.',
    symbol: 'NXTP',
  },
  {
    value: 'IINNW',
    label: 'IINNW,  Inspira Technologies Oxy B.H.N. Ltd.',
    name: 'Inspira Technologies Oxy B.H.N. Ltd.',
    symbol: 'IINNW',
  },
  {
    value: 'IINN',
    label: 'IINN,  Inspira Technologies Oxy B.H.N. Ltd.',
    name: 'Inspira Technologies Oxy B.H.N. Ltd.',
    symbol: 'IINN',
  },
  {
    value: 'SSIT.L',
    label: 'SSIT.L,  Seraphim Space Investment Trust Plc',
    name: 'Seraphim Space Investment Trust Plc',
    symbol: 'SSIT.L',
  },
  {
    value: 'FXLV',
    label: 'FXLV,  F45 Training Holdings Inc.',
    name: 'F45 Training Holdings Inc.',
    symbol: 'FXLV',
  },
  {
    value: 'PECO',
    label: 'PECO,  Phillips Edison & Company, Inc.',
    name: 'Phillips Edison & Company, Inc.',
    symbol: 'PECO',
  },
  {
    value: 'GVOLT.LS',
    label: 'GVOLT.LS,  Greenvolt - Energias Renováveis, S.A.',
    name: 'Greenvolt - Energias Renováveis, S.A.',
    symbol: 'GVOLT.LS',
  },
  {
    value: 'SGHT',
    label: 'SGHT,  Sight Sciences, Inc.',
    name: 'Sight Sciences, Inc.',
    symbol: 'SGHT',
  },
  {
    value: 'BC94.L',
    label: 'BC94.L,  Samsung Electronics Co., Ltd.',
    name: 'Samsung Electronics Co., Ltd.',
    symbol: 'BC94.L',
  },
  {
    value: 'MRX.DE',
    label: 'MRX.DE,  Mister Spex SE',
    name: 'Mister Spex SE',
    symbol: 'MRX.DE',
  },
  {
    value: 'FSNT.DE',
    label: 'FSNT.DE,  Fashionette AG',
    name: 'Fashionette AG',
    symbol: 'FSNT.DE',
  },
  {
    value: 'KTEK.DE',
    label: 'KTEK.DE,  KATEK SE',
    name: 'KATEK SE',
    symbol: 'KTEK.DE',
  },
  {
    value: 'QFUEL.OL',
    label: 'QFUEL.OL,  Quantafuel ASA',
    name: 'Quantafuel ASA',
    symbol: 'QFUEL.OL',
  },
  {
    value: 'SCATC.OL',
    label: 'SCATC.OL,  Scatec ASA',
    name: 'Scatec ASA',
    symbol: 'SCATC.OL',
  },
  {
    value: 'PMG.OL',
    label: 'PMG.OL,  Play Magnus AS',
    name: 'Play Magnus AS',
    symbol: 'PMG.OL',
  },
  {
    value: 'IVX.V',
    label: 'IVX.V,  Inventronics Limited',
    name: 'Inventronics Limited',
    symbol: 'IVX.V',
  },
  {
    value: 'APL.V',
    label: 'APL.V,  Appulse Corporation',
    name: 'Appulse Corporation',
    symbol: 'APL.V',
  },
  {
    value: 'CLOEU',
    label: 'CLOEU,  Clover Leaf Capital Corp.',
    name: 'Clover Leaf Capital Corp.',
    symbol: 'CLOEU',
  },
  {
    value: 'AUVIP',
    label: 'AUVIP,  Applied UV, Inc.',
    name: 'Applied UV, Inc.',
    symbol: 'AUVIP',
  },
  {
    value: 'HCWB',
    label: 'HCWB,  HCW Biologics Inc.',
    name: 'HCW Biologics Inc.',
    symbol: 'HCWB',
  },
  {
    value: 'BRDG',
    label: 'BRDG,  Bridge Investment Group Holdings Inc.',
    name: 'Bridge Investment Group Holdings Inc.',
    symbol: 'BRDG',
  },
  {
    value: 'ALTO',
    label: 'ALTO,  Alto Ingredients, Inc.',
    name: 'Alto Ingredients, Inc.',
    symbol: 'ALTO',
  },
  {
    value: 'CRCL.L',
    label: 'CRCL.L,  Corcel Plc',
    name: 'Corcel Plc',
    symbol: 'CRCL.L',
  },
  {
    value: 'INST',
    label: 'INST,  Instructure Holdings, Inc.',
    name: 'Instructure Holdings, Inc.',
    symbol: 'INST',
  },
  {
    value: 'VTEX',
    label: 'VTEX,  VTEX',
    name: 'VTEX',
    symbol: 'VTEX',
  },
  {
    value: 'ZVIA',
    label: 'ZVIA,  Zevia PBC',
    name: 'Zevia PBC',
    symbol: 'ZVIA',
  },
  {
    value: 'DEN',
    label: 'DEN,  Denbury Inc.',
    name: 'Denbury Inc.',
    symbol: 'DEN',
  },
  {
    value: 'WFG',
    label: 'WFG,  West Fraser Timber Co. Ltd.',
    name: 'West Fraser Timber Co. Ltd.',
    symbol: 'WFG',
  },
  {
    value: 'MKTW',
    label: 'MKTW,  MarketWise, Inc.',
    name: 'MarketWise, Inc.',
    symbol: 'MKTW',
  },
  {
    value: 'BASE',
    label: 'BASE,  Couchbase, Inc.',
    name: 'Couchbase, Inc.',
    symbol: 'BASE',
  },
  {
    value: 'MLAS3.SA',
    label: 'MLAS3.SA,  Multilaser Industrial S.A.',
    name: 'Multilaser Industrial S.A.',
    symbol: 'MLAS3.SA',
  },
  {
    value: 'PFTAU',
    label: 'PFTAU,  Portage Fintech Acquisition Corporation',
    name: 'Portage Fintech Acquisition Corporation',
    symbol: 'PFTAU',
  },
  {
    value: 'TCBC',
    label: 'TCBC,  TC Bancshares, Inc.',
    name: 'TC Bancshares, Inc.',
    symbol: 'TCBC',
  },
  {
    value: 'KLTR',
    label: 'KLTR,  Kaltura, Inc.',
    name: 'Kaltura, Inc.',
    symbol: 'KLTR',
  },
  {
    value: 'DCRDU',
    label: 'DCRDU,  Decarbonization Plus Acquisition Corporation IV',
    name: 'Decarbonization Plus Acquisition Corporation IV',
    symbol: 'DCRDU',
  },
  {
    value: 'PYCR',
    label: 'PYCR,  Paycor HCM, Inc.',
    name: 'Paycor HCM, Inc.',
    symbol: 'PYCR',
  },
  {
    value: 'GAMB',
    label: 'GAMB,  Gambling.com Group Limited',
    name: 'Gambling.com Group Limited',
    symbol: 'GAMB',
  },
  {
    value: 'VEEE',
    label: 'VEEE,  Twin Vee Powercats Co.',
    name: 'Twin Vee Powercats Co.',
    symbol: 'VEEE',
  },
  {
    value: 'LAW',
    label: 'LAW,  CS Disco, Inc.',
    name: 'CS Disco, Inc.',
    symbol: 'LAW',
  },
  {
    value: 'XPOF',
    label: 'XPOF,  Xponential Fitness, Inc.',
    name: 'Xponential Fitness, Inc.',
    symbol: 'XPOF',
  },
  {
    value: 'CRBU',
    label: 'CRBU,  Caribou Biosciences, Inc.',
    name: 'Caribou Biosciences, Inc.',
    symbol: 'CRBU',
  },
  {
    value: 'BLNGU',
    label: 'BLNGU,  Belong Acquisition Corp.',
    name: 'Belong Acquisition Corp.',
    symbol: 'BLNGU',
  },
  {
    value: 'OB',
    label: 'OB,  Outbrain Inc.',
    name: 'Outbrain Inc.',
    symbol: 'OB',
  },
  {
    value: 'USCB',
    label: 'USCB,  USCB Financial Holdings, Inc.',
    name: 'USCB Financial Holdings, Inc.',
    symbol: 'USCB',
  },
  {
    value: 'CNM',
    label: 'CNM,  Core & Main, Inc.',
    name: 'Core & Main, Inc.',
    symbol: 'CNM',
  },
  {
    value: 'CADL',
    label: 'CADL,  Candel Therapeutics, Inc.',
    name: 'Candel Therapeutics, Inc.',
    symbol: 'CADL',
  },
  {
    value: 'SOPH',
    label: 'SOPH,  SOPHiA GENETICS SA',
    name: 'SOPHiA GENETICS SA',
    symbol: 'SOPH',
  },
  {
    value: 'SSIC',
    label: 'SSIC,  Silver Spike Investment Corp.',
    name: 'Silver Spike Investment Corp.',
    symbol: 'SSIC',
  },
  {
    value: 'CTKB',
    label: 'CTKB,  Cytek Biosciences, Inc.',
    name: 'Cytek Biosciences, Inc.',
    symbol: 'CTKB',
  },
  {
    value: 'MQ',
    label: 'MQ,  Marqeta, Inc.',
    name: 'Marqeta, Inc.',
    symbol: 'MQ',
  },
  {
    value: 'GK',
    label: 'GK,  AdvisorShares Gerber Kawasaki ETF',
    name: 'AdvisorShares Gerber Kawasaki ETF',
    symbol: 'GK',
  },
  {
    value: 'LVMHF',
    label: 'LVMHF,  LVMH Moët Hennessy - Louis Vuitton, Société Européenne',
    name: 'LVMH Moët Hennessy - Louis Vuitton, Société Européenne',
    symbol: 'LVMHF',
  },
  {
    value: 'FSELX',
    label: 'FSELX,  Fidelity Select Portfolios - Semiconductors Portfolio',
    name: 'Fidelity Select Portfolios - Semiconductors Portfolio',
    symbol: 'FSELX',
  },
  {
    value: 'FNTEX',
    label: 'FNTEX,  Fidelity Disruptive Finance Fund',
    name: 'Fidelity Disruptive Finance Fund',
    symbol: 'FNTEX',
  },
  {
    value: 'FSMAX',
    label: 'FSMAX,  Fidelity Extended Market Index Fund',
    name: 'Fidelity Extended Market Index Fund',
    symbol: 'FSMAX',
  },
  {
    value: 'FIPDX',
    label: 'FIPDX,  Fidelity Inflation-Protected Bond Index Fund',
    name: 'Fidelity Inflation-Protected Bond Index Fund',
    symbol: 'FIPDX',
  },
  {
    value: 'FBOTX',
    label: 'FBOTX,  Fidelity Disruptive Automation Fund',
    name: 'Fidelity Disruptive Automation Fund',
    symbol: 'FBOTX',
  },
  {
    value: 'FTEKX',
    label: 'FTEKX,  Fidelity Disruptive Technology Fund',
    name: 'Fidelity Disruptive Technology Fund',
    symbol: 'FTEKX',
  },
  {
    value: 'LCID',
    label: 'LCID,  Lucid Group, Inc.',
    name: 'Lucid Group, Inc.',
    symbol: 'LCID',
  },
  {
    value: 'VDMCY',
    label: 'VDMCY,  Vodacom Group Limited',
    name: 'Vodacom Group Limited',
    symbol: 'VDMCY',
  },
  {
    value: 'KIROY',
    label: 'KIROY,  Kumba Iron Ore Limited',
    name: 'Kumba Iron Ore Limited',
    symbol: 'KIROY',
  },
  {
    value: 'SGBLY',
    label: 'SGBLY,  Standard Bank Group Limited',
    name: 'Standard Bank Group Limited',
    symbol: 'SGBLY',
  },
  {
    value: 'MTNOY',
    label: 'MTNOY,  MTN Group Limited',
    name: 'MTN Group Limited',
    symbol: 'MTNOY',
  },
  {
    value: 'IMPUY',
    label: 'IMPUY,  Impala Platinum Holdings Limited',
    name: 'Impala Platinum Holdings Limited',
    symbol: 'IMPUY',
  },
  {
    value: 'SLLDY',
    label: 'SLLDY,  Sanlam Limited',
    name: 'Sanlam Limited',
    symbol: 'SLLDY',
  },
  {
    value: 'AGRPY',
    label: 'AGRPY,  Absa Group Limited',
    name: 'Absa Group Limited',
    symbol: 'AGRPY',
  },
  {
    value: 'SRGHY',
    label: 'SRGHY,  Shoprite Holdings Ltd',
    name: 'Shoprite Holdings Ltd',
    symbol: 'SRGHY',
  },
  {
    value: 'TLKGY',
    label: 'TLKGY,  Telkom SA SOC Limited',
    name: 'Telkom SA SOC Limited',
    symbol: 'TLKGY',
  },
  {
    value: 'NDBKY',
    label: 'NDBKY,  Nedbank Group Limited',
    name: 'Nedbank Group Limited',
    symbol: 'NDBKY',
  },
  {
    value: 'BDVSY',
    label: 'BDVSY,  The Bidvest Group Limited',
    name: 'The Bidvest Group Limited',
    symbol: 'BDVSY',
  },
  {
    value: 'CLCGY',
    label: 'CLCGY,  Clicks Group Limited',
    name: 'Clicks Group Limited',
    symbol: 'CLCGY',
  },
  {
    value: 'MRPLY',
    label: 'MRPLY,  Mr Price Group Limited',
    name: 'Mr Price Group Limited',
    symbol: 'MRPLY',
  },
  {
    value: 'TBLMY',
    label: 'TBLMY,  Tiger Brands Limited',
    name: 'Tiger Brands Limited',
    symbol: 'TBLMY',
  },
  {
    value: 'SPPJY',
    label: 'SPPJY,  Sappi Limited',
    name: 'Sappi Limited',
    symbol: 'SPPJY',
  },
  {
    value: 'MMRTY',
    label: 'MMRTY,  Massmart Holdings Limited',
    name: 'Massmart Holdings Limited',
    symbol: 'MMRTY',
  },
  {
    value: 'PAFRY',
    label: 'PAFRY,  Pan African Resources PLC',
    name: 'Pan African Resources PLC',
    symbol: 'PAFRY',
  },
  {
    value: 'PPCLY',
    label: 'PPCLY,  PPC Ltd',
    name: 'PPC Ltd',
    symbol: 'PPCLY',
  },
  {
    value: 'AMSIY',
    label: 'AMSIY,  ArcelorMittal South Africa Ltd',
    name: 'ArcelorMittal South Africa Ltd',
    symbol: 'AMSIY',
  },
  {
    value: 'NPKLY',
    label: 'NPKLY,  Nampak Limited',
    name: 'Nampak Limited',
    symbol: 'NPKLY',
  },
  {
    value: 'CNTX',
    label: 'CNTX,  Context Therapeutics Inc.',
    name: 'Context Therapeutics Inc.',
    symbol: 'CNTX',
  },
  {
    value: 'MLNK',
    label: 'MLNK,  MeridianLink, Inc.',
    name: 'MeridianLink, Inc.',
    symbol: 'MLNK',
  },
  {
    value: 'DOLE',
    label: 'DOLE,  Dole plc',
    name: 'Dole plc',
    symbol: 'DOLE',
  },
  {
    value: 'DUOL',
    label: 'DUOL,  Duolingo, Inc.',
    name: 'Duolingo, Inc.',
    symbol: 'DUOL',
  },
  {
    value: 'SNPO',
    label: 'SNPO,  Snap One Holdings Corp.',
    name: 'Snap One Holdings Corp.',
    symbol: 'SNPO',
  },
  {
    value: 'HOOD',
    label: 'HOOD,  Robinhood Markets, Inc.',
    name: 'Robinhood Markets, Inc.',
    symbol: 'HOOD',
  },
  {
    value: 'CRLBF',
    label: 'CRLBF,  Cresco Labs Inc.',
    name: 'Cresco Labs Inc.',
    symbol: 'CRLBF',
  },
  {
    value: 'TCNNF',
    label: 'TCNNF,  Trulieve Cannabis Corp.',
    name: 'Trulieve Cannabis Corp.',
    symbol: 'TCNNF',
  },
  {
    value: 'BCTX',
    label: 'BCTX,  BriaCell Therapeutics Corp.',
    name: 'BriaCell Therapeutics Corp.',
    symbol: 'BCTX',
  },
  {
    value: 'BBWI',
    label: 'BBWI,  Bath & Body Works, Inc.',
    name: 'Bath & Body Works, Inc.',
    symbol: 'BBWI',
  },
  {
    value: 'ELYM',
    label: 'ELYM,  Eliem Therapeutics, Inc.',
    name: 'Eliem Therapeutics, Inc.',
    symbol: 'ELYM',
  },
  {
    value: 'NOVVU',
    label: 'NOVVU,  Nova Vision Acquisition Corporation',
    name: 'Nova Vision Acquisition Corporation',
    symbol: 'NOVVU',
  },
  {
    value: 'DWACU',
    label: 'DWACU,  Digital World Acquisition Corp.',
    name: 'Digital World Acquisition Corp.',
    symbol: 'DWACU',
  },
  {
    value: 'MVST',
    label: 'MVST,  Microvast Holdings, Inc.',
    name: 'Microvast Holdings, Inc.',
    symbol: 'MVST',
  },
  {
    value: 'VSCO',
    label: "VSCO,  Victoria's Secret & Co.",
    name: "Victoria's Secret & Co.",
    symbol: 'VSCO',
  },
  {
    value: 'PONOU',
    label: 'PONOU,  Pono Capital Corp.',
    name: 'Pono Capital Corp.',
    symbol: 'PONOU',
  },
  {
    value: 'VCXAU',
    label: 'VCXAU,  10X Capital Venture Acquisition Corp. II',
    name: '10X Capital Venture Acquisition Corp. II',
    symbol: 'VCXAU',
  },
  {
    value: 'TPBAU',
    label: 'TPBAU,  TPB Acquisition Corporation I',
    name: 'TPB Acquisition Corporation I',
    symbol: 'TPBAU',
  },
  {
    value: 'JAQCU',
    label: 'JAQCU,  Jupiter Acquisition Corporation',
    name: 'Jupiter Acquisition Corporation',
    symbol: 'JAQCU',
  },
  {
    value: 'ASPAU',
    label: 'ASPAU,  Abri SPAC I, Inc.',
    name: 'Abri SPAC I, Inc.',
    symbol: 'ASPAU',
  },
  {
    value: 'RNXT',
    label: 'RNXT,  RenovoRx, Inc.',
    name: 'RenovoRx, Inc.',
    symbol: 'RNXT',
  },
  {
    value: 'PPHPU',
    label: 'PPHPU,  PHP Ventures Acquisition Corp.',
    name: 'PHP Ventures Acquisition Corp.',
    symbol: 'PPHPU',
  },
  {
    value: 'KTTA',
    label: 'KTTA,  Pasithea Therapeutics Corp.',
    name: 'Pasithea Therapeutics Corp.',
    symbol: 'KTTA',
  },
  {
    value: 'OXACU',
    label: 'OXACU,  Oxbridge Acquisition Corp.',
    name: 'Oxbridge Acquisition Corp.',
    symbol: 'OXACU',
  },
  {
    value: 'EZFL',
    label: 'EZFL,  EZFill Holdings Inc.',
    name: 'EZFill Holdings Inc.',
    symbol: 'EZFL',
  },
  {
    value: 'DATS',
    label: 'DATS,  DatChat, Inc.',
    name: 'DatChat, Inc.',
    symbol: 'DATS',
  },
  {
    value: '6609.HK',
    label:
      '6609.HK,  Shanghai HeartCare Medical Technology Corporation Limited',
    name: 'Shanghai HeartCare Medical Technology Corporation Limited',
    symbol: '6609.HK',
  },
  {
    value: 'CMPX',
    label: 'CMPX,  Compass Therapeutics, Inc.',
    name: 'Compass Therapeutics, Inc.',
    symbol: 'CMPX',
  },
  {
    value: 'ECV.F',
    label: 'ECV.F,  Encavis AG',
    name: 'Encavis AG',
    symbol: 'ECV.F',
  },
  {
    value: 'TVTX',
    label: 'TVTX,  Travere Therapeutics, Inc.',
    name: 'Travere Therapeutics, Inc.',
    symbol: 'TVTX',
  },
  {
    value: 'DIBS',
    label: 'DIBS,  1stdibs.Com, Inc.',
    name: '1stdibs.Com, Inc.',
    symbol: 'DIBS',
  },
  {
    value: 'TIXT',
    label: 'TIXT,  TELUS International (Cda) Inc.',
    name: 'TELUS International (Cda) Inc.',
    symbol: 'TIXT',
  },
  {
    value: 'CLVT',
    label: 'CLVT,  Clarivate Plc',
    name: 'Clarivate Plc',
    symbol: 'CLVT',
  },
  {
    value: 'DTM',
    label: 'DTM,  DT Midstream, Inc.',
    name: 'DT Midstream, Inc.',
    symbol: 'DTM',
  },
  {
    value: 'DBRG',
    label: 'DBRG,  DigitalBridge Group, Inc.',
    name: 'DigitalBridge Group, Inc.',
    symbol: 'DBRG',
  },
  {
    value: 'MNTV',
    label: 'MNTV,  Momentive Global Inc.',
    name: 'Momentive Global Inc.',
    symbol: 'MNTV',
  },
  {
    value: 'BALY',
    label: "BALY,  Bally's Corporation",
    name: "Bally's Corporation",
    symbol: 'BALY',
  },
  {
    value: 'UTZ',
    label: 'UTZ,  Utz Brands, Inc.',
    name: 'Utz Brands, Inc.',
    symbol: 'UTZ',
  },
  {
    value: 'DNMR',
    label: 'DNMR,  Danimer Scientific, Inc.',
    name: 'Danimer Scientific, Inc.',
    symbol: 'DNMR',
  },
  {
    value: 'RADI',
    label: 'RADI,  Radius Global Infrastructure, Inc.',
    name: 'Radius Global Infrastructure, Inc.',
    symbol: 'RADI',
  },
  {
    value: 'GIC',
    label: 'GIC,  Global Industrial Company',
    name: 'Global Industrial Company',
    symbol: 'GIC',
  },
  {
    value: 'NABL',
    label: 'NABL,  N-able, Inc.',
    name: 'N-able, Inc.',
    symbol: 'NABL',
  },
  {
    value: 'ONTF',
    label: 'ONTF,  ON24, Inc.',
    name: 'ON24, Inc.',
    symbol: 'ONTF',
  },
  {
    value: 'PRCH',
    label: 'PRCH,  Porch Group, Inc.',
    name: 'Porch Group, Inc.',
    symbol: 'PRCH',
  },
  {
    value: 'FDMT',
    label: 'FDMT,  4D Molecular Therapeutics, Inc.',
    name: '4D Molecular Therapeutics, Inc.',
    symbol: 'FDMT',
  },
  {
    value: 'BVH',
    label: 'BVH,  Bluegreen Vacations Holding Corporation',
    name: 'Bluegreen Vacations Holding Corporation',
    symbol: 'BVH',
  },
  {
    value: 'RYAN',
    label: 'RYAN,  Ryan Specialty Holdings, Inc.',
    name: 'Ryan Specialty Holdings, Inc.',
    symbol: 'RYAN',
  },
  {
    value: 'FOA',
    label: 'FOA,  Finance Of America Companies Inc.',
    name: 'Finance Of America Companies Inc.',
    symbol: 'FOA',
  },
  {
    value: 'CLNN',
    label: 'CLNN,  Clene Inc.',
    name: 'Clene Inc.',
    symbol: 'CLNN',
  },
  {
    value: 'FORA',
    label: 'FORA,  Forian Inc.',
    name: 'Forian Inc.',
    symbol: 'FORA',
  },
  {
    value: 'ARKO',
    label: 'ARKO,  Arko Corp.',
    name: 'Arko Corp.',
    symbol: 'ARKO',
  },
  {
    value: 'VOR',
    label: 'VOR,  Vor Biopharma Inc.',
    name: 'Vor Biopharma Inc.',
    symbol: 'VOR',
  },
  {
    value: 'BOLT',
    label: 'BOLT,  Bolt Biotherapeutics, Inc.',
    name: 'Bolt Biotherapeutics, Inc.',
    symbol: 'BOLT',
  },
  {
    value: 'TALS',
    label: 'TALS,  Talaris Therapeutics, Inc.',
    name: 'Talaris Therapeutics, Inc.',
    symbol: 'TALS',
  },
  {
    value: 'ANGN',
    label: 'ANGN,  Angion Biomedica Corp.',
    name: 'Angion Biomedica Corp.',
    symbol: 'ANGN',
  },
  {
    value: 'MDVL',
    label: 'MDVL,  MedAvail Holdings, Inc.',
    name: 'MedAvail Holdings, Inc.',
    symbol: 'MDVL',
  },
  {
    value: 'GMTX',
    label: 'GMTX,  Gemini Therapeutics, Inc.',
    name: 'Gemini Therapeutics, Inc.',
    symbol: 'GMTX',
  },
  {
    value: 'PWSC',
    label: 'PWSC,  PowerSchool Holdings, Inc.',
    name: 'PowerSchool Holdings, Inc.',
    symbol: 'PWSC',
  },
  {
    value: 'FIGS',
    label: 'FIGS,  FIGS, Inc.',
    name: 'FIGS, Inc.',
    symbol: 'FIGS',
  },
  {
    value: 'AOMR',
    label: 'AOMR,  Angel Oak Mortgage, Inc.',
    name: 'Angel Oak Mortgage, Inc.',
    symbol: 'AOMR',
  },
  {
    value: 'VERV',
    label: 'VERV,  Verve Therapeutics, Inc.',
    name: 'Verve Therapeutics, Inc.',
    symbol: 'VERV',
  },
  {
    value: 'TERN',
    label: 'TERN,  Terns Pharmaceuticals, Inc.',
    name: 'Terns Pharmaceuticals, Inc.',
    symbol: 'TERN',
  },
  {
    value: 'COOK',
    label: 'COOK,  Traeger, Inc.',
    name: 'Traeger, Inc.',
    symbol: 'COOK',
  },
  {
    value: 'YOU',
    label: 'YOU,  Clear Secure, Inc.',
    name: 'Clear Secure, Inc.',
    symbol: 'YOU',
  },
  {
    value: 'DNUT',
    label: 'DNUT,  Krispy Kreme, Inc.',
    name: 'Krispy Kreme, Inc.',
    symbol: 'DNUT',
  },
  {
    value: 'PRTG',
    label: 'PRTG,  Portage Biotech Inc.',
    name: 'Portage Biotech Inc.',
    symbol: 'PRTG',
  },
  {
    value: 'TASK',
    label: 'TASK,  TaskUs, Inc.',
    name: 'TaskUs, Inc.',
    symbol: 'TASK',
  },
  {
    value: 'INAB',
    label: 'INAB,  IN8bio, Inc.',
    name: 'IN8bio, Inc.',
    symbol: 'INAB',
  },
  {
    value: 'RXST',
    label: 'RXST,  RxSight, Inc.',
    name: 'RxSight, Inc.',
    symbol: 'RXST',
  },
  {
    value: 'NUVL',
    label: 'NUVL,  Nuvalent, Inc.',
    name: 'Nuvalent, Inc.',
    symbol: 'NUVL',
  },
  {
    value: 'DAWN',
    label: 'DAWN,  Day One Biopharmaceuticals, Inc.',
    name: 'Day One Biopharmaceuticals, Inc.',
    symbol: 'DAWN',
  },
  {
    value: 'IMRX',
    label: 'IMRX,  Immuneering Corporation',
    name: 'Immuneering Corporation',
    symbol: 'IMRX',
  },
  {
    value: 'INTA',
    label: 'INTA,  Intapp, Inc.',
    name: 'Intapp, Inc.',
    symbol: 'INTA',
  },
  {
    value: 'LFST',
    label: 'LFST,  LifeStance Health Group, Inc.',
    name: 'LifeStance Health Group, Inc.',
    symbol: 'LFST',
  },
  {
    value: 'TCRX',
    label: 'TCRX,  TScan Therapeutics, Inc.',
    name: 'TScan Therapeutics, Inc.',
    symbol: 'TCRX',
  },
  {
    value: 'CYT',
    label: 'CYT,  Cyteir Therapeutics, Inc.',
    name: 'Cyteir Therapeutics, Inc.',
    symbol: 'CYT',
  },
  {
    value: 'TNYA',
    label: 'TNYA,  Tenaya Therapeutics, Inc.',
    name: 'Tenaya Therapeutics, Inc.',
    symbol: 'TNYA',
  },
  {
    value: 'CVRX',
    label: 'CVRX,  CVRx, Inc.',
    name: 'CVRx, Inc.',
    symbol: 'CVRX',
  },
  {
    value: 'SERA',
    label: 'SERA,  Sera Prognostics, Inc.',
    name: 'Sera Prognostics, Inc.',
    symbol: 'SERA',
  },
  {
    value: 'OMGA',
    label: 'OMGA,  Omega Therapeutics, Inc.',
    name: 'Omega Therapeutics, Inc.',
    symbol: 'OMGA',
  },
  {
    value: 'LEX.L',
    label: 'LEX.L,  Lexington Gold Ltd',
    name: 'Lexington Gold Ltd',
    symbol: 'LEX.L',
  },
  {
    value: 'IRNT',
    label: 'IRNT,  IronNet, Inc.',
    name: 'IronNet, Inc.',
    symbol: 'IRNT',
  },
  {
    value: 'MMAT',
    label: 'MMAT,  Meta Materials Inc.',
    name: 'Meta Materials Inc.',
    symbol: 'MMAT',
  },
  {
    value: 'ABDX.L',
    label: 'ABDX.L,  Abingdon Health Plc',
    name: 'Abingdon Health Plc',
    symbol: 'ABDX.L',
  },
  {
    value: 'ZPHR.L',
    label: 'ZPHR.L,  Zephyr Energy plc',
    name: 'Zephyr Energy plc',
    symbol: 'ZPHR.L',
  },
  {
    value: 'CYTH',
    label: 'CYTH,  Cyclo Therapeutics, Inc.',
    name: 'Cyclo Therapeutics, Inc.',
    symbol: 'CYTH',
  },
  {
    value: 'EFTR',
    label: 'EFTR,  eFFECTOR Therapeutics, Inc.',
    name: 'eFFECTOR Therapeutics, Inc.',
    symbol: 'EFTR',
  },
  {
    value: 'LHDX',
    label: 'LHDX,  Lucira Health, Inc.',
    name: 'Lucira Health, Inc.',
    symbol: 'LHDX',
  },
  {
    value: 'FHLTU',
    label: 'FHLTU,  Future Health ESG Corp.',
    name: 'Future Health ESG Corp.',
    symbol: 'FHLTU',
  },
  {
    value: 'SLVRU',
    label: 'SLVRU,  SILVERspac Inc.',
    name: 'SILVERspac Inc.',
    symbol: 'SLVRU',
  },
  {
    value: 'PTRA',
    label: 'PTRA,  Proterra Inc.',
    name: 'Proterra Inc.',
    symbol: 'PTRA',
  },
  {
    value: 'SRAD',
    label: 'SRAD,  Sportradar Group AG',
    name: 'Sportradar Group AG',
    symbol: 'SRAD',
  },
  {
    value: 'PAFOU',
    label: 'PAFOU,  Pacifico Acquisition Corp.',
    name: 'Pacifico Acquisition Corp.',
    symbol: 'PAFOU',
  },
  {
    value: '000001.SZ',
    label: '000001.SZ,  Ping An Bank Co., Ltd.',
    name: 'Ping An Bank Co., Ltd.',
    symbol: '000001.SZ',
  },
  {
    value: '000002.SZ',
    label: '000002.SZ,  China Vanke Co., Ltd.',
    name: 'China Vanke Co., Ltd.',
    symbol: '000002.SZ',
  },
  {
    value: '000009.SZ',
    label: '000009.SZ,  China Baoan Group Co., Ltd.',
    name: 'China Baoan Group Co., Ltd.',
    symbol: '000009.SZ',
  },
  {
    value: '000021.SZ',
    label: '000021.SZ,  Shenzhen Kaifa Technology Co., Ltd.',
    name: 'Shenzhen Kaifa Technology Co., Ltd.',
    symbol: '000021.SZ',
  },
  {
    value: '000027.SZ',
    label: '000027.SZ,  Shenzhen Energy Group Co., Ltd.',
    name: 'Shenzhen Energy Group Co., Ltd.',
    symbol: '000027.SZ',
  },
  {
    value: '000030.SZ',
    label: '000030.SZ,  FAWER Automotive Parts Limited Company',
    name: 'FAWER Automotive Parts Limited Company',
    symbol: '000030.SZ',
  },
  {
    value: '000050.SZ',
    label: '000050.SZ,  Tianma Microelectronics Co., Ltd.',
    name: 'Tianma Microelectronics Co., Ltd.',
    symbol: '000050.SZ',
  },
  {
    value: '000060.KS',
    label: '000060.KS,  Meritz Fire & Marine Insurance Co., Ltd.',
    name: 'Meritz Fire & Marine Insurance Co., Ltd.',
    symbol: '000060.KS',
  },
  {
    value: '000063.SZ',
    label: '000063.SZ,  ZTE Corporation',
    name: 'ZTE Corporation',
    symbol: '000063.SZ',
  },
  {
    value: '000066.SZ',
    label: '000066.SZ,  China Greatwall Technology Group Co., Ltd.',
    name: 'China Greatwall Technology Group Co., Ltd.',
    symbol: '000066.SZ',
  },
  {
    value: '000069.SZ',
    label: '000069.SZ,  Shenzhen Overseas Chinese Town Co.,Ltd.',
    name: 'Shenzhen Overseas Chinese Town Co.,Ltd.',
    symbol: '000069.SZ',
  },
  {
    value: '000070.KS',
    label: '000070.KS,  Samyang Holdings Corporation',
    name: 'Samyang Holdings Corporation',
    symbol: '000070.KS',
  },
  {
    value: '000078.SZ',
    label: '000078.SZ,  Shenzhen Neptunus Bioengineering Co., Ltd.',
    name: 'Shenzhen Neptunus Bioengineering Co., Ltd.',
    symbol: '000078.SZ',
  },
  {
    value: '000080.KS',
    label: '000080.KS,  HITEJINRO Co., Ltd.',
    name: 'HITEJINRO Co., Ltd.',
    symbol: '000080.KS',
  },
  {
    value: '000089.SZ',
    label: '000089.SZ,  Shenzhen Airport Co., Ltd.',
    name: 'Shenzhen Airport Co., Ltd.',
    symbol: '000089.SZ',
  },
  {
    value: '000090.SZ',
    label: '000090.SZ,  Shenzhen Tagen Group Co., Ltd.',
    name: 'Shenzhen Tagen Group Co., Ltd.',
    symbol: '000090.SZ',
  },
  {
    value: '000100.KS',
    label: '000100.KS,  Yuhan Corporation',
    name: 'Yuhan Corporation',
    symbol: '000100.KS',
  },
  {
    value: '000100.SZ',
    label: '000100.SZ,  TCL Technology Group Corporation',
    name: 'TCL Technology Group Corporation',
    symbol: '000100.SZ',
  },
  {
    value: '000120.KS',
    label: '000120.KS,  CJ Logistics Corporation',
    name: 'CJ Logistics Corporation',
    symbol: '000120.KS',
  },
  {
    value: '000140.KS',
    label: '000140.KS,  Hitejinro Holdings Co., Ltd.',
    name: 'Hitejinro Holdings Co., Ltd.',
    symbol: '000140.KS',
  },
  {
    value: '000156.SZ',
    label: '000156.SZ,  Wasu Media Holding Co.,Ltd',
    name: 'Wasu Media Holding Co.,Ltd',
    symbol: '000156.SZ',
  },
  {
    value: '000157.SZ',
    label:
      '000157.SZ,  Zoomlion Heavy Industry Science and Technology Co., Ltd.',
    name: 'Zoomlion Heavy Industry Science and Technology Co., Ltd.',
    symbol: '000157.SZ',
  },
  {
    value: '000166.SZ',
    label: '000166.SZ,  Shenwan Hongyuan Group Co., Ltd.',
    name: 'Shenwan Hongyuan Group Co., Ltd.',
    symbol: '000166.SZ',
  },
  {
    value: '000210.KS',
    label: '000210.KS,  DL Holdings CO., LTD.',
    name: 'DL Holdings CO., LTD.',
    symbol: '000210.KS',
  },
  {
    value: '000240.KS',
    label: '000240.KS,  Hankook & Company Co., Ltd.',
    name: 'Hankook & Company Co., Ltd.',
    symbol: '000240.KS',
  },
  {
    value: '000270.KS',
    label: '000270.KS,  Kia Corporation',
    name: 'Kia Corporation',
    symbol: '000270.KS',
  },
  {
    value: '000301.SZ',
    label: '000301.SZ,  Jiangsu Eastern Shenghong Co., Ltd.',
    name: 'Jiangsu Eastern Shenghong Co., Ltd.',
    symbol: '000301.SZ',
  },
  {
    value: '000333.SZ',
    label: '000333.SZ,  Midea Group Co., Ltd.',
    name: 'Midea Group Co., Ltd.',
    symbol: '000333.SZ',
  },
  {
    value: '000338.SZ',
    label: '000338.SZ,  Weichai Power Co., Ltd.',
    name: 'Weichai Power Co., Ltd.',
    symbol: '000338.SZ',
  },
  {
    value: '000370.KS',
    label: '000370.KS,  Hanwha General Insurance Co., Ltd.',
    name: 'Hanwha General Insurance Co., Ltd.',
    symbol: '000370.KS',
  },
  {
    value: '000401.SZ',
    label: '000401.SZ,  Tangshan Jidong Cement Co.,Ltd.',
    name: 'Tangshan Jidong Cement Co.,Ltd.',
    symbol: '000401.SZ',
  },
  {
    value: '000402.SZ',
    label: '000402.SZ,  Financial Street Holdings Co., Ltd.',
    name: 'Financial Street Holdings Co., Ltd.',
    symbol: '000402.SZ',
  },
  {
    value: '000423.SZ',
    label: '000423.SZ,  Dong-E-E-Jiao Co.,Ltd.',
    name: 'Dong-E-E-Jiao Co.,Ltd.',
    symbol: '000423.SZ',
  },
  {
    value: '000425.SZ',
    label: '000425.SZ,  XCMG Construction Machinery Co., Ltd.',
    name: 'XCMG Construction Machinery Co., Ltd.',
    symbol: '000425.SZ',
  },
  {
    value: '000501.SZ',
    label: '000501.SZ,  Wushang Group Co., Ltd.',
    name: 'Wushang Group Co., Ltd.',
    symbol: '000501.SZ',
  },
  {
    value: '000513.SZ',
    label: '000513.SZ,  Livzon Pharmaceutical Group Inc.',
    name: 'Livzon Pharmaceutical Group Inc.',
    symbol: '000513.SZ',
  },
  {
    value: '000537.SZ',
    label:
      '000537.SZ,  China Green Electricity Investment of Tianjin Co., Ltd.',
    name: 'China Green Electricity Investment of Tianjin Co., Ltd.',
    symbol: '000537.SZ',
  },
  {
    value: '000538.SZ',
    label: '000538.SZ,  Yunnan Baiyao Group Co.,Ltd',
    name: 'Yunnan Baiyao Group Co.,Ltd',
    symbol: '000538.SZ',
  },
  {
    value: '000540.SZ',
    label: '000540.SZ,  Zhongtian Financial Group Company Limited',
    name: 'Zhongtian Financial Group Company Limited',
    symbol: '000540.SZ',
  },
  {
    value: '000544.SZ',
    label: '000544.SZ,  Central Plains Environment Protection Co.,Ltd.',
    name: 'Central Plains Environment Protection Co.,Ltd.',
    symbol: '000544.SZ',
  },
  {
    value: '000547.SZ',
    label: '000547.SZ,  Addsino Co., Ltd.',
    name: 'Addsino Co., Ltd.',
    symbol: '000547.SZ',
  },
  {
    value: '000568.SZ',
    label: '000568.SZ,  Luzhou Laojiao Co.,Ltd.',
    name: 'Luzhou Laojiao Co.,Ltd.',
    symbol: '000568.SZ',
  },
  {
    value: '000581.SZ',
    label: '000581.SZ,  Weifu High-Technology Group Co., Ltd.',
    name: 'Weifu High-Technology Group Co., Ltd.',
    symbol: '000581.SZ',
  },
  {
    value: '000591.SZ',
    label: '000591.SZ,  CECEP Solar Energy Co.,Ltd.',
    name: 'CECEP Solar Energy Co.,Ltd.',
    symbol: '000591.SZ',
  },
  {
    value: '000596.SZ',
    label: '000596.SZ,  Anhui Gujing Distillery Co., Ltd.',
    name: 'Anhui Gujing Distillery Co., Ltd.',
    symbol: '000596.SZ',
  },
  {
    value: '000598.SZ',
    label: '000598.SZ,  Chengdu Xingrong Environment Co., Ltd.',
    name: 'Chengdu Xingrong Environment Co., Ltd.',
    symbol: '000598.SZ',
  },
  {
    value: '000601.SZ',
    label: '000601.SZ,  GuangDong ShaoNeng Group Co., Ltd.',
    name: 'GuangDong ShaoNeng Group Co., Ltd.',
    symbol: '000601.SZ',
  },
  {
    value: '000617.SZ',
    label: '000617.SZ,  CNPC Capital Company Limited',
    name: 'CNPC Capital Company Limited',
    symbol: '000617.SZ',
  },
  {
    value: '000623.SZ',
    label: '000623.SZ,  Jilin Aodong Pharmaceutical Group Co., Ltd.',
    name: 'Jilin Aodong Pharmaceutical Group Co., Ltd.',
    symbol: '000623.SZ',
  },
  {
    value: '000625.SZ',
    label: '000625.SZ,  Chongqing Changan Automobile Company Limited',
    name: 'Chongqing Changan Automobile Company Limited',
    symbol: '000625.SZ',
  },
  {
    value: '000627.SZ',
    label: '000627.SZ,  Hubei Biocause Pharmaceutical Co., Ltd.',
    name: 'Hubei Biocause Pharmaceutical Co., Ltd.',
    symbol: '000627.SZ',
  },
  {
    value: '000629.SZ',
    label: '000629.SZ,  Pangang Group Vanadium & Titanium Resources Co., Ltd.',
    name: 'Pangang Group Vanadium & Titanium Resources Co., Ltd.',
    symbol: '000629.SZ',
  },
  {
    value: '000630.SZ',
    label: '000630.SZ,  Tongling Nonferrous Metals Group Co.,Ltd.',
    name: 'Tongling Nonferrous Metals Group Co.,Ltd.',
    symbol: '000630.SZ',
  },
  {
    value: '000640.KS',
    label: '000640.KS,  Dong-A Socio Holdings Co., Ltd.',
    name: 'Dong-A Socio Holdings Co., Ltd.',
    symbol: '000640.KS',
  },
  {
    value: '000651.SZ',
    label: '000651.SZ,  Gree Electric Appliances, Inc. of Zhuhai',
    name: 'Gree Electric Appliances, Inc. of Zhuhai',
    symbol: '000651.SZ',
  },
  {
    value: '000656.SZ',
    label: '000656.SZ,  Jinke Property Group Co., Ltd.',
    name: 'Jinke Property Group Co., Ltd.',
    symbol: '000656.SZ',
  },
  {
    value: '000660.KS',
    label: '000660.KS,  SK hynix Inc.',
    name: 'SK hynix Inc.',
    symbol: '000660.KS',
  },
  {
    value: '000661.SZ',
    label: '000661.SZ,  Changchun High-Tech Industries (Group) Inc.',
    name: 'Changchun High-Tech Industries (Group) Inc.',
    symbol: '000661.SZ',
  },
  {
    value: '000670.KS',
    label: '000670.KS,  Young Poong Corporation',
    name: 'Young Poong Corporation',
    symbol: '000670.KS',
  },
  {
    value: '000671.SZ',
    label: '000671.SZ,  Yango Group Co., Ltd',
    name: 'Yango Group Co., Ltd',
    symbol: '000671.SZ',
  },
  {
    value: '000685.SZ',
    label: '000685.SZ,  Zhongshan Public Utilities Group Co.,Ltd.',
    name: 'Zhongshan Public Utilities Group Co.,Ltd.',
    symbol: '000685.SZ',
  },
  {
    value: '000686.SZ',
    label: '000686.SZ,  Northeast Securities Co., Ltd.',
    name: 'Northeast Securities Co., Ltd.',
    symbol: '000686.SZ',
  },
  {
    value: '000690.SZ',
    label: '000690.SZ,  Guangdong Baolihua New Energy Stock Co., Ltd.',
    name: 'Guangdong Baolihua New Energy Stock Co., Ltd.',
    symbol: '000690.SZ',
  },
  {
    value: '000703.SZ',
    label: '000703.SZ,  Hengyi Petrochemical Co., Ltd.',
    name: 'Hengyi Petrochemical Co., Ltd.',
    symbol: '000703.SZ',
  },
  {
    value: '000708.SZ',
    label: '000708.SZ,  Citic Pacific Special Steel Group Co., Ltd.',
    name: 'Citic Pacific Special Steel Group Co., Ltd.',
    symbol: '000708.SZ',
  },
  {
    value: '000709.SZ',
    label: '000709.SZ,  Hbis Company Limited',
    name: 'Hbis Company Limited',
    symbol: '000709.SZ',
  },
  {
    value: '000710.SZ',
    label: '000710.SZ,  Berry Genomics Co.,Ltd',
    name: 'Berry Genomics Co.,Ltd',
    symbol: '000710.SZ',
  },
  {
    value: '000720.KS',
    label: '000720.KS,  Hyundai Engineering & Construction Co., Ltd.',
    name: 'Hyundai Engineering & Construction Co., Ltd.',
    symbol: '000720.KS',
  },
  {
    value: '000723.SZ',
    label: '000723.SZ,  Shanxi Meijin Energy Co.,Ltd.',
    name: 'Shanxi Meijin Energy Co.,Ltd.',
    symbol: '000723.SZ',
  },
  {
    value: '000725.SZ',
    label: '000725.SZ,  BOE Technology Group Company Limited',
    name: 'BOE Technology Group Company Limited',
    symbol: '000725.SZ',
  },
  {
    value: '000728.SZ',
    label: '000728.SZ,  Guoyuan Securities Company Limited',
    name: 'Guoyuan Securities Company Limited',
    symbol: '000728.SZ',
  },
  {
    value: '000729.SZ',
    label: '000729.SZ,  Beijing Yanjing Brewery Co.,Ltd.',
    name: 'Beijing Yanjing Brewery Co.,Ltd.',
    symbol: '000729.SZ',
  },
  {
    value: '000738.SZ',
    label: '000738.SZ,  Aecc Aero-Engine Control Co.,Ltd.',
    name: 'Aecc Aero-Engine Control Co.,Ltd.',
    symbol: '000738.SZ',
  },
  {
    value: '000739.SZ',
    label: '000739.SZ,  Apeloa Pharmaceutical Co.,Ltd',
    name: 'Apeloa Pharmaceutical Co.,Ltd',
    symbol: '000739.SZ',
  },
  {
    value: '000750.SZ',
    label: '000750.SZ,  Sealand Securities Co., Ltd.',
    name: 'Sealand Securities Co., Ltd.',
    symbol: '000750.SZ',
  },
  {
    value: '000776.SZ',
    label: '000776.SZ,  GF Securities Co., Ltd.',
    name: 'GF Securities Co., Ltd.',
    symbol: '000776.SZ',
  },
  {
    value: '000778.SZ',
    label: '000778.SZ,  Xinxing Ductile Iron Pipes Co., Ltd.',
    name: 'Xinxing Ductile Iron Pipes Co., Ltd.',
    symbol: '000778.SZ',
  },
  {
    value: '000783.SZ',
    label: '000783.SZ,  Changjiang Securities Company Limited',
    name: 'Changjiang Securities Company Limited',
    symbol: '000783.SZ',
  },
  {
    value: '000786.SZ',
    label: '000786.SZ,  Beijing New Building Materials Public Limited Company',
    name: 'Beijing New Building Materials Public Limited Company',
    symbol: '000786.SZ',
  },
  {
    value: '000789.SZ',
    label: '000789.SZ,  Jiangxi Wannianqing Cement Co., Ltd.',
    name: 'Jiangxi Wannianqing Cement Co., Ltd.',
    symbol: '000789.SZ',
  },
  {
    value: '000799.SZ',
    label: '000799.SZ,  Jiugui Liquor Co., Ltd.',
    name: 'Jiugui Liquor Co., Ltd.',
    symbol: '000799.SZ',
  },
  {
    value: '000800.SZ',
    label: '000800.SZ,  FAW Jiefang Group Co., Ltd',
    name: 'FAW Jiefang Group Co., Ltd',
    symbol: '000800.SZ',
  },
  {
    value: '000807.SZ',
    label: '000807.SZ,  Yunnan Aluminium Co., Ltd.',
    name: 'Yunnan Aluminium Co., Ltd.',
    symbol: '000807.SZ',
  },
  {
    value: '000810.KS',
    label: '000810.KS,  Samsung Fire & Marine Insurance Co., Ltd.',
    name: 'Samsung Fire & Marine Insurance Co., Ltd.',
    symbol: '000810.KS',
  },
  {
    value: '000825.SZ',
    label: '000825.SZ,  Shanxi Taigang Stainless Steel Co., Ltd.',
    name: 'Shanxi Taigang Stainless Steel Co., Ltd.',
    symbol: '000825.SZ',
  },
  {
    value: '000830.SZ',
    label: '000830.SZ,  Luxi Chemical Group Co., Ltd.',
    name: 'Luxi Chemical Group Co., Ltd.',
    symbol: '000830.SZ',
  },
  {
    value: '000858.SZ',
    label: '000858.SZ,  Wuliangye Yibin Co.,Ltd.',
    name: 'Wuliangye Yibin Co.,Ltd.',
    symbol: '000858.SZ',
  },
  {
    value: '000860.SZ',
    label: '000860.SZ,  Beijing Shunxin Agriculture Co.,Ltd',
    name: 'Beijing Shunxin Agriculture Co.,Ltd',
    symbol: '000860.SZ',
  },
  {
    value: '000876.SZ',
    label: '000876.SZ,  New Hope Liuhe Co.,Ltd.',
    name: 'New Hope Liuhe Co.,Ltd.',
    symbol: '000876.SZ',
  },
  {
    value: '000878.SZ',
    label: '000878.SZ,  Yunnan Copper Co.,Ltd',
    name: 'Yunnan Copper Co.,Ltd',
    symbol: '000878.SZ',
  },
  {
    value: '000880.KS',
    label: '000880.KS,  Hanwha Corporation',
    name: 'Hanwha Corporation',
    symbol: '000880.KS',
  },
  {
    value: '000883.SZ',
    label: '000883.SZ,  Hubei Energy Group Co., Ltd.',
    name: 'Hubei Energy Group Co., Ltd.',
    symbol: '000883.SZ',
  },
  {
    value: '000887.SZ',
    label: '000887.SZ,  Anhui Zhongding Sealing Parts Co., Ltd.',
    name: 'Anhui Zhongding Sealing Parts Co., Ltd.',
    symbol: '000887.SZ',
  },
  {
    value: '00088K.KS',
    label: '00088K.KS,  Hanwha Corporation',
    name: 'Hanwha Corporation',
    symbol: '00088K.KS',
  },
  {
    value: '000895.SZ',
    label: '000895.SZ,  Henan Shuanghui Investment & Development Co.,Ltd.',
    name: 'Henan Shuanghui Investment & Development Co.,Ltd.',
    symbol: '000895.SZ',
  },
  {
    value: '000921.SZ',
    label: '000921.SZ,  Hisense Home Appliances Group Co., Ltd.',
    name: 'Hisense Home Appliances Group Co., Ltd.',
    symbol: '000921.SZ',
  },
  {
    value: '000932.SZ',
    label: '000932.SZ,  Hunan Valin Steel Co., Ltd.',
    name: 'Hunan Valin Steel Co., Ltd.',
    symbol: '000932.SZ',
  },
  {
    value: '000937.SZ',
    label: '000937.SZ,  Jizhong Energy Resources Co., Ltd.',
    name: 'Jizhong Energy Resources Co., Ltd.',
    symbol: '000937.SZ',
  },
  {
    value: '000938.SZ',
    label: '000938.SZ,  Unisplendour Corporation Limited',
    name: 'Unisplendour Corporation Limited',
    symbol: '000938.SZ',
  },
  {
    value: '000951.SZ',
    label: '000951.SZ,  Sinotruk Jinan Truck Co., Ltd.',
    name: 'Sinotruk Jinan Truck Co., Ltd.',
    symbol: '000951.SZ',
  },
  {
    value: '000958.SZ',
    label: '000958.SZ,  SPIC Industry-Finance Holdings Co., Ltd.',
    name: 'SPIC Industry-Finance Holdings Co., Ltd.',
    symbol: '000958.SZ',
  },
  {
    value: '000959.SZ',
    label: '000959.SZ,  Beijing Shougang Co., Ltd.',
    name: 'Beijing Shougang Co., Ltd.',
    symbol: '000959.SZ',
  },
  {
    value: '000961.SZ',
    label: '000961.SZ,  Jiangsu Zhongnan Construction Group Co., Ltd.',
    name: 'Jiangsu Zhongnan Construction Group Co., Ltd.',
    symbol: '000961.SZ',
  },
  {
    value: '000963.SZ',
    label: '000963.SZ,  Huadong Medicine Co., Ltd',
    name: 'Huadong Medicine Co., Ltd',
    symbol: '000963.SZ',
  },
  {
    value: '000967.SZ',
    label: '000967.SZ,  Infore Environment Technology Group Co., Ltd.',
    name: 'Infore Environment Technology Group Co., Ltd.',
    symbol: '000967.SZ',
  },
  {
    value: '000975.SZ',
    label: '000975.SZ,  Yintai Gold Co., Ltd.',
    name: 'Yintai Gold Co., Ltd.',
    symbol: '000975.SZ',
  },
  {
    value: '000977.SZ',
    label: '000977.SZ,  Inspur Electronic Information Industry Co., Ltd.',
    name: 'Inspur Electronic Information Industry Co., Ltd.',
    symbol: '000977.SZ',
  },
  {
    value: '000983.SZ',
    label: '000983.SZ,  Shanxi Coking Coal Energy Group Co.,Ltd.',
    name: 'Shanxi Coking Coal Energy Group Co.,Ltd.',
    symbol: '000983.SZ',
  },
  {
    value: '000987.SZ',
    label: '000987.SZ,  Guangzhou Yuexiu Financial Holdings Group Co., Ltd.',
    name: 'Guangzhou Yuexiu Financial Holdings Group Co., Ltd.',
    symbol: '000987.SZ',
  },
  {
    value: '000988.SZ',
    label: '000988.SZ,  Huagong Tech Company Limited',
    name: 'Huagong Tech Company Limited',
    symbol: '000988.SZ',
  },
  {
    value: '000990.KS',
    label: '000990.KS,  DB HiTek CO., LTD.',
    name: 'DB HiTek CO., LTD.',
    symbol: '000990.KS',
  },
  {
    value: '000998.SZ',
    label: '000998.SZ,  Yuan Long Ping High-Tech Agriculture Co., Ltd.',
    name: 'Yuan Long Ping High-Tech Agriculture Co., Ltd.',
    symbol: '000998.SZ',
  },
  {
    value: '000999.SZ',
    label:
      '000999.SZ,  China Resources Sanjiu Medical & Pharmaceutical Co., Ltd.',
    name: 'China Resources Sanjiu Medical & Pharmaceutical Co., Ltd.',
    symbol: '000999.SZ',
  },
  {
    value: '001040.KS',
    label: '001040.KS,  CJ Corporation',
    name: 'CJ Corporation',
    symbol: '001040.KS',
  },
  {
    value: '001060.KS',
    label: '001060.KS,  JW Pharmaceutical Corporation',
    name: 'JW Pharmaceutical Corporation',
    symbol: '001060.KS',
  },
  {
    value: '001120.KS',
    label: '001120.KS,  LX International Corp.',
    name: 'LX International Corp.',
    symbol: '001120.KS',
  },
  {
    value: '001200.KS',
    label: '001200.KS,  Eugene Investment & Securities Co.,Ltd.',
    name: 'Eugene Investment & Securities Co.,Ltd.',
    symbol: '001200.KS',
  },
  {
    value: '001230.KS',
    label: '001230.KS,  Dongkuk Steel Mill Company Limited',
    name: 'Dongkuk Steel Mill Company Limited',
    symbol: '001230.KS',
  },
  {
    value: '001360.KS',
    label: '001360.KS,  SAMSUNG PHARM. Co., LTD.',
    name: 'SAMSUNG PHARM. Co., LTD.',
    symbol: '001360.KS',
  },
  {
    value: '001430.KS',
    label: '001430.KS,  SeAH Besteel Holdings Corporation',
    name: 'SeAH Besteel Holdings Corporation',
    symbol: '001430.KS',
  },
  {
    value: '001440.KS',
    label: '001440.KS,  Taihan Cable & Solution Co., Ltd.',
    name: 'Taihan Cable & Solution Co., Ltd.',
    symbol: '001440.KS',
  },
  {
    value: '001450.KS',
    label: '001450.KS,  Hyundai Marine & Fire Insurance Co., Ltd.',
    name: 'Hyundai Marine & Fire Insurance Co., Ltd.',
    symbol: '001450.KS',
  },
  {
    value: '001500.KS',
    label: '001500.KS,  Hyundai Motor Securities Co., Ltd.',
    name: 'Hyundai Motor Securities Co., Ltd.',
    symbol: '001500.KS',
  },
  {
    value: '001510.KS',
    label: '001510.KS,  SK Securities Co., Ltd.',
    name: 'SK Securities Co., Ltd.',
    symbol: '001510.KS',
  },
  {
    value: '001520.KS',
    label: '001520.KS,  Tongyang Inc.',
    name: 'Tongyang Inc.',
    symbol: '001520.KS',
  },
  {
    value: '001630.KS',
    label: '001630.KS,  Chong Kun Dang Holdings Corp.',
    name: 'Chong Kun Dang Holdings Corp.',
    symbol: '001630.KS',
  },
  {
    value: '001680.KS',
    label: '001680.KS,  Daesang Corporation',
    name: 'Daesang Corporation',
    symbol: '001680.KS',
  },
  {
    value: '001720.KS',
    label: '001720.KS,  Shinyoung Securities Co., Ltd.',
    name: 'Shinyoung Securities Co., Ltd.',
    symbol: '001720.KS',
  },
  {
    value: '001740.KS',
    label: '001740.KS,  SK Networks Company Limited',
    name: 'SK Networks Company Limited',
    symbol: '001740.KS',
  },
  {
    value: '001800.KS',
    label: '001800.KS,  ORION Holdings Corp.',
    name: 'ORION Holdings Corp.',
    symbol: '001800.KS',
  },
  {
    value: '001820.KS',
    label: '001820.KS,  SAMWHA CAPACITOR Co.,LTD',
    name: 'SAMWHA CAPACITOR Co.,LTD',
    symbol: '001820.KS',
  },
  {
    value: '001880.KS',
    label: '001880.KS,  DL Construction Co., Ltd.',
    name: 'DL Construction Co., Ltd.',
    symbol: '001880.KS',
  },
  {
    value: '001914.SZ',
    label: '001914.SZ,  China Merchants Property Operation & Service Co., Ltd.',
    name: 'China Merchants Property Operation & Service Co., Ltd.',
    symbol: '001914.SZ',
  },
  {
    value: '001979.SZ',
    label:
      '001979.SZ,  China Merchants Shekou Industrial Zone Holdings Co., Ltd.',
    name: 'China Merchants Shekou Industrial Zone Holdings Co., Ltd.',
    symbol: '001979.SZ',
  },
  {
    value: '002001.SZ',
    label: '002001.SZ,  Zhejiang NHU Company Ltd.',
    name: 'Zhejiang NHU Company Ltd.',
    symbol: '002001.SZ',
  },
  {
    value: '002007.SZ',
    label: '002007.SZ,  Hualan Biological Engineering Inc.',
    name: 'Hualan Biological Engineering Inc.',
    symbol: '002007.SZ',
  },
  {
    value: '002008.SZ',
    label: "002008.SZ,  Han's Laser Technology Industry Group Co., Ltd.",
    name: "Han's Laser Technology Industry Group Co., Ltd.",
    symbol: '002008.SZ',
  },
  {
    value: '002010.SZ',
    label: '002010.SZ,  Transfar Zhilian Co., Ltd.',
    name: 'Transfar Zhilian Co., Ltd.',
    symbol: '002010.SZ',
  },
  {
    value: '002013.SZ',
    label: '002013.SZ,  AVIC Electromechanical Systems Co.,Ltd',
    name: 'AVIC Electromechanical Systems Co.,Ltd',
    symbol: '002013.SZ',
  },
  {
    value: '002019.SZ',
    label: '002019.SZ,  Yifan Pharmaceutical Co., Ltd.',
    name: 'Yifan Pharmaceutical Co., Ltd.',
    symbol: '002019.SZ',
  },
  {
    value: '002020.KS',
    label: '002020.KS,  Kolon Corporation',
    name: 'Kolon Corporation',
    symbol: '002020.KS',
  },
  {
    value: '002024.SZ',
    label: '002024.SZ,  Suning.com Co., Ltd.',
    name: 'Suning.com Co., Ltd.',
    symbol: '002024.SZ',
  },
  {
    value: '002027.SZ',
    label: '002027.SZ,  Focus Media Information Technology Co., Ltd.',
    name: 'Focus Media Information Technology Co., Ltd.',
    symbol: '002027.SZ',
  },
  {
    value: '002030.SZ',
    label: '002030.SZ,  Daan Gene Co., Ltd.',
    name: 'Daan Gene Co., Ltd.',
    symbol: '002030.SZ',
  },
  {
    value: '002032.SZ',
    label: '002032.SZ,  Zhejiang Supor Co., Ltd.',
    name: 'Zhejiang Supor Co., Ltd.',
    symbol: '002032.SZ',
  },
  {
    value: '002038.SZ',
    label: '002038.SZ,  Beijing SL Pharmaceutical Co., Ltd.',
    name: 'Beijing SL Pharmaceutical Co., Ltd.',
    symbol: '002038.SZ',
  },
  {
    value: '002044.SZ',
    label: '002044.SZ,  Meinian Onehealth Healthcare Holdings Co., Ltd.',
    name: 'Meinian Onehealth Healthcare Holdings Co., Ltd.',
    symbol: '002044.SZ',
  },
  {
    value: '002049.SZ',
    label: '002049.SZ,  Unigroup Guoxin Microelectronics Co., Ltd.',
    name: 'Unigroup Guoxin Microelectronics Co., Ltd.',
    symbol: '002049.SZ',
  },
  {
    value: '002050.SZ',
    label: '002050.SZ,  Zhejiang Sanhua Intelligent Controls Co.,Ltd',
    name: 'Zhejiang Sanhua Intelligent Controls Co.,Ltd',
    symbol: '002050.SZ',
  },
  {
    value: '002056.SZ',
    label: '002056.SZ,  Hengdian Group DMEGC Magnetics Co. ,Ltd',
    name: 'Hengdian Group DMEGC Magnetics Co. ,Ltd',
    symbol: '002056.SZ',
  },
  {
    value: '002064.SZ',
    label: '002064.SZ,  Huafon Chemical Co., Ltd.',
    name: 'Huafon Chemical Co., Ltd.',
    symbol: '002064.SZ',
  },
  {
    value: '002065.SZ',
    label: '002065.SZ,  DHC Software Co.,Ltd.',
    name: 'DHC Software Co.,Ltd.',
    symbol: '002065.SZ',
  },
  {
    value: '002074.SZ',
    label: '002074.SZ,  Gotion High-tech Co.,Ltd.',
    name: 'Gotion High-tech Co.,Ltd.',
    symbol: '002074.SZ',
  },
  {
    value: '002075.SZ',
    label: '002075.SZ,  Jiangsu Shagang Co., Ltd.',
    name: 'Jiangsu Shagang Co., Ltd.',
    symbol: '002075.SZ',
  },
  {
    value: '002078.SZ',
    label: '002078.SZ,  Shandong Sunpaper Co., Ltd.',
    name: 'Shandong Sunpaper Co., Ltd.',
    symbol: '002078.SZ',
  },
  {
    value: '002080.SZ',
    label: '002080.SZ,  Sinoma Science & Technology Co.,Ltd.',
    name: 'Sinoma Science & Technology Co.,Ltd.',
    symbol: '002080.SZ',
  },
  {
    value: '002081.SZ',
    label: '002081.SZ,  Suzhou Gold Mantis Construction Decoration Co., Ltd.',
    name: 'Suzhou Gold Mantis Construction Decoration Co., Ltd.',
    symbol: '002081.SZ',
  },
  {
    value: '002099.SZ',
    label: '002099.SZ,  Zhejiang Hisoar Pharmaceutical Co., Ltd.',
    name: 'Zhejiang Hisoar Pharmaceutical Co., Ltd.',
    symbol: '002099.SZ',
  },
  {
    value: '002110.SZ',
    label: '002110.SZ,  Sansteel MinGuang Co.,Ltd.,Fujian',
    name: 'Sansteel MinGuang Co.,Ltd.,Fujian',
    symbol: '002110.SZ',
  },
  {
    value: '002120.SZ',
    label: '002120.SZ,  YUNDA Holding Co., Ltd.',
    name: 'YUNDA Holding Co., Ltd.',
    symbol: '002120.SZ',
  },
  {
    value: '002124.SZ',
    label: '002124.SZ,  Tech-Bank Food Co., Ltd.',
    name: 'Tech-Bank Food Co., Ltd.',
    symbol: '002124.SZ',
  },
  {
    value: '002127.SZ',
    label: '002127.SZ,  NanJi E-Commerce Co., LTD',
    name: 'NanJi E-Commerce Co., LTD',
    symbol: '002127.SZ',
  },
  {
    value: '002128.SZ',
    label: '002128.SZ,  Inner Mongolia Dian Tou Energy Corporation Limited',
    name: 'Inner Mongolia Dian Tou Energy Corporation Limited',
    symbol: '002128.SZ',
  },
  {
    value: '002129.SZ',
    label: '002129.SZ,  TCL Zhonghuan Renewable Energy Technology Co.,Ltd.',
    name: 'TCL Zhonghuan Renewable Energy Technology Co.,Ltd.',
    symbol: '002129.SZ',
  },
  {
    value: '002131.SZ',
    label: '002131.SZ,  Leo Group Co., Ltd.',
    name: 'Leo Group Co., Ltd.',
    symbol: '002131.SZ',
  },
  {
    value: '002138.SZ',
    label: '002138.SZ,  Shenzhen Sunlord Electronics Co.,Ltd.',
    name: 'Shenzhen Sunlord Electronics Co.,Ltd.',
    symbol: '002138.SZ',
  },
  {
    value: '002142.SZ',
    label: '002142.SZ,  Bank of Ningbo Co., Ltd.',
    name: 'Bank of Ningbo Co., Ltd.',
    symbol: '002142.SZ',
  },
  {
    value: '002146.SZ',
    label: '002146.SZ,  RiseSun Real Estate Development Co.,Ltd',
    name: 'RiseSun Real Estate Development Co.,Ltd',
    symbol: '002146.SZ',
  },
  {
    value: '002150.KS',
    label: '002150.KS,  Dohwa Engineering Co., Ltd.',
    name: 'Dohwa Engineering Co., Ltd.',
    symbol: '002150.KS',
  },
  {
    value: '002151.SZ',
    label: '002151.SZ,  Beijing BDStar Navigation Co., Ltd.',
    name: 'Beijing BDStar Navigation Co., Ltd.',
    symbol: '002151.SZ',
  },
  {
    value: '002152.SZ',
    label: '002152.SZ,  GRG Banking Equipment Co., Ltd.',
    name: 'GRG Banking Equipment Co., Ltd.',
    symbol: '002152.SZ',
  },
  {
    value: '002153.SZ',
    label: '002153.SZ,  Beijing Shiji Information Technology Co., Ltd.',
    name: 'Beijing Shiji Information Technology Co., Ltd.',
    symbol: '002153.SZ',
  },
  {
    value: '002156.SZ',
    label: '002156.SZ,  Tongfu Microelectronics Co.,Ltd',
    name: 'Tongfu Microelectronics Co.,Ltd',
    symbol: '002156.SZ',
  },
  {
    value: '002157.SZ',
    label: '002157.SZ,  Jiangxi Zhengbang Technology Co.Ltd.',
    name: 'Jiangxi Zhengbang Technology Co.Ltd.',
    symbol: '002157.SZ',
  },
  {
    value: '002180.SZ',
    label: '002180.SZ,  Ninestar Corporation',
    name: 'Ninestar Corporation',
    symbol: '002180.SZ',
  },
  {
    value: '002185.SZ',
    label: '002185.SZ,  Tianshui Huatian Technology Co., Ltd.',
    name: 'Tianshui Huatian Technology Co., Ltd.',
    symbol: '002185.SZ',
  },
  {
    value: '002191.SZ',
    label: '002191.SZ,  Shenzhen Jinjia Group Co.,Ltd.',
    name: 'Shenzhen Jinjia Group Co.,Ltd.',
    symbol: '002191.SZ',
  },
  {
    value: '002202.SZ',
    label: '002202.SZ,  Xinjiang Goldwind Science & Technology Co., Ltd.',
    name: 'Xinjiang Goldwind Science & Technology Co., Ltd.',
    symbol: '002202.SZ',
  },
  {
    value: '002210.KS',
    label: '002210.KS,  Dong Sung Bio Pharm.Co.,Ltd.',
    name: 'Dong Sung Bio Pharm.Co.,Ltd.',
    symbol: '002210.KS',
  },
  {
    value: '002212.SZ',
    label: '002212.SZ,  Topsec Technologies Group Inc.',
    name: 'Topsec Technologies Group Inc.',
    symbol: '002212.SZ',
  },
  {
    value: '002223.SZ',
    label: '002223.SZ,  Jiangsu Yuyue Medical Equipment & Supply Co., Ltd.',
    name: 'Jiangsu Yuyue Medical Equipment & Supply Co., Ltd.',
    symbol: '002223.SZ',
  },
  {
    value: '002233.SZ',
    label: '002233.SZ,  Guangdong Tapai Group Co., Ltd.',
    name: 'Guangdong Tapai Group Co., Ltd.',
    symbol: '002233.SZ',
  },
  {
    value: '002236.SZ',
    label: '002236.SZ,  Zhejiang Dahua Technology Co., Ltd.',
    name: 'Zhejiang Dahua Technology Co., Ltd.',
    symbol: '002236.SZ',
  },
  {
    value: '002240.KS',
    label: '002240.KS,  Kiswire Ltd.',
    name: 'Kiswire Ltd.',
    symbol: '002240.KS',
  },
  {
    value: '002240.SZ',
    label: '002240.SZ,  Chengxin Lithium Group Co., Ltd.',
    name: 'Chengxin Lithium Group Co., Ltd.',
    symbol: '002240.SZ',
  },
  {
    value: '002241.SZ',
    label: '002241.SZ,  Goertek Inc.',
    name: 'Goertek Inc.',
    symbol: '002241.SZ',
  },
  {
    value: '002242.SZ',
    label: '002242.SZ,  Joyoung Co.,Ltd',
    name: 'Joyoung Co.,Ltd',
    symbol: '002242.SZ',
  },
  {
    value: '002252.SZ',
    label: '002252.SZ,  Shanghai RAAS Blood Products Co., Ltd.',
    name: 'Shanghai RAAS Blood Products Co., Ltd.',
    symbol: '002252.SZ',
  },
  {
    value: '002266.SZ',
    label: '002266.SZ,  Zhefu Holding Group Co., Ltd.',
    name: 'Zhefu Holding Group Co., Ltd.',
    symbol: '002266.SZ',
  },
  {
    value: '002271.SZ',
    label:
      '002271.SZ,  Beijing Oriental Yuhong Waterproof Technology Co., Ltd.',
    name: 'Beijing Oriental Yuhong Waterproof Technology Co., Ltd.',
    symbol: '002271.SZ',
  },
  {
    value: '002294.SZ',
    label: '002294.SZ,  Shenzhen Salubris Pharmaceuticals Co., Ltd.',
    name: 'Shenzhen Salubris Pharmaceuticals Co., Ltd.',
    symbol: '002294.SZ',
  },
  {
    value: '002299.SZ',
    label: '002299.SZ,  Fujian Sunner Development Co., Ltd.',
    name: 'Fujian Sunner Development Co., Ltd.',
    symbol: '002299.SZ',
  },
  {
    value: '002303.SZ',
    label: '002303.SZ,  MYS Group Co., Ltd.',
    name: 'MYS Group Co., Ltd.',
    symbol: '002303.SZ',
  },
  {
    value: '002304.SZ',
    label: '002304.SZ,  Jiangsu Yanghe Brewery Joint-Stock Co., Ltd.',
    name: 'Jiangsu Yanghe Brewery Joint-Stock Co., Ltd.',
    symbol: '002304.SZ',
  },
  {
    value: '002310.KS',
    label: '002310.KS,  Asia Paper Manufacturing.Co.,Ltd',
    name: 'Asia Paper Manufacturing.Co.,Ltd',
    symbol: '002310.KS',
  },
  {
    value: '002311.SZ',
    label: '002311.SZ,  Guangdong Haid Group Co., Limited',
    name: 'Guangdong Haid Group Co., Limited',
    symbol: '002311.SZ',
  },
  {
    value: '002320.KS',
    label: '002320.KS,  Hanjin Transportation Co., Ltd.',
    name: 'Hanjin Transportation Co., Ltd.',
    symbol: '002320.KS',
  },
  {
    value: '002340.SZ',
    label: '002340.SZ,  GEM Co., Ltd.',
    name: 'GEM Co., Ltd.',
    symbol: '002340.SZ',
  },
  {
    value: '002350.KS',
    label: '002350.KS,  Nexen Tire Corporation',
    name: 'Nexen Tire Corporation',
    symbol: '002350.KS',
  },
  {
    value: '002352.SZ',
    label: '002352.SZ,  S.F. Holding Co., Ltd.',
    name: 'S.F. Holding Co., Ltd.',
    symbol: '002352.SZ',
  },
  {
    value: '002353.SZ',
    label: '002353.SZ,  Yantai Jereh Oilfield Services Group Co., Ltd.',
    name: 'Yantai Jereh Oilfield Services Group Co., Ltd.',
    symbol: '002353.SZ',
  },
  {
    value: '002367.SZ',
    label: '002367.SZ,  Canny Elevator Co., Ltd.',
    name: 'Canny Elevator Co., Ltd.',
    symbol: '002367.SZ',
  },
  {
    value: '002371.SZ',
    label: '002371.SZ,  NAURA Technology Group Co., Ltd.',
    name: 'NAURA Technology Group Co., Ltd.',
    symbol: '002371.SZ',
  },
  {
    value: '002372.SZ',
    label: '002372.SZ,  Zhejiang Weixing New Building Materials Co., Ltd.',
    name: 'Zhejiang Weixing New Building Materials Co., Ltd.',
    symbol: '002372.SZ',
  },
  {
    value: '002373.SZ',
    label: '002373.SZ,  China Transinfo Technology Co., Ltd',
    name: 'China Transinfo Technology Co., Ltd',
    symbol: '002373.SZ',
  },
  {
    value: '002380.KS',
    label: '002380.KS,  KCC Corporation',
    name: 'KCC Corporation',
    symbol: '002380.KS',
  },
  {
    value: '002382.SZ',
    label: '002382.SZ,  Blue Sail Medical Co.,Ltd.',
    name: 'Blue Sail Medical Co.,Ltd.',
    symbol: '002382.SZ',
  },
  {
    value: '002384.SZ',
    label: '002384.SZ,  Suzhou Dongshan Precision Manufacturing Co., Ltd.',
    name: 'Suzhou Dongshan Precision Manufacturing Co., Ltd.',
    symbol: '002384.SZ',
  },
  {
    value: '002385.SZ',
    label: '002385.SZ,  Beijing Dabeinong Technology Group Co.,Ltd.',
    name: 'Beijing Dabeinong Technology Group Co.,Ltd.',
    symbol: '002385.SZ',
  },
  {
    value: '002390.KS',
    label: '002390.KS,  HANDOK Inc.',
    name: 'HANDOK Inc.',
    symbol: '002390.KS',
  },
  {
    value: '002399.SZ',
    label: '002399.SZ,  Shenzhen Hepalink Pharmaceutical Group Co., Ltd.',
    name: 'Shenzhen Hepalink Pharmaceutical Group Co., Ltd.',
    symbol: '002399.SZ',
  },
  {
    value: '002405.SZ',
    label: '002405.SZ,  NavInfo Co., Ltd.',
    name: 'NavInfo Co., Ltd.',
    symbol: '002405.SZ',
  },
  {
    value: '002409.SZ',
    label: '002409.SZ,  Jiangsu Yoke Technology Co., Ltd.',
    name: 'Jiangsu Yoke Technology Co., Ltd.',
    symbol: '002409.SZ',
  },
  {
    value: '002410.SZ',
    label: '002410.SZ,  Glodon Company Limited',
    name: 'Glodon Company Limited',
    symbol: '002410.SZ',
  },
  {
    value: '002414.SZ',
    label: '002414.SZ,  Wuhan Guide Infrared Co., Ltd.',
    name: 'Wuhan Guide Infrared Co., Ltd.',
    symbol: '002414.SZ',
  },
  {
    value: '002422.SZ',
    label: '002422.SZ,  Sichuan Kelun Pharmaceutical Co., Ltd.',
    name: 'Sichuan Kelun Pharmaceutical Co., Ltd.',
    symbol: '002422.SZ',
  },
  {
    value: '002424.SZ',
    label: '002424.SZ,  Guizhou Bailing Group Pharmaceutical Co., Ltd.',
    name: 'Guizhou Bailing Group Pharmaceutical Co., Ltd.',
    symbol: '002424.SZ',
  },
  {
    value: '002429.SZ',
    label: '002429.SZ,  Shenzhen MTC Co., Ltd.',
    name: 'Shenzhen MTC Co., Ltd.',
    symbol: '002429.SZ',
  },
  {
    value: '002430.SZ',
    label: '002430.SZ,  Hangzhou Oxygen Plant Group Co.,Ltd.',
    name: 'Hangzhou Oxygen Plant Group Co.,Ltd.',
    symbol: '002430.SZ',
  },
  {
    value: '002444.SZ',
    label: '002444.SZ,  Hangzhou Greatstar Industrial Co., Ltd',
    name: 'Hangzhou Greatstar Industrial Co., Ltd',
    symbol: '002444.SZ',
  },
  {
    value: '002456.SZ',
    label: '002456.SZ,  OFILM Group Co., Ltd.',
    name: 'OFILM Group Co., Ltd.',
    symbol: '002456.SZ',
  },
  {
    value: '002458.SZ',
    label:
      '002458.SZ,  Shandong Yisheng Livestock & Poultry Breeding Co., Ltd.',
    name: 'Shandong Yisheng Livestock & Poultry Breeding Co., Ltd.',
    symbol: '002458.SZ',
  },
  {
    value: '002459.SZ',
    label: '002459.SZ,  JA Solar Technology Co., Ltd.',
    name: 'JA Solar Technology Co., Ltd.',
    symbol: '002459.SZ',
  },
  {
    value: '002460.SZ',
    label: '002460.SZ,  Ganfeng Lithium Group Co., Ltd.',
    name: 'Ganfeng Lithium Group Co., Ltd.',
    symbol: '002460.SZ',
  },
  {
    value: '002463.SZ',
    label: '002463.SZ,  Wus Printed Circuit (Kunshan) Co., Ltd.',
    name: 'Wus Printed Circuit (Kunshan) Co., Ltd.',
    symbol: '002463.SZ',
  },
  {
    value: '002465.SZ',
    label:
      '002465.SZ,  Guangzhou Haige Communications Group Incorporated Company',
    name: 'Guangzhou Haige Communications Group Incorporated Company',
    symbol: '002465.SZ',
  },
  {
    value: '002475.SZ',
    label: '002475.SZ,  Luxshare Precision Industry Co., Ltd.',
    name: 'Luxshare Precision Industry Co., Ltd.',
    symbol: '002475.SZ',
  },
  {
    value: '002493.SZ',
    label: '002493.SZ,  Rongsheng Petrochemical Co., Ltd.',
    name: 'Rongsheng Petrochemical Co., Ltd.',
    symbol: '002493.SZ',
  },
  {
    value: '002500.SZ',
    label: '002500.SZ,  Shanxi Securities Co., Ltd.',
    name: 'Shanxi Securities Co., Ltd.',
    symbol: '002500.SZ',
  },
  {
    value: '002506.SZ',
    label: '002506.SZ,  GCL System Integration Technology Co., Ltd.',
    name: 'GCL System Integration Technology Co., Ltd.',
    symbol: '002506.SZ',
  },
  {
    value: '002507.SZ',
    label: '002507.SZ,  Chongqing Fuling Zhacai Group Co., Ltd.',
    name: 'Chongqing Fuling Zhacai Group Co., Ltd.',
    symbol: '002507.SZ',
  },
  {
    value: '002508.SZ',
    label: '002508.SZ,  Hangzhou Robam Appliances Co., Ltd.',
    name: 'Hangzhou Robam Appliances Co., Ltd.',
    symbol: '002508.SZ',
  },
  {
    value: '002511.SZ',
    label: '002511.SZ,  C&S Paper Co.,Ltd',
    name: 'C&S Paper Co.,Ltd',
    symbol: '002511.SZ',
  },
  {
    value: '002531.SZ',
    label: '002531.SZ,  Titan Wind Energy (Suzhou) Co.,Ltd',
    name: 'Titan Wind Energy (Suzhou) Co.,Ltd',
    symbol: '002531.SZ',
  },
  {
    value: '002541.SZ',
    label: '002541.SZ,  Anhui Honglu Steel Construction(Group) CO., LTD',
    name: 'Anhui Honglu Steel Construction(Group) CO., LTD',
    symbol: '002541.SZ',
  },
  {
    value: '002555.SZ',
    label:
      '002555.SZ,  37 Interactive Entertainment Network Technology Group Co., Ltd.',
    name: '37 Interactive Entertainment Network Technology Group Co., Ltd.',
    symbol: '002555.SZ',
  },
  {
    value: '002557.SZ',
    label: '002557.SZ,  Chacha Food Company, Limited',
    name: 'Chacha Food Company, Limited',
    symbol: '002557.SZ',
  },
  {
    value: '002558.SZ',
    label: '002558.SZ,  Giant Network Group Co., Ltd.',
    name: 'Giant Network Group Co., Ltd.',
    symbol: '002558.SZ',
  },
  {
    value: '002563.SZ',
    label: '002563.SZ,  Zhejiang Semir Garment Co., Ltd.',
    name: 'Zhejiang Semir Garment Co., Ltd.',
    symbol: '002563.SZ',
  },
  {
    value: '002568.SZ',
    label: '002568.SZ,  Shanghai Bairun Investment Holding Group Co., Ltd.',
    name: 'Shanghai Bairun Investment Holding Group Co., Ltd.',
    symbol: '002568.SZ',
  },
  {
    value: '002572.SZ',
    label: '002572.SZ,  Suofeiya Home Collection Co., Ltd.',
    name: 'Suofeiya Home Collection Co., Ltd.',
    symbol: '002572.SZ',
  },
  {
    value: '002594.SZ',
    label: '002594.SZ,  BYD Company Limited',
    name: 'BYD Company Limited',
    symbol: '002594.SZ',
  },
  {
    value: '002600.SZ',
    label: '002600.SZ,  Lingyi iTech (Guangdong) Company',
    name: 'Lingyi iTech (Guangdong) Company',
    symbol: '002600.SZ',
  },
  {
    value: '002601.SZ',
    label: '002601.SZ,  LB Group Co., Ltd.',
    name: 'LB Group Co., Ltd.',
    symbol: '002601.SZ',
  },
  {
    value: '002602.SZ',
    label: '002602.SZ,  Zhejiang Century Huatong Group Co.,Ltd',
    name: 'Zhejiang Century Huatong Group Co.,Ltd',
    symbol: '002602.SZ',
  },
  {
    value: '002603.SZ',
    label: '002603.SZ,  Shijiazhuang Yiling Pharmaceutical Co., Ltd.',
    name: 'Shijiazhuang Yiling Pharmaceutical Co., Ltd.',
    symbol: '002603.SZ',
  },
  {
    value: '002605.SZ',
    label: '002605.SZ,  Shanghai Yaoji Technology Co., Ltd.',
    name: 'Shanghai Yaoji Technology Co., Ltd.',
    symbol: '002605.SZ',
  },
  {
    value: '002607.SZ',
    label: '002607.SZ,  Offcn Education Technology Co., Ltd.',
    name: 'Offcn Education Technology Co., Ltd.',
    symbol: '002607.SZ',
  },
  {
    value: '002624.SZ',
    label: '002624.SZ,  Perfect World Co., Ltd.',
    name: 'Perfect World Co., Ltd.',
    symbol: '002624.SZ',
  },
  {
    value: '002625.SZ',
    label: '002625.SZ,  Kuang-Chi Technologies Co., Ltd.',
    name: 'Kuang-Chi Technologies Co., Ltd.',
    symbol: '002625.SZ',
  },
  {
    value: '002648.SZ',
    label: '002648.SZ,  Satellite Chemical Co.,Ltd.',
    name: 'Satellite Chemical Co.,Ltd.',
    symbol: '002648.SZ',
  },
  {
    value: '002673.SZ',
    label: '002673.SZ,  Western Securities Co., Ltd.',
    name: 'Western Securities Co., Ltd.',
    symbol: '002673.SZ',
  },
  {
    value: '002677.SZ',
    label: '002677.SZ,  Zhejiang Meida Industrial Co., Ltd.',
    name: 'Zhejiang Meida Industrial Co., Ltd.',
    symbol: '002677.SZ',
  },
  {
    value: '002683.SZ',
    label: '002683.SZ,  Guangdong Hongda Holdings Group Co., Ltd.',
    name: 'Guangdong Hongda Holdings Group Co., Ltd.',
    symbol: '002683.SZ',
  },
  {
    value: '002690.SZ',
    label: '002690.SZ,  Hefei Meyer Optoelectronic Technology Inc.',
    name: 'Hefei Meyer Optoelectronic Technology Inc.',
    symbol: '002690.SZ',
  },
  {
    value: '002698.SZ',
    label: '002698.SZ,  Harbin Boshi Automation Co., Ltd.',
    name: 'Harbin Boshi Automation Co., Ltd.',
    symbol: '002698.SZ',
  },
  {
    value: '002701.SZ',
    label: '002701.SZ,  ORG Technology Co.,Ltd.',
    name: 'ORG Technology Co.,Ltd.',
    symbol: '002701.SZ',
  },
  {
    value: '002705.SZ',
    label:
      '002705.SZ,  Guangdong Xinbao Electrical Appliances Holdings Co., Ltd',
    name: 'Guangdong Xinbao Electrical Appliances Holdings Co., Ltd',
    symbol: '002705.SZ',
  },
  {
    value: '002706.SZ',
    label: '002706.SZ,  Shanghai Liangxin Electrical Co.,LTD.',
    name: 'Shanghai Liangxin Electrical Co.,LTD.',
    symbol: '002706.SZ',
  },
  {
    value: '002709.SZ',
    label: '002709.SZ,  Guangzhou Tinci Materials Technology Co., Ltd.',
    name: 'Guangzhou Tinci Materials Technology Co., Ltd.',
    symbol: '002709.SZ',
  },
  {
    value: '002714.SZ',
    label: '002714.SZ,  Muyuan Foods Co., Ltd.',
    name: 'Muyuan Foods Co., Ltd.',
    symbol: '002714.SZ',
  },
  {
    value: '002727.SZ',
    label: '002727.SZ,  Yixintang Pharmaceutical Group Co., Ltd.',
    name: 'Yixintang Pharmaceutical Group Co., Ltd.',
    symbol: '002727.SZ',
  },
  {
    value: '002736.SZ',
    label: '002736.SZ,  Guosen Securities Co., Ltd.',
    name: 'Guosen Securities Co., Ltd.',
    symbol: '002736.SZ',
  },
  {
    value: '002737.SZ',
    label: '002737.SZ,  Sunflower Pharmaceutical Group Co.,Ltd',
    name: 'Sunflower Pharmaceutical Group Co.,Ltd',
    symbol: '002737.SZ',
  },
  {
    value: '002745.SZ',
    label: '002745.SZ,  MLS Co., Ltd',
    name: 'MLS Co., Ltd',
    symbol: '002745.SZ',
  },
  {
    value: '002747.SZ',
    label: '002747.SZ,  Estun Automation Co., Ltd',
    name: 'Estun Automation Co., Ltd',
    symbol: '002747.SZ',
  },
  {
    value: '002790.KS',
    label: '002790.KS,  AMOREPACIFIC Group',
    name: 'AMOREPACIFIC Group',
    symbol: '002790.KS',
  },
  {
    value: '002791.SZ',
    label: '002791.SZ,  Guangdong KinLong Hardware Products Co.,Ltd.',
    name: 'Guangdong KinLong Hardware Products Co.,Ltd.',
    symbol: '002791.SZ',
  },
  {
    value: '002797.SZ',
    label: '002797.SZ,  First Capital Securities Co., Ltd.',
    name: 'First Capital Securities Co., Ltd.',
    symbol: '002797.SZ',
  },
  {
    value: '002812.SZ',
    label: '002812.SZ,  Yunnan Energy New Material Co., Ltd.',
    name: 'Yunnan Energy New Material Co., Ltd.',
    symbol: '002812.SZ',
  },
  {
    value: '002821.SZ',
    label: '002821.SZ,  Asymchem Laboratories (Tianjin) Co., Ltd.',
    name: 'Asymchem Laboratories (Tianjin) Co., Ltd.',
    symbol: '002821.SZ',
  },
  {
    value: '002833.SZ',
    label: '002833.SZ,  Guangzhou KDT Machinery Co.,Ltd.',
    name: 'Guangzhou KDT Machinery Co.,Ltd.',
    symbol: '002833.SZ',
  },
  {
    value: '002840.KS',
    label: '002840.KS,  Miwon Commercial Co., Ltd.',
    name: 'Miwon Commercial Co., Ltd.',
    symbol: '002840.KS',
  },
  {
    value: '002841.SZ',
    label:
      '002841.SZ,  Guangzhou Shiyuan Electronic Technology Company Limited',
    name: 'Guangzhou Shiyuan Electronic Technology Company Limited',
    symbol: '002841.SZ',
  },
  {
    value: '002880.SZ',
    label: '002880.SZ,  Shenzhen Weiguang Biological Products Co., Ltd.',
    name: 'Shenzhen Weiguang Biological Products Co., Ltd.',
    symbol: '002880.SZ',
  },
  {
    value: '002916.SZ',
    label: '002916.SZ,  Shennan Circuit Company Limited',
    name: 'Shennan Circuit Company Limited',
    symbol: '002916.SZ',
  },
  {
    value: '002920.SZ',
    label: '002920.SZ,  Huizhou Desay SV Automotive Co., Ltd.',
    name: 'Huizhou Desay SV Automotive Co., Ltd.',
    symbol: '002920.SZ',
  },
  {
    value: '002925.SZ',
    label: '002925.SZ,  Xiamen Intretech Inc.',
    name: 'Xiamen Intretech Inc.',
    symbol: '002925.SZ',
  },
  {
    value: '002926.SZ',
    label: '002926.SZ,  HUAXI Securities Co., Ltd.',
    name: 'HUAXI Securities Co., Ltd.',
    symbol: '002926.SZ',
  },
  {
    value: '002936.SZ',
    label: '002936.SZ,  Bank of Zhengzhou Co., Ltd.',
    name: 'Bank of Zhengzhou Co., Ltd.',
    symbol: '002936.SZ',
  },
  {
    value: '002938.SZ',
    label: '002938.SZ,  Avary Holding(Shenzhen)Co., Limited',
    name: 'Avary Holding(Shenzhen)Co., Limited',
    symbol: '002938.SZ',
  },
  {
    value: '002939.SZ',
    label: '002939.SZ,  China Great Wall Securities Co.,Ltd.',
    name: 'China Great Wall Securities Co.,Ltd.',
    symbol: '002939.SZ',
  },
  {
    value: '002958.SZ',
    label: '002958.SZ,  Qingdao Rural Commercial Bank Co., Ltd.',
    name: 'Qingdao Rural Commercial Bank Co., Ltd.',
    symbol: '002958.SZ',
  },
  {
    value: '002960.KS',
    label: '002960.KS,  Hankook Shell Oil Co.,Ltd.',
    name: 'Hankook Shell Oil Co.,Ltd.',
    symbol: '002960.KS',
  },
  {
    value: '002990.KS',
    label: '002990.KS,  KUMHO Engineering & Construction Co., Ltd.',
    name: 'KUMHO Engineering & Construction Co., Ltd.',
    symbol: '002990.KS',
  },
  {
    value: '003000.KS',
    label: '003000.KS,  Bukwang Pharmaceutical Co., Ltd.',
    name: 'Bukwang Pharmaceutical Co., Ltd.',
    symbol: '003000.KS',
  },
  {
    value: '003030.KS',
    label: '003030.KS,  SeAH Steel Holdings Corporation',
    name: 'SeAH Steel Holdings Corporation',
    symbol: '003030.KS',
  },
  {
    value: '003060.KS',
    label: '003060.KS,  Aprogen pharmaceuticals,Inc.',
    name: 'Aprogen pharmaceuticals,Inc.',
    symbol: '003060.KS',
  },
  {
    value: '003090.KS',
    label: '003090.KS,  Daewoong Co., Ltd.',
    name: 'Daewoong Co., Ltd.',
    symbol: '003090.KS',
  },
  {
    value: '003220.KS',
    label: '003220.KS,  Daewon Pharmaceutical Co., Ltd.',
    name: 'Daewon Pharmaceutical Co., Ltd.',
    symbol: '003220.KS',
  },
  {
    value: '003230.KS',
    label: '003230.KS,  Samyang Foods Co., Ltd.',
    name: 'Samyang Foods Co., Ltd.',
    symbol: '003230.KS',
  },
  {
    value: '003240.KS',
    label: '003240.KS,  Taekwang Industrial Co., Ltd.',
    name: 'Taekwang Industrial Co., Ltd.',
    symbol: '003240.KS',
  },
  {
    value: '003410.KS',
    label: '003410.KS,  SsangYong C&E Co., Ltd.',
    name: 'SsangYong C&E Co., Ltd.',
    symbol: '003410.KS',
  },
  {
    value: '003470.KS',
    label: '003470.KS,  Yuanta Securities Korea Co., Ltd.',
    name: 'Yuanta Securities Korea Co., Ltd.',
    symbol: '003470.KS',
  },
  {
    value: '003490.KS',
    label: '003490.KS,  Korean Air Lines Co., Ltd.',
    name: 'Korean Air Lines Co., Ltd.',
    symbol: '003490.KS',
  },
  {
    value: '003520.KS',
    label: '003520.KS,  Yungjin Pharm. Co., Ltd.',
    name: 'Yungjin Pharm. Co., Ltd.',
    symbol: '003520.KS',
  },
  {
    value: '003530.KS',
    label: '003530.KS,  Hanwha Investment & Securities Co., Ltd.',
    name: 'Hanwha Investment & Securities Co., Ltd.',
    symbol: '003530.KS',
  },
  {
    value: '003540.KS',
    label: '003540.KS,  Daishin Securities Co., Ltd.',
    name: 'Daishin Securities Co., Ltd.',
    symbol: '003540.KS',
  },
  {
    value: '003550.KS',
    label: '003550.KS,  LG Corp.',
    name: 'LG Corp.',
    symbol: '003550.KS',
  },
  {
    value: '003570.KS',
    label: '003570.KS,  Snt Dynamics Co.,Ltd.',
    name: 'Snt Dynamics Co.,Ltd.',
    symbol: '003570.KS',
  },
  {
    value: '003580.KS',
    label: '003580.KS,  HLB GLOBAL Co., Ltd.',
    name: 'HLB GLOBAL Co., Ltd.',
    symbol: '003580.KS',
  },
  {
    value: '003670.KS',
    label: '003670.KS,  Posco Chemical Co., Ltd.',
    name: 'Posco Chemical Co., Ltd.',
    symbol: '003670.KS',
  },
  {
    value: '003690.KS',
    label: '003690.KS,  Korean Reinsurance Company',
    name: 'Korean Reinsurance Company',
    symbol: '003690.KS',
  },
  {
    value: '003850.KS',
    label: '003850.KS,  Boryung Corporation',
    name: 'Boryung Corporation',
    symbol: '003850.KS',
  },
  {
    value: '004000.KS',
    label: '004000.KS,  LOTTE Fine Chemical Co., Ltd.',
    name: 'LOTTE Fine Chemical Co., Ltd.',
    symbol: '004000.KS',
  },
  {
    value: '004020.KS',
    label: '004020.KS,  Hyundai Steel Company',
    name: 'Hyundai Steel Company',
    symbol: '004020.KS',
  },
  {
    value: '004170.KS',
    label: '004170.KS,  SHINSEGAE Inc.',
    name: 'SHINSEGAE Inc.',
    symbol: '004170.KS',
  },
  {
    value: '004370.KS',
    label: '004370.KS,  Nongshim Co., Ltd.',
    name: 'Nongshim Co., Ltd.',
    symbol: '004370.KS',
  },
  {
    value: '004430.KS',
    label: '004430.KS,  Songwon Industrial Co., Ltd.',
    name: 'Songwon Industrial Co., Ltd.',
    symbol: '004430.KS',
  },
  {
    value: '004490.KS',
    label: '004490.KS,  Sebang Global Battery Co., Ltd.',
    name: 'Sebang Global Battery Co., Ltd.',
    symbol: '004490.KS',
  },
  {
    value: '004710.KS',
    label: '004710.KS,  Hansol  Technics Co., LTD.',
    name: 'Hansol  Technics Co., LTD.',
    symbol: '004710.KS',
  },
  {
    value: '004800.KS',
    label: '004800.KS,  Hyosung Corporation',
    name: 'Hyosung Corporation',
    symbol: '004800.KS',
  },
  {
    value: '004990.KS',
    label: '004990.KS,  LOTTE Corporation',
    name: 'LOTTE Corporation',
    symbol: '004990.KS',
  },
  {
    value: '005070.KS',
    label: '005070.KS,  Cosmo Advanced Materials & Technology Co., Ltd.',
    name: 'Cosmo Advanced Materials & Technology Co., Ltd.',
    symbol: '005070.KS',
  },
  {
    value: '005090.KS',
    label: '005090.KS,  SGC Energy Co.,Ltd.',
    name: 'SGC Energy Co.,Ltd.',
    symbol: '005090.KS',
  },
  {
    value: '005180.KS',
    label: '005180.KS,  Binggrae Co., Ltd.',
    name: 'Binggrae Co., Ltd.',
    symbol: '005180.KS',
  },
  {
    value: '005250.KS',
    label: '005250.KS,  Green Cross Holdings Corporation',
    name: 'Green Cross Holdings Corporation',
    symbol: '005250.KS',
  },
  {
    value: '005300.KS',
    label: '005300.KS,  Lotte Chilsung Beverage Co., Ltd.',
    name: 'Lotte Chilsung Beverage Co., Ltd.',
    symbol: '005300.KS',
  },
  {
    value: '005380.KS',
    label: '005380.KS,  Hyundai Motor Company',
    name: 'Hyundai Motor Company',
    symbol: '005380.KS',
  },
  {
    value: '005440.KS',
    label: '005440.KS,  Hyundai Green Food Co.,Ltd.',
    name: 'Hyundai Green Food Co.,Ltd.',
    symbol: '005440.KS',
  },
  {
    value: '005490.KS',
    label: '005490.KS,  POSCO Holdings Inc.',
    name: 'POSCO Holdings Inc.',
    symbol: '005490.KS',
  },
  {
    value: '005500.KS',
    label: '005500.KS,  Samjin Pharmaceuticals Co., Ltd.',
    name: 'Samjin Pharmaceuticals Co., Ltd.',
    symbol: '005500.KS',
  },
  {
    value: '005610.KS',
    label: '005610.KS,  SPC Samlip Co., Ltd.',
    name: 'SPC Samlip Co., Ltd.',
    symbol: '005610.KS',
  },
  {
    value: '005690.KS',
    label: '005690.KS,  Pharmicell Co., Ltd.',
    name: 'Pharmicell Co., Ltd.',
    symbol: '005690.KS',
  },
  {
    value: '005830.KS',
    label: '005830.KS,  DB Insurance Co., Ltd.',
    name: 'DB Insurance Co., Ltd.',
    symbol: '005830.KS',
  },
  {
    value: '005850.KS',
    label: '005850.KS,  SL Corporation',
    name: 'SL Corporation',
    symbol: '005850.KS',
  },
  {
    value: '005880.KS',
    label: '005880.KS,  Korea Line Corporation',
    name: 'Korea Line Corporation',
    symbol: '005880.KS',
  },
  {
    value: '005930.KS',
    label: '005930.KS,  Samsung Electronics Co., Ltd.',
    name: 'Samsung Electronics Co., Ltd.',
    symbol: '005930.KS',
  },
  {
    value: 'TMC',
    label: 'TMC,  TMC the metals company Inc.',
    name: 'TMC the metals company Inc.',
    symbol: 'TMC',
  },
  {
    value: 'ONON',
    label: 'ONON,  On Holding AG',
    name: 'On Holding AG',
    symbol: 'ONON',
  },
  {
    value: 'DH',
    label: 'DH,  Definitive Healthcare Corp.',
    name: 'Definitive Healthcare Corp.',
    symbol: 'DH',
  },
  {
    value: 'BROS',
    label: 'BROS,  Dutch Bros Inc.',
    name: 'Dutch Bros Inc.',
    symbol: 'BROS',
  },
  {
    value: 'TWKS',
    label: 'TWKS,  Thoughtworks Holding, Inc.',
    name: 'Thoughtworks Holding, Inc.',
    symbol: 'TWKS',
  },
  {
    value: 'DICE',
    label: 'DICE,  DICE Therapeutics, Inc.',
    name: 'DICE Therapeutics, Inc.',
    symbol: 'DICE',
  },
  {
    value: 'PRCT',
    label: 'PRCT,  PROCEPT BioRobotics Corporation',
    name: 'PROCEPT BioRobotics Corporation',
    symbol: 'PRCT',
  },
  {
    value: 'TYRA',
    label: 'TYRA,  Tyra Biosciences, Inc.',
    name: 'Tyra Biosciences, Inc.',
    symbol: 'TYRA',
  },
  {
    value: 'SIERU',
    label: 'SIERU,  Sierra Lake Acquisition Corp.',
    name: 'Sierra Lake Acquisition Corp.',
    symbol: 'SIERU',
  },
  {
    value: 'WINVU',
    label: 'WINVU,  WinVest Acquisition Corp.',
    name: 'WinVest Acquisition Corp.',
    symbol: 'WINVU',
  },
  {
    value: 'AEHAU',
    label: 'AEHAU,  Aesther Healthcare Acquisition Corp.',
    name: 'Aesther Healthcare Acquisition Corp.',
    symbol: 'AEHAU',
  },
  {
    value: 'CIIGU',
    label: 'CIIGU,  CIIG Capital Partners II, Inc.',
    name: 'CIIG Capital Partners II, Inc.',
    symbol: 'CIIGU',
  },
  {
    value: '005940.KS',
    label: '005940.KS,  NH Investment & Securities Co., Ltd.',
    name: 'NH Investment & Securities Co., Ltd.',
    symbol: '005940.KS',
  },
  {
    value: '006040.KS',
    label: '006040.KS,  Dongwon Industries Co., Ltd.',
    name: 'Dongwon Industries Co., Ltd.',
    symbol: '006040.KS',
  },
  {
    value: '006060.KS',
    label: '006060.KS,  HWASEUNG Industries Co.,Ltd.',
    name: 'HWASEUNG Industries Co.,Ltd.',
    symbol: '006060.KS',
  },
  {
    value: '006120.KS',
    label: '006120.KS,  SK Discovery Co., Ltd.',
    name: 'SK Discovery Co., Ltd.',
    symbol: '006120.KS',
  },
  {
    value: '006260.KS',
    label: '006260.KS,  LS Corp.',
    name: 'LS Corp.',
    symbol: '006260.KS',
  },
  {
    value: '006280.KS',
    label: '006280.KS,  Green Cross Corporation',
    name: 'Green Cross Corporation',
    symbol: '006280.KS',
  },
  {
    value: '006360.KS',
    label: '006360.KS,  GS Engineering & Construction Corporation',
    name: 'GS Engineering & Construction Corporation',
    symbol: '006360.KS',
  },
  {
    value: '006400.KS',
    label: '006400.KS,  Samsung SDI Co., Ltd.',
    name: 'Samsung SDI Co., Ltd.',
    symbol: '006400.KS',
  },
  {
    value: '006490.KS',
    label: '006490.KS,  Inscobee., Inc.',
    name: 'Inscobee., Inc.',
    symbol: '006490.KS',
  },
  {
    value: '006650.KS',
    label: '006650.KS,  Korea Petrochemical Ind. Co., Ltd.',
    name: 'Korea Petrochemical Ind. Co., Ltd.',
    symbol: '006650.KS',
  },
  {
    value: '006730.KQ',
    label: '006730.KQ,  Seobu T&D Co., Ltd.',
    name: 'Seobu T&D Co., Ltd.',
    symbol: '006730.KQ',
  },
  {
    value: '006800.KS',
    label: '006800.KS,  Mirae Asset Securities Co. Ltd.',
    name: 'Mirae Asset Securities Co. Ltd.',
    symbol: '006800.KS',
  },
  {
    value: '00680K.KS',
    label: '00680K.KS,  Mirae Asset Securities Co. Ltd.',
    name: 'Mirae Asset Securities Co. Ltd.',
    symbol: '00680K.KS',
  },
  {
    value: '007070.KS',
    label: '007070.KS,  GS Retail Co., Ltd.',
    name: 'GS Retail Co., Ltd.',
    symbol: '007070.KS',
  },
  {
    value: '007210.KS',
    label: '007210.KS,  Byucksan Corporation',
    name: 'Byucksan Corporation',
    symbol: '007210.KS',
  },
  {
    value: '007310.KS',
    label: '007310.KS,  Ottogi Corporation',
    name: 'Ottogi Corporation',
    symbol: '007310.KS',
  },
  {
    value: '007390.KQ',
    label: '007390.KQ,  NatureCell Co.,Ltd.',
    name: 'NatureCell Co.,Ltd.',
    symbol: '007390.KQ',
  },
  {
    value: '007460.KS',
    label: '007460.KS,  Aprogen Medicines Inc.',
    name: 'Aprogen Medicines Inc.',
    symbol: '007460.KS',
  },
  {
    value: '007570.KS',
    label: '007570.KS,  Ilyang Pharmaceutical Co.,Ltd',
    name: 'Ilyang Pharmaceutical Co.,Ltd',
    symbol: '007570.KS',
  },
  {
    value: '007700.KS',
    label: '007700.KS,  F&F Holdings Co., Ltd.',
    name: 'F&F Holdings Co., Ltd.',
    symbol: '007700.KS',
  },
  {
    value: '008060.KS',
    label: '008060.KS,  Daeduck Co.,Ltd.',
    name: 'Daeduck Co.,Ltd.',
    symbol: '008060.KS',
  },
  {
    value: '008350.KS',
    label: '008350.KS,  Namsun Aluminum Co., Ltd.',
    name: 'Namsun Aluminum Co., Ltd.',
    symbol: '008350.KS',
  },
  {
    value: '008490.KS',
    label: '008490.KS,  Suheung Co., Ltd.',
    name: 'Suheung Co., Ltd.',
    symbol: '008490.KS',
  },
  {
    value: '008560.KS',
    label: '008560.KS,  Meritz Securities Co., Ltd.',
    name: 'Meritz Securities Co., Ltd.',
    symbol: '008560.KS',
  },
  {
    value: '008730.KS',
    label: '008730.KS,  Youlchon Chemical Co.,Ltd.',
    name: 'Youlchon Chemical Co.,Ltd.',
    symbol: '008730.KS',
  },
  {
    value: '008770.KS',
    label: '008770.KS,  Hotel Shilla Co.,Ltd',
    name: 'Hotel Shilla Co.,Ltd',
    symbol: '008770.KS',
  },
  {
    value: '008930.KS',
    label: '008930.KS,  Hanmi Science Co., Ltd.',
    name: 'Hanmi Science Co., Ltd.',
    symbol: '008930.KS',
  },
  {
    value: '009150.KS',
    label: '009150.KS,  Samsung Electro-Mechanics Co., Ltd.',
    name: 'Samsung Electro-Mechanics Co., Ltd.',
    symbol: '009150.KS',
  },
  {
    value: '009240.KS',
    label: '009240.KS,  Hanssem Co., Ltd.',
    name: 'Hanssem Co., Ltd.',
    symbol: '009240.KS',
  },
  {
    value: '009290.KS',
    label: '009290.KS,  Kwang Dong Pharmaceutical Co., Ltd.',
    name: 'Kwang Dong Pharmaceutical Co., Ltd.',
    symbol: '009290.KS',
  },
  {
    value: '009410.KS',
    label: '009410.KS,  Taeyoung Engineering & Construction Co.,Ltd.',
    name: 'Taeyoung Engineering & Construction Co.,Ltd.',
    symbol: '009410.KS',
  },
  {
    value: '009420.KS',
    label: '009420.KS,  Hanall Biopharma Co.,Ltd',
    name: 'Hanall Biopharma Co.,Ltd',
    symbol: '009420.KS',
  },
  {
    value: '009450.KS',
    label: '009450.KS,  Kyung Dong Navien Co., Ltd.',
    name: 'Kyung Dong Navien Co., Ltd.',
    symbol: '009450.KS',
  },
  {
    value: '009540.KS',
    label: '009540.KS,  Korea Shipbuilding & Offshore Engineering Co., Ltd.',
    name: 'Korea Shipbuilding & Offshore Engineering Co., Ltd.',
    symbol: '009540.KS',
  },
  {
    value: '009830.KS',
    label: '009830.KS,  Hanwha Solutions Corporation',
    name: 'Hanwha Solutions Corporation',
    symbol: '009830.KS',
  },
  {
    value: '009970.KS',
    label: '009970.KS,  Youngone Holdings Co., Ltd.',
    name: 'Youngone Holdings Co., Ltd.',
    symbol: '009970.KS',
  },
  {
    value: '010050.KS',
    label: '010050.KS,  Woori Investment Bank Co.,Ltd.',
    name: 'Woori Investment Bank Co.,Ltd.',
    symbol: '010050.KS',
  },
  {
    value: '010060.KS',
    label: '010060.KS,  OCI Company Ltd.',
    name: 'OCI Company Ltd.',
    symbol: '010060.KS',
  },
  {
    value: '010120.KS',
    label: '010120.KS,  LS ELECTRIC Co., Ltd.',
    name: 'LS ELECTRIC Co., Ltd.',
    symbol: '010120.KS',
  },
  {
    value: '010130.KS',
    label: '010130.KS,  Korea Zinc Company, Ltd.',
    name: 'Korea Zinc Company, Ltd.',
    symbol: '010130.KS',
  },
  {
    value: '010140.KS',
    label: '010140.KS,  Samsung Heavy Industries Co., Ltd.',
    name: 'Samsung Heavy Industries Co., Ltd.',
    symbol: '010140.KS',
  },
  {
    value: '010170.KQ',
    label: '010170.KQ,  Taihan Fiber Optics Co., Ltd',
    name: 'Taihan Fiber Optics Co., Ltd',
    symbol: '010170.KQ',
  },
  {
    value: '010620.KS',
    label: '010620.KS,  Hyundai Mipo Dockyard Co., Ltd.',
    name: 'Hyundai Mipo Dockyard Co., Ltd.',
    symbol: '010620.KS',
  },
  {
    value: '010780.KS',
    label: '010780.KS,  IS DongSeo Co., Ltd.',
    name: 'IS DongSeo Co., Ltd.',
    symbol: '010780.KS',
  },
  {
    value: '010950.KS',
    label: '010950.KS,  S-Oil Corporation',
    name: 'S-Oil Corporation',
    symbol: '010950.KS',
  },
  {
    value: '011000.KS',
    label: '011000.KS,  GeneOne Life Science, Inc.',
    name: 'GeneOne Life Science, Inc.',
    symbol: '011000.KS',
  },
  {
    value: '011070.KS',
    label: '011070.KS,  LG Innotek Co., Ltd.',
    name: 'LG Innotek Co., Ltd.',
    symbol: '011070.KS',
  },
  {
    value: '011170.KS',
    label: '011170.KS,  Lotte Chemical Corporation',
    name: 'Lotte Chemical Corporation',
    symbol: '011170.KS',
  },
  {
    value: '011200.KS',
    label: '011200.KS,  HMM Company Limited',
    name: 'HMM Company Limited',
    symbol: '011200.KS',
  },
  {
    value: '011210.KS',
    label: '011210.KS,  HYUNDAI WIA Corporation',
    name: 'HYUNDAI WIA Corporation',
    symbol: '011210.KS',
  },
  {
    value: '011280.KS',
    label: '011280.KS,  Tailim Packaging Co., Ltd.',
    name: 'Tailim Packaging Co., Ltd.',
    symbol: '011280.KS',
  },
  {
    value: '011760.KS',
    label: '011760.KS,  Hyundai Corporation',
    name: 'Hyundai Corporation',
    symbol: '011760.KS',
  },
  {
    value: '011780.KS',
    label: '011780.KS,  Kumho Petrochemical Co., Ltd.',
    name: 'Kumho Petrochemical Co., Ltd.',
    symbol: '011780.KS',
  },
  {
    value: '011790.KS',
    label: '011790.KS,  SKC Co., Ltd.',
    name: 'SKC Co., Ltd.',
    symbol: '011790.KS',
  },
  {
    value: '011930.KS',
    label: '011930.KS,  Shinsung E&G Co., Ltd.',
    name: 'Shinsung E&G Co., Ltd.',
    symbol: '011930.KS',
  },
  {
    value: '012330.KS',
    label: '012330.KS,  Hyundai Mobis Co.,Ltd',
    name: 'Hyundai Mobis Co.,Ltd',
    symbol: '012330.KS',
  },
  {
    value: '012450.KS',
    label: '012450.KS,  Hanwha Aerospace Co., Ltd.',
    name: 'Hanwha Aerospace Co., Ltd.',
    symbol: '012450.KS',
  },
  {
    value: '012510.KS',
    label: '012510.KS,  Douzone Bizon Co., Ltd.',
    name: 'Douzone Bizon Co., Ltd.',
    symbol: '012510.KS',
  },
  {
    value: '012630.KS',
    label: '012630.KS,  HDC HOLDINGS CO.,Ltd',
    name: 'HDC HOLDINGS CO.,Ltd',
    symbol: '012630.KS',
  },
  {
    value: '012750.KS',
    label: '012750.KS,  S-1 Corporation',
    name: 'S-1 Corporation',
    symbol: '012750.KS',
  },
  {
    value: '013120.KQ',
    label: '013120.KQ,  DongWon Development Co.,Ltd.',
    name: 'DongWon Development Co.,Ltd.',
    symbol: '013120.KQ',
  },
  {
    value: '013890.KS',
    label: '013890.KS,  Zinus, Inc',
    name: 'Zinus, Inc',
    symbol: '013890.KS',
  },
  {
    value: '014620.KQ',
    label: '014620.KQ,  Sung Kwang Bend Co.,Ltd.',
    name: 'Sung Kwang Bend Co.,Ltd.',
    symbol: '014620.KQ',
  },
  {
    value: '014680.KS',
    label: '014680.KS,  Hansol Chemical Co., Ltd.',
    name: 'Hansol Chemical Co., Ltd.',
    symbol: '014680.KS',
  },
  {
    value: '014820.KS',
    label: '014820.KS,  Dongwon Systems Corporation',
    name: 'Dongwon Systems Corporation',
    symbol: '014820.KS',
  },
  {
    value: '014830.KS',
    label: '014830.KS,  UNID Company Ltd.',
    name: 'UNID Company Ltd.',
    symbol: '014830.KS',
  },
  {
    value: '015750.KQ',
    label: '015750.KQ,  Sungwoo Hitech Co., Ltd.',
    name: 'Sungwoo Hitech Co., Ltd.',
    symbol: '015750.KQ',
  },
  {
    value: '015760.KS',
    label: '015760.KS,  Korea Electric Power Corporation',
    name: 'Korea Electric Power Corporation',
    symbol: '015760.KS',
  },
  {
    value: '016360.KS',
    label: '016360.KS,  Samsung Securities Co., Ltd.',
    name: 'Samsung Securities Co., Ltd.',
    symbol: '016360.KS',
  },
  {
    value: '016380.KS',
    label: '016380.KS,  KG Dongbu Steel Co.,Ltd.',
    name: 'KG Dongbu Steel Co.,Ltd.',
    symbol: '016380.KS',
  },
  {
    value: '016580.KS',
    label: '016580.KS,  Whan In Pharm Co.,Ltd.',
    name: 'Whan In Pharm Co.,Ltd.',
    symbol: '016580.KS',
  },
  {
    value: '016610.KS',
    label: '016610.KS,  DB Financial Investment Co., Ltd.',
    name: 'DB Financial Investment Co., Ltd.',
    symbol: '016610.KS',
  },
  {
    value: '017670.KS',
    label: '017670.KS,  SK Telecom Co.,Ltd',
    name: 'SK Telecom Co.,Ltd',
    symbol: '017670.KS',
  },
  {
    value: '017800.KS',
    label: '017800.KS,  Hyundai Elevator Co., Ltd',
    name: 'Hyundai Elevator Co., Ltd',
    symbol: '017800.KS',
  },
  {
    value: '017810.KS',
    label: '017810.KS,  Pulmuone Co., Ltd.',
    name: 'Pulmuone Co., Ltd.',
    symbol: '017810.KS',
  },
  {
    value: '017940.KS',
    label: '017940.KS,  E1 Corporation',
    name: 'E1 Corporation',
    symbol: '017940.KS',
  },
  {
    value: '018000.KQ',
    label: '018000.KQ,  Unison Co., Ltd.',
    name: 'Unison Co., Ltd.',
    symbol: '018000.KQ',
  },
  {
    value: '018250.KS',
    label: '018250.KS,  Aekyung Industrial Co., Ltd.',
    name: 'Aekyung Industrial Co., Ltd.',
    symbol: '018250.KS',
  },
  {
    value: '018260.KS',
    label: '018260.KS,  Samsung SDS Co.,Ltd.',
    name: 'Samsung SDS Co.,Ltd.',
    symbol: '018260.KS',
  },
  {
    value: '018670.KS',
    label: '018670.KS,  SK Gas Co., Ltd.',
    name: 'SK Gas Co., Ltd.',
    symbol: '018670.KS',
  },
  {
    value: '018880.KS',
    label: '018880.KS,  Hanon Systems',
    name: 'Hanon Systems',
    symbol: '018880.KS',
  },
  {
    value: '019170.KS',
    label: '019170.KS,  Shin Poong Pharm.Co.,Ltd.',
    name: 'Shin Poong Pharm.Co.,Ltd.',
    symbol: '019170.KS',
  },
  {
    value: '019210.KQ',
    label: '019210.KQ,  YG-1 Co., Ltd.',
    name: 'YG-1 Co., Ltd.',
    symbol: '019210.KQ',
  },
  {
    value: '019680.KS',
    label: '019680.KS,  Daekyo Co., Ltd.',
    name: 'Daekyo Co., Ltd.',
    symbol: '019680.KS',
  },
  {
    value: '020000.KS',
    label: '020000.KS,  HANDSOME Corp.',
    name: 'HANDSOME Corp.',
    symbol: '020000.KS',
  },
  {
    value: '020150.KS',
    label: '020150.KS,  ILJIN MATERIALS Co., Ltd.',
    name: 'ILJIN MATERIALS Co., Ltd.',
    symbol: '020150.KS',
  },
  {
    value: '020560.KS',
    label: '020560.KS,  Asiana Airlines, Inc.',
    name: 'Asiana Airlines, Inc.',
    symbol: '020560.KS',
  },
  {
    value: '021240.KS',
    label: '021240.KS,  COWAY Co., Ltd.',
    name: 'COWAY Co., Ltd.',
    symbol: '021240.KS',
  },
  {
    value: '022100.KQ',
    label: '022100.KQ,  Posco ICT Company Ltd.',
    name: 'Posco ICT Company Ltd.',
    symbol: '022100.KQ',
  },
  {
    value: '023160.KQ',
    label: '023160.KQ,  Tae Kwang Corporation',
    name: 'Tae Kwang Corporation',
    symbol: '023160.KQ',
  },
  {
    value: '023410.KQ',
    label: '023410.KQ,  Eugene Corporation',
    name: 'Eugene Corporation',
    symbol: '023410.KQ',
  },
  {
    value: '023530.KS',
    label: '023530.KS,  Lotte Shopping Co., Ltd.',
    name: 'Lotte Shopping Co., Ltd.',
    symbol: '023530.KS',
  },
  {
    value: '023590.KS',
    label: '023590.KS,  Daou Technology Inc.',
    name: 'Daou Technology Inc.',
    symbol: '023590.KS',
  },
  {
    value: '024110.KS',
    label: '024110.KS,  Industrial Bank of Korea',
    name: 'Industrial Bank of Korea',
    symbol: '024110.KS',
  },
  {
    value: '024720.KS',
    label: '024720.KS,  Kolmar Korea Holdings Co., Ltd.',
    name: 'Kolmar Korea Holdings Co., Ltd.',
    symbol: '024720.KS',
  },
  {
    value: '025540.KS',
    label: '025540.KS,  Korea Electric Terminal Co., Ltd.',
    name: 'Korea Electric Terminal Co., Ltd.',
    symbol: '025540.KS',
  },
  {
    value: '025860.KS',
    label: '025860.KS,  Namhae Chemical Corporation',
    name: 'Namhae Chemical Corporation',
    symbol: '025860.KS',
  },
  {
    value: '025900.KQ',
    label: '025900.KQ,  Dongwha Enterprise Co.,Ltd',
    name: 'Dongwha Enterprise Co.,Ltd',
    symbol: '025900.KQ',
  },
  {
    value: '025980.KQ',
    label: '025980.KQ,  Ananti Inc.',
    name: 'Ananti Inc.',
    symbol: '025980.KQ',
  },
  {
    value: '026960.KS',
    label: '026960.KS,  Dong Suh Companies Inc.',
    name: 'Dong Suh Companies Inc.',
    symbol: '026960.KS',
  },
  {
    value: '027360.KQ',
    label: '027360.KQ,  Aju IB Investment Co., Ltd.',
    name: 'Aju IB Investment Co., Ltd.',
    symbol: '027360.KQ',
  },
  {
    value: '027410.KS',
    label: '027410.KS,  BGF Co., Ltd.',
    name: 'BGF Co., Ltd.',
    symbol: '027410.KS',
  },
  {
    value: '028050.KS',
    label: '028050.KS,  Samsung Engineering Co., Ltd.',
    name: 'Samsung Engineering Co., Ltd.',
    symbol: '028050.KS',
  },
  {
    value: '028260.KS',
    label: '028260.KS,  Samsung C&T Corporation',
    name: 'Samsung C&T Corporation',
    symbol: '028260.KS',
  },
  {
    value: '028300.KQ',
    label: '028300.KQ,  HLB Co., Ltd.',
    name: 'HLB Co., Ltd.',
    symbol: '028300.KQ',
  },
  {
    value: '028670.KS',
    label: '028670.KS,  Pan Ocean Co., Ltd.',
    name: 'Pan Ocean Co., Ltd.',
    symbol: '028670.KS',
  },
  {
    value: '029460.KS',
    label: '029460.KS,  KC Co., Ltd.',
    name: 'KC Co., Ltd.',
    symbol: '029460.KS',
  },
  {
    value: '029780.KS',
    label: '029780.KS,  Samsung Card Co., Ltd.',
    name: 'Samsung Card Co., Ltd.',
    symbol: '029780.KS',
  },
  {
    value: '029960.KQ',
    label: '029960.KQ,  Korea Environment Technology Co., LTD.',
    name: 'Korea Environment Technology Co., LTD.',
    symbol: '029960.KQ',
  },
  {
    value: '030000.KS',
    label: '030000.KS,  Cheil Worldwide Inc.',
    name: 'Cheil Worldwide Inc.',
    symbol: '030000.KS',
  },
  {
    value: '030190.KQ',
    label: '030190.KQ,  NICE Information Service Co., Ltd.',
    name: 'NICE Information Service Co., Ltd.',
    symbol: '030190.KQ',
  },
  {
    value: '030200.KS',
    label: '030200.KS,  KT Corporation',
    name: 'KT Corporation',
    symbol: '030200.KS',
  },
  {
    value: '030210.KS',
    label: '030210.KS,  Daol Investment & Securities Co., Ltd.',
    name: 'Daol Investment & Securities Co., Ltd.',
    symbol: '030210.KS',
  },
  {
    value: '030530.KQ',
    label: '030530.KQ,  Wonik Holdings Co., Ltd',
    name: 'Wonik Holdings Co., Ltd',
    symbol: '030530.KQ',
  },
  {
    value: '031430.KS',
    label: '031430.KS,  Shinsegae International Co., Ltd.',
    name: 'Shinsegae International Co., Ltd.',
    symbol: '031430.KS',
  },
  {
    value: '032190.KQ',
    label: '032190.KQ,  Daou Data Corp.',
    name: 'Daou Data Corp.',
    symbol: '032190.KQ',
  },
  {
    value: '032350.KS',
    label: '032350.KS,  Lotte Tour Development Co., Ltd.',
    name: 'Lotte Tour Development Co., Ltd.',
    symbol: '032350.KS',
  },
  {
    value: '032500.KQ',
    label: '032500.KQ,  Kmw Inc.',
    name: 'Kmw Inc.',
    symbol: '032500.KQ',
  },
  {
    value: '032640.KS',
    label: '032640.KS,  LG Uplus Corp.',
    name: 'LG Uplus Corp.',
    symbol: '032640.KS',
  },
  {
    value: '032830.KS',
    label: '032830.KS,  Samsung Life Insurance Co., Ltd.',
    name: 'Samsung Life Insurance Co., Ltd.',
    symbol: '032830.KS',
  },
  {
    value: '033180.KS',
    label: '033180.KS,  KH Feelux Co., Ltd.',
    name: 'KH Feelux Co., Ltd.',
    symbol: '033180.KS',
  },
  {
    value: '033270.KS',
    label: '033270.KS,  Korea United Pharm Inc.',
    name: 'Korea United Pharm Inc.',
    symbol: '033270.KS',
  },
  {
    value: '033290.KQ',
    label: '033290.KQ,  COWELL FASHION Co.,Ltd',
    name: 'COWELL FASHION Co.,Ltd',
    symbol: '033290.KQ',
  },
  {
    value: '033640.KQ',
    label: '033640.KQ,  Nepes Corporation',
    name: 'Nepes Corporation',
    symbol: '033640.KQ',
  },
  {
    value: '033780.KS',
    label: '033780.KS,  KT&G Corporation',
    name: 'KT&G Corporation',
    symbol: '033780.KS',
  },
  {
    value: '033920.KS',
    label: '033920.KS,  Muhak Co., Ltd.',
    name: 'Muhak Co., Ltd.',
    symbol: '033920.KS',
  },
  {
    value: '034020.KS',
    label: '034020.KS,  Doosan Enerbility Co., Ltd.',
    name: 'Doosan Enerbility Co., Ltd.',
    symbol: '034020.KS',
  },
  {
    value: '034220.KS',
    label: '034220.KS,  LG Display Co., Ltd.',
    name: 'LG Display Co., Ltd.',
    symbol: '034220.KS',
  },
  {
    value: '034230.KQ',
    label: '034230.KQ,  PARADISE Co., Ltd.',
    name: 'PARADISE Co., Ltd.',
    symbol: '034230.KQ',
  },
  {
    value: '034310.KS',
    label: '034310.KS,  NICE Holdings Co., Ltd.',
    name: 'NICE Holdings Co., Ltd.',
    symbol: '034310.KS',
  },
  {
    value: '034730.KS',
    label: '034730.KS,  SK Inc.',
    name: 'SK Inc.',
    symbol: '034730.KS',
  },
  {
    value: '034830.KS',
    label: '034830.KS,  KOREIT',
    name: 'KOREIT',
    symbol: '034830.KS',
  },
  {
    value: '035080.KQ',
    label: '035080.KQ,  Interpark Co., Ltd.',
    name: 'Interpark Co., Ltd.',
    symbol: '035080.KQ',
  },
  {
    value: '035250.KS',
    label: '035250.KS,  Kangwon Land, Inc.',
    name: 'Kangwon Land, Inc.',
    symbol: '035250.KS',
  },
  {
    value: '035600.KQ',
    label: '035600.KQ,  Kginicis Co.,Ltd',
    name: 'Kginicis Co.,Ltd',
    symbol: '035600.KQ',
  },
  {
    value: '035720.KS',
    label: '035720.KS,  Kakao Corp.',
    name: 'Kakao Corp.',
    symbol: '035720.KS',
  },
  {
    value: '035760.KQ',
    label: '035760.KQ,  CJ ENM CO., Ltd.',
    name: 'CJ ENM CO., Ltd.',
    symbol: '035760.KQ',
  },
  {
    value: '035810.KQ',
    label: '035810.KQ,  EASY HOLDINGS Co., Ltd.',
    name: 'EASY HOLDINGS Co., Ltd.',
    symbol: '035810.KQ',
  },
  {
    value: '035900.KQ',
    label: '035900.KQ,  JYP Entertainment Corporation',
    name: 'JYP Entertainment Corporation',
    symbol: '035900.KQ',
  },
  {
    value: '036420.KS',
    label: '036420.KS,  ContentreeJoongAng corp.',
    name: 'ContentreeJoongAng corp.',
    symbol: '036420.KS',
  },
  {
    value: '036460.KS',
    label: '036460.KS,  Korea Gas Corporation',
    name: 'Korea Gas Corporation',
    symbol: '036460.KS',
  },
  {
    value: '036540.KQ',
    label: '036540.KQ,  SFA Semicon Co., Ltd.',
    name: 'SFA Semicon Co., Ltd.',
    symbol: '036540.KQ',
  },
  {
    value: '036570.KS',
    label: '036570.KS,  Ncsoft Corporation',
    name: 'Ncsoft Corporation',
    symbol: '036570.KS',
  },
  {
    value: '036630.KQ',
    label: '036630.KQ,  Sejong Telecom, Inc.',
    name: 'Sejong Telecom, Inc.',
    symbol: '036630.KQ',
  },
  {
    value: '036830.KQ',
    label: '036830.KQ,  Soulbrain Holdings Co., Ltd.',
    name: 'Soulbrain Holdings Co., Ltd.',
    symbol: '036830.KQ',
  },
  {
    value: '036930.KQ',
    label: '036930.KQ,  JUSUNG ENGINEERING Co., Ltd.',
    name: 'JUSUNG ENGINEERING Co., Ltd.',
    symbol: '036930.KQ',
  },
  {
    value: '037560.KS',
    label: '037560.KS,  LG HelloVision Corp.',
    name: 'LG HelloVision Corp.',
    symbol: '037560.KS',
  },
  {
    value: '038540.KQ',
    label: '038540.KQ,  Sangsangin Co., Ltd.',
    name: 'Sangsangin Co., Ltd.',
    symbol: '038540.KQ',
  },
  {
    value: '039030.KQ',
    label: '039030.KQ,  EO Technics Co., Ltd.',
    name: 'EO Technics Co., Ltd.',
    symbol: '039030.KQ',
  },
  {
    value: '039200.KQ',
    label: '039200.KQ,  Oscotec Inc.',
    name: 'Oscotec Inc.',
    symbol: '039200.KQ',
  },
  {
    value: '039490.KS',
    label: '039490.KS,  Kiwoom Securities Co., Ltd.',
    name: 'Kiwoom Securities Co., Ltd.',
    symbol: '039490.KS',
  },
  {
    value: '039840.KQ',
    label: '039840.KQ,  DIO Corporation',
    name: 'DIO Corporation',
    symbol: '039840.KQ',
  },
  {
    value: '040910.KQ',
    label: '040910.KQ,  ICD Co., Ltd.',
    name: 'ICD Co., Ltd.',
    symbol: '040910.KQ',
  },
  {
    value: '041190.KQ',
    label: '041190.KQ,  Woori Technology Investment Co., Ltd',
    name: 'Woori Technology Investment Co., Ltd',
    symbol: '041190.KQ',
  },
  {
    value: '041510.KQ',
    label: '041510.KQ,  SM Entertainment Co., Ltd.',
    name: 'SM Entertainment Co., Ltd.',
    symbol: '041510.KQ',
  },
  {
    value: '041830.KQ',
    label: '041830.KQ,  InBody Co.,Ltd',
    name: 'InBody Co.,Ltd',
    symbol: '041830.KQ',
  },
  {
    value: '042660.KS',
    label: '042660.KS,  Daewoo Shipbuilding & Marine Engineering Co., Ltd.',
    name: 'Daewoo Shipbuilding & Marine Engineering Co., Ltd.',
    symbol: '042660.KS',
  },
  {
    value: '042670.KS',
    label: '042670.KS,  Hyundai Doosan Infracore Co., Ltd.',
    name: 'Hyundai Doosan Infracore Co., Ltd.',
    symbol: '042670.KS',
  },
  {
    value: '042700.KS',
    label: '042700.KS,  HANMI Semiconductor Co., Ltd.',
    name: 'HANMI Semiconductor Co., Ltd.',
    symbol: '042700.KS',
  },
  {
    value: '043150.KQ',
    label: '043150.KQ,  Value Added Technology Co., Ltd.',
    name: 'Value Added Technology Co., Ltd.',
    symbol: '043150.KQ',
  },
  {
    value: '044340.KQ',
    label: '044340.KQ,  Winix Inc.',
    name: 'Winix Inc.',
    symbol: '044340.KQ',
  },
  {
    value: '044490.KQ',
    label: '044490.KQ,  Taewoong Co.,Ltd',
    name: 'Taewoong Co.,Ltd',
    symbol: '044490.KQ',
  },
  {
    value: '045390.KQ',
    label: '045390.KQ,  DAEA TI Co., Ltd.',
    name: 'DAEA TI Co., Ltd.',
    symbol: '045390.KQ',
  },
  {
    value: '046890.KQ',
    label: '046890.KQ,  Seoul Semiconductor Co., Ltd.',
    name: 'Seoul Semiconductor Co., Ltd.',
    symbol: '046890.KQ',
  },
  {
    value: '047040.KS',
    label: '047040.KS,  Daewoo Engineering & Construction Co., Ltd.',
    name: 'Daewoo Engineering & Construction Co., Ltd.',
    symbol: '047040.KS',
  },
  {
    value: '047050.KS',
    label: '047050.KS,  Posco International Corporation',
    name: 'Posco International Corporation',
    symbol: '047050.KS',
  },
  {
    value: '047810.KS',
    label: '047810.KS,  Korea Aerospace Industries, Ltd.',
    name: 'Korea Aerospace Industries, Ltd.',
    symbol: '047810.KS',
  },
  {
    value: '048260.KQ',
    label: '048260.KQ,  Osstem Implant Co., Ltd.',
    name: 'Osstem Implant Co., Ltd.',
    symbol: '048260.KQ',
  },
  {
    value: '048410.KQ',
    label: '048410.KQ,  Hyundai Bioscience Co., Ltd.',
    name: 'Hyundai Bioscience Co., Ltd.',
    symbol: '048410.KQ',
  },
  {
    value: '048530.KQ',
    label: '048530.KQ,  iNtRON Biotechnology, Inc.',
    name: 'iNtRON Biotechnology, Inc.',
    symbol: '048530.KQ',
  },
  {
    value: '049070.KQ',
    label: '049070.KQ,  Intops Co., Ltd.',
    name: 'Intops Co., Ltd.',
    symbol: '049070.KQ',
  },
  {
    value: '049770.KS',
    label: '049770.KS,  Dongwon F&B Co., Ltd.',
    name: 'Dongwon F&B Co., Ltd.',
    symbol: '049770.KS',
  },
  {
    value: '049950.KQ',
    label: '049950.KQ,  meerecompany Incorporated',
    name: 'meerecompany Incorporated',
    symbol: '049950.KQ',
  },
  {
    value: '051370.KQ',
    label: '051370.KQ,  Interflex Co., Ltd.',
    name: 'Interflex Co., Ltd.',
    symbol: '051370.KQ',
  },
  {
    value: '051600.KS',
    label: '051600.KS,  KEPCO Plant Service & Engineering Co.,Ltd.',
    name: 'KEPCO Plant Service & Engineering Co.,Ltd.',
    symbol: '051600.KS',
  },
  {
    value: '051900.KS',
    label: '051900.KS,  LG H&H Co., Ltd.',
    name: 'LG H&H Co., Ltd.',
    symbol: '051900.KS',
  },
  {
    value: '051905.KS',
    label: '051905.KS,  LG H&H Co., Ltd.',
    name: 'LG H&H Co., Ltd.',
    symbol: '051905.KS',
  },
  {
    value: '051910.KS',
    label: '051910.KS,  LG Chem, Ltd.',
    name: 'LG Chem, Ltd.',
    symbol: '051910.KS',
  },
  {
    value: '052400.KQ',
    label: '052400.KQ,  Kona I Co.,Ltd.',
    name: 'Kona I Co.,Ltd.',
    symbol: '052400.KQ',
  },
  {
    value: '052690.KS',
    label: '052690.KS,  KEPCO Engineering & Construction Company, Inc.',
    name: 'KEPCO Engineering & Construction Company, Inc.',
    symbol: '052690.KS',
  },
  {
    value: '053030.KQ',
    label: '053030.KQ,  BINEX Co., Ltd.',
    name: 'BINEX Co., Ltd.',
    symbol: '053030.KQ',
  },
  {
    value: '053210.KS',
    label: '053210.KS,  KT Skylife Co., Ltd.',
    name: 'KT Skylife Co., Ltd.',
    symbol: '053210.KS',
  },
  {
    value: '055550.KS',
    label: '055550.KS,  Shinhan Financial Group Co., Ltd.',
    name: 'Shinhan Financial Group Co., Ltd.',
    symbol: '055550.KS',
  },
  {
    value: 'ZV.MI',
    label: 'ZV.MI,  Zignago Vetro S.p.A.',
    name: 'Zignago Vetro S.p.A.',
    symbol: 'ZV.MI',
  },
  {
    value: 'ZUR.TA',
    label: 'ZUR.TA,  Zur Shamir Holdings Ltd',
    name: 'Zur Shamir Holdings Ltd',
    symbol: 'ZUR.TA',
  },
  {
    value: 'ZOREN.IS',
    label: 'ZOREN.IS,  Zorlu Enerji Elektrik Üretim A.S.',
    name: 'Zorlu Enerji Elektrik Üretim A.S.',
    symbol: 'ZOREN.IS',
  },
  {
    value: 'ZIM',
    label: 'ZIM,  ZIM Integrated Shipping Services Ltd.',
    name: 'ZIM Integrated Shipping Services Ltd.',
    symbol: 'ZIM',
  },
  {
    value: 'ZETA',
    label: 'ZETA,  Zeta Global Holdings Corp.',
    name: 'Zeta Global Holdings Corp.',
    symbol: 'ZETA',
  },
  {
    value: 'ZEP.WA',
    label: 'ZEP.WA,  Zespól Elektrowni Patnów-Adamów-Konin S.A.',
    name: 'Zespól Elektrowni Patnów-Adamów-Konin S.A.',
    symbol: 'ZEP.WA',
  },
  {
    value: 'YMTX',
    label: 'YMTX,  Yumanity Therapeutics, Inc.',
    name: 'Yumanity Therapeutics, Inc.',
    symbol: 'YMTX',
  },
  {
    value: 'YKBNK.IS',
    label: 'YKBNK.IS,  Yapi ve Kredi Bankasi A.S.',
    name: 'Yapi ve Kredi Bankasi A.S.',
    symbol: 'YKBNK.IS',
  },
  {
    value: 'YIT.HE',
    label: 'YIT.HE,  YIT Oyj',
    name: 'YIT Oyj',
    symbol: 'YIT.HE',
  },
  {
    value: 'YDUQ3.SA',
    label: 'YDUQ3.SA,  Yduqs Participações S.A.',
    name: 'Yduqs Participações S.A.',
    symbol: 'YDUQ3.SA',
  },
  {
    value: 'YATAS.IS',
    label: 'YATAS.IS,  Yatas Yatak ve Yorgan Sanayi ve Ticaret A.S.',
    name: 'Yatas Yatak ve Yorgan Sanayi ve Ticaret A.S.',
    symbol: 'YATAS.IS',
  },
  {
    value: 'XVIVO.ST',
    label: 'XVIVO.ST,  Xvivo Perfusion AB (publ)',
    name: 'Xvivo Perfusion AB (publ)',
    symbol: 'XVIVO.ST',
  },
  {
    value: 'XTB.WA',
    label: 'XTB.WA,  XTB S.A.',
    name: 'XTB S.A.',
    symbol: 'XTB.WA',
  },
  {
    value: 'XOMAP',
    label: 'XOMAP,  XOMA Corporation',
    name: 'XOMA Corporation',
    symbol: 'XOMAP',
  },
  {
    value: 'WWL.WA',
    label: 'WWL.WA,  Wawel S.A.',
    name: 'Wawel S.A.',
    symbol: 'WWL.WA',
  },
  {
    value: 'WWIB.OL',
    label: 'WWIB.OL,  Wilh. Wilhelmsen Holding ASA',
    name: 'Wilh. Wilhelmsen Holding ASA',
    symbol: 'WWIB.OL',
  },
  {
    value: 'WSKT.JK',
    label: 'WSKT.JK,  PT Waskita Karya (Persero) Tbk',
    name: 'PT Waskita Karya (Persero) Tbk',
    symbol: 'WSKT.JK',
  },
  {
    value: 'WRT1V.HE',
    label: 'WRT1V.HE,  Wärtsilä Oyj Abp',
    name: 'Wärtsilä Oyj Abp',
    symbol: 'WRT1V.HE',
  },
  {
    value: 'WRAP',
    label: 'WRAP,  Wrap Technologies, Inc.',
    name: 'Wrap Technologies, Inc.',
    symbol: 'WRAP',
  },
  {
    value: 'WPL.WA',
    label: 'WPL.WA,  Wirtualna Polska Holding S.A.',
    name: 'Wirtualna Polska Holding S.A.',
    symbol: 'WPL.WA',
  },
  {
    value: 'WLT.WA',
    label: 'WLT.WA,  Wielton S.A.',
    name: 'Wielton S.A.',
    symbol: 'WLT.WA',
  },
  {
    value: 'WIZS3.SA',
    label: 'WIZS3.SA,  Wiz Soluções e Corretagem de Seguros S.A.',
    name: 'Wiz Soluções e Corretagem de Seguros S.A.',
    symbol: 'WIZS3.SA',
  },
  {
    value: 'WIX.L',
    label: 'WIX.L,  Wickes Group plc',
    name: 'Wickes Group plc',
    symbol: 'WIX.L',
  },
  {
    value: 'WIKA.JK',
    label: 'WIKA.JK,  PT Wijaya Karya (Persero) Tbk',
    name: 'PT Wijaya Karya (Persero) Tbk',
    symbol: 'WIKA.JK',
  },
  {
    value: 'WIHL.ST',
    label: 'WIHL.ST,  Wihlborgs Fastigheter AB (publ)',
    name: 'Wihlborgs Fastigheter AB (publ)',
    symbol: 'WIHL.ST',
  },
  {
    value: 'WHAUP-R.BK',
    label: 'WHAUP-R.BK,  WHA Utilities and Power Public Company Limited',
    name: 'WHA Utilities and Power Public Company Limited',
    symbol: 'WHAUP-R.BK',
  },
  {
    value: 'WHA-R.BK',
    label: 'WHA-R.BK,  WHA Corporation Public Company Limited',
    name: 'WHA Corporation Public Company Limited',
    symbol: 'WHA-R.BK',
  },
  {
    value: 'WFRD',
    label: 'WFRD,  Weatherford International plc',
    name: 'Weatherford International plc',
    symbol: 'WFRD',
  },
  {
    value: 'WFG.TO',
    label: 'WFG.TO,  West Fraser Timber Co. Ltd.',
    name: 'West Fraser Timber Co. Ltd.',
    symbol: 'WFG.TO',
  },
  {
    value: 'WEGE3.SA',
    label: 'WEGE3.SA,  WEG S.A.',
    name: 'WEG S.A.',
    symbol: 'WEGE3.SA',
  },
  {
    value: 'WEBR',
    label: 'WEBR,  Weber Inc.',
    name: 'Weber Inc.',
    symbol: 'WEBR',
  },
  {
    value: 'WDI',
    label: 'WDI,  Western Asset Diversified Income Fund',
    name: 'Western Asset Diversified Income Fund',
    symbol: 'WDI',
  },
  {
    value: 'WBD.MI',
    label: 'WBD.MI,  Webuild S.p.A.',
    name: 'Webuild S.p.A.',
    symbol: 'WBD.MI',
  },
  {
    value: 'WAWI.OL',
    label: 'WAWI.OL,  Wallenius Wilhelmsen ASA',
    name: 'Wallenius Wilhelmsen ASA',
    symbol: 'WAWI.OL',
  },
  {
    value: 'WALMEX.MX',
    label: 'WALMEX.MX,  Wal-Mart de México, S.A.B. de C.V.',
    name: 'Wal-Mart de México, S.A.B. de C.V.',
    symbol: 'WALMEX.MX',
  },
  {
    value: 'WAFDP',
    label: 'WAFDP,  Washington Federal, Inc.',
    name: 'Washington Federal, Inc.',
    symbol: 'WAFDP',
  },
  {
    value: 'VYNE',
    label: 'VYNE,  VYNE Therapeutics Inc.',
    name: 'VYNE Therapeutics Inc.',
    symbol: 'VYNE',
  },
  {
    value: 'VWS.CO',
    label: 'VWS.CO,  Vestas Wind Systems A/S',
    name: 'Vestas Wind Systems A/S',
    symbol: 'VWS.CO',
  },
  {
    value: 'VWE',
    label: 'VWE,  Vintage Wine Estates, Inc.',
    name: 'Vintage Wine Estates, Inc.',
    symbol: 'VWE',
  },
  {
    value: 'VWAGY',
    label: 'VWAGY,  Volkswagen AG',
    name: 'Volkswagen AG',
    symbol: 'VWAGY',
  },
  {
    value: 'VULC3.SA',
    label: 'VULC3.SA,  Vulcabras S.A.',
    name: 'Vulcabras S.A.',
    symbol: 'VULC3.SA',
  },
  {
    value: 'VTWR.DE',
    label: 'VTWR.DE,  Vantage Towers AG',
    name: 'Vantage Towers AG',
    symbol: 'VTWR.DE',
  },
  {
    value: 'VS',
    label: 'VS,  Versus Systems Inc.',
    name: 'Versus Systems Inc.',
    symbol: 'VS',
  },
  {
    value: 'VRNOF',
    label: 'VRNOF,  Verano Holdings Corp.',
    name: 'Verano Holdings Corp.',
    symbol: 'VRNOF',
  },
  {
    value: 'VRG.WA',
    label: 'VRG.WA,  VRG S.A.',
    name: 'VRG S.A.',
    symbol: 'VRG.WA',
  },
  {
    value: 'VOLUE.OL',
    label: 'VOLUE.OL,  Volue ASA',
    name: 'Volue ASA',
    symbol: 'VOLUE.OL',
  },
  {
    value: 'VOLARA.MX',
    label:
      'VOLARA.MX,  Controladora Vuela Compañía de Aviación, S.A.B. de C.V.',
    name: 'Controladora Vuela Compañía de Aviación, S.A.B. de C.V.',
    symbol: 'VOLARA.MX',
  },
  {
    value: 'VNV.ST',
    label: 'VNV.ST,  VNV Global AB (publ)',
    name: 'VNV Global AB (publ)',
    symbol: 'VNV.ST',
  },
  {
    value: 'VLID3.SA',
    label: 'VLID3.SA,  Valid Soluções S.A.',
    name: 'Valid Soluções S.A.',
    symbol: 'VLID3.SA',
  },
  {
    value: 'VIVT3.SA',
    label: 'VIVT3.SA,  Telefônica Brasil S.A.',
    name: 'Telefônica Brasil S.A.',
    symbol: 'VIVT3.SA',
  },
  {
    value: 'VIVA3.SA',
    label: 'VIVA3.SA,  Vivara Participações S.A.',
    name: 'Vivara Participações S.A.',
    symbol: 'VIVA3.SA',
  },
  {
    value: 'VITR.ST',
    label: 'VITR.ST,  Vitrolife AB (publ)',
    name: 'Vitrolife AB (publ)',
    symbol: 'VITR.ST',
  },
  {
    value: 'VIS.MC',
    label: 'VIS.MC,  Viscofan, S.A.',
    name: 'Viscofan, S.A.',
    symbol: 'VIS.MC',
  },
  {
    value: 'VINP',
    label: 'VINP,  Vinci Partners Investments Ltd.',
    name: 'Vinci Partners Investments Ltd.',
    symbol: 'VINP',
  },
  {
    value: 'VIIA3.SA',
    label: 'VIIA3.SA,  Via S.A.',
    name: 'Via S.A.',
    symbol: 'VIIA3.SA',
  },
  {
    value: 'VID.MC',
    label: 'VID.MC,  Vidrala, S.A.',
    name: 'Vidrala, S.A.',
    symbol: 'VID.MC',
  },
  {
    value: 'VIA',
    label: 'VIA,  Via Renewables, Inc.',
    name: 'Via Renewables, Inc.',
    symbol: 'VIA',
  },
  {
    value: 'VHP.NZ',
    label: 'VHP.NZ,  Vital Healthcare Property Trust',
    name: 'Vital Healthcare Property Trust',
    symbol: 'VHP.NZ',
  },
  {
    value: 'VGO.WA',
    label: 'VGO.WA,  VIGO Photonics S.A.',
    name: 'VIGO Photonics S.A.',
    symbol: 'VGO.WA',
  },
  {
    value: 'VGI-R.BK',
    label: 'VGI-R.BK,  VGI Public Company Limited',
    name: 'VGI Public Company Limited',
    symbol: 'VGI-R.BK',
  },
  {
    value: 'VFQS.QA',
    label: 'VFQS.QA,  Vodafone Qatar P.Q.S.C.',
    name: 'Vodafone Qatar P.Q.S.C.',
    symbol: 'VFQS.QA',
  },
  {
    value: 'VEXTF',
    label: 'VEXTF,  Vext Science, Inc.',
    name: 'Vext Science, Inc.',
    symbol: 'VEXTF',
  },
  {
    value: 'VESTL.IS',
    label: 'VESTL.IS,  Vestel Elektronik Sanayi ve Ticaret Anonim Sirketi',
    name: 'Vestel Elektronik Sanayi ve Ticaret Anonim Sirketi',
    symbol: 'VESTL.IS',
  },
  {
    value: 'VESTA.MX',
    label: 'VESTA.MX,  Corporación Inmobiliaria Vesta, S.A.B. de C.V.',
    name: 'Corporación Inmobiliaria Vesta, S.A.B. de C.V.',
    symbol: 'VESTA.MX',
  },
  {
    value: 'VCTR.TA',
    label: 'VCTR.TA,  Victory Supermarket Chain Ltd',
    name: 'Victory Supermarket Chain Ltd',
    symbol: 'VCTR.TA',
  },
  {
    value: 'VAMO3.SA',
    label:
      'VAMO3.SA,  Vamos Locação de Caminhões, Máquinas e Equipamentos S.A.',
    name: 'Vamos Locação de Caminhões, Máquinas e Equipamentos S.A.',
    symbol: 'VAMO3.SA',
  },
  {
    value: 'VALN',
    label: 'VALN,  Valneva SE',
    name: 'Valneva SE',
    symbol: 'VALN',
  },
  {
    value: 'VALMT.HE',
    label: 'VALMT.HE,  Valmet Oyj',
    name: 'Valmet Oyj',
    symbol: 'VALMT.HE',
  },
  {
    value: 'VALE3.SA',
    label: 'VALE3.SA,  Vale S.A.',
    name: 'Vale S.A.',
    symbol: 'VALE3.SA',
  },
  {
    value: 'VAL',
    label: 'VAL,  Valaris Limited',
    name: 'Valaris Limited',
    symbol: 'VAL',
  },
  {
    value: 'VAKBN.IS',
    label: 'VAKBN.IS,  Türkiye Vakiflar Bankasi Türk Anonim Ortakligi',
    name: 'Türkiye Vakiflar Bankasi Türk Anonim Ortakligi',
    symbol: 'VAKBN.IS',
  },
  {
    value: 'VAIAS.HE',
    label: 'VAIAS.HE,  Vaisala Oyj',
    name: 'Vaisala Oyj',
    symbol: 'VAIAS.HE',
  },
  {
    value: 'USNZY',
    label: 'USNZY,  Usinas Siderúrgicas de Minas Gerais S.A.',
    name: 'Usinas Siderúrgicas de Minas Gerais S.A.',
    symbol: 'USNZY',
  },
  {
    value: 'USIM5.SA',
    label: 'USIM5.SA,  Usinas Siderúrgicas de Minas Gerais S.A.',
    name: 'Usinas Siderúrgicas de Minas Gerais S.A.',
    symbol: 'USIM5.SA',
  },
  {
    value: 'URBI.MX',
    label: 'URBI.MX,  Urbi, Desarrollos Urbanos, S.A.B. de C.V.',
    name: 'Urbi, Desarrollos Urbanos, S.A.B. de C.V.',
    symbol: 'URBI.MX',
  },
  {
    value: 'UPONOR.HE',
    label: 'UPONOR.HE,  Uponor Oyj',
    name: 'Uponor Oyj',
    symbol: 'UPONOR.HE',
  },
  {
    value: 'UPM.HE',
    label: 'UPM.HE,  UPM-Kymmene Oyj',
    name: 'UPM-Kymmene Oyj',
    symbol: 'UPM.HE',
  },
  {
    value: 'UPH',
    label: 'UPH,  UpHealth, Inc.',
    name: 'UpHealth, Inc.',
    symbol: 'UPH',
  },
  {
    value: 'UP',
    label: 'UP,  Wheels Up Experience Inc.',
    name: 'Wheels Up Experience Inc.',
    symbol: 'UP',
  },
  {
    value: 'UNVR.JK',
    label: 'UNVR.JK,  PT Unilever Indonesia Tbk',
    name: 'PT Unilever Indonesia Tbk',
    symbol: 'UNVR.JK',
  },
  {
    value: 'UNTR.JK',
    label: 'UNTR.JK,  PT United Tractors Tbk',
    name: 'PT United Tractors Tbk',
    symbol: 'UNTR.JK',
  },
  {
    value: 'UNIR.MI',
    label: 'UNIR.MI,  Unieuro S.p.A.',
    name: 'Unieuro S.p.A.',
    symbol: 'UNIR.MI',
  },
  {
    value: 'UNIP6.SA',
    label: 'UNIP6.SA,  Unipar Carbocloro S.A.',
    name: 'Unipar Carbocloro S.A.',
    symbol: 'UNIP6.SA',
  },
  {
    value: 'UNI.MC',
    label: 'UNI.MC,  Unicaja Banco, S.A.',
    name: 'Unicaja Banco, S.A.',
    symbol: 'UNI.MC',
  },
  {
    value: 'UNIFINA.MX',
    label: 'UNIFINA.MX,  Unifin Financiera, S. A. B. de C. V.',
    name: 'Unifin Financiera, S. A. B. de C. V.',
    symbol: 'UNIFINA.MX',
  },
  {
    value: 'UNBK',
    label: 'UNBK,  United National Bank',
    name: 'United National Bank',
    symbol: 'UNBK',
  },
  {
    value: 'UMI',
    label: 'UMI,  USCF Midstream Energy Income Fund',
    name: 'USCF Midstream Energy Income Fund',
    symbol: 'UMI',
  },
  {
    value: 'ULUSE.IS',
    label: 'ULUSE.IS,  Ulusoy Elektrik Imalat Taahhüt ve Ticaret A.S.',
    name: 'Ulusoy Elektrik Imalat Taahhüt ve Ticaret A.S.',
    symbol: 'ULUSE.IS',
  },
  {
    value: 'ULTI.OL',
    label: 'ULTI.OL,  Ultimovacs ASA',
    name: 'Ultimovacs ASA',
    symbol: 'ULTI.OL',
  },
  {
    value: 'UK',
    label: 'UK,  Ucommune International Ltd',
    name: 'Ucommune International Ltd',
    symbol: 'UK',
  },
  {
    value: 'UGPA3.SA',
    label: 'UGPA3.SA,  Ultrapar Participações S.A.',
    name: 'Ultrapar Participações S.A.',
    symbol: 'UGPA3.SA',
  },
  {
    value: 'UGIC',
    label: 'UGIC,  UGI Corporation',
    name: 'UGI Corporation',
    symbol: 'UGIC',
  },
  {
    value: 'UDCD.QA',
    label: 'UDCD.QA,  United Development Company Q.P.S.C.',
    name: 'United Development Company Q.P.S.C.',
    symbol: 'UDCD.QA',
  },
  {
    value: 'TYRES.HE',
    label: 'TYRES.HE,  Nokian Renkaat Oyj',
    name: 'Nokian Renkaat Oyj',
    symbol: 'TYRES.HE',
  },
  {
    value: 'TWR.NZ',
    label: 'TWR.NZ,  Tower Limited',
    name: 'Tower Limited',
    symbol: 'TWR.NZ',
  },
  {
    value: 'TVO-R.BK',
    label: 'TVO-R.BK,  Thai Vegetable Oil Public Company Limited',
    name: 'Thai Vegetable Oil Public Company Limited',
    symbol: 'TVO-R.BK',
  },
  {
    value: 'TURSG.IS',
    label: 'TURSG.IS,  Türkiye Sigorta Anonim Sirketi',
    name: 'Türkiye Sigorta Anonim Sirketi',
    symbol: 'TURSG.IS',
  },
  {
    value: 'TUPY3.SA',
    label: 'TUPY3.SA,  Tupy S.A.',
    name: 'Tupy S.A.',
    symbol: 'TUPY3.SA',
  },
  {
    value: 'TUPRS.IS',
    label: 'TUPRS.IS,  Türkiye Petrol Rafinerileri A.S.',
    name: 'Türkiye Petrol Rafinerileri A.S.',
    symbol: 'TUPRS.IS',
  },
  {
    value: 'TTW-R.BK',
    label: 'TTW-R.BK,  TTW Public Company Limited',
    name: 'TTW Public Company Limited',
    symbol: 'TTW-R.BK',
  },
  {
    value: 'TTRAK.IS',
    label: 'TTRAK.IS,  Türk Traktör ve Ziraat Makineleri A.S.',
    name: 'Türk Traktör ve Ziraat Makineleri A.S.',
    symbol: 'TTRAK.IS',
  },
  {
    value: 'TTE',
    label: 'TTE,  TotalEnergies SE',
    name: 'TotalEnergies SE',
    symbol: 'TTE',
  },
  {
    value: 'TTALO.HE',
    label: 'TTALO.HE,  Terveystalo Oyj',
    name: 'Terveystalo Oyj',
    symbol: 'TTALO.HE',
  },
  {
    value: 'TSLVF',
    label: 'TSLVF,  Tier One Silver Inc.',
    name: 'Tier One Silver Inc.',
    symbol: 'TSLVF',
  },
  {
    value: 'TSKB.IS',
    label: 'TSKB.IS,  Türkiye Sinai Kalkinma Bankasi A.S.',
    name: 'Türkiye Sinai Kalkinma Bankasi A.S.',
    symbol: 'TSKB.IS',
  },
  {
    value: 'TSEM.TA',
    label: 'TSEM.TA,  Tower Semiconductor Ltd.',
    name: 'Tower Semiconductor Ltd.',
    symbol: 'TSEM.TA',
  },
  {
    value: 'TRYG.CO',
    label: 'TRYG.CO,  Tryg A/S',
    name: 'Tryg A/S',
    symbol: 'TRYG.CO',
  },
  {
    value: 'TRUE-R.BK',
    label: 'TRUE-R.BK,  True Corporation Public Company Limited',
    name: 'True Corporation Public Company Limited',
    symbol: 'TRUE-R.BK',
  },
  {
    value: 'TRSSF',
    label: 'TRSSF,  TerrAscend Corp.',
    name: 'TerrAscend Corp.',
    symbol: 'TRSSF',
  },
  {
    value: 'TRPL4.SA',
    label:
      'TRPL4.SA,  CTEEP - Companhia de Transmissão de Energia Elétrica Paulista S.A.',
    name: 'CTEEP - Companhia de Transmissão de Energia Elétrica Paulista S.A.',
    symbol: 'TRPL4.SA',
  },
  {
    value: 'TROAX.ST',
    label: 'TROAX.ST,  Troax Group AB (publ)',
    name: 'Troax Group AB (publ)',
    symbol: 'TROAX.ST',
  },
  {
    value: 'TRN.MI',
    label: 'TRN.MI,  Terna - Rete Elettrica Nazionale Società per Azioni',
    name: 'Terna - Rete Elettrica Nazionale Società per Azioni',
    symbol: 'TRN.MI',
  },
  {
    value: 'TRMR',
    label: 'TRMR,  Tremor International Ltd',
    name: 'Tremor International Ltd',
    symbol: 'TRMR',
  },
  {
    value: 'TRIS3.SA',
    label: 'TRIS3.SA,  Trisul S.A.',
    name: 'Trisul S.A.',
    symbol: 'TRIS3.SA',
  },
  {
    value: 'TRIN',
    label: 'TRIN,  Trinity Capital Inc.',
    name: 'Trinity Capital Inc.',
    symbol: 'TRIN',
  },
  {
    value: 'TRE.MC',
    label: 'TRE.MC,  Técnicas Reunidas, S.A.',
    name: 'Técnicas Reunidas, S.A.',
    symbol: 'TRE.MC',
  },
  {
    value: 'TRAXIONA.MX',
    label: 'TRAXIONA.MX,  Grupo Traxión, S.A.B. de C.V.',
    name: 'Grupo Traxión, S.A.B. de C.V.',
    symbol: 'TRAXIONA.MX',
  },
  {
    value: 'TQM-R.BK',
    label: 'TQM-R.BK,  TQM Alpha Public Company Limited',
    name: 'TQM Alpha Public Company Limited',
    symbol: 'TQM-R.BK',
  },
  {
    value: 'TPZ.TO',
    label: 'TPZ.TO,  Topaz Energy Corp.',
    name: 'Topaz Energy Corp.',
    symbol: 'TPZ.TO',
  },
  {
    value: 'TPST',
    label: 'TPST,  Tempest Therapeutics, Inc.',
    name: 'Tempest Therapeutics, Inc.',
    symbol: 'TPST',
  },
  {
    value: 'TPIPP-R.BK',
    label: 'TPIPP-R.BK,  TPI Polene Power Public Company Limited',
    name: 'TPI Polene Power Public Company Limited',
    symbol: 'TPIPP-R.BK',
  },
  {
    value: 'TPIPL-R.BK',
    label: 'TPIPL-R.BK,  TPI Polene Public Company Limited',
    name: 'TPI Polene Public Company Limited',
    symbol: 'TPIPL-R.BK',
  },
  {
    value: 'TPIA.JK',
    label: 'TPIA.JK,  PT. Chandra Asri Petrochemical Tbk',
    name: 'PT. Chandra Asri Petrochemical Tbk',
    symbol: 'TPIA.JK',
  },
  {
    value: 'TPIA',
    label: 'TPIA,  Mycotopia Therapies, Inc.',
    name: 'Mycotopia Therapies, Inc.',
    symbol: 'TPIA',
  },
  {
    value: 'TPE.WA',
    label: 'TPE.WA,  TAURON Polska Energia S.A.',
    name: 'TAURON Polska Energia S.A.',
    symbol: 'TPE.WA',
  },
  {
    value: 'TOWR.JK',
    label: 'TOWR.JK,  PT Sarana Menara Nusantara Tbk.',
    name: 'PT Sarana Menara Nusantara Tbk.',
    symbol: 'TOWR.JK',
  },
  {
    value: 'TOTS3.SA',
    label: 'TOTS3.SA,  TOTVS S.A.',
    name: 'TOTVS S.A.',
    symbol: 'TOTS3.SA',
  },
  {
    value: 'TOP-R.BK',
    label: 'TOP-R.BK,  Thai Oil Public Company Limited',
    name: 'Thai Oil Public Company Limited',
    symbol: 'TOP-R.BK',
  },
  {
    value: 'TOP.CO',
    label: 'TOP.CO,  Topdanmark A/S',
    name: 'Topdanmark A/S',
    symbol: 'TOP.CO',
  },
  {
    value: 'TOKMAN.HE',
    label: 'TOKMAN.HE,  Tokmanni Group Oyj',
    name: 'Tokmanni Group Oyj',
    symbol: 'TOKMAN.HE',
  },
  {
    value: 'TOD.MI',
    label: "TOD.MI,  TOD'S S.p.A.",
    name: "TOD'S S.p.A.",
    symbol: 'TOD.MI',
  },
  {
    value: 'TOASO.IS',
    label: 'TOASO.IS,  Tofas Türk Otomobil Fabrikasi Anonim Sirketi',
    name: 'Tofas Türk Otomobil Fabrikasi Anonim Sirketi',
    symbol: 'TOASO.IS',
  },
  {
    value: 'TNXT.MI',
    label: 'TNXT.MI,  Tinexta S.p.A.',
    name: 'Tinexta S.p.A.',
    symbol: 'TNXT.MI',
  },
  {
    value: 'TNOM.HE',
    label: 'TNOM.HE,  Talenom Oyj',
    name: 'Talenom Oyj',
    symbol: 'TNOM.HE',
  },
  {
    value: 'TLRY.TO',
    label: 'TLRY.TO,  Tilray Brands, Inc.',
    name: 'Tilray Brands, Inc.',
    symbol: 'TLRY.TO',
  },
  {
    value: 'TLKM.JK',
    label:
      'TLKM.JK,  Perusahaan Perseroan (Persero) PT Telekomunikasi Indonesia Tbk',
    name: 'Perusahaan Perseroan (Persero) PT Telekomunikasi Indonesia Tbk',
    symbol: 'TLKM.JK',
  },
  {
    value: 'TLGO.MC',
    label: 'TLGO.MC,  Talgo, S.A.',
    name: 'Talgo, S.A.',
    symbol: 'TLGO.MC',
  },
  {
    value: 'TLEVISACPO.MX',
    label: 'TLEVISACPO.MX,  Grupo Televisa, S.A.B.',
    name: 'Grupo Televisa, S.A.B.',
    symbol: 'TLEVISACPO.MX',
  },
  {
    value: 'TL5.MC',
    label: 'TL5.MC,  Mediaset España Comunicación, S.A.',
    name: 'Mediaset España Comunicación, S.A.',
    symbol: 'TL5.MC',
  },
  {
    value: 'TKN-R.BK',
    label: 'TKN-R.BK,  Taokaenoi Food & Marketing Public Company Limited',
    name: 'Taokaenoi Food & Marketing Public Company Limited',
    symbol: 'TKN-R.BK',
  },
  {
    value: 'TKIM.JK',
    label: 'TKIM.JK,  PT Pabrik Kertas Tjiwi Kimia Tbk',
    name: 'PT Pabrik Kertas Tjiwi Kimia Tbk',
    symbol: 'TKIM.JK',
  },
  {
    value: 'TKFEN.IS',
    label: 'TKFEN.IS,  Tekfen Holding Anonim Sirketi',
    name: 'Tekfen Holding Anonim Sirketi',
    symbol: 'TKFEN.IS',
  },
  {
    value: 'TISCO-R.BK',
    label: 'TISCO-R.BK,  TISCO Financial Group Public Company Limited',
    name: 'TISCO Financial Group Public Company Limited',
    symbol: 'TISCO-R.BK',
  },
  {
    value: 'TIP.MI',
    label: 'TIP.MI,  Tamburi Investment Partners S.p.A.',
    name: 'Tamburi Investment Partners S.p.A.',
    symbol: 'TIP.MI',
  },
  {
    value: 'TINS.JK',
    label: 'TINS.JK,  PT TIMAH Tbk',
    name: 'PT TIMAH Tbk',
    symbol: 'TINS.JK',
  },
  {
    value: 'TIMS3.SA',
    label: 'TIMS3.SA,  TIM S.A.',
    name: 'TIM S.A.',
    symbol: 'TIMS3.SA',
  },
  {
    value: 'TIETO.HE',
    label: 'TIETO.HE,  TietoEVRY Oyj',
    name: 'TietoEVRY Oyj',
    symbol: 'TIETO.HE',
  },
  {
    value: 'THYAO.IS',
    label: 'THYAO.IS,  Türk Hava Yollari Anonim Ortakligi',
    name: 'Türk Hava Yollari Anonim Ortakligi',
    symbol: 'THYAO.IS',
  },
  {
    value: 'THULE.ST',
    label: 'THULE.ST,  Thule Group AB (publ)',
    name: 'Thule Group AB (publ)',
    symbol: 'THULE.ST',
  },
  {
    value: 'THL.NZ',
    label: 'THL.NZ,  Tourism Holdings Limited',
    name: 'Tourism Holdings Limited',
    symbol: 'THL.NZ',
  },
  {
    value: 'TGYM.MI',
    label: 'TGYM.MI,  Technogym S.p.A.',
    name: 'Technogym S.p.A.',
    symbol: 'TGYM.MI',
  },
  {
    value: 'TGMA3.SA',
    label: 'TGMA3.SA,  Tegma Gestão Logística S.A.',
    name: 'Tegma Gestão Logística S.A.',
    symbol: 'TGMA3.SA',
  },
  {
    value: 'TGA.L',
    label: 'TGA.L,  Thungela Resources Limited',
    name: 'Thungela Resources Limited',
    symbol: 'TGA.L',
  },
  {
    value: 'TFG-R.BK',
    label: 'TFG-R.BK,  Thaifoods Group Public Company Limited',
    name: 'Thaifoods Group Public Company Limited',
    symbol: 'TFG-R.BK',
  },
  {
    value: 'TEVA.TA',
    label: 'TEVA.TA,  Teva Pharmaceutical Industries Limited',
    name: 'Teva Pharmaceutical Industries Limited',
    symbol: 'TEVA.TA',
  },
  {
    value: 'TETY.ST',
    label: 'TETY.ST,  Tethys Oil AB (publ)',
    name: 'Tethys Oil AB (publ)',
    symbol: 'TETY.ST',
  },
  {
    value: 'TERRA13.MX',
    label: 'TERRA13.MX,  Fibra Terrafina',
    name: 'Fibra Terrafina',
    symbol: 'TERRA13.MX',
  },
  {
    value: 'TEN.WA',
    label: 'TEN.WA,  Ten Square Games S.A.',
    name: 'Ten Square Games S.A.',
    symbol: 'TEN.WA',
  },
  {
    value: 'TEND3.SA',
    label: 'TEND3.SA,  Construtora Tenda S.A.',
    name: 'Construtora Tenda S.A.',
    symbol: 'TEND3.SA',
  },
  {
    value: 'TELIA.ST',
    label: 'TELIA.ST,  Telia Company AB (publ)',
    name: 'Telia Company AB (publ)',
    symbol: 'TELIA.ST',
  },
  {
    value: 'TEKK',
    label: 'TEKK,  Tekkorp Digital Acquisition Corp.',
    name: 'Tekkorp Digital Acquisition Corp.',
    symbol: 'TEKK',
  },
  {
    value: 'TEF.MC',
    label: 'TEF.MC,  Telefónica, S.A.',
    name: 'Telefónica, S.A.',
    symbol: 'TEF.MC',
  },
  {
    value: 'TDRN.TA',
    label: 'TDRN.TA,  Tadiran Group Ltd',
    name: 'Tadiran Group Ltd',
    symbol: 'TDRN.TA',
  },
  {
    value: 'TCSA3.SA',
    label: 'TCSA3.SA,  Tecnisa S.A.',
    name: 'Tecnisa S.A.',
    symbol: 'TCSA3.SA',
  },
  {
    value: 'TCPI.JK',
    label: 'TCPI.JK,  PT Transcoal Pacific Tbk',
    name: 'PT Transcoal Pacific Tbk',
    symbol: 'TCPI.JK',
  },
  {
    value: 'TCBIO',
    label: 'TCBIO,  Texas Capital Bancshares, Inc.',
    name: 'Texas Capital Bancshares, Inc.',
    symbol: 'TCBIO',
  },
  {
    value: 'TCAP-R.BK',
    label: 'TCAP-R.BK,  Thanachart Capital Public Company Limited',
    name: 'Thanachart Capital Public Company Limited',
    symbol: 'TCAP-R.BK',
  },
  {
    value: 'TBLD',
    label: 'TBLD,  Thornburg Income Builder Opportunities Trust',
    name: 'Thornburg Income Builder Opportunities Trust',
    symbol: 'TBLD',
  },
  {
    value: 'TBLA.JK',
    label: 'TBLA.JK,  PT Tunas Baru Lampung Tbk',
    name: 'PT Tunas Baru Lampung Tbk',
    symbol: 'TBLA.JK',
  },
  {
    value: 'TBIG.JK',
    label: 'TBIG.JK,  PT Tower Bersama Infrastructure Tbk',
    name: 'PT Tower Bersama Infrastructure Tbk',
    symbol: 'TBIG.JK',
  },
  {
    value: 'TAVHL.IS',
    label: 'TAVHL.IS,  TAV Havalimanlari Holding A.S.',
    name: 'TAV Havalimanlari Holding A.S.',
    symbol: 'TAVHL.IS',
  },
  {
    value: 'TASE.TA',
    label: 'TASE.TA,  The Tel-Aviv Stock Exchange Ltd.',
    name: 'The Tel-Aviv Stock Exchange Ltd.',
    symbol: 'TASE.TA',
  },
  {
    value: 'TASCO-R.BK',
    label: 'TASCO-R.BK,  Tipco Asphalt Public Company Limited',
    name: 'Tipco Asphalt Public Company Limited',
    symbol: 'TASCO-R.BK',
  },
  {
    value: 'TALK',
    label: 'TALK,  Talkspace, Inc.',
    name: 'Talkspace, Inc.',
    symbol: 'TALK',
  },
  {
    value: 'TAEE11.SA',
    label: 'TAEE11.SA,  Transmissora Aliança de Energia Elétrica S.A.',
    name: 'Transmissora Aliança de Energia Elétrica S.A.',
    symbol: 'TAEE11.SA',
  },
  {
    value: 'TAALA.HE',
    label: 'TAALA.HE,  Taaleri Oyj',
    name: 'Taaleri Oyj',
    symbol: 'TAALA.HE',
  },
  {
    value: 'SYSR.ST',
    label: 'SYSR.ST,  Systemair AB (publ)',
    name: 'Systemair AB (publ)',
    symbol: 'SYSR.ST',
  },
  {
    value: 'SYNEX-R.BK',
    label: 'SYNEX-R.BK,  Synnex (Thailand) Public Company Limited',
    name: 'Synnex (Thailand) Public Company Limited',
    symbol: 'SYNEX-R.BK',
  },
  {
    value: 'SYDB.CO',
    label: 'SYDB.CO,  Sydbank A/S',
    name: 'Sydbank A/S',
    symbol: 'SYDB.CO',
  },
  {
    value: 'SYAB.DE',
    label: 'SYAB.DE,  SYNLAB AG',
    name: 'SYNLAB AG',
    symbol: 'SYAB.DE',
  },
  {
    value: 'SWMA.ST',
    label: 'SWMA.ST,  Swedish Match AB (publ)',
    name: 'Swedish Match AB (publ)',
    symbol: 'SWMA.ST',
  },
  {
    value: 'SVFA',
    label: 'SVFA,  SVF Investment Corp.',
    name: 'SVF Investment Corp.',
    symbol: 'SVFA',
  },
  {
    value: 'SUZB3.SA',
    label: 'SUZB3.SA,  Suzano S.A.',
    name: 'Suzano S.A.',
    symbol: 'SUZB3.SA',
  },
  {
    value: 'SUY1V.HE',
    label: 'SUY1V.HE,  Suominen Oyj',
    name: 'Suominen Oyj',
    symbol: 'SUY1V.HE',
  },
  {
    value: 'SUPER-R.BK',
    label: 'SUPER-R.BK,  Super Energy Corporation Public Company Limited',
    name: 'Super Energy Corporation Public Company Limited',
    symbol: 'SUPER-R.BK',
  },
  {
    value: 'SUNL',
    label: 'SUNL,  Sunlight Financial Holdings Inc.',
    name: 'Sunlight Financial Holdings Inc.',
    symbol: 'SUNL',
  },
  {
    value: 'SUM.NZ',
    label: 'SUM.NZ,  Summerset Group Holdings Limited',
    name: 'Summerset Group Holdings Limited',
    symbol: 'SUM.NZ',
  },
  {
    value: 'SULA11.SA',
    label: 'SULA11.SA,  Sul América S.A.',
    name: 'Sul América S.A.',
    symbol: 'SULA11.SA',
  },
  {
    value: 'STX.WA',
    label: 'STX.WA,  Stalexport Autostrady S.A.',
    name: 'Stalexport Autostrady S.A.',
    symbol: 'STX.WA',
  },
  {
    value: 'STRS.TA',
    label: 'STRS.TA,  Strauss Group Ltd.',
    name: 'Strauss Group Ltd.',
    symbol: 'STRS.TA',
  },
  {
    value: 'STRR',
    label: 'STRR,  Star Equity Holdings, Inc.',
    name: 'Star Equity Holdings, Inc.',
    symbol: 'STRR',
  },
  {
    value: 'STP.WA',
    label: 'STP.WA,  Stalprodukt S.A.',
    name: 'Stalprodukt S.A.',
    symbol: 'STP.WA',
  },
  {
    value: 'STPJF',
    label: 'STPJF,  Southern Pacific Resource Corp.',
    name: 'Southern Pacific Resource Corp.',
    symbol: 'STPJF',
  },
  {
    value: 'STLA.MI',
    label: 'STLA.MI,  Stellantis N.V.',
    name: 'Stellantis N.V.',
    symbol: 'STLA.MI',
  },
  {
    value: 'STGW',
    label: 'STGW,  Stagwell Inc.',
    name: 'Stagwell Inc.',
    symbol: 'STGW',
  },
  {
    value: 'STGT.BK',
    label: 'STGT.BK,  Sri Trang Gloves (Thailand) Public Company Limited',
    name: 'Sri Trang Gloves (Thailand) Public Company Limited',
    symbol: 'STGT.BK',
  },
  {
    value: 'STERV.HE',
    label: 'STERV.HE,  Stora Enso Oyj',
    name: 'Stora Enso Oyj',
    symbol: 'STERV.HE',
  },
  {
    value: 'STEC-R.BK',
    label:
      'STEC-R.BK,  Sino-Thai Engineering and Construction Public Company Limited',
    name: 'Sino-Thai Engineering and Construction Public Company Limited',
    symbol: 'STEC-R.BK',
  },
  {
    value: 'STEAV.HE',
    label: 'STEAV.HE,  Stora Enso Oyj',
    name: 'Stora Enso Oyj',
    symbol: 'STEAV.HE',
  },
  {
    value: 'STBP3.SA',
    label: 'STBP3.SA,  Santos Brasil Participações S.A.',
    name: 'Santos Brasil Participações S.A.',
    symbol: 'STBP3.SA',
  },
  {
    value: 'STA-R.BK',
    label: 'STA-R.BK,  Sri Trang Agro-Industry Public Company Limited',
    name: 'Sri Trang Agro-Industry Public Company Limited',
    symbol: 'STA-R.BK',
  },
  {
    value: 'STAB',
    label: 'STAB,  Statera Biopharma, Inc.',
    name: 'Statera Biopharma, Inc.',
    symbol: 'STAB',
  },
  {
    value: 'SSMS.JK',
    label: 'SSMS.JK,  PT Sawit Sumbermas Sarana Tbk.',
    name: 'PT Sawit Sumbermas Sarana Tbk.',
    symbol: 'SSMS.JK',
  },
  {
    value: 'SSIA.JK',
    label: 'SSIA.JK,  PT Surya Semesta Internusa Tbk',
    name: 'PT Surya Semesta Internusa Tbk',
    symbol: 'SSIA.JK',
  },
  {
    value: 'SRTG.JK',
    label: 'SRTG.JK,  PT Saratoga Investama Sedaya Tbk',
    name: 'PT Saratoga Investama Sedaya Tbk',
    symbol: 'SRTG.JK',
  },
  {
    value: 'SRS.MI',
    label: 'SRS.MI,  Saras S.p.A.',
    name: 'Saras S.p.A.',
    symbol: 'SRS.MI',
  },
  {
    value: 'SRG.MI',
    label: 'SRG.MI,  Snam S.p.A.',
    name: 'Snam S.p.A.',
    symbol: 'SRG.MI',
  },
  {
    value: 'SRBNK.OL',
    label: 'SRBNK.OL,  SpareBank 1 SR-Bank ASA',
    name: 'SpareBank 1 SR-Bank ASA',
    symbol: 'SRBNK.OL',
  },
  {
    value: 'SPNS.TA',
    label: 'SPNS.TA,  Sapiens International Corporation N.V.',
    name: 'Sapiens International Corporation N.V.',
    symbol: 'SPNS.TA',
  },
  {
    value: 'SPNO.CO',
    label: 'SPNO.CO,  Spar Nord Bank A/S',
    name: 'Spar Nord Bank A/S',
    symbol: 'SPNO.CO',
  },
  {
    value: 'SPL.WA',
    label: 'SPL.WA,  Santander Bank Polska S.A.',
    name: 'Santander Bank Polska S.A.',
    symbol: 'SPL.WA',
  },
  {
    value: 'SPK.NZ',
    label: 'SPK.NZ,  Spark New Zealand Limited',
    name: 'Spark New Zealand Limited',
    symbol: 'SPK.NZ',
  },
  {
    value: 'SPK',
    label: 'SPK,  SPK Acquisition Corp.',
    name: 'SPK Acquisition Corp.',
    symbol: 'SPK',
  },
  {
    value: 'SPIR',
    label: 'SPIR,  Spire Global, Inc.',
    name: 'Spire Global, Inc.',
    symbol: 'SPIR',
  },
  {
    value: 'SPEN.TA',
    label: 'SPEN.TA,  Shapir Engineering and Industry Ltd',
    name: 'Shapir Engineering and Industry Ltd',
    symbol: 'SPEN.TA',
  },
  {
    value: 'SPEC.ST',
    label: 'SPEC.ST,  SpectraCure AB (publ)',
    name: 'SpectraCure AB (publ)',
    symbol: 'SPEC.ST',
  },
  {
    value: 'SPCG-R.BK',
    label: 'SPCG-R.BK,  SPCG Public Company Limited',
    name: 'SPCG Public Company Limited',
    symbol: 'SPCG-R.BK',
  },
  {
    value: 'SPALI-R.BK',
    label: 'SPALI-R.BK,  Supalai Public Company Limited',
    name: 'Supalai Public Company Limited',
    symbol: 'SPALI-R.BK',
  },
  {
    value: 'SOON.OL',
    label: 'SOON.OL,  SpareBank 1 Sørøst-Norge',
    name: 'SpareBank 1 Sørøst-Norge',
    symbol: 'SOON.OL',
  },
  {
    value: 'SOMA3.SA',
    label: 'SOMA3.SA,  Grupo de Moda SOMA S.A.',
    name: 'Grupo de Moda SOMA S.A.',
    symbol: 'SOMA3.SA',
  },
  {
    value: 'SOLT.ST',
    label: 'SOLT.ST,  SolTech Energy Sweden AB (publ)',
    name: 'SolTech Energy Sweden AB (publ)',
    symbol: 'SOLT.ST',
  },
  {
    value: 'SOLCF',
    label: 'SOLCF,  SOL Global Investments Corp.',
    name: 'SOL Global Investments Corp.',
    symbol: 'SOLCF',
  },
  {
    value: 'SOKM.IS',
    label: 'SOKM.IS,  Sok Marketler Ticaret A.S.',
    name: 'Sok Marketler Ticaret A.S.',
    symbol: 'SOKM.IS',
  },
  {
    value: 'SOBI.ST',
    label: 'SOBI.ST,  Swedish Orphan Biovitrum AB (publ)',
    name: 'Swedish Orphan Biovitrum AB (publ)',
    symbol: 'SOBI.ST',
  },
  {
    value: 'SNK.WA',
    label: 'SNK.WA,  Sanok Rubber Company Spólka Akcyjna',
    name: 'Sanok Rubber Company Spólka Akcyjna',
    symbol: 'SNK.WA',
  },
  {
    value: 'SMU.SN',
    label: 'SMU.SN,  SMU S.A.',
    name: 'SMU S.A.',
    symbol: 'SMU.SN',
  },
  {
    value: 'SMT.TA',
    label: 'SMT.TA,  Summit Real Estate Holdings Ltd',
    name: 'Summit Real Estate Holdings Ltd',
    symbol: 'SMT.TA',
  },
  {
    value: 'SMTO3.SA',
    label: 'SMTO3.SA,  São Martinho S.A.',
    name: 'São Martinho S.A.',
    symbol: 'SMTO3.SA',
  },
  {
    value: 'SMSM.JK',
    label: 'SMSM.JK,  PT Selamat Sempurna Tbk',
    name: 'PT Selamat Sempurna Tbk',
    symbol: 'SMSM.JK',
  },
  {
    value: 'SMSAAM.SN',
    label: 'SMSAAM.SN,  Sociedad Matriz SAAM S.A.',
    name: 'Sociedad Matriz SAAM S.A.',
    symbol: 'SMSAAM.SN',
  },
  {
    value: 'SMRA.JK',
    label: 'SMRA.JK,  PT Summarecon Agung Tbk',
    name: 'PT Summarecon Agung Tbk',
    symbol: 'SMRA.JK',
  },
  {
    value: 'SML.NZ',
    label: 'SML.NZ,  Synlait Milk Limited',
    name: 'Synlait Milk Limited',
    symbol: 'SML.NZ',
  },
  {
    value: 'SMGR.JK',
    label: 'SMGR.JK,  PT Semen Indonesia (Persero) Tbk',
    name: 'PT Semen Indonesia (Persero) Tbk',
    symbol: 'SMGR.JK',
  },
  {
    value: 'SMFR',
    label: 'SMFR,  Sema4 Holdings Corp.',
    name: 'Sema4 Holdings Corp.',
    symbol: 'SMFR',
  },
  {
    value: 'SLV.WA',
    label: 'SLV.WA,  Selvita S.A.',
    name: 'Selvita S.A.',
    symbol: 'SLV.WA',
  },
  {
    value: 'SLI',
    label: 'SLI,  Standard Lithium Ltd.',
    name: 'Standard Lithium Ltd.',
    symbol: 'SLI',
  },
  {
    value: 'SLGC',
    label: 'SLGC,  SomaLogic, Inc.',
    name: 'SomaLogic, Inc.',
    symbol: 'SLGC',
  },
  {
    value: 'SLCE3.SA',
    label: 'SLCE3.SA,  SLC Agrícola S.A.',
    name: 'SLC Agrícola S.A.',
    symbol: 'SLCE3.SA',
  },
  {
    value: 'SLARL.TA',
    label: 'SLARL.TA,  Sella Capital Real Estate Ltd.',
    name: 'Sella Capital Real Estate Ltd.',
    symbol: 'SLARL.TA',
  },
  {
    value: 'SKL.NZ',
    label: 'SKL.NZ,  Skellerup Holdings Limited',
    name: 'Skellerup Holdings Limited',
    symbol: 'SKL.NZ',
  },
  {
    value: 'SKIL',
    label: 'SKIL,  Skillsoft Corp.',
    name: 'Skillsoft Corp.',
    symbol: 'SKIL',
  },
  {
    value: 'SKC.NZ',
    label: 'SKC.NZ,  SkyCity Entertainment Group Limited',
    name: 'SkyCity Entertainment Group Limited',
    symbol: 'SKC.NZ',
  },
  {
    value: 'SKBN.TA',
    label: 'SKBN.TA,  Shikun & Binui Ltd.',
    name: 'Shikun & Binui Ltd.',
    symbol: 'SKBN.TA',
  },
  {
    value: 'SKBNK.IS',
    label: 'SKBNK.IS,  Sekerbank T.A.S.',
    name: 'Sekerbank T.A.S.',
    symbol: 'SKBNK.IS',
  },
  {
    value: 'SKA.WA',
    label: 'SKA.WA,  Fabryka Farb i Lakierów Sniezka SA',
    name: 'Fabryka Farb i Lakierów Sniezka SA',
    symbol: 'SKA.WA',
  },
  {
    value: 'SITOWS.HE',
    label: 'SITOWS.HE,  Sitowise Group Oyj',
    name: 'Sitowise Group Oyj',
    symbol: 'SITOWS.HE',
  },
  {
    value: 'SISE.IS',
    label: 'SISE.IS,  Türkiye Sise Ve Cam Fabrikalari A.S.',
    name: 'Türkiye Sise Ve Cam Fabrikalari A.S.',
    symbol: 'SISE.IS',
  },
  {
    value: 'SIOX',
    label: 'SIOX,  Sio Gene Therapies Inc.',
    name: 'Sio Gene Therapies Inc.',
    symbol: 'SIOX',
  },
  {
    value: 'SINCH.ST',
    label: 'SINCH.ST,  Sinch AB (publ)',
    name: 'Sinch AB (publ)',
    symbol: 'SINCH.ST',
  },
  {
    value: 'SIMP.JK',
    label: 'SIMP.JK,  PT Salim Ivomas Pratama Tbk',
    name: 'PT Salim Ivomas Pratama Tbk',
    symbol: 'SIMP.JK',
  },
  {
    value: 'SIMH3.SA',
    label: 'SIMH3.SA,  SIMPAR S.A.',
    name: 'SIMPAR S.A.',
    symbol: 'SIMH3.SA',
  },
  {
    value: 'SIM.CO',
    label: 'SIM.CO,  SimCorp A/S',
    name: 'SimCorp A/S',
    symbol: 'SIM.CO',
  },
  {
    value: 'SILO.JK',
    label: 'SILO.JK,  PT Siloam International Hospitals Tbk',
    name: 'PT Siloam International Hospitals Tbk',
    symbol: 'SILO.JK',
  },
  {
    value: 'SIGIP',
    label: 'SIGIP,  Selective Insurance Group, Inc.',
    name: 'Selective Insurance Group, Inc.',
    symbol: 'SIGIP',
  },
  {
    value: 'SIEGY',
    label: 'SIEGY,  Siemens Aktiengesellschaft',
    name: 'Siemens Aktiengesellschaft',
    symbol: 'SIEGY',
  },
  {
    value: 'SIDO.JK',
    label: 'SIDO.JK,  PT Industri Jamu dan Farmasi Sido Muncul Tbk',
    name: 'PT Industri Jamu dan Farmasi Sido Muncul Tbk',
    symbol: 'SIDO.JK',
  },
  {
    value: 'SHOW3.SA',
    label: 'SHOW3.SA,  T4F Entretenimento S.A.',
    name: 'T4F Entretenimento S.A.',
    symbol: 'SHOW3.SA',
  },
  {
    value: 'SHOT.ST',
    label: 'SHOT.ST,  Scandic Hotels Group AB (publ)',
    name: 'Scandic Hotels Group AB (publ)',
    symbol: 'SHOT.ST',
  },
  {
    value: 'SHCR',
    label: 'SHCR,  Sharecare, Inc.',
    name: 'Sharecare, Inc.',
    symbol: 'SHCR',
  },
  {
    value: 'SGP-R.BK',
    label: 'SGP-R.BK,  Siamgas and Petrochemicals Public Company Limited',
    name: 'Siamgas and Petrochemicals Public Company Limited',
    symbol: 'SGP-R.BK',
  },
  {
    value: 'SF.ST',
    label: 'SF.ST,  Stillfront Group AB (publ)',
    name: 'Stillfront Group AB (publ)',
    symbol: 'SF.ST',
  },
  {
    value: 'SFIN',
    label: 'SFIN,  Safetek International, Inc.',
    name: 'Safetek International, Inc.',
    symbol: 'SFIN',
  },
  {
    value: 'SFER.MI',
    label: 'SFER.MI,  Salvatore Ferragamo S.p.A.',
    name: 'Salvatore Ferragamo S.p.A.',
    symbol: 'SFER.MI',
  },
  {
    value: 'SEYE.ST',
    label: 'SEYE.ST,  Smart Eye AB (publ)',
    name: 'Smart Eye AB (publ)',
    symbol: 'SEYE.ST',
  },
  {
    value: 'SES.MI',
    label: 'SES.MI,  SeSa S.p.A.',
    name: 'SeSa S.p.A.',
    symbol: 'SES.MI',
  },
  {
    value: 'SEQL3.SA',
    label: 'SEQL3.SA,  Sequoia Logística e Transportes S.A.',
    name: 'Sequoia Logística e Transportes S.A.',
    symbol: 'SEQL3.SA',
  },
  {
    value: 'SENS.ST',
    label: 'SENS.ST,  Sensys Gatso Group AB (publ)',
    name: 'Sensys Gatso Group AB (publ)',
    symbol: 'SENS.ST',
  },
  {
    value: 'SELEC.IS',
    label: 'SELEC.IS,  Selçuk Ecza Deposu Ticaret ve Sanayi A.S.',
    name: 'Selçuk Ecza Deposu Ticaret ve Sanayi A.S.',
    symbol: 'SELEC.IS',
  },
  {
    value: 'SEER3.SA',
    label: 'SEER3.SA,  Ser Educacional S.A.',
    name: 'Ser Educacional S.A.',
    symbol: 'SEER3.SA',
  },
  {
    value: 'SEDANA.ST',
    label: 'SEDANA.ST,  Sedana Medical AB (publ)',
    name: 'Sedana Medical AB (publ)',
    symbol: 'SEDANA.ST',
  },
  {
    value: 'SEBC',
    label: 'SEBC,  Southeastern Banking Corporation',
    name: 'Southeastern Banking Corporation',
    symbol: 'SEBC',
  },
  {
    value: 'SEA',
    label: 'SEA,  U.S. Global Sea to Sky Cargo ETF',
    name: 'U.S. Global Sea to Sky Cargo ETF',
    symbol: 'SEA',
  },
  {
    value: 'SDRC',
    label: 'SDRC,  Sidney Resources Corp.',
    name: 'Sidney Resources Corp.',
    symbol: 'SDRC',
  },
  {
    value: 'SCYR.MC',
    label: 'SCYR.MC,  Sacyr, S.A.',
    name: 'Sacyr, S.A.',
    symbol: 'SCYR.MC',
  },
  {
    value: 'SCST.ST',
    label: 'SCST.ST,  Scandi Standard AB (publ)',
    name: 'Scandi Standard AB (publ)',
    symbol: 'SCST.ST',
  },
  {
    value: 'SC-R.BK',
    label: 'SC-R.BK,  SC Asset Corporation Public Company Limited',
    name: 'SC Asset Corporation Public Company Limited',
    symbol: 'SC-R.BK',
  },
  {
    value: 'SCOA',
    label: 'SCOA,  ScION Tech Growth I',
    name: 'ScION Tech Growth I',
    symbol: 'SCOA',
  },
  {
    value: 'SCMA.JK',
    label: 'SCMA.JK,  PT Surya Citra Media Tbk',
    name: 'PT Surya Citra Media Tbk',
    symbol: 'SCMA.JK',
  },
  {
    value: 'SCHO.CO',
    label: 'SCHO.CO,  Aktieselskabet Schouw & Co.',
    name: 'Aktieselskabet Schouw & Co.',
    symbol: 'SCHO.CO',
  },
  {
    value: 'SCHN.SW',
    label: 'SCHN.SW,  Schindler Holding AG',
    name: 'Schindler Holding AG',
    symbol: 'SCHN.SW',
  },
  {
    value: 'SCHB.OL',
    label: 'SCHB.OL,  Schibsted ASA',
    name: 'Schibsted ASA',
    symbol: 'SCHB.OL',
  },
  {
    value: 'SCC-R.BK',
    label: 'SCC-R.BK,  The Siam Cement Public Company Limited',
    name: 'The Siam Cement Public Company Limited',
    symbol: 'SCC-R.BK',
  },
  {
    value: 'SCCC-R.BK',
    label: 'SCCC-R.BK,  Siam City Cement Public Company Limited',
    name: 'Siam City Cement Public Company Limited',
    symbol: 'SCCC-R.BK',
  },
  {
    value: 'SCB-R.BK',
    label: 'SCB-R.BK,  SCB X Public Company Limited',
    name: 'SCB X Public Company Limited',
    symbol: 'SCB-R.BK',
  },
  {
    value: 'SCANFL.HE',
    label: 'SCANFL.HE,  Scanfil Oyj',
    name: 'Scanfil Oyj',
    symbol: 'SCANFL.HE',
  },
  {
    value: 'SBSP3.SA',
    label:
      'SBSP3.SA,  Companhia de Saneamento Básico do Estado de São Paulo - SABESP',
    name: 'Companhia de Saneamento Básico do Estado de São Paulo - SABESP',
    symbol: 'SBSP3.SA',
  },
  {
    value: 'SBNYP',
    label: 'SBNYP,  Signature Bank',
    name: 'Signature Bank',
    symbol: 'SBNYP',
  },
  {
    value: 'SBFG3.SA',
    label: 'SBFG3.SA,  Grupo SBF S.A.',
    name: 'Grupo SBF S.A.',
    symbol: 'SBFG3.SA',
  },
  {
    value: 'SAWAD-R.BK',
    label: 'SAWAD-R.BK,  Srisawad Corporation Public Company Limited',
    name: 'Srisawad Corporation Public Company Limited',
    symbol: 'SAWAD-R.BK',
  },
  {
    value: 'SAVE.ST',
    label: 'SAVE.ST,  Nordnet AB (publ)',
    name: 'Nordnet AB (publ)',
    symbol: 'SAVE.ST',
  },
  {
    value: 'SAS.ST',
    label: 'SAS.ST,  SAS AB (publ)',
    name: 'SAS AB (publ)',
    symbol: 'SAS.ST',
  },
  {
    value: 'SASA.IS',
    label: 'SASA.IS,  Sasa Polyester Sanayi A.S.',
    name: 'Sasa Polyester Sanayi A.S.',
    symbol: 'SASA.IS',
  },
  {
    value: 'SARKY.IS',
    label: 'SARKY.IS,  Sarkuysan Elektrolitik Bakir Sanayi ve Ticaret A.S.',
    name: 'Sarkuysan Elektrolitik Bakir Sanayi ve Ticaret A.S.',
    symbol: 'SARKY.IS',
  },
  {
    value: 'SAPR4.SA',
    label: 'SAPR4.SA,  Companhia de Saneamento do Paraná - SANEPAR',
    name: 'Companhia de Saneamento do Paraná - SANEPAR',
    symbol: 'SAPR4.SA',
  },
  {
    value: 'SAPR11.SA',
    label: 'SAPR11.SA,  Companhia de Saneamento do Paraná - SANEPAR',
    name: 'Companhia de Saneamento do Paraná - SANEPAR',
    symbol: 'SAPR11.SA',
  },
  {
    value: 'SANT',
    label: 'SANT,  Santeon Group, Inc.',
    name: 'Santeon Group, Inc.',
    symbol: 'SANT',
  },
  {
    value: 'SANO1.TA',
    label: "SANO1.TA,  Sano Bruno's Enterprises Ltd",
    name: "Sano Bruno's Enterprises Ltd",
    symbol: 'SANO1.TA',
  },
  {
    value: 'SAN.NZ',
    label: 'SAN.NZ,  Sanford Limited',
    name: 'Sanford Limited',
    symbol: 'SAN.NZ',
  },
  {
    value: 'SAN.MX',
    label: 'SAN.MX,  Banco Santander, S.A.',
    name: 'Banco Santander, S.A.',
    symbol: 'SAN.MX',
  },
  {
    value: 'SAN.MC',
    label: 'SAN.MC,  Banco Santander, S.A.',
    name: 'Banco Santander, S.A.',
    symbol: 'SAN.MC',
  },
  {
    value: 'SAND.ST',
    label: 'SAND.ST,  Sandvik AB (publ)',
    name: 'Sandvik AB (publ)',
    symbol: 'SAND.ST',
  },
  {
    value: 'SANB11.SA',
    label: 'SANB11.SA,  Banco Santander (Brasil) S.A.',
    name: 'Banco Santander (Brasil) S.A.',
    symbol: 'SANB11.SA',
  },
  {
    value: 'SAMPO.HE',
    label: 'SAMPO.HE,  Sampo Oyj',
    name: 'Sampo Oyj',
    symbol: 'SAMPO.HE',
  },
  {
    value: 'SAHOL.IS',
    label: 'SAHOL.IS,  Haci Ömer Sabanci Holding A.S.',
    name: 'Haci Ömer Sabanci Holding A.S.',
    symbol: 'SAHOL.IS',
  },
  {
    value: 'SAE.TA',
    label: 'SAE.TA,  Shufersal Ltd',
    name: 'Shufersal Ltd',
    symbol: 'SAE.TA',
  },
  {
    value: 'SABINA-R.BK',
    label: 'SABINA-R.BK,  Sabina Public Company Limited',
    name: 'Sabina Public Company Limited',
    symbol: 'SABINA-R.BK',
  },
  {
    value: 'RYM.NZ',
    label: 'RYM.NZ,  Ryman Healthcare Limited',
    name: 'Ryman Healthcare Limited',
    symbol: 'RYM.NZ',
  },
  {
    value: 'RWAY.MI',
    label: 'RWAY.MI,  Rai Way S.p.A.',
    name: 'Rai Way S.p.A.',
    symbol: 'RWAY.MI',
  },
  {
    value: 'RVU.WA',
    label: 'RVU.WA,  Ryvu Therapeutics S.A.',
    name: 'Ryvu Therapeutics S.A.',
    symbol: 'RVU.WA',
  },
  {
    value: 'RVPH',
    label: 'RVPH,  Reviva Pharmaceuticals Holdings, Inc.',
    name: 'Reviva Pharmaceuticals Holdings, Inc.',
    symbol: 'RVPH',
  },
  {
    value: 'RVL.TA',
    label: 'RVL.TA,  Raval ACS Ltd.',
    name: 'Raval ACS Ltd.',
    symbol: 'RVL.TA',
  },
  {
    value: 'RSID3.SA',
    label: 'RSID3.SA,  Rossi Residencial S.A.',
    name: 'Rossi Residencial S.A.',
    symbol: 'RSID3.SA',
  },
  {
    value: 'ROVIO.HE',
    label: 'ROVIO.HE,  Rovio Entertainment Oyj',
    name: 'Rovio Entertainment Oyj',
    symbol: 'ROVIO.HE',
  },
  {
    value: 'ROVI.MC',
    label: 'ROVI.MC,  Laboratorios Farmaceuticos Rovi, S.A.',
    name: 'Laboratorios Farmaceuticos Rovi, S.A.',
    symbol: 'ROVI.MC',
  },
  {
    value: 'ROTH',
    label: 'ROTH,  PharmaRoth Labs, Inc.',
    name: 'PharmaRoth Labs, Inc.',
    symbol: 'ROTH',
  },
  {
    value: 'ROSN',
    label: 'ROSN,  Rosinbomb',
    name: 'Rosinbomb',
    symbol: 'ROSN',
  },
  {
    value: 'ROMI3.SA',
    label: 'ROMI3.SA,  Romi S.A.',
    name: 'Romi S.A.',
    symbol: 'ROMI3.SA',
  },
  {
    value: 'RNW',
    label: 'RNW,  ReNew Energy Global Plc',
    name: 'ReNew Energy Global Plc',
    symbol: 'RNW',
  },
  {
    value: 'RMLI.TA',
    label: 'RMLI.TA,  Rami Levi Chain Stores Hashikma Marketing 2006 Ltd',
    name: 'Rami Levi Chain Stores Hashikma Marketing 2006 Ltd',
    symbol: 'RMLI.TA',
  },
  {
    value: 'RLX',
    label: 'RLX,  RLX Technology Inc.',
    name: 'RLX Technology Inc.',
    symbol: 'RLX',
  },
  {
    value: 'RKT.L',
    label: 'RKT.L,  Reckitt Benckiser Group plc',
    name: 'Reckitt Benckiser Group plc',
    symbol: 'RKT.L',
  },
  {
    value: 'RIT1.TA',
    label: 'RIT1.TA,  Reit 1 Ltd',
    name: 'Reit 1 Ltd',
    symbol: 'RIT1.TA',
  },
  {
    value: 'RIPLEY.SN',
    label: 'RIPLEY.SN,  Ripley Corp S.A.',
    name: 'Ripley Corp S.A.',
    symbol: 'RIPLEY.SN',
  },
  {
    value: 'RILBA.CO',
    label: 'RILBA.CO,  Ringkjøbing Landbobank A/S',
    name: 'Ringkjøbing Landbobank A/S',
    symbol: 'RILBA.CO',
  },
  {
    value: 'RIGD.IL',
    label: 'RIGD.IL,  Reliance Industries Limited',
    name: 'Reliance Industries Limited',
    symbol: 'RIGD.IL',
  },
  {
    value: 'RHHBY',
    label: 'RHHBY,  Roche Holding AG',
    name: 'Roche Holding AG',
    symbol: 'RHHBY',
  },
  {
    value: 'REY.MI',
    label: 'REY.MI,  Reply S.p.A.',
    name: 'Reply S.p.A.',
    symbol: 'REY.MI',
  },
  {
    value: 'RESURS.ST',
    label: 'RESURS.ST,  Resurs Holding AB (publ)',
    name: 'Resurs Holding AB (publ)',
    symbol: 'RESURS.ST',
  },
  {
    value: 'RERE',
    label: 'RERE,  ATRenew Inc.',
    name: 'ATRenew Inc.',
    symbol: 'RERE',
  },
  {
    value: 'REP.MC',
    label: 'REP.MC,  Repsol, S.A.',
    name: 'Repsol, S.A.',
    symbol: 'REP.MC',
  },
  {
    value: 'RENT3.SA',
    label: 'RENT3.SA,  Localiza Rent a Car S.A.',
    name: 'Localiza Rent a Car S.A.',
    symbol: 'RENT3.SA',
  },
  {
    value: 'REG1V.HE',
    label: 'REG1V.HE,  Revenio Group Oyj',
    name: 'Revenio Group Oyj',
    symbol: 'REG1V.HE',
  },
  {
    value: 'REE',
    label: 'REE,  REE Automotive Ltd.',
    name: 'REE Automotive Ltd.',
    symbol: 'REE',
  },
  {
    value: 'RECSI.OL',
    label: 'RECSI.OL,  REC Silicon ASA',
    name: 'REC Silicon ASA',
    symbol: 'RECSI.OL',
  },
  {
    value: 'READ.ST',
    label: 'READ.ST,  Readly International AB (publ)',
    name: 'Readly International AB (publ)',
    symbol: 'READ.ST',
  },
  {
    value: 'RDW',
    label: 'RDW,  Redwire Corporation',
    name: 'Redwire Corporation',
    symbol: 'RDW',
  },
  {
    value: 'RDOR3.SA',
    label: "RDOR3.SA,  Rede D'Or São Luiz S.A.",
    name: "Rede D'Or São Luiz S.A.",
    symbol: 'RDOR3.SA',
  },
  {
    value: 'RDNI3.SA',
    label: 'RDNI3.SA,  RNI Negócios Imobiliários S.A.',
    name: 'RNI Negócios Imobiliários S.A.',
    symbol: 'RDNI3.SA',
  },
  {
    value: 'RBW.WA',
    label: 'RBW.WA,  Rainbow Tours S.A.',
    name: 'Rainbow Tours S.A.',
    symbol: 'RBW.WA',
  },
  {
    value: 'RBREW.CO',
    label: 'RBREW.CO,  Royal Unibrew A/S',
    name: 'Royal Unibrew A/S',
    symbol: 'RBREW.CO',
  },
  {
    value: 'RBD.NZ',
    label: 'RBD.NZ,  Restaurant Brands New Zealand Limited',
    name: 'Restaurant Brands New Zealand Limited',
    symbol: 'RBD.NZ',
  },
  {
    value: 'RATCH-R.BK',
    label: 'RATCH-R.BK,  Ratch Group Public Company Limited',
    name: 'Ratch Group Public Company Limited',
    symbol: 'RATCH-R.BK',
  },
  {
    value: 'RATCH.BK',
    label: 'RATCH.BK,  Ratch Group Public Company Limited',
    name: 'Ratch Group Public Company Limited',
    symbol: 'RATCH.BK',
  },
  {
    value: 'RAPT4.SA',
    label: 'RAPT4.SA,  Randon S.A. Implementos e Participações',
    name: 'Randon S.A. Implementos e Participações',
    symbol: 'RAPT4.SA',
  },
  {
    value: 'RANI3.SA',
    label: 'RANI3.SA,  Irani Papel e Embalagem S.A.',
    name: 'Irani Papel e Embalagem S.A.',
    symbol: 'RANI3.SA',
  },
  {
    value: 'RA.MX',
    label: 'RA.MX,  Regional S.A.B. de C.V.',
    name: 'Regional S.A.B. de C.V.',
    symbol: 'RA.MX',
  },
  {
    value: 'RALS.JK',
    label: 'RALS.JK,  PT Ramayana Lestari Sentosa Tbk',
    name: 'PT Ramayana Lestari Sentosa Tbk',
    symbol: 'RALS.JK',
  },
  {
    value: 'RAIVV.HE',
    label: 'RAIVV.HE,  Raisio plc',
    name: 'Raisio plc',
    symbol: 'RAIVV.HE',
  },
  {
    value: 'RAIL3.SA',
    label: 'RAIL3.SA,  Rumo S.A.',
    name: 'Rumo S.A.',
    symbol: 'RAIL3.SA',
  },
  {
    value: 'RADL3.SA',
    label: 'RADL3.SA,  Raia Drogasil S.A.',
    name: 'Raia Drogasil S.A.',
    symbol: 'RADL3.SA',
  },
  {
    value: 'RACE.MI',
    label: 'RACE.MI,  Ferrari N.V.',
    name: 'Ferrari N.V.',
    symbol: 'RACE.MI',
  },
  {
    value: 'RAAS',
    label: 'RAAS,  Cloopen Group Holding Limited',
    name: 'Cloopen Group Holding Limited',
    symbol: 'RAAS',
  },
  {
    value: 'QUINENCO.SN',
    label: 'QUINENCO.SN,  Quiñenco S.A.',
    name: 'Quiñenco S.A.',
    symbol: 'QUINENCO.SN',
  },
  {
    value: 'QUAL3.SA',
    label: 'QUAL3.SA,  Qualicorp Consultoria e Corretora de Seguros S.A.',
    name: 'Qualicorp Consultoria e Corretora de Seguros S.A.',
    symbol: 'QUAL3.SA',
  },
  {
    value: 'QTCOM.HE',
    label: 'QTCOM.HE,  Qt Group Oyj',
    name: 'Qt Group Oyj',
    symbol: 'QTCOM.HE',
  },
  {
    value: 'QRTEP',
    label: 'QRTEP,  Qurate Retail, Inc.',
    name: 'Qurate Retail, Inc.',
    symbol: 'QRTEP',
  },
  {
    value: 'Q.MX',
    label: 'Q.MX,  Quálitas Controladora, S.A.B. de C.V.',
    name: 'Quálitas Controladora, S.A.B. de C.V.',
    symbol: 'Q.MX',
  },
  {
    value: 'QH-R.BK',
    label: 'QH-R.BK,  Quality Houses Public Company Limited',
    name: 'Quality Houses Public Company Limited',
    symbol: 'QH-R.BK',
  },
  {
    value: 'QGTS.QA',
    label: 'QGTS.QA,  Qatar Gas Transport Company Limited (Nakilat) (QPSC)',
    name: 'Qatar Gas Transport Company Limited (Nakilat) (QPSC)',
    symbol: 'QGTS.QA',
  },
  {
    value: 'QFLS.QA',
    label: 'QFLS.QA,  Qatar Fuel Company Q.P.S.C.("WOQOD")',
    name: 'Qatar Fuel Company Q.P.S.C.("WOQOD")',
    symbol: 'QFLS.QA',
  },
  {
    value: 'QAMC.QA',
    label: 'QAMC.QA,  Qatar Aluminium Manufacturing Company Q.P.S.C.',
    name: 'Qatar Aluminium Manufacturing Company Q.P.S.C.',
    symbol: 'QAMC.QA',
  },
  {
    value: 'PZU.WA',
    label: 'PZU.WA,  Powszechny Zaklad Ubezpieczen SA',
    name: 'Powszechny Zaklad Ubezpieczen SA',
    symbol: 'PZU.WA',
  },
  {
    value: 'PZOL.TA',
    label: 'PZOL.TA,  Paz Oil Company Ltd.',
    name: 'Paz Oil Company Ltd.',
    symbol: 'PZOL.TA',
  },
  {
    value: 'PXM.WA',
    label: 'PXM.WA,  Polimex-Mostostal S.A.',
    name: 'Polimex-Mostostal S.A.',
    symbol: 'PXM.WA',
  },
  {
    value: 'PWP',
    label: 'PWP,  Perella Weinberg Partners',
    name: 'Perella Weinberg Partners',
    symbol: 'PWP',
  },
  {
    value: 'PWON.JK',
    label: 'PWON.JK,  PT Pakuwon Jati Tbk',
    name: 'PT Pakuwon Jati Tbk',
    symbol: 'PWON.JK',
  },
  {
    value: 'PV',
    label: 'PV,  Primavera Capital Acquisition Corporation',
    name: 'Primavera Capital Acquisition Corporation',
    symbol: 'PV',
  },
  {
    value: 'PTT-R.BK',
    label: 'PTT-R.BK,  PTT Public Company Limited',
    name: 'PTT Public Company Limited',
    symbol: 'PTT-R.BK',
  },
  {
    value: 'PTTEP-R.BK',
    label: 'PTTEP-R.BK,  PTT Exploration and Production Public Company Limited',
    name: 'PTT Exploration and Production Public Company Limited',
    symbol: 'PTTEP-R.BK',
  },
  {
    value: 'PTRS',
    label: 'PTRS,  Partners Bancorp',
    name: 'Partners Bancorp',
    symbol: 'PTRS',
  },
  {
    value: 'PTPP.JK',
    label: 'PTPP.JK,  PT PP (Persero) Tbk',
    name: 'PT PP (Persero) Tbk',
    symbol: 'PTPP.JK',
  },
  {
    value: 'PTPI',
    label: 'PTPI,  Petros Pharmaceuticals, Inc.',
    name: 'Petros Pharmaceuticals, Inc.',
    symbol: 'PTPI',
  },
  {
    value: 'PTNR.TA',
    label: 'PTNR.TA,  Partner Communications Company Ltd.',
    name: 'Partner Communications Company Ltd.',
    symbol: 'PTNR.TA',
  },
  {
    value: 'PTL-R.BK',
    label: 'PTL-R.BK,  Polyplex (Thailand) Public Company Limited',
    name: 'Polyplex (Thailand) Public Company Limited',
    symbol: 'PTL-R.BK',
  },
  {
    value: 'PTG-R.BK',
    label: 'PTG-R.BK,  PTG Energy Public Company Limited',
    name: 'PTG Energy Public Company Limited',
    symbol: 'PTG-R.BK',
  },
  {
    value: 'PTBL3.SA',
    label: 'PTBL3.SA,  PBG S.A.',
    name: 'PBG S.A.',
    symbol: 'PTBL3.SA',
  },
  {
    value: 'PTBA.JK',
    label: 'PTBA.JK,  PT Bukit Asam Tbk',
    name: 'PT Bukit Asam Tbk',
    symbol: 'PTBA.JK',
  },
  {
    value: 'PST.MI',
    label: 'PST.MI,  Poste Italiane S.p.A.',
    name: 'Poste Italiane S.p.A.',
    symbol: 'PST.MI',
  },
  {
    value: 'PSSA3.SA',
    label: 'PSSA3.SA,  Porto Seguro S.A.',
    name: 'Porto Seguro S.A.',
    symbol: 'PSSA3.SA',
  },
  {
    value: 'PSMR',
    label: 'PSMR,  Pacer Swan SOS Moderate (April) ETF',
    name: 'Pacer Swan SOS Moderate (April) ETF',
    symbol: 'PSMR',
  },
  {
    value: 'PSMP.BR',
    label: 'PSMP.BR,  ProSiebenSat.1 Media SE',
    name: 'ProSiebenSat.1 Media SE',
    symbol: 'PSMP.BR',
  },
  {
    value: 'PSMJ',
    label: 'PSMJ,  Pacer Swan SOS Moderate (July) ETF',
    name: 'Pacer Swan SOS Moderate (July) ETF',
    symbol: 'PSMJ',
  },
  {
    value: 'PSH-R.BK',
    label: 'PSH-R.BK,  Pruksa Holding Public Company Limited',
    name: 'Pruksa Holding Public Company Limited',
    symbol: 'PSH-R.BK',
  },
  {
    value: 'PSG.MC',
    label: 'PSG.MC,  Prosegur Compañía de Seguridad, S.A.',
    name: 'Prosegur Compañía de Seguridad, S.A.',
    symbol: 'PSG.MC',
  },
  {
    value: 'PSFM',
    label: 'PSFM,  Pacer Swan SOS Flex (April) ETF',
    name: 'Pacer Swan SOS Flex (April) ETF',
    symbol: 'PSFM',
  },
  {
    value: 'PSFJ',
    label: 'PSFJ,  Pacer Swan SOS Flex (July) ETF',
    name: 'Pacer Swan SOS Flex (July) ETF',
    symbol: 'PSFJ',
  },
  {
    value: 'PSCW',
    label: 'PSCW,  Pacer Swan SOS Conservative (April) ETF',
    name: 'Pacer Swan SOS Conservative (April) ETF',
    symbol: 'PSCW',
  },
  {
    value: 'PSCJ',
    label: 'PSCJ,  Pacer Swan SOS Conservative (July) ETF',
    name: 'Pacer Swan SOS Conservative (July) ETF',
    symbol: 'PSCJ',
  },
  {
    value: 'PRY.MI',
    label: 'PRY.MI,  Prysmian S.p.A.',
    name: 'Prysmian S.p.A.',
    symbol: 'PRY.MI',
  },
  {
    value: 'PRT.MI',
    label: 'PRT.MI,  Esprinet S.p.A.',
    name: 'Esprinet S.p.A.',
    symbol: 'PRT.MI',
  },
  {
    value: 'PRTC',
    label: 'PRTC,  PureTech Health plc',
    name: 'PureTech Health plc',
    symbol: 'PRTC',
  },
  {
    value: 'PRSK.TA',
    label: 'PRSK.TA,  Prashkovsky Investments and Construction Ltd.',
    name: 'Prashkovsky Investments and Construction Ltd.',
    symbol: 'PRSK.TA',
  },
  {
    value: 'PRPM',
    label: 'PRPM,  ProTek Capital, Inc.',
    name: 'ProTek Capital, Inc.',
    symbol: 'PRPM',
  },
  {
    value: 'PROT.OL',
    label: 'PROT.OL,  Protector Forsikring ASA',
    name: 'Protector Forsikring ASA',
    symbol: 'PROT.OL',
  },
  {
    value: 'PRM-R.BK',
    label: 'PRM-R.BK,  Prima Marine Public Company Limited',
    name: 'Prima Marine Public Company Limited',
    symbol: 'PRM-R.BK',
  },
  {
    value: 'PRIO3.SA',
    label: 'PRIO3.SA,  Petro Rio S.A.',
    name: 'Petro Rio S.A.',
    symbol: 'PRIO3.SA',
  },
  {
    value: 'POT.NZ',
    label: 'POT.NZ,  Port of Tauranga Limited',
    name: 'Port of Tauranga Limited',
    symbol: 'POT.NZ',
  },
  {
    value: 'POST.VI',
    label: 'POST.VI,  Österreichische Post AG',
    name: 'Österreichische Post AG',
    symbol: 'POST.VI',
  },
  {
    value: 'PON1V.HE',
    label: 'PON1V.HE,  Ponsse Oyj',
    name: 'Ponsse Oyj',
    symbol: 'PON1V.HE',
  },
  {
    value: 'POMO4.SA',
    label: 'POMO4.SA,  Marcopolo S.A.',
    name: 'Marcopolo S.A.',
    symbol: 'POMO4.SA',
  },
  {
    value: 'POLL.JK',
    label: 'POLL.JK,  PT Pollux Properties Indonesia Tbk',
    name: 'PT Pollux Properties Indonesia Tbk',
    symbol: 'POLL.JK',
  },
  {
    value: 'POLI.TA',
    label: 'POLI.TA,  Bank Hapoalim B.M.',
    name: 'Bank Hapoalim B.M.',
    symbol: 'POLI.TA',
  },
  {
    value: 'PNVL3.SA',
    label: 'PNVL3.SA,  Dimed S.A. Distribuidora de Medicamentos',
    name: 'Dimed S.A. Distribuidora de Medicamentos',
    symbol: 'PNVL3.SA',
  },
  {
    value: 'PNTM',
    label: 'PNTM,  Pontem Corporation',
    name: 'Pontem Corporation',
    symbol: 'PNTM',
  },
  {
    value: 'PNLF.JK',
    label: 'PNLF.JK,  PT Panin Financial Tbk',
    name: 'PT Panin Financial Tbk',
    symbol: 'PNLF.JK',
  },
  {
    value: 'PNDORA.CO',
    label: 'PNDORA.CO,  Pandora A/S',
    name: 'Pandora A/S',
    symbol: 'PNDORA.CO',
  },
  {
    value: 'PNBN.JK',
    label: 'PNBN.JK,  P.T. Bank Pan Indonesia Tbk',
    name: 'P.T. Bank Pan Indonesia Tbk',
    symbol: 'PNBN.JK',
  },
  {
    value: 'PLW.WA',
    label: 'PLW.WA,  PlayWay S.A.',
    name: 'PlayWay S.A.',
    symbol: 'PLW.WA',
  },
  {
    value: 'PLNHF',
    label: 'PLNHF,  Planet 13 Holdings Inc.',
    name: 'Planet 13 Holdings Inc.',
    symbol: 'PLNHF',
  },
  {
    value: 'PKP.WA',
    label: 'PKP.WA,  Pkp Cargo S.A.',
    name: 'Pkp Cargo S.A.',
    symbol: 'PKP.WA',
  },
  {
    value: 'PKO.WA',
    label: 'PKO.WA,  Powszechna Kasa Oszczednosci Bank Polski Spólka Akcyjna',
    name: 'Powszechna Kasa Oszczednosci Bank Polski Spólka Akcyjna',
    symbol: 'PKO.WA',
  },
  {
    value: 'PKN.WA',
    label: 'PKN.WA,  Polski Koncern Naftowy ORLEN Spólka Akcyjna',
    name: 'Polski Koncern Naftowy ORLEN Spólka Akcyjna',
    symbol: 'PKN.WA',
  },
  {
    value: 'PIRC.MI',
    label: 'PIRC.MI,  Pirelli & C. S.p.A.',
    name: 'Pirelli & C. S.p.A.',
    symbol: 'PIRC.MI',
  },
  {
    value: 'PINFRA.MX',
    label:
      'PINFRA.MX,  Promotora y Operadora de Infraestructura, S. A. B. de C. V.',
    name: 'Promotora y Operadora de Infraestructura, S. A. B. de C. V.',
    symbol: 'PINFRA.MX',
  },
  {
    value: 'PIA.MI',
    label: 'PIA.MI,  Piaggio & C. SpA',
    name: 'Piaggio & C. SpA',
    symbol: 'PIA.MI',
  },
  {
    value: 'PHVS',
    label: 'PHVS,  Pharvaris N.V.',
    name: 'Pharvaris N.V.',
    symbol: 'PHVS',
  },
  {
    value: 'PGSUS.IS',
    label: 'PGSUS.IS,  Pegasus Hava Tasimaciligi Anonim Sirketi',
    name: 'Pegasus Hava Tasimaciligi Anonim Sirketi',
    symbol: 'PGSUS.IS',
  },
  {
    value: 'PGN.WA',
    label: 'PGN.WA,  Polskie Górnictwo Naftowe i Gazownictwo S.A.',
    name: 'Polskie Górnictwo Naftowe i Gazownictwo S.A.',
    symbol: 'PGN.WA',
  },
  {
    value: 'PGMN3.SA',
    label: 'PGMN3.SA,  Empreendimentos Pague Menos S.A.',
    name: 'Empreendimentos Pague Menos S.A.',
    symbol: 'PGMN3.SA',
  },
  {
    value: 'PGE.WA',
    label: 'PGE.WA,  PGE Polska Grupa Energetyczna S.A.',
    name: 'PGE Polska Grupa Energetyczna S.A.',
    symbol: 'PGE.WA',
  },
  {
    value: 'PGAS.JK',
    label: 'PGAS.JK,  PT Perusahaan Gas Negara Tbk',
    name: 'PT Perusahaan Gas Negara Tbk',
    symbol: 'PGAS.JK',
  },
  {
    value: 'PFRM3.SA',
    label: 'PFRM3.SA,  Profarma Distribuidora de Produtos Farmacêuticos S.A.',
    name: 'Profarma Distribuidora de Produtos Farmacêuticos S.A.',
    symbol: 'PFRM3.SA',
  },
  {
    value: 'PFI.NZ',
    label: 'PFI.NZ,  Property For Industry Limited',
    name: 'Property For Industry Limited',
    symbol: 'PFI.NZ',
  },
  {
    value: 'PETZ3.SA',
    label: 'PETZ3.SA,  Pet Center Comércio e Participações S.A.',
    name: 'Pet Center Comércio e Participações S.A.',
    symbol: 'PETZ3.SA',
  },
  {
    value: 'PETR4.SA',
    label: 'PETR4.SA,  Petróleo Brasileiro S.A. - Petrobras',
    name: 'Petróleo Brasileiro S.A. - Petrobras',
    symbol: 'PETR4.SA',
  },
  {
    value: 'PETKM.IS',
    label: 'PETKM.IS,  Petkim Petrokimya Holding Anonim Sirketi',
    name: 'Petkim Petrokimya Holding Anonim Sirketi',
    symbol: 'PETKM.IS',
  },
  {
    value: 'PERI.TA',
    label: 'PERI.TA,  Perion Network Ltd.',
    name: 'Perion Network Ltd.',
    symbol: 'PERI.TA',
  },
  {
    value: 'PEP.WA',
    label: 'PEP.WA,  Polenergia S.A.',
    name: 'Polenergia S.A.',
    symbol: 'PEP.WA',
  },
  {
    value: 'PEO.WA',
    label: 'PEO.WA,  Bank Polska Kasa Opieki S.A.',
    name: 'Bank Polska Kasa Opieki S.A.',
    symbol: 'PEO.WA',
  },
  {
    value: 'PE&OLES.MX',
    label: 'PE&OLES.MX,  Industrias Peñoles, S.A.B. de C.V.',
    name: 'Industrias Peñoles, S.A.B. de C.V.',
    symbol: 'PE&OLES.MX',
  },
  {
    value: 'PEMEX',
    label: 'PEMEX,  Pioneer Emerging Markets Equity Fund',
    name: 'Pioneer Emerging Markets Equity Fund',
    symbol: 'PEMEX',
  },
  {
    value: 'PDYPY',
    label: 'PDYPY,  Flutter Entertainment plc',
    name: 'Flutter Entertainment plc',
    symbol: 'PDYPY',
  },
  {
    value: 'PDO',
    label: 'PDO,  Pimco Dynamic Income Opportunities Fund',
    name: 'Pimco Dynamic Income Opportunities Fund',
    symbol: 'PDO',
  },
  {
    value: 'PDGR3.SA',
    label: 'PDGR3.SA,  PDG Realty S.A. Empreendimentos e Participações',
    name: 'PDG Realty S.A. Empreendimentos e Participações',
    symbol: 'PDGR3.SA',
  },
  {
    value: 'PCT.NZ',
    label: 'PCT.NZ,  Precinct Properties New Zealand Limited',
    name: 'Precinct Properties New Zealand Limited',
    symbol: 'PCT.NZ',
  },
  {
    value: 'PCR.WA',
    label: 'PCR.WA,  PCC Rokita SA',
    name: 'PCC Rokita SA',
    symbol: 'PCR.WA',
  },
  {
    value: 'PCF.WA',
    label: 'PCF.WA,  PCF Group Spólka Akcyjna',
    name: 'PCF Group Spólka Akcyjna',
    symbol: 'PCF.WA',
  },
  {
    value: 'PCELL.ST',
    label: 'PCELL.ST,  PowerCell Sweden AB (publ)',
    name: 'PowerCell Sweden AB (publ)',
    symbol: 'PCELL.ST',
  },
  {
    value: 'PCAR3.SA',
    label: 'PCAR3.SA,  Companhia Brasileira de Distribuição',
    name: 'Companhia Brasileira de Distribuição',
    symbol: 'PCAR3.SA',
  },
  {
    value: 'PBX.WA',
    label: 'PBX.WA,  Pekabex S.A.',
    name: 'Pekabex S.A.',
    symbol: 'PBX.WA',
  },
  {
    value: 'PBLA',
    label: 'PBLA,  Panbela Therapeutics, Inc.',
    name: 'Panbela Therapeutics, Inc.',
    symbol: 'PBLA',
  },
  {
    value: 'PARD3.SA',
    label: 'PARD3.SA,  Instituto Hermes Pardini S.A.',
    name: 'Instituto Hermes Pardini S.A.',
    symbol: 'PARD3.SA',
  },
  {
    value: 'OWLT',
    label: 'OWLT,  Owlet, Inc.',
    name: 'Owlet, Inc.',
    symbol: 'OWLT',
  },
  {
    value: 'OVZON.ST',
    label: 'OVZON.ST,  Ovzon AB (publ)',
    name: 'Ovzon AB (publ)',
    symbol: 'OVZON.ST',
  },
  {
    value: 'OVS.MI',
    label: 'OVS.MI,  OVS S.p.A.',
    name: 'OVS S.p.A.',
    symbol: 'OVS.MI',
  },
  {
    value: 'OUT1V.HE',
    label: 'OUT1V.HE,  Outokumpu Oyj',
    name: 'Outokumpu Oyj',
    symbol: 'OUT1V.HE',
  },
  {
    value: 'OSP-R.BK',
    label: 'OSP-R.BK,  Osotspa Public Company Limited',
    name: 'Osotspa Public Company Limited',
    symbol: 'OSP-R.BK',
  },
  {
    value: 'ORX.ST',
    label: 'ORX.ST,  Orexo AB (publ)',
    name: 'Orexo AB (publ)',
    symbol: 'ORX.ST',
  },
  {
    value: 'ORSTED.CO',
    label: 'ORSTED.CO,  Ørsted A/S',
    name: 'Ørsted A/S',
    symbol: 'ORSTED.CO',
  },
  {
    value: 'OR-R.BK',
    label: 'OR-R.BK,  PTT Oil and Retail Business Public Company Limited',
    name: 'PTT Oil and Retail Business Public Company Limited',
    symbol: 'OR-R.BK',
  },
  {
    value: 'ORNBV.HE',
    label: 'ORNBV.HE,  Orion Oyj',
    name: 'Orion Oyj',
    symbol: 'ORNBV.HE',
  },
  {
    value: 'ORNAV.HE',
    label: 'ORNAV.HE,  Orion Oyj',
    name: 'Orion Oyj',
    symbol: 'ORNAV.HE',
  },
  {
    value: 'ORL.TA',
    label: 'ORL.TA,  Oil Refineries Ltd.',
    name: 'Oil Refineries Ltd.',
    symbol: 'ORL.TA',
  },
  {
    value: 'ORI-R.BK',
    label: 'ORI-R.BK,  Origin Property Public Company Limited',
    name: 'Origin Property Public Company Limited',
    symbol: 'ORI-R.BK',
  },
  {
    value: 'ORGN',
    label: 'ORGN,  Origin Materials, Inc.',
    name: 'Origin Materials, Inc.',
    symbol: 'ORGN',
  },
  {
    value: 'ORES.ST',
    label: 'ORES.ST,  Investment AB Öresund (publ)',
    name: 'Investment AB Öresund (publ)',
    symbol: 'ORES.ST',
  },
  {
    value: 'ORDS.QA',
    label: 'ORDS.QA,  Ooredoo Q.P.S.C.',
    name: 'Ooredoo Q.P.S.C.',
    symbol: 'ORDS.QA',
  },
  {
    value: 'ORBIA.MX',
    label: 'ORBIA.MX,  Orbia Advance Corporation, S.A.B. de C.V.',
    name: 'Orbia Advance Corporation, S.A.B. de C.V.',
    symbol: 'ORBIA.MX',
  },
  {
    value: 'OPL.WA',
    label: 'OPL.WA,  Orange Polska S.A.',
    name: 'Orange Polska S.A.',
    symbol: 'OPL.WA',
  },
  {
    value: 'OPCE.TA',
    label: 'OPCE.TA,  OPC Energy Ltd.',
    name: 'OPC Energy Ltd.',
    symbol: 'OPCE.TA',
  },
  {
    value: 'ONE.TA',
    label: 'ONE.TA,  One Software Technologies Ltd',
    name: 'One Software Technologies Ltd',
    symbol: 'ONE.TA',
  },
  {
    value: 'ONCO.ST',
    label: 'ONCO.ST,  Oncopeptides AB (publ)',
    name: 'Oncopeptides AB (publ)',
    symbol: 'ONCO.ST',
  },
  {
    value: 'OMIC',
    label: 'OMIC,  Singular Genomics Systems, Inc.',
    name: 'Singular Genomics Systems, Inc.',
    symbol: 'OMIC',
  },
  {
    value: 'OMAB.MX',
    label: 'OMAB.MX,  Grupo Aeroportuario del Centro Norte, S.A.B. de C.V.',
    name: 'Grupo Aeroportuario del Centro Norte, S.A.B. de C.V.',
    symbol: 'OMAB.MX',
  },
  {
    value: 'OLVAS.HE',
    label: 'OLVAS.HE,  Olvi Oyj',
    name: 'Olvi Oyj',
    symbol: 'OLVAS.HE',
  },
  {
    value: 'OKDBV.HE',
    label: 'OKDBV.HE,  Oriola Oyj',
    name: 'Oriola Oyj',
    symbol: 'OKDBV.HE',
  },
  {
    value: 'OIBR3.SA',
    label: 'OIBR3.SA,  Oi S.A.',
    name: 'Oi S.A.',
    symbol: 'OIBR3.SA',
  },
  {
    value: 'ODPV3.SA',
    label: 'ODPV3.SA,  Odontoprev S.A.',
    name: 'Odontoprev S.A.',
    symbol: 'ODPV3.SA',
  },
  {
    value: 'OCUP',
    label: 'OCUP,  Ocuphire Pharma, Inc.',
    name: 'Ocuphire Pharma, Inc.',
    symbol: 'OCUP',
  },
  {
    value: 'OCA.NZ',
    label: 'OCA.NZ,  Oceania Healthcare Limited',
    name: 'Oceania Healthcare Limited',
    symbol: 'OCA.NZ',
  },
  {
    value: 'NZX.NZ',
    label: 'NZX.NZ,  NZX Limited',
    name: 'NZX Limited',
    symbol: 'NZX.NZ',
  },
  {
    value: 'NYXH',
    label: 'NYXH,  Nyxoah S.A.',
    name: 'Nyxoah S.A.',
    symbol: 'NYXH',
  },
  {
    value: 'NYF.ST',
    label: 'NYF.ST,  Nyfosa AB (publ)',
    name: 'Nyfosa AB (publ)',
    symbol: 'NYF.ST',
  },
  {
    value: 'NWG.WA',
    label: 'NWG.WA,  Newag S.A.',
    name: 'Newag S.A.',
    symbol: 'NWG.WA',
  },
  {
    value: 'NWG.ST',
    label: 'NWG.ST,  Nordic Waterproofing Holding AB (publ)',
    name: 'Nordic Waterproofing Holding AB (publ)',
    symbol: 'NWG.ST',
  },
  {
    value: 'NWG.L',
    label: 'NWG.L,  NatWest Group plc',
    name: 'NatWest Group plc',
    symbol: 'NWG.L',
  },
  {
    value: 'NVMI.TA',
    label: 'NVMI.TA,  Nova Ltd.',
    name: 'Nova Ltd.',
    symbol: 'NVMI.TA',
  },
  {
    value: 'NUHCM.IS',
    label: 'NUHCM.IS,  Nuh Çimento Sanayi A.S.',
    name: 'Nuh Çimento Sanayi A.S.',
    symbol: 'NUHCM.IS',
  },
  {
    value: 'NTML.TA',
    label: 'NTML.TA,  Neto Malinda Trading Ltd.',
    name: 'Neto Malinda Trading Ltd.',
    symbol: 'NTML.TA',
  },
  {
    value: 'NTHOL.IS',
    label: 'NTHOL.IS,  Net Holding A.S.',
    name: 'Net Holding A.S.',
    symbol: 'NTHOL.IS',
  },
  {
    value: 'NTGY.MC',
    label: 'NTGY.MC,  Naturgy Energy Group, S.A.',
    name: 'Naturgy Energy Group, S.A.',
    symbol: 'NTGY.MC',
  },
  {
    value: 'NTDOY',
    label: 'NTDOY,  Nintendo Co., Ltd.',
    name: 'Nintendo Co., Ltd.',
    symbol: 'NTDOY',
  },
  {
    value: 'NTCO3.SA',
    label: 'NTCO3.SA,  Natura &Co Holding S.A.',
    name: 'Natura &Co Holding S.A.',
    symbol: 'NTCO3.SA',
  },
  {
    value: 'NSTB',
    label: 'NSTB,  Northern Star Investment Corp. II',
    name: 'Northern Star Investment Corp. II',
    symbol: 'NSTB',
  },
  {
    value: 'NSANY',
    label: 'NSANY,  Nissan Motor Co., Ltd.',
    name: 'Nissan Motor Co., Ltd.',
    symbol: 'NSANY',
  },
  {
    value: 'NP3.ST',
    label: 'NP3.ST,  NP3 Fastigheter AB (publ)',
    name: 'NP3 Fastigheter AB (publ)',
    symbol: 'NP3.ST',
  },
  {
    value: 'NOTE.ST',
    label: 'NOTE.ST,  NOTE AB (publ)',
    name: 'NOTE AB (publ)',
    symbol: 'NOTE.ST',
  },
  {
    value: 'NORTHM.CO',
    label: 'NORTHM.CO,  North Media A/S',
    name: 'North Media A/S',
    symbol: 'NORTHM.CO',
  },
  {
    value: 'NOKIA.HE',
    label: 'NOKIA.HE,  Nokia Oyj',
    name: 'Nokia Oyj',
    symbol: 'NOKIA.HE',
  },
  {
    value: 'NOBLE-R.BK',
    label: 'NOBLE-R.BK,  Noble Development Public Company Limited',
    name: 'Noble Development Public Company Limited',
    symbol: 'NOBLE-R.BK',
  },
  {
    value: 'NOBI.ST',
    label: 'NOBI.ST,  Nobia AB (publ)',
    name: 'Nobia AB (publ)',
    symbol: 'NOBI.ST',
  },
  {
    value: 'NOAC',
    label: 'NOAC,  Natural Order Acquisition Corp.',
    name: 'Natural Order Acquisition Corp.',
    symbol: 'NOAC',
  },
  {
    value: 'NNIT.CO',
    label: 'NNIT.CO,  NNIT A/S',
    name: 'NNIT A/S',
    symbol: 'NNIT.CO',
  },
  {
    value: 'NMG',
    label: 'NMG,  Nouveau Monde Graphite Inc.',
    name: 'Nouveau Monde Graphite Inc.',
    symbol: 'NMG',
  },
  {
    value: 'NLFSK.CO',
    label: 'NLFSK.CO,  Nilfisk Holding A/S',
    name: 'Nilfisk Holding A/S',
    symbol: 'NLFSK.CO',
  },
  {
    value: 'NKT.CO',
    label: 'NKT.CO,  NKT A/S',
    name: 'NKT A/S',
    symbol: 'NKT.CO',
  },
  {
    value: 'NISN',
    label: 'NISN,  Nisun International Enterprise Development Group Co., Ltd',
    name: 'Nisun International Enterprise Development Group Co., Ltd',
    symbol: 'NISN',
  },
  {
    value: 'NICE.TA',
    label: 'NICE.TA,  NICE Ltd.',
    name: 'NICE Ltd.',
    symbol: 'NICE.TA',
  },
  {
    value: 'NFTA.TA',
    label: 'NFTA.TA,  Naphtha Israel Petroleum Corp. Ltd.',
    name: 'Naphtha Israel Petroleum Corp. Ltd.',
    symbol: 'NFTA.TA',
  },
  {
    value: 'NEU.WA',
    label: 'NEU.WA,  NEUCA S.A.',
    name: 'NEUCA S.A.',
    symbol: 'NEU.WA',
  },
  {
    value: 'NESTE.HE',
    label: 'NESTE.HE,  Neste Oyj',
    name: 'Neste Oyj',
    symbol: 'NESTE.HE',
  },
  {
    value: 'NEOE3.SA',
    label: 'NEOE3.SA,  Neoenergia S.A.',
    name: 'Neoenergia S.A.',
    symbol: 'NEOE3.SA',
  },
  {
    value: 'NEMAKA.MX',
    label: 'NEMAKA.MX,  Nemak, S. A. B. de C. V.',
    name: 'Nemak, S. A. B. de C. V.',
    symbol: 'NEMAKA.MX',
  },
  {
    value: 'NDMO',
    label: 'NDMO,  Nuveen Dynamic Municipal Opportunities Fund',
    name: 'Nuveen Dynamic Municipal Opportunities Fund',
    symbol: 'NDMO',
  },
  {
    value: 'NAWI.TA',
    label: 'NAWI.TA,  Nawi Brothers Group Ltd',
    name: 'Nawi Brothers Group Ltd',
    symbol: 'NAWI.TA',
  },
  {
    value: 'NAUT',
    label: 'NAUT,  Nautilus Biotechnology, Inc.',
    name: 'Nautilus Biotechnology, Inc.',
    symbol: 'NAUT',
  },
  {
    value: 'NANOV.OL',
    label: 'NANOV.OL,  Nordic Nanovector ASA',
    name: 'Nordic Nanovector ASA',
    symbol: 'NANOV.OL',
  },
  {
    value: 'NA9.DE',
    label: 'NA9.DE,  Nagarro SE',
    name: 'Nagarro SE',
    symbol: 'NA9.DE',
  },
  {
    value: 'MZTF.TA',
    label: 'MZTF.TA,  Mizrahi Tefahot Bank Ltd.',
    name: 'Mizrahi Tefahot Bank Ltd.',
    symbol: 'MZTF.TA',
  },
  {
    value: 'MYTHY',
    label: 'MYTHY,  Mytilineos S.A.',
    name: 'Mytilineos S.A.',
    symbol: 'MYTHY',
  },
  {
    value: 'MYRX',
    label: 'MYRX,  Myrexis, Inc.',
    name: 'Myrexis, Inc.',
    symbol: 'MYRX',
  },
  {
    value: 'MYPS',
    label: 'MYPS,  PLAYSTUDIOS, Inc.',
    name: 'PLAYSTUDIOS, Inc.',
    symbol: 'MYPS',
  },
  {
    value: 'MYPK3.SA',
    label: 'MYPK3.SA,  Iochpe-Maxion S.A.',
    name: 'Iochpe-Maxion S.A.',
    symbol: 'MYPK3.SA',
  },
  {
    value: 'MYOR.JK',
    label: 'MYOR.JK,  PT Mayora Indah Tbk',
    name: 'PT Mayora Indah Tbk',
    symbol: 'MYOR.JK',
  },
  {
    value: 'MYCR.ST',
    label: 'MYCR.ST,  Mycronic AB (publ)',
    name: 'Mycronic AB (publ)',
    symbol: 'MYCR.ST',
  },
  {
    value: 'MVNE.TA',
    label: 'MVNE.TA,  Mivne Real Estate (K.D) Ltd',
    name: 'Mivne Real Estate (K.D) Ltd',
    symbol: 'MVNE.TA',
  },
  {
    value: 'MVC.MC',
    label: 'MVC.MC,  Metrovacesa S.A.',
    name: 'Metrovacesa S.A.',
    symbol: 'MVC.MC',
  },
  {
    value: 'MUSTI.HE',
    label: 'MUSTI.HE,  Musti Group Oyj',
    name: 'Musti Group Oyj',
    symbol: 'MUSTI.HE',
  },
  {
    value: 'MULT3.SA',
    label: 'MULT3.SA,  Multiplan Empreendimentos Imobiliários S.A.',
    name: 'Multiplan Empreendimentos Imobiliários S.A.',
    symbol: 'MULT3.SA',
  },
  {
    value: 'MTRX.TA',
    label: 'MTRX.TA,  Matrix IT Ltd.',
    name: 'Matrix IT Ltd.',
    symbol: 'MTRX.TA',
  },
  {
    value: 'MTRS.ST',
    label: 'MTRS.ST,  Munters Group AB (publ)',
    name: 'Munters Group AB (publ)',
    symbol: 'MTRS.ST',
  },
  {
    value: 'MTRN.TA',
    label: 'MTRN.TA,  Maytronics Ltd.',
    name: 'Maytronics Ltd.',
    symbol: 'MTRN.TA',
  },
  {
    value: 'MTRE3.SA',
    label: 'MTRE3.SA,  Mitre Realty Empreendimentos e Participações S.A.',
    name: 'Mitre Realty Empreendimentos e Participações S.A.',
    symbol: 'MTRE3.SA',
  },
  {
    value: 'MTC-R.BK',
    label: 'MTC-R.BK,  Muangthai Capital Public Company Limited',
    name: 'Muangthai Capital Public Company Limited',
    symbol: 'MTC-R.BK',
  },
  {
    value: 'MTAC',
    label: 'MTAC,  MedTech Acquisition Corporation',
    name: 'MedTech Acquisition Corporation',
    symbol: 'MTAC',
  },
  {
    value: 'MSF.BR',
    label: 'MSF.BR,  Microsoft Corporation',
    name: 'Microsoft Corporation',
    symbol: 'MSF.BR',
  },
  {
    value: 'MRVE3.SA',
    label: 'MRVE3.SA,  MRV Engenharia e Participações S.A.',
    name: 'MRV Engenharia e Participações S.A.',
    symbol: 'MRVE3.SA',
  },
  {
    value: 'MRL.MC',
    label: 'MRL.MC,  MERLIN Properties SOCIMI, S.A.',
    name: 'MERLIN Properties SOCIMI, S.A.',
    symbol: 'MRL.MC',
  },
  {
    value: 'MRFG3.SA',
    label: 'MRFG3.SA,  Marfrig Global Foods S.A.',
    name: 'Marfrig Global Foods S.A.',
    symbol: 'MRFG3.SA',
  },
  {
    value: 'MRC.WA',
    label: 'MRC.WA,  Mercator Medical S.A.',
    name: 'Mercator Medical S.A.',
    symbol: 'MRC.WA',
  },
  {
    value: 'M-R.BK',
    label: 'M-R.BK,  MK Restaurant Group Public Company Limited',
    name: 'MK Restaurant Group Public Company Limited',
    symbol: 'M-R.BK',
  },
  {
    value: 'MPPA.JK',
    label: 'MPPA.JK,  PT Matahari Putra Prima Tbk',
    name: 'PT Matahari Putra Prima Tbk',
    symbol: 'MPPA.JK',
  },
  {
    value: 'MPMX.JK',
    label: 'MPMX.JK,  PT Mitra Pinasthika Mustika Tbk',
    name: 'PT Mitra Pinasthika Mustika Tbk',
    symbol: 'MPMX.JK',
  },
  {
    value: 'MPHC.QA',
    label: 'MPHC.QA,  Mesaieed Petrochemical Holding Company Q.P.S.C.',
    name: 'Mesaieed Petrochemical Holding Company Q.P.S.C.',
    symbol: 'MPHC.QA',
  },
  {
    value: 'MPARK.IS',
    label: 'MPARK.IS,  MLP Saglik Hizmetleri A.S.',
    name: 'MLP Saglik Hizmetleri A.S.',
    symbol: 'MPARK.IS',
  },
  {
    value: 'MOVI3.SA',
    label: 'MOVI3.SA,  Movida Participações S.A.',
    name: 'Movida Participações S.A.',
    symbol: 'MOVI3.SA',
  },
  {
    value: 'MOON.L',
    label: 'MOON.L,  Moonpig Group PLC',
    name: 'Moonpig Group PLC',
    symbol: 'MOON.L',
  },
  {
    value: 'MONET.PR',
    label: 'MONET.PR,  MONETA Money Bank, a.s.',
    name: 'MONETA Money Bank, a.s.',
    symbol: 'MONET.PR',
  },
  {
    value: 'MONC.MI',
    label: 'MONC.MI,  Moncler S.p.A.',
    name: 'Moncler S.p.A.',
    symbol: 'MONC.MI',
  },
  {
    value: 'MOLN',
    label: 'MOLN,  Molecular Partners AG',
    name: 'Molecular Partners AG',
    symbol: 'MOLN',
  },
  {
    value: 'MOL.MI',
    label: 'MOL.MI,  Gruppo MutuiOnline S.p.A',
    name: 'Gruppo MutuiOnline S.p.A',
    symbol: 'MOL.MI',
  },
  {
    value: 'MOCORP.HE',
    label: 'MOCORP.HE,  Metso Outotec Oyj',
    name: 'Metso Outotec Oyj',
    symbol: 'MOCORP.HE',
  },
  {
    value: 'MNDY',
    label: 'MNDY,  monday.com Ltd.',
    name: 'monday.com Ltd.',
    symbol: 'MNDY',
  },
  {
    value: 'MNC.WA',
    label: 'MNC.WA,  Mennica Polska S.A.',
    name: 'Mennica Polska S.A.',
    symbol: 'MNC.WA',
  },
  {
    value: 'MNCN.JK',
    label: 'MNCN.JK,  PT. Media Nusantara Citra Tbk',
    name: 'PT. Media Nusantara Citra Tbk',
    symbol: 'MNCN.JK',
  },
  {
    value: 'MMHD.TA',
    label: 'MMHD.TA,  Menora Mivtachim Holdings Ltd',
    name: 'Menora Mivtachim Holdings Ltd',
    symbol: 'MMHD.TA',
  },
  {
    value: 'MLTM.TA',
    label: 'MLTM.TA,  Malam - Team Ltd',
    name: 'Malam - Team Ltd',
    symbol: 'MLTM.TA',
  },
  {
    value: 'MLSR.TA',
    label: 'MLSR.TA,  Melisron Ltd.',
    name: 'Melisron Ltd.',
    symbol: 'MLSR.TA',
  },
  {
    value: 'MLG.WA',
    label: 'MLG.WA,  MLP Group S.A.',
    name: 'MLP Group S.A.',
    symbol: 'MLG.WA',
  },
  {
    value: 'MKFG',
    label: 'MKFG,  Markforged Holding Corporation',
    name: 'Markforged Holding Corporation',
    symbol: 'MKFG',
  },
  {
    value: 'MIPS.ST',
    label: 'MIPS.ST,  Mips AB (publ)',
    name: 'Mips AB (publ)',
    symbol: 'MIPS.ST',
  },
  {
    value: 'MINT-R.BK',
    label: 'MINT-R.BK,  Minor International Public Company Limited',
    name: 'Minor International Public Company Limited',
    symbol: 'MINT-R.BK',
  },
  {
    value: 'MINEST.ST',
    label: 'MINEST.ST,  Minesto AB (publ)',
    name: 'Minesto AB (publ)',
    symbol: 'MINEST.ST',
  },
  {
    value: 'MIL.WA',
    label: 'MIL.WA,  Bank Millennium S.A.',
    name: 'Bank Millennium S.A.',
    symbol: 'MIL.WA',
  },
  {
    value: 'MILS3.SA',
    label: 'MILS3.SA,  Mills Locação, Serviços e Logística S.A.',
    name: 'Mills Locação, Serviços e Logística S.A.',
    symbol: 'MILS3.SA',
  },
  {
    value: 'MIKA.JK',
    label: 'MIKA.JK,  PT Mitra Keluarga Karyasehat Tbk',
    name: 'PT Mitra Keluarga Karyasehat Tbk',
    symbol: 'MIKA.JK',
  },
  {
    value: 'MGT.WA',
    label: 'MGT.WA,  Mangata Holding S.A.',
    name: 'Mangata Holding S.A.',
    symbol: 'MGT.WA',
  },
  {
    value: 'MGROS.IS',
    label: 'MGROS.IS,  Migros Ticaret A.S.',
    name: 'Migros Ticaret A.S.',
    symbol: 'MGROS.IS',
  },
  {
    value: 'MGRO.JK',
    label: 'MGRO.JK,  PT Mahkota Group Tbk',
    name: 'PT Mahkota Group Tbk',
    symbol: 'MGRO.JK',
  },
  {
    value: 'MGOR.TA',
    label: 'MGOR.TA,  Mega Or Holdings Ltd',
    name: 'Mega Or Holdings Ltd',
    symbol: 'MGOR.TA',
  },
  {
    value: 'MGLU3.SA',
    label: 'MGLU3.SA,  Magazine Luiza S.A.',
    name: 'Magazine Luiza S.A.',
    symbol: 'MGLU3.SA',
  },
  {
    value: 'MGIC.TA',
    label: 'MGIC.TA,  Magic Software Enterprises Ltd.',
    name: 'Magic Software Enterprises Ltd.',
    symbol: 'MGIC.TA',
  },
  {
    value: 'MGDL.TA',
    label: 'MGDL.TA,  Migdal Insurance and Financial Holdings Ltd.',
    name: 'Migdal Insurance and Financial Holdings Ltd.',
    symbol: 'MGDL.TA',
  },
  {
    value: 'MGDDY',
    label:
      'MGDDY,  Compagnie Générale des Établissements Michelin Société en commandite par actions',
    name: 'Compagnie Générale des Établissements Michelin Société en commandite par actions',
    symbol: 'MGDDY',
  },
  {
    value: 'MFT.NZ',
    label: 'MFT.NZ,  Mainfreight Limited',
    name: 'Mainfreight Limited',
    symbol: 'MFT.NZ',
  },
  {
    value: 'METSB.HE',
    label: 'METSB.HE,  Metsä Board Oyj',
    name: 'Metsä Board Oyj',
    symbol: 'METSB.HE',
  },
  {
    value: 'MERS.QA',
    label: 'MERS.QA,  Al Meera Consumer Goods Company Q.P.S.C.',
    name: 'Al Meera Consumer Goods Company Q.P.S.C.',
    symbol: 'MERS.QA',
  },
  {
    value: 'MEL.NZ',
    label: 'MEL.NZ,  Meridian Energy Limited',
    name: 'Meridian Energy Limited',
    symbol: 'MEL.NZ',
  },
  {
    value: 'MEL.MC',
    label: 'MEL.MC,  Meliá Hotels International, S.A.',
    name: 'Meliá Hotels International, S.A.',
    symbol: 'MEL.MC',
  },
  {
    value: 'MEKO.ST',
    label: 'MEKO.ST,  Meko AB',
    name: 'Meko AB',
    symbol: 'MEKO.ST',
  },
  {
    value: 'MEKKO.HE',
    label: 'MEKKO.HE,  Marimekko Oyj',
    name: 'Marimekko Oyj',
    symbol: 'MEKKO.HE',
  },
  {
    value: 'MEGA-R.BK',
    label: 'MEGA-R.BK,  Mega Lifesciences Public Company Limited',
    name: 'Mega Lifesciences Public Company Limited',
    symbol: 'MEGA-R.BK',
  },
  {
    value: 'MEGACPO.MX',
    label: 'MEGACPO.MX,  Megacable Holdings, S. A. B. de C. V.',
    name: 'Megacable Holdings, S. A. B. de C. V.',
    symbol: 'MEGACPO.MX',
  },
  {
    value: 'MEDN.TA',
    label: 'MEDN.TA,  Mehadrin Ltd.',
    name: 'Mehadrin Ltd.',
    symbol: 'MEDN.TA',
  },
  {
    value: 'MEDC.JK',
    label: 'MEDC.JK,  PT Medco Energi Internasional Tbk',
    name: 'PT Medco Energi Internasional Tbk',
    symbol: 'MEDC.JK',
  },
  {
    value: 'MEAL3.SA',
    label: 'MEAL3.SA,  International Meal Company Alimentação S.A.',
    name: 'International Meal Company Alimentação S.A.',
    symbol: 'MEAL3.SA',
  },
  {
    value: 'MDTR.TA',
    label: 'MDTR.TA,  Mediterranean Towers Ltd.',
    name: 'Mediterranean Towers Ltd.',
    symbol: 'MDTR.TA',
  },
  {
    value: 'MDNE3.SA',
    label: 'MDNE3.SA,  Moura Dubeux Engenharia S.A.',
    name: 'Moura Dubeux Engenharia S.A.',
    symbol: 'MDNE3.SA',
  },
  {
    value: 'MDLN.JK',
    label: 'MDLN.JK,  PT Modernland Realty Tbk',
    name: 'PT Modernland Realty Tbk',
    symbol: 'MDLN.JK',
  },
  {
    value: 'MDKA.JK',
    label: 'MDKA.JK,  PT Merdeka Copper Gold Tbk',
    name: 'PT Merdeka Copper Gold Tbk',
    symbol: 'MDKA.JK',
  },
  {
    value: 'MDIA3.SA',
    label: 'MDIA3.SA,  M. Dias Branco S.A. Indústria e Comércio de Alimentos',
    name: 'M. Dias Branco S.A. Indústria e Comércio de Alimentos',
    symbol: 'MDIA3.SA',
  },
  {
    value: 'MDG.WA',
    label: 'MDG.WA,  Medicalgorithmics S.A.',
    name: 'Medicalgorithmics S.A.',
    symbol: 'MDG.WA',
  },
  {
    value: 'MCY.NZ',
    label: 'MCY.NZ,  Mercury NZ Limited',
    name: 'Mercury NZ Limited',
    symbol: 'MCY.NZ',
  },
  {
    value: 'MCS-R.BK',
    label: 'MCS-R.BK,  M.C.S. Steel Public Company Limited',
    name: 'M.C.S. Steel Public Company Limited',
    symbol: 'MCS-R.BK',
  },
  {
    value: 'MC-R.BK',
    label: 'MC-R.BK,  MC Group Public Company Limited',
    name: 'MC Group Public Company Limited',
    symbol: 'MC-R.BK',
  },
  {
    value: 'MCM.MC',
    label: 'MCM.MC,  Miquel y Costas & Miquel, S.A.',
    name: 'Miquel y Costas & Miquel, S.A.',
    symbol: 'MCM.MC',
  },
  {
    value: 'MCI.WA',
    label: 'MCI.WA,  MCI Capital Alternatywna Spólka Inwestycyjna S.A.',
    name: 'MCI Capital Alternatywna Spólka Inwestycyjna S.A.',
    symbol: 'MCI.WA',
  },
  {
    value: 'MCG',
    label: 'MCG,  Membership Collective Group Inc.',
    name: 'Membership Collective Group Inc.',
    symbol: 'MCG',
  },
  {
    value: 'MCAP.ST',
    label: 'MCAP.ST,  MedCap AB (publ)',
    name: 'MedCap AB (publ)',
    symbol: 'MCAP.ST',
  },
  {
    value: 'MBR.WA',
    label: 'MBR.WA,  Mo-BRUK S.A.',
    name: 'Mo-BRUK S.A.',
    symbol: 'MBR.WA',
  },
  {
    value: 'MBK.WA',
    label: 'MBK.WA,  mBank S.A.',
    name: 'mBank S.A.',
    symbol: 'MBK.WA',
  },
  {
    value: 'MAVI.IS',
    label: 'MAVI.IS,  Mavi Giyim Sanayi ve Ticaret A.S.',
    name: 'Mavi Giyim Sanayi ve Ticaret A.S.',
    symbol: 'MAVI.IS',
  },
  {
    value: 'MARK.QA',
    label: 'MARK.QA,  Masraf Al Rayan (Q.P.S.C.)',
    name: 'Masraf Al Rayan (Q.P.S.C.)',
    symbol: 'MARK.QA',
  },
  {
    value: 'MAPS',
    label: 'MAPS,  WM Technology, Inc.',
    name: 'WM Technology, Inc.',
    symbol: 'MAPS',
  },
  {
    value: 'MAP.MC',
    label: 'MAP.MC,  Mapfre, S.A.',
    name: 'Mapfre, S.A.',
    symbol: 'MAP.MC',
  },
  {
    value: 'MAPI.JK',
    label: 'MAPI.JK,  PT. Mitra Adiperkasa Tbk',
    name: 'PT. Mitra Adiperkasa Tbk',
    symbol: 'MAPI.JK',
  },
  {
    value: 'MAORF',
    label: 'MAORF,  Mandarin Oriental International Limited',
    name: 'Mandarin Oriental International Limited',
    symbol: 'MAORF',
  },
  {
    value: 'MAB.WA',
    label: 'MAB.WA,  Mabion S.A.',
    name: 'Mabion S.A.',
    symbol: 'MAB.WA',
  },
  {
    value: 'OPAD',
    label: 'OPAD,  Offerpad Solutions Inc.',
    name: 'Offerpad Solutions Inc.',
    symbol: 'OPAD',
  },
  {
    value: 'LWB.WA',
    label: 'LWB.WA,  Lubelski Wegiel Bogdanka S.A.',
    name: 'Lubelski Wegiel Bogdanka S.A.',
    symbol: 'LWB.WA',
  },
  {
    value: 'LVOX',
    label: 'LVOX,  LiveVox Holdings, Inc.',
    name: 'LiveVox Holdings, Inc.',
    symbol: 'LVOX',
  },
  {
    value: 'LVC.WA',
    label: 'LVC.WA,  LiveChat Software S.A.',
    name: 'LiveChat Software S.A.',
    symbol: 'LVC.WA',
  },
  {
    value: 'LUXA',
    label: 'LUXA,  Lux Health Tech Acquisition Corp.',
    name: 'Lux Health Tech Acquisition Corp.',
    symbol: 'LUXA',
  },
  {
    value: 'LUMI.TA',
    label: 'LUMI.TA,  Bank Leumi le-Israel B.M.',
    name: 'Bank Leumi le-Israel B.M.',
    symbol: 'LUMI.TA',
  },
  {
    value: 'LTCH',
    label: 'LTCH,  Latch, Inc.',
    name: 'Latch, Inc.',
    symbol: 'LTCH',
  },
  {
    value: 'LSIP.JK',
    label: 'LSIP.JK,  PT Perusahaan Perkebunan London Sumatra Indonesia Tbk',
    name: 'PT Perusahaan Perkebunan London Sumatra Indonesia Tbk',
    symbol: 'LSIP.JK',
  },
  {
    value: 'LSEG.L',
    label: 'LSEG.L,  London Stock Exchange Group plc',
    name: 'London Stock Exchange Group plc',
    symbol: 'LSEG.L',
  },
  {
    value: 'LREN3.SA',
    label: 'LREN3.SA,  Lojas Renner S.A.',
    name: 'Lojas Renner S.A.',
    symbol: 'LREN3.SA',
  },
  {
    value: 'LPSB3.SA',
    label: 'LPSB3.SA,  LPS Brasil - Consultoria de Imóveis S.A.',
    name: 'LPS Brasil - Consultoria de Imóveis S.A.',
    symbol: 'LPSB3.SA',
  },
  {
    value: 'LPP.WA',
    label: 'LPP.WA,  LPP SA',
    name: 'LPP SA',
    symbol: 'LPP.WA',
  },
  {
    value: 'LPPF.JK',
    label: 'LPPF.JK,  PT Matahari Department Store Tbk',
    name: 'PT Matahari Department Store Tbk',
    symbol: 'LPPF.JK',
  },
  {
    value: 'LPKR.JK',
    label: 'LPKR.JK,  PT Lippo Karawaci Tbk',
    name: 'PT Lippo Karawaci Tbk',
    symbol: 'LPKR.JK',
  },
  {
    value: 'LPCK.JK',
    label: 'LPCK.JK,  PT Lippo Cikarang Tbk',
    name: 'PT Lippo Cikarang Tbk',
    symbol: 'LPCK.JK',
  },
  {
    value: 'LOWLF',
    label: 'LOWLF,  Lowell Farms Inc.',
    name: 'Lowell Farms Inc.',
    symbol: 'LOWLF',
  },
  {
    value: 'LOOMIS.ST',
    label: 'LOOMIS.ST,  Loomis AB (publ)',
    name: 'Loomis AB (publ)',
    symbol: 'LOOMIS.ST',
  },
  {
    value: 'LOGO.IS',
    label: 'LOGO.IS,  Logo Yazilim Sanayi ve Ticaret A.S.',
    name: 'Logo Yazilim Sanayi ve Ticaret A.S.',
    symbol: 'LOGO.IS',
  },
  {
    value: 'LOGN3.SA',
    label: 'LOGN3.SA,  Log-In Logística Intermodal S.A.',
    name: 'Log-In Logística Intermodal S.A.',
    symbol: 'LOGN3.SA',
  },
  {
    value: 'LOGN',
    label: 'LOGN,  Logansport Financial Corp.',
    name: 'Logansport Financial Corp.',
    symbol: 'LOGN',
  },
  {
    value: 'LOG.MC',
    label: 'LOG.MC,  Compañía de Distribución Integral Logista Holdings, S.A.',
    name: 'Compañía de Distribución Integral Logista Holdings, S.A.',
    symbol: 'LOG.MC',
  },
  {
    value: 'LOGG3.SA',
    label: 'LOGG3.SA,  LOG Commercial Properties e Participações S.A.',
    name: 'LOG Commercial Properties e Participações S.A.',
    symbol: 'LOGG3.SA',
  },
  {
    value: 'LLIS3.SA',
    label: 'LLIS3.SA,  Restoque Comércio e Confecções de Roupas S.A.',
    name: 'Restoque Comércio e Confecções de Roupas S.A.',
    symbol: 'LLIS3.SA',
  },
  {
    value: 'LJQQ3.SA',
    label: 'LJQQ3.SA,  Lojas Quero-Quero S.A.',
    name: 'Lojas Quero-Quero S.A.',
    symbol: 'LJQQ3.SA',
  },
  {
    value: 'LISP.SW',
    label: 'LISP.SW,  Chocoladefabriken Lindt & Sprüngli AG',
    name: 'Chocoladefabriken Lindt & Sprüngli AG',
    symbol: 'LISP.SW',
  },
  {
    value: 'LINK.OL',
    label: 'LINK.OL,  LINK Mobility Group Holding ASA',
    name: 'LINK Mobility Group Holding ASA',
    symbol: 'LINK.OL',
  },
  {
    value: 'LINK.JK',
    label: 'LINK.JK,  PT Link Net Tbk',
    name: 'PT Link Net Tbk',
    symbol: 'LINK.JK',
  },
  {
    value: 'LIME.ST',
    label: 'LIME.ST,  Lime Technologies AB (publ)',
    name: 'Lime Technologies AB (publ)',
    symbol: 'LIME.ST',
  },
  {
    value: 'LIGT3.SA',
    label: 'LIGT3.SA,  Light S.A.',
    name: 'Light S.A.',
    symbol: 'LIGT3.SA',
  },
  {
    value: 'LIAB.ST',
    label: 'LIAB.ST,  Lindab International AB (publ)',
    name: 'Lindab International AB (publ)',
    symbol: 'LIAB.ST',
  },
  {
    value: 'LH-R.BK',
    label: 'LH-R.BK,  Land and Houses Public Company Limited',
    name: 'Land and Houses Public Company Limited',
    symbol: 'LH-R.BK',
  },
  {
    value: 'LFTR',
    label: 'LFTR,  Lefteris Acquisition Corp.',
    name: 'Lefteris Acquisition Corp.',
    symbol: 'LFTR',
  },
  {
    value: 'LFT',
    label: 'LFT,  Lument Finance Trust, Inc.',
    name: 'Lument Finance Trust, Inc.',
    symbol: 'LFT',
  },
  {
    value: 'LEVE3.SA',
    label: 'LEVE3.SA,  MAHLE Metal Leve S.A.',
    name: 'MAHLE Metal Leve S.A.',
    symbol: 'LEVE3.SA',
  },
  {
    value: 'LEON',
    label: 'LEON,  Leone Asset Management, Inc.',
    name: 'Leone Asset Management, Inc.',
    symbol: 'LEON',
  },
  {
    value: 'SUAS.L',
    label: 'SUAS.L,  iShares MSCI USA SRI UCITS ETF USD (Acc)',
    name: 'iShares MSCI USA SRI UCITS ETF USD (Acc)',
    symbol: 'SUAS.L',
  },
  {
    value: 'FNARX',
    label:
      'FNARX,  Fidelity Select Portfolios - Fidelity Natural Resources Fund',
    name: 'Fidelity Select Portfolios - Fidelity Natural Resources Fund',
    symbol: 'FNARX',
  },
  {
    value: 'Q5T.SI',
    label: 'Q5T.SI,  Far East Hospitality Trust',
    name: 'Far East Hospitality Trust',
    symbol: 'Q5T.SI',
  },
  {
    value: 'PETR3.SA',
    label: 'PETR3.SA,  Petróleo Brasileiro S.A. - Petrobras',
    name: 'Petróleo Brasileiro S.A. - Petrobras',
    symbol: 'PETR3.SA',
  },
  {
    value: 'PDX.ST',
    label: 'PDX.ST,  Paradox Interactive AB (publ)',
    name: 'Paradox Interactive AB (publ)',
    symbol: 'PDX.ST',
  },
  {
    value: 'PARAUCO.SN',
    label: 'PARAUCO.SN,  Parque Arauco S.A.',
    name: 'Parque Arauco S.A.',
    symbol: 'PARAUCO.SN',
  },
  {
    value: 'PAAL-B.CO',
    label: 'PAAL-B.CO,  Per Aarsleff Holding A/S',
    name: 'Per Aarsleff Holding A/S',
    symbol: 'PAAL-B.CO',
  },
  {
    value: 'P40U.SI',
    label: 'P40U.SI,  Starhill Global Real Estate Investment Trust',
    name: 'Starhill Global Real Estate Investment Trust',
    symbol: 'P40U.SI',
  },
  {
    value: 'OVZ1.F',
    label: 'OVZ1.F,  Zensun Enterprises Limited',
    name: 'Zensun Enterprises Limited',
    symbol: 'OVZ1.F',
  },
  {
    value: 'OV8.SI',
    label: 'OV8.SI,  Sheng Siong Group Ltd',
    name: 'Sheng Siong Group Ltd',
    symbol: 'OV8.SI',
  },
  {
    value: 'OTKAR.IS',
    label: 'OTKAR.IS,  Otokar Otomotiv ve Savunma Sanayi A.S.',
    name: 'Otokar Otomotiv ve Savunma Sanayi A.S.',
    symbol: 'OTKAR.IS',
  },
  {
    value: 'O5RU.SI',
    label: 'O5RU.SI,  AIMS APAC REIT',
    name: 'AIMS APAC REIT',
    symbol: 'O5RU.SI',
  },
  {
    value: 'O39.SI',
    label: 'O39.SI,  Oversea-Chinese Banking Corporation Limited',
    name: 'Oversea-Chinese Banking Corporation Limited',
    symbol: 'O39.SI',
  },
  {
    value: 'NZYM-B.CO',
    label: 'NZYM-B.CO,  Novozymes A/S',
    name: 'Novozymes A/S',
    symbol: 'NZYM-B.CO',
  },
  {
    value: 'NTG.CO',
    label: 'NTG.CO,  NTG Nordic Transport Group A/S',
    name: 'NTG Nordic Transport Group A/S',
    symbol: 'NTG.CO',
  },
  {
    value: 'NOVO-B.CO',
    label: 'NOVO-B.CO,  Novo Nordisk A/S',
    name: 'Novo Nordisk A/S',
    symbol: 'NOVO-B.CO',
  },
  {
    value: 'NETC.CO',
    label: 'NETC.CO,  Netcompany Group A/S',
    name: 'Netcompany Group A/S',
    symbol: 'NETC.CO',
  },
  {
    value: 'N2IU.SI',
    label: 'N2IU.SI,  Mapletree Pan Asia Commercial Trust',
    name: 'Mapletree Pan Asia Commercial Trust',
    symbol: 'N2IU.SI',
  },
  {
    value: 'ME8U.SI',
    label: 'ME8U.SI,  Mapletree Industrial Trust',
    name: 'Mapletree Industrial Trust',
    symbol: 'ME8U.SI',
  },
  {
    value: 'MAXHEALTH.NS',
    label: 'MAXHEALTH.NS,  Max Healthcare Institute Limited',
    name: 'Max Healthcare Institute Limited',
    symbol: 'MAXHEALTH.NS',
  },
  {
    value: 'MATAS.CO',
    label: 'MATAS.CO,  Matas A/S',
    name: 'Matas A/S',
    symbol: 'MATAS.CO',
  },
  {
    value: 'MAERSK-B.CO',
    label: 'MAERSK-B.CO,  A.P. Møller - Mærsk A/S',
    name: 'A.P. Møller - Mærsk A/S',
    symbol: 'MAERSK-B.CO',
  },
  {
    value: 'MAERSK-A.CO',
    label: 'MAERSK-A.CO,  A.P. Møller - Mærsk A/S',
    name: 'A.P. Møller - Mærsk A/S',
    symbol: 'MAERSK-A.CO',
  },
  {
    value: 'M44U.SI',
    label: 'M44U.SI,  Mapletree Logistics Trust',
    name: 'Mapletree Logistics Trust',
    symbol: 'M44U.SI',
  },
  {
    value: 'M1GU.SI',
    label: 'M1GU.SI,  Sabana Industrial Real Estate Investment Trust',
    name: 'Sabana Industrial Real Estate Investment Trust',
    symbol: 'M1GU.SI',
  },
  {
    value: 'LJ3.SI',
    label: 'LJ3.SI,  OUE Limited',
    name: 'OUE Limited',
    symbol: 'LJ3.SI',
  },
  {
    value: 'LEHTO.HE',
    label: 'LEHTO.HE,  Lehto Group Oyj',
    name: 'Lehto Group Oyj',
    symbol: 'LEHTO.HE',
  },
  {
    value: 'LEAS',
    label: 'LEAS,  Strategic Asset Leasing, Inc.',
    name: 'Strategic Asset Leasing, Inc.',
    symbol: 'LEAS',
  },
  {
    value: 'LDO.MI',
    label: 'LDO.MI,  Leonardo S.p.a.',
    name: 'Leonardo S.p.a.',
    symbol: 'LDO.MI',
  },
  {
    value: 'LDA.MC',
    label: 'LDA.MC,  Línea Directa Aseguradora, S.A.',
    name: 'Línea Directa Aseguradora, S.A.',
    symbol: 'LDA.MC',
  },
  {
    value: 'LBRDP',
    label: 'LBRDP,  Liberty Broadband Corporation',
    name: 'Liberty Broadband Corporation',
    symbol: 'LBRDP',
  },
  {
    value: 'LAVV3.SA',
    label: 'LAVV3.SA,  Lavvi Empreendimentos Imobiliários S.A.',
    name: 'Lavvi Empreendimentos Imobiliários S.A.',
    symbol: 'LAVV3.SA',
  },
  {
    value: 'LAT1V.HE',
    label: 'LAT1V.HE,  Lassila & Tikanoja Oyj',
    name: 'Lassila & Tikanoja Oyj',
    symbol: 'LAT1V.HE',
  },
  {
    value: 'LACOMERUBC.MX',
    label: 'LACOMERUBC.MX,  La Comer, S.A.B. de C.V.',
    name: 'La Comer, S.A.B. de C.V.',
    symbol: 'LACOMERUBC.MX',
  },
  {
    value: 'LABB.MX',
    label: 'LABB.MX,  Genomma Lab Internacional, S.A.B. de C.V.',
    name: 'Genomma Lab Internacional, S.A.B. de C.V.',
    symbol: 'LABB.MX',
  },
  {
    value: 'KTY.WA',
    label: 'KTY.WA,  Grupa Kety S.A.',
    name: 'Grupa Kety S.A.',
    symbol: 'KTY.WA',
  },
  {
    value: 'KTRA',
    label: 'KTRA,  Kintara Therapeutics, Inc.',
    name: 'Kintara Therapeutics, Inc.',
    symbol: 'KTRA',
  },
  {
    value: 'KTC-R.BK',
    label: 'KTC-R.BK,  Krungthai Card Public Company Limited',
    name: 'Krungthai Card Public Company Limited',
    symbol: 'KTC-R.BK',
  },
  {
    value: 'KTB-R.BK',
    label: 'KTB-R.BK,  Krung Thai Bank Public Company Limited',
    name: 'Krung Thai Bank Public Company Limited',
    symbol: 'KTB-R.BK',
  },
  {
    value: 'KSPN',
    label: 'KSPN,  Kaspien Holdings Inc.',
    name: 'Kaspien Holdings Inc.',
    symbol: 'KSPN',
  },
  {
    value: 'KRU.WA',
    label: 'KRU.WA,  KRUK Spólka Akcyjna',
    name: 'KRUK Spólka Akcyjna',
    symbol: 'KRU.WA',
  },
  {
    value: 'KRDMD.IS',
    label: 'KRDMD.IS,  Kardemir Karabük Demir Çelik Sanayi Ve Ticaret A.S.',
    name: 'Kardemir Karabük Demir Çelik Sanayi Ve Ticaret A.S.',
    symbol: 'KRDMD.IS',
  },
  {
    value: 'KRAS.JK',
    label: 'KRAS.JK,  PT Krakatau Steel (Persero) Tbk',
    name: 'PT Krakatau Steel (Persero) Tbk',
    symbol: 'KRAS.JK',
  },
  {
    value: 'KPLT',
    label: 'KPLT,  Katapult Holdings, Inc.',
    name: 'Katapult Holdings, Inc.',
    symbol: 'KPLT',
  },
  {
    value: 'KPG.NZ',
    label: 'KPG.NZ,  Kiwi Property Group Limited',
    name: 'Kiwi Property Group Limited',
    symbol: 'KPG.NZ',
  },
  {
    value: 'KOZAL.IS',
    label: 'KOZAL.IS,  Koza Altin Isletmeleri A.S.',
    name: 'Koza Altin Isletmeleri A.S.',
    symbol: 'KOZAL.IS',
  },
  {
    value: 'KOZAA.IS',
    label: 'KOZAA.IS,  Koza Anadolu Metal Madencilik Isletmeleri A.S.',
    name: 'Koza Anadolu Metal Madencilik Isletmeleri A.S.',
    symbol: 'KOZAA.IS',
  },
  {
    value: 'KORDS.IS',
    label: 'KORDS.IS,  Kordsa Teknik Tekstil A.S.',
    name: 'Kordsa Teknik Tekstil A.S.',
    symbol: 'KORDS.IS',
  },
  {
    value: 'KOJAMO.HE',
    label: 'KOJAMO.HE,  Kojamo Oyj',
    name: 'Kojamo Oyj',
    symbol: 'KOJAMO.HE',
  },
  {
    value: 'KOFUBL.MX',
    label: 'KOFUBL.MX,  Coca-Cola FEMSA, S.A.B. de C.V.',
    name: 'Coca-Cola FEMSA, S.A.B. de C.V.',
    symbol: 'KOFUBL.MX',
  },
  {
    value: 'KNT.TO',
    label: 'KNT.TO,  K92 Mining Inc.',
    name: 'K92 Mining Inc.',
    symbol: 'KNT.TO',
  },
  {
    value: 'KNOW.ST',
    label: 'KNOW.ST,  Knowit AB (publ)',
    name: 'Knowit AB (publ)',
    symbol: 'KNOW.ST',
  },
  {
    value: 'KNOS',
    label: 'KNOS,  Kronos Advanced Technologies Inc.',
    name: 'Kronos Advanced Technologies Inc.',
    symbol: 'KNOS',
  },
  {
    value: 'KNEBV.HE',
    label: 'KNEBV.HE,  KONE Oyj',
    name: 'KONE Oyj',
    symbol: 'KNEBV.HE',
  },
  {
    value: 'KMTUY',
    label: 'KMTUY,  Komatsu Ltd.',
    name: 'Komatsu Ltd.',
    symbol: 'KMTUY',
  },
  {
    value: 'KMD.NZ',
    label: 'KMD.NZ,  KMD Brands Limited',
    name: 'KMD Brands Limited',
    symbol: 'KMD.NZ',
  },
  {
    value: 'KMDA.TA',
    label: 'KMDA.TA,  Kamada Ltd.',
    name: 'Kamada Ltd.',
    symbol: 'KMDA.TA',
  },
  {
    value: 'KLBN11.SA',
    label: 'KLBN11.SA,  Klabin S.A.',
    name: 'Klabin S.A.',
    symbol: 'KLBN11.SA',
  },
  {
    value: 'KLBF.JK',
    label: 'KLBF.JK,  PT Kalbe Farma Tbk.',
    name: 'PT Kalbe Farma Tbk.',
    symbol: 'KLBF.JK',
  },
  {
    value: 'KIMBERA.MX',
    label: 'KIMBERA.MX,  Kimberly-Clark de México, S. A. B. de C. V.',
    name: 'Kimberly-Clark de México, S. A. B. de C. V.',
    symbol: 'KIMBERA.MX',
  },
  {
    value: 'KIJA.JK',
    label: 'KIJA.JK,  PT Kawasan Industri Jababeka Tbk',
    name: 'PT Kawasan Industri Jababeka Tbk',
    symbol: 'KIJA.JK',
  },
  {
    value: 'KGN.WA',
    label: 'KGN.WA,  Zespól Elektrocieplowni Wroclawskich KOGENERACJA S.A.',
    name: 'Zespól Elektrocieplowni Wroclawskich KOGENERACJA S.A.',
    symbol: 'KGN.WA',
  },
  {
    value: 'KGH.WA',
    label: 'KGH.WA,  KGHM Polska Miedz S.A.',
    name: 'KGHM Polska Miedz S.A.',
    symbol: 'KGH.WA',
  },
  {
    value: 'KESKOB.HE',
    label: 'KESKOB.HE,  Kesko Oyj',
    name: 'Kesko Oyj',
    symbol: 'KESKOB.HE',
  },
  {
    value: 'KESKOA.HE',
    label: 'KESKOA.HE,  Kesko Oyj',
    name: 'Kesko Oyj',
    symbol: 'KESKOA.HE',
  },
  {
    value: 'KER.WA',
    label: 'KER.WA,  Kernel Holding S.A.',
    name: 'Kernel Holding S.A.',
    symbol: 'KER.WA',
  },
  {
    value: 'KEPL3.SA',
    label: 'KEPL3.SA,  Kepler Weber S.A.',
    name: 'Kepler Weber S.A.',
    symbol: 'KEPL3.SA',
  },
  {
    value: 'KEN.TA',
    label: 'KEN.TA,  Kenon Holdings Ltd.',
    name: 'Kenon Holdings Ltd.',
    symbol: 'KEN.TA',
  },
  {
    value: 'KEMIRA.HE',
    label: 'KEMIRA.HE,  Kemira Oyj',
    name: 'Kemira Oyj',
    symbol: 'KEMIRA.HE',
  },
  {
    value: 'KCR.HE',
    label: 'KCR.HE,  Konecranes Plc',
    name: 'Konecranes Plc',
    symbol: 'KCR.HE',
  },
  {
    value: 'KCHOL.IS',
    label: 'KCHOL.IS,  Koç Holding A.S.',
    name: 'Koç Holding A.S.',
    symbol: 'KCHOL.IS',
  },
  {
    value: 'KCE-R.BK',
    label: 'KCE-R.BK,  KCE Electronics Public Company Limited',
    name: 'KCE Electronics Public Company Limited',
    symbol: 'KCE-R.BK',
  },
  {
    value: 'KBANK-R.BK',
    label: 'KBANK-R.BK,  Kasikornbank Public Company Limited',
    name: 'Kasikornbank Public Company Limited',
    symbol: 'KBANK-R.BK',
  },
  {
    value: 'KARTN.IS',
    label: 'KARTN.IS,  Kartonsan Karton Sanayi ve Ticaret A.S.',
    name: 'Kartonsan Karton Sanayi ve Ticaret A.S.',
    symbol: 'KARTN.IS',
  },
  {
    value: 'KAR.ST',
    label: 'KAR.ST,  Karnov Group AB (publ)',
    name: 'Karnov Group AB (publ)',
    symbol: 'KAR.ST',
  },
  {
    value: 'KARO.ST',
    label: 'KARO.ST,  Karo Pharma AB (publ)',
    name: 'Karo Pharma AB (publ)',
    symbol: 'KARO.ST',
  },
  {
    value: 'KARE',
    label: 'KARE,  Koala Corporation',
    name: 'Koala Corporation',
    symbol: 'KARE',
  },
  {
    value: 'KAP.IL',
    label: 'KAP.IL,  JSC National Atomic Company Kazatomprom',
    name: 'JSC National Atomic Company Kazatomprom',
    symbol: 'KAP.IL',
  },
  {
    value: 'KAN.ST',
    label: 'KAN.ST,  Kancera AB (publ)',
    name: 'Kancera AB (publ)',
    symbol: 'KAN.ST',
  },
  {
    value: 'KAMUX.HE',
    label: 'KAMUX.HE,  Kamux Oyj',
    name: 'Kamux Oyj',
    symbol: 'KAMUX.HE',
  },
  {
    value: 'KAMBI.ST',
    label: 'KAMBI.ST,  Kambi Group plc',
    name: 'Kambi Group plc',
    symbol: 'KAMBI.ST',
  },
  {
    value: 'KAHOT.OL',
    label: 'KAHOT.OL,  Kahoot! ASA',
    name: 'Kahoot! ASA',
    symbol: 'KAHOT.OL',
  },
  {
    value: 'KAHC',
    label: 'KAHC,  KKR Acquisition Holdings I Corp.',
    name: 'KKR Acquisition Holdings I Corp.',
    symbol: 'KAHC',
  },
  {
    value: 'KAEF.JK',
    label: 'KAEF.JK,  PT Kimia Farma (Persero) Tbk',
    name: 'PT Kimia Farma (Persero) Tbk',
    symbol: 'KAEF.JK',
  },
  {
    value: 'K71U.SI',
    label: 'K71U.SI,  Keppel REIT',
    name: 'Keppel REIT',
    symbol: 'K71U.SI',
  },
  {
    value: 'JYSK.CO',
    label: 'JYSK.CO,  Jyske Bank A/S',
    name: 'Jyske Bank A/S',
    symbol: 'JYSK.CO',
  },
  {
    value: 'JYEU.SI',
    label: 'JYEU.SI,  Lendlease Global Commercial REIT',
    name: 'Lendlease Global Commercial REIT',
    symbol: 'JYEU.SI',
  },
  {
    value: 'JWSM',
    label: 'JWSM,  Jaws Mustang Acquisition Corporation',
    name: 'Jaws Mustang Acquisition Corporation',
    symbol: 'JWSM',
  },
  {
    value: 'JUSHF',
    label: 'JUSHF,  Jushi Holdings Inc.',
    name: 'Jushi Holdings Inc.',
    symbol: 'JUSHF',
  },
  {
    value: 'JUMSY',
    label: 'JUMSY,  Jumbo S.A.',
    name: 'Jumbo S.A.',
    symbol: 'JUMSY',
  },
  {
    value: 'JUBLPHARMA.NS',
    label: 'JUBLPHARMA.NS,  Jubilant Pharmova Limited',
    name: 'Jubilant Pharmova Limited',
    symbol: 'JUBLPHARMA.NS',
  },
  {
    value: 'JUBLINGREA.NS',
    label: 'JUBLINGREA.NS,  Jubilant Ingrevia Limited',
    name: 'Jubilant Ingrevia Limited',
    symbol: 'JUBLINGREA.NS',
  },
  {
    value: 'JSW.WA',
    label: 'JSW.WA,  Jastrzebska Spólka Weglowa S.A.',
    name: 'Jastrzebska Spólka Weglowa S.A.',
    symbol: 'JSW.WA',
  },
  {
    value: 'JSMR.JK',
    label: 'JSMR.JK,  PT Jasa Marga (Persero) Tbk',
    name: 'PT Jasa Marga (Persero) Tbk',
    symbol: 'JSMR.JK',
  },
  {
    value: 'JPM-PK',
    label: 'JPM-PK,  JPMorgan Chase & Co.',
    name: 'JPMorgan Chase & Co.',
    symbol: 'JPM-PK',
  },
  {
    value: 'JPFA.JK',
    label: 'JPFA.JK,  PT Japfa Comfeed Indonesia Tbk',
    name: 'PT Japfa Comfeed Indonesia Tbk',
    symbol: 'JPFA.JK',
  },
  {
    value: 'JMT-R.BK',
    label: 'JMT-R.BK,  JMT Network Services Public Company Limited',
    name: 'JMT Network Services Public Company Limited',
    symbol: 'JMT-R.BK',
  },
  {
    value: 'JM.ST',
    label: 'JM.ST,  JM AB (publ)',
    name: 'JM AB (publ)',
    symbol: 'JM.ST',
  },
  {
    value: 'JMART-R.BK',
    label: 'JMART-R.BK,  Jay Mart Public Company Limited',
    name: 'Jay Mart Public Company Limited',
    symbol: 'JMART-R.BK',
  },
  {
    value: 'JHSF3.SA',
    label: 'JHSF3.SA,  JHSF Participações S.A.',
    name: 'JHSF Participações S.A.',
    symbol: 'JHSF3.SA',
  },
  {
    value: 'JET2.L',
    label: 'JET2.L,  Jet2 plc',
    name: 'Jet2 plc',
    symbol: 'JET2.L',
  },
  {
    value: 'JDAN.CO',
    label: 'JDAN.CO,  Jeudan A/S',
    name: 'Jeudan A/S',
    symbol: 'JDAN.CO',
  },
  {
    value: 'JBSS3.SA',
    label: 'JBSS3.SA,  JBS S.A.',
    name: 'JBS S.A.',
    symbol: 'JBSS3.SA',
  },
  {
    value: 'JBI',
    label: 'JBI,  Janus International Group, Inc.',
    name: 'Janus International Group, Inc.',
    symbol: 'JBI',
  },
  {
    value: 'JARLF',
    label: 'JARLF,  Jardine Matheson Holdings Limited',
    name: 'Jardine Matheson Holdings Limited',
    symbol: 'JARLF',
  },
  {
    value: 'JAR.L',
    label: 'JAR.L,  Jardine Matheson Holdings Limited',
    name: 'Jardine Matheson Holdings Limited',
    symbol: 'JAR.L',
  },
  {
    value: 'JALL3.SA',
    label: 'JALL3.SA,  Jalles Machado S/A',
    name: 'Jalles Machado S/A',
    symbol: 'JALL3.SA',
  },
  {
    value: 'J91U.SI',
    label: 'J91U.SI,  ESR-Logos REIT',
    name: 'ESR-Logos REIT',
    symbol: 'J91U.SI',
  },
  {
    value: 'J85.SI',
    label: 'J85.SI,  CDL Hospitality Trusts',
    name: 'CDL Hospitality Trusts',
    symbol: 'J85.SI',
  },
  {
    value: 'J69U.SI',
    label: 'J69U.SI,  Frasers Centrepoint Trust',
    name: 'Frasers Centrepoint Trust',
    symbol: 'J69U.SI',
  },
  {
    value: 'IVSO.ST',
    label: 'IVSO.ST,  Invisio AB (publ)',
    name: 'Invisio AB (publ)',
    symbol: 'IVSO.ST',
  },
  {
    value: 'IVL-R.BK',
    label: 'IVL-R.BK,  Indorama Ventures Public Company Limited',
    name: 'Indorama Ventures Public Company Limited',
    symbol: 'IVL-R.BK',
  },
  {
    value: 'IVACC.ST',
    label: 'IVACC.ST,  Intervacc AB (publ)',
    name: 'Intervacc AB (publ)',
    symbol: 'IVACC.ST',
  },
  {
    value: 'ITX.MC',
    label: 'ITX.MC,  Industria de Diseño Textil, S.A.',
    name: 'Industria de Diseño Textil, S.A.',
    symbol: 'ITX.MC',
  },
  {
    value: 'ITUB4.SA',
    label: 'ITUB4.SA,  Itaú Unibanco Holding S.A.',
    name: 'Itaú Unibanco Holding S.A.',
    symbol: 'ITUB4.SA',
  },
  {
    value: 'ITSA4.SA',
    label: 'ITSA4.SA,  Itaúsa S.A.',
    name: 'Itaúsa S.A.',
    symbol: 'ITSA4.SA',
  },
  {
    value: 'ITSA3.SA',
    label: 'ITSA3.SA,  Itaúsa S.A.',
    name: 'Itaúsa S.A.',
    symbol: 'ITSA3.SA',
  },
  {
    value: 'ITMG.JK',
    label: 'ITMG.JK,  PT Indo Tambangraya Megah Tbk',
    name: 'PT Indo Tambangraya Megah Tbk',
    symbol: 'ITMG.JK',
  },
  {
    value: 'ITHUF',
    label: 'ITHUF,  iAnthus Capital Holdings, Inc.',
    name: 'iAnthus Capital Holdings, Inc.',
    symbol: 'ITHUF',
  },
  {
    value: 'ITAUCORP.SN',
    label: 'ITAUCORP.SN,  Itaú Corpbanca',
    name: 'Itaú Corpbanca',
    symbol: 'ITAUCORP.SN',
  },
  {
    value: 'ISUN',
    label: 'ISUN,  iSun, Inc.',
    name: 'iSun, Inc.',
    symbol: 'ISUN',
  },
  {
    value: 'ISS.CO',
    label: 'ISS.CO,  ISS A/S',
    name: 'ISS A/S',
    symbol: 'ISS.CO',
  },
  {
    value: 'ISRS.TA',
    label: 'ISRS.TA,  Isras Investment Company Ltd',
    name: 'Isras Investment Company Ltd',
    symbol: 'ISRS.TA',
  },
  {
    value: 'ISP.MI',
    label: 'ISP.MI,  Intesa Sanpaolo S.p.A.',
    name: 'Intesa Sanpaolo S.p.A.',
    symbol: 'ISP.MI',
  },
  {
    value: 'ISMEN.IS',
    label: 'ISMEN.IS,  Is Yatirim Menkul Degerler Anonim Sirketi',
    name: 'Is Yatirim Menkul Degerler Anonim Sirketi',
    symbol: 'ISMEN.IS',
  },
  {
    value: 'ISFIN.IS',
    label: 'ISFIN.IS,  Is Finansal Kiralama Anonim Sirketi',
    name: 'Is Finansal Kiralama Anonim Sirketi',
    symbol: 'ISFIN.IS',
  },
  {
    value: 'ISCTR.IS',
    label: 'ISCTR.IS,  Türkiye Is Bankasi A.S.',
    name: 'Türkiye Is Bankasi A.S.',
    symbol: 'ISCTR.IS',
  },
  {
    value: 'ISCN.TA',
    label: 'ISCN.TA,  Israel Canada (T.R) Ltd',
    name: 'Israel Canada (T.R) Ltd',
    symbol: 'ISCN.TA',
  },
  {
    value: 'ISCD.TA',
    label: 'ISCD.TA,  Isracard Ltd.',
    name: 'Isracard Ltd.',
    symbol: 'ISCD.TA',
  },
  {
    value: 'ISAT.JK',
    label: 'ISAT.JK,  PT Indosat Ooredoo Hutchison Tbk',
    name: 'PT Indosat Ooredoo Hutchison Tbk',
    symbol: 'ISAT.JK',
  },
  {
    value: 'ISAA',
    label: 'ISAA,  Iron Spark I Inc.',
    name: 'Iron Spark I Inc.',
    symbol: 'ISAA',
  },
  {
    value: 'IRBR3.SA',
    label: 'IRBR3.SA,  IRB-Brasil Resseguros S.A.',
    name: 'IRB-Brasil Resseguros S.A.',
    symbol: 'IRBR3.SA',
  },
  {
    value: 'IPTV.JK',
    label: 'IPTV.JK,  PT MNC Vision Networks Tbk',
    name: 'PT MNC Vision Networks Tbk',
    symbol: 'IPTV.JK',
  },
  {
    value: 'IPSC',
    label: 'IPSC,  Century Therapeutics, Inc.',
    name: 'Century Therapeutics, Inc.',
    symbol: 'IPSC',
  },
  {
    value: 'IPEKE.IS',
    label: 'IPEKE.IS,  Ipek Dogal Enerji Kaynaklari Arastirma ve Üretim A.S.',
    name: 'Ipek Dogal Enerji Kaynaklari Arastirma ve Üretim A.S.',
    symbol: 'IPEKE.IS',
  },
  {
    value: 'IPCO.ST',
    label: 'IPCO.ST,  International Petroleum Corporation',
    name: 'International Petroleum Corporation',
    symbol: 'IPCO.ST',
  },
  {
    value: 'INWI.ST',
    label: 'INWI.ST,  Inwido AB (publ)',
    name: 'Inwido AB (publ)',
    symbol: 'INWI.ST',
  },
  {
    value: 'INVO',
    label: 'INVO,  INVO Bioscience, Inc.',
    name: 'INVO Bioscience, Inc.',
    symbol: 'INVO',
  },
  {
    value: 'INTUCH-R.BK',
    label: 'INTUCH-R.BK,  Intouch Holdings Public Company Limited',
    name: 'Intouch Holdings Public Company Limited',
    symbol: 'INTUCH-R.BK',
  },
  {
    value: 'INTRUM.ST',
    label: 'INTRUM.ST,  Intrum AB (publ)',
    name: 'Intrum AB (publ)',
    symbol: 'INTRUM.ST',
  },
  {
    value: 'INTP.JK',
    label: 'INTP.JK,  PT Indocement Tunggal Prakarsa Tbk',
    name: 'PT Indocement Tunggal Prakarsa Tbk',
    symbol: 'INTP.JK',
  },
  {
    value: 'INSTAL.ST',
    label: 'INSTAL.ST,  Instalco AB (publ)',
    name: 'Instalco AB (publ)',
    symbol: 'INSTAL.ST',
  },
  {
    value: 'INRM.TA',
    label: 'INRM.TA,  Inrom Construction Industries Ltd',
    name: 'Inrom Construction Industries Ltd',
    symbol: 'INRM.TA',
  },
  {
    value: 'INPST.AS',
    label: 'INPST.AS,  InPost S.A.',
    name: 'InPost S.A.',
    symbol: 'INPST.AS',
  },
  {
    value: 'INKP.JK',
    label: 'INKP.JK,  PT Indah Kiat Pulp & Paper Tbk',
    name: 'PT Indah Kiat Pulp & Paper Tbk',
    symbol: 'INKP.JK',
  },
  {
    value: 'ING.WA',
    label: 'ING.WA,  ING Bank Slaski S.A.',
    name: 'ING Bank Slaski S.A.',
    symbol: 'ING.WA',
  },
  {
    value: 'INFO.IS',
    label: 'INFO.IS,  Info Yatirim Menkul Degerler A.S.',
    name: 'Info Yatirim Menkul Degerler A.S.',
    symbol: 'INFO.IS',
  },
  {
    value: 'INDY.JK',
    label: 'INDY.JK,  PT. Indika Energy Tbk',
    name: 'PT. Indika Energy Tbk',
    symbol: 'INDY.JK',
  },
  {
    value: 'INDT.ST',
    label: 'INDT.ST,  Indutrade AB (publ)',
    name: 'Indutrade AB (publ)',
    symbol: 'INDT.ST',
  },
  {
    value: 'INDT',
    label: 'INDT,  INDUS Realty Trust, Inc.',
    name: 'INDUS Realty Trust, Inc.',
    symbol: 'INDT',
  },
  {
    value: 'INDP',
    label: 'INDP,  Indaptus Therapeutics, Inc.',
    name: 'Indaptus Therapeutics, Inc.',
    symbol: 'INDP',
  },
  {
    value: 'INDI',
    label: 'INDI,  indie Semiconductor, Inc.',
    name: 'indie Semiconductor, Inc.',
    symbol: 'INDI',
  },
  {
    value: 'INDF.JK',
    label: 'INDF.JK,  PT Indofood Sukses Makmur Tbk',
    name: 'PT Indofood Sukses Makmur Tbk',
    symbol: 'INDF.JK',
  },
  {
    value: 'INCR',
    label: 'INCR,  InterCure Ltd.',
    name: 'InterCure Ltd.',
    symbol: 'INCR',
  },
  {
    value: 'IMPC.ST',
    label: 'IMPC.ST,  Impact Coatings AB (publ)',
    name: 'Impact Coatings AB (publ)',
    symbol: 'IMPC.ST',
  },
  {
    value: 'IMMU.ST',
    label: 'IMMU.ST,  Mendus AB (publ)',
    name: 'Mendus AB (publ)',
    symbol: 'IMMU.ST',
  },
  {
    value: 'IMMNOV.ST',
    label: 'IMMNOV.ST,  Immunovia AB (publ)',
    name: 'Immunovia AB (publ)',
    symbol: 'IMMNOV.ST',
  },
  {
    value: 'IMCR',
    label: 'IMCR,  Immunocore Holdings plc',
    name: 'Immunocore Holdings plc',
    symbol: 'IMCR',
  },
  {
    value: 'IMBBY',
    label: 'IMBBY,  Imperial Brands PLC',
    name: 'Imperial Brands PLC',
    symbol: 'IMBBY',
  },
  {
    value: 'IMAS.JK',
    label: 'IMAS.JK,  PT Indomobil Sukses Internasional Tbk',
    name: 'PT Indomobil Sukses Internasional Tbk',
    symbol: 'IMAS.JK',
  },
  {
    value: 'ILDC.TA',
    label: 'ILDC.TA,  The Israel Land Development Company Ltd.',
    name: 'The Israel Land Development Company Ltd.',
    symbol: 'ILDC.TA',
  },
  {
    value: 'ILC.SN',
    label: 'ILC.SN,  Inversiones La Construcción S.A.',
    name: 'Inversiones La Construcción S.A.',
    symbol: 'ILC.SN',
  },
  {
    value: 'ILCO.TA',
    label: 'ILCO.TA,  Israel Corporation Ltd',
    name: 'Israel Corporation Ltd',
    symbol: 'ILCO.TA',
  },
  {
    value: 'IG.MI',
    label: 'IG.MI,  Italgas S.p.A.',
    name: 'Italgas S.p.A.',
    symbol: 'IG.MI',
  },
  {
    value: 'IFT.NZ',
    label: 'IFT.NZ,  Infratil Limited',
    name: 'Infratil Limited',
    symbol: 'IFT.NZ',
  },
  {
    value: 'IFNNY',
    label: 'IFNNY,  Infineon Technologies AG',
    name: 'Infineon Technologies AG',
    symbol: 'IFNNY',
  },
  {
    value: 'IF.MI',
    label: 'IF.MI,  Banca IFIS S.p.A.',
    name: 'Banca IFIS S.p.A.',
    symbol: 'IF.MI',
  },
  {
    value: 'IDR.MC',
    label: 'IDR.MC,  Indra Sistemas, S.A.',
    name: 'Indra Sistemas, S.A.',
    symbol: 'IDR.MC',
  },
  {
    value: 'IDIN.TA',
    label: 'IDIN.TA,  I.D.I. Insurance Company Ltd.',
    name: 'I.D.I. Insurance Company Ltd.',
    symbol: 'IDIN.TA',
  },
  {
    value: 'IDEA',
    label: 'IDEA,  Invent Ventures, Inc.',
    name: 'Invent Ventures, Inc.',
    symbol: 'IDEA',
  },
  {
    value: 'ID',
    label: 'ID,  PARTS iD, Inc.',
    name: 'PARTS iD, Inc.',
    symbol: 'ID',
  },
  {
    value: 'ICL.TA',
    label: 'ICL.TA,  ICL Group Ltd',
    name: 'ICL Group Ltd',
    symbol: 'ICL.TA',
  },
  {
    value: 'ICHI-R.BK',
    label: 'ICHI-R.BK,  Ichitan Group Public Company Limited',
    name: 'Ichitan Group Public Company Limited',
    symbol: 'ICHI-R.BK',
  },
  {
    value: 'ICHB.MX',
    label: 'ICHB.MX,  Industrias CH, S. A. B. de C. V.',
    name: 'Industrias CH, S. A. B. de C. V.',
    symbol: 'ICHB.MX',
  },
  {
    value: 'ICBP.JK',
    label: 'ICBP.JK,  PT Indofood CBP Sukses Makmur Tbk',
    name: 'PT Indofood CBP Sukses Makmur Tbk',
    symbol: 'ICBP.JK',
  },
  {
    value: 'IBE.MC',
    label: 'IBE.MC,  Iberdrola, S.A.',
    name: 'Iberdrola, S.A.',
    symbol: 'IBE.MC',
  },
  {
    value: 'IBDRY',
    label: 'IBDRY,  Iberdrola, S.A.',
    name: 'Iberdrola, S.A.',
    symbol: 'IBDRY',
  },
  {
    value: 'IAU.TO',
    label: 'IAU.TO,  i-80 Gold Corp.',
    name: 'i-80 Gold Corp.',
    symbol: 'IAU.TO',
  },
  {
    value: 'IAS',
    label: 'IAS,  Integral Ad Science Holding Corp.',
    name: 'Integral Ad Science Holding Corp.',
    symbol: 'IAS',
  },
  {
    value: 'IAM.SN',
    label: 'IAM.SN,  Inversiones Aguas Metropolitanas S.A.',
    name: 'Inversiones Aguas Metropolitanas S.A.',
    symbol: 'IAM.SN',
  },
  {
    value: 'HYZN',
    label: 'HYZN,  Hyzon Motors Inc.',
    name: 'Hyzon Motors Inc.',
    symbol: 'HYZN',
  },
  {
    value: 'HYPE3.SA',
    label: 'HYPE3.SA,  Hypera S.A.',
    name: 'Hypera S.A.',
    symbol: 'HYPE3.SA',
  },
  {
    value: 'HYDR',
    label: 'HYDR,  Global X Hydrogen ETF',
    name: 'Global X Hydrogen ETF',
    symbol: 'HYDR',
  },
  {
    value: 'HUNT.OL',
    label: 'HUNT.OL,  Hunter Group ASA',
    name: 'Hunter Group ASA',
    symbol: 'HUNT.OL',
  },
  {
    value: 'HUM.ST',
    label: 'HUM.ST,  Humana AB (publ)',
    name: 'Humana AB (publ)',
    symbol: 'HUM.ST',
  },
  {
    value: 'HUH1V.HE',
    label: 'HUH1V.HE,  Huhtamäki Oyj',
    name: 'Huhtamäki Oyj',
    symbol: 'HUH1V.HE',
  },
  {
    value: 'HTRO.ST',
    label: 'HTRO.ST,  Hexatronic Group AB (publ)',
    name: 'Hexatronic Group AB (publ)',
    symbol: 'HTRO.ST',
  },
  {
    value: 'HTOO',
    label: 'HTOO,  Fusion Fuel Green PLC',
    name: 'Fusion Fuel Green PLC',
    symbol: 'HTOO',
  },
  {
    value: 'HSBK.IL',
    label: 'HSBK.IL,  JSC Halyk Bank',
    name: 'JSC Halyk Bank',
    symbol: 'HSBK.IL',
  },
  {
    value: 'HRUM.JK',
    label: 'HRUM.JK,  PT Harum Energy Tbk',
    name: 'PT Harum Energy Tbk',
    symbol: 'HRUM.JK',
  },
  {
    value: 'HPK',
    label: 'HPK,  HighPeak Energy, Inc.',
    name: 'HighPeak Energy, Inc.',
    symbol: 'HPK',
  },
  {
    value: 'HOME.MC',
    label: 'HOME.MC,  Neinor Homes, S.A.',
    name: 'Neinor Homes, S.A.',
    symbol: 'HOME.MC',
  },
  {
    value: 'HOME.L',
    label: 'HOME.L,  Home Reit Plc',
    name: 'Home Reit Plc',
    symbol: 'HOME.L',
  },
  {
    value: 'HOLN.SW',
    label: 'HOLN.SW,  Holcim Ltd',
    name: 'Holcim Ltd',
    symbol: 'HOLN.SW',
  },
  {
    value: 'HOFI.ST',
    label: 'HOFI.ST,  Hoist Finance AB (publ)',
    name: 'Hoist Finance AB (publ)',
    symbol: 'HOFI.ST',
  },
  {
    value: 'HNSA.ST',
    label: 'HNSA.ST,  Hansa Biopharma AB (publ)',
    name: 'Hansa Biopharma AB (publ)',
    symbol: 'HNSA.ST',
  },
  {
    value: 'HMS.ST',
    label: 'HMS.ST,  HMS Networks AB (publ)',
    name: 'HMS Networks AB (publ)',
    symbol: 'HMS.ST',
  },
  {
    value: 'HMSP.JK',
    label: 'HMSP.JK,  PT Hanjaya Mandala Sampoerna Tbk',
    name: 'PT Hanjaya Mandala Sampoerna Tbk',
    symbol: 'HMSP.JK',
  },
  {
    value: 'HMPT',
    label: 'HMPT,  Home Point Capital Inc.',
    name: 'Home Point Capital Inc.',
    symbol: 'HMPT',
  },
  {
    value: 'HMPRO-R.BK',
    label: 'HMPRO-R.BK,  Home Product Center Public Company Limited',
    name: 'Home Product Center Public Company Limited',
    symbol: 'HMPRO-R.BK',
  },
  {
    value: 'HMN.SI',
    label: 'HMN.SI,  CapitaLand Ascott Trust',
    name: 'CapitaLand Ascott Trust',
    symbol: 'HMN.SI',
  },
  {
    value: 'HLMN',
    label: 'HLMN,  Hillman Solutions Corp.',
    name: 'Hillman Solutions Corp.',
    symbol: 'HLMN',
  },
  {
    value: 'HLLY',
    label: 'HLLY,  Holley Inc.',
    name: 'Holley Inc.',
    symbol: 'HLLY',
  },
  {
    value: 'HLG.NZ',
    label: 'HLG.NZ,  Hallenstein Glasson Holdings Limited',
    name: 'Hallenstein Glasson Holdings Limited',
    symbol: 'HLG.NZ',
  },
  {
    value: 'HLAN.TA',
    label: 'HLAN.TA,  Hilan Ltd.',
    name: 'Hilan Ltd.',
    symbol: 'HLAN.TA',
  },
  {
    value: 'HLAN',
    label: 'HLAN,  Heartland BancCorp',
    name: 'Heartland BancCorp',
    symbol: 'HLAN',
  },
  {
    value: 'HKSAV.HE',
    label: 'HKSAV.HE,  HKScan Oyj',
    name: 'HKScan Oyj',
    symbol: 'HKSAV.HE',
  },
  {
    value: 'HIVE',
    label: 'HIVE,  HIVE Blockchain Technologies Ltd.',
    name: 'HIVE Blockchain Technologies Ltd.',
    symbol: 'HIVE',
  },
  {
    value: 'HITI',
    label: 'HITI,  High Tide Inc.',
    name: 'High Tide Inc.',
    symbol: 'HITI',
  },
  {
    value: 'HGH.NZ',
    label: 'HGH.NZ,  Heartland Group Holdings Limited',
    name: 'Heartland Group Holdings Limited',
    symbol: 'HGH.NZ',
  },
  {
    value: 'HGG.TA',
    label: 'HGG.TA,  Hagag Group Real Estate Entrepreneurship Ltd',
    name: 'Hagag Group Real Estate Entrepreneurship Ltd',
    symbol: 'HGG.TA',
  },
  {
    value: 'HF.SN',
    label: 'HF.SN,  Hortifrut S.A.',
    name: 'Hortifrut S.A.',
    symbol: 'HF.SN',
  },
  {
    value: 'HERDEZ.MX',
    label: 'HERDEZ.MX,  Grupo Herdez, S.A.B. de C.V.',
    name: 'Grupo Herdez, S.A.B. de C.V.',
    symbol: 'HERDEZ.MX',
  },
  {
    value: 'HEM.ST',
    label: 'HEM.ST,  Hemnet Group AB (publ)',
    name: 'Hemnet Group AB (publ)',
    symbol: 'HEM.ST',
  },
  {
    value: 'HCITY.MX',
    label: 'HCITY.MX,  Hoteles City Express, S.A.B. de C.V.',
    name: 'Hoteles City Express, S.A.B. de C.V.',
    symbol: 'HCITY.MX',
  },
  {
    value: 'HCAR',
    label: 'HCAR,  Healthcare Services Acquisition Corporation',
    name: 'Healthcare Services Acquisition Corporation',
    symbol: 'HCAR',
  },
  {
    value: 'HBSA3.SA',
    label: 'HBSA3.SA,  Hidrovias do Brasil S.A.',
    name: 'Hidrovias do Brasil S.A.',
    symbol: 'HBSA3.SA',
  },
  {
    value: 'HBR.L',
    label: 'HBR.L,  Harbour Energy plc',
    name: 'Harbour Energy plc',
    symbol: 'HBR.L',
  },
  {
    value: 'HBOR3.SA',
    label: 'HBOR3.SA,  Helbor Empreendimentos S.A.',
    name: 'Helbor Empreendimentos S.A.',
    symbol: 'HBOR3.SA',
  },
  {
    value: 'HBC.OL',
    label: 'HBC.OL,  Hofseth BioCare ASA',
    name: 'Hofseth BioCare ASA',
    symbol: 'HBC.OL',
  },
  {
    value: 'HARVIA.HE',
    label: 'HARVIA.HE,  Harvia Oyj',
    name: 'Harvia Oyj',
    symbol: 'HARVIA.HE',
  },
  {
    value: 'HART.CO',
    label: 'HART.CO,  Brødrene Hartmann A/S',
    name: 'Brødrene Hartmann A/S',
    symbol: 'HART.CO',
  },
  {
    value: 'HARL.TA',
    label: 'HARL.TA,  Harel Insurance Investments & Financial Services Ltd',
    name: 'Harel Insurance Investments & Financial Services Ltd',
    symbol: 'HARL.TA',
  },
  {
    value: 'HARL',
    label: 'HARL,  Harleysville Financial Corporation',
    name: 'Harleysville Financial Corporation',
    symbol: 'HARL',
  },
  {
    value: 'HAPV3.SA',
    label: 'HAPV3.SA,  Hapvida Participações e Investimentos S.A.',
    name: 'Hapvida Participações e Investimentos S.A.',
    symbol: 'HAPV3.SA',
  },
  {
    value: 'HAPPSTMNDS.NS',
    label: 'HAPPSTMNDS.NS,  Happiest Minds Technologies Limited',
    name: 'Happiest Minds Technologies Limited',
    symbol: 'HAPPSTMNDS.NS',
  },
  {
    value: 'HANA-R.BK',
    label: 'HANA-R.BK,  Hana Microelectronics Public Company Limited',
    name: 'Hana Microelectronics Public Company Limited',
    symbol: 'HANA-R.BK',
  },
  {
    value: 'HALKB.IS',
    label: 'HALKB.IS,  Türkiye Halk Bankasi A.S.',
    name: 'Türkiye Halk Bankasi A.S.',
    symbol: 'HALKB.IS',
  },
  {
    value: 'HAFNI.OL',
    label: 'HAFNI.OL,  Hafnia Limited',
    name: 'Hafnia Limited',
    symbol: 'HAFNI.OL',
  },
  {
    value: 'HAAC',
    label: 'HAAC,  Health Assurance Acquisition Corp.',
    name: 'Health Assurance Acquisition Corp.',
    symbol: 'HAAC',
  },
  {
    value: 'H02.SI',
    label: 'H02.SI,  Haw Par Corporation Limited',
    name: 'Haw Par Corporation Limited',
    symbol: 'H02.SI',
  },
  {
    value: 'GXO',
    label: 'GXO,  GXO Logistics, Inc.',
    name: 'GXO Logistics, Inc.',
    symbol: 'GXO',
  },
  {
    value: 'GVYM.TA',
    label: 'GVYM.TA,  Gav-Yam Lands Corp. Ltd',
    name: 'Gav-Yam Lands Corp. Ltd',
    symbol: 'GVYM.TA',
  },
  {
    value: 'GVS.MI',
    label: 'GVS.MI,  GVS S.p.A.',
    name: 'GVS S.p.A.',
    symbol: 'GVS.MI',
  },
  {
    value: 'GUNKUL-R.BK',
    label: 'GUNKUL-R.BK,  Gunkul Engineering Public Company Limited',
    name: 'Gunkul Engineering Public Company Limited',
    symbol: 'GUNKUL-R.BK',
  },
  {
    value: 'GULF-R.BK',
    label: 'GULF-R.BK,  Gulf Energy Development Public Company Limited',
    name: 'Gulf Energy Development Public Company Limited',
    symbol: 'GULF-R.BK',
  },
  {
    value: 'GUBRF.IS',
    label: 'GUBRF.IS,  Gübre Fabrikalari Türk Anonim Sirketi',
    name: 'Gübre Fabrikalari Türk Anonim Sirketi',
    symbol: 'GUBRF.IS',
  },
  {
    value: 'GUAR3.SA',
    label: 'GUAR3.SA,  Guararapes Confecções S.A.',
    name: 'Guararapes Confecções S.A.',
    symbol: 'GUAR3.SA',
  },
  {
    value: 'GTN.WA',
    label: 'GTN.WA,  Getin Holding S.A.',
    name: 'Getin Holding S.A.',
    symbol: 'GTN.WA',
  },
  {
    value: 'GTC.WA',
    label: 'GTC.WA,  Globe Trade Centre S.A.',
    name: 'Globe Trade Centre S.A.',
    symbol: 'GTC.WA',
  },
  {
    value: 'GRUMAB.MX',
    label: 'GRUMAB.MX,  Gruma, S.A.B. de C.V.',
    name: 'Gruma, S.A.B. de C.V.',
    symbol: 'GRUMAB.MX',
  },
  {
    value: 'GROY',
    label: 'GROY,  Gold Royalty Corp.',
    name: 'Gold Royalty Corp.',
    symbol: 'GROY',
  },
  {
    value: 'GRN.TO',
    label: 'GRN.TO,  Greenlane Renewables Inc.',
    name: 'Greenlane Renewables Inc.',
    symbol: 'GRN.TO',
  },
  {
    value: 'GRNG.ST',
    label: 'GRNG.ST,  Gränges AB (publ)',
    name: 'Gränges AB (publ)',
    symbol: 'GRNG.ST',
  },
  {
    value: 'GRND3.SA',
    label: 'GRND3.SA,  Grendene S.A.',
    name: 'Grendene S.A.',
    symbol: 'GRND3.SA',
  },
  {
    value: 'GRAMF',
    label: 'GRAMF,  TPCO Holding Corp.',
    name: 'TPCO Holding Corp.',
    symbol: 'GRAMF',
  },
  {
    value: 'GPW.WA',
    label: 'GPW.WA,  Gielda Papierów Wartosciowych w Warszawie S.A.',
    name: 'Gielda Papierów Wartosciowych w Warszawie S.A.',
    symbol: 'GPW.WA',
  },
  {
    value: 'GPSC-R.BK',
    label: 'GPSC-R.BK,  Global Power Synergy Public Company Limited',
    name: 'Global Power Synergy Public Company Limited',
    symbol: 'GPSC-R.BK',
  },
  {
    value: 'GPOR',
    label: 'GPOR,  Gulfport Energy Corporation',
    name: 'Gulfport Energy Corporation',
    symbol: 'GPOR',
  },
  {
    value: 'GP',
    label: 'GP,  GreenPower Motor Company Inc.',
    name: 'GreenPower Motor Company Inc.',
    symbol: 'GP',
  },
  {
    value: 'GOODY.IS',
    label: 'GOODY.IS,  Goodyear Lastikleri T.A.S.',
    name: 'Goodyear Lastikleri T.A.S.',
    symbol: 'GOODY.IS',
  },
  {
    value: 'GOLL4.SA',
    label: 'GOLL4.SA,  Gol Linhas Aéreas Inteligentes S.A.',
    name: 'Gol Linhas Aéreas Inteligentes S.A.',
    symbol: 'GOLL4.SA',
  },
  {
    value: 'GOFPY',
    label: 'GOFPY,  Organization of Football Prognostics S.A.',
    name: 'Organization of Football Prognostics S.A.',
    symbol: 'GOFPY',
  },
  {
    value: 'GOAU4.SA',
    label: 'GOAU4.SA,  Metalurgica Gerdau S.A.',
    name: 'Metalurgica Gerdau S.A.',
    symbol: 'GOAU4.SA',
  },
  {
    value: 'GNE.NZ',
    label: 'GNE.NZ,  Genesis Energy Limited',
    name: 'Genesis Energy Limited',
    symbol: 'GNE.NZ',
  },
  {
    value: 'GN.CO',
    label: 'GN.CO,  GN Store Nord A/S',
    name: 'GN Store Nord A/S',
    symbol: 'GN.CO',
  },
  {
    value: 'GMT.NZ',
    label: 'GMT.NZ,  Goodman Property Trust',
    name: 'Goodman Property Trust',
    symbol: 'GMT.NZ',
  },
  {
    value: 'GMEXICOB.MX',
    label: 'GMEXICOB.MX,  Grupo México, S.A.B. de C.V.',
    name: 'Grupo México, S.A.B. de C.V.',
    symbol: 'GMEXICOB.MX',
  },
  {
    value: 'GMAT3.SA',
    label: 'GMAT3.SA,  Grupo Mateus S.A.',
    name: 'Grupo Mateus S.A.',
    symbol: 'GMAT3.SA',
  },
  {
    value: 'GMAB.CO',
    label: 'GMAB.CO,  Genmab A/S',
    name: 'Genmab A/S',
    symbol: 'GMAB.CO',
  },
  {
    value: 'GLOBAL-R.BK',
    label: 'GLOBAL-R.BK,  Siam Global House Public Company Limited',
    name: 'Siam Global House Public Company Limited',
    symbol: 'GLOBAL-R.BK',
  },
  {
    value: 'GLDG',
    label: 'GLDG,  GoldMining Inc.',
    name: 'GoldMining Inc.',
    symbol: 'GLDG',
  },
  {
    value: 'GLASF',
    label: 'GLASF,  Glass House Brands Inc.',
    name: 'Glass House Brands Inc.',
    symbol: 'GLASF',
  },
  {
    value: 'GJTL.JK',
    label: 'GJTL.JK,  PT. Gajah Tunggal Tbk',
    name: 'PT. Gajah Tunggal Tbk',
    symbol: 'GJTL.JK',
  },
  {
    value: 'GISSAA.MX',
    label: 'GISSAA.MX,  Grupo Industrial Saltillo, S.A.B. de C.V.',
    name: 'Grupo Industrial Saltillo, S.A.B. de C.V.',
    symbol: 'GISSAA.MX',
  },
  {
    value: 'GILT.TA',
    label: 'GILT.TA,  Gilat Satellite Networks Ltd.',
    name: 'Gilat Satellite Networks Ltd.',
    symbol: 'GILT.TA',
  },
  {
    value: 'GICSAB.MX',
    label: 'GICSAB.MX,  Grupo Gicsa S.A.B. de C.V.',
    name: 'Grupo Gicsa S.A.B. de C.V.',
    symbol: 'GICSAB.MX',
  },
  {
    value: 'GGRM.JK',
    label: 'GGRM.JK,  PT Gudang Garam Tbk',
    name: 'PT Gudang Garam Tbk',
    symbol: 'GGRM.JK',
  },
  {
    value: 'GGBR4.SA',
    label: 'GGBR4.SA,  Gerdau S.A.',
    name: 'Gerdau S.A.',
    symbol: 'GGBR4.SA',
  },
  {
    value: 'GFX',
    label: 'GFX,  Golden Falcon Acquisition Corp.',
    name: 'Golden Falcon Acquisition Corp.',
    symbol: 'GFX',
  },
  {
    value: 'GFSA3.SA',
    label: 'GFSA3.SA,  Gafisa S.A.',
    name: 'Gafisa S.A.',
    symbol: 'GFSA3.SA',
  },
  {
    value: 'GFPT-R.BK',
    label: 'GFPT-R.BK,  GFPT Public Company Limited',
    name: 'GFPT Public Company Limited',
    symbol: 'GFPT-R.BK',
  },
  {
    value: 'GFNORTEO.MX',
    label: 'GFNORTEO.MX,  Grupo Financiero Banorte, S.A.B. de C.V.',
    name: 'Grupo Financiero Banorte, S.A.B. de C.V.',
    symbol: 'GFNORTEO.MX',
  },
  {
    value: 'GFINBURO.MX',
    label: 'GFINBURO.MX,  Grupo Financiero Inbursa, S.A.B. de C.V.',
    name: 'Grupo Financiero Inbursa, S.A.B. de C.V.',
    symbol: 'GFINBURO.MX',
  },
  {
    value: 'GEST.MC',
    label: 'GEST.MC,  Gestamp Automoción, S.A.',
    name: 'Gestamp Automoción, S.A.',
    symbol: 'GEST.MC',
  },
  {
    value: 'GEO.MI',
    label: 'GEO.MI,  Geox S.p.A.',
    name: 'Geox S.p.A.',
    symbol: 'GEO.MI',
  },
  {
    value: 'GENTERA.MX',
    label: 'GENTERA.MX,  Gentera, S.A.B. de C.V.',
    name: 'Gentera, S.A.B. de C.V.',
    symbol: 'GENTERA.MX',
  },
  {
    value: 'GENO.ST',
    label: 'GENO.ST,  Genovis AB (publ.)',
    name: 'Genovis AB (publ.)',
    symbol: 'GENO.ST',
  },
  {
    value: 'GEN.L',
    label: 'GEN.L,  Genuit Group plc',
    name: 'Genuit Group plc',
    symbol: 'GEN.L',
  },
  {
    value: 'GENI',
    label: 'GENI,  Genius Sports Limited',
    name: 'Genius Sports Limited',
    symbol: 'GENI',
  },
  {
    value: 'GEG',
    label: 'GEG,  Great Elm Group, Inc.',
    name: 'Great Elm Group, Inc.',
    symbol: 'GEG',
  },
  {
    value: 'GDNSF',
    label: 'GDNSF,  Goodness Growth Holdings, Inc.',
    name: 'Goodness Growth Holdings, Inc.',
    symbol: 'GDNSF',
  },
  {
    value: 'GCO.MC',
    label: 'GCO.MC,  Grupo Catalana Occidente, S.A.',
    name: 'Grupo Catalana Occidente, S.A.',
    symbol: 'GCO.MC',
  },
  {
    value: 'GCC.MX',
    label: 'GCC.MX,  GCC, S.A.B. de C.V.',
    name: 'GCC, S.A.B. de C.V.',
    symbol: 'GCC.MX',
  },
  {
    value: 'GCARSOA1.MX',
    label: 'GCARSOA1.MX,  Grupo Carso, S.A.B. de C.V.',
    name: 'Grupo Carso, S.A.B. de C.V.',
    symbol: 'GCARSOA1.MX',
  },
  {
    value: 'GB',
    label: 'GB,  Global Blue Group Holding AG',
    name: 'Global Blue Group Holding AG',
    symbol: 'GB',
  },
  {
    value: 'GARO.ST',
    label: 'GARO.ST,  Garo Aktiebolag (publ)',
    name: 'Garo Aktiebolag (publ)',
    symbol: 'GARO.ST',
  },
  {
    value: 'GARAN.IS',
    label: 'GARAN.IS,  Turkiye Garanti Bankasi A.S.',
    name: 'Turkiye Garanti Bankasi A.S.',
    symbol: 'GARAN.IS',
  },
  {
    value: 'GAPB.MX',
    label: 'GAPB.MX,  Grupo Aeroportuario del Pacífico, S.A.B. de C.V.',
    name: 'Grupo Aeroportuario del Pacífico, S.A.B. de C.V.',
    symbol: 'GAPB.MX',
  },
  {
    value: 'G92.SI',
    label: 'G92.SI,  China Aviation Oil (Singapore) Corporation Ltd',
    name: 'China Aviation Oil (Singapore) Corporation Ltd',
    symbol: 'G92.SI',
  },
  {
    value: 'G5EN.ST',
    label: 'G5EN.ST,  G5 Entertainment AB (publ)',
    name: 'G5 Entertainment AB (publ)',
    symbol: 'G5EN.ST',
  },
  {
    value: 'G13.SI',
    label: 'G13.SI,  Genting Singapore Limited',
    name: 'Genting Singapore Limited',
    symbol: 'G13.SI',
  },
  {
    value: 'FUNO11.MX',
    label: 'FUNO11.MX,  Fibra UNO',
    name: 'Fibra UNO',
    symbol: 'FUNO11.MX',
  },
  {
    value: 'FULTP',
    label: 'FULTP,  Fulton Financial Corporation',
    name: 'Fulton Financial Corporation',
    symbol: 'FULTP',
  },
  {
    value: 'FTE.WA',
    label: 'FTE.WA,  Fabryki Mebli FORTE S.A.',
    name: 'Fabryki Mebli FORTE S.A.',
    symbol: 'FTE.WA',
  },
  {
    value: 'FTCO',
    label: 'FTCO,  Fortitude Gold Corporation',
    name: 'Fortitude Gold Corporation',
    symbol: 'FTCO',
  },
  {
    value: 'FSTX',
    label: 'FSTX,  F-star Therapeutics, Inc.',
    name: 'F-star Therapeutics, Inc.',
    symbol: 'FSTX',
  },
  {
    value: 'FSKRS.HE',
    label: 'FSKRS.HE,  Fiskars Oyj Abp',
    name: 'Fiskars Oyj Abp',
    symbol: 'FSKRS.HE',
  },
  {
    value: 'FRO.WA',
    label: 'FRO.WA,  FERRO S.A.',
    name: 'FERRO S.A.',
    symbol: 'FRO.WA',
  },
  {
    value: 'FROTO.IS',
    label: 'FROTO.IS,  Ford Otomotiv Sanayi A.S.',
    name: 'Ford Otomotiv Sanayi A.S.',
    symbol: 'FROTO.IS',
  },
  {
    value: 'FRGAP',
    label: 'FRGAP,  Franchise Group, Inc.',
    name: 'Franchise Group, Inc.',
    symbol: 'FRGAP',
  },
  {
    value: 'FRFHF',
    label: 'FRFHF,  Fairfax Financial Holdings Limited',
    name: 'Fairfax Financial Holdings Limited',
    symbol: 'FRFHF',
  },
  {
    value: 'FREY',
    label: 'FREY,  FREYR Battery',
    name: 'FREYR Battery',
    symbol: 'FREY',
  },
  {
    value: 'FREN.JK',
    label: 'FREN.JK,  PT Smartfren Telecom Tbk',
    name: 'PT Smartfren Telecom Tbk',
    symbol: 'FREN.JK',
  },
  {
    value: 'FRAS3.SA',
    label: 'FRAS3.SA,  Fras-le S.A.',
    name: 'Fras-le S.A.',
    symbol: 'FRAS3.SA',
  },
  {
    value: 'FPH.NZ',
    label: 'FPH.NZ,  Fisher & Paykel Healthcare Corporation Limited',
    name: 'Fisher & Paykel Healthcare Corporation Limited',
    symbol: 'FPH.NZ',
  },
  {
    value: 'FOX.TA',
    label: 'FOX.TA,  Fox-Wizel Ltd.',
    name: 'Fox-Wizel Ltd.',
    symbol: 'FOX.TA',
  },
  {
    value: 'FORUS.SN',
    label: 'FORUS.SN,  Forus S.A.',
    name: 'Forus S.A.',
    symbol: 'FORUS.SN',
  },
  {
    value: 'FORTUM.HE',
    label: 'FORTUM.HE,  Fortum Oyj',
    name: 'Fortum Oyj',
    symbol: 'FORTUM.HE',
  },
  {
    value: 'FNM.ST',
    label: 'FNM.ST,  Ferronordic AB (publ)',
    name: 'Ferronordic AB (publ)',
    symbol: 'FNM.ST',
  },
  {
    value: 'FMF.WA',
    label: 'FMF.WA,  Famur S.A.',
    name: 'Famur S.A.',
    symbol: 'FMF.WA',
  },
  {
    value: 'FLYW',
    label: 'FLYW,  Flywire Corporation',
    name: 'Flywire Corporation',
    symbol: 'FLYW',
  },
  {
    value: 'FLS.CO',
    label: 'FLS.CO,  FLSmidth & Co. A/S',
    name: 'FLSmidth & Co. A/S',
    symbol: 'FLS.CO',
  },
  {
    value: 'FLRY3.SA',
    label: 'FLRY3.SA,  Fleury S.A.',
    name: 'Fleury S.A.',
    symbol: 'FLRY3.SA',
  },
  {
    value: 'FIVE.IL',
    label: 'FIVE.IL,  X5 Retail Group N.V.',
    name: 'X5 Retail Group N.V.',
    symbol: 'FIVE.IL',
  },
  {
    value: 'FILA.MI',
    label: 'FILA.MI,  F.I.L.A. - Fabbrica Italiana Lapis ed Affini S.p.A.',
    name: 'F.I.L.A. - Fabbrica Italiana Lapis ed Affini S.p.A.',
    symbol: 'FILA.MI',
  },
  {
    value: 'FIBRAPL14.MX',
    label: 'FIBRAPL14.MX,  FIBRA Prologis',
    name: 'FIBRA Prologis',
    symbol: 'FIBRAPL14.MX',
  },
  {
    value: 'FIBRAMQ12.MX',
    label: 'FIBRAMQ12.MX,  FIBRA Macquarie México',
    name: 'FIBRA Macquarie México',
    symbol: 'FIBRAMQ12.MX',
  },
  {
    value: 'FIBI.TA',
    label: 'FIBI.TA,  First International Bank of Israel Ltd',
    name: 'First International Bank of Israel Ltd',
    symbol: 'FIBI.TA',
  },
  {
    value: 'FIBIH.TA',
    label: 'FIBIH.TA,  F.I.B.I. Holdings Ltd',
    name: 'F.I.B.I. Holdings Ltd',
    symbol: 'FIBIH.TA',
  },
  {
    value: 'FIA1S.HE',
    label: 'FIA1S.HE,  Finnair Oyj',
    name: 'Finnair Oyj',
    symbol: 'FIA1S.HE',
  },
  {
    value: 'FHLB',
    label: 'FHLB,  Friendly Hills Bancorp',
    name: 'Friendly Hills Bancorp',
    symbol: 'FHLB',
  },
  {
    value: 'FHIPO14.MX',
    label: 'FHIPO14.MX,  Fideicomiso Irrevocable F/2061 FHipo',
    name: 'Fideicomiso Irrevocable F/2061 FHipo',
    symbol: 'FHIPO14.MX',
  },
  {
    value: 'FGPRB',
    label: 'FGPRB,  Ferrellgas Partners, L.P.',
    name: 'Ferrellgas Partners, L.P.',
    symbol: 'FGPRB',
  },
  {
    value: 'FGF',
    label: 'FGF,  FG Financial Group, Inc.',
    name: 'FG Financial Group, Inc.',
    symbol: 'FGF',
  },
  {
    value: 'FESA4.SA',
    label: 'FESA4.SA,  Cia de Ferro Ligas da Bahia - FERBASA',
    name: 'Cia de Ferro Ligas da Bahia - FERBASA',
    symbol: 'FESA4.SA',
  },
  {
    value: 'FER.MC',
    label: 'FER.MC,  Ferrovial, S.A.',
    name: 'Ferrovial, S.A.',
    symbol: 'FER.MC',
  },
  {
    value: 'FERG',
    label: 'FERG,  Ferguson plc',
    name: 'Ferguson plc',
    symbol: 'FERG',
  },
  {
    value: 'FEMSAUBD.MX',
    label: 'FEMSAUBD.MX,  Fomento Económico Mexicano, S.A.B. de C.V.',
    name: 'Fomento Económico Mexicano, S.A.B. de C.V.',
    symbol: 'FEMSAUBD.MX',
  },
  {
    value: 'FCT.MI',
    label: 'FCT.MI,  Fincantieri S.p.A.',
    name: 'Fincantieri S.p.A.',
    symbol: 'FCT.MI',
  },
  {
    value: 'FCNCB',
    label: 'FCNCB,  First Citizens BancShares, Inc.',
    name: 'First Citizens BancShares, Inc.',
    symbol: 'FCNCB',
  },
  {
    value: 'FCC.MC',
    label: 'FCC.MC,  Fomento de Construcciones y Contratas, S.A.',
    name: 'Fomento de Construcciones y Contratas, S.A.',
    symbol: 'FCC.MC',
  },
  {
    value: 'FBU.NZ',
    label: 'FBU.NZ,  Fletcher Building Limited',
    name: 'Fletcher Building Limited',
    symbol: 'FBU.NZ',
  },
  {
    value: 'FBK.MI',
    label: 'FBK.MI,  FinecoBank Banca Fineco S.p.A.',
    name: 'FinecoBank Banca Fineco S.p.A.',
    symbol: 'FBK.MI',
  },
  {
    value: 'FATBB',
    label: 'FATBB,  FAT Brands Inc.',
    name: 'FAT Brands Inc.',
    symbol: 'FATBB',
  },
  {
    value: 'FAPR',
    label: 'FAPR,  FT Cboe Vest U.S. Equity Buffer ETF - April',
    name: 'FT Cboe Vest U.S. Equity Buffer ETF - April',
    symbol: 'FAPR',
  },
  {
    value: 'FALABELLA.SN',
    label: 'FALABELLA.SN,  Falabella S.A.',
    name: 'Falabella S.A.',
    symbol: 'FALABELLA.SN',
  },
  {
    value: 'FAG.ST',
    label: 'FAG.ST,  AB Fagerhult (publ.)',
    name: 'AB Fagerhult (publ.)',
    symbol: 'FAG.ST',
  },
  {
    value: 'FAE.MC',
    label: 'FAE.MC,  Faes Farma, S.A.',
    name: 'Faes Farma, S.A.',
    symbol: 'FAE.MC',
  },
  {
    value: 'FACO',
    label: 'FACO,  First Acceptance Corporation',
    name: 'First Acceptance Corporation',
    symbol: 'FACO',
  },
  {
    value: 'FACC.VI',
    label: 'FACC.VI,  FACC AG',
    name: 'FACC AG',
    symbol: 'FACC.VI',
  },
  {
    value: 'FABG.ST',
    label: 'FABG.ST,  Fabege AB (publ)',
    name: 'Fabege AB (publ)',
    symbol: 'FABG.ST',
  },
  {
    value: 'F83.SI',
    label: 'F83.SI,  COSCO SHIPPING International (Singapore) Co., Ltd.',
    name: 'COSCO SHIPPING International (Singapore) Co., Ltd.',
    symbol: 'F83.SI',
  },
  {
    value: 'F34.SI',
    label: 'F34.SI,  Wilmar International Limited',
    name: 'Wilmar International Limited',
    symbol: 'F34.SI',
  },
  {
    value: 'EZTC3.SA',
    label: 'EZTC3.SA,  EZTEC Empreendimentos e Participações S.A.',
    name: 'EZTEC Empreendimentos e Participações S.A.',
    symbol: 'EZTC3.SA',
  },
  {
    value: 'EXN',
    label: 'EXN,  Excellon Resources Inc.',
    name: 'Excellon Resources Inc.',
    symbol: 'EXN',
  },
  {
    value: 'EXCL.JK',
    label: 'EXCL.JK,  PT XL Axiata Tbk',
    name: 'PT XL Axiata Tbk',
    symbol: 'EXCL.JK',
  },
  {
    value: 'EXCL',
    label: 'EXCL,  Excellerant, Inc.',
    name: 'Excellerant, Inc.',
    symbol: 'EXCL',
  },
  {
    value: 'EVO.ST',
    label: 'EVO.ST,  Evolution AB (publ)',
    name: 'Evolution AB (publ)',
    symbol: 'EVO.ST',
  },
  {
    value: 'EVGO',
    label: 'EVGO,  EVgo, Inc.',
    name: 'EVgo, Inc.',
    symbol: 'EVGO',
  },
  {
    value: 'EVEN3.SA',
    label: 'EVEN3.SA,  Even Construtora e Incorporadora S.A.',
    name: 'Even Construtora e Incorporadora S.A.',
    symbol: 'EVEN3.SA',
  },
  {
    value: 'EVCM',
    label: 'EVCM,  EverCommerce Inc.',
    name: 'EverCommerce Inc.',
    symbol: 'EVCM',
  },
  {
    value: 'EVAX',
    label: 'EVAX,  Evaxion Biotech A/S',
    name: 'Evaxion Biotech A/S',
    symbol: 'EVAX',
  },
  {
    value: 'EUR.WA',
    label: 'EUR.WA,  Eurocash S.A.',
    name: 'Eurocash S.A.',
    symbol: 'EUR.WA',
  },
  {
    value: 'EUCR',
    label: 'EUCR,  Eucrates Biomedical Acquisition Corp.',
    name: 'Eucrates Biomedical Acquisition Corp.',
    symbol: 'EUCR',
  },
  {
    value: 'EUCA4.SA',
    label: 'EUCA4.SA,  Eucatex S.A. Indústria e Comércio',
    name: 'Eucatex S.A. Indústria e Comércio',
    symbol: 'EUCA4.SA',
  },
  {
    value: 'EUA.L',
    label: 'EUA.L,  Eurasia Mining Plc',
    name: 'Eurasia Mining Plc',
    symbol: 'EUA.L',
  },
  {
    value: 'ETER3.SA',
    label: 'ETER3.SA,  Eternit S.A.',
    name: 'Eternit S.A.',
    symbol: 'ETER3.SA',
  },
  {
    value: 'ETD',
    label: 'ETD,  Ethan Allen Interiors Inc.',
    name: 'Ethan Allen Interiors Inc.',
    symbol: 'ETD',
  },
  {
    value: 'ESPA3.SA',
    label: 'ESPA3.SA,  MPM Corpóreos S.A.',
    name: 'MPM Corpóreos S.A.',
    symbol: 'ESPA3.SA',
  },
  {
    value: 'ESLT.TA',
    label: 'ESLT.TA,  Elbit Systems Ltd.',
    name: 'Elbit Systems Ltd.',
    symbol: 'ESLT.TA',
  },
  {
    value: 'ESKN.L',
    label: 'ESKN.L,  Esken Limited',
    name: 'Esken Limited',
    symbol: 'ESKN.L',
  },
  {
    value: 'ERG.MI',
    label: 'ERG.MI,  ERG S.p.A.',
    name: 'ERG S.p.A.',
    symbol: 'ERG.MI',
  },
  {
    value: 'ERES',
    label: 'ERES,  East Resources Acquisition Company',
    name: 'East Resources Acquisition Company',
    symbol: 'ERES',
  },
  {
    value: 'EREGL.IS',
    label: 'EREGL.IS,  Eregli Demir ve Çelik Fabrikalari T.A.S.',
    name: 'Eregli Demir ve Çelik Fabrikalari T.A.S.',
    symbol: 'EREGL.IS',
  },
  {
    value: 'ERAS',
    label: 'ERAS,  Erasca, Inc.',
    name: 'Erasca, Inc.',
    symbol: 'ERAS',
  },
  {
    value: 'ERAA.JK',
    label: 'ERAA.JK,  PT Erajaya Swasembada Tbk',
    name: 'PT Erajaya Swasembada Tbk',
    symbol: 'ERAA.JK',
  },
  {
    value: 'EQV1V.HE',
    label: 'EQV1V.HE,  eQ Oyj',
    name: 'eQ Oyj',
    symbol: 'EQV1V.HE',
  },
  {
    value: 'EQT.ST',
    label: 'EQT.ST,  EQT AB (publ)',
    name: 'EQT AB (publ)',
    symbol: 'EQT.ST',
  },
  {
    value: 'EQTL.TA',
    label: 'EQTL.TA,  Equital Ltd.',
    name: 'Equital Ltd.',
    symbol: 'EQTL.TA',
  },
  {
    value: 'EQTL3.SA',
    label: 'EQTL3.SA,  Equatorial Energia S.A.',
    name: 'Equatorial Energia S.A.',
    symbol: 'EQTL3.SA',
  },
  {
    value: 'EQTL',
    label: 'EQTL,  Equitech International Corporation',
    name: 'Equitech International Corporation',
    symbol: 'EQTL',
  },
  {
    value: 'EQOS',
    label: 'EQOS,  Eqonex Limited',
    name: 'Eqonex Limited',
    symbol: 'EQOS',
  },
  {
    value: 'EPL.NS',
    label: 'EPL.NS,  EPL Limited',
    name: 'EPL Limited',
    symbol: 'EPL.NS',
  },
  {
    value: 'EPG-R.BK',
    label: 'EPG-R.BK,  Eastern Polymer Group Public Company Limited',
    name: 'Eastern Polymer Group Public Company Limited',
    symbol: 'EPG-R.BK',
  },
  {
    value: 'EO.MI',
    label: 'EO.MI,  Faurecia S.E.',
    name: 'Faurecia S.E.',
    symbol: 'EO.MI',
  },
  {
    value: 'ENZY.ST',
    label: 'ENZY.ST,  Enzymatica AB (publ)',
    name: 'Enzymatica AB (publ)',
    symbol: 'ENZY.ST',
  },
  {
    value: 'ENVX',
    label: 'ENVX,  Enovix Corporation',
    name: 'Enovix Corporation',
    symbol: 'ENVX',
  },
  {
    value: 'ENVB',
    label: 'ENVB,  Enveric Biosciences, Inc.',
    name: 'Enveric Biosciences, Inc.',
    symbol: 'ENVB',
  },
  {
    value: 'ENT.WA',
    label: 'ENT.WA,  Enter Air Sp. z o.o.',
    name: 'Enter Air Sp. z o.o.',
    symbol: 'ENT.WA',
  },
  {
    value: 'ENT.L',
    label: 'ENT.L,  Entain Plc',
    name: 'Entain Plc',
    symbol: 'ENT.L',
  },
  {
    value: 'ENTEL.SN',
    label: 'ENTEL.SN,  Empresa Nacional de Telecomunicaciones S.A.',
    name: 'Empresa Nacional de Telecomunicaciones S.A.',
    symbol: 'ENTEL.SN',
  },
  {
    value: 'ENRG.TA',
    label: 'ENRG.TA,  Energix - Renewable Energies Ltd',
    name: 'Energix - Renewable Energies Ltd',
    symbol: 'ENRG.TA',
  },
  {
    value: 'ENLT.TA',
    label: 'ENLT.TA,  Enlight Renewable Energy Ltd',
    name: 'Enlight Renewable Energy Ltd',
    symbol: 'ENLT.TA',
  },
  {
    value: 'ENLAY',
    label: 'ENLAY,  Enel SpA',
    name: 'Enel SpA',
    symbol: 'ENLAY',
  },
  {
    value: 'ENKAI.IS',
    label: 'ENKAI.IS,  Enka Insaat ve Sanayi A.S.',
    name: 'Enka Insaat ve Sanayi A.S.',
    symbol: 'ENKAI.IS',
  },
  {
    value: 'ENJSA.IS',
    label: 'ENJSA.IS,  Enerjisa Enerji A.S.',
    name: 'Enerjisa Enerji A.S.',
    symbol: 'ENJSA.IS',
  },
  {
    value: 'ENI.MI',
    label: 'ENI.MI,  Eni S.p.A.',
    name: 'Eni S.p.A.',
    symbol: 'ENI.MI',
  },
  {
    value: 'ENG.WA',
    label: 'ENG.WA,  Energa SA',
    name: 'Energa SA',
    symbol: 'ENG.WA',
  },
  {
    value: 'ENGI11.SA',
    label: 'ENGI11.SA,  Energisa S.A.',
    name: 'Energisa S.A.',
    symbol: 'ENGI11.SA',
  },
  {
    value: 'ENGH',
    label: 'ENGH,  Energy Harbor Corp.',
    name: 'Energy Harbor Corp.',
    symbol: 'ENGH',
  },
  {
    value: 'ENEV3.SA',
    label: 'ENEV3.SA,  Eneva S.A.',
    name: 'Eneva S.A.',
    symbol: 'ENEV3.SA',
  },
  {
    value: 'ENENTO.HE',
    label: 'ENENTO.HE,  Enento Group Oyj',
    name: 'Enento Group Oyj',
    symbol: 'ENENTO.HE',
  },
  {
    value: 'ENELCHILE.SN',
    label: 'ENELCHILE.SN,  Enel Chile S.A.',
    name: 'Enel Chile S.A.',
    symbol: 'ENELCHILE.SN',
  },
  {
    value: 'ENELAM.SN',
    label: 'ENELAM.SN,  Enel Américas S.A.',
    name: 'Enel Américas S.A.',
    symbol: 'ENELAM.SN',
  },
  {
    value: 'ENC.MC',
    label: 'ENC.MC,  ENCE Energía y Celulosa, S.A.',
    name: 'ENCE Energía y Celulosa, S.A.',
    symbol: 'ENC.MC',
  },
  {
    value: 'ENBR3.SA',
    label: 'ENBR3.SA,  EDP - Energias do Brasil S.A.',
    name: 'EDP - Energias do Brasil S.A.',
    symbol: 'ENBR3.SA',
  },
  {
    value: 'ENA.WA',
    label: 'ENA.WA,  ENEA S.A.',
    name: 'ENEA S.A.',
    symbol: 'ENA.WA',
  },
  {
    value: 'ENAV.MI',
    label: 'ENAV.MI,  ENAV S.p.A.',
    name: 'ENAV S.p.A.',
    symbol: 'ENAV.MI',
  },
  {
    value: 'ENAT3.SA',
    label: 'ENAT3.SA,  Enauta Participações S.A.',
    name: 'Enauta Participações S.A.',
    symbol: 'ENAT3.SA',
  },
  {
    value: 'EMBR3.SA',
    label: 'EMBR3.SA,  Embraer S.A.',
    name: 'Embraer S.A.',
    symbol: 'EMBR3.SA',
  },
  {
    value: 'ELTR.TA',
    label: 'ELTR.TA,  Electra Limited',
    name: 'Electra Limited',
    symbol: 'ELTR.TA',
  },
  {
    value: 'ELTEL.ST',
    label: 'ELTEL.ST,  Eltel AB (publ)',
    name: 'Eltel AB (publ)',
    symbol: 'ELTEL.ST',
  },
  {
    value: 'ELISA.HE',
    label: 'ELISA.HE,  Elisa Oyj',
    name: 'Elisa Oyj',
    symbol: 'ELISA.HE',
  },
  {
    value: 'ELET6.SA',
    label: 'ELET6.SA,  Centrais Elétricas Brasileiras S.A. - Eletrobrás',
    name: 'Centrais Elétricas Brasileiras S.A. - Eletrobrás',
    symbol: 'ELET6.SA',
  },
  {
    value: 'ELET3.SA',
    label: 'ELET3.SA,  Centrais Elétricas Brasileiras S.A. - Eletrobrás',
    name: 'Centrais Elétricas Brasileiras S.A. - Eletrobrás',
    symbol: 'ELET3.SA',
  },
  {
    value: 'ELE.MC',
    label: 'ELE.MC,  Endesa, S.A.',
    name: 'Endesa, S.A.',
    symbol: 'ELE.MC',
  },
  {
    value: 'ELEKTRA.MX',
    label: 'ELEKTRA.MX,  Grupo Elektra, S.A.B. de C.V.',
    name: 'Grupo Elektra, S.A.B. de C.V.',
    symbol: 'ELEKTRA.MX',
  },
  {
    value: 'ELDN',
    label: 'ELDN,  Eledon Pharmaceuticals, Inc.',
    name: 'Eledon Pharmaceuticals, Inc.',
    symbol: 'ELDN',
  },
  {
    value: 'ELCO.TA',
    label: 'ELCO.TA,  Elco Ltd.',
    name: 'Elco Ltd.',
    symbol: 'ELCO.TA',
  },
  {
    value: 'EKGYO.IS',
    label: 'EKGYO.IS,  Emlak Konut Gayrimenkul Yatirim Ortakligi A.S.',
    name: 'Emlak Konut Gayrimenkul Yatirim Ortakligi A.S.',
    symbol: 'EKGYO.IS',
  },
  {
    value: 'EGIE3.SA',
    label: 'EGIE3.SA,  Engie Brasil Energia S.A.',
    name: 'Engie Brasil Energia S.A.',
    symbol: 'EGIE3.SA',
  },
  {
    value: 'EGEEN.IS',
    label: 'EGEEN.IS,  Ege Endüstri ve Ticaret A.S.',
    name: 'Ege Endüstri ve Ticaret A.S.',
    symbol: 'EGEEN.IS',
  },
  {
    value: 'EGCO-R.BK',
    label: 'EGCO-R.BK,  Electricity Generating Public Company Limited',
    name: 'Electricity Generating Public Company Limited',
    symbol: 'EGCO-R.BK',
  },
  {
    value: 'EGCO.BK',
    label: 'EGCO.BK,  Electricity Generating Public Company Limited',
    name: 'Electricity Generating Public Company Limited',
    symbol: 'EGCO.BK',
  },
  {
    value: 'EG7.ST',
    label: 'EG7.ST,  Enad Global 7 AB (publ)',
    name: 'Enad Global 7 AB (publ)',
    symbol: 'EG7.ST',
  },
  {
    value: 'EDV.L',
    label: 'EDV.L,  Endeavour Mining plc',
    name: 'Endeavour Mining plc',
    symbol: 'EDV.L',
  },
  {
    value: 'ECVT',
    label: 'ECVT,  Ecovyst Inc.',
    name: 'Ecovyst Inc.',
    symbol: 'ECVT',
  },
  {
    value: 'ECV.DE',
    label: 'ECV.DE,  Encavis AG',
    name: 'Encavis AG',
    symbol: 'ECV.DE',
  },
  {
    value: 'ECR.MC',
    label: 'ECR.MC,  Ercros, S.A.',
    name: 'Ercros, S.A.',
    symbol: 'ECR.MC',
  },
  {
    value: 'ECP.TA',
    label: 'ECP.TA,  Electra Consumer Products (1970) Ltd',
    name: 'Electra Consumer Products (1970) Ltd',
    symbol: 'ECP.TA',
  },
  {
    value: 'ECOR3.SA',
    label: 'ECOR3.SA,  EcoRodovias Infraestrutura e Logística S.A.',
    name: 'EcoRodovias Infraestrutura e Logística S.A.',
    symbol: 'ECOR3.SA',
  },
  {
    value: 'ECNL.MI',
    label: 'ECNL.MI,  Aquafil S.p.A.',
    name: 'Aquafil S.p.A.',
    symbol: 'ECNL.MI',
  },
  {
    value: 'ECL.SN',
    label: 'ECL.SN,  Engie Energia Chile S.A.',
    name: 'Engie Energia Chile S.A.',
    symbol: 'ECL.SN',
  },
  {
    value: 'ECILC.IS',
    label:
      'ECILC.IS,  EIS Eczacibasi Ilaç, Sinai ve Finansal Yatirimlar Sanayi ve Ticaret A.S.',
    name: 'EIS Eczacibasi Ilaç, Sinai ve Finansal Yatirimlar Sanayi ve Ticaret A.S.',
    symbol: 'ECILC.IS',
  },
  {
    value: 'ECH.WA',
    label: 'ECH.WA,  Echo Investment S.A.',
    name: 'Echo Investment S.A.',
    symbol: 'ECH.WA',
  },
  {
    value: 'EBRO.MC',
    label: 'EBRO.MC,  Ebro Foods, S.A.',
    name: 'Ebro Foods, S.A.',
    symbol: 'EBRO.MC',
  },
  {
    value: 'EB5.SI',
    label: 'EB5.SI,  First Resources Limited',
    name: 'First Resources Limited',
    symbol: 'EB5.SI',
  },
  {
    value: 'EAT.WA',
    label: 'EAT.WA,  AmRest Holdings SE',
    name: 'AmRest Holdings SE',
    symbol: 'EAT.WA',
  },
  {
    value: 'EASTW-R.BK',
    label:
      'EASTW-R.BK,  Eastern Water Resources Development and Management Public Company Limited',
    name: 'Eastern Water Resources Development and Management Public Company Limited',
    symbol: 'EASTW-R.BK',
  },
  {
    value: 'EA-R.BK',
    label: 'EA-R.BK,  Energy Absolute Public Company Limited',
    name: 'Energy Absolute Public Company Limited',
    symbol: 'EA-R.BK',
  },
  {
    value: 'EADSY',
    label: 'EADSY,  Airbus SE',
    name: 'Airbus SE',
    symbol: 'EADSY',
  },
  {
    value: 'E5H.SI',
    label: 'E5H.SI,  Golden Agri-Resources Ltd',
    name: 'Golden Agri-Resources Ltd',
    symbol: 'E5H.SI',
  },
  {
    value: 'DXCO3.SA',
    label: 'DXCO3.SA,  Dexco S.A.',
    name: 'Dexco S.A.',
    symbol: 'DXCO3.SA',
  },
  {
    value: 'DVL.WA',
    label: 'DVL.WA,  Develia S.A.',
    name: 'Develia S.A.',
    symbol: 'DVL.WA',
  },
  {
    value: 'DUST.ST',
    label: 'DUST.ST,  Dustin Group AB (publ)',
    name: 'Dustin Group AB (publ)',
    symbol: 'DUST.ST',
  },
  {
    value: 'DUNI.ST',
    label: 'DUNI.ST,  Duni AB (publ)',
    name: 'Duni AB (publ)',
    symbol: 'DUNI.ST',
  },
  {
    value: 'DTAC-R.BK',
    label: 'DTAC-R.BK,  Total Access Communication Public Company Limited',
    name: 'Total Access Communication Public Company Limited',
    symbol: 'DTAC-R.BK',
  },
  {
    value: 'DSV.CO',
    label: 'DSV.CO,  DSV A/S',
    name: 'DSV A/S',
    symbol: 'DSV.CO',
  },
  {
    value: 'DSNO.ST',
    label: 'DSNO.ST,  Desenio Group AB (publ)',
    name: 'Desenio Group AB (publ)',
    symbol: 'DSNO.ST',
  },
  {
    value: 'DSCT.TA',
    label: 'DSCT.TA,  Israel Discount Bank Limited',
    name: 'Israel Discount Bank Limited',
    symbol: 'DSCT.TA',
  },
  {
    value: 'DORL.TA',
    label: 'DORL.TA,  Doral Group Renewable Energy Resources Ltd',
    name: 'Doral Group Renewable Energy Resources Ltd',
    symbol: 'DORL.TA',
  },
  {
    value: 'DOM.WA',
    label: 'DOM.WA,  Dom Development S.A.',
    name: 'Dom Development S.A.',
    symbol: 'DOM.WA',
  },
  {
    value: 'DOM.ST',
    label: 'DOM.ST,  Dometic Group AB (publ)',
    name: 'Dometic Group AB (publ)',
    symbol: 'DOM.ST',
  },
  {
    value: 'DOID.JK',
    label: 'DOID.JK,  PT Delta Dunia Makmur Tbk',
    name: 'PT Delta Dunia Makmur Tbk',
    symbol: 'DOID.JK',
  },
  {
    value: 'DOHOL.IS',
    label: 'DOHOL.IS,  Dogan Sirketler Grubu Holding A.S.',
    name: 'Dogan Sirketler Grubu Holding A.S.',
    symbol: 'DOHOL.IS',
  },
  {
    value: 'DOCS.L',
    label: 'DOCS.L,  Dr. Martens plc',
    name: 'Dr. Martens plc',
    symbol: 'DOCS.L',
  },
  {
    value: 'DOAS.IS',
    label: 'DOAS.IS,  Dogus Otomotiv Servis ve Ticaret A.S.',
    name: 'Dogus Otomotiv Servis ve Ticaret A.S.',
    symbol: 'DOAS.IS',
  },
  {
    value: 'DNP.WA',
    label: 'DNP.WA,  Dino Polska S.A.',
    name: 'Dino Polska S.A.',
    symbol: 'DNP.WA',
  },
  {
    value: 'DNORD.CO',
    label: 'DNORD.CO,  Dampskibsselskabet Norden A/S',
    name: 'Dampskibsselskabet Norden A/S',
    symbol: 'DNORD.CO',
  },
  {
    value: 'DNNGY',
    label: 'DNNGY,  Ørsted A/S',
    name: 'Ørsted A/S',
    symbol: 'DNNGY',
  },
  {
    value: 'DMAS.JK',
    label: 'DMAS.JK,  PT Puradelta Lestari Tbk',
    name: 'PT Puradelta Lestari Tbk',
    symbol: 'DMAS.JK',
  },
  {
    value: 'DLTA',
    label: 'DLTA,  Delta Oil & Gas, Inc.',
    name: 'Delta Oil & Gas, Inc.',
    symbol: 'DLTA',
  },
  {
    value: 'DLG.MI',
    label: "DLG.MI,  De'Longhi S.p.A.",
    name: "De'Longhi S.p.A.",
    symbol: 'DLG.MI',
  },
  {
    value: 'DLEKG.TA',
    label: 'DLEKG.TA,  Delek Group Ltd.',
    name: 'Delek Group Ltd.',
    symbol: 'DLEKG.TA',
  },
  {
    value: 'DLEA.TA',
    label: 'DLEA.TA,  Delek Automotive Systems Ltd.',
    name: 'Delek Automotive Systems Ltd.',
    symbol: 'DLEA.TA',
  },
  {
    value: 'DIRR3.SA',
    label: 'DIRR3.SA,  Direcional Engenharia S.A.',
    name: 'Direcional Engenharia S.A.',
    symbol: 'DIRR3.SA',
  },
  {
    value: 'DIOS.ST',
    label: 'DIOS.ST,  Diös Fastigheter AB (publ)',
    name: 'Diös Fastigheter AB (publ)',
    symbol: 'DIOS.ST',
  },
  {
    value: 'DIMRI.TA',
    label: 'DIMRI.TA,  Y.H. Dimri Construction & Development Ltd',
    name: 'Y.H. Dimri Construction & Development Ltd',
    symbol: 'DIMRI.TA',
  },
  {
    value: 'DIGI',
    label: 'DIGI,  digitiliti, Inc.',
    name: 'digitiliti, Inc.',
    symbol: 'DIGI',
  },
  {
    value: 'DIB.MI',
    label: 'DIB.MI,  Digital Bros S.p.A.',
    name: 'Digital Bros S.p.A.',
    symbol: 'DIB.MI',
  },
  {
    value: 'DHANI.NS',
    label: 'DHANI.NS,  Dhani Services Limited',
    name: 'Dhani Services Limited',
    symbol: 'DHANI.NS',
  },
  {
    value: 'DFDS.CO',
    label: 'DFDS.CO,  DFDS A/S',
    name: 'DFDS A/S',
    symbol: 'DFDS.CO',
  },
  {
    value: 'DEXP3.SA',
    label: 'DEXP3.SA,  Dexxos Participações S.A.',
    name: 'Dexxos Participações S.A.',
    symbol: 'DEXP3.SA',
  },
  {
    value: 'DEVA.IS',
    label: 'DEVA.IS,  Deva Holding A.S.',
    name: 'Deva Holding A.S.',
    symbol: 'DEVA.IS',
  },
  {
    value: 'DEMANT.CO',
    label: 'DEMANT.CO,  Demant A/S',
    name: 'Demant A/S',
    symbol: 'DEMANT.CO',
  },
  {
    value: 'DELT.TA',
    label: 'DELT.TA,  Delta Galil Industries Ltd.',
    name: 'Delta Galil Industries Ltd.',
    symbol: 'DELT.TA',
  },
  {
    value: 'DELTA-R.BK',
    label: 'DELTA-R.BK,  Delta Electronics (Thailand) Public Company Limited',
    name: 'Delta Electronics (Thailand) Public Company Limited',
    symbol: 'DELTA-R.BK',
  },
  {
    value: 'DEC.L',
    label: 'DEC.L,  Diversified Energy Company PLC',
    name: 'Diversified Energy Company PLC',
    symbol: 'DEC.L',
  },
  {
    value: 'DCC-R.BK',
    label: 'DCC-R.BK,  Dynasty Ceramic Public Company Limited',
    name: 'Dynasty Ceramic Public Company Limited',
    symbol: 'DCC-R.BK',
  },
  {
    value: 'DBM.TO',
    label: 'DBM.TO,  Doman Building Materials Group Ltd.',
    name: 'Doman Building Materials Group Ltd.',
    symbol: 'DBM.TO',
  },
  {
    value: 'DBC.WA',
    label: 'DBC.WA,  Tire Company Debica S.A.',
    name: 'Tire Company Debica S.A.',
    symbol: 'DBC.WA',
  },
  {
    value: 'DAT.WA',
    label: 'DAT.WA,  DataWalk S.A.',
    name: 'DataWalk S.A.',
    symbol: 'DAT.WA',
  },
  {
    value: 'DANSKE.CO',
    label: 'DANSKE.CO,  Danske Bank A/S',
    name: 'Danske Bank A/S',
    symbol: 'DANSKE.CO',
  },
  {
    value: 'DANHOS13.MX',
    label: 'DANHOS13.MX,  Fibra Danhos',
    name: 'Fibra Danhos',
    symbol: 'DANHOS13.MX',
  },
  {
    value: 'DANE.TA',
    label: 'DANE.TA,  Danel (Adir Yeoshua) Ltd',
    name: 'Danel (Adir Yeoshua) Ltd',
    symbol: 'DANE.TA',
  },
  {
    value: 'D5IU.SI',
    label: 'D5IU.SI,  Lippo Malls Indonesia Retail Trust',
    name: 'Lippo Malls Indonesia Retail Trust',
    symbol: 'D5IU.SI',
  },
  {
    value: 'D05.SI',
    label: 'D05.SI,  DBS Group Holdings Ltd',
    name: 'DBS Group Holdings Ltd',
    symbol: 'D05.SI',
  },
  {
    value: 'CYTO',
    label: 'CYTO,  Altamira Therapeutics Ltd.',
    name: 'Altamira Therapeutics Ltd.',
    symbol: 'CYTO',
  },
  {
    value: 'CYRE3.SA',
    label:
      'CYRE3.SA,  Cyrela Brazil Realty S.A. Empreendimentos e Participações',
    name: 'Cyrela Brazil Realty S.A. Empreendimentos e Participações',
    symbol: 'CYRE3.SA',
  },
  {
    value: 'CY6U.SI',
    label: 'CY6U.SI,  CapitaLand India Trust',
    name: 'CapitaLand India Trust',
    symbol: 'CY6U.SI',
  },
  {
    value: 'CWGRP',
    label: 'CWGRP,  Cowen Inc.',
    name: 'Cowen Inc.',
    symbol: 'CWGRP',
  },
  {
    value: 'CVII',
    label: 'CVII,  Churchill Capital Corp VII',
    name: 'Churchill Capital Corp VII',
    symbol: 'CVII',
  },
  {
    value: 'CVCB3.SA',
    label: 'CVCB3.SA,  CVC Brasil Operadora e Agência de Viagens S.A.',
    name: 'CVC Brasil Operadora e Agência de Viagens S.A.',
    symbol: 'CVCB3.SA',
  },
  {
    value: 'CURY.L',
    label: 'CURY.L,  Currys plc',
    name: 'Currys plc',
    symbol: 'CURY.L',
  },
  {
    value: 'CURV',
    label: 'CURV,  Torrid Holdings Inc.',
    name: 'Torrid Holdings Inc.',
    symbol: 'CURV',
  },
  {
    value: 'CUERVO.MX',
    label: 'CUERVO.MX,  Becle, S.A.B. de C.V.',
    name: 'Becle, S.A.B. de C.V.',
    symbol: 'CUERVO.MX',
  },
  {
    value: 'CTY1S.HE',
    label: 'CTY1S.HE,  Citycon Oyj',
    name: 'Citycon Oyj',
    symbol: 'CTY1S.HE',
  },
  {
    value: 'CTT.ST',
    label: 'CTT.ST,  CTT Systems AB (publ)',
    name: 'CTT Systems AB (publ)',
    symbol: 'CTT.ST',
  },
  {
    value: 'CTRA.JK',
    label: 'CTRA.JK,  PT Ciputra Development Tbk',
    name: 'PT Ciputra Development Tbk',
    symbol: 'CTRA.JK',
  },
  {
    value: 'CTM.ST',
    label: 'CTM.ST,  Catena Media plc',
    name: 'Catena Media plc',
    symbol: 'CTM.ST',
  },
  {
    value: 'CTAQ',
    label: 'CTAQ,  Carney Technology Acquisition Corp. II',
    name: 'Carney Technology Acquisition Corp. II',
    symbol: 'CTAQ',
  },
  {
    value: 'CSNA3.SA',
    label: 'CSNA3.SA,  Companhia Siderúrgica Nacional',
    name: 'Companhia Siderúrgica Nacional',
    symbol: 'CSNA3.SA',
  },
  {
    value: 'CSMG3.SA',
    label: 'CSMG3.SA,  Companhia de Saneamento de Minas Gerais',
    name: 'Companhia de Saneamento de Minas Gerais',
    symbol: 'CSMG3.SA',
  },
  {
    value: 'CSAN3.SA',
    label: 'CSAN3.SA,  Cosan S.A.',
    name: 'Cosan S.A.',
    symbol: 'CSAN3.SA',
  },
  {
    value: 'CSAN',
    label: 'CSAN,  Cosan S.A.',
    name: 'Cosan S.A.',
    symbol: 'CSAN',
  },
  {
    value: 'CRPU.SI',
    label: 'CRPU.SI,  Sasseur Real Estate Investment Trust',
    name: 'Sasseur Real Estate Investment Trust',
    symbol: 'CRPU.SI',
  },
  {
    value: 'CRFB3.SA',
    label: 'CRFB3.SA,  Atacadão S.A.',
    name: 'Atacadão S.A.',
    symbol: 'CRFB3.SA',
  },
  {
    value: 'CRE.L',
    label: 'CRE.L,  Conduit Holdings Limited',
    name: 'Conduit Holdings Limited',
    symbol: 'CRE.L',
  },
  {
    value: 'CRDL',
    label: 'CRDL,  Cardiol Therapeutics Inc.',
    name: 'Cardiol Therapeutics Inc.',
    symbol: 'CRDL',
  },
  {
    value: 'CRC-R.BK',
    label: 'CRC-R.BK,  Central Retail Corporation Public Company Limited',
    name: 'Central Retail Corporation Public Company Limited',
    symbol: 'CRC-R.BK',
  },
  {
    value: 'CRCQW',
    label: 'CRCQW,  California Resources Corporation',
    name: 'California Resources Corporation',
    symbol: 'CRCQW',
  },
  {
    value: 'CRAYN.OL',
    label: 'CRAYN.OL,  Crayon Group Holding ASA',
    name: 'Crayon Group Holding ASA',
    symbol: 'CRAYN.OL',
  },
  {
    value: 'CPUH',
    label: 'CPUH,  Compute Health Acquisition Corp.',
    name: 'Compute Health Acquisition Corp.',
    symbol: 'CPUH',
  },
  {
    value: 'CPS.WA',
    label: 'CPS.WA,  Cyfrowy Polsat S.A.',
    name: 'Cyfrowy Polsat S.A.',
    symbol: 'CPS.WA',
  },
  {
    value: 'CPN-R.BK',
    label: 'CPN-R.BK,  Central Pattana Public Company Limited',
    name: 'Central Pattana Public Company Limited',
    symbol: 'CPN-R.BK',
  },
  {
    value: 'CPLE6.SA',
    label: 'CPLE6.SA,  Companhia Paranaense de Energia - COPEL',
    name: 'Companhia Paranaense de Energia - COPEL',
    symbol: 'CPLE6.SA',
  },
  {
    value: 'CPIN.JK',
    label: 'CPIN.JK,  PT Charoen Pokphand Indonesia Tbk',
    name: 'PT Charoen Pokphand Indonesia Tbk',
    symbol: 'CPIN.JK',
  },
  {
    value: 'CPF-R.BK',
    label: 'CPF-R.BK,  Charoen Pokphand Foods Public Company Limited',
    name: 'Charoen Pokphand Foods Public Company Limited',
    symbol: 'CPF-R.BK',
  },
  {
    value: 'CPFE3.SA',
    label: 'CPFE3.SA,  CPFL Energia S.A.',
    name: 'CPFL Energia S.A.',
    symbol: 'CPFE3.SA',
  },
  {
    value: 'CPALL-R.BK',
    label: 'CPALL-R.BK,  CP ALL Public Company Limited',
    name: 'CP ALL Public Company Limited',
    symbol: 'CPALL-R.BK',
  },
  {
    value: 'COPEC.SN',
    label: 'COPEC.SN,  Empresas Copec S.A.',
    name: 'Empresas Copec S.A.',
    symbol: 'COPEC.SN',
  },
  {
    value: 'COOR.ST',
    label: 'COOR.ST,  Coor Service Management Holding AB',
    name: 'Coor Service Management Holding AB',
    symbol: 'COOR.ST',
  },
  {
    value: 'CONX',
    label: 'CONX,  CONX Corp.',
    name: 'CONX Corp.',
    symbol: 'CONX',
  },
  {
    value: 'CONCHATORO.SN',
    label: 'CONCHATORO.SN,  Viña Concha y Toro S.A.',
    name: 'Viña Concha y Toro S.A.',
    symbol: 'CONCHATORO.SN',
  },
  {
    value: 'COM7-R.BK',
    label: 'COM7-R.BK,  Com7 Public Company Limited',
    name: 'Com7 Public Company Limited',
    symbol: 'COM7-R.BK',
  },
  {
    value: 'COLO-B.CO',
    label: 'COLO-B.CO,  Coloplast A/S',
    name: 'Coloplast A/S',
    symbol: 'COLO-B.CO',
  },
  {
    value: 'COL.MC',
    label: 'COL.MC,  Inmobiliaria Colonial, SOCIMI, S.A.',
    name: 'Inmobiliaria Colonial, SOCIMI, S.A.',
    symbol: 'COL.MC',
  },
  {
    value: 'COLL.ST',
    label: 'COLL.ST,  Collector Bank AB',
    name: 'Collector Bank AB',
    symbol: 'COLL.ST',
  },
  {
    value: 'COLBUN.SN',
    label: 'COLBUN.SN,  Colbún S.A.',
    name: 'Colbún S.A.',
    symbol: 'COLBUN.SN',
  },
  {
    value: 'COIC.ST',
    label: 'COIC.ST,  Concentric AB (publ)',
    name: 'Concentric AB (publ)',
    symbol: 'COIC.ST',
  },
  {
    value: 'COGN3.SA',
    label: 'COGN3.SA,  Cogna Educação S.A.',
    name: 'Cogna Educação S.A.',
    symbol: 'COGN3.SA',
  },
  {
    value: 'COCE5.SA',
    label: 'COCE5.SA,  Companhia Energética do Ceará - COELCE',
    name: 'Companhia Energética do Ceará - COELCE',
    symbol: 'COCE5.SA',
  },
  {
    value: 'CNU.NZ',
    label: 'CNU.NZ,  Chorus Limited',
    name: 'Chorus Limited',
    symbol: 'CNU.NZ',
  },
  {
    value: 'CNHI.MI',
    label: 'CNHI.MI,  CNH Industrial N.V.',
    name: 'CNH Industrial N.V.',
    symbol: 'CNHI.MI',
  },
  {
    value: 'CND',
    label: 'CND,  Concord Acquisition Corp',
    name: 'Concord Acquisition Corp',
    symbol: 'CND',
  },
  {
    value: 'CNAF',
    label: 'CNAF,  Commercial National Financial Corporation',
    name: 'Commercial National Financial Corporation',
    symbol: 'CNAF',
  },
  {
    value: 'CMR.WA',
    label: 'CMR.WA,  Comarch S.A.',
    name: 'Comarch S.A.',
    symbol: 'CMR.WA',
  },
  {
    value: 'CMP.WA',
    label: 'CMP.WA,  Comp S.A.',
    name: 'Comp S.A.',
    symbol: 'CMP.WA',
  },
  {
    value: 'CMPC.SN',
    label: 'CMPC.SN,  Empresas CMPC S.A.',
    name: 'Empresas CMPC S.A.',
    symbol: 'CMPC.SN',
  },
  {
    value: 'CMNP.JK',
    label: 'CMNP.JK,  PT Citra Marga Nusaphala Persada Tbk',
    name: 'PT Citra Marga Nusaphala Persada Tbk',
    symbol: 'CMNP.JK',
  },
  {
    value: 'CMIG4.SA',
    label: 'CMIG4.SA,  Companhia Energética de Minas Gerais',
    name: 'Companhia Energética de Minas Gerais',
    symbol: 'CMIG4.SA',
  },
  {
    value: 'CMGGF',
    label: 'CMGGF,  Commercial International Bank (Egypt) S.A.E',
    name: 'Commercial International Bank (Egypt) S.A.E',
    symbol: 'CMGGF',
  },
  {
    value: 'CMAX',
    label: 'CMAX,  CareMax, Inc.',
    name: 'CareMax, Inc.',
    symbol: 'CMAX',
  },
  {
    value: 'CLVR',
    label: 'CLVR,  Clever Leaves Holdings Inc.',
    name: 'Clever Leaves Holdings Inc.',
    symbol: 'CLVR',
  },
  {
    value: 'CLNX.MC',
    label: 'CLNX.MC,  Cellnex Telecom, S.A.',
    name: 'Cellnex Telecom, S.A.',
    symbol: 'CLNX.MC',
  },
  {
    value: 'CLN.WA',
    label: 'CLN.WA,  Celon Pharma S.A.',
    name: 'Celon Pharma S.A.',
    symbol: 'CLN.WA',
  },
  {
    value: 'CLIS.TA',
    label: 'CLIS.TA,  Clal Insurance Enterprises Holdings Ltd.',
    name: 'Clal Insurance Enterprises Holdings Ltd.',
    symbol: 'CLIS.TA',
  },
  {
    value: 'CLIS',
    label: 'CLIS,  ClickStream Corporation',
    name: 'ClickStream Corporation',
    symbol: 'CLIS',
  },
  {
    value: 'CLEBI.IS',
    label: 'CLEBI.IS,  Çelebi Hava Servisi A.S.',
    name: 'Çelebi Hava Servisi A.S.',
    symbol: 'CLEBI.IS',
  },
  {
    value: 'CKNQP',
    label: 'CKNQP,  CoBank, ACB',
    name: 'CoBank, ACB',
    symbol: 'CKNQP',
  },
  {
    value: 'CJLU.SI',
    label: 'CJLU.SI,  NetLink NBN Trust',
    name: 'NetLink NBN Trust',
    symbol: 'CJLU.SI',
  },
  {
    value: 'CINT.ST',
    label: 'CINT.ST,  Cint Group AB (publ)',
    name: 'Cint Group AB (publ)',
    symbol: 'CINT.ST',
  },
  {
    value: 'CIMSA.IS',
    label: 'CIMSA.IS,  Çimsa Çimento Sanayi ve Ticaret A.S.',
    name: 'Çimsa Çimento Sanayi ve Ticaret A.S.',
    symbol: 'CIMSA.IS',
  },
  {
    value: 'CIE.WA',
    label: 'CIE.WA,  Ciech S.A.',
    name: 'Ciech S.A.',
    symbol: 'CIE.WA',
  },
  {
    value: 'CIEL3.SA',
    label: 'CIEL3.SA,  Cielo S.A.',
    name: 'Cielo S.A.',
    symbol: 'CIEL3.SA',
  },
  {
    value: 'CIBUS.ST',
    label: 'CIBUS.ST,  Cibus Nordic Real Estate AB (publ)',
    name: 'Cibus Nordic Real Estate AB (publ)',
    symbol: 'CIBUS.ST',
  },
  {
    value: 'CHR.CO',
    label: 'CHR.CO,  Chr. Hansen Holding A/S',
    name: 'Chr. Hansen Holding A/S',
    symbol: 'CHR.CO',
  },
  {
    value: 'CHILE.SN',
    label: 'CHILE.SN,  Banco de Chile',
    name: 'Banco de Chile',
    symbol: 'CHILE.SN',
  },
  {
    value: 'CHG-R.BK',
    label: 'CHG-R.BK,  Chularat Hospital Public Company Limited',
    name: 'Chularat Hospital Public Company Limited',
    symbol: 'CHG-R.BK',
  },
  {
    value: 'CHEMM.CO',
    label: 'CHEMM.CO,  ChemoMetec A/S',
    name: 'ChemoMetec A/S',
    symbol: 'CHEMM.CO',
  },
  {
    value: 'CHDRAUIB.MX',
    label: 'CHDRAUIB.MX,  Grupo Comercial Chedraui, S.A.B. de C.V.',
    name: 'Grupo Comercial Chedraui, S.A.B. de C.V.',
    symbol: 'CHDRAUIB.MX',
  },
  {
    value: 'CGCBV.HE',
    label: 'CGCBV.HE,  Cargotec Corporation',
    name: 'Cargotec Corporation',
    symbol: 'CGCBV.HE',
  },
  {
    value: 'CGAU',
    label: 'CGAU,  Centerra Gold Inc.',
    name: 'Centerra Gold Inc.',
    symbol: 'CGAU',
  },
  {
    value: 'CFWFF',
    label: 'CFWFF,  Calfrac Well Services Ltd.',
    name: 'Calfrac Well Services Ltd.',
    symbol: 'CFWFF',
  },
  {
    value: 'CEVI.ST',
    label: 'CEVI.ST,  CellaVision AB (publ)',
    name: 'CellaVision AB (publ)',
    symbol: 'CEVI.ST',
  },
  {
    value: 'CERAF',
    label: 'CERAF,  Ceres Acquisition Corp.',
    name: 'Ceres Acquisition Corp.',
    symbol: 'CERAF',
  },
  {
    value: 'CEN.NZ',
    label: 'CEN.NZ,  Contact Energy Limited',
    name: 'Contact Energy Limited',
    symbol: 'CEN.NZ',
  },
  {
    value: 'CENCOSUD.SN',
    label: 'CENCOSUD.SN,  Cencosud S.A.',
    name: 'Cencosud S.A.',
    symbol: 'CENCOSUD.SN',
  },
  {
    value: 'CENCOSHOPP.SN',
    label: 'CENCOSHOPP.SN,  Cencosud Shopping S.A.',
    name: 'Cencosud Shopping S.A.',
    symbol: 'CENCOSHOPP.SN',
  },
  {
    value: 'CEMEXCPO.MX',
    label: 'CEMEXCPO.MX,  CEMEX, S.A.B. de C.V.',
    name: 'CEMEX, S.A.B. de C.V.',
    symbol: 'CEMEXCPO.MX',
  },
  {
    value: 'CELU',
    label: 'CELU,  Celularity Inc.',
    name: 'Celularity Inc.',
    symbol: 'CELU',
  },
  {
    value: 'CEL.TA',
    label: 'CEL.TA,  Cellcom Israel Ltd.',
    name: 'Cellcom Israel Ltd.',
    symbol: 'CEL.TA',
  },
  {
    value: 'CEAB3.SA',
    label: 'CEAB3.SA,  C&A Modas S.A.',
    name: 'C&A Modas S.A.',
    symbol: 'CEAB3.SA',
  },
  {
    value: 'CDR.WA',
    label: 'CDR.WA,  CD Projekt S.A.',
    name: 'CD Projekt S.A.',
    symbol: 'CDR.WA',
  },
  {
    value: 'CDON.ST',
    label: 'CDON.ST,  CDON AB',
    name: 'CDON AB',
    symbol: 'CDON.ST',
  },
  {
    value: 'CCV',
    label: 'CCV,  Churchill Capital Corp V',
    name: 'Churchill Capital Corp V',
    symbol: 'CCV',
  },
  {
    value: 'CCU.SN',
    label: 'CCU.SN,  Compañía Cervecerías Unidas S.A.',
    name: 'Compañía Cervecerías Unidas S.A.',
    symbol: 'CCU.SN',
  },
  {
    value: 'CCRO3.SA',
    label: 'CCRO3.SA,  CCR S.A.',
    name: 'CCR S.A.',
    symbol: 'CCRO3.SA',
  },
  {
    value: 'CCOLA.IS',
    label: 'CCOLA.IS,  Coca-Cola Içecek Anonim Sirketi',
    name: 'Coca-Cola Içecek Anonim Sirketi',
    symbol: 'CCOLA.IS',
  },
  {
    value: 'CCHWF',
    label: 'CCHWF,  Columbia Care Inc.',
    name: 'Columbia Care Inc.',
    symbol: 'CCHWF',
  },
  {
    value: 'CCC.WA',
    label: 'CCC.WA,  CCC S.A.',
    name: 'CCC S.A.',
    symbol: 'CCC.WA',
  },
  {
    value: 'CC3.SI',
    label: 'CC3.SI,  StarHub Ltd',
    name: 'StarHub Ltd',
    symbol: 'CC3.SI',
  },
  {
    value: 'CBRAIN.CO',
    label: 'CBRAIN.CO,  cBrain A/S',
    name: 'cBrain A/S',
    symbol: 'CBRAIN.CO',
  },
  {
    value: 'CBG-R.BK',
    label: 'CBG-R.BK,  Carabao Group Public Company Limited',
    name: 'Carabao Group Public Company Limited',
    symbol: 'CBG-R.BK',
  },
  {
    value: 'CBDHF',
    label: 'CBDHF,  Hempfusion Wellness Inc.',
    name: 'Hempfusion Wellness Inc.',
    symbol: 'CBDHF',
  },
  {
    value: 'CAV1V.HE',
    label: 'CAV1V.HE,  Caverion Oyj',
    name: 'Caverion Oyj',
    symbol: 'CAV1V.HE',
  },
  {
    value: 'CATE.ST',
    label: 'CATE.ST,  Catena AB (publ)',
    name: 'Catena AB (publ)',
    symbol: 'CATE.ST',
  },
  {
    value: 'CAST.ST',
    label: 'CAST.ST,  Castellum AB (publ)',
    name: 'Castellum AB (publ)',
    symbol: 'CAST.ST',
  },
  {
    value: 'CAR.WA',
    label: 'CAR.WA,  Inter Cars S.A.',
    name: 'Inter Cars S.A.',
    symbol: 'CAR.WA',
  },
  {
    value: 'CARMX',
    label:
      'CARMX,  Columbia Adaptive Retirement 2030 Fund Institutional 3 Class',
    name: 'Columbia Adaptive Retirement 2030 Fund Institutional 3 Class',
    symbol: 'CARMX',
  },
  {
    value: 'CARL-B.CO',
    label: 'CARL-B.CO,  Carlsberg A/S',
    name: 'Carlsberg A/S',
    symbol: 'CARL-B.CO',
  },
  {
    value: 'CARE.JK',
    label: 'CARE.JK,  PT Metro Healthcare Indonesia Tbk',
    name: 'PT Metro Healthcare Indonesia Tbk',
    symbol: 'CARE.JK',
  },
  {
    value: 'CAP.SN',
    label: 'CAP.SN,  CAP S.A.',
    name: 'CAP S.A.',
    symbol: 'CAP.SN',
  },
  {
    value: 'CAPMAN.HE',
    label: 'CAPMAN.HE,  CapMan Oyj',
    name: 'CapMan Oyj',
    symbol: 'CAPMAN.HE',
  },
  {
    value: 'CANTA.ST',
    label: 'CANTA.ST,  Cantargia AB (publ)',
    name: 'Cantargia AB (publ)',
    symbol: 'CANTA.ST',
  },
  {
    value: 'CANO',
    label: 'CANO,  Cano Health, Inc.',
    name: 'Cano Health, Inc.',
    symbol: 'CANO',
  },
  {
    value: 'CAMX.ST',
    label: 'CAMX.ST,  Camurus AB (publ)',
    name: 'Camurus AB (publ)',
    symbol: 'CAMX.ST',
  },
  {
    value: 'CAMT.TA',
    label: 'CAMT.TA,  Camtek Ltd.',
    name: 'Camtek Ltd.',
    symbol: 'CAMT.TA',
  },
  {
    value: 'CAMS.NS',
    label: 'CAMS.NS,  Computer Age Management Services Limited',
    name: 'Computer Age Management Services Limited',
    symbol: 'CAMS.NS',
  },
  {
    value: 'CAML3.SA',
    label: 'CAML3.SA,  Camil Alimentos S.A.',
    name: 'Camil Alimentos S.A.',
    symbol: 'CAML3.SA',
  },
  {
    value: 'CALTX.ST',
    label: 'CALTX.ST,  Calliditas Therapeutics AB (publ)',
    name: 'Calliditas Therapeutics AB (publ)',
    symbol: 'CALTX.ST',
  },
  {
    value: 'CAI.MI',
    label: 'CAI.MI,  Cairo Communication S.p.A.',
    name: 'Cairo Communication S.p.A.',
    symbol: 'CAI.MI',
  },
  {
    value: 'CAF.MC',
    label: 'CAF.MC,  Construcciones y Auxiliar de Ferrocarriles, S.A.',
    name: 'Construcciones y Auxiliar de Ferrocarriles, S.A.',
    symbol: 'CAF.MC',
  },
  {
    value: 'CABK.MC',
    label: 'CABK.MC,  CaixaBank, S.A.',
    name: 'CaixaBank, S.A.',
    symbol: 'CABK.MC',
  },
  {
    value: 'C6L.SI',
    label: 'C6L.SI,  Singapore Airlines Limited',
    name: 'Singapore Airlines Limited',
    symbol: 'C6L.SI',
  },
  {
    value: 'C52.SI',
    label: 'C52.SI,  ComfortDelGro Corporation Limited',
    name: 'ComfortDelGro Corporation Limited',
    symbol: 'C52.SI',
  },
  {
    value: 'C38U.SI',
    label: 'C38U.SI,  CapitaLand Integrated Commercial Trust',
    name: 'CapitaLand Integrated Commercial Trust',
    symbol: 'C38U.SI',
  },
  {
    value: 'C2PU.SI',
    label: 'C2PU.SI,  Parkway Life Real Estate Investment Trust',
    name: 'Parkway Life Real Estate Investment Trust',
    symbol: 'C2PU.SI',
  },
  {
    value: 'C29.SI',
    label: 'C29.SI,  Chip Eng Seng Corporation Ltd',
    name: 'Chip Eng Seng Corporation Ltd',
    symbol: 'C29.SI',
  },
  {
    value: 'C09.SI',
    label: 'C09.SI,  City Developments Limited',
    name: 'City Developments Limited',
    symbol: 'C09.SI',
  },
  {
    value: 'BZU.MI',
    label: 'BZU.MI,  Buzzi Unicem S.p.A.',
    name: 'Buzzi Unicem S.p.A.',
    symbol: 'BZU.MI',
  },
  {
    value: 'BZ',
    label: 'BZ,  Kanzhun Limited',
    name: 'Kanzhun Limited',
    symbol: 'BZ',
  },
  {
    value: 'BYS.SW',
    label: 'BYS.SW,  Bystronic AG',
    name: 'Bystronic AG',
    symbol: 'BYS.SW',
  },
  {
    value: 'BYSD',
    label: 'BYSD,  Bayside Corporation',
    name: 'Bayside Corporation',
    symbol: 'BYSD',
  },
  {
    value: 'BYIT.L',
    label: 'BYIT.L,  Bytes Technology Group plc',
    name: 'Bytes Technology Group plc',
    symbol: 'BYIT.L',
  },
  {
    value: 'BYDDY',
    label: 'BYDDY,  BYD Company Limited',
    name: 'BYD Company Limited',
    symbol: 'BYDDY',
  },
  {
    value: 'BWCU.SI',
    label: 'BWCU.SI,  EC World Real Estate Investment Trust',
    name: 'EC World Real Estate Investment Trust',
    symbol: 'BWCU.SI',
  },
  {
    value: 'BURE.ST',
    label: 'BURE.ST,  Bure Equity AB (publ)',
    name: 'Bure Equity AB (publ)',
    symbol: 'BURE.ST',
  },
  {
    value: 'BUOU.SI',
    label: 'BUOU.SI,  Frasers Logistics & Commercial Trust',
    name: 'Frasers Logistics & Commercial Trust',
    symbol: 'BUOU.SI',
  },
  {
    value: 'BUMI.JK',
    label: 'BUMI.JK,  PT Bumi Resources Tbk',
    name: 'PT Bumi Resources Tbk',
    symbol: 'BUMI.JK',
  },
  {
    value: 'BULTEN.ST',
    label: 'BULTEN.ST,  Bulten AB (publ)',
    name: 'Bulten AB (publ)',
    symbol: 'BULTEN.ST',
  },
  {
    value: 'BULL.JK',
    label: 'BULL.JK,  PT Buana Lintas Lautan Tbk',
    name: 'PT Buana Lintas Lautan Tbk',
    symbol: 'BULL.JK',
  },
  {
    value: 'BUFAB.ST',
    label: 'BUFAB.ST,  Bufab AB (publ)',
    name: 'Bufab AB (publ)',
    symbol: 'BUFAB.ST',
  },
  {
    value: 'BTWN',
    label: 'BTWN,  Bridgetown Holdings Limited',
    name: 'Bridgetown Holdings Limited',
    symbol: 'BTWN',
  },
  {
    value: 'BTS-R.BK',
    label: 'BTS-R.BK,  BTS Group Holdings Public Company Limited',
    name: 'BTS Group Holdings Public Company Limited',
    symbol: 'BTS-R.BK',
  },
  {
    value: 'BTPS.JK',
    label: 'BTPS.JK,  PT Bank BTPN Syariah Tbk',
    name: 'PT Bank BTPN Syariah Tbk',
    symbol: 'BTPS.JK',
  },
  {
    value: 'BSS.MI',
    label: 'BSS.MI,  Biesse S.p.A.',
    name: 'Biesse S.p.A.',
    symbol: 'BSS.MI',
  },
  {
    value: 'BSMXB.MX',
    label:
      'BSMXB.MX,  Banco Santander México, S.A., Institución de Banca Múltiple, Grupo Financiero Santander México',
    name: 'Banco Santander México, S.A., Institución de Banca Múltiple, Grupo Financiero Santander México',
    symbol: 'BSMXB.MX',
  },
  {
    value: 'BSL.SI',
    label: 'BSL.SI,  Raffles Medical Group Ltd',
    name: 'Raffles Medical Group Ltd',
    symbol: 'BSL.SI',
  },
  {
    value: 'BSDE.JK',
    label: 'BSDE.JK,  PT Bumi Serpong Damai Tbk',
    name: 'PT Bumi Serpong Damai Tbk',
    symbol: 'BSDE.JK',
  },
  {
    value: 'BSANTANDER.SN',
    label: 'BSANTANDER.SN,  Banco Santander-Chile',
    name: 'Banco Santander-Chile',
    symbol: 'BSANTANDER.SN',
  },
  {
    value: 'BS6.SI',
    label: 'BS6.SI,  Yangzijiang Shipbuilding (Holdings) Ltd.',
    name: 'Yangzijiang Shipbuilding (Holdings) Ltd.',
    symbol: 'BS6.SI',
  },
  {
    value: 'BRW',
    label: 'BRW,  Saba Capital Income & Opportunities Fund',
    name: 'Saba Capital Income & Opportunities Fund',
    symbol: 'BRW',
  },
  {
    value: 'BRS.WA',
    label: 'BRS.WA,  Boryszew S.A.',
    name: 'Boryszew S.A.',
    symbol: 'BRS.WA',
  },
  {
    value: 'BRSR6.SA',
    label: 'BRSR6.SA,  Banco do Estado do Rio Grande do Sul S.A.',
    name: 'Banco do Estado do Rio Grande do Sul S.A.',
    symbol: 'BRSR6.SA',
  },
  {
    value: 'BRSP',
    label: 'BRSP,  BrightSpire Capital, Inc.',
    name: 'BrightSpire Capital, Inc.',
    symbol: 'BRSP',
  },
  {
    value: 'BRSAN.IS',
    label: 'BRSAN.IS,  Borusan Mannesmann Boru Sanayi ve Ticaret A.S.',
    name: 'Borusan Mannesmann Boru Sanayi ve Ticaret A.S.',
    symbol: 'BRSAN.IS',
  },
  {
    value: 'BRPT.JK',
    label: 'BRPT.JK,  PT Barito Pacific Tbk',
    name: 'PT Barito Pacific Tbk',
    symbol: 'BRPT.JK',
  },
  {
    value: 'BRPR3.SA',
    label: 'BRPR3.SA,  BR Properties S.A.',
    name: 'BR Properties S.A.',
    symbol: 'BRPR3.SA',
  },
  {
    value: 'BRML3.SA',
    label: 'BRML3.SA,  BR Malls Participações S.A.',
    name: 'BR Malls Participações S.A.',
    symbol: 'BRML3.SA',
  },
  {
    value: 'BRKM5.SA',
    label: 'BRKM5.SA,  Braskem S.A.',
    name: 'Braskem S.A.',
    symbol: 'BRKM5.SA',
  },
  {
    value: 'BRIS.JK',
    label: 'BRIS.JK,  PT Bank Syariah Indonesia Tbk',
    name: 'PT Bank Syariah Indonesia Tbk',
    symbol: 'BRIS.JK',
  },
  {
    value: 'BRISA.IS',
    label: 'BRISA.IS,  Brisa Bridgestone Sabanci Lastik Sanayi ve Ticaret A.S.',
    name: 'Brisa Bridgestone Sabanci Lastik Sanayi ve Ticaret A.S.',
    symbol: 'BRISA.IS',
  },
  {
    value: 'BRFS3.SA',
    label: 'BRFS3.SA,  BRF S.A.',
    name: 'BRF S.A.',
    symbol: 'BRFS3.SA',
  },
  {
    value: 'BRES.QA',
    label: 'BRES.QA,  Barwa Real Estate Company Q.P.S.C.',
    name: 'Barwa Real Estate Company Q.P.S.C.',
    symbol: 'BRES.QA',
  },
  {
    value: 'BRAV.ST',
    label: 'BRAV.ST,  Bravida Holding AB (publ)',
    name: 'Bravida Holding AB (publ)',
    symbol: 'BRAV.ST',
  },
  {
    value: 'BRAV',
    label: 'BRAV,  BRAVADA International Ltd',
    name: 'BRAVADA International Ltd',
    symbol: 'BRAV',
  },
  {
    value: 'BRAP4.SA',
    label: 'BRAP4.SA,  Bradespar S.A.',
    name: 'Bradespar S.A.',
    symbol: 'BRAP4.SA',
  },
  {
    value: 'BRAG',
    label: 'BRAG,  Bragg Gaming Group Inc.',
    name: 'Bragg Gaming Group Inc.',
    symbol: 'BRAG',
  },
  {
    value: 'BPYPM',
    label: 'BPYPM,  Brookfield Property Preferred L.P.',
    name: 'Brookfield Property Preferred L.P.',
    symbol: 'BPYPM',
  },
  {
    value: 'BPP-R.BK',
    label: 'BPP-R.BK,  Banpu Power Public Company Limited',
    name: 'Banpu Power Public Company Limited',
    symbol: 'BPP-R.BK',
  },
  {
    value: 'BPAN4.SA',
    label: 'BPAN4.SA,  Banco Pan S.A.',
    name: 'Banco Pan S.A.',
    symbol: 'BPAN4.SA',
  },
  {
    value: 'BPAC11.SA',
    label: 'BPAC11.SA,  Banco BTG Pactual S.A.',
    name: 'Banco BTG Pactual S.A.',
    symbol: 'BPAC11.SA',
  },
  {
    value: 'BOUV.OL',
    label: 'BOUV.OL,  Bouvet ASA',
    name: 'Bouvet ASA',
    symbol: 'BOUV.OL',
  },
  {
    value: 'BOS.WA',
    label: 'BOS.WA,  Bank Ochrony Srodowiska S.A.',
    name: 'Bank Ochrony Srodowiska S.A.',
    symbol: 'BOS.WA',
  },
  {
    value: 'BORR.OL',
    label: 'BORR.OL,  Borr Drilling Limited',
    name: 'Borr Drilling Limited',
    symbol: 'BORR.OL',
  },
  {
    value: 'BONHR.OL',
    label: 'BONHR.OL,  Bonheur ASA',
    name: 'Bonheur ASA',
    symbol: 'BONHR.OL',
  },
  {
    value: 'BONEX.ST',
    label: 'BONEX.ST,  Bonesupport Holding AB (publ)',
    name: 'Bonesupport Holding AB (publ)',
    symbol: 'BONEX.ST',
  },
  {
    value: 'BOL.ST',
    label: 'BOL.ST,  Boliden AB (publ)',
    name: 'Boliden AB (publ)',
    symbol: 'BOL.ST',
  },
  {
    value: 'BOLSAA.MX',
    label: 'BOLSAA.MX,  Bolsa Mexicana de Valores, S.A.B. de C.V.',
    name: 'Bolsa Mexicana de Valores, S.A.B. de C.V.',
    symbol: 'BOLSAA.MX',
  },
  {
    value: 'BOGA.JK',
    label: 'BOGA.JK,  PT Bintang Oto Global Tbk',
    name: 'PT Bintang Oto Global Tbk',
    symbol: 'BOGA.JK',
  },
  {
    value: 'BODY',
    label: 'BODY,  The Beachbody Company, Inc.',
    name: 'The Beachbody Company, Inc.',
    symbol: 'BODY',
  },
  {
    value: 'BO.CO',
    label: 'BO.CO,  Bang & Olufsen a/s',
    name: 'Bang & Olufsen a/s',
    symbol: 'BO.CO',
  },
  {
    value: 'BOAS3.SA',
    label: 'BOAS3.SA,  Boa Vista Serviços S.A.',
    name: 'Boa Vista Serviços S.A.',
    symbol: 'BOAS3.SA',
  },
  {
    value: 'BNP.WA',
    label: 'BNP.WA,  BNP Paribas Bank Polska S.A.',
    name: 'BNP Paribas Bank Polska S.A.',
    symbol: 'BNP.WA',
  },
  {
    value: 'BNLI.JK',
    label: 'BNLI.JK,  PT Bank Permata Tbk',
    name: 'PT Bank Permata Tbk',
    symbol: 'BNLI.JK',
  },
  {
    value: 'BNGA.JK',
    label: 'BNGA.JK,  PT Bank CIMB Niaga Tbk',
    name: 'PT Bank CIMB Niaga Tbk',
    symbol: 'BNGA.JK',
  },
  {
    value: 'BN4.SI',
    label: 'BN4.SI,  Keppel Corporation Limited',
    name: 'Keppel Corporation Limited',
    symbol: 'BN4.SI',
  },
  {
    value: 'BMTR.JK',
    label: 'BMTR.JK,  PT Global Mediacom Tbk',
    name: 'PT Global Mediacom Tbk',
    symbol: 'BMTR.JK',
  },
  {
    value: 'BMRI.JK',
    label: 'BMRI.JK,  PT Bank Mandiri (Persero) Tbk',
    name: 'PT Bank Mandiri (Persero) Tbk',
    symbol: 'BMRI.JK',
  },
  {
    value: 'BMPS.MI',
    label: 'BMPS.MI,  Banca Monte dei Paschi di Siena S.p.A.',
    name: 'Banca Monte dei Paschi di Siena S.p.A.',
    symbol: 'BMPS.MI',
  },
  {
    value: 'BML.WA',
    label: 'BML.WA,  BIOMED-LUBLIN Wytwórnia Surowic i Szczepionek S.A.',
    name: 'BIOMED-LUBLIN Wytwórnia Surowic i Szczepionek S.A.',
    symbol: 'BML.WA',
  },
  {
    value: 'BMGB4.SA',
    label: 'BMGB4.SA,  Banco BMG S.A.',
    name: 'Banco BMG S.A.',
    symbol: 'BMGB4.SA',
  },
  {
    value: 'BMED.MI',
    label: 'BMED.MI,  Banca Mediolanum S.p.A.',
    name: 'Banca Mediolanum S.p.A.',
    symbol: 'BMED.MI',
  },
  {
    value: 'BMAX.ST',
    label: 'BMAX.ST,  Byggmax Group AB (publ)',
    name: 'Byggmax Group AB (publ)',
    symbol: 'BMAX.ST',
  },
  {
    value: 'BLTA.JK',
    label: 'BLTA.JK,  PT Berlian Laju Tanker Tbk',
    name: 'PT Berlian Laju Tanker Tbk',
    symbol: 'BLTA.JK',
  },
  {
    value: 'BLSR.TA',
    label: 'BLSR.TA,  Blue Square Real Estate Ltd',
    name: 'Blue Square Real Estate Ltd',
    symbol: 'BLSR.TA',
  },
  {
    value: 'BLND',
    label: 'BLND,  Blend Labs, Inc.',
    name: 'Blend Labs, Inc.',
    symbol: 'BLND',
  },
  {
    value: 'BLAND-R.BK',
    label: 'BLAND-R.BK,  Bangkok Land Public Company Limited',
    name: 'Bangkok Land Public Company Limited',
    symbol: 'BLAND-R.BK',
  },
  {
    value: 'BKT.MC',
    label: 'BKT.MC,  Bankinter, S.A.',
    name: 'Bankinter, S.A.',
    symbol: 'BKT.MC',
  },
  {
    value: 'BKSL.JK',
    label: 'BKSL.JK,  PT Sentul City Tbk',
    name: 'PT Sentul City Tbk',
    symbol: 'BKSL.JK',
  },
  {
    value: 'BJTM.JK',
    label: 'BJTM.JK,  PT Bank Pembangunan Daerah Jawa Timur Tbk',
    name: 'PT Bank Pembangunan Daerah Jawa Timur Tbk',
    symbol: 'BJTM.JK',
  },
  {
    value: 'BJC-R.BK',
    label: 'BJC-R.BK,  Berli Jucker Public Company Limited',
    name: 'Berli Jucker Public Company Limited',
    symbol: 'BJC-R.BK',
  },
  {
    value: 'BJBR.JK',
    label: 'BJBR.JK,  PT Bank Pembangunan Daerah Jawa Barat dan Banten Tbk',
    name: 'PT Bank Pembangunan Daerah Jawa Barat dan Banten Tbk',
    symbol: 'BJBR.JK',
  },
  {
    value: 'BITTI.HE',
    label: 'BITTI.HE,  Bittium Oyj',
    name: 'Bittium Oyj',
    symbol: 'BITTI.HE',
  },
  {
    value: 'BIO.WA',
    label: 'BIO.WA,  BIOTON S.A.',
    name: 'BIOTON S.A.',
    symbol: 'BIO.WA',
  },
  {
    value: 'BIOT.ST',
    label: 'BIOT.ST,  Biotage AB (publ)',
    name: 'Biotage AB (publ)',
    symbol: 'BIOT.ST',
  },
  {
    value: 'BIMBOA.MX',
    label: 'BIMBOA.MX,  Grupo Bimbo, S.A.B. de C.V.',
    name: 'Grupo Bimbo, S.A.B. de C.V.',
    symbol: 'BIMBOA.MX',
  },
  {
    value: 'BIMAS.IS',
    label: 'BIMAS.IS,  BIM Birlesik Magazalar A.S.',
    name: 'BIM Birlesik Magazalar A.S.',
    symbol: 'BIMAS.IS',
  },
  {
    value: 'BILL.ST',
    label: 'BILL.ST,  Billerud AB (publ)',
    name: 'Billerud AB (publ)',
    symbol: 'BILL.ST',
  },
  {
    value: 'BIG.TA',
    label: 'BIG.TA,  BIG Shopping Centers Ltd',
    name: 'BIG Shopping Centers Ltd',
    symbol: 'BIG.TA',
  },
  {
    value: 'BIGG',
    label: 'BIGG,  Big Tree Group, Inc.',
    name: 'Big Tree Group, Inc.',
    symbol: 'BIGG',
  },
  {
    value: 'BICO.ST',
    label: 'BICO.ST,  BICO Group AB (publ)',
    name: 'BICO Group AB (publ)',
    symbol: 'BICO.ST',
  },
  {
    value: 'BHW.WA',
    label: 'BHW.WA,  Bank Handlowy w Warszawie S.A.',
    name: 'Bank Handlowy w Warszawie S.A.',
    symbol: 'BHW.WA',
  },
  {
    value: 'BH-R.BK',
    label: 'BH-R.BK,  Bumrungrad Hospital Public Company Limited',
    name: 'Bumrungrad Hospital Public Company Limited',
    symbol: 'BH-R.BK',
  },
  {
    value: 'BHFAN',
    label: 'BHFAN,  Brighthouse Financial, Inc.',
    name: 'Brighthouse Financial, Inc.',
    symbol: 'BHFAN',
  },
  {
    value: 'BGRIM-R.BK',
    label: 'BGRIM-R.BK,  B.Grimm Power Public Company Limited',
    name: 'B.Grimm Power Public Company Limited',
    symbol: 'BGRIM-R.BK',
  },
  {
    value: 'BGN.MI',
    label: 'BGN.MI,  Banca Generali S.p.A.',
    name: 'Banca Generali S.p.A.',
    symbol: 'BGN.MI',
  },
  {
    value: 'BFT.WA',
    label: 'BFT.WA,  Benefit Systems S.A.',
    name: 'Benefit Systems S.A.',
    symbol: 'BFT.WA',
  },
  {
    value: 'BFIN.JK',
    label: 'BFIN.JK,  PT BFI Finance Indonesia Tbk',
    name: 'PT BFI Finance Indonesia Tbk',
    symbol: 'BFIN.JK',
  },
  {
    value: 'BFF.MI',
    label: 'BFF.MI,  BFF Bank S.p.A.',
    name: 'BFF Bank S.p.A.',
    symbol: 'BFF.MI',
  },
  {
    value: 'BEZQ.TA',
    label: 'BEZQ.TA,  Bezeq The Israel Telecommunication Corp. Ltd',
    name: 'Bezeq The Israel Telecommunication Corp. Ltd',
    symbol: 'BEZQ.TA',
  },
  {
    value: 'BEWI.OL',
    label: 'BEWI.OL,  BEWi ASA',
    name: 'BEWi ASA',
    symbol: 'BEWI.OL',
  },
  {
    value: 'BETCO.ST',
    label: 'BETCO.ST,  Better Collective A/S',
    name: 'Better Collective A/S',
    symbol: 'BETCO.ST',
  },
  {
    value: 'BEST.JK',
    label: 'BEST.JK,  PT Bekasi Fajar Industrial Estate Tbk',
    name: 'PT Bekasi Fajar Industrial Estate Tbk',
    symbol: 'BEST.JK',
  },
  {
    value: 'BERK',
    label: 'BERK,  Berkshire Bancorp Inc.',
    name: 'Berkshire Bancorp Inc.',
    symbol: 'BERK',
  },
  {
    value: 'BERA.IS',
    label: 'BERA.IS,  Bera Holding A.S.',
    name: 'Bera Holding A.S.',
    symbol: 'BERA.IS',
  },
  {
    value: 'BEM-R.BK',
    label: 'BEM-R.BK,  Bangkok Expressway and Metro Public Company Limited',
    name: 'Bangkok Expressway and Metro Public Company Limited',
    symbol: 'BEM-R.BK',
  },
  {
    value: 'BEEF3.SA',
    label: 'BEEF3.SA,  Minerva S.A.',
    name: 'Minerva S.A.',
    symbol: 'BEEF3.SA',
  },
  {
    value: 'BDX.WA',
    label: 'BDX.WA,  Budimex SA',
    name: 'Budimex SA',
    symbol: 'BDX.WA',
  },
  {
    value: 'BDMS-R.BK',
    label: 'BDMS-R.BK,  Bangkok Dusit Medical Services Public Company Limited',
    name: 'Bangkok Dusit Medical Services Public Company Limited',
    symbol: 'BDMS-R.BK',
  },
  {
    value: 'BDMN.JK',
    label: 'BDMN.JK,  PT Bank Danamon Indonesia Tbk',
    name: 'PT Bank Danamon Indonesia Tbk',
    symbol: 'BDMN.JK',
  },
  {
    value: 'BDGI.TO',
    label: 'BDGI.TO,  Badger Infrastructure Solutions Ltd.',
    name: 'Badger Infrastructure Solutions Ltd.',
    symbol: 'BDGI.TO',
  },
  {
    value: 'BCPG-R.BK',
    label: 'BCPG-R.BK,  BCPG Public Company Limited',
    name: 'BCPG Public Company Limited',
    symbol: 'BCPG-R.BK',
  },
  {
    value: 'BCI.SN',
    label: 'BCI.SN,  Banco de Crédito e Inversiones',
    name: 'Banco de Crédito e Inversiones',
    symbol: 'BCI.SN',
  },
  {
    value: 'BCH-R.BK',
    label: 'BCH-R.BK,  Bangkok Chain Hospital Public Company Limited',
    name: 'Bangkok Chain Hospital Public Company Limited',
    symbol: 'BCH-R.BK',
  },
  {
    value: 'BBVA.MC',
    label: 'BBVA.MC,  Banco Bilbao Vizcaya Argentaria, S.A.',
    name: 'Banco Bilbao Vizcaya Argentaria, S.A.',
    symbol: 'BBVA.MC',
  },
  {
    value: 'BBTN.JK',
    label: 'BBTN.JK,  PT Bank Tabungan Negara (Persero) Tbk',
    name: 'PT Bank Tabungan Negara (Persero) Tbk',
    symbol: 'BBTN.JK',
  },
  {
    value: 'BBSE3.SA',
    label: 'BBSE3.SA,  BB Seguridade Participações S.A.',
    name: 'BB Seguridade Participações S.A.',
    symbol: 'BBSE3.SA',
  },
  {
    value: 'BBRI.JK',
    label: 'BBRI.JK,  PT Bank Rakyat Indonesia (Persero) Tbk',
    name: 'PT Bank Rakyat Indonesia (Persero) Tbk',
    symbol: 'BBRI.JK',
  },
  {
    value: 'BBNI.JK',
    label: 'BBNI.JK,  PT Bank Negara Indonesia (Persero) Tbk',
    name: 'PT Bank Negara Indonesia (Persero) Tbk',
    symbol: 'BBNI.JK',
  },
  {
    value: 'BBL-R.BK',
    label: 'BBL-R.BK,  Bangkok Bank Public Company Limited',
    name: 'Bangkok Bank Public Company Limited',
    symbol: 'BBL-R.BK',
  },
  {
    value: 'BBKP.JK',
    label: 'BBKP.JK,  PT Bank KB Bukopin Tbk',
    name: 'PT Bank KB Bukopin Tbk',
    symbol: 'BBKP.JK',
  },
  {
    value: 'BBDC4.SA',
    label: 'BBDC4.SA,  Banco Bradesco S.A.',
    name: 'Banco Bradesco S.A.',
    symbol: 'BBDC4.SA',
  },
  {
    value: 'BBDC3.SA',
    label: 'BBDC3.SA,  Banco Bradesco S.A.',
    name: 'Banco Bradesco S.A.',
    symbol: 'BBDC3.SA',
  },
  {
    value: 'BBCA.JK',
    label: 'BBCA.JK,  PT Bank Central Asia Tbk',
    name: 'PT Bank Central Asia Tbk',
    symbol: 'BBCA.JK',
  },
  {
    value: 'BBAS3.SA',
    label: 'BBAS3.SA,  Banco do Brasil S.A.',
    name: 'Banco do Brasil S.A.',
    symbol: 'BBAS3.SA',
  },
  {
    value: 'BBAJIOO.MX',
    label: 'BBAJIOO.MX,  Banco del Bajío, S.A., Institución de Banca Múltiple',
    name: 'Banco del Bajío, S.A., Institución de Banca Múltiple',
    symbol: 'BBAJIOO.MX',
  },
  {
    value: 'BAYRY',
    label: 'BAYRY,  Bayer Aktiengesellschaft',
    name: 'Bayer Aktiengesellschaft',
    symbol: 'BAYRY',
  },
  {
    value: 'BAVA.CO',
    label: 'BAVA.CO,  Bavarian Nordic A/S',
    name: 'Bavarian Nordic A/S',
    symbol: 'BAVA.CO',
  },
  {
    value: 'BAMR.TO',
    label: 'BAMR.TO,  Brookfield Asset Management Reinsurance Partners Ltd.',
    name: 'Brookfield Asset Management Reinsurance Partners Ltd.',
    symbol: 'BAMR.TO',
  },
  {
    value: 'BAM-R.BK',
    label:
      'BAM-R.BK,  Bangkok Commercial Asset Management Public Company Limited',
    name: 'Bangkok Commercial Asset Management Public Company Limited',
    symbol: 'BAM-R.BK',
  },
  {
    value: 'BAMR',
    label: 'BAMR,  Brookfield Asset Management Reinsurance Partners Ltd.',
    name: 'Brookfield Asset Management Reinsurance Partners Ltd.',
    symbol: 'BAMR',
  },
  {
    value: 'BAMI.MI',
    label: 'BAMI.MI,  Banco BPM S.p.A.',
    name: 'Banco BPM S.p.A.',
    symbol: 'BAMI.MI',
  },
  {
    value: 'BAGFS.IS',
    label: 'BAGFS.IS,  Bagfas Bandirma Gubre Fabrikalari A.S.',
    name: 'Bagfas Bandirma Gubre Fabrikalari A.S.',
    symbol: 'BAGFS.IS',
  },
  {
    value: 'BACHOCOB.MX',
    label: 'BACHOCOB.MX,  Industrias Bachoco, S.A.B. de C.V.',
    name: 'Industrias Bachoco, S.A.B. de C.V.',
    symbol: 'BACHOCOB.MX',
  },
  {
    value: '000061.SZ',
    label: '000061.SZ,  Shenzhen Agricultural Products Group Co., Ltd.',
    name: 'Shenzhen Agricultural Products Group Co., Ltd.',
    symbol: '000061.SZ',
  },
  {
    value: '000062.SZ',
    label: '000062.SZ,  Shenzhen Huaqiang Industry Co., Ltd.',
    name: 'Shenzhen Huaqiang Industry Co., Ltd.',
    symbol: '000062.SZ',
  },
  {
    value: '000150.KS',
    label: '000150.KS,  Doosan Corporation',
    name: 'Doosan Corporation',
    symbol: '000150.KS',
  },
  {
    value: '000488.SZ',
    label: '000488.SZ,  Shandong Chenming Paper Holdings Limited',
    name: 'Shandong Chenming Paper Holdings Limited',
    symbol: '000488.SZ',
  },
  {
    value: '000517.SZ',
    label: '000517.SZ,  Rongan Property Co.,Ltd.',
    name: 'Rongan Property Co.,Ltd.',
    symbol: '000517.SZ',
  },
  {
    value: '000815.KS',
    label: '000815.KS,  Samsung Fire & Marine Insurance Co., Ltd.',
    name: 'Samsung Fire & Marine Insurance Co., Ltd.',
    symbol: '000815.KS',
  },
  {
    value: '000997.SZ',
    label: '000997.SZ,  Newland Digital Technology Co.,Ltd.',
    name: 'Newland Digital Technology Co.,Ltd.',
    symbol: '000997.SZ',
  },
  {
    value: '001250.KS',
    label: '001250.KS,  GS Global Corp.',
    name: 'GS Global Corp.',
    symbol: '001250.KS',
  },
  {
    value: '002028.SZ',
    label: '002028.SZ,  Sieyuan Electric Co., Ltd.',
    name: 'Sieyuan Electric Co., Ltd.',
    symbol: '002028.SZ',
  },
  {
    value: '002036.SZ',
    label: '002036.SZ,  LianChuang Electronic Technology Co.,Ltd',
    name: 'LianChuang Electronic Technology Co.,Ltd',
    symbol: '002036.SZ',
  },
  {
    value: '002092.SZ',
    label: '002092.SZ,  Xinjiang Zhongtai Chemical Co., Ltd.',
    name: 'Xinjiang Zhongtai Chemical Co., Ltd.',
    symbol: '002092.SZ',
  },
  {
    value: '002183.SZ',
    label: '002183.SZ,  Eternal Asia Supply Chain Management Ltd.',
    name: 'Eternal Asia Supply Chain Management Ltd.',
    symbol: '002183.SZ',
  },
  {
    value: '002195.SZ',
    label: '002195.SZ,  Shanghai 2345 Network Holding Group Co., Ltd.',
    name: 'Shanghai 2345 Network Holding Group Co., Ltd.',
    symbol: '002195.SZ',
  },
  {
    value: '002268.SZ',
    label: '002268.SZ,  Westone Information Industry Inc.',
    name: 'Westone Information Industry Inc.',
    symbol: '002268.SZ',
  },
  {
    value: '002273.SZ',
    label: '002273.SZ,  Zhejiang Crystal-Optech Co., Ltd',
    name: 'Zhejiang Crystal-Optech Co., Ltd',
    symbol: '002273.SZ',
  },
  {
    value: '002368.SZ',
    label: '002368.SZ,  Taiji Computer Corporation Limited',
    name: 'Taiji Computer Corporation Limited',
    symbol: '002368.SZ',
  },
  {
    value: '002497.SZ',
    label: '002497.SZ,  Sichuan Yahua Industrial Group Co., Ltd.',
    name: 'Sichuan Yahua Industrial Group Co., Ltd.',
    symbol: '002497.SZ',
  },
  {
    value: '002498.SZ',
    label: '002498.SZ,  Qingdao Hanhe Cable Co.,Ltd',
    name: 'Qingdao Hanhe Cable Co.,Ltd',
    symbol: '002498.SZ',
  },
  {
    value: '002532.SZ',
    label: '002532.SZ,  Tianshan Aluminum Group Co.,Ltd',
    name: 'Tianshan Aluminum Group Co.,Ltd',
    symbol: '002532.SZ',
  },
  {
    value: '002635.SZ',
    label: '002635.SZ,  Suzhou Anjie Technology Co., Ltd.',
    name: 'Suzhou Anjie Technology Co., Ltd.',
    symbol: '002635.SZ',
  },
  {
    value: '003545.KS',
    label: '003545.KS,  Daishin Securities Co., Ltd.',
    name: 'Daishin Securities Co., Ltd.',
    symbol: '003545.KS',
  },
  {
    value: '003816.SZ',
    label: '003816.SZ,  CGN Power Co., Ltd.',
    name: 'CGN Power Co., Ltd.',
    symbol: '003816.SZ',
  },
  {
    value: '005385.KS',
    label: '005385.KS,  Hyundai Motor Company',
    name: 'Hyundai Motor Company',
    symbol: '005385.KS',
  },
  {
    value: '005387.KS',
    label: '005387.KS,  Hyundai Motor Company',
    name: 'Hyundai Motor Company',
    symbol: '005387.KS',
  },
  {
    value: '005935.KS',
    label: '005935.KS,  Samsung Electronics Co., Ltd.',
    name: 'Samsung Electronics Co., Ltd.',
    symbol: '005935.KS',
  },
  {
    value: '006405.KS',
    label: '006405.KS,  Samsung SDI Co., Ltd.',
    name: 'Samsung SDI Co., Ltd.',
    symbol: '006405.KS',
  },
  {
    value: '006840.KS',
    label: '006840.KS,  AK Holdings, Inc.',
    name: 'AK Holdings, Inc.',
    symbol: '006840.KS',
  },
  {
    value: '007860.KS',
    label: '007860.KS,  Seoyon Co., Ltd.',
    name: 'Seoyon Co., Ltd.',
    symbol: '007860.KS',
  },
  {
    value: '009155.KS',
    label: '009155.KS,  Samsung Electro-Mechanics Co., Ltd.',
    name: 'Samsung Electro-Mechanics Co., Ltd.',
    symbol: '009155.KS',
  },
  {
    value: '009900.KS',
    label: '009900.KS,  Myoung Shin Industrial Co.,Ltd',
    name: 'Myoung Shin Industrial Co.,Ltd',
    symbol: '009900.KS',
  },
  {
    value: '011690.KS',
    label: '011690.KS,  Y2 Solution Co., Ltd',
    name: 'Y2 Solution Co., Ltd',
    symbol: '011690.KS',
  },
  {
    value: '0164.HK',
    label: '0164.HK,  China Baoli Technologies Holdings Limited',
    name: 'China Baoli Technologies Holdings Limited',
    symbol: '0164.HK',
  },
  {
    value: '026890.KS',
    label: '026890.KS,  STIC Investments, Inc.',
    name: 'STIC Investments, Inc.',
    symbol: '026890.KS',
  },
  {
    value: '039130.KS',
    label: '039130.KS,  Hanatour Service Inc.',
    name: 'Hanatour Service Inc.',
    symbol: '039130.KS',
  },
  {
    value: '051915.KS',
    label: '051915.KS,  LG Chem, Ltd.',
    name: 'LG Chem, Ltd.',
    symbol: '051915.KS',
  },
  {
    value: '056190.KQ',
    label: '056190.KQ,  SFA Engineering Corporation',
    name: 'SFA Engineering Corporation',
    symbol: '056190.KQ',
  },
  {
    value: '057050.KS',
    label: '057050.KS,  Hyundai Home Shopping Network Corporation',
    name: 'Hyundai Home Shopping Network Corporation',
    symbol: '057050.KS',
  },
  {
    value: '058470.KQ',
    label: '058470.KQ,  LEENO Industrial Inc.',
    name: 'LEENO Industrial Inc.',
    symbol: '058470.KQ',
  },
  {
    value: '058820.KQ',
    label: '058820.KQ,  CMG Pharmaceutical Co., Ltd.',
    name: 'CMG Pharmaceutical Co., Ltd.',
    symbol: '058820.KQ',
  },
  {
    value: '059090.KQ',
    label: '059090.KQ,  MiCo Ltd.',
    name: 'MiCo Ltd.',
    symbol: '059090.KQ',
  },
  {
    value: '060150.KQ',
    label: '060150.KQ,  INSUN Environmental New Technology Co., Ltd.',
    name: 'INSUN Environmental New Technology Co., Ltd.',
    symbol: '060150.KQ',
  },
  {
    value: '060250.KQ',
    label: '060250.KQ,  NHN KCP Corp.',
    name: 'NHN KCP Corp.',
    symbol: '060250.KQ',
  },
  {
    value: '060720.KQ',
    label: '060720.KQ,  KHVATEC Co.,Ltd.',
    name: 'KHVATEC Co.,Ltd.',
    symbol: '060720.KQ',
  },
  {
    value: '060980.KS',
    label: '060980.KS,  HL Holdings Corporation',
    name: 'HL Holdings Corporation',
    symbol: '060980.KS',
  },
  {
    value: '064350.KS',
    label: '064350.KS,  Hyundai Rotem Company',
    name: 'Hyundai Rotem Company',
    symbol: '064350.KS',
  },
  {
    value: '064550.KQ',
    label: '064550.KQ,  Bioneer Corporation',
    name: 'Bioneer Corporation',
    symbol: '064550.KQ',
  },
  {
    value: '064760.KQ',
    label: '064760.KQ,  Tokai Carbon Korea Co., Ltd.',
    name: 'Tokai Carbon Korea Co., Ltd.',
    symbol: '064760.KQ',
  },
  {
    value: '064960.KS',
    label: '064960.KS,  SNT Motiv Co., Ltd.',
    name: 'SNT Motiv Co., Ltd.',
    symbol: '064960.KS',
  },
  {
    value: '065660.KQ',
    label: '065660.KQ,  Anterogen.Co.,Ltd.',
    name: 'Anterogen.Co.,Ltd.',
    symbol: '065660.KQ',
  },
  {
    value: '066570.KS',
    label: '066570.KS,  LG Electronics Inc.',
    name: 'LG Electronics Inc.',
    symbol: '066570.KS',
  },
  {
    value: '066575.KS',
    label: '066575.KS,  LG Electronics Inc.',
    name: 'LG Electronics Inc.',
    symbol: '066575.KS',
  },
  {
    value: '066970.KQ',
    label: '066970.KQ,  L&F Co., Ltd.',
    name: 'L&F Co., Ltd.',
    symbol: '066970.KQ',
  },
  {
    value: '067160.KQ',
    label: '067160.KQ,  AfreecaTV Co., Ltd.',
    name: 'AfreecaTV Co., Ltd.',
    symbol: '067160.KQ',
  },
  {
    value: '067630.KQ',
    label: '067630.KQ,  HLB Life Science Co., Ltd.',
    name: 'HLB Life Science Co., Ltd.',
    symbol: '067630.KQ',
  },
  {
    value: '068240.KQ',
    label: '068240.KQ,  Dawonsys Co.,Ltd.',
    name: 'Dawonsys Co.,Ltd.',
    symbol: '068240.KQ',
  },
  {
    value: '068270.KS',
    label: '068270.KS,  Celltrion, Inc.',
    name: 'Celltrion, Inc.',
    symbol: '068270.KS',
  },
  {
    value: '068760.KQ',
    label: '068760.KQ,  Celltrion Pharm, Inc.',
    name: 'Celltrion Pharm, Inc.',
    symbol: '068760.KQ',
  },
  {
    value: '069080.KQ',
    label: '069080.KQ,  Webzen Inc.',
    name: 'Webzen Inc.',
    symbol: '069080.KQ',
  },
  {
    value: '069260.KS',
    label: '069260.KS,  TKG Huchems Co., Ltd.',
    name: 'TKG Huchems Co., Ltd.',
    symbol: '069260.KS',
  },
  {
    value: '069620.KS',
    label: '069620.KS,  Daewoong pharmaceutical Co.,Ltd',
    name: 'Daewoong pharmaceutical Co.,Ltd',
    symbol: '069620.KS',
  },
  {
    value: '069960.KS',
    label: '069960.KS,  Hyundai Department Store Co. Ltd.',
    name: 'Hyundai Department Store Co. Ltd.',
    symbol: '069960.KS',
  },
  {
    value: '071050.KS',
    label: '071050.KS,  Korea Investment Holdings Co., Ltd.',
    name: 'Korea Investment Holdings Co., Ltd.',
    symbol: '071050.KS',
  },
  {
    value: '071320.KS',
    label: '071320.KS,  Korea District Heating Corp.',
    name: 'Korea District Heating Corp.',
    symbol: '071320.KS',
  },
  {
    value: '071840.KS',
    label: '071840.KS,  LOTTE Himart Co.,Ltd.',
    name: 'LOTTE Himart Co.,Ltd.',
    symbol: '071840.KS',
  },
  {
    value: '073240.KS',
    label: '073240.KS,  Kumho Tire Co., Inc.',
    name: 'Kumho Tire Co., Inc.',
    symbol: '073240.KS',
  },
  {
    value: '078340.KQ',
    label: '078340.KQ,  Com2uS Corporation',
    name: 'Com2uS Corporation',
    symbol: '078340.KQ',
  },
  {
    value: '078600.KQ',
    label: '078600.KQ,  Daejoo Electronic Materials Co., Ltd.',
    name: 'Daejoo Electronic Materials Co., Ltd.',
    symbol: '078600.KQ',
  },
  {
    value: '078930.KS',
    label: '078930.KS,  GS Holdings Corp.',
    name: 'GS Holdings Corp.',
    symbol: '078930.KS',
  },
  {
    value: '079160.KS',
    label: '079160.KS,  CJ CGV Co., Ltd.',
    name: 'CJ CGV Co., Ltd.',
    symbol: '079160.KS',
  },
  {
    value: '079430.KS',
    label: '079430.KS,  Hyundai Livart Furniture Company Limited',
    name: 'Hyundai Livart Furniture Company Limited',
    symbol: '079430.KS',
  },
  {
    value: '079550.KS',
    label: '079550.KS,  LIG Nex1 Co., Ltd.',
    name: 'LIG Nex1 Co., Ltd.',
    symbol: '079550.KS',
  },
  {
    value: '081000.KS',
    label: '081000.KS,  Iljin Diamond Co.,Ltd',
    name: 'Iljin Diamond Co.,Ltd',
    symbol: '081000.KS',
  },
  {
    value: '081660.KS',
    label: '081660.KS,  FILA Holdings Corporation',
    name: 'FILA Holdings Corporation',
    symbol: '081660.KS',
  },
  {
    value: '082640.KS',
    label: '082640.KS,  Tong Yang Life Insurance Co., Ltd.',
    name: 'Tong Yang Life Insurance Co., Ltd.',
    symbol: '082640.KS',
  },
  {
    value: '083790.KQ',
    label: '083790.KQ,  CrystalGenomics, Inc.',
    name: 'CrystalGenomics, Inc.',
    symbol: '083790.KQ',
  },
  {
    value: '084110.KQ',
    label: '084110.KQ,  Huons Global Co., Ltd.',
    name: 'Huons Global Co., Ltd.',
    symbol: '084110.KQ',
  },
  {
    value: '084690.KS',
    label: '084690.KS,  Daesang Holdings Co., Ltd.',
    name: 'Daesang Holdings Co., Ltd.',
    symbol: '084690.KS',
  },
  {
    value: '084850.KQ',
    label: '084850.KQ,  ITM Semiconductor Co., Ltd.',
    name: 'ITM Semiconductor Co., Ltd.',
    symbol: '084850.KQ',
  },
  {
    value: '084990.KQ',
    label: '084990.KQ,  Helixmith Co., Ltd',
    name: 'Helixmith Co., Ltd',
    symbol: '084990.KQ',
  },
  {
    value: '085370.KQ',
    label: '085370.KQ,  Lutronic Corporation',
    name: 'Lutronic Corporation',
    symbol: '085370.KQ',
  },
  {
    value: '085620.KS',
    label: '085620.KS,  MIRAE ASSET Life Insurance Co., Ltd.',
    name: 'MIRAE ASSET Life Insurance Co., Ltd.',
    symbol: '085620.KS',
  },
  {
    value: '086280.KS',
    label: '086280.KS,  Hyundai Glovis Co., Ltd.',
    name: 'Hyundai Glovis Co., Ltd.',
    symbol: '086280.KS',
  },
  {
    value: '086390.KQ',
    label: '086390.KQ,  UniTest Incorporation',
    name: 'UniTest Incorporation',
    symbol: '086390.KQ',
  },
  {
    value: '086450.KQ',
    label: '086450.KQ,  DongKook Pharmaceutical Co., Ltd.',
    name: 'DongKook Pharmaceutical Co., Ltd.',
    symbol: '086450.KQ',
  },
  {
    value: '086520.KQ',
    label: '086520.KQ,  Ecopro Co., Ltd.',
    name: 'Ecopro Co., Ltd.',
    symbol: '086520.KQ',
  },
  {
    value: '086790.KS',
    label: '086790.KS,  Hana Financial Group Inc.',
    name: 'Hana Financial Group Inc.',
    symbol: '086790.KS',
  },
  {
    value: '086900.KQ',
    label: '086900.KQ,  Medy-Tox Inc.',
    name: 'Medy-Tox Inc.',
    symbol: '086900.KQ',
  },
  {
    value: '087010.KQ',
    label: '087010.KQ,  Peptron, Inc.',
    name: 'Peptron, Inc.',
    symbol: '087010.KQ',
  },
  {
    value: '088350.KS',
    label: '088350.KS,  Hanwha Life Insurance Co., Ltd.',
    name: 'Hanwha Life Insurance Co., Ltd.',
    symbol: '088350.KS',
  },
  {
    value: '088800.KQ',
    label: '088800.KQ,  Ace Technologies Corp.',
    name: 'Ace Technologies Corp.',
    symbol: '088800.KQ',
  },
  {
    value: '088980.KS',
    label: '088980.KS,  Macquarie Korea Infrastructure Fund',
    name: 'Macquarie Korea Infrastructure Fund',
    symbol: '088980.KS',
  },
  {
    value: '089590.KS',
    label: '089590.KS,  Jeju Air Co., Ltd.',
    name: 'Jeju Air Co., Ltd.',
    symbol: '089590.KS',
  },
  {
    value: '090430.KS',
    label: '090430.KS,  Amorepacific Corporation',
    name: 'Amorepacific Corporation',
    symbol: '090430.KS',
  },
  {
    value: '090435.KS',
    label: '090435.KS,  Amorepacific Corporation',
    name: 'Amorepacific Corporation',
    symbol: '090435.KS',
  },
  {
    value: '090460.KQ',
    label: '090460.KQ,  BH Co., Ltd.',
    name: 'BH Co., Ltd.',
    symbol: '090460.KQ',
  },
  {
    value: '091700.KQ',
    label: '091700.KQ,  Partron Co., Ltd.',
    name: 'Partron Co., Ltd.',
    symbol: '091700.KQ',
  },
  {
    value: '091990.KQ',
    label: '091990.KQ,  Celltrion Healthcare Co., Ltd.',
    name: 'Celltrion Healthcare Co., Ltd.',
    symbol: '091990.KQ',
  },
  {
    value: '092040.KQ',
    label: '092040.KQ,  Amicogen, Inc.',
    name: 'Amicogen, Inc.',
    symbol: '092040.KQ',
  },
  {
    value: '093050.KS',
    label: '093050.KS,  LF Corp.',
    name: 'LF Corp.',
    symbol: '093050.KS',
  },
  {
    value: '093370.KS',
    label: '093370.KS,  Foosung Co., Ltd.',
    name: 'Foosung Co., Ltd.',
    symbol: '093370.KS',
  },
  {
    value: '095610.KQ',
    label: '095610.KQ,  TES Co., Ltd',
    name: 'TES Co., Ltd',
    symbol: '095610.KQ',
  },
  {
    value: '095660.KQ',
    label: '095660.KQ,  NEOWIZ',
    name: 'NEOWIZ',
    symbol: '095660.KQ',
  },
  {
    value: '095700.KQ',
    label: '095700.KQ,  Genexine, Inc.',
    name: 'Genexine, Inc.',
    symbol: '095700.KQ',
  },
  {
    value: '095720.KS',
    label: '095720.KS,  Woongjin Thinkbig Co., Ltd.',
    name: 'Woongjin Thinkbig Co., Ltd.',
    symbol: '095720.KS',
  },
  {
    value: '096530.KQ',
    label: '096530.KQ,  Seegene, Inc.',
    name: 'Seegene, Inc.',
    symbol: '096530.KQ',
  },
  {
    value: '096760.KS',
    label: '096760.KS,  JW Holdings Corporation',
    name: 'JW Holdings Corporation',
    symbol: '096760.KS',
  },
  {
    value: '096770.KS',
    label: '096770.KS,  SK Innovation Co., Ltd.',
    name: 'SK Innovation Co., Ltd.',
    symbol: '096770.KS',
  },
  {
    value: '096775.KS',
    label: '096775.KS,  SK Innovation Co., Ltd.',
    name: 'SK Innovation Co., Ltd.',
    symbol: '096775.KS',
  },
  {
    value: '097230.KS',
    label: '097230.KS,  HJ Shipbuilding & Construction Co., Ltd.',
    name: 'HJ Shipbuilding & Construction Co., Ltd.',
    symbol: '097230.KS',
  },
  {
    value: '097520.KS',
    label: '097520.KS,  MCNEX Co., Ltd',
    name: 'MCNEX Co., Ltd',
    symbol: '097520.KS',
  },
  {
    value: '097950.KS',
    label: '097950.KS,  CJ Cheiljedang Corporation',
    name: 'CJ Cheiljedang Corporation',
    symbol: '097950.KS',
  },
  {
    value: '097955.KS',
    label: '097955.KS,  CJ Cheiljedang Corporation',
    name: 'CJ Cheiljedang Corporation',
    symbol: '097955.KS',
  },
  {
    value: '098460.KQ',
    label: '098460.KQ,  Koh Young Technology Inc.',
    name: 'Koh Young Technology Inc.',
    symbol: '098460.KQ',
  },
  {
    value: '099190.KQ',
    label: '099190.KQ,  i-SENS, Inc.',
    name: 'i-SENS, Inc.',
    symbol: '099190.KQ',
  },
  {
    value: '100120.KQ',
    label: '100120.KQ,  Vieworks Co., Ltd.',
    name: 'Vieworks Co., Ltd.',
    symbol: '100120.KQ',
  },
  {
    value: '101490.KQ',
    label: '101490.KQ,  S&S Tech Corporation',
    name: 'S&S Tech Corporation',
    symbol: '101490.KQ',
  },
  {
    value: '102710.KQ',
    label: '102710.KQ,  ENF Technology Co., Ltd.',
    name: 'ENF Technology Co., Ltd.',
    symbol: '102710.KQ',
  },
  {
    value: '103140.KS',
    label: '103140.KS,  Poongsan Corporation',
    name: 'Poongsan Corporation',
    symbol: '103140.KS',
  },
  {
    value: '104700.KS',
    label: '104700.KS,  KISCO Corp.',
    name: 'KISCO Corp.',
    symbol: '104700.KS',
  },
  {
    value: '104830.KQ',
    label: '104830.KQ,  WONIK Materials Co.,Ltd.',
    name: 'WONIK Materials Co.,Ltd.',
    symbol: '104830.KQ',
  },
  {
    value: '105560.KS',
    label: '105560.KS,  KB Financial Group Inc.',
    name: 'KB Financial Group Inc.',
    symbol: '105560.KS',
  },
  {
    value: '105630.KS',
    label: '105630.KS,  Hansae Co., Ltd.',
    name: 'Hansae Co., Ltd.',
    symbol: '105630.KS',
  },
  {
    value: '108230.KQ',
    label: '108230.KQ,  TOPTEC Co., Ltd',
    name: 'TOPTEC Co., Ltd',
    symbol: '108230.KQ',
  },
  {
    value: '108670.KS',
    label: '108670.KS,  LX Hausys, Ltd.',
    name: 'LX Hausys, Ltd.',
    symbol: '108670.KS',
  },
  {
    value: '1101.TW',
    label: '1101.TW,  Taiwan Cement Corp.',
    name: 'Taiwan Cement Corp.',
    symbol: '1101.TW',
  },
  {
    value: '1102.TW',
    label: '1102.TW,  Asia Cement Corporation',
    name: 'Asia Cement Corporation',
    symbol: '1102.TW',
  },
  {
    value: '1103.TW',
    label: '1103.TW,  Chia Hsin Cement Corporation',
    name: 'Chia Hsin Cement Corporation',
    symbol: '1103.TW',
  },
  {
    value: '1104.TW',
    label: '1104.TW,  Universal Cement Corporation',
    name: 'Universal Cement Corporation',
    symbol: '1104.TW',
  },
  {
    value: '1108.TW',
    label: '1108.TW,  Lucky Cement Co.',
    name: 'Lucky Cement Co.',
    symbol: '1108.TW',
  },
  {
    value: '1109.TW',
    label: '1109.TW,  Hsing Ta Cement Co.,Ltd',
    name: 'Hsing Ta Cement Co.,Ltd',
    symbol: '1109.TW',
  },
  {
    value: '111770.KS',
    label: '111770.KS,  Youngone Corporation',
    name: 'Youngone Corporation',
    symbol: '111770.KS',
  },
  {
    value: '112040.KQ',
    label: '112040.KQ,  Wemade Co.,Ltd.',
    name: 'Wemade Co.,Ltd.',
    symbol: '112040.KQ',
  },
  {
    value: '112610.KS',
    label: '112610.KS,  CS Wind Corporation',
    name: 'CS Wind Corporation',
    symbol: '112610.KS',
  },
  {
    value: '114090.KS',
    label: '114090.KS,  Grand Korea Leisure Co., Ltd.',
    name: 'Grand Korea Leisure Co., Ltd.',
    symbol: '114090.KS',
  },
  {
    value: '115160.KQ',
    label: '115160.KQ,  Humax Co., Ltd.',
    name: 'Humax Co., Ltd.',
    symbol: '115160.KQ',
  },
  {
    value: '115390.KS',
    label: '115390.KS,  Lock&Lock Co., Ltd.',
    name: 'Lock&Lock Co., Ltd.',
    symbol: '115390.KS',
  },
  {
    value: '11B.WA',
    label: '11B.WA,  11 bit studios S.A.',
    name: '11 bit studios S.A.',
    symbol: '11B.WA',
  },
  {
    value: '120110.KS',
    label: '120110.KS,  Kolon Industries, Inc.',
    name: 'Kolon Industries, Inc.',
    symbol: '120110.KS',
  },
  {
    value: '1201.TW',
    label: '1201.TW,  Wei Chuan Foods Corporation',
    name: 'Wei Chuan Foods Corporation',
    symbol: '1201.TW',
  },
  {
    value: '1203.TW',
    label: '1203.TW,  Ve Wong Corporation',
    name: 'Ve Wong Corporation',
    symbol: '1203.TW',
  },
  {
    value: '1210.TW',
    label: '1210.TW,  Great Wall Enterprise Co., Ltd.',
    name: 'Great Wall Enterprise Co., Ltd.',
    symbol: '1210.TW',
  },
  {
    value: '1215.TW',
    label: '1215.TW,  Charoen Pokphand Enterprise (Taiwan) Co., Ltd.',
    name: 'Charoen Pokphand Enterprise (Taiwan) Co., Ltd.',
    symbol: '1215.TW',
  },
  {
    value: '1216.TW',
    label: '1216.TW,  Uni-President Enterprises Corp.',
    name: 'Uni-President Enterprises Corp.',
    symbol: '1216.TW',
  },
  {
    value: '1217.TW',
    label: '1217.TW,  AGV Products Corporation',
    name: 'AGV Products Corporation',
    symbol: '1217.TW',
  },
  {
    value: '1218.TW',
    label: '1218.TW,  Taisun Enterprise Co., Ltd.',
    name: 'Taisun Enterprise Co., Ltd.',
    symbol: '1218.TW',
  },
  {
    value: '1219.TW',
    label: '1219.TW,  Fwusow Industry Co., Ltd.',
    name: 'Fwusow Industry Co., Ltd.',
    symbol: '1219.TW',
  },
  {
    value: '1220.TW',
    label: '1220.TW,  Tai Roun Products Co.,Ltd.',
    name: 'Tai Roun Products Co.,Ltd.',
    symbol: '1220.TW',
  },
  {
    value: '122450.KQ',
    label: '122450.KQ,  KMH Co. Ltd.',
    name: 'KMH Co. Ltd.',
    symbol: '122450.KQ',
  },
  {
    value: '1225.TW',
    label: '1225.TW,  Formosa Oilseed Processing Co., Ltd.',
    name: 'Formosa Oilseed Processing Co., Ltd.',
    symbol: '1225.TW',
  },
  {
    value: '1227.TW',
    label: '1227.TW,  Standard Foods Corporation',
    name: 'Standard Foods Corporation',
    symbol: '1227.TW',
  },
  {
    value: '122870.KQ',
    label: '122870.KQ,  YG Entertainment Inc.',
    name: 'YG Entertainment Inc.',
    symbol: '122870.KQ',
  },
  {
    value: '122900.KS',
    label: '122900.KS,  iMarketKorea Inc.',
    name: 'iMarketKorea Inc.',
    symbol: '122900.KS',
  },
  {
    value: '122990.KQ',
    label: '122990.KQ,  WiSoL CO.,LTD.',
    name: 'WiSoL CO.,LTD.',
    symbol: '122990.KQ',
  },
  {
    value: '1229.TW',
    label: '1229.TW,  Lien Hwa Industrial Holdings Corporation',
    name: 'Lien Hwa Industrial Holdings Corporation',
    symbol: '1229.TW',
  },
  {
    value: '1231.TW',
    label: '1231.TW,  Lian Hwa Foods Corporation',
    name: 'Lian Hwa Foods Corporation',
    symbol: '1231.TW',
  },
  {
    value: '1232.TW',
    label: '1232.TW,  TTET Union Corporation',
    name: 'TTET Union Corporation',
    symbol: '1232.TW',
  },
  {
    value: '123890.KS',
    label: '123890.KS,  Korea Asset In Trust Co., Ltd.',
    name: 'Korea Asset In Trust Co., Ltd.',
    symbol: '123890.KS',
  },
  {
    value: '1256.TW',
    label: '1256.TW,  Sunjuice Holdings Co., Limited',
    name: 'Sunjuice Holdings Co., Limited',
    symbol: '1256.TW',
  },
  {
    value: '126560.KS',
    label: '126560.KS,  Hyundai Futurenet Co., Ltd',
    name: 'Hyundai Futurenet Co., Ltd',
    symbol: '126560.KS',
  },
  {
    value: '128940.KS',
    label: '128940.KS,  Hanmi Pharm. Co., Ltd.',
    name: 'Hanmi Pharm. Co., Ltd.',
    symbol: '128940.KS',
  },
  {
    value: '1301.T',
    label: '1301.T,  Kyokuyo Co., Ltd.',
    name: 'Kyokuyo Co., Ltd.',
    symbol: '1301.T',
  },
  {
    value: '1301.TW',
    label: '1301.TW,  Formosa Plastics Corporation',
    name: 'Formosa Plastics Corporation',
    symbol: '1301.TW',
  },
  {
    value: '1303.TW',
    label: '1303.TW,  Nan Ya Plastics Corporation',
    name: 'Nan Ya Plastics Corporation',
    symbol: '1303.TW',
  },
  {
    value: '1304.TW',
    label: '1304.TW,  USI Corporation',
    name: 'USI Corporation',
    symbol: '1304.TW',
  },
  {
    value: '1305.TW',
    label: '1305.TW,  China General Plastics Corporation',
    name: 'China General Plastics Corporation',
    symbol: '1305.TW',
  },
  {
    value: '1307.TW',
    label: '1307.TW,  San Fang Chemical Industry Co., Ltd.',
    name: 'San Fang Chemical Industry Co., Ltd.',
    symbol: '1307.TW',
  },
  {
    value: '1308.TW',
    label: '1308.TW,  Asia Polymer Corporation',
    name: 'Asia Polymer Corporation',
    symbol: '1308.TW',
  },
  {
    value: '1309.TW',
    label: '1309.TW,  Taita Chemical Company, Limited',
    name: 'Taita Chemical Company, Limited',
    symbol: '1309.TW',
  },
  {
    value: '1310.TW',
    label: '1310.TW,  Taiwan Styrene Monomer Corporation',
    name: 'Taiwan Styrene Monomer Corporation',
    symbol: '1310.TW',
  },
  {
    value: '1312.TW',
    label: '1312.TW,  Grand Pacific Petrochemical Corporation',
    name: 'Grand Pacific Petrochemical Corporation',
    symbol: '1312.TW',
  },
  {
    value: '131370.KQ',
    label: '131370.KQ,  RSUPPORT Co., Ltd.',
    name: 'RSUPPORT Co., Ltd.',
    symbol: '131370.KQ',
  },
  {
    value: '1313.TW',
    label: '1313.TW,  UPC Technology Corporation',
    name: 'UPC Technology Corporation',
    symbol: '1313.TW',
  },
  {
    value: '1314.TW',
    label: '1314.TW,  China Petrochemical Development Corporation',
    name: 'China Petrochemical Development Corporation',
    symbol: '1314.TW',
  },
  {
    value: '131970.KQ',
    label: '131970.KQ,  Tesna Inc.',
    name: 'Tesna Inc.',
    symbol: '131970.KQ',
  },
  {
    value: '1319.TW',
    label: '1319.TW,  Tong Yang Industry Co., Ltd.',
    name: 'Tong Yang Industry Co., Ltd.',
    symbol: '1319.TW',
  },
  {
    value: '1321.TW',
    label: '1321.TW,  Ocean Plastics Co., Ltd.',
    name: 'Ocean Plastics Co., Ltd.',
    symbol: '1321.TW',
  },
  {
    value: '1325.TW',
    label: '1325.TW,  Universal Incorporation',
    name: 'Universal Incorporation',
    symbol: '1325.TW',
  },
  {
    value: '1326.TW',
    label: '1326.TW,  Formosa Chemicals & Fibre Corporation',
    name: 'Formosa Chemicals & Fibre Corporation',
    symbol: '1326.TW',
  },
  {
    value: '1332.T',
    label: '1332.T,  Nippon Suisan Kaisha, Ltd.',
    name: 'Nippon Suisan Kaisha, Ltd.',
    symbol: '1332.T',
  },
  {
    value: '1333.T',
    label: '1333.T,  Maruha Nichiro Corporation',
    name: 'Maruha Nichiro Corporation',
    symbol: '1333.T',
  },
  {
    value: '1338.TW',
    label: '1338.TW,  Hiroca Holdings Ltd.',
    name: 'Hiroca Holdings Ltd.',
    symbol: '1338.TW',
  },
  {
    value: 'FORG',
    label: 'FORG,  ForgeRock, Inc.',
    name: 'ForgeRock, Inc.',
    symbol: 'FORG',
  },
  {
    value: 'BKUCF',
    label: 'BKUCF,  Blue Sky Uranium Corp.',
    name: 'Blue Sky Uranium Corp.',
    symbol: 'BKUCF',
  },
  {
    value: 'BTRYF',
    label: 'BTRYF,  Battery Minerals Limited',
    name: 'Battery Minerals Limited',
    symbol: 'BTRYF',
  },
  {
    value: 'CVVUF',
    label: 'CVVUF,  CanAlaska Uranium Ltd.',
    name: 'CanAlaska Uranium Ltd.',
    symbol: 'CVVUF',
  },
  {
    value: 'DFMTF',
    label: 'DFMTF,  Defense Metals Corp.',
    name: 'Defense Metals Corp.',
    symbol: 'DFMTF',
  },
  {
    value: 'GDLNF',
    label: 'GDLNF,  Greenland Minerals Limited',
    name: 'Greenland Minerals Limited',
    symbol: 'GDLNF',
  },
  {
    value: 'LISMF',
    label: 'LISMF,  Lithium South Development Corporation',
    name: 'Lithium South Development Corporation',
    symbol: 'LISMF',
  },
  {
    value: 'LXENF',
    label: 'LXENF,  Lithium Energi Exploration Inc.',
    name: 'Lithium Energi Exploration Inc.',
    symbol: 'LXENF',
  },
  {
    value: 'LYSCF',
    label: 'LYSCF,  Lynas Rare Earths Limited',
    name: 'Lynas Rare Earths Limited',
    symbol: 'LYSCF',
  },
  {
    value: 'PALAF',
    label: 'PALAF,  Paladin Energy Ltd',
    name: 'Paladin Energy Ltd',
    symbol: 'PALAF',
  },
  {
    value: 'RAREF',
    label: 'RAREF,  Canada Rare Earth Corp.',
    name: 'Canada Rare Earth Corp.',
    symbol: 'RAREF',
  },
  {
    value: 'SILXF',
    label: 'SILXF,  Silex Systems Limited',
    name: 'Silex Systems Limited',
    symbol: 'SILXF',
  },
  {
    value: 'SRUUF',
    label: 'SRUUF,  Sprott Physical Uranium Trust Fund',
    name: 'Sprott Physical Uranium Trust Fund',
    symbol: 'SRUUF',
  },
  {
    value: 'TAOFF',
    label: 'TAOFF,  IperionX Limited',
    name: 'IperionX Limited',
    symbol: 'TAOFF',
  },
  {
    value: 'UURAF',
    label: 'UURAF,  Ucore Rare Metals Inc.',
    name: 'Ucore Rare Metals Inc.',
    symbol: 'UURAF',
  },
  {
    value: 'UWEFF',
    label: 'UWEFF,  Green Shift Commodities Ltd.',
    name: 'Green Shift Commodities Ltd.',
    symbol: 'UWEFF',
  },
  {
    value: 'HHGCU',
    label: 'HHGCU,  HHG Capital Corporation',
    name: 'HHG Capital Corporation',
    symbol: 'HHGCU',
  },
  {
    value: 'TOST',
    label: 'TOST,  Toast, Inc.',
    name: 'Toast, Inc.',
    symbol: 'TOST',
  },
  {
    value: 'STER',
    label: 'STER,  Sterling Check Corp.',
    name: 'Sterling Check Corp.',
    symbol: 'STER',
  },
  {
    value: 'FRSH',
    label: 'FRSH,  Freshworks Inc.',
    name: 'Freshworks Inc.',
    symbol: 'FRSH',
  },
  {
    value: 'AKA',
    label: 'AKA,  a.k.a. Brands Holding Corp.',
    name: 'a.k.a. Brands Holding Corp.',
    symbol: 'AKA',
  },
  {
    value: 'ARGUU',
    label: 'ARGUU,  Argus Capital Corp.',
    name: 'Argus Capital Corp.',
    symbol: 'ARGUU',
  },
  {
    value: 'IPAXU',
    label: 'IPAXU,  Inflection Point Acquisition Corp.',
    name: 'Inflection Point Acquisition Corp.',
    symbol: 'IPAXU',
  },
  {
    value: 'BRLT',
    label: 'BRLT,  Brilliant Earth Group, Inc.',
    name: 'Brilliant Earth Group, Inc.',
    symbol: 'BRLT',
  },
  {
    value: 'SOVO',
    label: 'SOVO,  Sovos Brands, Inc.',
    name: 'Sovos Brands, Inc.',
    symbol: 'SOVO',
  },
  {
    value: 'ESMT',
    label: 'ESMT,  EngageSmart, Inc.',
    name: 'EngageSmart, Inc.',
    symbol: 'ESMT',
  },
  {
    value: 'THRN',
    label: 'THRN,  Thorne HealthTech, Inc.',
    name: 'Thorne HealthTech, Inc.',
    symbol: 'THRN',
  },
  {
    value: 'RELY',
    label: 'RELY,  Remitly Global, Inc.',
    name: 'Remitly Global, Inc.',
    symbol: 'RELY',
  },
  {
    value: 'EVLV',
    label: 'EVLV,  Evolv Technologies Holdings, Inc.',
    name: 'Evolv Technologies Holdings, Inc.',
    symbol: 'EVLV',
  },
  {
    value: 'GENN',
    label: 'GENN,  Genesis Healthcare, Inc.',
    name: 'Genesis Healthcare, Inc.',
    symbol: 'GENN',
  },
  {
    value: 'NRDY',
    label: 'NRDY,  Nerdy, Inc.',
    name: 'Nerdy, Inc.',
    symbol: 'NRDY',
  },
  {
    value: 'HLTH',
    label: 'HLTH,  Cue Health Inc.',
    name: 'Cue Health Inc.',
    symbol: 'HLTH',
  },
  {
    value: 'ABMINTLLTD.NS',
    label: 'ABMINTLLTD.NS,  ABM International Limited',
    name: 'ABM International Limited',
    symbol: 'ABMINTLLTD.NS',
  },
  {
    value: 'ADL.NS',
    label: 'ADL.NS,  Archidply Decor Limited',
    name: 'Archidply Decor Limited',
    symbol: 'ADL.NS',
  },
  {
    value: 'AJRINFRA.NS',
    label: 'AJRINFRA.NS,  AJR Infra and Tolling Limited',
    name: 'AJR Infra and Tolling Limited',
    symbol: 'AJRINFRA.NS',
  },
  {
    value: 'ANTGRAPHIC.NS',
    label: 'ANTGRAPHIC.NS,  Antarctica Limited',
    name: 'Antarctica Limited',
    symbol: 'ANTGRAPHIC.NS',
  },
  {
    value: 'ARVEE.NS',
    label: 'ARVEE.NS,  Arvee Laboratories (India) Ltd.',
    name: 'Arvee Laboratories (India) Ltd.',
    symbol: 'ARVEE.NS',
  },
  {
    value: 'ATGL.NS',
    label: 'ATGL.NS,  Adani Total Gas Limited',
    name: 'Adani Total Gas Limited',
    symbol: 'ATGL.NS',
  },
  {
    value: 'BSHSL.NS',
    label: 'BSHSL.NS,  Bombay Super Hybrid Seeds Limited',
    name: 'Bombay Super Hybrid Seeds Limited',
    symbol: 'BSHSL.NS',
  },
  {
    value: 'CYBERMEDIA.NS',
    label: 'CYBERMEDIA.NS,  Cyber Media (India) Limited',
    name: 'Cyber Media (India) Limited',
    symbol: 'CYBERMEDIA.NS',
  },
  {
    value: 'DEEPENR.NS',
    label: 'DEEPENR.NS,  Deep Energy Resources Limited',
    name: 'Deep Energy Resources Limited',
    symbol: 'DEEPENR.NS',
  },
  {
    value: 'GLOBE.NS',
    label: 'GLOBE.NS,  Globe Textiles (India) Limited',
    name: 'Globe Textiles (India) Limited',
    symbol: 'GLOBE.NS',
  },
  {
    value: 'GODHA.NS',
    label: 'GODHA.NS,  Godha Cabcon & Insulation Limited',
    name: 'Godha Cabcon & Insulation Limited',
    symbol: 'GODHA.NS',
  },
  {
    value: 'ICDSLTD.NS',
    label: 'ICDSLTD.NS,  ICDS Limited',
    name: 'ICDS Limited',
    symbol: 'ICDSLTD.NS',
  },
  {
    value: 'INCREDIBLE.NS',
    label: 'INCREDIBLE.NS,  Incredible Industries Limited',
    name: 'Incredible Industries Limited',
    symbol: 'INCREDIBLE.NS',
  },
  {
    value: 'IRISDOREME.NS',
    label: 'IRISDOREME.NS,  Iris Clothings Limited',
    name: 'Iris Clothings Limited',
    symbol: 'IRISDOREME.NS',
  },
  {
    value: 'IWEL.NS',
    label: 'IWEL.NS,  Inox Wind Energy Limited',
    name: 'Inox Wind Energy Limited',
    symbol: 'IWEL.NS',
  },
  {
    value: 'KAVVERITEL.NS',
    label: 'KAVVERITEL.NS,  Kavveri Telecom Products Limited',
    name: 'Kavveri Telecom Products Limited',
    symbol: 'KAVVERITEL.NS',
  },
  {
    value: 'KHAITANLTD.NS',
    label: 'KHAITANLTD.NS,  Khaitan (India) Limited',
    name: 'Khaitan (India) Limited',
    symbol: 'KHAITANLTD.NS',
  },
  {
    value: 'LCCINFOTEC.NS',
    label: 'LCCINFOTEC.NS,  LCC Infotech Limited',
    name: 'LCC Infotech Limited',
    symbol: 'LCCINFOTEC.NS',
  },
  {
    value: 'NDRAUTO.NS',
    label: 'NDRAUTO.NS,  NDR Auto Components Limited',
    name: 'NDR Auto Components Limited',
    symbol: 'NDRAUTO.NS',
  },
  {
    value: 'NGIL.NS',
    label: 'NGIL.NS,  Nakoda Group of Industries Limited',
    name: 'Nakoda Group of Industries Limited',
    symbol: 'NGIL.NS',
  },
  {
    value: 'ORCHPHARMA.NS',
    label: 'ORCHPHARMA.NS,  Orchid Pharma Limited',
    name: 'Orchid Pharma Limited',
    symbol: 'ORCHPHARMA.NS',
  },
  {
    value: 'PDPL.NS',
    label: 'PDPL.NS,  Parenteral Drugs (India) Limited',
    name: 'Parenteral Drugs (India) Limited',
    symbol: 'PDPL.NS',
  },
  {
    value: 'POONAWALLA.NS',
    label: 'POONAWALLA.NS,  Poonawalla Fincorp Limited',
    name: 'Poonawalla Fincorp Limited',
    symbol: 'POONAWALLA.NS',
  },
  {
    value: 'PVP.NS',
    label: 'PVP.NS,  PVP Ventures Limited',
    name: 'PVP Ventures Limited',
    symbol: 'PVP.NS',
  },
  {
    value: 'RAJMET.NS',
    label: 'RAJMET.NS,  Rajnandini Metal Limited',
    name: 'Rajnandini Metal Limited',
    symbol: 'RAJMET.NS',
  },
  {
    value: 'ROML.NS',
    label: 'ROML.NS,  Raj Oil Mills Limited',
    name: 'Raj Oil Mills Limited',
    symbol: 'ROML.NS',
  },
  {
    value: 'RTNINDIA.NS',
    label: 'RTNINDIA.NS,  RattanIndia Enterprises Limited',
    name: 'RattanIndia Enterprises Limited',
    symbol: 'RTNINDIA.NS',
  },
  {
    value: 'SCAPDVR.NS',
    label: 'SCAPDVR.NS,  Stampede Capital Limited',
    name: 'Stampede Capital Limited',
    symbol: 'SCAPDVR.NS',
  },
  {
    value: 'SRPL.NS',
    label: 'SRPL.NS,  Shree Ram Proteins Limited',
    name: 'Shree Ram Proteins Limited',
    symbol: 'SRPL.NS',
  },
  {
    value: 'TARC.NS',
    label: 'TARC.NS,  TARC Limited',
    name: 'TARC Limited',
    symbol: 'TARC.NS',
  },
  {
    value: 'TIRUPATIFL.NS',
    label: 'TIRUPATIFL.NS,  Tirupati Forge Limited',
    name: 'Tirupati Forge Limited',
    symbol: 'TIRUPATIFL.NS',
  },
  {
    value: 'VIKASLIFE.NS',
    label: 'VIKASLIFE.NS,  Vikas Lifecare Limited',
    name: 'Vikas Lifecare Limited',
    symbol: 'VIKASLIFE.NS',
  },
  {
    value: 'WEALTH.NS',
    label: 'WEALTH.NS,  Wealth First Portfolio Managers Limited',
    name: 'Wealth First Portfolio Managers Limited',
    symbol: 'WEALTH.NS',
  },
  {
    value: 'IL&FSENGG.NS',
    label: 'IL&FSENGG.NS,  IL&FS Engineering and Construction Company Limited',
    name: 'IL&FS Engineering and Construction Company Limited',
    symbol: 'IL&FSENGG.NS',
  },
  {
    value: 'IL&FSTRANS.NS',
    label: 'IL&FSTRANS.NS,  IL&FS Transportation Networks Limited',
    name: 'IL&FS Transportation Networks Limited',
    symbol: 'IL&FSTRANS.NS',
  },
  {
    value: 'AAKASH.NS',
    label: 'AAKASH.NS,  Aakash Exploration Services Limited',
    name: 'Aakash Exploration Services Limited',
    symbol: 'AAKASH.NS',
  },
  {
    value: 'AAREYDRUGS.NS',
    label: 'AAREYDRUGS.NS,  Aarey Drugs & Pharmaceuticals Limited',
    name: 'Aarey Drugs & Pharmaceuticals Limited',
    symbol: 'AAREYDRUGS.NS',
  },
  {
    value: 'AARON.NS',
    label: 'AARON.NS,  Aaron Industries Limited',
    name: 'Aaron Industries Limited',
    symbol: 'AARON.NS',
  },
  {
    value: 'AARTISURF.NS',
    label: 'AARTISURF.NS,  Aarti Surfactants Limited',
    name: 'Aarti Surfactants Limited',
    symbol: 'AARTISURF.NS',
  },
  {
    value: 'ABSLNN50ET.NS',
    label: 'ABSLNN50ET.NS,  Aditya BSL Nifty Next 50 ETF',
    name: 'Aditya BSL Nifty Next 50 ETF',
    symbol: 'ABSLNN50ET.NS',
  },
  {
    value: 'ACCURACY.NS',
    label: 'ACCURACY.NS,  Accuracy Shipping Limited',
    name: 'Accuracy Shipping Limited',
    symbol: 'ACCURACY.NS',
  },
  {
    value: 'ACRYSIL.NS',
    label: 'ACRYSIL.NS,  Acrysil Limited',
    name: 'Acrysil Limited',
    symbol: 'ACRYSIL.NS',
  },
  {
    value: 'AHLADA.NS',
    label: 'AHLADA.NS,  Ahlada Engineers Limited',
    name: 'Ahlada Engineers Limited',
    symbol: 'AHLADA.NS',
  },
  {
    value: 'AJOONI.NS',
    label: 'AJOONI.NS,  Ajooni Biotech Limited',
    name: 'Ajooni Biotech Limited',
    symbol: 'AJOONI.NS',
  },
  {
    value: 'AKG.NS',
    label: 'AKG.NS,  AKG Exim Limited',
    name: 'AKG Exim Limited',
    symbol: 'AKG.NS',
  },
  {
    value: 'AMBICAAGAR.NS',
    label: 'AMBICAAGAR.NS,  Ambica Agarbathies Aroma & Industries Limited',
    name: 'Ambica Agarbathies Aroma & Industries Limited',
    symbol: 'AMBICAAGAR.NS',
  },
  {
    value: 'AMIORG.NS',
    label: 'AMIORG.NS,  Ami Organics Limited',
    name: 'Ami Organics Limited',
    symbol: 'AMIORG.NS',
  },
  {
    value: 'ANDREWYU.NS',
    label: 'ANDREWYU.NS,  Andrew Yule & Company Limited',
    name: 'Andrew Yule & Company Limited',
    symbol: 'ANDREWYU.NS',
  },
  {
    value: 'ANMOL.NS',
    label: 'ANMOL.NS,  Anmol India Limited',
    name: 'Anmol India Limited',
    symbol: 'ANMOL.NS',
  },
  {
    value: 'ANURAS.NS',
    label: 'ANURAS.NS,  Anupam Rasayan India Ltd',
    name: 'Anupam Rasayan India Ltd',
    symbol: 'ANURAS.NS',
  },
  {
    value: 'APTUS.NS',
    label: 'APTUS.NS,  Aptus Value Housing Finance India Limited',
    name: 'Aptus Value Housing Finance India Limited',
    symbol: 'APTUS.NS',
  },
  {
    value: 'ARIHANTCAP.NS',
    label: 'ARIHANTCAP.NS,  Arihant Capital Markets Limited',
    name: 'Arihant Capital Markets Limited',
    symbol: 'ARIHANTCAP.NS',
  },
  {
    value: 'AWHCL.NS',
    label: 'AWHCL.NS,  Antony Waste Handling Cell Limited',
    name: 'Antony Waste Handling Cell Limited',
    symbol: 'AWHCL.NS',
  },
  {
    value: 'BARBEQUE.NS',
    label: 'BARBEQUE.NS,  Barbeque-Nation Hospitality Limited',
    name: 'Barbeque-Nation Hospitality Limited',
    symbol: 'BARBEQUE.NS',
  },
  {
    value: 'BCLIND.NS',
    label: 'BCLIND.NS,  BCL Industries Limited',
    name: 'BCL Industries Limited',
    symbol: 'BCLIND.NS',
  },
  {
    value: 'BECTORFOOD.NS',
    label: 'BECTORFOOD.NS,  Mrs. Bectors Food Specialities Limited',
    name: 'Mrs. Bectors Food Specialities Limited',
    symbol: 'BECTORFOOD.NS',
  },
  {
    value: 'BESTAGRO.NS',
    label: 'BESTAGRO.NS,  Best Agrolife Limited',
    name: 'Best Agrolife Limited',
    symbol: 'BESTAGRO.NS',
  },
  {
    value: 'BOROLTD.NS',
    label: 'BOROLTD.NS,  Borosil Limited',
    name: 'Borosil Limited',
    symbol: 'BOROLTD.NS',
  },
  {
    value: 'CARTRADE.NS',
    label: 'CARTRADE.NS,  CarTrade Tech Limited',
    name: 'CarTrade Tech Limited',
    symbol: 'CARTRADE.NS',
  },
  {
    value: 'CHEMCON.NS',
    label: 'CHEMCON.NS,  Chemcon Speciality Chemicals Limited',
    name: 'Chemcon Speciality Chemicals Limited',
    symbol: 'CHEMCON.NS',
  },
  {
    value: 'CHEMPLASTS.NS',
    label: 'CHEMPLASTS.NS,  Chemplast Sanmar Limited',
    name: 'Chemplast Sanmar Limited',
    symbol: 'CHEMPLASTS.NS',
  },
  {
    value: 'CLEAN.NS',
    label: 'CLEAN.NS,  Clean Science and Technology Limited',
    name: 'Clean Science and Technology Limited',
    symbol: 'CLEAN.NS',
  },
  {
    value: 'CLSEL.NS',
    label: 'CLSEL.NS,  Chaman Lal Setia Exports Ltd',
    name: 'Chaman Lal Setia Exports Ltd',
    symbol: 'CLSEL.NS',
  },
  {
    value: 'COFFEEDAY.NS',
    label: 'COFFEEDAY.NS,  Coffee Day Enterprises Limited',
    name: 'Coffee Day Enterprises Limited',
    symbol: 'COFFEEDAY.NS',
  },
  {
    value: 'CRAFTSMAN.NS',
    label: 'CRAFTSMAN.NS,  Craftsman Automation Limited',
    name: 'Craftsman Automation Limited',
    symbol: 'CRAFTSMAN.NS',
  },
  {
    value: 'DANGEE.NS',
    label: 'DANGEE.NS,  Dangee Dums Limited',
    name: 'Dangee Dums Limited',
    symbol: 'DANGEE.NS',
  },
  {
    value: 'DEEPINDS.NS',
    label: 'DEEPINDS.NS,  Deep Industries Limited',
    name: 'Deep Industries Limited',
    symbol: 'DEEPINDS.NS',
  },
  {
    value: 'DELPHIFX.NS',
    label: 'DELPHIFX.NS,  Delphi World Money Limited',
    name: 'Delphi World Money Limited',
    symbol: 'DELPHIFX.NS',
  },
  {
    value: 'DEVYANI.NS',
    label: 'DEVYANI.NS,  Devyani International Limited',
    name: 'Devyani International Limited',
    symbol: 'DEVYANI.NS',
  },
  {
    value: 'DODLA.NS',
    label: 'DODLA.NS,  Dodla Dairy Limited',
    name: 'Dodla Dairy Limited',
    symbol: 'DODLA.NS',
  },
  {
    value: 'DPABHUSHAN.NS',
    label: 'DPABHUSHAN.NS,  D. P. Abhushan Limited',
    name: 'D. P. Abhushan Limited',
    symbol: 'DPABHUSHAN.NS',
  },
  {
    value: 'DRCSYSTEMS.NS',
    label: 'DRCSYSTEMS.NS,  DRC Systems India Limited',
    name: 'DRC Systems India Limited',
    symbol: 'DRCSYSTEMS.NS',
  },
  {
    value: 'EASEMYTRIP.NS',
    label: 'EASEMYTRIP.NS,  Easy Trip Planners Limited',
    name: 'Easy Trip Planners Limited',
    symbol: 'EASEMYTRIP.NS',
  },
  {
    value: 'EQUITASBNK.NS',
    label: 'EQUITASBNK.NS,  Equitas Small Finance Bank Limited',
    name: 'Equitas Small Finance Bank Limited',
    symbol: 'EQUITASBNK.NS',
  },
  {
    value: 'EXXARO.NS',
    label: 'EXXARO.NS,  Exxaro Tiles Limited',
    name: 'Exxaro Tiles Limited',
    symbol: 'EXXARO.NS',
  },
  {
    value: 'FAIRCHEMOR.NS',
    label: 'FAIRCHEMOR.NS,  Fairchem Organics Limited',
    name: 'Fairchem Organics Limited',
    symbol: 'FAIRCHEMOR.NS',
  },
  {
    value: 'FELDVR.NS',
    label: 'FELDVR.NS,  Future Enterprises Limited',
    name: 'Future Enterprises Limited',
    symbol: 'FELDVR.NS',
  },
  {
    value: 'GANESHBE.NS',
    label: 'GANESHBE.NS,  Ganesh Benzoplast Limited',
    name: 'Ganesh Benzoplast Limited',
    symbol: 'GANESHBE.NS',
  },
  {
    value: 'GANGAFORGE.NS',
    label: 'GANGAFORGE.NS,  Ganga Forging Limited',
    name: 'Ganga Forging Limited',
    symbol: 'GANGAFORGE.NS',
  },
  {
    value: 'GENCON.NS',
    label: 'GENCON.NS,  Generic Engineering Construction and Projects Limited',
    name: 'Generic Engineering Construction and Projects Limited',
    symbol: 'GENCON.NS',
  },
  {
    value: 'GET&D.NS',
    label: 'GET&D.NS,  GE T&D India Limited',
    name: 'GE T&D India Limited',
    symbol: 'GET&D.NS',
  },
  {
    value: 'GLAND.NS',
    label: 'GLAND.NS,  Gland Pharma Limited',
    name: 'Gland Pharma Limited',
    symbol: 'GLAND.NS',
  },
  {
    value: 'GLOBAL.NS',
    label: 'GLOBAL.NS,  Global Education Limited',
    name: 'Global Education Limited',
    symbol: 'GLOBAL.NS',
  },
  {
    value: 'GLS.NS',
    label: 'GLS.NS,  Glenmark Life Sciences Limited',
    name: 'Glenmark Life Sciences Limited',
    symbol: 'GLS.NS',
  },
  {
    value: 'GOLDSHARE.NS',
    label: 'GOLDSHARE.NS,  UTI Gold ETF',
    name: 'UTI Gold ETF',
    symbol: 'GOLDSHARE.NS',
  },
  {
    value: 'GOODYEAR.NS',
    label: 'GOODYEAR.NS,  Goodyear India Limited',
    name: 'Goodyear India Limited',
    symbol: 'GOODYEAR.NS',
  },
  {
    value: 'GRAUWEIL.NS',
    label: 'GRAUWEIL.NS,  Grauer & Weil (India) Limited',
    name: 'Grauer & Weil (India) Limited',
    symbol: 'GRAUWEIL.NS',
  },
  {
    value: 'GRINFRA.NS',
    label: 'GRINFRA.NS,  G R Infraprojects Limited',
    name: 'G R Infraprojects Limited',
    symbol: 'GRINFRA.NS',
  },
  {
    value: 'HEMIPROP.NS',
    label: 'HEMIPROP.NS,  Hemisphere Properties India Limited',
    name: 'Hemisphere Properties India Limited',
    symbol: 'HEMIPROP.NS',
  },
  {
    value: 'HERANBA.NS',
    label: 'HERANBA.NS,  Heranba Industries Limited',
    name: 'Heranba Industries Limited',
    symbol: 'HERANBA.NS',
  },
  {
    value: 'HINDCON.NS',
    label: 'HINDCON.NS,  Hindcon Chemicals Limited',
    name: 'Hindcon Chemicals Limited',
    symbol: 'HINDCON.NS',
  },
  {
    value: 'HLEGLAS.NS',
    label: 'HLEGLAS.NS,  HLE Glascoat Limited',
    name: 'HLE Glascoat Limited',
    symbol: 'HLEGLAS.NS',
  },
  {
    value: 'HOMEFIRST.NS',
    label: 'HOMEFIRST.NS,  Home First Finance Company India Limited',
    name: 'Home First Finance Company India Limited',
    symbol: 'HOMEFIRST.NS',
  },
  {
    value: 'HUHTAMAKI.NS',
    label: 'HUHTAMAKI.NS,  Huhtamaki India Limited',
    name: 'Huhtamaki India Limited',
    symbol: 'HUHTAMAKI.NS',
  },
  {
    value: 'ICEMAKE.NS',
    label: 'ICEMAKE.NS,  Ice Make Refrigeration Limited',
    name: 'Ice Make Refrigeration Limited',
    symbol: 'ICEMAKE.NS',
  },
  {
    value: 'ICICI500.NS',
    label: 'ICICI500.NS,  ICICI Prudential S&P BSE 500 ETF',
    name: 'ICICI Prudential S&P BSE 500 ETF',
    symbol: 'ICICI500.NS',
  },
  {
    value: 'ICICIALPLV.NS',
    label: 'ICICIALPLV.NS,  ICICI Prudential Nifty Alpha Low Volatility 30 ETF',
    name: 'ICICI Prudential Nifty Alpha Low Volatility 30 ETF',
    symbol: 'ICICIALPLV.NS',
  },
  {
    value: 'ICICIB22.NS',
    label: 'ICICIB22.NS,  BHARAT 22 ETF',
    name: 'BHARAT 22 ETF',
    symbol: 'ICICIB22.NS',
  },
  {
    value: 'ICICIFMCG.NS',
    label: 'ICICIFMCG.NS,  ICICI Prudential Nifty FMCG ETF',
    name: 'ICICI Prudential Nifty FMCG ETF',
    symbol: 'ICICIFMCG.NS',
  },
  {
    value: 'ICICILIQ.NS',
    label: 'ICICILIQ.NS,  ICICI Prudential S&P BSE Liquid Rate ETF',
    name: 'ICICI Prudential S&P BSE Liquid Rate ETF',
    symbol: 'ICICILIQ.NS',
  },
  {
    value: 'ICICILOVOL.NS',
    label: 'ICICILOVOL.NS,  ICICI Prudential Nifty 100 Low Volatility 30 ETF',
    name: 'ICICI Prudential Nifty 100 Low Volatility 30 ETF',
    symbol: 'ICICILOVOL.NS',
  },
  {
    value: 'ICICIM150.NS',
    label: 'ICICIM150.NS,  ICICI Prudential Nifty Midcap 150 ETF',
    name: 'ICICI Prudential Nifty Midcap 150 ETF',
    symbol: 'ICICIM150.NS',
  },
  {
    value: 'ICICIMCAP.NS',
    label: 'ICICIMCAP.NS,  ICICI Prudential Midcap Select ETF',
    name: 'ICICI Prudential Midcap Select ETF',
    symbol: 'ICICIMCAP.NS',
  },
  {
    value: 'ICICINF100.NS',
    label: 'ICICINF100.NS,  ICICI Prudential Nifty 100 ETF',
    name: 'ICICI Prudential Nifty 100 ETF',
    symbol: 'ICICINF100.NS',
  },
  {
    value: 'ICICINV20.NS',
    label: 'ICICINV20.NS,  ICICI Prudential Nifty50 Value 20 ETF',
    name: 'ICICI Prudential Nifty50 Value 20 ETF',
    symbol: 'ICICINV20.NS',
  },
  {
    value: 'ICICINXT50.NS',
    label: 'ICICINXT50.NS,  ICICI Prudential Nifty Next 50 ETF',
    name: 'ICICI Prudential Nifty Next 50 ETF',
    symbol: 'ICICINXT50.NS',
  },
  {
    value: 'ICICIPHARM.NS',
    label: 'ICICIPHARM.NS,  ICICI Prudential Nifty Healthcare ETF',
    name: 'ICICI Prudential Nifty Healthcare ETF',
    symbol: 'ICICIPHARM.NS',
  },
  {
    value: 'ICICISENSX.NS',
    label: 'ICICISENSX.NS,  ICICI Prudential S&P BSE Sensex ETF',
    name: 'ICICI Prudential S&P BSE Sensex ETF',
    symbol: 'ICICISENSX.NS',
  },
  {
    value: 'ICICITECH.NS',
    label: 'ICICITECH.NS,  ICICI Prudential Nifty IT ETF',
    name: 'ICICI Prudential Nifty IT ETF',
    symbol: 'ICICITECH.NS',
  },
  {
    value: 'INTLCONV.NS',
    label: 'INTLCONV.NS,  International Conveyors Ltd',
    name: 'International Conveyors Ltd',
    symbol: 'INTLCONV.NS',
  },
  {
    value: 'IPL.NS',
    label: 'IPL.NS,  India Pesticides Limited',
    name: 'India Pesticides Limited',
    symbol: 'IPL.NS',
  },
  {
    value: 'IRFC.NS',
    label: 'IRFC.NS,  Indian Railway Finance Corporation Limited',
    name: 'Indian Railway Finance Corporation Limited',
    symbol: 'IRFC.NS',
  },
  {
    value: 'ISGEC.NS',
    label: 'ISGEC.NS,  Isgec Heavy Engineering Limited',
    name: 'Isgec Heavy Engineering Limited',
    symbol: 'ISGEC.NS',
  },
  {
    value: 'JAIPURKURT.NS',
    label: 'JAIPURKURT.NS,  Nandani Creation Limited',
    name: 'Nandani Creation Limited',
    symbol: 'JAIPURKURT.NS',
  },
  {
    value: 'JISLDVREQS.NS',
    label: 'JISLDVREQS.NS,  Jain Irrigation Systems Limited',
    name: 'Jain Irrigation Systems Limited',
    symbol: 'JISLDVREQS.NS',
  },
  {
    value: 'JSWISPL.NS',
    label: 'JSWISPL.NS,  JSW Ispat Special Products Limited',
    name: 'JSW Ispat Special Products Limited',
    symbol: 'JSWISPL.NS',
  },
  {
    value: 'KALYANKJIL.NS',
    label: 'KALYANKJIL.NS,  Kalyan Jewellers India Limited',
    name: 'Kalyan Jewellers India Limited',
    symbol: 'KALYANKJIL.NS',
  },
  {
    value: 'KANPRPLA.NS',
    label: 'KANPRPLA.NS,  Kanpur Plastipack Limited',
    name: 'Kanpur Plastipack Limited',
    symbol: 'KANPRPLA.NS',
  },
  {
    value: 'KEERTI.NS',
    label: 'KEERTI.NS,  Keerti Knowledge and Skills Limited',
    name: 'Keerti Knowledge and Skills Limited',
    symbol: 'KEERTI.NS',
  },
  {
    value: 'KHAICHEM.NS',
    label: 'KHAICHEM.NS,  Khaitan Chemicals & Fertilizers Ltd',
    name: 'Khaitan Chemicals & Fertilizers Ltd',
    symbol: 'KHAICHEM.NS',
  },
  {
    value: 'KIMS.NS',
    label: 'KIMS.NS,  Krishna Institute of Medical Sciences Limited',
    name: 'Krishna Institute of Medical Sciences Limited',
    symbol: 'KIMS.NS',
  },
  {
    value: 'KOTAKIT.NS',
    label: 'KOTAKIT.NS,  Kotak Nifty IT ETF',
    name: 'Kotak Nifty IT ETF',
    symbol: 'KOTAKIT.NS',
  },
  {
    value: 'KOTAKNV20.NS',
    label: 'KOTAKNV20.NS,  Kotak Nifty 50 Value 20 ETF',
    name: 'Kotak Nifty 50 Value 20 ETF',
    symbol: 'KOTAKNV20.NS',
  },
  {
    value: 'KOTAKPSUBK.NS',
    label: 'KOTAKPSUBK.NS,  Kotak Nifty PSU Bank ETF',
    name: 'Kotak Nifty PSU Bank ETF',
    symbol: 'KOTAKPSUBK.NS',
  },
  {
    value: 'KOVAI.NS',
    label: 'KOVAI.NS,  Kovai Medical Center and Hospital Limited',
    name: 'Kovai Medical Center and Hospital Limited',
    symbol: 'KOVAI.NS',
  },
  {
    value: 'KRSNAA.NS',
    label: 'KRSNAA.NS,  Krsnaa Diagnostics Limited',
    name: 'Krsnaa Diagnostics Limited',
    symbol: 'KRSNAA.NS',
  },
  {
    value: 'LICNFNHGP.NS',
    label: 'LICNFNHGP.NS,  LIC MF Nifty 100 ETF',
    name: 'LIC MF Nifty 100 ETF',
    symbol: 'LICNFNHGP.NS',
  },
  {
    value: 'LIKHITHA.NS',
    label: 'LIKHITHA.NS,  Likhitha Infrastructure Limited',
    name: 'Likhitha Infrastructure Limited',
    symbol: 'LIKHITHA.NS',
  },
  {
    value: 'LODHA.NS',
    label: 'LODHA.NS,  Macrotech Developers Limited',
    name: 'Macrotech Developers Limited',
    symbol: 'LODHA.NS',
  },
  {
    value: 'LXCHEM.NS',
    label: 'LXCHEM.NS,  Laxmi Organic Industries Limited',
    name: 'Laxmi Organic Industries Limited',
    symbol: 'LXCHEM.NS',
  },
  {
    value: 'MACPOWER.NS',
    label: 'MACPOWER.NS,  Macpower CNC Machines Limited',
    name: 'Macpower CNC Machines Limited',
    symbol: 'MACPOWER.NS',
  },
  {
    value: 'MAFANG.NS',
    label: 'MAFANG.NS,  Mirae Asset NYSE FANG+ETF',
    name: 'Mirae Asset NYSE FANG+ETF',
    symbol: 'MAFANG.NS',
  },
  {
    value: 'MANXT50.NS',
    label: 'MANXT50.NS,  Mirae Asset Nifty Next 50 ETF',
    name: 'Mirae Asset Nifty Next 50 ETF',
    symbol: 'MANXT50.NS',
  },
  {
    value: 'MARINE.NS',
    label: 'MARINE.NS,  Marine Electricals (India) Limited',
    name: 'Marine Electricals (India) Limited',
    symbol: 'MARINE.NS',
  },
  {
    value: 'MASPTOP50.NS',
    label: 'MASPTOP50.NS,  Mirae Asset S&P 500 Top 50 ETF',
    name: 'Mirae Asset S&P 500 Top 50 ETF',
    symbol: 'MASPTOP50.NS',
  },
  {
    value: 'MAXIND.NS',
    label: 'MAXIND.NS,  Max India Limited',
    name: 'Max India Limited',
    symbol: 'MAXIND.NS',
  },
  {
    value: 'MAZDOCK.NS',
    label: 'MAZDOCK.NS,  Mazagon Dock Shipbuilders Limited',
    name: 'Mazagon Dock Shipbuilders Limited',
    symbol: 'MAZDOCK.NS',
  },
  {
    value: 'MCL.NS',
    label: 'MCL.NS,  Madhav Copper Limited',
    name: 'Madhav Copper Limited',
    symbol: 'MCL.NS',
  },
  {
    value: 'MFL.NS',
    label: 'MFL.NS,  Meghmani Finechem Limited',
    name: 'Meghmani Finechem Limited',
    symbol: 'MFL.NS',
  },
  {
    value: 'MGEL.NS',
    label: 'MGEL.NS,  Mangalam Global Enterprise Limited',
    name: 'Mangalam Global Enterprise Limited',
    symbol: 'MGEL.NS',
  },
  {
    value: 'MOGSEC.NS',
    label:
      'MOGSEC.NS,  Motilal Oswal Amc Ltd. - Motilal Oswal Mutual Fund - Motilal Oswal 5 Year G-Sec ETF',
    name: 'Motilal Oswal Amc Ltd. - Motilal Oswal Mutual Fund - Motilal Oswal 5 Year G-Sec ETF',
    symbol: 'MOGSEC.NS',
  },
  {
    value: 'MOKSH.NS',
    label: 'MOKSH.NS,  Moksh Ornaments Limited',
    name: 'Moksh Ornaments Limited',
    symbol: 'MOKSH.NS',
  },
  {
    value: 'MOL.NS',
    label: 'MOL.NS,  Meghmani Organics Limited',
    name: 'Meghmani Organics Limited',
    symbol: 'MOL.NS',
  },
  {
    value: 'MOM100.NS',
    label: 'MOM100.NS,  Motilal Oswal Nifty Midcap 100 ETF',
    name: 'Motilal Oswal Nifty Midcap 100 ETF',
    symbol: 'MOM100.NS',
  },
  {
    value: 'MOM50.NS',
    label: 'MOM50.NS,  Motilal Oswal M50 ETF',
    name: 'Motilal Oswal M50 ETF',
    symbol: 'MOM50.NS',
  },
  {
    value: 'MON100.NS',
    label: 'MON100.NS,  Motilal Oswal NASDAQ 100 ETF',
    name: 'Motilal Oswal NASDAQ 100 ETF',
    symbol: 'MON100.NS',
  },
  {
    value: 'MTARTECH.NS',
    label: 'MTARTECH.NS,  MTAR Technologies Limited',
    name: 'MTAR Technologies Limited',
    symbol: 'MTARTECH.NS',
  },
  {
    value: 'NAZARA.NS',
    label: 'NAZARA.NS,  Nazara Technologies Limited',
    name: 'Nazara Technologies Limited',
    symbol: 'NAZARA.NS',
  },
  {
    value: 'NIRAJ.NS',
    label: 'NIRAJ.NS,  Niraj Cement Structurals Limited',
    name: 'Niraj Cement Structurals Limited',
    symbol: 'NIRAJ.NS',
  },
  {
    value: 'NITIRAJ.NS',
    label: 'NITIRAJ.NS,  Nitiraj Engineers Limited',
    name: 'Nitiraj Engineers Limited',
    symbol: 'NITIRAJ.NS',
  },
  {
    value: 'NOVARTIND.NS',
    label: 'NOVARTIND.NS,  Novartis India Limited',
    name: 'Novartis India Limited',
    symbol: 'NOVARTIND.NS',
  },
  {
    value: 'NPBET.NS',
    label: 'NPBET.NS,  Tata Nifty Private Bank ETF',
    name: 'Tata Nifty Private Bank ETF',
    symbol: 'NPBET.NS',
  },
  {
    value: 'NURECA.NS',
    label: 'NURECA.NS,  Nureca Limited',
    name: 'Nureca Limited',
    symbol: 'NURECA.NS',
  },
  {
    value: 'NUVOCO.NS',
    label: 'NUVOCO.NS,  Nuvoco Vistas Corporation Limited',
    name: 'Nuvoco Vistas Corporation Limited',
    symbol: 'NUVOCO.NS',
  },
  {
    value: 'OMINFRAL.NS',
    label: 'OMINFRAL.NS,  Om Infra Limited',
    name: 'Om Infra Limited',
    symbol: 'OMINFRAL.NS',
  },
  {
    value: 'ORTINLAB.NS',
    label: 'ORTINLAB.NS,  Ortin Laboratories Limited',
    name: 'Ortin Laboratories Limited',
    symbol: 'ORTINLAB.NS',
  },
  {
    value: 'PAR.NS',
    label: 'PAR.NS,  Par Drugs and Chemicals Limited',
    name: 'Par Drugs and Chemicals Limited',
    symbol: 'PAR.NS',
  },
  {
    value: 'PRITIKAUTO.NS',
    label: 'PRITIKAUTO.NS,  Pritika Auto Industries Limited',
    name: 'Pritika Auto Industries Limited',
    symbol: 'PRITIKAUTO.NS',
  },
  {
    value: 'PRIVISCL.NS',
    label: 'PRIVISCL.NS,  Privi Speciality Chemicals Limited',
    name: 'Privi Speciality Chemicals Limited',
    symbol: 'PRIVISCL.NS',
  },
  {
    value: 'RAILTEL.NS',
    label: 'RAILTEL.NS,  RailTel Corporation of India Limited',
    name: 'RailTel Corporation of India Limited',
    symbol: 'RAILTEL.NS',
  },
  {
    value: 'REPL.NS',
    label: 'REPL.NS,  Rudrabhishek Enterprises Limited',
    name: 'Rudrabhishek Enterprises Limited',
    symbol: 'REPL.NS',
  },
  {
    value: 'RHIM.NS',
    label: 'RHIM.NS,  RHI Magnesita India Limited',
    name: 'RHI Magnesita India Limited',
    symbol: 'RHIM.NS',
  },
  {
    value: 'RKEC.NS',
    label: 'RKEC.NS,  RKEC Projects Limited',
    name: 'RKEC Projects Limited',
    symbol: 'RKEC.NS',
  },
  {
    value: 'ROLEXRINGS.NS',
    label: 'ROLEXRINGS.NS,  Rolex Rings Limited',
    name: 'Rolex Rings Limited',
    symbol: 'ROLEXRINGS.NS',
  },
  {
    value: 'ROSSARI.NS',
    label: 'ROSSARI.NS,  Rossari Biotech Limited',
    name: 'Rossari Biotech Limited',
    symbol: 'ROSSARI.NS',
  },
  {
    value: 'RPSGVENT.NS',
    label: 'RPSGVENT.NS,  RPSG Ventures Limited',
    name: 'RPSG Ventures Limited',
    symbol: 'RPSGVENT.NS',
  },
  {
    value: 'RVHL.NS',
    label: 'RVHL.NS,  Ravinder Heights Limited',
    name: 'Ravinder Heights Limited',
    symbol: 'RVHL.NS',
  },
  {
    value: 'SANSERA.NS',
    label: 'SANSERA.NS,  Sansera Engineering Limited',
    name: 'Sansera Engineering Limited',
    symbol: 'SANSERA.NS',
  },
  {
    value: 'SBCL.NS',
    label: 'SBCL.NS,  Shivalik Bimetal Controls Limited',
    name: 'Shivalik Bimetal Controls Limited',
    symbol: 'SBCL.NS',
  },
  {
    value: 'SECURKLOUD.NS',
    label: 'SECURKLOUD.NS,  SecureKloud Technologies Limited',
    name: 'SecureKloud Technologies Limited',
    symbol: 'SECURKLOUD.NS',
  },
  {
    value: 'SERVOTECH.NS',
    label: 'SERVOTECH.NS,  Servotech Power Systems Limited',
    name: 'Servotech Power Systems Limited',
    symbol: 'SERVOTECH.NS',
  },
  {
    value: 'SGIL.NS',
    label: 'SGIL.NS,  Synergy Green Industries Limited',
    name: 'Synergy Green Industries Limited',
    symbol: 'SGIL.NS',
  },
  {
    value: 'SHANTI.NS',
    label: 'SHANTI.NS,  Shanti Overseas (India) Limited',
    name: 'Shanti Overseas (India) Limited',
    symbol: 'SHANTI.NS',
  },
  {
    value: 'SHAREINDIA.NS',
    label: 'SHAREINDIA.NS,  Share India Securities Limited',
    name: 'Share India Securities Limited',
    symbol: 'SHAREINDIA.NS',
  },
  {
    value: 'SHRADHA.NS',
    label: 'SHRADHA.NS,  Shradha Infraprojects Limited',
    name: 'Shradha Infraprojects Limited',
    symbol: 'SHRADHA.NS',
  },
  {
    value: 'SHYAMMETL.NS',
    label: 'SHYAMMETL.NS,  Shyam Metalics and Energy Limited',
    name: 'Shyam Metalics and Energy Limited',
    symbol: 'SHYAMMETL.NS',
  },
  {
    value: 'SILGO.NS',
    label: 'SILGO.NS,  Silgo Retail Limited',
    name: 'Silgo Retail Limited',
    symbol: 'SILGO.NS',
  },
  {
    value: 'SILLYMONKS.NS',
    label: 'SILLYMONKS.NS,  Silly Monks Entertainment Limited',
    name: 'Silly Monks Entertainment Limited',
    symbol: 'SILLYMONKS.NS',
  },
  {
    value: 'SINTERCOM.NS',
    label: 'SINTERCOM.NS,  Sintercom India Limited',
    name: 'Sintercom India Limited',
    symbol: 'SINTERCOM.NS',
  },
  {
    value: 'SMCGLOBAL.NS',
    label: 'SMCGLOBAL.NS,  SMC Global Securities Limited',
    name: 'SMC Global Securities Limited',
    symbol: 'SMCGLOBAL.NS',
  },
  {
    value: 'SONACOMS.NS',
    label: 'SONACOMS.NS,  Sona BLW Precision Forgings Limited',
    name: 'Sona BLW Precision Forgings Limited',
    symbol: 'SONACOMS.NS',
  },
  {
    value: 'STOVEKRAFT.NS',
    label: 'STOVEKRAFT.NS,  Stove Kraft Limited',
    name: 'Stove Kraft Limited',
    symbol: 'STOVEKRAFT.NS',
  },
  {
    value: 'STYLAMIND.NS',
    label: 'STYLAMIND.NS,  Stylam Industries Limited',
    name: 'Stylam Industries Limited',
    symbol: 'STYLAMIND.NS',
  },
  {
    value: 'SUBEXLTD.NS',
    label: 'SUBEXLTD.NS,  Subex Limited',
    name: 'Subex Limited',
    symbol: 'SUBEXLTD.NS',
  },
  {
    value: 'SUPREMEENG.NS',
    label: 'SUPREMEENG.NS,  Supreme Engineering Limited',
    name: 'Supreme Engineering Limited',
    symbol: 'SUPREMEENG.NS',
  },
  {
    value: 'SURANAT&P.NS',
    label: 'SURANAT&P.NS,  Surana Telecom and Power Limited',
    name: 'Surana Telecom and Power Limited',
    symbol: 'SURANAT&P.NS',
  },
  {
    value: 'SURYODAY.NS',
    label: 'SURYODAY.NS,  Suryoday Small Finance Bank Limited',
    name: 'Suryoday Small Finance Bank Limited',
    symbol: 'SURYODAY.NS',
  },
  {
    value: 'SUULD.NS',
    label: 'SUULD.NS,  Suumaya Industries Limited',
    name: 'Suumaya Industries Limited',
    symbol: 'SUULD.NS',
  },
  {
    value: 'SUVIDHAA.NS',
    label: 'SUVIDHAA.NS,  Suvidhaa Infoserve Limited',
    name: 'Suvidhaa Infoserve Limited',
    symbol: 'SUVIDHAA.NS',
  },
  {
    value: 'SVPGLOB.NS',
    label: 'SVPGLOB.NS,  SVP Global Textiles Limited',
    name: 'SVP Global Textiles Limited',
    symbol: 'SVPGLOB.NS',
  },
  {
    value: 'TARAPUR.NS',
    label: 'TARAPUR.NS,  Tarapur Transformers Limited',
    name: 'Tarapur Transformers Limited',
    symbol: 'TARAPUR.NS',
  },
  {
    value: 'TATVA.NS',
    label: 'TATVA.NS,  Tatva Chintan Pharma Chem Limited',
    name: 'Tatva Chintan Pharma Chem Limited',
    symbol: 'TATVA.NS',
  },
  {
    value: 'TEMBO.NS',
    label: 'TEMBO.NS,  Tembo Global Industries Limited',
    name: 'Tembo Global Industries Limited',
    symbol: 'TEMBO.NS',
  },
  {
    value: 'TOTAL.NS',
    label: 'TOTAL.NS,  Total Transport Systems Limited',
    name: 'Total Transport Systems Limited',
    symbol: 'TOTAL.NS',
  },
  {
    value: 'UGROCAP.NS',
    label: 'UGROCAP.NS,  UGRO Capital Limited',
    name: 'UGRO Capital Limited',
    symbol: 'UGROCAP.NS',
  },
  {
    value: 'UNIDT.NS',
    label: 'UNIDT.NS,  United Drilling Tools Limited',
    name: 'United Drilling Tools Limited',
    symbol: 'UNIDT.NS',
  },
  {
    value: 'VALIANTORG.NS',
    label: 'VALIANTORG.NS,  Valiant Organics Limited',
    name: 'Valiant Organics Limited',
    symbol: 'VALIANTORG.NS',
  },
  {
    value: 'VIJAYA.NS',
    label: 'VIJAYA.NS,  Vijaya Diagnostic Centre Limited',
    name: 'Vijaya Diagnostic Centre Limited',
    symbol: 'VIJAYA.NS',
  },
  {
    value: 'VINEETLAB.NS',
    label: 'VINEETLAB.NS,  Vineet Laboratories Limited',
    name: 'Vineet Laboratories Limited',
    symbol: 'VINEETLAB.NS',
  },
  {
    value: 'WINDLAS.NS',
    label: 'WINDLAS.NS,  Windlas Biotech Limited',
    name: 'Windlas Biotech Limited',
    symbol: 'WINDLAS.NS',
  },
  {
    value: 'WORTH.NS',
    label: 'WORTH.NS,  Worth Peripherals Limited',
    name: 'Worth Peripherals Limited',
    symbol: 'WORTH.NS',
  },
  {
    value: 'YUKEN.NS',
    label: 'YUKEN.NS,  Yuken India Limited',
    name: 'Yuken India Limited',
    symbol: 'YUKEN.NS',
  },
  {
    value: 'ZOMATO.NS',
    label: 'ZOMATO.NS,  Zomato Limited',
    name: 'Zomato Limited',
    symbol: 'ZOMATO.NS',
  },
  {
    value: 'AMPL',
    label: 'AMPL,  Amplitude, Inc.',
    name: 'Amplitude, Inc.',
    symbol: 'AMPL',
  },
  {
    value: 'B1C.F',
    label: 'B1C.F,  Baidu, Inc.',
    name: 'Baidu, Inc.',
    symbol: 'B1C.F',
  },
  {
    value: 'BY6.F',
    label: 'BY6.F,  BYD Company Limited',
    name: 'BYD Company Limited',
    symbol: 'BY6.F',
  },
  {
    value: '0QF.F',
    label: '0QF.F,  Moderna, Inc.',
    name: 'Moderna, Inc.',
    symbol: '0QF.F',
  },
  {
    value: 'N3IA.F',
    label: 'N3IA.F,  NIO Inc.',
    name: 'NIO Inc.',
    symbol: 'N3IA.F',
  },
  {
    value: '0O9.F',
    label: '0O9.F,  Niu Technologies',
    name: 'Niu Technologies',
    symbol: '0O9.F',
  },
  {
    value: 'NVV1.F',
    label: 'NVV1.F,  Novavax, Inc.',
    name: 'Novavax, Inc.',
    symbol: 'NVV1.F',
  },
  {
    value: 'R35.F',
    label: 'R35.F,  Roku, Inc.',
    name: 'Roku, Inc.',
    symbol: 'R35.F',
  },
  {
    value: 'SIX3.BE',
    label: 'SIX3.BE,  Sixt SE',
    name: 'Sixt SE',
    symbol: 'SIX3.BE',
  },
  {
    value: 'SOH1.F',
    label: 'SOH1.F,  Soitec S.A.',
    name: 'Soitec S.A.',
    symbol: 'SOH1.F',
  },
  {
    value: 'SQ3.F',
    label: 'SQ3.F,  Block, Inc.',
    name: 'Block, Inc.',
    symbol: 'SQ3.F',
  },
  {
    value: '6I4.F',
    label: '6I4.F,  Zaptec AS',
    name: 'Zaptec AS',
    symbol: '6I4.F',
  },
  {
    value: 'ALAUD.PA',
    label: 'ALAUD.PA,  Audacia Société anonyme',
    name: 'Audacia Société anonyme',
    symbol: 'ALAUD.PA',
  },
  {
    value: 'SHPW',
    label: 'SHPW,  Shapeways Holdings, Inc.',
    name: 'Shapeways Holdings, Inc.',
    symbol: 'SHPW',
  },
  {
    value: 'SNT',
    label: 'SNT,  Senstar Technologies Ltd.',
    name: 'Senstar Technologies Ltd.',
    symbol: 'SNT',
  },
  {
    value: 'VLD',
    label: 'VLD,  Velo3D, Inc.',
    name: 'Velo3D, Inc.',
    symbol: 'VLD',
  },
  {
    value: 'BHIL',
    label: 'BHIL,  Benson Hill, Inc.',
    name: 'Benson Hill, Inc.',
    symbol: 'BHIL',
  },
  {
    value: 'CPAA',
    label: 'CPAA,  Conyers Park III Acquisition Corp.',
    name: 'Conyers Park III Acquisition Corp.',
    symbol: 'CPAA',
  },
  {
    value: 'ABDN.L',
    label: 'ABDN.L,  abrdn plc',
    name: 'abrdn plc',
    symbol: 'ABDN.L',
  },
  {
    value: 'ABOS',
    label: 'ABOS,  Acumen Pharmaceuticals, Inc.',
    name: 'Acumen Pharmaceuticals, Inc.',
    symbol: 'ABOS',
  },
  {
    value: 'ABSI',
    label: 'ABSI,  Absci Corporation',
    name: 'Absci Corporation',
    symbol: 'ABSI',
  },
  {
    value: 'AVTX',
    label: 'AVTX,  Avalo Therapeutics, Inc.',
    name: 'Avalo Therapeutics, Inc.',
    symbol: 'AVTX',
  },
  {
    value: 'BLFY',
    label: 'BLFY,  Blue Foundry Bancorp',
    name: 'Blue Foundry Bancorp',
    symbol: 'BLFY',
  },
  {
    value: 'DNAY',
    label: 'DNAY,  Telesis Bio, Inc.',
    name: 'Telesis Bio, Inc.',
    symbol: 'DNAY',
  },
  {
    value: 'EWCZ',
    label: 'EWCZ,  European Wax Center, Inc.',
    name: 'European Wax Center, Inc.',
    symbol: 'EWCZ',
  },
  {
    value: 'ICVX',
    label: 'ICVX,  Icosavax, Inc.',
    name: 'Icosavax, Inc.',
    symbol: 'ICVX',
  },
  {
    value: 'IMGO',
    label: 'IMGO,  Imago BioSciences, Inc.',
    name: 'Imago BioSciences, Inc.',
    symbol: 'IMGO',
  },
  {
    value: 'JANX',
    label: 'JANX,  Janux Therapeutics, Inc.',
    name: 'Janux Therapeutics, Inc.',
    symbol: 'JANX',
  },
  {
    value: 'MXCT',
    label: 'MXCT,  MaxCyte, Inc.',
    name: 'MaxCyte, Inc.',
    symbol: 'MXCT',
  },
  {
    value: 'RLYB',
    label: 'RLYB,  Rallybio Corporation',
    name: 'Rallybio Corporation',
    symbol: 'RLYB',
  },
  {
    value: 'RPID',
    label: 'RPID,  Rapid Micro Biosystems, Inc.',
    name: 'Rapid Micro Biosystems, Inc.',
    symbol: 'RPID',
  },
  {
    value: 'VATE',
    label: 'VATE,  INNOVATE Corp.',
    name: 'INNOVATE Corp.',
    symbol: 'VATE',
  },
  {
    value: 'WLLAW',
    label: 'WLLAW,  Chord Energy Corporation',
    name: 'Chord Energy Corporation',
    symbol: 'WLLAW',
  },
  {
    value: 'WLLBW',
    label: 'WLLBW,  Chord Energy Corporation',
    name: 'Chord Energy Corporation',
    symbol: 'WLLBW',
  },
  {
    value: 'NTRBW',
    label: 'NTRBW,  Nutriband Inc.',
    name: 'Nutriband Inc.',
    symbol: 'NTRBW',
  },
  {
    value: '000012.SZ',
    label: '000012.SZ,  CSG Holding Co., Ltd.',
    name: 'CSG Holding Co., Ltd.',
    symbol: '000012.SZ',
  },
  {
    value: 'BSKY',
    label: 'BSKY,  Big Sky Growth Partners, Inc.',
    name: 'Big Sky Growth Partners, Inc.',
    symbol: 'BSKY',
  },
  {
    value: 'ONT.L',
    label: 'ONT.L,  Oxford Nanopore Technologies plc',
    name: 'Oxford Nanopore Technologies plc',
    symbol: 'ONT.L',
  },
  {
    value: 'ZHDG',
    label: 'ZHDG,  ZEGA Buy and Hedge ETF',
    name: 'ZEGA Buy and Hedge ETF',
    symbol: 'ZHDG',
  },
  {
    value: 'XOS',
    label: 'XOS,  Xos, Inc.',
    name: 'Xos, Inc.',
    symbol: 'XOS',
  },
  {
    value: 'WKSP',
    label: 'WKSP,  Worksport Ltd.',
    name: 'Worksport Ltd.',
    symbol: 'WKSP',
  },
  {
    value: 'VQS',
    label: 'VQS,  VIQ Solutions Inc.',
    name: 'VIQ Solutions Inc.',
    symbol: 'VQS',
  },
  {
    value: 'VLTA',
    label: 'VLTA,  Volta Inc.',
    name: 'Volta Inc.',
    symbol: 'VLTA',
  },
  {
    value: 'VEV',
    label: 'VEV,  Vicinity Motor Corp.',
    name: 'Vicinity Motor Corp.',
    symbol: 'VEV',
  },
  {
    value: 'STVN',
    label: 'STVN,  Stevanato Group S.p.A.',
    name: 'Stevanato Group S.p.A.',
    symbol: 'STVN',
  },
  {
    value: 'SOXQ',
    label: 'SOXQ,  Invesco PHLX Semiconductor ETF',
    name: 'Invesco PHLX Semiconductor ETF',
    symbol: 'SOXQ',
  },
  {
    value: 'SILX',
    label: 'SILX,  ETFMG Prime 2x Daily Junior Silver Miners ETF',
    name: 'ETFMG Prime 2x Daily Junior Silver Miners ETF',
    symbol: 'SILX',
  },
  {
    value: 'SCHY',
    label: 'SCHY,  Schwab International Dividend Equity ETF',
    name: 'Schwab International Dividend Equity ETF',
    symbol: 'SCHY',
  },
  {
    value: 'ROVR',
    label: 'ROVR,  Rover Group, Inc.',
    name: 'Rover Group, Inc.',
    symbol: 'ROVR',
  },
  {
    value: 'RKLB',
    label: 'RKLB,  Rocket Lab USA, Inc.',
    name: 'Rocket Lab USA, Inc.',
    symbol: 'RKLB',
  },
  {
    value: 'RBOT',
    label: 'RBOT,  Vicarious Surgical Inc.',
    name: 'Vicarious Surgical Inc.',
    symbol: 'RBOT',
  },
  {
    value: 'QIPT',
    label: 'QIPT,  Quipt Home Medical Corp.',
    name: 'Quipt Home Medical Corp.',
    symbol: 'QIPT',
  },
  {
    value: 'OTMO',
    label: 'OTMO,  Otonomo Technologies Ltd.',
    name: 'Otonomo Technologies Ltd.',
    symbol: 'OTMO',
  },
  {
    value: 'OPFI',
    label: 'OPFI,  OppFi Inc.',
    name: 'OppFi Inc.',
    symbol: 'OPFI',
  },
  {
    value: 'MTTR',
    label: 'MTTR,  Matterport, Inc.',
    name: 'Matterport, Inc.',
    symbol: 'MTTR',
  },
  {
    value: 'MNTS',
    label: 'MNTS,  Momentus Inc.',
    name: 'Momentus Inc.',
    symbol: 'MNTS',
  },
  {
    value: 'ML',
    label: 'ML,  MoneyLion Inc.',
    name: 'MoneyLion Inc.',
    symbol: 'ML',
  },
  {
    value: '000869.SZ',
    label: '000869.SZ,  Yantai Changyu Pioneer Wine Company Limited',
    name: 'Yantai Changyu Pioneer Wine Company Limited',
    symbol: '000869.SZ',
  },
  {
    value: 'MJXL',
    label: 'MJXL,  ETFMG 2X Daily Alternative Harvest ETF',
    name: 'ETFMG 2X Daily Alternative Harvest ETF',
    symbol: 'MJXL',
  },
  {
    value: 'LILM',
    label: 'LILM,  Lilium N.V.',
    name: 'Lilium N.V.',
    symbol: 'LILM',
  },
  {
    value: 'LIDR',
    label: 'LIDR,  AEye, Inc.',
    name: 'AEye, Inc.',
    symbol: 'LIDR',
  },
  {
    value: 'LICY',
    label: 'LICY,  Li-Cycle Holdings Corp.',
    name: 'Li-Cycle Holdings Corp.',
    symbol: 'LICY',
  },
  {
    value: 'LCTU',
    label: 'LCTU,  BlackRock U.S. Carbon Transition Readiness ETF',
    name: 'BlackRock U.S. Carbon Transition Readiness ETF',
    symbol: 'LCTU',
  },
  {
    value: 'KLNE',
    label: 'KLNE,  Direxion Daily Global Clean Energy Bull 2X Shares',
    name: 'Direxion Daily Global Clean Energy Bull 2X Shares',
    symbol: 'KLNE',
  },
  {
    value: 'JOBY',
    label: 'JOBY,  Joby Aviation, Inc.',
    name: 'Joby Aviation, Inc.',
    symbol: 'JOBY',
  },
  {
    value: 'IBBQ',
    label: 'IBBQ,  Invesco Nasdaq Biotechnology ETF',
    name: 'Invesco Nasdaq Biotechnology ETF',
    symbol: 'IBBQ',
  },
  {
    value: 'HUMA',
    label: 'HUMA,  Humacyte, Inc.',
    name: 'Humacyte, Inc.',
    symbol: 'HUMA',
  },
  {
    value: 'HJEN',
    label: 'HJEN,  Direxion Hydrogen ETF',
    name: 'Direxion Hydrogen ETF',
    symbol: 'HJEN',
  },
  {
    value: 'HIPO',
    label: 'HIPO,  Hippo Holdings Inc.',
    name: 'Hippo Holdings Inc.',
    symbol: 'HIPO',
  },
  {
    value: 'GREE',
    label: 'GREE,  Greenidge Generation Holdings Inc.',
    name: 'Greenidge Generation Holdings Inc.',
    symbol: 'GREE',
  },
  {
    value: 'FFIE',
    label: 'FFIE,  Faraday Future Intelligent Electric Inc.',
    name: 'Faraday Future Intelligent Electric Inc.',
    symbol: 'FFIE',
  },
  {
    value: '000933.SZ',
    label: '000933.SZ,  Henan Shenhuo Coal & Power Co.,Ltd',
    name: 'Henan Shenhuo Coal & Power Co.,Ltd',
    symbol: '000933.SZ',
  },
  {
    value: 'DPRO',
    label: 'DPRO,  Draganfly Inc.',
    name: 'Draganfly Inc.',
    symbol: 'DPRO',
  },
  {
    value: 'DOMA',
    label: 'DOMA,  Doma Holdings Inc.',
    name: 'Doma Holdings Inc.',
    symbol: 'DOMA',
  },
  {
    value: 'DFUS',
    label: 'DFUS,  Dimensional U.S. Equity ETF',
    name: 'Dimensional U.S. Equity ETF',
    symbol: 'DFUS',
  },
  {
    value: 'DFAT',
    label: 'DFAT,  Dimensional U.S. Targeted Value ETF',
    name: 'Dimensional U.S. Targeted Value ETF',
    symbol: 'DFAT',
  },
  {
    value: 'DFAS',
    label: 'DFAS,  Dimensional U.S. Small Cap ETF',
    name: 'Dimensional U.S. Small Cap ETF',
    symbol: 'DFAS',
  },
  {
    value: 'DFAC',
    label: 'DFAC,  Dimensional U.S. Core Equity 2 ETF',
    name: 'Dimensional U.S. Core Equity 2 ETF',
    symbol: 'DFAC',
  },
  {
    value: 'CZOO',
    label: 'CZOO,  Cazoo Group Ltd',
    name: 'Cazoo Group Ltd',
    symbol: 'CZOO',
  },
  {
    value: 'CYXT',
    label: 'CYXT,  Cyxtera Technologies, Inc.',
    name: 'Cyxtera Technologies, Inc.',
    symbol: 'CYXT',
  },
  {
    value: 'CRUZ',
    label: 'CRUZ,  Defiance Hotel, Airline, and Cruise ETF',
    name: 'Defiance Hotel, Airline, and Cruise ETF',
    symbol: 'CRUZ',
  },
  {
    value: 'CLBT',
    label: 'CLBT,  Cellebrite DI Ltd.',
    name: 'Cellebrite DI Ltd.',
    symbol: 'CLBT',
  },
  {
    value: 'CIFR',
    label: 'CIFR,  Cipher Mining Inc.',
    name: 'Cipher Mining Inc.',
    symbol: 'CIFR',
  },
  {
    value: 'CCCS',
    label: 'CCCS,  CCC Intelligent Solutions Holdings Inc.',
    name: 'CCC Intelligent Solutions Holdings Inc.',
    symbol: 'CCCS',
  },
  {
    value: 'BKSY',
    label: 'BKSY,  BlackSky Technology Inc.',
    name: 'BlackSky Technology Inc.',
    symbol: 'BKSY',
  },
  {
    value: 'BKCH',
    label: 'BKCH,  Global X Blockchain ETF',
    name: 'Global X Blockchain ETF',
    symbol: 'BKCH',
  },
  {
    value: 'BGRY',
    label: 'BGRY,  Berkshire Grey, Inc.',
    name: 'Berkshire Grey, Inc.',
    symbol: 'BGRY',
  },
  {
    value: 'AWYX',
    label: 'AWYX,  ETFMG 2x Daily Travel Tech ETF',
    name: 'ETFMG 2x Daily Travel Tech ETF',
    symbol: 'AWYX',
  },
  {
    value: 'AVPT',
    label: 'AVPT,  AvePoint, Inc.',
    name: 'AvePoint, Inc.',
    symbol: 'AVPT',
  },
  {
    value: 'ATY',
    label: 'ATY,  AcuityAds Holdings Inc.',
    name: 'AcuityAds Holdings Inc.',
    symbol: 'ATY',
  },
  {
    value: 'ATXS',
    label: 'ATXS,  Astria Therapeutics, Inc.',
    name: 'Astria Therapeutics, Inc.',
    symbol: 'ATXS',
  },
  {
    value: 'ATAI',
    label: 'ATAI,  Atai Life Sciences N.V.',
    name: 'Atai Life Sciences N.V.',
    symbol: 'ATAI',
  },
  {
    value: 'AMBP',
    label: 'AMBP,  Ardagh Metal Packaging S.A.',
    name: 'Ardagh Metal Packaging S.A.',
    symbol: 'AMBP',
  },
  {
    value: 'ALIT',
    label: 'ALIT,  Alight, Inc.',
    name: 'Alight, Inc.',
    symbol: 'ALIT',
  },
  {
    value: 'ACHR',
    label: 'ACHR,  Archer Aviation Inc.',
    name: 'Archer Aviation Inc.',
    symbol: 'ACHR',
  },
  {
    value: '000020.KS',
    label: '000020.KS,  Dongwha Pharm.Co.,Ltd',
    name: 'Dongwha Pharm.Co.,Ltd',
    symbol: '000020.KS',
  },
  {
    value: '000028.SZ',
    label: '000028.SZ,  China National Accord Medicines Corporation Ltd.',
    name: 'China National Accord Medicines Corporation Ltd.',
    symbol: '000028.SZ',
  },
  {
    value: '000031.SZ',
    label: '000031.SZ,  Grandjoy Holdings Group Co., Ltd.',
    name: 'Grandjoy Holdings Group Co., Ltd.',
    symbol: '000031.SZ',
  },
  {
    value: '000039.SZ',
    label:
      '000039.SZ,  China International Marine Containers (Group) Co., Ltd.',
    name: 'China International Marine Containers (Group) Co., Ltd.',
    symbol: '000039.SZ',
  },
  {
    value: '000046.SZ',
    label: '000046.SZ,  Oceanwide Holdings Co., Ltd.',
    name: 'Oceanwide Holdings Co., Ltd.',
    symbol: '000046.SZ',
  },
  {
    value: '000060.SZ',
    label: '000060.SZ,  Shenzhen Zhongjin Lingnan Nonfemet Co. Ltd.',
    name: 'Shenzhen Zhongjin Lingnan Nonfemet Co. Ltd.',
    symbol: '000060.SZ',
  },
  {
    value: '000400.KS',
    label: '000400.KS,  Lotte Non - Life Insurance Co., Ltd.',
    name: 'Lotte Non - Life Insurance Co., Ltd.',
    symbol: '000400.KS',
  },
  {
    value: '000400.SZ',
    label: '000400.SZ,  XJ Electric Co., Ltd.',
    name: 'XJ Electric Co., Ltd.',
    symbol: '000400.SZ',
  },
  {
    value: '000415.SZ',
    label: '000415.SZ,  Bohai Leasing Co., Ltd.',
    name: 'Bohai Leasing Co., Ltd.',
    symbol: '000415.SZ',
  },
  {
    value: '000519.SZ',
    label: '000519.SZ,  North Industries Group Red Arrow Co., Ltd',
    name: 'North Industries Group Red Arrow Co., Ltd',
    symbol: '000519.SZ',
  },
  {
    value: '000528.SZ',
    label: '000528.SZ,  Guangxi Liugong Machinery Co., Ltd.',
    name: 'Guangxi Liugong Machinery Co., Ltd.',
    symbol: '000528.SZ',
  },
  {
    value: '000559.SZ',
    label: '000559.SZ,  Wanxiang Qianchao Co.,Ltd.',
    name: 'Wanxiang Qianchao Co.,Ltd.',
    symbol: '000559.SZ',
  },
  {
    value: '000563.SZ',
    label: '000563.SZ,  Shaanxi International Trust Co.,Ltd.',
    name: 'Shaanxi International Trust Co.,Ltd.',
    symbol: '000563.SZ',
  },
  {
    value: '000636.SZ',
    label:
      '000636.SZ,  Guangdong Fenghua Advanced Technology (Holding) Co., Ltd.',
    name: 'Guangdong Fenghua Advanced Technology (Holding) Co., Ltd.',
    symbol: '000636.SZ',
  },
  {
    value: '000681.SZ',
    label: '000681.SZ,  Visual China Group Co.,Ltd.',
    name: 'Visual China Group Co.,Ltd.',
    symbol: '000681.SZ',
  },
  {
    value: '000718.SZ',
    label: '000718.SZ,  Suning Universal Co.,Ltd',
    name: 'Suning Universal Co.,Ltd',
    symbol: '000718.SZ',
  },
  {
    value: '000732.SZ',
    label: '000732.SZ,  Tahoe Group Co., Ltd.',
    name: 'Tahoe Group Co., Ltd.',
    symbol: '000732.SZ',
  },
  {
    value: '000758.SZ',
    label:
      "000758.SZ,  China Nonferrous Metal Industry's Foreign Engineering and Construction Co.,Ltd.",
    name: "China Nonferrous Metal Industry's Foreign Engineering and Construction Co.,Ltd.",
    symbol: '000758.SZ',
  },
  {
    value: '000826.SZ',
    label:
      '000826.SZ,  TUS ENVIRONMENTAL SCIENCE AND TECHNOLOGY DEVELOPMENT Co., LTD.',
    name: 'TUS ENVIRONMENTAL SCIENCE AND TECHNOLOGY DEVELOPMENT Co., LTD.',
    symbol: '000826.SZ',
  },
  {
    value: '000877.SZ',
    label: '000877.SZ,  Xinjiangtianshan Cement Co.,Ltd',
    name: 'Xinjiangtianshan Cement Co.,Ltd',
    symbol: '000877.SZ',
  },
  {
    value: '000898.SZ',
    label: '000898.SZ,  Angang Steel Company Limited',
    name: 'Angang Steel Company Limited',
    symbol: '000898.SZ',
  },
  {
    value: '000930.SZ',
    label: '000930.SZ,  COFCO Biotechnology Co., Ltd.',
    name: 'COFCO Biotechnology Co., Ltd.',
    symbol: '000930.SZ',
  },
  {
    value: '000960.SZ',
    label: '000960.SZ,  Yunnan Tin Company Limited',
    name: 'Yunnan Tin Company Limited',
    symbol: '000960.SZ',
  },
  {
    value: '000970.SZ',
    label: '000970.SZ,  Beijing Zhong Ke San Huan High-Tech Co., Ltd.',
    name: 'Beijing Zhong Ke San Huan High-Tech Co., Ltd.',
    symbol: '000970.SZ',
  },
  {
    value: '001130.KS',
    label: '001130.KS,  Daehan Flour Mills Co.,Ltd',
    name: 'Daehan Flour Mills Co.,Ltd',
    symbol: '001130.KS',
  },
  {
    value: '001470.KS',
    label: '001470.KS,  Sambu Engineering & Construction Co., Ltd',
    name: 'Sambu Engineering & Construction Co., Ltd',
    symbol: '001470.KS',
  },
  {
    value: '001530.KS',
    label: '001530.KS,  Di Dong Il Corporation',
    name: 'Di Dong Il Corporation',
    symbol: '001530.KS',
  },
  {
    value: '002004.SZ',
    label: '002004.SZ,  Huapont Life Sciences Co., Ltd.',
    name: 'Huapont Life Sciences Co., Ltd.',
    symbol: '002004.SZ',
  },
  {
    value: '002048.SZ',
    label: '002048.SZ,  Ningbo Huaxiang Electronic Co., Ltd.',
    name: 'Ningbo Huaxiang Electronic Co., Ltd.',
    symbol: '002048.SZ',
  },
  {
    value: '002145.SZ',
    label: '002145.SZ,  CNNC Hua Yuan Titanium Dioxide Co., Ltd',
    name: 'CNNC Hua Yuan Titanium Dioxide Co., Ltd',
    symbol: '002145.SZ',
  },
  {
    value: '002155.SZ',
    label: '002155.SZ,  Hunan Gold Corporation Limited',
    name: 'Hunan Gold Corporation Limited',
    symbol: '002155.SZ',
  },
  {
    value: '002203.SZ',
    label: '002203.SZ,  Zhe Jiang Hai Liang Co., Ltd',
    name: 'Zhe Jiang Hai Liang Co., Ltd',
    symbol: '002203.SZ',
  },
  {
    value: '002221.SZ',
    label: '002221.SZ,  Oriental Energy Co., Ltd.',
    name: 'Oriental Energy Co., Ltd.',
    symbol: '002221.SZ',
  },
  {
    value: '002244.SZ',
    label: '002244.SZ,  Hangzhou Binjiang Real Estate Group Co.,Ltd',
    name: 'Hangzhou Binjiang Real Estate Group Co.,Ltd',
    symbol: '002244.SZ',
  },
  {
    value: '002249.SZ',
    label: '002249.SZ,  Zhongshan Broad-Ocean Motor Co., Ltd.',
    name: 'Zhongshan Broad-Ocean Motor Co., Ltd.',
    symbol: '002249.SZ',
  },
  {
    value: '002250.SZ',
    label: '002250.SZ,  Lianhe Chemical Technology Co., Ltd.',
    name: 'Lianhe Chemical Technology Co., Ltd.',
    symbol: '002250.SZ',
  },
  {
    value: '002281.SZ',
    label: '002281.SZ,  Accelink Technologies Co,Ltd.',
    name: 'Accelink Technologies Co,Ltd.',
    symbol: '002281.SZ',
  },
  {
    value: '002595.SZ',
    label:
      '002595.SZ,  Himile Mechanical Science and Technology (Shandong) Co., Ltd',
    name: 'Himile Mechanical Science and Technology (Shandong) Co., Ltd',
    symbol: '002595.SZ',
  },
  {
    value: '002630.KS',
    label: '002630.KS,  ORIENT BIO Inc.',
    name: 'ORIENT BIO Inc.',
    symbol: '002630.KS',
  },
  {
    value: '002653.SZ',
    label: '002653.SZ,  Haisco Pharmaceutical Group Co., Ltd.',
    name: 'Haisco Pharmaceutical Group Co., Ltd.',
    symbol: '002653.SZ',
  },
  {
    value: '002810.KS',
    label: '002810.KS,  Samyung Trading Co., Ltd.',
    name: 'Samyung Trading Co., Ltd.',
    symbol: '002810.KS',
  },
  {
    value: '002967.SZ',
    label: '002967.SZ,  Guang Zhou GRG Metrology & Test Co., Ltd.',
    name: 'Guang Zhou GRG Metrology & Test Co., Ltd.',
    symbol: '002967.SZ',
  },
  {
    value: '004150.KS',
    label: '004150.KS,  Hansol Holdings Co., Ltd.',
    name: 'Hansol Holdings Co., Ltd.',
    symbol: '004150.KS',
  },
  {
    value: '004380.KS',
    label: '004380.KS,  Samick THK Co., Ltd.',
    name: 'Samick THK Co., Ltd.',
    symbol: '004380.KS',
  },
  {
    value: '004830.KS',
    label: '004830.KS,  Duksung Co., Ltd.',
    name: 'Duksung Co., Ltd.',
    symbol: '004830.KS',
  },
  {
    value: '004980.KS',
    label: '004980.KS,  Sungshin Cement Co., Ltd',
    name: 'Sungshin Cement Co., Ltd',
    symbol: '004980.KS',
  },
  {
    value: '005420.KS',
    label: '005420.KS,  Cosmo Chemical Co., Ltd.',
    name: 'Cosmo Chemical Co., Ltd.',
    symbol: '005420.KS',
  },
  {
    value: '007160.KS',
    label: '007160.KS,  Sajo Industries Company Limited',
    name: 'Sajo Industries Company Limited',
    symbol: '007160.KS',
  },
  {
    value: '007690.KS',
    label: '007690.KS,  Kukdo Chemical Co., Ltd.',
    name: 'Kukdo Chemical Co., Ltd.',
    symbol: '007690.KS',
  },
  {
    value: '008700.KS',
    label: '008700.KS,  Anam Electronics Co.,Ltd.',
    name: 'Anam Electronics Co.,Ltd.',
    symbol: '008700.KS',
  },
  {
    value: '008970.KS',
    label: '008970.KS,  Dong Yang Steel Pipe Co., Ltd.',
    name: 'Dong Yang Steel Pipe Co., Ltd.',
    symbol: '008970.KS',
  },
  {
    value: '010600.KS',
    label: '010600.KS,  Wellbiotec Co., Ltd.',
    name: 'Wellbiotec Co., Ltd.',
    symbol: '010600.KS',
  },
  {
    value: '012170.KS',
    label: '012170.KS,  Ascendio Co., Ltd.',
    name: 'Ascendio Co., Ltd.',
    symbol: '012170.KS',
  },
  {
    value: '016710.KS',
    label: '016710.KS,  Daesung Holdings Co., Ltd.',
    name: 'Daesung Holdings Co., Ltd.',
    symbol: '016710.KS',
  },
  {
    value: '078130.KQ',
    label: '078130.KQ,  Kuk-Il Paper Mfg Co.,Ltd',
    name: 'Kuk-Il Paper Mfg Co.,Ltd',
    symbol: '078130.KQ',
  },
  {
    value: '078520.KS',
    label: '078520.KS,  Able C&C Co., Ltd.',
    name: 'Able C&C Co., Ltd.',
    symbol: '078520.KS',
  },
  {
    value: '1734.TW',
    label: '1734.TW,  Sinphar Pharmaceutical Co.,Ltd.',
    name: 'Sinphar Pharmaceutical Co.,Ltd.',
    symbol: '1734.TW',
  },
  {
    value: '1736.TW',
    label: '1736.TW,  Johnson Health Tech .Co., Ltd.',
    name: 'Johnson Health Tech .Co., Ltd.',
    symbol: '1736.TW',
  },
  {
    value: '1737.TW',
    label: '1737.TW,  Taiyen Biotech Co., Ltd.',
    name: 'Taiyen Biotech Co., Ltd.',
    symbol: '1737.TW',
  },
  {
    value: '175330.KS',
    label: '175330.KS,  JB Financial Group Co., Ltd.',
    name: 'JB Financial Group Co., Ltd.',
    symbol: '175330.KS',
  },
  {
    value: '1757.T',
    label: '1757.T,  Small and medium sized Enterprises Holdings,Inc.',
    name: 'Small and medium sized Enterprises Holdings,Inc.',
    symbol: '1757.T',
  },
  {
    value: '1760.TW',
    label: '1760.TW,  Panion & Bf Biotech Inc.',
    name: 'Panion & Bf Biotech Inc.',
    symbol: '1760.TW',
  },
  {
    value: '1762.T',
    label: '1762.T,  Takamatsu Construction Group Co., Ltd.',
    name: 'Takamatsu Construction Group Co., Ltd.',
    symbol: '1762.T',
  },
  {
    value: '1762.TW',
    label: '1762.TW,  Chunghwa Chemical Synthesis & Biotech Co., Ltd.',
    name: 'Chunghwa Chemical Synthesis & Biotech Co., Ltd.',
    symbol: '1762.TW',
  },
  {
    value: 'CTRA',
    label: 'CTRA,  Coterra Energy Inc.',
    name: 'Coterra Energy Inc.',
    symbol: 'CTRA',
  },
  {
    value: '1766.T',
    label: '1766.T,  Token Corporation',
    name: 'Token Corporation',
    symbol: '1766.T',
  },
  {
    value: '1773.TW',
    label: '1773.TW,  Shiny Chemical Industrial Co., Ltd.',
    name: 'Shiny Chemical Industrial Co., Ltd.',
    symbol: '1773.TW',
  },
  {
    value: '178320.KQ',
    label: '178320.KQ,  Seojin System Co.,Ltd',
    name: 'Seojin System Co.,Ltd',
    symbol: '178320.KQ',
  },
  {
    value: '1785.TWO',
    label: '1785.TWO,  Solar Applied Materials Technology Corporation',
    name: 'Solar Applied Materials Technology Corporation',
    symbol: '1785.TWO',
  },
  {
    value: '1786.T',
    label: '1786.T,  Oriental Shiraishi Corporation',
    name: 'Oriental Shiraishi Corporation',
    symbol: '1786.T',
  },
  {
    value: '178920.KS',
    label: '178920.KS,  PI Advanced Materials Co., Ltd.',
    name: 'PI Advanced Materials Co., Ltd.',
    symbol: '178920.KS',
  },
  {
    value: '1789.TW',
    label: '1789.TW,  ScinoPharm Taiwan, Ltd.',
    name: 'ScinoPharm Taiwan, Ltd.',
    symbol: '1789.TW',
  },
  {
    value: '1795.TW',
    label: '1795.TW,  Lotus Pharmaceutical Co., Ltd.',
    name: 'Lotus Pharmaceutical Co., Ltd.',
    symbol: '1795.TW',
  },
  {
    value: '1801.T',
    label: '1801.T,  Taisei Corporation',
    name: 'Taisei Corporation',
    symbol: '1801.T',
  },
  {
    value: '1802.T',
    label: '1802.T,  Obayashi Corporation',
    name: 'Obayashi Corporation',
    symbol: '1802.T',
  },
  {
    value: '1802.TW',
    label: '1802.TW,  Taiwan Glass Ind. Corp.',
    name: 'Taiwan Glass Ind. Corp.',
    symbol: '1802.TW',
  },
  {
    value: '1803.T',
    label: '1803.T,  Shimizu Corporation',
    name: 'Shimizu Corporation',
    symbol: '1803.T',
  },
  {
    value: '1805.T',
    label: '1805.T,  Tobishima Corporation',
    name: 'Tobishima Corporation',
    symbol: '1805.T',
  },
  {
    value: '180640.KS',
    label: '180640.KS,  Hanjin Kal',
    name: 'Hanjin Kal',
    symbol: '180640.KS',
  },
  {
    value: '1806.TW',
    label: '1806.TW,  Champion Building Materials Co., Ltd.',
    name: 'Champion Building Materials Co., Ltd.',
    symbol: '1806.TW',
  },
  {
    value: '1808.T',
    label: '1808.T,  HASEKO Corporation',
    name: 'HASEKO Corporation',
    symbol: '1808.T',
  },
  {
    value: '1810.T',
    label: '1810.T,  Matsui Construction Co., Ltd.',
    name: 'Matsui Construction Co., Ltd.',
    symbol: '1810.T',
  },
  {
    value: '1810.TW',
    label: '1810.TW,  Hocheng Corporation',
    name: 'Hocheng Corporation',
    symbol: '1810.TW',
  },
  {
    value: '1812.T',
    label: '1812.T,  Kajima Corporation',
    name: 'Kajima Corporation',
    symbol: '1812.T',
  },
  {
    value: '1813.T',
    label: '1813.T,  Fudo Tetra Corporation',
    name: 'Fudo Tetra Corporation',
    symbol: '1813.T',
  },
  {
    value: '1815.T',
    label: '1815.T,  Tekken Corporation',
    name: 'Tekken Corporation',
    symbol: '1815.T',
  },
  {
    value: '1815.TWO',
    label: '1815.TWO,  Fulltech Fiber Glass Corp.',
    name: 'Fulltech Fiber Glass Corp.',
    symbol: '1815.TWO',
  },
  {
    value: '181710.KS',
    label: '181710.KS,  NHN Corporation',
    name: 'NHN Corporation',
    symbol: '181710.KS',
  },
  {
    value: '1820.T',
    label: '1820.T,  Nishimatsu Construction Co., Ltd.',
    name: 'Nishimatsu Construction Co., Ltd.',
    symbol: '1820.T',
  },
  {
    value: '1822.T',
    label: '1822.T,  Daiho Corporation',
    name: 'Daiho Corporation',
    symbol: '1822.T',
  },
  {
    value: '183190.KS',
    label: '183190.KS,  Asia Cement Co.,Ltd.',
    name: 'Asia Cement Co.,Ltd.',
    symbol: '183190.KS',
  },
  {
    value: '1833.T',
    label: '1833.T,  Okumura Corporation',
    name: 'Okumura Corporation',
    symbol: '1833.T',
  },
  {
    value: '183490.KQ',
    label: '183490.KQ,  Enzychem Lifesciences Corporation',
    name: 'Enzychem Lifesciences Corporation',
    symbol: '183490.KQ',
  },
  {
    value: '1835.T',
    label: '1835.T,  Totetsu Kogyo Co., Ltd.',
    name: 'Totetsu Kogyo Co., Ltd.',
    symbol: '1835.T',
  },
  {
    value: '1852.T',
    label: '1852.T,  Asanuma Corporation',
    name: 'Asanuma Corporation',
    symbol: '1852.T',
  },
  {
    value: '185750.KS',
    label: '185750.KS,  Chong Kun Dang Pharmaceutical Corp.',
    name: 'Chong Kun Dang Pharmaceutical Corp.',
    symbol: '185750.KS',
  },
  {
    value: '1860.T',
    label: '1860.T,  Toda Corporation',
    name: 'Toda Corporation',
    symbol: '1860.T',
  },
  {
    value: '1861.T',
    label: '1861.T,  Kumagai Gumi Co.,Ltd.',
    name: 'Kumagai Gumi Co.,Ltd.',
    symbol: '1861.T',
  },
  {
    value: '1870.T',
    label: '1870.T,  Yahagi Construction Co.,Ltd.',
    name: 'Yahagi Construction Co.,Ltd.',
    symbol: '1870.T',
  },
  {
    value: '1871.T',
    label: '1871.T,  P.S. Mitsubishi Construction Co., Ltd.',
    name: 'P.S. Mitsubishi Construction Co., Ltd.',
    symbol: '1871.T',
  },
  {
    value: '1873.T',
    label: '1873.T,  Nihon House Holdings Co., Ltd.',
    name: 'Nihon House Holdings Co., Ltd.',
    symbol: '1873.T',
  },
  {
    value: '1878.T',
    label: '1878.T,  Daito Trust Construction Co.,Ltd.',
    name: 'Daito Trust Construction Co.,Ltd.',
    symbol: '1878.T',
  },
  {
    value: '1879.T',
    label: '1879.T,  Shinnihon Corporation',
    name: 'Shinnihon Corporation',
    symbol: '1879.T',
  },
  {
    value: '1882.T',
    label: '1882.T,  Toa Road Corporation',
    name: 'Toa Road Corporation',
    symbol: '1882.T',
  },
  {
    value: '1884.T',
    label: '1884.T,  THE NIPPON ROAD Co., Ltd.',
    name: 'THE NIPPON ROAD Co., Ltd.',
    symbol: '1884.T',
  },
  {
    value: '1885.T',
    label: '1885.T,  TOA Corporation',
    name: 'TOA Corporation',
    symbol: '1885.T',
  },
  {
    value: '1887.T',
    label: '1887.T,  JDC Corporation',
    name: 'JDC Corporation',
    symbol: '1887.T',
  },
  {
    value: '1888.T',
    label: '1888.T,  Wakachiku Construction Co., Ltd.',
    name: 'Wakachiku Construction Co., Ltd.',
    symbol: '1888.T',
  },
  {
    value: '1890.T',
    label: '1890.T,  Toyo Construction Co., Ltd.',
    name: 'Toyo Construction Co., Ltd.',
    symbol: '1890.T',
  },
  {
    value: '1893.T',
    label: '1893.T,  Penta-Ocean Construction Co., Ltd.',
    name: 'Penta-Ocean Construction Co., Ltd.',
    symbol: '1893.T',
  },
  {
    value: '1898.T',
    label: '1898.T,  Seikitokyu Kogyo Co., Ltd.',
    name: 'Seikitokyu Kogyo Co., Ltd.',
    symbol: '1898.T',
  },
  {
    value: '1899.T',
    label: '1899.T,  Fukuda Corporation',
    name: 'Fukuda Corporation',
    symbol: '1899.T',
  },
  {
    value: '1903.TW',
    label: '1903.TW,  Shihlin Paper Corporation',
    name: 'Shihlin Paper Corporation',
    symbol: '1903.TW',
  },
  {
    value: '1904.TW',
    label: '1904.TW,  Cheng Loong Corporation',
    name: 'Cheng Loong Corporation',
    symbol: '1904.TW',
  },
  {
    value: '1905.TW',
    label: '1905.TW,  Chung Hwa Pulp Corporation',
    name: 'Chung Hwa Pulp Corporation',
    symbol: '1905.TW',
  },
  {
    value: '1906.TW',
    label: '1906.TW,  Baolong International Co., Ltd.',
    name: 'Baolong International Co., Ltd.',
    symbol: '1906.TW',
  },
  {
    value: '1907.TW',
    label: '1907.TW,  YFY Inc.',
    name: 'YFY Inc.',
    symbol: '1907.TW',
  },
  {
    value: '1909.TW',
    label: '1909.TW,  Longchen Paper & Packaging Co., Ltd.',
    name: 'Longchen Paper & Packaging Co., Ltd.',
    symbol: '1909.TW',
  },
  {
    value: '1911.T',
    label: '1911.T,  Sumitomo Forestry Co., Ltd.',
    name: 'Sumitomo Forestry Co., Ltd.',
    symbol: '1911.T',
  },
  {
    value: '192080.KS',
    label: '192080.KS,  DoubleUGames Co., Ltd.',
    name: 'DoubleUGames Co., Ltd.',
    symbol: '192080.KS',
  },
  {
    value: '192400.KS',
    label: '192400.KS,  Cuckoo Holdings Co., Ltd.',
    name: 'Cuckoo Holdings Co., Ltd.',
    symbol: '192400.KS',
  },
  {
    value: '1925.T',
    label: '1925.T,  Daiwa House Industry Co., Ltd.',
    name: 'Daiwa House Industry Co., Ltd.',
    symbol: '1925.T',
  },
  {
    value: '192650.KS',
    label: '192650.KS,  DREAMTECH Co., Ltd.',
    name: 'DREAMTECH Co., Ltd.',
    symbol: '192650.KS',
  },
  {
    value: '1926.T',
    label: '1926.T,  Raito Kogyo Co., Ltd.',
    name: 'Raito Kogyo Co., Ltd.',
    symbol: '1926.T',
  },
  {
    value: '192820.KS',
    label: '192820.KS,  Cosmax, Inc.',
    name: 'Cosmax, Inc.',
    symbol: '192820.KS',
  },
  {
    value: '1928.T',
    label: '1928.T,  Sekisui House, Ltd.',
    name: 'Sekisui House, Ltd.',
    symbol: '1928.T',
  },
  {
    value: '1929.T',
    label: '1929.T,  Nittoc Construction Co., Ltd.',
    name: 'Nittoc Construction Co., Ltd.',
    symbol: '1929.T',
  },
  {
    value: '1934.T',
    label: '1934.T,  Yurtec Corporation',
    name: 'Yurtec Corporation',
    symbol: '1934.T',
  },
  {
    value: '1941.T',
    label: '1941.T,  Chudenko Corporation',
    name: 'Chudenko Corporation',
    symbol: '1941.T',
  },
  {
    value: '1942.T',
    label: '1942.T,  Kandenko Co.,Ltd.',
    name: 'Kandenko Co.,Ltd.',
    symbol: '1942.T',
  },
  {
    value: '1944.T',
    label: '1944.T,  Kinden Corporation',
    name: 'Kinden Corporation',
    symbol: '1944.T',
  },
  {
    value: '1945.T',
    label: '1945.T,  Tokyo Energy & Systems Inc.',
    name: 'Tokyo Energy & Systems Inc.',
    symbol: '1945.T',
  },
  {
    value: '1946.T',
    label: '1946.T,  Toenec Corporation',
    name: 'Toenec Corporation',
    symbol: '1946.T',
  },
  {
    value: '1949.T',
    label: '1949.T,  Sumitomo Densetsu Co.,Ltd.',
    name: 'Sumitomo Densetsu Co.,Ltd.',
    symbol: '1949.T',
  },
  {
    value: '1950.T',
    label: '1950.T,  Nippon Densetsu Kogyo Co., Ltd.',
    name: 'Nippon Densetsu Kogyo Co., Ltd.',
    symbol: '1950.T',
  },
  {
    value: '1951.T',
    label: '1951.T,  EXEO Group, Inc.',
    name: 'EXEO Group, Inc.',
    symbol: '1951.T',
  },
  {
    value: '1952.T',
    label: '1952.T,  Shin Nippon Air Technologies Co., Ltd.',
    name: 'Shin Nippon Air Technologies Co., Ltd.',
    symbol: '1952.T',
  },
  {
    value: '1954.T',
    label: '1954.T,  Nippon Koei Co., Ltd.',
    name: 'Nippon Koei Co., Ltd.',
    symbol: '1954.T',
  },
  {
    value: '195870.KS',
    label: '195870.KS,  HAESUNG DS Co., Ltd.',
    name: 'HAESUNG DS Co., Ltd.',
    symbol: '195870.KS',
  },
  {
    value: '1959.T',
    label: '1959.T,  Kyudenko Corporation',
    name: 'Kyudenko Corporation',
    symbol: '1959.T',
  },
  {
    value: '196170.KQ',
    label: '196170.KQ,  ALTEOGEN Inc.',
    name: 'ALTEOGEN Inc.',
    symbol: '196170.KQ',
  },
  {
    value: '1961.T',
    label: '1961.T,  Sanki Engineering Co., Ltd.',
    name: 'Sanki Engineering Co., Ltd.',
    symbol: '1961.T',
  },
  {
    value: '1963.T',
    label: '1963.T,  JGC Holdings Corporation',
    name: 'JGC Holdings Corporation',
    symbol: '1963.T',
  },
  {
    value: '1967.T',
    label: '1967.T,  Yamato Corporation',
    name: 'Yamato Corporation',
    symbol: '1967.T',
  },
  {
    value: '1968.T',
    label: '1968.T,  Taihei Dengyo Kaisha, Ltd.',
    name: 'Taihei Dengyo Kaisha, Ltd.',
    symbol: '1968.T',
  },
  {
    value: '1969.T',
    label: '1969.T,  Takasago Thermal Engineering Co., Ltd.',
    name: 'Takasago Thermal Engineering Co., Ltd.',
    symbol: '1969.T',
  },
  {
    value: '1973.T',
    label: '1973.T,  NEC Networks & System Integration Corporation',
    name: 'NEC Networks & System Integration Corporation',
    symbol: '1973.T',
  },
  {
    value: '1976.T',
    label: '1976.T,  MEISEI INDUSTRIAL Co.,Ltd.',
    name: 'MEISEI INDUSTRIAL Co.,Ltd.',
    symbol: '1976.T',
  },
  {
    value: '1979.T',
    label: '1979.T,  Taikisha Ltd.',
    name: 'Taikisha Ltd.',
    symbol: '1979.T',
  },
  {
    value: '1980.T',
    label: '1980.T,  Dai-Dan Co., Ltd.',
    name: 'Dai-Dan Co., Ltd.',
    symbol: '1980.T',
  },
  {
    value: '1982.T',
    label: '1982.T,  Hibiya Engineering, Ltd.',
    name: 'Hibiya Engineering, Ltd.',
    symbol: '1982.T',
  },
  {
    value: '1AT.WA',
    label: '1AT.WA,  Atal S.A.',
    name: 'Atal S.A.',
    symbol: '1AT.WA',
  },
  {
    value: '1U1.DE',
    label: '1U1.DE,  1&1 AG',
    name: '1&1 AG',
    symbol: '1U1.DE',
  },
  {
    value: '200012.SZ',
    label: '200012.SZ,  CSG Holding Co., Ltd.',
    name: 'CSG Holding Co., Ltd.',
    symbol: '200012.SZ',
  },
  {
    value: '200016.SZ',
    label: '200016.SZ,  Konka Group Co., Ltd.',
    name: 'Konka Group Co., Ltd.',
    symbol: '200016.SZ',
  },
  {
    value: '200028.SZ',
    label: '200028.SZ,  China National Accord Medicines Corporation Ltd.',
    name: 'China National Accord Medicines Corporation Ltd.',
    symbol: '200028.SZ',
  },
  {
    value: '200055.SZ',
    label: '200055.SZ,  China Fangda Group Co., Ltd.',
    name: 'China Fangda Group Co., Ltd.',
    symbol: '200055.SZ',
  },
  {
    value: '200058.SZ',
    label: '200058.SZ,  Shenzhen SEG Co.,Ltd',
    name: 'Shenzhen SEG Co.,Ltd',
    symbol: '200058.SZ',
  },
  {
    value: '200130.KQ',
    label: '200130.KQ,  Kolmar BNH Co., Ltd.',
    name: 'Kolmar BNH Co., Ltd.',
    symbol: '200130.KQ',
  },
  {
    value: '200152.SZ',
    label: '200152.SZ,  Shandong Airlines Co., Ltd.',
    name: 'Shandong Airlines Co., Ltd.',
    symbol: '200152.SZ',
  },
  {
    value: '2001.T',
    label: '2001.T,  Nippn Corporation',
    name: 'Nippn Corporation',
    symbol: '2001.T',
  },
  {
    value: '200230.KQ',
    label: '200230.KQ,  TELCON RF PHARMACEUTICAL. Inc.',
    name: 'TELCON RF PHARMACEUTICAL. Inc.',
    symbol: '200230.KQ',
  },
  {
    value: '2002.T',
    label: '2002.T,  Nisshin Seifun Group Inc.',
    name: 'Nisshin Seifun Group Inc.',
    symbol: '2002.T',
  },
  {
    value: '2002.TW',
    label: '2002.TW,  China Steel Corporation',
    name: 'China Steel Corporation',
    symbol: '2002.TW',
  },
  {
    value: '200429.SZ',
    label: '200429.SZ,  Guangdong Provincial Expressway Development Co., Ltd.',
    name: 'Guangdong Provincial Expressway Development Co., Ltd.',
    symbol: '200429.SZ',
  },
  {
    value: '200488.SZ',
    label: '200488.SZ,  Shandong Chenming Paper Holdings Limited',
    name: 'Shandong Chenming Paper Holdings Limited',
    symbol: '200488.SZ',
  },
  {
    value: '2004.T',
    label: '2004.T,  Showa Sangyo Co., Ltd.',
    name: 'Showa Sangyo Co., Ltd.',
    symbol: '2004.T',
  },
  {
    value: '200539.SZ',
    label: '200539.SZ,  Guangdong Electric Power Development Co., Ltd.',
    name: 'Guangdong Electric Power Development Co., Ltd.',
    symbol: '200539.SZ',
  },
  {
    value: '200596.SZ',
    label: '200596.SZ,  Anhui Gujing Distillery Co., Ltd.',
    name: 'Anhui Gujing Distillery Co., Ltd.',
    symbol: '200596.SZ',
  },
  {
    value: '200625.SZ',
    label: '200625.SZ,  Chongqing Changan Automobile Company Limited',
    name: 'Chongqing Changan Automobile Company Limited',
    symbol: '200625.SZ',
  },
  {
    value: '200670.KQ',
    label: '200670.KQ,  Humedix Co., Ltd.',
    name: 'Humedix Co., Ltd.',
    symbol: '200670.KQ',
  },
  {
    value: '2006.TW',
    label: '2006.TW,  Tung Ho Steel Enterprise Corporation',
    name: 'Tung Ho Steel Enterprise Corporation',
    symbol: '2006.TW',
  },
  {
    value: '200725.SZ',
    label: '200725.SZ,  BOE Technology Group Company Limited',
    name: 'BOE Technology Group Company Limited',
    symbol: '200725.SZ',
  },
  {
    value: '200726.SZ',
    label: '200726.SZ,  Lu Thai Textile Co., Ltd.',
    name: 'Lu Thai Textile Co., Ltd.',
    symbol: '200726.SZ',
  },
  {
    value: '200771.SZ',
    label: '200771.SZ,  Hangzhou Steam Turbine Power Group Co., Ltd.',
    name: 'Hangzhou Steam Turbine Power Group Co., Ltd.',
    symbol: '200771.SZ',
  },
  {
    value: '2007.TW',
    label: '2007.TW,  Yieh Hsing Enterprise Co., Ltd.',
    name: 'Yieh Hsing Enterprise Co., Ltd.',
    symbol: '2007.TW',
  },
  {
    value: '200869.SZ',
    label: '200869.SZ,  Yantai Changyu Pioneer Wine Company Limited',
    name: 'Yantai Changyu Pioneer Wine Company Limited',
    symbol: '200869.SZ',
  },
  {
    value: '200880.KS',
    label: '200880.KS,  Seoyon E-Hwa Co., Ltd.',
    name: 'Seoyon E-Hwa Co., Ltd.',
    symbol: '200880.KS',
  },
  {
    value: '2009.TW',
    label: '2009.TW,  First Copper Technology Co., Ltd.',
    name: 'First Copper Technology Co., Ltd.',
    symbol: '2009.TW',
  },
  {
    value: '2010.TW',
    label: '2010.TW,  Chun Yuan Steel Industry Co., Ltd.',
    name: 'Chun Yuan Steel Industry Co., Ltd.',
    symbol: '2010.TW',
  },
  {
    value: '2012.TW',
    label: '2012.TW,  Chun Yu Works & Co., Ltd.',
    name: 'Chun Yu Works & Co., Ltd.',
    symbol: '2012.TW',
  },
  {
    value: '2013.TW',
    label: '2013.TW,  China Steel Structure Co., Ltd.',
    name: 'China Steel Structure Co., Ltd.',
    symbol: '2013.TW',
  },
  {
    value: 'WOLF',
    label: 'WOLF,  Wolfspeed, Inc.',
    name: 'Wolfspeed, Inc.',
    symbol: 'WOLF',
  },
  {
    value: 'XPRO',
    label: 'XPRO,  Expro Group Holdings N.V.',
    name: 'Expro Group Holdings N.V.',
    symbol: 'XPRO',
  },
  {
    value: 'RRX',
    label: 'RRX,  Regal Rexnord Corporation',
    name: 'Regal Rexnord Corporation',
    symbol: 'RRX',
  },
  {
    value: '2014.TW',
    label: '2014.TW,  Chung Hung Steel Corporation',
    name: 'Chung Hung Steel Corporation',
    symbol: '2014.TW',
  },
  {
    value: '2015.TW',
    label: '2015.TW,  Feng Hsin Steel Co., Ltd.',
    name: 'Feng Hsin Steel Co., Ltd.',
    symbol: '2015.TW',
  },
  {
    value: '2017.TW',
    label: '2017.TW,  Quintain Steel Co., LTD.',
    name: 'Quintain Steel Co., LTD.',
    symbol: '2017.TW',
  },
  {
    value: '2020.OL',
    label: '2020.OL,  2020 Bulkers Ltd.',
    name: '2020 Bulkers Ltd.',
    symbol: '2020.OL',
  },
  {
    value: '2020.TW',
    label: '2020.TW,  Mayer Steel Pipe Corporation',
    name: 'Mayer Steel Pipe Corporation',
    symbol: '2020.TW',
  },
  {
    value: '2022.TW',
    label: '2022.TW,  Tycoons Group Enterprise Co.,Ltd.',
    name: 'Tycoons Group Enterprise Co.,Ltd.',
    symbol: '2022.TW',
  },
  {
    value: '2023.TW',
    label: '2023.TW,  Yieh Phui Enterprise Co., Ltd.',
    name: 'Yieh Phui Enterprise Co., Ltd.',
    symbol: '2023.TW',
  },
  {
    value: '2027.TW',
    label: '2027.TW,  Ta Chen Stainless Pipe Co., Ltd.',
    name: 'Ta Chen Stainless Pipe Co., Ltd.',
    symbol: '2027.TW',
  },
  {
    value: '2028.TW',
    label: '2028.TW,  Wei Chih Steel Industrial Co., Ltd.',
    name: 'Wei Chih Steel Industrial Co., Ltd.',
    symbol: '2028.TW',
  },
  {
    value: '2029.TW',
    label: '2029.TW,  Sheng Yu Steel Co., Ltd.',
    name: 'Sheng Yu Steel Co., Ltd.',
    symbol: '2029.TW',
  },
  {
    value: '2030.TW',
    label: '2030.TW,  Froch Enterprise Co., Ltd.',
    name: 'Froch Enterprise Co., Ltd.',
    symbol: '2030.TW',
  },
  {
    value: '2031.TW',
    label: '2031.TW,  Hsin Kuang Steel Company Limited',
    name: 'Hsin Kuang Steel Company Limited',
    symbol: '2031.TW',
  },
  {
    value: '2034.TW',
    label: '2034.TW,  YC Inox Co.,Ltd',
    name: 'YC Inox Co.,Ltd',
    symbol: '2034.TW',
  },
  {
    value: '2038.TW',
    label: '2038.TW,  Hai Kwang Enterprise Corporation',
    name: 'Hai Kwang Enterprise Corporation',
    symbol: '2038.TW',
  },
  {
    value: '204270.KQ',
    label: '204270.KQ,  JNTC Co., Ltd.',
    name: 'JNTC Co., Ltd.',
    symbol: '204270.KQ',
  },
  {
    value: '204320.KS',
    label: '204320.KS,  HL Mando Corporation',
    name: 'HL Mando Corporation',
    symbol: '204320.KS',
  },
  {
    value: '2049.TW',
    label: '2049.TW,  Hiwin Technologies Corp.',
    name: 'Hiwin Technologies Corp.',
    symbol: '2049.TW',
  },
  {
    value: '2053.T',
    label: '2053.T,  Chubu Shiryo Co., Ltd.',
    name: 'Chubu Shiryo Co., Ltd.',
    symbol: '2053.T',
  },
  {
    value: '205470.KQ',
    label: '205470.KQ,  Humasis Co. Ltd.',
    name: 'Humasis Co. Ltd.',
    symbol: '205470.KQ',
  },
  {
    value: '2059.TW',
    label: '2059.TW,  King Slide Works Co., Ltd.',
    name: 'King Slide Works Co., Ltd.',
    symbol: '2059.TW',
  },
  {
    value: '2060.T',
    label: '2060.T,  Feedone Co., Ltd.',
    name: 'Feedone Co., Ltd.',
    symbol: '2060.T',
  },
  {
    value: '2062.TW',
    label: '2062.TW,  Sunspring Metal Corporation',
    name: 'Sunspring Metal Corporation',
    symbol: '2062.TW',
  },
  {
    value: '206640.KQ',
    label: '206640.KQ,  Boditech Med Inc.',
    name: 'Boditech Med Inc.',
    symbol: '206640.KQ',
  },
  {
    value: '206650.KQ',
    label: '206650.KQ,  EuBiologics Co., Ltd.',
    name: 'EuBiologics Co., Ltd.',
    symbol: '206650.KQ',
  },
  {
    value: '207940.KS',
    label: '207940.KS,  Samsung Biologics Co.,Ltd.',
    name: 'Samsung Biologics Co.,Ltd.',
    symbol: '207940.KS',
  },
  {
    value: '2103.TW',
    label: '2103.TW,  TSRC Corporation',
    name: 'TSRC Corporation',
    symbol: '2103.TW',
  },
  {
    value: '2104.TW',
    label: '2104.TW,  International CSRC Investment Holdings Co., Ltd.',
    name: 'International CSRC Investment Holdings Co., Ltd.',
    symbol: '2104.TW',
  },
  {
    value: '2105.TW',
    label: '2105.TW,  Cheng Shin Rubber Ind. Co., Ltd.',
    name: 'Cheng Shin Rubber Ind. Co., Ltd.',
    symbol: '2105.TW',
  },
  {
    value: '2106.TW',
    label: '2106.TW,  Kenda Rubber Industrial Co. Ltd.',
    name: 'Kenda Rubber Industrial Co. Ltd.',
    symbol: '2106.TW',
  },
  {
    value: '2107.TW',
    label: '2107.TW,  Formosan Rubber Group Inc.',
    name: 'Formosan Rubber Group Inc.',
    symbol: '2107.TW',
  },
  {
    value: '2108.TW',
    label: '2108.TW,  Nantex Industry Co., Ltd.',
    name: 'Nantex Industry Co., Ltd.',
    symbol: '2108.TW',
  },
  {
    value: '210980.KS',
    label: '210980.KS,  SK D&D Co. Ltd.',
    name: 'SK D&D Co. Ltd.',
    symbol: '210980.KS',
  },
  {
    value: '2109.T',
    label: '2109.T,  Mitsui DM Sugar Holdings Co.,Ltd.',
    name: 'Mitsui DM Sugar Holdings Co.,Ltd.',
    symbol: '2109.T',
  },
  {
    value: '2114.TW',
    label: '2114.TW,  Hsin Yung Chien Co., Ltd.',
    name: 'Hsin Yung Chien Co., Ltd.',
    symbol: '2114.TW',
  },
  {
    value: '2120.T',
    label: '2120.T,  LIFULL Co.,Ltd.',
    name: 'LIFULL Co.,Ltd.',
    symbol: '2120.T',
  },
  {
    value: '2121.T',
    label: '2121.T,  mixi, Inc.',
    name: 'mixi, Inc.',
    symbol: '2121.T',
  },
  {
    value: '2124.T',
    label: '2124.T,  JAC Recruitment Co., Ltd.',
    name: 'JAC Recruitment Co., Ltd.',
    symbol: '2124.T',
  },
  {
    value: '2127.T',
    label: '2127.T,  Nihon M&A Center Holdings Inc.',
    name: 'Nihon M&A Center Holdings Inc.',
    symbol: '2127.T',
  },
  {
    value: '2130.T',
    label: '2130.T,  Members Co., Ltd.',
    name: 'Members Co., Ltd.',
    symbol: '2130.T',
  },
  {
    value: '213500.KS',
    label: '213500.KS,  Hansol Paper Co., Ltd.',
    name: 'Hansol Paper Co., Ltd.',
    symbol: '213500.KS',
  },
  {
    value: '2138.T',
    label: '2138.T,  CROOZ, Inc.',
    name: 'CROOZ, Inc.',
    symbol: '2138.T',
  },
  {
    value: '214320.KS',
    label: '214320.KS,  Innocean Worldwide Inc.',
    name: 'Innocean Worldwide Inc.',
    symbol: '214320.KS',
  },
  {
    value: '214450.KQ',
    label: '214450.KQ,  PharmaResearch Co., Ltd.',
    name: 'PharmaResearch Co., Ltd.',
    symbol: '214450.KQ',
  },
  {
    value: '2146.T',
    label: '2146.T,  UT Group Co., Ltd.',
    name: 'UT Group Co., Ltd.',
    symbol: '2146.T',
  },
  {
    value: '2148.T',
    label: '2148.T,  ITmedia Inc.',
    name: 'ITmedia Inc.',
    symbol: '2148.T',
  },
  {
    value: '215000.KQ',
    label: '215000.KQ,  GOLFZON Co., Ltd.',
    name: 'GOLFZON Co., Ltd.',
    symbol: '215000.KQ',
  },
  {
    value: '2150.T',
    label: '2150.T,  CareNet, Inc.',
    name: 'CareNet, Inc.',
    symbol: '2150.T',
  },
  {
    value: '215200.KQ',
    label: '215200.KQ,  MegaStudyEdu Co. Ltd',
    name: 'MegaStudyEdu Co. Ltd',
    symbol: '215200.KQ',
  },
  {
    value: '2153.T',
    label: '2153.T,  E J Holdings Inc.',
    name: 'E J Holdings Inc.',
    symbol: '2153.T',
  },
  {
    value: '2154.T',
    label: '2154.T,  BeNext-Yumeshin Group Co.',
    name: 'BeNext-Yumeshin Group Co.',
    symbol: '2154.T',
  },
  {
    value: '215600.KQ',
    label: '215600.KQ,  SillaJen, Inc.',
    name: 'SillaJen, Inc.',
    symbol: '215600.KQ',
  },
  {
    value: '2157.T',
    label: '2157.T,  Koshidaka Holdings Co., Ltd.',
    name: 'Koshidaka Holdings Co., Ltd.',
    symbol: '2157.T',
  },
  {
    value: '2158.T',
    label: '2158.T,  FRONTEO, Inc.',
    name: 'FRONTEO, Inc.',
    symbol: '2158.T',
  },
  {
    value: '216080.KQ',
    label: '216080.KQ,  JETEMA, Co., Ltd.',
    name: 'JETEMA, Co., Ltd.',
    symbol: '216080.KQ',
  },
  {
    value: '2160.T',
    label: '2160.T,  GNI Group Ltd.',
    name: 'GNI Group Ltd.',
    symbol: '2160.T',
  },
  {
    value: '2168.T',
    label: '2168.T,  Pasona Group Inc.',
    name: 'Pasona Group Inc.',
    symbol: '2168.T',
  },
  {
    value: '2170.T',
    label: '2170.T,  Link and Motivation Inc.',
    name: 'Link and Motivation Inc.',
    symbol: '2170.T',
  },
  {
    value: '2175.T',
    label: '2175.T,  SMS Co., Ltd.',
    name: 'SMS Co., Ltd.',
    symbol: '2175.T',
  },
  {
    value: '2181.T',
    label: '2181.T,  Persol Holdings Co., Ltd.',
    name: 'Persol Holdings Co., Ltd.',
    symbol: '2181.T',
  },
  {
    value: '218410.KQ',
    label: '218410.KQ,  RFHIC Corporation',
    name: 'RFHIC Corporation',
    symbol: '218410.KQ',
  },
  {
    value: '2193.T',
    label: '2193.T,  Cookpad Inc.',
    name: 'Cookpad Inc.',
    symbol: '2193.T',
  },
  {
    value: '2198.T',
    label: '2198.T,  IKK Holdings Inc .',
    name: 'IKK Holdings Inc .',
    symbol: '2198.T',
  },
  {
    value: '2201.T',
    label: '2201.T,  Morinaga&Co., Ltd.',
    name: 'Morinaga&Co., Ltd.',
    symbol: '2201.T',
  },
  {
    value: '2201.TW',
    label: '2201.TW,  Yulon Motor Company Ltd.',
    name: 'Yulon Motor Company Ltd.',
    symbol: '2201.TW',
  },
  {
    value: '2204.TW',
    label: '2204.TW,  China Motor Corporation',
    name: 'China Motor Corporation',
    symbol: '2204.TW',
  },
  {
    value: '2206.T',
    label: '2206.T,  Ezaki Glico Co., Ltd.',
    name: 'Ezaki Glico Co., Ltd.',
    symbol: '2206.T',
  },
  {
    value: '2206.TW',
    label: '2206.TW,  Sanyang Motor Co., Ltd.',
    name: 'Sanyang Motor Co., Ltd.',
    symbol: '2206.TW',
  },
  {
    value: '2207.TW',
    label: '2207.TW,  Hotai Motor Co.,Ltd.',
    name: 'Hotai Motor Co.,Ltd.',
    symbol: '2207.TW',
  },
  {
    value: '2208.TW',
    label: '2208.TW,  CSBC Corporation',
    name: 'CSBC Corporation',
    symbol: '2208.TW',
  },
  {
    value: '2209.T',
    label: '2209.T,  Imuraya Group Co., Ltd.',
    name: 'Imuraya Group Co., Ltd.',
    symbol: '2209.T',
  },
  {
    value: '2211.T',
    label: '2211.T,  Fujiya Co., Ltd.',
    name: 'Fujiya Co., Ltd.',
    symbol: '2211.T',
  },
  {
    value: '2211.TW',
    label: '2211.TW,  Evergreen Steel Corp.',
    name: 'Evergreen Steel Corp.',
    symbol: '2211.TW',
  },
  {
    value: '2212.T',
    label: '2212.T,  Yamazaki Baking Co., Ltd.',
    name: 'Yamazaki Baking Co., Ltd.',
    symbol: '2212.T',
  },
  {
    value: '222080.KQ',
    label: '222080.KQ,  Creative & Innovative System Corporation',
    name: 'Creative & Innovative System Corporation',
    symbol: '222080.KQ',
  },
  {
    value: 'PBAXU',
    label: 'PBAXU,  Phoenix Biotech Acquisition Corp.',
    name: 'Phoenix Biotech Acquisition Corp.',
    symbol: 'PBAXU',
  },
  {
    value: '226950.KQ',
    label: '226950.KQ,  OliX Pharmaceuticals,Inc',
    name: 'OliX Pharmaceuticals,Inc',
    symbol: '226950.KQ',
  },
  {
    value: '2269.T',
    label: '2269.T,  Meiji Holdings Co., Ltd.',
    name: 'Meiji Holdings Co., Ltd.',
    symbol: '2269.T',
  },
  {
    value: '2270.SR',
    label: '2270.SR,  Saudia Dairy & Foodstuff Company',
    name: 'Saudia Dairy & Foodstuff Company',
    symbol: '2270.SR',
  },
  {
    value: '2270.T',
    label: '2270.T,  MEGMILK SNOW BRAND Co.,Ltd.',
    name: 'MEGMILK SNOW BRAND Co.,Ltd.',
    symbol: '2270.T',
  },
  {
    value: '2280.SR',
    label: '2280.SR,  Almarai Company',
    name: 'Almarai Company',
    symbol: '2280.SR',
  },
  {
    value: '2281.T',
    label: '2281.T,  Prima Meat Packers, Ltd.',
    name: 'Prima Meat Packers, Ltd.',
    symbol: '2281.T',
  },
  {
    value: '2282.T',
    label: '2282.T,  NH Foods Ltd.',
    name: 'NH Foods Ltd.',
    symbol: '2282.T',
  },
  {
    value: '2288.T',
    label: '2288.T,  Marudai Food Co., Ltd.',
    name: 'Marudai Food Co., Ltd.',
    symbol: '2288.T',
  },
  {
    value: '2290.SR',
    label: '2290.SR,  Yanbu National Petrochemical Company',
    name: 'Yanbu National Petrochemical Company',
    symbol: '2290.SR',
  },
  {
    value: '2292.T',
    label: '2292.T,  S Foods Inc.',
    name: 'S Foods Inc.',
    symbol: '2292.T',
  },
  {
    value: '2294.T',
    label: '2294.T,  Kakiyasu Honten Co., Ltd.',
    name: 'Kakiyasu Honten Co., Ltd.',
    symbol: '2294.T',
  },
  {
    value: '2296.T',
    label: '2296.T,  Itoham Yonekyu Holdings Inc.',
    name: 'Itoham Yonekyu Holdings Inc.',
    symbol: '2296.T',
  },
  {
    value: '2300.SR',
    label: '2300.SR,  Saudi Paper Manufacturing Company',
    name: 'Saudi Paper Manufacturing Company',
    symbol: '2300.SR',
  },
  {
    value: '2301.T',
    label: '2301.T,  Gakujo Co., Ltd.',
    name: 'Gakujo Co., Ltd.',
    symbol: '2301.T',
  },
  {
    value: '2301.TW',
    label: '2301.TW,  Lite-On Technology Corporation',
    name: 'Lite-On Technology Corporation',
    symbol: '2301.TW',
  },
  {
    value: '230240.KQ',
    label: '230240.KQ,  HFR, Inc.',
    name: 'HFR, Inc.',
    symbol: '230240.KQ',
  },
  {
    value: '2302.TW',
    label: '2302.TW,  Rectron Limited',
    name: 'Rectron Limited',
    symbol: '2302.TW',
  },
  {
    value: '230360.KQ',
    label: '230360.KQ,  Echomarketing Co.,Ltd.',
    name: 'Echomarketing Co.,Ltd.',
    symbol: '230360.KQ',
  },
  {
    value: '2303.TW',
    label: '2303.TW,  United Microelectronics Corporation',
    name: 'United Microelectronics Corporation',
    symbol: '2303.TW',
  },
  {
    value: '2305.T',
    label: '2305.T,  STUDIO ALICE Co.,Ltd.',
    name: 'STUDIO ALICE Co.,Ltd.',
    symbol: '2305.T',
  },
  {
    value: '2308.TW',
    label: '2308.TW,  Delta Electronics, Inc.',
    name: 'Delta Electronics, Inc.',
    symbol: '2308.TW',
  },
  {
    value: '2309.T',
    label: '2309.T,  CMIC HOLDINGS Co., Ltd.',
    name: 'CMIC HOLDINGS Co., Ltd.',
    symbol: '2309.T',
  },
  {
    value: '2310.SR',
    label: '2310.SR,  Sahara International Petrochemical Company',
    name: 'Sahara International Petrochemical Company',
    symbol: '2310.SR',
  },
  {
    value: '2312.TW',
    label: '2312.TW,  Kinpo Electronics, Inc.',
    name: 'Kinpo Electronics, Inc.',
    symbol: '2312.TW',
  },
  {
    value: '2313.TW',
    label: '2313.TW,  Compeq Manufacturing Co., Ltd.',
    name: 'Compeq Manufacturing Co., Ltd.',
    symbol: '2313.TW',
  },
  {
    value: '2314.TW',
    label: '2314.TW,  Microelectronics Technology Inc.',
    name: 'Microelectronics Technology Inc.',
    symbol: '2314.TW',
  },
  {
    value: '2315.T',
    label: '2315.T,  CAICA DIGITAL Inc.',
    name: 'CAICA DIGITAL Inc.',
    symbol: '2315.T',
  },
  {
    value: '2316.TW',
    label: '2316.TW,  WUS Printed Circuit Co., Ltd.',
    name: 'WUS Printed Circuit Co., Ltd.',
    symbol: '2316.TW',
  },
  {
    value: '2317.T',
    label: '2317.T,  Systena Corporation',
    name: 'Systena Corporation',
    symbol: '2317.T',
  },
  {
    value: '2317.TW',
    label: '2317.TW,  Hon Hai Precision Industry Co., Ltd.',
    name: 'Hon Hai Precision Industry Co., Ltd.',
    symbol: '2317.TW',
  },
  {
    value: '2320.SR',
    label: '2320.SR,  Al-Babtain Power and Telecommunication Co.',
    name: 'Al-Babtain Power and Telecommunication Co.',
    symbol: '2320.SR',
  },
  {
    value: '2323.TW',
    label: '2323.TW,  CMC Magnetics Corporation',
    name: 'CMC Magnetics Corporation',
    symbol: '2323.TW',
  },
  {
    value: '2324.TW',
    label: '2324.TW,  Compal Electronics, Inc.',
    name: 'Compal Electronics, Inc.',
    symbol: '2324.TW',
  },
  {
    value: '2326.T',
    label: '2326.T,  Digital Arts Inc.',
    name: 'Digital Arts Inc.',
    symbol: '2326.T',
  },
  {
    value: '2327.T',
    label: '2327.T,  NS Solutions Corporation',
    name: 'NS Solutions Corporation',
    symbol: '2327.T',
  },
  {
    value: '2327.TW',
    label: '2327.TW,  Yageo Corporation',
    name: 'Yageo Corporation',
    symbol: '2327.TW',
  },
  {
    value: '2328.TW',
    label: '2328.TW,  Pan-International Industrial Corp.',
    name: 'Pan-International Industrial Corp.',
    symbol: '2328.TW',
  },
  {
    value: '2329.TW',
    label: '2329.TW,  Orient Semiconductor Electronics, Limited',
    name: 'Orient Semiconductor Electronics, Limited',
    symbol: '2329.TW',
  },
  {
    value: '2330.SR',
    label: '2330.SR,  Advanced Petrochemical Company',
    name: 'Advanced Petrochemical Company',
    symbol: '2330.SR',
  },
  {
    value: '2330.TW',
    label: '2330.TW,  Taiwan Semiconductor Manufacturing Company Limited',
    name: 'Taiwan Semiconductor Manufacturing Company Limited',
    symbol: '2330.TW',
  },
  {
    value: '2331.T',
    label: '2331.T,  Sohgo Security Services Co.,Ltd.',
    name: 'Sohgo Security Services Co.,Ltd.',
    symbol: '2331.T',
  },
  {
    value: '2331.TW',
    label: '2331.TW,  Elitegroup Computer Systems Co.,Ltd.',
    name: 'Elitegroup Computer Systems Co.,Ltd.',
    symbol: '2331.TW',
  },
  {
    value: '2337.T',
    label: '2337.T,  Ichigo Inc.',
    name: 'Ichigo Inc.',
    symbol: '2337.T',
  },
  {
    value: '2337.TW',
    label: '2337.TW,  Macronix International Co., Ltd.',
    name: 'Macronix International Co., Ltd.',
    symbol: '2337.TW',
  },
  {
    value: '2338.TW',
    label: '2338.TW,  Taiwan Mask Corporation',
    name: 'Taiwan Mask Corporation',
    symbol: '2338.TW',
  },
  {
    value: '234080.KS',
    label: '234080.KS,  JW Lifescience Corporation',
    name: 'JW Lifescience Corporation',
    symbol: '234080.KS',
  },
  {
    value: '2340.SR',
    label: '2340.SR,  Al Abdullatif Industrial Investment Company',
    name: 'Al Abdullatif Industrial Investment Company',
    symbol: '2340.SR',
  },
  {
    value: '2340.TW',
    label: '2340.TW,  Taiwan-Asia Semiconductor Corporation',
    name: 'Taiwan-Asia Semiconductor Corporation',
    symbol: '2340.TW',
  },
  {
    value: '2342.TW',
    label: '2342.TW,  Mosel Vitelic Inc.',
    name: 'Mosel Vitelic Inc.',
    symbol: '2342.TW',
  },
  {
    value: '2344.TW',
    label: '2344.TW,  Winbond Electronics Corporation',
    name: 'Winbond Electronics Corporation',
    symbol: '2344.TW',
  },
  {
    value: '2347.TW',
    label: '2347.TW,  Synnex Technology International Corporation',
    name: 'Synnex Technology International Corporation',
    symbol: '2347.TW',
  },
  {
    value: '2348.TW',
    label: '2348.TW,  Hiyes International Co., Ltd.',
    name: 'Hiyes International Co., Ltd.',
    symbol: '2348.TW',
  },
  {
    value: '2349.TW',
    label: '2349.TW,  RITEK Corporation',
    name: 'RITEK Corporation',
    symbol: '2349.TW',
  },
  {
    value: '2350.SR',
    label: '2350.SR,  Saudi Kayan Petrochemical Company',
    name: 'Saudi Kayan Petrochemical Company',
    symbol: '2350.SR',
  },
  {
    value: '2351.TW',
    label: '2351.TW,  SDI Corporation',
    name: 'SDI Corporation',
    symbol: '2351.TW',
  },
  {
    value: '2352.TW',
    label: '2352.TW,  Qisda Corporation',
    name: 'Qisda Corporation',
    symbol: '2352.TW',
  },
  {
    value: '2353.T',
    label: '2353.T,  NIPPON PARKING DEVELOPMENT Co.,Ltd.',
    name: 'NIPPON PARKING DEVELOPMENT Co.,Ltd.',
    symbol: '2353.T',
  },
  {
    value: '2353.TW',
    label: '2353.TW,  Acer Incorporated',
    name: 'Acer Incorporated',
    symbol: '2353.TW',
  },
  {
    value: '2354.TW',
    label: '2354.TW,  Foxconn Technology Co., Ltd.',
    name: 'Foxconn Technology Co., Ltd.',
    symbol: '2354.TW',
  },
  {
    value: '2355.TW',
    label: '2355.TW,  Chin-Poon Industrial Co., Ltd.',
    name: 'Chin-Poon Industrial Co., Ltd.',
    symbol: '2355.TW',
  },
  {
    value: '2356.TW',
    label: '2356.TW,  Inventec Corporation',
    name: 'Inventec Corporation',
    symbol: '2356.TW',
  },
  {
    value: '2357.TW',
    label: '2357.TW,  ASUSTeK Computer Inc.',
    name: 'ASUSTeK Computer Inc.',
    symbol: '2357.TW',
  },
  {
    value: '235980.KQ',
    label: '235980.KQ,  MedPacto, Inc.',
    name: 'MedPacto, Inc.',
    symbol: '235980.KQ',
  },
  {
    value: '2359.T',
    label: '2359.T,  Core Corporation',
    name: 'Core Corporation',
    symbol: '2359.T',
  },
  {
    value: '2360.SR',
    label: '2360.SR,  Saudi Vitrified Clay Pipe Company',
    name: 'Saudi Vitrified Clay Pipe Company',
    symbol: '2360.SR',
  },
  {
    value: '2360.TW',
    label: '2360.TW,  Chroma ATE Inc.',
    name: 'Chroma ATE Inc.',
    symbol: '2360.TW',
  },
  {
    value: '2362.TW',
    label: '2362.TW,  Clevo Co.',
    name: 'Clevo Co.',
    symbol: '2362.TW',
  },
  {
    value: '2363.TW',
    label: '2363.TW,  Silicon Integrated Systems Corp.',
    name: 'Silicon Integrated Systems Corp.',
    symbol: '2363.TW',
  },
  {
    value: '2367.TW',
    label: '2367.TW,  Unitech Printed Circuit Board Corp.',
    name: 'Unitech Printed Circuit Board Corp.',
    symbol: '2367.TW',
  },
  {
    value: '2368.TW',
    label: '2368.TW,  Gold Circuit Electronics Ltd.',
    name: 'Gold Circuit Electronics Ltd.',
    symbol: '2368.TW',
  },
  {
    value: '2369.TW',
    label: '2369.TW,  Lingsen Precision Industries, Ltd.',
    name: 'Lingsen Precision Industries, Ltd.',
    symbol: '2369.TW',
  },
  {
    value: '2370.T',
    label: '2370.T,  MEDINET Co., Ltd.',
    name: 'MEDINET Co., Ltd.',
    symbol: '2370.T',
  },
  {
    value: '2371.T',
    label: '2371.T,  Kakaku.com, Inc.',
    name: 'Kakaku.com, Inc.',
    symbol: '2371.T',
  },
  {
    value: '2371.TW',
    label: '2371.TW,  Tatung Co., Ltd.',
    name: 'Tatung Co., Ltd.',
    symbol: '2371.TW',
  },
  {
    value: '2372.T',
    label: "2372.T,  I'rom Group Co., Ltd.",
    name: "I'rom Group Co., Ltd.",
    symbol: '2372.T',
  },
  {
    value: '2373.TW',
    label: '2373.TW,  Aurora Corporation',
    name: 'Aurora Corporation',
    symbol: '2373.TW',
  },
  {
    value: '2374.T',
    label: '2374.T,  Saint-Care Holding Corporation',
    name: 'Saint-Care Holding Corporation',
    symbol: '2374.T',
  },
  {
    value: '2374.TW',
    label: '2374.TW,  Ability Enterprise Co., Ltd.',
    name: 'Ability Enterprise Co., Ltd.',
    symbol: '2374.TW',
  },
  {
    value: '2375.TW',
    label: '2375.TW,  Kaimei Electronic Corp.',
    name: 'Kaimei Electronic Corp.',
    symbol: '2375.TW',
  },
  {
    value: '237690.KQ',
    label: '237690.KQ,  ST Pharm Co.,Ltd.',
    name: 'ST Pharm Co.,Ltd.',
    symbol: '237690.KQ',
  },
  {
    value: '2376.TW',
    label: '2376.TW,  Giga-Byte Technology Co., Ltd.',
    name: 'Giga-Byte Technology Co., Ltd.',
    symbol: '2376.TW',
  },
  {
    value: '2377.TW',
    label: '2377.TW,  Micro-Star International Co., Ltd.',
    name: 'Micro-Star International Co., Ltd.',
    symbol: '2377.TW',
  },
  {
    value: '2378.T',
    label: '2378.T,  Renaissance,Incorporated',
    name: 'Renaissance,Incorporated',
    symbol: '2378.T',
  },
  {
    value: '2379.T',
    label: '2379.T,  DIP Corporation',
    name: 'DIP Corporation',
    symbol: '2379.T',
  },
  {
    value: '2379.TW',
    label: '2379.TW,  Realtek Semiconductor Corp.',
    name: 'Realtek Semiconductor Corp.',
    symbol: '2379.TW',
  },
  {
    value: '2380.SR',
    label: '2380.SR,  Rabigh Refining and Petrochemical Company',
    name: 'Rabigh Refining and Petrochemical Company',
    symbol: '2380.SR',
  },
  {
    value: '2382.TW',
    label: '2382.TW,  Quanta Computer Inc.',
    name: 'Quanta Computer Inc.',
    symbol: '2382.TW',
  },
  {
    value: '2383.TW',
    label: '2383.TW,  Elite Material Co., Ltd.',
    name: 'Elite Material Co., Ltd.',
    symbol: '2383.TW',
  },
  {
    value: '2384.T',
    label: '2384.T,  SBS Holdings, Inc.',
    name: 'SBS Holdings, Inc.',
    symbol: '2384.T',
  },
  {
    value: '2385.TW',
    label: '2385.TW,  Chicony Electronics Co., Ltd.',
    name: 'Chicony Electronics Co., Ltd.',
    symbol: '2385.TW',
  },
  {
    value: '2387.TW',
    label: '2387.TW,  Sunrex Technology Corporation',
    name: 'Sunrex Technology Corporation',
    symbol: '2387.TW',
  },
  {
    value: '2388.TW',
    label: '2388.TW,  VIA Technologies, Inc.',
    name: 'VIA Technologies, Inc.',
    symbol: '2388.TW',
  },
  {
    value: '2389.T',
    label: '2389.T,  Digital Holdings, Inc.',
    name: 'Digital Holdings, Inc.',
    symbol: '2389.T',
  },
  {
    value: '2390.TW',
    label: '2390.TW,  Everspring Industry Co., Ltd.',
    name: 'Everspring Industry Co., Ltd.',
    symbol: '2390.TW',
  },
  {
    value: '2392.TW',
    label: '2392.TW,  Cheng Uei Precision Industry Co., Ltd.',
    name: 'Cheng Uei Precision Industry Co., Ltd.',
    symbol: '2392.TW',
  },
  {
    value: '2393.TW',
    label: '2393.TW,  Everlight Electronics Co., Ltd.',
    name: 'Everlight Electronics Co., Ltd.',
    symbol: '2393.TW',
  },
  {
    value: '2395.T',
    label: '2395.T,  Shin Nippon Biomedical Laboratories, Ltd.',
    name: 'Shin Nippon Biomedical Laboratories, Ltd.',
    symbol: '2395.T',
  },
  {
    value: '2395.TW',
    label: '2395.TW,  Advantech Co., Ltd.',
    name: 'Advantech Co., Ltd.',
    symbol: '2395.TW',
  },
  {
    value: '2399.TW',
    label: '2399.TW,  Biostar Microtech International Corp.',
    name: 'Biostar Microtech International Corp.',
    symbol: '2399.TW',
  },
  {
    value: '2401.TW',
    label: '2401.TW,  Sunplus Technology Company Limited',
    name: 'Sunplus Technology Company Limited',
    symbol: '2401.TW',
  },
  {
    value: '2402.TW',
    label: '2402.TW,  Ichia Technologies, Inc.',
    name: 'Ichia Technologies, Inc.',
    symbol: '2402.TW',
  },
  {
    value: '2404.TW',
    label: '2404.TW,  United Integrated Services Co., Ltd.',
    name: 'United Integrated Services Co., Ltd.',
    symbol: '2404.TW',
  },
  {
    value: '2405.TW',
    label: '2405.TW,  Shuttle Inc.',
    name: 'Shuttle Inc.',
    symbol: '2405.TW',
  },
  {
    value: '2406.TW',
    label: '2406.TW,  Gigastorage Corporation',
    name: 'Gigastorage Corporation',
    symbol: '2406.TW',
  },
  {
    value: '240810.KQ',
    label: '240810.KQ,  Wonik Ips Co., Ltd',
    name: 'Wonik Ips Co., Ltd',
    symbol: '240810.KQ',
  },
  {
    value: '2408.TW',
    label: '2408.TW,  Nanya Technology Corporation',
    name: 'Nanya Technology Corporation',
    symbol: '2408.TW',
  },
  {
    value: '2409.TW',
    label: '2409.TW,  AUO Corporation',
    name: 'AUO Corporation',
    symbol: '2409.TW',
  },
  {
    value: '2412.T',
    label: '2412.T,  Benefit One Inc.',
    name: 'Benefit One Inc.',
    symbol: '2412.T',
  },
  {
    value: '2412.TW',
    label: '2412.TW,  Chunghwa Telecom Co., Ltd.',
    name: 'Chunghwa Telecom Co., Ltd.',
    symbol: '2412.TW',
  },
  {
    value: '2413.T',
    label: '2413.T,  M3, Inc.',
    name: 'M3, Inc.',
    symbol: '2413.T',
  },
  {
    value: '2413.TW',
    label: '2413.TW,  Universal Microelectronics Co., Ltd.',
    name: 'Universal Microelectronics Co., Ltd.',
    symbol: '2413.TW',
  },
  {
    value: '241560.KS',
    label: '241560.KS,  Doosan Bobcat Inc.',
    name: 'Doosan Bobcat Inc.',
    symbol: '241560.KS',
  },
  {
    value: '241590.KS',
    label: '241590.KS,  Hwaseung Enterprise Co., Ltd.',
    name: 'Hwaseung Enterprise Co., Ltd.',
    symbol: '241590.KS',
  },
  {
    value: '2415.TW',
    label: '2415.TW,  CX Technology Corporation',
    name: 'CX Technology Corporation',
    symbol: '2415.TW',
  },
  {
    value: '241820.KQ',
    label: '241820.KQ,  PCL, Inc.',
    name: 'PCL, Inc.',
    symbol: '241820.KQ',
  },
  {
    value: '2418.T',
    label: '2418.T,  TSUKADA GLOBAL HOLDINGS Inc.',
    name: 'TSUKADA GLOBAL HOLDINGS Inc.',
    symbol: '2418.T',
  },
  {
    value: '2419.TW',
    label: '2419.TW,  Hitron Technologies Inc.',
    name: 'Hitron Technologies Inc.',
    symbol: '2419.TW',
  },
  {
    value: '2420.TW',
    label: '2420.TW,  Zippy Technology Corp.',
    name: 'Zippy Technology Corp.',
    symbol: '2420.TW',
  },
  {
    value: '2421.TW',
    label: '2421.TW,  Sunonwealth Electric Machine Industry Co., Ltd.',
    name: 'Sunonwealth Electric Machine Industry Co., Ltd.',
    symbol: '2421.TW',
  },
  {
    value: '2426.TW',
    label: '2426.TW,  Tyntek Corporation',
    name: 'Tyntek Corporation',
    symbol: '2426.TW',
  },
  {
    value: '2427.T',
    label: '2427.T,  OUTSOURCING Inc.',
    name: 'OUTSOURCING Inc.',
    symbol: '2427.T',
  },
  {
    value: '2428.TW',
    label: '2428.TW,  Thinking Electronic Industrial Co., Ltd.',
    name: 'Thinking Electronic Industrial Co., Ltd.',
    symbol: '2428.TW',
  },
  {
    value: '2429.T',
    label: '2429.T,  World Holdings Co., Ltd.',
    name: 'World Holdings Co., Ltd.',
    symbol: '2429.T',
  },
  {
    value: '243070.KQ',
    label: '243070.KQ,  Huons Co., Ltd.',
    name: 'Huons Co., Ltd.',
    symbol: '243070.KQ',
  },
  {
    value: '2430.TW',
    label: '2430.TW,  Tsann Kuen Enterprise Co.,Ltd',
    name: 'Tsann Kuen Enterprise Co.,Ltd',
    symbol: '2430.TW',
  },
  {
    value: '2432.T',
    label: '2432.T,  DeNA Co., Ltd.',
    name: 'DeNA Co., Ltd.',
    symbol: '2432.T',
  },
  {
    value: '2433.T',
    label: '2433.T,  Hakuhodo DY Holdings Inc',
    name: 'Hakuhodo DY Holdings Inc',
    symbol: '2433.T',
  },
  {
    value: '2433.TW',
    label: '2433.TW,  Huxen Corporation',
    name: 'Huxen Corporation',
    symbol: '2433.TW',
  },
  {
    value: '2436.TW',
    label: '2436.TW,  Weltrend Semiconductor, Inc.',
    name: 'Weltrend Semiconductor, Inc.',
    symbol: '2436.TW',
  },
  {
    value: '2438.T',
    label: '2438.T,  Asukanet Co., Ltd.',
    name: 'Asukanet Co., Ltd.',
    symbol: '2438.T',
  },
  {
    value: '2439.TW',
    label: '2439.TW,  Merry Electronics Co., Ltd.',
    name: 'Merry Electronics Co., Ltd.',
    symbol: '2439.TW',
  },
  {
    value: '2440.T',
    label: '2440.T,  Gurunavi, Inc.',
    name: 'Gurunavi, Inc.',
    symbol: '2440.T',
  },
  {
    value: '2441.TW',
    label: '2441.TW,  Greatek Electronics Inc.',
    name: 'Greatek Electronics Inc.',
    symbol: '2441.TW',
  },
  {
    value: '2445.T',
    label: '2445.T,  Takamiya Co., Ltd.',
    name: 'Takamiya Co., Ltd.',
    symbol: '2445.T',
  },
  {
    value: '2449.TW',
    label: '2449.TW,  King Yuan Electronics Co., Ltd.',
    name: 'King Yuan Electronics Co., Ltd.',
    symbol: '2449.TW',
  },
  {
    value: '2450.TW',
    label: '2450.TW,  Senao International Co.,Ltd.',
    name: 'Senao International Co.,Ltd.',
    symbol: '2450.TW',
  },
  {
    value: '2451.TW',
    label: '2451.TW,  Transcend Information, Inc.',
    name: 'Transcend Information, Inc.',
    symbol: '2451.TW',
  },
  {
    value: '2454.TW',
    label: '2454.TW,  MediaTek Inc.',
    name: 'MediaTek Inc.',
    symbol: '2454.TW',
  },
  {
    value: '2455.TW',
    label: '2455.TW,  Visual Photonics Epitaxy Co., Ltd.',
    name: 'Visual Photonics Epitaxy Co., Ltd.',
    symbol: '2455.TW',
  },
  {
    value: '2457.TW',
    label: '2457.TW,  Phihong Technology Co., Ltd.',
    name: 'Phihong Technology Co., Ltd.',
    symbol: '2457.TW',
  },
  {
    value: '2458.TW',
    label: '2458.TW,  ELAN Microelectronics Corporation',
    name: 'ELAN Microelectronics Corporation',
    symbol: '2458.TW',
  },
  {
    value: '2459.TW',
    label: '2459.TW,  Audix Corporation',
    name: 'Audix Corporation',
    symbol: '2459.TW',
  },
  {
    value: '2460.TW',
    label: '2460.TW,  GEM Terminal Industry Co.,Ltd.',
    name: 'GEM Terminal Industry Co.,Ltd.',
    symbol: '2460.TW',
  },
  {
    value: '2461.T',
    label: '2461.T,  FAN Communications, Inc.',
    name: 'FAN Communications, Inc.',
    symbol: '2461.T',
  },
  {
    value: '2461.TW',
    label: '2461.TW,  K Laser Technology Inc.',
    name: 'K Laser Technology Inc.',
    symbol: '2461.TW',
  },
  {
    value: '2462.T',
    label: '2462.T,  LIKE Co., Ltd.',
    name: 'LIKE Co., Ltd.',
    symbol: '2462.T',
  },
  {
    value: '2464.TW',
    label: '2464.TW,  Mirle Automation Corporation',
    name: 'Mirle Automation Corporation',
    symbol: '2464.TW',
  },
  {
    value: '2465.TW',
    label: '2465.TW,  Leadtek Research Inc.',
    name: 'Leadtek Research Inc.',
    symbol: '2465.TW',
  },
  {
    value: '2467.TW',
    label: '2467.TW,  C Sun Mfg Ltd.',
    name: 'C Sun Mfg Ltd.',
    symbol: '2467.TW',
  },
  {
    value: '2471.T',
    label: '2471.T,  S-Pool, Inc.',
    name: 'S-Pool, Inc.',
    symbol: '2471.T',
  },
  {
    value: '2472.TW',
    label: '2472.TW,  Lelon Electronics Corp.',
    name: 'Lelon Electronics Corp.',
    symbol: '2472.TW',
  },
  {
    value: '2474.TW',
    label: '2474.TW,  Catcher Technology Co., Ltd.',
    name: 'Catcher Technology Co., Ltd.',
    symbol: '2474.TW',
  },
  {
    value: '247540.KQ',
    label: '247540.KQ,  Ecopro BM. Co., Ltd.',
    name: 'Ecopro BM. Co., Ltd.',
    symbol: '247540.KQ',
  },
  {
    value: '2475.T',
    label: '2475.T,  WDB Holdings Co., Ltd.',
    name: 'WDB Holdings Co., Ltd.',
    symbol: '2475.T',
  },
  {
    value: '2476.TW',
    label: '2476.TW,  G-SHANK Enterprise Co., Ltd.',
    name: 'G-SHANK Enterprise Co., Ltd.',
    symbol: '2476.TW',
  },
  {
    value: '2478.TW',
    label: '2478.TW,  TA-I Technology Co., Ltd.',
    name: 'TA-I Technology Co., Ltd.',
    symbol: '2478.TW',
  },
  {
    value: '248070.KS',
    label: '248070.KS,  Solum Co., Ltd.',
    name: 'Solum Co., Ltd.',
    symbol: '248070.KS',
  },
  {
    value: '2480.TW',
    label: '2480.TW,  Stark Technology Inc.',
    name: 'Stark Technology Inc.',
    symbol: '2480.TW',
  },
  {
    value: '2481.TW',
    label: '2481.TW,  Panjit International Inc.',
    name: 'Panjit International Inc.',
    symbol: '2481.TW',
  },
  {
    value: '2484.T',
    label: '2484.T,  Demae-Can Co.,Ltd',
    name: 'Demae-Can Co.,Ltd',
    symbol: '2484.T',
  },
  {
    value: '2484.TW',
    label: '2484.TW,  Siward Crystal Technology Co., Ltd',
    name: 'Siward Crystal Technology Co., Ltd',
    symbol: '2484.TW',
  },
  {
    value: '2485.TW',
    label: '2485.TW,  Zinwell Corporation',
    name: 'Zinwell Corporation',
    symbol: '2485.TW',
  },
  {
    value: '2486.TW',
    label: '2486.TW,  I-Chiun Precision Industry Co., Ltd.',
    name: 'I-Chiun Precision Industry Co., Ltd.',
    symbol: '2486.TW',
  },
  {
    value: '2489.T',
    label: '2489.T,  Adways Inc.',
    name: 'Adways Inc.',
    symbol: '2489.T',
  },
  {
    value: '2489.TW',
    label: '2489.TW,  AmTRAN Technology Co.,Ltd',
    name: 'AmTRAN Technology Co.,Ltd',
    symbol: '2489.TW',
  },
  {
    value: '2491.T',
    label: '2491.T,  ValueCommerce Co., Ltd.',
    name: 'ValueCommerce Co., Ltd.',
    symbol: '2491.T',
  },
  {
    value: '2492.T',
    label: '2492.T,  Infomart Corporation',
    name: 'Infomart Corporation',
    symbol: '2492.T',
  },
  {
    value: '2492.TW',
    label: '2492.TW,  Walsin Technology Corporation',
    name: 'Walsin Technology Corporation',
    symbol: '2492.TW',
  },
  {
    value: '2493.TW',
    label: '2493.TW,  Ampoc Far-East Co., Ltd.',
    name: 'Ampoc Far-East Co., Ltd.',
    symbol: '2493.TW',
  },
  {
    value: '249420.KS',
    label: '249420.KS,  Ildong Pharmaceutical Co., Ltd.',
    name: 'Ildong Pharmaceutical Co., Ltd.',
    symbol: '249420.KS',
  },
  {
    value: '2497.T',
    label: '2497.T,  UNITED, Inc.',
    name: 'UNITED, Inc.',
    symbol: '2497.T',
  },
  {
    value: '2497.TW',
    label: '2497.TW,  E-Lead Electronic Co., Ltd.',
    name: 'E-Lead Electronic Co., Ltd.',
    symbol: '2497.TW',
  },
  {
    value: '2498.TW',
    label: '2498.TW,  HTC Corporation',
    name: 'HTC Corporation',
    symbol: '2498.TW',
  },
  {
    value: '2501.T',
    label: '2501.T,  Sapporo Holdings Limited',
    name: 'Sapporo Holdings Limited',
    symbol: '2501.T',
  },
  {
    value: '2501.TW',
    label: '2501.TW,  Cathay Real Estate Development Co.,Ltd.',
    name: 'Cathay Real Estate Development Co.,Ltd.',
    symbol: '2501.TW',
  },
  {
    value: '2502.T',
    label: '2502.T,  Asahi Group Holdings, Ltd.',
    name: 'Asahi Group Holdings, Ltd.',
    symbol: '2502.T',
  },
  {
    value: '2503.T',
    label: '2503.T,  Kirin Holdings Company, Limited',
    name: 'Kirin Holdings Company, Limited',
    symbol: '2503.T',
  },
  {
    value: '2504.TW',
    label: '2504.TW,  Goldsun Building Materials Co., Ltd.',
    name: 'Goldsun Building Materials Co., Ltd.',
    symbol: '2504.TW',
  },
  {
    value: '2505.TW',
    label: '2505.TW,  Kuo Yang Construction Co.,Ltd',
    name: 'Kuo Yang Construction Co.,Ltd',
    symbol: '2505.TW',
  },
  {
    value: '2511.TW',
    label: '2511.TW,  Prince Housing & Development Corp.',
    name: 'Prince Housing & Development Corp.',
    symbol: '2511.TW',
  },
  {
    value: '251270.KS',
    label: '251270.KS,  Netmarble Corporation',
    name: 'Netmarble Corporation',
    symbol: '251270.KS',
  },
  {
    value: '2514.TW',
    label: '2514.TW,  Long Bon International Co.,Ltd',
    name: 'Long Bon International Co.,Ltd',
    symbol: '2514.TW',
  },
  {
    value: '2515.TW',
    label: '2515.TW,  BES Engineering Corporation',
    name: 'BES Engineering Corporation',
    symbol: '2515.TW',
  },
  {
    value: '2520.TW',
    label: '2520.TW,  Kindom Development Co., Ltd.',
    name: 'Kindom Development Co., Ltd.',
    symbol: '2520.TW',
  },
  {
    value: '2527.TW',
    label: '2527.TW,  Hung Ching Development & Construction Co., Ltd.',
    name: 'Hung Ching Development & Construction Co., Ltd.',
    symbol: '2527.TW',
  },
  {
    value: '2531.T',
    label: '2531.T,  Takara Holdings Inc.',
    name: 'Takara Holdings Inc.',
    symbol: '2531.T',
  },
  {
    value: '2533.T',
    label: '2533.T,  Oenon Holdings, Inc.',
    name: 'Oenon Holdings, Inc.',
    symbol: '2533.T',
  },
  {
    value: '253450.KQ',
    label: '253450.KQ,  Studio Dragon Corporation',
    name: 'Studio Dragon Corporation',
    symbol: '253450.KQ',
  },
  {
    value: '2534.TW',
    label: '2534.TW,  Hung Sheng Construction Ltd.',
    name: 'Hung Sheng Construction Ltd.',
    symbol: '2534.TW',
  },
  {
    value: '2535.TW',
    label: '2535.TW,  Da-Cin Construction Co.,Ltd.',
    name: 'Da-Cin Construction Co.,Ltd.',
    symbol: '2535.TW',
  },
  {
    value: '2536.TW',
    label: '2536.TW,  Hong Pu Real Estate Development Co., Ltd.',
    name: 'Hong Pu Real Estate Development Co., Ltd.',
    symbol: '2536.TW',
  },
  {
    value: '253840.KQ',
    label: '253840.KQ,  Sugentech Inc.',
    name: 'Sugentech Inc.',
    symbol: '253840.KQ',
  },
  {
    value: '2538.TW',
    label: '2538.TW,  Kee Tai Properties Co., Ltd.',
    name: 'Kee Tai Properties Co., Ltd.',
    symbol: '2538.TW',
  },
  {
    value: '2542.TW',
    label: '2542.TW,  Highwealth Construction Corp.',
    name: 'Highwealth Construction Corp.',
    symbol: '2542.TW',
  },
  {
    value: '2545.TW',
    label: '2545.TW,  Huang Hsiang Construction Corporation',
    name: 'Huang Hsiang Construction Corporation',
    symbol: '2545.TW',
  },
  {
    value: '2547.TW',
    label: '2547.TW,  Radium Life Tech. Co., Ltd.',
    name: 'Radium Life Tech. Co., Ltd.',
    symbol: '2547.TW',
  },
  {
    value: '2548.TW',
    label: '2548.TW,  Huaku Development Co., Ltd.',
    name: 'Huaku Development Co., Ltd.',
    symbol: '2548.TW',
  },
  {
    value: '2579.T',
    label: '2579.T,  Coca-Cola Bottlers Japan Holdings Inc.',
    name: 'Coca-Cola Bottlers Japan Holdings Inc.',
    symbol: '2579.T',
  },
  {
    value: '2587.T',
    label: '2587.T,  Suntory Beverage & Food Limited',
    name: 'Suntory Beverage & Food Limited',
    symbol: '2587.T',
  },
  {
    value: '2590.T',
    label: '2590.T,  DyDo Group Holdings, Inc.',
    name: 'DyDo Group Holdings, Inc.',
    symbol: '2590.T',
  },
  {
    value: '2593.T',
    label: '2593.T,  Ito En, Ltd.',
    name: 'Ito En, Ltd.',
    symbol: '2593.T',
  },
  {
    value: '2594.T',
    label: '2594.T,  Key Coffee Inc.',
    name: 'Key Coffee Inc.',
    symbol: '2594.T',
  },
  {
    value: '2597.TW',
    label: '2597.TW,  Ruentex Engineering & Construction Co., Ltd.',
    name: 'Ruentex Engineering & Construction Co., Ltd.',
    symbol: '2597.TW',
  },
  {
    value: '2601.TW',
    label: '2601.TW,  First Steamship Co., Ltd.',
    name: 'First Steamship Co., Ltd.',
    symbol: '2601.TW',
  },
  {
    value: '2602.T',
    label: '2602.T,  The Nisshin OilliO Group,Ltd.',
    name: 'The Nisshin OilliO Group,Ltd.',
    symbol: '2602.T',
  },
  {
    value: '2603.TW',
    label: '2603.TW,  Evergreen Marine Corporation (Taiwan) Ltd.',
    name: 'Evergreen Marine Corporation (Taiwan) Ltd.',
    symbol: '2603.TW',
  },
  {
    value: '2605.TW',
    label: '2605.TW,  Sincere Navigation Corporation',
    name: 'Sincere Navigation Corporation',
    symbol: '2605.TW',
  },
  {
    value: '2606.TW',
    label: '2606.TW,  U-Ming Marine Transport Corporation',
    name: 'U-Ming Marine Transport Corporation',
    symbol: '2606.TW',
  },
  {
    value: '2607.T',
    label: '2607.T,  Fuji Oil Holdings Inc.',
    name: 'Fuji Oil Holdings Inc.',
    symbol: '2607.T',
  },
  {
    value: '2607.TW',
    label: '2607.TW,  Evergreen International Storage & Transport Corporation',
    name: 'Evergreen International Storage & Transport Corporation',
    symbol: '2607.TW',
  },
  {
    value: '2608.TW',
    label: '2608.TW,  Kerry TJ Logistics Company Limited',
    name: 'Kerry TJ Logistics Company Limited',
    symbol: '2608.TW',
  },
  {
    value: '2609.TW',
    label: '2609.TW,  Yang Ming Marine Transport Corporation',
    name: 'Yang Ming Marine Transport Corporation',
    symbol: '2609.TW',
  },
  {
    value: '2610.TW',
    label: '2610.TW,  China Airlines, Ltd.',
    name: 'China Airlines, Ltd.',
    symbol: '2610.TW',
  },
  {
    value: '2612.TW',
    label: '2612.TW,  Chinese Maritime Transport Ltd.',
    name: 'Chinese Maritime Transport Ltd.',
    symbol: '2612.TW',
  },
  {
    value: '2613.T',
    label: '2613.T,  J-Oil Mills, Inc.',
    name: 'J-Oil Mills, Inc.',
    symbol: '2613.T',
  },
  {
    value: '2614.TW',
    label: '2614.TW,  Eastern Media International Corporation',
    name: 'Eastern Media International Corporation',
    symbol: '2614.TW',
  },
  {
    value: '2615.TW',
    label: '2615.TW,  Wan Hai Lines Ltd.',
    name: 'Wan Hai Lines Ltd.',
    symbol: '2615.TW',
  },
  {
    value: '2617.TW',
    label: '2617.TW,  Taiwan Navigation Co., Ltd.',
    name: 'Taiwan Navigation Co., Ltd.',
    symbol: '2617.TW',
  },
  {
    value: '2618.TW',
    label: '2618.TW,  EVA Airways Corp.',
    name: 'EVA Airways Corp.',
    symbol: '2618.TW',
  },
  {
    value: '2633.TW',
    label: '2633.TW,  Taiwan High Speed Rail Corporation',
    name: 'Taiwan High Speed Rail Corporation',
    symbol: '2633.TW',
  },
  {
    value: '2636.TW',
    label: '2636.TW,  T3EX Global Holdings Corp.',
    name: 'T3EX Global Holdings Corp.',
    symbol: '2636.TW',
  },
  {
    value: '263750.KQ',
    label: '263750.KQ,  Pearl Abyss Corp.',
    name: 'Pearl Abyss Corp.',
    symbol: '263750.KQ',
  },
  {
    value: '2637.TW',
    label: '2637.TW,  Wisdom Marine Lines Co., Limited (Cayman)',
    name: 'Wisdom Marine Lines Co., Limited (Cayman)',
    symbol: '2637.TW',
  },
  {
    value: '2641.TWO',
    label: '2641.TWO,  Franbo Lines Corp.',
    name: 'Franbo Lines Corp.',
    symbol: '2641.TWO',
  },
  {
    value: '2651.T',
    label: '2651.T,  Lawson, Inc.',
    name: 'Lawson, Inc.',
    symbol: '2651.T',
  },
  {
    value: '265520.KQ',
    label: '265520.KQ,  Advanced Process Systems Corporation',
    name: 'Advanced Process Systems Corporation',
    symbol: '265520.KQ',
  },
  {
    value: '2659.T',
    label: '2659.T,  SAN-A CO.,LTD.',
    name: 'SAN-A CO.,LTD.',
    symbol: '2659.T',
  },
  {
    value: '2664.T',
    label: '2664.T,  Cawachi Limited',
    name: 'Cawachi Limited',
    symbol: '2664.T',
  },
  {
    value: '2670.T',
    label: '2670.T,  ABC-Mart,Inc.',
    name: 'ABC-Mart,Inc.',
    symbol: '2670.T',
  },
  {
    value: '267250.KS',
    label: '267250.KS,  HD Hyundai Co., Ltd.',
    name: 'HD Hyundai Co., Ltd.',
    symbol: '267250.KS',
  },
  {
    value: '267260.KS',
    label: '267260.KS,  Hyundai Electric & Energy Systems Co., Ltd.',
    name: 'Hyundai Electric & Energy Systems Co., Ltd.',
    symbol: '267260.KS',
  },
  {
    value: '267270.KS',
    label: '267270.KS,  Hyundai Construction Equipment Co., Ltd.',
    name: 'Hyundai Construction Equipment Co., Ltd.',
    symbol: '267270.KS',
  },
  {
    value: '2678.T',
    label: '2678.T,  ASKUL Corporation',
    name: 'ASKUL Corporation',
    symbol: '2678.T',
  },
  {
    value: '267980.KQ',
    label: '267980.KQ,  Maeil Dairies Co., Ltd.',
    name: 'Maeil Dairies Co., Ltd.',
    symbol: '267980.KQ',
  },
  {
    value: '2681.T',
    label: '2681.T,  Geo Holdings Corporation',
    name: 'Geo Holdings Corporation',
    symbol: '2681.T',
  },
  {
    value: '2685.T',
    label: '2685.T,  Adastria Co., Ltd.',
    name: 'Adastria Co., Ltd.',
    symbol: '2685.T',
  },
  {
    value: '268600.KQ',
    label: '268600.KQ,  Cellivery Therapeutics, Inc.',
    name: 'Cellivery Therapeutics, Inc.',
    symbol: '268600.KQ',
  },
  {
    value: '2692.T',
    label: '2692.T,  ITOCHU-SHOKUHIN Co., Ltd.',
    name: 'ITOCHU-SHOKUHIN Co., Ltd.',
    symbol: '2692.T',
  },
  {
    value: '2695.T',
    label: '2695.T,  Kura Sushi,Inc.',
    name: 'Kura Sushi,Inc.',
    symbol: '2695.T',
  },
  {
    value: '2698.T',
    label: '2698.T,  Can Do Co., Ltd.',
    name: 'Can Do Co., Ltd.',
    symbol: '2698.T',
  },
  {
    value: '2702.T',
    label: "2702.T,  McDonald's Holdings Company (Japan), Ltd.",
    name: "McDonald's Holdings Company (Japan), Ltd.",
    symbol: '2702.T',
  },
  {
    value: '2705.TW',
    label: '2705.TW,  Leofoo Development Co., Ltd.',
    name: 'Leofoo Development Co., Ltd.',
    symbol: '2705.TW',
  },
  {
    value: '2707.TW',
    label: '2707.TW,  Formosa International Hotels Corporation',
    name: 'Formosa International Hotels Corporation',
    symbol: '2707.TW',
  },
  {
    value: '271560.KS',
    label: '271560.KS,  ORION Corp.',
    name: 'ORION Corp.',
    symbol: '271560.KS',
  },
  {
    value: '2715.T',
    label: '2715.T,  Elematec Corporation',
    name: 'Elematec Corporation',
    symbol: '2715.T',
  },
  {
    value: '271980.KS',
    label: '271980.KS,  Jeil Pharmaceutical Co.,Ltd',
    name: 'Jeil Pharmaceutical Co.,Ltd',
    symbol: '271980.KS',
  },
  {
    value: '272210.KS',
    label: '272210.KS,  Hanwha Systems Co., Ltd.',
    name: 'Hanwha Systems Co., Ltd.',
    symbol: '272210.KS',
  },
  {
    value: '272290.KQ',
    label: '272290.KQ,  INNOX Advanced Materials Co.,Ltd.',
    name: 'INNOX Advanced Materials Co.,Ltd.',
    symbol: '272290.KQ',
  },
  {
    value: '2723.TW',
    label: '2723.TW,  Gourmet Master Co. Ltd.',
    name: 'Gourmet Master Co. Ltd.',
    symbol: '2723.TW',
  },
  {
    value: '272450.KS',
    label: '272450.KS,  Jin Air Co., Ltd.',
    name: 'Jin Air Co., Ltd.',
    symbol: '272450.KS',
  },
  {
    value: '272550.KS',
    label: '272550.KS,  Samyang Packaging Corporation',
    name: 'Samyang Packaging Corporation',
    symbol: '272550.KS',
  },
  {
    value: '2726.T',
    label: '2726.T,  PAL GROUP Holdings CO., LTD.',
    name: 'PAL GROUP Holdings CO., LTD.',
    symbol: '2726.T',
  },
  {
    value: '2727.TW',
    label: '2727.TW,  Wowprime Corp.',
    name: 'Wowprime Corp.',
    symbol: '2727.TW',
  },
  {
    value: '2729.TWO',
    label: '2729.TWO,  TTFB Company Limited',
    name: 'TTFB Company Limited',
    symbol: '2729.TWO',
  },
  {
    value: '3090.SR',
    label: '3090.SR,  Tabuk Cement Company',
    name: 'Tabuk Cement Company',
    symbol: '3090.SR',
  },
  {
    value: '2886.TW',
    label: '2886.TW,  Mega Financial Holding Co., Ltd.',
    name: 'Mega Financial Holding Co., Ltd.',
    symbol: '2886.TW',
  },
  {
    value: '2887.TW',
    label: '2887.TW,  Taishin Financial Holding Co., Ltd.',
    name: 'Taishin Financial Holding Co., Ltd.',
    symbol: '2887.TW',
  },
  {
    value: '2888.TW',
    label: '2888.TW,  Shin Kong Financial Holding Co., Ltd.',
    name: 'Shin Kong Financial Holding Co., Ltd.',
    symbol: '2888.TW',
  },
  {
    value: '2889.TW',
    label: '2889.TW,  IBF Financial Holdings Co., Ltd.',
    name: 'IBF Financial Holdings Co., Ltd.',
    symbol: '2889.TW',
  },
  {
    value: '2890.TW',
    label: '2890.TW,  SinoPac Financial Holdings Company Limited',
    name: 'SinoPac Financial Holdings Company Limited',
    symbol: '2890.TW',
  },
  {
    value: '2891.TW',
    label: '2891.TW,  CTBC Financial Holding Co., Ltd.',
    name: 'CTBC Financial Holding Co., Ltd.',
    symbol: '2891.TW',
  },
  {
    value: '2892.TW',
    label: '2892.TW,  First Financial Holding Co., Ltd.',
    name: 'First Financial Holding Co., Ltd.',
    symbol: '2892.TW',
  },
  {
    value: '2897.T',
    label: '2897.T,  Nissin Foods Holdings Co.,Ltd.',
    name: 'Nissin Foods Holdings Co.,Ltd.',
    symbol: '2897.T',
  },
  {
    value: '2897.TW',
    label: '2897.TW,  O-Bank Co., Ltd.',
    name: 'O-Bank Co., Ltd.',
    symbol: '2897.TW',
  },
  {
    value: '2903.TW',
    label: '2903.TW,  Far Eastern Department Stores, Ltd.',
    name: 'Far Eastern Department Stores, Ltd.',
    symbol: '2903.TW',
  },
  {
    value: '2905.TW',
    label: '2905.TW,  Mercuries & Associates Holding, Ltd.',
    name: 'Mercuries & Associates Holding, Ltd.',
    symbol: '2905.TW',
  },
  {
    value: '290650.KQ',
    label: '290650.KQ,  L&C Bio Co., Ltd',
    name: 'L&C Bio Co., Ltd',
    symbol: '290650.KQ',
  },
  {
    value: '2908.T',
    label: '2908.T,  Fujicco Co., Ltd.',
    name: 'Fujicco Co., Ltd.',
    symbol: '2908.T',
  },
  {
    value: '2908.TW',
    label: '2908.TW,  Test-Rite International Co., Ltd.',
    name: 'Test-Rite International Co., Ltd.',
    symbol: '2908.TW',
  },
  {
    value: '2910.T',
    label: '2910.T,  Rock Field Co.,Ltd.',
    name: 'Rock Field Co.,Ltd.',
    symbol: '2910.T',
  },
  {
    value: '2912.TW',
    label: '2912.TW,  President Chain Store Corporation',
    name: 'President Chain Store Corporation',
    symbol: '2912.TW',
  },
  {
    value: '2913.TW',
    label: '2913.TW,  Taiwan Tea Corporation',
    name: 'Taiwan Tea Corporation',
    symbol: '2913.TW',
  },
  {
    value: '2914.T',
    label: '2914.T,  Japan Tobacco Inc.',
    name: 'Japan Tobacco Inc.',
    symbol: '2914.T',
  },
  {
    value: '2915.T',
    label: '2915.T,  KENKO Mayonnaise Co.,Ltd.',
    name: 'KENKO Mayonnaise Co.,Ltd.',
    symbol: '2915.T',
  },
  {
    value: '2915.TW',
    label: '2915.TW,  Ruentex Industries Ltd.',
    name: 'Ruentex Industries Ltd.',
    symbol: '2915.TW',
  },
  {
    value: '2918.T',
    label: '2918.T,  Warabeya Nichiyo Holdings Co., Ltd.',
    name: 'Warabeya Nichiyo Holdings Co., Ltd.',
    symbol: '2918.T',
  },
  {
    value: '2927.T',
    label: '2927.T,  AFC-HD AMS Life Science Co., Ltd.',
    name: 'AFC-HD AMS Life Science Co., Ltd.',
    symbol: '2927.T',
  },
  {
    value: '2929.T',
    label: '2929.T,  Pharma Foods International Co., Ltd.',
    name: 'Pharma Foods International Co., Ltd.',
    symbol: '2929.T',
  },
  {
    value: '2930.T',
    label: '2930.T,  Kitanotatsujin Corporation',
    name: 'Kitanotatsujin Corporation',
    symbol: '2930.T',
  },
  {
    value: '2931.T',
    label: '2931.T,  Euglena Co., Ltd.',
    name: 'Euglena Co., Ltd.',
    symbol: '2931.T',
  },
  {
    value: '293490.KQ',
    label: '293490.KQ,  Kakao Games Corp.',
    name: 'Kakao Games Corp.',
    symbol: '293490.KQ',
  },
  {
    value: '293780.KQ',
    label: '293780.KQ,  AptaBio Therapeutics Inc.',
    name: 'AptaBio Therapeutics Inc.',
    symbol: '293780.KQ',
  },
  {
    value: '294090.KQ',
    label: '294090.KQ,  EOFLOW Co.,Ltd.',
    name: 'EOFLOW Co.,Ltd.',
    symbol: '294090.KQ',
  },
  {
    value: '294870.KS',
    label: '294870.KS,  HDC Hyundai Development Company',
    name: 'HDC Hyundai Development Company',
    symbol: '294870.KS',
  },
  {
    value: '2979.T',
    label: '2979.T,  SOSiLA Logistics REIT, Inc.',
    name: 'SOSiLA Logistics REIT, Inc.',
    symbol: '2979.T',
  },
  {
    value: '298000.KS',
    label: '298000.KS,  Hyosung Chemical Corporation',
    name: 'Hyosung Chemical Corporation',
    symbol: '298000.KS',
  },
  {
    value: '298020.KS',
    label: '298020.KS,  Hyosung TNC Corporation',
    name: 'Hyosung TNC Corporation',
    symbol: '298020.KS',
  },
  {
    value: '298040.KS',
    label: '298040.KS,  Hyosung Heavy Industries Corporation',
    name: 'Hyosung Heavy Industries Corporation',
    symbol: '298040.KS',
  },
  {
    value: '298050.KS',
    label: '298050.KS,  Hyosung Advanced Materials Corporation',
    name: 'Hyosung Advanced Materials Corporation',
    symbol: '298050.KS',
  },
  {
    value: '298380.KQ',
    label: '298380.KQ,  ABL Bio Inc.',
    name: 'ABL Bio Inc.',
    symbol: '298380.KQ',
  },
  {
    value: '298690.KS',
    label: '298690.KS,  Air Busan Co., Ltd.',
    name: 'Air Busan Co., Ltd.',
    symbol: '298690.KS',
  },
  {
    value: '299660.KQ',
    label: '299660.KQ,  Cellid, Co., Ltd.',
    name: 'Cellid, Co., Ltd.',
    symbol: '299660.KQ',
  },
  {
    value: '300001.SZ',
    label: '300001.SZ,  Qingdao TGOOD Electric Co., Ltd.',
    name: 'Qingdao TGOOD Electric Co., Ltd.',
    symbol: '300001.SZ',
  },
  {
    value: '300002.SZ',
    label: '300002.SZ,  Beijing Ultrapower Software Co., Ltd.',
    name: 'Beijing Ultrapower Software Co., Ltd.',
    symbol: '300002.SZ',
  },
  {
    value: '300003.SZ',
    label: '300003.SZ,  Lepu Medical Technology (Beijing) Co., Ltd.',
    name: 'Lepu Medical Technology (Beijing) Co., Ltd.',
    symbol: '300003.SZ',
  },
  {
    value: '300009.SZ',
    label: '300009.SZ,  Anhui Anke Biotechnology (Group) Co., Ltd.',
    name: 'Anhui Anke Biotechnology (Group) Co., Ltd.',
    symbol: '300009.SZ',
  },
  {
    value: '300012.SZ',
    label: '300012.SZ,  Centre Testing International Group Co. Ltd.',
    name: 'Centre Testing International Group Co. Ltd.',
    symbol: '300012.SZ',
  },
  {
    value: '300014.SZ',
    label: '300014.SZ,  EVE Energy Co., Ltd.',
    name: 'EVE Energy Co., Ltd.',
    symbol: '300014.SZ',
  },
  {
    value: '300015.SZ',
    label: '300015.SZ,  Aier Eye Hospital Group Co., Ltd.',
    name: 'Aier Eye Hospital Group Co., Ltd.',
    symbol: '300015.SZ',
  },
  {
    value: '300017.SZ',
    label: '300017.SZ,  Wangsu Science & Technology Co.,Ltd.',
    name: 'Wangsu Science & Technology Co.,Ltd.',
    symbol: '300017.SZ',
  },
  {
    value: '300019.SZ',
    label: '300019.SZ,  Chengdu Guibao Science & Technology Co.,Ltd.',
    name: 'Chengdu Guibao Science & Technology Co.,Ltd.',
    symbol: '300019.SZ',
  },
  {
    value: '300024.SZ',
    label: '300024.SZ,  Siasun Robot&Automation Co.,Ltd.',
    name: 'Siasun Robot&Automation Co.,Ltd.',
    symbol: '300024.SZ',
  },
  {
    value: '300033.SZ',
    label: '300033.SZ,  Hithink RoyalFlush Information Network Co., Ltd.',
    name: 'Hithink RoyalFlush Information Network Co., Ltd.',
    symbol: '300033.SZ',
  },
  {
    value: '300037.SZ',
    label: '300037.SZ,  Shenzhen Capchem Technology Co., Ltd.',
    name: 'Shenzhen Capchem Technology Co., Ltd.',
    symbol: '300037.SZ',
  },
  {
    value: '300058.SZ',
    label: '300058.SZ,  BlueFocus Intelligent Communications Group Co., Ltd.',
    name: 'BlueFocus Intelligent Communications Group Co., Ltd.',
    symbol: '300058.SZ',
  },
  {
    value: '300059.SZ',
    label: '300059.SZ,  East Money Information Co.,Ltd.',
    name: 'East Money Information Co.,Ltd.',
    symbol: '300059.SZ',
  },
  {
    value: '300070.SZ',
    label: '300070.SZ,  Beijing Originwater Technology Co., Ltd.',
    name: 'Beijing Originwater Technology Co., Ltd.',
    symbol: '300070.SZ',
  },
  {
    value: '300073.SZ',
    label: '300073.SZ,  Beijing Easpring Material Technology CO.,LTD.',
    name: 'Beijing Easpring Material Technology CO.,LTD.',
    symbol: '300073.SZ',
  },
  {
    value: '300085.SZ',
    label: '300085.SZ,  Shenzhen InfoGem Technologies Co., Ltd.',
    name: 'Shenzhen InfoGem Technologies Co., Ltd.',
    symbol: '300085.SZ',
  },
  {
    value: '300088.SZ',
    label: '300088.SZ,  Wuhu Token Sciences Co., Ltd.',
    name: 'Wuhu Token Sciences Co., Ltd.',
    symbol: '300088.SZ',
  },
  {
    value: '300092.SZ',
    label:
      '300092.SZ,  Sichuan Kexin Mechanical and Electrical Equipment Co.,Ltd',
    name: 'Sichuan Kexin Mechanical and Electrical Equipment Co.,Ltd',
    symbol: '300092.SZ',
  },
  {
    value: '300113.SZ',
    label: '300113.SZ,  Hangzhou Shunwang Technology Co,Ltd',
    name: 'Hangzhou Shunwang Technology Co,Ltd',
    symbol: '300113.SZ',
  },
  {
    value: '300115.SZ',
    label: '300115.SZ,  Shenzhen Everwin Precision Technology Co., Ltd.',
    name: 'Shenzhen Everwin Precision Technology Co., Ltd.',
    symbol: '300115.SZ',
  },
  {
    value: '300122.SZ',
    label: '300122.SZ,  Chongqing Zhifei Biological Products Co., Ltd.',
    name: 'Chongqing Zhifei Biological Products Co., Ltd.',
    symbol: '300122.SZ',
  },
  {
    value: '300124.SZ',
    label: '300124.SZ,  Shenzhen Inovance Technology Co.,Ltd',
    name: 'Shenzhen Inovance Technology Co.,Ltd',
    symbol: '300124.SZ',
  },
  {
    value: '300132.SZ',
    label: '300132.SZ,  Fujian Green Pine Co., Ltd.',
    name: 'Fujian Green Pine Co., Ltd.',
    symbol: '300132.SZ',
  },
  {
    value: '300133.SZ',
    label: '300133.SZ,  Zhejiang Huace Film & TV Co., Ltd.',
    name: 'Zhejiang Huace Film & TV Co., Ltd.',
    symbol: '300133.SZ',
  },
  {
    value: '300136.SZ',
    label: '300136.SZ,  Shenzhen Sunway Communication Co., Ltd.',
    name: 'Shenzhen Sunway Communication Co., Ltd.',
    symbol: '300136.SZ',
  },
  {
    value: '300142.SZ',
    label: '300142.SZ,  Walvax Biotechnology Co., Ltd.',
    name: 'Walvax Biotechnology Co., Ltd.',
    symbol: '300142.SZ',
  },
  {
    value: '300144.SZ',
    label: '300144.SZ,  Songcheng Performance Development Co.,Ltd',
    name: 'Songcheng Performance Development Co.,Ltd',
    symbol: '300144.SZ',
  },
  {
    value: '300146.SZ',
    label: '300146.SZ,  By-health Co., Ltd.',
    name: 'By-health Co., Ltd.',
    symbol: '300146.SZ',
  },
  {
    value: '300166.SZ',
    label:
      '300166.SZ,  Business-intelligence of Oriental Nations Corporation Ltd.',
    name: 'Business-intelligence of Oriental Nations Corporation Ltd.',
    symbol: '300166.SZ',
  },
  {
    value: '300168.SZ',
    label: '300168.SZ,  Wonders Information Co., Ltd',
    name: 'Wonders Information Co., Ltd',
    symbol: '300168.SZ',
  },
  {
    value: '300171.SZ',
    label: '300171.SZ,  Tofflon Science and Technology Group Co., Ltd.',
    name: 'Tofflon Science and Technology Group Co., Ltd.',
    symbol: '300171.SZ',
  },
  {
    value: '300188.SZ',
    label: '300188.SZ,  Xiamen Meiya Pico Information Co.,LTD.',
    name: 'Xiamen Meiya Pico Information Co.,LTD.',
    symbol: '300188.SZ',
  },
  {
    value: '3001.SR',
    label: '3001.SR,  Hail Cement Company',
    name: 'Hail Cement Company',
    symbol: '3001.SR',
  },
  {
    value: '3001.T',
    label: '3001.T,  Katakura Industries Co.,Ltd.',
    name: 'Katakura Industries Co.,Ltd.',
    symbol: '3001.T',
  },
  {
    value: '300206.SZ',
    label: '300206.SZ,  Edan Instruments, Inc.',
    name: 'Edan Instruments, Inc.',
    symbol: '300206.SZ',
  },
  {
    value: '300207.SZ',
    label: '300207.SZ,  Sunwoda Electronic Co.,Ltd',
    name: 'Sunwoda Electronic Co.,Ltd',
    symbol: '300207.SZ',
  },
  {
    value: '300212.SZ',
    label: '300212.SZ,  Beijing E-Hualu Information Technology Co., Ltd.',
    name: 'Beijing E-Hualu Information Technology Co., Ltd.',
    symbol: '300212.SZ',
  },
  {
    value: '300223.SZ',
    label: '300223.SZ,  Ingenic Semiconductor Co.,Ltd.',
    name: 'Ingenic Semiconductor Co.,Ltd.',
    symbol: '300223.SZ',
  },
  {
    value: '300244.SZ',
    label: '300244.SZ,  Dian Diagnostics Group Co.,Ltd.',
    name: 'Dian Diagnostics Group Co.,Ltd.',
    symbol: '300244.SZ',
  },
  {
    value: '300251.SZ',
    label: '300251.SZ,  Beijing Enlight Media Co., Ltd.',
    name: 'Beijing Enlight Media Co., Ltd.',
    symbol: '300251.SZ',
  },
  {
    value: '300253.SZ',
    label: '300253.SZ,  Winning Health Technology Group Co., Ltd.',
    name: 'Winning Health Technology Group Co., Ltd.',
    symbol: '300253.SZ',
  },
  {
    value: '300257.SZ',
    label: '300257.SZ,  Kaishan Group Co., Ltd.',
    name: 'Kaishan Group Co., Ltd.',
    symbol: '300257.SZ',
  },
  {
    value: '300259.SZ',
    label: '300259.SZ,  Suntront Technology Co., Ltd.',
    name: 'Suntront Technology Co., Ltd.',
    symbol: '300259.SZ',
  },
  {
    value: '300274.SZ',
    label: '300274.SZ,  Sungrow Power Supply Co., Ltd.',
    name: 'Sungrow Power Supply Co., Ltd.',
    symbol: '300274.SZ',
  },
  {
    value: '300296.SZ',
    label: '300296.SZ,  Leyard Optoelectronic Co., Ltd.',
    name: 'Leyard Optoelectronic Co., Ltd.',
    symbol: '300296.SZ',
  },
  {
    value: '3002.SR',
    label: '3002.SR,  Najran Cement Company',
    name: 'Najran Cement Company',
    symbol: '3002.SR',
  },
  {
    value: '3002.T',
    label: '3002.T,  Gunze Limited',
    name: 'Gunze Limited',
    symbol: '3002.T',
  },
  {
    value: '300308.SZ',
    label: '300308.SZ,  Zhongji Innolight Co., Ltd.',
    name: 'Zhongji Innolight Co., Ltd.',
    symbol: '300308.SZ',
  },
  {
    value: '300314.SZ',
    label: '300314.SZ,  Ningbo David Medical Device Co., Ltd.',
    name: 'Ningbo David Medical Device Co., Ltd.',
    symbol: '300314.SZ',
  },
  {
    value: '300316.SZ',
    label: '300316.SZ,  Zhejiang Jingsheng Mechanical & Electrical Co., Ltd.',
    name: 'Zhejiang Jingsheng Mechanical & Electrical Co., Ltd.',
    symbol: '300316.SZ',
  },
  {
    value: '300347.SZ',
    label: '300347.SZ,  Hangzhou Tigermed Consulting Co., Ltd.',
    name: 'Hangzhou Tigermed Consulting Co., Ltd.',
    symbol: '300347.SZ',
  },
  {
    value: '300357.SZ',
    label: '300357.SZ,  Zhejiang Wolwo Bio-Pharmaceutical Co., Ltd.',
    name: 'Zhejiang Wolwo Bio-Pharmaceutical Co., Ltd.',
    symbol: '300357.SZ',
  },
  {
    value: '300369.SZ',
    label: '300369.SZ,  NSFOCUS Technologies Group Co., Ltd.',
    name: 'NSFOCUS Technologies Group Co., Ltd.',
    symbol: '300369.SZ',
  },
  {
    value: '300373.SZ',
    label: '300373.SZ,  Yangzhou Yangjie Electronic Technology Co., Ltd.',
    name: 'Yangzhou Yangjie Electronic Technology Co., Ltd.',
    symbol: '300373.SZ',
  },
  {
    value: '300376.SZ',
    label: '300376.SZ,  East Group Co.,Ltd',
    name: 'East Group Co.,Ltd',
    symbol: '300376.SZ',
  },
  {
    value: '300383.SZ',
    label: '300383.SZ,  Beijing Sinnet Technology Co., Ltd',
    name: 'Beijing Sinnet Technology Co., Ltd',
    symbol: '300383.SZ',
  },
  {
    value: '3003.SR',
    label: '3003.SR,  City Cement Company',
    name: 'City Cement Company',
    symbol: '3003.SR',
  },
  {
    value: '3003.T',
    label: '3003.T,  Hulic Co., Ltd.',
    name: 'Hulic Co., Ltd.',
    symbol: '3003.T',
  },
  {
    value: '3003.TW',
    label: '3003.TW,  K.S. Terminals Inc.',
    name: 'K.S. Terminals Inc.',
    symbol: '3003.TW',
  },
  {
    value: '300408.SZ',
    label: '300408.SZ,  Chaozhou Three-Circle (Group) Co.,Ltd.',
    name: 'Chaozhou Three-Circle (Group) Co.,Ltd.',
    symbol: '300408.SZ',
  },
  {
    value: '300413.SZ',
    label: '300413.SZ,  Mango Excellent Media Co., Ltd.',
    name: 'Mango Excellent Media Co., Ltd.',
    symbol: '300413.SZ',
  },
  {
    value: '300418.SZ',
    label: '300418.SZ,  Kunlun Tech Co., Ltd.',
    name: 'Kunlun Tech Co., Ltd.',
    symbol: '300418.SZ',
  },
  {
    value: '300433.SZ',
    label: '300433.SZ,  Lens Technology Co., Ltd.',
    name: 'Lens Technology Co., Ltd.',
    symbol: '300433.SZ',
  },
  {
    value: '300435.SZ',
    label: '300435.SZ,  Hangzhou Zhongtai Cryogenic Technology Corporation',
    name: 'Hangzhou Zhongtai Cryogenic Technology Corporation',
    symbol: '300435.SZ',
  },
  {
    value: '300443.SZ',
    label: '300443.SZ,  Jinlei Technology Co., Ltd.',
    name: 'Jinlei Technology Co., Ltd.',
    symbol: '300443.SZ',
  },
  {
    value: '300450.SZ',
    label: '300450.SZ,  Wuxi Lead Intelligent Equipment CO.,LTD.',
    name: 'Wuxi Lead Intelligent Equipment CO.,LTD.',
    symbol: '300450.SZ',
  },
  {
    value: '300454.SZ',
    label: '300454.SZ,  Sangfor Technologies Inc.',
    name: 'Sangfor Technologies Inc.',
    symbol: '300454.SZ',
  },
  {
    value: '300457.SZ',
    label: '300457.SZ,  Shenzhen Yinghe Technology Co., Ltd',
    name: 'Shenzhen Yinghe Technology Co., Ltd',
    symbol: '300457.SZ',
  },
  {
    value: '300458.SZ',
    label: '300458.SZ,  Allwinnertech Technology Co.,Ltd.',
    name: 'Allwinnertech Technology Co.,Ltd.',
    symbol: '300458.SZ',
  },
  {
    value: '300463.SZ',
    label: '300463.SZ,  Maccura Biotechnology Co.Ltd',
    name: 'Maccura Biotechnology Co.Ltd',
    symbol: '300463.SZ',
  },
  {
    value: '300482.SZ',
    label: '300482.SZ,  Guangzhou Wondfo Biotech Co.,Ltd',
    name: 'Guangzhou Wondfo Biotech Co.,Ltd',
    symbol: '300482.SZ',
  },
  {
    value: '300496.SZ',
    label: '300496.SZ,  Thunder Software Technology Co.,Ltd.',
    name: 'Thunder Software Technology Co.,Ltd.',
    symbol: '300496.SZ',
  },
  {
    value: '300498.SZ',
    label: '300498.SZ,  Wens Foodstuff Group Co., Ltd.',
    name: 'Wens Foodstuff Group Co., Ltd.',
    symbol: '300498.SZ',
  },
  {
    value: '3004.SR',
    label: '3004.SR,  Northern Region Cement Company',
    name: 'Northern Region Cement Company',
    symbol: '3004.SR',
  },
  {
    value: '300502.SZ',
    label: '300502.SZ,  Eoptolink Technology Inc., Ltd.',
    name: 'Eoptolink Technology Inc., Ltd.',
    symbol: '300502.SZ',
  },
  {
    value: '300529.SZ',
    label: '300529.SZ,  Jafron Biomedical Co.,Ltd.',
    name: 'Jafron Biomedical Co.,Ltd.',
    symbol: '300529.SZ',
  },
  {
    value: '300552.SZ',
    label: '300552.SZ,  Vanjee Technology Co., Ltd.',
    name: 'Vanjee Technology Co., Ltd.',
    symbol: '300552.SZ',
  },
  {
    value: '300558.SZ',
    label: '300558.SZ,  Betta Pharmaceuticals Co., Ltd.',
    name: 'Betta Pharmaceuticals Co., Ltd.',
    symbol: '300558.SZ',
  },
  {
    value: '300573.SZ',
    label: '300573.SZ,  Shenyang Xingqi Pharmaceutical Co.,Ltd',
    name: 'Shenyang Xingqi Pharmaceutical Co.,Ltd',
    symbol: '300573.SZ',
  },
  {
    value: '300578.SZ',
    label: '300578.SZ,  BizConf Telecom Co.,Ltd.',
    name: 'BizConf Telecom Co.,Ltd.',
    symbol: '300578.SZ',
  },
  {
    value: '300595.SZ',
    label: '300595.SZ,  Autek China Inc.',
    name: 'Autek China Inc.',
    symbol: '300595.SZ',
  },
  {
    value: '3005.SR',
    label: '3005.SR,  Umm Al-Qura Cement Company',
    name: 'Umm Al-Qura Cement Company',
    symbol: '3005.SR',
  },
  {
    value: '3005.TW',
    label: '3005.TW,  Getac Holdings Corporation',
    name: 'Getac Holdings Corporation',
    symbol: '3005.TW',
  },
  {
    value: '300601.SZ',
    label: '300601.SZ,  Shenzhen Kangtai Biological Products Co., Ltd.',
    name: 'Shenzhen Kangtai Biological Products Co., Ltd.',
    symbol: '300601.SZ',
  },
  {
    value: '300628.SZ',
    label: '300628.SZ,  Yealink Network Technology Co., Ltd.',
    name: 'Yealink Network Technology Co., Ltd.',
    symbol: '300628.SZ',
  },
  {
    value: '300639.SZ',
    label: '300639.SZ,  Guangdong Hybribio Biotech Co.,Ltd.',
    name: 'Guangdong Hybribio Biotech Co.,Ltd.',
    symbol: '300639.SZ',
  },
  {
    value: '300661.SZ',
    label: '300661.SZ,  SG Micro Corp',
    name: 'SG Micro Corp',
    symbol: '300661.SZ',
  },
  {
    value: '300676.SZ',
    label: '300676.SZ,  BGI Genomics Co., Ltd.',
    name: 'BGI Genomics Co., Ltd.',
    symbol: '300676.SZ',
  },
  {
    value: '300677.SZ',
    label: '300677.SZ,  Intco Medical Technology Co., Ltd.',
    name: 'Intco Medical Technology Co., Ltd.',
    symbol: '300677.SZ',
  },
  {
    value: '300699.SZ',
    label: '300699.SZ,  Weihai Guangwei Composites Co., Ltd.',
    name: 'Weihai Guangwei Composites Co., Ltd.',
    symbol: '300699.SZ',
  },
  {
    value: '3006.TW',
    label: '3006.TW,  Elite Semiconductor Microelectronics Tech Inc',
    name: 'Elite Semiconductor Microelectronics Tech Inc',
    symbol: '3006.TW',
  },
  {
    value: '300720.KS',
    label: '300720.KS,  Hanil Cement Co., Ltd.',
    name: 'Hanil Cement Co., Ltd.',
    symbol: '300720.KS',
  },
  {
    value: '300724.SZ',
    label: '300724.SZ,  Shenzhen S.C New Energy Technology Corporation',
    name: 'Shenzhen S.C New Energy Technology Corporation',
    symbol: '300724.SZ',
  },
  {
    value: '300725.SZ',
    label: '300725.SZ,  PharmaBlock Sciences (Nanjing), Inc.',
    name: 'PharmaBlock Sciences (Nanjing), Inc.',
    symbol: '300725.SZ',
  },
  {
    value: '300726.SZ',
    label: '300726.SZ,  Zhuzhou Hongda Electronics Corp.,Ltd.',
    name: 'Zhuzhou Hongda Electronics Corp.,Ltd.',
    symbol: '300726.SZ',
  },
  {
    value: '300741.SZ',
    label: '300741.SZ,  Huabao Flavours & Fragrances Co., Ltd.',
    name: 'Huabao Flavours & Fragrances Co., Ltd.',
    symbol: '300741.SZ',
  },
  {
    value: '300750.SZ',
    label: '300750.SZ,  Contemporary Amperex Technology Co., Limited',
    name: 'Contemporary Amperex Technology Co., Limited',
    symbol: '300750.SZ',
  },
  {
    value: '300751.SZ',
    label: '300751.SZ,  Suzhou Maxwell Technologies Co., Ltd.',
    name: 'Suzhou Maxwell Technologies Co., Ltd.',
    symbol: '300751.SZ',
  },
  {
    value: '300759.SZ',
    label: '300759.SZ,  Pharmaron Beijing Co., Ltd.',
    name: 'Pharmaron Beijing Co., Ltd.',
    symbol: '300759.SZ',
  },
  {
    value: '300760.SZ',
    label: '300760.SZ,  Shenzhen Mindray Bio-Medical Electronics Co., Ltd.',
    name: 'Shenzhen Mindray Bio-Medical Electronics Co., Ltd.',
    symbol: '300760.SZ',
  },
  {
    value: '300761.SZ',
    label: '300761.SZ,  Jiangsu Lihua Animal Husbandry Co., Ltd.',
    name: 'Jiangsu Lihua Animal Husbandry Co., Ltd.',
    symbol: '300761.SZ',
  },
  {
    value: '300773.SZ',
    label: '300773.SZ,  Lakala Payment Co., Ltd.',
    name: 'Lakala Payment Co., Ltd.',
    symbol: '300773.SZ',
  },
  {
    value: '300782.SZ',
    label: '300782.SZ,  Maxscend Microelectronics Company Limited',
    name: 'Maxscend Microelectronics Company Limited',
    symbol: '300782.SZ',
  },
  {
    value: '300832.SZ',
    label:
      '300832.SZ,  Shenzhen New Industries Biomedical Engineering Co., Ltd.',
    name: 'Shenzhen New Industries Biomedical Engineering Co., Ltd.',
    symbol: '300832.SZ',
  },
  {
    value: '300866.SZ',
    label: '300866.SZ,  Anker Innovations Limited',
    name: 'Anker Innovations Limited',
    symbol: '300866.SZ',
  },
  {
    value: '300888.SZ',
    label: '300888.SZ,  Winner Medical Co., Ltd.',
    name: 'Winner Medical Co., Ltd.',
    symbol: '300888.SZ',
  },
  {
    value: '300896.SZ',
    label: '300896.SZ,  Imeik Technology Development Co.,Ltd.',
    name: 'Imeik Technology Development Co.,Ltd.',
    symbol: '300896.SZ',
  },
  {
    value: '3008.TW',
    label: '3008.TW,  LARGAN Precision Co.,Ltd',
    name: 'LARGAN Precision Co.,Ltd',
    symbol: '3008.TW',
  },
  {
    value: '300999.SZ',
    label: '300999.SZ,  Yihai Kerry Arawana Holdings Co., Ltd',
    name: 'Yihai Kerry Arawana Holdings Co., Ltd',
    symbol: '300999.SZ',
  },
  {
    value: '3010.SR',
    label: '3010.SR,  Arabian Cement Company',
    name: 'Arabian Cement Company',
    symbol: '3010.SR',
  },
  {
    value: '3010.TW',
    label: '3010.TW,  Wah Lee Industrial Corporation',
    name: 'Wah Lee Industrial Corporation',
    symbol: '3010.TW',
  },
  {
    value: '3014.TW',
    label: '3014.TW,  ITE Tech. Inc',
    name: 'ITE Tech. Inc',
    symbol: '3014.TW',
  },
  {
    value: '3015.TW',
    label: '3015.TW,  FSP Technology Inc.',
    name: 'FSP Technology Inc.',
    symbol: '3015.TW',
  },
  {
    value: '3016.TW',
    label: '3016.TW,  Episil-Precision Inc.',
    name: 'Episil-Precision Inc.',
    symbol: '3016.TW',
  },
  {
    value: '3017.TW',
    label: '3017.TW,  Asia Vital Components Co., Ltd.',
    name: 'Asia Vital Components Co., Ltd.',
    symbol: '3017.TW',
  },
  {
    value: '3019.TW',
    label: '3019.TW,  Asia Optical Co., Inc.',
    name: 'Asia Optical Co., Inc.',
    symbol: '3019.TW',
  },
  {
    value: '3020.SR',
    label: '3020.SR,  Yamama Saudi Cement Company',
    name: 'Yamama Saudi Cement Company',
    symbol: '3020.SR',
  },
  {
    value: '3022.TW',
    label: '3022.TW,  IEI Integration Corp.',
    name: 'IEI Integration Corp.',
    symbol: '3022.TW',
  },
  {
    value: '3023.TW',
    label: '3023.TW,  SINBON Electronics Co., Ltd.',
    name: 'SINBON Electronics Co., Ltd.',
    symbol: '3023.TW',
  },
  {
    value: '302440.KS',
    label: '302440.KS,  SK bioscience Co.,Ltd.',
    name: 'SK bioscience Co.,Ltd.',
    symbol: '302440.KS',
  },
  {
    value: '3026.TW',
    label: '3026.TW,  Holy Stone Enterprise Co.,Ltd.',
    name: 'Holy Stone Enterprise Co.,Ltd.',
    symbol: '3026.TW',
  },
  {
    value: '3028.T',
    label: '3028.T,  Alpen Co.,Ltd.',
    name: 'Alpen Co.,Ltd.',
    symbol: '3028.T',
  },
  {
    value: '3028.TW',
    label: '3028.TW,  Zenitron Corporation',
    name: 'Zenitron Corporation',
    symbol: '3028.TW',
  },
  {
    value: '3029.TW',
    label: '3029.TW,  Zero One Technology Co., Ltd.',
    name: 'Zero One Technology Co., Ltd.',
    symbol: '3029.TW',
  },
  {
    value: '3030.SR',
    label: '3030.SR,  Saudi Cement Company',
    name: 'Saudi Cement Company',
    symbol: '3030.SR',
  },
  {
    value: '3030.TW',
    label: '3030.TW,  Test Research, Inc.',
    name: 'Test Research, Inc.',
    symbol: '3030.TW',
  },
  {
    value: '3031.T',
    label: '3031.T,  RACCOON HOLDINGS, Inc.',
    name: 'RACCOON HOLDINGS, Inc.',
    symbol: '3031.T',
  },
  {
    value: '3032.TW',
    label: '3032.TW,  Compucase Enterprise Co., Ltd.',
    name: 'Compucase Enterprise Co., Ltd.',
    symbol: '3032.TW',
  },
  {
    value: '3033.TW',
    label: '3033.TW,  Weikeng Industrial Co., Ltd.',
    name: 'Weikeng Industrial Co., Ltd.',
    symbol: '3033.TW',
  },
  {
    value: '3034.T',
    label: '3034.T,  Qol Holdings Co., Ltd.',
    name: 'Qol Holdings Co., Ltd.',
    symbol: '3034.T',
  },
  {
    value: '3034.TW',
    label: '3034.TW,  Novatek Microelectronics Corp.',
    name: 'Novatek Microelectronics Corp.',
    symbol: '3034.TW',
  },
  {
    value: '3035.TW',
    label: '3035.TW,  Faraday Technology Corporation',
    name: 'Faraday Technology Corporation',
    symbol: '3035.TW',
  },
  {
    value: '3036.T',
    label: '3036.T,  ALCONIX Corporation',
    name: 'ALCONIX Corporation',
    symbol: '3036.T',
  },
  {
    value: '3036.TW',
    label: '3036.TW,  WT Microelectronics Co., Ltd.',
    name: 'WT Microelectronics Co., Ltd.',
    symbol: '3036.TW',
  },
  {
    value: '3037.TW',
    label: '3037.TW,  Unimicron Technology Corp.',
    name: 'Unimicron Technology Corp.',
    symbol: '3037.TW',
  },
  {
    value: '3038.T',
    label: '3038.T,  Kobe Bussan Co., Ltd.',
    name: 'Kobe Bussan Co., Ltd.',
    symbol: '3038.T',
  },
  {
    value: '3040.SR',
    label: '3040.SR,  Qassim Cement Company',
    name: 'Qassim Cement Company',
    symbol: '3040.SR',
  },
  {
    value: '3040.T',
    label: '3040.T,  Soliton Systems K.K.',
    name: 'Soliton Systems K.K.',
    symbol: '3040.T',
  },
  {
    value: '3041.TW',
    label: '3041.TW,  ALi Corporation',
    name: 'ALi Corporation',
    symbol: '3041.TW',
  },
  {
    value: '3042.TW',
    label: '3042.TW,  TXC Corporation',
    name: 'TXC Corporation',
    symbol: '3042.TW',
  },
  {
    value: '3044.TW',
    label: '3044.TW,  Tripod Technology Corporation',
    name: 'Tripod Technology Corporation',
    symbol: '3044.TW',
  },
  {
    value: '3045.TW',
    label: '3045.TW,  Taiwan Mobile Co., Ltd.',
    name: 'Taiwan Mobile Co., Ltd.',
    symbol: '3045.TW',
  },
  {
    value: '3046.T',
    label: '3046.T,  JINS HOLDINGS Inc.',
    name: 'JINS HOLDINGS Inc.',
    symbol: '3046.T',
  },
  {
    value: '3048.T',
    label: '3048.T,  Bic Camera Inc.',
    name: 'Bic Camera Inc.',
    symbol: '3048.T',
  },
  {
    value: '3049.TW',
    label: '3049.TW,  HannsTouch Solution Incorporated',
    name: 'HannsTouch Solution Incorporated',
    symbol: '3049.TW',
  },
  {
    value: '3050.SR',
    label: '3050.SR,  Southern Province Cement Company',
    name: 'Southern Province Cement Company',
    symbol: '3050.SR',
  },
  {
    value: '3050.T',
    label: '3050.T,  DCM Holdings Co., Ltd.',
    name: 'DCM Holdings Co., Ltd.',
    symbol: '3050.T',
  },
  {
    value: '3051.TW',
    label: '3051.TW,  Optimax Technology Corporation',
    name: 'Optimax Technology Corporation',
    symbol: '3051.TW',
  },
  {
    value: '3056.TW',
    label: '3056.TW,  ZongTai Real Estate Development Co., Ltd.',
    name: 'ZongTai Real Estate Development Co., Ltd.',
    symbol: '3056.TW',
  },
  {
    value: '3059.TW',
    label: '3059.TW,  Altek Corporation',
    name: 'Altek Corporation',
    symbol: '3059.TW',
  },
  {
    value: '3060.SR',
    label: '3060.SR,  Yanbu Cement Company',
    name: 'Yanbu Cement Company',
    symbol: '3060.SR',
  },
  {
    value: '3060.TW',
    label: '3060.TW,  Min Aik Technology Co., Ltd.',
    name: 'Min Aik Technology Co., Ltd.',
    symbol: '3060.TW',
  },
  {
    value: '306200.KS',
    label: '306200.KS,  SeAH Steel Corporation',
    name: 'SeAH Steel Corporation',
    symbol: '306200.KS',
  },
  {
    value: '3062.TW',
    label: '3062.TW,  CyberTAN Technology Inc.',
    name: 'CyberTAN Technology Inc.',
    symbol: '3062.TW',
  },
  {
    value: '3064.T',
    label: '3064.T,  MonotaRO Co., Ltd.',
    name: 'MonotaRO Co., Ltd.',
    symbol: '3064.T',
  },
  {
    value: '3073.T',
    label: '3073.T,  DD Holdings Co.,Ltd.',
    name: 'DD Holdings Co.,Ltd.',
    symbol: '3073.T',
  },
  {
    value: '3076.T',
    label: '3076.T,  Ai Holdings Corporation',
    name: 'Ai Holdings Corporation',
    symbol: '3076.T',
  },
  {
    value: '3078.TWO',
    label: '3078.TWO,  Channel Well Technology Co.,Ltd.',
    name: 'Channel Well Technology Co.,Ltd.',
    symbol: '3078.TWO',
  },
  {
    value: '307950.KS',
    label: '307950.KS,  Hyundai Autoever Corporation',
    name: 'Hyundai Autoever Corporation',
    symbol: '307950.KS',
  },
  {
    value: '3080.SR',
    label: '3080.SR,  Eastern Province Cement Company',
    name: 'Eastern Province Cement Company',
    symbol: '3080.SR',
  },
  {
    value: '3081.TWO',
    label: '3081.TWO,  LandMark Optoelectronics Corporation',
    name: 'LandMark Optoelectronics Corporation',
    symbol: '3081.TWO',
  },
  {
    value: '3085.T',
    label: '3085.T,  Arcland Service Holdings Co., Ltd.',
    name: 'Arcland Service Holdings Co., Ltd.',
    symbol: '3085.T',
  },
  {
    value: '3086.T',
    label: '3086.T,  J. Front Retailing Co., Ltd.',
    name: 'J. Front Retailing Co., Ltd.',
    symbol: '3086.T',
  },
  {
    value: '3087.T',
    label: '3087.T,  DOUTOR NICHIRES Holdings Co., Ltd.',
    name: 'DOUTOR NICHIRES Holdings Co., Ltd.',
    symbol: '3087.T',
  },
  {
    value: '3088.T',
    label: '3088.T,  MatsukiyoCocokara & Co.',
    name: 'MatsukiyoCocokara & Co.',
    symbol: '3088.T',
  },
  {
    value: '3090.TW',
    label: '3090.TW,  Nichidenbo Corporation',
    name: 'Nichidenbo Corporation',
    symbol: '3090.TW',
  },
  {
    value: '3091.SR',
    label: '3091.SR,  Al Jouf Cement Company',
    name: 'Al Jouf Cement Company',
    symbol: '3091.SR',
  },
  {
    value: '3091.T',
    label: '3091.T,  BRONCO BILLY Co., LTD.',
    name: 'BRONCO BILLY Co., LTD.',
    symbol: '3091.T',
  },
  {
    value: '3092.T',
    label: '3092.T,  ZOZO, Inc.',
    name: 'ZOZO, Inc.',
    symbol: '3092.T',
  },
  {
    value: '3092.TW',
    label: '3092.TW,  Hotron Precision Electronic Industrial Co.,Ltd.',
    name: 'Hotron Precision Electronic Industrial Co.,Ltd.',
    symbol: '3092.TW',
  },
  {
    value: '3097.T',
    label: '3097.T,  The Monogatari Corporation',
    name: 'The Monogatari Corporation',
    symbol: '3097.T',
  },
  {
    value: '3132.T',
    label: '3132.T,  Macnica Holdings, Inc.',
    name: 'Macnica Holdings, Inc.',
    symbol: '3132.T',
  },
  {
    value: 'ISO',
    label: 'ISO,  IsoPlexis Corporation',
    name: 'IsoPlexis Corporation',
    symbol: 'ISO',
  },
  {
    value: 'SNCE',
    label: 'SNCE,  Science 37 Holdings, Inc.',
    name: 'Science 37 Holdings, Inc.',
    symbol: 'SNCE',
  },
  {
    value: '3856.T',
    label: '3856.T,  Abalance Corporation',
    name: 'Abalance Corporation',
    symbol: '3856.T',
  },
  {
    value: '3857.T',
    label: '3857.T,  LAC Co., Ltd.',
    name: 'LAC Co., Ltd.',
    symbol: '3857.T',
  },
  {
    value: '3861.T',
    label: '3861.T,  Oji Holdings Corporation',
    name: 'Oji Holdings Corporation',
    symbol: '3861.T',
  },
  {
    value: '3863.T',
    label: '3863.T,  Nippon Paper Industries Co., Ltd.',
    name: 'Nippon Paper Industries Co., Ltd.',
    symbol: '3863.T',
  },
  {
    value: '3864.T',
    label: '3864.T,  Mitsubishi Paper Mills Limited',
    name: 'Mitsubishi Paper Mills Limited',
    symbol: '3864.T',
  },
  {
    value: '3865.T',
    label: '3865.T,  Hokuetsu Corporation',
    name: 'Hokuetsu Corporation',
    symbol: '3865.T',
  },
  {
    value: '3880.T',
    label: '3880.T,  Daio Paper Corporation',
    name: 'Daio Paper Corporation',
    symbol: '3880.T',
  },
  {
    value: '3886.HK',
    label: '3886.HK,  Town Health International Medical Group Limited',
    name: 'Town Health International Medical Group Limited',
    symbol: '3886.HK',
  },
  {
    value: '3891.T',
    label: '3891.T,  Nippon Kodoshi Corporation',
    name: 'Nippon Kodoshi Corporation',
    symbol: '3891.T',
  },
  {
    value: '3900.T',
    label: '3900.T,  CrowdWorks Inc.',
    name: 'CrowdWorks Inc.',
    symbol: '3900.T',
  },
  {
    value: '3901.T',
    label: '3901.T,  MarkLines Co., Ltd.',
    name: 'MarkLines Co., Ltd.',
    symbol: '3901.T',
  },
  {
    value: '3902.T',
    label: '3902.T,  Medical Data Vision Co., Ltd.',
    name: 'Medical Data Vision Co., Ltd.',
    symbol: '3902.T',
  },
  {
    value: '3903.T',
    label: '3903.T,  gumi Inc.',
    name: 'gumi Inc.',
    symbol: '3903.T',
  },
  {
    value: '3906.T',
    label: '3906.T,  ALBERT Inc.',
    name: 'ALBERT Inc.',
    symbol: '3906.T',
  },
  {
    value: '3911.T',
    label: '3911.T,  Aiming Inc.',
    name: 'Aiming Inc.',
    symbol: '3911.T',
  },
  {
    value: '3915.T',
    label: '3915.T,  TerraSky Co.,Ltd.',
    name: 'TerraSky Co.,Ltd.',
    symbol: '3915.T',
  },
  {
    value: '3916.T',
    label: '3916.T,  Digital Information Technologies Corporation',
    name: 'Digital Information Technologies Corporation',
    symbol: '3916.T',
  },
  {
    value: '3922.T',
    label: '3922.T,  PR TIMES, Inc.',
    name: 'PR TIMES, Inc.',
    symbol: '3922.T',
  },
  {
    value: '3923.T',
    label: '3923.T,  Rakus Co., Ltd.',
    name: 'Rakus Co., Ltd.',
    symbol: '3923.T',
  },
  {
    value: '3926.T',
    label: '3926.T,  OpenDoor Inc.',
    name: 'OpenDoor Inc.',
    symbol: '3926.T',
  },
  {
    value: '3932.T',
    label: '3932.T,  Akatsuki Inc.',
    name: 'Akatsuki Inc.',
    symbol: '3932.T',
  },
  {
    value: '3941.T',
    label: '3941.T,  Rengo Co., Ltd.',
    name: 'Rengo Co., Ltd.',
    symbol: '3941.T',
  },
  {
    value: '3946.T',
    label: '3946.T,  Tomoku Co., Ltd.',
    name: 'Tomoku Co., Ltd.',
    symbol: '3946.T',
  },
  {
    value: '3950.T',
    label: '3950.T,  The Pack Corporation',
    name: 'The Pack Corporation',
    symbol: '3950.T',
  },
  {
    value: '3962.T',
    label: '3962.T,  Change Inc.',
    name: 'Change Inc.',
    symbol: '3962.T',
  },
  {
    value: '3966.T',
    label: '3966.T,  Uzabase, Inc.',
    name: 'Uzabase, Inc.',
    symbol: '3966.T',
  },
  {
    value: '3978.T',
    label: '3978.T,  Macromill, Inc.',
    name: 'Macromill, Inc.',
    symbol: '3978.T',
  },
  {
    value: '3983.T',
    label: '3983.T,  oRo Co., Ltd.',
    name: 'oRo Co., Ltd.',
    symbol: '3983.T',
  },
  {
    value: '3993.T',
    label: '3993.T,  PKSHA Technology Inc.',
    name: 'PKSHA Technology Inc.',
    symbol: '3993.T',
  },
  {
    value: '3994.T',
    label: '3994.T,  Money Forward, Inc.',
    name: 'Money Forward, Inc.',
    symbol: '3994.T',
  },
  {
    value: '3998.T',
    label: '3998.T,  SuRaLa Net Co.,Ltd.',
    name: 'SuRaLa Net Co.,Ltd.',
    symbol: '3998.T',
  },
  {
    value: '4001.SR',
    label: '4001.SR,  Abdullah Al-Othaim Markets Company',
    name: 'Abdullah Al-Othaim Markets Company',
    symbol: '4001.SR',
  },
  {
    value: '4002.SR',
    label: '4002.SR,  Mouwasat Medical Services Company',
    name: 'Mouwasat Medical Services Company',
    symbol: '4002.SR',
  },
  {
    value: '4003.SR',
    label: '4003.SR,  United Electronics Company',
    name: 'United Electronics Company',
    symbol: '4003.SR',
  },
  {
    value: '4004.SR',
    label: '4004.SR,  Dallah Healthcare Company',
    name: 'Dallah Healthcare Company',
    symbol: '4004.SR',
  },
  {
    value: '4004.T',
    label: '4004.T,  Showa Denko K.K.',
    name: 'Showa Denko K.K.',
    symbol: '4004.T',
  },
  {
    value: '4005.SR',
    label: '4005.SR,  National Medical Care Company',
    name: 'National Medical Care Company',
    symbol: '4005.SR',
  },
  {
    value: '4005.T',
    label: '4005.T,  Sumitomo Chemical Company, Limited',
    name: 'Sumitomo Chemical Company, Limited',
    symbol: '4005.T',
  },
  {
    value: '4007.SR',
    label: '4007.SR,  Al Hammadi Holding Company',
    name: 'Al Hammadi Holding Company',
    symbol: '4007.SR',
  },
  {
    value: '4008.SR',
    label: '4008.SR,  Saudi Company for Hardware',
    name: 'Saudi Company for Hardware',
    symbol: '4008.SR',
  },
  {
    value: '4008.T',
    label: '4008.T,  Sumitomo Seika Chemicals Company, Limited.',
    name: 'Sumitomo Seika Chemicals Company, Limited.',
    symbol: '4008.T',
  },
  {
    value: '4009.SR',
    label: '4009.SR,  Middle East Healthcare Company',
    name: 'Middle East Healthcare Company',
    symbol: '4009.SR',
  },
  {
    value: '4010.SR',
    label: '4010.SR,  Dur Hospitality Company',
    name: 'Dur Hospitality Company',
    symbol: '4010.SR',
  },
  {
    value: '4011.SR',
    label: "4011.SR,  L'azurde Company for Jewelry",
    name: "L'azurde Company for Jewelry",
    symbol: '4011.SR',
  },
  {
    value: '4012.SR',
    label: '4012.SR,  Thob Al Aseel Company',
    name: 'Thob Al Aseel Company',
    symbol: '4012.SR',
  },
  {
    value: '4013.SR',
    label: '4013.SR,  Dr. Sulaiman Al Habib Medical Services Group Company',
    name: 'Dr. Sulaiman Al Habib Medical Services Group Company',
    symbol: '4013.SR',
  },
  {
    value: '4020.SR',
    label: '4020.SR,  Saudi Real Estate Company',
    name: 'Saudi Real Estate Company',
    symbol: '4020.SR',
  },
  {
    value: '4021.T',
    label: '4021.T,  Nissan Chemical Corporation',
    name: 'Nissan Chemical Corporation',
    symbol: '4021.T',
  },
  {
    value: '4022.T',
    label: '4022.T,  Rasa Industries, Ltd.',
    name: 'Rasa Industries, Ltd.',
    symbol: '4022.T',
  },
  {
    value: '4023.T',
    label: '4023.T,  Kureha Corporation',
    name: 'Kureha Corporation',
    symbol: '4023.T',
  },
  {
    value: '4025.T',
    label: '4025.T,  Taki Chemical Co., Ltd.',
    name: 'Taki Chemical Co., Ltd.',
    symbol: '4025.T',
  },
  {
    value: '4026.T',
    label: '4026.T,  Konoshima Chemical Co.,Ltd.',
    name: 'Konoshima Chemical Co.,Ltd.',
    symbol: '4026.T',
  },
  {
    value: '4027.T',
    label: '4027.T,  Tayca Corporation',
    name: 'Tayca Corporation',
    symbol: '4027.T',
  },
  {
    value: '4028.T',
    label: '4028.T,  Ishihara Sangyo Kaisha,Ltd.',
    name: 'Ishihara Sangyo Kaisha,Ltd.',
    symbol: '4028.T',
  },
  {
    value: '4031.SR',
    label: '4031.SR,  Saudi Ground Services Company',
    name: 'Saudi Ground Services Company',
    symbol: '4031.SR',
  },
  {
    value: '4040.SR',
    label: '4040.SR,  Saudi Public Transport Company',
    name: 'Saudi Public Transport Company',
    symbol: '4040.SR',
  },
  {
    value: '4041.T',
    label: '4041.T,  Nippon Soda Co., Ltd.',
    name: 'Nippon Soda Co., Ltd.',
    symbol: '4041.T',
  },
  {
    value: '4042.T',
    label: '4042.T,  Tosoh Corporation',
    name: 'Tosoh Corporation',
    symbol: '4042.T',
  },
  {
    value: '4043.T',
    label: '4043.T,  Tokuyama Corporation',
    name: 'Tokuyama Corporation',
    symbol: '4043.T',
  },
  {
    value: '4044.T',
    label: '4044.T,  Central Glass Co., Ltd.',
    name: 'Central Glass Co., Ltd.',
    symbol: '4044.T',
  },
  {
    value: '4045.T',
    label: '4045.T,  Toagosei Co., Ltd.',
    name: 'Toagosei Co., Ltd.',
    symbol: '4045.T',
  },
  {
    value: '4046.T',
    label: '4046.T,  Osaka Soda Co., Ltd.',
    name: 'Osaka Soda Co., Ltd.',
    symbol: '4046.T',
  },
  {
    value: '4047.T',
    label: '4047.T,  Kanto Denka Kogyo Co., Ltd.',
    name: 'Kanto Denka Kogyo Co., Ltd.',
    symbol: '4047.T',
  },
  {
    value: '4050.SR',
    label: '4050.SR,  Saudi Automotive Services Company',
    name: 'Saudi Automotive Services Company',
    symbol: '4050.SR',
  },
  {
    value: '4051.SR',
    label: '4051.SR,  Baazeem Trading Company',
    name: 'Baazeem Trading Company',
    symbol: '4051.SR',
  },
  {
    value: '4056.T',
    label: '4056.T,  Neural Pocket Inc.',
    name: 'Neural Pocket Inc.',
    symbol: '4056.T',
  },
  {
    value: '4061.T',
    label: '4061.T,  Denka Company Limited',
    name: 'Denka Company Limited',
    symbol: '4061.T',
  },
  {
    value: '4062.T',
    label: '4062.T,  Ibiden Co.,Ltd.',
    name: 'Ibiden Co.,Ltd.',
    symbol: '4062.T',
  },
  {
    value: '4063.T',
    label: '4063.T,  Shin-Etsu Chemical Co., Ltd.',
    name: 'Shin-Etsu Chemical Co., Ltd.',
    symbol: '4063.T',
  },
  {
    value: '4064.T',
    label: '4064.T,  Nippon Carbide Industries Co., Inc.',
    name: 'Nippon Carbide Industries Co., Inc.',
    symbol: '4064.T',
  },
  {
    value: '4070.SR',
    label: '4070.SR,  Tihama Advertising and Public Relations Co.',
    name: 'Tihama Advertising and Public Relations Co.',
    symbol: '4070.SR',
  },
  {
    value: '4078.T',
    label: '4078.T,  Sakai Chemical Industry Co., Ltd.',
    name: 'Sakai Chemical Industry Co., Ltd.',
    symbol: '4078.T',
  },
  {
    value: '4080.SR',
    label: '4080.SR,  Sinad Holding Company',
    name: 'Sinad Holding Company',
    symbol: '4080.SR',
  },
  {
    value: '4082.T',
    label: '4082.T,  Daiichi Kigenso Kagaku Kogyo Co., Ltd.',
    name: 'Daiichi Kigenso Kagaku Kogyo Co., Ltd.',
    symbol: '4082.T',
  },
  {
    value: '4088.T',
    label: '4088.T,  Air Water Inc.',
    name: 'Air Water Inc.',
    symbol: '4088.T',
  },
  {
    value: '4091.T',
    label: '4091.T,  Nippon Sanso Holdings Corporation',
    name: 'Nippon Sanso Holdings Corporation',
    symbol: '4091.T',
  },
  {
    value: '4092.T',
    label: '4092.T,  Nippon Chemical Industrial Co., Ltd.',
    name: 'Nippon Chemical Industrial Co., Ltd.',
    symbol: '4092.T',
  },
  {
    value: '4095.T',
    label: '4095.T,  Nihon Parkerizing Co., Ltd.',
    name: 'Nihon Parkerizing Co., Ltd.',
    symbol: '4095.T',
  },
  {
    value: '4097.T',
    label: '4097.T,  Koatsu Gas Kogyo Co., Ltd.',
    name: 'Koatsu Gas Kogyo Co., Ltd.',
    symbol: '4097.T',
  },
  {
    value: '4099.T',
    label: '4099.T,  Shikoku Chemicals Corporation',
    name: 'Shikoku Chemicals Corporation',
    symbol: '4099.T',
  },
  {
    value: '4104.TW',
    label: '4104.TW,  Excelsior Medical Co., Ltd.',
    name: 'Excelsior Medical Co., Ltd.',
    symbol: '4104.TW',
  },
  {
    value: '4107.TWO',
    label: '4107.TWO,  Bioteque Corporation',
    name: 'Bioteque Corporation',
    symbol: '4107.TWO',
  },
  {
    value: '4108.TW',
    label: '4108.TW,  PhytoHealth Corporation',
    name: 'PhytoHealth Corporation',
    symbol: '4108.TW',
  },
  {
    value: '4109.T',
    label: '4109.T,  Stella Chemifa Corporation',
    name: 'Stella Chemifa Corporation',
    symbol: '4109.T',
  },
  {
    value: '4110.SR',
    label: '4110.SR,  Batic Investment and Logistics Company',
    name: 'Batic Investment and Logistics Company',
    symbol: '4110.SR',
  },
  {
    value: '4112.T',
    label: '4112.T,  Hodogaya Chemical Co., Ltd.',
    name: 'Hodogaya Chemical Co., Ltd.',
    symbol: '4112.T',
  },
  {
    value: '4113.T',
    label: '4113.T,  Taoka Chemical Company, Limited',
    name: 'Taoka Chemical Company, Limited',
    symbol: '4113.T',
  },
  {
    value: '4114.T',
    label: '4114.T,  Nippon Shokubai Co., Ltd.',
    name: 'Nippon Shokubai Co., Ltd.',
    symbol: '4114.T',
  },
  {
    value: '4116.T',
    label: '4116.T,  Dainichiseika Color & Chemicals Mfg. Co., Ltd.',
    name: 'Dainichiseika Color & Chemicals Mfg. Co., Ltd.',
    symbol: '4116.T',
  },
  {
    value: '4118.T',
    label: '4118.T,  Kaneka Corporation',
    name: 'Kaneka Corporation',
    symbol: '4118.T',
  },
  {
    value: '4119.TW',
    label: '4119.TW,  SCI Pharmtech, Inc.',
    name: 'SCI Pharmtech, Inc.',
    symbol: '4119.TW',
  },
  {
    value: '4123.TWO',
    label: '4123.TWO,  Center Laboratories, Inc.',
    name: 'Center Laboratories, Inc.',
    symbol: '4123.TWO',
  },
  {
    value: '4126.TWO',
    label: '4126.TWO,  Pacific Hospital Supply Co., Ltd',
    name: 'Pacific Hospital Supply Co., Ltd',
    symbol: '4126.TWO',
  },
  {
    value: '4128.TWO',
    label: '4128.TWO,  Microbio Co., Ltd.',
    name: 'Microbio Co., Ltd.',
    symbol: '4128.TWO',
  },
  {
    value: '4137.TW',
    label: '4137.TW,  Chlitina Holding Limited',
    name: 'Chlitina Holding Limited',
    symbol: '4137.TW',
  },
  {
    value: '4142.TW',
    label: '4142.TW,  Adimmune Corporation',
    name: 'Adimmune Corporation',
    symbol: '4142.TW',
  },
  {
    value: '4147.TWO',
    label: '4147.TWO,  TaiMed Biologics Inc.',
    name: 'TaiMed Biologics Inc.',
    symbol: '4147.TWO',
  },
  {
    value: '4150.SR',
    label: '4150.SR,  Arriyadh Development Co.',
    name: 'Arriyadh Development Co.',
    symbol: '4150.SR',
  },
  {
    value: '4151.T',
    label: '4151.T,  Kyowa Kirin Co., Ltd.',
    name: 'Kyowa Kirin Co., Ltd.',
    symbol: '4151.T',
  },
  {
    value: '4157.TWO',
    label: '4157.TWO,  TaiGen Biopharmaceuticals Holdings Limited',
    name: 'TaiGen Biopharmaceuticals Holdings Limited',
    symbol: '4157.TWO',
  },
  {
    value: '4162.TWO',
    label: '4162.TWO,  PharmaEngine, Inc.',
    name: 'PharmaEngine, Inc.',
    symbol: '4162.TWO',
  },
  {
    value: '4164.TW',
    label: '4164.TW,  CHC Healthcare Group',
    name: 'CHC Healthcare Group',
    symbol: '4164.TW',
  },
  {
    value: '4167.TWO',
    label: '4167.TWO,  Savior Lifetec Corporation',
    name: 'Savior Lifetec Corporation',
    symbol: '4167.TWO',
  },
  {
    value: '4174.TWO',
    label: '4174.TWO,  OBI Pharma, Inc.',
    name: 'OBI Pharma, Inc.',
    symbol: '4174.TWO',
  },
  {
    value: '4180.SR',
    label: '4180.SR,  Fitaihi Holding Group',
    name: 'Fitaihi Holding Group',
    symbol: '4180.SR',
  },
  {
    value: '4182.T',
    label: '4182.T,  Mitsubishi Gas Chemical Company, Inc.',
    name: 'Mitsubishi Gas Chemical Company, Inc.',
    symbol: '4182.T',
  },
  {
    value: '4183.T',
    label: '4183.T,  Mitsui Chemicals, Inc.',
    name: 'Mitsui Chemicals, Inc.',
    symbol: '4183.T',
  },
  {
    value: '4185.T',
    label: '4185.T,  JSR Corporation',
    name: 'JSR Corporation',
    symbol: '4185.T',
  },
  {
    value: '4186.T',
    label: '4186.T,  Tokyo Ohka Kogyo Co., Ltd.',
    name: 'Tokyo Ohka Kogyo Co., Ltd.',
    symbol: '4186.T',
  },
  {
    value: '4187.T',
    label: '4187.T,  Osaka Organic Chemical Industry Ltd.',
    name: 'Osaka Organic Chemical Industry Ltd.',
    symbol: '4187.T',
  },
  {
    value: '4188.T',
    label: '4188.T,  Mitsubishi Chemical Group Corporation',
    name: 'Mitsubishi Chemical Group Corporation',
    symbol: '4188.T',
  },
  {
    value: '4189.T',
    label: '4189.T,  KH Neochem Co., Ltd.',
    name: 'KH Neochem Co., Ltd.',
    symbol: '4189.T',
  },
  {
    value: '4190.SR',
    label: '4190.SR,  Jarir Marketing Company',
    name: 'Jarir Marketing Company',
    symbol: '4190.SR',
  },
  {
    value: '4190.TW',
    label: '4190.TW,  Jourdeness Group Limited',
    name: 'Jourdeness Group Limited',
    symbol: '4190.TW',
  },
  {
    value: '4200.SR',
    label: '4200.SR,  Aldrees Petroleum and Transport Services Company',
    name: 'Aldrees Petroleum and Transport Services Company',
    symbol: '4200.SR',
  },
  {
    value: '4202.T',
    label: '4202.T,  Daicel Corporation',
    name: 'Daicel Corporation',
    symbol: '4202.T',
  },
  {
    value: '4203.T',
    label: '4203.T,  Sumitomo Bakelite Company Limited',
    name: 'Sumitomo Bakelite Company Limited',
    symbol: '4203.T',
  },
  {
    value: '4204.T',
    label: '4204.T,  Sekisui Chemical Co., Ltd.',
    name: 'Sekisui Chemical Co., Ltd.',
    symbol: '4204.T',
  },
  {
    value: '4205.T',
    label: '4205.T,  Zeon Corporation',
    name: 'Zeon Corporation',
    symbol: '4205.T',
  },
  {
    value: '4206.T',
    label: '4206.T,  Aica Kogyo Company, Limited',
    name: 'Aica Kogyo Company, Limited',
    symbol: '4206.T',
  },
  {
    value: '4208.T',
    label: '4208.T,  UBE Corporation',
    name: 'UBE Corporation',
    symbol: '4208.T',
  },
  {
    value: '4210.SR',
    label: '4210.SR,  Saudi Research and Media Group',
    name: 'Saudi Research and Media Group',
    symbol: '4210.SR',
  },
  {
    value: '4212.T',
    label: '4212.T,  Sekisui Jushi Corporation',
    name: 'Sekisui Jushi Corporation',
    symbol: '4212.T',
  },
  {
    value: '3481.TW',
    label: '3481.TW,  Innolux Corporation',
    name: 'Innolux Corporation',
    symbol: '3481.TW',
  },
  {
    value: '348210.KQ',
    label: '348210.KQ,  NEXTIN, Inc.',
    name: 'NEXTIN, Inc.',
    symbol: '348210.KQ',
  },
  {
    value: '3483.TWO',
    label: '3483.TWO,  Forcecon Technology Co., Ltd.',
    name: 'Forcecon Technology Co., Ltd.',
    symbol: '3483.TWO',
  },
  {
    value: '3487.T',
    label: '3487.T,  CRE Logistics REIT, Inc.',
    name: 'CRE Logistics REIT, Inc.',
    symbol: '3487.T',
  },
  {
    value: '348950.KS',
    label: '348950.KS,  JR Global REIT',
    name: 'JR Global REIT',
    symbol: '348950.KS',
  },
  {
    value: '3491.T',
    label: '3491.T,  GA technologies Co., Ltd.',
    name: 'GA technologies Co., Ltd.',
    symbol: '3491.T',
  },
  {
    value: '3492.T',
    label: '3492.T,  Takara Leben Real Estate Investment Corporation',
    name: 'Takara Leben Real Estate Investment Corporation',
    symbol: '3492.T',
  },
  {
    value: '3493.T',
    label: '3493.T,  Advance Logistics Investment Corporation',
    name: 'Advance Logistics Investment Corporation',
    symbol: '3493.T',
  },
  {
    value: '3501.TW',
    label: '3501.TW,  Well Shin Technology Co., Ltd.',
    name: 'Well Shin Technology Co., Ltd.',
    symbol: '3501.TW',
  },
  {
    value: '3504.TW',
    label: '3504.TW,  Young Optics Inc.',
    name: 'Young Optics Inc.',
    symbol: '3504.TW',
  },
  {
    value: '3515.TW',
    label: '3515.TW,  ASROCK Incorporation',
    name: 'ASROCK Incorporation',
    symbol: '3515.TW',
  },
  {
    value: '3526.TWO',
    label: '3526.TWO,  Alltop Technology Co., Ltd.',
    name: 'Alltop Technology Co., Ltd.',
    symbol: '3526.TWO',
  },
  {
    value: '352820.KS',
    label: '352820.KS,  HYBE Co., Ltd.',
    name: 'HYBE Co., Ltd.',
    symbol: '352820.KS',
  },
  {
    value: '3529.TWO',
    label: '3529.TWO,  eMemory Technology Inc.',
    name: 'eMemory Technology Inc.',
    symbol: '3529.TWO',
  },
  {
    value: '3530.TW',
    label: '3530.TW,  Silicon Optronics, Inc.',
    name: 'Silicon Optronics, Inc.',
    symbol: '3530.TW',
  },
  {
    value: '353200.KS',
    label: '353200.KS,  DAEDUCK ELECTRONICS Co., Ltd.',
    name: 'DAEDUCK ELECTRONICS Co., Ltd.',
    symbol: '353200.KS',
  },
  {
    value: '3532.TW',
    label: '3532.TW,  Formosa Sumco Technology Corporation',
    name: 'Formosa Sumco Technology Corporation',
    symbol: '3532.TW',
  },
  {
    value: '3533.TW',
    label: '3533.TW,  Lotes Co., Ltd',
    name: 'Lotes Co., Ltd',
    symbol: '3533.TW',
  },
  {
    value: '353810.KQ',
    label: '353810.KQ,  EASY BIO,Inc.',
    name: 'EASY BIO,Inc.',
    symbol: '353810.KQ',
  },
  {
    value: '3539.T',
    label: '3539.T,  JM Holdings Co.,Ltd.',
    name: 'JM Holdings Co.,Ltd.',
    symbol: '3539.T',
  },
  {
    value: '3540.TWO',
    label: '3540.TWO,  Thermaltake Technology Co., Ltd.',
    name: 'Thermaltake Technology Co., Ltd.',
    symbol: '3540.TWO',
  },
  {
    value: '3543.T',
    label: '3543.T,  KOMEDA Holdings Co., Ltd.',
    name: 'KOMEDA Holdings Co., Ltd.',
    symbol: '3543.T',
  },
  {
    value: '3545.TW',
    label: '3545.TW,  FocalTech Systems Co., Ltd.',
    name: 'FocalTech Systems Co., Ltd.',
    symbol: '3545.TW',
  },
  {
    value: '3546.T',
    label: '3546.T,  Alleanza Holdings Co., Ltd.',
    name: 'Alleanza Holdings Co., Ltd.',
    symbol: '3546.T',
  },
  {
    value: '3546.TWO',
    label: '3546.TWO,  USERJOY Technology Co.,Ltd.',
    name: 'USERJOY Technology Co.,Ltd.',
    symbol: '3546.TWO',
  },
  {
    value: '3548.T',
    label: '3548.T,  Baroque Japan Limited',
    name: 'Baroque Japan Limited',
    symbol: '3548.T',
  },
  {
    value: '3548.TWO',
    label: '3548.TWO,  Jarllytec Co. , Ltd.',
    name: 'Jarllytec Co. , Ltd.',
    symbol: '3548.TWO',
  },
  {
    value: '3549.T',
    label: '3549.T,  Kusuri No Aoki Holdings Co., Ltd.',
    name: 'Kusuri No Aoki Holdings Co., Ltd.',
    symbol: '3549.T',
  },
  {
    value: '3551.TWO',
    label: '3551.TWO,  Shih Her Technologies Inc.',
    name: 'Shih Her Technologies Inc.',
    symbol: '3551.TWO',
  },
  {
    value: '3552.TWO',
    label: '3552.TWO,  Tung Thih Electronic Co., Ltd.',
    name: 'Tung Thih Electronic Co., Ltd.',
    symbol: '3552.TWO',
  },
  {
    value: '3556.TWO',
    label: '3556.TWO,  eGalax_eMPIA Technology Inc.',
    name: 'eGalax_eMPIA Technology Inc.',
    symbol: '3556.TWO',
  },
  {
    value: '3558.T',
    label: '3558.T,  LOCONDO, Inc.',
    name: 'LOCONDO, Inc.',
    symbol: '3558.T',
  },
  {
    value: '3558.TWO',
    label: '3558.TWO,  Senao Networks, Inc.',
    name: 'Senao Networks, Inc.',
    symbol: '3558.TWO',
  },
  {
    value: '3561.T',
    label: '3561.T,  Chikaranomoto Holdings Co.,Ltd.',
    name: 'Chikaranomoto Holdings Co.,Ltd.',
    symbol: '3561.T',
  },
  {
    value: '3563.T',
    label: '3563.T,  Food & Life Companies Ltd.',
    name: 'Food & Life Companies Ltd.',
    symbol: '3563.T',
  },
  {
    value: '3563.TW',
    label: '3563.TW,  Machvision Inc.',
    name: 'Machvision Inc.',
    symbol: '3563.TW',
  },
  {
    value: '3569.T',
    label: '3569.T,  Seiren Co.,Ltd.',
    name: 'Seiren Co.,Ltd.',
    symbol: '3569.T',
  },
  {
    value: '3576.TW',
    label: '3576.TW,  United Renewable Energy Co., Ltd.',
    name: 'United Renewable Energy Co., Ltd.',
    symbol: '3576.TW',
  },
  {
    value: '357780.KQ',
    label: '357780.KQ,  Soulbrain Co., Ltd.',
    name: 'Soulbrain Co., Ltd.',
    symbol: '357780.KQ',
  },
  {
    value: '3580.T',
    label: '3580.T,  KOMATSU MATERE Co.,Ltd.',
    name: 'KOMATSU MATERE Co.,Ltd.',
    symbol: '3580.T',
  },
  {
    value: '3583.TW',
    label: '3583.TW,  Scientech Corporation',
    name: 'Scientech Corporation',
    symbol: '3583.TW',
  },
  {
    value: '3587.TWO',
    label: '3587.TWO,  Materials Analysis Technology Inc.',
    name: 'Materials Analysis Technology Inc.',
    symbol: '3587.TWO',
  },
  {
    value: '3588.TW',
    label: '3588.TW,  Leadtrend Technology Corporation',
    name: 'Leadtrend Technology Corporation',
    symbol: '3588.TW',
  },
  {
    value: '3591.T',
    label: '3591.T,  Wacoal Holdings Corp.',
    name: 'Wacoal Holdings Corp.',
    symbol: '3591.T',
  },
  {
    value: '3593.T',
    label: '3593.T,  Hogy Medical Co.,Ltd.',
    name: 'Hogy Medical Co.,Ltd.',
    symbol: '3593.T',
  },
  {
    value: '3596.TW',
    label: '3596.TW,  Arcadyan Technology Corporation',
    name: 'Arcadyan Technology Corporation',
    symbol: '3596.TW',
  },
  {
    value: '3605.TW',
    label: '3605.TW,  ACES Electronics Co., Ltd.',
    name: 'ACES Electronics Co., Ltd.',
    symbol: '3605.TW',
  },
  {
    value: '3607.TW',
    label: '3607.TW,  Coxon Precise Industrial Co., Ltd',
    name: 'Coxon Precise Industrial Co., Ltd',
    symbol: '3607.TW',
  },
  {
    value: '3608.T',
    label: '3608.T,  TSI Holdings Co.,Ltd.',
    name: 'TSI Holdings Co.,Ltd.',
    symbol: '3608.T',
  },
  {
    value: '3611.TWO',
    label: '3611.TWO,  TSC Auto ID Technology Co., Ltd.',
    name: 'TSC Auto ID Technology Co., Ltd.',
    symbol: '3611.TWO',
  },
  {
    value: '3612.T',
    label: '3612.T,  World Co., Ltd.',
    name: 'World Co., Ltd.',
    symbol: '3612.T',
  },
  {
    value: '361610.KS',
    label: '361610.KS,  SK IE Technology Co., Ltd.',
    name: 'SK IE Technology Co., Ltd.',
    symbol: '361610.KS',
  },
  {
    value: '3622.TW',
    label: '3622.TW,  Young Fast Optoelectronics Co., Ltd.',
    name: 'Young Fast Optoelectronics Co., Ltd.',
    symbol: '3622.TW',
  },
  {
    value: '3626.T',
    label: '3626.T,  TIS Inc.',
    name: 'TIS Inc.',
    symbol: '3626.T',
  },
  {
    value: '3632.T',
    label: '3632.T,  GREE, Inc.',
    name: 'GREE, Inc.',
    symbol: '3632.T',
  },
  {
    value: '3630.TWO',
    label: '3630.TWO,  Newmax Technology Co., Ltd.',
    name: 'Newmax Technology Co., Ltd.',
    symbol: '3630.TWO',
  },
  {
    value: '363280.KS',
    label: '363280.KS,  TY Holdings Co., Ltd',
    name: 'TY Holdings Co., Ltd',
    symbol: '363280.KS',
  },
  {
    value: '3635.T',
    label: '3635.T,  Koei Tecmo Holdings Co., Ltd.',
    name: 'Koei Tecmo Holdings Co., Ltd.',
    symbol: '3635.T',
  },
  {
    value: '3636.T',
    label: '3636.T,  Mitsubishi Research Institute, Inc.',
    name: 'Mitsubishi Research Institute, Inc.',
    symbol: '3636.T',
  },
  {
    value: '3641.T',
    label: '3641.T,  Papyless Co., Ltd.',
    name: 'Papyless Co., Ltd.',
    symbol: '3641.T',
  },
  {
    value: '3645.TW',
    label: '3645.TW,  Taimide Tech. Inc.',
    name: 'Taimide Tech. Inc.',
    symbol: '3645.TW',
  },
  {
    value: '3649.T',
    label: '3649.T,  FINDEX Inc.',
    name: 'FINDEX Inc.',
    symbol: '3649.T',
  },
  {
    value: '3653.TW',
    label: '3653.TW,  Jentech Precision Industrial Co., Ltd',
    name: 'Jentech Precision Industrial Co., Ltd',
    symbol: '3653.TW',
  },
  {
    value: '3655.T',
    label: '3655.T,  BrainPad Inc.',
    name: 'BrainPad Inc.',
    symbol: '3655.T',
  },
  {
    value: '3656.T',
    label: '3656.T,  KLab Inc.',
    name: 'KLab Inc.',
    symbol: '3656.T',
  },
  {
    value: '3657.T',
    label: '3657.T,  Pole To Win Holdings, Inc.',
    name: 'Pole To Win Holdings, Inc.',
    symbol: '3657.T',
  },
  {
    value: '3659.T',
    label: '3659.T,  NEXON Co., Ltd.',
    name: 'NEXON Co., Ltd.',
    symbol: '3659.T',
  },
  {
    value: '3660.T',
    label: '3660.T,  istyle Inc.',
    name: 'istyle Inc.',
    symbol: '3660.T',
  },
  {
    value: '3661.T',
    label: '3661.T,  m-up holdings, Inc.',
    name: 'm-up holdings, Inc.',
    symbol: '3661.T',
  },
  {
    value: '3661.TW',
    label: '3661.TW,  Alchip Technologies, Limited',
    name: 'Alchip Technologies, Limited',
    symbol: '3661.TW',
  },
  {
    value: '3662.T',
    label: '3662.T,  Ateam Inc.',
    name: 'Ateam Inc.',
    symbol: '3662.T',
  },
  {
    value: '3663.T',
    label: '3663.T,  ArtSpark Holdings Inc.',
    name: 'ArtSpark Holdings Inc.',
    symbol: '3663.T',
  },
  {
    value: '3665.T',
    label: '3665.T,  Enigmo Inc.',
    name: 'Enigmo Inc.',
    symbol: '3665.T',
  },
  {
    value: '3665.TW',
    label: '3665.TW,  Bizlink Holding Inc.',
    name: 'Bizlink Holding Inc.',
    symbol: '3665.TW',
  },
  {
    value: '3668.T',
    label: '3668.T,  COLOPL, Inc.',
    name: 'COLOPL, Inc.',
    symbol: '3668.T',
  },
  {
    value: '3673.T',
    label: '3673.T,  Broadleaf Co., Ltd.',
    name: 'Broadleaf Co., Ltd.',
    symbol: '3673.T',
  },
  {
    value: '3673.TW',
    label: '3673.TW,  TPK Holding Co., Ltd.',
    name: 'TPK Holding Co., Ltd.',
    symbol: '3673.TW',
  },
  {
    value: '3676.T',
    label: '3676.T,  DIGITAL HEARTS HOLDINGS Co., Ltd.',
    name: 'DIGITAL HEARTS HOLDINGS Co., Ltd.',
    symbol: '3676.T',
  },
  {
    value: '3677.T',
    label: '3677.T,  System Information Co.,Ltd.',
    name: 'System Information Co.,Ltd.',
    symbol: '3677.T',
  },
  {
    value: '3678.T',
    label: '3678.T,  MEDIA DO Co., Ltd.',
    name: 'MEDIA DO Co., Ltd.',
    symbol: '3678.T',
  },
  {
    value: '3679.T',
    label: '3679.T,  ZIGExN Co., Ltd.',
    name: 'ZIGExN Co., Ltd.',
    symbol: '3679.T',
  },
  {
    value: '3679.TW',
    label: '3679.TW,  Nishoku Technology Inc.',
    name: 'Nishoku Technology Inc.',
    symbol: '3679.TW',
  },
  {
    value: '3680.TWO',
    label: '3680.TWO,  Gudeng Precision Industrial Co., Ltd.',
    name: 'Gudeng Precision Industrial Co., Ltd.',
    symbol: '3680.TWO',
  },
  {
    value: '3681.T',
    label: '3681.T,  V-cube, Inc.',
    name: 'V-cube, Inc.',
    symbol: '3681.T',
  },
  {
    value: '3682.TW',
    label: '3682.TW,  Asia Pacific Telecom Co., Ltd.',
    name: 'Asia Pacific Telecom Co., Ltd.',
    symbol: '3682.TW',
  },
  {
    value: '3683.T',
    label: '3683.T,  Cyberlinks Co., Ltd.',
    name: 'Cyberlinks Co., Ltd.',
    symbol: '3683.T',
  },
  {
    value: '3687.T',
    label: '3687.T,  Fixstars Corporation',
    name: 'Fixstars Corporation',
    symbol: '3687.T',
  },
  {
    value: '3688.T',
    label: '3688.T,  Carta Holdings, Inc.',
    name: 'Carta Holdings, Inc.',
    symbol: '3688.T',
  },
  {
    value: '3692.T',
    label: '3692.T,  FFRI Security, Inc.',
    name: 'FFRI Security, Inc.',
    symbol: '3692.T',
  },
  {
    value: '3694.T',
    label: '3694.T,  OPTiM Corporation',
    name: 'OPTiM Corporation',
    symbol: '3694.T',
  },
  {
    value: '3694.TW',
    label: '3694.TW,  AzureWave Technologies, Inc.',
    name: 'AzureWave Technologies, Inc.',
    symbol: '3694.TW',
  },
  {
    value: '3696.T',
    label: '3696.T,  Ceres Inc.',
    name: 'Ceres Inc.',
    symbol: '3696.T',
  },
  {
    value: '3697.T',
    label: '3697.T,  SHIFT Inc.',
    name: 'SHIFT Inc.',
    symbol: '3697.T',
  },
  {
    value: '3702.TW',
    label: '3702.TW,  WPG Holdings Limited',
    name: 'WPG Holdings Limited',
    symbol: '3702.TW',
  },
  {
    value: '3703.TW',
    label: '3703.TW,  Continental Holdings Corporation',
    name: 'Continental Holdings Corporation',
    symbol: '3703.TW',
  },
  {
    value: '3704.TW',
    label: '3704.TW,  Unizyx Holding Corporation',
    name: 'Unizyx Holding Corporation',
    symbol: '3704.TW',
  },
  {
    value: '3705.TW',
    label: '3705.TW,  YungShin Global Holding Corporation',
    name: 'YungShin Global Holding Corporation',
    symbol: '3705.TW',
  },
  {
    value: '3706.TW',
    label: '3706.TW,  MiTAC Holdings Corporation',
    name: 'MiTAC Holdings Corporation',
    symbol: '3706.TW',
  },
  {
    value: '3707.TWO',
    label: '3707.TWO,  Episil Technologies Inc.',
    name: 'Episil Technologies Inc.',
    symbol: '3707.TWO',
  },
  {
    value: '3708.T',
    label: '3708.T,  Tokushu Tokai Paper Co., Ltd.',
    name: 'Tokushu Tokai Paper Co., Ltd.',
    symbol: '3708.T',
  },
  {
    value: '3708.TW',
    label: '3708.TW,  Swancor Holding Co., LTD.',
    name: 'Swancor Holding Co., LTD.',
    symbol: '3708.TW',
  },
  {
    value: '3711.TW',
    label: '3711.TW,  ASE Technology Holding Co., Ltd.',
    name: 'ASE Technology Holding Co., Ltd.',
    symbol: '3711.TW',
  },
  {
    value: '3712.TW',
    label: '3712.TW,  FIT Holding Co., Ltd.',
    name: 'FIT Holding Co., Ltd.',
    symbol: '3712.TW',
  },
  {
    value: '3738.T',
    label: '3738.T,  T-Gaia Corporation',
    name: 'T-Gaia Corporation',
    symbol: '3738.T',
  },
  {
    value: '375500.KS',
    label: '375500.KS,  DL E&C Co.,Ltd.',
    name: 'DL E&C Co.,Ltd.',
    symbol: '375500.KS',
  },
  {
    value: '3762.T',
    label: '3762.T,  TechMatrix Corporation',
    name: 'TechMatrix Corporation',
    symbol: '3762.T',
  },
  {
    value: '3765.T',
    label: '3765.T,  GungHo Online Entertainment, Inc.',
    name: 'GungHo Online Entertainment, Inc.',
    symbol: '3765.T',
  },
  {
    value: '3769.T',
    label: '3769.T,  GMO Payment Gateway, Inc.',
    name: 'GMO Payment Gateway, Inc.',
    symbol: '3769.T',
  },
  {
    value: '3773.T',
    label: '3773.T,  Advanced Media, Inc.',
    name: 'Advanced Media, Inc.',
    symbol: '3773.T',
  },
  {
    value: '3774.T',
    label: '3774.T,  Internet Initiative Japan Inc.',
    name: 'Internet Initiative Japan Inc.',
    symbol: '3774.T',
  },
  {
    value: '3778.T',
    label: '3778.T,  SAKURA Internet Inc.',
    name: 'SAKURA Internet Inc.',
    symbol: '3778.T',
  },
  {
    value: '3782.T',
    label: '3782.T,  DDS,Inc.',
    name: 'DDS,Inc.',
    symbol: '3782.T',
  },
  {
    value: '3788.T',
    label: '3788.T,  GMO GlobalSign Holdings K.K.',
    name: 'GMO GlobalSign Holdings K.K.',
    symbol: '3788.T',
  },
  {
    value: '3817.T',
    label: '3817.T,  SRA Holdings, Inc.',
    name: 'SRA Holdings, Inc.',
    symbol: '3817.T',
  },
  {
    value: '383220.KS',
    label: '383220.KS,  F&F Co., Ltd',
    name: 'F&F Co., Ltd',
    symbol: '383220.KS',
  },
  {
    value: '3834.T',
    label: '3834.T,  Asahi Net, Inc.',
    name: 'Asahi Net, Inc.',
    symbol: '3834.T',
  },
  {
    value: '3835.T',
    label: '3835.T,  eBASE Co.,Ltd.',
    name: 'eBASE Co.,Ltd.',
    symbol: '3835.T',
  },
  {
    value: '3836.T',
    label: '3836.T,  Avant Group Corporation',
    name: 'Avant Group Corporation',
    symbol: '3836.T',
  },
  {
    value: '3837.T',
    label: '3837.T,  Ad-Sol Nissin Corporation',
    name: 'Ad-Sol Nissin Corporation',
    symbol: '3837.T',
  },
  {
    value: '383800.KS',
    label: '383800.KS,  LX Holdings Corp.',
    name: 'LX Holdings Corp.',
    symbol: '383800.KS',
  },
  {
    value: '3843.T',
    label: '3843.T,  FreeBit Co., Ltd.',
    name: 'FreeBit Co., Ltd.',
    symbol: '3843.T',
  },
  {
    value: '3844.T',
    label: '3844.T,  Comture Corporation',
    name: 'Comture Corporation',
    symbol: '3844.T',
  },
  {
    value: '4215.T',
    label: '4215.T,  C.I.TAKIRON Corporation',
    name: 'C.I.TAKIRON Corporation',
    symbol: '4215.T',
  },
  {
    value: '4216.T',
    label: '4216.T,  Asahi Yukizai Corporation',
    name: 'Asahi Yukizai Corporation',
    symbol: '4216.T',
  },
  {
    value: '4218.T',
    label: '4218.T,  Nichiban Co., Ltd.',
    name: 'Nichiban Co., Ltd.',
    symbol: '4218.T',
  },
  {
    value: '4220.SR',
    label: '4220.SR,  Emaar The Economic City',
    name: 'Emaar The Economic City',
    symbol: '4220.SR',
  },
  {
    value: '4220.T',
    label: '4220.T,  Riken Technos Corporation',
    name: 'Riken Technos Corporation',
    symbol: '4220.T',
  },
  {
    value: '4228.T',
    label: '4228.T,  Sekisui Kasei Co., Ltd.',
    name: 'Sekisui Kasei Co., Ltd.',
    symbol: '4228.T',
  },
  {
    value: '4230.SR',
    label: '4230.SR,  Red Sea International Company',
    name: 'Red Sea International Company',
    symbol: '4230.SR',
  },
  {
    value: '4240.SR',
    label: '4240.SR,  Fawaz Abdulaziz Al Hokair & Company',
    name: 'Fawaz Abdulaziz Al Hokair & Company',
    symbol: '4240.SR',
  },
  {
    value: '4246.T',
    label: '4246.T,  DaikyoNishikawa Corporation',
    name: 'DaikyoNishikawa Corporation',
    symbol: '4246.T',
  },
  {
    value: '4249.T',
    label: '4249.T,  Moriroku Holdings Company, Ltd.',
    name: 'Moriroku Holdings Company, Ltd.',
    symbol: '4249.T',
  },
  {
    value: '4251.T',
    label: '4251.T,  KEIWA Incorporated',
    name: 'KEIWA Incorporated',
    symbol: '4251.T',
  },
  {
    value: '4260.SR',
    label: '4260.SR,  United International Transportation Company',
    name: 'United International Transportation Company',
    symbol: '4260.SR',
  },
  {
    value: '4261.SR',
    label: '4261.SR,  Theeb Rent A Car Company',
    name: 'Theeb Rent A Car Company',
    symbol: '4261.SR',
  },
  {
    value: '4270.SR',
    label: '4270.SR,  Saudi Printing and Packaging Co.',
    name: 'Saudi Printing and Packaging Co.',
    symbol: '4270.SR',
  },
  {
    value: '4272.T',
    label: '4272.T,  Nippon Kayaku Co., Ltd.',
    name: 'Nippon Kayaku Co., Ltd.',
    symbol: '4272.T',
  },
  {
    value: '4275.T',
    label: '4275.T,  Carlit Holdings Co., Ltd.',
    name: 'Carlit Holdings Co., Ltd.',
    symbol: '4275.T',
  },
  {
    value: '4286.T',
    label: '4286.T,  CL Holdings Inc.',
    name: 'CL Holdings Inc.',
    symbol: '4286.T',
  },
  {
    value: '4290.SR',
    label: '4290.SR,  Al Khaleej Training and Education Company',
    name: 'Al Khaleej Training and Education Company',
    symbol: '4290.SR',
  },
  {
    value: '4290.T',
    label: '4290.T,  Prestige International Inc.',
    name: 'Prestige International Inc.',
    symbol: '4290.T',
  },
  {
    value: '4291.SR',
    label: '4291.SR,  National Company for Learning and Education',
    name: 'National Company for Learning and Education',
    symbol: '4291.SR',
  },
  {
    value: '4292.SR',
    label: '4292.SR,  Ataa Educational Company',
    name: 'Ataa Educational Company',
    symbol: '4292.SR',
  },
  {
    value: '4293.T',
    label: '4293.T,  Septeni Holdings Co., Ltd.',
    name: 'Septeni Holdings Co., Ltd.',
    symbol: '4293.T',
  },
  {
    value: '4298.T',
    label: '4298.T,  Proto Corporation',
    name: 'Proto Corporation',
    symbol: '4298.T',
  },
  {
    value: '4300.SR',
    label: '4300.SR,  Dar Al Arkan Real Estate Development Company',
    name: 'Dar Al Arkan Real Estate Development Company',
    symbol: '4300.SR',
  },
  {
    value: '4301.T',
    label: '4301.T,  Amuse Inc.',
    name: 'Amuse Inc.',
    symbol: '4301.T',
  },
  {
    value: '4306.TW',
    label: '4306.TW,  Yem Chio Co., Ltd.',
    name: 'Yem Chio Co., Ltd.',
    symbol: '4306.TW',
  },
  {
    value: '4307.T',
    label: '4307.T,  Nomura Research Institute, Ltd.',
    name: 'Nomura Research Institute, Ltd.',
    symbol: '4307.T',
  },
  {
    value: '4308.T',
    label: '4308.T,  J-Stream Inc.',
    name: 'J-Stream Inc.',
    symbol: '4308.T',
  },
  {
    value: '4312.T',
    label: '4312.T,  Cybernet Systems Co., Ltd.',
    name: 'Cybernet Systems Co., Ltd.',
    symbol: '4312.T',
  },
  {
    value: '4318.T',
    label: '4318.T,  Quick Co.,Ltd.',
    name: 'Quick Co.,Ltd.',
    symbol: '4318.T',
  },
  {
    value: '4320.SR',
    label: '4320.SR,  Alandalus Property Company',
    name: 'Alandalus Property Company',
    symbol: '4320.SR',
  },
  {
    value: '4321.SR',
    label: '4321.SR,  Arabian Centres Company',
    name: 'Arabian Centres Company',
    symbol: '4321.SR',
  },
  {
    value: '4324.T',
    label: '4324.T,  Dentsu Group Inc.',
    name: 'Dentsu Group Inc.',
    symbol: '4324.T',
  },
  {
    value: '4326.T',
    label: '4326.T,  INTAGE HOLDINGS Inc.',
    name: 'INTAGE HOLDINGS Inc.',
    symbol: '4326.T',
  },
  {
    value: '4330.SR',
    label: '4330.SR,  Riyad REIT Fund',
    name: 'Riyad REIT Fund',
    symbol: '4330.SR',
  },
  {
    value: '4335.SR',
    label: '4335.SR,  Musharaka REIT Fund',
    name: 'Musharaka REIT Fund',
    symbol: '4335.SR',
  },
  {
    value: '4337.T',
    label: '4337.T,  PIA Corporation',
    name: 'PIA Corporation',
    symbol: '4337.T',
  },
  {
    value: '4338.SR',
    label: '4338.SR,  Alahli Capital - Al-Ahli Reit Fund',
    name: 'Alahli Capital - Al-Ahli Reit Fund',
    symbol: '4338.SR',
  },
  {
    value: '4339.SR',
    label: '4339.SR,  Derayah Financial - Derayah Reit Fund',
    name: 'Derayah Financial - Derayah Reit Fund',
    symbol: '4339.SR',
  },
  {
    value: '4340.SR',
    label: '4340.SR,  Al Rajhi REIT Fund',
    name: 'Al Rajhi REIT Fund',
    symbol: '4340.SR',
  },
  {
    value: '4342.SR',
    label: '4342.SR,  Jadwa Investment - Jadwa Reit Saudi Fund',
    name: 'Jadwa Investment - Jadwa Reit Saudi Fund',
    symbol: '4342.SR',
  },
  {
    value: '4343.T',
    label: '4343.T,  Aeon Fantasy Co., Ltd.',
    name: 'Aeon Fantasy Co., Ltd.',
    symbol: '4343.T',
  },
  {
    value: '4344.SR',
    label: '4344.SR,  SEDCO Capital REIT Fund',
    name: 'SEDCO Capital REIT Fund',
    symbol: '4344.SR',
  },
  {
    value: '4344.T',
    label: '4344.T,  Sourcenext Corporation',
    name: 'Sourcenext Corporation',
    symbol: '4344.T',
  },
  {
    value: '4345.T',
    label: '4345.T,  CTS Co., Ltd.',
    name: 'CTS Co., Ltd.',
    symbol: '4345.T',
  },
  {
    value: '4348.SR',
    label: '4348.SR,  Alkhabeer REIT Fund',
    name: 'Alkhabeer REIT Fund',
    symbol: '4348.SR',
  },
  {
    value: '4348.T',
    label: '4348.T,  Infocom Corporation',
    name: 'Infocom Corporation',
    symbol: '4348.T',
  },
  {
    value: '4362.T',
    label: '4362.T,  Nippon Fine Chemical Co., Ltd.',
    name: 'Nippon Fine Chemical Co., Ltd.',
    symbol: '4362.T',
  },
  {
    value: '4368.T',
    label: '4368.T,  Fuso Chemical Co.,Ltd.',
    name: 'Fuso Chemical Co.,Ltd.',
    symbol: '4368.T',
  },
  {
    value: '4369.T',
    label: '4369.T,  Tri Chemical Laboratories Inc.',
    name: 'Tri Chemical Laboratories Inc.',
    symbol: '4369.T',
  },
  {
    value: '4384.T',
    label: '4384.T,  Raksul Inc.',
    name: 'Raksul Inc.',
    symbol: '4384.T',
  },
  {
    value: '4397.T',
    label: '4397.T,  TeamSpirit Inc.',
    name: 'TeamSpirit Inc.',
    symbol: '4397.T',
  },
  {
    value: '4401.T',
    label: '4401.T,  Adeka Corporation',
    name: 'Adeka Corporation',
    symbol: '4401.T',
  },
  {
    value: '4403.T',
    label: '4403.T,  NOF Corporation',
    name: 'NOF Corporation',
    symbol: '4403.T',
  },
  {
    value: '4423.T',
    label: '4423.T,  ARTERIA Networks Corporation',
    name: 'ARTERIA Networks Corporation',
    symbol: '4423.T',
  },
  {
    value: '4425.T',
    label: '4425.T,  Kudan Inc.',
    name: 'Kudan Inc.',
    symbol: '4425.T',
  },
  {
    value: '4426.TW',
    label: '4426.TW,  Li Cheng Enterprise Co., Ltd.',
    name: 'Li Cheng Enterprise Co., Ltd.',
    symbol: '4426.TW',
  },
  {
    value: '4427.T',
    label: '4427.T,  EduLab, Inc.',
    name: 'EduLab, Inc.',
    symbol: '4427.T',
  },
  {
    value: '4433.T',
    label: '4433.T,  HITO-Communications Holdings,Inc.',
    name: 'HITO-Communications Holdings,Inc.',
    symbol: '4433.T',
  },
  {
    value: '4435.T',
    label: '4435.T,  kaonavi, inc.',
    name: 'kaonavi, inc.',
    symbol: '4435.T',
  },
  {
    value: '4436.T',
    label: '4436.T,  MINKABU THE INFONOID, Inc.',
    name: 'MINKABU THE INFONOID, Inc.',
    symbol: '4436.T',
  },
  {
    value: '4438.TW',
    label: '4438.TW,  Quang Viet Enterprise Co., Ltd.',
    name: 'Quang Viet Enterprise Co., Ltd.',
    symbol: '4438.TW',
  },
  {
    value: '4443.T',
    label: '4443.T,  Sansan, Inc.',
    name: 'Sansan, Inc.',
    symbol: '4443.T',
  },
  {
    value: '4448.T',
    label: '4448.T,  Chatwork Co.,Ltd.',
    name: 'Chatwork Co.,Ltd.',
    symbol: '4448.T',
  },
  {
    value: '4449.T',
    label: '4449.T,  giftee Inc.',
    name: 'giftee Inc.',
    symbol: '4449.T',
  },
  {
    value: '4452.T',
    label: '4452.T,  Kao Corporation',
    name: 'Kao Corporation',
    symbol: '4452.T',
  },
  {
    value: '4461.T',
    label: '4461.T,  DKS Co. Ltd.',
    name: 'DKS Co. Ltd.',
    symbol: '4461.T',
  },
  {
    value: '4471.T',
    label: '4471.T,  Sanyo Chemical Industries Ltd.',
    name: 'Sanyo Chemical Industries Ltd.',
    symbol: '4471.T',
  },
  {
    value: '4475.T',
    label: '4475.T,  Hennge K.K.',
    name: 'Hennge K.K.',
    symbol: '4475.T',
  },
  {
    value: '4477.T',
    label: '4477.T,  BASE, Inc.',
    name: 'BASE, Inc.',
    symbol: '4477.T',
  },
  {
    value: '4478.T',
    label: '4478.T,  freee K.K.',
    name: 'freee K.K.',
    symbol: '4478.T',
  },
  {
    value: '4479.T',
    label: '4479.T,  Makuake, Inc.',
    name: 'Makuake, Inc.',
    symbol: '4479.T',
  },
  {
    value: '4480.T',
    label: '4480.T,  Medley, Inc.',
    name: 'Medley, Inc.',
    symbol: '4480.T',
  },
  {
    value: '4483.T',
    label: '4483.T,  JMDC Inc.',
    name: 'JMDC Inc.',
    symbol: '4483.T',
  },
  {
    value: '4485.T',
    label: '4485.T,  JTOWER Inc.',
    name: 'JTOWER Inc.',
    symbol: '4485.T',
  },
  {
    value: '4488.T',
    label: '4488.T,  AI inside Inc.',
    name: 'AI inside Inc.',
    symbol: '4488.T',
  },
  {
    value: '4493.T',
    label: '4493.T,  Cyber Security Cloud , Inc.',
    name: 'Cyber Security Cloud , Inc.',
    symbol: '4493.T',
  },
  {
    value: '4502.T',
    label: '4502.T,  Takeda Pharmaceutical Company Limited',
    name: 'Takeda Pharmaceutical Company Limited',
    symbol: '4502.T',
  },
  {
    value: '4503.T',
    label: '4503.T,  Astellas Pharma Inc.',
    name: 'Astellas Pharma Inc.',
    symbol: '4503.T',
  },
  {
    value: '4506.T',
    label: '4506.T,  Sumitomo Pharma Co., Ltd.',
    name: 'Sumitomo Pharma Co., Ltd.',
    symbol: '4506.T',
  },
  {
    value: '4506.TWO',
    label: '4506.TWO,  GFC. LTD.',
    name: 'GFC. LTD.',
    symbol: '4506.TWO',
  },
  {
    value: '4507.T',
    label: '4507.T,  Shionogi & Co., Ltd.',
    name: 'Shionogi & Co., Ltd.',
    symbol: '4507.T',
  },
  {
    value: '4516.T',
    label: '4516.T,  Nippon Shinyaku Co., Ltd.',
    name: 'Nippon Shinyaku Co., Ltd.',
    symbol: '4516.T',
  },
  {
    value: '4519.T',
    label: '4519.T,  Chugai Pharmaceutical Co., Ltd.',
    name: 'Chugai Pharmaceutical Co., Ltd.',
    symbol: '4519.T',
  },
  {
    value: '4521.T',
    label: '4521.T,  Kaken Pharmaceutical Co., Ltd.',
    name: 'Kaken Pharmaceutical Co., Ltd.',
    symbol: '4521.T',
  },
  {
    value: '4523.T',
    label: '4523.T,  Eisai Co., Ltd.',
    name: 'Eisai Co., Ltd.',
    symbol: '4523.T',
  },
  {
    value: '4526.T',
    label: '4526.T,  Riken Vitamin Co., Ltd.',
    name: 'Riken Vitamin Co., Ltd.',
    symbol: '4526.T',
  },
  {
    value: '4527.T',
    label: '4527.T,  Rohto Pharmaceutical Co.,Ltd.',
    name: 'Rohto Pharmaceutical Co.,Ltd.',
    symbol: '4527.T',
  },
  {
    value: '4528.T',
    label: '4528.T,  Ono Pharmaceutical Co., Ltd.',
    name: 'Ono Pharmaceutical Co., Ltd.',
    symbol: '4528.T',
  },
  {
    value: '4530.T',
    label: '4530.T,  Hisamitsu Pharmaceutical Co., Inc.',
    name: 'Hisamitsu Pharmaceutical Co., Inc.',
    symbol: '4530.T',
  },
  {
    value: '4532.TW',
    label: '4532.TW,  Rechi Precision Co., Ltd.',
    name: 'Rechi Precision Co., Ltd.',
    symbol: '4532.TW',
  },
  {
    value: '4534.T',
    label: '4534.T,  Mochida Pharmaceutical Co., Ltd.',
    name: 'Mochida Pharmaceutical Co., Ltd.',
    symbol: '4534.T',
  },
  {
    value: '4536.T',
    label: '4536.T,  Santen Pharmaceutical Co., Ltd.',
    name: 'Santen Pharmaceutical Co., Ltd.',
    symbol: '4536.T',
  },
  {
    value: '4536.TW',
    label: '4536.TW,  Topkey Corporation',
    name: 'Topkey Corporation',
    symbol: '4536.TW',
  },
  {
    value: '4540.T',
    label: '4540.T,  Tsumura & Co.',
    name: 'Tsumura & Co.',
    symbol: '4540.T',
  },
  {
    value: '4541.T',
    label: '4541.T,  Nichi-Iko Pharmaceutical Co., Ltd.',
    name: 'Nichi-Iko Pharmaceutical Co., Ltd.',
    symbol: '4541.T',
  },
  {
    value: '4543.T',
    label: '4543.T,  Terumo Corporation',
    name: 'Terumo Corporation',
    symbol: '4543.T',
  },
  {
    value: '4544.T',
    label: '4544.T,  H.U. Group Holdings, Inc.',
    name: 'H.U. Group Holdings, Inc.',
    symbol: '4544.T',
  },
  {
    value: '4547.T',
    label: '4547.T,  Kissei Pharmaceutical Co., Ltd.',
    name: 'Kissei Pharmaceutical Co., Ltd.',
    symbol: '4547.T',
  },
  {
    value: '4548.T',
    label: '4548.T,  Seikagaku Corporation',
    name: 'Seikagaku Corporation',
    symbol: '4548.T',
  },
  {
    value: '4549.T',
    label: '4549.T,  Eiken Chemical Co., Ltd.',
    name: 'Eiken Chemical Co., Ltd.',
    symbol: '4549.T',
  },
  {
    value: '4551.T',
    label: '4551.T,  Torii Pharmaceutical Co., Ltd.',
    name: 'Torii Pharmaceutical Co., Ltd.',
    symbol: '4551.T',
  },
  {
    value: '4551.TW',
    label: '4551.TW,  Global PMX Co., Ltd.',
    name: 'Global PMX Co., Ltd.',
    symbol: '4551.TW',
  },
  {
    value: '4552.T',
    label: '4552.T,  JCR Pharmaceuticals Co., Ltd.',
    name: 'JCR Pharmaceuticals Co., Ltd.',
    symbol: '4552.T',
  },
  {
    value: '4553.T',
    label: '4553.T,  Towa Pharmaceutical Co., Ltd.',
    name: 'Towa Pharmaceutical Co., Ltd.',
    symbol: '4553.T',
  },
  {
    value: '4554.T',
    label: '4554.T,  Fuji Pharma Co., Ltd.',
    name: 'Fuji Pharma Co., Ltd.',
    symbol: '4554.T',
  },
  {
    value: '4559.T',
    label: '4559.T,  Zeria Pharmaceutical Co., Ltd.',
    name: 'Zeria Pharmaceutical Co., Ltd.',
    symbol: '4559.T',
  },
  {
    value: '4563.T',
    label: '4563.T,  AnGes, Inc.',
    name: 'AnGes, Inc.',
    symbol: '4563.T',
  },
  {
    value: '4564.T',
    label: '4564.T,  OncoTherapy Science, Inc.',
    name: 'OncoTherapy Science, Inc.',
    symbol: '4564.T',
  },
  {
    value: '4564.TW',
    label: '4564.TW,  Mosa Industrial Corporation',
    name: 'Mosa Industrial Corporation',
    symbol: '4564.TW',
  },
  {
    value: '4565.T',
    label: '4565.T,  Sosei Group Corporation',
    name: 'Sosei Group Corporation',
    symbol: '4565.T',
  },
  {
    value: '4568.T',
    label: '4568.T,  Daiichi Sankyo Company, Limited',
    name: 'Daiichi Sankyo Company, Limited',
    symbol: '4568.T',
  },
  {
    value: '4569.T',
    label: '4569.T,  KYORIN Holdings, Inc.',
    name: 'KYORIN Holdings, Inc.',
    symbol: '4569.T',
  },
  {
    value: '4571.T',
    label: '4571.T,  NanoCarrier Co., Ltd.',
    name: 'NanoCarrier Co., Ltd.',
    symbol: '4571.T',
  },
  {
    value: '4571.TW',
    label: '4571.TW,  Khgears International Limited',
    name: 'Khgears International Limited',
    symbol: '4571.TW',
  },
  {
    value: '4572.T',
    label: '4572.T,  Carna Biosciences, Inc.',
    name: 'Carna Biosciences, Inc.',
    symbol: '4572.T',
  },
  {
    value: '4574.T',
    label: '4574.T,  Taiko Pharmaceutical Co.,Ltd.',
    name: 'Taiko Pharmaceutical Co.,Ltd.',
    symbol: '4574.T',
  },
  {
    value: '4577.T',
    label: '4577.T,  Daito Pharmaceutical Co.,Ltd.',
    name: 'Daito Pharmaceutical Co.,Ltd.',
    symbol: '4577.T',
  },
  {
    value: '4578.T',
    label: '4578.T,  Otsuka Holdings Co., Ltd.',
    name: 'Otsuka Holdings Co., Ltd.',
    symbol: '4578.T',
  },
  {
    value: '4579.T',
    label: '4579.T,  RaQualia Pharma Inc.',
    name: 'RaQualia Pharma Inc.',
    symbol: '4579.T',
  },
  {
    value: '4581.T',
    label: '4581.T,  Taisho Pharmaceutical Holdings Co., Ltd.',
    name: 'Taisho Pharmaceutical Holdings Co., Ltd.',
    symbol: '4581.T',
  },
  {
    value: '4582.T',
    label: '4582.T,  SymBio Pharmaceuticals Limited',
    name: 'SymBio Pharmaceuticals Limited',
    symbol: '4582.T',
  },
  {
    value: '4587.T',
    label: '4587.T,  PeptiDream Inc.',
    name: 'PeptiDream Inc.',
    symbol: '4587.T',
  },
  {
    value: '4588.T',
    label: '4588.T,  Oncolys BioPharma Inc.',
    name: 'Oncolys BioPharma Inc.',
    symbol: '4588.T',
  },
  {
    value: '4592.T',
    label: '4592.T,  SanBio Company Limited',
    name: 'SanBio Company Limited',
    symbol: '4592.T',
  },
  {
    value: '4593.T',
    label: '4593.T,  Healios K.K.',
    name: 'Healios K.K.',
    symbol: '4593.T',
  },
  {
    value: '4594.T',
    label: '4594.T,  BrightPath Biotherapeutics Co., Ltd.',
    name: 'BrightPath Biotherapeutics Co., Ltd.',
    symbol: '4594.T',
  },
  {
    value: '4597.T',
    label: '4597.T,  Solasia Pharma K.K.',
    name: 'Solasia Pharma K.K.',
    symbol: '4597.T',
  },
  {
    value: '4599.T',
    label: '4599.T,  StemRIM',
    name: 'StemRIM',
    symbol: '4599.T',
  },
  {
    value: '4611.T',
    label: '4611.T,  Dai Nippon Toryo Company,Limited',
    name: 'Dai Nippon Toryo Company,Limited',
    symbol: '4611.T',
  },
  {
    value: '4612.T',
    label: '4612.T,  Nippon Paint Holdings Co., Ltd.',
    name: 'Nippon Paint Holdings Co., Ltd.',
    symbol: '4612.T',
  },
  {
    value: '4613.T',
    label: '4613.T,  Kansai Paint Co., Ltd.',
    name: 'Kansai Paint Co., Ltd.',
    symbol: '4613.T',
  },
  {
    value: '4617.T',
    label: '4617.T,  Chugoku Marine Paints, Ltd.',
    name: 'Chugoku Marine Paints, Ltd.',
    symbol: '4617.T',
  },
  {
    value: '4619.T',
    label: '4619.T,  Nihon Tokushu Toryo Co., Ltd.',
    name: 'Nihon Tokushu Toryo Co., Ltd.',
    symbol: '4619.T',
  },
  {
    value: '4626.T',
    label: '4626.T,  Taiyo Holdings Co., Ltd.',
    name: 'Taiyo Holdings Co., Ltd.',
    symbol: '4626.T',
  },
  {
    value: '4631.T',
    label: '4631.T,  DIC Corporation',
    name: 'DIC Corporation',
    symbol: '4631.T',
  },
  {
    value: '4633.T',
    label: '4633.T,  Sakata INX Corporation',
    name: 'Sakata INX Corporation',
    symbol: '4633.T',
  },
  {
    value: '4634.T',
    label: '4634.T,  Toyo Ink SC Holdings Co., Ltd.',
    name: 'Toyo Ink SC Holdings Co., Ltd.',
    symbol: '4634.T',
  },
  {
    value: '4641.T',
    label: '4641.T,  Altech Corporation',
    name: 'Altech Corporation',
    symbol: '4641.T',
  },
  {
    value: '4658.T',
    label: '4658.T,  Nippon Air conditioning Services Co., Ltd.',
    name: 'Nippon Air conditioning Services Co., Ltd.',
    symbol: '4658.T',
  },
  {
    value: '4661.T',
    label: '4661.T,  Oriental Land Co., Ltd.',
    name: 'Oriental Land Co., Ltd.',
    symbol: '4661.T',
  },
  {
    value: '4662.T',
    label: '4662.T,  Focus Systems Corporation',
    name: 'Focus Systems Corporation',
    symbol: '4662.T',
  },
  {
    value: '4665.T',
    label: '4665.T,  Duskin Co., Ltd.',
    name: 'Duskin Co., Ltd.',
    symbol: '4665.T',
  },
  {
    value: '4666.T',
    label: '4666.T,  PARK24 Co., Ltd.',
    name: 'PARK24 Co., Ltd.',
    symbol: '4666.T',
  },
  {
    value: '4668.T',
    label: '4668.T,  Meiko Network Japan Co., Ltd.',
    name: 'Meiko Network Japan Co., Ltd.',
    symbol: '4668.T',
  },
  {
    value: '4671.T',
    label: '4671.T,  FALCO HOLDINGS Co., Ltd.',
    name: 'FALCO HOLDINGS Co., Ltd.',
    symbol: '4671.T',
  },
  {
    value: '4674.T',
    label: '4674.T,  Cresco Ltd.',
    name: 'Cresco Ltd.',
    symbol: '4674.T',
  },
  {
    value: '4676.T',
    label: '4676.T,  Fuji Media Holdings, Inc.',
    name: 'Fuji Media Holdings, Inc.',
    symbol: '4676.T',
  },
  {
    value: '4680.T',
    label: '4680.T,  Round One Corporation',
    name: 'Round One Corporation',
    symbol: '4680.T',
  },
  {
    value: '4681.T',
    label: '4681.T,  Resorttrust, Inc.',
    name: 'Resorttrust, Inc.',
    symbol: '4681.T',
  },
  {
    value: '4684.T',
    label: '4684.T,  OBIC Co.,Ltd.',
    name: 'OBIC Co.,Ltd.',
    symbol: '4684.T',
  },
  {
    value: '4686.T',
    label: '4686.T,  JustSystems Corporation',
    name: 'JustSystems Corporation',
    symbol: '4686.T',
  },
  {
    value: '4687.T',
    label: '4687.T,  TDC SOFT Inc.',
    name: 'TDC SOFT Inc.',
    symbol: '4687.T',
  },
  {
    value: '4689.T',
    label: '4689.T,  Z Holdings Corporation',
    name: 'Z Holdings Corporation',
    symbol: '4689.T',
  },
  {
    value: '4694.T',
    label: '4694.T,  BML, Inc.',
    name: 'BML, Inc.',
    symbol: '4694.T',
  },
  {
    value: '4704.T',
    label: '4704.T,  Trend Micro Incorporated',
    name: 'Trend Micro Incorporated',
    symbol: '4704.T',
  },
  {
    value: '4708.T',
    label: '4708.T,  Relia, Inc.',
    name: 'Relia, Inc.',
    symbol: '4708.T',
  },
  {
    value: '4709.T',
    label: '4709.T,  ID Holdings Corporation',
    name: 'ID Holdings Corporation',
    symbol: '4709.T',
  },
  {
    value: '4714.T',
    label: '4714.T,  Riso Kyoiku Co., Ltd.',
    name: 'Riso Kyoiku Co., Ltd.',
    symbol: '4714.T',
  },
  {
    value: '4716.T',
    label: '4716.T,  Oracle Corporation Japan',
    name: 'Oracle Corporation Japan',
    symbol: '4716.T',
  },
  {
    value: '4721.TWO',
    label: '4721.TWO,  Mechema Chemicals International Corp.',
    name: 'Mechema Chemicals International Corp.',
    symbol: '4721.TWO',
  },
  {
    value: '4722.T',
    label: '4722.T,  Future Corporation',
    name: 'Future Corporation',
    symbol: '4722.T',
  },
  {
    value: '4725.T',
    label: '4725.T,  CAC Holdings Corporation',
    name: 'CAC Holdings Corporation',
    symbol: '4725.T',
  },
  {
    value: '4726.T',
    label: '4726.T,  SB Technology Corp.',
    name: 'SB Technology Corp.',
    symbol: '4726.T',
  },
  {
    value: '4732.T',
    label: '4732.T,  USS Co., Ltd.',
    name: 'USS Co., Ltd.',
    symbol: '4732.T',
  },
  {
    value: '4733.T',
    label: '4733.T,  OBIC Business Consultants Co., Ltd.',
    name: 'OBIC Business Consultants Co., Ltd.',
    symbol: '4733.T',
  },
  {
    value: '4736.TWO',
    label: '4736.TWO,  TaiDoc Technology Corporation',
    name: 'TaiDoc Technology Corporation',
    symbol: '4736.TWO',
  },
  {
    value: '4739.T',
    label: '4739.T,  ITOCHU Techno-Solutions Corporation',
    name: 'ITOCHU Techno-Solutions Corporation',
    symbol: '4739.T',
  },
  {
    value: '4739.TW',
    label: '4739.TW,  Coremax Corporation',
    name: 'Coremax Corporation',
    symbol: '4739.TW',
  },
  {
    value: '4743.T',
    label: '4743.T,  ITFOR Inc.',
    name: 'ITFOR Inc.',
    symbol: '4743.T',
  },
  {
    value: '4743.TWO',
    label: '4743.TWO,  Oneness Biotech Co., Ltd.',
    name: 'Oneness Biotech Co., Ltd.',
    symbol: '4743.TWO',
  },
  {
    value: '4745.T',
    label: '4745.T,  Tokyo Individualized Educational Institute, Inc.',
    name: 'Tokyo Individualized Educational Institute, Inc.',
    symbol: '4745.T',
  },
  {
    value: '4746.TW',
    label: '4746.TW,  Formosa Laboratories, Inc.',
    name: 'Formosa Laboratories, Inc.',
    symbol: '4746.TW',
  },
  {
    value: '4751.T',
    label: '4751.T,  CyberAgent, Inc.',
    name: 'CyberAgent, Inc.',
    symbol: '4751.T',
  },
  {
    value: '4755.T',
    label: '4755.T,  Rakuten Group, Inc.',
    name: 'Rakuten Group, Inc.',
    symbol: '4755.T',
  },
  {
    value: '4763.T',
    label: '4763.T,  CREEK & RIVER Co., Ltd.',
    name: 'CREEK & RIVER Co., Ltd.',
    symbol: '4763.T',
  },
  {
    value: '4764.TW',
    label: '4764.TW,  Double Bond Chemical Ind. Co., Ltd.',
    name: 'Double Bond Chemical Ind. Co., Ltd.',
    symbol: '4764.TW',
  },
  {
    value: '4765.T',
    label: '4765.T,  Morningstar Japan K.K.',
    name: 'Morningstar Japan K.K.',
    symbol: '4765.T',
  },
  {
    value: '4766.TW',
    label: '4766.TW,  Nan Pao Resins Chemical Co., Ltd.',
    name: 'Nan Pao Resins Chemical Co., Ltd.',
    symbol: '4766.TW',
  },
  {
    value: '4768.T',
    label: '4768.T,  Otsuka Corporation',
    name: 'Otsuka Corporation',
    symbol: '4768.T',
  },
  {
    value: '4776.T',
    label: '4776.T,  Cybozu, Inc.',
    name: 'Cybozu, Inc.',
    symbol: '4776.T',
  },
  {
    value: '4792.T',
    label: '4792.T,  YAMADA Consulting Group Co.,Ltd.',
    name: 'YAMADA Consulting Group Co.,Ltd.',
    symbol: '4792.T',
  },
  {
    value: '4800.T',
    label: '4800.T,  Oricon Inc.',
    name: 'Oricon Inc.',
    symbol: '4800.T',
  },
  {
    value: '4812.T',
    label: '4812.T,  Information Services International-Dentsu, Ltd.',
    name: 'Information Services International-Dentsu, Ltd.',
    symbol: '4812.T',
  },
  {
    value: '4813.T',
    label: '4813.T,  Access Co., Ltd.',
    name: 'Access Co., Ltd.',
    symbol: '4813.T',
  },
  {
    value: '4816.T',
    label: '4816.T,  Toei Animation Co.,Ltd.',
    name: 'Toei Animation Co.,Ltd.',
    symbol: '4816.T',
  },
  {
    value: '4819.T',
    label: '4819.T,  Digital Garage, Inc.',
    name: 'Digital Garage, Inc.',
    symbol: '4819.T',
  },
  {
    value: '4820.T',
    label: '4820.T,  EM Systems Co., Ltd.',
    name: 'EM Systems Co., Ltd.',
    symbol: '4820.T',
  },
  {
    value: '4825.T',
    label: '4825.T,  Weathernews Inc.',
    name: 'Weathernews Inc.',
    symbol: '4825.T',
  },
  {
    value: '4839.T',
    label: '4839.T,  Wowow Inc.',
    name: 'Wowow Inc.',
    symbol: '4839.T',
  },
  {
    value: '4847.T',
    label: '4847.T,  Intelligent Wave Inc.',
    name: 'Intelligent Wave Inc.',
    symbol: '4847.T',
  },
  {
    value: '4848.T',
    label: '4848.T,  Fullcast Holdings Co., Ltd.',
    name: 'Fullcast Holdings Co., Ltd.',
    symbol: '4848.T',
  },
  {
    value: '4849.T',
    label: '4849.T,  en-japan inc.',
    name: 'en-japan inc.',
    symbol: '4849.T',
  },
  {
    value: '4880.T',
    label: '4880.T,  CellSource Co., Ltd.',
    name: 'CellSource Co., Ltd.',
    symbol: '4880.T',
  },
  {
    value: '4886.T',
    label: '4886.T,  ASKA Pharmaceutical Holdings Co.,Ltd.',
    name: 'ASKA Pharmaceutical Holdings Co.,Ltd.',
    symbol: '4886.T',
  },
  {
    value: '4887.T',
    label: '4887.T,  Sawai Group Holdings Co., Ltd.',
    name: 'Sawai Group Holdings Co., Ltd.',
    symbol: '4887.T',
  },
  {
    value: '4901.T',
    label: '4901.T,  FUJIFILM Holdings Corporation',
    name: 'FUJIFILM Holdings Corporation',
    symbol: '4901.T',
  },
  {
    value: '4902.T',
    label: '4902.T,  Konica Minolta, Inc.',
    name: 'Konica Minolta, Inc.',
    symbol: '4902.T',
  },
  {
    value: '4904.TW',
    label: '4904.TW,  Far EasTone Telecommunications Co., Ltd.',
    name: 'Far EasTone Telecommunications Co., Ltd.',
    symbol: '4904.TW',
  },
  {
    value: '4906.TW',
    label: '4906.TW,  Gemtek Technology Co., Ltd.',
    name: 'Gemtek Technology Co., Ltd.',
    symbol: '4906.TW',
  },
  {
    value: '4911.T',
    label: '4911.T,  Shiseido Company, Limited',
    name: 'Shiseido Company, Limited',
    symbol: '4911.T',
  },
  {
    value: '4912.T',
    label: '4912.T,  Lion Corporation',
    name: 'Lion Corporation',
    symbol: '4912.T',
  },
  {
    value: '4912.TW',
    label: '4912.TW,  Lemtech Holdings Co., Limited',
    name: 'Lemtech Holdings Co., Limited',
    symbol: '4912.TW',
  },
  {
    value: '4914.T',
    label: '4914.T,  Takasago International Corporation',
    name: 'Takasago International Corporation',
    symbol: '4914.T',
  },
  {
    value: '4915.TW',
    label: '4915.TW,  Primax Electronics Ltd.',
    name: 'Primax Electronics Ltd.',
    symbol: '4915.TW',
  },
  {
    value: '4917.T',
    label: '4917.T,  Mandom Corporation',
    name: 'Mandom Corporation',
    symbol: '4917.T',
  },
  {
    value: '4919.T',
    label: '4919.T,  Milbon Co., Ltd.',
    name: 'Milbon Co., Ltd.',
    symbol: '4919.T',
  },
  {
    value: '4919.TW',
    label: '4919.TW,  Nuvoton Technology Corporation',
    name: 'Nuvoton Technology Corporation',
    symbol: '4919.TW',
  },
  {
    value: '4921.T',
    label: '4921.T,  Fancl Corporation',
    name: 'Fancl Corporation',
    symbol: '4921.T',
  },
  {
    value: '4922.T',
    label: '4922.T,  KOSÉ Corporation',
    name: 'KOSÉ Corporation',
    symbol: '4922.T',
  },
  {
    value: '4927.T',
    label: '4927.T,  Pola Orbis Holdings Inc.',
    name: 'Pola Orbis Holdings Inc.',
    symbol: '4927.T',
  },
  {
    value: '4927.TW',
    label: '4927.TW,  Apex International Co., Ltd.',
    name: 'Apex International Co., Ltd.',
    symbol: '4927.TW',
  },
  {
    value: '4928.T',
    label: '4928.T,  Noevir Holdings Co., Ltd.',
    name: 'Noevir Holdings Co., Ltd.',
    symbol: '4928.T',
  },
  {
    value: '4931.T',
    label: '4931.T,  Shinnihonseiyaku Co., Ltd.',
    name: 'Shinnihonseiyaku Co., Ltd.',
    symbol: '4931.T',
  },
  {
    value: '4933.TWO',
    label: '4933.TWO,  Ubright Optronics Corporation',
    name: 'Ubright Optronics Corporation',
    symbol: '4933.TWO',
  },
  {
    value: '4934.TW',
    label: '4934.TW,  Tainergy Tech Co., Ltd.',
    name: 'Tainergy Tech Co., Ltd.',
    symbol: '4934.TW',
  },
  {
    value: '4935.TW',
    label: '4935.TW,  Global Lighting Technologies Inc.',
    name: 'Global Lighting Technologies Inc.',
    symbol: '4935.TW',
  },
  {
    value: '4938.TW',
    label: '4938.TW,  Pegatron Corporation',
    name: 'Pegatron Corporation',
    symbol: '4938.TW',
  },
  {
    value: '4942.TW',
    label: '4942.TW,  Chia Chang Co., Ltd',
    name: 'Chia Chang Co., Ltd',
    symbol: '4942.TW',
  },
  {
    value: '4943.TW',
    label: '4943.TW,  Concraft Holding Co., Ltd.',
    name: 'Concraft Holding Co., Ltd.',
    symbol: '4943.TW',
  },
  {
    value: '4951.T',
    label: '4951.T,  S.T. Corporation',
    name: 'S.T. Corporation',
    symbol: '4951.T',
  },
  {
    value: '4953.TWO',
    label: '4953.TWO,  Wistron Information Technology and Services Corporation',
    name: 'Wistron Information Technology and Services Corporation',
    symbol: '4953.TWO',
  },
  {
    value: '4956.T',
    label: '4956.T,  Konishi Co., Ltd.',
    name: 'Konishi Co., Ltd.',
    symbol: '4956.T',
  },
  {
    value: '4958.T',
    label: '4958.T,  T. Hasegawa Co., Ltd.',
    name: 'T. Hasegawa Co., Ltd.',
    symbol: '4958.T',
  },
  {
    value: '4958.TW',
    label: '4958.TW,  Zhen Ding Technology Holding Limited',
    name: 'Zhen Ding Technology Holding Limited',
    symbol: '4958.TW',
  },
  {
    value: '4960.TW',
    label: '4960.TW,  Cheng Mei Materials Technology Corporation',
    name: 'Cheng Mei Materials Technology Corporation',
    symbol: '4960.TW',
  },
  {
    value: '4961.TW',
    label: '4961.TW,  Fitipower Integrated Technology Inc.',
    name: 'Fitipower Integrated Technology Inc.',
    symbol: '4961.TW',
  },
  {
    value: '4963.T',
    label: '4963.T,  Seiko PMC Corporation',
    name: 'Seiko PMC Corporation',
    symbol: '4963.T',
  },
  {
    value: '4966.T',
    label: '4966.T,  C.Uyemura & Co.,Ltd.',
    name: 'C.Uyemura & Co.,Ltd.',
    symbol: '4966.T',
  },
  {
    value: '4966.TWO',
    label: '4966.TWO,  Parade Technologies, Ltd.',
    name: 'Parade Technologies, Ltd.',
    symbol: '4966.TWO',
  },
  {
    value: '4967.T',
    label: '4967.T,  Kobayashi Pharmaceutical Co., Ltd.',
    name: 'Kobayashi Pharmaceutical Co., Ltd.',
    symbol: '4967.T',
  },
  {
    value: '4968.T',
    label: '4968.T,  Arakawa Chemical Industries, Ltd.',
    name: 'Arakawa Chemical Industries, Ltd.',
    symbol: '4968.T',
  },
  {
    value: '4968.TW',
    label: '4968.TW,  RichWave Technology Corporation',
    name: 'RichWave Technology Corporation',
    symbol: '4968.TW',
  },
  {
    value: '4970.T',
    label: '4970.T,  Toyo Gosei Co.,Ltd.',
    name: 'Toyo Gosei Co.,Ltd.',
    symbol: '4970.T',
  },
  {
    value: '4971.T',
    label: '4971.T,  MEC Company Ltd.',
    name: 'MEC Company Ltd.',
    symbol: '4971.T',
  },
  {
    value: '4972.T',
    label: '4972.T,  Soken Chemical & Engineering Co., Ltd.',
    name: 'Soken Chemical & Engineering Co., Ltd.',
    symbol: '4972.T',
  },
  {
    value: '4974.T',
    label: '4974.T,  Takara Bio Inc.',
    name: 'Takara Bio Inc.',
    symbol: '4974.T',
  },
  {
    value: '4975.T',
    label: '4975.T,  JCU Corporation',
    name: 'JCU Corporation',
    symbol: '4975.T',
  },
  {
    value: '4976.TW',
    label: '4976.TW,  Calin Technology Co., Ltd.',
    name: 'Calin Technology Co., Ltd.',
    symbol: '4976.TW',
  },
  {
    value: '4978.T',
    label: '4978.T,  ReproCELL Incorporated',
    name: 'ReproCELL Incorporated',
    symbol: '4978.T',
  },
  {
    value: '4980.T',
    label: '4980.T,  Dexerials Corporation',
    name: 'Dexerials Corporation',
    symbol: '4980.T',
  },
  {
    value: '4985.T',
    label: '4985.T,  Earth Corporation',
    name: 'Earth Corporation',
    symbol: '4985.T',
  },
  {
    value: '4991.TWO',
    label: '4991.TWO,  GCS Holdings, Inc.',
    name: 'GCS Holdings, Inc.',
    symbol: '4991.TWO',
  },
  {
    value: '4992.T',
    label: '4992.T,  Hokko Chemical Industry Co., Ltd.',
    name: 'Hokko Chemical Industry Co., Ltd.',
    symbol: '4992.T',
  },
  {
    value: '4996.T',
    label: '4996.T,  Kumiai Chemical Industry Co., Ltd.',
    name: 'Kumiai Chemical Industry Co., Ltd.',
    symbol: '4996.T',
  },
  {
    value: '4997.T',
    label: '4997.T,  Nihon Nohyaku Co., Ltd.',
    name: 'Nihon Nohyaku Co., Ltd.',
    symbol: '4997.T',
  },
  {
    value: '4998.T',
    label: '4998.T,  Fumakilla Limited',
    name: 'Fumakilla Limited',
    symbol: '4998.T',
  },
  {
    value: '4999.TW',
    label: '4999.TW,  Sinher Technology Inc.',
    name: 'Sinher Technology Inc.',
    symbol: '4999.TW',
  },
  {
    value: '5007.TW',
    label: '5007.TW,  San Shing Fastech Corp.',
    name: 'San Shing Fastech Corp.',
    symbol: '5007.TW',
  },
  {
    value: '5008.T',
    label: '5008.T,  TOA Oil Co., Ltd.',
    name: 'TOA Oil Co., Ltd.',
    symbol: '5008.T',
  },
  {
    value: '5009.TWO',
    label: '5009.TWO,  Gloria Material Technology Corp.',
    name: 'Gloria Material Technology Corp.',
    symbol: '5009.TWO',
  },
  {
    value: '5011.T',
    label: '5011.T,  Nichireki Co., Ltd.',
    name: 'Nichireki Co., Ltd.',
    symbol: '5011.T',
  },
  {
    value: '5017.T',
    label: '5017.T,  Fuji Oil Company, Ltd.',
    name: 'Fuji Oil Company, Ltd.',
    symbol: '5017.T',
  },
  {
    value: '5019.T',
    label: '5019.T,  Idemitsu Kosan Co.,Ltd.',
    name: 'Idemitsu Kosan Co.,Ltd.',
    symbol: '5019.T',
  },
  {
    value: '5020.T',
    label: '5020.T,  ENEOS Holdings, Inc.',
    name: 'ENEOS Holdings, Inc.',
    symbol: '5020.T',
  },
  {
    value: '5021.T',
    label: '5021.T,  Cosmo Energy Holdings Co., Ltd.',
    name: 'Cosmo Energy Holdings Co., Ltd.',
    symbol: '5021.T',
  },
  {
    value: '5074.T',
    label: '5074.T,  Tess Holdings Co.,Ltd.',
    name: 'Tess Holdings Co.,Ltd.',
    symbol: '5074.T',
  },
  {
    value: '5076.T',
    label: '5076.T,  INFRONEER Holdings Inc.',
    name: 'INFRONEER Holdings Inc.',
    symbol: '5076.T',
  },
  {
    value: '5101.T',
    label: '5101.T,  The Yokohama Rubber Co., Ltd.',
    name: 'The Yokohama Rubber Co., Ltd.',
    symbol: '5101.T',
  },
  {
    value: '5105.T',
    label: '5105.T,  Toyo Tire Corporation',
    name: 'Toyo Tire Corporation',
    symbol: '5105.T',
  },
  {
    value: '5108.T',
    label: '5108.T,  Bridgestone Corporation',
    name: 'Bridgestone Corporation',
    symbol: '5108.T',
  },
  {
    value: '5110.SR',
    label: '5110.SR,  Saudi Electricity Company',
    name: 'Saudi Electricity Company',
    symbol: '5110.SR',
  },
  {
    value: '5110.T',
    label: '5110.T,  Sumitomo Rubber Industries, Ltd.',
    name: 'Sumitomo Rubber Industries, Ltd.',
    symbol: '5110.T',
  },
  {
    value: '5122.T',
    label: '5122.T,  Okamoto Industries, Inc.',
    name: 'Okamoto Industries, Inc.',
    symbol: '5122.T',
  },
  {
    value: '5142.T',
    label: '5142.T,  Achilles Corporation',
    name: 'Achilles Corporation',
    symbol: '5142.T',
  },
  {
    value: '5186.T',
    label: '5186.T,  Nitta Corporation',
    name: 'Nitta Corporation',
    symbol: '5186.T',
  },
  {
    value: '5191.T',
    label: '5191.T,  Sumitomo Riko Company Limited',
    name: 'Sumitomo Riko Company Limited',
    symbol: '5191.T',
  },
  {
    value: '5192.T',
    label: '5192.T,  Mitsuboshi Belting Ltd.',
    name: 'Mitsuboshi Belting Ltd.',
    symbol: '5192.T',
  },
  {
    value: '5195.T',
    label: '5195.T,  Bando Chemical Industries, Ltd.',
    name: 'Bando Chemical Industries, Ltd.',
    symbol: '5195.T',
  },
  {
    value: '5201.T',
    label: '5201.T,  AGC Inc.',
    name: 'AGC Inc.',
    symbol: '5201.T',
  },
  {
    value: '5202.T',
    label: '5202.T,  Nippon Sheet Glass Company, Limited',
    name: 'Nippon Sheet Glass Company, Limited',
    symbol: '5202.T',
  },
  {
    value: '5203.TW',
    label: '5203.TW,  CyberLink Corp.',
    name: 'CyberLink Corp.',
    symbol: '5203.TW',
  },
  {
    value: '5208.T',
    label: '5208.T,  Arisawa Mfg. Co., Ltd.',
    name: 'Arisawa Mfg. Co., Ltd.',
    symbol: '5208.T',
  },
  {
    value: '5213.TWO',
    label: '5213.TWO,  YeaShin International Development Co., Ltd.',
    name: 'YeaShin International Development Co., Ltd.',
    symbol: '5213.TWO',
  },
  {
    value: '5214.T',
    label: '5214.T,  Nippon Electric Glass Co., Ltd.',
    name: 'Nippon Electric Glass Co., Ltd.',
    symbol: '5214.T',
  },
  {
    value: '5218.T',
    label: '5218.T,  Ohara Inc.',
    name: 'Ohara Inc.',
    symbol: '5218.T',
  },
  {
    value: '5232.T',
    label: '5232.T,  Sumitomo Osaka Cement Co., Ltd.',
    name: 'Sumitomo Osaka Cement Co., Ltd.',
    symbol: '5232.T',
  },
  {
    value: '5233.T',
    label: '5233.T,  Taiheiyo Cement Corporation',
    name: 'Taiheiyo Cement Corporation',
    symbol: '5233.T',
  },
  {
    value: '5234.TW',
    label: '5234.TW,  Daxin Materials Corporation',
    name: 'Daxin Materials Corporation',
    symbol: '5234.TW',
  },
  {
    value: '5243.TW',
    label: '5243.TW,  Eson Precision Ind. Co., Ltd.',
    name: 'Eson Precision Ind. Co., Ltd.',
    symbol: '5243.TW',
  },
  {
    value: '5262.T',
    label: '5262.T,  Nippon Hume Corporation',
    name: 'Nippon Hume Corporation',
    symbol: '5262.T',
  },
  {
    value: '5269.T',
    label: '5269.T,  Nippon Concrete Industries Co., Ltd.',
    name: 'Nippon Concrete Industries Co., Ltd.',
    symbol: '5269.T',
  },
  {
    value: '5269.TW',
    label: '5269.TW,  ASMedia Technology Inc.',
    name: 'ASMedia Technology Inc.',
    symbol: '5269.TW',
  },
  {
    value: '5273.T',
    label: '5273.T,  Mitani Sekisan Co., Ltd.',
    name: 'Mitani Sekisan Co., Ltd.',
    symbol: '5273.T',
  },
  {
    value: '5274.TWO',
    label: '5274.TWO,  ASPEED Technology Inc.',
    name: 'ASPEED Technology Inc.',
    symbol: '5274.TWO',
  },
  {
    value: '5283.TW',
    label: '5283.TW,  Heran Co., Ltd.',
    name: 'Heran Co., Ltd.',
    symbol: '5283.TW',
  },
  {
    value: '5285.TW',
    label: '5285.TW,  Jih Lin Technology Co., Ltd.',
    name: 'Jih Lin Technology Co., Ltd.',
    symbol: '5285.TW',
  },
  {
    value: '5288.T',
    label: '5288.T,  Asia Pile Holdings Corporation',
    name: 'Asia Pile Holdings Corporation',
    symbol: '5288.T',
  },
  {
    value: '5288.TW',
    label: '5288.TW,  Eurocharm Holdings Co., Ltd.',
    name: 'Eurocharm Holdings Co., Ltd.',
    symbol: '5288.TW',
  },
  {
    value: '5289.TWO',
    label: '5289.TWO,  Innodisk Corporation',
    name: 'Innodisk Corporation',
    symbol: '5289.TWO',
  },
  {
    value: '5290.T',
    label: '5290.T,  Vertex Corporation',
    name: 'Vertex Corporation',
    symbol: '5290.T',
  },
  {
    value: '5299.TWO',
    label: '5299.TWO,  Excelliance MOS Corporation',
    name: 'Excelliance MOS Corporation',
    symbol: '5299.TWO',
  },
  {
    value: '5301.T',
    label: '5301.T,  Tokai Carbon Co., Ltd.',
    name: 'Tokai Carbon Co., Ltd.',
    symbol: '5301.T',
  },
  {
    value: '5302.T',
    label: '5302.T,  Nippon Carbon Co., Ltd.',
    name: 'Nippon Carbon Co., Ltd.',
    symbol: '5302.T',
  },
  {
    value: '5304.T',
    label: '5304.T,  Sec Carbon, Limited',
    name: 'Sec Carbon, Limited',
    symbol: '5304.T',
  },
  {
    value: '5310.T',
    label: '5310.T,  Toyo Tanso Co., Ltd.',
    name: 'Toyo Tanso Co., Ltd.',
    symbol: '5310.T',
  },
  {
    value: '5331.T',
    label: '5331.T,  Noritake Co., Limited',
    name: 'Noritake Co., Limited',
    symbol: '5331.T',
  },
  {
    value: '5332.T',
    label: '5332.T,  Toto Ltd.',
    name: 'Toto Ltd.',
    symbol: '5332.T',
  },
  {
    value: '5333.T',
    label: '5333.T,  NGK Insulators, Ltd.',
    name: 'NGK Insulators, Ltd.',
    symbol: '5333.T',
  },
  {
    value: '5334.T',
    label: '5334.T,  NGK Spark Plug Co., Ltd.',
    name: 'NGK Spark Plug Co., Ltd.',
    symbol: '5334.T',
  },
  {
    value: '5344.T',
    label: '5344.T,  Maruwa Co., Ltd.',
    name: 'Maruwa Co., Ltd.',
    symbol: '5344.T',
  },
  {
    value: '5347.TWO',
    label: '5347.TWO,  Vanguard International Semiconductor Corporation',
    name: 'Vanguard International Semiconductor Corporation',
    symbol: '5347.TWO',
  },
  {
    value: '5351.T',
    label: '5351.T,  Shinagawa Refractories Co., Ltd.',
    name: 'Shinagawa Refractories Co., Ltd.',
    symbol: '5351.T',
  },
  {
    value: '5352.T',
    label: '5352.T,  Krosaki Harima Corporation',
    name: 'Krosaki Harima Corporation',
    symbol: '5352.T',
  },
  {
    value: '5357.T',
    label: '5357.T,  Yotai Refractories Co., Ltd.',
    name: 'Yotai Refractories Co., Ltd.',
    symbol: '5357.T',
  },
  {
    value: '5371.TWO',
    label: '5371.TWO,  Coretronic Corporation',
    name: 'Coretronic Corporation',
    symbol: '5371.TWO',
  },
  {
    value: '5384.T',
    label: '5384.T,  Fujimi Incorporated',
    name: 'Fujimi Incorporated',
    symbol: '5384.T',
  },
  {
    value: '5388.TW',
    label: '5388.TW,  Sercomm Corporation',
    name: 'Sercomm Corporation',
    symbol: '5388.TW',
  },
  {
    value: '5392.TWO',
    label: '5392.TWO,  AVY Precision Technology INC.',
    name: 'AVY Precision Technology INC.',
    symbol: '5392.TWO',
  },
  {
    value: '5393.T',
    label: '5393.T,  Nichias Corporation',
    name: 'Nichias Corporation',
    symbol: '5393.T',
  },
  {
    value: '5401.T',
    label: '5401.T,  Nippon Steel Corporation',
    name: 'Nippon Steel Corporation',
    symbol: '5401.T',
  },
  {
    value: '5406.T',
    label: '5406.T,  Kobe Steel, Ltd.',
    name: 'Kobe Steel, Ltd.',
    symbol: '5406.T',
  },
  {
    value: '5408.T',
    label: '5408.T,  Nakayama Steel Works, Ltd.',
    name: 'Nakayama Steel Works, Ltd.',
    symbol: '5408.T',
  },
  {
    value: '5410.T',
    label: '5410.T,  Godo Steel, Ltd.',
    name: 'Godo Steel, Ltd.',
    symbol: '5410.T',
  },
  {
    value: '5411.T',
    label: '5411.T,  JFE Holdings, Inc.',
    name: 'JFE Holdings, Inc.',
    symbol: '5411.T',
  },
  {
    value: '5423.T',
    label: '5423.T,  Tokyo Steel Manufacturing Co., Ltd.',
    name: 'Tokyo Steel Manufacturing Co., Ltd.',
    symbol: '5423.T',
  },
  {
    value: '5425.TWO',
    label: '5425.TWO,  Taiwan Semiconductor Co., Ltd.',
    name: 'Taiwan Semiconductor Co., Ltd.',
    symbol: '5425.TWO',
  },
  {
    value: '5434.TW',
    label: '5434.TW,  Topco Scientific Co.,Ltd.',
    name: 'Topco Scientific Co.,Ltd.',
    symbol: '5434.TW',
  },
  {
    value: '5439.TWO',
    label: '5439.TWO,  First Hi-tec Enterprise Co., Ltd.',
    name: 'First Hi-tec Enterprise Co., Ltd.',
    symbol: '5439.TWO',
  },
  {
    value: '5440.T',
    label: '5440.T,  Kyoei Steel Ltd.',
    name: 'Kyoei Steel Ltd.',
    symbol: '5440.T',
  },
  {
    value: '5444.T',
    label: '5444.T,  Yamato Kogyo Co., Ltd.',
    name: 'Yamato Kogyo Co., Ltd.',
    symbol: '5444.T',
  },
  {
    value: '5445.T',
    label: '5445.T,  Tokyo Tekko Co., Ltd.',
    name: 'Tokyo Tekko Co., Ltd.',
    symbol: '5445.T',
  },
  {
    value: '5449.T',
    label: '5449.T,  Osaka Steel Co., Ltd.',
    name: 'Osaka Steel Co., Ltd.',
    symbol: '5449.T',
  },
  {
    value: '5451.T',
    label: '5451.T,  Yodogawa Steel Works, Ltd.',
    name: 'Yodogawa Steel Works, Ltd.',
    symbol: '5451.T',
  },
  {
    value: '5452.TWO',
    label: '5452.TWO,  UNIC Technology Corp.',
    name: 'UNIC Technology Corp.',
    symbol: '5452.TWO',
  },
  {
    value: '5457.TWO',
    label: '5457.TWO,  Speed Tech Corp.',
    name: 'Speed Tech Corp.',
    symbol: '5457.TWO',
  },
  {
    value: '5463.T',
    label: '5463.T,  Maruichi Steel Tube Ltd.',
    name: 'Maruichi Steel Tube Ltd.',
    symbol: '5463.T',
  },
  {
    value: '5469.TW',
    label: '5469.TW,  HannStar Board Corporation',
    name: 'HannStar Board Corporation',
    symbol: '5469.TW',
  },
  {
    value: '5471.T',
    label: '5471.T,  Daido Steel Co., Ltd.',
    name: 'Daido Steel Co., Ltd.',
    symbol: '5471.T',
  },
  {
    value: '5471.TW',
    label: '5471.TW,  Sonix Technology Co.,Ltd.',
    name: 'Sonix Technology Co.,Ltd.',
    symbol: '5471.TW',
  },
  {
    value: '5478.TWO',
    label: '5478.TWO,  Soft-World International Corporation',
    name: 'Soft-World International Corporation',
    symbol: '5478.TWO',
  },
  {
    value: '5480.T',
    label: '5480.T,  Nippon Yakin Kogyo Co., Ltd.',
    name: 'Nippon Yakin Kogyo Co., Ltd.',
    symbol: '5480.T',
  },
  {
    value: '5481.T',
    label: '5481.T,  Sanyo Special Steel Co., Ltd.',
    name: 'Sanyo Special Steel Co., Ltd.',
    symbol: '5481.T',
  },
  {
    value: '5482.T',
    label: '5482.T,  Aichi Steel Corporation',
    name: 'Aichi Steel Corporation',
    symbol: '5482.T',
  },
  {
    value: '5483.TWO',
    label: '5483.TWO,  Sino-American Silicon Products Inc.',
    name: 'Sino-American Silicon Products Inc.',
    symbol: '5483.TWO',
  },
  {
    value: '5486.T',
    label: '5486.T,  Hitachi Metals, Ltd.',
    name: 'Hitachi Metals, Ltd.',
    symbol: '5486.T',
  },
  {
    value: '5512.TWO',
    label: '5512.TWO,  Rich Development Co., Ltd.',
    name: 'Rich Development Co., Ltd.',
    symbol: '5512.TWO',
  },
  {
    value: '5519.TW',
    label: '5519.TW,  LongDa Construction & Development Corporation',
    name: 'LongDa Construction & Development Corporation',
    symbol: '5519.TW',
  },
  {
    value: '5521.TW',
    label: '5521.TW,  Kung Sing Engineering Corporation',
    name: 'Kung Sing Engineering Corporation',
    symbol: '5521.TW',
  },
  {
    value: '5522.TW',
    label: '5522.TW,  Farglory Land Development Co., Ltd.',
    name: 'Farglory Land Development Co., Ltd.',
    symbol: '5522.TW',
  },
  {
    value: '5525.TW',
    label: '5525.TW,  Sweeten Real Estate Development Co.,Ltd.',
    name: 'Sweeten Real Estate Development Co.,Ltd.',
    symbol: '5525.TW',
  },
  {
    value: '5530.TWO',
    label: '5530.TWO,  Lungyen Life Service Corporation',
    name: 'Lungyen Life Service Corporation',
    symbol: '5530.TWO',
  },
  {
    value: '5531.TW',
    label: '5531.TW,  Shining Building Business Co. Ltd.',
    name: 'Shining Building Business Co. Ltd.',
    symbol: '5531.TW',
  },
  {
    value: '5533.TW',
    label: '5533.TW,  Founding Construction Development Corp.',
    name: 'Founding Construction Development Corp.',
    symbol: '5533.TW',
  },
  {
    value: '5534.TW',
    label: '5534.TW,  Chong Hong Construction Co., Ltd.',
    name: 'Chong Hong Construction Co., Ltd.',
    symbol: '5534.TW',
  },
  {
    value: '5536.TWO',
    label: '5536.TWO,  Acter Group Corporation Limited',
    name: 'Acter Group Corporation Limited',
    symbol: '5536.TWO',
  },
  {
    value: '5541.T',
    label: '5541.T,  Pacific Metals Co., Ltd.',
    name: 'Pacific Metals Co., Ltd.',
    symbol: '5541.T',
  },
  {
    value: '5563.T',
    label: '5563.T,  Nippon Denko Co., Ltd.',
    name: 'Nippon Denko Co., Ltd.',
    symbol: '5563.T',
  },
  {
    value: '5602.T',
    label: '5602.T,  Kurimoto, Ltd.',
    name: 'Kurimoto, Ltd.',
    symbol: '5602.T',
  },
  {
    value: '5607.TW',
    label: '5607.TW,  Farglory Free Trade Zone Investment Holding Co., Ltd.',
    name: 'Farglory Free Trade Zone Investment Holding Co., Ltd.',
    symbol: '5607.TW',
  },
  {
    value: '5608.TW',
    label: '5608.TW,  Shih Wei Navigation Co., Ltd.',
    name: 'Shih Wei Navigation Co., Ltd.',
    symbol: '5608.TW',
  },
  {
    value: '5609.TWO',
    label: '5609.TWO,  Dimerco Express Corporation',
    name: 'Dimerco Express Corporation',
    symbol: '5609.TWO',
  },
  {
    value: '5631.T',
    label: '5631.T,  The Japan Steel Works, Ltd.',
    name: 'The Japan Steel Works, Ltd.',
    symbol: '5631.T',
  },
  {
    value: '5702.T',
    label: '5702.T,  Daiki Aluminium Industry Co., Ltd.',
    name: 'Daiki Aluminium Industry Co., Ltd.',
    symbol: '5702.T',
  },
  {
    value: '5703.T',
    label: '5703.T,  Nippon Light Metal Holdings Company, Ltd.',
    name: 'Nippon Light Metal Holdings Company, Ltd.',
    symbol: '5703.T',
  },
  {
    value: '5706.T',
    label: '5706.T,  Mitsui Mining & Smelting Co., Ltd.',
    name: 'Mitsui Mining & Smelting Co., Ltd.',
    symbol: '5706.T',
  },
  {
    value: '5707.T',
    label: '5707.T,  Toho Zinc Co., Ltd.',
    name: 'Toho Zinc Co., Ltd.',
    symbol: '5707.T',
  },
  {
    value: '5711.T',
    label: '5711.T,  Mitsubishi Materials Corporation',
    name: 'Mitsubishi Materials Corporation',
    symbol: '5711.T',
  },
  {
    value: '5713.T',
    label: '5713.T,  Sumitomo Metal Mining Co., Ltd.',
    name: 'Sumitomo Metal Mining Co., Ltd.',
    symbol: '5713.T',
  },
  {
    value: '5714.T',
    label: '5714.T,  Dowa Holdings Co., Ltd.',
    name: 'Dowa Holdings Co., Ltd.',
    symbol: '5714.T',
  },
  {
    value: '5715.T',
    label: '5715.T,  Furukawa Co.,Ltd.',
    name: 'Furukawa Co.,Ltd.',
    symbol: '5715.T',
  },
  {
    value: '5726.T',
    label: '5726.T,  OSAKA Titanium technologies Co.,Ltd.',
    name: 'OSAKA Titanium technologies Co.,Ltd.',
    symbol: '5726.T',
  },
  {
    value: '5727.T',
    label: '5727.T,  Toho Titanium Co., Ltd.',
    name: 'Toho Titanium Co., Ltd.',
    symbol: '5727.T',
  },
  {
    value: '5741.T',
    label: '5741.T,  UACJ Corporation',
    name: 'UACJ Corporation',
    symbol: '5741.T',
  },
  {
    value: '5801.T',
    label: '5801.T,  Furukawa Electric Co., Ltd.',
    name: 'Furukawa Electric Co., Ltd.',
    symbol: '5801.T',
  },
  {
    value: '5802.T',
    label: '5802.T,  Sumitomo Electric Industries, Ltd.',
    name: 'Sumitomo Electric Industries, Ltd.',
    symbol: '5802.T',
  },
  {
    value: '5803.T',
    label: '5803.T,  Fujikura Ltd.',
    name: 'Fujikura Ltd.',
    symbol: '5803.T',
  },
  {
    value: '5805.T',
    label: '5805.T,  SWCC Showa Holdings Co., Ltd.',
    name: 'SWCC Showa Holdings Co., Ltd.',
    symbol: '5805.T',
  },
  {
    value: '5809.T',
    label: '5809.T,  TATSUTA Electric Wire and Cable Co., Ltd.',
    name: 'TATSUTA Electric Wire and Cable Co., Ltd.',
    symbol: '5809.T',
  },
  {
    value: '5821.T',
    label: '5821.T,  Hirakawa Hewtech Corp.',
    name: 'Hirakawa Hewtech Corp.',
    symbol: '5821.T',
  },
  {
    value: '5851.T',
    label: '5851.T,  Ryobi Limited',
    name: 'Ryobi Limited',
    symbol: '5851.T',
  },
  {
    value: '5857.T',
    label: '5857.T,  Asahi Holdings, Inc.',
    name: 'Asahi Holdings, Inc.',
    symbol: '5857.T',
  },
  {
    value: '5864.TWO',
    label: '5864.TWO,  Concord International Securities Co., Ltd',
    name: 'Concord International Securities Co., Ltd',
    symbol: '5864.TWO',
  },
  {
    value: '5871.TW',
    label: '5871.TW,  Chailease Holding Company Limited',
    name: 'Chailease Holding Company Limited',
    symbol: '5871.TW',
  },
  {
    value: '5876.TW',
    label: '5876.TW,  The Shanghai Commercial & Savings Bank, Ltd.',
    name: 'The Shanghai Commercial & Savings Bank, Ltd.',
    symbol: '5876.TW',
  },
  {
    value: '5880.TW',
    label: '5880.TW,  Taiwan Cooperative Financial Holding Co., Ltd.',
    name: 'Taiwan Cooperative Financial Holding Co., Ltd.',
    symbol: '5880.TW',
  },
  {
    value: '5901.T',
    label: '5901.T,  Toyo Seikan Group Holdings, Ltd.',
    name: 'Toyo Seikan Group Holdings, Ltd.',
    symbol: '5901.T',
  },
  {
    value: '5902.T',
    label: '5902.T,  Hokkan Holdings Limited',
    name: 'Hokkan Holdings Limited',
    symbol: '5902.T',
  },
  {
    value: '5903.TWO',
    label: '5903.TWO,  Taiwan FamilyMart Co., Ltd.',
    name: 'Taiwan FamilyMart Co., Ltd.',
    symbol: '5903.TWO',
  },
  {
    value: '5904.TWO',
    label: '5904.TWO,  POYA International Co., Ltd.',
    name: 'POYA International Co., Ltd.',
    symbol: '5904.TWO',
  },
  {
    value: '5907.TW',
    label: '5907.TW,  Grand Ocean Retail Group Ltd.',
    name: 'Grand Ocean Retail Group Ltd.',
    symbol: '5907.TW',
  },
  {
    value: '5911.T',
    label: '5911.T,  Yokogawa Bridge Holdings Corp.',
    name: 'Yokogawa Bridge Holdings Corp.',
    symbol: '5911.T',
  },
  {
    value: '5929.T',
    label: '5929.T,  Sanwa Holdings Corporation',
    name: 'Sanwa Holdings Corporation',
    symbol: '5929.T',
  },
  {
    value: '5930.T',
    label: '5930.T,  Bunka Shutter Co., Ltd.',
    name: 'Bunka Shutter Co., Ltd.',
    symbol: '5930.T',
  },
  {
    value: '5932.T',
    label: '5932.T,  Sankyo Tateyama, Inc.',
    name: 'Sankyo Tateyama, Inc.',
    symbol: '5932.T',
  },
  {
    value: '5933.T',
    label: '5933.T,  Alinco Incorporated',
    name: 'Alinco Incorporated',
    symbol: '5933.T',
  },
  {
    value: '5938.T',
    label: '5938.T,  LIXIL Corporation',
    name: 'LIXIL Corporation',
    symbol: '5938.T',
  },
  {
    value: '5943.T',
    label: '5943.T,  Noritz Corporation',
    name: 'Noritz Corporation',
    symbol: '5943.T',
  },
  {
    value: '5946.T',
    label: '5946.T,  Chofu Seisakusho Co., Ltd.',
    name: 'Chofu Seisakusho Co., Ltd.',
    symbol: '5946.T',
  },
  {
    value: '5947.T',
    label: '5947.T,  Rinnai Corporation',
    name: 'Rinnai Corporation',
    symbol: '5947.T',
  },
  {
    value: '5949.T',
    label: '5949.T,  Unipres Corporation',
    name: 'Unipres Corporation',
    symbol: '5949.T',
  },
  {
    value: '5957.T',
    label: '5957.T,  Nittoseiko Co.,Ltd.',
    name: 'Nittoseiko Co.,Ltd.',
    symbol: '5957.T',
  },
  {
    value: '5959.T',
    label: '5959.T,  Okabe Co., Ltd.',
    name: 'Okabe Co., Ltd.',
    symbol: '5959.T',
  },
  {
    value: '5970.T',
    label: '5970.T,  G-Tekt Corporation',
    name: 'G-Tekt Corporation',
    symbol: '5970.T',
  },
  {
    value: '5975.T',
    label: '5975.T,  Topre Corporation',
    name: 'Topre Corporation',
    symbol: '5975.T',
  },
  {
    value: '5976.T',
    label: '5976.T,  Neturen Co., Ltd.',
    name: 'Neturen Co., Ltd.',
    symbol: '5976.T',
  },
  {
    value: '5981.T',
    label: '5981.T,  Tokyo Rope Mfg. Co., Ltd.',
    name: 'Tokyo Rope Mfg. Co., Ltd.',
    symbol: '5981.T',
  },
  {
    value: '5988.T',
    label: '5988.T,  PIOLAX, Inc.',
    name: 'PIOLAX, Inc.',
    symbol: '5988.T',
  },
  {
    value: '5989.T',
    label: '5989.T,  H-One Co.,Ltd.',
    name: 'H-One Co.,Ltd.',
    symbol: '5989.T',
  },
  {
    value: '5991.T',
    label: '5991.T,  NHK Spring Co., Ltd.',
    name: 'NHK Spring Co., Ltd.',
    symbol: '5991.T',
  },
  {
    value: '600000.SS',
    label: '600000.SS,  Shanghai Pudong Development Bank Co., Ltd.',
    name: 'Shanghai Pudong Development Bank Co., Ltd.',
    symbol: '600000.SS',
  },
  {
    value: '600008.SS',
    label:
      '600008.SS,  Beijing Capital Eco-environment Protection Group Co.,ltd.',
    name: 'Beijing Capital Eco-environment Protection Group Co.,ltd.',
    symbol: '600008.SS',
  },
  {
    value: '600009.SS',
    label: '600009.SS,  Shanghai International Airport Co., Ltd.',
    name: 'Shanghai International Airport Co., Ltd.',
    symbol: '600009.SS',
  },
  {
    value: '600010.SS',
    label: '600010.SS,  Inner Mongolia Baotou Steel Union Co., Ltd.',
    name: 'Inner Mongolia Baotou Steel Union Co., Ltd.',
    symbol: '600010.SS',
  },
  {
    value: '600011.SS',
    label: '600011.SS,  Huaneng Power International, Inc.',
    name: 'Huaneng Power International, Inc.',
    symbol: '600011.SS',
  },
  {
    value: '600012.SS',
    label: '600012.SS,  Anhui Expressway Company Limited',
    name: 'Anhui Expressway Company Limited',
    symbol: '600012.SS',
  },
  {
    value: '600015.SS',
    label: '600015.SS,  Hua Xia Bank Co., Limited',
    name: 'Hua Xia Bank Co., Limited',
    symbol: '600015.SS',
  },
  {
    value: '600016.SS',
    label: '600016.SS,  China Minsheng Banking Corp., Ltd.',
    name: 'China Minsheng Banking Corp., Ltd.',
    symbol: '600016.SS',
  },
  {
    value: '600018.SS',
    label: '600018.SS,  Shanghai International Port (Group) Co., Ltd.',
    name: 'Shanghai International Port (Group) Co., Ltd.',
    symbol: '600018.SS',
  },
  {
    value: '600019.SS',
    label: '600019.SS,  Baoshan Iron & Steel Co., Ltd.',
    name: 'Baoshan Iron & Steel Co., Ltd.',
    symbol: '600019.SS',
  },
  {
    value: '600021.SS',
    label: '600021.SS,  Shanghai Electric Power Co., Ltd.',
    name: 'Shanghai Electric Power Co., Ltd.',
    symbol: '600021.SS',
  },
  {
    value: '600025.SS',
    label: '600025.SS,  Huaneng Lancang River Hydropower Inc.',
    name: 'Huaneng Lancang River Hydropower Inc.',
    symbol: '600025.SS',
  },
  {
    value: '600026.SS',
    label: '600026.SS,  COSCO SHIPPING Energy Transportation Co., Ltd.',
    name: 'COSCO SHIPPING Energy Transportation Co., Ltd.',
    symbol: '600026.SS',
  },
  {
    value: '600027.SS',
    label: '600027.SS,  Huadian Power International Corporation Limited',
    name: 'Huadian Power International Corporation Limited',
    symbol: '600027.SS',
  },
  {
    value: '600028.SS',
    label: '600028.SS,  China Petroleum & Chemical Corporation',
    name: 'China Petroleum & Chemical Corporation',
    symbol: '600028.SS',
  },
  {
    value: '600029.SS',
    label: '600029.SS,  China Southern Airlines Company Limited',
    name: 'China Southern Airlines Company Limited',
    symbol: '600029.SS',
  },
  {
    value: '600030.SS',
    label: '600030.SS,  CITIC Securities Company Limited',
    name: 'CITIC Securities Company Limited',
    symbol: '600030.SS',
  },
  {
    value: '600031.SS',
    label: '600031.SS,  Sany Heavy Industry Co., Ltd',
    name: 'Sany Heavy Industry Co., Ltd',
    symbol: '600031.SS',
  },
  {
    value: '600036.SS',
    label: '600036.SS,  China Merchants Bank Co., Ltd.',
    name: 'China Merchants Bank Co., Ltd.',
    symbol: '600036.SS',
  },
  {
    value: '600038.SS',
    label: '600038.SS,  Avicopter Plc',
    name: 'Avicopter Plc',
    symbol: '600038.SS',
  },
  {
    value: '600039.SS',
    label: '600039.SS,  Sichuan Road & Bridge Co.,Ltd',
    name: 'Sichuan Road & Bridge Co.,Ltd',
    symbol: '600039.SS',
  },
  {
    value: '600048.SS',
    label: '600048.SS,  Poly Developments and Holdings Group Co., Ltd.',
    name: 'Poly Developments and Holdings Group Co., Ltd.',
    symbol: '600048.SS',
  },
  {
    value: '600050.SS',
    label: '600050.SS,  China United Network Communications Limited',
    name: 'China United Network Communications Limited',
    symbol: '600050.SS',
  },
  {
    value: '600057.SS',
    label: '600057.SS,  Xiamen Xiangyu Co., Ltd.',
    name: 'Xiamen Xiangyu Co., Ltd.',
    symbol: '600057.SS',
  },
  {
    value: '600061.SS',
    label: '600061.SS,  SDIC Capital Co.,Ltd',
    name: 'SDIC Capital Co.,Ltd',
    symbol: '600061.SS',
  },
  {
    value: '600062.SS',
    label: '600062.SS,  China Resources Double-Crane Pharmaceutical Co.,Ltd.',
    name: 'China Resources Double-Crane Pharmaceutical Co.,Ltd.',
    symbol: '600062.SS',
  },
  {
    value: '600066.SS',
    label: '600066.SS,  Yutong Bus Co.,Ltd.',
    name: 'Yutong Bus Co.,Ltd.',
    symbol: '600066.SS',
  },
  {
    value: '600079.SS',
    label: '600079.SS,  Humanwell Healthcare (Group) Co.,Ltd.',
    name: 'Humanwell Healthcare (Group) Co.,Ltd.',
    symbol: '600079.SS',
  },
  {
    value: '600089.SS',
    label: '600089.SS,  TBEA Co., Ltd.',
    name: 'TBEA Co., Ltd.',
    symbol: '600089.SS',
  },
  {
    value: '600104.SS',
    label: '600104.SS,  SAIC Motor Corporation Limited',
    name: 'SAIC Motor Corporation Limited',
    symbol: '600104.SS',
  },
  {
    value: '600109.SS',
    label: '600109.SS,  Sinolink Securities Co., Ltd.',
    name: 'Sinolink Securities Co., Ltd.',
    symbol: '600109.SS',
  },
  {
    value: '600111.SS',
    label: '600111.SS,  China Northern Rare Earth (Group) High-Tech Co.,Ltd',
    name: 'China Northern Rare Earth (Group) High-Tech Co.,Ltd',
    symbol: '600111.SS',
  },
  {
    value: '600115.SS',
    label: '600115.SS,  China Eastern Airlines Corporation Limited',
    name: 'China Eastern Airlines Corporation Limited',
    symbol: '600115.SS',
  },
  {
    value: '600132.SS',
    label: '600132.SS,  Chongqing Brewery Co., Ltd.',
    name: 'Chongqing Brewery Co., Ltd.',
    symbol: '600132.SS',
  },
  {
    value: '600143.SS',
    label: '600143.SS,  Kingfa Sci. & Tech. Co., Ltd.',
    name: 'Kingfa Sci. & Tech. Co., Ltd.',
    symbol: '600143.SS',
  },
  {
    value: '600150.SS',
    label: '600150.SS,  China CSSC Holdings Limited',
    name: 'China CSSC Holdings Limited',
    symbol: '600150.SS',
  },
  {
    value: '600153.SS',
    label: '600153.SS,  Xiamen C&D Inc.',
    name: 'Xiamen C&D Inc.',
    symbol: '600153.SS',
  },
  {
    value: '600160.SS',
    label: '600160.SS,  Zhejiang Juhua Co., Ltd.',
    name: 'Zhejiang Juhua Co., Ltd.',
    symbol: '600160.SS',
  },
  {
    value: '600161.SS',
    label: '600161.SS,  Beijing Tiantan Biological Products Co., Ltd.',
    name: 'Beijing Tiantan Biological Products Co., Ltd.',
    symbol: '600161.SS',
  },
  {
    value: '600167.SS',
    label: '600167.SS,  Luenmei Quantum Co.,Ltd',
    name: 'Luenmei Quantum Co.,Ltd',
    symbol: '600167.SS',
  },
  {
    value: '600170.SS',
    label: '600170.SS,  Shanghai Construction Group Co., Ltd.',
    name: 'Shanghai Construction Group Co., Ltd.',
    symbol: '600170.SS',
  },
  {
    value: '600176.SS',
    label: '600176.SS,  China Jushi Co., Ltd.',
    name: 'China Jushi Co., Ltd.',
    symbol: '600176.SS',
  },
  {
    value: '600177.SS',
    label: '600177.SS,  Youngor Group Co.,Ltd',
    name: 'Youngor Group Co.,Ltd',
    symbol: '600177.SS',
  },
  {
    value: '600183.SS',
    label: '600183.SS,  Shengyi Technology Co.,Ltd.',
    name: 'Shengyi Technology Co.,Ltd.',
    symbol: '600183.SS',
  },
  {
    value: '600188.SS',
    label: '600188.SS,  Yankuang Energy Group Company Limited',
    name: 'Yankuang Energy Group Company Limited',
    symbol: '600188.SS',
  },
  {
    value: '600196.SS',
    label: '600196.SS,  Shanghai Fosun Pharmaceutical (Group) Co., Ltd.',
    name: 'Shanghai Fosun Pharmaceutical (Group) Co., Ltd.',
    symbol: '600196.SS',
  },
  {
    value: '6001.SR',
    label: '6001.SR,  Halwani Bros. Co. Ltd.',
    name: 'Halwani Bros. Co. Ltd.',
    symbol: '6001.SR',
  },
  {
    value: '600210.SS',
    label: '600210.SS,  Shanghai Zijiang Enterprise Group Co., Ltd.',
    name: 'Shanghai Zijiang Enterprise Group Co., Ltd.',
    symbol: '600210.SS',
  },
  {
    value: '600216.SS',
    label: '600216.SS,  Zhejiang Medicine Co., Ltd.',
    name: 'Zhejiang Medicine Co., Ltd.',
    symbol: '600216.SS',
  },
  {
    value: '600219.SS',
    label: '600219.SS,  Shandong Nanshan Aluminium Co.,Ltd.',
    name: 'Shandong Nanshan Aluminium Co.,Ltd.',
    symbol: '600219.SS',
  },
  {
    value: '600233.SS',
    label: '600233.SS,  YTO Express Group Co.,Ltd.',
    name: 'YTO Express Group Co.,Ltd.',
    symbol: '600233.SS',
  },
  {
    value: '600236.SS',
    label: '600236.SS,  Guangxi Guiguan Electric PowerCo.,Ltd.',
    name: 'Guangxi Guiguan Electric PowerCo.,Ltd.',
    symbol: '600236.SS',
  },
  {
    value: '600256.SS',
    label: '600256.SS,  Guanghui Energy Co., Ltd.',
    name: 'Guanghui Energy Co., Ltd.',
    symbol: '600256.SS',
  },
  {
    value: '600276.SS',
    label: '600276.SS,  Jiangsu Hengrui Medicine Co., Ltd.',
    name: 'Jiangsu Hengrui Medicine Co., Ltd.',
    symbol: '600276.SS',
  },
  {
    value: '600282.SS',
    label: '600282.SS,  Nanjing Iron & Steel Co., Ltd.',
    name: 'Nanjing Iron & Steel Co., Ltd.',
    symbol: '600282.SS',
  },
  {
    value: '600295.SS',
    label: '600295.SS,  Inner Mongolia ERDOS Resources Co.,Ltd.',
    name: 'Inner Mongolia ERDOS Resources Co.,Ltd.',
    symbol: '600295.SS',
  },
  {
    value: '600298.SS',
    label: '600298.SS,  Angel Yeast Co., Ltd',
    name: 'Angel Yeast Co., Ltd',
    symbol: '600298.SS',
  },
  {
    value: '6002.SR',
    label: '6002.SR,  Herfy Food Services Company',
    name: 'Herfy Food Services Company',
    symbol: '6002.SR',
  },
  {
    value: '600309.SS',
    label: '600309.SS,  Wanhua Chemical Group Co., Ltd.',
    name: 'Wanhua Chemical Group Co., Ltd.',
    symbol: '600309.SS',
  },
  {
    value: '600315.SS',
    label: '600315.SS,  Shanghai Jahwa United Co., Ltd.',
    name: 'Shanghai Jahwa United Co., Ltd.',
    symbol: '600315.SS',
  },
  {
    value: '600323.SS',
    label: '600323.SS,  Grandblue Environment Co., Ltd.',
    name: 'Grandblue Environment Co., Ltd.',
    symbol: '600323.SS',
  },
  {
    value: '600325.SS',
    label: '600325.SS,  Zhuhai Huafa Properties Co.,Ltd',
    name: 'Zhuhai Huafa Properties Co.,Ltd',
    symbol: '600325.SS',
  },
  {
    value: '600332.SS',
    label:
      '600332.SS,  Guangzhou Baiyunshan Pharmaceutical Holdings Company Limited',
    name: 'Guangzhou Baiyunshan Pharmaceutical Holdings Company Limited',
    symbol: '600332.SS',
  },
  {
    value: '600340.SS',
    label: '600340.SS,  China Fortune Land Development Co., Ltd.',
    name: 'China Fortune Land Development Co., Ltd.',
    symbol: '600340.SS',
  },
  {
    value: '600346.SS',
    label: '600346.SS,  Hengli Petrochemical Co.,Ltd.',
    name: 'Hengli Petrochemical Co.,Ltd.',
    symbol: '600346.SS',
  },
  {
    value: '600348.SS',
    label: '600348.SS,  Shan Xi Huayang Group New Energy Co.,Ltd.',
    name: 'Shan Xi Huayang Group New Energy Co.,Ltd.',
    symbol: '600348.SS',
  },
  {
    value: '600352.SS',
    label: '600352.SS,  Zhejiang Longsheng Group Co.,Ltd',
    name: 'Zhejiang Longsheng Group Co.,Ltd',
    symbol: '600352.SS',
  },
  {
    value: '600362.SS',
    label: '600362.SS,  Jiangxi Copper Company Limited',
    name: 'Jiangxi Copper Company Limited',
    symbol: '600362.SS',
  },
  {
    value: '600369.SS',
    label: '600369.SS,  Southwest Securities Co., Ltd.',
    name: 'Southwest Securities Co., Ltd.',
    symbol: '600369.SS',
  },
  {
    value: '600380.SS',
    label: '600380.SS,  Joincare Pharmaceutical Group Industry Co.,Ltd.',
    name: 'Joincare Pharmaceutical Group Industry Co.,Ltd.',
    symbol: '600380.SS',
  },
  {
    value: '600383.SS',
    label: '600383.SS,  Gemdale Corporation',
    name: 'Gemdale Corporation',
    symbol: '600383.SS',
  },
  {
    value: '600392.SS',
    label: '600392.SS,  Shenghe Resources Holding Co., Ltd',
    name: 'Shenghe Resources Holding Co., Ltd',
    symbol: '600392.SS',
  },
  {
    value: '600395.SS',
    label: '600395.SS,  Guizhou Panjiang Refined Coal Co.,Ltd.',
    name: 'Guizhou Panjiang Refined Coal Co.,Ltd.',
    symbol: '600395.SS',
  },
  {
    value: '600406.SS',
    label: '600406.SS,  NARI Technology Co., Ltd.',
    name: 'NARI Technology Co., Ltd.',
    symbol: '600406.SS',
  },
  {
    value: '600409.SS',
    label: '600409.SS,  Tangshan Sanyou Chemical Industries Co.,Ltd',
    name: 'Tangshan Sanyou Chemical Industries Co.,Ltd',
    symbol: '600409.SS',
  },
  {
    value: '600415.SS',
    label: '600415.SS,  Zhejiang China Commodities City Group Co., Ltd.',
    name: 'Zhejiang China Commodities City Group Co., Ltd.',
    symbol: '600415.SS',
  },
  {
    value: '600426.SS',
    label: '600426.SS,  Shandong Hualu-Hengsheng Chemical Co., Ltd.',
    name: 'Shandong Hualu-Hengsheng Chemical Co., Ltd.',
    symbol: '600426.SS',
  },
  {
    value: '600436.SS',
    label: '600436.SS,  Zhangzhou Pientzehuang Pharmaceutical., Ltd',
    name: 'Zhangzhou Pientzehuang Pharmaceutical., Ltd',
    symbol: '600436.SS',
  },
  {
    value: '600438.SS',
    label: '600438.SS,  Tongwei Co.,Ltd',
    name: 'Tongwei Co.,Ltd',
    symbol: '600438.SS',
  },
  {
    value: '600446.SS',
    label: '600446.SS,  Shenzhen Kingdom Sci-Tech Co., Ltd',
    name: 'Shenzhen Kingdom Sci-Tech Co., Ltd',
    symbol: '600446.SS',
  },
  {
    value: '600460.SS',
    label: '600460.SS,  Hangzhou Silan Microelectronics Co., Ltd',
    name: 'Hangzhou Silan Microelectronics Co., Ltd',
    symbol: '600460.SS',
  },
  {
    value: '600483.SS',
    label: '600483.SS,  Fujian Funeng Co., Ltd.',
    name: 'Fujian Funeng Co., Ltd.',
    symbol: '600483.SS',
  },
  {
    value: '600486.SS',
    label: '600486.SS,  Jiangsu Yangnong Chemical Co., Ltd.',
    name: 'Jiangsu Yangnong Chemical Co., Ltd.',
    symbol: '600486.SS',
  },
  {
    value: '600489.SS',
    label: '600489.SS,  Zhongjin Gold Corp.,Ltd',
    name: 'Zhongjin Gold Corp.,Ltd',
    symbol: '600489.SS',
  },
  {
    value: '600491.SS',
    label: '600491.SS,  Long Yuan Construction Group Co., Ltd.',
    name: 'Long Yuan Construction Group Co., Ltd.',
    symbol: '600491.SS',
  },
  {
    value: '600498.SS',
    label: '600498.SS,  Fiberhome Telecommunication Technologies Co., Ltd.',
    name: 'Fiberhome Telecommunication Technologies Co., Ltd.',
    symbol: '600498.SS',
  },
  {
    value: '6004.SR',
    label: '6004.SR,  Saudi Airlines Catering Company',
    name: 'Saudi Airlines Catering Company',
    symbol: '6004.SR',
  },
  {
    value: '600511.SS',
    label: '600511.SS,  China National Medicines Corporation Ltd.',
    name: 'China National Medicines Corporation Ltd.',
    symbol: '600511.SS',
  },
  {
    value: '600516.SS',
    label: '600516.SS,  FangDa Carbon New Material Co.,Ltd',
    name: 'FangDa Carbon New Material Co.,Ltd',
    symbol: '600516.SS',
  },
  {
    value: '600519.SS',
    label: '600519.SS,  Kweichow Moutai Co., Ltd.',
    name: 'Kweichow Moutai Co., Ltd.',
    symbol: '600519.SS',
  },
  {
    value: '600521.SS',
    label: '600521.SS,  Zhejiang Huahai Pharmaceutical Co., Ltd.',
    name: 'Zhejiang Huahai Pharmaceutical Co., Ltd.',
    symbol: '600521.SS',
  },
  {
    value: '600522.SS',
    label: '600522.SS,  Jiangsu Zhongtian Technology Co., Ltd.',
    name: 'Jiangsu Zhongtian Technology Co., Ltd.',
    symbol: '600522.SS',
  },
  {
    value: '600529.SS',
    label: '600529.SS,  Shandong Pharmaceutical Glass Co., Ltd',
    name: 'Shandong Pharmaceutical Glass Co., Ltd',
    symbol: '600529.SS',
  },
  {
    value: '600536.SS',
    label: '600536.SS,  China National Software & Service Company Limited',
    name: 'China National Software & Service Company Limited',
    symbol: '600536.SS',
  },
  {
    value: '600547.SS',
    label: '600547.SS,  Shandong Gold Mining Co., Ltd.',
    name: 'Shandong Gold Mining Co., Ltd.',
    symbol: '600547.SS',
  },
  {
    value: '600548.SS',
    label: '600548.SS,  Shenzhen Expressway Corporation Limited',
    name: 'Shenzhen Expressway Corporation Limited',
    symbol: '600548.SS',
  },
  {
    value: '600549.SS',
    label: '600549.SS,  Xiamen Tungsten Co., Ltd.',
    name: 'Xiamen Tungsten Co., Ltd.',
    symbol: '600549.SS',
  },
  {
    value: '600559.SS',
    label: '600559.SS,  Hebei Hengshui Laobaigan Liquor Co., Ltd.',
    name: 'Hebei Hengshui Laobaigan Liquor Co., Ltd.',
    symbol: '600559.SS',
  },
  {
    value: '600562.SS',
    label: '600562.SS,  Glarun Technology Co.,Ltd',
    name: 'Glarun Technology Co.,Ltd',
    symbol: '600562.SS',
  },
  {
    value: '600566.SS',
    label: '600566.SS,  Hubei Jumpcan Pharmaceutical Co., Ltd.',
    name: 'Hubei Jumpcan Pharmaceutical Co., Ltd.',
    symbol: '600566.SS',
  },
  {
    value: '600570.SS',
    label: '600570.SS,  Hundsun Technologies Inc.',
    name: 'Hundsun Technologies Inc.',
    symbol: '600570.SS',
  },
  {
    value: '600582.SS',
    label: '600582.SS,  Tiandi Science & Technology Co.Ltd',
    name: 'Tiandi Science & Technology Co.Ltd',
    symbol: '600582.SS',
  },
  {
    value: '600583.SS',
    label: '600583.SS,  Offshore Oil Engineering Co.,Ltd',
    name: 'Offshore Oil Engineering Co.,Ltd',
    symbol: '600583.SS',
  },
  {
    value: '600584.SS',
    label: '600584.SS,  JCET Group Co., Ltd.',
    name: 'JCET Group Co., Ltd.',
    symbol: '600584.SS',
  },
  {
    value: '600585.SS',
    label: '600585.SS,  Anhui Conch Cement Company Limited',
    name: 'Anhui Conch Cement Company Limited',
    symbol: '600585.SS',
  },
  {
    value: '600588.SS',
    label: '600588.SS,  Yonyou Network Technology Co.,Ltd.',
    name: 'Yonyou Network Technology Co.,Ltd.',
    symbol: '600588.SS',
  },
  {
    value: '600598.SS',
    label: '600598.SS,  Heilongjiang Agriculture Company Limited',
    name: 'Heilongjiang Agriculture Company Limited',
    symbol: '600598.SS',
  },
  {
    value: '6005.T',
    label: '6005.T,  Miura Co., Ltd.',
    name: 'Miura Co., Ltd.',
    symbol: '6005.T',
  },
  {
    value: '6005.TW',
    label: '6005.TW,  Capital Securities Corporation',
    name: 'Capital Securities Corporation',
    symbol: '6005.TW',
  },
  {
    value: '600600.SS',
    label: '600600.SS,  Tsingtao Brewery Company Limited',
    name: 'Tsingtao Brewery Company Limited',
    symbol: '600600.SS',
  },
  {
    value: '600606.SS',
    label: '600606.SS,  Greenland Holdings Corporation Limited',
    name: 'Greenland Holdings Corporation Limited',
    symbol: '600606.SS',
  },
  {
    value: '600642.SS',
    label: '600642.SS,  Shenergy Company Limited',
    name: 'Shenergy Company Limited',
    symbol: '600642.SS',
  },
  {
    value: '600643.SS',
    label: '600643.SS,  Shanghai Aj Group Co.,Ltd',
    name: 'Shanghai Aj Group Co.,Ltd',
    symbol: '600643.SS',
  },
  {
    value: '600648.SS',
    label: '600648.SS,  Shanghai Waigaoqiao Free Trade Zone Group Co., Ltd.',
    name: 'Shanghai Waigaoqiao Free Trade Zone Group Co., Ltd.',
    symbol: '600648.SS',
  },
  {
    value: '600655.SS',
    label: '600655.SS,  Shanghai Yuyuan Tourist Mart (Group) Co., Ltd.',
    name: 'Shanghai Yuyuan Tourist Mart (Group) Co., Ltd.',
    symbol: '600655.SS',
  },
  {
    value: '600657.SS',
    label: '600657.SS,  Cinda Real Estate Co., Ltd.',
    name: 'Cinda Real Estate Co., Ltd.',
    symbol: '600657.SS',
  },
  {
    value: '600660.SS',
    label: '600660.SS,  Fuyao Glass Industry Group Co., Ltd.',
    name: 'Fuyao Glass Industry Group Co., Ltd.',
    symbol: '600660.SS',
  },
  {
    value: '600663.SS',
    label:
      '600663.SS,  Shanghai Lujiazui Finance & Trade Zone Development Co., Ltd.',
    name: 'Shanghai Lujiazui Finance & Trade Zone Development Co., Ltd.',
    symbol: '600663.SS',
  },
  {
    value: '600667.SS',
    label: '600667.SS,  Wuxi Taiji Industry Limited Corporation',
    name: 'Wuxi Taiji Industry Limited Corporation',
    symbol: '600667.SS',
  },
  {
    value: '600674.SS',
    label: '600674.SS,  Sichuan Chuantou Energy Co.,Ltd.',
    name: 'Sichuan Chuantou Energy Co.,Ltd.',
    symbol: '600674.SS',
  },
  {
    value: '600681.SS',
    label: '600681.SS,  Bestsun Energy Co., Ltd.',
    name: 'Bestsun Energy Co., Ltd.',
    symbol: '600681.SS',
  },
  {
    value: '600688.SS',
    label: '600688.SS,  Sinopec Shanghai Petrochemical Company Limited',
    name: 'Sinopec Shanghai Petrochemical Company Limited',
    symbol: '600688.SS',
  },
  {
    value: '600690.SS',
    label: '600690.SS,  Haier Smart Home Co., Ltd.',
    name: 'Haier Smart Home Co., Ltd.',
    symbol: '600690.SS',
  },
  {
    value: '600699.SS',
    label: '600699.SS,  Ningbo Joyson Electronic Corp.',
    name: 'Ningbo Joyson Electronic Corp.',
    symbol: '600699.SS',
  },
  {
    value: '600703.SS',
    label: '600703.SS,  Sanan Optoelectronics Co., Ltd.',
    name: 'Sanan Optoelectronics Co., Ltd.',
    symbol: '600703.SS',
  },
  {
    value: '600704.SS',
    label: '600704.SS,  Wuchan Zhongda Group Co.,Ltd.',
    name: 'Wuchan Zhongda Group Co.,Ltd.',
    symbol: '600704.SS',
  },
  {
    value: '600705.SS',
    label: '600705.SS,  AVIC Industry-Finance Holdings Co., Ltd.',
    name: 'AVIC Industry-Finance Holdings Co., Ltd.',
    symbol: '600705.SS',
  },
  {
    value: '600711.SS',
    label: '600711.SS,  Chengtun Mining Group Co., Ltd.',
    name: 'Chengtun Mining Group Co., Ltd.',
    symbol: '600711.SS',
  },
  {
    value: '600720.SS',
    label: '600720.SS,  Gansu Qilianshan Cement Group Co., Ltd.',
    name: 'Gansu Qilianshan Cement Group Co., Ltd.',
    symbol: '600720.SS',
  },
  {
    value: '600729.SS',
    label: '600729.SS,  Chongqing Department Store Co.,Ltd.',
    name: 'Chongqing Department Store Co.,Ltd.',
    symbol: '600729.SS',
  },
  {
    value: '600739.SS',
    label: '600739.SS,  Liaoning Cheng Da Co., Ltd.',
    name: 'Liaoning Cheng Da Co., Ltd.',
    symbol: '600739.SS',
  },
  {
    value: '600741.SS',
    label: '600741.SS,  HUAYU Automotive Systems Company Limited',
    name: 'HUAYU Automotive Systems Company Limited',
    symbol: '600741.SS',
  },
  {
    value: '600745.SS',
    label: '600745.SS,  Wingtech Technology Co.,Ltd',
    name: 'Wingtech Technology Co.,Ltd',
    symbol: '600745.SS',
  },
  {
    value: '600754.SS',
    label: '600754.SS,  Shanghai Jin Jiang International Hotels Co., Ltd.',
    name: 'Shanghai Jin Jiang International Hotels Co., Ltd.',
    symbol: '600754.SS',
  },
  {
    value: '600755.SS',
    label: '600755.SS,  Xiamen ITG Group Corp.,Ltd',
    name: 'Xiamen ITG Group Corp.,Ltd',
    symbol: '600755.SS',
  },
  {
    value: '600763.SS',
    label: '600763.SS,  Topchoice Medical Corporation',
    name: 'Topchoice Medical Corporation',
    symbol: '600763.SS',
  },
  {
    value: '600779.SS',
    label: '600779.SS,  Sichuan Swellfun Co.,Ltd',
    name: 'Sichuan Swellfun Co.,Ltd',
    symbol: '600779.SS',
  },
  {
    value: '600782.SS',
    label: '600782.SS,  Xinyu Iron & Steel Co., Ltd',
    name: 'Xinyu Iron & Steel Co., Ltd',
    symbol: '600782.SS',
  },
  {
    value: '600801.SS',
    label: '600801.SS,  Huaxin Cement Co., Ltd.',
    name: 'Huaxin Cement Co., Ltd.',
    symbol: '600801.SS',
  },
  {
    value: '600803.SS',
    label: '600803.SS,  ENN Natural Gas Co., Ltd.',
    name: 'ENN Natural Gas Co., Ltd.',
    symbol: '600803.SS',
  },
  {
    value: '600808.SS',
    label: '600808.SS,  Maanshan Iron & Steel Company Limited',
    name: 'Maanshan Iron & Steel Company Limited',
    symbol: '600808.SS',
  },
  {
    value: '600809.SS',
    label: '600809.SS,  Shanxi Xinghuacun Fen Wine Factory Co.,Ltd.',
    name: 'Shanxi Xinghuacun Fen Wine Factory Co.,Ltd.',
    symbol: '600809.SS',
  },
  {
    value: '600820.SS',
    label: '600820.SS,  Shanghai Tunnel Engineering Co., Ltd.',
    name: 'Shanghai Tunnel Engineering Co., Ltd.',
    symbol: '600820.SS',
  },
  {
    value: '600823.SS',
    label: '600823.SS,  Shanghai Shimao Co., Ltd.',
    name: 'Shanghai Shimao Co., Ltd.',
    symbol: '600823.SS',
  },
  {
    value: '600827.SS',
    label: '600827.SS,  Shanghai Bailian (Group) Co., Ltd.',
    name: 'Shanghai Bailian (Group) Co., Ltd.',
    symbol: '600827.SS',
  },
  {
    value: '600835.SS',
    label: '600835.SS,  Shanghai Mechanical & Electrical Industry Co.,Ltd.',
    name: 'Shanghai Mechanical & Electrical Industry Co.,Ltd.',
    symbol: '600835.SS',
  },
  {
    value: '600837.SS',
    label: '600837.SS,  Haitong Securities Co., Ltd.',
    name: 'Haitong Securities Co., Ltd.',
    symbol: '600837.SS',
  },
  {
    value: '600845.SS',
    label: '600845.SS,  Shanghai Baosight Software Co.,Ltd.',
    name: 'Shanghai Baosight Software Co.,Ltd.',
    symbol: '600845.SS',
  },
  {
    value: '600848.SS',
    label: '600848.SS,  Shanghai Lingang Holdings Co.,Ltd.',
    name: 'Shanghai Lingang Holdings Co.,Ltd.',
    symbol: '600848.SS',
  },
  {
    value: '600859.SS',
    label: '600859.SS,  Wangfujing Group Co., Ltd.',
    name: 'Wangfujing Group Co., Ltd.',
    symbol: '600859.SS',
  },
  {
    value: '600867.SS',
    label: '600867.SS,  Tonghua Dongbao Pharmaceutical Co., Ltd.',
    name: 'Tonghua Dongbao Pharmaceutical Co., Ltd.',
    symbol: '600867.SS',
  },
  {
    value: '600871.SS',
    label: '600871.SS,  Sinopec Oilfield Service Corporation',
    name: 'Sinopec Oilfield Service Corporation',
    symbol: '600871.SS',
  },
  {
    value: '600872.SS',
    label:
      '600872.SS,  Jonjee Hi-tech Industrial & Commercial Holding Co., Ltd.',
    name: 'Jonjee Hi-tech Industrial & Commercial Holding Co., Ltd.',
    symbol: '600872.SS',
  },
  {
    value: '600875.SS',
    label: '600875.SS,  Dongfang Electric Corporation Limited',
    name: 'Dongfang Electric Corporation Limited',
    symbol: '600875.SS',
  },
  {
    value: '600885.SS',
    label: '600885.SS,  Hongfa Technology Co., Ltd.',
    name: 'Hongfa Technology Co., Ltd.',
    symbol: '600885.SS',
  },
  {
    value: '600886.SS',
    label: '600886.SS,  SDIC Power Holdings Co., Ltd.',
    name: 'SDIC Power Holdings Co., Ltd.',
    symbol: '600886.SS',
  },
  {
    value: '600887.SS',
    label: '600887.SS,  Inner Mongolia Yili Industrial Group Co., Ltd.',
    name: 'Inner Mongolia Yili Industrial Group Co., Ltd.',
    symbol: '600887.SS',
  },
  {
    value: '600893.SS',
    label: '600893.SS,  AECC Aviation Power Co.,Ltd',
    name: 'AECC Aviation Power Co.,Ltd',
    symbol: '600893.SS',
  },
  {
    value: '600895.SS',
    label: '600895.SS,  Shanghai Zhangjiang Hi-Tech Park Development Co., Ltd.',
    name: 'Shanghai Zhangjiang Hi-Tech Park Development Co., Ltd.',
    symbol: '600895.SS',
  },
  {
    value: '600900.SS',
    label: '600900.SS,  China Yangtze Power Co., Ltd.',
    name: 'China Yangtze Power Co., Ltd.',
    symbol: '600900.SS',
  },
  {
    value: '600909.SS',
    label: '600909.SS,  Huaan Securities Co., Ltd.',
    name: 'Huaan Securities Co., Ltd.',
    symbol: '600909.SS',
  },
  {
    value: '600918.SS',
    label: '600918.SS,  Zhongtai Securities Co., Ltd.',
    name: 'Zhongtai Securities Co., Ltd.',
    symbol: '600918.SS',
  },
  {
    value: '600919.SS',
    label: '600919.SS,  Bank of Jiangsu Co., Ltd.',
    name: 'Bank of Jiangsu Co., Ltd.',
    symbol: '600919.SS',
  },
  {
    value: '600926.SS',
    label: '600926.SS,  Bank of Hangzhou Co., Ltd.',
    name: 'Bank of Hangzhou Co., Ltd.',
    symbol: '600926.SS',
  },
  {
    value: '600958.SS',
    label: '600958.SS,  Orient Securities Company Limited',
    name: 'Orient Securities Company Limited',
    symbol: '600958.SS',
  },
  {
    value: '600970.SS',
    label: '600970.SS,  Sinoma International Engineering Co.Ltd',
    name: 'Sinoma International Engineering Co.Ltd',
    symbol: '600970.SS',
  },
  {
    value: '600971.SS',
    label: '600971.SS,  Anhui Hengyuan Coal-Electricity Group Co., Ltd.',
    name: 'Anhui Hengyuan Coal-Electricity Group Co., Ltd.',
    symbol: '600971.SS',
  },
  {
    value: '600985.SS',
    label: '600985.SS,  Huaibei Mining Holdings Co.,Ltd.',
    name: 'Huaibei Mining Holdings Co.,Ltd.',
    symbol: '600985.SS',
  },
  {
    value: '600987.SS',
    label: '600987.SS,  Zhejiang Hangmin Co.,Ltd',
    name: 'Zhejiang Hangmin Co.,Ltd',
    symbol: '600987.SS',
  },
  {
    value: '600988.SS',
    label: '600988.SS,  Chifeng Jilong Gold Mining Co.,Ltd.',
    name: 'Chifeng Jilong Gold Mining Co.,Ltd.',
    symbol: '600988.SS',
  },
  {
    value: '600989.SS',
    label: '600989.SS,  Ningxia Baofeng Energy Group Co., Ltd.',
    name: 'Ningxia Baofeng Energy Group Co., Ltd.',
    symbol: '600989.SS',
  },
  {
    value: '600998.SS',
    label: '600998.SS,  Jointown Pharmaceutical Group Co., Ltd',
    name: 'Jointown Pharmaceutical Group Co., Ltd',
    symbol: '600998.SS',
  },
  {
    value: '600999.SS',
    label: '600999.SS,  China Merchants Securities Co., Ltd.',
    name: 'China Merchants Securities Co., Ltd.',
    symbol: '600999.SS',
  },
  {
    value: '601003.SS',
    label: '601003.SS,  Liuzhou Iron & Steel Co., Ltd.',
    name: 'Liuzhou Iron & Steel Co., Ltd.',
    symbol: '601003.SS',
  },
  {
    value: '601006.SS',
    label: '601006.SS,  Daqin Railway Co., Ltd.',
    name: 'Daqin Railway Co., Ltd.',
    symbol: '601006.SS',
  },
  {
    value: '601009.SS',
    label: '601009.SS,  Bank of Nanjing Co., Ltd.',
    name: 'Bank of Nanjing Co., Ltd.',
    symbol: '601009.SS',
  },
  {
    value: '601012.SS',
    label: '601012.SS,  LONGi Green Energy Technology Co., Ltd.',
    name: 'LONGi Green Energy Technology Co., Ltd.',
    symbol: '601012.SS',
  },
  {
    value: '601016.SS',
    label: '601016.SS,  CECEP Wind-power Corporation Co.,Ltd.',
    name: 'CECEP Wind-power Corporation Co.,Ltd.',
    symbol: '601016.SS',
  },
  {
    value: '601018.SS',
    label: '601018.SS,  Ningbo Zhoushan Port Company Limited',
    name: 'Ningbo Zhoushan Port Company Limited',
    symbol: '601018.SS',
  },
  {
    value: '601019.SS',
    label: '601019.SS,  Shandong Publishing&Media Co.,Ltd',
    name: 'Shandong Publishing&Media Co.,Ltd',
    symbol: '601019.SS',
  },
  {
    value: '601021.SS',
    label: '601021.SS,  Spring Airlines Co., Ltd.',
    name: 'Spring Airlines Co., Ltd.',
    symbol: '601021.SS',
  },
  {
    value: '601058.SS',
    label: '601058.SS,  Sailun Group Co., Ltd.',
    name: 'Sailun Group Co., Ltd.',
    symbol: '601058.SS',
  },
  {
    value: '601066.SS',
    label: '601066.SS,  CSC Financial Co., Ltd.',
    name: 'CSC Financial Co., Ltd.',
    symbol: '601066.SS',
  },
  {
    value: '601077.SS',
    label: '601077.SS,  Chongqing Rural Commercial Bank Co., Ltd.',
    name: 'Chongqing Rural Commercial Bank Co., Ltd.',
    symbol: '601077.SS',
  },
  {
    value: '601088.SS',
    label: '601088.SS,  China Shenhua Energy Company Limited',
    name: 'China Shenhua Energy Company Limited',
    symbol: '601088.SS',
  },
  {
    value: '601098.SS',
    label: '601098.SS,  China South Publishing & Media Group Co., Ltd',
    name: 'China South Publishing & Media Group Co., Ltd',
    symbol: '601098.SS',
  },
  {
    value: '601099.SS',
    label: '601099.SS,  The Pacific Securities Co., Ltd',
    name: 'The Pacific Securities Co., Ltd',
    symbol: '601099.SS',
  },
  {
    value: '6010.SR',
    label: '6010.SR,  The National Agricultural Development Company',
    name: 'The National Agricultural Development Company',
    symbol: '6010.SR',
  },
  {
    value: '601100.SS',
    label: '601100.SS,  Jiangsu Hengli Hydraulic Co.,Ltd',
    name: 'Jiangsu Hengli Hydraulic Co.,Ltd',
    symbol: '601100.SS',
  },
  {
    value: '601108.SS',
    label: '601108.SS,  Caitong Securities Co.,Ltd.',
    name: 'Caitong Securities Co.,Ltd.',
    symbol: '601108.SS',
  },
  {
    value: '601111.SS',
    label: '601111.SS,  Air China Limited',
    name: 'Air China Limited',
    symbol: '601111.SS',
  },
  {
    value: '601117.SS',
    label: '601117.SS,  China National Chemical Engineering Co., Ltd',
    name: 'China National Chemical Engineering Co., Ltd',
    symbol: '601117.SS',
  },
  {
    value: '601128.SS',
    label: '601128.SS,  Jiangsu Changshu Rural Commercial Bank Co., Ltd.',
    name: 'Jiangsu Changshu Rural Commercial Bank Co., Ltd.',
    symbol: '601128.SS',
  },
  {
    value: '601138.SS',
    label: '601138.SS,  Foxconn Industrial Internet Co., Ltd.',
    name: 'Foxconn Industrial Internet Co., Ltd.',
    symbol: '601138.SS',
  },
  {
    value: '601155.SS',
    label: '601155.SS,  Seazen Holdings Co., Ltd',
    name: 'Seazen Holdings Co., Ltd',
    symbol: '601155.SS',
  },
  {
    value: '601158.SS',
    label: '601158.SS,  Chongqing Water Group Co.,Ltd.',
    name: 'Chongqing Water Group Co.,Ltd.',
    symbol: '601158.SS',
  },
  {
    value: '601162.SS',
    label: '601162.SS,  Tianfeng Securities Co., Ltd.',
    name: 'Tianfeng Securities Co., Ltd.',
    symbol: '601162.SS',
  },
  {
    value: '601166.SS',
    label: '601166.SS,  Industrial Bank Co., Ltd.',
    name: 'Industrial Bank Co., Ltd.',
    symbol: '601166.SS',
  },
  {
    value: '601169.SS',
    label: '601169.SS,  Bank of Beijing Co., Ltd.',
    name: 'Bank of Beijing Co., Ltd.',
    symbol: '601169.SS',
  },
  {
    value: '601198.SS',
    label: '601198.SS,  Dongxing Securities Corporation Limited',
    name: 'Dongxing Securities Corporation Limited',
    symbol: '601198.SS',
  },
  {
    value: '601211.SS',
    label: '601211.SS,  Guotai Junan Securities Co., Ltd.',
    name: 'Guotai Junan Securities Co., Ltd.',
    symbol: '601211.SS',
  },
  {
    value: '601216.SS',
    label:
      '601216.SS,  Inner Mongolia Junzheng Energy & Chemical Group Co.,Ltd.',
    name: 'Inner Mongolia Junzheng Energy & Chemical Group Co.,Ltd.',
    symbol: '601216.SS',
  },
  {
    value: '601225.SS',
    label: '601225.SS,  Shaanxi Coal Industry Company Limited',
    name: 'Shaanxi Coal Industry Company Limited',
    symbol: '601225.SS',
  },
  {
    value: '601229.SS',
    label: '601229.SS,  Bank of Shanghai Co., Ltd.',
    name: 'Bank of Shanghai Co., Ltd.',
    symbol: '601229.SS',
  },
  {
    value: '601231.SS',
    label: '601231.SS,  Universal Scientific Industrial (Shanghai) Co., Ltd.',
    name: 'Universal Scientific Industrial (Shanghai) Co., Ltd.',
    symbol: '601231.SS',
  },
  {
    value: '601233.SS',
    label: '601233.SS,  Tongkun Group Co., Ltd.',
    name: 'Tongkun Group Co., Ltd.',
    symbol: '601233.SS',
  },
  {
    value: '601236.SS',
    label: '601236.SS,  Hongta Securities Co., Ltd.',
    name: 'Hongta Securities Co., Ltd.',
    symbol: '601236.SS',
  },
  {
    value: '601238.SS',
    label: '601238.SS,  Guangzhou Automobile Group Co., Ltd.',
    name: 'Guangzhou Automobile Group Co., Ltd.',
    symbol: '601238.SS',
  },
  {
    value: '601288.SS',
    label: '601288.SS,  Agricultural Bank of China Limited',
    name: 'Agricultural Bank of China Limited',
    symbol: '601288.SS',
  },
  {
    value: '6012.SR',
    label: '6012.SR,  Raydan Food Company',
    name: 'Raydan Food Company',
    symbol: '6012.SR',
  },
  {
    value: '601318.SS',
    label: '601318.SS,  Ping An Insurance (Group) Company of China, Ltd.',
    name: 'Ping An Insurance (Group) Company of China, Ltd.',
    symbol: '601318.SS',
  },
  {
    value: '601319.SS',
    label:
      "601319.SS,  The People's Insurance Company (Group) of China Limited",
    name: "The People's Insurance Company (Group) of China Limited",
    symbol: '601319.SS',
  },
  {
    value: '601326.SS',
    label: '601326.SS,  Qinhuangdao Port Co., Ltd.',
    name: 'Qinhuangdao Port Co., Ltd.',
    symbol: '601326.SS',
  },
  {
    value: '601328.SS',
    label: '601328.SS,  Bank of Communications Co., Ltd.',
    name: 'Bank of Communications Co., Ltd.',
    symbol: '601328.SS',
  },
  {
    value: '601336.SS',
    label: '601336.SS,  New China Life Insurance Company Ltd.',
    name: 'New China Life Insurance Company Ltd.',
    symbol: '601336.SS',
  },
  {
    value: '601360.SS',
    label: '601360.SS,  360 Security Technology Inc.',
    name: '360 Security Technology Inc.',
    symbol: '601360.SS',
  },
  {
    value: '601375.SS',
    label: '601375.SS,  Central China Securities Co., Ltd.',
    name: 'Central China Securities Co., Ltd.',
    symbol: '601375.SS',
  },
  {
    value: '601377.SS',
    label: '601377.SS,  Industrial Securities Co.,Ltd.',
    name: 'Industrial Securities Co.,Ltd.',
    symbol: '601377.SS',
  },
  {
    value: '601390.SS',
    label: '601390.SS,  China Railway Group Limited',
    name: 'China Railway Group Limited',
    symbol: '601390.SS',
  },
  {
    value: '601398.SS',
    label: '601398.SS,  Industrial and Commercial Bank of China Limited',
    name: 'Industrial and Commercial Bank of China Limited',
    symbol: '601398.SS',
  },
  {
    value: '6013.T',
    label: '6013.T,  Takuma Co., Ltd.',
    name: 'Takuma Co., Ltd.',
    symbol: '6013.T',
  },
  {
    value: '601456.SS',
    label: '601456.SS,  Guolian Securities Co., Ltd.',
    name: 'Guolian Securities Co., Ltd.',
    symbol: '601456.SS',
  },
  {
    value: '601555.SS',
    label: '601555.SS,  Soochow Securities Co., Ltd.',
    name: 'Soochow Securities Co., Ltd.',
    symbol: '601555.SS',
  },
  {
    value: '601577.SS',
    label: '601577.SS,  Bank of Changsha Co., Ltd.',
    name: 'Bank of Changsha Co., Ltd.',
    symbol: '601577.SS',
  },
  {
    value: '601598.SS',
    label: '601598.SS,  Sinotrans Limited',
    name: 'Sinotrans Limited',
    symbol: '601598.SS',
  },
  {
    value: '6015.TWO',
    label: '6015.TWO,  Horizon Securities Co., Ltd.',
    name: 'Horizon Securities Co., Ltd.',
    symbol: '6015.TWO',
  },
  {
    value: '601600.SS',
    label: '601600.SS,  Aluminum Corporation of China Limited',
    name: 'Aluminum Corporation of China Limited',
    symbol: '601600.SS',
  },
  {
    value: '601601.SS',
    label: '601601.SS,  China Pacific Insurance (Group) Co., Ltd.',
    name: 'China Pacific Insurance (Group) Co., Ltd.',
    symbol: '601601.SS',
  },
  {
    value: '601607.SS',
    label: '601607.SS,  Shanghai Pharmaceuticals Holding Co., Ltd',
    name: 'Shanghai Pharmaceuticals Holding Co., Ltd',
    symbol: '601607.SS',
  },
  {
    value: '601615.SS',
    label: '601615.SS,  Ming Yang Smart Energy Group Limited',
    name: 'Ming Yang Smart Energy Group Limited',
    symbol: '601615.SS',
  },
  {
    value: '601618.SS',
    label: '601618.SS,  Metallurgical Corporation of China Ltd.',
    name: 'Metallurgical Corporation of China Ltd.',
    symbol: '601618.SS',
  },
  {
    value: '601628.SS',
    label: '601628.SS,  China Life Insurance Company Limited',
    name: 'China Life Insurance Company Limited',
    symbol: '601628.SS',
  },
  {
    value: '601633.SS',
    label: '601633.SS,  Great Wall Motor Company Limited',
    name: 'Great Wall Motor Company Limited',
    symbol: '601633.SS',
  },
  {
    value: '601636.SS',
    label: '601636.SS,  Zhuzhou Kibing Group Co.,Ltd',
    name: 'Zhuzhou Kibing Group Co.,Ltd',
    symbol: '601636.SS',
  },
  {
    value: '601658.SS',
    label: '601658.SS,  Postal Savings Bank of China Co., Ltd.',
    name: 'Postal Savings Bank of China Co., Ltd.',
    symbol: '601658.SS',
  },
  {
    value: '601666.SS',
    label: '601666.SS,  Pingdingshan Tianan Coal. Mining Co., Ltd.',
    name: 'Pingdingshan Tianan Coal. Mining Co., Ltd.',
    symbol: '601666.SS',
  },
  {
    value: '601668.SS',
    label:
      '601668.SS,  China State Construction Engineering Corporation Limited',
    name: 'China State Construction Engineering Corporation Limited',
    symbol: '601668.SS',
  },
  {
    value: '601669.SS',
    label: '601669.SS,  Power Construction Corporation of China, Ltd',
    name: 'Power Construction Corporation of China, Ltd',
    symbol: '601669.SS',
  },
  {
    value: '601688.SS',
    label: '601688.SS,  Huatai Securities Co., Ltd.',
    name: 'Huatai Securities Co., Ltd.',
    symbol: '601688.SS',
  },
  {
    value: '601689.SS',
    label: '601689.SS,  Ningbo Tuopu Group Co.,Ltd.',
    name: 'Ningbo Tuopu Group Co.,Ltd.',
    symbol: '601689.SS',
  },
  {
    value: '601696.SS',
    label: '601696.SS,  BOC International (China) CO., LTD',
    name: 'BOC International (China) CO., LTD',
    symbol: '601696.SS',
  },
  {
    value: '601699.SS',
    label:
      "601699.SS,  Shanxi Lu'an Environmental Energy Development Co., Ltd.",
    name: "Shanxi Lu'an Environmental Energy Development Co., Ltd.",
    symbol: '601699.SS',
  },
  {
    value: '601717.SS',
    label: '601717.SS,  Zhengzhou Coal Mining Machinery Group Company Limited',
    name: 'Zhengzhou Coal Mining Machinery Group Company Limited',
    symbol: '601717.SS',
  },
  {
    value: '601727.SS',
    label: '601727.SS,  Shanghai Electric Group Company Limited',
    name: 'Shanghai Electric Group Company Limited',
    symbol: '601727.SS',
  },
  {
    value: '601766.SS',
    label: '601766.SS,  CRRC Corporation Limited',
    name: 'CRRC Corporation Limited',
    symbol: '601766.SS',
  },
  {
    value: '601788.SS',
    label: '601788.SS,  Everbright Securities Company Limited',
    name: 'Everbright Securities Company Limited',
    symbol: '601788.SS',
  },
  {
    value: '601799.SS',
    label: '601799.SS,  Changzhou Xingyu Automotive Lighting Systems Co.,Ltd.',
    name: 'Changzhou Xingyu Automotive Lighting Systems Co.,Ltd.',
    symbol: '601799.SS',
  },
  {
    value: '601808.SS',
    label: '601808.SS,  China Oilfield Services Limited',
    name: 'China Oilfield Services Limited',
    symbol: '601808.SS',
  },
  {
    value: '601816.SS',
    label: '601816.SS,  Beijing-Shanghai High-Speed Railway Co.,Ltd.',
    name: 'Beijing-Shanghai High-Speed Railway Co.,Ltd.',
    symbol: '601816.SS',
  },
  {
    value: '601818.SS',
    label: '601818.SS,  China Everbright Bank Company Limited',
    name: 'China Everbright Bank Company Limited',
    symbol: '601818.SS',
  },
  {
    value: '601838.SS',
    label: '601838.SS,  Bank of Chengdu Co., Ltd.',
    name: 'Bank of Chengdu Co., Ltd.',
    symbol: '601838.SS',
  },
  {
    value: '601857.SS',
    label: '601857.SS,  PetroChina Company Limited',
    name: 'PetroChina Company Limited',
    symbol: '601857.SS',
  },
  {
    value: '601865.SS',
    label: '601865.SS,  Flat Glass Group Co., Ltd.',
    name: 'Flat Glass Group Co., Ltd.',
    symbol: '601865.SS',
  },
  {
    value: '601866.SS',
    label: '601866.SS,  COSCO SHIPPING Development Co., Ltd.',
    name: 'COSCO SHIPPING Development Co., Ltd.',
    symbol: '601866.SS',
  },
  {
    value: '601872.SS',
    label: '601872.SS,  China Merchants Energy Shipping Co., Ltd.',
    name: 'China Merchants Energy Shipping Co., Ltd.',
    symbol: '601872.SS',
  },
  {
    value: '601877.SS',
    label: '601877.SS,  Zhejiang Chint Electrics Co., Ltd.',
    name: 'Zhejiang Chint Electrics Co., Ltd.',
    symbol: '601877.SS',
  },
  {
    value: '601878.SS',
    label: '601878.SS,  Zheshang Securities Co., Ltd.',
    name: 'Zheshang Securities Co., Ltd.',
    symbol: '601878.SS',
  },
  {
    value: '601881.SS',
    label: '601881.SS,  China Galaxy Securities Co., Ltd.',
    name: 'China Galaxy Securities Co., Ltd.',
    symbol: '601881.SS',
  },
  {
    value: '601888.SS',
    label: '601888.SS,  China Tourism Group Duty Free Corporation Limited',
    name: 'China Tourism Group Duty Free Corporation Limited',
    symbol: '601888.SS',
  },
  {
    value: '601898.SS',
    label: '601898.SS,  China Coal Energy Company Limited',
    name: 'China Coal Energy Company Limited',
    symbol: '601898.SS',
  },
  {
    value: '601899.SS',
    label: '601899.SS,  Zijin Mining Group Company Limited',
    name: 'Zijin Mining Group Company Limited',
    symbol: '601899.SS',
  },
  {
    value: '601901.SS',
    label: '601901.SS,  Founder Securities Co., Ltd.',
    name: 'Founder Securities Co., Ltd.',
    symbol: '601901.SS',
  },
  {
    value: '601916.SS',
    label: '601916.SS,  China Zheshang Bank Co., Ltd',
    name: 'China Zheshang Bank Co., Ltd',
    symbol: '601916.SS',
  },
  {
    value: '601919.SS',
    label: '601919.SS,  COSCO SHIPPING Holdings Co., Ltd.',
    name: 'COSCO SHIPPING Holdings Co., Ltd.',
    symbol: '601919.SS',
  },
  {
    value: '601933.SS',
    label: '601933.SS,  Yonghui Superstores Co., Ltd.',
    name: 'Yonghui Superstores Co., Ltd.',
    symbol: '601933.SS',
  },
  {
    value: '601939.SS',
    label: '601939.SS,  China Construction Bank Corporation',
    name: 'China Construction Bank Corporation',
    symbol: '601939.SS',
  },
  {
    value: '601958.SS',
    label: '601958.SS,  Jinduicheng Molybdenum Co., Ltd.',
    name: 'Jinduicheng Molybdenum Co., Ltd.',
    symbol: '601958.SS',
  },
  {
    value: '601966.SS',
    label: '601966.SS,  Shandong Linglong Tyre Co.,Ltd.',
    name: 'Shandong Linglong Tyre Co.,Ltd.',
    symbol: '601966.SS',
  },
  {
    value: '601985.SS',
    label: '601985.SS,  China National Nuclear Power Co., Ltd.',
    name: 'China National Nuclear Power Co., Ltd.',
    symbol: '601985.SS',
  },
  {
    value: '601988.SS',
    label: '601988.SS,  Bank of China Limited',
    name: 'Bank of China Limited',
    symbol: '601988.SS',
  },
  {
    value: '601990.SS',
    label: '601990.SS,  Nanjing Securities Co., Ltd.',
    name: 'Nanjing Securities Co., Ltd.',
    symbol: '601990.SS',
  },
  {
    value: '601991.SS',
    label: '601991.SS,  Datang International Power Generation Co., Ltd.',
    name: 'Datang International Power Generation Co., Ltd.',
    symbol: '601991.SS',
  },
  {
    value: '601992.SS',
    label: '601992.SS,  BBMG Corporation',
    name: 'BBMG Corporation',
    symbol: '601992.SS',
  },
  {
    value: '601995.SS',
    label: '601995.SS,  China International Capital Corporation Limited',
    name: 'China International Capital Corporation Limited',
    symbol: '601995.SS',
  },
  {
    value: '601998.SS',
    label: '601998.SS,  China CITIC Bank Corporation Limited',
    name: 'China CITIC Bank Corporation Limited',
    symbol: '601998.SS',
  },
  {
    value: '6024.TW',
    label: '6024.TW,  Capital Futures Corporation',
    name: 'Capital Futures Corporation',
    symbol: '6024.TW',
  },
  {
    value: '6027.T',
    label: '6027.T,  Bengo4.com,Inc.',
    name: 'Bengo4.com,Inc.',
    symbol: '6027.T',
  },
  {
    value: '6028.T',
    label: '6028.T,  TechnoPro Holdings, Inc.',
    name: 'TechnoPro Holdings, Inc.',
    symbol: '6028.T',
  },
  {
    value: '603000.SS',
    label: '603000.SS,  People.cn CO., LTD',
    name: 'People.cn CO., LTD',
    symbol: '603000.SS',
  },
  {
    value: '603019.SS',
    label: '603019.SS,  Dawning Information Industry Co., Ltd.',
    name: 'Dawning Information Industry Co., Ltd.',
    symbol: '603019.SS',
  },
  {
    value: '603027.SS',
    label: '603027.SS,  Qianhe Condiment and Food Co., Ltd.',
    name: 'Qianhe Condiment and Food Co., Ltd.',
    symbol: '603027.SS',
  },
  {
    value: '603127.SS',
    label: '603127.SS,  Joinn Laboratories(China)Co.,Ltd.',
    name: 'Joinn Laboratories(China)Co.,Ltd.',
    symbol: '603127.SS',
  },
  {
    value: '603160.SS',
    label: '603160.SS,  Shenzhen Goodix Technology Co., Ltd.',
    name: 'Shenzhen Goodix Technology Co., Ltd.',
    symbol: '603160.SS',
  },
  {
    value: '603185.SS',
    label: '603185.SS,  Wuxi Shangji Automation Co., Ltd.',
    name: 'Wuxi Shangji Automation Co., Ltd.',
    symbol: '603185.SS',
  },
  {
    value: '603218.SS',
    label: '603218.SS,  Riyue Heavy Industry Co., Ltd.',
    name: 'Riyue Heavy Industry Co., Ltd.',
    symbol: '603218.SS',
  },
  {
    value: '603228.SS',
    label: '603228.SS,  Shenzhen Kinwong Electronic Co., Ltd.',
    name: 'Shenzhen Kinwong Electronic Co., Ltd.',
    symbol: '603228.SS',
  },
  {
    value: '603233.SS',
    label: '603233.SS,  DaShenLin Pharmaceutical Group Co., Ltd.',
    name: 'DaShenLin Pharmaceutical Group Co., Ltd.',
    symbol: '603233.SS',
  },
  {
    value: '603259.SS',
    label: '603259.SS,  WuXi AppTec Co., Ltd.',
    name: 'WuXi AppTec Co., Ltd.',
    symbol: '603259.SS',
  },
  {
    value: '603267.SS',
    label:
      '603267.SS,  Beijing Yuanliu Hongyuan Electronic Technology Co., Ltd.',
    name: 'Beijing Yuanliu Hongyuan Electronic Technology Co., Ltd.',
    symbol: '603267.SS',
  },
  {
    value: '603279.SS',
    label: '603279.SS,  Jingjin Equipment Inc.',
    name: 'Jingjin Equipment Inc.',
    symbol: '603279.SS',
  },
  {
    value: '603288.SS',
    label: '603288.SS,  Foshan Haitian Flavouring and Food Company Ltd.',
    name: 'Foshan Haitian Flavouring and Food Company Ltd.',
    symbol: '603288.SS',
  },
  {
    value: '603290.SS',
    label: '603290.SS,  StarPower Semiconductor Ltd.',
    name: 'StarPower Semiconductor Ltd.',
    symbol: '603290.SS',
  },
  {
    value: '603298.SS',
    label: '603298.SS,  Hangcha Group Co., Ltd',
    name: 'Hangcha Group Co., Ltd',
    symbol: '603298.SS',
  },
  {
    value: '603317.SS',
    label: '603317.SS,  Sichuan Teway Food Group Co.,Ltd',
    name: 'Sichuan Teway Food Group Co.,Ltd',
    symbol: '603317.SS',
  },
  {
    value: '603338.SS',
    label: '603338.SS,  Zhejiang Dingli Machinery Co.,Ltd',
    name: 'Zhejiang Dingli Machinery Co.,Ltd',
    symbol: '603338.SS',
  },
  {
    value: '603345.SS',
    label: '603345.SS,  Anjoy Foods Group Co., Ltd.',
    name: 'Anjoy Foods Group Co., Ltd.',
    symbol: '603345.SS',
  },
  {
    value: '603355.SS',
    label: '603355.SS,  Kingclean Electric Co.,Ltd',
    name: 'Kingclean Electric Co.,Ltd',
    symbol: '603355.SS',
  },
  {
    value: '603360.SS',
    label: '603360.SS,  Dalian BIO-CHEM Company Limited',
    name: 'Dalian BIO-CHEM Company Limited',
    symbol: '603360.SS',
  },
  {
    value: '603363.SS',
    label:
      '603363.SS,  Fujian Aonong Biological Technology Group Incorporation Limited',
    name: 'Fujian Aonong Biological Technology Group Incorporation Limited',
    symbol: '603363.SS',
  },
  {
    value: '603369.SS',
    label: "603369.SS,  Jiangsu King's Luck Brewery Joint-Stock Co., Ltd.",
    name: "Jiangsu King's Luck Brewery Joint-Stock Co., Ltd.",
    symbol: '603369.SS',
  },
  {
    value: '603379.SS',
    label: '603379.SS,  Zhejiang Sanmei Chemical Industry Co., Ltd.',
    name: 'Zhejiang Sanmei Chemical Industry Co., Ltd.',
    symbol: '603379.SS',
  },
  {
    value: '603387.SS',
    label: '603387.SS,  Getein Biotech, Inc',
    name: 'Getein Biotech, Inc',
    symbol: '603387.SS',
  },
  {
    value: '603392.SS',
    label:
      '603392.SS,  Beijing Wantai Biological Pharmacy Enterprise Co., Ltd.',
    name: 'Beijing Wantai Biological Pharmacy Enterprise Co., Ltd.',
    symbol: '603392.SS',
  },
  {
    value: '603444.SS',
    label: '603444.SS,  G-bits Network Technology (Xiamen) Co., Ltd.',
    name: 'G-bits Network Technology (Xiamen) Co., Ltd.',
    symbol: '603444.SS',
  },
  {
    value: '603456.SS',
    label: '603456.SS,  Zhejiang Jiuzhou Pharmaceutical Co., Ltd',
    name: 'Zhejiang Jiuzhou Pharmaceutical Co., Ltd',
    symbol: '603456.SS',
  },
  {
    value: '603501.SS',
    label: '603501.SS,  Will Semiconductor Co., Ltd.',
    name: 'Will Semiconductor Co., Ltd.',
    symbol: '603501.SS',
  },
  {
    value: '603517.SS',
    label: '603517.SS,  Juewei Food Co., Ltd.',
    name: 'Juewei Food Co., Ltd.',
    symbol: '603517.SS',
  },
  {
    value: '603556.SS',
    label: '603556.SS,  Hexing Electrical Co.,Ltd.',
    name: 'Hexing Electrical Co.,Ltd.',
    symbol: '603556.SS',
  },
  {
    value: '603589.SS',
    label: '603589.SS,  Anhui Kouzi Distillery Co., Ltd.',
    name: 'Anhui Kouzi Distillery Co., Ltd.',
    symbol: '603589.SS',
  },
  {
    value: '6035.T',
    label: '6035.T,  IR Japan Holdings, Ltd.',
    name: 'IR Japan Holdings, Ltd.',
    symbol: '6035.T',
  },
  {
    value: '603605.SS',
    label: '603605.SS,  Proya Cosmetics Co.,Ltd.',
    name: 'Proya Cosmetics Co.,Ltd.',
    symbol: '603605.SS',
  },
  {
    value: '603609.SS',
    label: '603609.SS,  Wellhope Foods Co., Ltd.',
    name: 'Wellhope Foods Co., Ltd.',
    symbol: '603609.SS',
  },
  {
    value: '603613.SS',
    label: '603613.SS,  Beijing United Information Technology Co.,Ltd.',
    name: 'Beijing United Information Technology Co.,Ltd.',
    symbol: '603613.SS',
  },
  {
    value: '603638.SS',
    label: '603638.SS,  Yantai Eddie Precision Machinery Co., Ltd.',
    name: 'Yantai Eddie Precision Machinery Co., Ltd.',
    symbol: '603638.SS',
  },
  {
    value: '603658.SS',
    label: '603658.SS,  Autobio Diagnostics Co., Ltd.',
    name: 'Autobio Diagnostics Co., Ltd.',
    symbol: '603658.SS',
  },
  {
    value: '603659.SS',
    label: '603659.SS,  Shanghai Putailai New Energy Technology Co.,Ltd.',
    name: 'Shanghai Putailai New Energy Technology Co.,Ltd.',
    symbol: '603659.SS',
  },
  {
    value: '6036.T',
    label: '6036.T,  KeePer Technical Laboratory Co., Ltd.',
    name: 'KeePer Technical Laboratory Co., Ltd.',
    symbol: '6036.T',
  },
  {
    value: '603707.SS',
    label:
      '603707.SS,  Nanjing King-Friend Biochemical Pharmaceutical Co., Ltd',
    name: 'Nanjing King-Friend Biochemical Pharmaceutical Co., Ltd',
    symbol: '603707.SS',
  },
  {
    value: '603712.SS',
    label: '603712.SS,  TianJin 712 Communication & Broadcasting Co., Ltd.',
    name: 'TianJin 712 Communication & Broadcasting Co., Ltd.',
    symbol: '603712.SS',
  },
  {
    value: '603737.SS',
    label: '603737.SS,  SKSHU Paint Co.,Ltd.',
    name: 'SKSHU Paint Co.,Ltd.',
    symbol: '603737.SS',
  },
  {
    value: '603799.SS',
    label: '603799.SS,  Zhejiang Huayou Cobalt Co.,Ltd',
    name: 'Zhejiang Huayou Cobalt Co.,Ltd',
    symbol: '603799.SS',
  },
  {
    value: '603806.SS',
    label: '603806.SS,  Hangzhou First Applied Material Co., Ltd.',
    name: 'Hangzhou First Applied Material Co., Ltd.',
    symbol: '603806.SS',
  },
  {
    value: '603816.SS',
    label: '603816.SS,  Jason Furniture (Hangzhou) Co.,Ltd.',
    name: 'Jason Furniture (Hangzhou) Co.,Ltd.',
    symbol: '603816.SS',
  },
  {
    value: '603833.SS',
    label: '603833.SS,  Oppein Home Group Inc.',
    name: 'Oppein Home Group Inc.',
    symbol: '603833.SS',
  },
  {
    value: '603858.SS',
    label: '603858.SS,  Shandong Buchang Pharmaceuticals Co., Ltd.',
    name: 'Shandong Buchang Pharmaceuticals Co., Ltd.',
    symbol: '603858.SS',
  },
  {
    value: '603866.SS',
    label: '603866.SS,  Toly Bread Co.,Ltd.',
    name: 'Toly Bread Co.,Ltd.',
    symbol: '603866.SS',
  },
  {
    value: '603868.SS',
    label: '603868.SS,  Shanghai Flyco Electrical Appliance Co., Ltd.',
    name: 'Shanghai Flyco Electrical Appliance Co., Ltd.',
    symbol: '603868.SS',
  },
  {
    value: '603882.SS',
    label: '603882.SS,  Guangzhou Kingmed Diagnostics Group Co., Ltd.',
    name: 'Guangzhou Kingmed Diagnostics Group Co., Ltd.',
    symbol: '603882.SS',
  },
  {
    value: '603883.SS',
    label: '603883.SS,  LBX Pharmacy Chain Joint Stock Company',
    name: 'LBX Pharmacy Chain Joint Stock Company',
    symbol: '603883.SS',
  },
  {
    value: '603899.SS',
    label: '603899.SS,  Shanghai M&G Stationery Inc.',
    name: 'Shanghai M&G Stationery Inc.',
    symbol: '603899.SS',
  },
  {
    value: '603927.SS',
    label: '603927.SS,  Sinosoft Co.,Ltd',
    name: 'Sinosoft Co.,Ltd',
    symbol: '603927.SS',
  },
  {
    value: '603939.SS',
    label: '603939.SS,  Yifeng Pharmacy Chain Co., Ltd.',
    name: 'Yifeng Pharmacy Chain Co., Ltd.',
    symbol: '603939.SS',
  },
  {
    value: '603986.SS',
    label: '603986.SS,  GigaDevice Semiconductor (Beijing) Inc.',
    name: 'GigaDevice Semiconductor (Beijing) Inc.',
    symbol: '603986.SS',
  },
  {
    value: '603993.SS',
    label: '603993.SS,  CMOC Group Limited',
    name: 'CMOC Group Limited',
    symbol: '603993.SS',
  },
  {
    value: '6040.SR',
    label: '6040.SR,  Tabuk Agricultural Development Company',
    name: 'Tabuk Agricultural Development Company',
    symbol: '6040.SR',
  },
  {
    value: '6050.SR',
    label: '6050.SR,  Saudi Fisheries Company',
    name: 'Saudi Fisheries Company',
    symbol: '6050.SR',
  },
  {
    value: '6050.T',
    label: '6050.T,  E-Guardian Inc.',
    name: 'E-Guardian Inc.',
    symbol: '6050.T',
  },
  {
    value: '6055.T',
    label: '6055.T,  JAPAN MATERIAL Co., Ltd.',
    name: 'JAPAN MATERIAL Co., Ltd.',
    symbol: '6055.T',
  },
  {
    value: '6058.T',
    label: '6058.T,  Vector Inc.',
    name: 'Vector Inc.',
    symbol: '6058.T',
  },
  {
    value: '6070.SR',
    label: '6070.SR,  Al-Jouf Agricultural Development Co.',
    name: 'Al-Jouf Agricultural Development Co.',
    symbol: '6070.SR',
  },
  {
    value: '6070.T',
    label: '6070.T,  Careerlink Co., Ltd.',
    name: 'Careerlink Co., Ltd.',
    symbol: '6070.T',
  },
  {
    value: '6071.T',
    label: '6071.T,  IBJ, Inc.',
    name: 'IBJ, Inc.',
    symbol: '6071.T',
  },
  {
    value: '6073.T',
    label: '6073.T,  Asante Incorporated',
    name: 'Asante Incorporated',
    symbol: '6073.T',
  },
  {
    value: '6080.T',
    label: '6080.T,  M&A Capital Partners Co.,Ltd.',
    name: 'M&A Capital Partners Co.,Ltd.',
    symbol: '6080.T',
  },
  {
    value: '6088.T',
    label: '6088.T,  SIGMAXYZ Holdings Inc.',
    name: 'SIGMAXYZ Holdings Inc.',
    symbol: '6088.T',
  },
  {
    value: '6089.T',
    label: '6089.T,  Will Group, Inc.',
    name: 'Will Group, Inc.',
    symbol: '6089.T',
  },
  {
    value: '6090.SR',
    label: '6090.SR,  Jazan Energy and Development Company',
    name: 'Jazan Energy and Development Company',
    symbol: '6090.SR',
  },
  {
    value: '6095.T',
    label: '6095.T,  MedPeer,Inc.',
    name: 'MedPeer,Inc.',
    symbol: '6095.T',
  },
  {
    value: '6096.T',
    label: '6096.T,  RareJob Inc.',
    name: 'RareJob Inc.',
    symbol: '6096.T',
  },
  {
    value: '6098.T',
    label: '6098.T,  Recruit Holdings Co., Ltd.',
    name: 'Recruit Holdings Co., Ltd.',
    symbol: '6098.T',
  },
  {
    value: '6099.T',
    label: '6099.T,  Elan Corporation',
    name: 'Elan Corporation',
    symbol: '6099.T',
  },
  {
    value: '6101.T',
    label: '6101.T,  Tsugami Corporation',
    name: 'Tsugami Corporation',
    symbol: '6101.T',
  },
  {
    value: '6103.T',
    label: '6103.T,  Okuma Corporation',
    name: 'Okuma Corporation',
    symbol: '6103.T',
  },
  {
    value: '6104.T',
    label: '6104.T,  Shibaura Machine Co.,Ltd.',
    name: 'Shibaura Machine Co.,Ltd.',
    symbol: '6104.T',
  },
  {
    value: '6104.TWO',
    label: '6104.TWO,  Genesys Logic, Inc.',
    name: 'Genesys Logic, Inc.',
    symbol: '6104.TWO',
  },
  {
    value: '6108.TW',
    label: '6108.TW,  APCB Inc.',
    name: 'APCB Inc.',
    symbol: '6108.TW',
  },
  {
    value: '6112.TW',
    label: '6112.TW,  Metaage Corporation',
    name: 'Metaage Corporation',
    symbol: '6112.TW',
  },
  {
    value: '6113.T',
    label: '6113.T,  Amada Co., Ltd.',
    name: 'Amada Co., Ltd.',
    symbol: '6113.T',
  },
  {
    value: '6115.TW',
    label: '6115.TW,  I-Sheng Electric Wire & Cable Co., Ltd.',
    name: 'I-Sheng Electric Wire & Cable Co., Ltd.',
    symbol: '6115.TW',
  },
  {
    value: '6116.TW',
    label: '6116.TW,  HannStar Display Corporation',
    name: 'HannStar Display Corporation',
    symbol: '6116.TW',
  },
  {
    value: '6118.T',
    label: '6118.T,  Aida Engineering, Ltd.',
    name: 'Aida Engineering, Ltd.',
    symbol: '6118.T',
  },
  {
    value: '6120.TW',
    label: '6120.TW,  Darwin Precisions Corporation',
    name: 'Darwin Precisions Corporation',
    symbol: '6120.TW',
  },
  {
    value: '6121.TWO',
    label: '6121.TWO,  Simplo Technology Co., Ltd.',
    name: 'Simplo Technology Co., Ltd.',
    symbol: '6121.TWO',
  },
  {
    value: '6125.T',
    label: '6125.T,  Okamoto Machine Tool Works, Ltd.',
    name: 'Okamoto Machine Tool Works, Ltd.',
    symbol: '6125.T',
  },
  {
    value: '6126.TWO',
    label: '6126.TWO,  Singatron Enterprise Co.,Ltd',
    name: 'Singatron Enterprise Co.,Ltd',
    symbol: '6126.TWO',
  },
  {
    value: '6134.T',
    label: '6134.T,  Fuji Corporation',
    name: 'Fuji Corporation',
    symbol: '6134.T',
  },
  {
    value: '6135.T',
    label: '6135.T,  Makino Milling Machine Co., Ltd.',
    name: 'Makino Milling Machine Co., Ltd.',
    symbol: '6135.T',
  },
  {
    value: '6136.T',
    label: '6136.T,  OSG Corporation',
    name: 'OSG Corporation',
    symbol: '6136.T',
  },
  {
    value: '6136.TW',
    label: '6136.TW,  Fullerton Technology Co., Ltd.',
    name: 'Fullerton Technology Co., Ltd.',
    symbol: '6136.TW',
  },
  {
    value: '6138.TWO',
    label: '6138.TWO,  Anpec Electronics Corporation',
    name: 'Anpec Electronics Corporation',
    symbol: '6138.TWO',
  },
  {
    value: '6139.TW',
    label: '6139.TW,  L&K Engineering Co., Ltd.',
    name: 'L&K Engineering Co., Ltd.',
    symbol: '6139.TW',
  },
  {
    value: '6140.T',
    label: '6140.T,  Asahi Diamond Industrial Co., Ltd.',
    name: 'Asahi Diamond Industrial Co., Ltd.',
    symbol: '6140.T',
  },
  {
    value: '6141.T',
    label: '6141.T,  DMG Mori Co., Ltd.',
    name: 'DMG Mori Co., Ltd.',
    symbol: '6141.T',
  },
  {
    value: '6143.T',
    label: '6143.T,  Sodick Co., Ltd.',
    name: 'Sodick Co., Ltd.',
    symbol: '6143.T',
  },
  {
    value: '6145.T',
    label: '6145.T,  Nittoku Co., Ltd.',
    name: 'Nittoku Co., Ltd.',
    symbol: '6145.T',
  },
  {
    value: '6146.T',
    label: '6146.T,  Disco Corporation',
    name: 'Disco Corporation',
    symbol: '6146.T',
  },
  {
    value: '6147.TWO',
    label: '6147.TWO,  Chipbond Technology Corporation',
    name: 'Chipbond Technology Corporation',
    symbol: '6147.TWO',
  },
  {
    value: '6153.TW',
    label: '6153.TW,  Career Technology (Mfg.) Co., Ltd.',
    name: 'Career Technology (Mfg.) Co., Ltd.',
    symbol: '6153.TW',
  },
  {
    value: '6157.T',
    label: '6157.T,  NS Tool Co., Ltd.',
    name: 'NS Tool Co., Ltd.',
    symbol: '6157.T',
  },
  {
    value: '6168.TW',
    label: '6168.TW,  Harvatek Corporation',
    name: 'Harvatek Corporation',
    symbol: '6168.TW',
  },
  {
    value: '6173.TWO',
    label: '6173.TWO,  Prosperity Dielectrics Co., Ltd.',
    name: 'Prosperity Dielectrics Co., Ltd.',
    symbol: '6173.TWO',
  },
  {
    value: '6175.TWO',
    label: '6175.TWO,  Liton Technology Corp.',
    name: 'Liton Technology Corp.',
    symbol: '6175.TWO',
  },
  {
    value: '6176.TW',
    label: '6176.TW,  Radiant Opto-Electronics Corporation',
    name: 'Radiant Opto-Electronics Corporation',
    symbol: '6176.TW',
  },
  {
    value: '6177.TW',
    label: '6177.TW,  Da-Li Development Co.,Ltd.',
    name: 'Da-Li Development Co.,Ltd.',
    symbol: '6177.TW',
  },
  {
    value: '6178.T',
    label: '6178.T,  Japan Post Holdings Co., Ltd.',
    name: 'Japan Post Holdings Co., Ltd.',
    symbol: '6178.T',
  },
  {
    value: '6180.TWO',
    label: '6180.TWO,  Gamania Digital Entertainment Co., Ltd.',
    name: 'Gamania Digital Entertainment Co., Ltd.',
    symbol: '6180.TWO',
  },
  {
    value: '6182.TWO',
    label: '6182.TWO,  Wafer Works Corporation',
    name: 'Wafer Works Corporation',
    symbol: '6182.TWO',
  },
  {
    value: '6183.T',
    label: '6183.T,  BELLSYSTEM24 Holdings, Inc.',
    name: 'BELLSYSTEM24 Holdings, Inc.',
    symbol: '6183.T',
  },
  {
    value: '6183.TW',
    label: '6183.TW,  Trade-Van Information Services Co.',
    name: 'Trade-Van Information Services Co.',
    symbol: '6183.TW',
  },
  {
    value: '6184.T',
    label: '6184.T,  Kamakura Shinsho, Ltd.',
    name: 'Kamakura Shinsho, Ltd.',
    symbol: '6184.T',
  },
  {
    value: '6184.TW',
    label: '6184.TW,  Dafeng TV Ltd.',
    name: 'Dafeng TV Ltd.',
    symbol: '6184.TW',
  },
  {
    value: '6188.TWO',
    label: '6188.TWO,  Quanta Storage Inc.',
    name: 'Quanta Storage Inc.',
    symbol: '6188.TWO',
  },
  {
    value: '6189.TW',
    label: '6189.TW,  Promate Electronic Co.,Ltd.',
    name: 'Promate Electronic Co.,Ltd.',
    symbol: '6189.TW',
  },
  {
    value: '6191.T',
    label: '6191.T,  AirTrip Corp.',
    name: 'AirTrip Corp.',
    symbol: '6191.T',
  },
  {
    value: '6191.TW',
    label: '6191.TW,  Global Brands Manufacture Ltd.',
    name: 'Global Brands Manufacture Ltd.',
    symbol: '6191.TW',
  },
  {
    value: '6192.TW',
    label: '6192.TW,  Lumax International Corp., Ltd.',
    name: 'Lumax International Corp., Ltd.',
    symbol: '6192.TW',
  },
  {
    value: '6194.T',
    label: '6194.T,  Atrae, Inc.',
    name: 'Atrae, Inc.',
    symbol: '6194.T',
  },
  {
    value: '6196.T',
    label: '6196.T,  Strike Company,Limited',
    name: 'Strike Company,Limited',
    symbol: '6196.T',
  },
  {
    value: '6196.TW',
    label: '6196.TW,  Marketech International Corp.',
    name: 'Marketech International Corp.',
    symbol: '6196.TW',
  },
  {
    value: '6197.T',
    label: '6197.T,  Solasto Corporation',
    name: 'Solasto Corporation',
    symbol: '6197.T',
  },
  {
    value: '6197.TW',
    label: '6197.TW,  Jess-link Products Co., Ltd.',
    name: 'Jess-link Products Co., Ltd.',
    symbol: '6197.TW',
  },
  {
    value: '6200.T',
    label: '6200.T,  Insource Co., Ltd.',
    name: 'Insource Co., Ltd.',
    symbol: '6200.T',
  },
  {
    value: '6201.T',
    label: '6201.T,  Toyota Industries Corporation',
    name: 'Toyota Industries Corporation',
    symbol: '6201.T',
  },
  {
    value: '6202.TW',
    label: '6202.TW,  Holtek Semiconductor Inc.',
    name: 'Holtek Semiconductor Inc.',
    symbol: '6202.TW',
  },
  {
    value: '6203.T',
    label: '6203.T,  Howa Machinery, Ltd.',
    name: 'Howa Machinery, Ltd.',
    symbol: '6203.T',
  },
  {
    value: '6203.TWO',
    label: '6203.TWO,  Sea Sonic Electronics Co., Ltd.',
    name: 'Sea Sonic Electronics Co., Ltd.',
    symbol: '6203.TWO',
  },
  {
    value: '6206.TW',
    label: '6206.TW,  Flytech Technology Co., Ltd.',
    name: 'Flytech Technology Co., Ltd.',
    symbol: '6206.TW',
  },
  {
    value: '6209.TW',
    label: '6209.TW,  Kinko Optical Co., Ltd.',
    name: 'Kinko Optical Co., Ltd.',
    symbol: '6209.TW',
  },
  {
    value: '6213.TW',
    label: '6213.TW,  ITEQ Corporation',
    name: 'ITEQ Corporation',
    symbol: '6213.TW',
  },
  {
    value: '6214.TW',
    label: '6214.TW,  Systex Corporation',
    name: 'Systex Corporation',
    symbol: '6214.TW',
  },
  {
    value: '6219.TWO',
    label: '6219.TWO,  Full Wang International Development Co., Ltd.',
    name: 'Full Wang International Development Co., Ltd.',
    symbol: '6219.TWO',
  },
  {
    value: '6222.T',
    label: '6222.T,  Shima Seiki Mfg.,Ltd.',
    name: 'Shima Seiki Mfg.,Ltd.',
    symbol: '6222.T',
  },
  {
    value: '6223.TWO',
    label: '6223.TWO,  MPI Corporation',
    name: 'MPI Corporation',
    symbol: '6223.TWO',
  },
  {
    value: '6224.TW',
    label: '6224.TW,  Polytronics Technology Corp.',
    name: 'Polytronics Technology Corp.',
    symbol: '6224.TW',
  },
  {
    value: '6230.TW',
    label: '6230.TW,  Nidec Chaun-Choung Technology Corporation',
    name: 'Nidec Chaun-Choung Technology Corporation',
    symbol: '6230.TW',
  },
  {
    value: '6232.T',
    label: '6232.T,  ACSL Ltd.',
    name: 'ACSL Ltd.',
    symbol: '6232.T',
  },
  {
    value: '6235.T',
    label: '6235.T,  Optorun Co.,Ltd.',
    name: 'Optorun Co.,Ltd.',
    symbol: '6235.T',
  },
  {
    value: '6238.T',
    label: '6238.T,  FURYU Corporation',
    name: 'FURYU Corporation',
    symbol: '6238.T',
  },
  {
    value: '6239.TW',
    label: '6239.TW,  Powertech Technology Inc.',
    name: 'Powertech Technology Inc.',
    symbol: '6239.TW',
  },
  {
    value: '6240.T',
    label: '6240.T,  Yamashin-Filter Corp.',
    name: 'Yamashin-Filter Corp.',
    symbol: '6240.T',
  },
  {
    value: '6244.TWO',
    label: '6244.TWO,  Motech Industries Inc.',
    name: 'Motech Industries Inc.',
    symbol: '6244.TWO',
  },
  {
    value: '6245.T',
    label: '6245.T,  HIRANO TECSEED Co.,Ltd.',
    name: 'HIRANO TECSEED Co.,Ltd.',
    symbol: '6245.T',
  },
  {
    value: '6245.TWO',
    label: '6245.TWO,  Lanner Electronics Inc.',
    name: 'Lanner Electronics Inc.',
    symbol: '6245.TWO',
  },
  {
    value: '6247.T',
    label: '6247.T,  Hisaka Works, Ltd.',
    name: 'Hisaka Works, Ltd.',
    symbol: '6247.T',
  },
  {
    value: '6250.T',
    label: '6250.T,  Yamabiko Corporation',
    name: 'Yamabiko Corporation',
    symbol: '6250.T',
  },
  {
    value: '6254.T',
    label: '6254.T,  Nomura Micro Science Co., Ltd.',
    name: 'Nomura Micro Science Co., Ltd.',
    symbol: '6254.T',
  },
  {
    value: '6255.T',
    label: '6255.T,  NPC Incorporated',
    name: 'NPC Incorporated',
    symbol: '6255.T',
  },
  {
    value: '6257.TW',
    label: '6257.TW,  Sigurd Microelectronics Corporation',
    name: 'Sigurd Microelectronics Corporation',
    symbol: '6257.TW',
  },
  {
    value: '6258.T',
    label: '6258.T,  Hirata Corporation',
    name: 'Hirata Corporation',
    symbol: '6258.T',
  },
  {
    value: '6261.TWO',
    label: '6261.TWO,  Youngtek Electronics Corporation',
    name: 'Youngtek Electronics Corporation',
    symbol: '6261.TWO',
  },
  {
    value: '6264.T',
    label: '6264.T,  Marumae Co., Ltd.',
    name: 'Marumae Co., Ltd.',
    symbol: '6264.T',
  },
  {
    value: '6266.T',
    label: '6266.T,  Tazmo Co., Ltd.',
    name: 'Tazmo Co., Ltd.',
    symbol: '6266.T',
  },
  {
    value: '6268.T',
    label: '6268.T,  Nabtesco Corporation',
    name: 'Nabtesco Corporation',
    symbol: '6268.T',
  },
  {
    value: '6269.T',
    label: '6269.T,  MODEC, Inc.',
    name: 'MODEC, Inc.',
    symbol: '6269.T',
  },
  {
    value: '6269.TW',
    label: '6269.TW,  Flexium Interconnect, Inc.',
    name: 'Flexium Interconnect, Inc.',
    symbol: '6269.TW',
  },
  {
    value: '6271.TW',
    label: '6271.TW,  Tong Hsing Electronic Industries, Ltd.',
    name: 'Tong Hsing Electronic Industries, Ltd.',
    symbol: '6271.TW',
  },
  {
    value: '6272.T',
    label: '6272.T,  Rheon Automatic Machinery Co., Ltd.',
    name: 'Rheon Automatic Machinery Co., Ltd.',
    symbol: '6272.T',
  },
  {
    value: '6273.T',
    label: '6273.T,  SMC Corporation',
    name: 'SMC Corporation',
    symbol: '6273.T',
  },
  {
    value: '6274.TWO',
    label: '6274.TWO,  Taiwan Union Technology Corporation',
    name: 'Taiwan Union Technology Corporation',
    symbol: '6274.TWO',
  },
  {
    value: '6277.T',
    label: '6277.T,  Hosokawa Micron Corporation',
    name: 'Hosokawa Micron Corporation',
    symbol: '6277.T',
  },
  {
    value: '6277.TW',
    label: '6277.TW,  Aten International Co., Ltd',
    name: 'Aten International Co., Ltd',
    symbol: '6277.TW',
  },
  {
    value: '6278.T',
    label: '6278.T,  Union Tool Co.',
    name: 'Union Tool Co.',
    symbol: '6278.T',
  },
  {
    value: '6278.TW',
    label: '6278.TW,  Taiwan Surface Mounting Technology Corp.',
    name: 'Taiwan Surface Mounting Technology Corp.',
    symbol: '6278.TW',
  },
  {
    value: '6279.TWO',
    label: '6279.TWO,  Hu Lane Associate Inc.',
    name: 'Hu Lane Associate Inc.',
    symbol: '6279.TWO',
  },
  {
    value: '6281.TW',
    label: '6281.TW,  E-Life Corporation',
    name: 'E-Life Corporation',
    symbol: '6281.TW',
  },
  {
    value: '6282.T',
    label: '6282.T,  Oiles Corporation',
    name: 'Oiles Corporation',
    symbol: '6282.T',
  },
  {
    value: '6282.TW',
    label: '6282.TW,  AcBel Polytech Inc.',
    name: 'AcBel Polytech Inc.',
    symbol: '6282.TW',
  },
  {
    value: '6283.TW',
    label: '6283.TW,  Shun On Electronic Co., Ltd.',
    name: 'Shun On Electronic Co., Ltd.',
    symbol: '6283.TW',
  },
  {
    value: '6284.T',
    label: '6284.T,  Nissei ASB Machine Co., Ltd.',
    name: 'Nissei ASB Machine Co., Ltd.',
    symbol: '6284.T',
  },
  {
    value: '6284.TWO',
    label: '6284.TWO,  INPAQ Technology Co., Ltd.',
    name: 'INPAQ Technology Co., Ltd.',
    symbol: '6284.TWO',
  },
  {
    value: '6285.TW',
    label: '6285.TW,  Wistron NeWeb Corporation',
    name: 'Wistron NeWeb Corporation',
    symbol: '6285.TW',
  },
  {
    value: '6287.T',
    label: '6287.T,  Sato Holdings Corporation',
    name: 'Sato Holdings Corporation',
    symbol: '6287.T',
  },
  {
    value: '6289.T',
    label: '6289.T,  Giken Ltd.',
    name: 'Giken Ltd.',
    symbol: '6289.T',
  },
  {
    value: '6290.TWO',
    label: '6290.TWO,  Longwell Company',
    name: 'Longwell Company',
    symbol: '6290.TWO',
  },
  {
    value: '6293.T',
    label: '6293.T,  Nissei Plastic Industrial Co.,Ltd.',
    name: 'Nissei Plastic Industrial Co.,Ltd.',
    symbol: '6293.T',
  },
  {
    value: '6301.T',
    label: '6301.T,  Komatsu Ltd.',
    name: 'Komatsu Ltd.',
    symbol: '6301.T',
  },
  {
    value: '6302.T',
    label: '6302.T,  Sumitomo Heavy Industries, Ltd.',
    name: 'Sumitomo Heavy Industries, Ltd.',
    symbol: '6302.T',
  },
  {
    value: '6305.T',
    label: '6305.T,  Hitachi Construction Machinery Co., Ltd.',
    name: 'Hitachi Construction Machinery Co., Ltd.',
    symbol: '6305.T',
  },
  {
    value: '6306.T',
    label: '6306.T,  Nikko Co., Ltd.',
    name: 'Nikko Co., Ltd.',
    symbol: '6306.T',
  },
  {
    value: '6309.T',
    label: '6309.T,  Tomoe Engineering Co., Ltd.',
    name: 'Tomoe Engineering Co., Ltd.',
    symbol: '6309.T',
  },
  {
    value: '6310.T',
    label: '6310.T,  Iseki & Co., Ltd.',
    name: 'Iseki & Co., Ltd.',
    symbol: '6310.T',
  },
  {
    value: '6315.T',
    label: '6315.T,  TOWA Corporation',
    name: 'TOWA Corporation',
    symbol: '6315.T',
  },
  {
    value: '6319.T',
    label: '6319.T,  SNT Corporation',
    name: 'SNT Corporation',
    symbol: '6319.T',
  },
  {
    value: '6323.T',
    label: '6323.T,  Rorze Corporation',
    name: 'Rorze Corporation',
    symbol: '6323.T',
  },
  {
    value: '6324.T',
    label: '6324.T,  Harmonic Drive Systems Inc.',
    name: 'Harmonic Drive Systems Inc.',
    symbol: '6324.T',
  },
  {
    value: '6326.T',
    label: '6326.T,  Kubota Corporation',
    name: 'Kubota Corporation',
    symbol: '6326.T',
  },
  {
    value: '6328.T',
    label: '6328.T,  Ebara Jitsugyo Co.,Ltd.',
    name: 'Ebara Jitsugyo Co.,Ltd.',
    symbol: '6328.T',
  },
  {
    value: '6330.T',
    label: '6330.T,  Toyo Engineering Corporation',
    name: 'Toyo Engineering Corporation',
    symbol: '6330.T',
  },
  {
    value: '6331.T',
    label: '6331.T,  Mitsubishi Kakoki Kaisha, Ltd.',
    name: 'Mitsubishi Kakoki Kaisha, Ltd.',
    symbol: '6331.T',
  },
  {
    value: '6332.T',
    label: '6332.T,  Tsukishima Kikai Co., Ltd.',
    name: 'Tsukishima Kikai Co., Ltd.',
    symbol: '6332.T',
  },
  {
    value: '6333.T',
    label: '6333.T,  Teikoku Electric Mfg.Co.,Ltd.',
    name: 'Teikoku Electric Mfg.Co.,Ltd.',
    symbol: '6333.T',
  },
  {
    value: '6339.T',
    label: '6339.T,  Sintokogio,Ltd.',
    name: 'Sintokogio,Ltd.',
    symbol: '6339.T',
  },
  {
    value: '6340.T',
    label: '6340.T,  Shibuya Corporation',
    name: 'Shibuya Corporation',
    symbol: '6340.T',
  },
  {
    value: '6345.T',
    label: '6345.T,  Aichi Corporation',
    name: 'Aichi Corporation',
    symbol: '6345.T',
  },
  {
    value: '6349.T',
    label: '6349.T,  Komori Corporation',
    name: 'Komori Corporation',
    symbol: '6349.T',
  },
  {
    value: '6351.T',
    label: '6351.T,  Tsurumi Manufacturing Co., Ltd.',
    name: 'Tsurumi Manufacturing Co., Ltd.',
    symbol: '6351.T',
  },
  {
    value: '6361.T',
    label: '6361.T,  Ebara Corporation',
    name: 'Ebara Corporation',
    symbol: '6361.T',
  },
  {
    value: '6363.T',
    label: '6363.T,  Torishima Pump Mfg. Co., Ltd.',
    name: 'Torishima Pump Mfg. Co., Ltd.',
    symbol: '6363.T',
  },
  {
    value: '6364.T',
    label: '6364.T,  Hokuetsu Industries Co., Ltd.',
    name: 'Hokuetsu Industries Co., Ltd.',
    symbol: '6364.T',
  },
  {
    value: '6366.T',
    label: '6366.T,  Chiyoda Corporation',
    name: 'Chiyoda Corporation',
    symbol: '6366.T',
  },
  {
    value: '6367.T',
    label: '6367.T,  Daikin Industries,Ltd.',
    name: 'Daikin Industries,Ltd.',
    symbol: '6367.T',
  },
  {
    value: '6368.T',
    label: '6368.T,  Organo Corporation',
    name: 'Organo Corporation',
    symbol: '6368.T',
  },
  {
    value: '6369.T',
    label: '6369.T,  Toyo Kanetsu K.K.',
    name: 'Toyo Kanetsu K.K.',
    symbol: '6369.T',
  },
  {
    value: '6370.T',
    label: '6370.T,  Kurita Water Industries Ltd.',
    name: 'Kurita Water Industries Ltd.',
    symbol: '6370.T',
  },
  {
    value: '6371.T',
    label: '6371.T,  Tsubakimoto Chain Co.',
    name: 'Tsubakimoto Chain Co.',
    symbol: '6371.T',
  },
  {
    value: '6376.T',
    label: '6376.T,  Nikkiso Co., Ltd.',
    name: 'Nikkiso Co., Ltd.',
    symbol: '6376.T',
  },
  {
    value: '6378.T',
    label: '6378.T,  Kimura Chemical Plants Co., Ltd.',
    name: 'Kimura Chemical Plants Co., Ltd.',
    symbol: '6378.T',
  },
  {
    value: '6379.T',
    label: '6379.T,  RAIZNEXT Corporation',
    name: 'RAIZNEXT Corporation',
    symbol: '6379.T',
  },
  {
    value: '6381.T',
    label: '6381.T,  ANEST IWATA Corporation',
    name: 'ANEST IWATA Corporation',
    symbol: '6381.T',
  },
  {
    value: '6383.T',
    label: '6383.T,  Daifuku Co., Ltd.',
    name: 'Daifuku Co., Ltd.',
    symbol: '6383.T',
  },
  {
    value: '6390.T',
    label: '6390.T,  Kato Works Co.,Ltd.',
    name: 'Kato Works Co.,Ltd.',
    symbol: '6390.T',
  },
  {
    value: '6395.T',
    label: '6395.T,  Tadano Ltd.',
    name: 'Tadano Ltd.',
    symbol: '6395.T',
  },
  {
    value: '6406.T',
    label: '6406.T,  Fujitec Co., Ltd.',
    name: 'Fujitec Co., Ltd.',
    symbol: '6406.T',
  },
  {
    value: '6407.T',
    label: '6407.T,  CKD Corporation',
    name: 'CKD Corporation',
    symbol: '6407.T',
  },
  {
    value: '6409.T',
    label: '6409.T,  KITO Corporation',
    name: 'KITO Corporation',
    symbol: '6409.T',
  },
  {
    value: '6409.TW',
    label: '6409.TW,  Voltronic Power Technology Corp.',
    name: 'Voltronic Power Technology Corp.',
    symbol: '6409.TW',
  },
  {
    value: '6412.T',
    label: '6412.T,  Heiwa Corporation',
    name: 'Heiwa Corporation',
    symbol: '6412.T',
  },
  {
    value: '6412.TW',
    label: '6412.TW,  Chicony Power Technology Co., Ltd.',
    name: 'Chicony Power Technology Co., Ltd.',
    symbol: '6412.TW',
  },
  {
    value: '6413.T',
    label: '6413.T,  Riso Kagaku Corporation',
    name: 'Riso Kagaku Corporation',
    symbol: '6413.T',
  },
  {
    value: '6414.TW',
    label: '6414.TW,  Ennoconn Corporation',
    name: 'Ennoconn Corporation',
    symbol: '6414.TW',
  },
  {
    value: '6415.TW',
    label: '6415.TW,  Silergy Corp.',
    name: 'Silergy Corp.',
    symbol: '6415.TW',
  },
  {
    value: '6416.TW',
    label: '6416.TW,  CASwell Inc.',
    name: 'CASwell Inc.',
    symbol: '6416.TW',
  },
  {
    value: '6417.T',
    label: '6417.T,  Sankyo Co., Ltd.',
    name: 'Sankyo Co., Ltd.',
    symbol: '6417.T',
  },
  {
    value: '6419.T',
    label: '6419.T,  Mars Group Holdings Corporation',
    name: 'Mars Group Holdings Corporation',
    symbol: '6419.T',
  },
  {
    value: '6420.T',
    label: '6420.T,  Fukushima Galilei Co.Ltd.',
    name: 'Fukushima Galilei Co.Ltd.',
    symbol: '6420.T',
  },
  {
    value: '6425.T',
    label: '6425.T,  Universal Entertainment Corporation',
    name: 'Universal Entertainment Corporation',
    symbol: '6425.T',
  },
  {
    value: '6432.T',
    label: '6432.T,  Takeuchi Mfg. Co., Ltd.',
    name: 'Takeuchi Mfg. Co., Ltd.',
    symbol: '6432.T',
  },
  {
    value: '6435.TWO',
    label: '6435.TWO,  Sinopower Semiconductor, Inc.',
    name: 'Sinopower Semiconductor, Inc.',
    symbol: '6435.TWO',
  },
  {
    value: '6436.T',
    label: '6436.T,  Amano Corporation',
    name: 'Amano Corporation',
    symbol: '6436.T',
  },
  {
    value: '6440.T',
    label: '6440.T,  Juki Corporation',
    name: 'Juki Corporation',
    symbol: '6440.T',
  },
  {
    value: '6443.TW',
    label: '6443.TW,  TSEC Corporation',
    name: 'TSEC Corporation',
    symbol: '6443.TW',
  },
  {
    value: '6445.T',
    label: '6445.T,  JANOME Corporation',
    name: 'JANOME Corporation',
    symbol: '6445.T',
  },
  {
    value: '6448.T',
    label: '6448.T,  Brother Industries, Ltd.',
    name: 'Brother Industries, Ltd.',
    symbol: '6448.T',
  },
  {
    value: '6449.TW',
    label: '6449.TW,  Apaq Technology Co., Ltd.',
    name: 'Apaq Technology Co., Ltd.',
    symbol: '6449.TW',
  },
  {
    value: '6451.TW',
    label: '6451.TW,  ShunSin Technology Holdings Limited',
    name: 'ShunSin Technology Holdings Limited',
    symbol: '6451.TW',
  },
  {
    value: '6454.T',
    label: '6454.T,  Max Co., Ltd.',
    name: 'Max Co., Ltd.',
    symbol: '6454.T',
  },
  {
    value: '6455.T',
    label: '6455.T,  Morita Holdings Corporation',
    name: 'Morita Holdings Corporation',
    symbol: '6455.T',
  },
  {
    value: '6456.TW',
    label: '6456.TW,  General Interface Solution (GIS) Holding Limited',
    name: 'General Interface Solution (GIS) Holding Limited',
    symbol: '6456.TW',
  },
  {
    value: '6457.T',
    label: '6457.T,  Glory Ltd.',
    name: 'Glory Ltd.',
    symbol: '6457.T',
  },
  {
    value: '6457.TWO',
    label: '6457.TWO,  Hycon Technology Corporation',
    name: 'Hycon Technology Corporation',
    symbol: '6457.TWO',
  },
  {
    value: '6458.T',
    label: '6458.T,  Sinko Industries Ltd.',
    name: 'Sinko Industries Ltd.',
    symbol: '6458.T',
  },
  {
    value: '6459.T',
    label: '6459.T,  Daiwa Industries Ltd.',
    name: 'Daiwa Industries Ltd.',
    symbol: '6459.T',
  },
  {
    value: '6460.T',
    label: '6460.T,  Sega Sammy Holdings Inc.',
    name: 'Sega Sammy Holdings Inc.',
    symbol: '6460.T',
  },
  {
    value: '6462.T',
    label: '6462.T,  Riken Corporation',
    name: 'Riken Corporation',
    symbol: '6462.T',
  },
  {
    value: '6462.TWO',
    label: '6462.TWO,  Egis Technology Inc.',
    name: 'Egis Technology Inc.',
    symbol: '6462.TWO',
  },
  {
    value: '6463.T',
    label: '6463.T,  TPR Co., Ltd.',
    name: 'TPR Co., Ltd.',
    symbol: '6463.T',
  },
  {
    value: '6464.T',
    label: '6464.T,  Tsubaki Nakashima Co., Ltd.',
    name: 'Tsubaki Nakashima Co., Ltd.',
    symbol: '6464.T',
  },
  {
    value: '6465.T',
    label: '6465.T,  Hoshizaki Corporation',
    name: 'Hoshizaki Corporation',
    symbol: '6465.T',
  },
  {
    value: '6469.TWO',
    label: '6469.TWO,  Great Tree Pharmacy Co., Ltd.',
    name: 'Great Tree Pharmacy Co., Ltd.',
    symbol: '6469.TWO',
  },
  {
    value: '6470.T',
    label: '6470.T,  Taiho Kogyo Co., Ltd.',
    name: 'Taiho Kogyo Co., Ltd.',
    symbol: '6470.T',
  },
  {
    value: '6471.T',
    label: '6471.T,  NSK Ltd.',
    name: 'NSK Ltd.',
    symbol: '6471.T',
  },
  {
    value: '6472.T',
    label: '6472.T,  NTN Corporation',
    name: 'NTN Corporation',
    symbol: '6472.T',
  },
  {
    value: '6473.T',
    label: '6473.T,  JTEKT Corporation',
    name: 'JTEKT Corporation',
    symbol: '6473.T',
  },
  {
    value: '6474.T',
    label: '6474.T,  Nachi-Fujikoshi Corp.',
    name: 'Nachi-Fujikoshi Corp.',
    symbol: '6474.T',
  },
  {
    value: '6479.T',
    label: '6479.T,  MINEBEA MITSUMI Inc.',
    name: 'MINEBEA MITSUMI Inc.',
    symbol: '6479.T',
  },
  {
    value: '6480.T',
    label: '6480.T,  Nippon Thompson Co., Ltd.',
    name: 'Nippon Thompson Co., Ltd.',
    symbol: '6480.T',
  },
  {
    value: '6481.T',
    label: '6481.T,  THK Co., Ltd.',
    name: 'THK Co., Ltd.',
    symbol: '6481.T',
  },
  {
    value: '6482.T',
    label: '6482.T,  Yushin Precision Equipment Co., Ltd.',
    name: 'Yushin Precision Equipment Co., Ltd.',
    symbol: '6482.T',
  },
  {
    value: '6486.T',
    label: '6486.T,  Eagle Industry Co.,Ltd.',
    name: 'Eagle Industry Co.,Ltd.',
    symbol: '6486.T',
  },
  {
    value: '6486.TWO',
    label: '6486.TWO,  Interactive Digital Technologies Inc.',
    name: 'Interactive Digital Technologies Inc.',
    symbol: '6486.TWO',
  },
  {
    value: '6488.TWO',
    label: '6488.TWO,  GlobalWafers Co., Ltd.',
    name: 'GlobalWafers Co., Ltd.',
    symbol: '6488.TWO',
  },
  {
    value: '6490.T',
    label: '6490.T,  Nippon Pillar Packing Co., Ltd.',
    name: 'Nippon Pillar Packing Co., Ltd.',
    symbol: '6490.T',
  },
  {
    value: '6491.TW',
    label: '6491.TW,  Pegavision Corporation',
    name: 'Pegavision Corporation',
    symbol: '6491.TW',
  },
  {
    value: '6498.T',
    label: '6498.T,  KITZ Corporation',
    name: 'KITZ Corporation',
    symbol: '6498.T',
  },
  {
    value: '6501.T',
    label: '6501.T,  Hitachi, Ltd.',
    name: 'Hitachi, Ltd.',
    symbol: '6501.T',
  },
  {
    value: '6502.T',
    label: '6502.T,  Toshiba Corporation',
    name: 'Toshiba Corporation',
    symbol: '6502.T',
  },
  {
    value: '6503.T',
    label: '6503.T,  Mitsubishi Electric Corporation',
    name: 'Mitsubishi Electric Corporation',
    symbol: '6503.T',
  },
  {
    value: '6504.T',
    label: '6504.T,  Fuji Electric Co., Ltd.',
    name: 'Fuji Electric Co., Ltd.',
    symbol: '6504.T',
  },
  {
    value: '6504.TW',
    label: '6504.TW,  Nan Liu Enterprise Co., Ltd.',
    name: 'Nan Liu Enterprise Co., Ltd.',
    symbol: '6504.TW',
  },
  {
    value: '6505.T',
    label: '6505.T,  Toyo Denki Seizo K.K.',
    name: 'Toyo Denki Seizo K.K.',
    symbol: '6505.T',
  },
  {
    value: '6505.TW',
    label: '6505.TW,  Formosa Petrochemical Corporation',
    name: 'Formosa Petrochemical Corporation',
    symbol: '6505.TW',
  },
  {
    value: '6506.T',
    label: '6506.T,  YASKAWA Electric Corporation',
    name: 'YASKAWA Electric Corporation',
    symbol: '6506.T',
  },
  {
    value: '6507.T',
    label: '6507.T,  Sinfonia Technology Co.,Ltd.',
    name: 'Sinfonia Technology Co.,Ltd.',
    symbol: '6507.T',
  },
  {
    value: '6508.T',
    label: '6508.T,  Meidensha Corporation',
    name: 'Meidensha Corporation',
    symbol: '6508.T',
  },
  {
    value: '6509.TWO',
    label: '6509.TWO,  Taiwan Hopax Chemicals Manufacturing Co., Ltd.',
    name: 'Taiwan Hopax Chemicals Manufacturing Co., Ltd.',
    symbol: '6509.TWO',
  },
  {
    value: '6516.T',
    label: '6516.T,  Sanyo Denki Co., Ltd.',
    name: 'Sanyo Denki Co., Ltd.',
    symbol: '6516.T',
  },
  {
    value: '6517.T',
    label: '6517.T,  Denyo Co., Ltd.',
    name: 'Denyo Co., Ltd.',
    symbol: '6517.T',
  },
  {
    value: '6523.TWO',
    label: '6523.TWO,  Dr. Wu Skincare Co., Ltd.',
    name: 'Dr. Wu Skincare Co., Ltd.',
    symbol: '6523.TWO',
  },
  {
    value: '6525.TW',
    label: '6525.TW,  GEM Services, Inc.',
    name: 'GEM Services, Inc.',
    symbol: '6525.TW',
  },
  {
    value: '6531.TW',
    label: '6531.TW,  AP Memory Technology Corporation',
    name: 'AP Memory Technology Corporation',
    symbol: '6531.TW',
  },
  {
    value: '6532.T',
    label: '6532.T,  BayCurrent Consulting, Inc.',
    name: 'BayCurrent Consulting, Inc.',
    symbol: '6532.T',
  },
  {
    value: '6533.TW',
    label: '6533.TW,  Andes Technology Corporation',
    name: 'Andes Technology Corporation',
    symbol: '6533.TW',
  },
  {
    value: '6538.TWO',
    label: '6538.TWO,  Brave C&H Supply Co.,Ltd.',
    name: 'Brave C&H Supply Co.,Ltd.',
    symbol: '6538.TWO',
  },
  {
    value: '6539.T',
    label: '6539.T,  Matching Service Japan Co., Ltd.',
    name: 'Matching Service Japan Co., Ltd.',
    symbol: '6539.T',
  },
  {
    value: '6541.TW',
    label: '6541.TW,  Tanvex BioPharma, Inc.',
    name: 'Tanvex BioPharma, Inc.',
    symbol: '6541.TW',
  },
  {
    value: '6544.T',
    label: '6544.T,  Japan Elevator Service Holdings Co.,Ltd.',
    name: 'Japan Elevator Service Holdings Co.,Ltd.',
    symbol: '6544.T',
  },
  {
    value: '6547.TWO',
    label: '6547.TWO,  Medigen Vaccine Biologics Corporation',
    name: 'Medigen Vaccine Biologics Corporation',
    symbol: '6547.TWO',
  },
  {
    value: '6548.TWO',
    label: '6548.TWO,  Chang Wah Technology Co., Ltd.',
    name: 'Chang Wah Technology Co., Ltd.',
    symbol: '6548.TWO',
  },
  {
    value: '6561.TWO',
    label: '6561.TWO,  Chief Telecom Inc.',
    name: 'Chief Telecom Inc.',
    symbol: '6561.TWO',
  },
  {
    value: '6564.T',
    label: '6564.T,  Midac Holdings Co., Ltd.',
    name: 'Midac Holdings Co., Ltd.',
    symbol: '6564.T',
  },
  {
    value: '6569.T',
    label: '6569.T,  Nisso Corporation',
    name: 'Nisso Corporation',
    symbol: '6569.T',
  },
  {
    value: '6571.T',
    label: '6571.T,  QB Net Holdings Co.,Ltd.',
    name: 'QB Net Holdings Co.,Ltd.',
    symbol: '6571.T',
  },
  {
    value: '6572.T',
    label: '6572.T,  RPA Holdings, Inc.',
    name: 'RPA Holdings, Inc.',
    symbol: '6572.T',
  },
  {
    value: '6579.TW',
    label: '6579.TW,  AAEON Technology Inc.',
    name: 'AAEON Technology Inc.',
    symbol: '6579.TW',
  },
  {
    value: '6582.TW',
    label: '6582.TW,  Shin Foong Specialty and Applied Materials Co., Ltd.',
    name: 'Shin Foong Specialty and Applied Materials Co., Ltd.',
    symbol: '6582.TW',
  },
  {
    value: '6584.T',
    label: '6584.T,  Sanoh Industrial Co., Ltd.',
    name: 'Sanoh Industrial Co., Ltd.',
    symbol: '6584.T',
  },
  {
    value: '6586.T',
    label: '6586.T,  Makita Corporation',
    name: 'Makita Corporation',
    symbol: '6586.T',
  },
  {
    value: '6588.T',
    label: '6588.T,  Toshiba Tec Corporation',
    name: 'Toshiba Tec Corporation',
    symbol: '6588.T',
  },
  {
    value: '6590.T',
    label: '6590.T,  Shibaura Mechatronics Corporation',
    name: 'Shibaura Mechatronics Corporation',
    symbol: '6590.T',
  },
  {
    value: '6592.T',
    label: '6592.T,  Mabuchi Motor Co., Ltd.',
    name: 'Mabuchi Motor Co., Ltd.',
    symbol: '6592.T',
  },
  {
    value: '6592.TW',
    label: '6592.TW,  Hotai Finance Co., Ltd.',
    name: 'Hotai Finance Co., Ltd.',
    symbol: '6592.TW',
  },
  {
    value: '6594.T',
    label: '6594.T,  Nidec Corporation',
    name: 'Nidec Corporation',
    symbol: '6594.T',
  },
  {
    value: '6598.TW',
    label: '6598.TW,  Applied BioCode Corporation',
    name: 'Applied BioCode Corporation',
    symbol: '6598.TW',
  },
  {
    value: '6605.TW',
    label: '6605.TW,  Depo Auto Parts Industrial Co., Ltd.',
    name: 'Depo Auto Parts Industrial Co., Ltd.',
    symbol: '6605.TW',
  },
  {
    value: '6608.HK',
    label: '6608.HK,  Bairong Inc.',
    name: 'Bairong Inc.',
    symbol: '6608.HK',
  },
  {
    value: '6616.T',
    label: '6616.T,  Torex Semiconductor Ltd.',
    name: 'Torex Semiconductor Ltd.',
    symbol: '6616.T',
  },
  {
    value: '6617.T',
    label: '6617.T,  Takaoka Toko Co., Ltd.',
    name: 'Takaoka Toko Co., Ltd.',
    symbol: '6617.T',
  },
  {
    value: '6619.T',
    label: '6619.T,  W-SCOPE Corporation',
    name: 'W-SCOPE Corporation',
    symbol: '6619.T',
  },
  {
    value: '6622.T',
    label: '6622.T,  DAIHEN Corporation',
    name: 'DAIHEN Corporation',
    symbol: '6622.T',
  },
  {
    value: '6630.T',
    label: '6630.T,  Ya-Man Ltd.',
    name: 'Ya-Man Ltd.',
    symbol: '6630.T',
  },
  {
    value: '6632.T',
    label: '6632.T,  JVCKENWOOD Corporation',
    name: 'JVCKENWOOD Corporation',
    symbol: '6632.T',
  },
  {
    value: '6640.T',
    label: '6640.T,  I-PEX Inc.',
    name: 'I-PEX Inc.',
    symbol: '6640.T',
  },
  {
    value: '6641.T',
    label: '6641.T,  Nissin Electric Co., Ltd.',
    name: 'Nissin Electric Co., Ltd.',
    symbol: '6641.T',
  },
  {
    value: '6644.T',
    label: '6644.T,  Osaki Electric Co., Ltd.',
    name: 'Osaki Electric Co., Ltd.',
    symbol: '6644.T',
  },
  {
    value: '6645.T',
    label: '6645.T,  OMRON Corporation',
    name: 'OMRON Corporation',
    symbol: '6645.T',
  },
  {
    value: '6651.T',
    label: '6651.T,  Nitto Kogyo Corporation',
    name: 'Nitto Kogyo Corporation',
    symbol: '6651.T',
  },
  {
    value: '6652.T',
    label: '6652.T,  IDEC Corporation',
    name: 'IDEC Corporation',
    symbol: '6652.T',
  },
  {
    value: '6669.TW',
    label: '6669.TW,  Wiwynn Corporation',
    name: 'Wiwynn Corporation',
    symbol: '6669.TW',
  },
  {
    value: '6670.T',
    label: '6670.T,  MCJ Co., Ltd.',
    name: 'MCJ Co., Ltd.',
    symbol: '6670.T',
  },
  {
    value: '6670.TW',
    label: '6670.TW,  FuSheng Precision Co., Ltd.',
    name: 'FuSheng Precision Co., Ltd.',
    symbol: '6670.TW',
  },
  {
    value: '6672.TW',
    label: '6672.TW,  Ventec International Group Co.,Ltd.',
    name: 'Ventec International Group Co.,Ltd.',
    symbol: '6672.TW',
  },
  {
    value: '6674.T',
    label: '6674.T,  GS Yuasa Corporation',
    name: 'GS Yuasa Corporation',
    symbol: '6674.T',
  },
  {
    value: '6676.T',
    label: '6676.T,  Melco Holdings Inc.',
    name: 'Melco Holdings Inc.',
    symbol: '6676.T',
  },
  {
    value: '6677.T',
    label: '6677.T,  SK-Electronics CO.,LTD.',
    name: 'SK-Electronics CO.,LTD.',
    symbol: '6677.T',
  },
  {
    value: '6699.T',
    label: '6699.T,  Diamond Electric Holdings Co., Ltd.',
    name: 'Diamond Electric Holdings Co., Ltd.',
    symbol: '6699.T',
  },
  {
    value: '6701.T',
    label: '6701.T,  NEC Corporation',
    name: 'NEC Corporation',
    symbol: '6701.T',
  },
  {
    value: '6702.T',
    label: '6702.T,  Fujitsu Limited',
    name: 'Fujitsu Limited',
    symbol: '6702.T',
  },
  {
    value: '6703.T',
    label: '6703.T,  Oki Electric Industry Co., Ltd.',
    name: 'Oki Electric Industry Co., Ltd.',
    symbol: '6703.T',
  },
  {
    value: '6706.T',
    label: '6706.T,  DKK Co., Ltd.',
    name: 'DKK Co., Ltd.',
    symbol: '6706.T',
  },
  {
    value: '6706.TW',
    label: '6706.TW,  Fittech Co.,Ltd',
    name: 'Fittech Co.,Ltd',
    symbol: '6706.TW',
  },
  {
    value: '6707.T',
    label: '6707.T,  Sanken Electric Co., Ltd.',
    name: 'Sanken Electric Co., Ltd.',
    symbol: '6707.T',
  },
  {
    value: '6723.T',
    label: '6723.T,  Renesas Electronics Corporation',
    name: 'Renesas Electronics Corporation',
    symbol: '6723.T',
  },
  {
    value: '6724.T',
    label: '6724.T,  Seiko Epson Corporation',
    name: 'Seiko Epson Corporation',
    symbol: '6724.T',
  },
  {
    value: '6727.T',
    label: '6727.T,  Wacom Co., Ltd.',
    name: 'Wacom Co., Ltd.',
    symbol: '6727.T',
  },
  {
    value: '6728.T',
    label: '6728.T,  ULVAC, Inc.',
    name: 'ULVAC, Inc.',
    symbol: '6728.T',
  },
  {
    value: '6732.TWO',
    label: '6732.TWO,  Sensortek Technology Corp.',
    name: 'Sensortek Technology Corp.',
    symbol: '6732.TWO',
  },
  {
    value: '6736.T',
    label: '6736.T,  Sun Corporation',
    name: 'Sun Corporation',
    symbol: '6736.T',
  },
  {
    value: '6737.T',
    label: '6737.T,  EIZO Corporation',
    name: 'EIZO Corporation',
    symbol: '6737.T',
  },
  {
    value: '6740.T',
    label: '6740.T,  Japan Display Inc.',
    name: 'Japan Display Inc.',
    symbol: '6740.T',
  },
  {
    value: '6741.T',
    label: '6741.T,  Nippon Signal Co., Ltd.',
    name: 'Nippon Signal Co., Ltd.',
    symbol: '6741.T',
  },
  {
    value: '6742.T',
    label: '6742.T,  Kyosan Electric Manufacturing Co., Ltd.',
    name: 'Kyosan Electric Manufacturing Co., Ltd.',
    symbol: '6742.T',
  },
  {
    value: '6743.TW',
    label: '6743.TW,  AMPACS Corporation',
    name: 'AMPACS Corporation',
    symbol: '6743.TW',
  },
  {
    value: '6744.T',
    label: '6744.T,  Nohmi Bosai Ltd.',
    name: 'Nohmi Bosai Ltd.',
    symbol: '6744.T',
  },
  {
    value: '6745.T',
    label: '6745.T,  Hochiki Corporation',
    name: 'Hochiki Corporation',
    symbol: '6745.T',
  },
  {
    value: '6750.T',
    label: '6750.T,  Elecom Co., Ltd.',
    name: 'Elecom Co., Ltd.',
    symbol: '6750.T',
  },
  {
    value: '6752.T',
    label: '6752.T,  Panasonic Holdings Corporation',
    name: 'Panasonic Holdings Corporation',
    symbol: '6752.T',
  },
  {
    value: '6753.T',
    label: '6753.T,  Sharp Corporation',
    name: 'Sharp Corporation',
    symbol: '6753.T',
  },
  {
    value: '6754.T',
    label: '6754.T,  Anritsu Corporation',
    name: 'Anritsu Corporation',
    symbol: '6754.T',
  },
  {
    value: '6755.T',
    label: '6755.T,  Fujitsu General Limited',
    name: 'Fujitsu General Limited',
    symbol: '6755.T',
  },
  {
    value: '6758.T',
    label: '6758.T,  Sony Group Corporation',
    name: 'Sony Group Corporation',
    symbol: '6758.T',
  },
  {
    value: '6762.T',
    label: '6762.T,  TDK Corporation',
    name: 'TDK Corporation',
    symbol: '6762.T',
  },
  {
    value: '6768.T',
    label: '6768.T,  Tamura Corporation',
    name: 'Tamura Corporation',
    symbol: '6768.T',
  },
  {
    value: '6770.T',
    label: '6770.T,  Alps Alpine Co., Ltd.',
    name: 'Alps Alpine Co., Ltd.',
    symbol: '6770.T',
  },
  {
    value: '6779.T',
    label: '6779.T,  Nihon Dempa Kogyo Co., Ltd.',
    name: 'Nihon Dempa Kogyo Co., Ltd.',
    symbol: '6779.T',
  },
  {
    value: '6781.TW',
    label: '6781.TW,  Advanced Energy Solution Holding Co., Ltd.',
    name: 'Advanced Energy Solution Holding Co., Ltd.',
    symbol: '6781.TW',
  },
  {
    value: '6785.T',
    label: '6785.T,  Suzuki Co.,Ltd.',
    name: 'Suzuki Co.,Ltd.',
    symbol: '6785.T',
  },
  {
    value: '6787.T',
    label: '6787.T,  Meiko Electronics Co., Ltd.',
    name: 'Meiko Electronics Co., Ltd.',
    symbol: '6787.T',
  },
  {
    value: '6788.T',
    label: '6788.T,  Nihon Trim Co., Ltd.',
    name: 'Nihon Trim Co., Ltd.',
    symbol: '6788.T',
  },
  {
    value: '6789.T',
    label: '6789.T,  Roland DG Corporation',
    name: 'Roland DG Corporation',
    symbol: '6789.T',
  },
  {
    value: '6794.T',
    label: '6794.T,  Foster Electric Company, Limited',
    name: 'Foster Electric Company, Limited',
    symbol: '6794.T',
  },
  {
    value: '6798.T',
    label: '6798.T,  SMK Corporation',
    name: 'SMK Corporation',
    symbol: '6798.T',
  },
  {
    value: '6800.T',
    label: '6800.T,  Yokowo Co., Ltd.',
    name: 'Yokowo Co., Ltd.',
    symbol: '6800.T',
  },
  {
    value: '6803.TWO',
    label: '6803.TWO,  ECOVE Environment Corp.',
    name: 'ECOVE Environment Corp.',
    symbol: '6803.TWO',
  },
  {
    value: '6804.T',
    label: '6804.T,  Hosiden Corporation',
    name: 'Hosiden Corporation',
    symbol: '6804.T',
  },
  {
    value: '6806.T',
    label: '6806.T,  Hirose Electric Co.,Ltd.',
    name: 'Hirose Electric Co.,Ltd.',
    symbol: '6806.T',
  },
  {
    value: '6807.T',
    label: '6807.T,  Japan Aviation Electronics Industry, Limited',
    name: 'Japan Aviation Electronics Industry, Limited',
    symbol: '6807.T',
  },
  {
    value: '6809.T',
    label: '6809.T,  TOA Corporation',
    name: 'TOA Corporation',
    symbol: '6809.T',
  },
  {
    value: '6810.T',
    label: '6810.T,  Maxell, Ltd.',
    name: 'Maxell, Ltd.',
    symbol: '6810.T',
  },
  {
    value: '6814.T',
    label: '6814.T,  Furuno Electric Co., Ltd.',
    name: 'Furuno Electric Co., Ltd.',
    symbol: '6814.T',
  },
  {
    value: '6817.T',
    label: '6817.T,  Sumida Corporation',
    name: 'Sumida Corporation',
    symbol: '6817.T',
  },
  {
    value: '6828.HK',
    label: '6828.HK,  Beijing Gas Blue Sky Holdings Limited',
    name: 'Beijing Gas Blue Sky Holdings Limited',
    symbol: '6828.HK',
  },
  {
    value: '6841.T',
    label: '6841.T,  Yokogawa Electric Corporation',
    name: 'Yokogawa Electric Corporation',
    symbol: '6841.T',
  },
  {
    value: '6844.T',
    label: '6844.T,  Shindengen Electric Manufacturing Co.,Ltd.',
    name: 'Shindengen Electric Manufacturing Co.,Ltd.',
    symbol: '6844.T',
  },
  {
    value: '6845.T',
    label: '6845.T,  Azbil Corporation',
    name: 'Azbil Corporation',
    symbol: '6845.T',
  },
  {
    value: '6849.T',
    label: '6849.T,  Nihon Kohden Corporation',
    name: 'Nihon Kohden Corporation',
    symbol: '6849.T',
  },
  {
    value: '6856.T',
    label: '6856.T,  HORIBA, Ltd.',
    name: 'HORIBA, Ltd.',
    symbol: '6856.T',
  },
  {
    value: '6857.T',
    label: '6857.T,  Advantest Corporation',
    name: 'Advantest Corporation',
    symbol: '6857.T',
  },
  {
    value: '6859.T',
    label: '6859.T,  Espec Corp.',
    name: 'Espec Corp.',
    symbol: '6859.T',
  },
  {
    value: '6861.T',
    label: '6861.T,  Keyence Corporation',
    name: 'Keyence Corporation',
    symbol: '6861.T',
  },
  {
    value: '6866.T',
    label: '6866.T,  Hioki E.E. Corporation',
    name: 'Hioki E.E. Corporation',
    symbol: '6866.T',
  },
  {
    value: '6869.T',
    label: '6869.T,  Sysmex Corporation',
    name: 'Sysmex Corporation',
    symbol: '6869.T',
  },
  {
    value: '6871.T',
    label: '6871.T,  Micronics Japan Co., Ltd.',
    name: 'Micronics Japan Co., Ltd.',
    symbol: '6871.T',
  },
  {
    value: '6875.T',
    label: '6875.T,  MegaChips Corporation',
    name: 'MegaChips Corporation',
    symbol: '6875.T',
  },
  {
    value: '6877.T',
    label: '6877.T,  Obara Group Incorporated',
    name: 'Obara Group Incorporated',
    symbol: '6877.T',
  },
  {
    value: '6879.T',
    label: '6879.T,  IMAGICA GROUP Inc.',
    name: 'IMAGICA GROUP Inc.',
    symbol: '6879.T',
  },
  {
    value: '688002.SS',
    label: '688002.SS,  Raytron Technology Co.,Ltd.',
    name: 'Raytron Technology Co.,Ltd.',
    symbol: '688002.SS',
  },
  {
    value: '688006.SS',
    label: '688006.SS,  Zhejiang HangKe Technology Incorporated Company',
    name: 'Zhejiang HangKe Technology Incorporated Company',
    symbol: '688006.SS',
  },
  {
    value: '688008.SS',
    label: '688008.SS,  Montage Technology Co., Ltd.',
    name: 'Montage Technology Co., Ltd.',
    symbol: '688008.SS',
  },
  {
    value: '688009.SS',
    label:
      '688009.SS,  China Railway Signal & Communication Corporation Limited',
    name: 'China Railway Signal & Communication Corporation Limited',
    symbol: '688009.SS',
  },
  {
    value: '688012.SS',
    label: '688012.SS,  Advanced Micro-Fabrication Equipment Inc. China',
    name: 'Advanced Micro-Fabrication Equipment Inc. China',
    symbol: '688012.SS',
  },
  {
    value: '688111.SS',
    label: '688111.SS,  Beijing Kingsoft Office Software, Inc.',
    name: 'Beijing Kingsoft Office Software, Inc.',
    symbol: '688111.SS',
  },
  {
    value: '688126.SS',
    label: '688126.SS,  National Silicon Industry Group Co., Ltd.',
    name: 'National Silicon Industry Group Co., Ltd.',
    symbol: '688126.SS',
  },
  {
    value: '688169.SS',
    label: '688169.SS,  Beijing Roborock Technology Co., Ltd.',
    name: 'Beijing Roborock Technology Co., Ltd.',
    symbol: '688169.SS',
  },
  {
    value: '688180.SS',
    label: '688180.SS,  Shanghai Junshi Biosciences Co., Ltd.',
    name: 'Shanghai Junshi Biosciences Co., Ltd.',
    symbol: '688180.SS',
  },
  {
    value: '688185.SS',
    label: '688185.SS,  CanSino Biologics Inc.',
    name: 'CanSino Biologics Inc.',
    symbol: '688185.SS',
  },
  {
    value: '688188.SS',
    label:
      '688188.SS,  Shanghai Friendess Electronic Technology Corporation Limited',
    name: 'Shanghai Friendess Electronic Technology Corporation Limited',
    symbol: '688188.SS',
  },
  {
    value: '688366.SS',
    label: '688366.SS,  Shanghai Haohai Biological Technology Co., Ltd.',
    name: 'Shanghai Haohai Biological Technology Co., Ltd.',
    symbol: '688366.SS',
  },
  {
    value: '6890.T',
    label: '6890.T,  Ferrotec Holdings Corporation',
    name: 'Ferrotec Holdings Corporation',
    symbol: '6890.T',
  },
  {
    value: '6902.T',
    label: '6902.T,  DENSO Corporation',
    name: 'DENSO Corporation',
    symbol: '6902.T',
  },
  {
    value: '6905.T',
    label: '6905.T,  Cosel Co., Ltd.',
    name: 'Cosel Co., Ltd.',
    symbol: '6905.T',
  },
  {
    value: '6908.T',
    label: '6908.T,  Iriso Electronics Co., Ltd.',
    name: 'Iriso Electronics Co., Ltd.',
    symbol: '6908.T',
  },
  {
    value: '6914.T',
    label: '6914.T,  OPTEX GROUP Company, Limited',
    name: 'OPTEX GROUP Company, Limited',
    symbol: '6914.T',
  },
  {
    value: '6915.T',
    label: '6915.T,  Chiyoda Integre Co.,Ltd.',
    name: 'Chiyoda Integre Co.,Ltd.',
    symbol: '6915.T',
  },
  {
    value: '6920.T',
    label: '6920.T,  Lasertec Corporation',
    name: 'Lasertec Corporation',
    symbol: '6920.T',
  },
  {
    value: '6923.T',
    label: '6923.T,  Stanley Electric Co., Ltd.',
    name: 'Stanley Electric Co., Ltd.',
    symbol: '6923.T',
  },
  {
    value: '6925.T',
    label: '6925.T,  Ushio Inc.',
    name: 'Ushio Inc.',
    symbol: '6925.T',
  },
  {
    value: '6928.T',
    label: '6928.T,  Enomoto Co.,Ltd.',
    name: 'Enomoto Co.,Ltd.',
    symbol: '6928.T',
  },
  {
    value: '6929.T',
    label: '6929.T,  Nippon Ceramic Co., Ltd.',
    name: 'Nippon Ceramic Co., Ltd.',
    symbol: '6929.T',
  },
  {
    value: '6937.T',
    label: '6937.T,  The Furukawa Battery Co., Ltd.',
    name: 'The Furukawa Battery Co., Ltd.',
    symbol: '6937.T',
  },
  {
    value: '6941.T',
    label: '6941.T,  Yamaichi Electronics Co.,Ltd.',
    name: 'Yamaichi Electronics Co.,Ltd.',
    symbol: '6941.T',
  },
  {
    value: '6947.T',
    label: '6947.T,  Zuken Inc.',
    name: 'Zuken Inc.',
    symbol: '6947.T',
  },
  {
    value: '6951.T',
    label: '6951.T,  JEOL Ltd.',
    name: 'JEOL Ltd.',
    symbol: '6951.T',
  },
  {
    value: '6952.T',
    label: '6952.T,  Casio Computer Co.,Ltd.',
    name: 'Casio Computer Co.,Ltd.',
    symbol: '6952.T',
  },
  {
    value: '6954.T',
    label: '6954.T,  Fanuc Corporation',
    name: 'Fanuc Corporation',
    symbol: '6954.T',
  },
  {
    value: '6955.T',
    label: '6955.T,  FDK Corporation',
    name: 'FDK Corporation',
    symbol: '6955.T',
  },
  {
    value: '6957.T',
    label: '6957.T,  Shibaura Electronics Co.,Ltd.',
    name: 'Shibaura Electronics Co.,Ltd.',
    symbol: '6957.T',
  },
  {
    value: '6958.T',
    label: '6958.T,  CMK Corporation',
    name: 'CMK Corporation',
    symbol: '6958.T',
  },
  {
    value: '6961.T',
    label: '6961.T,  Enplas Corporation',
    name: 'Enplas Corporation',
    symbol: '6961.T',
  },
  {
    value: '6962.T',
    label: '6962.T,  Daishinku Corp.',
    name: 'Daishinku Corp.',
    symbol: '6962.T',
  },
  {
    value: '6963.T',
    label: '6963.T,  ROHM Co., Ltd.',
    name: 'ROHM Co., Ltd.',
    symbol: '6963.T',
  },
  {
    value: '6965.T',
    label: '6965.T,  Hamamatsu Photonics K.K.',
    name: 'Hamamatsu Photonics K.K.',
    symbol: '6965.T',
  },
  {
    value: '6966.T',
    label: '6966.T,  Mitsui High-tec, Inc.',
    name: 'Mitsui High-tec, Inc.',
    symbol: '6966.T',
  },
  {
    value: '6967.T',
    label: '6967.T,  Shinko Electric Industries Co., Ltd.',
    name: 'Shinko Electric Industries Co., Ltd.',
    symbol: '6967.T',
  },
  {
    value: '6971.T',
    label: '6971.T,  Kyocera Corporation',
    name: 'Kyocera Corporation',
    symbol: '6971.T',
  },
  {
    value: '6976.T',
    label: '6976.T,  Taiyo Yuden Co., Ltd.',
    name: 'Taiyo Yuden Co., Ltd.',
    symbol: '6976.T',
  },
  {
    value: '6981.T',
    label: '6981.T,  Murata Manufacturing Co., Ltd.',
    name: 'Murata Manufacturing Co., Ltd.',
    symbol: '6981.T',
  },
  {
    value: '6986.T',
    label: '6986.T,  Futaba Corporation',
    name: 'Futaba Corporation',
    symbol: '6986.T',
  },
  {
    value: '6988.T',
    label: '6988.T,  Nitto Denko Corporation',
    name: 'Nitto Denko Corporation',
    symbol: '6988.T',
  },
  {
    value: '6995.T',
    label: '6995.T,  Tokai Rika Co., Ltd.',
    name: 'Tokai Rika Co., Ltd.',
    symbol: '6995.T',
  },
  {
    value: '6996.T',
    label: '6996.T,  Nichicon Corporation',
    name: 'Nichicon Corporation',
    symbol: '6996.T',
  },
  {
    value: '6997.T',
    label: '6997.T,  Nippon Chemi-Con Corporation',
    name: 'Nippon Chemi-Con Corporation',
    symbol: '6997.T',
  },
  {
    value: '6999.T',
    label: '6999.T,  KOA Corporation',
    name: 'KOA Corporation',
    symbol: '6999.T',
  },
  {
    value: '7003.T',
    label: '7003.T,  Mitsui E&S Holdings Co., Ltd.',
    name: 'Mitsui E&S Holdings Co., Ltd.',
    symbol: '7003.T',
  },
  {
    value: '7004.T',
    label: '7004.T,  Hitachi Zosen Corporation',
    name: 'Hitachi Zosen Corporation',
    symbol: '7004.T',
  },
  {
    value: '7010.SR',
    label: '7010.SR,  Saudi Telecom Company',
    name: 'Saudi Telecom Company',
    symbol: '7010.SR',
  },
  {
    value: '7011.T',
    label: '7011.T,  Mitsubishi Heavy Industries, Ltd.',
    name: 'Mitsubishi Heavy Industries, Ltd.',
    symbol: '7011.T',
  },
  {
    value: '7012.T',
    label: '7012.T,  Kawasaki Heavy Industries, Ltd.',
    name: 'Kawasaki Heavy Industries, Ltd.',
    symbol: '7012.T',
  },
  {
    value: '7013.T',
    label: '7013.T,  IHI Corporation',
    name: 'IHI Corporation',
    symbol: '7013.T',
  },
  {
    value: '7014.T',
    label: '7014.T,  Namura Shipbuilding Co., Ltd.',
    name: 'Namura Shipbuilding Co., Ltd.',
    symbol: '7014.T',
  },
  {
    value: '7020.SR',
    label: '7020.SR,  Etihad Etisalat Company',
    name: 'Etihad Etisalat Company',
    symbol: '7020.SR',
  },
  {
    value: '7030.SR',
    label: '7030.SR,  Mobile Telecommunications Company Saudi Arabia',
    name: 'Mobile Telecommunications Company Saudi Arabia',
    symbol: '7030.SR',
  },
  {
    value: '7085.T',
    label: '7085.T,  CURVES HOLDINGS Co., Ltd.',
    name: 'CURVES HOLDINGS Co., Ltd.',
    symbol: '7085.T',
  },
  {
    value: '7088.T',
    label: '7088.T,  Forum Engineering Inc.',
    name: 'Forum Engineering Inc.',
    symbol: '7088.T',
  },
  {
    value: '7092.T',
    label: '7092.T,  Fast Fitness Japan Incorporated',
    name: 'Fast Fitness Japan Incorporated',
    symbol: '7092.T',
  },
  {
    value: '7094.T',
    label: '7094.T,  NexTone Inc.',
    name: 'NexTone Inc.',
    symbol: '7094.T',
  },
  {
    value: '7105.T',
    label: '7105.T,  Mitsubishi Logisnext Co., Ltd.',
    name: 'Mitsubishi Logisnext Co., Ltd.',
    symbol: '7105.T',
  },
  {
    value: '7148.T',
    label: '7148.T,  Financial Products Group Co., Ltd.',
    name: 'Financial Products Group Co., Ltd.',
    symbol: '7148.T',
  },
  {
    value: '7157.T',
    label: '7157.T,  Lifenet Insurance Company',
    name: 'Lifenet Insurance Company',
    symbol: '7157.T',
  },
  {
    value: '7161.T',
    label: '7161.T,  Jimoto Holdings, Inc.',
    name: 'Jimoto Holdings, Inc.',
    symbol: '7161.T',
  },
  {
    value: '7164.T',
    label: '7164.T,  ZENKOKU HOSHO Co.,Ltd.',
    name: 'ZENKOKU HOSHO Co.,Ltd.',
    symbol: '7164.T',
  },
  {
    value: '7167.T',
    label: '7167.T,  Mebuki Financial Group,Inc.',
    name: 'Mebuki Financial Group,Inc.',
    symbol: '7167.T',
  },
  {
    value: '7172.T',
    label: '7172.T,  Japan Investment Adviser Co., Ltd.',
    name: 'Japan Investment Adviser Co., Ltd.',
    symbol: '7172.T',
  },
  {
    value: '7173.T',
    label: '7173.T,  Tokyo Kiraboshi Financial Group, Inc.',
    name: 'Tokyo Kiraboshi Financial Group, Inc.',
    symbol: '7173.T',
  },
  {
    value: '7177.T',
    label: '7177.T,  GMO Financial Holdings, Inc.',
    name: 'GMO Financial Holdings, Inc.',
    symbol: '7177.T',
  },
  {
    value: '7180.T',
    label: '7180.T,  Kyushu Financial Group,Inc.',
    name: 'Kyushu Financial Group,Inc.',
    symbol: '7180.T',
  },
  {
    value: '7181.T',
    label: '7181.T,  Japan Post Insurance Co., Ltd.',
    name: 'Japan Post Insurance Co., Ltd.',
    symbol: '7181.T',
  },
  {
    value: '7182.T',
    label: '7182.T,  JAPAN POST BANK Co., Ltd.',
    name: 'JAPAN POST BANK Co., Ltd.',
    symbol: '7182.T',
  },
  {
    value: '7184.T',
    label: '7184.T,  The First Bank Of Toyama, Ltd.',
    name: 'The First Bank Of Toyama, Ltd.',
    symbol: '7184.T',
  },
  {
    value: '7186.T',
    label: '7186.T,  Concordia Financial Group, Ltd.',
    name: 'Concordia Financial Group, Ltd.',
    symbol: '7186.T',
  },
  {
    value: '7189.T',
    label: '7189.T,  Nishi-Nippon Financial Holdings, Inc.',
    name: 'Nishi-Nippon Financial Holdings, Inc.',
    symbol: '7189.T',
  },
  {
    value: '7198.T',
    label: '7198.T,  ARUHI Corporation',
    name: 'ARUHI Corporation',
    symbol: '7198.T',
  },
  {
    value: '7199.T',
    label: '7199.T,  Premium Group Co., Ltd.',
    name: 'Premium Group Co., Ltd.',
    symbol: '7199.T',
  },
  {
    value: '7200.SR',
    label: '7200.SR,  Al Moammar Information Systems Company',
    name: 'Al Moammar Information Systems Company',
    symbol: '7200.SR',
  },
  {
    value: '7201.SR',
    label: '7201.SR,  Arab Sea Information Systems Company',
    name: 'Arab Sea Information Systems Company',
    symbol: '7201.SR',
  },
  {
    value: '7201.T',
    label: '7201.T,  Nissan Motor Co., Ltd.',
    name: 'Nissan Motor Co., Ltd.',
    symbol: '7201.T',
  },
  {
    value: '7202.T',
    label: '7202.T,  Isuzu Motors Limited',
    name: 'Isuzu Motors Limited',
    symbol: '7202.T',
  },
  {
    value: '7203.T',
    label: '7203.T,  Toyota Motor Corporation',
    name: 'Toyota Motor Corporation',
    symbol: '7203.T',
  },
  {
    value: '7205.T',
    label: '7205.T,  Hino Motors, Ltd.',
    name: 'Hino Motors, Ltd.',
    symbol: '7205.T',
  },
  {
    value: '7211.T',
    label: '7211.T,  Mitsubishi Motors Corporation',
    name: 'Mitsubishi Motors Corporation',
    symbol: '7211.T',
  },
  {
    value: '7220.T',
    label: '7220.T,  Musashi Seimitsu Industry Co., Ltd.',
    name: 'Musashi Seimitsu Industry Co., Ltd.',
    symbol: '7220.T',
  },
  {
    value: '7222.T',
    label: '7222.T,  Nissan Shatai Co., Ltd.',
    name: 'Nissan Shatai Co., Ltd.',
    symbol: '7222.T',
  },
  {
    value: '7224.T',
    label: '7224.T,  ShinMaywa Industries, Ltd.',
    name: 'ShinMaywa Industries, Ltd.',
    symbol: '7224.T',
  },
  {
    value: '7226.T',
    label: '7226.T,  Kyokuto Kaihatsu Kogyo Co.,Ltd.',
    name: 'Kyokuto Kaihatsu Kogyo Co.,Ltd.',
    symbol: '7226.T',
  },
  {
    value: '7231.T',
    label: '7231.T,  Topy Industries, Limited',
    name: 'Topy Industries, Limited',
    symbol: '7231.T',
  },
  {
    value: '7236.T',
    label: '7236.T,  T.RAD Co., Ltd.',
    name: 'T.RAD Co., Ltd.',
    symbol: '7236.T',
  },
  {
    value: '7238.T',
    label: '7238.T,  Akebono Brake Industry Co., Ltd.',
    name: 'Akebono Brake Industry Co., Ltd.',
    symbol: '7238.T',
  },
  {
    value: '7239.T',
    label: '7239.T,  TACHI-S Co., Ltd.',
    name: 'TACHI-S Co., Ltd.',
    symbol: '7239.T',
  },
  {
    value: '7240.T',
    label: '7240.T,  NOK Corporation',
    name: 'NOK Corporation',
    symbol: '7240.T',
  },
  {
    value: '7241.T',
    label: '7241.T,  Futaba Industrial Co., Ltd.',
    name: 'Futaba Industrial Co., Ltd.',
    symbol: '7241.T',
  },
  {
    value: '7242.T',
    label: '7242.T,  KYB Corporation',
    name: 'KYB Corporation',
    symbol: '7242.T',
  },
  {
    value: '7244.T',
    label: '7244.T,  Ichikoh Industries, Ltd.',
    name: 'Ichikoh Industries, Ltd.',
    symbol: '7244.T',
  },
  {
    value: '7245.T',
    label: '7245.T,  Daido Metal Co., Ltd.',
    name: 'Daido Metal Co., Ltd.',
    symbol: '7245.T',
  },
  {
    value: '7246.T',
    label: '7246.T,  Press Kogyo Co., Ltd.',
    name: 'Press Kogyo Co., Ltd.',
    symbol: '7246.T',
  },
  {
    value: '7250.T',
    label: '7250.T,  Pacific Industrial Co., Ltd.',
    name: 'Pacific Industrial Co., Ltd.',
    symbol: '7250.T',
  },
  {
    value: '7256.T',
    label: '7256.T,  Kasai Kogyo Co., Ltd.',
    name: 'Kasai Kogyo Co., Ltd.',
    symbol: '7256.T',
  },
  {
    value: '7259.T',
    label: '7259.T,  Aisin Corporation',
    name: 'Aisin Corporation',
    symbol: '7259.T',
  },
  {
    value: '7261.T',
    label: '7261.T,  Mazda Motor Corporation',
    name: 'Mazda Motor Corporation',
    symbol: '7261.T',
  },
  {
    value: '7266.T',
    label: '7266.T,  Imasen Electric Industrial Co., Ltd.',
    name: 'Imasen Electric Industrial Co., Ltd.',
    symbol: '7266.T',
  },
  {
    value: '7267.T',
    label: '7267.T,  Honda Motor Co., Ltd.',
    name: 'Honda Motor Co., Ltd.',
    symbol: '7267.T',
  },
  {
    value: '7269.T',
    label: '7269.T,  Suzuki Motor Corporation',
    name: 'Suzuki Motor Corporation',
    symbol: '7269.T',
  },
  {
    value: '7270.T',
    label: '7270.T,  Subaru Corporation',
    name: 'Subaru Corporation',
    symbol: '7270.T',
  },
  {
    value: '7272.T',
    label: '7272.T,  Yamaha Motor Co., Ltd.',
    name: 'Yamaha Motor Co., Ltd.',
    symbol: '7272.T',
  },
  {
    value: '7276.T',
    label: '7276.T,  Koito Manufacturing Co., Ltd.',
    name: 'Koito Manufacturing Co., Ltd.',
    symbol: '7276.T',
  },
  {
    value: '7278.T',
    label: '7278.T,  EXEDY Corporation',
    name: 'EXEDY Corporation',
    symbol: '7278.T',
  },
  {
    value: '7279.T',
    label: '7279.T,  Hi-Lex Corporation',
    name: 'Hi-Lex Corporation',
    symbol: '7279.T',
  },
  {
    value: '7280.T',
    label: '7280.T,  Mitsuba Corporation',
    name: 'Mitsuba Corporation',
    symbol: '7280.T',
  },
  {
    value: '7282.T',
    label: '7282.T,  Toyoda Gosei Co., Ltd.',
    name: 'Toyoda Gosei Co., Ltd.',
    symbol: '7282.T',
  },
  {
    value: '7283.T',
    label: '7283.T,  Aisan Industry Co., Ltd.',
    name: 'Aisan Industry Co., Ltd.',
    symbol: '7283.T',
  },
  {
    value: '7287.T',
    label: '7287.T,  Nippon Seiki Co., Ltd.',
    name: 'Nippon Seiki Co., Ltd.',
    symbol: '7287.T',
  },
  {
    value: '7294.T',
    label: '7294.T,  Yorozu Corporation',
    name: 'Yorozu Corporation',
    symbol: '7294.T',
  },
  {
    value: '7296.T',
    label: '7296.T,  F.C.C. Co., Ltd.',
    name: 'F.C.C. Co., Ltd.',
    symbol: '7296.T',
  },
  {
    value: '7309.T',
    label: '7309.T,  Shimano Inc.',
    name: 'Shimano Inc.',
    symbol: '7309.T',
  },
  {
    value: '7313.T',
    label: '7313.T,  TS TECH Co., Ltd.',
    name: 'TS TECH Co., Ltd.',
    symbol: '7313.T',
  },
  {
    value: '7315.T',
    label: '7315.T,  IJTT Co., Ltd.',
    name: 'IJTT Co., Ltd.',
    symbol: '7315.T',
  },
  {
    value: '7322.T',
    label: '7322.T,  San ju San Financial Group,Inc.',
    name: 'San ju San Financial Group,Inc.',
    symbol: '7322.T',
  },
  {
    value: '7327.T',
    label: '7327.T,  Daishi Hokuetsu Financial Group, Inc.',
    name: 'Daishi Hokuetsu Financial Group, Inc.',
    symbol: '7327.T',
  },
  {
    value: '7337.T',
    label: '7337.T,  Hirogin Holdings, Inc.',
    name: 'Hirogin Holdings, Inc.',
    symbol: '7337.T',
  },
  {
    value: '7342.T',
    label: '7342.T,  WealthNavi Inc.',
    name: 'WealthNavi Inc.',
    symbol: '7342.T',
  },
  {
    value: '7350.T',
    label: '7350.T,  Okinawa Financial Group,Inc.',
    name: 'Okinawa Financial Group,Inc.',
    symbol: '7350.T',
  },
  {
    value: '7366.T',
    label: '7366.T,  LITALICO Inc.',
    name: 'LITALICO Inc.',
    symbol: '7366.T',
  },
  {
    value: '7380.T',
    label: '7380.T,  Juroku Financial Group,Inc.',
    name: 'Juroku Financial Group,Inc.',
    symbol: '7380.T',
  },
  {
    value: '7381.T',
    label: '7381.T,  Hokkoku Financial Holdings, Inc.',
    name: 'Hokkoku Financial Holdings, Inc.',
    symbol: '7381.T',
  },
  {
    value: '7412.T',
    label: '7412.T,  ATOM Corporation',
    name: 'ATOM Corporation',
    symbol: '7412.T',
  },
  {
    value: '7414.T',
    label: '7414.T,  Onoken Co., Ltd.',
    name: 'Onoken Co., Ltd.',
    symbol: '7414.T',
  },
  {
    value: '7419.T',
    label: '7419.T,  Nojima Corporation',
    name: 'Nojima Corporation',
    symbol: '7419.T',
  },
  {
    value: '7433.T',
    label: '7433.T,  Hakuto Co., Ltd.',
    name: 'Hakuto Co., Ltd.',
    symbol: '7433.T',
  },
  {
    value: '7438.T',
    label: '7438.T,  Kondotec Inc.',
    name: 'Kondotec Inc.',
    symbol: '7438.T',
  },
  {
    value: '7447.T',
    label: '7447.T,  Nagaileben Co., Ltd.',
    name: 'Nagaileben Co., Ltd.',
    symbol: '7447.T',
  },
  {
    value: '7451.T',
    label: '7451.T,  Mitsubishi Shokuhin Co., Ltd.',
    name: 'Mitsubishi Shokuhin Co., Ltd.',
    symbol: '7451.T',
  },
  {
    value: '7453.T',
    label: '7453.T,  Ryohin Keikaku Co., Ltd.',
    name: 'Ryohin Keikaku Co., Ltd.',
    symbol: '7453.T',
  },
  {
    value: '7456.T',
    label: '7456.T,  MATSUDA SANGYO Co., Ltd.',
    name: 'MATSUDA SANGYO Co., Ltd.',
    symbol: '7456.T',
  },
  {
    value: '7458.T',
    label: '7458.T,  Daiichikosho Co., Ltd.',
    name: 'Daiichikosho Co., Ltd.',
    symbol: '7458.T',
  },
  {
    value: '7459.T',
    label: '7459.T,  MediPal Holdings Corporation',
    name: 'MediPal Holdings Corporation',
    symbol: '7459.T',
  },
  {
    value: '7463.T',
    label: '7463.T,  Advan Group Co., Ltd.',
    name: 'Advan Group Co., Ltd.',
    symbol: '7463.T',
  },
  {
    value: '7467.T',
    label: '7467.T,  Hagiwara Electric Holdings Co., Ltd.',
    name: 'Hagiwara Electric Holdings Co., Ltd.',
    symbol: '7467.T',
  },
  {
    value: '7475.T',
    label: '7475.T,  ALBIS Co.,Ltd.',
    name: 'ALBIS Co.,Ltd.',
    symbol: '7475.T',
  },
  {
    value: '7476.T',
    label: '7476.T,  AS ONE Corporation',
    name: 'AS ONE Corporation',
    symbol: '7476.T',
  },
  {
    value: '7483.T',
    label: '7483.T,  Doshisha Co.,Ltd.',
    name: 'Doshisha Co.,Ltd.',
    symbol: '7483.T',
  },
  {
    value: '7504.T',
    label: '7504.T,  Kohsoku Corporation',
    name: 'Kohsoku Corporation',
    symbol: '7504.T',
  },
  {
    value: '7508.T',
    label: '7508.T,  G-7 Holdings Inc.',
    name: 'G-7 Holdings Inc.',
    symbol: '7508.T',
  },
  {
    value: '7512.T',
    label: '7512.T,  Aeon Hokkaido Corporation',
    name: 'Aeon Hokkaido Corporation',
    symbol: '7512.T',
  },
  {
    value: '7513.T',
    label: '7513.T,  Kojima Co.,Ltd.',
    name: 'Kojima Co.,Ltd.',
    symbol: '7513.T',
  },
  {
    value: '7516.T',
    label: '7516.T,  Kohnan Shoji Co., Ltd.',
    name: 'Kohnan Shoji Co., Ltd.',
    symbol: '7516.T',
  },
  {
    value: '7518.T',
    label: '7518.T,  Net One Systems Co., Ltd.',
    name: 'Net One Systems Co., Ltd.',
    symbol: '7518.T',
  },
  {
    value: '7520.T',
    label: "7520.T,  Eco's Co.,Ltd.",
    name: "Eco's Co.,Ltd.",
    symbol: '7520.T',
  },
  {
    value: '7522.T',
    label: '7522.T,  Watami Co., Ltd.',
    name: 'Watami Co., Ltd.',
    symbol: '7522.T',
  },
  {
    value: '7532.T',
    label: '7532.T,  Pan Pacific International Holdings Corporation',
    name: 'Pan Pacific International Holdings Corporation',
    symbol: '7532.T',
  },
  {
    value: '7537.T',
    label: '7537.T,  Marubun Corporation',
    name: 'Marubun Corporation',
    symbol: '7537.T',
  },
  {
    value: '7545.T',
    label: '7545.T,  Nishimatsuya Chain Co., Ltd.',
    name: 'Nishimatsuya Chain Co., Ltd.',
    symbol: '7545.T',
  },
  {
    value: '7550.T',
    label: '7550.T,  Zensho Holdings Co., Ltd.',
    name: 'Zensho Holdings Co., Ltd.',
    symbol: '7550.T',
  },
  {
    value: '7552.T',
    label: '7552.T,  Happinet Corporation',
    name: 'Happinet Corporation',
    symbol: '7552.T',
  },
  {
    value: '7554.T',
    label: '7554.T,  Kourakuen Holdings Corporation',
    name: 'Kourakuen Holdings Corporation',
    symbol: '7554.T',
  },
  {
    value: '7564.T',
    label: '7564.T,  Workman Co.,Ltd.',
    name: 'Workman Co.,Ltd.',
    symbol: '7564.T',
  },
  {
    value: '7575.T',
    label: '7575.T,  Japan Lifeline Co., Ltd.',
    name: 'Japan Lifeline Co., Ltd.',
    symbol: '7575.T',
  },
  {
    value: '7581.T',
    label: '7581.T,  Saizeriya Co.,Ltd.',
    name: 'Saizeriya Co.,Ltd.',
    symbol: '7581.T',
  },
  {
    value: '7590.T',
    label: '7590.T,  Takasho Co.,Ltd.',
    name: 'Takasho Co.,Ltd.',
    symbol: '7590.T',
  },
  {
    value: '7593.T',
    label: '7593.T,  Vt Holdings Co.,Ltd.',
    name: 'Vt Holdings Co.,Ltd.',
    symbol: '7593.T',
  },
  {
    value: '7595.T',
    label: '7595.T,  ARGO GRAPHICS Inc.',
    name: 'ARGO GRAPHICS Inc.',
    symbol: '7595.T',
  },
  {
    value: '7599.T',
    label: '7599.T,  IDOM Inc.',
    name: 'IDOM Inc.',
    symbol: '7599.T',
  },
  {
    value: '7600.T',
    label: '7600.T,  Japan Medical Dynamic Marketing, INC.',
    name: 'Japan Medical Dynamic Marketing, INC.',
    symbol: '7600.T',
  },
  {
    value: '7605.T',
    label: '7605.T,  Fuji Corporation',
    name: 'Fuji Corporation',
    symbol: '7605.T',
  },
  {
    value: '7606.T',
    label: '7606.T,  United Arrows Ltd.',
    name: 'United Arrows Ltd.',
    symbol: '7606.T',
  },
  {
    value: '7607.T',
    label: '7607.T,  Shinwa Co., Ltd.',
    name: 'Shinwa Co., Ltd.',
    symbol: '7607.T',
  },
  {
    value: '7611.T',
    label: '7611.T,  Hiday Hidaka Corp.',
    name: 'Hiday Hidaka Corp.',
    symbol: '7611.T',
  },
  {
    value: '7613.T',
    label: '7613.T,  SIIX Corporation',
    name: 'SIIX Corporation',
    symbol: '7613.T',
  },
  {
    value: '7616.T',
    label: '7616.T,  Colowide Co.,Ltd.',
    name: 'Colowide Co.,Ltd.',
    symbol: '7616.T',
  },
  {
    value: '7618.T',
    label: '7618.T,  PC Depot Corporation',
    name: 'PC Depot Corporation',
    symbol: '7618.T',
  },
  {
    value: '7630.T',
    label: '7630.T,  Ichibanya Co., Ltd.',
    name: 'Ichibanya Co., Ltd.',
    symbol: '7630.T',
  },
  {
    value: '7649.T',
    label: '7649.T,  Sugi Holdings Co.,Ltd.',
    name: 'Sugi Holdings Co.,Ltd.',
    symbol: '7649.T',
  },
  {
    value: '7679.T',
    label: '7679.T,  YAKUODO HOLDINGS Co., Ltd.',
    name: 'YAKUODO HOLDINGS Co., Ltd.',
    symbol: '7679.T',
  },
  {
    value: '7701.T',
    label: '7701.T,  Shimadzu Corporation',
    name: 'Shimadzu Corporation',
    symbol: '7701.T',
  },
  {
    value: '7702.T',
    label: '7702.T,  JMS Co.,Ltd.',
    name: 'JMS Co.,Ltd.',
    symbol: '7702.T',
  },
  {
    value: '7705.T',
    label: '7705.T,  GL Sciences Inc.',
    name: 'GL Sciences Inc.',
    symbol: '7705.T',
  },
  {
    value: '7707.T',
    label: '7707.T,  Precision System Science Co., Ltd.',
    name: 'Precision System Science Co., Ltd.',
    symbol: '7707.T',
  },
  {
    value: '7715.T',
    label: '7715.T,  Nagano Keiki Co., Ltd.',
    name: 'Nagano Keiki Co., Ltd.',
    symbol: '7715.T',
  },
  {
    value: '7716.T',
    label: '7716.T,  Nakanishi Inc.',
    name: 'Nakanishi Inc.',
    symbol: '7716.T',
  },
  {
    value: '7717.T',
    label: '7717.T,  V Technology Co., Ltd.',
    name: 'V Technology Co., Ltd.',
    symbol: '7717.T',
  },
  {
    value: '7718.T',
    label: '7718.T,  Star Micronics Co., Ltd.',
    name: 'Star Micronics Co., Ltd.',
    symbol: '7718.T',
  },
  {
    value: '7725.T',
    label: '7725.T,  Inter Action Corporation',
    name: 'Inter Action Corporation',
    symbol: '7725.T',
  },
  {
    value: '7729.T',
    label: '7729.T,  Tokyo Seimitsu Co., Ltd.',
    name: 'Tokyo Seimitsu Co., Ltd.',
    symbol: '7729.T',
  },
  {
    value: '7730.T',
    label: '7730.T,  Mani, Inc.',
    name: 'Mani, Inc.',
    symbol: '7730.T',
  },
  {
    value: '7731.T',
    label: '7731.T,  Nikon Corporation',
    name: 'Nikon Corporation',
    symbol: '7731.T',
  },
  {
    value: '7732.T',
    label: '7732.T,  Topcon Corporation',
    name: 'Topcon Corporation',
    symbol: '7732.T',
  },
  {
    value: '7733.T',
    label: '7733.T,  Olympus Corporation',
    name: 'Olympus Corporation',
    symbol: '7733.T',
  },
  {
    value: '7734.T',
    label: '7734.T,  Riken Keiki Co., Ltd.',
    name: 'Riken Keiki Co., Ltd.',
    symbol: '7734.T',
  },
  {
    value: '7735.T',
    label: '7735.T,  SCREEN Holdings Co., Ltd.',
    name: 'SCREEN Holdings Co., Ltd.',
    symbol: '7735.T',
  },
  {
    value: '7739.T',
    label: '7739.T,  Canon Electronics Inc.',
    name: 'Canon Electronics Inc.',
    symbol: '7739.T',
  },
  {
    value: '7740.T',
    label: '7740.T,  Tamron Co.,Ltd.',
    name: 'Tamron Co.,Ltd.',
    symbol: '7740.T',
  },
  {
    value: '7741.T',
    label: '7741.T,  HOYA Corporation',
    name: 'HOYA Corporation',
    symbol: '7741.T',
  },
  {
    value: '7743.T',
    label: '7743.T,  SEED Co.,Ltd.',
    name: 'SEED Co.,Ltd.',
    symbol: '7743.T',
  },
  {
    value: '7744.T',
    label: '7744.T,  Noritsu Koki Co., Ltd.',
    name: 'Noritsu Koki Co., Ltd.',
    symbol: '7744.T',
  },
  {
    value: '7745.T',
    label: '7745.T,  A&D HOLON Holdings Company, Limited',
    name: 'A&D HOLON Holdings Company, Limited',
    symbol: '7745.T',
  },
  {
    value: '7747.T',
    label: '7747.T,  Asahi Intecc Co., Ltd.',
    name: 'Asahi Intecc Co., Ltd.',
    symbol: '7747.T',
  },
  {
    value: '7751.T',
    label: '7751.T,  Canon Inc.',
    name: 'Canon Inc.',
    symbol: '7751.T',
  },
  {
    value: '7752.T',
    label: '7752.T,  Ricoh Company, Ltd.',
    name: 'Ricoh Company, Ltd.',
    symbol: '7752.T',
  },
  {
    value: '7762.T',
    label: '7762.T,  Citizen Watch Co., Ltd.',
    name: 'Citizen Watch Co., Ltd.',
    symbol: '7762.T',
  },
  {
    value: '7777.T',
    label: '7777.T,  3-D Matrix, Ltd.',
    name: '3-D Matrix, Ltd.',
    symbol: '7777.T',
  },
  {
    value: '7779.T',
    label: '7779.T,  CYBERDYNE Inc.',
    name: 'CYBERDYNE Inc.',
    symbol: '7779.T',
  },
  {
    value: '7780.T',
    label: '7780.T,  Menicon Co., Ltd.',
    name: 'Menicon Co., Ltd.',
    symbol: '7780.T',
  },
  {
    value: '7803.T',
    label: '7803.T,  Bushiroad Inc.',
    name: 'Bushiroad Inc.',
    symbol: '7803.T',
  },
  {
    value: '7816.T',
    label: '7816.T,  Snow Peak, Inc.',
    name: 'Snow Peak, Inc.',
    symbol: '7816.T',
  },
  {
    value: '7817.T',
    label: '7817.T,  Paramount Bed Holdings Co., Ltd.',
    name: 'Paramount Bed Holdings Co., Ltd.',
    symbol: '7817.T',
  },
  {
    value: '7818.T',
    label: '7818.T,  TRANSACTION CO.,Ltd.',
    name: 'TRANSACTION CO.,Ltd.',
    symbol: '7818.T',
  },
  {
    value: '7820.T',
    label: '7820.T,  Nihon Flush Co., Ltd.',
    name: 'Nihon Flush Co., Ltd.',
    symbol: '7820.T',
  },
  {
    value: '7821.T',
    label: '7821.T,  Maeda Kosen Co., Ltd.',
    name: 'Maeda Kosen Co., Ltd.',
    symbol: '7821.T',
  },
  {
    value: '7832.T',
    label: '7832.T,  BANDAI NAMCO Holdings Inc.',
    name: 'BANDAI NAMCO Holdings Inc.',
    symbol: '7832.T',
  },
  {
    value: '7839.T',
    label: '7839.T,  Shoei Co., Ltd.',
    name: 'Shoei Co., Ltd.',
    symbol: '7839.T',
  },
  {
    value: '7840.T',
    label: '7840.T,  France Bed Holdings Co.,Ltd.',
    name: 'France Bed Holdings Co.,Ltd.',
    symbol: '7840.T',
  },
  {
    value: '7844.T',
    label: '7844.T,  Marvelous Inc.',
    name: 'Marvelous Inc.',
    symbol: '7844.T',
  },
  {
    value: '7846.T',
    label: '7846.T,  Pilot Corporation',
    name: 'Pilot Corporation',
    symbol: '7846.T',
  },
  {
    value: '7856.T',
    label: '7856.T,  Hagihara Industries Inc.',
    name: 'Hagihara Industries Inc.',
    symbol: '7856.T',
  },
  {
    value: '7860.T',
    label: '7860.T,  Avex Inc.',
    name: 'Avex Inc.',
    symbol: '7860.T',
  },
  {
    value: '7864.T',
    label: '7864.T,  Fuji Seal International, Inc.',
    name: 'Fuji Seal International, Inc.',
    symbol: '7864.T',
  },
  {
    value: '7867.T',
    label: '7867.T,  TOMY Company, Ltd.',
    name: 'TOMY Company, Ltd.',
    symbol: '7867.T',
  },
  {
    value: '7874.T',
    label: '7874.T,  LEC, Inc.',
    name: 'LEC, Inc.',
    symbol: '7874.T',
  },
  {
    value: '7888.T',
    label: '7888.T,  Sanko Gosei Ltd.',
    name: 'Sanko Gosei Ltd.',
    symbol: '7888.T',
  },
  {
    value: '7905.T',
    label: '7905.T,  Daiken Corporation',
    name: 'Daiken Corporation',
    symbol: '7905.T',
  },
  {
    value: '7906.T',
    label: '7906.T,  YONEX Co., Ltd.',
    name: 'YONEX Co., Ltd.',
    symbol: '7906.T',
  },
  {
    value: '7911.T',
    label: '7911.T,  Toppan Inc.',
    name: 'Toppan Inc.',
    symbol: '7911.T',
  },
  {
    value: '7912.T',
    label: '7912.T,  Dai Nippon Printing Co., Ltd.',
    name: 'Dai Nippon Printing Co., Ltd.',
    symbol: '7912.T',
  },
  {
    value: '7915.T',
    label: '7915.T,  Nissha Co., Ltd.',
    name: 'Nissha Co., Ltd.',
    symbol: '7915.T',
  },
  {
    value: '7917.T',
    label: '7917.T,  Fujimori Kogyo Co., Ltd.',
    name: 'Fujimori Kogyo Co., Ltd.',
    symbol: '7917.T',
  },
  {
    value: '7921.T',
    label: '7921.T,  Takara & Company Ltd.',
    name: 'Takara & Company Ltd.',
    symbol: '7921.T',
  },
  {
    value: '7936.T',
    label: '7936.T,  ASICS Corporation',
    name: 'ASICS Corporation',
    symbol: '7936.T',
  },
  {
    value: '7942.T',
    label: '7942.T,  JSP Corporation',
    name: 'JSP Corporation',
    symbol: '7942.T',
  },
  {
    value: '7943.T',
    label: '7943.T,  Nichiha Corporation',
    name: 'Nichiha Corporation',
    symbol: '7943.T',
  },
  {
    value: '7947.T',
    label: '7947.T,  FP Corporation',
    name: 'FP Corporation',
    symbol: '7947.T',
  },
  {
    value: '7951.T',
    label: '7951.T,  Yamaha Corporation',
    name: 'Yamaha Corporation',
    symbol: '7951.T',
  },
  {
    value: '7952.T',
    label: '7952.T,  Kawai Musical Instruments Manufacturing Co., Ltd.',
    name: 'Kawai Musical Instruments Manufacturing Co., Ltd.',
    symbol: '7952.T',
  },
  {
    value: '7955.T',
    label: '7955.T,  Cleanup Corporation',
    name: 'Cleanup Corporation',
    symbol: '7955.T',
  },
  {
    value: '7956.T',
    label: '7956.T,  Pigeon Corporation',
    name: 'Pigeon Corporation',
    symbol: '7956.T',
  },
  {
    value: '7958.T',
    label: '7958.T,  Tenma Corporation',
    name: 'Tenma Corporation',
    symbol: '7958.T',
  },
  {
    value: '7962.T',
    label: '7962.T,  King Jim Co., Ltd.',
    name: 'King Jim Co., Ltd.',
    symbol: '7962.T',
  },
  {
    value: '7965.T',
    label: '7965.T,  Zojirushi Corporation',
    name: 'Zojirushi Corporation',
    symbol: '7965.T',
  },
  {
    value: '7966.T',
    label: '7966.T,  LINTEC Corporation',
    name: 'LINTEC Corporation',
    symbol: '7966.T',
  },
  {
    value: '7970.T',
    label: '7970.T,  Shin-Etsu Polymer Co.,Ltd.',
    name: 'Shin-Etsu Polymer Co.,Ltd.',
    symbol: '7970.T',
  },
  {
    value: '7972.T',
    label: '7972.T,  Itoki Corporation',
    name: 'Itoki Corporation',
    symbol: '7972.T',
  },
  {
    value: '7974.T',
    label: '7974.T,  Nintendo Co., Ltd.',
    name: 'Nintendo Co., Ltd.',
    symbol: '7974.T',
  },
  {
    value: '7976.T',
    label: '7976.T,  Mitsubishi Pencil Co., Ltd.',
    name: 'Mitsubishi Pencil Co., Ltd.',
    symbol: '7976.T',
  },
  {
    value: '7981.T',
    label: '7981.T,  Takara Standard Co.,Ltd.',
    name: 'Takara Standard Co.,Ltd.',
    symbol: '7981.T',
  },
  {
    value: '7984.T',
    label: '7984.T,  Kokuyo Co., Ltd.',
    name: 'Kokuyo Co., Ltd.',
    symbol: '7984.T',
  },
  {
    value: '7987.T',
    label: '7987.T,  Nakabayashi Co., Ltd.',
    name: 'Nakabayashi Co., Ltd.',
    symbol: '7987.T',
  },
  {
    value: '7988.T',
    label: '7988.T,  Nifco Inc.',
    name: 'Nifco Inc.',
    symbol: '7988.T',
  },
  {
    value: '7990.T',
    label: '7990.T,  Globeride, Inc.',
    name: 'Globeride, Inc.',
    symbol: '7990.T',
  },
  {
    value: '7994.T',
    label: '7994.T,  Okamura Corporation',
    name: 'Okamura Corporation',
    symbol: '7994.T',
  },
  {
    value: '7995.T',
    label: '7995.T,  Valqua, Ltd.',
    name: 'Valqua, Ltd.',
    symbol: '7995.T',
  },
  {
    value: '8001.T',
    label: '8001.T,  ITOCHU Corporation',
    name: 'ITOCHU Corporation',
    symbol: '8001.T',
  },
  {
    value: '8002.T',
    label: '8002.T,  Marubeni Corporation',
    name: 'Marubeni Corporation',
    symbol: '8002.T',
  },
  {
    value: '8005.T',
    label: '8005.T,  Scroll Corporation',
    name: 'Scroll Corporation',
    symbol: '8005.T',
  },
  {
    value: '8008.T',
    label: '8008.T,  Yondoshi Holdings Inc.',
    name: 'Yondoshi Holdings Inc.',
    symbol: '8008.T',
  },
  {
    value: '8010.SR',
    label: '8010.SR,  The Company for Cooperative Insurance',
    name: 'The Company for Cooperative Insurance',
    symbol: '8010.SR',
  },
  {
    value: '8012.T',
    label: '8012.T,  Nagase & Co., Ltd.',
    name: 'Nagase & Co., Ltd.',
    symbol: '8012.T',
  },
  {
    value: '8014.T',
    label: '8014.T,  Chori Co., Ltd.',
    name: 'Chori Co., Ltd.',
    symbol: '8014.T',
  },
  {
    value: '8015.T',
    label: '8015.T,  Toyota Tsusho Corporation',
    name: 'Toyota Tsusho Corporation',
    symbol: '8015.T',
  },
  {
    value: '8016.T',
    label: '8016.T,  Onward Holdings Co., Ltd.',
    name: 'Onward Holdings Co., Ltd.',
    symbol: '8016.T',
  },
  {
    value: '8016.TW',
    label: '8016.TW,  Sitronix Technology Corporation',
    name: 'Sitronix Technology Corporation',
    symbol: '8016.TW',
  },
  {
    value: '8018.T',
    label: '8018.T,  Sankyo Seiko Co., Ltd.',
    name: 'Sankyo Seiko Co., Ltd.',
    symbol: '8018.T',
  },
  {
    value: '8020.SR',
    label: '8020.SR,  Malath Cooperative Insurance Company',
    name: 'Malath Cooperative Insurance Company',
    symbol: '8020.SR',
  },
  {
    value: '8020.T',
    label: '8020.T,  Kanematsu Corporation',
    name: 'Kanematsu Corporation',
    symbol: '8020.T',
  },
  {
    value: '8021.TW',
    label: '8021.TW,  Topoint Technology Co., Ltd.',
    name: 'Topoint Technology Co., Ltd.',
    symbol: '8021.TW',
  },
  {
    value: '8022.T',
    label: '8022.T,  Mizuno Corporation',
    name: 'Mizuno Corporation',
    symbol: '8022.T',
  },
  {
    value: '8028.TW',
    label: '8028.TW,  Phoenix Silicon International Corporation',
    name: 'Phoenix Silicon International Corporation',
    symbol: '8028.TW',
  },
  {
    value: '8030.SR',
    label:
      '8030.SR,  The Mediterranean and Gulf Cooperative Insurance and Reinsurance Company',
    name: 'The Mediterranean and Gulf Cooperative Insurance and Reinsurance Company',
    symbol: '8030.SR',
  },
  {
    value: '8031.T',
    label: '8031.T,  Mitsui & Co., Ltd.',
    name: 'Mitsui & Co., Ltd.',
    symbol: '8031.T',
  },
  {
    value: '8032.T',
    label: '8032.T,  Japan Pulp and Paper Company Limited',
    name: 'Japan Pulp and Paper Company Limited',
    symbol: '8032.T',
  },
  {
    value: '8035.T',
    label: '8035.T,  Tokyo Electron Limited',
    name: 'Tokyo Electron Limited',
    symbol: '8035.T',
  },
  {
    value: '8037.T',
    label: '8037.T,  Kamei Corporation',
    name: 'Kamei Corporation',
    symbol: '8037.T',
  },
  {
    value: '8039.TW',
    label: '8039.TW,  Taiflex Scientific Co., Ltd.',
    name: 'Taiflex Scientific Co., Ltd.',
    symbol: '8039.TW',
  },
  {
    value: '8043.T',
    label: '8043.T,  Starzen Company Limited',
    name: 'Starzen Company Limited',
    symbol: '8043.T',
  },
  {
    value: '8044.TWO',
    label: '8044.TWO,  PChome Online Inc.',
    name: 'PChome Online Inc.',
    symbol: '8044.TWO',
  },
  {
    value: '8046.TW',
    label: '8046.TW,  Nan Ya Printed Circuit Board Corporation',
    name: 'Nan Ya Printed Circuit Board Corporation',
    symbol: '8046.TW',
  },
  {
    value: '8049.TWO',
    label: '8049.TWO,  Ampire Co., Ltd.',
    name: 'Ampire Co., Ltd.',
    symbol: '8049.TWO',
  },
  {
    value: '8050.T',
    label: '8050.T,  Seiko Group Corporation',
    name: 'Seiko Group Corporation',
    symbol: '8050.T',
  },
  {
    value: '8050.TWO',
    label: '8050.TWO,  IBASE Technology Inc.',
    name: 'IBASE Technology Inc.',
    symbol: '8050.TWO',
  },
  {
    value: '8051.T',
    label: '8051.T,  Yamazen Corporation',
    name: 'Yamazen Corporation',
    symbol: '8051.T',
  },
  {
    value: '8053.T',
    label: '8053.T,  Sumitomo Corporation',
    name: 'Sumitomo Corporation',
    symbol: '8053.T',
  },
  {
    value: '8056.T',
    label: '8056.T,  BIPROGY Inc.',
    name: 'BIPROGY Inc.',
    symbol: '8056.T',
  },
  {
    value: '8057.T',
    label: '8057.T,  Uchida Yoko Co., Ltd.',
    name: 'Uchida Yoko Co., Ltd.',
    symbol: '8057.T',
  },
  {
    value: '8058.T',
    label: '8058.T,  Mitsubishi Corporation',
    name: 'Mitsubishi Corporation',
    symbol: '8058.T',
  },
  {
    value: '8059.T',
    label: '8059.T,  Daiichi Jitsugyo Co., Ltd.',
    name: 'Daiichi Jitsugyo Co., Ltd.',
    symbol: '8059.T',
  },
  {
    value: '8060.SR',
    label: '8060.SR,  Walaa Cooperative Insurance Company',
    name: 'Walaa Cooperative Insurance Company',
    symbol: '8060.SR',
  },
  {
    value: '8060.T',
    label: '8060.T,  Canon Marketing Japan Inc.',
    name: 'Canon Marketing Japan Inc.',
    symbol: '8060.T',
  },
  {
    value: '8068.T',
    label: '8068.T,  Ryoyo Electro Corporation',
    name: 'Ryoyo Electro Corporation',
    symbol: '8068.T',
  },
  {
    value: '8069.TWO',
    label: '8069.TWO,  E Ink Holdings Inc.',
    name: 'E Ink Holdings Inc.',
    symbol: '8069.TWO',
  },
  {
    value: '8070.SR',
    label: '8070.SR,  Arabian Shield Cooperative Insurance Company',
    name: 'Arabian Shield Cooperative Insurance Company',
    symbol: '8070.SR',
  },
  {
    value: '8070.TW',
    label: '8070.TW,  Chang Wah Electromaterials Inc.',
    name: 'Chang Wah Electromaterials Inc.',
    symbol: '8070.TW',
  },
  {
    value: '8074.T',
    label: '8074.T,  Yuasa Trading Co., Ltd.',
    name: 'Yuasa Trading Co., Ltd.',
    symbol: '8074.T',
  },
  {
    value: '8076.TWO',
    label: '8076.TWO,  Firich Enterprises Co., Ltd.',
    name: 'Firich Enterprises Co., Ltd.',
    symbol: '8076.TWO',
  },
  {
    value: '8078.T',
    label: '8078.T,  Hanwa Co., Ltd.',
    name: 'Hanwa Co., Ltd.',
    symbol: '8078.T',
  },
  {
    value: '8079.T',
    label: '8079.T,  Shoei Foods Corporation',
    name: 'Shoei Foods Corporation',
    symbol: '8079.T',
  },
  {
    value: '8081.TW',
    label: '8081.TW,  Global Mixed-Mode Technology Inc.',
    name: 'Global Mixed-Mode Technology Inc.',
    symbol: '8081.TW',
  },
  {
    value: '8084.T',
    label: '8084.T,  Ryoden Corporation',
    name: 'Ryoden Corporation',
    symbol: '8084.T',
  },
  {
    value: '8086.T',
    label: '8086.T,  Nipro Corporation',
    name: 'Nipro Corporation',
    symbol: '8086.T',
  },
  {
    value: '8086.TWO',
    label: '8086.TWO,  Advanced Wireless Semiconductor Company',
    name: 'Advanced Wireless Semiconductor Company',
    symbol: '8086.TWO',
  },
  {
    value: '8088.T',
    label: '8088.T,  Iwatani Corporation',
    name: 'Iwatani Corporation',
    symbol: '8088.T',
  },
  {
    value: '8096.T',
    label: '8096.T,  Kanematsu Electronics Ltd.',
    name: 'Kanematsu Electronics Ltd.',
    symbol: '8096.T',
  },
  {
    value: '8097.T',
    label: '8097.T,  San-Ai Obbli Co., Ltd.',
    name: 'San-Ai Obbli Co., Ltd.',
    symbol: '8097.T',
  },
  {
    value: '8098.T',
    label: '8098.T,  Inabata & Co.,Ltd.',
    name: 'Inabata & Co.,Ltd.',
    symbol: '8098.T',
  },
  {
    value: '8099.TWO',
    label: '8099.TWO,  Tatung System Technologies Inc.',
    name: 'Tatung System Technologies Inc.',
    symbol: '8099.TWO',
  },
  {
    value: '8101.T',
    label: '8101.T,  GSI Creos Corporation',
    name: 'GSI Creos Corporation',
    symbol: '8101.T',
  },
  {
    value: '8103.T',
    label: '8103.T,  Meiwa Corporation',
    name: 'Meiwa Corporation',
    symbol: '8103.T',
  },
  {
    value: '8103.TW',
    label: '8103.TW,  CviLux Corporation',
    name: 'CviLux Corporation',
    symbol: '8103.TW',
  },
  {
    value: '8105.TW',
    label: '8105.TW,  Giantplus Technology Co., Ltd.',
    name: 'Giantplus Technology Co., Ltd.',
    symbol: '8105.TW',
  },
  {
    value: '8109.TWO',
    label: '8109.TWO,  P-Duke Technology Co.,Ltd.',
    name: 'P-Duke Technology Co.,Ltd.',
    symbol: '8109.TWO',
  },
  {
    value: '8110.TW',
    label: '8110.TW,  Walton Advanced Engineering, Inc.',
    name: 'Walton Advanced Engineering, Inc.',
    symbol: '8110.TW',
  },
  {
    value: '8111.T',
    label: '8111.T,  Goldwin Inc.',
    name: 'Goldwin Inc.',
    symbol: '8111.T',
  },
  {
    value: '8112.TW',
    label: '8112.TW,  Supreme Electronics Co., Ltd.',
    name: 'Supreme Electronics Co., Ltd.',
    symbol: '8112.TW',
  },
  {
    value: '8113.T',
    label: '8113.T,  Unicharm Corporation',
    name: 'Unicharm Corporation',
    symbol: '8113.T',
  },
  {
    value: '8114.T',
    label: '8114.T,  Descente Ltd.',
    name: 'Descente Ltd.',
    symbol: '8114.T',
  },
  {
    value: '8117.T',
    label: '8117.T,  Central Automotive Products Ltd.',
    name: 'Central Automotive Products Ltd.',
    symbol: '8117.T',
  },
  {
    value: '8125.T',
    label: '8125.T,  Wakita & Co.,LTD.',
    name: 'Wakita & Co.,LTD.',
    symbol: '8125.T',
  },
  {
    value: '8129.T',
    label: '8129.T,  Toho Holdings Co., Ltd.',
    name: 'Toho Holdings Co., Ltd.',
    symbol: '8129.T',
  },
  {
    value: '8130.T',
    label: '8130.T,  Sangetsu Corporation',
    name: 'Sangetsu Corporation',
    symbol: '8130.T',
  },
  {
    value: '8131.T',
    label: '8131.T,  Mitsuuroko Group Holdings Co.,Ltd.',
    name: 'Mitsuuroko Group Holdings Co.,Ltd.',
    symbol: '8131.T',
  },
  {
    value: '8131.TW',
    label: '8131.TW,  Formosa Advanced Technologies Co., Ltd.',
    name: 'Formosa Advanced Technologies Co., Ltd.',
    symbol: '8131.TW',
  },
  {
    value: '8132.T',
    label: '8132.T,  Sinanen Holdings Co., Ltd.',
    name: 'Sinanen Holdings Co., Ltd.',
    symbol: '8132.T',
  },
  {
    value: '8133.T',
    label: '8133.T,  Itochu Enex Co.,Ltd.',
    name: 'Itochu Enex Co.,Ltd.',
    symbol: '8133.T',
  },
  {
    value: '8136.T',
    label: '8136.T,  Sanrio Company, Ltd.',
    name: 'Sanrio Company, Ltd.',
    symbol: '8136.T',
  },
  {
    value: '8137.T',
    label: '8137.T,  Sun-Wa Technos Corporation',
    name: 'Sun-Wa Technos Corporation',
    symbol: '8137.T',
  },
  {
    value: '8140.T',
    label: '8140.T,  Ryosan Company, Limited',
    name: 'Ryosan Company, Limited',
    symbol: '8140.T',
  },
  {
    value: '8141.T',
    label: '8141.T,  Shinko Shoji Co., Ltd.',
    name: 'Shinko Shoji Co., Ltd.',
    symbol: '8141.T',
  },
  {
    value: '8150.T',
    label: '8150.T,  Sanshin Electronics Co., Ltd.',
    name: 'Sanshin Electronics Co., Ltd.',
    symbol: '8150.T',
  },
  {
    value: '8150.TW',
    label: '8150.TW,  ChipMOS TECHNOLOGIES INC.',
    name: 'ChipMOS TECHNOLOGIES INC.',
    symbol: '8150.TW',
  },
  {
    value: '8151.T',
    label: '8151.T,  TOYO Corporation',
    name: 'TOYO Corporation',
    symbol: '8151.T',
  },
  {
    value: '8153.T',
    label: '8153.T,  Mos Food Services, Inc.',
    name: 'Mos Food Services, Inc.',
    symbol: '8153.T',
  },
  {
    value: '8154.T',
    label: '8154.T,  Kaga Electronics Co.,Ltd.',
    name: 'Kaga Electronics Co.,Ltd.',
    symbol: '8154.T',
  },
  {
    value: '8155.T',
    label: '8155.T,  Mimasu Semiconductor Industry Co., Ltd.',
    name: 'Mimasu Semiconductor Industry Co., Ltd.',
    symbol: '8155.T',
  },
  {
    value: '8155.TWO',
    label: '8155.TWO,  Allied Circuit Co., Ltd.',
    name: 'Allied Circuit Co., Ltd.',
    symbol: '8155.TWO',
  },
  {
    value: '8157.T',
    label: '8157.T,  Tsuzuki Denki Co., Ltd.',
    name: 'Tsuzuki Denki Co., Ltd.',
    symbol: '8157.T',
  },
  {
    value: '8159.T',
    label: '8159.T,  Tachibana Eletech Co., Ltd.',
    name: 'Tachibana Eletech Co., Ltd.',
    symbol: '8159.T',
  },
  {
    value: '8160.T',
    label: '8160.T,  Kisoji Co., Ltd.',
    name: 'Kisoji Co., Ltd.',
    symbol: '8160.T',
  },
  {
    value: '8163.TW',
    label: '8163.TW,  Darfon Electronics Corp.',
    name: 'Darfon Electronics Corp.',
    symbol: '8163.TW',
  },
  {
    value: '8167.T',
    label: '8167.T,  Retail Partners Co., Ltd.',
    name: 'Retail Partners Co., Ltd.',
    symbol: '8167.T',
  },
  {
    value: '8168.T',
    label: '8168.T,  Keiyo Co., Ltd.',
    name: 'Keiyo Co., Ltd.',
    symbol: '8168.T',
  },
  {
    value: '8170.SR',
    label: '8170.SR,  Al-Etihad Cooperative Insurance Company',
    name: 'Al-Etihad Cooperative Insurance Company',
    symbol: '8170.SR',
  },
  {
    value: '8173.T',
    label: '8173.T,  Joshin Denki Co., Ltd.',
    name: 'Joshin Denki Co., Ltd.',
    symbol: '8173.T',
  },
  {
    value: '8174.T',
    label: '8174.T,  Nippon Gas Co., Ltd.',
    name: 'Nippon Gas Co., Ltd.',
    symbol: '8174.T',
  },
  {
    value: '8179.T',
    label: '8179.T,  ROYAL HOLDINGS Co., Ltd.',
    name: 'ROYAL HOLDINGS Co., Ltd.',
    symbol: '8179.T',
  },
  {
    value: '8182.T',
    label: '8182.T,  Inageya Co., Ltd.',
    name: 'Inageya Co., Ltd.',
    symbol: '8182.T',
  },
  {
    value: '8182.TWO',
    label: '8182.TWO,  Harmony Electronics Co. Ltd',
    name: 'Harmony Electronics Co. Ltd',
    symbol: '8182.TWO',
  },
  {
    value: '8183.TWO',
    label: '8183.TWO,  Info-Tek Corporation',
    name: 'Info-Tek Corporation',
    symbol: '8183.TWO',
  },
  {
    value: '8185.T',
    label: '8185.T,  Chiyoda Co., Ltd.',
    name: 'Chiyoda Co., Ltd.',
    symbol: '8185.T',
  },
  {
    value: '8190.SR',
    label: '8190.SR,  United Cooperative Assurance Company',
    name: 'United Cooperative Assurance Company',
    symbol: '8190.SR',
  },
  {
    value: '8194.T',
    label: '8194.T,  Life Corporation',
    name: 'Life Corporation',
    symbol: '8194.T',
  },
  {
    value: '8198.T',
    label: '8198.T,  Maxvalu Tokai Co.,Ltd.',
    name: 'Maxvalu Tokai Co.,Ltd.',
    symbol: '8198.T',
  },
  {
    value: '8200.SR',
    label: '8200.SR,  Saudi Re for Cooperative Reinsurance Company',
    name: 'Saudi Re for Cooperative Reinsurance Company',
    symbol: '8200.SR',
  },
  {
    value: '8200.T',
    label: '8200.T,  Ringer Hut Co., Ltd.',
    name: 'Ringer Hut Co., Ltd.',
    symbol: '8200.T',
  },
  {
    value: '8203.T',
    label: '8203.T,  Mr Max Holdings Ltd.',
    name: 'Mr Max Holdings Ltd.',
    symbol: '8203.T',
  },
  {
    value: '8210.SR',
    label: '8210.SR,  Bupa Arabia for Cooperative Insurance Company',
    name: 'Bupa Arabia for Cooperative Insurance Company',
    symbol: '8210.SR',
  },
  {
    value: '8210.TW',
    label: '8210.TW,  Chenbro Micom Co., Ltd.',
    name: 'Chenbro Micom Co., Ltd.',
    symbol: '8210.TW',
  },
  {
    value: '8213.TW',
    label: '8213.TW,  Taiwan Pcb Techvest Co., Ltd.',
    name: 'Taiwan Pcb Techvest Co., Ltd.',
    symbol: '8213.TW',
  },
  {
    value: '8214.T',
    label: '8214.T,  AOKI Holdings Inc.',
    name: 'AOKI Holdings Inc.',
    symbol: '8214.T',
  },
  {
    value: '8215.TW',
    label: '8215.TW,  BenQ Materials Corporation',
    name: 'BenQ Materials Corporation',
    symbol: '8215.TW',
  },
  {
    value: '8217.T',
    label: '8217.T,  Okuwa Co., Ltd.',
    name: 'Okuwa Co., Ltd.',
    symbol: '8217.T',
  },
  {
    value: '8218.T',
    label: '8218.T,  Komeri Co.,Ltd.',
    name: 'Komeri Co.,Ltd.',
    symbol: '8218.T',
  },
  {
    value: '8219.T',
    label: '8219.T,  Aoyama Trading Co., Ltd.',
    name: 'Aoyama Trading Co., Ltd.',
    symbol: '8219.T',
  },
  {
    value: '8227.T',
    label: '8227.T,  SHIMAMURA Co., Ltd.',
    name: 'SHIMAMURA Co., Ltd.',
    symbol: '8227.T',
  },
  {
    value: '8230.SR',
    label: '8230.SR,  Al Rajhi Company for Cooperative Insurance',
    name: 'Al Rajhi Company for Cooperative Insurance',
    symbol: '8230.SR',
  },
  {
    value: '8233.T',
    label: '8233.T,  Takashimaya Company, Limited',
    name: 'Takashimaya Company, Limited',
    symbol: '8233.T',
  },
  {
    value: '8237.T',
    label: '8237.T,  Matsuya Co., Ltd.',
    name: 'Matsuya Co., Ltd.',
    symbol: '8237.T',
  },
  {
    value: '8240.TWO',
    label: '8240.TWO,  Wah Hong Industrial Corp.',
    name: 'Wah Hong Industrial Corp.',
    symbol: '8240.TWO',
  },
  {
    value: '8242.T',
    label: '8242.T,  H2O Retailing Corporation',
    name: 'H2O Retailing Corporation',
    symbol: '8242.T',
  },
  {
    value: '8249.T',
    label: '8249.T,  TECHNO ASSOCIE Co., Ltd.',
    name: 'TECHNO ASSOCIE Co., Ltd.',
    symbol: '8249.T',
  },
  {
    value: '8250.SR',
    label: '8250.SR,  Gulf Insurance Group',
    name: 'Gulf Insurance Group',
    symbol: '8250.SR',
  },
  {
    value: '8252.T',
    label: '8252.T,  Marui Group Co., Ltd.',
    name: 'Marui Group Co., Ltd.',
    symbol: '8252.T',
  },
  {
    value: '8253.T',
    label: '8253.T,  Credit Saison Co., Ltd.',
    name: 'Credit Saison Co., Ltd.',
    symbol: '8253.T',
  },
  {
    value: '8255.T',
    label: '8255.T,  Axial Retailing Inc.',
    name: 'Axial Retailing Inc.',
    symbol: '8255.T',
  },
  {
    value: '8260.SR',
    label: '8260.SR,  Gulf General Cooperative Insurance Company',
    name: 'Gulf General Cooperative Insurance Company',
    symbol: '8260.SR',
  },
  {
    value: '8261.TW',
    label: '8261.TW,  Advanced Power Electronics Co., Ltd.',
    name: 'Advanced Power Electronics Co., Ltd.',
    symbol: '8261.TW',
  },
  {
    value: '8267.T',
    label: '8267.T,  Aeon Co., Ltd.',
    name: 'Aeon Co., Ltd.',
    symbol: '8267.T',
  },
  {
    value: '8270.SR',
    label: '8270.SR,  Buruj Cooperative Insurance Company',
    name: 'Buruj Cooperative Insurance Company',
    symbol: '8270.SR',
  },
  {
    value: '8273.T',
    label: '8273.T,  Izumi Co., Ltd.',
    name: 'Izumi Co., Ltd.',
    symbol: '8273.T',
  },
  {
    value: '8276.T',
    label: '8276.T,  Heiwado Co.,Ltd.',
    name: 'Heiwado Co.,Ltd.',
    symbol: '8276.T',
  },
  {
    value: '8278.T',
    label: '8278.T,  Fuji Co., Ltd.',
    name: 'Fuji Co., Ltd.',
    symbol: '8278.T',
  },
  {
    value: '8279.T',
    label: '8279.T,  Yaoko Co.,Ltd.',
    name: 'Yaoko Co.,Ltd.',
    symbol: '8279.T',
  },
  {
    value: '8280.SR',
    label: '8280.SR,  Al Alamiya for Cooperative Insurance Company',
    name: 'Al Alamiya for Cooperative Insurance Company',
    symbol: '8280.SR',
  },
  {
    value: '8281.T',
    label: '8281.T,  Xebio Holdings Co., Ltd.',
    name: 'Xebio Holdings Co., Ltd.',
    symbol: '8281.T',
  },
  {
    value: '8282.T',
    label: "8282.T,  K's Holdings Corporation",
    name: "K's Holdings Corporation",
    symbol: '8282.T',
  },
  {
    value: '8283.HK',
    label: '8283.HK,  Zhongshi Minan Holdings Limited',
    name: 'Zhongshi Minan Holdings Limited',
    symbol: '8283.HK',
  },
  {
    value: '8283.T',
    label: '8283.T,  Paltac Corporation',
    name: 'Paltac Corporation',
    symbol: '8283.T',
  },
  {
    value: '8285.T',
    label: '8285.T,  Mitani Sangyo Co., Ltd.',
    name: 'Mitani Sangyo Co., Ltd.',
    symbol: '8285.T',
  },
  {
    value: '8289.T',
    label: '8289.T,  Olympic Group Corporation',
    name: 'Olympic Group Corporation',
    symbol: '8289.T',
  },
  {
    value: '8291.T',
    label: '8291.T,  Nissan Tokyo Sales Holdings Co., Ltd.',
    name: 'Nissan Tokyo Sales Holdings Co., Ltd.',
    symbol: '8291.T',
  },
  {
    value: '8299.TWO',
    label: '8299.TWO,  Phison Electronics Corp.',
    name: 'Phison Electronics Corp.',
    symbol: '8299.TWO',
  },
  {
    value: '8300.SR',
    label: '8300.SR,  Wataniya Insurance Company',
    name: 'Wataniya Insurance Company',
    symbol: '8300.SR',
  },
  {
    value: '8303.T',
    label: '8303.T,  Shinsei Bank, Limited',
    name: 'Shinsei Bank, Limited',
    symbol: '8303.T',
  },
  {
    value: '8304.T',
    label: '8304.T,  Aozora Bank, Ltd.',
    name: 'Aozora Bank, Ltd.',
    symbol: '8304.T',
  },
  {
    value: '8306.T',
    label: '8306.T,  Mitsubishi UFJ Financial Group, Inc.',
    name: 'Mitsubishi UFJ Financial Group, Inc.',
    symbol: '8306.T',
  },
  {
    value: '8308.T',
    label: '8308.T,  Resona Holdings, Inc.',
    name: 'Resona Holdings, Inc.',
    symbol: '8308.T',
  },
  {
    value: '8309.T',
    label: '8309.T,  Sumitomo Mitsui Trust Holdings, Inc.',
    name: 'Sumitomo Mitsui Trust Holdings, Inc.',
    symbol: '8309.T',
  },
  {
    value: '8310.SR',
    label: '8310.SR,  Amana Cooperative Insurance Company',
    name: 'Amana Cooperative Insurance Company',
    symbol: '8310.SR',
  },
  {
    value: '8316.T',
    label: '8316.T,  Sumitomo Mitsui Financial Group, Inc.',
    name: 'Sumitomo Mitsui Financial Group, Inc.',
    symbol: '8316.T',
  },
  {
    value: '8331.T',
    label: '8331.T,  The Chiba Bank, Ltd.',
    name: 'The Chiba Bank, Ltd.',
    symbol: '8331.T',
  },
  {
    value: '8334.T',
    label: '8334.T,  The Gunma Bank, Ltd.',
    name: 'The Gunma Bank, Ltd.',
    symbol: '8334.T',
  },
  {
    value: '8336.T',
    label: '8336.T,  The Musashino Bank, Ltd.',
    name: 'The Musashino Bank, Ltd.',
    symbol: '8336.T',
  },
  {
    value: '8337.T',
    label: '8337.T,  The Chiba Kogyo Bank, Ltd.',
    name: 'The Chiba Kogyo Bank, Ltd.',
    symbol: '8337.T',
  },
  {
    value: '8338.T',
    label: '8338.T,  Tsukuba Bank, Ltd.',
    name: 'Tsukuba Bank, Ltd.',
    symbol: '8338.T',
  },
  {
    value: '8341.T',
    label: '8341.T,  The 77 Bank, Ltd.',
    name: 'The 77 Bank, Ltd.',
    symbol: '8341.T',
  },
  {
    value: '8341.TW',
    label: '8341.TW,  Sunny Friend Environmental Technology Co., Ltd.',
    name: 'Sunny Friend Environmental Technology Co., Ltd.',
    symbol: '8341.TW',
  },
  {
    value: '8343.T',
    label: '8343.T,  The Akita Bank, Ltd.',
    name: 'The Akita Bank, Ltd.',
    symbol: '8343.T',
  },
  {
    value: '8344.T',
    label: '8344.T,  The Yamagata Bank, Ltd.',
    name: 'The Yamagata Bank, Ltd.',
    symbol: '8344.T',
  },
  {
    value: '8345.T',
    label: '8345.T,  The Bank of Iwate, Ltd.',
    name: 'The Bank of Iwate, Ltd.',
    symbol: '8345.T',
  },
  {
    value: '8346.T',
    label: '8346.T,  The Toho Bank, Ltd.',
    name: 'The Toho Bank, Ltd.',
    symbol: '8346.T',
  },
  {
    value: '8349.TWO',
    label: '8349.TWO,  QST International Corp.',
    name: 'QST International Corp.',
    symbol: '8349.TWO',
  },
  {
    value: '8354.T',
    label: '8354.T,  Fukuoka Financial Group, Inc.',
    name: 'Fukuoka Financial Group, Inc.',
    symbol: '8354.T',
  },
  {
    value: '8358.T',
    label: '8358.T,  Suruga Bank Ltd.',
    name: 'Suruga Bank Ltd.',
    symbol: '8358.T',
  },
  {
    value: '8358.TWO',
    label: '8358.TWO,  Co-Tech Development Corporation',
    name: 'Co-Tech Development Corporation',
    symbol: '8358.TWO',
  },
  {
    value: '8359.T',
    label: '8359.T,  The Hachijuni Bank, Ltd.',
    name: 'The Hachijuni Bank, Ltd.',
    symbol: '8359.T',
  },
  {
    value: '8360.T',
    label: '8360.T,  The Yamanashi Chuo Bank, Ltd.',
    name: 'The Yamanashi Chuo Bank, Ltd.',
    symbol: '8360.T',
  },
  {
    value: '8361.T',
    label: '8361.T,  The Ogaki Kyoritsu Bank, Ltd.',
    name: 'The Ogaki Kyoritsu Bank, Ltd.',
    symbol: '8361.T',
  },
  {
    value: '8362.T',
    label: '8362.T,  The Fukui Bank, Ltd.',
    name: 'The Fukui Bank, Ltd.',
    symbol: '8362.T',
  },
  {
    value: '8366.T',
    label: '8366.T,  The Shiga Bank, Ltd.',
    name: 'The Shiga Bank, Ltd.',
    symbol: '8366.T',
  },
  {
    value: '8367.T',
    label: '8367.T,  The Nanto Bank, Ltd.',
    name: 'The Nanto Bank, Ltd.',
    symbol: '8367.T',
  },
  {
    value: '8368.T',
    label: '8368.T,  The Hyakugo Bank, Ltd.',
    name: 'The Hyakugo Bank, Ltd.',
    symbol: '8368.T',
  },
  {
    value: '8369.T',
    label: '8369.T,  The Bank of Kyoto, Ltd.',
    name: 'The Bank of Kyoto, Ltd.',
    symbol: '8369.T',
  },
  {
    value: '8370.T',
    label: '8370.T,  The Kiyo Bank, Ltd.',
    name: 'The Kiyo Bank, Ltd.',
    symbol: '8370.T',
  },
  {
    value: '8377.T',
    label: '8377.T,  Hokuhoku Financial Group, Inc.',
    name: 'Hokuhoku Financial Group, Inc.',
    symbol: '8377.T',
  },
  {
    value: '8381.T',
    label: '8381.T,  The San-in Godo Bank, Ltd.',
    name: 'The San-in Godo Bank, Ltd.',
    symbol: '8381.T',
  },
  {
    value: '8383.TWO',
    label: '8383.TWO,  Chen Full International Co., Ltd.',
    name: 'Chen Full International Co., Ltd.',
    symbol: '8383.TWO',
  },
  {
    value: '8386.T',
    label: '8386.T,  The Hyakujushi Bank, Ltd.',
    name: 'The Hyakujushi Bank, Ltd.',
    symbol: '8386.T',
  },
  {
    value: '8387.T',
    label: '8387.T,  The Shikoku Bank, Ltd.',
    name: 'The Shikoku Bank, Ltd.',
    symbol: '8387.T',
  },
  {
    value: '8388.T',
    label: '8388.T,  The Awa Bank, Ltd.',
    name: 'The Awa Bank, Ltd.',
    symbol: '8388.T',
  },
  {
    value: '8392.T',
    label: '8392.T,  The Oita Bank, Ltd.',
    name: 'The Oita Bank, Ltd.',
    symbol: '8392.T',
  },
  {
    value: '8393.T',
    label: '8393.T,  The Miyazaki Bank, Ltd.',
    name: 'The Miyazaki Bank, Ltd.',
    symbol: '8393.T',
  },
  {
    value: '8395.T',
    label: '8395.T,  The Bank of Saga Ltd.',
    name: 'The Bank of Saga Ltd.',
    symbol: '8395.T',
  },
  {
    value: '8399.T',
    label: '8399.T,  Bank of The Ryukyus, Limited',
    name: 'Bank of The Ryukyus, Limited',
    symbol: '8399.T',
  },
  {
    value: '8404.TW',
    label: '8404.TW,  Paiho Shih Holdings Corporation',
    name: 'Paiho Shih Holdings Corporation',
    symbol: '8404.TW',
  },
  {
    value: '8410.T',
    label: '8410.T,  Seven Bank, Ltd.',
    name: 'Seven Bank, Ltd.',
    symbol: '8410.T',
  },
  {
    value: '8411.T',
    label: '8411.T,  Mizuho Financial Group, Inc.',
    name: 'Mizuho Financial Group, Inc.',
    symbol: '8411.T',
  },
  {
    value: '8415.TWO',
    label: '8415.TWO,  Brighton-Best International (Taiwan) Inc.',
    name: 'Brighton-Best International (Taiwan) Inc.',
    symbol: '8415.TWO',
  },
  {
    value: '8418.T',
    label: '8418.T,  Yamaguchi Financial Group, Inc.',
    name: 'Yamaguchi Financial Group, Inc.',
    symbol: '8418.T',
  },
  {
    value: '8422.TW',
    label: '8422.TW,  Cleanaway Company Limited',
    name: 'Cleanaway Company Limited',
    symbol: '8422.TW',
  },
  {
    value: '8424.T',
    label: '8424.T,  Fuyo General Lease Co., Ltd.',
    name: 'Fuyo General Lease Co., Ltd.',
    symbol: '8424.T',
  },
  {
    value: '8425.T',
    label: '8425.T,  Mizuho Leasing Company, Limited',
    name: 'Mizuho Leasing Company, Limited',
    symbol: '8425.T',
  },
  {
    value: '8436.TWO',
    label: '8436.TWO,  TCI Co., Ltd.',
    name: 'TCI Co., Ltd.',
    symbol: '8436.TWO',
  },
  {
    value: '8439.T',
    label: '8439.T,  Tokyo Century Corporation',
    name: 'Tokyo Century Corporation',
    symbol: '8439.T',
  },
  {
    value: '8454.TW',
    label: '8454.TW,  momo.com Inc.',
    name: 'momo.com Inc.',
    symbol: '8454.TW',
  },
  {
    value: '8462.TW',
    label: '8462.TW,  Power Wind Health Industry Incorporated',
    name: 'Power Wind Health Industry Incorporated',
    symbol: '8462.TW',
  },
  {
    value: '8464.TW',
    label: '8464.TW,  Nien Made Enterprise Co., LTD.',
    name: 'Nien Made Enterprise Co., LTD.',
    symbol: '8464.TW',
  },
  {
    value: '8473.T',
    label: '8473.T,  SBI Holdings, Inc.',
    name: 'SBI Holdings, Inc.',
    symbol: '8473.T',
  },
  {
    value: '8473.TW',
    label: '8473.TW,  Forest Water Environmental Engineering Co., Ltd.',
    name: 'Forest Water Environmental Engineering Co., Ltd.',
    symbol: '8473.TW',
  },
  {
    value: '8499.TW',
    label: '8499.TW,  Top Bright Holding Co., Ltd.',
    name: 'Top Bright Holding Co., Ltd.',
    symbol: '8499.TW',
  },
  {
    value: '8508.T',
    label: '8508.T,  J Trust Co., Ltd.',
    name: 'J Trust Co., Ltd.',
    symbol: '8508.T',
  },
  {
    value: '8511.T',
    label: '8511.T,  Japan Securities Finance Co., Ltd.',
    name: 'Japan Securities Finance Co., Ltd.',
    symbol: '8511.T',
  },
  {
    value: '8515.T',
    label: '8515.T,  Aiful Corporation',
    name: 'Aiful Corporation',
    symbol: '8515.T',
  },
  {
    value: '8522.T',
    label: '8522.T,  The Bank of Nagoya, Ltd.',
    name: 'The Bank of Nagoya, Ltd.',
    symbol: '8522.T',
  },
  {
    value: '8524.T',
    label: '8524.T,  North Pacific Bank,Ltd.',
    name: 'North Pacific Bank,Ltd.',
    symbol: '8524.T',
  },
  {
    value: '8541.T',
    label: '8541.T,  The Ehime Bank, Ltd.',
    name: 'The Ehime Bank, Ltd.',
    symbol: '8541.T',
  },
  {
    value: '8544.T',
    label: '8544.T,  The Keiyo Bank, Ltd.',
    name: 'The Keiyo Bank, Ltd.',
    symbol: '8544.T',
  },
  {
    value: '8550.T',
    label: '8550.T,  The Tochigi Bank, Ltd.',
    name: 'The Tochigi Bank, Ltd.',
    symbol: '8550.T',
  },
  {
    value: '8558.T',
    label: '8558.T,  The Towa Bank, Ltd.',
    name: 'The Towa Bank, Ltd.',
    symbol: '8558.T',
  },
  {
    value: '8566.T',
    label: '8566.T,  Ricoh Leasing Company, Ltd.',
    name: 'Ricoh Leasing Company, Ltd.',
    symbol: '8566.T',
  },
  {
    value: '8570.T',
    label: '8570.T,  AEON Financial Service Co., Ltd.',
    name: 'AEON Financial Service Co., Ltd.',
    symbol: '8570.T',
  },
  {
    value: '8572.T',
    label: '8572.T,  Acom Co., Ltd.',
    name: 'Acom Co., Ltd.',
    symbol: '8572.T',
  },
  {
    value: '8584.T',
    label: '8584.T,  JACCS Co., Ltd.',
    name: 'JACCS Co., Ltd.',
    symbol: '8584.T',
  },
  {
    value: '8585.T',
    label: '8585.T,  Orient Corporation',
    name: 'Orient Corporation',
    symbol: '8585.T',
  },
  {
    value: '8591.T',
    label: '8591.T,  ORIX Corporation',
    name: 'ORIX Corporation',
    symbol: '8591.T',
  },
  {
    value: '8593.T',
    label: '8593.T,  Mitsubishi HC Capital Inc.',
    name: 'Mitsubishi HC Capital Inc.',
    symbol: '8593.T',
  },
  {
    value: '8595.T',
    label: '8595.T,  JAFCO Group Co., Ltd.',
    name: 'JAFCO Group Co., Ltd.',
    symbol: '8595.T',
  },
  {
    value: '8600.T',
    label: '8600.T,  TOMONY Holdings, Inc.',
    name: 'TOMONY Holdings, Inc.',
    symbol: '8600.T',
  },
  {
    value: '8601.T',
    label: '8601.T,  Daiwa Securities Group Inc.',
    name: 'Daiwa Securities Group Inc.',
    symbol: '8601.T',
  },
  {
    value: '8604.T',
    label: '8604.T,  Nomura Holdings, Inc.',
    name: 'Nomura Holdings, Inc.',
    symbol: '8604.T',
  },
  {
    value: '8609.T',
    label: '8609.T,  Okasan Securities Group Inc.',
    name: 'Okasan Securities Group Inc.',
    symbol: '8609.T',
  },
  {
    value: '8613.T',
    label: '8613.T,  Marusan Securities Co., Ltd.',
    name: 'Marusan Securities Co., Ltd.',
    symbol: '8613.T',
  },
  {
    value: '8616.T',
    label: '8616.T,  Tokai Tokyo Financial Holdings, Inc.',
    name: 'Tokai Tokyo Financial Holdings, Inc.',
    symbol: '8616.T',
  },
  {
    value: '8622.T',
    label: '8622.T,  Mito Securities Co., Ltd.',
    name: 'Mito Securities Co., Ltd.',
    symbol: '8622.T',
  },
  {
    value: '8624.T',
    label: '8624.T,  Ichiyoshi Securities Co., Ltd.',
    name: 'Ichiyoshi Securities Co., Ltd.',
    symbol: '8624.T',
  },
  {
    value: '8628.T',
    label: '8628.T,  Matsui Securities Co., Ltd.',
    name: 'Matsui Securities Co., Ltd.',
    symbol: '8628.T',
  },
  {
    value: '8630.T',
    label: '8630.T,  Sompo Holdings, Inc.',
    name: 'Sompo Holdings, Inc.',
    symbol: '8630.T',
  },
  {
    value: '8697.T',
    label: '8697.T,  Japan Exchange Group, Inc.',
    name: 'Japan Exchange Group, Inc.',
    symbol: '8697.T',
  },
  {
    value: '8698.T',
    label: '8698.T,  Monex Group, Inc.',
    name: 'Monex Group, Inc.',
    symbol: '8698.T',
  },
  {
    value: '8699.T',
    label: '8699.T,  HS Holdings Co., Ltd.',
    name: 'HS Holdings Co., Ltd.',
    symbol: '8699.T',
  },
  {
    value: '8706.T',
    label: '8706.T,  Kyokuto Securities Co., Ltd.',
    name: 'Kyokuto Securities Co., Ltd.',
    symbol: '8706.T',
  },
  {
    value: '8707.T',
    label: '8707.T,  IwaiCosmo Holdings, Inc.',
    name: 'IwaiCosmo Holdings, Inc.',
    symbol: '8707.T',
  },
  {
    value: '8708.T',
    label: '8708.T,  Aizawa Securities Group Co., Ltd.',
    name: 'Aizawa Securities Group Co., Ltd.',
    symbol: '8708.T',
  },
  {
    value: '8713.T',
    label: '8713.T,  FIDEA Holdings Co. Ltd.',
    name: 'FIDEA Holdings Co. Ltd.',
    symbol: '8713.T',
  },
  {
    value: '8714.T',
    label: '8714.T,  Senshu Ikeda Holdings, Inc.',
    name: 'Senshu Ikeda Holdings, Inc.',
    symbol: '8714.T',
  },
  {
    value: '8715.T',
    label: '8715.T,  Anicom Holdings, Inc.',
    name: 'Anicom Holdings, Inc.',
    symbol: '8715.T',
  },
  {
    value: '8725.T',
    label: '8725.T,  MS&AD Insurance Group Holdings, Inc.',
    name: 'MS&AD Insurance Group Holdings, Inc.',
    symbol: '8725.T',
  },
  {
    value: '8739.T',
    label: '8739.T,  SPARX Group Co., Ltd.',
    name: 'SPARX Group Co., Ltd.',
    symbol: '8739.T',
  },
  {
    value: '2235.HK',
    label: '2235.HK,  MicroTech Medical (Hangzhou) Co., Ltd.',
    name: 'MicroTech Medical (Hangzhou) Co., Ltd.',
    symbol: '2235.HK',
  },
  {
    value: '8750.T',
    label: '8750.T,  Dai-ichi Life Holdings, Inc.',
    name: 'Dai-ichi Life Holdings, Inc.',
    symbol: '8750.T',
  },
  {
    value: '8766.T',
    label: '8766.T,  Tokio Marine Holdings, Inc.',
    name: 'Tokio Marine Holdings, Inc.',
    symbol: '8766.T',
  },
  {
    value: '8771.T',
    label: '8771.T,  eGuarantee, Inc.',
    name: 'eGuarantee, Inc.',
    symbol: '8771.T',
  },
  {
    value: '8793.T',
    label: '8793.T,  NEC Capital Solutions Limited',
    name: 'NEC Capital Solutions Limited',
    symbol: '8793.T',
  },
  {
    value: '8795.T',
    label: '8795.T,  T&D Holdings, Inc.',
    name: 'T&D Holdings, Inc.',
    symbol: '8795.T',
  },
  {
    value: '8801.T',
    label: '8801.T,  Mitsui Fudosan Co., Ltd.',
    name: 'Mitsui Fudosan Co., Ltd.',
    symbol: '8801.T',
  },
  {
    value: '8802.T',
    label: '8802.T,  Mitsubishi Estate Co., Ltd.',
    name: 'Mitsubishi Estate Co., Ltd.',
    symbol: '8802.T',
  },
  {
    value: '8803.T',
    label: '8803.T,  Heiwa Real Estate Co., Ltd.',
    name: 'Heiwa Real Estate Co., Ltd.',
    symbol: '8803.T',
  },
  {
    value: '8804.T',
    label: '8804.T,  Tokyo Tatemono Co., Ltd.',
    name: 'Tokyo Tatemono Co., Ltd.',
    symbol: '8804.T',
  },
  {
    value: '8818.T',
    label: '8818.T,  Keihanshin Building Co., Ltd.',
    name: 'Keihanshin Building Co., Ltd.',
    symbol: '8818.T',
  },
  {
    value: '8830.T',
    label: '8830.T,  Sumitomo Realty & Development Co., Ltd.',
    name: 'Sumitomo Realty & Development Co., Ltd.',
    symbol: '8830.T',
  },
  {
    value: '8841.T',
    label: '8841.T,  TOC Co., Ltd.',
    name: 'TOC Co., Ltd.',
    symbol: '8841.T',
  },
  {
    value: '8844.T',
    label: '8844.T,  Cosmos Initia Co., Ltd.',
    name: 'Cosmos Initia Co., Ltd.',
    symbol: '8844.T',
  },
  {
    value: '8848.T',
    label: '8848.T,  Leopalace21 Corporation',
    name: 'Leopalace21 Corporation',
    symbol: '8848.T',
  },
  {
    value: '8850.T',
    label: '8850.T,  Starts Corporation Inc.',
    name: 'Starts Corporation Inc.',
    symbol: '8850.T',
  },
  {
    value: '8860.T',
    label: '8860.T,  Fuji Corporation Limited',
    name: 'Fuji Corporation Limited',
    symbol: '8860.T',
  },
  {
    value: '8864.T',
    label: '8864.T,  Airport Facilities Co., Ltd.',
    name: 'Airport Facilities Co., Ltd.',
    symbol: '8864.T',
  },
  {
    value: '8871.T',
    label: '8871.T,  GOLDCREST Co.,Ltd.',
    name: 'GOLDCREST Co.,Ltd.',
    symbol: '8871.T',
  },
  {
    value: '8876.T',
    label: '8876.T,  Relo Group, Inc.',
    name: 'Relo Group, Inc.',
    symbol: '8876.T',
  },
  {
    value: '8877.T',
    label: '8877.T,  Eslead Corporation',
    name: 'Eslead Corporation',
    symbol: '8877.T',
  },
  {
    value: '8881.T',
    label: '8881.T,  NISSHIN GROUP HOLDINGS Company, Limited',
    name: 'NISSHIN GROUP HOLDINGS Company, Limited',
    symbol: '8881.T',
  },
  {
    value: '8890.T',
    label: '8890.T,  Raysum Co., Ltd.',
    name: 'Raysum Co., Ltd.',
    symbol: '8890.T',
  },
  {
    value: '8892.T',
    label: '8892.T,  ES-CON JAPAN Ltd.',
    name: 'ES-CON JAPAN Ltd.',
    symbol: '8892.T',
  },
  {
    value: '8897.T',
    label: '8897.T,  MIRARTH HOLDINGS,Inc.',
    name: 'MIRARTH HOLDINGS,Inc.',
    symbol: '8897.T',
  },
  {
    value: '8905.T',
    label: '8905.T,  AEON Mall Co., Ltd.',
    name: 'AEON Mall Co., Ltd.',
    symbol: '8905.T',
  },
  {
    value: '8909.T',
    label: '8909.T,  Shinoken Group Co., Ltd.',
    name: 'Shinoken Group Co., Ltd.',
    symbol: '8909.T',
  },
  {
    value: '8914.T',
    label: '8914.T,  Arealink Co., Ltd.',
    name: 'Arealink Co., Ltd.',
    symbol: '8914.T',
  },
  {
    value: '8917.T',
    label: '8917.T,  First Juken Co., Ltd.',
    name: 'First Juken Co., Ltd.',
    symbol: '8917.T',
  },
  {
    value: '8919.T',
    label: '8919.T,  KATITAS CO., Ltd.',
    name: 'KATITAS CO., Ltd.',
    symbol: '8919.T',
  },
  {
    value: '8920.T',
    label: '8920.T,  Tosho Co., Ltd.',
    name: 'Tosho Co., Ltd.',
    symbol: '8920.T',
  },
  {
    value: '8923.T',
    label: '8923.T,  Tosei Corporation',
    name: 'Tosei Corporation',
    symbol: '8923.T',
  },
  {
    value: '8924.TWO',
    label: '8924.TWO,  O-TA Precision Industry Co., Ltd.',
    name: 'O-TA Precision Industry Co., Ltd.',
    symbol: '8924.TWO',
  },
  {
    value: '8926.TW',
    label: '8926.TW,  Taiwan Cogeneration Corporation',
    name: 'Taiwan Cogeneration Corporation',
    symbol: '8926.TW',
  },
  {
    value: '8929.T',
    label: '8929.T,  Aoyama Zaisan Networks Company,Limited',
    name: 'Aoyama Zaisan Networks Company,Limited',
    symbol: '8929.T',
  },
  {
    value: '8934.T',
    label: '8934.T,  Sun Frontier Fudousan Co., Ltd.',
    name: 'Sun Frontier Fudousan Co., Ltd.',
    symbol: '8934.T',
  },
  {
    value: '8935.T',
    label: '8935.T,  FJ Next Holdings Co., Ltd.',
    name: 'FJ Next Holdings Co., Ltd.',
    symbol: '8935.T',
  },
  {
    value: '8936.TWO',
    label: '8936.TWO,  Kuo Toong International Co., Ltd.',
    name: 'Kuo Toong International Co., Ltd.',
    symbol: '8936.TWO',
  },
  {
    value: '8938.TWO',
    label: '8938.TWO,  Advanced International Multitech Co., Ltd.',
    name: 'Advanced International Multitech Co., Ltd.',
    symbol: '8938.TWO',
  },
  {
    value: '8942.TWO',
    label: '8942.TWO,  Xxentria Technology Materials Co., Ltd.',
    name: 'Xxentria Technology Materials Co., Ltd.',
    symbol: '8942.TWO',
  },
  {
    value: '8951.T',
    label: '8951.T,  Nippon Building Fund Incorporation',
    name: 'Nippon Building Fund Incorporation',
    symbol: '8951.T',
  },
  {
    value: '8952.T',
    label: '8952.T,  Japan Real Estate Investment Corporation',
    name: 'Japan Real Estate Investment Corporation',
    symbol: '8952.T',
  },
  {
    value: '8953.T',
    label: '8953.T,  Japan Metropolitan Fund Investment Corporation',
    name: 'Japan Metropolitan Fund Investment Corporation',
    symbol: '8953.T',
  },
  {
    value: '8954.T',
    label: '8954.T,  ORIX JREIT Inc.',
    name: 'ORIX JREIT Inc.',
    symbol: '8954.T',
  },
  {
    value: '8955.T',
    label: '8955.T,  Japan Prime Realty Investment Corporation',
    name: 'Japan Prime Realty Investment Corporation',
    symbol: '8955.T',
  },
  {
    value: '8956.T',
    label: '8956.T,  NTT UD REIT Investment Corporation',
    name: 'NTT UD REIT Investment Corporation',
    symbol: '8956.T',
  },
  {
    value: '8957.T',
    label: '8957.T,  Tokyu REIT, Inc.',
    name: 'Tokyu REIT, Inc.',
    symbol: '8957.T',
  },
  {
    value: '8958.T',
    label: '8958.T,  Global One Real Estate Investment Corp.',
    name: 'Global One Real Estate Investment Corp.',
    symbol: '8958.T',
  },
  {
    value: '8960.T',
    label: '8960.T,  United Urban Investment Corporation',
    name: 'United Urban Investment Corporation',
    symbol: '8960.T',
  },
  {
    value: '8961.T',
    label: '8961.T,  MORI TRUST Sogo Reit, Inc.',
    name: 'MORI TRUST Sogo Reit, Inc.',
    symbol: '8961.T',
  },
  {
    value: '8963.T',
    label: '8963.T,  Invincible Investment Corporation',
    name: 'Invincible Investment Corporation',
    symbol: '8963.T',
  },
  {
    value: '8964.T',
    label: '8964.T,  Frontier Real Estate Investment Corporation',
    name: 'Frontier Real Estate Investment Corporation',
    symbol: '8964.T',
  },
  {
    value: '8966.T',
    label: '8966.T,  Heiwa Real Estate REIT, Inc.',
    name: 'Heiwa Real Estate REIT, Inc.',
    symbol: '8966.T',
  },
  {
    value: '8967.T',
    label: '8967.T,  Japan Logistics Fund, Inc.',
    name: 'Japan Logistics Fund, Inc.',
    symbol: '8967.T',
  },
  {
    value: '8968.T',
    label: '8968.T,  Fukuoka REIT Corporation',
    name: 'Fukuoka REIT Corporation',
    symbol: '8968.T',
  },
  {
    value: '8972.T',
    label: '8972.T,  Kenedix Office Investment Corporation',
    name: 'Kenedix Office Investment Corporation',
    symbol: '8972.T',
  },
  {
    value: '8975.T',
    label: '8975.T,  Ichigo Office REIT Investment Corporation',
    name: 'Ichigo Office REIT Investment Corporation',
    symbol: '8975.T',
  },
  {
    value: '8976.T',
    label: '8976.T,  Daiwa Office Investment Corporation',
    name: 'Daiwa Office Investment Corporation',
    symbol: '8976.T',
  },
  {
    value: '8977.T',
    label: '8977.T,  Hankyu Hanshin REIT, Inc.',
    name: 'Hankyu Hanshin REIT, Inc.',
    symbol: '8977.T',
  },
  {
    value: '8979.T',
    label: '8979.T,  Starts Proceed Investment Corporation',
    name: 'Starts Proceed Investment Corporation',
    symbol: '8979.T',
  },
  {
    value: '8984.T',
    label: '8984.T,  Daiwa House REIT Investment Corporation',
    name: 'Daiwa House REIT Investment Corporation',
    symbol: '8984.T',
  },
  {
    value: '8985.T',
    label: '8985.T,  Japan Hotel REIT Investment Corporation',
    name: 'Japan Hotel REIT Investment Corporation',
    symbol: '8985.T',
  },
  {
    value: '8986.T',
    label: '8986.T,  Daiwa Securities Living Investment Corporation',
    name: 'Daiwa Securities Living Investment Corporation',
    symbol: '8986.T',
  },
  {
    value: '8987.T',
    label: '8987.T,  Japan Excellent, Inc.',
    name: 'Japan Excellent, Inc.',
    symbol: '8987.T',
  },
  {
    value: '8999.T',
    label: '8999.T,  Grandy House Corporation',
    name: 'Grandy House Corporation',
    symbol: '8999.T',
  },
  {
    value: '900140.KS',
    label: '900140.KS,  LVMC Holdings',
    name: 'LVMC Holdings',
    symbol: '900140.KS',
  },
  {
    value: '9001.T',
    label: '9001.T,  Tobu Railway Co., Ltd.',
    name: 'Tobu Railway Co., Ltd.',
    symbol: '9001.T',
  },
  {
    value: '9003.T',
    label: '9003.T,  Sotetsu Holdings, Inc.',
    name: 'Sotetsu Holdings, Inc.',
    symbol: '9003.T',
  },
  {
    value: '9005.T',
    label: '9005.T,  Tokyu Corporation',
    name: 'Tokyu Corporation',
    symbol: '9005.T',
  },
  {
    value: '9006.T',
    label: '9006.T,  Keikyu Corporation',
    name: 'Keikyu Corporation',
    symbol: '9006.T',
  },
  {
    value: '9007.T',
    label: '9007.T,  Odakyu Electric Railway Co., Ltd.',
    name: 'Odakyu Electric Railway Co., Ltd.',
    symbol: '9007.T',
  },
  {
    value: '9008.T',
    label: '9008.T,  Keio Corporation',
    name: 'Keio Corporation',
    symbol: '9008.T',
  },
  {
    value: '900911.SS',
    label:
      '900911.SS,  Shanghai Jinqiao Export Processing Zone Development Co.,Ltd',
    name: 'Shanghai Jinqiao Export Processing Zone Development Co.,Ltd',
    symbol: '900911.SS',
  },
  {
    value: '900925.SS',
    label: '900925.SS,  Shanghai Mechanical & Electrical Industry Co.,Ltd.',
    name: 'Shanghai Mechanical & Electrical Industry Co.,Ltd.',
    symbol: '900925.SS',
  },
  {
    value: '9009.T',
    label: '9009.T,  Keisei Electric Railway Co., Ltd.',
    name: 'Keisei Electric Railway Co., Ltd.',
    symbol: '9009.T',
  },
  {
    value: '9010.T',
    label: '9010.T,  Fuji Kyuko Co., Ltd.',
    name: 'Fuji Kyuko Co., Ltd.',
    symbol: '9010.T',
  },
  {
    value: '9020.T',
    label: '9020.T,  East Japan Railway Company',
    name: 'East Japan Railway Company',
    symbol: '9020.T',
  },
  {
    value: '9021.T',
    label: '9021.T,  West Japan Railway Company',
    name: 'West Japan Railway Company',
    symbol: '9021.T',
  },
  {
    value: '9022.T',
    label: '9022.T,  Central Japan Railway Company',
    name: 'Central Japan Railway Company',
    symbol: '9022.T',
  },
  {
    value: '9024.T',
    label: '9024.T,  Seibu Holdings Inc.',
    name: 'Seibu Holdings Inc.',
    symbol: '9024.T',
  },
  {
    value: '9025.T',
    label: '9025.T,  Konoike Transport Co.,Ltd.',
    name: 'Konoike Transport Co.,Ltd.',
    symbol: '9025.T',
  },
  {
    value: '9031.T',
    label: '9031.T,  Nishi-Nippon Railroad Co., Ltd.',
    name: 'Nishi-Nippon Railroad Co., Ltd.',
    symbol: '9031.T',
  },
  {
    value: '9037.T',
    label: '9037.T,  Hamakyorex Co., Ltd.',
    name: 'Hamakyorex Co., Ltd.',
    symbol: '9037.T',
  },
  {
    value: '9039.T',
    label: '9039.T,  Sakai Moving Service Co.,Ltd.',
    name: 'Sakai Moving Service Co.,Ltd.',
    symbol: '9039.T',
  },
  {
    value: '9041.T',
    label: '9041.T,  Kintetsu Group Holdings Co.,Ltd.',
    name: 'Kintetsu Group Holdings Co.,Ltd.',
    symbol: '9041.T',
  },
  {
    value: '9042.T',
    label: '9042.T,  Hankyu Hanshin Holdings, Inc.',
    name: 'Hankyu Hanshin Holdings, Inc.',
    symbol: '9042.T',
  },
  {
    value: '9044.T',
    label: '9044.T,  Nankai Electric Railway Co., Ltd.',
    name: 'Nankai Electric Railway Co., Ltd.',
    symbol: '9044.T',
  },
  {
    value: '9045.T',
    label: '9045.T,  Keihan Holdings Co., Ltd.',
    name: 'Keihan Holdings Co., Ltd.',
    symbol: '9045.T',
  },
  {
    value: '9048.T',
    label: '9048.T,  Nagoya Railroad Co., Ltd.',
    name: 'Nagoya Railroad Co., Ltd.',
    symbol: '9048.T',
  },
  {
    value: '9052.T',
    label: '9052.T,  Sanyo Electric Railway Co.,Ltd.',
    name: 'Sanyo Electric Railway Co.,Ltd.',
    symbol: '9052.T',
  },
  {
    value: '9058.T',
    label: '9058.T,  Trancom Co., Ltd.',
    name: 'Trancom Co., Ltd.',
    symbol: '9058.T',
  },
  {
    value: '9064.T',
    label: '9064.T,  Yamato Holdings Co., Ltd.',
    name: 'Yamato Holdings Co., Ltd.',
    symbol: '9064.T',
  },
  {
    value: '9065.T',
    label: '9065.T,  Sankyu Inc.',
    name: 'Sankyu Inc.',
    symbol: '9065.T',
  },
  {
    value: '9066.T',
    label: '9066.T,  Nissin Corporation',
    name: 'Nissin Corporation',
    symbol: '9066.T',
  },
  {
    value: '9068.T',
    label: '9068.T,  Maruzen Showa Unyu Co., Ltd.',
    name: 'Maruzen Showa Unyu Co., Ltd.',
    symbol: '9068.T',
  },
  {
    value: '9069.T',
    label: '9069.T,  SENKO Group Holdings Co., Ltd.',
    name: 'SENKO Group Holdings Co., Ltd.',
    symbol: '9069.T',
  },
  {
    value: '9070.T',
    label: '9070.T,  Tonami Holdings Co., Ltd.',
    name: 'Tonami Holdings Co., Ltd.',
    symbol: '9070.T',
  },
  {
    value: '9072.T',
    label: '9072.T,  NIKKON Holdings Co.,Ltd.',
    name: 'NIKKON Holdings Co.,Ltd.',
    symbol: '9072.T',
  },
  {
    value: '9075.T',
    label: '9075.T,  Fukuyama Transporting Co., Ltd.',
    name: 'Fukuyama Transporting Co., Ltd.',
    symbol: '9075.T',
  },
  {
    value: '9076.T',
    label: '9076.T,  Seino Holdings Co., Ltd.',
    name: 'Seino Holdings Co., Ltd.',
    symbol: '9076.T',
  },
  {
    value: '9086.T',
    label: '9086.T,  Hitachi Transport System, Ltd.',
    name: 'Hitachi Transport System, Ltd.',
    symbol: '9086.T',
  },
  {
    value: '9090.T',
    label: '9090.T,  AZ-COM MARUWA Holdings Inc.',
    name: 'AZ-COM MARUWA Holdings Inc.',
    symbol: '9090.T',
  },
  {
    value: '9099.T',
    label: '9099.T,  Chilled & Frozen Logistics Holdings Co., Ltd.',
    name: 'Chilled & Frozen Logistics Holdings Co., Ltd.',
    symbol: '9099.T',
  },
  {
    value: '9101.T',
    label: '9101.T,  Nippon Yusen Kabushiki Kaisha',
    name: 'Nippon Yusen Kabushiki Kaisha',
    symbol: '9101.T',
  },
  {
    value: '9104.T',
    label: '9104.T,  Mitsui O.S.K. Lines, Ltd.',
    name: 'Mitsui O.S.K. Lines, Ltd.',
    symbol: '9104.T',
  },
  {
    value: '9107.T',
    label: '9107.T,  Kawasaki Kisen Kaisha, Ltd.',
    name: 'Kawasaki Kisen Kaisha, Ltd.',
    symbol: '9107.T',
  },
  {
    value: '9110.T',
    label: '9110.T,  NS United Kaiun Kaisha, Ltd.',
    name: 'NS United Kaiun Kaisha, Ltd.',
    symbol: '9110.T',
  },
  {
    value: '9119.T',
    label: '9119.T,  Iino Kaiun Kaisha, Ltd.',
    name: 'Iino Kaiun Kaisha, Ltd.',
    symbol: '9119.T',
  },
  {
    value: '9142.T',
    label: '9142.T,  Kyushu Railway Company',
    name: 'Kyushu Railway Company',
    symbol: '9142.T',
  },
  {
    value: '9143.T',
    label: '9143.T,  SG Holdings Co.,Ltd.',
    name: 'SG Holdings Co.,Ltd.',
    symbol: '9143.T',
  },
  {
    value: '9201.T',
    label: '9201.T,  Japan Airlines Co., Ltd.',
    name: 'Japan Airlines Co., Ltd.',
    symbol: '9201.T',
  },
  {
    value: '9202.T',
    label: '9202.T,  ANA Holdings Inc.',
    name: 'ANA Holdings Inc.',
    symbol: '9202.T',
  },
  {
    value: '9232.T',
    label: '9232.T,  Pasco Corporation',
    name: 'Pasco Corporation',
    symbol: '9232.T',
  },
  {
    value: '9247.T',
    label: '9247.T,  TRE Holdings Corporation',
    name: 'TRE Holdings Corporation',
    symbol: '9247.T',
  },
  {
    value: '9262.T',
    label: '9262.T,  Silver Life Co., Ltd.',
    name: 'Silver Life Co., Ltd.',
    symbol: '9262.T',
  },
  {
    value: '9267.T',
    label: '9267.T,  Genky DrugStores Co., Ltd.',
    name: 'Genky DrugStores Co., Ltd.',
    symbol: '9267.T',
  },
  {
    value: '9273.T',
    label: '9273.T,  Koa Shoji Holdings Co.,Ltd.',
    name: 'Koa Shoji Holdings Co.,Ltd.',
    symbol: '9273.T',
  },
  {
    value: '9274.T',
    label: '9274.T,  Kpp Group Holdings Co.,Ltd.',
    name: 'Kpp Group Holdings Co.,Ltd.',
    symbol: '9274.T',
  },
  {
    value: '9278.T',
    label: '9278.T,  Bookoff Group Holdings Limited',
    name: 'Bookoff Group Holdings Limited',
    symbol: '9278.T',
  },
  {
    value: '9301.T',
    label: '9301.T,  Mitsubishi Logistics Corporation',
    name: 'Mitsubishi Logistics Corporation',
    symbol: '9301.T',
  },
  {
    value: '9302.T',
    label: '9302.T,  MITSUI-SOKO HOLDINGS Co., Ltd.',
    name: 'MITSUI-SOKO HOLDINGS Co., Ltd.',
    symbol: '9302.T',
  },
  {
    value: '9303.T',
    label: '9303.T,  The Sumitomo Warehouse Co., Ltd.',
    name: 'The Sumitomo Warehouse Co., Ltd.',
    symbol: '9303.T',
  },
  {
    value: '9308.T',
    label: '9308.T,  Inui Global Logistics Co., Ltd.',
    name: 'Inui Global Logistics Co., Ltd.',
    symbol: '9308.T',
  },
  {
    value: '9310.T',
    label: '9310.T,  Japan Transcity Corporation',
    name: 'Japan Transcity Corporation',
    symbol: '9310.T',
  },
  {
    value: '9324.T',
    label: '9324.T,  Yasuda Logistics Corporation',
    name: 'Yasuda Logistics Corporation',
    symbol: '9324.T',
  },
  {
    value: '9364.T',
    label: '9364.T,  Kamigumi Co., Ltd.',
    name: 'Kamigumi Co., Ltd.',
    symbol: '9364.T',
  },
  {
    value: '9401.T',
    label: '9401.T,  TBS Holdings,Inc.',
    name: 'TBS Holdings,Inc.',
    symbol: '9401.T',
  },
  {
    value: '9404.T',
    label: '9404.T,  Nippon Television Holdings, Inc.',
    name: 'Nippon Television Holdings, Inc.',
    symbol: '9404.T',
  },
  {
    value: '9409.T',
    label: '9409.T,  TV Asahi Holdings Corporation',
    name: 'TV Asahi Holdings Corporation',
    symbol: '9409.T',
  },
  {
    value: '9412.T',
    label: '9412.T,  SKY Perfect JSAT Holdings Inc.',
    name: 'SKY Perfect JSAT Holdings Inc.',
    symbol: '9412.T',
  },
  {
    value: '9413.T',
    label: '9413.T,  TV TOKYO Holdings Corporation',
    name: 'TV TOKYO Holdings Corporation',
    symbol: '9413.T',
  },
  {
    value: '9416.T',
    label: '9416.T,  Vision Inc.',
    name: 'Vision Inc.',
    symbol: '9416.T',
  },
  {
    value: '9422.T',
    label: '9422.T,  Conexio Corporation',
    name: 'Conexio Corporation',
    symbol: '9422.T',
  },
  {
    value: '9424.T',
    label: '9424.T,  Japan Communications Inc.',
    name: 'Japan Communications Inc.',
    symbol: '9424.T',
  },
  {
    value: '9432.T',
    label: '9432.T,  Nippon Telegraph and Telephone Corporation',
    name: 'Nippon Telegraph and Telephone Corporation',
    symbol: '9432.T',
  },
  {
    value: '9433.T',
    label: '9433.T,  KDDI Corporation',
    name: 'KDDI Corporation',
    symbol: '9433.T',
  },
  {
    value: '9434.T',
    label: '9434.T,  SoftBank Corp.',
    name: 'SoftBank Corp.',
    symbol: '9434.T',
  },
  {
    value: '9435.T',
    label: '9435.T,  Hikari Tsushin, Inc.',
    name: 'Hikari Tsushin, Inc.',
    symbol: '9435.T',
  },
  {
    value: '9436.T',
    label: '9436.T,  Okinawa Cellular Telephone Company',
    name: 'Okinawa Cellular Telephone Company',
    symbol: '9436.T',
  },
  {
    value: '9438.T',
    label: '9438.T,  MTI Ltd.',
    name: 'MTI Ltd.',
    symbol: '9438.T',
  },
  {
    value: '9449.T',
    label: '9449.T,  GMO internet group, Inc.',
    name: 'GMO internet group, Inc.',
    symbol: '9449.T',
  },
  {
    value: '9450.T',
    label: '9450.T,  Fibergate Inc.',
    name: 'Fibergate Inc.',
    symbol: '9450.T',
  },
  {
    value: '9468.T',
    label: '9468.T,  Kadokawa Corporation',
    name: 'Kadokawa Corporation',
    symbol: '9468.T',
  },
  {
    value: '9470.T',
    label: '9470.T,  Gakken Holdings Co., Ltd.',
    name: 'Gakken Holdings Co., Ltd.',
    symbol: '9470.T',
  },
  {
    value: '9474.T',
    label: '9474.T,  Zenrin Co., Ltd.',
    name: 'Zenrin Co., Ltd.',
    symbol: '9474.T',
  },
  {
    value: '9501.T',
    label: '9501.T,  Tokyo Electric Power Company Holdings, Incorporated',
    name: 'Tokyo Electric Power Company Holdings, Incorporated',
    symbol: '9501.T',
  },
  {
    value: '950210.KS',
    label: '950210.KS,  Prestige BioPharma Limited',
    name: 'Prestige BioPharma Limited',
    symbol: '950210.KS',
  },
  {
    value: '9502.T',
    label: '9502.T,  Chubu Electric Power Company, Incorporated',
    name: 'Chubu Electric Power Company, Incorporated',
    symbol: '9502.T',
  },
  {
    value: '9503.T',
    label: '9503.T,  The Kansai Electric Power Company, Incorporated',
    name: 'The Kansai Electric Power Company, Incorporated',
    symbol: '9503.T',
  },
  {
    value: '9504.T',
    label: '9504.T,  The Chugoku Electric Power Co., Inc.',
    name: 'The Chugoku Electric Power Co., Inc.',
    symbol: '9504.T',
  },
  {
    value: '9505.T',
    label: '9505.T,  Hokuriku Electric Power Company',
    name: 'Hokuriku Electric Power Company',
    symbol: '9505.T',
  },
  {
    value: '9506.T',
    label: '9506.T,  Tohoku Electric Power Company, Incorporated',
    name: 'Tohoku Electric Power Company, Incorporated',
    symbol: '9506.T',
  },
  {
    value: '9507.T',
    label: '9507.T,  Shikoku Electric Power Company, Incorporated',
    name: 'Shikoku Electric Power Company, Incorporated',
    symbol: '9507.T',
  },
  {
    value: '9508.T',
    label: '9508.T,  Kyushu Electric Power Company, Incorporated',
    name: 'Kyushu Electric Power Company, Incorporated',
    symbol: '9508.T',
  },
  {
    value: '9509.T',
    label: '9509.T,  Hokkaido Electric Power Company, Incorporated',
    name: 'Hokkaido Electric Power Company, Incorporated',
    symbol: '9509.T',
  },
  {
    value: '9511.T',
    label: '9511.T,  The Okinawa Electric Power Company, Incorporated',
    name: 'The Okinawa Electric Power Company, Incorporated',
    symbol: '9511.T',
  },
  {
    value: '9512.SR',
    label: '9512.SR,  Riyadh Cement Company',
    name: 'Riyadh Cement Company',
    symbol: '9512.SR',
  },
  {
    value: '9513.T',
    label: '9513.T,  Electric Power Development Co., Ltd.',
    name: 'Electric Power Development Co., Ltd.',
    symbol: '9513.T',
  },
  {
    value: '9514.T',
    label: '9514.T,  EF-ON Inc.',
    name: 'EF-ON Inc.',
    symbol: '9514.T',
  },
  {
    value: '9517.T',
    label: '9517.T,  eREX Co.,Ltd.',
    name: 'eREX Co.,Ltd.',
    symbol: '9517.T',
  },
  {
    value: '9519.T',
    label: '9519.T,  RENOVA, Inc.',
    name: 'RENOVA, Inc.',
    symbol: '9519.T',
  },
  {
    value: '9531.T',
    label: '9531.T,  Tokyo Gas Co.,Ltd.',
    name: 'Tokyo Gas Co.,Ltd.',
    symbol: '9531.T',
  },
  {
    value: '9532.T',
    label: '9532.T,  Osaka Gas Co., Ltd.',
    name: 'Osaka Gas Co., Ltd.',
    symbol: '9532.T',
  },
  {
    value: '9533.T',
    label: '9533.T,  Toho Gas Co., Ltd.',
    name: 'Toho Gas Co., Ltd.',
    symbol: '9533.T',
  },
  {
    value: '9534.T',
    label: '9534.T,  Hokkaido Gas Co., Ltd.',
    name: 'Hokkaido Gas Co., Ltd.',
    symbol: '9534.T',
  },
  {
    value: '9535.T',
    label: '9535.T,  HIROSHIMA GAS Co.,Ltd.',
    name: 'HIROSHIMA GAS Co.,Ltd.',
    symbol: '9535.T',
  },
  {
    value: '9536.T',
    label: '9536.T,  Saibu Gas Holdings Co.,Ltd.',
    name: 'Saibu Gas Holdings Co.,Ltd.',
    symbol: '9536.T',
  },
  {
    value: '9543.T',
    label: '9543.T,  Shizuoka Gas Co., Ltd.',
    name: 'Shizuoka Gas Co., Ltd.',
    symbol: '9543.T',
  },
  {
    value: '9551.T',
    label: '9551.T,  METAWATER Co., Ltd.',
    name: 'METAWATER Co., Ltd.',
    symbol: '9551.T',
  },
  {
    value: '9601.T',
    label: '9601.T,  Shochiku Co., Ltd.',
    name: 'Shochiku Co., Ltd.',
    symbol: '9601.T',
  },
  {
    value: '9602.T',
    label: '9602.T,  Toho Co., Ltd.',
    name: 'Toho Co., Ltd.',
    symbol: '9602.T',
  },
  {
    value: '9603.T',
    label: '9603.T,  H.I.S. Co., Ltd.',
    name: 'H.I.S. Co., Ltd.',
    symbol: '9603.T',
  },
  {
    value: '9605.T',
    label: '9605.T,  Toei Company, Ltd.',
    name: 'Toei Company, Ltd.',
    symbol: '9605.T',
  },
  {
    value: '9613.T',
    label: '9613.T,  NTT DATA Corporation',
    name: 'NTT DATA Corporation',
    symbol: '9613.T',
  },
  {
    value: '9616.T',
    label: '9616.T,  Kyoritsu Maintenance Co., Ltd.',
    name: 'Kyoritsu Maintenance Co., Ltd.',
    symbol: '9616.T',
  },
  {
    value: '9619.T',
    label: '9619.T,  Ichinen Holdings Co., Ltd.',
    name: 'Ichinen Holdings Co., Ltd.',
    symbol: '9619.T',
  },
  {
    value: '9621.T',
    label: '9621.T,  CTI Engineering Co., Ltd.',
    name: 'CTI Engineering Co., Ltd.',
    symbol: '9621.T',
  },
  {
    value: '9622.T',
    label: '9622.T,  Space Co.,Ltd.',
    name: 'Space Co.,Ltd.',
    symbol: '9622.T',
  },
  {
    value: '9627.T',
    label: '9627.T,  Ain Holdings Inc.',
    name: 'Ain Holdings Inc.',
    symbol: '9627.T',
  },
  {
    value: '9629.T',
    label: '9629.T,  PCA Corporation',
    name: 'PCA Corporation',
    symbol: '9629.T',
  },
  {
    value: '9658.T',
    label: '9658.T,  Business Brain Showa-Ota Inc.',
    name: 'Business Brain Showa-Ota Inc.',
    symbol: '9658.T',
  },
  {
    value: '9663.T',
    label: '9663.T,  NAGAWA Co., Ltd.',
    name: 'NAGAWA Co., Ltd.',
    symbol: '9663.T',
  },
  {
    value: '9672.T',
    label: '9672.T,  Tokyotokeiba Co.,Ltd.',
    name: 'Tokyotokeiba Co.,Ltd.',
    symbol: '9672.T',
  },
  {
    value: '9678.T',
    label: '9678.T,  Kanamoto Co.,Ltd.',
    name: 'Kanamoto Co.,Ltd.',
    symbol: '9678.T',
  },
  {
    value: '9682.T',
    label: '9682.T,  DTS Corporation',
    name: 'DTS Corporation',
    symbol: '9682.T',
  },
  {
    value: '9684.T',
    label: '9684.T,  Square Enix Holdings Co., Ltd.',
    name: 'Square Enix Holdings Co., Ltd.',
    symbol: '9684.T',
  },
  {
    value: '9692.T',
    label: '9692.T,  Computer Engineering & Consulting, Ltd.',
    name: 'Computer Engineering & Consulting, Ltd.',
    symbol: '9692.T',
  },
  {
    value: '9697.T',
    label: '9697.T,  Capcom Co., Ltd.',
    name: 'Capcom Co., Ltd.',
    symbol: '9697.T',
  },
  {
    value: '9699.T',
    label: '9699.T,  Nishio Rent All Co., Ltd.',
    name: 'Nishio Rent All Co., Ltd.',
    symbol: '9699.T',
  },
  {
    value: '9706.T',
    label: '9706.T,  Japan Airport Terminal Co., Ltd.',
    name: 'Japan Airport Terminal Co., Ltd.',
    symbol: '9706.T',
  },
  {
    value: '9715.T',
    label: '9715.T,  transcosmos inc.',
    name: 'transcosmos inc.',
    symbol: '9715.T',
  },
  {
    value: '9716.T',
    label: '9716.T,  NOMURA Co., Ltd.',
    name: 'NOMURA Co., Ltd.',
    symbol: '9716.T',
  },
  {
    value: '9719.T',
    label: '9719.T,  SCSK Corporation',
    name: 'SCSK Corporation',
    symbol: '9719.T',
  },
  {
    value: '9722.T',
    label: '9722.T,  Fujita Kanko Inc.',
    name: 'Fujita Kanko Inc.',
    symbol: '9722.T',
  },
  {
    value: '9726.T',
    label: '9726.T,  KNT-CT Holdings Co., Ltd.',
    name: 'KNT-CT Holdings Co., Ltd.',
    symbol: '9726.T',
  },
  {
    value: '9728.T',
    label: '9728.T,  Nippon Kanzai Co., Ltd.',
    name: 'Nippon Kanzai Co., Ltd.',
    symbol: '9728.T',
  },
  {
    value: '9729.T',
    label: '9729.T,  TOKAI Corp.',
    name: 'TOKAI Corp.',
    symbol: '9729.T',
  },
  {
    value: '9735.T',
    label: '9735.T,  SECOM CO., LTD.',
    name: 'SECOM CO., LTD.',
    symbol: '9735.T',
  },
  {
    value: '9740.T',
    label: '9740.T,  Central Security Patrols Co., Ltd.',
    name: 'Central Security Patrols Co., Ltd.',
    symbol: '9740.T',
  },
  {
    value: '9742.T',
    label: '9742.T,  INES Corporation',
    name: 'INES Corporation',
    symbol: '9742.T',
  },
  {
    value: '9743.T',
    label: '9743.T,  Tanseisha Co., Ltd.',
    name: 'Tanseisha Co., Ltd.',
    symbol: '9743.T',
  },
  {
    value: '9744.T',
    label: '9744.T,  Meitec Corporation',
    name: 'Meitec Corporation',
    symbol: '9744.T',
  },
  {
    value: '9746.T',
    label: '9746.T,  TKC Corporation',
    name: 'TKC Corporation',
    symbol: '9746.T',
  },
  {
    value: '9749.T',
    label: '9749.T,  Fuji Soft Incorporated',
    name: 'Fuji Soft Incorporated',
    symbol: '9749.T',
  },
  {
    value: '9755.T',
    label: '9755.T,  OYO Corporation',
    name: 'OYO Corporation',
    symbol: '9755.T',
  },
  {
    value: '9757.T',
    label: '9757.T,  Funai Soken Holdings Incorporated',
    name: 'Funai Soken Holdings Incorporated',
    symbol: '9757.T',
  },
  {
    value: '9759.T',
    label: '9759.T,  NSD Co., Ltd.',
    name: 'NSD Co., Ltd.',
    symbol: '9759.T',
  },
  {
    value: '9766.T',
    label: '9766.T,  Konami Group Corporation',
    name: 'Konami Group Corporation',
    symbol: '9766.T',
  },
  {
    value: '9768.T',
    label: '9768.T,  IDEA Consultants,Inc.',
    name: 'IDEA Consultants,Inc.',
    symbol: '9768.T',
  },
  {
    value: '9783.T',
    label: '9783.T,  Benesse Holdings, Inc.',
    name: 'Benesse Holdings, Inc.',
    symbol: '9783.T',
  },
  {
    value: '9787.T',
    label: '9787.T,  Aeon Delight Co., Ltd.',
    name: 'Aeon Delight Co., Ltd.',
    symbol: '9787.T',
  },
  {
    value: '9788.T',
    label: '9788.T,  Nac Co., Ltd.',
    name: 'Nac Co., Ltd.',
    symbol: '9788.T',
  },
  {
    value: '9790.T',
    label: '9790.T,  Fukui Computer Holdings,Inc.',
    name: 'Fukui Computer Holdings,Inc.',
    symbol: '9790.T',
  },
  {
    value: '9793.T',
    label: '9793.T,  Daiseki Co.,Ltd.',
    name: 'Daiseki Co.,Ltd.',
    symbol: '9793.T',
  },
  {
    value: '9802.TW',
    label: '9802.TW,  Fulgent Sun International (Holding) Co., Ltd.',
    name: 'Fulgent Sun International (Holding) Co., Ltd.',
    symbol: '9802.TW',
  },
  {
    value: '9810.T',
    label: '9810.T,  Nippon Steel Trading Corporation',
    name: 'Nippon Steel Trading Corporation',
    symbol: '9810.T',
  },
  {
    value: '9828.T',
    label: '9828.T,  Genki Sushi Co., Ltd.',
    name: 'Genki Sushi Co., Ltd.',
    symbol: '9828.T',
  },
  {
    value: '9830.T',
    label: '9830.T,  Trusco Nakayama Corporation',
    name: 'Trusco Nakayama Corporation',
    symbol: '9830.T',
  },
  {
    value: '9831.T',
    label: '9831.T,  Yamada Holdings Co., Ltd.',
    name: 'Yamada Holdings Co., Ltd.',
    symbol: '9831.T',
  },
  {
    value: '9832.T',
    label: '9832.T,  Autobacs Seven Co., Ltd.',
    name: 'Autobacs Seven Co., Ltd.',
    symbol: '9832.T',
  },
  {
    value: '9837.T',
    label: '9837.T,  Morito Co., Ltd.',
    name: 'Morito Co., Ltd.',
    symbol: '9837.T',
  },
  {
    value: '9842.T',
    label: '9842.T,  Arclands Corporation',
    name: 'Arclands Corporation',
    symbol: '9842.T',
  },
  {
    value: '9843.T',
    label: '9843.T,  Nitori Holdings Co., Ltd.',
    name: 'Nitori Holdings Co., Ltd.',
    symbol: '9843.T',
  },
  {
    value: '9861.T',
    label: '9861.T,  Yoshinoya Holdings Co., Ltd.',
    name: 'Yoshinoya Holdings Co., Ltd.',
    symbol: '9861.T',
  },
  {
    value: '9869.T',
    label: '9869.T,  Kato Sangyo Co., Ltd.',
    name: 'Kato Sangyo Co., Ltd.',
    symbol: '9869.T',
  },
  {
    value: '9873.T',
    label: '9873.T,  KFC Holdings Japan, Ltd.',
    name: 'KFC Holdings Japan, Ltd.',
    symbol: '9873.T',
  },
  {
    value: '9880.T',
    label: '9880.T,  Innotech Corporation',
    name: 'Innotech Corporation',
    symbol: '9880.T',
  },
  {
    value: '9882.T',
    label: '9882.T,  Yellow Hat Ltd.',
    name: 'Yellow Hat Ltd.',
    symbol: '9882.T',
  },
  {
    value: '9889.T',
    label: '9889.T,  JBCC Holdings Inc.',
    name: 'JBCC Holdings Inc.',
    symbol: '9889.T',
  },
  {
    value: '9896.T',
    label: '9896.T,  JK Holdings Co., Ltd.',
    name: 'JK Holdings Co., Ltd.',
    symbol: '9896.T',
  },
  {
    value: '9900.T',
    label: '9900.T,  Sagami Holdings Corporation',
    name: 'Sagami Holdings Corporation',
    symbol: '9900.T',
  },
  {
    value: '9902.T',
    label: '9902.T,  NICHIDEN Corporation',
    name: 'NICHIDEN Corporation',
    symbol: '9902.T',
  },
  {
    value: '9904.TW',
    label: '9904.TW,  Pou Chen Corporation',
    name: 'Pou Chen Corporation',
    symbol: '9904.TW',
  },
  {
    value: '9905.TW',
    label: '9905.TW,  Great China Metal Ind. Co., Ltd.',
    name: 'Great China Metal Ind. Co., Ltd.',
    symbol: '9905.TW',
  },
  {
    value: '9907.TW',
    label: '9907.TW,  Ton Yi Industrial Corp.',
    name: 'Ton Yi Industrial Corp.',
    symbol: '9907.TW',
  },
  {
    value: '9910.TW',
    label: '9910.TW,  Feng Tay Enterprises Co., Ltd.',
    name: 'Feng Tay Enterprises Co., Ltd.',
    symbol: '9910.TW',
  },
  {
    value: '9911.TW',
    label: '9911.TW,  Taiwan Sakura Corporation',
    name: 'Taiwan Sakura Corporation',
    symbol: '9911.TW',
  },
  {
    value: '9914.TW',
    label: '9914.TW,  Merida Industry Co., Ltd.',
    name: 'Merida Industry Co., Ltd.',
    symbol: '9914.TW',
  },
  {
    value: '9917.TW',
    label: '9917.TW,  Taiwan Secom Co., Ltd.',
    name: 'Taiwan Secom Co., Ltd.',
    symbol: '9917.TW',
  },
  {
    value: '9919.TW',
    label: '9919.TW,  KNH Enterprise Co., Ltd.',
    name: 'KNH Enterprise Co., Ltd.',
    symbol: '9919.TW',
  },
  {
    value: '9921.TW',
    label: '9921.TW,  Giant Manufacturing Co., Ltd.',
    name: 'Giant Manufacturing Co., Ltd.',
    symbol: '9921.TW',
  },
  {
    value: '9924.TW',
    label: '9924.TW,  Taiwan Fu Hsing Industrial Co.,Ltd.',
    name: 'Taiwan Fu Hsing Industrial Co.,Ltd.',
    symbol: '9924.TW',
  },
  {
    value: '9925.TW',
    label: '9925.TW,  Taiwan Shin Kong Security Co., Ltd.',
    name: 'Taiwan Shin Kong Security Co., Ltd.',
    symbol: '9925.TW',
  },
  {
    value: '9927.TW',
    label: '9927.TW,  Thye Ming Industrial Co., Ltd.',
    name: 'Thye Ming Industrial Co., Ltd.',
    symbol: '9927.TW',
  },
  {
    value: '9928.T',
    label: '9928.T,  Miroku Jyoho Service Co., Ltd.',
    name: 'Miroku Jyoho Service Co., Ltd.',
    symbol: '9928.T',
  },
  {
    value: '9930.TW',
    label: '9930.TW,  CHC Resources Corporation',
    name: 'CHC Resources Corporation',
    symbol: '9930.TW',
  },
  {
    value: '9933.TW',
    label: '9933.TW,  CTCI Corporation',
    name: 'CTCI Corporation',
    symbol: '9933.TW',
  },
  {
    value: '9934.T',
    label: '9934.T,  Inaba Denki Sangyo Co.,Ltd.',
    name: 'Inaba Denki Sangyo Co.,Ltd.',
    symbol: '9934.T',
  },
  {
    value: '9934.TW',
    label: '9934.TW,  Globe Union Industrial Corp.',
    name: 'Globe Union Industrial Corp.',
    symbol: '9934.TW',
  },
  {
    value: '9936.T',
    label: '9936.T,  Ohsho Food Service Corp.',
    name: 'Ohsho Food Service Corp.',
    symbol: '9936.T',
  },
  {
    value: '9937.TW',
    label: '9937.TW,  National Petroleum Co., Ltd.',
    name: 'National Petroleum Co., Ltd.',
    symbol: '9937.TW',
  },
  {
    value: '9938.TW',
    label: '9938.TW,  Taiwan Paiho Limited',
    name: 'Taiwan Paiho Limited',
    symbol: '9938.TW',
  },
  {
    value: '9939.TW',
    label: '9939.TW,  Taiwan Hon Chuan Enterprise Co., Ltd.',
    name: 'Taiwan Hon Chuan Enterprise Co., Ltd.',
    symbol: '9939.TW',
  },
  {
    value: '9940.TW',
    label: '9940.TW,  Sinyi Realty Inc.',
    name: 'Sinyi Realty Inc.',
    symbol: '9940.TW',
  },
  {
    value: '9941.TW',
    label: '9941.TW,  Yulon Finance Corporation',
    name: 'Yulon Finance Corporation',
    symbol: '9941.TW',
  },
  {
    value: '9942.TW',
    label: '9942.TW,  NAK Sealing Technologies Corporation',
    name: 'NAK Sealing Technologies Corporation',
    symbol: '9942.TW',
  },
  {
    value: '9943.TW',
    label: '9943.TW,  Holiday Entertainment Co.,Ltd',
    name: 'Holiday Entertainment Co.,Ltd',
    symbol: '9943.TW',
  },
  {
    value: '9945.T',
    label: '9945.T,  PLENUS Co., Ltd.',
    name: 'PLENUS Co., Ltd.',
    symbol: '9945.T',
  },
  {
    value: '9945.TW',
    label: '9945.TW,  Ruentex Development Co.,Ltd.',
    name: 'Ruentex Development Co.,Ltd.',
    symbol: '9945.TW',
  },
  {
    value: '9948.T',
    label: '9948.T,  ARCS Company Limited',
    name: 'ARCS Company Limited',
    symbol: '9948.T',
  },
  {
    value: '9951.TWO',
    label: '9951.TWO,  Macauto Industrial Co., Ltd.',
    name: 'Macauto Industrial Co., Ltd.',
    symbol: '9951.TWO',
  },
  {
    value: '9956.T',
    label: '9956.T,  Valor Holdings Co., Ltd.',
    name: 'Valor Holdings Co., Ltd.',
    symbol: '9956.T',
  },
  {
    value: '9958.TW',
    label: '9958.TW,  Century Iron and Steel Industrial Co.,Ltd.',
    name: 'Century Iron and Steel Industrial Co.,Ltd.',
    symbol: '9958.TW',
  },
  {
    value: '9959.HK',
    label: '9959.HK,  Linklogis Inc.',
    name: 'Linklogis Inc.',
    symbol: '9959.HK',
  },
  {
    value: '9962.T',
    label: '9962.T,  MISUMI Group Inc.',
    name: 'MISUMI Group Inc.',
    symbol: '9962.T',
  },
  {
    value: '9974.T',
    label: '9974.T,  Belc CO., LTD.',
    name: 'Belc CO., LTD.',
    symbol: '9974.T',
  },
  {
    value: '9977.HK',
    label: '9977.HK,  Shandong Fengxiang Co., Ltd',
    name: 'Shandong Fengxiang Co., Ltd',
    symbol: '9977.HK',
  },
  {
    value: '9983.T',
    label: '9983.T,  Fast Retailing Co., Ltd.',
    name: 'Fast Retailing Co., Ltd.',
    symbol: '9983.T',
  },
  {
    value: '9984.T',
    label: '9984.T,  SoftBank Group Corp.',
    name: 'SoftBank Group Corp.',
    symbol: '9984.T',
  },
  {
    value: '9987.T',
    label: '9987.T,  Suzuken Co., Ltd.',
    name: 'Suzuken Co., Ltd.',
    symbol: '9987.T',
  },
  {
    value: '9989.T',
    label: '9989.T,  Sundrug Co.,Ltd.',
    name: 'Sundrug Co.,Ltd.',
    symbol: '9989.T',
  },
  {
    value: '9990.T',
    label: "9990.T,  Sac's Bar Holdings Inc.",
    name: "Sac's Bar Holdings Inc.",
    symbol: '9990.T',
  },
  {
    value: '9991.T',
    label: '9991.T,  Gecoss Corporation',
    name: 'Gecoss Corporation',
    symbol: '9991.T',
  },
  {
    value: '9997.T',
    label: '9997.T,  Belluna Co., Ltd.',
    name: 'Belluna Co., Ltd.',
    symbol: '9997.T',
  },
  {
    value: '9CI.SI',
    label: '9CI.SI,  CapitaLand Investment Limited',
    name: 'CapitaLand Investment Limited',
    symbol: '9CI.SI',
  },
  {
    value: 'A17U.SI',
    label: 'A17U.SI,  CapitaLand Ascendas REIT',
    name: 'CapitaLand Ascendas REIT',
    symbol: 'A17U.SI',
  },
  {
    value: 'A3M.MC',
    label: 'A3M.MC,  Atresmedia Corporación de Medios de Comunicación, S.A.',
    name: 'Atresmedia Corporación de Medios de Comunicación, S.A.',
    symbol: 'A3M.MC',
  },
  {
    value: 'A7RU.SI',
    label: 'A7RU.SI,  Keppel Infrastructure Trust',
    name: 'Keppel Infrastructure Trust',
    symbol: 'A7RU.SI',
  },
  {
    value: 'AADI',
    label: 'AADI,  Aadi Bioscience, Inc.',
    name: 'Aadi Bioscience, Inc.',
    symbol: 'AADI',
  },
  {
    value: 'AAGIY',
    label: 'AAGIY,  AIA Group Limited',
    name: 'AIA Group Limited',
    symbol: 'AAGIY',
  },
  {
    value: 'AAIC',
    label: 'AAIC,  Arlington Asset Investment Corp.',
    name: 'Arlington Asset Investment Corp.',
    symbol: 'AAIC',
  },
  {
    value: 'AAK.ST',
    label: 'AAK.ST,  AAK AB (publ.)',
    name: 'AAK AB (publ.)',
    symbol: 'AAK.ST',
  },
  {
    value: 'AALI.JK',
    label: 'AALI.JK,  PT Astra Agro Lestari Tbk',
    name: 'PT Astra Agro Lestari Tbk',
    symbol: 'AALI.JK',
  },
  {
    value: 'AALR3.SA',
    label: 'AALR3.SA,  Centro de Imagem Diagnósticos S.A.',
    name: 'Centro de Imagem Diagnósticos S.A.',
    symbol: 'AALR3.SA',
  },
  {
    value: 'AATC',
    label: 'AATC,  Autoscope Technologies Corporation',
    name: 'Autoscope Technologies Corporation',
    symbol: 'AATC',
  },
  {
    value: 'ACCON.ST',
    label: 'ACCON.ST,  Acconeer AB (publ)',
    name: 'Acconeer AB (publ)',
    symbol: 'ACCON.ST',
  },
  {
    value: 'ACE.MI',
    label: 'ACE.MI,  ACEA S.p.A.',
    name: 'ACEA S.p.A.',
    symbol: 'ACE.MI',
  },
  {
    value: 'ACE-R.BK',
    label: 'ACE-R.BK,  Absolute Clean Energy Public Company Limited',
    name: 'Absolute Clean Energy Public Company Limited',
    symbol: 'ACE-R.BK',
  },
  {
    value: 'ACES.JK',
    label: 'ACES.JK,  PT Ace Hardware Indonesia Tbk',
    name: 'PT Ace Hardware Indonesia Tbk',
    symbol: 'ACES.JK',
  },
  {
    value: 'ACGLN',
    label: 'ACGLN,  Arch Capital Group Ltd.',
    name: 'Arch Capital Group Ltd.',
    symbol: 'ACGLN',
  },
  {
    value: 'ACG.WA',
    label: 'ACG.WA,  AC Spólka Akcyjna',
    name: 'AC Spólka Akcyjna',
    symbol: 'ACG.WA',
  },
  {
    value: 'ACP.WA',
    label: 'ACP.WA,  Asseco Poland S.A.',
    name: 'Asseco Poland S.A.',
    symbol: 'ACP.WA',
  },
  {
    value: 'ACRHF',
    label: 'ACRHF,  Acreage Holdings, Inc.',
    name: 'Acreage Holdings, Inc.',
    symbol: 'ACRHF',
  },
  {
    value: 'ACR.OL',
    label: 'ACR.OL,  Axactor ASA',
    name: 'Axactor ASA',
    symbol: 'ACR.OL',
  },
  {
    value: 'ACS.MC',
    label: 'ACS.MC,  ACS, Actividades de Construcción y Servicios, S.A.',
    name: 'ACS, Actividades de Construcción y Servicios, S.A.',
    symbol: 'ACS.MC',
  },
  {
    value: 'ACX.MC',
    label: 'ACX.MC,  Acerinox, S.A.',
    name: 'Acerinox, S.A.',
    symbol: 'ACX.MC',
  },
  {
    value: 'ADGR.TA',
    label: 'ADGR.TA,  Adgar Investments and Development Ltd',
    name: 'Adgar Investments and Development Ltd',
    symbol: 'ADGR.TA',
  },
  {
    value: 'ADHI.JK',
    label: 'ADHI.JK,  PT Adhi Karya (Persero) Tbk',
    name: 'PT Adhi Karya (Persero) Tbk',
    symbol: 'ADHI.JK',
  },
  {
    value: 'ADMCM.HE',
    label: 'ADMCM.HE,  Admicom Oyj',
    name: 'Admicom Oyj',
    symbol: 'ADMCM.HE',
  },
  {
    value: 'ADRO.JK',
    label: 'ADRO.JK,  PT Adaro Energy Indonesia Tbk',
    name: 'PT Adaro Energy Indonesia Tbk',
    symbol: 'ADRO.JK',
  },
  {
    value: 'ADVANC.BK',
    label: 'ADVANC.BK,  Advanced Info Service Public Company Limited',
    name: 'Advanced Info Service Public Company Limited',
    symbol: 'ADVANC.BK',
  },
  {
    value: 'ADVANC-R.BK',
    label: 'ADVANC-R.BK,  Advanced Info Service Public Company Limited',
    name: 'Advanced Info Service Public Company Limited',
    symbol: 'ADVANC-R.BK',
  },
  {
    value: 'AEDAS.MC',
    label: 'AEDAS.MC,  Aedas Homes, S.A.',
    name: 'Aedas Homes, S.A.',
    symbol: 'AEDAS.MC',
  },
  {
    value: 'AEFES.IS',
    label: 'AEFES.IS,  Anadolu Efes Biracilik ve Malt Sanayii Anonim Sirketi',
    name: 'Anadolu Efes Biracilik ve Malt Sanayii Anonim Sirketi',
    symbol: 'AEFES.IS',
  },
  {
    value: 'AEF.MI',
    label: 'AEF.MI,  Aeffe S.p.A.',
    name: 'Aeffe S.p.A.',
    symbol: 'AEF.MI',
  },
  {
    value: 'AEGIR.ST',
    label: 'AEGIR.ST,  AegirBio AB (publ)',
    name: 'AegirBio AB (publ)',
    symbol: 'AEGIR.ST',
  },
  {
    value: 'AEHL',
    label: 'AEHL,  Antelope Enterprise Holdings Limited',
    name: 'Antelope Enterprise Holdings Limited',
    symbol: 'AEHL',
  },
  {
    value: 'AENA.MC',
    label: 'AENA.MC,  Aena S.M.E., S.A.',
    name: 'Aena S.M.E., S.A.',
    symbol: 'AENA.MC',
  },
  {
    value: 'AERI3.SA',
    label:
      'AERI3.SA,  Aeris Indústria e Comércio de Equipamentos para Geração de Energia S.A.',
    name: 'Aeris Indústria e Comércio de Equipamentos para Geração de Energia S.A.',
    symbol: 'AERI3.SA',
  },
  {
    value: 'AESANDES.SN',
    label: 'AESANDES.SN,  AES Andes S.A.',
    name: 'AES Andes S.A.',
    symbol: 'AESANDES.SN',
  },
  {
    value: 'AESB3.SA',
    label: 'AESB3.SA,  AES Brasil Energia S.A.',
    name: 'AES Brasil Energia S.A.',
    symbol: 'AESB3.SA',
  },
  {
    value: 'AFE.JO',
    label: 'AFE.JO,  AECI Ltd',
    name: 'AECI Ltd',
    symbol: 'AFE.JO',
  },
  {
    value: 'AFLYY',
    label: 'AFLYY,  Air France-KLM SA',
    name: 'Air France-KLM SA',
    symbol: 'AFLYY',
  },
  {
    value: 'AFPR.TA',
    label: 'AFPR.TA,  AFI Properties Ltd',
    name: 'AFI Properties Ltd',
    symbol: 'AFPR.TA',
  },
  {
    value: 'AFRY.ST',
    label: 'AFRY.ST,  Afry AB',
    name: 'Afry AB',
    symbol: 'AFRY.ST',
  },
  {
    value: 'AGAS.OL',
    label: 'AGAS.OL,  Avance Gas Holding Ltd',
    name: 'Avance Gas Holding Ltd',
    symbol: 'AGAS.OL',
  },
  {
    value: 'AGHOL.IS',
    label: 'AGHOL.IS,  AG Anadolu Grubu Holding A.S.',
    name: 'AG Anadolu Grubu Holding A.S.',
    symbol: 'AGHOL.IS',
  },
  {
    value: 'AGL.MI',
    label: 'AGL.MI,  Autogrill S.p.A.',
    name: 'Autogrill S.p.A.',
    symbol: 'AGL.MI',
  },
  {
    value: 'AGO.WA',
    label: 'AGO.WA,  Agora S.A.',
    name: 'Agora S.A.',
    symbol: 'AGO.WA',
  },
  {
    value: 'AGRIP',
    label: 'AGRIP,  AgriBank, FCB',
    name: 'AgriBank, FCB',
    symbol: 'AGRIP',
  },
  {
    value: 'AGRO3.SA',
    label:
      'AGRO3.SA,  BrasilAgro - Companhia Brasileira de Propriedades Agrícolas',
    name: 'BrasilAgro - Companhia Brasileira de Propriedades Agrícolas',
    symbol: 'AGRO3.SA',
  },
  {
    value: 'AGRO.JK',
    label: 'AGRO.JK,  PT Bank Raya Indonesia Tbk',
    name: 'PT Bank Raya Indonesia Tbk',
    symbol: 'AGRO.JK',
  },
  {
    value: 'AGUA.MX',
    label: 'AGUA.MX,  Grupo Rotoplas S.A.B. de C.V.',
    name: 'Grupo Rotoplas S.A.B. de C.V.',
    symbol: 'AGUA.MX',
  },
  {
    value: 'GRF.MC',
    label: 'GRF.MC,  Grifols, S.A.',
    name: 'Grifols, S.A.',
    symbol: 'GRF.MC',
  },
  {
    value: 'OTCM',
    label: 'OTCM,  OTC Markets Group Inc.',
    name: 'OTC Markets Group Inc.',
    symbol: 'OTCM',
  },
  {
    value: 'PIPE.TO',
    label: 'PIPE.TO,  Pipestone Energy Corp.',
    name: 'Pipestone Energy Corp.',
    symbol: 'PIPE.TO',
  },
  {
    value: 'XPLRA.OL',
    label: 'XPLRA.OL,  Xplora Technologies AS',
    name: 'Xplora Technologies AS',
    symbol: 'XPLRA.OL',
  },
  {
    value: 'LIO.V',
    label: 'LIO.V,  Lion One Metals Limited',
    name: 'Lion One Metals Limited',
    symbol: 'LIO.V',
  },
  {
    value: 'IHS',
    label: 'IHS,  IHS Holding Limited',
    name: 'IHS Holding Limited',
    symbol: 'IHS',
  },
  {
    value: 'MAI.V',
    label: 'MAI.V,  Minera Alamos Inc.',
    name: 'Minera Alamos Inc.',
    symbol: 'MAI.V',
  },
  {
    value: 'GASX.V',
    label: 'GASX.V,  NG Energy International Corp.',
    name: 'NG Energy International Corp.',
    symbol: 'GASX.V',
  },
  {
    value: 'ICAGY',
    label: 'ICAGY,  International Consolidated Airlines Group S.A.',
    name: 'International Consolidated Airlines Group S.A.',
    symbol: 'ICAGY',
  },
  {
    value: 'USBF',
    label: 'USBF,  iShares USD Bond Factor ETF',
    name: 'iShares USD Bond Factor ETF',
    symbol: 'USBF',
  },
  {
    value: 'BFRI',
    label: 'BFRI,  Biofrontera Inc.',
    name: 'Biofrontera Inc.',
    symbol: 'BFRI',
  },
  {
    value: 'AALLON.HE',
    label: 'AALLON.HE,  Aallon Group Oyj',
    name: 'Aallon Group Oyj',
    symbol: 'AALLON.HE',
  },
  {
    value: 'ACG1V.HE',
    label: 'ACG1V.HE,  Aspocomp Group Oyj',
    name: 'Aspocomp Group Oyj',
    symbol: 'ACG1V.HE',
  },
  {
    value: 'AFAGR.HE',
    label: 'AFAGR.HE,  Afarak Group SE',
    name: 'Afarak Group SE',
    symbol: 'AFAGR.HE',
  },
  {
    value: 'AKTIA.HE',
    label: 'AKTIA.HE,  Aktia Pankki Oyj',
    name: 'Aktia Pankki Oyj',
    symbol: 'AKTIA.HE',
  },
  {
    value: 'ALBAV.HE',
    label: 'ALBAV.HE,  Ålandsbanken Abp',
    name: 'Ålandsbanken Abp',
    symbol: 'ALBAV.HE',
  },
  {
    value: 'ALBBV.HE',
    label: 'ALBBV.HE,  Ålandsbanken Abp',
    name: 'Ålandsbanken Abp',
    symbol: 'ALBBV.HE',
  },
  {
    value: 'ABCB4.SA',
    label: 'ABCB4.SA,  Banco ABC Brasil S.A.',
    name: 'Banco ABC Brasil S.A.',
    symbol: 'ABCB4.SA',
  },
  {
    value: 'ALMA.HE',
    label: 'ALMA.HE,  Alma Media Oyj',
    name: 'Alma Media Oyj',
    symbol: 'ALMA.HE',
  },
  {
    value: 'ABEV3.SA',
    label: 'ABEV3.SA,  Ambev S.A.',
    name: 'Ambev S.A.',
    symbol: 'ABEV3.SA',
  },
  {
    value: 'ANORA.HE',
    label: 'ANORA.HE,  Anora Group Oyj',
    name: 'Anora Group Oyj',
    symbol: 'ANORA.HE',
  },
  {
    value: 'ABE.WA',
    label: 'ABE.WA,  AB S.A.',
    name: 'AB S.A.',
    symbol: 'ABE.WA',
  },
  {
    value: 'APETIT.HE',
    label: 'APETIT.HE,  Apetit Oyj',
    name: 'Apetit Oyj',
    symbol: 'APETIT.HE',
  },
  {
    value: 'ABG.JO',
    label: 'ABG.JO,  Absa Group Limited',
    name: 'Absa Group Limited',
    symbol: 'ABG.JO',
  },
  {
    value: 'ABG.OL',
    label: 'ABG.OL,  ABG Sundal Collier Holding ASA',
    name: 'ABG Sundal Collier Holding ASA',
    symbol: 'ABG.OL',
  },
  {
    value: 'ASPO.HE',
    label: 'ASPO.HE,  Aspo Oyj',
    name: 'Aspo Oyj',
    symbol: 'ASPO.HE',
  },
  {
    value: 'ATRAV.HE',
    label: 'ATRAV.HE,  Atria Oyj',
    name: 'Atria Oyj',
    symbol: 'ATRAV.HE',
  },
  {
    value: 'BIOBV.HE',
    label: 'BIOBV.HE,  Biohit Oyj',
    name: 'Biohit Oyj',
    symbol: 'BIOBV.HE',
  },
  {
    value: 'BONEH.HE',
    label: 'BONEH.HE,  BBS-Bioactive Bone Substitutes Oyj',
    name: 'BBS-Bioactive Bone Substitutes Oyj',
    symbol: 'BONEH.HE',
  },
  {
    value: 'BOREO.HE',
    label: 'BOREO.HE,  Boreo Oyj',
    name: 'Boreo Oyj',
    symbol: 'BOREO.HE',
  },
  {
    value: 'CONSTI.HE',
    label: 'CONSTI.HE,  Consti Oyj',
    name: 'Consti Oyj',
    symbol: 'CONSTI.HE',
  },
  {
    value: 'CTH1V.HE',
    label: 'CTH1V.HE,  Componenta Corporation',
    name: 'Componenta Corporation',
    symbol: 'CTH1V.HE',
  },
  {
    value: 'BANKA.NS',
    label: 'BANKA.NS,  Banka BioLoo Limited',
    name: 'Banka BioLoo Limited',
    symbol: 'BANKA.NS',
  },
  {
    value: 'DETEC.HE',
    label: 'DETEC.HE,  Detection Technology Oyj',
    name: 'Detection Technology Oyj',
    symbol: 'DETEC.HE',
  },
  {
    value: 'BGLOBAL.NS',
    label: 'BGLOBAL.NS,  Bharatiya Global Infomedia Limited',
    name: 'Bharatiya Global Infomedia Limited',
    symbol: 'BGLOBAL.NS',
  },
  {
    value: 'DIGIGR.HE',
    label: 'DIGIGR.HE,  Digitalist Group Plc',
    name: 'Digitalist Group Plc',
    symbol: 'DIGIGR.HE',
  },
  {
    value: 'DOV1V.HE',
    label: 'DOV1V.HE,  Dovre Group Plc',
    name: 'Dovre Group Plc',
    symbol: 'DOV1V.HE',
  },
  {
    value: 'EEZY.HE',
    label: 'EEZY.HE,  Eezy Oyj',
    name: 'Eezy Oyj',
    symbol: 'EEZY.HE',
  },
  {
    value: 'EFECTE.HE',
    label: 'EFECTE.HE,  Efecte Oy',
    name: 'Efecte Oy',
    symbol: 'EFECTE.HE',
  },
  {
    value: 'ELEAV.HE',
    label: 'ELEAV.HE,  Elecster Oyj',
    name: 'Elecster Oyj',
    symbol: 'ELEAV.HE',
  },
  {
    value: 'ENDOM.HE',
    label: 'ENDOM.HE,  Endomines AB (publ)',
    name: 'Endomines AB (publ)',
    symbol: 'ENDOM.HE',
  },
  {
    value: 'ENEDO.HE',
    label: 'ENEDO.HE,  Enedo Oyj',
    name: 'Enedo Oyj',
    symbol: 'ENEDO.HE',
  },
  {
    value: 'ERIBR.HE',
    label: 'ERIBR.HE,  Telefonaktiebolaget LM Ericsson (publ)',
    name: 'Telefonaktiebolaget LM Ericsson (publ)',
    symbol: 'ERIBR.HE',
  },
  {
    value: 'ESENSE.HE',
    label: 'ESENSE.HE,  Enersense International Oyj',
    name: 'Enersense International Oyj',
    symbol: 'ESENSE.HE',
  },
  {
    value: 'ETTE.HE',
    label: 'ETTE.HE,  Etteplan Oyj',
    name: 'Etteplan Oyj',
    symbol: 'ETTE.HE',
  },
  {
    value: 'EVLI.HE',
    label: 'EVLI.HE,  Evli Oyj',
    name: 'Evli Oyj',
    symbol: 'EVLI.HE',
  },
  {
    value: 'EXL1V.HE',
    label: 'EXL1V.HE,  Exel Composites Oyj',
    name: 'Exel Composites Oyj',
    symbol: 'EXL1V.HE',
  },
  {
    value: 'FELLOW.HE',
    label: 'FELLOW.HE,  Fellow Pankki Oyj',
    name: 'Fellow Pankki Oyj',
    symbol: 'FELLOW.HE',
  },
  {
    value: 'FONDIA.HE',
    label: 'FONDIA.HE,  Fondia Oyj',
    name: 'Fondia Oyj',
    symbol: 'FONDIA.HE',
  },
  {
    value: 'GLA1V.HE',
    label: 'GLA1V.HE,  Glaston Oyj Abp',
    name: 'Glaston Oyj Abp',
    symbol: 'GLA1V.HE',
  },
  {
    value: 'GOFORE.HE',
    label: 'GOFORE.HE,  Gofore Oyj',
    name: 'Gofore Oyj',
    symbol: 'GOFORE.HE',
  },
  {
    value: 'HEEROS.HE',
    label: 'HEEROS.HE,  Heeros Oyj',
    name: 'Heeros Oyj',
    symbol: 'HEEROS.HE',
  },
  {
    value: 'HONBS.HE',
    label: 'HONBS.HE,  Honkarakenne Oyj',
    name: 'Honkarakenne Oyj',
    symbol: 'HONBS.HE',
  },
  {
    value: 'HRTIS.HE',
    label: 'HRTIS.HE,  Herantis Pharma Oyj',
    name: 'Herantis Pharma Oyj',
    symbol: 'HRTIS.HE',
  },
  {
    value: 'GANGOTRI.NS',
    label: 'GANGOTRI.NS,  Gangotri Textiles Limited',
    name: 'Gangotri Textiles Limited',
    symbol: 'GANGOTRI.NS',
  },
  {
    value: 'ICP1V.HE',
    label: 'ICP1V.HE,  Incap Oyj',
    name: 'Incap Oyj',
    symbol: 'ICP1V.HE',
  },
  {
    value: 'IFA1V.HE',
    label: 'IFA1V.HE,  Innofactor Oyj',
    name: 'Innofactor Oyj',
    symbol: 'IFA1V.HE',
  },
  {
    value: 'INVEST.HE',
    label: 'INVEST.HE,  Investors House Oyj',
    name: 'Investors House Oyj',
    symbol: 'INVEST.HE',
  },
  {
    value: 'KELAS.HE',
    label: 'KELAS.HE,  Kesla Oyj',
    name: 'Kesla Oyj',
    symbol: 'KELAS.HE',
  },
  {
    value: 'KREATE.HE',
    label: 'KREATE.HE,  Kreate Group Oyj',
    name: 'Kreate Group Oyj',
    symbol: 'KREATE.HE',
  },
  {
    value: 'KSLAV.HE',
    label: 'KSLAV.HE,  Keskisuomalainen Oyj',
    name: 'Keskisuomalainen Oyj',
    symbol: 'KSLAV.HE',
  },
  {
    value: 'JINDCOT.NS',
    label: 'JINDCOT.NS,  Jindal Cotex Limited',
    name: 'Jindal Cotex Limited',
    symbol: 'JINDCOT.NS',
  },
  {
    value: 'LOUD.HE',
    label: 'LOUD.HE,  Loudspring Oyj',
    name: 'Loudspring Oyj',
    symbol: 'LOUD.HE',
  },
  {
    value: 'MARAS.HE',
    label: 'MARAS.HE,  Martela Oyj',
    name: 'Martela Oyj',
    symbol: 'MARAS.HE',
  },
  {
    value: 'METSA.HE',
    label: 'METSA.HE,  Metsä Board Oyj',
    name: 'Metsä Board Oyj',
    symbol: 'METSA.HE',
  },
  {
    value: 'NANOFH.HE',
    label: 'NANOFH.HE,  Nanoform Finland Oyj',
    name: 'Nanoform Finland Oyj',
    symbol: 'NANOFH.HE',
  },
  {
    value: 'NDA-FI.HE',
    label: 'NDA-FI.HE,  Nordea Bank Abp',
    name: 'Nordea Bank Abp',
    symbol: 'NDA-FI.HE',
  },
  {
    value: 'NIXU.HE',
    label: 'NIXU.HE,  Nixu Oyj',
    name: 'Nixu Oyj',
    symbol: 'NIXU.HE',
  },
  {
    value: 'NLG1V.HE',
    label: 'NLG1V.HE,  Nurminen Logistics Oyj',
    name: 'Nurminen Logistics Oyj',
    symbol: 'NLG1V.HE',
  },
  {
    value: 'NOHO.HE',
    label: 'NOHO.HE,  NoHo Partners Oyj',
    name: 'NoHo Partners Oyj',
    symbol: 'NOHO.HE',
  },
  {
    value: 'NXTMH.HE',
    label: 'NXTMH.HE,  Nexstim Plc',
    name: 'Nexstim Plc',
    symbol: 'NXTMH.HE',
  },
  {
    value: 'OKDAV.HE',
    label: 'OKDAV.HE,  Oriola Oyj',
    name: 'Oriola Oyj',
    symbol: 'OKDAV.HE',
  },
  {
    value: 'OMASP.HE',
    label: 'OMASP.HE,  Oma Säästöpankki Oyj',
    name: 'Oma Säästöpankki Oyj',
    symbol: 'OMASP.HE',
  },
  {
    value: 'OPTOMED.HE',
    label: 'OPTOMED.HE,  Optomed Oyj',
    name: 'Optomed Oyj',
    symbol: 'OPTOMED.HE',
  },
  {
    value: 'ORTHEX.HE',
    label: 'ORTHEX.HE,  Orthex Oyj',
    name: 'Orthex Oyj',
    symbol: 'ORTHEX.HE',
  },
  {
    value: 'QUINTEGRA.NS',
    label: 'QUINTEGRA.NS,  Quintegra Solutions Limited',
    name: 'Quintegra Solutions Limited',
    symbol: 'QUINTEGRA.NS',
  },
  {
    value: 'OVARO.HE',
    label: 'OVARO.HE,  Ovaro Kiinteistösijoitus Oyj',
    name: 'Ovaro Kiinteistösijoitus Oyj',
    symbol: 'OVARO.HE',
  },
  {
    value: 'RAJVIR.NS',
    label: 'RAJVIR.NS,  Rajvir Industries Limited',
    name: 'Rajvir Industries Limited',
    symbol: 'RAJVIR.NS',
  },
  {
    value: 'PARTNE1.HE',
    label: 'PARTNE1.HE,  Partnera Oy',
    name: 'Partnera Oy',
    symbol: 'PARTNE1.HE',
  },
  {
    value: 'PIHLIS.HE',
    label: 'PIHLIS.HE,  Pihlajalinna Oyj',
    name: 'Pihlajalinna Oyj',
    symbol: 'PIHLIS.HE',
  },
  {
    value: 'REGENCERAM.NS',
    label: 'REGENCERAM.NS,  Regency Ceramics Limited',
    name: 'Regency Ceramics Limited',
    symbol: 'REGENCERAM.NS',
  },
  {
    value: 'PIIPPO.HE',
    label: 'PIIPPO.HE,  Piippo Oyj',
    name: 'Piippo Oyj',
    symbol: 'PIIPPO.HE',
  },
  {
    value: 'PNA1V.HE',
    label: 'PNA1V.HE,  Panostaja Oyj',
    name: 'Panostaja Oyj',
    symbol: 'PNA1V.HE',
  },
  {
    value: 'SPCENET.NS',
    label: 'SPCENET.NS,  Spacenet Enterprises India Limited',
    name: 'Spacenet Enterprises India Limited',
    symbol: 'SPCENET.NS',
  },
  {
    value: 'PUMU.HE',
    label: 'PUMU.HE,  PunaMusta Media Oyj',
    name: 'PunaMusta Media Oyj',
    symbol: 'PUMU.HE',
  },
  {
    value: 'PUUILO.HE',
    label: 'PUUILO.HE,  Puuilo Oyj',
    name: 'Puuilo Oyj',
    symbol: 'PUUILO.HE',
  },
  {
    value: 'QPR1V.HE',
    label: 'QPR1V.HE,  QPR Software Oyj',
    name: 'QPR Software Oyj',
    symbol: 'QPR1V.HE',
  },
  {
    value: 'RAIKV.HE',
    label: 'RAIKV.HE,  Raisio plc',
    name: 'Raisio plc',
    symbol: 'RAIKV.HE',
  },
  {
    value: 'UTIAMC.NS',
    label: 'UTIAMC.NS,  UTI Asset Management Company Limited',
    name: 'UTI Asset Management Company Limited',
    symbol: 'UTIAMC.NS',
  },
  {
    value: 'RAP1V.HE',
    label: 'RAP1V.HE,  Rapala VMC Corporation',
    name: 'Rapala VMC Corporation',
    symbol: 'RAP1V.HE',
  },
  {
    value: 'RAUTE.HE',
    label: 'RAUTE.HE,  Raute Oyj',
    name: 'Raute Oyj',
    symbol: 'RAUTE.HE',
  },
  {
    value: 'VISESHINFO.NS',
    label: 'VISESHINFO.NS,  MPS Infotecnics Limited',
    name: 'MPS Infotecnics Limited',
    symbol: 'VISESHINFO.NS',
  },
  {
    value: 'ZENITHSTL.NS',
    label: 'ZENITHSTL.NS,  Zenith Steel Pipes & Industries Limited',
    name: 'Zenith Steel Pipes & Industries Limited',
    symbol: 'ZENITHSTL.NS',
  },
  {
    value: 'REKA.HE',
    label: 'REKA.HE,  Reka Industrial Oyj',
    name: 'Reka Industrial Oyj',
    symbol: 'REKA.HE',
  },
  {
    value: 'REMEDY.HE',
    label: 'REMEDY.HE,  Remedy Entertainment Oyj',
    name: 'Remedy Entertainment Oyj',
    symbol: 'REMEDY.HE',
  },
  {
    value: 'ROBIT.HE',
    label: 'ROBIT.HE,  Robit Oyj',
    name: 'Robit Oyj',
    symbol: 'ROBIT.HE',
  },
  {
    value: 'SAGCV.HE',
    label: 'SAGCV.HE,  Saga Furs Oyj',
    name: 'Saga Furs Oyj',
    symbol: 'SAGCV.HE',
  },
  {
    value: 'SAVOH.HE',
    label: 'SAVOH.HE,  Savosolar Oyj',
    name: 'Savosolar Oyj',
    symbol: 'SAVOH.HE',
  },
  {
    value: 'SIEVI.HE',
    label: 'SIEVI.HE,  Sievi Capital Plc',
    name: 'Sievi Capital Plc',
    symbol: 'SIEVI.HE',
  },
  {
    value: 'SIILI.HE',
    label: 'SIILI.HE,  Siili Solutions Oyj',
    name: 'Siili Solutions Oyj',
    symbol: 'SIILI.HE',
  },
  {
    value: 'SKARTA.HE',
    label: 'SKARTA.HE,  SkartaNYAB Oyj',
    name: 'SkartaNYAB Oyj',
    symbol: 'SKARTA.HE',
  },
  {
    value: 'SOLTEQ.HE',
    label: 'SOLTEQ.HE,  Solteq Oyj',
    name: 'Solteq Oyj',
    symbol: 'SOLTEQ.HE',
  },
  {
    value: 'SOPRA.HE',
    label: 'SOPRA.HE,  Soprano Oyj',
    name: 'Soprano Oyj',
    symbol: 'SOPRA.HE',
  },
  {
    value: 'SOSI1.HE',
    label: 'SOSI1.HE,  Sotkamo Silver AB',
    name: 'Sotkamo Silver AB',
    symbol: 'SOSI1.HE',
  },
  {
    value: 'SRV1V.HE',
    label: 'SRV1V.HE,  SRV Yhtiöt Oyj',
    name: 'SRV Yhtiöt Oyj',
    symbol: 'SRV1V.HE',
  },
  {
    value: 'SSABAH.HE',
    label: 'SSABAH.HE,  SSAB AB (publ)',
    name: 'SSAB AB (publ)',
    symbol: 'SSABAH.HE',
  },
  {
    value: 'SSABBH.HE',
    label: 'SSABBH.HE,  SSAB AB (publ)',
    name: 'SSAB AB (publ)',
    symbol: 'SSABBH.HE',
  },
  {
    value: 'SSH1V.HE',
    label: 'SSH1V.HE,  SSH Communications Security Oyj',
    name: 'SSH Communications Security Oyj',
    symbol: 'SSH1V.HE',
  },
  {
    value: 'STOCKA.HE',
    label: 'STOCKA.HE,  Stockmann Oyj Abp',
    name: 'Stockmann Oyj Abp',
    symbol: 'STOCKA.HE',
  },
  {
    value: 'TALLINK.HE',
    label: 'TALLINK.HE,  AS Tallink Grupp',
    name: 'AS Tallink Grupp',
    symbol: 'TALLINK.HE',
  },
  {
    value: 'TELIA1.HE',
    label: 'TELIA1.HE,  Telia Company AB (publ)',
    name: 'Telia Company AB (publ)',
    symbol: 'TELIA1.HE',
  },
  {
    value: 'ABLT',
    label: 'ABLT,  American Biltrite Inc.',
    name: 'American Biltrite Inc.',
    symbol: 'ABLT',
  },
  {
    value: 'ABS.WA',
    label: 'ABS.WA,  Asseco Business Solutions S.A.',
    name: 'Asseco Business Solutions S.A.',
    symbol: 'ABS.WA',
  },
  {
    value: 'ACAD.ST',
    label: 'ACAD.ST,  AcadeMedia AB (publ)',
    name: 'AcadeMedia AB (publ)',
    symbol: 'ACAD.ST',
  },
  {
    value: 'AC.MX',
    label: 'AC.MX,  Arca Continental, S.A.B. de C.V.',
    name: 'Arca Continental, S.A.B. de C.V.',
    symbol: 'AC.MX',
  },
  {
    value: 'AHCS.QA',
    label: 'AHCS.QA,  Aamal Company Q.P.S.C.',
    name: 'Aamal Company Q.P.S.C.',
    symbol: 'AHCS.QA',
  },
  {
    value: 'AIA.NZ',
    label: 'AIA.NZ,  Auckland International Airport Limited',
    name: 'Auckland International Airport Limited',
    symbol: 'AIA.NZ',
  },
  {
    value: 'AIR.NZ',
    label: 'AIR.NZ,  Air New Zealand Limited',
    name: 'Air New Zealand Limited',
    symbol: 'AIR.NZ',
  },
  {
    value: 'AISA.JK',
    label: 'AISA.JK,  PT FKS Food Sejahtera Tbk',
    name: 'PT FKS Food Sejahtera Tbk',
    symbol: 'AISA.JK',
  },
  {
    value: 'AIY.SI',
    label: 'AIY.SI,  iFAST Corporation Ltd.',
    name: 'iFAST Corporation Ltd.',
    symbol: 'AIY.SI',
  },
  {
    value: 'AJBU.SI',
    label: 'AJBU.SI,  Keppel DC REIT',
    name: 'Keppel DC REIT',
    symbol: 'AJBU.SI',
  },
  {
    value: 'AKAST.OL',
    label: 'AKAST.OL,  Akastor ASA',
    name: 'Akastor ASA',
    symbol: 'AKAST.OL',
  },
  {
    value: 'AKBNK.IS',
    label: 'AKBNK.IS,  Akbank T.A.S.',
    name: 'Akbank T.A.S.',
    symbol: 'AKBNK.IS',
  },
  {
    value: 'AKGRT.IS',
    label: 'AKGRT.IS,  Aksigorta A.S.',
    name: 'Aksigorta A.S.',
    symbol: 'AKGRT.IS',
  },
  {
    value: 'AKH.OL',
    label: 'AKH.OL,  Aker Horizons ASA',
    name: 'Aker Horizons ASA',
    symbol: 'AKH.OL',
  },
  {
    value: 'AKRA.JK',
    label: 'AKRA.JK,  PT AKR Corporindo Tbk',
    name: 'PT AKR Corporindo Tbk',
    symbol: 'AKRA.JK',
  },
  {
    value: 'AKRBP.OL',
    label: 'AKRBP.OL,  Aker BP ASA',
    name: 'Aker BP ASA',
    symbol: 'AKRBP.OL',
  },
  {
    value: 'AKSA.IS',
    label: 'AKSA.IS,  Aksa Akrilik Kimya Sanayii A.S.',
    name: 'Aksa Akrilik Kimya Sanayii A.S.',
    symbol: 'AKSA.IS',
  },
  {
    value: 'AKSEN.IS',
    label: 'AKSEN.IS,  Aksa Enerji Üretim A.S.',
    name: 'Aksa Enerji Üretim A.S.',
    symbol: 'AKSEN.IS',
  },
  {
    value: 'ALARK.IS',
    label: 'ALARK.IS,  Alarko Holding A.S.',
    name: 'Alarko Holding A.S.',
    symbol: 'ALARK.IS',
  },
  {
    value: 'ALBRK.IS',
    label: 'ALBRK.IS,  Albaraka Türk Katilim Bankasi A.S.',
    name: 'Albaraka Türk Katilim Bankasi A.S.',
    symbol: 'ALBRK.IS',
  },
  {
    value: 'ENTFU',
    label: 'ENTFU,  Enterprise 4.0 Technology Acquisition Corp.',
    name: 'Enterprise 4.0 Technology Acquisition Corp.',
    symbol: 'ENTFU',
  },
  {
    value: 'SMAPU',
    label: 'SMAPU,  SportsMap Tech Acquisition Corp.',
    name: 'SportsMap Tech Acquisition Corp.',
    symbol: 'SMAPU',
  },
  {
    value: 'GTACU',
    label: 'GTACU,  Global Technology Acquisition Corp. I',
    name: 'Global Technology Acquisition Corp. I',
    symbol: 'GTACU',
  },
  {
    value: 'BMAQU',
    label: 'BMAQU,  Blockchain Moon Acquisition Corp.',
    name: 'Blockchain Moon Acquisition Corp.',
    symbol: 'BMAQU',
  },
  {
    value: 'ALE.WA',
    label: 'ALE.WA,  Allegro.eu SA',
    name: 'Allegro.eu SA',
    symbol: 'ALE.WA',
  },
  {
    value: 'CDAQU',
    label: 'CDAQU,  Compass Digital Acquisition Corp.',
    name: 'Compass Digital Acquisition Corp.',
    symbol: 'CDAQU',
  },
  {
    value: 'FNA',
    label: 'FNA,  Paragon 28, Inc.',
    name: 'Paragon 28, Inc.',
    symbol: 'FNA',
  },
  {
    value: 'ALFAA.MX',
    label: 'ALFAA.MX,  Alfa, S. A. B. de C. V.',
    name: 'Alfa, S. A. B. de C. V.',
    symbol: 'ALFAA.MX',
  },
  {
    value: 'CING',
    label: 'CING,  Cingulate Inc.',
    name: 'Cingulate Inc.',
    symbol: 'CING',
  },
  {
    value: 'AUGX',
    label: 'AUGX,  Augmedix, Inc.',
    name: 'Augmedix, Inc.',
    symbol: 'AUGX',
  },
  {
    value: 'INKT',
    label: 'INKT,  MiNK Therapeutics, Inc.',
    name: 'MiNK Therapeutics, Inc.',
    symbol: 'INKT',
  },
  {
    value: 'ALFA.ST',
    label: 'ALFA.ST,  Alfa Laval Corporate AB',
    name: 'Alfa Laval Corporate AB',
    symbol: 'ALFA.ST',
  },
  {
    value: 'AVHIU',
    label: 'AVHIU,  Achari Ventures Holdings Corp. I',
    name: 'Achari Ventures Holdings Corp. I',
    symbol: 'AVHIU',
  },
  {
    value: 'OVH.PA',
    label: 'OVH.PA,  OVH Groupe S.A.',
    name: 'OVH Groupe S.A.',
    symbol: 'OVH.PA',
  },
  {
    value: 'PEPLU',
    label: 'PEPLU,  PepperLime Health Acquisition Corporation',
    name: 'PepperLime Health Acquisition Corporation',
    symbol: 'PEPLU',
  },
  {
    value: 'LUCD',
    label: 'LUCD,  Lucid Diagnostics Inc.',
    name: 'Lucid Diagnostics Inc.',
    symbol: 'LUCD',
  },
  {
    value: 'GTLB',
    label: 'GTLB,  GitLab Inc.',
    name: 'GitLab Inc.',
    symbol: 'GTLB',
  },
  {
    value: 'BEAT',
    label: 'BEAT,  HeartBeam, Inc.',
    name: 'HeartBeam, Inc.',
    symbol: 'BEAT',
  },
  {
    value: 'ALHE.TA',
    label: 'ALHE.TA,  Alony-Hetz Properties & Investments Ltd',
    name: 'Alony-Hetz Properties & Investments Ltd',
    symbol: 'ALHE.TA',
  },
  {
    value: 'ALHRS.PA',
    label: 'ALHRS.PA,  Hydrogen-Refueling-Solutions SA',
    name: 'Hydrogen-Refueling-Solutions SA',
    symbol: 'ALHRS.PA',
  },
  {
    value: 'CLST',
    label: 'CLST,  Catalyst Bancorp, Inc.',
    name: 'Catalyst Bancorp, Inc.',
    symbol: 'CLST',
  },
  {
    value: 'HCTI',
    label: 'HCTI,  Healthcare Triangle, Inc.',
    name: 'Healthcare Triangle, Inc.',
    symbol: 'HCTI',
  },
  {
    value: 'HAYPP.ST',
    label: 'HAYPP.ST,  Haypp Group AB (publ)',
    name: 'Haypp Group AB (publ)',
    symbol: 'HAYPP.ST',
  },
  {
    value: 'BBLGW',
    label: 'BBLGW,  Bone Biologics Corporation',
    name: 'Bone Biologics Corporation',
    symbol: 'BBLGW',
  },
  {
    value: 'AVDX',
    label: 'AVDX,  AvidXchange Holdings, Inc.',
    name: 'AvidXchange Holdings, Inc.',
    symbol: 'AVDX',
  },
  {
    value: 'BBLG',
    label: 'BBLG,  Bone Biologics Corporation',
    name: 'Bone Biologics Corporation',
    symbol: 'BBLG',
  },
  {
    value: 'XRTX',
    label: 'XRTX,  XORTX Therapeutics Inc.',
    name: 'XORTX Therapeutics Inc.',
    symbol: 'XRTX',
  },
  {
    value: 'VGFC',
    label: 'VGFC,  The Very Good Food Company Inc.',
    name: 'The Very Good Food Company Inc.',
    symbol: 'VGFC',
  },
  {
    value: 'ALIG.ST',
    label: 'ALIG.ST,  Alimak Group AB (publ)',
    name: 'Alimak Group AB (publ)',
    symbol: 'ALIG.ST',
  },
  {
    value: 'GIACU',
    label: 'GIACU,  Gesher I Acquisition Corp.',
    name: 'Gesher I Acquisition Corp.',
    symbol: 'GIACU',
  },
  {
    value: 'TMTA.L',
    label: 'TMTA.L,  TMT Acquisition Plc',
    name: 'TMT Acquisition Plc',
    symbol: 'TMTA.L',
  },
  {
    value: 'PONO',
    label: 'PONO,  Pono Capital Corp.',
    name: 'Pono Capital Corp.',
    symbol: 'PONO',
  },
  {
    value: 'PYXS',
    label: 'PYXS,  Pyxis Oncology, Inc.',
    name: 'Pyxis Oncology, Inc.',
    symbol: 'PYXS',
  },
  {
    value: 'SAMAU',
    label: 'SAMAU,  Schultze Special Purpose Acquisition Corp. II',
    name: 'Schultze Special Purpose Acquisition Corp. II',
    symbol: 'SAMAU',
  },
  {
    value: 'ARBE',
    label: 'ARBE,  Arbe Robotics Ltd.',
    name: 'Arbe Robotics Ltd.',
    symbol: 'ARBE',
  },
  {
    value: 'SOPA',
    label: 'SOPA,  Society Pass Incorporated',
    name: 'Society Pass Incorporated',
    symbol: 'SOPA',
  },
  {
    value: 'CGTX',
    label: 'CGTX,  Cognition Therapeutics, Inc.',
    name: 'Cognition Therapeutics, Inc.',
    symbol: 'CGTX',
  },
  {
    value: 'JMACU',
    label: 'JMACU,  Maxpro Capital Acquisition Corp.',
    name: 'Maxpro Capital Acquisition Corp.',
    symbol: 'JMACU',
  },
  {
    value: 'LCW-UN',
    label: 'LCW-UN,  Learn CW Investment Corporation',
    name: 'Learn CW Investment Corporation',
    symbol: 'LCW-UN',
  },
  {
    value: 'ARBEW',
    label: 'ARBEW,  Arbe Robotics Ltd.',
    name: 'Arbe Robotics Ltd.',
    symbol: 'ARBEW',
  },
  {
    value: 'PONOW',
    label: 'PONOW,  Pono Capital Corp.',
    name: 'Pono Capital Corp.',
    symbol: 'PONOW',
  },
  {
    value: 'IXAQU',
    label: 'IXAQU,  IX Acquisition Corp.',
    name: 'IX Acquisition Corp.',
    symbol: 'IXAQU',
  },
  {
    value: 'CPLF.TO',
    label: 'CPLF.TO,  Copperleaf Technologies Inc.',
    name: 'Copperleaf Technologies Inc.',
    symbol: 'CPLF.TO',
  },
  {
    value: 'THRX',
    label: 'THRX,  Theseus Pharmaceuticals, Inc.',
    name: 'Theseus Pharmaceuticals, Inc.',
    symbol: 'THRX',
  },
  {
    value: 'GTR',
    label: 'GTR,  WisdomTree Target Range Fund',
    name: 'WisdomTree Target Range Fund',
    symbol: 'GTR',
  },
  {
    value: 'DCACS.AS',
    label: 'DCACS.AS,  Disruptive Capital Acquisition Company Limited',
    name: 'Disruptive Capital Acquisition Company Limited',
    symbol: 'DCACS.AS',
  },
  {
    value: 'HTIBP',
    label: 'HTIBP,  Healthcare Trust, Inc.',
    name: 'Healthcare Trust, Inc.',
    symbol: 'HTIBP',
  },
  {
    value: 'LTH',
    label: 'LTH,  Life Time Group Holdings, Inc.',
    name: 'Life Time Group Holdings, Inc.',
    symbol: 'LTH',
  },
  {
    value: '2256.HK',
    label: '2256.HK,  Abbisko Cayman Limited',
    name: 'Abbisko Cayman Limited',
    symbol: '2256.HK',
  },
  {
    value: 'VLCN',
    label: 'VLCN,  Volcon, Inc.',
    name: 'Volcon, Inc.',
    symbol: 'VLCN',
  },
  {
    value: 'PCXCU',
    label: 'PCXCU,  Parsec Capital Acquisitions Corp',
    name: 'Parsec Capital Acquisitions Corp',
    symbol: 'PCXCU',
  },
  {
    value: 'OXUS',
    label: 'OXUS,  Oxus Acquisition Corp.',
    name: 'Oxus Acquisition Corp.',
    symbol: 'OXUS',
  },
  {
    value: 'AVACU',
    label: 'AVACU,  Avalon Acquisition Inc.',
    name: 'Avalon Acquisition Inc.',
    symbol: 'AVACU',
  },
  {
    value: 'OXUSW',
    label: 'OXUSW,  Oxus Acquisition Corp.',
    name: 'Oxus Acquisition Corp.',
    symbol: 'OXUSW',
  },
  {
    value: 'NVEI',
    label: 'NVEI,  Nuvei Corporation',
    name: 'Nuvei Corporation',
    symbol: 'NVEI',
  },
  {
    value: 'LP.CN',
    label: 'LP.CN,  Leopard Lake Gold Corp.',
    name: 'Leopard Lake Gold Corp.',
    symbol: 'LP.CN',
  },
  {
    value: 'RNERU',
    label: 'RNERU,  Mount Rainier Acquisition Corp.',
    name: 'Mount Rainier Acquisition Corp.',
    symbol: 'RNERU',
  },
  {
    value: 'UCRD',
    label: 'UCRD,  VictoryShares ESG Corporate Bond ETF',
    name: 'VictoryShares ESG Corporate Bond ETF',
    symbol: 'UCRD',
  },
  {
    value: 'MDCP',
    label: 'MDCP,  VictoryShares THB Mid Cap ESG ETF',
    name: 'VictoryShares THB Mid Cap ESG ETF',
    symbol: 'MDCP',
  },
  {
    value: 'HCDIZ',
    label: 'HCDIZ,  Harbor Custom Development, Inc.',
    name: 'Harbor Custom Development, Inc.',
    symbol: 'HCDIZ',
  },
  {
    value: 'LFMDP',
    label: 'LFMDP,  LifeMD, Inc.',
    name: 'LifeMD, Inc.',
    symbol: 'LFMDP',
  },
  {
    value: 'UBND',
    label: 'UBND,  VictoryShares ESG Core Plus Bond ETF',
    name: 'VictoryShares ESG Core Plus Bond ETF',
    symbol: 'UBND',
  },
  {
    value: 'GIPR',
    label: 'GIPR,  Generation Income Properties, Inc.',
    name: 'Generation Income Properties, Inc.',
    symbol: 'GIPR',
  },
  {
    value: 'CENQW',
    label: 'CENQW,  CENAQ Energy Corp.',
    name: 'CENAQ Energy Corp.',
    symbol: 'CENQW',
  },
  {
    value: 'CENQ',
    label: 'CENQ,  CENAQ Energy Corp.',
    name: 'CENAQ Energy Corp.',
    symbol: 'CENQ',
  },
  {
    value: 'PPHP',
    label: 'PPHP,  PHP Ventures Acquisition Corp.',
    name: 'PHP Ventures Acquisition Corp.',
    symbol: 'PPHP',
  },
  {
    value: 'PPHPR',
    label: 'PPHPR,  PHP Ventures Acquisition Corp.',
    name: 'PHP Ventures Acquisition Corp.',
    symbol: 'PPHPR',
  },
  {
    value: 'GIPRW',
    label: 'GIPRW,  Generation Income Properties, Inc.',
    name: 'Generation Income Properties, Inc.',
    symbol: 'GIPRW',
  },
  {
    value: 'PPHPW',
    label: 'PPHPW,  PHP Ventures Acquisition Corp.',
    name: 'PHP Ventures Acquisition Corp.',
    symbol: 'PPHPW',
  },
  {
    value: 'WINV',
    label: 'WINV,  WinVest Acquisition Corp.',
    name: 'WinVest Acquisition Corp.',
    symbol: 'WINV',
  },
  {
    value: 'APMI',
    label: 'APMI,  AxonPrime Infrastructure Acquisition Corporation',
    name: 'AxonPrime Infrastructure Acquisition Corporation',
    symbol: 'APMI',
  },
  {
    value: 'APMIW',
    label: 'APMIW,  AxonPrime Infrastructure Acquisition Corporation',
    name: 'AxonPrime Infrastructure Acquisition Corporation',
    symbol: 'APMIW',
  },
  {
    value: 'WINVR',
    label: 'WINVR,  WinVest Acquisition Corp.',
    name: 'WinVest Acquisition Corp.',
    symbol: 'WINVR',
  },
  {
    value: 'WINVW',
    label: 'WINVW,  WinVest Acquisition Corp.',
    name: 'WinVest Acquisition Corp.',
    symbol: 'WINVW',
  },
  {
    value: 'GATEU',
    label: 'GATEU,  Marblegate Acquisition Corp.',
    name: 'Marblegate Acquisition Corp.',
    symbol: 'GATEU',
  },
  {
    value: 'ROIVW',
    label: 'ROIVW,  Roivant Sciences Ltd.',
    name: 'Roivant Sciences Ltd.',
    symbol: 'ROIVW',
  },
  {
    value: 'FWRG',
    label: 'FWRG,  First Watch Restaurant Group, Inc.',
    name: 'First Watch Restaurant Group, Inc.',
    symbol: 'FWRG',
  },
  {
    value: 'MTRYU',
    label: 'MTRYU,  Monterey Bio Acquisition Corporation',
    name: 'Monterey Bio Acquisition Corporation',
    symbol: 'MTRYU',
  },
  {
    value: 'EXAI',
    label: 'EXAI,  Exscientia plc',
    name: 'Exscientia plc',
    symbol: 'EXAI',
  },
  {
    value: 'OXACW',
    label: 'OXACW,  Oxbridge Acquisition Corp.',
    name: 'Oxbridge Acquisition Corp.',
    symbol: 'OXACW',
  },
  {
    value: 'VCXA',
    label: 'VCXA,  10X Capital Venture Acquisition Corp. II',
    name: '10X Capital Venture Acquisition Corp. II',
    symbol: 'VCXA',
  },
  {
    value: 'OXAC',
    label: 'OXAC,  Oxbridge Acquisition Corp.',
    name: 'Oxbridge Acquisition Corp.',
    symbol: 'OXAC',
  },
  {
    value: 'JAQCW',
    label: 'JAQCW,  Jupiter Acquisition Corporation',
    name: 'Jupiter Acquisition Corporation',
    symbol: 'JAQCW',
  },
  {
    value: 'VCXAW',
    label: 'VCXAW,  10X Capital Venture Acquisition Corp. II',
    name: '10X Capital Venture Acquisition Corp. II',
    symbol: 'VCXAW',
  },
  {
    value: 'TPBA',
    label: 'TPBA,  TPB Acquisition Corporation I',
    name: 'TPB Acquisition Corporation I',
    symbol: 'TPBA',
  },
  {
    value: 'TPBAW',
    label: 'TPBAW,  TPB Acquisition Corporation I',
    name: 'TPB Acquisition Corporation I',
    symbol: 'TPBAW',
  },
  {
    value: 'DCRD',
    label: 'DCRD,  Decarbonization Plus Acquisition Corporation IV',
    name: 'Decarbonization Plus Acquisition Corporation IV',
    symbol: 'DCRD',
  },
  {
    value: 'JAQC',
    label: 'JAQC,  Jupiter Acquisition Corporation',
    name: 'Jupiter Acquisition Corporation',
    symbol: 'JAQC',
  },
  {
    value: 'ROIV',
    label: 'ROIV,  Roivant Sciences Ltd.',
    name: 'Roivant Sciences Ltd.',
    symbol: 'ROIV',
  },
  {
    value: 'TDCX',
    label: 'TDCX,  TDCX Inc.',
    name: 'TDCX Inc.',
    symbol: 'TDCX',
  },
  {
    value: 'DCRDW',
    label: 'DCRDW,  Decarbonization Plus Acquisition Corporation IV',
    name: 'Decarbonization Plus Acquisition Corporation IV',
    symbol: 'DCRDW',
  },
  {
    value: 'GTXAP',
    label: 'GTXAP,  Garrett Motion Inc.',
    name: 'Garrett Motion Inc.',
    symbol: 'GTXAP',
  },
  {
    value: 'AHPAW',
    label: 'AHPAW,  Avista Public Acquisition Corp. II',
    name: 'Avista Public Acquisition Corp. II',
    symbol: 'AHPAW',
  },
  {
    value: 'HPLTU',
    label: 'HPLTU,  Home Plate Acquisition Corp.',
    name: 'Home Plate Acquisition Corp.',
    symbol: 'HPLTU',
  },
  {
    value: 'OLPX',
    label: 'OLPX,  Olaplex Holdings, Inc.',
    name: 'Olaplex Holdings, Inc.',
    symbol: 'OLPX',
  },
  {
    value: 'PROCW',
    label: 'PROCW,  Procaps Group, S.A.',
    name: 'Procaps Group, S.A.',
    symbol: 'PROCW',
  },
  {
    value: 'PROC',
    label: 'PROC,  Procaps Group S.A.',
    name: 'Procaps Group S.A.',
    symbol: 'PROC',
  },
  {
    value: 'ARTEU',
    label: 'ARTEU,  Artemis Strategic Investment Corporation',
    name: 'Artemis Strategic Investment Corporation',
    symbol: 'ARTEU',
  },
  {
    value: 'BAY.L',
    label: 'BAY.L,  Bay Capital Plc',
    name: 'Bay Capital Plc',
    symbol: 'BAY.L',
  },
  {
    value: 'CPAAW',
    label: 'CPAAW,  Conyers Park III Acquisition Corp.',
    name: 'Conyers Park III Acquisition Corp.',
    symbol: 'CPAAW',
  },
  {
    value: 'AHPA',
    label: 'AHPA,  Avista Public Acquisition Corp. II',
    name: 'Avista Public Acquisition Corp. II',
    symbol: 'AHPA',
  },
  {
    value: 'NOVVW',
    label: 'NOVVW,  Nova Vision Acquisition Corporation',
    name: 'Nova Vision Acquisition Corporation',
    symbol: 'NOVVW',
  },
  {
    value: 'NOVV',
    label: 'NOVV,  Nova Vision Acquisition Corporation',
    name: 'Nova Vision Acquisition Corporation',
    symbol: 'NOVV',
  },
  {
    value: 'DWACW',
    label: 'DWACW,  Digital World Acquisition Corp.',
    name: 'Digital World Acquisition Corp.',
    symbol: 'DWACW',
  },
  {
    value: 'MEKA',
    label: 'MEKA,  MELI Kaszek Pioneer Corp',
    name: 'MELI Kaszek Pioneer Corp',
    symbol: 'MEKA',
  },
  {
    value: 'MIGI',
    label: 'MIGI,  Mawson Infrastructure Group, Inc.',
    name: 'Mawson Infrastructure Group, Inc.',
    symbol: 'MIGI',
  },
  {
    value: 'IMBIL',
    label: 'IMBIL,  iMedia Brands, Inc.',
    name: 'iMedia Brands, Inc.',
    symbol: 'IMBIL',
  },
  {
    value: 'WRBY',
    label: 'WRBY,  Warby Parker Inc.',
    name: 'Warby Parker Inc.',
    symbol: 'WRBY',
  },
  {
    value: 'GFAI',
    label: 'GFAI,  Guardforce AI Co., Limited',
    name: 'Guardforce AI Co., Limited',
    symbol: 'GFAI',
  },
  {
    value: 'GFAIW',
    label: 'GFAIW,  Guardforce AI Co., Limited',
    name: 'Guardforce AI Co., Limited',
    symbol: 'GFAIW',
  },
  {
    value: 'IONM',
    label: 'IONM,  Assure Holdings Corp.',
    name: 'Assure Holdings Corp.',
    symbol: 'IONM',
  },
  {
    value: 'LMDX',
    label: 'LMDX,  LumiraDx Limited',
    name: 'LumiraDx Limited',
    symbol: 'LMDX',
  },
  {
    value: 'LMDXW',
    label: 'LMDXW,  LumiraDx Limited',
    name: 'LumiraDx Limited',
    symbol: 'LMDXW',
  },
  {
    value: 'HCVIU',
    label: 'HCVIU,  Hennessy Capital Investment Corp. VI',
    name: 'Hennessy Capital Investment Corp. VI',
    symbol: 'HCVIU',
  },
  {
    value: 'ALESE.PA',
    label: 'ALESE.PA,  Entech SA',
    name: 'Entech SA',
    symbol: 'ALESE.PA',
  },
  {
    value: 'ALAFY.PA',
    label: 'ALAFY.PA,  AFYREN SAS',
    name: 'AFYREN SAS',
    symbol: 'ALAFY.PA',
  },
  {
    value: '2217.HK',
    label: '2217.HK,  Tam Jai International Co. Limited',
    name: 'Tam Jai International Co. Limited',
    symbol: '2217.HK',
  },
  {
    value: 'GROC.L',
    label: 'GROC.L,  GreenRoc Mining plc',
    name: 'GreenRoc Mining plc',
    symbol: 'GROC.L',
  },
  {
    value: 'PHLL.L',
    label: 'PHLL.L,  Petershill Partners PLC',
    name: 'Petershill Partners PLC',
    symbol: 'PHLL.L',
  },
  {
    value: 'PAFOR',
    label: 'PAFOR,  Pacifico Acquisition Corp.',
    name: 'Pacifico Acquisition Corp.',
    symbol: 'PAFOR',
  },
  {
    value: 'ALK.L',
    label: 'ALK.L,  Alkemy Capital Investments Plc',
    name: 'Alkemy Capital Investments Plc',
    symbol: 'ALK.L',
  },
  {
    value: 'STRC',
    label: 'STRC,  Sarcos Technology and Robotics Corporation',
    name: 'Sarcos Technology and Robotics Corporation',
    symbol: 'STRC',
  },
  {
    value: 'STRCW',
    label: 'STRCW,  Sarcos Technology and Robotics Corporation',
    name: 'Sarcos Technology and Robotics Corporation',
    symbol: 'STRCW',
  },
  {
    value: 'PAFO',
    label: 'PAFO,  Pacifico Acquisition Corp.',
    name: 'Pacifico Acquisition Corp.',
    symbol: 'PAFO',
  },
  {
    value: 'PDM.V',
    label: 'PDM.V,  Palladium One Mining Inc.',
    name: 'Palladium One Mining Inc.',
    symbol: 'PDM.V',
  },
  {
    value: 'CWAN',
    label: 'CWAN,  Clearwater Analytics Holdings, Inc.',
    name: 'Clearwater Analytics Holdings, Inc.',
    symbol: 'CWAN',
  },
  {
    value: 'BWC',
    label: 'BWC,  Blue Whale Acquisition Corp I',
    name: 'Blue Whale Acquisition Corp I',
    symbol: 'BWC',
  },
  {
    value: 'AMCIW',
    label: 'AMCIW,  AMCI Acquisition Corp. II',
    name: 'AMCI Acquisition Corp. II',
    symbol: 'AMCIW',
  },
  {
    value: 'AMCI',
    label: 'AMCI,  AMCI Acquisition Corp. II',
    name: 'AMCI Acquisition Corp. II',
    symbol: 'AMCI',
  },
  {
    value: 'PEEL.L',
    label: 'PEEL.L,  Peel Hunt Limited',
    name: 'Peel Hunt Limited',
    symbol: 'PEEL.L',
  },
  {
    value: 'BWCAW',
    label: 'BWCAW,  Blue Whale Acquisition Corp I',
    name: 'Blue Whale Acquisition Corp I',
    symbol: 'BWCAW',
  },
  {
    value: 'ARBK',
    label: 'ARBK,  Argo Blockchain plc',
    name: 'Argo Blockchain plc',
    symbol: 'ARBK',
  },
  {
    value: 'BNNRU',
    label: 'BNNRU,  Banner Acquisition Corp.',
    name: 'Banner Acquisition Corp.',
    symbol: 'BNNRU',
  },
  {
    value: 'OXUSU',
    label: 'OXUSU,  Oxus Acquisition Corp.',
    name: 'Oxus Acquisition Corp.',
    symbol: 'OXUSU',
  },
  {
    value: 'DDI',
    label: 'DDI,  DoubleDown Interactive Co., Ltd.',
    name: 'DoubleDown Interactive Co., Ltd.',
    symbol: 'DDI',
  },
  {
    value: 'REVEU',
    label: 'REVEU,  Alpine Acquisition Corporation',
    name: 'Alpine Acquisition Corporation',
    symbol: 'REVEU',
  },
  {
    value: 'SQL',
    label: 'SQL,  SeqLL Inc.',
    name: 'SeqLL Inc.',
    symbol: 'SQL',
  },
  {
    value: 'CCAIU',
    label: 'CCAIU,  Cascadia Acquisition Corp.',
    name: 'Cascadia Acquisition Corp.',
    symbol: 'CCAIU',
  },
  {
    value: 'MEOAU',
    label: 'MEOAU,  Minority Equality Opportunities Acquisition Inc.',
    name: 'Minority Equality Opportunities Acquisition Inc.',
    symbol: 'MEOAU',
  },
  {
    value: 'SWSSU',
    label: 'SWSSU,  Springwater Special Situations Corp.',
    name: 'Springwater Special Situations Corp.',
    symbol: 'SWSSU',
  },
  {
    value: 'DRAY',
    label: 'DRAY,  Macondray Capital Acquisition Corp. I',
    name: 'Macondray Capital Acquisition Corp. I',
    symbol: 'DRAY',
  },
  {
    value: 'DRAYW',
    label: 'DRAYW,  Macondray Capital Acquisition Corp. I',
    name: 'Macondray Capital Acquisition Corp. I',
    symbol: 'DRAYW',
  },
  {
    value: 'IMAQR',
    label: 'IMAQR,  International Media Acquisition Corp.',
    name: 'International Media Acquisition Corp.',
    symbol: 'IMAQR',
  },
  {
    value: '6669.HK',
    label: '6669.HK,  Acotec Scientific Holdings Limited',
    name: 'Acotec Scientific Holdings Limited',
    symbol: '6669.HK',
  },
  {
    value: 'IMAQ',
    label: 'IMAQ,  International Media Acquisition Corp.',
    name: 'International Media Acquisition Corp.',
    symbol: 'IMAQ',
  },
  {
    value: 'IMAQW',
    label: 'IMAQW,  International Media Acquisition Corp.',
    name: 'International Media Acquisition Corp.',
    symbol: 'IMAQW',
  },
  {
    value: 'OTMOW',
    label: 'OTMOW,  Otonomo Technologies Ltd.',
    name: 'Otonomo Technologies Ltd.',
    symbol: 'OTMOW',
  },
  {
    value: 'FTVIW',
    label: 'FTVIW,  FinTech Acquisition Corp. VI',
    name: 'FinTech Acquisition Corp. VI',
    symbol: 'FTVIW',
  },
  {
    value: 'FTVI',
    label: 'FTVI,  FinTech Acquisition Corp. VI',
    name: 'FinTech Acquisition Corp. VI',
    symbol: 'FTVI',
  },
  {
    value: 'WHLRL',
    label: 'WHLRL,  Wheeler Real Estate Investment Trust, Inc.',
    name: 'Wheeler Real Estate Investment Trust, Inc.',
    symbol: 'WHLRL',
  },
  {
    value: 'CLAY',
    label: 'CLAY,  Chavant Capital Acquisition Corp.',
    name: 'Chavant Capital Acquisition Corp.',
    symbol: 'CLAY',
  },
  {
    value: 'DRMA',
    label: 'DRMA,  Dermata Therapeutics, Inc.',
    name: 'Dermata Therapeutics, Inc.',
    symbol: 'DRMA',
  },
  {
    value: 'LCFS.TO',
    label: 'LCFS.TO,  Tidewater Renewables Ltd.',
    name: 'Tidewater Renewables Ltd.',
    symbol: 'LCFS.TO',
  },
  {
    value: 'CENQU',
    label: 'CENQU,  CENAQ Energy Corp.',
    name: 'CENAQ Energy Corp.',
    symbol: 'CENQU',
  },
  {
    value: 'AACIU',
    label: 'AACIU,  Armada Acquisition Corp. I',
    name: 'Armada Acquisition Corp. I',
    symbol: 'AACIU',
  },
  {
    value: 'APMIU',
    label: 'APMIU,  AxonPrime Infrastructure Acquisition Corporation',
    name: 'AxonPrime Infrastructure Acquisition Corporation',
    symbol: 'APMIU',
  },
  {
    value: 'MITAW',
    label: 'MITAW,  Coliseum Acquisition Corp.',
    name: 'Coliseum Acquisition Corp.',
    symbol: 'MITAW',
  },
  {
    value: 'KTTAW',
    label: 'KTTAW,  Pasithea Therapeutics Corp.',
    name: 'Pasithea Therapeutics Corp.',
    symbol: 'KTTAW',
  },
  {
    value: 'DRMAW',
    label: 'DRMAW,  Dermata Therapeutics, Inc.',
    name: 'Dermata Therapeutics, Inc.',
    symbol: 'DRMAW',
  },
  {
    value: 'DATSW',
    label: 'DATSW,  DatChat, Inc.',
    name: 'DatChat, Inc.',
    symbol: 'DATSW',
  },
  {
    value: 'CLAYW',
    label: 'CLAYW,  Chavant Capital Acquisition Corp.',
    name: 'Chavant Capital Acquisition Corp.',
    symbol: 'CLAYW',
  },
  {
    value: 'KRSA3.SA',
    label: 'KRSA3.SA,  Kora Saúde Participações S.A.',
    name: 'Kora Saúde Participações S.A.',
    symbol: 'KRSA3.SA',
  },
  {
    value: 'MITA',
    label: 'MITA,  Coliseum Acquisition Corp.',
    name: 'Coliseum Acquisition Corp.',
    symbol: 'MITA',
  },
  {
    value: 'FINW',
    label: 'FINW,  FinWise Bancorp',
    name: 'FinWise Bancorp',
    symbol: 'FINW',
  },
  {
    value: 'SRZN',
    label: 'SRZN,  Surrozen, Inc.',
    name: 'Surrozen, Inc.',
    symbol: 'SRZN',
  },
  {
    value: 'SSBK',
    label: 'SSBK,  Southern States Bancshares, Inc.',
    name: 'Southern States Bancshares, Inc.',
    symbol: 'SSBK',
  },
  {
    value: 'SRZNW',
    label: 'SRZNW,  Surrozen, Inc.',
    name: 'Surrozen, Inc.',
    symbol: 'SRZNW',
  },
  {
    value: 'BVX.L',
    label: 'BVX.L,  BiVictriX Therapeutics Plc',
    name: 'BiVictriX Therapeutics Plc',
    symbol: 'BVX.L',
  },
  {
    value: 'PETV',
    label: 'PETV,  PetVivo Holdings, Inc.',
    name: 'PetVivo Holdings, Inc.',
    symbol: 'PETV',
  },
  {
    value: 'PETVW',
    label: 'PETVW,  PetVivo Holdings, Inc.',
    name: 'PetVivo Holdings, Inc.',
    symbol: 'PETVW',
  },
  {
    value: 'ONCO3.SA',
    label: 'ONCO3.SA,  Oncoclínicas do Brasil Serviços Médicos S.A.',
    name: 'Oncoclínicas do Brasil Serviços Médicos S.A.',
    symbol: 'ONCO3.SA',
  },
  {
    value: 'CPAAU',
    label: 'CPAAU,  Conyers Park III Acquisition Corp.',
    name: 'Conyers Park III Acquisition Corp.',
    symbol: 'CPAAU',
  },
  {
    value: 'ROCGU',
    label: 'ROCGU,  Roth CH Acquisition IV Co.',
    name: 'Roth CH Acquisition IV Co.',
    symbol: 'ROCGU',
  },
  {
    value: 'CDRE',
    label: 'CDRE,  Cadre Holdings, Inc.',
    name: 'Cadre Holdings, Inc.',
    symbol: 'CDRE',
  },
  {
    value: 'OBT',
    label: 'OBT,  Orange County Bancorp, Inc.',
    name: 'Orange County Bancorp, Inc.',
    symbol: 'OBT',
  },
  {
    value: 'BWCAU',
    label: 'BWCAU,  Blue Whale Acquisition Corp I',
    name: 'Blue Whale Acquisition Corp I',
    symbol: 'BWCAU',
  },
  {
    value: 'AMCIU',
    label: 'AMCIU,  AMCI Acquisition Corp. II',
    name: 'AMCI Acquisition Corp. II',
    symbol: 'AMCIU',
  },
  {
    value: 'RSKD',
    label: 'RSKD,  Riskified Ltd.',
    name: 'Riskified Ltd.',
    symbol: 'RSKD',
  },
  {
    value: 'IMAQU',
    label: 'IMAQU,  International Media Acquisition Corp.',
    name: 'International Media Acquisition Corp.',
    symbol: 'IMAQU',
  },
  {
    value: 'DALS',
    label: 'DALS,  DA32 Life Science Tech Acquisition Corp.',
    name: 'DA32 Life Science Tech Acquisition Corp.',
    symbol: 'DALS',
  },
  {
    value: 'MEACU',
    label: 'MEACU,  Mercury Ecommerce Acquisition Corp.',
    name: 'Mercury Ecommerce Acquisition Corp.',
    symbol: 'MEACU',
  },
  {
    value: 'APTMU',
    label: 'APTMU,  Alpha Partners Technology Merger Corp.',
    name: 'Alpha Partners Technology Merger Corp.',
    symbol: 'APTMU',
  },
  {
    value: 'ALIZY',
    label: 'ALIZY,  Allianz SE',
    name: 'Allianz SE',
    symbol: 'ALIZY',
  },
  {
    value: 'ALK-B.CO',
    label: 'ALK-B.CO,  ALK-Abelló A/S',
    name: 'ALK-Abelló A/S',
    symbol: 'ALK-B.CO',
  },
  {
    value: 'ALKIM.IS',
    label: 'ALKIM.IS,  Alkim Alkali Kimya A.S.',
    name: 'Alkim Alkali Kimya A.S.',
    symbol: 'ALKIM.IS',
  },
  {
    value: 'ALMB.CO',
    label: 'ALMB.CO,  Alm. Brand A/S',
    name: 'Alm. Brand A/S',
    symbol: 'ALMB.CO',
  },
  {
    value: 'ALM.MC',
    label: 'ALM.MC,  Almirall, S.A.',
    name: 'Almirall, S.A.',
    symbol: 'ALM.MC',
  },
  {
    value: 'ALPA4.SA',
    label: 'ALPA4.SA,  Alpargatas S.A.',
    name: 'Alpargatas S.A.',
    symbol: 'ALPA4.SA',
  },
  {
    value: 'ALPEKA.MX',
    label: 'ALPEKA.MX,  ALPEK, S.A.B. de C.V.',
    name: 'ALPEK, S.A.B. de C.V.',
    symbol: 'ALPEKA.MX',
  },
  {
    value: 'ALR.WA',
    label: 'ALR.WA,  Alior Bank S.A.',
    name: 'Alior Bank S.A.',
    symbol: 'ALR.WA',
  },
  {
    value: 'ALSEA.MX',
    label: 'ALSEA.MX,  Alsea, S.A.B. de C.V.',
    name: 'Alsea, S.A.B. de C.V.',
    symbol: 'ALSEA.MX',
  },
  {
    value: 'ALSO3.SA',
    label: 'ALSO3.SA,  Aliansce Sonae Shopping Centers S.A.',
    name: 'Aliansce Sonae Shopping Centers S.A.',
    symbol: 'ALSO3.SA',
  },
  {
    value: 'ALTU',
    label: 'ALTU,  Altitude Acquisition Corp.',
    name: 'Altitude Acquisition Corp.',
    symbol: 'ALTU',
  },
  {
    value: 'ALUP11.SA',
    label: 'ALUP11.SA,  Alupar Investimento S.A.',
    name: 'Alupar Investimento S.A.',
    symbol: 'ALUP11.SA',
  },
  {
    value: 'AMAM',
    label: 'AMAM,  Ambrx Biopharma Inc.',
    name: 'Ambrx Biopharma Inc.',
    symbol: 'AMAM',
  },
  {
    value: 'AMAR3.SA',
    label: 'AMAR3.SA,  Marisa Lojas S.A.',
    name: 'Marisa Lojas S.A.',
    symbol: 'AMAR3.SA',
  },
  {
    value: 'AMBEA.ST',
    label: 'AMBEA.ST,  Ambea AB (publ)',
    name: 'Ambea AB (publ)',
    symbol: 'AMBEA.ST',
  },
  {
    value: 'AMBP3.SA',
    label: 'AMBP3.SA,  Ambipar Participações e Empreendimentos S.A.',
    name: 'Ambipar Participações e Empreendimentos S.A.',
    symbol: 'AMBP3.SA',
  },
  {
    value: 'AMBU-B.CO',
    label: 'AMBU-B.CO,  Ambu A/S',
    name: 'Ambu A/S',
    symbol: 'AMBU-B.CO',
  },
  {
    value: 'AMB.WA',
    label: 'AMB.WA,  Ambra S.A.',
    name: 'Ambra S.A.',
    symbol: 'AMB.WA',
  },
  {
    value: 'AMC.WA',
    label: 'AMC.WA,  Amica S.A.',
    name: 'Amica S.A.',
    symbol: 'AMC.WA',
  },
  {
    value: 'AMER3.SA',
    label: 'AMER3.SA,  Americanas S.A.',
    name: 'Americanas S.A.',
    symbol: 'AMER3.SA',
  },
  {
    value: 'AML.WA',
    label: 'AML.WA,  Alumetal S.A.',
    name: 'Alumetal S.A.',
    symbol: 'AML.WA',
  },
  {
    value: 'AMOT.TA',
    label: 'AMOT.TA,  Amot Investment Ltd',
    name: 'Amot Investment Ltd',
    symbol: 'AMOT.TA',
  },
  {
    value: 'AMRT.JK',
    label: 'AMRT.JK,  PT Sumber Alfaria Trijaya Tbk',
    name: 'PT Sumber Alfaria Trijaya Tbk',
    symbol: 'AMRT.JK',
  },
  {
    value: 'AMS.JO',
    label: 'AMS.JO,  Anglo American Platinum Limited',
    name: 'Anglo American Platinum Limited',
    symbol: 'AMS.JO',
  },
  {
    value: 'AMXL.MX',
    label: 'AMXL.MX,  América Móvil, S.A.B. de C.V.',
    name: 'América Móvil, S.A.B. de C.V.',
    symbol: 'AMXL.MX',
  },
  {
    value: 'ANA.MC',
    label: 'ANA.MC,  Acciona, S.A.',
    name: 'Acciona, S.A.',
    symbol: 'ANA.MC',
  },
  {
    value: 'ANDR.VI',
    label: 'ANDR.VI,  Andritz AG',
    name: 'Andritz AG',
    symbol: 'ANDR.VI',
  },
  {
    value: 'ANEB',
    label: 'ANEB,  Anebulo Pharmaceuticals, Inc.',
    name: 'Anebulo Pharmaceuticals, Inc.',
    symbol: 'ANEB',
  },
  {
    value: 'ANG.JO',
    label: 'ANG.JO,  AngloGold Ashanti Limited',
    name: 'AngloGold Ashanti Limited',
    symbol: 'ANG.JO',
  },
  {
    value: 'ANHYT.IS',
    label: 'ANHYT.IS,  Anadolu Hayat Emeklilik Anonim Sirketi',
    name: 'Anadolu Hayat Emeklilik Anonim Sirketi',
    symbol: 'ANHYT.IS',
  },
  {
    value: 'ANIM3.SA',
    label: 'ANIM3.SA,  Anima Holding S.A.',
    name: 'Anima Holding S.A.',
    symbol: 'ANIM3.SA',
  },
  {
    value: 'ANIM.MI',
    label: 'ANIM.MI,  Anima Holding S.p.A.',
    name: 'Anima Holding S.p.A.',
    symbol: 'ANIM.MI',
  },
  {
    value: 'ANSGR.IS',
    label: 'ANSGR.IS,  Anadolu Anonim Türk Sigorta Sirketi',
    name: 'Anadolu Anonim Türk Sigorta Sirketi',
    symbol: 'ANSGR.IS',
  },
  {
    value: 'ANTARCHILE.SN',
    label: 'ANTARCHILE.SN,  AntarChile S.A.',
    name: 'AntarChile S.A.',
    symbol: 'ANTARCHILE.SN',
  },
  {
    value: 'ANTM.JK',
    label: 'ANTM.JK,  PT Aneka Tambang Tbk',
    name: 'PT Aneka Tambang Tbk',
    symbol: 'ANTM.JK',
  },
  {
    value: 'AOT-R.BK',
    label: 'AOT-R.BK,  Airports of Thailand Public Company Limited',
    name: 'Airports of Thailand Public Company Limited',
    symbol: 'AOT-R.BK',
  },
  {
    value: 'AP.BK',
    label: 'AP.BK,  AP (Thailand) Public Company Limited',
    name: 'AP (Thailand) Public Company Limited',
    symbol: 'AP.BK',
  },
  {
    value: 'APER3.SA',
    label: 'APER3.SA,  Alper Consultoria e Corretora de Seguros S.A.',
    name: 'Alper Consultoria e Corretora de Seguros S.A.',
    symbol: 'APER3.SA',
  },
  {
    value: 'APGB',
    label: 'APGB,  Apollo Strategic Growth Capital II',
    name: 'Apollo Strategic Growth Capital II',
    symbol: 'APGB',
  },
  {
    value: 'APIC.JK',
    label: 'APIC.JK,  PT Pacific Strategic Financial Tbk',
    name: 'PT Pacific Strategic Financial Tbk',
    symbol: 'APIC.JK',
  },
  {
    value: 'APLN.JK',
    label: 'APLN.JK,  PT Agung Podomoro Land Tbk',
    name: 'PT Agung Podomoro Land Tbk',
    symbol: 'APLN.JK',
  },
  {
    value: 'APN.JO',
    label: 'APN.JO,  Aspen Pharmacare Holdings Limited',
    name: 'Aspen Pharmacare Holdings Limited',
    symbol: 'APN.JO',
  },
  {
    value: 'APPS.MC',
    label: 'APPS.MC,  Applus Services, S.A.',
    name: 'Applus Services, S.A.',
    symbol: 'APPS.MC',
  },
  {
    value: 'APR.WA',
    label: 'APR.WA,  Auto Partner SA',
    name: 'Auto Partner SA',
    symbol: 'APR.WA',
  },
  {
    value: 'APT.WA',
    label: 'APT.WA,  Apator S.A.',
    name: 'Apator S.A.',
    symbol: 'APT.WA',
  },
  {
    value: 'AQ.ST',
    label: 'AQ.ST,  AQ Group AB (publ)',
    name: 'AQ Group AB (publ)',
    symbol: 'AQ.ST',
  },
  {
    value: 'ARAD.TA',
    label: 'ARAD.TA,  Arad Investment & Industrial Development Ltd.',
    name: 'Arad Investment & Industrial Development Ltd.',
    symbol: 'ARAD.TA',
  },
  {
    value: 'ARA.MX',
    label: 'ARA.MX,  Consorcio ARA, S. A. B. de C. V.',
    name: 'Consorcio ARA, S. A. B. de C. V.',
    symbol: 'ARA.MX',
  },
  {
    value: 'ARCLK.IS',
    label: 'ARCLK.IS,  Arçelik Anonim Sirketi',
    name: 'Arçelik Anonim Sirketi',
    symbol: 'ARCLK.IS',
  },
  {
    value: 'ARG.NZ',
    label: 'ARG.NZ,  Argosy Property Limited',
    name: 'Argosy Property Limited',
    symbol: 'ARG.NZ',
  },
  {
    value: 'ARI.JO',
    label: 'ARI.JO,  African Rainbow Minerals Limited',
    name: 'African Rainbow Minerals Limited',
    symbol: 'ARI.JO',
  },
  {
    value: 'ARISE.ST',
    label: 'ARISE.ST,  Arise AB (publ)',
    name: 'Arise AB (publ)',
    symbol: 'ARISE.ST',
  },
  {
    value: 'ARML3.SA',
    label: 'ARML3.SA,  Armac Locação, Logística e Serviços S.A.',
    name: 'Armac Locação, Logística e Serviços S.A.',
    symbol: 'ARML3.SA',
  },
  {
    value: 'ARPT.TA',
    label: 'ARPT.TA,  Airport City Ltd.',
    name: 'Airport City Ltd.',
    symbol: 'ARPT.TA',
  },
  {
    value: 'ARTO.JK',
    label: 'ARTO.JK,  PT Bank Jago Tbk',
    name: 'PT Bank Jago Tbk',
    symbol: 'ARTO.JK',
  },
  {
    value: 'ARV.NZ',
    label: 'ARV.NZ,  Arvida Group Limited',
    name: 'Arvida Group Limited',
    symbol: 'ARV.NZ',
  },
  {
    value: 'ARZZ3.SA',
    label: 'ARZZ3.SA,  Arezzo Indústria e Comércio S.A.',
    name: 'Arezzo Indústria e Comércio S.A.',
    symbol: 'ARZZ3.SA',
  },
  {
    value: 'ASAI',
    label: 'ASAI,  Sendas Distribuidora S.A.',
    name: 'Sendas Distribuidora S.A.',
    symbol: 'ASAI',
  },
  {
    value: 'ASAI3.SA',
    label: 'ASAI3.SA,  Sendas Distribuidora S.A.',
    name: 'Sendas Distribuidora S.A.',
    symbol: 'ASAI3.SA',
  },
  {
    value: 'ASCN',
    label: 'ASCN,  Absecon Bancorp',
    name: 'Absecon Bancorp',
    symbol: 'ASCN',
  },
  {
    value: 'ASELS.IS',
    label: 'ASELS.IS,  Aselsan Elektronik Sanayi ve Ticaret Anonim Sirketi',
    name: 'Aselsan Elektronik Sanayi ve Ticaret Anonim Sirketi',
    symbol: 'ASELS.IS',
  },
  {
    value: 'ASE.WA',
    label: 'ASE.WA,  Asseco South Eastern Europe S.A.',
    name: 'Asseco South Eastern Europe S.A.',
    symbol: 'ASE.WA',
  },
  {
    value: 'ASHG.TA',
    label: 'ASHG.TA,  Ashtrom Group Ltd.',
    name: 'Ashtrom Group Ltd.',
    symbol: 'ASHG.TA',
  },
  {
    value: 'ASII.JK',
    label: 'ASII.JK,  PT Astra International Tbk',
    name: 'PT Astra International Tbk',
    symbol: 'ASII.JK',
  },
  {
    value: 'ASRI.JK',
    label: 'ASRI.JK,  PT Alam Sutera Realty Tbk',
    name: 'PT Alam Sutera Realty Tbk',
    symbol: 'ASRI.JK',
  },
  {
    value: 'ASSA.JK',
    label: 'ASSA.JK,  PT Adi Sarana Armada Tbk',
    name: 'PT Adi Sarana Armada Tbk',
    symbol: 'ASSA.JK',
  },
  {
    value: 'ASTK.OL',
    label: 'ASTK.OL,  Asetek A/S',
    name: 'Asetek A/S',
    symbol: 'ASTK.OL',
  },
  {
    value: 'ASURB.MX',
    label: 'ASURB.MX,  Grupo Aeroportuario del Sureste, S. A. B. de C. V.',
    name: 'Grupo Aeroportuario del Sureste, S. A. B. de C. V.',
    symbol: 'ASURB.MX',
  },
  {
    value: 'ASZ',
    label: 'ASZ,  Austerlitz Acquisition Corporation II',
    name: 'Austerlitz Acquisition Corporation II',
    symbol: 'ASZ',
  },
  {
    value: 'ATA',
    label: 'ATA,  Americas Technology Acquisition Corp.',
    name: 'Americas Technology Acquisition Corp.',
    symbol: 'ATA',
  },
  {
    value: 'BKKT',
    label: 'BKKT,  Bakkt Holdings, Inc.',
    name: 'Bakkt Holdings, Inc.',
    symbol: 'BKKT',
  },
  {
    value: 'ATG.L',
    label: 'ATG.L,  Auction Technology Group plc',
    name: 'Auction Technology Group plc',
    symbol: 'ATG.L',
  },
  {
    value: 'ATL.MI',
    label: 'ATL.MI,  Atlantia SpA',
    name: 'Atlantia SpA',
    symbol: 'ATL.MI',
  },
  {
    value: 'ATM.NZ',
    label: 'ATM.NZ,  The a2 Milk Company Limited',
    name: 'The a2 Milk Company Limited',
    symbol: 'ATM.NZ',
  },
  {
    value: 'ATNF',
    label: 'ATNF,  180 Life Sciences Corp.',
    name: '180 Life Sciences Corp.',
    symbol: 'ATNF',
  },
  {
    value: 'ATROB',
    label: 'ATROB,  Astronics Corporation',
    name: 'Astronics Corporation',
    symbol: 'ATROB',
  },
  {
    value: 'ATT.ST',
    label: 'ATT.ST,  Attendo AB (publ)',
    name: 'Attendo AB (publ)',
    symbol: 'ATT.ST',
  },
  {
    value: 'ATT.WA',
    label: 'ATT.WA,  Grupa Azoty S.A.',
    name: 'Grupa Azoty S.A.',
    symbol: 'ATT.WA',
  },
  {
    value: 'AU8U.SI',
    label: 'AU8U.SI,  CapitaLand China Trust',
    name: 'CapitaLand China Trust',
    symbol: 'AU8U.SI',
  },
  {
    value: 'AUCT-R.BK',
    label: 'AUCT-R.BK,  Union Auction Public Company Limited',
    name: 'Union Auction Public Company Limited',
    symbol: 'AUCT-R.BK',
  },
  {
    value: 'AUOTY',
    label: 'AUOTY,  AUO Corporation',
    name: 'AUO Corporation',
    symbol: 'AUOTY',
  },
  {
    value: 'AURA33.SA',
    label: 'AURA33.SA,  Aura Minerals Inc.',
    name: 'Aura Minerals Inc.',
    symbol: 'AURA33.SA',
  },
  {
    value: 'AUS',
    label: 'AUS,  Austerlitz Acquisition Corporation I',
    name: 'Austerlitz Acquisition Corporation I',
    symbol: 'AUS',
  },
  {
    value: 'AVI.JO',
    label: 'AVI.JO,  AVI Limited',
    name: 'AVI Limited',
    symbol: 'AVI.JO',
  },
  {
    value: 'AVNT.TO',
    label: 'AVNT.TO,  Avant Brands Inc.',
    name: 'Avant Brands Inc.',
    symbol: 'AVNT.TO',
  },
  {
    value: 'AWC-R.BK',
    label: 'AWC-R.BK,  Asset World Corp Public Company Limited',
    name: 'Asset World Corp Public Company Limited',
    symbol: 'AWC-R.BK',
  },
  {
    value: 'AWX.SI',
    label: 'AWX.SI,  AEM Holdings Ltd.',
    name: 'AEM Holdings Ltd.',
    symbol: 'AWX.SI',
  },
  {
    value: 'AXB.IL',
    label: 'AXB.IL,  Axis Bank Limited',
    name: 'Axis Bank Limited',
    symbol: 'AXB.IL',
  },
  {
    value: 'AXFO.ST',
    label: 'AXFO.ST,  Axfood AB (publ)',
    name: 'Axfood AB (publ)',
    symbol: 'AXFO.ST',
  },
  {
    value: 'AXTELCPO.MX',
    label: 'AXTELCPO.MX,  Axtel, S.A.B. de C.V.',
    name: 'Axtel, S.A.B. de C.V.',
    symbol: 'AXTELCPO.MX',
  },
  {
    value: 'AYGAZ.IS',
    label: 'AYGAZ.IS,  Aygaz A.S.',
    name: 'Aygaz A.S.',
    symbol: 'AYGAZ.IS',
  },
  {
    value: 'AYRWF',
    label: 'AYRWF,  Ayr Wellness Inc.',
    name: 'Ayr Wellness Inc.',
    symbol: 'AYRWF',
  },
  {
    value: 'AZA.ST',
    label: 'AZA.ST,  Avanza Bank Holding AB (publ)',
    name: 'Avanza Bank Holding AB (publ)',
    symbol: 'AZA.ST',
  },
  {
    value: 'AZELIO.ST',
    label: 'AZELIO.ST,  Azelio AB (publ)',
    name: 'Azelio AB (publ)',
    symbol: 'AZELIO.ST',
  },
  {
    value: 'AZRG.TA',
    label: 'AZRG.TA,  Azrieli Group Ltd',
    name: 'Azrieli Group Ltd',
    symbol: 'AZRG.TA',
  },
  {
    value: 'AZRM.TA',
    label: 'AZRM.TA,  Azorim-Investment, Development & Construction Co. Ltd',
    name: 'Azorim-Investment, Development & Construction Co. Ltd',
    symbol: 'AZRM.TA',
  },
  {
    value: 'AZTECACPO.MX',
    label: 'AZTECACPO.MX,  TV Azteca, S.A.B. de C.V.',
    name: 'TV Azteca, S.A.B. de C.V.',
    symbol: 'AZTECACPO.MX',
  },
  {
    value: 'AZUL4.SA',
    label: 'AZUL4.SA,  Azul S.A.',
    name: 'Azul S.A.',
    symbol: 'AZUL4.SA',
  },
  {
    value: 'B3SA3.SA',
    label: 'B3SA3.SA,  B3 S.A. - Brasil, Bolsa, Balcão',
    name: 'B3 S.A. - Brasil, Bolsa, Balcão',
    symbol: 'B3SA3.SA',
  },
  {
    value: 'BABP.JK',
    label: 'BABP.JK,  PT Bank MNC Internasional Tbk',
    name: 'PT Bank MNC Internasional Tbk',
    symbol: 'BABP.JK',
  },
  {
    value: 'RIGZ',
    label: 'RIGZ,  Viridi Bitcoin Miners ETF',
    name: 'Viridi Bitcoin Miners ETF',
    symbol: 'RIGZ',
  },
  {
    value: 'BTF',
    label: 'BTF,  Valkyrie Bitcoin Strategy ETF',
    name: 'Valkyrie Bitcoin Strategy ETF',
    symbol: 'BTF',
  },
  {
    value: 'NVTS',
    label: 'NVTS,  Navitas Semiconductor Corporation',
    name: 'Navitas Semiconductor Corporation',
    symbol: 'NVTS',
  },
  {
    value: 'ARIS',
    label: 'ARIS,  Aris Water Solutions, Inc.',
    name: 'Aris Water Solutions, Inc.',
    symbol: 'ARIS',
  },
  {
    value: 'BPT.L',
    label: 'BPT.L,  Bridgepoint Group plc',
    name: 'Bridgepoint Group plc',
    symbol: 'BPT.L',
  },
  {
    value: 'BRAG.TO',
    label: 'BRAG.TO,  Bragg Gaming Group Inc.',
    name: 'Bragg Gaming Group Inc.',
    symbol: 'BRAG.TO',
  },
  {
    value: 'BRBI11.SA',
    label: 'BRBI11.SA,  BR Advisory Partners Participações S.A.',
    name: 'BR Advisory Partners Participações S.A.',
    symbol: 'BRBI11.SA',
  },
  {
    value: 'BRCR11.SA',
    label:
      'BRCR11.SA,  Fundo de Investimento Imobiliário - FII BTG Pactual Corporate Office Fund',
    name: 'Fundo de Investimento Imobiliário - FII BTG Pactual Corporate Office Fund',
    symbol: 'BRCR11.SA',
  },
  {
    value: 'BRIG.ST',
    label: 'BRIG.ST,  Brighter AB (publ)',
    name: 'Brighter AB (publ)',
    symbol: 'BRIG.ST',
  },
  {
    value: 'BRIT3.SA',
    label: 'BRIT3.SA,  Brisanet Participações S.A.',
    name: 'Brisanet Participações S.A.',
    symbol: 'BRIT3.SA',
  },
  {
    value: 'TUN.L',
    label: 'TUN.L,  Tungsten West PLC',
    name: 'Tungsten West PLC',
    symbol: 'TUN.L',
  },
  {
    value: 'BRMS.JK',
    label: 'BRMS.JK,  PT Bumi Resources Minerals Tbk',
    name: 'PT Bumi Resources Minerals Tbk',
    symbol: 'BRMS.JK',
  },
  {
    value: 'ZWS',
    label: 'ZWS,  Zurn Elkay Water Solutions Corporation',
    name: 'Zurn Elkay Water Solutions Corporation',
    symbol: 'ZWS',
  },
  {
    value: 'GET',
    label:
      'GET,  Getnet Adquirencia e Servicos para Meios de Pagamento S.A. - Instituicão de Pagamento',
    name: 'Getnet Adquirencia e Servicos para Meios de Pagamento S.A. - Instituicão de Pagamento',
    symbol: 'GET',
  },
  {
    value: 'BBLN',
    label: 'BBLN,  Babylon Holdings Limited',
    name: 'Babylon Holdings Limited',
    symbol: 'BBLN',
  },
  {
    value: 'EBUS.AS',
    label: 'EBUS.AS,  Ebusco Holding N.V.',
    name: 'Ebusco Holding N.V.',
    symbol: 'EBUS.AS',
  },
  {
    value: 'BTB',
    label: 'BTB,  Bit Brother Limited',
    name: 'Bit Brother Limited',
    symbol: 'BTB',
  },
  {
    value: 'BVT.JO',
    label: 'BVT.JO,  The Bidvest Group Limited',
    name: 'The Bidvest Group Limited',
    symbol: 'BVT.JO',
  },
  {
    value: 'CBAV3.SA',
    label: 'CBAV3.SA,  Companhia Brasileira de Alumínio',
    name: 'Companhia Brasileira de Alumínio',
    symbol: 'CBAV3.SA',
  },
  {
    value: 'CBKD.IL',
    label: 'CBKD.IL,  Commercial International Bank (Egypt) S.A.E',
    name: 'Commercial International Bank (Egypt) S.A.E',
    symbol: 'CBKD.IL',
  },
  {
    value: 'CCSI',
    label: 'CCSI,  Consensus Cloud Solutions, Inc.',
    name: 'Consensus Cloud Solutions, Inc.',
    symbol: 'CCSI',
  },
  {
    value: 'CHRY.L',
    label: 'CHRY.L,  Chrysalis Investments Limited',
    name: 'Chrysalis Investments Limited',
    symbol: 'CHRY.L',
  },
  {
    value: 'BITO',
    label: 'BITO,  ProShares Bitcoin Strategy ETF',
    name: 'ProShares Bitcoin Strategy ETF',
    symbol: 'BITO',
  },
  {
    value: 'DTRTW',
    label: 'DTRTW,  DTRT Health Acquisition Corp.',
    name: 'DTRT Health Acquisition Corp.',
    symbol: 'DTRTW',
  },
  {
    value: 'CLSA3.SA',
    label: 'CLSA3.SA,  ClearSale S.A.',
    name: 'ClearSale S.A.',
    symbol: 'CLSA3.SA',
  },
  {
    value: 'CLS.JO',
    label: 'CLS.JO,  Clicks Group Limited',
    name: 'Clicks Group Limited',
    symbol: 'CLS.JO',
  },
  {
    value: 'CML.JO',
    label: 'CML.JO,  Coronation Fund Managers Limited',
    name: 'Coronation Fund Managers Limited',
    symbol: 'CML.JO',
  },
  {
    value: 'CNEY',
    label: 'CNEY,  CN Energy Group. Inc.',
    name: 'CN Energy Group. Inc.',
    symbol: 'CNEY',
  },
  {
    value: 'WE',
    label: 'WE,  WeWork Inc.',
    name: 'WeWork Inc.',
    symbol: 'WE',
  },
  {
    value: 'MRAI',
    label: 'MRAI,  Marpai, Inc.',
    name: 'Marpai, Inc.',
    symbol: 'MRAI',
  },
  {
    value: 'CPI.JO',
    label: 'CPI.JO,  Capitec Bank Holdings Limited',
    name: 'Capitec Bank Holdings Limited',
    symbol: 'CPI.JO',
  },
  {
    value: 'SABS',
    label: 'SABS,  SAB Biotherapeutics, Inc.',
    name: 'SAB Biotherapeutics, Inc.',
    symbol: 'SABS',
  },
  {
    value: 'SABSW',
    label: 'SABSW,  SAB Biotherapeutics, Inc.',
    name: 'SAB Biotherapeutics, Inc.',
    symbol: 'SABSW',
  },
  {
    value: 'CSB.JO',
    label: 'CSB.JO,  Cashbuild Limited',
    name: 'Cashbuild Limited',
    symbol: 'CSB.JO',
  },
  {
    value: 'IONQ',
    label: 'IONQ,  IonQ, Inc.',
    name: 'IonQ, Inc.',
    symbol: 'IONQ',
  },
  {
    value: 'KWAC',
    label: 'KWAC,  Kingswood Acquisition Corp.',
    name: 'Kingswood Acquisition Corp.',
    symbol: 'KWAC',
  },
  {
    value: 'CTPNV.AS',
    label: 'CTPNV.AS,  CTP N.V.',
    name: 'CTP N.V.',
    symbol: 'CTPNV.AS',
  },
  {
    value: 'CTX.WA',
    label: 'CTX.WA,  Captor Therapeutics Spolka Akcyjna',
    name: 'Captor Therapeutics Spolka Akcyjna',
    symbol: 'CTX.WA',
  },
  {
    value: 'CURY3.SA',
    label: 'CURY3.SA,  Cury Construtora e Incorporadora S.A.',
    name: 'Cury Construtora e Incorporadora S.A.',
    symbol: 'CURY3.SA',
  },
  {
    value: 'DARK.L',
    label: 'DARK.L,  Darktrace plc',
    name: 'Darktrace plc',
    symbol: 'DARK.L',
  },
  {
    value: '1948.HK',
    label: '1948.HK,  Uju Holding Limited',
    name: 'Uju Holding Limited',
    symbol: '1948.HK',
  },
  {
    value: 'ACID.IL',
    label: 'ACID.IL,  Acer Incorporated',
    name: 'Acer Incorporated',
    symbol: 'ACID.IL',
  },
  {
    value: 'ACT.WA',
    label: 'ACT.WA,  Action S.A.',
    name: 'Action S.A.',
    symbol: 'ACT.WA',
  },
  {
    value: 'ACROUD.ST',
    label: 'ACROUD.ST,  Acroud AB (publ)',
    name: 'Acroud AB (publ)',
    symbol: 'ACROUD.ST',
  },
  {
    value: '3260.TWO',
    label: '3260.TWO,  ADATA Technology Co., Ltd.',
    name: 'ADATA Technology Co., Ltd.',
    symbol: '3260.TWO',
  },
  {
    value: '9912.TW',
    label: '9912.TW,  Associated Industries China, Inc.',
    name: 'Associated Industries China, Inc.',
    symbol: '9912.TW',
  },
  {
    value: 'ALL.WA',
    label: 'ALL.WA,  Ailleron S.A.',
    name: 'Ailleron S.A.',
    symbol: 'ALL.WA',
  },
  {
    value: 'ALK.MI',
    label: 'ALK.MI,  Alkemy S.p.A.',
    name: 'Alkemy S.p.A.',
    symbol: 'ALK.MI',
  },
  {
    value: '4719.T',
    label: '4719.T,  Alpha Systems Inc.',
    name: 'Alpha Systems Inc.',
    symbol: '4719.T',
  },
  {
    value: 'ALC.MC',
    label: 'ALC.MC,  Altia Consultores, S.A.',
    name: 'Altia Consultores, S.A.',
    symbol: 'ALC.MC',
  },
  {
    value: 'AVV.JO',
    label: 'AVV.JO,  Alviva Holdings Limited',
    name: 'Alviva Holdings Limited',
    symbol: 'AVV.JO',
  },
  {
    value: 'AMS.MC',
    label: 'AMS.MC,  Amadeus IT Group, S.A.',
    name: 'Amadeus IT Group, S.A.',
    symbol: 'AMS.MC',
  },
  {
    value: 'GOJXN.AS',
    label: 'GOJXN.AS,  GeoJunxion N.V.',
    name: 'GeoJunxion N.V.',
    symbol: 'GOJXN.AS',
  },
  {
    value: '3046.TW',
    label: '3046.TW,  Aopen Inc.',
    name: 'Aopen Inc.',
    symbol: '3046.TW',
  },
  {
    value: '3169.TWO',
    label: '3169.TWO,  ASIX Electronics Corporation',
    name: 'ASIX Electronics Corporation',
    symbol: '3169.TWO',
  },
  {
    value: 'AEXAY',
    label: 'AEXAY,  Atos SE',
    name: 'Atos SE',
    symbol: 'AEXAY',
  },
  {
    value: '3964.T',
    label: '3964.T,  Aucnet Inc.',
    name: 'Aucnet Inc.',
    symbol: '3964.T',
  },
  {
    value: 'AVEN.ST',
    label: 'AVEN.ST,  Avensia AB (publ)',
    name: 'Avensia AB (publ)',
    symbol: 'AVEN.ST',
  },
  {
    value: 'B3.ST',
    label: 'B3.ST,  B3 Consulting Group AB (publ)',
    name: 'B3 Consulting Group AB (publ)',
    symbol: 'B3.ST',
  },
  {
    value: '4481.T',
    label: '4481.T,  BASE CO., LTD.',
    name: 'BASE CO., LTD.',
    symbol: '4481.T',
  },
  {
    value: '3981.T',
    label: '3981.T,  Beaglee Inc.',
    name: 'Beaglee Inc.',
    symbol: '3981.T',
  },
  {
    value: '600410.SS',
    label: '600410.SS,  Beijing Teamsun Technology Co.,Ltd.',
    name: 'Beijing Teamsun Technology Co.,Ltd.',
    symbol: '600410.SS',
  },
  {
    value: '600640.SS',
    label: '600640.SS,  Besttone Holding Co.,Ltd',
    name: 'Besttone Holding Co.,Ltd',
    symbol: '600640.SS',
  },
  {
    value: 'BSS.F',
    label: 'BSS.F,  Beta Systems Software AG',
    name: 'Beta Systems Software AG',
    symbol: 'BSS.F',
  },
  {
    value: 'BRE2.ST',
    label: 'BRE2.ST,  Bredband2 i Skandinavien AB (publ)',
    name: 'Bredband2 i Skandinavien AB (publ)',
    symbol: 'BRE2.ST',
  },
  {
    value: 'BRTHY',
    label: 'BRTHY,  Brother Industries, Ltd.',
    name: 'Brother Industries, Ltd.',
    symbol: 'BRTHY',
  },
  {
    value: '4828.T',
    label: '4828.T,  Business Engineering Corporation',
    name: 'Business Engineering Corporation',
    symbol: '4828.T',
  },
  {
    value: '5258.TW',
    label: '5258.TW,  Castles Technology Co., Ltd.',
    name: 'Castles Technology Co., Ltd.',
    symbol: '5258.TW',
  },
  {
    value: 'CELL.MI',
    label: 'CELL.MI,  Cellularline S.p.A.',
    name: 'Cellularline S.p.A.',
    symbol: 'CELL.MI',
  },
  {
    value: 'COLUM.CO',
    label: 'COLUM.CO,  Columbus A/S',
    name: 'Columbus A/S',
    symbol: 'COLUM.CO',
  },
  {
    value: 'CSVI',
    label: 'CSVI,  Computer Services, Inc.',
    name: 'Computer Services, Inc.',
    symbol: 'CSVI',
  },
  {
    value: '3816.T',
    label: '3816.T,  Daiwa Computer Co., Ltd.',
    name: 'Daiwa Computer Co., Ltd.',
    symbol: '3816.T',
  },
  {
    value: 'DEVP-B.ST',
    label: 'DEVP-B.ST,  DevPort AB (publ)',
    name: 'DevPort AB (publ)',
    symbol: 'DEVP-B.ST',
  },
  {
    value: 'DHH.MI',
    label: 'DHH.MI,  Dominion Hosting Holding S.p.A.',
    name: 'Dominion Hosting Holding S.p.A.',
    symbol: 'DHH.MI',
  },
  {
    value: '000555.SZ',
    label: '000555.SZ,  Digital China Information Service Company Ltd.',
    name: 'Digital China Information Service Company Ltd.',
    symbol: '000555.SZ',
  },
  {
    value: 'DMMX.JK',
    label: 'DMMX.JK,  PT Digital Mediatama Maxima Tbk',
    name: 'PT Digital Mediatama Maxima Tbk',
    symbol: 'DMMX.JK',
  },
  {
    value: 'DM.MI',
    label: 'DM.MI,  Digital Magics S.p.A.',
    name: 'Digital Magics S.p.A.',
    symbol: 'DM.MI',
  },
  {
    value: 'DNA',
    label: 'DNA,  Ginkgo Bioworks Holdings, Inc.',
    name: 'Ginkgo Bioworks Holdings, Inc.',
    symbol: 'DNA',
  },
  {
    value: 'DIG.MI',
    label: 'DIG.MI,  Digital360 S.p.A.',
    name: 'Digital360 S.p.A.',
    symbol: 'DIG.MI',
  },
  {
    value: 'DGT.MI',
    label: 'DGT.MI,  DigiTouch S.p.A.',
    name: 'DigiTouch S.p.A.',
    symbol: 'DGT.MI',
  },
  {
    value: 'ELAN-B.ST',
    label: 'ELAN-B.ST,  Elanders AB (publ)',
    name: 'Elanders AB (publ)',
    symbol: 'ELAN-B.ST',
  },
  {
    value: 'EMBRAC-B.ST',
    label: 'EMBRAC-B.ST,  Embracer Group AB (publ)',
    name: 'Embracer Group AB (publ)',
    symbol: 'EMBRAC-B.ST',
  },
  {
    value: 'EOH.JO',
    label: 'EOH.JO,  EOH Holdings Limited',
    name: 'EOH Holdings Limited',
    symbol: 'EOH.JO',
  },
  {
    value: 'EPSIL.AT',
    label: 'EPSIL.AT,  Epsilon Net S.A.',
    name: 'Epsilon Net S.A.',
    symbol: 'EPSIL.AT',
  },
  {
    value: 'ERD.NZ',
    label: 'ERD.NZ,  EROAD Limited',
    name: 'EROAD Limited',
    symbol: 'ERD.NZ',
  },
  {
    value: 'ETH.MI',
    label: 'ETH.MI,  Eurotech S.p.A.',
    name: 'Eurotech S.p.A.',
    symbol: 'ETH.MI',
  },
  {
    value: 'EWRK.ST',
    label: 'EWRK.ST,  Ework Group AB (publ)',
    name: 'Ework Group AB (publ)',
    symbol: 'EWRK.ST',
  },
  {
    value: 'DRD.JO',
    label: 'DRD.JO,  DRDGOLD Limited',
    name: 'DRDGOLD Limited',
    symbol: 'DRD.JO',
  },
  {
    value: 'FOOT-PREF.ST',
    label: 'FOOT-PREF.ST,  Footway Group AB (publ)',
    name: 'Footway Group AB (publ)',
    symbol: 'FOOT-PREF.ST',
  },
  {
    value: 'FRAM-B.ST',
    label: 'FRAM-B.ST,  Fram Skandinavien AB',
    name: 'Fram Skandinavien AB',
    symbol: 'FRAM-B.ST',
  },
  {
    value: 'DSY.JO',
    label: 'DSY.JO,  Discovery Limited',
    name: 'Discovery Limited',
    symbol: 'DSY.JO',
  },
  {
    value: 'GKO.V',
    label: 'GKO.V,  Geekco Technologies Corporation',
    name: 'Geekco Technologies Corporation',
    symbol: 'GKO.V',
  },
  {
    value: '6047.T',
    label: '6047.T,  Gunosy Inc.',
    name: 'Gunosy Inc.',
    symbol: '6047.T',
  },
  {
    value: 'HTJ.F',
    label: 'HTJ.F,  HTC Corporation',
    name: 'HTC Corporation',
    symbol: 'HTJ.F',
  },
  {
    value: '6573.TW',
    label: '6573.TW,  HY Electronic (Cayman) Limited',
    name: 'HY Electronic (Cayman) Limited',
    symbol: '6573.TW',
  },
  {
    value: 'IAR-B.ST',
    label: 'IAR-B.ST,  IAR Systems Group AB (publ)',
    name: 'IAR Systems Group AB (publ)',
    symbol: 'IAR-B.ST',
  },
  {
    value: 'IFI.WA',
    label: 'IFI.WA,  Ifirma SA',
    name: 'Ifirma SA',
    symbol: 'IFI.WA',
  },
  {
    value: 'IE2A.F',
    label: 'IE2A.F,  Internet Thailand Public Company Limited',
    name: 'Internet Thailand Public Company Limited',
    symbol: 'IE2A.F',
  },
  {
    value: 'ITD.MI',
    label: 'ITD.MI,  Intred S.p.A.',
    name: 'Intred S.p.A.',
    symbol: 'ITD.MI',
  },
  {
    value: 'ALITL.PA',
    label: 'ALITL.PA,  IT Link SA',
    name: 'IT Link SA',
    symbol: 'ALITL.PA',
  },
  {
    value: 'JLT.ST',
    label: 'JLT.ST,  JLT Mobile Computers AB (publ)',
    name: 'JLT Mobile Computers AB (publ)',
    symbol: 'JLT.ST',
  },
  {
    value: '6552.TW',
    label: '6552.TW,  JMC Electronics Co., Ltd.',
    name: 'JMC Electronics Co., Ltd.',
    symbol: '6552.TW',
  },
  {
    value: 'K2H.WA',
    label: 'K2H.WA,  K2 Holding S.A.',
    name: 'K2 Holding S.A.',
    symbol: 'K2H.WA',
  },
  {
    value: '3904.T',
    label: '3904.T,  KAYAC Inc.',
    name: 'KAYAC Inc.',
    symbol: '3904.T',
  },
  {
    value: '6715.TW',
    label: '6715.TW,  Lintes Technology Co., Ltd.',
    name: 'Lintes Technology Co., Ltd.',
    symbol: '6715.TW',
  },
  {
    value: 'MAGI.ST',
    label: 'MAGI.ST,  MAG Interactive AB (publ)',
    name: 'MAG Interactive AB (publ)',
    symbol: 'MAGI.ST',
  },
  {
    value: 'GROW.MI',
    label: 'GROW.MI,  Growens S.p.A.',
    name: 'Growens S.p.A.',
    symbol: 'GROW.MI',
  },
  {
    value: 'MIX.JO',
    label: 'MIX.JO,  MiX Telematics Limited',
    name: 'MiX Telematics Limited',
    symbol: 'MIX.JO',
  },
  {
    value: '3912.T',
    label: '3912.T,  Mobile Factory, Inc.',
    name: 'Mobile Factory, Inc.',
    symbol: '3912.T',
  },
  {
    value: '9616.HK',
    label: '9616.HK,  Neusoft Education Technology Co. Limited',
    name: 'Neusoft Education Technology Co. Limited',
    symbol: '9616.HK',
  },
  {
    value: 'NRST.MI',
    label: 'NRST.MI,  Neurosoft Software Production S.A.',
    name: 'Neurosoft Software Production S.A.',
    symbol: 'NRST.MI',
  },
  {
    value: 'QY1.F',
    label: 'QY1.F,  NextGen Healthcare, Inc.',
    name: 'NextGen Healthcare, Inc.',
    symbol: 'QY1.F',
  },
  {
    value: 'NRILY',
    label: 'NRILY,  Nomura Research Institute, Ltd.',
    name: 'Nomura Research Institute, Ltd.',
    symbol: 'NRILY',
  },
  {
    value: 'NVI.V',
    label: 'NVI.V,  Novra Technologies Inc.',
    name: 'Novra Technologies Inc.',
    symbol: 'NVI.V',
  },
  {
    value: 'OEX.WA',
    label: 'OEX.WA,  OEX S.A.',
    name: 'OEX S.A.',
    symbol: 'OEX.WA',
  },
  {
    value: 'ONVC',
    label: 'ONVC,  Online Vacation Center Holdings Corp.',
    name: 'Online Vacation Center Holdings Corp.',
    symbol: 'ONVC',
  },
  {
    value: 'ORIGO.IC',
    label: 'ORIGO.IC,  Origo hf.',
    name: 'Origo hf.',
    symbol: 'ORIGO.IC',
  },
  {
    value: 'TR9.F',
    label: 'TR9.F,  OTRS AG',
    name: 'OTRS AG',
    symbol: 'TR9.F',
  },
  {
    value: '3559.T',
    label: '3559.T,  p-ban.com Corp.',
    name: 'p-ban.com Corp.',
    symbol: '3559.T',
  },
  {
    value: 'PIERCE.ST',
    label: 'PIERCE.ST,  Pierce Group AB (publ)',
    name: 'Pierce Group AB (publ)',
    symbol: 'PIERCE.ST',
  },
  {
    value: 'PRCO-B.ST',
    label: 'PRCO-B.ST,  Precio Fishbone AB (publ)',
    name: 'Precio Fishbone AB (publ)',
    symbol: 'PRCO-B.ST',
  },
  {
    value: 'PRM.MI',
    label: 'PRM.MI,  Prismi S.p.A.',
    name: 'Prismi S.p.A.',
    symbol: 'PRM.MI',
  },
  {
    value: 'PACT.ST',
    label: 'PACT.ST,  Proact IT Group AB (publ)',
    name: 'Proact IT Group AB (publ)',
    symbol: 'PACT.ST',
  },
  {
    value: 'YK6B.F',
    label: 'YK6B.F,  GlobalData Plc',
    name: 'GlobalData Plc',
    symbol: 'YK6B.F',
  },
  {
    value: 'DIGI.JK',
    label: 'DIGI.JK,  PT Arkadia Digital Media Tbk',
    name: 'PT Arkadia Digital Media Tbk',
    symbol: 'DIGI.JK',
  },
  {
    value: 'ASGR.JK',
    label: 'ASGR.JK,  PT Astra Graphia Tbk',
    name: 'PT Astra Graphia Tbk',
    symbol: 'ASGR.JK',
  },
  {
    value: 'BLUE.JK',
    label: 'BLUE.JK,  PT Berkah Prima Perkasa Tbk',
    name: 'PT Berkah Prima Perkasa Tbk',
    symbol: 'BLUE.JK',
  },
  {
    value: 'CASH.JK',
    label: 'CASH.JK,  PT Cashlez Worldwide Indonesia Tbk',
    name: 'PT Cashlez Worldwide Indonesia Tbk',
    symbol: 'CASH.JK',
  },
  {
    value: 'DIVA.JK',
    label: 'DIVA.JK,  PT Distribusi Voucher Nusantara Tbk',
    name: 'PT Distribusi Voucher Nusantara Tbk',
    symbol: 'DIVA.JK',
  },
  {
    value: 'GLVA.JK',
    label: 'GLVA.JK,  PT Galva Technologies Tbk',
    name: 'PT Galva Technologies Tbk',
    symbol: 'GLVA.JK',
  },
  {
    value: 'GHON.JK',
    label: 'GHON.JK,  PT Gihon Telekomunikasi Indonesia Tbk',
    name: 'PT Gihon Telekomunikasi Indonesia Tbk',
    symbol: 'GHON.JK',
  },
  {
    value: 'HDIT.JK',
    label: 'HDIT.JK,  PT Hensel Davest Indonesia Tbk',
    name: 'PT Hensel Davest Indonesia Tbk',
    symbol: 'HDIT.JK',
  },
  {
    value: 'TECH.JK',
    label: 'TECH.JK,  PT Indosterling Technomedia TBK',
    name: 'PT Indosterling Technomedia TBK',
    symbol: 'TECH.JK',
  },
  {
    value: 'JAST.JK',
    label: 'JAST.JK,  PT. Jasnita Telekomindo Tbk',
    name: 'PT. Jasnita Telekomindo Tbk',
    symbol: 'JAST.JK',
  },
  {
    value: 'MLPL.JK',
    label: 'MLPL.JK,  PT Multipolar Tbk',
    name: 'PT Multipolar Tbk',
    symbol: 'MLPL.JK',
  },
  {
    value: 'NFCX.JK',
    label: 'NFCX.JK,  PT NFC Indonesia Tbk',
    name: 'PT NFC Indonesia Tbk',
    symbol: 'NFCX.JK',
  },
  {
    value: 'TFAS.JK',
    label: 'TFAS.JK,  PT Telefast Indonesia Tbk',
    name: 'PT Telefast Indonesia Tbk',
    symbol: 'TFAS.JK',
  },
  {
    value: 'ZYRX.JK',
    label: 'ZYRX.JK,  PT Zyrexindo Mandiri Buana Tbk',
    name: 'PT Zyrexindo Mandiri Buana Tbk',
    symbol: 'ZYRX.JK',
  },
  {
    value: 'QUEST.AT',
    label: 'QUEST.AT,  Quest Holdings S.A.',
    name: 'Quest Holdings S.A.',
    symbol: 'QUEST.AT',
  },
  {
    value: 'QUIK.TA',
    label: 'QUIK.TA,  Quik Super Online Ltd.',
    name: 'Quik Super Online Ltd.',
    symbol: 'QUIK.TA',
  },
  {
    value: 'QIS.V',
    label: 'QIS.V,  Quorum Information Technologies Inc.',
    name: 'Quorum Information Technologies Inc.',
    symbol: 'QIS.V',
  },
  {
    value: 'R22.WA',
    label: 'R22.WA,  R22 S.A.',
    name: 'R22 S.A.',
    symbol: 'R22.WA',
  },
  {
    value: 'RZR.TA',
    label: 'RZR.TA,  Razor Labs',
    name: 'Razor Labs',
    symbol: 'RZR.TA',
  },
  {
    value: 'RKET.HM',
    label: 'RKET.HM,  Rocket Internet SE',
    name: 'Rocket Internet SE',
    symbol: 'RKET.HM',
  },
  {
    value: 'SSUN.F',
    label: 'SSUN.F,  Samsung Electronics Co., Ltd.',
    name: 'Samsung Electronics Co., Ltd.',
    symbol: 'SSUN.F',
  },
  {
    value: 'SDS.ST',
    label: 'SDS.ST,  Seamless Distribution Systems AB (publ)',
    name: 'Seamless Distribution Systems AB (publ)',
    symbol: 'SDS.ST',
  },
  {
    value: 'SES.WA',
    label: 'SES.WA,  Sescom S.A.',
    name: 'Sescom S.A.',
    symbol: 'SES.WA',
  },
  {
    value: '3976.T',
    label: '3976.T,  SHANON Inc.',
    name: 'SHANON Inc.',
    symbol: '3976.T',
  },
  {
    value: '300052.SZ',
    label: '300052.SZ,  Shenzhen Zqgame Co., Ltd',
    name: 'Shenzhen Zqgame Co., Ltd',
    symbol: '300052.SZ',
  },
  {
    value: 'YSFT.MI',
    label: 'YSFT.MI,  Softec S.p.A.',
    name: 'Softec S.p.A.',
    symbol: 'YSFT.MI',
  },
  {
    value: 'SOF-B.ST',
    label: 'SOF-B.ST,  Softronic AB (publ)',
    name: 'Softronic AB (publ)',
    symbol: 'SOF-B.ST',
  },
  {
    value: 'STORY-B.ST',
    label: 'STORY-B.ST,  Storytel AB (publ)',
    name: 'Storytel AB (publ)',
    symbol: 'STORY-B.ST',
  },
  {
    value: 'STRAX.ST',
    label: 'STRAX.ST,  Strax AB (publ)',
    name: 'Strax AB (publ)',
    symbol: 'STRAX.ST',
  },
  {
    value: '3436.T',
    label: '3436.T,  Sumco Corporation',
    name: 'Sumco Corporation',
    symbol: '3436.T',
  },
  {
    value: 'SYZ.TO',
    label: 'SYZ.TO,  Sylogist Ltd.',
    name: 'Sylogist Ltd.',
    symbol: 'SYZ.TO',
  },
  {
    value: 'IDAI',
    label: 'IDAI,  T Stamp Inc.',
    name: 'T Stamp Inc.',
    symbol: 'IDAI',
  },
  {
    value: 'TBL.WA',
    label: 'TBL.WA,  T-Bull S.A.',
    name: 'T-Bull S.A.',
    symbol: 'TBL.WA',
  },
  {
    value: 'TTDKY',
    label: 'TTDKY,  TDK Corporation',
    name: 'TDK Corporation',
    symbol: 'TTDKY',
  },
  {
    value: '4967.TW',
    label: '4967.TW,  Team Group Inc.',
    name: 'Team Group Inc.',
    symbol: '4967.TW',
  },
  {
    value: 'TKG.JO',
    label: 'TKG.JO,  Telkom SA SOC Limited',
    name: 'Telkom SA SOC Limited',
    symbol: 'TKG.JO',
  },
  {
    value: 'TBX.F',
    label: 'TBX.F,  tick Trading Software AG',
    name: 'tick Trading Software AG',
    symbol: 'TBX.F',
  },
  {
    value: 'TTZ.V',
    label: 'TTZ.V,  Total Telcom Inc.',
    name: 'Total Telcom Inc.',
    symbol: 'TTZ.V',
  },
  {
    value: 'TRANS.ST',
    label: 'TRANS.ST,  Transtema Group AB',
    name: 'Transtema Group AB',
    symbol: 'TRANS.ST',
  },
  {
    value: 'TB.MI',
    label: 'TB.MI,  Triboo S.p.A.',
    name: 'Triboo S.p.A.',
    symbol: 'TB.MI',
  },
  {
    value: '3798.T',
    label: '3798.T,  ULS Group, Inc.',
    name: 'ULS Group, Inc.',
    symbol: '3798.T',
  },
  {
    value: '3979.T',
    label: '3979.T,  Uluru.Co.,Ltd.',
    name: 'Uluru.Co.,Ltd.',
    symbol: '3979.T',
  },
  {
    value: '558.SI',
    label: '558.SI,  UMS Holdings Limited',
    name: 'UMS Holdings Limited',
    symbol: '558.SI',
  },
  {
    value: '9418.T',
    label: '9418.T,  USEN-NEXT HOLDINGS Co.,Ltd.',
    name: 'USEN-NEXT HOLDINGS Co.,Ltd.',
    symbol: '9418.T',
  },
  {
    value: 'VGL.NZ',
    label: 'VGL.NZ,  Vista Group International Limited',
    name: 'Vista Group International Limited',
    symbol: 'VGL.NZ',
  },
  {
    value: 'VXTR.V',
    label: 'VXTR.V,  Voxtur Analytics Corp.',
    name: 'Voxtur Analytics Corp.',
    symbol: 'VXTR.V',
  },
  {
    value: 'WAYS.ST',
    label: 'WAYS.ST,  Waystream Holding AB (publ)',
    name: 'Waystream Holding AB (publ)',
    symbol: 'WAYS.ST',
  },
  {
    value: 'WPAY.ST',
    label: 'WPAY.ST,  Westpay AB',
    name: 'Westpay AB',
    symbol: 'WPAY.ST',
  },
  {
    value: 'WIIT.MI',
    label: 'WIIT.MI,  Wiit S.p.A.',
    name: 'Wiit S.p.A.',
    symbol: 'WIIT.MI',
  },
  {
    value: '3231.TW',
    label: '3231.TW,  Wistron Corporation',
    name: 'Wistron Corporation',
    symbol: '3231.TW',
  },
  {
    value: 'DTC',
    label: 'DTC,  Solo Brands, Inc.',
    name: 'Solo Brands, Inc.',
    symbol: 'DTC',
  },
  {
    value: 'RIET',
    label: 'RIET,  Hoya Capital High Dividend Yield ETF',
    name: 'Hoya Capital High Dividend Yield ETF',
    symbol: 'RIET',
  },
  {
    value: 'EAST.ST',
    label: 'EAST.ST,  Eastnine AB (publ)',
    name: 'Eastnine AB (publ)',
    symbol: 'EAST.ST',
  },
  {
    value: 'EFGD.IL',
    label: 'EFGD.IL,  EFG-Hermes Holding S.A.E',
    name: 'EFG-Hermes Holding S.A.E',
    symbol: 'EFGD.IL',
  },
  {
    value: 'EFUEL.OL',
    label: 'EFUEL.OL,  Everfuel A/S',
    name: 'Everfuel A/S',
    symbol: 'EFUEL.OL',
  },
  {
    value: 'QNRX',
    label: 'QNRX,  Quoin Pharmaceuticals, Ltd.',
    name: 'Quoin Pharmaceuticals, Ltd.',
    symbol: 'QNRX',
  },
  {
    value: 'BTTX',
    label: 'BTTX,  Better Therapeutics, Inc.',
    name: 'Better Therapeutics, Inc.',
    symbol: 'BTTX',
  },
  {
    value: 'CYN',
    label: 'CYN,  Cyngn Inc.',
    name: 'Cyngn Inc.',
    symbol: 'CYN',
  },
  {
    value: 'EMTK.JK',
    label: 'EMTK.JK,  PT Elang Mahkota Teknologi Tbk',
    name: 'PT Elang Mahkota Teknologi Tbk',
    symbol: 'EMTK.JK',
  },
  {
    value: 'ENEA.ST',
    label: 'ENEA.ST,  Enea AB (publ)',
    name: 'Enea AB (publ)',
    symbol: 'ENEA.ST',
  },
  {
    value: 'ENQ.ST',
    label: 'ENQ.ST,  EnQuest PLC',
    name: 'EnQuest PLC',
    symbol: 'ENQ.ST',
  },
  {
    value: 'EQU.JO',
    label: 'EQU.JO,  Equites Property Fund Limited',
    name: 'Equites Property Fund Limited',
    symbol: 'EQU.JO',
  },
  {
    value: 'ERB.WA',
    label: 'ERB.WA,  Erbud S.A.',
    name: 'Erbud S.A.',
    symbol: 'ERB.WA',
  },
  {
    value: 'ERGO',
    label: 'ERGO,  Entia Biosciences, Inc.',
    name: 'Entia Biosciences, Inc.',
    symbol: 'ERGO',
  },
  {
    value: 'EXPRS2.ST',
    label: 'EXPRS2.ST,  ExpreS2ion Biotech Holding AB (publ)',
    name: 'ExpreS2ion Biotech Holding AB (publ)',
    symbol: 'EXPRS2.ST',
  },
  {
    value: 'EXX.JO',
    label: 'EXX.JO,  Exxaro Resources Limited',
    name: 'Exxaro Resources Limited',
    symbol: 'EXX.JO',
  },
  {
    value: 'MLKN',
    label: 'MLKN,  MillerKnoll, Inc.',
    name: 'MillerKnoll, Inc.',
    symbol: 'MLKN',
  },
  {
    value: 'LTRY',
    label: 'LTRY,  Lottery.com Inc.',
    name: 'Lottery.com Inc.',
    symbol: 'LTRY',
  },
  {
    value: 'LTRYW',
    label: 'LTRYW,  Lottery.com Inc.',
    name: 'Lottery.com Inc.',
    symbol: 'LTRYW',
  },
  {
    value: 'FSNB',
    label: 'FSNB,  Fusion Acquisition Corp. II',
    name: 'Fusion Acquisition Corp. II',
    symbol: 'FSNB',
  },
  {
    value: 'KD',
    label: 'KD,  Kyndryl Holdings, Inc.',
    name: 'Kyndryl Holdings, Inc.',
    symbol: 'KD',
  },
  {
    value: 'SLNH',
    label: 'SLNH,  Soluna Holdings, Inc.',
    name: 'Soluna Holdings, Inc.',
    symbol: 'SLNH',
  },
  {
    value: 'MULN',
    label: 'MULN,  Mullen Automotive, Inc.',
    name: 'Mullen Automotive, Inc.',
    symbol: 'MULN',
  },
  {
    value: 'AUR',
    label: 'AUR,  Aurora Innovation, Inc.',
    name: 'Aurora Innovation, Inc.',
    symbol: 'AUR',
  },
  {
    value: 'AUROW',
    label: 'AUROW,  Aurora Innovation, Inc.',
    name: 'Aurora Innovation, Inc.',
    symbol: 'AUROW',
  },
  {
    value: 'TSVT',
    label: 'TSVT,  2seventy bio, Inc.',
    name: '2seventy bio, Inc.',
    symbol: 'TSVT',
  },
  {
    value: 'CIAN',
    label: 'CIAN,  Cian PLC',
    name: 'Cian PLC',
    symbol: 'CIAN',
  },
  {
    value: 'NRDS',
    label: 'NRDS,  NerdWallet, Inc.',
    name: 'NerdWallet, Inc.',
    symbol: 'NRDS',
  },
  {
    value: 'AUTO.OL',
    label: 'AUTO.OL,  AutoStore Holdings Ltd.',
    name: 'AutoStore Holdings Ltd.',
    symbol: 'AUTO.OL',
  },
  {
    value: 'FLNC',
    label: 'FLNC,  Fluence Energy, Inc.',
    name: 'Fluence Energy, Inc.',
    symbol: 'FLNC',
  },
  {
    value: 'ARQQ',
    label: 'ARQQ,  Arqit Quantum Inc.',
    name: 'Arqit Quantum Inc.',
    symbol: 'ARQQ',
  },
  {
    value: 'DRUG',
    label: 'DRUG,  Bright Minds Biosciences Inc.',
    name: 'Bright Minds Biosciences Inc.',
    symbol: 'DRUG',
  },
  {
    value: 'KPRX',
    label: 'KPRX,  Kiora Pharmaceuticals, Inc.',
    name: 'Kiora Pharmaceuticals, Inc.',
    symbol: 'KPRX',
  },
  {
    value: 'TCBX',
    label: 'TCBX,  Third Coast Bancshares, Inc.',
    name: 'Third Coast Bancshares, Inc.',
    symbol: 'TCBX',
  },
  {
    value: 'EXFY',
    label: 'EXFY,  Expensify, Inc.',
    name: 'Expensify, Inc.',
    symbol: 'EXFY',
  },
  {
    value: 'RIVN',
    label: 'RIVN,  Rivian Automotive, Inc.',
    name: 'Rivian Automotive, Inc.',
    symbol: 'RIVN',
  },
  {
    value: 'TMVWY',
    label: 'TMVWY,  TeamViewer AG',
    name: 'TeamViewer AG',
    symbol: 'TMVWY',
  },
  {
    value: 'WEAV',
    label: 'WEAV,  Weave Communications, Inc.',
    name: 'Weave Communications, Inc.',
    symbol: 'WEAV',
  },
  {
    value: 'FBRT',
    label: 'FBRT,  Franklin BSP Realty Trust, Inc.',
    name: 'Franklin BSP Realty Trust, Inc.',
    symbol: 'FBRT',
  },
  {
    value: 'DERM',
    label: 'DERM,  Journey Medical Corporation',
    name: 'Journey Medical Corporation',
    symbol: 'DERM',
  },
  {
    value: 'ROCC',
    label: 'ROCC,  Ranger Oil Corporation',
    name: 'Ranger Oil Corporation',
    symbol: 'ROCC',
  },
  {
    value: 'SLVM',
    label: 'SLVM,  Sylvamo Corporation',
    name: 'Sylvamo Corporation',
    symbol: 'SLVM',
  },
  {
    value: 'SNDA',
    label: 'SNDA,  Sonida Senior Living, Inc.',
    name: 'Sonida Senior Living, Inc.',
    symbol: 'SNDA',
  },
  {
    value: 'ONL',
    label: 'ONL,  Orion Office REIT Inc.',
    name: 'Orion Office REIT Inc.',
    symbol: 'ONL',
  },
  {
    value: 'SMAP',
    label: 'SMAP,  SportsMap Tech Acquisition Corp.',
    name: 'SportsMap Tech Acquisition Corp.',
    symbol: 'SMAP',
  },
  {
    value: 'USER',
    label: 'USER,  UserTesting, Inc.',
    name: 'UserTesting, Inc.',
    symbol: 'USER',
  },
  {
    value: 'PRV-UN.TO',
    label: 'PRV-UN.TO,  Pro Real Estate Investment Trust',
    name: 'Pro Real Estate Investment Trust',
    symbol: 'PRV-UN.TO',
  },
  {
    value: 'NXR-UN.TO',
    label: 'NXR-UN.TO,  Nexus Industrial REIT',
    name: 'Nexus Industrial REIT',
    symbol: 'NXR-UN.TO',
  },
  {
    value: 'VBNK',
    label: 'VBNK,  VersaBank',
    name: 'VersaBank',
    symbol: 'VBNK',
  },
  {
    value: 'BLZE',
    label: 'BLZE,  Backblaze, Inc.',
    name: 'Backblaze, Inc.',
    symbol: 'BLZE',
  },
  {
    value: 'BIRD',
    label: 'BIRD,  Allbirds, Inc.',
    name: 'Allbirds, Inc.',
    symbol: 'BIRD',
  },
  {
    value: 'SGR-UN.TO',
    label: 'SGR-UN.TO,  Slate Grocery REIT',
    name: 'Slate Grocery REIT',
    symbol: 'SGR-UN.TO',
  },
  {
    value: '2845.HK',
    label: '2845.HK,  Global X China Electric Vehicle ETF',
    name: 'Global X China Electric Vehicle ETF',
    symbol: '2845.HK',
  },
  {
    value: 'MI-UN.TO',
    label: 'MI-UN.TO,  Minto Apartment Real Estate Investment Trust',
    name: 'Minto Apartment Real Estate Investment Trust',
    symbol: 'MI-UN.TO',
  },
  {
    value: 'FCR-UN.TO',
    label: 'FCR-UN.TO,  First Capital Real Estate Investment Trust',
    name: 'First Capital Real Estate Investment Trust',
    symbol: 'FCR-UN.TO',
  },
  {
    value: 'TROO',
    label: 'TROO,  TROOPS, Inc.',
    name: 'TROOPS, Inc.',
    symbol: 'TROO',
  },
  {
    value: 'SG',
    label: 'SG,  Sweetgreen, Inc.',
    name: 'Sweetgreen, Inc.',
    symbol: 'SG',
  },
  {
    value: 'GFS',
    label: 'GFS,  GLOBALFOUNDRIES Inc.',
    name: 'GLOBALFOUNDRIES Inc.',
    symbol: 'GFS',
  },
  {
    value: 'LGTOU',
    label: 'LGTOU,  Legato Merger Corp. II',
    name: 'Legato Merger Corp. II',
    symbol: 'LGTOU',
  },
  {
    value: '600004.SS',
    label: '600004.SS,  Guangzhou Baiyun International Airport Co., Ltd.',
    name: 'Guangzhou Baiyun International Airport Co., Ltd.',
    symbol: '600004.SS',
  },
  {
    value: '600006.SS',
    label: '600006.SS,  DongFeng Automobile Co. LTD',
    name: 'DongFeng Automobile Co. LTD',
    symbol: '600006.SS',
  },
  {
    value: '600007.SS',
    label: '600007.SS,  China World Trade Center Co., Ltd.',
    name: 'China World Trade Center Co., Ltd.',
    symbol: '600007.SS',
  },
  {
    value: '600017.SS',
    label: '600017.SS,  Rizhao Port Co., Ltd.',
    name: 'Rizhao Port Co., Ltd.',
    symbol: '600017.SS',
  },
  {
    value: '600020.SS',
    label: '600020.SS,  Henan Zhongyuan Expressway Company Limited',
    name: 'Henan Zhongyuan Expressway Company Limited',
    symbol: '600020.SS',
  },
  {
    value: '600022.SS',
    label: '600022.SS,  Shandong Iron and Steel Company Ltd.',
    name: 'Shandong Iron and Steel Company Ltd.',
    symbol: '600022.SS',
  },
  {
    value: '600023.SS',
    label: '600023.SS,  Zhejiang Zheneng Electric Power Co., Ltd.',
    name: 'Zhejiang Zheneng Electric Power Co., Ltd.',
    symbol: '600023.SS',
  },
  {
    value: '600033.SS',
    label: '600033.SS,  Fujian Expressway Development Co.,Ltd',
    name: 'Fujian Expressway Development Co.,Ltd',
    symbol: '600033.SS',
  },
  {
    value: '600035.SS',
    label: '600035.SS,  Hubei Chutian Smart Communication Co.,Ltd.',
    name: 'Hubei Chutian Smart Communication Co.,Ltd.',
    symbol: '600035.SS',
  },
  {
    value: '600037.SS',
    label: '600037.SS,  Beijing Gehua Catv Network Co.,Ltd.',
    name: 'Beijing Gehua Catv Network Co.,Ltd.',
    symbol: '600037.SS',
  },
  {
    value: '600051.SS',
    label: '600051.SS,  Ningbo United Group Co.,Ltd.',
    name: 'Ningbo United Group Co.,Ltd.',
    symbol: '600051.SS',
  },
  {
    value: '600052.SS',
    label: '600052.SS,  Zhejiang Dongwang Times Technology Co., Ltd.',
    name: 'Zhejiang Dongwang Times Technology Co., Ltd.',
    symbol: '600052.SS',
  },
  {
    value: '600053.SS',
    label: '600053.SS,  Kunwu Jiuding Investment Holdings Co., Ltd.',
    name: 'Kunwu Jiuding Investment Holdings Co., Ltd.',
    symbol: '600053.SS',
  },
  {
    value: '600054.SS',
    label: '600054.SS,  Huangshan Tourism Development Co.,Ltd.',
    name: 'Huangshan Tourism Development Co.,Ltd.',
    symbol: '600054.SS',
  },
  {
    value: '600055.SS',
    label: '600055.SS,  Beijing Wandong Medical Technology Co., Ltd.',
    name: 'Beijing Wandong Medical Technology Co., Ltd.',
    symbol: '600055.SS',
  },
  {
    value: '600056.SS',
    label: '600056.SS,  China Meheco Group Co., Ltd.',
    name: 'China Meheco Group Co., Ltd.',
    symbol: '600056.SS',
  },
  {
    value: '600058.SS',
    label: '600058.SS,  Minmetals Development Co., Ltd.',
    name: 'Minmetals Development Co., Ltd.',
    symbol: '600058.SS',
  },
  {
    value: '600059.SS',
    label: '600059.SS,  Zhejiang Guyuelongshan Shaoxing Wine Co.,Ltd',
    name: 'Zhejiang Guyuelongshan Shaoxing Wine Co.,Ltd',
    symbol: '600059.SS',
  },
  {
    value: '600060.SS',
    label: '600060.SS,  Hisense Visual Technology Co., Ltd.',
    name: 'Hisense Visual Technology Co., Ltd.',
    symbol: '600060.SS',
  },
  {
    value: '600063.SS',
    label:
      '600063.SS,  Anhui Wanwei Updated High-Tech Material Industry Co.,Ltd',
    name: 'Anhui Wanwei Updated High-Tech Material Industry Co.,Ltd',
    symbol: '600063.SS',
  },
  {
    value: '600064.SS',
    label: '600064.SS,  Nanjing Gaoke Company Limited',
    name: 'Nanjing Gaoke Company Limited',
    symbol: '600064.SS',
  },
  {
    value: '600067.SS',
    label: '600067.SS,  Citychamp Dartong Co.,Ltd.',
    name: 'Citychamp Dartong Co.,Ltd.',
    symbol: '600067.SS',
  },
  {
    value: '600070.SS',
    label: '600070.SS,  Zhejiang Furun Digital Technology Co., Ltd.',
    name: 'Zhejiang Furun Digital Technology Co., Ltd.',
    symbol: '600070.SS',
  },
  {
    value: '600071.SS',
    label: '600071.SS,  Phenix Optical Company Limited',
    name: 'Phenix Optical Company Limited',
    symbol: '600071.SS',
  },
  {
    value: '600072.SS',
    label: '600072.SS,  CSSC Science& Technology Co., Ltd',
    name: 'CSSC Science& Technology Co., Ltd',
    symbol: '600072.SS',
  },
  {
    value: '600073.SS',
    label: '600073.SS,  Shanghai Maling Aquarius Co.,Ltd',
    name: 'Shanghai Maling Aquarius Co.,Ltd',
    symbol: '600073.SS',
  },
  {
    value: '3IINFOLTD.NS',
    label: '3IINFOLTD.NS,  3i Infotech Limited',
    name: '3i Infotech Limited',
    symbol: '3IINFOLTD.NS',
  },
  {
    value: '600075.SS',
    label: '600075.SS,  Xinjiang Tianye Co.,Ltd.',
    name: 'Xinjiang Tianye Co.,Ltd.',
    symbol: '600075.SS',
  },
  {
    value: '600076.SS',
    label: '600076.SS,  Kangxin New Materials Co., Ltd',
    name: 'Kangxin New Materials Co., Ltd',
    symbol: '600076.SS',
  },
  {
    value: '600077.SS',
    label: '600077.SS,  Sundy Land Investment Co., Ltd.',
    name: 'Sundy Land Investment Co., Ltd.',
    symbol: '600077.SS',
  },
  {
    value: '600078.SS',
    label: '600078.SS,  Jiangsu ChengXing Phosph-Chemicals Co., Ltd.',
    name: 'Jiangsu ChengXing Phosph-Chemicals Co., Ltd.',
    symbol: '600078.SS',
  },
  {
    value: '600080.SS',
    label: '600080.SS,  Ginwa Enterprise (Group) Inc.',
    name: 'Ginwa Enterprise (Group) Inc.',
    symbol: '600080.SS',
  },
  {
    value: '600081.SS',
    label: '600081.SS,  Dongfeng Electronic Technology Co.,Ltd.',
    name: 'Dongfeng Electronic Technology Co.,Ltd.',
    symbol: '600081.SS',
  },
  {
    value: '600082.SS',
    label: '600082.SS,  Tianjin Hi-Tech Development Co.,Ltd.',
    name: 'Tianjin Hi-Tech Development Co.,Ltd.',
    symbol: '600082.SS',
  },
  {
    value: '600083.SS',
    label: '600083.SS,  Jiangsu Boxin Investing&Holdings Co.,Ltd.',
    name: 'Jiangsu Boxin Investing&Holdings Co.,Ltd.',
    symbol: '600083.SS',
  },
  {
    value: '600084.SS',
    label: '600084.SS,  Citic Guoan Wine CO.,LTD',
    name: 'Citic Guoan Wine CO.,LTD',
    symbol: '600084.SS',
  },
  {
    value: '600085.SS',
    label: '600085.SS,  Beijing Tongrentang Co., Ltd',
    name: 'Beijing Tongrentang Co., Ltd',
    symbol: '600085.SS',
  },
  {
    value: '600088.SS',
    label: '600088.SS,  China Television Media, Ltd.',
    name: 'China Television Media, Ltd.',
    symbol: '600088.SS',
  },
  {
    value: '600094.SS',
    label: '600094.SS,  Greattown Holdings Ltd.',
    name: 'Greattown Holdings Ltd.',
    symbol: '600094.SS',
  },
  {
    value: '600095.SS',
    label: '600095.SS,  Xiangcai Co.,Ltd',
    name: 'Xiangcai Co.,Ltd',
    symbol: '600095.SS',
  },
  {
    value: '600096.SS',
    label: '600096.SS,  Yunnan Yuntianhua Co., Ltd.',
    name: 'Yunnan Yuntianhua Co., Ltd.',
    symbol: '600096.SS',
  },
  {
    value: '600097.SS',
    label: '600097.SS,  Shanghai Kaichuang Marine International Co., Ltd.',
    name: 'Shanghai Kaichuang Marine International Co., Ltd.',
    symbol: '600097.SS',
  },
  {
    value: '600098.SS',
    label: '600098.SS,  Guangzhou Development Group Incorporated',
    name: 'Guangzhou Development Group Incorporated',
    symbol: '600098.SS',
  },
  {
    value: '600099.SS',
    label: '600099.SS,  Linhai Co.,Ltd.',
    name: 'Linhai Co.,Ltd.',
    symbol: '600099.SS',
  },
  {
    value: '600100.SS',
    label: '600100.SS,  Tsinghua Tongfang Co.,Ltd.',
    name: 'Tsinghua Tongfang Co.,Ltd.',
    symbol: '600100.SS',
  },
  {
    value: '600101.SS',
    label: '600101.SS,  Sichuan Mingxing Electric Power Co., Ltd.',
    name: 'Sichuan Mingxing Electric Power Co., Ltd.',
    symbol: '600101.SS',
  },
  {
    value: '600103.SS',
    label: '600103.SS,  Fujian Qingshan Paper Industry Co.,Ltd.',
    name: 'Fujian Qingshan Paper Industry Co.,Ltd.',
    symbol: '600103.SS',
  },
  {
    value: '600105.SS',
    label: '600105.SS,  Jiangsu Etern Company Limited',
    name: 'Jiangsu Etern Company Limited',
    symbol: '600105.SS',
  },
  {
    value: '600106.SS',
    label: '600106.SS,  Chongqing road & bridge co.,ltd',
    name: 'Chongqing road & bridge co.,ltd',
    symbol: '600106.SS',
  },
  {
    value: '600107.SS',
    label: '600107.SS,  Hubei Mailyard Share Co.,Ltd',
    name: 'Hubei Mailyard Share Co.,Ltd',
    symbol: '600107.SS',
  },
  {
    value: '600108.SS',
    label: '600108.SS,  Gansu Yasheng Industrial (Group) Co., Ltd.',
    name: 'Gansu Yasheng Industrial (Group) Co., Ltd.',
    symbol: '600108.SS',
  },
  {
    value: '600110.SS',
    label: '600110.SS,  Nuode New Materials Co.,Ltd.',
    name: 'Nuode New Materials Co.,Ltd.',
    symbol: '600110.SS',
  },
  {
    value: '600112.SS',
    label: '600112.SS,  Guizhou Changzheng Tiancheng Holding Co.,Ltd.',
    name: 'Guizhou Changzheng Tiancheng Holding Co.,Ltd.',
    symbol: '600112.SS',
  },
  {
    value: '600113.SS',
    label: '600113.SS,  Zhejiang Dongri Limited Company',
    name: 'Zhejiang Dongri Limited Company',
    symbol: '600113.SS',
  },
  {
    value: '600114.SS',
    label: '600114.SS,  NBTM New Materials Group Co., Ltd.',
    name: 'NBTM New Materials Group Co., Ltd.',
    symbol: '600114.SS',
  },
  {
    value: '600116.SS',
    label:
      '600116.SS,  Chongqing Three Gorges Water Conservancy and Electric Power Co., Ltd.',
    name: 'Chongqing Three Gorges Water Conservancy and Electric Power Co., Ltd.',
    symbol: '600116.SS',
  },
  {
    value: '600117.SS',
    label: '600117.SS,  XiNing Special Steel Co., Ltd.',
    name: 'XiNing Special Steel Co., Ltd.',
    symbol: '600117.SS',
  },
  {
    value: '600118.SS',
    label: '600118.SS,  China Spacesat Co.,Ltd.',
    name: 'China Spacesat Co.,Ltd.',
    symbol: '600118.SS',
  },
  {
    value: '600119.SS',
    label: '600119.SS,  Y.U.D.Yangtze River Investment Industry Co.,Ltd.',
    name: 'Y.U.D.Yangtze River Investment Industry Co.,Ltd.',
    symbol: '600119.SS',
  },
  {
    value: '600120.SS',
    label: '600120.SS,  Zhejiang Orient Financial Holdings Group Co., Ltd.',
    name: 'Zhejiang Orient Financial Holdings Group Co., Ltd.',
    symbol: '600120.SS',
  },
  {
    value: '600121.SS',
    label: '600121.SS,  Zhengzhou Coal Industry & Electric Power Co., Ltd.',
    name: 'Zhengzhou Coal Industry & Electric Power Co., Ltd.',
    symbol: '600121.SS',
  },
  {
    value: '600122.SS',
    label: '600122.SS,  Jiangsu Hongtu High Technology Co., Ltd.',
    name: 'Jiangsu Hongtu High Technology Co., Ltd.',
    symbol: '600122.SS',
  },
  {
    value: '600123.SS',
    label: '600123.SS,  Shanxi Lanhua Sci-Tech Venture Co.,Ltd',
    name: 'Shanxi Lanhua Sci-Tech Venture Co.,Ltd',
    symbol: '600123.SS',
  },
  {
    value: '600125.SS',
    label: '600125.SS,  China Railway Tielong Container Logistics Co., Ltd',
    name: 'China Railway Tielong Container Logistics Co., Ltd',
    symbol: '600125.SS',
  },
  {
    value: '600126.SS',
    label: '600126.SS,  Hang Zhou Iron & Steel Co.,Ltd.',
    name: 'Hang Zhou Iron & Steel Co.,Ltd.',
    symbol: '600126.SS',
  },
  {
    value: '600127.SS',
    label: '600127.SS,  Jinjian Cereals Industry Co.,Ltd.',
    name: 'Jinjian Cereals Industry Co.,Ltd.',
    symbol: '600127.SS',
  },
  {
    value: '600128.SS',
    label: '600128.SS,  Jiangsu Holly Corporation',
    name: 'Jiangsu Holly Corporation',
    symbol: '600128.SS',
  },
  {
    value: '600129.SS',
    label: '600129.SS,  Chongqing Taiji Industry(Group) Co.,Ltd',
    name: 'Chongqing Taiji Industry(Group) Co.,Ltd',
    symbol: '600129.SS',
  },
  {
    value: '600130.SS',
    label: '600130.SS,  Ningbo Bird Co.,Ltd.',
    name: 'Ningbo Bird Co.,Ltd.',
    symbol: '600130.SS',
  },
  {
    value: '600131.SS',
    label: '600131.SS,  State Grid Information & Communication Co., Ltd.',
    name: 'State Grid Information & Communication Co., Ltd.',
    symbol: '600131.SS',
  },
  {
    value: '600133.SS',
    label: '600133.SS,  Wuhan East Lake High Technology Group Co., Ltd.',
    name: 'Wuhan East Lake High Technology Group Co., Ltd.',
    symbol: '600133.SS',
  },
  {
    value: '600135.SS',
    label: '600135.SS,  Lucky Film Company,Limited',
    name: 'Lucky Film Company,Limited',
    symbol: '600135.SS',
  },
  {
    value: '600136.SS',
    label: '600136.SS,  Wuhan DDMC Culture & Sports Co.,Ltd.',
    name: 'Wuhan DDMC Culture & Sports Co.,Ltd.',
    symbol: '600136.SS',
  },
  {
    value: '600137.SS',
    label: '600137.SS,  Sichuan Langsha Holding Ltd.',
    name: 'Sichuan Langsha Holding Ltd.',
    symbol: '600137.SS',
  },
  {
    value: '600138.SS',
    label: '600138.SS,  China Cyts Tours Holding Co., Ltd.',
    name: 'China Cyts Tours Holding Co., Ltd.',
    symbol: '600138.SS',
  },
  {
    value: '600139.SS',
    label: '600139.SS,  Sichuan Western Resources Holding Co., Ltd.',
    name: 'Sichuan Western Resources Holding Co., Ltd.',
    symbol: '600139.SS',
  },
  {
    value: '600141.SS',
    label: '600141.SS,  Hubei Xingfa Chemicals Group Co., Ltd.',
    name: 'Hubei Xingfa Chemicals Group Co., Ltd.',
    symbol: '600141.SS',
  },
  {
    value: '600148.SS',
    label: '600148.SS,  Changchun Yidong Clutch CO.,LTD',
    name: 'Changchun Yidong Clutch CO.,LTD',
    symbol: '600148.SS',
  },
  {
    value: '600149.SS',
    label: '600149.SS,  Langfang Development Co., Ltd.',
    name: 'Langfang Development Co., Ltd.',
    symbol: '600149.SS',
  },
  {
    value: '600151.SS',
    label:
      '600151.SS,  Shanghai Aerospace Automobile Electromechanical Co., Ltd.',
    name: 'Shanghai Aerospace Automobile Electromechanical Co., Ltd.',
    symbol: '600151.SS',
  },
  {
    value: '600152.SS',
    label: '600152.SS,  Veken Technology Co., Ltd.',
    name: 'Veken Technology Co., Ltd.',
    symbol: '600152.SS',
  },
  {
    value: '600155.SS',
    label: '600155.SS,  Polaris Bay Group Co.,Ltd.',
    name: 'Polaris Bay Group Co.,Ltd.',
    symbol: '600155.SS',
  },
  {
    value: '600156.SS',
    label: '600156.SS,  Hunan Huasheng Co.,Ltd',
    name: 'Hunan Huasheng Co.,Ltd',
    symbol: '600156.SS',
  },
  {
    value: '600157.SS',
    label: '600157.SS,  Wintime Energy Co.,Ltd.',
    name: 'Wintime Energy Co.,Ltd.',
    symbol: '600157.SS',
  },
  {
    value: '600158.SS',
    label: '600158.SS,  China Sports Industry Group Co., Ltd.',
    name: 'China Sports Industry Group Co., Ltd.',
    symbol: '600158.SS',
  },
  {
    value: '600159.SS',
    label: '600159.SS,  Beijing Dalong Weiye Real Estate Development Co.,Ltd',
    name: 'Beijing Dalong Weiye Real Estate Development Co.,Ltd',
    symbol: '600159.SS',
  },
  {
    value: '600162.SS',
    label: '600162.SS,  Shenzhen HeungKong Holding Co.,Ltd',
    name: 'Shenzhen HeungKong Holding Co.,Ltd',
    symbol: '600162.SS',
  },
  {
    value: '600163.SS',
    label: '600163.SS,  Zhongmin Energy Co., Ltd.',
    name: 'Zhongmin Energy Co., Ltd.',
    symbol: '600163.SS',
  },
  {
    value: '600165.SS',
    label: '600165.SS,  Ningxia Zhongke Biotechnology Co., Ltd.',
    name: 'Ningxia Zhongke Biotechnology Co., Ltd.',
    symbol: '600165.SS',
  },
  {
    value: '600166.SS',
    label: '600166.SS,  Beiqi Foton Motor Co., Ltd.',
    name: 'Beiqi Foton Motor Co., Ltd.',
    symbol: '600166.SS',
  },
  {
    value: '600168.SS',
    label: '600168.SS,  Wuhan Sanzhen Industry Holding Co.,Ltd',
    name: 'Wuhan Sanzhen Industry Holding Co.,Ltd',
    symbol: '600168.SS',
  },
  {
    value: '600169.SS',
    label: '600169.SS,  Taiyuan Heavy Industry Co., Ltd.',
    name: 'Taiyuan Heavy Industry Co., Ltd.',
    symbol: '600169.SS',
  },
  {
    value: '600171.SS',
    label: '600171.SS,  Shanghai Belling Co., Ltd.',
    name: 'Shanghai Belling Co., Ltd.',
    symbol: '600171.SS',
  },
  {
    value: '600172.SS',
    label: '600172.SS,  Henan Huanghe Whirlwind Co., Ltd.',
    name: 'Henan Huanghe Whirlwind Co., Ltd.',
    symbol: '600172.SS',
  },
  {
    value: '600173.SS',
    label: '600173.SS,  Wolong Real Estate Group Co.,Ltd.',
    name: 'Wolong Real Estate Group Co.,Ltd.',
    symbol: '600173.SS',
  },
  {
    value: '600178.SS',
    label: '600178.SS,  Harbin Dongan Auto Engine Co.,Ltd',
    name: 'Harbin Dongan Auto Engine Co.,Ltd',
    symbol: '600178.SS',
  },
  {
    value: '600179.SS',
    label: '600179.SS,  Antong Holdings Co., Ltd.',
    name: 'Antong Holdings Co., Ltd.',
    symbol: '600179.SS',
  },
  {
    value: '600180.SS',
    label: '600180.SS,  CCS Supply Chain Management Co., Ltd.',
    name: 'CCS Supply Chain Management Co., Ltd.',
    symbol: '600180.SS',
  },
  {
    value: '600182.SS',
    label: '600182.SS,  Giti Tire Corporation',
    name: 'Giti Tire Corporation',
    symbol: '600182.SS',
  },
  {
    value: '600184.SS',
    label: '600184.SS,  North Electro-Optic Co.,Ltd.',
    name: 'North Electro-Optic Co.,Ltd.',
    symbol: '600184.SS',
  },
  {
    value: '600185.SS',
    label: '600185.SS,  Gree Real Estate Co.,Ltd',
    name: 'Gree Real Estate Co.,Ltd',
    symbol: '600185.SS',
  },
  {
    value: '600186.SS',
    label: '600186.SS,  Lotus Health Group Company',
    name: 'Lotus Health Group Company',
    symbol: '600186.SS',
  },
  {
    value: '600187.SS',
    label: '600187.SS,  Heilongjiang Interchina Water Treatment Co.,Ltd',
    name: 'Heilongjiang Interchina Water Treatment Co.,Ltd',
    symbol: '600187.SS',
  },
  {
    value: '600189.SS',
    label: '600189.SS,  Jilin Forest Industry Co., Ltd.',
    name: 'Jilin Forest Industry Co., Ltd.',
    symbol: '600189.SS',
  },
  {
    value: '600190.SS',
    label: '600190.SS,  Jinzhou Port Co., Ltd.',
    name: 'Jinzhou Port Co., Ltd.',
    symbol: '600190.SS',
  },
  {
    value: '600191.SS',
    label: '600191.SS,  Baotou Huazi Industry Co., Ltd',
    name: 'Baotou Huazi Industry Co., Ltd',
    symbol: '600191.SS',
  },
  {
    value: '600192.SS',
    label: '600192.SS,  Lanzhou GreatWall Electrical Co., Ltd',
    name: 'Lanzhou GreatWall Electrical Co., Ltd',
    symbol: '600192.SS',
  },
  {
    value: '600193.SS',
    label: '600193.SS,  Shanghai Prosolar Resources Development Co., Ltd',
    name: 'Shanghai Prosolar Resources Development Co., Ltd',
    symbol: '600193.SS',
  },
  {
    value: '600195.SS',
    label: '600195.SS,  China Animal Husbandry Industry Co., Ltd.',
    name: 'China Animal Husbandry Industry Co., Ltd.',
    symbol: '600195.SS',
  },
  {
    value: '600197.SS',
    label: '600197.SS,  Xinjiang Yilite Industry Co.,Ltd',
    name: 'Xinjiang Yilite Industry Co.,Ltd',
    symbol: '600197.SS',
  },
  {
    value: '600198.SS',
    label: '600198.SS,  Datang Telecom Technology Co., Ltd.',
    name: 'Datang Telecom Technology Co., Ltd.',
    symbol: '600198.SS',
  },
  {
    value: '600199.SS',
    label: '600199.SS,  Anhui Golden Seed Winery Co., Ltd.',
    name: 'Anhui Golden Seed Winery Co., Ltd.',
    symbol: '600199.SS',
  },
  {
    value: '600200.SS',
    label: '600200.SS,  JiangSu WuZhong Pharmaceutical Development Co., Ltd.',
    name: 'JiangSu WuZhong Pharmaceutical Development Co., Ltd.',
    symbol: '600200.SS',
  },
  {
    value: '600201.SS',
    label: '600201.SS,  Jinyu Bio-technology Co., Ltd.',
    name: 'Jinyu Bio-technology Co., Ltd.',
    symbol: '600201.SS',
  },
  {
    value: '600202.SS',
    label: '600202.SS,  Harbin Air Conditioning Co.,Ltd.',
    name: 'Harbin Air Conditioning Co.,Ltd.',
    symbol: '600202.SS',
  },
  {
    value: '600203.SS',
    label: '600203.SS,  Fujian Furi Electronics Co.,Ltd',
    name: 'Fujian Furi Electronics Co.,Ltd',
    symbol: '600203.SS',
  },
  {
    value: '600206.SS',
    label: '600206.SS,  Grinm Advanced Materials Co., Ltd.',
    name: 'Grinm Advanced Materials Co., Ltd.',
    symbol: '600206.SS',
  },
  {
    value: '600207.SS',
    label: '600207.SS,  Henan Ancai Hi-Tech Co.,Ltd',
    name: 'Henan Ancai Hi-Tech Co.,Ltd',
    symbol: '600207.SS',
  },
  {
    value: '600208.SS',
    label: '600208.SS,  Xinhu Zhongbao Co.,Ltd.',
    name: 'Xinhu Zhongbao Co.,Ltd.',
    symbol: '600208.SS',
  },
  {
    value: '600211.SS',
    label: '600211.SS,  Tibet Rhodiola Pharmaceutical Holding Co.',
    name: 'Tibet Rhodiola Pharmaceutical Holding Co.',
    symbol: '600211.SS',
  },
  {
    value: '600212.SS',
    label: '600212.SS,  Gresgying Digital Energy Technology Co.,Ltd',
    name: 'Gresgying Digital Energy Technology Co.,Ltd',
    symbol: '600212.SS',
  },
  {
    value: '600213.SS',
    label: '600213.SS,  Yangzhou Yaxing Motor Coach Co., Ltd.',
    name: 'Yangzhou Yaxing Motor Coach Co., Ltd.',
    symbol: '600213.SS',
  },
  {
    value: '600215.SS',
    label: '600215.SS,  Paslin Digital Technology Co., Ltd.',
    name: 'Paslin Digital Technology Co., Ltd.',
    symbol: '600215.SS',
  },
  {
    value: '600217.SS',
    label: '600217.SS,  China Resources and Environment Co.,Ltd.',
    name: 'China Resources and Environment Co.,Ltd.',
    symbol: '600217.SS',
  },
  {
    value: '600218.SS',
    label: '600218.SS,  Anhui Quanchai Engine Co., Ltd.',
    name: 'Anhui Quanchai Engine Co., Ltd.',
    symbol: '600218.SS',
  },
  {
    value: '600220.SS',
    label: '600220.SS,  Jiangsu Sunshine Co., Ltd.',
    name: 'Jiangsu Sunshine Co., Ltd.',
    symbol: '600220.SS',
  },
  {
    value: '600221.SS',
    label: '600221.SS,  Hainan Airlines Holding Co., Ltd.',
    name: 'Hainan Airlines Holding Co., Ltd.',
    symbol: '600221.SS',
  },
  {
    value: '600222.SS',
    label: '600222.SS,  Henan Taloph Pharmaceutical Stock Co.,Ltd',
    name: 'Henan Taloph Pharmaceutical Stock Co.,Ltd',
    symbol: '600222.SS',
  },
  {
    value: '600223.SS',
    label: '600223.SS,  Lushang Health Industry Development Co.,Ltd.',
    name: 'Lushang Health Industry Development Co.,Ltd.',
    symbol: '600223.SS',
  },
  {
    value: '600225.SS',
    label: '600225.SS,  Tianjin Troila Information Technology Co.,Ltd.',
    name: 'Tianjin Troila Information Technology Co.,Ltd.',
    symbol: '600225.SS',
  },
  {
    value: '600226.SS',
    label: '600226.SS,  Zhejiang Hugeleaf Co.,Ltd.',
    name: 'Zhejiang Hugeleaf Co.,Ltd.',
    symbol: '600226.SS',
  },
  {
    value: '600227.SS',
    label: '600227.SS,  Guizhou Salvage Pharmaceutical Co.,Ltd.',
    name: 'Guizhou Salvage Pharmaceutical Co.,Ltd.',
    symbol: '600227.SS',
  },
  {
    value: '600228.SS',
    label: '600228.SS,  Fanli Digital Technology Co.,Ltd',
    name: 'Fanli Digital Technology Co.,Ltd',
    symbol: '600228.SS',
  },
  {
    value: '600229.SS',
    label: '600229.SS,  Qingdao Citymedia Co,. Ltd.',
    name: 'Qingdao Citymedia Co,. Ltd.',
    symbol: '600229.SS',
  },
  {
    value: '600230.SS',
    label: '600230.SS,  Cangzhou Dahua Co., Ltd.',
    name: 'Cangzhou Dahua Co., Ltd.',
    symbol: '600230.SS',
  },
  {
    value: '600231.SS',
    label: '600231.SS,  Lingyuan Iron & Steel Co., Ltd.',
    name: 'Lingyuan Iron & Steel Co., Ltd.',
    symbol: '600231.SS',
  },
  {
    value: '600232.SS',
    label: '600232.SS,  Zhejiang Golden Eagle Co.,Ltd.',
    name: 'Zhejiang Golden Eagle Co.,Ltd.',
    symbol: '600232.SS',
  },
  {
    value: '600234.SS',
    label: '600234.SS,  Guanghe Landscape Culture Communication Co., Ltd.',
    name: 'Guanghe Landscape Culture Communication Co., Ltd.',
    symbol: '600234.SS',
  },
  {
    value: '600235.SS',
    label: '600235.SS,  Minfeng Special Paper Co., Ltd.',
    name: 'Minfeng Special Paper Co., Ltd.',
    symbol: '600235.SS',
  },
  {
    value: '600237.SS',
    label: '600237.SS,  Anhui Tongfeng Electronics Company Limited',
    name: 'Anhui Tongfeng Electronics Company Limited',
    symbol: '600237.SS',
  },
  {
    value: '600238.SS',
    label: '600238.SS,  HaiNan Yedao (Group) Co., Ltd.',
    name: 'HaiNan Yedao (Group) Co., Ltd.',
    symbol: '600238.SS',
  },
  {
    value: '600239.SS',
    label: '600239.SS,  Yunnan Metropolitan RealEstate Development Co.Ltd',
    name: 'Yunnan Metropolitan RealEstate Development Co.Ltd',
    symbol: '600239.SS',
  },
  {
    value: '600241.SS',
    label: '600241.SS,  Liaoning Shidai Wanheng Co.,Ltd.',
    name: 'Liaoning Shidai Wanheng Co.,Ltd.',
    symbol: '600241.SS',
  },
  {
    value: '600242.SS',
    label: '600242.SS,  Zhongchang Big Data Corporation Limited',
    name: 'Zhongchang Big Data Corporation Limited',
    symbol: '600242.SS',
  },
  {
    value: '600243.SS',
    label: '600243.SS,  QingHai HuaDing Industrial CO.,LTD.',
    name: 'QingHai HuaDing Industrial CO.,LTD.',
    symbol: '600243.SS',
  },
  {
    value: '600246.SS',
    label: '600246.SS,  Vantone Neo Development Group Co., Ltd.',
    name: 'Vantone Neo Development Group Co., Ltd.',
    symbol: '600246.SS',
  },
  {
    value: '600248.SS',
    label:
      '600248.SS,  Shaanxi Construction Engineering Group Corporation Limited',
    name: 'Shaanxi Construction Engineering Group Corporation Limited',
    symbol: '600248.SS',
  },
  {
    value: '600249.SS',
    label: '600249.SS,  Liuzhou Liangmianzhen Co., Ltd.',
    name: 'Liuzhou Liangmianzhen Co., Ltd.',
    symbol: '600249.SS',
  },
  {
    value: '600250.SS',
    label: '600250.SS,  Nanjing Textiles Import & Export Corp., Ltd.',
    name: 'Nanjing Textiles Import & Export Corp., Ltd.',
    symbol: '600250.SS',
  },
  {
    value: '600251.SS',
    label: '600251.SS,  Xinjiang Guannong Fruit & Antler Co.,Ltd.',
    name: 'Xinjiang Guannong Fruit & Antler Co.,Ltd.',
    symbol: '600251.SS',
  },
  {
    value: '600252.SS',
    label: '600252.SS,  Guangxi Wuzhou Zhongheng Group Co.,Ltd',
    name: 'Guangxi Wuzhou Zhongheng Group Co.,Ltd',
    symbol: '600252.SS',
  },
  {
    value: '600255.SS',
    label: '600255.SS,  Anhui Xinke New Materials Co.,Ltd',
    name: 'Anhui Xinke New Materials Co.,Ltd',
    symbol: '600255.SS',
  },
  {
    value: '600257.SS',
    label: '600257.SS,  Dahu Aquaculture Co.,Ltd.',
    name: 'Dahu Aquaculture Co.,Ltd.',
    symbol: '600257.SS',
  },
  {
    value: '600258.SS',
    label: '600258.SS,  BTG Hotels (Group) Co., Ltd.',
    name: 'BTG Hotels (Group) Co., Ltd.',
    symbol: '600258.SS',
  },
  {
    value: '600259.SS',
    label: '600259.SS,  Rising Nonferrous Metals Share Co.,Ltd.',
    name: 'Rising Nonferrous Metals Share Co.,Ltd.',
    symbol: '600259.SS',
  },
  {
    value: '600260.SS',
    label: '600260.SS,  Kaile Science and Technology Co;Ltd.Hubei',
    name: 'Kaile Science and Technology Co;Ltd.Hubei',
    symbol: '600260.SS',
  },
  {
    value: '600261.SS',
    label: '600261.SS,  Zhejiang Yankon Group Co., Ltd.',
    name: 'Zhejiang Yankon Group Co., Ltd.',
    symbol: '600261.SS',
  },
  {
    value: '600262.SS',
    label: '600262.SS,  Inner Mongolia North Hauler Joint Stock Co., Ltd.',
    name: 'Inner Mongolia North Hauler Joint Stock Co., Ltd.',
    symbol: '600262.SS',
  },
  {
    value: '600265.SS',
    label: '600265.SS,  Yunnan Jinggu Forestry Co.,Ltd',
    name: 'Yunnan Jinggu Forestry Co.,Ltd',
    symbol: '600265.SS',
  },
  {
    value: '600266.SS',
    label:
      '600266.SS,  Beijing Urban Construction Investment & Development Co., Ltd.',
    name: 'Beijing Urban Construction Investment & Development Co., Ltd.',
    symbol: '600266.SS',
  },
  {
    value: '600267.SS',
    label: '600267.SS,  Zhejiang Hisun Pharmaceutical Co., Ltd.',
    name: 'Zhejiang Hisun Pharmaceutical Co., Ltd.',
    symbol: '600267.SS',
  },
  {
    value: '600268.SS',
    label: '600268.SS,  Guodian Nanjing Automation Co., Ltd.',
    name: 'Guodian Nanjing Automation Co., Ltd.',
    symbol: '600268.SS',
  },
  {
    value: '600269.SS',
    label: '600269.SS,  Jiangxi Ganyue Expressway CO.,LTD.',
    name: 'Jiangxi Ganyue Expressway CO.,LTD.',
    symbol: '600269.SS',
  },
  {
    value: '600271.SS',
    label: '600271.SS,  Aisino Corporation',
    name: 'Aisino Corporation',
    symbol: '600271.SS',
  },
  {
    value: '600272.SS',
    label: '600272.SS,  Shanghai Kai Kai Industry Company Limited',
    name: 'Shanghai Kai Kai Industry Company Limited',
    symbol: '600272.SS',
  },
  {
    value: '600273.SS',
    label: '600273.SS,  Zhejiang Jiahua Energy Chemical Industry Co.,Ltd.',
    name: 'Zhejiang Jiahua Energy Chemical Industry Co.,Ltd.',
    symbol: '600273.SS',
  },
  {
    value: '600277.SS',
    label: '600277.SS,  Elion Energy Company Limited',
    name: 'Elion Energy Company Limited',
    symbol: '600277.SS',
  },
  {
    value: '600278.SS',
    label: '600278.SS,  Orient International Enterprise, Ltd.',
    name: 'Orient International Enterprise, Ltd.',
    symbol: '600278.SS',
  },
  {
    value: '600279.SS',
    label: '600279.SS,  Chongqing Port Co.,Ltd.',
    name: 'Chongqing Port Co.,Ltd.',
    symbol: '600279.SS',
  },
  {
    value: '600280.SS',
    label: '600280.SS,  Nanjing Central Emporium (Group) Stocks Co., Ltd.',
    name: 'Nanjing Central Emporium (Group) Stocks Co., Ltd.',
    symbol: '600280.SS',
  },
  {
    value: '600281.SS',
    label: '600281.SS,  Shanxi Huayang New Material Co.,Ltd.',
    name: 'Shanxi Huayang New Material Co.,Ltd.',
    symbol: '600281.SS',
  },
  {
    value: '600283.SS',
    label: '600283.SS,  Qian Jiang Water Resources Development Co.,Ltd',
    name: 'Qian Jiang Water Resources Development Co.,Ltd',
    symbol: '600283.SS',
  },
  {
    value: '600284.SS',
    label: '600284.SS,  Shanghai Pudong Construction Co.,Ltd.',
    name: 'Shanghai Pudong Construction Co.,Ltd.',
    symbol: '600284.SS',
  },
  {
    value: '600285.SS',
    label: '600285.SS,  Henan Lingrui Pharmaceutical Co., Ltd.',
    name: 'Henan Lingrui Pharmaceutical Co., Ltd.',
    symbol: '600285.SS',
  },
  {
    value: '600287.SS',
    label: '600287.SS,  Jiangsu Sainty Corp., Ltd.',
    name: 'Jiangsu Sainty Corp., Ltd.',
    symbol: '600287.SS',
  },
  {
    value: '600288.SS',
    label: '600288.SS,  Daheng New Epoch Technology Inc.',
    name: 'Daheng New Epoch Technology Inc.',
    symbol: '600288.SS',
  },
  {
    value: '600289.SS',
    label: '600289.SS,  Bright Oceans Inter-Telecom Corporation',
    name: 'Bright Oceans Inter-Telecom Corporation',
    symbol: '600289.SS',
  },
  {
    value: '600290.SS',
    label: '600290.SS,  Huayi Electric Company Limited',
    name: 'Huayi Electric Company Limited',
    symbol: '600290.SS',
  },
  {
    value: '600292.SS',
    label: '600292.SS,  Spic Yuanda Environmental-Protection Co., Ltd.',
    name: 'Spic Yuanda Environmental-Protection Co., Ltd.',
    symbol: '600292.SS',
  },
  {
    value: '600293.SS',
    label: '600293.SS,  Hubei Sanxia New Building Materials Co., Ltd.',
    name: 'Hubei Sanxia New Building Materials Co., Ltd.',
    symbol: '600293.SS',
  },
  {
    value: '600297.SS',
    label: '600297.SS,  China Grand Automotive Services Group Co., Ltd.',
    name: 'China Grand Automotive Services Group Co., Ltd.',
    symbol: '600297.SS',
  },
  {
    value: '600299.SS',
    label: '600299.SS,  Bluestar Adisseo Company',
    name: 'Bluestar Adisseo Company',
    symbol: '600299.SS',
  },
  {
    value: '600300.SS',
    label: '600300.SS,  V V Food & Beverage Co.,Ltd',
    name: 'V V Food & Beverage Co.,Ltd',
    symbol: '600300.SS',
  },
  {
    value: '600301.SS',
    label: '600301.SS,  Nanning Chemical Industry Co., Ltd.',
    name: 'Nanning Chemical Industry Co., Ltd.',
    symbol: '600301.SS',
  },
  {
    value: '600302.SS',
    label: "600302.SS,  Xi'an Typical Industries Co.,Ltd",
    name: "Xi'an Typical Industries Co.,Ltd",
    symbol: '600302.SS',
  },
  {
    value: '600303.SS',
    label: '600303.SS,  Liaoning SG Automotive Group Co., Ltd.',
    name: 'Liaoning SG Automotive Group Co., Ltd.',
    symbol: '600303.SS',
  },
  {
    value: '600305.SS',
    label: '600305.SS,  Jiangsu Hengshun Vinegar-Industry Co.,Ltd',
    name: 'Jiangsu Hengshun Vinegar-Industry Co.,Ltd',
    symbol: '600305.SS',
  },
  {
    value: '600306.SS',
    label: '600306.SS,  Shenyang Commercial City Co.,Ltd.',
    name: 'Shenyang Commercial City Co.,Ltd.',
    symbol: '600306.SS',
  },
  {
    value: '600307.SS',
    label: '600307.SS,  Gansu Jiu Steel Group Hongxing Iron & Steel Co.,Ltd.',
    name: 'Gansu Jiu Steel Group Hongxing Iron & Steel Co.,Ltd.',
    symbol: '600307.SS',
  },
  {
    value: '600308.SS',
    label: '600308.SS,  Shandong Huatai Paper Industry Shareholding Co.,Ltd',
    name: 'Shandong Huatai Paper Industry Shareholding Co.,Ltd',
    symbol: '600308.SS',
  },
  {
    value: '600310.SS',
    label: '600310.SS,  Guangxi Guidong Electric Power Co., Ltd.',
    name: 'Guangxi Guidong Electric Power Co., Ltd.',
    symbol: '600310.SS',
  },
  {
    value: '600311.SS',
    label: '600311.SS,  Gansu Ronghua Industry Group Co.,Ltd.',
    name: 'Gansu Ronghua Industry Group Co.,Ltd.',
    symbol: '600311.SS',
  },
  {
    value: '600312.SS',
    label: '600312.SS,  Henan Pinggao Electric Co.,Ltd.',
    name: 'Henan Pinggao Electric Co.,Ltd.',
    symbol: '600312.SS',
  },
  {
    value: '600313.SS',
    label: '600313.SS,  Zhongnongfa Seed Industry Group Co., Ltd.',
    name: 'Zhongnongfa Seed Industry Group Co., Ltd.',
    symbol: '600313.SS',
  },
  {
    value: '600316.SS',
    label: '600316.SS,  Jiangxi Hongdu Aviation Industry Co., Ltd.',
    name: 'Jiangxi Hongdu Aviation Industry Co., Ltd.',
    symbol: '600316.SS',
  },
  {
    value: '600318.SS',
    label: '600318.SS,  Anhui Xinli Finance Co., Ltd.',
    name: 'Anhui Xinli Finance Co., Ltd.',
    symbol: '600318.SS',
  },
  {
    value: '600319.SS',
    label: '600319.SS,  Weifang Yaxing Chemical Co., Ltd.',
    name: 'Weifang Yaxing Chemical Co., Ltd.',
    symbol: '600319.SS',
  },
  {
    value: '600320.SS',
    label: '600320.SS,  Shanghai Zhenhua Heavy Industries Co., Ltd.',
    name: 'Shanghai Zhenhua Heavy Industries Co., Ltd.',
    symbol: '600320.SS',
  },
  {
    value: '600321.SS',
    label: '600321.SS,  Rightway Holdings Co.,ltd.',
    name: 'Rightway Holdings Co.,ltd.',
    symbol: '600321.SS',
  },
  {
    value: '600322.SS',
    label: '600322.SS,  Tianjin Realty Development (Group) Co., Ltd.',
    name: 'Tianjin Realty Development (Group) Co., Ltd.',
    symbol: '600322.SS',
  },
  {
    value: '600326.SS',
    label: '600326.SS,  Tibet Tianlu Co., Ltd.',
    name: 'Tibet Tianlu Co., Ltd.',
    symbol: '600326.SS',
  },
  {
    value: '600327.SS',
    label: '600327.SS,  Wuxi Commercial Mansion Grand Orient Co., Ltd.',
    name: 'Wuxi Commercial Mansion Grand Orient Co., Ltd.',
    symbol: '600327.SS',
  },
  {
    value: '600328.SS',
    label: '600328.SS,  CNSIG Inner Mongolia Chemical Industry Co.,Ltd.',
    name: 'CNSIG Inner Mongolia Chemical Industry Co.,Ltd.',
    symbol: '600328.SS',
  },
  {
    value: '600329.SS',
    label:
      '600329.SS,  Tianjin Pharmaceutical Da Ren Tang Group Corporation Limited',
    name: 'Tianjin Pharmaceutical Da Ren Tang Group Corporation Limited',
    symbol: '600329.SS',
  },
  {
    value: '600330.SS',
    label: '600330.SS,  TDG Holding Co., Ltd.',
    name: 'TDG Holding Co., Ltd.',
    symbol: '600330.SS',
  },
  {
    value: '600331.SS',
    label: '600331.SS,  Sichuan Hongda Co.,Ltd',
    name: 'Sichuan Hongda Co.,Ltd',
    symbol: '600331.SS',
  },
  {
    value: '600333.SS',
    label: '600333.SS,  Changchun Gas Co,.Ltd',
    name: 'Changchun Gas Co,.Ltd',
    symbol: '600333.SS',
  },
  {
    value: '600335.SS',
    label: '600335.SS,  Sinomach Automobile Co., Ltd.',
    name: 'Sinomach Automobile Co., Ltd.',
    symbol: '600335.SS',
  },
  {
    value: '600336.SS',
    label: '600336.SS,  AUCMA Co.,Ltd.',
    name: 'AUCMA Co.,Ltd.',
    symbol: '600336.SS',
  },
  {
    value: '600337.SS',
    label: '600337.SS,  Markor International Home Furnishings Co., Ltd.',
    name: 'Markor International Home Furnishings Co., Ltd.',
    symbol: '600337.SS',
  },
  {
    value: '600338.SS',
    label: '600338.SS,  Tibet Summit Resources Co.,Ltd.',
    name: 'Tibet Summit Resources Co.,Ltd.',
    symbol: '600338.SS',
  },
  {
    value: '600339.SS',
    label: '600339.SS,  China Petroleum Engineering Corporation',
    name: 'China Petroleum Engineering Corporation',
    symbol: '600339.SS',
  },
  {
    value: '600343.SS',
    label: '600343.SS,  Shaanxi Aerospace Power Hi-Tech Co., Ltd.',
    name: 'Shaanxi Aerospace Power Hi-Tech Co., Ltd.',
    symbol: '600343.SS',
  },
  {
    value: '600345.SS',
    label: '600345.SS,  WuHan Yangtze Communication Industry GroupCo.,Ltd',
    name: 'WuHan Yangtze Communication Industry GroupCo.,Ltd',
    symbol: '600345.SS',
  },
  {
    value: '600350.SS',
    label: '600350.SS,  Shandong Hi-speed Company Limited',
    name: 'Shandong Hi-speed Company Limited',
    symbol: '600350.SS',
  },
  {
    value: '600351.SS',
    label: '600351.SS,  Yabao Pharmaceutical Group Co., Ltd',
    name: 'Yabao Pharmaceutical Group Co., Ltd',
    symbol: '600351.SS',
  },
  {
    value: '600353.SS',
    label: '600353.SS,  Chengdu Xuguang Electronics Co., Ltd.',
    name: 'Chengdu Xuguang Electronics Co., Ltd.',
    symbol: '600353.SS',
  },
  {
    value: '600354.SS',
    label: '600354.SS,  Gansu Dunhuang Seed Group Co.,Ltd.',
    name: 'Gansu Dunhuang Seed Group Co.,Ltd.',
    symbol: '600354.SS',
  },
  {
    value: '600355.SS',
    label: '600355.SS,  Routon Electronic Co., Ltd.',
    name: 'Routon Electronic Co., Ltd.',
    symbol: '600355.SS',
  },
  {
    value: '600356.SS',
    label: '600356.SS,  Mudanjiang Hengfeng Paper Co.,Ltd',
    name: 'Mudanjiang Hengfeng Paper Co.,Ltd',
    symbol: '600356.SS',
  },
  {
    value: '600358.SS',
    label: '600358.SS,  China United Travel Co.,Ltd',
    name: 'China United Travel Co.,Ltd',
    symbol: '600358.SS',
  },
  {
    value: '600359.SS',
    label: '600359.SS,  Xinjiang Talimu Agriculture Development Co., Ltd.',
    name: 'Xinjiang Talimu Agriculture Development Co., Ltd.',
    symbol: '600359.SS',
  },
  {
    value: '600360.SS',
    label: '600360.SS,  Jilin Sino-Microelectronics Co., Ltd.',
    name: 'Jilin Sino-Microelectronics Co., Ltd.',
    symbol: '600360.SS',
  },
  {
    value: '600361.SS',
    label: '600361.SS,  Beijing Hualian Hypermarket Co., Ltd.',
    name: 'Beijing Hualian Hypermarket Co., Ltd.',
    symbol: '600361.SS',
  },
  {
    value: '600363.SS',
    label:
      '600363.SS,  Jiangxi Lianchuang Opto-Electronic Science&Technology Co.,Ltd',
    name: 'Jiangxi Lianchuang Opto-Electronic Science&Technology Co.,Ltd',
    symbol: '600363.SS',
  },
  {
    value: '600365.SS',
    label: '600365.SS,  Tonghua Grape Wine Co.,Ltd',
    name: 'Tonghua Grape Wine Co.,Ltd',
    symbol: '600365.SS',
  },
  {
    value: '600366.SS',
    label: '600366.SS,  Ningbo Yunsheng Co., Ltd.',
    name: 'Ningbo Yunsheng Co., Ltd.',
    symbol: '600366.SS',
  },
  {
    value: '600367.SS',
    label: '600367.SS,  Guizhou RedStar Developing Co.,Ltd.',
    name: 'Guizhou RedStar Developing Co.,Ltd.',
    symbol: '600367.SS',
  },
  {
    value: '600368.SS',
    label: '600368.SS,  Guangxi Wuzhou Communications Co., Ltd.',
    name: 'Guangxi Wuzhou Communications Co., Ltd.',
    symbol: '600368.SS',
  },
  {
    value: '600370.SS',
    label: '600370.SS,  Jiangsu Sanfame Polyester Material Co.,Ltd.',
    name: 'Jiangsu Sanfame Polyester Material Co.,Ltd.',
    symbol: '600370.SS',
  },
  {
    value: '600371.SS',
    label: '600371.SS,  Wanxiang Doneed Co., ltd',
    name: 'Wanxiang Doneed Co., ltd',
    symbol: '600371.SS',
  },
  {
    value: '600372.SS',
    label: '600372.SS,  China Avionics Systems Co.,Ltd.',
    name: 'China Avionics Systems Co.,Ltd.',
    symbol: '600372.SS',
  },
  {
    value: '600373.SS',
    label: '600373.SS,  Chinese Universe Publishing and Media Group Co., Ltd.',
    name: 'Chinese Universe Publishing and Media Group Co., Ltd.',
    symbol: '600373.SS',
  },
  {
    value: '600375.SS',
    label: '600375.SS,  Hanma Technology Group Co.,Ltd.',
    name: 'Hanma Technology Group Co.,Ltd.',
    symbol: '600375.SS',
  },
  {
    value: '600376.SS',
    label: '600376.SS,  Beijing Capital Development Co., Ltd.',
    name: 'Beijing Capital Development Co., Ltd.',
    symbol: '600376.SS',
  },
  {
    value: '600377.SS',
    label: '600377.SS,  Jiangsu Expressway Company Limited',
    name: 'Jiangsu Expressway Company Limited',
    symbol: '600377.SS',
  },
  {
    value: '600378.SS',
    label: '600378.SS,  Haohua Chemical Science & Technology Corp., Ltd.',
    name: 'Haohua Chemical Science & Technology Corp., Ltd.',
    symbol: '600378.SS',
  },
  {
    value: '600379.SS',
    label: '600379.SS,  Shaanxi Baoguang Vacuum Electric Device Co., Ltd.',
    name: 'Shaanxi Baoguang Vacuum Electric Device Co., Ltd.',
    symbol: '600379.SS',
  },
  {
    value: '600381.SS',
    label:
      '600381.SS,  Qinghai Spring Medicinal Resources Technology Co., Ltd.',
    name: 'Qinghai Spring Medicinal Resources Technology Co., Ltd.',
    symbol: '600381.SS',
  },
  {
    value: '600382.SS',
    label: '600382.SS,  Guangdong Mingzhu Group Co.,Ltd',
    name: 'Guangdong Mingzhu Group Co.,Ltd',
    symbol: '600382.SS',
  },
  {
    value: '600386.SS',
    label: '600386.SS,  Beijing Bashi Media Co., Ltd.',
    name: 'Beijing Bashi Media Co., Ltd.',
    symbol: '600386.SS',
  },
  {
    value: '600387.SS',
    label: '600387.SS,  HY Energy Group Co.,Ltd',
    name: 'HY Energy Group Co.,Ltd',
    symbol: '600387.SS',
  },
  {
    value: '600388.SS',
    label: '600388.SS,  Fujian Longking Co., Ltd.',
    name: 'Fujian Longking Co., Ltd.',
    symbol: '600388.SS',
  },
  {
    value: '600389.SS',
    label: '600389.SS,  Nantong Jiangshan Agrochemical & Chemicals Co.,Ltd.',
    name: 'Nantong Jiangshan Agrochemical & Chemicals Co.,Ltd.',
    symbol: '600389.SS',
  },
  {
    value: '600390.SS',
    label: '600390.SS,  Minmetals Capital Company Limited',
    name: 'Minmetals Capital Company Limited',
    symbol: '600390.SS',
  },
  {
    value: '600391.SS',
    label: '600391.SS,  Aecc Aero Science and Technology Co.,Ltd',
    name: 'Aecc Aero Science and Technology Co.,Ltd',
    symbol: '600391.SS',
  },
  {
    value: '600393.SS',
    label: '600393.SS,  Guangzhou Yuetai Group Co., Ltd.',
    name: 'Guangzhou Yuetai Group Co., Ltd.',
    symbol: '600393.SS',
  },
  {
    value: '600396.SS',
    label: '600396.SS,  Shenyang Jinshan Energy Co., Ltd.',
    name: 'Shenyang Jinshan Energy Co., Ltd.',
    symbol: '600396.SS',
  },
  {
    value: '600397.SS',
    label: '600397.SS,  Anyuan Coal Industry Group Co., Ltd.',
    name: 'Anyuan Coal Industry Group Co., Ltd.',
    symbol: '600397.SS',
  },
  {
    value: '600398.SS',
    label: '600398.SS,  Hla Group Corp., Ltd.',
    name: 'Hla Group Corp., Ltd.',
    symbol: '600398.SS',
  },
  {
    value: '600399.SS',
    label: '600399.SS,  Fushun Special Steel Co.,LTD.',
    name: 'Fushun Special Steel Co.,LTD.',
    symbol: '600399.SS',
  },
  {
    value: '600400.SS',
    label: '600400.SS,  Jiangsu Hongdou Industrial Co., Ltd',
    name: 'Jiangsu Hongdou Industrial Co., Ltd',
    symbol: '600400.SS',
  },
  {
    value: '600403.SS',
    label: '600403.SS,  Henan Dayou Energy Co., Ltd',
    name: 'Henan Dayou Energy Co., Ltd',
    symbol: '600403.SS',
  },
  {
    value: '600405.SS',
    label: '600405.SS,  Beijing Dynamic Power Co.,LTD',
    name: 'Beijing Dynamic Power Co.,LTD',
    symbol: '600405.SS',
  },
  {
    value: '600408.SS',
    label: '600408.SS,  Shanxi Antai Group Co.,Ltd',
    name: 'Shanxi Antai Group Co.,Ltd',
    symbol: '600408.SS',
  },
  {
    value: '600416.SS',
    label: '600416.SS,  Xiangtan Electric Manufacturing Co. Ltd.',
    name: 'Xiangtan Electric Manufacturing Co. Ltd.',
    symbol: '600416.SS',
  },
  {
    value: '600418.SS',
    label: '600418.SS,  Anhui Jianghuai Automobile Group Corp.,Ltd.',
    name: 'Anhui Jianghuai Automobile Group Corp.,Ltd.',
    symbol: '600418.SS',
  },
  {
    value: '600419.SS',
    label: '600419.SS,  Xinjiang Tianrun Dairy Co., Ltd.',
    name: 'Xinjiang Tianrun Dairy Co., Ltd.',
    symbol: '600419.SS',
  },
  {
    value: '600420.SS',
    label: '600420.SS,  Shanghai Shyndec Pharmaceutical Co., Ltd.',
    name: 'Shanghai Shyndec Pharmaceutical Co., Ltd.',
    symbol: '600420.SS',
  },
  {
    value: '600421.SS',
    label: '600421.SS,  Hubei Huarong Holding Co.,Ltd.',
    name: 'Hubei Huarong Holding Co.,Ltd.',
    symbol: '600421.SS',
  },
  {
    value: '600422.SS',
    label: '600422.SS,  KPC Pharmaceuticals, Inc.',
    name: 'KPC Pharmaceuticals, Inc.',
    symbol: '600422.SS',
  },
  {
    value: '600423.SS',
    label: '600423.SS,  Liuzhou Chemical Industry Co., Ltd.',
    name: 'Liuzhou Chemical Industry Co., Ltd.',
    symbol: '600423.SS',
  },
  {
    value: '600425.SS',
    label:
      '600425.SS,  Xinjiang Qingsong Building Materials and Chemicals (Group) Co, Ltd.',
    name: 'Xinjiang Qingsong Building Materials and Chemicals (Group) Co, Ltd.',
    symbol: '600425.SS',
  },
  {
    value: '600428.SS',
    label: '600428.SS,  COSCO SHIPPING Specialized Carriers Co.,Ltd.',
    name: 'COSCO SHIPPING Specialized Carriers Co.,Ltd.',
    symbol: '600428.SS',
  },
  {
    value: '600429.SS',
    label: '600429.SS,  Beijing Sanyuan Foods Co., Ltd.',
    name: 'Beijing Sanyuan Foods Co., Ltd.',
    symbol: '600429.SS',
  },
  {
    value: '600433.SS',
    label: '600433.SS,  Guangdong Guanhao High-Tech Co., Ltd.',
    name: 'Guangdong Guanhao High-Tech Co., Ltd.',
    symbol: '600433.SS',
  },
  {
    value: '600435.SS',
    label: '600435.SS,  North Navigation Control Technology Co.,Ltd.',
    name: 'North Navigation Control Technology Co.,Ltd.',
    symbol: '600435.SS',
  },
  {
    value: '600439.SS',
    label: '600439.SS,  Henan Rebecca Hair Products Co., Ltd.',
    name: 'Henan Rebecca Hair Products Co., Ltd.',
    symbol: '600439.SS',
  },
  {
    value: '600444.SS',
    label:
      '600444.SS,  Sinomach General Machinery Science & Technology Co., Ltd.',
    name: 'Sinomach General Machinery Science & Technology Co., Ltd.',
    symbol: '600444.SS',
  },
  {
    value: '600448.SS',
    label: '600448.SS,  Huafang Co.,Ltd',
    name: 'Huafang Co.,Ltd',
    symbol: '600448.SS',
  },
  {
    value: '600449.SS',
    label: '600449.SS,  Ningxia Building Materials Group Co.,Ltd',
    name: 'Ningxia Building Materials Group Co.,Ltd',
    symbol: '600449.SS',
  },
  {
    value: '600452.SS',
    label: '600452.SS,  Chongqing Fuling Electric Power Industrial Co., Ltd.',
    name: 'Chongqing Fuling Electric Power Industrial Co., Ltd.',
    symbol: '600452.SS',
  },
  {
    value: '600455.SS',
    label: "600455.SS,  But'one Information Corporation,Xi'an",
    name: "But'one Information Corporation,Xi'an",
    symbol: '600455.SS',
  },
  {
    value: '600456.SS',
    label: '600456.SS,  Baoji Titanium Industry Co., Ltd.',
    name: 'Baoji Titanium Industry Co., Ltd.',
    symbol: '600456.SS',
  },
  {
    value: '600458.SS',
    label: '600458.SS,  Zhuzhou Times New Material Technology Co., Ltd.',
    name: 'Zhuzhou Times New Material Technology Co., Ltd.',
    symbol: '600458.SS',
  },
  {
    value: '600459.SS',
    label: '600459.SS,  Sino-Platinum Metals Co.,Ltd',
    name: 'Sino-Platinum Metals Co.,Ltd',
    symbol: '600459.SS',
  },
  {
    value: '600461.SS',
    label: '600461.SS,  Jiangxi Hongcheng Environment Co.,Ltd.',
    name: 'Jiangxi Hongcheng Environment Co.,Ltd.',
    symbol: '600461.SS',
  },
  {
    value: '600462.SS',
    label: '600462.SS,  Hubei Geoway Investment Co.,Ltd.',
    name: 'Hubei Geoway Investment Co.,Ltd.',
    symbol: '600462.SS',
  },
  {
    value: '600463.SS',
    label: '600463.SS,  Beijing Airport High-Tech Park Co., Ltd.',
    name: 'Beijing Airport High-Tech Park Co., Ltd.',
    symbol: '600463.SS',
  },
  {
    value: '600466.SS',
    label: '600466.SS,  Sichuan Languang Development Co., Ltd.',
    name: 'Sichuan Languang Development Co., Ltd.',
    symbol: '600466.SS',
  },
  {
    value: '600467.SS',
    label: '600467.SS,  Shandong Homey Aquatic Development Co.,Ltd.',
    name: 'Shandong Homey Aquatic Development Co.,Ltd.',
    symbol: '600467.SS',
  },
  {
    value: '600468.SS',
    label: '600468.SS,  Tianjin Benefo Tejing Electric Co., Ltd.',
    name: 'Tianjin Benefo Tejing Electric Co., Ltd.',
    symbol: '600468.SS',
  },
  {
    value: '600469.SS',
    label: '600469.SS,  Aeolus Tyre Co., Ltd.',
    name: 'Aeolus Tyre Co., Ltd.',
    symbol: '600469.SS',
  },
  {
    value: '600470.SS',
    label: '600470.SS,  Anhui Liuguo Chemical Co., Ltd.',
    name: 'Anhui Liuguo Chemical Co., Ltd.',
    symbol: '600470.SS',
  },
  {
    value: '600475.SS',
    label: '600475.SS,  Wuxi Huaguang Environment & Energy Group Co.,Ltd.',
    name: 'Wuxi Huaguang Environment & Energy Group Co.,Ltd.',
    symbol: '600475.SS',
  },
  {
    value: '600476.SS',
    label: '600476.SS,  Hunan Copote Science Technology Co.,Ltd.',
    name: 'Hunan Copote Science Technology Co.,Ltd.',
    symbol: '600476.SS',
  },
  {
    value: '600477.SS',
    label: '600477.SS,  Hang Xiao Steel Structure Co.,Ltd',
    name: 'Hang Xiao Steel Structure Co.,Ltd',
    symbol: '600477.SS',
  },
  {
    value: '600478.SS',
    label: '600478.SS,  Hunan Corun New Energy Co., Ltd.',
    name: 'Hunan Corun New Energy Co., Ltd.',
    symbol: '600478.SS',
  },
  {
    value: '600479.SS',
    label: '600479.SS,  ZhuZhou QianJin Pharmaceutical Co.,Ltd',
    name: 'ZhuZhou QianJin Pharmaceutical Co.,Ltd',
    symbol: '600479.SS',
  },
  {
    value: '600480.SS',
    label: '600480.SS,  Ling Yun Industrial Corporation Limited',
    name: 'Ling Yun Industrial Corporation Limited',
    symbol: '600480.SS',
  },
  {
    value: '600481.SS',
    label: '600481.SS,  Shuangliang Eco-Energy Systems Co.,Ltd',
    name: 'Shuangliang Eco-Energy Systems Co.,Ltd',
    symbol: '600481.SS',
  },
  {
    value: '600482.SS',
    label: '600482.SS,  China Shipbuilding Industry Group Power Co., Ltd.',
    name: 'China Shipbuilding Industry Group Power Co., Ltd.',
    symbol: '600482.SS',
  },
  {
    value: '600487.SS',
    label: '600487.SS,  Hengtong Optic-Electric Co., Ltd.',
    name: 'Hengtong Optic-Electric Co., Ltd.',
    symbol: '600487.SS',
  },
  {
    value: '600488.SS',
    label: '600488.SS,  Tianjin Tianyao Pharmaceuticals Co., Ltd.',
    name: 'Tianjin Tianyao Pharmaceuticals Co., Ltd.',
    symbol: '600488.SS',
  },
  {
    value: '600490.SS',
    label: '600490.SS,  Pengxin International Mining Co.,Ltd',
    name: 'Pengxin International Mining Co.,Ltd',
    symbol: '600490.SS',
  },
  {
    value: '600493.SS',
    label: '600493.SS,  Fujian Fynex Textile Science & Technology Co., Ltd.',
    name: 'Fujian Fynex Textile Science & Technology Co., Ltd.',
    symbol: '600493.SS',
  },
  {
    value: '600495.SS',
    label: '600495.SS,  Jinxi Axle Company Limited',
    name: 'Jinxi Axle Company Limited',
    symbol: '600495.SS',
  },
  {
    value: '600496.SS',
    label: '600496.SS,  Changjiang & Jinggong Steel Building (Group) Co., Ltd',
    name: 'Changjiang & Jinggong Steel Building (Group) Co., Ltd',
    symbol: '600496.SS',
  },
  {
    value: '600497.SS',
    label: '600497.SS,  Yunnan Chihong Zinc & Germanium Co., Ltd.',
    name: 'Yunnan Chihong Zinc & Germanium Co., Ltd.',
    symbol: '600497.SS',
  },
  {
    value: '600499.SS',
    label: '600499.SS,  Keda Industrial Group Co., Ltd.',
    name: 'Keda Industrial Group Co., Ltd.',
    symbol: '600499.SS',
  },
  {
    value: '600500.SS',
    label: '600500.SS,  Sinochem International Corporation',
    name: 'Sinochem International Corporation',
    symbol: '600500.SS',
  },
  {
    value: '600501.SS',
    label: '600501.SS,  Aerosun Corporation',
    name: 'Aerosun Corporation',
    symbol: '600501.SS',
  },
  {
    value: '600502.SS',
    label: '600502.SS,  Anhui Construction Engineering Group Co., Ltd.',
    name: 'Anhui Construction Engineering Group Co., Ltd.',
    symbol: '600502.SS',
  },
  {
    value: '600503.SS',
    label: '600503.SS,  Deluxe Family Co., Ltd.',
    name: 'Deluxe Family Co., Ltd.',
    symbol: '600503.SS',
  },
  {
    value: '600505.SS',
    label: '600505.SS,  Sichuan Xichang Electric Power Co.,Ltd.',
    name: 'Sichuan Xichang Electric Power Co.,Ltd.',
    symbol: '600505.SS',
  },
  {
    value: '600506.SS',
    label: '600506.SS,  Xinjiang Korla Pear Co.,Ltd',
    name: 'Xinjiang Korla Pear Co.,Ltd',
    symbol: '600506.SS',
  },
  {
    value: '600507.SS',
    label: '600507.SS,  Fangda Special Steel Technology Co., Ltd.',
    name: 'Fangda Special Steel Technology Co., Ltd.',
    symbol: '600507.SS',
  },
  {
    value: '600508.SS',
    label: '600508.SS,  Shanghai Datun Energy Resources Co., Ltd.',
    name: 'Shanghai Datun Energy Resources Co., Ltd.',
    symbol: '600508.SS',
  },
  {
    value: '600509.SS',
    label: '600509.SS,  Xinjiang Tianfu Energy Co., Ltd.',
    name: 'Xinjiang Tianfu Energy Co., Ltd.',
    symbol: '600509.SS',
  },
  {
    value: '600510.SS',
    label: '600510.SS,  Black Peony (Group) Co., Ltd.',
    name: 'Black Peony (Group) Co., Ltd.',
    symbol: '600510.SS',
  },
  {
    value: '600512.SS',
    label: '600512.SS,  Tengda Construction Group Co., Ltd.',
    name: 'Tengda Construction Group Co., Ltd.',
    symbol: '600512.SS',
  },
  {
    value: '600513.SS',
    label: '600513.SS,  Jiangsu Lianhuan Pharmaceutical Co., Ltd.',
    name: 'Jiangsu Lianhuan Pharmaceutical Co., Ltd.',
    symbol: '600513.SS',
  },
  {
    value: '600515.SS',
    label: '600515.SS,  Hainan Airport Infrastructure Co., Ltd',
    name: 'Hainan Airport Infrastructure Co., Ltd',
    symbol: '600515.SS',
  },
  {
    value: '600517.SS',
    label: '600517.SS,  State Grid Yingda Co.,Ltd.',
    name: 'State Grid Yingda Co.,Ltd.',
    symbol: '600517.SS',
  },
  {
    value: '600518.SS',
    label: '600518.SS,  Kangmei Pharmaceutical Co., Ltd.',
    name: 'Kangmei Pharmaceutical Co., Ltd.',
    symbol: '600518.SS',
  },
  {
    value: '600520.SS',
    label: '600520.SS,  WenYi Trinity Technology Co., Ltd',
    name: 'WenYi Trinity Technology Co., Ltd',
    symbol: '600520.SS',
  },
  {
    value: '600523.SS',
    label: '600523.SS,  Guizhou Guihang Automotive Components Co.,Ltd',
    name: 'Guizhou Guihang Automotive Components Co.,Ltd',
    symbol: '600523.SS',
  },
  {
    value: '600525.SS',
    label: '600525.SS,  Changyuan Technology Group Ltd.',
    name: 'Changyuan Technology Group Ltd.',
    symbol: '600525.SS',
  },
  {
    value: '600526.SS',
    label:
      '600526.SS,  Zhejiang Feida Environmental Science & Technology Co., Ltd.',
    name: 'Zhejiang Feida Environmental Science & Technology Co., Ltd.',
    symbol: '600526.SS',
  },
  {
    value: '600527.SS',
    label: '600527.SS,  Jiangsu Jiangnan High Polymer Fiber Co.,Ltd',
    name: 'Jiangsu Jiangnan High Polymer Fiber Co.,Ltd',
    symbol: '600527.SS',
  },
  {
    value: '600528.SS',
    label: '600528.SS,  China Railway Hi-tech Industry Corporation Limited',
    name: 'China Railway Hi-tech Industry Corporation Limited',
    symbol: '600528.SS',
  },
  {
    value: '600530.SS',
    label: '600530.SS,  Shanghai Jiaoda Onlly Co.,Ltd',
    name: 'Shanghai Jiaoda Onlly Co.,Ltd',
    symbol: '600530.SS',
  },
  {
    value: '600531.SS',
    label: '600531.SS,  Henan Yuguang Gold&Lead Co.,Ltd.',
    name: 'Henan Yuguang Gold&Lead Co.,Ltd.',
    symbol: '600531.SS',
  },
  {
    value: '600532.SS',
    label: '600532.SS,  Shanghai Topcare Medical Services Co., Ltd.',
    name: 'Shanghai Topcare Medical Services Co., Ltd.',
    symbol: '600532.SS',
  },
  {
    value: '600533.SS',
    label: '600533.SS,  Nanjing Chixia Development Co., Ltd.',
    name: 'Nanjing Chixia Development Co., Ltd.',
    symbol: '600533.SS',
  },
  {
    value: '600535.SS',
    label: '600535.SS,  Tasly Pharmaceutical Group Co., Ltd',
    name: 'Tasly Pharmaceutical Group Co., Ltd',
    symbol: '600535.SS',
  },
  {
    value: '600537.SS',
    label: '600537.SS,  EGing Photovoltaic Technology Co.,Ltd.',
    name: 'EGing Photovoltaic Technology Co.,Ltd.',
    symbol: '600537.SS',
  },
  {
    value: '600538.SS',
    label: '600538.SS,  Beihai Gofar Chuanshan Biological Co., Ltd.',
    name: 'Beihai Gofar Chuanshan Biological Co., Ltd.',
    symbol: '600538.SS',
  },
  {
    value: '600539.SS',
    label: '600539.SS,  Lionhead Technology Development Co., Ltd.',
    name: 'Lionhead Technology Development Co., Ltd.',
    symbol: '600539.SS',
  },
  {
    value: '600540.SS',
    label: '600540.SS,  Xinjiang Sayram Modern Agriculture Co., Ltd',
    name: 'Xinjiang Sayram Modern Agriculture Co., Ltd',
    symbol: '600540.SS',
  },
  {
    value: '600543.SS',
    label: '600543.SS,  Gansu Mogao Industrial Development Co.,Ltd',
    name: 'Gansu Mogao Industrial Development Co.,Ltd',
    symbol: '600543.SS',
  },
  {
    value: '600545.SS',
    label: '600545.SS,  Saurer Intelligent Technology Co.,Ltd.',
    name: 'Saurer Intelligent Technology Co.,Ltd.',
    symbol: '600545.SS',
  },
  {
    value: '600546.SS',
    label: '600546.SS,  Shanxi Coal International Energy Group Co.,Ltd',
    name: 'Shanxi Coal International Energy Group Co.,Ltd',
    symbol: '600546.SS',
  },
  {
    value: '600550.SS',
    label: '600550.SS,  Baoding Tianwei Baobian Electric Co., Ltd.',
    name: 'Baoding Tianwei Baobian Electric Co., Ltd.',
    symbol: '600550.SS',
  },
  {
    value: '600551.SS',
    label: '600551.SS,  Time Publishing and Media Co., Ltd.',
    name: 'Time Publishing and Media Co., Ltd.',
    symbol: '600551.SS',
  },
  {
    value: '600552.SS',
    label: '600552.SS,  Triumph Science & Technology Co.,Ltd',
    name: 'Triumph Science & Technology Co.,Ltd',
    symbol: '600552.SS',
  },
  {
    value: '600556.SS',
    label: '600556.SS,  Inmyshow Digital Technology(Group)Co.,Ltd.',
    name: 'Inmyshow Digital Technology(Group)Co.,Ltd.',
    symbol: '600556.SS',
  },
  {
    value: '600557.SS',
    label: '600557.SS,  Jiangsu Kanion Pharmaceutical Co.,Ltd.',
    name: 'Jiangsu Kanion Pharmaceutical Co.,Ltd.',
    symbol: '600557.SS',
  },
  {
    value: '600558.SS',
    label: '600558.SS,  Atlantic China Welding Consumables, Inc.',
    name: 'Atlantic China Welding Consumables, Inc.',
    symbol: '600558.SS',
  },
  {
    value: '600560.SS',
    label: '600560.SS,  Beijing AriTime Intelligent Control Co., Ltd.',
    name: 'Beijing AriTime Intelligent Control Co., Ltd.',
    symbol: '600560.SS',
  },
  {
    value: '600561.SS',
    label: '600561.SS,  Jiangxi Changyun Co., Ltd.',
    name: 'Jiangxi Changyun Co., Ltd.',
    symbol: '600561.SS',
  },
  {
    value: '600563.SS',
    label: '600563.SS,  Xiamen Faratronic Co., Ltd.',
    name: 'Xiamen Faratronic Co., Ltd.',
    symbol: '600563.SS',
  },
  {
    value: '600565.SS',
    label: '600565.SS,  DIMA HOLDINGS Co. ,Ltd.',
    name: 'DIMA HOLDINGS Co. ,Ltd.',
    symbol: '600565.SS',
  },
  {
    value: '600567.SS',
    label: '600567.SS,  Shanying International Holdings Co.,Ltd',
    name: 'Shanying International Holdings Co.,Ltd',
    symbol: '600567.SS',
  },
  {
    value: '600568.SS',
    label: '600568.SS,  Zhongzhu Healthcare Holding Co.,Ltd',
    name: 'Zhongzhu Healthcare Holding Co.,Ltd',
    symbol: '600568.SS',
  },
  {
    value: '600569.SS',
    label: '600569.SS,  Anyang Iron and Steel Co., Ltd.',
    name: 'Anyang Iron and Steel Co., Ltd.',
    symbol: '600569.SS',
  },
  {
    value: '600571.SS',
    label: '600571.SS,  Sunyard Technology Co.,Ltd',
    name: 'Sunyard Technology Co.,Ltd',
    symbol: '600571.SS',
  },
  {
    value: '600572.SS',
    label: '600572.SS,  Zhejiang CONBA Pharmaceutical Co.,Ltd.',
    name: 'Zhejiang CONBA Pharmaceutical Co.,Ltd.',
    symbol: '600572.SS',
  },
  {
    value: '600573.SS',
    label: '600573.SS,  FuJian YanJing HuiQuan Brewery Co.,Ltd',
    name: 'FuJian YanJing HuiQuan Brewery Co.,Ltd',
    symbol: '600573.SS',
  },
  {
    value: '600575.SS',
    label: '600575.SS,  Huaihe Energy (Group) Co.,Ltd',
    name: 'Huaihe Energy (Group) Co.,Ltd',
    symbol: '600575.SS',
  },
  {
    value: '600576.SS',
    label: '600576.SS,  Zhejiang Sunriver Culture Co.,Ltd.',
    name: 'Zhejiang Sunriver Culture Co.,Ltd.',
    symbol: '600576.SS',
  },
  {
    value: '600577.SS',
    label: '600577.SS,  Tongling Jingda Special Magnet Wire Co., Ltd.',
    name: 'Tongling Jingda Special Magnet Wire Co., Ltd.',
    symbol: '600577.SS',
  },
  {
    value: '600578.SS',
    label: '600578.SS,  Beijing Jingneng Power Co., Ltd.',
    name: 'Beijing Jingneng Power Co., Ltd.',
    symbol: '600578.SS',
  },
  {
    value: '600579.SS',
    label: '600579.SS,  KraussMaffei Company Limited',
    name: 'KraussMaffei Company Limited',
    symbol: '600579.SS',
  },
  {
    value: '600580.SS',
    label: '600580.SS,  Wolong Electric Group Co.,Ltd.',
    name: 'Wolong Electric Group Co.,Ltd.',
    symbol: '600580.SS',
  },
  {
    value: '600581.SS',
    label: '600581.SS,  XinJiang Ba Yi Iron & Steel Co.,Ltd.',
    name: 'XinJiang Ba Yi Iron & Steel Co.,Ltd.',
    symbol: '600581.SS',
  },
  {
    value: '600586.SS',
    label:
      '600586.SS,  Shandong Jinjing Science and Technology Stock Co., Ltd.',
    name: 'Shandong Jinjing Science and Technology Stock Co., Ltd.',
    symbol: '600586.SS',
  },
  {
    value: '600587.SS',
    label: '600587.SS,  Shinva Medical Instrument Co., Ltd.',
    name: 'Shinva Medical Instrument Co., Ltd.',
    symbol: '600587.SS',
  },
  {
    value: '600589.SS',
    label: '600589.SS,  Guangdong Rongtai Industry Co.,Ltd',
    name: 'Guangdong Rongtai Industry Co.,Ltd',
    symbol: '600589.SS',
  },
  {
    value: '600590.SS',
    label: '600590.SS,  Tellhow Sci-Tech Co., Ltd.',
    name: 'Tellhow Sci-Tech Co., Ltd.',
    symbol: '600590.SS',
  },
  {
    value: 'VHNAU',
    label: 'VHNAU,  Vahanna Tech Edge Acquisition I Corp.',
    name: 'Vahanna Tech Edge Acquisition I Corp.',
    symbol: 'VHNAU',
  },
  {
    value: '600592.SS',
    label: '600592.SS,  Fujian Longxi Bearing (Group) Co., Ltd',
    name: 'Fujian Longxi Bearing (Group) Co., Ltd',
    symbol: '600592.SS',
  },
  {
    value: '600593.SS',
    label: '600593.SS,  Dalian Sunasia Tourism Holding CO.,LTD',
    name: 'Dalian Sunasia Tourism Holding CO.,LTD',
    symbol: '600593.SS',
  },
  {
    value: '600594.SS',
    label: '600594.SS,  Guizhou Yibai Pharmaceutical Co., Ltd.',
    name: 'Guizhou Yibai Pharmaceutical Co., Ltd.',
    symbol: '600594.SS',
  },
  {
    value: '600595.SS',
    label: '600595.SS,  Henan Zhongfu Industrial Co.,Ltd',
    name: 'Henan Zhongfu Industrial Co.,Ltd',
    symbol: '600595.SS',
  },
  {
    value: '600596.SS',
    label: '600596.SS,  Zhejiang Xinan Chemical Industrial Group Co.,Ltd',
    name: 'Zhejiang Xinan Chemical Industrial Group Co.,Ltd',
    symbol: '600596.SS',
  },
  {
    value: '600597.SS',
    label: '600597.SS,  Bright Dairy & Food Co.,Ltd',
    name: 'Bright Dairy & Food Co.,Ltd',
    symbol: '600597.SS',
  },
  {
    value: '600599.SS',
    label: '600599.SS,  Panda Financial Holding Corp., Ltd.',
    name: 'Panda Financial Holding Corp., Ltd.',
    symbol: '600599.SS',
  },
  {
    value: '600601.SS',
    label: '600601.SS,  Founder Technology Group Co.,Ltd.',
    name: 'Founder Technology Group Co.,Ltd.',
    symbol: '600601.SS',
  },
  {
    value: '600602.SS',
    label: '600602.SS,  INESA Intelligent Tech Inc.',
    name: 'INESA Intelligent Tech Inc.',
    symbol: '600602.SS',
  },
  {
    value: '600603.SS',
    label: '600603.SS,  Guanghui Logistics Co.Ltd',
    name: 'Guanghui Logistics Co.Ltd',
    symbol: '600603.SS',
  },
  {
    value: '600604.SS',
    label: '600604.SS,  Shanghai Shibei Hi-Tech Co.,Ltd.',
    name: 'Shanghai Shibei Hi-Tech Co.,Ltd.',
    symbol: '600604.SS',
  },
  {
    value: '600605.SS',
    label: '600605.SS,  Shanghai Huitong Energy Co.,Ltd',
    name: 'Shanghai Huitong Energy Co.,Ltd',
    symbol: '600605.SS',
  },
  {
    value: '600608.SS',
    label: '600608.SS,  Shanghai Broadband Technology Co.,Ltd',
    name: 'Shanghai Broadband Technology Co.,Ltd',
    symbol: '600608.SS',
  },
  {
    value: '600609.SS',
    label: '600609.SS,  Shenyang Jinbei Automotive Company Limited',
    name: 'Shenyang Jinbei Automotive Company Limited',
    symbol: '600609.SS',
  },
  {
    value: '600610.SS',
    label: '600610.SS,  Shanghai Zhongyida Co., Ltd.',
    name: 'Shanghai Zhongyida Co., Ltd.',
    symbol: '600610.SS',
  },
  {
    value: '600611.SS',
    label: '600611.SS,  Dazhong Transportation (Group) Co., Ltd.',
    name: 'Dazhong Transportation (Group) Co., Ltd.',
    symbol: '600611.SS',
  },
  {
    value: '600612.SS',
    label: '600612.SS,  Lao Feng Xiang Co., Ltd.',
    name: 'Lao Feng Xiang Co., Ltd.',
    symbol: '600612.SS',
  },
  {
    value: '600613.SS',
    label:
      '600613.SS,  Shanghai Shenqi Pharmaceutical Investment Management Co., Ltd.',
    name: 'Shanghai Shenqi Pharmaceutical Investment Management Co., Ltd.',
    symbol: '600613.SS',
  },
  {
    value: '600615.SS',
    label: '600615.SS,  Chongqing Fenghwa Group Co., Ltd',
    name: 'Chongqing Fenghwa Group Co., Ltd',
    symbol: '600615.SS',
  },
  {
    value: '600616.SS',
    label: '600616.SS,  Shanghai Jinfeng Wine Company Limited',
    name: 'Shanghai Jinfeng Wine Company Limited',
    symbol: '600616.SS',
  },
  {
    value: '600617.SS',
    label: '600617.SS,  Shanxi Guoxin Energy Corporation Limited',
    name: 'Shanxi Guoxin Energy Corporation Limited',
    symbol: '600617.SS',
  },
  {
    value: '600618.SS',
    label: '600618.SS,  Shanghai Chlor-Alkali Chemical Co., Ltd.',
    name: 'Shanghai Chlor-Alkali Chemical Co., Ltd.',
    symbol: '600618.SS',
  },
  {
    value: '600619.SS',
    label: '600619.SS,  Shanghai Highly (Group) Co., Ltd.',
    name: 'Shanghai Highly (Group) Co., Ltd.',
    symbol: '600619.SS',
  },
  {
    value: '600620.SS',
    label: '600620.SS,  Shanghai Tianchen Co.,Ltd',
    name: 'Shanghai Tianchen Co.,Ltd',
    symbol: '600620.SS',
  },
  {
    value: '600621.SS',
    label: '600621.SS,  Shanghai Chinafortune Co., Ltd.',
    name: 'Shanghai Chinafortune Co., Ltd.',
    symbol: '600621.SS',
  },
  {
    value: '600622.SS',
    label: '600622.SS,  Everbright Jiabao Co., Ltd.',
    name: 'Everbright Jiabao Co., Ltd.',
    symbol: '600622.SS',
  },
  {
    value: '600623.SS',
    label: '600623.SS,  Shanghai Huayi Group Corporation Limited',
    name: 'Shanghai Huayi Group Corporation Limited',
    symbol: '600623.SS',
  },
  {
    value: '600624.SS',
    label: '600624.SS,  Shanghai Fudan Forward S&T Co., Ltd',
    name: 'Shanghai Fudan Forward S&T Co., Ltd',
    symbol: '600624.SS',
  },
  {
    value: '600626.SS',
    label: '600626.SS,  Shanghai Shenda Co., Ltd',
    name: 'Shanghai Shenda Co., Ltd',
    symbol: '600626.SS',
  },
  {
    value: '600628.SS',
    label: '600628.SS,  Shanghai New World Co., Ltd',
    name: 'Shanghai New World Co., Ltd',
    symbol: '600628.SS',
  },
  {
    value: '600629.SS',
    label: '600629.SS,  Arcplus Group PLC',
    name: 'Arcplus Group PLC',
    symbol: '600629.SS',
  },
  {
    value: '600630.SS',
    label: '600630.SS,  Shanghai Dragon Corporation',
    name: 'Shanghai Dragon Corporation',
    symbol: '600630.SS',
  },
  {
    value: '600633.SS',
    label: '600633.SS,  Zhejiang Daily Digital Culture Group Co.,Ltd.',
    name: 'Zhejiang Daily Digital Culture Group Co.,Ltd.',
    symbol: '600633.SS',
  },
  {
    value: '600635.SS',
    label: '600635.SS,  Shanghai Dazhong Public Utilities(Group) Co.,Ltd.',
    name: 'Shanghai Dazhong Public Utilities(Group) Co.,Ltd.',
    symbol: '600635.SS',
  },
  {
    value: '600636.SS',
    label: '600636.SS,  China Reform Culture Holdings Co., Ltd.',
    name: 'China Reform Culture Holdings Co., Ltd.',
    symbol: '600636.SS',
  },
  {
    value: '600637.SS',
    label: '600637.SS,  Shanghai Oriental Pearl Group Co., Ltd.',
    name: 'Shanghai Oriental Pearl Group Co., Ltd.',
    symbol: '600637.SS',
  },
  {
    value: '600638.SS',
    label: '600638.SS,  Shanghai New Huang Pu Industrial Group Co., Ltd.',
    name: 'Shanghai New Huang Pu Industrial Group Co., Ltd.',
    symbol: '600638.SS',
  },
  {
    value: '600639.SS',
    label:
      '600639.SS,  Shanghai Jinqiao Export Processing Zone Development Co.,Ltd',
    name: 'Shanghai Jinqiao Export Processing Zone Development Co.,Ltd',
    symbol: '600639.SS',
  },
  {
    value: '600641.SS',
    label: '600641.SS,  Shanghai Wanye Enterprises Co.,Ltd',
    name: 'Shanghai Wanye Enterprises Co.,Ltd',
    symbol: '600641.SS',
  },
  {
    value: '600644.SS',
    label: '600644.SS,  Leshan Electric Power Co.,Ltd',
    name: 'Leshan Electric Power Co.,Ltd',
    symbol: '600644.SS',
  },
  {
    value: '600645.SS',
    label: '600645.SS,  Vcanbio Cell & Gene Engineering Corp., Ltd',
    name: 'Vcanbio Cell & Gene Engineering Corp., Ltd',
    symbol: '600645.SS',
  },
  {
    value: '600647.SS',
    label: '600647.SS,  Shanghai Tongda Venture Capital Co., Ltd.',
    name: 'Shanghai Tongda Venture Capital Co., Ltd.',
    symbol: '600647.SS',
  },
  {
    value: '600649.SS',
    label: '600649.SS,  Shanghai Chengtou Holding Co.,Ltd',
    name: 'Shanghai Chengtou Holding Co.,Ltd',
    symbol: '600649.SS',
  },
  {
    value: '600650.SS',
    label: '600650.SS,  Shanghai Jin Jiang Online Network Service Co., Ltd.',
    name: 'Shanghai Jin Jiang Online Network Service Co., Ltd.',
    symbol: '600650.SS',
  },
  {
    value: '600651.SS',
    label: '600651.SS,  Shanghai Feilo Acoustics Co.,Ltd',
    name: 'Shanghai Feilo Acoustics Co.,Ltd',
    symbol: '600651.SS',
  },
  {
    value: '600653.SS',
    label: '600653.SS,  Liaoning Shenhua Holdings Co.,Ltd',
    name: 'Liaoning Shenhua Holdings Co.,Ltd',
    symbol: '600653.SS',
  },
  {
    value: '600654.SS',
    label: '600654.SS,  China Security Co., Ltd.',
    name: 'China Security Co., Ltd.',
    symbol: '600654.SS',
  },
  {
    value: '600658.SS',
    label:
      '600658.SS,  Beijing Electronic Zone Investment and Development Group Co., Ltd.',
    name: 'Beijing Electronic Zone Investment and Development Group Co., Ltd.',
    symbol: '600658.SS',
  },
  {
    value: '600661.SS',
    label:
      '600661.SS,  Shanghai Xinnanyang Only Education & Technology Co., Ltd.',
    name: 'Shanghai Xinnanyang Only Education & Technology Co., Ltd.',
    symbol: '600661.SS',
  },
  {
    value: '600662.SS',
    label: '600662.SS,  Shanghai Foreign Service Holding Group CO.,Ltd.',
    name: 'Shanghai Foreign Service Holding Group CO.,Ltd.',
    symbol: '600662.SS',
  },
  {
    value: '600664.SS',
    label: '600664.SS,  Harbin Pharmaceutical Group Co., Ltd.',
    name: 'Harbin Pharmaceutical Group Co., Ltd.',
    symbol: '600664.SS',
  },
  {
    value: '600665.SS',
    label: '600665.SS,  Tande Co., Ltd.',
    name: 'Tande Co., Ltd.',
    symbol: '600665.SS',
  },
  {
    value: '600666.SS',
    label: '600666.SS,  Aurora Optoelectronics Co.,Ltd.',
    name: 'Aurora Optoelectronics Co.,Ltd.',
    symbol: '600666.SS',
  },
  {
    value: '600668.SS',
    label: '600668.SS,  Zhejiang Jianfeng Group Co., Ltd.',
    name: 'Zhejiang Jianfeng Group Co., Ltd.',
    symbol: '600668.SS',
  },
  {
    value: '600671.SS',
    label: '600671.SS,  Hangzhou TianMuShan Pharmaceutical Enterprise Co.,Ltd',
    name: 'Hangzhou TianMuShan Pharmaceutical Enterprise Co.,Ltd',
    symbol: '600671.SS',
  },
  {
    value: '600673.SS',
    label: '600673.SS,  Guangdong Hec Technologyholding Co., Ltd',
    name: 'Guangdong Hec Technologyholding Co., Ltd',
    symbol: '600673.SS',
  },
  {
    value: '600675.SS',
    label: '600675.SS,  China Enterprise Company Limited',
    name: 'China Enterprise Company Limited',
    symbol: '600675.SS',
  },
  {
    value: '600676.SS',
    label: '600676.SS,  Shanghai Jiao Yun Group Co., Ltd.',
    name: 'Shanghai Jiao Yun Group Co., Ltd.',
    symbol: '600676.SS',
  },
  {
    value: '600678.SS',
    label: '600678.SS,  Sichuan Golden Summit (group) Joint-Stock Co., Ltd.',
    name: 'Sichuan Golden Summit (group) Joint-Stock Co., Ltd.',
    symbol: '600678.SS',
  },
  {
    value: '600679.SS',
    label: '600679.SS,  Shanghai Phoenix Enterprise (Group) Co., Ltd.',
    name: 'Shanghai Phoenix Enterprise (Group) Co., Ltd.',
    symbol: '600679.SS',
  },
  {
    value: '600682.SS',
    label: '600682.SS,  Nanjing Xinjiekou Department Store Co., Ltd.',
    name: 'Nanjing Xinjiekou Department Store Co., Ltd.',
    symbol: '600682.SS',
  },
  {
    value: '600683.SS',
    label: '600683.SS,  Metro Land Corporation Ltd.',
    name: 'Metro Land Corporation Ltd.',
    symbol: '600683.SS',
  },
  {
    value: '600684.SS',
    label: '600684.SS,  Guangzhou Pearl River Development Group Co., Ltd.',
    name: 'Guangzhou Pearl River Development Group Co., Ltd.',
    symbol: '600684.SS',
  },
  {
    value: '600685.SS',
    label:
      '600685.SS,  CSSC Offshore & Marine Engineering (Group) Company Limited',
    name: 'CSSC Offshore & Marine Engineering (Group) Company Limited',
    symbol: '600685.SS',
  },
  {
    value: '600686.SS',
    label: '600686.SS,  Xiamen King Long Motor Group Co., Ltd.',
    name: 'Xiamen King Long Motor Group Co., Ltd.',
    symbol: '600686.SS',
  },
  {
    value: '600689.SS',
    label: '600689.SS,  Shanghai Sanmao Enterprise (Group) Co., Ltd.',
    name: 'Shanghai Sanmao Enterprise (Group) Co., Ltd.',
    symbol: '600689.SS',
  },
  {
    value: '600691.SS',
    label: '600691.SS,  Yangmei Chemical Co.,Ltd',
    name: 'Yangmei Chemical Co.,Ltd',
    symbol: '600691.SS',
  },
  {
    value: '600692.SS',
    label: '600692.SS,  Shang Hai Ya Tong Co.,Ltd.',
    name: 'Shang Hai Ya Tong Co.,Ltd.',
    symbol: '600692.SS',
  },
  {
    value: '600693.SS',
    label: '600693.SS,  Fujian Dongbai (Group) Co.,Ltd.',
    name: 'Fujian Dongbai (Group) Co.,Ltd.',
    symbol: '600693.SS',
  },
  {
    value: '600694.SS',
    label: '600694.SS,  Dashang Co., Ltd.',
    name: 'Dashang Co., Ltd.',
    symbol: '600694.SS',
  },
  {
    value: '600696.SS',
    label: '600696.SS,  Shanghai Guijiu Co.,Ltd.',
    name: 'Shanghai Guijiu Co.,Ltd.',
    symbol: '600696.SS',
  },
  {
    value: '600697.SS',
    label: '600697.SS,  Chang Chun Eurasia Group Co., Ltd.',
    name: 'Chang Chun Eurasia Group Co., Ltd.',
    symbol: '600697.SS',
  },
  {
    value: '600698.SS',
    label: '600698.SS,  Hunan Tyen Machinery Co.,Ltd',
    name: 'Hunan Tyen Machinery Co.,Ltd',
    symbol: '600698.SS',
  },
  {
    value: '600702.SS',
    label: '600702.SS,  Shede Spirits Co., Ltd.',
    name: 'Shede Spirits Co., Ltd.',
    symbol: '600702.SS',
  },
  {
    value: '600706.SS',
    label: "600706.SS,  Xi'an Qujiang Cultural Tourism Co., Ltd.",
    name: "Xi'an Qujiang Cultural Tourism Co., Ltd.",
    symbol: '600706.SS',
  },
  {
    value: '600707.SS',
    label: '600707.SS,  Caihong Display Devices Co.,Ltd.',
    name: 'Caihong Display Devices Co.,Ltd.',
    symbol: '600707.SS',
  },
  {
    value: '600708.SS',
    label: '600708.SS,  Bright Real Estate Group Co.,Limited',
    name: 'Bright Real Estate Group Co.,Limited',
    symbol: '600708.SS',
  },
  {
    value: '600710.SS',
    label: '600710.SS,  Sumec Corporation Limited',
    name: 'Sumec Corporation Limited',
    symbol: '600710.SS',
  },
  {
    value: '600712.SS',
    label: '600712.SS,  Nanning Department Store Co., Ltd.',
    name: 'Nanning Department Store Co., Ltd.',
    symbol: '600712.SS',
  },
  {
    value: '600713.SS',
    label: '600713.SS,  NanJing Pharmaceutical Company Limited',
    name: 'NanJing Pharmaceutical Company Limited',
    symbol: '600713.SS',
  },
  {
    value: '600714.SS',
    label: '600714.SS,  Qinghai Jinrui Mineral Development Co., Ltd',
    name: 'Qinghai Jinrui Mineral Development Co., Ltd',
    symbol: '600714.SS',
  },
  {
    value: '600715.SS',
    label: '600715.SS,  Cultural Investment Holdings Co.,Ltd',
    name: 'Cultural Investment Holdings Co.,Ltd',
    symbol: '600715.SS',
  },
  {
    value: '600716.SS',
    label: '600716.SS,  Jiangsu Phoenix Property Investment Company Limited',
    name: 'Jiangsu Phoenix Property Investment Company Limited',
    symbol: '600716.SS',
  },
  {
    value: '600717.SS',
    label: '600717.SS,  Tianjin Port Co., Ltd.',
    name: 'Tianjin Port Co., Ltd.',
    symbol: '600717.SS',
  },
  {
    value: '600718.SS',
    label: '600718.SS,  Neusoft Corporation',
    name: 'Neusoft Corporation',
    symbol: '600718.SS',
  },
  {
    value: '600719.SS',
    label: '600719.SS,  Dalian Thermal Power Co.,Ltd.',
    name: 'Dalian Thermal Power Co.,Ltd.',
    symbol: '600719.SS',
  },
  {
    value: '600721.SS',
    label: '600721.SS,  Xinjiang Bai Hua Cun Pharma Tech Co.,Ltd',
    name: 'Xinjiang Bai Hua Cun Pharma Tech Co.,Ltd',
    symbol: '600721.SS',
  },
  {
    value: '600722.SS',
    label: '600722.SS,  HeBei Jinniu Chemical Industry Co.,Ltd',
    name: 'HeBei Jinniu Chemical Industry Co.,Ltd',
    symbol: '600722.SS',
  },
  {
    value: '600724.SS',
    label: '600724.SS,  Ningbo Fuda Company Limited',
    name: 'Ningbo Fuda Company Limited',
    symbol: '600724.SS',
  },
  {
    value: '600725.SS',
    label: '600725.SS,  Yunnan Yunwei Company Limited',
    name: 'Yunnan Yunwei Company Limited',
    symbol: '600725.SS',
  },
  {
    value: '600726.SS',
    label: '600726.SS,  Huadian Energy Company Limited',
    name: 'Huadian Energy Company Limited',
    symbol: '600726.SS',
  },
  {
    value: '600727.SS',
    label: '600727.SS,  Shandong Lubei Chemical Co., Ltd.',
    name: 'Shandong Lubei Chemical Co., Ltd.',
    symbol: '600727.SS',
  },
  {
    value: '600728.SS',
    label: '600728.SS,  Pci Technology Group Co.,Ltd.',
    name: 'Pci Technology Group Co.,Ltd.',
    symbol: '600728.SS',
  },
  {
    value: '600730.SS',
    label: '600730.SS,  China Hi-Tech Group Co., Ltd.',
    name: 'China Hi-Tech Group Co., Ltd.',
    symbol: '600730.SS',
  },
  {
    value: '600731.SS',
    label: '600731.SS,  Hunan Haili Chemical Industry Co., Ltd.',
    name: 'Hunan Haili Chemical Industry Co., Ltd.',
    symbol: '600731.SS',
  },
  {
    value: '600732.SS',
    label: '600732.SS,  Shanghai Aiko Solar Energy Co., Ltd.',
    name: 'Shanghai Aiko Solar Energy Co., Ltd.',
    symbol: '600732.SS',
  },
  {
    value: '600733.SS',
    label: '600733.SS,  BAIC BluePark New Energy Technology Co.,Ltd.',
    name: 'BAIC BluePark New Energy Technology Co.,Ltd.',
    symbol: '600733.SS',
  },
  {
    value: '600734.SS',
    label: '600734.SS,  Fujian Start Group Co.Ltd',
    name: 'Fujian Start Group Co.Ltd',
    symbol: '600734.SS',
  },
  {
    value: '600735.SS',
    label: '600735.SS,  Shandong Hiking International Co.,Ltd',
    name: 'Shandong Hiking International Co.,Ltd',
    symbol: '600735.SS',
  },
  {
    value: '600736.SS',
    label: '600736.SS,  Suzhou New District Hi-Tech Industrial Co.,Ltd',
    name: 'Suzhou New District Hi-Tech Industrial Co.,Ltd',
    symbol: '600736.SS',
  },
  {
    value: '600737.SS',
    label: '600737.SS,  Cofco Sugar Holding CO.,LTD.',
    name: 'Cofco Sugar Holding CO.,LTD.',
    symbol: '600737.SS',
  },
  {
    value: '600738.SS',
    label: '600738.SS,  Lanzhou Lishang Guochao Industrial Group Co.,Ltd',
    name: 'Lanzhou Lishang Guochao Industrial Group Co.,Ltd',
    symbol: '600738.SS',
  },
  {
    value: '600740.SS',
    label: '600740.SS,  Shanxi Coking Co., Ltd.',
    name: 'Shanxi Coking Co., Ltd.',
    symbol: '600740.SS',
  },
  {
    value: '600742.SS',
    label: '600742.SS,  Changchun FAWAY Automobile Components Co.,Ltd',
    name: 'Changchun FAWAY Automobile Components Co.,Ltd',
    symbol: '600742.SS',
  },
  {
    value: '600743.SS',
    label: '600743.SS,  Huayuan Property Co.,Ltd.',
    name: 'Huayuan Property Co.,Ltd.',
    symbol: '600743.SS',
  },
  {
    value: '600744.SS',
    label: '600744.SS,  DaTang HuaYin Electric Power CO.,LTD',
    name: 'DaTang HuaYin Electric Power CO.,LTD',
    symbol: '600744.SS',
  },
  {
    value: '600746.SS',
    label: '600746.SS,  Jiangsu SOPO Chemical Co. Ltd.',
    name: 'Jiangsu SOPO Chemical Co. Ltd.',
    symbol: '600746.SS',
  },
  {
    value: '600748.SS',
    label: '600748.SS,  Shanghai Industrial Development Co.,Ltd',
    name: 'Shanghai Industrial Development Co.,Ltd',
    symbol: '600748.SS',
  },
  {
    value: '600749.SS',
    label: '600749.SS,  Tibet Tourism Co.,Ltd',
    name: 'Tibet Tourism Co.,Ltd',
    symbol: '600749.SS',
  },
  {
    value: '600750.SS',
    label: '600750.SS,  Jiang Zhong Pharmaceutical Co.,Ltd',
    name: 'Jiang Zhong Pharmaceutical Co.,Ltd',
    symbol: '600750.SS',
  },
  {
    value: '600751.SS',
    label: '600751.SS,  HNA Technology Co.,Ltd.',
    name: 'HNA Technology Co.,Ltd.',
    symbol: '600751.SS',
  },
  {
    value: '600753.SS',
    label: '600753.SS,  Fujian Oriental Silver Star Investment Co., Ltd.',
    name: 'Fujian Oriental Silver Star Investment Co., Ltd.',
    symbol: '600753.SS',
  },
  {
    value: '600756.SS',
    label: '600756.SS,  Inspur Software Co., Ltd.',
    name: 'Inspur Software Co., Ltd.',
    symbol: '600756.SS',
  },
  {
    value: '600757.SS',
    label: '600757.SS,  Changjiang Publishing & Media Co.,Ltd',
    name: 'Changjiang Publishing & Media Co.,Ltd',
    symbol: '600757.SS',
  },
  {
    value: '600758.SS',
    label: '600758.SS,  LIAONING ENERGY INDUSTRY Co.,LTD',
    name: 'LIAONING ENERGY INDUSTRY Co.,LTD',
    symbol: '600758.SS',
  },
  {
    value: '600759.SS',
    label: '600759.SS,  Geo-Jade Petroleum Corporation',
    name: 'Geo-Jade Petroleum Corporation',
    symbol: '600759.SS',
  },
  {
    value: '600760.SS',
    label: '600760.SS,  AVIC Shenyang Aircraft Company Limited',
    name: 'AVIC Shenyang Aircraft Company Limited',
    symbol: '600760.SS',
  },
  {
    value: '600761.SS',
    label: '600761.SS,  Anhui Heli Co.,Ltd.',
    name: 'Anhui Heli Co.,Ltd.',
    symbol: '600761.SS',
  },
  {
    value: '600764.SS',
    label: '600764.SS,  China Marine Information Electronics Company Limited',
    name: 'China Marine Information Electronics Company Limited',
    symbol: '600764.SS',
  },
  {
    value: '600765.SS',
    label: '600765.SS,  AVIC Heavy Machinery Co., Ltd.',
    name: 'AVIC Heavy Machinery Co., Ltd.',
    symbol: '600765.SS',
  },
  {
    value: '600766.SS',
    label: '600766.SS,  YanTai Yuancheng Gold Co., Ltd.',
    name: 'YanTai Yuancheng Gold Co., Ltd.',
    symbol: '600766.SS',
  },
  {
    value: '600767.SS',
    label:
      '600767.SS,  Winsan (Chengdu) Medical Science and Technology Co., Ltd.',
    name: 'Winsan (Chengdu) Medical Science and Technology Co., Ltd.',
    symbol: '600767.SS',
  },
  {
    value: '600768.SS',
    label: '600768.SS,  Ningbo Fubang Jingye Group Co.,Ltd',
    name: 'Ningbo Fubang Jingye Group Co.,Ltd',
    symbol: '600768.SS',
  },
  {
    value: '600769.SS',
    label: '600769.SS,  Wuhan Xianglong Power Industry Co.Ltd',
    name: 'Wuhan Xianglong Power Industry Co.Ltd',
    symbol: '600769.SS',
  },
  {
    value: '600770.SS',
    label: '600770.SS,  Jiangsu Zongyi Co.,LTD',
    name: 'Jiangsu Zongyi Co.,LTD',
    symbol: '600770.SS',
  },
  {
    value: '600771.SS',
    label: '600771.SS,  GuangYuYuan Chinese Herbal Medicine Co., Ltd.',
    name: 'GuangYuYuan Chinese Herbal Medicine Co., Ltd.',
    symbol: '600771.SS',
  },
  {
    value: '600773.SS',
    label: '600773.SS,  Tibet Urban Development and Investment Co.,LTD',
    name: 'Tibet Urban Development and Investment Co.,LTD',
    symbol: '600773.SS',
  },
  {
    value: '600774.SS',
    label: '600774.SS,  Hanshang Group Co., Ltd.',
    name: 'Hanshang Group Co., Ltd.',
    symbol: '600774.SS',
  },
  {
    value: '600775.SS',
    label: '600775.SS,  Nanjing Panda Electronics Company Limited',
    name: 'Nanjing Panda Electronics Company Limited',
    symbol: '600775.SS',
  },
  {
    value: '600776.SS',
    label: '600776.SS,  Eastern Communications Co., Ltd.',
    name: 'Eastern Communications Co., Ltd.',
    symbol: '600776.SS',
  },
  {
    value: '600777.SS',
    label: '600777.SS,  Shandong Xinchao Energy Corporation Limited',
    name: 'Shandong Xinchao Energy Corporation Limited',
    symbol: '600777.SS',
  },
  {
    value: '600778.SS',
    label: '600778.SS,  Xinjiang Youhao(Group)Co.,Ltd',
    name: 'Xinjiang Youhao(Group)Co.,Ltd',
    symbol: '600778.SS',
  },
  {
    value: '600780.SS',
    label: '600780.SS,  Top Energy Company Ltd.Shanxi',
    name: 'Top Energy Company Ltd.Shanxi',
    symbol: '600780.SS',
  },
  {
    value: '600781.SS',
    label: '600781.SS,  FUREN Group Pharmaceutical Co., Ltd.',
    name: 'FUREN Group Pharmaceutical Co., Ltd.',
    symbol: '600781.SS',
  },
  {
    value: '600783.SS',
    label: '600783.SS,  Luxin Venture Capital Group Co., Ltd.',
    name: 'Luxin Venture Capital Group Co., Ltd.',
    symbol: '600783.SS',
  },
  {
    value: '600784.SS',
    label: '600784.SS,  Luyin Investment Group Co.,Ltd.',
    name: 'Luyin Investment Group Co.,Ltd.',
    symbol: '600784.SS',
  },
  {
    value: '600785.SS',
    label: '600785.SS,  Yinchuan Xinhua Commercial (Group) Co., Ltd.',
    name: 'Yinchuan Xinhua Commercial (Group) Co., Ltd.',
    symbol: '600785.SS',
  },
  {
    value: '600787.SS',
    label: '600787.SS,  CMST Development Co.,Ltd.',
    name: 'CMST Development Co.,Ltd.',
    symbol: '600787.SS',
  },
  {
    value: '600789.SS',
    label: '600789.SS,  Shandong Lukang Pharmaceutical Co.,Ltd.',
    name: 'Shandong Lukang Pharmaceutical Co.,Ltd.',
    symbol: '600789.SS',
  },
  {
    value: '600790.SS',
    label:
      '600790.SS,  Zhejiang China Light&Textile Industrial City Group Co.,Ltd',
    name: 'Zhejiang China Light&Textile Industrial City Group Co.,Ltd',
    symbol: '600790.SS',
  },
  {
    value: '600791.SS',
    label: '600791.SS,  BEH-Property Co.,Ltd',
    name: 'BEH-Property Co.,Ltd',
    symbol: '600791.SS',
  },
  {
    value: '600792.SS',
    label: '600792.SS,  Yunnan Coal & Energy Co.,Ltd.',
    name: 'Yunnan Coal & Energy Co.,Ltd.',
    symbol: '600792.SS',
  },
  {
    value: '600793.SS',
    label: '600793.SS,  Yibin Paper Industry Co., Ltd.',
    name: 'Yibin Paper Industry Co., Ltd.',
    symbol: '600793.SS',
  },
  {
    value: '600794.SS',
    label:
      '600794.SS,  Zhang Jia Gang Freetrade Science&Technology Group Co.,Ltd.',
    name: 'Zhang Jia Gang Freetrade Science&Technology Group Co.,Ltd.',
    symbol: '600794.SS',
  },
  {
    value: '600795.SS',
    label: '600795.SS,  GD Power Development Co.,Ltd',
    name: 'GD Power Development Co.,Ltd',
    symbol: '600795.SS',
  },
  {
    value: '600796.SS',
    label: '600796.SS,  Zhejiang Qianjiang Biochemical Co., Ltd',
    name: 'Zhejiang Qianjiang Biochemical Co., Ltd',
    symbol: '600796.SS',
  },
  {
    value: '600797.SS',
    label: '600797.SS,  Insigma Technology Co., Ltd.',
    name: 'Insigma Technology Co., Ltd.',
    symbol: '600797.SS',
  },
  {
    value: '600798.SS',
    label: '600798.SS,  Ningbo Marine Company Limited',
    name: 'Ningbo Marine Company Limited',
    symbol: '600798.SS',
  },
  {
    value: '600800.SS',
    label: '600800.SS,  Tian Jin Bohai Chemical Co.,Ltd.',
    name: 'Tian Jin Bohai Chemical Co.,Ltd.',
    symbol: '600800.SS',
  },
  {
    value: '600802.SS',
    label: '600802.SS,  Fujian Cement Inc.',
    name: 'Fujian Cement Inc.',
    symbol: '600802.SS',
  },
  {
    value: '600804.SS',
    label: '600804.SS,  Dr. Peng Telecom & Media Group Co., Ltd.',
    name: 'Dr. Peng Telecom & Media Group Co., Ltd.',
    symbol: '600804.SS',
  },
  {
    value: '600805.SS',
    label: '600805.SS,  Jiangsu Yueda Investment Co., Ltd.',
    name: 'Jiangsu Yueda Investment Co., Ltd.',
    symbol: '600805.SS',
  },
  {
    value: '600807.SS',
    label: '600807.SS,  Jinan High-tech Development Co., Ltd.',
    name: 'Jinan High-tech Development Co., Ltd.',
    symbol: '600807.SS',
  },
  {
    value: '600810.SS',
    label: '600810.SS,  Shenma Industrial Co., Ltd.',
    name: 'Shenma Industrial Co., Ltd.',
    symbol: '600810.SS',
  },
  {
    value: '600811.SS',
    label: '600811.SS,  Orient Group Incorporation',
    name: 'Orient Group Incorporation',
    symbol: '600811.SS',
  },
  {
    value: '600812.SS',
    label: '600812.SS,  North China Pharmaceutical Company Ltd.',
    name: 'North China Pharmaceutical Company Ltd.',
    symbol: '600812.SS',
  },
  {
    value: '600814.SS',
    label: '600814.SS,  Hangzhou Jiebai Group Co., Limited',
    name: 'Hangzhou Jiebai Group Co., Limited',
    symbol: '600814.SS',
  },
  {
    value: '600815.SS',
    label: '600815.SS,  Xiamen XGMA Machinery Company Limited',
    name: 'Xiamen XGMA Machinery Company Limited',
    symbol: '600815.SS',
  },
  {
    value: '600816.SS',
    label: '600816.SS,  Anxin Trust Co., Ltd',
    name: 'Anxin Trust Co., Ltd',
    symbol: '600816.SS',
  },
  {
    value: '600817.SS',
    label: '600817.SS,  Zhengzhou Deheng Hongsheng Technology Co., Ltd.',
    name: 'Zhengzhou Deheng Hongsheng Technology Co., Ltd.',
    symbol: '600817.SS',
  },
  {
    value: '600818.SS',
    label: '600818.SS,  Zhonglu.Co.,Ltd',
    name: 'Zhonglu.Co.,Ltd',
    symbol: '600818.SS',
  },
  {
    value: '600819.SS',
    label: '600819.SS,  Shanghai Yaohua Pilkington Glass Group Co., Ltd.',
    name: 'Shanghai Yaohua Pilkington Glass Group Co., Ltd.',
    symbol: '600819.SS',
  },
  {
    value: '600821.SS',
    label: '600821.SS,  NYOCOR Co., Ltd.',
    name: 'NYOCOR Co., Ltd.',
    symbol: '600821.SS',
  },
  {
    value: '600822.SS',
    label: '600822.SS,  Shanghai Material Trading Co., Ltd.',
    name: 'Shanghai Material Trading Co., Ltd.',
    symbol: '600822.SS',
  },
  {
    value: '600824.SS',
    label: '600824.SS,  Shanghai Yimin Commercial Group Co., Ltd.',
    name: 'Shanghai Yimin Commercial Group Co., Ltd.',
    symbol: '600824.SS',
  },
  {
    value: '600825.SS',
    label: '600825.SS,  Shanghai Xinhua Media Co., Ltd.',
    name: 'Shanghai Xinhua Media Co., Ltd.',
    symbol: '600825.SS',
  },
  {
    value: '600826.SS',
    label: '600826.SS,  Dlg Exhibitions & Events Corporation Limited',
    name: 'Dlg Exhibitions & Events Corporation Limited',
    symbol: '600826.SS',
  },
  {
    value: '600828.SS',
    label: '600828.SS,  Maoye Commercial Co., Ltd.',
    name: 'Maoye Commercial Co., Ltd.',
    symbol: '600828.SS',
  },
  {
    value: '600829.SS',
    label: '600829.SS,  HPGC Renmintongtai Pharmaceutical Corporation',
    name: 'HPGC Renmintongtai Pharmaceutical Corporation',
    symbol: '600829.SS',
  },
  {
    value: '600830.SS',
    label: '600830.SS,  Sunny Loan Top Co.,Ltd.',
    name: 'Sunny Loan Top Co.,Ltd.',
    symbol: '600830.SS',
  },
  {
    value: '600831.SS',
    label:
      '600831.SS,  Shaanxi Broadcast & TV Network Intermediary(Group)Co.,Ltd.',
    name: 'Shaanxi Broadcast & TV Network Intermediary(Group)Co.,Ltd.',
    symbol: '600831.SS',
  },
  {
    value: '600833.SS',
    label: '600833.SS,  Shanghai No.1 Pharmacy Co., Ltd.',
    name: 'Shanghai No.1 Pharmacy Co., Ltd.',
    symbol: '600833.SS',
  },
  {
    value: '600834.SS',
    label: '600834.SS,  Shanghai Shentong Metro Co.,Ltd.',
    name: 'Shanghai Shentong Metro Co.,Ltd.',
    symbol: '600834.SS',
  },
  {
    value: '600836.SS',
    label: '600836.SS,  Shanghai Eliansy Industry Group Corporation Limited',
    name: 'Shanghai Eliansy Industry Group Corporation Limited',
    symbol: '600836.SS',
  },
  {
    value: '600838.SS',
    label: '600838.SS,  Shanghai Join Buy Co.,Ltd.',
    name: 'Shanghai Join Buy Co.,Ltd.',
    symbol: '600838.SS',
  },
  {
    value: '600839.SS',
    label: '600839.SS,  Sichuan Changhong Electric Co.,Ltd.',
    name: 'Sichuan Changhong Electric Co.,Ltd.',
    symbol: '600839.SS',
  },
  {
    value: '600841.SS',
    label:
      '600841.SS,  Shanghai New Power Automotive Technology Company Limited',
    name: 'Shanghai New Power Automotive Technology Company Limited',
    symbol: '600841.SS',
  },
  {
    value: '600843.SS',
    label: '600843.SS,  Shang Gong Group Co., Ltd.',
    name: 'Shang Gong Group Co., Ltd.',
    symbol: '600843.SS',
  },
  {
    value: '600844.SS',
    label: '600844.SS,  Danhua Chemical Technology Co.,Ltd',
    name: 'Danhua Chemical Technology Co.,Ltd',
    symbol: '600844.SS',
  },
  {
    value: '600846.SS',
    label: '600846.SS,  Shanghai Tongji Science&Technology Industrial Co.,Ltd',
    name: 'Shanghai Tongji Science&Technology Industrial Co.,Ltd',
    symbol: '600846.SS',
  },
  {
    value: '600847.SS',
    label: '600847.SS,  Chongqing Wanli New Energy Co., Ltd.',
    name: 'Chongqing Wanli New Energy Co., Ltd.',
    symbol: '600847.SS',
  },
  {
    value: '600850.SS',
    label: '600850.SS,  CETC Digital Technology Co.,Ltd.',
    name: 'CETC Digital Technology Co.,Ltd.',
    symbol: '600850.SS',
  },
  {
    value: '600851.SS',
    label: '600851.SS,  Shanghai Haixin Group Co., Ltd.',
    name: 'Shanghai Haixin Group Co., Ltd.',
    symbol: '600851.SS',
  },
  {
    value: '600853.SS',
    label: '600853.SS,  Longjian Road&Bridge Co.,Ltd',
    name: 'Longjian Road&Bridge Co.,Ltd',
    symbol: '600853.SS',
  },
  {
    value: '600854.SS',
    label: '600854.SS,  Jiangsu chunlan refrigerating equipment stock co.,ltd.',
    name: 'Jiangsu chunlan refrigerating equipment stock co.,ltd.',
    symbol: '600854.SS',
  },
  {
    value: '600855.SS',
    label: '600855.SS,  Beijing Aerospace Changfeng Co.,Ltd',
    name: 'Beijing Aerospace Changfeng Co.,Ltd',
    symbol: '600855.SS',
  },
  {
    value: '600857.SS',
    label: '600857.SS,  Ningbo Zhongbai Co., Ltd.',
    name: 'Ningbo Zhongbai Co., Ltd.',
    symbol: '600857.SS',
  },
  {
    value: '600858.SS',
    label: '600858.SS,  Inzone Group Co.,Ltd',
    name: 'Inzone Group Co.,Ltd',
    symbol: '600858.SS',
  },
  {
    value: '600860.SS',
    label: '600860.SS,  Beijing Jingcheng Machinery Electric Company Limited',
    name: 'Beijing Jingcheng Machinery Electric Company Limited',
    symbol: '600860.SS',
  },
  {
    value: '600861.SS',
    label: '600861.SS,  Beijing Urban-Rural Commercial (Group) Co.,Ltd.',
    name: 'Beijing Urban-Rural Commercial (Group) Co.,Ltd.',
    symbol: '600861.SS',
  },
  {
    value: '600862.SS',
    label: '600862.SS,  AVIC Aviation High-Technology Co., Ltd.',
    name: 'AVIC Aviation High-Technology Co., Ltd.',
    symbol: '600862.SS',
  },
  {
    value: '600863.SS',
    label:
      '600863.SS,  Inner Mongolia MengDian HuaNeng Thermal Power Corporation Limited',
    name: 'Inner Mongolia MengDian HuaNeng Thermal Power Corporation Limited',
    symbol: '600863.SS',
  },
  {
    value: '600864.SS',
    label: '600864.SS,  Harbin Hatou Investment Co.,Ltd',
    name: 'Harbin Hatou Investment Co.,Ltd',
    symbol: '600864.SS',
  },
  {
    value: '600865.SS',
    label: '600865.SS,  Baida Group Co.,Ltd',
    name: 'Baida Group Co.,Ltd',
    symbol: '600865.SS',
  },
  {
    value: '600866.SS',
    label: '600866.SS,  Star Lake Bioscience Co., Inc.Zhaoqing Guangdong',
    name: 'Star Lake Bioscience Co., Inc.Zhaoqing Guangdong',
    symbol: '600866.SS',
  },
  {
    value: '600868.SS',
    label: '600868.SS,  Guangdong Meiyan Jixiang Hydropower Co.,Ltd.',
    name: 'Guangdong Meiyan Jixiang Hydropower Co.,Ltd.',
    symbol: '600868.SS',
  },
  {
    value: '600869.SS',
    label: '600869.SS,  Far East Smarter Energy Co., Ltd.',
    name: 'Far East Smarter Energy Co., Ltd.',
    symbol: '600869.SS',
  },
  {
    value: '600873.SS',
    label: '600873.SS,  MeiHua Holdings Group Co.,Ltd',
    name: 'MeiHua Holdings Group Co.,Ltd',
    symbol: '600873.SS',
  },
  {
    value: '600874.SS',
    label:
      '600874.SS,  Tianjin Capital Environmental Protection Group Company Limited',
    name: 'Tianjin Capital Environmental Protection Group Company Limited',
    symbol: '600874.SS',
  },
  {
    value: '600876.SS',
    label: '600876.SS,  Luoyang Glass Company Limited',
    name: 'Luoyang Glass Company Limited',
    symbol: '600876.SS',
  },
  {
    value: '600877.SS',
    label: '600877.SS,  CETC Acoustic-Optic-Electronic Technology Inc.',
    name: 'CETC Acoustic-Optic-Electronic Technology Inc.',
    symbol: '600877.SS',
  },
  {
    value: '600879.SS',
    label: '600879.SS,  China Aerospace Times Electronics CO., LTD.',
    name: 'China Aerospace Times Electronics CO., LTD.',
    symbol: '600879.SS',
  },
  {
    value: '600880.SS',
    label: '600880.SS,  Chengdu B-ray Media Co.,Ltd.',
    name: 'Chengdu B-ray Media Co.,Ltd.',
    symbol: '600880.SS',
  },
  {
    value: '600881.SS',
    label: '600881.SS,  Jilin Yatai (Group) Co., Ltd.',
    name: 'Jilin Yatai (Group) Co., Ltd.',
    symbol: '600881.SS',
  },
  {
    value: '600882.SS',
    label: '600882.SS,  Shanghai Milkground Food Tech Co., Ltd',
    name: 'Shanghai Milkground Food Tech Co., Ltd',
    symbol: '600882.SS',
  },
  {
    value: '600883.SS',
    label: '600883.SS,  Yunnan Bowin Technology Industry Co.,Ltd',
    name: 'Yunnan Bowin Technology Industry Co.,Ltd',
    symbol: '600883.SS',
  },
  {
    value: '600884.SS',
    label: '600884.SS,  Ningbo Shanshan Co.,Ltd.',
    name: 'Ningbo Shanshan Co.,Ltd.',
    symbol: '600884.SS',
  },
  {
    value: '600888.SS',
    label: '600888.SS,  Xinjiang Joinworld Co.,Ltd.',
    name: 'Xinjiang Joinworld Co.,Ltd.',
    symbol: '600888.SS',
  },
  {
    value: '600889.SS',
    label: '600889.SS,  Nanjing Chemical Fibre Co.,Ltd',
    name: 'Nanjing Chemical Fibre Co.,Ltd',
    symbol: '600889.SS',
  },
  {
    value: '600892.SS',
    label: '600892.SS,  Dasheng Times Cultural Investment Co., Ltd.',
    name: 'Dasheng Times Cultural Investment Co., Ltd.',
    symbol: '600892.SS',
  },
  {
    value: '600894.SS',
    label: '600894.SS,  Guangzhou Guangri Stock Co.,Ltd.',
    name: 'Guangzhou Guangri Stock Co.,Ltd.',
    symbol: '600894.SS',
  },
  {
    value: '600897.SS',
    label: '600897.SS,  Xiamen International Airport Co.,Ltd',
    name: 'Xiamen International Airport Co.,Ltd',
    symbol: '600897.SS',
  },
  {
    value: '600898.SS',
    label: '600898.SS,  Gome Telecom Equipment Co.,Ltd.',
    name: 'Gome Telecom Equipment Co.,Ltd.',
    symbol: '600898.SS',
  },
  {
    value: '600901.SS',
    label: '600901.SS,  Jiangsu Financial Leasing Co., Ltd.',
    name: 'Jiangsu Financial Leasing Co., Ltd.',
    symbol: '600901.SS',
  },
  {
    value: '600903.SS',
    label: '600903.SS,  Guizhou Gas Group Corporation Ltd.',
    name: 'Guizhou Gas Group Corporation Ltd.',
    symbol: '600903.SS',
  },
  {
    value: '600908.SS',
    label: '600908.SS,  Wuxi Rural Commercial Bank Co.,Ltd',
    name: 'Wuxi Rural Commercial Bank Co.,Ltd',
    symbol: '600908.SS',
  },
  {
    value: '600916.SS',
    label: '600916.SS,  China National Gold Group Gold Jewellery Co.,Ltd.',
    name: 'China National Gold Group Gold Jewellery Co.,Ltd.',
    symbol: '600916.SS',
  },
  {
    value: '600917.SS',
    label: '600917.SS,  Chongqing Gas Group Corporation Ltd.',
    name: 'Chongqing Gas Group Corporation Ltd.',
    symbol: '600917.SS',
  },
  {
    value: '600928.SS',
    label: "600928.SS,  Bank of Xi'an Co.,Ltd.",
    name: "Bank of Xi'an Co.,Ltd.",
    symbol: '600928.SS',
  },
  {
    value: '600929.SS',
    label: '600929.SS,  Snowsky Salt Industry Group Co., Ltd.',
    name: 'Snowsky Salt Industry Group Co., Ltd.',
    symbol: '600929.SS',
  },
  {
    value: '600933.SS',
    label: '600933.SS,  IKD Co., Ltd.',
    name: 'IKD Co., Ltd.',
    symbol: '600933.SS',
  },
  {
    value: '600936.SS',
    label:
      '600936.SS,  Guangxi Radio and Television Information Network Corporation Limited',
    name: 'Guangxi Radio and Television Information Network Corporation Limited',
    symbol: '600936.SS',
  },
  {
    value: '600939.SS',
    label:
      '600939.SS,  Chongqing Construction Engineering Group Corporation Limited',
    name: 'Chongqing Construction Engineering Group Corporation Limited',
    symbol: '600939.SS',
  },
  {
    value: '600956.SS',
    label: '600956.SS,  China Suntien Green Energy Corporation Limited',
    name: 'China Suntien Green Energy Corporation Limited',
    symbol: '600956.SS',
  },
  {
    value: '600959.SS',
    label:
      '600959.SS,  Jiangsu Broadcasting Cable Information Network Corporation Limited',
    name: 'Jiangsu Broadcasting Cable Information Network Corporation Limited',
    symbol: '600959.SS',
  },
  {
    value: '600960.SS',
    label: '600960.SS,  Bohai Automotive Systems CO., LTD.',
    name: 'Bohai Automotive Systems CO., LTD.',
    symbol: '600960.SS',
  },
  {
    value: '600961.SS',
    label: '600961.SS,  Zhuzhou Smelter Group Co.,Ltd.',
    name: 'Zhuzhou Smelter Group Co.,Ltd.',
    symbol: '600961.SS',
  },
  {
    value: '600962.SS',
    label: '600962.SS,  SDIC Zhonglu Fruit Juice Co.,Ltd.',
    name: 'SDIC Zhonglu Fruit Juice Co.,Ltd.',
    symbol: '600962.SS',
  },
  {
    value: '600963.SS',
    label: '600963.SS,  Yueyang Forest & Paper Co., Ltd.',
    name: 'Yueyang Forest & Paper Co., Ltd.',
    symbol: '600963.SS',
  },
  {
    value: '600965.SS',
    label: '600965.SS,  Fortune Ng Fung Food (Hebei) Co.,Ltd',
    name: 'Fortune Ng Fung Food (Hebei) Co.,Ltd',
    symbol: '600965.SS',
  },
  {
    value: '600966.SS',
    label: '600966.SS,  Shandong Bohui Paper Industry Co., Ltd.',
    name: 'Shandong Bohui Paper Industry Co., Ltd.',
    symbol: '600966.SS',
  },
  {
    value: '600967.SS',
    label: '600967.SS,  Inner Mongolia First Machinery Group Co.,Ltd.',
    name: 'Inner Mongolia First Machinery Group Co.,Ltd.',
    symbol: '600967.SS',
  },
  {
    value: '600968.SS',
    label: '600968.SS,  CNOOC Energy Technology & Services Limited',
    name: 'CNOOC Energy Technology & Services Limited',
    symbol: '600968.SS',
  },
  {
    value: '600969.SS',
    label: '600969.SS,  Hunan Chendian International Development co.,ltd',
    name: 'Hunan Chendian International Development co.,ltd',
    symbol: '600969.SS',
  },
  {
    value: '600973.SS',
    label: '600973.SS,  Baosheng Science and Technology Innovation Co.,Ltd.',
    name: 'Baosheng Science and Technology Innovation Co.,Ltd.',
    symbol: '600973.SS',
  },
  {
    value: '600975.SS',
    label: '600975.SS,  Hunan New Wellful Co.,Ltd.',
    name: 'Hunan New Wellful Co.,Ltd.',
    symbol: '600975.SS',
  },
  {
    value: '600976.SS',
    label: '600976.SS,  Jianmin Pharmaceutical Group Co.,Ltd.',
    name: 'Jianmin Pharmaceutical Group Co.,Ltd.',
    symbol: '600976.SS',
  },
  {
    value: '600977.SS',
    label: '600977.SS,  China Film Co.,Ltd.',
    name: 'China Film Co.,Ltd.',
    symbol: '600977.SS',
  },
  {
    value: '600979.SS',
    label: '600979.SS,  Sichuan Guangan Aaapublic Co.,Ltd',
    name: 'Sichuan Guangan Aaapublic Co.,Ltd',
    symbol: '600979.SS',
  },
  {
    value: '600980.SS',
    label: '600980.SS,  BGRIMM Technology Co., Ltd.',
    name: 'BGRIMM Technology Co., Ltd.',
    symbol: '600980.SS',
  },
  {
    value: '600981.SS',
    label: '600981.SS,  Jiangsu High Hope International Group Corporation',
    name: 'Jiangsu High Hope International Group Corporation',
    symbol: '600981.SS',
  },
  {
    value: '600982.SS',
    label: '600982.SS,  Ningbo Energy Group Co.,Ltd.',
    name: 'Ningbo Energy Group Co.,Ltd.',
    symbol: '600982.SS',
  },
  {
    value: '600983.SS',
    label: '600983.SS,  Whirlpool China Co., Ltd.',
    name: 'Whirlpool China Co., Ltd.',
    symbol: '600983.SS',
  },
  {
    value: '600984.SS',
    label: '600984.SS,  Shaanxi Construction Machinery Co.,Ltd',
    name: 'Shaanxi Construction Machinery Co.,Ltd',
    symbol: '600984.SS',
  },
  {
    value: '600986.SS',
    label: '600986.SS,  Zhewen Interactive Group Co., Ltd.',
    name: 'Zhewen Interactive Group Co., Ltd.',
    symbol: '600986.SS',
  },
  {
    value: '600990.SS',
    label: '600990.SS,  Sun Create Electronics Co., Ltd',
    name: 'Sun Create Electronics Co., Ltd',
    symbol: '600990.SS',
  },
  {
    value: '600992.SS',
    label: '600992.SS,  Guizhou Wire Rope Incorporated Company',
    name: 'Guizhou Wire Rope Incorporated Company',
    symbol: '600992.SS',
  },
  {
    value: '600993.SS',
    label: '600993.SS,  Mayinglong Pharmaceutical Group Co., LTD.',
    name: 'Mayinglong Pharmaceutical Group Co., LTD.',
    symbol: '600993.SS',
  },
  {
    value: '600995.SS',
    label: '600995.SS,  China Southern Power Grid Energy Storage Co., Ltd.',
    name: 'China Southern Power Grid Energy Storage Co., Ltd.',
    symbol: '600995.SS',
  },
  {
    value: '600996.SS',
    label: '600996.SS,  Guizhou BC&TV Information Network CO.,LTD',
    name: 'Guizhou BC&TV Information Network CO.,LTD',
    symbol: '600996.SS',
  },
  {
    value: '600997.SS',
    label: '600997.SS,  Kailuan Energy Chemical Co.,Ltd.',
    name: 'Kailuan Energy Chemical Co.,Ltd.',
    symbol: '600997.SS',
  },
  {
    value: '601000.SS',
    label: '601000.SS,  TangShan Port Group Co.,Ltd',
    name: 'TangShan Port Group Co.,Ltd',
    symbol: '601000.SS',
  },
  {
    value: '601001.SS',
    label: '601001.SS,  Jinneng Holding Shanxi Coal Industry Co.,ltd.',
    name: 'Jinneng Holding Shanxi Coal Industry Co.,ltd.',
    symbol: '601001.SS',
  },
  {
    value: '601002.SS',
    label: '601002.SS,  Gem-Year Industrial Co.,Ltd.',
    name: 'Gem-Year Industrial Co.,Ltd.',
    symbol: '601002.SS',
  },
  {
    value: '601005.SS',
    label: '601005.SS,  Chongqing Iron & Steel Company Limited',
    name: 'Chongqing Iron & Steel Company Limited',
    symbol: '601005.SS',
  },
  {
    value: '601007.SS',
    label: '601007.SS,  Jinling Hotel Corporation, Ltd.',
    name: 'Jinling Hotel Corporation, Ltd.',
    symbol: '601007.SS',
  },
  {
    value: '601008.SS',
    label: '601008.SS,  Jiangsu Lianyungang Port Co., Ltd.',
    name: 'Jiangsu Lianyungang Port Co., Ltd.',
    symbol: '601008.SS',
  },
  {
    value: '601010.SS',
    label: '601010.SS,  Wenfeng Great World Chain Development Corporation',
    name: 'Wenfeng Great World Chain Development Corporation',
    symbol: '601010.SS',
  },
  {
    value: '601011.SS',
    label: '601011.SS,  Baotailong New Materials Co., Ltd.',
    name: 'Baotailong New Materials Co., Ltd.',
    symbol: '601011.SS',
  },
  {
    value: '601015.SS',
    label: '601015.SS,  Shaanxi Heimao Coking Co., Ltd.',
    name: 'Shaanxi Heimao Coking Co., Ltd.',
    symbol: '601015.SS',
  },
  {
    value: '601020.SS',
    label: '601020.SS,  Tibet Huayu Mining Co., Ltd.',
    name: 'Tibet Huayu Mining Co., Ltd.',
    symbol: '601020.SS',
  },
  {
    value: '601028.SS',
    label: '601028.SS,  Shandong Yulong Gold Co., Ltd.',
    name: 'Shandong Yulong Gold Co., Ltd.',
    symbol: '601028.SS',
  },
  {
    value: '601038.SS',
    label: '601038.SS,  First Tractor Company Limited',
    name: 'First Tractor Company Limited',
    symbol: '601038.SS',
  },
  {
    value: '601068.SS',
    label:
      '601068.SS,  China Aluminum International Engineering Corporation Limited',
    name: 'China Aluminum International Engineering Corporation Limited',
    symbol: '601068.SS',
  },
  {
    value: '601069.SS',
    label: '601069.SS,  Western Region Gold Co., Ltd.',
    name: 'Western Region Gold Co., Ltd.',
    symbol: '601069.SS',
  },
  {
    value: '601086.SS',
    label: '601086.SS,  Gansu Guofang Industry & Trade (Group) Co., Ltd.',
    name: 'Gansu Guofang Industry & Trade (Group) Co., Ltd.',
    symbol: '601086.SS',
  },
  {
    value: '601101.SS',
    label: '601101.SS,  Beijing Haohua Energy Resource Co., Ltd.',
    name: 'Beijing Haohua Energy Resource Co., Ltd.',
    symbol: '601101.SS',
  },
  {
    value: '601106.SS',
    label: '601106.SS,  China First Heavy Industries',
    name: 'China First Heavy Industries',
    symbol: '601106.SS',
  },
  {
    value: '601107.SS',
    label: '601107.SS,  Sichuan Expressway Company Limited',
    name: 'Sichuan Expressway Company Limited',
    symbol: '601107.SS',
  },
  {
    value: '601113.SS',
    label: '601113.SS,  Yiwu Huading Nylon Co.,Ltd.',
    name: 'Yiwu Huading Nylon Co.,Ltd.',
    symbol: '601113.SS',
  },
  {
    value: '601116.SS',
    label: '601116.SS,  Sanjiang Shopping Club Co.,Ltd',
    name: 'Sanjiang Shopping Club Co.,Ltd',
    symbol: '601116.SS',
  },
  {
    value: '601118.SS',
    label: '601118.SS,  China Hainan Rubber Industry Group Co., Ltd.',
    name: 'China Hainan Rubber Industry Group Co., Ltd.',
    symbol: '601118.SS',
  },
  {
    value: '601126.SS',
    label: '601126.SS,  Beijing Sifang Automation Co.,Ltd',
    name: 'Beijing Sifang Automation Co.,Ltd',
    symbol: '601126.SS',
  },
  {
    value: '601127.SS',
    label: '601127.SS,  Seres Group Co.,Ltd.',
    name: 'Seres Group Co.,Ltd.',
    symbol: '601127.SS',
  },
  {
    value: '601137.SS',
    label: '601137.SS,  Ningbo Boway Alloy Material Company Limited',
    name: 'Ningbo Boway Alloy Material Company Limited',
    symbol: '601137.SS',
  },
  {
    value: '601139.SS',
    label: '601139.SS,  Shenzhen Gas Corporation Ltd.',
    name: 'Shenzhen Gas Corporation Ltd.',
    symbol: '601139.SS',
  },
  {
    value: '601163.SS',
    label: '601163.SS,  Triangle Tyre Co., Ltd',
    name: 'Triangle Tyre Co., Ltd',
    symbol: '601163.SS',
  },
  {
    value: '601168.SS',
    label: '601168.SS,  Western Mining Co.,Ltd.',
    name: 'Western Mining Co.,Ltd.',
    symbol: '601168.SS',
  },
  {
    value: '601177.SS',
    label: '601177.SS,  Hangzhou Advance Gearbox Group Co., Ltd.',
    name: 'Hangzhou Advance Gearbox Group Co., Ltd.',
    symbol: '601177.SS',
  },
  {
    value: '601179.SS',
    label: '601179.SS,  China XD Electric Co., Ltd',
    name: 'China XD Electric Co., Ltd',
    symbol: '601179.SS',
  },
  {
    value: '601186.SS',
    label: '601186.SS,  China Railway Construction Corporation Limited',
    name: 'China Railway Construction Corporation Limited',
    symbol: '601186.SS',
  },
  {
    value: '601187.SS',
    label: '601187.SS,  Xiamen Bank Co., Ltd.',
    name: 'Xiamen Bank Co., Ltd.',
    symbol: '601187.SS',
  },
  {
    value: '601188.SS',
    label: '601188.SS,  Heilongjiang Transport Development Co., Ltd.',
    name: 'Heilongjiang Transport Development Co., Ltd.',
    symbol: '601188.SS',
  },
  {
    value: '601199.SS',
    label: '601199.SS,  Jiangsu Jiangnan Water Co., Ltd.',
    name: 'Jiangsu Jiangnan Water Co., Ltd.',
    symbol: '601199.SS',
  },
  {
    value: '601200.SS',
    label: '601200.SS,  Shanghai Environment Group Co., Ltd',
    name: 'Shanghai Environment Group Co., Ltd',
    symbol: '601200.SS',
  },
  {
    value: '601208.SS',
    label: '601208.SS,  Sichuan Em Technology Co., Ltd.',
    name: 'Sichuan Em Technology Co., Ltd.',
    symbol: '601208.SS',
  },
  {
    value: '601212.SS',
    label: '601212.SS,  Baiyin Nonferrous Group Co., Ltd.',
    name: 'Baiyin Nonferrous Group Co., Ltd.',
    symbol: '601212.SS',
  },
  {
    value: '601218.SS',
    label: '601218.SS,  Jiangsu JIXIN Wind Energy Technology Co., Ltd.',
    name: 'Jiangsu JIXIN Wind Energy Technology Co., Ltd.',
    symbol: '601218.SS',
  },
  {
    value: '601222.SS',
    label: '601222.SS,  Jiangsu Linyang Energy Co., Ltd.',
    name: 'Jiangsu Linyang Energy Co., Ltd.',
    symbol: '601222.SS',
  },
  {
    value: '601226.SS',
    label: '601226.SS,  Huadian Heavy Industries Co., Ltd.',
    name: 'Huadian Heavy Industries Co., Ltd.',
    symbol: '601226.SS',
  },
  {
    value: '601228.SS',
    label: '601228.SS,  Guangzhou Port Company Limited',
    name: 'Guangzhou Port Company Limited',
    symbol: '601228.SS',
  },
  {
    value: '601258.SS',
    label: '601258.SS,  Pang Da Automobile Trade Co., Ltd',
    name: 'Pang Da Automobile Trade Co., Ltd',
    symbol: '601258.SS',
  },
  {
    value: '601298.SS',
    label: '601298.SS,  Qingdao Port International Co., Ltd.',
    name: 'Qingdao Port International Co., Ltd.',
    symbol: '601298.SS',
  },
  {
    value: '601311.SS',
    label: '601311.SS,  Camel Group Co., Ltd.',
    name: 'Camel Group Co., Ltd.',
    symbol: '601311.SS',
  },
  {
    value: '601330.SS',
    label: '601330.SS,  Dynagreen Environmental Protection Group Co., Ltd.',
    name: 'Dynagreen Environmental Protection Group Co., Ltd.',
    symbol: '601330.SS',
  },
  {
    value: '601333.SS',
    label: '601333.SS,  Guangshen Railway Company Limited',
    name: 'Guangshen Railway Company Limited',
    symbol: '601333.SS',
  },
  {
    value: '601339.SS',
    label: '601339.SS,  Bros Eastern.,Ltd',
    name: 'Bros Eastern.,Ltd',
    symbol: '601339.SS',
  },
  {
    value: '601366.SS',
    label: '601366.SS,  Liqun Commercial Group Co.,Ltd.',
    name: 'Liqun Commercial Group Co.,Ltd.',
    symbol: '601366.SS',
  },
  {
    value: '601368.SS',
    label: '601368.SS,  Guangxi Nanning Waterworks Co.,Ltd.',
    name: 'Guangxi Nanning Waterworks Co.,Ltd.',
    symbol: '601368.SS',
  },
  {
    value: '601369.SS',
    label: "601369.SS,  Xi'an Shaangu Power Co., Ltd.",
    name: "Xi'an Shaangu Power Co., Ltd.",
    symbol: '601369.SS',
  },
  {
    value: '601388.SS',
    label: '601388.SS,  Yechiu Metal Recycling (China) Ltd.',
    name: 'Yechiu Metal Recycling (China) Ltd.',
    symbol: '601388.SS',
  },
  {
    value: '601399.SS',
    label: '601399.SS,  SINOMACH HEAVY EQUIPMENT GROUP CO.,LTD',
    name: 'SINOMACH HEAVY EQUIPMENT GROUP CO.,LTD',
    symbol: '601399.SS',
  },
  {
    value: '601500.SS',
    label: '601500.SS,  Jiangsu General Science Technology Co., Ltd.',
    name: 'Jiangsu General Science Technology Co., Ltd.',
    symbol: '601500.SS',
  },
  {
    value: '601512.SS',
    label:
      '601512.SS,  China-Singapore Suzhou Industrial Park Development Group Co., Ltd.',
    name: 'China-Singapore Suzhou Industrial Park Development Group Co., Ltd.',
    symbol: '601512.SS',
  },
  {
    value: '601515.SS',
    label: '601515.SS,  Shantou Dongfeng Printing Co., Ltd.',
    name: 'Shantou Dongfeng Printing Co., Ltd.',
    symbol: '601515.SS',
  },
  {
    value: '601518.SS',
    label: '601518.SS,  Jilin Expressway Co., Ltd.',
    name: 'Jilin Expressway Co., Ltd.',
    symbol: '601518.SS',
  },
  {
    value: '601519.SS',
    label: '601519.SS,  Shanghai DZH Limited',
    name: 'Shanghai DZH Limited',
    symbol: '601519.SS',
  },
  {
    value: '601566.SS',
    label: '601566.SS,  Joeone Co., Ltd.',
    name: 'Joeone Co., Ltd.',
    symbol: '601566.SS',
  },
  {
    value: '601567.SS',
    label: '601567.SS,  Ningbo Sanxing Medical Electric Co.,Ltd.',
    name: 'Ningbo Sanxing Medical Electric Co.,Ltd.',
    symbol: '601567.SS',
  },
  {
    value: '601579.SS',
    label: '601579.SS,  Kuaijishan Shaoxing Rice Wine Co., Ltd.',
    name: 'Kuaijishan Shaoxing Rice Wine Co., Ltd.',
    symbol: '601579.SS',
  },
  {
    value: '601588.SS',
    label: '601588.SS,  Beijing North Star Company Limited',
    name: 'Beijing North Star Company Limited',
    symbol: '601588.SS',
  },
  {
    value: '601595.SS',
    label: '601595.SS,  Shanghai Film Co., Ltd.',
    name: 'Shanghai Film Co., Ltd.',
    symbol: '601595.SS',
  },
  {
    value: '601599.SS',
    label: '601599.SS,  Zhewen Pictures Group co.,ltd',
    name: 'Zhewen Pictures Group co.,ltd',
    symbol: '601599.SS',
  },
  {
    value: '601606.SS',
    label: '601606.SS,  Anhui Great Wall Military Industry Co., Ltd.',
    name: 'Anhui Great Wall Military Industry Co., Ltd.',
    symbol: '601606.SS',
  },
  {
    value: '601608.SS',
    label: '601608.SS,  CITIC Heavy Industries Co., Ltd.',
    name: 'CITIC Heavy Industries Co., Ltd.',
    symbol: '601608.SS',
  },
  {
    value: '601609.SS',
    label: '601609.SS,  Ningbo Jintian Copper(Group) Co., Ltd.',
    name: 'Ningbo Jintian Copper(Group) Co., Ltd.',
    symbol: '601609.SS',
  },
  {
    value: '601611.SS',
    label: '601611.SS,  China Nuclear Engineering Corporation Limited',
    name: 'China Nuclear Engineering Corporation Limited',
    symbol: '601611.SS',
  },
  {
    value: '601616.SS',
    label: '601616.SS,  Shanghai Guangdian Electric Group Co., Ltd.',
    name: 'Shanghai Guangdian Electric Group Co., Ltd.',
    symbol: '601616.SS',
  },
  {
    value: '601619.SS',
    label: '601619.SS,  Ningxia Jiaze Renewables Corporation Limited',
    name: 'Ningxia Jiaze Renewables Corporation Limited',
    symbol: '601619.SS',
  },
  {
    value: '601677.SS',
    label: '601677.SS,  Henan Mingtai Al.Industrial Co.,Ltd.',
    name: 'Henan Mingtai Al.Industrial Co.,Ltd.',
    symbol: '601677.SS',
  },
  {
    value: '601678.SS',
    label: '601678.SS,  Befar Group Co.,Ltd',
    name: 'Befar Group Co.,Ltd',
    symbol: '601678.SS',
  },
  {
    value: '601686.SS',
    label: '601686.SS,  Tianjin You Fa Steel Pipe Group Stock Co., Ltd.',
    name: 'Tianjin You Fa Steel Pipe Group Stock Co., Ltd.',
    symbol: '601686.SS',
  },
  {
    value: '601698.SS',
    label: '601698.SS,  China Satellite Communications Co., Ltd.',
    name: 'China Satellite Communications Co., Ltd.',
    symbol: '601698.SS',
  },
  {
    value: '601700.SS',
    label: '601700.SS,  Changshu Fengfan Power Equipment Co., Ltd.',
    name: 'Changshu Fengfan Power Equipment Co., Ltd.',
    symbol: '601700.SS',
  },
  {
    value: '601702.SS',
    label: '601702.SS,  Shanghai Huafon Aluminium Corporation',
    name: 'Shanghai Huafon Aluminium Corporation',
    symbol: '601702.SS',
  },
  {
    value: '601718.SS',
    label: '601718.SS,  Jihua Group Corporation Limited',
    name: 'Jihua Group Corporation Limited',
    symbol: '601718.SS',
  },
  {
    value: '601728.SS',
    label: '601728.SS,  China Telecom Corporation Limited',
    name: 'China Telecom Corporation Limited',
    symbol: '601728.SS',
  },
  {
    value: '601777.SS',
    label: '601777.SS,  Lifan Technology (Group) Co., Ltd.',
    name: 'Lifan Technology (Group) Co., Ltd.',
    symbol: '601777.SS',
  },
  {
    value: '601778.SS',
    label: '601778.SS,  Jinko Power Technology Co.,Ltd.',
    name: 'Jinko Power Technology Co.,Ltd.',
    symbol: '601778.SS',
  },
  {
    value: '601789.SS',
    label: '601789.SS,  Ningbo Construction Co., Ltd.',
    name: 'Ningbo Construction Co., Ltd.',
    symbol: '601789.SS',
  },
  {
    value: '601798.SS',
    label: '601798.SS,  Lanpec Technologies Limited',
    name: 'Lanpec Technologies Limited',
    symbol: '601798.SS',
  },
  {
    value: '601800.SS',
    label: '601800.SS,  China Communications Construction Company Limited',
    name: 'China Communications Construction Company Limited',
    symbol: '601800.SS',
  },
  {
    value: '601801.SS',
    label: '601801.SS,  Anhui Xinhua Media Co., Ltd.',
    name: 'Anhui Xinhua Media Co., Ltd.',
    symbol: '601801.SS',
  },
  {
    value: '601811.SS',
    label: '601811.SS,  Xinhua Winshare Publishing and Media Co., Ltd.',
    name: 'Xinhua Winshare Publishing and Media Co., Ltd.',
    symbol: '601811.SS',
  },
  {
    value: '601825.SS',
    label: '601825.SS,  Shanghai Rural Commercial Bank Co., Ltd.',
    name: 'Shanghai Rural Commercial Bank Co., Ltd.',
    symbol: '601825.SS',
  },
  {
    value: '601827.SS',
    label: '601827.SS,  Chongqing Sanfeng Environment Group Corp., Ltd.',
    name: 'Chongqing Sanfeng Environment Group Corp., Ltd.',
    symbol: '601827.SS',
  },
  {
    value: '601828.SS',
    label: '601828.SS,  Red Star Macalline Group Corporation Ltd.',
    name: 'Red Star Macalline Group Corporation Ltd.',
    symbol: '601828.SS',
  },
  {
    value: '601858.SS',
    label: '601858.SS,  China Science Publishing & Media Ltd.',
    name: 'China Science Publishing & Media Ltd.',
    symbol: '601858.SS',
  },
  {
    value: '601860.SS',
    label: '601860.SS,  Jiangsu Zijin Rural Commercial Bank Co.,Ltd',
    name: 'Jiangsu Zijin Rural Commercial Bank Co.,Ltd',
    symbol: '601860.SS',
  },
  {
    value: '601868.SS',
    label: '601868.SS,  China Energy Engineering Corporation Limited',
    name: 'China Energy Engineering Corporation Limited',
    symbol: '601868.SS',
  },
  {
    value: '601869.SS',
    label:
      '601869.SS,  Yangtze Optical Fibre And Cable Joint Stock Limited Company',
    name: 'Yangtze Optical Fibre And Cable Joint Stock Limited Company',
    symbol: '601869.SS',
  },
  {
    value: '601880.SS',
    label: '601880.SS,  Liaoning Port Co., Ltd.',
    name: 'Liaoning Port Co., Ltd.',
    symbol: '601880.SS',
  },
  {
    value: '601882.SS',
    label: '601882.SS,  Ningbo Haitian Precision Machinery Co.,Ltd.',
    name: 'Ningbo Haitian Precision Machinery Co.,Ltd.',
    symbol: '601882.SS',
  },
  {
    value: '601886.SS',
    label: '601886.SS,  Jangho Group Co., Ltd.',
    name: 'Jangho Group Co., Ltd.',
    symbol: '601886.SS',
  },
  {
    value: '601890.SS',
    label: '601890.SS,  Asian Star Anchor Chain Co., Ltd. Jiangsu',
    name: 'Asian Star Anchor Chain Co., Ltd. Jiangsu',
    symbol: '601890.SS',
  },
  {
    value: '601900.SS',
    label: '601900.SS,  Southern Publishing and Media Co.,Ltd.',
    name: 'Southern Publishing and Media Co.,Ltd.',
    symbol: '601900.SS',
  },
  {
    value: '601908.SS',
    label: '601908.SS,  Beijing Jingyuntong Technology Co., Ltd.',
    name: 'Beijing Jingyuntong Technology Co., Ltd.',
    symbol: '601908.SS',
  },
  {
    value: '601918.SS',
    label: '601918.SS,  China Coal Xinji Energy Co.,Ltd',
    name: 'China Coal Xinji Energy Co.,Ltd',
    symbol: '601918.SS',
  },
  {
    value: '601921.SS',
    label: '601921.SS,  Zhejiang Publishing & Media Co., Ltd.',
    name: 'Zhejiang Publishing & Media Co., Ltd.',
    symbol: '601921.SS',
  },
  {
    value: '601928.SS',
    label: '601928.SS,  Jiangsu Phoenix Publishing & Media Corporation Limited',
    name: 'Jiangsu Phoenix Publishing & Media Corporation Limited',
    symbol: '601928.SS',
  },
  {
    value: '601929.SS',
    label: '601929.SS,  JiShi Media Co., Ltd.',
    name: 'JiShi Media Co., Ltd.',
    symbol: '601929.SS',
  },
  {
    value: '601949.SS',
    label: '601949.SS,  China Publishing & Media Holdings Co., Ltd.',
    name: 'China Publishing & Media Holdings Co., Ltd.',
    symbol: '601949.SS',
  },
  {
    value: '601952.SS',
    label:
      '601952.SS,  Jiangsu Provincial Agricultural Reclamation and Development Co.,Ltd.',
    name: 'Jiangsu Provincial Agricultural Reclamation and Development Co.,Ltd.',
    symbol: '601952.SS',
  },
  {
    value: '601963.SS',
    label: '601963.SS,  Bank of Chongqing Co., Ltd.',
    name: 'Bank of Chongqing Co., Ltd.',
    symbol: '601963.SS',
  },
  {
    value: '601965.SS',
    label:
      '601965.SS,  China Automotive Engineering Research Institute Co., Ltd.',
    name: 'China Automotive Engineering Research Institute Co., Ltd.',
    symbol: '601965.SS',
  },
  {
    value: '601968.SS',
    label: '601968.SS,  Shanghai Baosteel Packaging Co., Ltd.',
    name: 'Shanghai Baosteel Packaging Co., Ltd.',
    symbol: '601968.SS',
  },
  {
    value: '601969.SS',
    label: '601969.SS,  Hainan Mining Co., Ltd.',
    name: 'Hainan Mining Co., Ltd.',
    symbol: '601969.SS',
  },
  {
    value: '601975.SS',
    label: '601975.SS,  Nanjing Tanker Corporation',
    name: 'Nanjing Tanker Corporation',
    symbol: '601975.SS',
  },
  {
    value: '601989.SS',
    label: '601989.SS,  China Shipbuilding Industry Company Limited',
    name: 'China Shipbuilding Industry Company Limited',
    symbol: '601989.SS',
  },
  {
    value: '601996.SS',
    label: '601996.SS,  Guangxi Fenglin Wood Industry Group Co.,Ltd',
    name: 'Guangxi Fenglin Wood Industry Group Co.,Ltd',
    symbol: '601996.SS',
  },
  {
    value: '601997.SS',
    label: '601997.SS,  Bank of Guiyang Co.,Ltd.',
    name: 'Bank of Guiyang Co.,Ltd.',
    symbol: '601997.SS',
  },
  {
    value: '601999.SS',
    label:
      '601999.SS,  Northern United Publishing & Media (Group) Company Limited',
    name: 'Northern United Publishing & Media (Group) Company Limited',
    symbol: '601999.SS',
  },
  {
    value: '603001.SS',
    label: '603001.SS,  ZheJiang AoKang Shoes Co.,Ltd.',
    name: 'ZheJiang AoKang Shoes Co.,Ltd.',
    symbol: '603001.SS',
  },
  {
    value: '603002.SS',
    label: '603002.SS,  Epoxy Base Electronic Material Corporation Limited',
    name: 'Epoxy Base Electronic Material Corporation Limited',
    symbol: '603002.SS',
  },
  {
    value: '603003.SS',
    label: '603003.SS,  Shanghai Lonyer Fuels Co., Ltd.',
    name: 'Shanghai Lonyer Fuels Co., Ltd.',
    symbol: '603003.SS',
  },
  {
    value: '603005.SS',
    label: '603005.SS,  China Wafer Level CSP Co., Ltd.',
    name: 'China Wafer Level CSP Co., Ltd.',
    symbol: '603005.SS',
  },
  {
    value: '603006.SS',
    label: '603006.SS,  Shanghai Lianming Machinery Co., Ltd.',
    name: 'Shanghai Lianming Machinery Co., Ltd.',
    symbol: '603006.SS',
  },
  {
    value: '603007.SS',
    label: '603007.SS,  Flower King Eco-Engineering Inc.',
    name: 'Flower King Eco-Engineering Inc.',
    symbol: '603007.SS',
  },
  {
    value: '603008.SS',
    label: '603008.SS,  Xilinmen Furniture Co.,Ltd',
    name: 'Xilinmen Furniture Co.,Ltd',
    symbol: '603008.SS',
  },
  {
    value: '603009.SS',
    label: '603009.SS,  Shanghai Beite Technology Co., Ltd.',
    name: 'Shanghai Beite Technology Co., Ltd.',
    symbol: '603009.SS',
  },
  {
    value: '603010.SS',
    label: '603010.SS,  Zhejiang Wansheng Co., Ltd.',
    name: 'Zhejiang Wansheng Co., Ltd.',
    symbol: '603010.SS',
  },
  {
    value: '603011.SS',
    label: '603011.SS,  Hefei Metalforming Intelligent Manufacturing Co., Ltd.',
    name: 'Hefei Metalforming Intelligent Manufacturing Co., Ltd.',
    symbol: '603011.SS',
  },
  {
    value: '603012.SS',
    label: '603012.SS,  Shanghai Chuangli Group Co., Ltd.',
    name: 'Shanghai Chuangli Group Co., Ltd.',
    symbol: '603012.SS',
  },
  {
    value: '603013.SS',
    label: '603013.SS,  YAPP Automotive Systems Co., Ltd.',
    name: 'YAPP Automotive Systems Co., Ltd.',
    symbol: '603013.SS',
  },
  {
    value: '603015.SS',
    label: '603015.SS,  Ningbo Techmation Co.,Ltd.',
    name: 'Ningbo Techmation Co.,Ltd.',
    symbol: '603015.SS',
  },
  {
    value: '603016.SS',
    label: '603016.SS,  Wuxi New Hongtai Electrical Technology Co.,Ltd',
    name: 'Wuxi New Hongtai Electrical Technology Co.,Ltd',
    symbol: '603016.SS',
  },
  {
    value: '603017.SS',
    label: '603017.SS,  ARTS Group Co., Ltd',
    name: 'ARTS Group Co., Ltd',
    symbol: '603017.SS',
  },
  {
    value: '603018.SS',
    label: '603018.SS,  China Design Group Co., Ltd.',
    name: 'China Design Group Co., Ltd.',
    symbol: '603018.SS',
  },
  {
    value: '603020.SS',
    label: '603020.SS,  Apple Flavor & Fragrance Group Co.,Ltd.',
    name: 'Apple Flavor & Fragrance Group Co.,Ltd.',
    symbol: '603020.SS',
  },
  {
    value: '603021.SS',
    label: '603021.SS,  Shandong Huapeng Glass Co.,Ltd.',
    name: 'Shandong Huapeng Glass Co.,Ltd.',
    symbol: '603021.SS',
  },
  {
    value: '603022.SS',
    label: '603022.SS,  Shanghai Xintonglian Packaging Co., Ltd.',
    name: 'Shanghai Xintonglian Packaging Co., Ltd.',
    symbol: '603022.SS',
  },
  {
    value: '603023.SS',
    label: '603023.SS,  Harbin VITI Electronics Co., Ltd.',
    name: 'Harbin VITI Electronics Co., Ltd.',
    symbol: '603023.SS',
  },
  {
    value: '603025.SS',
    label: '603025.SS,  Beijing Dahao Technology Corp.,Ltd',
    name: 'Beijing Dahao Technology Corp.,Ltd',
    symbol: '603025.SS',
  },
  {
    value: '603026.SS',
    label: '603026.SS,  Shinghwa Advanced Material Group Co., Ltd.',
    name: 'Shinghwa Advanced Material Group Co., Ltd.',
    symbol: '603026.SS',
  },
  {
    value: '603028.SS',
    label: '603028.SS,  Jiangsu Safety Wire Rope Co.,Ltd.',
    name: 'Jiangsu Safety Wire Rope Co.,Ltd.',
    symbol: '603028.SS',
  },
  {
    value: '603029.SS',
    label:
      '603029.SS,  Shandong Swan CottonIndustrial Machinery Stock Co.,Ltd.',
    name: 'Shandong Swan CottonIndustrial Machinery Stock Co.,Ltd.',
    symbol: '603029.SS',
  },
  {
    value: '603030.SS',
    label:
      '603030.SS,  Shanghai Trendzone Construction Decoration Group Co.,Ltd.',
    name: 'Shanghai Trendzone Construction Decoration Group Co.,Ltd.',
    symbol: '603030.SS',
  },
  {
    value: '603031.SS',
    label: '603031.SS,  Anhui Anfu Battery Technology Co., Ltd.',
    name: 'Anhui Anfu Battery Technology Co., Ltd.',
    symbol: '603031.SS',
  },
  {
    value: '603032.SS',
    label: '603032.SS,  Delixi Xinjiang Transportation Co.,Ltd.',
    name: 'Delixi Xinjiang Transportation Co.,Ltd.',
    symbol: '603032.SS',
  },
  {
    value: '603033.SS',
    label: '603033.SS,  Zhejiang Sanwei Rubber Item Co., Ltd.',
    name: 'Zhejiang Sanwei Rubber Item Co., Ltd.',
    symbol: '603033.SS',
  },
  {
    value: '603035.SS',
    label: '603035.SS,  Jiangsu Changshu Automotive Trim Group Co., Ltd.',
    name: 'Jiangsu Changshu Automotive Trim Group Co., Ltd.',
    symbol: '603035.SS',
  },
  {
    value: '603036.SS',
    label: '603036.SS,  Jiangsu Rutong Petro-Machinery Co., Ltd',
    name: 'Jiangsu Rutong Petro-Machinery Co., Ltd',
    symbol: '603036.SS',
  },
  {
    value: '603037.SS',
    label: '603037.SS,  Shanghai Carthane Co.,Ltd.',
    name: 'Shanghai Carthane Co.,Ltd.',
    symbol: '603037.SS',
  },
  {
    value: '603038.SS',
    label: '603038.SS,  Dongguan Huali Industries Co.,Ltd',
    name: 'Dongguan Huali Industries Co.,Ltd',
    symbol: '603038.SS',
  },
  {
    value: '603039.SS',
    label: '603039.SS,  Shanghai Weaver Network Co., Ltd.',
    name: 'Shanghai Weaver Network Co., Ltd.',
    symbol: '603039.SS',
  },
  {
    value: '603040.SS',
    label: '603040.SS,  Hangzhou XZB Tech Co.,Ltd',
    name: 'Hangzhou XZB Tech Co.,Ltd',
    symbol: '603040.SS',
  },
  {
    value: '603041.SS',
    label: '603041.SS,  Jiangsu Maysta Chemical Co., Ltd.',
    name: 'Jiangsu Maysta Chemical Co., Ltd.',
    symbol: '603041.SS',
  },
  {
    value: '603042.SS',
    label: '603042.SS,  Nanjing Huamai Technology Co., Ltd.',
    name: 'Nanjing Huamai Technology Co., Ltd.',
    symbol: '603042.SS',
  },
  {
    value: '603043.SS',
    label: '603043.SS,  Guangzhou Restaurant Group Company Limited',
    name: 'Guangzhou Restaurant Group Company Limited',
    symbol: '603043.SS',
  },
  {
    value: '603045.SS',
    label: '603045.SS,  Fuda Alloy Materials Co.,Ltd',
    name: 'Fuda Alloy Materials Co.,Ltd',
    symbol: '603045.SS',
  },
  {
    value: '603050.SS',
    label: '603050.SS,  Shijiazhuang Kelin Electric Co., Ltd.',
    name: 'Shijiazhuang Kelin Electric Co., Ltd.',
    symbol: '603050.SS',
  },
  {
    value: '603053.SS',
    label: '603053.SS,  Chengdu Gas Group Corporation Ltd.',
    name: 'Chengdu Gas Group Corporation Ltd.',
    symbol: '603053.SS',
  },
  {
    value: '603055.SS',
    label: '603055.SS,  Zhe Jiang Taihua New Material Co., Ltd.',
    name: 'Zhe Jiang Taihua New Material Co., Ltd.',
    symbol: '603055.SS',
  },
  {
    value: '603056.SS',
    label: '603056.SS,  DEPPON LOGISTICS Co.,LTD.',
    name: 'DEPPON LOGISTICS Co.,LTD.',
    symbol: '603056.SS',
  },
  {
    value: '603058.SS',
    label: '603058.SS,  GuiZhou YongJi Printing Co.,Ltd',
    name: 'GuiZhou YongJi Printing Co.,Ltd',
    symbol: '603058.SS',
  },
  {
    value: '603059.SS',
    label: '603059.SS,  Perfect Group Corp., Ltd',
    name: 'Perfect Group Corp., Ltd',
    symbol: '603059.SS',
  },
  {
    value: '603060.SS',
    label:
      '603060.SS,  China Building Material Test & Certification Group Co., Ltd.',
    name: 'China Building Material Test & Certification Group Co., Ltd.',
    symbol: '603060.SS',
  },
  {
    value: '603063.SS',
    label: '603063.SS,  Shenzhen Hopewind Electric Co., Ltd.',
    name: 'Shenzhen Hopewind Electric Co., Ltd.',
    symbol: '603063.SS',
  },
  {
    value: '603066.SS',
    label: '603066.SS,  Nanjing Inform Storage Equipment (Group) Co., Ltd.',
    name: 'Nanjing Inform Storage Equipment (Group) Co., Ltd.',
    symbol: '603066.SS',
  },
  {
    value: '603067.SS',
    label: '603067.SS,  Hubei Zhenhua Chemical Co.,Ltd.',
    name: 'Hubei Zhenhua Chemical Co.,Ltd.',
    symbol: '603067.SS',
  },
  {
    value: '603068.SS',
    label: '603068.SS,  Beken Corporation',
    name: 'Beken Corporation',
    symbol: '603068.SS',
  },
  {
    value: '603069.SS',
    label: '603069.SS,  Hainan Haiqi Transportation Group Co.,Ltd.',
    name: 'Hainan Haiqi Transportation Group Co.,Ltd.',
    symbol: '603069.SS',
  },
  {
    value: '603076.SS',
    label:
      '603076.SS,  Ningbo Lehui International Engineering Equipment Co.,Ltd',
    name: 'Ningbo Lehui International Engineering Equipment Co.,Ltd',
    symbol: '603076.SS',
  },
  {
    value: '603077.SS',
    label: '603077.SS,  Sichuan Hebang Biotechnology Corporation Limited',
    name: 'Sichuan Hebang Biotechnology Corporation Limited',
    symbol: '603077.SS',
  },
  {
    value: '603078.SS',
    label: '603078.SS,  Jiangyin Jianghua Microelectronics Materials Co., Ltd',
    name: 'Jiangyin Jianghua Microelectronics Materials Co., Ltd',
    symbol: '603078.SS',
  },
  {
    value: '603079.SS',
    label: '603079.SS,  Zhejiang Shengda Bio-Pharm Co., Ltd.',
    name: 'Zhejiang Shengda Bio-Pharm Co., Ltd.',
    symbol: '603079.SS',
  },
  {
    value: '603080.SS',
    label: '603080.SS,  Xinjiang Torch Gas Co., Ltd',
    name: 'Xinjiang Torch Gas Co., Ltd',
    symbol: '603080.SS',
  },
  {
    value: '603081.SS',
    label: '603081.SS,  Zhejiang Dafeng Industry Co., Ltd',
    name: 'Zhejiang Dafeng Industry Co., Ltd',
    symbol: '603081.SS',
  },
  {
    value: '603083.SS',
    label: '603083.SS,  CIG ShangHai Co., Ltd.',
    name: 'CIG ShangHai Co., Ltd.',
    symbol: '603083.SS',
  },
  {
    value: '603085.SS',
    label: '603085.SS,  Zhejiang Tiancheng Controls Co., Ltd.',
    name: 'Zhejiang Tiancheng Controls Co., Ltd.',
    symbol: '603085.SS',
  },
  {
    value: '603086.SS',
    label: '603086.SS,  ShanDong Cynda Chemical Co.,Ltd',
    name: 'ShanDong Cynda Chemical Co.,Ltd',
    symbol: '603086.SS',
  },
  {
    value: '603087.SS',
    label: '603087.SS,  Gan & Lee Pharmaceuticals.',
    name: 'Gan & Lee Pharmaceuticals.',
    symbol: '603087.SS',
  },
  {
    value: '603088.SS',
    label: '603088.SS,  JDM JingDaMachine(Ningbo)Co.Ltd',
    name: 'JDM JingDaMachine(Ningbo)Co.Ltd',
    symbol: '603088.SS',
  },
  {
    value: '603089.SS',
    label: '603089.SS,  ADD Industry (Zhejiang) CO., LTD',
    name: 'ADD Industry (Zhejiang) CO., LTD',
    symbol: '603089.SS',
  },
  {
    value: '603090.SS',
    label: '603090.SS,  Wuxi Hongsheng Heat Exchanger Manufacturing Co., Ltd.',
    name: 'Wuxi Hongsheng Heat Exchanger Manufacturing Co., Ltd.',
    symbol: '603090.SS',
  },
  {
    value: '603093.SS',
    label: '603093.SS,  Nanhua Futures Co., Ltd.',
    name: 'Nanhua Futures Co., Ltd.',
    symbol: '603093.SS',
  },
  {
    value: '603095.SS',
    label: '603095.SS,  Zhejiang Yuejian Intelligent Equipment Co.,Ltd.',
    name: 'Zhejiang Yuejian Intelligent Equipment Co.,Ltd.',
    symbol: '603095.SS',
  },
  {
    value: '603096.SS',
    label: '603096.SS,  Thinkingdom Media Group Ltd.',
    name: 'Thinkingdom Media Group Ltd.',
    symbol: '603096.SS',
  },
  {
    value: '603098.SS',
    label: '603098.SS,  Center International Group Co.,Ltd.',
    name: 'Center International Group Co.,Ltd.',
    symbol: '603098.SS',
  },
  {
    value: '603099.SS',
    label: '603099.SS,  Changbai Mountain Tourism Co., Ltd.',
    name: 'Changbai Mountain Tourism Co., Ltd.',
    symbol: '603099.SS',
  },
  {
    value: '603100.SS',
    label: '603100.SS,  Chongqing Chuanyi Automation Co., Ltd.',
    name: 'Chongqing Chuanyi Automation Co., Ltd.',
    symbol: '603100.SS',
  },
  {
    value: '603101.SS',
    label: '603101.SS,  Xinjiang Winka Times Department Store Co.,Ltd.',
    name: 'Xinjiang Winka Times Department Store Co.,Ltd.',
    symbol: '603101.SS',
  },
  {
    value: '603103.SS',
    label: '603103.SS,  Hengdian Entertainment Co.,LTD',
    name: 'Hengdian Entertainment Co.,LTD',
    symbol: '603103.SS',
  },
  {
    value: '603105.SS',
    label: '603105.SS,  Zhejiang Sunoren Solar Technology Co.,Ltd.',
    name: 'Zhejiang Sunoren Solar Technology Co.,Ltd.',
    symbol: '603105.SS',
  },
  {
    value: '603106.SS',
    label: '603106.SS,  Cashway Fintech Co.,Ltd.',
    name: 'Cashway Fintech Co.,Ltd.',
    symbol: '603106.SS',
  },
  {
    value: '603108.SS',
    label: '603108.SS,  Shanghai Runda Medical Technology Co., Ltd.',
    name: 'Shanghai Runda Medical Technology Co., Ltd.',
    symbol: '603108.SS',
  },
  {
    value: '603110.SS',
    label: '603110.SS,  New East New Materials Co., Ltd',
    name: 'New East New Materials Co., Ltd',
    symbol: '603110.SS',
  },
  {
    value: '603111.SS',
    label: '603111.SS,  Nanjing Kangni Mechanical & Electrical Co.,Ltd',
    name: 'Nanjing Kangni Mechanical & Electrical Co.,Ltd',
    symbol: '603111.SS',
  },
  {
    value: '603112.SS',
    label: '603112.SS,  Shanxi Huaxiang Group Co., Ltd.',
    name: 'Shanxi Huaxiang Group Co., Ltd.',
    symbol: '603112.SS',
  },
  {
    value: '603113.SS',
    label: '603113.SS,  Jinneng Science&Techology Co.,Ltd',
    name: 'Jinneng Science&Techology Co.,Ltd',
    symbol: '603113.SS',
  },
  {
    value: '603115.SS',
    label: '603115.SS,  Nantong Haixing Electronics Co., Ltd.',
    name: 'Nantong Haixing Electronics Co., Ltd.',
    symbol: '603115.SS',
  },
  {
    value: '603116.SS',
    label: '603116.SS,  Zhejiang Red Dragonfly Footwear Co., Ltd.',
    name: 'Zhejiang Red Dragonfly Footwear Co., Ltd.',
    symbol: '603116.SS',
  },
  {
    value: '603117.SS',
    label: '603117.SS,  Jiangsu Wanlin Modern Logistics Co., Ltd.',
    name: 'Jiangsu Wanlin Modern Logistics Co., Ltd.',
    symbol: '603117.SS',
  },
  {
    value: '603118.SS',
    label: '603118.SS,  Shenzhen Gongjin Electronics Co., Ltd.',
    name: 'Shenzhen Gongjin Electronics Co., Ltd.',
    symbol: '603118.SS',
  },
  {
    value: '603121.SS',
    label: '603121.SS,  Shanghai Sinotec (Group) Co., Ltd.',
    name: 'Shanghai Sinotec (Group) Co., Ltd.',
    symbol: '603121.SS',
  },
  {
    value: '603123.SS',
    label: '603123.SS,  Beijing Cuiwei Tower Co., Ltd.',
    name: 'Beijing Cuiwei Tower Co., Ltd.',
    symbol: '603123.SS',
  },
  {
    value: '603126.SS',
    label: '603126.SS,  Sinoma Energy Conservation Ltd.',
    name: 'Sinoma Energy Conservation Ltd.',
    symbol: '603126.SS',
  },
  {
    value: '603128.SS',
    label: '603128.SS,  CTS International Logistics Corporation Limited',
    name: 'CTS International Logistics Corporation Limited',
    symbol: '603128.SS',
  },
  {
    value: '603129.SS',
    label: '603129.SS,  Zhejiang Cfmoto Power Co.,Ltd',
    name: 'Zhejiang Cfmoto Power Co.,Ltd',
    symbol: '603129.SS',
  },
  {
    value: '603131.SS',
    label: '603131.SS,  Shanghai Hugong Electric Group Co.,Ltd.',
    name: 'Shanghai Hugong Electric Group Co.,Ltd.',
    symbol: '603131.SS',
  },
  {
    value: '603133.SS',
    label: '603133.SS,  Tanyuan Technology Co., Ltd.',
    name: 'Tanyuan Technology Co., Ltd.',
    symbol: '603133.SS',
  },
  {
    value: '603136.SS',
    label: '603136.SS,  Jiangsu Tianmu Lake Tourism Co.,Ltd',
    name: 'Jiangsu Tianmu Lake Tourism Co.,Ltd',
    symbol: '603136.SS',
  },
  {
    value: '603138.SS',
    label: '603138.SS,  Beijing Vastdata Technology Co., Ltd.',
    name: 'Beijing Vastdata Technology Co., Ltd.',
    symbol: '603138.SS',
  },
  {
    value: '603139.SS',
    label: '603139.SS,  Shaanxi Kanghui Pharmaceutical Co., Ltd.',
    name: 'Shaanxi Kanghui Pharmaceutical Co., Ltd.',
    symbol: '603139.SS',
  },
  {
    value: '603155.SS',
    label: '603155.SS,  Xinyaqiang Silicon Chemistry Co.,Ltd',
    name: 'Xinyaqiang Silicon Chemistry Co.,Ltd',
    symbol: '603155.SS',
  },
  {
    value: '603156.SS',
    label: '603156.SS,  Hebei Yangyuan ZhiHui Beverage Co., Ltd.',
    name: 'Hebei Yangyuan ZhiHui Beverage Co., Ltd.',
    symbol: '603156.SS',
  },
  {
    value: '603158.SS',
    label: '603158.SS,  Changzhou Tenglong AutoPartsCo.,Ltd.',
    name: 'Changzhou Tenglong AutoPartsCo.,Ltd.',
    symbol: '603158.SS',
  },
  {
    value: '603159.SS',
    label: '603159.SS,  Shanghai Yahong Moulding Co., Ltd.',
    name: 'Shanghai Yahong Moulding Co., Ltd.',
    symbol: '603159.SS',
  },
  {
    value: '603161.SS',
    label: '603161.SS,  Kehua Holdings Co., Ltd.',
    name: 'Kehua Holdings Co., Ltd.',
    symbol: '603161.SS',
  },
  {
    value: '603165.SS',
    label:
      '603165.SS,  Zhejiang Rongsheng Environmental Protection Paper Joint Stock Co., Ltd.',
    name: 'Zhejiang Rongsheng Environmental Protection Paper Joint Stock Co., Ltd.',
    symbol: '603165.SS',
  },
  {
    value: '603166.SS',
    label: '603166.SS,  GUILIN FUDA Co.,Ltd.',
    name: 'GUILIN FUDA Co.,Ltd.',
    symbol: '603166.SS',
  },
  {
    value: '603167.SS',
    label: '603167.SS,  Bohai Ferry Group Co., Ltd.',
    name: 'Bohai Ferry Group Co., Ltd.',
    symbol: '603167.SS',
  },
  {
    value: '603168.SS',
    label: '603168.SS,  Zhejiang Shapuaisi Pharmaceutical Co.,Ltd.',
    name: 'Zhejiang Shapuaisi Pharmaceutical Co.,Ltd.',
    symbol: '603168.SS',
  },
  {
    value: '603169.SS',
    label: '603169.SS,  Lanzhou LS Heavy Equipment Co., Ltd',
    name: 'Lanzhou LS Heavy Equipment Co., Ltd',
    symbol: '603169.SS',
  },
  {
    value: '603177.SS',
    label:
      '603177.SS,  Zhejiang Tuna Environmental Science & TechnologyCo.,Ltd.',
    name: 'Zhejiang Tuna Environmental Science & TechnologyCo.,Ltd.',
    symbol: '603177.SS',
  },
  {
    value: '603178.SS',
    label: '603178.SS,  Ningbo Shenglong Automotive Powertrain System Co.,Ltd.',
    name: 'Ningbo Shenglong Automotive Powertrain System Co.,Ltd.',
    symbol: '603178.SS',
  },
  {
    value: '603179.SS',
    label: '603179.SS,  Jiangsu Xinquan Automotive Trim Co.,Ltd.',
    name: 'Jiangsu Xinquan Automotive Trim Co.,Ltd.',
    symbol: '603179.SS',
  },
  {
    value: '603180.SS',
    label: '603180.SS,  GoldenHome Living Co., Ltd.',
    name: 'GoldenHome Living Co., Ltd.',
    symbol: '603180.SS',
  },
  {
    value: '603181.SS',
    label: '603181.SS,  Zhejiang Huangma Technology Co.,Ltd',
    name: 'Zhejiang Huangma Technology Co.,Ltd',
    symbol: '603181.SS',
  },
  {
    value: '603183.SS',
    label: '603183.SS,  Suzhou Institute of Building Science Group Co.,Ltd',
    name: 'Suzhou Institute of Building Science Group Co.,Ltd',
    symbol: '603183.SS',
  },
  {
    value: '603186.SS',
    label: '603186.SS,  Zhejiang Wazam New Materials Co.,LTD.',
    name: 'Zhejiang Wazam New Materials Co.,LTD.',
    symbol: '603186.SS',
  },
  {
    value: '603187.SS',
    label: '603187.SS,  Qingdao Hiron Commercial Cold Chain Co., Ltd.',
    name: 'Qingdao Hiron Commercial Cold Chain Co., Ltd.',
    symbol: '603187.SS',
  },
  {
    value: '603188.SS',
    label: '603188.SS,  Jiangsu Yabang Dyestuff Co., Ltd.',
    name: 'Jiangsu Yabang Dyestuff Co., Ltd.',
    symbol: '603188.SS',
  },
  {
    value: '603189.SS',
    label: '603189.SS,  Shanghai Wondertek Software Co., Ltd',
    name: 'Shanghai Wondertek Software Co., Ltd',
    symbol: '603189.SS',
  },
  {
    value: '603192.SS',
    label: '603192.SS,  Shanghai Huide Science & Technology Co.,Ltd',
    name: 'Shanghai Huide Science & Technology Co.,Ltd',
    symbol: '603192.SS',
  },
  {
    value: '603195.SS',
    label: '603195.SS,  Gongniu Group Co., Ltd.',
    name: 'Gongniu Group Co., Ltd.',
    symbol: '603195.SS',
  },
  {
    value: '603196.SS',
    label: '603196.SS,  Ribo Fashion Group Co., Ltd.',
    name: 'Ribo Fashion Group Co., Ltd.',
    symbol: '603196.SS',
  },
  {
    value: '603197.SS',
    label: '603197.SS,  Shanghai Baolong Automotive Corporation',
    name: 'Shanghai Baolong Automotive Corporation',
    symbol: '603197.SS',
  },
  {
    value: '603198.SS',
    label: '603198.SS,  Anhui Yingjia Distillery Co., Ltd.',
    name: 'Anhui Yingjia Distillery Co., Ltd.',
    symbol: '603198.SS',
  },
  {
    value: '603199.SS',
    label: '603199.SS,  Anhui Jiuhuashan Tourism Development Co., Ltd.',
    name: 'Anhui Jiuhuashan Tourism Development Co., Ltd.',
    symbol: '603199.SS',
  },
  {
    value: '603200.SS',
    label: '603200.SS,  Shanghai Emperor of Cleaning Hi-Tech Co., Ltd',
    name: 'Shanghai Emperor of Cleaning Hi-Tech Co., Ltd',
    symbol: '603200.SS',
  },
  {
    value: '603203.SS',
    label: '603203.SS,  Quick Intelligent Equipment Co., Ltd.',
    name: 'Quick Intelligent Equipment Co., Ltd.',
    symbol: '603203.SS',
  },
  {
    value: '603208.SS',
    label: '603208.SS,  Jiangshan Oupai Door Industry Co., Ltd',
    name: 'Jiangshan Oupai Door Industry Co., Ltd',
    symbol: '603208.SS',
  },
  {
    value: '603212.SS',
    label: '603212.SS,  Cybrid Technologies Inc.',
    name: 'Cybrid Technologies Inc.',
    symbol: '603212.SS',
  },
  {
    value: '603214.SS',
    label: '603214.SS,  Shanghai Aiyingshi Co.,Ltd',
    name: 'Shanghai Aiyingshi Co.,Ltd',
    symbol: '603214.SS',
  },
  {
    value: '603217.SS',
    label: '603217.SS,  Yuanli Chemical Group Co.,Ltd.',
    name: 'Yuanli Chemical Group Co.,Ltd.',
    symbol: '603217.SS',
  },
  {
    value: '603220.SS',
    label: '603220.SS,  China Bester Group Telecom Co., Ltd.',
    name: 'China Bester Group Telecom Co., Ltd.',
    symbol: '603220.SS',
  },
  {
    value: '603222.SS',
    label: '603222.SS,  Chimin Health Management Co., Ltd.',
    name: 'Chimin Health Management Co., Ltd.',
    symbol: '603222.SS',
  },
  {
    value: '603223.SS',
    label: '603223.SS,  Hengtong Logistics Co., Ltd.',
    name: 'Hengtong Logistics Co., Ltd.',
    symbol: '603223.SS',
  },
  {
    value: '603225.SS',
    label: '603225.SS,  Xinfengming Group Co., Ltd.',
    name: 'Xinfengming Group Co., Ltd.',
    symbol: '603225.SS',
  },
  {
    value: '603226.SS',
    label: '603226.SS,  Vohringer Home Technology Co.,Ltd.',
    name: 'Vohringer Home Technology Co.,Ltd.',
    symbol: '603226.SS',
  },
  {
    value: '603227.SS',
    label: '603227.SS,  Xinjiang Xuefeng Sci-Tech(Group)Co.,Ltd',
    name: 'Xinjiang Xuefeng Sci-Tech(Group)Co.,Ltd',
    symbol: '603227.SS',
  },
  {
    value: '603229.SS',
    label: '603229.SS,  Zhejiang Ausun Pharmaceutical Co., Ltd.',
    name: 'Zhejiang Ausun Pharmaceutical Co., Ltd.',
    symbol: '603229.SS',
  },
  {
    value: '603232.SS',
    label: '603232.SS,  Koal Software Co., Ltd.',
    name: 'Koal Software Co., Ltd.',
    symbol: '603232.SS',
  },
  {
    value: '603236.SS',
    label: '603236.SS,  Quectel Wireless Solutions Co., Ltd.',
    name: 'Quectel Wireless Solutions Co., Ltd.',
    symbol: '603236.SS',
  },
  {
    value: '603238.SS',
    label: '603238.SS,  HangZhou Nbond Nonwovens Co., Ltd.',
    name: 'HangZhou Nbond Nonwovens Co., Ltd.',
    symbol: '603238.SS',
  },
  {
    value: '603239.SS',
    label: '603239.SS,  Zhejiang Xiantong Rubber&Plastic Co.,Ltd',
    name: 'Zhejiang Xiantong Rubber&Plastic Co.,Ltd',
    symbol: '603239.SS',
  },
  {
    value: '603256.SS',
    label: '603256.SS,  Grace Fabric Technology Co.,Ltd.',
    name: 'Grace Fabric Technology Co.,Ltd.',
    symbol: '603256.SS',
  },
  {
    value: '603258.SS',
    label: '603258.SS,  Hangzhou Electronic Soul Network Technology Co., Ltd.',
    name: 'Hangzhou Electronic Soul Network Technology Co., Ltd.',
    symbol: '603258.SS',
  },
  {
    value: '603260.SS',
    label: '603260.SS,  Hoshine Silicon Industry Co., Ltd.',
    name: 'Hoshine Silicon Industry Co., Ltd.',
    symbol: '603260.SS',
  },
  {
    value: '603266.SS',
    label: '603266.SS,  Ningbo Tianlong Electronics Co., Ltd.',
    name: 'Ningbo Tianlong Electronics Co., Ltd.',
    symbol: '603266.SS',
  },
  {
    value: '603268.SS',
    label: '603268.SS,  Guangdong Songfa Ceramics Co.,Ltd.',
    name: 'Guangdong Songfa Ceramics Co.,Ltd.',
    symbol: '603268.SS',
  },
  {
    value: '603269.SS',
    label: '603269.SS,  Jiangsu Seagull Cooling Tower Co.,Ltd.',
    name: 'Jiangsu Seagull Cooling Tower Co.,Ltd.',
    symbol: '603269.SS',
  },
  {
    value: '603277.SS',
    label: '603277.SS,  Yindu Kitchen Equipment Co., Ltd',
    name: 'Yindu Kitchen Equipment Co., Ltd',
    symbol: '603277.SS',
  },
  {
    value: '603278.SS',
    label: '603278.SS,  Shandong Daye Co., Ltd.',
    name: 'Shandong Daye Co., Ltd.',
    symbol: '603278.SS',
  },
  {
    value: '603283.SS',
    label: '603283.SS,  Suzhou Secote Precision Electronic Co.,LTD',
    name: 'Suzhou Secote Precision Electronic Co.,LTD',
    symbol: '603283.SS',
  },
  {
    value: '603286.SS',
    label: '603286.SS,  Jiangsu Riying Electronics Co.,Ltd.',
    name: 'Jiangsu Riying Electronics Co.,Ltd.',
    symbol: '603286.SS',
  },
  {
    value: '603289.SS',
    label: '603289.SS,  Zhejiang Tederic Machinery Co., Ltd.',
    name: 'Zhejiang Tederic Machinery Co., Ltd.',
    symbol: '603289.SS',
  },
  {
    value: '603297.SS',
    label: '603297.SS,  Ningbo Yongxin Optics Co.,Ltd',
    name: 'Ningbo Yongxin Optics Co.,Ltd',
    symbol: '603297.SS',
  },
  {
    value: '603299.SS',
    label: '603299.SS,  Jiang Su Suyan Jingshen Co.,Ltd.',
    name: 'Jiang Su Suyan Jingshen Co.,Ltd.',
    symbol: '603299.SS',
  },
  {
    value: '603300.SS',
    label:
      '603300.SS,  Zhejiang Huatie Emergency Equipment Science & Technology Co.,Ltd.',
    name: 'Zhejiang Huatie Emergency Equipment Science & Technology Co.,Ltd.',
    symbol: '603300.SS',
  },
  {
    value: '603301.SS',
    label: '603301.SS,  Zhende Medical Co., Ltd.',
    name: 'Zhende Medical Co., Ltd.',
    symbol: '603301.SS',
  },
  {
    value: '603303.SS',
    label: '603303.SS,  Hengdian Group Tospo Lighting Co., Ltd.',
    name: 'Hengdian Group Tospo Lighting Co., Ltd.',
    symbol: '603303.SS',
  },
  {
    value: '603305.SS',
    label: '603305.SS,  Ningbo Xusheng Auto Technology Co.,Ltd',
    name: 'Ningbo Xusheng Auto Technology Co.,Ltd',
    symbol: '603305.SS',
  },
  {
    value: '603306.SS',
    label: '603306.SS,  HMT (Xiamen) New Technical Materials Co., Ltd',
    name: 'HMT (Xiamen) New Technical Materials Co., Ltd',
    symbol: '603306.SS',
  },
  {
    value: '603308.SS',
    label: '603308.SS,  Anhui Yingliu Electromechanical Co., Ltd.',
    name: 'Anhui Yingliu Electromechanical Co., Ltd.',
    symbol: '603308.SS',
  },
  {
    value: '603309.SS',
    label: '603309.SS,  Well Lead Medical Co., Ltd.',
    name: 'Well Lead Medical Co., Ltd.',
    symbol: '603309.SS',
  },
  {
    value: '603311.SS',
    label: '603311.SS,  Zhejiang Goldensea Hi-Tech Co., Ltd',
    name: 'Zhejiang Goldensea Hi-Tech Co., Ltd',
    symbol: '603311.SS',
  },
  {
    value: '603313.SS',
    label: '603313.SS,  Healthcare Co., Ltd.',
    name: 'Healthcare Co., Ltd.',
    symbol: '603313.SS',
  },
  {
    value: '603315.SS',
    label: '603315.SS,  Liaoning Fu-An Heavy Industry Co.,Ltd',
    name: 'Liaoning Fu-An Heavy Industry Co.,Ltd',
    symbol: '603315.SS',
  },
  {
    value: '603316.SS',
    label: '603316.SS,  Chengbang Eco-Environment Co.,Ltd.',
    name: 'Chengbang Eco-Environment Co.,Ltd.',
    symbol: '603316.SS',
  },
  {
    value: '603318.SS',
    label: '603318.SS,  Shuifa Energas Gas Co., Ltd.',
    name: 'Shuifa Energas Gas Co., Ltd.',
    symbol: '603318.SS',
  },
  {
    value: '603319.SS',
    label: '603319.SS,  Hunan Oil Pump Co., Ltd.',
    name: 'Hunan Oil Pump Co., Ltd.',
    symbol: '603319.SS',
  },
  {
    value: '603320.SS',
    label: '603320.SS,  ZHEJIANG DIBAY ELECTRIC CO.,Ltd.',
    name: 'ZHEJIANG DIBAY ELECTRIC CO.,Ltd.',
    symbol: '603320.SS',
  },
  {
    value: '603321.SS',
    label: '603321.SS,  Zhejiang Meilun Elevator Co., Ltd.',
    name: 'Zhejiang Meilun Elevator Co., Ltd.',
    symbol: '603321.SS',
  },
  {
    value: '603322.SS',
    label: '603322.SS,  Super Telecom Co.,Ltd',
    name: 'Super Telecom Co.,Ltd',
    symbol: '603322.SS',
  },
  {
    value: '603323.SS',
    label: '603323.SS,  Jiangsu Suzhou Rural Commercial Bank Co., Ltd',
    name: 'Jiangsu Suzhou Rural Commercial Bank Co., Ltd',
    symbol: '603323.SS',
  },
  {
    value: '603326.SS',
    label: '603326.SS,  Nanjing OLO Home Furnishing Co.,Ltd',
    name: 'Nanjing OLO Home Furnishing Co.,Ltd',
    symbol: '603326.SS',
  },
  {
    value: '603327.SS',
    label: '603327.SS,  Sichuan Furong Technology Co., Ltd.',
    name: 'Sichuan Furong Technology Co., Ltd.',
    symbol: '603327.SS',
  },
  {
    value: '603328.SS',
    label: '603328.SS,  Guangdong Ellington Electronics Technology Co.,Ltd',
    name: 'Guangdong Ellington Electronics Technology Co.,Ltd',
    symbol: '603328.SS',
  },
  {
    value: '603329.SS',
    label: '603329.SS,  Shanghai Ace Investment & Development Co., Ltd.',
    name: 'Shanghai Ace Investment & Development Co., Ltd.',
    symbol: '603329.SS',
  },
  {
    value: '603330.SS',
    label: '603330.SS,  Shanghai Tianyang Hot Melt Adhesives Co., Ltd.',
    name: 'Shanghai Tianyang Hot Melt Adhesives Co., Ltd.',
    symbol: '603330.SS',
  },
  {
    value: '603331.SS',
    label: '603331.SS,  Zhejiang Baida Precision Manufacturing Corp.',
    name: 'Zhejiang Baida Precision Manufacturing Corp.',
    symbol: '603331.SS',
  },
  {
    value: '603332.SS',
    label: '603332.SS,  Suzhou Longjie Special Fiber Co., Ltd.',
    name: 'Suzhou Longjie Special Fiber Co., Ltd.',
    symbol: '603332.SS',
  },
  {
    value: '603333.SS',
    label: '603333.SS,  Sunway Co., Ltd.',
    name: 'Sunway Co., Ltd.',
    symbol: '603333.SS',
  },
  {
    value: '603335.SS',
    label: '603335.SS,  Guangdong Dcenti Auto-Parts Stock Limited Company',
    name: 'Guangdong Dcenti Auto-Parts Stock Limited Company',
    symbol: '603335.SS',
  },
  {
    value: '603336.SS',
    label: '603336.SS,  Great-Sun Foods Co., Ltd.',
    name: 'Great-Sun Foods Co., Ltd.',
    symbol: '603336.SS',
  },
  {
    value: '603337.SS',
    label: '603337.SS,  Jack Technology Co.,Ltd',
    name: 'Jack Technology Co.,Ltd',
    symbol: '603337.SS',
  },
  {
    value: '603339.SS',
    label: '603339.SS,  Square Technology Group Co.,Ltd',
    name: 'Square Technology Group Co.,Ltd',
    symbol: '603339.SS',
  },
  {
    value: '603348.SS',
    label: '603348.SS,  Wencan Group Co.,Ltd.',
    name: 'Wencan Group Co.,Ltd.',
    symbol: '603348.SS',
  },
  {
    value: '603351.SS',
    label: '603351.SS,  Nanjing Well Pharmaceutical Group Co.,Ltd.',
    name: 'Nanjing Well Pharmaceutical Group Co.,Ltd.',
    symbol: '603351.SS',
  },
  {
    value: '603353.SS',
    label: '603353.SS,  Hunan Heshun Petroleum Co.,Ltd.',
    name: 'Hunan Heshun Petroleum Co.,Ltd.',
    symbol: '603353.SS',
  },
  {
    value: '603356.SS',
    label: '603356.SS,  Xuancheng Valin Precision Technology Co., Ltd.',
    name: 'Xuancheng Valin Precision Technology Co., Ltd.',
    symbol: '603356.SS',
  },
  {
    value: '603357.SS',
    label: '603357.SS,  Anhui Transport Consulting & Design Institute Co.,Ltd.',
    name: 'Anhui Transport Consulting & Design Institute Co.,Ltd.',
    symbol: '603357.SS',
  },
  {
    value: '603358.SS',
    label: '603358.SS,  Huada Automotive Technology Corp.,Ltd',
    name: 'Huada Automotive Technology Corp.,Ltd',
    symbol: '603358.SS',
  },
  {
    value: '603359.SS',
    label: '603359.SS,  Dongzhu Ecological Environment Protection Co.,Ltd',
    name: 'Dongzhu Ecological Environment Protection Co.,Ltd',
    symbol: '603359.SS',
  },
  {
    value: '603365.SS',
    label: '603365.SS,  Shanghai Shuixing Home Textile Co., Ltd.',
    name: 'Shanghai Shuixing Home Textile Co., Ltd.',
    symbol: '603365.SS',
  },
  {
    value: '603366.SS',
    label: '603366.SS,  Solareast Holdings Co., Ltd.',
    name: 'Solareast Holdings Co., Ltd.',
    symbol: '603366.SS',
  },
  {
    value: '603367.SS',
    label: '603367.SS,  Cisen Pharmaceutical Co., Ltd.',
    name: 'Cisen Pharmaceutical Co., Ltd.',
    symbol: '603367.SS',
  },
  {
    value: '603368.SS',
    label: '603368.SS,  Guangxi LiuYao Group Co., Ltd',
    name: 'Guangxi LiuYao Group Co., Ltd',
    symbol: '603368.SS',
  },
  {
    value: '603377.SS',
    label: '603377.SS,  Eastern Pioneer Driving School Co., Ltd',
    name: 'Eastern Pioneer Driving School Co., Ltd',
    symbol: '603377.SS',
  },
  {
    value: '603378.SS',
    label: '603378.SS,  Asia Cuanon Technology (Shanghai) Co.,Ltd.',
    name: 'Asia Cuanon Technology (Shanghai) Co.,Ltd.',
    symbol: '603378.SS',
  },
  {
    value: '603380.SS',
    label: '603380.SS,  Suzhou Etron Technologies Co.,Ltd.',
    name: 'Suzhou Etron Technologies Co.,Ltd.',
    symbol: '603380.SS',
  },
  {
    value: '603383.SS',
    label: '603383.SS,  Fujian Apex Software Co.,LTD',
    name: 'Fujian Apex Software Co.,LTD',
    symbol: '603383.SS',
  },
  {
    value: '603385.SS',
    label: '603385.SS,  Huida Sanitary Ware Co., Ltd.',
    name: 'Huida Sanitary Ware Co., Ltd.',
    symbol: '603385.SS',
  },
  {
    value: '603386.SS',
    label: '603386.SS,  Guangdong Champion Asia Electronics Co.,Ltd.',
    name: 'Guangdong Champion Asia Electronics Co.,Ltd.',
    symbol: '603386.SS',
  },
  {
    value: '603388.SS',
    label: '603388.SS,  Yuancheng Environment Co., Ltd.',
    name: 'Yuancheng Environment Co., Ltd.',
    symbol: '603388.SS',
  },
  {
    value: '603389.SS',
    label: '603389.SS,  A-Zenith Home Furnishings Co., Ltd.',
    name: 'A-Zenith Home Furnishings Co., Ltd.',
    symbol: '603389.SS',
  },
  {
    value: '603393.SS',
    label: '603393.SS,  Xinjiang Xintai Natural Gas Co., Ltd.',
    name: 'Xinjiang Xintai Natural Gas Co., Ltd.',
    symbol: '603393.SS',
  },
  {
    value: '603396.SS',
    label: '603396.SS,  Yingkou Jinchen Machinery Co., Ltd.',
    name: 'Yingkou Jinchen Machinery Co., Ltd.',
    symbol: '603396.SS',
  },
  {
    value: '603398.SS',
    label: '603398.SS,  BanBao Co., Ltd.',
    name: 'BanBao Co., Ltd.',
    symbol: '603398.SS',
  },
  {
    value: '603399.SS',
    label: '603399.SS,  Jinzhou Jixiang Molybdenum Co., Ltd.',
    name: 'Jinzhou Jixiang Molybdenum Co., Ltd.',
    symbol: '603399.SS',
  },
  {
    value: '603408.SS',
    label: '603408.SS,  Runner (Xiamen) Corp.',
    name: 'Runner (Xiamen) Corp.',
    symbol: '603408.SS',
  },
  {
    value: '603416.SS',
    label: '603416.SS,  WuXi Xinje Electric Co.,Ltd.',
    name: 'WuXi Xinje Electric Co.,Ltd.',
    symbol: '603416.SS',
  },
  {
    value: '603421.SS',
    label: '603421.SS,  Qingdao Topscomm Communication INC.',
    name: 'Qingdao Topscomm Communication INC.',
    symbol: '603421.SS',
  },
  {
    value: '603429.SS',
    label: '603429.SS,  Anhui Genuine New Materials Co.,Ltd.',
    name: 'Anhui Genuine New Materials Co.,Ltd.',
    symbol: '603429.SS',
  },
  {
    value: '603439.SS',
    label: '603439.SS,  GuiZhou SanLi Pharmaceutical Co.,Ltd',
    name: 'GuiZhou SanLi Pharmaceutical Co.,Ltd',
    symbol: '603439.SS',
  },
  {
    value: '603458.SS',
    label:
      '603458.SS,  Guizhou Transportation Planning Survey&Design Academe Co.,Ltd.',
    name: 'Guizhou Transportation Planning Survey&Design Academe Co.,Ltd.',
    symbol: '603458.SS',
  },
  {
    value: '603466.SS',
    label: '603466.SS,  Shanghai Fengyuzhu Culture Technology Co., Ltd.',
    name: 'Shanghai Fengyuzhu Culture Technology Co., Ltd.',
    symbol: '603466.SS',
  },
  {
    value: '603477.SS',
    label: '603477.SS,  Leshan Giantstar Farming&Husbandry Corporation Limited',
    name: 'Leshan Giantstar Farming&Husbandry Corporation Limited',
    symbol: '603477.SS',
  },
  {
    value: '603488.SS',
    label: '603488.SS,  Flying Technology Co., Ltd.',
    name: 'Flying Technology Co., Ltd.',
    symbol: '603488.SS',
  },
  {
    value: '603489.SS',
    label: '603489.SS,  Bafang Electric (Suzhou) Co.,Ltd.',
    name: 'Bafang Electric (Suzhou) Co.,Ltd.',
    symbol: '603489.SS',
  },
  {
    value: '603496.SS',
    label: '603496.SS,  EmbedWay Technologies (Shanghai) Corporation',
    name: 'EmbedWay Technologies (Shanghai) Corporation',
    symbol: '603496.SS',
  },
  {
    value: '603499.SS',
    label: '603499.SS,  Shanghai Sunglow Packaging Technology Co.,Ltd',
    name: 'Shanghai Sunglow Packaging Technology Co.,Ltd',
    symbol: '603499.SS',
  },
  {
    value: '603500.SS',
    label: '603500.SS,  Zhejiang Tiantai Xianghe Industrial Co.,Ltd.',
    name: 'Zhejiang Tiantai Xianghe Industrial Co.,Ltd.',
    symbol: '603500.SS',
  },
  {
    value: '603505.SS',
    label: '603505.SS,  China Kings Resources Group Co.,Ltd.',
    name: 'China Kings Resources Group Co.,Ltd.',
    symbol: '603505.SS',
  },
  {
    value: '603506.SS',
    label: '603506.SS,  Nacity Property Service Group Co.,Ltd.',
    name: 'Nacity Property Service Group Co.,Ltd.',
    symbol: '603506.SS',
  },
  {
    value: '603507.SS',
    label: '603507.SS,  JiangSu Zhenjiang New Energy Equipment Co., Ltd.',
    name: 'JiangSu Zhenjiang New Energy Equipment Co., Ltd.',
    symbol: '603507.SS',
  },
  {
    value: '603508.SS',
    label: '603508.SS,  Henan Thinker Automatic Equipment Co.,Ltd.',
    name: 'Henan Thinker Automatic Equipment Co.,Ltd.',
    symbol: '603508.SS',
  },
  {
    value: '603515.SS',
    label: '603515.SS,  Opple Lighting Co.,LTD',
    name: 'Opple Lighting Co.,LTD',
    symbol: '603515.SS',
  },
  {
    value: '603516.SS',
    label: '603516.SS,  Beijing Tricolor Technology Co., Ltd',
    name: 'Beijing Tricolor Technology Co., Ltd',
    symbol: '603516.SS',
  },
  {
    value: '603518.SS',
    label: '603518.SS,  Jinhong Fashion Group Co.,Ltd.',
    name: 'Jinhong Fashion Group Co.,Ltd.',
    symbol: '603518.SS',
  },
  {
    value: '603519.SS',
    label: '603519.SS,  Jiangsu Liba Enterprise Joint-Stock Co., Ltd.',
    name: 'Jiangsu Liba Enterprise Joint-Stock Co., Ltd.',
    symbol: '603519.SS',
  },
  {
    value: '603520.SS',
    label: '603520.SS,  Zhejiang Starry Pharmaceutical Co.,Ltd.',
    name: 'Zhejiang Starry Pharmaceutical Co.,Ltd.',
    symbol: '603520.SS',
  },
  {
    value: '603527.SS',
    label: '603527.SS,  Anhui Zhongyuan New Materials Co., Ltd.',
    name: 'Anhui Zhongyuan New Materials Co., Ltd.',
    symbol: '603527.SS',
  },
  {
    value: '603528.SS',
    label: '603528.SS,  DuoLun Technology Corporation Ltd.',
    name: 'DuoLun Technology Corporation Ltd.',
    symbol: '603528.SS',
  },
  {
    value: '603530.SS',
    label: '603530.SS,  Jiangsu Shemar Electric Co.,Ltd',
    name: 'Jiangsu Shemar Electric Co.,Ltd',
    symbol: '603530.SS',
  },
  {
    value: '603533.SS',
    label: '603533.SS,  IReader Technology Co., Ltd.',
    name: 'IReader Technology Co., Ltd.',
    symbol: '603533.SS',
  },
  {
    value: '603535.SS',
    label: '603535.SS,  Guangzhou Jiacheng International Logistics Co.,Ltd.',
    name: 'Guangzhou Jiacheng International Logistics Co.,Ltd.',
    symbol: '603535.SS',
  },
  {
    value: '603536.SS',
    label: '603536.SS,  Shandong Huifa Foodstuff Co.,Ltd.',
    name: 'Shandong Huifa Foodstuff Co.,Ltd.',
    symbol: '603536.SS',
  },
  {
    value: '603538.SS',
    label: '603538.SS,  Ningbo Menovo Pharmaceutical Co., Ltd.',
    name: 'Ningbo Menovo Pharmaceutical Co., Ltd.',
    symbol: '603538.SS',
  },
  {
    value: '603551.SS',
    label: '603551.SS,  AUPU Home Style Corporation Limited',
    name: 'AUPU Home Style Corporation Limited',
    symbol: '603551.SS',
  },
  {
    value: '603555.SS',
    label: '603555.SS,  Guirenniao Co., Ltd.',
    name: 'Guirenniao Co., Ltd.',
    symbol: '603555.SS',
  },
  {
    value: '603557.SS',
    label: '603557.SS,  Qibu Co.,Ltd.',
    name: 'Qibu Co.,Ltd.',
    symbol: '603557.SS',
  },
  {
    value: '603558.SS',
    label: '603558.SS,  Zhejiang Jasan Holding Group Co., Ltd.',
    name: 'Zhejiang Jasan Holding Group Co., Ltd.',
    symbol: '603558.SS',
  },
  {
    value: '603559.SS',
    label: '603559.SS,  Zhongtong Guomai Communication Co., Ltd.',
    name: 'Zhongtong Guomai Communication Co., Ltd.',
    symbol: '603559.SS',
  },
  {
    value: '603565.SS',
    label: '603565.SS,  Shanghai Zhonggu Logistics Co., Ltd.',
    name: 'Shanghai Zhonggu Logistics Co., Ltd.',
    symbol: '603565.SS',
  },
  {
    value: '603566.SS',
    label: '603566.SS,  Pulike Biological Engineering, Inc.',
    name: 'Pulike Biological Engineering, Inc.',
    symbol: '603566.SS',
  },
  {
    value: '603567.SS',
    label: '603567.SS,  Heilongjiang ZBD Pharmaceutical Co., Ltd.',
    name: 'Heilongjiang ZBD Pharmaceutical Co., Ltd.',
    symbol: '603567.SS',
  },
  {
    value: '603568.SS',
    label: '603568.SS,  Zhejiang Weiming Environment Protection Co., Ltd.',
    name: 'Zhejiang Weiming Environment Protection Co., Ltd.',
    symbol: '603568.SS',
  },
  {
    value: '603569.SS',
    label: '603569.SS,  Beijing Changjiu Logistics Co.,Ltd',
    name: 'Beijing Changjiu Logistics Co.,Ltd',
    symbol: '603569.SS',
  },
  {
    value: '603577.SS',
    label: '603577.SS,  Qingdao Huijintong Power Equipment Co.,Ltd.',
    name: 'Qingdao Huijintong Power Equipment Co.,Ltd.',
    symbol: '603577.SS',
  },
  {
    value: '603578.SS',
    label: '603578.SS,  Zhejiang Three Stars New Materials Co., Ltd.',
    name: 'Zhejiang Three Stars New Materials Co., Ltd.',
    symbol: '603578.SS',
  },
  {
    value: '603579.SS',
    label: '603579.SS,  Shanghai Rongtai Health Technology Corporation Limited',
    name: 'Shanghai Rongtai Health Technology Corporation Limited',
    symbol: '603579.SS',
  },
  {
    value: '603580.SS',
    label: '603580.SS,  AA Industrial Belting (Shanghai) Co.,Ltd',
    name: 'AA Industrial Belting (Shanghai) Co.,Ltd',
    symbol: '603580.SS',
  },
  {
    value: '603583.SS',
    label: '603583.SS,  Zhejiang Jiecang Linear Motion Technology Co.,Ltd.',
    name: 'Zhejiang Jiecang Linear Motion Technology Co.,Ltd.',
    symbol: '603583.SS',
  },
  {
    value: '603585.SS',
    label: '603585.SS,  Suli Co., Ltd.',
    name: 'Suli Co., Ltd.',
    symbol: '603585.SS',
  },
  {
    value: '603586.SS',
    label: '603586.SS,  Shandong Gold Phoenix Co.,Ltd',
    name: 'Shandong Gold Phoenix Co.,Ltd',
    symbol: '603586.SS',
  },
  {
    value: '603587.SS',
    label: '603587.SS,  Dazzle Fashion Co., Ltd',
    name: 'Dazzle Fashion Co., Ltd',
    symbol: '603587.SS',
  },
  {
    value: '603588.SS',
    label: '603588.SS,  Beijing GeoEnviron Engineering & Technology, Inc.',
    name: 'Beijing GeoEnviron Engineering & Technology, Inc.',
    symbol: '603588.SS',
  },
  {
    value: '603590.SS',
    label: '603590.SS,  Beijing Konruns Pharmaceutical Co.,Ltd.',
    name: 'Beijing Konruns Pharmaceutical Co.,Ltd.',
    symbol: '603590.SS',
  },
  {
    value: '603595.SS',
    label: '603595.SS,  Zhejiang Tony Electronic Co., Ltd',
    name: 'Zhejiang Tony Electronic Co., Ltd',
    symbol: '603595.SS',
  },
  {
    value: '603596.SS',
    label: '603596.SS,  Bethel Automotive Safety Systems Co., Ltd',
    name: 'Bethel Automotive Safety Systems Co., Ltd',
    symbol: '603596.SS',
  },
  {
    value: '603598.SS',
    label: '603598.SS,  Inly Media Co., Ltd.',
    name: 'Inly Media Co., Ltd.',
    symbol: '603598.SS',
  },
  {
    value: '603599.SS',
    label: '603599.SS,  Anhui Guangxin Agrochemical Co., Ltd.',
    name: 'Anhui Guangxin Agrochemical Co., Ltd.',
    symbol: '603599.SS',
  },
  {
    value: '603600.SS',
    label: '603600.SS,  UE Furniture Co., Ltd.',
    name: 'UE Furniture Co., Ltd.',
    symbol: '603600.SS',
  },
  {
    value: '603601.SS',
    label: '603601.SS,  Chongqing Zaisheng Technology Co., Ltd.',
    name: 'Chongqing Zaisheng Technology Co., Ltd.',
    symbol: '603601.SS',
  },
  {
    value: '603602.SS',
    label: '603602.SS,  Hangzhou Freely Communication Co., Ltd.',
    name: 'Hangzhou Freely Communication Co., Ltd.',
    symbol: '603602.SS',
  },
  {
    value: '603603.SS',
    label: '603603.SS,  Poten Environment Group Co.,Ltd.',
    name: 'Poten Environment Group Co.,Ltd.',
    symbol: '603603.SS',
  },
  {
    value: '603606.SS',
    label: '603606.SS,  Ningbo Orient Wires & Cables Co.,Ltd.',
    name: 'Ningbo Orient Wires & Cables Co.,Ltd.',
    symbol: '603606.SS',
  },
  {
    value: '603607.SS',
    label: '603607.SS,  Zhejiang Jinghua Laser Technology Co.,Ltd',
    name: 'Zhejiang Jinghua Laser Technology Co.,Ltd',
    symbol: '603607.SS',
  },
  {
    value: '603608.SS',
    label: '603608.SS,  Topscore Fashion Co., Ltd.',
    name: 'Topscore Fashion Co., Ltd.',
    symbol: '603608.SS',
  },
  {
    value: '603610.SS',
    label: '603610.SS,  Keeson Technology Corporation Limited',
    name: 'Keeson Technology Corporation Limited',
    symbol: '603610.SS',
  },
  {
    value: '603611.SS',
    label: '603611.SS,  Noblelift Intelligent Equipment Co.,Ltd.',
    name: 'Noblelift Intelligent Equipment Co.,Ltd.',
    symbol: '603611.SS',
  },
  {
    value: '603612.SS',
    label: '603612.SS,  Sunstone Development Co., Ltd.',
    name: 'Sunstone Development Co., Ltd.',
    symbol: '603612.SS',
  },
  {
    value: '603615.SS',
    label: '603615.SS,  Chahua Modern Housewares Co., Ltd.',
    name: 'Chahua Modern Housewares Co., Ltd.',
    symbol: '603615.SS',
  },
  {
    value: '603616.SS',
    label: '603616.SS,  Beijing Hanjian Heshan Pipeline Co.,Ltd',
    name: 'Beijing Hanjian Heshan Pipeline Co.,Ltd',
    symbol: '603616.SS',
  },
  {
    value: '603617.SS',
    label: '603617.SS,  Junhe Pumps Holding Co., Ltd',
    name: 'Junhe Pumps Holding Co., Ltd',
    symbol: '603617.SS',
  },
  {
    value: '603618.SS',
    label: '603618.SS,  Hangzhou Cable Co., Ltd.',
    name: 'Hangzhou Cable Co., Ltd.',
    symbol: '603618.SS',
  },
  {
    value: '603619.SS',
    label: '603619.SS,  Zhongman Petroleum and Natural Gas Group Corp., Ltd.',
    name: 'Zhongman Petroleum and Natural Gas Group Corp., Ltd.',
    symbol: '603619.SS',
  },
  {
    value: '603626.SS',
    label: '603626.SS,  Kunshan Kersen Science & Technology Co.,Ltd.',
    name: 'Kunshan Kersen Science & Technology Co.,Ltd.',
    symbol: '603626.SS',
  },
  {
    value: '603628.SS',
    label: '603628.SS,  Clenergy Technology Co., Ltd.',
    name: 'Clenergy Technology Co., Ltd.',
    symbol: '603628.SS',
  },
  {
    value: '603629.SS',
    label: '603629.SS,  Jiangsu Lettall Electronic Co.,Ltd',
    name: 'Jiangsu Lettall Electronic Co.,Ltd',
    symbol: '603629.SS',
  },
  {
    value: '603630.SS',
    label: '603630.SS,  Lafang China Co.,Ltd',
    name: 'Lafang China Co.,Ltd',
    symbol: '603630.SS',
  },
  {
    value: '603633.SS',
    label: '603633.SS,  Shanghai Laimu Electronics Co.,Ltd.',
    name: 'Shanghai Laimu Electronics Co.,Ltd.',
    symbol: '603633.SS',
  },
  {
    value: '603636.SS',
    label: '603636.SS,  Linewell Software Co., Ltd.',
    name: 'Linewell Software Co., Ltd.',
    symbol: '603636.SS',
  },
  {
    value: '603637.SS',
    label: '603637.SS,  Zhenhai Petrochemical Engineering CO., LTD',
    name: 'Zhenhai Petrochemical Engineering CO., LTD',
    symbol: '603637.SS',
  },
  {
    value: '603639.SS',
    label: '603639.SS,  Hailir Pesticides and Chemicals Group Co.,Ltd.',
    name: 'Hailir Pesticides and Chemicals Group Co.,Ltd.',
    symbol: '603639.SS',
  },
  {
    value: '603648.SS',
    label: '603648.SS,  Shanghai Shine-Link International Logistics Co., Ltd.',
    name: 'Shanghai Shine-Link International Logistics Co., Ltd.',
    symbol: '603648.SS',
  },
  {
    value: '603650.SS',
    label: '603650.SS,  Red Avenue New Materials Group Co., Ltd.',
    name: 'Red Avenue New Materials Group Co., Ltd.',
    symbol: '603650.SS',
  },
  {
    value: '603655.SS',
    label: '603655.SS,  Changzhou Langbo Sealing Technologies Co.,Ltd.',
    name: 'Changzhou Langbo Sealing Technologies Co.,Ltd.',
    symbol: '603655.SS',
  },
  {
    value: '603656.SS',
    label: '603656.SS,  Hefei Taihe Optoelectronic Technology Co., Ltd.',
    name: 'Hefei Taihe Optoelectronic Technology Co., Ltd.',
    symbol: '603656.SS',
  },
  {
    value: '603657.SS',
    label: '603657.SS,  Jinhua Chunguang Technology Co.,Ltd',
    name: 'Jinhua Chunguang Technology Co.,Ltd',
    symbol: '603657.SS',
  },
  {
    value: '603660.SS',
    label: '603660.SS,  Suzhou Keda Technology Co.,Ltd',
    name: 'Suzhou Keda Technology Co.,Ltd',
    symbol: '603660.SS',
  },
  {
    value: '603661.SS',
    label: '603661.SS,  Henglin Home Furnishings Co.,Ltd',
    name: 'Henglin Home Furnishings Co.,Ltd',
    symbol: '603661.SS',
  },
  {
    value: '603662.SS',
    label: '603662.SS,  Keli Sensing Technology (Ningbo) Co.,Ltd.',
    name: 'Keli Sensing Technology (Ningbo) Co.,Ltd.',
    symbol: '603662.SS',
  },
  {
    value: '603663.SS',
    label: '603663.SS,  Sanxiang Advanced Materials Co., Ltd.',
    name: 'Sanxiang Advanced Materials Co., Ltd.',
    symbol: '603663.SS',
  },
  {
    value: '603665.SS',
    label:
      '603665.SS,  ZheJiang KangLongDa Special Protection Technology Co., Ltd',
    name: 'ZheJiang KangLongDa Special Protection Technology Co., Ltd',
    symbol: '603665.SS',
  },
  {
    value: '603666.SS',
    label: '603666.SS,  Yijiahe Technology Co., Ltd.',
    name: 'Yijiahe Technology Co., Ltd.',
    symbol: '603666.SS',
  },
  {
    value: '603667.SS',
    label: '603667.SS,  Zhejiang XCC Group Co.,Ltd',
    name: 'Zhejiang XCC Group Co.,Ltd',
    symbol: '603667.SS',
  },
  {
    value: '603668.SS',
    label: '603668.SS,  Fujian Tianma Science and Technology Group Co., Ltd',
    name: 'Fujian Tianma Science and Technology Group Co., Ltd',
    symbol: '603668.SS',
  },
  {
    value: '603669.SS',
    label: '603669.SS,  Lionco Pharmaceutical Group Co.,Ltd.',
    name: 'Lionco Pharmaceutical Group Co.,Ltd.',
    symbol: '603669.SS',
  },
  {
    value: '603676.SS',
    label: '603676.SS,  Tibet Weixinkang Medicine Co., Ltd.',
    name: 'Tibet Weixinkang Medicine Co., Ltd.',
    symbol: '603676.SS',
  },
  {
    value: '603677.SS',
    label: '603677.SS,  Qijing Machinery Co., Ltd.',
    name: 'Qijing Machinery Co., Ltd.',
    symbol: '603677.SS',
  },
  {
    value: '603678.SS',
    label: '603678.SS,  Fujian Torch Electron Technology Co., Ltd.',
    name: 'Fujian Torch Electron Technology Co., Ltd.',
    symbol: '603678.SS',
  },
  {
    value: '603679.SS',
    label: '603679.SS,  Sichuan Huati Lighting Technology Co.,Ltd.',
    name: 'Sichuan Huati Lighting Technology Co.,Ltd.',
    symbol: '603679.SS',
  },
  {
    value: '603680.SS',
    label: '603680.SS,  KTK Group Co., Ltd.',
    name: 'KTK Group Co., Ltd.',
    symbol: '603680.SS',
  },
  {
    value: '603681.SS',
    label: '603681.SS,  Shanghai Yongguan Adhesive Products Corp., Ltd.',
    name: 'Shanghai Yongguan Adhesive Products Corp., Ltd.',
    symbol: '603681.SS',
  },
  {
    value: '603682.SS',
    label: '603682.SS,  Shanghai Golden Union Business Management Co., Ltd.',
    name: 'Shanghai Golden Union Business Management Co., Ltd.',
    symbol: '603682.SS',
  },
  {
    value: '603683.SS',
    label: '603683.SS,  Shanghai Smith Adhesive New Material Co., Ltd',
    name: 'Shanghai Smith Adhesive New Material Co., Ltd',
    symbol: '603683.SS',
  },
  {
    value: '603685.SS',
    label: '603685.SS,  Zhejiang Chenfeng Technology Co., Ltd.',
    name: 'Zhejiang Chenfeng Technology Co., Ltd.',
    symbol: '603685.SS',
  },
  {
    value: '603686.SS',
    label: '603686.SS,  FULONGMA GROUP Co.,Ltd.',
    name: 'FULONGMA GROUP Co.,Ltd.',
    symbol: '603686.SS',
  },
  {
    value: '603687.SS',
    label: '603687.SS,  Zhejiang Great Shengda Packaging Co.,Ltd.',
    name: 'Zhejiang Great Shengda Packaging Co.,Ltd.',
    symbol: '603687.SS',
  },
  {
    value: '603688.SS',
    label: '603688.SS,  Jiangsu Pacific Quartz Co., Ltd',
    name: 'Jiangsu Pacific Quartz Co., Ltd',
    symbol: '603688.SS',
  },
  {
    value: '603689.SS',
    label: '603689.SS,  Anhui Province Natural Gas DevelopmentCo.,Ltd.',
    name: 'Anhui Province Natural Gas DevelopmentCo.,Ltd.',
    symbol: '603689.SS',
  },
  {
    value: '603690.SS',
    label: '603690.SS,  PNC Process Systems Co., Ltd.',
    name: 'PNC Process Systems Co., Ltd.',
    symbol: '603690.SS',
  },
  {
    value: '603693.SS',
    label: '603693.SS,  Jiangsu New Energy Development Co., Ltd.',
    name: 'Jiangsu New Energy Development Co., Ltd.',
    symbol: '603693.SS',
  },
  {
    value: '603696.SS',
    label: '603696.SS,  Anji Foodstuff Co., Ltd',
    name: 'Anji Foodstuff Co., Ltd',
    symbol: '603696.SS',
  },
  {
    value: '603697.SS',
    label: '603697.SS,  YouYou Foods Co., Ltd.',
    name: 'YouYou Foods Co., Ltd.',
    symbol: '603697.SS',
  },
  {
    value: '603698.SS',
    label: '603698.SS,  Changzheng Engineering Co.,LTD',
    name: 'Changzheng Engineering Co.,LTD',
    symbol: '603698.SS',
  },
  {
    value: '603699.SS',
    label: '603699.SS,  Neway Valve (Suzhou) Co., Ltd.',
    name: 'Neway Valve (Suzhou) Co., Ltd.',
    symbol: '603699.SS',
  },
  {
    value: '603700.SS',
    label: '603700.SS,  Ningbo Water Meter (Group) Co., LTD.',
    name: 'Ningbo Water Meter (Group) Co., LTD.',
    symbol: '603700.SS',
  },
  {
    value: '603701.SS',
    label:
      '603701.SS,  Zhejiang Dehong Automotive Electronic & Electrical Co., Ltd.',
    name: 'Zhejiang Dehong Automotive Electronic & Electrical Co., Ltd.',
    symbol: '603701.SS',
  },
  {
    value: '603703.SS',
    label: '603703.SS,  Zhejiang Shengyang Science and Technology Co., Ltd.',
    name: 'Zhejiang Shengyang Science and Technology Co., Ltd.',
    symbol: '603703.SS',
  },
  {
    value: '603706.SS',
    label: '603706.SS,  Xinjiang East Universe Gas Co.Ltd.',
    name: 'Xinjiang East Universe Gas Co.Ltd.',
    symbol: '603706.SS',
  },
  {
    value: '603708.SS',
    label: '603708.SS,  Jiajiayue Group Co., Ltd.',
    name: 'Jiajiayue Group Co., Ltd.',
    symbol: '603708.SS',
  },
  {
    value: '603709.SS',
    label: '603709.SS,  ZOY Home Furnishing Co.,Ltd',
    name: 'ZOY Home Furnishing Co.,Ltd',
    symbol: '603709.SS',
  },
  {
    value: '603711.SS',
    label: '603711.SS,  XIANGPIAOPIAO Food Co.,Ltd',
    name: 'XIANGPIAOPIAO Food Co.,Ltd',
    symbol: '603711.SS',
  },
  {
    value: '603713.SS',
    label: '603713.SS,  Milkyway Chemical Supply Chain Service Co., Ltd.',
    name: 'Milkyway Chemical Supply Chain Service Co., Ltd.',
    symbol: '603713.SS',
  },
  {
    value: '603716.SS',
    label: '603716.SS,  Thalys Medical Technology Group Inc.',
    name: 'Thalys Medical Technology Group Inc.',
    symbol: '603716.SS',
  },
  {
    value: '603717.SS',
    label: '603717.SS,  TianYu Eco-Environment Co., Ltd.',
    name: 'TianYu Eco-Environment Co., Ltd.',
    symbol: '603717.SS',
  },
  {
    value: '603718.SS',
    label: '603718.SS,  Shanghai Hile Bio-Technology Co., Ltd.',
    name: 'Shanghai Hile Bio-Technology Co., Ltd.',
    symbol: '603718.SS',
  },
  {
    value: '603719.SS',
    label: '603719.SS,  Bestore Co.,Ltd',
    name: 'Bestore Co.,Ltd',
    symbol: '603719.SS',
  },
  {
    value: '603721.SS',
    label: '603721.SS,  TVZone Media Co., Ltd.',
    name: 'TVZone Media Co., Ltd.',
    symbol: '603721.SS',
  },
  {
    value: '603722.SS',
    label: '603722.SS,  Wuxi Acryl Technology Co., Ltd.',
    name: 'Wuxi Acryl Technology Co., Ltd.',
    symbol: '603722.SS',
  },
  {
    value: '603725.SS',
    label: '603725.SS,  Guangdong Tianan New Material Co., Ltd.',
    name: 'Guangdong Tianan New Material Co., Ltd.',
    symbol: '603725.SS',
  },
  {
    value: '603726.SS',
    label: '603726.SS,  Zhejiang Langdi Group Co., Ltd.',
    name: 'Zhejiang Langdi Group Co., Ltd.',
    symbol: '603726.SS',
  },
  {
    value: '603727.SS',
    label: '603727.SS,  BOMESC Offshore Engineering Company Limited',
    name: 'BOMESC Offshore Engineering Company Limited',
    symbol: '603727.SS',
  },
  {
    value: '603728.SS',
    label: "603728.SS,  Shanghai MOONS' Electric Co.,Ltd.",
    name: "Shanghai MOONS' Electric Co.,Ltd.",
    symbol: '603728.SS',
  },
  {
    value: '603729.SS',
    label: '603729.SS,  Shanghai LongYun Media Group Co., Ltd.',
    name: 'Shanghai LongYun Media Group Co., Ltd.',
    symbol: '603729.SS',
  },
  {
    value: '603730.SS',
    label: '603730.SS,  Shanghai Daimay Automotive Interior Co., Ltd',
    name: 'Shanghai Daimay Automotive Interior Co., Ltd',
    symbol: '603730.SS',
  },
  {
    value: '603733.SS',
    label: '603733.SS,  Xianhe Co.,Ltd.',
    name: 'Xianhe Co.,Ltd.',
    symbol: '603733.SS',
  },
  {
    value: '603738.SS',
    label: '603738.SS,  TKD Science and Technology Co.,Ltd.',
    name: 'TKD Science and Technology Co.,Ltd.',
    symbol: '603738.SS',
  },
  {
    value: '603739.SS',
    label: '603739.SS,  Qingdao Vland Biotech INC.',
    name: 'Qingdao Vland Biotech INC.',
    symbol: '603739.SS',
  },
  {
    value: '603755.SS',
    label: '603755.SS,  Qingdao Richen Foods Co., Ltd.',
    name: 'Qingdao Richen Foods Co., Ltd.',
    symbol: '603755.SS',
  },
  {
    value: '603757.SS',
    label: '603757.SS,  Zhejiang Dayuan Pumps Industry Co., Ltd',
    name: 'Zhejiang Dayuan Pumps Industry Co., Ltd',
    symbol: '603757.SS',
  },
  {
    value: '603758.SS',
    label: "603758.SS,  Chongqing Qin'an M&E PLC.",
    name: "Chongqing Qin'an M&E PLC.",
    symbol: '603758.SS',
  },
  {
    value: '603766.SS',
    label: '603766.SS,  Loncin Motor Co., Ltd.',
    name: 'Loncin Motor Co., Ltd.',
    symbol: '603766.SS',
  },
  {
    value: '603767.SS',
    label: '603767.SS,  Zhejiang Zomax Transmission Co., Ltd.',
    name: 'Zhejiang Zomax Transmission Co., Ltd.',
    symbol: '603767.SS',
  },
  {
    value: '603768.SS',
    label: '603768.SS,  Changqing Machinery Company Limited',
    name: 'Changqing Machinery Company Limited',
    symbol: '603768.SS',
  },
  {
    value: '603773.SS',
    label: '603773.SS,  WG TECH (Jiang Xi) Co., Ltd.',
    name: 'WG TECH (Jiang Xi) Co., Ltd.',
    symbol: '603773.SS',
  },
  {
    value: '603776.SS',
    label: '603776.SS,  Youon Technology Co.,Ltd',
    name: 'Youon Technology Co.,Ltd',
    symbol: '603776.SS',
  },
  {
    value: '603777.SS',
    label: '603777.SS,  Shanghai Laiyifen Co.,Ltd',
    name: 'Shanghai Laiyifen Co.,Ltd',
    symbol: '603777.SS',
  },
  {
    value: '603778.SS',
    label: '603778.SS,  Beijing Qianjing Landscape Co.,Ltd',
    name: 'Beijing Qianjing Landscape Co.,Ltd',
    symbol: '603778.SS',
  },
  {
    value: '603779.SS',
    label: '603779.SS,  Wei Long Grape Wine Co., Ltd.',
    name: 'Wei Long Grape Wine Co., Ltd.',
    symbol: '603779.SS',
  },
  {
    value: '603786.SS',
    label: '603786.SS,  KEBODA TECHNOLOGY Co., Ltd.',
    name: 'KEBODA TECHNOLOGY Co., Ltd.',
    symbol: '603786.SS',
  },
  {
    value: '603787.SS',
    label: '603787.SS,  Jiangsu Xinri E-Vehicle Co., Ltd.',
    name: 'Jiangsu Xinri E-Vehicle Co., Ltd.',
    symbol: '603787.SS',
  },
  {
    value: '603788.SS',
    label: '603788.SS,  Ningbo Gaofa Automotive Control System Co., Ltd.',
    name: 'Ningbo Gaofa Automotive Control System Co., Ltd.',
    symbol: '603788.SS',
  },
  {
    value: '603789.SS',
    label: '603789.SS,  Thinker Agricultural Machinery Co., Ltd.',
    name: 'Thinker Agricultural Machinery Co., Ltd.',
    symbol: '603789.SS',
  },
  {
    value: '603790.SS',
    label: '603790.SS,  Argus (Shanghai) Textile Chemicals Co.,Ltd.',
    name: 'Argus (Shanghai) Textile Chemicals Co.,Ltd.',
    symbol: '603790.SS',
  },
  {
    value: '603797.SS',
    label: '603797.SS,  Guangdong Liantai Environmental Protection Co.,Ltd.',
    name: 'Guangdong Liantai Environmental Protection Co.,Ltd.',
    symbol: '603797.SS',
  },
  {
    value: '603798.SS',
    label: '603798.SS,  Qingdao Copton Technology Company Limited',
    name: 'Qingdao Copton Technology Company Limited',
    symbol: '603798.SS',
  },
  {
    value: '603800.SS',
    label: '603800.SS,  Suzhou Douson Drilling & Production Equipment Co.,Ltd.',
    name: 'Suzhou Douson Drilling & Production Equipment Co.,Ltd.',
    symbol: '603800.SS',
  },
  {
    value: '603801.SS',
    label: '603801.SS,  Zbom Home Collection Co.,Ltd',
    name: 'Zbom Home Collection Co.,Ltd',
    symbol: '603801.SS',
  },
  {
    value: '603803.SS',
    label: '603803.SS,  RAISECOM TECHNOLOGY CO.,Ltd.',
    name: 'RAISECOM TECHNOLOGY CO.,Ltd.',
    symbol: '603803.SS',
  },
  {
    value: '603808.SS',
    label: '603808.SS,  Shenzhen Ellassay Fashion Co.,Ltd.',
    name: 'Shenzhen Ellassay Fashion Co.,Ltd.',
    symbol: '603808.SS',
  },
  {
    value: '603809.SS',
    label: '603809.SS,  Chengdu Haoneng Technology Co., Ltd.',
    name: 'Chengdu Haoneng Technology Co., Ltd.',
    symbol: '603809.SS',
  },
  {
    value: '603810.SS',
    label: '603810.SS,  Jiangsu Fengshan Group Co.,Ltd',
    name: 'Jiangsu Fengshan Group Co.,Ltd',
    symbol: '603810.SS',
  },
  {
    value: '603811.SS',
    label: '603811.SS,  Zhejiang Cheng Yi Pharmaceutical Co., Ltd.',
    name: 'Zhejiang Cheng Yi Pharmaceutical Co., Ltd.',
    symbol: '603811.SS',
  },
  {
    value: '603813.SS',
    label: '603813.SS,  GuangDong GenSho Logistics Co.,LTD',
    name: 'GuangDong GenSho Logistics Co.,LTD',
    symbol: '603813.SS',
  },
  {
    value: '603815.SS',
    label: '603815.SS,  Anhui Gourgen Traffic Construction Co.,Ltd.',
    name: 'Anhui Gourgen Traffic Construction Co.,Ltd.',
    symbol: '603815.SS',
  },
  {
    value: '603817.SS',
    label: '603817.SS,  Fujian Haixia Environmental Protection Group Co.,Ltd.',
    name: 'Fujian Haixia Environmental Protection Group Co.,Ltd.',
    symbol: '603817.SS',
  },
  {
    value: '603818.SS',
    label: '603818.SS,  Qu Mei Home Furnishings Group Co.,Ltd',
    name: 'Qu Mei Home Furnishings Group Co.,Ltd',
    symbol: '603818.SS',
  },
  {
    value: '603819.SS',
    label:
      '603819.SS,  Changzhou Shenli Electrical Machine Incorporated Company',
    name: 'Changzhou Shenli Electrical Machine Incorporated Company',
    symbol: '603819.SS',
  },
  {
    value: '603822.SS',
    label: '603822.SS,  Zhejiang Jiaao Enprotech Stock Co., Ltd',
    name: 'Zhejiang Jiaao Enprotech Stock Co., Ltd',
    symbol: '603822.SS',
  },
  {
    value: '603823.SS',
    label: '603823.SS,  Lily Group Co., Ltd.',
    name: 'Lily Group Co., Ltd.',
    symbol: '603823.SS',
  },
  {
    value: '603825.SS',
    label: '603825.SS,  Hylink Digital Solution Co., Ltd.',
    name: 'Hylink Digital Solution Co., Ltd.',
    symbol: '603825.SS',
  },
  {
    value: '603826.SS',
    label: '603826.SS,  Fujian Kuncai Material Technology Co., Ltd.',
    name: 'Fujian Kuncai Material Technology Co., Ltd.',
    symbol: '603826.SS',
  },
  {
    value: '603828.SS',
    label: '603828.SS,  Suzhou Kelida Building& Decoration Co.,Ltd.',
    name: 'Suzhou Kelida Building& Decoration Co.,Ltd.',
    symbol: '603828.SS',
  },
  {
    value: '603829.SS',
    label: '603829.SS,  Jiangsu Luokai Mechanical &Electrical Co., Ltd .',
    name: 'Jiangsu Luokai Mechanical &Electrical Co., Ltd .',
    symbol: '603829.SS',
  },
  {
    value: '603838.SS',
    label: '603838.SS,  Guang Dong Sitong Group Co.,Ltd',
    name: 'Guang Dong Sitong Group Co.,Ltd',
    symbol: '603838.SS',
  },
  {
    value: '603839.SS',
    label: '603839.SS,  Anzheng Fashion Group Co., Ltd.',
    name: 'Anzheng Fashion Group Co., Ltd.',
    symbol: '603839.SS',
  },
  {
    value: '603843.SS',
    label: '603843.SS,  Zhengping Road & Bridge Construction Co.,Ltd.',
    name: 'Zhengping Road & Bridge Construction Co.,Ltd.',
    symbol: '603843.SS',
  },
  {
    value: '603848.SS',
    label: '603848.SS,  Guangdong Hotata Technology Group Co.,Ltd.',
    name: 'Guangdong Hotata Technology Group Co.,Ltd.',
    symbol: '603848.SS',
  },
  {
    value: '603855.SS',
    label: '603855.SS,  Warom Technology Incorporated Company',
    name: 'Warom Technology Incorporated Company',
    symbol: '603855.SS',
  },
  {
    value: '603856.SS',
    label: '603856.SS,  Shandong Donghong Pipe Industry Co., Ltd.',
    name: 'Shandong Donghong Pipe Industry Co., Ltd.',
    symbol: '603856.SS',
  },
  {
    value: '603859.SS',
    label: '603859.SS,  Nancal Technology Co.,Ltd',
    name: 'Nancal Technology Co.,Ltd',
    symbol: '603859.SS',
  },
  {
    value: '603860.SS',
    label: '603860.SS,  RoadMainT Co.,Ltd.',
    name: 'RoadMainT Co.,Ltd.',
    symbol: '603860.SS',
  },
  {
    value: '603861.SS',
    label: '603861.SS,  Guangzhou Baiyun Electric Equipment Co., Ltd.',
    name: 'Guangzhou Baiyun Electric Equipment Co., Ltd.',
    symbol: '603861.SS',
  },
  {
    value: '603863.SS',
    label: '603863.SS,  GuangDong SongYang Recycle Resources CO.,LTD',
    name: 'GuangDong SongYang Recycle Resources CO.,LTD',
    symbol: '603863.SS',
  },
  {
    value: '603867.SS',
    label: '603867.SS,  Zhejiang Xinhua Chemical Co.,Ltd',
    name: 'Zhejiang Xinhua Chemical Co.,Ltd',
    symbol: '603867.SS',
  },
  {
    value: '603869.SS',
    label: '603869.SS,  ENC Digital Technology Co., Ltd',
    name: 'ENC Digital Technology Co., Ltd',
    symbol: '603869.SS',
  },
  {
    value: '603871.SS',
    label: '603871.SS,  Jiayou International Logistics Co.,Ltd',
    name: 'Jiayou International Logistics Co.,Ltd',
    symbol: '603871.SS',
  },
  {
    value: '603876.SS',
    label: '603876.SS,  Jiangsu Dingsheng New Material Joint-Stock Co.,Ltd',
    name: 'Jiangsu Dingsheng New Material Joint-Stock Co.,Ltd',
    symbol: '603876.SS',
  },
  {
    value: '603877.SS',
    label: '603877.SS,  Ningbo Peacebird Fashion Co.,Ltd.',
    name: 'Ningbo Peacebird Fashion Co.,Ltd.',
    symbol: '603877.SS',
  },
  {
    value: '603878.SS',
    label: '603878.SS,  Jiangsu Wujin Stainless Steel Pipe Group CO.,LTD.',
    name: 'Jiangsu Wujin Stainless Steel Pipe Group CO.,LTD.',
    symbol: '603878.SS',
  },
  {
    value: '603879.SS',
    label: '603879.SS,  Yongyue Science&Technology Co.,Ltd',
    name: 'Yongyue Science&Technology Co.,Ltd',
    symbol: '603879.SS',
  },
  {
    value: '603880.SS',
    label: '603880.SS,  Jiangsu Nanfang Medical Co., Ltd.',
    name: 'Jiangsu Nanfang Medical Co., Ltd.',
    symbol: '603880.SS',
  },
  {
    value: '603881.SS',
    label: '603881.SS,  Shanghai AtHub Co.,Ltd.',
    name: 'Shanghai AtHub Co.,Ltd.',
    symbol: '603881.SS',
  },
  {
    value: '603885.SS',
    label: '603885.SS,  Juneyao Airlines Co., Ltd',
    name: 'Juneyao Airlines Co., Ltd',
    symbol: '603885.SS',
  },
  {
    value: '603886.SS',
    label: '603886.SS,  Ganso Co., Ltd.',
    name: 'Ganso Co., Ltd.',
    symbol: '603886.SS',
  },
  {
    value: '603887.SS',
    label: '603887.SS,  Shanghai CDXJ Digital Technology Co., Ltd.',
    name: 'Shanghai CDXJ Digital Technology Co., Ltd.',
    symbol: '603887.SS',
  },
  {
    value: '603888.SS',
    label: '603888.SS,  Xinhuanet Co., Ltd.',
    name: 'Xinhuanet Co., Ltd.',
    symbol: '603888.SS',
  },
  {
    value: '603889.SS',
    label: '603889.SS,  Zhejiang Xinao Textiles Inc.',
    name: 'Zhejiang Xinao Textiles Inc.',
    symbol: '603889.SS',
  },
  {
    value: '603890.SS',
    label: '603890.SS,  Suzhou Chunqiu Electronic Technology Co., Ltd.',
    name: 'Suzhou Chunqiu Electronic Technology Co., Ltd.',
    symbol: '603890.SS',
  },
  {
    value: '603893.SS',
    label: '603893.SS,  Rockchip Electronics Co., Ltd.',
    name: 'Rockchip Electronics Co., Ltd.',
    symbol: '603893.SS',
  },
  {
    value: '603895.SS',
    label: '603895.SS,  Shanghai Tianyong Engineering Co., Ltd.',
    name: 'Shanghai Tianyong Engineering Co., Ltd.',
    symbol: '603895.SS',
  },
  {
    value: '603896.SS',
    label: '603896.SS,  Zhejiang Shouxiangu Pharmaceutical Co., Ltd.',
    name: 'Zhejiang Shouxiangu Pharmaceutical Co., Ltd.',
    symbol: '603896.SS',
  },
  {
    value: '603897.SS',
    label:
      '603897.SS,  Zhejiang grandwall electric science&technology co.,ltd.',
    name: 'Zhejiang grandwall electric science&technology co.,ltd.',
    symbol: '603897.SS',
  },
  {
    value: '603898.SS',
    label: '603898.SS,  Guangzhou Holike Creative Home Co.,Ltd.',
    name: 'Guangzhou Holike Creative Home Co.,Ltd.',
    symbol: '603898.SS',
  },
  {
    value: '603900.SS',
    label: '603900.SS,  Leysen Jewellery Inc.',
    name: 'Leysen Jewellery Inc.',
    symbol: '603900.SS',
  },
  {
    value: '603901.SS',
    label: '603901.SS,  Hangzhou Youngsun Intelligent Equipment Co., Ltd.',
    name: 'Hangzhou Youngsun Intelligent Equipment Co., Ltd.',
    symbol: '603901.SS',
  },
  {
    value: '603903.SS',
    label: '603903.SS,  CSD Water Service Co., Ltd.',
    name: 'CSD Water Service Co., Ltd.',
    symbol: '603903.SS',
  },
  {
    value: '603906.SS',
    label: '603906.SS,  Jiangsu Lopal Tech. Co., Ltd.',
    name: 'Jiangsu Lopal Tech. Co., Ltd.',
    symbol: '603906.SS',
  },
  {
    value: '603908.SS',
    label: '603908.SS,  Comefly Outdoor Co., Ltd.',
    name: 'Comefly Outdoor Co., Ltd.',
    symbol: '603908.SS',
  },
  {
    value: '603909.SS',
    label: '603909.SS,  C&D Holsin Engineering Consulting Co., Ltd.',
    name: 'C&D Holsin Engineering Consulting Co., Ltd.',
    symbol: '603909.SS',
  },
  {
    value: '603912.SS',
    label:
      '603912.SS,  Nanjing Canatal Data-Centre Environmental Tech Co., Ltd',
    name: 'Nanjing Canatal Data-Centre Environmental Tech Co., Ltd',
    symbol: '603912.SS',
  },
  {
    value: '603915.SS',
    label: '603915.SS,  Jiangsu Guomao Reducer Co., Ltd.',
    name: 'Jiangsu Guomao Reducer Co., Ltd.',
    symbol: '603915.SS',
  },
  {
    value: '603916.SS',
    label: '603916.SS,  Sobute New Materials Co., Ltd',
    name: 'Sobute New Materials Co., Ltd',
    symbol: '603916.SS',
  },
  {
    value: '603917.SS',
    label: '603917.SS,  Ningbo Heli Technology Co., Ltd.',
    name: 'Ningbo Heli Technology Co., Ltd.',
    symbol: '603917.SS',
  },
  {
    value: '603918.SS',
    label: '603918.SS,  Shanghai Golden Bridge Info Tech Co.,Ltd',
    name: 'Shanghai Golden Bridge Info Tech Co.,Ltd',
    symbol: '603918.SS',
  },
  {
    value: '603919.SS',
    label: '603919.SS,  JINHUI LIQUOR Co.,Ltd.',
    name: 'JINHUI LIQUOR Co.,Ltd.',
    symbol: '603919.SS',
  },
  {
    value: '603920.SS',
    label: '603920.SS,  Olympic Circuit Technology Co., Ltd',
    name: 'Olympic Circuit Technology Co., Ltd',
    symbol: '603920.SS',
  },
  {
    value: '603922.SS',
    label: '603922.SS,  Suzhou Jin Hong Shun Auto Parts Co., Ltd.',
    name: 'Suzhou Jin Hong Shun Auto Parts Co., Ltd.',
    symbol: '603922.SS',
  },
  {
    value: '603926.SS',
    label: '603926.SS,  Zhejiang Tieliu Clutch Co., Ltd.',
    name: 'Zhejiang Tieliu Clutch Co., Ltd.',
    symbol: '603926.SS',
  },
  {
    value: '603928.SS',
    label: '603928.SS,  Suzhou Xingye Materials Technology Co.,Ltd.',
    name: 'Suzhou Xingye Materials Technology Co.,Ltd.',
    symbol: '603928.SS',
  },
  {
    value: '603929.SS',
    label: '603929.SS,  L&K Engineering (Suzhou) Co.,Ltd.',
    name: 'L&K Engineering (Suzhou) Co.,Ltd.',
    symbol: '603929.SS',
  },
  {
    value: '603931.SS',
    label: '603931.SS,  Hangzhou Greenda Electronic Materials Co., Ltd.',
    name: 'Hangzhou Greenda Electronic Materials Co., Ltd.',
    symbol: '603931.SS',
  },
  {
    value: '603933.SS',
    label: '603933.SS,  Fujian Raynen Technology Co., Ltd.',
    name: 'Fujian Raynen Technology Co., Ltd.',
    symbol: '603933.SS',
  },
  {
    value: '603936.SS',
    label: '603936.SS,  Bomin Electronics Co., Ltd.',
    name: 'Bomin Electronics Co., Ltd.',
    symbol: '603936.SS',
  },
  {
    value: '603937.SS',
    label: '603937.SS,  Jiangsu Lidao New Materials Co., Ltd.',
    name: 'Jiangsu Lidao New Materials Co., Ltd.',
    symbol: '603937.SS',
  },
  {
    value: '603938.SS',
    label: '603938.SS,  Tangshan Sunfar Silicon Industries Co.,Ltd.',
    name: 'Tangshan Sunfar Silicon Industries Co.,Ltd.',
    symbol: '603938.SS',
  },
  {
    value: '603948.SS',
    label: '603948.SS,  Zhejiang Jianye Chemical Co., Ltd.',
    name: 'Zhejiang Jianye Chemical Co., Ltd.',
    symbol: '603948.SS',
  },
  {
    value: '603950.SS',
    label: '603950.SS,  Xiangyang Changyuandonggu Industry Co., Ltd.',
    name: 'Xiangyang Changyuandonggu Industry Co., Ltd.',
    symbol: '603950.SS',
  },
  {
    value: '603955.SS',
    label: '603955.SS,  Daqian Ecology&Environment Group Co.,Ltd.',
    name: 'Daqian Ecology&Environment Group Co.,Ltd.',
    symbol: '603955.SS',
  },
  {
    value: '603956.SS',
    label: '603956.SS,  WPG (Shanghai) Smart Water Public Co., Ltd.',
    name: 'WPG (Shanghai) Smart Water Public Co., Ltd.',
    symbol: '603956.SS',
  },
  {
    value: '603958.SS',
    label: '603958.SS,  Harson Trading (China) Co.,Ltd.',
    name: 'Harson Trading (China) Co.,Ltd.',
    symbol: '603958.SS',
  },
  {
    value: '603959.SS',
    label: '603959.SS,  Hunan Baili Engineering Sci&Tech Co.,Ltd',
    name: 'Hunan Baili Engineering Sci&Tech Co.,Ltd',
    symbol: '603959.SS',
  },
  {
    value: '603960.SS',
    label: '603960.SS,  Shanghai Kelai Mechatronics Engineering Co.,Ltd.',
    name: 'Shanghai Kelai Mechatronics Engineering Co.,Ltd.',
    symbol: '603960.SS',
  },
  {
    value: '603963.SS',
    label: '603963.SS,  Dali Pharmaceuticalco.,Ltd',
    name: 'Dali Pharmaceuticalco.,Ltd',
    symbol: '603963.SS',
  },
  {
    value: '603966.SS',
    label: '603966.SS,  Eurocrane (China) Co., Ltd.',
    name: 'Eurocrane (China) Co., Ltd.',
    symbol: '603966.SS',
  },
  {
    value: '603967.SS',
    label: '603967.SS,  China Master Logistics Co., Ltd.',
    name: 'China Master Logistics Co., Ltd.',
    symbol: '603967.SS',
  },
  {
    value: '603968.SS',
    label: '603968.SS,  Nantong Acetic Acid Chemical Co., Ltd.',
    name: 'Nantong Acetic Acid Chemical Co., Ltd.',
    symbol: '603968.SS',
  },
  {
    value: '603969.SS',
    label: '603969.SS,  SILVERY DRAGON PRESTRESSED MATERIALS CO., LTD. TIANJIN',
    name: 'SILVERY DRAGON PRESTRESSED MATERIALS CO., LTD. TIANJIN',
    symbol: '603969.SS',
  },
  {
    value: '603970.SS',
    label: '603970.SS,  Sino-Agri Leading Biosciences Co.,Ltd',
    name: 'Sino-Agri Leading Biosciences Co.,Ltd',
    symbol: '603970.SS',
  },
  {
    value: '603976.SS',
    label: '603976.SS,  ChongQing Zhengchuan Pharmaceutical Packaging Co.,Ltd.',
    name: 'ChongQing Zhengchuan Pharmaceutical Packaging Co.,Ltd.',
    symbol: '603976.SS',
  },
  {
    value: '603977.SS',
    label: '603977.SS,  Jiangxi Guotai Group Co.,Ltd.',
    name: 'Jiangxi Guotai Group Co.,Ltd.',
    symbol: '603977.SS',
  },
  {
    value: '603978.SS',
    label: '603978.SS,  Shenzhen SunXing Light Alloys Materials Co.,Ltd.',
    name: 'Shenzhen SunXing Light Alloys Materials Co.,Ltd.',
    symbol: '603978.SS',
  },
  {
    value: '603979.SS',
    label: '603979.SS,  JCHX Mining Management Co.,Ltd.',
    name: 'JCHX Mining Management Co.,Ltd.',
    symbol: '603979.SS',
  },
  {
    value: '603980.SS',
    label: '603980.SS,  Zhejiang Jihua Group Co., Ltd.',
    name: 'Zhejiang Jihua Group Co., Ltd.',
    symbol: '603980.SS',
  },
  {
    value: '603982.SS',
    label: '603982.SS,  Nanjing Chervon Auto Precision Technology Co., Ltd',
    name: 'Nanjing Chervon Auto Precision Technology Co., Ltd',
    symbol: '603982.SS',
  },
  {
    value: '603983.SS',
    label: '603983.SS,  Guangdong Marubi Biotechnology Co., Ltd.',
    name: 'Guangdong Marubi Biotechnology Co., Ltd.',
    symbol: '603983.SS',
  },
  {
    value: '603985.SS',
    label: '603985.SS,  Jiangyin Hengrun Heavy Industries Co., Ltd',
    name: 'Jiangyin Hengrun Heavy Industries Co., Ltd',
    symbol: '603985.SS',
  },
  {
    value: '603987.SS',
    label: '603987.SS,  Shanghai Kindly Enterprise Development Group Co.,LTD.',
    name: 'Shanghai Kindly Enterprise Development Group Co.,LTD.',
    symbol: '603987.SS',
  },
  {
    value: '603988.SS',
    label: '603988.SS,  SEC Electric Machinery Co., Ltd.',
    name: 'SEC Electric Machinery Co., Ltd.',
    symbol: '603988.SS',
  },
  {
    value: '603989.SS',
    label: '603989.SS,  Hunan Aihua Group Co., Ltd',
    name: 'Hunan Aihua Group Co., Ltd',
    symbol: '603989.SS',
  },
  {
    value: '603990.SS',
    label: '603990.SS,  Suzhou MedicalSystem Technology Co., Ltd.',
    name: 'Suzhou MedicalSystem Technology Co., Ltd.',
    symbol: '603990.SS',
  },
  {
    value: '603991.SS',
    label: '603991.SS,  Shenzhen Original Advanced Compounds Co., Ltd.',
    name: 'Shenzhen Original Advanced Compounds Co., Ltd.',
    symbol: '603991.SS',
  },
  {
    value: '603992.SS',
    label: '603992.SS,  Xiamen Solex High-tech Industries Co., Ltd.',
    name: 'Xiamen Solex High-tech Industries Co., Ltd.',
    symbol: '603992.SS',
  },
  {
    value: '603997.SS',
    label: '603997.SS,  Ningbo Jifeng Auto Parts Co., Ltd.',
    name: 'Ningbo Jifeng Auto Parts Co., Ltd.',
    symbol: '603997.SS',
  },
  {
    value: '603998.SS',
    label: '603998.SS,  Hunan Fangsheng Pharmaceutical Co., Ltd.',
    name: 'Hunan Fangsheng Pharmaceutical Co., Ltd.',
    symbol: '603998.SS',
  },
  {
    value: '603999.SS',
    label: '603999.SS,  DuZhe Publish&Media Co.,Ltd',
    name: 'DuZhe Publish&Media Co.,Ltd',
    symbol: '603999.SS',
  },
  {
    value: '605001.SS',
    label: '605001.SS,  Qingdao Victall Railway Co., Ltd.',
    name: 'Qingdao Victall Railway Co., Ltd.',
    symbol: '605001.SS',
  },
  {
    value: '605005.SS',
    label: '605005.SS,  CWB Automotive Electronics Co., Ltd.',
    name: 'CWB Automotive Electronics Co., Ltd.',
    symbol: '605005.SS',
  },
  {
    value: '605006.SS',
    label: '605006.SS,  Shandong Fiberglass Group Co., Ltd',
    name: 'Shandong Fiberglass Group Co., Ltd',
    symbol: '605006.SS',
  },
  {
    value: '605008.SS',
    label: '605008.SS,  Ningbo Changhong Polymer Scientific and Technical Inc.',
    name: 'Ningbo Changhong Polymer Scientific and Technical Inc.',
    symbol: '605008.SS',
  },
  {
    value: '605009.SS',
    label: '605009.SS,  Hangzhou Haoyue Personal Care Co., Ltd',
    name: 'Hangzhou Haoyue Personal Care Co., Ltd',
    symbol: '605009.SS',
  },
  {
    value: '605018.SS',
    label: '605018.SS,  Zhejiang Changhua Auto Parts Co., Ltd.',
    name: 'Zhejiang Changhua Auto Parts Co., Ltd.',
    symbol: '605018.SS',
  },
  {
    value: '605020.SS',
    label: '605020.SS,  Zhejiang Yonghe Refrigerant Co., Ltd.',
    name: 'Zhejiang Yonghe Refrigerant Co., Ltd.',
    symbol: '605020.SS',
  },
  {
    value: '605050.SS',
    label: '605050.SS,  Friend Co.,Ltd.',
    name: 'Friend Co.,Ltd.',
    symbol: '605050.SS',
  },
  {
    value: '605055.SS',
    label: '605055.SS,  Zhejiang Yingfeng Technology Co., Ltd.',
    name: 'Zhejiang Yingfeng Technology Co., Ltd.',
    symbol: '605055.SS',
  },
  {
    value: '605056.SS',
    label: '605056.SS,  Xianheng International Science&Technology Co., Ltd.',
    name: 'Xianheng International Science&Technology Co., Ltd.',
    symbol: '605056.SS',
  },
  {
    value: '605066.SS',
    label: '605066.SS,  Zhejiang Tengen Electrics Co.,Ltd.',
    name: 'Zhejiang Tengen Electrics Co.,Ltd.',
    symbol: '605066.SS',
  },
  {
    value: '605088.SS',
    label: '605088.SS,  GSP Automotive Group Wenzhou Co.,Ltd.',
    name: 'GSP Automotive Group Wenzhou Co.,Ltd.',
    symbol: '605088.SS',
  },
  {
    value: '605099.SS',
    label: '605099.SS,  CoCreation Grass Co., Ltd',
    name: 'CoCreation Grass Co., Ltd',
    symbol: '605099.SS',
  },
  {
    value: '605100.SS',
    label: '605100.SS,  Power HF Co., Ltd.',
    name: 'Power HF Co., Ltd.',
    symbol: '605100.SS',
  },
  {
    value: '605108.SS',
    label: '605108.SS,  Tongqinglou Catering Co., Ltd.',
    name: 'Tongqinglou Catering Co., Ltd.',
    symbol: '605108.SS',
  },
  {
    value: '605111.SS',
    label: '605111.SS,  Wuxi NCE Power Co., Ltd.',
    name: 'Wuxi NCE Power Co., Ltd.',
    symbol: '605111.SS',
  },
  {
    value: '605116.SS',
    label: '605116.SS,  Aurisco Pharmaceutical Co.,Ltd.',
    name: 'Aurisco Pharmaceutical Co.,Ltd.',
    symbol: '605116.SS',
  },
  {
    value: '605118.SS',
    label: '605118.SS,  Xiamen Leading Optics Co., Ltd.',
    name: 'Xiamen Leading Optics Co., Ltd.',
    symbol: '605118.SS',
  },
  {
    value: '605123.SS',
    label: '605123.SS,  Wuxi Paike New Materials Technology Co.,Ltd.',
    name: 'Wuxi Paike New Materials Technology Co.,Ltd.',
    symbol: '605123.SS',
  },
  {
    value: '605128.SS',
    label: '605128.SS,  Shanghai Yanpu Metal Products Co.,Ltd',
    name: 'Shanghai Yanpu Metal Products Co.,Ltd',
    symbol: '605128.SS',
  },
  {
    value: '605136.SS',
    label: '605136.SS,  Shanghai Lily&Beauty Cosmetics Co.,Ltd.',
    name: 'Shanghai Lily&Beauty Cosmetics Co.,Ltd.',
    symbol: '605136.SS',
  },
  {
    value: '605158.SS',
    label: '605158.SS,  Zhejiang Huada New Materials Co., Ltd.',
    name: 'Zhejiang Huada New Materials Co., Ltd.',
    symbol: '605158.SS',
  },
  {
    value: '605162.SS',
    label: '605162.SS,  Zhejiang Xinzhonggang Thermal Power Co., LTD.',
    name: 'Zhejiang Xinzhonggang Thermal Power Co., LTD.',
    symbol: '605162.SS',
  },
  {
    value: '605166.SS',
    label: '605166.SS,  Hangzhou Juheshun New Material Co., Ltd.',
    name: 'Hangzhou Juheshun New Material Co., Ltd.',
    symbol: '605166.SS',
  },
  {
    value: '605167.SS',
    label: '605167.SS,  Jiangsu Libert INC.',
    name: 'Jiangsu Libert INC.',
    symbol: '605167.SS',
  },
  {
    value: '605168.SS',
    label: "605168.SS,  Three's Company Media Group Co., Ltd.",
    name: "Three's Company Media Group Co., Ltd.",
    symbol: '605168.SS',
  },
  {
    value: '605178.SS',
    label: '605178.SS,  Beijing New Space Technology Co., Ltd.',
    name: 'Beijing New Space Technology Co., Ltd.',
    symbol: '605178.SS',
  },
  {
    value: '605188.SS',
    label: '605188.SS,  Jiangxi Guoguang Commercial Chains Co., Ltd.',
    name: 'Jiangxi Guoguang Commercial Chains Co., Ltd.',
    symbol: '605188.SS',
  },
  {
    value: '605198.SS',
    label: '605198.SS,  Yantai North Andre Juice Co.,Ltd.',
    name: 'Yantai North Andre Juice Co.,Ltd.',
    symbol: '605198.SS',
  },
  {
    value: '605199.SS',
    label: '605199.SS,  Hainan Huluwa Pharmaceutical Group Co., Ltd.',
    name: 'Hainan Huluwa Pharmaceutical Group Co., Ltd.',
    symbol: '605199.SS',
  },
  {
    value: '605218.SS',
    label: '605218.SS,  Ways Electron Co.,Ltd.',
    name: 'Ways Electron Co.,Ltd.',
    symbol: '605218.SS',
  },
  {
    value: '605222.SS',
    label: '605222.SS,  Shanghai QiFan Cable Co., Ltd',
    name: 'Shanghai QiFan Cable Co., Ltd',
    symbol: '605222.SS',
  },
  {
    value: '605228.SS',
    label: '605228.SS,  Shentong Technology Group Co., Ltd',
    name: 'Shentong Technology Group Co., Ltd',
    symbol: '605228.SS',
  },
  {
    value: '605255.SS',
    label: '605255.SS,  Ningbo TIP Rubber Technology Co., Ltd',
    name: 'Ningbo TIP Rubber Technology Co., Ltd',
    symbol: '605255.SS',
  },
  {
    value: '605266.SS',
    label: '605266.SS,  Jianzhijia Pharmaceutical Chain Group Co., Ltd.',
    name: 'Jianzhijia Pharmaceutical Chain Group Co., Ltd.',
    symbol: '605266.SS',
  },
  {
    value: '605277.SS',
    label: '605277.SS,  Xinya Electronic Co., Ltd.',
    name: 'Xinya Electronic Co., Ltd.',
    symbol: '605277.SS',
  },
  {
    value: '605287.SS',
    label: '605287.SS,  Decai Decoration CO.,LTD',
    name: 'Decai Decoration CO.,LTD',
    symbol: '605287.SS',
  },
  {
    value: '605288.SS',
    label: '605288.SS,  ChangZhou KAIDI Electrical Inc.',
    name: 'ChangZhou KAIDI Electrical Inc.',
    symbol: '605288.SS',
  },
  {
    value: '605318.SS',
    label:
      '605318.SS,  Fsilon Furnishing and Construction Materials Corporation',
    name: 'Fsilon Furnishing and Construction Materials Corporation',
    symbol: '605318.SS',
  },
  {
    value: '605333.SS',
    label: '605333.SS,  Kunshan Huguang Auto Harness Co.,Ltd.',
    name: 'Kunshan Huguang Auto Harness Co.,Ltd.',
    symbol: '605333.SS',
  },
  {
    value: '605358.SS',
    label: '605358.SS,  Hangzhou Lion Electronics Co.,Ltd',
    name: 'Hangzhou Lion Electronics Co.,Ltd',
    symbol: '605358.SS',
  },
  {
    value: '605365.SS',
    label: '605365.SS,  Leedarson IoT Technology Inc.',
    name: 'Leedarson IoT Technology Inc.',
    symbol: '605365.SS',
  },
  {
    value: '605366.SS',
    label: '605366.SS,  Jiangxi Hungpai New Material Co., Ltd.',
    name: 'Jiangxi Hungpai New Material Co., Ltd.',
    symbol: '605366.SS',
  },
  {
    value: '605368.SS',
    label: '605368.SS,  Henan Lantian Gas Co.,Ltd.',
    name: 'Henan Lantian Gas Co.,Ltd.',
    symbol: '605368.SS',
  },
  {
    value: '605369.SS',
    label: '605369.SS,  Zhejiang Gongdong Medical Technology Co.,Ltd.',
    name: 'Zhejiang Gongdong Medical Technology Co.,Ltd.',
    symbol: '605369.SS',
  },
  {
    value: '605388.SS',
    label: '605388.SS,  Hubei Juneyao Health Drinks Co., Ltd',
    name: 'Hubei Juneyao Health Drinks Co., Ltd',
    symbol: '605388.SS',
  },
  {
    value: '605398.SS',
    label:
      '605398.SS,  Shanghai New Centurion Network Information Technology Co., Ltd.',
    name: 'Shanghai New Centurion Network Information Technology Co., Ltd.',
    symbol: '605398.SS',
  },
  {
    value: '605399.SS',
    label: '605399.SS,  Jiang Xi Chenguang New Materials Co.,Ltd.',
    name: 'Jiang Xi Chenguang New Materials Co.,Ltd.',
    symbol: '605399.SS',
  },
  {
    value: '605507.SS',
    label: '605507.SS,  Guobang Pharma Ltd.',
    name: 'Guobang Pharma Ltd.',
    symbol: '605507.SS',
  },
  {
    value: '605577.SS',
    label: '605577.SS,  Heilongjiang Publishing & Media Co., Ltd.',
    name: 'Heilongjiang Publishing & Media Co., Ltd.',
    symbol: '605577.SS',
  },
  {
    value: '605580.SS',
    label: '605580.SS,  Hengsheng Energy Co., Ltd',
    name: 'Hengsheng Energy Co., Ltd',
    symbol: '605580.SS',
  },
  {
    value: '605589.SS',
    label: '605589.SS,  Jinan Shengquan Group Share Holding Co., Ltd.',
    name: 'Jinan Shengquan Group Share Holding Co., Ltd.',
    symbol: '605589.SS',
  },
  {
    value: '000004.SZ',
    label: '000004.SZ,  Shenzhen GuoHua Network Security Technology Co., Ltd.',
    name: 'Shenzhen GuoHua Network Security Technology Co., Ltd.',
    symbol: '000004.SZ',
  },
  {
    value: '000005.SZ',
    label: '000005.SZ,  Shenzhen Fountain Corporation',
    name: 'Shenzhen Fountain Corporation',
    symbol: '000005.SZ',
  },
  {
    value: '000006.SZ',
    label: '000006.SZ,  Shenzhen Zhenye (Group) Co.,Ltd.',
    name: 'Shenzhen Zhenye (Group) Co.,Ltd.',
    symbol: '000006.SZ',
  },
  {
    value: '000007.SZ',
    label: '000007.SZ,  Shenzhen Quanxinhao Co., Ltd.',
    name: 'Shenzhen Quanxinhao Co., Ltd.',
    symbol: '000007.SZ',
  },
  {
    value: '000008.SZ',
    label: '000008.SZ,  China High-Speed Railway Technology Co., Ltd.',
    name: 'China High-Speed Railway Technology Co., Ltd.',
    symbol: '000008.SZ',
  },
  {
    value: '000010.SZ',
    label: '000010.SZ,  Shenzhen Ecobeauty Co., Ltd.',
    name: 'Shenzhen Ecobeauty Co., Ltd.',
    symbol: '000010.SZ',
  },
  {
    value: '000011.SZ',
    label:
      '000011.SZ,  ShenZhen Properties & Resources Development (Group) Ltd.',
    name: 'ShenZhen Properties & Resources Development (Group) Ltd.',
    symbol: '000011.SZ',
  },
  {
    value: '000014.SZ',
    label: '000014.SZ,  Shahe Industrial Co., Ltd',
    name: 'Shahe Industrial Co., Ltd',
    symbol: '000014.SZ',
  },
  {
    value: '000016.SZ',
    label: '000016.SZ,  Konka Group Co., Ltd.',
    name: 'Konka Group Co., Ltd.',
    symbol: '000016.SZ',
  },
  {
    value: '000017.SZ',
    label: '000017.SZ,  Shenzhen China Bicycle Company (Holdings) Limited',
    name: 'Shenzhen China Bicycle Company (Holdings) Limited',
    symbol: '000017.SZ',
  },
  {
    value: '000019.SZ',
    label: '000019.SZ,  Shenzhen Cereals Holdings Co., Ltd.',
    name: 'Shenzhen Cereals Holdings Co., Ltd.',
    symbol: '000019.SZ',
  },
  {
    value: '000020.SZ',
    label: '000020.SZ,  Shenzhen Zhongheng Huafa Co., Ltd.',
    name: 'Shenzhen Zhongheng Huafa Co., Ltd.',
    symbol: '000020.SZ',
  },
  {
    value: '000023.SZ',
    label: '000023.SZ,  Shenzhen Universe Group Co., Ltd.',
    name: 'Shenzhen Universe Group Co., Ltd.',
    symbol: '000023.SZ',
  },
  {
    value: '000025.SZ',
    label: '000025.SZ,  Shenzhen Tellus Holding Co., Ltd.',
    name: 'Shenzhen Tellus Holding Co., Ltd.',
    symbol: '000025.SZ',
  },
  {
    value: '000026.SZ',
    label: '000026.SZ,  FIYTA Precision Technology Co., Ltd.',
    name: 'FIYTA Precision Technology Co., Ltd.',
    symbol: '000026.SZ',
  },
  {
    value: '000029.SZ',
    label:
      '000029.SZ,  Shenzhen Special Economic Zone Real Estate & Properties (Group) Co., Ltd.',
    name: 'Shenzhen Special Economic Zone Real Estate & Properties (Group) Co., Ltd.',
    symbol: '000029.SZ',
  },
  {
    value: '000032.SZ',
    label: '000032.SZ,  Shenzhen SED Industry Co., Ltd.',
    name: 'Shenzhen SED Industry Co., Ltd.',
    symbol: '000032.SZ',
  },
  {
    value: '000034.SZ',
    label: '000034.SZ,  Digital China Group Co., Ltd.',
    name: 'Digital China Group Co., Ltd.',
    symbol: '000034.SZ',
  },
  {
    value: '000035.SZ',
    label: '000035.SZ,  China Tianying Inc.',
    name: 'China Tianying Inc.',
    symbol: '000035.SZ',
  },
  {
    value: '000036.SZ',
    label: '000036.SZ,  China Union Holdings Ltd.',
    name: 'China Union Holdings Ltd.',
    symbol: '000036.SZ',
  },
  {
    value: '000037.SZ',
    label: '000037.SZ,  Shenzhen Nanshan Power Co., Ltd.',
    name: 'Shenzhen Nanshan Power Co., Ltd.',
    symbol: '000037.SZ',
  },
  {
    value: '000038.SZ',
    label: '000038.SZ,  Shenzhen Capstone Industrial Co.,Ltd.',
    name: 'Shenzhen Capstone Industrial Co.,Ltd.',
    symbol: '000038.SZ',
  },
  {
    value: '000040.SZ',
    label: '000040.SZ,  Tunghsu Azure Renewable Energy Co.,Ltd.',
    name: 'Tunghsu Azure Renewable Energy Co.,Ltd.',
    symbol: '000040.SZ',
  },
  {
    value: '000042.SZ',
    label: '000042.SZ,  Shenzhen Centralcon Investment Holding Co., Ltd.',
    name: 'Shenzhen Centralcon Investment Holding Co., Ltd.',
    symbol: '000042.SZ',
  },
  {
    value: '000045.SZ',
    label: '000045.SZ,  Shenzhen Textile (Holdings) Co., Ltd.',
    name: 'Shenzhen Textile (Holdings) Co., Ltd.',
    symbol: '000045.SZ',
  },
  {
    value: '000048.SZ',
    label: '000048.SZ,  Shenzhen Kingkey Smart Agriculture Times Co.,Ltd',
    name: 'Shenzhen Kingkey Smart Agriculture Times Co.,Ltd',
    symbol: '000048.SZ',
  },
  {
    value: '000049.SZ',
    label: '000049.SZ,  Shenzhen Desay Battery Technology Co., Ltd.',
    name: 'Shenzhen Desay Battery Technology Co., Ltd.',
    symbol: '000049.SZ',
  },
  {
    value: '000055.SZ',
    label: '000055.SZ,  China Fangda Group Co., Ltd.',
    name: 'China Fangda Group Co., Ltd.',
    symbol: '000055.SZ',
  },
  {
    value: '000056.SZ',
    label: '000056.SZ,  Shenzhen Wongtee International Enterprise Co., Ltd.',
    name: 'Shenzhen Wongtee International Enterprise Co., Ltd.',
    symbol: '000056.SZ',
  },
  {
    value: '000058.SZ',
    label: '000058.SZ,  Shenzhen SEG Co.,Ltd',
    name: 'Shenzhen SEG Co.,Ltd',
    symbol: '000058.SZ',
  },
  {
    value: '000059.SZ',
    label: '000059.SZ,  North Huajin Chemical Industries Co.,Ltd',
    name: 'North Huajin Chemical Industries Co.,Ltd',
    symbol: '000059.SZ',
  },
  {
    value: '000065.SZ',
    label: '000065.SZ,  Norinco International Cooperation Ltd.',
    name: 'Norinco International Cooperation Ltd.',
    symbol: '000065.SZ',
  },
  {
    value: '000068.SZ',
    label: '000068.SZ,  Shenzhen Huakong Seg Co., Ltd.',
    name: 'Shenzhen Huakong Seg Co., Ltd.',
    symbol: '000068.SZ',
  },
  {
    value: '000070.SZ',
    label: '000070.SZ,  Shenzhen SDG Information Co., Ltd.',
    name: 'Shenzhen SDG Information Co., Ltd.',
    symbol: '000070.SZ',
  },
  {
    value: '000088.SZ',
    label: '000088.SZ,  Shenzhen Yan Tian Port Holdings Co.,Ltd.',
    name: 'Shenzhen Yan Tian Port Holdings Co.,Ltd.',
    symbol: '000088.SZ',
  },
  {
    value: '000096.SZ',
    label: '000096.SZ,  Shenzhen Guangju Energy Co., Ltd.',
    name: 'Shenzhen Guangju Energy Co., Ltd.',
    symbol: '000096.SZ',
  },
  {
    value: '000099.SZ',
    label: '000099.SZ,  CITIC Offshore Helicopter Co., Ltd.',
    name: 'CITIC Offshore Helicopter Co., Ltd.',
    symbol: '000099.SZ',
  },
  {
    value: '000150.SZ',
    label: '000150.SZ,  Yihua Healthcare Co., Ltd.',
    name: 'Yihua Healthcare Co., Ltd.',
    symbol: '000150.SZ',
  },
  {
    value: '000151.SZ',
    label:
      '000151.SZ,  China National Complete Plant Import & Export Corporation Limited',
    name: 'China National Complete Plant Import & Export Corporation Limited',
    symbol: '000151.SZ',
  },
  {
    value: '000153.SZ',
    label: '000153.SZ,  Anhui Fengyuan Pharmaceutical Co., Ltd.',
    name: 'Anhui Fengyuan Pharmaceutical Co., Ltd.',
    symbol: '000153.SZ',
  },
  {
    value: '000155.SZ',
    label: '000155.SZ,  Sichuan New Energy Power Company Limited',
    name: 'Sichuan New Energy Power Company Limited',
    symbol: '000155.SZ',
  },
  {
    value: '000158.SZ',
    label: '000158.SZ,  Shijiazhuang ChangShan BeiMing Technology Co.,Ltd',
    name: 'Shijiazhuang ChangShan BeiMing Technology Co.,Ltd',
    symbol: '000158.SZ',
  },
  {
    value: '000159.SZ',
    label: '000159.SZ,  Xinjiang International Industry Co.,Ltd',
    name: 'Xinjiang International Industry Co.,Ltd',
    symbol: '000159.SZ',
  },
  {
    value: '000403.SZ',
    label: '000403.SZ,  Pacific Shuanglin Bio-pharmacy Co., LTD',
    name: 'Pacific Shuanglin Bio-pharmacy Co., LTD',
    symbol: '000403.SZ',
  },
  {
    value: '000404.SZ',
    label: '000404.SZ,  Changhong Huayi Compressor Co., Ltd.',
    name: 'Changhong Huayi Compressor Co., Ltd.',
    symbol: '000404.SZ',
  },
  {
    value: '000407.SZ',
    label: '000407.SZ,  Shandong Shengli Co., Ltd.',
    name: 'Shandong Shengli Co., Ltd.',
    symbol: '000407.SZ',
  },
  {
    value: '000408.SZ',
    label: '000408.SZ,  Zangge Mining Company Limited',
    name: 'Zangge Mining Company Limited',
    symbol: '000408.SZ',
  },
  {
    value: '000409.SZ',
    label: '000409.SZ,  Yunding Technology Co.,Ltd.',
    name: 'Yunding Technology Co.,Ltd.',
    symbol: '000409.SZ',
  },
  {
    value: '000410.SZ',
    label: '000410.SZ,  Shenyang Machine Tool Co., Ltd.',
    name: 'Shenyang Machine Tool Co., Ltd.',
    symbol: '000410.SZ',
  },
  {
    value: '000411.SZ',
    label: "000411.SZ,  Zhejiang Int'l Group Co.,Ltd.",
    name: "Zhejiang Int'l Group Co.,Ltd.",
    symbol: '000411.SZ',
  },
  {
    value: '000413.SZ',
    label: '000413.SZ,  Tunghsu Optoelectronic Technology Co., Ltd.',
    name: 'Tunghsu Optoelectronic Technology Co., Ltd.',
    symbol: '000413.SZ',
  },
  {
    value: '000416.SZ',
    label: '000416.SZ,  Minsheng Holdings Co.,Ltd',
    name: 'Minsheng Holdings Co.,Ltd',
    symbol: '000416.SZ',
  },
  {
    value: '000417.SZ',
    label: '000417.SZ,  Hefei Department Store Group Co.,Ltd',
    name: 'Hefei Department Store Group Co.,Ltd',
    symbol: '000417.SZ',
  },
  {
    value: '000419.SZ',
    label: '000419.SZ,  Changsha Tongcheng Holdings Co.Ltd',
    name: 'Changsha Tongcheng Holdings Co.Ltd',
    symbol: '000419.SZ',
  },
  {
    value: '000420.SZ',
    label: '000420.SZ,  Jilin Chemical Fibre Stock Co.,Ltd',
    name: 'Jilin Chemical Fibre Stock Co.,Ltd',
    symbol: '000420.SZ',
  },
  {
    value: '000421.SZ',
    label: '000421.SZ,  Nanjing Public Utilities Development Co., Ltd.',
    name: 'Nanjing Public Utilities Development Co., Ltd.',
    symbol: '000421.SZ',
  },
  {
    value: '000422.SZ',
    label: '000422.SZ,  Hubei Yihua Chemical Industry Co., Ltd.',
    name: 'Hubei Yihua Chemical Industry Co., Ltd.',
    symbol: '000422.SZ',
  },
  {
    value: '000426.SZ',
    label: '000426.SZ,  Inner Mongolia Xingye Mining Co., Ltd.',
    name: 'Inner Mongolia Xingye Mining Co., Ltd.',
    symbol: '000426.SZ',
  },
  {
    value: '000428.SZ',
    label: '000428.SZ,  Huatian Hotel Group Co.,Ltd.',
    name: 'Huatian Hotel Group Co.,Ltd.',
    symbol: '000428.SZ',
  },
  {
    value: '000429.SZ',
    label: '000429.SZ,  Guangdong Provincial Expressway Development Co., Ltd.',
    name: 'Guangdong Provincial Expressway Development Co., Ltd.',
    symbol: '000429.SZ',
  },
  {
    value: '000430.SZ',
    label: '000430.SZ,  Zhang Jia Jie Tourism Group Co., Ltd',
    name: 'Zhang Jia Jie Tourism Group Co., Ltd',
    symbol: '000430.SZ',
  },
  {
    value: '000498.SZ',
    label: '000498.SZ,  Shandong Hi-Speed Road and Bridge Group Co., Ltd.',
    name: 'Shandong Hi-Speed Road and Bridge Group Co., Ltd.',
    symbol: '000498.SZ',
  },
  {
    value: '000503.SZ',
    label:
      '000503.SZ,  China Reform Health Management and Services Group Co., Ltd.',
    name: 'China Reform Health Management and Services Group Co., Ltd.',
    symbol: '000503.SZ',
  },
  {
    value: '000504.SZ',
    label: '000504.SZ,  NanHua Bio-medicine Co., Ltd',
    name: 'NanHua Bio-medicine Co., Ltd',
    symbol: '000504.SZ',
  },
  {
    value: '000505.SZ',
    label: '000505.SZ,  Hainan Jingliang Holdings Co., Ltd.',
    name: 'Hainan Jingliang Holdings Co., Ltd.',
    symbol: '000505.SZ',
  },
  {
    value: '000506.SZ',
    label: '000506.SZ,  Zhongrun Resources Investment Corporation',
    name: 'Zhongrun Resources Investment Corporation',
    symbol: '000506.SZ',
  },
  {
    value: '000507.SZ',
    label: '000507.SZ,  Zhuhai Port Co.,Ltd.',
    name: 'Zhuhai Port Co.,Ltd.',
    symbol: '000507.SZ',
  },
  {
    value: '000509.SZ',
    label: '000509.SZ,  Huasu Holdings Co.,Ltd',
    name: 'Huasu Holdings Co.,Ltd',
    symbol: '000509.SZ',
  },
  {
    value: '000510.SZ',
    label: '000510.SZ,  Sichuan Xinjinlu Group Co., Ltd.',
    name: 'Sichuan Xinjinlu Group Co., Ltd.',
    symbol: '000510.SZ',
  },
  {
    value: '000514.SZ',
    label: '000514.SZ,  Chongqing Yukaifa Co., Ltd',
    name: 'Chongqing Yukaifa Co., Ltd',
    symbol: '000514.SZ',
  },
  {
    value: '000516.SZ',
    label: "000516.SZ,  Xi'an International Medical Investment Company Limited",
    name: "Xi'an International Medical Investment Company Limited",
    symbol: '000516.SZ',
  },
  {
    value: '000518.SZ',
    label: '000518.SZ,  Jiangsu Sihuan Bioengineering Co., Ltd',
    name: 'Jiangsu Sihuan Bioengineering Co., Ltd',
    symbol: '000518.SZ',
  },
  {
    value: '000520.SZ',
    label: '000520.SZ,  Chang Jiang Shipping Group Phoenix Co.,Ltd',
    name: 'Chang Jiang Shipping Group Phoenix Co.,Ltd',
    symbol: '000520.SZ',
  },
  {
    value: '000521.SZ',
    label: '000521.SZ,  Changhong Meiling Co., Ltd.',
    name: 'Changhong Meiling Co., Ltd.',
    symbol: '000521.SZ',
  },
  {
    value: '000523.SZ',
    label: '000523.SZ,  Lonkey Industrial Co.,Ltd.Guangzhou',
    name: 'Lonkey Industrial Co.,Ltd.Guangzhou',
    symbol: '000523.SZ',
  },
  {
    value: '000524.SZ',
    label: '000524.SZ,  Guangzhou Lingnan Group Holdings Company Limited',
    name: 'Guangzhou Lingnan Group Holdings Company Limited',
    symbol: '000524.SZ',
  },
  {
    value: '000525.SZ',
    label: '000525.SZ,  Nanjing Red Sun Co.,Ltd.',
    name: 'Nanjing Red Sun Co.,Ltd.',
    symbol: '000525.SZ',
  },
  {
    value: '000526.SZ',
    label: '000526.SZ,  Xueda (Xiamen) Education Technology Group Co., Ltd.',
    name: 'Xueda (Xiamen) Education Technology Group Co., Ltd.',
    symbol: '000526.SZ',
  },
  {
    value: '000529.SZ',
    label: '000529.SZ,  Guangdong Guanghong Holdings Co.,Ltd.',
    name: 'Guangdong Guanghong Holdings Co.,Ltd.',
    symbol: '000529.SZ',
  },
  {
    value: '000530.SZ',
    label:
      '000530.SZ,  Bingshan Refrigeration & Heat Transfer Technologies Co., Ltd.',
    name: 'Bingshan Refrigeration & Heat Transfer Technologies Co., Ltd.',
    symbol: '000530.SZ',
  },
  {
    value: '000531.SZ',
    label: '000531.SZ,  Guangzhou Hengyun Enterprises Holding Ltd',
    name: 'Guangzhou Hengyun Enterprises Holding Ltd',
    symbol: '000531.SZ',
  },
  {
    value: '000532.SZ',
    label: '000532.SZ,  Zhuhai Huajin Capital Co., Ltd.',
    name: 'Zhuhai Huajin Capital Co., Ltd.',
    symbol: '000532.SZ',
  },
  {
    value: '000533.SZ',
    label: '000533.SZ,  Guangdong Shunna Electric Co., Ltd',
    name: 'Guangdong Shunna Electric Co., Ltd',
    symbol: '000533.SZ',
  },
  {
    value: '000534.SZ',
    label: '000534.SZ,  Wedge Industrial Co.,Ltd.',
    name: 'Wedge Industrial Co.,Ltd.',
    symbol: '000534.SZ',
  },
  {
    value: '000536.SZ',
    label: '000536.SZ,  CPT Technology (Group) Co.,Ltd',
    name: 'CPT Technology (Group) Co.,Ltd',
    symbol: '000536.SZ',
  },
  {
    value: '000539.SZ',
    label: '000539.SZ,  Guangdong Electric Power Development Co., Ltd.',
    name: 'Guangdong Electric Power Development Co., Ltd.',
    symbol: '000539.SZ',
  },
  {
    value: '000541.SZ',
    label: '000541.SZ,  Foshan Electrical and Lighting Co.,Ltd',
    name: 'Foshan Electrical and Lighting Co.,Ltd',
    symbol: '000541.SZ',
  },
  {
    value: '000543.SZ',
    label: '000543.SZ,  An Hui Wenergy Company Limited',
    name: 'An Hui Wenergy Company Limited',
    symbol: '000543.SZ',
  },
  {
    value: '000545.SZ',
    label: '000545.SZ,  Gpro Titanium Industry Co., Ltd.',
    name: 'Gpro Titanium Industry Co., Ltd.',
    symbol: '000545.SZ',
  },
  {
    value: '000546.SZ',
    label: '000546.SZ,  Jinyuan EP Co., Ltd.',
    name: 'Jinyuan EP Co., Ltd.',
    symbol: '000546.SZ',
  },
  {
    value: '000548.SZ',
    label: '000548.SZ,  Hunan Investment Group Co.,Ltd.',
    name: 'Hunan Investment Group Co.,Ltd.',
    symbol: '000548.SZ',
  },
  {
    value: '000550.SZ',
    label: '000550.SZ,  Jiangling Motors Corporation, Ltd.',
    name: 'Jiangling Motors Corporation, Ltd.',
    symbol: '000550.SZ',
  },
  {
    value: '000551.SZ',
    label: '000551.SZ,  Create Technology & Science Co.,Ltd.',
    name: 'Create Technology & Science Co.,Ltd.',
    symbol: '000551.SZ',
  },
  {
    value: '000552.SZ',
    label:
      '000552.SZ,  Gansu Jingyuan Coal Industry & Electricity Power Co., Ltd',
    name: 'Gansu Jingyuan Coal Industry & Electricity Power Co., Ltd',
    symbol: '000552.SZ',
  },
  {
    value: '000553.SZ',
    label: '000553.SZ,  ADAMA Ltd.',
    name: 'ADAMA Ltd.',
    symbol: '000553.SZ',
  },
  {
    value: '000554.SZ',
    label: '000554.SZ,  SINOPEC Shandong Taishan Pectroleum CO.,LTD.',
    name: 'SINOPEC Shandong Taishan Pectroleum CO.,LTD.',
    symbol: '000554.SZ',
  },
  {
    value: '000557.SZ',
    label: '000557.SZ,  Ningxia Western Venture Industrial Co.,Ltd.',
    name: 'Ningxia Western Venture Industrial Co.,Ltd.',
    symbol: '000557.SZ',
  },
  {
    value: '000558.SZ',
    label: '000558.SZ,  Lander Sports Development Co., Ltd.',
    name: 'Lander Sports Development Co., Ltd.',
    symbol: '000558.SZ',
  },
  {
    value: '000560.SZ',
    label: '000560.SZ,  5i5j Holding Group Co., Ltd.',
    name: '5i5j Holding Group Co., Ltd.',
    symbol: '000560.SZ',
  },
  {
    value: '000561.SZ',
    label: '000561.SZ,  Shaanxi Fenghuo Electronics Co., Ltd.',
    name: 'Shaanxi Fenghuo Electronics Co., Ltd.',
    symbol: '000561.SZ',
  },
  {
    value: '000564.SZ',
    label: '000564.SZ,  Ccoop Group Co., Ltd',
    name: 'Ccoop Group Co., Ltd',
    symbol: '000564.SZ',
  },
  {
    value: '000565.SZ',
    label: '000565.SZ,  Chongqing Sanxia Paints Co., Ltd',
    name: 'Chongqing Sanxia Paints Co., Ltd',
    symbol: '000565.SZ',
  },
  {
    value: '000566.SZ',
    label: '000566.SZ,  Hainan Haiyao Co., Ltd.',
    name: 'Hainan Haiyao Co., Ltd.',
    symbol: '000566.SZ',
  },
  {
    value: '000567.SZ',
    label: '000567.SZ,  Hainan Haide Capital Management Co., Ltd.',
    name: 'Hainan Haide Capital Management Co., Ltd.',
    symbol: '000567.SZ',
  },
  {
    value: '000570.SZ',
    label: '000570.SZ,  Changchai Company, Limited',
    name: 'Changchai Company, Limited',
    symbol: '000570.SZ',
  },
  {
    value: '000571.SZ',
    label: '000571.SZ,  Sundiro Holding Co., Ltd.',
    name: 'Sundiro Holding Co., Ltd.',
    symbol: '000571.SZ',
  },
  {
    value: '000572.SZ',
    label: '000572.SZ,  Haima Automobile Co.,Ltd',
    name: 'Haima Automobile Co.,Ltd',
    symbol: '000572.SZ',
  },
  {
    value: '000573.SZ',
    label: '000573.SZ,  DongGuan Winnerway Industry Zone LTD.',
    name: 'DongGuan Winnerway Industry Zone LTD.',
    symbol: '000573.SZ',
  },
  {
    value: '000576.SZ',
    label: '000576.SZ,  Guangdong Ganhua Science and Industry Co., Ltd.',
    name: 'Guangdong Ganhua Science and Industry Co., Ltd.',
    symbol: '000576.SZ',
  },
  {
    value: '000582.SZ',
    label: '000582.SZ,  Beibu Gulf Port Co., Ltd.',
    name: 'Beibu Gulf Port Co., Ltd.',
    symbol: '000582.SZ',
  },
  {
    value: '000584.SZ',
    label: '000584.SZ,  Jiangsu Hagong Intelligent Robot Co., Ltd',
    name: 'Jiangsu Hagong Intelligent Robot Co., Ltd',
    symbol: '000584.SZ',
  },
  {
    value: '000586.SZ',
    label: '000586.SZ,  Sichuan Huiyuan Optical Communication Co., Ltd.',
    name: 'Sichuan Huiyuan Optical Communication Co., Ltd.',
    symbol: '000586.SZ',
  },
  {
    value: '000587.SZ',
    label: '000587.SZ,  Jinzhou Cihang Group Co., Ltd.',
    name: 'Jinzhou Cihang Group Co., Ltd.',
    symbol: '000587.SZ',
  },
  {
    value: '000589.SZ',
    label: '000589.SZ,  Guizhou Tyre Co.,Ltd.',
    name: 'Guizhou Tyre Co.,Ltd.',
    symbol: '000589.SZ',
  },
  {
    value: '000590.SZ',
    label: '000590.SZ,  Tus-Pharmaceutical Group Co., Ltd.',
    name: 'Tus-Pharmaceutical Group Co., Ltd.',
    symbol: '000590.SZ',
  },
  {
    value: '000592.SZ',
    label: '000592.SZ,  Zhongfu Straits (Pingtan) Development Company Limited',
    name: 'Zhongfu Straits (Pingtan) Development Company Limited',
    symbol: '000592.SZ',
  },
  {
    value: '000593.SZ',
    label: '000593.SZ,  Delong Composite Energy Group Co., Ltd.',
    name: 'Delong Composite Energy Group Co., Ltd.',
    symbol: '000593.SZ',
  },
  {
    value: '000595.SZ',
    label: '000595.SZ,  Baota Industry Co., Ltd.',
    name: 'Baota Industry Co., Ltd.',
    symbol: '000595.SZ',
  },
  {
    value: '000597.SZ',
    label: '000597.SZ,  Northeast Pharmaceutical Group Co., Ltd.',
    name: 'Northeast Pharmaceutical Group Co., Ltd.',
    symbol: '000597.SZ',
  },
  {
    value: '000599.SZ',
    label: '000599.SZ,  Qingdao Doublestar Co.,Ltd',
    name: 'Qingdao Doublestar Co.,Ltd',
    symbol: '000599.SZ',
  },
  {
    value: '000600.SZ',
    label: '000600.SZ,  Jointo Energy Investment Co., Ltd. Hebei',
    name: 'Jointo Energy Investment Co., Ltd. Hebei',
    symbol: '000600.SZ',
  },
  {
    value: '000603.SZ',
    label: '000603.SZ,  Shengda Resources Co.,Ltd.',
    name: 'Shengda Resources Co.,Ltd.',
    symbol: '000603.SZ',
  },
  {
    value: '000605.SZ',
    label: '000605.SZ,  Bohai Water Industry Co.,Ltd',
    name: 'Bohai Water Industry Co.,Ltd',
    symbol: '000605.SZ',
  },
  {
    value: '000606.SZ',
    label: '000606.SZ,  Shunliban Information Service Co.,Ltd',
    name: 'Shunliban Information Service Co.,Ltd',
    symbol: '000606.SZ',
  },
  {
    value: '000607.SZ',
    label: '000607.SZ,  Zhejiang Huamei Holding CO., LTD.',
    name: 'Zhejiang Huamei Holding CO., LTD.',
    symbol: '000607.SZ',
  },
  {
    value: '000608.SZ',
    label: '000608.SZ,  Yang Guang Co.,Ltd.',
    name: 'Yang Guang Co.,Ltd.',
    symbol: '000608.SZ',
  },
  {
    value: '000609.SZ',
    label: '000609.SZ,  Beijing Zodi Investment Co., Ltd.',
    name: 'Beijing Zodi Investment Co., Ltd.',
    symbol: '000609.SZ',
  },
  {
    value: '000610.SZ',
    label: "000610.SZ,  Xi'an Tourism Co., Ltd.",
    name: "Xi'an Tourism Co., Ltd.",
    symbol: '000610.SZ',
  },
  {
    value: '000612.SZ',
    label: '000612.SZ,  JiaoZuo WanFang Aluminum Manufacturing Co., Ltd',
    name: 'JiaoZuo WanFang Aluminum Manufacturing Co., Ltd',
    symbol: '000612.SZ',
  },
  {
    value: '000615.SZ',
    label: '000615.SZ,  Aoyuan Beauty Valley Technology Co.,Ltd.',
    name: 'Aoyuan Beauty Valley Technology Co.,Ltd.',
    symbol: '000615.SZ',
  },
  {
    value: '000616.SZ',
    label: '000616.SZ,  HNA Investment Group Co., Ltd.',
    name: 'HNA Investment Group Co., Ltd.',
    symbol: '000616.SZ',
  },
  {
    value: '000619.SZ',
    label:
      '000619.SZ,  Conch (Anhui) Energy Saving and Environment Protection New Material Co., Ltd.',
    name: 'Conch (Anhui) Energy Saving and Environment Protection New Material Co., Ltd.',
    symbol: '000619.SZ',
  },
  {
    value: '000620.SZ',
    label: '000620.SZ,  Macrolink Culturaltainment Development Co., Ltd.',
    name: 'Macrolink Culturaltainment Development Co., Ltd.',
    symbol: '000620.SZ',
  },
  {
    value: '000622.SZ',
    label: '000622.SZ,  Hengli Industrial Development Group Co., Ltd.',
    name: 'Hengli Industrial Development Group Co., Ltd.',
    symbol: '000622.SZ',
  },
  {
    value: '000626.SZ',
    label: '000626.SZ,  Grand Industrial Holding Co.,Ltd',
    name: 'Grand Industrial Holding Co.,Ltd',
    symbol: '000626.SZ',
  },
  {
    value: '000628.SZ',
    label: '000628.SZ,  ChengDu Hi-Tech Development Co., Ltd.',
    name: 'ChengDu Hi-Tech Development Co., Ltd.',
    symbol: '000628.SZ',
  },
  {
    value: '000631.SZ',
    label: '000631.SZ,  Shunfa Hengye Corporation',
    name: 'Shunfa Hengye Corporation',
    symbol: '000631.SZ',
  },
  {
    value: '000632.SZ',
    label: '000632.SZ,  Fujian Sanmu Group Co., Ltd.',
    name: 'Fujian Sanmu Group Co., Ltd.',
    symbol: '000632.SZ',
  },
  {
    value: '000633.SZ',
    label: '000633.SZ,  Xinjiang Hejin Holding Co.,Ltd',
    name: 'Xinjiang Hejin Holding Co.,Ltd',
    symbol: '000633.SZ',
  },
  {
    value: '000635.SZ',
    label: '000635.SZ,  Ningxia Yinglite Chemicals Co., Ltd',
    name: 'Ningxia Yinglite Chemicals Co., Ltd',
    symbol: '000635.SZ',
  },
  {
    value: '000637.SZ',
    label: '000637.SZ,  Maoming Petro-Chemical Shihua Co., Ltd',
    name: 'Maoming Petro-Chemical Shihua Co., Ltd',
    symbol: '000637.SZ',
  },
  {
    value: '000638.SZ',
    label: '000638.SZ,  Vanfund Urban Investment and Development Co., Ltd.',
    name: 'Vanfund Urban Investment and Development Co., Ltd.',
    symbol: '000638.SZ',
  },
  {
    value: '000639.SZ',
    label: '000639.SZ,  Xiwang Foodstuffs Co.,Ltd.',
    name: 'Xiwang Foodstuffs Co.,Ltd.',
    symbol: '000639.SZ',
  },
  {
    value: '000650.SZ',
    label: '000650.SZ,  Renhe Pharmacy Co., Ltd.',
    name: 'Renhe Pharmacy Co., Ltd.',
    symbol: '000650.SZ',
  },
  {
    value: '000652.SZ',
    label: '000652.SZ,  Tianjin Teda Co., Ltd.',
    name: 'Tianjin Teda Co., Ltd.',
    symbol: '000652.SZ',
  },
  {
    value: '000655.SZ',
    label: '000655.SZ,  Shandong Jinling Mining Co., Ltd.',
    name: 'Shandong Jinling Mining Co., Ltd.',
    symbol: '000655.SZ',
  },
  {
    value: '000657.SZ',
    label: '000657.SZ,  China Tungsten And Hightech Materals Co.,Ltd',
    name: 'China Tungsten And Hightech Materals Co.,Ltd',
    symbol: '000657.SZ',
  },
  {
    value: '000659.SZ',
    label: '000659.SZ,  Zhuhai Zhongfu Enterprise Co.,Ltd',
    name: 'Zhuhai Zhongfu Enterprise Co.,Ltd',
    symbol: '000659.SZ',
  },
  {
    value: '000663.SZ',
    label: '000663.SZ,  Fujian Yongan Forestry(Group)Joint -Stock Co.,Ltd.',
    name: 'Fujian Yongan Forestry(Group)Joint -Stock Co.,Ltd.',
    symbol: '000663.SZ',
  },
  {
    value: '000665.SZ',
    label: '000665.SZ,  Hubei Radio & Television Information Network Co., Ltd.',
    name: 'Hubei Radio & Television Information Network Co., Ltd.',
    symbol: '000665.SZ',
  },
  {
    value: '000666.SZ',
    label: '000666.SZ,  Jingwei Textile Machinery Company Limited',
    name: 'Jingwei Textile Machinery Company Limited',
    symbol: '000666.SZ',
  },
  {
    value: '000667.SZ',
    label: '000667.SZ,  Myhome Real Estate Development Group Co., Ltd.',
    name: 'Myhome Real Estate Development Group Co., Ltd.',
    symbol: '000667.SZ',
  },
  {
    value: '000668.SZ',
    label: '000668.SZ,  Rongfeng Holding Group Co.,Ltd.',
    name: 'Rongfeng Holding Group Co.,Ltd.',
    symbol: '000668.SZ',
  },
  {
    value: '000669.SZ',
    label: '000669.SZ,  Jinhong Holding Group Co., Ltd.',
    name: 'Jinhong Holding Group Co., Ltd.',
    symbol: '000669.SZ',
  },
  {
    value: '000672.SZ',
    label: '000672.SZ,  Gansu Shangfeng Cement Co.,Ltd',
    name: 'Gansu Shangfeng Cement Co.,Ltd',
    symbol: '000672.SZ',
  },
  {
    value: '000676.SZ',
    label: '000676.SZ,  Genimous Technology Co., Ltd.',
    name: 'Genimous Technology Co., Ltd.',
    symbol: '000676.SZ',
  },
  {
    value: '000677.SZ',
    label: '000677.SZ,  CHTC Helon Co., Ltd.',
    name: 'CHTC Helon Co., Ltd.',
    symbol: '000677.SZ',
  },
  {
    value: '000678.SZ',
    label: '000678.SZ,  Xiangyang Automobile Bearing Co., Ltd.',
    name: 'Xiangyang Automobile Bearing Co., Ltd.',
    symbol: '000678.SZ',
  },
  {
    value: '000679.SZ',
    label: '000679.SZ,  Dalian Friendship (Group) Co., Ltd.',
    name: 'Dalian Friendship (Group) Co., Ltd.',
    symbol: '000679.SZ',
  },
  {
    value: '000680.SZ',
    label: '000680.SZ,  Shantui Construction Machinery Co., Ltd.',
    name: 'Shantui Construction Machinery Co., Ltd.',
    symbol: '000680.SZ',
  },
  {
    value: '000682.SZ',
    label: '000682.SZ,  Dongfang Electronics Co., Ltd.',
    name: 'Dongfang Electronics Co., Ltd.',
    symbol: '000682.SZ',
  },
  {
    value: '000683.SZ',
    label: '000683.SZ,  Inner Mongolia Yuan Xing Energy Co.,Ltd',
    name: 'Inner Mongolia Yuan Xing Energy Co.,Ltd',
    symbol: '000683.SZ',
  },
  {
    value: '000688.SZ',
    label: '000688.SZ,  GuoCheng Mining CO.,LTD',
    name: 'GuoCheng Mining CO.,LTD',
    symbol: '000688.SZ',
  },
  {
    value: '000691.SZ',
    label: '000691.SZ,  Gansu Yatai Industrial Developent Co.,Ltd.',
    name: 'Gansu Yatai Industrial Developent Co.,Ltd.',
    symbol: '000691.SZ',
  },
  {
    value: '000692.SZ',
    label: '000692.SZ,  Shenyang Huitian Thermal Power Co.,Ltd',
    name: 'Shenyang Huitian Thermal Power Co.,Ltd',
    symbol: '000692.SZ',
  },
  {
    value: '000695.SZ',
    label: '000695.SZ,  Tianjin Binhai Energy & Development Co.,Ltd',
    name: 'Tianjin Binhai Energy & Development Co.,Ltd',
    symbol: '000695.SZ',
  },
  {
    value: '000697.SZ',
    label: '000697.SZ,  Ligeance Aerospace Technology Co.,Ltd.',
    name: 'Ligeance Aerospace Technology Co.,Ltd.',
    symbol: '000697.SZ',
  },
  {
    value: '000698.SZ',
    label: '000698.SZ,  Shenyang Chemical Industry Co., Ltd.',
    name: 'Shenyang Chemical Industry Co., Ltd.',
    symbol: '000698.SZ',
  },
  {
    value: '000700.SZ',
    label: '000700.SZ,  Jiangnan Mould & Plastic Technology Co., Ltd.',
    name: 'Jiangnan Mould & Plastic Technology Co., Ltd.',
    symbol: '000700.SZ',
  },
  {
    value: '000701.SZ',
    label: '000701.SZ,  Xiamen Xindeco Ltd.',
    name: 'Xiamen Xindeco Ltd.',
    symbol: '000701.SZ',
  },
  {
    value: '000702.SZ',
    label:
      '000702.SZ,  Hunan Zhenghong Science and Technology Develop Co.,Ltd.',
    name: 'Hunan Zhenghong Science and Technology Develop Co.,Ltd.',
    symbol: '000702.SZ',
  },
  {
    value: '000705.SZ',
    label: '000705.SZ,  Zhejiang Zhenyuan Share Co., Ltd.',
    name: 'Zhejiang Zhenyuan Share Co., Ltd.',
    symbol: '000705.SZ',
  },
  {
    value: '000707.SZ',
    label: '000707.SZ,  Hubei Shuanghuan Science and Technology Stock Co.,Ltd',
    name: 'Hubei Shuanghuan Science and Technology Stock Co.,Ltd',
    symbol: '000707.SZ',
  },
  {
    value: '000711.SZ',
    label: '000711.SZ,  Kingland Technology Co.,Ltd.',
    name: 'Kingland Technology Co.,Ltd.',
    symbol: '000711.SZ',
  },
  {
    value: '000712.SZ',
    label: '000712.SZ,  Guangdong Golden Dragon Development Inc.',
    name: 'Guangdong Golden Dragon Development Inc.',
    symbol: '000712.SZ',
  },
  {
    value: '000713.SZ',
    label: '000713.SZ,  Hefei Fengle Seed Co., Ltd',
    name: 'Hefei Fengle Seed Co., Ltd',
    symbol: '000713.SZ',
  },
  {
    value: '000715.SZ',
    label: '000715.SZ,  Zhongxing Shenyang Commercial Building Group Co.,Ltd',
    name: 'Zhongxing Shenyang Commercial Building Group Co.,Ltd',
    symbol: '000715.SZ',
  },
  {
    value: '000716.SZ',
    label: '000716.SZ,  Nanfang Black Sesame Group Co., Ltd.',
    name: 'Nanfang Black Sesame Group Co., Ltd.',
    symbol: '000716.SZ',
  },
  {
    value: '000717.SZ',
    label: '000717.SZ,  Guangdong Zhongnan Iron and Steel Co., Ltd.',
    name: 'Guangdong Zhongnan Iron and Steel Co., Ltd.',
    symbol: '000717.SZ',
  },
  {
    value: '000719.SZ',
    label: '000719.SZ,  Central China Land Media CO.,LTD',
    name: 'Central China Land Media CO.,LTD',
    symbol: '000719.SZ',
  },
  {
    value: '000720.SZ',
    label: '000720.SZ,  Shandong Xinneng Taishan Power Generation Co.,Ltd.',
    name: 'Shandong Xinneng Taishan Power Generation Co.,Ltd.',
    symbol: '000720.SZ',
  },
  {
    value: '000721.SZ',
    label: "000721.SZ,  Xi'an Catering Co., Ltd.",
    name: "Xi'an Catering Co., Ltd.",
    symbol: '000721.SZ',
  },
  {
    value: '000722.SZ',
    label: '000722.SZ,  Hunan Development Group Co.,Ltd',
    name: 'Hunan Development Group Co.,Ltd',
    symbol: '000722.SZ',
  },
  {
    value: '000726.SZ',
    label: '000726.SZ,  Lu Thai Textile Co., Ltd.',
    name: 'Lu Thai Textile Co., Ltd.',
    symbol: '000726.SZ',
  },
  {
    value: '000727.SZ',
    label: '000727.SZ,  TPV Technology Co., Ltd.',
    name: 'TPV Technology Co., Ltd.',
    symbol: '000727.SZ',
  },
  {
    value: '000731.SZ',
    label: '000731.SZ,  Sichuan Meifeng Chemical Industry Co., Ltd.',
    name: 'Sichuan Meifeng Chemical Industry Co., Ltd.',
    symbol: '000731.SZ',
  },
  {
    value: '000733.SZ',
    label: '000733.SZ,  China Zhenhua (Group) Science & Technology Co., Ltd',
    name: 'China Zhenhua (Group) Science & Technology Co., Ltd',
    symbol: '000733.SZ',
  },
  {
    value: '000735.SZ',
    label: '000735.SZ,  Luoniushan Co., Ltd.',
    name: 'Luoniushan Co., Ltd.',
    symbol: '000735.SZ',
  },
  {
    value: '000736.SZ',
    label: '000736.SZ,  CCCG Real Estate Corporation Limited',
    name: 'CCCG Real Estate Corporation Limited',
    symbol: '000736.SZ',
  },
  {
    value: '000737.SZ',
    label: '000737.SZ,  North Copper Co., Ltd.',
    name: 'North Copper Co., Ltd.',
    symbol: '000737.SZ',
  },
  {
    value: '000751.SZ',
    label: '000751.SZ,  Huludao Zinc Industry Co.,Ltd.',
    name: 'Huludao Zinc Industry Co.,Ltd.',
    symbol: '000751.SZ',
  },
  {
    value: '000752.SZ',
    label: '000752.SZ,  Tibet Development Co., Ltd.',
    name: 'Tibet Development Co., Ltd.',
    symbol: '000752.SZ',
  },
  {
    value: '000753.SZ',
    label: '000753.SZ,  Fujian Zhangzhou Development Co.,LTD.',
    name: 'Fujian Zhangzhou Development Co.,LTD.',
    symbol: '000753.SZ',
  },
  {
    value: '000755.SZ',
    label: '000755.SZ,  Shanxi Road & Bridge Co.,Ltd.',
    name: 'Shanxi Road & Bridge Co.,Ltd.',
    symbol: '000755.SZ',
  },
  {
    value: '000756.SZ',
    label: '000756.SZ,  Shandong Xinhua Pharmaceutical Company Limited',
    name: 'Shandong Xinhua Pharmaceutical Company Limited',
    symbol: '000756.SZ',
  },
  {
    value: '000757.SZ',
    label: '000757.SZ,  Sichuan Haowu Electromechanical Co., Ltd.',
    name: 'Sichuan Haowu Electromechanical Co., Ltd.',
    symbol: '000757.SZ',
  },
  {
    value: '000759.SZ',
    label: '000759.SZ,  Zhongbai Holdings Group Co.,Ltd',
    name: 'Zhongbai Holdings Group Co.,Ltd',
    symbol: '000759.SZ',
  },
  {
    value: '000761.SZ',
    label: '000761.SZ,  Bengang Steel Plates Co., Ltd.',
    name: 'Bengang Steel Plates Co., Ltd.',
    symbol: '000761.SZ',
  },
  {
    value: '000762.SZ',
    label: '000762.SZ,  Tibet Mineral Development Co., LTD',
    name: 'Tibet Mineral Development Co., LTD',
    symbol: '000762.SZ',
  },
  {
    value: '000766.SZ',
    label: '000766.SZ,  Tonghua Golden-Horse Pharmaceutical Industry Co,Ltd',
    name: 'Tonghua Golden-Horse Pharmaceutical Industry Co,Ltd',
    symbol: '000766.SZ',
  },
  {
    value: '000767.SZ',
    label: '000767.SZ,  Jinneng Holding Shanxi Electric Power Co.,LTD.',
    name: 'Jinneng Holding Shanxi Electric Power Co.,LTD.',
    symbol: '000767.SZ',
  },
  {
    value: '000768.SZ',
    label: "000768.SZ,  AVIC Xi'an Aircraft Industry Group Company Ltd.",
    name: "AVIC Xi'an Aircraft Industry Group Company Ltd.",
    symbol: '000768.SZ',
  },
  {
    value: '000777.SZ',
    label: '000777.SZ,  SUFA Technology Industry Co., Ltd. CNNC',
    name: 'SUFA Technology Industry Co., Ltd. CNNC',
    symbol: '000777.SZ',
  },
  {
    value: '000779.SZ',
    label: '000779.SZ,  Gansu Engineering Consulting Group Co., Ltd.',
    name: 'Gansu Engineering Consulting Group Co., Ltd.',
    symbol: '000779.SZ',
  },
  {
    value: '000782.SZ',
    label: '000782.SZ,  Guangdong Xinhui Meida Nylon Co., Ltd.',
    name: 'Guangdong Xinhui Meida Nylon Co., Ltd.',
    symbol: '000782.SZ',
  },
  {
    value: '000785.SZ',
    label: '000785.SZ,  Easyhome New Retail Group Corporation Limited',
    name: 'Easyhome New Retail Group Corporation Limited',
    symbol: '000785.SZ',
  },
  {
    value: '000788.SZ',
    label: '000788.SZ,  PKU HealthCare Corp.,Ltd.',
    name: 'PKU HealthCare Corp.,Ltd.',
    symbol: '000788.SZ',
  },
  {
    value: '000790.SZ',
    label: '000790.SZ,  Chengdu Huasun Technology Group Inc., Ltd.',
    name: 'Chengdu Huasun Technology Group Inc., Ltd.',
    symbol: '000790.SZ',
  },
  {
    value: '000791.SZ',
    label: '000791.SZ,  GEPIC Energy Development Co., Ltd.',
    name: 'GEPIC Energy Development Co., Ltd.',
    symbol: '000791.SZ',
  },
  {
    value: '000792.SZ',
    label: '000792.SZ,  Qinghai Salt Lake Industry Co.,Ltd',
    name: 'Qinghai Salt Lake Industry Co.,Ltd',
    symbol: '000792.SZ',
  },
  {
    value: '000793.SZ',
    label: '000793.SZ,  Huawen Media Group',
    name: 'Huawen Media Group',
    symbol: '000793.SZ',
  },
  {
    value: '000795.SZ',
    label: '000795.SZ,  Innuovo Technology Co., Ltd.',
    name: 'Innuovo Technology Co., Ltd.',
    symbol: '000795.SZ',
  },
  {
    value: '000796.SZ',
    label: '000796.SZ,  Caissa Tosun Development Co.,Ltd.',
    name: 'Caissa Tosun Development Co.,Ltd.',
    symbol: '000796.SZ',
  },
  {
    value: '000797.SZ',
    label: '000797.SZ,  China Wuyi Co., Ltd.',
    name: 'China Wuyi Co., Ltd.',
    symbol: '000797.SZ',
  },
  {
    value: '000798.SZ',
    label: '000798.SZ,  CNFC Overseas Fisheries Co.,Ltd',
    name: 'CNFC Overseas Fisheries Co.,Ltd',
    symbol: '000798.SZ',
  },
  {
    value: '000801.SZ',
    label: '000801.SZ,  Sichuan Jiuzhou Electronic Co., Ltd.',
    name: 'Sichuan Jiuzhou Electronic Co., Ltd.',
    symbol: '000801.SZ',
  },
  {
    value: '000802.SZ',
    label: '000802.SZ,  Beijing Jingxi Culture & Tourism Co.,Ltd',
    name: 'Beijing Jingxi Culture & Tourism Co.,Ltd',
    symbol: '000802.SZ',
  },
  {
    value: '000803.SZ',
    label: '000803.SZ,  Shandong High Speed Renewable Energy Group Limited',
    name: 'Shandong High Speed Renewable Energy Group Limited',
    symbol: '000803.SZ',
  },
  {
    value: '000806.SZ',
    label: '000806.SZ,  Galaxy Biomedical Investment Co., Ltd.',
    name: 'Galaxy Biomedical Investment Co., Ltd.',
    symbol: '000806.SZ',
  },
  {
    value: '000809.SZ',
    label: '000809.SZ,  Tieling Newcity Investment Holding (Group) Limited',
    name: 'Tieling Newcity Investment Holding (Group) Limited',
    symbol: '000809.SZ',
  },
  {
    value: '000810.SZ',
    label: '000810.SZ,  Skyworth Digital Co., Ltd.',
    name: 'Skyworth Digital Co., Ltd.',
    symbol: '000810.SZ',
  },
  {
    value: '000811.SZ',
    label: '000811.SZ,  Moon Environment Technology Co.,Ltd.',
    name: 'Moon Environment Technology Co.,Ltd.',
    symbol: '000811.SZ',
  },
  {
    value: '000812.SZ',
    label:
      '000812.SZ,  Shaanxi Jinye Science Technology and Education Group Co.,Ltd',
    name: 'Shaanxi Jinye Science Technology and Education Group Co.,Ltd',
    symbol: '000812.SZ',
  },
  {
    value: '000813.SZ',
    label: '000813.SZ,  Dezhan Healthcare Company Limited',
    name: 'Dezhan Healthcare Company Limited',
    symbol: '000813.SZ',
  },
  {
    value: '000815.SZ',
    label: '000815.SZ,  MCC Meili Cloud Computing Industry Investment Co., Ltd',
    name: 'MCC Meili Cloud Computing Industry Investment Co., Ltd',
    symbol: '000815.SZ',
  },
  {
    value: '000816.SZ',
    label:
      '000816.SZ,  Jiangsu Nonghua Intelligent Agriculture Technology Co.ltd',
    name: 'Jiangsu Nonghua Intelligent Agriculture Technology Co.ltd',
    symbol: '000816.SZ',
  },
  {
    value: '000818.SZ',
    label: '000818.SZ,  Hangjin Technology Co., Ltd.',
    name: 'Hangjin Technology Co., Ltd.',
    symbol: '000818.SZ',
  },
  {
    value: '000819.SZ',
    label: '000819.SZ,  Yueyang Xingchang Petro-Chemical Co., Ltd.',
    name: 'Yueyang Xingchang Petro-Chemical Co., Ltd.',
    symbol: '000819.SZ',
  },
  {
    value: '000820.SZ',
    label: '000820.SZ,  Shenwu Energy Saving Co., Ltd.',
    name: 'Shenwu Energy Saving Co., Ltd.',
    symbol: '000820.SZ',
  },
  {
    value: '000821.SZ',
    label: '000821.SZ,  J.S. Corrugating Machinery Co., Ltd.',
    name: 'J.S. Corrugating Machinery Co., Ltd.',
    symbol: '000821.SZ',
  },
  {
    value: '000822.SZ',
    label: '000822.SZ,  Shandong Haihua Co.,Ltd',
    name: 'Shandong Haihua Co.,Ltd',
    symbol: '000822.SZ',
  },
  {
    value: '000823.SZ',
    label: '000823.SZ,  Guangdong Goworld Co., Ltd.',
    name: 'Guangdong Goworld Co., Ltd.',
    symbol: '000823.SZ',
  },
  {
    value: '000828.SZ',
    label: '000828.SZ,  Dongguan Development (Holdings) Co., Ltd.',
    name: 'Dongguan Development (Holdings) Co., Ltd.',
    symbol: '000828.SZ',
  },
  {
    value: '000829.SZ',
    label: '000829.SZ,  Telling Telecommunication Holding Co.,Ltd',
    name: 'Telling Telecommunication Holding Co.,Ltd',
    symbol: '000829.SZ',
  },
  {
    value: '000831.SZ',
    label: '000831.SZ,  China Minmetals Rare Earth Co., Ltd.',
    name: 'China Minmetals Rare Earth Co., Ltd.',
    symbol: '000831.SZ',
  },
  {
    value: '000833.SZ',
    label: '000833.SZ,  Guangxi Yuegui Guangye Holdings Co., Ltd.',
    name: 'Guangxi Yuegui Guangye Holdings Co., Ltd.',
    symbol: '000833.SZ',
  },
  {
    value: '000836.SZ',
    label: '000836.SZ,  Tianjin Futong Information Science&Technology Co.,Ltd.',
    name: 'Tianjin Futong Information Science&Technology Co.,Ltd.',
    symbol: '000836.SZ',
  },
  {
    value: '000837.SZ',
    label: '000837.SZ,  Qinchuan Machine Tool & Tool Group Share Co., Ltd.',
    name: 'Qinchuan Machine Tool & Tool Group Share Co., Ltd.',
    symbol: '000837.SZ',
  },
  {
    value: '000838.SZ',
    label: '000838.SZ,  CASIN Real Estate Development Group Co.,Ltd.',
    name: 'CASIN Real Estate Development Group Co.,Ltd.',
    symbol: '000838.SZ',
  },
  {
    value: '000839.SZ',
    label: '000839.SZ,  CITIC Guoan Information Industry Co., Ltd.',
    name: 'CITIC Guoan Information Industry Co., Ltd.',
    symbol: '000839.SZ',
  },
  {
    value: '000848.SZ',
    label: '000848.SZ,  Cheng De Lolo Company Limited',
    name: 'Cheng De Lolo Company Limited',
    symbol: '000848.SZ',
  },
  {
    value: '000850.SZ',
    label: '000850.SZ,  Anhui Huamao Textile Co., Ltd.',
    name: 'Anhui Huamao Textile Co., Ltd.',
    symbol: '000850.SZ',
  },
  {
    value: '000851.SZ',
    label: '000851.SZ,  Gohigh Networks Co., Ltd.',
    name: 'Gohigh Networks Co., Ltd.',
    symbol: '000851.SZ',
  },
  {
    value: '000852.SZ',
    label: '000852.SZ,  Sinopec Oilfield Equipment Corporation',
    name: 'Sinopec Oilfield Equipment Corporation',
    symbol: '000852.SZ',
  },
  {
    value: '000856.SZ',
    label: '000856.SZ,  Tangshan Jidong Equipment and Engineering Co.,Ltd.',
    name: 'Tangshan Jidong Equipment and Engineering Co.,Ltd.',
    symbol: '000856.SZ',
  },
  {
    value: '000859.SZ',
    label: '000859.SZ,  Anhui Guofeng New Materials Co., Ltd.',
    name: 'Anhui Guofeng New Materials Co., Ltd.',
    symbol: '000859.SZ',
  },
  {
    value: '000861.SZ',
    label: '000861.SZ,  Guangdong Highsun Group Co.,Ltd.',
    name: 'Guangdong Highsun Group Co.,Ltd.',
    symbol: '000861.SZ',
  },
  {
    value: '000862.SZ',
    label: '000862.SZ,  Ning Xia Yin Xing Energy Co.,Ltd',
    name: 'Ning Xia Yin Xing Energy Co.,Ltd',
    symbol: '000862.SZ',
  },
  {
    value: '000863.SZ',
    label: '000863.SZ,  Sanxiang Impression Co., Ltd.',
    name: 'Sanxiang Impression Co., Ltd.',
    symbol: '000863.SZ',
  },
  {
    value: '000868.SZ',
    label: '000868.SZ,  Anhui Ankai Automobile Co., Ltd',
    name: 'Anhui Ankai Automobile Co., Ltd',
    symbol: '000868.SZ',
  },
  {
    value: '000875.SZ',
    label: '000875.SZ,  Jilin Electric Power Co.,Ltd.',
    name: 'Jilin Electric Power Co.,Ltd.',
    symbol: '000875.SZ',
  },
  {
    value: '000880.SZ',
    label: '000880.SZ,  Weichai Heavy Machinery Co., Ltd.',
    name: 'Weichai Heavy Machinery Co., Ltd.',
    symbol: '000880.SZ',
  },
  {
    value: '000881.SZ',
    label: '000881.SZ,  CGN Nuclear Technology Development Co., Ltd.',
    name: 'CGN Nuclear Technology Development Co., Ltd.',
    symbol: '000881.SZ',
  },
  {
    value: '000882.SZ',
    label: '000882.SZ,  Beijing Hualian Department Store Co., Ltd',
    name: 'Beijing Hualian Department Store Co., Ltd',
    symbol: '000882.SZ',
  },
  {
    value: '000885.SZ',
    label: '000885.SZ,  City Development Environment CO.,Ltd.',
    name: 'City Development Environment CO.,Ltd.',
    symbol: '000885.SZ',
  },
  {
    value: '000886.SZ',
    label: '000886.SZ,  Hainan Expressway Co., Ltd.',
    name: 'Hainan Expressway Co., Ltd.',
    symbol: '000886.SZ',
  },
  {
    value: '000888.SZ',
    label: '000888.SZ,  Emei Shan Tourism Co.,Ltd',
    name: 'Emei Shan Tourism Co.,Ltd',
    symbol: '000888.SZ',
  },
  {
    value: '000889.SZ',
    label: '000889.SZ,  ZJBC Information Technology Co., Ltd',
    name: 'ZJBC Information Technology Co., Ltd',
    symbol: '000889.SZ',
  },
  {
    value: '000890.SZ',
    label: '000890.SZ,  Jiangsu Fasten Company Limited',
    name: 'Jiangsu Fasten Company Limited',
    symbol: '000890.SZ',
  },
  {
    value: '000892.SZ',
    label: '000892.SZ,  H&R Century Union Corporation',
    name: 'H&R Century Union Corporation',
    symbol: '000892.SZ',
  },
  {
    value: '000893.SZ',
    label:
      '000893.SZ,  Asia-potash International Investment (Guangzhou)Co.,Ltd.',
    name: 'Asia-potash International Investment (Guangzhou)Co.,Ltd.',
    symbol: '000893.SZ',
  },
  {
    value: '000897.SZ',
    label: '000897.SZ,  Tianjin Jinbin Development Co., Ltd',
    name: 'Tianjin Jinbin Development Co., Ltd',
    symbol: '000897.SZ',
  },
  {
    value: '000899.SZ',
    label: '000899.SZ,  Jiangxi Ganneng Co., Ltd.',
    name: 'Jiangxi Ganneng Co., Ltd.',
    symbol: '000899.SZ',
  },
  {
    value: '000900.SZ',
    label: '000900.SZ,  Xiandai Investment Co.,Ltd',
    name: 'Xiandai Investment Co.,Ltd',
    symbol: '000900.SZ',
  },
  {
    value: '000901.SZ',
    label: '000901.SZ,  Aerospace Hi-Tech Holding Group Co., Ltd.',
    name: 'Aerospace Hi-Tech Holding Group Co., Ltd.',
    symbol: '000901.SZ',
  },
  {
    value: '000902.SZ',
    label: '000902.SZ,  Xinyangfeng Agricultural Technology Co., Ltd.',
    name: 'Xinyangfeng Agricultural Technology Co., Ltd.',
    symbol: '000902.SZ',
  },
  {
    value: '000903.SZ',
    label: '000903.SZ,  Kunming Yunnei Power Co.,Ltd.',
    name: 'Kunming Yunnei Power Co.,Ltd.',
    symbol: '000903.SZ',
  },
  {
    value: '000905.SZ',
    label: '000905.SZ,  Xiamen Port Development Co., Ltd.',
    name: 'Xiamen Port Development Co., Ltd.',
    symbol: '000905.SZ',
  },
  {
    value: '000906.SZ',
    label: '000906.SZ,  Zheshang Development Group Co., Ltd',
    name: 'Zheshang Development Group Co., Ltd',
    symbol: '000906.SZ',
  },
  {
    value: '000908.SZ',
    label: '000908.SZ,  Hunan Jingfeng Pharmaceutical Co.,Ltd.',
    name: 'Hunan Jingfeng Pharmaceutical Co.,Ltd.',
    symbol: '000908.SZ',
  },
  {
    value: '000909.SZ',
    label: '000909.SZ,  Soyea Technology Co., Ltd',
    name: 'Soyea Technology Co., Ltd',
    symbol: '000909.SZ',
  },
  {
    value: '000910.SZ',
    label: '000910.SZ,  Dare Power Dekor Home Co.,Ltd.',
    name: 'Dare Power Dekor Home Co.,Ltd.',
    symbol: '000910.SZ',
  },
  {
    value: '000911.SZ',
    label: '000911.SZ,  Nanning Sugar Industry Co.,Ltd.',
    name: 'Nanning Sugar Industry Co.,Ltd.',
    symbol: '000911.SZ',
  },
  {
    value: '000912.SZ',
    label: '000912.SZ,  Sichuan Lutianhua Company Limited By Shares',
    name: 'Sichuan Lutianhua Company Limited By Shares',
    symbol: '000912.SZ',
  },
  {
    value: '000913.SZ',
    label: '000913.SZ,  Zhejiang Qianjiang Motorcycle Co., Ltd.',
    name: 'Zhejiang Qianjiang Motorcycle Co., Ltd.',
    symbol: '000913.SZ',
  },
  {
    value: '000915.SZ',
    label: '000915.SZ,  Shandong Shanda Wit Science and Technology Co., Ltd.',
    name: 'Shandong Shanda Wit Science and Technology Co., Ltd.',
    symbol: '000915.SZ',
  },
  {
    value: '000917.SZ',
    label: '000917.SZ,  Hunan TV & Broadcast Intermediary Co., Ltd.',
    name: 'Hunan TV & Broadcast Intermediary Co., Ltd.',
    symbol: '000917.SZ',
  },
  {
    value: '000918.SZ',
    label: '000918.SZ,  China Calxon Group Co., Ltd.',
    name: 'China Calxon Group Co., Ltd.',
    symbol: '000918.SZ',
  },
  {
    value: '000919.SZ',
    label: '000919.SZ,  Jinling Pharmaceutical Company Limited',
    name: 'Jinling Pharmaceutical Company Limited',
    symbol: '000919.SZ',
  },
  {
    value: '000920.SZ',
    label: '000920.SZ,  Vontron Technology Co., Ltd.',
    name: 'Vontron Technology Co., Ltd.',
    symbol: '000920.SZ',
  },
  {
    value: '000922.SZ',
    label:
      '000922.SZ,  Harbin Electric Corporation Jiamusi Electric Machine CO.,Ltd',
    name: 'Harbin Electric Corporation Jiamusi Electric Machine CO.,Ltd',
    symbol: '000922.SZ',
  },
  {
    value: '000923.SZ',
    label: '000923.SZ,  HBIS Resources Co., Ltd.',
    name: 'HBIS Resources Co., Ltd.',
    symbol: '000923.SZ',
  },
  {
    value: '000925.SZ',
    label: '000925.SZ,  UniTTEC Co.,Ltd',
    name: 'UniTTEC Co.,Ltd',
    symbol: '000925.SZ',
  },
  {
    value: '000926.SZ',
    label: '000926.SZ,  Hubei Fuxing Science and Technology Co.,Ltd',
    name: 'Hubei Fuxing Science and Technology Co.,Ltd',
    symbol: '000926.SZ',
  },
  {
    value: '000927.SZ',
    label: '000927.SZ,  China Railway Materials Company Limited',
    name: 'China Railway Materials Company Limited',
    symbol: '000927.SZ',
  },
  {
    value: '000928.SZ',
    label: '000928.SZ,  Sinosteel Engineering & Technology Co., Ltd.',
    name: 'Sinosteel Engineering & Technology Co., Ltd.',
    symbol: '000928.SZ',
  },
  {
    value: '000929.SZ',
    label: '000929.SZ,  Lanzhou Huanghe Enterprise Co., Ltd',
    name: 'Lanzhou Huanghe Enterprise Co., Ltd',
    symbol: '000929.SZ',
  },
  {
    value: '000931.SZ',
    label: '000931.SZ,  Beijing Centergate Technologies (holding) Co., Ltd.',
    name: 'Beijing Centergate Technologies (holding) Co., Ltd.',
    symbol: '000931.SZ',
  },
  {
    value: '000935.SZ',
    label: '000935.SZ,  Sichuan Hexie Shuangma Co., Ltd.',
    name: 'Sichuan Hexie Shuangma Co., Ltd.',
    symbol: '000935.SZ',
  },
  {
    value: '000936.SZ',
    label: '000936.SZ,  Jiangsu Huaxicun Co.,Ltd.',
    name: 'Jiangsu Huaxicun Co.,Ltd.',
    symbol: '000936.SZ',
  },
  {
    value: '000948.SZ',
    label: '000948.SZ,  Yunnan Nantian Electronics Information Co.,Ltd.',
    name: 'Yunnan Nantian Electronics Information Co.,Ltd.',
    symbol: '000948.SZ',
  },
  {
    value: '000949.SZ',
    label: '000949.SZ,  Xinxiang Chemical Fiber Co., Ltd.',
    name: 'Xinxiang Chemical Fiber Co., Ltd.',
    symbol: '000949.SZ',
  },
  {
    value: '000950.SZ',
    label: '000950.SZ,  C.Q. Pharmaceutical Holding Co., Ltd.',
    name: 'C.Q. Pharmaceutical Holding Co., Ltd.',
    symbol: '000950.SZ',
  },
  {
    value: '000952.SZ',
    label: '000952.SZ,  Hubei Guangji Pharmaceutical Co., Ltd.',
    name: 'Hubei Guangji Pharmaceutical Co., Ltd.',
    symbol: '000952.SZ',
  },
  {
    value: '000953.SZ',
    label: '000953.SZ,  Guangxi Hechi Chemical Co., Ltd',
    name: 'Guangxi Hechi Chemical Co., Ltd',
    symbol: '000953.SZ',
  },
  {
    value: '000955.SZ',
    label: '000955.SZ,  Xinglong Holding (Group) Company Ltd.',
    name: 'Xinglong Holding (Group) Company Ltd.',
    symbol: '000955.SZ',
  },
  {
    value: '000957.SZ',
    label: '000957.SZ,  Zhongtong Bus Holding Co., Ltd.',
    name: 'Zhongtong Bus Holding Co., Ltd.',
    symbol: '000957.SZ',
  },
  {
    value: '000962.SZ',
    label: '000962.SZ,  Ningxia Orient Tantalum Industry Co., Ltd.',
    name: 'Ningxia Orient Tantalum Industry Co., Ltd.',
    symbol: '000962.SZ',
  },
  {
    value: '000965.SZ',
    label: '000965.SZ,  Tianjin Tianbao Infrastructure Co., Ltd.',
    name: 'Tianjin Tianbao Infrastructure Co., Ltd.',
    symbol: '000965.SZ',
  },
  {
    value: '000966.SZ',
    label: '000966.SZ,  CHN Energy Changyuan Electric Power Co.,Ltd.',
    name: 'CHN Energy Changyuan Electric Power Co.,Ltd.',
    symbol: '000966.SZ',
  },
  {
    value: '000968.SZ',
    label: '000968.SZ,  Shanxi Blue Flame Holding Company Limited',
    name: 'Shanxi Blue Flame Holding Company Limited',
    symbol: '000968.SZ',
  },
  {
    value: '000969.SZ',
    label: '000969.SZ,  Advanced Technology & Materials Co., Ltd.',
    name: 'Advanced Technology & Materials Co., Ltd.',
    symbol: '000969.SZ',
  },
  {
    value: '000971.SZ',
    label: '000971.SZ,  Gosun Holding Co., Ltd.',
    name: 'Gosun Holding Co., Ltd.',
    symbol: '000971.SZ',
  },
  {
    value: '000972.SZ',
    label: '000972.SZ,  Chalkis Health Industry Co., Ltd',
    name: 'Chalkis Health Industry Co., Ltd',
    symbol: '000972.SZ',
  },
  {
    value: '000973.SZ',
    label: '000973.SZ,  FSPG Hi-Tech CO., Ltd.',
    name: 'FSPG Hi-Tech CO., Ltd.',
    symbol: '000973.SZ',
  },
  {
    value: '000976.SZ',
    label:
      '000976.SZ,  Guangdong Huatie Tongda High-speed Railway Equipment Corporation',
    name: 'Guangdong Huatie Tongda High-speed Railway Equipment Corporation',
    symbol: '000976.SZ',
  },
  {
    value: '000978.SZ',
    label: '000978.SZ,  Guilin Tourism Corporation Limited',
    name: 'Guilin Tourism Corporation Limited',
    symbol: '000978.SZ',
  },
  {
    value: '000980.SZ',
    label: '000980.SZ,  Zotye Automobile Co., Ltd',
    name: 'Zotye Automobile Co., Ltd',
    symbol: '000980.SZ',
  },
  {
    value: '000981.SZ',
    label: '000981.SZ,  Yinyi Co., Ltd.',
    name: 'Yinyi Co., Ltd.',
    symbol: '000981.SZ',
  },
  {
    value: '000982.SZ',
    label: '000982.SZ,  Ningxia Zhongyin Cashmere Co., Ltd.',
    name: 'Ningxia Zhongyin Cashmere Co., Ltd.',
    symbol: '000982.SZ',
  },
  {
    value: '000985.SZ',
    label: '000985.SZ,  Daqing Huake Company Limited',
    name: 'Daqing Huake Company Limited',
    symbol: '000985.SZ',
  },
  {
    value: '000989.SZ',
    label: '000989.SZ,  Jiuzhitang Co., Ltd.',
    name: 'Jiuzhitang Co., Ltd.',
    symbol: '000989.SZ',
  },
  {
    value: '000990.SZ',
    label: '000990.SZ,  Chengzhi Shareholding Co., Ltd.',
    name: 'Chengzhi Shareholding Co., Ltd.',
    symbol: '000990.SZ',
  },
  {
    value: '000993.SZ',
    label: '000993.SZ,  Fujian Mindong Electric Power Limited Company',
    name: 'Fujian Mindong Electric Power Limited Company',
    symbol: '000993.SZ',
  },
  {
    value: '000995.SZ',
    label: '000995.SZ,  Gansu Huangtai Wine-Marketing Industry Co.,Ltd',
    name: 'Gansu Huangtai Wine-Marketing Industry Co.,Ltd',
    symbol: '000995.SZ',
  },
  {
    value: '000996.SZ',
    label: '000996.SZ,  China CIFCO Investment Co., Ltd',
    name: 'China CIFCO Investment Co., Ltd',
    symbol: '000996.SZ',
  },
  {
    value: '001201.SZ',
    label: '001201.SZ,  Dongrui Food Group Co., Ltd.',
    name: 'Dongrui Food Group Co., Ltd.',
    symbol: '001201.SZ',
  },
  {
    value: '001202.SZ',
    label: '001202.SZ,  Guangdong Jushen Logistics Co., Ltd.',
    name: 'Guangdong Jushen Logistics Co., Ltd.',
    symbol: '001202.SZ',
  },
  {
    value: '001203.SZ',
    label: '001203.SZ,  Inner Mongolia Dazhong Mining Co., Ltd.',
    name: 'Inner Mongolia Dazhong Mining Co., Ltd.',
    symbol: '001203.SZ',
  },
  {
    value: '001205.SZ',
    label: '001205.SZ,  Nanjing ShengHang Shipping Co., Ltd.',
    name: 'Nanjing ShengHang Shipping Co., Ltd.',
    symbol: '001205.SZ',
  },
  {
    value: '001206.SZ',
    label: '001206.SZ,  Tianjin Yiyi Hygiene Products Co.,Ltd',
    name: 'Tianjin Yiyi Hygiene Products Co.,Ltd',
    symbol: '001206.SZ',
  },
  {
    value: '001207.SZ',
    label: '001207.SZ,  Shandong Link Science and Technology Co.,Ltd.',
    name: 'Shandong Link Science and Technology Co.,Ltd.',
    symbol: '001207.SZ',
  },
  {
    value: '001208.SZ',
    label: '001208.SZ,  Hunan Valin Wire & Cable Co.,Ltd.',
    name: 'Hunan Valin Wire & Cable Co.,Ltd.',
    symbol: '001208.SZ',
  },
  {
    value: '001211.SZ',
    label: '001211.SZ,  Suncha Technology Co., Ltd.',
    name: 'Suncha Technology Co., Ltd.',
    symbol: '001211.SZ',
  },
  {
    value: '001212.SZ',
    label: '001212.SZ,  Sinostone(Guangdong) Co.,Ltd.',
    name: 'Sinostone(Guangdong) Co.,Ltd.',
    symbol: '001212.SZ',
  },
  {
    value: '001215.SZ',
    label: '001215.SZ,  Zhengzhou Qianweiyangchu Food Co., Ltd.',
    name: 'Zhengzhou Qianweiyangchu Food Co., Ltd.',
    symbol: '001215.SZ',
  },
  {
    value: '001217.SZ',
    label: '001217.SZ,  Anhui Huaertai Chemical Co., Ltd.',
    name: 'Anhui Huaertai Chemical Co., Ltd.',
    symbol: '001217.SZ',
  },
  {
    value: '001218.SZ',
    label: '001218.SZ,  HUNAN RESUN Co., Ltd.',
    name: 'HUNAN RESUN Co., Ltd.',
    symbol: '001218.SZ',
  },
  {
    value: '001219.SZ',
    label: '001219.SZ,  Qingdao Foods CO.,LTD.',
    name: 'Qingdao Foods CO.,LTD.',
    symbol: '001219.SZ',
  },
  {
    value: '001267.SZ',
    label: '001267.SZ,  Hui Lyu Ecological Technology Groups Co.,Ltd.',
    name: 'Hui Lyu Ecological Technology Groups Co.,Ltd.',
    symbol: '001267.SZ',
  },
  {
    value: '001288.SZ',
    label: '001288.SZ,  Sichuan Zigong Conveying Machine Group Co., Ltd.',
    name: 'Sichuan Zigong Conveying Machine Group Co., Ltd.',
    symbol: '001288.SZ',
  },
  {
    value: '001696.SZ',
    label: '001696.SZ,  Chongqing Zongshen Power Machinery Co.,Ltd',
    name: 'Chongqing Zongshen Power Machinery Co.,Ltd',
    symbol: '001696.SZ',
  },
  {
    value: '001872.SZ',
    label: '001872.SZ,  China Merchants Port Group Co., Ltd.',
    name: 'China Merchants Port Group Co., Ltd.',
    symbol: '001872.SZ',
  },
  {
    value: '001896.SZ',
    label: '001896.SZ,  Henan Yuneng Holdings Co.,Ltd.',
    name: 'Henan Yuneng Holdings Co.,Ltd.',
    symbol: '001896.SZ',
  },
  {
    value: '001965.SZ',
    label:
      '001965.SZ,  China Merchants Expressway Network & Technology Holdings Co.,Ltd.',
    name: 'China Merchants Expressway Network & Technology Holdings Co.,Ltd.',
    symbol: '001965.SZ',
  },
  {
    value: '002002.SZ',
    label: '002002.SZ,  Hongda Xingye Co., Ltd.',
    name: 'Hongda Xingye Co., Ltd.',
    symbol: '002002.SZ',
  },
  {
    value: '002003.SZ',
    label: '002003.SZ,  Zhejiang Weixing Industrial Development Co., Ltd.',
    name: 'Zhejiang Weixing Industrial Development Co., Ltd.',
    symbol: '002003.SZ',
  },
  {
    value: '002005.SZ',
    label: '002005.SZ,  Elec-Tech International Co., Ltd.',
    name: 'Elec-Tech International Co., Ltd.',
    symbol: '002005.SZ',
  },
  {
    value: '002006.SZ',
    label: '002006.SZ,  Zhejiang Jinggong Science & Technology Co., Ltd',
    name: 'Zhejiang Jinggong Science & Technology Co., Ltd',
    symbol: '002006.SZ',
  },
  {
    value: '002009.SZ',
    label: '002009.SZ,  Miracle Automation Engineering Co.Ltd',
    name: 'Miracle Automation Engineering Co.Ltd',
    symbol: '002009.SZ',
  },
  {
    value: '002011.SZ',
    label: "002011.SZ,  Zhejiang Dun'an Artificial Environment Co., Ltd",
    name: "Zhejiang Dun'an Artificial Environment Co., Ltd",
    symbol: '002011.SZ',
  },
  {
    value: '002012.SZ',
    label: '002012.SZ,  Zhejiang Kan Specialities Material Co., Ltd.',
    name: 'Zhejiang Kan Specialities Material Co., Ltd.',
    symbol: '002012.SZ',
  },
  {
    value: '002014.SZ',
    label: '002014.SZ,  Huangshan Novel Co.,Ltd',
    name: 'Huangshan Novel Co.,Ltd',
    symbol: '002014.SZ',
  },
  {
    value: '002015.SZ',
    label: '002015.SZ,  GCL Energy Technology Co.,Ltd.',
    name: 'GCL Energy Technology Co.,Ltd.',
    symbol: '002015.SZ',
  },
  {
    value: '002016.SZ',
    label: '002016.SZ,  Guangdong Shirongzhaoye Co., Ltd.',
    name: 'Guangdong Shirongzhaoye Co., Ltd.',
    symbol: '002016.SZ',
  },
  {
    value: '002017.SZ',
    label: '002017.SZ,  Eastcompeace Technology Co.,ltd',
    name: 'Eastcompeace Technology Co.,ltd',
    symbol: '002017.SZ',
  },
  {
    value: '002020.SZ',
    label: '002020.SZ,  Zhejiang Jingxin Pharmaceutical Co., Ltd.',
    name: 'Zhejiang Jingxin Pharmaceutical Co., Ltd.',
    symbol: '002020.SZ',
  },
  {
    value: '002021.SZ',
    label: '002021.SZ,  Zoje Resources Investment Co., Ltd.',
    name: 'Zoje Resources Investment Co., Ltd.',
    symbol: '002021.SZ',
  },
  {
    value: '002022.SZ',
    label: '002022.SZ,  Shanghai Kehua Bio-Engineering Co.,Ltd',
    name: 'Shanghai Kehua Bio-Engineering Co.,Ltd',
    symbol: '002022.SZ',
  },
  {
    value: '002023.SZ',
    label: '002023.SZ,  Sichuan Haite High-tech Co., Ltd.',
    name: 'Sichuan Haite High-tech Co., Ltd.',
    symbol: '002023.SZ',
  },
  {
    value: '002025.SZ',
    label: '002025.SZ,  Guizhou Space Appliance Co., LTD',
    name: 'Guizhou Space Appliance Co., LTD',
    symbol: '002025.SZ',
  },
  {
    value: '002026.SZ',
    label: '002026.SZ,  Shandong Weida Machinery Co., Ltd.',
    name: 'Shandong Weida Machinery Co., Ltd.',
    symbol: '002026.SZ',
  },
  {
    value: '002029.SZ',
    label: '002029.SZ,  Fujian Septwolves Industry Co., Ltd.',
    name: 'Fujian Septwolves Industry Co., Ltd.',
    symbol: '002029.SZ',
  },
  {
    value: '002031.SZ',
    label: '002031.SZ,  Greatoo Intelligent Equipment Inc.',
    name: 'Greatoo Intelligent Equipment Inc.',
    symbol: '002031.SZ',
  },
  {
    value: '002033.SZ',
    label: '002033.SZ,  LiJiang YuLong Tourism Co., LTD.',
    name: 'LiJiang YuLong Tourism Co., LTD.',
    symbol: '002033.SZ',
  },
  {
    value: '002034.SZ',
    label: '002034.SZ,  Wangneng Environment Co., Ltd.',
    name: 'Wangneng Environment Co., Ltd.',
    symbol: '002034.SZ',
  },
  {
    value: '002035.SZ',
    label: '002035.SZ,  Vatti Corporation Limited',
    name: 'Vatti Corporation Limited',
    symbol: '002035.SZ',
  },
  {
    value: '002037.SZ',
    label: '002037.SZ,  Poly Union Chemical Holding Group Co., Ltd.',
    name: 'Poly Union Chemical Holding Group Co., Ltd.',
    symbol: '002037.SZ',
  },
  {
    value: '002039.SZ',
    label: '002039.SZ,  GuiZhou QianYuan Power Co., Ltd.',
    name: 'GuiZhou QianYuan Power Co., Ltd.',
    symbol: '002039.SZ',
  },
  {
    value: '002040.SZ',
    label: '002040.SZ,  Nanjing Port Co., Ltd.',
    name: 'Nanjing Port Co., Ltd.',
    symbol: '002040.SZ',
  },
  {
    value: '002041.SZ',
    label: '002041.SZ,  ShanDongDenghai Seeds Co.,Ltd',
    name: 'ShanDongDenghai Seeds Co.,Ltd',
    symbol: '002041.SZ',
  },
  {
    value: '002042.SZ',
    label: '002042.SZ,  Huafu Fashion Co., Ltd.',
    name: 'Huafu Fashion Co., Ltd.',
    symbol: '002042.SZ',
  },
  {
    value: '002043.SZ',
    label: '002043.SZ,  DeHua TB New Decoration Material Co.,Ltd',
    name: 'DeHua TB New Decoration Material Co.,Ltd',
    symbol: '002043.SZ',
  },
  {
    value: '002045.SZ',
    label: '002045.SZ,  Guoguang Electric Company Limited',
    name: 'Guoguang Electric Company Limited',
    symbol: '002045.SZ',
  },
  {
    value: '002046.SZ',
    label: '002046.SZ,  Sinomach Precision Industry Co.,Ltd.',
    name: 'Sinomach Precision Industry Co.,Ltd.',
    symbol: '002046.SZ',
  },
  {
    value: '002047.SZ',
    label: '002047.SZ,  Shenzhen Bauing Construction Holding Group Co., Ltd.',
    name: 'Shenzhen Bauing Construction Holding Group Co., Ltd.',
    symbol: '002047.SZ',
  },
  {
    value: '002051.SZ',
    label: '002051.SZ,  China CAMC Engineering Co., Ltd.',
    name: 'China CAMC Engineering Co., Ltd.',
    symbol: '002051.SZ',
  },
  {
    value: '002052.SZ',
    label: '002052.SZ,  Shenzhen Coship Electronics Co., Ltd.',
    name: 'Shenzhen Coship Electronics Co., Ltd.',
    symbol: '002052.SZ',
  },
  {
    value: '002053.SZ',
    label: '002053.SZ,  Yunnan Energy Investment Co., Ltd.',
    name: 'Yunnan Energy Investment Co., Ltd.',
    symbol: '002053.SZ',
  },
  {
    value: '002054.SZ',
    label: '002054.SZ,  Dymatic Chemicals,Inc.',
    name: 'Dymatic Chemicals,Inc.',
    symbol: '002054.SZ',
  },
  {
    value: '002055.SZ',
    label: '002055.SZ,  Shenzhen Deren Electronic Co., Ltd.',
    name: 'Shenzhen Deren Electronic Co., Ltd.',
    symbol: '002055.SZ',
  },
  {
    value: '002057.SZ',
    label: '002057.SZ,  Sinosteel New Materials Co., Ltd.',
    name: 'Sinosteel New Materials Co., Ltd.',
    symbol: '002057.SZ',
  },
  {
    value: '002058.SZ',
    label: '002058.SZ,  Shanghai Welltech Automation Co.,Ltd.',
    name: 'Shanghai Welltech Automation Co.,Ltd.',
    symbol: '002058.SZ',
  },
  {
    value: '002059.SZ',
    label: '002059.SZ,  Yunnan Tourism Co., Ltd.',
    name: 'Yunnan Tourism Co., Ltd.',
    symbol: '002059.SZ',
  },
  {
    value: '002060.SZ',
    label: '002060.SZ,  Guangdong No.2 Hydropower Engineering Company, Ltd.',
    name: 'Guangdong No.2 Hydropower Engineering Company, Ltd.',
    symbol: '002060.SZ',
  },
  {
    value: '002061.SZ',
    label: '002061.SZ,  Zhejiang Communications Technology Co., Ltd.',
    name: 'Zhejiang Communications Technology Co., Ltd.',
    symbol: '002061.SZ',
  },
  {
    value: '002062.SZ',
    label: '002062.SZ,  Hongrun Construction Group Co., Ltd.',
    name: 'Hongrun Construction Group Co., Ltd.',
    symbol: '002062.SZ',
  },
  {
    value: '002063.SZ',
    label: '002063.SZ,  YGSOFT Inc.',
    name: 'YGSOFT Inc.',
    symbol: '002063.SZ',
  },
  {
    value: '002066.SZ',
    label: '002066.SZ,  Ruitai Materials Technology Co., Ltd.',
    name: 'Ruitai Materials Technology Co., Ltd.',
    symbol: '002066.SZ',
  },
  {
    value: '002067.SZ',
    label: '002067.SZ,  Zhejiang Jingxing Paper Joint Stock Co., Ltd.',
    name: 'Zhejiang Jingxing Paper Joint Stock Co., Ltd.',
    symbol: '002067.SZ',
  },
  {
    value: '002068.SZ',
    label: '002068.SZ,  Jiangxi Black Cat Carbon Black Inc.,Ltd',
    name: 'Jiangxi Black Cat Carbon Black Inc.,Ltd',
    symbol: '002068.SZ',
  },
  {
    value: '002069.SZ',
    label: '002069.SZ,  Zoneco Group Co., Ltd.',
    name: 'Zoneco Group Co., Ltd.',
    symbol: '002069.SZ',
  },
  {
    value: '002072.SZ',
    label: '002072.SZ,  Kairuide Holding Co.Ltd',
    name: 'Kairuide Holding Co.Ltd',
    symbol: '002072.SZ',
  },
  {
    value: '002073.SZ',
    label: '002073.SZ,  Mesnac Co., Ltd.',
    name: 'Mesnac Co., Ltd.',
    symbol: '002073.SZ',
  },
  {
    value: '002076.SZ',
    label: '002076.SZ,  Cnlight Co.,Ltd',
    name: 'Cnlight Co.,Ltd',
    symbol: '002076.SZ',
  },
  {
    value: '002077.SZ',
    label: '002077.SZ,  Jiangsu Dagang Co., Ltd.',
    name: 'Jiangsu Dagang Co., Ltd.',
    symbol: '002077.SZ',
  },
  {
    value: '002079.SZ',
    label: '002079.SZ,  Suzhou Good-Ark Electronics Co., Ltd.',
    name: 'Suzhou Good-Ark Electronics Co., Ltd.',
    symbol: '002079.SZ',
  },
  {
    value: '002082.SZ',
    label: '002082.SZ,  Wanbangde Pharmaceutical Holding Group Co., Ltd.',
    name: 'Wanbangde Pharmaceutical Holding Group Co., Ltd.',
    symbol: '002082.SZ',
  },
  {
    value: '002083.SZ',
    label: '002083.SZ,  Sunvim Group Co.,Ltd',
    name: 'Sunvim Group Co.,Ltd',
    symbol: '002083.SZ',
  },
  {
    value: '002084.SZ',
    label: '002084.SZ,  Guangzhou Seagull Kitchen and Bath Products Co., Ltd.',
    name: 'Guangzhou Seagull Kitchen and Bath Products Co., Ltd.',
    symbol: '002084.SZ',
  },
  {
    value: '002085.SZ',
    label: '002085.SZ,  Zhejiang Wanfeng Auto Wheel Co., Ltd.',
    name: 'Zhejiang Wanfeng Auto Wheel Co., Ltd.',
    symbol: '002085.SZ',
  },
  {
    value: '002086.SZ',
    label: '002086.SZ,  Shandong Oriental Ocean Sci-Tech Co., Ltd.',
    name: 'Shandong Oriental Ocean Sci-Tech Co., Ltd.',
    symbol: '002086.SZ',
  },
  {
    value: '002087.SZ',
    label: '002087.SZ,  Henan Xinye Textile Co., Ltd.',
    name: 'Henan Xinye Textile Co., Ltd.',
    symbol: '002087.SZ',
  },
  {
    value: '002088.SZ',
    label: '002088.SZ,  Luyang Energy-Saving Materials Co., Ltd.',
    name: 'Luyang Energy-Saving Materials Co., Ltd.',
    symbol: '002088.SZ',
  },
  {
    value: '002089.SZ',
    label: '002089.SZ,  New Sea Union Technology Group Co.,Ltd.',
    name: 'New Sea Union Technology Group Co.,Ltd.',
    symbol: '002089.SZ',
  },
  {
    value: '002090.SZ',
    label: '002090.SZ,  Wiscom System Co., Ltd.',
    name: 'Wiscom System Co., Ltd.',
    symbol: '002090.SZ',
  },
  {
    value: '002091.SZ',
    label: '002091.SZ,  Jiangsu Guotai International Group Co., Ltd.',
    name: 'Jiangsu Guotai International Group Co., Ltd.',
    symbol: '002091.SZ',
  },
  {
    value: '002093.SZ',
    label: '002093.SZ,  Guomai Technologies, Inc.',
    name: 'Guomai Technologies, Inc.',
    symbol: '002093.SZ',
  },
  {
    value: '002094.SZ',
    label: '002094.SZ,  Qingdao Kingking Applied Chemistry Co., Ltd.',
    name: 'Qingdao Kingking Applied Chemistry Co., Ltd.',
    symbol: '002094.SZ',
  },
  {
    value: '002095.SZ',
    label: '002095.SZ,  Zhejiang NetSun Co., Ltd.',
    name: 'Zhejiang NetSun Co., Ltd.',
    symbol: '002095.SZ',
  },
  {
    value: '002096.SZ',
    label: '002096.SZ,  Hunan Nanling Industrial Explosive Materials Co., Ltd.',
    name: 'Hunan Nanling Industrial Explosive Materials Co., Ltd.',
    symbol: '002096.SZ',
  },
  {
    value: '002097.SZ',
    label: '002097.SZ,  Sunward Intelligent Equipment Co., Ltd.',
    name: 'Sunward Intelligent Equipment Co., Ltd.',
    symbol: '002097.SZ',
  },
  {
    value: '002098.SZ',
    label: '002098.SZ,  Fujian SBS Zipper Science&Technology Co., Ltd',
    name: 'Fujian SBS Zipper Science&Technology Co., Ltd',
    symbol: '002098.SZ',
  },
  {
    value: '002100.SZ',
    label: '002100.SZ,  TECON BIOLOGY Co.LTD',
    name: 'TECON BIOLOGY Co.LTD',
    symbol: '002100.SZ',
  },
  {
    value: '002101.SZ',
    label: '002101.SZ,  Guangdong Hongtu Technology (holdings) Co.,Ltd.',
    name: 'Guangdong Hongtu Technology (holdings) Co.,Ltd.',
    symbol: '002101.SZ',
  },
  {
    value: '002102.SZ',
    label: '002102.SZ,  Guanfu Holding Co., Ltd.',
    name: 'Guanfu Holding Co., Ltd.',
    symbol: '002102.SZ',
  },
  {
    value: '002103.SZ',
    label: '002103.SZ,  Guangbo Group Stock Co., Ltd.',
    name: 'Guangbo Group Stock Co., Ltd.',
    symbol: '002103.SZ',
  },
  {
    value: '002104.SZ',
    label: '002104.SZ,  Hengbao Co.,Ltd.',
    name: 'Hengbao Co.,Ltd.',
    symbol: '002104.SZ',
  },
  {
    value: '002105.SZ',
    label: '002105.SZ,  HL Corp (Shenzhen)',
    name: 'HL Corp (Shenzhen)',
    symbol: '002105.SZ',
  },
  {
    value: '002106.SZ',
    label: '002106.SZ,  Shenzhen Laibao Hi-Tech Co., Ltd.',
    name: 'Shenzhen Laibao Hi-Tech Co., Ltd.',
    symbol: '002106.SZ',
  },
  {
    value: '002107.SZ',
    label: '002107.SZ,  Shandong Wohua Pharmaceutical Co., Ltd.',
    name: 'Shandong Wohua Pharmaceutical Co., Ltd.',
    symbol: '002107.SZ',
  },
  {
    value: '002108.SZ',
    label: '002108.SZ,  Cangzhou Mingzhu Plastic Co., Ltd.',
    name: 'Cangzhou Mingzhu Plastic Co., Ltd.',
    symbol: '002108.SZ',
  },
  {
    value: '002109.SZ',
    label: '002109.SZ,  Shaanxi Xinghua Chemistry Co.,Ltd',
    name: 'Shaanxi Xinghua Chemistry Co.,Ltd',
    symbol: '002109.SZ',
  },
  {
    value: '002111.SZ',
    label: '002111.SZ,  Weihai Guangtai Airport Equipment Co.,Ltd',
    name: 'Weihai Guangtai Airport Equipment Co.,Ltd',
    symbol: '002111.SZ',
  },
  {
    value: '002112.SZ',
    label: '002112.SZ,  SAN BIAN SCIENCE& TECHNOLOGY Co., LTD.',
    name: 'SAN BIAN SCIENCE& TECHNOLOGY Co., LTD.',
    symbol: '002112.SZ',
  },
  {
    value: '002113.SZ',
    label:
      '002113.SZ,  Hunan Tianrun Digital Entertainment &Cultural Media Co.,Ltd.',
    name: 'Hunan Tianrun Digital Entertainment &Cultural Media Co.,Ltd.',
    symbol: '002113.SZ',
  },
  {
    value: '002114.SZ',
    label: '002114.SZ,  Yunnan Luoping Zinc&Electricity Co., Ltd.',
    name: 'Yunnan Luoping Zinc&Electricity Co., Ltd.',
    symbol: '002114.SZ',
  },
  {
    value: '002115.SZ',
    label: '002115.SZ,  Sunwave Communications Co.Ltd',
    name: 'Sunwave Communications Co.Ltd',
    symbol: '002115.SZ',
  },
  {
    value: '002116.SZ',
    label: '002116.SZ,  China Haisum Engineering Co., Ltd.',
    name: 'China Haisum Engineering Co., Ltd.',
    symbol: '002116.SZ',
  },
  {
    value: '002117.SZ',
    label: '002117.SZ,  Tungkong Inc.',
    name: 'Tungkong Inc.',
    symbol: '002117.SZ',
  },
  {
    value: '002118.SZ',
    label: '002118.SZ,  Jilin Zixin Pharmaceutical Industrial Co.,Ltd.',
    name: 'Jilin Zixin Pharmaceutical Industrial Co.,Ltd.',
    symbol: '002118.SZ',
  },
  {
    value: '002119.SZ',
    label: '002119.SZ,  Ningbo Kangqiang Electronics Co., Ltd',
    name: 'Ningbo Kangqiang Electronics Co., Ltd',
    symbol: '002119.SZ',
  },
  {
    value: '002121.SZ',
    label: '002121.SZ,  Shenzhen Clou Electronics Co., Ltd.',
    name: 'Shenzhen Clou Electronics Co., Ltd.',
    symbol: '002121.SZ',
  },
  {
    value: '002122.SZ',
    label: '002122.SZ,  Tianma Bearing Group Co.,Ltd',
    name: 'Tianma Bearing Group Co.,Ltd',
    symbol: '002122.SZ',
  },
  {
    value: '002123.SZ',
    label: '002123.SZ,  Montnets Cloud Technology Group Co., Ltd.',
    name: 'Montnets Cloud Technology Group Co., Ltd.',
    symbol: '002123.SZ',
  },
  {
    value: '002125.SZ',
    label: '002125.SZ,  Xiangtan Electrochemical Scientific Co.,Ltd',
    name: 'Xiangtan Electrochemical Scientific Co.,Ltd',
    symbol: '002125.SZ',
  },
  {
    value: '002126.SZ',
    label: '002126.SZ,  Zhejiang Yinlun Machinery Co.,Ltd.',
    name: 'Zhejiang Yinlun Machinery Co.,Ltd.',
    symbol: '002126.SZ',
  },
  {
    value: '002130.SZ',
    label: '002130.SZ,  ShenZhen Woer Heat-Shrinkable Material Co.,Ltd.',
    name: 'ShenZhen Woer Heat-Shrinkable Material Co.,Ltd.',
    symbol: '002130.SZ',
  },
  {
    value: '002132.SZ',
    label: '002132.SZ,  Henan Hengxing Science & Technology Co.,Ltd.',
    name: 'Henan Hengxing Science & Technology Co.,Ltd.',
    symbol: '002132.SZ',
  },
  {
    value: '002133.SZ',
    label: '002133.SZ,  Cosmos Group Co., Ltd.',
    name: 'Cosmos Group Co., Ltd.',
    symbol: '002133.SZ',
  },
  {
    value: '002134.SZ',
    label: '002134.SZ,  Tianjin Printronics Circuit Corporation',
    name: 'Tianjin Printronics Circuit Corporation',
    symbol: '002134.SZ',
  },
  {
    value: '002135.SZ',
    label: '002135.SZ,  Zhejiang Southeast Space Frame Co., Ltd.',
    name: 'Zhejiang Southeast Space Frame Co., Ltd.',
    symbol: '002135.SZ',
  },
  {
    value: '002136.SZ',
    label: '002136.SZ,  Anhui Annada Titanium Industry Co., Ltd.',
    name: 'Anhui Annada Titanium Industry Co., Ltd.',
    symbol: '002136.SZ',
  },
  {
    value: '002137.SZ',
    label: '002137.SZ,  Shenzhen Sea Star Technology Co., Ltd.',
    name: 'Shenzhen Sea Star Technology Co., Ltd.',
    symbol: '002137.SZ',
  },
  {
    value: '002139.SZ',
    label: '002139.SZ,  Shenzhen Topband Co., Ltd.',
    name: 'Shenzhen Topband Co., Ltd.',
    symbol: '002139.SZ',
  },
  {
    value: '002140.SZ',
    label:
      '002140.SZ,  East China Engineering Science and Technology Co., Ltd.',
    name: 'East China Engineering Science and Technology Co., Ltd.',
    symbol: '002140.SZ',
  },
  {
    value: '002141.SZ',
    label: '002141.SZ,  Infund Holding Co., Ltd.',
    name: 'Infund Holding Co., Ltd.',
    symbol: '002141.SZ',
  },
  {
    value: '002144.SZ',
    label: '002144.SZ,  Hongda High-Tech Holding Co.,Ltd.',
    name: 'Hongda High-Tech Holding Co.,Ltd.',
    symbol: '002144.SZ',
  },
  {
    value: '002148.SZ',
    label: '002148.SZ,  Beijing Bewinner Communications Co., Ltd.',
    name: 'Beijing Bewinner Communications Co., Ltd.',
    symbol: '002148.SZ',
  },
  {
    value: '002149.SZ',
    label: '002149.SZ,  Western Metal Materials Co., Ltd.',
    name: 'Western Metal Materials Co., Ltd.',
    symbol: '002149.SZ',
  },
  {
    value: '002150.SZ',
    label: '002150.SZ,  Jiangsu Tongrun Equipment Technology Co.,Ltd',
    name: 'Jiangsu Tongrun Equipment Technology Co.,Ltd',
    symbol: '002150.SZ',
  },
  {
    value: '002154.SZ',
    label: '002154.SZ,  Baoxiniao Holding Co., Ltd.',
    name: 'Baoxiniao Holding Co., Ltd.',
    symbol: '002154.SZ',
  },
  {
    value: '002158.SZ',
    label: '002158.SZ,  Shanghai Hanbell Precise Machinery Co., Ltd.',
    name: 'Shanghai Hanbell Precise Machinery Co., Ltd.',
    symbol: '002158.SZ',
  },
  {
    value: '002159.SZ',
    label: '002159.SZ,  Wuhan Sante Cableway Group Co., Ltd.',
    name: 'Wuhan Sante Cableway Group Co., Ltd.',
    symbol: '002159.SZ',
  },
  {
    value: '002160.SZ',
    label: '002160.SZ,  Jiangsu Alcha Aluminium Group Co., Ltd.',
    name: 'Jiangsu Alcha Aluminium Group Co., Ltd.',
    symbol: '002160.SZ',
  },
  {
    value: '002161.SZ',
    label: '002161.SZ,  Invengo Information Technology Co., Ltd',
    name: 'Invengo Information Technology Co., Ltd',
    symbol: '002161.SZ',
  },
  {
    value: '002162.SZ',
    label: '002162.SZ,  Everjoy Health Group Co., Ltd.',
    name: 'Everjoy Health Group Co., Ltd.',
    symbol: '002162.SZ',
  },
  {
    value: '002163.SZ',
    label: '002163.SZ,  Hainan Development Holdings Nanhai Co., Ltd.',
    name: 'Hainan Development Holdings Nanhai Co., Ltd.',
    symbol: '002163.SZ',
  },
  {
    value: '002164.SZ',
    label: '002164.SZ,  Ningbo Donly Co.,Ltd',
    name: 'Ningbo Donly Co.,Ltd',
    symbol: '002164.SZ',
  },
  {
    value: '002165.SZ',
    label: '002165.SZ,  Hongbaoli Group Corporation, Ltd.',
    name: 'Hongbaoli Group Corporation, Ltd.',
    symbol: '002165.SZ',
  },
  {
    value: '002166.SZ',
    label: '002166.SZ,  Guilin Layn Natural Ingredients Corp.',
    name: 'Guilin Layn Natural Ingredients Corp.',
    symbol: '002166.SZ',
  },
  {
    value: '002167.SZ',
    label: '002167.SZ,  Guangdong Orient Zirconic Ind Sci & Tech Co.,Ltd',
    name: 'Guangdong Orient Zirconic Ind Sci & Tech Co.,Ltd',
    symbol: '002167.SZ',
  },
  {
    value: '002168.SZ',
    label: '002168.SZ,  Shenzhen Hifuture Information Technology Co., Ltd.',
    name: 'Shenzhen Hifuture Information Technology Co., Ltd.',
    symbol: '002168.SZ',
  },
  {
    value: '002169.SZ',
    label: '002169.SZ,  Guangzhou Zhiguang Electric Co., Ltd.',
    name: 'Guangzhou Zhiguang Electric Co., Ltd.',
    symbol: '002169.SZ',
  },
  {
    value: '002170.SZ',
    label: '002170.SZ,  Shenzhen Batian Ecotypic Engineering Co., Ltd.',
    name: 'Shenzhen Batian Ecotypic Engineering Co., Ltd.',
    symbol: '002170.SZ',
  },
  {
    value: '002171.SZ',
    label:
      '002171.SZ,  Anhui Truchum Advanced Materials and Technology Co., Ltd.',
    name: 'Anhui Truchum Advanced Materials and Technology Co., Ltd.',
    symbol: '002171.SZ',
  },
  {
    value: '002172.SZ',
    label: '002172.SZ,  Jiangsu Aoyang Health Industry Co.ltd.',
    name: 'Jiangsu Aoyang Health Industry Co.ltd.',
    symbol: '002172.SZ',
  },
  {
    value: '002173.SZ',
    label: '002173.SZ,  Innovation Medical Management Co., Ltd.',
    name: 'Innovation Medical Management Co., Ltd.',
    symbol: '002173.SZ',
  },
  {
    value: '002175.SZ',
    label:
      '002175.SZ,  Guangxi Oriental Intelligent Manufacturing Technology Co., Ltd.',
    name: 'Guangxi Oriental Intelligent Manufacturing Technology Co., Ltd.',
    symbol: '002175.SZ',
  },
  {
    value: '002176.SZ',
    label: '002176.SZ,  Jiangxi Special Electric Motor Co.,Ltd',
    name: 'Jiangxi Special Electric Motor Co.,Ltd',
    symbol: '002176.SZ',
  },
  {
    value: '002177.SZ',
    label: '002177.SZ,  Guangzhou Kingteller Technology Co.,Ltd.',
    name: 'Guangzhou Kingteller Technology Co.,Ltd.',
    symbol: '002177.SZ',
  },
  {
    value: '002178.SZ',
    label: '002178.SZ,  Shanghai Yanhua Smartech Group Co., Ltd.',
    name: 'Shanghai Yanhua Smartech Group Co., Ltd.',
    symbol: '002178.SZ',
  },
  {
    value: '002179.SZ',
    label: '002179.SZ,  AVIC Jonhon Optronic Technology Co.,Ltd.',
    name: 'AVIC Jonhon Optronic Technology Co.,Ltd.',
    symbol: '002179.SZ',
  },
  {
    value: '002181.SZ',
    label: '002181.SZ,  Guangdong Guangzhou Daily Media Co., Ltd.',
    name: 'Guangdong Guangzhou Daily Media Co., Ltd.',
    symbol: '002181.SZ',
  },
  {
    value: '002182.SZ',
    label: '002182.SZ,  Nanjing Yunhai Special Metals Co., Ltd.',
    name: 'Nanjing Yunhai Special Metals Co., Ltd.',
    symbol: '002182.SZ',
  },
  {
    value: '002184.SZ',
    label: '002184.SZ,  Shanghai Hi-Tech Control System Co., Ltd',
    name: 'Shanghai Hi-Tech Control System Co., Ltd',
    symbol: '002184.SZ',
  },
  {
    value: '002186.SZ',
    label: '002186.SZ,  China Quanjude(Group) Co.,Ltd.',
    name: 'China Quanjude(Group) Co.,Ltd.',
    symbol: '002186.SZ',
  },
  {
    value: '002187.SZ',
    label: '002187.SZ,  Guangzhou Grandbuy Co., Ltd.',
    name: 'Guangzhou Grandbuy Co., Ltd.',
    symbol: '002187.SZ',
  },
  {
    value: '002188.SZ',
    label: '002188.SZ,  Zhongtian Service Co., Ltd.',
    name: 'Zhongtian Service Co., Ltd.',
    symbol: '002188.SZ',
  },
  {
    value: '002189.SZ',
    label: '002189.SZ,  Costar Group Co., Ltd.',
    name: 'Costar Group Co., Ltd.',
    symbol: '002189.SZ',
  },
  {
    value: '002190.SZ',
    label: '002190.SZ,  Sichuan Chengfei Integration Technology Corp.Ltd',
    name: 'Sichuan Chengfei Integration Technology Corp.Ltd',
    symbol: '002190.SZ',
  },
  {
    value: '002192.SZ',
    label: '002192.SZ,  YOUNGY Co.,Ltd.',
    name: 'YOUNGY Co.,Ltd.',
    symbol: '002192.SZ',
  },
  {
    value: '002193.SZ',
    label: '002193.SZ,  Shandong Ruyi Woolen Garment Group Co., Ltd.',
    name: 'Shandong Ruyi Woolen Garment Group Co., Ltd.',
    symbol: '002193.SZ',
  },
  {
    value: '002194.SZ',
    label: '002194.SZ,  Wuhan Fingu Electronic Technology Co., LTD.',
    name: 'Wuhan Fingu Electronic Technology Co., LTD.',
    symbol: '002194.SZ',
  },
  {
    value: '002196.SZ',
    label: '002196.SZ,  Zhejiang Founder Motor Co., Ltd.',
    name: 'Zhejiang Founder Motor Co., Ltd.',
    symbol: '002196.SZ',
  },
  {
    value: '002197.SZ',
    label: '002197.SZ,  SZZT Electronics CO.,LTD',
    name: 'SZZT Electronics CO.,LTD',
    symbol: '002197.SZ',
  },
  {
    value: '002198.SZ',
    label: '002198.SZ,  Guangdong Jiaying Pharmaceutical Co., Ltd',
    name: 'Guangdong Jiaying Pharmaceutical Co., Ltd',
    symbol: '002198.SZ',
  },
  {
    value: '002199.SZ',
    label: '002199.SZ,  Zhejiang East Crystal Electronic Co.,Ltd.',
    name: 'Zhejiang East Crystal Electronic Co.,Ltd.',
    symbol: '002199.SZ',
  },
  {
    value: '002200.SZ',
    label: '002200.SZ,  YCIC Eco-Technology Co.,Ltd.',
    name: 'YCIC Eco-Technology Co.,Ltd.',
    symbol: '002200.SZ',
  },
  {
    value: '002201.SZ',
    label: '002201.SZ,  Jiangsu Amer New Material Co., Ltd.',
    name: 'Jiangsu Amer New Material Co., Ltd.',
    symbol: '002201.SZ',
  },
  {
    value: '002204.SZ',
    label: '002204.SZ,  Dalian Huarui Heavy Industry Group Co., LTD.',
    name: 'Dalian Huarui Heavy Industry Group Co., LTD.',
    symbol: '002204.SZ',
  },
  {
    value: '002205.SZ',
    label: '002205.SZ,  XinJiang GuoTong Pipeline CO.,Ltd',
    name: 'XinJiang GuoTong Pipeline CO.,Ltd',
    symbol: '002205.SZ',
  },
  {
    value: '002206.SZ',
    label: '002206.SZ,  Zhejiang Hailide New Material Co.,Ltd',
    name: 'Zhejiang Hailide New Material Co.,Ltd',
    symbol: '002206.SZ',
  },
  {
    value: '002207.SZ',
    label: '002207.SZ,  Xinjiang Zhundong Petroleum Technology Co., Ltd.',
    name: 'Xinjiang Zhundong Petroleum Technology Co., Ltd.',
    symbol: '002207.SZ',
  },
  {
    value: '002208.SZ',
    label: '002208.SZ,  Hefei Urban Construction Development Co., Ltd',
    name: 'Hefei Urban Construction Development Co., Ltd',
    symbol: '002208.SZ',
  },
  {
    value: '002209.SZ',
    label: '002209.SZ,  Guangzhou Tech-Long Packaging Machinery Co.,Ltd.',
    name: 'Guangzhou Tech-Long Packaging Machinery Co.,Ltd.',
    symbol: '002209.SZ',
  },
  {
    value: '002210.SZ',
    label: '002210.SZ,  Shenzhen Feima International Supply Chain Co., Ltd.',
    name: 'Shenzhen Feima International Supply Chain Co., Ltd.',
    symbol: '002210.SZ',
  },
  {
    value: '002211.SZ',
    label: '002211.SZ,  Shanghai Hongda New Material Co., Ltd.',
    name: 'Shanghai Hongda New Material Co., Ltd.',
    symbol: '002211.SZ',
  },
  {
    value: '002213.SZ',
    label: '002213.SZ,  Shenzhen Dawei Innovation Technology Co., Ltd.',
    name: 'Shenzhen Dawei Innovation Technology Co., Ltd.',
    symbol: '002213.SZ',
  },
  {
    value: '002214.SZ',
    label: '002214.SZ,  Zhe Jiang Dali Technology Co.,Ltd',
    name: 'Zhe Jiang Dali Technology Co.,Ltd',
    symbol: '002214.SZ',
  },
  {
    value: '002215.SZ',
    label: '002215.SZ,  Shenzhen Noposion Agrochemicals Co.,Ltd',
    name: 'Shenzhen Noposion Agrochemicals Co.,Ltd',
    symbol: '002215.SZ',
  },
  {
    value: '002216.SZ',
    label: '002216.SZ,  Sanquan Food Co., Ltd.',
    name: 'Sanquan Food Co., Ltd.',
    symbol: '002216.SZ',
  },
  {
    value: '002217.SZ',
    label: '002217.SZ,  Holitech Technology Co., Ltd.',
    name: 'Holitech Technology Co., Ltd.',
    symbol: '002217.SZ',
  },
  {
    value: '002218.SZ',
    label: '002218.SZ,  SHENZHEN TOPRAYSOLAR Co.,Ltd.',
    name: 'SHENZHEN TOPRAYSOLAR Co.,Ltd.',
    symbol: '002218.SZ',
  },
  {
    value: '002219.SZ',
    label: '002219.SZ,  Hengkang Medical Group Co., Ltd.',
    name: 'Hengkang Medical Group Co., Ltd.',
    symbol: '002219.SZ',
  },
  {
    value: '002222.SZ',
    label: '002222.SZ,  CASTECH Inc.',
    name: 'CASTECH Inc.',
    symbol: '002222.SZ',
  },
  {
    value: '002224.SZ',
    label: '002224.SZ,  Sanlux Co.,Ltd',
    name: 'Sanlux Co.,Ltd',
    symbol: '002224.SZ',
  },
  {
    value: '002225.SZ',
    label: '002225.SZ,  Puyang Refractories Group Co., Ltd.',
    name: 'Puyang Refractories Group Co., Ltd.',
    symbol: '002225.SZ',
  },
  {
    value: '002226.SZ',
    label: '002226.SZ,  Anhui Jiangnan Chemical Industry Co., Ltd.',
    name: 'Anhui Jiangnan Chemical Industry Co., Ltd.',
    symbol: '002226.SZ',
  },
  {
    value: '002227.SZ',
    label: '002227.SZ,  Shenzhen Auto Electric Power Plant Co.,Ltd',
    name: 'Shenzhen Auto Electric Power Plant Co.,Ltd',
    symbol: '002227.SZ',
  },
  {
    value: '002228.SZ',
    label: '002228.SZ,  Xiamen Hexing Packaging Printing Co., Ltd.',
    name: 'Xiamen Hexing Packaging Printing Co., Ltd.',
    symbol: '002228.SZ',
  },
  {
    value: '002229.SZ',
    label: '002229.SZ,  Hongbo Co., Ltd.',
    name: 'Hongbo Co., Ltd.',
    symbol: '002229.SZ',
  },
  {
    value: '002231.SZ',
    label: '002231.SZ,  Allwin Telecommunication Co., Ltd.',
    name: 'Allwin Telecommunication Co., Ltd.',
    symbol: '002231.SZ',
  },
  {
    value: '002232.SZ',
    label: '002232.SZ,  Qiming Information Technology Co.,Ltd',
    name: 'Qiming Information Technology Co.,Ltd',
    symbol: '002232.SZ',
  },
  {
    value: '002234.SZ',
    label: '002234.SZ,  Shandong Minhe Animal Husbandry Co., Ltd.',
    name: 'Shandong Minhe Animal Husbandry Co., Ltd.',
    symbol: '002234.SZ',
  },
  {
    value: '002235.SZ',
    label: '002235.SZ,  Xiamen anne co.ltd',
    name: 'Xiamen anne co.ltd',
    symbol: '002235.SZ',
  },
  {
    value: '002237.SZ',
    label: '002237.SZ,  Shandong Humon Smelting Co., Ltd.',
    name: 'Shandong Humon Smelting Co., Ltd.',
    symbol: '002237.SZ',
  },
  {
    value: '002238.SZ',
    label: '002238.SZ,  Shenzhen Topway Video Communication Co., Ltd',
    name: 'Shenzhen Topway Video Communication Co., Ltd',
    symbol: '002238.SZ',
  },
  {
    value: '002239.SZ',
    label: '002239.SZ,  Aotecar New Energy Technology Co., Ltd.',
    name: 'Aotecar New Energy Technology Co., Ltd.',
    symbol: '002239.SZ',
  },
  {
    value: '002243.SZ',
    label: '002243.SZ,  Shenzhen Leaguer Co., Ltd.',
    name: 'Shenzhen Leaguer Co., Ltd.',
    symbol: '002243.SZ',
  },
  {
    value: '002245.SZ',
    label: '002245.SZ,  Jiangsu Azure Corporation',
    name: 'Jiangsu Azure Corporation',
    symbol: '002245.SZ',
  },
  {
    value: '002246.SZ',
    label: '002246.SZ,  North Chemical Industries Co., Ltd.',
    name: 'North Chemical Industries Co., Ltd.',
    symbol: '002246.SZ',
  },
  {
    value: '002247.SZ',
    label: '002247.SZ,  Zhejiang Juli Culture Development Co.,Ltd.',
    name: 'Zhejiang Juli Culture Development Co.,Ltd.',
    symbol: '002247.SZ',
  },
  {
    value: '002248.SZ',
    label: '002248.SZ,  Weihai Huadong Automation Co., Ltd',
    name: 'Weihai Huadong Automation Co., Ltd',
    symbol: '002248.SZ',
  },
  {
    value: '002251.SZ',
    label: '002251.SZ,  Better Life Commercial Chain Share Co.,Ltd',
    name: 'Better Life Commercial Chain Share Co.,Ltd',
    symbol: '002251.SZ',
  },
  {
    value: '002253.SZ',
    label: '002253.SZ,  Wisesoft Co., Ltd.',
    name: 'Wisesoft Co., Ltd.',
    symbol: '002253.SZ',
  },
  {
    value: '002254.SZ',
    label: '002254.SZ,  Yantai Tayho Advanced Materials Co., Ltd.',
    name: 'Yantai Tayho Advanced Materials Co., Ltd.',
    symbol: '002254.SZ',
  },
  {
    value: '002255.SZ',
    label: '002255.SZ,  Suzhou Hailu Heavy Industry Co.,Ltd',
    name: 'Suzhou Hailu Heavy Industry Co.,Ltd',
    symbol: '002255.SZ',
  },
  {
    value: '002256.SZ',
    label: '002256.SZ,  Shenzhen Sunrise New Energy Co., Ltd.',
    name: 'Shenzhen Sunrise New Energy Co., Ltd.',
    symbol: '002256.SZ',
  },
  {
    value: '002258.SZ',
    label: '002258.SZ,  Lier Chemical Co.,LTD.',
    name: 'Lier Chemical Co.,LTD.',
    symbol: '002258.SZ',
  },
  {
    value: '002259.SZ',
    label: '002259.SZ,  Sichuan Shengda Forestry Industry Co., Ltd',
    name: 'Sichuan Shengda Forestry Industry Co., Ltd',
    symbol: '002259.SZ',
  },
  {
    value: '002261.SZ',
    label: '002261.SZ,  Talkweb Information System Co.,Ltd.',
    name: 'Talkweb Information System Co.,Ltd.',
    symbol: '002261.SZ',
  },
  {
    value: '002262.SZ',
    label: '002262.SZ,  Jiangsu Nhwa Pharmaceutical Co., LTD',
    name: 'Jiangsu Nhwa Pharmaceutical Co., LTD',
    symbol: '002262.SZ',
  },
  {
    value: '002263.SZ',
    label: '002263.SZ,  Zhejiang Great Southeast Corp.Ltd',
    name: 'Zhejiang Great Southeast Corp.Ltd',
    symbol: '002263.SZ',
  },
  {
    value: '002264.SZ',
    label: '002264.SZ,  New Hua Du Supercenter Co.,Ltd.',
    name: 'New Hua Du Supercenter Co.,Ltd.',
    symbol: '002264.SZ',
  },
  {
    value: '002265.SZ',
    label: '002265.SZ,  Yunnan Xiyi Industry Co., Ltd.',
    name: 'Yunnan Xiyi Industry Co., Ltd.',
    symbol: '002265.SZ',
  },
  {
    value: '002267.SZ',
    label: '002267.SZ,  Shaanxi Provincial Natural Gas Co.,Ltd',
    name: 'Shaanxi Provincial Natural Gas Co.,Ltd',
    symbol: '002267.SZ',
  },
  {
    value: '002269.SZ',
    label: '002269.SZ,  Shanghai Metersbonwe Fashion and Accessories Co., Ltd.',
    name: 'Shanghai Metersbonwe Fashion and Accessories Co., Ltd.',
    symbol: '002269.SZ',
  },
  {
    value: '002270.SZ',
    label: '002270.SZ,  Huaming Power Equipment Co.,Ltd',
    name: 'Huaming Power Equipment Co.,Ltd',
    symbol: '002270.SZ',
  },
  {
    value: '002272.SZ',
    label: '002272.SZ,  Sichuan Crun Co., Ltd',
    name: 'Sichuan Crun Co., Ltd',
    symbol: '002272.SZ',
  },
  {
    value: '002274.SZ',
    label: '002274.SZ,  Jiangsu Huachang Chemical Co., Ltd.',
    name: 'Jiangsu Huachang Chemical Co., Ltd.',
    symbol: '002274.SZ',
  },
  {
    value: '002275.SZ',
    label: '002275.SZ,  Guilin Sanjin Pharmaceutical Co., Ltd.',
    name: 'Guilin Sanjin Pharmaceutical Co., Ltd.',
    symbol: '002275.SZ',
  },
  {
    value: '002276.SZ',
    label: '002276.SZ,  Zhejiang Wanma Co., Ltd.',
    name: 'Zhejiang Wanma Co., Ltd.',
    symbol: '002276.SZ',
  },
  {
    value: '002277.SZ',
    label: '002277.SZ,  Hunan Friendship&Apollo Commercial Co.,Ltd.',
    name: 'Hunan Friendship&Apollo Commercial Co.,Ltd.',
    symbol: '002277.SZ',
  },
  {
    value: '002278.SZ',
    label:
      '002278.SZ,  Shanghai SK Petroleum & Chemical Equipment Corporation Ltd.',
    name: 'Shanghai SK Petroleum & Chemical Equipment Corporation Ltd.',
    symbol: '002278.SZ',
  },
  {
    value: '002279.SZ',
    label: '002279.SZ,  Beijing Join-Cheer Software Co., Ltd.',
    name: 'Beijing Join-Cheer Software Co., Ltd.',
    symbol: '002279.SZ',
  },
  {
    value: '002280.SZ',
    label:
      '002280.SZ,  Hangzhou Lianluo Interactive Information Technology Co.,Ltd',
    name: 'Hangzhou Lianluo Interactive Information Technology Co.,Ltd',
    symbol: '002280.SZ',
  },
  {
    value: '002282.SZ',
    label: '002282.SZ,  Bosun Co., Ltd.',
    name: 'Bosun Co., Ltd.',
    symbol: '002282.SZ',
  },
  {
    value: '002283.SZ',
    label: '002283.SZ,  Tianrun Industry Technology Co., Ltd.',
    name: 'Tianrun Industry Technology Co., Ltd.',
    symbol: '002283.SZ',
  },
  {
    value: '002284.SZ',
    label: '002284.SZ,  Zhejiang Asia-Pacific Mechanical & Electronic Co.,Ltd',
    name: 'Zhejiang Asia-Pacific Mechanical & Electronic Co.,Ltd',
    symbol: '002284.SZ',
  },
  {
    value: '002285.SZ',
    label: '002285.SZ,  Shenzhen Worldunion Group Incorporated',
    name: 'Shenzhen Worldunion Group Incorporated',
    symbol: '002285.SZ',
  },
  {
    value: '002286.SZ',
    label: '002286.SZ,  Baolingbao Biology Co.,Ltd.',
    name: 'Baolingbao Biology Co.,Ltd.',
    symbol: '002286.SZ',
  },
  {
    value: '002287.SZ',
    label: '002287.SZ,  Tibet Cheezheng Tibetan Medicine Co., Ltd.',
    name: 'Tibet Cheezheng Tibetan Medicine Co., Ltd.',
    symbol: '002287.SZ',
  },
  {
    value: '002288.SZ',
    label: '002288.SZ,  Guangdong Chaohua Technology Co., Ltd',
    name: 'Guangdong Chaohua Technology Co., Ltd',
    symbol: '002288.SZ',
  },
  {
    value: '002289.SZ',
    label: '002289.SZ,  Shenzhen Success Electronics Co., Ltd',
    name: 'Shenzhen Success Electronics Co., Ltd',
    symbol: '002289.SZ',
  },
  {
    value: '002290.SZ',
    label: '002290.SZ,  Suzhou Hesheng Special Material Co., Ltd.',
    name: 'Suzhou Hesheng Special Material Co., Ltd.',
    symbol: '002290.SZ',
  },
  {
    value: '002291.SZ',
    label: '002291.SZ,  Saturday Co.,Ltd',
    name: 'Saturday Co.,Ltd',
    symbol: '002291.SZ',
  },
  {
    value: '002292.SZ',
    label: '002292.SZ,  Alpha Group',
    name: 'Alpha Group',
    symbol: '002292.SZ',
  },
  {
    value: '002293.SZ',
    label: '002293.SZ,  Luolai Lifestyle Technology Co., Ltd.',
    name: 'Luolai Lifestyle Technology Co., Ltd.',
    symbol: '002293.SZ',
  },
  {
    value: '002295.SZ',
    label: '002295.SZ,  Guangdong JingYi Metal CO.,Ltd',
    name: 'Guangdong JingYi Metal CO.,Ltd',
    symbol: '002295.SZ',
  },
  {
    value: '002296.SZ',
    label: '002296.SZ,  HeNan Splendor Science & Technology Co., Ltd.',
    name: 'HeNan Splendor Science & Technology Co., Ltd.',
    symbol: '002296.SZ',
  },
  {
    value: '002297.SZ',
    label: '002297.SZ,  Hunan Boyun New Materials Co.,Ltd',
    name: 'Hunan Boyun New Materials Co.,Ltd',
    symbol: '002297.SZ',
  },
  {
    value: '002298.SZ',
    label: '002298.SZ,  Anhui Sinonet & Xinlong Science & Technology Co., Ltd.',
    name: 'Anhui Sinonet & Xinlong Science & Technology Co., Ltd.',
    symbol: '002298.SZ',
  },
  {
    value: '002300.SZ',
    label: '002300.SZ,  Fujian Nanping Sun Cable Co., Ltd.',
    name: 'Fujian Nanping Sun Cable Co., Ltd.',
    symbol: '002300.SZ',
  },
  {
    value: '002301.SZ',
    label: '002301.SZ,  Shenzhen Comix Group Co., Ltd.',
    name: 'Shenzhen Comix Group Co., Ltd.',
    symbol: '002301.SZ',
  },
  {
    value: '002302.SZ',
    label: '002302.SZ,  China West Construction Group Co., Ltd',
    name: 'China West Construction Group Co., Ltd',
    symbol: '002302.SZ',
  },
  {
    value: '002305.SZ',
    label: '002305.SZ,  Langold Real Estate Co., Ltd.',
    name: 'Langold Real Estate Co., Ltd.',
    symbol: '002305.SZ',
  },
  {
    value: '002306.SZ',
    label: '002306.SZ,  Cloud Live Technology Group Co.,Ltd.',
    name: 'Cloud Live Technology Group Co.,Ltd.',
    symbol: '002306.SZ',
  },
  {
    value: '002307.SZ',
    label: '002307.SZ,  Xinjiang Beixin Road & Bridge Group Co., Ltd',
    name: 'Xinjiang Beixin Road & Bridge Group Co., Ltd',
    symbol: '002307.SZ',
  },
  {
    value: '002308.SZ',
    label: '002308.SZ,  Vtron Group Co.,Ltd.',
    name: 'Vtron Group Co.,Ltd.',
    symbol: '002308.SZ',
  },
  {
    value: '002309.SZ',
    label: '002309.SZ,  Jiangsu Zhongli Group Co.,Ltd',
    name: 'Jiangsu Zhongli Group Co.,Ltd',
    symbol: '002309.SZ',
  },
  {
    value: '002310.SZ',
    label: '002310.SZ,  Beijing Orient Landscape & Environment Co., Ltd.',
    name: 'Beijing Orient Landscape & Environment Co., Ltd.',
    symbol: '002310.SZ',
  },
  {
    value: '002312.SZ',
    label: '002312.SZ,  Sichuan Development Lomon Co., Ltd.',
    name: 'Sichuan Development Lomon Co., Ltd.',
    symbol: '002312.SZ',
  },
  {
    value: '002313.SZ',
    label: '002313.SZ,  Sunsea AIoT Technology Co., Ltd.',
    name: 'Sunsea AIoT Technology Co., Ltd.',
    symbol: '002313.SZ',
  },
  {
    value: '002314.SZ',
    label: '002314.SZ,  Shenzhen New Nanshan Holding (Group) Co., Ltd.',
    name: 'Shenzhen New Nanshan Holding (Group) Co., Ltd.',
    symbol: '002314.SZ',
  },
  {
    value: '002315.SZ',
    label: '002315.SZ,  Focus Technology Co., Ltd.',
    name: 'Focus Technology Co., Ltd.',
    symbol: '002315.SZ',
  },
  {
    value: '002316.SZ',
    label: '002316.SZ,  Shenzhen Asia Link Technology Development Co.,Ltd.',
    name: 'Shenzhen Asia Link Technology Development Co.,Ltd.',
    symbol: '002316.SZ',
  },
  {
    value: '002317.SZ',
    label: '002317.SZ,  Guangdong Zhongsheng Pharmaceutical Co., Ltd.',
    name: 'Guangdong Zhongsheng Pharmaceutical Co., Ltd.',
    symbol: '002317.SZ',
  },
  {
    value: '002318.SZ',
    label: '002318.SZ,  Zhejiang JIULI Hi-tech Metals Co.,Ltd',
    name: 'Zhejiang JIULI Hi-tech Metals Co.,Ltd',
    symbol: '002318.SZ',
  },
  {
    value: '002319.SZ',
    label: '002319.SZ,  Letong Chemical Co.,LTD',
    name: 'Letong Chemical Co.,LTD',
    symbol: '002319.SZ',
  },
  {
    value: '002320.SZ',
    label: '002320.SZ,  Hainan Strait Shipping Co.,Ltd.',
    name: 'Hainan Strait Shipping Co.,Ltd.',
    symbol: '002320.SZ',
  },
  {
    value: '002321.SZ',
    label: '002321.SZ,  Henan Huaying Agricultural Development Co., Ltd.',
    name: 'Henan Huaying Agricultural Development Co., Ltd.',
    symbol: '002321.SZ',
  },
  {
    value: '002322.SZ',
    label:
      '002322.SZ,  Ningbo Ligong Environment And Energy Technology Co.,Ltd',
    name: 'Ningbo Ligong Environment And Energy Technology Co.,Ltd',
    symbol: '002322.SZ',
  },
  {
    value: '002323.SZ',
    label: '002323.SZ,  Shandong Yabo Technology Co., Ltd',
    name: 'Shandong Yabo Technology Co., Ltd',
    symbol: '002323.SZ',
  },
  {
    value: '002324.SZ',
    label: '002324.SZ,  Shanghai Pret Composites Co., Ltd.',
    name: 'Shanghai Pret Composites Co., Ltd.',
    symbol: '002324.SZ',
  },
  {
    value: '002325.SZ',
    label: '002325.SZ,  Shenzhen Hongtao Group Co.,Ltd.',
    name: 'Shenzhen Hongtao Group Co.,Ltd.',
    symbol: '002325.SZ',
  },
  {
    value: '002326.SZ',
    label: '002326.SZ,  Zhejiang Yongtai Technology Co.,Ltd.',
    name: 'Zhejiang Yongtai Technology Co.,Ltd.',
    symbol: '002326.SZ',
  },
  {
    value: '002327.SZ',
    label: '002327.SZ,  Shenzhen Fuanna Bedding and Furnishing Co.,Ltd',
    name: 'Shenzhen Fuanna Bedding and Furnishing Co.,Ltd',
    symbol: '002327.SZ',
  },
  {
    value: '002328.SZ',
    label: '002328.SZ,  Shanghai Xinpeng Industry Co.,Ltd.',
    name: 'Shanghai Xinpeng Industry Co.,Ltd.',
    symbol: '002328.SZ',
  },
  {
    value: '002329.SZ',
    label: '002329.SZ,  Royal Group Co.,Ltd.',
    name: 'Royal Group Co.,Ltd.',
    symbol: '002329.SZ',
  },
  {
    value: '002330.SZ',
    label: '002330.SZ,  Shandong Delisi Food Co., Ltd.',
    name: 'Shandong Delisi Food Co., Ltd.',
    symbol: '002330.SZ',
  },
  {
    value: '002331.SZ',
    label: '002331.SZ,  Anhui Wantong Technology Co.,Ltd.',
    name: 'Anhui Wantong Technology Co.,Ltd.',
    symbol: '002331.SZ',
  },
  {
    value: '002332.SZ',
    label: '002332.SZ,  Zhejiang Xianju Pharmaceutical Co.,Ltd.',
    name: 'Zhejiang Xianju Pharmaceutical Co.,Ltd.',
    symbol: '002332.SZ',
  },
  {
    value: '002333.SZ',
    label: '002333.SZ,  ZYF Lopsking Aluminum Co., Ltd.',
    name: 'ZYF Lopsking Aluminum Co., Ltd.',
    symbol: '002333.SZ',
  },
  {
    value: '002334.SZ',
    label: '002334.SZ,  Shenzhen INVT Electric Co.,Ltd',
    name: 'Shenzhen INVT Electric Co.,Ltd',
    symbol: '002334.SZ',
  },
  {
    value: '002335.SZ',
    label: '002335.SZ,  Kehua Data Co., Ltd.',
    name: 'Kehua Data Co., Ltd.',
    symbol: '002335.SZ',
  },
  {
    value: '002336.SZ',
    label: '002336.SZ,  Renrenle Commercial Group Co.,Ltd.',
    name: 'Renrenle Commercial Group Co.,Ltd.',
    symbol: '002336.SZ',
  },
  {
    value: '002337.SZ',
    label: '002337.SZ,  Tianjin Saixiang Technology Co.,Ltd',
    name: 'Tianjin Saixiang Technology Co.,Ltd',
    symbol: '002337.SZ',
  },
  {
    value: '002338.SZ',
    label: '002338.SZ,  Changchun UP Optotech Co.,Ltd.',
    name: 'Changchun UP Optotech Co.,Ltd.',
    symbol: '002338.SZ',
  },
  {
    value: '002339.SZ',
    label: '002339.SZ,  Integrated Electronic Systems Lab Co., Ltd.',
    name: 'Integrated Electronic Systems Lab Co., Ltd.',
    symbol: '002339.SZ',
  },
  {
    value: '002341.SZ',
    label: '002341.SZ,  Xinlun New Materials Co., Ltd.',
    name: 'Xinlun New Materials Co., Ltd.',
    symbol: '002341.SZ',
  },
  {
    value: '002342.SZ',
    label: '002342.SZ,  Juli Sling Co., Ltd.',
    name: 'Juli Sling Co., Ltd.',
    symbol: '002342.SZ',
  },
  {
    value: '002343.SZ',
    label: '002343.SZ,  Ciwen Media Co.,Ltd.',
    name: 'Ciwen Media Co.,Ltd.',
    symbol: '002343.SZ',
  },
  {
    value: '002344.SZ',
    label: '002344.SZ,  Haining China Leather Market Co.,Ltd',
    name: 'Haining China Leather Market Co.,Ltd',
    symbol: '002344.SZ',
  },
  {
    value: '002345.SZ',
    label: '002345.SZ,  Guangdong Chj Industry Co.,Ltd.',
    name: 'Guangdong Chj Industry Co.,Ltd.',
    symbol: '002345.SZ',
  },
  {
    value: '002346.SZ',
    label: '002346.SZ,  Shanghai Zhezhong Group Co.,Ltd',
    name: 'Shanghai Zhezhong Group Co.,Ltd',
    symbol: '002346.SZ',
  },
  {
    value: '002347.SZ',
    label: '002347.SZ,  Taier Heavy Industry Co., Ltd.',
    name: 'Taier Heavy Industry Co., Ltd.',
    symbol: '002347.SZ',
  },
  {
    value: '002348.SZ',
    label: '002348.SZ,  Goldlok Holdings(Guangdong) Co.,Ltd.',
    name: 'Goldlok Holdings(Guangdong) Co.,Ltd.',
    symbol: '002348.SZ',
  },
  {
    value: '002349.SZ',
    label: '002349.SZ,  Jinghua Pharmaceutical Group Co., Ltd.',
    name: 'Jinghua Pharmaceutical Group Co., Ltd.',
    symbol: '002349.SZ',
  },
  {
    value: '002350.SZ',
    label: '002350.SZ,  Beijing Creative Distribution Automation Co., Ltd.',
    name: 'Beijing Creative Distribution Automation Co., Ltd.',
    symbol: '002350.SZ',
  },
  {
    value: '002351.SZ',
    label: '002351.SZ,  Edifier Technology Co., Ltd.',
    name: 'Edifier Technology Co., Ltd.',
    symbol: '002351.SZ',
  },
  {
    value: '002354.SZ',
    label: '002354.SZ,  Tianyu Digital Technology (Dalian) Group Co.,Ltd.',
    name: 'Tianyu Digital Technology (Dalian) Group Co.,Ltd.',
    symbol: '002354.SZ',
  },
  {
    value: '002355.SZ',
    label:
      '002355.SZ,  Xingmin Intelligent Transportation Systems (Group) Co., Ltd.',
    name: 'Xingmin Intelligent Transportation Systems (Group) Co., Ltd.',
    symbol: '002355.SZ',
  },
  {
    value: '002356.SZ',
    label: '002356.SZ,  Shenzhen Hemei Group Co.,LTD.',
    name: 'Shenzhen Hemei Group Co.,LTD.',
    symbol: '002356.SZ',
  },
  {
    value: '002357.SZ',
    label: '002357.SZ,  Sichuan Fulin Transportation Group Co., Ltd.',
    name: 'Sichuan Fulin Transportation Group Co., Ltd.',
    symbol: '002357.SZ',
  },
  {
    value: '002358.SZ',
    label: '002358.SZ,  Henan Senyuan Electric Co., Ltd.',
    name: 'Henan Senyuan Electric Co., Ltd.',
    symbol: '002358.SZ',
  },
  {
    value: '002360.SZ',
    label: '002360.SZ,  Shanxi Tond Chemical Co., Ltd.',
    name: 'Shanxi Tond Chemical Co., Ltd.',
    symbol: '002360.SZ',
  },
  {
    value: '002361.SZ',
    label: '002361.SZ,  Anhui Shenjian New Materials Co.,Ltd',
    name: 'Anhui Shenjian New Materials Co.,Ltd',
    symbol: '002361.SZ',
  },
  {
    value: '002362.SZ',
    label: '002362.SZ,  Hanwang Technology Co.,Ltd.',
    name: 'Hanwang Technology Co.,Ltd.',
    symbol: '002362.SZ',
  },
  {
    value: '002363.SZ',
    label: '002363.SZ,  Shandong Longji Machinery Co.,Ltd',
    name: 'Shandong Longji Machinery Co.,Ltd',
    symbol: '002363.SZ',
  },
  {
    value: '002364.SZ',
    label: '002364.SZ,  Hangzhou Zhongheng Electric Co., Ltd',
    name: 'Hangzhou Zhongheng Electric Co., Ltd',
    symbol: '002364.SZ',
  },
  {
    value: '002365.SZ',
    label: '002365.SZ,  Qianjiang Yongan Pharmaceutical Co., Ltd.',
    name: 'Qianjiang Yongan Pharmaceutical Co., Ltd.',
    symbol: '002365.SZ',
  },
  {
    value: '002366.SZ',
    label: '002366.SZ,  Taihai Manoir Nuclear Equipment Co., Ltd.',
    name: 'Taihai Manoir Nuclear Equipment Co., Ltd.',
    symbol: '002366.SZ',
  },
  {
    value: '002369.SZ',
    label: '002369.SZ,  Shenzhen Zowee Technology Co., Ltd.',
    name: 'Shenzhen Zowee Technology Co., Ltd.',
    symbol: '002369.SZ',
  },
  {
    value: '002370.SZ',
    label: '002370.SZ,  Zhejiang Yatai Pharmaceutical Co., Ltd.',
    name: 'Zhejiang Yatai Pharmaceutical Co., Ltd.',
    symbol: '002370.SZ',
  },
  {
    value: '002374.SZ',
    label: '002374.SZ,  Shandong Chiway Industry Development Co.,Ltd.',
    name: 'Shandong Chiway Industry Development Co.,Ltd.',
    symbol: '002374.SZ',
  },
  {
    value: '002375.SZ',
    label: '002375.SZ,  Zhejiang Yasha Decoration Co.,Ltd',
    name: 'Zhejiang Yasha Decoration Co.,Ltd',
    symbol: '002375.SZ',
  },
  {
    value: '002376.SZ',
    label: '002376.SZ,  Shandong New Beiyang Information Technology Co., Ltd.',
    name: 'Shandong New Beiyang Information Technology Co., Ltd.',
    symbol: '002376.SZ',
  },
  {
    value: '002377.SZ',
    label: '002377.SZ,  Hubei Guochuang Hi-tech Material Co.,Ltd',
    name: 'Hubei Guochuang Hi-tech Material Co.,Ltd',
    symbol: '002377.SZ',
  },
  {
    value: '002378.SZ',
    label: '002378.SZ,  Chongyi Zhangyuan Tungsten Co., Ltd.',
    name: 'Chongyi Zhangyuan Tungsten Co., Ltd.',
    symbol: '002378.SZ',
  },
  {
    value: '002379.SZ',
    label:
      '002379.SZ,  Shandong Hongchuang Aluminum Industry Holding Company Limited',
    name: 'Shandong Hongchuang Aluminum Industry Holding Company Limited',
    symbol: '002379.SZ',
  },
  {
    value: '002380.SZ',
    label: '002380.SZ,  Nanjing Sciyon Wisdom Technology Group Co., Ltd.',
    name: 'Nanjing Sciyon Wisdom Technology Group Co., Ltd.',
    symbol: '002380.SZ',
  },
  {
    value: '002381.SZ',
    label: '002381.SZ,  Zhejiang Double Arrow Rubber Co., Ltd.',
    name: 'Zhejiang Double Arrow Rubber Co., Ltd.',
    symbol: '002381.SZ',
  },
  {
    value: '002383.SZ',
    label: '002383.SZ,  Beijing UniStrong Science&Technology CO.,LTD',
    name: 'Beijing UniStrong Science&Technology CO.,LTD',
    symbol: '002383.SZ',
  },
  {
    value: '002386.SZ',
    label: '002386.SZ,  Yibin Tianyuan Group Co., Ltd.',
    name: 'Yibin Tianyuan Group Co., Ltd.',
    symbol: '002386.SZ',
  },
  {
    value: '002387.SZ',
    label: '002387.SZ,  Visionox Technology Inc.',
    name: 'Visionox Technology Inc.',
    symbol: '002387.SZ',
  },
  {
    value: '002388.SZ',
    label:
      '002388.SZ,  Shenzhen Sunyes Electronic Manufacturing Holding Co., Ltd.',
    name: 'Shenzhen Sunyes Electronic Manufacturing Holding Co., Ltd.',
    symbol: '002388.SZ',
  },
  {
    value: '002389.SZ',
    label: '002389.SZ,  Aerospace CH UAV Co.,Ltd',
    name: 'Aerospace CH UAV Co.,Ltd',
    symbol: '002389.SZ',
  },
  {
    value: '002390.SZ',
    label: '002390.SZ,  Guizhou Xinbang Pharmaceutical Co., Ltd.',
    name: 'Guizhou Xinbang Pharmaceutical Co., Ltd.',
    symbol: '002390.SZ',
  },
  {
    value: '002391.SZ',
    label: '002391.SZ,  Jiangsu Changqing Agrochemical Co., Ltd.',
    name: 'Jiangsu Changqing Agrochemical Co., Ltd.',
    symbol: '002391.SZ',
  },
  {
    value: '002392.SZ',
    label: '002392.SZ,  Beijing Lier High-temperature Materials Co.,Ltd.',
    name: 'Beijing Lier High-temperature Materials Co.,Ltd.',
    symbol: '002392.SZ',
  },
  {
    value: '002393.SZ',
    label: '002393.SZ,  Tianjin Lisheng Pharmaceutical Co.,Ltd.',
    name: 'Tianjin Lisheng Pharmaceutical Co.,Ltd.',
    symbol: '002393.SZ',
  },
  {
    value: '002394.SZ',
    label: '002394.SZ,  Jiangsu Lianfa Textile Co.,Ltd',
    name: 'Jiangsu Lianfa Textile Co.,Ltd',
    symbol: '002394.SZ',
  },
  {
    value: '002395.SZ',
    label: '002395.SZ,  Wuxi Double Elephant Micro Fibre Material Co.,Ltd',
    name: 'Wuxi Double Elephant Micro Fibre Material Co.,Ltd',
    symbol: '002395.SZ',
  },
  {
    value: '002396.SZ',
    label: '002396.SZ,  Fujian Star-net Communication Co., LTD.',
    name: 'Fujian Star-net Communication Co., LTD.',
    symbol: '002396.SZ',
  },
  {
    value: '002397.SZ',
    label: '002397.SZ,  Hunan Mendale Hometextile Co.,Ltd',
    name: 'Hunan Mendale Hometextile Co.,Ltd',
    symbol: '002397.SZ',
  },
  {
    value: '002398.SZ',
    label: '002398.SZ,  Lets Holdings Group Co., Ltd.',
    name: 'Lets Holdings Group Co., Ltd.',
    symbol: '002398.SZ',
  },
  {
    value: '002400.SZ',
    label: '002400.SZ,  Guangdong Advertising Group Co.,Ltd',
    name: 'Guangdong Advertising Group Co.,Ltd',
    symbol: '002400.SZ',
  },
  {
    value: '002401.SZ',
    label: '002401.SZ,  COSCO SHIPPING Technology Co., Ltd.',
    name: 'COSCO SHIPPING Technology Co., Ltd.',
    symbol: '002401.SZ',
  },
  {
    value: '002402.SZ',
    label: '002402.SZ,  Shenzhen H&T Intelligent Control Co., Ltd.',
    name: 'Shenzhen H&T Intelligent Control Co., Ltd.',
    symbol: '002402.SZ',
  },
  {
    value: '002403.SZ',
    label: '002403.SZ,  Aishida Co.,Ltd',
    name: 'Aishida Co.,Ltd',
    symbol: '002403.SZ',
  },
  {
    value: '002404.SZ',
    label: '002404.SZ,  Zhejiang Jiaxin Silk Corp., Ltd.',
    name: 'Zhejiang Jiaxin Silk Corp., Ltd.',
    symbol: '002404.SZ',
  },
  {
    value: '002406.SZ',
    label: '002406.SZ,  Xuchang Yuandong Drive Shaft Co.Ltd',
    name: 'Xuchang Yuandong Drive Shaft Co.Ltd',
    symbol: '002406.SZ',
  },
  {
    value: '002407.SZ',
    label: '002407.SZ,  Do-Fluoride New Materials Co., Ltd.',
    name: 'Do-Fluoride New Materials Co., Ltd.',
    symbol: '002407.SZ',
  },
  {
    value: '002408.SZ',
    label: '002408.SZ,  Zibo Qixiang Tengda Chemical Co., Ltd',
    name: 'Zibo Qixiang Tengda Chemical Co., Ltd',
    symbol: '002408.SZ',
  },
  {
    value: '002411.SZ',
    label: '002411.SZ,  YanAn Bicon Pharmaceutical Listed Company',
    name: 'YanAn Bicon Pharmaceutical Listed Company',
    symbol: '002411.SZ',
  },
  {
    value: '002412.SZ',
    label: '002412.SZ,  Hunan Hansen Pharmaceutical Co., Ltd.',
    name: 'Hunan Hansen Pharmaceutical Co., Ltd.',
    symbol: '002412.SZ',
  },
  {
    value: '002413.SZ',
    label: '002413.SZ,  Jiangsu Leike Defense Technology Co., Ltd.',
    name: 'Jiangsu Leike Defense Technology Co., Ltd.',
    symbol: '002413.SZ',
  },
  {
    value: '002415.SZ',
    label: '002415.SZ,  Hangzhou Hikvision Digital Technology Co., Ltd.',
    name: 'Hangzhou Hikvision Digital Technology Co., Ltd.',
    symbol: '002415.SZ',
  },
  {
    value: '002416.SZ',
    label: '002416.SZ,  Shenzhen Aisidi CO.,LTD.',
    name: 'Shenzhen Aisidi CO.,LTD.',
    symbol: '002416.SZ',
  },
  {
    value: '002417.SZ',
    label: '002417.SZ,  SUNA Co.,Ltd',
    name: 'SUNA Co.,Ltd',
    symbol: '002417.SZ',
  },
  {
    value: '002418.SZ',
    label: '002418.SZ,  Zhe Jiang Kangsheng Co.,Ltd.',
    name: 'Zhe Jiang Kangsheng Co.,Ltd.',
    symbol: '002418.SZ',
  },
  {
    value: '002419.SZ',
    label: '002419.SZ,  Rainbow Digital Commercial Co., Ltd.',
    name: 'Rainbow Digital Commercial Co., Ltd.',
    symbol: '002419.SZ',
  },
  {
    value: '002420.SZ',
    label: '002420.SZ,  Guangzhou Echom Sci.&Tech.Co.,Ltd',
    name: 'Guangzhou Echom Sci.&Tech.Co.,Ltd',
    symbol: '002420.SZ',
  },
  {
    value: '002421.SZ',
    label: '002421.SZ,  Shenzhen Das Intellitech Co., Ltd.',
    name: 'Shenzhen Das Intellitech Co., Ltd.',
    symbol: '002421.SZ',
  },
  {
    value: '002423.SZ',
    label: '002423.SZ,  COFCO Capital Holdings Co., Ltd.',
    name: 'COFCO Capital Holdings Co., Ltd.',
    symbol: '002423.SZ',
  },
  {
    value: '002425.SZ',
    label: '002425.SZ,  Kaiser (China) Culture Co., LTD',
    name: 'Kaiser (China) Culture Co., LTD',
    symbol: '002425.SZ',
  },
  {
    value: '002426.SZ',
    label: '002426.SZ,  Suzhou Victory Precision Manufacture Co., Ltd.',
    name: 'Suzhou Victory Precision Manufacture Co., Ltd.',
    symbol: '002426.SZ',
  },
  {
    value: '002427.SZ',
    label: '002427.SZ,  Zhejiang Unifull Industrial Fibre Co., Ltd.',
    name: 'Zhejiang Unifull Industrial Fibre Co., Ltd.',
    symbol: '002427.SZ',
  },
  {
    value: '002428.SZ',
    label: '002428.SZ,  Yunnan Lincang Xinyuan Germanium Industry Co.,LTD',
    name: 'Yunnan Lincang Xinyuan Germanium Industry Co.,LTD',
    symbol: '002428.SZ',
  },
  {
    value: '002431.SZ',
    label: '002431.SZ,  Palm Eco-Town Development Co., Ltd',
    name: 'Palm Eco-Town Development Co., Ltd',
    symbol: '002431.SZ',
  },
  {
    value: '002432.SZ',
    label: '002432.SZ,  Andon Health Co., Ltd.',
    name: 'Andon Health Co., Ltd.',
    symbol: '002432.SZ',
  },
  {
    value: '002433.SZ',
    label: '002433.SZ,  Guangdong Taiantang Pharmaceutical Co., Ltd.',
    name: 'Guangdong Taiantang Pharmaceutical Co., Ltd.',
    symbol: '002433.SZ',
  },
  {
    value: '002434.SZ',
    label: '002434.SZ,  Zhejiang Wanliyang Co., Ltd.',
    name: 'Zhejiang Wanliyang Co., Ltd.',
    symbol: '002434.SZ',
  },
  {
    value: '002435.SZ',
    label: '002435.SZ,  Changjiang Runfa Health Industry Co., Ltd.',
    name: 'Changjiang Runfa Health Industry Co., Ltd.',
    symbol: '002435.SZ',
  },
  {
    value: '002436.SZ',
    label: '002436.SZ,  Shenzhen Fastprint Circuit Tech Co., Ltd.',
    name: 'Shenzhen Fastprint Circuit Tech Co., Ltd.',
    symbol: '002436.SZ',
  },
  {
    value: '002437.SZ',
    label: '002437.SZ,  Harbin Gloria Pharmaceuticals Co., Ltd',
    name: 'Harbin Gloria Pharmaceuticals Co., Ltd',
    symbol: '002437.SZ',
  },
  {
    value: '002438.SZ',
    label: '002438.SZ,  Jiangsu Shentong Valve Co., Ltd.',
    name: 'Jiangsu Shentong Valve Co., Ltd.',
    symbol: '002438.SZ',
  },
  {
    value: '002439.SZ',
    label: '002439.SZ,  Venustech Group Inc.',
    name: 'Venustech Group Inc.',
    symbol: '002439.SZ',
  },
  {
    value: '002440.SZ',
    label: '002440.SZ,  Zhejiang Runtu Co., Ltd.',
    name: 'Zhejiang Runtu Co., Ltd.',
    symbol: '002440.SZ',
  },
  {
    value: '002441.SZ',
    label: '002441.SZ,  Zhongyeda Electric Co., Ltd.',
    name: 'Zhongyeda Electric Co., Ltd.',
    symbol: '002441.SZ',
  },
  {
    value: '002442.SZ',
    label: '002442.SZ,  Longxing Chemical Stock Co., Ltd.',
    name: 'Longxing Chemical Stock Co., Ltd.',
    symbol: '002442.SZ',
  },
  {
    value: '002443.SZ',
    label: '002443.SZ,  Zhejiang Kingland Pipeline and Technologies Co.,Ltd.',
    name: 'Zhejiang Kingland Pipeline and Technologies Co.,Ltd.',
    symbol: '002443.SZ',
  },
  {
    value: '002445.SZ',
    label: '002445.SZ,  Jiangyin Zhongnan Heavy Industries Co.,Ltd',
    name: 'Jiangyin Zhongnan Heavy Industries Co.,Ltd',
    symbol: '002445.SZ',
  },
  {
    value: '002446.SZ',
    label: '002446.SZ,  Guangdong Shenglu Telecommunication Tech. Co., Ltd.',
    name: 'Guangdong Shenglu Telecommunication Tech. Co., Ltd.',
    symbol: '002446.SZ',
  },
  {
    value: '002448.SZ',
    label: '002448.SZ,  ZYNP Corporation',
    name: 'ZYNP Corporation',
    symbol: '002448.SZ',
  },
  {
    value: '002449.SZ',
    label: '002449.SZ,  Foshan NationStar Optoelectronics Co.,Ltd',
    name: 'Foshan NationStar Optoelectronics Co.,Ltd',
    symbol: '002449.SZ',
  },
  {
    value: '002451.SZ',
    label: '002451.SZ,  Shanghai Morn Electric Equipment Co., Ltd.',
    name: 'Shanghai Morn Electric Equipment Co., Ltd.',
    symbol: '002451.SZ',
  },
  {
    value: '002452.SZ',
    label: '002452.SZ,  Changgao Electric Group Co., Ltd.',
    name: 'Changgao Electric Group Co., Ltd.',
    symbol: '002452.SZ',
  },
  {
    value: '002453.SZ',
    label: '002453.SZ,  Great Chinasoft Technology Co.,Ltd.',
    name: 'Great Chinasoft Technology Co.,Ltd.',
    symbol: '002453.SZ',
  },
  {
    value: '002454.SZ',
    label: '002454.SZ,  Songz Automobile Air Conditioning Co., Ltd.',
    name: 'Songz Automobile Air Conditioning Co., Ltd.',
    symbol: '002454.SZ',
  },
  {
    value: '002455.SZ',
    label: '002455.SZ,  Jiangsu Baichuan High-Tech New Materials Co., Ltd',
    name: 'Jiangsu Baichuan High-Tech New Materials Co., Ltd',
    symbol: '002455.SZ',
  },
  {
    value: '002457.SZ',
    label: '002457.SZ,  Ningxia Qinglong Pipes Industry Group Co., Ltd.',
    name: 'Ningxia Qinglong Pipes Industry Group Co., Ltd.',
    symbol: '002457.SZ',
  },
  {
    value: '002461.SZ',
    label: '002461.SZ,  Guangzhou Zhujiang Brewery Co., Ltd',
    name: 'Guangzhou Zhujiang Brewery Co., Ltd',
    symbol: '002461.SZ',
  },
  {
    value: '002462.SZ',
    label: '002462.SZ,  Cachet Pharmaceutical Co., Ltd.',
    name: 'Cachet Pharmaceutical Co., Ltd.',
    symbol: '002462.SZ',
  },
  {
    value: '002466.SZ',
    label: '002466.SZ,  Tianqi Lithium Corporation',
    name: 'Tianqi Lithium Corporation',
    symbol: '002466.SZ',
  },
  {
    value: '002467.SZ',
    label: '002467.SZ,  NET263 Ltd.',
    name: 'NET263 Ltd.',
    symbol: '002467.SZ',
  },
  {
    value: '002468.SZ',
    label: '002468.SZ,  STO Express Co.,Ltd',
    name: 'STO Express Co.,Ltd',
    symbol: '002468.SZ',
  },
  {
    value: '002469.SZ',
    label: '002469.SZ,  Shandong Sunway Chemical Group Co., Ltd.',
    name: 'Shandong Sunway Chemical Group Co., Ltd.',
    symbol: '002469.SZ',
  },
  {
    value: '002470.SZ',
    label: '002470.SZ,  Kingenta Ecological Engineering Group Co., Ltd.',
    name: 'Kingenta Ecological Engineering Group Co., Ltd.',
    symbol: '002470.SZ',
  },
  {
    value: '002471.SZ',
    label: '002471.SZ,  Jiangsu Zhongchao Holding Co., Ltd.',
    name: 'Jiangsu Zhongchao Holding Co., Ltd.',
    symbol: '002471.SZ',
  },
  {
    value: '002472.SZ',
    label: '002472.SZ,  Zhejiang Shuanghuan Driveline Co., Ltd.',
    name: 'Zhejiang Shuanghuan Driveline Co., Ltd.',
    symbol: '002472.SZ',
  },
  {
    value: '002474.SZ',
    label: '002474.SZ,  Fujian Rongji Software Co., Ltd.',
    name: 'Fujian Rongji Software Co., Ltd.',
    symbol: '002474.SZ',
  },
  {
    value: '002476.SZ',
    label: '002476.SZ,  Shandong Polymer Biochemicals Co., Ltd.',
    name: 'Shandong Polymer Biochemicals Co., Ltd.',
    symbol: '002476.SZ',
  },
  {
    value: '002478.SZ',
    label: '002478.SZ,  Jiangsu Changbao Steeltube Co.,Ltd',
    name: 'Jiangsu Changbao Steeltube Co.,Ltd',
    symbol: '002478.SZ',
  },
  {
    value: '002479.SZ',
    label:
      '002479.SZ,  Zhejiang Fuchunjiang Environmental Thermoelectric Co.,LTD.',
    name: 'Zhejiang Fuchunjiang Environmental Thermoelectric Co.,LTD.',
    symbol: '002479.SZ',
  },
  {
    value: '002480.SZ',
    label: '002480.SZ,  Chengdu Xinzhu Road&Bridge Machinery Co.,LTD',
    name: 'Chengdu Xinzhu Road&Bridge Machinery Co.,LTD',
    symbol: '002480.SZ',
  },
  {
    value: '002481.SZ',
    label: '002481.SZ,  Yantai Shuangta Food Co., Ltd.',
    name: 'Yantai Shuangta Food Co., Ltd.',
    symbol: '002481.SZ',
  },
  {
    value: '002482.SZ',
    label: '002482.SZ,  Shenzhen Grandland Group Co., Ltd.',
    name: 'Shenzhen Grandland Group Co., Ltd.',
    symbol: '002482.SZ',
  },
  {
    value: '002483.SZ',
    label: '002483.SZ,  Jiangsu Rainbow Heavy Industries Co., Ltd.',
    name: 'Jiangsu Rainbow Heavy Industries Co., Ltd.',
    symbol: '002483.SZ',
  },
  {
    value: '002484.SZ',
    label: '002484.SZ,  Nantong Jianghai Capacitor Co. Ltd.',
    name: 'Nantong Jianghai Capacitor Co. Ltd.',
    symbol: '002484.SZ',
  },
  {
    value: '002485.SZ',
    label: '002485.SZ,  Cedar Development Co.,Ltd.',
    name: 'Cedar Development Co.,Ltd.',
    symbol: '002485.SZ',
  },
  {
    value: '002486.SZ',
    label: '002486.SZ,  Shanghai Challenge Textile Co.,Ltd.',
    name: 'Shanghai Challenge Textile Co.,Ltd.',
    symbol: '002486.SZ',
  },
  {
    value: '002487.SZ',
    label: '002487.SZ,  Dajin Heavy Industry Corporation',
    name: 'Dajin Heavy Industry Corporation',
    symbol: '002487.SZ',
  },
  {
    value: '002488.SZ',
    label: '002488.SZ,  Zhejiang Jingu Company Limited',
    name: 'Zhejiang Jingu Company Limited',
    symbol: '002488.SZ',
  },
  {
    value: '002489.SZ',
    label: '002489.SZ,  Yotrio Group Co., Ltd.',
    name: 'Yotrio Group Co., Ltd.',
    symbol: '002489.SZ',
  },
  {
    value: '002490.SZ',
    label: '002490.SZ,  Shandong Molong Petroleum Machinery Company Limited',
    name: 'Shandong Molong Petroleum Machinery Company Limited',
    symbol: '002490.SZ',
  },
  {
    value: '002491.SZ',
    label: '002491.SZ,  Tongding Interconnection Information Co., Ltd.',
    name: 'Tongding Interconnection Information Co., Ltd.',
    symbol: '002491.SZ',
  },
  {
    value: '002492.SZ',
    label:
      '002492.SZ,  Zhuhai Winbase International Chemical Tank Terminal Co.,Ltd',
    name: 'Zhuhai Winbase International Chemical Tank Terminal Co.,Ltd',
    symbol: '002492.SZ',
  },
  {
    value: '002494.SZ',
    label: '002494.SZ,  Huasi Holding Company Limited',
    name: 'Huasi Holding Company Limited',
    symbol: '002494.SZ',
  },
  {
    value: '002495.SZ',
    label: '002495.SZ,  Guangdong Jialong Food Co., Ltd.',
    name: 'Guangdong Jialong Food Co., Ltd.',
    symbol: '002495.SZ',
  },
  {
    value: '002496.SZ',
    label: '002496.SZ,  Jiangsu Huifeng Bio Agriculture Co., Ltd.',
    name: 'Jiangsu Huifeng Bio Agriculture Co., Ltd.',
    symbol: '002496.SZ',
  },
  {
    value: '002499.SZ',
    label: '002499.SZ,  Kelin Environmental Protection Equipment, Inc.',
    name: 'Kelin Environmental Protection Equipment, Inc.',
    symbol: '002499.SZ',
  },
  {
    value: '002501.SZ',
    label: '002501.SZ,  Jilin Liyuan Precision Manufacturing Co., Ltd.',
    name: 'Jilin Liyuan Precision Manufacturing Co., Ltd.',
    symbol: '002501.SZ',
  },
  {
    value: '002502.SZ',
    label: '002502.SZ,  Dinglong Culture Co.,Ltd.',
    name: 'Dinglong Culture Co.,Ltd.',
    symbol: '002502.SZ',
  },
  {
    value: '002503.SZ',
    label: '002503.SZ,  Souyute Group Co.,Ltd',
    name: 'Souyute Group Co.,Ltd',
    symbol: '002503.SZ',
  },
  {
    value: '002504.SZ',
    label: '002504.SZ,  Beijing Honggao Creative Architectural Design Co., Ltd',
    name: 'Beijing Honggao Creative Architectural Design Co., Ltd',
    symbol: '002504.SZ',
  },
  {
    value: '002505.SZ',
    label: '002505.SZ,  Pengdu Agriculture & Animal Husbandry Co.,Ltd.',
    name: 'Pengdu Agriculture & Animal Husbandry Co.,Ltd.',
    symbol: '002505.SZ',
  },
  {
    value: '002510.SZ',
    label: '002510.SZ,  Tianjin Motor Dies Co.,Ltd.',
    name: 'Tianjin Motor Dies Co.,Ltd.',
    symbol: '002510.SZ',
  },
  {
    value: '002512.SZ',
    label: '002512.SZ,  Tatwah Smartech Co.,Ltd',
    name: 'Tatwah Smartech Co.,Ltd',
    symbol: '002512.SZ',
  },
  {
    value: '002513.SZ',
    label: '002513.SZ,  Jiangsu Lanfeng Bio-chemical Co.,Ltd',
    name: 'Jiangsu Lanfeng Bio-chemical Co.,Ltd',
    symbol: '002513.SZ',
  },
  {
    value: '002514.SZ',
    label: '002514.SZ,  Jiangsu Boamax Technologies Group Co., Ltd.',
    name: 'Jiangsu Boamax Technologies Group Co., Ltd.',
    symbol: '002514.SZ',
  },
  {
    value: '002515.SZ',
    label: '002515.SZ,  Jinzi Ham Co.,Ltd.',
    name: 'Jinzi Ham Co.,Ltd.',
    symbol: '002515.SZ',
  },
  {
    value: '002516.SZ',
    label: '002516.SZ,  Kuangda Technology Group Co., Ltd.',
    name: 'Kuangda Technology Group Co., Ltd.',
    symbol: '002516.SZ',
  },
  {
    value: '002517.SZ',
    label: '002517.SZ,  Kingnet Network Co., Ltd.',
    name: 'Kingnet Network Co., Ltd.',
    symbol: '002517.SZ',
  },
  {
    value: '002518.SZ',
    label: '002518.SZ,  Shenzhen KSTAR Science and Technology Co., Ltd.',
    name: 'Shenzhen KSTAR Science and Technology Co., Ltd.',
    symbol: '002518.SZ',
  },
  {
    value: '002519.SZ',
    label: '002519.SZ,  Jiangsu Yinhe Electronics Co.,Ltd.',
    name: 'Jiangsu Yinhe Electronics Co.,Ltd.',
    symbol: '002519.SZ',
  },
  {
    value: '002520.SZ',
    label: '002520.SZ,  Zhejiang RIFA Precision Machinery Co., Ltd.',
    name: 'Zhejiang RIFA Precision Machinery Co., Ltd.',
    symbol: '002520.SZ',
  },
  {
    value: '002521.SZ',
    label: '002521.SZ,  Qifeng New Material Co., Ltd.',
    name: 'Qifeng New Material Co., Ltd.',
    symbol: '002521.SZ',
  },
  {
    value: '002522.SZ',
    label: '002522.SZ,  Zhejiang Zhongcheng Packing Material Co., Ltd.',
    name: 'Zhejiang Zhongcheng Packing Material Co., Ltd.',
    symbol: '002522.SZ',
  },
  {
    value: '002523.SZ',
    label: '002523.SZ,  Zhuzhou Tianqiao Crane Co., Ltd.',
    name: 'Zhuzhou Tianqiao Crane Co., Ltd.',
    symbol: '002523.SZ',
  },
  {
    value: '002524.SZ',
    label: '002524.SZ,  Guangzheng Eye Hospital Group Co., Ltd.',
    name: 'Guangzheng Eye Hospital Group Co., Ltd.',
    symbol: '002524.SZ',
  },
  {
    value: '002526.SZ',
    label: '002526.SZ,  Shandong Mining Machinery Group Co., Ltd',
    name: 'Shandong Mining Machinery Group Co., Ltd',
    symbol: '002526.SZ',
  },
  {
    value: '002527.SZ',
    label: '002527.SZ,  Shanghai STEP Electric Corporation',
    name: 'Shanghai STEP Electric Corporation',
    symbol: '002527.SZ',
  },
  {
    value: '002528.SZ',
    label: '002528.SZ,  Shenzhen Infinova Limited',
    name: 'Shenzhen Infinova Limited',
    symbol: '002528.SZ',
  },
  {
    value: '002529.SZ',
    label: '002529.SZ,  Jiangxi Haiyuan Composites Technology Co.,Ltd.',
    name: 'Jiangxi Haiyuan Composites Technology Co.,Ltd.',
    symbol: '002529.SZ',
  },
  {
    value: '002530.SZ',
    label: '002530.SZ,  JC Finance&Tax Interconnect Holdings Ltd.',
    name: 'JC Finance&Tax Interconnect Holdings Ltd.',
    symbol: '002530.SZ',
  },
  {
    value: '002533.SZ',
    label: '002533.SZ,  Gold cup Electric Apparatus Co.,Ltd.',
    name: 'Gold cup Electric Apparatus Co.,Ltd.',
    symbol: '002533.SZ',
  },
  {
    value: '002534.SZ',
    label: '002534.SZ,  Xizi Clean Energy Equipment Manufacturing Co., Ltd.',
    name: 'Xizi Clean Energy Equipment Manufacturing Co., Ltd.',
    symbol: '002534.SZ',
  },
  {
    value: '002535.SZ',
    label: '002535.SZ,  Linzhou Heavy Machinery Group Co.,Ltd',
    name: 'Linzhou Heavy Machinery Group Co.,Ltd',
    symbol: '002535.SZ',
  },
  {
    value: '002536.SZ',
    label: '002536.SZ,  Feilong Auto Components Co., Ltd.',
    name: 'Feilong Auto Components Co., Ltd.',
    symbol: '002536.SZ',
  },
  {
    value: '002537.SZ',
    label: '002537.SZ,  HyUnion Holding Co.,Ltd',
    name: 'HyUnion Holding Co.,Ltd',
    symbol: '002537.SZ',
  },
  {
    value: '002538.SZ',
    label: '002538.SZ,  Anhui Sierte Fertilizer industry LTD. ,company',
    name: 'Anhui Sierte Fertilizer industry LTD. ,company',
    symbol: '002538.SZ',
  },
  {
    value: '002539.SZ',
    label: '002539.SZ,  Chengdu Wintrue Holding Co., Ltd.',
    name: 'Chengdu Wintrue Holding Co., Ltd.',
    symbol: '002539.SZ',
  },
  {
    value: '002540.SZ',
    label: '002540.SZ,  Jiangsu Asia-Pacific Light Alloy Technology Co., Ltd.',
    name: 'Jiangsu Asia-Pacific Light Alloy Technology Co., Ltd.',
    symbol: '002540.SZ',
  },
  {
    value: '002542.SZ',
    label:
      '002542.SZ,  China Zhonghua Geotechnical Engineering Group Co., Ltd.',
    name: 'China Zhonghua Geotechnical Engineering Group Co., Ltd.',
    symbol: '002542.SZ',
  },
  {
    value: '002543.SZ',
    label: '002543.SZ,  Guangdong Vanward New Electric Co., Ltd.',
    name: 'Guangdong Vanward New Electric Co., Ltd.',
    symbol: '002543.SZ',
  },
  {
    value: '002544.SZ',
    label: '002544.SZ,  Cetc Potevio Science&Technology Co.,Ltd.',
    name: 'Cetc Potevio Science&Technology Co.,Ltd.',
    symbol: '002544.SZ',
  },
  {
    value: '002545.SZ',
    label: '002545.SZ,  Qingdao East Steel Tower Stock Co.Ltd',
    name: 'Qingdao East Steel Tower Stock Co.Ltd',
    symbol: '002545.SZ',
  },
  {
    value: '002546.SZ',
    label: '002546.SZ,  Nanjing Xinlian Electronics Co., Ltd',
    name: 'Nanjing Xinlian Electronics Co., Ltd',
    symbol: '002546.SZ',
  },
  {
    value: '002547.SZ',
    label: '002547.SZ,  Suzhou Chunxing Precision Mechanical Co., Ltd.',
    name: 'Suzhou Chunxing Precision Mechanical Co., Ltd.',
    symbol: '002547.SZ',
  },
  {
    value: '002548.SZ',
    label: '002548.SZ,  Shenzhen Kingsino Technology Co.,Ltd.',
    name: 'Shenzhen Kingsino Technology Co.,Ltd.',
    symbol: '002548.SZ',
  },
  {
    value: '002549.SZ',
    label: '002549.SZ,  Hunan Kaimeite Gases Co., Ltd.',
    name: 'Hunan Kaimeite Gases Co., Ltd.',
    symbol: '002549.SZ',
  },
  {
    value: '002550.SZ',
    label: '002550.SZ,  Changzhou Qianhong Biopharma CO.,LTD',
    name: 'Changzhou Qianhong Biopharma CO.,LTD',
    symbol: '002550.SZ',
  },
  {
    value: '002551.SZ',
    label: '002551.SZ,  Shenzhen Glory Medical Co.,Ltd.',
    name: 'Shenzhen Glory Medical Co.,Ltd.',
    symbol: '002551.SZ',
  },
  {
    value: '002552.SZ',
    label: '002552.SZ,  Baoding Technology Co., Ltd.',
    name: 'Baoding Technology Co., Ltd.',
    symbol: '002552.SZ',
  },
  {
    value: '002553.SZ',
    label: '002553.SZ,  Jiangsu NanFang Precision Co.,Ltd.',
    name: 'Jiangsu NanFang Precision Co.,Ltd.',
    symbol: '002553.SZ',
  },
  {
    value: '002554.SZ',
    label: '002554.SZ,  China Oil HBP Science & Technology Co., Ltd',
    name: 'China Oil HBP Science & Technology Co., Ltd',
    symbol: '002554.SZ',
  },
  {
    value: '002556.SZ',
    label:
      '002556.SZ,  Anhui Huilong Agricultural Means of Production Co.,Ltd.',
    name: 'Anhui Huilong Agricultural Means of Production Co.,Ltd.',
    symbol: '002556.SZ',
  },
  {
    value: '002559.SZ',
    label: '002559.SZ,  Jiangsu Yawei Machine Tool Co., Ltd.',
    name: 'Jiangsu Yawei Machine Tool Co., Ltd.',
    symbol: '002559.SZ',
  },
  {
    value: '002560.SZ',
    label: '002560.SZ,  Henan Tong-Da Cable Co., Ltd.',
    name: 'Henan Tong-Da Cable Co., Ltd.',
    symbol: '002560.SZ',
  },
  {
    value: '002561.SZ',
    label: '002561.SZ,  Shanghai Xujiahui Commercial Co., Ltd.',
    name: 'Shanghai Xujiahui Commercial Co., Ltd.',
    symbol: '002561.SZ',
  },
  {
    value: '002562.SZ',
    label: '002562.SZ,  Brother Enterprises Holding Co.,Ltd.',
    name: 'Brother Enterprises Holding Co.,Ltd.',
    symbol: '002562.SZ',
  },
  {
    value: '002564.SZ',
    label: '002564.SZ,  SuZhou THVOW Technology. Co., Ltd.',
    name: 'SuZhou THVOW Technology. Co., Ltd.',
    symbol: '002564.SZ',
  },
  {
    value: '002565.SZ',
    label: '002565.SZ,  Shanghai Shunho New Materials Technology Co.,Ltd.',
    name: 'Shanghai Shunho New Materials Technology Co.,Ltd.',
    symbol: '002565.SZ',
  },
  {
    value: '002566.SZ',
    label: '002566.SZ,  Jilin Jian Yisheng Pharmaceutical Co., Ltd.',
    name: 'Jilin Jian Yisheng Pharmaceutical Co., Ltd.',
    symbol: '002566.SZ',
  },
  {
    value: '002567.SZ',
    label: '002567.SZ,  Tangrenshen Group Co., Ltd',
    name: 'Tangrenshen Group Co., Ltd',
    symbol: '002567.SZ',
  },
  {
    value: '002569.SZ',
    label: '002569.SZ,  Zhejiang Busen Garments Co., Ltd.',
    name: 'Zhejiang Busen Garments Co., Ltd.',
    symbol: '002569.SZ',
  },
  {
    value: '002570.SZ',
    label: '002570.SZ,  Beingmate Co., Ltd.',
    name: 'Beingmate Co., Ltd.',
    symbol: '002570.SZ',
  },
  {
    value: '002571.SZ',
    label: '002571.SZ,  Anhui Deli Household Glass Co., Ltd.',
    name: 'Anhui Deli Household Glass Co., Ltd.',
    symbol: '002571.SZ',
  },
  {
    value: '002573.SZ',
    label: '002573.SZ,  Beijing SPC Environment Protection Tech Co., Ltd.',
    name: 'Beijing SPC Environment Protection Tech Co., Ltd.',
    symbol: '002573.SZ',
  },
  {
    value: '002574.SZ',
    label: '002574.SZ,  Zhejiang Ming Jewelry Co., Ltd.',
    name: 'Zhejiang Ming Jewelry Co., Ltd.',
    symbol: '002574.SZ',
  },
  {
    value: '002575.SZ',
    label: '002575.SZ,  Guangdong Qunxing Toys Joint-Stock Co., Ltd.',
    name: 'Guangdong Qunxing Toys Joint-Stock Co., Ltd.',
    symbol: '002575.SZ',
  },
  {
    value: '002576.SZ',
    label: '002576.SZ,  Jiangsu Tongda Power Technology Co.,Ltd.',
    name: 'Jiangsu Tongda Power Technology Co.,Ltd.',
    symbol: '002576.SZ',
  },
  {
    value: '002577.SZ',
    label: '002577.SZ,  Shenzhen Rapoo Technology Co., Ltd.',
    name: 'Shenzhen Rapoo Technology Co., Ltd.',
    symbol: '002577.SZ',
  },
  {
    value: '002578.SZ',
    label: '002578.SZ,  Fujian Minfa Aluminium Co.,Ltd.',
    name: 'Fujian Minfa Aluminium Co.,Ltd.',
    symbol: '002578.SZ',
  },
  {
    value: '002579.SZ',
    label: '002579.SZ,  Huizhou China Eagle Electronic Technology Inc.',
    name: 'Huizhou China Eagle Electronic Technology Inc.',
    symbol: '002579.SZ',
  },
  {
    value: '002580.SZ',
    label: '002580.SZ,  Shandong Sacred Sun Power Sources Co.,Ltd',
    name: 'Shandong Sacred Sun Power Sources Co.,Ltd',
    symbol: '002580.SZ',
  },
  {
    value: '002581.SZ',
    label: '002581.SZ,  Shandong Sinobioway Biomedicine Co., Ltd.',
    name: 'Shandong Sinobioway Biomedicine Co., Ltd.',
    symbol: '002581.SZ',
  },
  {
    value: '002582.SZ',
    label: '002582.SZ,  Haoxiangni Health Food Co.,Ltd.',
    name: 'Haoxiangni Health Food Co.,Ltd.',
    symbol: '002582.SZ',
  },
  {
    value: '002583.SZ',
    label: '002583.SZ,  Hytera Communications Corporation Limited',
    name: 'Hytera Communications Corporation Limited',
    symbol: '002583.SZ',
  },
  {
    value: '002584.SZ',
    label: '002584.SZ,  Xilong Scientific Co., Ltd.',
    name: 'Xilong Scientific Co., Ltd.',
    symbol: '002584.SZ',
  },
  {
    value: '002585.SZ',
    label:
      '002585.SZ,  Jiangsu Shuangxing Color Plastic New Materials Co., Ltd.',
    name: 'Jiangsu Shuangxing Color Plastic New Materials Co., Ltd.',
    symbol: '002585.SZ',
  },
  {
    value: '002586.SZ',
    label: '002586.SZ,  Zhejiang Reclaim Construction Group Co., Ltd.',
    name: 'Zhejiang Reclaim Construction Group Co., Ltd.',
    symbol: '002586.SZ',
  },
  {
    value: '002587.SZ',
    label: '002587.SZ,  Shenzhen AOTO Electronics Co., Ltd.',
    name: 'Shenzhen AOTO Electronics Co., Ltd.',
    symbol: '002587.SZ',
  },
  {
    value: '002588.SZ',
    label: '002588.SZ,  Stanley Agriculture Group Co.,Ltd.',
    name: 'Stanley Agriculture Group Co.,Ltd.',
    symbol: '002588.SZ',
  },
  {
    value: '002589.SZ',
    label: '002589.SZ,  Realcan Pharmaceutical Group Co., Ltd.',
    name: 'Realcan Pharmaceutical Group Co., Ltd.',
    symbol: '002589.SZ',
  },
  {
    value: '002590.SZ',
    label: '002590.SZ,  Zhejiang VIE Science & Technology Co., Ltd.',
    name: 'Zhejiang VIE Science & Technology Co., Ltd.',
    symbol: '002590.SZ',
  },
  {
    value: '002591.SZ',
    label: '002591.SZ,  Jiangxi Hengda Hi-Tech Co.,Ltd.',
    name: 'Jiangxi Hengda Hi-Tech Co.,Ltd.',
    symbol: '002591.SZ',
  },
  {
    value: '002592.SZ',
    label: '002592.SZ,  Nanning Baling Technology Co., Ltd.',
    name: 'Nanning Baling Technology Co., Ltd.',
    symbol: '002592.SZ',
  },
  {
    value: '002593.SZ',
    label: '002593.SZ,  Xiamen Sunrise Group Co., Ltd.',
    name: 'Xiamen Sunrise Group Co., Ltd.',
    symbol: '002593.SZ',
  },
  {
    value: '002596.SZ',
    label: '002596.SZ,  Hainan RuiZe New Building Material Co.,Ltd',
    name: 'Hainan RuiZe New Building Material Co.,Ltd',
    symbol: '002596.SZ',
  },
  {
    value: '002597.SZ',
    label: '002597.SZ,  Anhui Jinhe Industrial Co.,Ltd.',
    name: 'Anhui Jinhe Industrial Co.,Ltd.',
    symbol: '002597.SZ',
  },
  {
    value: '002598.SZ',
    label: '002598.SZ,  Shandong Zhangqiu Blower Co., Ltd',
    name: 'Shandong Zhangqiu Blower Co., Ltd',
    symbol: '002598.SZ',
  },
  {
    value: '002599.SZ',
    label: '002599.SZ,  Beijing Shengtong Printing Co., Ltd',
    name: 'Beijing Shengtong Printing Co., Ltd',
    symbol: '002599.SZ',
  },
  {
    value: '002606.SZ',
    label: '002606.SZ,  Dalian Insulator Group Co., Ltd',
    name: 'Dalian Insulator Group Co., Ltd',
    symbol: '002606.SZ',
  },
  {
    value: '002608.SZ',
    label: '002608.SZ,  Jiangsu Guoxin Corp. Ltd.',
    name: 'Jiangsu Guoxin Corp. Ltd.',
    symbol: '002608.SZ',
  },
  {
    value: '002609.SZ',
    label:
      '002609.SZ,  Shenzhen Jieshun Science and Technology Industry Co.,Ltd.',
    name: 'Shenzhen Jieshun Science and Technology Industry Co.,Ltd.',
    symbol: '002609.SZ',
  },
  {
    value: '002610.SZ',
    label: '002610.SZ,  Jiangsu Akcome Science and Technology Co., Ltd.',
    name: 'Jiangsu Akcome Science and Technology Co., Ltd.',
    symbol: '002610.SZ',
  },
  {
    value: '002611.SZ',
    label:
      '002611.SZ,  Guangdong Dongfang Precision Science & Technology Co., Ltd.',
    name: 'Guangdong Dongfang Precision Science & Technology Co., Ltd.',
    symbol: '002611.SZ',
  },
  {
    value: '002612.SZ',
    label: '002612.SZ,  Lancy Co., Ltd.',
    name: 'Lancy Co., Ltd.',
    symbol: '002612.SZ',
  },
  {
    value: '002613.SZ',
    label: '002613.SZ,  Luoyang Northglass Technology Co.,Ltd',
    name: 'Luoyang Northglass Technology Co.,Ltd',
    symbol: '002613.SZ',
  },
  {
    value: '002614.SZ',
    label: '002614.SZ,  Xiamen Comfort Science&Technology Group Co., Ltd',
    name: 'Xiamen Comfort Science&Technology Group Co., Ltd',
    symbol: '002614.SZ',
  },
  {
    value: '002615.SZ',
    label: '002615.SZ,  ZheJiang Haers Vacuum Containers Co.,Ltd.',
    name: 'ZheJiang Haers Vacuum Containers Co.,Ltd.',
    symbol: '002615.SZ',
  },
  {
    value: '002616.SZ',
    label: '002616.SZ,  Guangdong Chant Group Inc.',
    name: 'Guangdong Chant Group Inc.',
    symbol: '002616.SZ',
  },
  {
    value: '002617.SZ',
    label: '002617.SZ,  Roshow Technology Co., Ltd.',
    name: 'Roshow Technology Co., Ltd.',
    symbol: '002617.SZ',
  },
  {
    value: '002620.SZ',
    label: '002620.SZ,  Shenzhen Ruihe Construction Decoration Co., Ltd.',
    name: 'Shenzhen Ruihe Construction Decoration Co., Ltd.',
    symbol: '002620.SZ',
  },
  {
    value: '002621.SZ',
    label: '002621.SZ,  Dalian My Gym Education Technology Co.,Ltd.',
    name: 'Dalian My Gym Education Technology Co.,Ltd.',
    symbol: '002621.SZ',
  },
  {
    value: '002622.SZ',
    label: '002622.SZ,  Rongyu Group Co., Ltd.',
    name: 'Rongyu Group Co., Ltd.',
    symbol: '002622.SZ',
  },
  {
    value: '002623.SZ',
    label: '002623.SZ,  Changzhou Almaden Co., Ltd.',
    name: 'Changzhou Almaden Co., Ltd.',
    symbol: '002623.SZ',
  },
  {
    value: '002626.SZ',
    label: '002626.SZ,  Xiamen Kingdomway Group Company',
    name: 'Xiamen Kingdomway Group Company',
    symbol: '002626.SZ',
  },
  {
    value: '002627.SZ',
    label: '002627.SZ,  Hubei Three Gorges Tourism Group Co., Ltd.',
    name: 'Hubei Three Gorges Tourism Group Co., Ltd.',
    symbol: '002627.SZ',
  },
  {
    value: '002628.SZ',
    label: '002628.SZ,  Chengdu Road & Bridge Engineering CO.,LTD',
    name: 'Chengdu Road & Bridge Engineering CO.,LTD',
    symbol: '002628.SZ',
  },
  {
    value: '002629.SZ',
    label: '002629.SZ,  Zhejiang Renzhi Co., Ltd.',
    name: 'Zhejiang Renzhi Co., Ltd.',
    symbol: '002629.SZ',
  },
  {
    value: '002630.SZ',
    label: '002630.SZ,  China Western Power Industrial Co., Ltd.',
    name: 'China Western Power Industrial Co., Ltd.',
    symbol: '002630.SZ',
  },
  {
    value: '002631.SZ',
    label:
      '002631.SZ,  Der Future Science and Technology Holding Group Co., Ltd.',
    name: 'Der Future Science and Technology Holding Group Co., Ltd.',
    symbol: '002631.SZ',
  },
  {
    value: '002632.SZ',
    label: '002632.SZ,  Daoming Optics&Chemical Co.,Ltd',
    name: 'Daoming Optics&Chemical Co.,Ltd',
    symbol: '002632.SZ',
  },
  {
    value: '002633.SZ',
    label: '002633.SZ,  Shenke Slide Bearing Corporation',
    name: 'Shenke Slide Bearing Corporation',
    symbol: '002633.SZ',
  },
  {
    value: '002634.SZ',
    label: '002634.SZ,  Zhejiang Bangjie Holding Group Co.,Ltd',
    name: 'Zhejiang Bangjie Holding Group Co.,Ltd',
    symbol: '002634.SZ',
  },
  {
    value: '002636.SZ',
    label: '002636.SZ,  Goldenmax International Technology Ltd.',
    name: 'Goldenmax International Technology Ltd.',
    symbol: '002636.SZ',
  },
  {
    value: '002637.SZ',
    label: '002637.SZ,  Zanyu Technology Group Co., Ltd.',
    name: 'Zanyu Technology Group Co., Ltd.',
    symbol: '002637.SZ',
  },
  {
    value: '002638.SZ',
    label: '002638.SZ,  Dongguan Kingsun Optoelectronic Co.,Ltd.',
    name: 'Dongguan Kingsun Optoelectronic Co.,Ltd.',
    symbol: '002638.SZ',
  },
  {
    value: '002639.SZ',
    label: '002639.SZ,  Fujian Snowman Co., Ltd.',
    name: 'Fujian Snowman Co., Ltd.',
    symbol: '002639.SZ',
  },
  {
    value: '002640.SZ',
    label: '002640.SZ,  Global Top E-Commerce Co., Ltd.',
    name: 'Global Top E-Commerce Co., Ltd.',
    symbol: '002640.SZ',
  },
  {
    value: '002641.SZ',
    label: '002641.SZ,  Era Co., Ltd.',
    name: 'Era Co., Ltd.',
    symbol: '002641.SZ',
  },
  {
    value: '002642.SZ',
    label: '002642.SZ,  Ronglian Group Ltd.',
    name: 'Ronglian Group Ltd.',
    symbol: '002642.SZ',
  },
  {
    value: '002643.SZ',
    label: '002643.SZ,  Valiant Co.,Ltd',
    name: 'Valiant Co.,Ltd',
    symbol: '002643.SZ',
  },
  {
    value: '002644.SZ',
    label: '002644.SZ,  LanZhou Foci Pharmaceutical Co.,Ltd.',
    name: 'LanZhou Foci Pharmaceutical Co.,Ltd.',
    symbol: '002644.SZ',
  },
  {
    value: '002645.SZ',
    label: '002645.SZ,  Jiangsu Huahong Technology Co., Ltd.',
    name: 'Jiangsu Huahong Technology Co., Ltd.',
    symbol: '002645.SZ',
  },
  {
    value: '002646.SZ',
    label:
      '002646.SZ,  Qinghai Huzhu TianYouDe Highland Barley Spirit Co., Ltd.',
    name: 'Qinghai Huzhu TianYouDe Highland Barley Spirit Co., Ltd.',
    symbol: '002646.SZ',
  },
  {
    value: '002647.SZ',
    label: '002647.SZ,  Rendong Holdings Co., Ltd.',
    name: 'Rendong Holdings Co., Ltd.',
    symbol: '002647.SZ',
  },
  {
    value: '002649.SZ',
    label: '002649.SZ,  Beyondsoft Corporation',
    name: 'Beyondsoft Corporation',
    symbol: '002649.SZ',
  },
  {
    value: '002650.SZ',
    label: '002650.SZ,  Jiajia Food Group Co.,Ltd',
    name: 'Jiajia Food Group Co.,Ltd',
    symbol: '002650.SZ',
  },
  {
    value: '002651.SZ',
    label: '002651.SZ,  Chengdu Leejun Industrial Co., Ltd.',
    name: 'Chengdu Leejun Industrial Co., Ltd.',
    symbol: '002651.SZ',
  },
  {
    value: '002652.SZ',
    label: '002652.SZ,  Suzhou Yangtze New Materials Co., Ltd.',
    name: 'Suzhou Yangtze New Materials Co., Ltd.',
    symbol: '002652.SZ',
  },
  {
    value: '002654.SZ',
    label: '002654.SZ,  Shenzhen Mason Technologies Co.,Ltd',
    name: 'Shenzhen Mason Technologies Co.,Ltd',
    symbol: '002654.SZ',
  },
  {
    value: '002655.SZ',
    label: '002655.SZ,  Gettop Acoustic Co., Ltd.',
    name: 'Gettop Acoustic Co., Ltd.',
    symbol: '002655.SZ',
  },
  {
    value: '002656.SZ',
    label: '002656.SZ,  Modern Avenue Group Co., Ltd.',
    name: 'Modern Avenue Group Co., Ltd.',
    symbol: '002656.SZ',
  },
  {
    value: '002657.SZ',
    label: '002657.SZ,  Sinodata Co., Ltd.',
    name: 'Sinodata Co., Ltd.',
    symbol: '002657.SZ',
  },
  {
    value: '002658.SZ',
    label: '002658.SZ,  Beijing SDL Technology Co.,Ltd.',
    name: 'Beijing SDL Technology Co.,Ltd.',
    symbol: '002658.SZ',
  },
  {
    value: '002659.SZ',
    label: '002659.SZ,  Beijing Kaiwen Education Technology Co., Ltd',
    name: 'Beijing Kaiwen Education Technology Co., Ltd',
    symbol: '002659.SZ',
  },
  {
    value: '002660.SZ',
    label: '002660.SZ,  Moso Power Supply Technology Co.,Ltd',
    name: 'Moso Power Supply Technology Co.,Ltd',
    symbol: '002660.SZ',
  },
  {
    value: '002661.SZ',
    label: '002661.SZ,  Chen Ke Ming Food Manufacturing Co., Ltd.',
    name: 'Chen Ke Ming Food Manufacturing Co., Ltd.',
    symbol: '002661.SZ',
  },
  {
    value: '002662.SZ',
    label: '002662.SZ,  Beijing WKW Automotive Parts Co.,Ltd.',
    name: 'Beijing WKW Automotive Parts Co.,Ltd.',
    symbol: '002662.SZ',
  },
  {
    value: '002663.SZ',
    label: '002663.SZ,  Pubang Landscape Architecture Co., Ltd',
    name: 'Pubang Landscape Architecture Co., Ltd',
    symbol: '002663.SZ',
  },
  {
    value: '002664.SZ',
    label: '002664.SZ,  Xinzhi Group Co., Ltd.',
    name: 'Xinzhi Group Co., Ltd.',
    symbol: '002664.SZ',
  },
  {
    value: '002665.SZ',
    label: '002665.SZ,  Shouhang High-Tech Energy Co., Ltd.',
    name: 'Shouhang High-Tech Energy Co., Ltd.',
    symbol: '002665.SZ',
  },
  {
    value: '002666.SZ',
    label: '002666.SZ,  Guangdong Delian Group Co., Ltd.',
    name: 'Guangdong Delian Group Co., Ltd.',
    symbol: '002666.SZ',
  },
  {
    value: '002667.SZ',
    label: '002667.SZ,  Anshan Heavy Duty Mining Machinery Co., Ltd.',
    name: 'Anshan Heavy Duty Mining Machinery Co., Ltd.',
    symbol: '002667.SZ',
  },
  {
    value: '002668.SZ',
    label: '002668.SZ,  Guangdong Homa Group Co., Ltd.',
    name: 'Guangdong Homa Group Co., Ltd.',
    symbol: '002668.SZ',
  },
  {
    value: '002669.SZ',
    label: '002669.SZ,  Kangda New Materials (Group) Co., Ltd.',
    name: 'Kangda New Materials (Group) Co., Ltd.',
    symbol: '002669.SZ',
  },
  {
    value: '002670.SZ',
    label: '002670.SZ,  Guosheng Financial Holding Inc.',
    name: 'Guosheng Financial Holding Inc.',
    symbol: '002670.SZ',
  },
  {
    value: '002671.SZ',
    label: '002671.SZ,  Shandong Longquan Pipeline Engineering Co.,LTD',
    name: 'Shandong Longquan Pipeline Engineering Co.,LTD',
    symbol: '002671.SZ',
  },
  {
    value: '002672.SZ',
    label: '002672.SZ,  Dongjiang Environmental Company Limited',
    name: 'Dongjiang Environmental Company Limited',
    symbol: '002672.SZ',
  },
  {
    value: '002674.SZ',
    label: '002674.SZ,  Xingye Leather Technology Co., Ltd.',
    name: 'Xingye Leather Technology Co., Ltd.',
    symbol: '002674.SZ',
  },
  {
    value: '002675.SZ',
    label: '002675.SZ,  Yantai Dongcheng Pharmaceutical Group Co.,Ltd.',
    name: 'Yantai Dongcheng Pharmaceutical Group Co.,Ltd.',
    symbol: '002675.SZ',
  },
  {
    value: '002676.SZ',
    label: '002676.SZ,  Guangdong Sunwill Precising Plastic Co.,Ltd',
    name: 'Guangdong Sunwill Precising Plastic Co.,Ltd',
    symbol: '002676.SZ',
  },
  {
    value: '002678.SZ',
    label: '002678.SZ,  Guangzhou Pearl River Piano Group Co.,Ltd',
    name: 'Guangzhou Pearl River Piano Group Co.,Ltd',
    symbol: '002678.SZ',
  },
  {
    value: '002679.SZ',
    label: '002679.SZ,  Fujian Jinsen Forestry Co.,Ltd',
    name: 'Fujian Jinsen Forestry Co.,Ltd',
    symbol: '002679.SZ',
  },
  {
    value: '002681.SZ',
    label: '002681.SZ,  Shenzhen Fenda Technology Co., Ltd.',
    name: 'Shenzhen Fenda Technology Co., Ltd.',
    symbol: '002681.SZ',
  },
  {
    value: '002682.SZ',
    label: '002682.SZ,  Longzhou Group Co., Ltd.',
    name: 'Longzhou Group Co., Ltd.',
    symbol: '002682.SZ',
  },
  {
    value: '002685.SZ',
    label: '002685.SZ,  Wuxi Huadong Heavy Machinery Co., Ltd.',
    name: 'Wuxi Huadong Heavy Machinery Co., Ltd.',
    symbol: '002685.SZ',
  },
  {
    value: '002686.SZ',
    label: '002686.SZ,  Zhejiang Yilida Ventilator Co.,Ltd.',
    name: 'Zhejiang Yilida Ventilator Co.,Ltd.',
    symbol: '002686.SZ',
  },
  {
    value: '002687.SZ',
    label: '002687.SZ,  Zhejiang Giuseppe Garment Co., Ltd',
    name: 'Zhejiang Giuseppe Garment Co., Ltd',
    symbol: '002687.SZ',
  },
  {
    value: '002688.SZ',
    label: '002688.SZ,  Jinhe Biotechnology CO., LTD.',
    name: 'Jinhe Biotechnology CO., LTD.',
    symbol: '002688.SZ',
  },
  {
    value: '002689.SZ',
    label: '002689.SZ,  Shenyang Yuanda Intellectual Industry Group Co.,Ltd',
    name: 'Shenyang Yuanda Intellectual Industry Group Co.,Ltd',
    symbol: '002689.SZ',
  },
  {
    value: '002691.SZ',
    label: '002691.SZ,  Jikai Equipment Manufacturing Co., Ltd.',
    name: 'Jikai Equipment Manufacturing Co., Ltd.',
    symbol: '002691.SZ',
  },
  {
    value: '002692.SZ',
    label: '002692.SZ,  Yuan Cheng Cable Co.,Ltd.',
    name: 'Yuan Cheng Cable Co.,Ltd.',
    symbol: '002692.SZ',
  },
  {
    value: '002693.SZ',
    label: '002693.SZ,  Hainan Shuangcheng Pharmaceuticals Co., Ltd.',
    name: 'Hainan Shuangcheng Pharmaceuticals Co., Ltd.',
    symbol: '002693.SZ',
  },
  {
    value: '002694.SZ',
    label: '002694.SZ,  Goody Science and Technology Co., Ltd.',
    name: 'Goody Science and Technology Co., Ltd.',
    symbol: '002694.SZ',
  },
  {
    value: '002695.SZ',
    label: '002695.SZ,  Jiangxi Huangshanghuang Group Food Co., Ltd.',
    name: 'Jiangxi Huangshanghuang Group Food Co., Ltd.',
    symbol: '002695.SZ',
  },
  {
    value: '002696.SZ',
    label: '002696.SZ,  Baiyang Investment Group, Inc.',
    name: 'Baiyang Investment Group, Inc.',
    symbol: '002696.SZ',
  },
  {
    value: '002697.SZ',
    label: '002697.SZ,  Chengdu Hongqi Chain Co.,Ltd.',
    name: 'Chengdu Hongqi Chain Co.,Ltd.',
    symbol: '002697.SZ',
  },
  {
    value: '002699.SZ',
    label: '002699.SZ,  Meisheng Cultural & Creative Corp., Ltd.',
    name: 'Meisheng Cultural & Creative Corp., Ltd.',
    symbol: '002699.SZ',
  },
  {
    value: '002700.SZ',
    label: '002700.SZ,  Xinjiang Haoyuan Natural Gas Co. ,Ltd.',
    name: 'Xinjiang Haoyuan Natural Gas Co. ,Ltd.',
    symbol: '002700.SZ',
  },
  {
    value: '002702.SZ',
    label: '002702.SZ,  HaiXin Foods Co.,Ltd',
    name: 'HaiXin Foods Co.,Ltd',
    symbol: '002702.SZ',
  },
  {
    value: '002703.SZ',
    label: '002703.SZ,  Zhejiang Shibao Company Limited',
    name: 'Zhejiang Shibao Company Limited',
    symbol: '002703.SZ',
  },
  {
    value: '002707.SZ',
    label: '002707.SZ,  UTour Group Co., Ltd.',
    name: 'UTour Group Co., Ltd.',
    symbol: '002707.SZ',
  },
  {
    value: '002708.SZ',
    label: '002708.SZ,  Changzhou Nrb Corporation',
    name: 'Changzhou Nrb Corporation',
    symbol: '002708.SZ',
  },
  {
    value: '002712.SZ',
    label: '002712.SZ,  Simei Media Co.,Ltd.',
    name: 'Simei Media Co.,Ltd.',
    symbol: '002712.SZ',
  },
  {
    value: '002713.SZ',
    label: '002713.SZ,  Dong Yi Ri Sheng Home Decoration Group Co.,Ltd.',
    name: 'Dong Yi Ri Sheng Home Decoration Group Co.,Ltd.',
    symbol: '002713.SZ',
  },
  {
    value: '002715.SZ',
    label: '002715.SZ,  Huaiji Dengyun Auto-parts (Holding) Co.,Ltd.',
    name: 'Huaiji Dengyun Auto-parts (Holding) Co.,Ltd.',
    symbol: '002715.SZ',
  },
  {
    value: '002716.SZ',
    label: '002716.SZ,  Chenzhou City Jingui Silver Industry Co., Ltd.',
    name: 'Chenzhou City Jingui Silver Industry Co., Ltd.',
    symbol: '002716.SZ',
  },
  {
    value: '002717.SZ',
    label: '002717.SZ,  LingNan Eco&Culture-Tourism Co.,Ltd.',
    name: 'LingNan Eco&Culture-Tourism Co.,Ltd.',
    symbol: '002717.SZ',
  },
  {
    value: '002718.SZ',
    label: '002718.SZ,  Zhejiang Youpon Integrated Ceiling Co.,Ltd.',
    name: 'Zhejiang Youpon Integrated Ceiling Co.,Ltd.',
    symbol: '002718.SZ',
  },
  {
    value: '002719.SZ',
    label: '002719.SZ,  Maiquer Group CO.,LTD',
    name: 'Maiquer Group CO.,LTD',
    symbol: '002719.SZ',
  },
  {
    value: '002721.SZ',
    label: '002721.SZ,  Beijing Kingee Culture Development Co., Ltd.',
    name: 'Beijing Kingee Culture Development Co., Ltd.',
    symbol: '002721.SZ',
  },
  {
    value: '002722.SZ',
    label: '002722.SZ,  Wuchan Zhongda Geron Co.,Ltd.',
    name: 'Wuchan Zhongda Geron Co.,Ltd.',
    symbol: '002722.SZ',
  },
  {
    value: '002723.SZ',
    label: '002723.SZ,  Kennede Electronics MFG. Co., Ltd.',
    name: 'Kennede Electronics MFG. Co., Ltd.',
    symbol: '002723.SZ',
  },
  {
    value: '002724.SZ',
    label: "002724.SZ,  Ocean's King Lighting Science & Technology Co., Ltd.",
    name: "Ocean's King Lighting Science & Technology Co., Ltd.",
    symbol: '002724.SZ',
  },
  {
    value: '002725.SZ',
    label: '002725.SZ,  Zhejiang Yueling Co., Ltd.',
    name: 'Zhejiang Yueling Co., Ltd.',
    symbol: '002725.SZ',
  },
  {
    value: '002726.SZ',
    label: '002726.SZ,  Shandong Longda Meishi Co., Ltd.',
    name: 'Shandong Longda Meishi Co., Ltd.',
    symbol: '002726.SZ',
  },
  {
    value: '002728.SZ',
    label: '002728.SZ,  Teyi Pharmaceutical Group Co.,Ltd',
    name: 'Teyi Pharmaceutical Group Co.,Ltd',
    symbol: '002728.SZ',
  },
  {
    value: '002729.SZ',
    label:
      '002729.SZ,  Hollyland (China) Electronics Technology Corporation Limited',
    name: 'Hollyland (China) Electronics Technology Corporation Limited',
    symbol: '002729.SZ',
  },
  {
    value: '002730.SZ',
    label: '002730.SZ,  Dianguang Explosion-proof Technology Co., Ltd.',
    name: 'Dianguang Explosion-proof Technology Co., Ltd.',
    symbol: '002730.SZ',
  },
  {
    value: '002731.SZ',
    label: '002731.SZ,  Shenyang Cuihua Gold and Silver Jewelry Co., Ltd.',
    name: 'Shenyang Cuihua Gold and Silver Jewelry Co., Ltd.',
    symbol: '002731.SZ',
  },
  {
    value: '002732.SZ',
    label: '002732.SZ,  Guangdong Yantang Dairy Co., Ltd.',
    name: 'Guangdong Yantang Dairy Co., Ltd.',
    symbol: '002732.SZ',
  },
  {
    value: '002733.SZ',
    label: '002733.SZ,  Shenzhen Center Power Tech. Co., Ltd',
    name: 'Shenzhen Center Power Tech. Co., Ltd',
    symbol: '002733.SZ',
  },
  {
    value: '002734.SZ',
    label: '002734.SZ,  Limin Group Co., Ltd.',
    name: 'Limin Group Co., Ltd.',
    symbol: '002734.SZ',
  },
  {
    value: '002735.SZ',
    label: '002735.SZ,  Shenzhen Prince New Materials Co., Ltd.',
    name: 'Shenzhen Prince New Materials Co., Ltd.',
    symbol: '002735.SZ',
  },
  {
    value: '002738.SZ',
    label: '002738.SZ,  Sinomine Resource Group Co., Ltd.',
    name: 'Sinomine Resource Group Co., Ltd.',
    symbol: '002738.SZ',
  },
  {
    value: '002739.SZ',
    label: '002739.SZ,  Wanda Film Holding Co., Ltd.',
    name: 'Wanda Film Holding Co., Ltd.',
    symbol: '002739.SZ',
  },
  {
    value: '002740.SZ',
    label: '002740.SZ,  Fujian Ideal Jewellery Industrial Co., Ltd.',
    name: 'Fujian Ideal Jewellery Industrial Co., Ltd.',
    symbol: '002740.SZ',
  },
  {
    value: '002741.SZ',
    label: '002741.SZ,  Guangdong Guanghua Sci-Tech Co., Ltd.',
    name: 'Guangdong Guanghua Sci-Tech Co., Ltd.',
    symbol: '002741.SZ',
  },
  {
    value: '002742.SZ',
    label: '002742.SZ,  Chongqing Sansheng Industrial Co.,Ltd.',
    name: 'Chongqing Sansheng Industrial Co.,Ltd.',
    symbol: '002742.SZ',
  },
  {
    value: '002743.SZ',
    label: '002743.SZ,  Anhui Fuhuang Steel Structure Co., Ltd.',
    name: 'Anhui Fuhuang Steel Structure Co., Ltd.',
    symbol: '002743.SZ',
  },
  {
    value: '002746.SZ',
    label: '002746.SZ,  Shandong Xiantan Co., Ltd.',
    name: 'Shandong Xiantan Co., Ltd.',
    symbol: '002746.SZ',
  },
  {
    value: '002748.SZ',
    label: '002748.SZ,  Jiangxi Selon Industrial Co., Ltd.',
    name: 'Jiangxi Selon Industrial Co., Ltd.',
    symbol: '002748.SZ',
  },
  {
    value: '002749.SZ',
    label: '002749.SZ,  Sichuan Guoguang Agrochemical Co., Ltd.',
    name: 'Sichuan Guoguang Agrochemical Co., Ltd.',
    symbol: '002749.SZ',
  },
  {
    value: '002750.SZ',
    label: '002750.SZ,  Kunming Longjin Pharmaceutical Co., Ltd.',
    name: 'Kunming Longjin Pharmaceutical Co., Ltd.',
    symbol: '002750.SZ',
  },
  {
    value: '002751.SZ',
    label: '002751.SZ,  Shenzhen ESUN Display Co.,Ltd',
    name: 'Shenzhen ESUN Display Co.,Ltd',
    symbol: '002751.SZ',
  },
  {
    value: '002752.SZ',
    label: '002752.SZ,  Sunrise Group Company Limited',
    name: 'Sunrise Group Company Limited',
    symbol: '002752.SZ',
  },
  {
    value: '002753.SZ',
    label: '002753.SZ,  Shanxi Yongdong Chemistry Industry Co., Ltd.',
    name: 'Shanxi Yongdong Chemistry Industry Co., Ltd.',
    symbol: '002753.SZ',
  },
  {
    value: '002755.SZ',
    label: '002755.SZ,  Beijing Aosaikang Pharmaceutical Co., Ltd.',
    name: 'Beijing Aosaikang Pharmaceutical Co., Ltd.',
    symbol: '002755.SZ',
  },
  {
    value: '002756.SZ',
    label: '002756.SZ,  Yongxing Special Materials Technology Co.,Ltd',
    name: 'Yongxing Special Materials Technology Co.,Ltd',
    symbol: '002756.SZ',
  },
  {
    value: '002757.SZ',
    label: '002757.SZ,  Nanxing Machinery Co., Ltd.',
    name: 'Nanxing Machinery Co., Ltd.',
    symbol: '002757.SZ',
  },
  {
    value: '002758.SZ',
    label: '002758.SZ,  ZJAMP Group Co., Ltd.',
    name: 'ZJAMP Group Co., Ltd.',
    symbol: '002758.SZ',
  },
  {
    value: '002759.SZ',
    label: '002759.SZ,  Tonze New Energy Technology Co.,Ltd.',
    name: 'Tonze New Energy Technology Co.,Ltd.',
    symbol: '002759.SZ',
  },
  {
    value: '002760.SZ',
    label: '002760.SZ,  Fengxing Co., Ltd.',
    name: 'Fengxing Co., Ltd.',
    symbol: '002760.SZ',
  },
  {
    value: '002761.SZ',
    label: '002761.SZ,  Zhejiang Construction Investment Group Co.,Ltd',
    name: 'Zhejiang Construction Investment Group Co.,Ltd',
    symbol: '002761.SZ',
  },
  {
    value: '002762.SZ',
    label: '002762.SZ,  Jinfa Labi Maternity & Baby Articles Co., Ltd',
    name: 'Jinfa Labi Maternity & Baby Articles Co., Ltd',
    symbol: '002762.SZ',
  },
  {
    value: '002763.SZ',
    label: '002763.SZ,  Shenzhen Huijie Group Co., Ltd.',
    name: 'Shenzhen Huijie Group Co., Ltd.',
    symbol: '002763.SZ',
  },
  {
    value: '002765.SZ',
    label: '002765.SZ,  Landai Technology Group Corp., Ltd.',
    name: 'Landai Technology Group Corp., Ltd.',
    symbol: '002765.SZ',
  },
  {
    value: '002766.SZ',
    label: '002766.SZ,  Shenzhen Soling Industrial Co.,Ltd',
    name: 'Shenzhen Soling Industrial Co.,Ltd',
    symbol: '002766.SZ',
  },
  {
    value: '002767.SZ',
    label: '002767.SZ,  Hangzhou Innover Technology Co., Ltd.',
    name: 'Hangzhou Innover Technology Co., Ltd.',
    symbol: '002767.SZ',
  },
  {
    value: '002768.SZ',
    label: '002768.SZ,  Qingdao Gon Technology Co., Ltd.',
    name: 'Qingdao Gon Technology Co., Ltd.',
    symbol: '002768.SZ',
  },
  {
    value: '002769.SZ',
    label: '002769.SZ,  Shenzhen Prolto Supply Chain Management Co.,Ltd.',
    name: 'Shenzhen Prolto Supply Chain Management Co.,Ltd.',
    symbol: '002769.SZ',
  },
  {
    value: '002771.SZ',
    label: '002771.SZ,  Beijing Transtrue Technology Inc.',
    name: 'Beijing Transtrue Technology Inc.',
    symbol: '002771.SZ',
  },
  {
    value: '002772.SZ',
    label: '002772.SZ,  Tianshui Zhongxing Bio-technology Co.,Ltd.',
    name: 'Tianshui Zhongxing Bio-technology Co.,Ltd.',
    symbol: '002772.SZ',
  },
  {
    value: '002773.SZ',
    label: '002773.SZ,  Chengdu Kanghong Pharmaceutical Group Co., Ltd',
    name: 'Chengdu Kanghong Pharmaceutical Group Co., Ltd',
    symbol: '002773.SZ',
  },
  {
    value: '002774.SZ',
    label: '002774.SZ,  IFE Elevators Co., Ltd.',
    name: 'IFE Elevators Co., Ltd.',
    symbol: '002774.SZ',
  },
  {
    value: '002775.SZ',
    label: '002775.SZ,  Shenzhen Wenke Landscape Co., Ltd.',
    name: 'Shenzhen Wenke Landscape Co., Ltd.',
    symbol: '002775.SZ',
  },
  {
    value: '002776.SZ',
    label: '002776.SZ,  Guangdong Bobaolon Co.,Ltd.',
    name: 'Guangdong Bobaolon Co.,Ltd.',
    symbol: '002776.SZ',
  },
  {
    value: '002777.SZ',
    label: '002777.SZ,  Sichuan Jiuyuan Yinhai Software.Co.,Ltd',
    name: 'Sichuan Jiuyuan Yinhai Software.Co.,Ltd',
    symbol: '002777.SZ',
  },
  {
    value: '002778.SZ',
    label: '002778.SZ,  Jiangsu Zhongsheng Gaoke Environmental Co.,Ltd.',
    name: 'Jiangsu Zhongsheng Gaoke Environmental Co.,Ltd.',
    symbol: '002778.SZ',
  },
  {
    value: '002779.SZ',
    label: '002779.SZ,  Zhejiang Zhongjian Technology Co.,Ltd',
    name: 'Zhejiang Zhongjian Technology Co.,Ltd',
    symbol: '002779.SZ',
  },
  {
    value: '002780.SZ',
    label: '002780.SZ,  Beijing Sanfo Outdoor Products Co., Ltd',
    name: 'Beijing Sanfo Outdoor Products Co., Ltd',
    symbol: '002780.SZ',
  },
  {
    value: '002781.SZ',
    label: '002781.SZ,  Jiangxi Qixin Group Co., Ltd.',
    name: 'Jiangxi Qixin Group Co., Ltd.',
    symbol: '002781.SZ',
  },
  {
    value: '002782.SZ',
    label: '002782.SZ,  ShenZhen Click Technology Co.,LTD.',
    name: 'ShenZhen Click Technology Co.,LTD.',
    symbol: '002782.SZ',
  },
  {
    value: '002783.SZ',
    label: '002783.SZ,  Hubei Kailong Chemical Group Co., Ltd.',
    name: 'Hubei Kailong Chemical Group Co., Ltd.',
    symbol: '002783.SZ',
  },
  {
    value: '002785.SZ',
    label: '002785.SZ,  Xiamen Wanli Stone Stock Co.,Ltd',
    name: 'Xiamen Wanli Stone Stock Co.,Ltd',
    symbol: '002785.SZ',
  },
  {
    value: '002786.SZ',
    label: '002786.SZ,  Shenzhen Silver Basis Technology Co., Ltd.',
    name: 'Shenzhen Silver Basis Technology Co., Ltd.',
    symbol: '002786.SZ',
  },
  {
    value: '002787.SZ',
    label: '002787.SZ,  Suzhou Hycan Holdings Co., Ltd.',
    name: 'Suzhou Hycan Holdings Co., Ltd.',
    symbol: '002787.SZ',
  },
  {
    value: '002788.SZ',
    label: '002788.SZ,  Luyan Pharma Co.,Ltd.',
    name: 'Luyan Pharma Co.,Ltd.',
    symbol: '002788.SZ',
  },
  {
    value: '002789.SZ',
    label: '002789.SZ,  Shenzhen Jianyi Decoration Group Co., Ltd.',
    name: 'Shenzhen Jianyi Decoration Group Co., Ltd.',
    symbol: '002789.SZ',
  },
  {
    value: '002790.SZ',
    label: '002790.SZ,  Xiamen R&T Plumbing Technology Co., Ltd.',
    name: 'Xiamen R&T Plumbing Technology Co., Ltd.',
    symbol: '002790.SZ',
  },
  {
    value: '002792.SZ',
    label: '002792.SZ,  Tongyu Communication Inc.',
    name: 'Tongyu Communication Inc.',
    symbol: '002792.SZ',
  },
  {
    value: '002793.SZ',
    label: '002793.SZ,  Luoxin Pharmaceuticals Group Stock Co., Ltd.',
    name: 'Luoxin Pharmaceuticals Group Stock Co., Ltd.',
    symbol: '002793.SZ',
  },
  {
    value: '002795.SZ',
    label: '002795.SZ,  Yorhe Fluid Intelligent Control Co., Ltd.',
    name: 'Yorhe Fluid Intelligent Control Co., Ltd.',
    symbol: '002795.SZ',
  },
  {
    value: '002796.SZ',
    label: '002796.SZ,  Suzhou Shijia Science & Technology Inc.',
    name: 'Suzhou Shijia Science & Technology Inc.',
    symbol: '002796.SZ',
  },
  {
    value: '002798.SZ',
    label: '002798.SZ,  D and O Home Collection Group Co., Ltd.',
    name: 'D and O Home Collection Group Co., Ltd.',
    symbol: '002798.SZ',
  },
  {
    value: '002799.SZ',
    label: "002799.SZ,  Xi'an Global Printing Co., Ltd.",
    name: "Xi'an Global Printing Co., Ltd.",
    symbol: '002799.SZ',
  },
  {
    value: '002800.SZ',
    label: '002800.SZ,  Xinjiang Tianshun Supply Chain Co., Ltd.',
    name: 'Xinjiang Tianshun Supply Chain Co., Ltd.',
    symbol: '002800.SZ',
  },
  {
    value: '002801.SZ',
    label: '002801.SZ,  Hangzhou Weiguang Electronic Co.,Ltd.',
    name: 'Hangzhou Weiguang Electronic Co.,Ltd.',
    symbol: '002801.SZ',
  },
  {
    value: '002802.SZ',
    label: '002802.SZ,  Wuxi Honghui New Materials Technology Co., Ltd.',
    name: 'Wuxi Honghui New Materials Technology Co., Ltd.',
    symbol: '002802.SZ',
  },
  {
    value: '002803.SZ',
    label: '002803.SZ,  Xiamen Jihong Technology Co., Ltd.',
    name: 'Xiamen Jihong Technology Co., Ltd.',
    symbol: '002803.SZ',
  },
  {
    value: '002805.SZ',
    label: '002805.SZ,  Shandong Fengyuan Chemical Co., Ltd.',
    name: 'Shandong Fengyuan Chemical Co., Ltd.',
    symbol: '002805.SZ',
  },
  {
    value: '002806.SZ',
    label: '002806.SZ,  Guangdong Huafeng New Energy Technology Co.,Ltd.',
    name: 'Guangdong Huafeng New Energy Technology Co.,Ltd.',
    symbol: '002806.SZ',
  },
  {
    value: '002807.SZ',
    label: '002807.SZ,  Jiangsu Jiangyin Rural Commercial Bank Co.,LTD.',
    name: 'Jiangsu Jiangyin Rural Commercial Bank Co.,LTD.',
    symbol: '002807.SZ',
  },
  {
    value: '002808.SZ',
    label: '002808.SZ,  Suzhou Goldengreen Technologies Ltd.',
    name: 'Suzhou Goldengreen Technologies Ltd.',
    symbol: '002808.SZ',
  },
  {
    value: '002809.SZ',
    label: '002809.SZ,  Guangdong Redwall New Materials Co., Ltd.',
    name: 'Guangdong Redwall New Materials Co., Ltd.',
    symbol: '002809.SZ',
  },
  {
    value: '002810.SZ',
    label: '002810.SZ,  Shandong Head Group Co.,Ltd.',
    name: 'Shandong Head Group Co.,Ltd.',
    symbol: '002810.SZ',
  },
  {
    value: '002811.SZ',
    label: '002811.SZ,  Shenzhen Cheng Chung Design Co., Ltd.',
    name: 'Shenzhen Cheng Chung Design Co., Ltd.',
    symbol: '002811.SZ',
  },
  {
    value: '002813.SZ',
    label: '002813.SZ,  ShenZhen RoadRover Technology Co.,Ltd',
    name: 'ShenZhen RoadRover Technology Co.,Ltd',
    symbol: '002813.SZ',
  },
  {
    value: '002815.SZ',
    label: '002815.SZ,  Suntak Technology Co.,Ltd.',
    name: 'Suntak Technology Co.,Ltd.',
    symbol: '002815.SZ',
  },
  {
    value: '002816.SZ',
    label: '002816.SZ,  Shenzhen HEKEDA Precision Cleaning Equipment Co., Ltd.',
    name: 'Shenzhen HEKEDA Precision Cleaning Equipment Co., Ltd.',
    symbol: '002816.SZ',
  },
  {
    value: '002817.SZ',
    label: '002817.SZ,  Anhui Huangshan Capsule Co., Ltd.',
    name: 'Anhui Huangshan Capsule Co., Ltd.',
    symbol: '002817.SZ',
  },
  {
    value: '002818.SZ',
    label: '002818.SZ,  Chengdu Fusen Noble-House Industrial Co.,Ltd.',
    name: 'Chengdu Fusen Noble-House Industrial Co.,Ltd.',
    symbol: '002818.SZ',
  },
  {
    value: '002819.SZ',
    label: '002819.SZ,  Beijing Oriental Jicheng Co., Ltd.',
    name: 'Beijing Oriental Jicheng Co., Ltd.',
    symbol: '002819.SZ',
  },
  {
    value: '002820.SZ',
    label: '002820.SZ,  Tianjin Guifaxiang 18th Street Mahua Food Co.,Ltd.',
    name: 'Tianjin Guifaxiang 18th Street Mahua Food Co.,Ltd.',
    symbol: '002820.SZ',
  },
  {
    value: '002822.SZ',
    label: '002822.SZ,  Shenzhen Zhongzhuang Construction Group Co.,Ltd',
    name: 'Shenzhen Zhongzhuang Construction Group Co.,Ltd',
    symbol: '002822.SZ',
  },
  {
    value: '002823.SZ',
    label: '002823.SZ,  Shenzhen Kaizhong Precision Technology Co., Ltd.',
    name: 'Shenzhen Kaizhong Precision Technology Co., Ltd.',
    symbol: '002823.SZ',
  },
  {
    value: '002824.SZ',
    label: '002824.SZ,  Guangdong Hoshion Aluminium Co., Ltd.',
    name: 'Guangdong Hoshion Aluminium Co., Ltd.',
    symbol: '002824.SZ',
  },
  {
    value: '002825.SZ',
    label: '002825.SZ,  Shanghai NAR Industrial Co., Ltd',
    name: 'Shanghai NAR Industrial Co., Ltd',
    symbol: '002825.SZ',
  },
  {
    value: '002826.SZ',
    label: '002826.SZ,  Tibet AIM Pharm. Inc.',
    name: 'Tibet AIM Pharm. Inc.',
    symbol: '002826.SZ',
  },
  {
    value: '002827.SZ',
    label: '002827.SZ,  Tibet GaoZheng Explosive Co., Ltd.',
    name: 'Tibet GaoZheng Explosive Co., Ltd.',
    symbol: '002827.SZ',
  },
  {
    value: '002828.SZ',
    label: '002828.SZ,  XinJiang Beiken Energy Engineering Co.,Ltd.',
    name: 'XinJiang Beiken Energy Engineering Co.,Ltd.',
    symbol: '002828.SZ',
  },
  {
    value: '002829.SZ',
    label: '002829.SZ,  Beijing StarNeto Technology Co., Ltd.',
    name: 'Beijing StarNeto Technology Co., Ltd.',
    symbol: '002829.SZ',
  },
  {
    value: '002830.SZ',
    label: '002830.SZ,  Shenzhen Mingdiao Decoration Co., Ltd.',
    name: 'Shenzhen Mingdiao Decoration Co., Ltd.',
    symbol: '002830.SZ',
  },
  {
    value: '002831.SZ',
    label: '002831.SZ,  ShenZhen YUTO Packaging Technology Co., Ltd.',
    name: 'ShenZhen YUTO Packaging Technology Co., Ltd.',
    symbol: '002831.SZ',
  },
  {
    value: '002832.SZ',
    label: '002832.SZ,  BIEM.L.FDLKK Garment Co.,Ltd.',
    name: 'BIEM.L.FDLKK Garment Co.,Ltd.',
    symbol: '002832.SZ',
  },
  {
    value: '002835.SZ',
    label: '002835.SZ,  Shenzhen TVT Digital Technology Co., Ltd.',
    name: 'Shenzhen TVT Digital Technology Co., Ltd.',
    symbol: '002835.SZ',
  },
  {
    value: '002836.SZ',
    label: '002836.SZ,  Guangdong New Grand Long Packing Co., Ltd.',
    name: 'Guangdong New Grand Long Packing Co., Ltd.',
    symbol: '002836.SZ',
  },
  {
    value: '002837.SZ',
    label: '002837.SZ,  Shenzhen Envicool Technology Co., Ltd.',
    name: 'Shenzhen Envicool Technology Co., Ltd.',
    symbol: '002837.SZ',
  },
  {
    value: '002838.SZ',
    label: '002838.SZ,  Shandong Dawn Polymer Co.,Ltd.',
    name: 'Shandong Dawn Polymer Co.,Ltd.',
    symbol: '002838.SZ',
  },
  {
    value: '002839.SZ',
    label: '002839.SZ,  Jiangsu Zhangjiagang Rural Commercial Bank Co., Ltd',
    name: 'Jiangsu Zhangjiagang Rural Commercial Bank Co., Ltd',
    symbol: '002839.SZ',
  },
  {
    value: '002840.SZ',
    label: '002840.SZ,  Zhejiang Huatong Meat Products Co., Ltd.',
    name: 'Zhejiang Huatong Meat Products Co., Ltd.',
    symbol: '002840.SZ',
  },
  {
    value: '002842.SZ',
    label: '002842.SZ,  Guangdong Xianglu Tungsten Co., Ltd.',
    name: 'Guangdong Xianglu Tungsten Co., Ltd.',
    symbol: '002842.SZ',
  },
  {
    value: '002843.SZ',
    label: '002843.SZ,  Bichamp Cutting Technology (Hunan) Co., Ltd.',
    name: 'Bichamp Cutting Technology (Hunan) Co., Ltd.',
    symbol: '002843.SZ',
  },
  {
    value: '002845.SZ',
    label: '002845.SZ,  Shenzhen TXD Technology Co., Ltd.',
    name: 'Shenzhen TXD Technology Co., Ltd.',
    symbol: '002845.SZ',
  },
  {
    value: '002846.SZ',
    label: '002846.SZ,  Guangdong Enpack Packaging Co., Ltd.',
    name: 'Guangdong Enpack Packaging Co., Ltd.',
    symbol: '002846.SZ',
  },
  {
    value: '002847.SZ',
    label: '002847.SZ,  YanKer shop Food Co.,Ltd',
    name: 'YanKer shop Food Co.,Ltd',
    symbol: '002847.SZ',
  },
  {
    value: '002848.SZ',
    label: '002848.SZ,  Gospell Digital Technology Co., Ltd.',
    name: 'Gospell Digital Technology Co., Ltd.',
    symbol: '002848.SZ',
  },
  {
    value: '002849.SZ',
    label: '002849.SZ,  Zhejiang Viewshine Intelligent Meter Co.,Ltd',
    name: 'Zhejiang Viewshine Intelligent Meter Co.,Ltd',
    symbol: '002849.SZ',
  },
  {
    value: '002850.SZ',
    label: '002850.SZ,  Shenzhen Kedali Industry Co., Ltd.',
    name: 'Shenzhen Kedali Industry Co., Ltd.',
    symbol: '002850.SZ',
  },
  {
    value: '002851.SZ',
    label: '002851.SZ,  Shenzhen Megmeet Electrical Co., LTD',
    name: 'Shenzhen Megmeet Electrical Co., LTD',
    symbol: '002851.SZ',
  },
  {
    value: '002852.SZ',
    label: '002852.SZ,  Daodaoquan Grain and Oil Co.,Ltd.',
    name: 'Daodaoquan Grain and Oil Co.,Ltd.',
    symbol: '002852.SZ',
  },
  {
    value: '002853.SZ',
    label: '002853.SZ,  Guangdong Piano Customized Furniture Co., Ltd.',
    name: 'Guangdong Piano Customized Furniture Co., Ltd.',
    symbol: '002853.SZ',
  },
  {
    value: '002855.SZ',
    label: '002855.SZ,  Dongguan Chitwing Technology Co., Ltd.',
    name: 'Dongguan Chitwing Technology Co., Ltd.',
    symbol: '002855.SZ',
  },
  {
    value: '002856.SZ',
    label:
      '002856.SZ,  Shenzhen Magic Design & Decoration Engineering Co., Ltd.',
    name: 'Shenzhen Magic Design & Decoration Engineering Co., Ltd.',
    symbol: '002856.SZ',
  },
  {
    value: '002857.SZ',
    label: '002857.SZ,  SMS Electric Co.,Ltd.Zhengzhou',
    name: 'SMS Electric Co.,Ltd.Zhengzhou',
    symbol: '002857.SZ',
  },
  {
    value: '002858.SZ',
    label: '002858.SZ,  Lisheng Sports (hanghai) Co., Ltd.',
    name: 'Lisheng Sports (hanghai) Co., Ltd.',
    symbol: '002858.SZ',
  },
  {
    value: '002859.SZ',
    label: '002859.SZ,  Zhejiang Jiemei Electronic And Technology Co., Ltd.',
    name: 'Zhejiang Jiemei Electronic And Technology Co., Ltd.',
    symbol: '002859.SZ',
  },
  {
    value: '002860.SZ',
    label: '002860.SZ,  Hangzhou Star Shuaier Electric Appliance Co., Ltd.',
    name: 'Hangzhou Star Shuaier Electric Appliance Co., Ltd.',
    symbol: '002860.SZ',
  },
  {
    value: '002861.SZ',
    label: '002861.SZ,  YingTong Telecommunication Co.,Ltd.',
    name: 'YingTong Telecommunication Co.,Ltd.',
    symbol: '002861.SZ',
  },
  {
    value: '002862.SZ',
    label: '002862.SZ,  Shifeng Cultural Development Co., Ltd.',
    name: 'Shifeng Cultural Development Co., Ltd.',
    symbol: '002862.SZ',
  },
  {
    value: '002863.SZ',
    label: '002863.SZ,  Zhejiang Jinfei Kaida Wheel Co.,Ltd.',
    name: 'Zhejiang Jinfei Kaida Wheel Co.,Ltd.',
    symbol: '002863.SZ',
  },
  {
    value: '002864.SZ',
    label:
      '002864.SZ,  Shaanxi Panlong Pharmaceutical Group Limited By Share Ltd',
    name: 'Shaanxi Panlong Pharmaceutical Group Limited By Share Ltd',
    symbol: '002864.SZ',
  },
  {
    value: '002865.SZ',
    label: '002865.SZ,  Hainan Drinda New Energy Technology Co., Ltd.',
    name: 'Hainan Drinda New Energy Technology Co., Ltd.',
    symbol: '002865.SZ',
  },
  {
    value: '002866.SZ',
    label: '002866.SZ,  Jiangsu Transimage Technology Co., Ltd.',
    name: 'Jiangsu Transimage Technology Co., Ltd.',
    symbol: '002866.SZ',
  },
  {
    value: '002867.SZ',
    label: '002867.SZ,  Chow Tai Seng Jewellery Co., Ltd.',
    name: 'Chow Tai Seng Jewellery Co., Ltd.',
    symbol: '002867.SZ',
  },
  {
    value: '002868.SZ',
    label: '002868.SZ,  Lifecome Biochemistry Co.,Ltd.',
    name: 'Lifecome Biochemistry Co.,Ltd.',
    symbol: '002868.SZ',
  },
  {
    value: '002869.SZ',
    label: '002869.SZ,  Shenzhen Genvict Technologies Co., Ltd.',
    name: 'Shenzhen Genvict Technologies Co., Ltd.',
    symbol: '002869.SZ',
  },
  {
    value: '002870.SZ',
    label: '002870.SZ,  Guangdong Senssun Weighing Apparatus Group Ltd.',
    name: 'Guangdong Senssun Weighing Apparatus Group Ltd.',
    symbol: '002870.SZ',
  },
  {
    value: '002871.SZ',
    label: '002871.SZ,  Qingdao Weflo Valve Co., Ltd.',
    name: 'Qingdao Weflo Valve Co., Ltd.',
    symbol: '002871.SZ',
  },
  {
    value: '002872.SZ',
    label: '002872.SZ,  Tiansheng Pharmaceutical Group Co., Ltd.',
    name: 'Tiansheng Pharmaceutical Group Co., Ltd.',
    symbol: '002872.SZ',
  },
  {
    value: '002873.SZ',
    label: '002873.SZ,  Guiyang Xintian Pharmaceutical Co.,Ltd.',
    name: 'Guiyang Xintian Pharmaceutical Co.,Ltd.',
    symbol: '002873.SZ',
  },
  {
    value: '002875.SZ',
    label: '002875.SZ,  Annil Co.,Ltd',
    name: 'Annil Co.,Ltd',
    symbol: '002875.SZ',
  },
  {
    value: '002876.SZ',
    label: '002876.SZ,  Shenzhen Sunnypol Optoelectronics Co.,Ltd.',
    name: 'Shenzhen Sunnypol Optoelectronics Co.,Ltd.',
    symbol: '002876.SZ',
  },
  {
    value: '002877.SZ',
    label: '002877.SZ,  Wuxi Smart Auto-Control Engineering Co., Ltd.',
    name: 'Wuxi Smart Auto-Control Engineering Co., Ltd.',
    symbol: '002877.SZ',
  },
  {
    value: '002878.SZ',
    label: '002878.SZ,  Beijing Yuanlong Yato Culture Dissemination Co.,Ltd.',
    name: 'Beijing Yuanlong Yato Culture Dissemination Co.,Ltd.',
    symbol: '002878.SZ',
  },
  {
    value: '002879.SZ',
    label: '002879.SZ,  Chang Lan Electric Technology Co.,Ltd.',
    name: 'Chang Lan Electric Technology Co.,Ltd.',
    symbol: '002879.SZ',
  },
  {
    value: '002881.SZ',
    label: '002881.SZ,  MeiG Smart Technology Co., Ltd',
    name: 'MeiG Smart Technology Co., Ltd',
    symbol: '002881.SZ',
  },
  {
    value: '002882.SZ',
    label: '002882.SZ,  Jinlongyu Group Co., Ltd.',
    name: 'Jinlongyu Group Co., Ltd.',
    symbol: '002882.SZ',
  },
  {
    value: '002883.SZ',
    label: '002883.SZ,  Jiangsu Zhongshe Group Co., Ltd.',
    name: 'Jiangsu Zhongshe Group Co., Ltd.',
    symbol: '002883.SZ',
  },
  {
    value: '002884.SZ',
    label: '002884.SZ,  Guangdong Lingxiao Pump Industry Co.,Ltd.',
    name: 'Guangdong Lingxiao Pump Industry Co.,Ltd.',
    symbol: '002884.SZ',
  },
  {
    value: '002885.SZ',
    label: '002885.SZ,  Shenzhen JingQuanHua Electronics Co.,Ltd.',
    name: 'Shenzhen JingQuanHua Electronics Co.,Ltd.',
    symbol: '002885.SZ',
  },
  {
    value: '002886.SZ',
    label: '002886.SZ,  Shenzhen WOTE Advanced Materials Co., Ltd',
    name: 'Shenzhen WOTE Advanced Materials Co., Ltd',
    symbol: '002886.SZ',
  },
  {
    value: '002887.SZ',
    label:
      '002887.SZ,  Tianjin LVYIN Landscape and Ecology Construction Co., Ltd',
    name: 'Tianjin LVYIN Landscape and Ecology Construction Co., Ltd',
    symbol: '002887.SZ',
  },
  {
    value: '002888.SZ',
    label: '002888.SZ,  HiVi Acoustics Technology Co., Ltd',
    name: 'HiVi Acoustics Technology Co., Ltd',
    symbol: '002888.SZ',
  },
  {
    value: '002889.SZ',
    label: '002889.SZ,  Shenzhen Easttop Supply Chain Management Co., Ltd.',
    name: 'Shenzhen Easttop Supply Chain Management Co., Ltd.',
    symbol: '002889.SZ',
  },
  {
    value: '002890.SZ',
    label: '002890.SZ,  Shandong Hongyu Agricultural Machinery Co., Ltd.',
    name: 'Shandong Hongyu Agricultural Machinery Co., Ltd.',
    symbol: '002890.SZ',
  },
  {
    value: '002891.SZ',
    label: '002891.SZ,  Yantai China Pet Foods Co., Ltd.',
    name: 'Yantai China Pet Foods Co., Ltd.',
    symbol: '002891.SZ',
  },
  {
    value: '002892.SZ',
    label: '002892.SZ,  Keli Motor Group Co., Ltd.',
    name: 'Keli Motor Group Co., Ltd.',
    symbol: '002892.SZ',
  },
  {
    value: '002893.SZ',
    label: '002893.SZ,  Beijing Huayuanyitong Thermal Technology Co., Ltd.',
    name: 'Beijing Huayuanyitong Thermal Technology Co., Ltd.',
    symbol: '002893.SZ',
  },
  {
    value: '002895.SZ',
    label: '002895.SZ,  Guizhou Chanhen Chemical Corporation',
    name: 'Guizhou Chanhen Chemical Corporation',
    symbol: '002895.SZ',
  },
  {
    value: '002896.SZ',
    label:
      '002896.SZ,  Ningbo ZhongDa Leader Intelligent Transmission Co., Ltd.',
    name: 'Ningbo ZhongDa Leader Intelligent Transmission Co., Ltd.',
    symbol: '002896.SZ',
  },
  {
    value: '002897.SZ',
    label: '002897.SZ,  Wenzhou Yihua Connector Co., Ltd.',
    name: 'Wenzhou Yihua Connector Co., Ltd.',
    symbol: '002897.SZ',
  },
  {
    value: '002898.SZ',
    label: '002898.SZ,  Sailong Pharmaceutical Group Co.,Ltd.',
    name: 'Sailong Pharmaceutical Group Co.,Ltd.',
    symbol: '002898.SZ',
  },
  {
    value: '002899.SZ',
    label: '002899.SZ,  Impulse (Qingdao) Health Tech Co.,Ltd.',
    name: 'Impulse (Qingdao) Health Tech Co.,Ltd.',
    symbol: '002899.SZ',
  },
  {
    value: '002900.SZ',
    label: '002900.SZ,  Harbin Medisan Pharmaceutical Co., Ltd.',
    name: 'Harbin Medisan Pharmaceutical Co., Ltd.',
    symbol: '002900.SZ',
  },
  {
    value: '002901.SZ',
    label: '002901.SZ,  Double Medical Technology Inc.',
    name: 'Double Medical Technology Inc.',
    symbol: '002901.SZ',
  },
  {
    value: '002902.SZ',
    label: '002902.SZ,  Dongguan Mentech Optical & Magnetic Co., Ltd.',
    name: 'Dongguan Mentech Optical & Magnetic Co., Ltd.',
    symbol: '002902.SZ',
  },
  {
    value: '002903.SZ',
    label: '002903.SZ,  Yuhuan CNC Machine Tool Co.,Ltd.',
    name: 'Yuhuan CNC Machine Tool Co.,Ltd.',
    symbol: '002903.SZ',
  },
  {
    value: '002905.SZ',
    label: '002905.SZ,  Guangzhou Jinyi Media Corporation',
    name: 'Guangzhou Jinyi Media Corporation',
    symbol: '002905.SZ',
  },
  {
    value: '002906.SZ',
    label: '002906.SZ,  Foryou Corporation',
    name: 'Foryou Corporation',
    symbol: '002906.SZ',
  },
  {
    value: '002907.SZ',
    label: '002907.SZ,  Chongqing Pharscin Pharmaceutical Co., Ltd.',
    name: 'Chongqing Pharscin Pharmaceutical Co., Ltd.',
    symbol: '002907.SZ',
  },
  {
    value: '002908.SZ',
    label: '002908.SZ,  Guangdong Tecsun Science & Technology Co.,Ltd.',
    name: 'Guangdong Tecsun Science & Technology Co.,Ltd.',
    symbol: '002908.SZ',
  },
  {
    value: '002909.SZ',
    label: '002909.SZ,  Guangzhou Jointas Chemical Co., Ltd.',
    name: 'Guangzhou Jointas Chemical Co., Ltd.',
    symbol: '002909.SZ',
  },
  {
    value: '002910.SZ',
    label: '002910.SZ,  Lanzhou Zhuangyuan Pasture Co., Ltd.',
    name: 'Lanzhou Zhuangyuan Pasture Co., Ltd.',
    symbol: '002910.SZ',
  },
  {
    value: '002911.SZ',
    label: '002911.SZ,  Foran Energy Group Co.,Ltd.',
    name: 'Foran Energy Group Co.,Ltd.',
    symbol: '002911.SZ',
  },
  {
    value: '002912.SZ',
    label: '002912.SZ,  Shenzhen Sinovatio Technology Co., Ltd.',
    name: 'Shenzhen Sinovatio Technology Co., Ltd.',
    symbol: '002912.SZ',
  },
  {
    value: '002913.SZ',
    label: '002913.SZ,  Aoshikang Technology Co., Ltd.',
    name: 'Aoshikang Technology Co., Ltd.',
    symbol: '002913.SZ',
  },
  {
    value: '002915.SZ',
    label: '002915.SZ,  Zhejiang Zhongxin Fluoride Materials Co.,Ltd',
    name: 'Zhejiang Zhongxin Fluoride Materials Co.,Ltd',
    symbol: '002915.SZ',
  },
  {
    value: '002917.SZ',
    label:
      '002917.SZ,  Shenzhen King Explorer Science and Technology Corporation',
    name: 'Shenzhen King Explorer Science and Technology Corporation',
    symbol: '002917.SZ',
  },
  {
    value: '002918.SZ',
    label: '002918.SZ,  Monalisa Group CO.,Ltd',
    name: 'Monalisa Group CO.,Ltd',
    symbol: '002918.SZ',
  },
  {
    value: '002919.SZ',
    label: '002919.SZ,  Mingchen Health Co.,Ltd.',
    name: 'Mingchen Health Co.,Ltd.',
    symbol: '002919.SZ',
  },
  {
    value: '002921.SZ',
    label: '002921.SZ,  Shandong Liancheng Precision Manufacturing Co., Ltd',
    name: 'Shandong Liancheng Precision Manufacturing Co., Ltd',
    symbol: '002921.SZ',
  },
  {
    value: '002922.SZ',
    label: '002922.SZ,  Eaglerise Electric & Electronic (China) Co., Ltd',
    name: 'Eaglerise Electric & Electronic (China) Co., Ltd',
    symbol: '002922.SZ',
  },
  {
    value: '002923.SZ',
    label: '002923.SZ,  Zhuhai Rundu Pharmaceutical Co., Ltd.',
    name: 'Zhuhai Rundu Pharmaceutical Co., Ltd.',
    symbol: '002923.SZ',
  },
  {
    value: '002927.SZ',
    label: '002927.SZ,  Guizhou Taiyong-Changzheng Technology Co.,Ltd.',
    name: 'Guizhou Taiyong-Changzheng Technology Co.,Ltd.',
    symbol: '002927.SZ',
  },
  {
    value: '002928.SZ',
    label: '002928.SZ,  China Express Airlines Co.,LTD',
    name: 'China Express Airlines Co.,LTD',
    symbol: '002928.SZ',
  },
  {
    value: '002929.SZ',
    label: '002929.SZ,  Runjian Co., Ltd.',
    name: 'Runjian Co., Ltd.',
    symbol: '002929.SZ',
  },
  {
    value: '002930.SZ',
    label: '002930.SZ,  Guangdong Great River Smarter Logistics Co., Ltd.',
    name: 'Guangdong Great River Smarter Logistics Co., Ltd.',
    symbol: '002930.SZ',
  },
  {
    value: '002931.SZ',
    label: '002931.SZ,  Zhejiang Fenglong Electric Co., Ltd.',
    name: 'Zhejiang Fenglong Electric Co., Ltd.',
    symbol: '002931.SZ',
  },
  {
    value: '002932.SZ',
    label: '002932.SZ,  Wuhan Easy Diagnosis Biomedicine Co.,Ltd.',
    name: 'Wuhan Easy Diagnosis Biomedicine Co.,Ltd.',
    symbol: '002932.SZ',
  },
  {
    value: '002933.SZ',
    label: '002933.SZ,  Beijing Emerging Eastern Aviation Equipment Co., Ltd.',
    name: 'Beijing Emerging Eastern Aviation Equipment Co., Ltd.',
    symbol: '002933.SZ',
  },
  {
    value: '002935.SZ',
    label: '002935.SZ,  Chengdu Spaceon Electronics Co., Ltd.',
    name: 'Chengdu Spaceon Electronics Co., Ltd.',
    symbol: '002935.SZ',
  },
  {
    value: '002937.SZ',
    label: '002937.SZ,  Ningbo Sunrise Elc Technology Co.,Ltd',
    name: 'Ningbo Sunrise Elc Technology Co.,Ltd',
    symbol: '002937.SZ',
  },
  {
    value: '002940.SZ',
    label: '002940.SZ,  Zhejiang AngLiKang Pharmaceutical CO.,LTD.',
    name: 'Zhejiang AngLiKang Pharmaceutical CO.,LTD.',
    symbol: '002940.SZ',
  },
  {
    value: '002941.SZ',
    label: '002941.SZ,  Xinjiang Communications Construction Group Co., Ltd.',
    name: 'Xinjiang Communications Construction Group Co., Ltd.',
    symbol: '002941.SZ',
  },
  {
    value: '002942.SZ',
    label: '002942.SZ,  Zhejiang XinNong Chemical Co.,Ltd.',
    name: 'Zhejiang XinNong Chemical Co.,Ltd.',
    symbol: '002942.SZ',
  },
  {
    value: '002943.SZ',
    label: '002943.SZ,  Hunan Yujing Machinery Co.,Ltd',
    name: 'Hunan Yujing Machinery Co.,Ltd',
    symbol: '002943.SZ',
  },
  {
    value: '002945.SZ',
    label: '002945.SZ,  ChinaLin Securities Co., Ltd.',
    name: 'ChinaLin Securities Co., Ltd.',
    symbol: '002945.SZ',
  },
  {
    value: '002946.SZ',
    label: '002946.SZ,  New Hope Dairy Co., Ltd.',
    name: 'New Hope Dairy Co., Ltd.',
    symbol: '002946.SZ',
  },
  {
    value: '002947.SZ',
    label: '002947.SZ,  Suzhou Hengmingda Electronic Technology Co., Ltd.',
    name: 'Suzhou Hengmingda Electronic Technology Co., Ltd.',
    symbol: '002947.SZ',
  },
  {
    value: '002948.SZ',
    label: '002948.SZ,  Bank of Qingdao Co., Ltd.',
    name: 'Bank of Qingdao Co., Ltd.',
    symbol: '002948.SZ',
  },
  {
    value: '002949.SZ',
    label: '002949.SZ,  Shenzhen Capol International & Associatesco., Ltd',
    name: 'Shenzhen Capol International & Associatesco., Ltd',
    symbol: '002949.SZ',
  },
  {
    value: '002950.SZ',
    label: '002950.SZ,  Allmed Medical Products Co., Ltd',
    name: 'Allmed Medical Products Co., Ltd',
    symbol: '002950.SZ',
  },
  {
    value: '002951.SZ',
    label: '002951.SZ,  Sichuan Jinshi Technology Co.,Ltd',
    name: 'Sichuan Jinshi Technology Co.,Ltd',
    symbol: '002951.SZ',
  },
  {
    value: '002952.SZ',
    label: '002952.SZ,  Yes Optoelectronics (Group) Co., Ltd.',
    name: 'Yes Optoelectronics (Group) Co., Ltd.',
    symbol: '002952.SZ',
  },
  {
    value: '002953.SZ',
    label: '002953.SZ,  Guangdong Rifeng Electric Cable Co., Ltd.',
    name: 'Guangdong Rifeng Electric Cable Co., Ltd.',
    symbol: '002953.SZ',
  },
  {
    value: '002955.SZ',
    label: '002955.SZ,  Hitevision Co., Ltd.',
    name: 'Hitevision Co., Ltd.',
    symbol: '002955.SZ',
  },
  {
    value: '002956.SZ',
    label: '002956.SZ,  Guilin Seamild Foods Co., Ltd',
    name: 'Guilin Seamild Foods Co., Ltd',
    symbol: '002956.SZ',
  },
  {
    value: '002957.SZ',
    label: '002957.SZ,  Shenzhen Colibri Technologies Co., Ltd.',
    name: 'Shenzhen Colibri Technologies Co., Ltd.',
    symbol: '002957.SZ',
  },
  {
    value: '002959.SZ',
    label: '002959.SZ,  Bear Electric Appliance Co.,Ltd.',
    name: 'Bear Electric Appliance Co.,Ltd.',
    symbol: '002959.SZ',
  },
  {
    value: '002960.SZ',
    label: '002960.SZ,  Jade Bird Fire Co., Ltd.',
    name: 'Jade Bird Fire Co., Ltd.',
    symbol: '002960.SZ',
  },
  {
    value: '002961.SZ',
    label: '002961.SZ,  Ruida Futures Co., Ltd.',
    name: 'Ruida Futures Co., Ltd.',
    symbol: '002961.SZ',
  },
  {
    value: '002962.SZ',
    label: '002962.SZ,  Hubei W-olf Photoelectric Technology Co., Ltd.',
    name: 'Hubei W-olf Photoelectric Technology Co., Ltd.',
    symbol: '002962.SZ',
  },
  {
    value: '002963.SZ',
    label: '002963.SZ,  Haoersai Technology Group Corp., Ltd.',
    name: 'Haoersai Technology Group Corp., Ltd.',
    symbol: '002963.SZ',
  },
  {
    value: '002965.SZ',
    label: '002965.SZ,  Lucky Harvest Co., Ltd.',
    name: 'Lucky Harvest Co., Ltd.',
    symbol: '002965.SZ',
  },
  {
    value: '002966.SZ',
    label: '002966.SZ,  Bank of Suzhou Co., Ltd.',
    name: 'Bank of Suzhou Co., Ltd.',
    symbol: '002966.SZ',
  },
  {
    value: '002968.SZ',
    label: '002968.SZ,  New DaZheng Property Group Co., LTD',
    name: 'New DaZheng Property Group Co., LTD',
    symbol: '002968.SZ',
  },
  {
    value: '002969.SZ',
    label: '002969.SZ,  Jiamei Food Packaging (Chuzhou) Co.,Ltd',
    name: 'Jiamei Food Packaging (Chuzhou) Co.,Ltd',
    symbol: '002969.SZ',
  },
  {
    value: '002970.SZ',
    label: '002970.SZ,  Streamax Technology Co., Ltd.',
    name: 'Streamax Technology Co., Ltd.',
    symbol: '002970.SZ',
  },
  {
    value: '002971.SZ',
    label: '002971.SZ,  Hubei Heyuan Gas Co.,Ltd.',
    name: 'Hubei Heyuan Gas Co.,Ltd.',
    symbol: '002971.SZ',
  },
  {
    value: '002972.SZ',
    label: '002972.SZ,  Shenzhen Keanda Electronic Technology Corp., Ltd.',
    name: 'Shenzhen Keanda Electronic Technology Corp., Ltd.',
    symbol: '002972.SZ',
  },
  {
    value: '002973.SZ',
    label: '002973.SZ,  Qiaoyin City Management Co.,Ltd.',
    name: 'Qiaoyin City Management Co.,Ltd.',
    symbol: '002973.SZ',
  },
  {
    value: '002975.SZ',
    label: '002975.SZ,  Zhuhai Bojay Electronics Co.,Ltd.',
    name: 'Zhuhai Bojay Electronics Co.,Ltd.',
    symbol: '002975.SZ',
  },
  {
    value: '002976.SZ',
    label: '002976.SZ,  Suzhou Cheersson Precision Metal Forming Co., Ltd.',
    name: 'Suzhou Cheersson Precision Metal Forming Co., Ltd.',
    symbol: '002976.SZ',
  },
  {
    value: '002977.SZ',
    label: '002977.SZ,  Chengdu Tianjian Technology Co., Ltd.',
    name: 'Chengdu Tianjian Technology Co., Ltd.',
    symbol: '002977.SZ',
  },
  {
    value: '002978.SZ',
    label: '002978.SZ,  Sichuan Anning Iron and Titanium Co.,Ltd.',
    name: 'Sichuan Anning Iron and Titanium Co.,Ltd.',
    symbol: '002978.SZ',
  },
  {
    value: '002979.SZ',
    label: '002979.SZ,  China Leadshine Technology Co., Ltd.',
    name: 'China Leadshine Technology Co., Ltd.',
    symbol: '002979.SZ',
  },
  {
    value: '002980.SZ',
    label: '002980.SZ,  Shenzhen Everbest Machinery Industry Co., Ltd.',
    name: 'Shenzhen Everbest Machinery Industry Co., Ltd.',
    symbol: '002980.SZ',
  },
  {
    value: '002981.SZ',
    label: '002981.SZ,  Risuntek Inc.',
    name: 'Risuntek Inc.',
    symbol: '002981.SZ',
  },
  {
    value: '002982.SZ',
    label: '002982.SZ,  Hunan Xiangjia Animal Husbandry Co.,Ltd',
    name: 'Hunan Xiangjia Animal Husbandry Co.,Ltd',
    symbol: '002982.SZ',
  },
  {
    value: '002983.SZ',
    label: '002983.SZ,  Anhui Coreach Technology Co.,Ltd',
    name: 'Anhui Coreach Technology Co.,Ltd',
    symbol: '002983.SZ',
  },
  {
    value: '002984.SZ',
    label: '002984.SZ,  Qingdao Sentury Tire Co., Ltd.',
    name: 'Qingdao Sentury Tire Co., Ltd.',
    symbol: '002984.SZ',
  },
  {
    value: '002985.SZ',
    label: '002985.SZ,  Beijing Beimo High-tech Frictional Material Co.,Ltd',
    name: 'Beijing Beimo High-tech Frictional Material Co.,Ltd',
    symbol: '002985.SZ',
  },
  {
    value: '002986.SZ',
    label: '002986.SZ,  Hunan Yussen Energy Technology Co., Ltd.',
    name: 'Hunan Yussen Energy Technology Co., Ltd.',
    symbol: '002986.SZ',
  },
  {
    value: '002987.SZ',
    label: '002987.SZ,  Northking Information Technology Co., Ltd.',
    name: 'Northking Information Technology Co., Ltd.',
    symbol: '002987.SZ',
  },
  {
    value: '002988.SZ',
    label: '002988.SZ,  Guangdong Haomei New Material Co., Ltd.',
    name: 'Guangdong Haomei New Material Co., Ltd.',
    symbol: '002988.SZ',
  },
  {
    value: '002989.SZ',
    label: '002989.SZ,  Shenzhen Strongteam Decoration Engineering Co., Ltd.',
    name: 'Shenzhen Strongteam Decoration Engineering Co., Ltd.',
    symbol: '002989.SZ',
  },
  {
    value: '002990.SZ',
    label: '002990.SZ,  Maxvision Technology Corp.',
    name: 'Maxvision Technology Corp.',
    symbol: '002990.SZ',
  },
  {
    value: '002991.SZ',
    label: '002991.SZ,  Ganyuan Foods Co., Ltd.',
    name: 'Ganyuan Foods Co., Ltd.',
    symbol: '002991.SZ',
  },
  {
    value: '002992.SZ',
    label: '002992.SZ,  Shenzhen Baoming Technology Co.,Ltd.',
    name: 'Shenzhen Baoming Technology Co.,Ltd.',
    symbol: '002992.SZ',
  },
  {
    value: '002993.SZ',
    label: '002993.SZ,  Dongguan Aohai Technology Co., Ltd.',
    name: 'Dongguan Aohai Technology Co., Ltd.',
    symbol: '002993.SZ',
  },
  {
    value: '002995.SZ',
    label:
      '002995.SZ,  Beijing Quanshi World Online Network Information Co., Ltd.',
    name: 'Beijing Quanshi World Online Network Information Co., Ltd.',
    symbol: '002995.SZ',
  },
  {
    value: '002996.SZ',
    label: '002996.SZ,  Chongqing Shunbo Aluminum Co.,Ltd.',
    name: 'Chongqing Shunbo Aluminum Co.,Ltd.',
    symbol: '002996.SZ',
  },
  {
    value: '002997.SZ',
    label: '002997.SZ,  Rayhoo Motor Dies Co.,Ltd.',
    name: 'Rayhoo Motor Dies Co.,Ltd.',
    symbol: '002997.SZ',
  },
  {
    value: '002998.SZ',
    label:
      '002998.SZ,  Elite Color Environmental Resources Science & Technology Co., Ltd.',
    name: 'Elite Color Environmental Resources Science & Technology Co., Ltd.',
    symbol: '002998.SZ',
  },
  {
    value: '002999.SZ',
    label:
      '002999.SZ,  Guangdong Tianhe Agricultural Means of Production Co., Ltd.',
    name: 'Guangdong Tianhe Agricultural Means of Production Co., Ltd.',
    symbol: '002999.SZ',
  },
  {
    value: '003000.SZ',
    label: '003000.SZ,  Jinzai Food Group Co.,Ltd.',
    name: 'Jinzai Food Group Co.,Ltd.',
    symbol: '003000.SZ',
  },
  {
    value: '003001.SZ',
    label: '003001.SZ,  Zhongyan Technology Co., Ltd.',
    name: 'Zhongyan Technology Co., Ltd.',
    symbol: '003001.SZ',
  },
  {
    value: '003002.SZ',
    label: '003002.SZ,  Shanxi Huhua Group Co., Ltd.',
    name: 'Shanxi Huhua Group Co., Ltd.',
    symbol: '003002.SZ',
  },
  {
    value: '003003.SZ',
    label: '003003.SZ,  Guangdong Tengen Industrial Group Co.,Ltd.',
    name: 'Guangdong Tengen Industrial Group Co.,Ltd.',
    symbol: '003003.SZ',
  },
  {
    value: '003004.SZ',
    label: '003004.SZ,  Beijing Telesound Electronics Co., Ltd.',
    name: 'Beijing Telesound Electronics Co., Ltd.',
    symbol: '003004.SZ',
  },
  {
    value: '003005.SZ',
    label: '003005.SZ,  Beijing Jingyeda Technology Co.,Ltd.',
    name: 'Beijing Jingyeda Technology Co.,Ltd.',
    symbol: '003005.SZ',
  },
  {
    value: '003006.SZ',
    label: '003006.SZ,  Chongqing Baiya Sanitary Products Co., Ltd.',
    name: 'Chongqing Baiya Sanitary Products Co., Ltd.',
    symbol: '003006.SZ',
  },
  {
    value: '003007.SZ',
    label: '003007.SZ,  Beijing ZZNode Technologies Co., Ltd.',
    name: 'Beijing ZZNode Technologies Co., Ltd.',
    symbol: '003007.SZ',
  },
  {
    value: '003008.SZ',
    label: '003008.SZ,  Xuchang KETOP Testing Research Institute Co.,Ltd',
    name: 'Xuchang KETOP Testing Research Institute Co.,Ltd',
    symbol: '003008.SZ',
  },
  {
    value: '003009.SZ',
    label: '003009.SZ,  Shaanxi Zhongtian Rocket Technology Co., Ltd',
    name: 'Shaanxi Zhongtian Rocket Technology Co., Ltd',
    symbol: '003009.SZ',
  },
  {
    value: '003010.SZ',
    label: '003010.SZ,  Guangzhou Ruoyuchen Technology Co.,Ltd.',
    name: 'Guangzhou Ruoyuchen Technology Co.,Ltd.',
    symbol: '003010.SZ',
  },
  {
    value: '003011.SZ',
    label: '003011.SZ,  Zhejiang Walrus New Material Co.,Ltd.',
    name: 'Zhejiang Walrus New Material Co.,Ltd.',
    symbol: '003011.SZ',
  },
  {
    value: '003012.SZ',
    label: '003012.SZ,  Guangdong Dongpeng Holdings Co., Ltd.',
    name: 'Guangdong Dongpeng Holdings Co., Ltd.',
    symbol: '003012.SZ',
  },
  {
    value: '003013.SZ',
    label: '003013.SZ,  Guangzhou Metro Design & Research Institute Co., Ltd.',
    name: 'Guangzhou Metro Design & Research Institute Co., Ltd.',
    symbol: '003013.SZ',
  },
  {
    value: '003015.SZ',
    label: '003015.SZ,  Jiangsu Rijiu Optoelectronics Jointstock Co., Ltd',
    name: 'Jiangsu Rijiu Optoelectronics Jointstock Co., Ltd',
    symbol: '003015.SZ',
  },
  {
    value: '003016.SZ',
    label: '003016.SZ,  Xin Hee Co., Ltd.',
    name: 'Xin Hee Co., Ltd.',
    symbol: '003016.SZ',
  },
  {
    value: '003017.SZ',
    label: '003017.SZ,  Zhejiang Dayang Biology Technology Co.,Ltd.',
    name: 'Zhejiang Dayang Biology Technology Co.,Ltd.',
    symbol: '003017.SZ',
  },
  {
    value: '003018.SZ',
    label: '003018.SZ,  JinFu Technology Co., Ltd.',
    name: 'JinFu Technology Co., Ltd.',
    symbol: '003018.SZ',
  },
  {
    value: '003019.SZ',
    label: '003019.SZ,  TES Touch Embedded Solutions (Xiamen) Co., Ltd.',
    name: 'TES Touch Embedded Solutions (Xiamen) Co., Ltd.',
    symbol: '003019.SZ',
  },
  {
    value: '003020.SZ',
    label: '003020.SZ,  Hefei Lifeon Pharmaceutical Co., Ltd.',
    name: 'Hefei Lifeon Pharmaceutical Co., Ltd.',
    symbol: '003020.SZ',
  },
  {
    value: '003021.SZ',
    label: '003021.SZ,  Shenzhen Zhaowei Machinery & Electronic Co., Ltd.',
    name: 'Shenzhen Zhaowei Machinery & Electronic Co., Ltd.',
    symbol: '003021.SZ',
  },
  {
    value: '003022.SZ',
    label: '003022.SZ,  Levima Advanced Materials Corporation',
    name: 'Levima Advanced Materials Corporation',
    symbol: '003022.SZ',
  },
  {
    value: '003023.SZ',
    label: '003023.SZ,  Chengdu Rainbow Appliance (Group) Shares Co., Ltd.',
    name: 'Chengdu Rainbow Appliance (Group) Shares Co., Ltd.',
    symbol: '003023.SZ',
  },
  {
    value: '003025.SZ',
    label: '003025.SZ,  Sijin Intelligent Forming Machinery Co., Ltd.',
    name: 'Sijin Intelligent Forming Machinery Co., Ltd.',
    symbol: '003025.SZ',
  },
  {
    value: '003026.SZ',
    label: '003026.SZ,  Zhejiang MTCN Technology Co.,Ltd.',
    name: 'Zhejiang MTCN Technology Co.,Ltd.',
    symbol: '003026.SZ',
  },
  {
    value: '003027.SZ',
    label: '003027.SZ,  Tongxing Environmental Protection Technology Co.,Ltd',
    name: 'Tongxing Environmental Protection Technology Co.,Ltd',
    symbol: '003027.SZ',
  },
  {
    value: '003028.SZ',
    label: '003028.SZ,  Genbyte Technology Inc.',
    name: 'Genbyte Technology Inc.',
    symbol: '003028.SZ',
  },
  {
    value: '003029.SZ',
    label:
      '003029.SZ,  Jilin University Zhengyuan Information Technologies Co., Ltd.',
    name: 'Jilin University Zhengyuan Information Technologies Co., Ltd.',
    symbol: '003029.SZ',
  },
  {
    value: '003030.SZ',
    label: '003030.SZ,  Zuming Bean Products Co., Ltd',
    name: 'Zuming Bean Products Co., Ltd',
    symbol: '003030.SZ',
  },
  {
    value: '003031.SZ',
    label: '003031.SZ,  Hebei Sinopack Electronic Technology Co.,Ltd.',
    name: 'Hebei Sinopack Electronic Technology Co.,Ltd.',
    symbol: '003031.SZ',
  },
  {
    value: '003032.SZ',
    label:
      '003032.SZ,  Jiangsu Chuanzhi Podcast Education Technology Co., Ltd.',
    name: 'Jiangsu Chuanzhi Podcast Education Technology Co., Ltd.',
    symbol: '003032.SZ',
  },
  {
    value: '003033.SZ',
    label: '003033.SZ,  Qingdao CHOHO Industrial Co., Ltd',
    name: 'Qingdao CHOHO Industrial Co., Ltd',
    symbol: '003033.SZ',
  },
  {
    value: '003035.SZ',
    label:
      '003035.SZ,  China Southern Power Grid Energy Efficiency & Clean Energy Co.,Ltd.',
    name: 'China Southern Power Grid Energy Efficiency & Clean Energy Co.,Ltd.',
    symbol: '003035.SZ',
  },
  {
    value: '003036.SZ',
    label: '003036.SZ,  Zhejiang Taitan Co.,Ltd.',
    name: 'Zhejiang Taitan Co.,Ltd.',
    symbol: '003036.SZ',
  },
  {
    value: '003037.SZ',
    label: '003037.SZ,  Guangdong Sanhe Pile Co., Ltd.',
    name: 'Guangdong Sanhe Pile Co., Ltd.',
    symbol: '003037.SZ',
  },
  {
    value: '003038.SZ',
    label: '003038.SZ,  Anhui Xinbo Aluminum Co., Ltd.',
    name: 'Anhui Xinbo Aluminum Co., Ltd.',
    symbol: '003038.SZ',
  },
  {
    value: '003039.SZ',
    label: '003039.SZ,  Guangdong Shunkong Development Co.,Ltd.',
    name: 'Guangdong Shunkong Development Co.,Ltd.',
    symbol: '003039.SZ',
  },
  {
    value: '003040.SZ',
    label: '003040.SZ,  Chutian Dragon Co., Ltd.',
    name: 'Chutian Dragon Co., Ltd.',
    symbol: '003040.SZ',
  },
  {
    value: '003041.SZ',
    label: '003041.SZ,  Zhejiang Truelove Vogue Co., Ltd.',
    name: 'Zhejiang Truelove Vogue Co., Ltd.',
    symbol: '003041.SZ',
  },
  {
    value: '003042.SZ',
    label: '003042.SZ,  Shandong Sino-Agri United Biotechnology Co.,Ltd',
    name: 'Shandong Sino-Agri United Biotechnology Co.,Ltd',
    symbol: '003042.SZ',
  },
  {
    value: '003043.SZ',
    label: '003043.SZ,  Suzhou Huaya Intelligence Technology Co., Ltd.',
    name: 'Suzhou Huaya Intelligence Technology Co., Ltd.',
    symbol: '003043.SZ',
  },
  {
    value: '1110.TW',
    label: '1110.TW,  Southeast Cement Corporation',
    name: 'Southeast Cement Corporation',
    symbol: '1110.TW',
  },
  {
    value: '1213.TW',
    label: '1213.TW,  Oceanic Beverages Co., Inc.',
    name: 'Oceanic Beverages Co., Inc.',
    symbol: '1213.TW',
  },
  {
    value: '1233.TW',
    label: '1233.TW,  Ten Ren Tea Co., Ltd.',
    name: 'Ten Ren Tea Co., Ltd.',
    symbol: '1233.TW',
  },
  {
    value: '1234.TW',
    label: '1234.TW,  Hey Song Corporation',
    name: 'Hey Song Corporation',
    symbol: '1234.TW',
  },
  {
    value: '1235.TW',
    label: '1235.TW,  Shin Tai Industry Co., Ltd.',
    name: 'Shin Tai Industry Co., Ltd.',
    symbol: '1235.TW',
  },
  {
    value: '1236.TW',
    label: '1236.TW,  Hunya Foods Co., Ltd.',
    name: 'Hunya Foods Co., Ltd.',
    symbol: '1236.TW',
  },
  {
    value: '1315.TW',
    label: '1315.TW,  Tah Hsin Industrial Corporation',
    name: 'Tah Hsin Industrial Corporation',
    symbol: '1315.TW',
  },
  {
    value: '1316.TW',
    label: '1316.TW,  Sun Yad Construction Co.,Ltd',
    name: 'Sun Yad Construction Co.,Ltd',
    symbol: '1316.TW',
  },
  {
    value: '1323.TW',
    label: '1323.TW,  Yonyu Plastics Co., Ltd.',
    name: 'Yonyu Plastics Co., Ltd.',
    symbol: '1323.TW',
  },
  {
    value: '1324.TW',
    label: '1324.TW,  Globe Industries Corporation',
    name: 'Globe Industries Corporation',
    symbol: '1324.TW',
  },
  {
    value: '1337.TW',
    label: '1337.TW,  Asia Plastic Recycling Holding Limited',
    name: 'Asia Plastic Recycling Holding Limited',
    symbol: '1337.TW',
  },
  {
    value: '1339.TW',
    label: '1339.TW,  Y.C.C. Parts Mfg. Co., Ltd.',
    name: 'Y.C.C. Parts Mfg. Co., Ltd.',
    symbol: '1339.TW',
  },
  {
    value: '1340.TW',
    label: '1340.TW,  Victory New Materials Limited Company',
    name: 'Victory New Materials Limited Company',
    symbol: '1340.TW',
  },
  {
    value: '1341.TW',
    label: '1341.TW,  Fulin Plastic Industry (Cayman) Holding Co., Ltd.',
    name: 'Fulin Plastic Industry (Cayman) Holding Co., Ltd.',
    symbol: '1341.TW',
  },
  {
    value: '1342.TW',
    label: '1342.TW,  Cathay Consolidated, Inc.',
    name: 'Cathay Consolidated, Inc.',
    symbol: '1342.TW',
  },
  {
    value: '1402.TW',
    label: '1402.TW,  Far Eastern New Century Corporation',
    name: 'Far Eastern New Century Corporation',
    symbol: '1402.TW',
  },
  {
    value: '1409.TW',
    label: '1409.TW,  Shinkong Synthetic Fibers Corporation',
    name: 'Shinkong Synthetic Fibers Corporation',
    symbol: '1409.TW',
  },
  {
    value: '1410.TW',
    label: '1410.TW,  Nan Yang Dyeing & Finishing Co.,Ltd',
    name: 'Nan Yang Dyeing & Finishing Co.,Ltd',
    symbol: '1410.TW',
  },
  {
    value: '1413.TW',
    label: '1413.TW,  Hung Chou Fiber Ind. Co., Ltd',
    name: 'Hung Chou Fiber Ind. Co., Ltd',
    symbol: '1413.TW',
  },
  {
    value: '1414.TW',
    label: '1414.TW,  Tung Ho Textile Co., Ltd.',
    name: 'Tung Ho Textile Co., Ltd.',
    symbol: '1414.TW',
  },
  {
    value: '1416.TW',
    label: '1416.TW,  Kwong Fong Industries Corporation',
    name: 'Kwong Fong Industries Corporation',
    symbol: '1416.TW',
  },
  {
    value: '1417.TW',
    label: '1417.TW,  Carnival Industrial Corporation',
    name: 'Carnival Industrial Corporation',
    symbol: '1417.TW',
  },
  {
    value: '1418.TW',
    label: '1418.TW,  TongHwa Corporation',
    name: 'TongHwa Corporation',
    symbol: '1418.TW',
  },
  {
    value: '1419.TW',
    label: '1419.TW,  Shinkong Textile Co., Ltd.',
    name: 'Shinkong Textile Co., Ltd.',
    symbol: '1419.TW',
  },
  {
    value: '1423.TW',
    label: '1423.TW,  Reward Wool Industry Corporation',
    name: 'Reward Wool Industry Corporation',
    symbol: '1423.TW',
  },
  {
    value: '1432.TW',
    label: '1432.TW,  TRK Corporation',
    name: 'TRK Corporation',
    symbol: '1432.TW',
  },
  {
    value: '1434.TW',
    label: '1434.TW,  Formosa Taffeta Co., Ltd.',
    name: 'Formosa Taffeta Co., Ltd.',
    symbol: '1434.TW',
  },
  {
    value: '1436.TW',
    label: '1436.TW,  Hua Yu Lien Development Co., Ltd',
    name: 'Hua Yu Lien Development Co., Ltd',
    symbol: '1436.TW',
  },
  {
    value: '1437.TW',
    label: '1437.TW,  GTM Holdings Corporation',
    name: 'GTM Holdings Corporation',
    symbol: '1437.TW',
  },
  {
    value: '1438.TW',
    label: '1438.TW,  SanDi Properties Co.,Ltd.',
    name: 'SanDi Properties Co.,Ltd.',
    symbol: '1438.TW',
  },
  {
    value: '1439.TW',
    label: '1439.TW,  Ascent Development Co., Ltd',
    name: 'Ascent Development Co., Ltd',
    symbol: '1439.TW',
  },
  {
    value: '1440.TW',
    label: '1440.TW,  Tainan Spinning Co., Ltd.',
    name: 'Tainan Spinning Co., Ltd.',
    symbol: '1440.TW',
  },
  {
    value: '1441.TW',
    label: '1441.TW,  Tah Tong Textile Co., Ltd.',
    name: 'Tah Tong Textile Co., Ltd.',
    symbol: '1441.TW',
  },
  {
    value: '1442.TW',
    label: '1442.TW,  Advancetek Enterprise Co.,Ltd.',
    name: 'Advancetek Enterprise Co.,Ltd.',
    symbol: '1442.TW',
  },
  {
    value: '1443.TW',
    label: '1443.TW,  Lily Textile Co., Ltd.',
    name: 'Lily Textile Co., Ltd.',
    symbol: '1443.TW',
  },
  {
    value: '1444.TW',
    label: '1444.TW,  Lealea Enterprise Co., Ltd.',
    name: 'Lealea Enterprise Co., Ltd.',
    symbol: '1444.TW',
  },
  {
    value: '1445.TW',
    label: '1445.TW,  Universal Textile Co., Ltd.',
    name: 'Universal Textile Co., Ltd.',
    symbol: '1445.TW',
  },
  {
    value: '1446.TW',
    label: '1446.TW,  Hong Ho Precision Textile Co.,Ltd.',
    name: 'Hong Ho Precision Textile Co.,Ltd.',
    symbol: '1446.TW',
  },
  {
    value: '1447.TW',
    label: '1447.TW,  Li Peng Enterprise Co., Ltd.',
    name: 'Li Peng Enterprise Co., Ltd.',
    symbol: '1447.TW',
  },
  {
    value: '1449.TW',
    label: '1449.TW,  Chia Her Industrial Co., Ltd.',
    name: 'Chia Her Industrial Co., Ltd.',
    symbol: '1449.TW',
  },
  {
    value: '1451.TW',
    label: '1451.TW,  Nien Hsing Textile Co., Ltd.',
    name: 'Nien Hsing Textile Co., Ltd.',
    symbol: '1451.TW',
  },
  {
    value: '1452.TW',
    label: '1452.TW,  Hong Yi Fiber Ind. Co., Ltd.',
    name: 'Hong Yi Fiber Ind. Co., Ltd.',
    symbol: '1452.TW',
  },
  {
    value: '1453.TW',
    label: '1453.TW,  Ta Jiang Co., Ltd.',
    name: 'Ta Jiang Co., Ltd.',
    symbol: '1453.TW',
  },
  {
    value: '1454.TW',
    label: '1454.TW,  Taiwan Taffeta Fabric Co., Ltd.',
    name: 'Taiwan Taffeta Fabric Co., Ltd.',
    symbol: '1454.TW',
  },
  {
    value: '1455.TW',
    label: '1455.TW,  Zig Sheng Industrial Co., Ltd.',
    name: 'Zig Sheng Industrial Co., Ltd.',
    symbol: '1455.TW',
  },
  {
    value: '1456.TW',
    label: '1456.TW,  I-Hwa Industrial Co.,Ltd',
    name: 'I-Hwa Industrial Co.,Ltd',
    symbol: '1456.TW',
  },
  {
    value: '1457.TW',
    label: '1457.TW,  Yi Jinn Industrial Co., Ltd.',
    name: 'Yi Jinn Industrial Co., Ltd.',
    symbol: '1457.TW',
  },
  {
    value: '1459.TW',
    label: '1459.TW,  Lan Fa Textile Co., Ltd.',
    name: 'Lan Fa Textile Co., Ltd.',
    symbol: '1459.TW',
  },
  {
    value: '1460.TW',
    label: '1460.TW,  Everest Textile Co., Ltd.',
    name: 'Everest Textile Co., Ltd.',
    symbol: '1460.TW',
  },
  {
    value: '1463.TW',
    label: '1463.TW,  Chyang Sheng Dyeing & Finishing Co., Ltd.',
    name: 'Chyang Sheng Dyeing & Finishing Co., Ltd.',
    symbol: '1463.TW',
  },
  {
    value: '1464.TW',
    label: '1464.TW,  De Licacy Industrial Co., Ltd.',
    name: 'De Licacy Industrial Co., Ltd.',
    symbol: '1464.TW',
  },
  {
    value: '1465.TW',
    label: '1465.TW,  Wisher Industrial Co., Ltd.',
    name: 'Wisher Industrial Co., Ltd.',
    symbol: '1465.TW',
  },
  {
    value: '1466.TW',
    label: '1466.TW,  Acelon Chemicals & Fiber Corporation',
    name: 'Acelon Chemicals & Fiber Corporation',
    symbol: '1466.TW',
  },
  {
    value: '1467.TW',
    label: '1467.TW,  Tex-Ray Industrial Co., Ltd.',
    name: 'Tex-Ray Industrial Co., Ltd.',
    symbol: '1467.TW',
  },
  {
    value: '1468.TW',
    label: '1468.TW,  Chang-Ho Fibre Corporation',
    name: 'Chang-Ho Fibre Corporation',
    symbol: '1468.TW',
  },
  {
    value: '1470.TW',
    label: '1470.TW,  evertex fabrinology limited',
    name: 'evertex fabrinology limited',
    symbol: '1470.TW',
  },
  {
    value: '1471.TW',
    label: '1471.TW,  Solytech Enterprise Corporation',
    name: 'Solytech Enterprise Corporation',
    symbol: '1471.TW',
  },
  {
    value: '1472.TW',
    label: '1472.TW,  Triocean Industrial Corporation Co., Ltd.',
    name: 'Triocean Industrial Corporation Co., Ltd.',
    symbol: '1472.TW',
  },
  {
    value: '1473.TW',
    label: '1473.TW,  Tainan Enterprises Co.,Ltd',
    name: 'Tainan Enterprises Co.,Ltd',
    symbol: '1473.TW',
  },
  {
    value: '1474.TW',
    label: '1474.TW,  Honmyue Enterprise Co., Ltd.',
    name: 'Honmyue Enterprise Co., Ltd.',
    symbol: '1474.TW',
  },
  {
    value: '1475.TW',
    label: '1475.TW,  Big Sun Shine CO., LTD.',
    name: 'Big Sun Shine CO., LTD.',
    symbol: '1475.TW',
  },
  {
    value: '1476.TW',
    label: '1476.TW,  Eclat Textile Co., Ltd.',
    name: 'Eclat Textile Co., Ltd.',
    symbol: '1476.TW',
  },
  {
    value: '1477.TW',
    label: '1477.TW,  Makalot Industrial Co., Ltd.',
    name: 'Makalot Industrial Co., Ltd.',
    symbol: '1477.TW',
  },
  {
    value: '1503.TW',
    label: '1503.TW,  Shihlin Electric & Engineering Corp.',
    name: 'Shihlin Electric & Engineering Corp.',
    symbol: '1503.TW',
  },
  {
    value: '1504.TW',
    label: '1504.TW,  TECO Electric & Machinery Co., Ltd.',
    name: 'TECO Electric & Machinery Co., Ltd.',
    symbol: '1504.TW',
  },
  {
    value: '1506.TW',
    label: '1506.TW,  Right Way Industrial Co.,Ltd',
    name: 'Right Way Industrial Co.,Ltd',
    symbol: '1506.TW',
  },
  {
    value: '1512.TW',
    label: '1512.TW,  Jui Li Enterprise Co., Ltd.',
    name: 'Jui Li Enterprise Co., Ltd.',
    symbol: '1512.TW',
  },
  {
    value: '1513.TW',
    label: '1513.TW,  Chung-Hsin Electric and Machinery Manufacturing Corp.',
    name: 'Chung-Hsin Electric and Machinery Manufacturing Corp.',
    symbol: '1513.TW',
  },
  {
    value: '1514.TW',
    label: '1514.TW,  Allis Electric Co.,Ltd.',
    name: 'Allis Electric Co.,Ltd.',
    symbol: '1514.TW',
  },
  {
    value: '1515.TW',
    label: '1515.TW,  Rexon Industrial Corp.,Ltd',
    name: 'Rexon Industrial Corp.,Ltd',
    symbol: '1515.TW',
  },
  {
    value: '1516.TW',
    label: '1516.TW,  Falcon Power Co., Ltd.',
    name: 'Falcon Power Co., Ltd.',
    symbol: '1516.TW',
  },
  {
    value: '1517.TW',
    label: '1517.TW,  Lee Chi Enterprises Company Ltd.',
    name: 'Lee Chi Enterprises Company Ltd.',
    symbol: '1517.TW',
  },
  {
    value: '1519.TW',
    label: '1519.TW,  Fortune Electric Co., Ltd.',
    name: 'Fortune Electric Co., Ltd.',
    symbol: '1519.TW',
  },
  {
    value: '1521.TW',
    label: '1521.TW,  Ta Yih Industrial Co., Ltd.',
    name: 'Ta Yih Industrial Co., Ltd.',
    symbol: '1521.TW',
  },
  {
    value: '1522.TW',
    label: '1522.TW,  TYC Brother Industrial Co., Ltd.',
    name: 'TYC Brother Industrial Co., Ltd.',
    symbol: '1522.TW',
  },
  {
    value: '1524.TW',
    label: '1524.TW,  Gordon Auto Body Parts Co., Ltd.',
    name: 'Gordon Auto Body Parts Co., Ltd.',
    symbol: '1524.TW',
  },
  {
    value: '1525.TW',
    label: '1525.TW,  Kian Shen Corporation',
    name: 'Kian Shen Corporation',
    symbol: '1525.TW',
  },
  {
    value: '1526.TW',
    label: '1526.TW,  Sun Race Sturmey-Archer Corporation',
    name: 'Sun Race Sturmey-Archer Corporation',
    symbol: '1526.TW',
  },
  {
    value: '1527.TW',
    label: '1527.TW,  Basso Industry Corp.',
    name: 'Basso Industry Corp.',
    symbol: '1527.TW',
  },
  {
    value: '1528.TW',
    label: '1528.TW,  Anderson Industrial Corporation',
    name: 'Anderson Industrial Corporation',
    symbol: '1528.TW',
  },
  {
    value: '1529.TW',
    label: '1529.TW,  Luxe Green Energy Technology Co., Ltd.',
    name: 'Luxe Green Energy Technology Co., Ltd.',
    symbol: '1529.TW',
  },
  {
    value: '1530.TW',
    label: '1530.TW,  Awea mechantronic co.,ltd',
    name: 'Awea mechantronic co.,ltd',
    symbol: '1530.TW',
  },
  {
    value: '1531.TW',
    label: '1531.TW,  Kaulin Mfg. Co., Ltd.',
    name: 'Kaulin Mfg. Co., Ltd.',
    symbol: '1531.TW',
  },
  {
    value: '1532.TW',
    label: '1532.TW,  China Metal Products Co., Ltd.',
    name: 'China Metal Products Co., Ltd.',
    symbol: '1532.TW',
  },
  {
    value: '1533.TW',
    label: '1533.TW,  Mobiletron Electronics Co.,Ltd.',
    name: 'Mobiletron Electronics Co.,Ltd.',
    symbol: '1533.TW',
  },
  {
    value: '1535.TW',
    label: '1535.TW,  China Ecotek Corporation',
    name: 'China Ecotek Corporation',
    symbol: '1535.TW',
  },
  {
    value: '1536.TW',
    label: '1536.TW,  Hota Industrial Mfg. Co., Ltd.',
    name: 'Hota Industrial Mfg. Co., Ltd.',
    symbol: '1536.TW',
  },
  {
    value: '1537.TW',
    label: '1537.TW,  Kung Long Batteries Industrial Co.,Ltd',
    name: 'Kung Long Batteries Industrial Co.,Ltd',
    symbol: '1537.TW',
  },
  {
    value: '1538.TW',
    label: '1538.TW,  Jenn Feng Industrial Co., Ltd.',
    name: 'Jenn Feng Industrial Co., Ltd.',
    symbol: '1538.TW',
  },
  {
    value: '1539.TW',
    label: '1539.TW,  Chiu Ting Machinery Co., Ltd.',
    name: 'Chiu Ting Machinery Co., Ltd.',
    symbol: '1539.TW',
  },
  {
    value: '1540.TW',
    label: '1540.TW,  Roundtop Machinery Industries Co., Ltd.',
    name: 'Roundtop Machinery Industries Co., Ltd.',
    symbol: '1540.TW',
  },
  {
    value: '1541.TW',
    label: '1541.TW,  CHANG TYPE Industrial Co., Ltd.',
    name: 'CHANG TYPE Industrial Co., Ltd.',
    symbol: '1541.TW',
  },
  {
    value: '1558.TW',
    label: '1558.TW,  Zeng Hsing Industrial Co., Ltd.',
    name: 'Zeng Hsing Industrial Co., Ltd.',
    symbol: '1558.TW',
  },
  {
    value: '1560.TW',
    label: '1560.TW,  Kinik Company',
    name: 'Kinik Company',
    symbol: '1560.TW',
  },
  {
    value: '1568.TW',
    label: '1568.TW,  Tsang Yow Industrial Co.,Ltd.',
    name: 'Tsang Yow Industrial Co.,Ltd.',
    symbol: '1568.TW',
  },
  {
    value: '1582.TW',
    label: '1582.TW,  Syncmold Enterprise Corp.',
    name: 'Syncmold Enterprise Corp.',
    symbol: '1582.TW',
  },
  {
    value: '1583.TW',
    label: '1583.TW,  Goodway Machine Corp.',
    name: 'Goodway Machine Corp.',
    symbol: '1583.TW',
  },
  {
    value: '1587.TW',
    label: '1587.TW,  Cryomax Cooling System Corp.',
    name: 'Cryomax Cooling System Corp.',
    symbol: '1587.TW',
  },
  {
    value: '1589.TW',
    label: '1589.TW,  Yeong Guan Energy Technology Group Co., Ltd.',
    name: 'Yeong Guan Energy Technology Group Co., Ltd.',
    symbol: '1589.TW',
  },
  {
    value: '1590.TW',
    label: '1590.TW,  AirTAC International Group',
    name: 'AirTAC International Group',
    symbol: '1590.TW',
  },
  {
    value: '1597.TW',
    label: '1597.TW,  Chieftek Precision Co., Ltd.',
    name: 'Chieftek Precision Co., Ltd.',
    symbol: '1597.TW',
  },
  {
    value: '1598.TW',
    label: '1598.TW,  Dyaco International Inc.',
    name: 'Dyaco International Inc.',
    symbol: '1598.TW',
  },
  {
    value: '1603.TW',
    label: '1603.TW,  China Wire & Cable Co., Ltd.',
    name: 'China Wire & Cable Co., Ltd.',
    symbol: '1603.TW',
  },
  {
    value: '1604.TW',
    label: '1604.TW,  Sampo Corporation',
    name: 'Sampo Corporation',
    symbol: '1604.TW',
  },
  {
    value: '1605.TW',
    label: '1605.TW,  Walsin Lihwa Corporation',
    name: 'Walsin Lihwa Corporation',
    symbol: '1605.TW',
  },
  {
    value: '1608.TW',
    label: '1608.TW,  Hua Eng Wire & Cable Co., Ltd.',
    name: 'Hua Eng Wire & Cable Co., Ltd.',
    symbol: '1608.TW',
  },
  {
    value: '1609.TW',
    label: '1609.TW,  Ta Ya Electric Wire & Cable Co., Ltd.',
    name: 'Ta Ya Electric Wire & Cable Co., Ltd.',
    symbol: '1609.TW',
  },
  {
    value: '1611.TW',
    label: '1611.TW,  China Electric Mfg. Corporation',
    name: 'China Electric Mfg. Corporation',
    symbol: '1611.TW',
  },
  {
    value: '1612.TW',
    label: '1612.TW,  Hong Tai Electric Industrial Co., Ltd.',
    name: 'Hong Tai Electric Industrial Co., Ltd.',
    symbol: '1612.TW',
  },
  {
    value: '1614.TW',
    label: '1614.TW,  Taiwan Sanyo Electric Co.,Ltd.',
    name: 'Taiwan Sanyo Electric Co.,Ltd.',
    symbol: '1614.TW',
  },
  {
    value: '1615.TW',
    label: '1615.TW,  Dah San Electric Wire & Cable Corp.',
    name: 'Dah San Electric Wire & Cable Corp.',
    symbol: '1615.TW',
  },
  {
    value: '1616.TW',
    label: '1616.TW,  Evertop Wire Cable Corporation',
    name: 'Evertop Wire Cable Corporation',
    symbol: '1616.TW',
  },
  {
    value: '1617.TW',
    label: '1617.TW,  Jung Shing Wire Co., Ltd.',
    name: 'Jung Shing Wire Co., Ltd.',
    symbol: '1617.TW',
  },
  {
    value: '1618.TW',
    label: '1618.TW,  Hold-Key Electric Wire & Cable Co., Ltd.',
    name: 'Hold-Key Electric Wire & Cable Co., Ltd.',
    symbol: '1618.TW',
  },
  {
    value: '1626.TW',
    label: '1626.TW,  Airmate (Cayman) International Co Limited',
    name: 'Airmate (Cayman) International Co Limited',
    symbol: '1626.TW',
  },
  {
    value: '1701.TW',
    label: '1701.TW,  China Chemical & Pharmaceutical Co., Ltd.',
    name: 'China Chemical & Pharmaceutical Co., Ltd.',
    symbol: '1701.TW',
  },
  {
    value: '1702.TW',
    label: '1702.TW,  Namchow Holdings Co., Ltd.',
    name: 'Namchow Holdings Co., Ltd.',
    symbol: '1702.TW',
  },
  {
    value: '1707.TW',
    label: '1707.TW,  Grape King Bio Ltd',
    name: 'Grape King Bio Ltd',
    symbol: '1707.TW',
  },
  {
    value: '1708.TW',
    label: '1708.TW,  Sesoda Corporation',
    name: 'Sesoda Corporation',
    symbol: '1708.TW',
  },
  {
    value: '1709.TW',
    label: '1709.TW,  Formosan Union Chemical Corp.',
    name: 'Formosan Union Chemical Corp.',
    symbol: '1709.TW',
  },
  {
    value: '1710.TW',
    label: '1710.TW,  Oriental Union Chemical Corporation',
    name: 'Oriental Union Chemical Corporation',
    symbol: '1710.TW',
  },
  {
    value: '1711.TW',
    label: '1711.TW,  Everlight Chemical Industrial Corporation',
    name: 'Everlight Chemical Industrial Corporation',
    symbol: '1711.TW',
  },
  {
    value: '1712.TW',
    label: '1712.TW,  Sinon Corporation',
    name: 'Sinon Corporation',
    symbol: '1712.TW',
  },
  {
    value: '1713.TW',
    label: '1713.TW,  Cathay Chemical Works Inc.',
    name: 'Cathay Chemical Works Inc.',
    symbol: '1713.TW',
  },
  {
    value: '1714.TW',
    label: '1714.TW,  Ho Tung Chemical Corp.',
    name: 'Ho Tung Chemical Corp.',
    symbol: '1714.TW',
  },
  {
    value: '1717.TW',
    label: '1717.TW,  Eternal Materials Co., Ltd.',
    name: 'Eternal Materials Co., Ltd.',
    symbol: '1717.TW',
  },
  {
    value: '1718.TW',
    label: '1718.TW,  China Man-Made Fiber Corporation',
    name: 'China Man-Made Fiber Corporation',
    symbol: '1718.TW',
  },
  {
    value: '1720.TW',
    label: '1720.TW,  Standard Chemical & Pharmaceutical Co. Ltd.',
    name: 'Standard Chemical & Pharmaceutical Co. Ltd.',
    symbol: '1720.TW',
  },
  {
    value: '1721.TW',
    label: '1721.TW,  Sunko Ink Co., Ltd.',
    name: 'Sunko Ink Co., Ltd.',
    symbol: '1721.TW',
  },
  {
    value: '1722.TW',
    label: '1722.TW,  Taiwan Fertilizer Co., Ltd.',
    name: 'Taiwan Fertilizer Co., Ltd.',
    symbol: '1722.TW',
  },
  {
    value: '1723.TW',
    label: '1723.TW,  China Steel Chemical Corporation',
    name: 'China Steel Chemical Corporation',
    symbol: '1723.TW',
  },
  {
    value: '1725.TW',
    label: '1725.TW,  Yuan Jen Enterprises Co.,Ltd.',
    name: 'Yuan Jen Enterprises Co.,Ltd.',
    symbol: '1725.TW',
  },
  {
    value: '1726.TW',
    label: '1726.TW,  Yung Chi Paint & Varnish Mfg.Co.,Ltd',
    name: 'Yung Chi Paint & Varnish Mfg.Co.,Ltd',
    symbol: '1726.TW',
  },
  {
    value: '1727.TW',
    label: '1727.TW,  Chung Hwa Chemical Industrial Works, Ltd.',
    name: 'Chung Hwa Chemical Industrial Works, Ltd.',
    symbol: '1727.TW',
  },
  {
    value: '1730.TW',
    label: '1730.TW,  Farcent Enterprise Co.,Ltd',
    name: 'Farcent Enterprise Co.,Ltd',
    symbol: '1730.TW',
  },
  {
    value: '1731.TW',
    label: '1731.TW,  Maywufa Company Ltd.',
    name: 'Maywufa Company Ltd.',
    symbol: '1731.TW',
  },
  {
    value: '1732.TW',
    label: '1732.TW,  Mao Bao Inc.',
    name: 'Mao Bao Inc.',
    symbol: '1732.TW',
  },
  {
    value: '1733.TW',
    label: '1733.TW,  Apex Biotechnology Corp.',
    name: 'Apex Biotechnology Corp.',
    symbol: '1733.TW',
  },
  {
    value: '1735.TW',
    label: '1735.TW,  Evermore Chemical Industry Co., Ltd.',
    name: 'Evermore Chemical Industry Co., Ltd.',
    symbol: '1735.TW',
  },
  {
    value: '1776.TW',
    label: '1776.TW,  Headway Advanced Materials Inc.',
    name: 'Headway Advanced Materials Inc.',
    symbol: '1776.TW',
  },
  {
    value: '1783.TW',
    label: '1783.TW,  Maxigen Biotech Inc.',
    name: 'Maxigen Biotech Inc.',
    symbol: '1783.TW',
  },
  {
    value: '1786.TW',
    label: '1786.TW,  SciVision Biotech Inc.',
    name: 'SciVision Biotech Inc.',
    symbol: '1786.TW',
  },
  {
    value: '1805.TW',
    label: '1805.TW,  Better Life Group Co., LTD.',
    name: 'Better Life Group Co., LTD.',
    symbol: '1805.TW',
  },
  {
    value: '1808.TW',
    label: '1808.TW,  Run Long Construction Co.,Ltd.',
    name: 'Run Long Construction Co.,Ltd.',
    symbol: '1808.TW',
  },
  {
    value: '1809.TW',
    label: '1809.TW,  China Glaze Co.,Ltd.',
    name: 'China Glaze Co.,Ltd.',
    symbol: '1809.TW',
  },
  {
    value: '1817.TW',
    label: '1817.TW,  Sanitar Co., Ltd.',
    name: 'Sanitar Co., Ltd.',
    symbol: '1817.TW',
  },
  {
    value: '2008.TW',
    label: '2008.TW,  Kao Hsing Chang Iron & Steel Corp.',
    name: 'Kao Hsing Chang Iron & Steel Corp.',
    symbol: '2008.TW',
  },
  {
    value: '2024.TW',
    label: '2024.TW,  Chih Lien Industrial Co., Ltd.',
    name: 'Chih Lien Industrial Co., Ltd.',
    symbol: '2024.TW',
  },
  {
    value: '2025.TW',
    label: '2025.TW,  Chien Shing Stainless Steel Co., Ltd.',
    name: 'Chien Shing Stainless Steel Co., Ltd.',
    symbol: '2025.TW',
  },
  {
    value: '2032.TW',
    label: '2032.TW,  Sinkang Industries Co., Ltd.',
    name: 'Sinkang Industries Co., Ltd.',
    symbol: '2032.TW',
  },
  {
    value: '2033.TW',
    label: '2033.TW,  Chia Ta World Co., Ltd.',
    name: 'Chia Ta World Co., Ltd.',
    symbol: '2033.TW',
  },
  {
    value: '2069.TW',
    label: '2069.TW,  Yuen Chang Stainless Steel Co., Ltd.',
    name: 'Yuen Chang Stainless Steel Co., Ltd.',
    symbol: '2069.TW',
  },
  {
    value: '2101.TW',
    label: '2101.TW,  Nankang Rubber Tire Corp.,Ltd.',
    name: 'Nankang Rubber Tire Corp.,Ltd.',
    symbol: '2101.TW',
  },
  {
    value: '2102.TW',
    label: '2102.TW,  Federal Corporation',
    name: 'Federal Corporation',
    symbol: '2102.TW',
  },
  {
    value: '2109.TW',
    label: '2109.TW,  Hwa Fong Rubber Industrial Co., Ltd.',
    name: 'Hwa Fong Rubber Industrial Co., Ltd.',
    symbol: '2109.TW',
  },
  {
    value: '2115.TW',
    label: '2115.TW,  Luhai Holding Corp.',
    name: 'Luhai Holding Corp.',
    symbol: '2115.TW',
  },
  {
    value: '2227.TW',
    label: '2227.TW,  Yulon Nissan Motor Co., Ltd',
    name: 'Yulon Nissan Motor Co., Ltd',
    symbol: '2227.TW',
  },
  {
    value: '2228.TW',
    label: '2228.TW,  Iron Force Industrial Co., Ltd.',
    name: 'Iron Force Industrial Co., Ltd.',
    symbol: '2228.TW',
  },
  {
    value: '2231.TW',
    label: '2231.TW,  Cub Elecparts Inc.',
    name: 'Cub Elecparts Inc.',
    symbol: '2231.TW',
  },
  {
    value: '2233.TW',
    label: '2233.TW,  TURVO International Co., Ltd.',
    name: 'TURVO International Co., Ltd.',
    symbol: '2233.TW',
  },
  {
    value: '2236.TW',
    label: '2236.TW,  Patec Precision Industry Co., Ltd.',
    name: 'Patec Precision Industry Co., Ltd.',
    symbol: '2236.TW',
  },
  {
    value: '2239.TW',
    label: '2239.TW,  Cayman Engley Industrial Co., Ltd.',
    name: 'Cayman Engley Industrial Co., Ltd.',
    symbol: '2239.TW',
  },
  {
    value: '2241.TW',
    label: '2241.TW,  Amulaire Thermal Technology, Inc.',
    name: 'Amulaire Thermal Technology, Inc.',
    symbol: '2241.TW',
  },
  {
    value: '2243.TW',
    label: '2243.TW,  HORNG SHIUE HOLDING Co., Ltd.',
    name: 'HORNG SHIUE HOLDING Co., Ltd.',
    symbol: '2243.TW',
  },
  {
    value: '2247.TW',
    label: '2247.TW,  Pan German Universal Motors Ltd.',
    name: 'Pan German Universal Motors Ltd.',
    symbol: '2247.TW',
  },
  {
    value: '2250.TW',
    label: '2250.TW,  IKKA Holdings (Cayman) Limited',
    name: 'IKKA Holdings (Cayman) Limited',
    symbol: '2250.TW',
  },
  {
    value: '2305.TW',
    label: '2305.TW,  Microtek International, Inc.',
    name: 'Microtek International, Inc.',
    symbol: '2305.TW',
  },
  {
    value: '2321.TW',
    label: '2321.TW,  Tecom Co., Ltd.',
    name: 'Tecom Co., Ltd.',
    symbol: '2321.TW',
  },
  {
    value: '2358.TW',
    label: '2358.TW,  Ting Sin Co.,Ltd',
    name: 'Ting Sin Co.,Ltd',
    symbol: '2358.TW',
  },
  {
    value: '2359.TW',
    label: '2359.TW,  Solomon Technology Corporation',
    name: 'Solomon Technology Corporation',
    symbol: '2359.TW',
  },
  {
    value: '2364.TW',
    label: '2364.TW,  Twinhead International Corp.',
    name: 'Twinhead International Corp.',
    symbol: '2364.TW',
  },
  {
    value: '2365.TW',
    label: '2365.TW,  KYE Systems Corp.',
    name: 'KYE Systems Corp.',
    symbol: '2365.TW',
  },
  {
    value: '2380.TW',
    label: '2380.TW,  Avision Inc.',
    name: 'Avision Inc.',
    symbol: '2380.TW',
  },
  {
    value: '2397.TW',
    label: '2397.TW,  DFI Inc.',
    name: 'DFI Inc.',
    symbol: '2397.TW',
  },
  {
    value: '2414.TW',
    label: '2414.TW,  Unitech Computer Co., Ltd.',
    name: 'Unitech Computer Co., Ltd.',
    symbol: '2414.TW',
  },
  {
    value: '2417.TW',
    label: '2417.TW,  AVerMedia Technologies, Inc.',
    name: 'AVerMedia Technologies, Inc.',
    symbol: '2417.TW',
  },
  {
    value: '2423.TW',
    label: '2423.TW,  Good Will Instrument Co., Ltd.',
    name: 'Good Will Instrument Co., Ltd.',
    symbol: '2423.TW',
  },
  {
    value: '2424.TW',
    label: '2424.TW,  Lung Hwa Electronics Co., Ltd.',
    name: 'Lung Hwa Electronics Co., Ltd.',
    symbol: '2424.TW',
  },
  {
    value: '2425.TW',
    label: '2425.TW,  Chaintech Technology Corporation',
    name: 'Chaintech Technology Corporation',
    symbol: '2425.TW',
  },
  {
    value: '2427.TW',
    label: '2427.TW,  Mercuries Data Systems Ltd.',
    name: 'Mercuries Data Systems Ltd.',
    symbol: '2427.TW',
  },
  {
    value: '2429.TW',
    label: '2429.TW,  Abonmax Co., Ltd',
    name: 'Abonmax Co., Ltd',
    symbol: '2429.TW',
  },
  {
    value: '2431.TW',
    label: '2431.TW,  Lien Chang Electronic Enterprise Co., Ltd',
    name: 'Lien Chang Electronic Enterprise Co., Ltd',
    symbol: '2431.TW',
  },
  {
    value: '2434.TW',
    label: '2434.TW,  Mospec Semiconductor Corp.',
    name: 'Mospec Semiconductor Corp.',
    symbol: '2434.TW',
  },
  {
    value: '2438.TW',
    label: '2438.TW,  Enlight Corporation',
    name: 'Enlight Corporation',
    symbol: '2438.TW',
  },
  {
    value: '2440.TW',
    label: '2440.TW,  Space Shuttle Hi-Tech Co., Ltd.',
    name: 'Space Shuttle Hi-Tech Co., Ltd.',
    symbol: '2440.TW',
  },
  {
    value: '2442.TW',
    label: '2442.TW,  Jean Co.,Ltd',
    name: 'Jean Co.,Ltd',
    symbol: '2442.TW',
  },
  {
    value: '2443.TW',
    label: '2443.TW,  Lead Data Inc.',
    name: 'Lead Data Inc.',
    symbol: '2443.TW',
  },
  {
    value: '2444.TW',
    label: '2444.TW,  AboCom Systems, Inc.',
    name: 'AboCom Systems, Inc.',
    symbol: '2444.TW',
  },
  {
    value: '2453.TW',
    label: '2453.TW,  Syscom Computer Engineering Co.',
    name: 'Syscom Computer Engineering Co.',
    symbol: '2453.TW',
  },
  {
    value: '2462.TW',
    label: '2462.TW,  Taiwan Line Tek Electronic Co., Ltd.',
    name: 'Taiwan Line Tek Electronic Co., Ltd.',
    symbol: '2462.TW',
  },
  {
    value: '2466.TW',
    label: '2466.TW,  Cosmo Electronics Corporation',
    name: 'Cosmo Electronics Corporation',
    symbol: '2466.TW',
  },
  {
    value: '2468.TW',
    label: '2468.TW,  Fortune Information Systems Corp',
    name: 'Fortune Information Systems Corp',
    symbol: '2468.TW',
  },
  {
    value: '2471.TW',
    label: '2471.TW,  Ares International Corp.',
    name: 'Ares International Corp.',
    symbol: '2471.TW',
  },
  {
    value: '2477.TW',
    label: '2477.TW,  Meiloon Industrial Co., Ltd.',
    name: 'Meiloon Industrial Co., Ltd.',
    symbol: '2477.TW',
  },
  {
    value: '2482.TW',
    label: '2482.TW,  Uniform Industrial Corporation',
    name: 'Uniform Industrial Corporation',
    symbol: '2482.TW',
  },
  {
    value: '2483.TW',
    label: '2483.TW,  Excel Cell Electronic Co., Ltd.',
    name: 'Excel Cell Electronic Co., Ltd.',
    symbol: '2483.TW',
  },
  {
    value: '2488.TW',
    label: '2488.TW,  Hanpin Electron Co., Ltd.',
    name: 'Hanpin Electron Co., Ltd.',
    symbol: '2488.TW',
  },
  {
    value: '2491.TW',
    label: '2491.TW,  Fortune Oriental Company Limited',
    name: 'Fortune Oriental Company Limited',
    symbol: '2491.TW',
  },
  {
    value: '2495.TW',
    label: '2495.TW,  Infortrend Technology Inc.',
    name: 'Infortrend Technology Inc.',
    symbol: '2495.TW',
  },
  {
    value: '2496.TW',
    label: '2496.TW,  Success Prime Corporation',
    name: 'Success Prime Corporation',
    symbol: '2496.TW',
  },
  {
    value: '2506.TW',
    label: '2506.TW,  Pacific Construction Co., Ltd',
    name: 'Pacific Construction Co., Ltd',
    symbol: '2506.TW',
  },
  {
    value: '2509.TW',
    label: '2509.TW,  Chainqui Construction Development Co., Ltd.',
    name: 'Chainqui Construction Development Co., Ltd.',
    symbol: '2509.TW',
  },
  {
    value: '2516.TW',
    label: '2516.TW,  New Asia Construction & Development Corp.',
    name: 'New Asia Construction & Development Corp.',
    symbol: '2516.TW',
  },
  {
    value: '2524.TW',
    label: "2524.TW,  King's Town Construction Co., Ltd.",
    name: "King's Town Construction Co., Ltd.",
    symbol: '2524.TW',
  },
  {
    value: '2528.TW',
    label: '2528.TW,  Crowell Development Corp.',
    name: 'Crowell Development Corp.',
    symbol: '2528.TW',
  },
  {
    value: '2530.TW',
    label: '2530.TW,  Delpha Construction Co.,Ltd.',
    name: 'Delpha Construction Co.,Ltd.',
    symbol: '2530.TW',
  },
  {
    value: '2537.TW',
    label: '2537.TW,  WE & WIN Development Co., LTD',
    name: 'WE & WIN Development Co., LTD',
    symbol: '2537.TW',
  },
  {
    value: '2539.TW',
    label: '2539.TW,  Sakura Development Co.,Ltd',
    name: 'Sakura Development Co.,Ltd',
    symbol: '2539.TW',
  },
  {
    value: '2540.TW',
    label: '2540.TW,  JSL Construction & Development Co., Ltd.',
    name: 'JSL Construction & Development Co., Ltd.',
    symbol: '2540.TW',
  },
  {
    value: '2543.TW',
    label: '2543.TW,  Hwang Chang General Contractor Co., Ltd',
    name: 'Hwang Chang General Contractor Co., Ltd',
    symbol: '2543.TW',
  },
  {
    value: '2546.TW',
    label: '2546.TW,  Kedge Construction Co., Ltd.',
    name: 'Kedge Construction Co., Ltd.',
    symbol: '2546.TW',
  },
  {
    value: '2611.TW',
    label: '2611.TW,  Tze Shin International Co., Ltd.',
    name: 'Tze Shin International Co., Ltd.',
    symbol: '2611.TW',
  },
  {
    value: '2613.TW',
    label: '2613.TW,  China Container Terminal Corporation',
    name: 'China Container Terminal Corporation',
    symbol: '2613.TW',
  },
  {
    value: '2616.TW',
    label: '2616.TW,  Shan-Loong Transportation Co.,Ltd',
    name: 'Shan-Loong Transportation Co.,Ltd',
    symbol: '2616.TW',
  },
  {
    value: '2630.TW',
    label: '2630.TW,  Air Asia Co., Ltd.',
    name: 'Air Asia Co., Ltd.',
    symbol: '2630.TW',
  },
  {
    value: '2634.TW',
    label: '2634.TW,  Aerospace Industrial Development Corporation',
    name: 'Aerospace Industrial Development Corporation',
    symbol: '2634.TW',
  },
  {
    value: '2642.TW',
    label: '2642.TW,  Taiwan Pelican Express Co., Ltd.',
    name: 'Taiwan Pelican Express Co., Ltd.',
    symbol: '2642.TW',
  },
  {
    value: '2701.TW',
    label: '2701.TW,  Wan Hwa Enterprise Company Ltd.',
    name: 'Wan Hwa Enterprise Company Ltd.',
    symbol: '2701.TW',
  },
  {
    value: '2702.TW',
    label: '2702.TW,  Hotel Holiday Garden',
    name: 'Hotel Holiday Garden',
    symbol: '2702.TW',
  },
  {
    value: '2704.TW',
    label: '2704.TW,  The Ambassador Hotel,Ltd.',
    name: 'The Ambassador Hotel,Ltd.',
    symbol: '2704.TW',
  },
  {
    value: '2706.TW',
    label: '2706.TW,  First Hotel Company Ltd.',
    name: 'First Hotel Company Ltd.',
    symbol: '2706.TW',
  },
  {
    value: '2712.TW',
    label: '2712.TW,  FarGlory Hotel Co.,Ltd',
    name: 'FarGlory Hotel Co.,Ltd',
    symbol: '2712.TW',
  },
  {
    value: '2722.TW',
    label: '2722.TW,  Chateau International Development Co., Ltd.',
    name: 'Chateau International Development Co., Ltd.',
    symbol: '2722.TW',
  },
  {
    value: '2731.TW',
    label: '2731.TW,  Lion Travel Service Co., Ltd.',
    name: 'Lion Travel Service Co., Ltd.',
    symbol: '2731.TW',
  },
  {
    value: '2739.TW',
    label:
      '2739.TW,  My Humble House Hospitality Management Consulting Co., Ltd.',
    name: 'My Humble House Hospitality Management Consulting Co., Ltd.',
    symbol: '2739.TW',
  },
  {
    value: '2748.TW',
    label: '2748.TW,  FDC International Hotels Corporation',
    name: 'FDC International Hotels Corporation',
    symbol: '2748.TW',
  },
  {
    value: '2753.TW',
    label: '2753.TW,  Bafang Yunji International Company Limited',
    name: 'Bafang Yunji International Company Limited',
    symbol: '2753.TW',
  },
  {
    value: '2801.TW',
    label: '2801.TW,  Chang Hwa Commercial Bank, Ltd.',
    name: 'Chang Hwa Commercial Bank, Ltd.',
    symbol: '2801.TW',
  },
  {
    value: '2809.TW',
    label: "2809.TW,  King's Town Bank",
    name: "King's Town Bank",
    symbol: '2809.TW',
  },
  {
    value: '2812.TW',
    label: '2812.TW,  Taichung Commercial Bank Co., Ltd.',
    name: 'Taichung Commercial Bank Co., Ltd.',
    symbol: '2812.TW',
  },
  {
    value: '2816.TW',
    label: '2816.TW,  Union Insurance Co., Ltd.',
    name: 'Union Insurance Co., Ltd.',
    symbol: '2816.TW',
  },
  {
    value: '2820.TW',
    label: '2820.TW,  China Bills Finance Corporation',
    name: 'China Bills Finance Corporation',
    symbol: '2820.TW',
  },
  {
    value: '2832.TW',
    label: '2832.TW,  Taiwan Fire & Marine Insurance Co., Ltd.',
    name: 'Taiwan Fire & Marine Insurance Co., Ltd.',
    symbol: '2832.TW',
  },
  {
    value: '2834.TW',
    label: '2834.TW,  Taiwan Business Bank, Ltd.',
    name: 'Taiwan Business Bank, Ltd.',
    symbol: '2834.TW',
  },
  {
    value: '2836.TW',
    label: '2836.TW,  Bank of Kaohsiung Co., Ltd.',
    name: 'Bank of Kaohsiung Co., Ltd.',
    symbol: '2836.TW',
  },
  {
    value: '2838.TW',
    label: '2838.TW,  Union Bank of Taiwan',
    name: 'Union Bank of Taiwan',
    symbol: '2838.TW',
  },
  {
    value: '2845.TW',
    label: '2845.TW,  Far Eastern International Bank Ltd.',
    name: 'Far Eastern International Bank Ltd.',
    symbol: '2845.TW',
  },
  {
    value: '2849.TW',
    label: '2849.TW,  EnTie Commercial Bank Co., Ltd.',
    name: 'EnTie Commercial Bank Co., Ltd.',
    symbol: '2849.TW',
  },
  {
    value: '2850.TW',
    label: '2850.TW,  Shinkong Insurance Co., Ltd.',
    name: 'Shinkong Insurance Co., Ltd.',
    symbol: '2850.TW',
  },
  {
    value: '2851.TW',
    label: '2851.TW,  Central Reinsurance Corporation',
    name: 'Central Reinsurance Corporation',
    symbol: '2851.TW',
  },
  {
    value: '2852.TW',
    label: '2852.TW,  The First Insurance Co., Ltd.',
    name: 'The First Insurance Co., Ltd.',
    symbol: '2852.TW',
  },
  {
    value: '2855.TW',
    label: '2855.TW,  President Securities Corporation',
    name: 'President Securities Corporation',
    symbol: '2855.TW',
  },
  {
    value: '2867.TW',
    label: '2867.TW,  Mercuries Life Insurance Company Ltd.',
    name: 'Mercuries Life Insurance Company Ltd.',
    symbol: '2867.TW',
  },
  {
    value: '2880.TW',
    label: '2880.TW,  Hua Nan Financial Holdings Co., Ltd.',
    name: 'Hua Nan Financial Holdings Co., Ltd.',
    symbol: '2880.TW',
  },
  {
    value: '2881.TW',
    label: '2881.TW,  Fubon Financial Holding Co., Ltd.',
    name: 'Fubon Financial Holding Co., Ltd.',
    symbol: '2881.TW',
  },
  {
    value: '2882.TW',
    label: '2882.TW,  Cathay Financial Holding Co., Ltd.',
    name: 'Cathay Financial Holding Co., Ltd.',
    symbol: '2882.TW',
  },
  {
    value: '2883.TW',
    label: '2883.TW,  China Development Financial Holding Corporation',
    name: 'China Development Financial Holding Corporation',
    symbol: '2883.TW',
  },
  {
    value: '2884.TW',
    label: '2884.TW,  E.SUN Financial Holding Company, Ltd.',
    name: 'E.SUN Financial Holding Company, Ltd.',
    symbol: '2884.TW',
  },
  {
    value: '2885.TW',
    label: '2885.TW,  Yuanta Financial Holding Co., Ltd.',
    name: 'Yuanta Financial Holding Co., Ltd.',
    symbol: '2885.TW',
  },
  {
    value: '2901.TW',
    label: '2901.TW,  Shin Shin Co Ltd.',
    name: 'Shin Shin Co Ltd.',
    symbol: '2901.TW',
  },
  {
    value: '2904.TW',
    label: '2904.TW,  Prime Oil Chemical Service Corporation',
    name: 'Prime Oil Chemical Service Corporation',
    symbol: '2904.TW',
  },
  {
    value: '2906.TW',
    label: '2906.TW,  Collins Co., Ltd.',
    name: 'Collins Co., Ltd.',
    symbol: '2906.TW',
  },
  {
    value: '2910.TW',
    label: '2910.TW,  Tonlin Department Store Co.,Ltd.',
    name: 'Tonlin Department Store Co.,Ltd.',
    symbol: '2910.TW',
  },
  {
    value: '2911.TW',
    label: '2911.TW,  Les Enphants Co., Ltd.',
    name: 'Les Enphants Co., Ltd.',
    symbol: '2911.TW',
  },
  {
    value: '2923.TW',
    label: '2923.TW,  Sino Horizon Holdings Limited',
    name: 'Sino Horizon Holdings Limited',
    symbol: '2923.TW',
  },
  {
    value: '2929.TW',
    label: '2929.TW,  TOPBI International Holdings Limited',
    name: 'TOPBI International Holdings Limited',
    symbol: '2929.TW',
  },
  {
    value: '2939.TW',
    label: '2939.TW,  Kayee International Group Co., Ltd',
    name: 'Kayee International Group Co., Ltd',
    symbol: '2939.TW',
  },
  {
    value: '3002.TW',
    label: '3002.TW,  AHOKU Electronic Company',
    name: 'AHOKU Electronic Company',
    symbol: '3002.TW',
  },
  {
    value: '3004.TW',
    label: '3004.TW,  National Aerospace Fasteners Corporation',
    name: 'National Aerospace Fasteners Corporation',
    symbol: '3004.TW',
  },
  {
    value: '3011.TW',
    label: '3011.TW,  Ji-Haw Industrial Co.,Ltd.',
    name: 'Ji-Haw Industrial Co.,Ltd.',
    symbol: '3011.TW',
  },
  {
    value: '3013.TW',
    label: '3013.TW,  Chenming Electronic Tech. Corp.',
    name: 'Chenming Electronic Tech. Corp.',
    symbol: '3013.TW',
  },
  {
    value: '3018.TW',
    label: '3018.TW,  Tung Kai Technology Engineering Co., LTD.',
    name: 'Tung Kai Technology Engineering Co., LTD.',
    symbol: '3018.TW',
  },
  {
    value: '3021.TW',
    label: '3021.TW,  Welltend Technology Corporation',
    name: 'Welltend Technology Corporation',
    symbol: '3021.TW',
  },
  {
    value: '3024.TW',
    label: '3024.TW,  Action Electronics Co., Ltd.',
    name: 'Action Electronics Co., Ltd.',
    symbol: '3024.TW',
  },
  {
    value: '3025.TW',
    label: '3025.TW,  Loop Telecommunication International,Inc.',
    name: 'Loop Telecommunication International,Inc.',
    symbol: '3025.TW',
  },
  {
    value: '3027.TW',
    label: '3027.TW,  Billion Electric Co., Ltd.',
    name: 'Billion Electric Co., Ltd.',
    symbol: '3027.TW',
  },
  {
    value: '3031.TW',
    label: '3031.TW,  Bright LED Electronics Corp.',
    name: 'Bright LED Electronics Corp.',
    symbol: '3031.TW',
  },
  {
    value: '3038.TW',
    label: '3038.TW,  Emerging Display Technologies Corp.',
    name: 'Emerging Display Technologies Corp.',
    symbol: '3038.TW',
  },
  {
    value: '3040.TW',
    label: '3040.TW,  Global View Co., Ltd.',
    name: 'Global View Co., Ltd.',
    symbol: '3040.TW',
  },
  {
    value: '3043.TW',
    label: '3043.TW,  Powercom Co., Ltd',
    name: 'Powercom Co., Ltd',
    symbol: '3043.TW',
  },
  {
    value: '3047.TW',
    label: '3047.TW,  EDIMAX Technology Co., Ltd.',
    name: 'EDIMAX Technology Co., Ltd.',
    symbol: '3047.TW',
  },
  {
    value: '3048.TW',
    label: '3048.TW,  EDOM Technology Co., Ltd.',
    name: 'EDOM Technology Co., Ltd.',
    symbol: '3048.TW',
  },
  {
    value: '3050.TW',
    label: '3050.TW,  U-Tech Media Corporation',
    name: 'U-Tech Media Corporation',
    symbol: '3050.TW',
  },
  {
    value: '3052.TW',
    label: '3052.TW,  Apex Science & Engineering Corp.',
    name: 'Apex Science & Engineering Corp.',
    symbol: '3052.TW',
  },
  {
    value: '3054.TW',
    label: '3054.TW,  LIWANLI Innovation Co., Ltd.',
    name: 'LIWANLI Innovation Co., Ltd.',
    symbol: '3054.TW',
  },
  {
    value: '3055.TW',
    label: '3055.TW,  Spirox Corporation',
    name: 'Spirox Corporation',
    symbol: '3055.TW',
  },
  {
    value: '3057.TW',
    label: '3057.TW,  Promise Technology, Inc.',
    name: 'Promise Technology, Inc.',
    symbol: '3057.TW',
  },
  {
    value: '3058.TW',
    label: '3058.TW,  Leader Electronics Inc.',
    name: 'Leader Electronics Inc.',
    symbol: '3058.TW',
  },
  {
    value: '3094.TW',
    label: '3094.TW,  Davicom Semiconductor, Inc.',
    name: 'Davicom Semiconductor, Inc.',
    symbol: '3094.TW',
  },
  {
    value: '3130.TW',
    label: '3130.TW,  104 Corporation',
    name: '104 Corporation',
    symbol: '3130.TW',
  },
  {
    value: '3149.TW',
    label: '3149.TW,  G-TECH Optoelectronics Corporation',
    name: 'G-TECH Optoelectronics Corporation',
    symbol: '3149.TW',
  },
  {
    value: '3164.TW',
    label: '3164.TW,  GenMont Biotech Incorporation',
    name: 'GenMont Biotech Incorporation',
    symbol: '3164.TW',
  },
  {
    value: '3167.TW',
    label: '3167.TW,  Taliang Technology Co., Ltd.',
    name: 'Taliang Technology Co., Ltd.',
    symbol: '3167.TW',
  },
  {
    value: '3189.TW',
    label: '3189.TW,  Kinsus Interconnect Technology Corp.',
    name: 'Kinsus Interconnect Technology Corp.',
    symbol: '3189.TW',
  },
  {
    value: '3209.TW',
    label: '3209.TW,  Alltek Technology Corporation',
    name: 'Alltek Technology Corporation',
    symbol: '3209.TW',
  },
  {
    value: '3229.TW',
    label: '3229.TW,  Cheer Time Enterprise Co., Ltd',
    name: 'Cheer Time Enterprise Co., Ltd',
    symbol: '3229.TW',
  },
  {
    value: '3257.TW',
    label: '3257.TW,  Champion Microelectronic Corporation',
    name: 'Champion Microelectronic Corporation',
    symbol: '3257.TW',
  },
  {
    value: '3266.TW',
    label: '3266.TW,  Sunty Development Co., LTD',
    name: 'Sunty Development Co., LTD',
    symbol: '3266.TW',
  },
  {
    value: '3296.TW',
    label: '3296.TW,  Powertech Industrial Co., Ltd.',
    name: 'Powertech Industrial Co., Ltd.',
    symbol: '3296.TW',
  },
  {
    value: '3305.TW',
    label: '3305.TW,  Shenmao Technology Inc',
    name: 'Shenmao Technology Inc',
    symbol: '3305.TW',
  },
  {
    value: '3308.TW',
    label: '3308.TW,  Bestec Power Electronics Co., Ltd.',
    name: 'Bestec Power Electronics Co., Ltd.',
    symbol: '3308.TW',
  },
  {
    value: '3311.TW',
    label: '3311.TW,  Silitech Technology Corporation',
    name: 'Silitech Technology Corporation',
    symbol: '3311.TW',
  },
  {
    value: '3312.TW',
    label: '3312.TW,  GMI Technology Inc.',
    name: 'GMI Technology Inc.',
    symbol: '3312.TW',
  },
  {
    value: '3321.TW',
    label: '3321.TW,  Uniflex Technology Inc.',
    name: 'Uniflex Technology Inc.',
    symbol: '3321.TW',
  },
  {
    value: '3338.TW',
    label: '3338.TW,  TaiSol Electronics Co., Ltd.',
    name: 'TaiSol Electronics Co., Ltd.',
    symbol: '3338.TW',
  },
  {
    value: '3346.TW',
    label: '3346.TW,  Laster Tech Co., Ltd.',
    name: 'Laster Tech Co., Ltd.',
    symbol: '3346.TW',
  },
  {
    value: '3356.TW',
    label: '3356.TW,  GeoVision Inc.',
    name: 'GeoVision Inc.',
    symbol: '3356.TW',
  },
  {
    value: '3376.TW',
    label: '3376.TW,  Shin Zu Shing Co., Ltd.',
    name: 'Shin Zu Shing Co., Ltd.',
    symbol: '3376.TW',
  },
  {
    value: '3380.TW',
    label: '3380.TW,  Alpha Networks Inc.',
    name: 'Alpha Networks Inc.',
    symbol: '3380.TW',
  },
  {
    value: '3406.TW',
    label: '3406.TW,  Genius Electronic Optical Co., Ltd',
    name: 'Genius Electronic Optical Co., Ltd',
    symbol: '3406.TW',
  },
  {
    value: '3413.TW',
    label: '3413.TW,  Foxsemicon Integrated Technology Inc.',
    name: 'Foxsemicon Integrated Technology Inc.',
    symbol: '3413.TW',
  },
  {
    value: '3416.TW',
    label: '3416.TW,  WinMate Inc.',
    name: 'WinMate Inc.',
    symbol: '3416.TW',
  },
  {
    value: '3419.TW',
    label: '3419.TW,  Wha Yu Industrial Co., Ltd.',
    name: 'Wha Yu Industrial Co., Ltd.',
    symbol: '3419.TW',
  },
  {
    value: '3432.TW',
    label: '3432.TW,  Tai Twun Enterprise Co., Ltd.',
    name: 'Tai Twun Enterprise Co., Ltd.',
    symbol: '3432.TW',
  },
  {
    value: '3437.TW',
    label: '3437.TW,  Advanced Optoelectronic Technology Inc.',
    name: 'Advanced Optoelectronic Technology Inc.',
    symbol: '3437.TW',
  },
  {
    value: '3443.TW',
    label: '3443.TW,  Global Unichip Corp.',
    name: 'Global Unichip Corp.',
    symbol: '3443.TW',
  },
  {
    value: '3450.TW',
    label: '3450.TW,  Elite Advanced Laser Corporation',
    name: 'Elite Advanced Laser Corporation',
    symbol: '3450.TW',
  },
  {
    value: '3454.TW',
    label: '3454.TW,  Vivotek Inc.',
    name: 'Vivotek Inc.',
    symbol: '3454.TW',
  },
  {
    value: '3494.TW',
    label: '3494.TW,  HiTi Digital, Inc.',
    name: 'HiTi Digital, Inc.',
    symbol: '3494.TW',
  },
  {
    value: '3518.TW',
    label: '3518.TW,  Paragon Technologies Co., Ltd.',
    name: 'Paragon Technologies Co., Ltd.',
    symbol: '3518.TW',
  },
  {
    value: '3528.TW',
    label: '3528.TW,  Answer Technology Co., Ltd.',
    name: 'Answer Technology Co., Ltd.',
    symbol: '3528.TW',
  },
  {
    value: '3535.TW',
    label: '3535.TW,  Favite, Inc.',
    name: 'Favite, Inc.',
    symbol: '3535.TW',
  },
  {
    value: '3536.TW',
    label: '3536.TW,  Sintronic Technology Inc.',
    name: 'Sintronic Technology Inc.',
    symbol: '3536.TW',
  },
  {
    value: '3543.TW',
    label: '3543.TW,  Jochu Technology Co., Ltd.',
    name: 'Jochu Technology Co., Ltd.',
    symbol: '3543.TW',
  },
  {
    value: '3550.TW',
    label: '3550.TW,  Copartner Technology Corporation',
    name: 'Copartner Technology Corporation',
    symbol: '3550.TW',
  },
  {
    value: '3557.TW',
    label: '3557.TW,  Jia Wei Lifestyle, Inc.',
    name: 'Jia Wei Lifestyle, Inc.',
    symbol: '3557.TW',
  },
  {
    value: '3591.TW',
    label: '3591.TW,  Edison Opto Corporation',
    name: 'Edison Opto Corporation',
    symbol: '3591.TW',
  },
  {
    value: '3593.TW',
    label: '3593.TW,  Logah Technology Corp.',
    name: 'Logah Technology Corp.',
    symbol: '3593.TW',
  },
  {
    value: '3617.TW',
    label: '3617.TW,  Cyber Power Systems, Inc.',
    name: 'Cyber Power Systems, Inc.',
    symbol: '3617.TW',
  },
  {
    value: '3669.TW',
    label: '3669.TW,  AVer Information Inc.',
    name: 'AVer Information Inc.',
    symbol: '3669.TW',
  },
  {
    value: '3686.TW',
    label: '3686.TW,  Danen Technology Corporation',
    name: 'Danen Technology Corporation',
    symbol: '3686.TW',
  },
  {
    value: '3701.TW',
    label: '3701.TW,  FIC Global, Inc.',
    name: 'FIC Global, Inc.',
    symbol: '3701.TW',
  },
  {
    value: '4106.TW',
    label: '4106.TW,  Wellell Inc.',
    name: 'Wellell Inc.',
    symbol: '4106.TW',
  },
  {
    value: '4133.TW',
    label: '4133.TW,  Abnova (Taiwan) Corporation',
    name: 'Abnova (Taiwan) Corporation',
    symbol: '4133.TW',
  },
  {
    value: '4148.TW',
    label: '4148.TW,  All Cosmos Bio-Tech Holding Corporation',
    name: 'All Cosmos Bio-Tech Holding Corporation',
    symbol: '4148.TW',
  },
  {
    value: '4155.TW',
    label: '4155.TW,  OK Biotech Co., Ltd.',
    name: 'OK Biotech Co., Ltd.',
    symbol: '4155.TW',
  },
  {
    value: '4439.TW',
    label: '4439.TW,  TST Group Holding Ltd.',
    name: 'TST Group Holding Ltd.',
    symbol: '4439.TW',
  },
  {
    value: '4440.TW',
    label: '4440.TW,  Yi Shin Textile Industrial Co., Ltd.',
    name: 'Yi Shin Textile Industrial Co., Ltd.',
    symbol: '4440.TW',
  },
  {
    value: '4526.TW',
    label: '4526.TW,  Tongtai Machine & Tool Co., Ltd.',
    name: 'Tongtai Machine & Tool Co., Ltd.',
    symbol: '4526.TW',
  },
  {
    value: '4540.TW',
    label: '4540.TW,  Tbi Motion Technology Co., Ltd.',
    name: 'Tbi Motion Technology Co., Ltd.',
    symbol: '4540.TW',
  },
  {
    value: '4545.TW',
    label: '4545.TW,  Min Aik Precision Industrial Co., Ltd.',
    name: 'Min Aik Precision Industrial Co., Ltd.',
    symbol: '4545.TW',
  },
  {
    value: '4552.TW',
    label: '4552.TW,  Lida Holdings Limited',
    name: 'Lida Holdings Limited',
    symbol: '4552.TW',
  },
  {
    value: '4555.TW',
    label: '4555.TW,  TAIWAN CHELIC Co., Ltd.',
    name: 'TAIWAN CHELIC Co., Ltd.',
    symbol: '4555.TW',
  },
  {
    value: '4557.TW',
    label: '4557.TW,  Yusin Holding Corp.',
    name: 'Yusin Holding Corp.',
    symbol: '4557.TW',
  },
  {
    value: '4560.TW',
    label: '4560.TW,  Strong H Machinery Technology (Cayman) Incorporation',
    name: 'Strong H Machinery Technology (Cayman) Incorporation',
    symbol: '4560.TW',
  },
  {
    value: '4562.TW',
    label: '4562.TW,  Ying Han Technology Co., Ltd.',
    name: 'Ying Han Technology Co., Ltd.',
    symbol: '4562.TW',
  },
  {
    value: '4566.TW',
    label: '4566.TW,  GLOBAL TEK FABRICATION CO., Ltd.',
    name: 'GLOBAL TEK FABRICATION CO., Ltd.',
    symbol: '4566.TW',
  },
  {
    value: '4572.TW',
    label: '4572.TW,  Drewloong Precision, Inc.',
    name: 'Drewloong Precision, Inc.',
    symbol: '4572.TW',
  },
  {
    value: '4576.TW',
    label: '4576.TW,  Hiwin Mikrosystem Corp.',
    name: 'Hiwin Mikrosystem Corp.',
    symbol: '4576.TW',
  },
  {
    value: '4581.TW',
    label: '4581.TW,  World Known MFG (Cayman) Limited',
    name: 'World Known MFG (Cayman) Limited',
    symbol: '4581.TW',
  },
  {
    value: '4720.TW',
    label: '4720.TW,  Tex Year Industries Inc.',
    name: 'Tex Year Industries Inc.',
    symbol: '4720.TW',
  },
  {
    value: '4722.TW',
    label: '4722.TW,  Qualipoly Chemical Corp.',
    name: 'Qualipoly Chemical Corp.',
    symbol: '4722.TW',
  },
  {
    value: '4737.TW',
    label: '4737.TW,  Bionime Corporation',
    name: 'Bionime Corporation',
    symbol: '4737.TW',
  },
  {
    value: '4755.TW',
    label: '4755.TW,  San Fu Chemical Co., Ltd.',
    name: 'San Fu Chemical Co., Ltd.',
    symbol: '4755.TW',
  },
  {
    value: '4763.TW',
    label: '4763.TW,  Jinan Acetate Chemical Co., Ltd.',
    name: 'Jinan Acetate Chemical Co., Ltd.',
    symbol: '4763.TW',
  },
  {
    value: '4807.TW',
    label: '4807.TW,  Regal Holding Co., Ltd.',
    name: 'Regal Holding Co., Ltd.',
    symbol: '4807.TW',
  },
  {
    value: '4916.TW',
    label: '4916.TW,  Parpro Corporation',
    name: 'Parpro Corporation',
    symbol: '4916.TW',
  },
  {
    value: '4930.TW',
    label: '4930.TW,  Star Comgistic Capital Co., Ltd.',
    name: 'Star Comgistic Capital Co., Ltd.',
    symbol: '4930.TW',
  },
  {
    value: '4952.TW',
    label: '4952.TW,  Generalplus Technology Inc.',
    name: 'Generalplus Technology Inc.',
    symbol: '4952.TW',
  },
  {
    value: '4956.TW',
    label: '4956.TW,  Epileds Technologies, Inc.',
    name: 'Epileds Technologies, Inc.',
    symbol: '4956.TW',
  },
  {
    value: '4977.TW',
    label: '4977.TW,  PCL Technologies, Inc.',
    name: 'PCL Technologies, Inc.',
    symbol: '4977.TW',
  },
  {
    value: '4989.TW',
    label: '4989.TW,  LCY Technology Corp.',
    name: 'LCY Technology Corp.',
    symbol: '4989.TW',
  },
  {
    value: '4994.TW',
    label: '4994.TW,  X-Legend Entertainment Co., Ltd.',
    name: 'X-Legend Entertainment Co., Ltd.',
    symbol: '4994.TW',
  },
  {
    value: '5215.TW',
    label: '5215.TW,  Ko Ja (Cayman) Co., Ltd.',
    name: 'Ko Ja (Cayman) Co., Ltd.',
    symbol: '5215.TW',
  },
  {
    value: '5222.TW',
    label: '5222.TW,  Transcom, Inc.',
    name: 'Transcom, Inc.',
    symbol: '5222.TW',
  },
  {
    value: '5225.TW',
    label: '5225.TW,  Eastech Holding Limited',
    name: 'Eastech Holding Limited',
    symbol: '5225.TW',
  },
  {
    value: '5284.TW',
    label: '5284.TW,  JPP Holding Company Limited',
    name: 'JPP Holding Company Limited',
    symbol: '5284.TW',
  },
  {
    value: '5484.TW',
    label: '5484.TW,  EverFocus Electronics Corporation',
    name: 'EverFocus Electronics Corporation',
    symbol: '5484.TW',
  },
  {
    value: '5515.TW',
    label: '5515.TW,  Chien Kuo Construction Co., Ltd.',
    name: 'Chien Kuo Construction Co., Ltd.',
    symbol: '5515.TW',
  },
  {
    value: '5538.TW',
    label: '5538.TW,  Tong Ming Enterprise Co., Ltd.',
    name: 'Tong Ming Enterprise Co., Ltd.',
    symbol: '5538.TW',
  },
  {
    value: '5546.TW',
    label: '5546.TW,  Yonggu Group Inc.',
    name: 'Yonggu Group Inc.',
    symbol: '5546.TW',
  },
  {
    value: '5706.TW',
    label: '5706.TW,  Phoenix Tours International, Inc.',
    name: 'Phoenix Tours International, Inc.',
    symbol: '5706.TW',
  },
  {
    value: '5906.TW',
    label: '5906.TW,  Tainan Enterprise (Cayman) Co., Limited',
    name: 'Tainan Enterprise (Cayman) Co., Limited',
    symbol: '5906.TW',
  },
  {
    value: '6117.TW',
    label: '6117.TW,  In Win Development Inc.',
    name: 'In Win Development Inc.',
    symbol: '6117.TW',
  },
  {
    value: '6128.TW',
    label: '6128.TW,  General Plastic Industrial Co., Ltd.',
    name: 'General Plastic Industrial Co., Ltd.',
    symbol: '6128.TW',
  },
  {
    value: '6133.TW',
    label: '6133.TW,  Golden Bridge Electech Inc.',
    name: 'Golden Bridge Electech Inc.',
    symbol: '6133.TW',
  },
  {
    value: '6141.TW',
    label: '6141.TW,  Plotech Co.,Ltd',
    name: 'Plotech Co.,Ltd',
    symbol: '6141.TW',
  },
  {
    value: '6142.TW',
    label: '6142.TW,  Cameo Communications, Inc.',
    name: 'Cameo Communications, Inc.',
    symbol: '6142.TW',
  },
  {
    value: '6152.TW',
    label: '6152.TW,  Prime Electronics & Satellitics Inc.',
    name: 'Prime Electronics & Satellitics Inc.',
    symbol: '6152.TW',
  },
  {
    value: '6155.TW',
    label: '6155.TW,  King Core Electronics Inc.',
    name: 'King Core Electronics Inc.',
    symbol: '6155.TW',
  },
  {
    value: '6164.TW',
    label: '6164.TW,  Ledtech Electronics Corp.',
    name: 'Ledtech Electronics Corp.',
    symbol: '6164.TW',
  },
  {
    value: '6165.TW',
    label: '6165.TW,  Lang Inc.',
    name: 'Lang Inc.',
    symbol: '6165.TW',
  },
  {
    value: '6166.TW',
    label: '6166.TW,  ADLINK Technology, Inc.',
    name: 'ADLINK Technology, Inc.',
    symbol: '6166.TW',
  },
  {
    value: '6201.TW',
    label: '6201.TW,  Ya Horng Electronic Co., Ltd.',
    name: 'Ya Horng Electronic Co., Ltd.',
    symbol: '6201.TW',
  },
  {
    value: '6205.TW',
    label: '6205.TW,  Chant Sincere Co., Ltd.',
    name: 'Chant Sincere Co., Ltd.',
    symbol: '6205.TW',
  },
  {
    value: '6215.TW',
    label: '6215.TW,  Aurotek Corporation',
    name: 'Aurotek Corporation',
    symbol: '6215.TW',
  },
  {
    value: '6216.TW',
    label: '6216.TW,  DrayTek Corporation',
    name: 'DrayTek Corporation',
    symbol: '6216.TW',
  },
  {
    value: '6225.TW',
    label: '6225.TW,  AIPTEK International Inc.',
    name: 'AIPTEK International Inc.',
    symbol: '6225.TW',
  },
  {
    value: '6226.TW',
    label: '6226.TW,  Para Light Electronics Co., Ltd.',
    name: 'Para Light Electronics Co., Ltd.',
    symbol: '6226.TW',
  },
  {
    value: '6235.TW',
    label: '6235.TW,  Waffer Technology Corporation',
    name: 'Waffer Technology Corporation',
    symbol: '6235.TW',
  },
  {
    value: '6243.TW',
    label: '6243.TW,  Ene Technology Inc.',
    name: 'Ene Technology Inc.',
    symbol: '6243.TW',
  },
  {
    value: '6288.TW',
    label: '6288.TW,  Excellence Optoelectronics Inc.',
    name: 'Excellence Optoelectronics Inc.',
    symbol: '6288.TW',
  },
  {
    value: '6405.TW',
    label: '6405.TW,  Onano Industrial Corp.',
    name: 'Onano Industrial Corp.',
    symbol: '6405.TW',
  },
  {
    value: '6426.TW',
    label: '6426.TW,  Apogee Optocom Co., Ltd.',
    name: 'Apogee Optocom Co., Ltd.',
    symbol: '6426.TW',
  },
  {
    value: '6431.TW',
    label: '6431.TW,  Kuangli Photoelectric Technology Co., Ltd.',
    name: 'Kuangli Photoelectric Technology Co., Ltd.',
    symbol: '6431.TW',
  },
  {
    value: '6442.TW',
    label: '6442.TW,  EZconn Corporation',
    name: 'EZconn Corporation',
    symbol: '6442.TW',
  },
  {
    value: '6464.TW',
    label: '6464.TW,  Taiwan Optical Platform Co., Ltd.',
    name: 'Taiwan Optical Platform Co., Ltd.',
    symbol: '6464.TW',
  },
  {
    value: '6477.TW',
    label: '6477.TW,  ANJI Technology Co., Ltd.',
    name: 'ANJI Technology Co., Ltd.',
    symbol: '6477.TW',
  },
  {
    value: '6558.TW',
    label: '6558.TW,  SYNergy ScienTech Corp.',
    name: 'SYNergy ScienTech Corp.',
    symbol: '6558.TW',
  },
  {
    value: '6581.TW',
    label: '6581.TW,  Taiwan Steel Union Co., Ltd.',
    name: 'Taiwan Steel Union Co., Ltd.',
    symbol: '6581.TW',
  },
  {
    value: '6591.TW',
    label: '6591.TW,  Sun Max Tech Limited',
    name: 'Sun Max Tech Limited',
    symbol: '6591.TW',
  },
  {
    value: '6625.TW',
    label: "6625.TW,  B'in Live Co., Ltd.",
    name: "B'in Live Co., Ltd.",
    symbol: '6625.TW',
  },
  {
    value: '6641.TW',
    label: '6641.TW,  GSD Technologies Co., Ltd.',
    name: 'GSD Technologies Co., Ltd.',
    symbol: '6641.TW',
  },
  {
    value: '6655.TW',
    label: '6655.TW,  Keding Enterprises Co., Ltd.',
    name: 'Keding Enterprises Co., Ltd.',
    symbol: '6655.TW',
  },
  {
    value: '6666.TW',
    label: '6666.TW,  Luo Lih-Fen Holding Co., Ltd.',
    name: 'Luo Lih-Fen Holding Co., Ltd.',
    symbol: '6666.TW',
  },
  {
    value: '6668.TW',
    label: '6668.TW,  Zhong Yang Technology Co., Ltd.',
    name: 'Zhong Yang Technology Co., Ltd.',
    symbol: '6668.TW',
  },
  {
    value: '6671.TW',
    label: '6671.TW,  San Neng Group Holdings Co., Ltd.',
    name: 'San Neng Group Holdings Co., Ltd.',
    symbol: '6671.TW',
  },
  {
    value: '6674.TW',
    label: '6674.TW,  Compal Broadband Networks, Inc.',
    name: 'Compal Broadband Networks, Inc.',
    symbol: '6674.TW',
  },
  {
    value: '6698.TW',
    label: '6698.TW,  FineMat Applied Materials Co., Ltd.',
    name: 'FineMat Applied Materials Co., Ltd.',
    symbol: '6698.TW',
  },
  {
    value: '6754.TW',
    label: '6754.TW,  Rich Honour International Designs Co., Ltd.',
    name: 'Rich Honour International Designs Co., Ltd.',
    symbol: '6754.TW',
  },
  {
    value: '6768.TW',
    label: '6768.TW,  Sports Gear Co., Ltd.',
    name: 'Sports Gear Co., Ltd.',
    symbol: '6768.TW',
  },
  {
    value: '6776.TW',
    label: '6776.TW,  Weblink International Inc.',
    name: 'Weblink International Inc.',
    symbol: '6776.TW',
  },
  {
    value: '6790.TW',
    label: '6790.TW,  Yuen Foong Yu Consumer Products Co., Ltd.',
    name: 'Yuen Foong Yu Consumer Products Co., Ltd.',
    symbol: '6790.TW',
  },
  {
    value: '6806.TW',
    label: '6806.TW,  Shinfox Energy Co., Ltd.',
    name: 'Shinfox Energy Co., Ltd.',
    symbol: '6806.TW',
  },
  {
    value: '8011.TW',
    label: '8011.TW,  Tai Tung Communication Co., Ltd.',
    name: 'Tai Tung Communication Co., Ltd.',
    symbol: '8011.TW',
  },
  {
    value: '8033.TW',
    label: '8033.TW,  Thunder Tiger Corp.',
    name: 'Thunder Tiger Corp.',
    symbol: '8033.TW',
  },
  {
    value: '8072.TW',
    label: '8072.TW,  AV TECH Corporation',
    name: 'AV TECH Corporation',
    symbol: '8072.TW',
  },
  {
    value: '8101.TW',
    label: '8101.TW,  Arima Communications Corp.',
    name: 'Arima Communications Corp.',
    symbol: '8101.TW',
  },
  {
    value: '8104.TW',
    label: '8104.TW,  RiTdisplay Corporation',
    name: 'RiTdisplay Corporation',
    symbol: '8104.TW',
  },
  {
    value: '8114.TW',
    label: '8114.TW,  Posiflex Technology, Inc.',
    name: 'Posiflex Technology, Inc.',
    symbol: '8114.TW',
  },
  {
    value: '8201.TW',
    label: '8201.TW,  Inventec Besta Co.,Ltd',
    name: 'Inventec Besta Co.,Ltd',
    symbol: '8201.TW',
  },
  {
    value: '8222.TW',
    label: '8222.TW,  Aero Win Technology Corporation',
    name: 'Aero Win Technology Corporation',
    symbol: '8222.TW',
  },
  {
    value: '8249.TW',
    label: '8249.TW,  Creative Sensor Inc.',
    name: 'Creative Sensor Inc.',
    symbol: '8249.TW',
  },
  {
    value: '8271.TW',
    label: '8271.TW,  Apacer Technology Inc.',
    name: 'Apacer Technology Inc.',
    symbol: '8271.TW',
  },
  {
    value: '8367.TW',
    label: '8367.TW,  Chien Shing Harbour Service Co.,Ltd.',
    name: 'Chien Shing Harbour Service Co.,Ltd.',
    symbol: '8367.TW',
  },
  {
    value: '8374.TW',
    label: '8374.TW,  Ace Pillar Co., Ltd.',
    name: 'Ace Pillar Co., Ltd.',
    symbol: '8374.TW',
  },
  {
    value: '8411.TW',
    label: '8411.TW,  Kingcan Holdings Limited',
    name: 'Kingcan Holdings Limited',
    symbol: '8411.TW',
  },
  {
    value: '8429.TW',
    label: '8429.TW,  Jinli Group Holdings Limited',
    name: 'Jinli Group Holdings Limited',
    symbol: '8429.TW',
  },
  {
    value: '8442.TW',
    label: '8442.TW,  WW Holding Inc.',
    name: 'WW Holding Inc.',
    symbol: '8442.TW',
  },
  {
    value: '8443.TW',
    label: '8443.TW,  SHUI-MU International Co., Ltd.',
    name: 'SHUI-MU International Co., Ltd.',
    symbol: '8443.TW',
  },
  {
    value: '8463.TW',
    label: '8463.TW,  Ruentex Materials Co.,Ltd.',
    name: 'Ruentex Materials Co.,Ltd.',
    symbol: '8463.TW',
  },
  {
    value: '8466.TW',
    label: '8466.TW,  M.J. International Co., Ltd.',
    name: 'M.J. International Co., Ltd.',
    symbol: '8466.TW',
  },
  {
    value: '8467.TW',
    label: '8467.TW,  Bonny Worldwide Limited',
    name: 'Bonny Worldwide Limited',
    symbol: '8467.TW',
  },
  {
    value: '8478.TW',
    label: '8478.TW,  Alexander Marine Co., Ltd.',
    name: 'Alexander Marine Co., Ltd.',
    symbol: '8478.TW',
  },
  {
    value: '8480.TW',
    label: "8480.TW,  Taisun Int'l (Holding) Corporation",
    name: "Taisun Int'l (Holding) Corporation",
    symbol: '8480.TW',
  },
  {
    value: '8481.TW',
    label: '8481.TW,  Transart Graphics Co., Ltd.',
    name: 'Transart Graphics Co., Ltd.',
    symbol: '8481.TW',
  },
  {
    value: '8482.TW',
    label: '8482.TW,  Shane Global Holding Inc.',
    name: 'Shane Global Holding Inc.',
    symbol: '8482.TW',
  },
  {
    value: '8488.TW',
    label: '8488.TW,  Jiyuan Packaging Holdings Limited',
    name: 'Jiyuan Packaging Holdings Limited',
    symbol: '8488.TW',
  },
  {
    value: '8940.TW',
    label: '8940.TW,  New Palace International Co., Ltd.',
    name: 'New Palace International Co., Ltd.',
    symbol: '8940.TW',
  },
  {
    value: '8996.TW',
    label: '8996.TW,  Kaori Heat Treatment Co., Ltd.',
    name: 'Kaori Heat Treatment Co., Ltd.',
    symbol: '8996.TW',
  },
  {
    value: '9103.TW',
    label: '9103.TW,  Medtecs International Corporation Limited',
    name: 'Medtecs International Corporation Limited',
    symbol: '9103.TW',
  },
  {
    value: '9105.TW',
    label: '9105.TW,  Cal-Comp Electronics (Thailand) Public Company Limited',
    name: 'Cal-Comp Electronics (Thailand) Public Company Limited',
    symbol: '9105.TW',
  },
  {
    value: '9110.TW',
    label:
      '9110.TW,  Vietnam Manufacturing and Export Processing (Holdings) Limited',
    name: 'Vietnam Manufacturing and Export Processing (Holdings) Limited',
    symbol: '9110.TW',
  },
  {
    value: '9136.TW',
    label: '9136.TW,  Ju Teng International Holdings Limited',
    name: 'Ju Teng International Holdings Limited',
    symbol: '9136.TW',
  },
  {
    value: '9902.TW',
    label: '9902.TW,  Tidehold Development Co., Ltd.',
    name: 'Tidehold Development Co., Ltd.',
    symbol: '9902.TW',
  },
  {
    value: '9906.TW',
    label: '9906.TW,  Hsin Ba Ba Corporation',
    name: 'Hsin Ba Ba Corporation',
    symbol: '9906.TW',
  },
  {
    value: '9908.TW',
    label: '9908.TW,  The Great Taipei Gas Corporation',
    name: 'The Great Taipei Gas Corporation',
    symbol: '9908.TW',
  },
  {
    value: '9918.TW',
    label: '9918.TW,  Shin Shin Natural Gas Company Limited',
    name: 'Shin Shin Natural Gas Company Limited',
    symbol: '9918.TW',
  },
  {
    value: '9926.TW',
    label: '9926.TW,  Shin Hai Gas Corporation',
    name: 'Shin Hai Gas Corporation',
    symbol: '9926.TW',
  },
  {
    value: '9928.TW',
    label: '9928.TW,  China Television Company, Ltd.',
    name: 'China Television Company, Ltd.',
    symbol: '9928.TW',
  },
  {
    value: '9929.TW',
    label: '9929.TW,  Choice Development, Inc.',
    name: 'Choice Development, Inc.',
    symbol: '9929.TW',
  },
  {
    value: '9931.TW',
    label: '9931.TW,  Hsin Kao Gas Co.,Ltd.',
    name: 'Hsin Kao Gas Co.,Ltd.',
    symbol: '9931.TW',
  },
  {
    value: '9935.TW',
    label: '9935.TW,  Ching Feng Home Fashions Co.,Ltd',
    name: 'Ching Feng Home Fashions Co.,Ltd',
    symbol: '9935.TW',
  },
  {
    value: '9944.TW',
    label: '9944.TW,  Shinih Enterprise Co., Ltd.',
    name: 'Shinih Enterprise Co., Ltd.',
    symbol: '9944.TW',
  },
  {
    value: '9946.TW',
    label: '9946.TW,  San Far Property Limited',
    name: 'San Far Property Limited',
    symbol: '9946.TW',
  },
  {
    value: '9955.TW',
    label: '9955.TW,  Super Dragon Technology Co., Ltd',
    name: 'Super Dragon Technology Co., Ltd',
    symbol: '9955.TW',
  },
  {
    value: '910322.TW',
    label: '910322.TW,  Tingyi (Cayman Islands) Holding Corp.',
    name: 'Tingyi (Cayman Islands) Holding Corp.',
    symbol: '910322.TW',
  },
  {
    value: '910861.TW',
    label: '910861.TW,  Digital China Holdings Limited',
    name: 'Digital China Holdings Limited',
    symbol: '910861.TW',
  },
  {
    value: '911608.TW',
    label: '911608.TW,  BH Global Corporation Limited',
    name: 'BH Global Corporation Limited',
    symbol: '911608.TW',
  },
  {
    value: '911622.TW',
    label:
      '911622.TW,  Tycoons Worldwide Group (Thailand) Public Company Limited',
    name: 'Tycoons Worldwide Group (Thailand) Public Company Limited',
    symbol: '911622.TW',
  },
  {
    value: '911868.TW',
    label: '911868.TW,  Neo-Neon Holdings Limited',
    name: 'Neo-Neon Holdings Limited',
    symbol: '911868.TW',
  },
  {
    value: '912000.TW',
    label: '912000.TW,  SIM Technology Group Limited',
    name: 'SIM Technology Group Limited',
    symbol: '912000.TW',
  },
  {
    value: 'AERC',
    label: 'AERC,  AeroClean Technologies, Inc.',
    name: 'AeroClean Technologies, Inc.',
    symbol: 'AERC',
  },
  {
    value: 'DGHI',
    label: 'DGHI,  Digihost Technology Inc.',
    name: 'Digihost Technology Inc.',
    symbol: 'DGHI',
  },
  {
    value: 'FA.L',
    label: 'FA.L,  FireAngel Safety Technology Group plc',
    name: 'FireAngel Safety Technology Group plc',
    symbol: 'FA.L',
  },
  {
    value: '18MK.DE',
    label: '18MK.DE,  Amundi Index Solutions - Amundi MSCI India',
    name: 'Amundi Index Solutions - Amundi MSCI India',
    symbol: '18MK.DE',
  },
  {
    value: '2B70.DE',
    label: '2B70.DE,  iShares Nasdaq US Biotechnology UCITS ETF USD (Acc)',
    name: 'iShares Nasdaq US Biotechnology UCITS ETF USD (Acc)',
    symbol: '2B70.DE',
  },
  {
    value: '2B7F.DE',
    label: '2B7F.DE,  iShares Automation & Robotics UCITS ETF',
    name: 'iShares Automation & Robotics UCITS ETF',
    symbol: '2B7F.DE',
  },
  {
    value: '2B7J.DE',
    label: '2B7J.DE,  iShares MSCI World SRI UCITS ETF',
    name: 'iShares MSCI World SRI UCITS ETF',
    symbol: '2B7J.DE',
  },
  {
    value: '36B1.DE',
    label: '36B1.DE,  iShares J.P. Morgan ESG $ EM Bond UCITS ETF',
    name: 'iShares J.P. Morgan ESG $ EM Bond UCITS ETF',
    symbol: '36B1.DE',
  },
  {
    value: '36BB.DE',
    label:
      '36BB.DE,  iShares MSCI World Consumer Discretionary Sector UCITS ETF',
    name: 'iShares MSCI World Consumer Discretionary Sector UCITS ETF',
    symbol: '36BB.DE',
  },
  {
    value: '4PN.DE',
    label: '4PN.DE,  Carrier Global Corporation',
    name: 'Carrier Global Corporation',
    symbol: '4PN.DE',
  },
  {
    value: '4XT.DE',
    label: '4XT.DE,  XPhyto Therapeutics Corp.',
    name: 'XPhyto Therapeutics Corp.',
    symbol: '4XT.DE',
  },
  {
    value: '540G.DE',
    label:
      '540G.DE,  Amundi Index Solutions - Amundi MSCI Eastern Europe Ex Russia',
    name: 'Amundi Index Solutions - Amundi MSCI Eastern Europe Ex Russia',
    symbol: '540G.DE',
  },
  {
    value: '5CV.DE',
    label: '5CV.DE,  CureVac N.V.',
    name: 'CureVac N.V.',
    symbol: '5CV.DE',
  },
  {
    value: '5ESG.DE',
    label: '5ESG.DE,  Invesco S&P 500 ESG UCITS ETF',
    name: 'Invesco S&P 500 ESG UCITS ETF',
    symbol: '5ESG.DE',
  },
  {
    value: '6AQQ.DE',
    label: '6AQQ.DE,  Amundi Index Solutions - Amundi Nasdaq-100',
    name: 'Amundi Index Solutions - Amundi Nasdaq-100',
    symbol: '6AQQ.DE',
  },
  {
    value: '6FIN.DE',
    label:
      '6FIN.DE,  Lyxor Index Fund - Lyxor STOXX Europe 600 Financial Services UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor STOXX Europe 600 Financial Services UCITS ETF',
    symbol: '6FIN.DE',
  },
  {
    value: '6PSK.DE',
    label: '6PSK.DE,  Invesco FTSE RAFI Emerging Markets UCITS ETF',
    name: 'Invesco FTSE RAFI Emerging Markets UCITS ETF',
    symbol: '6PSK.DE',
  },
  {
    value: '6TVL.DE',
    label:
      '6TVL.DE,  Lyxor Index Fund - Lyxor STOXX Europe 600 Travel & Leisure UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor STOXX Europe 600 Travel & Leisure UCITS ETF',
    symbol: '6TVL.DE',
  },
  {
    value: '6TVM.DE',
    label: '6TVM.DE,  Lyxor S&P 500 UCITS ETF',
    name: 'Lyxor S&P 500 UCITS ETF',
    symbol: '6TVM.DE',
  },
  {
    value: '93M1.DE',
    label: '93M1.DE,  MPH Health Care AG',
    name: 'MPH Health Care AG',
    symbol: '93M1.DE',
  },
  {
    value: 'AAQ1.DE',
    label: 'AAQ1.DE,  aap Implantate AG',
    name: 'aap Implantate AG',
    symbol: 'AAQ1.DE',
  },
  {
    value: 'ABEC.DE',
    label: 'ABEC.DE,  Alphabet Inc.',
    name: 'Alphabet Inc.',
    symbol: 'ABEC.DE',
  },
  {
    value: 'AHYE.DE',
    label: 'AHYE.DE,  Amundi Index Solutions - Amundi EURO High Yield Bond ESG',
    name: 'Amundi Index Solutions - Amundi EURO High Yield Bond ESG',
    symbol: 'AHYE.DE',
  },
  {
    value: 'AMEC.DE',
    label: 'AMEC.DE,  Amundi Index Solutions - Amundi Smart City ETF',
    name: 'Amundi Index Solutions - Amundi Smart City ETF',
    symbol: 'AMEC.DE',
  },
  {
    value: 'AMEI.DE',
    label:
      'AMEI.DE,  Amundi Index Solutions - Amundi Index MSCI Emerging Markets SRI PAB',
    name: 'Amundi Index Solutions - Amundi Index MSCI Emerging Markets SRI PAB',
    symbol: 'AMEI.DE',
  },
  {
    value: 'AMEQ.DE',
    label:
      'AMEQ.DE,  Amundi Index Solutions - Amundi MSCI Europe Quality Factor',
    name: 'Amundi Index Solutions - Amundi MSCI Europe Quality Factor',
    symbol: 'AMEQ.DE',
  },
  {
    value: 'AMEW.DE',
    label: 'AMEW.DE,  Amundi Index Solutions - Amundi MSCI World UCITS ETF',
    name: 'Amundi Index Solutions - Amundi MSCI World UCITS ETF',
    symbol: 'AMEW.DE',
  },
  {
    value: 'ASRI.DE',
    label: 'ASRI.DE,  BNP Paribas Easy € Corp Bond SRI Fossil Free',
    name: 'BNP Paribas Easy € Corp Bond SRI Fossil Free',
    symbol: 'ASRI.DE',
  },
  {
    value: 'ATD.DE',
    label: 'ATD.DE,  ATI Inc.',
    name: 'ATI Inc.',
    symbol: 'ATD.DE',
  },
  {
    value: 'AYEP.DE',
    label: 'AYEP.DE,  iShares Asia Property Yield UCITS ETF',
    name: 'iShares Asia Property Yield UCITS ETF',
    symbol: 'AYEP.DE',
  },
  {
    value: 'BBLL.DE',
    label: 'BBLL.DE,  JPM BetaBuilders US Treasury Bond 0-1 yr UCITS ETF',
    name: 'JPM BetaBuilders US Treasury Bond 0-1 yr UCITS ETF',
    symbol: 'BBLL.DE',
  },
  {
    value: 'BBUD.DE',
    label:
      'BBUD.DE,  JPMorgan ETFs (Ireland) ICAV - BetaBuilders US Equity UCITS ETF',
    name: 'JPMorgan ETFs (Ireland) ICAV - BetaBuilders US Equity UCITS ETF',
    symbol: 'BBUD.DE',
  },
  {
    value: 'BBUS.DE',
    label:
      'BBUS.DE,  JPMorgan ETFs (Ireland) ICAV - BetaBuilders US Equity UCITS ETF',
    name: 'JPMorgan ETFs (Ireland) ICAV - BetaBuilders US Equity UCITS ETF',
    symbol: 'BBUS.DE',
  },
  {
    value: 'BCFU.DE',
    label:
      'BCFU.DE,  UBS (Irl) Fund Solutions plc - Bloomberg Commodity CMCI SF UCITS ETF',
    name: 'UBS (Irl) Fund Solutions plc - Bloomberg Commodity CMCI SF UCITS ETF',
    symbol: 'BCFU.DE',
  },
  {
    value: 'BRYN.DE',
    label: 'BRYN.DE,  Berkshire Hathaway Inc.',
    name: 'Berkshire Hathaway Inc.',
    symbol: 'BRYN.DE',
  },
  {
    value: 'C004.DE',
    label: 'C004.DE,  Lyxor ShortDAX Daily (-1x) Inverse UCITS ETF',
    name: 'Lyxor ShortDAX Daily (-1x) Inverse UCITS ETF',
    symbol: 'C004.DE',
  },
  {
    value: 'C006.DE',
    label: 'C006.DE,  Lyxor F.A.Z. 100 Index (DR) UCITS ETF',
    name: 'Lyxor F.A.Z. 100 Index (DR) UCITS ETF',
    symbol: 'C006.DE',
  },
  {
    value: 'C0M.DE',
    label: 'C0M.DE,  Compleo Charging Solutions AG',
    name: 'Compleo Charging Solutions AG',
    symbol: 'C0M.DE',
  },
  {
    value: 'CASH.DE',
    label: 'CASH.DE,  L&GE Fund MSCI China A UCITS ETF',
    name: 'L&GE Fund MSCI China A UCITS ETF',
    symbol: 'CASH.DE',
  },
  {
    value: 'CB3G.DE',
    label:
      'CB3G.DE,  Amundi Index Solutions - Amundi Govt Bond Euro Broad Investment Grade',
    name: 'Amundi Index Solutions - Amundi Govt Bond Euro Broad Investment Grade',
    symbol: 'CB3G.DE',
  },
  {
    value: 'CE8G.DE',
    label: 'CE8G.DE,  Amundi Index Solutions - Amundi MSCI World Ex Europe',
    name: 'Amundi Index Solutions - Amundi MSCI World Ex Europe',
    symbol: 'CE8G.DE',
  },
  {
    value: 'CEMG.DE',
    label:
      'CEMG.DE,  iShares V PLC - iShares MSCI EM Consumer Growth UCITS ETF USD (Acc)',
    name: 'iShares V PLC - iShares MSCI EM Consumer Growth UCITS ETF USD (Acc)',
    symbol: 'CEMG.DE',
  },
  {
    value: 'CEMQ.DE',
    label: 'CEMQ.DE,  iShares Edge MSCI Europe Quality Factor UCITS ETF',
    name: 'iShares Edge MSCI Europe Quality Factor UCITS ETF',
    symbol: 'CEMQ.DE',
  },
  {
    value: 'CEMR.DE',
    label: 'CEMR.DE,  iShares Edge MSCI Europe Momentum Factor UCITS ETF',
    name: 'iShares Edge MSCI Europe Momentum Factor UCITS ETF',
    symbol: 'CEMR.DE',
  },
  {
    value: 'CEMT.DE',
    label: 'CEMT.DE,  iShares Edge MSCI Europe Size Factor UCITS ETF',
    name: 'iShares Edge MSCI Europe Size Factor UCITS ETF',
    symbol: 'CEMT.DE',
  },
  {
    value: 'CEUG.DE',
    label:
      'CEUG.DE,  Amundi Index Solutions - Amundi MSCI Europe ESG Broad CTB',
    name: 'Amundi Index Solutions - Amundi MSCI Europe ESG Broad CTB',
    symbol: 'CEUG.DE',
  },
  {
    value: 'CN1G.DE',
    label: 'CN1G.DE,  Amundi Index Solutions - Amundi MSCI Nordic',
    name: 'Amundi Index Solutions - Amundi MSCI Nordic',
    symbol: 'CN1G.DE',
  },
  {
    value: 'CNAA.DE',
    label: 'CNAA.DE,  Lyxor MSCI China A (DR) UCITS ETF',
    name: 'Lyxor MSCI China A (DR) UCITS ETF',
    symbol: 'CNAA.DE',
  },
  {
    value: 'CNUA.DE',
    label: 'CNUA.DE,  UBS (Irl) Fund Solutions plc - MSCI China A SF UCITS ETF',
    name: 'UBS (Irl) Fund Solutions plc - MSCI China A SF UCITS ETF',
    symbol: 'CNUA.DE',
  },
  {
    value: 'CSTA.DE',
    label:
      'CSTA.DE,  Lyxor Index Fund - Lyxor Stoxx Europe 600 Technology UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor Stoxx Europe 600 Technology UCITS ETF',
    symbol: 'CSTA.DE',
  },
  {
    value: 'CSTD.DE',
    label:
      'CSTD.DE,  Lyxor Index Fund - Lyxor STOXX Europe 600 Construction & Materials UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor STOXX Europe 600 Construction & Materials UCITS ETF',
    symbol: 'CSTD.DE',
  },
  {
    value: 'CSY1.DE',
    label:
      'CSY1.DE,  Credit Suisse Index Fund (IE) ETF ICAV - CSIF (IE) MSCI USA Blue UCITS ETF B USD',
    name: 'Credit Suisse Index Fund (IE) ETF ICAV - CSIF (IE) MSCI USA Blue UCITS ETF B USD',
    symbol: 'CSY1.DE',
  },
  {
    value: 'CSY2.DE',
    label:
      'CSY2.DE,  Credit Suisse Index Fund (IE) ETF ICAV - CSIF (IE) MSCI USA ESG Leaders Blue UCITS ETF B USD',
    name: 'Credit Suisse Index Fund (IE) ETF ICAV - CSIF (IE) MSCI USA ESG Leaders Blue UCITS ETF B USD',
    symbol: 'CSY2.DE',
  },
  {
    value: 'CSY5.DE',
    label:
      'CSY5.DE,  Credit Suisse Index Fund (IE) ETF ICAV - CSIF (IE) MSCI World ESG Leaders Blue UCITS ETF B USD',
    name: 'Credit Suisse Index Fund (IE) ETF ICAV - CSIF (IE) MSCI World ESG Leaders Blue UCITS ETF B USD',
    symbol: 'CSY5.DE',
  },
  {
    value: 'CSY7.DE',
    label:
      'CSY7.DE,  Credit Suisse Index Fund (IE) ETF ICAV - CSIF (IE) MSCI World ESG Leaders Blue UCITS ETF',
    name: 'Credit Suisse Index Fund (IE) ETF ICAV - CSIF (IE) MSCI World ESG Leaders Blue UCITS ETF',
    symbol: 'CSY7.DE',
  },
  {
    value: 'CSYZ.DE',
    label:
      'CSYZ.DE,  Credit Suisse Index Fund (IE) ETF ICAV - CSIF (IE) FTSE EPRA Nareit Developed Green Blue UCITS ETF',
    name: 'Credit Suisse Index Fund (IE) ETF ICAV - CSIF (IE) FTSE EPRA Nareit Developed Green Blue UCITS ETF',
    symbol: 'CSYZ.DE',
  },
  {
    value: 'D3V3.DE',
    label: 'D3V3.DE,  Xtrackers EUR Credit 12.5 Swap UCITS ETF',
    name: 'Xtrackers EUR Credit 12.5 Swap UCITS ETF',
    symbol: 'D3V3.DE',
  },
  {
    value: 'D5BE.DE',
    label: 'D5BE.DE,  Xtrackers II US Treasuries 1-3 UCITS ETF',
    name: 'Xtrackers II US Treasuries 1-3 UCITS ETF',
    symbol: 'D5BE.DE',
  },
  {
    value: 'D5BK.DE',
    label: 'D5BK.DE,  Xtrackers FTSE Developed Europe Real Estate UCITS ETF',
    name: 'Xtrackers FTSE Developed Europe Real Estate UCITS ETF',
    symbol: 'D5BK.DE',
  },
  {
    value: 'DBX2.DE',
    label: 'DBX2.DE,  Xtrackers MSCI EM Asia Swap UCITS ETF 1C',
    name: 'Xtrackers MSCI EM Asia Swap UCITS ETF 1C',
    symbol: 'DBX2.DE',
  },
  {
    value: 'DBXB.DE',
    label: 'DBXB.DE,  Xtrackers II Eurozone Government Bond 7-10 UCITS ETF',
    name: 'Xtrackers II Eurozone Government Bond 7-10 UCITS ETF',
    symbol: 'DBXB.DE',
  },
  {
    value: 'DDOC.DE',
    label: 'DDOC.DE,  Global X Telemedicine & Digital Health UCITS ETF',
    name: 'Global X Telemedicine & Digital Health UCITS ETF',
    symbol: 'DDOC.DE',
  },
  {
    value: 'DE5A.DE',
    label:
      'DE5A.DE,  Amundi Index Solutions - Amundi Govt Bond Highest Rated Euro Investment Grade',
    name: 'Amundi Index Solutions - Amundi Govt Bond Highest Rated Euro Investment Grade',
    symbol: 'DE5A.DE',
  },
  {
    value: 'DELF.DE',
    label: 'DELF.DE,  L&G Europe Equity (Responsible Exclusions) UCITS ETF',
    name: 'L&G Europe Equity (Responsible Exclusions) UCITS ETF',
    symbol: 'DELF.DE',
  },
  {
    value: 'DELG.DE',
    label: 'DELG.DE,  L&G US Equity (Responsible Exclusions) UCITS ETF',
    name: 'L&G US Equity (Responsible Exclusions) UCITS ETF',
    symbol: 'DELG.DE',
  },
  {
    value: 'DFOA.DE',
    label:
      'DFOA.DE,  Lyxor Index Fund - Lyxor STOXX Europe 600 Automobiles & Parts UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor STOXX Europe 600 Automobiles & Parts UCITS ETF',
    symbol: 'DFOA.DE',
  },
  {
    value: 'DFOB.DE',
    label: 'DFOB.DE,  Lyxor Euro Government Bond 25+Y (DR) ETF',
    name: 'Lyxor Euro Government Bond 25+Y (DR) ETF',
    symbol: 'DFOB.DE',
  },
  {
    value: 'DFOP.DE',
    label:
      'DFOP.DE,  Lyxor Index Fund - Lyxor Stoxx Europe 600 Food & Beverage UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor Stoxx Europe 600 Food & Beverage UCITS ETF',
    symbol: 'DFOP.DE',
  },
  {
    value: 'DJAD.DE',
    label: 'DJAD.DE,  Lyxor US Treasury 10+Y (DR) UCITS ETF Dist',
    name: 'Lyxor US Treasury 10+Y (DR) UCITS ETF Dist',
    symbol: 'DJAD.DE',
  },
  {
    value: 'DX2K.DE',
    label: 'DX2K.DE,  Xtrackers FTSE 100 Short Daily Swap UCITS ETF',
    name: 'Xtrackers FTSE 100 Short Daily Swap UCITS ETF',
    symbol: 'DX2K.DE',
  },
  {
    value: 'DXS6.DE',
    label: 'DXS6.DE,  Xtrackers MSCI Pacific ex Japan ESG Screened UCITS ETF',
    name: 'Xtrackers MSCI Pacific ex Japan ESG Screened UCITS ETF',
    symbol: 'DXS6.DE',
  },
  {
    value: 'E15G.DE',
    label:
      'E15G.DE,  MULTI-UNITS LUXEMBOURG - Lyxor Euro Government Bond 15+Y (DR) UCITS ETF',
    name: 'MULTI-UNITS LUXEMBOURG - Lyxor Euro Government Bond 15+Y (DR) UCITS ETF',
    symbol: 'E15G.DE',
  },
  {
    value: 'E15H.DE',
    label:
      'E15H.DE,  Lyxor Core Euro Government Inflation-Linked Bond (DR) UCITS ETF',
    name: 'Lyxor Core Euro Government Inflation-Linked Bond (DR) UCITS ETF',
    symbol: 'E15H.DE',
  },
  {
    value: 'ECBI.DE',
    label:
      'ECBI.DE,  SI UCITS ETF - UC MSCI European Green Bond EUR UCITS ETF Acc EUR',
    name: 'SI UCITS ETF - UC MSCI European Green Bond EUR UCITS ETF Acc EUR',
    symbol: 'ECBI.DE',
  },
  {
    value: 'EDM2.DE',
    label: 'EDM2.DE,  iShares MSCI EM ESG Enhanced UCITS ETF USD Acc',
    name: 'iShares MSCI EM ESG Enhanced UCITS ETF USD Acc',
    symbol: 'EDM2.DE',
  },
  {
    value: 'EDMU.DE',
    label: 'EDMU.DE,  iShares MSCI USA ESG Enhanced UCITS ETF',
    name: 'iShares MSCI USA ESG Enhanced UCITS ETF',
    symbol: 'EDMU.DE',
  },
  {
    value: 'EGV1.DE',
    label:
      'EGV1.DE,  Lyxor Index Fund - Lyxor STOXX Europe 600 Insurance UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor STOXX Europe 600 Insurance UCITS ETF',
    symbol: 'EGV1.DE',
  },
  {
    value: 'EGV2.DE',
    label: 'EGV2.DE,  Lyxor Smart Overnight Return',
    name: 'Lyxor Smart Overnight Return',
    symbol: 'EGV2.DE',
  },
  {
    value: 'EGV3.DE',
    label:
      'EGV3.DE,  MULTI-UNITS LUXEMBOURG - Lyxor Euro Government Bond 1-3Y (DR) UCITS ETF',
    name: 'MULTI-UNITS LUXEMBOURG - Lyxor Euro Government Bond 1-3Y (DR) UCITS ETF',
    symbol: 'EGV3.DE',
  },
  {
    value: 'EGV5.DE',
    label:
      'EGV5.DE,  MULTI-UNITS LUXEMBOURG - Lyxor Euro Government Bond 3-5Y (DR) UCITS ETF',
    name: 'MULTI-UNITS LUXEMBOURG - Lyxor Euro Government Bond 3-5Y (DR) UCITS ETF',
    symbol: 'EGV5.DE',
  },
  {
    value: 'EHF1.DE',
    label:
      'EHF1.DE,  Amundi Index Solutions - Amundi MSCI Europe High Dividend Factor',
    name: 'Amundi Index Solutions - Amundi MSCI Europe High Dividend Factor',
    symbol: 'EHF1.DE',
  },
  {
    value: 'EHLT.DE',
    label:
      'EHLT.DE,  Lyxor Index Fund - Lyxor Stoxx Europe 600 Healthcare UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor Stoxx Europe 600 Healthcare UCITS ETF',
    symbol: 'EHLT.DE',
  },
  {
    value: 'EJAH.DE',
    label: 'EJAH.DE,  BNPP E MSCI Japan ESG Filtered Min TE',
    name: 'BNPP E MSCI Japan ESG Filtered Min TE',
    symbol: 'EJAH.DE',
  },
  {
    value: 'ELFE.DE',
    label: 'ELFE.DE,  Deka US Treasury 7-10 UCITS ETF',
    name: 'Deka US Treasury 7-10 UCITS ETF',
    symbol: 'ELFE.DE',
  },
  {
    value: 'ELFF.DE',
    label: 'ELFF.DE,  Deka Euro Corporates 0-3 Liquid UCITS ETF',
    name: 'Deka Euro Corporates 0-3 Liquid UCITS ETF',
    symbol: 'ELFF.DE',
  },
  {
    value: 'ELFG.DE',
    label: 'ELFG.DE,  Deka Germany 30 UCITS ETF',
    name: 'Deka Germany 30 UCITS ETF',
    symbol: 'ELFG.DE',
  },
  {
    value: 'EMEC.DE',
    label: 'EMEC.DE,  BNP Paribas Easy ECPI Circular Economy Leaders',
    name: 'BNP Paribas Easy ECPI Circular Economy Leaders',
    symbol: 'EMEC.DE',
  },
  {
    value: 'EMNJ.DE',
    label: 'EMNJ.DE,  iShares MSCI Japan ESG Enhanced UCITS ETF',
    name: 'iShares MSCI Japan ESG Enhanced UCITS ETF',
    symbol: 'EMNJ.DE',
  },
  {
    value: 'EMUS.DE',
    label: 'EMUS.DE,  BNP Paribas Easy MSCI EMU SRI S-Series PAB 5% Capped',
    name: 'BNP Paribas Easy MSCI EMU SRI S-Series PAB 5% Capped',
    symbol: 'EMUS.DE',
  },
  {
    value: 'ERCG.DE',
    label: 'ERCG.DE,  Telefonaktiebolaget LM Ericsson (publ)',
    name: 'Telefonaktiebolaget LM Ericsson (publ)',
    symbol: 'ERCG.DE',
  },
  {
    value: 'ESEA.DE',
    label: 'ESEA.DE,  BNP Paribas Easy S&P 500 UCITS ETF',
    name: 'BNP Paribas Easy S&P 500 UCITS ETF',
    symbol: 'ESEA.DE',
  },
  {
    value: 'ESGU.DE',
    label: 'ESGU.DE,  Invesco MSCI USA ESG Universal Screened UCITS ETF',
    name: 'Invesco MSCI USA ESG Universal Screened UCITS ETF',
    symbol: 'ESGU.DE',
  },
  {
    value: 'ESIF.DE',
    label: 'ESIF.DE,  iShares MSCI Europe Financials Sector UCITS ETF',
    name: 'iShares MSCI Europe Financials Sector UCITS ETF',
    symbol: 'ESIF.DE',
  },
  {
    value: 'ESIH.DE',
    label: 'ESIH.DE,  iShares MSCI Europe Health Care Sector UCITS ETF',
    name: 'iShares MSCI Europe Health Care Sector UCITS ETF',
    symbol: 'ESIH.DE',
  },
  {
    value: 'ESIN.DE',
    label: 'ESIN.DE,  iShares MSCI Europe Industrials Sector UCITS ETF',
    name: 'iShares MSCI Europe Industrials Sector UCITS ETF',
    symbol: 'ESIN.DE',
  },
  {
    value: 'ESIS.DE',
    label: 'ESIS.DE,  iShares MSCI Europe Consumer Staples Sector UCITS ETF',
    name: 'iShares MSCI Europe Consumer Staples Sector UCITS ETF',
    symbol: 'ESIS.DE',
  },
  {
    value: 'ESIT.DE',
    label:
      'ESIT.DE,  iShares MSCI Europe Information Technology Sector UCITS ETF',
    name: 'iShares MSCI Europe Information Technology Sector UCITS ETF',
    symbol: 'ESIT.DE',
  },
  {
    value: 'ESP0.DE',
    label: 'ESP0.DE,  VanEck Video Gaming and eSports UCITS ETF',
    name: 'VanEck Video Gaming and eSports UCITS ETF',
    symbol: 'ESP0.DE',
  },
  {
    value: 'ETSA.DE',
    label: 'ETSA.DE,  BNP Paribas Easy Stoxx Europe 600 UCITS ETF EUR C/D',
    name: 'BNP Paribas Easy Stoxx Europe 600 UCITS ETF EUR C/D',
    symbol: 'ETSA.DE',
  },
  {
    value: 'EUN0.DE',
    label: 'EUN0.DE,  iShares Edge MSCI Europe Minimum Volatility UCITS ETF',
    name: 'iShares Edge MSCI Europe Minimum Volatility UCITS ETF',
    symbol: 'EUN0.DE',
  },
  {
    value: 'EUNL.DE',
    label: 'EUNL.DE,  iShares Core MSCI World UCITS ETF USD (Acc)',
    name: 'iShares Core MSCI World UCITS ETF USD (Acc)',
    symbol: 'EUNL.DE',
  },
  {
    value: 'EUNM.DE',
    label: 'EUNM.DE,  iShares MSCI EM UCITS ETF USD (Acc)',
    name: 'iShares MSCI EM UCITS ETF USD (Acc)',
    symbol: 'EUNM.DE',
  },
  {
    value: 'EUNN.DE',
    label: 'EUNN.DE,  iShares Core MSCI Japan IMI UCITS ETF USD (Acc)',
    name: 'iShares Core MSCI Japan IMI UCITS ETF USD (Acc)',
    symbol: 'EUNN.DE',
  },
  {
    value: 'EUNT.DE',
    label: 'EUNT.DE,  iShares € Corp Bond 1-5yr UCITS ETF EUR (Dist)',
    name: 'iShares € Corp Bond 1-5yr UCITS ETF EUR (Dist)',
    symbol: 'EUNT.DE',
  },
  {
    value: 'EUNX.DE',
    label: 'EUNX.DE,  iShares US Aggregate Bond UCITS ETF USD (Dist)',
    name: 'iShares US Aggregate Bond UCITS ETF USD (Dist)',
    symbol: 'EUNX.DE',
  },
  {
    value: 'EUNY.DE',
    label: 'EUNY.DE,  iShares V PLC - iShares EM Dividend UCITS ETF USD (Dist)',
    name: 'iShares V PLC - iShares EM Dividend UCITS ETF USD (Dist)',
    symbol: 'EUNY.DE',
  },
  {
    value: 'EUNZ.DE',
    label:
      'EUNZ.DE,  iShares Edge MSCI EM Minimum Volatility UCITS ETF USD (Acc)',
    name: 'iShares Edge MSCI EM Minimum Volatility UCITS ETF USD (Acc)',
    symbol: 'EUNZ.DE',
  },
  {
    value: 'EXH5.DE',
    label: 'EXH5.DE,  iShares STOXX Europe 600 Insurance UCITS ETF (DE)',
    name: 'iShares STOXX Europe 600 Insurance UCITS ETF (DE)',
    symbol: 'EXH5.DE',
  },
  {
    value: 'EXHE.DE',
    label: 'EXHE.DE,  iShares Pfandbriefe UCITS ETF (DE)',
    name: 'iShares Pfandbriefe UCITS ETF (DE)',
    symbol: 'EXHE.DE',
  },
  {
    value: 'EXI1.DE',
    label: 'EXI1.DE,  iShares SLI UCITS ETF (DE)',
    name: 'iShares SLI UCITS ETF (DE)',
    symbol: 'EXI1.DE',
  },
  {
    value: 'EXI5.DE',
    label: 'EXI5.DE,  iShares STOXX Europe 600 Real Estate UCITS ETF (DE)',
    name: 'iShares STOXX Europe 600 Real Estate UCITS ETF (DE)',
    symbol: 'EXI5.DE',
  },
  {
    value: 'EXIA.DE',
    label: 'EXIA.DE,  iShares DAX ESG UCITS ETF (DE)',
    name: 'iShares DAX ESG UCITS ETF (DE)',
    symbol: 'EXIA.DE',
  },
  {
    value: 'G1CE.DE',
    label:
      'G1CE.DE,  Invesco Markets II plc - Invesco Global Clean Energy UCITS ETF',
    name: 'Invesco Markets II plc - Invesco Global Clean Energy UCITS ETF',
    symbol: 'G1CE.DE',
  },
  {
    value: 'GACA.DE',
    label: 'GACA.DE,  Goldman Sachs ActiveBeta US Large Cap Equity UCITS ETF',
    name: 'Goldman Sachs ActiveBeta US Large Cap Equity UCITS ETF',
    symbol: 'GACA.DE',
  },
  {
    value: 'GACB.DE',
    label:
      'GACB.DE,  Goldman Sachs ActiveBeta Emerging Market Equity UCITS ETF',
    name: 'Goldman Sachs ActiveBeta Emerging Market Equity UCITS ETF',
    symbol: 'GACB.DE',
  },
  {
    value: 'GASF.DE',
    label: 'GASF.DE,  Goldman Sachs Access China Government Bond UCITS ETF',
    name: 'Goldman Sachs Access China Government Bond UCITS ETF',
    symbol: 'GASF.DE',
  },
  {
    value: 'GLUX.DE',
    label: 'GLUX.DE,  Amundi Index Solutions - Amundi S&P Global Luxury',
    name: 'Amundi Index Solutions - Amundi S&P Global Luxury',
    symbol: 'GLUX.DE',
  },
  {
    value: 'GNAR.DE',
    label:
      'GNAR.DE,  Amundi Index Solutions - Amundi MSCI USA SRI UCITS ETF DR',
    name: 'Amundi Index Solutions - Amundi MSCI USA SRI UCITS ETF DR',
    symbol: 'GNAR.DE',
  },
  {
    value: 'GOAI.DE',
    label: 'GOAI.DE,  Amundi Stoxx Global Artificial Intelligence',
    name: 'Amundi Stoxx Global Artificial Intelligence',
    symbol: 'GOAI.DE',
  },
  {
    value: 'GSDE.DE',
    label: 'GSDE.DE,  BNP Paribas Easy Energy & Metals Enhanced Roll',
    name: 'BNP Paribas Easy Energy & Metals Enhanced Roll',
    symbol: 'GSDE.DE',
  },
  {
    value: 'GWI2.DE',
    label: 'GWI2.DE,  GERRY WEBER International AG',
    name: 'GERRY WEBER International AG',
    symbol: 'GWI2.DE',
  },
  {
    value: 'H410.DE',
    label: 'H410.DE,  HSBC MSCI Emerging Markets UCITS ETF',
    name: 'HSBC MSCI Emerging Markets UCITS ETF',
    symbol: 'H410.DE',
  },
  {
    value: 'H41K.DE',
    label: 'H41K.DE,  HSBC MSCI CHINA A UCITS ETF',
    name: 'HSBC MSCI CHINA A UCITS ETF',
    symbol: 'H41K.DE',
  },
  {
    value: 'H4ZC.DE',
    label: 'H4ZC.DE,  HSBC MSCI JAPAN UCITS ETF',
    name: 'HSBC MSCI JAPAN UCITS ETF',
    symbol: 'H4ZC.DE',
  },
  {
    value: 'H4ZE.DE',
    label: 'H4ZE.DE,  HSBC MSCI Europe UCITS ETF',
    name: 'HSBC MSCI Europe UCITS ETF',
    symbol: 'H4ZE.DE',
  },
  {
    value: 'H4ZV.DE',
    label: 'H4ZV.DE,  HSBC MSCI Malaysia UCITS ETF',
    name: 'HSBC MSCI Malaysia UCITS ETF',
    symbol: 'H4ZV.DE',
  },
  {
    value: 'HAEK.DE',
    label: 'HAEK.DE,  HAEMATO AG',
    name: 'HAEMATO AG',
    symbol: 'HAEK.DE',
  },
  {
    value: 'HGEA.DE',
    label: 'HGEA.DE,  hGears AG',
    name: 'hGears AG',
    symbol: 'HGEA.DE',
  },
  {
    value: 'HP3A.DE',
    label: 'HP3A.DE,  Ringmetall SE',
    name: 'Ringmetall SE',
    symbol: 'HP3A.DE',
  },
  {
    value: 'HTG.DE',
    label: 'HTG.DE,  HomeToGo SE',
    name: 'HomeToGo SE',
    symbol: 'HTG.DE',
  },
  {
    value: 'IBC9.DE',
    label: 'IBC9.DE,  iShares Global High Yield Corp Bond UCITS ETF',
    name: 'iShares Global High Yield Corp Bond UCITS ETF',
    symbol: 'IBC9.DE',
  },
  {
    value: 'IBCA.DE',
    label: 'IBCA.DE,  iShares € Govt Bond 1-3yr UCITS ETF EUR (Dist)',
    name: 'iShares € Govt Bond 1-3yr UCITS ETF EUR (Dist)',
    symbol: 'IBCA.DE',
  },
  {
    value: 'IBCF.DE',
    label:
      'IBCF.DE,  iShares V PLC - iShares S&P 500 EUR Hedged UCITS ETF (Acc)',
    name: 'iShares V PLC - iShares S&P 500 EUR Hedged UCITS ETF (Acc)',
    symbol: 'IBCF.DE',
  },
  {
    value: 'IBCG.DE',
    label:
      'IBCG.DE,  iShares V PLC - iShares MSCI Japan EUR Hedged UCITS ETF (Acc)',
    name: 'iShares V PLC - iShares MSCI Japan EUR Hedged UCITS ETF (Acc)',
    symbol: 'IBCG.DE',
  },
  {
    value: 'IBCH.DE',
    label:
      'IBCH.DE,  iShares V PLC - iShares MSCI World EUR Hedged UCITS ETF (Acc)',
    name: 'iShares V PLC - iShares MSCI World EUR Hedged UCITS ETF (Acc)',
    symbol: 'IBCH.DE',
  },
  {
    value: 'IBCK.DE',
    label: 'IBCK.DE,  iShares Edge S&P 500 Minimum Volatility UCITS ETF',
    name: 'iShares Edge S&P 500 Minimum Volatility UCITS ETF',
    symbol: 'IBCK.DE',
  },
  {
    value: 'IBCM.DE',
    label: 'IBCM.DE,  iShares € Govt Bond 7-10yr UCITS ETF',
    name: 'iShares € Govt Bond 7-10yr UCITS ETF',
    symbol: 'IBCM.DE',
  },
  {
    value: 'IBCQ.DE',
    label: 'IBCQ.DE,  iShares Global Corp Bond EUR Hedged UCITS ETF (Dist)',
    name: 'iShares Global Corp Bond EUR Hedged UCITS ETF (Dist)',
    symbol: 'IBCQ.DE',
  },
  {
    value: 'INDA.DE',
    label:
      'INDA.DE,  Lyxor Index Fund - Lyxor Stoxx Europe 600 Banks UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor Stoxx Europe 600 Banks UCITS ETF',
    symbol: 'INDA.DE',
  },
  {
    value: 'INDU.DE',
    label:
      'INDU.DE,  Lyxor Index Fund - Lyxor STOXX Europe 600 Industrial Goods & Services UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor STOXX Europe 600 Industrial Goods & Services UCITS ETF',
    symbol: 'INDU.DE',
  },
  {
    value: 'IQCY.DE',
    label:
      'IQCY.DE,  Lyxor Index Fund - Lyxor MSCI Smart Cities ESG Filtered (DR) UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor MSCI Smart Cities ESG Filtered (DR) UCITS ETF',
    symbol: 'IQCY.DE',
  },
  {
    value: 'IQQ0.DE',
    label:
      'IQQ0.DE,  iShares Edge MSCI World Minimum Volatility UCITS ETF USD (Acc)',
    name: 'iShares Edge MSCI World Minimum Volatility UCITS ETF USD (Acc)',
    symbol: 'IQQ0.DE',
  },
  {
    value: 'IQQ9.DE',
    label: 'IQQ9.DE,  iShares BRIC 50 UCITS ETF',
    name: 'iShares BRIC 50 UCITS ETF',
    symbol: 'IQQ9.DE',
  },
  {
    value: 'IQQA.DE',
    label: 'IQQA.DE,  iShares Euro Dividend UCITS ETF',
    name: 'iShares Euro Dividend UCITS ETF',
    symbol: 'IQQA.DE',
  },
  {
    value: 'IQQB.DE',
    label: 'IQQB.DE,  iShares MSCI Brazil UCITS ETF USD (Dist)',
    name: 'iShares MSCI Brazil UCITS ETF USD (Dist)',
    symbol: 'IQQB.DE',
  },
  {
    value: 'IQQG.DE',
    label: 'IQQG.DE,  iShares Euro Total Market Growth Large UCITS ETF',
    name: 'iShares Euro Total Market Growth Large UCITS ETF',
    symbol: 'IQQG.DE',
  },
  {
    value: 'IQQL.DE',
    label: 'IQQL.DE,  iShares Listed Private Equity UCITS ETF',
    name: 'iShares Listed Private Equity UCITS ETF',
    symbol: 'IQQL.DE',
  },
  {
    value: 'IQQP.DE',
    label: 'IQQP.DE,  iShares European Property Yield UCITS ETF',
    name: 'iShares European Property Yield UCITS ETF',
    symbol: 'IQQP.DE',
  },
  {
    value: 'IQQQ.DE',
    label: 'IQQQ.DE,  iShares Global Water UCITS ETF USD (Dist)',
    name: 'iShares Global Water UCITS ETF USD (Dist)',
    symbol: 'IQQQ.DE',
  },
  {
    value: 'IS0D.DE',
    label:
      'IS0D.DE,  iShares V PLC - iShares Oil & Gas Exploration & Production UCITS ETF USD (Acc)',
    name: 'iShares V PLC - iShares Oil & Gas Exploration & Production UCITS ETF USD (Acc)',
    symbol: 'IS0D.DE',
  },
  {
    value: 'IS0E.DE',
    label:
      'IS0E.DE,  iShares V PLC - iShares Gold Producers UCITS ETF USD (Acc)',
    name: 'iShares V PLC - iShares Gold Producers UCITS ETF USD (Acc)',
    symbol: 'IS0E.DE',
  },
  {
    value: 'IS0M.DE',
    label:
      'IS0M.DE,  iShares V PLC - iShares Italy Govt Bond UCITS ETF EUR (Dist)',
    name: 'iShares V PLC - iShares Italy Govt Bond UCITS ETF EUR (Dist)',
    symbol: 'IS0M.DE',
  },
  {
    value: 'IS0P.DE',
    label:
      'IS0P.DE,  iShares V PLC - iShares Spain Govt Bond UCITS ETF EUR (Dist)',
    name: 'iShares V PLC - iShares Spain Govt Bond UCITS ETF EUR (Dist)',
    symbol: 'IS0P.DE',
  },
  {
    value: 'IS0S.DE',
    label:
      'IS0S.DE,  iShares Emerging Asia Local Govt Bond UCITS ETF USD (Dist)',
    name: 'iShares Emerging Asia Local Govt Bond UCITS ETF USD (Dist)',
    symbol: 'IS0S.DE',
  },
  {
    value: 'IS0X.DE',
    label: 'IS0X.DE,  iShares Global Corp Bond UCITS ETF USD (Dist)',
    name: 'iShares Global Corp Bond UCITS ETF USD (Dist)',
    symbol: 'IS0X.DE',
  },
  {
    value: 'IS3C.DE',
    label:
      'IS3C.DE,  iShares J.P. Morgan $ EM Bond EUR Hedged UCITS ETF (Dist)',
    name: 'iShares J.P. Morgan $ EM Bond EUR Hedged UCITS ETF (Dist)',
    symbol: 'IS3C.DE',
  },
  {
    value: 'IS3U.DE',
    label: 'IS3U.DE,  iShares MSCI France UCITS ETF',
    name: 'iShares MSCI France UCITS ETF',
    symbol: 'IS3U.DE',
  },
  {
    value: 'IU0E.DE',
    label: 'IU0E.DE,  iShares $ Corp Bond 0-3yr ESG UCITS ETF',
    name: 'iShares $ Corp Bond 0-3yr ESG UCITS ETF',
    symbol: 'IU0E.DE',
  },
  {
    value: 'IUS6.DE',
    label: 'IUS6.DE,  iShares € Covered Bond UCITS ETF',
    name: 'iShares € Covered Bond UCITS ETF',
    symbol: 'IUS6.DE',
  },
  {
    value: 'IUS7.DE',
    label: 'IUS7.DE,  iShares J.P. Morgan $ EM Bond UCITS ETF USD (Dist)',
    name: 'iShares J.P. Morgan $ EM Bond UCITS ETF USD (Dist)',
    symbol: 'IUS7.DE',
  },
  {
    value: 'IUSA.DE',
    label: 'IUSA.DE,  iShares Core S&P 500 UCITS ETF USD Dist',
    name: 'iShares Core S&P 500 UCITS ETF USD Dist',
    symbol: 'IUSA.DE',
  },
  {
    value: 'IUSK.DE',
    label: 'IUSK.DE,  iShares MSCI Europe SRI UCITS ETF EUR (Acc)',
    name: 'iShares MSCI Europe SRI UCITS ETF EUR (Acc)',
    symbol: 'IUSK.DE',
  },
  {
    value: 'IUSP.DE',
    label: 'IUSP.DE,  iShares J.P. Morgan EM Local Govt Bond UCITS ETF',
    name: 'iShares J.P. Morgan EM Local Govt Bond UCITS ETF',
    symbol: 'IUSP.DE',
  },
  {
    value: 'IUSQ.DE',
    label: 'IUSQ.DE,  iShares V PLC - iShares MSCI ACWI UCITS ETF USD (Acc)',
    name: 'iShares V PLC - iShares MSCI ACWI UCITS ETF USD (Acc)',
    symbol: 'IUSQ.DE',
  },
  {
    value: 'JNHA.DE',
    label:
      'JNHA.DE,  Lyxor Index Fund - Lyxor STOXX Europe 600 Retail UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor STOXX Europe 600 Retail UCITS ETF',
    symbol: 'JNHA.DE',
  },
  {
    value: 'JNHD.DE',
    label: 'JNHD.DE,  Lyxor Core MSCI Japan (DR) UCITS ETF',
    name: 'Lyxor Core MSCI Japan (DR) UCITS ETF',
    symbol: 'JNHD.DE',
  },
  {
    value: 'JPGL.DE',
    label: 'JPGL.DE,  JPM Global Equity Multi-Factor UCITS ETF',
    name: 'JPM Global Equity Multi-Factor UCITS ETF',
    symbol: 'JPGL.DE',
  },
  {
    value: 'JPNE.DE',
    label: 'JPNE.DE,  Lyxor MSCI Japan ESG Leaders Extra (DR) UCITS ETF',
    name: 'Lyxor MSCI Japan ESG Leaders Extra (DR) UCITS ETF',
    symbol: 'JPNE.DE',
  },
  {
    value: 'JPPA.DE',
    label:
      'JPPA.DE,  JPMorgan ETFs (Ireland) ICAV - USD Ultra-Short Income UCITS ETF',
    name: 'JPMorgan ETFs (Ireland) ICAV - USD Ultra-Short Income UCITS ETF',
    symbol: 'JPPA.DE',
  },
  {
    value: 'JRUD.DE',
    label:
      'JRUD.DE,  JPMorgan ETFs (Ireland) ICAV - US Research Enhanced Index Equity (ESG) UCITS ETF',
    name: 'JPMorgan ETFs (Ireland) ICAV - US Research Enhanced Index Equity (ESG) UCITS ETF',
    symbol: 'JRUD.DE',
  },
  {
    value: 'JUMF.DE',
    label: 'JUMF.DE,  JPM US Equity Multi-Factor UCITS ETF',
    name: 'JPM US Equity Multi-Factor UCITS ETF',
    symbol: 'JUMF.DE',
  },
  {
    value: 'KLMT.DE',
    label: 'KLMT.DE,  Lyxor Green Bond (DR) UCITS ETF',
    name: 'Lyxor Green Bond (DR) UCITS ETF',
    symbol: 'KLMT.DE',
  },
  {
    value: 'KTA.DE',
    label: 'KTA.DE,  Knaus Tabbert AG',
    name: 'Knaus Tabbert AG',
    symbol: 'KTA.DE',
  },
  {
    value: 'KX1G.DE',
    label:
      'KX1G.DE,  Amundi Index Solutions - Amundi Govt Bond Lowest Rated Euromts Investment Grade',
    name: 'Amundi Index Solutions - Amundi Govt Bond Lowest Rated Euromts Investment Grade',
    symbol: 'KX1G.DE',
  },
  {
    value: 'L4K3.DE',
    label: 'L4K3.DE,  Lyxor MSCI China UCITS ETF',
    name: 'Lyxor MSCI China UCITS ETF',
    symbol: 'L4K3.DE',
  },
  {
    value: 'LASI.DE',
    label:
      'LASI.DE,  MULTI-UNITS LUXEMBOURG - Lyxor MSCI AC Asia Ex Japan UCITS ETF',
    name: 'MULTI-UNITS LUXEMBOURG - Lyxor MSCI AC Asia Ex Japan UCITS ETF',
    symbol: 'LASI.DE',
  },
  {
    value: 'LASP.DE',
    label:
      'LASP.DE,  MULTI-UNITS LUXEMBOURG - Lyxor MSCI AC Asia Pacific Ex Japan UCITS ETF',
    name: 'MULTI-UNITS LUXEMBOURG - Lyxor MSCI AC Asia Pacific Ex Japan UCITS ETF',
    symbol: 'LASP.DE',
  },
  {
    value: 'LAUT.DE',
    label:
      'LAUT.DE,  Lyxor Index Fund - Lyxor STOXX Europe 600 Automobiles & Parts UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor STOXX Europe 600 Automobiles & Parts UCITS ETF',
    symbol: 'LAUT.DE',
  },
  {
    value: 'LBNK.DE',
    label:
      'LBNK.DE,  Lyxor Index Fund - Lyxor Stoxx Europe 600 Banks UCITS ETF Acc',
    name: 'Lyxor Index Fund - Lyxor Stoxx Europe 600 Banks UCITS ETF Acc',
    symbol: 'LBNK.DE',
  },
  {
    value: 'LBRA.DE',
    label: 'LBRA.DE,  Lyxor MSCI Brazil UCITS ETF',
    name: 'Lyxor MSCI Brazil UCITS ETF',
    symbol: 'LBRA.DE',
  },
  {
    value: 'LCHI.DE',
    label: 'LCHI.DE,  Lyxor MSCI China ESG Leaders Extra (DR) UCITS ETF',
    name: 'Lyxor MSCI China ESG Leaders Extra (DR) UCITS ETF',
    symbol: 'LCHI.DE',
  },
  {
    value: 'LCHM.DE',
    label:
      'LCHM.DE,  Lyxor Index Fund - Lyxor STOXX Europe 600 Chemicals UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor STOXX Europe 600 Chemicals UCITS ETF',
    symbol: 'LCHM.DE',
  },
  {
    value: 'LCTR.DE',
    label:
      'LCTR.DE,  MULTI-UNITS LUXEMBOURG - Lyxor Commodities Refinitiv/CoreCommodity CRB EX-Energy TR UCITS ETF',
    name: 'MULTI-UNITS LUXEMBOURG - Lyxor Commodities Refinitiv/CoreCommodity CRB EX-Energy TR UCITS ETF',
    symbol: 'LCTR.DE',
  },
  {
    value: 'LEER.DE',
    label: 'LEER.DE,  Lyxor MSCI Eastern Europe ex Russia UCITS ETF Acc',
    name: 'Lyxor MSCI Eastern Europe ex Russia UCITS ETF Acc',
    symbol: 'LEER.DE',
  },
  {
    value: 'LERN.DE',
    label: 'LERN.DE,  Rize Education Tech and Digital Learning UCITS ETF',
    name: 'Rize Education Tech and Digital Learning UCITS ETF',
    symbol: 'LERN.DE',
  },
  {
    value: 'LGQG.DE',
    label: 'LGQG.DE,  Lyxor MSCI EMU ESG (DR) - UCITS ETF',
    name: 'Lyxor MSCI EMU ESG (DR) - UCITS ETF',
    symbol: 'LGQG.DE',
  },
  {
    value: 'LGQK.DE',
    label: 'LGQK.DE,  Lyxor MSCI Pacific Ex Japan UCITS ETF',
    name: 'Lyxor MSCI Pacific Ex Japan UCITS ETF',
    symbol: 'LGQK.DE',
  },
  {
    value: 'LHKG.DE',
    label: 'LHKG.DE,  MULTI-UNITS LUXEMBOURG - Lyxor Hong Kong (HSI) UCITS ETF',
    name: 'MULTI-UNITS LUXEMBOURG - Lyxor Hong Kong (HSI) UCITS ETF',
    symbol: 'LHKG.DE',
  },
  {
    value: 'LHTC.DE',
    label:
      'LHTC.DE,  Lyxor Index Fund - Lyxor Stoxx Europe 600 Healthcare UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor Stoxx Europe 600 Healthcare UCITS ETF',
    symbol: 'LHTC.DE',
  },
  {
    value: 'LIGS.DE',
    label:
      'LIGS.DE,  Lyxor Index Fund - Lyxor STOXX Europe 600 Industrial Goods & Services UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor STOXX Europe 600 Industrial Goods & Services UCITS ETF',
    symbol: 'LIGS.DE',
  },
  {
    value: 'LKOR.DE',
    label: 'LKOR.DE,  MULTI-UNITS LUXEMBOURG - Lyxor MSCI Korea UCITS ETF',
    name: 'MULTI-UNITS LUXEMBOURG - Lyxor MSCI Korea UCITS ETF',
    symbol: 'LKOR.DE',
  },
  {
    value: 'LLAM.DE',
    label: 'LLAM.DE,  Lyxor MSCI EM Latin America UCITS ETF',
    name: 'Lyxor MSCI EM Latin America UCITS ETF',
    symbol: 'LLAM.DE',
  },
  {
    value: 'LPHG.DE',
    label:
      'LPHG.DE,  Lyxor Index Fund - Lyxor STOXX Europe 600 Personal & Household Goods UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor STOXX Europe 600 Personal & Household Goods UCITS ETF',
    symbol: 'LPHG.DE',
  },
  {
    value: 'LRET.DE',
    label:
      'LRET.DE,  Lyxor Index Fund - Lyxor STOXX Europe 600 Retail UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor STOXX Europe 600 Retail UCITS ETF',
    symbol: 'LRET.DE',
  },
  {
    value: 'LTCM.DE',
    label:
      'LTCM.DE,  Lyxor Index Fund - Lyxor Stoxx Europe 600 Telecommunications UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor Stoxx Europe 600 Telecommunications UCITS ETF',
    symbol: 'LTCM.DE',
  },
  {
    value: 'LTUR.DE',
    label: 'LTUR.DE,  Lyxor MSCI Turkey UCITS ETF',
    name: 'Lyxor MSCI Turkey UCITS ETF',
    symbol: 'LTUR.DE',
  },
  {
    value: 'LTVL.DE',
    label:
      'LTVL.DE,  Lyxor Index Fund - Lyxor STOXX Europe 600 Travel & Leisure UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor STOXX Europe 600 Travel & Leisure UCITS ETF',
    symbol: 'LTVL.DE',
  },
  {
    value: 'LYMS.DE',
    label: 'LYMS.DE,  Lyxor Nasdaq-100 Ucits ETF',
    name: 'Lyxor Nasdaq-100 Ucits ETF',
    symbol: 'LYMS.DE',
  },
  {
    value: 'LYP2.DE',
    label: 'LYP2.DE,  Lyxor S&P 500 UCITS ETF - Daily Hedged D-EUR',
    name: 'Lyxor S&P 500 UCITS ETF - Daily Hedged D-EUR',
    symbol: 'LYP2.DE',
  },
  {
    value: 'LYQ2.DE',
    label:
      'LYQ2.DE,  MULTI-UNITS LUXEMBOURG - Lyxor Euro Government Bond 1-3Y (DR) UCITS ETF',
    name: 'MULTI-UNITS LUXEMBOURG - Lyxor Euro Government Bond 1-3Y (DR) UCITS ETF',
    symbol: 'LYQ2.DE',
  },
  {
    value: 'LYQ3.DE',
    label:
      'LYQ3.DE,  MULTI-UNITS LUXEMBOURG - Lyxor Euro Government Bond 3-5Y (DR) UCITS ETF',
    name: 'MULTI-UNITS LUXEMBOURG - Lyxor Euro Government Bond 3-5Y (DR) UCITS ETF',
    symbol: 'LYQ3.DE',
  },
  {
    value: 'LYQS.DE',
    label:
      'LYQS.DE,  Lyxor Index Fund - Lyxor iBoxx $ Liquid Emerging Markets Sovereign UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor iBoxx $ Liquid Emerging Markets Sovereign UCITS ETF',
    symbol: 'LYQS.DE',
  },
  {
    value: 'LYS4.DE',
    label:
      'LYS4.DE,  Lyxor EuroMTS Highest Rated Macro-Weighted Govt Bond 1-3Y (DR) UCITS ETF',
    name: 'Lyxor EuroMTS Highest Rated Macro-Weighted Govt Bond 1-3Y (DR) UCITS ETF',
    symbol: 'LYS4.DE',
  },
  {
    value: 'LYS5.DE',
    label:
      'LYS5.DE,  Lyxor EuroMTS Highest Rated Macro-Weighted Govt Bond 3-5Y (DR) UCITS ETF',
    name: 'Lyxor EuroMTS Highest Rated Macro-Weighted Govt Bond 3-5Y (DR) UCITS ETF',
    symbol: 'LYS5.DE',
  },
  {
    value: 'LYTR.DE',
    label:
      'LYTR.DE,  MULTI-UNITS LUXEMBOURG - Lyxor Commodities Refinitiv/CoreCommodity CRB TR UCITS ETF',
    name: 'MULTI-UNITS LUXEMBOURG - Lyxor Commodities Refinitiv/CoreCommodity CRB TR UCITS ETF',
    symbol: 'LYTR.DE',
  },
  {
    value: 'LYXI.DE',
    label: 'LYXI.DE,  Lyxor MSCI Indonesia UCITS ETF',
    name: 'Lyxor MSCI Indonesia UCITS ETF',
    symbol: 'LYXI.DE',
  },
  {
    value: 'LYY5.DE',
    label: 'LYY5.DE,  Lyxor MSCI Europe (DR) UCITS ETF',
    name: 'Lyxor MSCI Europe (DR) UCITS ETF',
    symbol: 'LYY5.DE',
  },
  {
    value: 'M9SA.DE',
    label:
      'M9SA.DE,  Market Access Rogers International Commodity Index UCITS ETF',
    name: 'Market Access Rogers International Commodity Index UCITS ETF',
    symbol: 'M9SA.DE',
  },
  {
    value: 'MIVB.DE',
    label:
      'MIVB.DE,  Amundi Index Solutions - Amundi MSCI Europe SRI UCITS ETF DR',
    name: 'Amundi Index Solutions - Amundi MSCI Europe SRI UCITS ETF DR',
    symbol: 'MIVB.DE',
  },
  {
    value: 'MPN.DE',
    label: 'MPN.DE,  Marathon Petroleum Corporation',
    name: 'Marathon Petroleum Corporation',
    symbol: 'MPN.DE',
  },
  {
    value: 'MTDA.DE',
    label:
      'MTDA.DE,  Lyxor Index Fund - Lyxor STOXX Europe 600 Personal & Household Goods UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor STOXX Europe 600 Personal & Household Goods UCITS ETF',
    symbol: 'MTDA.DE',
  },
  {
    value: 'MTDB.DE',
    label: 'MTDB.DE,  Lyxor Index Fund - Lyxor Euro Stoxx 50 (DR)',
    name: 'Lyxor Index Fund - Lyxor Euro Stoxx 50 (DR)',
    symbol: 'MTDB.DE',
  },
  {
    value: 'MTDD.DE',
    label:
      'MTDD.DE,  MULTI-UNITS LUXEMBOURG - Lyxor Euro Government Bond 7-10Y (DR) UCITS ETF',
    name: 'MULTI-UNITS LUXEMBOURG - Lyxor Euro Government Bond 7-10Y (DR) UCITS ETF',
    symbol: 'MTDD.DE',
  },
  {
    value: 'MXUK.DE',
    label: 'MXUK.DE,  Invesco MSCI Europe ex-UK UCITS ETF',
    name: 'Invesco MSCI Europe ex-UK UCITS ETF',
    symbol: 'MXUK.DE',
  },
  {
    value: 'NADA.DE',
    label: 'NADA.DE,  Lyxor Core MSCI Japan (DR) UCITS ETF 2',
    name: 'Lyxor Core MSCI Japan (DR) UCITS ETF 2',
    symbol: 'NADA.DE',
  },
  {
    value: 'NADB.DE',
    label:
      'NADB.DE,  MULTI-UNITS LUXEMBOURG - Lyxor Euro Government Bond 10-15Y (DR) UCITS ETF',
    name: 'MULTI-UNITS LUXEMBOURG - Lyxor Euro Government Bond 10-15Y (DR) UCITS ETF',
    symbol: 'NADB.DE',
  },
  {
    value: 'NADQ.DE',
    label: 'NADQ.DE,  Lyxor Nasdaq-100 Ucits ETF',
    name: 'Lyxor Nasdaq-100 Ucits ETF',
    symbol: 'NADQ.DE',
  },
  {
    value: 'OIGS.DE',
    label:
      'OIGS.DE,  Lyxor Index Fund - Lyxor Stoxx Europe 600 Oil & Gas UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor Stoxx Europe 600 Oil & Gas UCITS ETF',
    symbol: 'OIGS.DE',
  },
  {
    value: 'OM3L.DE',
    label: 'OM3L.DE,  iShares MSCI USA ESG Enhanced UCITS ETF',
    name: 'iShares MSCI USA ESG Enhanced UCITS ETF',
    symbol: 'OM3L.DE',
  },
  {
    value: 'OUFE.DE',
    label: 'OUFE.DE,  Ossiam US ESG Low Carbon Equity Factors UCITS ETF',
    name: 'Ossiam US ESG Low Carbon Equity Factors UCITS ETF',
    symbol: 'OUFE.DE',
  },
  {
    value: 'OWLU.DE',
    label: 'OWLU.DE,  OSSIAM World ESG Machine Learning UCITS ETF',
    name: 'OSSIAM World ESG Machine Learning UCITS ETF',
    symbol: 'OWLU.DE',
  },
  {
    value: 'PABW.DE',
    label:
      'PABW.DE,  Amundi Index Solutions - Amundi MSCI World Climate Paris Aligned Pab',
    name: 'Amundi Index Solutions - Amundi MSCI World Climate Paris Aligned Pab',
    symbol: 'PABW.DE',
  },
  {
    value: 'PR1C.DE',
    label: 'PR1C.DE,  Amundi Index Solutions - Amundi Prime Euro Corporates',
    name: 'Amundi Index Solutions - Amundi Prime Euro Corporates',
    symbol: 'PR1C.DE',
  },
  {
    value: 'PR1E.DE',
    label: 'PR1E.DE,  Amundi Index Solutions - Amundi Prime Europe',
    name: 'Amundi Index Solutions - Amundi Prime Europe',
    symbol: 'PR1E.DE',
  },
  {
    value: 'PR1G.DE',
    label: 'PR1G.DE,  Amundi Index Solutions - Amundi Prime Global Govies',
    name: 'Amundi Index Solutions - Amundi Prime Global Govies',
    symbol: 'PR1G.DE',
  },
  {
    value: 'PR1H.DE',
    label:
      'PR1H.DE,  Amundi Index Solutions - Amundi Prime US Treasury Bond 0-1 Y UCITS ETF',
    name: 'Amundi Index Solutions - Amundi Prime US Treasury Bond 0-1 Y UCITS ETF',
    symbol: 'PR1H.DE',
  },
  {
    value: 'PR1J.DE',
    label: 'PR1J.DE,  Amundi Index Solutions - Amundi Prime Japan',
    name: 'Amundi Index Solutions - Amundi Prime Japan',
    symbol: 'PR1J.DE',
  },
  {
    value: 'PR1P.DE',
    label: 'PR1P.DE,  Amundi Index Solutions - Amundi Prime US Corporates',
    name: 'Amundi Index Solutions - Amundi Prime US Corporates',
    symbol: 'PR1P.DE',
  },
  {
    value: 'PR1R.DE',
    label:
      'PR1R.DE,  Amundi Index Solutions - Amundi Prime Euro Govies UCITS ETF DR',
    name: 'Amundi Index Solutions - Amundi Prime Euro Govies UCITS ETF DR',
    symbol: 'PR1R.DE',
  },
  {
    value: 'PR1S.DE',
    label:
      'PR1S.DE,  Amundi Index Solutions - Amundi Prime US Treasury UCITS ETF DR',
    name: 'Amundi Index Solutions - Amundi Prime US Treasury UCITS ETF DR',
    symbol: 'PR1S.DE',
  },
  {
    value: 'PR1U.DE',
    label: 'PR1U.DE,  Amundi Index Solutions - Amundi Prime USA UCITS ETF DR',
    name: 'Amundi Index Solutions - Amundi Prime USA UCITS ETF DR',
    symbol: 'PR1U.DE',
  },
  {
    value: 'PR1W.DE',
    label:
      'PR1W.DE,  Amundi Index Solutions - Amundi Prime Global UCITS ETF DR',
    name: 'Amundi Index Solutions - Amundi Prime Global UCITS ETF DR',
    symbol: 'PR1W.DE',
  },
  {
    value: 'PR1Z.DE',
    label: 'PR1Z.DE,  Amundi Index Solutions - Amundi Prime Eurozone',
    name: 'Amundi Index Solutions - Amundi Prime Eurozone',
    symbol: 'PR1Z.DE',
  },
  {
    value: 'PRAC.DE',
    label: 'PRAC.DE,  Amundi Index Solutions - Amundi Prime Euro Corporates',
    name: 'Amundi Index Solutions - Amundi Prime Euro Corporates',
    symbol: 'PRAC.DE',
  },
  {
    value: 'PRAE.DE',
    label: 'PRAE.DE,  Amundi Index Solutions - Amundi Prime Europe',
    name: 'Amundi Index Solutions - Amundi Prime Europe',
    symbol: 'PRAE.DE',
  },
  {
    value: 'PRAG.DE',
    label: 'PRAG.DE,  Amundi Index Solutions - Amundi Prime Global Govies',
    name: 'Amundi Index Solutions - Amundi Prime Global Govies',
    symbol: 'PRAG.DE',
  },
  {
    value: 'PRAJ.DE',
    label: 'PRAJ.DE,  Amundi Index Solutions - Amundi Prime Japan',
    name: 'Amundi Index Solutions - Amundi Prime Japan',
    symbol: 'PRAJ.DE',
  },
  {
    value: 'PRAP.DE',
    label: 'PRAP.DE,  Amundi Index Solutions - Amundi Prime US Corporates',
    name: 'Amundi Index Solutions - Amundi Prime US Corporates',
    symbol: 'PRAP.DE',
  },
  {
    value: 'PRAR.DE',
    label: 'PRAR.DE,  Amundi Index Solutions - Amundi Prime Euro Govies',
    name: 'Amundi Index Solutions - Amundi Prime Euro Govies',
    symbol: 'PRAR.DE',
  },
  {
    value: 'PRAS.DE',
    label: 'PRAS.DE,  Amundi Index Solutions - Amundi Prime US Treasury',
    name: 'Amundi Index Solutions - Amundi Prime US Treasury',
    symbol: 'PRAS.DE',
  },
  {
    value: 'PRAU.DE',
    label: 'PRAU.DE,  Amundi Index Solutions - Amundi Prime USA',
    name: 'Amundi Index Solutions - Amundi Prime USA',
    symbol: 'PRAU.DE',
  },
  {
    value: 'PRAW.DE',
    label: 'PRAW.DE,  Amundi Index Solutions - Amundi Prime Global',
    name: 'Amundi Index Solutions - Amundi Prime Global',
    symbol: 'PRAW.DE',
  },
  {
    value: 'PRAZ.DE',
    label: 'PRAZ.DE,  Amundi Index Solutions - Amundi Prime Eurozone',
    name: 'Amundi Index Solutions - Amundi Prime Eurozone',
    symbol: 'PRAZ.DE',
  },
  {
    value: 'PSWD.DE',
    label: 'PSWD.DE,  Invesco FTSE RAFI All World 3000 UCITS ETF',
    name: 'Invesco FTSE RAFI All World 3000 UCITS ETF',
    symbol: 'PSWD.DE',
  },
  {
    value: 'PUIG.DE',
    label: 'PUIG.DE,  Invesco USD Corporate Bond ESG UCITS ETF',
    name: 'Invesco USD Corporate Bond ESG UCITS ETF',
    symbol: 'PUIG.DE',
  },
  {
    value: 'QDVL.DE',
    label: 'QDVL.DE,  iShares € Corp Bond 0-3yr ESG UCITS ETF',
    name: 'iShares € Corp Bond 0-3yr ESG UCITS ETF',
    symbol: 'QDVL.DE',
  },
  {
    value: 'RCRS.DE',
    label:
      'RCRS.DE,  Rize UCITS ICAV - Rize Cybersecurity Data Privacy UCITS ETF',
    name: 'Rize UCITS ICAV - Rize Cybersecurity Data Privacy UCITS ETF',
    symbol: 'RCRS.DE',
  },
  {
    value: 'RENW.DE',
    label: 'RENW.DE,  L&G Clean Energy UCITS ETF',
    name: 'L&G Clean Energy UCITS ETF',
    symbol: 'RENW.DE',
  },
  {
    value: 'RIZF.DE',
    label: 'RIZF.DE,  Rize Sustainable Future of Food UCITS ETF',
    name: 'Rize Sustainable Future of Food UCITS ETF',
    symbol: 'RIZF.DE',
  },
  {
    value: 'SADM.DE',
    label:
      'SADM.DE,  Amundi Index Solutions - Amundi MSCI Emerging ESG Leaders',
    name: 'Amundi Index Solutions - Amundi MSCI Emerging ESG Leaders',
    symbol: 'SADM.DE',
  },
  {
    value: 'SAV.DE',
    label: 'SAV.DE,  Savannah Resources Plc',
    name: 'Savannah Resources Plc',
    symbol: 'SAV.DE',
  },
  {
    value: 'SBX.DE',
    label: 'SBX.DE,  SynBiotic SE',
    name: 'SynBiotic SE',
    symbol: 'SBX.DE',
  },
  {
    value: 'SEAA.DE',
    label:
      'SEAA.DE,  UBS (Lux) Fund Solutions – J.P. Morgan USD EM Diversified Bond 1-5 UCITS ETF',
    name: 'UBS (Lux) Fund Solutions – J.P. Morgan USD EM Diversified Bond 1-5 UCITS ETF',
    symbol: 'SEAA.DE',
  },
  {
    value: 'SEAC.DE',
    label:
      'SEAC.DE,  UBS (Lux) Fund Solutions – MSCI World Socially Responsible UCITS ETF',
    name: 'UBS (Lux) Fund Solutions – MSCI World Socially Responsible UCITS ETF',
    symbol: 'SEAC.DE',
  },
  {
    value: 'SELD.DE',
    label:
      'SELD.DE,  Lyxor Index Fund - Lyxor STOXX Europe Select Dividend 30 UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor STOXX Europe Select Dividend 30 UCITS ETF',
    symbol: 'SELD.DE',
  },
  {
    value: 'SGF.DE',
    label: 'SGF.DE,  SGT German Private Equity GmbH & Co. KGaA',
    name: 'SGT German Private Equity GmbH & Co. KGaA',
    symbol: 'SGF.DE',
  },
  {
    value: 'SLMD.DE',
    label: 'SLMD.DE,  iShares MSCI Europe ESG Screened UCITS ETF',
    name: 'iShares MSCI Europe ESG Screened UCITS ETF',
    symbol: 'SLMD.DE',
  },
  {
    value: 'SLMG.DE',
    label: 'SLMG.DE,  iShares J.P. Morgan ESG $ EM Bond UCITS ETF',
    name: 'iShares J.P. Morgan ESG $ EM Bond UCITS ETF',
    symbol: 'SLMG.DE',
  },
  {
    value: 'SMLP.DE',
    label:
      'SMLP.DE,  Invesco Morningstar US Energy Infrastructure MLP UCITS ETF',
    name: 'Invesco Morningstar US Energy Infrastructure MLP UCITS ETF',
    symbol: 'SMLP.DE',
  },
  {
    value: 'SMLU.DE',
    label:
      'SMLU.DE,  Invesco Goldman Sachs Equity Factor Index Europe UCITS ETF',
    name: 'Invesco Goldman Sachs Equity Factor Index Europe UCITS ETF',
    symbol: 'SMLU.DE',
  },
  {
    value: 'SPFA.DE',
    label:
      'SPFA.DE,  SPDR Bloomberg Emerging Markets Local Bond USD Base CCY Hdg to EUR UCITS ETF Acc',
    name: 'SPDR Bloomberg Emerging Markets Local Bond USD Base CCY Hdg to EUR UCITS ETF Acc',
    symbol: 'SPFA.DE',
  },
  {
    value: 'SPFD.DE',
    label:
      'SPFD.DE,  SPDR Bloomberg Emerging Markets Local Bond USD Base CCY Hdg to EUR UCITS ETF Acc',
    name: 'SPDR Bloomberg Emerging Markets Local Bond USD Base CCY Hdg to EUR UCITS ETF Acc',
    symbol: 'SPFD.DE',
  },
  {
    value: 'SUSE.DE',
    label: 'SUSE.DE,  SUSE S.A.',
    name: 'SUSE S.A.',
    symbol: 'SUSE.DE',
  },
  {
    value: 'SXRL.DE',
    label: 'SXRL.DE,  iShares $ Treasury Bond 3-7yr UCITS ETF USD (Acc)',
    name: 'iShares $ Treasury Bond 3-7yr UCITS ETF USD (Acc)',
    symbol: 'SXRL.DE',
  },
  {
    value: 'SXRM.DE',
    label:
      'SXRM.DE,  iShares VII PLC - iShares $ Treasury Bond 7-10yr UCITS ETF USD (Acc)',
    name: 'iShares VII PLC - iShares $ Treasury Bond 7-10yr UCITS ETF USD (Acc)',
    symbol: 'SXRM.DE',
  },
  {
    value: 'SYBG.DE',
    label: 'SYBG.DE,  SPDR Bloomberg UK Gilt UCITS ETF',
    name: 'SPDR Bloomberg UK Gilt UCITS ETF',
    symbol: 'SYBG.DE',
  },
  {
    value: 'TCC4.DE',
    label: 'TCC4.DE,  Amundi Index Solutions - Amundi EURO Corporates {d}',
    name: 'Amundi Index Solutions - Amundi EURO Corporates {d}',
    symbol: 'TCC4.DE',
  },
  {
    value: 'TLIK.DE',
    label: 'TLIK.DE,  TELES AG Informationstechnologien',
    name: 'TELES AG Informationstechnologien',
    symbol: 'TLIK.DE',
  },
  {
    value: 'TQIR.DE',
    label: 'TQIR.DE,  Telecom Italia S.p.A.',
    name: 'Telecom Italia S.p.A.',
    symbol: 'TQIR.DE',
  },
  {
    value: 'TRD1.DE',
    label: 'TRD1.DE,  Invesco US Treasury Bond 0-1 Year UCITS ETF USD Dist',
    name: 'Invesco US Treasury Bond 0-1 Year UCITS ETF USD Dist',
    symbol: 'TRD1.DE',
  },
  {
    value: 'TRD3.DE',
    label: 'TRD3.DE,  Invesco US Treasury Bond 1-3 Year UCITS ETF',
    name: 'Invesco US Treasury Bond 1-3 Year UCITS ETF',
    symbol: 'TRD3.DE',
  },
  {
    value: 'TRD7.DE',
    label: 'TRD7.DE,  Invesco US Treasury Bond 3-7 Year UCITS ETF',
    name: 'Invesco US Treasury Bond 3-7 Year UCITS ETF',
    symbol: 'TRD7.DE',
  },
  {
    value: 'TRDS.DE',
    label: 'TRDS.DE,  Invesco US Treasury Bond UCITS ETF USD Dist',
    name: 'Invesco US Treasury Bond UCITS ETF USD Dist',
    symbol: 'TRDS.DE',
  },
  {
    value: 'TRDX.DE',
    label: 'TRDX.DE,  Invesco US Treasury Bond 7-10 Year UCITS ETF',
    name: 'Invesco US Treasury Bond 7-10 Year UCITS ETF',
    symbol: 'TRDX.DE',
  },
  {
    value: 'TTPX.DE',
    label:
      'TTPX.DE,  Amundi Index Solutions - Amundi Japan Topix UCITS ETF-C EUR Hedged',
    name: 'Amundi Index Solutions - Amundi Japan Topix UCITS ETF-C EUR Hedged',
    symbol: 'TTPX.DE',
  },
  {
    value: 'U9RA.DE',
    label: 'U9RA.DE,  Under Armour, Inc.',
    name: 'Under Armour, Inc.',
    symbol: 'U9RA.DE',
  },
  {
    value: 'UCRP.DE',
    label: 'UCRP.DE,  Amundi Index Solutions - Amundi Index US Corp SRI',
    name: 'Amundi Index Solutions - Amundi Index US Corp SRI',
    symbol: 'UCRP.DE',
  },
  {
    value: 'UCT2.DE',
    label:
      'UCT2.DE,  MULTI-UNITS LUXEMBOURG - Lyxor US Curve Steepening 2-10 UCITS ETF',
    name: 'MULTI-UNITS LUXEMBOURG - Lyxor US Curve Steepening 2-10 UCITS ETF',
    symbol: 'UCT2.DE',
  },
  {
    value: 'UETE.DE',
    label:
      'UETE.DE,  UBS (Lux) Fund Solutions – MSCI Emerging Markets Socially Responsible UCITS ETF',
    name: 'UBS (Lux) Fund Solutions – MSCI Emerging Markets Socially Responsible UCITS ETF',
    symbol: 'UETE.DE',
  },
  {
    value: 'UIC2.DE',
    label: 'UIC2.DE,  UBS ETF - Solactive China Technology UCITS ETF',
    name: 'UBS ETF - Solactive China Technology UCITS ETF',
    symbol: 'UIC2.DE',
  },
  {
    value: 'UIM7.DE',
    label: 'UIM7.DE,  UBS (Lux) Fund Solutions – MSCI World UCITS ETF',
    name: 'UBS (Lux) Fund Solutions – MSCI World UCITS ETF',
    symbol: 'UIM7.DE',
  },
  {
    value: 'USTP.DE',
    label: 'USTP.DE,  Ossiam US Steepener',
    name: 'Ossiam US Steepener',
    symbol: 'USTP.DE',
  },
  {
    value: 'V20A.DE',
    label: 'V20A.DE,  Vanguard LifeStrategy 20% Equity UCITS ETF',
    name: 'Vanguard LifeStrategy 20% Equity UCITS ETF',
    symbol: 'V20A.DE',
  },
  {
    value: 'V20D.DE',
    label: 'V20D.DE,  Vanguard LifeStrategy 20% Equity UCITS ETF',
    name: 'Vanguard LifeStrategy 20% Equity UCITS ETF',
    symbol: 'V20D.DE',
  },
  {
    value: 'V40A.DE',
    label: 'V40A.DE,  Vanguard LifeStrategy 40% Equity UCITS ETF',
    name: 'Vanguard LifeStrategy 40% Equity UCITS ETF',
    symbol: 'V40A.DE',
  },
  {
    value: 'V40D.DE',
    label: 'V40D.DE,  Vanguard LifeStrategy 40% Equity UCITS ETF',
    name: 'Vanguard LifeStrategy 40% Equity UCITS ETF',
    symbol: 'V40D.DE',
  },
  {
    value: 'V60A.DE',
    label: 'V60A.DE,  Vanguard LifeStrategy 60% Equity UCITS ETF',
    name: 'Vanguard LifeStrategy 60% Equity UCITS ETF',
    symbol: 'V60A.DE',
  },
  {
    value: 'V60D.DE',
    label: 'V60D.DE,  Vanguard LifeStrategy 60% Equity UCITS ETF',
    name: 'Vanguard LifeStrategy 60% Equity UCITS ETF',
    symbol: 'V60D.DE',
  },
  {
    value: 'V80A.DE',
    label: 'V80A.DE,  Vanguard LifeStrategy 80% Equity UCITS ETF',
    name: 'Vanguard LifeStrategy 80% Equity UCITS ETF',
    symbol: 'V80A.DE',
  },
  {
    value: 'V80D.DE',
    label: 'V80D.DE,  Vanguard LifeStrategy 80% Equity UCITS ETF',
    name: 'Vanguard LifeStrategy 80% Equity UCITS ETF',
    symbol: 'V80D.DE',
  },
  {
    value: 'VAGE.DE',
    label: 'VAGE.DE,  Vanguard Global Aggregate Bond UCITS ETF',
    name: 'Vanguard Global Aggregate Bond UCITS ETF',
    symbol: 'VAGE.DE',
  },
  {
    value: 'VAGF.DE',
    label: 'VAGF.DE,  Vanguard Global Aggregate Bond UCITS ETF',
    name: 'Vanguard Global Aggregate Bond UCITS ETF',
    symbol: 'VAGF.DE',
  },
  {
    value: 'VDCE.DE',
    label: 'VDCE.DE,  Vanguard USD Corporate Bond UCITS ETF',
    name: 'Vanguard USD Corporate Bond UCITS ETF',
    symbol: 'VDCE.DE',
  },
  {
    value: 'VDTE.DE',
    label: 'VDTE.DE,  Vanguard USD Treasury Bond UCITS ETF',
    name: 'Vanguard USD Treasury Bond UCITS ETF',
    symbol: 'VDTE.DE',
  },
  {
    value: 'VECA.DE',
    label: 'VECA.DE,  Vanguard EUR Corporate Bond UCITS ETF',
    name: 'Vanguard EUR Corporate Bond UCITS ETF',
    symbol: 'VECA.DE',
  },
  {
    value: 'VERE.DE',
    label: 'VERE.DE,  Vanguard FTSE Developed Europe ex UK UCITS ETF',
    name: 'Vanguard FTSE Developed Europe ex UK UCITS ETF',
    symbol: 'VERE.DE',
  },
  {
    value: 'VFEA.DE',
    label: 'VFEA.DE,  Vanguard FTSE Emerging Markets UCITS ETF',
    name: 'Vanguard FTSE Emerging Markets UCITS ETF',
    symbol: 'VFEA.DE',
  },
  {
    value: 'VGEA.DE',
    label: 'VGEA.DE,  Vanguard EUR Eurozone Government Bond UCITS ETF',
    name: 'Vanguard EUR Eurozone Government Bond UCITS ETF',
    symbol: 'VGEA.DE',
  },
  {
    value: 'VGEK.DE',
    label: 'VGEK.DE,  Vanguard FTSE Developed Asia Pacific ex Japan UCITS ETF',
    name: 'Vanguard FTSE Developed Asia Pacific ex Japan UCITS ETF',
    symbol: 'VGEK.DE',
  },
  {
    value: 'VGUE.DE',
    label: 'VGUE.DE,  Vanguard U.K. Gilt UCITS ETF',
    name: 'Vanguard U.K. Gilt UCITS ETF',
    symbol: 'VGUE.DE',
  },
  {
    value: 'VGVF.DE',
    label: 'VGVF.DE,  Vanguard FTSE Developed World UCITS ETF',
    name: 'Vanguard FTSE Developed World UCITS ETF',
    symbol: 'VGVF.DE',
  },
  {
    value: 'VH2.DE',
    label: 'VH2.DE,  Friedrich Vorwerk Group SE',
    name: 'Friedrich Vorwerk Group SE',
    symbol: 'VH2.DE',
  },
  {
    value: 'VJPA.DE',
    label: 'VJPA.DE,  Vanguard FTSE Japan UCITS ETF',
    name: 'Vanguard FTSE Japan UCITS ETF',
    symbol: 'VJPA.DE',
  },
  {
    value: 'VNRA.DE',
    label: 'VNRA.DE,  Vanguard FTSE North America UCITS ETF',
    name: 'Vanguard FTSE North America UCITS ETF',
    symbol: 'VNRA.DE',
  },
  {
    value: 'VOOM.DE',
    label:
      'VOOM.DE,  Lyxor Index Fund - Lyxor Global Gender Equality (DR) UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor Global Gender Equality (DR) UCITS ETF',
    symbol: 'VOOM.DE',
  },
  {
    value: 'VTSC.DE',
    label: 'VTSC.DE,  Vitesco Technologies Group Aktiengesellschaft',
    name: 'Vitesco Technologies Group Aktiengesellschaft',
    symbol: 'VTSC.DE',
  },
  {
    value: 'VUAA.DE',
    label: 'VUAA.DE,  Vanguard S&P 500 UCITS ETF',
    name: 'Vanguard S&P 500 UCITS ETF',
    symbol: 'VUAA.DE',
  },
  {
    value: 'VUCE.DE',
    label: 'VUCE.DE,  Vanguard USD Corporate Bond UCITS ETF',
    name: 'Vanguard USD Corporate Bond UCITS ETF',
    symbol: 'VUCE.DE',
  },
  {
    value: 'VVSM.DE',
    label: 'VVSM.DE,  VanEck Semiconductor UCITS ETF',
    name: 'VanEck Semiconductor UCITS ETF',
    symbol: 'VVSM.DE',
  },
  {
    value: 'VWCE.DE',
    label: 'VWCE.DE,  Vanguard FTSE All-World UCITS ETF',
    name: 'Vanguard FTSE All-World UCITS ETF',
    symbol: 'VWCE.DE',
  },
  {
    value: 'VWCG.DE',
    label: 'VWCG.DE,  Vanguard FTSE Developed Europe UCITS ETF',
    name: 'Vanguard FTSE Developed Europe UCITS ETF',
    symbol: 'VWCG.DE',
  },
  {
    value: 'VWSB.DE',
    label: 'VWSB.DE,  Vestas Wind Systems A/S',
    name: 'Vestas Wind Systems A/S',
    symbol: 'VWSB.DE',
  },
  {
    value: 'W1TA.DE',
    label: 'W1TA.DE,  WisdomTree Battery Solutions UCITS ETF',
    name: 'WisdomTree Battery Solutions UCITS ETF',
    symbol: 'W1TA.DE',
  },
  {
    value: 'W311.DE',
    label:
      'W311.DE,  HAN-GINS Indxx Healthcare Megatrend Equal Weight UCITS ETF',
    name: 'HAN-GINS Indxx Healthcare Megatrend Equal Weight UCITS ETF',
    symbol: 'W311.DE',
  },
  {
    value: 'WTDH.DE',
    label: 'WTDH.DE,  WisdomTree Europe Equity UCITS ETF',
    name: 'WisdomTree Europe Equity UCITS ETF',
    symbol: 'WTDH.DE',
  },
  {
    value: 'WTDI.DE',
    label: 'WTDI.DE,  WisdomTree AT1 CoCo Bond UCITS ETF',
    name: 'WisdomTree AT1 CoCo Bond UCITS ETF',
    symbol: 'WTDI.DE',
  },
  {
    value: 'WTDX.DE',
    label: 'WTDX.DE,  WisdomTree Japan Equity UCITS ETF',
    name: 'WisdomTree Japan Equity UCITS ETF',
    symbol: 'WTDX.DE',
  },
  {
    value: 'WTED.DE',
    label: 'WTED.DE,  WisdomTree Emerging Markets SmallCap Dividend UCITS ETF',
    name: 'WisdomTree Emerging Markets SmallCap Dividend UCITS ETF',
    symbol: 'WTED.DE',
  },
  {
    value: 'WTEE.DE',
    label: 'WTEE.DE,  WisdomTree Europe Equity Income UCITS ETF',
    name: 'WisdomTree Europe Equity Income UCITS ETF',
    symbol: 'WTEE.DE',
  },
  {
    value: 'WTES.DE',
    label: 'WTES.DE,  WisdomTree Europe SmallCap Dividend UCITS ETF',
    name: 'WisdomTree Europe SmallCap Dividend UCITS ETF',
    symbol: 'WTES.DE',
  },
  {
    value: 'WTIC.DE',
    label: 'WTIC.DE,  WisdomTree Enhanced Commodity UCITS ETF - USD Acc',
    name: 'WisdomTree Enhanced Commodity UCITS ETF - USD Acc',
    symbol: 'WTIC.DE',
  },
  {
    value: 'WTIF.DE',
    label: 'WTIF.DE,  WisdomTree Japan Equity UCITS ETF - EUR Hedged Acc',
    name: 'WisdomTree Japan Equity UCITS ETF - EUR Hedged Acc',
    symbol: 'WTIF.DE',
  },
  {
    value: 'WTIZ.DE',
    label: 'WTIZ.DE,  WisdomTree Japan Equity UCITS ETF',
    name: 'WisdomTree Japan Equity UCITS ETF',
    symbol: 'WTIZ.DE',
  },
  {
    value: 'X03F.DE',
    label: 'X03F.DE,  Xtrackers II Eurozone Government Bond UCITS ETF',
    name: 'Xtrackers II Eurozone Government Bond UCITS ETF',
    symbol: 'X03F.DE',
  },
  {
    value: 'X03G.DE',
    label: 'X03G.DE,  Xtrackers II Germany Government Bond UCITS ETF',
    name: 'Xtrackers II Germany Government Bond UCITS ETF',
    symbol: 'X03G.DE',
  },
  {
    value: 'XAAG.DE',
    label: 'XAAG.DE,  Invesco Bloomberg Commodity ex-Agriculture UCITS ETF',
    name: 'Invesco Bloomberg Commodity ex-Agriculture UCITS ETF',
    symbol: 'XAAG.DE',
  },
  {
    value: 'XAMB.DE',
    label:
      'XAMB.DE,  Amundi Index Solutions - Amundi MSCI World SRI UCITS ETF DR',
    name: 'Amundi Index Solutions - Amundi MSCI World SRI UCITS ETF DR',
    symbol: 'XAMB.DE',
  },
  {
    value: 'XBO2.DE',
    label: 'XBO2.DE,  Xtrackers II Italy Government Bond 0-1 Swap UCITS ETF',
    name: 'Xtrackers II Italy Government Bond 0-1 Swap UCITS ETF',
    symbol: 'XBO2.DE',
  },
  {
    value: 'XCO2.DE',
    label: 'XCO2.DE,  Lyxor Global Green Bond 1-10Y (DR) UCITS ETF - Acc',
    name: 'Lyxor Global Green Bond 1-10Y (DR) UCITS ETF - Acc',
    symbol: 'XCO2.DE',
  },
  {
    value: 'XDWD.DE',
    label: 'XDWD.DE,  Xtrackers MSCI World UCITS ETF',
    name: 'Xtrackers MSCI World UCITS ETF',
    symbol: 'XDWD.DE',
  },
  {
    value: 'XESP.DE',
    label: 'XESP.DE,  Xtrackers Spain UCITS ETF',
    name: 'Xtrackers Spain UCITS ETF',
    symbol: 'XESP.DE',
  },
  {
    value: 'XGVD.DE',
    label:
      'XGVD.DE,  Xtrackers II Global Government Bond UCITS ETF 1D - EUR Hedged',
    name: 'Xtrackers II Global Government Bond UCITS ETF 1D - EUR Hedged',
    symbol: 'XGVD.DE',
  },
  {
    value: 'XMK9.DE',
    label: 'XMK9.DE,  Xtrackers MSCI Japan UCITS ETF',
    name: 'Xtrackers MSCI Japan UCITS ETF',
    symbol: 'XMK9.DE',
  },
  {
    value: 'XMLC.DE',
    label: 'XMLC.DE,  L&G Clean Water UCITS ETF',
    name: 'L&G Clean Water UCITS ETF',
    symbol: 'XMLC.DE',
  },
  {
    value: 'XMLD.DE',
    label: 'XMLD.DE,  L&G Artificial Intelligence UCITS ETF',
    name: 'L&G Artificial Intelligence UCITS ETF',
    symbol: 'XMLD.DE',
  },
  {
    value: 'XMLH.DE',
    label: 'XMLH.DE,  L&G Healthcare Breakthrough UCITS ETF',
    name: 'L&G Healthcare Breakthrough UCITS ETF',
    symbol: 'XMLH.DE',
  },
  {
    value: 'XU61.DE',
    label: 'XU61.DE,  BNP Paribas Easy ECPI Global ESG Infrastructure',
    name: 'BNP Paribas Easy ECPI Global ESG Infrastructure',
    symbol: 'XU61.DE',
  },
  {
    value: 'XYPD.DE',
    label:
      'XYPD.DE,  Xtrackers II iBoxx Eurozone Government Bond Yield Plus UCITS ETF',
    name: 'Xtrackers II iBoxx Eurozone Government Bond Yield Plus UCITS ETF',
    symbol: 'XYPD.DE',
  },
  {
    value: 'ZPAB.DE',
    label:
      'ZPAB.DE,  Lyxor Net Zero 2050 S&P Eurozone Climate PAB (DR) UCITS ETF-Acc',
    name: 'Lyxor Net Zero 2050 S&P Eurozone Climate PAB (DR) UCITS ETF-Acc',
    symbol: 'ZPAB.DE',
  },
  {
    value: 'ZSRL.DE',
    label: 'ZSRL.DE,  BNP Paribas Easy FTSE EPRA/NAREIT Developed Europe',
    name: 'BNP Paribas Easy FTSE EPRA/NAREIT Developed Europe',
    symbol: 'ZSRL.DE',
  },
  {
    value: '3E2.F',
    label: '3E2.F,  Etsy, Inc.',
    name: 'Etsy, Inc.',
    symbol: '3E2.F',
  },
  {
    value: 'NNND.F',
    label: 'NNND.F,  Tencent Holdings Limited',
    name: 'Tencent Holdings Limited',
    symbol: 'NNND.F',
  },
  {
    value: '5ZM.F',
    label: '5ZM.F,  Zoom Video Communications, Inc.',
    name: 'Zoom Video Communications, Inc.',
    symbol: '5ZM.F',
  },
  {
    value: 'CVO.TO',
    label: 'CVO.TO,  Coveo Solutions Inc.',
    name: 'Coveo Solutions Inc.',
    symbol: 'CVO.TO',
  },
  {
    value: '013A.F',
    label: '013A.F,  JD.com, Inc.',
    name: 'JD.com, Inc.',
    symbol: '013A.F',
  },
  {
    value: 'CHLL.L',
    label: 'CHLL.L,  Chill Brands Group PLC',
    name: 'Chill Brands Group PLC',
    symbol: 'CHLL.L',
  },
  {
    value: '6909.HK',
    label: '6909.HK,  BetterLife Holding Limited',
    name: 'BetterLife Holding Limited',
    symbol: '6909.HK',
  },
  {
    value: 'UDMY',
    label: 'UDMY,  Udemy, Inc.',
    name: 'Udemy, Inc.',
    symbol: 'UDMY',
  },
  {
    value: '2015.HK',
    label: '2015.HK,  Li Auto Inc.',
    name: 'Li Auto Inc.',
    symbol: '2015.HK',
  },
  {
    value: '9868.HK',
    label: '9868.HK,  XPeng Inc.',
    name: 'XPeng Inc.',
    symbol: '9868.HK',
  },
  {
    value: '9677.HK',
    label: '9677.HK,  Weihai City Commercial Bank Co., Ltd.',
    name: 'Weihai City Commercial Bank Co., Ltd.',
    symbol: '9677.HK',
  },
  {
    value: '9889.HK',
    label: '9889.HK,  Dongguan Rural Commercial Bank Co., Ltd.',
    name: 'Dongguan Rural Commercial Bank Co., Ltd.',
    symbol: '9889.HK',
  },
  {
    value: '0013.HK',
    label: '0013.HK,  HUTCHMED (China) Limited',
    name: 'HUTCHMED (China) Limited',
    symbol: '0013.HK',
  },
  {
    value: '2137.HK',
    label: '2137.HK,  Brii Biosciences Limited',
    name: 'Brii Biosciences Limited',
    symbol: '2137.HK',
  },
  {
    value: 'TGVC',
    label: 'TGVC,  TG Venture Acquisition Corp.',
    name: 'TG Venture Acquisition Corp.',
    symbol: 'TGVC',
  },
  {
    value: '2162.HK',
    label: '2162.HK,  Keymed Biosciences Inc.',
    name: 'Keymed Biosciences Inc.',
    symbol: '2162.HK',
  },
  {
    value: 'TGVCW',
    label: 'TGVCW,  TG Venture Acquisition Corp.',
    name: 'TG Venture Acquisition Corp.',
    symbol: 'TGVCW',
  },
  {
    value: 'LAXXU',
    label: 'LAXXU,  8i Acquisition 2 Corp.',
    name: '8i Acquisition 2 Corp.',
    symbol: 'LAXXU',
  },
  {
    value: '2171.HK',
    label: '2171.HK,  CARsgen Therapeutics Holdings Limited',
    name: 'CARsgen Therapeutics Holdings Limited',
    symbol: '2171.HK',
  },
  {
    value: 'GATE',
    label: 'GATE,  Marblegate Acquisition Corp.',
    name: 'Marblegate Acquisition Corp.',
    symbol: 'GATE',
  },
  {
    value: 'ARTE',
    label: 'ARTE,  Artemis Strategic Investment Corporation',
    name: 'Artemis Strategic Investment Corporation',
    symbol: 'ARTE',
  },
  {
    value: '2197.HK',
    label: '2197.HK,  Clover Biopharmaceuticals, Ltd.',
    name: 'Clover Biopharmaceuticals, Ltd.',
    symbol: '2197.HK',
  },
  {
    value: 'MTRYW',
    label: 'MTRYW,  Monterey Bio Acquisition Corporation',
    name: 'Monterey Bio Acquisition Corporation',
    symbol: 'MTRYW',
  },
  {
    value: 'GATEW',
    label: 'GATEW,  Marblegate Acquisition Corp.',
    name: 'Marblegate Acquisition Corp.',
    symbol: 'GATEW',
  },
  {
    value: 'HPLTW',
    label: 'HPLTW,  Home Plate Acquisition Corporation',
    name: 'Home Plate Acquisition Corporation',
    symbol: 'HPLTW',
  },
  {
    value: '6628.HK',
    label: '6628.HK,  Transcenta Holding Limited',
    name: 'Transcenta Holding Limited',
    symbol: '6628.HK',
  },
  {
    value: 'BRZE',
    label: 'BRZE,  Braze, Inc.',
    name: 'Braze, Inc.',
    symbol: 'BRZE',
  },
  {
    value: 'IREN',
    label: 'IREN,  Iris Energy Limited',
    name: 'Iris Energy Limited',
    symbol: 'IREN',
  },
  {
    value: 'SEV',
    label: 'SEV,  Sono Group N.V.',
    name: 'Sono Group N.V.',
    symbol: 'SEV',
  },
  {
    value: 'AVHI',
    label: 'AVHI,  Achari Ventures Holdings Corp. I',
    name: 'Achari Ventures Holdings Corp. I',
    symbol: 'AVHI',
  },
  {
    value: 'SGIIU',
    label: 'SGIIU,  Seaport Global Acquisition II Corp.',
    name: 'Seaport Global Acquisition II Corp.',
    symbol: 'SGIIU',
  },
  {
    value: 'SMAPW',
    label: 'SMAPW,  SportsMap Tech Acquisition Corp.',
    name: 'SportsMap Tech Acquisition Corp.',
    symbol: 'SMAPW',
  },
  {
    value: 'LOCC',
    label: 'LOCC,  Live Oak Crestview Climate Acquisition Corp.',
    name: 'Live Oak Crestview Climate Acquisition Corp.',
    symbol: 'LOCC',
  },
  {
    value: '6616.HK',
    label: '6616.HK,  Global New Material International Holdings Limited',
    name: 'Global New Material International Holdings Limited',
    symbol: '6616.HK',
  },
  {
    value: 'DSAQ',
    label: 'DSAQ,  Direct Selling Acquisition Corp.',
    name: 'Direct Selling Acquisition Corp.',
    symbol: 'DSAQ',
  },
  {
    value: 'BTBD',
    label: 'BTBD,  BT Brands, Inc.',
    name: 'BT Brands, Inc.',
    symbol: 'BTBD',
  },
  {
    value: 'MYNA',
    label: 'MYNA,  Mynaric AG',
    name: 'Mynaric AG',
    symbol: 'MYNA',
  },
  {
    value: 'MCAGU',
    label: 'MCAGU,  Mountain Crest Acquisition Corp. V',
    name: 'Mountain Crest Acquisition Corp. V',
    symbol: 'MCAGU',
  },
  {
    value: 'LVLU',
    label: "LVLU,  Lulu's Fashion Lounge Holdings, Inc.",
    name: "Lulu's Fashion Lounge Holdings, Inc.",
    symbol: 'LVLU',
  },
  {
    value: 'PIK',
    label: 'PIK,  Kidpik Corp.',
    name: 'Kidpik Corp.',
    symbol: 'PIK',
  },
  {
    value: 'LGVCU',
    label: 'LGVCU,  LAMF Global Ventures Corp. I',
    name: 'LAMF Global Ventures Corp. I',
    symbol: 'LGVCU',
  },
  {
    value: 'TIVC',
    label: 'TIVC,  Tivic Health Systems, Inc.',
    name: 'Tivic Health Systems, Inc.',
    symbol: 'TIVC',
  },
  {
    value: 'VAXX',
    label: 'VAXX,  Vaxxinity, Inc.',
    name: 'Vaxxinity, Inc.',
    symbol: 'VAXX',
  },
  {
    value: 'VMGAU',
    label: 'VMGAU,  VMG Consumer Acquisition Corp.',
    name: 'VMG Consumer Acquisition Corp.',
    symbol: 'VMGAU',
  },
  {
    value: '9858.HK',
    label: '9858.HK,  China Youran Dairy Group Limited',
    name: 'China Youran Dairy Group Limited',
    symbol: '9858.HK',
  },
  {
    value: 'HHGCR',
    label: 'HHGCR,  HHG Capital Corporation',
    name: 'HHG Capital Corporation',
    symbol: 'HHGCR',
  },
  {
    value: '0797.HK',
    label: '0797.HK,  7Road Holdings Limited',
    name: '7Road Holdings Limited',
    symbol: '0797.HK',
  },
  {
    value: 'CINT',
    label: 'CINT,  CI&T Inc',
    name: 'CI&T Inc',
    symbol: 'CINT',
  },
  {
    value: 'HTZ',
    label: 'HTZ,  Hertz Global Holdings, Inc.',
    name: 'Hertz Global Holdings, Inc.',
    symbol: 'HTZ',
  },
  {
    value: 'GVCIU',
    label: 'GVCIU,  Green Visor Financial Technology Acquisition Corp. I',
    name: 'Green Visor Financial Technology Acquisition Corp. I',
    symbol: 'GVCIU',
  },
  {
    value: 'OLITU',
    label: 'OLITU,  OmniLit Acquisition Corp.',
    name: 'OmniLit Acquisition Corp.',
    symbol: 'OLITU',
  },
  {
    value: 'DPCSU',
    label: 'DPCSU,  DP Cap Acquisition Corp I',
    name: 'DP Cap Acquisition Corp I',
    symbol: 'DPCSU',
  },
  {
    value: '2823.HK',
    label: '2823.HK,  iShares FTSE China A50 ETF',
    name: 'iShares FTSE China A50 ETF',
    symbol: '2823.HK',
  },
  {
    value: 'BCSAU',
    label: 'BCSAU,  Blockchain Coinvestors Acquisition Corp. I',
    name: 'Blockchain Coinvestors Acquisition Corp. I',
    symbol: 'BCSAU',
  },
  {
    value: '2828.HK',
    label: '2828.HK,  Hang Seng China Enterprises Index ETF',
    name: 'Hang Seng China Enterprises Index ETF',
    symbol: '2828.HK',
  },
  {
    value: '2155.HK',
    label: '2155.HK,  Morimatsu International Holdings Company Limited',
    name: 'Morimatsu International Holdings Company Limited',
    symbol: '2155.HK',
  },
  {
    value: '2190.HK',
    label: '2190.HK,  Zylox-Tonbridge Medical Technology Co., Ltd.',
    name: 'Zylox-Tonbridge Medical Technology Co., Ltd.',
    symbol: '2190.HK',
  },
  {
    value: '2192.HK',
    label: '2192.HK,  Medlive Technology Co., Ltd.',
    name: 'Medlive Technology Co., Ltd.',
    symbol: '2192.HK',
  },
  {
    value: '2216.HK',
    label: '2216.HK,  Broncus Holding Corporation',
    name: 'Broncus Holding Corporation',
    symbol: '2216.HK',
  },
  {
    value: '2219.HK',
    label: '2219.HK,  Chaoju Eye Care Holdings Limited',
    name: 'Chaoju Eye Care Holdings Limited',
    symbol: '2219.HK',
  },
  {
    value: '2251.HK',
    label: '2251.HK,  Beijing Airdoc Technology Co., Ltd.',
    name: 'Beijing Airdoc Technology Co., Ltd.',
    symbol: '2251.HK',
  },
  {
    value: '2252.HK',
    label: '2252.HK,  Shanghai MicroPort MedBot (Group) Co., Ltd.',
    name: 'Shanghai MicroPort MedBot (Group) Co., Ltd.',
    symbol: '2252.HK',
  },
  {
    value: '6699.HK',
    label: '6699.HK,  Angelalign Technology Inc.',
    name: 'Angelalign Technology Inc.',
    symbol: '6699.HK',
  },
  {
    value: '9960.HK',
    label: '9960.HK,  Kindstar Globalgene Technology, Inc.',
    name: 'Kindstar Globalgene Technology, Inc.',
    symbol: '9960.HK',
  },
  {
    value: '2975.HK',
    label: '2975.HK,  Haichang Ocean Park Holdings Ltd.',
    name: 'Haichang Ocean Park Holdings Ltd.',
    symbol: '2975.HK',
  },
  {
    value: '1969.HK',
    label: '1969.HK,  China Chunlai Education Group Co., Ltd.',
    name: 'China Chunlai Education Group Co., Ltd.',
    symbol: '1969.HK',
  },
  {
    value: '2175.HK',
    label: '2175.HK,  China General Education Group Limited',
    name: 'China General Education Group Limited',
    symbol: '2175.HK',
  },
  {
    value: '6913.HK',
    label: '6913.HK,  South China Vocational Education Group Company Limited',
    name: 'South China Vocational Education Group Company Limited',
    symbol: '6913.HK',
  },
  {
    value: '1940.HK',
    label: '1940.HK,  China Gas Industry Investment Holdings Co. Ltd.',
    name: 'China Gas Industry Investment Holdings Co. Ltd.',
    symbol: '1940.HK',
  },
  {
    value: '6611.HK',
    label: '6611.HK,  Sanxun Holdings Group Limited',
    name: 'Sanxun Holdings Group Limited',
    symbol: '6611.HK',
  },
  {
    value: '0606.HK',
    label: '0606.HK,  SCE Intelligent Commercial Management Holdings Limited',
    name: 'SCE Intelligent Commercial Management Holdings Limited',
    symbol: '0606.HK',
  },
  {
    value: '1965.HK',
    label: '1965.HK,  Landsea Green Life Service Company Limited',
    name: 'Landsea Green Life Service Company Limited',
    symbol: '1965.HK',
  },
  {
    value: '1971.HK',
    label: '1971.HK,  Redsun Services Group Limited',
    name: 'Redsun Services Group Limited',
    symbol: '1971.HK',
  },
  {
    value: '2107.HK',
    label: '2107.HK,  First Service Holding Limited',
    name: 'First Service Holding Limited',
    symbol: '2107.HK',
  },
  {
    value: '2165.HK',
    label: '2165.HK,  Ling Yue Services Group Limited',
    name: 'Ling Yue Services Group Limited',
    symbol: '2165.HK',
  },
  {
    value: '2205.HK',
    label: '2205.HK,  Kangqiao Service Group Limited',
    name: 'Kangqiao Service Group Limited',
    symbol: '2205.HK',
  },
  {
    value: '2207.HK',
    label: '2207.HK,  Ronshine Service Holding Co., Ltd',
    name: 'Ronshine Service Holding Co., Ltd',
    symbol: '2207.HK',
  },
  {
    value: '2215.HK',
    label: '2215.HK,  Dexin Services Group Limited',
    name: 'Dexin Services Group Limited',
    symbol: '2215.HK',
  },
  {
    value: '6626.HK',
    label: '6626.HK,  Yuexiu Services Group Limited',
    name: 'Yuexiu Services Group Limited',
    symbol: '6626.HK',
  },
  {
    value: '2191.HK',
    label: '2191.HK,  SF Real Estate Investment Trust',
    name: 'SF Real Estate Investment Trust',
    symbol: '2191.HK',
  },
  {
    value: '9869.HK',
    label: '9869.HK,  Helens International Holdings Company Limited',
    name: 'Helens International Holdings Company Limited',
    symbol: '9869.HK',
  },
  {
    value: '0279.HK',
    label: '0279.HK,  Arta TechFin Corporation Limited',
    name: 'Arta TechFin Corporation Limited',
    symbol: '0279.HK',
  },
  {
    value: '2150.HK',
    label: '2150.HK,  Nayuki Holdings Limited',
    name: 'Nayuki Holdings Limited',
    symbol: '2150.HK',
  },
  {
    value: 'KUKE',
    label: 'KUKE,  Kuke Music Holding Limited',
    name: 'Kuke Music Holding Limited',
    symbol: 'KUKE',
  },
  {
    value: 'SBET',
    label: 'SBET,  SharpLink Gaming Ltd.',
    name: 'SharpLink Gaming Ltd.',
    symbol: 'SBET',
  },
  {
    value: 'NFYS',
    label: 'NFYS,  Enphys Acquisition Corp.',
    name: 'Enphys Acquisition Corp.',
    symbol: 'NFYS',
  },
  {
    value: 'CNGLU',
    label: 'CNGLU,  Canna-Global Acquisition Corp',
    name: 'Canna-Global Acquisition Corp',
    symbol: 'CNGLU',
  },
  {
    value: 'TLGYU',
    label: 'TLGYU,  TLGY Acquisition Corporation',
    name: 'TLGY Acquisition Corporation',
    symbol: 'TLGYU',
  },
  {
    value: 'PALT',
    label: 'PALT,  Paltalk, Inc.',
    name: 'Paltalk, Inc.',
    symbol: 'PALT',
  },
  {
    value: 'AZTA',
    label: 'AZTA,  Azenta, Inc.',
    name: 'Azenta, Inc.',
    symbol: 'AZTA',
  },
  {
    value: 'CTV',
    label: 'CTV,  Innovid Corp.',
    name: 'Innovid Corp.',
    symbol: 'CTV',
  },
  {
    value: 'MCAG',
    label: 'MCAG,  Mountain Crest Acquisition Corp. V',
    name: 'Mountain Crest Acquisition Corp. V',
    symbol: 'MCAG',
  },
  {
    value: 'BRDS',
    label: 'BRDS,  Bird Global, Inc.',
    name: 'Bird Global, Inc.',
    symbol: 'BRDS',
  },
  {
    value: 'HM-B.ST',
    label: 'HM-B.ST,  H & M Hennes & Mauritz AB (publ)',
    name: 'H & M Hennes & Mauritz AB (publ)',
    symbol: 'HM-B.ST',
  },
  {
    value: 'HABA.DE',
    label: 'HABA.DE,  Hamborner REIT AG',
    name: 'Hamborner REIT AG',
    symbol: 'HABA.DE',
  },
  {
    value: 'HAG.DE',
    label: 'HAG.DE,  Hensoldt AG',
    name: 'Hensoldt AG',
    symbol: 'HAG.DE',
  },
  {
    value: 'MESTRO.ST',
    label: 'MESTRO.ST,  Mestro AB (publ)',
    name: 'Mestro AB (publ)',
    symbol: 'MESTRO.ST',
  },
  {
    value: 'GRAB',
    label: 'GRAB,  Grab Holdings Limited',
    name: 'Grab Holdings Limited',
    symbol: 'GRAB',
  },
  {
    value: '000040.KS',
    label: '000040.KS,  KR Motors Co., Ltd.',
    name: 'KR Motors Co., Ltd.',
    symbol: '000040.KS',
  },
  {
    value: '000050.KS',
    label: '000050.KS,  Kyungbangco.Ltd',
    name: 'Kyungbangco.Ltd',
    symbol: '000050.KS',
  },
  {
    value: '000075.KS',
    label: '000075.KS,  Samyang Holdings Corporation',
    name: 'Samyang Holdings Corporation',
    symbol: '000075.KS',
  },
  {
    value: '000087.KS',
    label: '000087.KS,  HITEJINRO Co., Ltd.',
    name: 'HITEJINRO Co., Ltd.',
    symbol: '000087.KS',
  },
  {
    value: '000105.KS',
    label: '000105.KS,  Yuhan Corporation',
    name: 'Yuhan Corporation',
    symbol: '000105.KS',
  },
  {
    value: '000145.KS',
    label: '000145.KS,  Hitejinro Holdings Co., Ltd.',
    name: 'Hitejinro Holdings Co., Ltd.',
    symbol: '000145.KS',
  },
  {
    value: '000155.KS',
    label: '000155.KS,  Doosan Corporation',
    name: 'Doosan Corporation',
    symbol: '000155.KS',
  },
  {
    value: '000157.KS',
    label: '000157.KS,  Doosan Corporation',
    name: 'Doosan Corporation',
    symbol: '000157.KS',
  },
  {
    value: '000180.KS',
    label: '000180.KS,  Sungchang Enterprise Holdings Limited',
    name: 'Sungchang Enterprise Holdings Limited',
    symbol: '000180.KS',
  },
  {
    value: '000215.KS',
    label: '000215.KS,  DL Holdings CO., LTD.',
    name: 'DL Holdings CO., LTD.',
    symbol: '000215.KS',
  },
  {
    value: '000220.KS',
    label: '000220.KS,  Yuyu Pharma, Inc.',
    name: 'Yuyu Pharma, Inc.',
    symbol: '000220.KS',
  },
  {
    value: '000225.KS',
    label: '000225.KS,  Yuyu Pharma, Inc.',
    name: 'Yuyu Pharma, Inc.',
    symbol: '000225.KS',
  },
  {
    value: '000227.KS',
    label: '000227.KS,  Yuyu Pharma, Inc.',
    name: 'Yuyu Pharma, Inc.',
    symbol: '000227.KS',
  },
  {
    value: '000230.KS',
    label: '000230.KS,  Ildong Holdings Co., Ltd.',
    name: 'Ildong Holdings Co., Ltd.',
    symbol: '000230.KS',
  },
  {
    value: '000300.KS',
    label: '000300.KS,  Dayou Plus Co.,Ltd',
    name: 'Dayou Plus Co.,Ltd',
    symbol: '000300.KS',
  },
  {
    value: '000320.KS',
    label: '000320.KS,  NOROO Holdings Co., Ltd.',
    name: 'NOROO Holdings Co., Ltd.',
    symbol: '000320.KS',
  },
  {
    value: '000325.KS',
    label: '000325.KS,  NOROO Holdings Co., Ltd.',
    name: 'NOROO Holdings Co., Ltd.',
    symbol: '000325.KS',
  },
  {
    value: '000390.KS',
    label: '000390.KS,  Samhwa Paints Industrial Co., Ltd.',
    name: 'Samhwa Paints Industrial Co., Ltd.',
    symbol: '000390.KS',
  },
  {
    value: '000430.KS',
    label: '000430.KS,  Daewon Kang Up Co., Ltd.',
    name: 'Daewon Kang Up Co., Ltd.',
    symbol: '000430.KS',
  },
  {
    value: '000480.KS',
    label: '000480.KS,  Chosun Refractories Co.,Ltd.',
    name: 'Chosun Refractories Co.,Ltd.',
    symbol: '000480.KS',
  },
  {
    value: '000490.KS',
    label: '000490.KS,  Daedong Corporation',
    name: 'Daedong Corporation',
    symbol: '000490.KS',
  },
  {
    value: '000500.KS',
    label: '000500.KS,  GAON CABLE Co., Ltd.',
    name: 'GAON CABLE Co., Ltd.',
    symbol: '000500.KS',
  },
  {
    value: '000520.KS',
    label: '000520.KS,  Samil Pharmaceutical Co.,Ltd',
    name: 'Samil Pharmaceutical Co.,Ltd',
    symbol: '000520.KS',
  },
  {
    value: '000540.KS',
    label: '000540.KS,  Heungkuk Fire&Marine Insurance Co., Ltd.',
    name: 'Heungkuk Fire&Marine Insurance Co., Ltd.',
    symbol: '000540.KS',
  },
  {
    value: '000545.KS',
    label: '000545.KS,  Heungkuk Fire&Marine Insurance Co., Ltd.',
    name: 'Heungkuk Fire&Marine Insurance Co., Ltd.',
    symbol: '000545.KS',
  },
  {
    value: '000547.KS',
    label: '000547.KS,  Heungkuk Fire&Marine Insurance Co., Ltd.',
    name: 'Heungkuk Fire&Marine Insurance Co., Ltd.',
    symbol: '000547.KS',
  },
  {
    value: '000590.KS',
    label: '000590.KS,  CS Holdings Co., Ltd.',
    name: 'CS Holdings Co., Ltd.',
    symbol: '000590.KS',
  },
  {
    value: '000650.KS',
    label: '000650.KS,  Chunil Express Co., Ltd.',
    name: 'Chunil Express Co., Ltd.',
    symbol: '000650.KS',
  },
  {
    value: '000680.KS',
    label: '000680.KS,  LS Networks Corporation Limited',
    name: 'LS Networks Corporation Limited',
    symbol: '000680.KS',
  },
  {
    value: '000700.KS',
    label: '000700.KS,  Eusu Holdings Co., Ltd.',
    name: 'Eusu Holdings Co., Ltd.',
    symbol: '000700.KS',
  },
  {
    value: '000725.KS',
    label: '000725.KS,  Hyundai Engineering & Construction Co., Ltd.',
    name: 'Hyundai Engineering & Construction Co., Ltd.',
    symbol: '000725.KS',
  },
  {
    value: '000760.KS',
    label: '000760.KS,  Rifa Co.,Ltd.',
    name: 'Rifa Co.,Ltd.',
    symbol: '000760.KS',
  },
  {
    value: '000850.KS',
    label: '000850.KS,  Hwacheon Machine Tool Co. Ltd',
    name: 'Hwacheon Machine Tool Co. Ltd',
    symbol: '000850.KS',
  },
  {
    value: '000860.KS',
    label: '000860.KS,  Kangnam Jevisco Co., Ltd',
    name: 'Kangnam Jevisco Co., Ltd',
    symbol: '000860.KS',
  },
  {
    value: '000885.KS',
    label: '000885.KS,  Hanwha Corporation',
    name: 'Hanwha Corporation',
    symbol: '000885.KS',
  },
  {
    value: '000890.KS',
    label: '000890.KS,  BOHAE BREWERY Co., Ltd.',
    name: 'BOHAE BREWERY Co., Ltd.',
    symbol: '000890.KS',
  },
  {
    value: '000910.KS',
    label: '000910.KS,  Union Corporation',
    name: 'Union Corporation',
    symbol: '000910.KS',
  },
  {
    value: '000950.KS',
    label: '000950.KS,  Chonbang Co., Ltd.',
    name: 'Chonbang Co., Ltd.',
    symbol: '000950.KS',
  },
  {
    value: '000970.KS',
    label: '000970.KS,  Korea Cast Iron Pipe Ind. Co., Ltd.',
    name: 'Korea Cast Iron Pipe Ind. Co., Ltd.',
    symbol: '000970.KS',
  },
  {
    value: '000995.KS',
    label: '000995.KS,  DB HiTek CO., LTD.',
    name: 'DB HiTek CO., LTD.',
    symbol: '000995.KS',
  },
  {
    value: '001020.KS',
    label: '001020.KS,  PaperCorea Inc.',
    name: 'PaperCorea Inc.',
    symbol: '001020.KS',
  },
  {
    value: '001045.KS',
    label: '001045.KS,  CJ Corporation',
    name: 'CJ Corporation',
    symbol: '001045.KS',
  },
  {
    value: '00104K.KS',
    label: '00104K.KS,  CJ Corporation',
    name: 'CJ Corporation',
    symbol: '00104K.KS',
  },
  {
    value: '001065.KS',
    label: '001065.KS,  JW Pharmaceutical Corporation',
    name: 'JW Pharmaceutical Corporation',
    symbol: '001065.KS',
  },
  {
    value: '001067.KS',
    label: '001067.KS,  JW Pharmaceutical Corporation',
    name: 'JW Pharmaceutical Corporation',
    symbol: '001067.KS',
  },
  {
    value: '001070.KS',
    label: '001070.KS,  Taihan Textile Co., Ltd.',
    name: 'Taihan Textile Co., Ltd.',
    symbol: '001070.KS',
  },
  {
    value: '001080.KS',
    label: '001080.KS,  Manho Rope & Wire Co., Ltd.',
    name: 'Manho Rope & Wire Co., Ltd.',
    symbol: '001080.KS',
  },
  {
    value: '001140.KS',
    label: '001140.KS,  KUKBO Logistics Co., Ltd.',
    name: 'KUKBO Logistics Co., Ltd.',
    symbol: '001140.KS',
  },
  {
    value: '001210.KS',
    label: '001210.KS,  Kumho Electric, Inc.',
    name: 'Kumho Electric, Inc.',
    symbol: '001210.KS',
  },
  {
    value: '001260.KS',
    label: '001260.KS,  Namkwang Engineering & Construction Co., Ltd.',
    name: 'Namkwang Engineering & Construction Co., Ltd.',
    symbol: '001260.KS',
  },
  {
    value: '001270.KS',
    label: '001270.KS,  BOOKOOK Securities Co., Ltd.',
    name: 'BOOKOOK Securities Co., Ltd.',
    symbol: '001270.KS',
  },
  {
    value: '001275.KS',
    label: '001275.KS,  BOOKOOK Securities Co., Ltd.',
    name: 'BOOKOOK Securities Co., Ltd.',
    symbol: '001275.KS',
  },
  {
    value: '001290.KS',
    label: '001290.KS,  Sangsangin Investment & Securities Co.,Ltd.',
    name: 'Sangsangin Investment & Securities Co.,Ltd.',
    symbol: '001290.KS',
  },
  {
    value: '001340.KS',
    label: '001340.KS,  Paik Kwang Industrial Co., Ltd.',
    name: 'Paik Kwang Industrial Co., Ltd.',
    symbol: '001340.KS',
  },
  {
    value: '001380.KS',
    label: '001380.KS,  SG Global Co.,Ltd.',
    name: 'SG Global Co.,Ltd.',
    symbol: '001380.KS',
  },
  {
    value: '001390.KS',
    label: '001390.KS,  KG Chemical Corporation',
    name: 'KG Chemical Corporation',
    symbol: '001390.KS',
  },
  {
    value: '001420.KS',
    label: '001420.KS,  Tae Won Mulsan Co., Ltd.',
    name: 'Tae Won Mulsan Co., Ltd.',
    symbol: '001420.KS',
  },
  {
    value: '001460.KS',
    label: '001460.KS,  BYC Co., Ltd.',
    name: 'BYC Co., Ltd.',
    symbol: '001460.KS',
  },
  {
    value: '001465.KS',
    label: '001465.KS,  BYC Co., Ltd.',
    name: 'BYC Co., Ltd.',
    symbol: '001465.KS',
  },
  {
    value: '001515.KS',
    label: '001515.KS,  SK Securities Co., Ltd.',
    name: 'SK Securities Co., Ltd.',
    symbol: '001515.KS',
  },
  {
    value: '001525.KS',
    label: '001525.KS,  Tongyang Inc.',
    name: 'Tongyang Inc.',
    symbol: '001525.KS',
  },
  {
    value: '001527.KS',
    label: '001527.KS,  Tongyang Inc.',
    name: 'Tongyang Inc.',
    symbol: '001527.KS',
  },
  {
    value: '001550.KS',
    label: '001550.KS,  Chobi Company Limited',
    name: 'Chobi Company Limited',
    symbol: '001550.KS',
  },
  {
    value: '001560.KS',
    label: '001560.KS,  Cheil Grinding Wheel Ind. Co., Ltd.',
    name: 'Cheil Grinding Wheel Ind. Co., Ltd.',
    symbol: '001560.KS',
  },
  {
    value: '001570.KS',
    label: '001570.KS,  Kumyang Co., Ltd.',
    name: 'Kumyang Co., Ltd.',
    symbol: '001570.KS',
  },
  {
    value: '001620.KS',
    label: '001620.KS,  Kbi Dongkook Ind.Co.,Ltd.',
    name: 'Kbi Dongkook Ind.Co.,Ltd.',
    symbol: '001620.KS',
  },
  {
    value: '001685.KS',
    label: '001685.KS,  Daesang Corporation',
    name: 'Daesang Corporation',
    symbol: '001685.KS',
  },
  {
    value: '001725.KS',
    label: '001725.KS,  Shinyoung Securities Co., Ltd.',
    name: 'Shinyoung Securities Co., Ltd.',
    symbol: '001725.KS',
  },
  {
    value: '001745.KS',
    label: '001745.KS,  SK Networks Company Limited',
    name: 'SK Networks Company Limited',
    symbol: '001745.KS',
  },
  {
    value: '001750.KS',
    label: '001750.KS,  Hanyang Securities Co. Ltd.',
    name: 'Hanyang Securities Co. Ltd.',
    symbol: '001750.KS',
  },
  {
    value: '001755.KS',
    label: '001755.KS,  Hanyang Securities Co. Ltd.',
    name: 'Hanyang Securities Co. Ltd.',
    symbol: '001755.KS',
  },
  {
    value: '001770.KS',
    label: '001770.KS,  Shin Hwa Dynamics Co.,Ltd.',
    name: 'Shin Hwa Dynamics Co.,Ltd.',
    symbol: '001770.KS',
  },
  {
    value: '001780.KS',
    label: '001780.KS,  Aluko Co., Ltd.',
    name: 'Aluko Co., Ltd.',
    symbol: '001780.KS',
  },
  {
    value: '001790.KS',
    label: '001790.KS,  TS Corporation',
    name: 'TS Corporation',
    symbol: '001790.KS',
  },
  {
    value: '001795.KS',
    label: '001795.KS,  TS Corporation',
    name: 'TS Corporation',
    symbol: '001795.KS',
  },
  {
    value: '001940.KS',
    label: '001940.KS,  KISCO Holdings Corp.',
    name: 'KISCO Holdings Corp.',
    symbol: '001940.KS',
  },
  {
    value: '002025.KS',
    label: '002025.KS,  Kolon Corporation',
    name: 'Kolon Corporation',
    symbol: '002025.KS',
  },
  {
    value: '002030.KS',
    label: '002030.KS,  Asia Holdings Co., Ltd.',
    name: 'Asia Holdings Co., Ltd.',
    symbol: '002030.KS',
  },
  {
    value: '002070.KS',
    label: '002070.KS,  Vivien Corporation',
    name: 'Vivien Corporation',
    symbol: '002070.KS',
  },
  {
    value: '002100.KS',
    label: '002100.KS,  Kyung Nong Corporation',
    name: 'Kyung Nong Corporation',
    symbol: '002100.KS',
  },
  {
    value: '002140.KS',
    label: '002140.KS,  Korea Industrial Co., Ltd.',
    name: 'Korea Industrial Co., Ltd.',
    symbol: '002140.KS',
  },
  {
    value: '002170.KS',
    label: '002170.KS,  Samyang Tongsang Co., Ltd',
    name: 'Samyang Tongsang Co., Ltd',
    symbol: '002170.KS',
  },
  {
    value: '002200.KS',
    label: '002200.KS,  Korea Export Packaging Industrial Co.,Ltd',
    name: 'Korea Export Packaging Industrial Co.,Ltd',
    symbol: '002200.KS',
  },
  {
    value: '002220.KS',
    label: '002220.KS,  Hanil Iron & Steel Co., Ltd',
    name: 'Hanil Iron & Steel Co., Ltd',
    symbol: '002220.KS',
  },
  {
    value: '002355.KS',
    label: '002355.KS,  Nexen Tire Corporation',
    name: 'Nexen Tire Corporation',
    symbol: '002355.KS',
  },
  {
    value: '002360.KS',
    label: '002360.KS,  SH Energy & Chemical Co., Ltd.',
    name: 'SH Energy & Chemical Co., Ltd.',
    symbol: '002360.KS',
  },
  {
    value: '002410.KS',
    label: '002410.KS,  Bumyang Construction Co.,Ltd.',
    name: 'Bumyang Construction Co.,Ltd.',
    symbol: '002410.KS',
  },
  {
    value: '002450.KS',
    label: '002450.KS,  SAMICK MUSICAL INSTRUMENT Co., Ltd',
    name: 'SAMICK MUSICAL INSTRUMENT Co., Ltd',
    symbol: '002450.KS',
  },
  {
    value: '002460.KS',
    label: '002460.KS,  Hwasung Industrial Co., Ltd.',
    name: 'Hwasung Industrial Co., Ltd.',
    symbol: '002460.KS',
  },
  {
    value: '002600.KS',
    label: '002600.KS,  Choheung Corporation',
    name: 'Choheung Corporation',
    symbol: '002600.KS',
  },
  {
    value: '002620.KS',
    label: '002620.KS,  Jeil Pharma Holdings Inc',
    name: 'Jeil Pharma Holdings Inc',
    symbol: '002620.KS',
  },
  {
    value: '002690.KS',
    label: '002690.KS,  Dong Il Steel Mfg Co.,Ltd',
    name: 'Dong Il Steel Mfg Co.,Ltd',
    symbol: '002690.KS',
  },
  {
    value: '002700.KS',
    label: '002700.KS,  Shinil Electronics Co.,Ltd.',
    name: 'Shinil Electronics Co.,Ltd.',
    symbol: '002700.KS',
  },
  {
    value: '002710.KS',
    label: '002710.KS,  TCC Steel Corp.',
    name: 'TCC Steel Corp.',
    symbol: '002710.KS',
  },
  {
    value: '002720.KS',
    label: '002720.KS,  Kukje Pharma Co., Ltd.',
    name: 'Kukje Pharma Co., Ltd.',
    symbol: '002720.KS',
  },
  {
    value: '002760.KS',
    label: '002760.KS,  Bolak Company Limited',
    name: 'Bolak Company Limited',
    symbol: '002760.KS',
  },
  {
    value: '002780.KS',
    label: '002780.KS,  ChinHung International, Inc.',
    name: 'ChinHung International, Inc.',
    symbol: '002780.KS',
  },
  {
    value: '002785.KS',
    label: '002785.KS,  ChinHung International, Inc.',
    name: 'ChinHung International, Inc.',
    symbol: '002785.KS',
  },
  {
    value: '002787.KS',
    label: '002787.KS,  ChinHung International, Inc.',
    name: 'ChinHung International, Inc.',
    symbol: '002787.KS',
  },
  {
    value: '002795.KS',
    label: '002795.KS,  AMOREPACIFIC Group',
    name: 'AMOREPACIFIC Group',
    symbol: '002795.KS',
  },
  {
    value: '00279K.KS',
    label: '00279K.KS,  AMOREPACIFIC Group',
    name: 'AMOREPACIFIC Group',
    symbol: '00279K.KS',
  },
  {
    value: '002820.KS',
    label: '002820.KS,  SUN&L Co., Ltd.',
    name: 'SUN&L Co., Ltd.',
    symbol: '002820.KS',
  },
  {
    value: '002870.KS',
    label: '002870.KS,  Shinpoong Paper Mfg. Co., Ltd',
    name: 'Shinpoong Paper Mfg. Co., Ltd',
    symbol: '002870.KS',
  },
  {
    value: '002880.KS',
    label: '002880.KS,  Dayou A-Tech Co.,Ltd',
    name: 'Dayou A-Tech Co.,Ltd',
    symbol: '002880.KS',
  },
  {
    value: '002900.KS',
    label: '002900.KS,  TYM Corporation',
    name: 'TYM Corporation',
    symbol: '002900.KS',
  },
  {
    value: '002920.KS',
    label: '002920.KS,  Yoosung Enterprise Co., Ltd.',
    name: 'Yoosung Enterprise Co., Ltd.',
    symbol: '002920.KS',
  },
  {
    value: '002995.KS',
    label: '002995.KS,  KUMHO Engineering & Construction Co., Ltd.',
    name: 'KUMHO Engineering & Construction Co., Ltd.',
    symbol: '002995.KS',
  },
  {
    value: '003010.KS',
    label: '003010.KS,  Haein Corporation',
    name: 'Haein Corporation',
    symbol: '003010.KS',
  },
  {
    value: '003070.KS',
    label: '003070.KS,  Kolon Global Corporation',
    name: 'Kolon Global Corporation',
    symbol: '003070.KS',
  },
  {
    value: '003075.KS',
    label: '003075.KS,  Kolon Global Corporation',
    name: 'Kolon Global Corporation',
    symbol: '003075.KS',
  },
  {
    value: '003080.KS',
    label: '003080.KS,  Sung Bo Chemicals Co., LTD',
    name: 'Sung Bo Chemicals Co., LTD',
    symbol: '003080.KS',
  },
  {
    value: '003120.KS',
    label: '003120.KS,  Ilsung Pharmaceuticals Co., Ltd.',
    name: 'Ilsung Pharmaceuticals Co., Ltd.',
    symbol: '003120.KS',
  },
  {
    value: '003160.KS',
    label: '003160.KS,  D.I Corporation',
    name: 'D.I Corporation',
    symbol: '003160.KS',
  },
  {
    value: '003200.KS',
    label: '003200.KS,  Ilshin Spinning Co.,Ltd',
    name: 'Ilshin Spinning Co.,Ltd',
    symbol: '003200.KS',
  },
  {
    value: '003300.KS',
    label: '003300.KS,  Hanil Holdings Co., Ltd.',
    name: 'Hanil Holdings Co., Ltd.',
    symbol: '003300.KS',
  },
  {
    value: '003350.KS',
    label: '003350.KS,  Hankook Cosmetics Manufacturing Co., Ltd',
    name: 'Hankook Cosmetics Manufacturing Co., Ltd',
    symbol: '003350.KS',
  },
  {
    value: '003460.KS',
    label: '003460.KS,  Yuhwa Securities co.,ltd.',
    name: 'Yuhwa Securities co.,ltd.',
    symbol: '003460.KS',
  },
  {
    value: '003465.KS',
    label: '003465.KS,  Yuhwa Securities co.,ltd.',
    name: 'Yuhwa Securities co.,ltd.',
    symbol: '003465.KS',
  },
  {
    value: '003475.KS',
    label: '003475.KS,  Yuanta Securities Korea Co., Ltd.',
    name: 'Yuanta Securities Korea Co., Ltd.',
    symbol: '003475.KS',
  },
  {
    value: '003480.KS',
    label:
      '003480.KS,  Hanjin Heavy Industries & Construction Holdings Co., Ltd.',
    name: 'Hanjin Heavy Industries & Construction Holdings Co., Ltd.',
    symbol: '003480.KS',
  },
  {
    value: '003495.KS',
    label: '003495.KS,  Korean Air Lines Co., Ltd.',
    name: 'Korean Air Lines Co., Ltd.',
    symbol: '003495.KS',
  },
  {
    value: '003535.KS',
    label: '003535.KS,  Hanwha Investment & Securities Co., Ltd.',
    name: 'Hanwha Investment & Securities Co., Ltd.',
    symbol: '003535.KS',
  },
  {
    value: '003547.KS',
    label: '003547.KS,  Daishin Securities Co., Ltd.',
    name: 'Daishin Securities Co., Ltd.',
    symbol: '003547.KS',
  },
  {
    value: '003555.KS',
    label: '003555.KS,  LG Corp.',
    name: 'LG Corp.',
    symbol: '003555.KS',
  },
  {
    value: '003560.KS',
    label: '003560.KS,  IHQ Inc.',
    name: 'IHQ Inc.',
    symbol: '003560.KS',
  },
  {
    value: '003610.KS',
    label: '003610.KS,  Pangrim Co., Ltd.',
    name: 'Pangrim Co., Ltd.',
    symbol: '003610.KS',
  },
  {
    value: '003650.KS',
    label: '003650.KS,  Michang Oil Ind  .Co.,Ltd.',
    name: 'Michang Oil Ind  .Co.,Ltd.',
    symbol: '003650.KS',
  },
  {
    value: '003680.KS',
    label: '003680.KS,  Hansung Enterprise Co.,Ltd',
    name: 'Hansung Enterprise Co.,Ltd',
    symbol: '003680.KS',
  },
  {
    value: '003720.KS',
    label: '003720.KS,  SamYoung Chemical Co.,Ltd',
    name: 'SamYoung Chemical Co.,Ltd',
    symbol: '003720.KS',
  },
  {
    value: '003780.KS',
    label: '003780.KS,  Chin Yang Industry Co., Ltd.',
    name: 'Chin Yang Industry Co., Ltd.',
    symbol: '003780.KS',
  },
  {
    value: '003830.KS',
    label: '003830.KS,  Daehan Synthetic Fiber Co., Ltd.',
    name: 'Daehan Synthetic Fiber Co., Ltd.',
    symbol: '003830.KS',
  },
  {
    value: '003920.KS',
    label: '003920.KS,  Namyang Dairy Products Co., Ltd',
    name: 'Namyang Dairy Products Co., Ltd',
    symbol: '003920.KS',
  },
  {
    value: '003925.KS',
    label: '003925.KS,  Namyang Dairy Products Co., Ltd',
    name: 'Namyang Dairy Products Co., Ltd',
    symbol: '003925.KS',
  },
  {
    value: '003960.KS',
    label: '003960.KS,  Sajodaerim Corporation',
    name: 'Sajodaerim Corporation',
    symbol: '003960.KS',
  },
  {
    value: '004060.KS',
    label: '004060.KS,  SG Corporation',
    name: 'SG Corporation',
    symbol: '004060.KS',
  },
  {
    value: '004080.KS',
    label: '004080.KS,  Shinhung Co., Ltd',
    name: 'Shinhung Co., Ltd',
    symbol: '004080.KS',
  },
  {
    value: '004090.KS',
    label: '004090.KS,  Korea Petroleum Industries Company',
    name: 'Korea Petroleum Industries Company',
    symbol: '004090.KS',
  },
  {
    value: '004100.KS',
    label: '004100.KS,  Taeyang Metal Industrial Co., Ltd.',
    name: 'Taeyang Metal Industrial Co., Ltd.',
    symbol: '004100.KS',
  },
  {
    value: '004105.KS',
    label: '004105.KS,  Taeyang Metal Industrial Co., Ltd.',
    name: 'Taeyang Metal Industrial Co., Ltd.',
    symbol: '004105.KS',
  },
  {
    value: '004140.KS',
    label: '004140.KS,  Dongbang Transport Logistics Co., Ltd.',
    name: 'Dongbang Transport Logistics Co., Ltd.',
    symbol: '004140.KS',
  },
  {
    value: '004250.KS',
    label: '004250.KS,  NPC Co., Ltd.',
    name: 'NPC Co., Ltd.',
    symbol: '004250.KS',
  },
  {
    value: '004255.KS',
    label: '004255.KS,  NPC Co., Ltd.',
    name: 'NPC Co., Ltd.',
    symbol: '004255.KS',
  },
  {
    value: '004270.KS',
    label: '004270.KS,  Namsung Corp.',
    name: 'Namsung Corp.',
    symbol: '004270.KS',
  },
  {
    value: '004310.KS',
    label: '004310.KS,  Hyundai Pharmaceutical Co., Ltd.',
    name: 'Hyundai Pharmaceutical Co., Ltd.',
    symbol: '004310.KS',
  },
  {
    value: '004360.KS',
    label: '004360.KS,  Sebang Co., Ltd',
    name: 'Sebang Co., Ltd',
    symbol: '004360.KS',
  },
  {
    value: '004365.KS',
    label: '004365.KS,  Sebang Co., Ltd',
    name: 'Sebang Co., Ltd',
    symbol: '004365.KS',
  },
  {
    value: '004410.KS',
    label: '004410.KS,  Seoul Food Industrial.Co.,Ltd',
    name: 'Seoul Food Industrial.Co.,Ltd',
    symbol: '004410.KS',
  },
  {
    value: '004415.KS',
    label: '004415.KS,  Seoul Food Industrial.Co.,Ltd',
    name: 'Seoul Food Industrial.Co.,Ltd',
    symbol: '004415.KS',
  },
  {
    value: '004440.KS',
    label: '004440.KS,  Samil C&S Co.,Ltd',
    name: 'Samil C&S Co.,Ltd',
    symbol: '004440.KS',
  },
  {
    value: '004450.KS',
    label: '004450.KS,  Samhwa Crown & Closure Co., Ltd',
    name: 'Samhwa Crown & Closure Co., Ltd',
    symbol: '004450.KS',
  },
  {
    value: '004540.KS',
    label: '004540.KS,  KleanNara Co., Ltd.',
    name: 'KleanNara Co., Ltd.',
    symbol: '004540.KS',
  },
  {
    value: '004545.KS',
    label: '004545.KS,  KleanNara Co., Ltd.',
    name: 'KleanNara Co., Ltd.',
    symbol: '004545.KS',
  },
  {
    value: '004560.KS',
    label: '004560.KS,  Hyundai Bng Steel Co., Ltd.',
    name: 'Hyundai Bng Steel Co., Ltd.',
    symbol: '004560.KS',
  },
  {
    value: '004565.KS',
    label: '004565.KS,  Hyundai Bng Steel Co., Ltd.',
    name: 'Hyundai Bng Steel Co., Ltd.',
    symbol: '004565.KS',
  },
  {
    value: '004690.KS',
    label: '004690.KS,  Samchully Co.,Ltd',
    name: 'Samchully Co.,Ltd',
    symbol: '004690.KS',
  },
  {
    value: '004700.KS',
    label: '004700.KS,  Chokwang Leather Co.,Ltd.',
    name: 'Chokwang Leather Co.,Ltd.',
    symbol: '004700.KS',
  },
  {
    value: '004720.KS',
    label: '004720.KS,  PharmGen Science, Inc.',
    name: 'PharmGen Science, Inc.',
    symbol: '004720.KS',
  },
  {
    value: '004770.KS',
    label: '004770.KS,  Sunny Electronics Corp.',
    name: 'Sunny Electronics Corp.',
    symbol: '004770.KS',
  },
  {
    value: '004835.KS',
    label: '004835.KS,  Duksung Co., Ltd.',
    name: 'Duksung Co., Ltd.',
    symbol: '004835.KS',
  },
  {
    value: '004840.KS',
    label: '004840.KS,  DRB Holding Co., Ltd.',
    name: 'DRB Holding Co., Ltd.',
    symbol: '004840.KS',
  },
  {
    value: '004870.KS',
    label: '004870.KS,  Tway Holdings Incorporation',
    name: 'Tway Holdings Incorporation',
    symbol: '004870.KS',
  },
  {
    value: '004890.KS',
    label: '004890.KS,  Dongil Industries Co.,Ltd.',
    name: 'Dongil Industries Co.,Ltd.',
    symbol: '004890.KS',
  },
  {
    value: '004910.KS',
    label: '004910.KS,  Chokwang Paint Ltd.',
    name: 'Chokwang Paint Ltd.',
    symbol: '004910.KS',
  },
  {
    value: '004920.KS',
    label: '004920.KS,  CITECH Co., Ltd.',
    name: 'CITECH Co., Ltd.',
    symbol: '004920.KS',
  },
  {
    value: '004960.KS',
    label: '004960.KS,  HANSHIN Engineering & Construction Co., Ltd.',
    name: 'HANSHIN Engineering & Construction Co., Ltd.',
    symbol: '004960.KS',
  },
  {
    value: '004970.KS',
    label: '004970.KS,  Silla Co.,Ltd',
    name: 'Silla Co.,Ltd',
    symbol: '004970.KS',
  },
  {
    value: '004985.KS',
    label: '004985.KS,  Sungshin Cement Co., Ltd',
    name: 'Sungshin Cement Co., Ltd',
    symbol: '004985.KS',
  },
  {
    value: '00499K.KS',
    label: '00499K.KS,  LOTTE Corporation',
    name: 'LOTTE Corporation',
    symbol: '00499K.KS',
  },
  {
    value: '005010.KS',
    label: '005010.KS,  Husteel Co., Ltd.',
    name: 'Husteel Co., Ltd.',
    symbol: '005010.KS',
  },
  {
    value: '005030.KS',
    label: '005030.KS,  Pusan Cast Iron Co., Ltd.',
    name: 'Pusan Cast Iron Co., Ltd.',
    symbol: '005030.KS',
  },
  {
    value: '005110.KS',
    label: '005110.KS,  Hanchang Corporation',
    name: 'Hanchang Corporation',
    symbol: '005110.KS',
  },
  {
    value: '005257.KS',
    label: '005257.KS,  Green Cross Holdings Corporation',
    name: 'Green Cross Holdings Corporation',
    symbol: '005257.KS',
  },
  {
    value: '005305.KS',
    label: '005305.KS,  Lotte Chilsung Beverage Co., Ltd.',
    name: 'Lotte Chilsung Beverage Co., Ltd.',
    symbol: '005305.KS',
  },
  {
    value: '005320.KS',
    label: '005320.KS,  Kukdong Corporation',
    name: 'Kukdong Corporation',
    symbol: '005320.KS',
  },
  {
    value: '005360.KS',
    label: '005360.KS,  Monami Co., Ltd.',
    name: 'Monami Co., Ltd.',
    symbol: '005360.KS',
  },
  {
    value: '005389.KS',
    label: '005389.KS,  Hyundai Motor Company',
    name: 'Hyundai Motor Company',
    symbol: '005389.KS',
  },
  {
    value: '005390.KS',
    label: '005390.KS,  Shinsung Tongsang Co., Ltd.',
    name: 'Shinsung Tongsang Co., Ltd.',
    symbol: '005390.KS',
  },
  {
    value: '005430.KS',
    label: '005430.KS,  Korea Airport Service Co.,Ltd.',
    name: 'Korea Airport Service Co.,Ltd.',
    symbol: '005430.KS',
  },
  {
    value: '005680.KS',
    label: '005680.KS,  Samyoung Electronics Co., Ltd',
    name: 'Samyoung Electronics Co., Ltd',
    symbol: '005680.KS',
  },
  {
    value: '005720.KS',
    label: '005720.KS,  Nexen Corporation',
    name: 'Nexen Corporation',
    symbol: '005720.KS',
  },
  {
    value: '005725.KS',
    label: '005725.KS,  Nexen Corporation',
    name: 'Nexen Corporation',
    symbol: '005725.KS',
  },
  {
    value: '005740.KS',
    label: '005740.KS,  CROWNHAITAI Holdings Co.,Ltd.',
    name: 'CROWNHAITAI Holdings Co.,Ltd.',
    symbol: '005740.KS',
  },
  {
    value: '005745.KS',
    label: '005745.KS,  CROWNHAITAI Holdings Co.,Ltd.',
    name: 'CROWNHAITAI Holdings Co.,Ltd.',
    symbol: '005745.KS',
  },
  {
    value: '005750.KS',
    label: '005750.KS,  Daelim B&Co Co.,Ltd.',
    name: 'Daelim B&Co Co.,Ltd.',
    symbol: '005750.KS',
  },
  {
    value: '005800.KS',
    label: '005800.KS,  Shinyoungwacoal,Inc.',
    name: 'Shinyoungwacoal,Inc.',
    symbol: '005800.KS',
  },
  {
    value: '005810.KS',
    label: '005810.KS,  Poongsan Holdings Corporation',
    name: 'Poongsan Holdings Corporation',
    symbol: '005810.KS',
  },
  {
    value: '005820.KS',
    label: '005820.KS,  Wonlim Corporation',
    name: 'Wonlim Corporation',
    symbol: '005820.KS',
  },
  {
    value: '005870.KS',
    label: '005870.KS,  Huneed Technologies',
    name: 'Huneed Technologies',
    symbol: '005870.KS',
  },
  {
    value: '005945.KS',
    label: '005945.KS,  NH Investment & Securities Co., Ltd.',
    name: 'NH Investment & Securities Co., Ltd.',
    symbol: '005945.KS',
  },
  {
    value: '005950.KS',
    label: '005950.KS,  ISU Chemical Co., Ltd',
    name: 'ISU Chemical Co., Ltd',
    symbol: '005950.KS',
  },
  {
    value: '005960.KS',
    label: '005960.KS,  Dongbu Corporation',
    name: 'Dongbu Corporation',
    symbol: '005960.KS',
  },
  {
    value: '005965.KS',
    label: '005965.KS,  Dongbu Corporation',
    name: 'Dongbu Corporation',
    symbol: '005965.KS',
  },
  {
    value: '006090.KS',
    label: '006090.KS,  Oyang Corporation',
    name: 'Oyang Corporation',
    symbol: '006090.KS',
  },
  {
    value: '006110.KS',
    label: '006110.KS,  Sam-A Aluminium Company, Limited',
    name: 'Sam-A Aluminium Company, Limited',
    symbol: '006110.KS',
  },
  {
    value: '006125.KS',
    label: '006125.KS,  SK Discovery Co., Ltd.',
    name: 'SK Discovery Co., Ltd.',
    symbol: '006125.KS',
  },
  {
    value: '006200.KS',
    label: '006200.KS,  KEC Holdings Co., Ltd.',
    name: 'KEC Holdings Co., Ltd.',
    symbol: '006200.KS',
  },
  {
    value: '006220.KS',
    label: '006220.KS,  Jeju Bank',
    name: 'Jeju Bank',
    symbol: '006220.KS',
  },
  {
    value: '006340.KS',
    label: '006340.KS,  Daewon Cable. Co., Ltd.',
    name: 'Daewon Cable. Co., Ltd.',
    symbol: '006340.KS',
  },
  {
    value: '006345.KS',
    label: '006345.KS,  Daewon Cable. Co., Ltd.',
    name: 'Daewon Cable. Co., Ltd.',
    symbol: '006345.KS',
  },
  {
    value: '006370.KS',
    label: '006370.KS,  Daegu Department Store Co., Ltd.',
    name: 'Daegu Department Store Co., Ltd.',
    symbol: '006370.KS',
  },
  {
    value: '006380.KS',
    label: '006380.KS,  Capro Corporation',
    name: 'Capro Corporation',
    symbol: '006380.KS',
  },
  {
    value: '006390.KS',
    label: '006390.KS,  Hanil Hyundai Cement Co., Ltd',
    name: 'Hanil Hyundai Cement Co., Ltd',
    symbol: '006390.KS',
  },
  {
    value: '006570.KS',
    label: '006570.KS,  Daelim Trading Co., Ltd.',
    name: 'Daelim Trading Co., Ltd.',
    symbol: '006570.KS',
  },
  {
    value: '006740.KS',
    label: '006740.KS,  Young Poong Paper Mfg Co.,Ltd.',
    name: 'Young Poong Paper Mfg Co.,Ltd.',
    symbol: '006740.KS',
  },
  {
    value: '006805.KS',
    label: '006805.KS,  Mirae Asset Securities Co. Ltd.',
    name: 'Mirae Asset Securities Co. Ltd.',
    symbol: '006805.KS',
  },
  {
    value: '006880.KS',
    label: '006880.KS,  Singsong Holdings Co.,Ltd.',
    name: 'Singsong Holdings Co.,Ltd.',
    symbol: '006880.KS',
  },
  {
    value: '006890.KS',
    label: '006890.KS,  Taekyung Chemical Co., Ltd.',
    name: 'Taekyung Chemical Co., Ltd.',
    symbol: '006890.KS',
  },
  {
    value: '006980.KS',
    label: '006980.KS,  Woosung Co., Ltd.',
    name: 'Woosung Co., Ltd.',
    symbol: '006980.KS',
  },
  {
    value: '007110.KS',
    label: '007110.KS,  Ilshinstone.Co.,Ltd.',
    name: 'Ilshinstone.Co.,Ltd.',
    symbol: '007110.KS',
  },
  {
    value: '007120.KS',
    label: '007120.KS,  MiraeING.Co.,Ltd.',
    name: 'MiraeING.Co.,Ltd.',
    symbol: '007120.KS',
  },
  {
    value: '007280.KS',
    label: '007280.KS,  Korea Steel Co., Ltd.',
    name: 'Korea Steel Co., Ltd.',
    symbol: '007280.KS',
  },
  {
    value: '007340.KS',
    label: '007340.KS,  DN Automotive Corporation',
    name: 'DN Automotive Corporation',
    symbol: '007340.KS',
  },
  {
    value: '007540.KS',
    label: '007540.KS,  Sempio Company',
    name: 'Sempio Company',
    symbol: '007540.KS',
  },
  {
    value: '007575.KS',
    label: '007575.KS,  Ilyang Pharmaceutical Co.,Ltd',
    name: 'Ilyang Pharmaceutical Co.,Ltd',
    symbol: '007575.KS',
  },
  {
    value: '007590.KS',
    label: '007590.KS,  DONGBANG AGRO Corporation',
    name: 'DONGBANG AGRO Corporation',
    symbol: '007590.KS',
  },
  {
    value: '007660.KS',
    label: '007660.KS,  ISU Petasys Co., Ltd.',
    name: 'ISU Petasys Co., Ltd.',
    symbol: '007660.KS',
  },
  {
    value: '007810.KS',
    label: '007810.KS,  Korea Circuit Co., Ltd.',
    name: 'Korea Circuit Co., Ltd.',
    symbol: '007810.KS',
  },
  {
    value: '007815.KS',
    label: '007815.KS,  Korea Circuit Co., Ltd.',
    name: 'Korea Circuit Co., Ltd.',
    symbol: '007815.KS',
  },
  {
    value: '00781K.KS',
    label: '00781K.KS,  Korea Circuit Co., Ltd.',
    name: 'Korea Circuit Co., Ltd.',
    symbol: '00781K.KS',
  },
  {
    value: '007980.KS',
    label: '007980.KS,  Pan-Pacific Co., Ltd.',
    name: 'Pan-Pacific Co., Ltd.',
    symbol: '007980.KS',
  },
  {
    value: '008040.KS',
    label: '008040.KS,  Sajodongaone Co.,Ltd',
    name: 'Sajodongaone Co.,Ltd',
    symbol: '008040.KS',
  },
  {
    value: '00806K.KS',
    label: '00806K.KS,  Daeduck Co.,Ltd.',
    name: 'Daeduck Co.,Ltd.',
    symbol: '00806K.KS',
  },
  {
    value: '008110.KS',
    label: '008110.KS,  Daidong Electronics Co. Ltd.',
    name: 'Daidong Electronics Co. Ltd.',
    symbol: '008110.KS',
  },
  {
    value: '008250.KS',
    label: '008250.KS,  Eagon Industrial Co., Ltd.',
    name: 'Eagon Industrial Co., Ltd.',
    symbol: '008250.KS',
  },
  {
    value: '008260.KS',
    label: '008260.KS,  Ni Steel Co.,Ltd',
    name: 'Ni Steel Co.,Ltd',
    symbol: '008260.KS',
  },
  {
    value: '008355.KS',
    label: '008355.KS,  Namsun Aluminum Co., Ltd.',
    name: 'Namsun Aluminum Co., Ltd.',
    symbol: '008355.KS',
  },
  {
    value: '008420.KS',
    label: '008420.KS,  Moonbaesteel Co.,Ltd',
    name: 'Moonbaesteel Co.,Ltd',
    symbol: '008420.KS',
  },
  {
    value: '008500.KS',
    label: '008500.KS,  Iljeong Industrial Co.,Ltd',
    name: 'Iljeong Industrial Co.,Ltd',
    symbol: '008500.KS',
  },
  {
    value: '008600.KS',
    label: '008600.KS,  The Willbes & CO., Ltd.',
    name: 'The Willbes & CO., Ltd.',
    symbol: '008600.KS',
  },
  {
    value: '008775.KS',
    label: '008775.KS,  Hotel Shilla Co.,Ltd',
    name: 'Hotel Shilla Co.,Ltd',
    symbol: '008775.KS',
  },
  {
    value: '008870.KS',
    label: '008870.KS,  Kumbi Corporation',
    name: 'Kumbi Corporation',
    symbol: '008870.KS',
  },
  {
    value: '009070.KS',
    label: '009070.KS,  KCTC Co. Ltd',
    name: 'KCTC Co. Ltd',
    symbol: '009070.KS',
  },
  {
    value: '009140.KS',
    label: '009140.KS,  Kyungin Electronics Co., Ltd',
    name: 'Kyungin Electronics Co., Ltd',
    symbol: '009140.KS',
  },
  {
    value: '009160.KS',
    label: '009160.KS,  SIMPAC Inc.',
    name: 'SIMPAC Inc.',
    symbol: '009160.KS',
  },
  {
    value: '009180.KS',
    label: '009180.KS,  Hansol Logistics Co., Ltd.',
    name: 'Hansol Logistics Co., Ltd.',
    symbol: '009180.KS',
  },
  {
    value: '009190.KS',
    label: '009190.KS,  Daiyang Metal Co., Ltd.',
    name: 'Daiyang Metal Co., Ltd.',
    symbol: '009190.KS',
  },
  {
    value: '009200.KS',
    label: '009200.KS,  Moorim Paper Co., Ltd.',
    name: 'Moorim Paper Co., Ltd.',
    symbol: '009200.KS',
  },
  {
    value: '009270.KS',
    label: '009270.KS,  ShinWon Corporation',
    name: 'ShinWon Corporation',
    symbol: '009270.KS',
  },
  {
    value: '009310.KS',
    label: '009310.KS,  Charm Engineering Co.,Ltd.',
    name: 'Charm Engineering Co.,Ltd.',
    symbol: '009310.KS',
  },
  {
    value: '009320.KS',
    label: '009320.KS,  Daewoo Electronic Components Co., Ltd.',
    name: 'Daewoo Electronic Components Co., Ltd.',
    symbol: '009320.KS',
  },
  {
    value: '009415.KS',
    label: '009415.KS,  Taeyoung Engineering & Construction Co.,Ltd.',
    name: 'Taeyoung Engineering & Construction Co.,Ltd.',
    symbol: '009415.KS',
  },
  {
    value: '009440.KS',
    label: '009440.KS,  KC Green Holdings Co., Ltd.',
    name: 'KC Green Holdings Co., Ltd.',
    symbol: '009440.KS',
  },
  {
    value: '009460.KS',
    label: '009460.KS,  Hanchangpaper co., Ltd',
    name: 'Hanchangpaper co., Ltd',
    symbol: '009460.KS',
  },
  {
    value: '009470.KS',
    label: '009470.KS,  Samwha Electric Co.,Ltd.',
    name: 'Samwha Electric Co.,Ltd.',
    symbol: '009470.KS',
  },
  {
    value: '009580.KS',
    label: '009580.KS,  Moorim P&P Co., Ltd.',
    name: 'Moorim P&P Co., Ltd.',
    symbol: '009580.KS',
  },
  {
    value: '009680.KS',
    label: '009680.KS,  Motonic Corporation',
    name: 'Motonic Corporation',
    symbol: '009680.KS',
  },
  {
    value: '009770.KS',
    label: '009770.KS,  Sam Jung Pulp Co.,Ltd.',
    name: 'Sam Jung Pulp Co.,Ltd.',
    symbol: '009770.KS',
  },
  {
    value: '009810.KS',
    label: '009810.KS,  Playgram Co., Ltd.',
    name: 'Playgram Co., Ltd.',
    symbol: '009810.KS',
  },
  {
    value: '009835.KS',
    label: '009835.KS,  Hanwha Solutions Corporation',
    name: 'Hanwha Solutions Corporation',
    symbol: '009835.KS',
  },
  {
    value: '010040.KS',
    label: '010040.KS,  Korea Refractories Co., Ltd',
    name: 'Korea Refractories Co., Ltd',
    symbol: '010040.KS',
  },
  {
    value: '010100.KS',
    label: '010100.KS,  Korea Flange Co., Ltd',
    name: 'Korea Flange Co., Ltd',
    symbol: '010100.KS',
  },
  {
    value: '010145.KS',
    label: '010145.KS,  Samsung Heavy Industries Co., Ltd.',
    name: 'Samsung Heavy Industries Co., Ltd.',
    symbol: '010145.KS',
  },
  {
    value: '010400.KS',
    label: '010400.KS,  Woojin I&S Co., Ltd.',
    name: 'Woojin I&S Co., Ltd.',
    symbol: '010400.KS',
  },
  {
    value: '010420.KS',
    label: '010420.KS,  Hansol PNS Co.,Ltd.',
    name: 'Hansol PNS Co.,Ltd.',
    symbol: '010420.KS',
  },
  {
    value: '010640.KS',
    label: '010640.KS,  Chinyang Poly Urethane Co.,Ltd',
    name: 'Chinyang Poly Urethane Co.,Ltd',
    symbol: '010640.KS',
  },
  {
    value: '010660.KS',
    label: '010660.KS,  Hwacheon Machinery Co., Ltd.',
    name: 'Hwacheon Machinery Co., Ltd.',
    symbol: '010660.KS',
  },
  {
    value: '010690.KS',
    label: '010690.KS,  HWASHIN CO.,Ltd',
    name: 'HWASHIN CO.,Ltd',
    symbol: '010690.KS',
  },
  {
    value: '010770.KS',
    label: '010770.KS,  Pyung Hwa Holdings Co., Ltd.',
    name: 'Pyung Hwa Holdings Co., Ltd.',
    symbol: '010770.KS',
  },
  {
    value: '010820.KS',
    label: '010820.KS,  FIRSTEC Co., Ltd',
    name: 'FIRSTEC Co., Ltd',
    symbol: '010820.KS',
  },
  {
    value: '010955.KS',
    label: '010955.KS,  S-Oil Corporation',
    name: 'S-Oil Corporation',
    symbol: '010955.KS',
  },
  {
    value: '010960.KS',
    label: '010960.KS,  Samho Development Co., LTD',
    name: 'Samho Development Co., LTD',
    symbol: '010960.KS',
  },
  {
    value: '011090.KS',
    label: '011090.KS,  ENEX Co.,LTD',
    name: 'ENEX Co.,LTD',
    symbol: '011090.KS',
  },
  {
    value: '011150.KS',
    label: '011150.KS,  CJ Seafood Corporation',
    name: 'CJ Seafood Corporation',
    symbol: '011150.KS',
  },
  {
    value: '011155.KS',
    label: '011155.KS,  CJ Seafood Corporation',
    name: 'CJ Seafood Corporation',
    symbol: '011155.KS',
  },
  {
    value: '011230.KS',
    label: '011230.KS,  Samwha Electronics Co.,Ltd.',
    name: 'Samwha Electronics Co.,Ltd.',
    symbol: '011230.KS',
  },
  {
    value: '011330.KS',
    label: '011330.KS,  Uni-Chem Co., Ltd.',
    name: 'Uni-Chem Co., Ltd.',
    symbol: '011330.KS',
  },
  {
    value: '011390.KS',
    label: '011390.KS,  Busan Industrial Co., Ltd.',
    name: 'Busan Industrial Co., Ltd.',
    symbol: '011390.KS',
  },
  {
    value: '011420.KS',
    label: '011420.KS,  Galaxia SM, Inc.',
    name: 'Galaxia SM, Inc.',
    symbol: '011420.KS',
  },
  {
    value: '011500.KS',
    label: '011500.KS,  Hannong Chemicals Inc.',
    name: 'Hannong Chemicals Inc.',
    symbol: '011500.KS',
  },
  {
    value: '011700.KS',
    label: '011700.KS,  Hanshin Machinery Co., Ltd.',
    name: 'Hanshin Machinery Co., Ltd.',
    symbol: '011700.KS',
  },
  {
    value: '011785.KS',
    label: '011785.KS,  Kumho Petrochemical Co., Ltd.',
    name: 'Kumho Petrochemical Co., Ltd.',
    symbol: '011785.KS',
  },
  {
    value: '011810.KS',
    label: '011810.KS,  STX Corporation',
    name: 'STX Corporation',
    symbol: '011810.KS',
  },
  {
    value: '012030.KS',
    label: '012030.KS,  DB Inc.',
    name: 'DB Inc.',
    symbol: '012030.KS',
  },
  {
    value: '012160.KS',
    label: '012160.KS,  Youngwire Co., Ltd.',
    name: 'Youngwire Co., Ltd.',
    symbol: '012160.KS',
  },
  {
    value: '012200.KS',
    label: '012200.KS,  Keyang Electric Machinery Co., Ltd.',
    name: 'Keyang Electric Machinery Co., Ltd.',
    symbol: '012200.KS',
  },
  {
    value: '012205.KS',
    label: '012205.KS,  Keyang Electric Machinery Co., Ltd.',
    name: 'Keyang Electric Machinery Co., Ltd.',
    symbol: '012205.KS',
  },
  {
    value: '012280.KS',
    label: '012280.KS,  Yeong Hwa Metal Co., Ltd.',
    name: 'Yeong Hwa Metal Co., Ltd.',
    symbol: '012280.KS',
  },
  {
    value: '012320.KS',
    label: '012320.KS,  Kyungdong Invest Co., Ltd',
    name: 'Kyungdong Invest Co., Ltd',
    symbol: '012320.KS',
  },
  {
    value: '012610.KS',
    label: '012610.KS,  Kyungin Synthetic Co., Ltd.',
    name: 'Kyungin Synthetic Co., Ltd.',
    symbol: '012610.KS',
  },
  {
    value: '012690.KS',
    label: '012690.KS,  Monalisa Co., Ltd',
    name: 'Monalisa Co., Ltd',
    symbol: '012690.KS',
  },
  {
    value: '012800.KS',
    label: '012800.KS,  Daechang Co., Ltd.',
    name: 'Daechang Co., Ltd.',
    symbol: '012800.KS',
  },
  {
    value: '013360.KS',
    label: '013360.KS,  Ilsung Construction Co., Ltd.',
    name: 'Ilsung Construction Co., Ltd.',
    symbol: '013360.KS',
  },
  {
    value: '013520.KS',
    label: '013520.KS,  Hwaseung Corporation Co.,Ltd.',
    name: 'Hwaseung Corporation Co.,Ltd.',
    symbol: '013520.KS',
  },
  {
    value: '013570.KS',
    label: '013570.KS,  DY Corporation',
    name: 'DY Corporation',
    symbol: '013570.KS',
  },
  {
    value: '013580.KS',
    label: '013580.KS,  Kyeryong Construction Industrial Co., Ltd.',
    name: 'Kyeryong Construction Industrial Co., Ltd.',
    symbol: '013580.KS',
  },
  {
    value: '013700.KS',
    label: '013700.KS,  CAMUS ENGINEERING & CONSTRUCTION Inc.',
    name: 'CAMUS ENGINEERING & CONSTRUCTION Inc.',
    symbol: '013700.KS',
  },
  {
    value: '013870.KS',
    label: '013870.KS,  GMB Korea Corp.',
    name: 'GMB Korea Corp.',
    symbol: '013870.KS',
  },
  {
    value: '014130.KS',
    label: '014130.KS,  Hanexpress.Co., Ltd',
    name: 'Hanexpress.Co., Ltd',
    symbol: '014130.KS',
  },
  {
    value: '014160.KS',
    label: '014160.KS,  DAE YOUNG Packaging.Co., Ltd.',
    name: 'DAE YOUNG Packaging.Co., Ltd.',
    symbol: '014160.KS',
  },
  {
    value: '014280.KS',
    label: '014280.KS,  Kumkang Kind Co., Ltd.',
    name: 'Kumkang Kind Co., Ltd.',
    symbol: '014280.KS',
  },
  {
    value: '014285.KS',
    label: '014285.KS,  Kumkang Kind Co., Ltd.',
    name: 'Kumkang Kind Co., Ltd.',
    symbol: '014285.KS',
  },
  {
    value: '014440.KS',
    label: '014440.KS,  Youngbo Chemical Co., Ltd.',
    name: 'Youngbo Chemical Co., Ltd.',
    symbol: '014440.KS',
  },
  {
    value: '014530.KS',
    label: '014530.KS,  Kukdong Oil & Chemicals Co.,Ltd',
    name: 'Kukdong Oil & Chemicals Co.,Ltd',
    symbol: '014530.KS',
  },
  {
    value: '014580.KS',
    label: '014580.KS,  Taekyung Bk Co., Ltd',
    name: 'Taekyung Bk Co., Ltd',
    symbol: '014580.KS',
  },
  {
    value: '014710.KS',
    label: '014710.KS,  SAJO SEAFOOD Co.,Ltd',
    name: 'SAJO SEAFOOD Co.,Ltd',
    symbol: '014710.KS',
  },
  {
    value: '014790.KS',
    label: '014790.KS,  HL D&I Halla Corporation',
    name: 'HL D&I Halla Corporation',
    symbol: '014790.KS',
  },
  {
    value: '014825.KS',
    label: '014825.KS,  Dongwon Systems Corporation',
    name: 'Dongwon Systems Corporation',
    symbol: '014825.KS',
  },
  {
    value: '014910.KS',
    label: '014910.KS,  Sungmoon Electronics Co., Ltd.',
    name: 'Sungmoon Electronics Co., Ltd.',
    symbol: '014910.KS',
  },
  {
    value: '014915.KS',
    label: '014915.KS,  Sungmoon Electronics Co., Ltd.',
    name: 'Sungmoon Electronics Co., Ltd.',
    symbol: '014915.KS',
  },
  {
    value: '014990.KS',
    label: '014990.KS,  In the F CO.,LTD.',
    name: 'In the F CO.,LTD.',
    symbol: '014990.KS',
  },
  {
    value: '015020.KS',
    label: '015020.KS,  E-Starco. Co. Ltd',
    name: 'E-Starco. Co. Ltd',
    symbol: '015020.KS',
  },
  {
    value: '015230.KS',
    label: '015230.KS,  Daechang Forging Co., Ltd.',
    name: 'Daechang Forging Co., Ltd.',
    symbol: '015230.KS',
  },
  {
    value: '015260.KS',
    label: '015260.KS,  Automobile & PCB Inc.',
    name: 'Automobile & PCB Inc.',
    symbol: '015260.KS',
  },
  {
    value: '015360.KS',
    label: '015360.KS,  Yesco Holdings Co., Ltd.',
    name: 'Yesco Holdings Co., Ltd.',
    symbol: '015360.KS',
  },
  {
    value: '015590.KS',
    label: '015590.KS,  Curo Co.,Ltd.',
    name: 'Curo Co.,Ltd.',
    symbol: '015590.KS',
  },
  {
    value: '015860.KS',
    label: '015860.KS,  ILJIN Holdings Co., Ltd.',
    name: 'ILJIN Holdings Co., Ltd.',
    symbol: '015860.KS',
  },
  {
    value: '015890.KS',
    label: '015890.KS,  Taekyung Industry.Co., Ltd.',
    name: 'Taekyung Industry.Co., Ltd.',
    symbol: '015890.KS',
  },
  {
    value: '016090.KS',
    label: '016090.KS,  Daehyun Co.,Ltd.',
    name: 'Daehyun Co.,Ltd.',
    symbol: '016090.KS',
  },
  {
    value: '016450.KS',
    label: '016450.KS,  Hansae Yes24 Holdings Co., Ltd',
    name: 'Hansae Yes24 Holdings Co., Ltd',
    symbol: '016450.KS',
  },
  {
    value: '016590.KS',
    label: '016590.KS,  Shindaeyang Paper Co., Ltd.',
    name: 'Shindaeyang Paper Co., Ltd.',
    symbol: '016590.KS',
  },
  {
    value: '016740.KS',
    label: '016740.KS,  DUAL Co., Ltd.',
    name: 'DUAL Co., Ltd.',
    symbol: '016740.KS',
  },
  {
    value: '016800.KS',
    label: '016800.KS,  Fursys Inc.',
    name: 'Fursys Inc.',
    symbol: '016800.KS',
  },
  {
    value: '016880.KS',
    label: '016880.KS,  Woongjin Co., Ltd.',
    name: 'Woongjin Co., Ltd.',
    symbol: '016880.KS',
  },
  {
    value: '017040.KS',
    label: '017040.KS,  Kwang Myung Electric Co.,Ltd',
    name: 'Kwang Myung Electric Co.,Ltd',
    symbol: '017040.KS',
  },
  {
    value: '017180.KS',
    label: '017180.KS,  MYUNGMOON Pharm co.,Ltd',
    name: 'MYUNGMOON Pharm co.,Ltd',
    symbol: '017180.KS',
  },
  {
    value: '017370.KS',
    label: '017370.KS,  Wooshin Systems Co., Ltd.',
    name: 'Wooshin Systems Co., Ltd.',
    symbol: '017370.KS',
  },
  {
    value: '017390.KS',
    label: '017390.KS,  Seoul City Gas Co., Ltd.',
    name: 'Seoul City Gas Co., Ltd.',
    symbol: '017390.KS',
  },
  {
    value: '017550.KS',
    label: '017550.KS,  Soosan Heavy Industries Co., Ltd.',
    name: 'Soosan Heavy Industries Co., Ltd.',
    symbol: '017550.KS',
  },
  {
    value: '017900.KS',
    label: '017900.KS,  AUK Corp.',
    name: 'AUK Corp.',
    symbol: '017900.KS',
  },
  {
    value: '017960.KS',
    label: '017960.KS,  Hankuk Carbon Co., Ltd.',
    name: 'Hankuk Carbon Co., Ltd.',
    symbol: '017960.KS',
  },
  {
    value: '018470.KS',
    label: '018470.KS,  Choil Aluminum Co., Ltd.',
    name: 'Choil Aluminum Co., Ltd.',
    symbol: '018470.KS',
  },
  {
    value: '018500.KS',
    label: '018500.KS,  Dongwon Metal Co., Ltd.',
    name: 'Dongwon Metal Co., Ltd.',
    symbol: '018500.KS',
  },
  {
    value: '019175.KS',
    label: '019175.KS,  Shin Poong Pharm.Co.,Ltd.',
    name: 'Shin Poong Pharm.Co.,Ltd.',
    symbol: '019175.KS',
  },
  {
    value: '019180.KS',
    label: '019180.KS,  THN Corporation',
    name: 'THN Corporation',
    symbol: '019180.KS',
  },
  {
    value: '019440.KS',
    label: '019440.KS,  SeAH SPECIALSTEEL CO., LTD.',
    name: 'SeAH SPECIALSTEEL CO., LTD.',
    symbol: '019440.KS',
  },
  {
    value: '019685.KS',
    label: '019685.KS,  Daekyo Co., Ltd.',
    name: 'Daekyo Co., Ltd.',
    symbol: '019685.KS',
  },
  {
    value: '020120.KS',
    label: '020120.KS,  KidariStudio, Inc.',
    name: 'KidariStudio, Inc.',
    symbol: '020120.KS',
  },
  {
    value: '020760.KS',
    label: '020760.KS,  Iljin Display Co., Ltd.',
    name: 'Iljin Display Co., Ltd.',
    symbol: '020760.KS',
  },
  {
    value: '021050.KS',
    label: '021050.KS,  Seowon Co., Ltd.',
    name: 'Seowon Co., Ltd.',
    symbol: '021050.KS',
  },
  {
    value: '023000.KS',
    label: '023000.KS,  SAMWONSTEEL Co.,Ltd.',
    name: 'SAMWONSTEEL Co.,Ltd.',
    symbol: '023000.KS',
  },
  {
    value: '023150.KS',
    label: '023150.KS,  MH Ethanol Co.,Ltd.',
    name: 'MH Ethanol Co.,Ltd.',
    symbol: '023150.KS',
  },
  {
    value: '023350.KS',
    label: '023350.KS,  Korea Engineering Consultants Corp.',
    name: 'Korea Engineering Consultants Corp.',
    symbol: '023350.KS',
  },
  {
    value: '023450.KS',
    label: '023450.KS,  Dongnam Chemical Co., LTD.',
    name: 'Dongnam Chemical Co., LTD.',
    symbol: '023450.KS',
  },
  {
    value: '023800.KS',
    label: '023800.KS,  INZI Controls Co.,Ltd.',
    name: 'INZI Controls Co.,Ltd.',
    symbol: '023800.KS',
  },
  {
    value: '023810.KS',
    label: '023810.KS,  INFAC Corporation',
    name: 'INFAC Corporation',
    symbol: '023810.KS',
  },
  {
    value: '023960.KS',
    label: '023960.KS,  SC Engineering Co., Ltd',
    name: 'SC Engineering Co., Ltd',
    symbol: '023960.KS',
  },
  {
    value: '024070.KS',
    label: '024070.KS,  Wiscom Co.,Ltd.',
    name: 'Wiscom Co.,Ltd.',
    symbol: '024070.KS',
  },
  {
    value: '024090.KS',
    label: '024090.KS,  DCM Corp.',
    name: 'DCM Corp.',
    symbol: '024090.KS',
  },
  {
    value: '024890.KS',
    label: '024890.KS,  DAEWON Chemical Co., Ltd.',
    name: 'DAEWON Chemical Co., Ltd.',
    symbol: '024890.KS',
  },
  {
    value: '024900.KS',
    label: '024900.KS,  Duckyang Ind. Co., Ltd.',
    name: 'Duckyang Ind. Co., Ltd.',
    symbol: '024900.KS',
  },
  {
    value: '025000.KS',
    label: '025000.KS,  KPX Chemical Co.,Ltd.',
    name: 'KPX Chemical Co.,Ltd.',
    symbol: '025000.KS',
  },
  {
    value: '025530.KS',
    label: '025530.KS,  SJM Holdings Co.,Ltd.',
    name: 'SJM Holdings Co.,Ltd.',
    symbol: '025530.KS',
  },
  {
    value: '025560.KS',
    label: '025560.KS,  Mirae Corporation',
    name: 'Mirae Corporation',
    symbol: '025560.KS',
  },
  {
    value: '025620.KS',
    label: '025620.KS,  Jayjun Cosmetic Co., Ltd.',
    name: 'Jayjun Cosmetic Co., Ltd.',
    symbol: '025620.KS',
  },
  {
    value: '025750.KS',
    label: '025750.KS,  Hansol HomeDeco Co., Ltd.',
    name: 'Hansol HomeDeco Co., Ltd.',
    symbol: '025750.KS',
  },
  {
    value: '025820.KS',
    label: '025820.KS,  Leeku Industrial Co., Ltd.',
    name: 'Leeku Industrial Co., Ltd.',
    symbol: '025820.KS',
  },
  {
    value: '025890.KS',
    label: '025890.KS,  Hankook Steel Co., Ltd.',
    name: 'Hankook Steel Co., Ltd.',
    symbol: '025890.KS',
  },
  {
    value: '026940.KS',
    label: '026940.KS,  Bookook Steel Co., Ltd.',
    name: 'Bookook Steel Co., Ltd.',
    symbol: '026940.KS',
  },
  {
    value: '027740.KS',
    label: '027740.KS,  Maniker.Co.,Ltd',
    name: 'Maniker.Co.,Ltd',
    symbol: '027740.KS',
  },
  {
    value: '027970.KS',
    label: '027970.KS,  seha corporation',
    name: 'seha corporation',
    symbol: '027970.KS',
  },
  {
    value: '028100.KS',
    label: '028100.KS,  Dong-Ah Geological Engineering Company Ltd.',
    name: 'Dong-Ah Geological Engineering Company Ltd.',
    symbol: '028100.KS',
  },
  {
    value: '02826K.KS',
    label: '02826K.KS,  Samsung C&T Corporation',
    name: 'Samsung C&T Corporation',
    symbol: '02826K.KS',
  },
  {
    value: '029530.KS',
    label: '029530.KS,  sindoh Co.,Ltd.',
    name: 'sindoh Co.,Ltd.',
    symbol: '029530.KS',
  },
  {
    value: '030610.KS',
    label: '030610.KS,  Kyobo Securities Co ., Ltd',
    name: 'Kyobo Securities Co ., Ltd',
    symbol: '030610.KS',
  },
  {
    value: '030720.KS',
    label: '030720.KS,  Dong Won Fisheries Co., Ltd.',
    name: 'Dong Won Fisheries Co., Ltd.',
    symbol: '030720.KS',
  },
  {
    value: '031440.KS',
    label: '031440.KS,  SHINSEGAE FOOD Inc.',
    name: 'SHINSEGAE FOOD Inc.',
    symbol: '031440.KS',
  },
  {
    value: '031820.KS',
    label: '031820.KS,  Comtec Systems Co.,Ltd.',
    name: 'Comtec Systems Co.,Ltd.',
    symbol: '031820.KS',
  },
  {
    value: '032560.KS',
    label: '032560.KS,  Hwang Kum Steel & Technology Co., Ltd',
    name: 'Hwang Kum Steel & Technology Co., Ltd',
    symbol: '032560.KS',
  },
  {
    value: '033240.KS',
    label: '033240.KS,  Jahwa Electronics. Co., Ltd',
    name: 'Jahwa Electronics. Co., Ltd',
    symbol: '033240.KS',
  },
  {
    value: '033250.KS',
    label: '033250.KS,  CHASYS Co., Ltd.',
    name: 'CHASYS Co., Ltd.',
    symbol: '033250.KS',
  },
  {
    value: '033530.KS',
    label: '033530.KS,  Sejong Industrial Co., Ltd.',
    name: 'Sejong Industrial Co., Ltd.',
    symbol: '033530.KS',
  },
  {
    value: '034120.KS',
    label: '034120.KS,  Seoul Broadcasting System',
    name: 'Seoul Broadcasting System',
    symbol: '034120.KS',
  },
  {
    value: '034300.KS',
    label: '034300.KS,  Shinsegae Engineering & Construction Inc.',
    name: 'Shinsegae Engineering & Construction Inc.',
    symbol: '034300.KS',
  },
  {
    value: '034590.KS',
    label: '034590.KS,  Incheon City Gas Co., Ltd.',
    name: 'Incheon City Gas Co., Ltd.',
    symbol: '034590.KS',
  },
  {
    value: '03473K.KS',
    label: '03473K.KS,  SK Inc.',
    name: 'SK Inc.',
    symbol: '03473K.KS',
  },
  {
    value: '035000.KS',
    label: '035000.KS,  GIIR Inc.',
    name: 'GIIR Inc.',
    symbol: '035000.KS',
  },
  {
    value: '035150.KS',
    label: '035150.KS,  BAIKSAN Co,. Ltd',
    name: 'BAIKSAN Co,. Ltd',
    symbol: '035150.KS',
  },
  {
    value: '035510.KS',
    label: '035510.KS,  SHINSEGAE Information & Communication Co., LTD',
    name: 'SHINSEGAE Information & Communication Co., LTD',
    symbol: '035510.KS',
  },
  {
    value: '036530.KS',
    label: '036530.KS,  SNT Holdings Co., Ltd.',
    name: 'SNT Holdings Co., Ltd.',
    symbol: '036530.KS',
  },
  {
    value: '036580.KS',
    label: '036580.KS,  Farmsco',
    name: 'Farmsco',
    symbol: '036580.KS',
  },
  {
    value: '037270.KS',
    label: '037270.KS,  YG Plus, Inc.',
    name: 'YG Plus, Inc.',
    symbol: '037270.KS',
  },
  {
    value: '037710.KS',
    label: '037710.KS,  Gwangju Shinsegae. Co. ,Ltd.',
    name: 'Gwangju Shinsegae. Co. ,Ltd.',
    symbol: '037710.KS',
  },
  {
    value: '039570.KS',
    label: '039570.KS,  HDC I-Controls Co., Ltd.',
    name: 'HDC I-Controls Co., Ltd.',
    symbol: '039570.KS',
  },
  {
    value: '041650.KS',
    label: '041650.KS,  Sangsin Brake Co., Ltd.',
    name: 'Sangsin Brake Co., Ltd.',
    symbol: '041650.KS',
  },
  {
    value: '044380.KS',
    label: '044380.KS,  JOOYONTECH CO., Ltd',
    name: 'JOOYONTECH CO., Ltd',
    symbol: '044380.KS',
  },
  {
    value: '044450.KS',
    label: '044450.KS,  KSS Line Ltd.',
    name: 'KSS Line Ltd.',
    symbol: '044450.KS',
  },
  {
    value: '044820.KS',
    label: '044820.KS,  Cosmax BTI, Inc.',
    name: 'Cosmax BTI, Inc.',
    symbol: '044820.KS',
  },
  {
    value: '047400.KS',
    label: '047400.KS,  Union Materials Corp.',
    name: 'Union Materials Corp.',
    symbol: '047400.KS',
  },
  {
    value: '049800.KS',
    label: '049800.KS,  Woojin Plaimm Co., Ltd.',
    name: 'Woojin Plaimm Co., Ltd.',
    symbol: '049800.KS',
  },
  {
    value: '051630.KS',
    label: '051630.KS,  ChinYang Chemical Corporation',
    name: 'ChinYang Chemical Corporation',
    symbol: '051630.KS',
  },
  {
    value: '053690.KS',
    label: '053690.KS,  HanmiGlobal Co., Ltd.',
    name: 'HanmiGlobal Co., Ltd.',
    symbol: '053690.KS',
  },
  {
    value: '055490.KS',
    label: '055490.KS,  Tapex Co., Ltd.',
    name: 'Tapex Co., Ltd.',
    symbol: '055490.KS',
  },
  {
    value: '058430.KS',
    label: '058430.KS,  POSCO STEELEON Co., Ltd.',
    name: 'POSCO STEELEON Co., Ltd.',
    symbol: '058430.KS',
  },
  {
    value: '058650.KS',
    label: '058650.KS,  SeAH Holdings Corporation',
    name: 'SeAH Holdings Corporation',
    symbol: '058650.KS',
  },
  {
    value: '058730.KS',
    label: '058730.KS,  Development Advance Solution Co.,Ltd.',
    name: 'Development Advance Solution Co.,Ltd.',
    symbol: '058730.KS',
  },
  {
    value: '058850.KS',
    label: '058850.KS,  ktcs corporation',
    name: 'ktcs corporation',
    symbol: '058850.KS',
  },
  {
    value: '058860.KS',
    label: '058860.KS,  KTIS Corporation',
    name: 'KTIS Corporation',
    symbol: '058860.KS',
  },
  {
    value: '063160.KS',
    label: '063160.KS,  CKD Bio Corp.',
    name: 'CKD Bio Corp.',
    symbol: '063160.KS',
  },
  {
    value: '067830.KS',
    label: '067830.KS,  Savezone I&C Corporation',
    name: 'Savezone I&C Corporation',
    symbol: '067830.KS',
  },
  {
    value: '068290.KS',
    label: '068290.KS,  Samsung Publishing Co., Ltd',
    name: 'Samsung Publishing Co., Ltd',
    symbol: '068290.KS',
  },
  {
    value: '068400.KS',
    label: '068400.KS,  SK Rent A Car Co., Ltd',
    name: 'SK Rent A Car Co., Ltd',
    symbol: '068400.KS',
  },
  {
    value: '069460.KS',
    label: '069460.KS,  Daeho Al Co.,Ltd.',
    name: 'Daeho Al Co.,Ltd.',
    symbol: '069460.KS',
  },
  {
    value: '069640.KS',
    label: '069640.KS,  Hansaemk Co.,Ltd.',
    name: 'Hansaemk Co.,Ltd.',
    symbol: '069640.KS',
  },
  {
    value: '069730.KS',
    label: '069730.KS,  DSR Wire Corp',
    name: 'DSR Wire Corp',
    symbol: '069730.KS',
  },
  {
    value: '070960.KS',
    label: '070960.KS,  HJ Magnolia Yongpyong Hotel & Resort',
    name: 'HJ Magnolia Yongpyong Hotel & Resort',
    symbol: '070960.KS',
  },
  {
    value: '071055.KS',
    label: '071055.KS,  Korea Investment Holdings Co., Ltd.',
    name: 'Korea Investment Holdings Co., Ltd.',
    symbol: '071055.KS',
  },
  {
    value: '071090.KS',
    label: '071090.KS,  Histeel Co.,Ltd.',
    name: 'Histeel Co.,Ltd.',
    symbol: '071090.KS',
  },
  {
    value: '071950.KS',
    label: '071950.KS,  KOAS Co., Ltd.',
    name: 'KOAS Co., Ltd.',
    symbol: '071950.KS',
  },
  {
    value: '071970.KS',
    label: '071970.KS,  STX Heavy Industries Co., Ltd.',
    name: 'STX Heavy Industries Co., Ltd.',
    symbol: '071970.KS',
  },
  {
    value: '072130.KS',
    label: '072130.KS,  UANGEL Corporation',
    name: 'UANGEL Corporation',
    symbol: '072130.KS',
  },
  {
    value: '072710.KS',
    label: '072710.KS,  Nongshim Holdings Co.,Ltd.',
    name: 'Nongshim Holdings Co.,Ltd.',
    symbol: '072710.KS',
  },
  {
    value: '074610.KS',
    label: '074610.KS,  ENPLUS Co., Ltd.',
    name: 'ENPLUS Co., Ltd.',
    symbol: '074610.KS',
  },
  {
    value: '075180.KS',
    label: '075180.KS,  Saeron Automotive Corporation',
    name: 'Saeron Automotive Corporation',
    symbol: '075180.KS',
  },
  {
    value: '075580.KS',
    label: '075580.KS,  Sejin Heavy Industries Co., Ltd.',
    name: 'Sejin Heavy Industries Co., Ltd.',
    symbol: '075580.KS',
  },
  {
    value: '077500.KS',
    label: '077500.KS,  Uniquest Corporation',
    name: 'Uniquest Corporation',
    symbol: '077500.KS',
  },
  {
    value: '077970.KS',
    label: '077970.KS,  STX Engine Co.,Ltd.',
    name: 'STX Engine Co.,Ltd.',
    symbol: '077970.KS',
  },
  {
    value: '078000.KS',
    label: '078000.KS,  Telcoware Co.,Ltd.',
    name: 'Telcoware Co.,Ltd.',
    symbol: '078000.KS',
  },
  {
    value: '078935.KS',
    label: '078935.KS,  GS Holdings Corp.',
    name: 'GS Holdings Corp.',
    symbol: '078935.KS',
  },
  {
    value: '079980.KS',
    label: '079980.KS,  Huvis Corporation',
    name: 'Huvis Corporation',
    symbol: '079980.KS',
  },
  {
    value: '082740.KS',
    label: '082740.KS,  HSD Engine Co., Ltd.',
    name: 'HSD Engine Co., Ltd.',
    symbol: '082740.KS',
  },
  {
    value: '083420.KS',
    label: '083420.KS,  Green Chemical Co., Ltd.',
    name: 'Green Chemical Co., Ltd.',
    symbol: '083420.KS',
  },
  {
    value: '084010.KS',
    label: '084010.KS,  Daehan Steel Co., Ltd.',
    name: 'Daehan Steel Co., Ltd.',
    symbol: '084010.KS',
  },
  {
    value: '084670.KS',
    label: '084670.KS,  Dongyang Express Corp.',
    name: 'Dongyang Express Corp.',
    symbol: '084670.KS',
  },
  {
    value: '084680.KS',
    label: '084680.KS,  E-World Co.,Ltd.',
    name: 'E-World Co.,Ltd.',
    symbol: '084680.KS',
  },
  {
    value: '084695.KS',
    label: '084695.KS,  Daesang Holdings Co., Ltd.',
    name: 'Daesang Holdings Co., Ltd.',
    symbol: '084695.KS',
  },
  {
    value: '084870.KS',
    label: '084870.KS,  TBH Global Co., Ltd',
    name: 'TBH Global Co., Ltd',
    symbol: '084870.KS',
  },
  {
    value: '085310.KS',
    label: '085310.KS,  NK Co., Ltd.',
    name: 'NK Co., Ltd.',
    symbol: '085310.KS',
  },
  {
    value: '088260.KS',
    label: '088260.KS,  E Kocref Cr-Reit',
    name: 'E Kocref Cr-Reit',
    symbol: '088260.KS',
  },
  {
    value: '088790.KS',
    label: '088790.KS,  Jindo.Co., Ltd.',
    name: 'Jindo.Co., Ltd.',
    symbol: '088790.KS',
  },
  {
    value: '089470.KS',
    label: '089470.KS,  HDC Hyundai Engineering Plastics Co., Ltd.',
    name: 'HDC Hyundai Engineering Plastics Co., Ltd.',
    symbol: '089470.KS',
  },
  {
    value: '089860.KS',
    label: '089860.KS,  LOTTE rental co.,ltd.',
    name: 'LOTTE rental co.,ltd.',
    symbol: '089860.KS',
  },
  {
    value: '090080.KS',
    label: '090080.KS,  Pyung Hwa Industrial Co., Ltd.',
    name: 'Pyung Hwa Industrial Co., Ltd.',
    symbol: '090080.KS',
  },
  {
    value: '090350.KS',
    label: '090350.KS,  NOROO PAINT & COATINGS Co., Ltd.',
    name: 'NOROO PAINT & COATINGS Co., Ltd.',
    symbol: '090350.KS',
  },
  {
    value: '090355.KS',
    label: '090355.KS,  NOROO PAINT & COATINGS Co., Ltd.',
    name: 'NOROO PAINT & COATINGS Co., Ltd.',
    symbol: '090355.KS',
  },
  {
    value: '090370.KS',
    label: '090370.KS,  Metalabs Co., Ltd.',
    name: 'Metalabs Co., Ltd.',
    symbol: '090370.KS',
  },
  {
    value: '091090.KS',
    label: '091090.KS,  SEWON E&C CO., Ltd.',
    name: 'SEWON E&C CO., Ltd.',
    symbol: '091090.KS',
  },
  {
    value: '091810.KS',
    label: "091810.KS,  T'Way Air Co., Ltd.",
    name: "T'Way Air Co., Ltd.",
    symbol: '091810.KS',
  },
  {
    value: '092200.KS',
    label: '092200.KS,  DAE-IL Corporation',
    name: 'DAE-IL Corporation',
    symbol: '092200.KS',
  },
  {
    value: '092220.KS',
    label: '092220.KS,  Kec Corporation',
    name: 'Kec Corporation',
    symbol: '092220.KS',
  },
  {
    value: '092230.KS',
    label: '092230.KS,  KPX Holdings Co.,Ltd.',
    name: 'KPX Holdings Co.,Ltd.',
    symbol: '092230.KS',
  },
  {
    value: '092440.KS',
    label: '092440.KS,  Kishin Corporation',
    name: 'Kishin Corporation',
    symbol: '092440.KS',
  },
  {
    value: '092780.KS',
    label: '092780.KS,  Dong Yang Piston Co., Ltd.',
    name: 'Dong Yang Piston Co., Ltd.',
    symbol: '092780.KS',
  },
  {
    value: '093230.KS',
    label: '093230.KS,  E Investment&Development Co., Ltd.',
    name: 'E Investment&Development Co., Ltd.',
    symbol: '093230.KS',
  },
  {
    value: '093240.KS',
    label: '093240.KS,  hyungji Elite Co., Ltd.',
    name: 'hyungji Elite Co., Ltd.',
    symbol: '093240.KS',
  },
  {
    value: '094280.KS',
    label: '094280.KS,  Hyosung ITX Co. Ltd.',
    name: 'Hyosung ITX Co. Ltd.',
    symbol: '094280.KS',
  },
  {
    value: '095570.KS',
    label: '095570.KS,  AJ Networks Co.,Ltd.',
    name: 'AJ Networks Co.,Ltd.',
    symbol: '095570.KS',
  },
  {
    value: '100220.KS',
    label: '100220.KS,  Visang Education Inc',
    name: 'Visang Education Inc',
    symbol: '100220.KS',
  },
  {
    value: '100250.KS',
    label: '100250.KS,  Chinyang Holdings Corp.',
    name: 'Chinyang Holdings Corp.',
    symbol: '100250.KS',
  },
  {
    value: '100840.KS',
    label: '100840.KS,  SNT Energy Co., Ltd.',
    name: 'SNT Energy Co., Ltd.',
    symbol: '100840.KS',
  },
  {
    value: '101140.KS',
    label: '101140.KS,  INBIOGEN Co., Ltd.',
    name: 'INBIOGEN Co., Ltd.',
    symbol: '101140.KS',
  },
  {
    value: '101530.KS',
    label: '101530.KS,  Haitai Confectionery&Foods Co.,ltd.',
    name: 'Haitai Confectionery&Foods Co.,ltd.',
    symbol: '101530.KS',
  },
  {
    value: '102260.KS',
    label: '102260.KS,  Dongsung Chemical Co., Ltd.',
    name: 'Dongsung Chemical Co., Ltd.',
    symbol: '102260.KS',
  },
  {
    value: '102280.KS',
    label: '102280.KS,  SBW, Inc.',
    name: 'SBW, Inc.',
    symbol: '102280.KS',
  },
  {
    value: '102460.KS',
    label: '102460.KS,  REYON Pharmaceutical Co., Ltd.',
    name: 'REYON Pharmaceutical Co., Ltd.',
    symbol: '102460.KS',
  },
  {
    value: '103590.KS',
    label: '103590.KS,  Iljin Electric Co.,Ltd',
    name: 'Iljin Electric Co.,Ltd',
    symbol: '103590.KS',
  },
  {
    value: '105840.KS',
    label: '105840.KS,  Woojin Inc.',
    name: 'Woojin Inc.',
    symbol: '105840.KS',
  },
  {
    value: '107590.KS',
    label: '107590.KS,  Miwon Holdings Co., Ltd.',
    name: 'Miwon Holdings Co., Ltd.',
    symbol: '107590.KS',
  },
  {
    value: '108675.KS',
    label: '108675.KS,  LX Hausys, Ltd.',
    name: 'LX Hausys, Ltd.',
    symbol: '108675.KS',
  },
  {
    value: '111110.KS',
    label: '111110.KS,  Hojeon Limited',
    name: 'Hojeon Limited',
    symbol: '111110.KS',
  },
  {
    value: '117580.KS',
    label: '117580.KS,  Daesung Energy Co., Ltd.',
    name: 'Daesung Energy Co., Ltd.',
    symbol: '117580.KS',
  },
  {
    value: '118000.KS',
    label: '118000.KS,  Wooridul Huebrain Limited',
    name: 'Wooridul Huebrain Limited',
    symbol: '118000.KS',
  },
  {
    value: '119650.KS',
    label: '119650.KS,  KC Cottrell Co., Ltd.',
    name: 'KC Cottrell Co., Ltd.',
    symbol: '119650.KS',
  },
  {
    value: '120030.KS',
    label: '120030.KS,  CHOSUN WELDING POHANG Co., Ltd',
    name: 'CHOSUN WELDING POHANG Co., Ltd',
    symbol: '120030.KS',
  },
  {
    value: '120115.KS',
    label: '120115.KS,  Kolon Industries, Inc.',
    name: 'Kolon Industries, Inc.',
    symbol: '120115.KS',
  },
  {
    value: '123690.KS',
    label: '123690.KS,  Hankook Cosmetics Co., Ltd.',
    name: 'Hankook Cosmetics Co., Ltd.',
    symbol: '123690.KS',
  },
  {
    value: '123700.KS',
    label: '123700.KS,  SJM Co., Ltd.',
    name: 'SJM Co., Ltd.',
    symbol: '123700.KS',
  },
  {
    value: '128820.KS',
    label: '128820.KS,  Daesung Industrial Co., Ltd.',
    name: 'Daesung Industrial Co., Ltd.',
    symbol: '128820.KS',
  },
  {
    value: '129260.KS',
    label: '129260.KS,  Intergis Co., Ltd',
    name: 'Intergis Co., Ltd',
    symbol: '129260.KS',
  },
  {
    value: '130660.KS',
    label: '130660.KS,  Korea Electric Power Industrial Development Co., Ltd',
    name: 'Korea Electric Power Industrial Development Co., Ltd',
    symbol: '130660.KS',
  },
  {
    value: '133820.KS',
    label: '133820.KS,  Fine besteel. Co., Ltd.',
    name: 'Fine besteel. Co., Ltd.',
    symbol: '133820.KS',
  },
  {
    value: '134380.KS',
    label: '134380.KS,  Miwon Chemicals Co., Ltd.',
    name: 'Miwon Chemicals Co., Ltd.',
    symbol: '134380.KS',
  },
  {
    value: '134790.KS',
    label: '134790.KS,  Sidiz.Inc.',
    name: 'Sidiz.Inc.',
    symbol: '134790.KS',
  },
  {
    value: '136490.KS',
    label: '136490.KS,  Sunjin Co.,Ltd.',
    name: 'Sunjin Co.,Ltd.',
    symbol: '136490.KS',
  },
  {
    value: '137310.KS',
    label: '137310.KS,  SD Biosensor, Inc',
    name: 'SD Biosensor, Inc',
    symbol: '137310.KS',
  },
  {
    value: '138040.KS',
    label: '138040.KS,  Meritz Financial Group Inc.',
    name: 'Meritz Financial Group Inc.',
    symbol: '138040.KS',
  },
  {
    value: '138490.KS',
    label: '138490.KS,  Kolon Plastics, Inc.',
    name: 'Kolon Plastics, Inc.',
    symbol: '138490.KS',
  },
  {
    value: '138930.KS',
    label: '138930.KS,  BNK Financial Group Inc.',
    name: 'BNK Financial Group Inc.',
    symbol: '138930.KS',
  },
  {
    value: '139130.KS',
    label: '139130.KS,  DGB Financial Group Co., Ltd.',
    name: 'DGB Financial Group Co., Ltd.',
    symbol: '139130.KS',
  },
  {
    value: '139480.KS',
    label: '139480.KS,  E-MART Inc.',
    name: 'E-MART Inc.',
    symbol: '139480.KS',
  },
  {
    value: '140910.KS',
    label: '140910.KS,  A Self-Administered Real Estate Investment Trust Inc.',
    name: 'A Self-Administered Real Estate Investment Trust Inc.',
    symbol: '140910.KS',
  },
  {
    value: '143210.KS',
    label: '143210.KS,  Hands Corporation Ltd',
    name: 'Hands Corporation Ltd',
    symbol: '143210.KS',
  },
  {
    value: '145210.KS',
    label: '145210.KS,  Dynamic Design Co., LTD.',
    name: 'Dynamic Design Co., LTD.',
    symbol: '145210.KS',
  },
  {
    value: '145270.KS',
    label: '145270.KS,  K-TOP Reits Co.,Ltd.',
    name: 'K-TOP Reits Co.,Ltd.',
    symbol: '145270.KS',
  },
  {
    value: '145720.KS',
    label: '145720.KS,  Dentium CO., LTD',
    name: 'Dentium CO., LTD',
    symbol: '145720.KS',
  },
  {
    value: '145990.KS',
    label: '145990.KS,  Samyang Corporation',
    name: 'Samyang Corporation',
    symbol: '145990.KS',
  },
  {
    value: '145995.KS',
    label: '145995.KS,  Samyang Corporation',
    name: 'Samyang Corporation',
    symbol: '145995.KS',
  },
  {
    value: '155660.KS',
    label: '155660.KS,  DSR Corp',
    name: 'DSR Corp',
    symbol: '155660.KS',
  },
  {
    value: '155900.KS',
    label: '155900.KS,  Badaro No. 19 Ship Investment Company',
    name: 'Badaro No. 19 Ship Investment Company',
    symbol: '155900.KS',
  },
  {
    value: '161000.KS',
    label: '161000.KS,  Aekyungchemical Co., Ltd.',
    name: 'Aekyungchemical Co., Ltd.',
    symbol: '161000.KS',
  },
  {
    value: '161390.KS',
    label: '161390.KS,  Hankook Tire & Technology Co., Ltd.',
    name: 'Hankook Tire & Technology Co., Ltd.',
    symbol: '161390.KS',
  },
  {
    value: '161890.KS',
    label: '161890.KS,  Kolmar Korea Co., Ltd.',
    name: 'Kolmar Korea Co., Ltd.',
    symbol: '161890.KS',
  },
  {
    value: '163560.KS',
    label: '163560.KS,  DRB Industrial Co., Ltd.',
    name: 'DRB Industrial Co., Ltd.',
    symbol: '163560.KS',
  },
  {
    value: '170900.KS',
    label: '170900.KS,  Dong-A ST Co., Ltd.',
    name: 'Dong-A ST Co., Ltd.',
    symbol: '170900.KS',
  },
  {
    value: '18064K.KS',
    label: '18064K.KS,  Hanjin Kal',
    name: 'Hanjin Kal',
    symbol: '18064K.KS',
  },
  {
    value: '194370.KS',
    label: '194370.KS,  JS Corporation',
    name: 'JS Corporation',
    symbol: '194370.KS',
  },
  {
    value: '204210.KS',
    label: '204210.KS,  MODE TOUR Real Estate Investment Trust Incorporated',
    name: 'MODE TOUR Real Estate Investment Trust Incorporated',
    symbol: '204210.KS',
  },
  {
    value: '210540.KS',
    label: '210540.KS,  DY Power Corporation',
    name: 'DY Power Corporation',
    symbol: '210540.KS',
  },
  {
    value: '214330.KS',
    label: '214330.KS,  Kumho HT, Inc',
    name: 'Kumho HT, Inc',
    symbol: '214330.KS',
  },
  {
    value: '214390.KS',
    label: '214390.KS,  Kyongbo Pharmaceutical Co., Ltd',
    name: 'Kyongbo Pharmaceutical Co., Ltd',
    symbol: '214390.KS',
  },
  {
    value: '214420.KS',
    label: '214420.KS,  Tonymoly Co., Ltd',
    name: 'Tonymoly Co., Ltd',
    symbol: '214420.KS',
  },
  {
    value: '226320.KS',
    label: "226320.KS,  It'S Hanbul Co., Ltd.",
    name: "It'S Hanbul Co., Ltd.",
    symbol: '226320.KS',
  },
  {
    value: '227840.KS',
    label: '227840.KS,  HYUNDAI CORPORATION HOLDINGS Co., Ltd.',
    name: 'HYUNDAI CORPORATION HOLDINGS Co., Ltd.',
    symbol: '227840.KS',
  },
  {
    value: '229640.KS',
    label: '229640.KS,  LS Cable & System Asia Ltd.',
    name: 'LS Cable & System Asia Ltd.',
    symbol: '229640.KS',
  },
  {
    value: '244920.KS',
    label: '244920.KS,  Aplus Asset Advisor Co. Ltd',
    name: 'Aplus Asset Advisor Co. Ltd',
    symbol: '244920.KS',
  },
  {
    value: '248170.KS',
    label: '248170.KS,  Sempio Foods Company',
    name: 'Sempio Foods Company',
    symbol: '248170.KS',
  },
  {
    value: '259960.KS',
    label: '259960.KS,  KRAFTON, Inc.',
    name: 'KRAFTON, Inc.',
    symbol: '259960.KS',
  },
  {
    value: '264900.KS',
    label: '264900.KS,  Crown Confectionery Co., Ltd.',
    name: 'Crown Confectionery Co., Ltd.',
    symbol: '264900.KS',
  },
  {
    value: '26490K.KS',
    label: '26490K.KS,  Crown Confectionery Co., Ltd.',
    name: 'Crown Confectionery Co., Ltd.',
    symbol: '26490K.KS',
  },
  {
    value: '267290.KS',
    label: '267290.KS,  Kyungdong City Gas Co., Ltd',
    name: 'Kyungdong City Gas Co., Ltd',
    symbol: '267290.KS',
  },
  {
    value: '267850.KS',
    label: '267850.KS,  Asiana IDT, Inc.',
    name: 'Asiana IDT, Inc.',
    symbol: '267850.KS',
  },
  {
    value: '268280.KS',
    label: '268280.KS,  Miwon Specialty Chemical Co., Ltd.',
    name: 'Miwon Specialty Chemical Co., Ltd.',
    symbol: '268280.KS',
  },
  {
    value: '280360.KS',
    label: '280360.KS,  Lotte Confectionery Co., Ltd.',
    name: 'Lotte Confectionery Co., Ltd.',
    symbol: '280360.KS',
  },
  {
    value: '281820.KS',
    label: '281820.KS,  KCTech Co., Ltd.',
    name: 'KCTech Co., Ltd.',
    symbol: '281820.KS',
  },
  {
    value: '282330.KS',
    label: '282330.KS,  BGF retail CO., LTD.',
    name: 'BGF retail CO., LTD.',
    symbol: '282330.KS',
  },
  {
    value: '282690.KS',
    label: '282690.KS,  Dong Ah Tire & Rubber Co.,Ltd',
    name: 'Dong Ah Tire & Rubber Co.,Ltd',
    symbol: '282690.KS',
  },
  {
    value: '284740.KS',
    label: '284740.KS,  CUCKOO Homesys Co., Ltd',
    name: 'CUCKOO Homesys Co., Ltd',
    symbol: '284740.KS',
  },
  {
    value: '285130.KS',
    label: '285130.KS,  SK Chemicals Co.,Ltd',
    name: 'SK Chemicals Co.,Ltd',
    symbol: '285130.KS',
  },
  {
    value: '28513K.KS',
    label: '28513K.KS,  SK Chemicals Co.,Ltd',
    name: 'SK Chemicals Co.,Ltd',
    symbol: '28513K.KS',
  },
  {
    value: '286940.KS',
    label: '286940.KS,  Lotte Data Communication Company',
    name: 'Lotte Data Communication Company',
    symbol: '286940.KS',
  },
  {
    value: '293480.KS',
    label: '293480.KS,  Hana Pharm Co., Ltd.',
    name: 'Hana Pharm Co., Ltd.',
    symbol: '293480.KS',
  },
  {
    value: '293940.KS',
    label: '293940.KS,  Shinhan Alpha REIT Co., Ltd.',
    name: 'Shinhan Alpha REIT Co., Ltd.',
    symbol: '293940.KS',
  },
  {
    value: '308170.KS',
    label: '308170.KS,  CENTRAL MOTEK Co.Ltd.',
    name: 'CENTRAL MOTEK Co.Ltd.',
    symbol: '308170.KS',
  },
  {
    value: '310960.KS',
    label: '310960.KS,  Tiger 200 Total Return',
    name: 'Tiger 200 Total Return',
    symbol: '310960.KS',
  },
  {
    value: '316140.KS',
    label: '316140.KS,  Woori Financial Group Inc.',
    name: 'Woori Financial Group Inc.',
    symbol: '316140.KS',
  },
  {
    value: '317400.KS',
    label: '317400.KS,  Xi S&D Inc.',
    name: 'Xi S&D Inc.',
    symbol: '317400.KS',
  },
  {
    value: '322000.KS',
    label: '322000.KS,  Hyundai Energy Solutions Co.,Ltd',
    name: 'Hyundai Energy Solutions Co.,Ltd',
    symbol: '322000.KS',
  },
  {
    value: '323410.KS',
    label: '323410.KS,  KakaoBank Corp.',
    name: 'KakaoBank Corp.',
    symbol: '323410.KS',
  },
  {
    value: '326030.KS',
    label: '326030.KS,  SK Biopharmaceuticals Co., Ltd.',
    name: 'SK Biopharmaceuticals Co., Ltd.',
    symbol: '326030.KS',
  },
  {
    value: '330590.KS',
    label: '330590.KS,  LOTTE REIT Co., Ltd.',
    name: 'LOTTE REIT Co., Ltd.',
    symbol: '330590.KS',
  },
  {
    value: '334890.KS',
    label: '334890.KS,  IGIS Value Plus REIT Co., Ltd.',
    name: 'IGIS Value Plus REIT Co., Ltd.',
    symbol: '334890.KS',
  },
  {
    value: '336260.KS',
    label: '336260.KS,  Doosan Fuel Cell Co., Ltd.',
    name: 'Doosan Fuel Cell Co., Ltd.',
    symbol: '336260.KS',
  },
  {
    value: '33626K.KS',
    label: '33626K.KS,  Doosan Fuel Cell Co., Ltd.',
    name: 'Doosan Fuel Cell Co., Ltd.',
    symbol: '33626K.KS',
  },
  {
    value: '33626L.KS',
    label: '33626L.KS,  Doosan Fuel Cell Co., Ltd.',
    name: 'Doosan Fuel Cell Co., Ltd.',
    symbol: '33626L.KS',
  },
  {
    value: '336370.KS',
    label: '336370.KS,  Solus Advanced Materials Co., Ltd.',
    name: 'Solus Advanced Materials Co., Ltd.',
    symbol: '336370.KS',
  },
  {
    value: '33637K.KS',
    label: '33637K.KS,  Solus Advanced Materials Co., Ltd.',
    name: 'Solus Advanced Materials Co., Ltd.',
    symbol: '33637K.KS',
  },
  {
    value: '33637L.KS',
    label: '33637L.KS,  Solus Advanced Materials Co., Ltd.',
    name: 'Solus Advanced Materials Co., Ltd.',
    symbol: '33637L.KS',
  },
  {
    value: '338100.KS',
    label: '338100.KS,  NH Prime REIT Co., Ltd.',
    name: 'NH Prime REIT Co., Ltd.',
    symbol: '338100.KS',
  },
  {
    value: '339770.KS',
    label: '339770.KS,  Kyochon Food&Beverage Co., Ltd.',
    name: 'Kyochon Food&Beverage Co., Ltd.',
    symbol: '339770.KS',
  },
  {
    value: '344820.KS',
    label: '344820.KS,  KCC GLASS Corporation',
    name: 'KCC GLASS Corporation',
    symbol: '344820.KS',
  },
  {
    value: '350520.KS',
    label: '350520.KS,  IGIS RESIDENCE REIT Co., Ltd.',
    name: 'IGIS RESIDENCE REIT Co., Ltd.',
    symbol: '350520.KS',
  },
  {
    value: '35320K.KS',
    label: '35320K.KS,  DAEDUCK ELECTRONICS Co., Ltd.',
    name: 'DAEDUCK ELECTRONICS Co., Ltd.',
    symbol: '35320K.KS',
  },
  {
    value: '357120.KS',
    label: '357120.KS,  Koramco Energy Plus Reit',
    name: 'Koramco Energy Plus Reit',
    symbol: '357120.KS',
  },
  {
    value: '357250.KS',
    label: '357250.KS,  Miraeasset Maps REIT 1 Co., Ltd.',
    name: 'Miraeasset Maps REIT 1 Co., Ltd.',
    symbol: '357250.KS',
  },
  {
    value: '365550.KS',
    label: '365550.KS,  ESR Kendall Square REIT Co., Ltd.',
    name: 'ESR Kendall Square REIT Co., Ltd.',
    symbol: '365550.KS',
  },
  {
    value: '2270.HK',
    label: '2270.HK,  Desun Real Estate Investment Services Group Co., Ltd.',
    name: 'Desun Real Estate Investment Services Group Co., Ltd.',
    symbol: '2270.HK',
  },
  {
    value: 'ENSC',
    label: 'ENSC,  Ensysce Biosciences, Inc.',
    name: 'Ensysce Biosciences, Inc.',
    symbol: 'ENSC',
  },
  {
    value: 'LITM',
    label: 'LITM,  Snow Lake Resources Ltd.',
    name: 'Snow Lake Resources Ltd.',
    symbol: 'LITM',
  },
  {
    value: 'REFI',
    label: 'REFI,  Chicago Atlantic Real Estate Finance, Inc.',
    name: 'Chicago Atlantic Real Estate Finance, Inc.',
    symbol: 'REFI',
  },
  {
    value: 'NU',
    label: 'NU,  Nu Holdings Ltd.',
    name: 'Nu Holdings Ltd.',
    symbol: 'NU',
  },
  {
    value: '2276.HK',
    label: '2276.HK,  Shanghai Conant Optical Co., Ltd.',
    name: 'Shanghai Conant Optical Co., Ltd.',
    symbol: '2276.HK',
  },
  {
    value: 'AMPS',
    label: 'AMPS,  Altus Power, Inc.',
    name: 'Altus Power, Inc.',
    symbol: 'AMPS',
  },
  {
    value: 'VRE',
    label: 'VRE,  Veris Residential, Inc.',
    name: 'Veris Residential, Inc.',
    symbol: 'VRE',
  },
  {
    value: 'PL',
    label: 'PL,  Planet Labs PBC',
    name: 'Planet Labs PBC',
    symbol: 'PL',
  },
  {
    value: 'HCP',
    label: 'HCP,  HashiCorp, Inc.',
    name: 'HashiCorp, Inc.',
    symbol: 'HCP',
  },
  {
    value: 'NILE',
    label: 'NILE,  BitNile Holdings, Inc.',
    name: 'BitNile Holdings, Inc.',
    symbol: 'NILE',
  },
  {
    value: 'A2A.MI',
    label: 'A2A.MI,  A2A S.p.A.',
    name: 'A2A S.p.A.',
    symbol: 'A2A.MI',
  },
  {
    value: '0NX1.IL',
    label: '0NX1.IL,  Aalberts N.V.',
    name: 'Aalberts N.V.',
    symbol: '0NX1.IL',
  },
  {
    value: 'ADS.F',
    label: 'ADS.F,  adidas AG',
    name: 'adidas AG',
    symbol: 'ADS.F',
  },
  {
    value: '0R4Y.IL',
    label: '0R4Y.IL,  Aena S.M.E., S.A.',
    name: 'Aena S.M.E., S.A.',
    symbol: '0R4Y.IL',
  },
  {
    value: 'AMP.MI',
    label: 'AMP.MI,  Amplifon S.p.A.',
    name: 'Amplifon S.p.A.',
    symbol: 'AMP.MI',
  },
  {
    value: 'AT1.F',
    label: 'AT1.F,  Aroundtown SA',
    name: 'Aroundtown SA',
    symbol: 'AT1.F',
  },
  {
    value: 'ASSA-B.ST',
    label: 'ASSA-B.ST,  ASSA ABLOY AB (publ)',
    name: 'ASSA ABLOY AB (publ)',
    symbol: 'ASSA-B.ST',
  },
  {
    value: 'G.MI',
    label: 'G.MI,  Assicurazioni Generali S.p.A.',
    name: 'Assicurazioni Generali S.p.A.',
    symbol: 'G.MI',
  },
  {
    value: 'ATCO-A.ST',
    label: 'ATCO-A.ST,  Atlas Copco AB',
    name: 'Atlas Copco AB',
    symbol: 'ATCO-A.ST',
  },
  {
    value: 'SAB.MC',
    label: 'SAB.MC,  Banco de Sabadell, S.A.',
    name: 'Banco de Sabadell, S.A.',
    symbol: 'SAB.MC',
  },
  {
    value: 'BAS.F',
    label: 'BAS.F,  BASF SE',
    name: 'BASF SE',
    symbol: 'BAS.F',
  },
  {
    value: 'BEIJ-B.ST',
    label: 'BEIJ-B.ST,  Beijer Ref AB (publ)',
    name: 'Beijer Ref AB (publ)',
    symbol: 'BEIJ-B.ST',
  },
  {
    value: 'CPR.MI',
    label: 'CPR.MI,  Davide Campari-Milano N.V.',
    name: 'Davide Campari-Milano N.V.',
    symbol: 'CPR.MI',
  },
  {
    value: 'CS3.F',
    label: 'CS3.F,  Close Brothers Group plc',
    name: 'Close Brothers Group plc',
    symbol: 'CS3.F',
  },
  {
    value: '1COV.F',
    label: '1COV.F,  Covestro AG',
    name: 'Covestro AG',
    symbol: '1COV.F',
  },
  {
    value: 'DIA.MI',
    label: 'DIA.MI,  DiaSorin S.p.A.',
    name: 'DiaSorin S.p.A.',
    symbol: 'DIA.MI',
  },
  {
    value: 'EDF.VI',
    label: 'EDF.VI,  Electricité de France S.A.',
    name: 'Electricité de France S.A.',
    symbol: 'EDF.VI',
  },
  {
    value: '0MDT.IL',
    label: '0MDT.IL,  AB Electrolux (publ)',
    name: 'AB Electrolux (publ)',
    symbol: '0MDT.IL',
  },
  {
    value: '0O5H.IL',
    label: '0O5H.IL,  Elekta AB (publ)',
    name: 'Elekta AB (publ)',
    symbol: '0O5H.IL',
  },
  {
    value: 'EMSHF',
    label: 'EMSHF,  EMS-CHEMIE HOLDING AG',
    name: 'EMS-CHEMIE HOLDING AG',
    symbol: 'EMSHF',
  },
  {
    value: 'ENG.MC',
    label: 'ENG.MC,  Enagás, S.A.',
    name: 'Enagás, S.A.',
    symbol: 'ENG.MC',
  },
  {
    value: 'ENEL.MI',
    label: 'ENEL.MI,  Enel SpA',
    name: 'Enel SpA',
    symbol: 'ENEL.MI',
  },
  {
    value: '0N9S.IL',
    label: '0N9S.IL,  Eni S.p.A.',
    name: 'Eni S.p.A.',
    symbol: '0N9S.IL',
  },
  {
    value: 'EPI-A.ST',
    label: 'EPI-A.ST,  Epiroc AB (publ)',
    name: 'Epiroc AB (publ)',
    symbol: 'EPI-A.ST',
  },
  {
    value: 'EBS.VI',
    label: 'EBS.VI,  Erste Group Bank AG',
    name: 'Erste Group Bank AG',
    symbol: 'EBS.VI',
  },
  {
    value: 'ESSITY-B.ST',
    label: 'ESSITY-B.ST,  Essity AB (publ)',
    name: 'Essity AB (publ)',
    symbol: 'ESSITY-B.ST',
  },
  {
    value: '0N4Y.IL',
    label: '0N4Y.IL,  Etn. Fr. Colruyt NV',
    name: 'Etn. Fr. Colruyt NV',
    symbol: '0N4Y.IL',
  },
  {
    value: '0IRF.IL',
    label: '0IRF.IL,  Evotec SE',
    name: 'Evotec SE',
    symbol: '0IRF.IL',
  },
  {
    value: 'SGRE.MC',
    label: 'SGRE.MC,  Siemens Gamesa Renewable Energy, S.A.',
    name: 'Siemens Gamesa Renewable Energy, S.A.',
    symbol: 'SGRE.MC',
  },
  {
    value: '0QP4.IL',
    label: '0QP4.IL,  Georg Fischer AG',
    name: 'Georg Fischer AG',
    symbol: '0QP4.IL',
  },
  {
    value: '0GZV.IL',
    label: '0GZV.IL,  Getinge AB (publ)',
    name: 'Getinge AB (publ)',
    symbol: '0GZV.IL',
  },
  {
    value: 'HEIO.VI',
    label: 'HEIO.VI,  Heineken Holding N.V.',
    name: 'Heineken Holding N.V.',
    symbol: 'HEIO.VI',
  },
  {
    value: 'HEN3.F',
    label: 'HEN3.F,  Henkel AG & Co. KGaA',
    name: 'Henkel AG & Co. KGaA',
    symbol: 'HEN3.F',
  },
  {
    value: 'HER.MI',
    label: 'HER.MI,  Hera S.p.A.',
    name: 'Hera S.p.A.',
    symbol: 'HER.MI',
  },
  {
    value: 'RMS.VI',
    label: 'RMS.VI,  Hermès International Société en commandite par actions',
    name: 'Hermès International Société en commandite par actions',
    symbol: 'RMS.VI',
  },
  {
    value: 'HPOL-B.ST',
    label: 'HPOL-B.ST,  HEXPOL AB (publ)',
    name: 'HEXPOL AB (publ)',
    symbol: 'HPOL-B.ST',
  },
  {
    value: 'HOLM-B.ST',
    label: 'HOLM-B.ST,  Holmen AB (publ)',
    name: 'Holmen AB (publ)',
    symbol: 'HOLM-B.ST',
  },
  {
    value: '0K9W.IL',
    label: '0K9W.IL,  Huhtamäki Oyj',
    name: 'Huhtamäki Oyj',
    symbol: '0K9W.IL',
  },
  {
    value: 'IP.MI',
    label: 'IP.MI,  Interpump Group S.p.A.',
    name: 'Interpump Group S.p.A.',
    symbol: 'IP.MI',
  },
  {
    value: 'INVE-B.ST',
    label: 'INVE-B.ST,  Investor AB (publ)',
    name: 'Investor AB (publ)',
    symbol: 'INVE-B.ST',
  },
  {
    value: 'INW.MI',
    label: 'INW.MI,  Infrastrutture Wireless Italiane S.p.A.',
    name: 'Infrastrutture Wireless Italiane S.p.A.',
    symbol: 'INW.MI',
  },
  {
    value: '0EXG.IL',
    label: '0EXG.IL,  Jerónimo Martins, SGPS, S.A.',
    name: 'Jerónimo Martins, SGPS, S.A.',
    symbol: '0EXG.IL',
  },
  {
    value: 'ERIC-B.ST',
    label: 'ERIC-B.ST,  Telefonaktiebolaget LM Ericsson (publ)',
    name: 'Telefonaktiebolaget LM Ericsson (publ)',
    symbol: 'ERIC-B.ST',
  },
  {
    value: '0GWB.IL',
    label: '0GWB.IL,  L E Lundbergföretagen AB (publ)',
    name: 'L E Lundbergföretagen AB (publ)',
    symbol: '0GWB.IL',
  },
  {
    value: 'MB.MI',
    label: 'MB.MI,  Mediobanca Banca di Credito Finanziario S.p.A.',
    name: 'Mediobanca Banca di Credito Finanziario S.p.A.',
    symbol: 'MB.MI',
  },
  {
    value: '0O77.IL',
    label: '0O77.IL,  A.P. Møller - Mærsk A/S',
    name: 'A.P. Møller - Mærsk A/S',
    symbol: '0O77.IL',
  },
  {
    value: 'NEXI.MI',
    label: 'NEXI.MI,  Nexi S.p.A.',
    name: 'Nexi S.p.A.',
    symbol: 'NEXI.MI',
  },
  {
    value: 'NIBE-B.ST',
    label: 'NIBE-B.ST,  NIBE Industrier AB (publ)',
    name: 'NIBE Industrier AB (publ)',
    symbol: 'NIBE-B.ST',
  },
  {
    value: 'OMV.VI',
    label: 'OMV.VI,  OMV Aktiengesellschaft',
    name: 'OMV Aktiengesellschaft',
    symbol: 'OMV.VI',
  },
  {
    value: 'REC.MI',
    label: 'REC.MI,  Recordati Industria Chimica e Farmaceutica S.p.A.',
    name: 'Recordati Industria Chimica e Farmaceutica S.p.A.',
    symbol: 'REC.MI',
  },
  {
    value: 'REE.VI',
    label: 'REE.VI,  Red Eléctrica Corporación, S.A.',
    name: 'Red Eléctrica Corporación, S.A.',
    symbol: 'REE.VI',
  },
  {
    value: 'SAAB-B.ST',
    label: 'SAAB-B.ST,  Saab AB (publ)',
    name: 'Saab AB (publ)',
    symbol: 'SAAB-B.ST',
  },
  {
    value: 'SAGA-B.ST',
    label: 'SAGA-B.ST,  AB Sagax (publ)',
    name: 'AB Sagax (publ)',
    symbol: 'SAGA-B.ST',
  },
  {
    value: 'SPM.MI',
    label: 'SPM.MI,  Saipem SpA',
    name: 'Saipem SpA',
    symbol: 'SPM.MI',
  },
  {
    value: 'SCA-A.ST',
    label: 'SCA-A.ST,  Svenska Cellulosa Aktiebolaget SCA (publ)',
    name: 'Svenska Cellulosa Aktiebolaget SCA (publ)',
    symbol: 'SCA-A.ST',
  },
  {
    value: 'SEB-A.ST',
    label: 'SEB-A.ST,  Skandinaviska Enskilda Banken AB (publ)',
    name: 'Skandinaviska Enskilda Banken AB (publ)',
    symbol: 'SEB-A.ST',
  },
  {
    value: 'SECU-B.ST',
    label: 'SECU-B.ST,  Securitas AB (publ)',
    name: 'Securitas AB (publ)',
    symbol: 'SECU-B.ST',
  },
  {
    value: 'SKA-B.ST',
    label: 'SKA-B.ST,  Skanska AB (publ)',
    name: 'Skanska AB (publ)',
    symbol: 'SKA-B.ST',
  },
  {
    value: 'SKF-B.ST',
    label: 'SKF-B.ST,  AB SKF (publ)',
    name: 'AB SKF (publ)',
    symbol: 'SKF-B.ST',
  },
  {
    value: 'SHB-A.ST',
    label: 'SHB-A.ST,  Svenska Handelsbanken AB (publ)',
    name: 'Svenska Handelsbanken AB (publ)',
    symbol: 'SHB-A.ST',
  },
  {
    value: 'SWED-A.ST',
    label: 'SWED-A.ST,  Swedbank AB (publ)',
    name: 'Swedbank AB (publ)',
    symbol: 'SWED-A.ST',
  },
  {
    value: 'TEL2-B.ST',
    label: 'TEL2-B.ST,  Tele2 AB (publ)',
    name: 'Tele2 AB (publ)',
    symbol: 'TEL2-B.ST',
  },
  {
    value: 'TIT.MI',
    label: 'TIT.MI,  Telecom Italia S.p.A.',
    name: 'Telecom Italia S.p.A.',
    symbol: 'TIT.MI',
  },
  {
    value: '0QAL.IL',
    label: '0QAL.IL,  Telefónica Deutschland Holding AG',
    name: 'Telefónica Deutschland Holding AG',
    symbol: '0QAL.IL',
  },
  {
    value: 'TEN.MI',
    label: 'TEN.MI,  Tenaris S.A.',
    name: 'Tenaris S.A.',
    symbol: 'TEN.MI',
  },
  {
    value: 'UCG.MI',
    label: 'UCG.MI,  UniCredit S.p.A.',
    name: 'UniCredit S.p.A.',
    symbol: 'UCG.MI',
  },
  {
    value: 'VER.VI',
    label: 'VER.VI,  VERBUND AG',
    name: 'VERBUND AG',
    symbol: 'VER.VI',
  },
  {
    value: 'VOE.VI',
    label: 'VOE.VI,  Voestalpine AG',
    name: 'Voestalpine AG',
    symbol: 'VOE.VI',
  },
  {
    value: 'VOLV-B.ST',
    label: 'VOLV-B.ST,  AB Volvo (publ)',
    name: 'AB Volvo (publ)',
    symbol: 'VOLV-B.ST',
  },
  {
    value: 'VNA.F',
    label: 'VNA.F,  Vonovia SE',
    name: 'Vonovia SE',
    symbol: 'VNA.F',
  },
  {
    value: 'VINE',
    label: 'VINE,  Fresh Vine Wine, Inc.',
    name: 'Fresh Vine Wine, Inc.',
    symbol: 'VINE',
  },
  {
    value: 'CVT',
    label: 'CVT,  Cvent Holding Corp.',
    name: 'Cvent Holding Corp.',
    symbol: 'CVT',
  },
  {
    value: 'SIDU',
    label: 'SIDU,  Sidus Space, Inc.',
    name: 'Sidus Space, Inc.',
    symbol: 'SIDU',
  },
  {
    value: 'BOWL',
    label: 'BOWL,  Bowlero Corp.',
    name: 'Bowlero Corp.',
    symbol: 'BOWL',
  },
  {
    value: 'PX',
    label: 'PX,  P10, Inc.',
    name: 'P10, Inc.',
    symbol: 'PX',
  },
  {
    value: 'AUID',
    label: 'AUID,  authID Inc.',
    name: 'authID Inc.',
    symbol: 'AUID',
  },
  {
    value: 'BLDE',
    label: 'BLDE,  Blade Air Mobility, Inc.',
    name: 'Blade Air Mobility, Inc.',
    symbol: 'BLDE',
  },
  {
    value: 'GWH',
    label: 'GWH,  ESS Tech, Inc.',
    name: 'ESS Tech, Inc.',
    symbol: 'GWH',
  },
  {
    value: 'NXGL',
    label: 'NXGL,  NEXGEL, Inc.',
    name: 'NEXGEL, Inc.',
    symbol: 'NXGL',
  },
  {
    value: 'IOT',
    label: 'IOT,  Samsara Inc.',
    name: 'Samsara Inc.',
    symbol: 'IOT',
  },
  {
    value: 'WAVD',
    label: 'WAVD,  WaveDancer, Inc.',
    name: 'WaveDancer, Inc.',
    symbol: 'WAVD',
  },
  {
    value: 'PEGRW',
    label: 'PEGRW,  Project Energy Reimagined Acquisition Corp.',
    name: 'Project Energy Reimagined Acquisition Corp.',
    symbol: 'PEGRW',
  },
  {
    value: 'EQRX',
    label: 'EQRX,  EQRx, Inc.',
    name: 'EQRx, Inc.',
    symbol: 'EQRX',
  },
  {
    value: 'EQRXW',
    label: 'EQRXW,  EQRx, Inc.',
    name: 'EQRx, Inc.',
    symbol: 'EQRXW',
  },
  {
    value: 'PRSO',
    label: 'PRSO,  Peraso Inc.',
    name: 'Peraso Inc.',
    symbol: 'PRSO',
  },
  {
    value: '2257.HK',
    label: '2257.HK,  Sirnaomics Ltd.',
    name: 'Sirnaomics Ltd.',
    symbol: '2257.HK',
  },
  {
    value: 'AGIL',
    label: 'AGIL,  AgileThought, Inc.',
    name: 'AgileThought, Inc.',
    symbol: 'AGIL',
  },
  {
    value: '2285.HK',
    label: '2285.HK,  Chervon Holdings Limited',
    name: 'Chervon Holdings Limited',
    symbol: '2285.HK',
  },
  {
    value: 'FRLA',
    label: 'FRLA,  Fortune Rise Acquisition Corporation',
    name: 'Fortune Rise Acquisition Corporation',
    symbol: 'FRLA',
  },
  {
    value: 'BRTX',
    label: 'BRTX,  BioRestorative Therapies, Inc.',
    name: 'BioRestorative Therapies, Inc.',
    symbol: 'BRTX',
  },
  {
    value: 'FATH',
    label: 'FATH,  Fathom Digital Manufacturing Corporation',
    name: 'Fathom Digital Manufacturing Corporation',
    symbol: 'FATH',
  },
  {
    value: 'PRDS',
    label: 'PRDS,  Pardes Biosciences, Inc.',
    name: 'Pardes Biosciences, Inc.',
    symbol: 'PRDS',
  },
  {
    value: 'MUSI',
    label: 'MUSI,  American Century Multisector Income ETF',
    name: 'American Century Multisector Income ETF',
    symbol: 'MUSI',
  },
  {
    value: 'PND.F',
    label: 'PND.F,  Mowi ASA',
    name: 'Mowi ASA',
    symbol: 'PND.F',
  },
  {
    value: 'D7G.F',
    label: 'D7G.F,  Nel ASA',
    name: 'Nel ASA',
    symbol: 'D7G.F',
  },
  {
    value: 'ROCAR',
    label: 'ROCAR,  ROC Energy Acquisition Corp.',
    name: 'ROC Energy Acquisition Corp.',
    symbol: 'ROCAR',
  },
  {
    value: 'GMFIU',
    label: 'GMFIU,  Aetherium Acquisition Corp.',
    name: 'Aetherium Acquisition Corp.',
    symbol: 'GMFIU',
  },
  {
    value: 'SPX.V',
    label: 'SPX.V,  Stellar AfricaGold Inc.',
    name: 'Stellar AfricaGold Inc.',
    symbol: 'SPX.V',
  },
  {
    value: 'ALLR',
    label: 'ALLR,  Allarity Therapeutics, Inc.',
    name: 'Allarity Therapeutics, Inc.',
    symbol: 'ALLR',
  },
  {
    value: 'CBRGW',
    label: 'CBRGW,  Chain Bridge I',
    name: 'Chain Bridge I',
    symbol: 'CBRGW',
  },
  {
    value: 'HLGN',
    label: 'HLGN,  Heliogen, Inc.',
    name: 'Heliogen, Inc.',
    symbol: 'HLGN',
  },
  {
    value: 'TMFS',
    label: 'TMFS,  Motley Fool Small-Cap Growth ETF',
    name: 'Motley Fool Small-Cap Growth ETF',
    symbol: 'TMFS',
  },
  {
    value: 'ADTH',
    label: 'ADTH,  AdTheorent Holding Company, Inc.',
    name: 'AdTheorent Holding Company, Inc.',
    symbol: 'ADTH',
  },
  {
    value: 'IMMX',
    label: 'IMMX,  Immix Biopharma, Inc.',
    name: 'Immix Biopharma, Inc.',
    symbol: 'IMMX',
  },
  {
    value: 'IQMD',
    label: 'IQMD,  Intelligent Medicine Acquisition Corp.',
    name: 'Intelligent Medicine Acquisition Corp.',
    symbol: 'IQMD',
  },
  {
    value: 'IOAC',
    label: 'IOAC,  Innovative International Acquisition Corp.',
    name: 'Innovative International Acquisition Corp.',
    symbol: 'IOAC',
  },
  {
    value: 'RNER',
    label: 'RNER,  Mount Rainier Acquisition Corp.',
    name: 'Mount Rainier Acquisition Corp.',
    symbol: 'RNER',
  },
  {
    value: 'LGSTW',
    label: 'LGSTW,  Semper Paratus Acquisition Corporation',
    name: 'Semper Paratus Acquisition Corporation',
    symbol: 'LGSTW',
  },
  {
    value: 'IOACW',
    label: 'IOACW,  Innovative International Acquisition Corp.',
    name: 'Innovative International Acquisition Corp.',
    symbol: 'IOACW',
  },
  {
    value: 'VMGA',
    label: 'VMGA,  VMG Consumer Acquisition Corp.',
    name: 'VMG Consumer Acquisition Corp.',
    symbol: 'VMGA',
  },
  {
    value: 'LGST',
    label: 'LGST,  Semper Paratus Acquisition Corporation',
    name: 'Semper Paratus Acquisition Corporation',
    symbol: 'LGST',
  },
  {
    value: 'RNERW',
    label: 'RNERW,  Mount Rainier Acquisition Corp.',
    name: 'Mount Rainier Acquisition Corp.',
    symbol: 'RNERW',
  },
  {
    value: 'IRRX',
    label: 'IRRX,  Integrated Rail and Resources Acquisition Corp.',
    name: 'Integrated Rail and Resources Acquisition Corp.',
    symbol: 'IRRX',
  },
  {
    value: 'AFAC',
    label: 'AFAC,  Arena Fortify Acquisition Corp.',
    name: 'Arena Fortify Acquisition Corp.',
    symbol: 'AFAC',
  },
  {
    value: 'CBRG',
    label: 'CBRG,  Chain Bridge I',
    name: 'Chain Bridge I',
    symbol: 'CBRG',
  },
  {
    value: 'BCSAW',
    label: 'BCSAW,  Blockchain Coinvestors Acquisition Corp. I',
    name: 'Blockchain Coinvestors Acquisition Corp. I',
    symbol: 'BCSAW',
  },
  {
    value: 'BCSA',
    label: 'BCSA,  Blockchain Coinvestors Acquisition Corp. I',
    name: 'Blockchain Coinvestors Acquisition Corp. I',
    symbol: 'BCSA',
  },
  {
    value: 'AFACW',
    label: 'AFACW,  Arena Fortify Acquisition Corp.',
    name: 'Arena Fortify Acquisition Corp.',
    symbol: 'AFACW',
  },
  {
    value: 'ACDI',
    label: 'ACDI,  Ascendant Digital Acquisition Corp. III',
    name: 'Ascendant Digital Acquisition Corp. III',
    symbol: 'ACDI',
  },
  {
    value: 'RCFA',
    label: 'RCFA,  RCF Acquisition Corp.',
    name: 'RCF Acquisition Corp.',
    symbol: 'RCFA',
  },
  {
    value: 'GVCIW',
    label: 'GVCIW,  Green Visor Financial Technology Acquisition Corp. I',
    name: 'Green Visor Financial Technology Acquisition Corp. I',
    symbol: 'GVCIW',
  },
  {
    value: 'GVCI',
    label: 'GVCI,  Green Visor Financial Technology Acquisition Corp. I',
    name: 'Green Visor Financial Technology Acquisition Corp. I',
    symbol: 'GVCI',
  },
  {
    value: 'DPCSW',
    label: 'DPCSW,  DP Cap Acquisition Corp I',
    name: 'DP Cap Acquisition Corp I',
    symbol: 'DPCSW',
  },
  {
    value: 'ROC',
    label: 'ROC,  ROC Energy Acquisition Corp.',
    name: 'ROC Energy Acquisition Corp.',
    symbol: 'ROC',
  },
  {
    value: 'NUBIU',
    label: 'NUBIU,  Nubia Brand International Corp.',
    name: 'Nubia Brand International Corp.',
    symbol: 'NUBIU',
  },
  {
    value: 'NLSP',
    label: 'NLSP,  NLS Pharmaceutics AG',
    name: 'NLS Pharmaceutics AG',
    symbol: 'NLSP',
  },
  {
    value: 'WBEV',
    label: 'WBEV,  Winc, Inc.',
    name: 'Winc, Inc.',
    symbol: 'WBEV',
  },
  {
    value: 'WEJO',
    label: 'WEJO,  Wejo Group Limited',
    name: 'Wejo Group Limited',
    symbol: 'WEJO',
  },
  {
    value: 'BJDX',
    label: 'BJDX,  Bluejay Diagnostics, Inc.',
    name: 'Bluejay Diagnostics, Inc.',
    symbol: 'BJDX',
  },
  {
    value: 'VMGAW',
    label: 'VMGAW,  VMG Consumer Acquisition Corp.',
    name: 'VMG Consumer Acquisition Corp.',
    symbol: 'VMGAW',
  },
  {
    value: 'CITEU',
    label: 'CITEU,  Cartica Acquisition Corp',
    name: 'Cartica Acquisition Corp',
    symbol: 'CITEU',
  },
  {
    value: 'WTV',
    label: 'WTV,  WisdomTree U.S. Value Fund',
    name: 'WisdomTree U.S. Value Fund',
    symbol: 'WTV',
  },
  {
    value: 'CMSINFO.NS',
    label: 'CMSINFO.NS,  CMS Info Systems Limited',
    name: 'CMS Info Systems Limited',
    symbol: 'CMSINFO.NS',
  },
  {
    value: 'SUPRIYA.NS',
    label: 'SUPRIYA.NS,  Supriya Lifescience Limited',
    name: 'Supriya Lifescience Limited',
    symbol: 'SUPRIYA.NS',
  },
  {
    value: 'HPAL.NS',
    label: 'HPAL.NS,  HP Adhesives Limited',
    name: 'HP Adhesives Limited',
    symbol: 'HPAL.NS',
  },
  {
    value: 'DATAPATTNS.NS',
    label: 'DATAPATTNS.NS,  Data Patterns (India) Limited',
    name: 'Data Patterns (India) Limited',
    symbol: 'DATAPATTNS.NS',
  },
  {
    value: 'MEDPLUS.NS',
    label: 'MEDPLUS.NS,  MedPlus Health Services Limited',
    name: 'MedPlus Health Services Limited',
    symbol: 'MEDPLUS.NS',
  },
  {
    value: 'ARA.TO',
    label: 'ARA.TO,  Aclara Resources Inc.',
    name: 'Aclara Resources Inc.',
    symbol: 'ARA.TO',
  },
  {
    value: 'RWAY',
    label: 'RWAY,  Runway Growth Finance Corp.',
    name: 'Runway Growth Finance Corp.',
    symbol: 'RWAY',
  },
  {
    value: 'PEAR',
    label: 'PEAR,  Pear Therapeutics, Inc.',
    name: 'Pear Therapeutics, Inc.',
    symbol: 'PEAR',
  },
  {
    value: 'SEAT',
    label: 'SEAT,  Vivid Seats Inc.',
    name: 'Vivid Seats Inc.',
    symbol: 'SEAT',
  },
  {
    value: 'AZ',
    label: 'AZ,  A2Z Smart Technologies Corp.',
    name: 'A2Z Smart Technologies Corp.',
    symbol: 'AZ',
  },
  {
    value: 'GAST',
    label: 'GAST,  Gabelli Automation ETF',
    name: 'Gabelli Automation ETF',
    symbol: 'GAST',
  },
  {
    value: 'ROCLW',
    label: 'ROCLW,  Roth Ch Acquisition V Co.',
    name: 'Roth Ch Acquisition V Co.',
    symbol: 'ROCLW',
  },
  {
    value: 'ROCL',
    label: 'ROCL,  Roth Ch Acquisition V Co.',
    name: 'Roth Ch Acquisition V Co.',
    symbol: 'ROCL',
  },
  {
    value: 'FCNCO',
    label: 'FCNCO,  First Citizens BancShares, Inc.',
    name: 'First Citizens BancShares, Inc.',
    symbol: 'FCNCO',
  },
  {
    value: 'RENT',
    label: 'RENT,  Rent the Runway, Inc.',
    name: 'Rent the Runway, Inc.',
    symbol: 'RENT',
  },
  {
    value: 'DAVEW',
    label: 'DAVEW,  Dave Inc.',
    name: 'Dave Inc.',
    symbol: 'DAVEW',
  },
  {
    value: 'SCRMU',
    label: 'SCRMU,  Screaming Eagle Acquisition Corp.',
    name: 'Screaming Eagle Acquisition Corp.',
    symbol: 'SCRMU',
  },
  {
    value: 'DAVE',
    label: 'DAVE,  Dave Inc.',
    name: 'Dave Inc.',
    symbol: 'DAVE',
  },
  {
    value: 'APCXW',
    label: 'APCXW,  AppTech Payments Corp.',
    name: 'AppTech Payments Corp.',
    symbol: 'APCXW',
  },
  {
    value: 'HOUR',
    label: 'HOUR,  Hour Loop, Inc.',
    name: 'Hour Loop, Inc.',
    symbol: 'HOUR',
  },
  {
    value: 'CINC',
    label: 'CINC,  CinCor Pharma, Inc.',
    name: 'CinCor Pharma, Inc.',
    symbol: 'CINC',
  },
  {
    value: 'VIGL',
    label: 'VIGL,  Vigil Neuroscience, Inc.',
    name: 'Vigil Neuroscience, Inc.',
    symbol: 'VIGL',
  },
  {
    value: 'HGTY',
    label: 'HGTY,  Hagerty, Inc.',
    name: 'Hagerty, Inc.',
    symbol: 'HGTY',
  },
  {
    value: 'VBOCU',
    label: 'VBOCU,  Viscogliosi Brothers Acquisition Corp.',
    name: 'Viscogliosi Brothers Acquisition Corp.',
    symbol: 'VBOCU',
  },
  {
    value: 'SGLY',
    label: 'SGLY,  Singularity Future Technology Ltd.',
    name: 'Singularity Future Technology Ltd.',
    symbol: 'SGLY',
  },
  {
    value: 'BT-A.L',
    label: 'BT-A.L,  BT Group plc',
    name: 'BT Group plc',
    symbol: 'BT-A.L',
  },
  {
    value: 'FASTAT.ST',
    label: 'FASTAT.ST,  Aktiebolaget Fastator (publ)',
    name: 'Aktiebolaget Fastator (publ)',
    symbol: 'FASTAT.ST',
  },
  {
    value: 'ABLI.ST',
    label: 'ABLI.ST,  Abliva AB (publ)',
    name: 'Abliva AB (publ)',
    symbol: 'ABLI.ST',
  },
  {
    value: 'ACQ-SPAC.ST',
    label: 'ACQ-SPAC.ST,  ACQ Bure AB (publ)',
    name: 'ACQ Bure AB (publ)',
    symbol: 'ACQ-SPAC.ST',
  },
  {
    value: 'ATIC.ST',
    label: 'ATIC.ST,  Actic Group AB (publ)',
    name: 'Actic Group AB (publ)',
    symbol: 'ATIC.ST',
  },
  {
    value: 'ACTI.ST',
    label: 'ACTI.ST,  Active Biotech AB (publ)',
    name: 'Active Biotech AB (publ)',
    symbol: 'ACTI.ST',
  },
  {
    value: 'ALIF-B.ST',
    label: 'ALIF-B.ST,  AddLife AB (publ)',
    name: 'AddLife AB (publ)',
    symbol: 'ALIF-B.ST',
  },
  {
    value: 'ANOD-B.ST',
    label: 'ANOD-B.ST,  Addnode Group AB (publ)',
    name: 'Addnode Group AB (publ)',
    symbol: 'ANOD-B.ST',
  },
  {
    value: 'ADDT-B.ST',
    label: 'ADDT-B.ST,  Addtech AB (publ.)',
    name: 'Addtech AB (publ.)',
    symbol: 'ADDT-B.ST',
  },
  {
    value: 'APAC-SPAC-A.ST',
    label: 'APAC-SPAC-A.ST,  Aligro Planet Acquisition Company AB (publ)',
    name: 'Aligro Planet Acquisition Company AB (publ)',
    symbol: 'APAC-SPAC-A.ST',
  },
  {
    value: 'ATORX.ST',
    label: 'ATORX.ST,  Alligator Bioscience AB (publ)',
    name: 'Alligator Bioscience AB (publ)',
    symbol: 'ATORX.ST',
  },
  {
    value: 'ANNE-B.ST',
    label: 'ANNE-B.ST,  Annehem Fastigheter AB',
    name: 'Annehem Fastigheter AB',
    symbol: 'ANNE-B.ST',
  },
  {
    value: 'ANOT.ST',
    label: 'ANOT.ST,  Anoto Group AB (publ)',
    name: 'Anoto Group AB (publ)',
    symbol: 'ANOT.ST',
  },
  {
    value: 'ATC.WA',
    label: 'ATC.WA,  Arctic Paper S.A.',
    name: 'Arctic Paper S.A.',
    symbol: 'ATC.WA',
  },
  {
    value: 'ARION-SDB.ST',
    label: 'ARION-SDB.ST,  Arion banki hf.',
    name: 'Arion banki hf.',
    symbol: 'ARION-SDB.ST',
  },
  {
    value: 'ARJO-B.ST',
    label: 'ARJO-B.ST,  Arjo AB (publ)',
    name: 'Arjo AB (publ)',
    symbol: 'ARJO-B.ST',
  },
  {
    value: 'ARPL.ST',
    label: 'ARPL.ST,  Arla Plast AB',
    name: 'Arla Plast AB',
    symbol: 'ARPL.ST',
  },
  {
    value: 'ACE.ST',
    label: 'ACE.ST,  Ascelia Pharma AB (publ)',
    name: 'Ascelia Pharma AB (publ)',
    symbol: 'ACE.ST',
  },
  {
    value: 'ATRLJ-B.ST',
    label: 'ATRLJ-B.ST,  Atrium Ljungberg AB (publ)',
    name: 'Atrium Ljungberg AB (publ)',
    symbol: 'ATRLJ-B.ST',
  },
  {
    value: 'ALIV-SDB.ST',
    label: 'ALIV-SDB.ST,  Autoliv, Inc.',
    name: 'Autoliv, Inc.',
    symbol: 'ALIV-SDB.ST',
  },
  {
    value: 'BACTI-B.ST',
    label: 'BACTI-B.ST,  Bactiguard Holding AB (publ)',
    name: 'Bactiguard Holding AB (publ)',
    symbol: 'BACTI-B.ST',
  },
  {
    value: 'BALCO.ST',
    label: 'BALCO.ST,  Balco Group AB',
    name: 'Balco Group AB',
    symbol: 'BALCO.ST',
  },
  {
    value: 'BEGR.ST',
    label: 'BEGR.ST,  BE Group AB (publ)',
    name: 'BE Group AB (publ)',
    symbol: 'BEGR.ST',
  },
  {
    value: 'BEIA-B.ST',
    label: 'BEIA-B.ST,  Beijer Alma AB (publ)',
    name: 'Beijer Alma AB (publ)',
    symbol: 'BEIA-B.ST',
  },
  {
    value: 'BELE.ST',
    label: 'BELE.ST,  Beijer Electronics Group AB (publ)',
    name: 'Beijer Electronics Group AB (publ)',
    symbol: 'BELE.ST',
  },
  {
    value: 'BERG-B.ST',
    label: 'BERG-B.ST,  Bergman & Beving AB (publ)',
    name: 'Bergman & Beving AB (publ)',
    symbol: 'BERG-B.ST',
  },
  {
    value: 'BRG-B.ST',
    label: 'BRG-B.ST,  Bergs Timber AB (publ)',
    name: 'Bergs Timber AB (publ)',
    symbol: 'BRG-B.ST',
  },
  {
    value: 'BESQ.ST',
    label: 'BESQ.ST,  Besqab AB (publ)',
    name: 'Besqab AB (publ)',
    symbol: 'BESQ.ST',
  },
  {
    value: 'BILI-A.ST',
    label: 'BILI-A.ST,  Bilia AB (publ)',
    name: 'Bilia AB (publ)',
    symbol: 'BILI-A.ST',
  },
  {
    value: 'BIOA-B.ST',
    label: 'BIOA-B.ST,  BioArctic AB (publ)',
    name: 'BioArctic AB (publ)',
    symbol: 'BIOA-B.ST',
  },
  {
    value: 'BIOG-B.ST',
    label: 'BIOG-B.ST,  BioGaia AB (publ)',
    name: 'BioGaia AB (publ)',
    symbol: 'BIOG-B.ST',
  },
  {
    value: 'BINV.ST',
    label: 'BINV.ST,  BioInvent International AB (publ)',
    name: 'BioInvent International AB (publ)',
    symbol: 'BINV.ST',
  },
  {
    value: 'BORG.ST',
    label: 'BORG.ST,  Björn Borg AB (publ)',
    name: 'Björn Borg AB (publ)',
    symbol: 'BORG.ST',
  },
  {
    value: 'BONAV-A.ST',
    label: 'BONAV-A.ST,  Bonava AB (publ)',
    name: 'Bonava AB (publ)',
    symbol: 'BONAV-A.ST',
  },
  {
    value: 'BONAV-B.ST',
    label: 'BONAV-B.ST,  Bonava AB (publ)',
    name: 'Bonava AB (publ)',
    symbol: 'BONAV-B.ST',
  },
  {
    value: 'BONG.ST',
    label: 'BONG.ST,  Bong AB (publ)',
    name: 'Bong AB (publ)',
    symbol: 'BONG.ST',
  },
  {
    value: 'BOUL.ST',
    label: 'BOUL.ST,  Boule Diagnostics AB (publ)',
    name: 'Boule Diagnostics AB (publ)',
    symbol: 'BOUL.ST',
  },
  {
    value: 'BRIN-B.ST',
    label: 'BRIN-B.ST,  Brinova Fastigheter AB (publ)',
    name: 'Brinova Fastigheter AB (publ)',
    symbol: 'BRIN-B.ST',
  },
  {
    value: 'BTS-B.ST',
    label: 'BTS-B.ST,  BTS Group AB (publ)',
    name: 'BTS Group AB (publ)',
    symbol: 'BTS-B.ST',
  },
  {
    value: 'BFG.ST',
    label: 'BFG.ST,  Byggfakta Group Nordic HoldCo AB (publ)',
    name: 'Byggfakta Group Nordic HoldCo AB (publ)',
    symbol: 'BFG.ST',
  },
  {
    value: 'CAT-A.ST',
    label: 'CAT-A.ST,  Catella AB (publ)',
    name: 'Catella AB (publ)',
    symbol: 'CAT-A.ST',
  },
  {
    value: 'CAT-B.ST',
    label: 'CAT-B.ST,  Catella AB (publ)',
    name: 'Catella AB (publ)',
    symbol: 'CAT-B.ST',
  },
  {
    value: 'CCC.ST',
    label: 'CCC.ST,  Cavotec SA',
    name: 'Cavotec SA',
    symbol: 'CCC.ST',
  },
  {
    value: 'CBTT-B.ST',
    label: 'CBTT-B.ST,  Christian Berner Tech Trade AB (publ)',
    name: 'Christian Berner Tech Trade AB (publ)',
    symbol: 'CBTT-B.ST',
  },
  {
    value: 'CLAS-B.ST',
    label: 'CLAS-B.ST,  Clas Ohlson AB (publ)',
    name: 'Clas Ohlson AB (publ)',
    symbol: 'CLAS-B.ST',
  },
  {
    value: 'CLA-B.ST',
    label: 'CLA-B.ST,  Cloetta AB (publ)',
    name: 'Cloetta AB (publ)',
    symbol: 'CLA-B.ST',
  },
  {
    value: 'CNCJO-B.ST',
    label: 'CNCJO-B.ST,  Concejo AB (publ)',
    name: 'Concejo AB (publ)',
    symbol: 'CNCJO-B.ST',
  },
  {
    value: 'CCOR-B.ST',
    label: 'CCOR-B.ST,  Concordia Maritime AB (publ)',
    name: 'Concordia Maritime AB (publ)',
    symbol: 'CCOR-B.ST',
  },
  {
    value: 'CORE-PREF.ST',
    label: 'CORE-PREF.ST,  Corem Property Group AB (publ)',
    name: 'Corem Property Group AB (publ)',
    symbol: 'CORE-PREF.ST',
  },
  {
    value: 'CORE-A.ST',
    label: 'CORE-A.ST,  Corem Property Group AB (publ)',
    name: 'Corem Property Group AB (publ)',
    symbol: 'CORE-A.ST',
  },
  {
    value: 'CORE-B.ST',
    label: 'CORE-B.ST,  Corem Property Group AB (publ)',
    name: 'Corem Property Group AB (publ)',
    symbol: 'CORE-B.ST',
  },
  {
    value: 'CORE-D.ST',
    label: 'CORE-D.ST,  Corem Property Group AB (publ)',
    name: 'Corem Property Group AB (publ)',
    symbol: 'CORE-D.ST',
  },
  {
    value: 'CRAD-B.ST',
    label: 'CRAD-B.ST,  C-Rad AB (publ)',
    name: 'C-Rad AB (publ)',
    symbol: 'CRAD-B.ST',
  },
  {
    value: 'CRED-A.ST',
    label: 'CRED-A.ST,  Creades AB',
    name: 'Creades AB',
    symbol: 'CRED-A.ST',
  },
  {
    value: 'CPAC-SPAC.ST',
    label: 'CPAC-SPAC.ST,  Creaspac AB (publ)',
    name: 'Creaspac AB (publ)',
    symbol: 'CPAC-SPAC.ST',
  },
  {
    value: 'CTEK.ST',
    label: 'CTEK.ST,  CTEK AB (publ)',
    name: 'CTEK AB (publ)',
    symbol: 'CTEK.ST',
  },
  {
    value: 'DEDI.ST',
    label: 'DEDI.ST,  Dedicare AB (publ)',
    name: 'Dedicare AB (publ)',
    symbol: 'DEDI.ST',
  },
  {
    value: 'DORO.ST',
    label: 'DORO.ST,  Doro AB (publ)',
    name: 'Doro AB (publ)',
    symbol: 'DORO.ST',
  },
  {
    value: 'DURC-B.ST',
    label: 'DURC-B.ST,  Duroc AB (publ)',
    name: 'Duroc AB (publ)',
    symbol: 'DURC-B.ST',
  },
  {
    value: 'EGTX.ST',
    label: 'EGTX.ST,  Egetis Therapeutics AB (publ)',
    name: 'Egetis Therapeutics AB (publ)',
    symbol: 'EGTX.ST',
  },
  {
    value: 'EPRO-B.ST',
    label: 'EPRO-B.ST,  Electrolux Professional AB (publ)',
    name: 'Electrolux Professional AB (publ)',
    symbol: 'EPRO-B.ST',
  },
  {
    value: 'ELUX-A.ST',
    label: 'ELUX-A.ST,  AB Electrolux (publ)',
    name: 'AB Electrolux (publ)',
    symbol: 'ELUX-A.ST',
  },
  {
    value: 'ELUX-B.ST',
    label: 'ELUX-B.ST,  AB Electrolux (publ)',
    name: 'AB Electrolux (publ)',
    symbol: 'ELUX-B.ST',
  },
  {
    value: 'EKTA-B.ST',
    label: 'EKTA-B.ST,  Elekta AB (publ)',
    name: 'Elekta AB (publ)',
    symbol: 'EKTA-B.ST',
  },
  {
    value: 'ELOS-B.ST',
    label: 'ELOS-B.ST,  Elos Medtech AB (publ)',
    name: 'Elos Medtech AB (publ)',
    symbol: 'ELOS-B.ST',
  },
  {
    value: 'EMPIR-B.ST',
    label: 'EMPIR-B.ST,  Empir Group AB (publ)',
    name: 'Empir Group AB (publ)',
    symbol: 'EMPIR-B.ST',
  },
  {
    value: 'ENDO.ST',
    label: 'ENDO.ST,  Endomines AB (publ)',
    name: 'Endomines AB (publ)',
    symbol: 'ENDO.ST',
  },
  {
    value: 'ENRO.ST',
    label: 'ENRO.ST,  Eniro Group AB (publ)',
    name: 'Eniro Group AB (publ)',
    symbol: 'ENRO.ST',
  },
  {
    value: 'ENRO-PREF-A.ST',
    label: 'ENRO-PREF-A.ST,  Eniro Group AB (publ)',
    name: 'Eniro Group AB (publ)',
    symbol: 'ENRO-PREF-A.ST',
  },
  {
    value: 'ENRO-PREF-B.ST',
    label: 'ENRO-PREF-B.ST,  Eniro Group AB (publ)',
    name: 'Eniro Group AB (publ)',
    symbol: 'ENRO-PREF-B.ST',
  },
  {
    value: 'EOLU-B.ST',
    label: 'EOLU-B.ST,  Eolus Vind AB (publ)',
    name: 'Eolus Vind AB (publ)',
    symbol: 'EOLU-B.ST',
  },
  {
    value: 'EPIS-B.ST',
    label: 'EPIS-B.ST,  Episurf Medical AB (publ)',
    name: 'Episurf Medical AB (publ)',
    symbol: 'EPIS-B.ST',
  },
  {
    value: 'ERIC-A.ST',
    label: 'ERIC-A.ST,  Telefonaktiebolaget LM Ericsson (publ)',
    name: 'Telefonaktiebolaget LM Ericsson (publ)',
    symbol: 'ERIC-A.ST',
  },
  {
    value: 'ESSITY-A.ST',
    label: 'ESSITY-A.ST,  Essity AB (publ)',
    name: 'Essity AB (publ)',
    symbol: 'ESSITY-A.ST',
  },
  {
    value: 'ETRXF',
    label: 'ETRXF,  Etrion Corporation',
    name: 'Etrion Corporation',
    symbol: 'ETRXF',
  },
  {
    value: 'FG.ST',
    label: 'FG.ST,  Fasadgruppen Group AB (publ)',
    name: 'Fasadgruppen Group AB (publ)',
    symbol: 'FG.ST',
  },
  {
    value: 'FPAR-A.ST',
    label: 'FPAR-A.ST,  FastPartner AB (publ)',
    name: 'FastPartner AB (publ)',
    symbol: 'FPAR-A.ST',
  },
  {
    value: 'FPAR-D.ST',
    label: 'FPAR-D.ST,  FastPartner AB (publ)',
    name: 'FastPartner AB (publ)',
    symbol: 'FPAR-D.ST',
  },
  {
    value: 'FOI-B.ST',
    label: 'FOI-B.ST,  Fenix Outdoor International AG',
    name: 'Fenix Outdoor International AG',
    symbol: 'FOI-B.ST',
  },
  {
    value: 'FING-B.ST',
    label: 'FING-B.ST,  Fingerprint Cards AB (publ)',
    name: 'Fingerprint Cards AB (publ)',
    symbol: 'FING-B.ST',
  },
  {
    value: 'FMM-B.ST',
    label: 'FMM-B.ST,  FM Mattsson Mora Group AB (publ)',
    name: 'FM Mattsson Mora Group AB (publ)',
    symbol: 'FMM-B.ST',
  },
  {
    value: 'FPIP.ST',
    label: 'FPIP.ST,  Formpipe Software AB (publ)',
    name: 'Formpipe Software AB (publ)',
    symbol: 'FPIP.ST',
  },
  {
    value: 'GPG.ST',
    label: 'GPG.ST,  Genova Property Group AB (publ)',
    name: 'Genova Property Group AB (publ)',
    symbol: 'GPG.ST',
  },
  {
    value: 'GETI-B.ST',
    label: 'GETI-B.ST,  Getinge AB (publ)',
    name: 'Getinge AB (publ)',
    symbol: 'GETI-B.ST',
  },
  {
    value: 'GREEN.ST',
    label: 'GREEN.ST,  Green Landscaping Group AB (publ)',
    name: 'Green Landscaping Group AB (publ)',
    symbol: 'GREEN.ST',
  },
  {
    value: 'HANZA.ST',
    label: 'HANZA.ST,  Hanza AB (publ)',
    name: 'Hanza AB (publ)',
    symbol: 'HANZA.ST',
  },
  {
    value: 'HAV-B.ST',
    label: 'HAV-B.ST,  Havsfrun Investment AB (publ)',
    name: 'Havsfrun Investment AB (publ)',
    symbol: 'HAV-B.ST',
  },
  {
    value: 'HEBA-B.ST',
    label: 'HEBA-B.ST,  Heba Fastighets AB (publ)',
    name: 'Heba Fastighets AB (publ)',
    symbol: 'HEBA-B.ST',
  },
  {
    value: 'HEXA-B.ST',
    label: 'HEXA-B.ST,  Hexagon AB (publ)',
    name: 'Hexagon AB (publ)',
    symbol: 'HEXA-B.ST',
  },
  {
    value: 'HOLM-A.ST',
    label: 'HOLM-A.ST,  Holmen AB (publ)',
    name: 'Holmen AB (publ)',
    symbol: 'HOLM-A.ST',
  },
  {
    value: 'HUFV-A.ST',
    label: 'HUFV-A.ST,  Hufvudstaden AB (publ)',
    name: 'Hufvudstaden AB (publ)',
    symbol: 'HUFV-A.ST',
  },
  {
    value: 'HUSQ-A.ST',
    label: 'HUSQ-A.ST,  Husqvarna AB (publ)',
    name: 'Husqvarna AB (publ)',
    symbol: 'HUSQ-A.ST',
  },
  {
    value: 'HUSQ-B.ST',
    label: 'HUSQ-B.ST,  Husqvarna AB (publ)',
    name: 'Husqvarna AB (publ)',
    symbol: 'HUSQ-B.ST',
  },
  {
    value: 'IS.ST',
    label: 'IS.ST,  Image Systems AB',
    name: 'Image Systems AB',
    symbol: 'IS.ST',
  },
  {
    value: 'INDU-A.ST',
    label: 'INDU-A.ST,  AB Industrivärden (publ)',
    name: 'AB Industrivärden (publ)',
    symbol: 'INDU-A.ST',
  },
  {
    value: 'INDU-C.ST',
    label: 'INDU-C.ST,  AB Industrivärden (publ)',
    name: 'AB Industrivärden (publ)',
    symbol: 'INDU-C.ST',
  },
  {
    value: 'IBT-B.ST',
    label: 'IBT-B.ST,  Infant Bacterial Therapeutics AB (publ)',
    name: 'Infant Bacterial Therapeutics AB (publ)',
    symbol: 'IBT-B.ST',
  },
  {
    value: 'INFREA.ST',
    label: 'INFREA.ST,  Infrea AB',
    name: 'Infrea AB',
    symbol: 'INFREA.ST',
  },
  {
    value: 'INVE-A.ST',
    label: 'INVE-A.ST,  Investor AB (publ)',
    name: 'Investor AB (publ)',
    symbol: 'INVE-A.ST',
  },
  {
    value: 'IRLAB-A.ST',
    label: 'IRLAB-A.ST,  IRLAB Therapeutics AB (publ)',
    name: 'IRLAB Therapeutics AB (publ)',
    symbol: 'IRLAB-A.ST',
  },
  {
    value: 'IRRAS.ST',
    label: 'IRRAS.ST,  IRRAS AB (publ)',
    name: 'IRRAS AB (publ)',
    symbol: 'IRRAS.ST',
  },
  {
    value: 'ISOFOL.ST',
    label: 'ISOFOL.ST,  Isofol Medical AB (publ)',
    name: 'Isofol Medical AB (publ)',
    symbol: 'ISOFOL.ST',
  },
  {
    value: 'ITAB.ST',
    label: 'ITAB.ST,  ITAB Shop Concept AB (publ)',
    name: 'ITAB Shop Concept AB (publ)',
    symbol: 'ITAB.ST',
  },
  {
    value: 'JOMA.ST',
    label: 'JOMA.ST,  John Mattson Fastighetsföretagen AB (publ)',
    name: 'John Mattson Fastighetsföretagen AB (publ)',
    symbol: 'JOMA.ST',
  },
  {
    value: 'K2A-PREF.ST',
    label: 'K2A-PREF.ST,  K2A Knaust & Andersson Fastigheter AB (publ)',
    name: 'K2A Knaust & Andersson Fastigheter AB (publ)',
    symbol: 'K2A-PREF.ST',
  },
  {
    value: 'K2A-B.ST',
    label: 'K2A-B.ST,  K2A Knaust & Andersson Fastigheter AB (publ)',
    name: 'K2A Knaust & Andersson Fastigheter AB (publ)',
    symbol: 'K2A-B.ST',
  },
  {
    value: 'KABE-B.ST',
    label: 'KABE-B.ST,  KABE Group AB (publ.)',
    name: 'KABE Group AB (publ.)',
    symbol: 'KABE-B.ST',
  },
  {
    value: 'KDEV.ST',
    label: 'KDEV.ST,  Karolinska Development AB (publ)',
    name: 'Karolinska Development AB (publ)',
    symbol: 'KDEV.ST',
  },
  {
    value: 'KFAST-B.ST',
    label: 'KFAST-B.ST,  K-Fast Holding AB (publ)',
    name: 'K-Fast Holding AB (publ)',
    symbol: 'KFAST-B.ST',
  },
  {
    value: 'KIND-SDB.ST',
    label: 'KIND-SDB.ST,  Kindred Group plc',
    name: 'Kindred Group plc',
    symbol: 'KIND-SDB.ST',
  },
  {
    value: 'KINV-A.ST',
    label: 'KINV-A.ST,  Kinnevik AB',
    name: 'Kinnevik AB',
    symbol: 'KINV-A.ST',
  },
  {
    value: 'KINV-B.ST',
    label: 'KINV-B.ST,  Kinnevik AB',
    name: 'Kinnevik AB',
    symbol: 'KINV-B.ST',
  },
  {
    value: 'LAGR-B.ST',
    label: 'LAGR-B.ST,  Lagercrantz Group AB (publ)',
    name: 'Lagercrantz Group AB (publ)',
    symbol: 'LAGR-B.ST',
  },
  {
    value: 'LAMM-B.ST',
    label: 'LAMM-B.ST,  Lammhults Design Group AB (publ)',
    name: 'Lammhults Design Group AB (publ)',
    symbol: 'LAMM-B.ST',
  },
  {
    value: 'LATO-B.ST',
    label: 'LATO-B.ST,  Investment AB Latour (publ)',
    name: 'Investment AB Latour (publ)',
    symbol: 'LATO-B.ST',
  },
  {
    value: 'LIFCO-B.ST',
    label: 'LIFCO-B.ST,  Lifco AB (publ)',
    name: 'Lifco AB (publ)',
    symbol: 'LIFCO-B.ST',
  },
  {
    value: 'LINC.ST',
    label: 'LINC.ST,  Linc AB',
    name: 'Linc AB',
    symbol: 'LINC.ST',
  },
  {
    value: 'LOGI-A.ST',
    label: 'LOGI-A.ST,  Logistea AB (publ)',
    name: 'Logistea AB (publ)',
    symbol: 'LOGI-A.ST',
  },
  {
    value: 'LOGI-B.ST',
    label: 'LOGI-B.ST,  Logistea AB (publ)',
    name: 'Logistea AB (publ)',
    symbol: 'LOGI-B.ST',
  },
  {
    value: 'LUND-B.ST',
    label: 'LUND-B.ST,  L E Lundbergföretagen AB (publ)',
    name: 'L E Lundbergföretagen AB (publ)',
    symbol: 'LUND-B.ST',
  },
  {
    value: 'MAHA-A.ST',
    label: 'MAHA-A.ST,  Maha Energy AB (publ)',
    name: 'Maha Energy AB (publ)',
    symbol: 'MAHA-A.ST',
  },
  {
    value: 'MEAB-B.ST',
    label: 'MEAB-B.ST,  Malmbergs Elektriska AB (publ)',
    name: 'Malmbergs Elektriska AB (publ)',
    symbol: 'MEAB-B.ST',
  },
  {
    value: 'MCOV-B.ST',
    label: 'MCOV-B.ST,  Medicover AB (publ)',
    name: 'Medicover AB (publ)',
    symbol: 'MCOV-B.ST',
  },
  {
    value: 'MVIR-B.ST',
    label: 'MVIR-B.ST,  Medivir AB (publ)',
    name: 'Medivir AB (publ)',
    symbol: 'MVIR-B.ST',
  },
  {
    value: 'MSAB-B.ST',
    label: 'MSAB-B.ST,  Micro Systemation AB (publ)',
    name: 'Micro Systemation AB (publ)',
    symbol: 'MSAB-B.ST',
  },
  {
    value: 'MSON-A.ST',
    label: 'MSON-A.ST,  Midsona AB (publ)',
    name: 'Midsona AB (publ)',
    symbol: 'MSON-A.ST',
  },
  {
    value: '9KF.F',
    label: '9KF.F,  Midsona AB (publ)',
    name: 'Midsona AB (publ)',
    symbol: '9KF.F',
  },
  {
    value: 'MIDW-A.ST',
    label: 'MIDW-A.ST,  Midway Holding AB (publ)',
    name: 'Midway Holding AB (publ)',
    symbol: 'MIDW-A.ST',
  },
  {
    value: 'MIDW-B.ST',
    label: 'MIDW-B.ST,  Midway Holding AB (publ)',
    name: 'Midway Holding AB (publ)',
    symbol: 'MIDW-B.ST',
  },
  {
    value: 'MILDEF.ST',
    label: 'MILDEF.ST,  MilDef Group AB (publ)',
    name: 'MilDef Group AB (publ)',
    symbol: 'MILDEF.ST',
  },
  {
    value: 'TIGO-SDB.ST',
    label: 'TIGO-SDB.ST,  Millicom International Cellular S.A.',
    name: 'Millicom International Cellular S.A.',
    symbol: 'TIGO-SDB.ST',
  },
  {
    value: 'MOB.ST',
    label: 'MOB.ST,  Moberg Pharma AB (publ)',
    name: 'Moberg Pharma AB (publ)',
    symbol: 'MOB.ST',
  },
  {
    value: 'MTG-A.ST',
    label: 'MTG-A.ST,  Modern Times Group MTG AB (publ)',
    name: 'Modern Times Group MTG AB (publ)',
    symbol: 'MTG-A.ST',
  },
  {
    value: 'MTG-B.ST',
    label: 'MTG-B.ST,  Modern Times Group MTG AB (publ)',
    name: 'Modern Times Group MTG AB (publ)',
    symbol: 'MTG-B.ST',
  },
  {
    value: 'MOMENT.ST',
    label: 'MOMENT.ST,  Moment Group AB',
    name: 'Moment Group AB',
    symbol: 'MOMENT.ST',
  },
  {
    value: 'ALLIGO-B.ST',
    label: 'ALLIGO-B.ST,  Alligo AB (publ)',
    name: 'Alligo AB (publ)',
    symbol: 'ALLIGO-B.ST',
  },
  {
    value: 'NAXS.ST',
    label: 'NAXS.ST,  NAXS AB (publ)',
    name: 'NAXS AB (publ)',
    symbol: 'NAXS.ST',
  },
  {
    value: 'NCC-A.ST',
    label: 'NCC-A.ST,  NCC AB (publ)',
    name: 'NCC AB (publ)',
    symbol: 'NCC-A.ST',
  },
  {
    value: '0OFP.IL',
    label: '0OFP.IL,  NCC AB (publ)',
    name: 'NCC AB (publ)',
    symbol: '0OFP.IL',
  },
  {
    value: 'NMAN.ST',
    label: 'NMAN.ST,  Nederman Holding AB (publ)',
    name: 'Nederman Holding AB (publ)',
    symbol: 'NMAN.ST',
  },
  {
    value: 'NETI-B.ST',
    label: 'NETI-B.ST,  Net Insight AB (publ)',
    name: 'Net Insight AB (publ)',
    symbol: 'NETI-B.ST',
  },
  {
    value: 'NETEL.ST',
    label: 'NETEL.ST,  Netel Holding AB (publ)',
    name: 'Netel Holding AB (publ)',
    symbol: 'NETEL.ST',
  },
  {
    value: 'NEWA-B.ST',
    label: 'NEWA-B.ST,  New Wave Group AB (publ)',
    name: 'New Wave Group AB (publ)',
    symbol: 'NEWA-B.ST',
  },
  {
    value: 'NGS.ST',
    label: 'NGS.ST,  NGS Group AB (publ)',
    name: 'NGS Group AB (publ)',
    symbol: 'NGS.ST',
  },
  {
    value: 'NIL-B.ST',
    label: 'NIL-B.ST,  Nilörngruppen AB',
    name: 'Nilörngruppen AB',
    symbol: 'NIL-B.ST',
  },
  {
    value: 'NOLA-B.ST',
    label: 'NOLA-B.ST,  Nolato AB (publ)',
    name: 'Nolato AB (publ)',
    symbol: 'NOLA-B.ST',
  },
  {
    value: 'NDA-SE.ST',
    label: 'NDA-SE.ST,  Nordea Bank Abp',
    name: 'Nordea Bank Abp',
    symbol: 'NDA-SE.ST',
  },
  {
    value: 'NPAPER.ST',
    label: 'NPAPER.ST,  Nordic Paper Holding AB (publ)',
    name: 'Nordic Paper Holding AB (publ)',
    symbol: 'NPAPER.ST',
  },
  {
    value: 'NORB-B.ST',
    label: 'NORB-B.ST,  Nordisk Bergteknik AB (publ)',
    name: 'Nordisk Bergteknik AB (publ)',
    symbol: 'NORB-B.ST',
  },
  {
    value: 'NTEK-B.ST',
    label: 'NTEK-B.ST,  Novotek AB',
    name: 'Novotek AB',
    symbol: 'NTEK-B.ST',
  },
  {
    value: 'NP3-PREF.ST',
    label: 'NP3-PREF.ST,  NP3 Fastigheter AB (publ)',
    name: 'NP3 Fastigheter AB (publ)',
    symbol: 'NP3-PREF.ST',
  },
  {
    value: 'OEM-B.ST',
    label: 'OEM-B.ST,  OEM International AB (publ)',
    name: 'OEM International AB (publ)',
    symbol: 'OEM-B.ST',
  },
  {
    value: 'ORTI-A.ST',
    label: 'ORTI-A.ST,  Ortivus AB (publ)',
    name: 'Ortivus AB (publ)',
    symbol: 'ORTI-A.ST',
  },
  {
    value: 'ORTI-B.ST',
    label: 'ORTI-B.ST,  Ortivus AB (publ)',
    name: 'Ortivus AB (publ)',
    symbol: 'ORTI-B.ST',
  },
  {
    value: 'OP.ST',
    label: 'OP.ST,  Oscar Properties Holding AB (publ)',
    name: 'Oscar Properties Holding AB (publ)',
    symbol: 'OP.ST',
  },
  {
    value: 'OP-PREF.ST',
    label: 'OP-PREF.ST,  Oscar Properties Holding AB (publ)',
    name: 'Oscar Properties Holding AB (publ)',
    symbol: 'OP-PREF.ST',
  },
  {
    value: 'OP-PREFB.ST',
    label: 'OP-PREFB.ST,  Oscar Properties Holding AB (publ)',
    name: 'Oscar Properties Holding AB (publ)',
    symbol: 'OP-PREFB.ST',
  },
  {
    value: 'PNDX-B.ST',
    label: 'PNDX-B.ST,  Pandox AB (publ)',
    name: 'Pandox AB (publ)',
    symbol: 'PNDX-B.ST',
  },
  {
    value: 'PEAB-B.ST',
    label: 'PEAB-B.ST,  Peab AB (publ)',
    name: 'Peab AB (publ)',
    symbol: 'PEAB-B.ST',
  },
  {
    value: 'PLAZ-B.ST',
    label: 'PLAZ-B.ST,  Platzer Fastigheter Holding AB (publ)',
    name: 'Platzer Fastigheter Holding AB (publ)',
    symbol: 'PLAZ-B.ST',
  },
  {
    value: 'PREC.ST',
    label: 'PREC.ST,  Precise Biometrics AB (publ)',
    name: 'Precise Biometrics AB (publ)',
    symbol: 'PREC.ST',
  },
  {
    value: 'PREV-B.ST',
    label: 'PREV-B.ST,  Prevas AB',
    name: 'Prevas AB',
    symbol: 'PREV-B.ST',
  },
  {
    value: 'PRIC-B.ST',
    label: 'PRIC-B.ST,  Pricer AB (publ)',
    name: 'Pricer AB (publ)',
    symbol: 'PRIC-B.ST',
  },
  {
    value: 'PROB.ST',
    label: 'PROB.ST,  Probi AB (publ)',
    name: 'Probi AB (publ)',
    symbol: 'PROB.ST',
  },
  {
    value: 'PROF-B.ST',
    label: 'PROF-B.ST,  ProfilGruppen AB (publ)',
    name: 'ProfilGruppen AB (publ)',
    symbol: 'PROF-B.ST',
  },
  {
    value: 'PRFO.ST',
    label: 'PRFO.ST,  Profoto Holding AB (publ)',
    name: 'Profoto Holding AB (publ)',
    symbol: 'PRFO.ST',
  },
  {
    value: 'PENG-B.ST',
    label: 'PENG-B.ST,  Projektengagemang Sweden AB (publ)',
    name: 'Projektengagemang Sweden AB (publ)',
    symbol: 'PENG-B.ST',
  },
  {
    value: 'QLINEA.ST',
    label: 'QLINEA.ST,  Q-linea AB (publ)',
    name: 'Q-linea AB (publ)',
    symbol: 'QLINEA.ST',
  },
  {
    value: 'QLIRO.ST',
    label: 'QLIRO.ST,  Qliro AB (publ)',
    name: 'Qliro AB (publ)',
    symbol: 'QLIRO.ST',
  },
  {
    value: 'RAIL.ST',
    label: 'RAIL.ST,  Railcare Group AB (publ)',
    name: 'Railcare Group AB (publ)',
    symbol: 'RAIL.ST',
  },
  {
    value: 'RATO-A.ST',
    label: 'RATO-A.ST,  Ratos AB (publ)',
    name: 'Ratos AB (publ)',
    symbol: 'RATO-A.ST',
  },
  {
    value: 'RATO-B.ST',
    label: 'RATO-B.ST,  Ratos AB (publ)',
    name: 'Ratos AB (publ)',
    symbol: 'RATO-B.ST',
  },
  {
    value: 'RAY-B.ST',
    label: 'RAY-B.ST,  RaySearch Laboratories AB (publ)',
    name: 'RaySearch Laboratories AB (publ)',
    symbol: 'RAY-B.ST',
  },
  {
    value: 'REJL-B.ST',
    label: 'REJL-B.ST,  Rejlers AB (publ)',
    name: 'Rejlers AB (publ)',
    symbol: 'REJL-B.ST',
  },
  {
    value: 'RIZZO-B.ST',
    label: 'RIZZO-B.ST,  Rizzo Group AB (publ)',
    name: 'Rizzo Group AB (publ)',
    symbol: 'RIZZO-B.ST',
  },
  {
    value: 'RROS.ST',
    label: 'RROS.ST,  Rottneros AB (publ)',
    name: 'Rottneros AB (publ)',
    symbol: 'RROS.ST',
  },
  {
    value: 'RVRC.ST',
    label: 'RVRC.ST,  RVRC Holding AB (publ)',
    name: 'RVRC Holding AB (publ)',
    symbol: 'RVRC.ST',
  },
  {
    value: '0QDX.IL',
    label: '0QDX.IL,  AB Sagax (publ)',
    name: 'AB Sagax (publ)',
    symbol: '0QDX.IL',
  },
  {
    value: 'SAGA-D.ST',
    label: 'SAGA-D.ST,  AB Sagax (publ)',
    name: 'AB Sagax (publ)',
    symbol: 'SAGA-D.ST',
  },
  {
    value: 'SBB-B.ST',
    label: 'SBB-B.ST,  Samhällsbyggnadsbolaget i Norden AB (publ)',
    name: 'Samhällsbyggnadsbolaget i Norden AB (publ)',
    symbol: 'SBB-B.ST',
  },
  {
    value: 'SBB-D.ST',
    label: 'SBB-D.ST,  Samhällsbyggnadsbolaget i Norden AB (publ)',
    name: 'Samhällsbyggnadsbolaget i Norden AB (publ)',
    symbol: 'SBB-D.ST',
  },
  {
    value: 'SANION.ST',
    label: 'SANION.ST,  Saniona AB (publ)',
    name: 'Saniona AB (publ)',
    symbol: 'SANION.ST',
  },
  {
    value: 'SDIP-PREF.ST',
    label: 'SDIP-PREF.ST,  Sdiptech AB (publ)',
    name: 'Sdiptech AB (publ)',
    symbol: 'SDIP-PREF.ST',
  },
  {
    value: 'SDIP-B.ST',
    label: 'SDIP-B.ST,  Sdiptech AB (publ)',
    name: 'Sdiptech AB (publ)',
    symbol: 'SDIP-B.ST',
  },
  {
    value: '0A0L.IL',
    label: '0A0L.IL,  Sectra AB (publ)',
    name: 'Sectra AB (publ)',
    symbol: '0A0L.IL',
  },
  {
    value: 'SEMC.ST',
    label: 'SEMC.ST,  Semcon AB (publ)',
    name: 'Semcon AB (publ)',
    symbol: 'SEMC.ST',
  },
  {
    value: 'SEZI.ST',
    label: 'SEZI.ST,  Senzime AB (publ)',
    name: 'Senzime AB (publ)',
    symbol: 'SEZI.ST',
  },
  {
    value: 'SRNKE-B.ST',
    label: 'SRNKE-B.ST,  Serneke Group AB (publ)',
    name: 'Serneke Group AB (publ)',
    symbol: 'SRNKE-B.ST',
  },
  {
    value: 'SINT.ST',
    label: 'SINT.ST,  SinterCast AB (publ)',
    name: 'SinterCast AB (publ)',
    symbol: 'SINT.ST',
  },
  {
    value: 'SIVE.ST',
    label: 'SIVE.ST,  Sivers Semiconductors AB (publ)',
    name: 'Sivers Semiconductors AB (publ)',
    symbol: 'SIVE.ST',
  },
  {
    value: 'SEBC.F',
    label: 'SEBC.F,  Skandinaviska Enskilda Banken AB (publ)',
    name: 'Skandinaviska Enskilda Banken AB (publ)',
    symbol: 'SEBC.F',
  },
  {
    value: 'SKF-A.ST',
    label: 'SKF-A.ST,  AB SKF (publ)',
    name: 'AB SKF (publ)',
    symbol: 'SKF-A.ST',
  },
  {
    value: 'SKIS-B.ST',
    label: 'SKIS-B.ST,  SkiStar AB (publ)',
    name: 'SkiStar AB (publ)',
    symbol: 'SKIS-B.ST',
  },
  {
    value: 'SLEEP.ST',
    label: 'SLEEP.ST,  Sleep Cycle AB (publ)',
    name: 'Sleep Cycle AB (publ)',
    symbol: 'SLEEP.ST',
  },
  {
    value: 'SSAB-A.ST',
    label: 'SSAB-A.ST,  SSAB AB (publ)',
    name: 'SSAB AB (publ)',
    symbol: 'SSAB-A.ST',
  },
  {
    value: 'SSAB-B.ST',
    label: 'SSAB-B.ST,  SSAB AB (publ)',
    name: 'SSAB AB (publ)',
    symbol: 'SSAB-B.ST',
  },
  {
    value: 'STAR-A.ST',
    label: 'STAR-A.ST,  Starbreeze AB (publ)',
    name: 'Starbreeze AB (publ)',
    symbol: 'STAR-A.ST',
  },
  {
    value: 'STAR-B.ST',
    label: 'STAR-B.ST,  Starbreeze AB (publ)',
    name: 'Starbreeze AB (publ)',
    symbol: 'STAR-B.ST',
  },
  {
    value: 'STEF-B.ST',
    label: 'STEF-B.ST,  Stendörren Fastigheter AB (publ)',
    name: 'Stendörren Fastigheter AB (publ)',
    symbol: 'STEF-B.ST',
  },
  {
    value: 'STWK.ST',
    label: 'STWK.ST,  Stockwik Förvaltning AB (publ)',
    name: 'Stockwik Förvaltning AB (publ)',
    symbol: 'STWK.ST',
  },
  {
    value: 'STE-A.ST',
    label: 'STE-A.ST,  Stora Enso Oyj',
    name: 'Stora Enso Oyj',
    symbol: 'STE-A.ST',
  },
  {
    value: 'STE-R.ST',
    label: 'STE-R.ST,  Stora Enso Oyj',
    name: 'Stora Enso Oyj',
    symbol: 'STE-R.ST',
  },
  {
    value: 'STOR-B.ST',
    label: 'STOR-B.ST,  Storskogen Group AB (publ)',
    name: 'Storskogen Group AB (publ)',
    symbol: 'STOR-B.ST',
  },
  {
    value: 'SVIK.ST',
    label: 'SVIK.ST,  Studsvik AB (publ)',
    name: 'Studsvik AB (publ)',
    symbol: 'SVIK.ST',
  },
  {
    value: 'SVED-B.ST',
    label: 'SVED-B.ST,  Svedbergs i Dalstorp AB (publ)',
    name: 'Svedbergs i Dalstorp AB (publ)',
    symbol: 'SVED-B.ST',
  },
  {
    value: 'SCA-B.ST',
    label: 'SCA-B.ST,  Svenska Cellulosa Aktiebolaget SCA (publ)',
    name: 'Svenska Cellulosa Aktiebolaget SCA (publ)',
    symbol: 'SCA-B.ST',
  },
  {
    value: 'SHB-B.ST',
    label: 'SHB-B.ST,  Svenska Handelsbanken AB (publ)',
    name: 'Svenska Handelsbanken AB (publ)',
    symbol: 'SHB-B.ST',
  },
  {
    value: 'SVOL-A.ST',
    label: 'SVOL-A.ST,  Svolder AB (publ)',
    name: 'Svolder AB (publ)',
    symbol: 'SVOL-A.ST',
  },
  {
    value: 'SVOL-B.ST',
    label: 'SVOL-B.ST,  Svolder AB (publ)',
    name: 'Svolder AB (publ)',
    symbol: 'SVOL-B.ST',
  },
  {
    value: 'SWEC-A.ST',
    label: 'SWEC-A.ST,  Sweco AB (publ)',
    name: 'Sweco AB (publ)',
    symbol: 'SWEC-A.ST',
  },
  {
    value: 'SWEC-B.ST',
    label: 'SWEC-B.ST,  Sweco AB (publ)',
    name: 'Sweco AB (publ)',
    symbol: 'SWEC-B.ST',
  },
  {
    value: 'SYNSAM.ST',
    label: 'SYNSAM.ST,  Synsam AB (publ)',
    name: 'Synsam AB (publ)',
    symbol: 'SYNSAM.ST',
  },
  {
    value: 'TEL2-A.ST',
    label: 'TEL2-A.ST,  Tele2 AB (publ)',
    name: 'Tele2 AB (publ)',
    symbol: 'TEL2-A.ST',
  },
  {
    value: 'TFBANK.ST',
    label: 'TFBANK.ST,  TF Bank AB (publ)',
    name: 'TF Bank AB (publ)',
    symbol: 'TFBANK.ST',
  },
  {
    value: 'TRAC-B.ST',
    label: 'TRAC-B.ST,  AB Traction',
    name: 'AB Traction',
    symbol: 'TRAC-B.ST',
  },
  {
    value: 'TRAD.ST',
    label: 'TRAD.ST,  TradeDoubler AB (publ)',
    name: 'TradeDoubler AB (publ)',
    symbol: 'TRAD.ST',
  },
  {
    value: 'TREL-B.ST',
    label: 'TREL-B.ST,  Trelleborg AB (publ)',
    name: 'Trelleborg AB (publ)',
    symbol: 'TREL-B.ST',
  },
  {
    value: 'TRIAN-B.ST',
    label: 'TRIAN-B.ST,  Fastighets AB Trianon (publ)',
    name: 'Fastighets AB Trianon (publ)',
    symbol: 'TRIAN-B.ST',
  },
  {
    value: 'TRUE-B.ST',
    label: 'TRUE-B.ST,  Truecaller AB (publ)',
    name: 'Truecaller AB (publ)',
    symbol: 'TRUE-B.ST',
  },
  {
    value: 'VBG-B.ST',
    label: 'VBG-B.ST,  VBG Group AB (publ)',
    name: 'VBG Group AB (publ)',
    symbol: 'VBG-B.ST',
  },
  {
    value: 'VICO.ST',
    label: 'VICO.ST,  Vicore Pharma Holding AB (publ)',
    name: 'Vicore Pharma Holding AB (publ)',
    symbol: 'VICO.ST',
  },
  {
    value: 'VSSAB-B.ST',
    label: 'VSSAB-B.ST,  Viking Supply Ships AB (publ)',
    name: 'Viking Supply Ships AB (publ)',
    symbol: 'VSSAB-B.ST',
  },
  {
    value: 'VIT-B.ST',
    label: 'VIT-B.ST,  Vitec Software Group AB (publ)',
    name: 'Vitec Software Group AB (publ)',
    symbol: 'VIT-B.ST',
  },
  {
    value: 'VOLO.ST',
    label: 'VOLO.ST,  Volati AB',
    name: 'Volati AB',
    symbol: 'VOLO.ST',
  },
  {
    value: 'VOLO-PREF.ST',
    label: 'VOLO-PREF.ST,  Volati AB',
    name: 'Volati AB',
    symbol: 'VOLO-PREF.ST',
  },
  {
    value: 'VOLCAR-B.ST',
    label: 'VOLCAR-B.ST,  Volvo Car AB (publ.)',
    name: 'Volvo Car AB (publ.)',
    symbol: 'VOLCAR-B.ST',
  },
  {
    value: 'VOLV-A.ST',
    label: 'VOLV-A.ST,  AB Volvo (publ)',
    name: 'AB Volvo (publ)',
    symbol: 'VOLV-A.ST',
  },
  {
    value: 'WALL-B.ST',
    label: 'WALL-B.ST,  Wallenstam AB (publ)',
    name: 'Wallenstam AB (publ)',
    symbol: 'WALL-B.ST',
  },
  {
    value: 'WBGR-B.ST',
    label: 'WBGR-B.ST,  Wästbygg Gruppen AB (publ)',
    name: 'Wästbygg Gruppen AB (publ)',
    symbol: 'WBGR-B.ST',
  },
  {
    value: 'WISE.ST',
    label: 'WISE.ST,  Wise Group AB (publ)',
    name: 'Wise Group AB (publ)',
    symbol: 'WISE.ST',
  },
  {
    value: 'XANO-B.ST',
    label: 'XANO-B.ST,  XANO Industri AB (publ)',
    name: 'XANO Industri AB (publ)',
    symbol: 'XANO-B.ST',
  },
  {
    value: 'XBRANE.ST',
    label: 'XBRANE.ST,  Xbrane Biopharma AB (publ)',
    name: 'Xbrane Biopharma AB (publ)',
    symbol: 'XBRANE.ST',
  },
  {
    value: 'XSPRAY.ST',
    label: 'XSPRAY.ST,  Xspray Pharma AB (publ)',
    name: 'Xspray Pharma AB (publ)',
    symbol: 'XSPRAY.ST',
  },
  {
    value: '2CUREX.ST',
    label: '2CUREX.ST,  2cureX AB (publ)',
    name: '2cureX AB (publ)',
    symbol: '2CUREX.ST',
  },
  {
    value: 'AAC.ST',
    label: 'AAC.ST,  AAC Clyde Space AB (publ)',
    name: 'AAC Clyde Space AB (publ)',
    symbol: 'AAC.ST',
  },
  {
    value: 'ABSO.ST',
    label: 'ABSO.ST,  Absolent Air Care Group AB (publ)',
    name: 'Absolent Air Care Group AB (publ)',
    symbol: 'ABSO.ST',
  },
  {
    value: 'ACARIX.ST',
    label: 'ACARIX.ST,  Acarix AB (publ)',
    name: 'Acarix AB (publ)',
    symbol: 'ACARIX.ST',
  },
  {
    value: 'ACAST.ST',
    label: 'ACAST.ST,  Acast AB (publ)',
    name: 'Acast AB (publ)',
    symbol: 'ACAST.ST',
  },
  {
    value: 'ACOU.ST',
    label: 'ACOU.ST,  AcouSort AB (publ)',
    name: 'AcouSort AB (publ)',
    symbol: 'ACOU.ST',
  },
  {
    value: 'ACRI-A.ST',
    label: 'ACRI-A.ST,  Acrinova AB (publ)',
    name: 'Acrinova AB (publ)',
    symbol: 'ACRI-A.ST',
  },
  {
    value: 'ACRI-B.ST',
    label: 'ACRI-B.ST,  Acrinova AB (publ)',
    name: 'Acrinova AB (publ)',
    symbol: 'ACRI-B.ST',
  },
  {
    value: 'ADDERA.ST',
    label: 'ADDERA.ST,  AdderaCare AB',
    name: 'AdderaCare AB',
    symbol: 'ADDERA.ST',
  },
  {
    value: 'ADDV-A.ST',
    label: 'ADDV-A.ST,  ADDvise Group AB (publ)',
    name: 'ADDvise Group AB (publ)',
    symbol: 'ADDV-A.ST',
  },
  {
    value: 'ADDV-B.ST',
    label: 'ADDV-B.ST,  ADDvise Group AB (publ)',
    name: 'ADDvise Group AB (publ)',
    symbol: 'ADDV-B.ST',
  },
  {
    value: 'ASAB.ST',
    label: 'ASAB.ST,  Advanced Soltech Sweden AB (publ)',
    name: 'Advanced Soltech Sweden AB (publ)',
    symbol: 'ASAB.ST',
  },
  {
    value: 'ADVE.ST',
    label: 'ADVE.ST,  Advenica AB (publ)',
    name: 'Advenica AB (publ)',
    symbol: 'ADVE.ST',
  },
  {
    value: 'ADVBOX.ST',
    label: 'ADVBOX.ST,  Adventure Box Technology AB (publ)',
    name: 'Adventure Box Technology AB (publ)',
    symbol: 'ADVBOX.ST',
  },
  {
    value: 'AFE.V',
    label: 'AFE.V,  Africa Energy Corp.',
    name: 'Africa Energy Corp.',
    symbol: 'AFE.V',
  },
  {
    value: 'AGES-B.ST',
    label: 'AGES-B.ST,  AGES Industri AB (publ)',
    name: 'AGES Industri AB (publ)',
    symbol: 'AGES-B.ST',
  },
  {
    value: 'AINO.ST',
    label: 'AINO.ST,  Aino Health AB (publ)',
    name: 'Aino Health AB (publ)',
    symbol: 'AINO.ST',
  },
  {
    value: 'AKEL-D.ST',
    label: 'AKEL-D.ST,  Akelius Residential Property AB (publ)',
    name: 'Akelius Residential Property AB (publ)',
    symbol: 'AKEL-D.ST',
  },
  {
    value: 'ALCA.ST',
    label: 'ALCA.ST,  Alcadon Group AB (publ)',
    name: 'Alcadon Group AB (publ)',
    symbol: 'ALCA.ST',
  },
  {
    value: 'ALELIO.ST',
    label: 'ALELIO.ST,  Alelion Energy Systems AB (publ)',
    name: 'Alelion Energy Systems AB (publ)',
    symbol: 'ALELIO.ST',
  },
  {
    value: 'ALM.ST',
    label: 'ALM.ST,  ALM Equity AB (publ)',
    name: 'ALM Equity AB (publ)',
    symbol: 'ALM.ST',
  },
  {
    value: 'ALM-PREF.ST',
    label: 'ALM-PREF.ST,  ALM Equity AB (publ)',
    name: 'ALM Equity AB (publ)',
    symbol: 'ALM-PREF.ST',
  },
  {
    value: 'ALPCOT-B.ST',
    label: 'ALPCOT-B.ST,  Alpcot Holding AB (publ)',
    name: 'Alpcot Holding AB (publ)',
    symbol: 'ALPCOT-B.ST',
  },
  {
    value: 'ALZCUR.ST',
    label: 'ALZCUR.ST,  AlzeCure Pharma AB (publ)',
    name: 'AlzeCure Pharma AB (publ)',
    symbol: 'ALZCUR.ST',
  },
  {
    value: 'ALZ.ST',
    label: 'ALZ.ST,  Alzinova AB (publ)',
    name: 'Alzinova AB (publ)',
    symbol: 'ALZ.ST',
  },
  {
    value: 'AMNI.ST',
    label: 'AMNI.ST,  Amniotics AB (publ)',
    name: 'Amniotics AB (publ)',
    symbol: 'AMNI.ST',
  },
  {
    value: 'ANNX.ST',
    label: 'ANNX.ST,  Annexin Pharmaceuticals AB (publ)',
    name: 'Annexin Pharmaceuticals AB (publ)',
    symbol: 'ANNX.ST',
  },
  {
    value: 'APTR.ST',
    label: 'APTR.ST,  AppSpotr AB (publ)',
    name: 'AppSpotr AB (publ)',
    symbol: 'APTR.ST',
  },
  {
    value: 'APRNDR.ST',
    label: 'APRNDR.ST,  Aprendere Skolor AB (publ)',
    name: 'Aprendere Skolor AB (publ)',
    symbol: 'APRNDR.ST',
  },
  {
    value: 'ARCANE.ST',
    label: 'ARCANE.ST,  Arcane Crypto AB',
    name: 'Arcane Crypto AB',
    symbol: 'ARCANE.ST',
  },
  {
    value: 'ARCOMA.ST',
    label: 'ARCOMA.ST,  Arcoma AB',
    name: 'Arcoma AB',
    symbol: 'ARCOMA.ST',
  },
  {
    value: 'ARCT.ST',
    label: 'ARCT.ST,  Arctic Minerals AB (publ)',
    name: 'Arctic Minerals AB (publ)',
    symbol: 'ARCT.ST',
  },
  {
    value: 'AGROUP.ST',
    label: 'AGROUP.ST,  Arlandastad Group AB (publ)',
    name: 'Arlandastad Group AB (publ)',
    symbol: 'AGROUP.ST',
  },
  {
    value: 'AROC.ST',
    label: 'AROC.ST,  AroCell AB (publ)',
    name: 'AroCell AB (publ)',
    symbol: 'AROC.ST',
  },
  {
    value: 'AROS.ST',
    label: 'AROS.ST,  Aros Bostadsutveckling AB (publ)',
    name: 'Aros Bostadsutveckling AB (publ)',
    symbol: 'AROS.ST',
  },
  {
    value: 'AROS-PREF-B.ST',
    label: 'AROS-PREF-B.ST,  Aros Bostadsutveckling AB (publ)',
    name: 'Aros Bostadsutveckling AB (publ)',
    symbol: 'AROS-PREF-B.ST',
  },
  {
    value: 'ASAI.ST',
    label: 'ASAI.ST,  Artificial Solutions International AB (publ)',
    name: 'Artificial Solutions International AB (publ)',
    symbol: 'ASAI.ST',
  },
  {
    value: 'ASAP.ST',
    label: 'ASAP.ST,  Asarina Pharma AB (publ)',
    name: 'Asarina Pharma AB (publ)',
    symbol: 'ASAP.ST',
  },
  {
    value: 'ATIN.ST',
    label: 'ATIN.ST,  Athanase Innovation AB (publ)',
    name: 'Athanase Innovation AB (publ)',
    symbol: 'ATIN.ST',
  },
  {
    value: 'AUR.ST',
    label: 'AUR.ST,  Auriant Mining AB (publ)',
    name: 'Auriant Mining AB (publ)',
    symbol: 'AUR.ST',
  },
  {
    value: 'AVENT-B.ST',
    label: 'AVENT-B.ST,  Aventura Group AB (publ)',
    name: 'Aventura Group AB (publ)',
    symbol: 'AVENT-B.ST',
  },
  {
    value: 'AVT-B.ST',
    label: 'AVT-B.ST,  AVTECH Sweden AB (publ)',
    name: 'AVTECH Sweden AB (publ)',
    symbol: 'AVT-B.ST',
  },
  {
    value: 'AWRD.ST',
    label: 'AWRD.ST,  Awardit AB (publ)',
    name: 'Awardit AB (publ)',
    symbol: 'AWRD.ST',
  },
  {
    value: 'AXIC-A.ST',
    label: 'AXIC-A.ST,  aXichem AB',
    name: 'aXichem AB',
    symbol: 'AXIC-A.ST',
  },
  {
    value: 'AXOLOT.ST',
    label: 'AXOLOT.ST,  Axolot Solutions Holding AB (publ)',
    name: 'Axolot Solutions Holding AB (publ)',
    symbol: 'AXOLOT.ST',
  },
  {
    value: 'AYIMA-B.ST',
    label: 'AYIMA-B.ST,  Ayima Group AB (publ)',
    name: 'Ayima Group AB (publ)',
    symbol: 'AYIMA-B.ST',
  },
  {
    value: 'BUSER.ST',
    label: 'BUSER.ST,  Bambuser AB (publ)',
    name: 'Bambuser AB (publ)',
    symbol: 'BUSER.ST',
  },
  {
    value: 'BIM.ST',
    label: 'BIM.ST,  BIMobject AB',
    name: 'BIMobject AB',
    symbol: 'BIM.ST',
  },
  {
    value: 'BINERO.ST',
    label: 'BINERO.ST,  Binero Group AB (publ)',
    name: 'Binero Group AB (publ)',
    symbol: 'BINERO.ST',
  },
  {
    value: 'BIOSGN.ST',
    label: 'BIOSGN.ST,  Biosergen AB',
    name: 'Biosergen AB',
    symbol: 'BIOSGN.ST',
  },
  {
    value: 'BIOS.ST',
    label: 'BIOS.ST,  Bioservo Technologies AB (publ)',
    name: 'Bioservo Technologies AB (publ)',
    symbol: 'BIOS.ST',
  },
  {
    value: 'BIOVIC-B.ST',
    label: 'BIOVIC-B.ST,  Biovica International AB (publ)',
    name: 'Biovica International AB (publ)',
    symbol: 'BIOVIC-B.ST',
  },
  {
    value: 'BIOWKS.ST',
    label: 'BIOWKS.ST,  Bio-Works Technologies AB (publ)',
    name: 'Bio-Works Technologies AB (publ)',
    symbol: 'BIOWKS.ST',
  },
  {
    value: 'BOKUS.ST',
    label: 'BOKUS.ST,  Bokusgruppen AB (publ)',
    name: 'Bokusgruppen AB (publ)',
    symbol: 'BOKUS.ST',
  },
  {
    value: 'BOMILL.ST',
    label: 'BOMILL.ST,  BoMill AB (publ)',
    name: 'BoMill AB (publ)',
    symbol: 'BOMILL.ST',
  },
  {
    value: 'BONAS.ST',
    label: 'BONAS.ST,  Bonäsudden Holding AB (publ)',
    name: 'Bonäsudden Holding AB (publ)',
    symbol: 'BONAS.ST',
  },
  {
    value: 'BONZUN.ST',
    label: 'BONZUN.ST,  Bonzun AB (publ)',
    name: 'Bonzun AB (publ)',
    symbol: 'BONZUN.ST',
  },
  {
    value: 'BOTX.ST',
    label: 'BOTX.ST,  Botnia Exploration Holding AB (publ)',
    name: 'Botnia Exploration Holding AB (publ)',
    symbol: 'BOTX.ST',
  },
  {
    value: 'BRICK-B.ST',
    label: 'BRICK-B.ST,  Bricknode Holding AB (publ)',
    name: 'Bricknode Holding AB (publ)',
    symbol: 'BRICK-B.ST',
  },
  {
    value: 'BRILL.ST',
    label: 'BRILL.ST,  Brilliant Future AB (publ)',
    name: 'Brilliant Future AB (publ)',
    symbol: 'BRILL.ST',
  },
  {
    value: 'BUILD.ST',
    label: 'BUILD.ST,  BuildData Group AB (publ)',
    name: 'BuildData Group AB (publ)',
    symbol: 'BUILD.ST',
  },
  {
    value: 'AJA-B.ST',
    label: 'AJA-B.ST,  Byggmästare Anders J Ahlström Holding AB (publ)',
    name: 'Byggmästare Anders J Ahlström Holding AB (publ)',
    symbol: 'AJA-B.ST',
  },
  {
    value: 'BYGGP.ST',
    label: 'BYGGP.ST,  ByggPartner Gruppen AB (publ)',
    name: 'ByggPartner Gruppen AB (publ)',
    symbol: 'BYGGP.ST',
  },
  {
    value: 'CAG.ST',
    label: 'CAG.ST,  CAG Group AB (publ)',
    name: 'CAG Group AB (publ)',
    symbol: 'CAG.ST',
  },
  {
    value: 'CANDLE-B.ST',
    label: 'CANDLE-B.ST,  Candles Scandinavia AB (publ)',
    name: 'Candles Scandinavia AB (publ)',
    symbol: 'CANDLE-B.ST',
  },
  {
    value: 'CEDER.ST',
    label: 'CEDER.ST,  Cedergrenska AB (publ)',
    name: 'Cedergrenska AB (publ)',
    symbol: 'CEDER.ST',
  },
  {
    value: 'CHECK.ST',
    label: 'CHECK.ST,  Checkin.Com Group AB (publ)',
    name: 'Checkin.Com Group AB (publ)',
    symbol: 'CHECK.ST',
  },
  {
    value: 'CHRO.ST',
    label: 'CHRO.ST,  ChromoGenics AB',
    name: 'ChromoGenics AB',
    symbol: 'CHRO.ST',
  },
  {
    value: 'CIRCHE.ST',
    label: 'CIRCHE.ST,  CirChem AB (publ)',
    name: 'CirChem AB (publ)',
    symbol: 'CIRCHE.ST',
  },
  {
    value: 'CLAV.ST',
    label: 'CLAV.ST,  Clavister Holding AB (publ.)',
    name: 'Clavister Holding AB (publ.)',
    symbol: 'CLAV.ST',
  },
  {
    value: 'CISH.ST',
    label: 'CISH.ST,  Clean Industry Solutions Holding Europe AB',
    name: 'Clean Industry Solutions Holding Europe AB',
    symbol: 'CISH.ST',
  },
  {
    value: 'CLEMO.ST',
    label: 'CLEMO.ST,  Clean Motion AB (publ)',
    name: 'Clean Motion AB (publ)',
    symbol: 'CLEMO.ST',
  },
  {
    value: 'CLEM.ST',
    label: 'CLEM.ST,  Clemondo Group AB (publ)',
    name: 'Clemondo Group AB (publ)',
    symbol: 'CLEM.ST',
  },
  {
    value: 'CLIME-B.ST',
    label: 'CLIME-B.ST,  Climeon AB (publ)',
    name: 'Climeon AB (publ)',
    symbol: 'CLIME-B.ST',
  },
  {
    value: 'CLINE-B.ST',
    label: 'CLINE-B.ST,  Cline Scientific AB (publ)',
    name: 'Cline Scientific AB (publ)',
    symbol: 'CLINE-B.ST',
  },
  {
    value: 'CLS-B.ST',
    label: 'CLS-B.ST,  Clinical Laserthermia Systems AB (publ)',
    name: 'Clinical Laserthermia Systems AB (publ)',
    symbol: 'CLS-B.ST',
  },
  {
    value: 'COALA.ST',
    label: 'COALA.ST,  Coala-Life Group AB (publ)',
    name: 'Coala-Life Group AB (publ)',
    symbol: 'COALA.ST',
  },
  {
    value: 'CDMIL.ST',
    label: 'CDMIL.ST,  CodeMill AB (publ)',
    name: 'CodeMill AB (publ)',
    symbol: 'CDMIL.ST',
  },
  {
    value: 'CS.ST',
    label: 'CS.ST,  CoinShares International Limited',
    name: 'CoinShares International Limited',
    symbol: 'CS.ST',
  },
  {
    value: 'COMBI.ST',
    label: 'COMBI.ST,  CombiGene AB (publ)',
    name: 'CombiGene AB (publ)',
    symbol: 'COMBI.ST',
  },
  {
    value: 'COMPDM.ST',
    label: 'COMPDM.ST,  Compodium International AB (publ)',
    name: 'Compodium International AB (publ)',
    symbol: 'COMPDM.ST',
  },
  {
    value: 'LEVEL.ST',
    label: 'LEVEL.ST,  Nordic LEVEL Group AB (publ.)',
    name: 'Nordic LEVEL Group AB (publ.)',
    symbol: 'LEVEL.ST',
  },
  {
    value: 'COPP-B.ST',
    label: 'COPP-B.ST,  Copperstone Resources AB',
    name: 'Copperstone Resources AB',
    symbol: 'COPP-B.ST',
  },
  {
    value: 'CLBIO.ST',
    label: 'CLBIO.ST,  Corline Biomedical AB',
    name: 'Corline Biomedical AB',
    symbol: 'CLBIO.ST',
  },
  {
    value: 'CE.ST',
    label: 'CE.ST,  Cortus Energy AB (publ)',
    name: 'Cortus Energy AB (publ)',
    symbol: 'CE.ST',
  },
  {
    value: 'CFISH.ST',
    label: 'CFISH.ST,  Crunchfish AB (publ)',
    name: 'Crunchfish AB (publ)',
    symbol: 'CFISH.ST',
  },
  {
    value: 'CYB1.ST',
    label: 'CYB1.ST,  Cyber Security 1 AB (publ)',
    name: 'Cyber Security 1 AB (publ)',
    symbol: 'CYB1.ST',
  },
  {
    value: 'CYXO.ST',
    label: 'CYXO.ST,  Cyxone AB (publ)',
    name: 'Cyxone AB (publ)',
    symbol: 'CYXO.ST',
  },
  {
    value: 'DDM.ST',
    label: 'DDM.ST,  DDM Holding AG',
    name: 'DDM Holding AG',
    symbol: 'DDM.ST',
  },
  {
    value: 'DIAH.ST',
    label: 'DIAH.ST,  Diadrom Holding AB (publ)',
    name: 'Diadrom Holding AB (publ)',
    symbol: 'DIAH.ST',
  },
  {
    value: 'DIABIO.ST',
    label: 'DIABIO.ST,  Diagonal Bio AB (publ)',
    name: 'Diagonal Bio AB (publ)',
    symbol: 'DIABIO.ST',
  },
  {
    value: 'DMYD-B.ST',
    label: 'DMYD-B.ST,  Diamyd Medical AB (publ)',
    name: 'Diamyd Medical AB (publ)',
    symbol: 'DMYD-B.ST',
  },
  {
    value: 'DIGN.ST',
    label: 'DIGN.ST,  Dignitana AB (publ)',
    name: 'Dignitana AB (publ)',
    symbol: 'DIGN.ST',
  },
  {
    value: 'DIST.ST',
    label: 'DIST.ST,  DistIT AB (publ)',
    name: 'DistIT AB (publ)',
    symbol: 'DIST.ST',
  },
  {
    value: 'DIVIO-B.ST',
    label: 'DIVIO-B.ST,  Divio Technologies AB (publ)',
    name: 'Divio Technologies AB (publ)',
    symbol: 'DIVIO-B.ST',
  },
  {
    value: 'DLAB.ST',
    label: 'DLAB.ST,  Dlaboratory Sweden AB (publ)',
    name: 'Dlaboratory Sweden AB (publ)',
    symbol: 'DLAB.ST',
  },
  {
    value: 'DOXA.ST',
    label: 'DOXA.ST,  Doxa AB (publ)',
    name: 'Doxa AB (publ)',
    symbol: 'DOXA.ST',
  },
  {
    value: 'DRIL.ST',
    label: 'DRIL.ST,  Drillcon AB (publ)',
    name: 'Drillcon AB (publ)',
    symbol: 'DRIL.ST',
  },
  {
    value: 'DEAR.ST',
    label: 'DEAR.ST,  Duearity AB (publ)',
    name: 'Duearity AB (publ)',
    symbol: 'DEAR.ST',
  },
  {
    value: 'ECC-B.ST',
    label: 'ECC-B.ST,  Ecoclime Group AB (publ)',
    name: 'Ecoclime Group AB (publ)',
    symbol: 'ECC-B.ST',
  },
  {
    value: 'ALBERT.ST',
    label: 'ALBERT.ST,  eEducation Albert AB (publ)',
    name: 'eEducation Albert AB (publ)',
    symbol: 'ALBERT.ST',
  },
  {
    value: 'EFFH.ST',
    label: 'EFFH.ST,  Effnetplattformen Holding AB (publ)',
    name: 'Effnetplattformen Holding AB (publ)',
    symbol: 'EFFH.ST',
  },
  {
    value: 'EKOBOT.ST',
    label: 'EKOBOT.ST,  Ekobot AB (publ)',
    name: 'Ekobot AB (publ)',
    symbol: 'EKOBOT.ST',
  },
  {
    value: 'ELIC.ST',
    label: 'ELIC.ST,  Elicera Therapeutics AB (publ)',
    name: 'Elicera Therapeutics AB (publ)',
    symbol: 'ELIC.ST',
  },
  {
    value: 'ELN.ST',
    label: 'ELN.ST,  Ellen AB (publ)',
    name: 'Ellen AB (publ)',
    symbol: 'ELN.ST',
  },
  {
    value: 'ELLWEE.ST',
    label: 'ELLWEE.ST,  Ellwee AB (publ)',
    name: 'Ellwee AB (publ)',
    symbol: 'ELLWEE.ST',
  },
  {
    value: 'EMBELL.ST',
    label: 'EMBELL.ST,  Embellence Group AB (publ)',
    name: 'Embellence Group AB (publ)',
    symbol: 'EMBELL.ST',
  },
  {
    value: 'EMPLI.ST',
    label: 'EMPLI.ST,  Emplicure AB (publ)',
    name: 'Emplicure AB (publ)',
    symbol: 'EMPLI.ST',
  },
  {
    value: 'ENERS.ST',
    label: 'ENERS.ST,  Enersize Oyj',
    name: 'Enersize Oyj',
    symbol: 'ENERS.ST',
  },
  {
    value: 'ERMA.ST',
    label: 'ERMA.ST,  Enorama Pharma AB (publ)',
    name: 'Enorama Pharma AB (publ)',
    symbol: 'ERMA.ST',
  },
  {
    value: 'EURA.ST',
    label: 'EURA.ST,  Euroafrica Digital Ventures AB (publ)',
    name: 'Euroafrica Digital Ventures AB (publ)',
    symbol: 'EURA.ST',
  },
  {
    value: 'EXS.ST',
    label: 'EXS.ST,  Exsitec Holding AB (publ)',
    name: 'Exsitec Holding AB (publ)',
    symbol: 'EXS.ST',
  },
  {
    value: 'FAGA.ST',
    label: 'FAGA.ST,  Fantasma Games AB (publ)',
    name: 'Fantasma Games AB (publ)',
    symbol: 'FAGA.ST',
  },
  {
    value: 'EMIL-PREF.ST',
    label: 'EMIL-PREF.ST,  Fastighetsbolaget Emilshus AB (publ)',
    name: 'Fastighetsbolaget Emilshus AB (publ)',
    symbol: 'EMIL-PREF.ST',
  },
  {
    value: 'FERRO.ST',
    label: 'FERRO.ST,  Ferroamp AB (publ)',
    name: 'Ferroamp AB (publ)',
    symbol: 'FERRO.ST',
  },
  {
    value: 'FIL.TO',
    label: 'FIL.TO,  Filo Mining Corp.',
    name: 'Filo Mining Corp.',
    symbol: 'FIL.TO',
  },
  {
    value: 'FIRE.ST',
    label: 'FIRE.ST,  Firefly AB (publ)',
    name: 'Firefly AB (publ)',
    symbol: 'FIRE.ST',
  },
  {
    value: 'FIRST-B.ST',
    label: 'FIRST-B.ST,  First Venture Sweden AB (publ)',
    name: 'First Venture Sweden AB (publ)',
    symbol: 'FIRST-B.ST',
  },
  {
    value: 'FLAT-B.ST',
    label: 'FLAT-B.ST,  Flat Capital AB (publ)',
    name: 'Flat Capital AB (publ)',
    symbol: 'FLAT-B.ST',
  },
  {
    value: 'FLEXM.ST',
    label: 'FLEXM.ST,  Flexion Mobile Plc',
    name: 'Flexion Mobile Plc',
    symbol: 'FLEXM.ST',
  },
  {
    value: 'FLEXQ.ST',
    label: 'FLEXQ.ST,  FlexQube AB (publ)',
    name: 'FlexQube AB (publ)',
    symbol: 'FLEXQ.ST',
  },
  {
    value: 'FLUI.ST',
    label: 'FLUI.ST,  Fluicell AB (publ)',
    name: 'Fluicell AB (publ)',
    symbol: 'FLUI.ST',
  },
  {
    value: 'FLUO.ST',
    label: 'FLUO.ST,  FluoGuide A/S',
    name: 'FluoGuide A/S',
    symbol: 'FLUO.ST',
  },
  {
    value: 'FOOT-B.ST',
    label: 'FOOT-B.ST,  Footway Group AB (publ)',
    name: 'Footway Group AB (publ)',
    symbol: 'FOOT-B.ST',
  },
  {
    value: 'FNOVA-B.ST',
    label: 'FNOVA-B.ST,  Fortinova Fastigheter AB (Publ)',
    name: 'Fortinova Fastigheter AB (Publ)',
    symbol: 'FNOVA-B.ST',
  },
  {
    value: 'FRACTL.ST',
    label: 'FRACTL.ST,  Fractal Gaming Group AB (publ)',
    name: 'Fractal Gaming Group AB (publ)',
    symbol: 'FRACTL.ST',
  },
  {
    value: 'FRAG.ST',
    label: 'FRAG.ST,  Fragbite Group AB (publ)',
    name: 'Fragbite Group AB (publ)',
    symbol: 'FRAG.ST',
  },
  {
    value: 'FREEM.ST',
    label: 'FREEM.ST,  Freemelt Holding AB (publ)',
    name: 'Freemelt Holding AB (publ)',
    symbol: 'FREEM.ST',
  },
  {
    value: 'FREJA.ST',
    label: 'FREJA.ST,  Freja eID Group AB (publ)',
    name: 'Freja eID Group AB (publ)',
    symbol: 'FREJA.ST',
  },
  {
    value: 'FRISQ.ST',
    label: 'FRISQ.ST,  FRISQ Holding AB (publ)',
    name: 'FRISQ Holding AB (publ)',
    symbol: 'FRISQ.ST',
  },
  {
    value: 'FSPORT.ST',
    label: 'FSPORT.ST,  FSport AB (publ)',
    name: 'FSport AB (publ)',
    symbol: 'FSPORT.ST',
  },
  {
    value: 'GABA.ST',
    label: 'GABA.ST,  Gabather AB (publ)',
    name: 'Gabather AB (publ)',
    symbol: 'GABA.ST',
  },
  {
    value: 'GCOR.ST',
    label: 'GCOR.ST,  Gaming Corps AB (publ)',
    name: 'Gaming Corps AB (publ)',
    symbol: 'GCOR.ST',
  },
  {
    value: 'GAPW-B.ST',
    label: 'GAPW-B.ST,  Gapwaves AB (publ)',
    name: 'Gapwaves AB (publ)',
    symbol: 'GAPW-B.ST',
  },
  {
    value: 'GPX.ST',
    label: 'GPX.ST,  Gasporox AB (publ)',
    name: 'Gasporox AB (publ)',
    symbol: 'GPX.ST',
  },
  {
    value: 'GENI.ST',
    label: 'GENI.ST,  Generic Sweden AB',
    name: 'Generic Sweden AB',
    symbol: 'GENI.ST',
  },
  {
    value: 'GIAB.ST',
    label: 'GIAB.ST,  Godsinlösen Nordic AB (publ)',
    name: 'Godsinlösen Nordic AB (publ)',
    symbol: 'GIAB.ST',
  },
  {
    value: 'GOMX.ST',
    label: 'GOMX.ST,  GomSpace Group AB (publ)',
    name: 'GomSpace Group AB (publ)',
    symbol: 'GOMX.ST',
  },
  {
    value: 'BTCX.ST',
    label: 'BTCX.ST,  Goobit Group AB (publ)',
    name: 'Goobit Group AB (publ)',
    symbol: 'BTCX.ST',
  },
  {
    value: 'GBK.ST',
    label: 'GBK.ST,  Goodbye Kansas Group AB (publ)',
    name: 'Goodbye Kansas Group AB (publ)',
    symbol: 'GBK.ST',
  },
  {
    value: 'GHUS-B.ST',
    label: 'GHUS-B.ST,  Götenehus Group AB (publ)',
    name: 'Götenehus Group AB (publ)',
    symbol: 'GHUS-B.ST',
  },
  {
    value: 'GREAT.ST',
    label: 'GREAT.ST,  Greater Than AB',
    name: 'Greater Than AB',
    symbol: 'GREAT.ST',
  },
  {
    value: 'GUARD.ST',
    label: 'GUARD.ST,  Guard Therapeutics International AB (publ)',
    name: 'Guard Therapeutics International AB (publ)',
    symbol: 'GUARD.ST',
  },
  {
    value: 'GGEO.ST',
    label: 'GGEO.ST,  Guideline Geo AB (publ)',
    name: 'Guideline Geo AB (publ)',
    symbol: 'GGEO.ST',
  },
  {
    value: 'HEGR.ST',
    label: 'HEGR.ST,  Hedera Group AB (publ)',
    name: 'Hedera Group AB (publ)',
    symbol: 'HEGR.ST',
  },
  {
    value: 'HEIM-PREF.ST',
    label: 'HEIM-PREF.ST,  Heimstaden AB (publ)',
    name: 'Heimstaden AB (publ)',
    symbol: 'HEIM-PREF.ST',
  },
  {
    value: 'HELIO.ST',
    label: 'HELIO.ST,  Heliospectra AB (publ)',
    name: 'Heliospectra AB (publ)',
    symbol: 'HELIO.ST',
  },
  {
    value: 'HEMC.ST',
    label: 'HEMC.ST,  Hemcheck Sweden AB (publ)',
    name: 'Hemcheck Sweden AB (publ)',
    symbol: 'HEMC.ST',
  },
  {
    value: 'HEXI.ST',
    label: 'HEXI.ST,  Hexicon AB (publ)',
    name: 'Hexicon AB (publ)',
    symbol: 'HEXI.ST',
  },
  {
    value: 'HIFA-B.ST',
    label: 'HIFA-B.ST,  Hifab Group AB (publ.)',
    name: 'Hifab Group AB (publ.)',
    symbol: 'HIFA-B.ST',
  },
  {
    value: 'HILB-B.ST',
    label: 'HILB-B.ST,  Hilbert Group AB (publ)',
    name: 'Hilbert Group AB (publ)',
    symbol: 'HILB-B.ST',
  },
  {
    value: 'HDW-B.ST',
    label: 'HDW-B.ST,  Hitech & Development Wireless Sweden Holding AB (publ)',
    name: 'Hitech & Development Wireless Sweden Holding AB (publ)',
    symbol: 'HDW-B.ST',
  },
  {
    value: 'HOYLU.ST',
    label: 'HOYLU.ST,  Hoylu AB (publ)',
    name: 'Hoylu AB (publ)',
    symbol: 'HOYLU.ST',
  },
  {
    value: 'HUMBLE.ST',
    label: 'HUMBLE.ST,  Humble Group AB (publ)',
    name: 'Humble Group AB (publ)',
    symbol: 'HUMBLE.ST',
  },
  {
    value: 'ICO.ST',
    label: 'ICO.ST,  Iconovo AB (publ)',
    name: 'Iconovo AB (publ)',
    symbol: 'ICO.ST',
  },
  {
    value: 'IDOGEN.ST',
    label: 'IDOGEN.ST,  Idogen AB (publ)',
    name: 'Idogen AB (publ)',
    symbol: 'IDOGEN.ST',
  },
  {
    value: 'IDUN-B.ST',
    label: 'IDUN-B.ST,  Idun Industrier AB (publ)',
    name: 'Idun Industrier AB (publ)',
    symbol: 'IDUN-B.ST',
  },
  {
    value: 'IMP-A-SDB.ST',
    label: 'IMP-A-SDB.ST,  Implantica AG',
    name: 'Implantica AG',
    symbol: 'IMP-A-SDB.ST',
  },
  {
    value: 'INCOAX.ST',
    label: 'INCOAX.ST,  InCoax Networks AB (publ)',
    name: 'InCoax Networks AB (publ)',
    symbol: 'INCOAX.ST',
  },
  {
    value: 'INDEX.ST',
    label: 'INDEX.ST,  InDex Pharmaceuticals Holding AB (publ)',
    name: 'InDex Pharmaceuticals Holding AB (publ)',
    symbol: 'INDEX.ST',
  },
  {
    value: 'INISS-B.ST',
    label: 'INISS-B.ST,  Inission AB (publ)',
    name: 'Inission AB (publ)',
    symbol: 'INISS-B.ST',
  },
  {
    value: 'INIT.ST',
    label: 'INIT.ST,  Initiator Pharma A/S',
    name: 'Initiator Pharma A/S',
    symbol: 'INIT.ST',
  },
  {
    value: 'INSP.ST',
    label: 'INSP.ST,  Insplorion AB (publ)',
    name: 'Insplorion AB (publ)',
    symbol: 'INSP.ST',
  },
  {
    value: 'INTEG-B.ST',
    label: 'INTEG-B.ST,  Integrum AB (publ)',
    name: 'Integrum AB (publ)',
    symbol: 'INTEG-B.ST',
  },
  {
    value: 'INT.ST',
    label: 'INT.ST,  Intellego Technologies AB',
    name: 'Intellego Technologies AB',
    symbol: 'INT.ST',
  },
  {
    value: 'EPTI.ST',
    label: 'EPTI.ST,  EPTI AB',
    name: 'EPTI AB',
    symbol: 'EPTI.ST',
  },
  {
    value: 'INZILE.ST',
    label: 'INZILE.ST,  Inzile AB (publ)',
    name: 'Inzile AB (publ)',
    symbol: 'INZILE.ST',
  },
  {
    value: 'IRIS.ST',
    label: 'IRIS.ST,  Irisity AB (publ)',
    name: 'Irisity AB (publ)',
    symbol: 'IRIS.ST',
  },
  {
    value: 'ISR.ST',
    label: 'ISR.ST,  ISR Immune System Regulation Holding AB (publ)',
    name: 'ISR Immune System Regulation Holding AB (publ)',
    symbol: 'ISR.ST',
  },
  {
    value: 'ITECH.ST',
    label: 'ITECH.ST,  I-Tech AB',
    name: 'I-Tech AB',
    symbol: 'ITECH.ST',
  },
  {
    value: 'IZAFE-B.ST',
    label: 'IZAFE-B.ST,  iZafe Group AB (publ)',
    name: 'iZafe Group AB (publ)',
    symbol: 'IZAFE-B.ST',
  },
  {
    value: 'JETPAK.ST',
    label: 'JETPAK.ST,  Jetpak Top Holding AB (publ)',
    name: 'Jetpak Top Holding AB (publ)',
    symbol: 'JETPAK.ST',
  },
  {
    value: 'JDT.ST',
    label: 'JDT.ST,  JonDeTech Sensors AB (publ)',
    name: 'JonDeTech Sensors AB (publ)',
    symbol: 'JDT.ST',
  },
  {
    value: 'KAKEL.ST',
    label: 'KAKEL.ST,  Kakel Max AB (publ)',
    name: 'Kakel Max AB (publ)',
    symbol: 'KAKEL.ST',
  },
  {
    value: 'KAPIAB.ST',
    label: 'KAPIAB.ST,  Kallebäck Property Invest AB (publ)',
    name: 'Kallebäck Property Invest AB (publ)',
    symbol: 'KAPIAB.ST',
  },
  {
    value: 'KEBNI-B.ST',
    label: 'KEBNI-B.ST,  KebNi AB (publ)',
    name: 'KebNi AB (publ)',
    symbol: 'KEBNI-B.ST',
  },
  {
    value: 'KENH.ST',
    label: 'KENH.ST,  Kentima Holding AB (publ)',
    name: 'Kentima Holding AB (publ)',
    symbol: 'KENH.ST',
  },
  {
    value: 'KILI.ST',
    label: 'KILI.ST,  Kiliaro AB (publ)',
    name: 'Kiliaro AB (publ)',
    symbol: 'KILI.ST',
  },
  {
    value: 'KJELL.ST',
    label: 'KJELL.ST,  Kjell Group AB (publ)',
    name: 'Kjell Group AB (publ)',
    symbol: 'KJELL.ST',
  },
  {
    value: 'KLAR.ST',
    label: 'KLAR.ST,  Klaria Pharma Holding AB (publ.)',
    name: 'Klaria Pharma Holding AB (publ.)',
    symbol: 'KLAR.ST',
  },
  {
    value: 'KOLL.ST',
    label: 'KOLL.ST,  Kollect on Demand Holding AB (publ)',
    name: 'Kollect on Demand Holding AB (publ)',
    symbol: 'KOLL.ST',
  },
  {
    value: 'KONT.ST',
    label: 'KONT.ST,  Kontigo Care AB (publ)',
    name: 'Kontigo Care AB (publ)',
    symbol: 'KONT.ST',
  },
  {
    value: 'KOPY.ST',
    label: 'KOPY.ST,  Kopy Goldfields AB (publ)',
    name: 'Kopy Goldfields AB (publ)',
    symbol: 'KOPY.ST',
  },
  {
    value: 'LAUR.ST',
    label: 'LAUR.ST,  Lauritz.com Group A/S',
    name: 'Lauritz.com Group A/S',
    symbol: 'LAUR.ST',
  },
  {
    value: 'LEM.V',
    label: 'LEM.V,  Leading Edge Materials Corp.',
    name: 'Leading Edge Materials Corp.',
    symbol: 'LEM.V',
  },
  {
    value: 'LIDDS.ST',
    label: 'LIDDS.ST,  LIDDS AB (publ)',
    name: 'LIDDS AB (publ)',
    symbol: 'LIDDS.ST',
  },
  {
    value: 'LCLEAN.ST',
    label: 'LCLEAN.ST,  LifeClean International AB (publ)',
    name: 'LifeClean International AB (publ)',
    symbol: 'LCLEAN.ST',
  },
  {
    value: 'LINKAB.ST',
    label: 'LINKAB.ST,  Link Prop Investment AB (publ)',
    name: 'Link Prop Investment AB (publ)',
    symbol: 'LINKAB.ST',
  },
  {
    value: 'LINKFI.ST',
    label: 'LINKFI.ST,  Linkfire A/S',
    name: 'Linkfire A/S',
    symbol: 'LINKFI.ST',
  },
  {
    value: 'LIPI.ST',
    label: 'LIPI.ST,  Lipidor AB (publ)',
    name: 'Lipidor AB (publ)',
    symbol: 'LIPI.ST',
  },
  {
    value: 'LPGO.ST',
    label: 'LPGO.ST,  Lipigon Pharmaceuticals AB (publ)',
    name: 'Lipigon Pharmaceuticals AB (publ)',
    symbol: 'LPGO.ST',
  },
  {
    value: 'LIPUM.ST',
    label: 'LIPUM.ST,  Lipum AB (publ)',
    name: 'Lipum AB (publ)',
    symbol: 'LIPUM.ST',
  },
  {
    value: 'LITI.ST',
    label: 'LITI.ST,  Litium AB (publ)',
    name: 'Litium AB (publ)',
    symbol: 'LITI.ST',
  },
  {
    value: 'LADYLU.ST',
    label: 'LADYLU.ST,  LL Lucky Games AB (publ)',
    name: 'LL Lucky Games AB (publ)',
    symbol: 'LADYLU.ST',
  },
  {
    value: 'LMKG.ST',
    label: 'LMKG.ST,  LMK Group AB (publ)',
    name: 'LMK Group AB (publ)',
    symbol: 'LMKG.ST',
  },
  {
    value: 'LOHILO.ST',
    label: 'LOHILO.ST,  Lohilo Foods AB (publ)',
    name: 'Lohilo Foods AB (publ)',
    symbol: 'LOHILO.ST',
  },
  {
    value: 'LOYAL.ST',
    label: 'LOYAL.ST,  Loyal Solutions A/S',
    name: 'Loyal Solutions A/S',
    symbol: 'LOYAL.ST',
  },
  {
    value: 'LXB.ST',
    label: 'LXB.ST,  Luxbright AB (publ)',
    name: 'Luxbright AB (publ)',
    symbol: 'LXB.ST',
  },
  {
    value: 'LYKO-A.ST',
    label: 'LYKO-A.ST,  Lyko Group AB (publ)',
    name: 'Lyko Group AB (publ)',
    symbol: 'LYKO-A.ST',
  },
  {
    value: 'MOBA.ST',
    label: 'MOBA.ST,  M.O.B.A. Network AB (publ)',
    name: 'M.O.B.A. Network AB (publ)',
    symbol: 'MOBA.ST',
  },
  {
    value: 'MACK-B.ST',
    label: 'MACK-B.ST,  Mackmyra Svensk Whisky AB (publ)',
    name: 'Mackmyra Svensk Whisky AB (publ)',
    symbol: 'MACK-B.ST',
  },
  {
    value: 'MAGLE.ST',
    label: 'MAGLE.ST,  Magle Chemoswed Holding AB (publ)',
    name: 'Magle Chemoswed Holding AB (publ)',
    symbol: 'MAGLE.ST',
  },
  {
    value: 'MANG.ST',
    label: 'MANG.ST,  Mangold Fondkommission AB',
    name: 'Mangold Fondkommission AB',
    symbol: 'MANG.ST',
  },
  {
    value: 'MANTEX.ST',
    label: 'MANTEX.ST,  Mantex AB (publ)',
    name: 'Mantex AB (publ)',
    symbol: 'MANTEX.ST',
  },
  {
    value: 'MAVEN.ST',
    label: 'MAVEN.ST,  Maven Wireless Sweden AB (Publ)',
    name: 'Maven Wireless Sweden AB (Publ)',
    symbol: 'MAVEN.ST',
  },
  {
    value: 'MAV.ST',
    label: 'MAV.ST,  Mavshack AB (publ)',
    name: 'Mavshack AB (publ)',
    symbol: 'MAV.ST',
  },
  {
    value: 'MEDHLP.ST',
    label: 'MEDHLP.ST,  Medhelp Care Aktiebolag (publ)',
    name: 'Medhelp Care Aktiebolag (publ)',
    symbol: 'MEDHLP.ST',
  },
  {
    value: 'MNTC.ST',
    label: 'MNTC.ST,  Mentice AB (publ)',
    name: 'Mentice AB (publ)',
    symbol: 'MNTC.ST',
  },
  {
    value: 'MIDS.ST',
    label: 'MIDS.ST,  Midsummer AB (publ)',
    name: 'Midsummer AB (publ)',
    symbol: 'MIDS.ST',
  },
  {
    value: 'MISE.ST',
    label: 'MISE.ST,  Misen Energy AB (publ)',
    name: 'Misen Energy AB (publ)',
    symbol: 'MISE.ST',
  },
  {
    value: 'MODEL-B.ST',
    label: 'MODEL-B.ST,  Modelon AB (publ)',
    name: 'Modelon AB (publ)',
    symbol: 'MODEL-B.ST',
  },
  {
    value: 'ME.ST',
    label: 'ME.ST,  Modern Ekonomi Sverige Holding AB (publ)',
    name: 'Modern Ekonomi Sverige Holding AB (publ)',
    symbol: 'ME.ST',
  },
  {
    value: 'MODTX.ST',
    label: 'MODTX.ST,  Modus Therapeutics Holding AB (publ)',
    name: 'Modus Therapeutics Holding AB (publ)',
    symbol: 'MODTX.ST',
  },
  {
    value: 'NANEXA.ST',
    label: 'NANEXA.ST,  Nanexa AB (publ)',
    name: 'Nanexa AB (publ)',
    symbol: 'NANEXA.ST',
  },
  {
    value: 'NEPA.ST',
    label: 'NEPA.ST,  Nepa AB (publ)',
    name: 'Nepa AB (publ)',
    symbol: 'NEPA.ST',
  },
  {
    value: 'NJOB.ST',
    label: 'NJOB.ST,  NetJobs Group AB (publ)',
    name: 'NetJobs Group AB (publ)',
    symbol: 'NJOB.ST',
  },
  {
    value: 'NETM-B.ST',
    label: 'NETM-B.ST,  Netmore Group AB (publ)',
    name: 'Netmore Group AB (publ)',
    symbol: 'NETM-B.ST',
  },
  {
    value: 'BBROOM.ST',
    label: 'BBROOM.ST,  New Bubbleroom Sweden AB (publ)',
    name: 'New Bubbleroom Sweden AB (publ)',
    symbol: 'BBROOM.ST',
  },
  {
    value: 'NNH.ST',
    label: 'NNH.ST,  New Nordic Healthbrands AB (publ)',
    name: 'New Nordic Healthbrands AB (publ)',
    symbol: 'NNH.ST',
  },
  {
    value: 'NEXAM.ST',
    label: 'NEXAM.ST,  Nexam Chemical Holding AB (publ)',
    name: 'Nexam Chemical Holding AB (publ)',
    symbol: 'NEXAM.ST',
  },
  {
    value: 'NXTCL.ST',
    label: 'NXTCL.ST,  NextCell Pharma AB',
    name: 'NextCell Pharma AB',
    symbol: 'NXTCL.ST',
  },
  {
    value: 'NGENIC.ST',
    label: 'NGENIC.ST,  Ngenic AB (publ)',
    name: 'Ngenic AB (publ)',
    symbol: 'NGENIC.ST',
  },
  {
    value: 'NICO.ST',
    label: 'NICO.ST,  Nicoccino Holding AB (publ)',
    name: 'Nicoccino Holding AB (publ)',
    symbol: 'NICO.ST',
  },
  {
    value: 'NILAR.ST',
    label: 'NILAR.ST,  Nilar International AB',
    name: 'Nilar International AB',
    symbol: 'NILAR.ST',
  },
  {
    value: 'NILS.ST',
    label: 'NILS.ST,  Nilsson Special Vehicles AB (publ)',
    name: 'Nilsson Special Vehicles AB (publ)',
    symbol: 'NILS.ST',
  },
  {
    value: 'NITRO.ST',
    label: 'NITRO.ST,  Nitro Games Oyj',
    name: 'Nitro Games Oyj',
    symbol: 'NITRO.ST',
  },
  {
    value: 'NORDIG.ST',
    label: 'NORDIG.ST,  Nord Insuretech Group AB (publ)',
    name: 'Nord Insuretech Group AB (publ)',
    symbol: 'NORDIG.ST',
  },
  {
    value: 'NFGAB.ST',
    label: 'NFGAB.ST,  Nordic Flanges Group AB (publ)',
    name: 'Nordic Flanges Group AB (publ)',
    symbol: 'NFGAB.ST',
  },
  {
    value: 'NIO.ST',
    label: 'NIO.ST,  Nordic Iron Ore AB (publ)',
    name: 'Nordic Iron Ore AB (publ)',
    symbol: 'NIO.ST',
  },
  {
    value: 'NOTEK.ST',
    label: 'NOTEK.ST,  Norditek Group AB (publ)',
    name: 'Norditek Group AB (publ)',
    symbol: 'NOTEK.ST',
  },
  {
    value: 'NBZ.ST',
    label: 'NBZ.ST,  Northbaze Group AB (publ)',
    name: 'Northbaze Group AB (publ)',
    symbol: 'NBZ.ST',
  },
  {
    value: 'OGUN-B.ST',
    label: 'OGUN-B.ST,  Ogunsen AB (publ)',
    name: 'Ogunsen AB (publ)',
    symbol: 'OGUN-B.ST',
  },
  {
    value: 'ONCOZ.ST',
    label: 'ONCOZ.ST,  OncoZenge AB (publ)',
    name: 'OncoZenge AB (publ)',
    symbol: 'ONCOZ.ST',
  },
  {
    value: 'OBAB.ST',
    label: 'OBAB.ST,  Online Brands Nordic AB (publ)',
    name: 'Online Brands Nordic AB (publ)',
    symbol: 'OBAB.ST',
  },
  {
    value: 'OPTER.ST',
    label: 'OPTER.ST,  Opter AB (publ)',
    name: 'Opter AB (publ)',
    symbol: 'OPTER.ST',
  },
  {
    value: 'OPTI.ST',
    label: 'OPTI.ST,  OptiCept Technologies AB (publ)',
    name: 'OptiCept Technologies AB (publ)',
    symbol: 'OPTI.ST',
  },
  {
    value: 'ORGC.ST',
    label: 'ORGC.ST,  OrganoClick AB (publ)',
    name: 'OrganoClick AB (publ)',
    symbol: 'ORGC.ST',
  },
  {
    value: 'OSSD.ST',
    label: 'OSSD.ST,  OssDsign AB (publ)',
    name: 'OssDsign AB (publ)',
    symbol: 'OSSD.ST',
  },
  {
    value: 'OX2.ST',
    label: 'OX2.ST,  OX2 AB (publ)',
    name: 'OX2 AB (publ)',
    symbol: 'OX2.ST',
  },
  {
    value: 'OXE.ST',
    label: 'OXE.ST,  OXE Marine AB (publ)',
    name: 'OXE Marine AB (publ)',
    symbol: 'OXE.ST',
  },
  {
    value: 'PAGERO.ST',
    label: 'PAGERO.ST,  Pagero Group Ab (Publ)',
    name: 'Pagero Group Ab (Publ)',
    symbol: 'PAGERO.ST',
  },
  {
    value: 'PAX.ST',
    label: 'PAX.ST,  Paxman AB (publ)',
    name: 'Paxman AB (publ)',
    symbol: 'PAX.ST',
  },
  {
    value: 'PEGRO-PREF.ST',
    label: 'PEGRO-PREF.ST,  Pegroco Invest AB (publ)',
    name: 'Pegroco Invest AB (publ)',
    symbol: 'PEGRO-PREF.ST',
  },
  {
    value: 'PSCAND.ST',
    label: 'PSCAND.ST,  Permascand Top Holding AB (publ)',
    name: 'Permascand Top Holding AB (publ)',
    symbol: 'PSCAND.ST',
  },
  {
    value: 'PHLOG-B.ST',
    label: 'PHLOG-B.ST,  Pharmacolog i Uppsala AB (publ)',
    name: 'Pharmacolog i Uppsala AB (publ)',
    symbol: 'PHLOG-B.ST',
  },
  {
    value: 'PHARM.ST',
    label: 'PHARM.ST,  Pharmiva AB (publ)',
    name: 'Pharmiva AB (publ)',
    symbol: 'PHARM.ST',
  },
  {
    value: 'PCAT.ST',
    label: 'PCAT.ST,  Photocat A/S',
    name: 'Photocat A/S',
    symbol: 'PCAT.ST',
  },
  {
    value: 'PTRK.ST',
    label: 'PTRK.ST,  Physitrack PLC',
    name: 'Physitrack PLC',
    symbol: 'PTRK.ST',
  },
  {
    value: 'PILA.ST',
    label: 'PILA.ST,  Pila Pharma AB (publ)',
    name: 'Pila Pharma AB (publ)',
    symbol: 'PILA.ST',
  },
  {
    value: 'PLEX.ST',
    label: 'PLEX.ST,  Plexian AB (publ)',
    name: 'Plexian AB (publ)',
    symbol: 'PLEX.ST',
  },
  {
    value: 'POLYG.ST',
    label: 'POLYG.ST,  Polygiene Group AB',
    name: 'Polygiene Group AB',
    symbol: 'POLYG.ST',
  },
  {
    value: 'POLY.ST',
    label: 'POLY.ST,  Polyplank AB (publ)',
    name: 'Polyplank AB (publ)',
    symbol: 'POLY.ST',
  },
  {
    value: 'PCOM-B.ST',
    label: 'PCOM-B.ST,  Precomp Solutions AB (publ)',
    name: 'Precomp Solutions AB (publ)',
    symbol: 'PCOM-B.ST',
  },
  {
    value: 'PROMO.ST',
    label: 'PROMO.ST,  Promore Pharma AB (publ)',
    name: 'Promore Pharma AB (publ)',
    symbol: 'PROMO.ST',
  },
  {
    value: 'PLUN.ST',
    label: 'PLUN.ST,  ProstaLund AB (publ)',
    name: 'ProstaLund AB (publ)',
    symbol: 'PLUN.ST',
  },
  {
    value: 'QIIWI.ST',
    label: 'QIIWI.ST,  Qiiwi Games AB (publ)',
    name: 'Qiiwi Games AB (publ)',
    symbol: 'QIIWI.ST',
  },
  {
    value: 'QAIR.ST',
    label: 'QAIR.ST,  QleanAir AB (publ)',
    name: 'QleanAir AB (publ)',
    symbol: 'QAIR.ST',
  },
  {
    value: 'QLIFE.ST',
    label: 'QLIFE.ST,  Qlife Holding AB (publ)',
    name: 'Qlife Holding AB (publ)',
    symbol: 'QLIFE.ST',
  },
  {
    value: 'QCORE.ST',
    label: 'QCORE.ST,  Qlucore AB (publ)',
    name: 'Qlucore AB (publ)',
    symbol: 'QCORE.ST',
  },
  {
    value: 'QUART.ST',
    label: 'QUART.ST,  Quartiers Properties AB (publ)',
    name: 'Quartiers Properties AB (publ)',
    symbol: 'QUART.ST',
  },
  {
    value: 'QUART-PREF.ST',
    label: 'QUART-PREF.ST,  Quartiers Properties AB (publ)',
    name: 'Quartiers Properties AB (publ)',
    symbol: 'QUART-PREF.ST',
  },
  {
    value: 'QUIA.ST',
    label: 'QUIA.ST,  QuiaPEG Pharmaceuticals Holding AB (publ)',
    name: 'QuiaPEG Pharmaceuticals Holding AB (publ)',
    symbol: 'QUIA.ST',
  },
  {
    value: 'RAKE.ST',
    label: 'RAKE.ST,  Raketech Group Holding PLC',
    name: 'Raketech Group Holding PLC',
    symbol: 'RAKE.ST',
  },
  {
    value: 'RPLAN.ST',
    label: 'RPLAN.ST,  Ranplan Group AB',
    name: 'Ranplan Group AB',
    symbol: 'RPLAN.ST',
  },
  {
    value: 'RENEW.ST',
    label: 'RENEW.ST,  Re:NewCell AB (publ)',
    name: 'Re:NewCell AB (publ)',
    symbol: 'RENEW.ST',
  },
  {
    value: 'REALFI.ST',
    label: 'REALFI.ST,  Realfiction Holding AB (publ)',
    name: 'Realfiction Holding AB (publ)',
    symbol: 'REALFI.ST',
  },
  {
    value: 'REDW.ST',
    label: 'REDW.ST,  Redwood Pharma AB',
    name: 'Redwood Pharma AB',
    symbol: 'REDW.ST',
  },
  {
    value: 'RESQ.ST',
    label: 'RESQ.ST,  Resqunit AB (publ)',
    name: 'Resqunit AB (publ)',
    symbol: 'RESQ.ST',
  },
  {
    value: 'RLS.ST',
    label: 'RLS.ST,  RLS Global AB (publ)',
    name: 'RLS Global AB (publ)',
    symbol: 'RLS.ST',
  },
  {
    value: 'RO.ST',
    label: 'RO.ST,  Rolling Optics Holding AB (publ)',
    name: 'Rolling Optics Holding AB (publ)',
    symbol: 'RO.ST',
  },
  {
    value: 'RUG.ST',
    label: 'RUG.ST,  Rugvista Group AB (publ)',
    name: 'Rugvista Group AB (publ)',
    symbol: 'RUG.ST',
  },
  {
    value: 'S2M.ST',
    label: 'S2M.ST,  S2Medical AB (publ)',
    name: 'S2Medical AB (publ)',
    symbol: 'S2M.ST',
  },
  {
    value: 'SFL.ST',
    label: 'SFL.ST,  Safello Group AB (publ)',
    name: 'Safello Group AB (publ)',
    symbol: 'SFL.ST',
  },
  {
    value: 'SALT-B.ST',
    label: 'SALT-B.ST,  SaltX Technology Holding AB (publ)',
    name: 'SaltX Technology Holding AB (publ)',
    symbol: 'SALT-B.ST',
  },
  {
    value: 'YIELD.ST',
    label: 'YIELD.ST,  SaveLend Group AB (publ)',
    name: 'SaveLend Group AB (publ)',
    symbol: 'YIELD.ST',
  },
  {
    value: 'SAXG.ST',
    label: 'SAXG.ST,  Saxlund Group AB (publ)',
    name: 'Saxlund Group AB (publ)',
    symbol: 'SAXG.ST',
  },
  {
    value: 'SBOK.ST',
    label: 'SBOK.ST,  ScandBook Holding AB (publ)',
    name: 'ScandBook Holding AB (publ)',
    symbol: 'SBOK.ST',
  },
  {
    value: 'SDOS.ST',
    label: 'SDOS.ST,  ScandiDos AB (publ)',
    name: 'ScandiDos AB (publ)',
    symbol: 'SDOS.ST',
  },
  {
    value: 'BIOGAS.ST',
    label: 'BIOGAS.ST,  Scandinavian Biogas Fuels International AB (publ)',
    name: 'Scandinavian Biogas Fuels International AB (publ)',
    symbol: 'BIOGAS.ST',
  },
  {
    value: 'CMOTEC-B.ST',
    label: 'CMOTEC-B.ST,  Scandinavian ChemoTech AB (publ)',
    name: 'Scandinavian ChemoTech AB (publ)',
    symbol: 'CMOTEC-B.ST',
  },
  {
    value: 'SES.ST',
    label: 'SES.ST,  Scandinavian Enviro Systems AB (publ)',
    name: 'Scandinavian Enviro Systems AB (publ)',
    symbol: 'SES.ST',
  },
  {
    value: 'SCOL.ST',
    label: 'SCOL.ST,  Scandion Oncology A/S',
    name: 'Scandion Oncology A/S',
    symbol: 'SCOL.ST',
  },
  {
    value: 'SCIB.ST',
    label: 'SCIB.ST,  SciBase Holding AB (publ)',
    name: 'SciBase Holding AB (publ)',
    symbol: 'SCIB.ST',
  },
  {
    value: 'SCOUT.ST',
    label: 'SCOUT.ST,  Scout Gaming Group AB (publ)',
    name: 'Scout Gaming Group AB (publ)',
    symbol: 'SCOUT.ST',
  },
  {
    value: 'SEAF.ST',
    label: 'SEAF.ST,  Seafire AB (publ)',
    name: 'Seafire AB (publ)',
    symbol: 'SEAF.ST',
  },
  {
    value: 'STW.ST',
    label: 'STW.ST,  SeaTwirl AB (publ)',
    name: 'SeaTwirl AB (publ)',
    symbol: 'STW.ST',
  },
  {
    value: 'SECI.ST',
    label: 'SECI.ST,  SECITS Holding AB (publ)',
    name: 'SECITS Holding AB (publ)',
    symbol: 'SECI.ST',
  },
  {
    value: 'SENZA.ST',
    label: 'SENZA.ST,  SenzaGen AB',
    name: 'SenzaGen AB',
    symbol: 'SENZA.ST',
  },
  {
    value: 'SERT.ST',
    label: 'SERT.ST,  Serstech AB',
    name: 'Serstech AB',
    symbol: 'SERT.ST',
  },
  {
    value: 'SNM.V',
    label: 'SNM.V,  ShaMaran Petroleum Corp.',
    name: 'ShaMaran Petroleum Corp.',
    symbol: 'SNM.V',
  },
  {
    value: 'SIGNUP.ST',
    label: 'SIGNUP.ST,  SignUp Software AB (publ)',
    name: 'SignUp Software AB (publ)',
    symbol: 'SIGNUP.ST',
  },
  {
    value: 'SIMRIS-B.ST',
    label: 'SIMRIS-B.ST,  Simris Alg AB (publ)',
    name: 'Simris Alg AB (publ)',
    symbol: 'SIMRIS-B.ST',
  },
  {
    value: 'SKMO.ST',
    label: 'SKMO.ST,  Skåne-möllan AB (publ)',
    name: 'Skåne-möllan AB (publ)',
    symbol: 'SKMO.ST',
  },
  {
    value: 'GOGRID-SDB.ST',
    label: 'GOGRID-SDB.ST,  Smart Wires Technology Ltd.',
    name: 'Smart Wires Technology Ltd.',
    symbol: 'GOGRID-SDB.ST',
  },
  {
    value: 'SODER.ST',
    label: 'SODER.ST,  SÖDer Sportfiske Ab',
    name: 'SÖDer Sportfiske Ab',
    symbol: 'SODER.ST',
  },
  {
    value: 'SOLNA.ST',
    label: 'SOLNA.ST,  Solnaberg Property AB (publ)',
    name: 'Solnaberg Property AB (publ)',
    symbol: 'SOLNA.ST',
  },
  {
    value: 'SONE.ST',
    label: 'SONE.ST,  Sonetel AB (publ)',
    name: 'Sonetel AB (publ)',
    symbol: 'SONE.ST',
  },
  {
    value: 'SOZAP.ST',
    label: 'SOZAP.ST,  SOZAP AB (publ)',
    name: 'SOZAP AB (publ)',
    symbol: 'SOZAP.ST',
  },
  {
    value: 'SPAGO.ST',
    label: 'SPAGO.ST,  Spago Nanomedical AB (publ)',
    name: 'Spago Nanomedical AB (publ)',
    symbol: 'SPAGO.ST',
  },
  {
    value: 'SPEONE.ST',
    label: 'SPEONE.ST,  SpectrumOne AB (publ)',
    name: 'SpectrumOne AB (publ)',
    symbol: 'SPEONE.ST',
  },
  {
    value: 'SPEQT.ST',
    label: 'SPEQT.ST,  Speqta AB (publ)',
    name: 'Speqta AB (publ)',
    symbol: 'SPEQT.ST',
  },
  {
    value: 'SPIFF.ST',
    label: 'SPIFF.ST,  Spiffbet AB',
    name: 'Spiffbet AB',
    symbol: 'SPIFF.ST',
  },
  {
    value: 'SPRINT.ST',
    label: 'SPRINT.ST,  Sprint Bioscience AB (publ)',
    name: 'Sprint Bioscience AB (publ)',
    symbol: 'SPRINT.ST',
  },
  {
    value: 'STABL.ST',
    label: 'STABL.ST,  Stayble Therapeutics AB (publ)',
    name: 'Stayble Therapeutics AB (publ)',
    symbol: 'STABL.ST',
  },
  {
    value: 'SFAST.ST',
    label: 'SFAST.ST,  Stenhus Fastigheter i Norden AB (publ)',
    name: 'Stenhus Fastigheter i Norden AB (publ)',
    symbol: 'SFAST.ST',
  },
  {
    value: 'STIL.ST',
    label: 'STIL.ST,  Stille AB',
    name: 'Stille AB',
    symbol: 'STIL.ST',
  },
  {
    value: 'STUDBO.ST',
    label: 'STUDBO.ST,  Studentbostäder i Norden AB (publ)',
    name: 'Studentbostäder i Norden AB (publ)',
    symbol: 'STUDBO.ST',
  },
  {
    value: 'SUS.ST',
    label: 'SUS.ST,  Surgical Science Sweden AB (publ)',
    name: 'Surgical Science Sweden AB (publ)',
    symbol: 'SUS.ST',
  },
  {
    value: 'AERO.ST',
    label: 'AERO.ST,  Svenska Aerogel Holding AB (publ)',
    name: 'Svenska Aerogel Holding AB (publ)',
    symbol: 'AERO.ST',
  },
  {
    value: 'NYTTO.ST',
    label: 'NYTTO.ST,  Svenska Nyttobostäder AB (publ)',
    name: 'Svenska Nyttobostäder AB (publ)',
    symbol: 'NYTTO.ST',
  },
  {
    value: 'NYTTO-PREF.ST',
    label: 'NYTTO-PREF.ST,  Svenska Nyttobostäder AB (publ)',
    name: 'Svenska Nyttobostäder AB (publ)',
    symbol: 'NYTTO-PREF.ST',
  },
  {
    value: 'SECARE.ST',
    label: 'SECARE.ST,  Swedencare AB (publ)',
    name: 'Swedencare AB (publ)',
    symbol: 'SECARE.ST',
  },
  {
    value: 'STRLNG.ST',
    label: 'STRLNG.ST,  Swedish Stirling AB (publ)',
    name: 'Swedish Stirling AB (publ)',
    symbol: 'STRLNG.ST',
  },
  {
    value: 'TAGM-B.ST',
    label: 'TAGM-B.ST,  TagMaster AB (publ)',
    name: 'TagMaster AB (publ)',
    symbol: 'TAGM-B.ST',
  },
  {
    value: 'TALK.ST',
    label: 'TALK.ST,  TalkPool AG',
    name: 'TalkPool AG',
    symbol: 'TALK.ST',
  },
  {
    value: 'TANGI.ST',
    label: 'TANGI.ST,  Tangiamo Touch Technology AB (publ)',
    name: 'Tangiamo Touch Technology AB (publ)',
    symbol: 'TANGI.ST',
  },
  {
    value: 'TCT.ST',
    label: 'TCT.ST,  TC TECH Sweden AB (publ)',
    name: 'TC TECH Sweden AB (publ)',
    symbol: 'TCT.ST',
  },
  {
    value: 'TELLUS.ST',
    label: 'TELLUS.ST,  Tellusgruppen AB (publ)',
    name: 'Tellusgruppen AB (publ)',
    symbol: 'TELLUS.ST',
  },
  {
    value: 'TSEC.ST',
    label: 'TSEC.ST,  Tempest Security AB (publ)',
    name: 'Tempest Security AB (publ)',
    symbol: 'TSEC.ST',
  },
  {
    value: 'TEQ.ST',
    label: 'TEQ.ST,  Teqnion AB (publ)',
    name: 'Teqnion AB (publ)',
    symbol: 'TEQ.ST',
  },
  {
    value: 'TERRNT-B.ST',
    label: 'TERRNT-B.ST,  Terranet AB',
    name: 'Terranet AB',
    symbol: 'TERRNT-B.ST',
  },
  {
    value: 'TESSIN.ST',
    label: 'TESSIN.ST,  Tessin Nordic Holding AB (publ)',
    name: 'Tessin Nordic Holding AB (publ)',
    symbol: 'TESSIN.ST',
  },
  {
    value: 'TH1NG.ST',
    label: 'TH1NG.ST,  TH1NG AB (publ)',
    name: 'TH1NG AB (publ)',
    symbol: 'TH1NG.ST',
  },
  {
    value: 'THUNDR.ST',
    label: 'THUNDR.ST,  Thunderful Group AB',
    name: 'Thunderful Group AB',
    symbol: 'THUNDR.ST',
  },
  {
    value: 'TOL.ST',
    label: 'TOL.ST,  Toleranzia AB',
    name: 'Toleranzia AB',
    symbol: 'TOL.ST',
  },
  {
    value: 'TORSAB.ST',
    label: 'TORSAB.ST,  Torslanda Property Investment AB (publ)',
    name: 'Torslanda Property Investment AB (publ)',
    symbol: 'TORSAB.ST',
  },
  {
    value: 'TOURN.ST',
    label: 'TOURN.ST,  Tourn International AB (publ)',
    name: 'Tourn International AB (publ)',
    symbol: 'TOURN.ST',
  },
  {
    value: 'TRAIN-B.ST',
    label: 'TRAIN-B.ST,  Train Alliance Sweden AB (publ)',
    name: 'Train Alliance Sweden AB (publ)',
    symbol: 'TRAIN-B.ST',
  },
  {
    value: 'TRIBO-B.ST',
    label: 'TRIBO-B.ST,  Triboron International AB (publ)',
    name: 'Triboron International AB (publ)',
    symbol: 'TRIBO-B.ST',
  },
  {
    value: 'TWIIK.ST',
    label: 'TWIIK.ST,  Twiik AB (publ)',
    name: 'Twiik AB (publ)',
    symbol: 'TWIIK.ST',
  },
  {
    value: 'UNIBAP.ST',
    label: 'UNIBAP.ST,  Unibap AB (publ)',
    name: 'Unibap AB (publ)',
    symbol: 'UNIBAP.ST',
  },
  {
    value: 'UTG.ST',
    label: 'UTG.ST,  Unlimited Travel Group UTG AB (publ)',
    name: 'Unlimited Travel Group UTG AB (publ)',
    symbol: 'UTG.ST',
  },
  {
    value: 'UPSALE.ST',
    label: 'UPSALE.ST,  Upsales Technology AB (publ)',
    name: 'Upsales Technology AB (publ)',
    symbol: 'UPSALE.ST',
  },
  {
    value: 'URBIT.ST',
    label: 'URBIT.ST,  Urb-it AB (publ)',
    name: 'Urb-it AB (publ)',
    symbol: 'URBIT.ST',
  },
  {
    value: 'USWE.ST',
    label: 'USWE.ST,  Uswe Sports AB (publ)',
    name: 'Uswe Sports AB (publ)',
    symbol: 'USWE.ST',
  },
  {
    value: 'VEFAB.ST',
    label: 'VEFAB.ST,  VEF AB (Publ)',
    name: 'VEF AB (Publ)',
    symbol: 'VEFAB.ST',
  },
  {
    value: 'VOLAB.ST',
    label: 'VOLAB.ST,  Veg of Lund AB (publ)',
    name: 'Veg of Lund AB (publ)',
    symbol: 'VOLAB.ST',
  },
  {
    value: 'VERT-B.ST',
    label: 'VERT-B.ST,  Vertiseit AB (publ)',
    name: 'Vertiseit AB (publ)',
    symbol: 'VERT-B.ST',
  },
  {
    value: 'VESTUM.ST',
    label: 'VESTUM.ST,  Vestum AB (publ)',
    name: 'Vestum AB (publ)',
    symbol: 'VESTUM.ST',
  },
  {
    value: 'VIMIAN.ST',
    label: 'VIMIAN.ST,  Vimian Group AB (publ)',
    name: 'Vimian Group AB (publ)',
    symbol: 'VIMIAN.ST',
  },
  {
    value: 'VO2.ST',
    label: 'VO2.ST,  Vo2 Cap Holding AB (publ)',
    name: 'Vo2 Cap Holding AB (publ)',
    symbol: 'VO2.ST',
  },
  {
    value: 'WYLD.ST',
    label: 'WYLD.ST,  Wyld Networks AB (publ)',
    name: 'Wyld Networks AB (publ)',
    symbol: 'WYLD.ST',
  },
  {
    value: 'XINT.ST',
    label: 'XINT.ST,  Xintela AB (publ)',
    name: 'Xintela AB (publ)',
    symbol: 'XINT.ST',
  },
  {
    value: 'XMR.ST',
    label: 'XMR.ST,  XMReality AB (publ)',
    name: 'XMReality AB (publ)',
    symbol: 'XMR.ST',
  },
  {
    value: 'XPC.ST',
    label: 'XPC.ST,  XP Chemistries AB (publ)',
    name: 'XP Chemistries AB (publ)',
    symbol: 'XPC.ST',
  },
  {
    value: 'ZAPLOX.ST',
    label: 'ZAPLOX.ST,  Zaplox AB',
    name: 'Zaplox AB',
    symbol: 'ZAPLOX.ST',
  },
  {
    value: 'ZICC.ST',
    label: 'ZICC.ST,  Ziccum AB (publ)',
    name: 'Ziccum AB (publ)',
    symbol: 'ZICC.ST',
  },
  {
    value: 'ZIGN.ST',
    label: 'ZIGN.ST,  ZignSec AB (publ)',
    name: 'ZignSec AB (publ)',
    symbol: 'ZIGN.ST',
  },
  {
    value: 'ZZ-B.ST',
    label: 'ZZ-B.ST,  Zinzino AB (publ)',
    name: 'Zinzino AB (publ)',
    symbol: 'ZZ-B.ST',
  },
  {
    value: 'ZORDIX-B.ST',
    label: 'ZORDIX-B.ST,  Zordix AB (publ)',
    name: 'Zordix AB (publ)',
    symbol: 'ZORDIX-B.ST',
  },
  {
    value: 'ZWIPE.OL',
    label: 'ZWIPE.OL,  Zwipe AS',
    name: 'Zwipe AS',
    symbol: 'ZWIPE.OL',
  },
  {
    value: 'ALOR',
    label: 'ALOR,  ALSP Orchid Acquisition Corporation I',
    name: 'ALSP Orchid Acquisition Corporation I',
    symbol: 'ALOR',
  },
  {
    value: 'ALORW',
    label: 'ALORW,  ALSP Orchid Acquisition Corporation I',
    name: 'ALSP Orchid Acquisition Corporation I',
    symbol: 'ALORW',
  },
  {
    value: 'U-UN.TO',
    label: 'U-UN.TO,  Sprott Physical Uranium Trust Fund',
    name: 'Sprott Physical Uranium Trust Fund',
    symbol: 'U-UN.TO',
  },
  {
    value: 'IFIN',
    label: 'IFIN,  InFinT Acquisition Corporation',
    name: 'InFinT Acquisition Corporation',
    symbol: 'IFIN',
  },
  {
    value: 'NFNT',
    label: 'NFNT,  Infinite Acquisition Corp.',
    name: 'Infinite Acquisition Corp.',
    symbol: 'NFNT',
  },
  {
    value: 'ZINGW',
    label: 'ZINGW,  FTAC Zeus Acquisition Corp.',
    name: 'FTAC Zeus Acquisition Corp.',
    symbol: 'ZINGW',
  },
  {
    value: 'ZING',
    label: 'ZING,  FTAC Zeus Acquisition Corp.',
    name: 'FTAC Zeus Acquisition Corp.',
    symbol: 'ZING',
  },
  {
    value: 'CREC',
    label: 'CREC,  Crescera Capital Acquisition Corp.',
    name: 'Crescera Capital Acquisition Corp.',
    symbol: 'CREC',
  },
  {
    value: 'ENCP',
    label: 'ENCP,  Energem Corp.',
    name: 'Energem Corp.',
    symbol: 'ENCP',
  },
  {
    value: 'CRECW',
    label: 'CRECW,  Crescera Capital Acquisition Corp.',
    name: 'Crescera Capital Acquisition Corp.',
    symbol: 'CRECW',
  },
  {
    value: 'FXCO',
    label: 'FXCO,  Financial Strategies Acquisition Corp.',
    name: 'Financial Strategies Acquisition Corp.',
    symbol: 'FXCO',
  },
  {
    value: 'FXCOW',
    label: 'FXCOW,  Financial Strategies Acquisition Corp.',
    name: 'Financial Strategies Acquisition Corp.',
    symbol: 'FXCOW',
  },
  {
    value: 'SUAC',
    label: 'SUAC,  ShoulderUp Technology Acquisition Corp.',
    name: 'ShoulderUp Technology Acquisition Corp.',
    symbol: 'SUAC',
  },
  {
    value: 'BLEUR',
    label: 'BLEUR,  bleuacacia ltd',
    name: 'bleuacacia ltd',
    symbol: 'BLEUR',
  },
  {
    value: 'BLEUW',
    label: 'BLEUW,  bleuacacia ltd',
    name: 'bleuacacia ltd',
    symbol: 'BLEUW',
  },
  {
    value: 'BLEU',
    label: 'BLEU,  bleuacacia ltd',
    name: 'bleuacacia ltd',
    symbol: 'BLEU',
  },
  {
    value: 'ENCPW',
    label: 'ENCPW,  Energem Corp.',
    name: 'Energem Corp.',
    symbol: 'ENCPW',
  },
  {
    value: 'ITAQU',
    label: 'ITAQU,  Industrial Tech Acquisitions II, Inc.',
    name: 'Industrial Tech Acquisitions II, Inc.',
    symbol: 'ITAQU',
  },
  {
    value: 'HILS',
    label: 'HILS,  Hillstream BioPharma, Inc.',
    name: 'Hillstream BioPharma, Inc.',
    symbol: 'HILS',
  },
  {
    value: 'VTSAX',
    label: 'VTSAX,  Vanguard Total Stock Market Index Fund',
    name: 'Vanguard Total Stock Market Index Fund',
    symbol: 'VTSAX',
  },
  {
    value: 'BXSL',
    label: 'BXSL,  Blackstone Secured Lending Fund',
    name: 'Blackstone Secured Lending Fund',
    symbol: 'BXSL',
  },
  {
    value: 'PMZ-UN.TO',
    label: 'PMZ-UN.TO,  Primaris Real Estate Investment Trust',
    name: 'Primaris Real Estate Investment Trust',
    symbol: 'PMZ-UN.TO',
  },
  {
    value: 'VORB',
    label: 'VORB,  Virgin Orbit Holdings, Inc.',
    name: 'Virgin Orbit Holdings, Inc.',
    symbol: 'VORB',
  },
  {
    value: 'AZN.ST',
    label: 'AZN.ST,  AstraZeneca PLC',
    name: 'AstraZeneca PLC',
    symbol: 'AZN.ST',
  },
  {
    value: 'GHIXU',
    label: 'GHIXU,  Gores Holdings IX, Inc.',
    name: 'Gores Holdings IX, Inc.',
    symbol: 'GHIXU',
  },
  {
    value: 'WAVSU',
    label: 'WAVSU,  Western Acquisition Ventures Corp.',
    name: 'Western Acquisition Ventures Corp.',
    symbol: 'WAVSU',
  },
  {
    value: 'BRACU',
    label: 'BRACU,  Broad Capital Acquisition Corp.',
    name: 'Broad Capital Acquisition Corp.',
    symbol: 'BRACU',
  },
  {
    value: 'FEXDW',
    label: 'FEXDW,  Fintech Ecosystem Development Corp.',
    name: 'Fintech Ecosystem Development Corp.',
    symbol: 'FEXDW',
  },
  {
    value: 'FEXD',
    label: 'FEXD,  Fintech Ecosystem Development Corp.',
    name: 'Fintech Ecosystem Development Corp.',
    symbol: 'FEXD',
  },
  {
    value: 'TPG',
    label: 'TPG,  TPG Inc.',
    name: 'TPG Inc.',
    symbol: 'TPG',
  },
  {
    value: 'FGI',
    label: 'FGI,  FGI Industries Ltd.',
    name: 'FGI Industries Ltd.',
    symbol: 'FGI',
  },
  {
    value: 'CFSB',
    label: 'CFSB,  CFSB Bancorp, Inc.',
    name: 'CFSB Bancorp, Inc.',
    symbol: 'CFSB',
  },
  {
    value: 'CSLMU',
    label: 'CSLMU,  Consilium Acquisition Corp I, Ltd.',
    name: 'Consilium Acquisition Corp I, Ltd.',
    symbol: 'CSLMU',
  },
  {
    value: 'NZRO',
    label: 'NZRO,  Strategy Shares Halt Climate Change ETF',
    name: 'Strategy Shares Halt Climate Change ETF',
    symbol: 'NZRO',
  },
  {
    value: 'ACABU',
    label: 'ACABU,  Atlantic Coastal Acquisition Corp. II',
    name: 'Atlantic Coastal Acquisition Corp. II',
    symbol: 'ACABU',
  },
  {
    value: 'PPYAU',
    label: 'PPYAU,  Papaya Growth Opportunity Corp. I',
    name: 'Papaya Growth Opportunity Corp. I',
    symbol: 'PPYAU',
  },
  {
    value: 'MNTN',
    label: 'MNTN,  Everest Consolidator Acquisition Corporation',
    name: 'Everest Consolidator Acquisition Corporation',
    symbol: 'MNTN',
  },
  {
    value: 'MAAQW',
    label: 'MAAQW,  Mana Capital Acquisition Corp.',
    name: 'Mana Capital Acquisition Corp.',
    symbol: 'MAAQW',
  },
  {
    value: 'CPER.V',
    label: 'CPER.V,  CopperCorp Resources Inc.',
    name: 'CopperCorp Resources Inc.',
    symbol: 'CPER.V',
  },
  {
    value: 'DCFCW',
    label: 'DCFCW,  Tritium DCFC Limited',
    name: 'Tritium DCFC Limited',
    symbol: 'DCFCW',
  },
  {
    value: 'BRD',
    label: 'BRD,  Beard Energy Transition Acquisition Corp.',
    name: 'Beard Energy Transition Acquisition Corp.',
    symbol: 'BRD',
  },
  {
    value: 'DCFC',
    label: 'DCFC,  Tritium DCFC Limited',
    name: 'Tritium DCFC Limited',
    symbol: 'DCFC',
  },
  {
    value: 'SEVN',
    label: 'SEVN,  Seven Hills Realty Trust',
    name: 'Seven Hills Realty Trust',
    symbol: 'SEVN',
  },
  {
    value: 'NBVA.V',
    label: 'NBVA.V,  Nubeva Technologies Ltd.',
    name: 'Nubeva Technologies Ltd.',
    symbol: 'NBVA.V',
  },
  {
    value: 'KIND',
    label: 'KIND,  Nextdoor Holdings, Inc.',
    name: 'Nextdoor Holdings, Inc.',
    symbol: 'KIND',
  },
  {
    value: 'CIVI',
    label: 'CIVI,  Civitas Resources, Inc.',
    name: 'Civitas Resources, Inc.',
    symbol: 'CIVI',
  },
  {
    value: 'ZD',
    label: 'ZD,  Ziff Davis, Inc.',
    name: 'Ziff Davis, Inc.',
    symbol: 'ZD',
  },
  {
    value: 'TSAT',
    label: 'TSAT,  Telesat Corporation',
    name: 'Telesat Corporation',
    symbol: 'TSAT',
  },
  {
    value: 'AHI',
    label: 'AHI,  Advanced Human Imaging Limited',
    name: 'Advanced Human Imaging Limited',
    symbol: 'AHI',
  },
  {
    value: 'ZAP.OL',
    label: 'ZAP.OL,  Zaptec AS',
    name: 'Zaptec AS',
    symbol: 'ZAP.OL',
  },
  {
    value: 'TETEU',
    label: 'TETEU,  Technology & Telecommunication Acquisition Corporation',
    name: 'Technology & Telecommunication Acquisition Corporation',
    symbol: 'TETEU',
  },
  {
    value: 'TKLF',
    label: 'TKLF,  Yoshitsu Co., Ltd',
    name: 'Yoshitsu Co., Ltd',
    symbol: 'TKLF',
  },
  {
    value: 'NSTS',
    label: 'NSTS,  NSTS Bancorp, Inc.',
    name: 'NSTS Bancorp, Inc.',
    symbol: 'NSTS',
  },
  {
    value: 'CENN',
    label: 'CENN,  Cenntro Electric Group Limited',
    name: 'Cenntro Electric Group Limited',
    symbol: 'CENN',
  },
  {
    value: 'STRN',
    label: 'STRN,  Stran & Company, Inc.',
    name: 'Stran & Company, Inc.',
    symbol: 'STRN',
  },
  {
    value: 'AIVI',
    label: 'AIVI,  WisdomTree International Al Enhanced Value Fund',
    name: 'WisdomTree International Al Enhanced Value Fund',
    symbol: 'AIVI',
  },
  {
    value: 'AIVL',
    label: 'AIVL,  WisdomTree U.S. Al Enhanced Value Fund',
    name: 'WisdomTree U.S. Al Enhanced Value Fund',
    symbol: 'AIVL',
  },
  {
    value: 'SPV2.F',
    label: 'SPV2.F,  468 SPAC II SE',
    name: '468 SPAC II SE',
    symbol: 'SPV2.F',
  },
  {
    value: 'AIBBU',
    label: 'AIBBU,  AIB Acquisition Corporation',
    name: 'AIB Acquisition Corporation',
    symbol: 'AIBBU',
  },
  {
    value: 'KACL',
    label: 'KACL,  Kairous Acquisition Corp. Limited',
    name: 'Kairous Acquisition Corp. Limited',
    symbol: 'KACL',
  },
  {
    value: 'CNGL',
    label: 'CNGL,  Canna-Global Acquisition Corp',
    name: 'Canna-Global Acquisition Corp',
    symbol: 'CNGL',
  },
  {
    value: 'DUETU',
    label: 'DUETU,  DUET Acquisition Corp.',
    name: 'DUET Acquisition Corp.',
    symbol: 'DUETU',
  },
  {
    value: 'KACLW',
    label: 'KACLW,  Kairous Acquisition Corp. Limited',
    name: 'Kairous Acquisition Corp. Limited',
    symbol: 'KACLW',
  },
  {
    value: 'CNGLW',
    label: 'CNGLW,  Canna-Global Acquisition Corp',
    name: 'Canna-Global Acquisition Corp',
    symbol: 'CNGLW',
  },
  {
    value: 'KACLR',
    label: 'KACLR,  Kairous Acquisition Corp. Limited',
    name: 'Kairous Acquisition Corp. Limited',
    symbol: 'KACLR',
  },
  {
    value: 'OP',
    label: 'OP,  OceanPal Inc.',
    name: 'OceanPal Inc.',
    symbol: 'OP',
  },
  {
    value: 'HCMAU',
    label: 'HCMAU,  HCM Acquisition Corp',
    name: 'HCM Acquisition Corp',
    symbol: 'HCMAU',
  },
  {
    value: 'PACI',
    label: 'PACI,  PROOF Acquisition Corp I',
    name: 'PROOF Acquisition Corp I',
    symbol: 'PACI',
  },
  {
    value: 'TLGY',
    label: 'TLGY,  TLGY Acquisition Corporation',
    name: 'TLGY Acquisition Corporation',
    symbol: 'TLGY',
  },
  {
    value: 'CMCAW',
    label: 'CMCAW,  Capitalworks Emerging Markets Acquisition Corp',
    name: 'Capitalworks Emerging Markets Acquisition Corp',
    symbol: 'CMCAW',
  },
  {
    value: 'WTMA',
    label: 'WTMA,  Welsbach Technology Metals Acquisition Corp.',
    name: 'Welsbach Technology Metals Acquisition Corp.',
    symbol: 'WTMA',
  },
  {
    value: 'CMCA',
    label: 'CMCA,  Capitalworks Emerging Markets Acquisition Corp',
    name: 'Capitalworks Emerging Markets Acquisition Corp',
    symbol: 'CMCA',
  },
  {
    value: 'WTMAR',
    label: 'WTMAR,  Welsbach Technology Metals Acquisition Corp.',
    name: 'Welsbach Technology Metals Acquisition Corp.',
    symbol: 'WTMAR',
  },
  {
    value: 'GAQ-UN',
    label: 'GAQ-UN,  Generation Asia I Acquisition Limited',
    name: 'Generation Asia I Acquisition Limited',
    symbol: 'GAQ-UN',
  },
  {
    value: 'NXPL',
    label: 'NXPL,  NextPlat Corp',
    name: 'NextPlat Corp',
    symbol: 'NXPL',
  },
  {
    value: 'IGTAW',
    label: 'IGTAW,  Inception Growth Acquisition Limited',
    name: 'Inception Growth Acquisition Limited',
    symbol: 'IGTAW',
  },
  {
    value: 'TLGYW',
    label: 'TLGYW,  TLGY Acquisition Corporation',
    name: 'TLGY Acquisition Corporation',
    symbol: 'TLGYW',
  },
  {
    value: 'IGTA',
    label: 'IGTA,  Inception Growth Acquisition Limited',
    name: 'Inception Growth Acquisition Limited',
    symbol: 'IGTA',
  },
  {
    value: 'IGTAR',
    label: 'IGTAR,  Inception Growth Acquisition Limited',
    name: 'Inception Growth Acquisition Limited',
    symbol: 'IGTAR',
  },
  {
    value: 'HMA-UN',
    label: 'HMA-UN,  Heartland Media Acquisition Corp.',
    name: 'Heartland Media Acquisition Corp.',
    symbol: 'HMA-UN',
  },
  {
    value: 'VZLA',
    label: 'VZLA,  Vizsla Silver Corp.',
    name: 'Vizsla Silver Corp.',
    symbol: 'VZLA',
  },
  {
    value: '2121.HK',
    label: '2121.HK,  Qingdao AInnovation Technology Group Co., Ltd.',
    name: 'Qingdao AInnovation Technology Group Co., Ltd.',
    symbol: '2121.HK',
  },
  {
    value: 'VRAR',
    label: 'VRAR,  The Glimpse Group, Inc.',
    name: 'The Glimpse Group, Inc.',
    symbol: 'VRAR',
  },
  {
    value: 'BPACW',
    label: 'BPACW,  Bullpen Parlay Acquisition Company',
    name: 'Bullpen Parlay Acquisition Company',
    symbol: 'BPACW',
  },
  {
    value: 'NVACW',
    label: 'NVACW,  NorthView Acquisition Corporation',
    name: 'NorthView Acquisition Corporation',
    symbol: 'NVACW',
  },
  {
    value: 'STET',
    label: 'STET,  ST Energy Transition I Ltd.',
    name: 'ST Energy Transition I Ltd.',
    symbol: 'STET',
  },
  {
    value: 'AORT',
    label: 'AORT,  Artivion, Inc.',
    name: 'Artivion, Inc.',
    symbol: 'AORT',
  },
  {
    value: 'PBBK',
    label: 'PBBK,  PB Bankshares, Inc.',
    name: 'PB Bankshares, Inc.',
    symbol: 'PBBK',
  },
  {
    value: '1375.T',
    label: '1375.T,  Yukiguni Maitake Co., Ltd.',
    name: 'Yukiguni Maitake Co., Ltd.',
    symbol: '1375.T',
  },
  {
    value: '1376.T',
    label: '1376.T,  Kaneko Seeds Co., Ltd.',
    name: 'Kaneko Seeds Co., Ltd.',
    symbol: '1376.T',
  },
  {
    value: '1377.T',
    label: '1377.T,  Sakata Seed Corporation',
    name: 'Sakata Seed Corporation',
    symbol: '1377.T',
  },
  {
    value: '1379.T',
    label: '1379.T,  Hokuto Corporation',
    name: 'Hokuto Corporation',
    symbol: '1379.T',
  },
  {
    value: '1384.T',
    label: '1384.T,  Hokuryo Co., Ltd.',
    name: 'Hokuryo Co., Ltd.',
    symbol: '1384.T',
  },
  {
    value: 'TCBS',
    label: 'TCBS,  Texas Community Bancshares, Inc.',
    name: 'Texas Community Bancshares, Inc.',
    symbol: 'TCBS',
  },
  {
    value: '1414.T',
    label: '1414.T,  SHO-BOND Holdings Co.,Ltd.',
    name: 'SHO-BOND Holdings Co.,Ltd.',
    symbol: '1414.T',
  },
  {
    value: '1417.T',
    label: '1417.T,  MIRAIT ONE Corporation',
    name: 'MIRAIT ONE Corporation',
    symbol: '1417.T',
  },
  {
    value: '1419.T',
    label: '1419.T,  Tama Home Co., Ltd.',
    name: 'Tama Home Co., Ltd.',
    symbol: '1419.T',
  },
  {
    value: '1420.T',
    label: '1420.T,  Sanyo Homes Corporation',
    name: 'Sanyo Homes Corporation',
    symbol: '1420.T',
  },
  {
    value: '1429.T',
    label: '1429.T,  Nippon Aqua Co., Ltd.',
    name: 'Nippon Aqua Co., Ltd.',
    symbol: '1429.T',
  },
  {
    value: '1430.T',
    label: '1430.T,  First-corporation Inc.',
    name: 'First-corporation Inc.',
    symbol: '1430.T',
  },
  {
    value: 'GDEV',
    label: 'GDEV,  Nexters Inc.',
    name: 'Nexters Inc.',
    symbol: 'GDEV',
  },
  {
    value: '1433.T',
    label: '1433.T,  Besterra Co., Ltd.',
    name: 'Besterra Co., Ltd.',
    symbol: '1433.T',
  },
  {
    value: '1435.T',
    label: '1435.T,  Robot Home, Inc.',
    name: 'Robot Home, Inc.',
    symbol: '1435.T',
  },
  {
    value: '1446.T',
    label: '1446.T,  CANDEAL Co.,Ltd',
    name: 'CANDEAL Co.,Ltd',
    symbol: '1446.T',
  },
  {
    value: '1514.T',
    label: '1514.T,  Sumiseki Holdings,Inc.',
    name: 'Sumiseki Holdings,Inc.',
    symbol: '1514.T',
  },
  {
    value: '1515.T',
    label: '1515.T,  Nittetsu Mining Co., Ltd.',
    name: 'Nittetsu Mining Co., Ltd.',
    symbol: '1515.T',
  },
  {
    value: '1518.T',
    label: '1518.T,  Mitsui Matsushima Holdings Co., Ltd.',
    name: 'Mitsui Matsushima Holdings Co., Ltd.',
    symbol: '1518.T',
  },
  {
    value: '1605.T',
    label: '1605.T,  Inpex Corporation',
    name: 'Inpex Corporation',
    symbol: '1605.T',
  },
  {
    value: '1662.T',
    label: '1662.T,  Japan Petroleum Exploration Co., Ltd.',
    name: 'Japan Petroleum Exploration Co., Ltd.',
    symbol: '1662.T',
  },
  {
    value: '1663.T',
    label: '1663.T,  K&O Energy Group Inc.',
    name: 'K&O Energy Group Inc.',
    symbol: '1663.T',
  },
  {
    value: '1712.T',
    label: '1712.T,  Daiseki Eco. Solution Co., Ltd.',
    name: 'Daiseki Eco. Solution Co., Ltd.',
    symbol: '1712.T',
  },
  {
    value: '1716.T',
    label: '1716.T,  Dai-Ichi Cutter Kogyo k.k.',
    name: 'Dai-Ichi Cutter Kogyo k.k.',
    symbol: '1716.T',
  },
  {
    value: '1717.T',
    label: '1717.T,  Meiho Facility Works Ltd.',
    name: 'Meiho Facility Works Ltd.',
    symbol: '1717.T',
  },
  {
    value: '1719.T',
    label: '1719.T,  Hazama Ando Corporation',
    name: 'Hazama Ando Corporation',
    symbol: '1719.T',
  },
  {
    value: '1720.T',
    label: '1720.T,  Tokyu Construction Co., Ltd.',
    name: 'Tokyu Construction Co., Ltd.',
    symbol: '1720.T',
  },
  {
    value: '1721.T',
    label: '1721.T,  COMSYS Holdings Corporation',
    name: 'COMSYS Holdings Corporation',
    symbol: '1721.T',
  },
  {
    value: '1726.T',
    label: '1726.T,  Br. Holdings Corporation',
    name: 'Br. Holdings Corporation',
    symbol: '1726.T',
  },
  {
    value: '1768.T',
    label: '1768.T,  Sonec Corporation',
    name: 'Sonec Corporation',
    symbol: '1768.T',
  },
  {
    value: '1780.T',
    label: '1780.T,  Yamaura Corporation',
    name: 'Yamaura Corporation',
    symbol: '1780.T',
  },
  {
    value: '1811.T',
    label: '1811.T,  The Zenitaka Corporation',
    name: 'The Zenitaka Corporation',
    symbol: '1811.T',
  },
  {
    value: '1814.T',
    label: '1814.T,  Daisue Construction Co., Ltd.',
    name: 'Daisue Construction Co., Ltd.',
    symbol: '1814.T',
  },
  {
    value: '1826.T',
    label: '1826.T,  Sata Construction Co., Ltd.',
    name: 'Sata Construction Co., Ltd.',
    symbol: '1826.T',
  },
  {
    value: '1827.T',
    label: '1827.T,  Nakano Corporation',
    name: 'Nakano Corporation',
    symbol: '1827.T',
  },
  {
    value: '1847.T',
    label: '1847.T,  Ichiken Co., Ltd.',
    name: 'Ichiken Co., Ltd.',
    symbol: '1847.T',
  },
  {
    value: '1848.T',
    label: '1848.T,  Fuji P.S Corporation',
    name: 'Fuji P.S Corporation',
    symbol: '1848.T',
  },
  {
    value: '1866.T',
    label: '1866.T,  Kitano Construction Corp.',
    name: 'Kitano Construction Corp.',
    symbol: '1866.T',
  },
  {
    value: '1867.T',
    label: '1867.T,  UEKI Corporation',
    name: 'UEKI Corporation',
    symbol: '1867.T',
  },
  {
    value: '1909.T',
    label: '1909.T,  Nippon Dry-Chemical Co., Ltd.',
    name: 'Nippon Dry-Chemical Co., Ltd.',
    symbol: '1909.T',
  },
  {
    value: '1914.T',
    label: '1914.T,  Japan Foundation Engineering Co., Ltd.',
    name: 'Japan Foundation Engineering Co., Ltd.',
    symbol: '1914.T',
  },
  {
    value: '1921.T',
    label: '1921.T,  Tomoe Corporation',
    name: 'Tomoe Corporation',
    symbol: '1921.T',
  },
  {
    value: '1930.T',
    label: '1930.T,  Hokuriku Electrical Construction Co.,Ltd.',
    name: 'Hokuriku Electrical Construction Co.,Ltd.',
    symbol: '1930.T',
  },
  {
    value: '1938.T',
    label: '1938.T,  Nippon Rietec Co.,Ltd.',
    name: 'Nippon Rietec Co.,Ltd.',
    symbol: '1938.T',
  },
  {
    value: '1939.T',
    label: '1939.T,  Yondenko Corporation',
    name: 'Yondenko Corporation',
    symbol: '1939.T',
  },
  {
    value: '1964.T',
    label: '1964.T,  Chugai Ro Co., Ltd.',
    name: 'Chugai Ro Co., Ltd.',
    symbol: '1964.T',
  },
  {
    value: '1972.T',
    label: '1972.T,  Sanko Metal Industrial Co., Ltd.',
    name: 'Sanko Metal Industrial Co., Ltd.',
    symbol: '1972.T',
  },
  {
    value: '1975.T',
    label: '1975.T,  Asahi Kogyosha Co., Ltd.',
    name: 'Asahi Kogyosha Co., Ltd.',
    symbol: '1975.T',
  },
  {
    value: '2003.T',
    label: '2003.T,  Nitto Fuji Flour Milling Co.,Ltd.',
    name: 'Nitto Fuji Flour Milling Co.,Ltd.',
    symbol: '2003.T',
  },
  {
    value: '2009.T',
    label: '2009.T,  The Torigoe Co., Ltd.',
    name: 'The Torigoe Co., Ltd.',
    symbol: '2009.T',
  },
  {
    value: '2107.T',
    label: '2107.T,  Toyo Sugar Refining Co., Ltd.',
    name: 'Toyo Sugar Refining Co., Ltd.',
    symbol: '2107.T',
  },
  {
    value: '2108.T',
    label: '2108.T,  Nippon Beet Sugar Manufacturing Co.,Ltd.',
    name: 'Nippon Beet Sugar Manufacturing Co.,Ltd.',
    symbol: '2108.T',
  },
  {
    value: '2112.T',
    label: '2112.T,  Ensuiko Sugar Refining Co., Ltd.',
    name: 'Ensuiko Sugar Refining Co., Ltd.',
    symbol: '2112.T',
  },
  {
    value: '2117.T',
    label: '2117.T,  Nissin Sugar Co., Ltd.',
    name: 'Nissin Sugar Co., Ltd.',
    symbol: '2117.T',
  },
  {
    value: '2139.T',
    label: '2139.T,  Chuco Co., Ltd.',
    name: 'Chuco Co., Ltd.',
    symbol: '2139.T',
  },
  {
    value: '2163.T',
    label: '2163.T,  Artner Co.,Ltd.',
    name: 'Artner Co.,Ltd.',
    symbol: '2163.T',
  },
  {
    value: '2169.T',
    label: '2169.T,  CDS Co., Ltd.',
    name: 'CDS Co., Ltd.',
    symbol: '2169.T',
  },
  {
    value: '2180.T',
    label: '2180.T,  Sunny Side Up Group, Inc.',
    name: 'Sunny Side Up Group, Inc.',
    symbol: '2180.T',
  },
  {
    value: '2183.T',
    label: '2183.T,  Linical Co., Ltd.',
    name: 'Linical Co., Ltd.',
    symbol: '2183.T',
  },
  {
    value: '2196.T',
    label: '2196.T,  Escrit Inc.',
    name: 'Escrit Inc.',
    symbol: '2196.T',
  },
  {
    value: '2204.T',
    label: '2204.T,  Nakamuraya Co., Ltd.',
    name: 'Nakamuraya Co., Ltd.',
    symbol: '2204.T',
  },
  {
    value: '2207.T',
    label: '2207.T,  Meito Sangyo Co., Ltd.',
    name: 'Meito Sangyo Co., Ltd.',
    symbol: '2207.T',
  },
  {
    value: '2215.T',
    label: '2215.T,  First Baking Co., Ltd.',
    name: 'First Baking Co., Ltd.',
    symbol: '2215.T',
  },
  {
    value: '2217.T',
    label: '2217.T,  Morozoff Limited',
    name: 'Morozoff Limited',
    symbol: '2217.T',
  },
  {
    value: '2220.T',
    label: '2220.T,  Kameda Seika Co.,Ltd.',
    name: 'Kameda Seika Co.,Ltd.',
    symbol: '2220.T',
  },
  {
    value: '2222.T',
    label: '2222.T,  Kotobuki Spirits Co., Ltd.',
    name: 'Kotobuki Spirits Co., Ltd.',
    symbol: '2222.T',
  },
  {
    value: '2229.T',
    label: '2229.T,  Calbee, Inc.',
    name: 'Calbee, Inc.',
    symbol: '2229.T',
  },
  {
    value: '2264.T',
    label: '2264.T,  Morinaga Milk Industry Co., Ltd.',
    name: 'Morinaga Milk Industry Co., Ltd.',
    symbol: '2264.T',
  },
  {
    value: '2266.T',
    label: '2266.T,  Rokko Butter Co., Ltd.',
    name: 'Rokko Butter Co., Ltd.',
    symbol: '2266.T',
  },
  {
    value: '2267.T',
    label: '2267.T,  Yakult Honsha Co.,Ltd.',
    name: 'Yakult Honsha Co.,Ltd.',
    symbol: '2267.T',
  },
  {
    value: 'RKLY',
    label: 'RKLY,  Rockley Photonics Holdings Limited',
    name: 'Rockley Photonics Holdings Limited',
    symbol: 'RKLY',
  },
  {
    value: '2286.T',
    label: '2286.T,  Hayashikane Sangyo Co.,Ltd.',
    name: 'Hayashikane Sangyo Co.,Ltd.',
    symbol: '2286.T',
  },
  {
    value: '2307.T',
    label: '2307.T,  Cross Cat Co., Ltd.',
    name: 'Cross Cat Co., Ltd.',
    symbol: '2307.T',
  },
  {
    value: '2311.T',
    label: '2311.T,  EPCO Co.,Ltd.',
    name: 'EPCO Co.,Ltd.',
    symbol: '2311.T',
  },
  {
    value: '2325.T',
    label: '2325.T,  NJS Co., Ltd.',
    name: 'NJS Co., Ltd.',
    symbol: '2325.T',
  },
  {
    value: '2335.T',
    label: '2335.T,  Cube System Inc.',
    name: 'Cube System Inc.',
    symbol: '2335.T',
  },
  {
    value: '2376.T',
    label: '2376.T,  Scinex Corporation',
    name: 'Scinex Corporation',
    symbol: '2376.T',
  },
  {
    value: '2410.T',
    label: '2410.T,  Career Design Center Co., Ltd.',
    name: 'Career Design Center Co., Ltd.',
    symbol: '2410.T',
  },
  {
    value: '2424.T',
    label: '2424.T,  Brass Corporation',
    name: 'Brass Corporation',
    symbol: '2424.T',
  },
  {
    value: '2428.T',
    label: '2428.T,  Wellnet Corporation',
    name: 'Wellnet Corporation',
    symbol: '2428.T',
  },
  {
    value: '2453.T',
    label: '2453.T,  Japan Best Rescue System Co.,Ltd.',
    name: 'Japan Best Rescue System Co.,Ltd.',
    symbol: '2453.T',
  },
  {
    value: '2464.T',
    label: '2464.T,  Business Breakthrough, Inc.',
    name: 'Business Breakthrough, Inc.',
    symbol: '2464.T',
  },
  {
    value: '2477.T',
    label: '2477.T,  Temairazu, Inc.',
    name: 'Temairazu, Inc.',
    symbol: '2477.T',
  },
  {
    value: '2485.T',
    label: '2485.T,  Tear Corporation',
    name: 'Tear Corporation',
    symbol: '2485.T',
  },
  {
    value: '2487.T',
    label: '2487.T,  CDG Co., Ltd.',
    name: 'CDG Co., Ltd.',
    symbol: '2487.T',
  },
  {
    value: 'DILA',
    label: 'DILA,  DILA Capital Acquisition Corp.',
    name: 'DILA Capital Acquisition Corp.',
    symbol: 'DILA',
  },
  {
    value: '2540.T',
    label: '2540.T,  Yomeishu Seizo Co., Ltd.',
    name: 'Yomeishu Seizo Co., Ltd.',
    symbol: '2540.T',
  },
  {
    value: 'EGGF',
    label: 'EGGF,  EG Acquisition Corp.',
    name: 'EG Acquisition Corp.',
    symbol: 'EGGF',
  },
  {
    value: '2597.T',
    label: '2597.T,  Unicafe Inc.',
    name: 'Unicafe Inc.',
    symbol: '2597.T',
  },
  {
    value: 'EOCW',
    label: 'EOCW,  Elliott Opportunity II Corp.',
    name: 'Elliott Opportunity II Corp.',
    symbol: 'EOCW',
  },
  {
    value: '2599.T',
    label: '2599.T,  Japan Foods Co., Ltd.',
    name: 'Japan Foods Co., Ltd.',
    symbol: '2599.T',
  },
  {
    value: '2612.T',
    label: '2612.T,  Kadoya Sesame Mills Incorporated',
    name: 'Kadoya Sesame Mills Incorporated',
    symbol: '2612.T',
  },
  {
    value: 'GACQ',
    label: 'GACQ,  Global Consumer Acquisition Corp.',
    name: 'Global Consumer Acquisition Corp.',
    symbol: 'GACQ',
  },
  {
    value: '2674.T',
    label: '2674.T,  Hard Off Corporation Co.,Ltd.',
    name: 'Hard Off Corporation Co.,Ltd.',
    symbol: '2674.T',
  },
  {
    value: 'GFOR',
    label: 'GFOR,  Graf Acquisition Corp. IV',
    name: 'Graf Acquisition Corp. IV',
    symbol: 'GFOR',
  },
  {
    value: 'GLTA',
    label: 'GLTA,  Galata Acquisition Corp.',
    name: 'Galata Acquisition Corp.',
    symbol: 'GLTA',
  },
  {
    value: '2676.T',
    label: '2676.T,  Takachiho Koheki Co.,Ltd.',
    name: 'Takachiho Koheki Co.,Ltd.',
    symbol: '2676.T',
  },
  {
    value: '2686.T',
    label: '2686.T,  Gfoot Co.,Ltd.',
    name: 'Gfoot Co.,Ltd.',
    symbol: '2686.T',
  },
  {
    value: 'GSQB',
    label: 'GSQB,  G Squared Ascend II Inc.',
    name: 'G Squared Ascend II Inc.',
    symbol: 'GSQB',
  },
  {
    value: '2687.T',
    label: '2687.T,  CVS Bay Area Inc.',
    name: 'CVS Bay Area Inc.',
    symbol: '2687.T',
  },
  {
    value: 'GWII',
    label: 'GWII,  Good Works II Acquisition Corp.',
    name: 'Good Works II Acquisition Corp.',
    symbol: 'GWII',
  },
  {
    value: '2689.T',
    label: '2689.T,  OLBA HEALTHCARE HOLDINGS, Inc.',
    name: 'OLBA HEALTHCARE HOLDINGS, Inc.',
    symbol: '2689.T',
  },
  {
    value: 'HCNE',
    label: 'HCNE,  JAWS Hurricane Acquisition Corporation',
    name: 'JAWS Hurricane Acquisition Corporation',
    symbol: 'HCNE',
  },
  {
    value: '2722.T',
    label: '2722.T,  I.K Co., Ltd.',
    name: 'I.K Co., Ltd.',
    symbol: '2722.T',
  },
  {
    value: 'JUGG',
    label: 'JUGG,  Jaws Juggernaut Acquisition Corporation',
    name: 'Jaws Juggernaut Acquisition Corporation',
    symbol: 'JUGG',
  },
  {
    value: '2730.T',
    label: '2730.T,  EDION Corporation',
    name: 'EDION Corporation',
    symbol: '2730.T',
  },
  {
    value: '2733.T',
    label: '2733.T,  Arata Corporation',
    name: 'Arata Corporation',
    symbol: '2733.T',
  },
  {
    value: 'LAAA',
    label: 'LAAA,  Lakeshore Acquisition I Corp.',
    name: 'Lakeshore Acquisition I Corp.',
    symbol: 'LAAA',
  },
  {
    value: '2734.T',
    label: '2734.T,  SALA Corporation',
    name: 'SALA Corporation',
    symbol: '2734.T',
  },
  {
    value: 'LITT',
    label: 'LITT,  Logistics Innovation Technologies Corp.',
    name: 'Logistics Innovation Technologies Corp.',
    symbol: 'LITT',
  },
  {
    value: '2735.T',
    label: '2735.T,  Watts Co., Ltd.',
    name: 'Watts Co., Ltd.',
    symbol: '2735.T',
  },
  {
    value: 'MACC',
    label: 'MACC,  Mission Advancement Corp.',
    name: 'Mission Advancement Corp.',
    symbol: 'MACC',
  },
  {
    value: 'MCAF',
    label: 'MCAF,  Mountain Crest Acquisition Corp. IV',
    name: 'Mountain Crest Acquisition Corp. IV',
    symbol: 'MCAF',
  },
  {
    value: '2737.T',
    label: '2737.T,  Tomen Devices Corporation',
    name: 'Tomen Devices Corporation',
    symbol: '2737.T',
  },
  {
    value: '2742.T',
    label: '2742.T,  Halows Co.,Ltd.',
    name: 'Halows Co.,Ltd.',
    symbol: '2742.T',
  },
  {
    value: '2749.T',
    label: '2749.T,  Jp-Holdings,Inc.',
    name: 'Jp-Holdings,Inc.',
    symbol: '2749.T',
  },
  {
    value: 'NSTD',
    label: 'NSTD,  Northern Star Investment Corp. IV',
    name: 'Northern Star Investment Corp. IV',
    symbol: 'NSTD',
  },
  {
    value: '2752.T',
    label: '2752.T,  Fujio Food Group Inc.',
    name: 'Fujio Food Group Inc.',
    symbol: '2752.T',
  },
  {
    value: 'ORIA',
    label: 'ORIA,  Orion Biotech Opportunities Corp.',
    name: 'Orion Biotech Opportunities Corp.',
    symbol: 'ORIA',
  },
  {
    value: '2753.T',
    label: '2753.T,  Amiyaki Tei Co., Ltd.',
    name: 'Amiyaki Tei Co., Ltd.',
    symbol: '2753.T',
  },
  {
    value: 'OTEC',
    label: 'OTEC,  OceanTech Acquisitions I Corp.',
    name: 'OceanTech Acquisitions I Corp.',
    symbol: 'OTEC',
  },
  {
    value: '2760.T',
    label: '2760.T,  Tokyo Electron Device Limited',
    name: 'Tokyo Electron Device Limited',
    symbol: '2760.T',
  },
  {
    value: '2764.T',
    label: '2764.T,  Hiramatsu Inc.',
    name: 'Hiramatsu Inc.',
    symbol: '2764.T',
  },
  {
    value: 'PSPC',
    label: 'PSPC,  Post Holdings Partnering Corporation',
    name: 'Post Holdings Partnering Corporation',
    symbol: 'PSPC',
  },
  {
    value: '2767.T',
    label: '2767.T,  Tsuburaya Fields Holdings Inc.',
    name: 'Tsuburaya Fields Holdings Inc.',
    symbol: '2767.T',
  },
  {
    value: '2768.T',
    label: '2768.T,  Sojitz Corporation',
    name: 'Sojitz Corporation',
    symbol: '2768.T',
  },
  {
    value: 'ROCG',
    label: 'ROCG,  Roth CH Acquisition IV Co.',
    name: 'Roth CH Acquisition IV Co.',
    symbol: 'ROCG',
  },
  {
    value: '2784.T',
    label: '2784.T,  Alfresa Holdings Corporation',
    name: 'Alfresa Holdings Corporation',
    symbol: '2784.T',
  },
  {
    value: 'RONI',
    label: 'RONI,  Rice Acquisition Corp. II',
    name: 'Rice Acquisition Corp. II',
    symbol: 'RONI',
  },
  {
    value: '2791.T',
    label: '2791.T,  Daikokutenbussan Co.,Ltd.',
    name: 'Daikokutenbussan Co.,Ltd.',
    symbol: '2791.T',
  },
  {
    value: 'SHQA',
    label: 'SHQA,  Shelter Acquisition Corporation I',
    name: 'Shelter Acquisition Corporation I',
    symbol: 'SHQA',
  },
  {
    value: '2792.T',
    label: '2792.T,  Honeys Holdings Co., Ltd.',
    name: 'Honeys Holdings Co., Ltd.',
    symbol: '2792.T',
  },
  {
    value: 'SKYA',
    label: 'SKYA,  Skydeck Acquisition Corp.',
    name: 'Skydeck Acquisition Corp.',
    symbol: 'SKYA',
  },
  {
    value: 'SMIH',
    label: 'SMIH,  Summit Healthcare Acquisition Corp.',
    name: 'Summit Healthcare Acquisition Corp.',
    symbol: 'SMIH',
  },
  {
    value: '2796.T',
    label: '2796.T,  Pharmarise Holdings Corporation',
    name: 'Pharmarise Holdings Corporation',
    symbol: '2796.T',
  },
  {
    value: 'THCP',
    label: 'THCP,  Thunder Bridge Capital Partners IV Inc.',
    name: 'Thunder Bridge Capital Partners IV Inc.',
    symbol: 'THCP',
  },
  {
    value: '2801.T',
    label: '2801.T,  Kikkoman Corporation',
    name: 'Kikkoman Corporation',
    symbol: '2801.T',
  },
  {
    value: 'TMAC',
    label: 'TMAC,  The Music Acquisition Corporation',
    name: 'The Music Acquisition Corporation',
    symbol: 'TMAC',
  },
  {
    value: '2802.T',
    label: '2802.T,  Ajinomoto Co., Inc.',
    name: 'Ajinomoto Co., Inc.',
    symbol: '2802.T',
  },
  {
    value: 'TRCA',
    label: 'TRCA,  Twin Ridge Capital Acquisition Corp.',
    name: 'Twin Ridge Capital Acquisition Corp.',
    symbol: 'TRCA',
  },
  {
    value: 'TRON',
    label: 'TRON,  Corner Growth Acquisition Corp. 2',
    name: 'Corner Growth Acquisition Corp. 2',
    symbol: 'TRON',
  },
  {
    value: '2804.T',
    label: '2804.T,  Bull-Dog Sauce Co., Ltd.',
    name: 'Bull-Dog Sauce Co., Ltd.',
    symbol: '2804.T',
  },
  {
    value: '2809.T',
    label: '2809.T,  Kewpie Corporation',
    name: 'Kewpie Corporation',
    symbol: '2809.T',
  },
  {
    value: '2810.T',
    label: '2810.T,  House Foods Group Inc.',
    name: 'House Foods Group Inc.',
    symbol: '2810.T',
  },
  {
    value: '2811.T',
    label: '2811.T,  Kagome Co., Ltd.',
    name: 'Kagome Co., Ltd.',
    symbol: '2811.T',
  },
  {
    value: '2812.T',
    label: '2812.T,  Yaizu Suisankagaku Industry Co.,Ltd.',
    name: 'Yaizu Suisankagaku Industry Co.,Ltd.',
    symbol: '2812.T',
  },
  {
    value: '2815.T',
    label: '2815.T,  ARIAKE JAPAN Co., Ltd.',
    name: 'ARIAKE JAPAN Co., Ltd.',
    symbol: '2815.T',
  },
  {
    value: '2818.T',
    label: '2818.T,  PIETRO Co., Ltd.',
    name: 'PIETRO Co., Ltd.',
    symbol: '2818.T',
  },
  {
    value: '2819.T',
    label: '2819.T,  Ebara Foods Industry,Inc.',
    name: 'Ebara Foods Industry,Inc.',
    symbol: '2819.T',
  },
  {
    value: '2820.T',
    label: '2820.T,  Yamami Company',
    name: 'Yamami Company',
    symbol: '2820.T',
  },
  {
    value: '2871.T',
    label: '2871.T,  Nichirei Corporation',
    name: 'Nichirei Corporation',
    symbol: '2871.T',
  },
  {
    value: '2874.T',
    label: '2874.T,  Yokorei Co.,Ltd.',
    name: 'Yokorei Co.,Ltd.',
    symbol: '2874.T',
  },
  {
    value: '2875.T',
    label: '2875.T,  Toyo Suisan Kaisha, Ltd.',
    name: 'Toyo Suisan Kaisha, Ltd.',
    symbol: '2875.T',
  },
  {
    value: '2882.T',
    label: '2882.T,  EAT&HOLDINGS Co.,Ltd',
    name: 'EAT&HOLDINGS Co.,Ltd',
    symbol: '2882.T',
  },
  {
    value: '2883.T',
    label: '2883.T,  Dairei Co.,Ltd.',
    name: 'Dairei Co.,Ltd.',
    symbol: '2883.T',
  },
  {
    value: '2884.T',
    label: '2884.T,  Yoshimura Food Holdings K.K.',
    name: 'Yoshimura Food Holdings K.K.',
    symbol: '2884.T',
  },
  {
    value: '2899.T',
    label: '2899.T,  Nagatanien Holdings Co.,Ltd.',
    name: 'Nagatanien Holdings Co.,Ltd.',
    symbol: '2899.T',
  },
  {
    value: 'RSVR',
    label: 'RSVR,  Reservoir Media, Inc.',
    name: 'Reservoir Media, Inc.',
    symbol: 'RSVR',
  },
  {
    value: '2904.T',
    label: '2904.T,  Ichimasa Kamaboko Co., Ltd.',
    name: 'Ichimasa Kamaboko Co., Ltd.',
    symbol: '2904.T',
  },
  {
    value: '2922.T',
    label: '2922.T,  Natori Co., Ltd.',
    name: 'Natori Co., Ltd.',
    symbol: '2922.T',
  },
  {
    value: '2924.T',
    label: '2924.T,  Ifuji Sangyo Co., Ltd.',
    name: 'Ifuji Sangyo Co., Ltd.',
    symbol: '2924.T',
  },
  {
    value: '2933.T',
    label: '2933.T,  Kibun Foods Inc.',
    name: 'Kibun Foods Inc.',
    symbol: '2933.T',
  },
  {
    value: '2975.T',
    label: '2975.T,  Star Mica Holdings Co., Ltd.',
    name: 'Star Mica Holdings Co., Ltd.',
    symbol: '2975.T',
  },
  {
    value: 'IDW',
    label: 'IDW,  IDW Media Holdings, Inc.',
    name: 'IDW Media Holdings, Inc.',
    symbol: 'IDW',
  },
  {
    value: '2980.T',
    label: '2980.T,  SRE Holdings Corporation',
    name: 'SRE Holdings Corporation',
    symbol: '2980.T',
  },
  {
    value: '2982.T',
    label: '2982.T,  A.D.Works Group Co.,Ltd.',
    name: 'A.D.Works Group Co.,Ltd.',
    symbol: '2982.T',
  },
  {
    value: '3004.T',
    label: '3004.T,  Shinyei Kaisha',
    name: 'Shinyei Kaisha',
    symbol: '3004.T',
  },
  {
    value: '3023.T',
    label: '3023.T,  Rasa Corporation',
    name: 'Rasa Corporation',
    symbol: '3023.T',
  },
  {
    value: '3030.T',
    label: '3030.T,  Hub Co., Ltd.',
    name: 'Hub Co., Ltd.',
    symbol: '3030.T',
  },
  {
    value: '3053.T',
    label: '3053.T,  Pepper Food Service Co., Ltd.',
    name: 'Pepper Food Service Co., Ltd.',
    symbol: '3053.T',
  },
  {
    value: '3054.T',
    label: '3054.T,  HYPER Inc.',
    name: 'HYPER Inc.',
    symbol: '3054.T',
  },
  {
    value: '3067.T',
    label: '3067.T,  Tokyo Ichiban Foods Co., Ltd.',
    name: 'Tokyo Ichiban Foods Co., Ltd.',
    symbol: '3067.T',
  },
  {
    value: '3079.T',
    label: '3079.T,  DVx Inc.',
    name: 'DVx Inc.',
    symbol: '3079.T',
  },
  {
    value: '3082.T',
    label: '3082.T,  Kichiri & Co., Ltd.',
    name: 'Kichiri & Co., Ltd.',
    symbol: '3082.T',
  },
  {
    value: '3093.T',
    label: '3093.T,  Treasure Factory Co.,LTD.',
    name: 'Treasure Factory Co.,LTD.',
    symbol: '3093.T',
  },
  {
    value: '3099.T',
    label: '3099.T,  Isetan Mitsukoshi Holdings Ltd.',
    name: 'Isetan Mitsukoshi Holdings Ltd.',
    symbol: '3099.T',
  },
  {
    value: '3101.T',
    label: '3101.T,  Toyobo Co., Ltd.',
    name: 'Toyobo Co., Ltd.',
    symbol: '3101.T',
  },
  {
    value: '3103.T',
    label: '3103.T,  Unitika Ltd.',
    name: 'Unitika Ltd.',
    symbol: '3103.T',
  },
  {
    value: '3104.T',
    label: '3104.T,  Fujibo Holdings, Inc.',
    name: 'Fujibo Holdings, Inc.',
    symbol: '3104.T',
  },
  {
    value: '3105.T',
    label: '3105.T,  Nisshinbo Holdings Inc.',
    name: 'Nisshinbo Holdings Inc.',
    symbol: '3105.T',
  },
  {
    value: 'CYBN',
    label: 'CYBN,  Cybin Inc.',
    name: 'Cybin Inc.',
    symbol: 'CYBN',
  },
  {
    value: '3106.T',
    label: '3106.T,  Kurabo Industries Ltd.',
    name: 'Kurabo Industries Ltd.',
    symbol: '3106.T',
  },
  {
    value: '3107.T',
    label: '3107.T,  Daiwabo Holdings Co., Ltd.',
    name: 'Daiwabo Holdings Co., Ltd.',
    symbol: '3107.T',
  },
  {
    value: '3109.T',
    label: '3109.T,  Shikibo Ltd.',
    name: 'Shikibo Ltd.',
    symbol: '3109.T',
  },
  {
    value: '3110.T',
    label: '3110.T,  Nitto Boseki Co., Ltd.',
    name: 'Nitto Boseki Co., Ltd.',
    symbol: '3110.T',
  },
  {
    value: '3116.T',
    label: '3116.T,  Toyota Boshoku Corporation',
    name: 'Toyota Boshoku Corporation',
    symbol: '3116.T',
  },
  {
    value: '3134.T',
    label: '3134.T,  Hamee Corp.',
    name: 'Hamee Corp.',
    symbol: '3134.T',
  },
  {
    value: '3135.T',
    label: '3135.T,  MarketEnterprise Co.,Ltd',
    name: 'MarketEnterprise Co.,Ltd',
    symbol: '3135.T',
  },
  {
    value: '3139.T',
    label: '3139.T,  Lacto Japan Co., Ltd.',
    name: 'Lacto Japan Co., Ltd.',
    symbol: '3139.T',
  },
  {
    value: '3141.T',
    label: '3141.T,  Welcia Holdings Co., Ltd.',
    name: 'Welcia Holdings Co., Ltd.',
    symbol: '3141.T',
  },
  {
    value: '3148.T',
    label: '3148.T,  Create SD Holdings Co., Ltd.',
    name: 'Create SD Holdings Co., Ltd.',
    symbol: '3148.T',
  },
  {
    value: '3150.T',
    label: '3150.T,  gremz,Inc.',
    name: 'gremz,Inc.',
    symbol: '3150.T',
  },
  {
    value: '3151.T',
    label: '3151.T,  Vital KSK Holdings, Inc.',
    name: 'Vital KSK Holdings, Inc.',
    symbol: '3151.T',
  },
  {
    value: '3153.T',
    label: '3153.T,  Yashima Denki Co., Ltd.',
    name: 'Yashima Denki Co., Ltd.',
    symbol: '3153.T',
  },
  {
    value: '3154.T',
    label: '3154.T,  Medius Holdings Co., Ltd.',
    name: 'Medius Holdings Co., Ltd.',
    symbol: '3154.T',
  },
  {
    value: '3156.T',
    label: '3156.T,  Restar Holdings Corporation',
    name: 'Restar Holdings Corporation',
    symbol: '3156.T',
  },
  {
    value: 'RANI',
    label: 'RANI,  Rani Therapeutics Holdings, Inc.',
    name: 'Rani Therapeutics Holdings, Inc.',
    symbol: 'RANI',
  },
  {
    value: '3157.T',
    label: '3157.T,  JUTEC Holdings Corporation',
    name: 'JUTEC Holdings Corporation',
    symbol: '3157.T',
  },
  {
    value: '3159.T',
    label: '3159.T,  Maruzen CHI Holdings Co.,Ltd.',
    name: 'Maruzen CHI Holdings Co.,Ltd.',
    symbol: '3159.T',
  },
  {
    value: '3160.T',
    label: '3160.T,  Oomitsu Co., Ltd.',
    name: 'Oomitsu Co., Ltd.',
    symbol: '3160.T',
  },
  {
    value: '3166.T',
    label: '3166.T,  Ochi Holdings Co., Ltd.',
    name: 'Ochi Holdings Co., Ltd.',
    symbol: '3166.T',
  },
  {
    value: '3167.T',
    label: '3167.T,  TOKAI Holdings Corporation',
    name: 'TOKAI Holdings Corporation',
    symbol: '3167.T',
  },
  {
    value: '3168.T',
    label: '3168.T,  Kurotani Corporation',
    name: 'Kurotani Corporation',
    symbol: '3168.T',
  },
  {
    value: '3169.T',
    label: '3169.T,  Misawa & Co.,Ltd.',
    name: 'Misawa & Co.,Ltd.',
    symbol: '3169.T',
  },
  {
    value: '3172.T',
    label: '3172.T,  Tea Life Co., Ltd.',
    name: 'Tea Life Co., Ltd.',
    symbol: '3172.T',
  },
  {
    value: '3173.T',
    label: '3173.T,  Cominix Co.,Ltd.',
    name: 'Cominix Co.,Ltd.',
    symbol: '3173.T',
  },
  {
    value: '3175.T',
    label: '3175.T,  AP Holdings Co.,Ltd.',
    name: 'AP Holdings Co.,Ltd.',
    symbol: '3175.T',
  },
  {
    value: '3176.T',
    label: '3176.T,  Sanyo Trading Co., Ltd.',
    name: 'Sanyo Trading Co., Ltd.',
    symbol: '3176.T',
  },
  {
    value: '3178.T',
    label: '3178.T,  Chimney Co., Ltd.',
    name: 'Chimney Co., Ltd.',
    symbol: '3178.T',
  },
  {
    value: '3179.T',
    label: '3179.T,  Syuppin Co., Ltd.',
    name: 'Syuppin Co., Ltd.',
    symbol: '3179.T',
  },
  {
    value: 'ICCM',
    label: 'ICCM,  IceCure Medical Ltd',
    name: 'IceCure Medical Ltd',
    symbol: 'ICCM',
  },
  {
    value: '3180.T',
    label: '3180.T,  BEAUTY GARAGE Inc.',
    name: 'BEAUTY GARAGE Inc.',
    symbol: '3180.T',
  },
  {
    value: '3182.T',
    label: '3182.T,  Oisix ra daichi Inc.',
    name: 'Oisix ra daichi Inc.',
    symbol: '3182.T',
  },
  {
    value: '3183.T',
    label: '3183.T,  WIN-Partners Co., Ltd.',
    name: 'WIN-Partners Co., Ltd.',
    symbol: '3183.T',
  },
  {
    value: '3186.T',
    label: '3186.T,  NEXTAGE Co., Ltd.',
    name: 'NEXTAGE Co., Ltd.',
    symbol: '3186.T',
  },
  {
    value: '3191.T',
    label: '3191.T,  Joyful Honda Co.,Ltd.',
    name: 'Joyful Honda Co.,Ltd.',
    symbol: '3191.T',
  },
  {
    value: '3193.T',
    label: '3193.T,  Torikizoku Holdings Co.,Ltd.',
    name: 'Torikizoku Holdings Co.,Ltd.',
    symbol: '3193.T',
  },
  {
    value: '3196.T',
    label: '3196.T,  HOTLAND Co.,Ltd.',
    name: 'HOTLAND Co.,Ltd.',
    symbol: '3196.T',
  },
  {
    value: '3197.T',
    label: '3197.T,  Skylark Holdings Co., Ltd.',
    name: 'Skylark Holdings Co., Ltd.',
    symbol: '3197.T',
  },
  {
    value: '3198.T',
    label: '3198.T,  SFP Holdings Co., Ltd.',
    name: 'SFP Holdings Co., Ltd.',
    symbol: '3198.T',
  },
  {
    value: '3199.T',
    label: '3199.T,  Watahan & Co.,Ltd.',
    name: 'Watahan & Co.,Ltd.',
    symbol: '3199.T',
  },
  {
    value: '3201.T',
    label: '3201.T,  The Japan Wool Textile Co., Ltd.',
    name: 'The Japan Wool Textile Co., Ltd.',
    symbol: '3201.T',
  },
  {
    value: '3202.T',
    label: '3202.T,  Daitobo Co., Ltd.',
    name: 'Daitobo Co., Ltd.',
    symbol: '3202.T',
  },
  {
    value: 'MIMO',
    label: 'MIMO,  Airspan Networks Holdings Inc.',
    name: 'Airspan Networks Holdings Inc.',
    symbol: 'MIMO',
  },
  {
    value: '3204.T',
    label: '3204.T,  Toabo Corporation',
    name: 'Toabo Corporation',
    symbol: '3204.T',
  },
  {
    value: '3205.T',
    label: '3205.T,  Daidoh Limited',
    name: 'Daidoh Limited',
    symbol: '3205.T',
  },
  {
    value: '3221.T',
    label: '3221.T,  Yossix Holdings Co.,Ltd.',
    name: 'Yossix Holdings Co.,Ltd.',
    symbol: '3221.T',
  },
  {
    value: '3222.T',
    label: '3222.T,  United Super Markets Holdings Inc.',
    name: 'United Super Markets Holdings Inc.',
    symbol: '3222.T',
  },
  {
    value: '3228.T',
    label: '3228.T,  Sanei Architecture Planning Co.,Ltd.',
    name: 'Sanei Architecture Planning Co.,Ltd.',
    symbol: '3228.T',
  },
  {
    value: '3231.T',
    label: '3231.T,  Nomura Real Estate Holdings, Inc.',
    name: 'Nomura Real Estate Holdings, Inc.',
    symbol: '3231.T',
  },
  {
    value: '3232.T',
    label: '3232.T,  Mie Kotsu Group Holdings, Inc.',
    name: 'Mie Kotsu Group Holdings, Inc.',
    symbol: '3232.T',
  },
  {
    value: '3244.T',
    label: '3244.T,  Samty Co., Ltd.',
    name: 'Samty Co., Ltd.',
    symbol: '3244.T',
  },
  {
    value: '3245.T',
    label: '3245.T,  Dear Life Co.,Ltd.',
    name: 'Dear Life Co.,Ltd.',
    symbol: '3245.T',
  },
  {
    value: '3246.T',
    label: '3246.T,  KOSE R.E. Co.,Ltd.',
    name: 'KOSE R.E. Co.,Ltd.',
    symbol: '3246.T',
  },
  {
    value: '3252.T',
    label: '3252.T,  JINUSHI Co.,Ltd.',
    name: 'JINUSHI Co.,Ltd.',
    symbol: '3252.T',
  },
  {
    value: '3254.T',
    label: '3254.T,  Pressance Corporation',
    name: 'Pressance Corporation',
    symbol: '3254.T',
  },
  {
    value: '3267.T',
    label: '3267.T,  Phil Company,Inc.',
    name: 'Phil Company,Inc.',
    symbol: '3267.T',
  },
  {
    value: '3271.T',
    label: '3271.T,  The Global Ltd.',
    name: 'The Global Ltd.',
    symbol: '3271.T',
  },
  {
    value: '3275.T',
    label: '3275.T,  Housecom Corporation',
    name: 'Housecom Corporation',
    symbol: '3275.T',
  },
  {
    value: 'GEEX',
    label: 'GEEX,  Games & Esports Experience Acquisition Corp.',
    name: 'Games & Esports Experience Acquisition Corp.',
    symbol: 'GEEX',
  },
  {
    value: '3276.T',
    label: '3276.T,  Japan Property Management Center Co.,Ltd.',
    name: 'Japan Property Management Center Co.,Ltd.',
    symbol: '3276.T',
  },
  {
    value: '3277.T',
    label: '3277.T,  Sansei Landic Co.,Ltd',
    name: 'Sansei Landic Co.,Ltd',
    symbol: '3277.T',
  },
  {
    value: '3280.T',
    label: '3280.T,  STrust Co.,Ltd.',
    name: 'STrust Co.,Ltd.',
    symbol: '3280.T',
  },
  {
    value: '3284.T',
    label: '3284.T,  Hoosiers Holdings Co., Ltd.',
    name: 'Hoosiers Holdings Co., Ltd.',
    symbol: '3284.T',
  },
  {
    value: '3288.T',
    label: '3288.T,  Open House Group Co., Ltd.',
    name: 'Open House Group Co., Ltd.',
    symbol: '3288.T',
  },
  {
    value: '3289.T',
    label: '3289.T,  Tokyu Fudosan Holdings Corporation',
    name: 'Tokyu Fudosan Holdings Corporation',
    symbol: '3289.T',
  },
  {
    value: '3291.T',
    label: '3291.T,  Iida Group Holdings Co., Ltd.',
    name: 'Iida Group Holdings Co., Ltd.',
    symbol: '3291.T',
  },
  {
    value: '3294.T',
    label: "3294.T,  e'grand Co.,Ltd",
    name: "e'grand Co.,Ltd",
    symbol: '3294.T',
  },
  {
    value: '3299.T',
    label: '3299.T,  MUGEN ESTATE Co.,Ltd.',
    name: 'MUGEN ESTATE Co.,Ltd.',
    symbol: '3299.T',
  },
  {
    value: '3302.T',
    label: '3302.T,  Teikoku Sen-i Co., Ltd.',
    name: 'Teikoku Sen-i Co., Ltd.',
    symbol: '3302.T',
  },
  {
    value: '3315.T',
    label: '3315.T,  Nippon Coke & Engineering Company, Limited',
    name: 'Nippon Coke & Engineering Company, Limited',
    symbol: '3315.T',
  },
  {
    value: '3319.T',
    label: '3319.T,  Golf Digest Online Inc.',
    name: 'Golf Digest Online Inc.',
    symbol: '3319.T',
  },
  {
    value: '3321.T',
    label: '3321.T,  Mitachi Co., Ltd.',
    name: 'Mitachi Co., Ltd.',
    symbol: '3321.T',
  },
  {
    value: '3328.T',
    label: '3328.T,  BEENOS Inc.',
    name: 'BEENOS Inc.',
    symbol: '3328.T',
  },
  {
    value: '3333.T',
    label: '3333.T,  Asahi Co., Ltd.',
    name: 'Asahi Co., Ltd.',
    symbol: '3333.T',
  },
  {
    value: '3341.T',
    label: '3341.T,  NIHON CHOUZAI Co.,Ltd.',
    name: 'NIHON CHOUZAI Co.,Ltd.',
    symbol: '3341.T',
  },
  {
    value: '3349.T',
    label: '3349.T,  COSMOS Pharmaceutical Corporation',
    name: 'COSMOS Pharmaceutical Corporation',
    symbol: '3349.T',
  },
  {
    value: '3360.T',
    label: '3360.T,  Ship Healthcare Holdings, Inc.',
    name: 'Ship Healthcare Holdings, Inc.',
    symbol: '3360.T',
  },
  {
    value: '3361.T',
    label: '3361.T,  Toell Co.,Ltd.',
    name: 'Toell Co.,Ltd.',
    symbol: '3361.T',
  },
  {
    value: '3371.T',
    label: '3371.T,  Softcreate Holdings Corp.',
    name: 'Softcreate Holdings Corp.',
    symbol: '3371.T',
  },
  {
    value: '3382.T',
    label: '3382.T,  Seven & i Holdings Co., Ltd.',
    name: 'Seven & i Holdings Co., Ltd.',
    symbol: '3382.T',
  },
  {
    value: '3387.T',
    label: '3387.T,  create restaurants holdings inc.',
    name: 'create restaurants holdings inc.',
    symbol: '3387.T',
  },
  {
    value: '3388.T',
    label: '3388.T,  Meiji Electric Industries Co.,Ltd.',
    name: 'Meiji Electric Industries Co.,Ltd.',
    symbol: '3388.T',
  },
  {
    value: '3391.T',
    label: '3391.T,  Tsuruha Holdings Inc.',
    name: 'Tsuruha Holdings Inc.',
    symbol: '3391.T',
  },
  {
    value: '3392.T',
    label: '3392.T,  Delica Foods Holdings Co., Ltd.',
    name: 'Delica Foods Holdings Co., Ltd.',
    symbol: '3392.T',
  },
  {
    value: '3393.T',
    label: '3393.T,  Startia Holdings,Inc.',
    name: 'Startia Holdings,Inc.',
    symbol: '3393.T',
  },
  {
    value: '3395.T',
    label: '3395.T,  Saint Marc Holdings Co., Ltd.',
    name: 'Saint Marc Holdings Co., Ltd.',
    symbol: '3395.T',
  },
  {
    value: '3396.T',
    label: '3396.T,  Felissimo Corporation',
    name: 'Felissimo Corporation',
    symbol: '3396.T',
  },
  {
    value: '3397.T',
    label: '3397.T,  TORIDOLL Holdings Corporation',
    name: 'TORIDOLL Holdings Corporation',
    symbol: '3397.T',
  },
  {
    value: '3401.T',
    label: '3401.T,  Teijin Limited',
    name: 'Teijin Limited',
    symbol: '3401.T',
  },
  {
    value: '3402.T',
    label: '3402.T,  Toray Industries, Inc.',
    name: 'Toray Industries, Inc.',
    symbol: '3402.T',
  },
  {
    value: '3405.T',
    label: '3405.T,  Kuraray Co., Ltd.',
    name: 'Kuraray Co., Ltd.',
    symbol: '3405.T',
  },
  {
    value: '3407.T',
    label: '3407.T,  Asahi Kasei Corporation',
    name: 'Asahi Kasei Corporation',
    symbol: '3407.T',
  },
  {
    value: '3415.T',
    label: '3415.T,  TOKYO BASE Co.,Ltd.',
    name: 'TOKYO BASE Co.,Ltd.',
    symbol: '3415.T',
  },
  {
    value: '3421.T',
    label: '3421.T,  Inaba Seisakusho Co., Ltd.',
    name: 'Inaba Seisakusho Co., Ltd.',
    symbol: '3421.T',
  },
  {
    value: '3431.T',
    label: '3431.T,  Miyaji Engineering Group,Inc.',
    name: 'Miyaji Engineering Group,Inc.',
    symbol: '3431.T',
  },
  {
    value: '3433.T',
    label: '3433.T,  TOCALO Co.,Ltd.',
    name: 'TOCALO Co.,Ltd.',
    symbol: '3433.T',
  },
  {
    value: '3434.T',
    label: '3434.T,  ALPHA Corporation',
    name: 'ALPHA Corporation',
    symbol: '3434.T',
  },
  {
    value: '3443.T',
    label: '3443.T,  Kawada Technologies, Inc.',
    name: 'Kawada Technologies, Inc.',
    symbol: '3443.T',
  },
  {
    value: '3445.T',
    label: '3445.T,  RS Technologies Co., Ltd.',
    name: 'RS Technologies Co., Ltd.',
    symbol: '3445.T',
  },
  {
    value: '3446.T',
    label: '3446.T,  JTEC Corporation',
    name: 'JTEC Corporation',
    symbol: '3446.T',
  },
  {
    value: '3447.T',
    label: '3447.T,  Shinwa Co., Ltd.',
    name: 'Shinwa Co., Ltd.',
    symbol: '3447.T',
  },
  {
    value: '3452.T',
    label: '3452.T,  B-Lot Company Limited',
    name: 'B-Lot Company Limited',
    symbol: '3452.T',
  },
  {
    value: '3454.T',
    label: '3454.T,  First Brothers Co.,Ltd.',
    name: 'First Brothers Co.,Ltd.',
    symbol: '3454.T',
  },
  {
    value: '3457.T',
    label: '3457.T,  &Do Holdings Co.,Ltd.',
    name: '&Do Holdings Co.,Ltd.',
    symbol: '3457.T',
  },
  {
    value: '3458.T',
    label: '3458.T,  CRE, Inc.',
    name: 'CRE, Inc.',
    symbol: '3458.T',
  },
  {
    value: '3464.T',
    label: '3464.T,  Property Agent Inc.',
    name: 'Property Agent Inc.',
    symbol: '3464.T',
  },
  {
    value: '3465.T',
    label: '3465.T,  Ki-Star Real Estate Co.,Ltd',
    name: 'Ki-Star Real Estate Co.,Ltd',
    symbol: '3465.T',
  },
  {
    value: '3467.T',
    label: '3467.T,  Agratio urban design Inc.',
    name: 'Agratio urban design Inc.',
    symbol: '3467.T',
  },
  {
    value: '3475.T',
    label: '3475.T,  Good Com Asset Co., Ltd.',
    name: 'Good Com Asset Co., Ltd.',
    symbol: '3475.T',
  },
  {
    value: '3480.T',
    label: '3480.T,  J.S.B.Co.,Ltd.',
    name: 'J.S.B.Co.,Ltd.',
    symbol: '3480.T',
  },
  {
    value: '3484.T',
    label: '3484.T,  Tenpo Innovation CO.,LTD.',
    name: 'Tenpo Innovation CO.,LTD.',
    symbol: '3484.T',
  },
  {
    value: '3486.T',
    label: '3486.T,  Global Link Management Inc.',
    name: 'Global Link Management Inc.',
    symbol: '3486.T',
  },
  {
    value: '3489.T',
    label: '3489.T,  FaithNetwork Co.,Ltd',
    name: 'FaithNetwork Co.,Ltd',
    symbol: '3489.T',
  },
  {
    value: '3501.T',
    label: '3501.T,  Suminoe Textile Co., Ltd.',
    name: 'Suminoe Textile Co., Ltd.',
    symbol: '3501.T',
  },
  {
    value: '3512.T',
    label: '3512.T,  Nippon Felt Co., Ltd.',
    name: 'Nippon Felt Co., Ltd.',
    symbol: '3512.T',
  },
  {
    value: '3513.T',
    label: '3513.T,  Ichikawa Co., Ltd.',
    name: 'Ichikawa Co., Ltd.',
    symbol: '3513.T',
  },
  {
    value: '3521.T',
    label: '3521.T,  Econach Holdings Co.,Ltd.',
    name: 'Econach Holdings Co.,Ltd.',
    symbol: '3521.T',
  },
  {
    value: '3524.T',
    label: '3524.T,  Nitto Seimo Co., Ltd.',
    name: 'Nitto Seimo Co., Ltd.',
    symbol: '3524.T',
  },
  {
    value: '3526.T',
    label: '3526.T,  Ashimori Industry Co., Ltd.',
    name: 'Ashimori Industry Co., Ltd.',
    symbol: '3526.T',
  },
  {
    value: '3529.T',
    label: '3529.T,  Atsugi Co., Ltd.',
    name: 'Atsugi Co., Ltd.',
    symbol: '3529.T',
  },
  {
    value: '3538.T',
    label: '3538.T,  WILLPLUS Holdings Corporation',
    name: 'WILLPLUS Holdings Corporation',
    symbol: '3538.T',
  },
  {
    value: '3544.T',
    label: '3544.T,  Satudora Holdings Co.,Ltd.',
    name: 'Satudora Holdings Co.,Ltd.',
    symbol: '3544.T',
  },
  {
    value: '3547.T',
    label: '3547.T,  Kushikatsu Tanaka Holdings Co.',
    name: 'Kushikatsu Tanaka Holdings Co.',
    symbol: '3547.T',
  },
  {
    value: '3551.T',
    label: '3551.T,  Dynic Corporation',
    name: 'Dynic Corporation',
    symbol: '3551.T',
  },
  {
    value: '3553.T',
    label: '3553.T,  Kyowa Leather Cloth Co., Ltd.',
    name: 'Kyowa Leather Cloth Co., Ltd.',
    symbol: '3553.T',
  },
  {
    value: '3565.T',
    label: '3565.T,  Ascentech K.K.',
    name: 'Ascentech K.K.',
    symbol: '3565.T',
  },
  {
    value: '3571.T',
    label: '3571.T,  Sotoh Co., Ltd.',
    name: 'Sotoh Co., Ltd.',
    symbol: '3571.T',
  },
  {
    value: '3577.T',
    label: '3577.T,  Tokai Senko K.K.',
    name: 'Tokai Senko K.K.',
    symbol: '3577.T',
  },
  {
    value: '3607.T',
    label: '3607.T,  Kuraudia Holdings Co.,Ltd.',
    name: 'Kuraudia Holdings Co.,Ltd.',
    symbol: '3607.T',
  },
  {
    value: '3611.T',
    label: '3611.T,  Matsuoka Corporation',
    name: 'Matsuoka Corporation',
    symbol: '3611.T',
  },
  {
    value: '3627.T',
    label: '3627.T,  JNS Holdings Inc.',
    name: 'JNS Holdings Inc.',
    symbol: '3627.T',
  },
  {
    value: '3633.T',
    label: '3633.T,  GMO Pepabo, Inc.',
    name: 'GMO Pepabo, Inc.',
    symbol: '3633.T',
  },
  {
    value: '3639.T',
    label: '3639.T,  Voltage Incorporation',
    name: 'Voltage Incorporation',
    symbol: '3639.T',
  },
  {
    value: '3640.T',
    label: '3640.T,  Densan Co., Ltd.',
    name: 'Densan Co., Ltd.',
    symbol: '3640.T',
  },
  {
    value: '3648.T',
    label: '3648.T,  AGS Corporation',
    name: 'AGS Corporation',
    symbol: '3648.T',
  },
  {
    value: '3666.T',
    label: '3666.T,  Tecnos Japan Incorporated',
    name: 'Tecnos Japan Incorporated',
    symbol: '3666.T',
  },
  {
    value: '3667.T',
    label: '3667.T,  enish,inc.',
    name: 'enish,inc.',
    symbol: '3667.T',
  },
  {
    value: '3672.T',
    label: '3672.T,  AltPlus Inc.',
    name: 'AltPlus Inc.',
    symbol: '3672.T',
  },
  {
    value: '3675.T',
    label: '3675.T,  Cross Marketing Group Inc.',
    name: 'Cross Marketing Group Inc.',
    symbol: '3675.T',
  },
  {
    value: '3682.T',
    label: '3682.T,  Encourage Technologies Co., Ltd.',
    name: 'Encourage Technologies Co., Ltd.',
    symbol: '3682.T',
  },
  {
    value: '3686.T',
    label: '3686.T,  DLE Inc.',
    name: 'DLE Inc.',
    symbol: '3686.T',
  },
  {
    value: '3741.T',
    label: '3741.T,  Systems Engineering Consultants Co.,LTD.',
    name: 'Systems Engineering Consultants Co.,LTD.',
    symbol: '3741.T',
  },
  {
    value: '3763.T',
    label: '3763.T,  Pro-Ship Incorporated',
    name: 'Pro-Ship Incorporated',
    symbol: '3763.T',
  },
  {
    value: '3770.T',
    label: '3770.T,  Zappallas, Inc.',
    name: 'Zappallas, Inc.',
    symbol: '3770.T',
  },
  {
    value: '3771.T',
    label: '3771.T,  System Research Co.,Ltd.',
    name: 'System Research Co.,Ltd.',
    symbol: '3771.T',
  },
  {
    value: '3784.T',
    label: '3784.T,  Vinx Corp.',
    name: 'Vinx Corp.',
    symbol: '3784.T',
  },
  {
    value: '3826.T',
    label: '3826.T,  System Integrator Corp.',
    name: 'System Integrator Corp.',
    symbol: '3826.T',
  },
  {
    value: '3839.T',
    label: '3839.T,  ODK Solutions Company, Ltd.',
    name: 'ODK Solutions Company, Ltd.',
    symbol: '3839.T',
  },
  {
    value: '3852.T',
    label: '3852.T,  Cyber Com Co., Ltd.',
    name: 'Cyber Com Co., Ltd.',
    symbol: '3852.T',
  },
  {
    value: '3853.T',
    label: '3853.T,  Asteria Corporation',
    name: 'Asteria Corporation',
    symbol: '3853.T',
  },
  {
    value: '3854.T',
    label: "3854.T,  I'LL inc.",
    name: "I'LL inc.",
    symbol: '3854.T',
  },
  {
    value: '3877.T',
    label: '3877.T,  Chuetsu Pulp & Paper Co., Ltd.',
    name: 'Chuetsu Pulp & Paper Co., Ltd.',
    symbol: '3877.T',
  },
  {
    value: '3878.T',
    label: '3878.T,  Tomoegawa Co., Ltd.',
    name: 'Tomoegawa Co., Ltd.',
    symbol: '3878.T',
  },
  {
    value: '3896.T',
    label: '3896.T,  Awa Paper & Technological Company, Inc.',
    name: 'Awa Paper & Technological Company, Inc.',
    symbol: '3896.T',
  },
  {
    value: '3909.T',
    label: '3909.T,  Showcase Inc.',
    name: 'Showcase Inc.',
    symbol: '3909.T',
  },
  {
    value: '3918.T',
    label: '3918.T,  PCI Holdings, Inc.',
    name: 'PCI Holdings, Inc.',
    symbol: '3918.T',
  },
  {
    value: '3920.T',
    label: '3920.T,  Internetworking and Broadband Consulting Co.,Ltd.',
    name: 'Internetworking and Broadband Consulting Co.,Ltd.',
    symbol: '3920.T',
  },
  {
    value: '3921.T',
    label: '3921.T,  NEOJAPAN Inc.',
    name: 'NEOJAPAN Inc.',
    symbol: '3921.T',
  },
  {
    value: '3924.T',
    label: '3924.T,  R&D Computer Co.,Ltd.',
    name: 'R&D Computer Co.,Ltd.',
    symbol: '3924.T',
  },
  {
    value: '3925.T',
    label: '3925.T,  Double Standard Inc.',
    name: 'Double Standard Inc.',
    symbol: '3925.T',
  },
  {
    value: '3928.T',
    label: '3928.T,  Mynet Inc.',
    name: 'Mynet Inc.',
    symbol: '3928.T',
  },
  {
    value: '3934.T',
    label: '3934.T,  BENEFIT JAPAN Co.,LTD.',
    name: 'BENEFIT JAPAN Co.,LTD.',
    symbol: '3934.T',
  },
  {
    value: '3937.T',
    label: '3937.T,  Ubicom Holdings, Inc.',
    name: 'Ubicom Holdings, Inc.',
    symbol: '3937.T',
  },
  {
    value: '3939.T',
    label: '3939.T,  Kanamic Network Co.,LTD',
    name: 'Kanamic Network Co.,LTD',
    symbol: '3939.T',
  },
  {
    value: '3940.T',
    label: '3940.T,  Nomura System Corporation Co, Ltd.',
    name: 'Nomura System Corporation Co, Ltd.',
    symbol: '3940.T',
  },
  {
    value: '3963.T',
    label: '3963.T,  Synchro Food Co., Ltd.',
    name: 'Synchro Food Co., Ltd.',
    symbol: '3963.T',
  },
  {
    value: '3965.T',
    label: '3965.T,  Capital Asset Planning, Inc.',
    name: 'Capital Asset Planning, Inc.',
    symbol: '3965.T',
  },
  {
    value: '3968.T',
    label: '3968.T,  Segue Group Co.,Ltd.',
    name: 'Segue Group Co.,Ltd.',
    symbol: '3968.T',
  },
  {
    value: '3969.T',
    label: '3969.T,  ATLED CORP.',
    name: 'ATLED CORP.',
    symbol: '3969.T',
  },
  {
    value: '3984.T',
    label: '3984.T,  User Local, Inc.',
    name: 'User Local, Inc.',
    symbol: '3984.T',
  },
  {
    value: '3985.T',
    label: '3985.T,  TEMONA.inc.',
    name: 'TEMONA.inc.',
    symbol: '3985.T',
  },
  {
    value: '3992.T',
    label: '3992.T,  Needs Well Inc.',
    name: 'Needs Well Inc.',
    symbol: '3992.T',
  },
  {
    value: '3996.T',
    label: '3996.T,  Signpost Corporation',
    name: 'Signpost Corporation',
    symbol: '3996.T',
  },
  {
    value: '4031.T',
    label: '4031.T,  Katakura & Co-op Agri Corporation',
    name: 'Katakura & Co-op Agri Corporation',
    symbol: '4031.T',
  },
  {
    value: '4072.T',
    label: '4072.T,  Densan System Holdings Co., Ltd.',
    name: 'Densan System Holdings Co., Ltd.',
    symbol: '4072.T',
  },
  {
    value: '4093.T',
    label: '4093.T,  Toho Acetylene Co., Ltd.',
    name: 'Toho Acetylene Co., Ltd.',
    symbol: '4093.T',
  },
  {
    value: '4098.T',
    label: '4098.T,  Titan Kogyo, Ltd.',
    name: 'Titan Kogyo, Ltd.',
    symbol: '4098.T',
  },
  {
    value: '4100.T',
    label: '4100.T,  Toda Kogyo Corp.',
    name: 'Toda Kogyo Corp.',
    symbol: '4100.T',
  },
  {
    value: '4221.T',
    label: '4221.T,  Okura Industrial Co., Ltd.',
    name: 'Okura Industrial Co., Ltd.',
    symbol: '4221.T',
  },
  {
    value: '4229.T',
    label: '4229.T,  Gun Ei Chemical Industry Co., Ltd.',
    name: 'Gun Ei Chemical Industry Co., Ltd.',
    symbol: '4229.T',
  },
  {
    value: '4231.T',
    label: '4231.T,  Tigers Polymer Corporation',
    name: 'Tigers Polymer Corporation',
    symbol: '4231.T',
  },
  {
    value: '4238.T',
    label: '4238.T,  Miraial Co., Ltd.',
    name: 'Miraial Co., Ltd.',
    symbol: '4238.T',
  },
  {
    value: '4245.T',
    label: '4245.T,  Daiki Axis Co., Ltd.',
    name: 'Daiki Axis Co., Ltd.',
    symbol: '4245.T',
  },
  {
    value: '4248.T',
    label: '4248.T,  Takemoto Yohki Co., Ltd.',
    name: 'Takemoto Yohki Co., Ltd.',
    symbol: '4248.T',
  },
  {
    value: '4284.T',
    label: '4284.T,  SOLXYZ Co., Ltd.',
    name: 'SOLXYZ Co., Ltd.',
    symbol: '4284.T',
  },
  {
    value: '4295.T',
    label: '4295.T,  Faith, Inc.',
    name: 'Faith, Inc.',
    symbol: '4295.T',
  },
  {
    value: '4299.T',
    label: '4299.T,  HIMACS, Ltd.',
    name: 'HIMACS, Ltd.',
    symbol: '4299.T',
  },
  {
    value: '4310.T',
    label: '4310.T,  Dream Incubator Inc.',
    name: 'Dream Incubator Inc.',
    symbol: '4310.T',
  },
  {
    value: '4319.T',
    label: '4319.T,  TAC Co.,Ltd.',
    name: 'TAC Co.,Ltd.',
    symbol: '4319.T',
  },
  {
    value: '4320.T',
    label: '4320.T,  CE Holdings Co., Ltd.',
    name: 'CE Holdings Co., Ltd.',
    symbol: '4320.T',
  },
  {
    value: '4323.T',
    label: '4323.T,  Japan System Techniques Co., Ltd.',
    name: 'Japan System Techniques Co., Ltd.',
    symbol: '4323.T',
  },
  {
    value: '4331.T',
    label: '4331.T,  Take and Give. Needs Co., Ltd',
    name: 'Take and Give. Needs Co., Ltd',
    symbol: '4331.T',
  },
  {
    value: '4333.T',
    label: '4333.T,  Toho System Science Co., Ltd.',
    name: 'Toho System Science Co., Ltd.',
    symbol: '4333.T',
  },
  {
    value: '4346.T',
    label: '4346.T,  Nexyz. Group Corporation',
    name: 'Nexyz. Group Corporation',
    symbol: '4346.T',
  },
  {
    value: '4350.T',
    label: '4350.T,  Medical System Network Co., Ltd.',
    name: 'Medical System Network Co., Ltd.',
    symbol: '4350.T',
  },
  {
    value: '4373.T',
    label: '4373.T,  Simplex Holdings, Inc.',
    name: 'Simplex Holdings, Inc.',
    symbol: '4373.T',
  },
  {
    value: '4382.T',
    label: '4382.T,  HEROZ, Inc.',
    name: 'HEROZ, Inc.',
    symbol: '4382.T',
  },
  {
    value: '4390.T',
    label: '4390.T,  IPS, Inc.',
    name: 'IPS, Inc.',
    symbol: '4390.T',
  },
  {
    value: '4392.T',
    label: '4392.T,  Future Innovation Group, Inc.',
    name: 'Future Innovation Group, Inc.',
    symbol: '4392.T',
  },
  {
    value: '4396.T',
    label: '4396.T,  System Support Inc.',
    name: 'System Support Inc.',
    symbol: '4396.T',
  },
  {
    value: '4404.T',
    label: '4404.T,  Miyoshi Oil & Fat Co., Ltd.',
    name: 'Miyoshi Oil & Fat Co., Ltd.',
    symbol: '4404.T',
  },
  {
    value: '4406.T',
    label: '4406.T,  New Japan Chemical Co., Ltd.',
    name: 'New Japan Chemical Co., Ltd.',
    symbol: '4406.T',
  },
  {
    value: '4410.T',
    label: '4410.T,  Harima Chemicals Group, Inc.',
    name: 'Harima Chemicals Group, Inc.',
    symbol: '4410.T',
  },
  {
    value: '4420.T',
    label: '4420.T,  eSOL Co.,Ltd.',
    name: 'eSOL Co.,Ltd.',
    symbol: '4420.T',
  },
  {
    value: '4430.T',
    label: '4430.T,  Tokai Soft Co., Ltd.',
    name: 'Tokai Soft Co., Ltd.',
    symbol: '4430.T',
  },
  {
    value: '4432.T',
    label: '4432.T,  WingArc1st Inc.',
    name: 'WingArc1st Inc.',
    symbol: '4432.T',
  },
  {
    value: '4434.T',
    label: '4434.T,  Serverworks Co.,Ltd.',
    name: 'Serverworks Co.,Ltd.',
    symbol: '4434.T',
  },
  {
    value: '4439.T',
    label: '4439.T,  Toumei Co.,Ltd.',
    name: 'Toumei Co.,Ltd.',
    symbol: '4439.T',
  },
  {
    value: '4440.T',
    label: '4440.T,  WITZ Corporation',
    name: 'WITZ Corporation',
    symbol: '4440.T',
  },
  {
    value: '4441.T',
    label: '4441.T,  Tobila Systems Inc.',
    name: 'Tobila Systems Inc.',
    symbol: '4441.T',
  },
  {
    value: '4446.T',
    label: '4446.T,  Link-U Inc.',
    name: 'Link-U Inc.',
    symbol: '4446.T',
  },
  {
    value: '4462.T',
    label: '4462.T,  Ishihara Chemical Co., Ltd.',
    name: 'Ishihara Chemical Co., Ltd.',
    symbol: '4462.T',
  },
  {
    value: '4463.T',
    label: '4463.T,  Nicca Chemical Co.,Ltd.',
    name: 'Nicca Chemical Co.,Ltd.',
    symbol: '4463.T',
  },
  {
    value: '4465.T',
    label: '4465.T,  Niitaka Co., Ltd.',
    name: 'Niitaka Co., Ltd.',
    symbol: '4465.T',
  },
  {
    value: '4512.T',
    label: '4512.T,  Wakamoto Pharmaceutical Co.,Ltd.',
    name: 'Wakamoto Pharmaceutical Co.,Ltd.',
    symbol: '4512.T',
  },
  {
    value: '4531.T',
    label: '4531.T,  Yuki Gosei Kogyo Co., Ltd.',
    name: 'Yuki Gosei Kogyo Co., Ltd.',
    symbol: '4531.T',
  },
  {
    value: '4538.T',
    label: '4538.T,  Fuso Pharmaceutical Industries,Ltd.',
    name: 'Fuso Pharmaceutical Industries,Ltd.',
    symbol: '4538.T',
  },
  {
    value: '4539.T',
    label: '4539.T,  Nippon Chemiphar Co., Ltd.',
    name: 'Nippon Chemiphar Co., Ltd.',
    symbol: '4539.T',
  },
  {
    value: '4550.T',
    label: '4550.T,  Nissui Pharmaceutical Co., Ltd.',
    name: 'Nissui Pharmaceutical Co., Ltd.',
    symbol: '4550.T',
  },
  {
    value: '4615.T',
    label: '4615.T,  Shinto Paint Company, Limited',
    name: 'Shinto Paint Company, Limited',
    symbol: '4615.T',
  },
  {
    value: '4620.T',
    label: '4620.T,  Fujikura Kasei Co., Ltd.',
    name: 'Fujikura Kasei Co., Ltd.',
    symbol: '4620.T',
  },
  {
    value: '4636.T',
    label: '4636.T,  T&K Toka Co., Ltd.',
    name: 'T&K Toka Co., Ltd.',
    symbol: '4636.T',
  },
  {
    value: '4651.T',
    label: '4651.T,  Sanix Incorporated',
    name: 'Sanix Incorporated',
    symbol: '4651.T',
  },
  {
    value: '4653.T',
    label: '4653.T,  Daiohs Corporation',
    name: 'Daiohs Corporation',
    symbol: '4653.T',
  },
  {
    value: '4678.T',
    label: '4678.T,  SHUEI YOBIKO Co., Ltd.',
    name: 'SHUEI YOBIKO Co., Ltd.',
    symbol: '4678.T',
  },
  {
    value: '4679.T',
    label: '4679.T,  TAYA Co.,Ltd.',
    name: 'TAYA Co.,Ltd.',
    symbol: '4679.T',
  },
  {
    value: '4718.T',
    label: '4718.T,  Waseda Academy Co., Ltd.',
    name: 'Waseda Academy Co., Ltd.',
    symbol: '4718.T',
  },
  {
    value: '4728.T',
    label: '4728.T,  Tose Co., Ltd.',
    name: 'Tose Co., Ltd.',
    symbol: '4728.T',
  },
  {
    value: '4746.T',
    label: '4746.T,  Toukei Computer Co., Ltd.',
    name: 'Toukei Computer Co., Ltd.',
    symbol: '4746.T',
  },
  {
    value: '4762.T',
    label: '4762.T,  XNET Corporation',
    name: 'XNET Corporation',
    symbol: '4762.T',
  },
  {
    value: '4767.T',
    label: '4767.T,  TOW Co.,Ltd.',
    name: 'TOW Co.,Ltd.',
    symbol: '4767.T',
  },
  {
    value: '4801.T',
    label: '4801.T,  Central Sports Co., Ltd.',
    name: 'Central Sports Co., Ltd.',
    symbol: '4801.T',
  },
  {
    value: '4809.T',
    label: '4809.T,  Paraca Inc.',
    name: 'Paraca Inc.',
    symbol: '4809.T',
  },
  {
    value: '4826.T',
    label: '4826.T,  Computer Institute of Japan, Ltd.',
    name: 'Computer Institute of Japan, Ltd.',
    symbol: '4826.T',
  },
  {
    value: '4829.T',
    label: '4829.T,  Nihon Enterprise Co.,Ltd.',
    name: 'Nihon Enterprise Co.,Ltd.',
    symbol: '4829.T',
  },
  {
    value: '4845.T',
    label: '4845.T,  Scala, Inc.',
    name: 'Scala, Inc.',
    symbol: '4845.T',
  },
  {
    value: '4923.T',
    label: '4923.T,  Cota Co., Ltd.',
    name: 'Cota Co., Ltd.',
    symbol: '4923.T',
  },
  {
    value: '4926.T',
    label: "4926.T,  C'bon Cosmetics Co.,Ltd.",
    name: "C'bon Cosmetics Co.,Ltd.",
    symbol: '4926.T',
  },
  {
    value: '4929.T',
    label: '4929.T,  Adjuvant Holdings Co.,Ltd.',
    name: 'Adjuvant Holdings Co.,Ltd.',
    symbol: '4929.T',
  },
  {
    value: '4955.T',
    label: '4955.T,  Agro-Kanesho Co., Ltd.',
    name: 'Agro-Kanesho Co., Ltd.',
    symbol: '4955.T',
  },
  {
    value: '4973.T',
    label: '4973.T,  Japan Pure Chemical Co., Ltd.',
    name: 'Japan Pure Chemical Co., Ltd.',
    symbol: '4973.T',
  },
  {
    value: '4977.T',
    label: '4977.T,  Nitta Gelatin Inc.',
    name: 'Nitta Gelatin Inc.',
    symbol: '4977.T',
  },
  {
    value: '4979.T',
    label: '4979.T,  OAT Agrio Co., Ltd.',
    name: 'OAT Agrio Co., Ltd.',
    symbol: '4979.T',
  },
  {
    value: '4994.T',
    label: '4994.T,  Taisei Lamick Co., Ltd.',
    name: 'Taisei Lamick Co., Ltd.',
    symbol: '4994.T',
  },
  {
    value: '5009.T',
    label: '5009.T,  Fuji Kosan Company, Ltd.',
    name: 'Fuji Kosan Company, Ltd.',
    symbol: '5009.T',
  },
  {
    value: '5013.T',
    label: '5013.T,  Yushiro Chemical Industry Co., Ltd.',
    name: 'Yushiro Chemical Industry Co., Ltd.',
    symbol: '5013.T',
  },
  {
    value: '5015.T',
    label: '5015.T,  BP Castrol K.K.',
    name: 'BP Castrol K.K.',
    symbol: '5015.T',
  },
  {
    value: '5018.T',
    label: '5018.T,  MORESCO Corporation',
    name: 'MORESCO Corporation',
    symbol: '5018.T',
  },
  {
    value: '5121.T',
    label: '5121.T,  FUJIKURA COMPOSITES Inc.',
    name: 'FUJIKURA COMPOSITES Inc.',
    symbol: '5121.T',
  },
  {
    value: '5185.T',
    label: '5185.T,  Fukoku Co.,Ltd.',
    name: 'Fukoku Co.,Ltd.',
    symbol: '5185.T',
  },
  {
    value: '5187.T',
    label: '5187.T,  Create Medic Co., Ltd.',
    name: 'Create Medic Co., Ltd.',
    symbol: '5187.T',
  },
  {
    value: '5204.T',
    label: '5204.T,  Ishizuka Glass Co., Ltd.',
    name: 'Ishizuka Glass Co., Ltd.',
    symbol: '5204.T',
  },
  {
    value: '5210.T',
    label: '5210.T,  Nihon Yamamura Glass Co., Ltd.',
    name: 'Nihon Yamamura Glass Co., Ltd.',
    symbol: '5210.T',
  },
  {
    value: '5261.T',
    label: '5261.T,  RESOL HOLDINGS Co.,Ltd.',
    name: 'RESOL HOLDINGS Co.,Ltd.',
    symbol: '5261.T',
  },
  {
    value: '5337.T',
    label: '5337.T,  Danto Holdings Corporation',
    name: 'Danto Holdings Corporation',
    symbol: '5337.T',
  },
  {
    value: '5363.T',
    label: '5363.T,  TYK Corporation',
    name: 'TYK Corporation',
    symbol: '5363.T',
  },
  {
    value: '5367.T',
    label: '5367.T,  Nikkato Corporation',
    name: 'Nikkato Corporation',
    symbol: '5367.T',
  },
  {
    value: '5388.T',
    label: '5388.T,  Kunimine Industries Co., Ltd.',
    name: 'Kunimine Industries Co., Ltd.',
    symbol: '5388.T',
  },
  {
    value: '5391.T',
    label: '5391.T,  A&A Material Corporation',
    name: 'A&A Material Corporation',
    symbol: '5391.T',
  },
  {
    value: '5464.T',
    label: '5464.T,  Mory Industries Inc.',
    name: 'Mory Industries Inc.',
    symbol: '5464.T',
  },
  {
    value: '5476.T',
    label: '5476.T,  Nippon Koshuha Steel Co.,Ltd.',
    name: 'Nippon Koshuha Steel Co.,Ltd.',
    symbol: '5476.T',
  },
  {
    value: '5491.T',
    label: '5491.T,  Nippon Kinzoku Co., Ltd.',
    name: 'Nippon Kinzoku Co., Ltd.',
    symbol: '5491.T',
  },
  {
    value: '5603.T',
    label: '5603.T,  Kogi Corporation',
    name: 'Kogi Corporation',
    symbol: '5603.T',
  },
  {
    value: '5612.T',
    label: '5612.T,  Nippon Chutetsukan K.K.',
    name: 'Nippon Chutetsukan K.K.',
    symbol: '5612.T',
  },
  {
    value: '5632.T',
    label: '5632.T,  Mitsubishi Steel Mfg. Co., Ltd.',
    name: 'Mitsubishi Steel Mfg. Co., Ltd.',
    symbol: '5632.T',
  },
  {
    value: '5658.T',
    label: '5658.T,  Nichia Steel Works, Ltd.',
    name: 'Nichia Steel Works, Ltd.',
    symbol: '5658.T',
  },
  {
    value: '5659.T',
    label: '5659.T,  Nippon Seisen Co.,Ltd.',
    name: 'Nippon Seisen Co.,Ltd.',
    symbol: '5659.T',
  },
  {
    value: '5698.T',
    label: '5698.T,  Envipro Holdings Inc.',
    name: 'Envipro Holdings Inc.',
    symbol: '5698.T',
  },
  {
    value: '5721.T',
    label: '5721.T,  S Science Company, Ltd.',
    name: 'S Science Company, Ltd.',
    symbol: '5721.T',
  },
  {
    value: '5757.T',
    label: '5757.T,  CK SAN-ETSU Co., Ltd.',
    name: 'CK SAN-ETSU Co., Ltd.',
    symbol: '5757.T',
  },
  {
    value: '5807.T',
    label: '5807.T,  Totoku Electric Co., Ltd.',
    name: 'Totoku Electric Co., Ltd.',
    symbol: '5807.T',
  },
  {
    value: '5819.T',
    label: '5819.T,  Canare Electric Co., Ltd.',
    name: 'Canare Electric Co., Ltd.',
    symbol: '5819.T',
  },
  {
    value: '5852.T',
    label: '5852.T,  Ahresty Corporation',
    name: 'Ahresty Corporation',
    symbol: '5852.T',
  },
  {
    value: '5909.T',
    label: '5909.T,  Corona Corporation',
    name: 'Corona Corporation',
    symbol: '5909.T',
  },
  {
    value: '5915.T',
    label: '5915.T,  Komaihaltec Inc.',
    name: 'Komaihaltec Inc.',
    symbol: '5915.T',
  },
  {
    value: '5923.T',
    label: '5923.T,  TAKADAKIKO (Steel Construction) CO.,LTD.',
    name: 'TAKADAKIKO (Steel Construction) CO.,LTD.',
    symbol: '5923.T',
  },
  {
    value: '5936.T',
    label: '5936.T,  Toyo Shutter Co., Ltd.',
    name: 'Toyo Shutter Co., Ltd.',
    symbol: '5936.T',
  },
  {
    value: '5942.T',
    label: '5942.T,  Nippon Filcon Co., Ltd.',
    name: 'Nippon Filcon Co., Ltd.',
    symbol: '5942.T',
  },
  {
    value: '5951.T',
    label: '5951.T,  Dainichi Co., Ltd.',
    name: 'Dainichi Co., Ltd.',
    symbol: '5951.T',
  },
  {
    value: '5958.T',
    label: '5958.T,  Sanyo Industries, Ltd.',
    name: 'Sanyo Industries, Ltd.',
    symbol: '5958.T',
  },
  {
    value: '5985.T',
    label: '5985.T,  Suncall Corporation',
    name: 'Suncall Corporation',
    symbol: '5985.T',
  },
  {
    value: '5986.T',
    label: '5986.T,  Molitec Steel Co., Ltd.',
    name: 'Molitec Steel Co., Ltd.',
    symbol: '5986.T',
  },
  {
    value: '5992.T',
    label: '5992.T,  Chuo Spring Co.,Ltd.',
    name: 'Chuo Spring Co.,Ltd.',
    symbol: '5992.T',
  },
  {
    value: '5998.T',
    label: '5998.T,  Advanex Inc.',
    name: 'Advanex Inc.',
    symbol: '5998.T',
  },
  {
    value: '6029.T',
    label: '6029.T,  Artra Group Corporation',
    name: 'Artra Group Corporation',
    symbol: '6029.T',
  },
  {
    value: '6032.T',
    label: '6032.T,  Interworks, Inc.',
    name: 'Interworks, Inc.',
    symbol: '6032.T',
  },
  {
    value: '6037.T',
    label: '6037.T,  Firstlogic,Inc.',
    name: 'Firstlogic,Inc.',
    symbol: '6037.T',
  },
  {
    value: '6044.T',
    label: '6044.T,  Sanki Service Corporation',
    name: 'Sanki Service Corporation',
    symbol: '6044.T',
  },
  {
    value: '6048.T',
    label: '6048.T,  DesignOne Japan, Inc.',
    name: 'DesignOne Japan, Inc.',
    symbol: '6048.T',
  },
  {
    value: '6054.T',
    label: '6054.T,  Livesense Inc.',
    name: 'Livesense Inc.',
    symbol: '6054.T',
  },
  {
    value: '6059.T',
    label: '6059.T,  UCHIYAMA HOLDINGS Co.,Ltd.',
    name: 'UCHIYAMA HOLDINGS Co.,Ltd.',
    symbol: '6059.T',
  },
  {
    value: '6062.T',
    label: '6062.T,  Charm Care Corporation',
    name: 'Charm Care Corporation',
    symbol: '6062.T',
  },
  {
    value: '6078.T',
    label: '6078.T,  Value HR Co.,Ltd.',
    name: 'Value HR Co.,Ltd.',
    symbol: '6078.T',
  },
  {
    value: '6082.T',
    label: '6082.T,  RIDE ON EXPRESS HOLDINGS Co., Ltd.',
    name: 'RIDE ON EXPRESS HOLDINGS Co., Ltd.',
    symbol: '6082.T',
  },
  {
    value: '6083.T',
    label: '6083.T,  ERI Holdings Co., Ltd.',
    name: 'ERI Holdings Co., Ltd.',
    symbol: '6083.T',
  },
  {
    value: '6087.T',
    label: '6087.T,  ABIST Co.,Ltd.',
    name: 'ABIST Co.,Ltd.',
    symbol: '6087.T',
  },
  {
    value: '6093.T',
    label: '6093.T,  Escrow Agent Japan, Inc.',
    name: 'Escrow Agent Japan, Inc.',
    symbol: '6093.T',
  },
  {
    value: '6121.T',
    label: '6121.T,  Takisawa Machine Tool Co., Ltd.',
    name: 'Takisawa Machine Tool Co., Ltd.',
    symbol: '6121.T',
  },
  {
    value: '6138.T',
    label: '6138.T,  Dijet Industrial Co., Ltd.',
    name: 'Dijet Industrial Co., Ltd.',
    symbol: '6138.T',
  },
  {
    value: '6151.T',
    label: '6151.T,  Nitto Kohki Co., Ltd.',
    name: 'Nitto Kohki Co., Ltd.',
    symbol: '6151.T',
  },
  {
    value: '6165.T',
    label: '6165.T,  Punch Industry Co., Ltd.',
    name: 'Punch Industry Co., Ltd.',
    symbol: '6165.T',
  },
  {
    value: '6167.T',
    label: '6167.T,  Fuji Die Co.,Ltd.',
    name: 'Fuji Die Co.,Ltd.',
    symbol: '6167.T',
  },
  {
    value: '6171.T',
    label: '6171.T,  C.E.Management Integrated Laboratory Co.Ltd',
    name: 'C.E.Management Integrated Laboratory Co.Ltd',
    symbol: '6171.T',
  },
  {
    value: '6175.T',
    label: '6175.T,  Net Marketing Co. Ltd.',
    name: 'Net Marketing Co. Ltd.',
    symbol: '6175.T',
  },
  {
    value: '6185.T',
    label: '6185.T,  SMN Corporation',
    name: 'SMN Corporation',
    symbol: '6185.T',
  },
  {
    value: '6186.T',
    label: '6186.T,  Ichikura Co., Ltd.',
    name: 'Ichikura Co., Ltd.',
    symbol: '6186.T',
  },
  {
    value: '6189.T',
    label: '6189.T,  Global Kids Company Corp.',
    name: 'Global Kids Company Corp.',
    symbol: '6189.T',
  },
  {
    value: '6199.T',
    label: '6199.T,  SERAKU Co., Ltd.',
    name: 'SERAKU Co., Ltd.',
    symbol: '6199.T',
  },
  {
    value: '6205.T',
    label: '6205.T,  Nidec Okk Corporation',
    name: 'Nidec Okk Corporation',
    symbol: '6205.T',
  },
  {
    value: '6208.T',
    label: '6208.T,  Ishikawa Seisakusho, Ltd.',
    name: 'Ishikawa Seisakusho, Ltd.',
    symbol: '6208.T',
  },
  {
    value: '6210.T',
    label: '6210.T,  Toyo Machinery & Metal Co., Ltd.',
    name: 'Toyo Machinery & Metal Co., Ltd.',
    symbol: '6210.T',
  },
  {
    value: '6217.T',
    label: '6217.T,  Tsudakoma Corp.',
    name: 'Tsudakoma Corp.',
    symbol: '6217.T',
  },
  {
    value: '6218.T',
    label: '6218.T,  ENSHU Limited',
    name: 'ENSHU Limited',
    symbol: '6218.T',
  },
  {
    value: '6236.T',
    label: '6236.T,  NC Holdings Co., Ltd.',
    name: 'NC Holdings Co., Ltd.',
    symbol: '6236.T',
  },
  {
    value: '6237.T',
    label: '6237.T,  Iwaki Co., Ltd.',
    name: 'Iwaki Co., Ltd.',
    symbol: '6237.T',
  },
  {
    value: '6262.T',
    label: '6262.T,  Pegasus Sewing Machine Mfg. Co., Ltd.',
    name: 'Pegasus Sewing Machine Mfg. Co., Ltd.',
    symbol: '6262.T',
  },
  {
    value: '6291.T',
    label: '6291.T,  Airtech Japan, Ltd.',
    name: 'Airtech Japan, Ltd.',
    symbol: '6291.T',
  },
  {
    value: '6292.T',
    label: '6292.T,  Kawata Mfg. Co., Ltd.',
    name: 'Kawata Mfg. Co., Ltd.',
    symbol: '6292.T',
  },
  {
    value: '6294.T',
    label: '6294.T,  Okada Aiyon Corporation',
    name: 'Okada Aiyon Corporation',
    symbol: '6294.T',
  },
  {
    value: '6298.T',
    label: '6298.T,  Y.A.C. Holdings Co., Ltd.',
    name: 'Y.A.C. Holdings Co., Ltd.',
    symbol: '6298.T',
  },
  {
    value: '6316.T',
    label: '6316.T,  Maruyama Mfg. Co., Inc.',
    name: 'Maruyama Mfg. Co., Inc.',
    symbol: '6316.T',
  },
  {
    value: '6317.T',
    label: '6317.T,  Kitagawa Corporation',
    name: 'Kitagawa Corporation',
    symbol: '6317.T',
  },
  {
    value: '6325.T',
    label: '6325.T,  Takakita Co., Ltd.',
    name: 'Takakita Co., Ltd.',
    symbol: '6325.T',
  },
  {
    value: '6335.T',
    label: '6335.T,  Tokyo Kikai Seisakusho, Ltd.',
    name: 'Tokyo Kikai Seisakusho, Ltd.',
    symbol: '6335.T',
  },
  {
    value: '6355.T',
    label: '6355.T,  Sumitomo Precision Products Co., Ltd.',
    name: 'Sumitomo Precision Products Co., Ltd.',
    symbol: '6355.T',
  },
  {
    value: '6356.T',
    label: '6356.T,  Nippon Gear Co., Ltd.',
    name: 'Nippon Gear Co., Ltd.',
    symbol: '6356.T',
  },
  {
    value: '6358.T',
    label: '6358.T,  Sakai Heavy Industries, Ltd.',
    name: 'Sakai Heavy Industries, Ltd.',
    symbol: '6358.T',
  },
  {
    value: '6362.T',
    label: '6362.T,  Ishii Iron Works Co., Ltd.',
    name: 'Ishii Iron Works Co., Ltd.',
    symbol: '6362.T',
  },
  {
    value: '6373.T',
    label: '6373.T,  Daido Kogyo Co., Ltd.',
    name: 'Daido Kogyo Co., Ltd.',
    symbol: '6373.T',
  },
  {
    value: '6387.T',
    label: '6387.T,  SAMCO Inc.',
    name: 'SAMCO Inc.',
    symbol: '6387.T',
  },
  {
    value: '6393.T',
    label: '6393.T,  Yuken Kogyo Co., Ltd.',
    name: 'Yuken Kogyo Co., Ltd.',
    symbol: '6393.T',
  },
  {
    value: '6418.T',
    label: '6418.T,  Japan Cash Machine Co., Ltd.',
    name: 'Japan Cash Machine Co., Ltd.',
    symbol: '6418.T',
  },
  {
    value: '6428.T',
    label: '6428.T,  OIZUMI Corporation',
    name: 'OIZUMI Corporation',
    symbol: '6428.T',
  },
  {
    value: '6430.T',
    label: '6430.T,  Daikoku Denki Co., Ltd.',
    name: 'Daikoku Denki Co., Ltd.',
    symbol: '6430.T',
  },
  {
    value: '6444.T',
    label: '6444.T,  Sanden Corporation',
    name: 'Sanden Corporation',
    symbol: '6444.T',
  },
  {
    value: '6461.T',
    label: '6461.T,  Nippon Piston Ring Co., Ltd.',
    name: 'Nippon Piston Ring Co., Ltd.',
    symbol: '6461.T',
  },
  {
    value: '6485.T',
    label: '6485.T,  Maezawa Kyuso Industries Co.,Ltd.',
    name: 'Maezawa Kyuso Industries Co.,Ltd.',
    symbol: '6485.T',
  },
  {
    value: '6489.T',
    label: '6489.T,  Maezawa Industries, Inc.',
    name: 'Maezawa Industries, Inc.',
    symbol: '6489.T',
  },
  {
    value: '6513.T',
    label: '6513.T,  Origin Company, Limited',
    name: 'Origin Company, Limited',
    symbol: '6513.T',
  },
  {
    value: '6523.T',
    label: '6523.T,  PHC Holdings Corporation',
    name: 'PHC Holdings Corporation',
    symbol: '6523.T',
  },
  {
    value: '6533.T',
    label: '6533.T,  Orchestra Holdings Inc.',
    name: 'Orchestra Holdings Inc.',
    symbol: '6533.T',
  },
  {
    value: '6535.T',
    label: '6535.T,  i-mobile Co.,Ltd.',
    name: 'i-mobile Co.,Ltd.',
    symbol: '6535.T',
  },
  {
    value: '6538.T',
    label: '6538.T,  CareerIndex Inc.',
    name: 'CareerIndex Inc.',
    symbol: '6538.T',
  },
  {
    value: '6540.T',
    label: '6540.T,  Semba Corporation',
    name: 'Semba Corporation',
    symbol: '6540.T',
  },
  {
    value: '6546.T',
    label: '6546.T,  Fulltech Co.Ltd.',
    name: 'Fulltech Co.Ltd.',
    symbol: '6546.T',
  },
  {
    value: '6547.T',
    label: '6547.T,  Greens Co.,Ltd.',
    name: 'Greens Co.,Ltd.',
    symbol: '6547.T',
  },
  {
    value: '6551.T',
    label: '6551.T,  Tsunagu Group Holdings, Inc.',
    name: 'Tsunagu Group Holdings, Inc.',
    symbol: '6551.T',
  },
  {
    value: '6552.T',
    label: '6552.T,  GameWith Inc.',
    name: 'GameWith Inc.',
    symbol: '6552.T',
  },
  {
    value: '6555.T',
    label: '6555.T,  MS&Consulting Co., Ltd.',
    name: 'MS&Consulting Co., Ltd.',
    symbol: '6555.T',
  },
  {
    value: '6556.T',
    label: '6556.T,  Welbe, Inc.',
    name: 'Welbe, Inc.',
    symbol: '6556.T',
  },
  {
    value: '6560.T',
    label: '6560.T,  LTS, Inc.',
    name: 'LTS, Inc.',
    symbol: '6560.T',
  },
  {
    value: '6615.T',
    label: '6615.T,  UMC Electronics Co., Ltd.',
    name: 'UMC Electronics Co., Ltd.',
    symbol: '6615.T',
  },
  {
    value: '6620.T',
    label: '6620.T,  Miyakoshi Holdings, Inc.',
    name: 'Miyakoshi Holdings, Inc.',
    symbol: '6620.T',
  },
  {
    value: '6638.T',
    label: '6638.T,  Mimaki Engineering Co., Ltd.',
    name: 'Mimaki Engineering Co., Ltd.',
    symbol: '6638.T',
  },
  {
    value: '6653.T',
    label: '6653.T,  Seiko Electric Co., Ltd.',
    name: 'Seiko Electric Co., Ltd.',
    symbol: '6653.T',
  },
  {
    value: '6654.T',
    label: '6654.T,  Fuji Electric Industry Co., Ltd.',
    name: 'Fuji Electric Industry Co., Ltd.',
    symbol: '6654.T',
  },
  {
    value: '6675.T',
    label: '6675.T,  SAXA Holdings, Inc.',
    name: 'SAXA Holdings, Inc.',
    symbol: '6675.T',
  },
  {
    value: '6678.T',
    label: '6678.T,  Techno Medica Co., Ltd.',
    name: 'Techno Medica Co., Ltd.',
    symbol: '6678.T',
  },
  {
    value: '6704.T',
    label: '6704.T,  Iwatsu Electric Co., Ltd.',
    name: 'Iwatsu Electric Co., Ltd.',
    symbol: '6704.T',
  },
  {
    value: '6715.T',
    label: '6715.T,  Nakayo, Inc.',
    name: 'Nakayo, Inc.',
    symbol: '6715.T',
  },
  {
    value: '6718.T',
    label: '6718.T,  Aiphone Co.,Ltd.',
    name: 'Aiphone Co.,Ltd.',
    symbol: '6718.T',
  },
  {
    value: '6730.T',
    label: '6730.T,  AXELL Corporation',
    name: 'AXELL Corporation',
    symbol: '6730.T',
  },
  {
    value: '6748.T',
    label: '6748.T,  Seiwa Electric Mfg. Co., Ltd.',
    name: 'Seiwa Electric Mfg. Co., Ltd.',
    symbol: '6748.T',
  },
  {
    value: '6763.T',
    label: '6763.T,  Teikoku Tsushin Kogyo Co., Ltd.',
    name: 'Teikoku Tsushin Kogyo Co., Ltd.',
    symbol: '6763.T',
  },
  {
    value: '6771.T',
    label: '6771.T,  Ikegami Tsushinki Co., Ltd.',
    name: 'Ikegami Tsushinki Co., Ltd.',
    symbol: '6771.T',
  },
  {
    value: '6803.T',
    label: '6803.T,  Teac Corporation',
    name: 'Teac Corporation',
    symbol: '6803.T',
  },
  {
    value: '6815.T',
    label: '6815.T,  Uniden Holdings Corporation',
    name: 'Uniden Holdings Corporation',
    symbol: '6815.T',
  },
  {
    value: '6820.T',
    label: '6820.T,  Icom Incorporated',
    name: 'Icom Incorporated',
    symbol: '6820.T',
  },
  {
    value: '6823.T',
    label: '6823.T,  Rion Co., Ltd.',
    name: 'Rion Co., Ltd.',
    symbol: '6823.T',
  },
  {
    value: '6826.T',
    label: '6826.T,  Honda Tsushin Kogyo Co., Ltd.',
    name: 'Honda Tsushin Kogyo Co., Ltd.',
    symbol: '6826.T',
  },
  {
    value: '6848.T',
    label: '6848.T,  Dkk-Toa Corporation',
    name: 'Dkk-Toa Corporation',
    symbol: '6848.T',
  },
  {
    value: '6850.T',
    label: '6850.T,  Chino Corporation',
    name: 'Chino Corporation',
    symbol: '6850.T',
  },
  {
    value: '6853.T',
    label: '6853.T,  Kyowa Electronic Instruments Co., Ltd.',
    name: 'Kyowa Electronic Instruments Co., Ltd.',
    symbol: '6853.T',
  },
  {
    value: '6855.T',
    label: '6855.T,  Japan Electronic Materials Corporation',
    name: 'Japan Electronic Materials Corporation',
    symbol: '6855.T',
  },
  {
    value: '6858.T',
    label: '6858.T,  Ono Sokki Co., Ltd.',
    name: 'Ono Sokki Co., Ltd.',
    symbol: '6858.T',
  },
  {
    value: '6901.T',
    label: '6901.T,  Sawafuji Electric Co., Ltd.',
    name: 'Sawafuji Electric Co., Ltd.',
    symbol: '6901.T',
  },
  {
    value: '6904.T',
    label: '6904.T,  Harada Industry Co., Ltd.',
    name: 'Harada Industry Co., Ltd.',
    symbol: '6904.T',
  },
  {
    value: '6924.T',
    label: '6924.T,  Iwasaki Electric Co., Ltd.',
    name: 'Iwasaki Electric Co., Ltd.',
    symbol: '6924.T',
  },
  {
    value: '6926.T',
    label: '6926.T,  Okaya Electric Industries Co., Ltd.',
    name: 'Okaya Electric Industries Co., Ltd.',
    symbol: '6926.T',
  },
  {
    value: '6927.T',
    label: '6927.T,  Helios Techno Holding Co., Ltd.',
    name: 'Helios Techno Holding Co., Ltd.',
    symbol: '6927.T',
  },
  {
    value: '6932.T',
    label: '6932.T,  ENDO Lighting Corporation',
    name: 'ENDO Lighting Corporation',
    symbol: '6932.T',
  },
  {
    value: '6938.T',
    label: '6938.T,  Soshin Electric Co.,Ltd.',
    name: 'Soshin Electric Co.,Ltd.',
    symbol: '6938.T',
  },
  {
    value: '6973.T',
    label: '6973.T,  Kyoei Sangyo Co.,Ltd.',
    name: 'Kyoei Sangyo Co.,Ltd.',
    symbol: '6973.T',
  },
  {
    value: '6989.T',
    label: '6989.T,  Hokuriku Electric Industry Co.,Ltd.',
    name: 'Hokuriku Electric Industry Co.,Ltd.',
    symbol: '6989.T',
  },
  {
    value: '7022.T',
    label: '7022.T,  Sanoyas Holdings Corporation',
    name: 'Sanoyas Holdings Corporation',
    symbol: '7022.T',
  },
  {
    value: '7030.T',
    label: '7030.T,  SPRIX, Ltd.',
    name: 'SPRIX, Ltd.',
    symbol: '7030.T',
  },
  {
    value: '7033.T',
    label: '7033.T,  Management Solutions co.,Ltd.',
    name: 'Management Solutions co.,Ltd.',
    symbol: '7033.T',
  },
  {
    value: '7034.T',
    label: '7034.T,  Prored Partners CO.,LTD.',
    name: 'Prored Partners CO.,LTD.',
    symbol: '7034.T',
  },
  {
    value: '7035.T',
    label: '7035.T,  and factory,inc.',
    name: 'and factory,inc.',
    symbol: '7035.T',
  },
  {
    value: '7037.T',
    label: '7037.T,  teno. Holdings Company Limited',
    name: 'teno. Holdings Company Limited',
    symbol: '7037.T',
  },
  {
    value: '7038.T',
    label: '7038.T,  Frontier Management Inc.',
    name: 'Frontier Management Inc.',
    symbol: '7038.T',
  },
  {
    value: '7044.T',
    label: '7044.T,  Piala Inc.',
    name: 'Piala Inc.',
    symbol: '7044.T',
  },
  {
    value: '7059.T',
    label: '7059.T,  COPRO-HOLDINGS. Co., Ltd.',
    name: 'COPRO-HOLDINGS. Co., Ltd.',
    symbol: '7059.T',
  },
  {
    value: '7060.T',
    label: '7060.T,  geechs inc.',
    name: 'geechs inc.',
    symbol: '7060.T',
  },
  {
    value: '7102.T',
    label: '7102.T,  Nippon Sharyo, Ltd.',
    name: 'Nippon Sharyo, Ltd.',
    symbol: '7102.T',
  },
  {
    value: '7122.T',
    label: '7122.T,  The Kinki Sharyo Co., Ltd.',
    name: 'The Kinki Sharyo Co., Ltd.',
    symbol: '7122.T',
  },
  {
    value: '7127.T',
    label: '7127.T,  Ikka Holdings Co.,Ltd.',
    name: 'Ikka Holdings Co.,Ltd.',
    symbol: '7127.T',
  },
  {
    value: '7128.T',
    label: '7128.T,  MARUKA FURUSATO Corporation',
    name: 'MARUKA FURUSATO Corporation',
    symbol: '7128.T',
  },
  {
    value: '7130.T',
    label: '7130.T,  Yamae Group Holdings Co.,Ltd.',
    name: 'Yamae Group Holdings Co.,Ltd.',
    symbol: '7130.T',
  },
  {
    value: '7150.T',
    label: '7150.T,  The Shimane Bank,Ltd.',
    name: 'The Shimane Bank,Ltd.',
    symbol: '7150.T',
  },
  {
    value: '7183.T',
    label: '7183.T,  Anshin Guarantor Service Co., Ltd.',
    name: 'Anshin Guarantor Service Co., Ltd.',
    symbol: '7183.T',
  },
  {
    value: '7187.T',
    label: '7187.T,  J-Lease Co.,Ltd.',
    name: 'J-Lease Co.,Ltd.',
    symbol: '7187.T',
  },
  {
    value: '7191.T',
    label: '7191.T,  Entrust Inc.',
    name: 'Entrust Inc.',
    symbol: '7191.T',
  },
  {
    value: '7192.T',
    label: '7192.T,  Mortgage Service Japan Limited',
    name: 'Mortgage Service Japan Limited',
    symbol: '7192.T',
  },
  {
    value: '7196.T',
    label: '7196.T,  Casa Inc.',
    name: 'Casa Inc.',
    symbol: '7196.T',
  },
  {
    value: '7212.T',
    label: '7212.T,  F-Tech Inc.',
    name: 'F-Tech Inc.',
    symbol: '7212.T',
  },
  {
    value: '7213.T',
    label: '7213.T,  Lecip Holdings Corporation',
    name: 'Lecip Holdings Corporation',
    symbol: '7213.T',
  },
  {
    value: '7214.T',
    label: '7214.T,  GMB Corporation',
    name: 'GMB Corporation',
    symbol: '7214.T',
  },
  {
    value: '7215.T',
    label: '7215.T,  Faltec Co., Ltd.',
    name: 'Faltec Co., Ltd.',
    symbol: '7215.T',
  },
  {
    value: '7247.T',
    label: '7247.T,  Mikuni Corporation',
    name: 'Mikuni Corporation',
    symbol: '7247.T',
  },
  {
    value: '7271.T',
    label: '7271.T,  Yasunaga Corporation',
    name: 'Yasunaga Corporation',
    symbol: '7271.T',
  },
  {
    value: '7277.T',
    label: '7277.T,  TBK Co., Ltd.',
    name: 'TBK Co., Ltd.',
    symbol: '7277.T',
  },
  {
    value: '7284.T',
    label: '7284.T,  Meiwa Industry Co., Ltd.',
    name: 'Meiwa Industry Co., Ltd.',
    symbol: '7284.T',
  },
  {
    value: '7291.T',
    label: '7291.T,  Nihon Plast Co., Ltd.',
    name: 'Nihon Plast Co., Ltd.',
    symbol: '7291.T',
  },
  {
    value: '7305.T',
    label: '7305.T,  Araya Industrial Co., Ltd.',
    name: 'Araya Industrial Co., Ltd.',
    symbol: '7305.T',
  },
  {
    value: '7347.T',
    label: '7347.T,  Mercuria Holdings Co., Ltd.',
    name: 'Mercuria Holdings Co., Ltd.',
    symbol: '7347.T',
  },
  {
    value: '7354.T',
    label: '7354.T,  Direct Marketing MiX Inc.',
    name: 'Direct Marketing MiX Inc.',
    symbol: '7354.T',
  },
  {
    value: '7358.T',
    label: '7358.T,  Poppins Corporation',
    name: 'Poppins Corporation',
    symbol: '7358.T',
  },
  {
    value: '7383.T',
    label: '7383.T,  Net Protections Holdings, Inc.',
    name: 'Net Protections Holdings, Inc.',
    symbol: '7383.T',
  },
  {
    value: '7408.T',
    label: '7408.T,  JAMCO Corporation',
    name: 'JAMCO Corporation',
    symbol: '7408.T',
  },
  {
    value: '7416.T',
    label: '7416.T,  Haruyama Holdings Inc.',
    name: 'Haruyama Holdings Inc.',
    symbol: '7416.T',
  },
  {
    value: '7417.T',
    label: '7417.T,  NANYO Corporation',
    name: 'NANYO Corporation',
    symbol: '7417.T',
  },
  {
    value: '7420.T',
    label: '7420.T,  Satori Electric Co., Ltd.',
    name: 'Satori Electric Co., Ltd.',
    symbol: '7420.T',
  },
  {
    value: '7421.T',
    label: '7421.T,  Kappa Create Co., Ltd.',
    name: 'Kappa Create Co., Ltd.',
    symbol: '7421.T',
  },
  {
    value: '7427.T',
    label: '7427.T,  Echo Trading Co., Ltd.',
    name: 'Echo Trading Co., Ltd.',
    symbol: '7427.T',
  },
  {
    value: '7442.T',
    label: '7442.T,  Nakayamafuku Co.,Ltd.',
    name: 'Nakayamafuku Co.,Ltd.',
    symbol: '7442.T',
  },
  {
    value: '7445.T',
    label: '7445.T,  RIGHT ON Co., Ltd.',
    name: 'RIGHT ON Co., Ltd.',
    symbol: '7445.T',
  },
  {
    value: '7455.T',
    label: '7455.T,  Paris Miki Holdings Inc.',
    name: 'Paris Miki Holdings Inc.',
    symbol: '7455.T',
  },
  {
    value: '7466.T',
    label: '7466.T,  SPK Corporation',
    name: 'SPK Corporation',
    symbol: '7466.T',
  },
  {
    value: '7480.T',
    label: '7480.T,  Suzuden Corporation',
    name: 'Suzuden Corporation',
    symbol: '7480.T',
  },
  {
    value: '7481.T',
    label: '7481.T,  Oie Sangyo Co., Ltd.',
    name: 'Oie Sangyo Co., Ltd.',
    symbol: '7481.T',
  },
  {
    value: '7482.T',
    label: '7482.T,  Shimojima Co., Ltd.',
    name: 'Shimojima Co., Ltd.',
    symbol: '7482.T',
  },
  {
    value: '7487.T',
    label: '7487.T,  Ozu Corporation',
    name: 'Ozu Corporation',
    symbol: '7487.T',
  },
  {
    value: '7494.T',
    label: '7494.T,  Konaka Co.,Ltd.',
    name: 'Konaka Co.,Ltd.',
    symbol: '7494.T',
  },
  {
    value: '7506.T',
    label: '7506.T,  HOUSE OF ROSE Co.,Ltd.',
    name: 'HOUSE OF ROSE Co.,Ltd.',
    symbol: '7506.T',
  },
  {
    value: '7510.T',
    label: '7510.T,  Takebishi Corporation',
    name: 'Takebishi Corporation',
    symbol: '7510.T',
  },
  {
    value: '7514.T',
    label: '7514.T,  HIMARAYA Co.,Ltd.',
    name: 'HIMARAYA Co.,Ltd.',
    symbol: '7514.T',
  },
  {
    value: '7524.T',
    label: '7524.T,  Marche Corporation',
    name: 'Marche Corporation',
    symbol: '7524.T',
  },
  {
    value: '7525.T',
    label: '7525.T,  Rix Corporation',
    name: 'Rix Corporation',
    symbol: '7525.T',
  },
  {
    value: '7527.T',
    label: '7527.T,  SystemSoft Corporation',
    name: 'SystemSoft Corporation',
    symbol: '7527.T',
  },
  {
    value: '7561.T',
    label: '7561.T,  Hurxley Corporation',
    name: 'Hurxley Corporation',
    symbol: '7561.T',
  },
  {
    value: '7570.T',
    label: '7570.T,  Hashimoto Sogyo Holdings Co.,Ltd.',
    name: 'Hashimoto Sogyo Holdings Co.,Ltd.',
    symbol: '7570.T',
  },
  {
    value: '7596.T',
    label: '7596.T,  Uoriki Co., Ltd.',
    name: 'Uoriki Co., Ltd.',
    symbol: '7596.T',
  },
  {
    value: '7601.T',
    label: '7601.T,  Poplar Co., Ltd.',
    name: 'Poplar Co., Ltd.',
    symbol: '7601.T',
  },
  {
    value: '7608.T',
    label: '7608.T,  SK Japan Co.,Ltd.',
    name: 'SK Japan Co.,Ltd.',
    symbol: '7608.T',
  },
  {
    value: '7609.T',
    label: '7609.T,  Daitron Co., Ltd.',
    name: 'Daitron Co., Ltd.',
    symbol: '7609.T',
  },
  {
    value: '7615.T',
    label: '7615.T,  YU-WA Creation Holdings Co.,Ltd.',
    name: 'YU-WA Creation Holdings Co.,Ltd.',
    symbol: '7615.T',
  },
  {
    value: '7619.T',
    label: '7619.T,  Tanaka Co.,Ltd.',
    name: 'Tanaka Co.,Ltd.',
    symbol: '7619.T',
  },
  {
    value: '7628.T',
    label: '7628.T,  Ohashi Technica Inc.',
    name: 'Ohashi Technica Inc.',
    symbol: '7628.T',
  },
  {
    value: '7637.T',
    label: '7637.T,  Hakudo Co., Ltd.',
    name: 'Hakudo Co., Ltd.',
    symbol: '7637.T',
  },
  {
    value: '7640.T',
    label: '7640.T,  TOP CULTURE Co.,Ltd.',
    name: 'TOP CULTURE Co.,Ltd.',
    symbol: '7640.T',
  },
  {
    value: '7646.T',
    label: '7646.T,  PLANT Co.,Ltd.',
    name: 'PLANT Co.,Ltd.',
    symbol: '7646.T',
  },
  {
    value: '7673.T',
    label: '7673.T,  Daiko Tsusan Co.,Ltd.',
    name: 'Daiko Tsusan Co.,Ltd.',
    symbol: '7673.T',
  },
  {
    value: '7709.T',
    label: '7709.T,  KUBOTEK Corporation',
    name: 'KUBOTEK Corporation',
    symbol: '7709.T',
  },
  {
    value: '7721.T',
    label: '7721.T,  TOKYO KEIKI INC.',
    name: 'TOKYO KEIKI INC.',
    symbol: '7721.T',
  },
  {
    value: '7723.T',
    label: '7723.T,  Aichi Tokei Denki Co., Ltd.',
    name: 'Aichi Tokei Denki Co., Ltd.',
    symbol: '7723.T',
  },
  {
    value: '7727.T',
    label: '7727.T,  OVAL Corporation',
    name: 'OVAL Corporation',
    symbol: '7727.T',
  },
  {
    value: '7769.T',
    label: '7769.T,  Rhythm Co.,Ltd.',
    name: 'Rhythm Co.,Ltd.',
    symbol: '7769.T',
  },
  {
    value: '7775.T',
    label: '7775.T,  Daiken Medical Co., Ltd.',
    name: 'Daiken Medical Co., Ltd.',
    symbol: '7775.T',
  },
  {
    value: '7782.T',
    label: '7782.T,  Sincere Co., LTD.',
    name: 'Sincere Co., LTD.',
    symbol: '7782.T',
  },
  {
    value: '7811.T',
    label: '7811.T,  Nakamoto Packs Co.,Ltd.',
    name: 'Nakamoto Packs Co.,Ltd.',
    symbol: '7811.T',
  },
  {
    value: '7819.T',
    label: '7819.T,  SHOBIDO Corporation',
    name: 'SHOBIDO Corporation',
    symbol: '7819.T',
  },
  {
    value: '7822.T',
    label: '7822.T,  Eidai Co.,Ltd.',
    name: 'Eidai Co.,Ltd.',
    symbol: '7822.T',
  },
  {
    value: '7823.T',
    label: '7823.T,  Artnature Inc.',
    name: 'Artnature Inc.',
    symbol: '7823.T',
  },
  {
    value: '7833.T',
    label: '7833.T,  IFIS Japan Ltd.',
    name: 'IFIS Japan Ltd.',
    symbol: '7833.T',
  },
  {
    value: '7868.T',
    label: '7868.T,  KOSAIDO Holdings Co., Ltd.',
    name: 'KOSAIDO Holdings Co., Ltd.',
    symbol: '7868.T',
  },
  {
    value: '7872.T',
    label: '7872.T,  Estelle Holdings Co.,Ltd.',
    name: 'Estelle Holdings Co.,Ltd.',
    symbol: '7872.T',
  },
  {
    value: '7885.T',
    label: '7885.T,  Takano Co.,Ltd.',
    name: 'Takano Co.,Ltd.',
    symbol: '7885.T',
  },
  {
    value: '7893.T',
    label: '7893.T,  PRONEXUS Inc.',
    name: 'PRONEXUS Inc.',
    symbol: '7893.T',
  },
  {
    value: '7897.T',
    label: '7897.T,  Hokushin Co., Ltd.',
    name: 'Hokushin Co., Ltd.',
    symbol: '7897.T',
  },
  {
    value: '7898.T',
    label: '7898.T,  Wood One Co.,Ltd.',
    name: 'Wood One Co.,Ltd.',
    symbol: '7898.T',
  },
  {
    value: '7908.T',
    label: '7908.T,  Kimoto Co., Ltd.',
    name: 'Kimoto Co., Ltd.',
    symbol: '7908.T',
  },
  {
    value: '7914.T',
    label: '7914.T,  Kyodo Printing Co., Ltd.',
    name: 'Kyodo Printing Co., Ltd.',
    symbol: '7914.T',
  },
  {
    value: '7916.T',
    label: '7916.T,  Mitsumura Printing Co., Ltd.',
    name: 'Mitsumura Printing Co., Ltd.',
    symbol: '7916.T',
  },
  {
    value: '7918.T',
    label: '7918.T,  VIA Holdings,Inc.',
    name: 'VIA Holdings,Inc.',
    symbol: '7918.T',
  },
  {
    value: '7925.T',
    label: '7925.T,  Maezawa Kasei Industries Co., Ltd.',
    name: 'Maezawa Kasei Industries Co., Ltd.',
    symbol: '7925.T',
  },
  {
    value: '7931.T',
    label: '7931.T,  Mirai Industry Co.,Ltd.',
    name: 'Mirai Industry Co.,Ltd.',
    symbol: '7931.T',
  },
  {
    value: '7937.T',
    label: '7937.T,  Tsutsumi Jewelry Co.,Ltd.',
    name: 'Tsutsumi Jewelry Co.,Ltd.',
    symbol: '7937.T',
  },
  {
    value: '7940.T',
    label: '7940.T,  Wavelock Holdings Co., Ltd.',
    name: 'Wavelock Holdings Co., Ltd.',
    symbol: '7940.T',
  },
  {
    value: '7944.T',
    label: '7944.T,  Roland Corporation',
    name: 'Roland Corporation',
    symbol: '7944.T',
  },
  {
    value: '7949.T',
    label: '7949.T,  Komatsu Wall Industry Co., Ltd.',
    name: 'Komatsu Wall Industry Co., Ltd.',
    symbol: '7949.T',
  },
  {
    value: '7961.T',
    label: '7961.T,  Kanematsu Sustech Corporation',
    name: 'Kanematsu Sustech Corporation',
    symbol: '7961.T',
  },
  {
    value: '7971.T',
    label: '7971.T,  TOLI Corporation',
    name: 'TOLI Corporation',
    symbol: '7971.T',
  },
  {
    value: '7979.T',
    label: '7979.T,  Shofu Inc.',
    name: 'Shofu Inc.',
    symbol: '7979.T',
  },
  {
    value: '7989.T',
    label: '7989.T,  Tachikawa Corporation',
    name: 'Tachikawa Corporation',
    symbol: '7989.T',
  },
  {
    value: '7999.T',
    label: '7999.T,  Mutoh Holdings Co., Ltd.',
    name: 'Mutoh Holdings Co., Ltd.',
    symbol: '7999.T',
  },
  {
    value: '8007.T',
    label: '8007.T,  Takashima & Co., Ltd.',
    name: 'Takashima & Co., Ltd.',
    symbol: '8007.T',
  },
  {
    value: '8011.T',
    label: '8011.T,  Sanyo Shokai Ltd.',
    name: 'Sanyo Shokai Ltd.',
    symbol: '8011.T',
  },
  {
    value: '8013.T',
    label: '8013.T,  Naigai Co.,Ltd.',
    name: 'Naigai Co.,Ltd.',
    symbol: '8013.T',
  },
  {
    value: '8025.T',
    label: '8025.T,  Tsukamoto Corporation Co., Ltd.',
    name: 'Tsukamoto Corporation Co., Ltd.',
    symbol: '8025.T',
  },
  {
    value: '8029.T',
    label: '8029.T,  Look Holdings Incorporated',
    name: 'Look Holdings Incorporated',
    symbol: '8029.T',
  },
  {
    value: '8038.T',
    label: '8038.T,  Tohto Suisan Co., Ltd.',
    name: 'Tohto Suisan Co., Ltd.',
    symbol: '8038.T',
  },
  {
    value: '8041.T',
    label: '8041.T,  OUG Holdings Inc.',
    name: 'OUG Holdings Inc.',
    symbol: '8041.T',
  },
  {
    value: '8052.T',
    label: '8052.T,  Tsubakimoto Kogyo Co., Ltd.',
    name: 'Tsubakimoto Kogyo Co., Ltd.',
    symbol: '8052.T',
  },
  {
    value: '8061.T',
    label: '8061.T,  Seika Corporation',
    name: 'Seika Corporation',
    symbol: '8061.T',
  },
  {
    value: '8065.T',
    label: '8065.T,  Sato Shoji Corporation',
    name: 'Sato Shoji Corporation',
    symbol: '8065.T',
  },
  {
    value: '8070.T',
    label: '8070.T,  Tokyo Sangyo Co., Ltd.',
    name: 'Tokyo Sangyo Co., Ltd.',
    symbol: '8070.T',
  },
  {
    value: '8075.T',
    label: '8075.T,  Shinsho Corporation',
    name: 'Shinsho Corporation',
    symbol: '8075.T',
  },
  {
    value: '8077.T',
    label: '8077.T,  TORQ Inc.',
    name: 'TORQ Inc.',
    symbol: '8077.T',
  },
  {
    value: '8081.T',
    label: '8081.T,  Kanaden Corporation',
    name: 'Kanaden Corporation',
    symbol: '8081.T',
  },
  {
    value: '8089.T',
    label: '8089.T,  Nice Corporation',
    name: 'Nice Corporation',
    symbol: '8089.T',
  },
  {
    value: '8091.T',
    label: '8091.T,  Nichimo Co., Ltd.',
    name: 'Nichimo Co., Ltd.',
    symbol: '8091.T',
  },
  {
    value: '8093.T',
    label: '8093.T,  Kyokuto Boeki Kaisha, Ltd.',
    name: 'Kyokuto Boeki Kaisha, Ltd.',
    symbol: '8093.T',
  },
  {
    value: '8095.T',
    label: '8095.T,  Astena Holdings Co., Ltd.',
    name: 'Astena Holdings Co., Ltd.',
    symbol: '8095.T',
  },
  {
    value: '8104.T',
    label: '8104.T,  KUWAZAWA Holdings Corporation',
    name: 'KUWAZAWA Holdings Corporation',
    symbol: '8104.T',
  },
  {
    value: '8107.T',
    label: '8107.T,  Kimuratan Corporation',
    name: 'Kimuratan Corporation',
    symbol: '8107.T',
  },
  {
    value: '8118.T',
    label: '8118.T,  KING Co., Ltd.',
    name: 'KING Co., Ltd.',
    symbol: '8118.T',
  },
  {
    value: 'UTAA',
    label: 'UTAA,  UTA Acquisition Corporation',
    name: 'UTA Acquisition Corporation',
    symbol: 'UTAA',
  },
  {
    value: '8127.T',
    label: '8127.T,  Yamato International Inc.',
    name: 'Yamato International Inc.',
    symbol: '8127.T',
  },
  {
    value: '8142.T',
    label: '8142.T,  Toho Co., Ltd.',
    name: 'Toho Co., Ltd.',
    symbol: '8142.T',
  },
  {
    value: '8158.T',
    label: '8158.T,  Soda Nikka Co., Ltd.',
    name: 'Soda Nikka Co., Ltd.',
    symbol: '8158.T',
  },
  {
    value: '8163.T',
    label: '8163.T,  SRS Holdings Co.,Ltd.',
    name: 'SRS Holdings Co.,Ltd.',
    symbol: '8163.T',
  },
  {
    value: '8165.T',
    label: '8165.T,  Senshukai Co., Ltd.',
    name: 'Senshukai Co., Ltd.',
    symbol: '8165.T',
  },
  {
    value: '8166.T',
    label: '8166.T,  Taka-Q Co., Ltd.',
    name: 'Taka-Q Co., Ltd.',
    symbol: '8166.T',
  },
  {
    value: '8181.T',
    label: '8181.T,  Totenko Co., Ltd.',
    name: 'Totenko Co., Ltd.',
    symbol: '8181.T',
  },
  {
    value: '8207.T',
    label: '8207.T,  Ten Allied Co.,Ltd.',
    name: 'Ten Allied Co.,Ltd.',
    symbol: '8207.T',
  },
  {
    value: '8230.T',
    label: '8230.T,  Hasegawa Co., Ltd.',
    name: 'Hasegawa Co., Ltd.',
    symbol: '8230.T',
  },
  {
    value: '8244.T',
    label: '8244.T,  Kintetsu Department Store Co., Ltd.',
    name: 'Kintetsu Department Store Co., Ltd.',
    symbol: '8244.T',
  },
  {
    value: '8260.T',
    label: '8260.T,  Izutsuya Co., Ltd.',
    name: 'Izutsuya Co., Ltd.',
    symbol: '8260.T',
  },
  {
    value: '8275.T',
    label: '8275.T,  Forval Corporation',
    name: 'Forval Corporation',
    symbol: '8275.T',
  },
  {
    value: '8349.T',
    label: '8349.T,  The Tohoku Bank, Ltd.',
    name: 'The Tohoku Bank, Ltd.',
    symbol: '8349.T',
  },
  {
    value: '8364.T',
    label: '8364.T,  The Shimizu Bank, Ltd.',
    name: 'The Shimizu Bank, Ltd.',
    symbol: '8364.T',
  },
  {
    value: '8365.T',
    label: '8365.T,  The Bank of Toyama, Ltd.',
    name: 'The Bank of Toyama, Ltd.',
    symbol: '8365.T',
  },
  {
    value: '8383.T',
    label: '8383.T,  The Tottori Bank, Ltd.',
    name: 'The Tottori Bank, Ltd.',
    symbol: '8383.T',
  },
  {
    value: '8416.T',
    label: '8416.T,  The Bank Of Kochi, Ltd.',
    name: 'The Bank Of Kochi, Ltd.',
    symbol: '8416.T',
  },
  {
    value: '8518.T',
    label: '8518.T,  Japan Asia Investment Co., Ltd.',
    name: 'Japan Asia Investment Co., Ltd.',
    symbol: '8518.T',
  },
  {
    value: '8521.T',
    label: '8521.T,  The Naganobank, Ltd.',
    name: 'The Naganobank, Ltd.',
    symbol: '8521.T',
  },
  {
    value: '8537.T',
    label: '8537.T,  The Taiko Bank,Ltd.',
    name: 'The Taiko Bank,Ltd.',
    symbol: '8537.T',
  },
  {
    value: '8542.T',
    label: '8542.T,  Tomato Bank, Ltd.',
    name: 'Tomato Bank, Ltd.',
    symbol: '8542.T',
  },
  {
    value: '8551.T',
    label: '8551.T,  The Kita-Nippon Bank, Ltd.',
    name: 'The Kita-Nippon Bank, Ltd.',
    symbol: '8551.T',
  },
  {
    value: '8562.T',
    label: '8562.T,  The Fukushima Bank, Ltd.',
    name: 'The Fukushima Bank, Ltd.',
    symbol: '8562.T',
  },
  {
    value: '8563.T',
    label: '8563.T,  The Daito Bank, Ltd.',
    name: 'The Daito Bank, Ltd.',
    symbol: '8563.T',
  },
  {
    value: '8596.T',
    label: '8596.T,  Kyushu Leasing Service Co., Ltd.',
    name: 'Kyushu Leasing Service Co., Ltd.',
    symbol: '8596.T',
  },
  {
    value: '8614.T',
    label: '8614.T,  Toyo Securities Co., Ltd.',
    name: 'Toyo Securities Co., Ltd.',
    symbol: '8614.T',
  },
  {
    value: '8617.T',
    label: '8617.T,  The Kosei Securities Co., Ltd.',
    name: 'The Kosei Securities Co., Ltd.',
    symbol: '8617.T',
  },
  {
    value: '8732.T',
    label: '8732.T,  Money Partners Group Co.,Ltd.',
    name: 'Money Partners Group Co.,Ltd.',
    symbol: '8732.T',
  },
  {
    value: '8742.T',
    label: '8742.T,  Kobayashi Yoko Co., Ltd.',
    name: 'Kobayashi Yoko Co., Ltd.',
    symbol: '8742.T',
  },
  {
    value: '8769.T',
    label: '8769.T,  Advantage Risk Management Co., Ltd.',
    name: 'Advantage Risk Management Co., Ltd.',
    symbol: '8769.T',
  },
  {
    value: '8772.T',
    label: '8772.T,  Asax Co., Ltd.',
    name: 'Asax Co., Ltd.',
    symbol: '8772.T',
  },
  {
    value: '8798.T',
    label: '8798.T,  Advance Create Co., Ltd.',
    name: 'Advance Create Co., Ltd.',
    symbol: '8798.T',
  },
  {
    value: '8835.T',
    label: '8835.T,  Taiheiyo Kouhatsu Incorporated',
    name: 'Taiheiyo Kouhatsu Incorporated',
    symbol: '8835.T',
  },
  {
    value: '8842.T',
    label: '8842.T,  Tokyo Rakutenchi Co.,Ltd.',
    name: 'Tokyo Rakutenchi Co.,Ltd.',
    symbol: '8842.T',
  },
  {
    value: '8869.T',
    label: '8869.T,  Meiwa Estate Company Limited',
    name: 'Meiwa Estate Company Limited',
    symbol: '8869.T',
  },
  {
    value: '8904.T',
    label: '8904.T,  Avantia Co., Ltd.',
    name: 'Avantia Co., Ltd.',
    symbol: '8904.T',
  },
  {
    value: '8908.T',
    label: '8908.T,  Mainichi Comnet Co., Ltd.',
    name: 'Mainichi Comnet Co., Ltd.',
    symbol: '8908.T',
  },
  {
    value: '8918.T',
    label: '8918.T,  LAND Co., Ltd.',
    name: 'LAND Co., Ltd.',
    symbol: '8918.T',
  },
  {
    value: '8928.T',
    label: '8928.T,  Anabuki Kosan Inc.',
    name: 'Anabuki Kosan Inc.',
    symbol: '8928.T',
  },
  {
    value: '8940.T',
    label: '8940.T,  Intellex Co., Ltd.',
    name: 'Intellex Co., Ltd.',
    symbol: '8940.T',
  },
  {
    value: '8944.T',
    label: '8944.T,  Land Business Co.,Ltd.',
    name: 'Land Business Co.,Ltd.',
    symbol: '8944.T',
  },
  {
    value: '8945.T',
    label: '8945.T,  SUNNEXTA GROUP Inc.',
    name: 'SUNNEXTA GROUP Inc.',
    symbol: '8945.T',
  },
  {
    value: '9046.T',
    label: '9046.T,  Kobe Electric Railway Co., Ltd.',
    name: 'Kobe Electric Railway Co., Ltd.',
    symbol: '9046.T',
  },
  {
    value: '9055.T',
    label: '9055.T,  Alps Logistics Co., Ltd.',
    name: 'Alps Logistics Co., Ltd.',
    symbol: '9055.T',
  },
  {
    value: '9067.T',
    label: '9067.T,  Maruwn Corporation',
    name: 'Maruwn Corporation',
    symbol: '9067.T',
  },
  {
    value: '9074.T',
    label: '9074.T,  Japan Oil Transportation Co., Ltd.',
    name: 'Japan Oil Transportation Co., Ltd.',
    symbol: '9074.T',
  },
  {
    value: '9078.T',
    label: '9078.T,  S Line Co.,Ltd.',
    name: 'S Line Co.,Ltd.',
    symbol: '9078.T',
  },
  {
    value: '9081.T',
    label: '9081.T,  Kanagawa Chuo Kotsu Co., Ltd.',
    name: 'Kanagawa Chuo Kotsu Co., Ltd.',
    symbol: '9081.T',
  },
  {
    value: '9115.T',
    label: '9115.T,  Meiji Shipping Co., Ltd.',
    name: 'Meiji Shipping Co., Ltd.',
    symbol: '9115.T',
  },
  {
    value: '9130.T',
    label: '9130.T,  Kyoei Tanker Co., Ltd.',
    name: 'Kyoei Tanker Co., Ltd.',
    symbol: '9130.T',
  },
  {
    value: '9248.T',
    label: '9248.T,  People, Dreams & Technologies Group Co., Ltd.',
    name: 'People, Dreams & Technologies Group Co., Ltd.',
    symbol: '9248.T',
  },
  {
    value: '9260.T',
    label: '9260.T,  Nishimoto Co., Ltd.',
    name: 'Nishimoto Co., Ltd.',
    symbol: '9260.T',
  },
  {
    value: '9265.T',
    label: '9265.T,  Yamashita Health Care Holdings,Inc.',
    name: 'Yamashita Health Care Holdings,Inc.',
    symbol: '9265.T',
  },
  {
    value: '9275.T',
    label: '9275.T,  NARUMIYA INTERNATIONAL Co., Ltd.',
    name: 'NARUMIYA INTERNATIONAL Co., Ltd.',
    symbol: '9275.T',
  },
  {
    value: '9279.T',
    label: '9279.T,  Gift Holdings Inc.',
    name: 'Gift Holdings Inc.',
    symbol: '9279.T',
  },
  {
    value: '9304.T',
    label: '9304.T,  The Shibusawa Warehouse Co., Ltd.',
    name: 'The Shibusawa Warehouse Co., Ltd.',
    symbol: '9304.T',
  },
  {
    value: '9305.T',
    label: '9305.T,  Yamatane Corporation',
    name: 'Yamatane Corporation',
    symbol: '9305.T',
  },
  {
    value: '9306.T',
    label: '9306.T,  Toyo Logistics Co., Ltd.',
    name: 'Toyo Logistics Co., Ltd.',
    symbol: '9306.T',
  },
  {
    value: '9312.T',
    label: '9312.T,  The Keihin Co., Ltd.',
    name: 'The Keihin Co., Ltd.',
    symbol: '9312.T',
  },
  {
    value: '9319.T',
    label: '9319.T,  Chuo Warehouse Co.,Ltd.',
    name: 'Chuo Warehouse Co.,Ltd.',
    symbol: '9319.T',
  },
  {
    value: '9322.T',
    label: '9322.T,  Kawanishi Warehouse Co.,Ltd.',
    name: 'Kawanishi Warehouse Co.,Ltd.',
    symbol: '9322.T',
  },
  {
    value: '9325.T',
    label: '9325.T,  PHYZ Holdings Inc.',
    name: 'PHYZ Holdings Inc.',
    symbol: '9325.T',
  },
  {
    value: '9351.T',
    label: '9351.T,  Toyo Wharf & Warehouse Co., Ltd.',
    name: 'Toyo Wharf & Warehouse Co., Ltd.',
    symbol: '9351.T',
  },
  {
    value: '9366.T',
    label: '9366.T,  Sanritsu Corporation',
    name: 'Sanritsu Corporation',
    symbol: '9366.T',
  },
  {
    value: '9368.T',
    label: '9368.T,  Kimura Unity Co., Ltd.',
    name: 'Kimura Unity Co., Ltd.',
    symbol: '9368.T',
  },
  {
    value: '9369.T',
    label: '9369.T,  K.R.S. Corporation',
    name: 'K.R.S. Corporation',
    symbol: '9369.T',
  },
  {
    value: '9380.T',
    label: '9380.T,  Azuma Shipping Co., Ltd.',
    name: 'Azuma Shipping Co., Ltd.',
    symbol: '9380.T',
  },
  {
    value: '9381.T',
    label: '9381.T,  AIT Corporation',
    name: 'AIT Corporation',
    symbol: '9381.T',
  },
  {
    value: '9384.T',
    label: '9384.T,  Naigai Trans Line Ltd.',
    name: 'Naigai Trans Line Ltd.',
    symbol: '9384.T',
  },
  {
    value: '9385.T',
    label: '9385.T,  Shoei Corporation',
    name: 'Shoei Corporation',
    symbol: '9385.T',
  },
  {
    value: '9386.T',
    label: '9386.T,  Nippon Concept Corporation',
    name: 'Nippon Concept Corporation',
    symbol: '9386.T',
  },
  {
    value: '9405.T',
    label: '9405.T,  Asahi Broadcasting Group Holdings Corporation',
    name: 'Asahi Broadcasting Group Holdings Corporation',
    symbol: '9405.T',
  },
  {
    value: '9414.T',
    label: '9414.T,  Nippon BS Broadcasting Corporation',
    name: 'Nippon BS Broadcasting Corporation',
    symbol: '9414.T',
  },
  {
    value: '9417.T',
    label: '9417.T,  Smartvalue Co., Ltd.',
    name: 'Smartvalue Co., Ltd.',
    symbol: '9417.T',
  },
  {
    value: '9419.T',
    label: '9419.T,  WirelessGate, Inc.',
    name: 'WirelessGate, Inc.',
    symbol: '9419.T',
  },
  {
    value: '9428.T',
    label: '9428.T,  Crops Corporation',
    name: 'Crops Corporation',
    symbol: '9428.T',
  },
  {
    value: '9466.T',
    label: '9466.T,  Aidma Marketing Communication Corporation',
    name: 'Aidma Marketing Communication Corporation',
    symbol: '9466.T',
  },
  {
    value: '9475.T',
    label: '9475.T,  Shobunsha Holdings, Inc.',
    name: 'Shobunsha Holdings, Inc.',
    symbol: '9475.T',
  },
  {
    value: '9479.T',
    label: '9479.T,  Impress Holdings, Inc.',
    name: 'Impress Holdings, Inc.',
    symbol: '9479.T',
  },
  {
    value: '9612.T',
    label: '9612.T,  LuckLand Co., Ltd.',
    name: 'LuckLand Co., Ltd.',
    symbol: '9612.T',
  },
  {
    value: '9628.T',
    label: '9628.T,  SAN Holdings, Inc.',
    name: 'SAN Holdings, Inc.',
    symbol: '9628.T',
  },
  {
    value: '9632.T',
    label: '9632.T,  Subaru Enterprise Co., Ltd.',
    name: 'Subaru Enterprise Co., Ltd.',
    symbol: '9632.T',
  },
  {
    value: '9633.T',
    label: '9633.T,  Tokyo Theatres Company, Incorporated',
    name: 'Tokyo Theatres Company, Incorporated',
    symbol: '9633.T',
  },
  {
    value: '9644.T',
    label: '9644.T,  Tanabe Consulting Group Co.,Ltd.',
    name: 'Tanabe Consulting Group Co.,Ltd.',
    symbol: '9644.T',
  },
  {
    value: '9675.T',
    label: '9675.T,  Joban Kosan Co.,Ltd.',
    name: 'Joban Kosan Co.,Ltd.',
    symbol: '9675.T',
  },
  {
    value: '9702.T',
    label: '9702.T,  ISB Corporation',
    name: 'ISB Corporation',
    symbol: '9702.T',
  },
  {
    value: '9704.T',
    label: '9704.T,  AGORA Hospitality Group Co., Ltd',
    name: 'AGORA Hospitality Group Co., Ltd',
    symbol: '9704.T',
  },
  {
    value: '9717.T',
    label: '9717.T,  JASTEC Co., Ltd.',
    name: 'JASTEC Co., Ltd.',
    symbol: '9717.T',
  },
  {
    value: '9731.T',
    label: '9731.T,  Hakuyosha Company, Ltd.',
    name: 'Hakuyosha Company, Ltd.',
    symbol: '9731.T',
  },
  {
    value: '9739.T',
    label: '9739.T,  NSW Inc.',
    name: 'NSW Inc.',
    symbol: '9739.T',
  },
  {
    value: '9760.T',
    label: '9760.T,  Shingakukai Holdings Co.,Ltd.',
    name: 'Shingakukai Holdings Co.,Ltd.',
    symbol: '9760.T',
  },
  {
    value: '9763.T',
    label: '9763.T,  Marubeni Construction Material Lease Co.,Ltd.',
    name: 'Marubeni Construction Material Lease Co.,Ltd.',
    symbol: '9763.T',
  },
  {
    value: '9765.T',
    label: '9765.T,  Ohba Co., Ltd.',
    name: 'Ohba Co., Ltd.',
    symbol: '9765.T',
  },
  {
    value: '9769.T',
    label: '9769.T,  Gakkyusha Co.,Ltd.',
    name: 'Gakkyusha Co.,Ltd.',
    symbol: '9769.T',
  },
  {
    value: '9795.T',
    label: '9795.T,  Step Co.,Ltd.',
    name: 'Step Co.,Ltd.',
    symbol: '9795.T',
  },
  {
    value: '9824.T',
    label: '9824.T,  Senshu Electric Co.,Ltd.',
    name: 'Senshu Electric Co.,Ltd.',
    symbol: '9824.T',
  },
  {
    value: '9850.T',
    label: '9850.T,  Gourmet Kineya Co.,Ltd.',
    name: 'Gourmet Kineya Co.,Ltd.',
    symbol: '9850.T',
  },
  {
    value: '9854.T',
    label: '9854.T,  Aigan Co.,Ltd.',
    name: 'Aigan Co.,Ltd.',
    symbol: '9854.T',
  },
  {
    value: '9856.T',
    label: '9856.T,  KU Holdings Co., Ltd.',
    name: 'KU Holdings Co., Ltd.',
    symbol: '9856.T',
  },
  {
    value: '9872.T',
    label: '9872.T,  Kitakei Co., Ltd.',
    name: 'Kitakei Co., Ltd.',
    symbol: '9872.T',
  },
  {
    value: '9887.T',
    label: '9887.T,  Matsuya Foods Holdings Co., Ltd.',
    name: 'Matsuya Foods Holdings Co., Ltd.',
    symbol: '9887.T',
  },
  {
    value: '9919.T',
    label: '9919.T,  Kansai Food Market Ltd.',
    name: 'Kansai Food Market Ltd.',
    symbol: '9919.T',
  },
  {
    value: '9930.T',
    label: '9930.T,  Kitazawa Sangyo Co., Ltd.',
    name: 'Kitazawa Sangyo Co., Ltd.',
    symbol: '9930.T',
  },
  {
    value: '9932.T',
    label: '9932.T,  Sugimoto & Co., Ltd.',
    name: 'Sugimoto & Co., Ltd.',
    symbol: '9932.T',
  },
  {
    value: '9946.T',
    label: '9946.T,  MINISTOP Co., Ltd.',
    name: 'MINISTOP Co., Ltd.',
    symbol: '9946.T',
  },
  {
    value: '9960.T',
    label: '9960.T,  Totech Corporation',
    name: 'Totech Corporation',
    symbol: '9960.T',
  },
  {
    value: '9972.T',
    label: '9972.T,  Altech Co., Ltd.',
    name: 'Altech Co., Ltd.',
    symbol: '9972.T',
  },
  {
    value: '9979.T',
    label: '9979.T,  Daisyo Corporation',
    name: 'Daisyo Corporation',
    symbol: '9979.T',
  },
  {
    value: '9982.T',
    label: '9982.T,  Takihyo Co., Ltd.',
    name: 'Takihyo Co., Ltd.',
    symbol: '9982.T',
  },
  {
    value: '9986.T',
    label: '9986.T,  ZAOH Company, Ltd.',
    name: 'ZAOH Company, Ltd.',
    symbol: '9986.T',
  },
  {
    value: '9993.T',
    label: '9993.T,  Yamazawa Co., Ltd.',
    name: 'Yamazawa Co., Ltd.',
    symbol: '9993.T',
  },
  {
    value: '9994.T',
    label: '9994.T,  Yamaya Corporation',
    name: 'Yamaya Corporation',
    symbol: '9994.T',
  },
  {
    value: '9995.T',
    label: '9995.T,  Glosel Co., Ltd.',
    name: 'Glosel Co., Ltd.',
    symbol: '9995.T',
  },
  {
    value: 'ATD.TO',
    label: 'ATD.TO,  Alimentation Couche-Tard Inc.',
    name: 'Alimentation Couche-Tard Inc.',
    symbol: 'ATD.TO',
  },
  {
    value: 'BIOSW',
    label: 'BIOSW,  BioPlus Acquisition Corp.',
    name: 'BioPlus Acquisition Corp.',
    symbol: 'BIOSW',
  },
  {
    value: 'SQFTW',
    label: 'SQFTW,  Presidio Property Trust, Inc.',
    name: 'Presidio Property Trust, Inc.',
    symbol: 'SQFTW',
  },
  {
    value: 'IMPP',
    label: 'IMPP,  Imperial Petroleum Inc.',
    name: 'Imperial Petroleum Inc.',
    symbol: 'IMPP',
  },
  {
    value: 'GEEXW',
    label: 'GEEXW,  Games & Esports Experience Acquisition Corp.',
    name: 'Games & Esports Experience Acquisition Corp.',
    symbol: 'GEEXW',
  },
  {
    value: 'NVACR',
    label: 'NVACR,  NorthView Acquisition Corporation',
    name: 'NorthView Acquisition Corporation',
    symbol: 'NVACR',
  },
  {
    value: 'BIOS',
    label: 'BIOS,  BioPlus Acquisition Corp.',
    name: 'BioPlus Acquisition Corp.',
    symbol: 'BIOS',
  },
  {
    value: 'BPAC',
    label: 'BPAC,  Bullpen Parlay Acquisition Company',
    name: 'Bullpen Parlay Acquisition Company',
    symbol: 'BPAC',
  },
  {
    value: 'BOCN',
    label: 'BOCN,  Blue Ocean Acquisition Corp.',
    name: 'Blue Ocean Acquisition Corp.',
    symbol: 'BOCN',
  },
  {
    value: 'ALR',
    label: 'ALR,  AlerisLife Inc.',
    name: 'AlerisLife Inc.',
    symbol: 'ALR',
  },
  {
    value: 'SST',
    label: 'SST,  System1, Inc.',
    name: 'System1, Inc.',
    symbol: 'SST',
  },
  {
    value: 'TCRT',
    label: 'TCRT,  Alaunos Therapeutics, Inc.',
    name: 'Alaunos Therapeutics, Inc.',
    symbol: 'TCRT',
  },
  {
    value: 'NVAC',
    label: 'NVAC,  NorthView Acquisition Corporation',
    name: 'NorthView Acquisition Corporation',
    symbol: 'NVAC',
  },
  {
    value: 'AT.L',
    label: 'AT.L,  Ashtead Technology Holdings Plc',
    name: 'Ashtead Technology Holdings Plc',
    symbol: 'AT.L',
  },
  {
    value: 'TTE.L',
    label: 'TTE.L,  TotalEnergies SE',
    name: 'TotalEnergies SE',
    symbol: 'TTE.L',
  },
  {
    value: 'CCZ.L',
    label: 'CCZ.L,  Castillo Copper Limited',
    name: 'Castillo Copper Limited',
    symbol: 'CCZ.L',
  },
  {
    value: 'ALL.L',
    label: 'ALL.L,  Atlantic Lithium Limited',
    name: 'Atlantic Lithium Limited',
    symbol: 'ALL.L',
  },
  {
    value: 'CMH.L',
    label: 'CMH.L,  Chamberlin plc',
    name: 'Chamberlin plc',
    symbol: 'CMH.L',
  },
  {
    value: 'SDG.L',
    label: 'SDG.L,  Sanderson Design Group plc',
    name: 'Sanderson Design Group plc',
    symbol: 'SDG.L',
  },
  {
    value: 'FLK.L',
    label: 'FLK.L,  Fletcher King Plc',
    name: 'Fletcher King Plc',
    symbol: 'FLK.L',
  },
  {
    value: 'AFRN.L',
    label: 'AFRN.L,  Aferian Plc',
    name: 'Aferian Plc',
    symbol: 'AFRN.L',
  },
  {
    value: 'LEND.L',
    label: 'LEND.L,  Sancus Lending Group Limited',
    name: 'Sancus Lending Group Limited',
    symbol: 'LEND.L',
  },
  {
    value: 'SNWS.L',
    label: 'SNWS.L,  Smiths News plc',
    name: 'Smiths News plc',
    symbol: 'SNWS.L',
  },
  {
    value: 'YNGN.L',
    label: "YNGN.L,  Young & Co.'s Brewery, P.L.C.",
    name: "Young & Co.'s Brewery, P.L.C.",
    symbol: 'YNGN.L',
  },
  {
    value: 'AET.L',
    label: 'AET.L,  Afentra plc',
    name: 'Afentra plc',
    symbol: 'AET.L',
  },
  {
    value: 'LDG.L',
    label: 'LDG.L,  Logistics Development Group plc',
    name: 'Logistics Development Group plc',
    symbol: 'LDG.L',
  },
  {
    value: 'XPF.L',
    label: 'XPF.L,  XP Factory Plc',
    name: 'XP Factory Plc',
    symbol: 'XPF.L',
  },
  {
    value: 'SCIR.L',
    label: 'SCIR.L,  Scirocco Energy Plc',
    name: 'Scirocco Energy Plc',
    symbol: 'SCIR.L',
  },
  {
    value: 'ZNWD.L',
    label: 'ZNWD.L,  Zinnwald Lithium Plc',
    name: 'Zinnwald Lithium Plc',
    symbol: 'ZNWD.L',
  },
  {
    value: 'NNN.L',
    label: 'NNN.L,  nanosynth group plc',
    name: 'nanosynth group plc',
    symbol: 'NNN.L',
  },
  {
    value: 'FNTL.L',
    label: 'FNTL.L,  Fintel Plc',
    name: 'Fintel Plc',
    symbol: 'FNTL.L',
  },
  {
    value: 'NWOR.L',
    label: 'NWOR.L,  National World Plc',
    name: 'National World Plc',
    symbol: 'NWOR.L',
  },
  {
    value: 'CRTM.L',
    label: 'CRTM.L,  Critical Metals Plc',
    name: 'Critical Metals Plc',
    symbol: 'CRTM.L',
  },
  {
    value: 'BFSP.L',
    label: 'BFSP.L,  Blackfinch Spring VCT PLC',
    name: 'Blackfinch Spring VCT PLC',
    symbol: 'BFSP.L',
  },
  {
    value: 'BARK.L',
    label: 'BARK.L,  Barkby Group PLC',
    name: 'Barkby Group PLC',
    symbol: 'BARK.L',
  },
  {
    value: 'ELIX.L',
    label: 'ELIX.L,  Elixirr International plc',
    name: 'Elixirr International plc',
    symbol: 'ELIX.L',
  },
  {
    value: 'HARL.L',
    label: 'HARL.L,  Harland & Wolff Group Holdings Plc',
    name: 'Harland & Wolff Group Holdings Plc',
    symbol: 'HARL.L',
  },
  {
    value: 'VARE.L',
    label: 'VARE.L,  Various Eateries PLC',
    name: 'Various Eateries PLC',
    symbol: 'VARE.L',
  },
  {
    value: 'DVRG.L',
    label: 'DVRG.L,  DeepVerge plc',
    name: 'DeepVerge plc',
    symbol: 'DVRG.L',
  },
  {
    value: 'IUG.L',
    label: 'IUG.L,  Intelligent Ultrasound Group plc',
    name: 'Intelligent Ultrasound Group plc',
    symbol: 'IUG.L',
  },
  {
    value: 'KINO.L',
    label: 'KINO.L,  Kinovo plc',
    name: 'Kinovo plc',
    symbol: 'KINO.L',
  },
  {
    value: 'KNB.L',
    label: 'KNB.L,  Kanabo Group Plc',
    name: 'Kanabo Group Plc',
    symbol: 'KNB.L',
  },
  {
    value: 'MHC.L',
    label: 'MHC.L,  MyHealthChecked PLC',
    name: 'MyHealthChecked PLC',
    symbol: 'MHC.L',
  },
  {
    value: 'CRDL.L',
    label: 'CRDL.L,  Cordel Group Plc',
    name: 'Cordel Group Plc',
    symbol: 'CRDL.L',
  },
  {
    value: 'DNA.L',
    label: 'DNA.L,  Doric Nimrod Air One Limited',
    name: 'Doric Nimrod Air One Limited',
    symbol: 'DNA.L',
  },
  {
    value: 'EXR.L',
    label: 'EXR.L,  ENGAGE XR Holdings plc',
    name: 'ENGAGE XR Holdings plc',
    symbol: 'EXR.L',
  },
  {
    value: 'ASPL.L',
    label: 'ASPL.L,  Aseana Properties Limited',
    name: 'Aseana Properties Limited',
    symbol: 'ASPL.L',
  },
  {
    value: 'MACP.L',
    label: 'MACP.L,  Marwyn Acquisition Company Plc',
    name: 'Marwyn Acquisition Company Plc',
    symbol: 'MACP.L',
  },
  {
    value: 'VRCI.L',
    label: 'VRCI.L,  Verici Dx plc',
    name: 'Verici Dx plc',
    symbol: 'VRCI.L',
  },
  {
    value: 'KIST.L',
    label: 'KIST.L,  Kistos plc',
    name: 'Kistos plc',
    symbol: 'KIST.L',
  },
  {
    value: 'PUAL.L',
    label: 'PUAL.L,  Puma Alpha VCT plc',
    name: 'Puma Alpha VCT plc',
    symbol: 'PUAL.L',
  },
  {
    value: 'AMOI.L',
    label: 'AMOI.L,  Anemoi International Limited',
    name: 'Anemoi International Limited',
    symbol: 'AMOI.L',
  },
  {
    value: 'HE1.L',
    label: 'HE1.L,  Helium One Global Ltd',
    name: 'Helium One Global Ltd',
    symbol: 'HE1.L',
  },
  {
    value: 'JADE.L',
    label: 'JADE.L,  Jade Road Investments Limited',
    name: 'Jade Road Investments Limited',
    symbol: 'JADE.L',
  },
  {
    value: 'BRSD.L',
    label: 'BRSD.L,  BrandShield Systems Plc',
    name: 'BrandShield Systems Plc',
    symbol: 'BRSD.L',
  },
  {
    value: 'TMI.L',
    label: 'TMI.L,  Taylor Maritime Investments Limited',
    name: 'Taylor Maritime Investments Limited',
    symbol: 'TMI.L',
  },
  {
    value: 'CDL.L',
    label: 'CDL.L,  Cloudbreak Discovery Plc',
    name: 'Cloudbreak Discovery Plc',
    symbol: 'CDL.L',
  },
  {
    value: 'PNPL.L',
    label: 'PNPL.L,  Pineapple Power Corporation plc',
    name: 'Pineapple Power Corporation plc',
    symbol: 'PNPL.L',
  },
  {
    value: 'TGR.L',
    label: 'TGR.L,  Tirupati Graphite plc',
    name: 'Tirupati Graphite plc',
    symbol: 'TGR.L',
  },
  {
    value: 'CBX.L',
    label: 'CBX.L,  Cellular Goods PLC',
    name: 'Cellular Goods PLC',
    symbol: 'CBX.L',
  },
  {
    value: 'OHG.L',
    label: 'OHG.L,  One Heritage Group PLC',
    name: 'One Heritage Group PLC',
    symbol: 'OHG.L',
  },
  {
    value: 'RNEP.L',
    label: 'RNEP.L,  Ecofin U.S. Renewables Infrastructure Trust PLC',
    name: 'Ecofin U.S. Renewables Infrastructure Trust PLC',
    symbol: 'RNEP.L',
  },
  {
    value: 'MAST.L',
    label: 'MAST.L,  MAST Energy Developments PLC',
    name: 'MAST Energy Developments PLC',
    symbol: 'MAST.L',
  },
  {
    value: 'ROQ.L',
    label: 'ROQ.L,  Roquefort Therapeutics plc',
    name: 'Roquefort Therapeutics plc',
    symbol: 'ROQ.L',
  },
  {
    value: 'CMRS.L',
    label: 'CMRS.L,  Caerus Mineral Resources Plc',
    name: 'Caerus Mineral Resources Plc',
    symbol: 'CMRS.L',
  },
  {
    value: 'VCAP.L',
    label: 'VCAP.L,  Vector Capital Plc',
    name: 'Vector Capital Plc',
    symbol: 'VCAP.L',
  },
  {
    value: 'WCAT.L',
    label: 'WCAT.L,  Wildcat Petroleum Plc',
    name: 'Wildcat Petroleum Plc',
    symbol: 'WCAT.L',
  },
  {
    value: 'OCTP.L',
    label: 'OCTP.L,  Oxford Cannabinoid Technologies Holdings Plc',
    name: 'Oxford Cannabinoid Technologies Holdings Plc',
    symbol: 'OCTP.L',
  },
  {
    value: 'BCG.L',
    label: 'BCG.L,  Baltic Classifieds Group PLC',
    name: 'Baltic Classifieds Group PLC',
    symbol: 'BCG.L',
  },
  {
    value: 'AEEE.L',
    label: 'AEEE.L,  Aquila Energy Efficiency Trust Plc',
    name: 'Aquila Energy Efficiency Trust Plc',
    symbol: 'AEEE.L',
  },
  {
    value: 'CIZ.L',
    label: 'CIZ.L,  Cizzle Biotechnology Holdings Plc',
    name: 'Cizzle Biotechnology Holdings Plc',
    symbol: 'CIZ.L',
  },
  {
    value: 'MADE.L',
    label: 'MADE.L,  Made.com Group Plc',
    name: 'Made.com Group Plc',
    symbol: 'MADE.L',
  },
  {
    value: 'FSG.L',
    label: 'FSG.L,  Foresight Group Holdings Limited',
    name: 'Foresight Group Holdings Limited',
    symbol: 'FSG.L',
  },
  {
    value: 'VIC.L',
    label: 'VIC.L,  Victorian Plumbing Group plc',
    name: 'Victorian Plumbing Group plc',
    symbol: 'VIC.L',
  },
  {
    value: 'BELL.L',
    label: 'BELL.L,  Belluscura plc',
    name: 'Belluscura plc',
    symbol: 'BELL.L',
  },
  {
    value: 'SUP.L',
    label: 'SUP.L,  Supreme Plc',
    name: 'Supreme Plc',
    symbol: 'SUP.L',
  },
  {
    value: 'MMAG.L',
    label: 'MMAG.L,  musicMagpie plc',
    name: 'musicMagpie plc',
    symbol: 'MMAG.L',
  },
  {
    value: 'DNM.L',
    label: 'DNM.L,  Dianomi plc',
    name: 'Dianomi plc',
    symbol: 'DNM.L',
  },
  {
    value: 'AOM.L',
    label: 'AOM.L,  ActiveOps Plc',
    name: 'ActiveOps Plc',
    symbol: 'AOM.L',
  },
  {
    value: 'NGHT.L',
    label: 'NGHT.L,  Nightcap Plc',
    name: 'Nightcap Plc',
    symbol: 'NGHT.L',
  },
  {
    value: 'ITIM.L',
    label: 'ITIM.L,  Itim Group Plc',
    name: 'Itim Group Plc',
    symbol: 'ITIM.L',
  },
  {
    value: 'CMET.L',
    label: 'CMET.L,  Capital Metals plc',
    name: 'Capital Metals plc',
    symbol: 'CMET.L',
  },
  {
    value: 'AREC.L',
    label: 'AREC.L,  Arecor Therapeutics plc',
    name: 'Arecor Therapeutics plc',
    symbol: 'AREC.L',
  },
  {
    value: 'ITS.L',
    label: 'ITS.L,  In The Style Group Plc',
    name: 'In The Style Group Plc',
    symbol: 'ITS.L',
  },
  {
    value: 'VINO.L',
    label: 'VINO.L,  Virgin Wines UK PLC',
    name: 'Virgin Wines UK PLC',
    symbol: 'VINO.L',
  },
  {
    value: 'IIG.L',
    label: 'IIG.L,  Intuitive Investments Group Plc',
    name: 'Intuitive Investments Group Plc',
    symbol: 'IIG.L',
  },
  {
    value: 'MEAL.L',
    label: 'MEAL.L,  Parsley Box Group plc',
    name: 'Parsley Box Group plc',
    symbol: 'MEAL.L',
  },
  {
    value: 'TRLS.L',
    label: 'TRLS.L,  Trellus Health plc',
    name: 'Trellus Health plc',
    symbol: 'TRLS.L',
  },
  {
    value: 'ART.L',
    label: 'ART.L,  The Artisanal Spirits Company plc',
    name: 'The Artisanal Spirits Company plc',
    symbol: 'ART.L',
  },
  {
    value: 'KITW.L',
    label: 'KITW.L,  Kitwave Group plc',
    name: 'Kitwave Group plc',
    symbol: 'KITW.L',
  },
  {
    value: 'TMIP.L',
    label: 'TMIP.L,  Taylor Maritime Investments Limited',
    name: 'Taylor Maritime Investments Limited',
    symbol: 'TMIP.L',
  },
  {
    value: 'GLAN.L',
    label: 'GLAN.L,  Glantus Holdings PLC',
    name: 'Glantus Holdings PLC',
    symbol: 'GLAN.L',
  },
  {
    value: 'AFP.L',
    label: 'AFP.L,  African Pioneer PLC',
    name: 'African Pioneer PLC',
    symbol: 'AFP.L',
  },
  {
    value: 'CEG.L',
    label: 'CEG.L,  Challenger Energy Group PLC',
    name: 'Challenger Energy Group PLC',
    symbol: 'CEG.L',
  },
  {
    value: 'TEAM.L',
    label: 'TEAM.L,  TEAM plc',
    name: 'TEAM plc',
    symbol: 'TEAM.L',
  },
  {
    value: 'CMO.L',
    label: 'CMO.L,  CMO Group PLC',
    name: 'CMO Group PLC',
    symbol: 'CMO.L',
  },
  {
    value: 'SED.L',
    label: 'SED.L,  Saietta Group plc',
    name: 'Saietta Group plc',
    symbol: 'SED.L',
  },
  {
    value: 'WISE.L',
    label: 'WISE.L,  Wise plc',
    name: 'Wise plc',
    symbol: 'WISE.L',
  },
  {
    value: '4BB.L',
    label: '4BB.L,  4basebio PLC',
    name: '4basebio PLC',
    symbol: '4BB.L',
  },
  {
    value: 'IBSU.L',
    label: 'IBSU.L,  Insight Business Support plc',
    name: 'Insight Business Support plc',
    symbol: 'IBSU.L',
  },
  {
    value: 'LINV.L',
    label: 'LINV.L,  LendInvest plc',
    name: 'LendInvest plc',
    symbol: 'LINV.L',
  },
  {
    value: 'EISB.L',
    label: 'EISB.L,  East Imperial PLC',
    name: 'East Imperial PLC',
    symbol: 'EISB.L',
  },
  {
    value: 'ORCA.L',
    label: 'ORCA.L,  Orcadian Energy Plc',
    name: 'Orcadian Energy Plc',
    symbol: 'ORCA.L',
  },
  {
    value: 'POLB.L',
    label: 'POLB.L,  Poolbeg Pharma PLC',
    name: 'Poolbeg Pharma PLC',
    symbol: 'POLB.L',
  },
  {
    value: 'AC8.L',
    label: 'AC8.L,  Acceler8 Ventures Plc',
    name: 'Acceler8 Ventures Plc',
    symbol: 'AC8.L',
  },
  {
    value: 'BHL.L',
    label: 'BHL.L,  Bradda Head Lithium Limited',
    name: 'Bradda Head Lithium Limited',
    symbol: 'BHL.L',
  },
  {
    value: 'ZED.L',
    label: 'ZED.L,  Zenova Group Plc',
    name: 'Zenova Group Plc',
    symbol: 'ZED.L',
  },
  {
    value: 'SIV.L',
    label: 'SIV.L,  Sivota PLC',
    name: 'Sivota PLC',
    symbol: 'SIV.L',
  },
  {
    value: 'SAAS.L',
    label: 'SAAS.L,  Microlise Group plc',
    name: 'Microlise Group plc',
    symbol: 'SAAS.L',
  },
  {
    value: 'GENI.L',
    label: 'GENI.L,  GENinCode Plc',
    name: 'GENinCode Plc',
    symbol: 'GENI.L',
  },
  {
    value: 'BIG.L',
    label: 'BIG.L,  Big Technologies plc',
    name: 'Big Technologies plc',
    symbol: 'BIG.L',
  },
  {
    value: 'CODE.L',
    label: 'CODE.L,  Northcoders Group PLC',
    name: 'Northcoders Group PLC',
    symbol: 'CODE.L',
  },
  {
    value: 'SOUC.L',
    label: 'SOUC.L,  Southern Energy Corp.',
    name: 'Southern Energy Corp.',
    symbol: 'SOUC.L',
  },
  {
    value: 'FWD.L',
    label: 'FWD.L,  Forward Partners Group plc',
    name: 'Forward Partners Group plc',
    symbol: 'FWD.L',
  },
  {
    value: 'LIKE.L',
    label: 'LIKE.L,  Likewise Group plc',
    name: 'Likewise Group plc',
    symbol: 'LIKE.L',
  },
  {
    value: 'MORE.L',
    label: 'MORE.L,  Hostmore plc',
    name: 'Hostmore plc',
    symbol: 'MORE.L',
  },
  {
    value: 'PINT.L',
    label: 'PINT.L,  Pantheon Infrastructure PLC',
    name: 'Pantheon Infrastructure PLC',
    symbol: 'PINT.L',
  },
  {
    value: 'PODP.L',
    label: 'PODP.L,  Pod Point Group Holdings Plc',
    name: 'Pod Point Group Holdings Plc',
    symbol: 'PODP.L',
  },
  {
    value: 'PROC.L',
    label: 'PROC.L,  ProCook Group plc',
    name: 'ProCook Group plc',
    symbol: 'PROC.L',
  },
  {
    value: 'SRAD.L',
    label: 'SRAD.L,  Stelrad Group PLC',
    name: 'Stelrad Group PLC',
    symbol: 'SRAD.L',
  },
  {
    value: 'WPS.L',
    label: 'WPS.L,  W.A.G payment solutions plc',
    name: 'W.A.G payment solutions plc',
    symbol: 'WPS.L',
  },
  {
    value: 'ROOF.L',
    label: 'ROOF.L,  Atrato Onsite Energy plc',
    name: 'Atrato Onsite Energy plc',
    symbol: 'ROOF.L',
  },
  {
    value: 'TM1.L',
    label: 'TM1.L,  Technology Minerals Plc',
    name: 'Technology Minerals Plc',
    symbol: 'TM1.L',
  },
  {
    value: 'BEN.L',
    label: 'BEN.L,  Bens Creek Group Plc',
    name: 'Bens Creek Group Plc',
    symbol: 'BEN.L',
  },
  {
    value: 'ETP.L',
    label: 'ETP.L,  Eneraqua Technologies plc',
    name: 'Eneraqua Technologies plc',
    symbol: 'ETP.L',
  },
  {
    value: 'FRG.L',
    label: 'FRG.L,  Firering Strategic Minerals Plc',
    name: 'Firering Strategic Minerals Plc',
    symbol: 'FRG.L',
  },
  {
    value: 'LABS.L',
    label: 'LABS.L,  Life Science REIT plc',
    name: 'Life Science REIT plc',
    symbol: 'LABS.L',
  },
  {
    value: 'LST.L',
    label: 'LST.L,  Light Science Technologies Holdings Plc',
    name: 'Light Science Technologies Holdings Plc',
    symbol: 'LST.L',
  },
  {
    value: 'MEX.L',
    label: 'MEX.L,  Tortilla Mexican Grill plc',
    name: 'Tortilla Mexican Grill plc',
    symbol: 'MEX.L',
  },
  {
    value: 'MRK.L',
    label: 'MRK.L,  Marks Electrical Group PLC',
    name: 'Marks Electrical Group PLC',
    symbol: 'MRK.L',
  },
  {
    value: 'MTEC.L',
    label: 'MTEC.L,  Made Tech Group Plc',
    name: 'Made Tech Group Plc',
    symbol: 'MTEC.L',
  },
  {
    value: 'SMD.L',
    label: 'SMD.L,  Spectral MD Holdings, Ltd.',
    name: 'Spectral MD Holdings, Ltd.',
    symbol: 'SMD.L',
  },
  {
    value: 'UTLF.L',
    label: 'UTLF.L,  UIL Finance Limited',
    name: 'UIL Finance Limited',
    symbol: 'UTLF.L',
  },
  {
    value: 'UTLG.L',
    label: 'UTLG.L,  UIL Finance Limited',
    name: 'UIL Finance Limited',
    symbol: 'UTLG.L',
  },
  {
    value: 'GAL.L',
    label: 'GAL.L,  Galantas Gold Corporation',
    name: 'Galantas Gold Corporation',
    symbol: 'GAL.L',
  },
  {
    value: 'AFRK.L',
    label: 'AFRK.L,  Afarak Group SE',
    name: 'Afarak Group SE',
    symbol: 'AFRK.L',
  },
  {
    value: 'MTL.L',
    label: 'MTL.L,  Metals Exploration plc',
    name: 'Metals Exploration plc',
    symbol: 'MTL.L',
  },
  {
    value: 'INVR.L',
    label: 'INVR.L,  Investec plc',
    name: 'Investec plc',
    symbol: 'INVR.L',
  },
  {
    value: 'QBT.L',
    label: 'QBT.L,  Quantum Blockchain Technologies Plc',
    name: 'Quantum Blockchain Technologies Plc',
    symbol: 'QBT.L',
  },
  {
    value: 'CEPS.L',
    label: 'CEPS.L,  CEPS PLC',
    name: 'CEPS PLC',
    symbol: 'CEPS.L',
  },
  {
    value: 'MILA.L',
    label: 'MILA.L,  Mila Resources Plc',
    name: 'Mila Resources Plc',
    symbol: 'MILA.L',
  },
  {
    value: 'ASLR.L',
    label: 'ASLR.L,  Asimilar Group Plc',
    name: 'Asimilar Group Plc',
    symbol: 'ASLR.L',
  },
  {
    value: 'TNT.L',
    label: 'TNT.L,  Tintra PLC',
    name: 'Tintra PLC',
    symbol: 'TNT.L',
  },
  {
    value: 'INSG.L',
    label: 'INSG.L,  Insig AI Plc',
    name: 'Insig AI Plc',
    symbol: 'INSG.L',
  },
  {
    value: 'GCAT.L',
    label: 'GCAT.L,  Caracal Gold plc',
    name: 'Caracal Gold plc',
    symbol: 'GCAT.L',
  },
  {
    value: 'MLI.L',
    label: 'MLI.L,  Industrials REIT Limited',
    name: 'Industrials REIT Limited',
    symbol: 'MLI.L',
  },
  {
    value: 'LENZ.L',
    label: 'LENZ.L,  Sancus Lending Group Limited',
    name: 'Sancus Lending Group Limited',
    symbol: 'LENZ.L',
  },
  {
    value: 'B90.L',
    label: 'B90.L,  B90 Holdings PLC',
    name: 'B90 Holdings PLC',
    symbol: 'B90.L',
  },
  {
    value: 'BIH.L',
    label: 'BIH.L,  Boston International Holdings Plc',
    name: 'Boston International Holdings Plc',
    symbol: 'BIH.L',
  },
  {
    value: 'ADVT.L',
    label: 'ADVT.L,  AdvancedAdvT Limited',
    name: 'AdvancedAdvT Limited',
    symbol: 'ADVT.L',
  },
  {
    value: 'HEIQ.L',
    label: 'HEIQ.L,  HeiQ Plc',
    name: 'HeiQ Plc',
    symbol: 'HEIQ.L',
  },
  {
    value: 'MRIT.L',
    label: 'MRIT.L,  Merit Group plc',
    name: 'Merit Group plc',
    symbol: 'MRIT.L',
  },
  {
    value: 'SNT.L',
    label: 'SNT.L,  Sabien Technology Group Plc',
    name: 'Sabien Technology Group Plc',
    symbol: 'SNT.L',
  },
  {
    value: 'TRAC.L',
    label: 'TRAC.L,  t42 IoT Tracking Solutions PLC',
    name: 't42 IoT Tracking Solutions PLC',
    symbol: 'TRAC.L',
  },
  {
    value: 'GELN.L',
    label: 'GELN.L,  Gelion plc',
    name: 'Gelion plc',
    symbol: 'GELN.L',
  },
  {
    value: 'WNWD.L',
    label: 'WNWD.L,  Windward Ltd.',
    name: 'Windward Ltd.',
    symbol: 'WNWD.L',
  },
  {
    value: 'OBI.L',
    label: 'OBI.L,  Ondine Biomedical Inc.',
    name: 'Ondine Biomedical Inc.',
    symbol: 'OBI.L',
  },
  {
    value: 'SKL.L',
    label: 'SKL.L,  Skillcast Group plc',
    name: 'Skillcast Group plc',
    symbol: 'SKL.L',
  },
  {
    value: 'LBG.L',
    label: 'LBG.L,  LBG Media plc',
    name: 'LBG Media plc',
    symbol: 'LBG.L',
  },
  {
    value: 'GRX.L',
    label: 'GRX.L,  GreenX Metals Limited',
    name: 'GreenX Metals Limited',
    symbol: 'GRX.L',
  },
  {
    value: '4GBL.L',
    label: '4GBL.L,  4GLOBAL plc',
    name: '4GLOBAL plc',
    symbol: '4GBL.L',
  },
  {
    value: 'DSW.L',
    label: 'DSW.L,  DSW Capital plc',
    name: 'DSW Capital plc',
    symbol: 'DSW.L',
  },
  {
    value: 'GSCU.L',
    label: 'GSCU.L,  Great Southern Copper PLC',
    name: 'Great Southern Copper PLC',
    symbol: 'GSCU.L',
  },
  {
    value: 'APTA.L',
    label: 'APTA.L,  Aptamer Group plc',
    name: 'Aptamer Group plc',
    symbol: 'APTA.L',
  },
  {
    value: 'LIB.L',
    label: 'LIB.L,  Libertine Holdings PLC',
    name: 'Libertine Holdings PLC',
    symbol: 'LIB.L',
  },
  {
    value: 'CTA.L',
    label: 'CTA.L,  CT Automotive Group plc',
    name: 'CT Automotive Group plc',
    symbol: 'CTA.L',
  },
  {
    value: 'DWHA.L',
    label: 'DWHA.L,  Dewhurst Group Plc',
    name: 'Dewhurst Group Plc',
    symbol: 'DWHA.L',
  },
  {
    value: 'LORD.L',
    label: 'LORD.L,  Lords Group Trading plc',
    name: 'Lords Group Trading plc',
    symbol: 'LORD.L',
  },
  {
    value: 'TBLD.L',
    label: 'TBLD.L,  tinyBuild, Inc.',
    name: 'tinyBuild, Inc.',
    symbol: 'TBLD.L',
  },
  {
    value: 'NLCP',
    label: 'NLCP,  NewLake Capital Partners, Inc.',
    name: 'NewLake Capital Partners, Inc.',
    symbol: 'NLCP',
  },
  {
    value: 'NORSE.OL',
    label: 'NORSE.OL,  Norse Atlantic ASA',
    name: 'Norse Atlantic ASA',
    symbol: 'NORSE.OL',
  },
  {
    value: 'QCLU.L',
    label: 'QCLU.L,  First Trust Nasdaq Clean Edge Green Energy UCITS ETF',
    name: 'First Trust Nasdaq Clean Edge Green Energy UCITS ETF',
    symbol: 'QCLU.L',
  },
  {
    value: 'GBSS.L',
    label: 'GBSS.L,  Gold Bullion Securities',
    name: 'Gold Bullion Securities',
    symbol: 'GBSS.L',
  },
  {
    value: '3LAL.L',
    label: '3LAL.L,  GraniteShares 3x Long Alphabet Daily ETP',
    name: 'GraniteShares 3x Long Alphabet Daily ETP',
    symbol: '3LAL.L',
  },
  {
    value: '3LZN.L',
    label: '3LZN.L,  GraniteShares 3x Long Amazon Daily ETP',
    name: 'GraniteShares 3x Long Amazon Daily ETP',
    symbol: '3LZN.L',
  },
  {
    value: '3LAP.L',
    label: '3LAP.L,  GraniteShares 3x Long Apple Daily ETP',
    name: 'GraniteShares 3x Long Apple Daily ETP',
    symbol: '3LAP.L',
  },
  {
    value: '3LFB.L',
    label: '3LFB.L,  GraniteShares 3x Long Facebook Daily ETP',
    name: 'GraniteShares 3x Long Facebook Daily ETP',
    symbol: '3LFB.L',
  },
  {
    value: '3LMS.L',
    label: '3LMS.L,  GraniteShares 3x Long Microsoft Daily ETP',
    name: 'GraniteShares 3x Long Microsoft Daily ETP',
    symbol: '3LMS.L',
  },
  {
    value: '3LNF.L',
    label: '3LNF.L,  GraniteShares 3x Long Netflix Daily ETP',
    name: 'GraniteShares 3x Long Netflix Daily ETP',
    symbol: '3LNF.L',
  },
  {
    value: '3LNV.L',
    label: '3LNV.L,  GraniteShares 3x Long NVIDIA Daily ETP',
    name: 'GraniteShares 3x Long NVIDIA Daily ETP',
    symbol: '3LNV.L',
  },
  {
    value: '3LTS.L',
    label: '3LTS.L,  GraniteShares 3x Long Tesla Daily ETP',
    name: 'GraniteShares 3x Long Tesla Daily ETP',
    symbol: '3LTS.L',
  },
  {
    value: '3LUB.L',
    label: '3LUB.L,  GraniteShares 3x Long Uber Daily ETP',
    name: 'GraniteShares 3x Long Uber Daily ETP',
    symbol: '3LUB.L',
  },
  {
    value: '3SZN.L',
    label: '3SZN.L,  GraniteShares 3x Short Amazon Daily ETP',
    name: 'GraniteShares 3x Short Amazon Daily ETP',
    symbol: '3SZN.L',
  },
  {
    value: '3SAP.L',
    label: '3SAP.L,  GraniteShares 3x Short Apple Daily ETP',
    name: 'GraniteShares 3x Short Apple Daily ETP',
    symbol: '3SAP.L',
  },
  {
    value: '3SFB.L',
    label: '3SFB.L,  GraniteShares 3x Short Facebook Daily ETP',
    name: 'GraniteShares 3x Short Facebook Daily ETP',
    symbol: '3SFB.L',
  },
  {
    value: '3SMS.L',
    label: '3SMS.L,  GraniteShares 3x Short Microsoft Daily ETP',
    name: 'GraniteShares 3x Short Microsoft Daily ETP',
    symbol: '3SMS.L',
  },
  {
    value: '3SNF.L',
    label: '3SNF.L,  GraniteShares 3x Short Netflix Daily ETP',
    name: 'GraniteShares 3x Short Netflix Daily ETP',
    symbol: '3SNF.L',
  },
  {
    value: '3SNV.L',
    label: '3SNV.L,  GraniteShares 3x Short NVIDIA Daily ETP',
    name: 'GraniteShares 3x Short NVIDIA Daily ETP',
    symbol: '3SNV.L',
  },
  {
    value: '3STS.L',
    label: '3STS.L,  GraniteShares 3x Short Tesla Daily ETP',
    name: 'GraniteShares 3x Short Tesla Daily ETP',
    symbol: '3STS.L',
  },
  {
    value: '3SUB.L',
    label: '3SUB.L,  GraniteShares 3x Short Uber Daily ETP',
    name: 'GraniteShares 3x Short Uber Daily ETP',
    symbol: '3SUB.L',
  },
  {
    value: '3LAZ.L',
    label: '3LAZ.L,  GraniteShares 3x Long AstraZeneca Daily ETC',
    name: 'GraniteShares 3x Long AstraZeneca Daily ETC',
    symbol: '3LAZ.L',
  },
  {
    value: '3SAL.L',
    label: '3SAL.L,  GraniteShares 3x Short Alphabet Daily ETP',
    name: 'GraniteShares 3x Short Alphabet Daily ETP',
    symbol: '3SAL.L',
  },
  {
    value: 'IDWP.L',
    label:
      'IDWP.L,  iShares Developed Markets Property Yield UCITS ETF USD (Dist)',
    name: 'iShares Developed Markets Property Yield UCITS ETF USD (Dist)',
    symbol: 'IDWP.L',
  },
  {
    value: 'SDIA.L',
    label: 'SDIA.L,  iShares $ Short Duration Corp Bond UCITS ETF',
    name: 'iShares $ Short Duration Corp Bond UCITS ETF',
    symbol: 'SDIA.L',
  },
  {
    value: 'BNKS.L',
    label: 'BNKS.L,  iShares V PLC - iShares S&P U.S. Banks UCITS ETF',
    name: 'iShares V PLC - iShares S&P U.S. Banks UCITS ETF',
    symbol: 'BNKS.L',
  },
  {
    value: 'JERE.L',
    label:
      'JERE.L,  JPMorgan ETFs (Ireland) ICAV - Europe Research Enhanced Index Equity (ESG) UCITS ETF',
    name: 'JPMorgan ETFs (Ireland) ICAV - Europe Research Enhanced Index Equity (ESG) UCITS ETF',
    symbol: 'JERE.L',
  },
  {
    value: 'JURE.L',
    label:
      'JURE.L,  JPMorgan ETFs (Ireland) ICAV - US Research Enhanced Index Equity (ESG) UCITS ETF',
    name: 'JPMorgan ETFs (Ireland) ICAV - US Research Enhanced Index Equity (ESG) UCITS ETF',
    symbol: 'JURE.L',
  },
  {
    value: 'KSTR.L',
    label: 'KSTR.L,  KraneShares ICBCCS SSE Star Market 50 Index UCITS ETF',
    name: 'KraneShares ICBCCS SSE Star Market 50 Index UCITS ETF',
    symbol: 'KSTR.L',
  },
  {
    value: 'SBPE.L',
    label: 'SBPE.L,  Leverage Shares -1x Short BP ETP',
    name: 'Leverage Shares -1x Short BP ETP',
    symbol: 'SBPE.L',
  },
  {
    value: 'SBP.L',
    label: 'SBP.L,  Leverage Shares -1x Short BP ETP',
    name: 'Leverage Shares -1x Short BP ETP',
    symbol: 'SBP.L',
  },
  {
    value: 'BPS.L',
    label: 'BPS.L,  Leverage Shares -1x Short BP ETP',
    name: 'Leverage Shares -1x Short BP ETP',
    symbol: 'BPS.L',
  },
  {
    value: 'SRDE.L',
    label: 'SRDE.L,  Leverage Shares -1x Short Royal Dutch Shell ETP',
    name: 'Leverage Shares -1x Short Royal Dutch Shell ETP',
    symbol: 'SRDE.L',
  },
  {
    value: 'SRDS.L',
    label: 'SRDS.L,  Leverage Shares -1x Short Royal Dutch Shell ETP',
    name: 'Leverage Shares -1x Short Royal Dutch Shell ETP',
    symbol: 'SRDS.L',
  },
  {
    value: 'RDSS.L',
    label: 'RDSS.L,  Leverage Shares -1x Short Royal Dutch Shell ETP',
    name: 'Leverage Shares -1x Short Royal Dutch Shell ETP',
    symbol: 'RDSS.L',
  },
  {
    value: 'SVOD.L',
    label: 'SVOD.L,  Leverage Shares -1x Short Vodafone ETP',
    name: 'Leverage Shares -1x Short Vodafone ETP',
    symbol: 'SVOD.L',
  },
  {
    value: 'VODS.L',
    label: 'VODS.L,  Leverage Shares -1x Short Vodafone ETP',
    name: 'Leverage Shares -1x Short Vodafone ETP',
    symbol: 'VODS.L',
  },
  {
    value: 'SVDE.L',
    label: 'SVDE.L,  Leverage Shares -1x Short Vodafone ETP',
    name: 'Leverage Shares -1x Short Vodafone ETP',
    symbol: 'SVDE.L',
  },
  {
    value: '2AMD.L',
    label: '2AMD.L,  Leverage Shares 2x Advanced Micro Devices ETC',
    name: 'Leverage Shares 2x Advanced Micro Devices ETC',
    symbol: '2AMD.L',
  },
  {
    value: 'NVDE.L',
    label: 'NVDE.L,  Leverage Shares 2x NVIDIA ETC A',
    name: 'Leverage Shares 2x NVIDIA ETC A',
    symbol: 'NVDE.L',
  },
  {
    value: '2NVD.L',
    label: '2NVD.L,  Leverage Shares 2x NVIDIA ETC A',
    name: 'Leverage Shares 2x NVIDIA ETC A',
    symbol: '2NVD.L',
  },
  {
    value: 'NVD2.L',
    label: 'NVD2.L,  Leverage Shares 2x NVIDIA ETC A',
    name: 'Leverage Shares 2x NVIDIA ETC A',
    symbol: 'NVD2.L',
  },
  {
    value: 'BP3L.L',
    label: 'BP3L.L,  Leverage Shares 3x BP ETP',
    name: 'Leverage Shares 3x BP ETP',
    symbol: 'BP3L.L',
  },
  {
    value: '3BP.L',
    label: '3BP.L,  Leverage Shares 3x BP ETP',
    name: 'Leverage Shares 3x BP ETP',
    symbol: '3BP.L',
  },
  {
    value: '3BPE.L',
    label: '3BPE.L,  Leverage Shares 3x BP ETP',
    name: 'Leverage Shares 3x BP ETP',
    symbol: '3BPE.L',
  },
  {
    value: '3NFE.L',
    label: '3NFE.L,  Leverage Shares 3x Netflix ETC',
    name: 'Leverage Shares 3x Netflix ETC',
    symbol: '3NFE.L',
  },
  {
    value: '3NFL.L',
    label: '3NFL.L,  Leverage Shares 3x Netflix ETC',
    name: 'Leverage Shares 3x Netflix ETC',
    symbol: '3NFL.L',
  },
  {
    value: 'NFL3.L',
    label: 'NFL3.L,  Leverage Shares 3x Netflix ETC',
    name: 'Leverage Shares 3x Netflix ETC',
    symbol: 'NFL3.L',
  },
  {
    value: 'RSH3.L',
    label: 'RSH3.L,  Leverage Shares 3x Royal Dutch Shell ETP',
    name: 'Leverage Shares 3x Royal Dutch Shell ETP',
    symbol: 'RSH3.L',
  },
  {
    value: '3RDS.L',
    label: '3RDS.L,  Leverage Shares 3x Royal Dutch Shell ETP',
    name: 'Leverage Shares 3x Royal Dutch Shell ETP',
    symbol: '3RDS.L',
  },
  {
    value: '3RDE.L',
    label: '3RDE.L,  Leverage Shares 3x Royal Dutch Shell ETP',
    name: 'Leverage Shares 3x Royal Dutch Shell ETP',
    symbol: '3RDE.L',
  },
  {
    value: 'VDF3.L',
    label: 'VDF3.L,  Leverage Shares 3x Vodafone ETP',
    name: 'Leverage Shares 3x Vodafone ETP',
    symbol: 'VDF3.L',
  },
  {
    value: '3VOD.L',
    label: '3VOD.L,  Leverage Shares 3x Vodafone ETP',
    name: 'Leverage Shares 3x Vodafone ETP',
    symbol: '3VOD.L',
  },
  {
    value: '3VDE.L',
    label: '3VDE.L,  Leverage Shares 3x Vodafone ETP',
    name: 'Leverage Shares 3x Vodafone ETP',
    symbol: '3VDE.L',
  },
  {
    value: 'GHYU.L',
    label:
      'GHYU.L,  Lyxor Index Fund - Lyxor Global High Yield Sustainable Exposure UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor Global High Yield Sustainable Exposure UCITS ETF',
    symbol: 'GHYU.L',
  },
  {
    value: '5ESG.L',
    label:
      '5ESG.L,  UBS (Irl) ETF plc - S&P 500 ESG UCITS ETF Hedged to GBP A Dis',
    name: 'UBS (Irl) ETF plc - S&P 500 ESG UCITS ETF Hedged to GBP A Dis',
    symbol: '5ESG.L',
  },
  {
    value: 'UB06.L',
    label: 'UB06.L,  UBS(Lux)Fund Solutions – MSCI EMU UCITS ETF(EUR)A-dis',
    name: 'UBS(Lux)Fund Solutions – MSCI EMU UCITS ETF(EUR)A-dis',
    symbol: 'UB06.L',
  },
  {
    value: 'UB39.L',
    label:
      'UB39.L,  UBS(Lux)Fund Solutions – MSCI EMU Socially Responsible UCITS ETF(EUR)A-dis',
    name: 'UBS(Lux)Fund Solutions – MSCI EMU Socially Responsible UCITS ETF(EUR)A-dis',
    symbol: 'UB39.L',
  },
  {
    value: 'UB17.L',
    label: 'UB17.L,  UBS (Lux) Fund Solutions – MSCI EMU Value UCITS ETF',
    name: 'UBS (Lux) Fund Solutions – MSCI EMU Value UCITS ETF',
    symbol: 'UB17.L',
  },
  {
    value: 'UB12.L',
    label: 'UB12.L,  UBS (Lux) Fund Solutions – MSCI Europe UCITS ETF',
    name: 'UBS (Lux) Fund Solutions – MSCI Europe UCITS ETF',
    symbol: 'UB12.L',
  },
  {
    value: 'VUSC.L',
    label: 'VUSC.L,  Vanguard USD Corporate 1-3 year Bond UCITS ETF',
    name: 'Vanguard USD Corporate 1-3 year Bond UCITS ETF',
    symbol: 'VUSC.L',
  },
  {
    value: 'AGAP.L',
    label: 'AGAP.L,  WisdomTree Agriculture',
    name: 'WisdomTree Agriculture',
    symbol: 'AGAP.L',
  },
  {
    value: 'LAGR.L',
    label: 'LAGR.L,  WisdomTree Agriculture 2x Daily Leveraged',
    name: 'WisdomTree Agriculture 2x Daily Leveraged',
    symbol: 'LAGR.L',
  },
  {
    value: 'FAGR.L',
    label: 'FAGR.L,  WisdomTree Agriculture Longer Dated',
    name: 'WisdomTree Agriculture Longer Dated',
    symbol: 'FAGR.L',
  },
  {
    value: 'ALUM.L',
    label: 'ALUM.L,  WisdomTree Aluminium',
    name: 'WisdomTree Aluminium',
    symbol: 'ALUM.L',
  },
  {
    value: 'LALU.L',
    label: 'LALU.L,  WisdomTree Aluminium 2x Daily Leveraged',
    name: 'WisdomTree Aluminium 2x Daily Leveraged',
    symbol: 'LALU.L',
  },
  {
    value: 'BRNT.L',
    label: 'BRNT.L,  WisdomTree Brent Crude Oil',
    name: 'WisdomTree Brent Crude Oil',
    symbol: 'BRNT.L',
  },
  {
    value: 'SBRT.L',
    label: 'SBRT.L,  WisdomTree Brent Crude Oil 1x Daily Short',
    name: 'WisdomTree Brent Crude Oil 1x Daily Short',
    symbol: 'SBRT.L',
  },
  {
    value: 'LBRT.L',
    label: 'LBRT.L,  WisdomTree Brent Crude Oil 2x Daily Leveraged',
    name: 'WisdomTree Brent Crude Oil 2x Daily Leveraged',
    symbol: 'LBRT.L',
  },
  {
    value: 'FBRT.L',
    label: 'FBRT.L,  WisdomTree Brent Crude Oil Longer Dated',
    name: 'WisdomTree Brent Crude Oil Longer Dated',
    symbol: 'FBRT.L',
  },
  {
    value: 'XFRM.L',
    label: 'XFRM.L,  WisdomTree Broad Commodities Ex-Agriculture and Livestock',
    name: 'WisdomTree Broad Commodities Ex-Agriculture and Livestock',
    symbol: 'XFRM.L',
  },
  {
    value: 'AGCP.L',
    label: 'AGCP.L,  WisdomTree Broad Commodities',
    name: 'WisdomTree Broad Commodities',
    symbol: 'AGCP.L',
  },
  {
    value: 'AIGC.L',
    label: 'AIGC.L,  WisdomTree Broad Commodities',
    name: 'WisdomTree Broad Commodities',
    symbol: 'AIGC.L',
  },
  {
    value: 'SALL.L',
    label: 'SALL.L,  WisdomTree Broad Commodities 1x Daily Short',
    name: 'WisdomTree Broad Commodities 1x Daily Short',
    symbol: 'SALL.L',
  },
  {
    value: 'FAIG.L',
    label: 'FAIG.L,  WisdomTree Broad Commodities Longer Dated',
    name: 'WisdomTree Broad Commodities Longer Dated',
    symbol: 'FAIG.L',
  },
  {
    value: 'CARP.L',
    label: 'CARP.L,  WisdomTree Carbon',
    name: 'WisdomTree Carbon',
    symbol: 'CARP.L',
  },
  {
    value: 'CARB.L',
    label: 'CARB.L,  WisdomTree Carbon',
    name: 'WisdomTree Carbon',
    symbol: 'CARB.L',
  },
  {
    value: 'COCO.L',
    label: 'COCO.L,  WisdomTree Cocoa',
    name: 'WisdomTree Cocoa',
    symbol: 'COCO.L',
  },
  {
    value: 'LCOC.L',
    label: 'LCOC.L,  WisdomTree Cocoa 2x Daily Leveraged',
    name: 'WisdomTree Cocoa 2x Daily Leveraged',
    symbol: 'LCOC.L',
  },
  {
    value: 'COFF.L',
    label: 'COFF.L,  WisdomTree Coffee',
    name: 'WisdomTree Coffee',
    symbol: 'COFF.L',
  },
  {
    value: 'LCFE.L',
    label: 'LCFE.L,  WisdomTree Coffee 2x Daily Leveraged',
    name: 'WisdomTree Coffee 2x Daily Leveraged',
    symbol: 'LCFE.L',
  },
  {
    value: '3CFL.L',
    label: '3CFL.L,  WisdomTree Coffee 3x Daily Leveraged',
    name: 'WisdomTree Coffee 3x Daily Leveraged',
    symbol: '3CFL.L',
  },
  {
    value: 'COPA.L',
    label: 'COPA.L,  WisdomTree Copper',
    name: 'WisdomTree Copper',
    symbol: 'COPA.L',
  },
  {
    value: 'SCOP.L',
    label: 'SCOP.L,  WisdomTree Copper 1x Daily Short',
    name: 'WisdomTree Copper 1x Daily Short',
    symbol: 'SCOP.L',
  },
  {
    value: 'LCOP.L',
    label: 'LCOP.L,  WisdomTree Copper 2x Daily Leveraged',
    name: 'WisdomTree Copper 2x Daily Leveraged',
    symbol: 'LCOP.L',
  },
  {
    value: 'CORN.L',
    label: 'CORN.L,  WisdomTree Corn',
    name: 'WisdomTree Corn',
    symbol: 'CORN.L',
  },
  {
    value: 'LCOR.L',
    label: 'LCOR.L,  WisdomTree Corn 2x Daily Leveraged',
    name: 'WisdomTree Corn 2x Daily Leveraged',
    symbol: 'LCOR.L',
  },
  {
    value: 'COTN.L',
    label: 'COTN.L,  WisdomTree Cotton',
    name: 'WisdomTree Cotton',
    symbol: 'COTN.L',
  },
  {
    value: 'AIGE.L',
    label: 'AIGE.L,  WisdomTree Energy',
    name: 'WisdomTree Energy',
    symbol: 'AIGE.L',
  },
  {
    value: 'ENEF.L',
    label: 'ENEF.L,  WisdomTree Energy Longer Dated',
    name: 'WisdomTree Energy Longer Dated',
    symbol: 'ENEF.L',
  },
  {
    value: 'UGAS.L',
    label: 'UGAS.L,  WisdomTree Gasoline',
    name: 'WisdomTree Gasoline',
    symbol: 'UGAS.L',
  },
  {
    value: 'BULL.L',
    label: 'BULL.L,  WisdomTree Gold',
    name: 'WisdomTree Gold',
    symbol: 'BULL.L',
  },
  {
    value: 'BULP.L',
    label: 'BULP.L,  WisdomTree Gold',
    name: 'WisdomTree Gold',
    symbol: 'BULP.L',
  },
  {
    value: 'SBUL.L',
    label: 'SBUL.L,  WisdomTree Gold 1x Daily Short',
    name: 'WisdomTree Gold 1x Daily Short',
    symbol: 'SBUL.L',
  },
  {
    value: 'LBUL.L',
    label: 'LBUL.L,  WisdomTree Gold 2x Daily Leveraged',
    name: 'WisdomTree Gold 2x Daily Leveraged',
    symbol: 'LBUL.L',
  },
  {
    value: 'AGGP.L',
    label: 'AGGP.L,  WisdomTree Grains',
    name: 'WisdomTree Grains',
    symbol: 'AGGP.L',
  },
  {
    value: 'AIGG.L',
    label: 'AIGG.L,  WisdomTree Grains',
    name: 'WisdomTree Grains',
    symbol: 'AIGG.L',
  },
  {
    value: 'HEAT.L',
    label: 'HEAT.L,  WisdomTree Heating Oil',
    name: 'WisdomTree Heating Oil',
    symbol: 'HEAT.L',
  },
  {
    value: 'AIGI.L',
    label: 'AIGI.L,  WisdomTree Industrial Metals',
    name: 'WisdomTree Industrial Metals',
    symbol: 'AIGI.L',
  },
  {
    value: 'FIND.L',
    label: 'FIND.L,  WisdomTree Industrial Metals Longer Dated',
    name: 'WisdomTree Industrial Metals Longer Dated',
    symbol: 'FIND.L',
  },
  {
    value: 'LEED.L',
    label: 'LEED.L,  WisdomTree Lead',
    name: 'WisdomTree Lead',
    symbol: 'LEED.L',
  },
  {
    value: 'HOGS.L',
    label: 'HOGS.L,  WisdomTree Lean Hogs',
    name: 'WisdomTree Lean Hogs',
    symbol: 'HOGS.L',
  },
  {
    value: 'AIGL.L',
    label: 'AIGL.L,  WisdomTree Livestock',
    name: 'WisdomTree Livestock',
    symbol: 'AIGL.L',
  },
  {
    value: 'NGSP.L',
    label: 'NGSP.L,  WisdomTree Natural Gas',
    name: 'WisdomTree Natural Gas',
    symbol: 'NGSP.L',
  },
  {
    value: 'SNGA.L',
    label: 'SNGA.L,  WisdomTree Natural Gas 1x Daily Short',
    name: 'WisdomTree Natural Gas 1x Daily Short',
    symbol: 'SNGA.L',
  },
  {
    value: 'LNGA.L',
    label: 'LNGA.L,  WisdomTree Natural Gas 2x Daily Leveraged',
    name: 'WisdomTree Natural Gas 2x Daily Leveraged',
    symbol: 'LNGA.L',
  },
  {
    value: 'NICK.L',
    label: 'NICK.L,  WisdomTree Nickel',
    name: 'WisdomTree Nickel',
    symbol: 'NICK.L',
  },
  {
    value: 'SNIK.L',
    label: 'SNIK.L,  WisdomTree Nickel 1x Daily Short',
    name: 'WisdomTree Nickel 1x Daily Short',
    symbol: 'SNIK.L',
  },
  {
    value: 'LNIK.L',
    label: 'LNIK.L,  WisdomTree Nickel 2x Daily Leveraged',
    name: 'WisdomTree Nickel 2x Daily Leveraged',
    symbol: 'LNIK.L',
  },
  {
    value: 'AIGO.L',
    label: 'AIGO.L,  WisdomTree Petroleum',
    name: 'WisdomTree Petroleum',
    symbol: 'AIGO.L',
  },
  {
    value: 'LPET.L',
    label: 'LPET.L,  WisdomTree Petroleum 2x Daily Leveraged',
    name: 'WisdomTree Petroleum 2x Daily Leveraged',
    symbol: 'LPET.L',
  },
  {
    value: 'LPLA.L',
    label: 'LPLA.L,  WisdomTree Platinum 2x Daily Leveraged',
    name: 'WisdomTree Platinum 2x Daily Leveraged',
    symbol: 'LPLA.L',
  },
  {
    value: 'AIGP.L',
    label: 'AIGP.L,  WisdomTree Precious Metals',
    name: 'WisdomTree Precious Metals',
    symbol: 'AIGP.L',
  },
  {
    value: 'SLVR.L',
    label: 'SLVR.L,  WisdomTree Silver',
    name: 'WisdomTree Silver',
    symbol: 'SLVR.L',
  },
  {
    value: 'SSIL.L',
    label: 'SSIL.L,  WisdomTree Silver 1x Daily Short',
    name: 'WisdomTree Silver 1x Daily Short',
    symbol: 'SSIL.L',
  },
  {
    value: 'LSIL.L',
    label: 'LSIL.L,  WisdomTree Silver 2x Daily Leveraged',
    name: 'WisdomTree Silver 2x Daily Leveraged',
    symbol: 'LSIL.L',
  },
  {
    value: 'AIGS.L',
    label: 'AIGS.L,  WisdomTree Softs',
    name: 'WisdomTree Softs',
    symbol: 'AIGS.L',
  },
  {
    value: 'SOYO.L',
    label: 'SOYO.L,  WisdomTree Soybean Oil',
    name: 'WisdomTree Soybean Oil',
    symbol: 'SOYO.L',
  },
  {
    value: 'SUGA.L',
    label: 'SUGA.L,  WisdomTree Sugar',
    name: 'WisdomTree Sugar',
    symbol: 'SUGA.L',
  },
  {
    value: 'LSUG.L',
    label: 'LSUG.L,  WisdomTree Sugar 2x Daily Leveraged',
    name: 'WisdomTree Sugar 2x Daily Leveraged',
    symbol: 'LSUG.L',
  },
  {
    value: '3SUL.L',
    label: '3SUL.L,  WisdomTree Sugar 3x Daily Leveraged',
    name: 'WisdomTree Sugar 3x Daily Leveraged',
    symbol: '3SUL.L',
  },
  {
    value: 'TINM.L',
    label: 'TINM.L,  WisdomTree Tin',
    name: 'WisdomTree Tin',
    symbol: 'TINM.L',
  },
  {
    value: 'LWEA.L',
    label: 'LWEA.L,  WisdomTree Wheat 2x Daily Leveraged',
    name: 'WisdomTree Wheat 2x Daily Leveraged',
    symbol: 'LWEA.L',
  },
  {
    value: '3WHL.L',
    label: '3WHL.L,  WisdomTree Wheat 3x Daily Leveraged',
    name: 'WisdomTree Wheat 3x Daily Leveraged',
    symbol: '3WHL.L',
  },
  {
    value: 'CRUD.L',
    label: 'CRUD.L,  WisdomTree WTI Crude Oil',
    name: 'WisdomTree WTI Crude Oil',
    symbol: 'CRUD.L',
  },
  {
    value: 'SOIL.L',
    label: 'SOIL.L,  WisdomTree WTI Crude Oil 1x Daily Short',
    name: 'WisdomTree WTI Crude Oil 1x Daily Short',
    symbol: 'SOIL.L',
  },
  {
    value: 'LOIL.L',
    label: 'LOIL.L,  WisdomTree WTI Crude Oil 2x Daily Leveraged',
    name: 'WisdomTree WTI Crude Oil 2x Daily Leveraged',
    symbol: 'LOIL.L',
  },
  {
    value: 'FCRU.L',
    label: 'FCRU.L,  WisdomTree WTI Crude Oil Longer Dated',
    name: 'WisdomTree WTI Crude Oil Longer Dated',
    symbol: 'FCRU.L',
  },
  {
    value: 'ZINC.L',
    label: 'ZINC.L,  WisdomTree Zinc',
    name: 'WisdomTree Zinc',
    symbol: 'ZINC.L',
  },
  {
    value: 'LCNY.L',
    label: 'LCNY.L,  WisdomTree Long CNY Short USD',
    name: 'WisdomTree Long CNY Short USD',
    symbol: 'LCNY.L',
  },
  {
    value: 'EUP3.L',
    label: 'EUP3.L,  WisdomTree Long EUR Short GBP 3x Daily',
    name: 'WisdomTree Long EUR Short GBP 3x Daily',
    symbol: 'EUP3.L',
  },
  {
    value: 'EUP5.L',
    label: 'EUP5.L,  WisdomTree Long EUR Short GBP 5x Daily',
    name: 'WisdomTree Long EUR Short GBP 5x Daily',
    symbol: 'EUP5.L',
  },
  {
    value: 'LEUR.L',
    label: 'LEUR.L,  WisdomTree Long EUR Short USD',
    name: 'WisdomTree Long EUR Short USD',
    symbol: 'LEUR.L',
  },
  {
    value: 'LEU3.L',
    label: 'LEU3.L,  WisdomTree Long EUR Short USD 3x Daily',
    name: 'WisdomTree Long EUR Short USD 3x Daily',
    symbol: 'LEU3.L',
  },
  {
    value: 'SUD5.L',
    label: 'SUD5.L,  WisdomTree Long EUR Short USD 5x Daily',
    name: 'WisdomTree Long EUR Short USD 5x Daily',
    symbol: 'SUD5.L',
  },
  {
    value: 'LGBP.L',
    label: 'LGBP.L,  WisdomTree Long GBP Short USD',
    name: 'WisdomTree Long GBP Short USD',
    symbol: 'LGBP.L',
  },
  {
    value: 'LGB3.L',
    label: 'LGB3.L,  WisdomTree Long GBP Short USD 3x Daily',
    name: 'WisdomTree Long GBP Short USD 3x Daily',
    symbol: 'LGB3.L',
  },
  {
    value: 'LJP3.L',
    label: 'LJP3.L,  WisdomTree Long JPY Short USD 3x Daily',
    name: 'WisdomTree Long JPY Short USD 3x Daily',
    symbol: 'LJP3.L',
  },
  {
    value: 'GBUS.L',
    label: 'GBUS.L,  WisdomTree Long USD Short GBP',
    name: 'WisdomTree Long USD Short GBP',
    symbol: 'GBUS.L',
  },
  {
    value: 'USP3.L',
    label: 'USP3.L,  WisdomTree Long USD Short GBP 3x Daily',
    name: 'WisdomTree Long USD Short GBP 3x Daily',
    symbol: 'USP3.L',
  },
  {
    value: 'USP5.L',
    label: 'USP5.L,  WisdomTree Long USD Short GBP 5x Daily',
    name: 'WisdomTree Long USD Short GBP 5x Daily',
    symbol: 'USP5.L',
  },
  {
    value: 'URGB.L',
    label: 'URGB.L,  WisdomTree Short EUR Long GBP',
    name: 'WisdomTree Short EUR Long GBP',
    symbol: 'URGB.L',
  },
  {
    value: 'SUP3.L',
    label: 'SUP3.L,  WisdomTree Short EUR Long GBP 3x Daily',
    name: 'WisdomTree Short EUR Long GBP 3x Daily',
    symbol: 'SUP3.L',
  },
  {
    value: 'SEU3.L',
    label: 'SEU3.L,  WisdomTree Short EUR Long USD 3x Daily',
    name: 'WisdomTree Short EUR Long USD 3x Daily',
    symbol: 'SEU3.L',
  },
  {
    value: 'LUD5.L',
    label: 'LUD5.L,  WisdomTree Short EUR Long USD 5x Daily',
    name: 'WisdomTree Short EUR Long USD 5x Daily',
    symbol: 'LUD5.L',
  },
  {
    value: 'SGBP.L',
    label: 'SGBP.L,  WisdomTree Short GBP Long USD',
    name: 'WisdomTree Short GBP Long USD',
    symbol: 'SGBP.L',
  },
  {
    value: 'SGB3.L',
    label: 'SGB3.L,  WisdomTree Short GBP Long USD 3x Daily',
    name: 'WisdomTree Short GBP Long USD 3x Daily',
    symbol: 'SGB3.L',
  },
  {
    value: 'JPGB.L',
    label: 'JPGB.L,  WisdomTree Short JPY Long GBP',
    name: 'WisdomTree Short JPY Long GBP',
    symbol: 'JPGB.L',
  },
  {
    value: 'SJPY.L',
    label: 'SJPY.L,  WisdomTree Short JPY Long USD',
    name: 'WisdomTree Short JPY Long USD',
    symbol: 'SJPY.L',
  },
  {
    value: 'SJP3.L',
    label: 'SJP3.L,  WisdomTree Short JPY Long USD 3x Daily',
    name: 'WisdomTree Short JPY Long USD 3x Daily',
    symbol: 'SJP3.L',
  },
  {
    value: 'USGB.L',
    label: 'USGB.L,  WisdomTree Short USD Long GBP',
    name: 'WisdomTree Short USD Long GBP',
    symbol: 'USGB.L',
  },
  {
    value: 'PUS3.L',
    label: 'PUS3.L,  WisdomTree Short USD Long GBP 3x Daily',
    name: 'WisdomTree Short USD Long GBP 3x Daily',
    symbol: 'PUS3.L',
  },
  {
    value: 'PBRT.L',
    label: 'PBRT.L,  WisdomTree Brent Crude Oil - GBP Daily Hedged',
    name: 'WisdomTree Brent Crude Oil - GBP Daily Hedged',
    symbol: 'PBRT.L',
  },
  {
    value: 'PIMT.L',
    label: 'PIMT.L,  WisdomTree Industrial Metals - GBP Daily Hedged',
    name: 'WisdomTree Industrial Metals - GBP Daily Hedged',
    symbol: 'PIMT.L',
  },
  {
    value: 'PCRD.L',
    label: 'PCRD.L,  WisdomTree WTI Crude Oil - GBP Daily Hedged',
    name: 'WisdomTree WTI Crude Oil - GBP Daily Hedged',
    symbol: 'PCRD.L',
  },
  {
    value: 'GBSP.L',
    label: 'GBSP.L,  WisdomTree Physical Gold - GBP Daily Hedged',
    name: 'WisdomTree Physical Gold - GBP Daily Hedged',
    symbol: 'GBSP.L',
  },
  {
    value: 'WGLD.L',
    label: 'WGLD.L,  WisdomTree Core Physical Gold USD ETC',
    name: 'WisdomTree Core Physical Gold USD ETC',
    symbol: 'WGLD.L',
  },
  {
    value: 'PHAU.L',
    label: 'PHAU.L,  WisdomTree Physical Gold',
    name: 'WisdomTree Physical Gold',
    symbol: 'PHAU.L',
  },
  {
    value: 'PHGP.L',
    label: 'PHGP.L,  WisdomTree Physical Gold',
    name: 'WisdomTree Physical Gold',
    symbol: 'PHGP.L',
  },
  {
    value: 'PHPD.L',
    label: 'PHPD.L,  WisdomTree Physical Palladium',
    name: 'WisdomTree Physical Palladium',
    symbol: 'PHPD.L',
  },
  {
    value: 'PHPT.L',
    label: 'PHPT.L,  WisdomTree Physical Platinum',
    name: 'WisdomTree Physical Platinum',
    symbol: 'PHPT.L',
  },
  {
    value: 'PHPM.L',
    label: 'PHPM.L,  WisdomTree Physical Precious Metals',
    name: 'WisdomTree Physical Precious Metals',
    symbol: 'PHPM.L',
  },
  {
    value: 'PHPP.L',
    label: 'PHPP.L,  WisdomTree Physical Precious Metals',
    name: 'WisdomTree Physical Precious Metals',
    symbol: 'PHPP.L',
  },
  {
    value: 'PHAG.L',
    label: 'PHAG.L,  WisdomTree Physical Silver',
    name: 'WisdomTree Physical Silver',
    symbol: 'PHAG.L',
  },
  {
    value: 'PHSP.L',
    label: 'PHSP.L,  WisdomTree Physical Silver',
    name: 'WisdomTree Physical Silver',
    symbol: 'PHSP.L',
  },
  {
    value: '3BSR.L',
    label: '3BSR.L,  WisdomTree Brent Crude Oil 3x Daily Short',
    name: 'WisdomTree Brent Crude Oil 3x Daily Short',
    symbol: '3BSR.L',
  },
  {
    value: '3HCS.L',
    label: '3HCS.L,  WisdomTree Copper 3x Daily Short',
    name: 'WisdomTree Copper 3x Daily Short',
    symbol: '3HCS.L',
  },
  {
    value: '3DES.L',
    label: '3DES.L,  WisdomTree DAX 3x Daily Short',
    name: 'WisdomTree DAX 3x Daily Short',
    symbol: '3DES.L',
  },
  {
    value: '3EML.L',
    label: '3EML.L,  WisdomTree Emerging Markets 3x Daily Leveraged',
    name: 'WisdomTree Emerging Markets 3x Daily Leveraged',
    symbol: '3EML.L',
  },
  {
    value: '3EMS.L',
    label: '3EMS.L,  WisdomTree Emerging Markets 3x Daily Short',
    name: 'WisdomTree Emerging Markets 3x Daily Short',
    symbol: '3EMS.L',
  },
  {
    value: 'QQQ3.L',
    label: 'QQQ3.L,  WisdomTree NASDAQ 100 3x Daily Leveraged',
    name: 'WisdomTree NASDAQ 100 3x Daily Leveraged',
    symbol: 'QQQ3.L',
  },
  {
    value: 'LQQ3.L',
    label: 'LQQ3.L,  WisdomTree NASDAQ 100 3x Daily Leveraged',
    name: 'WisdomTree NASDAQ 100 3x Daily Leveraged',
    symbol: 'LQQ3.L',
  },
  {
    value: '3NGL.L',
    label: '3NGL.L,  WisdomTree Natural Gas 3x Daily Leveraged',
    name: 'WisdomTree Natural Gas 3x Daily Leveraged',
    symbol: '3NGL.L',
  },
  {
    value: '3LGS.L',
    label: '3LGS.L,  WisdomTree Natural Gas 3x Daily Short',
    name: 'WisdomTree Natural Gas 3x Daily Short',
    symbol: '3LGS.L',
  },
  {
    value: '3NGS.L',
    label: '3NGS.L,  WisdomTree Natural Gas 3x Daily Short',
    name: 'WisdomTree Natural Gas 3x Daily Short',
    symbol: '3NGS.L',
  },
  {
    value: '3ULS.L',
    label: '3ULS.L,  WisdomTree S&P 500 3x Daily Short',
    name: 'WisdomTree S&P 500 3x Daily Short',
    symbol: '3ULS.L',
  },
  {
    value: '3USS.L',
    label: '3USS.L,  WisdomTree S&P 500 3x Daily Short',
    name: 'WisdomTree S&P 500 3x Daily Short',
    symbol: '3USS.L',
  },
  {
    value: 'VILX.L',
    label: 'VILX.L,  S&P 500 VIX Short-term Futures Index (0930-1600 EST)',
    name: 'S&P 500 VIX Short-term Futures Index (0930-1600 EST)',
    symbol: 'VILX.L',
  },
  {
    value: 'XGDG.L',
    label: 'XGDG.L,  Xtrackers IE Physical Gold GBP Hedged ETC Securities 6',
    name: 'Xtrackers IE Physical Gold GBP Hedged ETC Securities 6',
    symbol: 'XGDG.L',
  },
  {
    value: 'XGDU.L',
    label: 'XGDU.L,  Xtrackers IE Physical Gold ETC',
    name: 'Xtrackers IE Physical Gold ETC',
    symbol: 'XGDU.L',
  },
  {
    value: 'XPPT.L',
    label: 'XPPT.L,  Xtrackers ETC plc - Xtrackers IE Physical Platinum ETC 1',
    name: 'Xtrackers ETC plc - Xtrackers IE Physical Platinum ETC 1',
    symbol: 'XPPT.L',
  },
  {
    value: 'XSLR.L',
    label: 'XSLR.L,  Xtrackers IE Physical Silver ETC',
    name: 'Xtrackers IE Physical Silver ETC',
    symbol: 'XSLR.L',
  },
  {
    value: 'SHEL.L',
    label: 'SHEL.L,  Shell plc',
    name: 'Shell plc',
    symbol: 'SHEL.L',
  },
  {
    value: 'ATCO-B.ST',
    label: 'ATCO-B.ST,  Atlas Copco AB',
    name: 'Atlas Copco AB',
    symbol: 'ATCO-B.ST',
  },
  {
    value: 'WE.V',
    label: 'WE.V,  WeCommerce Holdings Ltd.',
    name: 'WeCommerce Holdings Ltd.',
    symbol: 'WE.V',
  },
  {
    value: 'NLH.V',
    label: 'NLH.V,  Nova Leap Health Corp.',
    name: 'Nova Leap Health Corp.',
    symbol: 'NLH.V',
  },
  {
    value: 'SHEL',
    label: 'SHEL,  Shell plc',
    name: 'Shell plc',
    symbol: 'SHEL',
  },
  {
    value: 'ECOM.V',
    label: 'ECOM.V,  Emerge Commerce Ltd.',
    name: 'Emerge Commerce Ltd.',
    symbol: 'ECOM.V',
  },
  {
    value: 'TOI.V',
    label: 'TOI.V,  Topicus.com Inc.',
    name: 'Topicus.com Inc.',
    symbol: 'TOI.V',
  },
  {
    value: 'TRIFOR.CO',
    label: 'TRIFOR.CO,  Trifork Holding AG',
    name: 'Trifork Holding AG',
    symbol: 'TRIFOR.CO',
  },
  {
    value: 'DNTL.TO',
    label: 'DNTL.TO,  dentalcorp Holdings Ltd.',
    name: 'dentalcorp Holdings Ltd.',
    symbol: 'DNTL.TO',
  },
  {
    value: 'NBLY.TO',
    label: 'NBLY.TO,  Neighbourly Pharmacy Inc.',
    name: 'Neighbourly Pharmacy Inc.',
    symbol: 'NBLY.TO',
  },
  {
    value: 'PRM',
    label: 'PRM,  Perimeter Solutions, SA',
    name: 'Perimeter Solutions, SA',
    symbol: 'PRM',
  },
  {
    value: 'PLRB.V',
    label: 'PLRB.V,  Pluribus Technologies Corp.',
    name: 'Pluribus Technologies Corp.',
    symbol: 'PLRB.V',
  },
  {
    value: 'ERE-UN.TO',
    label: 'ERE-UN.TO,  European Residential Real Estate Investment Trust',
    name: 'European Residential Real Estate Investment Trust',
    symbol: 'ERE-UN.TO',
  },
  {
    value: 'SPCX',
    label: 'SPCX,  AXS SPAC and New Issue ETF',
    name: 'AXS SPAC and New Issue ETF',
    symbol: 'SPCX',
  },
  {
    value: 'DSPC',
    label: 'DSPC,  AXS De-SPAC ETF',
    name: 'AXS De-SPAC ETF',
    symbol: 'DSPC',
  },
  {
    value: 'FOMO',
    label: 'FOMO,  AXS FOMO ETF',
    name: 'AXS FOMO ETF',
    symbol: 'FOMO',
  },
  {
    value: 'MBG.DE',
    label: 'MBG.DE,  Mercedes-Benz Group AG',
    name: 'Mercedes-Benz Group AG',
    symbol: 'MBG.DE',
  },
  {
    value: 'SBKO',
    label: 'SBKO,  Summit Bank Group, Inc.',
    name: 'Summit Bank Group, Inc.',
    symbol: 'SBKO',
  },
  {
    value: 'CCRD',
    label: 'CCRD,  CoreCard Corporation',
    name: 'CoreCard Corporation',
    symbol: 'CCRD',
  },
  {
    value: 'PDYPF',
    label: 'PDYPF,  Flutter Entertainment plc',
    name: 'Flutter Entertainment plc',
    symbol: 'PDYPF',
  },
  {
    value: 'AKBTY',
    label: 'AKBTY,  Akbank T.A.S.',
    name: 'Akbank T.A.S.',
    symbol: 'AKBTY',
  },
  {
    value: 'NGLOY',
    label: 'NGLOY,  Anglo American plc',
    name: 'Anglo American plc',
    symbol: 'NGLOY',
  },
  {
    value: 'ALPMY',
    label: 'ALPMY,  Astellas Pharma Inc.',
    name: 'Astellas Pharma Inc.',
    symbol: 'ALPMY',
  },
  {
    value: 'ADPXY',
    label: 'ADPXY,  Audio Pixels Holdings Limited',
    name: 'Audio Pixels Holdings Limited',
    symbol: 'ADPXY',
  },
  {
    value: 'AXAHY',
    label: 'AXAHY,  AXA SA',
    name: 'AXA SA',
    symbol: 'AXAHY',
  },
  {
    value: 'CHRYY',
    label: 'CHRYY,  Chorus Limited',
    name: 'Chorus Limited',
    symbol: 'CHRYY',
  },
  {
    value: 'CLVLY',
    label: 'CLVLY,  Clinuvel Pharmaceuticals Limited',
    name: 'Clinuvel Pharmaceuticals Limited',
    symbol: 'CLVLY',
  },
  {
    value: 'CMSQY',
    label: 'CMSQY,  Computershare Limited',
    name: 'Computershare Limited',
    symbol: 'CMSQY',
  },
  {
    value: 'CYBQY',
    label: 'CYBQY,  CYBERDYNE Inc.',
    name: 'CYBERDYNE Inc.',
    symbol: 'CYBQY',
  },
  {
    value: 'DASTY',
    label: 'DASTY,  Dassault Systèmes SE',
    name: 'Dassault Systèmes SE',
    symbol: 'DASTY',
  },
  {
    value: 'DTEGY',
    label: 'DTEGY,  Deutsche Telekom AG',
    name: 'Deutsche Telekom AG',
    symbol: 'DTEGY',
  },
  {
    value: 'XNGSY',
    label: 'XNGSY,  ENN Energy Holdings Limited',
    name: 'ENN Energy Holdings Limited',
    symbol: 'XNGSY',
  },
  {
    value: 'EONGY',
    label: 'EONGY,  E.ON SE',
    name: 'E.ON SE',
    symbol: 'EONGY',
  },
  {
    value: 'FANUY',
    label: 'FANUY,  Fanuc Corporation',
    name: 'Fanuc Corporation',
    symbol: 'FANUY',
  },
  {
    value: 'FSUGY',
    label: 'FSUGY,  Fortescue Metals Group Limited',
    name: 'Fortescue Metals Group Limited',
    symbol: 'FSUGY',
  },
  {
    value: 'FJTSY',
    label: 'FJTSY,  Fujitsu Limited',
    name: 'Fujitsu Limited',
    symbol: 'FJTSY',
  },
  {
    value: 'HRST',
    label: 'HRST,  Harvest Oil & Gas Corp.',
    name: 'Harvest Oil & Gas Corp.',
    symbol: 'HRST',
  },
  {
    value: 'HLTOY',
    label: 'HLTOY,  Hellenic Telecommunications Organization S.A.',
    name: 'Hellenic Telecommunications Organization S.A.',
    symbol: 'HLTOY',
  },
  {
    value: 'HTHIY',
    label: 'HTHIY,  Hitachi, Ltd.',
    name: 'Hitachi, Ltd.',
    symbol: 'HTHIY',
  },
  {
    value: 'HKXCY',
    label: 'HKXCY,  Hong Kong Exchanges and Clearing Limited',
    name: 'Hong Kong Exchanges and Clearing Limited',
    symbol: 'HKXCY',
  },
  {
    value: 'JBSAY',
    label: 'JBSAY,  JBS S.A.',
    name: 'JBS S.A.',
    symbol: 'JBSAY',
  },
  {
    value: 'JAPAY',
    label: 'JAPAY,  Japan Tobacco Inc.',
    name: 'Japan Tobacco Inc.',
    symbol: 'JAPAY',
  },
  {
    value: 'JMHLY',
    label: 'JMHLY,  Jardine Matheson Holdings Limited',
    name: 'Jardine Matheson Holdings Limited',
    symbol: 'JMHLY',
  },
  {
    value: 'KUBTY',
    label: 'KUBTY,  Kubota Corporation',
    name: 'Kubota Corporation',
    symbol: 'KUBTY',
  },
  {
    value: 'KYOCY',
    label: 'KYOCY,  Kyocera Corporation',
    name: 'Kyocera Corporation',
    symbol: 'KYOCY',
  },
  {
    value: 'MKTAY',
    label: 'MKTAY,  Makita Corporation',
    name: 'Makita Corporation',
    symbol: 'MKTAY',
  },
  {
    value: 'MZDAY',
    label: 'MZDAY,  Mazda Motor Corporation',
    name: 'Mazda Motor Corporation',
    symbol: 'MZDAY',
  },
  {
    value: 'MITEY',
    label: 'MITEY,  Mitsubishi Estate Co., Ltd.',
    name: 'Mitsubishi Estate Co., Ltd.',
    symbol: 'MITEY',
  },
  {
    value: 'MCHOY',
    label: 'MCHOY,  MultiChoice Group Limited',
    name: 'MultiChoice Group Limited',
    symbol: 'MCHOY',
  },
  {
    value: 'MRAAY',
    label: 'MRAAY,  Murata Manufacturing Co., Ltd.',
    name: 'Murata Manufacturing Co., Ltd.',
    symbol: 'MRAAY',
  },
  {
    value: 'NABZY',
    label: 'NABZY,  National Australia Bank Limited',
    name: 'National Australia Bank Limited',
    symbol: 'NABZY',
  },
  {
    value: 'NCMGY',
    label: 'NCMGY,  Newcrest Mining Limited',
    name: 'Newcrest Mining Limited',
    symbol: 'NCMGY',
  },
  {
    value: 'NHYDY',
    label: 'NHYDY,  Norsk Hydro ASA',
    name: 'Norsk Hydro ASA',
    symbol: 'NHYDY',
  },
  {
    value: 'PCRFY',
    label: 'PCRFY,  Panasonic Holdings Corporation',
    name: 'Panasonic Holdings Corporation',
    symbol: 'PCRFY',
  },
  {
    value: 'SSDOY',
    label: 'SSDOY,  Shiseido Company, Limited',
    name: 'Shiseido Company, Limited',
    symbol: 'SSDOY',
  },
  {
    value: 'SILXY',
    label: 'SILXY,  Silex Systems Limited',
    name: 'Silex Systems Limited',
    symbol: 'SILXY',
  },
  {
    value: 'SMSMY',
    label: 'SMSMY,  Sims Limited',
    name: 'Sims Limited',
    symbol: 'SMSMY',
  },
  {
    value: 'HWNI',
    label: 'HWNI,  High Wire Networks, Inc.',
    name: 'High Wire Networks, Inc.',
    symbol: 'HWNI',
  },
  {
    value: 'SUBCY',
    label: 'SUBCY,  Subsea 7 S.A.',
    name: 'Subsea 7 S.A.',
    symbol: 'SUBCY',
  },
  {
    value: 'TOSYY',
    label: 'TOSYY,  Toshiba Corporation',
    name: 'Toshiba Corporation',
    symbol: 'TOSYY',
  },
  {
    value: 'TSRYY',
    label: 'TSRYY,  Treasury Wine Estates Limited',
    name: 'Treasury Wine Estates Limited',
    symbol: 'TSRYY',
  },
  {
    value: 'TSGTY',
    label: 'TSGTY,  Tsingtao Brewery Company Limited',
    name: 'Tsingtao Brewery Company Limited',
    symbol: 'TSGTY',
  },
  {
    value: 'UOVEY',
    label: 'UOVEY,  United Overseas Bank Limited',
    name: 'United Overseas Bank Limited',
    symbol: 'UOVEY',
  },
  {
    value: 'VEOEY',
    label: 'VEOEY,  Veolia Environnement S.A.',
    name: 'Veolia Environnement S.A.',
    symbol: 'VEOEY',
  },
  {
    value: 'MRGO',
    label: 'MRGO,  Margo Caribe Inc.',
    name: 'Margo Caribe Inc.',
    symbol: 'MRGO',
  },
  {
    value: 'AMFC',
    label: 'AMFC,  AMB Financial Corp.',
    name: 'AMB Financial Corp.',
    symbol: 'AMFC',
  },
  {
    value: 'ACMTA',
    label: 'ACMTA,  ACMAT Corporation',
    name: 'ACMAT Corporation',
    symbol: 'ACMTA',
  },
  {
    value: 'APTL',
    label: 'APTL,  Alaska Power & Telephone Company',
    name: 'Alaska Power & Telephone Company',
    symbol: 'APTL',
  },
  {
    value: 'ATGN',
    label: 'ATGN,  Altigen Communications, Inc.',
    name: 'Altigen Communications, Inc.',
    symbol: 'ATGN',
  },
  {
    value: 'AMEN',
    label: 'AMEN,  AMEN Properties, Inc.',
    name: 'AMEN Properties, Inc.',
    symbol: 'AMEN',
  },
  {
    value: 'AMBK',
    label: 'AMBK,  American Bank Incorporated',
    name: 'American Bank Incorporated',
    symbol: 'AMBK',
  },
  {
    value: 'AMBZ',
    label: 'AMBZ,  American Business Bank',
    name: 'American Business Bank',
    symbol: 'AMBZ',
  },
  {
    value: 'AMLM',
    label: 'AMLM,  American Lithium Minerals, Inc.',
    name: 'American Lithium Minerals, Inc.',
    symbol: 'AMLM',
  },
  {
    value: 'ARBV',
    label: 'ARBV,  American Riviera Bancorp',
    name: 'American Riviera Bancorp',
    symbol: 'ARBV',
  },
  {
    value: 'APLO',
    label: 'APLO,  Apollo Bancorp, Inc.',
    name: 'Apollo Bancorp, Inc.',
    symbol: 'APLO',
  },
  {
    value: 'AMNF',
    label: 'AMNF,  Armanino Foods of Distinction, Inc.',
    name: 'Armanino Foods of Distinction, Inc.',
    symbol: 'AMNF',
  },
  {
    value: 'ATCN',
    label: 'ATCN,  Atec, Inc.',
    name: 'Atec, Inc.',
    symbol: 'ATCN',
  },
  {
    value: 'ABBB',
    label: 'ABBB,  Auburn Bancorp, Inc.',
    name: 'Auburn Bancorp, Inc.',
    symbol: 'ABBB',
  },
  {
    value: 'AVBH',
    label: 'AVBH,  Avidbank Holdings, Inc.',
    name: 'Avidbank Holdings, Inc.',
    symbol: 'AVBH',
  },
  {
    value: 'BEOB',
    label: 'BEOB,  BEO Bancorp',
    name: 'BEO Bancorp',
    symbol: 'BEOB',
  },
  {
    value: 'BNCC',
    label: 'BNCC,  BNCCORP, Inc.',
    name: 'BNCCORP, Inc.',
    symbol: 'BNCC',
  },
  {
    value: 'BORT',
    label: 'BORT,  Bank of Botetourt',
    name: 'Bank of Botetourt',
    symbol: 'BORT',
  },
  {
    value: 'BKUTK',
    label: 'BKUTK,  Bank of Utica',
    name: 'Bank of Utica',
    symbol: 'BKUTK',
  },
  {
    value: 'BMBN',
    label: 'BMBN,  Benchmark Bankshares, Inc.',
    name: 'Benchmark Bankshares, Inc.',
    symbol: 'BMBN',
  },
  {
    value: 'BXLC',
    label: 'BXLC,  Bexil Corporation',
    name: 'Bexil Corporation',
    symbol: 'BXLC',
  },
  {
    value: 'BIOQ',
    label: 'BIOQ,  Bioqual, Inc.',
    name: 'Bioqual, Inc.',
    symbol: 'BIOQ',
  },
  {
    value: 'BHWB',
    label: 'BHWB,  Blackhawk Bancorp, Inc.',
    name: 'Blackhawk Bancorp, Inc.',
    symbol: 'BHWB',
  },
  {
    value: 'BLHK',
    label: 'BLHK,  blueharbor bank',
    name: 'blueharbor bank',
    symbol: 'BLHK',
  },
  {
    value: 'BSHI',
    label: 'BSHI,  Boss Holdings, Inc.',
    name: 'Boss Holdings, Inc.',
    symbol: 'BSHI',
  },
  {
    value: 'BRBW',
    label: 'BRBW,  Brunswick Bancorp',
    name: 'Brunswick Bancorp',
    symbol: 'BRBW',
  },
  {
    value: 'BHRB',
    label: 'BHRB,  Burke & Herbert Financial Services Corp.',
    name: 'Burke & Herbert Financial Services Corp.',
    symbol: 'BHRB',
  },
  {
    value: 'BURCA',
    label: 'BURCA,  Burnham Holdings, Inc.',
    name: 'Burnham Holdings, Inc.',
    symbol: 'BURCA',
  },
  {
    value: 'CAWW',
    label: 'CAWW,  CCA Industries, Inc.',
    name: 'CCA Industries, Inc.',
    symbol: 'CAWW',
  },
  {
    value: 'CCFN',
    label: 'CCFN,  CCFNB Bancorp, Inc.',
    name: 'CCFNB Bancorp, Inc.',
    symbol: 'CCFN',
  },
  {
    value: 'CIBH',
    label: 'CIBH,  CIB Marine Bancshares, Inc.',
    name: 'CIB Marine Bancshares, Inc.',
    symbol: 'CIBH',
  },
  {
    value: 'CIBY',
    label: 'CIBY,  CIBL, Inc.',
    name: 'CIBL, Inc.',
    symbol: 'CIBY',
  },
  {
    value: 'CNBZ',
    label: 'CNBZ,  CNB Corporation',
    name: 'CNB Corporation',
    symbol: 'CNBZ',
  },
  {
    value: 'CNBB',
    label: 'CNBB,  CNB Community Bancorp, Inc.',
    name: 'CNB Community Bancorp, Inc.',
    symbol: 'CNBB',
  },
  {
    value: 'CWBK',
    label: 'CWBK,  CW Bancorp',
    name: 'CW Bancorp',
    symbol: 'CWBK',
  },
  {
    value: 'CLWY',
    label: "CLWY,  Calloway's Nursery, Inc.",
    name: "Calloway's Nursery, Inc.",
    symbol: 'CLWY',
  },
  {
    value: 'CNND',
    label: 'CNND,  Canandaigua National Corporation',
    name: 'Canandaigua National Corporation',
    symbol: 'CNND',
  },
  {
    value: 'CSHX',
    label: 'CSHX,  Cashmere Valley Bank',
    name: 'Cashmere Valley Bank',
    symbol: 'CSHX',
  },
  {
    value: 'CCFC',
    label: 'CCFC,  CCSB Financial Corp.',
    name: 'CCSB Financial Corp.',
    symbol: 'CCFC',
  },
  {
    value: 'CFCX',
    label: 'CFCX,  Centric Financial Corporation',
    name: 'Centric Financial Corporation',
    symbol: 'CFCX',
  },
  {
    value: 'CYFL',
    label: 'CYFL,  Century Financial Corporation',
    name: 'Century Financial Corporation',
    symbol: 'CYFL',
  },
  {
    value: 'CTUY',
    label: 'CTUY,  Century Next Financial Corporation',
    name: 'Century Next Financial Corporation',
    symbol: 'CTUY',
  },
  {
    value: 'CPKF',
    label: 'CPKF,  Chesapeake Financial Shares, Inc.',
    name: 'Chesapeake Financial Shares, Inc.',
    symbol: 'CPKF',
  },
  {
    value: 'CCBC',
    label: 'CCBC,  Chino Commercial Bancorp',
    name: 'Chino Commercial Bancorp',
    symbol: 'CCBC',
  },
  {
    value: 'CZBC',
    label: 'CZBC,  Citizens Bancorp',
    name: 'Citizens Bancorp',
    symbol: 'CZBC',
  },
  {
    value: 'CZBS',
    label: 'CZBS,  Citizens Bancshares Corporation',
    name: 'Citizens Bancshares Corporation',
    symbol: 'CZBS',
  },
  {
    value: 'CZBT',
    label: 'CZBT,  Citizens Bancorp of Virginia, Inc.',
    name: 'Citizens Bancorp of Virginia, Inc.',
    symbol: 'CZBT',
  },
  {
    value: 'CFIN',
    label: 'CFIN,  Citizens Financial Corporation',
    name: 'Citizens Financial Corporation',
    symbol: 'CFIN',
  },
  {
    value: 'CIWV',
    label: 'CIWV,  Citizens Financial Corp.',
    name: 'Citizens Financial Corp.',
    symbol: 'CIWV',
  },
  {
    value: 'CZNL',
    label: 'CZNL,  Citizens National Corporation',
    name: 'Citizens National Corporation',
    symbol: 'CZNL',
  },
  {
    value: 'CEFC',
    label: 'CEFC,  Commercial National Financial Corporation',
    name: 'Commercial National Financial Corporation',
    symbol: 'CEFC',
  },
  {
    value: 'CFST',
    label: 'CFST,  Communities First Financial Corporation',
    name: 'Communities First Financial Corporation',
    symbol: 'CFST',
  },
  {
    value: 'CTYP',
    label: "CTYP,  Community Bankers' Corporation",
    name: "Community Bankers' Corporation",
    symbol: 'CTYP',
  },
  {
    value: 'ALBY',
    label: 'ALBY,  Community Capital Bancshares, Inc.',
    name: 'Community Capital Bancshares, Inc.',
    symbol: 'ALBY',
  },
  {
    value: 'CIBN',
    label: 'CIBN,  Community Investors Bancorp, Inc.',
    name: 'Community Investors Bancorp, Inc.',
    symbol: 'CIBN',
  },
  {
    value: 'CFOK',
    label: 'CFOK,  Community First Bancorporation',
    name: 'Community First Bancorporation',
    symbol: 'CFOK',
  },
  {
    value: 'CNRD',
    label: 'CNRD,  Conrad Industries, Inc.',
    name: 'Conrad Industries, Inc.',
    symbol: 'CNRD',
  },
  {
    value: 'CRSB',
    label: 'CRSB,  Cornerstone Community Bancorp',
    name: 'Cornerstone Community Bancorp',
    symbol: 'CRSB',
  },
  {
    value: 'CSTI',
    label: 'CSTI,  Costar Technologies, Inc.',
    name: 'Costar Technologies, Inc.',
    symbol: 'CSTI',
  },
  {
    value: 'CRZY',
    label: 'CRZY,  Crazy Woman Creek Bancorp Incorporated',
    name: 'Crazy Woman Creek Bancorp Incorporated',
    symbol: 'CRZY',
  },
  {
    value: 'CHBH',
    label: 'CHBH,  Croghan Bancshares, Inc.',
    name: 'Croghan Bancshares, Inc.',
    symbol: 'CHBH',
  },
  {
    value: 'CRSS',
    label: 'CRSS,  Crossroads Impact Corp.',
    name: 'Crossroads Impact Corp.',
    symbol: 'CRSS',
  },
  {
    value: 'CUSI',
    label: 'CUSI,  Cuisine Solutions, Inc.',
    name: 'Cuisine Solutions, Inc.',
    symbol: 'CUSI',
  },
  {
    value: 'DTRK',
    label: 'DTRK,  DATATRAK International, Inc.',
    name: 'DATATRAK International, Inc.',
    symbol: 'DTRK',
  },
  {
    value: 'DIMC',
    label: 'DIMC,  Dimeco, Inc.',
    name: 'Dimeco, Inc.',
    symbol: 'DIMC',
  },
  {
    value: 'ELLH',
    label: 'ELLH,  Elah Holdings, Inc.',
    name: 'Elah Holdings, Inc.',
    symbol: 'ELLH',
  },
  {
    value: 'ELMA',
    label: 'ELMA,  Elmer Bancorp, Inc.',
    name: 'Elmer Bancorp, Inc.',
    symbol: 'ELMA',
  },
  {
    value: 'EUSP',
    label: 'EUSP,  EuroSite Power Inc.',
    name: 'EuroSite Power Inc.',
    symbol: 'EUSP',
  },
  {
    value: 'EXSR',
    label: 'EXSR,  Exchange Bank (Santa Rosa, CA)',
    name: 'Exchange Bank (Santa Rosa, CA)',
    symbol: 'EXSR',
  },
  {
    value: 'FFWC',
    label: 'FFWC,  FFW Corporation',
    name: 'FFW Corporation',
    symbol: 'FFWC',
  },
  {
    value: 'FFDF',
    label: 'FFDF,  FFD Financial Corporation',
    name: 'FFD Financial Corporation',
    symbol: 'FFDF',
  },
  {
    value: 'FIDS',
    label: 'FIDS,  FNB, Inc.',
    name: 'FNB, Inc.',
    symbol: 'FIDS',
  },
  {
    value: 'FRMO',
    label: 'FRMO,  FRMO Corporation',
    name: 'FRMO Corporation',
    symbol: 'FRMO',
  },
  {
    value: 'FMBL',
    label: 'FMBL,  Farmers & Merchants Bank of Long Beach',
    name: 'Farmers & Merchants Bank of Long Beach',
    symbol: 'FMBL',
  },
  {
    value: 'FABP',
    label: 'FABP,  Farmers Bancorp (Frankfort IN)',
    name: 'Farmers Bancorp (Frankfort IN)',
    symbol: 'FABP',
  },
  {
    value: 'FBPA',
    label: 'FBPA,  The Farmers Bank of Appomattox',
    name: 'The Farmers Bank of Appomattox',
    symbol: 'FBPA',
  },
  {
    value: 'FBVA',
    label: 'FBVA,  Farmers Bankshares, Inc.',
    name: 'Farmers Bankshares, Inc.',
    symbol: 'FBVA',
  },
  {
    value: 'FETM',
    label: 'FETM,  Fentura Financial, Inc.',
    name: 'Fentura Financial, Inc.',
    symbol: 'FETM',
  },
  {
    value: 'FNBT',
    label: 'FNBT,  FineMark Holdings, Inc.',
    name: 'FineMark Holdings, Inc.',
    symbol: 'FNBT',
  },
  {
    value: 'FBPI',
    label: 'FBPI,  First Bancorp of Indiana, Inc.',
    name: 'First Bancorp of Indiana, Inc.',
    symbol: 'FBPI',
  },
  {
    value: 'FBSI',
    label: 'FBSI,  First Bancshares, Inc.',
    name: 'First Bancshares, Inc.',
    symbol: 'FBSI',
  },
  {
    value: 'FBTT',
    label: 'FBTT,  First Bankers Trustshares, Inc.',
    name: 'First Bankers Trustshares, Inc.',
    symbol: 'FBTT',
  },
  {
    value: 'FCOB',
    label: 'FCOB,  1st Colonial Bancorp, Inc.',
    name: '1st Colonial Bancorp, Inc.',
    symbol: 'FCOB',
  },
  {
    value: 'FMFP',
    label: 'FMFP,  First Community Financial Corporation',
    name: 'First Community Financial Corporation',
    symbol: 'FMFP',
  },
  {
    value: 'FCCT',
    label: 'FCCT,  First Community Corporation',
    name: 'First Community Corporation',
    symbol: 'FCCT',
  },
  {
    value: 'FFMH',
    label: 'FFMH,  First Farmers and Merchants Corporation',
    name: 'First Farmers and Merchants Corporation',
    symbol: 'FFMH',
  },
  {
    value: 'FBAK',
    label: 'FBAK,  First National Bank Alaska',
    name: 'First National Bank Alaska',
    symbol: 'FBAK',
  },
  {
    value: 'FINN',
    label: 'FINN,  First National of Nebraska, Inc.',
    name: 'First National of Nebraska, Inc.',
    symbol: 'FINN',
  },
  {
    value: 'FNFI',
    label: 'FNFI,  First Niles Financial, Inc.',
    name: 'First Niles Financial, Inc.',
    symbol: 'FNFI',
  },
  {
    value: 'FOTB',
    label: 'FOTB,  First Ottawa Bancshares, Inc.',
    name: 'First Ottawa Bancshares, Inc.',
    symbol: 'FOTB',
  },
  {
    value: 'FRSB',
    label: 'FRSB,  First Resource Bancorp Inc.',
    name: 'First Resource Bancorp Inc.',
    symbol: 'FRSB',
  },
  {
    value: 'FRFC',
    label: 'FRFC,  First Robinson Financial Corporation',
    name: 'First Robinson Financial Corporation',
    symbol: 'FRFC',
  },
  {
    value: 'FTDL',
    label: 'FTDL,  FirsTime Design Limited',
    name: 'FirsTime Design Limited',
    symbol: 'FTDL',
  },
  {
    value: 'FGFH',
    label: 'FGFH,  Foresight Financial Group, Inc.',
    name: 'Foresight Financial Group, Inc.',
    symbol: 'FGFH',
  },
  {
    value: 'FDVA',
    label: 'FDVA,  Freedom Financial Holdings, Inc.',
    name: 'Freedom Financial Holdings, Inc.',
    symbol: 'FDVA',
  },
  {
    value: 'GWOX',
    label: 'GWOX,  The Goodheart-Willcox Company, Inc.',
    name: 'The Goodheart-Willcox Company, Inc.',
    symbol: 'GWOX',
  },
  {
    value: 'GOVB',
    label: 'GOVB,  Gouverneur Bancorp, Inc.',
    name: 'Gouverneur Bancorp, Inc.',
    symbol: 'GOVB',
  },
  {
    value: 'GNRV',
    label: 'GNRV,  Grand River Commerce, Inc.',
    name: 'Grand River Commerce, Inc.',
    symbol: 'GNRV',
  },
  {
    value: 'GTPS',
    label: 'GTPS,  Great American Bancorp, Inc.',
    name: 'Great American Bancorp, Inc.',
    symbol: 'GTPS',
  },
  {
    value: 'GVFF',
    label: 'GVFF,  Greenville Federal Financial Corporation',
    name: 'Greenville Federal Financial Corporation',
    symbol: 'GVFF',
  },
  {
    value: 'SORT',
    label: 'SORT,  inc.jet Holding, Inc.',
    name: 'inc.jet Holding, Inc.',
    symbol: 'SORT',
  },
  {
    value: 'HCBN',
    label: 'HCBN,  HCB Financial Corp.',
    name: 'HCB Financial Corp.',
    symbol: 'HCBN',
  },
  {
    value: 'HFBA',
    label: 'HFBA,  HFB Financial Corporation',
    name: 'HFB Financial Corporation',
    symbol: 'HFBA',
  },
  {
    value: 'HMLN',
    label: 'HMLN,  Hamlin Bank and Trust Company',
    name: 'Hamlin Bank and Trust Company',
    symbol: 'HMLN',
  },
  {
    value: 'HNFSA',
    label: 'HNFSA,  Hanover Foods Corporation',
    name: 'Hanover Foods Corporation',
    symbol: 'HNFSA',
  },
  {
    value: 'HFBK',
    label: 'HFBK,  Harford Bank',
    name: 'Harford Bank',
    symbol: 'HFBK',
  },
  {
    value: 'HEWA',
    label: 'HEWA,  HealthWarehouse.com, Inc.',
    name: 'HealthWarehouse.com, Inc.',
    symbol: 'HEWA',
  },
  {
    value: 'HRGG',
    label: 'HRGG,  Heritage NOLA Bancorp, Inc.',
    name: 'Heritage NOLA Bancorp, Inc.',
    symbol: 'HRGG',
  },
  {
    value: 'HSBI',
    label: 'HSBI,  Heritage Southeast Bancorporation, Inc.',
    name: 'Heritage Southeast Bancorporation, Inc.',
    symbol: 'HSBI',
  },
  {
    value: 'HCBC',
    label: 'HCBC,  High Country Bancorp, Inc.',
    name: 'High Country Bancorp, Inc.',
    symbol: 'HCBC',
  },
  {
    value: 'HBSI',
    label: 'HBSI,  Highlands Bankshares, Inc.',
    name: 'Highlands Bankshares, Inc.',
    symbol: 'HBSI',
  },
  {
    value: 'HWAL',
    label: 'HWAL,  Hollywall Entertainment, Inc.',
    name: 'Hollywall Entertainment, Inc.',
    symbol: 'HWAL',
  },
  {
    value: 'HWIS',
    label: 'HWIS,  Home Bancorp Wisconsin, Inc.',
    name: 'Home Bancorp Wisconsin, Inc.',
    symbol: 'HWIS',
  },
  {
    value: 'HLFN',
    label: 'HLFN,  Home Loan Financial Corporation',
    name: 'Home Loan Financial Corporation',
    symbol: 'HLFN',
  },
  {
    value: 'HONT',
    label: 'HONT,  Honat Bancorp, Inc.',
    name: 'Honat Bancorp, Inc.',
    symbol: 'HONT',
  },
  {
    value: 'HCGI',
    label: 'HCGI,  Huntwicke Capital Group Inc.',
    name: 'Huntwicke Capital Group Inc.',
    symbol: 'HCGI',
  },
  {
    value: 'HVLM',
    label: 'HVLM,  Huron Valley Bancorp, Inc.',
    name: 'Huron Valley Bancorp, Inc.',
    symbol: 'HVLM',
  },
  {
    value: 'IBWC',
    label: 'IBWC,  IBW Financial Corporation',
    name: 'IBW Financial Corporation',
    symbol: 'IBWC',
  },
  {
    value: 'IVST',
    label: 'IVST,  Innovest Global, Inc.',
    name: 'Innovest Global, Inc.',
    symbol: 'IVST',
  },
  {
    value: 'IFHI',
    label: 'IFHI,  Integrated Financial Holdings, Inc.',
    name: 'Integrated Financial Holdings, Inc.',
    symbol: 'IFHI',
  },
  {
    value: 'JDVB',
    label: 'JDVB,  JD Bancshares, Inc.',
    name: 'JD Bancshares, Inc.',
    symbol: 'JDVB',
  },
  {
    value: 'JFBC',
    label: 'JFBC,  Jeffersonville Bancorp',
    name: 'Jeffersonville Bancorp',
    symbol: 'JFBC',
  },
  {
    value: 'JMSB',
    label: 'JMSB,  John Marshall Bancorp, Inc.',
    name: 'John Marshall Bancorp, Inc.',
    symbol: 'JMSB',
  },
  {
    value: 'KSBI',
    label: 'KSBI,  KS Bancorp, Inc.',
    name: 'KS Bancorp, Inc.',
    symbol: 'KSBI',
  },
  {
    value: 'KCLI',
    label: 'KCLI,  Kansas City Life Insurance Company',
    name: 'Kansas City Life Insurance Company',
    symbol: 'KCLI',
  },
  {
    value: 'KTHN',
    label: 'KTHN,  Katahdin Bankshares Corp.',
    name: 'Katahdin Bankshares Corp.',
    symbol: 'KTHN',
  },
  {
    value: 'KEWL',
    label: 'KEWL,  Keweenaw Land Association, Limited',
    name: 'Keweenaw Land Association, Limited',
    symbol: 'KEWL',
  },
  {
    value: 'LICT',
    label: 'LICT,  LICT Corporation',
    name: 'LICT Corporation',
    symbol: 'LICT',
  },
  {
    value: 'LFGP',
    label: 'LFGP,  Ledyard Financial Group, Inc.',
    name: 'Ledyard Financial Group, Inc.',
    symbol: 'LFGP',
  },
  {
    value: 'LSFG',
    label: 'LSFG,  Lifestore Financial Group, Inc.',
    name: 'Lifestore Financial Group, Inc.',
    symbol: 'LSFG',
  },
  {
    value: 'MACE',
    label: 'MACE,  Mace Security International, Inc.',
    name: 'Mace Security International, Inc.',
    symbol: 'MACE',
  },
  {
    value: 'MCBK',
    label: 'MCBK,  Madison County Financial, Inc.',
    name: 'Madison County Financial, Inc.',
    symbol: 'MCBK',
  },
  {
    value: 'MLGF',
    label: 'MLGF,  Malaga Financial Corporation',
    name: 'Malaga Financial Corporation',
    symbol: 'MLGF',
  },
  {
    value: 'MAAL',
    label: 'MAAL,  The Marketing Alliance, Inc.',
    name: 'The Marketing Alliance, Inc.',
    symbol: 'MAAL',
  },
  {
    value: 'MRTI',
    label: 'MRTI,  Maxus Realty Trust, Inc.',
    name: 'Maxus Realty Trust, Inc.',
    symbol: 'MRTI',
  },
  {
    value: 'MCRAA',
    label: 'MCRAA,  McRae Industries, Inc.',
    name: 'McRae Industries, Inc.',
    symbol: 'MCRAA',
  },
  {
    value: 'MCRAB',
    label: 'MCRAB,  McRae Industries, Inc.',
    name: 'McRae Industries, Inc.',
    symbol: 'MCRAB',
  },
  {
    value: 'MHGU',
    label: 'MHGU,  Meritage Hospitality Group Inc.',
    name: 'Meritage Hospitality Group Inc.',
    symbol: 'MHGU',
  },
  {
    value: 'MCCK',
    label: 'MCCK,  Mestek, Inc.',
    name: 'Mestek, Inc.',
    symbol: 'MCCK',
  },
  {
    value: 'MDVT',
    label: 'MDVT,  Middlebury National Corporation',
    name: 'Middlebury National Corporation',
    symbol: 'MDVT',
  },
  {
    value: 'MSBC',
    label: 'MSBC,  Mission Bancorp',
    name: 'Mission Bancorp',
    symbol: 'MSBC',
  },
  {
    value: 'MVLY',
    label: 'MVLY,  Mission Valley Bancorp',
    name: 'Mission Valley Bancorp',
    symbol: 'MVLY',
  },
  {
    value: 'MCEM',
    label: 'MCEM,  The Monarch Cement Company',
    name: 'The Monarch Cement Company',
    symbol: 'MCEM',
  },
  {
    value: 'MCBI',
    label: 'MCBI,  Mountain Commerce Bancorp, Inc.',
    name: 'Mountain Commerce Bancorp, Inc.',
    symbol: 'MCBI',
  },
  {
    value: 'MUEL',
    label: 'MUEL,  Paul Mueller Company',
    name: 'Paul Mueller Company',
    symbol: 'MUEL',
  },
  {
    value: 'MYBF',
    label: 'MYBF,  Muncy Bank Financial, Inc.',
    name: 'Muncy Bank Financial, Inc.',
    symbol: 'MYBF',
  },
  {
    value: 'NASB',
    label: 'NASB,  NASB Financial, Inc.',
    name: 'NASB Financial, Inc.',
    symbol: 'NASB',
  },
  {
    value: 'NSYC',
    label: 'NSYC,  National Stock Yards Co.',
    name: 'National Stock Yards Co.',
    symbol: 'NSYC',
  },
  {
    value: 'NEFB',
    label: 'NEFB,  Neffs Bancorp, Inc.',
    name: 'Neffs Bancorp, Inc.',
    symbol: 'NEFB',
  },
  {
    value: 'NODB',
    label: 'NODB,  North Dallas Bank & Trust Co.',
    name: 'North Dallas Bank & Trust Co.',
    symbol: 'NODB',
  },
  {
    value: 'NIDB',
    label: 'NIDB,  Northeast Indiana Bancorp, Inc.',
    name: 'Northeast Indiana Bancorp, Inc.',
    symbol: 'NIDB',
  },
  {
    value: 'NUBC',
    label: 'NUBC,  Northumberland Bancorp',
    name: 'Northumberland Bancorp',
    symbol: 'NUBC',
  },
  {
    value: 'NWYF',
    label: 'NWYF,  Northway Financial, Inc.',
    name: 'Northway Financial, Inc.',
    symbol: 'NWYF',
  },
  {
    value: 'OPST',
    label: 'OPST,  OPT-Sciences Corporation',
    name: 'OPT-Sciences Corporation',
    symbol: 'OPST',
  },
  {
    value: 'BKOR',
    label: 'BKOR,  Oak Ridge Financial Services, Inc.',
    name: 'Oak Ridge Financial Services, Inc.',
    symbol: 'BKOR',
  },
  {
    value: 'OSBK',
    label: 'OSBK,  Oconee Financial Corporation',
    name: 'Oconee Financial Corporation',
    symbol: 'OSBK',
  },
  {
    value: 'ORBT',
    label: 'ORBT,  Orbit International Corp.',
    name: 'Orbit International Corp.',
    symbol: 'ORBT',
  },
  {
    value: 'ORBN',
    label: 'ORBN,  Oregon Bancorp, Inc.',
    name: 'Oregon Bancorp, Inc.',
    symbol: 'ORBN',
  },
  {
    value: 'PSBQ',
    label: 'PSBQ,  PSB Holdings, Inc.',
    name: 'PSB Holdings, Inc.',
    symbol: 'PSBQ',
  },
  {
    value: 'PFLC',
    label: 'PFLC,  Pacific Financial Corporation',
    name: 'Pacific Financial Corporation',
    symbol: 'PFLC',
  },
  {
    value: 'PVBK',
    label: 'PVBK,  Pacific Valley Bancorp',
    name: 'Pacific Valley Bancorp',
    symbol: 'PVBK',
  },
  {
    value: 'PWBO',
    label: 'PWBO,  Pacific West Bank',
    name: 'Pacific West Bank',
    symbol: 'PWBO',
  },
  {
    value: 'PGNN',
    label: 'PGNN,  Paragon Financial Solutions, Inc.',
    name: 'Paragon Financial Solutions, Inc.',
    symbol: 'PGNN',
  },
  {
    value: 'PGNT',
    label: 'PGNT,  Paragon Technologies, Inc.',
    name: 'Paragon Technologies, Inc.',
    symbol: 'PGNT',
  },
  {
    value: 'PDER',
    label: 'PDER,  Pardee Resources Company',
    name: 'Pardee Resources Company',
    symbol: 'PDER',
  },
  {
    value: 'PKDC',
    label: 'PKDC,  Parker Drilling Company',
    name: 'Parker Drilling Company',
    symbol: 'PKDC',
  },
  {
    value: 'PKIN',
    label: 'PKIN,  Pekin Life Insurance Company',
    name: 'Pekin Life Insurance Company',
    symbol: 'PKIN',
  },
  {
    value: 'PEBC',
    label: 'PEBC,  Peoples Bancorp, Inc.',
    name: 'Peoples Bancorp, Inc.',
    symbol: 'PEBC',
  },
  {
    value: 'PBCO',
    label: 'PBCO,  PBCO Financial Corporation',
    name: 'PBCO Financial Corporation',
    symbol: 'PBCO',
  },
  {
    value: 'PCLB',
    label: 'PCLB,  Pinnacle Bancshares, Inc.',
    name: 'Pinnacle Bancshares, Inc.',
    symbol: 'PCLB',
  },
  {
    value: 'PBNK',
    label: 'PBNK,  Pinnacle Bank',
    name: 'Pinnacle Bank',
    symbol: 'PBNK',
  },
  {
    value: 'PNBI',
    label: 'PNBI,  Pioneer Bankshares, Inc.',
    name: 'Pioneer Bankshares, Inc.',
    symbol: 'PNBI',
  },
  {
    value: 'PTBS',
    label: 'PTBS,  Potomac Bancshares, Inc.',
    name: 'Potomac Bancshares, Inc.',
    symbol: 'PTBS',
  },
  {
    value: 'PBAM',
    label: 'PBAM,  Private Bancorp of America, Inc.',
    name: 'Private Bancorp of America, Inc.',
    symbol: 'PBAM',
  },
  {
    value: 'QEPC',
    label: 'QEPC,  Q.E.P. Co., Inc.',
    name: 'Q.E.P. Co., Inc.',
    symbol: 'QEPC',
  },
  {
    value: 'RWWI',
    label: 'RWWI,  Rand Worldwide, Inc.',
    name: 'Rand Worldwide, Inc.',
    symbol: 'RWWI',
  },
  {
    value: 'RWCB',
    label: 'RWCB,  Redwood Capital Bancorp',
    name: 'Redwood Capital Bancorp',
    symbol: 'RWCB',
  },
  {
    value: 'RCBC',
    label: 'RCBC,  River City Bank',
    name: 'River City Bank',
    symbol: 'RCBC',
  },
  {
    value: 'RVCB',
    label: 'RVCB,  River Valley Community Bancorp',
    name: 'River Valley Community Bancorp',
    symbol: 'RVCB',
  },
  {
    value: 'SADL',
    label: 'SADL,  William H. Sadlier, Inc.',
    name: 'William H. Sadlier, Inc.',
    symbol: 'SADL',
  },
  {
    value: 'SCZC',
    label: 'SCZC,  Santa Cruz County Bank',
    name: 'Santa Cruz County Bank',
    symbol: 'SCZC',
  },
  {
    value: 'SVIN',
    label: 'SVIN,  Scheid Vineyards Inc.',
    name: 'Scheid Vineyards Inc.',
    symbol: 'SVIN',
  },
  {
    value: 'SCPJ',
    label: 'SCPJ,  Scope Industries',
    name: 'Scope Industries',
    symbol: 'SCPJ',
  },
  {
    value: 'SCYT',
    label: 'SCYT,  Security Bancorp, Inc.',
    name: 'Security Bancorp, Inc.',
    symbol: 'SCYT',
  },
  {
    value: 'SIGL',
    label: 'SIGL,  Signal Advance, Inc.',
    name: 'Signal Advance, Inc.',
    symbol: 'SIGL',
  },
  {
    value: 'SKTP',
    label: 'SKTP,  Skytop Lodge Corporation',
    name: 'Skytop Lodge Corporation',
    symbol: 'SKTP',
  },
  {
    value: 'SLRK',
    label: 'SLRK,  Solera National Bancorp, Inc.',
    name: 'Solera National Bancorp, Inc.',
    symbol: 'SLRK',
  },
  {
    value: 'SOBS',
    label: 'SOBS,  Solvay Bank Corp.',
    name: 'Solvay Bank Corp.',
    symbol: 'SOBS',
  },
  {
    value: 'SIMA',
    label: 'SIMA,  Sonics & Materials, Inc.',
    name: 'Sonics & Materials, Inc.',
    symbol: 'SIMA',
  },
  {
    value: 'SABK',
    label: 'SABK,  South Atlantic Bancshares, Inc.',
    name: 'South Atlantic Bancshares, Inc.',
    symbol: 'SABK',
  },
  {
    value: 'SRNN',
    label: 'SRNN,  The Southern Banc Company, Inc.',
    name: 'The Southern Banc Company, Inc.',
    symbol: 'SRNN',
  },
  {
    value: 'SBNC',
    label: 'SBNC,  Southern BancShares (N.C.), Inc.',
    name: 'Southern BancShares (N.C.), Inc.',
    symbol: 'SBNC',
  },
  {
    value: 'BCAL',
    label: 'BCAL,  Southern California Bancorp',
    name: 'Southern California Bancorp',
    symbol: 'BCAL',
  },
  {
    value: 'SOMC',
    label: 'SOMC,  Southern Michigan Bancorp, Inc.',
    name: 'Southern Michigan Bancorp, Inc.',
    symbol: 'SOMC',
  },
  {
    value: 'SPCO',
    label: 'SPCO,  The Stephan Co.',
    name: 'The Stephan Co.',
    symbol: 'SPCO',
  },
  {
    value: 'STBI',
    label: 'STBI,  Sturgis Bancorp, Inc.',
    name: 'Sturgis Bancorp, Inc.',
    symbol: 'STBI',
  },
  {
    value: 'SMAL',
    label: 'SMAL,  Summit Bancshares, Inc.',
    name: 'Summit Bancshares, Inc.',
    symbol: 'SMAL',
  },
  {
    value: 'SRYB',
    label: 'SRYB,  Surrey Bancorp',
    name: 'Surrey Bancorp',
    symbol: 'SRYB',
  },
  {
    value: 'SQCF',
    label: 'SQCF,  Susquehanna Community Financial, Inc.',
    name: 'Susquehanna Community Financial, Inc.',
    symbol: 'SQCF',
  },
  {
    value: 'TETAA',
    label: 'TETAA,  Teton Advisors, LLC',
    name: 'Teton Advisors, LLC',
    symbol: 'TETAA',
  },
  {
    value: 'TOOD',
    label: 'TOOD,  Thermwood Corporation',
    name: 'Thermwood Corporation',
    symbol: 'TOOD',
  },
  {
    value: 'TDCB',
    label: 'TDCB,  Third Century Bancorp',
    name: 'Third Century Bancorp',
    symbol: 'TDCB',
  },
  {
    value: 'THVB',
    label: 'THVB,  Thomasville Bancshares, Inc.',
    name: 'Thomasville Bancshares, Inc.',
    symbol: 'THVB',
  },
  {
    value: 'TMAK',
    label: 'TMAK,  Touchmark Bancshares, Inc.',
    name: 'Touchmark Bancshares, Inc.',
    symbol: 'TMAK',
  },
  {
    value: 'TPRP',
    label: 'TPRP,  Tower Properties Company',
    name: 'Tower Properties Company',
    symbol: 'TPRP',
  },
  {
    value: 'TWCF',
    label: 'TWCF,  Town and Country Financial Corporation',
    name: 'Town and Country Financial Corporation',
    symbol: 'TWCF',
  },
  {
    value: 'TRCY',
    label: 'TRCY,  Tri City Bankshares Corporation',
    name: 'Tri City Bankshares Corporation',
    symbol: 'TRCY',
  },
  {
    value: 'TYFG',
    label: 'TYFG,  Tri-County Financial Group, Inc.',
    name: 'Tri-County Financial Group, Inc.',
    symbol: 'TYFG',
  },
  {
    value: 'TYBT',
    label: 'TYBT,  Trinity Bank, N.A.',
    name: 'Trinity Bank, N.A.',
    symbol: 'TYBT',
  },
  {
    value: 'TRUX',
    label: 'TRUX,  Truxton Corporation',
    name: 'Truxton Corporation',
    symbol: 'TRUX',
  },
  {
    value: 'TRVR',
    label: 'TRVR,  Two Rivers Financial Group, Inc.',
    name: 'Two Rivers Financial Group, Inc.',
    symbol: 'TRVR',
  },
  {
    value: 'UBNC',
    label: 'UBNC,  UB Bancorp',
    name: 'UB Bancorp',
    symbol: 'UBNC',
  },
  {
    value: 'UBAB',
    label: 'UBAB,  United Bancorporation of Alabama, Inc.',
    name: 'United Bancorporation of Alabama, Inc.',
    symbol: 'UBAB',
  },
  {
    value: 'UNTN',
    label: 'UNTN,  United Tennessee Bankshares, Inc.',
    name: 'United Tennessee Bankshares, Inc.',
    symbol: 'UNTN',
  },
  {
    value: 'UNIB',
    label: 'UNIB,  University Bancorp, Inc.',
    name: 'University Bancorp, Inc.',
    symbol: 'UNIB',
  },
  {
    value: 'WTBFB',
    label: 'WTBFB,  W.T.B. Financial Corporation',
    name: 'W.T.B. Financial Corporation',
    symbol: 'WTBFB',
  },
  {
    value: 'WAKE',
    label: 'WAKE,  Wake Forest Bancshares, Inc.',
    name: 'Wake Forest Bancshares, Inc.',
    symbol: 'WAKE',
  },
  {
    value: 'WEBC',
    label: 'WEBC,  Webco Industries, Inc.',
    name: 'Webco Industries, Inc.',
    symbol: 'WEBC',
  },
  {
    value: 'WBBW',
    label: 'WBBW,  Westbury Bancorp, Inc.',
    name: 'Westbury Bancorp, Inc.',
    symbol: 'WBBW',
  },
  {
    value: 'WELX',
    label: 'WELX,  Winland Holdings Corporation',
    name: 'Winland Holdings Corporation',
    symbol: 'WELX',
  },
  {
    value: 'WDFN',
    label: 'WDFN,  Woodlands Financial Services Company',
    name: 'Woodlands Financial Services Company',
    symbol: 'WDFN',
  },
  {
    value: 'BTLCY',
    label: 'BTLCY,  British Land Company Plc',
    name: 'British Land Company Plc',
    symbol: 'BTLCY',
  },
  {
    value: 'CHDRY',
    label: 'CHDRY,  Christian Dior SE',
    name: 'Christian Dior SE',
    symbol: 'CHDRY',
  },
  {
    value: 'DELKY',
    label: 'DELKY,  Delek Group Ltd.',
    name: 'Delek Group Ltd.',
    symbol: 'DELKY',
  },
  {
    value: 'GMVHY',
    label: 'GMVHY,  Entain Plc',
    name: 'Entain Plc',
    symbol: 'GMVHY',
  },
  {
    value: 'RYHTY',
    label: 'RYHTY,  Ryman Healthcare Limited',
    name: 'Ryman Healthcare Limited',
    symbol: 'RYHTY',
  },
  {
    value: 'SCVPY',
    label: 'SCVPY,  The Siam Cement Public Company Limited',
    name: 'The Siam Cement Public Company Limited',
    symbol: 'SCVPY',
  },
  {
    value: 'STWRY',
    label: 'STWRY,  Software Aktiengesellschaft',
    name: 'Software Aktiengesellschaft',
    symbol: 'STWRY',
  },
  {
    value: 'SLVYY',
    label: 'SLVYY,  Solvay SA',
    name: 'Solvay SA',
    symbol: 'SLVYY',
  },
  {
    value: 'TCNB',
    label: 'TCNB,  Town Center Bank',
    name: 'Town Center Bank',
    symbol: 'TCNB',
  },
  {
    value: 'RLLCF',
    label: 'RLLCF,  Rolls-Royce Holdings plc',
    name: 'Rolls-Royce Holdings plc',
    symbol: 'RLLCF',
  },
  {
    value: 'AMNP',
    label: 'AMNP,  American Sierra Gold Corp.',
    name: 'American Sierra Gold Corp.',
    symbol: 'AMNP',
  },
  {
    value: 'BGEM',
    label: 'BGEM,  Blue Gem Enterprise',
    name: 'Blue Gem Enterprise',
    symbol: 'BGEM',
  },
  {
    value: 'CGLD',
    label: 'CGLD,  Buscar Company',
    name: 'Buscar Company',
    symbol: 'CGLD',
  },
  {
    value: 'CNTO',
    label: 'CNTO,  Centor Energy, Inc.',
    name: 'Centor Energy, Inc.',
    symbol: 'CNTO',
  },
  {
    value: 'DEER',
    label: 'DEER,  Deer Consumer Products, Inc.',
    name: 'Deer Consumer Products, Inc.',
    symbol: 'DEER',
  },
  {
    value: 'ETEK',
    label: 'ETEK,  Eco-Tek Group, Inc.',
    name: 'Eco-Tek Group, Inc.',
    symbol: 'ETEK',
  },
  {
    value: 'ECPN',
    label: 'ECPN,  El Capitan Precious Metals, Inc.',
    name: 'El Capitan Precious Metals, Inc.',
    symbol: 'ECPN',
  },
  {
    value: 'FRMA',
    label: 'FRMA,  Firma Holdings Corp.',
    name: 'Firma Holdings Corp.',
    symbol: 'FRMA',
  },
  {
    value: 'FOYJ',
    label: 'FOYJ,  Foy-Johnston Inc.',
    name: 'Foy-Johnston Inc.',
    symbol: 'FOYJ',
  },
  {
    value: 'IPRC',
    label: 'IPRC,  Imperial Resources, Inc.',
    name: 'Imperial Resources, Inc.',
    symbol: 'IPRC',
  },
  {
    value: 'INTK',
    label: 'INTK,  Industrial Nanotech, Inc.',
    name: 'Industrial Nanotech, Inc.',
    symbol: 'INTK',
  },
  {
    value: 'IGRW',
    label: 'IGRW,  Interactive Health Network',
    name: 'Interactive Health Network',
    symbol: 'IGRW',
  },
  {
    value: 'JRJRQ',
    label: 'JRJRQ,  JRjr33, Inc.',
    name: 'JRjr33, Inc.',
    symbol: 'JRJRQ',
  },
  {
    value: 'JAMN',
    label: 'JAMN,  Jammin Java Corp.',
    name: 'Jammin Java Corp.',
    symbol: 'JAMN',
  },
  {
    value: 'KNKT',
    label: 'KNKT,  Kunekt Corporation',
    name: 'Kunekt Corporation',
    symbol: 'KNKT',
  },
  {
    value: 'LLLI',
    label: 'LLLI,  Lamperd Less Lethal, Inc.',
    name: 'Lamperd Less Lethal, Inc.',
    symbol: 'LLLI',
  },
  {
    value: 'LNGT',
    label: 'LNGT,  Laser Energetics, Inc.',
    name: 'Laser Energetics, Inc.',
    symbol: 'LNGT',
  },
  {
    value: 'LEIC',
    label: 'LEIC,  Lead Innovation Corporation',
    name: 'Lead Innovation Corporation',
    symbol: 'LEIC',
  },
  {
    value: 'LOGL',
    label: 'LOGL,  Legend Oil and Gas, Ltd.',
    name: 'Legend Oil and Gas, Ltd.',
    symbol: 'LOGL',
  },
  {
    value: 'LYJN',
    label: 'LYJN,  Lyric Jeans, Inc.',
    name: 'Lyric Jeans, Inc.',
    symbol: 'LYJN',
  },
  {
    value: 'MBCI',
    label: 'MBCI,  MabCure, Inc.',
    name: 'MabCure, Inc.',
    symbol: 'MBCI',
  },
  {
    value: 'WOWI',
    label: 'WOWI,  Metro One Telecommunications, Inc.',
    name: 'Metro One Telecommunications, Inc.',
    symbol: 'WOWI',
  },
  {
    value: 'NWGC',
    label: 'NWGC,  New World Gold Corp.',
    name: 'New World Gold Corp.',
    symbol: 'NWGC',
  },
  {
    value: 'OCTX',
    label: 'OCTX,  Octagon 88 Resources, Inc.',
    name: 'Octagon 88 Resources, Inc.',
    symbol: 'OCTX',
  },
  {
    value: 'OMAGQ',
    label: 'OMAGQ,  Omagine, Inc.',
    name: 'Omagine, Inc.',
    symbol: 'OMAGQ',
  },
  {
    value: 'PRRY',
    label: 'PRRY,  Planet Resource Recovery, Inc.',
    name: 'Planet Resource Recovery, Inc.',
    symbol: 'PRRY',
  },
  {
    value: 'PTOI',
    label: 'PTOI,  Plastic2Oil, Inc.',
    name: 'Plastic2Oil, Inc.',
    symbol: 'PTOI',
  },
  {
    value: 'RGDXQ',
    label: 'RGDXQ,  Response Genetics, Inc',
    name: 'Response Genetics, Inc',
    symbol: 'RGDXQ',
  },
  {
    value: 'SNWR',
    label: 'SNWR,  Sanwire Corporation',
    name: 'Sanwire Corporation',
    symbol: 'SNWR',
  },
  {
    value: 'SHOM',
    label: 'SHOM,  Southern Home Medical, Inc.',
    name: 'Southern Home Medical, Inc.',
    symbol: 'SHOM',
  },
  {
    value: 'DDDX',
    label: 'DDDX,  3DX Industries, Inc.',
    name: '3DX Industries, Inc.',
    symbol: 'DDDX',
  },
  {
    value: 'PSPW',
    label: 'PSPW,  3Power Energy Group, Inc.',
    name: '3Power Energy Group, Inc.',
    symbol: 'PSPW',
  },
  {
    value: 'TACI',
    label: 'TACI,  TransAtlantic Capital Inc.',
    name: 'TransAtlantic Capital Inc.',
    symbol: 'TACI',
  },
  {
    value: 'RGLG',
    label: 'RGLG,  UHF Logistics Group, Inc.',
    name: 'UHF Logistics Group, Inc.',
    symbol: 'RGLG',
  },
  {
    value: 'UCPA',
    label: 'UCPA,  United Communications Partners Inc.',
    name: 'United Communications Partners Inc.',
    symbol: 'UCPA',
  },
  {
    value: 'VGLS',
    label: 'VGLS,  VG Life Sciences Inc.',
    name: 'VG Life Sciences Inc.',
    symbol: 'VGLS',
  },
  {
    value: 'VNTA',
    label: 'VNTA,  Ventana Biotech, Inc.',
    name: 'Ventana Biotech, Inc.',
    symbol: 'VNTA',
  },
  {
    value: 'WTII',
    label: 'WTII,  Water Technologies International, Inc.',
    name: 'Water Technologies International, Inc.',
    symbol: 'WTII',
  },
  {
    value: 'WRMA',
    label: 'WRMA,  Wiremedia, Inc.',
    name: 'Wiremedia, Inc.',
    symbol: 'WRMA',
  },
  {
    value: 'ALBKY',
    label: 'ALBKY,  Alpha Services and Holdings S.A.',
    name: 'Alpha Services and Holdings S.A.',
    symbol: 'ALBKY',
  },
  {
    value: 'BACHY',
    label: 'BACHY,  Bank of China Limited',
    name: 'Bank of China Limited',
    symbol: 'BACHY',
  },
  {
    value: 'CPCAY',
    label: 'CPCAY,  Cathay Pacific Airways Limited',
    name: 'Cathay Pacific Airways Limited',
    symbol: 'CPCAY',
  },
  {
    value: 'CICHY',
    label: 'CICHY,  China Construction Bank Corporation',
    name: 'China Construction Bank Corporation',
    symbol: 'CICHY',
  },
  {
    value: 'EGFEY',
    label: 'EGFEY,  Eurobank Ergasias Services and Holdings S.A.',
    name: 'Eurobank Ergasias Services and Holdings S.A.',
    symbol: 'EGFEY',
  },
  {
    value: 'GELYY',
    label: 'GELYY,  Geely Automobile Holdings Limited',
    name: 'Geely Automobile Holdings Limited',
    symbol: 'GELYY',
  },
  {
    value: 'KPELY',
    label: 'KPELY,  Keppel Corporation Limited',
    name: 'Keppel Corporation Limited',
    symbol: 'KPELY',
  },
  {
    value: 'LNVGY',
    label: 'LNVGY,  Lenovo Group Limited',
    name: 'Lenovo Group Limited',
    symbol: 'LNVGY',
  },
  {
    value: 'LVCLY',
    label: 'LVCLY,  Living Cell Technologies Limited',
    name: 'Living Cell Technologies Limited',
    symbol: 'LVCLY',
  },
  {
    value: 'LYSDY',
    label: 'LYSDY,  Lynas Rare Earths Limited',
    name: 'Lynas Rare Earths Limited',
    symbol: 'LYSDY',
  },
  {
    value: 'MRRTY',
    label: 'MRRTY,  Marfrig Global Foods S.A.',
    name: 'Marfrig Global Foods S.A.',
    symbol: 'MRRTY',
  },
  {
    value: 'NDVLY',
    label: 'NDVLY,  New World Development Company Limited',
    name: 'New World Development Company Limited',
    symbol: 'NDVLY',
  },
  {
    value: 'SCHYY',
    label: 'SCHYY,  Sands China Ltd.',
    name: 'Sands China Ltd.',
    symbol: 'SCHYY',
  },
  {
    value: 'SSLZY',
    label: 'SSLZY,  Santos Limited',
    name: 'Santos Limited',
    symbol: 'SSLZY',
  },
  {
    value: 'SGAPY',
    label: 'SGAPY,  Singapore Telecommunications Limited',
    name: 'Singapore Telecommunications Limited',
    symbol: 'SGAPY',
  },
  {
    value: 'SPKKY',
    label: 'SPKKY,  Spark New Zealand Limited',
    name: 'Spark New Zealand Limited',
    symbol: 'SPKKY',
  },
  {
    value: 'SWRAY',
    label: 'SWRAY,  Swire Pacific Limited',
    name: 'Swire Pacific Limited',
    symbol: 'SWRAY',
  },
  {
    value: 'SWRBY',
    label: 'SWRBY,  Swire Pacific Limited',
    name: 'Swire Pacific Limited',
    symbol: 'SWRBY',
  },
  {
    value: 'WLWHY',
    label: 'WLWHY,  Woolworths Holdings Limited',
    name: 'Woolworths Holdings Limited',
    symbol: 'WLWHY',
  },
  {
    value: 'WYNMY',
    label: 'WYNMY,  Wynn Macau, Limited',
    name: 'Wynn Macau, Limited',
    symbol: 'WYNMY',
  },
  {
    value: 'ARNGF',
    label: 'ARNGF,  Argonaut Gold Inc.',
    name: 'Argonaut Gold Inc.',
    symbol: 'ARNGF',
  },
  {
    value: 'WLDBF',
    label: 'WLDBF,  WildBrain Ltd.',
    name: 'WildBrain Ltd.',
    symbol: 'WLDBF',
  },
  {
    value: 'FQVLF',
    label: 'FQVLF,  First Quantum Minerals Ltd.',
    name: 'First Quantum Minerals Ltd.',
    symbol: 'FQVLF',
  },
  {
    value: 'IDCBY',
    label: 'IDCBY,  Industrial and Commercial Bank of China Limited',
    name: 'Industrial and Commercial Bank of China Limited',
    symbol: 'IDCBY',
  },
  {
    value: 'ENCR',
    label: 'ENCR,  Ener-Core, Inc.',
    name: 'Ener-Core, Inc.',
    symbol: 'ENCR',
  },
  {
    value: 'OMHI',
    label: 'OMHI,  OM Holdings International, Inc.',
    name: 'OM Holdings International, Inc.',
    symbol: 'OMHI',
  },
  {
    value: 'SOUHY',
    label: 'SOUHY,  South32 Limited',
    name: 'South32 Limited',
    symbol: 'SOUHY',
  },
  {
    value: 'STRI',
    label: 'STRI,  STR Holdings, Inc.',
    name: 'STR Holdings, Inc.',
    symbol: 'STRI',
  },
  {
    value: 'TNEN',
    label: 'TNEN,  True North Energy Corp.',
    name: 'True North Energy Corp.',
    symbol: 'TNEN',
  },
  {
    value: 'AHG',
    label: 'AHG,  Akso Health Group',
    name: 'Akso Health Group',
    symbol: 'AHG',
  },
  {
    value: 'RVLP',
    label: 'RVLP,  RVL Pharmaceuticals plc',
    name: 'RVL Pharmaceuticals plc',
    symbol: 'RVLP',
  },
  {
    value: 'TSCDY',
    label: 'TSCDY,  Tesco PLC',
    name: 'Tesco PLC',
    symbol: 'TSCDY',
  },
  {
    value: 'DNBBY',
    label: 'DNBBY,  DNB Bank ASA',
    name: 'DNB Bank ASA',
    symbol: 'DNBBY',
  },
  {
    value: 'PEGRY',
    label: 'PEGRY,  Pennon Group Plc',
    name: 'Pennon Group Plc',
    symbol: 'PEGRY',
  },
  {
    value: 'CNTTQ',
    label: 'CNTTQ,  CannTrust Holdings Inc.',
    name: 'CannTrust Holdings Inc.',
    symbol: 'CNTTQ',
  },
  {
    value: 'WACMY',
    label: 'WACMY,  Wacom Co., Ltd.',
    name: 'Wacom Co., Ltd.',
    symbol: 'WACMY',
  },
  {
    value: 'AVIFY',
    label: 'AVIFY,  Advanced Info Service Public Company Limited',
    name: 'Advanced Info Service Public Company Limited',
    symbol: 'AVIFY',
  },
  {
    value: 'ATEYY',
    label: 'ATEYY,  Advantest Corporation',
    name: 'Advantest Corporation',
    symbol: 'ATEYY',
  },
  {
    value: 'ANGPY',
    label: 'ANGPY,  Anglo American Platinum Limited',
    name: 'Anglo American Platinum Limited',
    symbol: 'ANGPY',
  },
  {
    value: 'AOZOY',
    label: 'AOZOY,  Aozora Bank, Ltd.',
    name: 'Aozora Bank, Ltd.',
    symbol: 'AOZOY',
  },
  {
    value: 'AHKSY',
    label: 'AHKSY,  Asahi Kasei Corporation',
    name: 'Asahi Kasei Corporation',
    symbol: 'AHKSY',
  },
  {
    value: 'ASCCY',
    label: 'ASCCY,  ASICS Corporation',
    name: 'ASICS Corporation',
    symbol: 'ASCCY',
  },
  {
    value: 'APNHY',
    label: 'APNHY,  Aspen Pharmacare Holdings Limited',
    name: 'Aspen Pharmacare Holdings Limited',
    symbol: 'APNHY',
  },
  {
    value: 'ATLCY',
    label: 'ATLCY,  Atlas Copco AB',
    name: 'Atlas Copco AB',
    symbol: 'ATLCY',
  },
  {
    value: 'AYALY',
    label: 'AYALY,  Ayala Corporation',
    name: 'Ayala Corporation',
    symbol: 'AYALY',
  },
  {
    value: 'BOZTY',
    label: 'BOZTY,  Boozt AB (publ)',
    name: 'Boozt AB (publ)',
    symbol: 'BOZTY',
  },
  {
    value: 'NCBDY',
    label: 'NCBDY,  BANDAI NAMCO Holdings Inc.',
    name: 'BANDAI NAMCO Holdings Inc.',
    symbol: 'NCBDY',
  },
  {
    value: 'BKHYY',
    label: 'BKHYY,  Bank Hapoalim B.M.',
    name: 'Bank Hapoalim B.M.',
    symbol: 'BKHYY',
  },
  {
    value: 'BSEFY',
    label: 'BSEFY,  Benesse Holdings, Inc.',
    name: 'Benesse Holdings, Inc.',
    symbol: 'BSEFY',
  },
  {
    value: 'BDNNY',
    label: 'BDNNY,  Boliden AB (publ)',
    name: 'Boliden AB (publ)',
    symbol: 'BDNNY',
  },
  {
    value: 'CLBEY',
    label: 'CLBEY,  Calbee, Inc.',
    name: 'Calbee, Inc.',
    symbol: 'CLBEY',
  },
  {
    value: 'CCOEY',
    label: 'CCOEY,  Capcom Co., Ltd.',
    name: 'Capcom Co., Ltd.',
    symbol: 'CCOEY',
  },
  {
    value: 'CKHGY',
    label: 'CKHGY,  Capitec Bank Holdings Limited',
    name: 'Capitec Bank Holdings Limited',
    symbol: 'CKHGY',
  },
  {
    value: 'CSIOY',
    label: 'CSIOY,  Casio Computer Co.,Ltd.',
    name: 'Casio Computer Co.,Ltd.',
    symbol: 'CSIOY',
  },
  {
    value: 'CDEVY',
    label: 'CDEVY,  City Developments Limited',
    name: 'City Developments Limited',
    symbol: 'CDEVY',
  },
  {
    value: 'CCOJY',
    label: 'CCOJY,  Coca-Cola Bottlers Japan Holdings Inc.',
    name: 'Coca-Cola Bottlers Japan Holdings Inc.',
    symbol: 'CCOJY',
  },
  {
    value: 'CYGIY',
    label: 'CYGIY,  CyberAgent, Inc.',
    name: 'CyberAgent, Inc.',
    symbol: 'CYGIY',
  },
  {
    value: 'DBSDY',
    label: 'DBSDY,  DBS Group Holdings Ltd',
    name: 'DBS Group Holdings Ltd',
    symbol: 'DBSDY',
  },
  {
    value: 'DFKCY',
    label: 'DFKCY,  Daifuku Co., Ltd.',
    name: 'Daifuku Co., Ltd.',
    symbol: 'DFKCY',
  },
  {
    value: 'DNPLY',
    label: 'DNPLY,  Dai Nippon Printing Co., Ltd.',
    name: 'Dai Nippon Printing Co., Ltd.',
    symbol: 'DNPLY',
  },
  {
    value: 'DKILY',
    label: 'DKILY,  Daikin Industries,Ltd.',
    name: 'Daikin Industries,Ltd.',
    symbol: 'DKILY',
  },
  {
    value: 'DSNKY',
    label: 'DSNKY,  Daiichi Sankyo Company, Limited',
    name: 'Daiichi Sankyo Company, Limited',
    symbol: 'DSNKY',
  },
  {
    value: 'DIFTY',
    label: 'DIFTY,  Daito Trust Construction Co.,Ltd.',
    name: 'Daito Trust Construction Co.,Ltd.',
    symbol: 'DIFTY',
  },
  {
    value: 'DWAHY',
    label: 'DWAHY,  Daiwa House Industry Co., Ltd.',
    name: 'Daiwa House Industry Co., Ltd.',
    symbol: 'DWAHY',
  },
  {
    value: 'EJPRY',
    label: 'EJPRY,  East Japan Railway Company',
    name: 'East Japan Railway Company',
    symbol: 'EJPRY',
  },
  {
    value: 'EBCOY',
    label: 'EBCOY,  Ebara Corporation',
    name: 'Ebara Corporation',
    symbol: 'EBCOY',
  },
  {
    value: 'ESALY',
    label: 'ESALY,  Eisai Co., Ltd.',
    name: 'Eisai Co., Ltd.',
    symbol: 'ESALY',
  },
  {
    value: 'EVVTY',
    label: 'EVVTY,  Evolution AB (publ)',
    name: 'Evolution AB (publ)',
    symbol: 'EVVTY',
  },
  {
    value: 'FOVSY',
    label: 'FOVSY,  Ford Otomotiv Sanayi A.S.',
    name: 'Ford Otomotiv Sanayi A.S.',
    symbol: 'FOVSY',
  },
  {
    value: 'FUPBY',
    label: 'FUPBY,  Fuchs Petrolub SE',
    name: 'Fuchs Petrolub SE',
    symbol: 'FUPBY',
  },
  {
    value: 'FJTNY',
    label: 'FJTNY,  Fuji Media Holdings, Inc.',
    name: 'Fuji Media Holdings, Inc.',
    symbol: 'FJTNY',
  },
  {
    value: 'FUJIY',
    label: 'FUJIY,  FUJIFILM Holdings Corporation',
    name: 'FUJIFILM Holdings Corporation',
    symbol: 'FUJIY',
  },
  {
    value: 'FJTCY',
    label: 'FJTCY,  Fujitec Co., Ltd.',
    name: 'Fujitec Co., Ltd.',
    symbol: 'FJTCY',
  },
  {
    value: 'FUWAY',
    label: 'FUWAY,  Furukawa Electric Co., Ltd.',
    name: 'Furukawa Electric Co., Ltd.',
    symbol: 'FUWAY',
  },
  {
    value: 'GULRY',
    label: 'GULRY,  Guoco Group Limited',
    name: 'Guoco Group Limited',
    symbol: 'GULRY',
  },
  {
    value: 'HKUOY',
    label: 'HKUOY,  Hakuhodo DY Holdings Inc',
    name: 'Hakuhodo DY Holdings Inc',
    symbol: 'HKUOY',
  },
  {
    value: 'HOCPY',
    label: 'HOCPY,  HOYA Corporation',
    name: 'HOYA Corporation',
    symbol: 'HOCPY',
  },
  {
    value: 'IHICY',
    label: 'IHICY,  IHI Corporation',
    name: 'IHI Corporation',
    symbol: 'IHICY',
  },
  {
    value: 'IDKOY',
    label: 'IDKOY,  Idemitsu Kosan Co.,Ltd.',
    name: 'Idemitsu Kosan Co.,Ltd.',
    symbol: 'IDKOY',
  },
  {
    value: 'IESFY',
    label: 'IESFY,  Interconexión Eléctrica S.A. E.S.P.',
    name: 'Interconexión Eléctrica S.A. E.S.P.',
    symbol: 'IESFY',
  },
  {
    value: 'JGCCY',
    label: 'JGCCY,  JGC Holdings Corporation',
    name: 'JGC Holdings Corporation',
    symbol: 'JGCCY',
  },
  {
    value: 'JSCPY',
    label: 'JSCPY,  JSR Corporation',
    name: 'JSR Corporation',
    symbol: 'JSCPY',
  },
  {
    value: 'JAPSY',
    label: 'JAPSY,  Japan Airlines Co., Ltd.',
    name: 'Japan Airlines Co., Ltd.',
    symbol: 'JAPSY',
  },
  {
    value: 'JTTRY',
    label: 'JTTRY,  Japan Airport Terminal Co., Ltd.',
    name: 'Japan Airport Terminal Co., Ltd.',
    symbol: 'JTTRY',
  },
  {
    value: 'JPXGY',
    label: 'JPXGY,  Japan Exchange Group, Inc.',
    name: 'Japan Exchange Group, Inc.',
    symbol: 'JPXGY',
  },
  {
    value: 'JPSWY',
    label: 'JPSWY,  The Japan Steel Works, Ltd.',
    name: 'The Japan Steel Works, Ltd.',
    symbol: 'JPSWY',
  },
  {
    value: 'JCYGY',
    label: 'JCYGY,  Jardine Cycle & Carriage Limited',
    name: 'Jardine Cycle & Carriage Limited',
    symbol: 'JCYGY',
  },
  {
    value: 'JTEKY',
    label: 'JTEKY,  JTEKT Corporation',
    name: 'JTEKT Corporation',
    symbol: 'JTEKY',
  },
  {
    value: 'KAJMY',
    label: 'KAJMY,  Kajima Corporation',
    name: 'Kajima Corporation',
    symbol: 'KAJMY',
  },
  {
    value: 'KAEPY',
    label: 'KAEPY,  The Kansai Electric Power Company, Incorporated',
    name: 'The Kansai Electric Power Company, Incorporated',
    symbol: 'KAEPY',
  },
  {
    value: 'KWHIY',
    label: 'KWHIY,  Kawasaki Heavy Industries, Ltd.',
    name: 'Kawasaki Heavy Industries, Ltd.',
    symbol: 'KWHIY',
  },
  {
    value: 'KAIKY',
    label: 'KAIKY,  Kawasaki Kisen Kaisha, Ltd.',
    name: 'Kawasaki Kisen Kaisha, Ltd.',
    symbol: 'KAIKY',
  },
  {
    value: 'KOTMY',
    label: 'KOTMY,  Koito Manufacturing Co., Ltd.',
    name: 'Koito Manufacturing Co., Ltd.',
    symbol: 'KOTMY',
  },
  {
    value: 'KSRYY',
    label: 'KSRYY,  KOSÉ Corporation',
    name: 'KOSÉ Corporation',
    symbol: 'KSRYY',
  },
  {
    value: 'KURRY',
    label: 'KURRY,  Kuraray Co., Ltd.',
    name: 'Kuraray Co., Ltd.',
    symbol: 'KURRY',
  },
  {
    value: 'KYSEY',
    label: 'KYSEY,  Kyushu Electric Power Company, Incorporated',
    name: 'Kyushu Electric Power Company, Incorporated',
    symbol: 'KYSEY',
  },
  {
    value: 'LDSCY',
    label: 'LDSCY,  Land Securities Group plc',
    name: 'Land Securities Group plc',
    symbol: 'LDSCY',
  },
  {
    value: 'JSGRY',
    label: 'JSGRY,  LIXIL Corporation',
    name: 'LIXIL Corporation',
    symbol: 'JSGRY',
  },
  {
    value: 'MSADY',
    label: 'MSADY,  MS&AD Insurance Group Holdings, Inc.',
    name: 'MS&AD Insurance Group Holdings, Inc.',
    symbol: 'MSADY',
  },
  {
    value: 'MTHRY',
    label: 'MTHRY,  M3, Inc.',
    name: 'M3, Inc.',
    symbol: 'MTHRY',
  },
  {
    value: 'MCARY',
    label: 'MCARY,  Mercari, Inc.',
    name: 'Mercari, Inc.',
    symbol: 'MCARY',
  },
  {
    value: 'MTLHY',
    label: 'MTLHY,  Mitsubishi Chemical Group Corporation',
    name: 'Mitsubishi Chemical Group Corporation',
    symbol: 'MTLHY',
  },
  {
    value: 'MIELY',
    label: 'MIELY,  Mitsubishi Electric Corporation',
    name: 'Mitsubishi Electric Corporation',
    symbol: 'MIELY',
  },
  {
    value: 'MITUY',
    label: 'MITUY,  Mitsui Chemicals, Inc.',
    name: 'Mitsui Chemicals, Inc.',
    symbol: 'MITUY',
  },
  {
    value: 'MTSFY',
    label: 'MTSFY,  Mitsui Fudosan Co., Ltd.',
    name: 'Mitsui Fudosan Co., Ltd.',
    symbol: 'MTSFY',
  },
  {
    value: 'MMSMY',
    label: 'MMSMY,  Mitsui Mining & Smelting Co., Ltd.',
    name: 'Mitsui Mining & Smelting Co., Ltd.',
    symbol: 'MMSMY',
  },
  {
    value: 'MSLOY',
    label: 'MSLOY,  Mitsui O.S.K. Lines, Ltd.',
    name: 'Mitsui O.S.K. Lines, Ltd.',
    symbol: 'MSLOY',
  },
  {
    value: 'MONOY',
    label: 'MONOY,  MonotaRO Co., Ltd.',
    name: 'MonotaRO Co., Ltd.',
    symbol: 'MONOY',
  },
  {
    value: 'NTDTY',
    label: 'NTDTY,  NTT DATA Corporation',
    name: 'NTT DATA Corporation',
    symbol: 'NTDTY',
  },
  {
    value: 'NPKYY',
    label: 'NPKYY,  Nippon Kayaku Co., Ltd.',
    name: 'Nippon Kayaku Co., Ltd.',
    symbol: 'NPKYY',
  },
  {
    value: 'NPSCY',
    label: 'NPSCY,  Nippon Steel Corporation',
    name: 'Nippon Steel Corporation',
    symbol: 'NPSCY',
  },
  {
    value: 'NPPNY',
    label: 'NPPNY,  Nippon Shinyaku Co., Ltd.',
    name: 'Nippon Shinyaku Co., Ltd.',
    symbol: 'NPPNY',
  },
  {
    value: 'NPNYY',
    label: 'NPNYY,  Nippon Yusen Kabushiki Kaisha',
    name: 'Nippon Yusen Kabushiki Kaisha',
    symbol: 'NPNYY',
  },
  {
    value: 'NCLTY',
    label: 'NCLTY,  Nitori Holdings Co., Ltd.',
    name: 'Nitori Holdings Co., Ltd.',
    symbol: 'NCLTY',
  },
  {
    value: 'NDEKY',
    label: 'NDEKY,  Nitto Denko Corporation',
    name: 'Nitto Denko Corporation',
    symbol: 'NDEKY',
  },
  {
    value: 'NPSKY',
    label: 'NPSKY,  NSK Ltd.',
    name: 'NSK Ltd.',
    symbol: 'NPSKY',
  },
  {
    value: 'OCPNY',
    label: 'OCPNY,  Olympus Corporation',
    name: 'Olympus Corporation',
    symbol: 'OCPNY',
  },
  {
    value: 'OMRNY',
    label: 'OMRNY,  OMRON Corporation',
    name: 'OMRON Corporation',
    symbol: 'OMRNY',
  },
  {
    value: 'OPHLY',
    label: 'OPHLY,  Ono Pharmaceutical Co., Ltd.',
    name: 'Ono Pharmaceutical Co., Ltd.',
    symbol: 'OPHLY',
  },
  {
    value: 'OLCLY',
    label: 'OLCLY,  Oriental Land Co., Ltd.',
    name: 'Oriental Land Co., Ltd.',
    symbol: 'OLCLY',
  },
  {
    value: 'OVCHY',
    label: 'OVCHY,  Oversea-Chinese Banking Corporation Limited',
    name: 'Oversea-Chinese Banking Corporation Limited',
    symbol: 'OVCHY',
  },
  {
    value: 'DQJCY',
    label: 'DQJCY,  Pan Pacific International Holdings Corporation',
    name: 'Pan Pacific International Holdings Corporation',
    symbol: 'DQJCY',
  },
  {
    value: 'PKCOY',
    label: 'PKCOY,  PARK24 Co., Ltd.',
    name: 'PARK24 Co., Ltd.',
    symbol: 'PKCOY',
  },
  {
    value: 'RNECY',
    label: 'RNECY,  Renesas Electronics Corporation',
    name: 'Renesas Electronics Corporation',
    symbol: 'RNECY',
  },
  {
    value: 'RICOY',
    label: 'RICOY,  Ricoh Company, Ltd.',
    name: 'Ricoh Company, Ltd.',
    symbol: 'RICOY',
  },
  {
    value: 'ROHCY',
    label: 'ROHCY,  ROHM Co., Ltd.',
    name: 'ROHM Co., Ltd.',
    symbol: 'ROHCY',
  },
  {
    value: 'RBSFY',
    label: 'RBSFY,  Rubis',
    name: 'Rubis',
    symbol: 'RBSFY',
  },
  {
    value: 'SMCAY',
    label: 'SMCAY,  SMC Corporation',
    name: 'SMC Corporation',
    symbol: 'SMCAY',
  },
  {
    value: 'SNPHY',
    label: 'SNPHY,  Santen Pharmaceutical Co., Ltd.',
    name: 'Santen Pharmaceutical Co., Ltd.',
    symbol: 'SNPHY',
  },
  {
    value: 'SGAMY',
    label: 'SGAMY,  Sega Sammy Holdings Inc.',
    name: 'Sega Sammy Holdings Inc.',
    symbol: 'SGAMY',
  },
  {
    value: 'SKHSY',
    label: 'SKHSY,  Sekisui House, Ltd.',
    name: 'Sekisui House, Ltd.',
    symbol: 'SKHSY',
  },
  {
    value: 'SKSUY',
    label: 'SKSUY,  Sekisui Chemical Co., Ltd.',
    name: 'Sekisui Chemical Co., Ltd.',
    symbol: 'SKSUY',
  },
  {
    value: 'SHCAY',
    label: 'SHCAY,  Sharp Corporation',
    name: 'Sharp Corporation',
    symbol: 'SHCAY',
  },
  {
    value: 'SHMUY',
    label: 'SHMUY,  Shimizu Corporation',
    name: 'Shimizu Corporation',
    symbol: 'SHMUY',
  },
  {
    value: 'SMNNY',
    label: 'SMNNY,  Shimano Inc.',
    name: 'Shimano Inc.',
    symbol: 'SMNNY',
  },
  {
    value: 'SGIOY',
    label: 'SGIOY,  Shionogi & Co., Ltd.',
    name: 'Shionogi & Co., Ltd.',
    symbol: 'SGIOY',
  },
  {
    value: 'SHWDY',
    label: 'SHWDY,  Showa Denko K.K.',
    name: 'Showa Denko K.K.',
    symbol: 'SHWDY',
  },
  {
    value: 'SPXCY',
    label: 'SPXCY,  Singapore Exchange Limited',
    name: 'Singapore Exchange Limited',
    symbol: 'SPXCY',
  },
  {
    value: 'SOBKY',
    label: 'SOBKY,  SoftBank Corp.',
    name: 'SoftBank Corp.',
    symbol: 'SOBKY',
  },
  {
    value: 'SMPNY',
    label: 'SMPNY,  Sompo Holdings, Inc.',
    name: 'Sompo Holdings, Inc.',
    symbol: 'SMPNY',
  },
  {
    value: 'SQNNY',
    label: 'SQNNY,  Square Enix Holdings Co., Ltd.',
    name: 'Square Enix Holdings Co., Ltd.',
    symbol: 'SQNNY',
  },
  {
    value: 'SUOPY',
    label: 'SUOPY,  Sumco Corporation',
    name: 'Sumco Corporation',
    symbol: 'SUOPY',
  },
  {
    value: 'SSUMY',
    label: 'SSUMY,  Sumitomo Corporation',
    name: 'Sumitomo Corporation',
    symbol: 'SSUMY',
  },
  {
    value: 'SMTOY',
    label: 'SMTOY,  Sumitomo Electric Industries, Ltd.',
    name: 'Sumitomo Electric Industries, Ltd.',
    symbol: 'SMTOY',
  },
  {
    value: 'SOHVY',
    label: 'SOHVY,  Sumitomo Heavy Industries, Ltd.',
    name: 'Sumitomo Heavy Industries, Ltd.',
    symbol: 'SOHVY',
  },
  {
    value: 'SUTNY',
    label: 'SUTNY,  Sumitomo Mitsui Trust Holdings, Inc.',
    name: 'Sumitomo Mitsui Trust Holdings, Inc.',
    symbol: 'SUTNY',
  },
  {
    value: 'SMMYY',
    label: 'SMMYY,  Sumitomo Metal Mining Co., Ltd.',
    name: 'Sumitomo Metal Mining Co., Ltd.',
    symbol: 'SMMYY',
  },
  {
    value: 'STBFY',
    label: 'STBFY,  Suntory Beverage & Food Limited',
    name: 'Suntory Beverage & Food Limited',
    symbol: 'STBFY',
  },
  {
    value: 'SSMXY',
    label: 'SSMXY,  Sysmex Corporation',
    name: 'Sysmex Corporation',
    symbol: 'SSMXY',
  },
  {
    value: 'TDHOY',
    label: 'TDHOY,  T&D Holdings, Inc.',
    name: 'T&D Holdings, Inc.',
    symbol: 'TDHOY',
  },
  {
    value: 'TISCY',
    label: 'TISCY,  Taisei Corporation',
    name: 'Taisei Corporation',
    symbol: 'TISCY',
  },
  {
    value: 'THYCY',
    label: 'THYCY,  Taiheiyo Cement Corporation',
    name: 'Taiheiyo Cement Corporation',
    symbol: 'THYCY',
  },
  {
    value: 'TAIPY',
    label: 'TAIPY,  Taisho Pharmaceutical Holdings Co., Ltd.',
    name: 'Taisho Pharmaceutical Holdings Co., Ltd.',
    symbol: 'TAIPY',
  },
  {
    value: 'TYOYY',
    label: 'TYOYY,  Taiyo Yuden Co., Ltd.',
    name: 'Taiyo Yuden Co., Ltd.',
    symbol: 'TYOYY',
  },
  {
    value: 'TCCPY',
    label: 'TCCPY,  TechnoPro Holdings, Inc.',
    name: 'TechnoPro Holdings, Inc.',
    symbol: 'TCCPY',
  },
  {
    value: 'TINLY',
    label: 'TINLY,  Teijin Limited',
    name: 'Teijin Limited',
    symbol: 'TINLY',
  },
  {
    value: 'THUPY',
    label: 'THUPY,  Thule Group AB (publ)',
    name: 'Thule Group AB (publ)',
    symbol: 'THUPY',
  },
  {
    value: 'TKCBY',
    label: 'TKCBY,  Tokai Carbon Co., Ltd.',
    name: 'Tokai Carbon Co., Ltd.',
    symbol: 'TKCBY',
  },
  {
    value: 'TKOMY',
    label: 'TKOMY,  Tokio Marine Holdings, Inc.',
    name: 'Tokio Marine Holdings, Inc.',
    symbol: 'TKOMY',
  },
  {
    value: 'TOELY',
    label: 'TOELY,  Tokyo Electron Limited',
    name: 'Tokyo Electron Limited',
    symbol: 'TOELY',
  },
  {
    value: 'TKGSY',
    label: 'TKGSY,  Tokyo Gas Co.,Ltd.',
    name: 'Tokyo Gas Co.,Ltd.',
    symbol: 'TKGSY',
  },
  {
    value: 'TOKUY',
    label: 'TOKUY,  Tokyu Corporation',
    name: 'Tokyu Corporation',
    symbol: 'TOKUY',
  },
  {
    value: 'TOPPY',
    label: 'TOPPY,  Toppan Inc.',
    name: 'Toppan Inc.',
    symbol: 'TOPPY',
  },
  {
    value: 'TOTDY',
    label: 'TOTDY,  Toto Ltd.',
    name: 'Toto Ltd.',
    symbol: 'TOTDY',
  },
  {
    value: 'TSUKY',
    label: 'TSUKY,  Toyo Suisan Kaisha, Ltd.',
    name: 'Toyo Suisan Kaisha, Ltd.',
    symbol: 'TSUKY',
  },
  {
    value: 'TYOBY',
    label: 'TYOBY,  Toyobo Co., Ltd.',
    name: 'Toyobo Co., Ltd.',
    symbol: 'TYOBY',
  },
  {
    value: 'TMICY',
    label: 'TMICY,  Trend Micro Incorporated',
    name: 'Trend Micro Incorporated',
    symbol: 'TMICY',
  },
  {
    value: 'TRNLY',
    label: 'TRNLY,  The Trendlines Group Ltd.',
    name: 'The Trendlines Group Ltd.',
    symbol: 'TRNLY',
  },
  {
    value: 'TKGBY',
    label: 'TKGBY,  Turkiye Garanti Bankasi A.S.',
    name: 'Turkiye Garanti Bankasi A.S.',
    symbol: 'TKGBY',
  },
  {
    value: 'THDDY',
    label: 'THDDY,  TV Asahi Holdings Corporation',
    name: 'TV Asahi Holdings Corporation',
    symbol: 'THDDY',
  },
  {
    value: 'UNICY',
    label: 'UNICY,  Unicharm Corporation',
    name: 'Unicharm Corporation',
    symbol: 'UNICY',
  },
  {
    value: 'UPMMY',
    label: 'UPMMY,  UPM-Kymmene Oyj',
    name: 'UPM-Kymmene Oyj',
    symbol: 'UPMMY',
  },
  {
    value: 'VLOWY',
    label: 'VLOWY,  Vallourec S.A.',
    name: 'Vallourec S.A.',
    symbol: 'VLOWY',
  },
  {
    value: 'WACLY',
    label: 'WACLY,  Wacoal Holdings Corp.',
    name: 'Wacoal Holdings Corp.',
    symbol: 'WACLY',
  },
  {
    value: 'WJRYY',
    label: 'WJRYY,  West Japan Railway Company',
    name: 'West Japan Railway Company',
    symbol: 'WJRYY',
  },
  {
    value: 'YKLTY',
    label: 'YKLTY,  Yakult Honsha Co.,Ltd.',
    name: 'Yakult Honsha Co.,Ltd.',
    symbol: 'YKLTY',
  },
  {
    value: 'YAMHY',
    label: 'YAMHY,  Yamaha Motor Co., Ltd.',
    name: 'Yamaha Motor Co., Ltd.',
    symbol: 'YAMHY',
  },
  {
    value: 'YATRY',
    label: 'YATRY,  Yamato Holdings Co., Ltd.',
    name: 'Yamato Holdings Co., Ltd.',
    symbol: 'YATRY',
  },
  {
    value: 'YOKEY',
    label: 'YOKEY,  Yokogawa Electric Corporation',
    name: 'Yokogawa Electric Corporation',
    symbol: 'YOKEY',
  },
  {
    value: 'YAHOY',
    label: 'YAHOY,  Z Holdings Corporation',
    name: 'Z Holdings Corporation',
    symbol: 'YAHOY',
  },
  {
    value: 'SRTTY',
    label: 'SRTTY,  ZOZO, Inc.',
    name: 'ZOZO, Inc.',
    symbol: 'SRTTY',
  },
  {
    value: 'BDORY',
    label: 'BDORY,  Banco do Brasil S.A.',
    name: 'Banco do Brasil S.A.',
    symbol: 'BDORY',
  },
  {
    value: 'COGNY',
    label: 'COGNY,  Cogna Educação S.A.',
    name: 'Cogna Educação S.A.',
    symbol: 'COGNY',
  },
  {
    value: 'EGIEY',
    label: 'EGIEY,  Engie Brasil Energia S.A.',
    name: 'Engie Brasil Energia S.A.',
    symbol: 'EGIEY',
  },
  {
    value: 'HYPMY',
    label: 'HYPMY,  Hypera S.A.',
    name: 'Hypera S.A.',
    symbol: 'HYPMY',
  },
  {
    value: 'LZRFY',
    label: 'LZRFY,  Localiza Rent a Car S.A.',
    name: 'Localiza Rent a Car S.A.',
    symbol: 'LZRFY',
  },
  {
    value: 'LRENY',
    label: 'LRENY,  Lojas Renner S.A.',
    name: 'Lojas Renner S.A.',
    symbol: 'LRENY',
  },
  {
    value: 'WEGZY',
    label: 'WEGZY,  WEG S.A.',
    name: 'WEG S.A.',
    symbol: 'WEGZY',
  },
  {
    value: 'AAVMY',
    label: 'AAVMY,  ABN AMRO Bank N.V.',
    name: 'ABN AMRO Bank N.V.',
    symbol: 'AAVMY',
  },
  {
    value: 'AKZOY',
    label: 'AKZOY,  Akzo Nobel N.V.',
    name: 'Akzo Nobel N.V.',
    symbol: 'AKZOY',
  },
  {
    value: 'CSNVY',
    label: 'CSNVY,  Corbion N.V.',
    name: 'Corbion N.V.',
    symbol: 'CSNVY',
  },
  {
    value: 'HKHHY',
    label: 'HKHHY,  Heineken Holding N.V.',
    name: 'Heineken Holding N.V.',
    symbol: 'HKHHY',
  },
  {
    value: 'HEINY',
    label: 'HEINY,  Heineken N.V.',
    name: 'Heineken N.V.',
    symbol: 'HEINY',
  },
  {
    value: 'ADRNY',
    label: 'ADRNY,  Koninklijke Ahold Delhaize N.V.',
    name: 'Koninklijke Ahold Delhaize N.V.',
    symbol: 'ADRNY',
  },
  {
    value: 'VOPKY',
    label: 'VOPKY,  Koninklijke Vopak N.V.',
    name: 'Koninklijke Vopak N.V.',
    symbol: 'VOPKY',
  },
  {
    value: 'NNGRY',
    label: 'NNGRY,  NN Group N.V.',
    name: 'NN Group N.V.',
    symbol: 'NNGRY',
  },
  {
    value: 'RANJY',
    label: 'RANJY,  Randstad N.V.',
    name: 'Randstad N.V.',
    symbol: 'RANJY',
  },
  {
    value: 'RDSMY',
    label: 'RDSMY,  Koninklijke DSM N.V.',
    name: 'Koninklijke DSM N.V.',
    symbol: 'RDSMY',
  },
  {
    value: 'PHPPY',
    label: 'PHPPY,  Signify N.V.',
    name: 'Signify N.V.',
    symbol: 'PHPPY',
  },
  {
    value: 'TMOAY',
    label: 'TMOAY,  TomTom N.V.',
    name: 'TomTom N.V.',
    symbol: 'TMOAY',
  },
  {
    value: 'WTKWY',
    label: 'WTKWY,  Wolters Kluwer N.V.',
    name: 'Wolters Kluwer N.V.',
    symbol: 'WTKWY',
  },
  {
    value: 'AGLXY',
    label: 'AGLXY,  AGL Energy Limited',
    name: 'AGL Energy Limited',
    symbol: 'AGLXY',
  },
  {
    value: 'ASXFY',
    label: 'ASXFY,  ASX Limited',
    name: 'ASX Limited',
    symbol: 'ASXFY',
  },
  {
    value: 'ACOPY',
    label: 'ACOPY,  The a2 Milk Company Limited',
    name: 'The a2 Milk Company Limited',
    symbol: 'ACOPY',
  },
  {
    value: 'AWCMY',
    label: 'AWCMY,  Alumina Limited',
    name: 'Alumina Limited',
    symbol: 'AWCMY',
  },
  {
    value: 'ANSLY',
    label: 'ANSLY,  Ansell Limited',
    name: 'Ansell Limited',
    symbol: 'ANSLY',
  },
  {
    value: 'ANZBY',
    label: 'ANZBY,  Australia and New Zealand Banking Group Limited',
    name: 'Australia and New Zealand Banking Group Limited',
    symbol: 'ANZBY',
  },
  {
    value: 'BLSFY',
    label: 'BLSFY,  BlueScope Steel Limited',
    name: 'BlueScope Steel Limited',
    symbol: 'BLSFY',
  },
  {
    value: 'BXBLY',
    label: 'BXBLY,  Brambles Limited',
    name: 'Brambles Limited',
    symbol: 'BXBLY',
  },
  {
    value: 'CSLLY',
    label: 'CSLLY,  CSL Limited',
    name: 'CSL Limited',
    symbol: 'CSLLY',
  },
  {
    value: 'CSXXY',
    label: 'CSXXY,  carsales.com Ltd',
    name: 'carsales.com Ltd',
    symbol: 'CSXXY',
  },
  {
    value: 'CHEOY',
    label: 'CHEOY,  Cochlear Limited',
    name: 'Cochlear Limited',
    symbol: 'CHEOY',
  },
  {
    value: 'CMWAY',
    label: 'CMWAY,  Commonwealth Bank of Australia',
    name: 'Commonwealth Bank of Australia',
    symbol: 'CMWAY',
  },
  {
    value: 'DMZPY',
    label: "DMZPY,  Domino's Pizza Enterprises Limited",
    name: "Domino's Pizza Enterprises Limited",
    symbol: 'DMZPY',
  },
  {
    value: 'ILKAY',
    label: 'ILKAY,  Iluka Resources Limited',
    name: 'Iluka Resources Limited',
    symbol: 'ILKAY',
  },
  {
    value: 'LLESY',
    label: 'LLESY,  Lendlease Group',
    name: 'Lendlease Group',
    symbol: 'LLESY',
  },
  {
    value: 'MQBKY',
    label: 'MQBKY,  Macquarie Group Limited',
    name: 'Macquarie Group Limited',
    symbol: 'MQBKY',
  },
  {
    value: 'MALRY',
    label: 'MALRY,  Mineral Resources Limited',
    name: 'Mineral Resources Limited',
    symbol: 'MALRY',
  },
  {
    value: 'OCLDY',
    label: 'OCLDY,  Orica Limited',
    name: 'Orica Limited',
    symbol: 'OCLDY',
  },
  {
    value: 'QBIEY',
    label: 'QBIEY,  QBE Insurance Group Limited',
    name: 'QBE Insurance Group Limited',
    symbol: 'QBIEY',
  },
  {
    value: 'RMYHY',
    label: 'RMYHY,  Ramsay Health Care Limited',
    name: 'Ramsay Health Care Limited',
    symbol: 'RMYHY',
  },
  {
    value: 'SKLTY',
    label: 'SKLTY,  SEEK Limited',
    name: 'SEEK Limited',
    symbol: 'SKLTY',
  },
  {
    value: 'SKHHY',
    label: 'SKHHY,  Sonic Healthcare Limited',
    name: 'Sonic Healthcare Limited',
    symbol: 'SKHHY',
  },
  {
    value: 'SPHRY',
    label: 'SPHRY,  Starpharma Holdings Limited',
    name: 'Starpharma Holdings Limited',
    symbol: 'SPHRY',
  },
  {
    value: 'SNMCY',
    label: 'SNMCY,  Suncorp Group Limited',
    name: 'Suncorp Group Limited',
    symbol: 'SNMCY',
  },
  {
    value: 'WFAFY',
    label: 'WFAFY,  Wesfarmers Limited',
    name: 'Wesfarmers Limited',
    symbol: 'WFAFY',
  },
  {
    value: 'WYGPY',
    label: 'WYGPY,  Worley Limited',
    name: 'Worley Limited',
    symbol: 'WYGPY',
  },
  {
    value: 'AGESY',
    label: 'AGESY,  ageas SA/NV',
    name: 'ageas SA/NV',
    symbol: 'AGESY',
  },
  {
    value: 'BPOSY',
    label: 'BPOSY,  bpost NV/SA',
    name: 'bpost NV/SA',
    symbol: 'BPOSY',
  },
  {
    value: 'CUYTY',
    label: 'CUYTY,  Etn. Fr. Colruyt NV',
    name: 'Etn. Fr. Colruyt NV',
    symbol: 'CUYTY',
  },
  {
    value: 'GBLBY',
    label: 'GBLBY,  Groupe Bruxelles Lambert SA',
    name: 'Groupe Bruxelles Lambert SA',
    symbol: 'GBLBY',
  },
  {
    value: 'BGAOY',
    label: 'BGAOY,  Proximus PLC',
    name: 'Proximus PLC',
    symbol: 'BGAOY',
  },
  {
    value: 'TLGHY',
    label: 'TLGHY,  Telenet Group Holding NV',
    name: 'Telenet Group Holding NV',
    symbol: 'TLGHY',
  },
  {
    value: 'UCBJY',
    label: 'UCBJY,  UCB SA',
    name: 'UCB SA',
    symbol: 'UCBJY',
  },
  {
    value: 'UMICY',
    label: 'UMICY,  Umicore SA',
    name: 'Umicore SA',
    symbol: 'UMICY',
  },
  {
    value: 'AMKBY',
    label: 'AMKBY,  A.P. Møller - Mærsk A/S',
    name: 'A.P. Møller - Mærsk A/S',
    symbol: 'AMKBY',
  },
  {
    value: 'AMBBY',
    label: 'AMBBY,  Ambu A/S',
    name: 'Ambu A/S',
    symbol: 'AMBBY',
  },
  {
    value: 'BVNRY',
    label: 'BVNRY,  Bavarian Nordic A/S',
    name: 'Bavarian Nordic A/S',
    symbol: 'BVNRY',
  },
  {
    value: 'CHYHY',
    label: 'CHYHY,  Chr. Hansen Holding A/S',
    name: 'Chr. Hansen Holding A/S',
    symbol: 'CHYHY',
  },
  {
    value: 'CABGY',
    label: 'CABGY,  Carlsberg A/S',
    name: 'Carlsberg A/S',
    symbol: 'CABGY',
  },
  {
    value: 'CLPBY',
    label: 'CLPBY,  Coloplast A/S',
    name: 'Coloplast A/S',
    symbol: 'CLPBY',
  },
  {
    value: 'DNKEY',
    label: 'DNKEY,  Danske Bank A/S',
    name: 'Danske Bank A/S',
    symbol: 'DNKEY',
  },
  {
    value: 'WILYY',
    label: 'WILYY,  Demant A/S',
    name: 'Demant A/S',
    symbol: 'WILYY',
  },
  {
    value: 'DSDVY',
    label: 'DSDVY,  DSV A/S',
    name: 'DSV A/S',
    symbol: 'DSDVY',
  },
  {
    value: 'FLIDY',
    label: 'FLIDY,  FLSmidth & Co. A/S',
    name: 'FLSmidth & Co. A/S',
    symbol: 'FLIDY',
  },
  {
    value: 'GNNDY',
    label: 'GNNDY,  GN Store Nord A/S',
    name: 'GN Store Nord A/S',
    symbol: 'GNNDY',
  },
  {
    value: 'ISSDY',
    label: 'ISSDY,  ISS A/S',
    name: 'ISS A/S',
    symbol: 'ISSDY',
  },
  {
    value: 'NVZMY',
    label: 'NVZMY,  Novozymes A/S',
    name: 'Novozymes A/S',
    symbol: 'NVZMY',
  },
  {
    value: 'PANDY',
    label: 'PANDY,  Pandora A/S',
    name: 'Pandora A/S',
    symbol: 'PANDY',
  },
  {
    value: 'TPDKY',
    label: 'TPDKY,  Topdanmark A/S',
    name: 'Topdanmark A/S',
    symbol: 'TPDKY',
  },
  {
    value: 'VWDRY',
    label: 'VWDRY,  Vestas Wind Systems A/S',
    name: 'Vestas Wind Systems A/S',
    symbol: 'VWDRY',
  },
  {
    value: 'AIAGY',
    label: 'AIAGY,  Aurubis AG',
    name: 'Aurubis AG',
    symbol: 'AIAGY',
  },
  {
    value: 'BASFY',
    label: 'BASFY,  BASF SE',
    name: 'BASF SE',
    symbol: 'BASFY',
  },
  {
    value: 'BDRFY',
    label: 'BDRFY,  Beiersdorf Aktiengesellschaft',
    name: 'Beiersdorf Aktiengesellschaft',
    symbol: 'BDRFY',
  },
  {
    value: 'BFLBY',
    label: 'BFLBY,  Bilfinger SE',
    name: 'Bilfinger SE',
    symbol: 'BFLBY',
  },
  {
    value: 'BNTGY',
    label: 'BNTGY,  Brenntag SE',
    name: 'Brenntag SE',
    symbol: 'BNTGY',
  },
  {
    value: 'CZMWY',
    label: 'CZMWY,  Carl Zeiss Meditec AG',
    name: 'Carl Zeiss Meditec AG',
    symbol: 'CZMWY',
  },
  {
    value: 'CRZBY',
    label: 'CRZBY,  Commerzbank AG',
    name: 'Commerzbank AG',
    symbol: 'CRZBY',
  },
  {
    value: 'CTTAY',
    label: 'CTTAY,  Continental Aktiengesellschaft',
    name: 'Continental Aktiengesellschaft',
    symbol: 'CTTAY',
  },
  {
    value: 'COVTY',
    label: 'COVTY,  Covestro AG',
    name: 'Covestro AG',
    symbol: 'COVTY',
  },
  {
    value: 'DBOEY',
    label: 'DBOEY,  Deutsche Börse AG',
    name: 'Deutsche Börse AG',
    symbol: 'DBOEY',
  },
  {
    value: 'DLAKY',
    label: 'DLAKY,  Deutsche Lufthansa AG',
    name: 'Deutsche Lufthansa AG',
    symbol: 'DLAKY',
  },
  {
    value: 'DPSGY',
    label: 'DPSGY,  Deutsche Post AG',
    name: 'Deutsche Post AG',
    symbol: 'DPSGY',
  },
  {
    value: 'DTCWY',
    label: 'DTCWY,  Deutsche Wohnen SE',
    name: 'Deutsche Wohnen SE',
    symbol: 'DTCWY',
  },
  {
    value: 'DURYY',
    label: 'DURYY,  Dürr Aktiengesellschaft',
    name: 'Dürr Aktiengesellschaft',
    symbol: 'DURYY',
  },
  {
    value: 'FPRUY',
    label: 'FPRUY,  Fraport AG',
    name: 'Fraport AG',
    symbol: 'FPRUY',
  },
  {
    value: 'FSNUY',
    label: 'FSNUY,  Fresenius SE & Co. KGaA',
    name: 'Fresenius SE & Co. KGaA',
    symbol: 'FSNUY',
  },
  {
    value: 'HHULY',
    label: 'HHULY,  Hamburger Hafen und Logistik Aktiengesellschaft',
    name: 'Hamburger Hafen und Logistik Aktiengesellschaft',
    symbol: 'HHULY',
  },
  {
    value: 'HVRRY',
    label: 'HVRRY,  Hannover Rück SE',
    name: 'Hannover Rück SE',
    symbol: 'HVRRY',
  },
  {
    value: 'HPGLY',
    label: 'HPGLY,  Hapag-Lloyd Aktiengesellschaft',
    name: 'Hapag-Lloyd Aktiengesellschaft',
    symbol: 'HPGLY',
  },
  {
    value: 'HDELY',
    label: 'HDELY,  HeidelbergCement AG',
    name: 'HeidelbergCement AG',
    symbol: 'HDELY',
  },
  {
    value: 'HENKY',
    label: 'HENKY,  Henkel AG & Co. KGaA',
    name: 'Henkel AG & Co. KGaA',
    symbol: 'HENKY',
  },
  {
    value: 'HENOY',
    label: 'HENOY,  Henkel AG & Co. KGaA',
    name: 'Henkel AG & Co. KGaA',
    symbol: 'HENOY',
  },
  {
    value: 'BOSSY',
    label: 'BOSSY,  Hugo Boss AG',
    name: 'Hugo Boss AG',
    symbol: 'BOSSY',
  },
  {
    value: 'KPLUY',
    label: 'KPLUY,  K+S Aktiengesellschaft',
    name: 'K+S Aktiengesellschaft',
    symbol: 'KPLUY',
  },
  {
    value: 'KIGRY',
    label: 'KIGRY,  KION GROUP AG',
    name: 'KION GROUP AG',
    symbol: 'KIGRY',
  },
  {
    value: 'KNRRY',
    label: 'KNRRY,  Knorr-Bremse Aktiengesellschaft',
    name: 'Knorr-Bremse Aktiengesellschaft',
    symbol: 'KNRRY',
  },
  {
    value: 'KRNTY',
    label: 'KRNTY,  Krones AG',
    name: 'Krones AG',
    symbol: 'KRNTY',
  },
  {
    value: 'KUKAY',
    label: 'KUKAY,  KUKA Aktiengesellschaft',
    name: 'KUKA Aktiengesellschaft',
    symbol: 'KUKAY',
  },
  {
    value: 'LNNNY',
    label: 'LNNNY,  LEONI AG',
    name: 'LEONI AG',
    symbol: 'LNNNY',
  },
  {
    value: 'MKKGY',
    label: 'MKKGY,  MERCK Kommanditgesellschaft auf Aktien',
    name: 'MERCK Kommanditgesellschaft auf Aktien',
    symbol: 'MKKGY',
  },
  {
    value: 'MTUAY',
    label: 'MTUAY,  MTU Aero Engines AG',
    name: 'MTU Aero Engines AG',
    symbol: 'MTUAY',
  },
  {
    value: 'MURGY',
    label:
      'MURGY,  Münchener Rückversicherungs-Gesellschaft Aktiengesellschaft in München',
    name: 'Münchener Rückversicherungs-Gesellschaft Aktiengesellschaft in München',
    symbol: 'MURGY',
  },
  {
    value: 'POAHY',
    label: 'POAHY,  Porsche Automobil Holding SE',
    name: 'Porsche Automobil Holding SE',
    symbol: 'POAHY',
  },
  {
    value: 'PBSFY',
    label: 'PBSFY,  ProSiebenSat.1 Media SE',
    name: 'ProSiebenSat.1 Media SE',
    symbol: 'PBSFY',
  },
  {
    value: 'PUMSY',
    label: 'PUMSY,  PUMA SE',
    name: 'PUMA SE',
    symbol: 'PUMSY',
  },
  {
    value: 'RGLXY',
    label: 'RGLXY,  RTL Group S.A.',
    name: 'RTL Group S.A.',
    symbol: 'RGLXY',
  },
  {
    value: 'RWEOY',
    label: 'RWEOY,  RWE Aktiengesellschaft',
    name: 'RWE Aktiengesellschaft',
    symbol: 'RWEOY',
  },
  {
    value: 'RNMBY',
    label: 'RNMBY,  Rheinmetall AG',
    name: 'Rheinmetall AG',
    symbol: 'RNMBY',
  },
  {
    value: 'SZGPY',
    label: 'SZGPY,  Salzgitter AG',
    name: 'Salzgitter AG',
    symbol: 'SZGPY',
  },
  {
    value: 'SMMNY',
    label: 'SMMNY,  Siemens Healthineers AG',
    name: 'Siemens Healthineers AG',
    symbol: 'SMMNY',
  },
  {
    value: 'SYIEY',
    label: 'SYIEY,  Symrise AG',
    name: 'Symrise AG',
    symbol: 'SYIEY',
  },
  {
    value: 'TKAMY',
    label: 'TKAMY,  thyssenkrupp AG',
    name: 'thyssenkrupp AG',
    symbol: 'TKAMY',
  },
  {
    value: 'VWAPY',
    label: 'VWAPY,  Volkswagen AG',
    name: 'Volkswagen AG',
    symbol: 'VWAPY',
  },
  {
    value: 'VONOY',
    label: 'VONOY,  Vonovia SE',
    name: 'Vonovia SE',
    symbol: 'VONOY',
  },
  {
    value: 'ZLNDY',
    label: 'ZLNDY,  Zalando SE',
    name: 'Zalando SE',
    symbol: 'ZLNDY',
  },
  {
    value: 'FOJCY',
    label: 'FOJCY,  Fortum Oyj',
    name: 'Fortum Oyj',
    symbol: 'FOJCY',
  },
  {
    value: 'KKOYY',
    label: 'KKOYY,  Kesko Oyj',
    name: 'Kesko Oyj',
    symbol: 'KKOYY',
  },
  {
    value: 'KNYJY',
    label: 'KNYJY,  KONE Oyj',
    name: 'KONE Oyj',
    symbol: 'KNYJY',
  },
  {
    value: 'KNCRY',
    label: 'KNCRY,  Konecranes Plc',
    name: 'Konecranes Plc',
    symbol: 'KNCRY',
  },
  {
    value: 'OUKPY',
    label: 'OUKPY,  Metso Outotec Oyj',
    name: 'Metso Outotec Oyj',
    symbol: 'OUKPY',
  },
  {
    value: 'NTOIY',
    label: 'NTOIY,  Neste Oyj',
    name: 'Neste Oyj',
    symbol: 'NTOIY',
  },
  {
    value: 'NKRKY',
    label: 'NKRKY,  Nokian Renkaat Oyj',
    name: 'Nokian Renkaat Oyj',
    symbol: 'NKRKY',
  },
  {
    value: 'NRDBY',
    label: 'NRDBY,  Nordea Bank Abp',
    name: 'Nordea Bank Abp',
    symbol: 'NRDBY',
  },
  {
    value: 'SAXPY',
    label: 'SAXPY,  Sampo Oyj',
    name: 'Sampo Oyj',
    symbol: 'SAXPY',
  },
  {
    value: 'SEOAY',
    label: 'SEOAY,  Stora Enso Oyj',
    name: 'Stora Enso Oyj',
    symbol: 'SEOAY',
  },
  {
    value: 'WRTBY',
    label: 'WRTBY,  Wärtsilä Oyj Abp',
    name: 'Wärtsilä Oyj Abp',
    symbol: 'WRTBY',
  },
  {
    value: 'AACAY',
    label: 'AACAY,  AAC Technologies Holdings Inc.',
    name: 'AAC Technologies Holdings Inc.',
    symbol: 'AACAY',
  },
  {
    value: 'ASMVY',
    label: 'ASMVY,  ASMPT Limited',
    name: 'ASMPT Limited',
    symbol: 'ASMVY',
  },
  {
    value: 'AHCHY',
    label: 'AHCHY,  Anhui Conch Cement Company Limited',
    name: 'Anhui Conch Cement Company Limited',
    symbol: 'AHCHY',
  },
  {
    value: 'ANPDY',
    label: 'ANPDY,  ANTA Sports Products Limited',
    name: 'ANTA Sports Products Limited',
    symbol: 'ANPDY',
  },
  {
    value: 'CKHUY',
    label: 'CKHUY,  CK Hutchison Holdings Limited',
    name: 'CK Hutchison Holdings Limited',
    symbol: 'CKHUY',
  },
  {
    value: 'CRHKY',
    label: 'CRHKY,  China Resources Beer (Holdings) Company Limited',
    name: 'China Resources Beer (Holdings) Company Limited',
    symbol: 'CRHKY',
  },
  {
    value: 'CIHKY',
    label: 'CIHKY,  China Merchants Bank Co., Ltd.',
    name: 'China Merchants Bank Co., Ltd.',
    symbol: 'CIHKY',
  },
  {
    value: 'CLPHY',
    label: 'CLPHY,  CLP Holdings Limited',
    name: 'CLP Holdings Limited',
    symbol: 'CLPHY',
  },
  {
    value: 'GXYYY',
    label: 'GXYYY,  Galaxy Entertainment Group Limited',
    name: 'Galaxy Entertainment Group Limited',
    symbol: 'GXYYY',
  },
  {
    value: 'HSNGY',
    label: 'HSNGY,  Hang Seng Bank Limited',
    name: 'Hang Seng Bank Limited',
    symbol: 'HSNGY',
  },
  {
    value: 'HEGIY',
    label: 'HEGIY,  Hengan International Group Company Limited',
    name: 'Hengan International Group Company Limited',
    symbol: 'HEGIY',
  },
  {
    value: 'LNNGY',
    label: 'LNNGY,  Li Ning Company Limited',
    name: 'Li Ning Company Limited',
    symbol: 'LNNGY',
  },
  {
    value: 'JSPR',
    label: 'JSPR,  Jasper Therapeutics, Inc.',
    name: 'Jasper Therapeutics, Inc.',
    symbol: 'JSPR',
  },
  {
    value: 'GNTA',
    label: 'GNTA,  Genenta Science S.p.A.',
    name: 'Genenta Science S.p.A.',
    symbol: 'GNTA',
  },
  {
    value: 'LGFRY',
    label: 'LGFRY,  Longfor Group Holdings Limited',
    name: 'Longfor Group Holdings Limited',
    symbol: 'LGFRY',
  },
  {
    value: 'XLO',
    label: 'XLO,  Xilio Therapeutics, Inc.',
    name: 'Xilio Therapeutics, Inc.',
    symbol: 'XLO',
  },
  {
    value: 'IOBT',
    label: 'IOBT,  IO Biotech, Inc.',
    name: 'IO Biotech, Inc.',
    symbol: 'IOBT',
  },
  {
    value: 'MPNGY',
    label: 'MPNGY,  Meituan',
    name: 'Meituan',
    symbol: 'MPNGY',
  },
  {
    value: 'PNGAY',
    label: 'PNGAY,  Ping An Insurance (Group) Company of China, Ltd.',
    name: 'Ping An Insurance (Group) Company of China, Ltd.',
    symbol: 'PNGAY',
  },
  {
    value: 'HGKGY',
    label: 'HGKGY,  Power Assets Holdings Limited',
    name: 'Power Assets Holdings Limited',
    symbol: 'HGKGY',
  },
  {
    value: 'SHZHY',
    label: 'SHZHY,  Shenzhou International Group Holdings Limited',
    name: 'Shenzhou International Group Holdings Limited',
    symbol: 'SHZHY',
  },
  {
    value: 'SCBFY',
    label: 'SCBFY,  Standard Chartered PLC',
    name: 'Standard Chartered PLC',
    symbol: 'SCBFY',
  },
  {
    value: 'SUHJY',
    label: 'SUHJY,  Sun Hung Kai Properties Limited',
    name: 'Sun Hung Kai Properties Limited',
    symbol: 'SUHJY',
  },
  {
    value: 'SOTGY',
    label: 'SOTGY,  Sunny Optical Technology (Group) Company Limited',
    name: 'Sunny Optical Technology (Group) Company Limited',
    symbol: 'SOTGY',
  },
  {
    value: 'TTNDY',
    label: 'TTNDY,  Techtronic Industries Company Limited',
    name: 'Techtronic Industries Company Limited',
    symbol: 'TTNDY',
  },
  {
    value: 'VTKLY',
    label: 'VTKLY,  Vtech Holdings Limited',
    name: 'Vtech Holdings Limited',
    symbol: 'VTKLY',
  },
  {
    value: 'WXXWY',
    label: 'WXXWY,  WuXi Biologics (Cayman) Inc.',
    name: 'WuXi Biologics (Cayman) Inc.',
    symbol: 'WXXWY',
  },
  {
    value: 'YZCAY',
    label: 'YZCAY,  Yankuang Energy Group Company Limited',
    name: 'Yankuang Energy Group Company Limited',
    symbol: 'YZCAY',
  },
  {
    value: 'AONNY',
    label: 'AONNY,  Aeon Co., Ltd.',
    name: 'Aeon Co., Ltd.',
    symbol: 'AONNY',
  },
  {
    value: 'BRDCY',
    label: 'BRDCY,  Bridgestone Corporation',
    name: 'Bridgestone Corporation',
    symbol: 'BRDCY',
  },
  {
    value: 'DNZOY',
    label: 'DNZOY,  DENSO Corporation',
    name: 'DENSO Corporation',
    symbol: 'DNZOY',
  },
  {
    value: 'ITOCY',
    label: 'ITOCY,  ITOCHU Corporation',
    name: 'ITOCHU Corporation',
    symbol: 'ITOCY',
  },
  {
    value: 'JPPHY',
    label: 'JPPHY,  Japan Post Holdings Co., Ltd.',
    name: 'Japan Post Holdings Co., Ltd.',
    symbol: 'JPPHY',
  },
  {
    value: 'KAOOY',
    label: 'KAOOY,  Kao Corporation',
    name: 'Kao Corporation',
    symbol: 'KAOOY',
  },
  {
    value: 'KDDIY',
    label: 'KDDIY,  KDDI Corporation',
    name: 'KDDI Corporation',
    symbol: 'KDDIY',
  },
  {
    value: 'MITSY',
    label: 'MITSY,  Mitsui & Co., Ltd.',
    name: 'Mitsui & Co., Ltd.',
    symbol: 'MITSY',
  },
  {
    value: 'NTTYY',
    label: 'NTTYY,  Nippon Telegraph and Telephone Corporation',
    name: 'Nippon Telegraph and Telephone Corporation',
    symbol: 'NTTYY',
  },
  {
    value: 'OTSKY',
    label: 'OTSKY,  Otsuka Holdings Co., Ltd.',
    name: 'Otsuka Holdings Co., Ltd.',
    symbol: 'OTSKY',
  },
  {
    value: 'SVNDY',
    label: 'SVNDY,  Seven & i Holdings Co., Ltd.',
    name: 'Seven & i Holdings Co., Ltd.',
    symbol: 'SVNDY',
  },
  {
    value: 'SHECY',
    label: 'SHECY,  Shin-Etsu Chemical Co., Ltd.',
    name: 'Shin-Etsu Chemical Co., Ltd.',
    symbol: 'SHECY',
  },
  {
    value: 'FUJHY',
    label: 'FUJHY,  Subaru Corporation',
    name: 'Subaru Corporation',
    symbol: 'FUJHY',
  },
  {
    value: 'SZKMY',
    label: 'SZKMY,  Suzuki Motor Corporation',
    name: 'Suzuki Motor Corporation',
    symbol: 'SZKMY',
  },
  {
    value: 'EDPFY',
    label: 'EDPFY,  EDP - Energias de Portugal, S.A.',
    name: 'EDP - Energias de Portugal, S.A.',
    symbol: 'EDPFY',
  },
  {
    value: 'GLPEY',
    label: 'GLPEY,  Galp Energia, SGPS, S.A.',
    name: 'Galp Energia, SGPS, S.A.',
    symbol: 'GLPEY',
  },
  {
    value: 'JRONY',
    label: 'JRONY,  Jerónimo Martins, SGPS, S.A.',
    name: 'Jerónimo Martins, SGPS, S.A.',
    symbol: 'JRONY',
  },
  {
    value: 'ASOMY',
    label: 'ASOMY,  ASOS Plc',
    name: 'ASOS Plc',
    symbol: 'ASOMY',
  },
  {
    value: 'AMIGY',
    label: 'AMIGY,  Admiral Group plc',
    name: 'Admiral Group plc',
    symbol: 'AMIGY',
  },
  {
    value: 'ASHTY',
    label: 'ASHTY,  Ashtead Group plc',
    name: 'Ashtead Group plc',
    symbol: 'ASHTY',
  },
  {
    value: 'ASBFY',
    label: 'ASBFY,  Associated British Foods plc',
    name: 'Associated British Foods plc',
    symbol: 'ASBFY',
  },
  {
    value: 'ATDRY',
    label: 'ATDRY,  Auto Trader Group plc',
    name: 'Auto Trader Group plc',
    symbol: 'ATDRY',
  },
  {
    value: 'BMRRY',
    label: 'BMRRY,  B&M European Value Retail S.A.',
    name: 'B&M European Value Retail S.A.',
    symbol: 'BMRRY',
  },
  {
    value: 'BTDPY',
    label: 'BTDPY,  Barratt Developments plc',
    name: 'Barratt Developments plc',
    symbol: 'BTDPY',
  },
  {
    value: 'BKGFY',
    label: 'BKGFY,  The Berkeley Group Holdings plc',
    name: 'The Berkeley Group Holdings plc',
    symbol: 'BKGFY',
  },
  {
    value: 'BTVCY',
    label: 'BTVCY,  Britvic plc',
    name: 'Britvic plc',
    symbol: 'BTVCY',
  },
  {
    value: 'BZLFY',
    label: 'BZLFY,  Bunzl plc',
    name: 'Bunzl plc',
    symbol: 'BZLFY',
  },
  {
    value: 'CBGPY',
    label: 'CBGPY,  Close Brothers Group plc',
    name: 'Close Brothers Group plc',
    symbol: 'CBGPY',
  },
  {
    value: 'CCHGY',
    label: 'CCHGY,  Coca-Cola HBC AG',
    name: 'Coca-Cola HBC AG',
    symbol: 'CCHGY',
  },
  {
    value: 'CMPGY',
    label: 'CMPGY,  Compass Group PLC',
    name: 'Compass Group PLC',
    symbol: 'CMPGY',
  },
  {
    value: 'COIHY',
    label: 'COIHY,  Croda International Plc',
    name: 'Croda International Plc',
    symbol: 'COIHY',
  },
  {
    value: 'SES',
    label: 'SES,  SES AI Corporation',
    name: 'SES AI Corporation',
    symbol: 'SES',
  },
  {
    value: 'ESYJY',
    label: 'ESYJY,  easyJet plc',
    name: 'easyJet plc',
    symbol: 'ESYJY',
  },
  {
    value: 'GLAPY',
    label: 'GLAPY,  Glanbia plc',
    name: 'Glanbia plc',
    symbol: 'GLAPY',
  },
  {
    value: 'GFKSY',
    label: 'GFKSY,  Gulf Keystone Petroleum Limited',
    name: 'Gulf Keystone Petroleum Limited',
    symbol: 'GFKSY',
  },
  {
    value: 'HALMY',
    label: 'HALMY,  Halma plc',
    name: 'Halma plc',
    symbol: 'HALMY',
  },
  {
    value: 'HRGLY',
    label: 'HRGLY,  Hargreaves Lansdown plc',
    name: 'Hargreaves Lansdown plc',
    symbol: 'HRGLY',
  },
  {
    value: 'HKMPY',
    label: 'HKMPY,  Hikma Pharmaceuticals PLC',
    name: 'Hikma Pharmaceuticals PLC',
    symbol: 'HKMPY',
  },
  {
    value: 'HWDJY',
    label: 'HWDJY,  Howden Joinery Group Plc',
    name: 'Howden Joinery Group Plc',
    symbol: 'HWDJY',
  },
  {
    value: 'IGGHY',
    label: 'IGGHY,  IG Group Holdings plc',
    name: 'IG Group Holdings plc',
    symbol: 'IGGHY',
  },
  {
    value: 'IMIAY',
    label: 'IMIAY,  IMI plc',
    name: 'IMI plc',
    symbol: 'IMIAY',
  },
  {
    value: 'IFJPY',
    label: 'IFJPY,  Informa plc',
    name: 'Informa plc',
    symbol: 'IFJPY',
  },
  {
    value: 'IKTSY',
    label: 'IKTSY,  Intertek Group plc',
    name: 'Intertek Group plc',
    symbol: 'IKTSY',
  },
  {
    value: 'JSAIY',
    label: 'JSAIY,  J Sainsbury plc',
    name: 'J Sainsbury plc',
    symbol: 'JSAIY',
  },
  {
    value: 'JMPLY',
    label: 'JMPLY,  Johnson Matthey Plc',
    name: 'Johnson Matthey Plc',
    symbol: 'JMPLY',
  },
  {
    value: 'KRYAY',
    label: 'KRYAY,  Kerry Group plc',
    name: 'Kerry Group plc',
    symbol: 'KRYAY',
  },
  {
    value: 'KGFHY',
    label: 'KGFHY,  Kingfisher plc',
    name: 'Kingfisher plc',
    symbol: 'KGFHY',
  },
  {
    value: 'KGSPY',
    label: 'KGSPY,  Kingspan Group plc',
    name: 'Kingspan Group plc',
    symbol: 'KGSPY',
  },
  {
    value: 'LNSTY',
    label: 'LNSTY,  London Stock Exchange Group plc',
    name: 'London Stock Exchange Group plc',
    symbol: 'LNSTY',
  },
  {
    value: 'MAKSY',
    label: 'MAKSY,  Marks and Spencer Group plc',
    name: 'Marks and Spencer Group plc',
    symbol: 'MAKSY',
  },
  {
    value: 'MONDY',
    label: 'MONDY,  Mondi plc',
    name: 'Mondi plc',
    symbol: 'MONDY',
  },
  {
    value: 'NXGPY',
    label: 'NXGPY,  NEXT plc',
    name: 'NEXT plc',
    symbol: 'NXGPY',
  },
  {
    value: 'OCDDY',
    label: 'OCDDY,  Ocado Group plc',
    name: 'Ocado Group plc',
    symbol: 'OCDDY',
  },
  {
    value: 'PSMMY',
    label: 'PSMMY,  Persimmon Plc',
    name: 'Persimmon Plc',
    symbol: 'PSMMY',
  },
  {
    value: 'AUCOY',
    label: 'AUCOY,  Polymetal International plc',
    name: 'Polymetal International plc',
    symbol: 'AUCOY',
  },
  {
    value: 'RBGLY',
    label: 'RBGLY,  Reckitt Benckiser Group plc',
    name: 'Reckitt Benckiser Group plc',
    symbol: 'RBGLY',
  },
  {
    value: 'RTMVY',
    label: 'RTMVY,  Rightmove plc',
    name: 'Rightmove plc',
    symbol: 'RTMVY',
  },
  {
    value: 'SSEZY',
    label: 'SSEZY,  SSE plc',
    name: 'SSE plc',
    symbol: 'SSEZY',
  },
  {
    value: 'SGPYY',
    label: 'SGPYY,  The Sage Group plc',
    name: 'The Sage Group plc',
    symbol: 'SGPYY',
  },
  {
    value: 'STRNY',
    label: 'STRNY,  Severn Trent Plc',
    name: 'Severn Trent Plc',
    symbol: 'STRNY',
  },
  {
    value: 'SMGZY',
    label: 'SMGZY,  Smiths Group plc',
    name: 'Smiths Group plc',
    symbol: 'SMGZY',
  },
  {
    value: 'SMFKY',
    label: 'SMFKY,  Smurfit Kappa Group Plc',
    name: 'Smurfit Kappa Group Plc',
    symbol: 'SMFKY',
  },
  {
    value: 'TATYY',
    label: 'TATYY,  Tate & Lyle plc',
    name: 'Tate & Lyle plc',
    symbol: 'TATYY',
  },
  {
    value: 'TGOPY',
    label: 'TGOPY,  3i Group plc',
    name: '3i Group plc',
    symbol: 'TGOPY',
  },
  {
    value: 'UUGRY',
    label: 'UUGRY,  United Utilities Group PLC',
    name: 'United Utilities Group PLC',
    symbol: 'UUGRY',
  },
  {
    value: 'WEGRY',
    label: 'WEGRY,  The Weir Group PLC',
    name: 'The Weir Group PLC',
    symbol: 'WEGRY',
  },
  {
    value: 'WTBDY',
    label: 'WTBDY,  Whitbread plc',
    name: 'Whitbread plc',
    symbol: 'WTBDY',
  },
  {
    value: 'ACSAY',
    label: 'ACSAY,  ACS, Actividades de Construcción y Servicios, S.A.',
    name: 'ACS, Actividades de Construcción y Servicios, S.A.',
    symbol: 'ACSAY',
  },
  {
    value: 'ANIOY',
    label: 'ANIOY,  Acerinox, S.A.',
    name: 'Acerinox, S.A.',
    symbol: 'ANIOY',
  },
  {
    value: 'AMADY',
    label: 'AMADY,  Amadeus IT Group, S.A.',
    name: 'Amadeus IT Group, S.A.',
    symbol: 'AMADY',
  },
  {
    value: 'CLLNY',
    label: 'CLLNY,  Cellnex Telecom, S.A.',
    name: 'Cellnex Telecom, S.A.',
    symbol: 'CLLNY',
  },
  {
    value: 'EBRPY',
    label: 'EBRPY,  Ebro Foods, S.A.',
    name: 'Ebro Foods, S.A.',
    symbol: 'EBRPY',
  },
  {
    value: 'ENGGY',
    label: 'ENGGY,  Enagás, S.A.',
    name: 'Enagás, S.A.',
    symbol: 'ENGGY',
  },
  {
    value: 'ELEZY',
    label: 'ELEZY,  Endesa, S.A.',
    name: 'Endesa, S.A.',
    symbol: 'ELEZY',
  },
  {
    value: 'FRRVY',
    label: 'FRRVY,  Ferrovial, S.A.',
    name: 'Ferrovial, S.A.',
    symbol: 'FRRVY',
  },
  {
    value: 'ISMAY',
    label: 'ISMAY,  Indra Sistemas, S.A.',
    name: 'Indra Sistemas, S.A.',
    symbol: 'ISMAY',
  },
  {
    value: 'GASNY',
    label: 'GASNY,  Naturgy Energy Group, S.A.',
    name: 'Naturgy Energy Group, S.A.',
    symbol: 'GASNY',
  },
  {
    value: 'RDEIY',
    label: 'RDEIY,  Red Eléctrica Corporación, S.A.',
    name: 'Red Eléctrica Corporación, S.A.',
    symbol: 'RDEIY',
  },
  {
    value: 'REPYY',
    label: 'REPYY,  Repsol, S.A.',
    name: 'Repsol, S.A.',
    symbol: 'REPYY',
  },
  {
    value: 'GCTAY',
    label: 'GCTAY,  Siemens Gamesa Renewable Energy, S.A.',
    name: 'Siemens Gamesa Renewable Energy, S.A.',
    symbol: 'GCTAY',
  },
  {
    value: 'GBOOY',
    label: 'GBOOY,  Grupo Financiero Banorte, S.A.B. de C.V.',
    name: 'Grupo Financiero Banorte, S.A.B. de C.V.',
    symbol: 'GBOOY',
  },
  {
    value: 'PUODY',
    label:
      'PUODY,  Promotora y Operadora de Infraestructura, S. A. B. de C. V.',
    name: 'Promotora y Operadora de Infraestructura, S. A. B. de C. V.',
    symbol: 'PUODY',
  },
  {
    value: 'ARZGY',
    label: 'ARZGY,  Assicurazioni Generali S.p.A.',
    name: 'Assicurazioni Generali S.p.A.',
    symbol: 'ARZGY',
  },
  {
    value: 'ATASY',
    label: 'ATASY,  Atlantia SpA',
    name: 'Atlantia SpA',
    symbol: 'ATASY',
  },
  {
    value: 'BNCDY',
    label: 'BNCDY,  Banca Mediolanum S.p.A.',
    name: 'Banca Mediolanum S.p.A.',
    symbol: 'BNCDY',
  },
  {
    value: 'BCUCY',
    label: 'BCUCY,  Brunello Cucinelli S.p.A.',
    name: 'Brunello Cucinelli S.p.A.',
    symbol: 'BCUCY',
  },
  {
    value: 'BZZUY',
    label: 'BZZUY,  Buzzi Unicem S.p.A.',
    name: 'Buzzi Unicem S.p.A.',
    symbol: 'BZZUY',
  },
  {
    value: 'FINMY',
    label: 'FINMY,  Leonardo S.p.a.',
    name: 'Leonardo S.p.a.',
    symbol: 'FINMY',
  },
  {
    value: 'MDIBY',
    label: 'MDIBY,  Mediobanca Banca di Credito Finanziario S.p.A.',
    name: 'Mediobanca Banca di Credito Finanziario S.p.A.',
    symbol: 'MDIBY',
  },
  {
    value: 'PRYMY',
    label: 'PRYMY,  Prysmian S.p.A.',
    name: 'Prysmian S.p.A.',
    symbol: 'PRYMY',
  },
  {
    value: 'SFRGY',
    label: 'SFRGY,  Salvatore Ferragamo S.p.A.',
    name: 'Salvatore Ferragamo S.p.A.',
    symbol: 'SFRGY',
  },
  {
    value: 'TEZNY',
    label: 'TEZNY,  Terna - Rete Elettrica Nazionale Società per Azioni',
    name: 'Terna - Rete Elettrica Nazionale Società per Azioni',
    symbol: 'TEZNY',
  },
  {
    value: 'UNCRY',
    label: 'UNCRY,  UniCredit S.p.A.',
    name: 'UniCredit S.p.A.',
    symbol: 'UNCRY',
  },
  {
    value: 'GJNSY',
    label: 'GJNSY,  Gjensidige Forsikring ASA',
    name: 'Gjensidige Forsikring ASA',
    symbol: 'GJNSY',
  },
  {
    value: 'MHGVY',
    label: 'MHGVY,  Mowi ASA',
    name: 'Mowi ASA',
    symbol: 'MHGVY',
  },
  {
    value: 'ORKLY',
    label: 'ORKLY,  Orkla ASA',
    name: 'Orkla ASA',
    symbol: 'ORKLY',
  },
  {
    value: 'SALRY',
    label: 'SALRY,  SalMar ASA',
    name: 'SalMar ASA',
    symbol: 'SALRY',
  },
  {
    value: 'TGSGY',
    label: 'TGSGY,  TGS ASA',
    name: 'TGS ASA',
    symbol: 'TGSGY',
  },
  {
    value: 'TELNY',
    label: 'TELNY,  Telenor ASA',
    name: 'Telenor ASA',
    symbol: 'TELNY',
  },
  {
    value: 'TMRAY',
    label: 'TMRAY,  Tomra Systems ASA',
    name: 'Tomra Systems ASA',
    symbol: 'TMRAY',
  },
  {
    value: 'YARIY',
    label: 'YARIY,  Yara International ASA',
    name: 'Yara International ASA',
    symbol: 'YARIY',
  },
  {
    value: 'AIQUY',
    label: "AIQUY,  L'Air Liquide S.A.",
    name: "L'Air Liquide S.A.",
    symbol: 'AIQUY',
  },
  {
    value: 'ALSMY',
    label: 'ALSMY,  Alstom SA',
    name: 'Alstom SA',
    symbol: 'ALSMY',
  },
  {
    value: 'ARKAY',
    label: 'ARKAY,  Arkema S.A.',
    name: 'Arkema S.A.',
    symbol: 'ARKAY',
  },
  {
    value: 'BNPQY',
    label: 'BNPQY,  BNP Paribas SA',
    name: 'BNP Paribas SA',
    symbol: 'BNPQY',
  },
  {
    value: 'BICEY',
    label: 'BICEY,  Société BIC SA',
    name: 'Société BIC SA',
    symbol: 'BICEY',
  },
  {
    value: 'CGEMY',
    label: 'CGEMY,  Capgemini SE',
    name: 'Capgemini SE',
    symbol: 'CGEMY',
  },
  {
    value: 'CRRFY',
    label: 'CRRFY,  Carrefour SA',
    name: 'Carrefour SA',
    symbol: 'CRRFY',
  },
  {
    value: 'CGUSY',
    label: 'CGUSY,  Casino, Guichard-Perrachon S.A.',
    name: 'Casino, Guichard-Perrachon S.A.',
    symbol: 'CGUSY',
  },
  {
    value: 'CRARY',
    label: 'CRARY,  Crédit Agricole S.A.',
    name: 'Crédit Agricole S.A.',
    symbol: 'CRARY',
  },
  {
    value: 'DANOY',
    label: 'DANOY,  Danone S.A.',
    name: 'Danone S.A.',
    symbol: 'DANOY',
  },
  {
    value: 'EDNMY',
    label: 'EDNMY,  Edenred SA',
    name: 'Edenred SA',
    symbol: 'EDNMY',
  },
  {
    value: 'EFGSY',
    label: 'EFGSY,  Eiffage SA',
    name: 'Eiffage SA',
    symbol: 'EFGSY',
  },
  {
    value: 'ECIFY',
    label: 'ECIFY,  Electricité de France S.A.',
    name: 'Electricité de France S.A.',
    symbol: 'ECIFY',
  },
  {
    value: 'ENGIY',
    label: 'ENGIY,  Engie SA',
    name: 'Engie SA',
    symbol: 'ENGIY',
  },
  {
    value: 'ERMAY',
    label: 'ERMAY,  ERAMET S.A.',
    name: 'ERAMET S.A.',
    symbol: 'ERMAY',
  },
  {
    value: 'ETCMY',
    label: 'ETCMY,  Eutelsat Communications S.A.',
    name: 'Eutelsat Communications S.A.',
    symbol: 'ETCMY',
  },
  {
    value: 'HESAY',
    label: 'HESAY,  Hermès International Société en commandite par actions',
    name: 'Hermès International Société en commandite par actions',
    symbol: 'HESAY',
  },
  {
    value: 'IPSEY',
    label: 'IPSEY,  Ipsen S.A.',
    name: 'Ipsen S.A.',
    symbol: 'IPSEY',
  },
  {
    value: 'LGRDY',
    label: 'LGRDY,  Legrand SA',
    name: 'Legrand SA',
    symbol: 'LGRDY',
  },
  {
    value: 'PUBGY',
    label: 'PUBGY,  Publicis Groupe S.A.',
    name: 'Publicis Groupe S.A.',
    symbol: 'PUBGY',
  },
  {
    value: 'NPACY',
    label: 'NPACY,  Quadient S.A.',
    name: 'Quadient S.A.',
    symbol: 'NPACY',
  },
  {
    value: 'REMYY',
    label: 'REMYY,  Rémy Cointreau SA',
    name: 'Rémy Cointreau SA',
    symbol: 'REMYY',
  },
  {
    value: 'RNLSY',
    label: 'RNLSY,  Renault SA',
    name: 'Renault SA',
    symbol: 'RNLSY',
  },
  {
    value: 'RXEEY',
    label: 'RXEEY,  Rexel S.A.',
    name: 'Rexel S.A.',
    symbol: 'RXEEY',
  },
  {
    value: 'SAFRY',
    label: 'SAFRY,  Safran SA',
    name: 'Safran SA',
    symbol: 'SAFRY',
  },
  {
    value: 'SBGSY',
    label: 'SBGSY,  Schneider Electric S.E.',
    name: 'Schneider Electric S.E.',
    symbol: 'SBGSY',
  },
  {
    value: 'SCRYY',
    label: 'SCRYY,  SCOR SE',
    name: 'SCOR SE',
    symbol: 'SCRYY',
  },
  {
    value: 'SCGLY',
    label: 'SCGLY,  Société Générale Société anonyme',
    name: 'Société Générale Société anonyme',
    symbol: 'SCGLY',
  },
  {
    value: 'SDXAY',
    label: 'SDXAY,  Sodexo S.A.',
    name: 'Sodexo S.A.',
    symbol: 'SDXAY',
  },
  {
    value: 'SLOIY',
    label: 'SLOIY,  Soitec S.A.',
    name: 'Soitec S.A.',
    symbol: 'SLOIY',
  },
  {
    value: 'TLPFY',
    label: 'TLPFY,  Teleperformance SE',
    name: 'Teleperformance SE',
    symbol: 'TLPFY',
  },
  {
    value: 'THLLY',
    label: 'THLLY,  Thales S.A.',
    name: 'Thales S.A.',
    symbol: 'THLLY',
  },
  {
    value: 'VLEEY',
    label: 'VLEEY,  Valeo SE',
    name: 'Valeo SE',
    symbol: 'VLEEY',
  },
  {
    value: 'VCISY',
    label: 'VCISY,  Vinci SA',
    name: 'Vinci SA',
    symbol: 'VCISY',
  },
  {
    value: 'ELUXY',
    label: 'ELUXY,  AB Electrolux (publ)',
    name: 'AB Electrolux (publ)',
    symbol: 'ELUXY',
  },
  {
    value: 'ALFVY',
    label: 'ALFVY,  Alfa Laval Corporate AB',
    name: 'Alfa Laval Corporate AB',
    symbol: 'ALFVY',
  },
  {
    value: 'ASAZY',
    label: 'ASAZY,  ASSA ABLOY AB (publ)',
    name: 'ASSA ABLOY AB (publ)',
    symbol: 'ASAZY',
  },
  {
    value: 'EKTAY',
    label: 'EKTAY,  Elekta AB (publ)',
    name: 'Elekta AB (publ)',
    symbol: 'EKTAY',
  },
  {
    value: 'EPOKY',
    label: 'EPOKY,  Epiroc AB (publ)',
    name: 'Epiroc AB (publ)',
    symbol: 'EPOKY',
  },
  {
    value: 'GNGBY',
    label: 'GNGBY,  Getinge AB (publ)',
    name: 'Getinge AB (publ)',
    symbol: 'GNGBY',
  },
  {
    value: 'HNNMY',
    label: 'HNNMY,  H & M Hennes & Mauritz AB (publ)',
    name: 'H & M Hennes & Mauritz AB (publ)',
    symbol: 'HNNMY',
  },
  {
    value: 'HXGBY',
    label: 'HXGBY,  Hexagon AB (publ)',
    name: 'Hexagon AB (publ)',
    symbol: 'HXGBY',
  },
  {
    value: 'HSQVY',
    label: 'HSQVY,  Husqvarna AB (publ)',
    name: 'Husqvarna AB (publ)',
    symbol: 'HSQVY',
  },
  {
    value: 'ITJTY',
    label: 'ITJTY,  Intrum AB (publ)',
    name: 'Intrum AB (publ)',
    symbol: 'ITJTY',
  },
  {
    value: 'ORXOY',
    label: 'ORXOY,  Orexo AB (publ)',
    name: 'Orexo AB (publ)',
    symbol: 'ORXOY',
  },
  {
    value: 'SKFRY',
    label: 'SKFRY,  AB SKF (publ)',
    name: 'AB SKF (publ)',
    symbol: 'SKFRY',
  },
  {
    value: 'SDVKY',
    label: 'SDVKY,  Sandvik AB (publ)',
    name: 'Sandvik AB (publ)',
    symbol: 'SDVKY',
  },
  {
    value: 'SKBSY',
    label: 'SKBSY,  Skanska AB (publ)',
    name: 'Skanska AB (publ)',
    symbol: 'SKBSY',
  },
  {
    value: 'SVNLY',
    label: 'SVNLY,  Svenska Handelsbanken AB (publ)',
    name: 'Svenska Handelsbanken AB (publ)',
    symbol: 'SVNLY',
  },
  {
    value: 'SWDBY',
    label: 'SWDBY,  Swedbank AB (publ)',
    name: 'Swedbank AB (publ)',
    symbol: 'SWDBY',
  },
  {
    value: 'TLTZY',
    label: 'TLTZY,  Tele2 AB (publ)',
    name: 'Tele2 AB (publ)',
    symbol: 'TLTZY',
  },
  {
    value: 'VLVLY',
    label: 'VLVLY,  AB Volvo (publ)',
    name: 'AB Volvo (publ)',
    symbol: 'VLVLY',
  },
  {
    value: 'AHEXY',
    label: 'AHEXY,  Adecco Group AG',
    name: 'Adecco Group AG',
    symbol: 'AHEXY',
  },
  {
    value: 'AMSSY',
    label: 'AMSSY,  ams-OSRAM AG',
    name: 'ams-OSRAM AG',
    symbol: 'AMSSY',
  },
  {
    value: 'BLHEY',
    label: 'BLHEY,  Bâloise Holding AG',
    name: 'Bâloise Holding AG',
    symbol: 'BLHEY',
  },
  {
    value: 'CLZNY',
    label: 'CLZNY,  Clariant AG',
    name: 'Clariant AG',
    symbol: 'CLZNY',
  },
  {
    value: 'CFRUY',
    label: 'CFRUY,  Compagnie Financière Richemont SA',
    name: 'Compagnie Financière Richemont SA',
    symbol: 'CFRUY',
  },
  {
    value: 'DUFRY',
    label: 'DUFRY,  Dufry AG',
    name: 'Dufry AG',
    symbol: 'DUFRY',
  },
  {
    value: 'FLGZY',
    label: 'FLGZY,  Flughafen Zürich AG',
    name: 'Flughafen Zürich AG',
    symbol: 'FLGZY',
  },
  {
    value: 'GBERY',
    label: 'GBERY,  Geberit AG',
    name: 'Geberit AG',
    symbol: 'GBERY',
  },
  {
    value: 'GVDNY',
    label: 'GVDNY,  Givaudan SA',
    name: 'Givaudan SA',
    symbol: 'GVDNY',
  },
  {
    value: 'JBAXY',
    label: 'JBAXY,  Julius Bär Gruppe AG',
    name: 'Julius Bär Gruppe AG',
    symbol: 'JBAXY',
  },
  {
    value: 'KHNGY',
    label: 'KHNGY,  Kuehne + Nagel International AG',
    name: 'Kuehne + Nagel International AG',
    symbol: 'KHNGY',
  },
  {
    value: 'SGSOY',
    label: 'SGSOY,  SGS SA',
    name: 'SGS SA',
    symbol: 'SGSOY',
  },
  {
    value: 'SXYAY',
    label: 'SXYAY,  Sika AG',
    name: 'Sika AG',
    symbol: 'SXYAY',
  },
  {
    value: 'SONVY',
    label: 'SONVY,  Sonova Holding AG',
    name: 'Sonova Holding AG',
    symbol: 'SONVY',
  },
  {
    value: 'SAUHY',
    label: 'SAUHY,  Straumann Holding AG',
    name: 'Straumann Holding AG',
    symbol: 'SAUHY',
  },
  {
    value: 'SWGAY',
    label: 'SWGAY,  The Swatch Group AG',
    name: 'The Swatch Group AG',
    symbol: 'SWGAY',
  },
  {
    value: 'SSREY',
    label: 'SSREY,  Swiss Re AG',
    name: 'Swiss Re AG',
    symbol: 'SSREY',
  },
  {
    value: 'SZLMY',
    label: 'SZLMY,  Swiss Life Holding AG',
    name: 'Swiss Life Holding AG',
    symbol: 'SZLMY',
  },
  {
    value: 'SCMWY',
    label: 'SCMWY,  Swisscom AG',
    name: 'Swisscom AG',
    symbol: 'SCMWY',
  },
  {
    value: 'TMSNY',
    label: 'TMSNY,  Temenos AG',
    name: 'Temenos AG',
    symbol: 'TMSNY',
  },
  {
    value: 'ZURVY',
    label: 'ZURVY,  Zurich Insurance Group AG',
    name: 'Zurich Insurance Group AG',
    symbol: 'ZURVY',
  },
  {
    value: 'ASGLY',
    label: 'ASGLY,  AGC Inc.',
    name: 'AGC Inc.',
    symbol: 'ASGLY',
  },
  {
    value: 'ASEKY',
    label: 'ASEKY,  Aisin Corporation',
    name: 'Aisin Corporation',
    symbol: 'ASEKY',
  },
  {
    value: 'AJINY',
    label: 'AJINY,  Ajinomoto Co., Inc.',
    name: 'Ajinomoto Co., Inc.',
    symbol: 'AJINY',
  },
  {
    value: 'APELY',
    label: 'APELY,  Alps Alpine Co., Ltd.',
    name: 'Alps Alpine Co., Ltd.',
    symbol: 'APELY',
  },
  {
    value: 'ALNPY',
    label: 'ALNPY,  ANA Holdings Inc.',
    name: 'ANA Holdings Inc.',
    symbol: 'ALNPY',
  },
  {
    value: 'CJPRY',
    label: 'CJPRY,  Central Japan Railway Company',
    name: 'Central Japan Railway Company',
    symbol: 'CJPRY',
  },
  {
    value: 'CHGCY',
    label: 'CHGCY,  Chugai Pharmaceutical Co., Ltd.',
    name: 'Chugai Pharmaceutical Co., Ltd.',
    symbol: 'CHGCY',
  },
  {
    value: 'DNTUY',
    label: 'DNTUY,  Dentsu Group Inc.',
    name: 'Dentsu Group Inc.',
    symbol: 'DNTUY',
  },
  {
    value: 'DSCSY',
    label: 'DSCSY,  Disco Corporation',
    name: 'Disco Corporation',
    symbol: 'DSCSY',
  },
  {
    value: 'FELTY',
    label: 'FELTY,  Fuji Electric Co., Ltd.',
    name: 'Fuji Electric Co., Ltd.',
    symbol: 'FELTY',
  },
  {
    value: 'HINOY',
    label: 'HINOY,  Hino Motors, Ltd.',
    name: 'Hino Motors, Ltd.',
    symbol: 'HINOY',
  },
  {
    value: 'HTCMY',
    label: 'HTCMY,  Hitachi Construction Machinery Co., Ltd.',
    name: 'Hitachi Construction Machinery Co., Ltd.',
    symbol: 'HTCMY',
  },
  {
    value: 'ISUZY',
    label: 'ISUZY,  Isuzu Motors Limited',
    name: 'Isuzu Motors Limited',
    symbol: 'ISUZY',
  },
  {
    value: 'ITTOY',
    label: 'ITTOY,  ITOCHU Techno-Solutions Corporation',
    name: 'ITOCHU Techno-Solutions Corporation',
    symbol: 'ITTOY',
  },
  {
    value: 'KIKOY',
    label: 'KIKOY,  Kikkoman Corporation',
    name: 'Kikkoman Corporation',
    symbol: 'KIKOY',
  },
  {
    value: 'KNBWY',
    label: 'KNBWY,  Kirin Holdings Company, Limited',
    name: 'Kirin Holdings Company, Limited',
    symbol: 'KNBWY',
  },
  {
    value: 'LSRCY',
    label: 'LSRCY,  Lasertec Corporation',
    name: 'Lasertec Corporation',
    symbol: 'LSRCY',
  },
  {
    value: 'MAURY',
    label: 'MAURY,  Marui Group Co., Ltd.',
    name: 'Marui Group Co., Ltd.',
    symbol: 'MAURY',
  },
  {
    value: 'MAHLY',
    label: 'MAHLY,  MediPal Holdings Corporation',
    name: 'MediPal Holdings Corporation',
    symbol: 'MAHLY',
  },
  {
    value: 'MNBEY',
    label: 'MNBEY,  MINEBEA MITSUMI Inc.',
    name: 'MINEBEA MITSUMI Inc.',
    symbol: 'MNBEY',
  },
  {
    value: 'NGKSY',
    label: 'NGKSY,  NGK Spark Plug Co., Ltd.',
    name: 'NGK Spark Plug Co., Ltd.',
    symbol: 'NGKSY',
  },
  {
    value: 'NIPMY',
    label: 'NIPMY,  NH Foods Ltd.',
    name: 'NH Foods Ltd.',
    symbol: 'NIPMY',
  },
  {
    value: 'NEXOY',
    label: 'NEXOY,  NEXON Co., Ltd.',
    name: 'NEXON Co., Ltd.',
    symbol: 'NEXOY',
  },
  {
    value: 'NJDCY',
    label: 'NJDCY,  Nidec Corporation',
    name: 'Nidec Corporation',
    symbol: 'NJDCY',
  },
  {
    value: 'NIFCY',
    label: 'NIFCY,  Nifco Inc.',
    name: 'Nifco Inc.',
    symbol: 'NIFCY',
  },
  {
    value: 'NINOY',
    label: 'NINOY,  Nikon Corporation',
    name: 'Nikon Corporation',
    symbol: 'NINOY',
  },
  {
    value: 'NHNKY',
    label: 'NHNKY,  Nihon Kohden Corporation',
    name: 'Nihon Kohden Corporation',
    symbol: 'NHNKY',
  },
  {
    value: 'PGENY',
    label: 'PGENY,  Pigeon Corporation',
    name: 'Pigeon Corporation',
    symbol: 'PGENY',
  },
  {
    value: 'RCRUY',
    label: 'RCRUY,  Recruit Holdings Co., Ltd.',
    name: 'Recruit Holdings Co., Ltd.',
    symbol: 'RCRUY',
  },
  {
    value: 'RYKKY',
    label: 'RYKKY,  Ryohin Keikaku Co., Ltd.',
    name: 'Ryohin Keikaku Co., Ltd.',
    symbol: 'RYKKY',
  },
  {
    value: 'SOMLY',
    label: 'SOMLY,  SECOM CO., LTD.',
    name: 'SECOM CO., LTD.',
    symbol: 'SOMLY',
  },
  {
    value: 'SEKEY',
    label: 'SEKEY,  Seiko Epson Corporation',
    name: 'Seiko Epson Corporation',
    symbol: 'SEKEY',
  },
  {
    value: 'THKLY',
    label: 'THKLY,  THK Co., Ltd.',
    name: 'THK Co., Ltd.',
    symbol: 'THKLY',
  },
  {
    value: 'TRUMY',
    label: 'TRUMY,  Terumo Corporation',
    name: 'Terumo Corporation',
    symbol: 'TRUMY',
  },
  {
    value: 'YAMCY',
    label: 'YAMCY,  Yamaha Corporation',
    name: 'Yamaha Corporation',
    symbol: 'YAMCY',
  },
  {
    value: 'YASKY',
    label: 'YASKY,  YASKAWA Electric Corporation',
    name: 'YASKAWA Electric Corporation',
    symbol: 'YASKY',
  },
  {
    value: 'ZNKKY',
    label: 'ZNKKY,  ZENKOKU HOSHO Co.,Ltd.',
    name: 'ZENKOKU HOSHO Co.,Ltd.',
    symbol: 'ZNKKY',
  },
  {
    value: 'OTGLY',
    label: 'OTGLY,  CD Projekt S.A.',
    name: 'CD Projekt S.A.',
    symbol: 'OTGLY',
  },
  {
    value: 'ADRZY',
    label: 'ADRZY,  Andritz AG',
    name: 'Andritz AG',
    symbol: 'ADRZY',
  },
  {
    value: 'EVNVY',
    label: 'EVNVY,  EVN AG',
    name: 'EVN AG',
    symbol: 'EVNVY',
  },
  {
    value: 'EBKDY',
    label: 'EBKDY,  Erste Group Bank AG',
    name: 'Erste Group Bank AG',
    symbol: 'EBKDY',
  },
  {
    value: 'OMVKY',
    label: 'OMVKY,  OMV Aktiengesellschaft',
    name: 'OMV Aktiengesellschaft',
    symbol: 'OMVKY',
  },
  {
    value: 'PLFRY',
    label: 'PLFRY,  Palfinger AG',
    name: 'Palfinger AG',
    symbol: 'PLFRY',
  },
  {
    value: 'RAIFY',
    label: 'RAIFY,  Raiffeisen Bank International AG',
    name: 'Raiffeisen Bank International AG',
    symbol: 'RAIFY',
  },
  {
    value: 'SBOEY',
    label: 'SBOEY,  Schoeller-Bleckmann Oilfield Equipment Aktiengesellschaft',
    name: 'Schoeller-Bleckmann Oilfield Equipment Aktiengesellschaft',
    symbol: 'SBOEY',
  },
  {
    value: 'TKAGY',
    label: 'TKAGY,  Telekom Austria AG',
    name: 'Telekom Austria AG',
    symbol: 'TKAGY',
  },
  {
    value: 'OEZVY',
    label: 'OEZVY,  VERBUND AG',
    name: 'VERBUND AG',
    symbol: 'OEZVY',
  },
  {
    value: 'VNRFY',
    label: 'VNRFY,  Vienna Insurance Group AG',
    name: 'Vienna Insurance Group AG',
    symbol: 'VNRFY',
  },
  {
    value: 'VIAAY',
    label: 'VIAAY,  Flughafen Wien Aktiengesellschaft',
    name: 'Flughafen Wien Aktiengesellschaft',
    symbol: 'VIAAY',
  },
  {
    value: 'VLPNY',
    label: 'VLPNY,  Voestalpine AG',
    name: 'Voestalpine AG',
    symbol: 'VLPNY',
  },
  {
    value: 'NYKAA.NS',
    label: 'NYKAA.NS,  FSN E-Commerce Ventures Limited',
    name: 'FSN E-Commerce Ventures Limited',
    symbol: 'NYKAA.NS',
  },
  {
    value: 'WBRBY',
    label: 'WBRBY,  Wienerberger AG',
    name: 'Wienerberger AG',
    symbol: 'WBRBY',
  },
  {
    value: 'WLFDY',
    label: 'WLFDY,  Wolford Aktiengesellschaft',
    name: 'Wolford Aktiengesellschaft',
    symbol: 'WLFDY',
  },
  {
    value: 'CIBEY',
    label: 'CIBEY,  Commercial International Bank (Egypt) S.A.E',
    name: 'Commercial International Bank (Egypt) S.A.E',
    symbol: 'CIBEY',
  },
  {
    value: 'TCLRY',
    label: 'TCLRY,  Vantiva S.A.',
    name: 'Vantiva S.A.',
    symbol: 'TCLRY',
  },
  {
    value: 'ATLKY',
    label: 'ATLKY,  Atlas Copco AB',
    name: 'Atlas Copco AB',
    symbol: 'ATLKY',
  },
  {
    value: 'WMMVY',
    label: 'WMMVY,  Wal-Mart de México, S.A.B. de C.V.',
    name: 'Wal-Mart de México, S.A.B. de C.V.',
    symbol: 'WMMVY',
  },
  {
    value: 'BOREF',
    label: 'BOREF,  Borealis Exploration Limited',
    name: 'Borealis Exploration Limited',
    symbol: 'BOREF',
  },
  {
    value: 'BRAXF',
    label: 'BRAXF,  Braxia Scientific Corp.',
    name: 'Braxia Scientific Corp.',
    symbol: 'BRAXF',
  },
  {
    value: 'CHALF',
    label: 'CHALF,  Chalice Brands Ltd.',
    name: 'Chalice Brands Ltd.',
    symbol: 'CHALF',
  },
  {
    value: 'FLFG',
    label: 'FLFG,  Federal Life Group, Inc.',
    name: 'Federal Life Group, Inc.',
    symbol: 'FLFG',
  },
  {
    value: 'NACB',
    label: 'NACB,  National Capital Bancorp, Inc.',
    name: 'National Capital Bancorp, Inc.',
    symbol: 'NACB',
  },
  {
    value: 'RHDGF',
    label: 'RHDGF,  Retail Holdings N.V.',
    name: 'Retail Holdings N.V.',
    symbol: 'RHDGF',
  },
  {
    value: 'SIETY',
    label: "SIETY,  D'Ieteren Group SA",
    name: "D'Ieteren Group SA",
    symbol: 'SIETY',
  },
  {
    value: 'SYCRF',
    label: 'SYCRF,  SHL Holdings Ltd.',
    name: 'SHL Holdings Ltd.',
    symbol: 'SYCRF',
  },
  {
    value: 'TOBAF',
    label: 'TOBAF,  TAAT Global Alternatives Inc.',
    name: 'TAAT Global Alternatives Inc.',
    symbol: 'TOBAF',
  },
  {
    value: 'TPRKY',
    label: 'TPRKY,  Travis Perkins plc',
    name: 'Travis Perkins plc',
    symbol: 'TPRKY',
  },
  {
    value: 'FISB',
    label: 'FISB,  1st Capital Bancorp',
    name: '1st Capital Bancorp',
    symbol: 'FISB',
  },
  {
    value: 'TRBK',
    label: 'TRBK,  Traditions Bancorp, Inc.',
    name: 'Traditions Bancorp, Inc.',
    symbol: 'TRBK',
  },
  {
    value: 'ADTTF',
    label: 'ADTTF,  Advantest Corporation',
    name: 'Advantest Corporation',
    symbol: 'ADTTF',
  },
  {
    value: 'CCOEF',
    label: 'CCOEF,  Capcom Co., Ltd.',
    name: 'Capcom Co., Ltd.',
    symbol: 'CCOEF',
  },
  {
    value: 'DAIUF',
    label: 'DAIUF,  Daifuku Co., Ltd.',
    name: 'Daifuku Co., Ltd.',
    symbol: 'DAIUF',
  },
  {
    value: 'DITTF',
    label: 'DITTF,  Daito Trust Construction Co.,Ltd.',
    name: 'Daito Trust Construction Co.,Ltd.',
    symbol: 'DITTF',
  },
  {
    value: 'DKILF',
    label: 'DKILF,  Daikin Industries,Ltd.',
    name: 'Daikin Industries,Ltd.',
    symbol: 'DKILF',
  },
  {
    value: 'DSKYF',
    label: 'DSKYF,  Daiichi Sankyo Company, Limited',
    name: 'Daiichi Sankyo Company, Limited',
    symbol: 'DSKYF',
  },
  {
    value: 'DWAHF',
    label: 'DWAHF,  Daiwa House Industry Co., Ltd.',
    name: 'Daiwa House Industry Co., Ltd.',
    symbol: 'DWAHF',
  },
  {
    value: 'EJPRF',
    label: 'EJPRF,  East Japan Railway Company',
    name: 'East Japan Railway Company',
    symbol: 'EJPRF',
  },
  {
    value: 'ESALF',
    label: 'ESALF,  Eisai Co., Ltd.',
    name: 'Eisai Co., Ltd.',
    symbol: 'ESALF',
  },
  {
    value: 'IDKOF',
    label: 'IDKOF,  Idemitsu Kosan Co.,Ltd.',
    name: 'Idemitsu Kosan Co.,Ltd.',
    symbol: 'IDKOF',
  },
  {
    value: 'JAIRF',
    label: 'JAIRF,  Japan Airport Terminal Co., Ltd.',
    name: 'Japan Airport Terminal Co., Ltd.',
    symbol: 'JAIRF',
  },
  {
    value: 'JTEKF',
    label: 'JTEKF,  JTEKT Corporation',
    name: 'JTEKT Corporation',
    symbol: 'JTEKF',
  },
  {
    value: 'KAEPF',
    label: 'KAEPF,  The Kansai Electric Power Company, Incorporated',
    name: 'The Kansai Electric Power Company, Incorporated',
    symbol: 'KAEPF',
  },
  {
    value: 'KAJMF',
    label: 'KAJMF,  Kajima Corporation',
    name: 'Kajima Corporation',
    symbol: 'KAJMF',
  },
  {
    value: 'KWHIF',
    label: 'KWHIF,  Kawasaki Heavy Industries, Ltd.',
    name: 'Kawasaki Heavy Industries, Ltd.',
    symbol: 'KWHIF',
  },
  {
    value: 'MTHRF',
    label: 'MTHRF,  M3, Inc.',
    name: 'M3, Inc.',
    symbol: 'MTHRF',
  },
  {
    value: 'NCBDF',
    label: 'NCBDF,  BANDAI NAMCO Holdings Inc.',
    name: 'BANDAI NAMCO Holdings Inc.',
    symbol: 'NCBDF',
  },
  {
    value: 'OCPNF',
    label: 'OCPNF,  Olympus Corporation',
    name: 'Olympus Corporation',
    symbol: 'OCPNF',
  },
  {
    value: 'OLCLF',
    label: 'OLCLF,  Oriental Land Co., Ltd.',
    name: 'Oriental Land Co., Ltd.',
    symbol: 'OLCLF',
  },
  {
    value: 'OMRNF',
    label: 'OMRNF,  OMRON Corporation',
    name: 'OMRON Corporation',
    symbol: 'OMRNF',
  },
  {
    value: 'OPHLF',
    label: 'OPHLF,  Ono Pharmaceutical Co., Ltd.',
    name: 'Ono Pharmaceutical Co., Ltd.',
    symbol: 'OPHLF',
  },
  {
    value: 'OSCUF',
    label: 'OSCUF,  Japan Exchange Group, Inc.',
    name: 'Japan Exchange Group, Inc.',
    symbol: 'OSCUF',
  },
  {
    value: 'RNECF',
    label: 'RNECF,  Renesas Electronics Corporation',
    name: 'Renesas Electronics Corporation',
    symbol: 'RNECF',
  },
  {
    value: 'SATLF',
    label: 'SATLF,  ZOZO, Inc.',
    name: 'ZOZO, Inc.',
    symbol: 'SATLF',
  },
  {
    value: 'SNPHF',
    label: 'SNPHF,  Santen Pharmaceutical Co., Ltd.',
    name: 'Santen Pharmaceutical Co., Ltd.',
    symbol: 'SNPHF',
  },
  {
    value: 'TTDKF',
    label: 'TTDKF,  TDK Corporation',
    name: 'TDK Corporation',
    symbol: 'TTDKF',
  },
  {
    value: 'TTNDF',
    label: 'TTNDF,  Techtronic Industries Company Limited',
    name: 'Techtronic Industries Company Limited',
    symbol: 'TTNDF',
  },
  {
    value: 'TXHPF',
    label: 'TXHPF,  TechnoPro Holdings, Inc.',
    name: 'TechnoPro Holdings, Inc.',
    symbol: 'TXHPF',
  },
  {
    value: 'UBEOF',
    label: 'UBEOF,  UBE Corporation',
    name: 'UBE Corporation',
    symbol: 'UBEOF',
  },
  {
    value: 'UNCHF',
    label: 'UNCHF,  Unicharm Corporation',
    name: 'Unicharm Corporation',
    symbol: 'UNCHF',
  },
  {
    value: 'WJRYF',
    label: 'WJRYF,  West Japan Railway Company',
    name: 'West Japan Railway Company',
    symbol: 'WJRYF',
  },
  {
    value: 'YAHOF',
    label: 'YAHOF,  Z Holdings Corporation',
    name: 'Z Holdings Corporation',
    symbol: 'YAHOF',
  },
  {
    value: 'YAMHF',
    label: 'YAMHF,  Yamaha Motor Co., Ltd.',
    name: 'Yamaha Motor Co., Ltd.',
    symbol: 'YAMHF',
  },
  {
    value: 'YOKEF',
    label: 'YOKEF,  Yokogawa Electric Corporation',
    name: 'Yokogawa Electric Corporation',
    symbol: 'YOKEF',
  },
  {
    value: 'YZCHF',
    label: 'YZCHF,  Yankuang Energy Group Company Limited',
    name: 'Yankuang Energy Group Company Limited',
    symbol: 'YZCHF',
  },
  {
    value: 'PKIUF',
    label: 'PKIUF,  Parkland Corporation',
    name: 'Parkland Corporation',
    symbol: 'PKIUF',
  },
  {
    value: 'QTRHF',
    label: 'QTRHF,  Quarterhill Inc.',
    name: 'Quarterhill Inc.',
    symbol: 'QTRHF',
  },
  {
    value: 'AVTBF',
    label: 'AVTBF,  Avant Brands Inc.',
    name: 'Avant Brands Inc.',
    symbol: 'AVTBF',
  },
  {
    value: 'DMEHF',
    label: 'DMEHF,  Desert Mountain Energy Corp.',
    name: 'Desert Mountain Energy Corp.',
    symbol: 'DMEHF',
  },
  {
    value: 'RCKTF',
    label: 'RCKTF,  Rock Tech Lithium Inc.',
    name: 'Rock Tech Lithium Inc.',
    symbol: 'RCKTF',
  },
  {
    value: 'SSFT',
    label: 'SSFT,  Sonasoft Corporation',
    name: 'Sonasoft Corporation',
    symbol: 'SSFT',
  },
  {
    value: 'PRNDY',
    label: 'PRNDY,  Pernod Ricard SA',
    name: 'Pernod Ricard SA',
    symbol: 'PRNDY',
  },
  {
    value: 'VTSCY',
    label: 'VTSCY,  Vitesco Technologies Group Aktiengesellschaft',
    name: 'Vitesco Technologies Group Aktiengesellschaft',
    symbol: 'VTSCY',
  },
  {
    value: 'OFSTF',
    label: 'OFSTF,  Carbon Streaming Corporation',
    name: 'Carbon Streaming Corporation',
    symbol: 'OFSTF',
  },
  {
    value: 'WTW',
    label: 'WTW,  Willis Towers Watson Public Limited Company',
    name: 'Willis Towers Watson Public Limited Company',
    symbol: 'WTW',
  },
  {
    value: 'OZ',
    label: 'OZ,  Belpointe PREP, LLC',
    name: 'Belpointe PREP, LLC',
    symbol: 'OZ',
  },
  {
    value: 'HHS',
    label: 'HHS,  Harte Hanks, Inc.',
    name: 'Harte Hanks, Inc.',
    symbol: 'HHS',
  },
  {
    value: 'MARUY',
    label: 'MARUY,  Marubeni Corporation',
    name: 'Marubeni Corporation',
    symbol: 'MARUY',
  },
  {
    value: 'CWBHF',
    label: "CWBHF,  Charlotte's Web Holdings, Inc.",
    name: "Charlotte's Web Holdings, Inc.",
    symbol: 'CWBHF',
  },
  {
    value: 'ATVK',
    label: 'ATVK,  Ameritek Ventures, Inc.',
    name: 'Ameritek Ventures, Inc.',
    symbol: 'ATVK',
  },
  {
    value: 'ARTNB',
    label: 'ARTNB,  Artesian Resources Corporation',
    name: 'Artesian Resources Corporation',
    symbol: 'ARTNB',
  },
  {
    value: 'BVFL',
    label: 'BVFL,  BV Financial, Inc.',
    name: 'BV Financial, Inc.',
    symbol: 'BVFL',
  },
  {
    value: 'CTAM',
    label: 'CTAM,  A.M. Castle & Co.',
    name: 'A.M. Castle & Co.',
    symbol: 'CTAM',
  },
  {
    value: 'CBKCQ',
    label: 'CBKCQ,  Christopher & Banks Corporation',
    name: 'Christopher & Banks Corporation',
    symbol: 'CBKCQ',
  },
  {
    value: 'CMGR',
    label: 'CMGR,  Clubhouse Media Group, Inc.',
    name: 'Clubhouse Media Group, Inc.',
    symbol: 'CMGR',
  },
  {
    value: 'DWOG',
    label: 'DWOG,  Deep Well Oil & Gas, Inc.',
    name: 'Deep Well Oil & Gas, Inc.',
    symbol: 'DWOG',
  },
  {
    value: 'FSRL',
    label: 'FSRL,  First Reliance Bancshares, Inc.',
    name: 'First Reliance Bancshares, Inc.',
    symbol: 'FSRL',
  },
  {
    value: 'GRRB',
    label: 'GRRB,  GrandSouth Bancorporation',
    name: 'GrandSouth Bancorporation',
    symbol: 'GRRB',
  },
  {
    value: 'KWBT',
    label: 'KWBT,  Kiwa Bio-Tech Products Group Corporation',
    name: 'Kiwa Bio-Tech Products Group Corporation',
    symbol: 'KWBT',
  },
  {
    value: 'LBRDB',
    label: 'LBRDB,  Liberty Broadband Corporation',
    name: 'Liberty Broadband Corporation',
    symbol: 'LBRDB',
  },
  {
    value: 'LGIQ',
    label: 'LGIQ,  Logiq, Inc.',
    name: 'Logiq, Inc.',
    symbol: 'LGIQ',
  },
  {
    value: 'LYBC',
    label: 'LYBC,  Lyons Bancorp Inc.',
    name: 'Lyons Bancorp Inc.',
    symbol: 'LYBC',
  },
  {
    value: 'MCVT',
    label: 'MCVT,  Mill City Ventures III, Ltd.',
    name: 'Mill City Ventures III, Ltd.',
    symbol: 'MCVT',
  },
  {
    value: 'OXBC',
    label: 'OXBC,  Oxford Bank Corporation',
    name: 'Oxford Bank Corporation',
    symbol: 'OXBC',
  },
  {
    value: 'PYYX',
    label: 'PYYX,  Pyxus International, Inc.',
    name: 'Pyxus International, Inc.',
    symbol: 'PYYX',
  },
  {
    value: 'NVCT',
    label: 'NVCT,  Nuvectis Pharma, Inc.',
    name: 'Nuvectis Pharma, Inc.',
    symbol: 'NVCT',
  },
  {
    value: 'ATAKU',
    label: 'ATAKU,  Aurora Technology Acquisition Corp.',
    name: 'Aurora Technology Acquisition Corp.',
    symbol: 'ATAKU',
  },
  {
    value: 'ACLX',
    label: 'ACLX,  Arcellx, Inc.',
    name: 'Arcellx, Inc.',
    symbol: 'ACLX',
  },
  {
    value: 'EVE',
    label: 'EVE,  EVe Mobility Acquisition Corp',
    name: 'EVe Mobility Acquisition Corp',
    symbol: 'EVE',
  },
  {
    value: 'PRLH',
    label: 'PRLH,  Pearl Holdings Acquisition Corp',
    name: 'Pearl Holdings Acquisition Corp',
    symbol: 'PRLH',
  },
  {
    value: 'IVCPW',
    label: 'IVCPW,  Swiftmerge Acquisition Corp.',
    name: 'Swiftmerge Acquisition Corp.',
    symbol: 'IVCPW',
  },
  {
    value: 'AHRN',
    label: 'AHRN,  Ahren Acquisition Corp.',
    name: 'Ahren Acquisition Corp.',
    symbol: 'AHRN',
  },
  {
    value: 'AHRNW',
    label: 'AHRNW,  Ahren Acquisition Corp.',
    name: 'Ahren Acquisition Corp.',
    symbol: 'AHRNW',
  },
  {
    value: 'DRCT',
    label: 'DRCT,  Direct Digital Holdings, Inc.',
    name: 'Direct Digital Holdings, Inc.',
    symbol: 'DRCT',
  },
  {
    value: 'ANGH',
    label: 'ANGH,  Anghami Inc.',
    name: 'Anghami Inc.',
    symbol: 'ANGH',
  },
  {
    value: 'ANGHW',
    label: 'ANGHW,  Anghami Inc.',
    name: 'Anghami Inc.',
    symbol: 'ANGHW',
  },
  {
    value: 'BFAC',
    label: 'BFAC,  Battery Future Acquisition Corp.',
    name: 'Battery Future Acquisition Corp.',
    symbol: 'BFAC',
  },
  {
    value: 'DRCTW',
    label: 'DRCTW,  Direct Digital Holdings, Inc.',
    name: 'Direct Digital Holdings, Inc.',
    symbol: 'DRCTW',
  },
  {
    value: 'PRLHW',
    label: 'PRLHW,  Pearl Holdings Acquisition Corp',
    name: 'Pearl Holdings Acquisition Corp',
    symbol: 'PRLHW',
  },
  {
    value: 'HERC.L',
    label: 'HERC.L,  Hercules Site Services Plc',
    name: 'Hercules Site Services Plc',
    symbol: 'HERC.L',
  },
  {
    value: 'MURFU',
    label: 'MURFU,  Murphy Canyon Acquisition Corp.',
    name: 'Murphy Canyon Acquisition Corp.',
    symbol: 'MURFU',
  },
  {
    value: 'MTBCO',
    label: 'MTBCO,  CareCloud, Inc.',
    name: 'CareCloud, Inc.',
    symbol: 'MTBCO',
  },
  {
    value: 'IVCBW',
    label: 'IVCBW,  Investcorp Europe Acquisition Corp I',
    name: 'Investcorp Europe Acquisition Corp I',
    symbol: 'IVCBW',
  },
  {
    value: 'IVCB',
    label: 'IVCB,  Investcorp Europe Acquisition Corp I',
    name: 'Investcorp Europe Acquisition Corp I',
    symbol: 'IVCB',
  },
  {
    value: 'MTEKW',
    label: 'MTEKW,  Maris-Tech Ltd.',
    name: 'Maris-Tech Ltd.',
    symbol: 'MTEKW',
  },
  {
    value: 'MTEK',
    label: 'MTEK,  Maris-Tech Ltd.',
    name: 'Maris-Tech Ltd.',
    symbol: 'MTEK',
  },
  {
    value: 'SOWG',
    label: 'SOWG,  Sow Good Inc.',
    name: 'Sow Good Inc.',
    symbol: 'SOWG',
  },
  {
    value: 'CEAD',
    label: 'CEAD,  CEA Industries Inc.',
    name: 'CEA Industries Inc.',
    symbol: 'CEAD',
  },
  {
    value: 'TRNF',
    label: 'TRNF,  Taronis Fuels, Inc.',
    name: 'Taronis Fuels, Inc.',
    symbol: 'TRNF',
  },
  {
    value: 'RCOR',
    label: 'RCOR,  Renovacor, Inc.',
    name: 'Renovacor, Inc.',
    symbol: 'RCOR',
  },
  {
    value: 'WINSF',
    label: 'WINSF,  Wins Finance Holdings Inc.',
    name: 'Wins Finance Holdings Inc.',
    symbol: 'WINSF',
  },
  {
    value: 'RENO',
    label: 'RENO,  Renovare Environmental, Inc.',
    name: 'Renovare Environmental, Inc.',
    symbol: 'RENO',
  },
  {
    value: 'ODTC',
    label: 'ODTC,  Odonate Therapeutics, Inc.',
    name: 'Odonate Therapeutics, Inc.',
    symbol: 'ODTC',
  },
  {
    value: 'SISI',
    label: 'SISI,  Shineco, Inc.',
    name: 'Shineco, Inc.',
    symbol: 'SISI',
  },
  {
    value: 'REVB',
    label: 'REVB,  Revelation Biosciences, Inc.',
    name: 'Revelation Biosciences, Inc.',
    symbol: 'REVB',
  },
  {
    value: 'SOND',
    label: 'SOND,  Sonder Holdings Inc.',
    name: 'Sonder Holdings Inc.',
    symbol: 'SOND',
  },
  {
    value: 'SATL',
    label: 'SATL,  Satellogic Inc.',
    name: 'Satellogic Inc.',
    symbol: 'SATL',
  },
  {
    value: 'CORZ',
    label: 'CORZ,  Core Scientific, Inc.',
    name: 'Core Scientific, Inc.',
    symbol: 'CORZ',
  },
  {
    value: 'FICV',
    label: 'FICV,  Frontier Investment Corp',
    name: 'Frontier Investment Corp',
    symbol: 'FICV',
  },
  {
    value: 'ALPA',
    label: 'ALPA,  Alpha Healthcare Acquisition Corp. III',
    name: 'Alpha Healthcare Acquisition Corp. III',
    symbol: 'ALPA',
  },
  {
    value: 'APAC',
    label: 'APAC,  StoneBridge Acquisition Corporation',
    name: 'StoneBridge Acquisition Corporation',
    symbol: 'APAC',
  },
  {
    value: 'APTM',
    label: 'APTM,  Alpha Partners Technology Merger Corp.',
    name: 'Alpha Partners Technology Merger Corp.',
    symbol: 'APTM',
  },
  {
    value: 'ASPA',
    label: 'ASPA,  Abri SPAC I, Inc.',
    name: 'Abri SPAC I, Inc.',
    symbol: 'ASPA',
  },
  {
    value: 'FWBI',
    label: 'FWBI,  First Wave BioPharma, Inc.',
    name: 'First Wave BioPharma, Inc.',
    symbol: 'FWBI',
  },
  {
    value: 'CLOE',
    label: 'CLOE,  Clover Leaf Capital Corp.',
    name: 'Clover Leaf Capital Corp.',
    symbol: 'CLOE',
  },
  {
    value: 'MEAC',
    label: 'MEAC,  Mercury Ecommerce Acquisition Corp.',
    name: 'Mercury Ecommerce Acquisition Corp.',
    symbol: 'MEAC',
  },
  {
    value: 'PFTA',
    label: 'PFTA,  Portage Fintech Acquisition Corporation',
    name: 'Portage Fintech Acquisition Corporation',
    symbol: 'PFTA',
  },
  {
    value: 'TWCB',
    label: 'TWCB,  Bilander Acquisition Corp.',
    name: 'Bilander Acquisition Corp.',
    symbol: 'TWCB',
  },
  {
    value: 'UPTD',
    label: 'UPTD,  TradeUP Acquisition Corp.',
    name: 'TradeUP Acquisition Corp.',
    symbol: 'UPTD',
  },
  {
    value: 'XPAX',
    label: 'XPAX,  XPAC Acquisition Corp.',
    name: 'XPAC Acquisition Corp.',
    symbol: 'XPAX',
  },
  {
    value: 'BNIX',
    label: 'BNIX,  Bannix Acquisition Corp.',
    name: 'Bannix Acquisition Corp.',
    symbol: 'BNIX',
  },
  {
    value: 'BNNR',
    label: 'BNNR,  Banner Acquisition Corp.',
    name: 'Banner Acquisition Corp.',
    symbol: 'BNNR',
  },
  {
    value: 'DTRT',
    label: 'DTRT,  DTRT Health Acquisition Corp.',
    name: 'DTRT Health Acquisition Corp.',
    symbol: 'DTRT',
  },
  {
    value: 'HWEL',
    label: 'HWEL,  Healthwell Acquisition Corp. I',
    name: 'Healthwell Acquisition Corp. I',
    symbol: 'HWEL',
  },
  {
    value: 'IPAX',
    label: 'IPAX,  Inflection Point Acquisition Corp.',
    name: 'Inflection Point Acquisition Corp.',
    symbol: 'IPAX',
  },
  {
    value: 'MEOA',
    label: 'MEOA,  Minority Equality Opportunities Acquisition Inc.',
    name: 'Minority Equality Opportunities Acquisition Inc.',
    symbol: 'MEOA',
  },
  {
    value: 'REVE',
    label: 'REVE,  Alpine Acquisition Corporation',
    name: 'Alpine Acquisition Corporation',
    symbol: 'REVE',
  },
  {
    value: 'SIER',
    label: 'SIER,  Sierra Lake Acquisition Corp.',
    name: 'Sierra Lake Acquisition Corp.',
    symbol: 'SIER',
  },
  {
    value: 'SWSS',
    label: 'SWSS,  Springwater Special Situations Corp.',
    name: 'Springwater Special Situations Corp.',
    symbol: 'SWSS',
  },
  {
    value: 'AACI',
    label: 'AACI,  Armada Acquisition Corp. I',
    name: 'Armada Acquisition Corp. I',
    symbol: 'AACI',
  },
  {
    value: 'AEHA',
    label: 'AEHA,  Aesther Healthcare Acquisition Corp.',
    name: 'Aesther Healthcare Acquisition Corp.',
    symbol: 'AEHA',
  },
  {
    value: 'AIP',
    label: 'AIP,  Arteris, Inc.',
    name: 'Arteris, Inc.',
    symbol: 'AIP',
  },
  {
    value: 'AIRS',
    label: 'AIRS,  AirSculpt Technologies, Inc.',
    name: 'AirSculpt Technologies, Inc.',
    symbol: 'AIRS',
  },
  {
    value: 'ARGU',
    label: 'ARGU,  Argus Capital Corp.',
    name: 'Argus Capital Corp.',
    symbol: 'ARGU',
  },
  {
    value: 'AURA',
    label: 'AURA,  Aura Biosciences, Inc.',
    name: 'Aura Biosciences, Inc.',
    symbol: 'AURA',
  },
  {
    value: 'BMAQ',
    label: 'BMAQ,  Blockchain Moon Acquisition Corp.',
    name: 'Blockchain Moon Acquisition Corp.',
    symbol: 'BMAQ',
  },
  {
    value: 'CCAI',
    label: 'CCAI,  Cascadia Acquisition Corp.',
    name: 'Cascadia Acquisition Corp.',
    symbol: 'CCAI',
  },
  {
    value: 'CIIG',
    label: 'CIIG,  CIIG Capital Partners II, Inc.',
    name: 'CIIG Capital Partners II, Inc.',
    symbol: 'CIIG',
  },
  {
    value: 'GIAC',
    label: 'GIAC,  Gesher I Acquisition Corp.',
    name: 'Gesher I Acquisition Corp.',
    symbol: 'GIAC',
  },
  {
    value: 'HCVI',
    label: 'HCVI,  Hennessy Capital Investment Corp. VI',
    name: 'Hennessy Capital Investment Corp. VI',
    symbol: 'HCVI',
  },
  {
    value: 'HPLT',
    label: 'HPLT,  Home Plate Acquisition Corp.',
    name: 'Home Plate Acquisition Corp.',
    symbol: 'HPLT',
  },
  {
    value: 'MTRY',
    label: 'MTRY,  Monterey Bio Acquisition Corporation',
    name: 'Monterey Bio Acquisition Corporation',
    symbol: 'MTRY',
  },
  {
    value: 'RGF',
    label: 'RGF,  The Real Good Food Company, Inc.',
    name: 'The Real Good Food Company, Inc.',
    symbol: 'RGF',
  },
  {
    value: 'SDIG',
    label: 'SDIG,  Stronghold Digital Mining, Inc.',
    name: 'Stronghold Digital Mining, Inc.',
    symbol: 'SDIG',
  },
  {
    value: 'TRDA',
    label: 'TRDA,  Entrada Therapeutics, Inc.',
    name: 'Entrada Therapeutics, Inc.',
    symbol: 'TRDA',
  },
  {
    value: 'UTRS',
    label: 'UTRS,  Minerva Surgical, Inc.',
    name: 'Minerva Surgical, Inc.',
    symbol: 'UTRS',
  },
  {
    value: 'ARHS',
    label: 'ARHS,  Arhaus, Inc.',
    name: 'Arhaus, Inc.',
    symbol: 'ARHS',
  },
  {
    value: 'COCO',
    label: 'COCO,  The Vita Coco Company, Inc.',
    name: 'The Vita Coco Company, Inc.',
    symbol: 'COCO',
  },
  {
    value: 'PTLO',
    label: "PTLO,  Portillo's Inc.",
    name: "Portillo's Inc.",
    symbol: 'PTLO',
  },
  {
    value: 'VTYX',
    label: 'VTYX,  Ventyx Biosciences, Inc.',
    name: 'Ventyx Biosciences, Inc.',
    symbol: 'VTYX',
  },
  {
    value: 'HHGC',
    label: 'HHGC,  HHG Capital Corporation',
    name: 'HHG Capital Corporation',
    symbol: 'HHGC',
  },
  {
    value: 'MCLD',
    label: 'MCLD,  mCloud Technologies Corp.',
    name: 'mCloud Technologies Corp.',
    symbol: 'MCLD',
  },
  {
    value: 'MDXH',
    label: 'MDXH,  MDxHealth SA',
    name: 'MDxHealth SA',
    symbol: 'MDXH',
  },
  {
    value: 'MYNZ',
    label: 'MYNZ,  Mainz Biomed B.V.',
    name: 'Mainz Biomed B.V.',
    symbol: 'MYNZ',
  },
  {
    value: 'NVNO',
    label: 'NVNO,  enVVeno Medical Corporation',
    name: 'enVVeno Medical Corporation',
    symbol: 'NVNO',
  },
  {
    value: 'LVO',
    label: 'LVO,  LiveOne, Inc.',
    name: 'LiveOne, Inc.',
    symbol: 'LVO',
  },
  {
    value: 'NN',
    label: 'NN,  NextNav Inc.',
    name: 'NextNav Inc.',
    symbol: 'NN',
  },
  {
    value: 'LIAN',
    label: 'LIAN,  LianBio',
    name: 'LianBio',
    symbol: 'LIAN',
  },
  {
    value: 'ASTL',
    label: 'ASTL,  Algoma Steel Group Inc.',
    name: 'Algoma Steel Group Inc.',
    symbol: 'ASTL',
  },
  {
    value: 'CBL',
    label: 'CBL,  CBL & Associates Properties, Inc.',
    name: 'CBL & Associates Properties, Inc.',
    symbol: 'CBL',
  },
  {
    value: 'GLS',
    label: 'GLS,  Gelesis Holdings, Inc.',
    name: 'Gelesis Holdings, Inc.',
    symbol: 'GLS',
  },
  {
    value: 'LIVBU',
    label: 'LIVBU,  LIV Capital Acquisition Corp. II',
    name: 'LIV Capital Acquisition Corp. II',
    symbol: 'LIVBU',
  },
  {
    value: 'TCBP',
    label: 'TCBP,  TC Biopharm (Holdings) Plc',
    name: 'TC Biopharm (Holdings) Plc',
    symbol: 'TCBP',
  },
  {
    value: 'EVGRU',
    label: 'EVGRU,  Evergreen Corporation',
    name: 'Evergreen Corporation',
    symbol: 'EVGRU',
  },
  {
    value: 'SKYH',
    label: 'SKYH,  Sky Harbour Group Corporation',
    name: 'Sky Harbour Group Corporation',
    symbol: 'SKYH',
  },
  {
    value: 'FSRD',
    label: 'FSRD,  Fast Radius, Inc.',
    name: 'Fast Radius, Inc.',
    symbol: 'FSRD',
  },
  {
    value: 'FSRDW',
    label: 'FSRDW,  Fast Radius, Inc.',
    name: 'Fast Radius, Inc.',
    symbol: 'FSRDW',
  },
  {
    value: 'LFLY',
    label: 'LFLY,  Leafly Holdings, Inc.',
    name: 'Leafly Holdings, Inc.',
    symbol: 'LFLY',
  },
  {
    value: 'LFLYW',
    label: 'LFLYW,  Leafly Holdings, Inc.',
    name: 'Leafly Holdings, Inc.',
    symbol: 'LFLYW',
  },
  {
    value: 'ACCYY',
    label: 'ACCYY,  Accor SA',
    name: 'Accor SA',
    symbol: 'ACCYY',
  },
  {
    value: 'ACRO',
    label: 'ACRO,  Acropolis Infrastructure Acquisition Corp.',
    name: 'Acropolis Infrastructure Acquisition Corp.',
    symbol: 'ACRO',
  },
  {
    value: 'ADAL',
    label: 'ADAL,  Anthemis Digital Acquisitions I Corp',
    name: 'Anthemis Digital Acquisitions I Corp',
    symbol: 'ADAL',
  },
  {
    value: 'ADSE',
    label: 'ADSE,  ADS-TEC Energy PLC',
    name: 'ADS-TEC Energy PLC',
    symbol: 'ADSE',
  },
  {
    value: 'AEAE',
    label: 'AEAE,  AltEnergy Acquisition Corp.',
    name: 'AltEnergy Acquisition Corp.',
    symbol: 'AEAE',
  },
  {
    value: 'AFTR',
    label: 'AFTR,  AfterNext HealthTech Acquisition Corp.',
    name: 'AfterNext HealthTech Acquisition Corp.',
    symbol: 'AFTR',
  },
  {
    value: 'AIAD',
    label: 'AIAD,  AiAdvertising, Inc.',
    name: 'AiAdvertising, Inc.',
    symbol: 'AIAD',
  },
  {
    value: 'ALSA',
    label: 'ALSA,  Alpha Star Acquisition Corporation',
    name: 'Alpha Star Acquisition Corporation',
    symbol: 'ALSA',
  },
  {
    value: 'AMTD',
    label: 'AMTD,  AMTD IDEA Group',
    name: 'AMTD IDEA Group',
    symbol: 'AMTD',
  },
  {
    value: 'APCA',
    label: 'APCA,  AP Acquisition Corp.',
    name: 'AP Acquisition Corp.',
    symbol: 'APCA',
  },
  {
    value: 'APN',
    label: 'APN,  Apeiron Capital Investment Corp.',
    name: 'Apeiron Capital Investment Corp.',
    symbol: 'APN',
  },
  {
    value: 'APXI',
    label: 'APXI,  APx Acquisition Corp. I',
    name: 'APx Acquisition Corp. I',
    symbol: 'APXI',
  },
  {
    value: 'ARCK',
    label: 'ARCK,  Arbor Rapha Capital Bioholdings Corp. I',
    name: 'Arbor Rapha Capital Bioholdings Corp. I',
    symbol: 'ARCK',
  },
  {
    value: 'ARIZ',
    label: 'ARIZ,  Arisz Acquisition Corp.',
    name: 'Arisz Acquisition Corp.',
    symbol: 'ARIZ',
  },
  {
    value: 'ATEK',
    label: 'ATEK,  Athena Technology Acquisition Corp. II',
    name: 'Athena Technology Acquisition Corp. II',
    symbol: 'ATEK',
  },
  {
    value: 'AVAC',
    label: 'AVAC,  Avalon Acquisition Inc.',
    name: 'Avalon Acquisition Inc.',
    symbol: 'AVAC',
  },
  {
    value: 'BACA',
    label: 'BACA,  Berenson Acquisition Corp. I',
    name: 'Berenson Acquisition Corp. I',
    symbol: 'BACA',
  },
  {
    value: 'BAFN',
    label: 'BAFN,  BayFirst Financial Corp.',
    name: 'BayFirst Financial Corp.',
    symbol: 'BAFN',
  },
  {
    value: 'BBAI',
    label: 'BBAI,  BigBear.ai Holdings, Inc.',
    name: 'BigBear.ai Holdings, Inc.',
    symbol: 'BBAI',
  },
  {
    value: 'BFDE',
    label: 'BFDE,  Bedford Holdings Corp',
    name: 'Bedford Holdings Corp',
    symbol: 'BFDE',
  },
  {
    value: 'BHAC',
    label: 'BHAC,  Crixus BH3 Acquisition Company',
    name: 'Crixus BH3 Acquisition Company',
    symbol: 'BHAC',
  },
  {
    value: 'BMAC',
    label: 'BMAC,  Black Mountain Acquisition Corp.',
    name: 'Black Mountain Acquisition Corp.',
    symbol: 'BMAC',
  },
  {
    value: 'BNOX',
    label: 'BNOX,  Bionomics Limited',
    name: 'Bionomics Limited',
    symbol: 'BNOX',
  },
  {
    value: 'BOXD',
    label: 'BOXD,  Boxed, Inc.',
    name: 'Boxed, Inc.',
    symbol: 'BOXD',
  },
  {
    value: 'BRKH',
    label: 'BRKH,  Burtech Acquisition Corp.',
    name: 'Burtech Acquisition Corp.',
    symbol: 'BRKH',
  },
  {
    value: 'BSAQ',
    label: 'BSAQ,  Black Spade Acquisition Co',
    name: 'Black Spade Acquisition Co',
    symbol: 'BSAQ',
  },
  {
    value: 'BZFD',
    label: 'BZFD,  BuzzFeed, Inc.',
    name: 'BuzzFeed, Inc.',
    symbol: 'BZFD',
  },
  {
    value: 'CCTS',
    label: 'CCTS,  Cactus Acquisition Corp. 1 Limited',
    name: 'Cactus Acquisition Corp. 1 Limited',
    symbol: 'CCTS',
  },
  {
    value: 'CDAQ',
    label: 'CDAQ,  Compass Digital Acquisition Corp.',
    name: 'Compass Digital Acquisition Corp.',
    symbol: 'CDAQ',
  },
  {
    value: 'CDRO',
    label: 'CDRO,  Codere Online Luxembourg, S.A.',
    name: 'Codere Online Luxembourg, S.A.',
    symbol: 'CDRO',
  },
  {
    value: 'CEG',
    label: 'CEG,  Constellation Energy Corporation',
    name: 'Constellation Energy Corporation',
    symbol: 'CEG',
  },
  {
    value: 'CELJF',
    label: 'CELJF,  Cellcom Israel Ltd.',
    name: 'Cellcom Israel Ltd.',
    symbol: 'CELJF',
  },
  {
    value: 'CMPO',
    label: 'CMPO,  CompoSecure, Inc.',
    name: 'CompoSecure, Inc.',
    symbol: 'CMPO',
  },
  {
    value: 'CMTG',
    label: 'CMTG,  Claros Mortgage Trust, Inc.',
    name: 'Claros Mortgage Trust, Inc.',
    symbol: 'CMTG',
  },
  {
    value: 'CNDA',
    label: 'CNDA,  Concord Acquisition Corp II',
    name: 'Concord Acquisition Corp II',
    symbol: 'CNDA',
  },
  {
    value: 'CNDB',
    label: 'CNDB,  Concord Acquisition Corp III',
    name: 'Concord Acquisition Corp III',
    symbol: 'CNDB',
  },
  {
    value: 'CODYY',
    label: 'CODYY,  Compagnie de Saint-Gobain S.A.',
    name: 'Compagnie de Saint-Gobain S.A.',
    symbol: 'CODYY',
  },
  {
    value: 'CORS',
    label: 'CORS,  Corsair Partnering Corporation',
    name: 'Corsair Partnering Corporation',
    symbol: 'CORS',
  },
  {
    value: 'CRDO',
    label: 'CRDO,  Credo Technology Group Holding Ltd',
    name: 'Credo Technology Group Holding Ltd',
    symbol: 'CRDO',
  },
  {
    value: 'CRGY',
    label: 'CRGY,  Crescent Energy Company',
    name: 'Crescent Energy Company',
    symbol: 'CRGY',
  },
  {
    value: 'CRYM',
    label: 'CRYM,  Cryomass Technologies Inc.',
    name: 'Cryomass Technologies Inc.',
    symbol: 'CRYM',
  },
  {
    value: 'DAOO',
    label: 'DAOO,  Crypto 1 Acquisition Corp',
    name: 'Crypto 1 Acquisition Corp',
    symbol: 'DAOO',
  },
  {
    value: 'DCGO',
    label: 'DCGO,  DocGo Inc.',
    name: 'DocGo Inc.',
    symbol: 'DCGO',
  },
  {
    value: 'DHAC',
    label: 'DHAC,  Digital Health Acquisition Corp.',
    name: 'Digital Health Acquisition Corp.',
    symbol: 'DHAC',
  },
  {
    value: 'DMAQ',
    label: 'DMAQ,  Deep Medicine Acquisition Corp.',
    name: 'Deep Medicine Acquisition Corp.',
    symbol: 'DMAQ',
  },
  {
    value: 'DMYS',
    label: 'DMYS,  dMY Technology Group, Inc. VI',
    name: 'dMY Technology Group, Inc. VI',
    symbol: 'DMYS',
  },
  {
    value: 'DOUG',
    label: 'DOUG,  Douglas Elliman Inc.',
    name: 'Douglas Elliman Inc.',
    symbol: 'DOUG',
  },
  {
    value: 'DPCS',
    label: 'DPCS,  DP Cap Acquisition Corp I',
    name: 'DP Cap Acquisition Corp I',
    symbol: 'DPCS',
  },
  {
    value: 'DWNX',
    label: 'DWNX,  Delhi Bank Corp.',
    name: 'Delhi Bank Corp.',
    symbol: 'DWNX',
  },
  {
    value: 'ENER',
    label: 'ENER,  Accretion Acquisition Corp.',
    name: 'Accretion Acquisition Corp.',
    symbol: 'ENER',
  },
  {
    value: 'ENFN',
    label: 'ENFN,  Enfusion, Inc.',
    name: 'Enfusion, Inc.',
    symbol: 'ENFN',
  },
  {
    value: 'ENTF',
    label: 'ENTF,  Enterprise 4.0 Technology Acquisition Corp.',
    name: 'Enterprise 4.0 Technology Acquisition Corp.',
    symbol: 'ENTF',
  },
  {
    value: 'ESAC',
    label: 'ESAC,  ESGEN Acquisition Corporation',
    name: 'ESGEN Acquisition Corporation',
    symbol: 'ESAC',
  },
  {
    value: 'EVO',
    label: 'EVO,  Evotec SE',
    name: 'Evotec SE',
    symbol: 'EVO',
  },
  {
    value: 'EVTL',
    label: 'EVTL,  Vertical Aerospace Ltd.',
    name: 'Vertical Aerospace Ltd.',
    symbol: 'EVTL',
  },
  {
    value: 'EVTV',
    label: 'EVTV,  Envirotech Vehicles, Inc.',
    name: 'Envirotech Vehicles, Inc.',
    symbol: 'EVTV',
  },
  {
    value: 'EXPGY',
    label: 'EXPGY,  Experian plc',
    name: 'Experian plc',
    symbol: 'EXPGY',
  },
  {
    value: 'FATP',
    label: 'FATP,  Fat Projects Acquisition Corp',
    name: 'Fat Projects Acquisition Corp',
    symbol: 'FATP',
  },
  {
    value: 'FHLT',
    label: 'FHLT,  Future Health ESG Corp.',
    name: 'Future Health ESG Corp.',
    symbol: 'FHLT',
  },
  {
    value: 'FIAC',
    label: 'FIAC,  Focus Impact Acquisition Corp.',
    name: 'Focus Impact Acquisition Corp.',
    symbol: 'FIAC',
  },
  {
    value: 'FLYA',
    label: 'FLYA,  SOAR Technology Acquisition Corp.',
    name: 'SOAR Technology Acquisition Corp.',
    symbol: 'FLYA',
  },
  {
    value: 'FNVT',
    label: 'FNVT,  Finnovate Acquisition Corp.',
    name: 'Finnovate Acquisition Corp.',
    symbol: 'FNVT',
  },
  {
    value: 'FNWD',
    label: 'FNWD,  Finward Bancorp',
    name: 'Finward Bancorp',
    symbol: 'FNWD',
  },
  {
    value: 'FRBN',
    label: 'FRBN,  Forbion European Acquisition Corp.',
    name: 'Forbion European Acquisition Corp.',
    symbol: 'FRBN',
  },
  {
    value: 'GADS',
    label: 'GADS,  Gadsden Properties, Inc.',
    name: 'Gadsden Properties, Inc.',
    symbol: 'GADS',
  },
  {
    value: 'GDNR',
    label: 'GDNR,  Gardiner Healthcare Acquisitions Corp.',
    name: 'Gardiner Healthcare Acquisitions Corp.',
    symbol: 'GDNR',
  },
  {
    value: 'GFGD',
    label: 'GFGD,  The Growth for Good Acquisition Corporation',
    name: 'The Growth for Good Acquisition Corporation',
    symbol: 'GFGD',
  },
  {
    value: 'GGAA',
    label: 'GGAA,  Genesis Growth Tech Acquisition Corp.',
    name: 'Genesis Growth Tech Acquisition Corp.',
    symbol: 'GGAA',
  },
  {
    value: 'GIA',
    label: 'GIA,  GigCapital5, Inc.',
    name: 'GigCapital5, Inc.',
    symbol: 'GIA',
  },
  {
    value: 'GIKLY',
    label: 'GIKLY,  Grifols, S.A.',
    name: 'Grifols, S.A.',
    symbol: 'GIKLY',
  },
  {
    value: 'GTAC',
    label: 'GTAC,  Global Technology Acquisition Corp. I',
    name: 'Global Technology Acquisition Corp. I',
    symbol: 'GTAC',
  },
  {
    value: 'GTTNQ',
    label: 'GTTNQ,  GTT Communications, Inc.',
    name: 'GTT Communications, Inc.',
    symbol: 'GTTNQ',
  },
  {
    value: 'HAIA',
    label: 'HAIA,  Healthcare AI Acquisition Corp.',
    name: 'Healthcare AI Acquisition Corp.',
    symbol: 'HAIA',
  },
  {
    value: 'HCMLY',
    label: 'HCMLY,  Holcim Ltd',
    name: 'Holcim Ltd',
    symbol: 'HCMLY',
  },
  {
    value: 'HGTXU',
    label: 'HGTXU,  Hugoton Royalty Trust',
    name: 'Hugoton Royalty Trust',
    symbol: 'HGTXU',
  },
  {
    value: 'HORI',
    label: 'HORI,  Emerging Markets Horizon Corp.',
    name: 'Emerging Markets Horizon Corp.',
    symbol: 'HORI',
  },
  {
    value: 'HRT',
    label: 'HRT,  HireRight Holdings Corporation',
    name: 'HireRight Holdings Corporation',
    symbol: 'HRT',
  },
  {
    value: 'HWKZ',
    label: 'HWKZ,  Hawks Acquisition Corp',
    name: 'Hawks Acquisition Corp',
    symbol: 'HWKZ',
  },
  {
    value: 'HYPR',
    label: 'HYPR,  Hyperfine, Inc.',
    name: 'Hyperfine, Inc.',
    symbol: 'HYPR',
  },
  {
    value: 'IMPPP',
    label: 'IMPPP,  Imperial Petroleum Inc.',
    name: 'Imperial Petroleum Inc.',
    symbol: 'IMPPP',
  },
  {
    value: 'INAQ',
    label: 'INAQ,  Insight Acquisition Corp.',
    name: 'Insight Acquisition Corp.',
    symbol: 'INAQ',
  },
  {
    value: 'INFA',
    label: 'INFA,  Informatica Inc.',
    name: 'Informatica Inc.',
    symbol: 'INFA',
  },
  {
    value: 'INTE',
    label: 'INTE,  Integral Acquisition Corporation 1',
    name: 'Integral Acquisition Corporation 1',
    symbol: 'INTE',
  },
  {
    value: 'IPCIF',
    label: 'IPCIF,  Intellipharmaceutics International Inc.',
    name: 'Intellipharmaceutics International Inc.',
    symbol: 'IPCIF',
  },
  {
    value: 'IVCP',
    label: 'IVCP,  Swiftmerge Acquisition Corp.',
    name: 'Swiftmerge Acquisition Corp.',
    symbol: 'IVCP',
  },
  {
    value: 'IXAQ',
    label: 'IXAQ,  IX Acquisition Corp.',
    name: 'IX Acquisition Corp.',
    symbol: 'IXAQ',
  },
  {
    value: 'JATT',
    label: 'JATT,  JATT Acquisition Corp',
    name: 'JATT Acquisition Corp',
    symbol: 'JATT',
  },
  {
    value: 'JMAC',
    label: 'JMAC,  Maxpro Capital Acquisition Corp.',
    name: 'Maxpro Capital Acquisition Corp.',
    symbol: 'JMAC',
  },
  {
    value: 'JUN',
    label: 'JUN,  Juniper II Corp.',
    name: 'Juniper II Corp.',
    symbol: 'JUN',
  },
  {
    value: 'JWAC',
    label: 'JWAC,  Jupiter Wellness Acquisition Corp.',
    name: 'Jupiter Wellness Acquisition Corp.',
    symbol: 'JWAC',
  },
  {
    value: 'JXN',
    label: 'JXN,  Jackson Financial Inc.',
    name: 'Jackson Financial Inc.',
    symbol: 'JXN',
  },
  {
    value: 'KATX',
    label: 'KATX,  KAT Exploration Inc.',
    name: 'KAT Exploration Inc.',
    symbol: 'KATX',
  },
  {
    value: 'KBCSY',
    label: 'KBCSY,  KBC Group NV',
    name: 'KBC Group NV',
    symbol: 'KBCSY',
  },
  {
    value: 'KCGI',
    label: 'KCGI,  Kensington Capital Acquisition Corp. V',
    name: 'Kensington Capital Acquisition Corp. V',
    symbol: 'KCGI',
  },
  {
    value: 'KORE',
    label: 'KORE,  KORE Group Holdings, Inc.',
    name: 'KORE Group Holdings, Inc.',
    symbol: 'KORE',
  },
  {
    value: 'LAX',
    label: 'LAX,  8i Acquisition 2 Corp.',
    name: '8i Acquisition 2 Corp.',
    symbol: 'LAX',
  },
  {
    value: 'LFAC',
    label: 'LFAC,  LF Capital Acquisition Corp. II',
    name: 'LF Capital Acquisition Corp. II',
    symbol: 'LFAC',
  },
  {
    value: 'LFG',
    label: 'LFG,  Archaea Energy Inc.',
    name: 'Archaea Energy Inc.',
    symbol: 'LFG',
  },
  {
    value: 'LGTO',
    label: 'LGTO,  Legato Merger Corp. II',
    name: 'Legato Merger Corp. II',
    symbol: 'LGTO',
  },
  {
    value: 'LGVC',
    label: 'LGVC,  LAMF Global Ventures Corp. I',
    name: 'LAMF Global Ventures Corp. I',
    symbol: 'LGVC',
  },
  {
    value: 'LIBY',
    label: 'LIBY,  Liberty Resources Acquisition Corp.',
    name: 'Liberty Resources Acquisition Corp.',
    symbol: 'LIBY',
  },
  {
    value: 'LION',
    label: 'LION,  Lionheart III Corp',
    name: 'Lionheart III Corp',
    symbol: 'LION',
  },
  {
    value: 'LOCL',
    label: 'LOCL,  Local Bounti Corporation',
    name: 'Local Bounti Corporation',
    symbol: 'LOCL',
  },
  {
    value: 'LVAC',
    label: 'LVAC,  LAVA Medtech Acquisition Corp.',
    name: 'LAVA Medtech Acquisition Corp.',
    symbol: 'LVAC',
  },
  {
    value: 'MCAA',
    label: 'MCAA,  Mountain & Co. I Acquisition Corp.',
    name: 'Mountain & Co. I Acquisition Corp.',
    symbol: 'MCAA',
  },
  {
    value: 'MIR',
    label: 'MIR,  Mirion Technologies, Inc.',
    name: 'Mirion Technologies, Inc.',
    symbol: 'MIR',
  },
  {
    value: 'MLAI',
    label: 'MLAI,  McLaren Technology Acquisition Corp.',
    name: 'McLaren Technology Acquisition Corp.',
    symbol: 'MLAI',
  },
  {
    value: 'MMTLP',
    label: 'MMTLP,  Meta Materials Inc.',
    name: 'Meta Materials Inc.',
    symbol: 'MMTLP',
  },
  {
    value: 'MPRA',
    label: 'MPRA,  Mercato Partners Acquisition Corporation',
    name: 'Mercato Partners Acquisition Corporation',
    symbol: 'MPRA',
  },
  {
    value: 'MTAL',
    label: 'MTAL,  Metals Acquisition Corp',
    name: 'Metals Acquisition Corp',
    symbol: 'MTAL',
  },
  {
    value: 'MTVC',
    label: 'MTVC,  Motive Capital Corp II',
    name: 'Motive Capital Corp II',
    symbol: 'MTVC',
  },
  {
    value: 'NCAC',
    label: 'NCAC,  Newcourt Acquisition Corp',
    name: 'Newcourt Acquisition Corp',
    symbol: 'NCAC',
  },
  {
    value: 'NETC',
    label: 'NETC,  Nabors Energy Transition Corp.',
    name: 'Nabors Energy Transition Corp.',
    symbol: 'NETC',
  },
  {
    value: 'NPAB',
    label: 'NPAB,  New Providence Acquisition Corp. II',
    name: 'New Providence Acquisition Corp. II',
    symbol: 'NPAB',
  },
  {
    value: 'NRSN',
    label: 'NRSN,  NeuroSense Therapeutics Ltd.',
    name: 'NeuroSense Therapeutics Ltd.',
    symbol: 'NRSN',
  },
  {
    value: 'NVX',
    label: 'NVX,  Novonix Limited',
    name: 'Novonix Limited',
    symbol: 'NVX',
  },
  {
    value: 'OHAA',
    label: 'OHAA,  OPY Acquisition Corp. I',
    name: 'OPY Acquisition Corp. I',
    symbol: 'OHAA',
  },
  {
    value: 'OIBZQ',
    label: 'OIBZQ,  Oi S.A.',
    name: 'Oi S.A.',
    symbol: 'OIBZQ',
  },
  {
    value: 'ONYX',
    label: 'ONYX,  Onyx Acquisition Co. I',
    name: 'Onyx Acquisition Co. I',
    symbol: 'ONYX',
  },
  {
    value: 'OOGI',
    label: 'OOGI,  C2E Energy, Inc.',
    name: 'C2E Energy, Inc.',
    symbol: 'OOGI',
  },
  {
    value: 'PBAX',
    label: 'PBAX,  Phoenix Biotech Acquisition Corp.',
    name: 'Phoenix Biotech Acquisition Corp.',
    symbol: 'PBAX',
  },
  {
    value: 'PCCT',
    label: 'PCCT,  Perception Capital Corp. II',
    name: 'Perception Capital Corp. II',
    symbol: 'PCCT',
  },
  {
    value: 'PCX',
    label: 'PCX,  Parsec Capital Acquisitions Corp',
    name: 'Parsec Capital Acquisitions Corp',
    symbol: 'PCX',
  },
  {
    value: 'PEGR',
    label: 'PEGR,  Project Energy Reimagined Acquisition Corp.',
    name: 'Project Energy Reimagined Acquisition Corp.',
    symbol: 'PEGR',
  },
  {
    value: 'PEPL',
    label: 'PEPL,  PepperLime Health Acquisition Corporation',
    name: 'PepperLime Health Acquisition Corporation',
    symbol: 'PEPL',
  },
  {
    value: 'PHYT',
    label: 'PHYT,  Pyrophyte Acquisition Corp.',
    name: 'Pyrophyte Acquisition Corp.',
    symbol: 'PHYT',
  },
  {
    value: 'PIII',
    label: 'PIII,  P3 Health Partners Inc.',
    name: 'P3 Health Partners Inc.',
    symbol: 'PIII',
  },
  {
    value: 'PKKFF',
    label: 'PKKFF,  Tenet Fintech Group Inc.',
    name: 'Tenet Fintech Group Inc.',
    symbol: 'PKKFF',
  },
  {
    value: 'PORT',
    label: 'PORT,  Southport Acquisition Corporation',
    name: 'Southport Acquisition Corporation',
    symbol: 'PORT',
  },
  {
    value: 'PRBM',
    label: 'PRBM,  Parabellum Acquisition Corp.',
    name: 'Parabellum Acquisition Corp.',
    symbol: 'PRBM',
  },
  {
    value: 'PRCX',
    label: 'PRCX,  Phoenix Rising Companies',
    name: 'Phoenix Rising Companies',
    symbol: 'PRCX',
  },
  {
    value: 'PVCT',
    label: 'PVCT,  Provectus Biopharmaceuticals, Inc.',
    name: 'Provectus Biopharmaceuticals, Inc.',
    symbol: 'PVCT',
  },
  {
    value: 'RJAC',
    label: 'RJAC,  Jackson Acquisition Company',
    name: 'Jackson Acquisition Company',
    symbol: 'RJAC',
  },
  {
    value: 'RKUNF',
    label: 'RKUNF,  Rakuten Group, Inc.',
    name: 'Rakuten Group, Inc.',
    symbol: 'RKUNF',
  },
  {
    value: 'RKUNY',
    label: 'RKUNY,  Rakuten Group, Inc.',
    name: 'Rakuten Group, Inc.',
    symbol: 'RKUNY',
  },
  {
    value: 'ROSE',
    label: 'ROSE,  Rose Hill Acquisition Corporation',
    name: 'Rose Hill Acquisition Corporation',
    symbol: 'ROSE',
  },
  {
    value: 'RRAC',
    label: 'RRAC,  Rigel Resource Acquisition Corp.',
    name: 'Rigel Resource Acquisition Corp.',
    symbol: 'RRAC',
  },
  {
    value: 'RSTRF',
    label: 'RSTRF,  Restaurant Brands International Limited Partnership',
    name: 'Restaurant Brands International Limited Partnership',
    symbol: 'RSTRF',
  },
  {
    value: 'SAMA',
    label: 'SAMA,  Schultze Special Purpose Acquisition Corp. II',
    name: 'Schultze Special Purpose Acquisition Corp. II',
    symbol: 'SAMA',
  },
  {
    value: 'SANG',
    label: 'SANG,  Sangoma Technologies Corporation',
    name: 'Sangoma Technologies Corporation',
    symbol: 'SANG',
  },
  {
    value: 'SCMA',
    label: 'SCMA,  Seaport Calibre Materials Acquisition Corp.',
    name: 'Seaport Calibre Materials Acquisition Corp.',
    symbol: 'SCMA',
  },
  {
    value: 'SCUA',
    label: 'SCUA,  Sculptor Acquisition Corp I',
    name: 'Sculptor Acquisition Corp I',
    symbol: 'SCUA',
  },
  {
    value: 'SEDA',
    label: 'SEDA,  SDCL EDGE Acquisition Corporation',
    name: 'SDCL EDGE Acquisition Corporation',
    symbol: 'SEDA',
  },
  {
    value: 'SGHC',
    label: 'SGHC,  Super Group (SGHC) Limited',
    name: 'Super Group (SGHC) Limited',
    symbol: 'SGHC',
  },
  {
    value: 'SGII',
    label: 'SGII,  Seaport Global Acquisition II Corp.',
    name: 'Seaport Global Acquisition II Corp.',
    symbol: 'SGII',
  },
  {
    value: 'SHCA',
    label: 'SHCA,  Spindletop Health Acquisition Corp.',
    name: 'Spindletop Health Acquisition Corp.',
    symbol: 'SHCA',
  },
  {
    value: 'SLDP',
    label: 'SLDP,  Solid Power, Inc.',
    name: 'Solid Power, Inc.',
    symbol: 'SLDP',
  },
  {
    value: 'SONX',
    label: 'SONX,  Sonendo, Inc.',
    name: 'Sonendo, Inc.',
    symbol: 'SONX',
  },
  {
    value: 'SPRC',
    label: 'SPRC,  SciSparc Ltd.',
    name: 'SciSparc Ltd.',
    symbol: 'SPRC',
  },
  {
    value: 'SSU',
    label: 'SSU,  SIGNA Sports United N.V.',
    name: 'SIGNA Sports United N.V.',
    symbol: 'SSU',
  },
  {
    value: 'SZZL',
    label: 'SZZL,  Sizzle Acquisition Corp.',
    name: 'Sizzle Acquisition Corp.',
    symbol: 'SZZL',
  },
  {
    value: 'TCOA',
    label: 'TCOA,  Trajectory Alpha Acquisition Corp.',
    name: 'Trajectory Alpha Acquisition Corp.',
    symbol: 'TCOA',
  },
  {
    value: 'TGAA',
    label: 'TGAA,  Target Global Acquisition I Corp.',
    name: 'Target Global Acquisition I Corp.',
    symbol: 'TGAA',
  },
  {
    value: 'THAC',
    label: 'THAC,  Thrive Acquisition Corporation',
    name: 'Thrive Acquisition Corporation',
    symbol: 'THAC',
  },
  {
    value: 'THNPY',
    label: 'THNPY,  Technip Energies N.V.',
    name: 'Technip Energies N.V.',
    symbol: 'THNPY',
  },
  {
    value: 'TOAC',
    label: 'TOAC,  Talon 1 Acquisition Corp.',
    name: 'Talon 1 Acquisition Corp.',
    symbol: 'TOAC',
  },
  {
    value: 'TOI',
    label: 'TOI,  The Oncology Institute, Inc.',
    name: 'The Oncology Institute, Inc.',
    symbol: 'TOI',
  },
  {
    value: 'TRAQ',
    label: 'TRAQ,  Trine II Acquisition Corp.',
    name: 'Trine II Acquisition Corp.',
    symbol: 'TRAQ',
  },
  {
    value: 'TRIS',
    label: 'TRIS,  Tristar Acquisition I Corp.',
    name: 'Tristar Acquisition I Corp.',
    symbol: 'TRIS',
  },
  {
    value: 'TRNX',
    label: 'TRNX,  Taronis Technologies, Inc.',
    name: 'Taronis Technologies, Inc.',
    symbol: 'TRNX',
  },
  {
    value: 'TRTL',
    label: 'TRTL,  TortoiseEcofin Acquisition Corp. III',
    name: 'TortoiseEcofin Acquisition Corp. III',
    symbol: 'TRTL',
  },
  {
    value: 'UHID',
    label: 'UHID,  Universal Health Services, Inc.',
    name: 'Universal Health Services, Inc.',
    symbol: 'UHID',
  },
  {
    value: 'UNRV',
    label: 'UNRV,  Unrivaled Brands, Inc.',
    name: 'Unrivaled Brands, Inc.',
    symbol: 'UNRV',
  },
  {
    value: 'USCT',
    label: 'USCT,  TKB Critical Technologies 1',
    name: 'TKB Critical Technologies 1',
    symbol: 'USCT',
  },
  {
    value: 'USLVF',
    label:
      'USLVF,  VelocityShares 3x Long Silver ETN Linked to the S&P GSCI Silver Index ER',
    name: 'VelocityShares 3x Long Silver ETN Linked to the S&P GSCI Silver Index ER',
    symbol: 'USLVF',
  },
  {
    value: 'VCSA',
    label: 'VCSA,  Vacasa, Inc.',
    name: 'Vacasa, Inc.',
    symbol: 'VCSA',
  },
  {
    value: 'VHNA',
    label: 'VHNA,  Vahanna Tech Edge Acquisition I Corp.',
    name: 'Vahanna Tech Edge Acquisition I Corp.',
    symbol: 'VHNA',
  },
  {
    value: 'VLN',
    label: 'VLN,  Valens Semiconductor Ltd.',
    name: 'Valens Semiconductor Ltd.',
    symbol: 'VLN',
  },
  {
    value: 'VSAC',
    label: 'VSAC,  Vision Sensing Acquisition Corp.',
    name: 'Vision Sensing Acquisition Corp.',
    symbol: 'VSAC',
  },
  {
    value: 'WAVC',
    label: 'WAVC,  Waverley Capital Acquisition Corp. 1',
    name: 'Waverley Capital Acquisition Corp. 1',
    symbol: 'WAVC',
  },
  {
    value: 'WBX',
    label: 'WBX,  Wallbox N.V.',
    name: 'Wallbox N.V.',
    symbol: 'WBX',
  },
  {
    value: 'WEL',
    label: 'WEL,  Integrated Wellness Acquisition Corp',
    name: 'Integrated Wellness Acquisition Corp',
    symbol: 'WEL',
  },
  {
    value: 'WQGA',
    label: 'WQGA,  World Quantum Growth Acquisition Corp.',
    name: 'World Quantum Growth Acquisition Corp.',
    symbol: 'WQGA',
  },
  {
    value: 'WRAC',
    label: 'WRAC,  Williams Rowland Acquisition Corp.',
    name: 'Williams Rowland Acquisition Corp.',
    symbol: 'WRAC',
  },
  {
    value: 'WULF',
    label: 'WULF,  TeraWulf Inc.',
    name: 'TeraWulf Inc.',
    symbol: 'WULF',
  },
  {
    value: 'WWAC',
    label: 'WWAC,  Worldwide Webb Acquisition Corp.',
    name: 'Worldwide Webb Acquisition Corp.',
    symbol: 'WWAC',
  },
  {
    value: 'XFIN',
    label: 'XFIN,  ExcelFin Acquisition Corp.',
    name: 'ExcelFin Acquisition Corp.',
    symbol: 'XFIN',
  },
  {
    value: 'XPDB',
    label: 'XPDB,  Power & Digital Infrastructure Acquisition II Corp.',
    name: 'Power & Digital Infrastructure Acquisition II Corp.',
    symbol: 'XPDB',
  },
  {
    value: 'ZGN',
    label: 'ZGN,  Ermenegildo Zegna N.V.',
    name: 'Ermenegildo Zegna N.V.',
    symbol: 'ZGN',
  },
  {
    value: 'ZUUS',
    label: 'ZUUS,  Zeuus, Inc.',
    name: 'Zeuus, Inc.',
    symbol: 'ZUUS',
  },
  {
    value: 'CPAQU',
    label: 'CPAQU,  Counter Press Acquisition Corporation',
    name: 'Counter Press Acquisition Corporation',
    symbol: 'CPAQU',
  },
  {
    value: 'EMLDW',
    label: 'EMLDW,  FTAC Emerald Acquisition Corp.',
    name: 'FTAC Emerald Acquisition Corp.',
    symbol: 'EMLDW',
  },
  {
    value: 'EMLD',
    label: 'EMLD,  FTAC Emerald Acquisition Corp.',
    name: 'FTAC Emerald Acquisition Corp.',
    symbol: 'EMLD',
  },
  {
    value: 'RCAC',
    label: 'RCAC,  Revelstone Capital Acquisition Corp.',
    name: 'Revelstone Capital Acquisition Corp.',
    symbol: 'RCAC',
  },
  {
    value: 'AREBW',
    label: 'AREBW,  American Rebel Holdings, Inc.',
    name: 'American Rebel Holdings, Inc.',
    symbol: 'AREBW',
  },
  {
    value: 'SHAP',
    label: 'SHAP,  Spree Acquisition Corp. 1 Limited',
    name: 'Spree Acquisition Corp. 1 Limited',
    symbol: 'SHAP',
  },
  {
    value: 'RCACW',
    label: 'RCACW,  Revelstone Capital Acquisition Corp.',
    name: 'Revelstone Capital Acquisition Corp.',
    symbol: 'RCACW',
  },
  {
    value: 'VFINX',
    label: 'VFINX,  Vanguard 500 Index Fund',
    name: 'Vanguard 500 Index Fund',
    symbol: 'VFINX',
  },
  {
    value: 'VFAIX',
    label: 'VFAIX,  Vanguard Financials Index Fund',
    name: 'Vanguard Financials Index Fund',
    symbol: 'VFAIX',
  },
  {
    value: 'HTCR',
    label: 'HTCR,  HeartCore Enterprises, Inc.',
    name: 'HeartCore Enterprises, Inc.',
    symbol: 'HTCR',
  },
  {
    value: 'SKYX',
    label: 'SKYX,  SKYX Platforms Corp.',
    name: 'SKYX Platforms Corp.',
    symbol: 'SKYX',
  },
  {
    value: 'BYNOU',
    label: 'BYNOU,  byNordic Acquisition Corporation',
    name: 'byNordic Acquisition Corporation',
    symbol: 'BYNOU',
  },
  {
    value: 'RACYU',
    label: 'RACYU,  Relativity Acquisition Corp.',
    name: 'Relativity Acquisition Corp.',
    symbol: 'RACYU',
  },
  {
    value: 'SGHLU',
    label: 'SGHLU,  Signal Hill Acquisition Corp.',
    name: 'Signal Hill Acquisition Corp.',
    symbol: 'SGHLU',
  },
  {
    value: 'JGGCU',
    label: 'JGGCU,  Jaguar Global Growth Corporation I',
    name: 'Jaguar Global Growth Corporation I',
    symbol: 'JGGCU',
  },
  {
    value: 'ASCAU',
    label: 'ASCAU,  A SPAC I Acquisition Corp.',
    name: 'A SPAC I Acquisition Corp.',
    symbol: 'ASCAU',
  },
  {
    value: 'PAYTM.NS',
    label: 'PAYTM.NS,  One97 Communications Limited',
    name: 'One97 Communications Limited',
    symbol: 'PAYTM.NS',
  },
  {
    value: 'SIGACHI.NS',
    label: 'SIGACHI.NS,  Sigachi Industries Limited',
    name: 'Sigachi Industries Limited',
    symbol: 'SIGACHI.NS',
  },
  {
    value: 'RATEGAIN.NS',
    label: 'RATEGAIN.NS,  RateGain Travel Technologies Limited',
    name: 'RateGain Travel Technologies Limited',
    symbol: 'RATEGAIN.NS',
  },
  {
    value: 'MTA.PA',
    label:
      'MTA.PA,  MULTI-UNITS LUXEMBOURG - Lyxor Euro Government Bond 1-3Y (DR) UCITS ETF',
    name: 'MULTI-UNITS LUXEMBOURG - Lyxor Euro Government Bond 1-3Y (DR) UCITS ETF',
    symbol: 'MTA.PA',
  },
  {
    value: 'AAC.PA',
    label: 'AAC.PA,  Accor Acquisition Company S.A.',
    name: 'Accor Acquisition Company S.A.',
    symbol: 'AAC.PA',
  },
  {
    value: 'MLAA.PA',
    label: 'MLAA.PA,  L Agence Automobiliere',
    name: 'L Agence Automobiliere',
    symbol: 'MLAA.PA',
  },
  {
    value: 'MLAIR.PA',
    label: 'MLAIR.PA,  Groupe Airwell Société anonyme',
    name: 'Groupe Airwell Société anonyme',
    symbol: 'MLAIR.PA',
  },
  {
    value: 'ALAMA.PA',
    label: 'ALAMA.PA,  AMA Corporation Plc',
    name: 'AMA Corporation Plc',
    symbol: 'ALAMA.PA',
  },
  {
    value: 'ANTIN.PA',
    label: 'ANTIN.PA,  Antin Infrastructure Partners S.A.',
    name: 'Antin Infrastructure Partners S.A.',
    symbol: 'ANTIN.PA',
  },
  {
    value: 'ARAMI.PA',
    label: 'ARAMI.PA,  Aramis Group SAS',
    name: 'Aramis Group SAS',
    symbol: 'ARAMI.PA',
  },
  {
    value: 'MLBBO.PA',
    label: 'MLBBO.PA,  BEBO Health SA',
    name: 'BEBO Health SA',
    symbol: 'MLBBO.PA',
  },
  {
    value: 'BLV.PA',
    label: 'BLV.PA,  Believe S.A.',
    name: 'Believe S.A.',
    symbol: 'BLV.PA',
  },
  {
    value: 'ALBOA.PA',
    label: 'ALBOA.PA,  BOA Concept SAS',
    name: 'BOA Concept SAS',
    symbol: 'ALBOA.PA',
  },
  {
    value: 'ALCBI.PA',
    label: 'ALCBI.PA,  Crypto Blockchain Industries',
    name: 'Crypto Blockchain Industries',
    symbol: 'ALCBI.PA',
  },
  {
    value: 'DEE.PA',
    label: 'DEE.PA,  Dee Tech S.A.',
    name: 'Dee Tech S.A.',
    symbol: 'DEE.PA',
  },
  {
    value: 'ALENO.PA',
    label: 'ALENO.PA,  Enogia SAS',
    name: 'Enogia SAS',
    symbol: 'ALENO.PA',
  },
  {
    value: 'FORSE.PA',
    label: 'FORSE.PA,  Forsee Power Société anonyme',
    name: 'Forsee Power Société anonyme',
    symbol: 'FORSE.PA',
  },
  {
    value: 'ALGRO.PA',
    label: 'ALGRO.PA,  Grolleau Société Anonyme',
    name: 'Grolleau Société Anonyme',
    symbol: 'ALGRO.PA',
  },
  {
    value: 'ALKEM.PA',
    label: 'ALKEM.PA,  Groupe Berkem Société anonyme',
    name: 'Groupe Berkem Société anonyme',
    symbol: 'ALKEM.PA',
  },
  {
    value: 'MLCAS.PA',
    label: 'MLCAS.PA,  Groupe Casol',
    name: 'Groupe Casol',
    symbol: 'MLCAS.PA',
  },
  {
    value: 'ALHGO.PA',
    label: 'ALHGO.PA,  Hamilton Global Opportunities PLC',
    name: 'Hamilton Global Opportunities PLC',
    symbol: 'ALHGO.PA',
  },
  {
    value: 'ALHYP.PA',
    label: 'ALHYP.PA,  HiPay Group SA',
    name: 'HiPay Group SA',
    symbol: 'ALHYP.PA',
  },
  {
    value: 'ALHPI.PA',
    label: 'ALHPI.PA,  Hopium SA',
    name: 'Hopium SA',
    symbol: 'ALHPI.PA',
  },
  {
    value: 'ALIKO.PA',
    label: 'ALIKO.PA,  Ikonisys S.A.',
    name: 'Ikonisys S.A.',
    symbol: 'ALIKO.PA',
  },
  {
    value: 'ALKEY.PA',
    label: 'ALKEY.PA,  Keyrus S.A.',
    name: 'Keyrus S.A.',
    symbol: 'ALKEY.PA',
  },
  {
    value: 'ALVAP.PA',
    label: 'ALVAP.PA,  Kumulus Vape S.A.',
    name: 'Kumulus Vape S.A.',
    symbol: 'ALVAP.PA',
  },
  {
    value: 'ALLGO.PA',
    label: 'ALLGO.PA,  Largo SA',
    name: 'Largo SA',
    symbol: 'ALLGO.PA',
  },
  {
    value: 'MLPAP.PA',
    label: 'MLPAP.PA,  Les Agences de Papa Société anonyme',
    name: 'Les Agences de Papa Société anonyme',
    symbol: 'MLPAP.PA',
  },
  {
    value: 'MAAT.PA',
    label: 'MAAT.PA,  MaaT Pharma SA',
    name: 'MaaT Pharma SA',
    symbol: 'MAAT.PA',
  },
  {
    value: 'MLMDV.PA',
    label: 'MLMDV.PA,  Medical Devices Venture S.A.',
    name: 'Medical Devices Venture S.A.',
    symbol: 'MLMDV.PA',
  },
  {
    value: 'ALMDP.PA',
    label: 'ALMDP.PA,  Medesis Pharma S.A.',
    name: 'Medesis Pharma S.A.',
    symbol: 'ALMDP.PA',
  },
  {
    value: 'ALNMR.PA',
    label: 'ALNMR.PA,  namR Société anonyme',
    name: 'namR Société anonyme',
    symbol: 'ALNMR.PA',
  },
  {
    value: 'ALNTG.PA',
    label: 'ALNTG.PA,  Netgem SA',
    name: 'Netgem SA',
    symbol: 'ALNTG.PA',
  },
  {
    value: 'ALNFL.PA',
    label: 'ALNFL.PA,  NFL Biosciences SA',
    name: 'NFL Biosciences SA',
    symbol: 'ALNFL.PA',
  },
  {
    value: 'ALODC.PA',
    label: 'ALODC.PA,  Omer-Decugis & Cie SA',
    name: 'Omer-Decugis & Cie SA',
    symbol: 'ALODC.PA',
  },
  {
    value: 'PEUG.PA',
    label: 'PEUG.PA,  Peugeot Invest Société anonyme',
    name: 'Peugeot Invest Société anonyme',
    symbol: 'PEUG.PA',
  },
  {
    value: 'ALPHE.PA',
    label: 'ALPHE.PA,  Pherecydes Pharma Société anonyme',
    name: 'Pherecydes Pharma Société anonyme',
    symbol: 'ALPHE.PA',
  },
  {
    value: 'ALRPD.PA',
    label: 'ALRPD.PA,  Rapid Nutrition PLC',
    name: 'Rapid Nutrition PLC',
    symbol: 'ALRPD.PA',
  },
  {
    value: 'ALRIB.PA',
    label: 'ALRIB.PA,  Riber S.A.',
    name: 'Riber S.A.',
    symbol: 'ALRIB.PA',
  },
  {
    value: 'ALSPT.PA',
    label: 'ALSPT.PA,  Spartoo SAS',
    name: 'Spartoo SAS',
    symbol: 'ALSPT.PA',
  },
  {
    value: 'TE.PA',
    label: 'TE.PA,  Technip Energies N.V.',
    name: 'Technip Energies N.V.',
    symbol: 'TE.PA',
  },
  {
    value: 'ALTOO.PA',
    label: 'ALTOO.PA,  Toosla Société Anonyme',
    name: 'Toosla Société Anonyme',
    symbol: 'ALTOO.PA',
  },
  {
    value: 'TRAN.PA',
    label: 'TRAN.PA,  Transition S.A.',
    name: 'Transition S.A.',
    symbol: 'TRAN.PA',
  },
  {
    value: 'WAGA.PA',
    label: 'WAGA.PA,  Waga Energy Société anonyme',
    name: 'Waga Energy Société anonyme',
    symbol: 'WAGA.PA',
  },
  {
    value: 'ALWF.PA',
    label: 'ALWF.PA,  WINFARM Société anonyme',
    name: 'WINFARM Société anonyme',
    symbol: 'ALWF.PA',
  },
  {
    value: 'MLWIZ.PA',
    label: 'MLWIZ.PA,  Wiziboat SA',
    name: 'Wiziboat SA',
    symbol: 'MLWIZ.PA',
  },
  {
    value: 'MDV',
    label: 'MDV,  Modiv Inc.',
    name: 'Modiv Inc.',
    symbol: 'MDV',
  },
  {
    value: 'AWL.NS',
    label: 'AWL.NS,  Adani Wilmar Limited',
    name: 'Adani Wilmar Limited',
    symbol: 'AWL.NS',
  },
  {
    value: 'AGSTRA.NS',
    label: 'AGSTRA.NS,  AGS Transact Technologies Limited',
    name: 'AGS Transact Technologies Limited',
    symbol: 'AGSTRA.NS',
  },
  {
    value: 'METROBRAND.NS',
    label: 'METROBRAND.NS,  Metro Brands Limited',
    name: 'Metro Brands Limited',
    symbol: 'METROBRAND.NS',
  },
  {
    value: 'MAPMYINDIA.NS',
    label: 'MAPMYINDIA.NS,  C. E. Info Systems Limited',
    name: 'C. E. Info Systems Limited',
    symbol: 'MAPMYINDIA.NS',
  },
  {
    value: 'SHRIRAMPPS.NS',
    label: 'SHRIRAMPPS.NS,  Shriram Properties Limited',
    name: 'Shriram Properties Limited',
    symbol: 'SHRIRAMPPS.NS',
  },
  {
    value: 'ANANDRATHI.NS',
    label: 'ANANDRATHI.NS,  Anand Rathi Wealth Limited',
    name: 'Anand Rathi Wealth Limited',
    symbol: 'ANANDRATHI.NS',
  },
  {
    value: 'TEGA.NS',
    label: 'TEGA.NS,  Tega Industries Limited',
    name: 'Tega Industries Limited',
    symbol: 'TEGA.NS',
  },
  {
    value: 'STARHEALTH.NS',
    label: 'STARHEALTH.NS,  Star Health and Allied Insurance Company Limited',
    name: 'Star Health and Allied Insurance Company Limited',
    symbol: 'STARHEALTH.NS',
  },
  {
    value: 'GOCOLORS.NS',
    label: 'GOCOLORS.NS,  Go Fashion (India) Limited',
    name: 'Go Fashion (India) Limited',
    symbol: 'GOCOLORS.NS',
  },
  {
    value: 'TARSONS.NS',
    label: 'TARSONS.NS,  Tarsons Products Limited',
    name: 'Tarsons Products Limited',
    symbol: 'TARSONS.NS',
  },
  {
    value: '2157.HK',
    label: '2157.HK,  Lepu Biopharma Co., Ltd.',
    name: 'Lepu Biopharma Co., Ltd.',
    symbol: '2157.HK',
  },
  {
    value: 'NRGV',
    label: 'NRGV,  Energy Vault Holdings, Inc.',
    name: 'Energy Vault Holdings, Inc.',
    symbol: 'NRGV',
  },
  {
    value: 'ISPO',
    label: 'ISPO,  Inspirato Incorporated',
    name: 'Inspirato Incorporated',
    symbol: 'ISPO',
  },
  {
    value: 'ISPOW',
    label: 'ISPOW,  Inspirato Incorporated',
    name: 'Inspirato Incorporated',
    symbol: 'ISPOW',
  },
  {
    value: 'BTOG',
    label: 'BTOG,  Bit Origin Ltd',
    name: 'Bit Origin Ltd',
    symbol: 'BTOG',
  },
  {
    value: 'MANYAVAR.NS',
    label: 'MANYAVAR.NS,  Vedant Fashions Limited',
    name: 'Vedant Fashions Limited',
    symbol: 'MANYAVAR.NS',
  },
  {
    value: 'LATENTVIEW.NS',
    label: 'LATENTVIEW.NS,  Latent View Analytics Limited',
    name: 'Latent View Analytics Limited',
    symbol: 'LATENTVIEW.NS',
  },
  {
    value: 'SAPPHIRE.NS',
    label: 'SAPPHIRE.NS,  Sapphire Foods India Limited',
    name: 'Sapphire Foods India Limited',
    symbol: 'SAPPHIRE.NS',
  },
  {
    value: 'SJS.NS',
    label: 'SJS.NS,  S.J.S. Enterprises Limited',
    name: 'S.J.S. Enterprises Limited',
    symbol: 'SJS.NS',
  },
  {
    value: 'POLICYBZR.NS',
    label: 'POLICYBZR.NS,  PB Fintech Limited',
    name: 'PB Fintech Limited',
    symbol: 'POLICYBZR.NS',
  },
  {
    value: 'FINOPB.NS',
    label: 'FINOPB.NS,  Fino Payments Bank Limited',
    name: 'Fino Payments Bank Limited',
    symbol: 'FINOPB.NS',
  },
  {
    value: 'ABSLAMC.NS',
    label: 'ABSLAMC.NS,  Aditya Birla Sun Life AMC Limited',
    name: 'Aditya Birla Sun Life AMC Limited',
    symbol: 'ABSLAMC.NS',
  },
  {
    value: 'SMRT',
    label: 'SMRT,  SmartRent, Inc.',
    name: 'SmartRent, Inc.',
    symbol: 'SMRT',
  },
  {
    value: 'ABBA.JK',
    label: 'ABBA.JK,  PT Mahaka Media Tbk',
    name: 'PT Mahaka Media Tbk',
    symbol: 'ABBA.JK',
  },
  {
    value: 'ABDA.JK',
    label: 'ABDA.JK,  PT Asuransi Bina Dana Arta Tbk',
    name: 'PT Asuransi Bina Dana Arta Tbk',
    symbol: 'ABDA.JK',
  },
  {
    value: 'ABMM.JK',
    label: 'ABMM.JK,  PT ABM Investama Tbk',
    name: 'PT ABM Investama Tbk',
    symbol: 'ABMM.JK',
  },
  {
    value: 'ACST.JK',
    label: 'ACST.JK,  PT Acset Indonusa Tbk',
    name: 'PT Acset Indonusa Tbk',
    symbol: 'ACST.JK',
  },
  {
    value: 'ADES.JK',
    label: 'ADES.JK,  PT Akasha Wira International Tbk',
    name: 'PT Akasha Wira International Tbk',
    symbol: 'ADES.JK',
  },
  {
    value: 'ADMF.JK',
    label: 'ADMF.JK,  PT Adira Dinamika Multi Finance Tbk',
    name: 'PT Adira Dinamika Multi Finance Tbk',
    symbol: 'ADMF.JK',
  },
  {
    value: 'ADMG.JK',
    label: 'ADMG.JK,  PT. Polychem Indonesia Tbk',
    name: 'PT. Polychem Indonesia Tbk',
    symbol: 'ADMG.JK',
  },
  {
    value: 'AGAR.JK',
    label: 'AGAR.JK,  PT Asia Sejahtera Mina Tbk',
    name: 'PT Asia Sejahtera Mina Tbk',
    symbol: 'AGAR.JK',
  },
  {
    value: 'AGII.JK',
    label: 'AGII.JK,  PT Aneka Gas Industri Tbk',
    name: 'PT Aneka Gas Industri Tbk',
    symbol: 'AGII.JK',
  },
  {
    value: 'AGRS.JK',
    label: 'AGRS.JK,  PT Bank IBK Indonesia Tbk',
    name: 'PT Bank IBK Indonesia Tbk',
    symbol: 'AGRS.JK',
  },
  {
    value: 'AHAP.JK',
    label: 'AHAP.JK,  PT Asuransi Harta Aman Pratama Tbk',
    name: 'PT Asuransi Harta Aman Pratama Tbk',
    symbol: 'AHAP.JK',
  },
  {
    value: 'AIMS.JK',
    label: 'AIMS.JK,  PT Akbar Indo Makmur Stimec Tbk',
    name: 'PT Akbar Indo Makmur Stimec Tbk',
    symbol: 'AIMS.JK',
  },
  {
    value: 'AKKU.JK',
    label: 'AKKU.JK,  PT Anugerah Kagum Karya Utama Tbk',
    name: 'PT Anugerah Kagum Karya Utama Tbk',
    symbol: 'AKKU.JK',
  },
  {
    value: 'AKPI.JK',
    label: 'AKPI.JK,  PT Argha Karya Prima Industry Tbk',
    name: 'PT Argha Karya Prima Industry Tbk',
    symbol: 'AKPI.JK',
  },
  {
    value: 'AKSI.JK',
    label: 'AKSI.JK,  PT Mineral Sumberdaya Mandiri Tbk',
    name: 'PT Mineral Sumberdaya Mandiri Tbk',
    symbol: 'AKSI.JK',
  },
  {
    value: 'ALDO.JK',
    label: 'ALDO.JK,  PT Alkindo Naratama Tbk',
    name: 'PT Alkindo Naratama Tbk',
    symbol: 'ALDO.JK',
  },
  {
    value: 'ALKA.JK',
    label: 'ALKA.JK,  PT Alakasa Industrindo Tbk',
    name: 'PT Alakasa Industrindo Tbk',
    symbol: 'ALKA.JK',
  },
  {
    value: 'ALMI.JK',
    label: 'ALMI.JK,  PT Alumindo Light Metal Industry Tbk',
    name: 'PT Alumindo Light Metal Industry Tbk',
    symbol: 'ALMI.JK',
  },
  {
    value: 'ALTO.JK',
    label: 'ALTO.JK,  PT Tri Banyan Tirta Tbk',
    name: 'PT Tri Banyan Tirta Tbk',
    symbol: 'ALTO.JK',
  },
  {
    value: 'AMAG.JK',
    label: 'AMAG.JK,  PT Asuransi Multi Artha Guna Tbk',
    name: 'PT Asuransi Multi Artha Guna Tbk',
    symbol: 'AMAG.JK',
  },
  {
    value: 'AMAN.JK',
    label: 'AMAN.JK,  PT Makmur Berkah Amanda Tbk',
    name: 'PT Makmur Berkah Amanda Tbk',
    symbol: 'AMAN.JK',
  },
  {
    value: 'AMAR.JK',
    label: 'AMAR.JK,  PT Bank Amar Indonesia Tbk',
    name: 'PT Bank Amar Indonesia Tbk',
    symbol: 'AMAR.JK',
  },
  {
    value: 'AMFG.JK',
    label: 'AMFG.JK,  PT Asahimas Flat Glass Tbk',
    name: 'PT Asahimas Flat Glass Tbk',
    symbol: 'AMFG.JK',
  },
  {
    value: 'AMIN.JK',
    label: "AMIN.JK,  PT Ateliers Mecaniques D'Indonesie Tbk",
    name: "PT Ateliers Mecaniques D'Indonesie Tbk",
    symbol: 'AMIN.JK',
  },
  {
    value: 'AMOR.JK',
    label: 'AMOR.JK,  PT Ashmore Asset Management Indonesia Tbk',
    name: 'PT Ashmore Asset Management Indonesia Tbk',
    symbol: 'AMOR.JK',
  },
  {
    value: 'ANDI.JK',
    label: 'ANDI.JK,  PT. Andira Agro, Tbk',
    name: 'PT. Andira Agro, Tbk',
    symbol: 'ANDI.JK',
  },
  {
    value: 'ANJT.JK',
    label: 'ANJT.JK,  PT Austindo Nusantara Jaya Tbk',
    name: 'PT Austindo Nusantara Jaya Tbk',
    symbol: 'ANJT.JK',
  },
  {
    value: 'APEX.JK',
    label: 'APEX.JK,  PT Apexindo Pratama Duta Tbk',
    name: 'PT Apexindo Pratama Duta Tbk',
    symbol: 'APEX.JK',
  },
  {
    value: 'APII.JK',
    label: 'APII.JK,  PT Arita Prima Indonesia Tbk',
    name: 'PT Arita Prima Indonesia Tbk',
    symbol: 'APII.JK',
  },
  {
    value: 'APLI.JK',
    label: 'APLI.JK,  PT Asiaplast Industries Tbk',
    name: 'PT Asiaplast Industries Tbk',
    symbol: 'APLI.JK',
  },
  {
    value: 'ARGO.JK',
    label: 'ARGO.JK,  PT Argo Pantes Tbk',
    name: 'PT Argo Pantes Tbk',
    symbol: 'ARGO.JK',
  },
  {
    value: 'ARII.JK',
    label: 'ARII.JK,  PT Atlas Resources Tbk',
    name: 'PT Atlas Resources Tbk',
    symbol: 'ARII.JK',
  },
  {
    value: 'ARKA.JK',
    label: 'ARKA.JK,  PT Arkha Jayanti Persada Tbk',
    name: 'PT Arkha Jayanti Persada Tbk',
    symbol: 'ARKA.JK',
  },
  {
    value: 'ARNA.JK',
    label: 'ARNA.JK,  PT Arwana Citramulia Tbk',
    name: 'PT Arwana Citramulia Tbk',
    symbol: 'ARNA.JK',
  },
  {
    value: 'ARTA.JK',
    label: 'ARTA.JK,  PT Arthavest Tbk',
    name: 'PT Arthavest Tbk',
    symbol: 'ARTA.JK',
  },
  {
    value: 'ASBI.JK',
    label: 'ASBI.JK,  PT Asuransi Bintang Tbk',
    name: 'PT Asuransi Bintang Tbk',
    symbol: 'ASBI.JK',
  },
  {
    value: 'ASDM.JK',
    label: 'ASDM.JK,  PT Asuransi Dayin Mitra Tbk',
    name: 'PT Asuransi Dayin Mitra Tbk',
    symbol: 'ASDM.JK',
  },
  {
    value: 'ASJT.JK',
    label: 'ASJT.JK,  PT Asuransi Jasa Tania Tbk',
    name: 'PT Asuransi Jasa Tania Tbk',
    symbol: 'ASJT.JK',
  },
  {
    value: 'ASMI.JK',
    label: 'ASMI.JK,  PT Asuransi Maximus Graha Persada Tbk',
    name: 'PT Asuransi Maximus Graha Persada Tbk',
    symbol: 'ASMI.JK',
  },
  {
    value: 'ASPI.JK',
    label: 'ASPI.JK,  PT Andalan Sakti Primaindo Tbk',
    name: 'PT Andalan Sakti Primaindo Tbk',
    symbol: 'ASPI.JK',
  },
  {
    value: 'ASRM.JK',
    label: 'ASRM.JK,  PT Asuransi Ramayana Tbk',
    name: 'PT Asuransi Ramayana Tbk',
    symbol: 'ASRM.JK',
  },
  {
    value: 'ATAP.JK',
    label: 'ATAP.JK,  PT Trimitra Prawara Goldland Tbk',
    name: 'PT Trimitra Prawara Goldland Tbk',
    symbol: 'ATAP.JK',
  },
  {
    value: 'ATIC.JK',
    label: 'ATIC.JK,  PT Anabatic Technologies Tbk',
    name: 'PT Anabatic Technologies Tbk',
    symbol: 'ATIC.JK',
  },
  {
    value: 'AUTO.JK',
    label: 'AUTO.JK,  PT Astra Otoparts Tbk',
    name: 'PT Astra Otoparts Tbk',
    symbol: 'AUTO.JK',
  },
  {
    value: 'AYLS.JK',
    label: 'AYLS.JK,  PT Agro Yasa Lestari Tbk',
    name: 'PT Agro Yasa Lestari Tbk',
    symbol: 'AYLS.JK',
  },
  {
    value: 'BACA.JK',
    label: 'BACA.JK,  PT Bank Capital Indonesia Tbk',
    name: 'PT Bank Capital Indonesia Tbk',
    symbol: 'BACA.JK',
  },
  {
    value: 'BAJA.JK',
    label: 'BAJA.JK,  PT Saranacentral Bajatama Tbk',
    name: 'PT Saranacentral Bajatama Tbk',
    symbol: 'BAJA.JK',
  },
  {
    value: 'BALI.JK',
    label: 'BALI.JK,  PT Bali Towerindo Sentra Tbk',
    name: 'PT Bali Towerindo Sentra Tbk',
    symbol: 'BALI.JK',
  },
  {
    value: 'BANK.JK',
    label: 'BANK.JK,  PT Bank Aladin Syariah Tbk',
    name: 'PT Bank Aladin Syariah Tbk',
    symbol: 'BANK.JK',
  },
  {
    value: 'BAPA.JK',
    label: 'BAPA.JK,  PT Bekasi Asri Pemula Tbk',
    name: 'PT Bekasi Asri Pemula Tbk',
    symbol: 'BAPA.JK',
  },
  {
    value: 'BAPI.JK',
    label: 'BAPI.JK,  PT Bhakti Agung Propertindo Tbk',
    name: 'PT Bhakti Agung Propertindo Tbk',
    symbol: 'BAPI.JK',
  },
  {
    value: 'BATA.JK',
    label: 'BATA.JK,  PT Sepatu Bata Tbk.',
    name: 'PT Sepatu Bata Tbk.',
    symbol: 'BATA.JK',
  },
  {
    value: 'BAYU.JK',
    label: 'BAYU.JK,  PT Bayu Buana Tbk',
    name: 'PT Bayu Buana Tbk',
    symbol: 'BAYU.JK',
  },
  {
    value: 'BBHI.JK',
    label: 'BBHI.JK,  PT Allo Bank Indonesia Tbk',
    name: 'PT Allo Bank Indonesia Tbk',
    symbol: 'BBHI.JK',
  },
  {
    value: 'BBLD.JK',
    label: 'BBLD.JK,  PT Buana Finance Tbk',
    name: 'PT Buana Finance Tbk',
    symbol: 'BBLD.JK',
  },
  {
    value: 'BBMD.JK',
    label: 'BBMD.JK,  PT Bank Mestika Dharma Tbk',
    name: 'PT Bank Mestika Dharma Tbk',
    symbol: 'BBMD.JK',
  },
  {
    value: 'BBRM.JK',
    label: 'BBRM.JK,  PT Pelayaran Nasional Bina Buana Raya Tbk',
    name: 'PT Pelayaran Nasional Bina Buana Raya Tbk',
    symbol: 'BBRM.JK',
  },
  {
    value: 'BBSS.JK',
    label: 'BBSS.JK,  PT Bumi Benowo Sukses Sejahtera Tbk',
    name: 'PT Bumi Benowo Sukses Sejahtera Tbk',
    symbol: 'BBSS.JK',
  },
  {
    value: 'BBYB.JK',
    label: 'BBYB.JK,  PT Bank Neo Commerce Tbk',
    name: 'PT Bank Neo Commerce Tbk',
    symbol: 'BBYB.JK',
  },
  {
    value: 'BCAP.JK',
    label: 'BCAP.JK,  PT MNC Kapital Indonesia Tbk',
    name: 'PT MNC Kapital Indonesia Tbk',
    symbol: 'BCAP.JK',
  },
  {
    value: 'BCIC.JK',
    label: 'BCIC.JK,  PT Bank JTrust Indonesia Tbk',
    name: 'PT Bank JTrust Indonesia Tbk',
    symbol: 'BCIC.JK',
  },
  {
    value: 'BCIP.JK',
    label: 'BCIP.JK,  PT Bumi Citra Permai Tbk',
    name: 'PT Bumi Citra Permai Tbk',
    symbol: 'BCIP.JK',
  },
  {
    value: 'BEBS.JK',
    label: 'BEBS.JK,  PT Berkah Beton Sadaya Tbk',
    name: 'PT Berkah Beton Sadaya Tbk',
    symbol: 'BEBS.JK',
  },
  {
    value: 'BEEF.JK',
    label: 'BEEF.JK,  PT Estika Tata Tiara Tbk',
    name: 'PT Estika Tata Tiara Tbk',
    symbol: 'BEEF.JK',
  },
  {
    value: 'BEKS.JK',
    label: 'BEKS.JK,  PT. Bank Pembangunan Daerah Banten, Tbk',
    name: 'PT. Bank Pembangunan Daerah Banten, Tbk',
    symbol: 'BEKS.JK',
  },
  {
    value: 'BELL.JK',
    label: 'BELL.JK,  PT Trisula Textile Industries Tbk',
    name: 'PT Trisula Textile Industries Tbk',
    symbol: 'BELL.JK',
  },
  {
    value: 'BESS.JK',
    label: 'BESS.JK,  PT Batulicin Nusantara Maritim Tbk',
    name: 'PT Batulicin Nusantara Maritim Tbk',
    symbol: 'BESS.JK',
  },
  {
    value: 'BGTG.JK',
    label: 'BGTG.JK,  PT Bank Ganesha Tbk',
    name: 'PT Bank Ganesha Tbk',
    symbol: 'BGTG.JK',
  },
  {
    value: 'BHAT.JK',
    label: 'BHAT.JK,  PT Bhakti Multi Artha Tbk',
    name: 'PT Bhakti Multi Artha Tbk',
    symbol: 'BHAT.JK',
  },
  {
    value: 'BHIT.JK',
    label: 'BHIT.JK,  PT MNC Asia Holding Tbk',
    name: 'PT MNC Asia Holding Tbk',
    symbol: 'BHIT.JK',
  },
  {
    value: 'BIKA.JK',
    label: 'BIKA.JK,  PT Binakarya Jaya Abadi Tbk',
    name: 'PT Binakarya Jaya Abadi Tbk',
    symbol: 'BIKA.JK',
  },
  {
    value: 'BIMA.JK',
    label: 'BIMA.JK,  PT Primarindo Asia Infrastructure Tbk.',
    name: 'PT Primarindo Asia Infrastructure Tbk.',
    symbol: 'BIMA.JK',
  },
  {
    value: 'BINA.JK',
    label: 'BINA.JK,  PT Bank Ina Perdana Tbk',
    name: 'PT Bank Ina Perdana Tbk',
    symbol: 'BINA.JK',
  },
  {
    value: 'BINO.JK',
    label: 'BINO.JK,  PT Perma Plasindo Tbk',
    name: 'PT Perma Plasindo Tbk',
    symbol: 'BINO.JK',
  },
  {
    value: 'BIPI.JK',
    label: 'BIPI.JK,  PT Astrindo Nusantara Infrastruktur Tbk',
    name: 'PT Astrindo Nusantara Infrastruktur Tbk',
    symbol: 'BIPI.JK',
  },
  {
    value: 'BIPP.JK',
    label: 'BIPP.JK,  PT Bhuwanatala Indah Permai Tbk',
    name: 'PT Bhuwanatala Indah Permai Tbk',
    symbol: 'BIPP.JK',
  },
  {
    value: 'BIRD.JK',
    label: 'BIRD.JK,  PT Blue Bird Tbk',
    name: 'PT Blue Bird Tbk',
    symbol: 'BIRD.JK',
  },
  {
    value: 'BISI.JK',
    label: 'BISI.JK,  PT BISI International Tbk',
    name: 'PT BISI International Tbk',
    symbol: 'BISI.JK',
  },
  {
    value: 'BKDP.JK',
    label: 'BKDP.JK,  PT Bukit Darmo Property Tbk',
    name: 'PT Bukit Darmo Property Tbk',
    symbol: 'BKDP.JK',
  },
  {
    value: 'BKSW.JK',
    label: 'BKSW.JK,  PT Bank QNB Indonesia Tbk',
    name: 'PT Bank QNB Indonesia Tbk',
    symbol: 'BKSW.JK',
  },
  {
    value: 'BLTZ.JK',
    label: 'BLTZ.JK,  PT Graha Layar Prima Tbk',
    name: 'PT Graha Layar Prima Tbk',
    symbol: 'BLTZ.JK',
  },
  {
    value: 'BMAS.JK',
    label: 'BMAS.JK,  PT Bank Maspion Indonesia Tbk',
    name: 'PT Bank Maspion Indonesia Tbk',
    symbol: 'BMAS.JK',
  },
  {
    value: 'BMSR.JK',
    label: 'BMSR.JK,  PT Bintang Mitra Semestaraya Tbk',
    name: 'PT Bintang Mitra Semestaraya Tbk',
    symbol: 'BMSR.JK',
  },
  {
    value: 'BNBA.JK',
    label: 'BNBA.JK,  PT Bank Bumi Arta Tbk',
    name: 'PT Bank Bumi Arta Tbk',
    symbol: 'BNBA.JK',
  },
  {
    value: 'BNBR.JK',
    label: 'BNBR.JK,  PT Bakrie & Brothers Tbk',
    name: 'PT Bakrie & Brothers Tbk',
    symbol: 'BNBR.JK',
  },
  {
    value: 'BNII.JK',
    label: 'BNII.JK,  PT Bank Maybank Indonesia Tbk',
    name: 'PT Bank Maybank Indonesia Tbk',
    symbol: 'BNII.JK',
  },
  {
    value: 'BOLA.JK',
    label: 'BOLA.JK,  PT Bali Bintang Sejahtera Tbk',
    name: 'PT Bali Bintang Sejahtera Tbk',
    symbol: 'BOLA.JK',
  },
  {
    value: 'BOLT.JK',
    label: 'BOLT.JK,  PT Garuda Metalindo Tbk',
    name: 'PT Garuda Metalindo Tbk',
    symbol: 'BOLT.JK',
  },
  {
    value: 'BOSS.JK',
    label: 'BOSS.JK,  PT. Borneo Olah Sarana Sukses Tbk',
    name: 'PT. Borneo Olah Sarana Sukses Tbk',
    symbol: 'BOSS.JK',
  },
  {
    value: 'BPFI.JK',
    label: 'BPFI.JK,  PT Batavia Prosperindo Finance Tbk',
    name: 'PT Batavia Prosperindo Finance Tbk',
    symbol: 'BPFI.JK',
  },
  {
    value: 'BPII.JK',
    label: 'BPII.JK,  PT Batavia Prosperindo Internasional Tbk',
    name: 'PT Batavia Prosperindo Internasional Tbk',
    symbol: 'BPII.JK',
  },
  {
    value: 'BPTR.JK',
    label: 'BPTR.JK,  PT Batavia Prosperindo Trans Tbk',
    name: 'PT Batavia Prosperindo Trans Tbk',
    symbol: 'BPTR.JK',
  },
  {
    value: 'BRAM.JK',
    label: 'BRAM.JK,  PT Indo Kordsa Tbk',
    name: 'PT Indo Kordsa Tbk',
    symbol: 'BRAM.JK',
  },
  {
    value: 'BRNA.JK',
    label: 'BRNA.JK,  PT Berlina Tbk',
    name: 'PT Berlina Tbk',
    symbol: 'BRNA.JK',
  },
  {
    value: 'BSIM.JK',
    label: 'BSIM.JK,  PT Bank Sinarmas Tbk',
    name: 'PT Bank Sinarmas Tbk',
    symbol: 'BSIM.JK',
  },
  {
    value: 'BSSR.JK',
    label: 'BSSR.JK,  PT Baramulti Suksessarana Tbk',
    name: 'PT Baramulti Suksessarana Tbk',
    symbol: 'BSSR.JK',
  },
  {
    value: 'BTEK.JK',
    label: 'BTEK.JK,  PT Bumi Teknokultura Unggul Tbk',
    name: 'PT Bumi Teknokultura Unggul Tbk',
    symbol: 'BTEK.JK',
  },
  {
    value: 'BTON.JK',
    label: 'BTON.JK,  PT Betonjaya Manunggal Tbk',
    name: 'PT Betonjaya Manunggal Tbk',
    symbol: 'BTON.JK',
  },
  {
    value: 'BTPN.JK',
    label: 'BTPN.JK,  PT Bank BTPN Tbk',
    name: 'PT Bank BTPN Tbk',
    symbol: 'BTPN.JK',
  },
  {
    value: 'BUDI.JK',
    label: 'BUDI.JK,  PT Budi Starch & Sweetener Tbk',
    name: 'PT Budi Starch & Sweetener Tbk',
    symbol: 'BUDI.JK',
  },
  {
    value: 'BUKK.JK',
    label: 'BUKK.JK,  PT Bukaka Teknik Utama Tbk.',
    name: 'PT Bukaka Teknik Utama Tbk.',
    symbol: 'BUKK.JK',
  },
  {
    value: 'BVIC.JK',
    label: 'BVIC.JK,  PT Bank Victoria International Tbk',
    name: 'PT Bank Victoria International Tbk',
    symbol: 'BVIC.JK',
  },
  {
    value: 'BWPT.JK',
    label: 'BWPT.JK,  PT Eagle High Plantations Tbk',
    name: 'PT Eagle High Plantations Tbk',
    symbol: 'BWPT.JK',
  },
  {
    value: 'BYAN.JK',
    label: 'BYAN.JK,  PT Bayan Resources Tbk',
    name: 'PT Bayan Resources Tbk',
    symbol: 'BYAN.JK',
  },
  {
    value: 'CAKK.JK',
    label: 'CAKK.JK,  PT Cahayaputra Asa Keramik Tbk',
    name: 'PT Cahayaputra Asa Keramik Tbk',
    symbol: 'CAKK.JK',
  },
  {
    value: 'CAMP.JK',
    label: 'CAMP.JK,  PT Campina Ice Cream Industry, Tbk.',
    name: 'PT Campina Ice Cream Industry, Tbk.',
    symbol: 'CAMP.JK',
  },
  {
    value: 'CANI.JK',
    label: 'CANI.JK,  PT Capitol Nusantara Indonesia Tbk',
    name: 'PT Capitol Nusantara Indonesia Tbk',
    symbol: 'CANI.JK',
  },
  {
    value: 'CARS.JK',
    label: 'CARS.JK,  PT Industri dan Perdagangan Bintraco Dharma Tbk',
    name: 'PT Industri dan Perdagangan Bintraco Dharma Tbk',
    symbol: 'CARS.JK',
  },
  {
    value: 'CASA.JK',
    label: 'CASA.JK,  PT Capital Financial Indonesia Tbk',
    name: 'PT Capital Financial Indonesia Tbk',
    symbol: 'CASA.JK',
  },
  {
    value: 'CBMF.JK',
    label: 'CBMF.JK,  PT Cahaya Bintang Medan Tbk',
    name: 'PT Cahaya Bintang Medan Tbk',
    symbol: 'CBMF.JK',
  },
  {
    value: 'CCSI.JK',
    label: 'CCSI.JK,  PT Communication Cable Systems Indonesia Tbk',
    name: 'PT Communication Cable Systems Indonesia Tbk',
    symbol: 'CCSI.JK',
  },
  {
    value: 'CEKA.JK',
    label: 'CEKA.JK,  PT Wilmar Cahaya Indonesia Tbk.',
    name: 'PT Wilmar Cahaya Indonesia Tbk.',
    symbol: 'CEKA.JK',
  },
  {
    value: 'CENT.JK',
    label: 'CENT.JK,  PT Centratama Telekomunikasi Indonesia Tbk',
    name: 'PT Centratama Telekomunikasi Indonesia Tbk',
    symbol: 'CENT.JK',
  },
  {
    value: 'CFIN.JK',
    label: 'CFIN.JK,  PT. Clipan Finance Indonesia Tbk',
    name: 'PT. Clipan Finance Indonesia Tbk',
    symbol: 'CFIN.JK',
  },
  {
    value: 'CINT.JK',
    label: 'CINT.JK,  PT Chitose Internasional Tbk',
    name: 'PT Chitose Internasional Tbk',
    symbol: 'CINT.JK',
  },
  {
    value: 'CITA.JK',
    label: 'CITA.JK,  PT Cita Mineral Investindo Tbk',
    name: 'PT Cita Mineral Investindo Tbk',
    symbol: 'CITA.JK',
  },
  {
    value: 'CITY.JK',
    label: 'CITY.JK,  PT Natura City Developments Tbk',
    name: 'PT Natura City Developments Tbk',
    symbol: 'CITY.JK',
  },
  {
    value: 'CLAY.JK',
    label: 'CLAY.JK,  PT Citra Putra Realty Tbk',
    name: 'PT Citra Putra Realty Tbk',
    symbol: 'CLAY.JK',
  },
  {
    value: 'CLEO.JK',
    label: 'CLEO.JK,  PT Sariguna Primatirta Tbk',
    name: 'PT Sariguna Primatirta Tbk',
    symbol: 'CLEO.JK',
  },
  {
    value: 'CLPI.JK',
    label: 'CLPI.JK,  PT Colorpak Indonesia Tbk',
    name: 'PT Colorpak Indonesia Tbk',
    symbol: 'CLPI.JK',
  },
  {
    value: 'CNKO.JK',
    label: 'CNKO.JK,  PT Exploitasi Energi Indonesia Tbk',
    name: 'PT Exploitasi Energi Indonesia Tbk',
    symbol: 'CNKO.JK',
  },
  {
    value: 'CNTX.JK',
    label: 'CNTX.JK,  PT Century Textile Industry Tbk',
    name: 'PT Century Textile Industry Tbk',
    symbol: 'CNTX.JK',
  },
  {
    value: 'COCO.JK',
    label: 'COCO.JK,  PT Wahana Interfood Nusantara Tbk',
    name: 'PT Wahana Interfood Nusantara Tbk',
    symbol: 'COCO.JK',
  },
  {
    value: 'CPRI.JK',
    label: 'CPRI.JK,  PT Capri Nusa Satu Properti Tbk',
    name: 'PT Capri Nusa Satu Properti Tbk',
    symbol: 'CPRI.JK',
  },
  {
    value: 'CPRO.JK',
    label: 'CPRO.JK,  PT Central Proteina Prima Tbk',
    name: 'PT Central Proteina Prima Tbk',
    symbol: 'CPRO.JK',
  },
  {
    value: 'CSAP.JK',
    label: 'CSAP.JK,  PT Catur Sentosa Adiprana Tbk',
    name: 'PT Catur Sentosa Adiprana Tbk',
    symbol: 'CSAP.JK',
  },
  {
    value: 'CSIS.JK',
    label: 'CSIS.JK,  PT Cahayasakti Investindo Sukses Tbk',
    name: 'PT Cahayasakti Investindo Sukses Tbk',
    symbol: 'CSIS.JK',
  },
  {
    value: 'CSRA.JK',
    label: 'CSRA.JK,  PT Cisadane Sawit Raya Tbk',
    name: 'PT Cisadane Sawit Raya Tbk',
    symbol: 'CSRA.JK',
  },
  {
    value: 'CTTH.JK',
    label: 'CTTH.JK,  PT Citatah Tbk',
    name: 'PT Citatah Tbk',
    symbol: 'CTTH.JK',
  },
  {
    value: 'DADA.JK',
    label: 'DADA.JK,  PT Diamond Citra Propertindo Tbk',
    name: 'PT Diamond Citra Propertindo Tbk',
    symbol: 'DADA.JK',
  },
  {
    value: 'DART.JK',
    label: 'DART.JK,  PT Duta Anggada Realty Tbk.',
    name: 'PT Duta Anggada Realty Tbk.',
    symbol: 'DART.JK',
  },
  {
    value: 'DAYA.JK',
    label: 'DAYA.JK,  PT Duta Intidaya Tbk',
    name: 'PT Duta Intidaya Tbk',
    symbol: 'DAYA.JK',
  },
  {
    value: 'DCII.JK',
    label: 'DCII.JK,  PT DCI Indonesia Tbk',
    name: 'PT DCI Indonesia Tbk',
    symbol: 'DCII.JK',
  },
  {
    value: 'DEAL.JK',
    label: 'DEAL.JK,  PT Dewata Freightinternational Tbk',
    name: 'PT Dewata Freightinternational Tbk',
    symbol: 'DEAL.JK',
  },
  {
    value: 'DEWA.JK',
    label: 'DEWA.JK,  PT Darma Henwa Tbk',
    name: 'PT Darma Henwa Tbk',
    symbol: 'DEWA.JK',
  },
  {
    value: 'DFAM.JK',
    label: 'DFAM.JK,  PT Dafam Property Indonesia Tbk',
    name: 'PT Dafam Property Indonesia Tbk',
    symbol: 'DFAM.JK',
  },
  {
    value: 'DGIK.JK',
    label: 'DGIK.JK,  PT Nusa Konstruksi Enjiniring Tbk',
    name: 'PT Nusa Konstruksi Enjiniring Tbk',
    symbol: 'DGIK.JK',
  },
  {
    value: 'DGNS.JK',
    label: 'DGNS.JK,  PT Diagnos Laboratorium Utama Tbk',
    name: 'PT Diagnos Laboratorium Utama Tbk',
    symbol: 'DGNS.JK',
  },
  {
    value: 'DILD.JK',
    label: 'DILD.JK,  PT Intiland Development Tbk',
    name: 'PT Intiland Development Tbk',
    symbol: 'DILD.JK',
  },
  {
    value: 'DKFT.JK',
    label: 'DKFT.JK,  PT Central Omega Resources Tbk',
    name: 'PT Central Omega Resources Tbk',
    symbol: 'DKFT.JK',
  },
  {
    value: 'DLTA.JK',
    label: 'DLTA.JK,  PT Delta Djakarta Tbk',
    name: 'PT Delta Djakarta Tbk',
    symbol: 'DLTA.JK',
  },
  {
    value: 'DMND.JK',
    label: 'DMND.JK,  PT Diamond Food Indonesia Tbk',
    name: 'PT Diamond Food Indonesia Tbk',
    symbol: 'DMND.JK',
  },
  {
    value: 'DNAR.JK',
    label: 'DNAR.JK,  PT Bank Oke Indonesia Tbk',
    name: 'PT Bank Oke Indonesia Tbk',
    symbol: 'DNAR.JK',
  },
  {
    value: 'DNET.JK',
    label: 'DNET.JK,  PT Indoritel Makmur Internasional Tbk.',
    name: 'PT Indoritel Makmur Internasional Tbk.',
    symbol: 'DNET.JK',
  },
  {
    value: 'DPNS.JK',
    label: 'DPNS.JK,  PT Duta Pertiwi Nusantara Tbk',
    name: 'PT Duta Pertiwi Nusantara Tbk',
    symbol: 'DPNS.JK',
  },
  {
    value: 'DSFI.JK',
    label: 'DSFI.JK,  PT Dharma Samudera Fishing Industries Tbk',
    name: 'PT Dharma Samudera Fishing Industries Tbk',
    symbol: 'DSFI.JK',
  },
  {
    value: 'DSNG.JK',
    label: 'DSNG.JK,  PT Dharma Satya Nusantara Tbk',
    name: 'PT Dharma Satya Nusantara Tbk',
    symbol: 'DSNG.JK',
  },
  {
    value: 'DSSA.JK',
    label: 'DSSA.JK,  PT Dian Swastatika Sentosa Tbk',
    name: 'PT Dian Swastatika Sentosa Tbk',
    symbol: 'DSSA.JK',
  },
  {
    value: 'DUTI.JK',
    label: 'DUTI.JK,  PT Duta Pertiwi Tbk',
    name: 'PT Duta Pertiwi Tbk',
    symbol: 'DUTI.JK',
  },
  {
    value: 'DVLA.JK',
    label: 'DVLA.JK,  PT Darya-Varia Laboratoria Tbk',
    name: 'PT Darya-Varia Laboratoria Tbk',
    symbol: 'DVLA.JK',
  },
  {
    value: 'DWGL.JK',
    label: 'DWGL.JK,  PT Dwi Guna Laksana Tbk',
    name: 'PT Dwi Guna Laksana Tbk',
    symbol: 'DWGL.JK',
  },
  {
    value: 'DYAN.JK',
    label: 'DYAN.JK,  PT Dyandra Media International Tbk',
    name: 'PT Dyandra Media International Tbk',
    symbol: 'DYAN.JK',
  },
  {
    value: 'EAST.JK',
    label: 'EAST.JK,  PT Eastparc Hotel Tbk',
    name: 'PT Eastparc Hotel Tbk',
    symbol: 'EAST.JK',
  },
  {
    value: 'ECII.JK',
    label: 'ECII.JK,  PT Electronic City Indonesia Tbk',
    name: 'PT Electronic City Indonesia Tbk',
    symbol: 'ECII.JK',
  },
  {
    value: 'EDGE.JK',
    label: 'EDGE.JK,  PT Indointernet Tbk.',
    name: 'PT Indointernet Tbk.',
    symbol: 'EDGE.JK',
  },
  {
    value: 'EKAD.JK',
    label: 'EKAD.JK,  PT Ekadharma International Tbk',
    name: 'PT Ekadharma International Tbk',
    symbol: 'EKAD.JK',
  },
  {
    value: 'ELSA.JK',
    label: 'ELSA.JK,  PT Elnusa Tbk',
    name: 'PT Elnusa Tbk',
    symbol: 'ELSA.JK',
  },
  {
    value: 'ELTY.JK',
    label: 'ELTY.JK,  PT Bakrieland Development Tbk',
    name: 'PT Bakrieland Development Tbk',
    symbol: 'ELTY.JK',
  },
  {
    value: 'EMDE.JK',
    label: 'EMDE.JK,  PT Megapolitan Developments Tbk',
    name: 'PT Megapolitan Developments Tbk',
    symbol: 'EMDE.JK',
  },
  {
    value: 'ENRG.JK',
    label: 'ENRG.JK,  PT Energi Mega Persada Tbk',
    name: 'PT Energi Mega Persada Tbk',
    symbol: 'ENRG.JK',
  },
  {
    value: 'ENZO.JK',
    label: 'ENZO.JK,  PT Morenzo Abadi Perkasa Tbk',
    name: 'PT Morenzo Abadi Perkasa Tbk',
    symbol: 'ENZO.JK',
  },
  {
    value: 'EPAC.JK',
    label: 'EPAC.JK,  PT Megalestari Epack Sentosaraya Tbk',
    name: 'PT Megalestari Epack Sentosaraya Tbk',
    symbol: 'EPAC.JK',
  },
  {
    value: 'EPMT.JK',
    label: 'EPMT.JK,  PT Enseval Putera Megatrading Tbk.',
    name: 'PT Enseval Putera Megatrading Tbk.',
    symbol: 'EPMT.JK',
  },
  {
    value: 'ERTX.JK',
    label: 'ERTX.JK,  PT Eratex Djaja Tbk',
    name: 'PT Eratex Djaja Tbk',
    symbol: 'ERTX.JK',
  },
  {
    value: 'ESIP.JK',
    label: 'ESIP.JK,  PT Sinergi Inti Plastindo Tbk',
    name: 'PT Sinergi Inti Plastindo Tbk',
    symbol: 'ESIP.JK',
  },
  {
    value: 'ESSA.JK',
    label: 'ESSA.JK,  PT Surya Esa Perkasa Tbk',
    name: 'PT Surya Esa Perkasa Tbk',
    symbol: 'ESSA.JK',
  },
  {
    value: 'ESTA.JK',
    label: 'ESTA.JK,  PT Esta Multi Usaha Tbk',
    name: 'PT Esta Multi Usaha Tbk',
    symbol: 'ESTA.JK',
  },
  {
    value: 'ESTI.JK',
    label: 'ESTI.JK,  PT Ever Shine Tex Tbk',
    name: 'PT Ever Shine Tex Tbk',
    symbol: 'ESTI.JK',
  },
  {
    value: 'ETWA.JK',
    label: 'ETWA.JK,  PT Eterindo Wahanatama Tbk',
    name: 'PT Eterindo Wahanatama Tbk',
    symbol: 'ETWA.JK',
  },
  {
    value: 'FAPA.JK',
    label: 'FAPA.JK,  PT FAP Agri Tbk',
    name: 'PT FAP Agri Tbk',
    symbol: 'FAPA.JK',
  },
  {
    value: 'FAST.JK',
    label: 'FAST.JK,  PT Fast Food Indonesia Tbk',
    name: 'PT Fast Food Indonesia Tbk',
    symbol: 'FAST.JK',
  },
  {
    value: 'FILM.JK',
    label: 'FILM.JK,  PT MD Pictures Tbk',
    name: 'PT MD Pictures Tbk',
    symbol: 'FILM.JK',
  },
  {
    value: 'FIRE.JK',
    label: 'FIRE.JK,  PT Alfa Energi Investama Tbk',
    name: 'PT Alfa Energi Investama Tbk',
    symbol: 'FIRE.JK',
  },
  {
    value: 'FISH.JK',
    label: 'FISH.JK,  PT FKS Multi Agro Tbk',
    name: 'PT FKS Multi Agro Tbk',
    symbol: 'FISH.JK',
  },
  {
    value: 'FITT.JK',
    label: 'FITT.JK,  PT Hotel Fitra International Tbk',
    name: 'PT Hotel Fitra International Tbk',
    symbol: 'FITT.JK',
  },
  {
    value: 'FMII.JK',
    label: 'FMII.JK,  PT Fortune Mate Indonesia Tbk',
    name: 'PT Fortune Mate Indonesia Tbk',
    symbol: 'FMII.JK',
  },
  {
    value: 'FOOD.JK',
    label: 'FOOD.JK,  PT Sentra Food Indonesia Tbk',
    name: 'PT Sentra Food Indonesia Tbk',
    symbol: 'FOOD.JK',
  },
  {
    value: 'FORU.JK',
    label: 'FORU.JK,  PT Fortune Indonesia Tbk',
    name: 'PT Fortune Indonesia Tbk',
    symbol: 'FORU.JK',
  },
  {
    value: 'FPNI.JK',
    label: 'FPNI.JK,  PT Lotte Chemical Titan Tbk',
    name: 'PT Lotte Chemical Titan Tbk',
    symbol: 'FPNI.JK',
  },
  {
    value: 'FUJI.JK',
    label: 'FUJI.JK,  PT Fuji Finance Indonesia Tbk',
    name: 'PT Fuji Finance Indonesia Tbk',
    symbol: 'FUJI.JK',
  },
  {
    value: 'GAMA.JK',
    label: 'GAMA.JK,  PT Aksara Global Development Tbk',
    name: 'PT Aksara Global Development Tbk',
    symbol: 'GAMA.JK',
  },
  {
    value: 'GDST.JK',
    label: 'GDST.JK,  PT Gunawan Dianjaya Steel Tbk',
    name: 'PT Gunawan Dianjaya Steel Tbk',
    symbol: 'GDST.JK',
  },
  {
    value: 'GDYR.JK',
    label: 'GDYR.JK,  PT Goodyear Indonesia Tbk',
    name: 'PT Goodyear Indonesia Tbk',
    symbol: 'GDYR.JK',
  },
  {
    value: 'GEMA.JK',
    label: 'GEMA.JK,  PT Gema Grahasarana Tbk',
    name: 'PT Gema Grahasarana Tbk',
    symbol: 'GEMA.JK',
  },
  {
    value: 'GGRP.JK',
    label: 'GGRP.JK,  PT Gunung Raja Paksi Tbk',
    name: 'PT Gunung Raja Paksi Tbk',
    symbol: 'GGRP.JK',
  },
  {
    value: 'GLOB.JK',
    label: 'GLOB.JK,  PT Globe Kita Terang Tbk',
    name: 'PT Globe Kita Terang Tbk',
    symbol: 'GLOB.JK',
  },
  {
    value: 'GMFI.JK',
    label: 'GMFI.JK,  PT Garuda Maintenance Facility Aero Asia Tbk',
    name: 'PT Garuda Maintenance Facility Aero Asia Tbk',
    symbol: 'GMFI.JK',
  },
  {
    value: 'GMTD.JK',
    label: 'GMTD.JK,  PT Gowa Makassar Tourism Development Tbk',
    name: 'PT Gowa Makassar Tourism Development Tbk',
    symbol: 'GMTD.JK',
  },
  {
    value: 'GOOD.JK',
    label: 'GOOD.JK,  PT Garudafood Putra Putri Jaya Tbk',
    name: 'PT Garudafood Putra Putri Jaya Tbk',
    symbol: 'GOOD.JK',
  },
  {
    value: 'GPRA.JK',
    label: 'GPRA.JK,  PT Perdana Gapuraprima Tbk',
    name: 'PT Perdana Gapuraprima Tbk',
    symbol: 'GPRA.JK',
  },
  {
    value: 'GSMF.JK',
    label: 'GSMF.JK,  PT Equity Development Investment Tbk',
    name: 'PT Equity Development Investment Tbk',
    symbol: 'GSMF.JK',
  },
  {
    value: 'GWSA.JK',
    label: 'GWSA.JK,  PT Greenwood Sejahtera Tbk',
    name: 'PT Greenwood Sejahtera Tbk',
    symbol: 'GWSA.JK',
  },
  {
    value: 'GZCO.JK',
    label: 'GZCO.JK,  PT Gozco Plantations Tbk',
    name: 'PT Gozco Plantations Tbk',
    symbol: 'GZCO.JK',
  },
  {
    value: 'HADE.JK',
    label: 'HADE.JK,  PT Himalaya Energi Perkasa Tbk',
    name: 'PT Himalaya Energi Perkasa Tbk',
    symbol: 'HADE.JK',
  },
  {
    value: 'HDFA.JK',
    label: 'HDFA.JK,  PT Radana Bhaskara Finance Tbk',
    name: 'PT Radana Bhaskara Finance Tbk',
    symbol: 'HDFA.JK',
  },
  {
    value: 'HEAL.JK',
    label: 'HEAL.JK,  PT Medikaloka Hermina Tbk',
    name: 'PT Medikaloka Hermina Tbk',
    symbol: 'HEAL.JK',
  },
  {
    value: 'HELI.JK',
    label: 'HELI.JK,  PT Jaya Trishindo Tbk',
    name: 'PT Jaya Trishindo Tbk',
    symbol: 'HELI.JK',
  },
  {
    value: 'HERO.JK',
    label: 'HERO.JK,  PT Hero Supermarket Tbk',
    name: 'PT Hero Supermarket Tbk',
    symbol: 'HERO.JK',
  },
  {
    value: 'HEXA.JK',
    label: 'HEXA.JK,  PT Hexindo Adiperkasa Tbk',
    name: 'PT Hexindo Adiperkasa Tbk',
    symbol: 'HEXA.JK',
  },
  {
    value: 'HITS.JK',
    label: 'HITS.JK,  PT Humpuss Intermoda Transportasi Tbk.',
    name: 'PT Humpuss Intermoda Transportasi Tbk.',
    symbol: 'HITS.JK',
  },
  {
    value: 'HKMU.JK',
    label: 'HKMU.JK,  PT HK Metals Utama Tbk',
    name: 'PT HK Metals Utama Tbk',
    symbol: 'HKMU.JK',
  },
  {
    value: 'HOKI.JK',
    label: 'HOKI.JK,  PT Buyung Poetra Sembada Tbk',
    name: 'PT Buyung Poetra Sembada Tbk',
    symbol: 'HOKI.JK',
  },
  {
    value: 'HOMI.JK',
    label: 'HOMI.JK,  PT Grand House Mulia Tbk',
    name: 'PT Grand House Mulia Tbk',
    symbol: 'HOMI.JK',
  },
  {
    value: 'HRME.JK',
    label: 'HRME.JK,  PT Menteng Heritage Realty Tbk',
    name: 'PT Menteng Heritage Realty Tbk',
    symbol: 'HRME.JK',
  },
  {
    value: 'HRTA.JK',
    label: 'HRTA.JK,  PT Hartadinata Abadi Tbk',
    name: 'PT Hartadinata Abadi Tbk',
    symbol: 'HRTA.JK',
  },
  {
    value: 'IATA.JK',
    label: 'IATA.JK,  PT MNC Energy Investments Tbk',
    name: 'PT MNC Energy Investments Tbk',
    symbol: 'IATA.JK',
  },
  {
    value: 'IBST.JK',
    label: 'IBST.JK,  PT Inti Bangun Sejahtera Tbk',
    name: 'PT Inti Bangun Sejahtera Tbk',
    symbol: 'IBST.JK',
  },
  {
    value: 'ICON.JK',
    label: 'ICON.JK,  PT Island Concepts Indonesia Tbk',
    name: 'PT Island Concepts Indonesia Tbk',
    symbol: 'ICON.JK',
  },
  {
    value: 'IDPR.JK',
    label: 'IDPR.JK,  PT Indonesia Pondasi Raya Tbk',
    name: 'PT Indonesia Pondasi Raya Tbk',
    symbol: 'IDPR.JK',
  },
  {
    value: 'IFII.JK',
    label: 'IFII.JK,  PT Indonesia Fibreboard Industry Tbk',
    name: 'PT Indonesia Fibreboard Industry Tbk',
    symbol: 'IFII.JK',
  },
  {
    value: 'IFSH.JK',
    label: 'IFSH.JK,  PT Ifishdeco Tbk',
    name: 'PT Ifishdeco Tbk',
    symbol: 'IFSH.JK',
  },
  {
    value: 'IGAR.JK',
    label: 'IGAR.JK,  PT Champion Pacific Indonesia Tbk',
    name: 'PT Champion Pacific Indonesia Tbk',
    symbol: 'IGAR.JK',
  },
  {
    value: 'IKAI.JK',
    label: 'IKAI.JK,  PT Intikeramik Alamasri Industri Tbk',
    name: 'PT Intikeramik Alamasri Industri Tbk',
    symbol: 'IKAI.JK',
  },
  {
    value: 'IKAN.JK',
    label: 'IKAN.JK,  PT Era Mandiri Cemerlang Tbk',
    name: 'PT Era Mandiri Cemerlang Tbk',
    symbol: 'IKAN.JK',
  },
  {
    value: 'IKBI.JK',
    label: 'IKBI.JK,  PT Sumi Indo Kabel Tbk',
    name: 'PT Sumi Indo Kabel Tbk',
    symbol: 'IKBI.JK',
  },
  {
    value: 'IMJS.JK',
    label: 'IMJS.JK,  PT Indomobil Multi Jasa Tbk',
    name: 'PT Indomobil Multi Jasa Tbk',
    symbol: 'IMJS.JK',
  },
  {
    value: 'IMPC.JK',
    label: 'IMPC.JK,  PT Impack Pratama Industri Tbk',
    name: 'PT Impack Pratama Industri Tbk',
    symbol: 'IMPC.JK',
  },
  {
    value: 'INAF.JK',
    label: 'INAF.JK,  PT Indofarma Tbk',
    name: 'PT Indofarma Tbk',
    symbol: 'INAF.JK',
  },
  {
    value: 'INAI.JK',
    label: 'INAI.JK,  PT Indal Aluminium Industry Tbk',
    name: 'PT Indal Aluminium Industry Tbk',
    symbol: 'INAI.JK',
  },
  {
    value: 'INCF.JK',
    label: 'INCF.JK,  PT Indo Komoditi Korpora Tbk',
    name: 'PT Indo Komoditi Korpora Tbk',
    symbol: 'INCF.JK',
  },
  {
    value: 'INCI.JK',
    label: 'INCI.JK,  PT Intanwijaya Internasional Tbk',
    name: 'PT Intanwijaya Internasional Tbk',
    symbol: 'INCI.JK',
  },
  {
    value: 'INCO.JK',
    label: 'INCO.JK,  PT Vale Indonesia Tbk',
    name: 'PT Vale Indonesia Tbk',
    symbol: 'INCO.JK',
  },
  {
    value: 'INDO.JK',
    label: 'INDO.JK,  PT Royalindo Investa Wijaya Tbk',
    name: 'PT Royalindo Investa Wijaya Tbk',
    symbol: 'INDO.JK',
  },
  {
    value: 'INDR.JK',
    label: 'INDR.JK,  PT. Indo-Rama Synthetics Tbk',
    name: 'PT. Indo-Rama Synthetics Tbk',
    symbol: 'INDR.JK',
  },
  {
    value: 'INDS.JK',
    label: 'INDS.JK,  PT Indospring Tbk',
    name: 'PT Indospring Tbk',
    symbol: 'INDS.JK',
  },
  {
    value: 'INDX.JK',
    label: 'INDX.JK,  PT Tanah Laut Tbk',
    name: 'PT Tanah Laut Tbk',
    symbol: 'INDX.JK',
  },
  {
    value: 'INOV.JK',
    label: 'INOV.JK,  PT Inocycle Technology Group Tbk',
    name: 'PT Inocycle Technology Group Tbk',
    symbol: 'INOV.JK',
  },
  {
    value: 'INPC.JK',
    label: 'INPC.JK,  PT Bank Artha Graha Internasional Tbk',
    name: 'PT Bank Artha Graha Internasional Tbk',
    symbol: 'INPC.JK',
  },
  {
    value: 'INPP.JK',
    label: 'INPP.JK,  PT Indonesian Paradise Property Tbk',
    name: 'PT Indonesian Paradise Property Tbk',
    symbol: 'INPP.JK',
  },
  {
    value: 'INPS.JK',
    label: 'INPS.JK,  PT Indah Prakasa Sentosa Tbk',
    name: 'PT Indah Prakasa Sentosa Tbk',
    symbol: 'INPS.JK',
  },
  {
    value: 'INRU.JK',
    label: 'INRU.JK,  PT Toba Pulp Lestari Tbk',
    name: 'PT Toba Pulp Lestari Tbk',
    symbol: 'INRU.JK',
  },
  {
    value: 'INTD.JK',
    label: 'INTD.JK,  PT Inter Delta Tbk',
    name: 'PT Inter Delta Tbk',
    symbol: 'INTD.JK',
  },
  {
    value: 'IPCC.JK',
    label: 'IPCC.JK,  PT Indonesia Kendaraan Terminal Tbk',
    name: 'PT Indonesia Kendaraan Terminal Tbk',
    symbol: 'IPCC.JK',
  },
  {
    value: 'IPCM.JK',
    label: 'IPCM.JK,  PT Jasa Armada Indonesia Tbk',
    name: 'PT Jasa Armada Indonesia Tbk',
    symbol: 'IPCM.JK',
  },
  {
    value: 'IPOL.JK',
    label: 'IPOL.JK,  PT Indopoly Swakarsa Industry Tbk',
    name: 'PT Indopoly Swakarsa Industry Tbk',
    symbol: 'IPOL.JK',
  },
  {
    value: 'IRRA.JK',
    label: 'IRRA.JK,  PT Itama Ranoraya Tbk',
    name: 'PT Itama Ranoraya Tbk',
    symbol: 'IRRA.JK',
  },
  {
    value: 'ISSP.JK',
    label: 'ISSP.JK,  PT Steel Pipe Industry of Indonesia Tbk',
    name: 'PT Steel Pipe Industry of Indonesia Tbk',
    symbol: 'ISSP.JK',
  },
  {
    value: 'ITIC.JK',
    label: 'ITIC.JK,  PT Indonesian Tobacco Tbk',
    name: 'PT Indonesian Tobacco Tbk',
    symbol: 'ITIC.JK',
  },
  {
    value: 'ITMA.JK',
    label: 'ITMA.JK,  PT Sumber Energi Andalan Tbk',
    name: 'PT Sumber Energi Andalan Tbk',
    symbol: 'ITMA.JK',
  },
  {
    value: 'JAWA.JK',
    label: 'JAWA.JK,  PT Jaya Agra Wattie Tbk',
    name: 'PT Jaya Agra Wattie Tbk',
    symbol: 'JAWA.JK',
  },
  {
    value: 'JAYA.JK',
    label: 'JAYA.JK,  PT Armada Berjaya Trans Tbk',
    name: 'PT Armada Berjaya Trans Tbk',
    symbol: 'JAYA.JK',
  },
  {
    value: 'JECC.JK',
    label: 'JECC.JK,  PT Jembo Cable Company Tbk',
    name: 'PT Jembo Cable Company Tbk',
    symbol: 'JECC.JK',
  },
  {
    value: 'JGLE.JK',
    label: 'JGLE.JK,  PT Graha Andrasentra Propertindo Tbk.',
    name: 'PT Graha Andrasentra Propertindo Tbk.',
    symbol: 'JGLE.JK',
  },
  {
    value: 'JIHD.JK',
    label: 'JIHD.JK,  PT Jakarta International Hotels & Development Tbk',
    name: 'PT Jakarta International Hotels & Development Tbk',
    symbol: 'JIHD.JK',
  },
  {
    value: 'JKON.JK',
    label: 'JKON.JK,  PT Jaya Konstruksi Manggala Pratama Tbk',
    name: 'PT Jaya Konstruksi Manggala Pratama Tbk',
    symbol: 'JKON.JK',
  },
  {
    value: 'JMAS.JK',
    label: 'JMAS.JK,  PT Asuransi Jiwa Syariah Jasa Mitra Abadi Tbk',
    name: 'PT Asuransi Jiwa Syariah Jasa Mitra Abadi Tbk',
    symbol: 'JMAS.JK',
  },
  {
    value: 'JRPT.JK',
    label: 'JRPT.JK,  PT Jaya Real Property, Tbk.',
    name: 'PT Jaya Real Property, Tbk.',
    symbol: 'JRPT.JK',
  },
  {
    value: 'JSPT.JK',
    label: 'JSPT.JK,  PT Jakarta Setiabudi Internasional Tbk',
    name: 'PT Jakarta Setiabudi Internasional Tbk',
    symbol: 'JSPT.JK',
  },
  {
    value: 'JTPE.JK',
    label: 'JTPE.JK,  PT Jasuindo Tiga Perkasa Tbk',
    name: 'PT Jasuindo Tiga Perkasa Tbk',
    symbol: 'JTPE.JK',
  },
  {
    value: 'KARW.JK',
    label: 'KARW.JK,  PT ICTSI Jasa Prima Tbk',
    name: 'PT ICTSI Jasa Prima Tbk',
    symbol: 'KARW.JK',
  },
  {
    value: 'KAYU.JK',
    label: 'KAYU.JK,  PT Darmi Bersaudara Tbk',
    name: 'PT Darmi Bersaudara Tbk',
    symbol: 'KAYU.JK',
  },
  {
    value: 'KBAG.JK',
    label: 'KBAG.JK,  PT Karya Bersama Anugerah Tbk',
    name: 'PT Karya Bersama Anugerah Tbk',
    symbol: 'KBAG.JK',
  },
  {
    value: 'KBLI.JK',
    label: 'KBLI.JK,  PT KMI Wire and Cable Tbk',
    name: 'PT KMI Wire and Cable Tbk',
    symbol: 'KBLI.JK',
  },
  {
    value: 'KBLM.JK',
    label: 'KBLM.JK,  PT Kabelindo Murni Tbk',
    name: 'PT Kabelindo Murni Tbk',
    symbol: 'KBLM.JK',
  },
  {
    value: 'KBLV.JK',
    label: 'KBLV.JK,  PT First Media Tbk',
    name: 'PT First Media Tbk',
    symbol: 'KBLV.JK',
  },
  {
    value: 'KDSI.JK',
    label: 'KDSI.JK,  PT Kedawung Setia Industrial Tbk',
    name: 'PT Kedawung Setia Industrial Tbk',
    symbol: 'KDSI.JK',
  },
  {
    value: 'KEEN.JK',
    label: 'KEEN.JK,  PT Kencana Energi Lestari Tbk',
    name: 'PT Kencana Energi Lestari Tbk',
    symbol: 'KEEN.JK',
  },
  {
    value: 'KEJU.JK',
    label: 'KEJU.JK,  PT Mulia Boga Raya Tbk',
    name: 'PT Mulia Boga Raya Tbk',
    symbol: 'KEJU.JK',
  },
  {
    value: 'KIAS.JK',
    label: 'KIAS.JK,  PT Keramika Indonesia Assosiasi Tbk',
    name: 'PT Keramika Indonesia Assosiasi Tbk',
    symbol: 'KIAS.JK',
  },
  {
    value: 'KICI.JK',
    label: 'KICI.JK,  PT Kedaung Indah Can Tbk',
    name: 'PT Kedaung Indah Can Tbk',
    symbol: 'KICI.JK',
  },
  {
    value: 'KINO.JK',
    label: 'KINO.JK,  PT Kino Indonesia Tbk',
    name: 'PT Kino Indonesia Tbk',
    symbol: 'KINO.JK',
  },
  {
    value: 'KIOS.JK',
    label: 'KIOS.JK,  PT Kioson Komersial Indonesia Tbk',
    name: 'PT Kioson Komersial Indonesia Tbk',
    symbol: 'KIOS.JK',
  },
  {
    value: 'KJEN.JK',
    label: 'KJEN.JK,  PT Krida Jaringan Nusantara Tbk',
    name: 'PT Krida Jaringan Nusantara Tbk',
    symbol: 'KJEN.JK',
  },
  {
    value: 'KKGI.JK',
    label: 'KKGI.JK,  PT Resource Alam Indonesia Tbk',
    name: 'PT Resource Alam Indonesia Tbk',
    symbol: 'KKGI.JK',
  },
  {
    value: 'KMDS.JK',
    label: 'KMDS.JK,  PT Kurniamitra Duta Sentosa, Tbk',
    name: 'PT Kurniamitra Duta Sentosa, Tbk',
    symbol: 'KMDS.JK',
  },
  {
    value: 'KMTR.JK',
    label: 'KMTR.JK,  PT Kirana Megatara Tbk',
    name: 'PT Kirana Megatara Tbk',
    symbol: 'KMTR.JK',
  },
  {
    value: 'KOBX.JK',
    label: 'KOBX.JK,  PT Kobexindo Tractors Tbk',
    name: 'PT Kobexindo Tractors Tbk',
    symbol: 'KOBX.JK',
  },
  {
    value: 'KOIN.JK',
    label: 'KOIN.JK,  PT Kokoh Inti Arebama Tbk',
    name: 'PT Kokoh Inti Arebama Tbk',
    symbol: 'KOIN.JK',
  },
  {
    value: 'KONI.JK',
    label: 'KONI.JK,  PT Perdana Bangun Pusaka Tbk',
    name: 'PT Perdana Bangun Pusaka Tbk',
    symbol: 'KONI.JK',
  },
  {
    value: 'KOPI.JK',
    label: 'KOPI.JK,  PT Mitra Energi Persada Tbk',
    name: 'PT Mitra Energi Persada Tbk',
    symbol: 'KOPI.JK',
  },
  {
    value: 'KOTA.JK',
    label: 'KOTA.JK,  PT DMS Propertindo Tbk',
    name: 'PT DMS Propertindo Tbk',
    symbol: 'KOTA.JK',
  },
  {
    value: 'KPIG.JK',
    label: 'KPIG.JK,  PT MNC Land Tbk',
    name: 'PT MNC Land Tbk',
    symbol: 'KPIG.JK',
  },
  {
    value: 'KREN.JK',
    label: 'KREN.JK,  PT Kresna Graha Investama Tbk',
    name: 'PT Kresna Graha Investama Tbk',
    symbol: 'KREN.JK',
  },
  {
    value: 'LAND.JK',
    label: 'LAND.JK,  PT Trimitra Propertindo Tbk',
    name: 'PT Trimitra Propertindo Tbk',
    symbol: 'LAND.JK',
  },
  {
    value: 'LCKM.JK',
    label: 'LCKM.JK,  PT LCK Global Kedaton Tbk',
    name: 'PT LCK Global Kedaton Tbk',
    symbol: 'LCKM.JK',
  },
  {
    value: 'LEAD.JK',
    label: 'LEAD.JK,  PT Logindo Samudramakmur Tbk.',
    name: 'PT Logindo Samudramakmur Tbk.',
    symbol: 'LEAD.JK',
  },
  {
    value: 'LIFE.JK',
    label: 'LIFE.JK,  PT Asuransi Jiwa Sinarmas MSIG Tbk',
    name: 'PT Asuransi Jiwa Sinarmas MSIG Tbk',
    symbol: 'LIFE.JK',
  },
  {
    value: 'LION.JK',
    label: 'LION.JK,  PT Lion Metal Works Tbk',
    name: 'PT Lion Metal Works Tbk',
    symbol: 'LION.JK',
  },
  {
    value: 'LMPI.JK',
    label: 'LMPI.JK,  PT Langgeng Makmur Industri Tbk',
    name: 'PT Langgeng Makmur Industri Tbk',
    symbol: 'LMPI.JK',
  },
  {
    value: 'LMSH.JK',
    label: 'LMSH.JK,  PT Lionmesh Prima Tbk',
    name: 'PT Lionmesh Prima Tbk',
    symbol: 'LMSH.JK',
  },
  {
    value: 'LPGI.JK',
    label: 'LPGI.JK,  PT Lippo General Insurance Tbk',
    name: 'PT Lippo General Insurance Tbk',
    symbol: 'LPGI.JK',
  },
  {
    value: 'LPIN.JK',
    label: 'LPIN.JK,  PT Multi Prima Sejahtera Tbk',
    name: 'PT Multi Prima Sejahtera Tbk',
    symbol: 'LPIN.JK',
  },
  {
    value: 'LPLI.JK',
    label: 'LPLI.JK,  PT Star Pacific Tbk',
    name: 'PT Star Pacific Tbk',
    symbol: 'LPLI.JK',
  },
  {
    value: 'LPPS.JK',
    label: 'LPPS.JK,  PT Lenox Pasifik Investama Tbk',
    name: 'PT Lenox Pasifik Investama Tbk',
    symbol: 'LPPS.JK',
  },
  {
    value: 'LRNA.JK',
    label: 'LRNA.JK,  PT Eka Sari Lorena Transport Tbk',
    name: 'PT Eka Sari Lorena Transport Tbk',
    symbol: 'LRNA.JK',
  },
  {
    value: 'LTLS.JK',
    label: 'LTLS.JK,  PT Lautan Luas Tbk',
    name: 'PT Lautan Luas Tbk',
    symbol: 'LTLS.JK',
  },
  {
    value: 'LUCK.JK',
    label: 'LUCK.JK,  PT Sentral Mitra Informatika Tbk',
    name: 'PT Sentral Mitra Informatika Tbk',
    symbol: 'LUCK.JK',
  },
  {
    value: 'MAIN.JK',
    label: 'MAIN.JK,  PT Malindo Feedmill Tbk',
    name: 'PT Malindo Feedmill Tbk',
    symbol: 'MAIN.JK',
  },
  {
    value: 'MAPA.JK',
    label: 'MAPA.JK,  PT Map Aktif Adiperkasa Tbk',
    name: 'PT Map Aktif Adiperkasa Tbk',
    symbol: 'MAPA.JK',
  },
  {
    value: 'MAPB.JK',
    label: 'MAPB.JK,  PT. Map Boga Adiperkasa Tbk',
    name: 'PT. Map Boga Adiperkasa Tbk',
    symbol: 'MAPB.JK',
  },
  {
    value: 'MARI.JK',
    label: 'MARI.JK,  PT Mahaka Radio Integra Tbk',
    name: 'PT Mahaka Radio Integra Tbk',
    symbol: 'MARI.JK',
  },
  {
    value: 'MARK.JK',
    label: 'MARK.JK,  PT Mark Dynamics Indonesia Tbk',
    name: 'PT Mark Dynamics Indonesia Tbk',
    symbol: 'MARK.JK',
  },
  {
    value: 'MASA.JK',
    label: 'MASA.JK,  PT Multistrada Arah Sarana Tbk',
    name: 'PT Multistrada Arah Sarana Tbk',
    symbol: 'MASA.JK',
  },
  {
    value: 'MAYA.JK',
    label: 'MAYA.JK,  PT Bank Mayapada Internasional Tbk',
    name: 'PT Bank Mayapada Internasional Tbk',
    symbol: 'MAYA.JK',
  },
  {
    value: 'MBAP.JK',
    label: 'MBAP.JK,  PT Mitrabara Adiperdana Tbk',
    name: 'PT Mitrabara Adiperdana Tbk',
    symbol: 'MBAP.JK',
  },
  {
    value: 'MBSS.JK',
    label: 'MBSS.JK,  PT Mitrabahtera Segara Sejati Tbk',
    name: 'PT Mitrabahtera Segara Sejati Tbk',
    symbol: 'MBSS.JK',
  },
  {
    value: 'MBTO.JK',
    label: 'MBTO.JK,  PT Martina Berto Tbk',
    name: 'PT Martina Berto Tbk',
    symbol: 'MBTO.JK',
  },
  {
    value: 'MCAS.JK',
    label: 'MCAS.JK,  PT M Cash Integrasi Tbk',
    name: 'PT M Cash Integrasi Tbk',
    symbol: 'MCAS.JK',
  },
  {
    value: 'MCOR.JK',
    label: 'MCOR.JK,  PT Bank China Construction Bank Indonesia Tbk',
    name: 'PT Bank China Construction Bank Indonesia Tbk',
    symbol: 'MCOR.JK',
  },
  {
    value: 'MDIA.JK',
    label: 'MDIA.JK,  PT Intermedia Capital Tbk',
    name: 'PT Intermedia Capital Tbk',
    symbol: 'MDIA.JK',
  },
  {
    value: 'MDKI.JK',
    label: 'MDKI.JK,  PT Emdeki Utama Tbk',
    name: 'PT Emdeki Utama Tbk',
    symbol: 'MDKI.JK',
  },
  {
    value: 'MDRN.JK',
    label: 'MDRN.JK,  PT Modern Internasional Tbk',
    name: 'PT Modern Internasional Tbk',
    symbol: 'MDRN.JK',
  },
  {
    value: 'MEGA.JK',
    label: 'MEGA.JK,  PT Bank Mega Tbk',
    name: 'PT Bank Mega Tbk',
    symbol: 'MEGA.JK',
  },
  {
    value: 'MERK.JK',
    label: 'MERK.JK,  PT Merck Tbk',
    name: 'PT Merck Tbk',
    symbol: 'MERK.JK',
  },
  {
    value: 'META.JK',
    label: 'META.JK,  PT Nusantara Infrastructure Tbk',
    name: 'PT Nusantara Infrastructure Tbk',
    symbol: 'META.JK',
  },
  {
    value: 'MFIN.JK',
    label: 'MFIN.JK,  PT Mandala Multifinance Tbk',
    name: 'PT Mandala Multifinance Tbk',
    symbol: 'MFIN.JK',
  },
  {
    value: 'MFMI.JK',
    label: 'MFMI.JK,  PT Multifiling Mitra Indonesia Tbk',
    name: 'PT Multifiling Mitra Indonesia Tbk',
    symbol: 'MFMI.JK',
  },
  {
    value: 'MICE.JK',
    label: 'MICE.JK,  PT Multi Indocitra Tbk',
    name: 'PT Multi Indocitra Tbk',
    symbol: 'MICE.JK',
  },
  {
    value: 'MIDI.JK',
    label: 'MIDI.JK,  PT Midi Utama Indonesia Tbk',
    name: 'PT Midi Utama Indonesia Tbk',
    symbol: 'MIDI.JK',
  },
  {
    value: 'MIRA.JK',
    label: 'MIRA.JK,  PT Mitra International Resources Tbk',
    name: 'PT Mitra International Resources Tbk',
    symbol: 'MIRA.JK',
  },
  {
    value: 'MKNT.JK',
    label: 'MKNT.JK,  PT Mitra Komunikasi Nusantara Tbk',
    name: 'PT Mitra Komunikasi Nusantara Tbk',
    symbol: 'MKNT.JK',
  },
  {
    value: 'MKPI.JK',
    label: 'MKPI.JK,  PT Metropolitan Kentjana Tbk',
    name: 'PT Metropolitan Kentjana Tbk',
    symbol: 'MKPI.JK',
  },
  {
    value: 'MLBI.JK',
    label: 'MLBI.JK,  PT Multi Bintang Indonesia Tbk',
    name: 'PT Multi Bintang Indonesia Tbk',
    symbol: 'MLBI.JK',
  },
  {
    value: 'MLIA.JK',
    label: 'MLIA.JK,  PT Mulia Industrindo Tbk',
    name: 'PT Mulia Industrindo Tbk',
    symbol: 'MLIA.JK',
  },
  {
    value: 'MLPT.JK',
    label: 'MLPT.JK,  PT Multipolar Technology Tbk',
    name: 'PT Multipolar Technology Tbk',
    symbol: 'MLPT.JK',
  },
  {
    value: 'MMLP.JK',
    label: 'MMLP.JK,  PT Mega Manunggal Property Tbk',
    name: 'PT Mega Manunggal Property Tbk',
    symbol: 'MMLP.JK',
  },
  {
    value: 'MOLI.JK',
    label: 'MOLI.JK,  PT Madusari Murni Indah Tbk',
    name: 'PT Madusari Murni Indah Tbk',
    symbol: 'MOLI.JK',
  },
  {
    value: 'MPOW.JK',
    label: 'MPOW.JK,  PT Megapower Makmur Tbk',
    name: 'PT Megapower Makmur Tbk',
    symbol: 'MPOW.JK',
  },
  {
    value: 'MPRO.JK',
    label: 'MPRO.JK,  PT Maha Properti Indonesia Tbk',
    name: 'PT Maha Properti Indonesia Tbk',
    symbol: 'MPRO.JK',
  },
  {
    value: 'MRAT.JK',
    label: 'MRAT.JK,  PT Mustika Ratu Tbk',
    name: 'PT Mustika Ratu Tbk',
    symbol: 'MRAT.JK',
  },
  {
    value: 'MREI.JK',
    label: 'MREI.JK,  PT Maskapai Reasuransi Indonesia Tbk',
    name: 'PT Maskapai Reasuransi Indonesia Tbk',
    symbol: 'MREI.JK',
  },
  {
    value: 'MSIN.JK',
    label: 'MSIN.JK,  PT. MNC Studios International Tbk.',
    name: 'PT. MNC Studios International Tbk.',
    symbol: 'MSIN.JK',
  },
  {
    value: 'MSKY.JK',
    label: 'MSKY.JK,  PT MNC Sky Vision Tbk',
    name: 'PT MNC Sky Vision Tbk',
    symbol: 'MSKY.JK',
  },
  {
    value: 'MTDL.JK',
    label: 'MTDL.JK,  PT Metrodata Electronics Tbk',
    name: 'PT Metrodata Electronics Tbk',
    symbol: 'MTDL.JK',
  },
  {
    value: 'MTLA.JK',
    label: 'MTLA.JK,  PT Metropolitan Land Tbk',
    name: 'PT Metropolitan Land Tbk',
    symbol: 'MTLA.JK',
  },
  {
    value: 'MTPS.JK',
    label: 'MTPS.JK,  PT Meta Epsi Tbk.',
    name: 'PT Meta Epsi Tbk.',
    symbol: 'MTPS.JK',
  },
  {
    value: 'MTSM.JK',
    label: 'MTSM.JK,  PT Metro Realty Tbk',
    name: 'PT Metro Realty Tbk',
    symbol: 'MTSM.JK',
  },
  {
    value: 'MTWI.JK',
    label: 'MTWI.JK,  PT Malacca Trust Wuwungan Insurance Tbk',
    name: 'PT Malacca Trust Wuwungan Insurance Tbk',
    symbol: 'MTWI.JK',
  },
  {
    value: 'MYOH.JK',
    label: 'MYOH.JK,  PT Samindo Resources Tbk',
    name: 'PT Samindo Resources Tbk',
    symbol: 'MYOH.JK',
  },
  {
    value: 'MYTX.JK',
    label: 'MYTX.JK,  PT Asia Pacific Investama Tbk',
    name: 'PT Asia Pacific Investama Tbk',
    symbol: 'MYTX.JK',
  },
  {
    value: 'NASA.JK',
    label: 'NASA.JK,  PT Andalan Perkasa Abadi Tbk',
    name: 'PT Andalan Perkasa Abadi Tbk',
    symbol: 'NASA.JK',
  },
  {
    value: 'NATO.JK',
    label: 'NATO.JK,  PT Surya Permata Andalan Tbk',
    name: 'PT Surya Permata Andalan Tbk',
    symbol: 'NATO.JK',
  },
  {
    value: 'NELY.JK',
    label: 'NELY.JK,  PT Pelayaran Nelly Dwi Putri Tbk',
    name: 'PT Pelayaran Nelly Dwi Putri Tbk',
    symbol: 'NELY.JK',
  },
  {
    value: 'NICK.JK',
    label: 'NICK.JK,  PT Charnic Capital Tbk',
    name: 'PT Charnic Capital Tbk',
    symbol: 'NICK.JK',
  },
  {
    value: 'NIKL.JK',
    label: 'NIKL.JK,  PT Pelat Timah Nusantara Tbk',
    name: 'PT Pelat Timah Nusantara Tbk',
    symbol: 'NIKL.JK',
  },
  {
    value: 'NIRO.JK',
    label: 'NIRO.JK,  PT City Retail Developments Tbk',
    name: 'PT City Retail Developments Tbk',
    symbol: 'NIRO.JK',
  },
  {
    value: 'NISP.JK',
    label: 'NISP.JK,  PT Bank OCBC NISP Tbk',
    name: 'PT Bank OCBC NISP Tbk',
    symbol: 'NISP.JK',
  },
  {
    value: 'NOBU.JK',
    label: 'NOBU.JK,  PT Bank Nationalnobu Tbk',
    name: 'PT Bank Nationalnobu Tbk',
    symbol: 'NOBU.JK',
  },
  {
    value: 'NRCA.JK',
    label: 'NRCA.JK,  PT Nusa Raya Cipta Tbk',
    name: 'PT Nusa Raya Cipta Tbk',
    symbol: 'NRCA.JK',
  },
  {
    value: 'NTBK.JK',
    label: 'NTBK.JK,  PT Nusatama Berkah Tbk',
    name: 'PT Nusatama Berkah Tbk',
    symbol: 'NTBK.JK',
  },
  {
    value: 'NZIA.JK',
    label: 'NZIA.JK,  PT Nusantara Almazia Tbk',
    name: 'PT Nusantara Almazia Tbk',
    symbol: 'NZIA.JK',
  },
  {
    value: 'OASA.JK',
    label: 'OASA.JK,  PT Maharaksa Biru Energi Tbk',
    name: 'PT Maharaksa Biru Energi Tbk',
    symbol: 'OASA.JK',
  },
  {
    value: 'OKAS.JK',
    label: 'OKAS.JK,  PT Ancora Indonesia Resources Tbk',
    name: 'PT Ancora Indonesia Resources Tbk',
    symbol: 'OKAS.JK',
  },
  {
    value: 'OMRE.JK',
    label: 'OMRE.JK,  PT Indonesia Prima Property Tbk',
    name: 'PT Indonesia Prima Property Tbk',
    symbol: 'OMRE.JK',
  },
  {
    value: 'OPMS.JK',
    label: 'OPMS.JK,  PT Optima Prima Metal Sinergi Tbk',
    name: 'PT Optima Prima Metal Sinergi Tbk',
    symbol: 'OPMS.JK',
  },
  {
    value: 'PADI.JK',
    label: 'PADI.JK,  PT Minna Padi Investama Sekuritas Tbk',
    name: 'PT Minna Padi Investama Sekuritas Tbk',
    symbol: 'PADI.JK',
  },
  {
    value: 'PALM.JK',
    label: 'PALM.JK,  PT Provident Investasi Bersama Tbk',
    name: 'PT Provident Investasi Bersama Tbk',
    symbol: 'PALM.JK',
  },
  {
    value: 'PAMG.JK',
    label: 'PAMG.JK,  PT Bima Sakti Pertiwi Tbk',
    name: 'PT Bima Sakti Pertiwi Tbk',
    symbol: 'PAMG.JK',
  },
  {
    value: 'PANI.JK',
    label: 'PANI.JK,  PT Pratama Abadi Nusa Industri Tbk',
    name: 'PT Pratama Abadi Nusa Industri Tbk',
    symbol: 'PANI.JK',
  },
  {
    value: 'PANR.JK',
    label: 'PANR.JK,  PT Panorama Sentrawisata Tbk',
    name: 'PT Panorama Sentrawisata Tbk',
    symbol: 'PANR.JK',
  },
  {
    value: 'PANS.JK',
    label: 'PANS.JK,  PT Panin Sekuritas Tbk',
    name: 'PT Panin Sekuritas Tbk',
    symbol: 'PANS.JK',
  },
  {
    value: 'PBID.JK',
    label: 'PBID.JK,  PT Panca Budi Idaman Tbk',
    name: 'PT Panca Budi Idaman Tbk',
    symbol: 'PBID.JK',
  },
  {
    value: 'PBRX.JK',
    label: 'PBRX.JK,  PT Pan Brothers Tbk',
    name: 'PT Pan Brothers Tbk',
    symbol: 'PBRX.JK',
  },
  {
    value: 'PBSA.JK',
    label: 'PBSA.JK,  PT Paramita Bangun Sarana Tbk',
    name: 'PT Paramita Bangun Sarana Tbk',
    symbol: 'PBSA.JK',
  },
  {
    value: 'PCAR.JK',
    label: 'PCAR.JK,  PT Prima Cakrawala Abadi Tbk',
    name: 'PT Prima Cakrawala Abadi Tbk',
    symbol: 'PCAR.JK',
  },
  {
    value: 'PDES.JK',
    label: 'PDES.JK,  PT Destinasi Tirta Nusantara Tbk',
    name: 'PT Destinasi Tirta Nusantara Tbk',
    symbol: 'PDES.JK',
  },
  {
    value: 'PEGE.JK',
    label: 'PEGE.JK,  PT Panca Global Kapital Tbk',
    name: 'PT Panca Global Kapital Tbk',
    symbol: 'PEGE.JK',
  },
  {
    value: 'PEHA.JK',
    label: 'PEHA.JK,  PT Phapros, Tbk',
    name: 'PT Phapros, Tbk',
    symbol: 'PEHA.JK',
  },
  {
    value: 'PGJO.JK',
    label: 'PGJO.JK,  PT Tourindo Guide Indonesia Tbk',
    name: 'PT Tourindo Guide Indonesia Tbk',
    symbol: 'PGJO.JK',
  },
  {
    value: 'PGLI.JK',
    label: 'PGLI.JK,  PT Pembangunan Graha Lestari Indah Tbk',
    name: 'PT Pembangunan Graha Lestari Indah Tbk',
    symbol: 'PGLI.JK',
  },
  {
    value: 'PGUN.JK',
    label: 'PGUN.JK,  PT Pradiksi Gunatama Tbk',
    name: 'PT Pradiksi Gunatama Tbk',
    symbol: 'PGUN.JK',
  },
  {
    value: 'PICO.JK',
    label: 'PICO.JK,  PT Pelangi Indah Canindo Tbk',
    name: 'PT Pelangi Indah Canindo Tbk',
    symbol: 'PICO.JK',
  },
  {
    value: 'PJAA.JK',
    label: 'PJAA.JK,  PT Pembangunan Jaya Ancol Tbk',
    name: 'PT Pembangunan Jaya Ancol Tbk',
    symbol: 'PJAA.JK',
  },
  {
    value: 'PKPK.JK',
    label: 'PKPK.JK,  PT Perdana Karya Perkasa Tbk',
    name: 'PT Perdana Karya Perkasa Tbk',
    symbol: 'PKPK.JK',
  },
  {
    value: 'PLAN.JK',
    label: 'PLAN.JK,  PT Planet Properindo Jaya Tbk',
    name: 'PT Planet Properindo Jaya Tbk',
    symbol: 'PLAN.JK',
  },
  {
    value: 'PMJS.JK',
    label: 'PMJS.JK,  PT Putra Mandiri Jembar Tbk',
    name: 'PT Putra Mandiri Jembar Tbk',
    symbol: 'PMJS.JK',
  },
  {
    value: 'PMMP.JK',
    label: 'PMMP.JK,  PT Panca Mitra Multiperdana Tbk',
    name: 'PT Panca Mitra Multiperdana Tbk',
    symbol: 'PMMP.JK',
  },
  {
    value: 'PNBS.JK',
    label: 'PNBS.JK,  PT Bank Panin Dubai Syariah Tbk',
    name: 'PT Bank Panin Dubai Syariah Tbk',
    symbol: 'PNBS.JK',
  },
  {
    value: 'PNGO.JK',
    label: 'PNGO.JK,  PT PINAGO UTAMA Tbk',
    name: 'PT PINAGO UTAMA Tbk',
    symbol: 'PNGO.JK',
  },
  {
    value: 'PNIN.JK',
    label: 'PNIN.JK,  PT Paninvest Tbk',
    name: 'PT Paninvest Tbk',
    symbol: 'PNIN.JK',
  },
  {
    value: 'PNSE.JK',
    label: 'PNSE.JK,  PT Pudjiadi and Sons Tbk',
    name: 'PT Pudjiadi and Sons Tbk',
    symbol: 'PNSE.JK',
  },
  {
    value: 'POLA.JK',
    label: 'POLA.JK,  PT Pool Advista Finance Tbk',
    name: 'PT Pool Advista Finance Tbk',
    symbol: 'POLA.JK',
  },
  {
    value: 'POLI.JK',
    label: 'POLI.JK,  PT Pollux Hotels Group Tbk',
    name: 'PT Pollux Hotels Group Tbk',
    symbol: 'POLI.JK',
  },
  {
    value: 'POLU.JK',
    label: 'POLU.JK,  PT Golden Flower Tbk',
    name: 'PT Golden Flower Tbk',
    symbol: 'POLU.JK',
  },
  {
    value: 'POLY.JK',
    label: 'POLY.JK,  PT Asia Pacific Fibers Tbk',
    name: 'PT Asia Pacific Fibers Tbk',
    symbol: 'POLY.JK',
  },
  {
    value: 'PORT.JK',
    label: 'PORT.JK,  PT Nusantara Pelabuhan Handal Tbk',
    name: 'PT Nusantara Pelabuhan Handal Tbk',
    symbol: 'PORT.JK',
  },
  {
    value: 'POWR.JK',
    label: 'POWR.JK,  PT Cikarang Listrindo Tbk',
    name: 'PT Cikarang Listrindo Tbk',
    symbol: 'POWR.JK',
  },
  {
    value: 'PPGL.JK',
    label: 'PPGL.JK,  PT Prima Globalindo Logistik Tbk',
    name: 'PT Prima Globalindo Logistik Tbk',
    symbol: 'PPGL.JK',
  },
  {
    value: 'PPRE.JK',
    label: 'PPRE.JK,  PT PP Presisi Tbk',
    name: 'PT PP Presisi Tbk',
    symbol: 'PPRE.JK',
  },
  {
    value: 'PPRO.JK',
    label: 'PPRO.JK,  PT Pembangunan Perumahan Properti Tbk',
    name: 'PT Pembangunan Perumahan Properti Tbk',
    symbol: 'PPRO.JK',
  },
  {
    value: 'PRAS.JK',
    label: 'PRAS.JK,  PT. Prima Alloy Steel Universal Tbk.',
    name: 'PT. Prima Alloy Steel Universal Tbk.',
    symbol: 'PRAS.JK',
  },
  {
    value: 'PRDA.JK',
    label: 'PRDA.JK,  PT Prodia Widyahusada Tbk',
    name: 'PT Prodia Widyahusada Tbk',
    symbol: 'PRDA.JK',
  },
  {
    value: 'PRIM.JK',
    label: 'PRIM.JK,  PT Royal Prima Tbk',
    name: 'PT Royal Prima Tbk',
    symbol: 'PRIM.JK',
  },
  {
    value: 'PSAB.JK',
    label: 'PSAB.JK,  PT J Resources Asia Pasifik Tbk',
    name: 'PT J Resources Asia Pasifik Tbk',
    symbol: 'PSAB.JK',
  },
  {
    value: 'PSDN.JK',
    label: 'PSDN.JK,  PT Prasidha Aneka Niaga Tbk',
    name: 'PT Prasidha Aneka Niaga Tbk',
    symbol: 'PSDN.JK',
  },
  {
    value: 'PSGO.JK',
    label: 'PSGO.JK,  PT Palma Serasih Tbk',
    name: 'PT Palma Serasih Tbk',
    symbol: 'PSGO.JK',
  },
  {
    value: 'PSKT.JK',
    label: 'PSKT.JK,  PT Red Planet Indonesia Tbk',
    name: 'PT Red Planet Indonesia Tbk',
    symbol: 'PSKT.JK',
  },
  {
    value: 'PSSI.JK',
    label: 'PSSI.JK,  PT Pelita Samudera Shipping Tbk',
    name: 'PT Pelita Samudera Shipping Tbk',
    symbol: 'PSSI.JK',
  },
  {
    value: 'PTDU.JK',
    label: 'PTDU.JK,  PT Djasa Ubersakti Tbk',
    name: 'PT Djasa Ubersakti Tbk',
    symbol: 'PTDU.JK',
  },
  {
    value: 'PTIS.JK',
    label: 'PTIS.JK,  PT Indo Straits Tbk',
    name: 'PT Indo Straits Tbk',
    symbol: 'PTIS.JK',
  },
  {
    value: 'PTPW.JK',
    label: 'PTPW.JK,  PT Pratama Widya Tbk',
    name: 'PT Pratama Widya Tbk',
    symbol: 'PTPW.JK',
  },
  {
    value: 'PTRO.JK',
    label: 'PTRO.JK,  PT Petrosea Tbk',
    name: 'PT Petrosea Tbk',
    symbol: 'PTRO.JK',
  },
  {
    value: 'PTSN.JK',
    label: 'PTSN.JK,  PT Sat Nusapersada Tbk',
    name: 'PT Sat Nusapersada Tbk',
    symbol: 'PTSN.JK',
  },
  {
    value: 'PTSP.JK',
    label: 'PTSP.JK,  PT Pioneerindo Gourmet International Tbk',
    name: 'PT Pioneerindo Gourmet International Tbk',
    symbol: 'PTSP.JK',
  },
  {
    value: 'PUDP.JK',
    label: 'PUDP.JK,  PT Pudjiadi Prestige Tbk',
    name: 'PT Pudjiadi Prestige Tbk',
    symbol: 'PUDP.JK',
  },
  {
    value: 'PURA.JK',
    label: 'PURA.JK,  PT Putra Rajawali Kencana Tbk',
    name: 'PT Putra Rajawali Kencana Tbk',
    symbol: 'PURA.JK',
  },
  {
    value: 'PURI.JK',
    label: 'PURI.JK,  PT Puri Global Sukses Tbk',
    name: 'PT Puri Global Sukses Tbk',
    symbol: 'PURI.JK',
  },
  {
    value: 'PYFA.JK',
    label: 'PYFA.JK,  PT Pyridam Farma Tbk',
    name: 'PT Pyridam Farma Tbk',
    symbol: 'PYFA.JK',
  },
  {
    value: 'PZZA.JK',
    label: 'PZZA.JK,  PT Sarimelati Kencana Tbk.',
    name: 'PT Sarimelati Kencana Tbk.',
    symbol: 'PZZA.JK',
  },
  {
    value: 'RAJA.JK',
    label: 'RAJA.JK,  PT Rukun Raharja Tbk',
    name: 'PT Rukun Raharja Tbk',
    symbol: 'RAJA.JK',
  },
  {
    value: 'RANC.JK',
    label: 'RANC.JK,  PT Supra Boga Lestari Tbk',
    name: 'PT Supra Boga Lestari Tbk',
    symbol: 'RANC.JK',
  },
  {
    value: 'RBMS.JK',
    label: 'RBMS.JK,  PT Ristia Bintang Mahkotasejati Tbk',
    name: 'PT Ristia Bintang Mahkotasejati Tbk',
    symbol: 'RBMS.JK',
  },
  {
    value: 'RDTX.JK',
    label: 'RDTX.JK,  PT Roda Vivatex Tbk',
    name: 'PT Roda Vivatex Tbk',
    symbol: 'RDTX.JK',
  },
  {
    value: 'REAL.JK',
    label: 'REAL.JK,  PT Repower Asia Indonesia Tbk',
    name: 'PT Repower Asia Indonesia Tbk',
    symbol: 'REAL.JK',
  },
  {
    value: 'RELI.JK',
    label: 'RELI.JK,  PT Reliance Sekuritas Indonesia Tbk',
    name: 'PT Reliance Sekuritas Indonesia Tbk',
    symbol: 'RELI.JK',
  },
  {
    value: 'RICY.JK',
    label: 'RICY.JK,  PT Ricky Putra Globalindo Tbk',
    name: 'PT Ricky Putra Globalindo Tbk',
    symbol: 'RICY.JK',
  },
  {
    value: 'RIGS.JK',
    label: 'RIGS.JK,  PT Rig Tenders Indonesia Tbk',
    name: 'PT Rig Tenders Indonesia Tbk',
    symbol: 'RIGS.JK',
  },
  {
    value: 'RISE.JK',
    label: 'RISE.JK,  PT Jaya Sukses Makmur Sentosa Tbk',
    name: 'PT Jaya Sukses Makmur Sentosa Tbk',
    symbol: 'RISE.JK',
  },
  {
    value: 'ROCK.JK',
    label: 'ROCK.JK,  PT Rockfields Properti Indonesia Tbk.',
    name: 'PT Rockfields Properti Indonesia Tbk.',
    symbol: 'ROCK.JK',
  },
  {
    value: 'RODA.JK',
    label: 'RODA.JK,  PT Pikko Land Development Tbk',
    name: 'PT Pikko Land Development Tbk',
    symbol: 'RODA.JK',
  },
  {
    value: 'ROTI.JK',
    label: 'ROTI.JK,  PT Nippon Indosari Corpindo Tbk',
    name: 'PT Nippon Indosari Corpindo Tbk',
    symbol: 'ROTI.JK',
  },
  {
    value: 'RUIS.JK',
    label: 'RUIS.JK,  PT Radiant Utama Interinsco Tbk',
    name: 'PT Radiant Utama Interinsco Tbk',
    symbol: 'RUIS.JK',
  },
  {
    value: 'SAFE.JK',
    label: 'SAFE.JK,  PT Steady Safe Tbk',
    name: 'PT Steady Safe Tbk',
    symbol: 'SAFE.JK',
  },
  {
    value: 'SAME.JK',
    label: 'SAME.JK,  PT Sarana Meditama Metropolitan Tbk',
    name: 'PT Sarana Meditama Metropolitan Tbk',
    symbol: 'SAME.JK',
  },
  {
    value: 'SAMF.JK',
    label: 'SAMF.JK,  PT Saraswanti Anugerah Makmur, Tbk',
    name: 'PT Saraswanti Anugerah Makmur, Tbk',
    symbol: 'SAMF.JK',
  },
  {
    value: 'SAPX.JK',
    label: 'SAPX.JK,  PT Satria Antaran Prima Tbk',
    name: 'PT Satria Antaran Prima Tbk',
    symbol: 'SAPX.JK',
  },
  {
    value: 'SATU.JK',
    label: 'SATU.JK,  PT Kota Satu Properti Tbk',
    name: 'PT Kota Satu Properti Tbk',
    symbol: 'SATU.JK',
  },
  {
    value: 'SBAT.JK',
    label: 'SBAT.JK,  PT Sejahtera Bintang Abadi Textile Tbk',
    name: 'PT Sejahtera Bintang Abadi Textile Tbk',
    symbol: 'SBAT.JK',
  },
  {
    value: 'SCCO.JK',
    label: 'SCCO.JK,  PT Supreme Cable Manufacturing & Commerce Tbk',
    name: 'PT Supreme Cable Manufacturing & Commerce Tbk',
    symbol: 'SCCO.JK',
  },
  {
    value: 'SDMU.JK',
    label: 'SDMU.JK,  PT Sidomulyo Selaras Tbk',
    name: 'PT Sidomulyo Selaras Tbk',
    symbol: 'SDMU.JK',
  },
  {
    value: 'SDPC.JK',
    label: 'SDPC.JK,  PT Millennium Pharmacon International Tbk',
    name: 'PT Millennium Pharmacon International Tbk',
    symbol: 'SDPC.JK',
  },
  {
    value: 'SDRA.JK',
    label: 'SDRA.JK,  PT Bank Woori Saudara Indonesia 1906 Tbk',
    name: 'PT Bank Woori Saudara Indonesia 1906 Tbk',
    symbol: 'SDRA.JK',
  },
  {
    value: 'SFAN.JK',
    label: 'SFAN.JK,  PT Surya Fajar Capital Tbk',
    name: 'PT Surya Fajar Capital Tbk',
    symbol: 'SFAN.JK',
  },
  {
    value: 'SGER.JK',
    label: 'SGER.JK,  PT Sumber Global Energy Tbk',
    name: 'PT Sumber Global Energy Tbk',
    symbol: 'SGER.JK',
  },
  {
    value: 'SGRO.JK',
    label: 'SGRO.JK,  PT Sampoerna Agro Tbk',
    name: 'PT Sampoerna Agro Tbk',
    symbol: 'SGRO.JK',
  },
  {
    value: 'SHID.JK',
    label: 'SHID.JK,  PT Hotel Sahid Jaya International Tbk',
    name: 'PT Hotel Sahid Jaya International Tbk',
    symbol: 'SHID.JK',
  },
  {
    value: 'SHIP.JK',
    label: 'SHIP.JK,  PT Sillo Maritime Perdana Tbk',
    name: 'PT Sillo Maritime Perdana Tbk',
    symbol: 'SHIP.JK',
  },
  {
    value: 'SINI.JK',
    label: 'SINI.JK,  PT Singaraja Putra Tbk',
    name: 'PT Singaraja Putra Tbk',
    symbol: 'SINI.JK',
  },
  {
    value: 'SIPD.JK',
    label: 'SIPD.JK,  PT Sreeya Sewu Indonesia Tbk',
    name: 'PT Sreeya Sewu Indonesia Tbk',
    symbol: 'SIPD.JK',
  },
  {
    value: 'SKBM.JK',
    label: 'SKBM.JK,  PT Sekar Bumi Tbk',
    name: 'PT Sekar Bumi Tbk',
    symbol: 'SKBM.JK',
  },
  {
    value: 'SKLT.JK',
    label: 'SKLT.JK,  PT Sekar Laut Tbk',
    name: 'PT Sekar Laut Tbk',
    symbol: 'SKLT.JK',
  },
  {
    value: 'SKRN.JK',
    label: 'SKRN.JK,  PT Superkrane Mitra Utama Tbk',
    name: 'PT Superkrane Mitra Utama Tbk',
    symbol: 'SKRN.JK',
  },
  {
    value: 'SLIS.JK',
    label: 'SLIS.JK,  PT Gaya Abadi Sempurna Tbk',
    name: 'PT Gaya Abadi Sempurna Tbk',
    symbol: 'SLIS.JK',
  },
  {
    value: 'SMAR.JK',
    label: 'SMAR.JK,  PT Sinar Mas Agro Resources and Technology Tbk',
    name: 'PT Sinar Mas Agro Resources and Technology Tbk',
    symbol: 'SMAR.JK',
  },
  {
    value: 'SMBR.JK',
    label: 'SMBR.JK,  PT Semen Baturaja (Persero) Tbk',
    name: 'PT Semen Baturaja (Persero) Tbk',
    symbol: 'SMBR.JK',
  },
  {
    value: 'SMCB.JK',
    label: 'SMCB.JK,  PT Solusi Bangun Indonesia Tbk',
    name: 'PT Solusi Bangun Indonesia Tbk',
    symbol: 'SMCB.JK',
  },
  {
    value: 'SMDM.JK',
    label: 'SMDM.JK,  PT Suryamas Dutamakmur Tbk',
    name: 'PT Suryamas Dutamakmur Tbk',
    symbol: 'SMDM.JK',
  },
  {
    value: 'SMDR.JK',
    label: 'SMDR.JK,  PT Samudera Indonesia Tbk',
    name: 'PT Samudera Indonesia Tbk',
    symbol: 'SMDR.JK',
  },
  {
    value: 'SMKL.JK',
    label: 'SMKL.JK,  PT Satyamitra Kemas Lestari Tbk',
    name: 'PT Satyamitra Kemas Lestari Tbk',
    symbol: 'SMKL.JK',
  },
  {
    value: 'SMMA.JK',
    label: 'SMMA.JK,  PT Sinar Mas Multiartha Tbk',
    name: 'PT Sinar Mas Multiartha Tbk',
    symbol: 'SMMA.JK',
  },
  {
    value: 'SMMT.JK',
    label: 'SMMT.JK,  PT Golden Eagle Energy Tbk',
    name: 'PT Golden Eagle Energy Tbk',
    symbol: 'SMMT.JK',
  },
  {
    value: 'SOCI.JK',
    label: 'SOCI.JK,  PT Soechi Lines Tbk',
    name: 'PT Soechi Lines Tbk',
    symbol: 'SOCI.JK',
  },
  {
    value: 'SONA.JK',
    label: 'SONA.JK,  PT Sona Topas Tourism Industry Tbk',
    name: 'PT Sona Topas Tourism Industry Tbk',
    symbol: 'SONA.JK',
  },
  {
    value: 'SOSS.JK',
    label: 'SOSS.JK,  PT Shield On Service Tbk',
    name: 'PT Shield On Service Tbk',
    symbol: 'SOSS.JK',
  },
  {
    value: 'SOTS.JK',
    label: 'SOTS.JK,  PT Satria Mega Kencana Tbk',
    name: 'PT Satria Mega Kencana Tbk',
    symbol: 'SOTS.JK',
  },
  {
    value: 'SPMA.JK',
    label: 'SPMA.JK,  PT Suparma Tbk',
    name: 'PT Suparma Tbk',
    symbol: 'SPMA.JK',
  },
  {
    value: 'SPTO.JK',
    label: 'SPTO.JK,  PT Surya Pertiwi Tbk',
    name: 'PT Surya Pertiwi Tbk',
    symbol: 'SPTO.JK',
  },
  {
    value: 'SQMI.JK',
    label: 'SQMI.JK,  PT Wilton Makmur indonesia Tbk.',
    name: 'PT Wilton Makmur indonesia Tbk.',
    symbol: 'SQMI.JK',
  },
  {
    value: 'SRAJ.JK',
    label: 'SRAJ.JK,  PT Sejahteraraya Anugrahjaya Tbk',
    name: 'PT Sejahteraraya Anugrahjaya Tbk',
    symbol: 'SRAJ.JK',
  },
  {
    value: 'SRSN.JK',
    label: 'SRSN.JK,  PT Indo Acidatama Tbk',
    name: 'PT Indo Acidatama Tbk',
    symbol: 'SRSN.JK',
  },
  {
    value: 'SSTM.JK',
    label: 'SSTM.JK,  PT Sunson Textile Manufacturer Tbk',
    name: 'PT Sunson Textile Manufacturer Tbk',
    symbol: 'SSTM.JK',
  },
  {
    value: 'STAR.JK',
    label: 'STAR.JK,  PT Buana Artha Anugerah Tbk',
    name: 'PT Buana Artha Anugerah Tbk',
    symbol: 'STAR.JK',
  },
  {
    value: 'STTP.JK',
    label: 'STTP.JK,  PT Siantar Top Tbk',
    name: 'PT Siantar Top Tbk',
    symbol: 'STTP.JK',
  },
  {
    value: 'SULI.JK',
    label: 'SULI.JK,  PT SLJ Global Tbk',
    name: 'PT SLJ Global Tbk',
    symbol: 'SULI.JK',
  },
  {
    value: 'SURE.JK',
    label: 'SURE.JK,  PT Super Energy Tbk',
    name: 'PT Super Energy Tbk',
    symbol: 'SURE.JK',
  },
  {
    value: 'SWAT.JK',
    label: 'SWAT.JK,  PT Sriwahana Adityakarta Tbk',
    name: 'PT Sriwahana Adityakarta Tbk',
    symbol: 'SWAT.JK',
  },
  {
    value: 'TALF.JK',
    label: 'TALF.JK,  PT Tunas Alfin Tbk',
    name: 'PT Tunas Alfin Tbk',
    symbol: 'TALF.JK',
  },
  {
    value: 'TAMA.JK',
    label: 'TAMA.JK,  PT Lancartama Sejati Tbk',
    name: 'PT Lancartama Sejati Tbk',
    symbol: 'TAMA.JK',
  },
  {
    value: 'TAMU.JK',
    label: 'TAMU.JK,  PT Pelayaran Tamarin Samudra Tbk',
    name: 'PT Pelayaran Tamarin Samudra Tbk',
    symbol: 'TAMU.JK',
  },
  {
    value: 'TARA.JK',
    label: 'TARA.JK,  PT Agung Semesta Sejahtera Tbk',
    name: 'PT Agung Semesta Sejahtera Tbk',
    symbol: 'TARA.JK',
  },
  {
    value: 'TAXI.JK',
    label: 'TAXI.JK,  PT Express Transindo Utama Tbk',
    name: 'PT Express Transindo Utama Tbk',
    symbol: 'TAXI.JK',
  },
  {
    value: 'TBMS.JK',
    label: 'TBMS.JK,  PT Tembaga Mulia Semanan Tbk',
    name: 'PT Tembaga Mulia Semanan Tbk',
    symbol: 'TBMS.JK',
  },
  {
    value: 'TCID.JK',
    label: 'TCID.JK,  PT. Mandom Indonesia Tbk',
    name: 'PT. Mandom Indonesia Tbk',
    symbol: 'TCID.JK',
  },
  {
    value: 'TEBE.JK',
    label: 'TEBE.JK,  PT Dana Brata Luhur Tbk',
    name: 'PT Dana Brata Luhur Tbk',
    symbol: 'TEBE.JK',
  },
  {
    value: 'TELE.JK',
    label: 'TELE.JK,  PT Omni Inovasi Indonesia Tbk',
    name: 'PT Omni Inovasi Indonesia Tbk',
    symbol: 'TELE.JK',
  },
  {
    value: 'TFCO.JK',
    label: 'TFCO.JK,  PT Tifico Fiber Indonesia Tbk',
    name: 'PT Tifico Fiber Indonesia Tbk',
    symbol: 'TFCO.JK',
  },
  {
    value: 'TGKA.JK',
    label: 'TGKA.JK,  PT Tigaraksa Satria Tbk',
    name: 'PT Tigaraksa Satria Tbk',
    symbol: 'TGKA.JK',
  },
  {
    value: 'TGRA.JK',
    label: 'TGRA.JK,  PT. Terregra Asia Energy Tbk',
    name: 'PT. Terregra Asia Energy Tbk',
    symbol: 'TGRA.JK',
  },
  {
    value: 'TIFA.JK',
    label: 'TIFA.JK,  PT KDB Tifa Finance Tbk',
    name: 'PT KDB Tifa Finance Tbk',
    symbol: 'TIFA.JK',
  },
  {
    value: 'TIRA.JK',
    label: 'TIRA.JK,  PT Tira Austenite Tbk',
    name: 'PT Tira Austenite Tbk',
    symbol: 'TIRA.JK',
  },
  {
    value: 'TIRT.JK',
    label: 'TIRT.JK,  PT Tirta Mahakam Resources Tbk',
    name: 'PT Tirta Mahakam Resources Tbk',
    symbol: 'TIRT.JK',
  },
  {
    value: 'TMAS.JK',
    label: 'TMAS.JK,  PT Temas Tbk.',
    name: 'PT Temas Tbk.',
    symbol: 'TMAS.JK',
  },
  {
    value: 'TMPO.JK',
    label: 'TMPO.JK,  PT Tempo Inti Media Tbk',
    name: 'PT Tempo Inti Media Tbk',
    symbol: 'TMPO.JK',
  },
  {
    value: 'TNCA.JK',
    label: 'TNCA.JK,  PT Trimuda Nuansa Citra Tbk',
    name: 'PT Trimuda Nuansa Citra Tbk',
    symbol: 'TNCA.JK',
  },
  {
    value: 'TOBA.JK',
    label: 'TOBA.JK,  PT TBS Energi Utama Tbk',
    name: 'PT TBS Energi Utama Tbk',
    symbol: 'TOBA.JK',
  },
  {
    value: 'TOPS.JK',
    label: 'TOPS.JK,  PT Totalindo Eka Persada Tbk',
    name: 'PT Totalindo Eka Persada Tbk',
    symbol: 'TOPS.JK',
  },
  {
    value: 'TOTL.JK',
    label: 'TOTL.JK,  PT Total Bangun Persada Tbk',
    name: 'PT Total Bangun Persada Tbk',
    symbol: 'TOTL.JK',
  },
  {
    value: 'TOTO.JK',
    label: 'TOTO.JK,  PT Surya Toto Indonesia Tbk',
    name: 'PT Surya Toto Indonesia Tbk',
    symbol: 'TOTO.JK',
  },
  {
    value: 'TOYS.JK',
    label: 'TOYS.JK,  PT Sunindo Adipersada Tbk',
    name: 'PT Sunindo Adipersada Tbk',
    symbol: 'TOYS.JK',
  },
  {
    value: 'TPMA.JK',
    label: 'TPMA.JK,  PT Trans Power Marine Tbk',
    name: 'PT Trans Power Marine Tbk',
    symbol: 'TPMA.JK',
  },
  {
    value: 'TRIM.JK',
    label: 'TRIM.JK,  PT Trimegah Sekuritas Indonesia Tbk',
    name: 'PT Trimegah Sekuritas Indonesia Tbk',
    symbol: 'TRIM.JK',
  },
  {
    value: 'TRIN.JK',
    label: 'TRIN.JK,  PT Perintis Triniti Properti Tbk',
    name: 'PT Perintis Triniti Properti Tbk',
    symbol: 'TRIN.JK',
  },
  {
    value: 'TRIS.JK',
    label: 'TRIS.JK,  PT Trisula International Tbk',
    name: 'PT Trisula International Tbk',
    symbol: 'TRIS.JK',
  },
  {
    value: 'TRJA.JK',
    label: 'TRJA.JK,  PT Transkon Jaya Tbk',
    name: 'PT Transkon Jaya Tbk',
    symbol: 'TRJA.JK',
  },
  {
    value: 'TRST.JK',
    label: 'TRST.JK,  PT Trias Sentosa Tbk',
    name: 'PT Trias Sentosa Tbk',
    symbol: 'TRST.JK',
  },
  {
    value: 'TRUK.JK',
    label: 'TRUK.JK,  PT Guna Timur Raya Tbk',
    name: 'PT Guna Timur Raya Tbk',
    symbol: 'TRUK.JK',
  },
  {
    value: 'TRUS.JK',
    label: 'TRUS.JK,  PT Trust Finance Indonesia Tbk',
    name: 'PT Trust Finance Indonesia Tbk',
    symbol: 'TRUS.JK',
  },
  {
    value: 'TSPC.JK',
    label: 'TSPC.JK,  PT Tempo Scan Pacific Tbk',
    name: 'PT Tempo Scan Pacific Tbk',
    symbol: 'TSPC.JK',
  },
  {
    value: 'TUGU.JK',
    label: 'TUGU.JK,  PT Asuransi Tugu Pratama Indonesia Tbk',
    name: 'PT Asuransi Tugu Pratama Indonesia Tbk',
    symbol: 'TUGU.JK',
  },
  {
    value: 'UANG.JK',
    label: 'UANG.JK,  PT Pakuan, Tbk',
    name: 'PT Pakuan, Tbk',
    symbol: 'UANG.JK',
  },
  {
    value: 'UCID.JK',
    label: 'UCID.JK,  PT Uni-Charm Indonesia Tbk',
    name: 'PT Uni-Charm Indonesia Tbk',
    symbol: 'UCID.JK',
  },
  {
    value: 'UFOE.JK',
    label: 'UFOE.JK,  PT Damai Sejahtera Abadi Tbk',
    name: 'PT Damai Sejahtera Abadi Tbk',
    symbol: 'UFOE.JK',
  },
  {
    value: 'ULTJ.JK',
    label: 'ULTJ.JK,  PT Ultrajaya Milk Industry & Trading Company Tbk',
    name: 'PT Ultrajaya Milk Industry & Trading Company Tbk',
    symbol: 'ULTJ.JK',
  },
  {
    value: 'UNIC.JK',
    label: 'UNIC.JK,  PT Unggul Indah Cahaya Tbk',
    name: 'PT Unggul Indah Cahaya Tbk',
    symbol: 'UNIC.JK',
  },
  {
    value: 'UNIQ.JK',
    label: 'UNIQ.JK,  PT Ulima Nitra Tbk',
    name: 'PT Ulima Nitra Tbk',
    symbol: 'UNIQ.JK',
  },
  {
    value: 'UNSP.JK',
    label: 'UNSP.JK,  PT Bakrie Sumatera Plantations Tbk',
    name: 'PT Bakrie Sumatera Plantations Tbk',
    symbol: 'UNSP.JK',
  },
  {
    value: 'URBN.JK',
    label: 'URBN.JK,  PT Urban Jakarta Propertindo Tbk.',
    name: 'PT Urban Jakarta Propertindo Tbk.',
    symbol: 'URBN.JK',
  },
  {
    value: 'VICI.JK',
    label: 'VICI.JK,  PT Victoria Care Indonesia Tbk',
    name: 'PT Victoria Care Indonesia Tbk',
    symbol: 'VICI.JK',
  },
  {
    value: 'VICO.JK',
    label: 'VICO.JK,  PT Victoria Investama Tbk',
    name: 'PT Victoria Investama Tbk',
    symbol: 'VICO.JK',
  },
  {
    value: 'VINS.JK',
    label: 'VINS.JK,  PT Victoria Insurance Tbk',
    name: 'PT Victoria Insurance Tbk',
    symbol: 'VINS.JK',
  },
  {
    value: 'VIVA.JK',
    label: 'VIVA.JK,  PT Visi Media Asia Tbk',
    name: 'PT Visi Media Asia Tbk',
    symbol: 'VIVA.JK',
  },
  {
    value: 'VOKS.JK',
    label: 'VOKS.JK,  PT Voksel Electric Tbk',
    name: 'PT Voksel Electric Tbk',
    symbol: 'VOKS.JK',
  },
  {
    value: 'VRNA.JK',
    label: 'VRNA.JK,  PT Mizuho Leasing Indonesia Tbk',
    name: 'PT Mizuho Leasing Indonesia Tbk',
    symbol: 'VRNA.JK',
  },
  {
    value: 'WAPO.JK',
    label: 'WAPO.JK,  PT Wahana Pronatural Tbk',
    name: 'PT Wahana Pronatural Tbk',
    symbol: 'WAPO.JK',
  },
  {
    value: 'WEGE.JK',
    label: 'WEGE.JK,  PT Wijaya Karya Bangunan Gedung Tbk',
    name: 'PT Wijaya Karya Bangunan Gedung Tbk',
    symbol: 'WEGE.JK',
  },
  {
    value: 'WEHA.JK',
    label: 'WEHA.JK,  PT WEHA Transportasi Indonesia Tbk',
    name: 'PT WEHA Transportasi Indonesia Tbk',
    symbol: 'WEHA.JK',
  },
  {
    value: 'WICO.JK',
    label: 'WICO.JK,  Pt Wicaksana Overseas International Tbk',
    name: 'Pt Wicaksana Overseas International Tbk',
    symbol: 'WICO.JK',
  },
  {
    value: 'WIFI.JK',
    label: 'WIFI.JK,  PT Solusi Sinergi Digital Tbk',
    name: 'PT Solusi Sinergi Digital Tbk',
    symbol: 'WIFI.JK',
  },
  {
    value: 'WIIM.JK',
    label: 'WIIM.JK,  PT Wismilak Inti Makmur Tbk',
    name: 'PT Wismilak Inti Makmur Tbk',
    symbol: 'WIIM.JK',
  },
  {
    value: 'WINS.JK',
    label: 'WINS.JK,  PT Wintermar Offshore Marine Tbk',
    name: 'PT Wintermar Offshore Marine Tbk',
    symbol: 'WINS.JK',
  },
  {
    value: 'WMUU.JK',
    label: 'WMUU.JK,  PT Widodo Makmur Unggas Tbk',
    name: 'PT Widodo Makmur Unggas Tbk',
    symbol: 'WMUU.JK',
  },
  {
    value: 'WOMF.JK',
    label: 'WOMF.JK,  PT Wahana Ottomitra Multiartha Tbk',
    name: 'PT Wahana Ottomitra Multiartha Tbk',
    symbol: 'WOMF.JK',
  },
  {
    value: 'WOOD.JK',
    label: 'WOOD.JK,  PT Integra Indocabinet Tbk',
    name: 'PT Integra Indocabinet Tbk',
    symbol: 'WOOD.JK',
  },
  {
    value: 'WOWS.JK',
    label: 'WOWS.JK,  PT Ginting Jaya Energi Tbk',
    name: 'PT Ginting Jaya Energi Tbk',
    symbol: 'WOWS.JK',
  },
  {
    value: 'WTON.JK',
    label: 'WTON.JK,  PT Wijaya Karya Beton Tbk',
    name: 'PT Wijaya Karya Beton Tbk',
    symbol: 'WTON.JK',
  },
  {
    value: 'YELO.JK',
    label: 'YELO.JK,  PT Yelooo Integra Datanet Tbk',
    name: 'PT Yelooo Integra Datanet Tbk',
    symbol: 'YELO.JK',
  },
  {
    value: 'YPAS.JK',
    label: 'YPAS.JK,  PT Yanaprima Hastapersada Tbk',
    name: 'PT Yanaprima Hastapersada Tbk',
    symbol: 'YPAS.JK',
  },
  {
    value: 'YULE.JK',
    label: 'YULE.JK,  PT Yulie Sekuritas Indonesia Tbk',
    name: 'PT Yulie Sekuritas Indonesia Tbk',
    symbol: 'YULE.JK',
  },
  {
    value: 'ZBRA.JK',
    label: 'ZBRA.JK,  PT Dosni Roha Indonesia Tbk',
    name: 'PT Dosni Roha Indonesia Tbk',
    symbol: 'ZBRA.JK',
  },
  {
    value: 'ZINC.JK',
    label: 'ZINC.JK,  PT Kapuas Prima Coal Tbk',
    name: 'PT Kapuas Prima Coal Tbk',
    symbol: 'ZINC.JK',
  },
  {
    value: 'ZONE.JK',
    label: 'ZONE.JK,  PT Mega Perintis Tbk',
    name: 'PT Mega Perintis Tbk',
    symbol: 'ZONE.JK',
  },
  {
    value: 'PARA',
    label: 'PARA,  Paramount Global',
    name: 'Paramount Global',
    symbol: 'PARA',
  },
  {
    value: 'QNGY',
    label: 'QNGY,  Quanergy Systems, Inc.',
    name: 'Quanergy Systems, Inc.',
    symbol: 'QNGY',
  },
  {
    value: 'ACAQ',
    label: 'ACAQ,  Athena Consumer Acquisition Corp.',
    name: 'Athena Consumer Acquisition Corp.',
    symbol: 'ACAQ',
  },
  {
    value: 'AFACU',
    label: 'AFACU,  Arena Fortify Acquisition Corp.',
    name: 'Arena Fortify Acquisition Corp.',
    symbol: 'AFACU',
  },
  {
    value: 'BRKHU',
    label: 'BRKHU,  Burtech Acquisition Corp.',
    name: 'Burtech Acquisition Corp.',
    symbol: 'BRKHU',
  },
  {
    value: 'NE',
    label: 'NE,  Noble Corporation Plc',
    name: 'Noble Corporation Plc',
    symbol: 'NE',
  },
  {
    value: 'ALCC',
    label: 'ALCC,  AltC Acquisition Corp.',
    name: 'AltC Acquisition Corp.',
    symbol: 'ALCC',
  },
  {
    value: 'AMPI',
    label: 'AMPI,  Advanced Merger Partners, Inc',
    name: 'Advanced Merger Partners, Inc',
    symbol: 'AMPI',
  },
  {
    value: 'ANAC',
    label: 'ANAC,  Arctos NorthStar Acquisition Corp.',
    name: 'Arctos NorthStar Acquisition Corp.',
    symbol: 'ANAC',
  },
  {
    value: 'AXH',
    label: 'AXH,  Industrial Human Capital, Inc.',
    name: 'Industrial Human Capital, Inc.',
    symbol: 'AXH',
  },
  {
    value: 'BGSX',
    label: 'BGSX,  Build Acquisition Corp.',
    name: 'Build Acquisition Corp.',
    symbol: 'BGSX',
  },
  {
    value: 'CLBR',
    label: 'CLBR,  Colombier Acquisition Corp.',
    name: 'Colombier Acquisition Corp.',
    symbol: 'CLBR',
  },
  {
    value: 'CNTA',
    label: 'CNTA,  Centessa Pharmaceuticals plc',
    name: 'Centessa Pharmaceuticals plc',
    symbol: 'CNTA',
  },
  {
    value: 'COCBF',
    label: 'COCBF,  Coastal Caribbean Oils & Minerals, Ltd.',
    name: 'Coastal Caribbean Oils & Minerals, Ltd.',
    symbol: 'COCBF',
  },
  {
    value: 'DMAQR',
    label: 'DMAQR,  Deep Medicine Acquisition Corp.',
    name: 'Deep Medicine Acquisition Corp.',
    symbol: 'DMAQR',
  },
  {
    value: 'DTRTU',
    label: 'DTRTU,  DTRT Health Acquisition Corp.',
    name: 'DTRT Health Acquisition Corp.',
    symbol: 'DTRTU',
  },
  {
    value: 'EMBK',
    label: 'EMBK,  Embark Technology, Inc.',
    name: 'Embark Technology, Inc.',
    symbol: 'EMBK',
  },
  {
    value: 'FLAG',
    label: 'FLAG,  First Light Acquisition Group, Inc.',
    name: 'First Light Acquisition Group, Inc.',
    symbol: 'FLAG',
  },
  {
    value: 'FRLAU',
    label: 'FRLAU,  Fortune Rise Acquisition Corporation',
    name: 'Fortune Rise Acquisition Corporation',
    symbol: 'FRLAU',
  },
  {
    value: 'FTEV',
    label: 'FTEV,  FinTech Evolution Acquisition Group',
    name: 'FinTech Evolution Acquisition Group',
    symbol: 'FTEV',
  },
  {
    value: 'GLLI',
    label: 'GLLI,  Globalink Investment Inc.',
    name: 'Globalink Investment Inc.',
    symbol: 'GLLI',
  },
  {
    value: 'GLLIR',
    label: 'GLLIR,  Globalink Investment Inc.',
    name: 'Globalink Investment Inc.',
    symbol: 'GLLIR',
  },
  {
    value: 'HMCO',
    label: 'HMCO,  HumanCo Acquisition Corp.',
    name: 'HumanCo Acquisition Corp.',
    symbol: 'HMCO',
  },
  {
    value: 'IACC',
    label: 'IACC,  ION Acquisition Corp 3 Ltd.',
    name: 'ION Acquisition Corp 3 Ltd.',
    symbol: 'IACC',
  },
  {
    value: 'IGTAU',
    label: 'IGTAU,  Inception Growth Acquisition Limited',
    name: 'Inception Growth Acquisition Limited',
    symbol: 'IGTAU',
  },
  {
    value: '006660.KS',
    label: '006660.KS,  Samsung Climate Control Co., Ltd.',
    name: 'Samsung Climate Control Co., Ltd.',
    symbol: '006660.KS',
  },
  {
    value: '0557.HK',
    label: '0557.HK,  China Tian Yuan Healthcare Group Limited',
    name: 'China Tian Yuan Healthcare Group Limited',
    symbol: '0557.HK',
  },
  {
    value: '0731.HK',
    label: '0731.HK,  C&D Newin Paper & Pulp Corporation Limited',
    name: 'C&D Newin Paper & Pulp Corporation Limited',
    symbol: '0731.HK',
  },
  {
    value: '1650.HK',
    label: '1650.HK,  Hygieia Group Limited',
    name: 'Hygieia Group Limited',
    symbol: '1650.HK',
  },
  {
    value: '1826.HK',
    label: '1826.HK,  FDB Holdings Limited',
    name: 'FDB Holdings Limited',
    symbol: '1826.HK',
  },
  {
    value: '3928.HK',
    label: '3928.HK,  S&T Holdings Limited',
    name: 'S&T Holdings Limited',
    symbol: '3928.HK',
  },
  {
    value: '600032.SS',
    label:
      '600032.SS,  Zhejiang Provincial New Energy Investment Group Co., Ltd.',
    name: 'Zhejiang Provincial New Energy Investment Group Co., Ltd.',
    symbol: '600032.SS',
  },
  {
    value: '600905.SS',
    label: '600905.SS,  China Three Gorges Renewables (Group) Co.,Ltd.',
    name: 'China Three Gorges Renewables (Group) Co.,Ltd.',
    symbol: '600905.SS',
  },
  {
    value: '600906.SS',
    label: '600906.SS,  Caida Securities Co., Ltd.',
    name: 'Caida Securities Co., Ltd.',
    symbol: '600906.SS',
  },
  {
    value: '600955.SS',
    label: '600955.SS,  Lihuayi Weiyuan Chemical Co., Ltd.',
    name: 'Lihuayi Weiyuan Chemical Co., Ltd.',
    symbol: '600955.SS',
  },
  {
    value: '601156.SS',
    label: '601156.SS,  Eastern Air Logistics Co., Ltd.',
    name: 'Eastern Air Logistics Co., Ltd.',
    symbol: '601156.SS',
  },
  {
    value: '601528.SS',
    label:
      '601528.SS,  Zhejiang Shaoxing RuiFeng Rural Commercial Bank Co.,Ltd',
    name: 'Zhejiang Shaoxing RuiFeng Rural Commercial Bank Co.,Ltd',
    symbol: '601528.SS',
  },
  {
    value: '601665.SS',
    label: '601665.SS,  QILU BANK CO., LTD.',
    name: 'QILU BANK CO., LTD.',
    symbol: '601665.SS',
  },
  {
    value: '603048.SS',
    label: '603048.SS,  Zhejiang Liming Intelligent Manufacturing Co.,Ltd.',
    name: 'Zhejiang Liming Intelligent Manufacturing Co.,Ltd.',
    symbol: '603048.SS',
  },
  {
    value: '603171.SS',
    label: '603171.SS,  Servyou Software Group Co., Ltd.',
    name: 'Servyou Software Group Co., Ltd.',
    symbol: '603171.SS',
  },
  {
    value: '603213.SS',
    label: '603213.SS,  Zhejiang Oceanking Development Co., Ltd.',
    name: 'Zhejiang Oceanking Development Co., Ltd.',
    symbol: '603213.SS',
  },
  {
    value: '603219.SS',
    label: '603219.SS,  Ningbo Fujia Industrial Co., Ltd.',
    name: 'Ningbo Fujia Industrial Co., Ltd.',
    symbol: '603219.SS',
  },
  {
    value: '603529.SS',
    label: '603529.SS,  AIMA Technology Group CO., LTD',
    name: 'AIMA Technology Group CO., LTD',
    symbol: '603529.SS',
  },
  {
    value: '605011.SS',
    label: '605011.SS,  Hangzhou Cogeneration Group Co., Ltd.',
    name: 'Hangzhou Cogeneration Group Co., Ltd.',
    symbol: '605011.SS',
  },
  {
    value: '605033.SS',
    label: '605033.SS,  Shaanxi Meibang Pharmaceutical Group Co., Ltd.',
    name: 'Shaanxi Meibang Pharmaceutical Group Co., Ltd.',
    symbol: '605033.SS',
  },
  {
    value: '605069.SS',
    label:
      '605069.SS,  Beijing ZEHO Waterfront Ecological Environment Treatment Co., Ltd.',
    name: 'Beijing ZEHO Waterfront Ecological Environment Treatment Co., Ltd.',
    symbol: '605069.SS',
  },
  {
    value: '605077.SS',
    label: '605077.SS,  Zhejiang Huakang Pharmaceutical Co., Ltd.',
    name: 'Zhejiang Huakang Pharmaceutical Co., Ltd.',
    symbol: '605077.SS',
  },
  {
    value: '605081.SS',
    label:
      '605081.SS,  Shanghai Taihe Water Environmental Technology Development Co.,Ltd.',
    name: 'Shanghai Taihe Water Environmental Technology Development Co.,Ltd.',
    symbol: '605081.SS',
  },
  {
    value: '605117.SS',
    label: '605117.SS,  Ningbo Deye Technology Group Co., Ltd.',
    name: 'Ningbo Deye Technology Group Co., Ltd.',
    symbol: '605117.SS',
  },
  {
    value: '605138.SS',
    label: '605138.SS,  Zhejiang Sunrise Garment Group Co., Ltd.',
    name: 'Zhejiang Sunrise Garment Group Co., Ltd.',
    symbol: '605138.SS',
  },
  {
    value: '605259.SS',
    label: '605259.SS,  Lutian Machinery Co., Ltd.',
    name: 'Lutian Machinery Co., Ltd.',
    symbol: '605259.SS',
  },
  {
    value: '605296.SS',
    label: '605296.SS,  Yunnan Shennong Agricultural Industry Group Co.,LTD.',
    name: 'Yunnan Shennong Agricultural Industry Group Co.,LTD.',
    symbol: '605296.SS',
  },
  {
    value: '605337.SS',
    label: '605337.SS,  Zhe Jiang Li Zi Yuan Food Co.,Ltd.',
    name: 'Zhe Jiang Li Zi Yuan Food Co.,Ltd.',
    symbol: '605337.SS',
  },
  {
    value: '605376.SS',
    label: '605376.SS,  Jiangsu Boqian New Materials Stock Co., Ltd.',
    name: 'Jiangsu Boqian New Materials Stock Co., Ltd.',
    symbol: '605376.SS',
  },
  {
    value: '605499.SS',
    label: '605499.SS,  Eastroc Beverage (Group) Co.,Ltd.',
    name: 'Eastroc Beverage (Group) Co.,Ltd.',
    symbol: '605499.SS',
  },
  {
    value: '605555.SS',
    label: '605555.SS,  Ningbo Dechang Electrical Machinery Made Co., Ltd.',
    name: 'Ningbo Dechang Electrical Machinery Made Co., Ltd.',
    symbol: '605555.SS',
  },
  {
    value: '605566.SS',
    label: '605566.SS,  Hangzhou Flariant Co., Ltd.',
    name: 'Hangzhou Flariant Co., Ltd.',
    symbol: '605566.SS',
  },
  {
    value: '605567.SS',
    label: '605567.SS,  Springsnow Food Group Co., Ltd.',
    name: 'Springsnow Food Group Co., Ltd.',
    symbol: '605567.SS',
  },
  {
    value: '605588.SS',
    label: '605588.SS,  Keystone Technology Co.,Ltd.',
    name: 'Keystone Technology Co.,Ltd.',
    symbol: '605588.SS',
  },
  {
    value: '605598.SS',
    label: '605598.SS,  Shanghai Geoharbour Construction Group Co., Ltd.',
    name: 'Shanghai Geoharbour Construction Group Co., Ltd.',
    symbol: '605598.SS',
  },
  {
    value: '605599.SS',
    label: '605599.SS,  Beijing Caishikou Department Store Co.,Ltd.',
    name: 'Beijing Caishikou Department Store Co.,Ltd.',
    symbol: '605599.SS',
  },
  {
    value: '8001.HK',
    label: '8001.HK,  Orient Securities International Holdings Limited',
    name: 'Orient Securities International Holdings Limited',
    symbol: '8001.HK',
  },
  {
    value: '8170.HK',
    label: '8170.HK,  China All Nation International Holdings Group Limited',
    name: 'China All Nation International Holdings Group Limited',
    symbol: '8170.HK',
  },
  {
    value: '8238.HK',
    label: '8238.HK,  Winto Group (Holdings) Limited',
    name: 'Winto Group (Holdings) Limited',
    symbol: '8238.HK',
  },
  {
    value: 'AAIC-PB',
    label: 'AAIC-PB,  Arlington Asset Investment Corp.',
    name: 'Arlington Asset Investment Corp.',
    symbol: 'AAIC-PB',
  },
  {
    value: 'ABCE',
    label: 'ABCE,  ABCO Energy, Inc.',
    name: 'ABCO Energy, Inc.',
    symbol: 'ABCE',
  },
  {
    value: 'ABR-PD',
    label: 'ABR-PD,  Arbor Realty Trust, Inc.',
    name: 'Arbor Realty Trust, Inc.',
    symbol: 'ABR-PD',
  },
  {
    value: 'ABR-PE',
    label: 'ABR-PE,  Arbor Realty Trust, Inc.',
    name: 'Arbor Realty Trust, Inc.',
    symbol: 'ABR-PE',
  },
  {
    value: 'ABR-PF',
    label: 'ABR-PF,  Arbor Realty Trust, Inc.',
    name: 'Arbor Realty Trust, Inc.',
    symbol: 'ABR-PF',
  },
  {
    value: 'ACHN',
    label: 'ACHN,  Achison Inc.',
    name: 'Achison Inc.',
    symbol: 'ACHN',
  },
  {
    value: 'ACII',
    label: 'ACII,  Atlas Crest Investment Corp. II',
    name: 'Atlas Crest Investment Corp. II',
    symbol: 'ACII',
  },
  {
    value: 'ACII-UN',
    label: 'ACII-UN,  Atlas Crest Investment Corp. II',
    name: 'Atlas Crest Investment Corp. II',
    symbol: 'ACII-UN',
  },
  {
    value: 'ACRDF',
    label: 'ACRDF,  Acreage Holdings, Inc.',
    name: 'Acreage Holdings, Inc.',
    symbol: 'ACRDF',
  },
  {
    value: 'ACR-PC',
    label: 'ACR-PC,  ACRES Commercial Realty Corp.',
    name: 'ACRES Commercial Realty Corp.',
    symbol: 'ACR-PC',
  },
  {
    value: 'ACR-PD',
    label: 'ACR-PD,  ACRES Commercial Realty Corp.',
    name: 'ACRES Commercial Realty Corp.',
    symbol: 'ACR-PD',
  },
  {
    value: 'ACTX',
    label: 'ACTX,  Advanced Container Technologies, Inc.',
    name: 'Advanced Container Technologies, Inc.',
    symbol: 'ACTX',
  },
  {
    value: 'ADC-PA',
    label: 'ADC-PA,  Agree Realty Corporation',
    name: 'Agree Realty Corporation',
    symbol: 'ADC-PA',
  },
  {
    value: 'ADEX',
    label: 'ADEX,  Adit EdTech Acquisition Corp.',
    name: 'Adit EdTech Acquisition Corp.',
    symbol: 'ADEX',
  },
  {
    value: 'ADEX-UN',
    label: 'ADEX-UN,  Adit EdTech Acquisition Corp.',
    name: 'Adit EdTech Acquisition Corp.',
    symbol: 'ADEX-UN',
  },
  {
    value: 'ADMG',
    label: 'ADMG,  Adamant DRI Processing and Minerals Group',
    name: 'Adamant DRI Processing and Minerals Group',
    symbol: 'ADMG',
  },
  {
    value: 'ADMQ',
    label: 'ADMQ,  ADM Endeavors, Inc.',
    name: 'ADM Endeavors, Inc.',
    symbol: 'ADMQ',
  },
  {
    value: 'ADOC',
    label: 'ADOC,  Edoc Acquisition Corp.',
    name: 'Edoc Acquisition Corp.',
    symbol: 'ADOC',
  },
  {
    value: 'ADOCR',
    label: 'ADOCR,  Edoc Acquisition Corp.',
    name: 'Edoc Acquisition Corp.',
    symbol: 'ADOCR',
  },
  {
    value: 'AEL-PA',
    label: 'AEL-PA,  American Equity Investment Life Holding Company',
    name: 'American Equity Investment Life Holding Company',
    symbol: 'AEL-PA',
  },
  {
    value: 'AEL-PB',
    label: 'AEL-PB,  American Equity Investment Life Holding Company',
    name: 'American Equity Investment Life Holding Company',
    symbol: 'AEL-PB',
  },
  {
    value: 'AENZ',
    label: 'AENZ,  Aenza S.A.A.',
    name: 'Aenza S.A.A.',
    symbol: 'AENZ',
  },
  {
    value: 'AESC',
    label: 'AESC,  The AES Corporation',
    name: 'The AES Corporation',
    symbol: 'AESC',
  },
  {
    value: 'AFHIF',
    label: 'AFHIF,  Atlas Financial Holdings, Inc.',
    name: 'Atlas Financial Holdings, Inc.',
    symbol: 'AFHIF',
  },
  {
    value: 'AFOM',
    label: 'AFOM,  All For One Media Corp.',
    name: 'All For One Media Corp.',
    symbol: 'AFOM',
  },
  {
    value: 'AFTR-UN',
    label: 'AFTR-UN,  AfterNext HealthTech Acquisition Corp.',
    name: 'AfterNext HealthTech Acquisition Corp.',
    symbol: 'AFTR-UN',
  },
  {
    value: 'AGM-PC',
    label: 'AGM-PC,  Federal Agricultural Mortgage Corporation',
    name: 'Federal Agricultural Mortgage Corporation',
    symbol: 'AGM-PC',
  },
  {
    value: 'AGM-PD',
    label: 'AGM-PD,  Federal Agricultural Mortgage Corporation',
    name: 'Federal Agricultural Mortgage Corporation',
    symbol: 'AGM-PD',
  },
  {
    value: 'AGM-PG',
    label: 'AGM-PG,  Federal Agricultural Mortgage Corporation',
    name: 'Federal Agricultural Mortgage Corporation',
    symbol: 'AGM-PG',
  },
  {
    value: 'AGRIW',
    label: 'AGRIW,  AgriFORCE Growing Systems Ltd.',
    name: 'AgriFORCE Growing Systems Ltd.',
    symbol: 'AGRIW',
  },
  {
    value: 'AGTK',
    label: 'AGTK,  Agritek Holdings, Inc.',
    name: 'Agritek Holdings, Inc.',
    symbol: 'AGTK',
  },
  {
    value: 'AHH-PA',
    label: 'AHH-PA,  Armada Hoffler Properties, Inc.',
    name: 'Armada Hoffler Properties, Inc.',
    symbol: 'AHH-PA',
  },
  {
    value: 'AHT-PF',
    label: 'AHT-PF,  Ashford Hospitality Trust, Inc.',
    name: 'Ashford Hospitality Trust, Inc.',
    symbol: 'AHT-PF',
  },
  {
    value: 'AHT-PG',
    label: 'AHT-PG,  Ashford Hospitality Trust, Inc.',
    name: 'Ashford Hospitality Trust, Inc.',
    symbol: 'AHT-PG',
  },
  {
    value: 'AHT-PH',
    label: 'AHT-PH,  Ashford Hospitality Trust, Inc.',
    name: 'Ashford Hospitality Trust, Inc.',
    symbol: 'AHT-PH',
  },
  {
    value: 'AIG-PA',
    label: 'AIG-PA,  American International Group, Inc.',
    name: 'American International Group, Inc.',
    symbol: 'AIG-PA',
  },
  {
    value: 'AILIH',
    label: 'AILIH,  Ameren Illinois Company',
    name: 'Ameren Illinois Company',
    symbol: 'AILIH',
  },
  {
    value: 'AILLI',
    label: 'AILLI,  Ameren Illinois Company',
    name: 'Ameren Illinois Company',
    symbol: 'AILLI',
  },
  {
    value: 'AILLO',
    label: 'AILLO,  Ameren Illinois Company',
    name: 'Ameren Illinois Company',
    symbol: 'AILLO',
  },
  {
    value: 'AIMD',
    label: 'AIMD,  Ainos, Inc.',
    name: 'Ainos, Inc.',
    symbol: 'AIMD',
  },
  {
    value: 'ALBOU.PA',
    label: 'ALBOU.PA,  Bourrelier Group SA',
    name: 'Bourrelier Group SA',
    symbol: 'ALBOU.PA',
  },
  {
    value: 'ALF.L',
    label: 'ALF.L,  Alternative Liquidity Fund Limited',
    name: 'Alternative Liquidity Fund Limited',
    symbol: 'ALF.L',
  },
  {
    value: 'ALL-PB',
    label: 'ALL-PB,  The Allstate Corporation',
    name: 'The Allstate Corporation',
    symbol: 'ALL-PB',
  },
  {
    value: 'ALL-PG',
    label: 'ALL-PG,  The Allstate Corporation',
    name: 'The Allstate Corporation',
    symbol: 'ALL-PG',
  },
  {
    value: 'ALL-PH',
    label: 'ALL-PH,  The Allstate Corporation',
    name: 'The Allstate Corporation',
    symbol: 'ALL-PH',
  },
  {
    value: 'ALNN6.PA',
    label: 'ALNN6.PA,  ENENSYS Technologies SA',
    name: 'ENENSYS Technologies SA',
    symbol: 'ALNN6.PA',
  },
  {
    value: 'ALTG-PA',
    label: 'ALTG-PA,  Alta Equipment Group Inc.',
    name: 'Alta Equipment Group Inc.',
    symbol: 'ALTG-PA',
  },
  {
    value: 'AMCCF',
    label: 'AMCCF,  Amcor plc',
    name: 'Amcor plc',
    symbol: 'AMCCF',
  },
  {
    value: 'AMH-PG',
    label: 'AMH-PG,  American Homes 4 Rent',
    name: 'American Homes 4 Rent',
    symbol: 'AMH-PG',
  },
  {
    value: 'AMRR',
    label: 'AMRR,  American Metals Recovery and Recycling Inc.',
    name: 'American Metals Recovery and Recycling Inc.',
    symbol: 'AMRR',
  },
  {
    value: 'ANTH',
    label: 'ANTH,  Anthera Pharmaceuticals, Inc.',
    name: 'Anthera Pharmaceuticals, Inc.',
    symbol: 'ANTH',
  },
  {
    value: 'APGB-UN',
    label: 'APGB-UN,  Apollo Strategic Growth Capital II',
    name: 'Apollo Strategic Growth Capital II',
    symbol: 'APGB-UN',
  },
  {
    value: 'APSI',
    label: 'APSI,  Aqua Power Systems Inc.',
    name: 'Aqua Power Systems Inc.',
    symbol: 'APSI',
  },
  {
    value: 'ARAT',
    label: 'ARAT,  Arax Holdings Corp.',
    name: 'Arax Holdings Corp.',
    symbol: 'ARAT',
  },
  {
    value: 'ARBG',
    label: 'ARBG,  Aequi Acquisition Corp.',
    name: 'Aequi Acquisition Corp.',
    symbol: 'ARBG',
  },
  {
    value: 'ARKOW',
    label: 'ARKOW,  Arko Corp.',
    name: 'Arko Corp.',
    symbol: 'ARKOW',
  },
  {
    value: 'ARTEW',
    label: 'ARTEW,  Artemis Strategic Investment Corporation',
    name: 'Artemis Strategic Investment Corporation',
    symbol: 'ARTEW',
  },
  {
    value: 'ASB-PE',
    label: 'ASB-PE,  Associated Banc-Corp',
    name: 'Associated Banc-Corp',
    symbol: 'ASB-PE',
  },
  {
    value: 'ASB-PF',
    label: 'ASB-PF,  Associated Banc-Corp',
    name: 'Associated Banc-Corp',
    symbol: 'ASB-PF',
  },
  {
    value: 'ASSF',
    label: 'ASSF,  Assisted 4 Living, Inc.',
    name: 'Assisted 4 Living, Inc.',
    symbol: 'ASSF',
  },
  {
    value: 'ATA-UN',
    label: 'ATA-UN,  Americas Technology Acquisition Corp.',
    name: 'Americas Technology Acquisition Corp.',
    symbol: 'ATA-UN',
  },
  {
    value: 'ATCC',
    label: 'ATCC,  Ameritrust Corporation',
    name: 'Ameritrust Corporation',
    symbol: 'ATCC',
  },
  {
    value: 'ATH-PA',
    label: 'ATH-PA,  Athene Holding Ltd.',
    name: 'Athene Holding Ltd.',
    symbol: 'ATH-PA',
  },
  {
    value: 'ATH-PB',
    label: 'ATH-PB,  Athene Holding Ltd.',
    name: 'Athene Holding Ltd.',
    symbol: 'ATH-PB',
  },
  {
    value: 'ATH-PD',
    label: 'ATH-PD,  Athene Holding Ltd.',
    name: 'Athene Holding Ltd.',
    symbol: 'ATH-PD',
  },
  {
    value: 'ATLCP',
    label: 'ATLCP,  Atlanticus Holdings Corporation',
    name: 'Atlanticus Holdings Corporation',
    symbol: 'ATLCP',
  },
  {
    value: 'ATYG',
    label: 'ATYG,  Atlas Technology Group, Inc.',
    name: 'Atlas Technology Group, Inc.',
    symbol: 'ATYG',
  },
  {
    value: 'AUGG',
    label: 'AUGG,  Augusta Gold Corp.',
    name: 'Augusta Gold Corp.',
    symbol: 'AUGG',
  },
  {
    value: 'AVCRF',
    label: 'AVCRF,  Avricore Health Inc.',
    name: 'Avricore Health Inc.',
    symbol: 'AVCRF',
  },
  {
    value: 'AXREF',
    label: 'AXREF,  Amarc Resources Ltd.',
    name: 'Amarc Resources Ltd.',
    symbol: 'AXREF',
  },
  {
    value: 'BAC-PB',
    label: 'BAC-PB,  Bank of America Corporation',
    name: 'Bank of America Corporation',
    symbol: 'BAC-PB',
  },
  {
    value: 'BAC-PE',
    label: 'BAC-PE,  Bank of America Corporation',
    name: 'Bank of America Corporation',
    symbol: 'BAC-PE',
  },
  {
    value: 'BAC-PK',
    label: 'BAC-PK,  Bank of America Corporation',
    name: 'Bank of America Corporation',
    symbol: 'BAC-PK',
  },
  {
    value: 'BAC-PL',
    label: 'BAC-PL,  Bank of America Corporation',
    name: 'Bank of America Corporation',
    symbol: 'BAC-PL',
  },
  {
    value: 'BAC-PM',
    label: 'BAC-PM,  Bank of America Corporation',
    name: 'Bank of America Corporation',
    symbol: 'BAC-PM',
  },
  {
    value: 'BAC-PN',
    label: 'BAC-PN,  Bank of America Corporation',
    name: 'Bank of America Corporation',
    symbol: 'BAC-PN',
  },
  {
    value: 'BAC-PO',
    label: 'BAC-PO,  Bank of America Corporation',
    name: 'Bank of America Corporation',
    symbol: 'BAC-PO',
  },
  {
    value: 'BAC-PP',
    label: 'BAC-PP,  Bank of America Corporation',
    name: 'Bank of America Corporation',
    symbol: 'BAC-PP',
  },
  {
    value: 'BAOS',
    label: 'BAOS,  Baosheng Media Group Holdings Limited',
    name: 'Baosheng Media Group Holdings Limited',
    symbol: 'BAOS',
  },
  {
    value: 'BATRB',
    label: 'BATRB,  The Liberty Braves Group',
    name: 'The Liberty Braves Group',
    symbol: 'BATRB',
  },
  {
    value: 'BBBT',
    label: 'BBBT,  Black Bird Biotech, Inc.',
    name: 'Black Bird Biotech, Inc.',
    symbol: 'BBBT',
  },
  {
    value: 'BBRW',
    label: 'BBRW,  BrewBilt Manufacturing Inc.',
    name: 'BrewBilt Manufacturing Inc.',
    symbol: 'BBRW',
  },
  {
    value: 'BBXIA',
    label: 'BBXIA,  BBX Capital, Inc.',
    name: 'BBX Capital, Inc.',
    symbol: 'BBXIA',
  },
  {
    value: 'BCRHF',
    label: 'BCRHF,  Blue Capital Reinsurance Holdings Ltd.',
    name: 'Blue Capital Reinsurance Holdings Ltd.',
    symbol: 'BCRHF',
  },
  {
    value: 'BENE',
    label: 'BENE,  Benessere Capital Acquisition Corp.',
    name: 'Benessere Capital Acquisition Corp.',
    symbol: 'BENE',
  },
  {
    value: 'BETRF',
    label: 'BETRF,  BetterLife Pharma Inc.',
    name: 'BetterLife Pharma Inc.',
    symbol: 'BETRF',
  },
  {
    value: 'BFS-PD',
    label: 'BFS-PD,  Saul Centers, Inc.',
    name: 'Saul Centers, Inc.',
    symbol: 'BFS-PD',
  },
  {
    value: 'BFYW',
    label: 'BFYW,  Better For You Wellness, Inc.',
    name: 'Better For You Wellness, Inc.',
    symbol: 'BFYW',
  },
  {
    value: 'BGLC',
    label: 'BGLC,  BioNexus Gene Lab Corporation',
    name: 'BioNexus Gene Lab Corporation',
    symbol: 'BGLC',
  },
  {
    value: 'BHACW',
    label: 'BHACW,  Crixus BH3 Acquisition Company',
    name: 'Crixus BH3 Acquisition Company',
    symbol: 'BHACW',
  },
  {
    value: 'BHR-PB',
    label: 'BHR-PB,  Braemar Hotels & Resorts Inc.',
    name: 'Braemar Hotels & Resorts Inc.',
    symbol: 'BHR-PB',
  },
  {
    value: 'BIOF',
    label: 'BIOF,  Blue Biofuels, Inc.',
    name: 'Blue Biofuels, Inc.',
    symbol: 'BIOF',
  },
  {
    value: 'BIP-PB',
    label: 'BIP-PB,  Brookfield Infrastructure Partners L.P.',
    name: 'Brookfield Infrastructure Partners L.P.',
    symbol: 'BIP-PB',
  },
  {
    value: 'BLMS',
    label: 'BLMS,  Bloomios, Inc.',
    name: 'Bloomios, Inc.',
    symbol: 'BLMS',
  },
  {
    value: 'BLTS',
    label: 'BLTS,  Bright Lights Acquisition Corp.',
    name: 'Bright Lights Acquisition Corp.',
    symbol: 'BLTS',
  },
  {
    value: 'BLUECOAST.NS',
    label: 'BLUECOAST.NS,  Blue Coast Hotels Limited',
    name: 'Blue Coast Hotels Limited',
    symbol: 'BLUECOAST.NS',
  },
  {
    value: 'BML-PG',
    label: 'BML-PG,  Bank of America Corporation',
    name: 'Bank of America Corporation',
    symbol: 'BML-PG',
  },
  {
    value: 'BML-PH',
    label: 'BML-PH,  Bank of America Corporation',
    name: 'Bank of America Corporation',
    symbol: 'BML-PH',
  },
  {
    value: 'BML-PJ',
    label: 'BML-PJ,  Bank of America Corporation',
    name: 'Bank of America Corporation',
    symbol: 'BML-PJ',
  },
  {
    value: 'BML-PL',
    label: 'BML-PL,  Bank of America Corporation',
    name: 'Bank of America Corporation',
    symbol: 'BML-PL',
  },
  {
    value: 'BOH-PA',
    label: 'BOH-PA,  Bank of Hawaii Corporation',
    name: 'Bank of Hawaii Corporation',
    symbol: 'BOH-PA',
  },
  {
    value: 'BOPO',
    label: 'BOPO,  BioPower Operations Corporation',
    name: 'BioPower Operations Corporation',
    symbol: 'BOPO',
  },
  {
    value: 'BOTY',
    label: 'BOTY,  Lingerie Fighting Championships, Inc.',
    name: 'Lingerie Fighting Championships, Inc.',
    symbol: 'BOTY',
  },
  {
    value: 'BRBL',
    label: 'BRBL,  BrewBilt Brewing Company',
    name: 'BrewBilt Brewing Company',
    symbol: 'BRBL',
  },
  {
    value: 'BREZ',
    label: 'BREZ,  Breeze Holdings Acquisition Corp.',
    name: 'Breeze Holdings Acquisition Corp.',
    symbol: 'BREZ',
  },
  {
    value: 'BREZR',
    label: 'BREZR,  Breeze Holdings Acquisition Corp.',
    name: 'Breeze Holdings Acquisition Corp.',
    symbol: 'BREZR',
  },
  {
    value: 'BRLL',
    label: 'BRLL,  Barrel Energy, Inc.',
    name: 'Barrel Energy, Inc.',
    symbol: 'BRLL',
  },
  {
    value: 'BSX-PA',
    label: 'BSX-PA,  Boston Scientific Corporation',
    name: 'Boston Scientific Corporation',
    symbol: 'BSX-PA',
  },
  {
    value: 'BTEGF',
    label: 'BTEGF,  Baytex Energy Corp.',
    name: 'Baytex Energy Corp.',
    symbol: 'BTEGF',
  },
  {
    value: 'BTZI',
    label: 'BTZI,  BOTS, Inc.',
    name: 'BOTS, Inc.',
    symbol: 'BTZI',
  },
  {
    value: 'BWBBP',
    label: 'BWBBP,  Bridgewater Bancshares, Inc.',
    name: 'Bridgewater Bancshares, Inc.',
    symbol: 'BWBBP',
  },
  {
    value: 'BW-PA',
    label: 'BW-PA,  Babcock & Wilcox Enterprises, Inc.',
    name: 'Babcock & Wilcox Enterprises, Inc.',
    symbol: 'BW-PA',
  },
  {
    value: 'BXXY',
    label: 'BXXY,  Boxxy Inc.',
    name: 'Boxxy Inc.',
    symbol: 'BXXY',
  },
  {
    value: 'BYLG',
    label: 'BYLG,  Bylog Group Corp.',
    name: 'Bylog Group Corp.',
    symbol: 'BYLG',
  },
  {
    value: 'CATC.L',
    label: 'CATC.L,  CATCo Reinsurance Opportunities Fund Ltd.',
    name: 'CATCo Reinsurance Opportunities Fund Ltd.',
    symbol: 'CATC.L',
  },
  {
    value: 'CBGL',
    label: 'CBGL,  Cannabis Global, Inc.',
    name: 'Cannabis Global, Inc.',
    symbol: 'CBGL',
  },
  {
    value: 'CCOB',
    label: 'CCOB,  Century Cobalt Corp.',
    name: 'Century Cobalt Corp.',
    symbol: 'CCOB',
  },
  {
    value: 'CDR-PB',
    label: 'CDR-PB,  Cedar Realty Trust, Inc.',
    name: 'Cedar Realty Trust, Inc.',
    symbol: 'CDR-PB',
  },
  {
    value: 'CDU.LS',
    label: 'CDU.LS,  Conduril - Engenharia, S.A.',
    name: 'Conduril - Engenharia, S.A.',
    symbol: 'CDU.LS',
  },
  {
    value: 'CDZIP',
    label: 'CDZIP,  Cadiz Inc.',
    name: 'Cadiz Inc.',
    symbol: 'CDZIP',
  },
  {
    value: 'CEF.TO',
    label: 'CEF.TO,  Sprott Physical Gold and Silver Trust',
    name: 'Sprott Physical Gold and Silver Trust',
    symbol: 'CEF.TO',
  },
  {
    value: 'CELUW',
    label: 'CELUW,  Celularity Inc.',
    name: 'Celularity Inc.',
    symbol: 'CELUW',
  },
  {
    value: 'CENBF',
    label: 'CENBF,  CEN Biotech, Inc.',
    name: 'CEN Biotech, Inc.',
    symbol: 'CENBF',
  },
  {
    value: 'CFG-PD',
    label: 'CFG-PD,  Citizens Financial Group, Inc.',
    name: 'Citizens Financial Group, Inc.',
    symbol: 'CFG-PD',
  },
  {
    value: 'CFI.PA',
    label: 'CFI.PA,  CFI-Compagnie Foncière Internationale',
    name: 'CFI-Compagnie Foncière Internationale',
    symbol: 'CFI.PA',
  },
  {
    value: 'CFIVU',
    label: 'CFIVU,  CF Acquisition Corp. IV',
    name: 'CF Acquisition Corp. IV',
    symbol: 'CFIVU',
  },
  {
    value: 'CFR-PB',
    label: 'CFR-PB,  Cullen/Frost Bankers, Inc.',
    name: 'Cullen/Frost Bankers, Inc.',
    symbol: 'CFR-PB',
  },
  {
    value: 'CGI.L',
    label: 'CGI.L,  Canadian General Investments, Limited',
    name: 'Canadian General Investments, Limited',
    symbol: 'CGI.L',
  },
  {
    value: 'CGSI',
    label: 'CGSI,  CGS International, Inc.',
    name: 'CGS International, Inc.',
    symbol: 'CGSI',
  },
  {
    value: 'CHAA',
    label: 'CHAA,  Catcha Investment Corp',
    name: 'Catcha Investment Corp',
    symbol: 'CHAA',
  },
  {
    value: 'CHAA-UN',
    label: 'CHAA-UN,  Catcha Investment Corp',
    name: 'Catcha Investment Corp',
    symbol: 'CHAA-UN',
  },
  {
    value: 'CHMI-PA',
    label: 'CHMI-PA,  Cherry Hill Mortgage Investment Corporation',
    name: 'Cherry Hill Mortgage Investment Corporation',
    symbol: 'CHMI-PA',
  },
  {
    value: 'CHMI-PB',
    label: 'CHMI-PB,  Cherry Hill Mortgage Investment Corporation',
    name: 'Cherry Hill Mortgage Investment Corporation',
    symbol: 'CHMI-PB',
  },
  {
    value: 'CHTH',
    label: 'CHTH,  CNL Healthcare Properties, Inc.',
    name: 'CNL Healthcare Properties, Inc.',
    symbol: 'CHTH',
  },
  {
    value: 'CIM-PA',
    label: 'CIM-PA,  Chimera Investment Corporation',
    name: 'Chimera Investment Corporation',
    symbol: 'CIM-PA',
  },
  {
    value: 'CIM-PB',
    label: 'CIM-PB,  Chimera Investment Corporation',
    name: 'Chimera Investment Corporation',
    symbol: 'CIM-PB',
  },
  {
    value: 'CIM-PC',
    label: 'CIM-PC,  Chimera Investment Corporation',
    name: 'Chimera Investment Corporation',
    symbol: 'CIM-PC',
  },
  {
    value: 'CIM-PD',
    label: 'CIM-PD,  Chimera Investment Corporation',
    name: 'Chimera Investment Corporation',
    symbol: 'CIM-PD',
  },
  {
    value: 'CION',
    label: 'CION,  CION Investment Corporation',
    name: 'CION Investment Corporation',
    symbol: 'CION',
  },
  {
    value: 'CIO-PA',
    label: 'CIO-PA,  City Office REIT, Inc.',
    name: 'City Office REIT, Inc.',
    symbol: 'CIO-PA',
  },
  {
    value: 'CLAS',
    label: 'CLAS,  Class Acceleration Corp.',
    name: 'Class Acceleration Corp.',
    symbol: 'CLAS',
  },
  {
    value: 'CLAS-UN',
    label: 'CLAS-UN,  Class Acceleration Corp.',
    name: 'Class Acceleration Corp.',
    symbol: 'CLAS-UN',
  },
  {
    value: 'CLDT-PA',
    label: 'CLDT-PA,  Chatham Lodging Trust',
    name: 'Chatham Lodging Trust',
    symbol: 'CLDT-PA',
  },
  {
    value: 'CLIM',
    label: 'CLIM,  Climate Real Impact Solutions II Acquisition Corporation',
    name: 'Climate Real Impact Solutions II Acquisition Corporation',
    symbol: 'CLIM',
  },
  {
    value: 'CLIM-UN',
    label: 'CLIM-UN,  Climate Real Impact Solutions II Acquisition Corporation',
    name: 'Climate Real Impact Solutions II Acquisition Corporation',
    symbol: 'CLIM-UN',
  },
  {
    value: 'CLRMU',
    label: 'CLRMU,  Clarim Acquisition Corp.',
    name: 'Clarim Acquisition Corp.',
    symbol: 'CLRMU',
  },
  {
    value: 'CLUBQ',
    label: 'CLUBQ,  Town Sports International Holdings, Inc.',
    name: 'Town Sports International Holdings, Inc.',
    symbol: 'CLUBQ',
  },
  {
    value: 'CLVRW',
    label: 'CLVRW,  Clever Leaves Holdings Inc.',
    name: 'Clever Leaves Holdings Inc.',
    symbol: 'CLVRW',
  },
  {
    value: 'CLVT-PA',
    label: 'CLVT-PA,  Clarivate Plc',
    name: 'Clarivate Plc',
    symbol: 'CLVT-PA',
  },
  {
    value: 'CMS-PC',
    label: 'CMS-PC,  CMS Energy Corporation',
    name: 'CMS Energy Corporation',
    symbol: 'CMS-PC',
  },
  {
    value: 'CNLHO',
    label: 'CNLHO,  The Connecticut Light and Power Company',
    name: 'The Connecticut Light and Power Company',
    symbol: 'CNLHO',
  },
  {
    value: 'CNLHP',
    label: 'CNLHP,  The Connecticut Light and Power Company',
    name: 'The Connecticut Light and Power Company',
    symbol: 'CNLHP',
  },
  {
    value: 'CNLPL',
    label: 'CNLPL,  The Connecticut Light and Power Company',
    name: 'The Connecticut Light and Power Company',
    symbol: 'CNLPL',
  },
  {
    value: 'CNLPM',
    label: 'CNLPM,  The Connecticut Light and Power Company',
    name: 'The Connecticut Light and Power Company',
    symbol: 'CNLPM',
  },
  {
    value: 'CNLTL',
    label: 'CNLTL,  The Connecticut Light and Power Company',
    name: 'The Connecticut Light and Power Company',
    symbol: 'CNLTL',
  },
  {
    value: 'CNLTN',
    label: 'CNLTN,  The Connecticut Light and Power Company',
    name: 'The Connecticut Light and Power Company',
    symbol: 'CNLTN',
  },
  {
    value: 'CNOBP',
    label: 'CNOBP,  ConnectOne Bancorp, Inc.',
    name: 'ConnectOne Bancorp, Inc.',
    symbol: 'CNOBP',
  },
  {
    value: 'CNPWP',
    label: 'CNPWP,  The Connecticut Light and Power Company',
    name: 'The Connecticut Light and Power Company',
    symbol: 'CNPWP',
  },
  {
    value: 'CNTHN',
    label: 'CNTHN,  The Connecticut Light and Power Company',
    name: 'The Connecticut Light and Power Company',
    symbol: 'CNTHN',
  },
  {
    value: 'CNTHO',
    label: 'CNTHO,  The Connecticut Light and Power Company',
    name: 'The Connecticut Light and Power Company',
    symbol: 'CNTHO',
  },
  {
    value: 'CNTHP',
    label: 'CNTHP,  The Connecticut Light and Power Company',
    name: 'The Connecticut Light and Power Company',
    symbol: 'CNTHP',
  },
  {
    value: 'CODI-PA',
    label: 'CODI-PA,  Compass Diversified',
    name: 'Compass Diversified',
    symbol: 'CODI-PA',
  },
  {
    value: 'CODI-PB',
    label: 'CODI-PB,  Compass Diversified',
    name: 'Compass Diversified',
    symbol: 'CODI-PB',
  },
  {
    value: 'CODQL',
    label: 'CODQL,  Coronado Global Resources Inc.',
    name: 'Coronado Global Resources Inc.',
    symbol: 'CODQL',
  },
  {
    value: 'COEP',
    label: 'COEP,  Coeptis Therapeutics Holdings, Inc.',
    name: 'Coeptis Therapeutics Holdings, Inc.',
    symbol: 'COEP',
  },
  {
    value: 'COF-PJ',
    label: 'COF-PJ,  Capital One Financial Corporation',
    name: 'Capital One Financial Corporation',
    symbol: 'COF-PJ',
  },
  {
    value: 'COF-PK',
    label: 'COF-PK,  Capital One Financial Corporation',
    name: 'Capital One Financial Corporation',
    symbol: 'COF-PK',
  },
  {
    value: 'COF-PL',
    label: 'COF-PL,  Capital One Financial Corporation',
    name: 'Capital One Financial Corporation',
    symbol: 'COF-PL',
  },
  {
    value: 'COF-PN',
    label: 'COF-PN,  Capital One Financial Corporation',
    name: 'Capital One Financial Corporation',
    symbol: 'COF-PN',
  },
  {
    value: 'CORG',
    label: 'CORG,  Cordia Corporation',
    name: 'Cordia Corporation',
    symbol: 'CORG',
  },
  {
    value: 'CORR-PA',
    label: 'CORR-PA,  CorEnergy Infrastructure Trust, Inc.',
    name: 'CorEnergy Infrastructure Trust, Inc.',
    symbol: 'CORR-PA',
  },
  {
    value: 'COSG',
    label: 'COSG,  Cosmos Group Holdings Inc.',
    name: 'Cosmos Group Holdings Inc.',
    symbol: 'COSG',
  },
  {
    value: 'CPA.LS',
    label: 'CPA.LS,  COPAM - Companhia Portuguesa de Amidos, S.A.',
    name: 'COPAM - Companhia Portuguesa de Amidos, S.A.',
    symbol: 'CPA.LS',
  },
  {
    value: 'C-PJ',
    label: 'C-PJ,  Citigroup Inc.',
    name: 'Citigroup Inc.',
    symbol: 'C-PJ',
  },
  {
    value: 'C-PK',
    label: 'C-PK,  Citigroup Inc.',
    name: 'Citigroup Inc.',
    symbol: 'C-PK',
  },
  {
    value: 'CPMV',
    label: 'CPMV,  Mosaic ImmunoEngineering Inc.',
    name: 'Mosaic ImmunoEngineering Inc.',
    symbol: 'CPMV',
  },
  {
    value: 'CRDV',
    label: 'CRDV,  Community Redevelopment Inc.',
    name: 'Community Redevelopment Inc.',
    symbol: 'CRDV',
  },
  {
    value: 'CRHC-UN',
    label: 'CRHC-UN,  Cohn Robbins Holdings Corp.',
    name: 'Cohn Robbins Holdings Corp.',
    symbol: 'CRHC-UN',
  },
  {
    value: 'CRKR',
    label: 'CRKR,  Creek Road Miners, Inc.',
    name: 'Creek Road Miners, Inc.',
    symbol: 'CRKR',
  },
  {
    value: 'CRU',
    label: 'CRU,  Crucible Acquisition Corporation',
    name: 'Crucible Acquisition Corporation',
    symbol: 'CRU',
  },
  {
    value: 'CRU-UN',
    label: 'CRU-UN,  Crucible Acquisition Corporation',
    name: 'Crucible Acquisition Corporation',
    symbol: 'CRU-UN',
  },
  {
    value: 'CSR-PC',
    label: 'CSR-PC,  Centerspace',
    name: 'Centerspace',
    symbol: 'CSR-PC',
  },
  {
    value: 'CTA-PA',
    label: 'CTA-PA,  E. I. du Pont de Nemours and Company',
    name: 'E. I. du Pont de Nemours and Company',
    symbol: 'CTA-PA',
  },
  {
    value: 'CTGL',
    label: 'CTGL,  Citrine Global Corp.',
    name: 'Citrine Global Corp.',
    symbol: 'CTGL',
  },
  {
    value: 'CTLPP',
    label: 'CTLPP,  Cantaloupe, Inc.',
    name: 'Cantaloupe, Inc.',
    symbol: 'CTLPP',
  },
  {
    value: 'CTO-PA',
    label: 'CTO-PA,  CTO Realty Growth, Inc.',
    name: 'CTO Realty Growth, Inc.',
    symbol: 'CTO-PA',
  },
  {
    value: 'CUBI-PE',
    label: 'CUBI-PE,  Customers Bancorp, Inc.',
    name: 'Customers Bancorp, Inc.',
    symbol: 'CUBI-PE',
  },
  {
    value: 'CUBS',
    label: 'CUBS,  Asian Growth Cubs ETF',
    name: 'Asian Growth Cubs ETF',
    symbol: 'CUBS',
  },
  {
    value: 'CUKPF',
    label: 'CUKPF,  Carnival Corporation & plc',
    name: 'Carnival Corporation & plc',
    symbol: 'CUKPF',
  },
  {
    value: 'CULL',
    label: 'CULL,  Cullman Bancorp, Inc.',
    name: 'Cullman Bancorp, Inc.',
    symbol: 'CULL',
  },
  {
    value: 'CYCA',
    label: 'CYCA,  Cytta Corp.',
    name: 'Cytta Corp.',
    symbol: 'CYCA',
  },
  {
    value: 'DBRG-PH',
    label: 'DBRG-PH,  DigitalBridge Group, Inc.',
    name: 'DigitalBridge Group, Inc.',
    symbol: 'DBRG-PH',
  },
  {
    value: 'DBRG-PI',
    label: 'DBRG-PI,  DigitalBridge Group, Inc.',
    name: 'DigitalBridge Group, Inc.',
    symbol: 'DBRG-PI',
  },
  {
    value: 'DBRG-PJ',
    label: 'DBRG-PJ,  DigitalBridge Group, Inc.',
    name: 'DigitalBridge Group, Inc.',
    symbol: 'DBRG-PJ',
  },
  {
    value: 'DCAC',
    label: 'DCAC,  Daniels Corporate Advisory Company, Inc.',
    name: 'Daniels Corporate Advisory Company, Inc.',
    symbol: 'DCAC',
  },
  {
    value: 'DHCC',
    label: 'DHCC,  Diamondhead Casino Corporation',
    name: 'Diamondhead Casino Corporation',
    symbol: 'DHCC',
  },
  {
    value: 'DIGAF',
    label: 'DIGAF,  Digatrade Financial Corp.',
    name: 'Digatrade Financial Corp.',
    symbol: 'DIGAF',
  },
  {
    value: 'DLR-PJ',
    label: 'DLR-PJ,  Digital Realty Trust, Inc.',
    name: 'Digital Realty Trust, Inc.',
    symbol: 'DLR-PJ',
  },
  {
    value: 'DLR-PK',
    label: 'DLR-PK,  Digital Realty Trust, Inc.',
    name: 'Digital Realty Trust, Inc.',
    symbol: 'DLR-PK',
  },
  {
    value: 'DP4B.DE',
    label: 'DP4B.DE,  A.P. Møller - Mærsk A/S',
    name: 'A.P. Møller - Mærsk A/S',
    symbol: 'DP4B.DE',
  },
  {
    value: 'DPSI',
    label: 'DPSI,  Decisionpoint Systems, Inc.',
    name: 'Decisionpoint Systems, Inc.',
    symbol: 'DPSI',
  },
  {
    value: 'DPSM',
    label: 'DPSM,  3D Pioneer Systems Inc.',
    name: '3D Pioneer Systems Inc.',
    symbol: 'DPSM',
  },
  {
    value: 'DQ7A.IR',
    label: 'DQ7A.IR,  Donegal Investment Group plc',
    name: 'Donegal Investment Group plc',
    symbol: 'DQ7A.IR',
  },
  {
    value: 'DRFS',
    label: 'DRFS,  Dr. Foods, Inc.',
    name: 'Dr. Foods, Inc.',
    symbol: 'DRFS',
  },
  {
    value: 'DS-PB',
    label: 'DS-PB,  Drive Shack Inc.',
    name: 'Drive Shack Inc.',
    symbol: 'DS-PB',
  },
  {
    value: 'DS-PC',
    label: 'DS-PC,  Drive Shack Inc.',
    name: 'Drive Shack Inc.',
    symbol: 'DS-PC',
  },
  {
    value: 'DS-PD',
    label: 'DS-PD,  Drive Shack Inc.',
    name: 'Drive Shack Inc.',
    symbol: 'DS-PD',
  },
  {
    value: 'DUSYF',
    label: 'DUSYF,  Duesenberg Technologies Inc.',
    name: 'Duesenberg Technologies Inc.',
    symbol: 'DUSYF',
  },
  {
    value: 'DVLP',
    label: 'DVLP,  Golden Developing Solutions, Inc.',
    name: 'Golden Developing Solutions, Inc.',
    symbol: 'DVLP',
  },
  {
    value: 'DX-PC',
    label: 'DX-PC,  Dynex Capital, Inc.',
    name: 'Dynex Capital, Inc.',
    symbol: 'DX-PC',
  },
  {
    value: 'EAPH',
    label: 'EAPH,  Parallel Industries Inc.',
    name: 'Parallel Industries Inc.',
    symbol: 'EAPH',
  },
  {
    value: 'EBBNF',
    label: 'EBBNF,  Enbridge Inc.',
    name: 'Enbridge Inc.',
    symbol: 'EBBNF',
  },
  {
    value: 'EBGEF',
    label: 'EBGEF,  Enbridge Inc.',
    name: 'Enbridge Inc.',
    symbol: 'EBGEF',
  },
  {
    value: 'ECOX',
    label: 'ECOX,  Eco Innovation Group, Inc.',
    name: 'Eco Innovation Group, Inc.',
    symbol: 'ECOX',
  },
  {
    value: 'ECTM',
    label: 'ECTM,  ECA Marcellus Trust I',
    name: 'ECA Marcellus Trust I',
    symbol: 'ECTM',
  },
  {
    value: 'EDXC',
    label: 'EDXC,  Endexx Corporation',
    name: 'Endexx Corporation',
    symbol: 'EDXC',
  },
  {
    value: 'EEII.SW',
    label: 'EEII.SW,  EEII AG',
    name: 'EEII AG',
    symbol: 'EEII.SW',
  },
  {
    value: 'EFSCP',
    label: 'EFSCP,  Enterprise Financial Services Corp',
    name: 'Enterprise Financial Services Corp',
    symbol: 'EFSCP',
  },
  {
    value: 'EGY.L',
    label: 'EGY.L,  VAALCO Energy, Inc.',
    name: 'VAALCO Energy, Inc.',
    symbol: 'EGY.L',
  },
  {
    value: 'EHVVF',
    label: 'EHVVF,  EHAVE, Inc.',
    name: 'EHAVE, Inc.',
    symbol: 'EHVVF',
  },
  {
    value: 'EMAX',
    label: 'EMAX,  Ecomax, Inc.',
    name: 'Ecomax, Inc.',
    symbol: 'EMAX',
  },
  {
    value: 'EMED',
    label: 'EMED,  Electromedical Technologies, Inc.',
    name: 'Electromedical Technologies, Inc.',
    symbol: 'EMED',
  },
  {
    value: 'EMHTF',
    label: 'EMHTF,  Emerald Health Therapeutics, Inc.',
    name: 'Emerald Health Therapeutics, Inc.',
    symbol: 'EMHTF',
  },
  {
    value: 'EPR-PC',
    label: 'EPR-PC,  EPR Properties',
    name: 'EPR Properties',
    symbol: 'EPR-PC',
  },
  {
    value: 'EPR-PE',
    label: 'EPR-PE,  EPR Properties',
    name: 'EPR Properties',
    symbol: 'EPR-PE',
  },
  {
    value: 'EPR-PG',
    label: 'EPR-PG,  EPR Properties',
    name: 'EPR Properties',
    symbol: 'EPR-PG',
  },
  {
    value: 'EPWCF',
    label: 'EPWCF,  Empower Clinics Inc.',
    name: 'Empower Clinics Inc.',
    symbol: 'EPWCF',
  },
  {
    value: 'EPWR',
    label: 'EPWR,  Empowerment & Inclusion Capital I Corp.',
    name: 'Empowerment & Inclusion Capital I Corp.',
    symbol: 'EPWR',
  },
  {
    value: 'EQHA',
    label: 'EQHA,  EQ Health Acquisition Corp.',
    name: 'EQ Health Acquisition Corp.',
    symbol: 'EQHA',
  },
  {
    value: 'EQHA-UN',
    label: 'EQHA-UN,  EQ Health Acquisition Corp.',
    name: 'EQ Health Acquisition Corp.',
    symbol: 'EQHA-UN',
  },
  {
    value: 'EQH-PC',
    label: 'EQH-PC,  Equitable Holdings, Inc.',
    name: 'Equitable Holdings, Inc.',
    symbol: 'EQH-PC',
  },
  {
    value: 'ESNC',
    label: 'ESNC,  EnSync, Inc.',
    name: 'EnSync, Inc.',
    symbol: 'ESNC',
  },
  {
    value: 'ET-PC',
    label: 'ET-PC,  Energy Transfer LP',
    name: 'Energy Transfer LP',
    symbol: 'ET-PC',
  },
  {
    value: 'ET-PD',
    label: 'ET-PD,  Energy Transfer LP',
    name: 'Energy Transfer LP',
    symbol: 'ET-PD',
  },
  {
    value: 'ET-PE',
    label: 'ET-PE,  Energy Transfer LP',
    name: 'Energy Transfer LP',
    symbol: 'ET-PE',
  },
  {
    value: 'ETST',
    label: 'ETST,  Earth Science Tech, Inc.',
    name: 'Earth Science Tech, Inc.',
    symbol: 'ETST',
  },
  {
    value: 'EVLVW',
    label: 'EVLVW,  Evolv Technologies Holdings, Inc.',
    name: 'Evolv Technologies Holdings, Inc.',
    symbol: 'EVLVW',
  },
  {
    value: 'FCELB',
    label: 'FCELB,  FuelCell Energy, Inc.',
    name: 'FuelCell Energy, Inc.',
    symbol: 'FCELB',
  },
  {
    value: 'FCTI',
    label: 'FCTI,  Fact, Inc.',
    name: 'Fact, Inc.',
    symbol: 'FCTI',
  },
  {
    value: 'FDOC',
    label: 'FDOC,  Fuel Doctor Holdings, Inc.',
    name: 'Fuel Doctor Holdings, Inc.',
    symbol: 'FDOC',
  },
  {
    value: 'FECOF',
    label: 'FECOF,  FEC Resources Inc.',
    name: 'FEC Resources Inc.',
    symbol: 'FECOF',
  },
  {
    value: 'FEMY',
    label: 'FEMY,  Femasys Inc.',
    name: 'Femasys Inc.',
    symbol: 'FEMY',
  },
  {
    value: 'FGFPP',
    label: 'FGFPP,  FG Financial Group, Inc.',
    name: 'FG Financial Group, Inc.',
    symbol: 'FGFPP',
  },
  {
    value: 'FHAI',
    label: 'FHAI,  Microalliance Group Inc.',
    name: 'Microalliance Group Inc.',
    symbol: 'FHAI',
  },
  {
    value: 'FHLD',
    label: 'FHLD,  Freedom Holdings, Inc.',
    name: 'Freedom Holdings, Inc.',
    symbol: 'FHLD',
  },
  {
    value: 'FHN-PF',
    label: 'FHN-PF,  First Horizon Corporation',
    name: 'First Horizon Corporation',
    symbol: 'FHN-PF',
  },
  {
    value: 'FJHL',
    label: 'FJHL,  Fovea Jewelry Holdings, Ltd',
    name: 'Fovea Jewelry Holdings, Ltd',
    symbol: 'FJHL',
  },
  {
    value: 'FLAC',
    label: 'FLAC,  Frazier Lifesciences Acquisition Corporation',
    name: 'Frazier Lifesciences Acquisition Corporation',
    symbol: 'FLAC',
  },
  {
    value: 'FLEXD.LS',
    label: 'FLEXD.LS,  Flexdeal Simfe S.A.',
    name: 'Flexdeal Simfe S.A.',
    symbol: 'FLEXD.LS',
  },
  {
    value: 'FMCCG',
    label: 'FMCCG,  Federal Home Loan Mortgage Corporation',
    name: 'Federal Home Loan Mortgage Corporation',
    symbol: 'FMCCG',
  },
  {
    value: 'FMCCH',
    label: 'FMCCH,  Federal Home Loan Mortgage Corporation',
    name: 'Federal Home Loan Mortgage Corporation',
    symbol: 'FMCCH',
  },
  {
    value: 'FMCCI',
    label: 'FMCCI,  Federal Home Loan Mortgage Corporation',
    name: 'Federal Home Loan Mortgage Corporation',
    symbol: 'FMCCI',
  },
  {
    value: 'FMCCJ',
    label: 'FMCCJ,  Federal Home Loan Mortgage Corporation',
    name: 'Federal Home Loan Mortgage Corporation',
    symbol: 'FMCCJ',
  },
  {
    value: 'FMCCK',
    label: 'FMCCK,  Federal Home Loan Mortgage Corporation',
    name: 'Federal Home Loan Mortgage Corporation',
    symbol: 'FMCCK',
  },
  {
    value: 'FMCCL',
    label: 'FMCCL,  Federal Home Loan Mortgage Corporation',
    name: 'Federal Home Loan Mortgage Corporation',
    symbol: 'FMCCL',
  },
  {
    value: 'FMCCM',
    label: 'FMCCM,  Federal Home Loan Mortgage Corporation',
    name: 'Federal Home Loan Mortgage Corporation',
    symbol: 'FMCCM',
  },
  {
    value: 'FMCCN',
    label: 'FMCCN,  Federal Home Loan Mortgage Corporation',
    name: 'Federal Home Loan Mortgage Corporation',
    symbol: 'FMCCN',
  },
  {
    value: 'FMCCO',
    label: 'FMCCO,  Federal Home Loan Mortgage Corporation',
    name: 'Federal Home Loan Mortgage Corporation',
    symbol: 'FMCCO',
  },
  {
    value: 'FMCCP',
    label: 'FMCCP,  Federal Home Loan Mortgage Corporation',
    name: 'Federal Home Loan Mortgage Corporation',
    symbol: 'FMCCP',
  },
  {
    value: 'FMCCS',
    label: 'FMCCS,  Federal Home Loan Mortgage Corporation',
    name: 'Federal Home Loan Mortgage Corporation',
    symbol: 'FMCCS',
  },
  {
    value: 'FMCCT',
    label: 'FMCCT,  Federal Home Loan Mortgage Corporation',
    name: 'Federal Home Loan Mortgage Corporation',
    symbol: 'FMCCT',
  },
  {
    value: 'FMCKI',
    label: 'FMCKI,  Federal Home Loan Mortgage Corporation',
    name: 'Federal Home Loan Mortgage Corporation',
    symbol: 'FMCKI',
  },
  {
    value: 'FMCKJ',
    label: 'FMCKJ,  Federal Home Loan Mortgage Corporation',
    name: 'Federal Home Loan Mortgage Corporation',
    symbol: 'FMCKJ',
  },
  {
    value: 'FMCKL',
    label: 'FMCKL,  Federal Home Loan Mortgage Corporation',
    name: 'Federal Home Loan Mortgage Corporation',
    symbol: 'FMCKL',
  },
  {
    value: 'FMCKM',
    label: 'FMCKM,  Federal Home Loan Mortgage Corporation',
    name: 'Federal Home Loan Mortgage Corporation',
    symbol: 'FMCKM',
  },
  {
    value: 'FMCKN',
    label: 'FMCKN,  Federal Home Loan Mortgage Corporation',
    name: 'Federal Home Loan Mortgage Corporation',
    symbol: 'FMCKN',
  },
  {
    value: 'FMCKO',
    label: 'FMCKO,  Federal Home Loan Mortgage Corporation',
    name: 'Federal Home Loan Mortgage Corporation',
    symbol: 'FMCKO',
  },
  {
    value: 'FMCKP',
    label: 'FMCKP,  Federal Home Loan Mortgage Corporation',
    name: 'Federal Home Loan Mortgage Corporation',
    symbol: 'FMCKP',
  },
  {
    value: 'FMHS',
    label: 'FMHS,  Farmhouse, Inc',
    name: 'Farmhouse, Inc',
    symbol: 'FMHS',
  },
  {
    value: 'FNB-PE',
    label: 'FNB-PE,  F.N.B. Corporation',
    name: 'F.N.B. Corporation',
    symbol: 'FNB-PE',
  },
  {
    value: 'FNMAG',
    label: 'FNMAG,  Federal National Mortgage Association',
    name: 'Federal National Mortgage Association',
    symbol: 'FNMAG',
  },
  {
    value: 'FNMAH',
    label: 'FNMAH,  Federal National Mortgage Association',
    name: 'Federal National Mortgage Association',
    symbol: 'FNMAH',
  },
  {
    value: 'FNMAI',
    label: 'FNMAI,  Federal National Mortgage Association',
    name: 'Federal National Mortgage Association',
    symbol: 'FNMAI',
  },
  {
    value: 'FNMAJ',
    label: 'FNMAJ,  Federal National Mortgage Association',
    name: 'Federal National Mortgage Association',
    symbol: 'FNMAJ',
  },
  {
    value: 'FNMAK',
    label: 'FNMAK,  Federal National Mortgage Association',
    name: 'Federal National Mortgage Association',
    symbol: 'FNMAK',
  },
  {
    value: 'FNMAL',
    label: 'FNMAL,  Federal National Mortgage Association',
    name: 'Federal National Mortgage Association',
    symbol: 'FNMAL',
  },
  {
    value: 'FNMAM',
    label: 'FNMAM,  Federal National Mortgage Association',
    name: 'Federal National Mortgage Association',
    symbol: 'FNMAM',
  },
  {
    value: 'FNMAN',
    label: 'FNMAN,  Federal National Mortgage Association',
    name: 'Federal National Mortgage Association',
    symbol: 'FNMAN',
  },
  {
    value: 'FNMAO',
    label: 'FNMAO,  Federal National Mortgage Association',
    name: 'Federal National Mortgage Association',
    symbol: 'FNMAO',
  },
  {
    value: 'FNMAS',
    label: 'FNMAS,  Federal National Mortgage Association',
    name: 'Federal National Mortgage Association',
    symbol: 'FNMAS',
  },
  {
    value: 'FNMAT',
    label: 'FNMAT,  Federal National Mortgage Association',
    name: 'Federal National Mortgage Association',
    symbol: 'FNMAT',
  },
  {
    value: 'FNMFM',
    label: 'FNMFM,  Federal National Mortgage Association',
    name: 'Federal National Mortgage Association',
    symbol: 'FNMFM',
  },
  {
    value: 'FNMFN',
    label: 'FNMFN,  Federal National Mortgage Association',
    name: 'Federal National Mortgage Association',
    symbol: 'FNMFN',
  },
  {
    value: 'FNMFO',
    label: 'FNMFO,  Federal National Mortgage Association',
    name: 'Federal National Mortgage Association',
    symbol: 'FNMFO',
  },
  {
    value: 'FOMC',
    label: 'FOMC,  FOMO Corp.',
    name: 'FOMO Corp.',
    symbol: 'FOMC',
  },
  {
    value: 'FORE.PA',
    label: 'FORE.PA,  La Forestière Equatoriale SA',
    name: 'La Forestière Equatoriale SA',
    symbol: 'FORE.PA',
  },
  {
    value: 'FORZ',
    label: 'FORZ,  Forza Innovations Inc.',
    name: 'Forza Innovations Inc.',
    symbol: 'FORZ',
  },
  {
    value: 'FOXW',
    label: 'FOXW,  FoxWayne Enterprises Acquisition Corp.',
    name: 'FoxWayne Enterprises Acquisition Corp.',
    symbol: 'FOXW',
  },
  {
    value: 'FPAC-UN',
    label: 'FPAC-UN,  Far Peak Acquisition Corporation',
    name: 'Far Peak Acquisition Corporation',
    symbol: 'FPAC-UN',
  },
  {
    value: 'FREGP',
    label: 'FREGP,  Federal Home Loan Mortgage Corporation',
    name: 'Federal Home Loan Mortgage Corporation',
    symbol: 'FREGP',
  },
  {
    value: 'FRTG',
    label: 'FRTG,  Frontera Group, Inc.',
    name: 'Frontera Group, Inc.',
    symbol: 'FRTG',
  },
  {
    value: 'FTCVU',
    label: 'FTCVU,  Fintech Acquisition Corp. V',
    name: 'Fintech Acquisition Corp. V',
    symbol: 'FTCVU',
  },
  {
    value: 'FURY',
    label: 'FURY,  Fury Gold Mines Limited',
    name: 'Fury Gold Mines Limited',
    symbol: 'FURY',
  },
  {
    value: 'FVT',
    label: 'FVT,  Fortress Value Acquisition Corp. III',
    name: 'Fortress Value Acquisition Corp. III',
    symbol: 'FVT',
  },
  {
    value: 'FWAV',
    label: 'FWAV,  Fourth Wave Energy, Inc.',
    name: 'Fourth Wave Energy, Inc.',
    symbol: 'FWAV',
  },
  {
    value: 'GCEH',
    label: 'GCEH,  Global Clean Energy Holdings, Inc.',
    name: 'Global Clean Energy Holdings, Inc.',
    symbol: 'GCEH',
  },
  {
    value: 'GDLC',
    label: 'GDLC,  Grayscale Digital Large Cap Fund LLC',
    name: 'Grayscale Digital Large Cap Fund LLC',
    symbol: 'GDLC',
  },
  {
    value: 'GDMK',
    label: 'GDMK,  Global Diversified Marketing Group, Inc.',
    name: 'Global Diversified Marketing Group, Inc.',
    symbol: 'GDMK',
  },
  {
    value: 'GDRZF',
    label: 'GDRZF,  Gold Reserve Inc.',
    name: 'Gold Reserve Inc.',
    symbol: 'GDRZF',
  },
  {
    value: 'GEGP',
    label: 'GEGP,  Gold Entertainment Group, Inc.',
    name: 'Gold Entertainment Group, Inc.',
    symbol: 'GEGP',
  },
  {
    value: 'GFOO',
    label: 'GFOO,  Genufood Energy Enzymes Corp.',
    name: 'Genufood Energy Enzymes Corp.',
    symbol: 'GFOO',
  },
  {
    value: 'GFTX',
    label: 'GFTX,  Global Fiber Technologies, Inc.',
    name: 'Global Fiber Technologies, Inc.',
    symbol: 'GFTX',
  },
  {
    value: 'GFX-UN',
    label: 'GFX-UN,  Golden Falcon Acquisition Corp.',
    name: 'Golden Falcon Acquisition Corp.',
    symbol: 'GFX-UN',
  },
  {
    value: 'GGT-PG',
    label: 'GGT-PG,  The Gabelli Multimedia Trust Inc.',
    name: 'The Gabelli Multimedia Trust Inc.',
    symbol: 'GGT-PG',
  },
  {
    value: 'GHST',
    label: 'GHST,  GHST World Inc.',
    name: 'GHST World Inc.',
    symbol: 'GHST',
  },
  {
    value: 'GJCU',
    label: 'GJCU,  GJ Culture Group US, Inc.',
    name: 'GJ Culture Group US, Inc.',
    symbol: 'GJCU',
  },
  {
    value: 'GKIN',
    label: 'GKIN,  Guskin Gold Corp.',
    name: 'Guskin Gold Corp.',
    symbol: 'GKIN',
  },
  {
    value: 'GLP-PB',
    label: 'GLP-PB,  Global Partners LP',
    name: 'Global Partners LP',
    symbol: 'GLP-PB',
  },
  {
    value: 'GMGT',
    label: 'GMGT,  Gaming Technologies, Inc.',
    name: 'Gaming Technologies, Inc.',
    symbol: 'GMGT',
  },
  {
    value: 'GNE-PA',
    label: 'GNE-PA,  Genie Energy Ltd.',
    name: 'Genie Energy Ltd.',
    symbol: 'GNE-PA',
  },
  {
    value: 'GNL-PA',
    label: 'GNL-PA,  Global Net Lease, Inc.',
    name: 'Global Net Lease, Inc.',
    symbol: 'GNL-PA',
  },
  {
    value: 'GPOX',
    label: 'GPOX,  GPO Plus, Inc.',
    name: 'GPO Plus, Inc.',
    symbol: 'GPOX',
  },
  {
    value: 'GRCY',
    label: 'GRCY,  Greencity Acquisition Corporation',
    name: 'Greencity Acquisition Corporation',
    symbol: 'GRCY',
  },
  {
    value: 'GROM',
    label: 'GROM,  Grom Social Enterprises, Inc.',
    name: 'Grom Social Enterprises, Inc.',
    symbol: 'GROM',
  },
  {
    value: 'GRP.IR',
    label: 'GRP.IR,  Greencoat Renewables PLC',
    name: 'Greencoat Renewables PLC',
    symbol: 'GRP.IR',
  },
  {
    value: 'GRWC',
    label: 'GRWC,  Grow Capital, Inc.',
    name: 'Grow Capital, Inc.',
    symbol: 'GRWC',
  },
  {
    value: 'GS-PA',
    label: 'GS-PA,  The Goldman Sachs Group, Inc.',
    name: 'The Goldman Sachs Group, Inc.',
    symbol: 'GS-PA',
  },
  {
    value: 'GS-PD',
    label: 'GS-PD,  The Goldman Sachs Group, Inc.',
    name: 'The Goldman Sachs Group, Inc.',
    symbol: 'GS-PD',
  },
  {
    value: 'GS-PJ',
    label: 'GS-PJ,  The Goldman Sachs Group, Inc.',
    name: 'The Goldman Sachs Group, Inc.',
    symbol: 'GS-PJ',
  },
  {
    value: 'GS-PK',
    label: 'GS-PK,  The Goldman Sachs Group, Inc.',
    name: 'The Goldman Sachs Group, Inc.',
    symbol: 'GS-PK',
  },
  {
    value: 'GSTC',
    label: 'GSTC,  GlobeStar Therapeutics Corporation',
    name: 'GlobeStar Therapeutics Corporation',
    symbol: 'GSTC',
  },
  {
    value: 'GTE.L',
    label: 'GTE.L,  Gran Tierra Energy Inc.',
    name: 'Gran Tierra Energy Inc.',
    symbol: 'GTE.L',
  },
  {
    value: 'GWSO',
    label: 'GWSO,  Global Warming Solutions, Inc.',
    name: 'Global Warming Solutions, Inc.',
    symbol: 'GWSO',
  },
  {
    value: 'GYQ.IR',
    label: 'GYQ.IR,  FD Technologies Plc',
    name: 'FD Technologies Plc',
    symbol: 'GYQ.IR',
  },
  {
    value: 'GZIC',
    label: 'GZIC,  GZ6G Technologies Corp.',
    name: 'GZ6G Technologies Corp.',
    symbol: 'GZIC',
  },
  {
    value: 'HBANM',
    label: 'HBANM,  Huntington Bancshares Incorporated',
    name: 'Huntington Bancshares Incorporated',
    symbol: 'HBANM',
  },
  {
    value: 'HBANP',
    label: 'HBANP,  Huntington Bancshares Incorporated',
    name: 'Huntington Bancshares Incorporated',
    symbol: 'HBANP',
  },
  {
    value: 'HCDIP',
    label: 'HCDIP,  Harbor Custom Development, Inc.',
    name: 'Harbor Custom Development, Inc.',
    symbol: 'HCDIP',
  },
  {
    value: 'HGYN',
    label: 'HGYN,  Hong Yuan Holding Group',
    name: 'Hong Yuan Holding Group',
    symbol: 'HGYN',
  },
  {
    value: 'HJGP',
    label: 'HJGP,  Hanjiao Group, Inc.',
    name: 'Hanjiao Group, Inc.',
    symbol: 'HJGP',
  },
  {
    value: 'HLBZ',
    label: 'HLBZ,  Helbiz, Inc.',
    name: 'Helbiz, Inc.',
    symbol: 'HLBZ',
  },
  {
    value: 'HLTC',
    label: 'HLTC,  Healthcare Trust, Inc.',
    name: 'Healthcare Trust, Inc.',
    symbol: 'HLTC',
  },
  {
    value: 'HLTT',
    label: 'HLTT,  Healthtech Solutions Inc.',
    name: 'Healthtech Solutions Inc.',
    symbol: 'HLTT',
  },
  {
    value: 'HMNY',
    label: 'HMNY,  Helios and Matheson Analytics Inc.',
    name: 'Helios and Matheson Analytics Inc.',
    symbol: 'HMNY',
  },
  {
    value: 'HPP-PC',
    label: 'HPP-PC,  Hudson Pacific Properties, Inc.',
    name: 'Hudson Pacific Properties, Inc.',
    symbol: 'HPP-PC',
  },
  {
    value: 'HPX-UN',
    label: 'HPX-UN,  HPX Corp.',
    name: 'HPX Corp.',
    symbol: 'HPX-UN',
  },
  {
    value: 'HSTA',
    label: 'HSTA,  Hestia Insight Inc.',
    name: 'Hestia Insight Inc.',
    symbol: 'HSTA',
  },
  {
    value: 'HTFB',
    label: 'HTFB,  Horizon Technology Finance Corporation',
    name: 'Horizon Technology Finance Corporation',
    symbol: 'HTFB',
  },
  {
    value: 'HTPA',
    label: 'HTPA,  Highland Transcend Partners I Corp.',
    name: 'Highland Transcend Partners I Corp.',
    symbol: 'HTPA',
  },
  {
    value: 'HTPA-UN',
    label: 'HTPA-UN,  Highland Transcend Partners I Corp.',
    name: 'Highland Transcend Partners I Corp.',
    symbol: 'HTPA-UN',
  },
  {
    value: 'HT-PC',
    label: 'HT-PC,  Hersha Hospitality Trust',
    name: 'Hersha Hospitality Trust',
    symbol: 'HT-PC',
  },
  {
    value: 'HT-PD',
    label: 'HT-PD,  Hersha Hospitality Trust',
    name: 'Hersha Hospitality Trust',
    symbol: 'HT-PD',
  },
  {
    value: 'HT-PE',
    label: 'HT-PE,  Hersha Hospitality Trust',
    name: 'Hersha Hospitality Trust',
    symbol: 'HT-PE',
  },
  {
    value: 'HWM-P',
    label: 'HWM-P,  Howmet Aerospace Inc.',
    name: 'Howmet Aerospace Inc.',
    symbol: 'HWM-P',
  },
  {
    value: 'HYEX',
    label: 'HYEX,  Healthy Extracts Inc.',
    name: 'Healthy Extracts Inc.',
    symbol: 'HYEX',
  },
  {
    value: 'IGNYU',
    label: 'IGNYU,  Ignyte Acquisition Corp.',
    name: 'Ignyte Acquisition Corp.',
    symbol: 'IGNYU',
  },
  {
    value: 'IIII',
    label: 'IIII,  INSU Acquisition Corp. III',
    name: 'INSU Acquisition Corp. III',
    symbol: 'IIII',
  },
  {
    value: 'IIIIU',
    label: 'IIIIU,  INSU Acquisition Corp. III',
    name: 'INSU Acquisition Corp. III',
    symbol: 'IIIIU',
  },
  {
    value: 'IMHC',
    label: 'IMHC,  Imperalis Holding Corp.',
    name: 'Imperalis Holding Corp.',
    symbol: 'IMHC',
  },
  {
    value: 'IMPHO',
    label: 'IMPHO,  Impac Mortgage Holdings, Inc.',
    name: 'Impac Mortgage Holdings, Inc.',
    symbol: 'IMPHO',
  },
  {
    value: 'IMPHP',
    label: 'IMPHP,  Impac Mortgage Holdings, Inc.',
    name: 'Impac Mortgage Holdings, Inc.',
    symbol: 'IMPHP',
  },
  {
    value: 'IMTH',
    label: 'IMTH,  Innovative MedTech Inc.',
    name: 'Innovative MedTech Inc.',
    symbol: 'IMTH',
  },
  {
    value: 'INN-PE',
    label: 'INN-PE,  Summit Hotel Properties, Inc.',
    name: 'Summit Hotel Properties, Inc.',
    symbol: 'INN-PE',
  },
  {
    value: 'INN-PF',
    label: 'INN-PF,  Summit Hotel Properties, Inc.',
    name: 'Summit Hotel Properties, Inc.',
    symbol: 'INN-PF',
  },
  {
    value: 'INRE',
    label: 'INRE,  Inland Real Estate Income Trust, Inc.',
    name: 'Inland Real Estate Income Trust, Inc.',
    symbol: 'INRE',
  },
  {
    value: 'IPA',
    label: 'IPA,  ImmunoPrecise Antibodies Ltd.',
    name: 'ImmunoPrecise Antibodies Ltd.',
    symbol: 'IPA',
  },
  {
    value: 'IPWLK',
    label: 'IPWLK,  AES Indiana',
    name: 'AES Indiana',
    symbol: 'IPWLK',
  },
  {
    value: 'ITXT.PA',
    label: 'ITXT.PA,  Intexa S.A.',
    name: 'Intexa S.A.',
    symbol: 'ITXT.PA',
  },
  {
    value: 'IVDA',
    label: 'IVDA,  Iveda Solutions, Inc.',
    name: 'Iveda Solutions, Inc.',
    symbol: 'IVDA',
  },
  {
    value: 'IVR-PB',
    label: 'IVR-PB,  Invesco Mortgage Capital Inc.',
    name: 'Invesco Mortgage Capital Inc.',
    symbol: 'IVR-PB',
  },
  {
    value: 'IVT',
    label: 'IVT,  InvenTrust Properties Corp.',
    name: 'InvenTrust Properties Corp.',
    symbol: 'IVT',
  },
  {
    value: 'IWAL',
    label: 'IWAL,  iWallet Corporation',
    name: 'iWallet Corporation',
    symbol: 'IWAL',
  },
  {
    value: 'IWSH',
    label: "IWSH,  Wright Investors' Service Holdings, Inc.",
    name: "Wright Investors' Service Holdings, Inc.",
    symbol: 'IWSH',
  },
  {
    value: 'JPM-PC',
    label: 'JPM-PC,  JPMorgan Chase & Co.',
    name: 'JPMorgan Chase & Co.',
    symbol: 'JPM-PC',
  },
  {
    value: 'JPM-PD',
    label: 'JPM-PD,  JPMorgan Chase & Co.',
    name: 'JPMorgan Chase & Co.',
    symbol: 'JPM-PD',
  },
  {
    value: 'JPM-PJ',
    label: 'JPM-PJ,  JPMorgan Chase & Co.',
    name: 'JPMorgan Chase & Co.',
    symbol: 'JPM-PJ',
  },
  {
    value: 'JPM-PL',
    label: 'JPM-PL,  JPMorgan Chase & Co.',
    name: 'JPMorgan Chase & Co.',
    symbol: 'JPM-PL',
  },
  {
    value: 'JPM-PM',
    label: 'JPM-PM,  JPMorgan Chase & Co.',
    name: 'JPMorgan Chase & Co.',
    symbol: 'JPM-PM',
  },
  {
    value: 'JYAC',
    label: 'JYAC,  Jiya Acquisition Corp.',
    name: 'Jiya Acquisition Corp.',
    symbol: 'JYAC',
  },
  {
    value: 'KEY-PI',
    label: 'KEY-PI,  KeyCorp',
    name: 'KeyCorp',
    symbol: 'KEY-PI',
  },
  {
    value: 'KEY-PJ',
    label: 'KEY-PJ,  KeyCorp',
    name: 'KeyCorp',
    symbol: 'KEY-PJ',
  },
  {
    value: 'KEY-PK',
    label: 'KEY-PK,  KeyCorp',
    name: 'KeyCorp',
    symbol: 'KEY-PK',
  },
  {
    value: 'KFSYF',
    label: 'KFSYF,  Kingsway Financial Services Inc.',
    name: 'Kingsway Financial Services Inc.',
    symbol: 'KFSYF',
  },
  {
    value: 'KGKG',
    label: 'KGKG,  Kona Gold Beverage, Inc.',
    name: 'Kona Gold Beverage, Inc.',
    symbol: 'KGKG',
  },
  {
    value: 'KINZU',
    label: 'KINZU,  KINS Technology Group Inc.',
    name: 'KINS Technology Group Inc.',
    symbol: 'KINZU',
  },
  {
    value: 'KKR-PC',
    label: 'KKR-PC,  KKR & Co. Inc.',
    name: 'KKR & Co. Inc.',
    symbol: 'KKR-PC',
  },
  {
    value: 'KNIT',
    label: 'KNIT,  Kinetic Group Inc.',
    name: 'Kinetic Group Inc.',
    symbol: 'KNIT',
  },
  {
    value: 'KREF-PA',
    label: 'KREF-PA,  KKR Real Estate Finance Trust Inc.',
    name: 'KKR Real Estate Finance Trust Inc.',
    symbol: 'KREF-PA',
  },
  {
    value: 'KTEC',
    label: 'KTEC,  KraneShares Hang Seng TECH Index ETF',
    name: 'KraneShares Hang Seng TECH Index ETF',
    symbol: 'KTEC',
  },
  {
    value: 'LEXX',
    label: 'LEXX,  Lexaria Bioscience Corp.',
    name: 'Lexaria Bioscience Corp.',
    symbol: 'LEXX',
  },
  {
    value: 'LFACU',
    label: 'LFACU,  LF Capital Acquisition Corp. II',
    name: 'LF Capital Acquisition Corp. II',
    symbol: 'LFACU',
  },
  {
    value: 'LFT-PA',
    label: 'LFT-PA,  Lument Finance Trust, Inc.',
    name: 'Lument Finance Trust, Inc.',
    symbol: 'LFT-PA',
  },
  {
    value: 'LHC-UN',
    label: 'LHC-UN,  Leo Holdings Corp. II',
    name: 'Leo Holdings Corp. II',
    symbol: 'LHC-UN',
  },
  {
    value: 'LILAB',
    label: 'LILAB,  Liberty Latin America Ltd.',
    name: 'Liberty Latin America Ltd.',
    symbol: 'LILAB',
  },
  {
    value: 'LKNCY',
    label: 'LKNCY,  Luckin Coffee Inc.',
    name: 'Luckin Coffee Inc.',
    symbol: 'LKNCY',
  },
  {
    value: 'LMDCF',
    label: 'LMDCF,  Everybody Loves Languages Corp.',
    name: 'Everybody Loves Languages Corp.',
    symbol: 'LMDCF',
  },
  {
    value: 'LNBY',
    label: 'LNBY,  Lanbay Inc',
    name: 'Lanbay Inc',
    symbol: 'LNBY',
  },
  {
    value: 'LNDZF',
    label: 'LNDZF,  Salona Global Medical Device Corporation',
    name: 'Salona Global Medical Device Corporation',
    symbol: 'LNDZF',
  },
  {
    value: 'LONCF',
    label: 'LONCF,  Loncor Gold Inc.',
    name: 'Loncor Gold Inc.',
    symbol: 'LONCF',
  },
  {
    value: 'LRFC',
    label: 'LRFC,  Logan Ridge Finance Corporation',
    name: 'Logan Ridge Finance Corporation',
    symbol: 'LRFC',
  },
  {
    value: 'LTES',
    label: 'LTES,  Leet Technology Inc.',
    name: 'Leet Technology Inc.',
    symbol: 'LTES',
  },
  {
    value: 'LVOXU',
    label: 'LVOXU,  LiveVox Holdings, Inc.',
    name: 'LiveVox Holdings, Inc.',
    symbol: 'LVOXU',
  },
  {
    value: 'LVRLF',
    label: 'LVRLF,  CordovaCann Corp.',
    name: 'CordovaCann Corp.',
    symbol: 'LVRLF',
  },
  {
    value: 'LYLT',
    label: 'LYLT,  Loyalty Ventures Inc.',
    name: 'Loyalty Ventures Inc.',
    symbol: 'LYLT',
  },
  {
    value: 'MAPT',
    label: 'MAPT,  Maptelligent, Inc.',
    name: 'Maptelligent, Inc.',
    symbol: 'MAPT',
  },
  {
    value: 'MAW.L',
    label: 'MAW.L,  Maruwa Co., Ltd.',
    name: 'Maruwa Co., Ltd.',
    symbol: 'MAW.L',
  },
  {
    value: 'MCP.LS',
    label: 'MCP.LS,  Grupo Media Capital, SGPS, S.A.',
    name: 'Grupo Media Capital, SGPS, S.A.',
    symbol: 'MCP.LS',
  },
  {
    value: 'MDH',
    label: 'MDH,  MDH Acquisition Corp.',
    name: 'MDH Acquisition Corp.',
    symbol: 'MDH',
  },
  {
    value: 'MDH-UN',
    label: 'MDH-UN,  MDH Acquisition Corp.',
    name: 'MDH Acquisition Corp.',
    symbol: 'MDH-UN',
  },
  {
    value: 'MDNA',
    label: 'MDNA,  Medicenna Therapeutics Corp.',
    name: 'Medicenna Therapeutics Corp.',
    symbol: 'MDNA',
  },
  {
    value: 'MDWK',
    label: 'MDWK,  MDwerks, Inc.',
    name: 'MDwerks, Inc.',
    symbol: 'MDWK',
  },
  {
    value: 'MET-PA',
    label: 'MET-PA,  MetLife, Inc.',
    name: 'MetLife, Inc.',
    symbol: 'MET-PA',
  },
  {
    value: 'MET-PE',
    label: 'MET-PE,  MetLife, Inc.',
    name: 'MetLife, Inc.',
    symbol: 'MET-PE',
  },
  {
    value: 'MFA-PB',
    label: 'MFA-PB,  MFA Financial, Inc.',
    name: 'MFA Financial, Inc.',
    symbol: 'MFA-PB',
  },
  {
    value: 'MFA-PC',
    label: 'MFA-PC,  MFA Financial, Inc.',
    name: 'MFA Financial, Inc.',
    symbol: 'MFA-PC',
  },
  {
    value: 'MH-PA',
    label: 'MH-PA,  Maiden Holdings, Ltd.',
    name: 'Maiden Holdings, Ltd.',
    symbol: 'MH-PA',
  },
  {
    value: 'MH-PC',
    label: 'MH-PC,  Maiden Holdings, Ltd.',
    name: 'Maiden Holdings, Ltd.',
    symbol: 'MH-PC',
  },
  {
    value: 'MH-PD',
    label: 'MH-PD,  Maiden Holdings, Ltd.',
    name: 'Maiden Holdings, Ltd.',
    symbol: 'MH-PD',
  },
  {
    value: 'MILC',
    label: 'MILC,  Millennium Sustainable Ventures Corp.',
    name: 'Millennium Sustainable Ventures Corp.',
    symbol: 'MILC',
  },
  {
    value: 'MIT',
    label: 'MIT,  Mason Industrial Technology, Inc.',
    name: 'Mason Industrial Technology, Inc.',
    symbol: 'MIT',
  },
  {
    value: 'MITT-PA',
    label: 'MITT-PA,  AG Mortgage Investment Trust, Inc.',
    name: 'AG Mortgage Investment Trust, Inc.',
    symbol: 'MITT-PA',
  },
  {
    value: 'MITT-PB',
    label: 'MITT-PB,  AG Mortgage Investment Trust, Inc.',
    name: 'AG Mortgage Investment Trust, Inc.',
    symbol: 'MITT-PB',
  },
  {
    value: 'MITT-PC',
    label: 'MITT-PC,  AG Mortgage Investment Trust, Inc.',
    name: 'AG Mortgage Investment Trust, Inc.',
    symbol: 'MITT-PC',
  },
  {
    value: 'MIT-UN',
    label: 'MIT-UN,  Mason Industrial Technology, Inc.',
    name: 'Mason Industrial Technology, Inc.',
    symbol: 'MIT-UN',
  },
  {
    value: 'MLCAC.PA',
    label: 'MLCAC.PA,  Lombard & Medot SA',
    name: 'Lombard & Medot SA',
    symbol: 'MLCAC.PA',
  },
  {
    value: 'MLCFD.PA',
    label:
      'MLCFD.PA,  Compagnie de Chemins de Fer Départementaux Société Anonyme',
    name: 'Compagnie de Chemins de Fer Départementaux Société Anonyme',
    symbol: 'MLCFD.PA',
  },
  {
    value: 'MLCFM.PA',
    label: 'MLCFM.PA,  CFM Indosuez Wealth Management SA',
    name: 'CFM Indosuez Wealth Management SA',
    symbol: 'MLCFM.PA',
  },
  {
    value: 'MLCT',
    label: 'MLCT,  Malachite Innovations, Inc.',
    name: 'Malachite Innovations, Inc.',
    symbol: 'MLCT',
  },
  {
    value: 'MLDYN.PA',
    label: 'MLDYN.PA,  Dynafond Société Anonyme',
    name: 'Dynafond Société Anonyme',
    symbol: 'MLDYN.PA',
  },
  {
    value: 'MLIRF.PA',
    label: 'MLIRF.PA,  Innovative-RFK S.p.A.',
    name: 'Innovative-RFK S.p.A.',
    symbol: 'MLIRF.PA',
  },
  {
    value: 'MLTRA.PA',
    label: 'MLTRA.PA,  Compagnie des Tramways de Rouen',
    name: 'Compagnie des Tramways de Rouen',
    symbol: 'MLTRA.PA',
  },
  {
    value: 'MLYF',
    label: 'MLYF,  Western Magnesium Corporation',
    name: 'Western Magnesium Corporation',
    symbol: 'MLYF',
  },
  {
    value: 'MMTIF',
    label: 'MMTIF,  Micromem Technologies Inc.',
    name: 'Micromem Technologies Inc.',
    symbol: 'MMTIF',
  },
  {
    value: 'MPLS.L',
    label: 'MPLS.L,  Marble Point Loan Financing Limited',
    name: 'Marble Point Loan Financing Limited',
    symbol: 'MPLS.L',
  },
  {
    value: 'MS-PA',
    label: 'MS-PA,  Morgan Stanley',
    name: 'Morgan Stanley',
    symbol: 'MS-PA',
  },
  {
    value: 'MS-PE',
    label: 'MS-PE,  Morgan Stanley',
    name: 'Morgan Stanley',
    symbol: 'MS-PE',
  },
  {
    value: 'MS-PF',
    label: 'MS-PF,  Morgan Stanley',
    name: 'Morgan Stanley',
    symbol: 'MS-PF',
  },
  {
    value: 'MS-PI',
    label: 'MS-PI,  Morgan Stanley',
    name: 'Morgan Stanley',
    symbol: 'MS-PI',
  },
  {
    value: 'MS-PK',
    label: 'MS-PK,  Morgan Stanley',
    name: 'Morgan Stanley',
    symbol: 'MS-PK',
  },
  {
    value: 'MS-PL',
    label: 'MS-PL,  Morgan Stanley',
    name: 'Morgan Stanley',
    symbol: 'MS-PL',
  },
  {
    value: 'MSYN',
    label: 'MSYN,  MS Young Adventure Enterprise, Inc.',
    name: 'MS Young Adventure Enterprise, Inc.',
    symbol: 'MSYN',
  },
  {
    value: 'MTLK',
    label: 'MTLK,  Metalink Ltd.',
    name: 'Metalink Ltd.',
    symbol: 'MTLK',
  },
  {
    value: 'MTWD',
    label: 'MTWD,  Metwood, Inc.',
    name: 'Metwood, Inc.',
    symbol: 'MTWD',
  },
  {
    value: 'MVI.L',
    label: 'MVI.L,  Marwyn Value Investors Limited',
    name: 'Marwyn Value Investors Limited',
    symbol: 'MVI.L',
  },
  {
    value: 'NBLD',
    label: 'NBLD,  Nestbuilder.com Corp.',
    name: 'Nestbuilder.com Corp.',
    symbol: 'NBLD',
  },
  {
    value: 'NCPL',
    label: 'NCPL,  Netcapital Inc.',
    name: 'Netcapital Inc.',
    symbol: 'NCPL',
  },
  {
    value: 'NCRE',
    label: 'NCRE,  Colambda Technologies, Inc.',
    name: 'Colambda Technologies, Inc.',
    symbol: 'NCRE',
  },
  {
    value: 'NECA',
    label: 'NECA,  New America Energy Corp.',
    name: 'New America Energy Corp.',
    symbol: 'NECA',
  },
  {
    value: 'NEWH',
    label: 'NEWH,  NewHydrogen, Inc.',
    name: 'NewHydrogen, Inc.',
    symbol: 'NEWH',
  },
  {
    value: 'NEWP',
    label: 'NEWP,  New Pacific Metals Corp.',
    name: 'New Pacific Metals Corp.',
    symbol: 'NEWP',
  },
  {
    value: 'NHHS',
    label: 'NHHS,  NorthStar Healthcare Income, Inc.',
    name: 'NorthStar Healthcare Income, Inc.',
    symbol: 'NHHS',
  },
  {
    value: 'NHICW',
    label: 'NHICW,  NewHold Investment Corp. II',
    name: 'NewHold Investment Corp. II',
    symbol: 'NHICW',
  },
  {
    value: 'NHMD',
    label: 'NHMD,  NHMD Holdings, Inc.',
    name: 'NHMD Holdings, Inc.',
    symbol: 'NHMD',
  },
  {
    value: 'NIMC',
    label: 'NIMC,  NiSource Inc.',
    name: 'NiSource Inc.',
    symbol: 'NIMC',
  },
  {
    value: 'NIPNF',
    label: 'NIPNF,  NEC Corporation',
    name: 'NEC Corporation',
    symbol: 'NIPNF',
  },
  {
    value: 'NLBS',
    label: 'NLBS,  NutraLife BioSciences, Inc.',
    name: 'NutraLife BioSciences, Inc.',
    symbol: 'NLBS',
  },
  {
    value: 'NLY-PF',
    label: 'NLY-PF,  Annaly Capital Management, Inc.',
    name: 'Annaly Capital Management, Inc.',
    symbol: 'NLY-PF',
  },
  {
    value: 'NLY-PG',
    label: 'NLY-PG,  Annaly Capital Management, Inc.',
    name: 'Annaly Capital Management, Inc.',
    symbol: 'NLY-PG',
  },
  {
    value: 'NNAX',
    label: 'NNAX,  New Momentum Corporation',
    name: 'New Momentum Corporation',
    symbol: 'NNAX',
  },
  {
    value: 'NOACU',
    label: 'NOACU,  Natural Order Acquisition Corp.',
    name: 'Natural Order Acquisition Corp.',
    symbol: 'NOACU',
  },
  {
    value: 'NOTR',
    label: 'NOTR,  NOWTRANSIT INC',
    name: 'NOWTRANSIT INC',
    symbol: 'NOTR',
  },
  {
    value: 'NPHC',
    label: 'NPHC,  Nutra Pharma Corp.',
    name: 'Nutra Pharma Corp.',
    symbol: 'NPHC',
  },
  {
    value: 'NPTX',
    label: 'NPTX,  Neuropathix, Inc.',
    name: 'Neuropathix, Inc.',
    symbol: 'NPTX',
  },
  {
    value: 'NRAC',
    label: 'NRAC,  Noble Rock Acquisition Corporation',
    name: 'Noble Rock Acquisition Corporation',
    symbol: 'NRAC',
  },
  {
    value: 'NRACU',
    label: 'NRACU,  Noble Rock Acquisition Corporation',
    name: 'Noble Rock Acquisition Corporation',
    symbol: 'NRACU',
  },
  {
    value: 'NSARO',
    label: 'NSARO,  NSTAR Electric Company',
    name: 'NSTAR Electric Company',
    symbol: 'NSARO',
  },
  {
    value: 'NS-PB',
    label: 'NS-PB,  NuStar Energy L.P.',
    name: 'NuStar Energy L.P.',
    symbol: 'NS-PB',
  },
  {
    value: 'NSRCF',
    label: 'NSRCF,  NextSource Materials Inc.',
    name: 'NextSource Materials Inc.',
    symbol: 'NSRCF',
  },
  {
    value: 'NSTB-UN',
    label: 'NSTB-UN,  Northern Star Investment Corp. II',
    name: 'Northern Star Investment Corp. II',
    symbol: 'NSTB-UN',
  },
  {
    value: 'NSTC',
    label: 'NSTC,  Northern Star Investment Corp. III',
    name: 'Northern Star Investment Corp. III',
    symbol: 'NSTC',
  },
  {
    value: 'NSTC-UN',
    label: 'NSTC-UN,  Northern Star Investment Corp. III',
    name: 'Northern Star Investment Corp. III',
    symbol: 'NSTC-UN',
  },
  {
    value: 'NSTD-UN',
    label: 'NSTD-UN,  Northern Star Investment Corp. IV',
    name: 'Northern Star Investment Corp. IV',
    symbol: 'NSTD-UN',
  },
  {
    value: 'NUNZ',
    label: 'NUNZ,  Nunzia Pharmaceutical Company',
    name: 'Nunzia Pharmaceutical Company',
    symbol: 'NUNZ',
  },
  {
    value: 'NVGT',
    label: 'NVGT,  Novagant Corp.',
    name: 'Novagant Corp.',
    symbol: 'NVGT',
  },
  {
    value: 'NVNOW',
    label: 'NVNOW,  enVVeno Medical Corporation',
    name: 'enVVeno Medical Corporation',
    symbol: 'NVNOW',
  },
  {
    value: 'NXMH',
    label: 'NXMH,  Next Meats Holdings, Inc.',
    name: 'Next Meats Holdings, Inc.',
    symbol: 'NXMH',
  },
  {
    value: 'NXMR',
    label: 'NXMR,  NextMart, Inc.',
    name: 'NextMart, Inc.',
    symbol: 'NXMR',
  },
  {
    value: 'NYCB-PA',
    label: 'NYCB-PA,  New York Community Bancorp, Inc.',
    name: 'New York Community Bancorp, Inc.',
    symbol: 'NYCB-PA',
  },
  {
    value: 'NYMTL',
    label: 'NYMTL,  New York Mortgage Trust, Inc.',
    name: 'New York Mortgage Trust, Inc.',
    symbol: 'NYMTL',
  },
  {
    value: 'NYMTZ',
    label: 'NYMTZ,  New York Mortgage Trust, Inc.',
    name: 'New York Mortgage Trust, Inc.',
    symbol: 'NYMTZ',
  },
  {
    value: 'OBE',
    label: 'OBE,  Obsidian Energy Ltd.',
    name: 'Obsidian Energy Ltd.',
    symbol: 'OBE',
  },
  {
    value: 'OCEL',
    label: 'OCEL,  Organicell Regenerative Medicine, Inc.',
    name: 'Organicell Regenerative Medicine, Inc.',
    symbol: 'OCEL',
  },
  {
    value: 'OPA',
    label: 'OPA,  Magnum Opus Acquisition Limited',
    name: 'Magnum Opus Acquisition Limited',
    symbol: 'OPA',
  },
  {
    value: 'OPA-UN',
    label: 'OPA-UN,  Magnum Opus Acquisition Limited',
    name: 'Magnum Opus Acquisition Limited',
    symbol: 'OPA-UN',
  },
  {
    value: 'OSTO',
    label: 'OSTO,  Original Sixteen to One Mine, Inc.',
    name: 'Original Sixteen to One Mine, Inc.',
    symbol: 'OSTO',
  },
  {
    value: 'OSTRU',
    label: 'OSTRU,  Oyster Enterprises Acquisition Corp.',
    name: 'Oyster Enterprises Acquisition Corp.',
    symbol: 'OSTRU',
  },
  {
    value: 'OTLC',
    label: 'OTLC,  Oncotelic Therapeutics, Inc.',
    name: 'Oncotelic Therapeutics, Inc.',
    symbol: 'OTLC',
  },
  {
    value: 'PBAJ',
    label: 'PBAJ,  Petro USA, Inc.',
    name: 'Petro USA, Inc.',
    symbol: 'PBAJ',
  },
  {
    value: 'PBMLF',
    label: 'PBMLF,  Pacific Booker Minerals Inc.',
    name: 'Pacific Booker Minerals Inc.',
    symbol: 'PBMLF',
  },
  {
    value: 'PCG-PA',
    label: 'PCG-PA,  Pacific Gas and Electric Company',
    name: 'Pacific Gas and Electric Company',
    symbol: 'PCG-PA',
  },
  {
    value: 'PCG-PB',
    label: 'PCG-PB,  Pacific Gas and Electric Company',
    name: 'Pacific Gas and Electric Company',
    symbol: 'PCG-PB',
  },
  {
    value: 'PCG-PD',
    label: 'PCG-PD,  Pacific Gas and Electric Company',
    name: 'Pacific Gas and Electric Company',
    symbol: 'PCG-PD',
  },
  {
    value: 'PCG-PE',
    label: 'PCG-PE,  Pacific Gas and Electric Company',
    name: 'Pacific Gas and Electric Company',
    symbol: 'PCG-PE',
  },
  {
    value: 'PCG-PG',
    label: 'PCG-PG,  Pacific Gas and Electric Company',
    name: 'Pacific Gas and Electric Company',
    symbol: 'PCG-PG',
  },
  {
    value: 'PCG-PH',
    label: 'PCG-PH,  Pacific Gas and Electric Company',
    name: 'Pacific Gas and Electric Company',
    symbol: 'PCG-PH',
  },
  {
    value: 'PCPC',
    label: 'PCPC,  Periphas Capital Partnering Corporation',
    name: 'Periphas Capital Partnering Corporation',
    symbol: 'PCPC',
  },
  {
    value: 'PCPC-UN',
    label: 'PCPC-UN,  Periphas Capital Partnering Corporation',
    name: 'Periphas Capital Partnering Corporation',
    symbol: 'PCPC-UN',
  },
  {
    value: 'PDNLA',
    label: 'PDNLA,  Presidential Realty Corporation',
    name: 'Presidential Realty Corporation',
    symbol: 'PDNLA',
  },
  {
    value: 'PEB-PE',
    label: 'PEB-PE,  Pebblebrook Hotel Trust',
    name: 'Pebblebrook Hotel Trust',
    symbol: 'PEB-PE',
  },
  {
    value: 'PEB-PG',
    label: 'PEB-PG,  Pebblebrook Hotel Trust',
    name: 'Pebblebrook Hotel Trust',
    symbol: 'PEB-PG',
  },
  {
    value: 'PEB-PH',
    label: 'PEB-PH,  Pebblebrook Hotel Trust',
    name: 'Pebblebrook Hotel Trust',
    symbol: 'PEB-PH',
  },
  {
    value: 'PEI-PB',
    label: 'PEI-PB,  Pennsylvania Real Estate Investment Trust',
    name: 'Pennsylvania Real Estate Investment Trust',
    symbol: 'PEI-PB',
  },
  {
    value: 'PEI-PC',
    label: 'PEI-PC,  Pennsylvania Real Estate Investment Trust',
    name: 'Pennsylvania Real Estate Investment Trust',
    symbol: 'PEI-PC',
  },
  {
    value: 'PEI-PD',
    label: 'PEI-PD,  Pennsylvania Real Estate Investment Trust',
    name: 'Pennsylvania Real Estate Investment Trust',
    symbol: 'PEI-PD',
  },
  {
    value: 'PFXNL',
    label: 'PFXNL,  PhenixFIN Corporation',
    name: 'PhenixFIN Corporation',
    symbol: 'PFXNL',
  },
  {
    value: 'PHCI',
    label: 'PHCI,  Panamera Holdings Corporation',
    name: 'Panamera Holdings Corporation',
    symbol: 'PHCI',
  },
  {
    value: 'PHIC',
    label: 'PHIC,  Population Health Investment Co., Inc.',
    name: 'Population Health Investment Co., Inc.',
    symbol: 'PHIC',
  },
  {
    value: 'PHICU',
    label: 'PHICU,  Population Health Investment Co., Inc.',
    name: 'Population Health Investment Co., Inc.',
    symbol: 'PHICU',
  },
  {
    value: 'PHIL',
    label: 'PHIL,  Philux Global Group Inc.',
    name: 'Philux Global Group Inc.',
    symbol: 'PHIL',
  },
  {
    value: 'PHYS.TO',
    label: 'PHYS.TO,  Sprott Physical Gold Trust',
    name: 'Sprott Physical Gold Trust',
    symbol: 'PHYS.TO',
  },
  {
    value: 'PIAI-UN',
    label: 'PIAI-UN,  Prime Impact Acquisition I',
    name: 'Prime Impact Acquisition I',
    symbol: 'PIAI-UN',
  },
  {
    value: 'PICC',
    label: 'PICC,  Pivotal Investment Corporation III',
    name: 'Pivotal Investment Corporation III',
    symbol: 'PICC',
  },
  {
    value: 'PICC-UN',
    label: 'PICC-UN,  Pivotal Investment Corporation III',
    name: 'Pivotal Investment Corporation III',
    symbol: 'PICC-UN',
  },
  {
    value: 'PIIIW',
    label: 'PIIIW,  P3 Health Partners Inc.',
    name: 'P3 Health Partners Inc.',
    symbol: 'PIIIW',
  },
  {
    value: 'PLDGP',
    label: 'PLDGP,  Prologis, Inc.',
    name: 'Prologis, Inc.',
    symbol: 'PLDGP',
  },
  {
    value: 'PLLTL',
    label: 'PLLTL,  Piedmont Lithium Inc.',
    name: 'Piedmont Lithium Inc.',
    symbol: 'PLLTL',
  },
  {
    value: 'PLRTF',
    label: 'PLRTF,  Plymouth Rock Technologies Inc.',
    name: 'Plymouth Rock Technologies Inc.',
    symbol: 'PLRTF',
  },
  {
    value: 'PLYM-PA',
    label: 'PLYM-PA,  Plymouth Industrial REIT, Inc.',
    name: 'Plymouth Industrial REIT, Inc.',
    symbol: 'PLYM-PA',
  },
  {
    value: 'PMT-PA',
    label: 'PMT-PA,  PennyMac Mortgage Investment Trust',
    name: 'PennyMac Mortgage Investment Trust',
    symbol: 'PMT-PA',
  },
  {
    value: 'PMT-PC',
    label: 'PMT-PC,  PennyMac Mortgage Investment Trust',
    name: 'PennyMac Mortgage Investment Trust',
    symbol: 'PMT-PC',
  },
  {
    value: 'PNPL',
    label: 'PNPL,  Pineapple, Inc.',
    name: 'Pineapple, Inc.',
    symbol: 'PNPL',
  },
  {
    value: 'PNT',
    label: 'PNT,  POINT Biopharma Global Inc.',
    name: 'POINT Biopharma Global Inc.',
    symbol: 'PNT',
  },
  {
    value: 'PNTM-UN',
    label: 'PNTM-UN,  Pontem Corporation',
    name: 'Pontem Corporation',
    symbol: 'PNTM-UN',
  },
  {
    value: 'POWWP',
    label: 'POWWP,  AMMO, Inc.',
    name: 'AMMO, Inc.',
    symbol: 'POWWP',
  },
  {
    value: 'PPBT',
    label: 'PPBT,  Purple Biotech Ltd.',
    name: 'Purple Biotech Ltd.',
    symbol: 'PPBT',
  },
  {
    value: 'PPWLM',
    label: 'PPWLM,  PacifiCorp',
    name: 'PacifiCorp',
    symbol: 'PPWLM',
  },
  {
    value: 'PPWLO',
    label: 'PPWLO,  PacifiCorp',
    name: 'PacifiCorp',
    symbol: 'PPWLO',
  },
  {
    value: 'PRTHU',
    label: 'PRTHU,  Priority Technology Holdings, Inc.',
    name: 'Priority Technology Holdings, Inc.',
    symbol: 'PRTHU',
  },
  {
    value: 'PRTM',
    label: 'PRTM,  Sparx Holdings Group, Inc.',
    name: 'Sparx Holdings Group, Inc.',
    symbol: 'PRTM',
  },
  {
    value: 'PSA-PF',
    label: 'PSA-PF,  Public Storage',
    name: 'Public Storage',
    symbol: 'PSA-PF',
  },
  {
    value: 'PSA-PH',
    label: 'PSA-PH,  Public Storage',
    name: 'Public Storage',
    symbol: 'PSA-PH',
  },
  {
    value: 'PSA-PI',
    label: 'PSA-PI,  Public Storage',
    name: 'Public Storage',
    symbol: 'PSA-PI',
  },
  {
    value: 'PSA-PJ',
    label: 'PSA-PJ,  Public Storage',
    name: 'Public Storage',
    symbol: 'PSA-PJ',
  },
  {
    value: 'PSA-PK',
    label: 'PSA-PK,  Public Storage',
    name: 'Public Storage',
    symbol: 'PSA-PK',
  },
  {
    value: 'PSA-PL',
    label: 'PSA-PL,  Public Storage',
    name: 'Public Storage',
    symbol: 'PSA-PL',
  },
  {
    value: 'PSA-PM',
    label: 'PSA-PM,  Public Storage',
    name: 'Public Storage',
    symbol: 'PSA-PM',
  },
  {
    value: 'PSA-PN',
    label: 'PSA-PN,  Public Storage',
    name: 'Public Storage',
    symbol: 'PSA-PN',
  },
  {
    value: 'PSA-PO',
    label: 'PSA-PO,  Public Storage',
    name: 'Public Storage',
    symbol: 'PSA-PO',
  },
  {
    value: 'PSA-PP',
    label: 'PSA-PP,  Public Storage',
    name: 'Public Storage',
    symbol: 'PSA-PP',
  },
  {
    value: 'PSA-PQ',
    label: 'PSA-PQ,  Public Storage',
    name: 'Public Storage',
    symbol: 'PSA-PQ',
  },
  {
    value: 'PSB-PX',
    label: 'PSB-PX,  PS Business Parks, Inc.',
    name: 'PS Business Parks, Inc.',
    symbol: 'PSB-PX',
  },
  {
    value: 'PSB-PY',
    label: 'PSB-PY,  PS Business Parks, Inc.',
    name: 'PS Business Parks, Inc.',
    symbol: 'PSB-PY',
  },
  {
    value: 'PSEC-PA',
    label: 'PSEC-PA,  Prospect Capital Corporation',
    name: 'Prospect Capital Corporation',
    symbol: 'PSEC-PA',
  },
  {
    value: 'PSLV.TO',
    label: 'PSLV.TO,  Sprott Physical Silver Trust',
    name: 'Sprott Physical Silver Trust',
    symbol: 'PSLV.TO',
  },
  {
    value: 'PTNYF',
    label: 'PTNYF,  ParcelPal Logistics Inc.',
    name: 'ParcelPal Logistics Inc.',
    symbol: 'PTNYF',
  },
  {
    value: 'PU13.L',
    label: 'PU13.L,  Puma Vct 13 Plc',
    name: 'Puma Vct 13 Plc',
    symbol: 'PU13.L',
  },
  {
    value: 'PUGE',
    label: 'PUGE,  Puget Technologies, Inc.',
    name: 'Puget Technologies, Inc.',
    symbol: 'PUGE',
  },
  {
    value: 'PZOO',
    label: 'PZOO,  Pazoo, Inc.',
    name: 'Pazoo, Inc.',
    symbol: 'PZOO',
  },
  {
    value: 'QFTA',
    label: 'QFTA,  Quantum FinTech Acquisition Corporation',
    name: 'Quantum FinTech Acquisition Corporation',
    symbol: 'QFTA',
  },
  {
    value: 'QFTA-UN',
    label: 'QFTA-UN,  Quantum FinTech Acquisition Corporation',
    name: 'Quantum FinTech Acquisition Corporation',
    symbol: 'QFTA-UN',
  },
  {
    value: 'QIAN',
    label: 'QIAN,  Qiansui International Group Co. Ltd.',
    name: 'Qiansui International Group Co. Ltd.',
    symbol: 'QIAN',
  },
  {
    value: 'QSAM',
    label: 'QSAM,  QSAM Biosciences, Inc.',
    name: 'QSAM Biosciences, Inc.',
    symbol: 'QSAM',
  },
  {
    value: 'QURT',
    label: 'QURT,  Quarta-Rad, Inc.',
    name: 'Quarta-Rad, Inc.',
    symbol: 'QURT',
  },
  {
    value: 'RAKR',
    label: 'RAKR,  Rainmaker Worldwide Inc.',
    name: 'Rainmaker Worldwide Inc.',
    symbol: 'RAKR',
  },
  {
    value: 'RBSPF',
    label: 'RBSPF,  NatWest Group plc',
    name: 'NatWest Group plc',
    symbol: 'RBSPF',
  },
  {
    value: 'RCFA-UN',
    label: 'RCFA-UN,  RCF Acquisition Corp.',
    name: 'RCF Acquisition Corp.',
    symbol: 'RCFA-UN',
  },
  {
    value: 'RC-PC',
    label: 'RC-PC,  Ready Capital Corporation',
    name: 'Ready Capital Corporation',
    symbol: 'RC-PC',
  },
  {
    value: 'RC-PE',
    label: 'RC-PE,  Ready Capital Corporation',
    name: 'Ready Capital Corporation',
    symbol: 'RC-PE',
  },
  {
    value: 'RELT',
    label: 'RELT,  Reliant Holdings, Inc.',
    name: 'Reliant Holdings, Inc.',
    symbol: 'RELT',
  },
  {
    value: 'REXR-PB',
    label: 'REXR-PB,  Rexford Industrial Realty, Inc.',
    name: 'Rexford Industrial Realty, Inc.',
    symbol: 'REXR-PB',
  },
  {
    value: 'RF-PB',
    label: 'RF-PB,  Regions Financial Corporation',
    name: 'Regions Financial Corporation',
    symbol: 'RF-PB',
  },
  {
    value: 'RF-PC',
    label: 'RF-PC,  Regions Financial Corporation',
    name: 'Regions Financial Corporation',
    symbol: 'RF-PC',
  },
  {
    value: 'RF-PE',
    label: 'RF-PE,  Regions Financial Corporation',
    name: 'Regions Financial Corporation',
    symbol: 'RF-PE',
  },
  {
    value: 'RGBP',
    label: 'RGBP,  Regen BioPharma, Inc.',
    name: 'Regen BioPharma, Inc.',
    symbol: 'RGBP',
  },
  {
    value: 'RGBPP',
    label: 'RGBPP,  Regen BioPharma, Inc.',
    name: 'Regen BioPharma, Inc.',
    symbol: 'RGBPP',
  },
  {
    value: 'RGC',
    label: 'RGC,  Regencell Bioscience Holdings Limited',
    name: 'Regencell Bioscience Holdings Limited',
    symbol: 'RGC',
  },
  {
    value: 'RIII',
    label: 'RIII,  Renavotio, Inc.',
    name: 'Renavotio, Inc.',
    symbol: 'RIII',
  },
  {
    value: 'RIVU',
    label: 'RIVU,  Rivulet Media, Inc.',
    name: 'Rivulet Media, Inc.',
    symbol: 'RIVU',
  },
  {
    value: 'RLBD',
    label: 'RLBD,  Real Brands, Inc.',
    name: 'Real Brands, Inc.',
    symbol: 'RLBD',
  },
  {
    value: 'RLJ-PA',
    label: 'RLJ-PA,  RLJ Lodging Trust',
    name: 'RLJ Lodging Trust',
    symbol: 'RLJ-PA',
  },
  {
    value: 'RNR-PG',
    label: 'RNR-PG,  RenaissanceRe Holdings Ltd.',
    name: 'RenaissanceRe Holdings Ltd.',
    symbol: 'RNR-PG',
  },
  {
    value: 'RPT-PD',
    label: 'RPT-PD,  RPT Realty',
    name: 'RPT Realty',
    symbol: 'RPT-PD',
  },
  {
    value: 'RSCF',
    label: 'RSCF,  Reflect Scientific, Inc.',
    name: 'Reflect Scientific, Inc.',
    symbol: 'RSCF',
  },
  {
    value: 'RSMDF',
    label: 'RSMDF,  ResMed Inc.',
    name: 'ResMed Inc.',
    symbol: 'RSMDF',
  },
  {
    value: 'RSPI',
    label: 'RSPI,  RespireRx Pharmaceuticals Inc.',
    name: 'RespireRx Pharmaceuticals Inc.',
    symbol: 'RSPI',
  },
  {
    value: 'RTON',
    label: 'RTON,  Right On Brands, Inc.',
    name: 'Right On Brands, Inc.',
    symbol: 'RTON',
  },
  {
    value: 'RWBYF',
    label: 'RWBYF,  Red White & Bloom Brands Inc.',
    name: 'Red White & Bloom Brands Inc.',
    symbol: 'RWBYF',
  },
  {
    value: 'SACH-PA',
    label: 'SACH-PA,  Sachem Capital Corp.',
    name: 'Sachem Capital Corp.',
    symbol: 'SACH-PA',
  },
  {
    value: 'SAMAW',
    label: 'SAMAW,  Schultze Special Purpose Acquisition Corp. II',
    name: 'Schultze Special Purpose Acquisition Corp. II',
    symbol: 'SAMAW',
  },
  {
    value: 'SAML',
    label: 'SAML,  Samsara Luggage, Inc.',
    name: 'Samsara Luggage, Inc.',
    symbol: 'SAML',
  },
  {
    value: 'SB-PC',
    label: 'SB-PC,  Safe Bulkers, Inc.',
    name: 'Safe Bulkers, Inc.',
    symbol: 'SB-PC',
  },
  {
    value: 'SCE-PH',
    label: 'SCE-PH,  SCE Trust III',
    name: 'SCE Trust III',
    symbol: 'SCE-PH',
  },
  {
    value: 'SCHD.BR',
    label: "SCHD.BR,  Scheerders van Kerchove's Verenigde fabrieken nv",
    name: "Scheerders van Kerchove's Verenigde fabrieken nv",
    symbol: 'SCHD.BR',
  },
  {
    value: 'SCHW-PJ',
    label: 'SCHW-PJ,  The Charles Schwab Corporation',
    name: 'The Charles Schwab Corporation',
    symbol: 'SCHW-PJ',
  },
  {
    value: 'SCMAU',
    label: 'SCMAU,  Seaport Calibre Materials Acquisition Corp.',
    name: 'Seaport Calibre Materials Acquisition Corp.',
    symbol: 'SCMAU',
  },
  {
    value: 'SCRH',
    label: 'SCRH,  Scores Holding Company, Inc.',
    name: 'Scores Holding Company, Inc.',
    symbol: 'SCRH',
  },
  {
    value: 'SCWO',
    label: 'SCWO,  374Water, Inc.',
    name: '374Water, Inc.',
    symbol: 'SCWO',
  },
  {
    value: 'SCXLB',
    label: 'SCXLB,  The L.S. Starrett Company',
    name: 'The L.S. Starrett Company',
    symbol: 'SCXLB',
  },
  {
    value: 'SEZNL',
    label: 'SEZNL,  Sezzle Inc.',
    name: 'Sezzle Inc.',
    symbol: 'SEZNL',
  },
  {
    value: 'SF-PB',
    label: 'SF-PB,  Stifel Financial Corp.',
    name: 'Stifel Financial Corp.',
    symbol: 'SF-PB',
  },
  {
    value: 'SF-PD',
    label: 'SF-PD,  Stifel Financial Corp.',
    name: 'Stifel Financial Corp.',
    symbol: 'SF-PD',
  },
  {
    value: 'SGLDF',
    label: 'SGLDF,  Sabre Gold Mines Corp.',
    name: 'Sabre Gold Mines Corp.',
    symbol: 'SGLDF',
  },
  {
    value: 'SHO-PH',
    label: 'SHO-PH,  Sunstone Hotel Investors, Inc.',
    name: 'Sunstone Hotel Investors, Inc.',
    symbol: 'SHO-PH',
  },
  {
    value: 'SHO-PI',
    label: 'SHO-PI,  Sunstone Hotel Investors, Inc.',
    name: 'Sunstone Hotel Investors, Inc.',
    symbol: 'SHO-PI',
  },
  {
    value: 'SIAF',
    label: 'SIAF,  Sino Agro Food, Inc.',
    name: 'Sino Agro Food, Inc.',
    symbol: 'SIAF',
  },
  {
    value: 'SIFI',
    label: 'SIFI,  Harbor Scientific Alpha Income ETF',
    name: 'Harbor Scientific Alpha Income ETF',
    symbol: 'SIFI',
  },
  {
    value: 'SIGY',
    label: 'SIGY,  Sigyn Therapeutics, Inc.',
    name: 'Sigyn Therapeutics, Inc.',
    symbol: 'SIGY',
  },
  {
    value: 'SILO',
    label: 'SILO,  Silo Pharma, Inc.',
    name: 'Silo Pharma, Inc.',
    symbol: 'SILO',
  },
  {
    value: 'SING',
    label: 'SING,  SinglePoint Inc.',
    name: 'SinglePoint Inc.',
    symbol: 'SING',
  },
  {
    value: 'SI-PA',
    label: 'SI-PA,  Silvergate Capital Corporation',
    name: 'Silvergate Capital Corporation',
    symbol: 'SI-PA',
  },
  {
    value: 'SITC-PA',
    label: 'SITC-PA,  SITE Centers Corp.',
    name: 'SITE Centers Corp.',
    symbol: 'SITC-PA',
  },
  {
    value: 'SKFG',
    label: 'SKFG,  Stark Focus Group, Inc.',
    name: 'Stark Focus Group, Inc.',
    symbol: 'SKFG',
  },
  {
    value: 'SKYE',
    label: 'SKYE,  Skye Bioscience, Inc.',
    name: 'Skye Bioscience, Inc.',
    symbol: 'SKYE',
  },
  {
    value: 'SLDPW',
    label: 'SLDPW,  Solid Power, Inc.',
    name: 'Solid Power, Inc.',
    symbol: 'SLDPW',
  },
  {
    value: 'SLG-PI',
    label: 'SLG-PI,  SL Green Realty Corp.',
    name: 'SL Green Realty Corp.',
    symbol: 'SLG-PI',
  },
  {
    value: 'SNAX',
    label: 'SNAX,  Stryve Foods, Inc.',
    name: 'Stryve Foods, Inc.',
    symbol: 'SNAX',
  },
  {
    value: 'SNBH',
    label: 'SNBH,  Sentient Brands Holdings Inc.',
    name: 'Sentient Brands Holdings Inc.',
    symbol: 'SNBH',
  },
  {
    value: 'SNNAQ',
    label: 'SNNAQ,  Sienna Biopharmaceuticals, Inc.',
    name: 'Sienna Biopharmaceuticals, Inc.',
    symbol: 'SNNAQ',
  },
  {
    value: 'SNPX',
    label: 'SNPX,  Synaptogenix, Inc.',
    name: 'Synaptogenix, Inc.',
    symbol: 'SNPX',
  },
  {
    value: 'SNV-PE',
    label: 'SNV-PE,  Synovus Financial Corp.',
    name: 'Synovus Financial Corp.',
    symbol: 'SNV-PE',
  },
  {
    value: 'SODI',
    label: 'SODI,  Solitron Devices, Inc.',
    name: 'Solitron Devices, Inc.',
    symbol: 'SODI',
  },
  {
    value: 'SOLS',
    label: 'SOLS,  Sollensys Corp.',
    name: 'Sollensys Corp.',
    symbol: 'SOLS',
  },
  {
    value: 'SPGX',
    label: 'SPGX,  Sustainable Projects Group Inc.',
    name: 'Sustainable Projects Group Inc.',
    symbol: 'SPGX',
  },
  {
    value: 'SPNT-PB',
    label: 'SPNT-PB,  SiriusPoint Ltd.',
    name: 'SiriusPoint Ltd.',
    symbol: 'SPNT-PB',
  },
  {
    value: 'SPOWF',
    label: 'SPOWF,  Strata Power Corporation',
    name: 'Strata Power Corporation',
    symbol: 'SPOWF',
  },
  {
    value: 'SPPP.TO',
    label: 'SPPP.TO,  Sprott Physical Platinum and Palladium Trust',
    name: 'Sprott Physical Platinum and Palladium Trust',
    symbol: 'SPPP.TO',
  },
  {
    value: 'SPTK',
    label: 'SPTK,  SportsTek Acquisition Corp.',
    name: 'SportsTek Acquisition Corp.',
    symbol: 'SPTK',
  },
  {
    value: 'SQFTP',
    label: 'SQFTP,  Presidio Property Trust, Inc.',
    name: 'Presidio Property Trust, Inc.',
    symbol: 'SQFTP',
  },
  {
    value: 'SRCO',
    label: 'SRCO,  Sparta Commercial Services, Inc.',
    name: 'Sparta Commercial Services, Inc.',
    symbol: 'SRCO',
  },
  {
    value: 'SRC-PA',
    label: 'SRC-PA,  Spirit Realty Capital, Inc.',
    name: 'Spirit Realty Capital, Inc.',
    symbol: 'SRC-PA',
  },
  {
    value: 'SRCU',
    label: 'SRCU,  Spire Inc.',
    name: 'Spire Inc.',
    symbol: 'SRCU',
  },
  {
    value: 'SRG-PA',
    label: 'SRG-PA,  Seritage Growth Properties',
    name: 'Seritage Growth Properties',
    symbol: 'SRG-PA',
  },
  {
    value: 'SRSA',
    label: 'SRSA,  Sarissa Capital Acquisition Corp.',
    name: 'Sarissa Capital Acquisition Corp.',
    symbol: 'SRSA',
  },
  {
    value: 'STAL',
    label: 'STAL,  Star Alliance International Corp.',
    name: 'Star Alliance International Corp.',
    symbol: 'STAL',
  },
  {
    value: 'STAR-PD',
    label: 'STAR-PD,  iStar Inc.',
    name: 'iStar Inc.',
    symbol: 'STAR-PD',
  },
  {
    value: 'STAR-PG',
    label: 'STAR-PG,  iStar Inc.',
    name: 'iStar Inc.',
    symbol: 'STAR-PG',
  },
  {
    value: 'STAR-PI',
    label: 'STAR-PI,  iStar Inc.',
    name: 'iStar Inc.',
    symbol: 'STAR-PI',
  },
  {
    value: 'STQN',
    label: 'STQN,  Strategic Acquisitions, Inc.',
    name: 'Strategic Acquisitions, Inc.',
    symbol: 'STQN',
  },
  {
    value: 'STRNW',
    label: 'STRNW,  Stran & Company, Inc.',
    name: 'Stran & Company, Inc.',
    symbol: 'STRNW',
  },
  {
    value: 'STRRP',
    label: 'STRRP,  Star Equity Holdings, Inc.',
    name: 'Star Equity Holdings, Inc.',
    symbol: 'STRRP',
  },
  {
    value: 'SYQH',
    label: 'SYQH,  Liaoning Shuiyun Qinghe Rice Industry Co., Ltd.',
    name: 'Liaoning Shuiyun Qinghe Rice Industry Co., Ltd.',
    symbol: 'SYQH',
  },
  {
    value: 'TARSF',
    label: 'TARSF,  Alianza Minerals Ltd.',
    name: 'Alianza Minerals Ltd.',
    symbol: 'TARSF',
  },
  {
    value: 'TCRI',
    label: 'TCRI,  TechCom, Inc.',
    name: 'TechCom, Inc.',
    symbol: 'TCRI',
  },
  {
    value: 'TDS-PU',
    label: 'TDS-PU,  Telephone and Data Systems, Inc.',
    name: 'Telephone and Data Systems, Inc.',
    symbol: 'TDS-PU',
  },
  {
    value: 'TDS-PV',
    label: 'TDS-PV,  Telephone and Data Systems, Inc.',
    name: 'Telephone and Data Systems, Inc.',
    symbol: 'TDS-PV',
  },
  {
    value: 'TETC',
    label: 'TETC,  Tech and Energy Transition Corporation',
    name: 'Tech and Energy Transition Corporation',
    symbol: 'TETC',
  },
  {
    value: 'TEVJF',
    label: 'TEVJF,  Teva Pharmaceutical Industries Limited',
    name: 'Teva Pharmaceutical Industries Limited',
    symbol: 'TEVJF',
  },
  {
    value: 'TFC-PI',
    label: 'TFC-PI,  Truist Financial Corporation',
    name: 'Truist Financial Corporation',
    symbol: 'TFC-PI',
  },
  {
    value: 'TFC-PO',
    label: 'TFC-PO,  Truist Financial Corporation',
    name: 'Truist Financial Corporation',
    symbol: 'TFC-PO',
  },
  {
    value: 'TGCB',
    label: 'TGCB,  Tego Cyber Inc.',
    name: 'Tego Cyber Inc.',
    symbol: 'TGCB',
  },
  {
    value: 'THNPF',
    label: 'THNPF,  Technip Energies N.V.',
    name: 'Technip Energies N.V.',
    symbol: 'THNPF',
  },
  {
    value: 'TIKRF',
    label: 'TIKRF,  Tikcro Technologies Ltd.',
    name: 'Tikcro Technologies Ltd.',
    symbol: 'TIKRF',
  },
  {
    value: 'TINV-UN',
    label: 'TINV-UN,  Tiga Acquisition Corp.',
    name: 'Tiga Acquisition Corp.',
    symbol: 'TINV-UN',
  },
  {
    value: 'TIRX',
    label: 'TIRX,  Tian Ruixiang Holdings Ltd',
    name: 'Tian Ruixiang Holdings Ltd',
    symbol: 'TIRX',
  },
  {
    value: 'TMAC-UN',
    label: 'TMAC-UN,  The Music Acquisition Corporation',
    name: 'The Music Acquisition Corporation',
    symbol: 'TMAC-UN',
  },
  {
    value: 'TNCAF',
    label: 'TNCAF,  TC Energy Corporation',
    name: 'TC Energy Corporation',
    symbol: 'TNCAF',
  },
  {
    value: 'TNGX',
    label: 'TNGX,  Tango Therapeutics, Inc.',
    name: 'Tango Therapeutics, Inc.',
    symbol: 'TNGX',
  },
  {
    value: 'TOWTF',
    label: 'TOWTF,  Tower One Wireless Corp.',
    name: 'Tower One Wireless Corp.',
    symbol: 'TOWTF',
  },
  {
    value: 'T-PA',
    label: 'T-PA,  AT&T Inc.',
    name: 'AT&T Inc.',
    symbol: 'T-PA',
  },
  {
    value: 'TROLB',
    label: 'TROLB,  Tootsie Roll Industries, Inc.',
    name: 'Tootsie Roll Industries, Inc.',
    symbol: 'TROLB',
  },
  {
    value: 'TRTK',
    label: 'TRTK,  TORtec Group Corporation',
    name: 'TORtec Group Corporation',
    symbol: 'TRTK',
  },
  {
    value: 'TRTN-PA',
    label: 'TRTN-PA,  Triton International Limited',
    name: 'Triton International Limited',
    symbol: 'TRTN-PA',
  },
  {
    value: 'TRTN-PB',
    label: 'TRTN-PB,  Triton International Limited',
    name: 'Triton International Limited',
    symbol: 'TRTN-PB',
  },
  {
    value: 'TRTN-PC',
    label: 'TRTN-PC,  Triton International Limited',
    name: 'Triton International Limited',
    symbol: 'TRTN-PC',
  },
  {
    value: 'TRTN-PE',
    label: 'TRTN-PE,  Triton International Limited',
    name: 'Triton International Limited',
    symbol: 'TRTN-PE',
  },
  {
    value: 'TRTX-PC',
    label: 'TRTX-PC,  TPG RE Finance Trust, Inc.',
    name: 'TPG RE Finance Trust, Inc.',
    symbol: 'TRTX-PC',
  },
  {
    value: 'TRXA',
    label: 'TRXA,  T-REX Acquisition Corp.',
    name: 'T-REX Acquisition Corp.',
    symbol: 'TRXA',
  },
  {
    value: 'TWO-PA',
    label: 'TWO-PA,  Two Harbors Investment Corp.',
    name: 'Two Harbors Investment Corp.',
    symbol: 'TWO-PA',
  },
  {
    value: 'TXCB',
    label: 'TXCB,  Cang Bao Tian Xia International Art Trade Center, Inc.',
    name: 'Cang Bao Tian Xia International Art Trade Center, Inc.',
    symbol: 'TXCB',
  },
  {
    value: 'UBP-PH',
    label: 'UBP-PH,  Urstadt Biddle Properties Inc.',
    name: 'Urstadt Biddle Properties Inc.',
    symbol: 'UBP-PH',
  },
  {
    value: 'UBP-PK',
    label: 'UBP-PK,  Urstadt Biddle Properties Inc.',
    name: 'Urstadt Biddle Properties Inc.',
    symbol: 'UBP-PK',
  },
  {
    value: 'UCASU',
    label: 'UCASU,  UC Asset, LP',
    name: 'UC Asset, LP',
    symbol: 'UCASU',
  },
  {
    value: 'UELMO',
    label: 'UELMO,  Union Electric Company',
    name: 'Union Electric Company',
    symbol: 'UELMO',
  },
  {
    value: 'UEPCN',
    label: 'UEPCN,  Union Electric Company',
    name: 'Union Electric Company',
    symbol: 'UEPCN',
  },
  {
    value: 'UEPCO',
    label: 'UEPCO,  Union Electric Company',
    name: 'Union Electric Company',
    symbol: 'UEPCO',
  },
  {
    value: 'UEPCP',
    label: 'UEPCP,  Union Electric Company',
    name: 'Union Electric Company',
    symbol: 'UEPCP',
  },
  {
    value: 'UEPEM',
    label: 'UEPEM,  Union Electric Company',
    name: 'Union Electric Company',
    symbol: 'UEPEM',
  },
  {
    value: 'UEPEN',
    label: 'UEPEN,  Union Electric Company',
    name: 'Union Electric Company',
    symbol: 'UEPEN',
  },
  {
    value: 'UEPEO',
    label: 'UEPEO,  Union Electric Company',
    name: 'Union Electric Company',
    symbol: 'UEPEO',
  },
  {
    value: 'UNQL',
    label: 'UNQL,  Unique Logistics International, Inc.',
    name: 'Unique Logistics International, Inc.',
    symbol: 'UNQL',
  },
  {
    value: 'UNTC',
    label: 'UNTC,  Unit Corporation',
    name: 'Unit Corporation',
    symbol: 'UNTC',
  },
  {
    value: 'USB-PH',
    label: 'USB-PH,  U.S. Bancorp',
    name: 'U.S. Bancorp',
    symbol: 'USB-PH',
  },
  {
    value: 'USB-PQ',
    label: 'USB-PQ,  U.S. Bancorp',
    name: 'U.S. Bancorp',
    symbol: 'USB-PQ',
  },
  {
    value: 'USB-PR',
    label: 'USB-PR,  U.S. Bancorp',
    name: 'U.S. Bancorp',
    symbol: 'USB-PR',
  },
  {
    value: 'USRM',
    label: 'USRM,  U.S. Stem Cell, Inc.',
    name: 'U.S. Stem Cell, Inc.',
    symbol: 'USRM',
  },
  {
    value: 'VHAQ',
    label: 'VHAQ,  Viveon Health Acquisition Corp.',
    name: 'Viveon Health Acquisition Corp.',
    symbol: 'VHAQ',
  },
  {
    value: 'VIASP',
    label: 'VIASP,  Via Renewables, Inc.',
    name: 'Via Renewables, Inc.',
    symbol: 'VIASP',
  },
  {
    value: 'VIHD',
    label: 'VIHD,  Vision Hydrogen Corporation',
    name: 'Vision Hydrogen Corporation',
    symbol: 'VIHD',
  },
  {
    value: 'VIIAU',
    label: 'VIIAU,  7GC & Co. Holdings Inc.',
    name: '7GC & Co. Holdings Inc.',
    symbol: 'VIIAU',
  },
  {
    value: 'VMNT',
    label: 'VMNT,  Vemanti Group, Inc.',
    name: 'Vemanti Group, Inc.',
    symbol: 'VMNT',
  },
  {
    value: 'VNO-PN',
    label: 'VNO-PN,  Vornado Realty Trust',
    name: 'Vornado Realty Trust',
    symbol: 'VNO-PN',
  },
  {
    value: 'VNO-PO',
    label: 'VNO-PO,  Vornado Realty Trust',
    name: 'Vornado Realty Trust',
    symbol: 'VNO-PO',
  },
  {
    value: 'VNORP',
    label: 'VNORP,  Vornado Realty Trust',
    name: 'Vornado Realty Trust',
    symbol: 'VNORP',
  },
  {
    value: 'VRRMW',
    label: 'VRRMW,  Verra Mobility Corporation',
    name: 'Verra Mobility Corporation',
    symbol: 'VRRMW',
  },
  {
    value: 'VTNA',
    label: 'VTNA,  VetaNova, Inc.',
    name: 'VetaNova, Inc.',
    symbol: 'VTNA',
  },
  {
    value: 'WAL-PA',
    label: 'WAL-PA,  Western Alliance Bancorporation',
    name: 'Western Alliance Bancorporation',
    symbol: 'WAL-PA',
  },
  {
    value: 'WBBA',
    label: 'WBBA,  WB Burgers Asia, Inc.',
    name: 'WB Burgers Asia, Inc.',
    symbol: 'WBBA',
  },
  {
    value: 'WBS-PF',
    label: 'WBS-PF,  Webster Financial Corporation',
    name: 'Webster Financial Corporation',
    symbol: 'WBS-PF',
  },
  {
    value: 'WDH',
    label: 'WDH,  Waterdrop Inc.',
    name: 'Waterdrop Inc.',
    symbol: 'WDH',
  },
  {
    value: 'WEJOW',
    label: 'WEJOW,  Wejo Group Limited',
    name: 'Wejo Group Limited',
    symbol: 'WEJOW',
  },
  {
    value: 'WFC-PA',
    label: 'WFC-PA,  Wells Fargo & Company',
    name: 'Wells Fargo & Company',
    symbol: 'WFC-PA',
  },
  {
    value: 'WFC-PC',
    label: 'WFC-PC,  Wells Fargo & Company',
    name: 'Wells Fargo & Company',
    symbol: 'WFC-PC',
  },
  {
    value: 'WFC-PD',
    label: 'WFC-PD,  Wells Fargo & Company',
    name: 'Wells Fargo & Company',
    symbol: 'WFC-PD',
  },
  {
    value: 'WFC-PL',
    label: 'WFC-PL,  Wells Fargo & Company',
    name: 'Wells Fargo & Company',
    symbol: 'WFC-PL',
  },
  {
    value: 'WFC-PQ',
    label: 'WFC-PQ,  Wells Fargo & Company',
    name: 'Wells Fargo & Company',
    symbol: 'WFC-PQ',
  },
  {
    value: 'WFC-PR',
    label: 'WFC-PR,  Wells Fargo & Company',
    name: 'Wells Fargo & Company',
    symbol: 'WFC-PR',
  },
  {
    value: 'WFC-PY',
    label: 'WFC-PY,  Wells Fargo & Company',
    name: 'Wells Fargo & Company',
    symbol: 'WFC-PY',
  },
  {
    value: 'WGEI',
    label: 'WGEI,  WindGen Energy, Inc.',
    name: 'WindGen Energy, Inc.',
    symbol: 'WGEI',
  },
  {
    value: 'WHLT',
    label: 'WHLT,  Chase Packaging Corporation',
    name: 'Chase Packaging Corporation',
    symbol: 'WHLT',
  },
  {
    value: 'WLYW',
    label: 'WLYW,  Wally World Media, Inc.',
    name: 'Wally World Media, Inc.',
    symbol: 'WLYW',
  },
  {
    value: 'WOLV',
    label: 'WOLV,  Wolverine Resources Corp.',
    name: 'Wolverine Resources Corp.',
    symbol: 'WOLV',
  },
  {
    value: 'WSCRF',
    label: 'WSCRF,  Premium Nickel Resources Ltd.',
    name: 'Premium Nickel Resources Ltd.',
    symbol: 'WSCRF',
  },
  {
    value: 'WSO-B',
    label: 'WSO-B,  Watsco, Inc.',
    name: 'Watsco, Inc.',
    symbol: 'WSO-B',
  },
  {
    value: 'WWSG',
    label: 'WWSG,  Worldwide Strategies, Inc.',
    name: 'Worldwide Strategies, Inc.',
    symbol: 'WWSG',
  },
  {
    value: 'XERI',
    label: 'XERI,  Xeriant, Inc.',
    name: 'Xeriant, Inc.',
    symbol: 'XERI',
  },
  {
    value: 'XNDA',
    label: 'XNDA,  Tribal Rides International Corp.',
    name: 'Tribal Rides International Corp.',
    symbol: 'XNDA',
  },
  {
    value: 'XTGRF',
    label: 'XTGRF,  Xtra-Gold Resources Corp.',
    name: 'Xtra-Gold Resources Corp.',
    symbol: 'XTGRF',
  },
  {
    value: 'YBCN',
    label: 'YBCN,  Yong Bai Chao New Retail Corporation',
    name: 'Yong Bai Chao New Retail Corporation',
    symbol: 'YBCN',
  },
  {
    value: 'YTFD',
    label: 'YTFD,  Yale Transaction Finders, Inc.',
    name: 'Yale Transaction Finders, Inc.',
    symbol: 'YTFD',
  },
  {
    value: 'YTPG',
    label: 'YTPG,  TPG Pace Beneficial II Corp.',
    name: 'TPG Pace Beneficial II Corp.',
    symbol: 'YTPG',
  },
  {
    value: 'ZENO',
    label: 'ZENO,  Zenosense, Inc.',
    name: 'Zenosense, Inc.',
    symbol: 'ZENO',
  },
  {
    value: 'ZNOG',
    label: 'ZNOG,  Zion Oil & Gas, Inc.',
    name: 'Zion Oil & Gas, Inc.',
    symbol: 'ZNOG',
  },
  {
    value: 'ZNRG',
    label: 'ZNRG,  Znergy, Inc.',
    name: 'Znergy, Inc.',
    symbol: 'ZNRG',
  },
  {
    value: 'ZWRKU',
    label: 'ZWRKU,  Z-Work Acquisition Corp.',
    name: 'Z-Work Acquisition Corp.',
    symbol: 'ZWRKU',
  },
  {
    value: 'BOAT',
    label: 'BOAT,  SonicShares Global Shipping ETF',
    name: 'SonicShares Global Shipping ETF',
    symbol: 'BOAT',
  },
  {
    value: 'OILU',
    label:
      'OILU,  MicroSectorsTM Oil & Gas Exploration & Production 3X Leveraged ETNs',
    name: 'MicroSectorsTM Oil & Gas Exploration & Production 3X Leveraged ETNs',
    symbol: 'OILU',
  },
  {
    value: 'OILD',
    label:
      'OILD,  MicroSectorsTM Oil & Gas Exploration & Production -3X Inverse Leveraged ETNs',
    name: 'MicroSectorsTM Oil & Gas Exploration & Production -3X Inverse Leveraged ETNs',
    symbol: 'OILD',
  },
  {
    value: 'CMT.CN',
    label: 'CMT.CN,  Cullinan Metals Corp.',
    name: 'Cullinan Metals Corp.',
    symbol: 'CMT.CN',
  },
  {
    value: 'FGMCU',
    label: 'FGMCU,  FG Merger Corp.',
    name: 'FG Merger Corp.',
    symbol: 'FGMCU',
  },
  {
    value: 'DTG.DE',
    label: 'DTG.DE,  Daimler Truck Holding AG',
    name: 'Daimler Truck Holding AG',
    symbol: 'DTG.DE',
  },
  {
    value: 'PWUPU',
    label: 'PWUPU,  PowerUp Acquisition Corp.',
    name: 'PowerUp Acquisition Corp.',
    symbol: 'PWUPU',
  },
  {
    value: '1201.SR',
    label: '1201.SR,  Takween Advanced Industries',
    name: 'Takween Advanced Industries',
    symbol: '1201.SR',
  },
  {
    value: '1202.SR',
    label:
      '1202.SR,  Middle East Company for Manufacturing and Producing Paper',
    name: 'Middle East Company for Manufacturing and Producing Paper',
    symbol: '1202.SR',
  },
  {
    value: '1210.SR',
    label: '1210.SR,  Basic Chemical Industries Company',
    name: 'Basic Chemical Industries Company',
    symbol: '1210.SR',
  },
  {
    value: '1211.SR',
    label: "1211.SR,  Saudi Arabian Mining Company (Ma'aden)",
    name: "Saudi Arabian Mining Company (Ma'aden)",
    symbol: '1211.SR',
  },
  {
    value: '1301.SR',
    label: '1301.SR,  United Wire Factories Company',
    name: 'United Wire Factories Company',
    symbol: '1301.SR',
  },
  {
    value: '1304.SR',
    label: '1304.SR,  Al Yamamah Steel Industries Company',
    name: 'Al Yamamah Steel Industries Company',
    symbol: '1304.SR',
  },
  {
    value: '1320.SR',
    label: '1320.SR,  Saudi Steel Pipes Company',
    name: 'Saudi Steel Pipes Company',
    symbol: '1320.SR',
  },
  {
    value: '2001.SR',
    label: '2001.SR,  Methanol Chemicals Company',
    name: 'Methanol Chemicals Company',
    symbol: '2001.SR',
  },
  {
    value: '2010.SR',
    label: '2010.SR,  Saudi Basic Industries Corporation',
    name: 'Saudi Basic Industries Corporation',
    symbol: '2010.SR',
  },
  {
    value: '2020.SR',
    label: '2020.SR,  SABIC Agri-Nutrients Company',
    name: 'SABIC Agri-Nutrients Company',
    symbol: '2020.SR',
  },
  {
    value: '2090.SR',
    label: '2090.SR,  National Gypsum Company',
    name: 'National Gypsum Company',
    symbol: '2090.SR',
  },
  {
    value: '2150.SR',
    label: '2150.SR,  The National Company for Glass Industries',
    name: 'The National Company for Glass Industries',
    symbol: '2150.SR',
  },
  {
    value: '2170.SR',
    label: '2170.SR,  Alujain Corporation',
    name: 'Alujain Corporation',
    symbol: '2170.SR',
  },
  {
    value: '2180.SR',
    label: '2180.SR,  Filling and Packing Materials Manufacturing Company',
    name: 'Filling and Packing Materials Manufacturing Company',
    symbol: '2180.SR',
  },
  {
    value: '2200.SR',
    label: '2200.SR,  Arabian Pipes Company',
    name: 'Arabian Pipes Company',
    symbol: '2200.SR',
  },
  {
    value: '2210.SR',
    label: '2210.SR,  Nama Chemicals Company',
    name: 'Nama Chemicals Company',
    symbol: '2210.SR',
  },
  {
    value: '2220.SR',
    label: '2220.SR,  National Metal Manufacturing and Casting Co.',
    name: 'National Metal Manufacturing and Casting Co.',
    symbol: '2220.SR',
  },
  {
    value: '2240.SR',
    label: '2240.SR,  Zamil Industrial Investment Company',
    name: 'Zamil Industrial Investment Company',
    symbol: '2240.SR',
  },
  {
    value: '2250.SR',
    label: '2250.SR,  Saudi Industrial Investment Group',
    name: 'Saudi Industrial Investment Group',
    symbol: '2250.SR',
  },
  {
    value: '300772.SZ',
    label: '300772.SZ,  Zhejiang Windey Co.,Ltd.',
    name: 'Zhejiang Windey Co.,Ltd.',
    symbol: '300772.SZ',
  },
  {
    value: '300397.SZ',
    label: "300397.SZ,  Xi'an Tianhe Defense Technology Co., Ltd.",
    name: "Xi'an Tianhe Defense Technology Co., Ltd.",
    symbol: '300397.SZ',
  },
  {
    value: '300483.SZ',
    label: '300483.SZ,  Sino Prima Gas Technology Co., Ltd.',
    name: 'Sino Prima Gas Technology Co., Ltd.',
    symbol: '300483.SZ',
  },
  {
    value: 'KNTK',
    label: 'KNTK,  Kinetik Holdings Inc.',
    name: 'Kinetik Holdings Inc.',
    symbol: 'KNTK',
  },
  {
    value: 'AIB',
    label: 'AIB,  AIB Acquisition Corporation',
    name: 'AIB Acquisition Corporation',
    symbol: 'AIB',
  },
  {
    value: 'BRAC',
    label: 'BRAC,  Broad Capital Acquisition Corp.',
    name: 'Broad Capital Acquisition Corp.',
    symbol: 'BRAC',
  },
  {
    value: 'AIBBR',
    label: 'AIBBR,  AIB Acquisition Corporation',
    name: 'AIB Acquisition Corporation',
    symbol: 'AIBBR',
  },
  {
    value: 'GMFI',
    label: 'GMFI,  Aetherium Acquisition Corp.',
    name: 'Aetherium Acquisition Corp.',
    symbol: 'GMFI',
  },
  {
    value: 'GMFIW',
    label: 'GMFIW,  Aetherium Acquisition Corp.',
    name: 'Aetherium Acquisition Corp.',
    symbol: 'GMFIW',
  },
  {
    value: 'PLAOU',
    label: 'PLAOU,  Patria Latin American Opportunity Acquisition Corp.',
    name: 'Patria Latin American Opportunity Acquisition Corp.',
    symbol: 'PLAOU',
  },
  {
    value: 'AKAN',
    label: 'AKAN,  Akanda Corp.',
    name: 'Akanda Corp.',
    symbol: 'AKAN',
  },
  {
    value: 'VMCAU',
    label: 'VMCAU,  Valuence Merger Corp. I',
    name: 'Valuence Merger Corp. I',
    symbol: 'VMCAU',
  },
  {
    value: 'HDIV.TO',
    label: 'HDIV.TO,  Hamilton Enhanced Multi-Sector Covered Call ETF',
    name: 'Hamilton Enhanced Multi-Sector Covered Call ETF',
    symbol: 'HDIV.TO',
  },
  {
    value: 'CITEW',
    label: 'CITEW,  Cartica Acquisition Corp',
    name: 'Cartica Acquisition Corp',
    symbol: 'CITEW',
  },
  {
    value: 'CITE',
    label: 'CITE,  Cartica Acquisition Corp',
    name: 'Cartica Acquisition Corp',
    symbol: 'CITE',
  },
  {
    value: 'GSRMU',
    label: 'GSRMU,  GSR II Meteora Acquisition Corp.',
    name: 'GSR II Meteora Acquisition Corp.',
    symbol: 'GSRMU',
  },
  {
    value: 'IXHL',
    label: 'IXHL,  Incannex Healthcare Limited',
    name: 'Incannex Healthcare Limited',
    symbol: 'IXHL',
  },
  {
    value: 'ALHUN.PA',
    label: 'ALHUN.PA,  Hunyvers SA',
    name: 'Hunyvers SA',
    symbol: 'ALHUN.PA',
  },
  {
    value: 'CLINU',
    label: 'CLINU,  Clean Earth Acquisitions Corp.',
    name: 'Clean Earth Acquisitions Corp.',
    symbol: 'CLINU',
  },
  {
    value: 'KACLU',
    label: 'KACLU,  Kairous Acquisition Corp. Limited',
    name: 'Kairous Acquisition Corp. Limited',
    symbol: 'KACLU',
  },
  {
    value: 'WRPT',
    label: 'WRPT,  WarpSpeed Taxi Inc.',
    name: 'WarpSpeed Taxi Inc.',
    symbol: 'WRPT',
  },
  {
    value: 'ENMI',
    label: 'ENMI,  DH Enchantment, Inc.',
    name: 'DH Enchantment, Inc.',
    symbol: 'ENMI',
  },
  {
    value: 'WN-PA.TO',
    label: 'WN-PA.TO,  George Weston Limited',
    name: 'George Weston Limited',
    symbol: 'WN-PA.TO',
  },
  {
    value: 'RCG-PB.TO',
    label: 'RCG-PB.TO,  RF Capital Group Inc.',
    name: 'RF Capital Group Inc.',
    symbol: 'RCG-PB.TO',
  },
  {
    value: 'RS.TO',
    label: 'RS.TO,  Real Estate Split Corp.',
    name: 'Real Estate Split Corp.',
    symbol: 'RS.TO',
  },
  {
    value: 'MPCT-UN.TO',
    label: 'MPCT-UN.TO,  Dream Impact Trust',
    name: 'Dream Impact Trust',
    symbol: 'MPCT-UN.TO',
  },
  {
    value: 'L-PB.TO',
    label: 'L-PB.TO,  Loblaw Companies Limited',
    name: 'Loblaw Companies Limited',
    symbol: 'L-PB.TO',
  },
  {
    value: 'IAF-PB.TO',
    label: 'IAF-PB.TO,  iA Financial Corporation Inc.',
    name: 'iA Financial Corporation Inc.',
    symbol: 'IAF-PB.TO',
  },
  {
    value: 'EFN-PA.TO',
    label: 'EFN-PA.TO,  Element Fleet Management Corp.',
    name: 'Element Fleet Management Corp.',
    symbol: 'EFN-PA.TO',
  },
  {
    value: 'ECN-PC.TO',
    label: 'ECN-PC.TO,  ECN Capital Corp.',
    name: 'ECN Capital Corp.',
    symbol: 'ECN-PC.TO',
  },
  {
    value: 'BBD-PC.TO',
    label: 'BBD-PC.TO,  Bombardier Inc.',
    name: 'Bombardier Inc.',
    symbol: 'BBD-PC.TO',
  },
  {
    value: 'AX-PE.TO',
    label: 'AX-PE.TO,  Artis Real Estate Investment Trust',
    name: 'Artis Real Estate Investment Trust',
    symbol: 'AX-PE.TO',
  },
  {
    value: 'LUS1.DE',
    label: 'LUS1.DE,  Lang & Schwarz Aktiengesellschaft',
    name: 'Lang & Schwarz Aktiengesellschaft',
    symbol: 'LUS1.DE',
  },
  {
    value: '688981.SS',
    label: '688981.SS,  Semiconductor Manufacturing International Corporation',
    name: 'Semiconductor Manufacturing International Corporation',
    symbol: '688981.SS',
  },
  {
    value: '688819.SS',
    label: '688819.SS,  Tianneng Battery Group Co., Ltd.',
    name: 'Tianneng Battery Group Co., Ltd.',
    symbol: '688819.SS',
  },
  {
    value: '688800.SS',
    label: '688800.SS,  Suzhou Recodeal Interconnect System Co.,Ltd',
    name: 'Suzhou Recodeal Interconnect System Co.,Ltd',
    symbol: '688800.SS',
  },
  {
    value: '688798.SS',
    label: '688798.SS,  Shanghai Awinic Technology Co.,Ltd.',
    name: 'Shanghai Awinic Technology Co.,Ltd.',
    symbol: '688798.SS',
  },
  {
    value: '688789.SS',
    label: '688789.SS,  Hangzhou Honghua Digital Technology Stock Company LTD.',
    name: 'Hangzhou Honghua Digital Technology Stock Company LTD.',
    symbol: '688789.SS',
  },
  {
    value: '688787.SS',
    label: '688787.SS,  Beijing Haitian Ruisheng Science Technology Ltd.',
    name: 'Beijing Haitian Ruisheng Science Technology Ltd.',
    symbol: '688787.SS',
  },
  {
    value: '688786.SS',
    label: '688786.SS,  Jiangxi Yuean Advanced Materials Co.,Ltd.',
    name: 'Jiangxi Yuean Advanced Materials Co.,Ltd.',
    symbol: '688786.SS',
  },
  {
    value: '688779.SS',
    label: '688779.SS,  Hunan Changyuan Lico Co.,Ltd.',
    name: 'Hunan Changyuan Lico Co.,Ltd.',
    symbol: '688779.SS',
  },
  {
    value: '688778.SS',
    label: '688778.SS,  XTC New Energy Materials(Xiamen) Co.,Ltd.',
    name: 'XTC New Energy Materials(Xiamen) Co.,Ltd.',
    symbol: '688778.SS',
  },
  {
    value: '688776.SS',
    label: '688776.SS,  Guoguang Electric Co.,Ltd.Chengdu',
    name: 'Guoguang Electric Co.,Ltd.Chengdu',
    symbol: '688776.SS',
  },
  {
    value: '688772.SS',
    label: '688772.SS,  Zhuhai CosMX Battery Co., Ltd.',
    name: 'Zhuhai CosMX Battery Co., Ltd.',
    symbol: '688772.SS',
  },
  {
    value: '688768.SS',
    label: '688768.SS,  Anhui Ronds Science & Technology Incorporated Company',
    name: 'Anhui Ronds Science & Technology Incorporated Company',
    symbol: '688768.SS',
  },
  {
    value: '688767.SS',
    label: '688767.SS,  Hangzhou Biotest Biotech Co.,Ltd.',
    name: 'Hangzhou Biotest Biotech Co.,Ltd.',
    symbol: '688767.SS',
  },
  {
    value: '688766.SS',
    label: '688766.SS,  Puya Semiconductor (Shanghai) Co., Ltd.',
    name: 'Puya Semiconductor (Shanghai) Co., Ltd.',
    symbol: '688766.SS',
  },
  {
    value: '688737.SS',
    label: '688737.SS,  Sinocat Environmental Technology Co.,Ltd.',
    name: 'Sinocat Environmental Technology Co.,Ltd.',
    symbol: '688737.SS',
  },
  {
    value: '688733.SS',
    label: '688733.SS,  Anhui Estone Materials Technology Co.,Ltd',
    name: 'Anhui Estone Materials Technology Co.,Ltd',
    symbol: '688733.SS',
  },
  {
    value: '688728.SS',
    label: '688728.SS,  GalaxyCore Inc.',
    name: 'GalaxyCore Inc.',
    symbol: '688728.SS',
  },
  {
    value: '688722.SS',
    label:
      '688722.SS,  Beijing Tongyizhong New Material Technology Corporation',
    name: 'Beijing Tongyizhong New Material Technology Corporation',
    symbol: '688722.SS',
  },
  {
    value: '688718.SS',
    label: '688718.SS,  Wave Cyber (Shanghai) Co., Ltd.',
    name: 'Wave Cyber (Shanghai) Co., Ltd.',
    symbol: '688718.SS',
  },
  {
    value: '688711.SS',
    label: '688711.SS,  Macmic Science&Technology Co.,Ltd.',
    name: 'Macmic Science&Technology Co.,Ltd.',
    symbol: '688711.SS',
  },
  {
    value: '688707.SS',
    label: '688707.SS,  Guizhou Zhenhua New Material Co., Ltd.',
    name: 'Guizhou Zhenhua New Material Co., Ltd.',
    symbol: '688707.SS',
  },
  {
    value: '688701.SS',
    label: '688701.SS,  Zhejiang Zone-King Environmental Sci&Tech Co., Ltd.',
    name: 'Zhejiang Zone-King Environmental Sci&Tech Co., Ltd.',
    symbol: '688701.SS',
  },
  {
    value: '688700.SS',
    label: '688700.SS,  Kunshan Dongwei Technology Co.,Ltd.',
    name: 'Kunshan Dongwei Technology Co.,Ltd.',
    symbol: '688700.SS',
  },
  {
    value: '688697.SS',
    label: '688697.SS,  Neway CNC Equipment (Suzhou) Co., Ltd.',
    name: 'Neway CNC Equipment (Suzhou) Co., Ltd.',
    symbol: '688697.SS',
  },
  {
    value: '688690.SS',
    label: '688690.SS,  Suzhou Nanomicro Technology Co., Ltd.',
    name: 'Suzhou Nanomicro Technology Co., Ltd.',
    symbol: '688690.SS',
  },
  {
    value: '688689.SS',
    label: '688689.SS,  Changzhou Galaxy Century Microelectronics Co.,Ltd.',
    name: 'Changzhou Galaxy Century Microelectronics Co.,Ltd.',
    symbol: '688689.SS',
  },
  {
    value: '688687.SS',
    label: '688687.SS,  Beijing Kawin Technology Share-Holding Co., Ltd.',
    name: 'Beijing Kawin Technology Share-Holding Co., Ltd.',
    symbol: '688687.SS',
  },
  {
    value: '688681.SS',
    label: '688681.SS,  Shandong Kehui Power Automation Co.,Ltd.',
    name: 'Shandong Kehui Power Automation Co.,Ltd.',
    symbol: '688681.SS',
  },
  {
    value: '688680.SS',
    label: '688680.SS,  Shanghai HIUV New Materials Co.,Ltd',
    name: 'Shanghai HIUV New Materials Co.,Ltd',
    symbol: '688680.SS',
  },
  {
    value: '688670.SS',
    label: '688670.SS,  Jiangsu gdk Biotechnology Co., Ltd.',
    name: 'Jiangsu gdk Biotechnology Co., Ltd.',
    symbol: '688670.SS',
  },
  {
    value: '688669.SS',
    label: '688669.SS,  Polyrocks Chemical Co., Ltd',
    name: 'Polyrocks Chemical Co., Ltd',
    symbol: '688669.SS',
  },
  {
    value: '688665.SS',
    label: '688665.SS,  Cubic Sensor and Instrument Co., Ltd',
    name: 'Cubic Sensor and Instrument Co., Ltd',
    symbol: '688665.SS',
  },
  {
    value: '688656.SS',
    label: '688656.SS,  HOB Biotech Group Corp.,Ltd',
    name: 'HOB Biotech Group Corp.,Ltd',
    symbol: '688656.SS',
  },
  {
    value: '688622.SS',
    label: '688622.SS,  Guangzhou Hexin Instrument Co.,Ltd.',
    name: 'Guangzhou Hexin Instrument Co.,Ltd.',
    symbol: '688622.SS',
  },
  {
    value: '688621.SS',
    label: '688621.SS,  Beijing Sun-Novo Pharmaceutical Research Co., Ltd.',
    name: 'Beijing Sun-Novo Pharmaceutical Research Co., Ltd.',
    symbol: '688621.SS',
  },
  {
    value: '688619.SS',
    label: '688619.SS,  ROPEOK Technology Group Co.,Ltd.',
    name: 'ROPEOK Technology Group Co.,Ltd.',
    symbol: '688619.SS',
  },
  {
    value: '688617.SS',
    label: '688617.SS,  APT Medical Inc.',
    name: 'APT Medical Inc.',
    symbol: '688617.SS',
  },
  {
    value: '688607.SS',
    label: '688607.SS,  CareRay Digital Medical Technology Co., Ltd.',
    name: 'CareRay Digital Medical Technology Co., Ltd.',
    symbol: '688607.SS',
  },
  {
    value: '688601.SS',
    label: '688601.SS,  Wuxi ETEK Microelectronics Co.,Ltd.',
    name: 'Wuxi ETEK Microelectronics Co.,Ltd.',
    symbol: '688601.SS',
  },
  {
    value: '688600.SS',
    label: '688600.SS,  AnHui Wanyi Science and Technology Co., Ltd.',
    name: 'AnHui Wanyi Science and Technology Co., Ltd.',
    symbol: '688600.SS',
  },
  {
    value: '688599.SS',
    label: '688599.SS,  Trina Solar Co., Ltd',
    name: 'Trina Solar Co., Ltd',
    symbol: '688599.SS',
  },
  {
    value: '688598.SS',
    label: '688598.SS,  KBC Corporation, Ltd.',
    name: 'KBC Corporation, Ltd.',
    symbol: '688598.SS',
  },
  {
    value: '688597.SS',
    label: '688597.SS,  Beijing Yupont Electric Power Technology Co., Ltd.',
    name: 'Beijing Yupont Electric Power Technology Co., Ltd.',
    symbol: '688597.SS',
  },
  {
    value: '688596.SS',
    label: '688596.SS,  Shanghai GenTech Co., Ltd.',
    name: 'Shanghai GenTech Co., Ltd.',
    symbol: '688596.SS',
  },
  {
    value: '688595.SS',
    label: '688595.SS,  Chipsea Technologies (shenzhen) Corp.',
    name: 'Chipsea Technologies (shenzhen) Corp.',
    symbol: '688595.SS',
  },
  {
    value: '688589.SS',
    label: '688589.SS,  Leaguer (Shenzhen) Microelectronics Corp.',
    name: 'Leaguer (Shenzhen) Microelectronics Corp.',
    symbol: '688589.SS',
  },
  {
    value: '688588.SS',
    label: '688588.SS,  Linkage Software Co., LTD',
    name: 'Linkage Software Co., LTD',
    symbol: '688588.SS',
  },
  {
    value: '688586.SS',
    label: '688586.SS,  Hefei Jianghang Aircraft Equipment Corporation Ltd.',
    name: 'Hefei Jianghang Aircraft Equipment Corporation Ltd.',
    symbol: '688586.SS',
  },
  {
    value: '688585.SS',
    label: '688585.SS,  Swancor Advanced Materials Co., Ltd.',
    name: 'Swancor Advanced Materials Co., Ltd.',
    symbol: '688585.SS',
  },
  {
    value: '688580.SS',
    label: '688580.SS,  Nanjing Vishee Medical Technology Co., Ltd',
    name: 'Nanjing Vishee Medical Technology Co., Ltd',
    symbol: '688580.SS',
  },
  {
    value: '688579.SS',
    label: '688579.SS,  Dareway Software Co.,Ltd.',
    name: 'Dareway Software Co.,Ltd.',
    symbol: '688579.SS',
  },
  {
    value: '688577.SS',
    label: '688577.SS,  Zhe Jiang Headman Machinery Co.,Ltd.',
    name: 'Zhe Jiang Headman Machinery Co.,Ltd.',
    symbol: '688577.SS',
  },
  {
    value: '688569.SS',
    label: '688569.SS,  Beijing Tieke Shougang Rail Way-Tech Co., Ltd.',
    name: 'Beijing Tieke Shougang Rail Way-Tech Co., Ltd.',
    symbol: '688569.SS',
  },
  {
    value: '688568.SS',
    label: '688568.SS,  Geovis Technology Co.,Ltd',
    name: 'Geovis Technology Co.,Ltd',
    symbol: '688568.SS',
  },
  {
    value: '688567.SS',
    label: '688567.SS,  Farasis Energy (Gan Zhou) Co., Ltd.',
    name: 'Farasis Energy (Gan Zhou) Co., Ltd.',
    symbol: '688567.SS',
  },
  {
    value: '688566.SS',
    label: '688566.SS,  Jiangsu Jibeier Pharmaceutical Co., Ltd.',
    name: 'Jiangsu Jibeier Pharmaceutical Co., Ltd.',
    symbol: '688566.SS',
  },
  {
    value: '688559.SS',
    label: '688559.SS,  Shenzhen Hymson Laser Intelligent Equipments Co., Ltd.',
    name: 'Shenzhen Hymson Laser Intelligent Equipments Co., Ltd.',
    symbol: '688559.SS',
  },
  {
    value: '688558.SS',
    label:
      '688558.SS,  Nantong Guosheng Intelligence Technology Group Co., Ltd.',
    name: 'Nantong Guosheng Intelligence Technology Group Co., Ltd.',
    symbol: '688558.SS',
  },
  {
    value: '688556.SS',
    label: '688556.SS,  Qingdao Gaoce Technology Co., Ltd',
    name: 'Qingdao Gaoce Technology Co., Ltd',
    symbol: '688556.SS',
  },
  {
    value: '688555.SS',
    label: '688555.SS,  Essence Information Technology Co., Ltd.',
    name: 'Essence Information Technology Co., Ltd.',
    symbol: '688555.SS',
  },
  {
    value: '688553.SS',
    label: '688553.SS,  Sichuan Huiyu Pharmaceutical Co., Ltd.',
    name: 'Sichuan Huiyu Pharmaceutical Co., Ltd.',
    symbol: '688553.SS',
  },
  {
    value: '688551.SS',
    label: '688551.SS,  Hefei Kewell Power System Co.,Ltd.',
    name: 'Hefei Kewell Power System Co.,Ltd.',
    symbol: '688551.SS',
  },
  {
    value: '688538.SS',
    label: '688538.SS,  Everdisplay Optronics (Shanghai) Co., Ltd.',
    name: 'Everdisplay Optronics (Shanghai) Co., Ltd.',
    symbol: '688538.SS',
  },
  {
    value: '688536.SS',
    label: '688536.SS,  3Peak Incorporated',
    name: '3Peak Incorporated',
    symbol: '688536.SS',
  },
  {
    value: '688528.SS',
    label: '688528.SS,  Chengdu Qinchuan IoT Technology Co., Ltd.',
    name: 'Chengdu Qinchuan IoT Technology Co., Ltd.',
    symbol: '688528.SS',
  },
  {
    value: '688526.SS',
    label: '688526.SS,  Wuhan Keqian Biology Co.,Ltd',
    name: 'Wuhan Keqian Biology Co.,Ltd',
    symbol: '688526.SS',
  },
  {
    value: '688521.SS',
    label: '688521.SS,  VeriSilicon Microelectronics (Shanghai) Co., Ltd.',
    name: 'VeriSilicon Microelectronics (Shanghai) Co., Ltd.',
    symbol: '688521.SS',
  },
  {
    value: '688520.SS',
    label: '688520.SS,  Sinocelltech Group Limited',
    name: 'Sinocelltech Group Limited',
    symbol: '688520.SS',
  },
  {
    value: '688519.SS',
    label: '688519.SS,  Nanya New Material Technology Co.,Ltd',
    name: 'Nanya New Material Technology Co.,Ltd',
    symbol: '688519.SS',
  },
  {
    value: '688518.SS',
    label: '688518.SS,  Shenzhen United Winners Laser Co., Ltd.',
    name: 'Shenzhen United Winners Laser Co., Ltd.',
    symbol: '688518.SS',
  },
  {
    value: '688517.SS',
    label: '688517.SS,  JinGuan Electric Co., Ltd.',
    name: 'JinGuan Electric Co., Ltd.',
    symbol: '688517.SS',
  },
  {
    value: '688516.SS',
    label: '688516.SS,  Wuxi Autowell Technology Co.,Ltd.',
    name: 'Wuxi Autowell Technology Co.,Ltd.',
    symbol: '688516.SS',
  },
  {
    value: '688513.SS',
    label: '688513.SS,  Chengdu Easton Biopharmaceuticals Co., Ltd.',
    name: 'Chengdu Easton Biopharmaceuticals Co., Ltd.',
    symbol: '688513.SS',
  },
  {
    value: '688511.SS',
    label: '688511.SS,  Sichuan Tianwei Electronic Co.,Ltd.',
    name: 'Sichuan Tianwei Electronic Co.,Ltd.',
    symbol: '688511.SS',
  },
  {
    value: '688509.SS',
    label: '688509.SS,  Zhengyuan Geomatics Group Co.,Ltd.',
    name: 'Zhengyuan Geomatics Group Co.,Ltd.',
    symbol: '688509.SS',
  },
  {
    value: '688508.SS',
    label: '688508.SS,  Wuxi Chipown Micro-electronics limited',
    name: 'Wuxi Chipown Micro-electronics limited',
    symbol: '688508.SS',
  },
  {
    value: '688505.SS',
    label: '688505.SS,  Shanghai Fudan-Zhangjiang Bio-Pharmaceutical Co.,Ltd.',
    name: 'Shanghai Fudan-Zhangjiang Bio-Pharmaceutical Co.,Ltd.',
    symbol: '688505.SS',
  },
  {
    value: '688501.SS',
    label:
      '688501.SS,  Qingdao Daneng Environmental Protection Equipment Co., Ltd.',
    name: 'Qingdao Daneng Environmental Protection Equipment Co., Ltd.',
    symbol: '688501.SS',
  },
  {
    value: '688500.SS',
    label: '688500.SS,  HCR Co., Ltd',
    name: 'HCR Co., Ltd',
    symbol: '688500.SS',
  },
  {
    value: '688499.SS',
    label: '688499.SS,  Guangdong Lyric Robot Automation Co., Ltd.',
    name: 'Guangdong Lyric Robot Automation Co., Ltd.',
    symbol: '688499.SS',
  },
  {
    value: '688488.SS',
    label: '688488.SS,  Jiangsu Aidea Pharmaceutical Co., Ltd.',
    name: 'Jiangsu Aidea Pharmaceutical Co., Ltd.',
    symbol: '688488.SS',
  },
  {
    value: '688466.SS',
    label: '688466.SS,  GreenTech Environmental Co., Ltd.',
    name: 'GreenTech Environmental Co., Ltd.',
    symbol: '688466.SS',
  },
  {
    value: '688425.SS',
    label:
      '688425.SS,  China Railway Construction Heavy Industry Corporation Limited',
    name: 'China Railway Construction Heavy Industry Corporation Limited',
    symbol: '688425.SS',
  },
  {
    value: '688408.SS',
    label: '688408.SS,  Arctech Solar Holding Co., Ltd.',
    name: 'Arctech Solar Holding Co., Ltd.',
    symbol: '688408.SS',
  },
  {
    value: '688399.SS',
    label: '688399.SS,  Jiangsu Bioperfectus Technologies Co., Ltd.',
    name: 'Jiangsu Bioperfectus Technologies Co., Ltd.',
    symbol: '688399.SS',
  },
  {
    value: '688398.SS',
    label: '688398.SS,  Fujian Supertech Advanced Material Co., Ltd.',
    name: 'Fujian Supertech Advanced Material Co., Ltd.',
    symbol: '688398.SS',
  },
  {
    value: '688396.SS',
    label: '688396.SS,  China Resources Microelectronics Limited',
    name: 'China Resources Microelectronics Limited',
    symbol: '688396.SS',
  },
  {
    value: '688393.SS',
    label: '688393.SS,  Guangzhou LBP Medicine Science & Technology Co., Ltd.',
    name: 'Guangzhou LBP Medicine Science & Technology Co., Ltd.',
    symbol: '688393.SS',
  },
  {
    value: '688390.SS',
    label: '688390.SS,  Jiangsu Goodwe Power Supply Technology Co., Ltd',
    name: 'Jiangsu Goodwe Power Supply Technology Co., Ltd',
    symbol: '688390.SS',
  },
  {
    value: '688388.SS',
    label: '688388.SS,  Guangdong Jiayuan Technology Co.,Ltd.',
    name: 'Guangdong Jiayuan Technology Co.,Ltd.',
    symbol: '688388.SS',
  },
  {
    value: '688385.SS',
    label: '688385.SS,  Shanghai Fudan Microelectronics Group Company Limited',
    name: 'Shanghai Fudan Microelectronics Group Company Limited',
    symbol: '688385.SS',
  },
  {
    value: '688379.SS',
    label: '688379.SS,  Hangzhou Huaguang Advanced Welding Materials Co., Ltd.',
    name: 'Hangzhou Huaguang Advanced Welding Materials Co., Ltd.',
    symbol: '688379.SS',
  },
  {
    value: '688378.SS',
    label: '688378.SS,  Jilin OLED Material Tech Co., Ltd.',
    name: 'Jilin OLED Material Tech Co., Ltd.',
    symbol: '688378.SS',
  },
  {
    value: '688377.SS',
    label: '688377.SS,  Nanjing Develop Advanced Manufacturing Co., Ltd.',
    name: 'Nanjing Develop Advanced Manufacturing Co., Ltd.',
    symbol: '688377.SS',
  },
  {
    value: '688369.SS',
    label: '688369.SS,  BeiJing Seeyon Internet Software Corp.',
    name: 'BeiJing Seeyon Internet Software Corp.',
    symbol: '688369.SS',
  },
  {
    value: '688368.SS',
    label: '688368.SS,  Shanghai Bright Power Semiconductor Co., Ltd.',
    name: 'Shanghai Bright Power Semiconductor Co., Ltd.',
    symbol: '688368.SS',
  },
  {
    value: '688367.SS',
    label: '688367.SS,  Hefei Gocom Information Technology Co.,Ltd.',
    name: 'Hefei Gocom Information Technology Co.,Ltd.',
    symbol: '688367.SS',
  },
  {
    value: '688365.SS',
    label: '688365.SS,  Hangzhou Raycloud Technology Co., Ltd.',
    name: 'Hangzhou Raycloud Technology Co., Ltd.',
    symbol: '688365.SS',
  },
  {
    value: '688363.SS',
    label: '688363.SS,  Bloomage BioTechnology Corporation Limited',
    name: 'Bloomage BioTechnology Corporation Limited',
    symbol: '688363.SS',
  },
  {
    value: '688360.SS',
    label: '688360.SS,  Damon Technology Group Co.,Ltd.',
    name: 'Damon Technology Group Co.,Ltd.',
    symbol: '688360.SS',
  },
  {
    value: '688356.SS',
    label: '688356.SS,  Jenkem Technology Co., Ltd.',
    name: 'Jenkem Technology Co., Ltd.',
    symbol: '688356.SS',
  },
  {
    value: '688350.SS',
    label: '688350.SS,  Jiangsu Feymer Technology Co., Ltd.',
    name: 'Jiangsu Feymer Technology Co., Ltd.',
    symbol: '688350.SS',
  },
  {
    value: '688345.SS',
    label: '688345.SS,  Guangdong Greenway Technology Co., Ltd.',
    name: 'Guangdong Greenway Technology Co., Ltd.',
    symbol: '688345.SS',
  },
  {
    value: '688338.SS',
    label: '688338.SS,  Beijing Succeeder Technology Inc.',
    name: 'Beijing Succeeder Technology Inc.',
    symbol: '688338.SS',
  },
  {
    value: '688335.SS',
    label:
      '688335.SS,  Shanghai CEO Environmental Protection Technology Co., Ltd',
    name: 'Shanghai CEO Environmental Protection Technology Co., Ltd',
    symbol: '688335.SS',
  },
  {
    value: '688333.SS',
    label: "688333.SS,  Xi'an Bright Laser Technologies Co.,Ltd.",
    name: "Xi'an Bright Laser Technologies Co.,Ltd.",
    symbol: '688333.SS',
  },
  {
    value: '688321.SS',
    label: '688321.SS,  Shenzhen Chipscreen Biosciences Co., Ltd.',
    name: 'Shenzhen Chipscreen Biosciences Co., Ltd.',
    symbol: '688321.SS',
  },
  {
    value: '688318.SS',
    label: '688318.SS,  Shenzhen Fortune Trend technology Co., Ltd.',
    name: 'Shenzhen Fortune Trend technology Co., Ltd.',
    symbol: '688318.SS',
  },
  {
    value: '688313.SS',
    label: '688313.SS,  Henan Shijia Photons Technology Co., Ltd.',
    name: 'Henan Shijia Photons Technology Co., Ltd.',
    symbol: '688313.SS',
  },
  {
    value: '688312.SS',
    label: '688312.SS,  Shenzhen Yanmade Technology Inc.',
    name: 'Shenzhen Yanmade Technology Inc.',
    symbol: '688312.SS',
  },
  {
    value: '688309.SS',
    label: '688309.SS,  Niutech Environment Technology Corporation',
    name: 'Niutech Environment Technology Corporation',
    symbol: '688309.SS',
  },
  {
    value: '688305.SS',
    label: '688305.SS,  KEDE Numerical Control Co., Ltd.',
    name: 'KEDE Numerical Control Co., Ltd.',
    symbol: '688305.SS',
  },
  {
    value: '688303.SS',
    label: '688303.SS,  Xinjiang Daqo New Energy Co.,Ltd.',
    name: 'Xinjiang Daqo New Energy Co.,Ltd.',
    symbol: '688303.SS',
  },
  {
    value: '688301.SS',
    label: '688301.SS,  iRay Technology Company Limited',
    name: 'iRay Technology Company Limited',
    symbol: '688301.SS',
  },
  {
    value: '688300.SS',
    label: '688300.SS,  Novoray Corporation',
    name: 'Novoray Corporation',
    symbol: '688300.SS',
  },
  {
    value: '688299.SS',
    label: '688299.SS,  Ningbo Solartron Technology Co.,Ltd.',
    name: 'Ningbo Solartron Technology Co.,Ltd.',
    symbol: '688299.SS',
  },
  {
    value: '688298.SS',
    label: '688298.SS,  Zhejiang Orient Gene Biotech Co., Ltd.',
    name: 'Zhejiang Orient Gene Biotech Co., Ltd.',
    symbol: '688298.SS',
  },
  {
    value: '688296.SS',
    label: '688296.SS,  Zhejiang Heda Technology Co., Ltd.',
    name: 'Zhejiang Heda Technology Co., Ltd.',
    symbol: '688296.SS',
  },
  {
    value: '688289.SS',
    label: '688289.SS,  Sansure Biotech Inc.',
    name: 'Sansure Biotech Inc.',
    symbol: '688289.SS',
  },
  {
    value: '688288.SS',
    label: '688288.SS,  Hangzhou Hopechart IoT Technology Co.,Ltd',
    name: 'Hangzhou Hopechart IoT Technology Co.,Ltd',
    symbol: '688288.SS',
  },
  {
    value: '688286.SS',
    label: '688286.SS,  MEMSensing Microsystems (Suzhou, China) Co., Ltd.',
    name: 'MEMSensing Microsystems (Suzhou, China) Co., Ltd.',
    symbol: '688286.SS',
  },
  {
    value: '688285.SS',
    label:
      '688285.SS,  China Railway High-speed Electrification Equipment Corporation Limited',
    name: 'China Railway High-speed Electrification Equipment Corporation Limited',
    symbol: '688285.SS',
  },
  {
    value: '688280.SS',
    label: '688280.SS,  Jing-jin Electric Technologies Co.,Ltd.',
    name: 'Jing-jin Electric Technologies Co.,Ltd.',
    symbol: '688280.SS',
  },
  {
    value: '688278.SS',
    label: '688278.SS,  Xiamen Amoytop Biotech Co., Ltd.',
    name: 'Xiamen Amoytop Biotech Co., Ltd.',
    symbol: '688278.SS',
  },
  {
    value: '688277.SS',
    label: '688277.SS,  TINAVI Medical Technologies Co., Ltd.',
    name: 'TINAVI Medical Technologies Co., Ltd.',
    symbol: '688277.SS',
  },
  {
    value: '688272.SS',
    label: '688272.SS,  Beijing Fjr Optoelectronic Technology Co., Ltd.',
    name: 'Beijing Fjr Optoelectronic Technology Co., Ltd.',
    symbol: '688272.SS',
  },
  {
    value: '688266.SS',
    label: '688266.SS,  Suzhou Zelgen Biopharmaceuticals Co., Ltd.',
    name: 'Suzhou Zelgen Biopharmaceuticals Co., Ltd.',
    symbol: '688266.SS',
  },
  {
    value: '688265.SS',
    label: '688265.SS,  Shanghai Model Organisms Center, Inc.',
    name: 'Shanghai Model Organisms Center, Inc.',
    symbol: '688265.SS',
  },
  {
    value: '688262.SS',
    label: '688262.SS,  C*Core Technology Co., Ltd.',
    name: 'C*Core Technology Co., Ltd.',
    symbol: '688262.SS',
  },
  {
    value: '688257.SS',
    label: '688257.SS,  Shareate Tools Ltd.',
    name: 'Shareate Tools Ltd.',
    symbol: '688257.SS',
  },
  {
    value: '688256.SS',
    label: '688256.SS,  Cambricon Technologies Corporation Limited',
    name: 'Cambricon Technologies Corporation Limited',
    symbol: '688256.SS',
  },
  {
    value: '688255.SS',
    label: '688255.SS,  Hangzhou Kaierda Robot Technology Co.,Ltd',
    name: 'Hangzhou Kaierda Robot Technology Co.,Ltd',
    symbol: '688255.SS',
  },
  {
    value: '688248.SS',
    label: '688248.SS,  China Southern Power Grid Technology Co.,Ltd',
    name: 'China Southern Power Grid Technology Co.,Ltd',
    symbol: '688248.SS',
  },
  {
    value: '688246.SS',
    label: '688246.SS,  Goodwill E-Health Info Co., Ltd.',
    name: 'Goodwill E-Health Info Co., Ltd.',
    symbol: '688246.SS',
  },
  {
    value: '688239.SS',
    label: '688239.SS,  Guizhou Aviation Technical Development Co., Ltd',
    name: 'Guizhou Aviation Technical Development Co., Ltd',
    symbol: '688239.SS',
  },
  {
    value: '688236.SS',
    label: '688236.SS,  Beijing Chunlizhengda Medical Instruments Co., Ltd.',
    name: 'Beijing Chunlizhengda Medical Instruments Co., Ltd.',
    symbol: '688236.SS',
  },
  {
    value: '688233.SS',
    label: '688233.SS,  Thinkon Semiconductor Jinzhou Corp.',
    name: 'Thinkon Semiconductor Jinzhou Corp.',
    symbol: '688233.SS',
  },
  {
    value: '688232.SS',
    label: '688232.SS,  Guo Tai Epoint Software Co.,Ltd.',
    name: 'Guo Tai Epoint Software Co.,Ltd.',
    symbol: '688232.SS',
  },
  {
    value: '688230.SS',
    label: '688230.SS,  Shanghai Prisemi Electronics Co.,Ltd.',
    name: 'Shanghai Prisemi Electronics Co.,Ltd.',
    symbol: '688230.SS',
  },
  {
    value: '688229.SS',
    label: '688229.SS,  Bonree Data Technology Co., Ltd',
    name: 'Bonree Data Technology Co., Ltd',
    symbol: '688229.SS',
  },
  {
    value: '688227.SS',
    label: '688227.SS,  Bingo Software Co., Ltd.',
    name: 'Bingo Software Co., Ltd.',
    symbol: '688227.SS',
  },
  {
    value: '688226.SS',
    label: '688226.SS,  Wetown Electric Group Co., Ltd.',
    name: 'Wetown Electric Group Co., Ltd.',
    symbol: '688226.SS',
  },
  {
    value: '688138.SS',
    label: '688138.SS,  Shenzhen Qingyi Photomask Limited',
    name: 'Shenzhen Qingyi Photomask Limited',
    symbol: '688138.SS',
  },
  {
    value: '688128.SS',
    label:
      '688128.SS,  China National Electric Apparatus Research Institute Co., Ltd.',
    name: 'China National Electric Apparatus Research Institute Co., Ltd.',
    symbol: '688128.SS',
  },
  {
    value: '688127.SS',
    label: '688127.SS,  Zhejiang Lante Optics Co., Ltd.',
    name: 'Zhejiang Lante Optics Co., Ltd.',
    symbol: '688127.SS',
  },
  {
    value: '688122.SS',
    label: '688122.SS,  Western Superconducting Technologies Co., Ltd.',
    name: 'Western Superconducting Technologies Co., Ltd.',
    symbol: '688122.SS',
  },
  {
    value: '688121.SS',
    label: '688121.SS,  Shanghai Supezet Engineering Technology Corp., Ltd.',
    name: 'Shanghai Supezet Engineering Technology Corp., Ltd.',
    symbol: '688121.SS',
  },
  {
    value: '688116.SS',
    label: '688116.SS,  Jiangsu Cnano Technology Co., Ltd.',
    name: 'Jiangsu Cnano Technology Co., Ltd.',
    symbol: '688116.SS',
  },
  {
    value: '688112.SS',
    label: '688112.SS,  Siglent Technologies CO., Ltd.',
    name: 'Siglent Technologies CO., Ltd.',
    symbol: '688112.SS',
  },
  {
    value: '688110.SS',
    label: '688110.SS,  Dosilicon Co., Ltd.',
    name: 'Dosilicon Co., Ltd.',
    symbol: '688110.SS',
  },
  {
    value: '688108.SS',
    label: '688108.SS,  Sino Medical Sciences Technology Inc.',
    name: 'Sino Medical Sciences Technology Inc.',
    symbol: '688108.SS',
  },
  {
    value: '688107.SS',
    label: '688107.SS,  Shanghai Anlogic Infotech Co., Ltd.',
    name: 'Shanghai Anlogic Infotech Co., Ltd.',
    symbol: '688107.SS',
  },
  {
    value: '688106.SS',
    label: '688106.SS,  Suzhou Jinhong Gas Co.,Ltd.',
    name: 'Suzhou Jinhong Gas Co.,Ltd.',
    symbol: '688106.SS',
  },
  {
    value: '688105.SS',
    label: '688105.SS,  Nanjing Vazyme Biotech Co., Ltd.',
    name: 'Nanjing Vazyme Biotech Co., Ltd.',
    symbol: '688105.SS',
  },
  {
    value: '688103.SS',
    label: '688103.SS,  Kunshan GuoLi Electronic Technology Co., Ltd.',
    name: 'Kunshan GuoLi Electronic Technology Co., Ltd.',
    symbol: '688103.SS',
  },
  {
    value: '688101.SS',
    label: '688101.SS,  Suntar Environmental Technology Co., Ltd.',
    name: 'Suntar Environmental Technology Co., Ltd.',
    symbol: '688101.SS',
  },
  {
    value: '688100.SS',
    label: '688100.SS,  Willfar Information Technology Co., Ltd.',
    name: 'Willfar Information Technology Co., Ltd.',
    symbol: '688100.SS',
  },
  {
    value: '688099.SS',
    label: '688099.SS,  Amlogic (Shanghai) Co.,Ltd.',
    name: 'Amlogic (Shanghai) Co.,Ltd.',
    symbol: '688099.SS',
  },
  {
    value: '688098.SS',
    label: '688098.SS,  Shanghai Shen Lian Biomedical Corporation',
    name: 'Shanghai Shen Lian Biomedical Corporation',
    symbol: '688098.SS',
  },
  {
    value: '688096.SS',
    label: '688096.SS,  Jiangsu Jingyuan Environmental Protection Co., Ltd.',
    name: 'Jiangsu Jingyuan Environmental Protection Co., Ltd.',
    symbol: '688096.SS',
  },
  {
    value: '688095.SS',
    label: '688095.SS,  Fujian Foxit Software Development Joint Stock Co.,Ltd',
    name: 'Fujian Foxit Software Development Joint Stock Co.,Ltd',
    symbol: '688095.SS',
  },
  {
    value: '688093.SS',
    label: '688093.SS,  Suzhou Shihua New Material Technology Co., Ltd.',
    name: 'Suzhou Shihua New Material Technology Co., Ltd.',
    symbol: '688093.SS',
  },
  {
    value: '688091.SS',
    label: '688091.SS,  Shanghai Yizhong Pharmaceutical Co., Ltd.',
    name: 'Shanghai Yizhong Pharmaceutical Co., Ltd.',
    symbol: '688091.SS',
  },
  {
    value: '688090.SS',
    label:
      '688090.SS,  Guangzhou Risong Intelligent Technology Holding Co., Ltd.',
    name: 'Guangzhou Risong Intelligent Technology Holding Co., Ltd.',
    symbol: '688090.SS',
  },
  {
    value: '688088.SS',
    label: '688088.SS,  ArcSoft Corporation Limited',
    name: 'ArcSoft Corporation Limited',
    symbol: '688088.SS',
  },
  {
    value: '688087.SS',
    label: '688087.SS,  Shandong Intco Recycling Resources Co., Ltd.',
    name: 'Shandong Intco Recycling Resources Co., Ltd.',
    symbol: '688087.SS',
  },
  {
    value: '688086.SS',
    label: '688086.SS,  Amethystum Storage Technology Co., Ltd',
    name: 'Amethystum Storage Technology Co., Ltd',
    symbol: '688086.SS',
  },
  {
    value: '688085.SS',
    label: '688085.SS,  Shanghai Sanyou Medical Co., Ltd.',
    name: 'Shanghai Sanyou Medical Co., Ltd.',
    symbol: '688085.SS',
  },
  {
    value: '688083.SS',
    label: '688083.SS,  Zwsoft Co.,Ltd.',
    name: 'Zwsoft Co.,Ltd.',
    symbol: '688083.SS',
  },
  {
    value: '688082.SS',
    label: '688082.SS,  ACM Research (Shanghai), Inc.',
    name: 'ACM Research (Shanghai), Inc.',
    symbol: '688082.SS',
  },
  {
    value: '688081.SS',
    label: '688081.SS,  Wuhan Xingtu Xinke Electronics Co.,Ltd.',
    name: 'Wuhan Xingtu Xinke Electronics Co.,Ltd.',
    symbol: '688081.SS',
  },
  {
    value: '688080.SS',
    label: '688080.SS,  Beijing InHand Networks Technology Co., Ltd.',
    name: 'Beijing InHand Networks Technology Co., Ltd.',
    symbol: '688080.SS',
  },
  {
    value: '688077.SS',
    label: '688077.SS,  Earth-Panda Advanced Magnetic Material Co.,Ltd.',
    name: 'Earth-Panda Advanced Magnetic Material Co.,Ltd.',
    symbol: '688077.SS',
  },
  {
    value: '688075.SS',
    label: '688075.SS,  Assure Tech (Hangzhou) Co., Ltd.',
    name: 'Assure Tech (Hangzhou) Co., Ltd.',
    symbol: '688075.SS',
  },
  {
    value: '688071.SS',
    label: '688071.SS,  Shanghai W-Ibeda High Tech.Group Co.,Ltd.',
    name: 'Shanghai W-Ibeda High Tech.Group Co.,Ltd.',
    symbol: '688071.SS',
  },
  {
    value: '688069.SS',
    label: '688069.SS,  Wuxi Delinhai Environmental Technology Co., Ltd.',
    name: 'Wuxi Delinhai Environmental Technology Co., Ltd.',
    symbol: '688069.SS',
  },
  {
    value: '688068.SS',
    label: '688068.SS,  Beijing Hotgen Biotech Co., Ltd.',
    name: 'Beijing Hotgen Biotech Co., Ltd.',
    symbol: '688068.SS',
  },
  {
    value: '688067.SS',
    label: '688067.SS,  AVE Science&Technology CO.,LTD',
    name: 'AVE Science&Technology CO.,LTD',
    symbol: '688067.SS',
  },
  {
    value: '688066.SS',
    label: '688066.SS,  Piesat Information Technology Co., Ltd.',
    name: 'Piesat Information Technology Co., Ltd.',
    symbol: '688066.SS',
  },
  {
    value: '688065.SS',
    label: '688065.SS,  Cathay Biotech Inc.',
    name: 'Cathay Biotech Inc.',
    symbol: '688065.SS',
  },
  {
    value: '688059.SS',
    label: '688059.SS,  Zhuzhou Huarui Precision Cutting Tools Co.,Ltd',
    name: 'Zhuzhou Huarui Precision Cutting Tools Co.,Ltd',
    symbol: '688059.SS',
  },
  {
    value: '688058.SS',
    label: '688058.SS,  Beijing Baolande Software Corporation',
    name: 'Beijing Baolande Software Corporation',
    symbol: '688058.SS',
  },
  {
    value: '688056.SS',
    label: '688056.SS,  Beijing Labtech Instruments Co., Ltd.',
    name: 'Beijing Labtech Instruments Co., Ltd.',
    symbol: '688056.SS',
  },
  {
    value: '688055.SS',
    label: '688055.SS,  InfoVision Optoelectronics (Kunshan) Co., Ltd.',
    name: 'InfoVision Optoelectronics (Kunshan) Co., Ltd.',
    symbol: '688055.SS',
  },
  {
    value: '688050.SS',
    label: '688050.SS,  Eyebright Medical Technology (Beijing) Co., Ltd.',
    name: 'Eyebright Medical Technology (Beijing) Co., Ltd.',
    symbol: '688050.SS',
  },
  {
    value: '688049.SS',
    label: '688049.SS,  Actions Technology Co., Ltd.',
    name: 'Actions Technology Co., Ltd.',
    symbol: '688049.SS',
  },
  {
    value: '688038.SS',
    label: '688038.SS,  Wuhan Citms Technology CO.,LTD.',
    name: 'Wuhan Citms Technology CO.,LTD.',
    symbol: '688038.SS',
  },
  {
    value: '688036.SS',
    label: '688036.SS,  Shenzhen Transsion Holdings Co., Ltd.',
    name: 'Shenzhen Transsion Holdings Co., Ltd.',
    symbol: '688036.SS',
  },
  {
    value: '688033.SS',
    label: '688033.SS,  Beijing Tianyishangjia New Material Corp., Ltd.',
    name: 'Beijing Tianyishangjia New Material Corp., Ltd.',
    symbol: '688033.SS',
  },
  {
    value: '688032.SS',
    label: '688032.SS,  Hoymiles Power Electronics Inc.',
    name: 'Hoymiles Power Electronics Inc.',
    symbol: '688032.SS',
  },
  {
    value: '688030.SS',
    label: '688030.SS,  Hillstone Networks Co., Ltd.',
    name: 'Hillstone Networks Co., Ltd.',
    symbol: '688030.SS',
  },
  {
    value: '688029.SS',
    label: '688029.SS,  Micro-Tech (Nanjing) Co., Ltd.',
    name: 'Micro-Tech (Nanjing) Co., Ltd.',
    symbol: '688029.SS',
  },
  {
    value: '688028.SS',
    label: '688028.SS,  Beijing Worldia Diamond Tools Co.,Ltd.',
    name: 'Beijing Worldia Diamond Tools Co.,Ltd.',
    symbol: '688028.SS',
  },
  {
    value: '688027.SS',
    label: '688027.SS,  QuantumCTek Co., Ltd.',
    name: 'QuantumCTek Co., Ltd.',
    symbol: '688027.SS',
  },
  {
    value: '688026.SS',
    label: '688026.SS,  Guangzhou Jet Bio-Filtration Co., Ltd.',
    name: 'Guangzhou Jet Bio-Filtration Co., Ltd.',
    symbol: '688026.SS',
  },
  {
    value: '688023.SS',
    label: '688023.SS,  DBAPPSecurity Co., Ltd.',
    name: 'DBAPPSecurity Co., Ltd.',
    symbol: '688023.SS',
  },
  {
    value: '688022.SS',
    label: '688022.SS,  Suzhou Harmontronics Automation Technology Co., Ltd',
    name: 'Suzhou Harmontronics Automation Technology Co., Ltd',
    symbol: '688022.SS',
  },
  {
    value: '688021.SS',
    label: '688021.SS,  Aofu Environmental Technology Co.,Ltd.',
    name: 'Aofu Environmental Technology Co.,Ltd.',
    symbol: '688021.SS',
  },
  {
    value: '688020.SS',
    label: '688020.SS,  Guangzhou Fangbang Electronics Co.,Ltd',
    name: 'Guangzhou Fangbang Electronics Co.,Ltd',
    symbol: '688020.SS',
  },
  {
    value: '688019.SS',
    label: '688019.SS,  Anji Microelectronics Technology (Shanghai) Co., Ltd.',
    name: 'Anji Microelectronics Technology (Shanghai) Co., Ltd.',
    symbol: '688019.SS',
  },
  {
    value: '688018.SS',
    label: '688018.SS,  Espressif Systems (Shanghai) Co., Ltd.',
    name: 'Espressif Systems (Shanghai) Co., Ltd.',
    symbol: '688018.SS',
  },
  {
    value: '688017.SS',
    label: '688017.SS,  Leader Harmonious Drive Systems Co., Ltd.',
    name: 'Leader Harmonious Drive Systems Co., Ltd.',
    symbol: '688017.SS',
  },
  {
    value: '688016.SS',
    label: '688016.SS,  Shanghai MicroPort Endovascular MedTech Co., Ltd.',
    name: 'Shanghai MicroPort Endovascular MedTech Co., Ltd.',
    symbol: '688016.SS',
  },
  {
    value: '688015.SS',
    label: '688015.SS,  Traffic Control Technology Co., Ltd.',
    name: 'Traffic Control Technology Co., Ltd.',
    symbol: '688015.SS',
  },
  {
    value: '688013.SS',
    label: '688013.SS,  Touchstone International Medical Science Co., Ltd.',
    name: 'Touchstone International Medical Science Co., Ltd.',
    symbol: '688013.SS',
  },
  {
    value: '688011.SS',
    label: '688011.SS,  Harbin Xinguang Optic-Electronics Technology Co.,Ltd.',
    name: 'Harbin Xinguang Optic-Electronics Technology Co.,Ltd.',
    symbol: '688011.SS',
  },
  {
    value: '688010.SS',
    label: '688010.SS,  Fujian Forecam Optics Co., Ltd.',
    name: 'Fujian Forecam Optics Co., Ltd.',
    symbol: '688010.SS',
  },
  {
    value: '688007.SS',
    label: '688007.SS,  Appotronics Corporation Limited',
    name: 'Appotronics Corporation Limited',
    symbol: '688007.SS',
  },
  {
    value: '688005.SS',
    label: '688005.SS,  Ningbo Ronbay New Energy Technology Co., Ltd.',
    name: 'Ningbo Ronbay New Energy Technology Co., Ltd.',
    symbol: '688005.SS',
  },
  {
    value: '688004.SS',
    label: '688004.SS,  Beijing Bohui Science & Technology Co., Ltd.',
    name: 'Beijing Bohui Science & Technology Co., Ltd.',
    symbol: '688004.SS',
  },
  {
    value: '688003.SS',
    label: '688003.SS,  Suzhou TZTEK Technology Co., Ltd',
    name: 'Suzhou TZTEK Technology Co., Ltd',
    symbol: '688003.SS',
  },
  {
    value: '688001.SS',
    label: '688001.SS,  Suzhou HYC Technology Co.,Ltd.',
    name: 'Suzhou HYC Technology Co.,Ltd.',
    symbol: '688001.SS',
  },
  {
    value: 'CPTN',
    label: 'CPTN,  Cepton, Inc.',
    name: 'Cepton, Inc.',
    symbol: 'CPTN',
  },
  {
    value: 'RTL',
    label: 'RTL,  The Necessity Retail REIT, Inc.',
    name: 'The Necessity Retail REIT, Inc.',
    symbol: 'RTL',
  },
  {
    value: 'BRCC',
    label: 'BRCC,  BRC Inc.',
    name: 'BRC Inc.',
    symbol: 'BRCC',
  },
  {
    value: 'QTEK',
    label: 'QTEK,  QualTek Services Inc.',
    name: 'QualTek Services Inc.',
    symbol: 'QTEK',
  },
  {
    value: 'RBZHF',
    label: 'RBZHF,  Reebonz Holding Limited',
    name: 'Reebonz Holding Limited',
    symbol: 'RBZHF',
  },
  {
    value: 'VBOC',
    label: 'VBOC,  Viscogliosi Brothers Acquisition Corp.',
    name: 'Viscogliosi Brothers Acquisition Corp.',
    symbol: 'VBOC',
  },
  {
    value: 'VBOCW',
    label: 'VBOCW,  Viscogliosi Brothers Acquisition Corp.',
    name: 'Viscogliosi Brothers Acquisition Corp.',
    symbol: 'VBOCW',
  },
  {
    value: '300117.SZ',
    label:
      '300117.SZ,  Beijing JIAYU Door, Window and Curtain Wall Joint-Stock Co., Ltd.',
    name: 'Beijing JIAYU Door, Window and Curtain Wall Joint-Stock Co., Ltd.',
    symbol: '300117.SZ',
  },
  {
    value: 'CXAC',
    label: 'CXAC,  C5 Acquisition Corporation',
    name: 'C5 Acquisition Corporation',
    symbol: 'CXAC',
  },
  {
    value: 'SCRMW',
    label: 'SCRMW,  Screaming Eagle Acquisition Corp.',
    name: 'Screaming Eagle Acquisition Corp.',
    symbol: 'SCRMW',
  },
  {
    value: 'SCRM',
    label: 'SCRM,  Screaming Eagle Acquisition Corp.',
    name: 'Screaming Eagle Acquisition Corp.',
    symbol: 'SCRM',
  },
  {
    value: 'SHUAU',
    label: 'SHUAU,  SHUAA Partners Acquisition Corp I',
    name: 'SHUAA Partners Acquisition Corp I',
    symbol: 'SHUAU',
  },
  {
    value: 'SPCMU',
    label: 'SPCMU,  Sound Point Acquisition Corp I, Ltd',
    name: 'Sound Point Acquisition Corp I, Ltd',
    symbol: 'SPCMU',
  },
  {
    value: 'SRR.V',
    label: 'SRR.V,  Source Rock Royalties Ltd.',
    name: 'Source Rock Royalties Ltd.',
    symbol: 'SRR.V',
  },
  {
    value: '300094.SZ',
    label: '300094.SZ,  Zhanjiang Guolian Aquatic Products Co., Ltd.',
    name: 'Zhanjiang Guolian Aquatic Products Co., Ltd.',
    symbol: '300094.SZ',
  },
  {
    value: 'KYCHR',
    label: 'KYCHR,  Keyarch Acquisition Corporation',
    name: 'Keyarch Acquisition Corporation',
    symbol: 'KYCHR',
  },
  {
    value: 'KYCHW',
    label: 'KYCHW,  Keyarch Acquisition Corporation',
    name: 'Keyarch Acquisition Corporation',
    symbol: 'KYCHW',
  },
  {
    value: 'KYCH',
    label: 'KYCH,  Keyarch Acquisition Corporation',
    name: 'Keyarch Acquisition Corporation',
    symbol: 'KYCH',
  },
  {
    value: 'RGTI',
    label: 'RGTI,  Rigetti Computing, Inc.',
    name: 'Rigetti Computing, Inc.',
    symbol: 'RGTI',
  },
  {
    value: 'RGTIW',
    label: 'RGTIW,  Rigetti Computing, Inc.',
    name: 'Rigetti Computing, Inc.',
    symbol: 'RGTIW',
  },
  {
    value: 'URAH.L',
    label: 'URAH.L,  URA Holdings Plc',
    name: 'URA Holdings Plc',
    symbol: 'URAH.L',
  },
  {
    value: 'HAMA.L',
    label: 'HAMA.L,  Hamak Gold Limited',
    name: 'Hamak Gold Limited',
    symbol: 'HAMA.L',
  },
  {
    value: 'LGMK',
    label: 'LGMK,  LogicMark, Inc.',
    name: 'LogicMark, Inc.',
    symbol: 'LGMK',
  },
  {
    value: 'VCXB',
    label: 'VCXB,  10X Capital Venture Acquisition Corp. III',
    name: '10X Capital Venture Acquisition Corp. III',
    symbol: 'VCXB',
  },
  {
    value: 'GHIX',
    label: 'GHIX,  Gores Holdings IX, Inc.',
    name: 'Gores Holdings IX, Inc.',
    symbol: 'GHIX',
  },
  {
    value: 'GHIXW',
    label: 'GHIXW,  Gores Holdings IX, Inc.',
    name: 'Gores Holdings IX, Inc.',
    symbol: 'GHIXW',
  },
  {
    value: 'PPYAW',
    label: 'PPYAW,  Papaya Growth Opportunity Corp. I',
    name: 'Papaya Growth Opportunity Corp. I',
    symbol: 'PPYAW',
  },
  {
    value: 'ITAQ',
    label: 'ITAQ,  Industrial Tech Acquisitions II, Inc.',
    name: 'Industrial Tech Acquisitions II, Inc.',
    symbol: 'ITAQ',
  },
  {
    value: 'PPYA',
    label: 'PPYA,  Papaya Growth Opportunity Corp. I',
    name: 'Papaya Growth Opportunity Corp. I',
    symbol: 'PPYA',
  },
  {
    value: 'ITAQW',
    label: 'ITAQW,  Industrial Tech Acquisitions II, Inc.',
    name: 'Industrial Tech Acquisitions II, Inc.',
    symbol: 'ITAQW',
  },
  {
    value: 'APO.JO',
    label: 'APO.JO,  aReit Prop Limited',
    name: 'aReit Prop Limited',
    symbol: 'APO.JO',
  },
  {
    value: '0P0001971O.F',
    label: '0P0001971O.F,  Indexa Mas Rentabilidad Acciones PP',
    name: 'Indexa Mas Rentabilidad Acciones PP',
    symbol: '0P0001971O.F',
  },
  {
    value: '0P0001971N.F',
    label: '0P0001971N.F,  Indexa Mas Rentabilidad Bonos PP',
    name: 'Indexa Mas Rentabilidad Bonos PP',
    symbol: '0P0001971N.F',
  },
  {
    value: '0P0000YX0L.L',
    label:
      '0P0000YX0L.L,  Vanguard U.K. Short-Term Investment Grade Bond Index Fund GBP Acc',
    name: 'Vanguard U.K. Short-Term Investment Grade Bond Index Fund GBP Acc',
    symbol: '0P0000YX0L.L',
  },
  {
    value: '0P0000YX0K.L',
    label:
      '0P0000YX0K.L,  Vanguard U.K. Short-Term Investment Grade Bond Index Fund GBP Dist',
    name: 'Vanguard U.K. Short-Term Investment Grade Bond Index Fund GBP Dist',
    symbol: '0P0000YX0K.L',
  },
  {
    value: '0P00011HBV.F',
    label:
      '0P00011HBV.F,  Vanguard ESG Developed World All Cap Equity Index Fund Institutional Plus EUR Acc',
    name: 'Vanguard ESG Developed World All Cap Equity Index Fund Institutional Plus EUR Acc',
    symbol: '0P00011HBV.F',
  },
  {
    value: '0P00011HBU.F',
    label:
      '0P00011HBU.F,  Vanguard SRI European Stock Fund Institutional Plus EUR Acc',
    name: 'Vanguard SRI European Stock Fund Institutional Plus EUR Acc',
    symbol: '0P00011HBU.F',
  },
  {
    value: '0P00011HBM.F',
    label:
      '0P00011HBM.F,  Vanguard Global Stock Index Fund Institutional Plus EUR Acc',
    name: 'Vanguard Global Stock Index Fund Institutional Plus EUR Acc',
    symbol: '0P00011HBM.F',
  },
  {
    value: '0P00011HBN.F',
    label:
      '0P00011HBN.F,  Vanguard Japan Stock Index Fund Institutional Plus EUR Acc',
    name: 'Vanguard Japan Stock Index Fund Institutional Plus EUR Acc',
    symbol: '0P00011HBN.F',
  },
  {
    value: '0P00011HBL.F',
    label:
      '0P00011HBL.F,  Vanguard European Stock Index Fund Institutional Plus EUR Acc',
    name: 'Vanguard European Stock Index Fund Institutional Plus EUR Acc',
    symbol: '0P00011HBL.F',
  },
  {
    value: '0P00011HBQ.F',
    label:
      '0P00011HBQ.F,  Vanguard Emerging Markets Stock Index Fund Institutional Plus EUR Acc',
    name: 'Vanguard Emerging Markets Stock Index Fund Institutional Plus EUR Acc',
    symbol: '0P00011HBQ.F',
  },
  {
    value: '0P00011IIE.F',
    label:
      '0P00011IIE.F,  Vanguard Euro Investment Grade Bond Index Fund Institutional Plus EUR Acc',
    name: 'Vanguard Euro Investment Grade Bond Index Fund Institutional Plus EUR Acc',
    symbol: '0P00011IIE.F',
  },
  {
    value: '0P00011HBR.F',
    label:
      '0P00011HBR.F,  Vanguard Euro Government Bond Index Fund Institutional Plus EUR Acc',
    name: 'Vanguard Euro Government Bond Index Fund Institutional Plus EUR Acc',
    symbol: '0P00011HBR.F',
  },
  {
    value: '0P00011HBK.F',
    label:
      '0P00011HBK.F,  Vanguard U.S. 500 Stock Index Fund Institutional Plus EUR Acc',
    name: 'Vanguard U.S. 500 Stock Index Fund Institutional Plus EUR Acc',
    symbol: '0P00011HBK.F',
  },
  {
    value: '0P00011OE5.F',
    label:
      '0P00011OE5.F,  Vanguard U.S. Investment Grade Credit Index Fund Institutional Plus EUR Acc',
    name: 'Vanguard U.S. Investment Grade Credit Index Fund Institutional Plus EUR Acc',
    symbol: '0P00011OE5.F',
  },
  {
    value: '0P00011OE2.F',
    label:
      '0P00011OE2.F,  Vanguard Global Small-Cap Index Fund Institutional Plus EUR Acc',
    name: 'Vanguard Global Small-Cap Index Fund Institutional Plus EUR Acc',
    symbol: '0P00011OE2.F',
  },
  {
    value: '0P00011WUF.L',
    label:
      '0P00011WUF.L,  Vanguard Euro Investment Grade Bond Index Fund GBP Hedged Acc',
    name: 'Vanguard Euro Investment Grade Bond Index Fund GBP Hedged Acc',
    symbol: '0P00011WUF.L',
  },
  {
    value: '0P00011WUG.L',
    label:
      '0P00011WUG.L,  Vanguard Euro Government Bond Index Fund GBP Hedged Acc',
    name: 'Vanguard Euro Government Bond Index Fund GBP Hedged Acc',
    symbol: '0P00011WUG.L',
  },
  {
    value: '0P00011WUE.L',
    label:
      '0P00011WUE.L,  Vanguard Japan Government Bond Index Fund GBP Hedged Acc',
    name: 'Vanguard Japan Government Bond Index Fund GBP Hedged Acc',
    symbol: '0P00011WUE.L',
  },
  {
    value: '0P00011WUC.L',
    label:
      '0P00011WUC.L,  Vanguard U.S. Investment Grade Credit Index Fund GBP Hedged Acc',
    name: 'Vanguard U.S. Investment Grade Credit Index Fund GBP Hedged Acc',
    symbol: '0P00011WUC.L',
  },
  {
    value: '0P00011WUD.L',
    label:
      '0P00011WUD.L,  Vanguard U.S. Government Bond Index Fund GBP Hedged Acc',
    name: 'Vanguard U.S. Government Bond Index Fund GBP Hedged Acc',
    symbol: '0P00011WUD.L',
  },
  {
    value: '0P00011WUA.L',
    label:
      '0P00011WUA.L,  Vanguard Global Short-Term Bond Index Fund GBP Hedged Acc',
    name: 'Vanguard Global Short-Term Bond Index Fund GBP Hedged Acc',
    symbol: '0P00011WUA.L',
  },
  {
    value: '0P00011WU9.L',
    label:
      '0P00011WU9.L,  Vanguard Global Short-Term Bond Index Fund GBP Hedged Dist',
    name: 'Vanguard Global Short-Term Bond Index Fund GBP Hedged Dist',
    symbol: '0P00011WU9.L',
  },
  {
    value: '0P00012I6A.F',
    label: '0P00012I6A.F,  Vanguard Emerging Markets Stock Index Fund EUR Acc',
    name: 'Vanguard Emerging Markets Stock Index Fund EUR Acc',
    symbol: '0P00012I6A.F',
  },
  {
    value: '0P00012I66.F',
    label:
      '0P00012I66.F,  Vanguard Global Small-Cap Index Fund Investor EUR Accumulation',
    name: 'Vanguard Global Small-Cap Index Fund Investor EUR Accumulation',
    symbol: '0P00012I66.F',
  },
  {
    value: '0P00012I6C.T',
    label:
      '0P00012I6C.T,  Vanguard Japan Stock Index Fund Institutional Plus JPY Acc',
    name: 'Vanguard Japan Stock Index Fund Institutional Plus JPY Acc',
    symbol: '0P00012I6C.T',
  },
  {
    value: '0P00012I68.F',
    label:
      '0P00012I68.F,  Vanguard Eurozone Inflation-Linked Bond Index Fund Institutional Plus EUR Acc',
    name: 'Vanguard Eurozone Inflation-Linked Bond Index Fund Institutional Plus EUR Acc',
    symbol: '0P00012I68.F',
  },
  {
    value: '0P00012I69.F',
    label: '0P00012I69.F,  Vanguard Global Bond Index Fund EUR Hedged Acc',
    name: 'Vanguard Global Bond Index Fund EUR Hedged Acc',
    symbol: '0P00012I69.F',
  },
  {
    value: '0P00012I6E.F',
    label:
      '0P00012I6E.F,  Vanguard Global Bond Index Fund Institutional Plus EUR Hedged Acc',
    name: 'Vanguard Global Bond Index Fund Institutional Plus EUR Hedged Acc',
    symbol: '0P00012I6E.F',
  },
  {
    value: '0P00012I6P.F',
    label: '0P00012I6P.F,  Vanguard Pacific ex-Japan Stock Index Fund EUR Acc',
    name: 'Vanguard Pacific ex-Japan Stock Index Fund EUR Acc',
    symbol: '0P00012I6P.F',
  },
  {
    value: '0P00012I6B.F',
    label:
      '0P00012I6B.F,  Vanguard Pacific ex-Japan Stock Index Fund Institutional Plus EUR Acc',
    name: 'Vanguard Pacific ex-Japan Stock Index Fund Institutional Plus EUR Acc',
    symbol: '0P00012I6B.F',
  },
  {
    value: '0P00012NJH.F',
    label:
      '0P00012NJH.F,  Vanguard Global Short-Term Bond Index Fund EUR Hedged Acc',
    name: 'Vanguard Global Short-Term Bond Index Fund EUR Hedged Acc',
    symbol: '0P00012NJH.F',
  },
  {
    value: '0P00012NJA.F',
    label:
      '0P00012NJA.F,  Vanguard Global Short-Term Bond Index Fund Investor EUR Hedged Accumulation',
    name: 'Vanguard Global Short-Term Bond Index Fund Investor EUR Hedged Accumulation',
    symbol: '0P00012NJA.F',
  },
  {
    value: '0P00012NJC.SW',
    label:
      '0P00012NJC.SW,  Vanguard Global Short-Term Bond Index Fund Institutional Plus CHF Hedged Acc',
    name: 'Vanguard Global Short-Term Bond Index Fund Institutional Plus CHF Hedged Acc',
    symbol: '0P00012NJC.SW',
  },
  {
    value: '0P00012NJF.SW',
    label:
      '0P00012NJF.SW,  Vanguard Global Short-Term Bond Index Fund CHF Hedged Acc',
    name: 'Vanguard Global Short-Term Bond Index Fund CHF Hedged Acc',
    symbol: '0P00012NJF.SW',
  },
  {
    value: '0P00012NJE.F',
    label:
      '0P00012NJE.F,  Vanguard Global Short-Term Bond Index Fund Institutional Plus EUR Hedged Acc',
    name: 'Vanguard Global Short-Term Bond Index Fund Institutional Plus EUR Hedged Acc',
    symbol: '0P00012NJE.F',
  },
  {
    value: '0P00000G12.F',
    label:
      '0P00000G12.F,  Vanguard U.S. 500 Stock Index Fund Investor EUR Accumulation',
    name: 'Vanguard U.S. 500 Stock Index Fund Investor EUR Accumulation',
    symbol: '0P00000G12.F',
  },
  {
    value: '0P000147LN.L',
    label:
      '0P000147LN.L,  Vanguard Emerging Markets Stock Index Fund Institutional Plus GBP Dist',
    name: 'Vanguard Emerging Markets Stock Index Fund Institutional Plus GBP Dist',
    symbol: '0P000147LN.L',
  },
  {
    value: '0P000147LM.L',
    label:
      '0P000147LM.L,  Vanguard Emerging Markets Stock Index Fund Institutional Plus GBP Acc',
    name: 'Vanguard Emerging Markets Stock Index Fund Institutional Plus GBP Acc',
    symbol: '0P000147LM.L',
  },
  {
    value: '0P000147M6.L',
    label:
      '0P000147M6.L,  Vanguard Pacific ex-Japan Stock Index Fund Institutional Plus GBP Acc',
    name: 'Vanguard Pacific ex-Japan Stock Index Fund Institutional Plus GBP Acc',
    symbol: '0P000147M6.L',
  },
  {
    value: '0P000147M5.L',
    label:
      '0P000147M5.L,  Vanguard Pacific ex-Japan Stock Index Fund Institutional Plus GBP Dist',
    name: 'Vanguard Pacific ex-Japan Stock Index Fund Institutional Plus GBP Dist',
    symbol: '0P000147M5.L',
  },
  {
    value: '0P000147M4.L',
    label:
      '0P000147M4.L,  Vanguard Japan Stock Index Fund Institutional Plus GBP Acc',
    name: 'Vanguard Japan Stock Index Fund Institutional Plus GBP Acc',
    symbol: '0P000147M4.L',
  },
  {
    value: '0P000147M3.L',
    label:
      '0P000147M3.L,  Vanguard Japan Stock Index Fund Institutional Plus GBP Dist',
    name: 'Vanguard Japan Stock Index Fund Institutional Plus GBP Dist',
    symbol: '0P000147M3.L',
  },
  {
    value: '0P0001481A.T',
    label:
      '0P0001481A.T,  Vanguard Japan Government Bond Index Fund Institutional Plus JPY Acc',
    name: 'Vanguard Japan Government Bond Index Fund Institutional Plus JPY Acc',
    symbol: '0P0001481A.T',
  },
  {
    value: '0P0001481C.SW',
    label:
      '0P0001481C.SW,  Vanguard Global Bond Index Fund Institutional Plus CHF Hedged Acc',
    name: 'Vanguard Global Bond Index Fund Institutional Plus CHF Hedged Acc',
    symbol: '0P0001481C.SW',
  },
  {
    value: '0P0001481B.F',
    label:
      '0P0001481B.F,  Vanguard Eurozone Stock Index Fund Institutional Plus EUR Acc',
    name: 'Vanguard Eurozone Stock Index Fund Institutional Plus EUR Acc',
    symbol: '0P0001481B.F',
  },
  {
    value: '0P000147MA.L',
    label:
      '0P000147MA.L,  Vanguard ESG Developed World All Cap Equity Index Fund Institutional Plus GBP Acc',
    name: 'Vanguard ESG Developed World All Cap Equity Index Fund Institutional Plus GBP Acc',
    symbol: '0P000147MA.L',
  },
  {
    value: '0P000147Q3.L',
    label:
      '0P000147Q3.L,  Vanguard FTSE U.K. All Share Index Unit Trust GBP Inc',
    name: 'Vanguard FTSE U.K. All Share Index Unit Trust GBP Inc',
    symbol: '0P000147Q3.L',
  },
  {
    value: '0P000147MB.L',
    label:
      '0P000147MB.L,  Vanguard U.K. Government Bond Index Fund Institutional Plus GBP Dist',
    name: 'Vanguard U.K. Government Bond Index Fund Institutional Plus GBP Dist',
    symbol: '0P000147MB.L',
  },
  {
    value: '0P000147LZ.L',
    label:
      '0P000147LZ.L,  Vanguard Global Short-Term Bond Index Fund Institutional Plus GBP Hedged Acc',
    name: 'Vanguard Global Short-Term Bond Index Fund Institutional Plus GBP Hedged Acc',
    symbol: '0P000147LZ.L',
  },
  {
    value: '0P000147M0.L',
    label:
      '0P000147M0.L,  Vanguard Global Short-Term Bond Index Fund Institutional Plus GBP Hedged Dist',
    name: 'Vanguard Global Short-Term Bond Index Fund Institutional Plus GBP Hedged Dist',
    symbol: '0P000147M0.L',
  },
  {
    value: '0P000147M1.L',
    label:
      '0P000147M1.L,  Vanguard Global Small-Cap Index Fund Institutional Plus GBP Acc',
    name: 'Vanguard Global Small-Cap Index Fund Institutional Plus GBP Acc',
    symbol: '0P000147M1.L',
  },
  {
    value: '0P000147LV.L',
    label:
      '0P000147LV.L,  Vanguard Global Bond Index Fund Institutional Plus GBP Hedged Acc',
    name: 'Vanguard Global Bond Index Fund Institutional Plus GBP Hedged Acc',
    symbol: '0P000147LV.L',
  },
  {
    value: '0P000147M2.L',
    label:
      '0P000147M2.L,  Vanguard Global Small-Cap Index Fund Institutional Plus GBP Dist',
    name: 'Vanguard Global Small-Cap Index Fund Institutional Plus GBP Dist',
    symbol: '0P000147M2.L',
  },
  {
    value: '0P000147LW.L',
    label:
      '0P000147LW.L,  Vanguard Global Bond Index Fund Institutional Plus GBP Hedged Dist',
    name: 'Vanguard Global Bond Index Fund Institutional Plus GBP Hedged Dist',
    symbol: '0P000147LW.L',
  },
  {
    value: '0P000147MG.L',
    label:
      '0P000147MG.L,  Vanguard U.K. Short-Term Investment Grade Bond Index Fund Institutional Plus GBP Acc',
    name: 'Vanguard U.K. Short-Term Investment Grade Bond Index Fund Institutional Plus GBP Acc',
    symbol: '0P000147MG.L',
  },
  {
    value: '0P000147MF.L',
    label:
      '0P000147MF.L,  Vanguard U.K. Short-Term Investment Grade Bond Index Fund Institutional Plus GBP Dist',
    name: 'Vanguard U.K. Short-Term Investment Grade Bond Index Fund Institutional Plus GBP Dist',
    symbol: '0P000147MF.L',
  },
  {
    value: '0P000147MD.L',
    label:
      '0P000147MD.L,  Vanguard U.K. Investment Grade Bond Index Fund Institutional Plus GBP Acc',
    name: 'Vanguard U.K. Investment Grade Bond Index Fund Institutional Plus GBP Acc',
    symbol: '0P000147MD.L',
  },
  {
    value: '0P000147ME.L',
    label:
      '0P000147ME.L,  Vanguard U.K. Investment Grade Bond Index Fund Institutional Plus GBP Dist',
    name: 'Vanguard U.K. Investment Grade Bond Index Fund Institutional Plus GBP Dist',
    symbol: '0P000147ME.L',
  },
  {
    value: '0P000147MC.L',
    label:
      '0P000147MC.L,  Vanguard U.K. Government Bond Index Fund Institutional Plus GBP Acc',
    name: 'Vanguard U.K. Government Bond Index Fund Institutional Plus GBP Acc',
    symbol: '0P000147MC.L',
  },
  {
    value: '0P00015M0N.SW',
    label:
      '0P00015M0N.SW,  Vanguard Emerging Markets Stock Index Fund Institutional Plus CHF Acc',
    name: 'Vanguard Emerging Markets Stock Index Fund Institutional Plus CHF Acc',
    symbol: '0P00015M0N.SW',
  },
  {
    value: '0P00015M0M.F',
    label:
      '0P00015M0M.F,  Vanguard 20+ Year Euro Treasury Index Fund Institutional Plus EUR Acc',
    name: 'Vanguard 20+ Year Euro Treasury Index Fund Institutional Plus EUR Acc',
    symbol: '0P00015M0M.F',
  },
  {
    value: '0P00001CGE.T',
    label: '0P00001CGE.T,  Vanguard Japan Stock Index Fund JPY Acc',
    name: 'Vanguard Japan Stock Index Fund JPY Acc',
    symbol: '0P00001CGE.T',
  },
  {
    value: '0P000015J7.F',
    label: '0P000015J7.F,  Vanguard Global Stock Index Fund EUR Hedged Acc',
    name: 'Vanguard Global Stock Index Fund EUR Hedged Acc',
    symbol: '0P000015J7.F',
  },
  {
    value: '0P000019FE.F',
    label:
      '0P000019FE.F,  Vanguard Eurozone Inflation-Linked Bond Index Fund EUR Acc',
    name: 'Vanguard Eurozone Inflation-Linked Bond Index Fund EUR Acc',
    symbol: '0P000019FE.F',
  },
  {
    value: '0P00016QKO.F',
    label:
      '0P00016QKO.F,  Vanguard U.S. Investment Grade Credit Index Fund Institutional Plus EUR Hedged Acc',
    name: 'Vanguard U.S. Investment Grade Credit Index Fund Institutional Plus EUR Hedged Acc',
    symbol: '0P00016QKO.F',
  },
  {
    value: '0P00017UWV.L',
    label:
      '0P00017UWV.L,  Vanguard Target Retirement 2055 Fund Accumulation Shares',
    name: 'Vanguard Target Retirement 2055 Fund Accumulation Shares',
    symbol: '0P00017UWV.L',
  },
  {
    value: '0P00017UWU.L',
    label:
      '0P00017UWU.L,  Vanguard Target Retirement 2050 Fund Accumulation Shares',
    name: 'Vanguard Target Retirement 2050 Fund Accumulation Shares',
    symbol: '0P00017UWU.L',
  },
  {
    value: '0P00017UWR.L',
    label:
      '0P00017UWR.L,  Vanguard Target Retirement 2035 Fund Accumulation Shares',
    name: 'Vanguard Target Retirement 2035 Fund Accumulation Shares',
    symbol: '0P00017UWR.L',
  },
  {
    value: '0P00017UWT.L',
    label:
      '0P00017UWT.L,  Vanguard Target Retirement 2045 Fund Accumulation Shares',
    name: 'Vanguard Target Retirement 2045 Fund Accumulation Shares',
    symbol: '0P00017UWT.L',
  },
  {
    value: '0P00017UWQ.L',
    label:
      '0P00017UWQ.L,  Vanguard Target Retirement 2030 Fund Accumulation Shares',
    name: 'Vanguard Target Retirement 2030 Fund Accumulation Shares',
    symbol: '0P00017UWQ.L',
  },
  {
    value: '0P00017UWS.L',
    label:
      '0P00017UWS.L,  Vanguard Target Retirement 2040 Fund Accumulation Shares',
    name: 'Vanguard Target Retirement 2040 Fund Accumulation Shares',
    symbol: '0P00017UWS.L',
  },
  {
    value: '0P00017UWP.L',
    label:
      '0P00017UWP.L,  Vanguard Target Retirement 2025 Fund Accumulation Shares',
    name: 'Vanguard Target Retirement 2025 Fund Accumulation Shares',
    symbol: '0P00017UWP.L',
  },
  {
    value: '0P00017UWO.L',
    label:
      '0P00017UWO.L,  Vanguard Target Retirement 2020 Fund Accumulation Shares',
    name: 'Vanguard Target Retirement 2020 Fund Accumulation Shares',
    symbol: '0P00017UWO.L',
  },
  {
    value: '0P00017UWM.L',
    label:
      '0P00017UWM.L,  Vanguard Target Retirement 2015 Fund Accumulation Shares',
    name: 'Vanguard Target Retirement 2015 Fund Accumulation Shares',
    symbol: '0P00017UWM.L',
  },
  {
    value: '0P00000W6H.F',
    label:
      '0P00000W6H.F,  Vanguard U.S. Government Bond Index Fund EUR Hedged Acc',
    name: 'Vanguard U.S. Government Bond Index Fund EUR Hedged Acc',
    symbol: '0P00000W6H.F',
  },
  {
    value: '0P000185T0.L',
    label: '0P000185T0.L,  Vanguard Global Equity Income Fund A GBP Inc',
    name: 'Vanguard Global Equity Income Fund A GBP Inc',
    symbol: '0P000185T0.L',
  },
  {
    value: '0P000185T3.L',
    label: '0P000185T3.L,  Vanguard Global Equity Fund A GBP Acc',
    name: 'Vanguard Global Equity Fund A GBP Acc',
    symbol: '0P000185T3.L',
  },
  {
    value: '0P000185T5.L',
    label: '0P000185T5.L,  Vanguard Global Balanced Fund A GBP Acc',
    name: 'Vanguard Global Balanced Fund A GBP Acc',
    symbol: '0P000185T5.L',
  },
  {
    value: '0P000185T6.L',
    label: '0P000185T6.L,  Vanguard Global Balanced Fund A GBP Inc',
    name: 'Vanguard Global Balanced Fund A GBP Inc',
    symbol: '0P000185T6.L',
  },
  {
    value: '0P000185T1.L',
    label: '0P000185T1.L,  Vanguard Global Emerging Markets Fund A GBP Acc',
    name: 'Vanguard Global Emerging Markets Fund A GBP Acc',
    symbol: '0P000185T1.L',
  },
  {
    value: '0P000185T2.L',
    label: '0P000185T2.L,  Vanguard Global Emerging Markets Fund A GBP Inc',
    name: 'Vanguard Global Emerging Markets Fund A GBP Inc',
    symbol: '0P000185T2.L',
  },
  {
    value: '0P000185T4.L',
    label: '0P000185T4.L,  Vanguard Global Equity Fund A GBP Inc',
    name: 'Vanguard Global Equity Fund A GBP Inc',
    symbol: '0P000185T4.L',
  },
  {
    value: '0P000185SZ.L',
    label: '0P000185SZ.L,  Vanguard Global Equity Income Fund A GBP Acc',
    name: 'Vanguard Global Equity Income Fund A GBP Acc',
    symbol: '0P000185SZ.L',
  },
  {
    value: '0P00001S66.F',
    label: '0P00001S66.F,  Vanguard Japan Stock Index Fund EUR Acc',
    name: 'Vanguard Japan Stock Index Fund EUR Acc',
    symbol: '0P00001S66.F',
  },
  {
    value: '0P00018Q2R.L',
    label:
      '0P00018Q2R.L,  Vanguard U.S. Investment Grade Credit Index Fund Institutional Plus GBP Hedged Acc',
    name: 'Vanguard U.S. Investment Grade Credit Index Fund Institutional Plus GBP Hedged Acc',
    symbol: '0P00018Q2R.L',
  },
  {
    value: '0P00018Q2Q.L',
    label:
      '0P00018Q2Q.L,  Vanguard U.S. Investment Grade Credit Index Fund Institutional Plus GBP Hedged Dist',
    name: 'Vanguard U.S. Investment Grade Credit Index Fund Institutional Plus GBP Hedged Dist',
    symbol: '0P00018Q2Q.L',
  },
  {
    value: '0P00018Q2P.T',
    label:
      '0P00018Q2P.T,  Vanguard Global Bond Index Fund Institutional Plus JPY Hedged Acc',
    name: 'Vanguard Global Bond Index Fund Institutional Plus JPY Hedged Acc',
    symbol: '0P00018Q2P.T',
  },
  {
    value: '0P000060MS.F',
    label:
      '0P000060MS.F,  Vanguard Emerging Markets Stock Index Fund Investor EUR Accumulation',
    name: 'Vanguard Emerging Markets Stock Index Fund Investor EUR Accumulation',
    symbol: '0P000060MS.F',
  },
  {
    value: '0P00018XAQ.L',
    label: '0P00018XAQ.L,  Vanguard FTSE 100 Index Unit Trust GBP Inc',
    name: 'Vanguard FTSE 100 Index Unit Trust GBP Inc',
    symbol: '0P00018XAQ.L',
  },
  {
    value: '0P00018XAP.L',
    label: '0P00018XAP.L,  Vanguard FTSE 100 Index Unit Trust GBP Acc',
    name: 'Vanguard FTSE 100 Index Unit Trust GBP Acc',
    symbol: '0P00018XAP.L',
  },
  {
    value: '0P00018XAO.L',
    label: '0P00018XAO.L,  Vanguard FTSE Global All Cap Index Fund GBP Inc',
    name: 'Vanguard FTSE Global All Cap Index Fund GBP Inc',
    symbol: '0P00018XAO.L',
  },
  {
    value: '0P00018XAR.L',
    label: '0P00018XAR.L,  Vanguard FTSE Global All Cap Index Fund GBP Acc',
    name: 'Vanguard FTSE Global All Cap Index Fund GBP Acc',
    symbol: '0P00018XAR.L',
  },
  {
    value: '0P00006TV8.F',
    label: '0P00006TV8.F,  Vanguard U.S. 500 Stock Index Fund EUR Hedged Acc',
    name: 'Vanguard U.S. 500 Stock Index Fund EUR Hedged Acc',
    symbol: '0P00006TV8.F',
  },
  {
    value: '0P00001CIF.T',
    label:
      '0P00001CIF.T,  Vanguard Japan Stock Index Fund Investor JPY Accumulation',
    name: 'Vanguard Japan Stock Index Fund Investor JPY Accumulation',
    symbol: '0P00001CIF.T',
  },
  {
    value: '0P0001BDXH.F',
    label:
      '0P0001BDXH.F,  Vanguard SRI Euro Investment Grade Bond Index Fund EUR Acc',
    name: 'Vanguard SRI Euro Investment Grade Bond Index Fund EUR Acc',
    symbol: '0P0001BDXH.F',
  },
  {
    value: '0P0001BIBT.BE',
    label: '0P0001BIBT.BE,  Vanguard Pacific ex-Japan Stock Index Fund AUD Acc',
    name: 'Vanguard Pacific ex-Japan Stock Index Fund AUD Acc',
    symbol: '0P0001BIBT.BE',
  },
  {
    value: '0P00006XPO.F',
    label:
      '0P00006XPO.F,  Vanguard U.S. Opportunities Fund Investor EUR Accumulation',
    name: 'Vanguard U.S. Opportunities Fund Investor EUR Accumulation',
    symbol: '0P00006XPO.F',
  },
  {
    value: '0P0001BLQI.L',
    label:
      '0P0001BLQI.L,  Vanguard Global Credit Bond Fund Investor GBP Hedged Accumulation',
    name: 'Vanguard Global Credit Bond Fund Investor GBP Hedged Accumulation',
    symbol: '0P0001BLQI.L',
  },
  {
    value: '0P0001BLQG.F',
    label:
      '0P0001BLQG.F,  Vanguard Global Credit Bond Fund Institutional EUR Hedged Accumulation',
    name: 'Vanguard Global Credit Bond Fund Institutional EUR Hedged Accumulation',
    symbol: '0P0001BLQG.F',
  },
  {
    value: '0P00007KX9.L',
    label:
      '0P00007KX9.L,  Vanguard U.S. Opportunities Fund GBP Investor Dist Shares',
    name: 'Vanguard U.S. Opportunities Fund GBP Investor Dist Shares',
    symbol: '0P00007KX9.L',
  },
  {
    value: '0P0001C5T6.L',
    label:
      '0P0001C5T6.L,  Vanguard Global Corporate Bond Index Fund GBP Hedged Acc',
    name: 'Vanguard Global Corporate Bond Index Fund GBP Hedged Acc',
    symbol: '0P0001C5T6.L',
  },
  {
    value: '0P0001C5T8.L',
    label:
      '0P0001C5T8.L,  Vanguard Global Short-Term Corporate Bond Index Fund Institutional Plus GBP Hedged Acc',
    name: 'Vanguard Global Short-Term Corporate Bond Index Fund Institutional Plus GBP Hedged Acc',
    symbol: '0P0001C5T8.L',
  },
  {
    value: '0P0001C6H6.L',
    label:
      '0P0001C6H6.L,  Vanguard Global Corporate Bond Index Fund Institutional Plus GBP Hedged Acc',
    name: 'Vanguard Global Corporate Bond Index Fund Institutional Plus GBP Hedged Acc',
    symbol: '0P0001C6H6.L',
  },
  {
    value: '0P0001C5T4.L',
    label:
      '0P0001C5T4.L,  Vanguard Global Short-Term Corporate Bond Index Fund GBP Hedged Acc',
    name: 'Vanguard Global Short-Term Corporate Bond Index Fund GBP Hedged Acc',
    symbol: '0P0001C5T4.L',
  },
  {
    value: '0P0001CBQR.F',
    label:
      '0P0001CBQR.F,  Vanguard U.S. Government Bond Index Fund Institutional Plus EUR Hedged Acc',
    name: 'Vanguard U.S. Government Bond Index Fund Institutional Plus EUR Hedged Acc',
    symbol: '0P0001CBQR.F',
  },
  {
    value: '0P0001CE2G.L',
    label:
      '0P0001CE2G.L,  Vanguard Target Retirement 2065 Fund Accumulation Shares',
    name: 'Vanguard Target Retirement 2065 Fund Accumulation Shares',
    symbol: '0P0001CE2G.L',
  },
  {
    value: '0P0001CE2H.L',
    label:
      '0P0001CE2H.L,  Vanguard Target Retirement 2060 Fund Accumulation Shares',
    name: 'Vanguard Target Retirement 2060 Fund Accumulation Shares',
    symbol: '0P0001CE2H.L',
  },
  {
    value: '0P0001CHRU.F',
    label:
      '0P0001CHRU.F,  Vanguard SRI Euro Investment Grade Bond Index Fund Institutional Plus EUR Acc',
    name: 'Vanguard SRI Euro Investment Grade Bond Index Fund Institutional Plus EUR Acc',
    symbol: '0P0001CHRU.F',
  },
  {
    value: '0P0001CGI6.L',
    label: '0P0001CGI6.L,  Vanguard U.S. Government Bond Index Fund GBP Dist',
    name: 'Vanguard U.S. Government Bond Index Fund GBP Dist',
    symbol: '0P0001CGI6.L',
  },
  {
    value: '0P0001CG6I.L',
    label:
      '0P0001CG6I.L,  Vanguard Global Stock Index Fund Institutional Plus GBP Acc',
    name: 'Vanguard Global Stock Index Fund Institutional Plus GBP Acc',
    symbol: '0P0001CG6I.L',
  },
  {
    value: '0P0001CQCZ.F',
    label:
      '0P0001CQCZ.F,  Vanguard Global Short-Term Corporate Bond Index Fund Institutional Plus EUR Hedged Acc',
    name: 'Vanguard Global Short-Term Corporate Bond Index Fund Institutional Plus EUR Hedged Acc',
    symbol: '0P0001CQCZ.F',
  },
  {
    value: '0P0001CQCY.F',
    label:
      '0P0001CQCY.F,  Vanguard Global Corporate Bond Index Fund Institutional Plus EUR Hedged Acc',
    name: 'Vanguard Global Corporate Bond Index Fund Institutional Plus EUR Hedged Acc',
    symbol: '0P0001CQCY.F',
  },
  {
    value: '0P0001CXIY.F',
    label: '0P0001CXIY.F,  Vanguard Global Small-Cap Index Fund EUR Dist',
    name: 'Vanguard Global Small-Cap Index Fund EUR Dist',
    symbol: '0P0001CXIY.F',
  },
  {
    value: '0P0001CXIX.L',
    label:
      '0P0001CXIX.L,  Vanguard U.S. Government Bond Index Fund GBP Hedged Dist',
    name: 'Vanguard U.S. Government Bond Index Fund GBP Hedged Dist',
    symbol: '0P0001CXIX.L',
  },
  {
    value: '0P0000FXJW.L',
    label:
      '0P0000FXJW.L,  Vanguard U.K. Investment Grade Bond Index Fund GBP Acc',
    name: 'Vanguard U.K. Investment Grade Bond Index Fund GBP Acc',
    symbol: '0P0000FXJW.L',
  },
  {
    value: '0P0000CV2T.F',
    label: '0P0000CV2T.F,  Vanguard 20+ Year Euro Treasury Index Fund EUR Acc',
    name: 'Vanguard 20+ Year Euro Treasury Index Fund EUR Acc',
    symbol: '0P0000CV2T.F',
  },
  {
    value: '0P00009UMU.T',
    label: '0P00009UMU.T,  Vanguard Japan Government Bond Index Fund JPY Acc',
    name: 'Vanguard Japan Government Bond Index Fund JPY Acc',
    symbol: '0P00009UMU.T',
  },
  {
    value: '0P00009WXM.L',
    label:
      '0P00009WXM.L,  Vanguard U.K. Investment Grade Bond Index Fund GBP Dist',
    name: 'Vanguard U.K. Investment Grade Bond Index Fund GBP Dist',
    symbol: '0P00009WXM.L',
  },
  {
    value: '0P0001I2A0.L',
    label:
      '0P0001I2A0.L,  Vanguard Investments Money Market Funds - Vanguard Sterling Short-Term Money Market A GBP Inc',
    name: 'Vanguard Investments Money Market Funds - Vanguard Sterling Short-Term Money Market A GBP Inc',
    symbol: '0P0001I2A0.L',
  },
  {
    value: '0P0001IH7B.BE',
    label:
      '0P0001IH7B.BE,  Vanguard Global Credit Bond Fund Institutional AUD Hedged Accumulation',
    name: 'Vanguard Global Credit Bond Fund Institutional AUD Hedged Accumulation',
    symbol: '0P0001IH7B.BE',
  },
  {
    value: '0P0001IEQR.F',
    label:
      '0P0001IEQR.F,  Vanguard Global Short-Term Corporate Bond Index Fund EUR Hedged Acc',
    name: 'Vanguard Global Short-Term Corporate Bond Index Fund EUR Hedged Acc',
    symbol: '0P0001IEQR.F',
  },
  {
    value: '0P0001IL91.L',
    label: '0P0001IL91.L,  Vanguard Active U.K. Equity Fund A GBP Accumulation',
    name: 'Vanguard Active U.K. Equity Fund A GBP Accumulation',
    symbol: '0P0001IL91.L',
  },
  {
    value: '0P0001IL90.L',
    label: '0P0001IL90.L,  Vanguard Active U.K. Equity Fund A GBP Income',
    name: 'Vanguard Active U.K. Equity Fund A GBP Income',
    symbol: '0P0001IL90.L',
  },
  {
    value: '0P0001IU23.F',
    label:
      '0P0001IU23.F,  Vanguard Emerging Markets Bond Fund Investor EUR Hedged Accumulation',
    name: 'Vanguard Emerging Markets Bond Fund Investor EUR Hedged Accumulation',
    symbol: '0P0001IU23.F',
  },
  {
    value: '0P0001IU24.L',
    label:
      '0P0001IU24.L,  Vanguard Emerging Markets Bond Fund Investor GBP Hedged Accumulation',
    name: 'Vanguard Emerging Markets Bond Fund Investor GBP Hedged Accumulation',
    symbol: '0P0001IU24.L',
  },
  {
    value: '0P0001J1WM.L',
    label:
      '0P0001J1WM.L,  Vanguard Global Credit Bond Fund Investor GBP Hedged Income',
    name: 'Vanguard Global Credit Bond Fund Investor GBP Hedged Income',
    symbol: '0P0001J1WM.L',
  },
  {
    value: '0P0001J1WL.F',
    label:
      '0P0001J1WL.F,  Vanguard Japan Government Bond Index Fund Institutional Plus EUR Hedged Acc',
    name: 'Vanguard Japan Government Bond Index Fund Institutional Plus EUR Hedged Acc',
    symbol: '0P0001J1WL.F',
  },
  {
    value: '0P0001J1WK.L',
    label:
      '0P0001J1WK.L,  Vanguard Japan Government Bond Index Fund Institutional Plus GBP Hedged Dist',
    name: 'Vanguard Japan Government Bond Index Fund Institutional Plus GBP Hedged Dist',
    symbol: '0P0001J1WK.L',
  },
  {
    value: '0P0001K1DU.F',
    label:
      '0P0001K1DU.F,  Vanguard ESG Emerging Markets All Cap Equity Index Fund EUR Acc',
    name: 'Vanguard ESG Emerging Markets All Cap Equity Index Fund EUR Acc',
    symbol: '0P0001K1DU.F',
  },
  {
    value: '0P0001K1DP.L',
    label:
      '0P0001K1DP.L,  Vanguard ESG Developed World All Cap Equity Index Fund (UK) GBP Acc',
    name: 'Vanguard ESG Developed World All Cap Equity Index Fund (UK) GBP Acc',
    symbol: '0P0001K1DP.L',
  },
  {
    value: '0P0001K1DS.L',
    label:
      '0P0001K1DS.L,  Vanguard ESG Emerging Markets All Cap Equity Index Fund GBP Dist',
    name: 'Vanguard ESG Emerging Markets All Cap Equity Index Fund GBP Dist',
    symbol: '0P0001K1DS.L',
  },
  {
    value: '0P0001K1DR.L',
    label:
      '0P0001K1DR.L,  Vanguard ESG Emerging Markets All Cap Equity Index Fund GBP Acc',
    name: 'Vanguard ESG Emerging Markets All Cap Equity Index Fund GBP Acc',
    symbol: '0P0001K1DR.L',
  },
  {
    value: '0P0001K1DQ.L',
    label:
      '0P0001K1DQ.L,  Vanguard ESG Developed World All Cap Equity Index Fund (UK) Institutional Plus GBP Inc',
    name: 'Vanguard ESG Developed World All Cap Equity Index Fund (UK) Institutional Plus GBP Inc',
    symbol: '0P0001K1DQ.L',
  },
  {
    value: '0P0001K1DT.L',
    label:
      '0P0001K1DT.L,  Vanguard ESG Emerging Markets All Cap Equity Index Fund Institutional Plus GBP Dist',
    name: 'Vanguard ESG Emerging Markets All Cap Equity Index Fund Institutional Plus GBP Dist',
    symbol: '0P0001K1DT.L',
  },
  {
    value: '0P0001K1DW.L',
    label:
      '0P0001K1DW.L,  Vanguard ESG Developed World All Cap Equity Index Fund (UK) GBP Inc',
    name: 'Vanguard ESG Developed World All Cap Equity Index Fund (UK) GBP Inc',
    symbol: '0P0001K1DW.L',
  },
  {
    value: '0P00001QSE.F',
    label:
      '0P00001QSE.F,  Vanguard Japan Stock Index Fund Investor EUR Accumulation',
    name: 'Vanguard Japan Stock Index Fund Investor EUR Accumulation',
    symbol: '0P00001QSE.F',
  },
  {
    value: '0P0000GFVM.F',
    label:
      '0P0000GFVM.F,  Vanguard U.S. Investment Grade Credit Index Fund EUR Acc',
    name: 'Vanguard U.S. Investment Grade Credit Index Fund EUR Acc',
    symbol: '0P0000GFVM.F',
  },
  {
    value: '0P0001LJIT.F',
    label:
      '0P0001LJIT.F,  Vanguard Global Bond Index Fund EUR Hedged Distribution',
    name: 'Vanguard Global Bond Index Fund EUR Hedged Distribution',
    symbol: '0P0001LJIT.F',
  },
  {
    value: '0P0001LJIS.SW',
    label:
      '0P0001LJIS.SW,  Vanguard Global Bond Index Fund CHF Hedged Distribution',
    name: 'Vanguard Global Bond Index Fund CHF Hedged Distribution',
    symbol: '0P0001LJIS.SW',
  },
  {
    value: '0P0001LJIY.F',
    label:
      '0P0001LJIY.F,  Vanguard Japan Government Bond Index Fund EUR Hedged Acc',
    name: 'Vanguard Japan Government Bond Index Fund EUR Hedged Acc',
    symbol: '0P0001LJIY.F',
  },
  {
    value: '0P0001LJIZ.F',
    label:
      '0P0001LJIZ.F,  Vanguard U.K. Government Bond Index Fund EUR Hedged Acc',
    name: 'Vanguard U.K. Government Bond Index Fund EUR Hedged Acc',
    symbol: '0P0001LJIZ.F',
  },
  {
    value: '0P0001LJIX.L',
    label:
      '0P0001LJIX.L,  Vanguard Global Corporate Bond Index Fund GBP Hedged Distribution',
    name: 'Vanguard Global Corporate Bond Index Fund GBP Hedged Distribution',
    symbol: '0P0001LJIX.L',
  },
  {
    value: '0P0001LJIV.SW',
    label:
      '0P0001LJIV.SW,  Vanguard Global Corporate Bond Index Fund CHF Hedged Accumulation',
    name: 'Vanguard Global Corporate Bond Index Fund CHF Hedged Accumulation',
    symbol: '0P0001LJIV.SW',
  },
  {
    value: '0P0001LJIW.F',
    label:
      '0P0001LJIW.F,  Vanguard Global Corporate Bond Index Fund EUR Hedged Accumulation',
    name: 'Vanguard Global Corporate Bond Index Fund EUR Hedged Accumulation',
    symbol: '0P0001LJIW.F',
  },
  {
    value: '0P0001LUW7.F',
    label:
      '0P0001LUW7.F,  Vanguard ESG Emerging Markets All Cap Equity Index Fund Institutional Plus EUR Acc',
    name: 'Vanguard ESG Emerging Markets All Cap Equity Index Fund Institutional Plus EUR Acc',
    symbol: '0P0001LUW7.F',
  },
  {
    value: '0P0001LUW6.F',
    label:
      '0P0001LUW6.F,  Vanguard ESG Developed World All Cap Equity Index Fund Institutional Plus EUR Hedged Acc',
    name: 'Vanguard ESG Developed World All Cap Equity Index Fund Institutional Plus EUR Hedged Acc',
    symbol: '0P0001LUW6.F',
  },
  {
    value: '0P0001M7M3.F',
    label:
      '0P0001M7M3.F,  Vanguard Emerging Markets Bond Fund Institutional Plus EUR Hedged Accumulation',
    name: 'Vanguard Emerging Markets Bond Fund Institutional Plus EUR Hedged Accumulation',
    symbol: '0P0001M7M3.F',
  },
  {
    value: '0P0001M7M2.SW',
    label:
      '0P0001M7M2.SW,  Vanguard Emerging Markets Bond Fund Institutional Plus CHF Hedged Accumulation',
    name: 'Vanguard Emerging Markets Bond Fund Institutional Plus CHF Hedged Accumulation',
    symbol: '0P0001M7M2.SW',
  },
  {
    value: '0P0000IWBZ.SW',
    label: '0P0000IWBZ.SW,  Vanguard Global Bond Index Fund CHF Hedged Acc',
    name: 'Vanguard Global Bond Index Fund CHF Hedged Acc',
    symbol: '0P0000IWBZ.SW',
  },
  {
    value: '0P0000J59O.F',
    label:
      '0P0000J59O.F,  Vanguard Eurozone Inflation-Linked Bond Index Fund Investor EUR Accumulation',
    name: 'Vanguard Eurozone Inflation-Linked Bond Index Fund Investor EUR Accumulation',
    symbol: '0P0000J59O.F',
  },
  {
    value: '0P0000KM22.L',
    label: '0P0000KM22.L,  Vanguard Emerging Markets Stock Index Fund GBP Acc',
    name: 'Vanguard Emerging Markets Stock Index Fund GBP Acc',
    symbol: '0P0000KM22.L',
  },
  {
    value: '0P0000KM21.L',
    label: '0P0000KM21.L,  Vanguard Emerging Markets Stock Index Fund GBP Dist',
    name: 'Vanguard Emerging Markets Stock Index Fund GBP Dist',
    symbol: '0P0000KM21.L',
  },
  {
    value: '0P0000KM24.L',
    label: '0P0000KM24.L,  Vanguard Global Bond Index Fund GBP Hedged Acc',
    name: 'Vanguard Global Bond Index Fund GBP Hedged Acc',
    symbol: '0P0000KM24.L',
  },
  {
    value: '0P0000KM23.L',
    label: '0P0000KM23.L,  Vanguard Global Bond Index Fund GBP Hedged Dist',
    name: 'Vanguard Global Bond Index Fund GBP Hedged Dist',
    symbol: '0P0000KM23.L',
  },
  {
    value: '0P0000KM1Y.L',
    label: '0P0000KM1Y.L,  Vanguard Japan Stock Index Fund GBP Acc',
    name: 'Vanguard Japan Stock Index Fund GBP Acc',
    symbol: '0P0000KM1Y.L',
  },
  {
    value: '0P0000KM1W.L',
    label: '0P0000KM1W.L,  Vanguard Japan Stock Index Fund GBP Dist',
    name: 'Vanguard Japan Stock Index Fund GBP Dist',
    symbol: '0P0000KM1W.L',
  },
  {
    value: '0P0000KM20.L',
    label: '0P0000KM20.L,  Vanguard Pacific ex-Japan Stock Index Fund GBP Acc',
    name: 'Vanguard Pacific ex-Japan Stock Index Fund GBP Acc',
    symbol: '0P0000KM20.L',
  },
  {
    value: '0P0000KM1Z.L',
    label: '0P0000KM1Z.L,  Vanguard Pacific ex-Japan Stock Index Fund GBP Dist',
    name: 'Vanguard Pacific ex-Japan Stock Index Fund GBP Dist',
    symbol: '0P0000KM1Z.L',
  },
  {
    value: '0P0000KM26.L',
    label: '0P0000KM26.L,  Vanguard U.K. Government Bond Index Fund GBP Acc',
    name: 'Vanguard U.K. Government Bond Index Fund GBP Acc',
    symbol: '0P0000KM26.L',
  },
  {
    value: '0P0000KM25.L',
    label: '0P0000KM25.L,  Vanguard U.K. Government Bond Index Fund GBP Dist',
    name: 'Vanguard U.K. Government Bond Index Fund GBP Dist',
    symbol: '0P0000KM25.L',
  },
  {
    value: '0P0000KSP5.L',
    label: '0P0000KSP5.L,  Vanguard FTSE U.K. Equity Income Index Fund GBP Inc',
    name: 'Vanguard FTSE U.K. Equity Income Index Fund GBP Inc',
    symbol: '0P0000KSP5.L',
  },
  {
    value: '0P0000KSP4.L',
    label: '0P0000KSP4.L,  Vanguard FTSE U.K. Equity Income Index Fund GBP Acc',
    name: 'Vanguard FTSE U.K. Equity Income Index Fund GBP Acc',
    symbol: '0P0000KSP4.L',
  },
  {
    value: '0P0000KSP7.L',
    label:
      '0P0000KSP7.L,  Vanguard FTSE Developed World ex-U.K. Equity Index Fund GBP Inc',
    name: 'Vanguard FTSE Developed World ex-U.K. Equity Index Fund GBP Inc',
    symbol: '0P0000KSP7.L',
  },
  {
    value: '0P0000KSP6.L',
    label:
      '0P0000KSP6.L,  Vanguard FTSE Developed World ex-U.K. Equity Index Fund GBP Acc',
    name: 'Vanguard FTSE Developed World ex-U.K. Equity Index Fund GBP Acc',
    symbol: '0P0000KSP6.L',
  },
  {
    value: '0P0000KSP9.L',
    label:
      '0P0000KSP9.L,  Vanguard FTSE Developed Europe ex-U.K. Equity Index Fund GBP Inc',
    name: 'Vanguard FTSE Developed Europe ex-U.K. Equity Index Fund GBP Inc',
    symbol: '0P0000KSP9.L',
  },
  {
    value: '0P0000KSP8.L',
    label:
      '0P0000KSP8.L,  Vanguard FTSE Developed Europe ex-U.K. Equity Index Fund GBP Acc',
    name: 'Vanguard FTSE Developed Europe ex-U.K. Equity Index Fund GBP Acc',
    symbol: '0P0000KSP8.L',
  },
  {
    value: '0P0000KSPB.L',
    label: '0P0000KSPB.L,  Vanguard U.S. Equity Index Fund GBP Inc',
    name: 'Vanguard U.S. Equity Index Fund GBP Inc',
    symbol: '0P0000KSPB.L',
  },
  {
    value: '0P0000KSPA.L',
    label: '0P0000KSPA.L,  Vanguard U.S. Equity Index Fund GBP Acc',
    name: 'Vanguard U.S. Equity Index Fund GBP Acc',
    symbol: '0P0000KSPA.L',
  },
  {
    value: '0P00000SUJ.F',
    label: '0P00000SUJ.F,  Vanguard U.S. 500 Stock Index Fund EUR Acc',
    name: 'Vanguard U.S. 500 Stock Index Fund EUR Acc',
    symbol: '0P00000SUJ.F',
  },
  {
    value: '0P00000WLG.F',
    label: '0P00000WLG.F,  Vanguard Global Stock Index Fund EUR Acc',
    name: 'Vanguard Global Stock Index Fund EUR Acc',
    symbol: '0P00000WLG.F',
  },
  {
    value: '0P00000RN9.F',
    label: '0P00000RN9.F,  Vanguard European Stock Index Fund EUR Acc',
    name: 'Vanguard European Stock Index Fund EUR Acc',
    symbol: '0P00000RN9.F',
  },
  {
    value: '0P00000RQ8.F',
    label:
      '0P00000RQ8.F,  Vanguard European Stock Index Fund Investor EUR Accumulation',
    name: 'Vanguard European Stock Index Fund Investor EUR Accumulation',
    symbol: '0P00000RQ8.F',
  },
  {
    value: '0P00000MO4.F',
    label: '0P00000MO4.F,  Vanguard Eurozone Stock Index Fund EUR Acc',
    name: 'Vanguard Eurozone Stock Index Fund EUR Acc',
    symbol: '0P00000MO4.F',
  },
  {
    value: '0P00000RQC.F',
    label:
      '0P00000RQC.F,  Vanguard Global Stock Index Fund Investor EUR Accumulation',
    name: 'Vanguard Global Stock Index Fund Investor EUR Accumulation',
    symbol: '0P00000RQC.F',
  },
  {
    value: '0P00000RNA.F',
    label:
      '0P00000RNA.F,  Vanguard Euro Government Bond Index Fund Investor EUR Accumulation',
    name: 'Vanguard Euro Government Bond Index Fund Investor EUR Accumulation',
    symbol: '0P00000RNA.F',
  },
  {
    value: '0P00000RQE.F',
    label: '0P00000RQE.F,  Vanguard Euro Government Bond Index Fund EUR Acc',
    name: 'Vanguard Euro Government Bond Index Fund EUR Acc',
    symbol: '0P00000RQE.F',
  },
  {
    value: '0P00000SQW.F',
    label:
      '0P00000SQW.F,  Vanguard Euro Investment Grade Bond Index Fund Investor EUR Accumulation',
    name: 'Vanguard Euro Investment Grade Bond Index Fund Investor EUR Accumulation',
    symbol: '0P00000SQW.F',
  },
  {
    value: '0P00000MNM.F',
    label:
      '0P00000MNM.F,  Vanguard Euro Investment Grade Bond Index Fund EUR Acc',
    name: 'Vanguard Euro Investment Grade Bond Index Fund EUR Acc',
    symbol: '0P00000MNM.F',
  },
  {
    value: '0P0000SAVS.L',
    label:
      '0P0000SAVS.L,  Vanguard FTSE U.K. All Share Index Unit Trust GBP Acc',
    name: 'Vanguard FTSE U.K. All Share Index Unit Trust GBP Acc',
    symbol: '0P0000SAVS.L',
  },
  {
    value: '0P00000W6B.F',
    label:
      '0P00000W6B.F,  Vanguard Eurozone Stock Index Fund Investor EUR Accumulation',
    name: 'Vanguard Eurozone Stock Index Fund Investor EUR Accumulation',
    symbol: '0P00000W6B.F',
  },
  {
    value: '0P0000N47P.L',
    label: '0P0000N47P.L,  Vanguard Global Small-Cap Index Fund GBP Dist',
    name: 'Vanguard Global Small-Cap Index Fund GBP Dist',
    symbol: '0P0000N47P.L',
  },
  {
    value: '0P0000N47O.L',
    label: '0P0000N47O.L,  Vanguard Global Small-Cap Index Fund GBP Acc',
    name: 'Vanguard Global Small-Cap Index Fund GBP Acc',
    symbol: '0P0000N47O.L',
  },
  {
    value: '0P0000P2ON.F',
    label: '0P0000P2ON.F,  Vanguard SRI European Stock Fund EUR Acc',
    name: 'Vanguard SRI European Stock Fund EUR Acc',
    symbol: '0P0000P2ON.F',
  },
  {
    value: '0P0000SCKI.F',
    label:
      '0P0000SCKI.F,  Vanguard ESG Developed World All Cap Equity Index Fund EUR Acc',
    name: 'Vanguard ESG Developed World All Cap Equity Index Fund EUR Acc',
    symbol: '0P0000SCKI.F',
  },
  {
    value: '0P0000SHY5.L',
    label:
      '0P0000SHY5.L,  Vanguard U.K. Inflation-Linked Gilt Index Fund GBP Acc',
    name: 'Vanguard U.K. Inflation-Linked Gilt Index Fund GBP Acc',
    symbol: '0P0000SHY5.L',
  },
  {
    value: '0P0000SHY6.L',
    label:
      '0P0000SHY6.L,  Vanguard U.K. Inflation-Linked Gilt Index Fund GBP Inc',
    name: 'Vanguard U.K. Inflation-Linked Gilt Index Fund GBP Inc',
    symbol: '0P0000SHY6.L',
  },
  {
    value: '0P0000SHY8.L',
    label: '0P0000SHY8.L,  Vanguard U.K. Long Duration Gilt Index Fund GBP Acc',
    name: 'Vanguard U.K. Long Duration Gilt Index Fund GBP Acc',
    symbol: '0P0000SHY8.L',
  },
  {
    value: '0P0000SHY7.L',
    label: '0P0000SHY7.L,  Vanguard U.K. Long Duration Gilt Index Fund GBP Inc',
    name: 'Vanguard U.K. Long Duration Gilt Index Fund GBP Inc',
    symbol: '0P0000SHY7.L',
  },
  {
    value: '0P0000TKZP.L',
    label: '0P0000TKZP.L,  Vanguard LifeStrategy 100% Equity Fund A Inc',
    name: 'Vanguard LifeStrategy 100% Equity Fund A Inc',
    symbol: '0P0000TKZP.L',
  },
  {
    value: '0P0000TKZO.L',
    label: '0P0000TKZO.L,  Vanguard LifeStrategy 100% Equity Fund A Acc',
    name: 'Vanguard LifeStrategy 100% Equity Fund A Acc',
    symbol: '0P0000TKZO.L',
  },
  {
    value: '0P0000TKZH.L',
    label: '0P0000TKZH.L,  Vanguard LifeStrategy 20% Equity Fund A Gross Inc',
    name: 'Vanguard LifeStrategy 20% Equity Fund A Gross Inc',
    symbol: '0P0000TKZH.L',
  },
  {
    value: '0P0000TKZG.L',
    label: '0P0000TKZG.L,  Vanguard LifeStrategy 20% Equity Fund A Gross Acc',
    name: 'Vanguard LifeStrategy 20% Equity Fund A Gross Acc',
    symbol: '0P0000TKZG.L',
  },
  {
    value: '0P0000TKZJ.L',
    label: '0P0000TKZJ.L,  Vanguard LifeStrategy 40% Equity Fund A Inc',
    name: 'Vanguard LifeStrategy 40% Equity Fund A Inc',
    symbol: '0P0000TKZJ.L',
  },
  {
    value: '0P0000TKZI.L',
    label: '0P0000TKZI.L,  Vanguard LifeStrategy 40% Equity Fund A Acc',
    name: 'Vanguard LifeStrategy 40% Equity Fund A Acc',
    symbol: '0P0000TKZI.L',
  },
  {
    value: '0P0000TKZL.L',
    label: '0P0000TKZL.L,  Vanguard LifeStrategy 60% Equity Fund A Inc',
    name: 'Vanguard LifeStrategy 60% Equity Fund A Inc',
    symbol: '0P0000TKZL.L',
  },
  {
    value: '0P0000TKZK.L',
    label: '0P0000TKZK.L,  Vanguard LifeStrategy 60% Equity Fund A Acc',
    name: 'Vanguard LifeStrategy 60% Equity Fund A Acc',
    symbol: '0P0000TKZK.L',
  },
  {
    value: '0P0000TKZN.L',
    label: '0P0000TKZN.L,  Vanguard LifeStrategy 80% Equity Fund A Inc',
    name: 'Vanguard LifeStrategy 80% Equity Fund A Inc',
    symbol: '0P0000TKZN.L',
  },
  {
    value: '0P0000TKZM.L',
    label: '0P0000TKZM.L,  Vanguard LifeStrategy 80% Equity Fund A Acc',
    name: 'Vanguard LifeStrategy 80% Equity Fund A Acc',
    symbol: '0P0000TKZM.L',
  },
  {
    value: '0P0000UGLE.L',
    label: '0P0000UGLE.L,  Vanguard SRI European Stock Fund GBP Acc',
    name: 'Vanguard SRI European Stock Fund GBP Acc',
    symbol: '0P0000UGLE.L',
  },
  {
    value: '0P0000UGLF.L',
    label: '0P0000UGLF.L,  Vanguard SRI European Stock Fund GBP Dist',
    name: 'Vanguard SRI European Stock Fund GBP Dist',
    symbol: '0P0000UGLF.L',
  },
  {
    value: '0P0000UGLG.L',
    label:
      '0P0000UGLG.L,  Vanguard ESG Developed World All Cap Equity Index Fund GBP Acc',
    name: 'Vanguard ESG Developed World All Cap Equity Index Fund GBP Acc',
    symbol: '0P0000UGLG.L',
  },
  {
    value: 'JFWD',
    label: 'JFWD,  Jacob Forward ETF',
    name: 'Jacob Forward ETF',
    symbol: 'JFWD',
  },
  {
    value: '0P0000UGLH.L',
    label:
      '0P0000UGLH.L,  Vanguard ESG Developed World All Cap Equity Index Fund GBP Dist',
    name: 'Vanguard ESG Developed World All Cap Equity Index Fund GBP Dist',
    symbol: '0P0000UGLH.L',
  },
  {
    value: '0P0000XR9M.F',
    label: '0P0000XR9M.F,  Vanguard Global Small-Cap Index Fund EUR Acc',
    name: 'Vanguard Global Small-Cap Index Fund EUR Acc',
    symbol: '0P0000XR9M.F',
  },
  {
    value: '0P000147Q5.L',
    label:
      '0P000147Q5.L,  Vanguard FTSE U.K. Equity Income Index Fund Institutional Plus GBP Acc',
    name: 'Vanguard FTSE U.K. Equity Income Index Fund Institutional Plus GBP Acc',
    symbol: '0P000147Q5.L',
  },
  {
    value: '0P000147Q4.L',
    label:
      '0P000147Q4.L,  Vanguard FTSE U.K. Equity Income Index Fund Institutional Plus GBP Inc',
    name: 'Vanguard FTSE U.K. Equity Income Index Fund Institutional Plus GBP Inc',
    symbol: '0P000147Q4.L',
  },
  {
    value: '0P000147Q7.L',
    label:
      '0P000147Q7.L,  Vanguard FTSE Developed World ex-U.K. Equity Index Fund Institutional Plus GBP Acc',
    name: 'Vanguard FTSE Developed World ex-U.K. Equity Index Fund Institutional Plus GBP Acc',
    symbol: '0P000147Q7.L',
  },
  {
    value: '0P000147Q6.L',
    label:
      '0P000147Q6.L,  Vanguard FTSE Developed World ex-U.K. Equity Index Fund Institutional Plus GBP Inc',
    name: 'Vanguard FTSE Developed World ex-U.K. Equity Index Fund Institutional Plus GBP Inc',
    symbol: '0P000147Q6.L',
  },
  {
    value: '0P000147QA.L',
    label:
      '0P000147QA.L,  Vanguard U.K. Inflation-Linked Gilt Index Fund Institutional Plus GBP Inc',
    name: 'Vanguard U.K. Inflation-Linked Gilt Index Fund Institutional Plus GBP Inc',
    symbol: '0P000147QA.L',
  },
  {
    value: '0P000147QD.L',
    label:
      '0P000147QD.L,  Vanguard U.S. Equity Index Fund Institutional Plus GBP Acc',
    name: 'Vanguard U.S. Equity Index Fund Institutional Plus GBP Acc',
    symbol: '0P000147QD.L',
  },
  {
    value: '0P000147QC.L',
    label:
      '0P000147QC.L,  Vanguard U.S. Equity Index Fund Institutional Plus GBP Inc',
    name: 'Vanguard U.S. Equity Index Fund Institutional Plus GBP Inc',
    symbol: '0P000147QC.L',
  },
  {
    value: '0P000147QE.L',
    label:
      '0P000147QE.L,  Vanguard FTSE Developed Europe ex-U.K. Equity Index Fund Institutional Plus GBP Acc',
    name: 'Vanguard FTSE Developed Europe ex-U.K. Equity Index Fund Institutional Plus GBP Acc',
    symbol: '0P000147QE.L',
  },
  {
    value: '0P000147QF.L',
    label:
      '0P000147QF.L,  Vanguard FTSE Developed Europe ex-U.K. Equity Index Fund Institutional Plus GBP Inc',
    name: 'Vanguard FTSE Developed Europe ex-U.K. Equity Index Fund Institutional Plus GBP Inc',
    symbol: '0P000147QF.L',
  },
  {
    value: '0P000147Q2.L',
    label:
      '0P000147Q2.L,  Vanguard FTSE U.K. All Share Index Unit Trust Institutional Plus GBP Acc',
    name: 'Vanguard FTSE U.K. All Share Index Unit Trust Institutional Plus GBP Acc',
    symbol: '0P000147Q2.L',
  },
  {
    value: '0P000147Q1.L',
    label:
      '0P000147Q1.L,  Vanguard FTSE U.K. All Share Index Unit Trust Institutional Plus GBP Inc',
    name: 'Vanguard FTSE U.K. All Share Index Unit Trust Institutional Plus GBP Inc',
    symbol: '0P000147Q1.L',
  },
  {
    value: '0P000147QB.L',
    label:
      '0P000147QB.L,  Vanguard U.K. Inflation-Linked Gilt Index Fund Institutional Plus GBP Acc',
    name: 'Vanguard U.K. Inflation-Linked Gilt Index Fund Institutional Plus GBP Acc',
    symbol: '0P000147QB.L',
  },
  {
    value: '0P000147Q9.L',
    label:
      '0P000147Q9.L,  Vanguard U.K. Long Duration Gilt Index Fund Institutional Plus GBP Acc',
    name: 'Vanguard U.K. Long Duration Gilt Index Fund Institutional Plus GBP Acc',
    symbol: '0P000147Q9.L',
  },
  {
    value: '0P000147Q8.L',
    label:
      '0P000147Q8.L,  Vanguard U.K. Long Duration Gilt Index Fund Institutional Plus GBP Inc',
    name: 'Vanguard U.K. Long Duration Gilt Index Fund Institutional Plus GBP Inc',
    symbol: '0P000147Q8.L',
  },
  {
    value: '0P0001I2A1.L',
    label:
      '0P0001I2A1.L,  Vanguard Investments Money Market Funds - Vanguard Sterling Short-Term Money Market Ins Pls GBP Acc',
    name: 'Vanguard Investments Money Market Funds - Vanguard Sterling Short-Term Money Market Ins Pls GBP Acc',
    symbol: '0P0001I2A1.L',
  },
  {
    value: '2060.SR',
    label: '2060.SR,  National Industrialization Company',
    name: 'National Industrialization Company',
    symbol: '2060.SR',
  },
  {
    value: '1321.SR',
    label: '1321.SR,  East Pipes Integrated Company for Industry',
    name: 'East Pipes Integrated Company for Industry',
    symbol: '1321.SR',
  },
  {
    value: '3007.SR',
    label: '3007.SR,  Zahrat Al Waha For Trading Company',
    name: 'Zahrat Al Waha For Trading Company',
    symbol: '3007.SR',
  },
  {
    value: '9523.SR',
    label: '9523.SR,  Group Five Pipe Saudi Ltd.',
    name: 'Group Five Pipe Saudi Ltd.',
    symbol: '9523.SR',
  },
  {
    value: '9513.SR',
    label: '9513.SR,  Watani Iron Steel Co.',
    name: 'Watani Iron Steel Co.',
    symbol: '9513.SR',
  },
  {
    value: '3008.SR',
    label: '3008.SR,  Al Kathiri Holding Company',
    name: 'Al Kathiri Holding Company',
    symbol: '3008.SR',
  },
  {
    value: '300225.SZ',
    label: '300225.SZ,  Shanghai Kinlita Chemical Co., Ltd.',
    name: 'Shanghai Kinlita Chemical Co., Ltd.',
    symbol: '300225.SZ',
  },
  {
    value: 'PDSL.NS',
    label: 'PDSL.NS,  PDS Limited',
    name: 'PDS Limited',
    symbol: 'PDSL.NS',
  },
  {
    value: '300456.SZ',
    label: '300456.SZ,  Sai MicroElectronics Inc.',
    name: 'Sai MicroElectronics Inc.',
    symbol: '300456.SZ',
  },
  {
    value: '300323.SZ',
    label: '300323.SZ,  HC SemiTek Corporation',
    name: 'HC SemiTek Corporation',
    symbol: '300323.SZ',
  },
  {
    value: '300672.SZ',
    label: '300672.SZ,  Hunan Goke Microelectronics Co.,Ltd.',
    name: 'Hunan Goke Microelectronics Co.,Ltd.',
    symbol: '300672.SZ',
  },
  {
    value: 'BNDD',
    label: 'BNDD,  Quadratic Deflation ETF',
    name: 'Quadratic Deflation ETF',
    symbol: 'BNDD',
  },
  {
    value: '301060.SZ',
    label: '301060.SZ,  Shanghai Labway Clinical Laboratory Co., Ltd.',
    name: 'Shanghai Labway Clinical Laboratory Co., Ltd.',
    symbol: '301060.SZ',
  },
  {
    value: '300604.SZ',
    label: '300604.SZ,  Hangzhou Changchuan Technology Co.,Ltd',
    name: 'Hangzhou Changchuan Technology Co.,Ltd',
    symbol: '300604.SZ',
  },
  {
    value: 'TCN',
    label: 'TCN,  Tricon Residential Inc.',
    name: 'Tricon Residential Inc.',
    symbol: 'TCN',
  },
  {
    value: 'SIRE',
    label: 'SIRE,  Sisecam Resources LP',
    name: 'Sisecam Resources LP',
    symbol: 'SIRE',
  },
  {
    value: 'HLZ.MC',
    label: 'HLZ.MC,  Holaluz-Clidom, S.A.',
    name: 'Holaluz-Clidom, S.A.',
    symbol: 'HLZ.MC',
  },
  {
    value: 'SGML',
    label: 'SGML,  Sigma Lithium Corporation',
    name: 'Sigma Lithium Corporation',
    symbol: 'SGML',
  },
  {
    value: '301027.SZ',
    label: '301027.SZ,  Hualan Group Co., Ltd.',
    name: 'Hualan Group Co., Ltd.',
    symbol: '301027.SZ',
  },
  {
    value: 'CLSC',
    label: 'CLSC,  Cabana Target Leading Sector Conservative ETF',
    name: 'Cabana Target Leading Sector Conservative ETF',
    symbol: 'CLSC',
  },
  {
    value: 'CLSM',
    label: 'CLSM,  Cabana Target Leading Sector Moderate ETF',
    name: 'Cabana Target Leading Sector Moderate ETF',
    symbol: 'CLSM',
  },
  {
    value: '0P00000XI6.F',
    label: '0P00000XI6.F,  Naranja Standard & Poors 500 PP',
    name: 'Naranja Standard & Poors 500 PP',
    symbol: '0P00000XI6.F',
  },
  {
    value: '0P00000XI7.F',
    label: '0P00000XI7.F,  Naranja Renta Fija Europea PP',
    name: 'Naranja Renta Fija Europea PP',
    symbol: '0P00000XI7.F',
  },
  {
    value: 'WNNR',
    label: 'WNNR,  Andretti Acquisition Corp.',
    name: 'Andretti Acquisition Corp.',
    symbol: 'WNNR',
  },
  {
    value: 'VCLN',
    label: 'VCLN,  Virtus Duff & Phelps Clean Energy ETF',
    name: 'Virtus Duff & Phelps Clean Energy ETF',
    symbol: 'VCLN',
  },
  {
    value: 'ACAB',
    label: 'ACAB,  Atlantic Coastal Acquisition Corp. II',
    name: 'Atlantic Coastal Acquisition Corp. II',
    symbol: 'ACAB',
  },
  {
    value: 'ZYDUSLIFE.NS',
    label: 'ZYDUSLIFE.NS,  Zydus Lifesciences Limited',
    name: 'Zydus Lifesciences Limited',
    symbol: 'ZYDUSLIFE.NS',
  },
  {
    value: 'KCCA',
    label: 'KCCA,  KraneShares California Carbon Allowance ETF',
    name: 'KraneShares California Carbon Allowance ETF',
    symbol: 'KCCA',
  },
  {
    value: 'DOZR',
    label: 'DOZR,  Direxion Daily US Infrastructure Bull 2X Shares',
    name: 'Direxion Daily US Infrastructure Bull 2X Shares',
    symbol: 'DOZR',
  },
  {
    value: 'TYA',
    label: 'TYA,  Simplify Intermediate Term Treasury Futures Strategy ETF',
    name: 'Simplify Intermediate Term Treasury Futures Strategy ETF',
    symbol: 'TYA',
  },
  {
    value: 'BSEA',
    label: 'BSEA,  ETFMG Breakwave Sea Decarbonization Tech ETF',
    name: 'ETFMG Breakwave Sea Decarbonization Tech ETF',
    symbol: 'BSEA',
  },
  {
    value: 'CSLM',
    label: 'CSLM,  Consilium Acquisition Corp I, Ltd.',
    name: 'Consilium Acquisition Corp I, Ltd.',
    symbol: 'CSLM',
  },
  {
    value: 'GCTK',
    label: 'GCTK,  GlucoTrack, Inc.',
    name: 'GlucoTrack, Inc.',
    symbol: 'GCTK',
  },
  {
    value: 'ACABW',
    label: 'ACABW,  Atlantic Coastal Acquisition Corp. II',
    name: 'Atlantic Coastal Acquisition Corp. II',
    symbol: 'ACABW',
  },
  {
    value: 'CSLMW',
    label: 'CSLMW,  Consilium Acquisition Corp I, Ltd.',
    name: 'Consilium Acquisition Corp I, Ltd.',
    symbol: 'CSLMW',
  },
  {
    value: 'CSLMR',
    label: 'CSLMR,  Consilium Acquisition Corp I, Ltd.',
    name: 'Consilium Acquisition Corp I, Ltd.',
    symbol: 'CSLMR',
  },
  {
    value: 'WMC.CN',
    label: 'WMC.CN,  Westmount Minerals Corp.',
    name: 'Westmount Minerals Corp.',
    symbol: 'WMC.CN',
  },
  {
    value: 'EMFQ',
    label: 'EMFQ,  Amplify Emerging Markets FinTech ETF',
    name: 'Amplify Emerging Markets FinTech ETF',
    symbol: 'EMFQ',
  },
  {
    value: 'AGOV',
    label: 'AGOV,  ETC Gavekal Asia Pacific Government Bond ETF',
    name: 'ETC Gavekal Asia Pacific Government Bond ETF',
    symbol: 'AGOV',
  },
  {
    value: 'GDSTU',
    label: 'GDSTU,  Goldenstone Acquisition Limited',
    name: 'Goldenstone Acquisition Limited',
    symbol: 'GDSTU',
  },
  {
    value: 'ASCA',
    label: 'ASCA,  A SPAC I Acquisition Corp.',
    name: 'A SPAC I Acquisition Corp.',
    symbol: 'ASCA',
  },
  {
    value: 'ASCAR',
    label: 'ASCAR,  A SPAC I Acquisition Corp.',
    name: 'A SPAC I Acquisition Corp.',
    symbol: 'ASCAR',
  },
  {
    value: 'LATGW',
    label: 'LATGW,  LatAmGrowth SPAC',
    name: 'LatAmGrowth SPAC',
    symbol: 'LATGW',
  },
  {
    value: 'BWAQR',
    label: 'BWAQR,  Blue World Acquisition Corporation',
    name: 'Blue World Acquisition Corporation',
    symbol: 'BWAQR',
  },
  {
    value: 'BWAQW',
    label: 'BWAQW,  Blue World Acquisition Corporation',
    name: 'Blue World Acquisition Corporation',
    symbol: 'BWAQW',
  },
  {
    value: 'BWAQ',
    label: 'BWAQ,  Blue World Acquisition Corporation',
    name: 'Blue World Acquisition Corporation',
    symbol: 'BWAQ',
  },
  {
    value: 'FEAM',
    label: 'FEAM,  5E Advanced Materials Inc.',
    name: '5E Advanced Materials Inc.',
    symbol: 'FEAM',
  },
  {
    value: 'KNSW',
    label: 'KNSW,  KnightSwan Acquisition Corporation',
    name: 'KnightSwan Acquisition Corporation',
    symbol: 'KNSW',
  },
  {
    value: 'HCMA',
    label: 'HCMA,  HCM Acquisition Corp',
    name: 'HCM Acquisition Corp',
    symbol: 'HCMA',
  },
  {
    value: 'DUETW',
    label: 'DUETW,  DUET Acquisition Corp.',
    name: 'DUET Acquisition Corp.',
    symbol: 'DUETW',
  },
  {
    value: 'HCMAW',
    label: 'HCMAW,  HCM Acquisition Corp',
    name: 'HCM Acquisition Corp',
    symbol: 'HCMAW',
  },
  {
    value: 'DUET',
    label: 'DUET,  DUET Acquisition Corp.',
    name: 'DUET Acquisition Corp.',
    symbol: 'DUET',
  },
  {
    value: 'GMBLZ',
    label: 'GMBLZ,  Esports Entertainment Group, Inc.',
    name: 'Esports Entertainment Group, Inc.',
    symbol: 'GMBLZ',
  },
  {
    value: 'POET',
    label: 'POET,  POET Technologies Inc.',
    name: 'POET Technologies Inc.',
    symbol: 'POET',
  },
  {
    value: '6639.HK',
    label: '6639.HK,  Arrail Group Limited',
    name: 'Arrail Group Limited',
    symbol: '6639.HK',
  },
  {
    value: 'CAPD',
    label: 'CAPD,  Barclays ETN+ Shiller Capet ETN',
    name: 'Barclays ETN+ Shiller Capet ETN',
    symbol: 'CAPD',
  },
  {
    value: 'ATAK',
    label: 'ATAK,  Aurora Technology Acquisition Corp.',
    name: 'Aurora Technology Acquisition Corp.',
    symbol: 'ATAK',
  },
  {
    value: 'ATAKW',
    label: 'ATAKW,  Aurora Technology Acquisition Corp.',
    name: 'Aurora Technology Acquisition Corp.',
    symbol: 'ATAKW',
  },
  {
    value: 'ATAKR',
    label: 'ATAKR,  Aurora Technology Acquisition Corp.',
    name: 'Aurora Technology Acquisition Corp.',
    symbol: 'ATAKR',
  },
  {
    value: 'QQC.TO',
    label: 'QQC.TO,  Invesco NASDAQ 100 Index ETF',
    name: 'Invesco NASDAQ 100 Index ETF',
    symbol: 'QQC.TO',
  },
  {
    value: 'VFSUX',
    label: 'VFSUX,  Vanguard Short-Term Investment-Grade Fund Admiral Shares',
    name: 'Vanguard Short-Term Investment-Grade Fund Admiral Shares',
    symbol: 'VFSUX',
  },
  {
    value: '5PG.OL',
    label: '5PG.OL,  5th Planet Games A/S',
    name: '5th Planet Games A/S',
    symbol: '5PG.OL',
  },
  {
    value: 'ABXXF',
    label: 'ABXXF,  Abaxx Technologies Inc.',
    name: 'Abaxx Technologies Inc.',
    symbol: 'ABXXF',
  },
  {
    value: 'YOU.F',
    label: 'YOU.F,  ABOUT YOU Holding AG',
    name: 'ABOUT YOU Holding AG',
    symbol: 'YOU.F',
  },
  {
    value: 'ACCR',
    label: 'ACCR,  Access-Power & Co., Inc.',
    name: 'Access-Power & Co., Inc.',
    symbol: 'ACCR',
  },
  {
    value: 'ADCO.TO',
    label: 'ADCO.TO,  Adcore Inc.',
    name: 'Adcore Inc.',
    symbol: 'ADCO.TO',
  },
  {
    value: 'A31.SI',
    label: 'A31.SI,  Addvalue Technologies Ltd',
    name: 'Addvalue Technologies Ltd',
    symbol: 'A31.SI',
  },
  {
    value: 'ADKA.TA',
    label: 'ADKA.TA,  Adika Style Ltd.',
    name: 'Adika Style Ltd.',
    symbol: 'ADKA.TA',
  },
  {
    value: '7093.T',
    label: '7093.T,  adish Co., Ltd.',
    name: 'adish Co., Ltd.',
    symbol: '7093.T',
  },
  {
    value: 'ATCLF',
    label: 'ATCLF,  AdvanceTC Limited',
    name: 'AdvanceTC Limited',
    symbol: 'ATCLF',
  },
  {
    value: 'ABT.DU',
    label: 'ABT.DU,  Advanced Bitcoin Technologies AG',
    name: 'Advanced Bitcoin Technologies AG',
    symbol: 'ABT.DU',
  },
  {
    value: '6573.T',
    label: '6573.T,  Agile Media Network Inc.',
    name: 'Agile Media Network Inc.',
    symbol: '6573.T',
  },
  {
    value: '4476.T',
    label: '4476.T,  AI CROSS Inc.',
    name: 'AI CROSS Inc.',
    symbol: '4476.T',
  },
  {
    value: 'IQ.V',
    label: 'IQ.V,  AirIQ Inc.',
    name: 'AirIQ Inc.',
    symbol: 'IQ.V',
  },
  {
    value: 'ALF.MI',
    label: 'ALF.MI,  Alfonsino S.p.A.',
    name: 'Alfonsino S.p.A.',
    symbol: 'ALF.MI',
  },
  {
    value: '7077.T',
    label: '7077.T,  ALiNK Internet, Inc.',
    name: 'ALiNK Internet, Inc.',
    symbol: '7077.T',
  },
  {
    value: 'AIW.MI',
    label: 'AIW.MI,  Almawave S.p.A.',
    name: 'Almawave S.p.A.',
    symbol: 'AIW.MI',
  },
  {
    value: '4424.T',
    label: '4424.T,  Amazia,inc.',
    name: 'Amazia,inc.',
    symbol: '4424.T',
  },
  {
    value: '7671.T',
    label: '7671.T,  AmidA Holdings Co.,Ltd.',
    name: 'AmidA Holdings Co.,Ltd.',
    symbol: '7671.T',
  },
  {
    value: 'ANR.WA',
    label: 'ANR.WA,  Answear.com S.A.',
    name: 'Answear.com S.A.',
    symbol: 'ANR.WA',
  },
  {
    value: 'AV.MI',
    label: 'AV.MI,  Antares Vision S.p.A.',
    name: 'Antares Vision S.p.A.',
    symbol: 'AV.MI',
  },
  {
    value: '4180.T',
    label: '4180.T,  Appier Group, Inc.',
    name: 'Appier Group, Inc.',
    symbol: '4180.T',
  },
  {
    value: '4174.T',
    label: '4174.T,  Appirits Inc.',
    name: 'Appirits Inc.',
    symbol: '4174.T',
  },
  {
    value: '4015.T',
    label: '4015.T,  arara inc.',
    name: 'arara inc.',
    symbol: '4015.T',
  },
  {
    value: 'ART.WA',
    label: 'ART.WA,  Artifex Mundi S.A.',
    name: 'Artifex Mundi S.A.',
    symbol: 'ART.WA',
  },
  {
    value: 'ASB.WA',
    label: 'ASB.WA,  ASBISc Enterprises Plc',
    name: 'ASBISc Enterprises Plc',
    symbol: 'ASB.WA',
  },
  {
    value: '7378.T',
    label: '7378.T,  ASIRO Inc.',
    name: 'ASIRO Inc.',
    symbol: '7378.T',
  },
  {
    value: 'ASTRLS.CO',
    label: 'ASTRLS.CO,  Astralis A/S',
    name: 'Astralis A/S',
    symbol: 'ASTRLS.CO',
  },
  {
    value: 'ASTRO.OL',
    label: 'ASTRO.OL,  Astrocast SA',
    name: 'Astrocast SA',
    symbol: 'ASTRO.OL',
  },
  {
    value: 'ATD.WA',
    label: 'ATD.WA,  Atende S.A.',
    name: 'Atende S.A.',
    symbol: 'ATD.WA',
  },
  {
    value: '3IT.F',
    label: '3IT.F,  audius SE',
    name: 'audius SE',
    symbol: '3IT.F',
  },
  {
    value: '300074.SZ',
    label: '300074.SZ,  AVCON Information Technology Co., Ltd.',
    name: 'AVCON Information Technology Co., Ltd.',
    symbol: '300074.SZ',
  },
  {
    value: '4012.T',
    label: '4012.T,  Axis Co.,Ltd.',
    name: 'Axis Co.,Ltd.',
    symbol: '4012.T',
  },
  {
    value: '300451.SZ',
    label: '300451.SZ,  B-SOFT Co.,Ltd.',
    name: 'B-SOFT Co.,Ltd.',
    symbol: '300451.SZ',
  },
  {
    value: '7363.T',
    label: '7363.T,  baby calendar Inc.',
    name: 'baby calendar Inc.',
    symbol: '7363.T',
  },
  {
    value: '4393.T',
    label: '4393.T,  Bank of Innovation,Inc.',
    name: 'Bank of Innovation,Inc.',
    symbol: '4393.T',
  },
  {
    value: '4068.T',
    label: '4068.T,  Basis Corporation',
    name: 'Basis Corporation',
    symbol: '4068.T',
  },
  {
    value: '3986.T',
    label: '3986.T,  bBreak Systems Company, Limited',
    name: 'bBreak Systems Company, Limited',
    symbol: '3986.T',
  },
  {
    value: 'BBTV.TO',
    label: 'BBTV.TO,  BBTV Holdings Inc.',
    name: 'BBTV Holdings Inc.',
    symbol: 'BBTV.TO',
  },
  {
    value: '7376.T',
    label: '7376.T,  BCC Co., Ltd.',
    name: 'BCC Co., Ltd.',
    symbol: '7376.T',
  },
  {
    value: '300150.SZ',
    label: '300150.SZ,  Beijing Century Real Technology Co.,Ltd',
    name: 'Beijing Century Real Technology Co.,Ltd',
    symbol: '300150.SZ',
  },
  {
    value: '300075.SZ',
    label: '300075.SZ,  Beijing eGOVA Co,. Ltd',
    name: 'Beijing eGOVA Co,. Ltd',
    symbol: '300075.SZ',
  },
  {
    value: 'ZTEK',
    label: 'ZTEK,  Zentek Ltd.',
    name: 'Zentek Ltd.',
    symbol: 'ZTEK',
  },
  {
    value: '300036.SZ',
    label: '300036.SZ,  Beijing SuperMap Software Co., Ltd.',
    name: 'Beijing SuperMap Software Co., Ltd.',
    symbol: '300036.SZ',
  },
  {
    value: '300352.SZ',
    label: '300352.SZ,  Beijing VRV Software Corporation Limited',
    name: 'Beijing VRV Software Corporation Limited',
    symbol: '300352.SZ',
  },
  {
    value: '300799.SZ',
    label: '300799.SZ,  Beijing Zuojiang Technology Co., Ltd.',
    name: 'Beijing Zuojiang Technology Co., Ltd.',
    symbol: '300799.SZ',
  },
  {
    value: '4020.T',
    label: '4020.T,  Betrend Corporation',
    name: 'Betrend Corporation',
    symbol: '4020.T',
  },
  {
    value: 'BIKE.F',
    label: 'BIKE.F,  Bike24 Holding AG',
    name: 'Bike24 Holding AG',
    symbol: 'BIKE.F',
  },
  {
    value: 'MA10.DE',
    label: 'MA10.DE,  Binect AG',
    name: 'Binect AG',
    symbol: 'MA10.DE',
  },
  {
    value: 'BLN.TO',
    label: 'BLN.TO,  Blackline Safety Corp.',
    name: 'Blackline Safety Corp.',
    symbol: 'BLN.TO',
  },
  {
    value: 'BITK.V',
    label: 'BITK.V,  BlockchainK2 Corp.',
    name: 'BlockchainK2 Corp.',
    symbol: 'BITK.V',
  },
  {
    value: 'BLU.JO',
    label: 'BLU.JO,  Blue Label Telecoms Limited',
    name: 'Blue Label Telecoms Limited',
    symbol: 'BLU.JO',
  },
  {
    value: '4069.T',
    label: '4069.T,  BlueMeme Inc.',
    name: 'BlueMeme Inc.',
    symbol: '4069.T',
  },
  {
    value: 'BTC.CN',
    label: 'BTC.CN,  Bluesky Digital Assets Corp.',
    name: 'Bluesky Digital Assets Corp.',
    symbol: 'BTC.CN',
  },
  {
    value: 'BBT.WA',
    label: 'BBT.WA,  BoomBit S.A.',
    name: 'BoomBit S.A.',
    symbol: 'BBT.WA',
  },
  {
    value: '4381.T',
    label: '4381.T,  BPLATS,Inc.',
    name: 'BPLATS,Inc.',
    symbol: '4381.T',
  },
  {
    value: '4075.T',
    label: '4075.T,  Brains Technology, Inc.',
    name: 'Brains Technology, Inc.',
    symbol: '4075.T',
  },
  {
    value: 'B24.WA',
    label: 'B24.WA,  Brand 24 S.A.',
    name: 'Brand 24 S.A.',
    symbol: 'B24.WA',
  },
  {
    value: '300542.SZ',
    label: '300542.SZ,  Brilliance Technology Co., Ltd.',
    name: 'Brilliance Technology Co., Ltd.',
    symbol: '300542.SZ',
  },
  {
    value: '4398.T',
    label: '4398.T,  BroadBand Security, Inc.',
    name: 'BroadBand Security, Inc.',
    symbol: '4398.T',
  },
  {
    value: '7685.T',
    label: '7685.T,  BuySell Technologies Co.,Ltd.',
    name: 'BuySell Technologies Co.,Ltd.',
    symbol: '7685.T',
  },
  {
    value: '4166.T',
    label: '4166.T,  Cacco Inc.',
    name: 'Cacco Inc.',
    symbol: '4166.T',
  },
  {
    value: 'AUTO.V',
    label: 'AUTO.V,  Carbeeza Inc.',
    name: 'Carbeeza Inc.',
    symbol: 'AUTO.V',
  },
  {
    value: 'CASA.MI',
    label: 'CASA.MI,  CASASOLD S.p.A.',
    name: 'CASASOLD S.p.A.',
    symbol: 'CASA.MI',
  },
  {
    value: 'CTNK.F',
    label: 'CTNK.F,  centrotherm international AG',
    name: 'centrotherm international AG',
    symbol: 'CTNK.F',
  },
  {
    value: 'T7RA.HM',
    label: 'T7RA.HM,  CGift AG',
    name: 'CGift AG',
    symbol: 'T7RA.HM',
  },
  {
    value: 'C3RY.DE',
    label: 'C3RY.DE,  Cherry AG',
    name: 'Cherry AG',
    symbol: 'C3RY.DE',
  },
  {
    value: 'CPIA.F',
    label: 'CPIA.F,  ChipMOS TECHNOLOGIES INC.',
    name: 'ChipMOS TECHNOLOGIES INC.',
    symbol: 'CPIA.F',
  },
  {
    value: 'CIG.WA',
    label: 'CIG.WA,  CI Games S.A.',
    name: 'CI Games S.A.',
    symbol: 'CIG.WA',
  },
  {
    value: '6160.TWO',
    label: '6160.TWO,  Cipherlab Co.,Ltd.',
    name: 'Cipherlab Co.,Ltd.',
    symbol: '6160.TWO',
  },
  {
    value: 'CPIA.TA',
    label: 'CPIA.TA,  Cipia Vision Ltd',
    name: 'Cipia Vision Ltd',
    symbol: 'CPIA.TA',
  },
  {
    value: 'CDX.V',
    label: 'CDX.V,  Cloud DX Inc.',
    name: 'Cloud DX Inc.',
    symbol: 'CDX.V',
  },
  {
    value: '4176.T',
    label: '4176.T,  coconala Inc.',
    name: 'coconala Inc.',
    symbol: '4176.T',
  },
  {
    value: 'XCX.DU',
    label: 'XCX.DU,  coinIX GmbH & Co. KGaA',
    name: 'coinIX GmbH & Co. KGaA',
    symbol: 'XCX.DU',
  },
  {
    value: '4175.T',
    label: '4175.T,  coly Inc.',
    name: 'coly Inc.',
    symbol: '4175.T',
  },
  {
    value: '4496.T',
    label: '4496.T,  Commerce One Holdings Inc.',
    name: 'Commerce One Holdings Inc.',
    symbol: '4496.T',
  },
  {
    value: 'CPL.WA',
    label: 'CPL.WA,  Comperia.pl S.A.',
    name: 'Comperia.pl S.A.',
    symbol: 'CPL.WA',
  },
  {
    value: '4491.T',
    label: '4491.T,  Computer Management Co., Ltd.',
    name: 'Computer Management Co., Ltd.',
    symbol: '4491.T',
  },
  {
    value: 'CONFRZ.CO',
    label: 'CONFRZ.CO,  Conferize A/S',
    name: 'Conferize A/S',
    symbol: 'CONFRZ.CO',
  },
  {
    value: 'CPU2.F',
    label: 'CPU2.F,  CPU Softwarehouse AG',
    name: 'CPU Softwarehouse AG',
    symbol: 'CPU2.F',
  },
  {
    value: 'C76.SI',
    label: 'C76.SI,  Creative Technology Ltd',
    name: 'Creative Technology Ltd',
    symbol: 'C76.SI',
  },
  {
    value: 'CRJ.WA',
    label: 'CRJ.WA,  Creepy Jar S.A.',
    name: 'Creepy Jar S.A.',
    symbol: 'CRJ.WA',
  },
  {
    value: '544.SI',
    label: '544.SI,  CSE Global Limited',
    name: 'CSE Global Limited',
    symbol: '544.SI',
  },
  {
    value: 'CY4.MI',
    label: 'CY4.MI,  Cy4gate S.p.A.',
    name: 'Cy4gate S.p.A.',
    symbol: 'CY4.MI',
  },
  {
    value: '7069.T',
    label: '7069.T,  CyberBuzz, Inc.',
    name: 'CyberBuzz, Inc.',
    symbol: '7069.T',
  },
  {
    value: 'CYB.MI',
    label: 'CYB.MI,  Cyberoo S.p.A.',
    name: 'Cyberoo S.p.A.',
    symbol: 'CYB.MI',
  },
  {
    value: '4498.T',
    label: '4498.T,  Cybertrust Japan Co., Ltd.',
    name: 'Cybertrust Japan Co., Ltd.',
    symbol: '4498.T',
  },
  {
    value: '8WZ.F',
    label: '8WZ.F,  D-Market Elektronik Hizmetler ve Ticaret A.S.',
    name: 'D-Market Elektronik Hizmetler ve Ticaret A.S.',
    symbol: '8WZ.F',
  },
  {
    value: 'DAL.MI',
    label: 'DAL.MI,  Datalogic S.p.A.',
    name: 'Datalogic S.p.A.',
    symbol: 'DAL.MI',
  },
  {
    value: 'DCIK.F',
    label: 'DCIK.F,  DCI Database for Commerce and Industry AG',
    name: 'DCI Database for Commerce and Industry AG',
    symbol: 'DCIK.F',
  },
  {
    value: 'DTH.MI',
    label: 'DTH.MI,  Defence Tech Holding S.p.A.',
    name: 'Defence Tech Holding S.p.A.',
    symbol: 'DTH.MI',
  },
  {
    value: '9240.T',
    label: '9240.T,  Delivery Consulting Inc.',
    name: 'Delivery Consulting Inc.',
    symbol: '9240.T',
  },
  {
    value: 'DEVIT.NS',
    label: 'DEVIT.NS,  Dev Information Technology Limited',
    name: 'Dev Information Technology Limited',
    symbol: 'DEVIT.NS',
  },
  {
    value: 'DIGIZ.CO',
    label: 'DIGIZ.CO,  Digizuite A/S',
    name: 'Digizuite A/S',
    symbol: 'DIGIZ.CO',
  },
  {
    value: '300050.SZ',
    label: '300050.SZ,  Dingli Corp., Ltd.',
    name: 'Dingli Corp., Ltd.',
    symbol: '300050.SZ',
  },
  {
    value: 'DCSI.CN',
    label: 'DCSI.CN,  Direct Communication Solutions, Inc.',
    name: 'Direct Communication Solutions, Inc.',
    symbol: 'DCSI.CN',
  },
  {
    value: '6549.T',
    label: '6549.T,  DM Solutions Co.,Ltd',
    name: 'DM Solutions Co.,Ltd',
    symbol: '6549.T',
  },
  {
    value: 'DONKEY.CO',
    label: 'DONKEY.CO,  DonkeyRepublic Holding A/S',
    name: 'DonkeyRepublic Holding A/S',
    symbol: 'DONKEY.CO',
  },
  {
    value: '300010.SZ',
    label: '300010.SZ,  Doushen (Beijing) Education & Technology INC.',
    name: 'Doushen (Beijing) Education & Technology INC.',
    symbol: '300010.SZ',
  },
  {
    value: 'DOX.MI',
    label: 'DOX.MI,  Doxee S.p.A.',
    name: 'Doxee S.p.A.',
    symbol: 'DOX.MI',
  },
  {
    value: '3987.T',
    label: '3987.T,  Ecomott Inc.',
    name: 'Ecomott Inc.',
    symbol: '3987.T',
  },
  {
    value: 'EEG1T.TL',
    label: 'EEG1T.TL,  AS Ekspress Grupp',
    name: 'AS Ekspress Grupp',
    symbol: 'EEG1T.TL',
  },
  {
    value: 'ELES.MI',
    label: 'ELES.MI,  ELES Semiconductor Equipment S.p.A.',
    name: 'ELES Semiconductor Equipment S.p.A.',
    symbol: 'ELES.MI',
  },
  {
    value: 'E2N.MU',
    label: 'E2N.MU,  Endor AG',
    name: 'Endor AG',
    symbol: 'E2N.MU',
  },
  {
    value: 'GAME.V',
    label: 'GAME.V,  Engine Gaming and Media, Inc.',
    name: 'Engine Gaming and Media, Inc.',
    symbol: 'GAME.V',
  },
  {
    value: '300020.SZ',
    label: '300020.SZ,  Enjoyor Technology Co., Ltd.',
    name: 'Enjoyor Technology Co., Ltd.',
    symbol: '300020.SZ',
  },
  {
    value: '2334.T',
    label: '2334.T,  eole Inc.',
    name: 'eole Inc.',
    symbol: '2334.T',
  },
  {
    value: 'ESE.V',
    label: 'ESE.V,  ESE Entertainment Inc.',
    name: 'ESE Entertainment Inc.',
    symbol: 'ESE.V',
  },
  {
    value: '5351.TWO',
    label: '5351.TWO,  Etron Technology, Inc.',
    name: 'Etron Technology, Inc.',
    symbol: '5351.TWO',
  },
  {
    value: 'EXN.PA',
    label: 'EXN.PA,  Exclusive Networks SA',
    name: 'Exclusive Networks SA',
    symbol: 'EXN.PA',
  },
  {
    value: '4394.T',
    label: '4394.T,  eXmotion Co., Ltd.',
    name: 'eXmotion Co., Ltd.',
    symbol: '4394.T',
  },
  {
    value: 'EXAI.MI',
    label: 'EXAI.MI,  Expert.ai S.p.A.',
    name: 'Expert.ai S.p.A.',
    symbol: 'EXAI.MI',
  },
  {
    value: '4193.T',
    label: '4193.T,  Fabrica Communications Co., LTD.',
    name: 'Fabrica Communications Co., LTD.',
    symbol: '4193.T',
  },
  {
    value: 'FDGE.TO',
    label: 'FDGE.TO,  Farmers Edge Inc.',
    name: 'Farmers Edge Inc.',
    symbol: 'FDGE.TO',
  },
  {
    value: '7068.T',
    label: '7068.T,  Feedforce Group Inc.',
    name: 'Feedforce Group Inc.',
    symbol: '7068.T',
  },
  {
    value: '4052.T',
    label: '4052.T,  Ficha Inc.',
    name: 'Ficha Inc.',
    symbol: '4052.T',
  },
  {
    value: '7089.T',
    label: '7089.T,  for Startups, Inc.',
    name: 'for Startups, Inc.',
    symbol: '7089.T',
  },
  {
    value: '300525.SZ',
    label: '300525.SZ,  Fujian Boss Software Corp.',
    name: 'Fujian Boss Software Corp.',
    symbol: '300525.SZ',
  },
  {
    value: '4179.T',
    label: '4179.T,  G-NEXT Inc.',
    name: 'G-NEXT Inc.',
    symbol: '4179.T',
  },
  {
    value: 'GSQ.CN',
    label: 'GSQ.CN,  GameSquare Esports Inc.',
    name: 'GameSquare Esports Inc.',
    symbol: 'GSQ.CN',
  },
  {
    value: '063080.KQ',
    label: '063080.KQ,  Com2uS Holdings Corporation',
    name: 'Com2uS Holdings Corporation',
    symbol: '063080.KQ',
  },
  {
    value: 'ALGEC.PA',
    label: 'ALGEC.PA,  GECI International S.A.',
    name: 'GECI International S.A.',
    symbol: 'ALGEC.PA',
  },
  {
    value: '6562.T',
    label: '6562.T,  Geniee, Inc.',
    name: 'Geniee, Inc.',
    symbol: '6562.T',
  },
  {
    value: 'GEO.NZ',
    label: 'GEO.NZ,  Geo Limited',
    name: 'Geo Limited',
    symbol: 'GEO.NZ',
  },
  {
    value: '7357.T',
    label: '7357.T,  GEOCODE CO.,Ltd.',
    name: 'GEOCODE CO.,Ltd.',
    symbol: '7357.T',
  },
  {
    value: '4018.F',
    label: '4018.F,  Geolocation Technology, Inc.',
    name: 'Geolocation Technology, Inc.',
    symbol: '4018.F',
  },
  {
    value: 'NINJ3.SA',
    label: 'NINJ3.SA,  GetNinjas S.A.',
    name: 'GetNinjas S.A.',
    symbol: 'NINJ3.SA',
  },
  {
    value: '300465.SZ',
    label: '300465.SZ,  Global Infotech Co., Ltd.',
    name: 'Global Infotech Co., Ltd.',
    symbol: '300465.SZ',
  },
  {
    value: '4437.T',
    label: '4437.T,  gooddays holdings, Inc.',
    name: 'gooddays holdings, Inc.',
    symbol: '4437.T',
  },
  {
    value: '300098.SZ',
    label: '300098.SZ,  Gosuncn Technology Group Co., Ltd.',
    name: 'Gosuncn Technology Group Co., Ltd.',
    symbol: '300098.SZ',
  },
  {
    value: 'G107.TA',
    label: 'G107.TA,  Group 107 Ltd.',
    name: 'Group 107 Ltd.',
    symbol: 'G107.TA',
  },
  {
    value: 'FARM.MI',
    label: 'FARM.MI,  H-Farm S.p.A.',
    name: 'H-Farm S.p.A.',
    symbol: 'FARM.MI',
  },
  {
    value: 'HAI.TO',
    label: 'HAI.TO,  Haivision Systems Inc.',
    name: 'Haivision Systems Inc.',
    symbol: 'HAI.TO',
  },
  {
    value: '300025.SZ',
    label:
      '300025.SZ,  Hangzhou Huaxing Chuangye Communication Technology Co., Ltd.',
    name: 'Hangzhou Huaxing Chuangye Communication Technology Co., Ltd.',
    symbol: '300025.SZ',
  },
  {
    value: 'HMONY.OL',
    label: 'HMONY.OL,  Harmonychain AS',
    name: 'Harmonychain AS',
    symbol: 'HMONY.OL',
  },
  {
    value: 'A7K.DU',
    label: 'A7K.DU,  HBI-Hyperion SE',
    name: 'HBI-Hyperion SE',
    symbol: 'A7K.DU',
  },
  {
    value: '4200.T',
    label: '4200.T,  HCS Holdings Co.,Ltd.',
    name: 'HCS Holdings Co.,Ltd.',
    symbol: '4200.T',
  },
  {
    value: '4011.T',
    label: '4011.T,  Headwaters Co.,Ltd',
    name: 'Headwaters Co.,Ltd',
    symbol: '4011.T',
  },
  {
    value: 'HTG.F',
    label: 'HTG.F,  HomeToGo SE',
    name: 'HomeToGo SE',
    symbol: 'HTG.F',
  },
  {
    value: '7064.T',
    label: '7064.T,  Howtelevision, Inc.',
    name: 'Howtelevision, Inc.',
    symbol: '7064.T',
  },
  {
    value: '6597.T',
    label: '6597.T,  HPC SYSTEMS Inc.',
    name: 'HPC SYSTEMS Inc.',
    symbol: '6597.T',
  },
  {
    value: 'HMBL',
    label: 'HMBL,  HUMBL, Inc.',
    name: 'HUMBL, Inc.',
    symbol: 'HMBL',
  },
  {
    value: 'HUG.WA',
    label: 'HUG.WA,  Huuuge, Inc.',
    name: 'Huuuge, Inc.',
    symbol: 'HUG.WA',
  },
  {
    value: 'HYPE.CO',
    label: 'HYPE.CO,  Hypefactors A/S',
    name: 'Hypefactors A/S',
    symbol: 'HYPE.CO',
  },
  {
    value: 'HYI.F',
    label: 'HYI.F,  Hyrican Informationssysteme Aktiengesellschaft',
    name: 'Hyrican Informationssysteme Aktiengesellschaft',
    symbol: 'HYI.F',
  },
  {
    value: '4495.T',
    label: '4495.T,  i3 Systems, Inc.',
    name: 'i3 Systems, Inc.',
    symbol: '4495.T',
  },
  {
    value: 'IDNTT.MI',
    label: 'IDNTT.MI,  ID-ENTITY SA',
    name: 'ID-ENTITY SA',
    symbol: 'IDNTT.MI',
  },
  {
    value: 'IMPC.TA',
    label: 'IMPC.TA,  Impacx.Io Ltd',
    name: 'Impacx.Io Ltd',
    symbol: 'IMPC.TA',
  },
  {
    value: 'IMPERO.CO',
    label: 'IMPERO.CO,  Impero A/S',
    name: 'Impero A/S',
    symbol: 'IMPERO.CO',
  },
  {
    value: 'IMS.WA',
    label: 'IMS.WA,  IMS S.A.',
    name: 'IMS S.A.',
    symbol: 'IMS.WA',
  },
  {
    value: '4444.T',
    label: '4444.T,  infoNet inc.',
    name: 'infoNet inc.',
    symbol: '4444.T',
  },
  {
    value: 'IMAIL.CO',
    label: 'IMAIL.CO,  InterMail A/S',
    name: 'InterMail A/S',
    symbol: 'IMAIL.CO',
  },
  {
    value: '4057.T',
    label: '4057.T,  Interfactory, Inc.',
    name: 'Interfactory, Inc.',
    symbol: '4057.T',
  },
  {
    value: '7072.T',
    label: '7072.T,  Intimate Merger, Inc.',
    name: 'Intimate Merger, Inc.',
    symbol: '7072.T',
  },
  {
    value: '1447.T',
    label: '1447.T,  ITbook Holdings Co.,Ltd.',
    name: 'ITbook Holdings Co.,Ltd.',
    symbol: '1447.T',
  },
  {
    value: '7694.T',
    label: '7694.T,  itsumo.inc.',
    name: 'itsumo.inc.',
    symbol: '7694.T',
  },
  {
    value: '688182.SS',
    label: '688182.SS,  Jiangsu Cai Qin Technology Co., Ltd',
    name: 'Jiangsu Cai Qin Technology Co., Ltd',
    symbol: '688182.SS',
  },
  {
    value: '300339.SZ',
    label: '300339.SZ,  Jiangsu Hoperun Software Co., Ltd.',
    name: 'Jiangsu Hoperun Software Co., Ltd.',
    symbol: '300339.SZ',
  },
  {
    value: '7082.T',
    label: '7082.T,  Jimoty, Inc.',
    name: 'Jimoty, Inc.',
    symbol: '7082.T',
  },
  {
    value: '4170.T',
    label: '4170.T,  Kaizen Platform, Inc.',
    name: 'Kaizen Platform, Inc.',
    symbol: '4170.T',
  },
  {
    value: '7353.T',
    label: '7353.T,  KIYO Learning Co.,Ltd.',
    name: 'KIYO Learning Co.,Ltd.',
    symbol: '7353.T',
  },
  {
    value: 'ILK1.SG',
    label: 'ILK1.SG,  Planet Home Investment AG',
    name: 'Planet Home Investment AG',
    symbol: 'ILK1.SG',
  },
  {
    value: '3999.T',
    label: '3999.T,  KnowledgeSuite Inc.',
    name: 'KnowledgeSuite Inc.',
    symbol: '3999.T',
  },
  {
    value: 'KOMPL.OL',
    label: 'KOMPL.OL,  Komplett ASA',
    name: 'Komplett ASA',
    symbol: 'KOMPL.OL',
  },
  {
    value: 'KOM.WA',
    label: 'KOM.WA,  Komputronik S.A.',
    name: 'Komputronik S.A.',
    symbol: 'KOM.WA',
  },
  {
    value: '7695.T',
    label: '7695.T,  Koukandekirukun, Inc.',
    name: 'Koukandekirukun, Inc.',
    symbol: '7695.T',
  },
  {
    value: '4074.T',
    label: '4074.T,  LaKeel, Inc.',
    name: 'LaKeel, Inc.',
    symbol: '4074.T',
  },
  {
    value: '4484.T',
    label: '4484.T,  Lancers, Inc.',
    name: 'Lancers, Inc.',
    symbol: '4484.T',
  },
  {
    value: '300603.SZ',
    label: '300603.SZ,  Leon Technology Co., Ltd.',
    name: 'Leon Technology Co., Ltd.',
    symbol: '300603.SZ',
  },
  {
    value: 'LDF.F',
    label: 'LDF.F,  Linus Digital Finance AG',
    name: 'Linus Digital Finance AG',
    symbol: 'LDF.F',
  },
  {
    value: '4445.T',
    label: '4445.T,  Living Technologies Inc.',
    name: 'Living Technologies Inc.',
    symbol: '4445.T',
  },
  {
    value: '4391.T',
    label: '4391.T,  Logizard Co.,Ltd.',
    name: 'Logizard Co.,Ltd.',
    symbol: '4391.T',
  },
  {
    value: '6579.T',
    label: '6579.T,  logly,Inc.',
    name: 'logly,Inc.',
    symbol: '6579.T',
  },
  {
    value: 'LSI.WA',
    label: 'LSI.WA,  LSI Software S.A.',
    name: 'LSI Software S.A.',
    symbol: 'LSI.WA',
  },
  {
    value: '4380.T',
    label: '4380.T,  M - mart Inc.',
    name: 'M - mart Inc.',
    symbol: '4380.T',
  },
  {
    value: '7095.T',
    label: '7095.T,  Macbee Planet,Inc.',
    name: 'Macbee Planet,Inc.',
    symbol: '7095.T',
  },
  {
    value: 'MAPS.MI',
    label: 'MAPS.MI,  Maps S.p.A.',
    name: 'Maps S.p.A.',
    symbol: 'MAPS.MI',
  },
  {
    value: 'MAPS.CO',
    label: 'MAPS.CO,  Mapspeople A/S',
    name: 'Mapspeople A/S',
    symbol: 'MAPS.CO',
  },
  {
    value: 'MFT.MI',
    label: 'MFT.MI,  Matica Fintec S.p.A.',
    name: 'Matica Fintec S.p.A.',
    symbol: 'MFT.MI',
  },
  {
    value: 'AMI.F',
    label: 'AMI.F,  medondo holding AG',
    name: 'medondo holding AG',
    symbol: 'AMI.F',
  },
  {
    value: 'MFL.JO',
    label: 'MFL.JO,  Metrofile Holdings Limited',
    name: 'Metrofile Holdings Limited',
    symbol: 'MFL.JO',
  },
  {
    value: 'FRGE',
    label: 'FRGE,  Forge Global Holdings, Inc.',
    name: 'Forge Global Holdings, Inc.',
    symbol: 'FRGE',
  },
  {
    value: 'SCTL',
    label: 'SCTL,  Societal CDMO, Inc.',
    name: 'Societal CDMO, Inc.',
    symbol: 'SCTL',
  },
  {
    value: '4016.T',
    label: '4016.T,  MIT Holdings Co., Ltd.',
    name: 'MIT Holdings Co., Ltd.',
    symbol: '4016.T',
  },
  {
    value: 'MODU.HE',
    label: 'MODU.HE,  Modulight Oyj',
    name: 'Modulight Oyj',
    symbol: 'MODU.HE',
  },
  {
    value: 'MTRK.AS',
    label: 'MTRK.AS,  MotorK plc',
    name: 'MotorK plc',
    symbol: 'MTRK.AS',
  },
  {
    value: 'NA9.F',
    label: 'NA9.F,  Nagarro SE',
    name: 'Nagarro SE',
    symbol: 'NA9.F',
  },
  {
    value: 'CTZ.V',
    label: 'CTZ.V,  NamSys Inc.',
    name: 'NamSys Inc.',
    symbol: 'CTZ.V',
  },
  {
    value: 'NSP.MI',
    label: 'NSP.MI,  Neosperience S.p.A.',
    name: 'Neosperience S.p.A.',
    symbol: 'NSP.MI',
  },
  {
    value: 'NERD.CN',
    label: 'NERD.CN,  Nerds on Site Inc.',
    name: 'Nerds on Site Inc.',
    symbol: 'NERD.CN',
  },
  {
    value: 'NETUM.HE',
    label: 'NETUM.HE,  Netum Group Oyj',
    name: 'Netum Group Oyj',
    symbol: 'NETUM.HE',
  },
  {
    value: '300248.SZ',
    label: '300248.SZ,  Newcapec Electronics Co., Ltd.',
    name: 'Newcapec Electronics Co., Ltd.',
    symbol: '300248.SZ',
  },
  {
    value: 'NEXCOM.CO',
    label: 'NEXCOM.CO,  Nexcom A/S',
    name: 'Nexcom A/S',
    symbol: 'NEXCOM.CO',
  },
  {
    value: 'NXR.DE',
    label: 'NXR.DE,  NeXR Technologies SE',
    name: 'NeXR Technologies SE',
    symbol: 'NXR.DE',
  },
  {
    value: '4054.T',
    label: '4054.T,  Nihon Jyoho Create Co., Ltd.',
    name: 'Nihon Jyoho Create Co., Ltd.',
    symbol: '4054.T',
  },
  {
    value: '6578.T',
    label: '6578.T,  NLINKS Co., Ltd.',
    name: 'NLINKS Co., Ltd.',
    symbol: '6578.T',
  },
  {
    value: 'NORDH.OL',
    label: 'NORDH.OL,  Nordhealth AS',
    name: 'Nordhealth AS',
    symbol: 'NORDH.OL',
  },
  {
    value: '6613.TWO',
    label: '6613.TWO,  Nova Technology Corp.',
    name: 'Nova Technology Corp.',
    symbol: '6613.TWO',
  },
  {
    value: 'NTT.WA',
    label: 'NTT.WA,  NTT System S.A.',
    name: 'NTT System S.A.',
    symbol: 'NTT.WA',
  },
  {
    value: 'MEND.CN',
    label: 'MEND.CN,  Nurosene Health Inc.',
    name: 'Nurosene Health Inc.',
    symbol: 'MEND.CN',
  },
  {
    value: 'OG',
    label: 'OG,  Onion Global Limited',
    name: 'Onion Global Limited',
    symbol: 'OG',
  },
  {
    value: 'OPN.WA',
    label: 'OPN.WA,  Oponeo.pl S.A.',
    name: 'Oponeo.pl S.A.',
    symbol: 'OPN.WA',
  },
  {
    value: 'OPM.WA',
    label: 'OPM.WA,  OPTeam Spólka Akcyjna',
    name: 'OPTeam Spólka Akcyjna',
    symbol: 'OPM.WA',
  },
  {
    value: '6521.T',
    label: '6521.T,  OXIDE Corporation',
    name: 'OXIDE Corporation',
    symbol: '6521.T',
  },
  {
    value: '6696.T',
    label: '6696.T,  TRaaS On Product Inc.',
    name: 'TRaaS On Product Inc.',
    symbol: '6696.T',
  },
  {
    value: 'PYS.NZ',
    label: 'PYS.NZ,  PaySauce Limited',
    name: 'PaySauce Limited',
    symbol: 'PYS.NZ',
  },
  {
    value: 'PERK.CN',
    label: 'PERK.CN,  Perk Labs Inc.',
    name: 'Perk Labs Inc.',
    symbol: 'PERK.CN',
  },
  {
    value: '4165.T',
    label: '4165.T,  PLAID,Inc.',
    name: 'PLAID,Inc.',
    symbol: '4165.T',
  },
  {
    value: '4071.T',
    label: '4071.T,  Plus Alpha Consulting Co.,Ltd.',
    name: 'Plus Alpha Consulting Co.,Ltd.',
    symbol: '4071.T',
  },
  {
    value: 'PSA.MI',
    label: 'PSA.MI,  Portale Sardegna S.p.A.',
    name: 'Portale Sardegna S.p.A.',
    symbol: 'PSA.MI',
  },
  {
    value: '4450.T',
    label: '4450.T,  Power Solutions, Ltd.',
    name: 'Power Solutions, Ltd.',
    symbol: '4450.T',
  },
  {
    value: 'PBX.V',
    label: 'PBX.V,  PowerBand Solutions Inc.',
    name: 'PowerBand Solutions Inc.',
    symbol: 'PBX.V',
  },
  {
    value: '6770.TW',
    label: '6770.TW,  Powerchip Semiconductor Manufacturing Corp.',
    name: 'Powerchip Semiconductor Manufacturing Corp.',
    symbol: '6770.TW',
  },
  {
    value: 'PFM.V',
    label: 'PFM.V,  ProntoForms Corporation',
    name: 'ProntoForms Corporation',
    symbol: 'PFM.V',
  },
  {
    value: '4389.T',
    label: '4389.T,  Property Data Bank, Inc.',
    name: 'Property Data Bank, Inc.',
    symbol: '4389.T',
  },
  {
    value: 'BUKA.JK',
    label: 'BUKA.JK,  PT Bukalapak.com Tbk.',
    name: 'PT Bukalapak.com Tbk.',
    symbol: 'BUKA.JK',
  },
  {
    value: 'MTEL.JK',
    label: 'MTEL.JK,  PT Dayamitra Telekomunikasi Tbk.',
    name: 'PT Dayamitra Telekomunikasi Tbk.',
    symbol: 'MTEL.JK',
  },
  {
    value: 'RUNS.JK',
    label: 'RUNS.JK,  PT Global Sukses Solusi Tbk',
    name: 'PT Global Sukses Solusi Tbk',
    symbol: 'RUNS.JK',
  },
  {
    value: 'UVCR.JK',
    label: 'UVCR.JK,  PT Trimegah Karya Pratama Tbk',
    name: 'PT Trimegah Karya Pratama Tbk',
    symbol: 'UVCR.JK',
  },
  {
    value: 'QNT.WA',
    label: 'QNT.WA,  Quantum Software S.A.',
    name: 'Quantum Software S.A.',
    symbol: 'QNT.WA',
  },
  {
    value: 'QUEST.OL',
    label: 'QUEST.OL,  Questback Group AS',
    name: 'Questback Group AS',
    symbol: 'QUEST.OL',
  },
  {
    value: 'QUIS.V',
    label: 'QUIS.V,  Quisitive Technology Solutions, Inc.',
    name: 'Quisitive Technology Solutions, Inc.',
    symbol: 'QUIS.V',
  },
  {
    value: 'RAD.CN',
    label: 'RAD.CN,  Radial Research Corp.',
    name: 'Radial Research Corp.',
    symbol: 'RAD.CN',
  },
  {
    value: 'RAK.NZ',
    label: 'RAK.NZ,  Rakon Limited',
    name: 'Rakon Limited',
    symbol: 'RAK.NZ',
  },
  {
    value: '4060.T',
    label: '4060.T,  rakumo Inc.',
    name: 'rakumo Inc.',
    symbol: '4060.T',
  },
  {
    value: 'REEVO.MI',
    label: 'REEVO.MI,  Reevo S.P.A.',
    name: 'Reevo S.P.A.',
    symbol: 'REEVO.MI',
  },
  {
    value: 'RLT.MI',
    label: 'RLT.MI,  Relatech S.p.A.',
    name: 'Relatech S.p.A.',
    symbol: 'RLT.MI',
  },
  {
    value: '7356.T',
    label: '7356.T,  Retty Inc.',
    name: 'Retty Inc.',
    symbol: '7356.T',
  },
  {
    value: '4429.T',
    label: '4429.T,  Ricksoft Co., Ltd.',
    name: 'Ricksoft Co., Ltd.',
    symbol: '4429.T',
  },
  {
    value: 'RISMA.CO',
    label: 'RISMA.CO,  RISMA Systems A/S',
    name: 'RISMA Systems A/S',
    symbol: 'RISMA.CO',
  },
  {
    value: 'ROI.V',
    label: 'ROI.V,  Route1 Inc.',
    name: 'Route1 Inc.',
    symbol: 'ROI.V',
  },
  {
    value: 'R1B.F',
    label: 'R1B.F,  Rubean AG',
    name: 'Rubean AG',
    symbol: 'R1B.F',
  },
  {
    value: 'STC.TO',
    label: 'STC.TO,  Sangoma Technologies Corporation',
    name: 'Sangoma Technologies Corporation',
    symbol: 'STC.TO',
  },
  {
    value: '7326.T',
    label: '7326.T,  SBI Insurance Group Co., Ltd.',
    name: 'SBI Insurance Group Co., Ltd.',
    symbol: '7326.T',
  },
  {
    value: 'SCAPE.CO',
    label: 'SCAPE.CO,  Scape Technologies A/S',
    name: 'Scape Technologies A/S',
    symbol: 'SCAPE.CO',
  },
  {
    value: 'IOT.MI',
    label: 'IOT.MI,  Seco S.p.A.',
    name: 'Seco S.p.A.',
    symbol: 'IOT.MI',
  },
  {
    value: '0020.HK',
    label: '0020.HK,  SenseTime Group Inc.',
    name: 'SenseTime Group Inc.',
    symbol: '0020.HK',
  },
  {
    value: 'SGC.MI',
    label: 'SGC.MI,  SG Company S.p.A.',
    name: 'SG Company S.p.A.',
    symbol: 'SGC.MI',
  },
  {
    value: 'SHAPE.CO',
    label: 'SHAPE.CO,  Shape Robotics A/S',
    name: 'Shape Robotics A/S',
    symbol: 'SHAPE.CO',
  },
  {
    value: '4178.T',
    label: '4178.T,  Sharing Innovations Inc.',
    name: 'Sharing Innovations Inc.',
    symbol: '4178.T',
  },
  {
    value: '3989.T',
    label: '3989.T,  Sharingtechnology, Inc.',
    name: 'Sharingtechnology, Inc.',
    symbol: '3989.T',
  },
  {
    value: '300044.SZ',
    label: '300044.SZ,  Shenzhen Sunwin Intelligent Co., Ltd.',
    name: 'Shenzhen Sunwin Intelligent Co., Ltd.',
    symbol: '300044.SZ',
  },
  {
    value: '300047.SZ',
    label: '300047.SZ,  Shenzhen Tianyuan DIC Information Technology Co., Ltd.',
    name: 'Shenzhen Tianyuan DIC Information Technology Co., Ltd.',
    symbol: '300047.SZ',
  },
  {
    value: 'SHO.WA',
    label: 'SHO.WA,  Shoper S.A.',
    name: 'Shoper S.A.',
    symbol: 'SHO.WA',
  },
  {
    value: '4386.T',
    label: '4386.T,  SIG Group Co.,Ltd.',
    name: 'SIG Group Co.,Ltd.',
    symbol: '4386.T',
  },
  {
    value: 'XTP.F',
    label: 'XTP.F,  Sino AG',
    name: 'Sino AG',
    symbol: 'XTP.F',
  },
  {
    value: '300333.SZ',
    label: '300333.SZ,  SinoSun Technology Co. Ltd.',
    name: 'SinoSun Technology Co. Ltd.',
    symbol: '300333.SZ',
  },
  {
    value: '3995.T',
    label: '3995.T,  SKIYAKI Inc.',
    name: 'SKIYAKI Inc.',
    symbol: '3995.T',
  },
  {
    value: '4431.T',
    label: '4431.T,  Smaregi, Inc.',
    name: 'Smaregi, Inc.',
    symbol: '4431.T',
  },
  {
    value: 'SMOP.OL',
    label: 'SMOP.OL,  Smartoptics Group AS',
    name: 'Smartoptics Group AS',
    symbol: 'SMOP.OL',
  },
  {
    value: 'MLCMI.PA',
    label:
      'MLCMI.PA,  Societe de Conseil en Externalisation et en Marketing Internet - SCEMI Société Anonyme',
    name: 'Societe de Conseil en Externalisation et en Marketing Internet - SCEMI Société Anonyme',
    symbol: 'MLCMI.PA',
  },
  {
    value: 'SFTC.TO',
    label: 'SFTC.TO,  Softchoice Corporation',
    name: 'Softchoice Corporation',
    symbol: 'SFTC.TO',
  },
  {
    value: 'SOFTTECH.NS',
    label: 'SOFTTECH.NS,  SoftTech Engineers Limited',
    name: 'SoftTech Engineers Limited',
    symbol: 'SOFTTECH.NS',
  },
  {
    value: 'SOS.MI',
    label: 'SOS.MI,  SosTravel.com S.P.A.',
    name: 'SosTravel.com S.P.A.',
    symbol: 'SOS.MI',
  },
  {
    value: 'SOU.MI',
    label: 'SOU.MI,  Sourcesense S.p.A.',
    name: 'Sourcesense S.p.A.',
    symbol: 'SOU.MI',
  },
  {
    value: '4487.T',
    label: '4487.T,  Space Market, Inc.',
    name: 'Space Market, Inc.',
    symbol: '4487.T',
  },
  {
    value: 'SPFY.CN',
    label: 'SPFY.CN,  Spacefy Inc.',
    name: 'Spacefy Inc.',
    symbol: 'SPFY.CN',
  },
  {
    value: 'SPN.MI',
    label: 'SPN.MI,  Spindox S.p.A.',
    name: 'Spindox S.p.A.',
    symbol: 'SPN.MI',
  },
  {
    value: '4019.T',
    label: '4019.T,  Stamen Co.,Ltd.',
    name: 'Stamen Co.,Ltd.',
    symbol: '4019.T',
  },
  {
    value: '300300.SZ',
    label: '300300.SZ,  Strait Innovation Internet Co., Ltd.',
    name: 'Strait Innovation Internet Co., Ltd.',
    symbol: '300300.SZ',
  },
  {
    value: 'STRO.OL',
    label: 'STRO.OL,  StrongPoint ASA',
    name: 'StrongPoint ASA',
    symbol: 'STRO.OL',
  },
  {
    value: 'SGN.WA',
    label: 'SGN.WA,  Sygnity S.A.',
    name: 'Sygnity S.A.',
    symbol: 'SGN.WA',
  },
  {
    value: '3988.T',
    label: '3988.T,  SYS Holdings Co., Ltd.',
    name: 'SYS Holdings Co., Ltd.',
    symbol: '3988.T',
  },
  {
    value: '4055.T',
    label: '4055.T,  T&S inc.',
    name: 'T&S inc.',
    symbol: '4055.T',
  },
  {
    value: '0T8B.F',
    label: '0T8B.F,  Tarena International, Inc.',
    name: 'Tarena International, Inc.',
    symbol: '0T8B.F',
  },
  {
    value: 'TRAD3.SA',
    label: 'TRAD3.SA,  TC Traders Club S.A.',
    name: 'TC Traders Club S.A.',
    symbol: 'TRAD3.SA',
  },
  {
    value: '6697.T',
    label: '6697.T,  Techpoint, Inc.',
    name: 'Techpoint, Inc.',
    symbol: '6697.T',
  },
  {
    value: '7046.T',
    label: '7046.T,  TDSE Inc.',
    name: 'TDSE Inc.',
    symbol: '7046.T',
  },
  {
    value: 'TEM1V.HE',
    label: 'TEM1V.HE,  Tecnotree Oyj',
    name: 'Tecnotree Oyj',
    symbol: 'TEM1V.HE',
  },
  {
    value: 'TH51.MU',
    label: 'TH51.MU,  Teleservice AG',
    name: 'Teleservice AG',
    symbol: 'TH51.MU',
  },
  {
    value: 'TLT1V.HE',
    label: 'TLT1V.HE,  Teleste Corporation',
    name: 'Teleste Corporation',
    symbol: 'TLT1V.HE',
  },
  {
    value: '4198.T',
    label: '4198.T,  TENDA Co.,LTD.',
    name: 'TENDA Co.,LTD.',
    symbol: '4198.T',
  },
  {
    value: 'TRX.TA',
    label: 'TRX.TA,  Terminal X Online Ltd.',
    name: 'Terminal X Online Ltd.',
    symbol: 'TRX.TA',
  },
  {
    value: 'PU11.F',
    label: 'PU11.F,  The Social Chain AG',
    name: 'The Social Chain AG',
    symbol: 'PU11.F',
  },
  {
    value: 'FZKA.F',
    label: 'FZKA.F,  The9 Limited',
    name: 'The9 Limited',
    symbol: 'FZKA.F',
  },
  {
    value: 'THNC.TO',
    label: 'THNC.TO,  Thinkific Labs Inc.',
    name: 'Thinkific Labs Inc.',
    symbol: 'THNC.TO',
  },
  {
    value: 'TIM.WA',
    label: 'TIM.WA,  TIM S.A.',
    name: 'TIM S.A.',
    symbol: 'TIM.WA',
  },
  {
    value: '7359.T',
    label: '7359.T,  TokyoTsushin, Inc.',
    name: 'TokyoTsushin, Inc.',
    symbol: '7359.T',
  },
  {
    value: '4172.T',
    label: '4172.T,  TOWA Hi SYSTEM CO.,LTD.',
    name: 'TOWA Hi SYSTEM CO.,LTD.',
    symbol: '4172.T',
  },
  {
    value: '4058.T',
    label: '4058.T,  Toyokumo, Inc.',
    name: 'Toyokumo, Inc.',
    symbol: '4058.T',
  },
  {
    value: '3997.T',
    label: '3997.T,  TRADE WORKS Co., Ltd',
    name: 'TRADE WORKS Co., Ltd',
    symbol: '3997.T',
  },
  {
    value: 'TD.MI',
    label: 'TD.MI,  TrenDevice S.p.A.',
    name: 'TrenDevice S.p.A.',
    symbol: 'TD.MI',
  },
  {
    value: 'TGAMES.CO',
    label: 'TGAMES.CO,  TROPHY GAMES Development A/S',
    name: 'TROPHY GAMES Development A/S',
    symbol: 'TGAMES.CO',
  },
  {
    value: '2978.T',
    label: '2978.T,  TSUKURUBA Inc.',
    name: 'TSUKURUBA Inc.',
    symbol: '2978.T',
  },
  {
    value: 'TXT.MI',
    label: 'TXT.MI,  TXT e-solutions S.p.A.',
    name: 'TXT e-solutions S.p.A.',
    symbol: 'TXT.MI',
  },
  {
    value: 'U24.MI',
    label: 'U24.MI,  UCapital24 S.p.A.',
    name: 'UCapital24 S.p.A.',
    symbol: 'U24.MI',
  },
  {
    value: 'ULG.WA',
    label: 'ULG.WA,  Ultimate Games S.A.',
    name: 'Ultimate Games S.A.',
    symbol: 'ULG.WA',
  },
  {
    value: 'UNCT.TA',
    label: 'UNCT.TA,  Unicorn Technologies - Limited Partnership',
    name: 'Unicorn Technologies - Limited Partnership',
    symbol: 'UNCT.TA',
  },
  {
    value: 'UPG.BR',
    label: 'UPG.BR,  Unifiedpost Group SA',
    name: 'Unifiedpost Group SA',
    symbol: 'UPG.BR',
  },
  {
    value: '6550.T',
    label: '6550.T,  Unipos Inc.',
    name: 'Unipos Inc.',
    symbol: '6550.T',
  },
  {
    value: '4486.T',
    label: '4486.T,  Unite and Grow Inc.',
    name: 'Unite and Grow Inc.',
    symbol: '4486.T',
  },
  {
    value: '2177.HK',
    label: '2177.HK,  UNQ Holdings Limited',
    name: 'UNQ Holdings Limited',
    symbol: '2177.HK',
  },
  {
    value: '3990.T',
    label: '3990.T,  UUUM Co.,Ltd.',
    name: 'UUUM Co.,Ltd.',
    symbol: '3990.T',
  },
  {
    value: '4442.T',
    label: '4442.T,  Valtes Co., Ltd.',
    name: 'Valtes Co., Ltd.',
    symbol: '4442.T',
  },
  {
    value: '4422.T',
    label: '4422.T,  VALUENEX Japan Inc.',
    name: 'VALUENEX Japan Inc.',
    symbol: '4422.T',
  },
  {
    value: 'VALUER.CO',
    label: 'VALUER.CO,  Valuer Holding A/S',
    name: 'Valuer Holding A/S',
    symbol: 'VALUER.CO',
  },
  {
    value: 'VNT.MI',
    label: 'VNT.MI,  Vantea SMART S.p.A.',
    name: 'Vantea SMART S.p.A.',
    symbol: 'VNT.MI',
  },
  {
    value: '4494.T',
    label: '4494.T,  Vario Secure Inc.',
    name: 'Vario Secure Inc.',
    symbol: '4494.T',
  },
  {
    value: 'VRC.WA',
    label: 'VRC.WA,  Vercom S.A.',
    name: 'Vercom S.A.',
    symbol: 'VRC.WA',
  },
  {
    value: 'FORA.TO',
    label: 'FORA.TO,  VerticalScope Holdings Inc.',
    name: 'VerticalScope Holdings Inc.',
    symbol: 'FORA.TO',
  },
  {
    value: 'VINCIT.HE',
    label: 'VINCIT.HE,  Vincit Oyj',
    name: 'Vincit Oyj',
    symbol: 'VINCIT.HE',
  },
  {
    value: 'VVD.WA',
    label: 'VVD.WA,  Vivid Games S.A.',
    name: 'Vivid Games S.A.',
    symbol: 'VVD.WA',
  },
  {
    value: 'VKCO.IL',
    label: 'VKCO.IL,  VK Company Limited',
    name: 'VK Company Limited',
    symbol: 'VKCO.IL',
  },
  {
    value: 'VNTZ-M.TA',
    label: 'VNTZ-M.TA,  Vonetize Plc',
    name: 'Vonetize Plc',
    symbol: 'VNTZ-M.TA',
  },
  {
    value: 'EXR.IR',
    label: 'EXR.IR,  ENGAGE XR Holdings plc',
    name: 'ENGAGE XR Holdings plc',
    symbol: 'EXR.IR',
  },
  {
    value: '4173.T',
    label: '4173.T,  Wacul.Inc',
    name: 'Wacul.Inc',
    symbol: '4173.T',
  },
  {
    value: '3991.T',
    label: '3991.T,  Wantedly, Inc.',
    name: 'Wantedly, Inc.',
    symbol: '3991.T',
  },
  {
    value: 'WEB.MI',
    label: 'WEB.MI,  Websolute S.p.A.',
    name: 'Websolute S.p.A.',
    symbol: 'WEB.MI',
  },
  {
    value: '4438.T',
    label: '4438.T,  Welby Inc.',
    name: 'Welby Inc.',
    symbol: '4438.T',
  },
  {
    value: '3105.TWO',
    label: '3105.TWO,  WIN Semiconductors Corp.',
    name: 'WIN Semiconductors Corp.',
    symbol: '3105.TWO',
  },
  {
    value: '4199.T',
    label: '4199.T,  WonderPlanet Inc.',
    name: 'WonderPlanet Inc.',
    symbol: '4199.T',
  },
  {
    value: '6580.T',
    label: '6580.T,  Writeup Co.,Ltd.',
    name: 'Writeup Co.,Ltd.',
    symbol: '6580.T',
  },
  {
    value: '300031.SZ',
    label: '300031.SZ,  Wuxi Boton Technology Co., Ltd.',
    name: 'Wuxi Boton Technology Co., Ltd.',
    symbol: '300031.SZ',
  },
  {
    value: '300051.SZ',
    label: '300051.SZ,  Xiamen 35.Com Technology Co., Ltd.',
    name: 'Xiamen 35.Com Technology Co., Ltd.',
    symbol: '300051.SZ',
  },
  {
    value: 'YOO.V',
    label: 'YOO.V,  YANGAROO Inc.',
    name: 'YANGAROO Inc.',
    symbol: 'YOO.V',
  },
  {
    value: '4168.T',
    label: '4168.T,  Yappli, Inc.',
    name: 'Yappli, Inc.',
    symbol: '4168.T',
  },
  {
    value: '2209.HK',
    label: '2209.HK,  YesAsia Holdings Limited',
    name: 'YesAsia Holdings Limited',
    symbol: '2209.HK',
  },
  {
    value: '300096.SZ',
    label: '300096.SZ,  YLZ Information Technology Co., Ltd',
    name: 'YLZ Information Technology Co., Ltd',
    symbol: '300096.SZ',
  },
  {
    value: '7371.T',
    label: '7371.T,  Zenken Corporation',
    name: 'Zenken Corporation',
    symbol: '7371.T',
  },
  {
    value: '4387.T',
    label: '4387.T,  ZUU Co.,Ltd.',
    name: 'ZUU Co.,Ltd.',
    symbol: '4387.T',
  },
  {
    value: 'ALLG',
    label: 'ALLG,  Allego N.V.',
    name: 'Allego N.V.',
    symbol: 'ALLG',
  },
  {
    value: 'DRTS',
    label: 'DRTS,  Alpha Tau Medical Ltd.',
    name: 'Alpha Tau Medical Ltd.',
    symbol: 'DRTS',
  },
  {
    value: 'RFACU',
    label: 'RFACU,  RF Acquisition Corp.',
    name: 'RF Acquisition Corp.',
    symbol: 'RFACU',
  },
  {
    value: 'REM.JO',
    label: 'REM.JO,  Remgro Limited',
    name: 'Remgro Limited',
    symbol: 'REM.JO',
  },
  {
    value: 'RCL.JO',
    label: 'RCL.JO,  RCL Foods Limited',
    name: 'RCL Foods Limited',
    symbol: 'RCL.JO',
  },
  {
    value: 'MEI.JO',
    label: 'MEI.JO,  Mediclinic International plc',
    name: 'Mediclinic International plc',
    symbol: 'MEI.JO',
  },
  {
    value: '6049.T',
    label: '6049.T,  ItoKuro Inc.',
    name: 'ItoKuro Inc.',
    symbol: '6049.T',
  },
  {
    value: 'LCFY',
    label: 'LCFY,  Locafy Limited',
    name: 'Locafy Limited',
    symbol: 'LCFY',
  },
  {
    value: 'ANTX',
    label: 'ANTX,  AN2 Therapeutics, Inc.',
    name: 'AN2 Therapeutics, Inc.',
    symbol: 'ANTX',
  },
  {
    value: 'ADDYY',
    label: 'ADDYY,  adidas AG',
    name: 'adidas AG',
    symbol: 'ADDYY',
  },
  {
    value: 'VAR.OL',
    label: 'VAR.OL,  Vår Energi AS',
    name: 'Vår Energi AS',
    symbol: 'VAR.OL',
  },
  {
    value: 'GTEK',
    label: 'GTEK,  Goldman Sachs Future Tech Leaders Equity ETF',
    name: 'Goldman Sachs Future Tech Leaders Equity ETF',
    symbol: 'GTEK',
  },
  {
    value: 'HSUN',
    label: 'HSUN,  Hartford Sustainable Income ETF',
    name: 'Hartford Sustainable Income ETF',
    symbol: 'HSUN',
  },
  {
    value: 'IAPR',
    label: 'IAPR,  Innovator MSCI EAFE Power Buffer ETF - April',
    name: 'Innovator MSCI EAFE Power Buffer ETF - April',
    symbol: 'IAPR',
  },
  {
    value: 'CLSA',
    label: 'CLSA,  Cabana Target Leading Sector Aggressive ETF',
    name: 'Cabana Target Leading Sector Aggressive ETF',
    symbol: 'CLSA',
  },
  {
    value: 'BEP-PM.TO',
    label: 'BEP-PM.TO,  Brookfield Renewable Partners L.P.',
    name: 'Brookfield Renewable Partners L.P.',
    symbol: 'BEP-PM.TO',
  },
  {
    value: 'BIP-PE.TO',
    label: 'BIP-PE.TO,  Brookfield Infrastructure Partners L.P.',
    name: 'Brookfield Infrastructure Partners L.P.',
    symbol: 'BIP-PE.TO',
  },
  {
    value: 'BPO-PI.TO',
    label: 'BPO-PI.TO,  Brookfield Office Properties Inc.',
    name: 'Brookfield Office Properties Inc.',
    symbol: 'BPO-PI.TO',
  },
  {
    value: 'CUP-U.TO',
    label: 'CUP-U.TO,  Caribbean Utilities Company, Ltd.',
    name: 'Caribbean Utilities Company, Ltd.',
    symbol: 'CUP-U.TO',
  },
  {
    value: 'EMA-PH.TO',
    label: 'EMA-PH.TO,  Emera Incorporated',
    name: 'Emera Incorporated',
    symbol: 'EMA-PH.TO',
  },
  {
    value: 'GWO-PN.TO',
    label: 'GWO-PN.TO,  Great-West Lifeco Inc.',
    name: 'Great-West Lifeco Inc.',
    symbol: 'GWO-PN.TO',
  },
  {
    value: 'NA-PC.TO',
    label: 'NA-PC.TO,  National Bank of Canada',
    name: 'National Bank of Canada',
    symbol: 'NA-PC.TO',
  },
  {
    value: 'NA-PE.TO',
    label: 'NA-PE.TO,  National Bank of Canada',
    name: 'National Bank of Canada',
    symbol: 'NA-PE.TO',
  },
  {
    value: 'RY-PZ.TO',
    label: 'RY-PZ.TO,  Royal Bank of Canada',
    name: 'Royal Bank of Canada',
    symbol: 'RY-PZ.TO',
  },
  {
    value: 'TD-PFI.TO',
    label: 'TD-PFI.TO,  The Toronto-Dominion Bank',
    name: 'The Toronto-Dominion Bank',
    symbol: 'TD-PFI.TO',
  },
  {
    value: 'LLAP',
    label: 'LLAP,  Terran Orbital Corporation',
    name: 'Terran Orbital Corporation',
    symbol: 'LLAP',
  },
  {
    value: 'MTMT',
    label: 'MTMT,  Mega Matrix Corp.',
    name: 'Mega Matrix Corp.',
    symbol: 'MTMT',
  },
  {
    value: '094820.KQ',
    label: '094820.KQ,  Iljin Power Co., Ltd.',
    name: 'Iljin Power Co., Ltd.',
    symbol: '094820.KQ',
  },
  {
    value: 'BALD-B.ST',
    label: 'BALD-B.ST,  Fastighets AB Balder (publ)',
    name: 'Fastighets AB Balder (publ)',
    symbol: 'BALD-B.ST',
  },
  {
    value: 'ABB.ST',
    label: 'ABB.ST,  ABB Ltd',
    name: 'ABB Ltd',
    symbol: 'ABB.ST',
  },
  {
    value: '300008.SZ',
    label: '300008.SZ,  Bestway Marine & Energy Technology Co.,Ltd',
    name: 'Bestway Marine & Energy Technology Co.,Ltd',
    symbol: '300008.SZ',
  },
  {
    value: '300919.SZ',
    label: '300919.SZ,  CNGR Advanced Material Co.,Ltd.',
    name: 'CNGR Advanced Material Co.,Ltd.',
    symbol: '300919.SZ',
  },
  {
    value: '301058.SZ',
    label: '301058.SZ,  COFCO Engineering & Technology Co., Ltd.',
    name: 'COFCO Engineering & Technology Co., Ltd.',
    symbol: '301058.SZ',
  },
  {
    value: '300364.SZ',
    label: '300364.SZ,  COL Digital Publishing Group Co., Ltd.',
    name: 'COL Digital Publishing Group Co., Ltd.',
    symbol: '300364.SZ',
  },
  {
    value: '300299.SZ',
    label: '300299.SZ,  Fuchun Technology Co., Ltd.',
    name: 'Fuchun Technology Co., Ltd.',
    symbol: '300299.SZ',
  },
  {
    value: '300027.SZ',
    label: '300027.SZ,  Huayi Brothers Media Corporation',
    name: 'Huayi Brothers Media Corporation',
    symbol: '300027.SZ',
  },
  {
    value: '300030.SZ',
    label: '300030.SZ,  Improve Medical Instruments Co., Ltd.',
    name: 'Improve Medical Instruments Co., Ltd.',
    symbol: '300030.SZ',
  },
  {
    value: '300439.SZ',
    label: '300439.SZ,  Medicalsystem Biotechnology Co., Ltd.',
    name: 'Medicalsystem Biotechnology Co., Ltd.',
    symbol: '300439.SZ',
  },
  {
    value: '300776.SZ',
    label: '300776.SZ,  Wuhan DR Laser Technology Corp.,Ltd',
    name: 'Wuhan DR Laser Technology Corp.,Ltd',
    symbol: '300776.SZ',
  },
  {
    value: '300147.SZ',
    label: '300147.SZ,  Xiangxue Pharmaceutical Co.,Ltd.',
    name: 'Xiangxue Pharmaceutical Co.,Ltd.',
    symbol: '300147.SZ',
  },
  {
    value: '300224.SZ',
    label: '300224.SZ,  Yantai Zhenghai Magnetic Material Co., Ltd.',
    name: 'Yantai Zhenghai Magnetic Material Co., Ltd.',
    symbol: '300224.SZ',
  },
  {
    value: '300131.SZ',
    label: '300131.SZ,  Shenzhen Yitoa Intelligent Control Co.,Ltd.',
    name: 'Shenzhen Yitoa Intelligent Control Co.,Ltd.',
    symbol: '300131.SZ',
  },
  {
    value: '300957.SZ',
    label: '300957.SZ,  Yunnan Botanee Bio-Technology Group Co.LTD',
    name: 'Yunnan Botanee Bio-Technology Group Co.LTD',
    symbol: '300957.SZ',
  },
  {
    value: 'SVIIU',
    label: 'SVIIU,  Spring Valley Acquisition Corp. II',
    name: 'Spring Valley Acquisition Corp. II',
    symbol: 'SVIIU',
  },
  {
    value: 'EMCGU',
    label: 'EMCGU,  Embrace Change Acquisition Corp.',
    name: 'Embrace Change Acquisition Corp.',
    symbol: 'EMCGU',
  },
  {
    value: 'IVCAU',
    label: 'IVCAU,  Investcorp India Acquisition Corp',
    name: 'Investcorp India Acquisition Corp',
    symbol: 'IVCAU',
  },
  {
    value: 'RWODU',
    label: 'RWODU,  Redwoods Acquisition Corp.',
    name: 'Redwoods Acquisition Corp.',
    symbol: 'RWODU',
  },
  {
    value: 'FCD-UN.TO',
    label: 'FCD-UN.TO,  Firm Capital Property Trust',
    name: 'Firm Capital Property Trust',
    symbol: 'FCD-UN.TO',
  },
  {
    value: 'GMBLP',
    label: 'GMBLP,  Esports Entertainment Group, Inc.',
    name: 'Esports Entertainment Group, Inc.',
    symbol: 'GMBLP',
  },
  {
    value: 'ADRT',
    label: 'ADRT,  Ault Disruptive Technologies Corporation',
    name: 'Ault Disruptive Technologies Corporation',
    symbol: 'ADRT',
  },
  {
    value: 'RVSN',
    label: 'RVSN,  Rail Vision Ltd.',
    name: 'Rail Vision Ltd.',
    symbol: 'RVSN',
  },
  {
    value: 'MSSAU',
    label: 'MSSAU,  Metal Sky Star Acquisition Corporation',
    name: 'Metal Sky Star Acquisition Corporation',
    symbol: 'MSSAU',
  },
  {
    value: 'CPAQ',
    label: 'CPAQ,  Counter Press Acquisition Corporation',
    name: 'Counter Press Acquisition Corporation',
    symbol: 'CPAQ',
  },
  {
    value: 'IVDAW',
    label: 'IVDAW,  Iveda Solutions, Inc.',
    name: 'Iveda Solutions, Inc.',
    symbol: 'IVDAW',
  },
  {
    value: 'XPON',
    label: 'XPON,  Expion360 Inc.',
    name: 'Expion360 Inc.',
    symbol: 'XPON',
  },
  {
    value: 'EMBC',
    label: 'EMBC,  Embecta Corp.',
    name: 'Embecta Corp.',
    symbol: 'EMBC',
  },
  {
    value: 'WLY',
    label: 'WLY,  John Wiley & Sons, Inc.',
    name: 'John Wiley & Sons, Inc.',
    symbol: 'WLY',
  },
  {
    value: 'WLYB',
    label: 'WLYB,  John Wiley & Sons, Inc.',
    name: 'John Wiley & Sons, Inc.',
    symbol: 'WLYB',
  },
  {
    value: 'SWVL',
    label: 'SWVL,  Swvl Holdings Corp.',
    name: 'Swvl Holdings Corp.',
    symbol: 'SWVL',
  },
  {
    value: '1010.SR',
    label: '1010.SR,  Riyad Bank',
    name: 'Riyad Bank',
    symbol: '1010.SR',
  },
  {
    value: '1020.SR',
    label: '1020.SR,  Bank AlJazira',
    name: 'Bank AlJazira',
    symbol: '1020.SR',
  },
  {
    value: '1030.SR',
    label: '1030.SR,  The Saudi Investment Bank',
    name: 'The Saudi Investment Bank',
    symbol: '1030.SR',
  },
  {
    value: '1050.SR',
    label: '1050.SR,  Banque Saudi Fransi',
    name: 'Banque Saudi Fransi',
    symbol: '1050.SR',
  },
  {
    value: '1060.SR',
    label: '1060.SR,  The Saudi British Bank',
    name: 'The Saudi British Bank',
    symbol: '1060.SR',
  },
  {
    value: '1080.SR',
    label: '1080.SR,  Arab National Bank',
    name: 'Arab National Bank',
    symbol: '1080.SR',
  },
  {
    value: '1111.SR',
    label: '1111.SR,  Saudi Tadawul Group Holding Company',
    name: 'Saudi Tadawul Group Holding Company',
    symbol: '1111.SR',
  },
  {
    value: '1120.SR',
    label: '1120.SR,  Al Rajhi Banking and Investment Corporation',
    name: 'Al Rajhi Banking and Investment Corporation',
    symbol: '1120.SR',
  },
  {
    value: '1140.SR',
    label: '1140.SR,  Bank Albilad',
    name: 'Bank Albilad',
    symbol: '1140.SR',
  },
  {
    value: '1150.SR',
    label: '1150.SR,  Alinma Bank',
    name: 'Alinma Bank',
    symbol: '1150.SR',
  },
  {
    value: '1180.SR',
    label: '1180.SR,  The Saudi National Bank',
    name: 'The Saudi National Bank',
    symbol: '1180.SR',
  },
  {
    value: '1182.SR',
    label: '1182.SR,  Amlak International for Real Estate Finance Company',
    name: 'Amlak International for Real Estate Finance Company',
    symbol: '1182.SR',
  },
  {
    value: '1212.SR',
    label: '1212.SR,  Astra Industrial Group Company',
    name: 'Astra Industrial Group Company',
    symbol: '1212.SR',
  },
  {
    value: '1213.SR',
    label: '1213.SR,  Naseej International Trading Company',
    name: 'Naseej International Trading Company',
    symbol: '1213.SR',
  },
  {
    value: '1214.SR',
    label: '1214.SR,  Al Hassan Ghazi Ibrahim Shaker Company',
    name: 'Al Hassan Ghazi Ibrahim Shaker Company',
    symbol: '1214.SR',
  },
  {
    value: '1302.SR',
    label: '1302.SR,  Bawan Company',
    name: 'Bawan Company',
    symbol: '1302.SR',
  },
  {
    value: '1303.SR',
    label: '1303.SR,  Electrical Industries Company',
    name: 'Electrical Industries Company',
    symbol: '1303.SR',
  },
  {
    value: '1322.SR',
    label: '1322.SR,  Al Masane Al Kobra Mining Company',
    name: 'Al Masane Al Kobra Mining Company',
    symbol: '1322.SR',
  },
  {
    value: '1810.SR',
    label: '1810.SR,  Seera Holding Group',
    name: 'Seera Holding Group',
    symbol: '1810.SR',
  },
  {
    value: '1820.SR',
    label:
      '1820.SR,  Abdulmohsen Al-Hokair Group for Tourism and Development Company',
    name: 'Abdulmohsen Al-Hokair Group for Tourism and Development Company',
    symbol: '1820.SR',
  },
  {
    value: '1830.SR',
    label: '1830.SR,  Leejam Sports Company',
    name: 'Leejam Sports Company',
    symbol: '1830.SR',
  },
  {
    value: '1831.SR',
    label: '1831.SR,  Maharah for Human Resources Company',
    name: 'Maharah for Human Resources Company',
    symbol: '1831.SR',
  },
  {
    value: '1832.SR',
    label: '1832.SR,  Sadr Logistics Company',
    name: 'Sadr Logistics Company',
    symbol: '1832.SR',
  },
  {
    value: '2030.SR',
    label: '2030.SR,  Saudi Arabia Refineries Company',
    name: 'Saudi Arabia Refineries Company',
    symbol: '2030.SR',
  },
  {
    value: '2040.SR',
    label: '2040.SR,  Saudi Ceramic Company',
    name: 'Saudi Ceramic Company',
    symbol: '2040.SR',
  },
  {
    value: '2050.SR',
    label: '2050.SR,  Savola Group Company',
    name: 'Savola Group Company',
    symbol: '2050.SR',
  },
  {
    value: '2070.SR',
    label:
      '2070.SR,  Saudi Pharmaceutical Industries and Medical Appliances Corporation',
    name: 'Saudi Pharmaceutical Industries and Medical Appliances Corporation',
    symbol: '2070.SR',
  },
  {
    value: '2080.SR',
    label: '2080.SR,  National Gas & Industerialization Co.',
    name: 'National Gas & Industerialization Co.',
    symbol: '2080.SR',
  },
  {
    value: '2081.SR',
    label: '2081.SR,  Alkhorayef Water & Power Technologies',
    name: 'Alkhorayef Water & Power Technologies',
    symbol: '2081.SR',
  },
  {
    value: '2082.SR',
    label: '2082.SR,  ACWA POWER Company',
    name: 'ACWA POWER Company',
    symbol: '2082.SR',
  },
  {
    value: '2100.SR',
    label: '2100.SR,  Wafrah for Industry and Development Company',
    name: 'Wafrah for Industry and Development Company',
    symbol: '2100.SR',
  },
  {
    value: '2120.SR',
    label: '2120.SR,  Saudi Advanced Industries Company',
    name: 'Saudi Advanced Industries Company',
    symbol: '2120.SR',
  },
  {
    value: '2130.SR',
    label: '2130.SR,  Saudi Industrial Development Co.',
    name: 'Saudi Industrial Development Co.',
    symbol: '2130.SR',
  },
  {
    value: '2140.SR',
    label: '2140.SR,  Ayyan Investment Company',
    name: 'Ayyan Investment Company',
    symbol: '2140.SR',
  },
  {
    value: '2160.SR',
    label: '2160.SR,  The Saudi Arabian Amiantit Company',
    name: 'The Saudi Arabian Amiantit Company',
    symbol: '2160.SR',
  },
  {
    value: '2190.SR',
    label: '2190.SR,  Saudi Industrial Services Company',
    name: 'Saudi Industrial Services Company',
    symbol: '2190.SR',
  },
  {
    value: '2222.SR',
    label: '2222.SR,  Saudi Arabian Oil Company',
    name: 'Saudi Arabian Oil Company',
    symbol: '2222.SR',
  },
  {
    value: '2230.SR',
    label: '2230.SR,  Saudi Chemical Holding Company',
    name: 'Saudi Chemical Holding Company',
    symbol: '2230.SR',
  },
  {
    value: '2281.SR',
    label: '2281.SR,  Tanmiah Food Company',
    name: 'Tanmiah Food Company',
    symbol: '2281.SR',
  },
  {
    value: '2370.SR',
    label: '2370.SR,  Middle East Specialized Cables Company',
    name: 'Middle East Specialized Cables Company',
    symbol: '2370.SR',
  },
  {
    value: '4006.SR',
    label: '4006.SR,  Saudi Marketing Company',
    name: 'Saudi Marketing Company',
    symbol: '4006.SR',
  },
  {
    value: '4014.SR',
    label: '4014.SR,  Scientific and Medical Equipment House Company',
    name: 'Scientific and Medical Equipment House Company',
    symbol: '4014.SR',
  },
  {
    value: '4030.SR',
    label: '4030.SR,  The National Shipping Company of Saudi Arabia',
    name: 'The National Shipping Company of Saudi Arabia',
    symbol: '4030.SR',
  },
  {
    value: '4061.SR',
    label: '4061.SR,  Anaam International Holding Group Company',
    name: 'Anaam International Holding Group Company',
    symbol: '4061.SR',
  },
  {
    value: '4071.SR',
    label: '4071.SR,  Arabian Contracting Services Company',
    name: 'Arabian Contracting Services Company',
    symbol: '4071.SR',
  },
  {
    value: '4081.SR',
    label: '4081.SR,  Nayifat Finance Company',
    name: 'Nayifat Finance Company',
    symbol: '4081.SR',
  },
  {
    value: '4090.SR',
    label: '4090.SR,  Taiba Investments Co.',
    name: 'Taiba Investments Co.',
    symbol: '4090.SR',
  },
  {
    value: '4100.SR',
    label: '4100.SR,  Makkah Construction & Development Company',
    name: 'Makkah Construction & Development Company',
    symbol: '4100.SR',
  },
  {
    value: '4130.SR',
    label: '4130.SR,  Al-Baha Investment & Development Co.',
    name: 'Al-Baha Investment & Development Co.',
    symbol: '4130.SR',
  },
  {
    value: '4140.SR',
    label: '4140.SR,  Saudi Industrial Export Company',
    name: 'Saudi Industrial Export Company',
    symbol: '4140.SR',
  },
  {
    value: '4141.SR',
    label: '4141.SR,  Al-Omran Industrial Trading Company',
    name: 'Al-Omran Industrial Trading Company',
    symbol: '4141.SR',
  },
  {
    value: '4160.SR',
    label: '4160.SR,  Thimar Development Holding Company',
    name: 'Thimar Development Holding Company',
    symbol: '4160.SR',
  },
  {
    value: '4161.SR',
    label: '4161.SR,  Bin Dawood Holding Company',
    name: 'Bin Dawood Holding Company',
    symbol: '4161.SR',
  },
  {
    value: '4162.SR',
    label: '4162.SR,  Almunajem Foods Company',
    name: 'Almunajem Foods Company',
    symbol: '4162.SR',
  },
  {
    value: '4163.SR',
    label: '4163.SR,  Al-Dawaa Medical Services Company',
    name: 'Al-Dawaa Medical Services Company',
    symbol: '4163.SR',
  },
  {
    value: '4164.SR',
    label: '4164.SR,  Nahdi Medical Company',
    name: 'Nahdi Medical Company',
    symbol: '4164.SR',
  },
  {
    value: '4170.SR',
    label: '4170.SR,  Tourism Enterprises Co.',
    name: 'Tourism Enterprises Co.',
    symbol: '4170.SR',
  },
  {
    value: '4191.SR',
    label: '4191.SR,  Abdullah Saad Mohammed Abo Moati for Bookstores Company',
    name: 'Abdullah Saad Mohammed Abo Moati for Bookstores Company',
    symbol: '4191.SR',
  },
  {
    value: '4250.SR',
    label: '4250.SR,  Jabal Omar Development Company',
    name: 'Jabal Omar Development Company',
    symbol: '4250.SR',
  },
  {
    value: '4280.SR',
    label: '4280.SR,  Kingdom Holding Company',
    name: 'Kingdom Holding Company',
    symbol: '4280.SR',
  },
  {
    value: '4310.SR',
    label: '4310.SR,  Knowledge Economic City Company',
    name: 'Knowledge Economic City Company',
    symbol: '4310.SR',
  },
  {
    value: '4331.SR',
    label: '4331.SR,  Aljazira Mawten REIT Fund',
    name: 'Aljazira Mawten REIT Fund',
    symbol: '4331.SR',
  },
  {
    value: '4332.SR',
    label: '4332.SR,  Jadwa REIT Al Haramain Fund',
    name: 'Jadwa REIT Al Haramain Fund',
    symbol: '4332.SR',
  },
  {
    value: '4334.SR',
    label: '4334.SR,  Al Maather Reit Fund',
    name: 'Al Maather Reit Fund',
    symbol: '4334.SR',
  },
  {
    value: '4345.SR',
    label: '4345.SR,  Swicorp Wabel Reit Fund',
    name: 'Swicorp Wabel Reit Fund',
    symbol: '4345.SR',
  },
  {
    value: '4346.SR',
    label: '4346.SR,  Middle East Financial Investment Company',
    name: 'Middle East Financial Investment Company',
    symbol: '4346.SR',
  },
  {
    value: '4347.SR',
    label: '4347.SR,  Bonyan REIT Fund',
    name: 'Bonyan REIT Fund',
    symbol: '4347.SR',
  },
  {
    value: '6020.SR',
    label: '6020.SR,  Al Gassim Investment Holding Company',
    name: 'Al Gassim Investment Holding Company',
    symbol: '6020.SR',
  },
  {
    value: 'STEW',
    label: 'STEW,  SRH Total Return Fund, Inc.',
    name: 'SRH Total Return Fund, Inc.',
    symbol: 'STEW',
  },
  {
    value: '6060.SR',
    label: '6060.SR,  Ash-Sharqiyah Development Co.',
    name: 'Ash-Sharqiyah Development Co.',
    symbol: '6060.SR',
  },
  {
    value: '7040.SR',
    label: '7040.SR,  Etihad Atheeb Telecommunication Company',
    name: 'Etihad Atheeb Telecommunication Company',
    symbol: '7040.SR',
  },
  {
    value: '7202.SR',
    label: '7202.SR,  Arabian Internet and Communications Services Co. Ltd.',
    name: 'Arabian Internet and Communications Services Co. Ltd.',
    symbol: '7202.SR',
  },
  {
    value: '7203.SR',
    label: '7203.SR,  Elm Company',
    name: 'Elm Company',
    symbol: '7203.SR',
  },
  {
    value: '8012.SR',
    label: '8012.SR,  Aljazira Takaful Taawuni Company',
    name: 'Aljazira Takaful Taawuni Company',
    symbol: '8012.SR',
  },
  {
    value: '8040.SR',
    label: '8040.SR,  Allianz Saudi Fransi Cooperative Insurance Company',
    name: 'Allianz Saudi Fransi Cooperative Insurance Company',
    symbol: '8040.SR',
  },
  {
    value: '8050.SR',
    label: '8050.SR,  Salama Cooperative Insurance Company',
    name: 'Salama Cooperative Insurance Company',
    symbol: '8050.SR',
  },
  {
    value: '8100.SR',
    label: '8100.SR,  Saudi Arabian Cooperative Insurance Company',
    name: 'Saudi Arabian Cooperative Insurance Company',
    symbol: '8100.SR',
  },
  {
    value: '8120.SR',
    label: '8120.SR,  Gulf Union Alahlia Cooperative Insurance Company',
    name: 'Gulf Union Alahlia Cooperative Insurance Company',
    symbol: '8120.SR',
  },
  {
    value: '8150.SR',
    label: '8150.SR,  Allied Cooperative Insurance Group',
    name: 'Allied Cooperative Insurance Group',
    symbol: '8150.SR',
  },
  {
    value: '8160.SR',
    label: '8160.SR,  Arabia Insurance Cooperative Company',
    name: 'Arabia Insurance Cooperative Company',
    symbol: '8160.SR',
  },
  {
    value: '8180.SR',
    label: '8180.SR,  Al Sagr Cooperative Insurance Company',
    name: 'Al Sagr Cooperative Insurance Company',
    symbol: '8180.SR',
  },
  {
    value: '8240.SR',
    label: '8240.SR,  Chubb Arabia Cooperative Insurance Company',
    name: 'Chubb Arabia Cooperative Insurance Company',
    symbol: '8240.SR',
  },
  {
    value: '8312.SR',
    label: '8312.SR,  Alinma Tokio Marine Company',
    name: 'Alinma Tokio Marine Company',
    symbol: '8312.SR',
  },
  {
    value: '9510.SR',
    label: '9510.SR,  National Building and Marketing Co.',
    name: 'National Building and Marketing Co.',
    symbol: '9510.SR',
  },
  {
    value: '9511.SR',
    label: '9511.SR,  Sumou Real Estate Company',
    name: 'Sumou Real Estate Company',
    symbol: '9511.SR',
  },
  {
    value: '9515.SR',
    label: '9515.SR,  Fesh Fash Snack Food Production Company',
    name: 'Fesh Fash Snack Food Production Company',
    symbol: '9515.SR',
  },
  {
    value: '9517.SR',
    label: '9517.SR,  National Fertilizer Company Ltd',
    name: 'National Fertilizer Company Ltd',
    symbol: '9517.SR',
  },
  {
    value: '9518.SR',
    label: '9518.SR,  Canadian General Medical Center Complex Company',
    name: 'Canadian General Medical Center Complex Company',
    symbol: '9518.SR',
  },
  {
    value: '9519.SR',
    label: '9519.SR,  Banan Real Estate Company',
    name: 'Banan Real Estate Company',
    symbol: '9519.SR',
  },
  {
    value: '9520.SR',
    label: '9520.SR,  Shatirah House Restaurant Co.',
    name: 'Shatirah House Restaurant Co.',
    symbol: '9520.SR',
  },
  {
    value: '9521.SR',
    label:
      '9521.SR,  Enma Al Rawabi Investment & Real Estate Development Company',
    name: 'Enma Al Rawabi Investment & Real Estate Development Company',
    symbol: '9521.SR',
  },
  {
    value: '9522.SR',
    label: '9522.SR,  Alhasoob Co.',
    name: 'Alhasoob Co.',
    symbol: '9522.SR',
  },
  {
    value: '9524.SR',
    label:
      '9524.SR,  Advance International Company for Communication and Information Technology',
    name: 'Advance International Company for Communication and Information Technology',
    symbol: '9524.SR',
  },
  {
    value: '9527.SR',
    label: '9527.SR,  Ame Company For Medical Supplies',
    name: 'Ame Company For Medical Supplies',
    symbol: '9527.SR',
  },
  {
    value: '9528.SR',
    label: '9528.SR,  Gas Arabian Services Company',
    name: 'Gas Arabian Services Company',
    symbol: '9528.SR',
  },
  {
    value: '9529.SR',
    label: '9529.SR,  Raoom trading Company',
    name: 'Raoom trading Company',
    symbol: '9529.SR',
  },
  {
    value: '9530.SR',
    label: '9530.SR,  Arabian International Healthcare Holding Company',
    name: 'Arabian International Healthcare Holding Company',
    symbol: '9530.SR',
  },
  {
    value: '9531.SR',
    label: '9531.SR,  Al Obeikan Glass Company',
    name: 'Al Obeikan Glass Company',
    symbol: '9531.SR',
  },
  {
    value: '9532.SR',
    label: '9532.SR,  Aljouf Mineral Water Bottling Co.',
    name: 'Aljouf Mineral Water Bottling Co.',
    symbol: '9532.SR',
  },
  {
    value: '9533.SR',
    label: '9533.SR,  Saudi Parts Center Company',
    name: 'Saudi Parts Center Company',
    symbol: '9533.SR',
  },
  {
    value: '9534.SR',
    label:
      '9534.SR,  Saudi Azm for Communication and Information Technology Company',
    name: 'Saudi Azm for Communication and Information Technology Company',
    symbol: '9534.SR',
  },
  {
    value: 'LYT',
    label: 'LYT,  Lytus Technologies Holdings PTV. Ltd.',
    name: 'Lytus Technologies Holdings PTV. Ltd.',
    symbol: 'LYT',
  },
  {
    value: 'VCADX',
    label:
      'VCADX,  Vanguard California Intermediate-Term Tax-Exempt Fd Admiral  Sh',
    name: 'Vanguard California Intermediate-Term Tax-Exempt Fd Admiral  Sh',
    symbol: 'VCADX',
  },
  {
    value: 'BFH',
    label: 'BFH,  Bread Financial Holdings, Inc.',
    name: 'Bread Financial Holdings, Inc.',
    symbol: 'BFH',
  },
  {
    value: '300860.SZ',
    label: '300860.SZ,  Beijing Funshine Culture Media Co., Ltd.',
    name: 'Beijing Funshine Culture Media Co., Ltd.',
    symbol: '300860.SZ',
  },
  {
    value: 'NUTX',
    label: 'NUTX,  Nutex Health Inc.',
    name: 'Nutex Health Inc.',
    symbol: 'NUTX',
  },
  {
    value: 'RACY',
    label: 'RACY,  Relativity Acquisition Corp.',
    name: 'Relativity Acquisition Corp.',
    symbol: 'RACY',
  },
  {
    value: 'ENOV',
    label: 'ENOV,  Enovis Corporation',
    name: 'Enovis Corporation',
    symbol: 'ENOV',
  },
  {
    value: 'ESAB',
    label: 'ESAB,  ESAB Corporation',
    name: 'ESAB Corporation',
    symbol: 'ESAB',
  },
  {
    value: 'LAB',
    label: 'LAB,  Standard BioTools Inc.',
    name: 'Standard BioTools Inc.',
    symbol: 'LAB',
  },
  {
    value: '4336.SR',
    label: '4336.SR,  Mulkia Gulf Real Estate REIT',
    name: 'Mulkia Gulf Real Estate REIT',
    symbol: '4336.SR',
  },
  {
    value: '8311.SR',
    label: '8311.SR,  Saudi Enaya Cooperative Insurance Company',
    name: 'Saudi Enaya Cooperative Insurance Company',
    symbol: '8311.SR',
  },
  {
    value: '9525.SR',
    label: '9525.SR,  Alwasail Industrial Company',
    name: 'Alwasail Industrial Company',
    symbol: '9525.SR',
  },
  {
    value: 'DECAU',
    label: 'DECAU,  Denali Capital Acquisition Corp.',
    name: 'Denali Capital Acquisition Corp.',
    symbol: 'DECAU',
  },
  {
    value: 'AFARU',
    label: 'AFARU,  Aura FAT Projects Acquisition Corp',
    name: 'Aura FAT Projects Acquisition Corp',
    symbol: 'AFARU',
  },
  {
    value: 'GENQ',
    label: 'GENQ,  Genesis Unicorn Capital Corp.',
    name: 'Genesis Unicorn Capital Corp.',
    symbol: 'GENQ',
  },
  {
    value: 'GENQW',
    label: 'GENQW,  Genesis Unicorn Capital Corp.',
    name: 'Genesis Unicorn Capital Corp.',
    symbol: 'GENQW',
  },
  {
    value: 'MLTX',
    label: 'MLTX,  MoonLake Immunotherapeutics',
    name: 'MoonLake Immunotherapeutics',
    symbol: 'MLTX',
  },
  {
    value: 'FTIIW',
    label: 'FTIIW,  FutureTech II Acquisition Corp.',
    name: 'FutureTech II Acquisition Corp.',
    symbol: 'FTIIW',
  },
  {
    value: 'SHELL.AS',
    label: 'SHELL.AS,  Shell plc',
    name: 'Shell plc',
    symbol: 'SHELL.AS',
  },
  {
    value: 'H78.SI',
    label: 'H78.SI,  Hongkong Land Holdings Limited',
    name: 'Hongkong Land Holdings Limited',
    symbol: 'H78.SI',
  },
  {
    value: '3269.T',
    label: '3269.T,  Advance Residence Investment Corporation',
    name: 'Advance Residence Investment Corporation',
    symbol: '3269.T',
  },
  {
    value: '3281.T',
    label: '3281.T,  GLP J-REIT',
    name: 'GLP J-REIT',
    symbol: '3281.T',
  },
  {
    value: '3283.T',
    label: '3283.T,  Nippon Prologis REIT, Inc.',
    name: 'Nippon Prologis REIT, Inc.',
    symbol: '3283.T',
  },
  {
    value: '3462.T',
    label: '3462.T,  Nomura Real Estate Master Fund, Inc.',
    name: 'Nomura Real Estate Master Fund, Inc.',
    symbol: '3462.T',
  },
  {
    value: '9147.T',
    label: '9147.T,  Nippon Express Holdings,Inc.',
    name: 'Nippon Express Holdings,Inc.',
    symbol: '9147.T',
  },
  {
    value: 'UMG.AS',
    label: 'UMG.AS,  Universal Music Group N.V.',
    name: 'Universal Music Group N.V.',
    symbol: 'UMG.AS',
  },
  {
    value: 'EFE.F',
    label: 'EFE.F,  AB Sagax (publ)',
    name: 'AB Sagax (publ)',
    symbol: 'EFE.F',
  },
  {
    value: 'S63.SI',
    label: 'S63.SI,  Singapore Technologies Engineering Ltd',
    name: 'Singapore Technologies Engineering Ltd',
    symbol: 'S63.SI',
  },
  {
    value: 'S68.SI',
    label: 'S68.SI,  Singapore Exchange Limited',
    name: 'Singapore Exchange Limited',
    symbol: 'S68.SI',
  },
  {
    value: 'U11.SI',
    label: 'U11.SI,  United Overseas Bank Limited',
    name: 'United Overseas Bank Limited',
    symbol: 'U11.SI',
  },
  {
    value: 'Z74.SI',
    label: 'Z74.SI,  Singapore Telecommunications Limited',
    name: 'Singapore Telecommunications Limited',
    symbol: 'Z74.SI',
  },
  {
    value: 'SMSN.IL',
    label: 'SMSN.IL,  Samsung Electronics Co., Ltd.',
    name: 'Samsung Electronics Co., Ltd.',
    symbol: 'SMSN.IL',
  },
  {
    value: 'ACUT',
    label: 'ACUT,  Accustem Sciences Inc.',
    name: 'Accustem Sciences Inc.',
    symbol: 'ACUT',
  },
  {
    value: 'AAM-PA',
    label: 'AAM-PA,  Apollo Global Management, Inc.',
    name: 'Apollo Global Management, Inc.',
    symbol: 'AAM-PA',
  },
  {
    value: 'BAC-PQ',
    label: 'BAC-PQ,  Bank of America Corporation',
    name: 'Bank of America Corporation',
    symbol: 'BAC-PQ',
  },
  {
    value: 'BAC-PS',
    label: 'BAC-PS,  Bank of America Corporation',
    name: 'Bank of America Corporation',
    symbol: 'BAC-PS',
  },
  {
    value: 'BOC',
    label: 'BOC,  Boston Omaha Corporation',
    name: 'Boston Omaha Corporation',
    symbol: 'BOC',
  },
  {
    value: 'BHFAM',
    label: 'BHFAM,  Brighthouse Financial, Inc.',
    name: 'Brighthouse Financial, Inc.',
    symbol: 'BHFAM',
  },
  {
    value: 'ARMX.AE',
    label: 'ARMX.AE,  Aramex PJSC',
    name: 'Aramex PJSC',
    symbol: 'ARMX.AE',
  },
  {
    value: 'DIB.AE',
    label: 'DIB.AE,  Dubai Islamic Bank P.J.S.C.',
    name: 'Dubai Islamic Bank P.J.S.C.',
    symbol: 'DIB.AE',
  },
  {
    value: 'DIC.AE',
    label: 'DIC.AE,  Dubai Investments PJSC',
    name: 'Dubai Investments PJSC',
    symbol: 'DIC.AE',
  },
  {
    value: 'DFM.AE',
    label: 'DFM.AE,  Dubai Financial Market P.J.S.C.',
    name: 'Dubai Financial Market P.J.S.C.',
    symbol: 'DFM.AE',
  },
  {
    value: 'DU.AE',
    label: 'DU.AE,  Emirates Integrated Telecommunications Company PJSC',
    name: 'Emirates Integrated Telecommunications Company PJSC',
    symbol: 'DU.AE',
  },
  {
    value: 'UPP.AE',
    label: 'UPP.AE,  Union Properties Public Joint Stock Company',
    name: 'Union Properties Public Joint Stock Company',
    symbol: 'UPP.AE',
  },
  {
    value: 'CRM.BA',
    label: 'CRM.BA,  Salesforce, Inc.',
    name: 'Salesforce, Inc.',
    symbol: 'CRM.BA',
  },
  {
    value: 'PAAS.BA',
    label: 'PAAS.BA,  Pan American Silver Corp.',
    name: 'Pan American Silver Corp.',
    symbol: 'PAAS.BA',
  },
  {
    value: 'CAI.VI',
    label: 'CAI.VI,  CA Immobilien Anlagen AG',
    name: 'CA Immobilien Anlagen AG',
    symbol: 'CAI.VI',
  },
  {
    value: 'EVN.VI',
    label: 'EVN.VI,  EVN AG',
    name: 'EVN AG',
    symbol: 'EVN.VI',
  },
  {
    value: 'PAL.VI',
    label: 'PAL.VI,  Palfinger AG',
    name: 'Palfinger AG',
    symbol: 'PAL.VI',
  },
  {
    value: 'SEM.VI',
    label: 'SEM.VI,  Semperit Aktiengesellschaft Holding',
    name: 'Semperit Aktiengesellschaft Holding',
    symbol: 'SEM.VI',
  },
  {
    value: 'UBS.VI',
    label: 'UBS.VI,  UBM Development AG',
    name: 'UBM Development AG',
    symbol: 'UBS.VI',
  },
  {
    value: 'DOC.VI',
    label: 'DOC.VI,  DO & CO Aktiengesellschaft',
    name: 'DO & CO Aktiengesellschaft',
    symbol: 'DOC.VI',
  },
  {
    value: 'ZAG.VI',
    label: 'ZAG.VI,  Zumtobel Group AG',
    name: 'Zumtobel Group AG',
    symbol: 'ZAG.VI',
  },
  {
    value: 'VIG.VI',
    label: 'VIG.VI,  Vienna Insurance Group AG',
    name: 'Vienna Insurance Group AG',
    symbol: 'VIG.VI',
  },
  {
    value: 'MMK.VI',
    label: 'MMK.VI,  Mayr-Melnhof Karton AG',
    name: 'Mayr-Melnhof Karton AG',
    symbol: 'MMK.VI',
  },
  {
    value: 'IIA.VI',
    label: 'IIA.VI,  IMMOFINANZ AG',
    name: 'IMMOFINANZ AG',
    symbol: 'IIA.VI',
  },
  {
    value: 'BG.VI',
    label: 'BG.VI,  BAWAG Group AG',
    name: 'BAWAG Group AG',
    symbol: 'BG.VI',
  },
  {
    value: 'AZE.BR',
    label: 'AZE.BR,  Azelis Group NV',
    name: 'Azelis Group NV',
    symbol: 'AZE.BR',
  },
  {
    value: 'AHL-PD',
    label: 'AHL-PD,  Aspen Insurance Holdings Limited',
    name: 'Aspen Insurance Holdings Limited',
    symbol: 'AHL-PD',
  },
  {
    value: 'BTE.SI',
    label: 'BTE.SI,  Bund Center Investment Ltd',
    name: 'Bund Center Investment Ltd',
    symbol: 'BTE.SI',
  },
  {
    value: 'D01.SI',
    label: 'D01.SI,  DFI Retail Group Holdings Limited',
    name: 'DFI Retail Group Holdings Limited',
    symbol: 'D01.SI',
  },
  {
    value: 'PRE-PJ',
    label: 'PRE-PJ,  PartnerRe Ltd.',
    name: 'PartnerRe Ltd.',
    symbol: 'PRE-PJ',
  },
  {
    value: '5GD.SI',
    label: '5GD.SI,  Sunpower Group Ltd.',
    name: 'Sunpower Group Ltd.',
    symbol: '5GD.SI',
  },
  {
    value: 'ANRG.TO',
    label: 'ANRG.TO,  Anaergia Inc.',
    name: 'Anaergia Inc.',
    symbol: 'ANRG.TO',
  },
  {
    value: 'BRMI.TO',
    label: 'BRMI.TO,  Boat Rocker Media Inc.',
    name: 'Boat Rocker Media Inc.',
    symbol: 'BRMI.TO',
  },
  {
    value: 'CU-X.TO',
    label: 'CU-X.TO,  Canadian Utilities Limited',
    name: 'Canadian Utilities Limited',
    symbol: 'CU-X.TO',
  },
  {
    value: 'DXT.TO',
    label: 'DXT.TO,  Dexterra Group Inc.',
    name: 'Dexterra Group Inc.',
    symbol: 'DXT.TO',
  },
  {
    value: 'FFXDF',
    label: 'FFXDF,  Fairfax India Holdings Corporation',
    name: 'Fairfax India Holdings Corporation',
    symbol: 'FFXDF',
  },
  {
    value: 'MAGT.TO',
    label: 'MAGT.TO,  Magnet Forensics Inc.',
    name: 'Magnet Forensics Inc.',
    symbol: 'MAGT.TO',
  },
  {
    value: 'POOSF',
    label: 'POOSF,  Poseidon Concepts Corp.',
    name: 'Poseidon Concepts Corp.',
    symbol: 'POOSF',
  },
  {
    value: 'RCG.TO',
    label: 'RCG.TO,  RF Capital Group Inc.',
    name: 'RF Capital Group Inc.',
    symbol: 'RCG.TO',
  },
  {
    value: 'SLS.TO',
    label: 'SLS.TO,  Solaris Resources Inc.',
    name: 'Solaris Resources Inc.',
    symbol: 'SLS.TO',
  },
  {
    value: 'TSAT.TO',
    label: 'TSAT.TO,  Telesat Corporation',
    name: 'Telesat Corporation',
    symbol: 'TSAT.TO',
  },
  {
    value: 'VBNK.TO',
    label: 'VBNK.TO,  VersaBank',
    name: 'VersaBank',
    symbol: 'VBNK.TO',
  },
  {
    value: 'UHRN.SW',
    label: 'UHRN.SW,  The Swatch Group AG',
    name: 'The Swatch Group AG',
    symbol: 'UHRN.SW',
  },
  {
    value: 'AERO.SW',
    label: 'AERO.SW,  Montana Aerospace AG',
    name: 'Montana Aerospace AG',
    symbol: 'AERO.SW',
  },
  {
    value: 'PPGN.SW',
    label: 'PPGN.SW,  PolyPeptide Group AG',
    name: 'PolyPeptide Group AG',
    symbol: 'PPGN.SW',
  },
  {
    value: 'MEDX.SW',
    label: 'MEDX.SW,  medmix AG',
    name: 'medmix AG',
    symbol: 'MEDX.SW',
  },
  {
    value: 'LTM.SN',
    label: 'LTM.SN,  LATAM Airlines Group S.A.',
    name: 'LATAM Airlines Group S.A.',
    symbol: 'LTM.SN',
  },
  {
    value: 'SK.SN',
    label: 'SK.SN,  Sigdo Koppers S.A.',
    name: 'Sigdo Koppers S.A.',
    symbol: 'SK.SN',
  },
  {
    value: 'PAZ.SN',
    label: 'PAZ.SN,  Paz Corp S.A.',
    name: 'Paz Corp S.A.',
    symbol: 'PAZ.SN',
  },
  {
    value: 'T14.SI',
    label:
      'T14.SI,  Tianjin Pharmaceutical Da Ren Tang Group Corporation Limited',
    name: 'Tianjin Pharmaceutical Da Ren Tang Group Corporation Limited',
    symbol: 'T14.SI',
  },
  {
    value: '6821.HK',
    label: '6821.HK,  Asymchem Laboratories (Tianjin) Co., Ltd.',
    name: 'Asymchem Laboratories (Tianjin) Co., Ltd.',
    symbol: '6821.HK',
  },
  {
    value: 'MSA.TO',
    label: 'MSA.TO,  Mineros S.A.',
    name: 'Mineros S.A.',
    symbol: 'MSA.TO',
  },
  {
    value: 'CEZ.PR',
    label: 'CEZ.PR,  CEZ, a. s.',
    name: 'CEZ, a. s.',
    symbol: 'CEZ.PR',
  },
  {
    value: 'KOMB.PR',
    label: 'KOMB.PR,  Komercní banka, a.s.',
    name: 'Komercní banka, a.s.',
    symbol: 'KOMB.PR',
  },
  {
    value: 'HHX.HM',
    label: 'HHX.HM,  HAMMONIA Schiffsholding AG',
    name: 'HAMMONIA Schiffsholding AG',
    symbol: 'HHX.HM',
  },
  {
    value: '4BRZ.DE',
    label: '4BRZ.DE,  iShares MSCI Brazil UCITS ETF (DE)',
    name: 'iShares MSCI Brazil UCITS ETF (DE)',
    symbol: '4BRZ.DE',
  },
  {
    value: 'VH2.F',
    label: 'VH2.F,  Friedrich Vorwerk Group SE',
    name: 'Friedrich Vorwerk Group SE',
    symbol: 'VH2.F',
  },
  {
    value: 'VEZ.DE',
    label: 'VEZ.DE,  Veganz Group AG',
    name: 'Veganz Group AG',
    symbol: 'VEZ.DE',
  },
  {
    value: 'LUS1.F',
    label: 'LUS1.F,  Lang & Schwarz Aktiengesellschaft',
    name: 'Lang & Schwarz Aktiengesellschaft',
    symbol: 'LUS1.F',
  },
  {
    value: 'HH.CO',
    label: 'HH.CO,  H+H International A/S',
    name: 'H+H International A/S',
    symbol: 'HH.CO',
  },
  {
    value: 'ZEAL.CO',
    label: 'ZEAL.CO,  Zealand Pharma A/S',
    name: 'Zealand Pharma A/S',
    symbol: 'ZEAL.CO',
  },
  {
    value: 'DAB.CO',
    label: 'DAB.CO,  Danske Andelskassers Bank A/S',
    name: 'Danske Andelskassers Bank A/S',
    symbol: 'DAB.CO',
  },
  {
    value: 'STG.CO',
    label: 'STG.CO,  Scandinavian Tobacco Group A/S',
    name: 'Scandinavian Tobacco Group A/S',
    symbol: 'STG.CO',
  },
  {
    value: 'TIV.CO',
    label: 'TIV.CO,  Tivoli A/S',
    name: 'Tivoli A/S',
    symbol: 'TIV.CO',
  },
  {
    value: 'TCM.CO',
    label: 'TCM.CO,  TCM Group A/S',
    name: 'TCM Group A/S',
    symbol: 'TCM.CO',
  },
  {
    value: 'SPG.CO',
    label: 'SPG.CO,  SP Group A/S',
    name: 'SP Group A/S',
    symbol: 'SPG.CO',
  },
  {
    value: 'LRE.MC',
    label: 'LRE.MC,  Lar España Real Estate SOCIMI, S.A.',
    name: 'Lar España Real Estate SOCIMI, S.A.',
    symbol: 'LRE.MC',
  },
  {
    value: 'CASH.MC',
    label: 'CASH.MC,  Prosegur Cash, S.A.',
    name: 'Prosegur Cash, S.A.',
    symbol: 'CASH.MC',
  },
  {
    value: 'ARM.MC',
    label: 'ARM.MC,  Árima Real Estate SOCIMI, S.A.',
    name: 'Árima Real Estate SOCIMI, S.A.',
    symbol: 'ARM.MC',
  },
  {
    value: 'CIE.MC',
    label: 'CIE.MC,  CIE Automotive, S.A.',
    name: 'CIE Automotive, S.A.',
    symbol: 'CIE.MC',
  },
  {
    value: 'AMP.MC',
    label: 'AMP.MC,  Amper, S.A.',
    name: 'Amper, S.A.',
    symbol: 'AMP.MC',
  },
  {
    value: 'AZK.MC',
    label: 'AZK.MC,  Azkoyen, S.A.',
    name: 'Azkoyen, S.A.',
    symbol: 'AZK.MC',
  },
  {
    value: 'VOC.MC',
    label: 'VOC.MC,  Vocento, S.A.',
    name: 'Vocento, S.A.',
    symbol: 'VOC.MC',
  },
  {
    value: 'ALNT.MC',
    label: 'ALNT.MC,  Alantra Partners, S.A.',
    name: 'Alantra Partners, S.A.',
    symbol: 'ALNT.MC',
  },
  {
    value: 'DIA.MC',
    label: 'DIA.MC,  Distribuidora Internacional de Alimentación, S.A.',
    name: 'Distribuidora Internacional de Alimentación, S.A.',
    symbol: 'DIA.MC',
  },
  {
    value: 'ENO.MC',
    label: 'ENO.MC,  Elecnor, S.A.',
    name: 'Elecnor, S.A.',
    symbol: 'ENO.MC',
  },
  {
    value: 'ADX.MC',
    label: 'ADX.MC,  Audax Renovables, S.A.',
    name: 'Audax Renovables, S.A.',
    symbol: 'ADX.MC',
  },
  {
    value: 'FDR.MC',
    label: 'FDR.MC,  Fluidra, S.A.',
    name: 'Fluidra, S.A.',
    symbol: 'FDR.MC',
  },
  {
    value: 'OHLA.MC',
    label: 'OHLA.MC,  Obrascón Huarte Lain, S.A.',
    name: 'Obrascón Huarte Lain, S.A.',
    symbol: 'OHLA.MC',
  },
  {
    value: 'IBG.MC',
    label: 'IBG.MC,  Iberpapel Gestión, S.A.',
    name: 'Iberpapel Gestión, S.A.',
    symbol: 'IBG.MC',
  },
  {
    value: 'SLR.MC',
    label: 'SLR.MC,  Solaria Energía y Medio Ambiente, S.A.',
    name: 'Solaria Energía y Medio Ambiente, S.A.',
    symbol: 'SLR.MC',
  },
  {
    value: 'PRM.MC',
    label: 'PRM.MC,  Prim, S.A.',
    name: 'Prim, S.A.',
    symbol: 'PRM.MC',
  },
  {
    value: 'PRS.MC',
    label: 'PRS.MC,  Promotora de Informaciones, S.A.',
    name: 'Promotora de Informaciones, S.A.',
    symbol: 'PRS.MC',
  },
  {
    value: 'RLIA.MC',
    label: 'RLIA.MC,  Realia Business, S.A.',
    name: 'Realia Business, S.A.',
    symbol: 'RLIA.MC',
  },
  {
    value: 'GSJ.MC',
    label: 'GSJ.MC,  Grupo Empresarial San José, S.A.',
    name: 'Grupo Empresarial San José, S.A.',
    symbol: 'GSJ.MC',
  },
  {
    value: '0O7A.IL',
    label: '0O7A.IL,  Metsä Board Oyj',
    name: 'Metsä Board Oyj',
    symbol: '0O7A.IL',
  },
  {
    value: '1IG.F',
    label: '1IG.F,  St. Modwen Properties Limited',
    name: 'St. Modwen Properties Limited',
    symbol: '1IG.F',
  },
  {
    value: 'NMMCF',
    label: 'NMMCF,  NMC Health plc',
    name: 'NMC Health plc',
    symbol: 'NMMCF',
  },
  {
    value: 'ETE.AT',
    label: 'ETE.AT,  National Bank of Greece S.A.',
    name: 'National Bank of Greece S.A.',
    symbol: 'ETE.AT',
  },
  {
    value: 'TELL.AT',
    label: 'TELL.AT,  Bank of Greece',
    name: 'Bank of Greece',
    symbol: 'TELL.AT',
  },
  {
    value: 'KARE.AT',
    label: 'KARE.AT,  Karelia Tobacco Company Inc.',
    name: 'Karelia Tobacco Company Inc.',
    symbol: 'KARE.AT',
  },
  {
    value: 'SAR.AT',
    label: 'SAR.AT,  Gr. Sarantis S.A.',
    name: 'Gr. Sarantis S.A.',
    symbol: 'SAR.AT',
  },
  {
    value: 'HTO.AT',
    label: 'HTO.AT,  Hellenic Telecommunications Organization S.A.',
    name: 'Hellenic Telecommunications Organization S.A.',
    symbol: 'HTO.AT',
  },
  {
    value: 'ELPE.AT',
    label: 'ELPE.AT,  HELLENiQ ENERGY Holdings S.A.',
    name: 'HELLENiQ ENERGY Holdings S.A.',
    symbol: 'ELPE.AT',
  },
  {
    value: 'EXAE.AT',
    label: 'EXAE.AT,  Hellenic Exchanges - Athens Stock Exchange SA',
    name: 'Hellenic Exchanges - Athens Stock Exchange SA',
    symbol: 'EXAE.AT',
  },
  {
    value: 'OPAP.AT',
    label: 'OPAP.AT,  Organization of Football Prognostics S.A.',
    name: 'Organization of Football Prognostics S.A.',
    symbol: 'OPAP.AT',
  },
  {
    value: 'MOH.AT',
    label: 'MOH.AT,  Motor Oil (Hellas) Corinth Refineries S.A.',
    name: 'Motor Oil (Hellas) Corinth Refineries S.A.',
    symbol: 'MOH.AT',
  },
  {
    value: 'PPC.AT',
    label: 'PPC.AT,  Public Power Corporation S.A.',
    name: 'Public Power Corporation S.A.',
    symbol: 'PPC.AT',
  },
  {
    value: 'AEGN.AT',
    label: 'AEGN.AT,  Aegean Airlines S.A.',
    name: 'Aegean Airlines S.A.',
    symbol: 'AEGN.AT',
  },
  {
    value: '0816.HK',
    label: '0816.HK,  Jinmao Property Services Co., Limited',
    name: 'Jinmao Property Services Co., Limited',
    symbol: '0816.HK',
  },
  {
    value: 'OTP.BD',
    label: 'OTP.BD,  OTP Bank Nyrt.',
    name: 'OTP Bank Nyrt.',
    symbol: 'OTP.BD',
  },
  {
    value: 'OPUS.BD',
    label: 'OPUS.BD,  OPUS GLOBAL Nyrt.',
    name: 'OPUS GLOBAL Nyrt.',
    symbol: 'OPUS.BD',
  },
  {
    value: 'MOL.BD',
    label:
      'MOL.BD,  MOL Magyar Olaj- és Gázipari Nyilvánosan Muködo Részvénytársaság',
    name: 'MOL Magyar Olaj- és Gázipari Nyilvánosan Muködo Részvénytársaság',
    symbol: 'MOL.BD',
  },
  {
    value: '4IG.BD',
    label: '4IG.BD,  4iG Nyrt.',
    name: '4iG Nyrt.',
    symbol: '4IG.BD',
  },
  {
    value: 'AVIA.JK',
    label: 'AVIA.JK,  PT Avia Avian Tbk',
    name: 'PT Avia Avian Tbk',
    symbol: 'AVIA.JK',
  },
  {
    value: 'RYA.IR',
    label: 'RYA.IR,  Ryanair Holdings plc',
    name: 'Ryanair Holdings plc',
    symbol: 'RYA.IR',
  },
  {
    value: 'NDIA.L',
    label: 'NDIA.L,  iShares MSCI India UCITS ETF',
    name: 'iShares MSCI India UCITS ETF',
    symbol: 'NDIA.L',
  },
  {
    value: 'TTAM.TA',
    label: 'TTAM.TA,  Tiv Taam Holdings 1 Ltd.',
    name: 'Tiv Taam Holdings 1 Ltd.',
    symbol: 'TTAM.TA',
  },
  {
    value: 'NTO.TA',
    label: 'NTO.TA,  Neto M.E Holdings Ltd',
    name: 'Neto M.E Holdings Ltd',
    symbol: 'NTO.TA',
  },
  {
    value: 'SCOP.TA',
    label: 'SCOP.TA,  Scope Metals Group Ltd.',
    name: 'Scope Metals Group Ltd.',
    symbol: 'SCOP.TA',
  },
  {
    value: 'FBRT.TA',
    label: 'FBRT.TA,  FMS Enterprises Migun Ltd.',
    name: 'FMS Enterprises Migun Ltd.',
    symbol: 'FBRT.TA',
  },
  {
    value: 'TLSY.TA',
    label: 'TLSY.TA,  Telsys Ltd.',
    name: 'Telsys Ltd.',
    symbol: 'TLSY.TA',
  },
  {
    value: 'ELWS.TA',
    label: 'ELWS.TA,  Electreon Wireless Ltd',
    name: 'Electreon Wireless Ltd',
    symbol: 'ELWS.TA',
  },
  {
    value: 'AURA.TA',
    label: 'AURA.TA,  Aura Investments Ltd.',
    name: 'Aura Investments Ltd.',
    symbol: 'AURA.TA',
  },
  {
    value: 'DUNI.TA',
    label: 'DUNI.TA,  Duniec Bros. Ltd.',
    name: 'Duniec Bros. Ltd.',
    symbol: 'DUNI.TA',
  },
  {
    value: 'VILR.TA',
    label: 'VILR.TA,  Villar International Ltd.',
    name: 'Villar International Ltd.',
    symbol: 'VILR.TA',
  },
  {
    value: 'AFHL.TA',
    label: 'AFHL.TA,  Afcon Holdings Ltd',
    name: 'Afcon Holdings Ltd',
    symbol: 'AFHL.TA',
  },
  {
    value: 'KRUR.TA',
    label: 'KRUR.TA,  Kerur Holdings Ltd.',
    name: 'Kerur Holdings Ltd.',
    symbol: 'KRUR.TA',
  },
  {
    value: 'INFN.TA',
    label: 'INFN.TA,  Infinya Ltd',
    name: 'Infinya Ltd',
    symbol: 'INFN.TA',
  },
  {
    value: 'PLRM.TA',
    label: 'PLRM.TA,  Palram Industries (1990) Ltd',
    name: 'Palram Industries (1990) Ltd',
    symbol: 'PLRM.TA',
  },
  {
    value: 'PTBL.TA',
    label: 'PTBL.TA,  Property & Building Corp. Ltd.',
    name: 'Property & Building Corp. Ltd.',
    symbol: 'PTBL.TA',
  },
  {
    value: 'PHOE.TA',
    label: 'PHOE.TA,  The Phoenix Holdings Ltd.',
    name: 'The Phoenix Holdings Ltd.',
    symbol: 'PHOE.TA',
  },
  {
    value: 'RPAC.TA',
    label: 'RPAC.TA,  Rapac Communication & Infrastructure Ltd',
    name: 'Rapac Communication & Infrastructure Ltd',
    symbol: 'RPAC.TA',
  },
  {
    value: 'KLIL.TA',
    label: 'KLIL.TA,  Klil Industries Ltd',
    name: 'Klil Industries Ltd',
    symbol: 'KLIL.TA',
  },
  {
    value: 'ILX.TA',
    label: 'ILX.TA,  Ilex Medical Ltd',
    name: 'Ilex Medical Ltd',
    symbol: 'ILX.TA',
  },
  {
    value: 'ISTA.TA',
    label: 'ISTA.TA,  Issta Lines Ltd.',
    name: 'Issta Lines Ltd.',
    symbol: 'ISTA.TA',
  },
  {
    value: 'BSEN.TA',
    label: 'BSEN.TA,  Bet Shemesh Engines Holdings (1997) Ltd',
    name: 'Bet Shemesh Engines Holdings (1997) Ltd',
    symbol: 'BSEN.TA',
  },
  {
    value: 'PLSN.TA',
    label: 'PLSN.TA,  Plasson Industries Ltd',
    name: 'Plasson Industries Ltd',
    symbol: 'PLSN.TA',
  },
  {
    value: 'SNCM.TA',
    label: 'SNCM.TA,  Suny Cellular Communication Ltd',
    name: 'Suny Cellular Communication Ltd',
    symbol: 'SNCM.TA',
  },
  {
    value: 'ARD.TA',
    label: 'ARD.TA,  Arad Ltd.',
    name: 'Arad Ltd.',
    symbol: 'ARD.TA',
  },
  {
    value: 'DRAL.TA',
    label: 'DRAL.TA,  Dor Alon Energy In Israel (1988) Ltd',
    name: 'Dor Alon Energy In Israel (1988) Ltd',
    symbol: 'DRAL.TA',
  },
  {
    value: 'AFRE.TA',
    label: 'AFRE.TA,  Africa Israel Residences Ltd',
    name: 'Africa Israel Residences Ltd',
    symbol: 'AFRE.TA',
  },
  {
    value: 'AVGL.TA',
    label: 'AVGL.TA,  Avgol Industries 1953 Ltd',
    name: 'Avgol Industries 1953 Ltd',
    symbol: 'AVGL.TA',
  },
  {
    value: 'VTNA.TA',
    label: 'VTNA.TA,  Vitania Ltd.',
    name: 'Vitania Ltd.',
    symbol: 'VTNA.TA',
  },
  {
    value: 'MGRT.TA',
    label: 'MGRT.TA,  Megureit Israel Ltd',
    name: 'Megureit Israel Ltd',
    symbol: 'MGRT.TA',
  },
  {
    value: 'NVLG.TA',
    label: 'NVLG.TA,  Novolog (Pharm-Up 1966) Ltd',
    name: 'Novolog (Pharm-Up 1966) Ltd',
    symbol: 'NVLG.TA',
  },
  {
    value: 'MNRT.TA',
    label: 'MNRT.TA,  Menivim - The New Reit Ltd',
    name: 'Menivim - The New Reit Ltd',
    symbol: 'MNRT.TA',
  },
  {
    value: 'TMRP.TA',
    label: 'TMRP.TA,  Tamar Petroleum Ltd',
    name: 'Tamar Petroleum Ltd',
    symbol: 'TMRP.TA',
  },
  {
    value: 'FTAL.TA',
    label: 'FTAL.TA,  Fattal Holdings (1998) Ltd',
    name: 'Fattal Holdings (1998) Ltd',
    symbol: 'FTAL.TA',
  },
  {
    value: 'RANI.TA',
    label: 'RANI.TA,  Rani Zim Shopping Centers Ltd',
    name: 'Rani Zim Shopping Centers Ltd',
    symbol: 'RANI.TA',
  },
  {
    value: 'YHNF.TA',
    label: 'YHNF.TA,  M.Yochananof and Sons (1988) Ltd',
    name: 'M.Yochananof and Sons (1988) Ltd',
    symbol: 'YHNF.TA',
  },
  {
    value: 'AZRT.TA',
    label: 'AZRT.TA,  Reit Azorim - Living Ltd',
    name: 'Reit Azorim - Living Ltd',
    symbol: 'AZRT.TA',
  },
  {
    value: 'NYAX.TA',
    label: 'NYAX.TA,  Nayax Ltd.',
    name: 'Nayax Ltd.',
    symbol: 'NYAX.TA',
  },
  {
    value: 'RTLS.TA',
    label: 'RTLS.TA,  Retailors Ltd',
    name: 'Retailors Ltd',
    symbol: 'RTLS.TA',
  },
  {
    value: 'TRPZ.TA',
    label: 'TRPZ.TA,  Turpaz Industries Ltd',
    name: 'Turpaz Industries Ltd',
    symbol: 'TRPZ.TA',
  },
  {
    value: 'KSTN.TA',
    label: 'KSTN.TA,  Keystone REIT Ltd.',
    name: 'Keystone REIT Ltd.',
    symbol: 'KSTN.TA',
  },
  {
    value: 'VRDS.TA',
    label: 'VRDS.TA,  Veridis Environment Ltd',
    name: 'Veridis Environment Ltd',
    symbol: 'VRDS.TA',
  },
  {
    value: 'TRLT.TA',
    label: 'TRLT.TA,  Tera Light Ltd',
    name: 'Tera Light Ltd',
    symbol: 'TRLT.TA',
  },
  {
    value: 'FCYIX',
    label: 'FCYIX,  Fidelity Select Industrials',
    name: 'Fidelity Select Industrials',
    symbol: 'FCYIX',
  },
  {
    value: 'FNM.MI',
    label: 'FNM.MI,  FNM S.p.A.',
    name: 'FNM S.p.A.',
    symbol: 'FNM.MI',
  },
  {
    value: 'BPE.MI',
    label: 'BPE.MI,  BPER Banca SpA',
    name: 'BPER Banca SpA',
    symbol: 'BPE.MI',
  },
  {
    value: 'CIR.MI',
    label: 'CIR.MI,  CIR S.p.A. - Compagnie Industriali Riunite',
    name: 'CIR S.p.A. - Compagnie Industriali Riunite',
    symbol: 'CIR.MI',
  },
  {
    value: 'DANR.MI',
    label: 'DANR.MI,  Danieli & C. Officine Meccaniche S.p.A.',
    name: 'Danieli & C. Officine Meccaniche S.p.A.',
    symbol: 'DANR.MI',
  },
  {
    value: 'SO.MI',
    label: 'SO.MI,  Sogefi S.p.A.',
    name: 'Sogefi S.p.A.',
    symbol: 'SO.MI',
  },
  {
    value: 'JUVE.MI',
    label: 'JUVE.MI,  Juventus Football Club S.p.A.',
    name: 'Juventus Football Club S.p.A.',
    symbol: 'JUVE.MI',
  },
  {
    value: 'BPSO.MI',
    label: 'BPSO.MI,  Banca Popolare di Sondrio S.p.A',
    name: 'Banca Popolare di Sondrio S.p.A',
    symbol: 'BPSO.MI',
  },
  {
    value: 'BDB.MI',
    label: 'BDB.MI,  Banco di Desio e della Brianza S.p.A.',
    name: 'Banco di Desio e della Brianza S.p.A.',
    symbol: 'BDB.MI',
  },
  {
    value: 'SAB.MI',
    label: 'SAB.MI,  Sabaf S.p.A.',
    name: 'Sabaf S.p.A.',
    symbol: 'SAB.MI',
  },
  {
    value: 'DOV.MI',
    label: 'DOV.MI,  doValue S.p.A.',
    name: 'doValue S.p.A.',
    symbol: 'DOV.MI',
  },
  {
    value: 'PRO.MI',
    label: 'PRO.MI,  Banca Profilo S.p.A.',
    name: 'Banca Profilo S.p.A.',
    symbol: 'PRO.MI',
  },
  {
    value: 'SOL.MI',
    label: 'SOL.MI,  SOL S.p.A.',
    name: 'SOL S.p.A.',
    symbol: 'SOL.MI',
  },
  {
    value: 'EM.MI',
    label: 'EM.MI,  Emak S.p.A.',
    name: 'Emak S.p.A.',
    symbol: 'EM.MI',
  },
  {
    value: 'IMS.MI',
    label: 'IMS.MI,  Immsi S.p.A.',
    name: 'Immsi S.p.A.',
    symbol: 'IMS.MI',
  },
  {
    value: 'BEST.MI',
    label: 'BEST.MI,  Be Shaping The Future S.p.A.',
    name: 'Be Shaping The Future S.p.A.',
    symbol: 'BEST.MI',
  },
  {
    value: 'IRE.MI',
    label: 'IRE.MI,  Iren SpA',
    name: 'Iren SpA',
    symbol: 'IRE.MI',
  },
  {
    value: 'CE.MI',
    label: 'CE.MI,  Credito Emiliano S.p.A.',
    name: 'Credito Emiliano S.p.A.',
    symbol: 'CE.MI',
  },
  {
    value: 'PRI.MI',
    label: 'PRI.MI,  Prima Industrie SpA',
    name: 'Prima Industrie SpA',
    symbol: 'PRI.MI',
  },
  {
    value: 'AZM.MI',
    label: 'AZM.MI,  Azimut Holding S.p.A.',
    name: 'Azimut Holding S.p.A.',
    symbol: 'AZM.MI',
  },
  {
    value: 'MARR.MI',
    label: 'MARR.MI,  MARR S.p.A.',
    name: 'MARR S.p.A.',
    symbol: 'MARR.MI',
  },
  {
    value: 'TITR.MI',
    label: 'TITR.MI,  Telecom Italia S.p.A.',
    name: 'Telecom Italia S.p.A.',
    symbol: 'TITR.MI',
  },
  {
    value: 'SRI.MI',
    label: 'SRI.MI,  Servizi Italia S.p.A.',
    name: 'Servizi Italia S.p.A.',
    symbol: 'SRI.MI',
  },
  {
    value: 'ASC.MI',
    label: 'ASC.MI,  Ascopiave S.p.A.',
    name: 'Ascopiave S.p.A.',
    symbol: 'ASC.MI',
  },
  {
    value: 'ALW.MI',
    label: 'ALW.MI,  algoWatt S.p.A.',
    name: 'algoWatt S.p.A.',
    symbol: 'ALW.MI',
  },
  {
    value: 'TES.MI',
    label: 'TES.MI,  Tesmec S.p.A.',
    name: 'Tesmec S.p.A.',
    symbol: 'TES.MI',
  },
  {
    value: 'SFL.MI',
    label: 'SFL.MI,  Safilo Group S.p.A.',
    name: 'Safilo Group S.p.A.',
    symbol: 'SFL.MI',
  },
  {
    value: 'BC.MI',
    label: 'BC.MI,  Brunello Cucinelli S.p.A.',
    name: 'Brunello Cucinelli S.p.A.',
    symbol: 'BC.MI',
  },
  {
    value: 'UNI.MI',
    label: 'UNI.MI,  Unipol Gruppo S.p.A.',
    name: 'Unipol Gruppo S.p.A.',
    symbol: 'UNI.MI',
  },
  {
    value: 'US.MI',
    label: 'US.MI,  UnipolSai Assicurazioni S.p.A.',
    name: 'UnipolSai Assicurazioni S.p.A.',
    symbol: 'US.MI',
  },
  {
    value: 'MT.MI',
    label: 'MT.MI,  Maire Tecnimont S.p.A.',
    name: 'Maire Tecnimont S.p.A.',
    symbol: 'MT.MI',
  },
  {
    value: 'RCS.MI',
    label: 'RCS.MI,  RCS MediaGroup S.p.A.',
    name: 'RCS MediaGroup S.p.A.',
    symbol: 'RCS.MI',
  },
  {
    value: 'LUVE.MI',
    label: 'LUVE.MI,  LU-VE S.p.A.',
    name: 'LU-VE S.p.A.',
    symbol: 'LUVE.MI',
  },
  {
    value: 'AVIO.MI',
    label: 'AVIO.MI,  Avio S.p.A.',
    name: 'Avio S.p.A.',
    symbol: 'AVIO.MI',
  },
  {
    value: 'ORS.MI',
    label: 'ORS.MI,  Orsero S.p.A.',
    name: 'Orsero S.p.A.',
    symbol: 'ORS.MI',
  },
  {
    value: 'BRE.MI',
    label: 'BRE.MI,  Brembo S.p.A.',
    name: 'Brembo S.p.A.',
    symbol: 'BRE.MI',
  },
  {
    value: 'PHN.MI',
    label: 'PHN.MI,  Pharmanutra S.p.A.',
    name: 'Pharmanutra S.p.A.',
    symbol: 'PHN.MI',
  },
  {
    value: 'IGD.MI',
    label: 'IGD.MI,  Immobiliare Grande Distribuzione SIIQ S.p.A.',
    name: 'Immobiliare Grande Distribuzione SIIQ S.p.A.',
    symbol: 'IGD.MI',
  },
  {
    value: 'SCF.MI',
    label: 'SCF.MI,  Salcef Group S.p.A.',
    name: 'Salcef Group S.p.A.',
    symbol: 'SCF.MI',
  },
  {
    value: 'ELN.MI',
    label: 'ELN.MI,  EL.En. S.p.A.',
    name: 'EL.En. S.p.A.',
    symbol: 'ELN.MI',
  },
  {
    value: '3226.T',
    label: '3226.T,  Nippon Accommodations Fund Inc.',
    name: 'Nippon Accommodations Fund Inc.',
    symbol: '3226.T',
  },
  {
    value: '3234.T',
    label: '3234.T,  Mori Hills REIT Investment Corporation',
    name: 'Mori Hills REIT Investment Corporation',
    symbol: '3234.T',
  },
  {
    value: '3249.T',
    label: '3249.T,  Industrial & Infrastructure Fund Investment Corporation',
    name: 'Industrial & Infrastructure Fund Investment Corporation',
    symbol: '3249.T',
  },
  {
    value: '3278.T',
    label: '3278.T,  Kenedix Residential Next Investment Corporation',
    name: 'Kenedix Residential Next Investment Corporation',
    symbol: '3278.T',
  },
  {
    value: '3279.T',
    label: '3279.T,  Activia Properties Inc.',
    name: 'Activia Properties Inc.',
    symbol: '3279.T',
  },
  {
    value: '3282.T',
    label: '3282.T,  Comforia Residential REIT, Inc',
    name: 'Comforia Residential REIT, Inc',
    symbol: '3282.T',
  },
  {
    value: '3287.T',
    label: '3287.T,  Hoshino Resorts REIT, Inc.',
    name: 'Hoshino Resorts REIT, Inc.',
    symbol: '3287.T',
  },
  {
    value: '3290.T',
    label: '3290.T,  One REIT, Inc.',
    name: 'One REIT, Inc.',
    symbol: '3290.T',
  },
  {
    value: '3292.T',
    label: '3292.T,  AEON REIT Investment Corporation',
    name: 'AEON REIT Investment Corporation',
    symbol: '3292.T',
  },
  {
    value: '3295.T',
    label: '3295.T,  Hulic Reit, Inc.',
    name: 'Hulic Reit, Inc.',
    symbol: '3295.T',
  },
  {
    value: '3296.T',
    label: '3296.T,  NIPPON REIT Investment Corporation',
    name: 'NIPPON REIT Investment Corporation',
    symbol: '3296.T',
  },
  {
    value: '3309.T',
    label: '3309.T,  Sekisui House Reit, Inc.',
    name: 'Sekisui House Reit, Inc.',
    symbol: '3309.T',
  },
  {
    value: '3451.T',
    label: '3451.T,  Tosei Reit Investment Corporation',
    name: 'Tosei Reit Investment Corporation',
    symbol: '3451.T',
  },
  {
    value: '3453.T',
    label: '3453.T,  Kenedix Retail REIT Corporation',
    name: 'Kenedix Retail REIT Corporation',
    symbol: '3453.T',
  },
  {
    value: '3459.T',
    label: '3459.T,  Samty Residential Investment Corporation',
    name: 'Samty Residential Investment Corporation',
    symbol: '3459.T',
  },
  {
    value: '3463.T',
    label: '3463.T,  Ichigo Hotel REIT Investment Corporation',
    name: 'Ichigo Hotel REIT Investment Corporation',
    symbol: '3463.T',
  },
  {
    value: '3466.T',
    label: '3466.T,  LaSalle LOGIPORT REIT',
    name: 'LaSalle LOGIPORT REIT',
    symbol: '3466.T',
  },
  {
    value: '3468.T',
    label: '3468.T,  Star Asia Investment Corporation',
    name: 'Star Asia Investment Corporation',
    symbol: '3468.T',
  },
  {
    value: '3470.T',
    label: '3470.T,  Marimo Regional Revitalization REIT, Inc.',
    name: 'Marimo Regional Revitalization REIT, Inc.',
    symbol: '3470.T',
  },
  {
    value: '3471.T',
    label: '3471.T,  Mitsui Fudosan Logistics Park Inc.',
    name: 'Mitsui Fudosan Logistics Park Inc.',
    symbol: '3471.T',
  },
  {
    value: '3472.T',
    label: '3472.T,  Ooedo Onsen Reit Investment Corporation',
    name: 'Ooedo Onsen Reit Investment Corporation',
    symbol: '3472.T',
  },
  {
    value: '3476.T',
    label: '3476.T,  MIRAI Corporation',
    name: 'MIRAI Corporation',
    symbol: '3476.T',
  },
  {
    value: '3478.T',
    label: '3478.T,  MORI TRUST Hotel Reit, Inc.',
    name: 'MORI TRUST Hotel Reit, Inc.',
    symbol: '3478.T',
  },
  {
    value: '3481.T',
    label: '3481.T,  Mitsubishi Estate Logistics REIT Investment Corporation',
    name: 'Mitsubishi Estate Logistics REIT Investment Corporation',
    symbol: '3481.T',
  },
  {
    value: '3488.T',
    label: '3488.T,  XYMAX REIT Investment Corporation',
    name: 'XYMAX REIT Investment Corporation',
    symbol: '3488.T',
  },
  {
    value: '2971.T',
    label: '2971.T,  Escon Japan REIT Investment Corporation',
    name: 'Escon Japan REIT Investment Corporation',
    symbol: '2971.T',
  },
  {
    value: '2972.T',
    label: '2972.T,  SANKEI REAL ESTATE Inc.',
    name: 'SANKEI REAL ESTATE Inc.',
    symbol: '2972.T',
  },
  {
    value: '2989.T',
    label: '2989.T,  Tokaido REIT, Inc.',
    name: 'Tokaido REIT, Inc.',
    symbol: '2989.T',
  },
  {
    value: '3242.T',
    label: '3242.T,  Urbanet Corporation Co.,Ltd.',
    name: 'Urbanet Corporation Co.,Ltd.',
    symbol: '3242.T',
  },
  {
    value: '3758.T',
    label: '3758.T,  Aeria Inc.',
    name: 'Aeria Inc.',
    symbol: '3758.T',
  },
  {
    value: '6899.T',
    label: '6899.T,  Asti Corporation',
    name: 'Asti Corporation',
    symbol: '6899.T',
  },
  {
    value: '6030.T',
    label: '6030.T,  Adventure Inc.',
    name: 'Adventure Inc.',
    symbol: '6030.T',
  },
  {
    value: '6918.T',
    label: '6918.T,  Aval Data Corporation',
    name: 'Aval Data Corporation',
    symbol: '6918.T',
  },
  {
    value: '8889.T',
    label: '8889.T,  Apaman Co., Ltd.',
    name: 'Apaman Co., Ltd.',
    symbol: '8889.T',
  },
  {
    value: '7539.T',
    label: '7539.T,  AINAVO HOLDINGS Co.,Ltd.',
    name: 'AINAVO HOLDINGS Co.,Ltd.',
    symbol: '7539.T',
  },
  {
    value: '8925.T',
    label: '8925.T,  ARDEPRO Co., Ltd.',
    name: 'ARDEPRO Co., Ltd.',
    symbol: '8925.T',
  },
  {
    value: '5999.T',
    label: '5999.T,  Ihara Science Corporation',
    name: 'Ihara Science Corporation',
    symbol: '5999.T',
  },
  {
    value: '1407.T',
    label: '1407.T,  West Holdings Corporation',
    name: 'West Holdings Corporation',
    symbol: '1407.T',
  },
  {
    value: '2588.T',
    label: '2588.T,  Premium Water Holdings,Inc.',
    name: 'Premium Water Holdings,Inc.',
    symbol: '2588.T',
  },
  {
    value: '2769.T',
    label: '2769.T,  Village Vanguard CO.,LTD.',
    name: 'Village Vanguard CO.,LTD.',
    symbol: '2769.T',
  },
  {
    value: '4659.T',
    label: '4659.T,  AJIS Co., Ltd.',
    name: 'AJIS Co., Ltd.',
    symbol: '4659.T',
  },
  {
    value: '2805.T',
    label: '2805.T,  S&B Foods Inc.',
    name: 'S&B Foods Inc.',
    symbol: '2805.T',
  },
  {
    value: '4771.T',
    label: '4771.T,  F&M Co.,Ltd.',
    name: 'F&M Co.,Ltd.',
    symbol: '4771.T',
  },
  {
    value: '2763.T',
    label: '2763.T,  FTGroup Co., Ltd.',
    name: 'FTGroup Co., Ltd.',
    symbol: '2763.T',
  },
  {
    value: '7806.T',
    label: '7806.T,  MTG Co., Ltd.',
    name: 'MTG Co., Ltd.',
    symbol: '7806.T',
  },
  {
    value: '3417.T',
    label: '3417.T,  Ohki Healthcare Holdings Co.,Ltd.',
    name: 'Ohki Healthcare Holdings Co.,Ltd.',
    symbol: '3417.T',
  },
  {
    value: '9661.T',
    label: '9661.T,  Kabuki-Za Co., Ltd.',
    name: 'Kabuki-Za Co., Ltd.',
    symbol: '9661.T',
  },
  {
    value: '3528.T',
    label: '3528.T,  Mirainovate Co., Ltd.',
    name: 'Mirainovate Co., Ltd.',
    symbol: '3528.T',
  },
  {
    value: '4832.T',
    label: '4832.T,  JFE Systems, Inc.',
    name: 'JFE Systems, Inc.',
    symbol: '4832.T',
  },
  {
    value: '6881.T',
    label: '6881.T,  Kyoden Company, Limited',
    name: 'Kyoden Company, Limited',
    symbol: '6881.T',
  },
  {
    value: '3355.T',
    label: '3355.T,  Kuriyama Holdings Corporation',
    name: 'Kuriyama Holdings Corporation',
    symbol: '3355.T',
  },
  {
    value: '3420.T',
    label: '3420.T,  KFC, Ltd.',
    name: 'KFC, Ltd.',
    symbol: '3420.T',
  },
  {
    value: '7722.T',
    label: '7722.T,  Kokusai Co., Ltd.',
    name: 'Kokusai Co., Ltd.',
    symbol: '7722.T',
  },
  {
    value: '2780.T',
    label: '2780.T,  Komehyo Holdings Co.,Ltd.',
    name: 'Komehyo Holdings Co.,Ltd.',
    symbol: '2780.T',
  },
  {
    value: '5194.T',
    label: '5194.T,  Sagami Rubber Industries Co., Ltd.',
    name: 'Sagami Rubber Industries Co., Ltd.',
    symbol: '5194.T',
  },
  {
    value: '4761.T',
    label: '4761.T,  SAKURA KCS Corporation',
    name: 'SAKURA KCS Corporation',
    symbol: '4761.T',
  },
  {
    value: '9639.T',
    label: '9639.T,  Sankyo Frontier Co.,Ltd.',
    name: 'Sankyo Frontier Co.,Ltd.',
    symbol: '9639.T',
  },
  {
    value: '6882.T',
    label: '6882.T,  Sansha Electric Manufacturing Co.,Ltd.',
    name: 'Sansha Electric Manufacturing Co.,Ltd.',
    symbol: '6882.T',
  },
  {
    value: '6357.T',
    label: '6357.T,  Sansei Technologies, Inc.',
    name: 'Sansei Technologies, Inc.',
    symbol: '6357.T',
  },
  {
    value: '4837.T',
    label: '4837.T,  Shidax Corporation',
    name: 'Shidax Corporation',
    symbol: '4837.T',
  },
  {
    value: '6994.T',
    label: '6994.T,  Shizuki Electric Company Inc.',
    name: 'Shizuki Electric Company Inc.',
    symbol: '6994.T',
  },
  {
    value: '3537.T',
    label: '3537.T,  Shoei Yakuhin Co.,Ltd.',
    name: 'Shoei Yakuhin Co.,Ltd.',
    symbol: '3537.T',
  },
  {
    value: '6384.T',
    label: '6384.T,  Showa Shinku Co., Ltd.',
    name: 'Showa Shinku Co., Ltd.',
    symbol: '6384.T',
  },
  {
    value: '8893.T',
    label: '8893.T,  Shin-Nihon Tatemono Co.,Ltd.',
    name: 'Shin-Nihon Tatemono Co.,Ltd.',
    symbol: '8893.T',
  },
  {
    value: '5282.T',
    label: '5282.T,  GEOSTR Corporation',
    name: 'GEOSTR Corporation',
    symbol: '5282.T',
  },
  {
    value: '3914.T',
    label: '3914.T,  JIG-SAW INC.',
    name: 'JIG-SAW INC.',
    symbol: '3914.T',
  },
  {
    value: '4051.T',
    label: '4051.T,  GMO Financial Gate, Inc.',
    name: 'GMO Financial Gate, Inc.',
    symbol: '4051.T',
  },
  {
    value: '6279.T',
    label: '6279.T,  Zuiko Corporation',
    name: 'Zuiko Corporation',
    symbol: '6279.T',
  },
  {
    value: '6626.T',
    label: '6626.T,  SEMITEC Corporation',
    name: 'SEMITEC Corporation',
    symbol: '6626.T',
  },
  {
    value: '2782.T',
    label: '2782.T,  Seria Co., Ltd.',
    name: 'Seria Co., Ltd.',
    symbol: '2782.T',
  },
  {
    value: '8135.T',
    label: '8135.T,  Zett Corporation',
    name: 'Zett Corporation',
    symbol: '8135.T',
  },
  {
    value: '3733.T',
    label: '3733.T,  Software Service, Inc.',
    name: 'Software Service, Inc.',
    symbol: '3733.T',
  },
  {
    value: '4080.T',
    label: '4080.T,  Tanaka Chemical Corporation',
    name: 'Tanaka Chemical Corporation',
    symbol: '4080.T',
  },
  {
    value: '6023.T',
    label: '6023.T,  Daihatsu Diesel Mfg. Co., Ltd.',
    name: 'Daihatsu Diesel Mfg. Co., Ltd.',
    symbol: '6023.T',
  },
  {
    value: '3479.T',
    label: '3479.T,  TKP Corporation',
    name: 'TKP Corporation',
    symbol: '3479.T',
  },
  {
    value: '9708.T',
    label: '9708.T,  Imperial Hotel, Ltd.',
    name: 'Imperial Hotel, Ltd.',
    symbol: '9708.T',
  },
  {
    value: '6629.T',
    label: '6629.T,  Techno Horizon Co.,Ltd.',
    name: 'Techno Horizon Co.,Ltd.',
    symbol: '6629.T',
  },
  {
    value: '6637.T',
    label: '6637.T,  Terasaki Electric Co.,Ltd.',
    name: 'Terasaki Electric Co.,Ltd.',
    symbol: '6637.T',
  },
  {
    value: '6627.T',
    label: '6627.T,  Tera Probe, Inc.',
    name: 'Tera Probe, Inc.',
    symbol: '6627.T',
  },
  {
    value: '3628.T',
    label: '3628.T,  Data Horizon Corporation',
    name: 'Data Horizon Corporation',
    symbol: '3628.T',
  },
  {
    value: '9761.T',
    label: '9761.T,  Tokai Lease Co.,Ltd.',
    name: 'Tokai Lease Co.,Ltd.',
    symbol: '9761.T',
  },
  {
    value: '6643.T',
    label: '6643.T,  Togami Electric Mfg. Co., Ltd.',
    name: 'Togami Electric Mfg. Co., Ltd.',
    symbol: '6643.T',
  },
  {
    value: '8704.T',
    label: '8704.T,  Traders Holdings Co.,Ltd.',
    name: 'Traders Holdings Co.,Ltd.',
    symbol: '8704.T',
  },
  {
    value: '3793.T',
    label: '3793.T,  Drecom Co.,Ltd.',
    name: 'Drecom Co.,Ltd.',
    symbol: '3793.T',
  },
  {
    value: '6496.T',
    label: '6496.T,  Nakakita Seisakusho Co., Ltd.',
    name: 'Nakakita Seisakusho Co., Ltd.',
    symbol: '6496.T',
  },
  {
    value: '7435.T',
    label: '7435.T,  Nadex Co., Ltd.',
    name: 'Nadex Co., Ltd.',
    symbol: '7435.T',
  },
  {
    value: '2790.T',
    label: '2790.T,  NAFCO Co., Ltd.',
    name: 'NAFCO Co., Ltd.',
    symbol: '2790.T',
  },
  {
    value: '8085.T',
    label: '8085.T,  Narasaki Sangyo Co., Ltd.',
    name: 'Narasaki Sangyo Co., Ltd.',
    symbol: '8085.T',
  },
  {
    value: '5184.T',
    label: '5184.T,  Nichirin Co., Ltd.',
    name: 'Nichirin Co., Ltd.',
    symbol: '5184.T',
  },
  {
    value: '6493.T',
    label: '6493.T,  NITTAN Corporation',
    name: 'NITTAN Corporation',
    symbol: '6493.T',
  },
  {
    value: '7932.T',
    label: '7932.T,  Nippi,Incorporated',
    name: 'Nippi,Incorporated',
    symbol: '7932.T',
  },
  {
    value: '2114.T',
    label: '2114.T,  Fuji Nihon Seito Corporation',
    name: 'Fuji Nihon Seito Corporation',
    symbol: '2114.T',
  },
  {
    value: '6998.T',
    label: '6998.T,  Nippon Tungsten Co., Ltd.',
    name: 'Nippon Tungsten Co., Ltd.',
    symbol: '6998.T',
  },
  {
    value: '5759.T',
    label: '5759.T,  Nippon Denkai,Ltd.',
    name: 'Nippon Denkai,Ltd.',
    symbol: '5759.T',
  },
  {
    value: '9908.T',
    label: '9908.T,  Nihon Denkei Co.,Ltd.',
    name: 'Nihon Denkei Co.,Ltd.',
    symbol: '9908.T',
  },
  {
    value: '7061.T',
    label: '7061.T,  Japan Hospice Holdings Inc.',
    name: 'Japan Hospice Holdings Inc.',
    symbol: '7061.T',
  },
  {
    value: '2323.T',
    label: '2323.T,  fonfun corporation',
    name: 'fonfun corporation',
    symbol: '2323.T',
  },
  {
    value: '3541.T',
    label: '3541.T,  Nousouken Corporation',
    name: 'Nousouken Corporation',
    symbol: '3541.T',
  },
  {
    value: '5237.T',
    label: '5237.T,  Nozawa Corporation',
    name: 'Nozawa Corporation',
    symbol: '5237.T',
  },
  {
    value: '4925.T',
    label: '4925.T,  HABA Laboratories,Inc.',
    name: 'HABA Laboratories,Inc.',
    symbol: '4925.T',
  },
  {
    value: '7636.T',
    label: '7636.T,  Handsman Co., Ltd.',
    name: 'Handsman Co., Ltd.',
    symbol: '7636.T',
  },
  {
    value: '6960.T',
    label: '6960.T,  Fukuda Denshi Co., Ltd.',
    name: 'Fukuda Denshi Co., Ltd.',
    symbol: '6960.T',
  },
  {
    value: '5940.T',
    label: '5940.T,  Fujisash Co.,Ltd.',
    name: 'Fujisash Co.,Ltd.',
    symbol: '5940.T',
  },
  {
    value: '1775.T',
    label: '1775.T,  Fuji Furukawa Engineering & Construction Co.Ltd.',
    name: 'Fuji Furukawa Engineering & Construction Co.Ltd.',
    symbol: '1775.T',
  },
  {
    value: '5965.T',
    label: '5965.T,  Fujimak Corporation',
    name: 'Fujimak Corporation',
    symbol: '5965.T',
  },
  {
    value: '7254.T',
    label: '7254.T,  Univance Corporation',
    name: 'Univance Corporation',
    symbol: '7254.T',
  },
  {
    value: '7826.T',
    label: '7826.T,  Furuya Metal Co., Ltd.',
    name: 'Furuya Metal Co., Ltd.',
    symbol: '7826.T',
  },
  {
    value: '6312.T',
    label: '6312.T,  Freund Corporation',
    name: 'Freund Corporation',
    symbol: '6312.T',
  },
  {
    value: '2208.T',
    label: '2208.T,  Bourbon Corporation',
    name: 'Bourbon Corporation',
    symbol: '2208.T',
  },
  {
    value: '4934.T',
    label: '4934.T,  Premier Anti-Aging Co., Ltd.',
    name: 'Premier Anti-Aging Co., Ltd.',
    symbol: '4934.T',
  },
  {
    value: '7384.T',
    label: '7384.T,  Procrea Holdings, Inc.',
    name: 'Procrea Holdings, Inc.',
    symbol: '7384.T',
  },
  {
    value: '8046.T',
    label: '8046.T,  Marufuji Sheet Piling Co.,Ltd.',
    name: 'Marufuji Sheet Piling Co.,Ltd.',
    symbol: '8046.T',
  },
  {
    value: '4595.T',
    label: '4595.T,  Mizuho Medy Co.,Ltd.',
    name: 'Mizuho Medy Co.,Ltd.',
    symbol: '4595.T',
  },
  {
    value: '8066.T',
    label: '8066.T,  Mitani Corporation',
    name: 'Mitani Corporation',
    symbol: '8066.T',
  },
  {
    value: '3353.T',
    label: '3353.T,  Medical Ikkou Group Co.,Ltd.',
    name: 'Medical Ikkou Group Co.,Ltd.',
    symbol: '3353.T',
  },
  {
    value: '6067.T',
    label: '6067.T,  Impact HD Inc.',
    name: 'Impact HD Inc.',
    symbol: '6067.T',
  },
  {
    value: '2354.T',
    label: '2354.T,  YE DIGITAL Corporation',
    name: 'YE DIGITAL Corporation',
    symbol: '2354.T',
  },
  {
    value: '7298.T',
    label: '7298.T,  Yachiyo Industry Co., Ltd.',
    name: 'Yachiyo Industry Co., Ltd.',
    symbol: '7298.T',
  },
  {
    value: '7065.T',
    label: '7065.T,  UPR Corporation',
    name: 'UPR Corporation',
    symbol: '7065.T',
  },
  {
    value: '8006.T',
    label: '8006.T,  Yuasa Funashoku Co., Ltd.',
    name: 'Yuasa Funashoku Co., Ltd.',
    symbol: '8006.T',
  },
  {
    value: '8202.T',
    label: '8202.T,  Laox Holdings CO.,LTD.',
    name: 'Laox Holdings CO.,LTD.',
    symbol: '8202.T',
  },
  {
    value: '3482.T',
    label: '3482.T,  Loadstar Capital K.K.',
    name: 'Loadstar Capital K.K.',
    symbol: '3482.T',
  },
  {
    value: '4224.T',
    label: '4224.T,  Lonseal Corporation',
    name: 'Lonseal Corporation',
    symbol: '4224.T',
  },
  {
    value: '2813.T',
    label: '2813.T,  Wakou Shokuhin Co., Ltd.',
    name: 'Wakou Shokuhin Co., Ltd.',
    symbol: '2813.T',
  },
  {
    value: '0587.HK',
    label: '0587.HK,  China Conch Environment Protection Holdings Limited',
    name: 'China Conch Environment Protection Holdings Limited',
    symbol: '0587.HK',
  },
  {
    value: 'MV4.SI',
    label: 'MV4.SI,  Mewah International Inc.',
    name: 'Mewah International Inc.',
    symbol: 'MV4.SI',
  },
  {
    value: '1228.HK',
    label: '1228.HK,  CANbridge Pharmaceuticals Inc.',
    name: 'CANbridge Pharmaceuticals Inc.',
    symbol: '1228.HK',
  },
  {
    value: 'BAT.JO',
    label: 'BAT.JO,  Brait PLC',
    name: 'Brait PLC',
    symbol: 'BAT.JO',
  },
  {
    value: 'DIS.MI',
    label: "DIS.MI,  d'Amico International Shipping S.A.",
    name: "d'Amico International Shipping S.A.",
    symbol: 'DIS.MI',
  },
  {
    value: 'RNT.F',
    label: 'RNT.F,  Reinet Investments S.C.A.',
    name: 'Reinet Investments S.C.A.',
    symbol: 'RNT.F',
  },
  {
    value: 'IVS.MI',
    label: 'IVS.MI,  IVS Group S.A.',
    name: 'IVS Group S.A.',
    symbol: 'IVS.MI',
  },
  {
    value: 'ATCO-PD',
    label: 'ATCO-PD,  Atlas Corp.',
    name: 'Atlas Corp.',
    symbol: 'ATCO-PD',
  },
  {
    value: 'ATCO-PH',
    label: 'ATCO-PH,  Atlas Corp.',
    name: 'Atlas Corp.',
    symbol: 'ATCO-PH',
  },
  {
    value: 'GSL-PB',
    label: 'GSL-PB,  Global Ship Lease, Inc.',
    name: 'Global Ship Lease, Inc.',
    symbol: 'GSL-PB',
  },
  {
    value: 'LTE.JO',
    label: 'LTE.JO,  Lighthouse Properties plc',
    name: 'Lighthouse Properties plc',
    symbol: 'LTE.JO',
  },
  {
    value: 'AMXVF',
    label: 'AMXVF,  América Móvil, S.A.B. de C.V.',
    name: 'América Móvil, S.A.B. de C.V.',
    symbol: 'AMXVF',
  },
  {
    value: 'BCNV.TA',
    label: 'BCNV.TA,  Brack Capital Properties NV',
    name: 'Brack Capital Properties NV',
    symbol: 'BCNV.TA',
  },
  {
    value: 'SNH.JO',
    label: 'SNH.JO,  Steinhoff International Holdings N.V.',
    name: 'Steinhoff International Holdings N.V.',
    symbol: 'SNH.JO',
  },
  {
    value: 'ARGO.TA',
    label: 'ARGO.TA,  ARGO Properties N.V.',
    name: 'ARGO Properties N.V.',
    symbol: 'ARGO.TA',
  },
  {
    value: 'IVG.MI',
    label: 'IVG.MI,  Iveco Group N.V.',
    name: 'Iveco Group N.V.',
    symbol: 'IVG.MI',
  },
  {
    value: 'MFEA.MI',
    label: 'MFEA.MI,  MFE-Mediaforeurope N.V.',
    name: 'MFE-Mediaforeurope N.V.',
    symbol: 'MFEA.MI',
  },
  {
    value: 'MFEB.MI',
    label: 'MFEB.MI,  MFE-Mediaforeurope N.V.',
    name: 'MFE-Mediaforeurope N.V.',
    symbol: 'MFEB.MI',
  },
  {
    value: 'KMCP.OL',
    label: 'KMCP.OL,  KMC Properties ASA',
    name: 'KMC Properties ASA',
    symbol: 'KMCP.OL',
  },
  {
    value: 'ALNG.OL',
    label: 'ALNG.OL,  Awilco LNG ASA',
    name: 'Awilco LNG ASA',
    symbol: 'ALNG.OL',
  },
  {
    value: 'BGP.NZ',
    label: 'BGP.NZ,  Briscoe Group Limited',
    name: 'Briscoe Group Limited',
    symbol: 'BGP.NZ',
  },
  {
    value: 'CVT.NZ',
    label: 'CVT.NZ,  Comvita Limited',
    name: 'Comvita Limited',
    symbol: 'CVT.NZ',
  },
  {
    value: 'FRE.NZ',
    label: 'FRE.NZ,  Freightways Limited',
    name: 'Freightways Limited',
    symbol: 'FRE.NZ',
  },
  {
    value: 'NPH.NZ',
    label: 'NPH.NZ,  Napier Port Holdings Limited',
    name: 'Napier Port Holdings Limited',
    symbol: 'NPH.NZ',
  },
  {
    value: 'PEB.NZ',
    label: 'PEB.NZ,  Pacific Edge Limited',
    name: 'Pacific Edge Limited',
    symbol: 'PEB.NZ',
  },
  {
    value: 'PGW.NZ',
    label: 'PGW.NZ,  PGG Wrightson Limited',
    name: 'PGG Wrightson Limited',
    symbol: 'PGW.NZ',
  },
  {
    value: 'SCL.NZ',
    label: 'SCL.NZ,  Scales Corporation Limited',
    name: 'Scales Corporation Limited',
    symbol: 'SCL.NZ',
  },
  {
    value: 'SPG.NZ',
    label: 'SPG.NZ,  Stride Property Group',
    name: 'Stride Property Group',
    symbol: 'SPG.NZ',
  },
  {
    value: 'STU.NZ',
    label: 'STU.NZ,  Steel & Tube Holdings Limited',
    name: 'Steel & Tube Holdings Limited',
    symbol: 'STU.NZ',
  },
  {
    value: 'VCT.NZ',
    label: 'VCT.NZ,  Vector Limited',
    name: 'Vector Limited',
    symbol: 'VCT.NZ',
  },
  {
    value: 'WHS.NZ',
    label: 'WHS.NZ,  The Warehouse Group Limited',
    name: 'The Warehouse Group Limited',
    symbol: 'WHS.NZ',
  },
  {
    value: 'NSTR.TA',
    label: 'NSTR.TA,  Norstar Holdings Inc.',
    name: 'Norstar Holdings Inc.',
    symbol: 'NSTR.TA',
  },
  {
    value: 'COG.WA',
    label: 'COG.WA,  Cognor Holding S.A.',
    name: 'Cognor Holding S.A.',
    symbol: 'COG.WA',
  },
  {
    value: 'GPP.WA',
    label: 'GPP.WA,  Grupa Pracuj S.A.',
    name: 'Grupa Pracuj S.A.',
    symbol: 'GPP.WA',
  },
  {
    value: 'MRB.WA',
    label: 'MRB.WA,  Mirbud S.A.',
    name: 'Mirbud S.A.',
    symbol: 'MRB.WA',
  },
  {
    value: 'MCGS.QA',
    label: 'MCGS.QA,  Medicare Group Q.P.S.C.',
    name: 'Medicare Group Q.P.S.C.',
    symbol: 'MCGS.QA',
  },
  {
    value: 'AKHI.QA',
    label: 'AKHI.QA,  Al Khaleej Takaful Insurance Company Q.P.S.C.',
    name: 'Al Khaleej Takaful Insurance Company Q.P.S.C.',
    symbol: 'AKHI.QA',
  },
  {
    value: 'DHBK.QA',
    label: 'DHBK.QA,  Doha Bank Q.P.S.C.',
    name: 'Doha Bank Q.P.S.C.',
    symbol: 'DHBK.QA',
  },
  {
    value: 'ZHCD.QA',
    label: 'ZHCD.QA,  Zad Holding Company Q.P.S.C.',
    name: 'Zad Holding Company Q.P.S.C.',
    symbol: 'ZHCD.QA',
  },
  {
    value: 'QEWS.QA',
    label: 'QEWS.QA,  Qatar Electricity & Water Company Q.P.S.C.',
    name: 'Qatar Electricity & Water Company Q.P.S.C.',
    symbol: 'QEWS.QA',
  },
  {
    value: 'QATI.QA',
    label: 'QATI.QA,  Qatar Insurance Company Q.S.P.C.',
    name: 'Qatar Insurance Company Q.S.P.C.',
    symbol: 'QATI.QA',
  },
  {
    value: 'QIMD.QA',
    label: 'QIMD.QA,  Qatar Industrial Manufacturing Company Q.P.S.C.',
    name: 'Qatar Industrial Manufacturing Company Q.P.S.C.',
    symbol: 'QIMD.QA',
  },
  {
    value: 'QIBK.QA',
    label: 'QIBK.QA,  Qatar Islamic Bank (Q.P.S.C.)',
    name: 'Qatar Islamic Bank (Q.P.S.C.)',
    symbol: 'QIBK.QA',
  },
  {
    value: 'QIIK.QA',
    label: 'QIIK.QA,  Qatar International Islamic Bank (Q.P.S.C)',
    name: 'Qatar International Islamic Bank (Q.P.S.C)',
    symbol: 'QIIK.QA',
  },
  {
    value: 'QNBK.QA',
    label: 'QNBK.QA,  Qatar National Bank (Q.P.S.C.)',
    name: 'Qatar National Bank (Q.P.S.C.)',
    symbol: 'QNBK.QA',
  },
  {
    value: 'IGRD.QA',
    label: 'IGRD.QA,  Estithmar Holding Q.P.S.C.',
    name: 'Estithmar Holding Q.P.S.C.',
    symbol: 'IGRD.QA',
  },
  {
    value: 'QNCD.QA',
    label: 'QNCD.QA,  Qatar National Cement Company (Q.P.S.C.)',
    name: 'Qatar National Cement Company (Q.P.S.C.)',
    symbol: 'QNCD.QA',
  },
  {
    value: 'QNNS.QA',
    label: 'QNNS.QA,  Qatar Navigation Q.P.S.C.',
    name: 'Qatar Navigation Q.P.S.C.',
    symbol: 'QNNS.QA',
  },
  {
    value: 'SIIS.QA',
    label: 'SIIS.QA,  Salam International Investment Limited Q.P.S.C.',
    name: 'Salam International Investment Limited Q.P.S.C.',
    symbol: 'SIIS.QA',
  },
  {
    value: 'CBQK.QA',
    label: 'CBQK.QA,  The Commercial Bank (P.S.Q.C.)',
    name: 'The Commercial Bank (P.S.Q.C.)',
    symbol: 'CBQK.QA',
  },
  {
    value: 'DOHI.QA',
    label: 'DOHI.QA,  Doha Insurance Group Q.P.S.C.',
    name: 'Doha Insurance Group Q.P.S.C.',
    symbol: 'DOHI.QA',
  },
  {
    value: 'GWCS.QA',
    label: 'GWCS.QA,  Gulf Warehousing Company Q.P.S.C.',
    name: 'Gulf Warehousing Company Q.P.S.C.',
    symbol: 'GWCS.QA',
  },
  {
    value: 'IQCD.QA',
    label: 'IQCD.QA,  Industries Qatar Q.P.S.C.',
    name: 'Industries Qatar Q.P.S.C.',
    symbol: 'IQCD.QA',
  },
  {
    value: 'WDAM.QA',
    label: 'WDAM.QA,  Widam Food Company Q.P.S.C.',
    name: 'Widam Food Company Q.P.S.C.',
    symbol: 'WDAM.QA',
  },
  {
    value: 'QIGD.QA',
    label: 'QIGD.QA,  Qatari Investors Group Q.S.C.',
    name: 'Qatari Investors Group Q.S.C.',
    symbol: 'QIGD.QA',
  },
  {
    value: 'MCCS.QA',
    label: 'MCCS.QA,  Mannai Corporation Q.P.S.C.',
    name: 'Mannai Corporation Q.P.S.C.',
    symbol: 'MCCS.QA',
  },
  {
    value: 'ERES.QA',
    label: 'ERES.QA,  Ezdan Holding Group Q.P.S.C.',
    name: 'Ezdan Holding Group Q.P.S.C.',
    symbol: 'ERES.QA',
  },
  {
    value: 'GISS.QA',
    label: 'GISS.QA,  Gulf International Services Q.P.S.C.',
    name: 'Gulf International Services Q.P.S.C.',
    symbol: 'GISS.QA',
  },
  {
    value: 'MRDS.QA',
    label: 'MRDS.QA,  Mazaya Real Estate Development Q.P.S.C.',
    name: 'Mazaya Real Estate Development Q.P.S.C.',
    symbol: 'MRDS.QA',
  },
  {
    value: 'QFBQ.QA',
    label: 'QFBQ.QA,  Lesha Bank LLC',
    name: 'Lesha Bank LLC',
    symbol: 'QFBQ.QA',
  },
  {
    value: 'BLDN.QA',
    label: 'BLDN.QA,  Baladna Q.P.S.C.',
    name: 'Baladna Q.P.S.C.',
    symbol: 'BLDN.QA',
  },
  {
    value: 'IVSBF',
    label: 'IVSBF,  Investor AB (publ)',
    name: 'Investor AB (publ)',
    symbol: 'IVSBF',
  },
  {
    value: '0A0H.IL',
    label: '0A0H.IL,  Beijer Ref AB (publ)',
    name: 'Beijer Ref AB (publ)',
    symbol: '0A0H.IL',
  },
  {
    value: 'SFAB.ST',
    label: 'SFAB.ST,  Solid Försäkringsaktiebolag (publ)',
    name: 'Solid Försäkringsaktiebolag (publ)',
    symbol: 'SFAB.ST',
  },
  {
    value: 'CARE.ST',
    label: 'CARE.ST,  Careium AB (Publ)',
    name: 'Careium AB (Publ)',
    symbol: 'CARE.ST',
  },
  {
    value: 'V03.SI',
    label: 'V03.SI,  Venture Corporation Limited',
    name: 'Venture Corporation Limited',
    symbol: 'V03.SI',
  },
  {
    value: 'Q01.SI',
    label: 'Q01.SI,  QAF Limited',
    name: 'QAF Limited',
    symbol: 'Q01.SI',
  },
  {
    value: 'P52.SI',
    label: 'P52.SI,  Pan-United Corporation Ltd',
    name: 'Pan-United Corporation Ltd',
    symbol: 'P52.SI',
  },
  {
    value: 'UD1U.SI',
    label: 'UD1U.SI,  IREIT Global',
    name: 'IREIT Global',
    symbol: 'UD1U.SI',
  },
  {
    value: 'UD2.SI',
    label: 'UD2.SI,  Japfa Ltd.',
    name: 'Japfa Ltd.',
    symbol: 'UD2.SI',
  },
  {
    value: 'AGS.SI',
    label: 'AGS.SI,  The Hour Glass Limited',
    name: 'The Hour Glass Limited',
    symbol: 'AGS.SI',
  },
  {
    value: 'AUE.SI',
    label: 'AUE.SI,  Golden Energy and Resources Limited',
    name: 'Golden Energy and Resources Limited',
    symbol: 'AUE.SI',
  },
  {
    value: 'C07.SI',
    label: 'C07.SI,  Jardine Cycle & Carriage Limited',
    name: 'Jardine Cycle & Carriage Limited',
    symbol: 'C07.SI',
  },
  {
    value: 'BEC.SI',
    label: 'BEC.SI,  BRC Asia Limited',
    name: 'BRC Asia Limited',
    symbol: 'BEC.SI',
  },
  {
    value: 'BHK.SI',
    label: 'BHK.SI,  SIIC Environment Holdings Ltd.',
    name: 'SIIC Environment Holdings Ltd.',
    symbol: 'BHK.SI',
  },
  {
    value: 'BTOU.SI',
    label: 'BTOU.SI,  Manulife US Real Estate Investment Trust',
    name: 'Manulife US Real Estate Investment Trust',
    symbol: 'BTOU.SI',
  },
  {
    value: 'A26.SI',
    label: 'A26.SI,  Sinarmas Land Limited',
    name: 'Sinarmas Land Limited',
    symbol: 'A26.SI',
  },
  {
    value: 'CMOU.SI',
    label: 'CMOU.SI,  Keppel Pacific Oak US REIT',
    name: 'Keppel Pacific Oak US REIT',
    symbol: 'CMOU.SI',
  },
  {
    value: 'A34.SI',
    label: 'A34.SI,  Amara Holdings Limited',
    name: 'Amara Holdings Limited',
    symbol: 'A34.SI',
  },
  {
    value: 'S56.SI',
    label: 'S56.SI,  Samudera Shipping Line Ltd',
    name: 'Samudera Shipping Line Ltd',
    symbol: 'S56.SI',
  },
  {
    value: 'S61.SI',
    label: 'S61.SI,  SBS Transit Ltd',
    name: 'SBS Transit Ltd',
    symbol: 'S61.SI',
  },
  {
    value: 'M14.SI',
    label: 'M14.SI,  InnoTek Limited',
    name: 'InnoTek Limited',
    symbol: 'M14.SI',
  },
  {
    value: '500.SI',
    label: '500.SI,  Tai Sin Electric Limited',
    name: 'Tai Sin Electric Limited',
    symbol: '500.SI',
  },
  {
    value: 'A30.SI',
    label: 'A30.SI,  Aspial Corporation Limited',
    name: 'Aspial Corporation Limited',
    symbol: 'A30.SI',
  },
  {
    value: 'S51.SI',
    label: 'S51.SI,  Sembcorp Marine Ltd',
    name: 'Sembcorp Marine Ltd',
    symbol: 'S51.SI',
  },
  {
    value: 'F03.SI',
    label: 'F03.SI,  Food Empire Holdings Limited',
    name: 'Food Empire Holdings Limited',
    symbol: 'F03.SI',
  },
  {
    value: 'H07.SI',
    label: 'H07.SI,  Stamford Land Corporation Ltd',
    name: 'Stamford Land Corporation Ltd',
    symbol: 'H07.SI',
  },
  {
    value: 'S58.SI',
    label: 'S58.SI,  SATS Ltd.',
    name: 'SATS Ltd.',
    symbol: 'S58.SI',
  },
  {
    value: 'S59.SI',
    label: 'S59.SI,  SIA Engineering Company Limited',
    name: 'SIA Engineering Company Limited',
    symbol: 'S59.SI',
  },
  {
    value: 'G07.SI',
    label: 'G07.SI,  Great Eastern Holdings Limited',
    name: 'Great Eastern Holdings Limited',
    symbol: 'G07.SI',
  },
  {
    value: 'H30.SI',
    label: 'H30.SI,  Hong Fok Corporation Limited',
    name: 'Hong Fok Corporation Limited',
    symbol: 'H30.SI',
  },
  {
    value: 'U10.SI',
    label: 'U10.SI,  UOB-Kay Hian Holdings Limited',
    name: 'UOB-Kay Hian Holdings Limited',
    symbol: 'U10.SI',
  },
  {
    value: 'H18.SI',
    label: 'H18.SI,  Hotel Grand Central Limited',
    name: 'Hotel Grand Central Limited',
    symbol: 'H18.SI',
  },
  {
    value: 'HYFXF',
    label: 'HYFXF,  Hyflux Ltd',
    name: 'Hyflux Ltd',
    symbol: 'HYFXF',
  },
  {
    value: 'U06.SI',
    label: 'U06.SI,  Singapore Land Group Limited',
    name: 'Singapore Land Group Limited',
    symbol: 'U06.SI',
  },
  {
    value: 'U09.SI',
    label: 'U09.SI,  Avarga Limited',
    name: 'Avarga Limited',
    symbol: 'U09.SI',
  },
  {
    value: 'W05.SI',
    label: 'W05.SI,  Wing Tai Holdings Limited',
    name: 'Wing Tai Holdings Limited',
    symbol: 'W05.SI',
  },
  {
    value: 'S41.SI',
    label: 'S41.SI,  Hong Leong Finance Limited',
    name: 'Hong Leong Finance Limited',
    symbol: 'S41.SI',
  },
  {
    value: '579.SI',
    label: '579.SI,  Oceanus Group Limited',
    name: 'Oceanus Group Limited',
    symbol: '579.SI',
  },
  {
    value: 'S08.SI',
    label: 'S08.SI,  Singapore Post Limited',
    name: 'Singapore Post Limited',
    symbol: 'S08.SI',
  },
  {
    value: '5DD.SI',
    label: '5DD.SI,  Micro-Mechanics (Holdings) Ltd.',
    name: 'Micro-Mechanics (Holdings) Ltd.',
    symbol: '5DD.SI',
  },
  {
    value: 'S29.SI',
    label: 'S29.SI,  Stamford Tyres Corporation Limited',
    name: 'Stamford Tyres Corporation Limited',
    symbol: 'S29.SI',
  },
  {
    value: 'P34.SI',
    label: 'P34.SI,  Delfi Limited',
    name: 'Delfi Limited',
    symbol: 'P34.SI',
  },
  {
    value: 'T82U.SI',
    label: 'T82U.SI,  Suntec Real Estate Investment Trust',
    name: 'Suntec Real Estate Investment Trust',
    symbol: 'T82U.SI',
  },
  {
    value: 'E28.SI',
    label: 'E28.SI,  Frencken Group Limited',
    name: 'Frencken Group Limited',
    symbol: 'E28.SI',
  },
  {
    value: 'U96.SI',
    label: 'U96.SI,  Sembcorp Industries Ltd',
    name: 'Sembcorp Industries Ltd',
    symbol: 'U96.SI',
  },
  {
    value: 'F17.SI',
    label: 'F17.SI,  GuocoLand Limited',
    name: 'GuocoLand Limited',
    symbol: 'F17.SI',
  },
  {
    value: 'U14.SI',
    label: 'U14.SI,  UOL Group Limited',
    name: 'UOL Group Limited',
    symbol: 'U14.SI',
  },
  {
    value: '5IC.SI',
    label: '5IC.SI,  Sing Holdings Limited',
    name: 'Sing Holdings Limited',
    symbol: '5IC.SI',
  },
  {
    value: '5IG.SI',
    label: '5IG.SI,  Gallant Venture Ltd.',
    name: 'Gallant Venture Ltd.',
    symbol: '5IG.SI',
  },
  {
    value: 'B58.SI',
    label: 'B58.SI,  Banyan Tree Holdings Limited',
    name: 'Banyan Tree Holdings Limited',
    symbol: 'B58.SI',
  },
  {
    value: 'Z25.SI',
    label: 'Z25.SI,  Yanlord Land Group Limited',
    name: 'Yanlord Land Group Limited',
    symbol: 'Z25.SI',
  },
  {
    value: 'Z59.SI',
    label: 'Z59.SI,  Yoma Strategic Holdings Ltd.',
    name: 'Yoma Strategic Holdings Ltd.',
    symbol: 'Z59.SI',
  },
  {
    value: 'B61.SI',
    label: 'B61.SI,  Bukit Sembawang Estates Limited',
    name: 'Bukit Sembawang Estates Limited',
    symbol: 'B61.SI',
  },
  {
    value: 'AP4.SI',
    label: 'AP4.SI,  Riverstone Holdings Limited',
    name: 'Riverstone Holdings Limited',
    symbol: 'AP4.SI',
  },
  {
    value: 'AW9U.SI',
    label: 'AW9U.SI,  First Real Estate Investment Trust',
    name: 'First Real Estate Investment Trust',
    symbol: 'AW9U.SI',
  },
  {
    value: '5JK.SI',
    label: '5JK.SI,  Hiap Hoe Limited',
    name: 'Hiap Hoe Limited',
    symbol: '5JK.SI',
  },
  {
    value: '5JS.SI',
    label: '5JS.SI,  Indofood Agri Resources Ltd.',
    name: 'Indofood Agri Resources Ltd.',
    symbol: '5JS.SI',
  },
  {
    value: 'C8R.SI',
    label: 'C8R.SI,  Jiutian Chemical Group Limited',
    name: 'Jiutian Chemical Group Limited',
    symbol: 'C8R.SI',
  },
  {
    value: 'ER0.SI',
    label: 'ER0.SI,  KSH Holdings Limited',
    name: 'KSH Holdings Limited',
    symbol: 'ER0.SI',
  },
  {
    value: 'E3B.SI',
    label: 'E3B.SI,  Wee Hur Holdings Ltd.',
    name: 'Wee Hur Holdings Ltd.',
    symbol: 'E3B.SI',
  },
  {
    value: 'SGXXF',
    label: 'SGXXF,  Sound Global Ltd.',
    name: 'Sound Global Ltd.',
    symbol: 'SGXXF',
  },
  {
    value: 'F1E.SI',
    label: 'F1E.SI,  Low Keng Huat (Singapore) Limited',
    name: 'Low Keng Huat (Singapore) Limited',
    symbol: 'F1E.SI',
  },
  {
    value: 'F9D.SI',
    label: 'F9D.SI,  Boustead Singapore Limited',
    name: 'Boustead Singapore Limited',
    symbol: 'F9D.SI',
  },
  {
    value: 'NR7.SI',
    label: 'NR7.SI,  Raffles Education Limited',
    name: 'Raffles Education Limited',
    symbol: 'NR7.SI',
  },
  {
    value: 'NS8U.SI',
    label: 'NS8U.SI,  Hutchison Port Holdings Trust',
    name: 'Hutchison Port Holdings Trust',
    symbol: 'NS8U.SI',
  },
  {
    value: 'T24.SI',
    label: 'T24.SI,  Tuan Sing Holdings Limited',
    name: 'Tuan Sing Holdings Limited',
    symbol: 'T24.SI',
  },
  {
    value: 'P8Z.SI',
    label: 'P8Z.SI,  Bumitama Agri Ltd.',
    name: 'Bumitama Agri Ltd.',
    symbol: 'P8Z.SI',
  },
  {
    value: 'P9D.SI',
    label: 'P9D.SI,  Civmec Limited',
    name: 'Civmec Limited',
    symbol: 'P9D.SI',
  },
  {
    value: 'QC7.SI',
    label: 'QC7.SI,  Q & M Dental Group (Singapore) Limited',
    name: 'Q & M Dental Group (Singapore) Limited',
    symbol: 'QC7.SI',
  },
  {
    value: 'RE4.SI',
    label: 'RE4.SI,  Geo Energy Resources Limited',
    name: 'Geo Energy Resources Limited',
    symbol: 'RE4.SI',
  },
  {
    value: '5UX.SI',
    label: '5UX.SI,  Oxley Holdings Limited',
    name: 'Oxley Holdings Limited',
    symbol: '5UX.SI',
  },
  {
    value: '5VJ.SI',
    label: '5VJ.SI,  Halcyon Agri Corporation Limited',
    name: 'Halcyon Agri Corporation Limited',
    symbol: '5VJ.SI',
  },
  {
    value: 'SK6U.SI',
    label: 'SK6U.SI,  SPH REIT',
    name: 'SPH REIT',
    symbol: 'SK6U.SI',
  },
  {
    value: 'TQ5.SI',
    label: 'TQ5.SI,  Frasers Property Limited',
    name: 'Frasers Property Limited',
    symbol: 'TQ5.SI',
  },
  {
    value: 'TS0U.SI',
    label: 'TS0U.SI,  OUE Commercial Real Estate Investment Trust',
    name: 'OUE Commercial Real Estate Investment Trust',
    symbol: 'TS0U.SI',
  },
  {
    value: 'O10.SI',
    label: 'O10.SI,  Far East Orchard Limited',
    name: 'Far East Orchard Limited',
    symbol: 'O10.SI',
  },
  {
    value: 'B28.SI',
    label: 'B28.SI,  Bonvests Holdings Limited',
    name: 'Bonvests Holdings Limited',
    symbol: 'B28.SI',
  },
  {
    value: 'CWBU.SI',
    label: 'CWBU.SI,  Cromwell European Real Estate Investment Trust',
    name: 'Cromwell European Real Estate Investment Trust',
    symbol: 'CWBU.SI',
  },
  {
    value: 'DCRU.SI',
    label: 'DCRU.SI,  Digital Core REIT',
    name: 'Digital Core REIT',
    symbol: 'DCRU.SI',
  },
  {
    value: 'OXMU.SI',
    label: 'OXMU.SI,  Prime US REIT',
    name: 'Prime US REIT',
    symbol: 'OXMU.SI',
  },
  {
    value: '8K7.SI',
    label: '8K7.SI,  UG Healthcare Corporation Limited',
    name: 'UG Healthcare Corporation Limited',
    symbol: '8K7.SI',
  },
  {
    value: 'QES.SI',
    label: 'QES.SI,  China Sunsine Chemical Holdings Ltd.',
    name: 'China Sunsine Chemical Holdings Ltd.',
    symbol: 'QES.SI',
  },
  {
    value: '8AZ.SI',
    label: '8AZ.SI,  Aztech Global Ltd.',
    name: 'Aztech Global Ltd.',
    symbol: '8AZ.SI',
  },
  {
    value: 'XCF.SI',
    label: 'XCF.SI,  KTMG Limited',
    name: 'KTMG Limited',
    symbol: 'XCF.SI',
  },
  {
    value: 'WJP.SI',
    label: 'WJP.SI,  VICOM Ltd',
    name: 'VICOM Ltd',
    symbol: 'WJP.SI',
  },
  {
    value: 'BBL.BK',
    label: 'BBL.BK,  Bangkok Bank Public Company Limited',
    name: 'Bangkok Bank Public Company Limited',
    symbol: 'BBL.BK',
  },
  {
    value: 'BKKPF',
    label: 'BKKPF,  Bangkok Bank Public Company Limited',
    name: 'Bangkok Bank Public Company Limited',
    symbol: 'BKKPF',
  },
  {
    value: 'BJC.BK',
    label: 'BJC.BK,  Berli Jucker Public Company Limited',
    name: 'Berli Jucker Public Company Limited',
    symbol: 'BJC.BK',
  },
  {
    value: 'NVP8.SG',
    label: 'NVP8.SG,  Berli Jucker Public Company Limited',
    name: 'Berli Jucker Public Company Limited',
    symbol: 'NVP8.SG',
  },
  {
    value: 'SCC.BK',
    label: 'SCC.BK,  The Siam Cement Public Company Limited',
    name: 'The Siam Cement Public Company Limited',
    symbol: 'SCC.BK',
  },
  {
    value: 'TCM1.F',
    label: 'TCM1.F,  The Siam Cement Public Company Limited',
    name: 'The Siam Cement Public Company Limited',
    symbol: 'TCM1.F',
  },
  {
    value: 'SCB.BK',
    label: 'SCB.BK,  SCB X Public Company Limited',
    name: 'SCB X Public Company Limited',
    symbol: 'SCB.BK',
  },
  {
    value: 'SCCC.BK',
    label: 'SCCC.BK,  Siam City Cement Public Company Limited',
    name: 'Siam City Cement Public Company Limited',
    symbol: 'SCCC.BK',
  },
  {
    value: 'BKI.BK',
    label: 'BKI.BK,  Bangkok Insurance Public Company Limited',
    name: 'Bangkok Insurance Public Company Limited',
    symbol: 'BKI.BK',
  },
  {
    value: 'TTB.BK',
    label: 'TTB.BK,  TMBThanachart Bank Public Company Limited',
    name: 'TMBThanachart Bank Public Company Limited',
    symbol: 'TTB.BK',
  },
  {
    value: 'TMLF.F',
    label: 'TMLF.F,  TMBThanachart Bank Public Company Limited',
    name: 'TMBThanachart Bank Public Company Limited',
    symbol: 'TMLF.F',
  },
  {
    value: 'TCAP.BK',
    label: 'TCAP.BK,  Thanachart Capital Public Company Limited',
    name: 'Thanachart Capital Public Company Limited',
    symbol: 'TCAP.BK',
  },
  {
    value: 'NFPH.F',
    label: 'NFPH.F,  Thanachart Capital Public Company Limited',
    name: 'Thanachart Capital Public Company Limited',
    symbol: 'NFPH.F',
  },
  {
    value: 'AYUD.BK',
    label: 'AYUD.BK,  Allianz Ayudhya Capital Public Company Limited',
    name: 'Allianz Ayudhya Capital Public Company Limited',
    symbol: 'AYUD.BK',
  },
  {
    value: '5Y7.F',
    label: '5Y7.F,  SCG Packaging Public Company Limited',
    name: 'SCG Packaging Public Company Limited',
    symbol: '5Y7.F',
  },
  {
    value: '5Y7A.F',
    label: '5Y7A.F,  SCG Packaging Public Company Limited',
    name: 'SCG Packaging Public Company Limited',
    symbol: '5Y7A.F',
  },
  {
    value: 'CPF.BK',
    label: 'CPF.BK,  Charoen Pokphand Foods Public Company Limited',
    name: 'Charoen Pokphand Foods Public Company Limited',
    symbol: 'CPF.BK',
  },
  {
    value: 'CPOF.F',
    label: 'CPOF.F,  Charoen Pokphand Foods Public Company Limited',
    name: 'Charoen Pokphand Foods Public Company Limited',
    symbol: 'CPOF.F',
  },
  {
    value: 'SKR.BK',
    label: 'SKR.BK,  Sikarin Public Company Limited',
    name: 'Sikarin Public Company Limited',
    symbol: 'SKR.BK',
  },
  {
    value: 'KKP.BK',
    label: 'KKP.BK,  Kiatnakin Phatra Bank Public Company Limited',
    name: 'Kiatnakin Phatra Bank Public Company Limited',
    symbol: 'KKP.BK',
  },
  {
    value: 'KCE.BK',
    label: 'KCE.BK,  KCE Electronics Public Company Limited',
    name: 'KCE Electronics Public Company Limited',
    symbol: 'KCE.BK',
  },
  {
    value: 'KCEA.SG',
    label: 'KCEA.SG,  KCE Electronics Public Company Limited',
    name: 'KCE Electronics Public Company Limited',
    symbol: 'KCEA.SG',
  },
  {
    value: 'MINT.BK',
    label: 'MINT.BK,  Minor International Public Company Limited',
    name: 'Minor International Public Company Limited',
    symbol: 'MINT.BK',
  },
  {
    value: 'UV-R.BK',
    label: 'UV-R.BK,  Univentures Public Company Limited',
    name: 'Univentures Public Company Limited',
    symbol: 'UV-R.BK',
  },
  {
    value: 'UV.BK',
    label: 'UV.BK,  Univentures Public Company Limited',
    name: 'Univentures Public Company Limited',
    symbol: 'UV.BK',
  },
  {
    value: 'LH.BK',
    label: 'LH.BK,  Land and Houses Public Company Limited',
    name: 'Land and Houses Public Company Limited',
    symbol: 'LH.BK',
  },
  {
    value: 'LHOG.F',
    label: 'LHOG.F,  Land and Houses Public Company Limited',
    name: 'Land and Houses Public Company Limited',
    symbol: 'LHOG.F',
  },
  {
    value: 'BPFG.F',
    label: 'BPFG.F,  Banpu Public Company Limited',
    name: 'Banpu Public Company Limited',
    symbol: 'BPFG.F',
  },
  {
    value: 'KTB.BK',
    label: 'KTB.BK,  Krung Thai Bank Public Company Limited',
    name: 'Krung Thai Bank Public Company Limited',
    symbol: 'KTB.BK',
  },
  {
    value: 'KTB1.F',
    label: 'KTB1.F,  Krung Thai Bank Public Company Limited',
    name: 'Krung Thai Bank Public Company Limited',
    symbol: 'KTB1.F',
  },
  {
    value: 'BH.BK',
    label: 'BH.BK,  Bumrungrad Hospital Public Company Limited',
    name: 'Bumrungrad Hospital Public Company Limited',
    symbol: 'BH.BK',
  },
  {
    value: 'BZ7A.F',
    label: 'BZ7A.F,  Bumrungrad Hospital Public Company Limited',
    name: 'Bumrungrad Hospital Public Company Limited',
    symbol: 'BZ7A.F',
  },
  {
    value: 'KGI.BK',
    label: 'KGI.BK,  KGI Securities (Thailand) Public Company Limited',
    name: 'KGI Securities (Thailand) Public Company Limited',
    symbol: 'KGI.BK',
  },
  {
    value: 'SWTF.F',
    label: 'SWTF.F,  Intouch Holdings Public Company Limited',
    name: 'Intouch Holdings Public Company Limited',
    symbol: 'SWTF.F',
  },
  {
    value: 'TVO.BK',
    label: 'TVO.BK,  Thai Vegetable Oil Public Company Limited',
    name: 'Thai Vegetable Oil Public Company Limited',
    symbol: 'TVO.BK',
  },
  {
    value: 'AJ-R.BK',
    label: 'AJ-R.BK,  A.J. Plast Public Company Limited',
    name: 'A.J. Plast Public Company Limited',
    symbol: 'AJ-R.BK',
  },
  {
    value: 'AJ.BK',
    label: 'AJ.BK,  A.J. Plast Public Company Limited',
    name: 'A.J. Plast Public Company Limited',
    symbol: 'AJ.BK',
  },
  {
    value: 'BTS.BK',
    label: 'BTS.BK,  BTS Group Holdings Public Company Limited',
    name: 'BTS Group Holdings Public Company Limited',
    symbol: 'BTS.BK',
  },
  {
    value: 'STA.BK',
    label: 'STA.BK,  Sri Trang Agro-Industry Public Company Limited',
    name: 'Sri Trang Agro-Industry Public Company Limited',
    symbol: 'STA.BK',
  },
  {
    value: 'QH.BK',
    label: 'QH.BK,  Quality Houses Public Company Limited',
    name: 'Quality Houses Public Company Limited',
    symbol: 'QH.BK',
  },
  {
    value: 'BDMS.BK',
    label: 'BDMS.BK,  Bangkok Dusit Medical Services Public Company Limited',
    name: 'Bangkok Dusit Medical Services Public Company Limited',
    symbol: 'BDMS.BK',
  },
  {
    value: 'BDULF',
    label: 'BDULF,  Bangkok Dusit Medical Services Public Company Limited',
    name: 'Bangkok Dusit Medical Services Public Company Limited',
    symbol: 'BDULF',
  },
  {
    value: 'AISF.F',
    label: 'AISF.F,  Advanced Info Service Public Company Limited',
    name: 'Advanced Info Service Public Company Limited',
    symbol: 'AISF.F',
  },
  {
    value: 'DCC.BK',
    label: 'DCC.BK,  Dynasty Ceramic Public Company Limited',
    name: 'Dynasty Ceramic Public Company Limited',
    symbol: 'DCC.BK',
  },
  {
    value: 'GFPT.BK',
    label: 'GFPT.BK,  GFPT Public Company Limited',
    name: 'GFPT Public Company Limited',
    symbol: 'GFPT.BK',
  },
  {
    value: 'STEC.BK',
    label:
      'STEC.BK,  Sino-Thai Engineering and Construction Public Company Limited',
    name: 'Sino-Thai Engineering and Construction Public Company Limited',
    symbol: 'STEC.BK',
  },
  {
    value: 'AP-R.BK',
    label: 'AP-R.BK,  AP (Thailand) Public Company Limited',
    name: 'AP (Thailand) Public Company Limited',
    symbol: 'AP-R.BK',
  },
  {
    value: 'HAA1.F',
    label: 'HAA1.F,  Hana Microelectronics Public Company Limited',
    name: 'Hana Microelectronics Public Company Limited',
    symbol: 'HAA1.F',
  },
  {
    value: 'U-R.BK',
    label: 'U-R.BK,  U City Public Company Limited',
    name: 'U City Public Company Limited',
    symbol: 'U-R.BK',
  },
  {
    value: 'U.BK',
    label: 'U.BK,  U City Public Company Limited',
    name: 'U City Public Company Limited',
    symbol: 'U.BK',
  },
  {
    value: 'PF-R.BK',
    label: 'PF-R.BK,  Property Perfect Public Company Limited',
    name: 'Property Perfect Public Company Limited',
    symbol: 'PF-R.BK',
  },
  {
    value: 'PTTG.F',
    label: 'PTTG.F,  PTT Exploration and Production Public Company Limited',
    name: 'PTT Exploration and Production Public Company Limited',
    symbol: 'PTTG.F',
  },
  {
    value: 'TRUE.BK',
    label: 'TRUE.BK,  True Corporation Public Company Limited',
    name: 'True Corporation Public Company Limited',
    symbol: 'TRUE.BK',
  },
  {
    value: 'TAFG.F',
    label: 'TAFG.F,  True Corporation Public Company Limited',
    name: 'True Corporation Public Company Limited',
    symbol: 'TAFG.F',
  },
  {
    value: 'JASN.F',
    label: 'JASN.F,  Jasmine International Public Company Limited',
    name: 'Jasmine International Public Company Limited',
    symbol: 'JASN.F',
  },
  {
    value: 'BCP.BK',
    label: 'BCP.BK,  Bangchak Corporation Public Company Limited',
    name: 'Bangchak Corporation Public Company Limited',
    symbol: 'BCP.BK',
  },
  {
    value: '7BP.F',
    label: '7BP.F,  Bangchak Corporation Public Company Limited',
    name: 'Bangchak Corporation Public Company Limited',
    symbol: '7BP.F',
  },
  {
    value: 'ITD.BK',
    label: 'ITD.BK,  Italian-Thai Development Public Company Limited',
    name: 'Italian-Thai Development Public Company Limited',
    symbol: 'ITD.BK',
  },
  {
    value: 'TU-R.BK',
    label: 'TU-R.BK,  Thai Union Group Public Company Limited',
    name: 'Thai Union Group Public Company Limited',
    symbol: 'TU-R.BK',
  },
  {
    value: 'TU.BK',
    label: 'TU.BK,  Thai Union Group Public Company Limited',
    name: 'Thai Union Group Public Company Limited',
    symbol: 'TU.BK',
  },
  {
    value: 'THYG.F',
    label: 'THYG.F,  Thai Union Group Public Company Limited',
    name: 'Thai Union Group Public Company Limited',
    symbol: 'THYG.F',
  },
  {
    value: 'LPN.BK',
    label: 'LPN.BK,  L.P.N. Development Public Company Limited',
    name: 'L.P.N. Development Public Company Limited',
    symbol: 'LPN.BK',
  },
  {
    value: 'ECGF.F',
    label: 'ECGF.F,  Electricity Generating Public Company Limited',
    name: 'Electricity Generating Public Company Limited',
    symbol: 'ECGF.F',
  },
  {
    value: 'IRPC.BK',
    label: 'IRPC.BK,  IRPC Public Company Limited',
    name: 'IRPC Public Company Limited',
    symbol: 'IRPC.BK',
  },
  {
    value: 'TPIG.F',
    label: 'TPIG.F,  IRPC Public Company Limited',
    name: 'IRPC Public Company Limited',
    symbol: 'TPIG.F',
  },
  {
    value: 'MBK.BK',
    label: 'MBK.BK,  MBK Public Company Limited',
    name: 'MBK Public Company Limited',
    symbol: 'MBK.BK',
  },
  {
    value: 'CPN.BK',
    label: 'CPN.BK,  Central Pattana Public Company Limited',
    name: 'Central Pattana Public Company Limited',
    symbol: 'CPN.BK',
  },
  {
    value: 'DLSF.F',
    label: 'DLSF.F,  Delta Electronics (Thailand) Public Company Limited',
    name: 'Delta Electronics (Thailand) Public Company Limited',
    symbol: 'DLSF.F',
  },
  {
    value: 'CK-R.BK',
    label: 'CK-R.BK,  CH. Karnchang Public Company Limited',
    name: 'CH. Karnchang Public Company Limited',
    symbol: 'CK-R.BK',
  },
  {
    value: 'CK.BK',
    label: 'CK.BK,  CH. Karnchang Public Company Limited',
    name: 'CH. Karnchang Public Company Limited',
    symbol: 'CK.BK',
  },
  {
    value: 'TTA.BK',
    label: 'TTA.BK,  Thoresen Thai Agencies Public Company Limited',
    name: 'Thoresen Thai Agencies Public Company Limited',
    symbol: 'TTA.BK',
  },
  {
    value: 'DTAC.BK',
    label: 'DTAC.BK,  Total Access Communication Public Company Limited',
    name: 'Total Access Communication Public Company Limited',
    symbol: 'DTAC.BK',
  },
  {
    value: 'SIRI.BK',
    label: 'SIRI.BK,  Sansiri Public Company Limited',
    name: 'Sansiri Public Company Limited',
    symbol: 'SIRI.BK',
  },
  {
    value: 'BEC.BK',
    label: 'BEC.BK,  BEC World Public Company Limited',
    name: 'BEC World Public Company Limited',
    symbol: 'BEC.BK',
  },
  {
    value: 'CCET.BK',
    label: 'CCET.BK,  Cal-Comp Electronics (Thailand) Public Company Limited',
    name: 'Cal-Comp Electronics (Thailand) Public Company Limited',
    symbol: 'CCET.BK',
  },
  {
    value: 'PTT.BK',
    label: 'PTT.BK,  PTT Public Company Limited',
    name: 'PTT Public Company Limited',
    symbol: 'PTT.BK',
  },
  {
    value: 'AH-R.BK',
    label: 'AH-R.BK,  AAPICO Hitech Public Company Limited',
    name: 'AAPICO Hitech Public Company Limited',
    symbol: 'AH-R.BK',
  },
  {
    value: 'KRTA.F',
    label: 'KRTA.F,  Krungthai Card Public Company Limited',
    name: 'Krungthai Card Public Company Limited',
    symbol: 'KRTA.F',
  },
  {
    value: 'RS-R.BK',
    label: 'RS-R.BK,  RS Public Company Limited',
    name: 'RS Public Company Limited',
    symbol: 'RS-R.BK',
  },
  {
    value: 'RS.BK',
    label: 'RS.BK,  RS Public Company Limited',
    name: 'RS Public Company Limited',
    symbol: 'RS.BK',
  },
  {
    value: 'LVN.SG',
    label: 'LVN.SG,  CP ALL Public Company Limited',
    name: 'CP ALL Public Company Limited',
    symbol: 'LVN.SG',
  },
  {
    value: 'SC.BK',
    label: 'SC.BK,  SC Asset Corporation Public Company Limited',
    name: 'SC Asset Corporation Public Company Limited',
    symbol: 'SC.BK',
  },
  {
    value: 'AOT.BK',
    label: 'AOT.BK,  Airports of Thailand Public Company Limited',
    name: 'Airports of Thailand Public Company Limited',
    symbol: 'AOT.BK',
  },
  {
    value: 'TX3A.F',
    label: 'TX3A.F,  Airports of Thailand Public Company Limited',
    name: 'Airports of Thailand Public Company Limited',
    symbol: 'TX3A.F',
  },
  {
    value: 'WORK.BK',
    label: 'WORK.BK,  Workpoint Entertainment Public Company Limited',
    name: 'Workpoint Entertainment Public Company Limited',
    symbol: 'WORK.BK',
  },
  {
    value: 'TOP.BK',
    label: 'TOP.BK,  Thai Oil Public Company Limited',
    name: 'Thai Oil Public Company Limited',
    symbol: 'TOP.BK',
  },
  {
    value: 'LQZ.F',
    label: 'LQZ.F,  Thai Oil Public Company Limited',
    name: 'Thai Oil Public Company Limited',
    symbol: 'LQZ.F',
  },
  {
    value: 'SNC.BK',
    label: 'SNC.BK,  SNC Former Public Company Limited',
    name: 'SNC Former Public Company Limited',
    symbol: 'SNC.BK',
  },
  {
    value: 'BCH.BK',
    label: 'BCH.BK,  Bangkok Chain Hospital Public Company Limited',
    name: 'Bangkok Chain Hospital Public Company Limited',
    symbol: 'BCH.BK',
  },
  {
    value: 'PTL.BK',
    label: 'PTL.BK,  Polyplex (Thailand) Public Company Limited',
    name: 'Polyplex (Thailand) Public Company Limited',
    symbol: 'PTL.BK',
  },
  {
    value: 'PSQ.F',
    label: 'PSQ.F,  Somboon Advance Technology Public Company Limited',
    name: 'Somboon Advance Technology Public Company Limited',
    symbol: 'PSQ.F',
  },
  {
    value: 'KSL.BK',
    label: 'KSL.BK,  Khon Kaen Sugar Industry Public Company Limited',
    name: 'Khon Kaen Sugar Industry Public Company Limited',
    symbol: 'KSL.BK',
  },
  {
    value: 'MCS.BK',
    label: 'MCS.BK,  M.C.S. Steel Public Company Limited',
    name: 'M.C.S. Steel Public Company Limited',
    symbol: 'MCS.BK',
  },
  {
    value: 'SPCG.BK',
    label: 'SPCG.BK,  SPCG Public Company Limited',
    name: 'SPCG Public Company Limited',
    symbol: 'SPCG.BK',
  },
  {
    value: 'Y92.SI',
    label: 'Y92.SI,  Thai Beverage Public Company Limited',
    name: 'Thai Beverage Public Company Limited',
    symbol: 'Y92.SI',
  },
  {
    value: 'UNIQ.BK',
    label:
      'UNIQ.BK,  Unique Engineering and Construction Public Company Limited',
    name: 'Unique Engineering and Construction Public Company Limited',
    symbol: 'UNIQ.BK',
  },
  {
    value: 'S-R.BK',
    label: 'S-R.BK,  Singha Estate Public Company Limited',
    name: 'Singha Estate Public Company Limited',
    symbol: 'S-R.BK',
  },
  {
    value: 'S.BK',
    label: 'S.BK,  Singha Estate Public Company Limited',
    name: 'Singha Estate Public Company Limited',
    symbol: 'S.BK',
  },
  {
    value: 'BWG.BK',
    label: 'BWG.BK,  Better World Green Public Company Limited',
    name: 'Better World Green Public Company Limited',
    symbol: 'BWG.BK',
  },
  {
    value: 'TTW.BK',
    label: 'TTW.BK,  TTW Public Company Limited',
    name: 'TTW Public Company Limited',
    symbol: 'TTW.BK',
  },
  {
    value: 'ESSO.BK',
    label: 'ESSO.BK,  Esso (Thailand) Public Company Limited',
    name: 'Esso (Thailand) Public Company Limited',
    symbol: 'ESSO.BK',
  },
  {
    value: 'PM-R.BK',
    label: 'PM-R.BK,  Premier Marketing Public Company Limited',
    name: 'Premier Marketing Public Company Limited',
    symbol: 'PM-R.BK',
  },
  {
    value: 'SGP.BK',
    label: 'SGP.BK,  Siamgas and Petrochemicals Public Company Limited',
    name: 'Siamgas and Petrochemicals Public Company Limited',
    symbol: 'SGP.BK',
  },
  {
    value: '47T.F',
    label: '47T.F,  TISCO Financial Group Public Company Limited',
    name: 'TISCO Financial Group Public Company Limited',
    symbol: '47T.F',
  },
  {
    value: 'TTCL.BK',
    label: 'TTCL.BK,  TTCL Public Company Limited',
    name: 'TTCL Public Company Limited',
    symbol: 'TTCL.BK',
  },
  {
    value: 'LHFG.BK',
    label: 'LHFG.BK,  LH Financial Group Public Company Limited',
    name: 'LH Financial Group Public Company Limited',
    symbol: 'LHFG.BK',
  },
  {
    value: 'IVL.BK',
    label: 'IVL.BK,  Indorama Ventures Public Company Limited',
    name: 'Indorama Ventures Public Company Limited',
    symbol: 'IVL.BK',
  },
  {
    value: 'I93.F',
    label: 'I93.F,  Indorama Ventures Public Company Limited',
    name: 'Indorama Ventures Public Company Limited',
    symbol: 'I93.F',
  },
  {
    value: 'GCB.F',
    label: 'GCB.F,  PTT Global Chemical Public Company Limited',
    name: 'PTT Global Chemical Public Company Limited',
    symbol: 'GCB.F',
  },
  {
    value: 'EA.BK',
    label: 'EA.BK,  Energy Absolute Public Company Limited',
    name: 'Energy Absolute Public Company Limited',
    symbol: 'EA.BK',
  },
  {
    value: 'JMT.BK',
    label: 'JMT.BK,  JMT Network Services Public Company Limited',
    name: 'JMT Network Services Public Company Limited',
    symbol: 'JMT.BK',
  },
  {
    value: 'BA-R.BK',
    label: 'BA-R.BK,  Bangkok Airways Public Company Limited',
    name: 'Bangkok Airways Public Company Limited',
    symbol: 'BA-R.BK',
  },
  {
    value: 'CHG.BK',
    label: 'CHG.BK,  Chularat Hospital Public Company Limited',
    name: 'Chularat Hospital Public Company Limited',
    symbol: 'CHG.BK',
  },
  {
    value: 'PTG.BK',
    label: 'PTG.BK,  PTG Energy Public Company Limited',
    name: 'PTG Energy Public Company Limited',
    symbol: 'PTG.BK',
  },
  {
    value: 'M.BK',
    label: 'M.BK,  MK Restaurant Group Public Company Limited',
    name: 'MK Restaurant Group Public Company Limited',
    symbol: 'M.BK',
  },
  {
    value: 'MEGA.BK',
    label: 'MEGA.BK,  Mega Lifesciences Public Company Limited',
    name: 'Mega Lifesciences Public Company Limited',
    symbol: 'MEGA.BK',
  },
  {
    value: 'EPG.BK',
    label: 'EPG.BK,  Eastern Polymer Group Public Company Limited',
    name: 'Eastern Polymer Group Public Company Limited',
    symbol: 'EPG.BK',
  },
  {
    value: 'TFG.BK',
    label: 'TFG.BK,  Thaifoods Group Public Company Limited',
    name: 'Thaifoods Group Public Company Limited',
    symbol: 'TFG.BK',
  },
  {
    value: 'SPRC.BK',
    label: 'SPRC.BK,  Star Petroleum Refining Public Company Limited',
    name: 'Star Petroleum Refining Public Company Limited',
    symbol: 'SPRC.BK',
  },
  {
    value: 'TKN.BK',
    label: 'TKN.BK,  Taokaenoi Food & Marketing Public Company Limited',
    name: 'Taokaenoi Food & Marketing Public Company Limited',
    symbol: 'TKN.BK',
  },
  {
    value: 'BEM.BK',
    label: 'BEM.BK,  Bangkok Expressway and Metro Public Company Limited',
    name: 'Bangkok Expressway and Metro Public Company Limited',
    symbol: 'BEM.BK',
  },
  {
    value: 'BCPG.BK',
    label: 'BCPG.BK,  BCPG Public Company Limited',
    name: 'BCPG Public Company Limited',
    symbol: 'BCPG.BK',
  },
  {
    value: 'BPP.BK',
    label: 'BPP.BK,  Banpu Power Public Company Limited',
    name: 'Banpu Power Public Company Limited',
    symbol: 'BPP.BK',
  },
  {
    value: 'PSH.BK',
    label: 'PSH.BK,  Pruksa Holding Public Company Limited',
    name: 'Pruksa Holding Public Company Limited',
    symbol: 'PSH.BK',
  },
  {
    value: '2PR4.SG',
    label: '2PR4.SG,  Pruksa Holding Public Company Limited',
    name: 'Pruksa Holding Public Company Limited',
    symbol: '2PR4.SG',
  },
  {
    value: 'PRM.BK',
    label: 'PRM.BK,  Prima Marine Public Company Limited',
    name: 'Prima Marine Public Company Limited',
    symbol: 'PRM.BK',
  },
  {
    value: 'CRC.BK',
    label: 'CRC.BK,  Central Retail Corporation Public Company Limited',
    name: 'Central Retail Corporation Public Company Limited',
    symbol: 'CRC.BK',
  },
  {
    value: '7F8.F',
    label: '7F8.F,  PTT Oil and Retail Business Public Company Limited',
    name: 'PTT Oil and Retail Business Public Company Limited',
    symbol: '7F8.F',
  },
  {
    value: 'ESEN.IS',
    label: 'ESEN.IS,  Esenboga Elektrik Üretim A.S.',
    name: 'Esenboga Elektrik Üretim A.S.',
    symbol: 'ESEN.IS',
  },
  {
    value: 'ODAS.IS',
    label: 'ODAS.IS,  Odas Elektrik Üretim Sanayi Ticaret A.S.',
    name: 'Odas Elektrik Üretim Sanayi Ticaret A.S.',
    symbol: 'ODAS.IS',
  },
  {
    value: '1752.TW',
    label: '1752.TW,  Nang Kuang Pharmaceutical Co., Ltd.',
    name: 'Nang Kuang Pharmaceutical Co., Ltd.',
    symbol: '1752.TW',
  },
  {
    value: '5306.TW',
    label: '5306.TW,  KMC (Kuei Meng) International Inc.',
    name: 'KMC (Kuei Meng) International Inc.',
    symbol: '5306.TW',
  },
  {
    value: 'AQNU',
    label: 'AQNU,  Algonquin Power & Utilities Corp.',
    name: 'Algonquin Power & Utilities Corp.',
    symbol: 'AQNU',
  },
  {
    value: 'AAM-PB',
    label: 'AAM-PB,  Apollo Global Management, Inc.',
    name: 'Apollo Global Management, Inc.',
    symbol: 'AAM-PB',
  },
  {
    value: '50C.MU',
    label: '50C.MU,  BIT Mining Limited',
    name: 'BIT Mining Limited',
    symbol: '50C.MU',
  },
  {
    value: 'OZKAP',
    label: 'OZKAP,  Bank OZK',
    name: 'Bank OZK',
    symbol: 'OZKAP',
  },
  {
    value: 'CADE-PA',
    label: 'CADE-PA,  Cadence Bank',
    name: 'Cadence Bank',
    symbol: 'CADE-PA',
  },
  {
    value: 'FCRX',
    label: 'FCRX,  First Eagle Alternative Capital BDC, Inc.',
    name: 'First Eagle Alternative Capital BDC, Inc.',
    symbol: 'FCRX',
  },
  {
    value: 'EFC-PB',
    label: 'EFC-PB,  Ellington Financial Inc.',
    name: 'Ellington Financial Inc.',
    symbol: 'EFC-PB',
  },
  {
    value: 'FHN-PD',
    label: 'FHN-PD,  First Horizon Corporation',
    name: 'First Horizon Corporation',
    symbol: 'FHN-PD',
  },
  {
    value: 'FRC-PI',
    label: 'FRC-PI,  First Republic Bank',
    name: 'First Republic Bank',
    symbol: 'FRC-PI',
  },
  {
    value: 'FRC-PN',
    label: 'FRC-PN,  First Republic Bank',
    name: 'First Republic Bank',
    symbol: 'FRC-PN',
  },
  {
    value: 'FRC-PM',
    label: 'FRC-PM,  First Republic Bank',
    name: 'First Republic Bank',
    symbol: 'FRC-PM',
  },
  {
    value: 'FRC-PL',
    label: 'FRC-PL,  First Republic Bank',
    name: 'First Republic Bank',
    symbol: 'FRC-PL',
  },
  {
    value: 'FRC-PK',
    label: 'FRC-PK,  First Republic Bank',
    name: 'First Republic Bank',
    symbol: 'FRC-PK',
  },
  {
    value: 'FRC-PH',
    label: 'FRC-PH,  First Republic Bank',
    name: 'First Republic Bank',
    symbol: 'FRC-PH',
  },
  {
    value: 'FIXP.IL',
    label: 'FIXP.IL,  Fix Price Group PLC',
    name: 'Fix Price Group PLC',
    symbol: 'FIXP.IL',
  },
  {
    value: 'FBRT-PE',
    label: 'FBRT-PE,  Franklin BSP Realty Trust, Inc.',
    name: 'Franklin BSP Realty Trust, Inc.',
    symbol: 'FBRT-PE',
  },
  {
    value: 'GPMT-PA',
    label: 'GPMT-PA,  Granite Point Mortgage Trust Inc.',
    name: 'Granite Point Mortgage Trust Inc.',
    symbol: 'GPMT-PA',
  },
  {
    value: 'GRNA',
    label: 'GRNA,  GreenLight Biosciences Holdings',
    name: 'GreenLight Biosciences Holdings',
    symbol: 'GRNA',
  },
  {
    value: '1MO.F',
    label: '1MO.F,  Hello Group Inc.',
    name: 'Hello Group Inc.',
    symbol: '1MO.F',
  },
  {
    value: 'HNHPF',
    label: 'HNHPF,  Hon Hai Precision Industry Co., Ltd.',
    name: 'Hon Hai Precision Industry Co., Ltd.',
    symbol: 'HNHPF',
  },
  {
    value: 'IC1B.F',
    label: 'IC1B.F,  InterContinental Hotels Group PLC',
    name: 'InterContinental Hotels Group PLC',
    symbol: 'IC1B.F',
  },
  {
    value: 'MARA.MX',
    label: 'MARA.MX,  Marathon Digital Holdings, Inc.',
    name: 'Marathon Digital Holdings, Inc.',
    symbol: 'MARA.MX',
  },
  {
    value: 'MS-PO',
    label: 'MS-PO,  Morgan Stanley',
    name: 'Morgan Stanley',
    symbol: 'MS-PO',
  },
  {
    value: 'ONBPP',
    label: 'ONBPP,  Old National Bancorp',
    name: 'Old National Bancorp',
    symbol: 'ONBPP',
  },
  {
    value: 'ONBPO',
    label: 'ONBPO,  Old National Bancorp',
    name: 'Old National Bancorp',
    symbol: 'ONBPO',
  },
  {
    value: 'PCG6.F',
    label: 'PCG6.F,  Pacific Gas and Electric Company',
    name: 'Pacific Gas and Electric Company',
    symbol: 'PCG6.F',
  },
  {
    value: 'PSA-PS',
    label: 'PSA-PS,  Public Storage',
    name: 'Public Storage',
    symbol: 'PSA-PS',
  },
  {
    value: 'PSA-PR',
    label: 'PSA-PR,  Public Storage',
    name: 'Public Storage',
    symbol: 'PSA-PR',
  },
  {
    value: 'RLI.F',
    label: 'RLI.F,  Rolinco N.V.',
    name: 'Rolinco N.V.',
    symbol: 'RLI.F',
  },
  {
    value: 'L3H.F',
    label: 'L3H.F,  Shell plc',
    name: 'Shell plc',
    symbol: 'L3H.F',
  },
  {
    value: 'SCE-PJ',
    label: 'SCE-PJ,  SCE Trust IV',
    name: 'SCE Trust IV',
    symbol: 'SCE-PJ',
  },
  {
    value: 'SKMD.IL',
    label: 'SKMD.IL,  SK Telecom Co.,Ltd',
    name: 'SK Telecom Co.,Ltd',
    symbol: 'SKMD.IL',
  },
  {
    value: 'CDX',
    label: 'CDX,  Simplify High Yield PLUS Credit Hedge ETF',
    name: 'Simplify High Yield PLUS Credit Hedge ETF',
    symbol: 'CDX',
  },
  {
    value: 'PFIX',
    label: 'PFIX,  Simplify Interest Rate Hedge ETF',
    name: 'Simplify Interest Rate Hedge ETF',
    symbol: 'PFIX',
  },
  {
    value: 'SVOL',
    label: 'SVOL,  Simplify Volatility Premium ETF',
    name: 'Simplify Volatility Premium ETF',
    symbol: 'SVOL',
  },
  {
    value: 'SONDW',
    label: 'SONDW,  Sonder Holdings Inc.',
    name: 'Sonder Holdings Inc.',
    symbol: 'SONDW',
  },
  {
    value: 'TTST.IL',
    label: 'TTST.IL,  Tata Steel Limited',
    name: 'Tata Steel Limited',
    symbol: 'TTST.IL',
  },
  {
    value: 'TIIAY',
    label: 'TIIAY,  Telecom Italia S.p.A.',
    name: 'Telecom Italia S.p.A.',
    symbol: 'TIIAY',
  },
  {
    value: '3T70.F',
    label: '3T70.F,  Textainer Group Holdings Limited',
    name: 'Textainer Group Holdings Limited',
    symbol: '3T70.F',
  },
  {
    value: 'TGH-PB',
    label: 'TGH-PB,  Textainer Group Holdings Limited',
    name: 'Textainer Group Holdings Limited',
    symbol: 'TGH-PB',
  },
  {
    value: 'WBS-PG',
    label: 'WBS-PG,  Webster Financial Corporation',
    name: 'Webster Financial Corporation',
    symbol: 'WBS-PG',
  },
  {
    value: 'ZIMV',
    label: 'ZIMV,  ZimVie Inc.',
    name: 'ZimVie Inc.',
    symbol: 'ZIMV',
  },
  {
    value: 'LTOUF',
    label: 'LTOUF,  Larsen & Toubro Limited',
    name: 'Larsen & Toubro Limited',
    symbol: 'LTOUF',
  },
  {
    value: 'MHID.IL',
    label: 'MHID.IL,  Mahindra & Mahindra Limited',
    name: 'Mahindra & Mahindra Limited',
    symbol: 'MHID.IL',
  },
  {
    value: 'D03.SI',
    label: 'D03.SI,  Del Monte Pacific Limited',
    name: 'Del Monte Pacific Limited',
    symbol: 'D03.SI',
  },
  {
    value: 'MSP.JO',
    label: 'MSP.JO,  MAS P.L.C.',
    name: 'MAS P.L.C.',
    symbol: 'MSP.JO',
  },
  {
    value: 'HDC.JO',
    label: 'HDC.JO,  Hudaco Industries Limited',
    name: 'Hudaco Industries Limited',
    symbol: 'HDC.JO',
  },
  {
    value: 'NED.JO',
    label: 'NED.JO,  Nedbank Group Limited',
    name: 'Nedbank Group Limited',
    symbol: 'NED.JO',
  },
  {
    value: 'OMN.JO',
    label: 'OMN.JO,  Omnia Holdings Limited',
    name: 'Omnia Holdings Limited',
    symbol: 'OMN.JO',
  },
  {
    value: 'PIK.JO',
    label: 'PIK.JO,  Pick n Pay Stores Limited',
    name: 'Pick n Pay Stores Limited',
    symbol: 'PIK.JO',
  },
  {
    value: 'SAP.JO',
    label: 'SAP.JO,  Sappi Limited',
    name: 'Sappi Limited',
    symbol: 'SAP.JO',
  },
  {
    value: 'SOL.JO',
    label: 'SOL.JO,  Sasol Limited',
    name: 'Sasol Limited',
    symbol: 'SOL.JO',
  },
  {
    value: 'WBO.JO',
    label: 'WBO.JO,  Wilson Bayly Holmes-Ovcon Limited',
    name: 'Wilson Bayly Holmes-Ovcon Limited',
    symbol: 'WBO.JO',
  },
  {
    value: 'NTC.JO',
    label: 'NTC.JO,  Netcare Limited',
    name: 'Netcare Limited',
    symbol: 'NTC.JO',
  },
  {
    value: 'SHP.JO',
    label: 'SHP.JO,  Shoprite Holdings Ltd',
    name: 'Shoprite Holdings Ltd',
    symbol: 'SHP.JO',
  },
  {
    value: 'HAR.JO',
    label: 'HAR.JO,  Harmony Gold Mining Company Limited',
    name: 'Harmony Gold Mining Company Limited',
    symbol: 'HAR.JO',
  },
  {
    value: 'NPN.JO',
    label: 'NPN.JO,  Naspers Limited',
    name: 'Naspers Limited',
    symbol: 'NPN.JO',
  },
  {
    value: 'DTC.JO',
    label: 'DTC.JO,  Datatec Limited',
    name: 'Datatec Limited',
    symbol: 'DTC.JO',
  },
  {
    value: 'GFI.JO',
    label: 'GFI.JO,  Gold Fields Limited',
    name: 'Gold Fields Limited',
    symbol: 'GFI.JO',
  },
  {
    value: 'SUR.JO',
    label: 'SUR.JO,  Spur Corporation Ltd',
    name: 'Spur Corporation Ltd',
    symbol: 'SUR.JO',
  },
  {
    value: 'RMH.JO',
    label: 'RMH.JO,  RMB Holdings Limited',
    name: 'RMB Holdings Limited',
    symbol: 'RMH.JO',
  },
  {
    value: 'OCE.JO',
    label: 'OCE.JO,  Oceana Group Limited',
    name: 'Oceana Group Limited',
    symbol: 'OCE.JO',
  },
  {
    value: 'TRU.JO',
    label: 'TRU.JO,  Truworths International Limited',
    name: 'Truworths International Limited',
    symbol: 'TRU.JO',
  },
  {
    value: 'ARL.JO',
    label: 'ARL.JO,  Astral Foods Limited',
    name: 'Astral Foods Limited',
    symbol: 'ARL.JO',
  },
  {
    value: 'ADH.JO',
    label: 'ADH.JO,  ADvTECH Limited',
    name: 'ADvTECH Limited',
    symbol: 'ADH.JO',
  },
  {
    value: 'MTN.JO',
    label: 'MTN.JO,  MTN Group Limited',
    name: 'MTN Group Limited',
    symbol: 'MTN.JO',
  },
  {
    value: 'FBR.JO',
    label: 'FBR.JO,  Famous Brands Limited',
    name: 'Famous Brands Limited',
    symbol: 'FBR.JO',
  },
  {
    value: 'RLO.JO',
    label: 'RLO.JO,  Reunert Limited',
    name: 'Reunert Limited',
    symbol: 'RLO.JO',
  },
  {
    value: 'LEW.JO',
    label: 'LEW.JO,  Lewis Group Limited',
    name: 'Lewis Group Limited',
    symbol: 'LEW.JO',
  },
  {
    value: 'SPP.JO',
    label: 'SPP.JO,  The SPAR Group Ltd',
    name: 'The SPAR Group Ltd',
    symbol: 'SPP.JO',
  },
  {
    value: 'WHL.JO',
    label: 'WHL.JO,  Woolworths Holdings Limited',
    name: 'Woolworths Holdings Limited',
    symbol: 'WHL.JO',
  },
  {
    value: 'FSR.JO',
    label: 'FSR.JO,  FirstRand Limited',
    name: 'FirstRand Limited',
    symbol: 'FSR.JO',
  },
  {
    value: 'SLM.JO',
    label: 'SLM.JO,  Sanlam Limited',
    name: 'Sanlam Limited',
    symbol: 'SLM.JO',
  },
  {
    value: 'TBS.JO',
    label: 'TBS.JO,  Tiger Brands Limited',
    name: 'Tiger Brands Limited',
    symbol: 'TBS.JO',
  },
  {
    value: 'NPK.JO',
    label: 'NPK.JO,  Nampak Limited',
    name: 'Nampak Limited',
    symbol: 'NPK.JO',
  },
  {
    value: 'GND.JO',
    label: 'GND.JO,  Grindrod Limited',
    name: 'Grindrod Limited',
    symbol: 'GND.JO',
  },
  {
    value: 'MUR.JO',
    label: 'MUR.JO,  Murray & Roberts Holdings Limited',
    name: 'Murray & Roberts Holdings Limited',
    symbol: 'MUR.JO',
  },
  {
    value: 'JSE.JO',
    label: 'JSE.JO,  JSE Limited',
    name: 'JSE Limited',
    symbol: 'JSE.JO',
  },
  {
    value: 'INL.JO',
    label: 'INL.JO,  Investec Group',
    name: 'Investec Group',
    symbol: 'INL.JO',
  },
  {
    value: 'IMP.JO',
    label: 'IMP.JO,  Impala Platinum Holdings Limited',
    name: 'Impala Platinum Holdings Limited',
    symbol: 'IMP.JO',
  },
  {
    value: 'KIO.JO',
    label: 'KIO.JO,  Kumba Iron Ore Limited',
    name: 'Kumba Iron Ore Limited',
    symbol: 'KIO.JO',
  },
  {
    value: 'AFT.JO',
    label: 'AFT.JO,  Afrimat Limited',
    name: 'Afrimat Limited',
    symbol: 'AFT.JO',
  },
  {
    value: 'ZED.JO',
    label: 'ZED.JO,  Zeder Investments Ltd.',
    name: 'Zeder Investments Ltd.',
    symbol: 'ZED.JO',
  },
  {
    value: 'MTA.JO',
    label: 'MTA.JO,  Metair Investments Limited',
    name: 'Metair Investments Limited',
    symbol: 'MTA.JO',
  },
  {
    value: 'RBX.JO',
    label: 'RBX.JO,  Raubex Group Limited',
    name: 'Raubex Group Limited',
    symbol: 'RBX.JO',
  },
  {
    value: 'SNT.JO',
    label: 'SNT.JO,  Santam Ltd',
    name: 'Santam Ltd',
    symbol: 'SNT.JO',
  },
  {
    value: 'SUI.JO',
    label: 'SUI.JO,  Sun International Limited',
    name: 'Sun International Limited',
    symbol: 'SUI.JO',
  },
  {
    value: 'ITE.JO',
    label: 'ITE.JO,  Italtile Limited',
    name: 'Italtile Limited',
    symbol: 'ITE.JO',
  },
  {
    value: 'SBK.JO',
    label: 'SBK.JO,  Standard Bank Group Limited',
    name: 'Standard Bank Group Limited',
    symbol: 'SBK.JO',
  },
  {
    value: 'CLH.JO',
    label: 'CLH.JO,  City Lodge Hotels Limited',
    name: 'City Lodge Hotels Limited',
    symbol: 'CLH.JO',
  },
  {
    value: 'AIP.JO',
    label: 'AIP.JO,  Adcock Ingram Holdings Limited',
    name: 'Adcock Ingram Holdings Limited',
    symbol: 'AIP.JO',
  },
  {
    value: 'VOD.JO',
    label: 'VOD.JO,  Vodacom Group Limited',
    name: 'Vodacom Group Limited',
    symbol: 'VOD.JO',
  },
  {
    value: 'ACL.JO',
    label: 'ACL.JO,  ArcelorMittal South Africa Ltd',
    name: 'ArcelorMittal South Africa Ltd',
    symbol: 'ACL.JO',
  },
  {
    value: 'TFG.JO',
    label: 'TFG.JO,  The Foschini Group Limited',
    name: 'The Foschini Group Limited',
    symbol: 'TFG.JO',
  },
  {
    value: 'RBP.JO',
    label: 'RBP.JO,  Royal Bafokeng Platinum Limited',
    name: 'Royal Bafokeng Platinum Limited',
    symbol: 'RBP.JO',
  },
  {
    value: 'MSM.JO',
    label: 'MSM.JO,  Massmart Holdings Limited',
    name: 'Massmart Holdings Limited',
    symbol: 'MSM.JO',
  },
  {
    value: 'COH.JO',
    label: 'COH.JO,  Curro Holdings Limited',
    name: 'Curro Holdings Limited',
    symbol: 'COH.JO',
  },
  {
    value: 'MPT.JO',
    label: 'MPT.JO,  Mpact Limited',
    name: 'Mpact Limited',
    symbol: 'MPT.JO',
  },
  {
    value: 'TCP.JO',
    label: 'TCP.JO,  Transaction Capital Limited',
    name: 'Transaction Capital Limited',
    symbol: 'TCP.JO',
  },
  {
    value: 'PPC.JO',
    label: 'PPC.JO,  PPC Ltd',
    name: 'PPC Ltd',
    symbol: 'PPC.JO',
  },
  {
    value: 'KAP.JO',
    label: 'KAP.JO,  KAP Industrial Holdings Limited',
    name: 'KAP Industrial Holdings Limited',
    symbol: 'KAP.JO',
  },
  {
    value: 'ATT.JO',
    label: 'ATT.JO,  Attacq Limited',
    name: 'Attacq Limited',
    symbol: 'ATT.JO',
  },
  {
    value: 'GRT.JO',
    label: 'GRT.JO,  Growthpoint Properties Limited',
    name: 'Growthpoint Properties Limited',
    symbol: 'GRT.JO',
  },
  {
    value: 'VKE.JO',
    label: 'VKE.JO,  Vukile Property Fund Limited',
    name: 'Vukile Property Fund Limited',
    symbol: 'VKE.JO',
  },
  {
    value: 'IPF.JO',
    label: 'IPF.JO,  Investec Property Fund Limited',
    name: 'Investec Property Fund Limited',
    symbol: 'IPF.JO',
  },
  {
    value: 'RDF.JO',
    label: 'RDF.JO,  Redefine Properties Limited',
    name: 'Redefine Properties Limited',
    symbol: 'RDF.JO',
  },
  {
    value: 'HYP.JO',
    label: 'HYP.JO,  Hyprop Investments Limited',
    name: 'Hyprop Investments Limited',
    symbol: 'HYP.JO',
  },
  {
    value: 'KST.JO',
    label: 'KST.JO,  PSG Konsult Limited',
    name: 'PSG Konsult Limited',
    symbol: 'KST.JO',
  },
  {
    value: 'AFH.JO',
    label: 'AFH.JO,  Alexander Forbes Group Holdings Limited',
    name: 'Alexander Forbes Group Holdings Limited',
    symbol: 'AFH.JO',
  },
  {
    value: 'RFG.JO',
    label: 'RFG.JO,  RFG Holdings Limited',
    name: 'RFG Holdings Limited',
    symbol: 'RFG.JO',
  },
  {
    value: 'MRP.JO',
    label: 'MRP.JO,  Mr Price Group Limited',
    name: 'Mr Price Group Limited',
    symbol: 'MRP.JO',
  },
  {
    value: 'EMI.JO',
    label: 'EMI.JO,  Emira Property Fund Limited',
    name: 'Emira Property Fund Limited',
    symbol: 'EMI.JO',
  },
  {
    value: 'SSS.JO',
    label: 'SSS.JO,  Stor-Age Property REIT Limited',
    name: 'Stor-Age Property REIT Limited',
    symbol: 'SSS.JO',
  },
  {
    value: 'BWN.JO',
    label: 'BWN.JO,  Balwin Properties Limited',
    name: 'Balwin Properties Limited',
    symbol: 'BWN.JO',
  },
  {
    value: 'RMI.JO',
    label: 'RMI.JO,  Rand Merchant Investment Holdings Limited',
    name: 'Rand Merchant Investment Holdings Limited',
    symbol: 'RMI.JO',
  },
  {
    value: 'BID.JO',
    label: 'BID.JO,  Bid Corporation Limited',
    name: 'Bid Corporation Limited',
    symbol: 'BID.JO',
  },
  {
    value: 'DCP.JO',
    label: 'DCP.JO,  Dis-Chem Pharmacies Limited',
    name: 'Dis-Chem Pharmacies Limited',
    symbol: 'DCP.JO',
  },
  {
    value: 'KAL.JO',
    label: 'KAL.JO,  Kaap Agri Limited',
    name: 'Kaap Agri Limited',
    symbol: 'KAL.JO',
  },
  {
    value: 'FFA.JO',
    label: 'FFA.JO,  Fortress REIT Limited',
    name: 'Fortress REIT Limited',
    symbol: 'FFA.JO',
  },
  {
    value: 'FFB.JO',
    label: 'FFB.JO,  Fortress REIT Limited',
    name: 'Fortress REIT Limited',
    symbol: 'FFB.JO',
  },
  {
    value: 'DGH.JO',
    label: 'DGH.JO,  Distell Group Holdings Limited',
    name: 'Distell Group Holdings Limited',
    symbol: 'DGH.JO',
  },
  {
    value: 'PPH.JO',
    label: 'PPH.JO,  Pepkor Holdings Limited',
    name: 'Pepkor Holdings Limited',
    symbol: 'PPH.JO',
  },
  {
    value: 'MTH.JO',
    label: 'MTH.JO,  Motus Holdings Limited',
    name: 'Motus Holdings Limited',
    symbol: 'MTH.JO',
  },
  {
    value: 'MCG.JO',
    label: 'MCG.JO,  MultiChoice Group Limited',
    name: 'MultiChoice Group Limited',
    symbol: 'MCG.JO',
  },
  {
    value: 'MTM.JO',
    label: 'MTM.JO,  Momentum Metropolitan Holdings Limited',
    name: 'Momentum Metropolitan Holdings Limited',
    symbol: 'MTM.JO',
  },
  {
    value: 'TSG.JO',
    label: 'TSG.JO,  Tsogo Sun Gaming Limited',
    name: 'Tsogo Sun Gaming Limited',
    symbol: 'TSG.JO',
  },
  {
    value: 'NY1.JO',
    label: 'NY1.JO,  Ninety One Group',
    name: 'Ninety One Group',
    symbol: 'NY1.JO',
  },
  {
    value: 'NPH.JO',
    label: 'NPH.JO,  Northam Platinum Holdings Limited',
    name: 'Northam Platinum Holdings Limited',
    symbol: 'NPH.JO',
  },
  {
    value: 'AEG.JO',
    label: 'AEG.JO,  Aveng Limited',
    name: 'Aveng Limited',
    symbol: 'AEG.JO',
  },
  {
    value: 'FTB.JO',
    label: 'FTB.JO,  Fairvest Limited',
    name: 'Fairvest Limited',
    symbol: 'FTB.JO',
  },
  {
    value: 'VWRA.L',
    label: 'VWRA.L,  Vanguard FTSE All-World UCITS ETF',
    name: 'Vanguard FTSE All-World UCITS ETF',
    symbol: 'VWRA.L',
  },
  {
    value: 'VWRD.L',
    label: 'VWRD.L,  Vanguard FTSE All-World UCITS ETF',
    name: 'Vanguard FTSE All-World UCITS ETF',
    symbol: 'VWRD.L',
  },
  {
    value: 'WAVS',
    label: 'WAVS,  Western Acquisition Ventures Corp.',
    name: 'Western Acquisition Ventures Corp.',
    symbol: 'WAVS',
  },
  {
    value: 'PWUPW',
    label: 'PWUPW,  PowerUp Acquisition Corp.',
    name: 'PowerUp Acquisition Corp.',
    symbol: 'PWUPW',
  },
  {
    value: 'APC.F',
    label: 'APC.F,  Apple Inc.',
    name: 'Apple Inc.',
    symbol: 'APC.F',
  },
  {
    value: 'WAVSW',
    label: 'WAVSW,  Western Acquisition Ventures Corp.',
    name: 'Western Acquisition Ventures Corp.',
    symbol: 'WAVSW',
  },
  {
    value: 'WBD',
    label: 'WBD,  Warner Bros. Discovery, Inc.',
    name: 'Warner Bros. Discovery, Inc.',
    symbol: 'WBD',
  },
  {
    value: 'PWUP',
    label: 'PWUP,  PowerUp Acquisition Corp.',
    name: 'PowerUp Acquisition Corp.',
    symbol: 'PWUP',
  },
  {
    value: 'GNS',
    label: 'GNS,  Genius Group Limited',
    name: 'Genius Group Limited',
    symbol: 'GNS',
  },
  {
    value: 'CRGE',
    label: 'CRGE,  Charge Enterprises, Inc.',
    name: 'Charge Enterprises, Inc.',
    symbol: 'CRGE',
  },
  {
    value: '1SN.L',
    label: '1SN.L,  First Tin Plc',
    name: 'First Tin Plc',
    symbol: '1SN.L',
  },
  {
    value: 'FTII',
    label: 'FTII,  FutureTech II Acquisition Corp.',
    name: 'FutureTech II Acquisition Corp.',
    symbol: 'FTII',
  },
  {
    value: 'SICO.JK',
    label: 'SICO.JK,  PT Sigma Energy Compressindo Tbk',
    name: 'PT Sigma Energy Compressindo Tbk',
    symbol: 'SICO.JK',
  },
  {
    value: 'EE',
    label: 'EE,  Excelerate Energy, Inc.',
    name: 'Excelerate Energy, Inc.',
    symbol: 'EE',
  },
  {
    value: 'STSSW',
    label: 'STSSW,  Sharps Technology, Inc.',
    name: 'Sharps Technology, Inc.',
    symbol: 'STSSW',
  },
  {
    value: '300007.SZ',
    label: '300007.SZ,  Hanwei Electronics Group Corporation',
    name: 'Hanwei Electronics Group Corporation',
    symbol: '300007.SZ',
  },
  {
    value: 'LBBB',
    label: 'LBBB,  Lakeshore Acquisition II Corp.',
    name: 'Lakeshore Acquisition II Corp.',
    symbol: 'LBBB',
  },
  {
    value: 'GDST',
    label: 'GDST,  Goldenstone Acquisition Limited',
    name: 'Goldenstone Acquisition Limited',
    symbol: 'GDST',
  },
  {
    value: 'LBBBW',
    label: 'LBBBW,  Lakeshore Acquisition II Corp.',
    name: 'Lakeshore Acquisition II Corp.',
    symbol: 'LBBBW',
  },
  {
    value: 'GDSTW',
    label: 'GDSTW,  Goldenstone Acquisition Limited',
    name: 'Goldenstone Acquisition Limited',
    symbol: 'GDSTW',
  },
  {
    value: 'STSS',
    label: 'STSS,  Sharps Technology, Inc.',
    name: 'Sharps Technology, Inc.',
    symbol: 'STSS',
  },
  {
    value: 'SVRE',
    label: 'SVRE,  SaverOne 2014 Ltd',
    name: 'SaverOne 2014 Ltd',
    symbol: 'SVRE',
  },
  {
    value: 'GRNR',
    label: 'GRNR,  Global X Green Building ETF',
    name: 'Global X Green Building ETF',
    symbol: 'GRNR',
  },
  {
    value: '300298.SZ',
    label: '300298.SZ,  Sinocare Inc.',
    name: 'Sinocare Inc.',
    symbol: '300298.SZ',
  },
  {
    value: 'AD.MI',
    label: 'AD.MI,  Koninklijke Ahold Delhaize N.V.',
    name: 'Koninklijke Ahold Delhaize N.V.',
    symbol: 'AD.MI',
  },
  {
    value: 'STM.MI',
    label: 'STM.MI,  STMicroelectronics N.V.',
    name: 'STMicroelectronics N.V.',
    symbol: 'STM.MI',
  },
  {
    value: 'JCSE',
    label: 'JCSE,  JE Cleantech Holdings Limited',
    name: 'JE Cleantech Holdings Limited',
    symbol: 'JCSE',
  },
  {
    value: '2179.HK',
    label: '2179.HK,  Jiangsu Recbio Technology Co., Ltd.',
    name: 'Jiangsu Recbio Technology Co., Ltd.',
    symbol: '2179.HK',
  },
  {
    value: 'VERS',
    label: 'VERS,  ProShares Metaverse ETF',
    name: 'ProShares Metaverse ETF',
    symbol: 'VERS',
  },
  {
    value: 'DFAR',
    label: 'DFAR,  Dimensional US Real Estate ETF',
    name: 'Dimensional US Real Estate ETF',
    symbol: 'DFAR',
  },
  {
    value: 'CLSE',
    label: 'CLSE,  Convergence Long/Short Equity ETF',
    name: 'Convergence Long/Short Equity ETF',
    symbol: 'CLSE',
  },
  {
    value: 'HAUS',
    label: 'HAUS,  Residential REIT Income ETF',
    name: 'Residential REIT Income ETF',
    symbol: 'HAUS',
  },
  {
    value: 'BNGE',
    label: 'BNGE,  First Trust S-Network Streaming & Gaming ETF',
    name: 'First Trust S-Network Streaming & Gaming ETF',
    symbol: 'BNGE',
  },
  {
    value: 'FTAIN',
    label: 'FTAIN,  Fortress Transportation and Infrastructure Investors LLC',
    name: 'Fortress Transportation and Infrastructure Investors LLC',
    symbol: 'FTAIN',
  },
  {
    value: 'FTAIO',
    label: 'FTAIO,  Fortress Transportation and Infrastructure Investors LLC',
    name: 'Fortress Transportation and Infrastructure Investors LLC',
    symbol: 'FTAIO',
  },
  {
    value: 'FTAIP',
    label: 'FTAIP,  Fortress Transportation and Infrastructure Investors LLC',
    name: 'Fortress Transportation and Infrastructure Investors LLC',
    symbol: 'FTAIP',
  },
  {
    value: 'GOGN',
    label: 'GOGN,  GoGreen Investments Corporation',
    name: 'GoGreen Investments Corporation',
    symbol: 'GOGN',
  },
  {
    value: 'VIVK',
    label: 'VIVK,  Vivakor, Inc.',
    name: 'Vivakor, Inc.',
    symbol: 'VIVK',
  },
  {
    value: 'SBFMW',
    label: 'SBFMW,  Sunshine Biopharma, Inc.',
    name: 'Sunshine Biopharma, Inc.',
    symbol: 'SBFMW',
  },
  {
    value: 'TNON',
    label: 'TNON,  Tenon Medical, Inc.',
    name: 'Tenon Medical, Inc.',
    symbol: 'TNON',
  },
  {
    value: 'ACON',
    label: 'ACON,  Aclarion, Inc.',
    name: 'Aclarion, Inc.',
    symbol: 'ACON',
  },
  {
    value: 'OST',
    label: 'OST,  Ostin Technology Group Co., Ltd.',
    name: 'Ostin Technology Group Co., Ltd.',
    symbol: 'OST',
  },
  {
    value: 'CHEAU',
    label: 'CHEAU,  Chenghe Acquisition Co.',
    name: 'Chenghe Acquisition Co.',
    symbol: 'CHEAU',
  },
  {
    value: 'CLRCU',
    label: 'CLRCU,  ClimateRock',
    name: 'ClimateRock',
    symbol: 'CLRCU',
  },
  {
    value: 'BAD',
    label: 'BAD,  B.A.D. ETF',
    name: 'B.A.D. ETF',
    symbol: 'BAD',
  },
  {
    value: 'DINO',
    label: 'DINO,  HF Sinclair Corporation',
    name: 'HF Sinclair Corporation',
    symbol: 'DINO',
  },
  {
    value: 'BLTE',
    label: 'BLTE,  Belite Bio, Inc',
    name: 'Belite Bio, Inc',
    symbol: 'BLTE',
  },
  {
    value: 'HLVX',
    label: 'HLVX,  HilleVax, Inc.',
    name: 'HilleVax, Inc.',
    symbol: 'HLVX',
  },
  {
    value: 'ANGELONE.NS',
    label: 'ANGELONE.NS,  Angel One Limited',
    name: 'Angel One Limited',
    symbol: 'ANGELONE.NS',
  },
  {
    value: 'IFSW.L',
    label: 'IFSW.L,  iShares Edge MSCI World Multifactor UCITS ETF USD (Acc)',
    name: 'iShares Edge MSCI World Multifactor UCITS ETF USD (Acc)',
    symbol: 'IFSW.L',
  },
  {
    value: '1183.SR',
    label: '1183.SR,  Saudi Home Loans Company',
    name: 'Saudi Home Loans Company',
    symbol: '1183.SR',
  },
  {
    value: '2390.HK',
    label: '2390.HK,  Zhihu Inc.',
    name: 'Zhihu Inc.',
    symbol: '2390.HK',
  },
  {
    value: 'LNW',
    label: 'LNW,  Light & Wonder, Inc.',
    name: 'Light & Wonder, Inc.',
    symbol: 'LNW',
  },
  {
    value: 'SOUNW',
    label: 'SOUNW,  SoundHound AI, Inc.',
    name: 'SoundHound AI, Inc.',
    symbol: 'SOUNW',
  },
  {
    value: 'PABU',
    label: 'PABU,  iShares Paris-Aligned Climate MSCI USA ETF',
    name: 'iShares Paris-Aligned Climate MSCI USA ETF',
    symbol: 'PABU',
  },
  {
    value: 'ELQD',
    label: 'ELQD,  iShares ESG Advanced Investment Grade Corporate Bond ETF',
    name: 'iShares ESG Advanced Investment Grade Corporate Bond ETF',
    symbol: 'ELQD',
  },
  {
    value: 'QSWN',
    label: 'QSWN,  Amplify BlackSwan Tech & Treasury ETF',
    name: 'Amplify BlackSwan Tech & Treasury ETF',
    symbol: 'QSWN',
  },
  {
    value: 'GLOV',
    label: 'GLOV,  Goldman Sachs ActiveBeta(R) World Low Vol Plus Equity ETF',
    name: 'Goldman Sachs ActiveBeta(R) World Low Vol Plus Equity ETF',
    symbol: 'GLOV',
  },
  {
    value: 'IWIN',
    label: 'IWIN,  Amplify Inflation Fighter ETF',
    name: 'Amplify Inflation Fighter ETF',
    symbol: 'IWIN',
  },
  {
    value: 'GDE',
    label: 'GDE,  WisdomTree Efficient Gold Plus Equity Strategy Fund',
    name: 'WisdomTree Efficient Gold Plus Equity Strategy Fund',
    symbol: 'GDE',
  },
  {
    value: 'AVSU',
    label: 'AVSU,  Avantis Responsible U.S. Equity ETF',
    name: 'Avantis Responsible U.S. Equity ETF',
    symbol: 'AVSU',
  },
  {
    value: 'BPAC3.SA',
    label: 'BPAC3.SA,  Banco BTG Pactual S.A.',
    name: 'Banco BTG Pactual S.A.',
    symbol: 'BPAC3.SA',
  },
  {
    value: 'SANB4.SA',
    label: 'SANB4.SA,  Banco Santander (Brasil) S.A.',
    name: 'Banco Santander (Brasil) S.A.',
    symbol: 'SANB4.SA',
  },
  {
    value: 'RAIZ4.SA',
    label: 'RAIZ4.SA,  Raízen S.A.',
    name: 'Raízen S.A.',
    symbol: 'RAIZ4.SA',
  },
  {
    value: 'HFGO',
    label: 'HFGO,  Hartford Large Cap Growth ETF',
    name: 'Hartford Large Cap Growth ETF',
    symbol: 'HFGO',
  },
  {
    value: 'IBTL',
    label: 'IBTL,  iShares iBonds Dec 2031 Term Treasury ETF',
    name: 'iShares iBonds Dec 2031 Term Treasury ETF',
    symbol: 'IBTL',
  },
  {
    value: 'ICAP',
    label: 'ICAP,  InfraCap Equity Income Fund ETF',
    name: 'InfraCap Equity Income Fund ETF',
    symbol: 'ICAP',
  },
  {
    value: 'INNO',
    label: 'INNO,  Harbor Disruptive Innovation ETF',
    name: 'Harbor Disruptive Innovation ETF',
    symbol: 'INNO',
  },
  {
    value: 'JAVA',
    label: 'JAVA,  JPMorgan Active Value ETF',
    name: 'JPMorgan Active Value ETF',
    symbol: 'JAVA',
  },
  {
    value: 'JBBB',
    label: 'JBBB,  Janus Henderson B-BBB CLO ETF',
    name: 'Janus Henderson B-BBB CLO ETF',
    symbol: 'JBBB',
  },
  {
    value: 'JHPI',
    label: 'JHPI,  John Hancock Preferred Income ETF',
    name: 'John Hancock Preferred Income ETF',
    symbol: 'JHPI',
  },
  {
    value: 'JIB',
    label: 'JIB,  Janus Henderson Sustainable & Impact Core Bond ETF',
    name: 'Janus Henderson Sustainable & Impact Core Bond ETF',
    symbol: 'JIB',
  },
  {
    value: 'KDRN',
    label: 'KDRN,  Kingsbarn Tactical Bond ETF',
    name: 'Kingsbarn Tactical Bond ETF',
    symbol: 'KDRN',
  },
  {
    value: 'MAKX',
    label: 'MAKX,  ProShares S&P Kensho Smart Factories ETF',
    name: 'ProShares S&P Kensho Smart Factories ETF',
    symbol: 'MAKX',
  },
  {
    value: 'MFUL',
    label: 'MFUL,  Mindful Conservative ETF',
    name: 'Mindful Conservative ETF',
    symbol: 'MFUL',
  },
  {
    value: 'MINO',
    label:
      'MINO,  PIMCO Municipal Income Opportunities Active Exchange-Traded Fund',
    name: 'PIMCO Municipal Income Opportunities Active Exchange-Traded Fund',
    symbol: 'MINO',
  },
  {
    value: 'MMCA',
    label: 'MMCA,  IQ MacKay California Municipal Intermediate ETF',
    name: 'IQ MacKay California Municipal Intermediate ETF',
    symbol: 'MMCA',
  },
  {
    value: 'MMSC',
    label: 'MMSC,  First Trust Multi-Manager Small Cap Opportunities ETF',
    name: 'First Trust Multi-Manager Small Cap Opportunities ETF',
    symbol: 'MMSC',
  },
  {
    value: 'MOHR',
    label: 'MOHR,  Mohr Growth ETF',
    name: 'Mohr Growth ETF',
    symbol: 'MOHR',
  },
  {
    value: 'MOTE',
    label: 'MOTE,  VanEck Morningstar ESG Moat ETF',
    name: 'VanEck Morningstar ESG Moat ETF',
    symbol: 'MOTE',
  },
  {
    value: 'MOTG',
    label: 'MOTG,  VanEck Morningstar Global Wide Moat ETF',
    name: 'VanEck Morningstar Global Wide Moat ETF',
    symbol: 'MOTG',
  },
  {
    value: 'MSMR',
    label: 'MSMR,  McElhenny Sheffield Managed Risk ETF',
    name: 'McElhenny Sheffield Managed Risk ETF',
    symbol: 'MSMR',
  },
  {
    value: 'NDJI',
    label: 'NDJI,  Nationwide Dow Jones Risk-Managed Income ETF',
    name: 'Nationwide Dow Jones Risk-Managed Income ETF',
    symbol: 'NDJI',
  },
  {
    value: 'NOVZ',
    label: 'NOVZ,  TrueShares Structured Outcome (November) ETF',
    name: 'TrueShares Structured Outcome (November) ETF',
    symbol: 'NOVZ',
  },
  {
    value: 'NSPI',
    label: 'NSPI,  Nationwide S&P 500 Risk-Managed Income ETF',
    name: 'Nationwide S&P 500 Risk-Managed Income ETF',
    symbol: 'NSPI',
  },
  {
    value: 'NTKI',
    label: 'NTKI,  Nationwide Russell 2000 Risk-Managed Income ETF',
    name: 'Nationwide Russell 2000 Risk-Managed Income ETF',
    symbol: 'NTKI',
  },
  {
    value: 'NUDV',
    label: 'NUDV,  Nuveen ESG Dividend ETF',
    name: 'Nuveen ESG Dividend ETF',
    symbol: 'NUDV',
  },
  {
    value: 'NWLG',
    label: 'NWLG,  Nuveen Winslow Large-Cap Growth ESG ETF',
    name: 'Nuveen Winslow Large-Cap Growth ESG ETF',
    symbol: 'NWLG',
  },
  {
    value: 'OALC',
    label: 'OALC,  OneAscent Large Cap Core ETF',
    name: 'OneAscent Large Cap Core ETF',
    symbol: 'OALC',
  },
  {
    value: 'OBND',
    label: 'OBND,  SPDR Loomis Sayles Opportunistic Bond ETF',
    name: 'SPDR Loomis Sayles Opportunistic Bond ETF',
    symbol: 'OBND',
  },
  {
    value: 'OCEN',
    label: 'OCEN,  IQ Clean Oceans ETF',
    name: 'IQ Clean Oceans ETF',
    symbol: 'OCEN',
  },
  {
    value: 'PAB',
    label: 'PAB,  PGIM Active Aggregate Bond ETF',
    name: 'PGIM Active Aggregate Bond ETF',
    symbol: 'PAB',
  },
  {
    value: 'PPI',
    label: 'PPI,  AXS Astoria Inflation Sensitive ETF',
    name: 'AXS Astoria Inflation Sensitive ETF',
    symbol: 'PPI',
  },
  {
    value: 'PSCQ',
    label: 'PSCQ,  Pacer Swan SOS Conservative (October) ETF',
    name: 'Pacer Swan SOS Conservative (October) ETF',
    symbol: 'PSCQ',
  },
  {
    value: 'PTRB',
    label: 'PTRB,  PGIM Total Return Bond ETF',
    name: 'PGIM Total Return Bond ETF',
    symbol: 'PTRB',
  },
  {
    value: 'HOM-UN.TO',
    label: 'HOM-UN.TO,  BSR Real Estate Investment Trust',
    name: 'BSR Real Estate Investment Trust',
    symbol: 'HOM-UN.TO',
  },
  {
    value: '5TY.SI',
    label: '5TY.SI,  Advanced Systems Automation Limited',
    name: 'Advanced Systems Automation Limited',
    symbol: '5TY.SI',
  },
  {
    value: 'AGIL.MC',
    label: 'AGIL.MC,  Agile Content, S.A.',
    name: 'Agile Content, S.A.',
    symbol: 'AGIL.MC',
  },
  {
    value: 'BCS.WA',
    label: 'BCS.WA,  Big Cheese Studio Spolka Akcyjna',
    name: 'Big Cheese Studio Spolka Akcyjna',
    symbol: 'BCS.WA',
  },
  {
    value: 'U1DA.F',
    label: 'U1DA.F,  Clockchain AG',
    name: 'Clockchain AG',
    symbol: 'U1DA.F',
  },
  {
    value: '9899.HK',
    label: '9899.HK,  Cloud Music Inc.',
    name: 'Cloud Music Inc.',
    symbol: '9899.HK',
  },
  {
    value: '8HC.DU',
    label: '8HC.DU,  Cogia AG',
    name: 'Cogia AG',
    symbol: '8HC.DU',
  },
  {
    value: 'CHS.V',
    label: 'CHS.V,  Comprehensive Healthcare Systems, Inc.',
    name: 'Comprehensive Healthcare Systems, Inc.',
    symbol: 'CHS.V',
  },
  {
    value: 'CNTL.TA',
    label: 'CNTL.TA,  Continual Ltd',
    name: 'Continual Ltd',
    symbol: 'CNTL.TA',
  },
  {
    value: 'DTOL.TO',
    label: 'DTOL.TO,  D2L Inc.',
    name: 'D2L Inc.',
    symbol: 'DTOL.TO',
  },
  {
    value: 'DATA.MI',
    label: 'DATA.MI,  Datrix S.p.A.',
    name: 'Datrix S.p.A.',
    symbol: 'DATA.MI',
  },
  {
    value: 'D.MI',
    label: 'D.MI,  Directa S.I.M.p.A.',
    name: 'Directa S.I.M.p.A.',
    symbol: 'D.MI',
  },
  {
    value: 'EINC.TO',
    label: 'EINC.TO,  E Automotive Inc.',
    name: 'E Automotive Inc.',
    symbol: 'EINC.TO',
  },
  {
    value: 'E2E.NS',
    label: 'E2E.NS,  E2E Networks Limited',
    name: 'E2E Networks Limited',
    symbol: 'E2E.NS',
  },
  {
    value: 'FNOX.ST',
    label: 'FNOX.ST,  Fortnox AB (publ)',
    name: 'Fortnox AB (publ)',
    symbol: 'FNOX.ST',
  },
  {
    value: 'JLB.SI',
    label: 'JLB.SI,  Grand Venture Technology Limited',
    name: 'Grand Venture Technology Limited',
    symbol: 'JLB.SI',
  },
  {
    value: 'HUB.TA',
    label: 'HUB.TA,  HUB Security',
    name: 'HUB Security',
    symbol: 'HUB.TA',
  },
  {
    value: '9878.HK',
    label: '9878.HK,  Huitongda Network Co., Ltd.',
    name: 'Huitongda Network Co., Ltd.',
    symbol: '9878.HK',
  },
  {
    value: '4014.T',
    label: '4014.T,  Karadanote Inc.',
    name: 'Karadanote Inc.',
    symbol: '4014.T',
  },
  {
    value: 'N01.SI',
    label: 'N01.SI,  Nera Telecommunications Ltd',
    name: 'Nera Telecommunications Ltd',
    symbol: 'N01.SI',
  },
  {
    value: 'NTEL.OL',
    label: 'NTEL.OL,  Nortel AS',
    name: 'Nortel AS',
    symbol: 'NTEL.OL',
  },
  {
    value: 'NBX.OL',
    label: 'NBX.OL,  Norwegian Block Exchange AS',
    name: 'Norwegian Block Exchange AS',
    symbol: 'NBX.OL',
  },
  {
    value: 'ASLC.JK',
    label: 'ASLC.JK,  PT Autopedia Sukses Lestari Tbk',
    name: 'PT Autopedia Sukses Lestari Tbk',
    symbol: 'ASLC.JK',
  },
  {
    value: 'WGSH.JK',
    label: 'WGSH.JK,  PT Wira Global Solusi Tbk',
    name: 'PT Wira Global Solusi Tbk',
    symbol: 'WGSH.JK',
  },
  {
    value: '6633.HK',
    label: '6633.HK,  Qingci Games Inc.',
    name: 'Qingci Games Inc.',
    symbol: '6633.HK',
  },
  {
    value: 'SBB.MI',
    label: 'SBB.MI,  Sababa Security S.p.A.',
    name: 'Sababa Security S.p.A.',
    symbol: 'SBB.MI',
  },
  {
    value: 'STH.WA',
    label: 'STH.WA,  STS Holding S.A.',
    name: 'STS Holding S.A.',
    symbol: 'STH.WA',
  },
  {
    value: '14D.DE',
    label: '14D.DE,  tokentus investment AG',
    name: 'tokentus investment AG',
    symbol: '14D.DE',
  },
  {
    value: 'UD.MI',
    label: 'UD.MI,  Unidata S.p.A.',
    name: 'Unidata S.p.A.',
    symbol: 'UD.MI',
  },
  {
    value: 'U2K.WA',
    label: 'U2K.WA,  Unima 2000 Systemy Teleinformatyczne S.A.',
    name: 'Unima 2000 Systemy Teleinformatyczne S.A.',
    symbol: 'U2K.WA',
  },
  {
    value: 'UWAY.TA',
    label: 'UWAY.TA,  UserWay Ltd',
    name: 'UserWay Ltd',
    symbol: 'UWAY.TA',
  },
  {
    value: 'V2Y.SI',
    label: 'V2Y.SI,  V2Y Corporation Ltd.',
    name: 'V2Y Corporation Ltd.',
    symbol: 'V2Y.SI',
  },
  {
    value: '4194.T',
    label: '4194.T,  Visional, Inc.',
    name: 'Visional, Inc.',
    symbol: '4194.T',
  },
  {
    value: 'VG0K.F',
    label: 'VG0K.F,  Vivanco Gruppe AG',
    name: 'Vivanco Gruppe AG',
    symbol: 'VG0K.F',
  },
  {
    value: 'OMK.SI',
    label: 'OMK.SI,  Vividthree Holdings Ltd.',
    name: 'Vividthree Holdings Ltd.',
    symbol: 'OMK.SI',
  },
  {
    value: '300613.SZ',
    label: '300613.SZ,  Shanghai Fullhan Microelectronics Co., Ltd.',
    name: 'Shanghai Fullhan Microelectronics Co., Ltd.',
    symbol: '300613.SZ',
  },
  {
    value: '300382.SZ',
    label: '300382.SZ,  Suzhou SLAC Precision Equipment CO.,Ltd.',
    name: 'Suzhou SLAC Precision Equipment CO.,Ltd.',
    symbol: '300382.SZ',
  },
  {
    value: '300636.SZ',
    label: '300636.SZ,  Jiangxi Synergy Pharmaceutical Co., Ltd.',
    name: 'Jiangxi Synergy Pharmaceutical Co., Ltd.',
    symbol: '300636.SZ',
  },
  {
    value: 'NCC-B.ST',
    label: 'NCC-B.ST,  NCC AB (publ)',
    name: 'NCC AB (publ)',
    symbol: 'NCC-B.ST',
  },
  {
    value: 'IAG.MC',
    label: 'IAG.MC,  International Consolidated Airlines Group S.A.',
    name: 'International Consolidated Airlines Group S.A.',
    symbol: 'IAG.MC',
  },
  {
    value: 'EDR.MC',
    label: 'EDR.MC,  eDreams ODIGEO S.A.',
    name: 'eDreams ODIGEO S.A.',
    symbol: 'EDR.MC',
  },
  {
    value: 'MTS.MC',
    label: 'MTS.MC,  ArcelorMittal S.A.',
    name: 'ArcelorMittal S.A.',
    symbol: 'MTS.MC',
  },
  {
    value: 'PHM.MC',
    label: 'PHM.MC,  Pharma Mar, S.A.',
    name: 'Pharma Mar, S.A.',
    symbol: 'PHM.MC',
  },
  {
    value: 'GRE.MC',
    label: 'GRE.MC,  Grenergy Renovables, S.A.',
    name: 'Grenergy Renovables, S.A.',
    symbol: 'GRE.MC',
  },
  {
    value: 'LGT.MC',
    label: 'LGT.MC,  Lingotes Especiales, S.A.',
    name: 'Lingotes Especiales, S.A.',
    symbol: 'LGT.MC',
  },
  {
    value: 'OLE.MC',
    label: 'OLE.MC,  Deoleo, S.A.',
    name: 'Deoleo, S.A.',
    symbol: 'OLE.MC',
  },
  {
    value: 'ATRY.MC',
    label: 'ATRY.MC,  Atrys Health, S.A.',
    name: 'Atrys Health, S.A.',
    symbol: 'ATRY.MC',
  },
  {
    value: 'RJF.MC',
    label: 'RJF.MC,  Laboratorio Reig Jofre, S.A.',
    name: 'Laboratorio Reig Jofre, S.A.',
    symbol: 'RJF.MC',
  },
  {
    value: 'XEKT.MC',
    label: 'XEKT.MC,  Grupo Elektra, S.A.B. de C.V.',
    name: 'Grupo Elektra, S.A.B. de C.V.',
    symbol: 'XEKT.MC',
  },
  {
    value: 'NHH.MC',
    label: 'NHH.MC,  NH Hotel Group, S.A.',
    name: 'NH Hotel Group, S.A.',
    symbol: 'NHH.MC',
  },
  {
    value: 'CBAV.MC',
    label: 'CBAV.MC,  Clínica Baviera, S.A.',
    name: 'Clínica Baviera, S.A.',
    symbol: 'CBAV.MC',
  },
  {
    value: 'ALB.MC',
    label: 'ALB.MC,  Corporación Financiera Alba, S.A.',
    name: 'Corporación Financiera Alba, S.A.',
    symbol: 'ALB.MC',
  },
  {
    value: 'R4.MC',
    label: 'R4.MC,  Renta 4 Banco, S.A.',
    name: 'Renta 4 Banco, S.A.',
    symbol: 'R4.MC',
  },
  {
    value: 'DOM.MC',
    label: 'DOM.MC,  Global Dominion Access, S.A.',
    name: 'Global Dominion Access, S.A.',
    symbol: 'DOM.MC',
  },
  {
    value: 'GOTO.JK',
    label: 'GOTO.JK,  PT GoTo Gojek Tokopedia Tbk',
    name: 'PT GoTo Gojek Tokopedia Tbk',
    symbol: 'GOTO.JK',
  },
  {
    value: 'JZRO',
    label: 'JZRO,  Janus Henderson Net Zero Transition Resources ETF',
    name: 'Janus Henderson Net Zero Transition Resources ETF',
    symbol: 'JZRO',
  },
  {
    value: 'SSAC.L',
    label: 'SSAC.L,  iShares V PLC - iShares MSCI ACWI UCITS ETF USD (Acc)',
    name: 'iShares V PLC - iShares MSCI ACWI UCITS ETF USD (Acc)',
    symbol: 'SSAC.L',
  },
  {
    value: 'IMEA.SW',
    label: 'IMEA.SW,  iShares Core MSCI Europe UCITS ETF EUR (Acc)',
    name: 'iShares Core MSCI Europe UCITS ETF EUR (Acc)',
    symbol: 'IMEA.SW',
  },
  {
    value: 'CSNDX.SW',
    label: 'CSNDX.SW,  iShares NASDAQ 100 UCITS ETF USD (Acc)',
    name: 'iShares NASDAQ 100 UCITS ETF USD (Acc)',
    symbol: 'CSNDX.SW',
  },
  {
    value: 'SUSW.L',
    label: 'SUSW.L,  iShares MSCI World SRI UCITS ETF EUR (Acc)',
    name: 'iShares MSCI World SRI UCITS ETF EUR (Acc)',
    symbol: 'SUSW.L',
  },
  {
    value: 'WITS.AS',
    label:
      'WITS.AS,  iShares MSCI World Information Technology Sector ESG UCITS ETF',
    name: 'iShares MSCI World Information Technology Sector ESG UCITS ETF',
    symbol: 'WITS.AS',
  },
  {
    value: 'BTEC.L',
    label: 'BTEC.L,  iShares Nasdaq US Biotechnology UCITS ETF USD (Acc)',
    name: 'iShares Nasdaq US Biotechnology UCITS ETF USD (Acc)',
    symbol: 'BTEC.L',
  },
  {
    value: 'EXV4.DE',
    label: 'EXV4.DE,  iShares STOXX Europe 600 Health Care UCITS ETF (DE)',
    name: 'iShares STOXX Europe 600 Health Care UCITS ETF (DE)',
    symbol: 'EXV4.DE',
  },
  {
    value: 'EMIM.L',
    label: 'EMIM.L,  iShares Core MSCI EM IMI UCITS ETF',
    name: 'iShares Core MSCI EM IMI UCITS ETF',
    symbol: 'EMIM.L',
  },
  {
    value: 'CISB.SW',
    label: 'CISB.SW,  iShares Smart City Infrastructure UCITS ETF',
    name: 'iShares Smart City Infrastructure UCITS ETF',
    symbol: 'CISB.SW',
  },
  {
    value: 'CT2B.AS',
    label: 'CT2B.AS,  iShares Smart City Infrastructure UCITS ETF',
    name: 'iShares Smart City Infrastructure UCITS ETF',
    symbol: 'CT2B.AS',
  },
  {
    value: 'SOUN',
    label: 'SOUN,  SoundHound AI, Inc.',
    name: 'SoundHound AI, Inc.',
    symbol: 'SOUN',
  },
  {
    value: 'BALL',
    label: 'BALL,  Ball Corporation',
    name: 'Ball Corporation',
    symbol: 'BALL',
  },
  {
    value: '300285.SZ',
    label: '300285.SZ,  Shandong Sinocera Functional Material Co., Ltd.',
    name: 'Shandong Sinocera Functional Material Co., Ltd.',
    symbol: '300285.SZ',
  },
  {
    value: 'WEIX',
    label: 'WEIX,  Dynamic Short Short-Term Volatility Futures ETF',
    name: 'Dynamic Short Short-Term Volatility Futures ETF',
    symbol: 'WEIX',
  },
  {
    value: 'SPRX',
    label: 'SPRX,  Spear Alpha ETF',
    name: 'Spear Alpha ETF',
    symbol: 'SPRX',
  },
  {
    value: 'IAPD.MI',
    label: 'IAPD.MI,  iShares Asia Pacific Dividend UCITS ETF',
    name: 'iShares Asia Pacific Dividend UCITS ETF',
    symbol: 'IAPD.MI',
  },
  {
    value: 'FSGGX',
    label: 'FSGGX,  Fidelity Global ex U.S. Index Fund',
    name: 'Fidelity Global ex U.S. Index Fund',
    symbol: 'FSGGX',
  },
  {
    value: 'FNILX',
    label: 'FNILX,  Fidelity ZERO Large Cap Index Fund',
    name: 'Fidelity ZERO Large Cap Index Fund',
    symbol: 'FNILX',
  },
  {
    value: 'FZROX',
    label: 'FZROX,  Fidelity Total Market Index Fund',
    name: 'Fidelity Total Market Index Fund',
    symbol: 'FZROX',
  },
  {
    value: 'OKYO',
    label: 'OKYO,  OKYO Pharma Limited',
    name: 'OKYO Pharma Limited',
    symbol: 'OKYO',
  },
  {
    value: 'SMFL',
    label: 'SMFL,  Smart for Life, Inc.',
    name: 'Smart for Life, Inc.',
    symbol: 'SMFL',
  },
  {
    value: 'VPLAY-B.ST',
    label: 'VPLAY-B.ST,  Viaplay Group AB (publ)',
    name: 'Viaplay Group AB (publ)',
    symbol: 'VPLAY-B.ST',
  },
  {
    value: 'VEDU',
    label: 'VEDU,  Visionary Education Technology Holdings Group Inc.',
    name: 'Visionary Education Technology Holdings Group Inc.',
    symbol: 'VEDU',
  },
  {
    value: '300313.SZ',
    label:
      '300313.SZ,  Xinjiang Tianshan Animal Husbandry Bio-engineering Co., Ltd.',
    name: 'Xinjiang Tianshan Animal Husbandry Bio-engineering Co., Ltd.',
    symbol: '300313.SZ',
  },
  {
    value: 'APLD',
    label: 'APLD,  Applied Blockchain, Inc.',
    name: 'Applied Blockchain, Inc.',
    symbol: 'APLD',
  },
  {
    value: 'AREN',
    label: 'AREN,  The Arena Group Holdings, Inc.',
    name: 'The Arena Group Holdings, Inc.',
    symbol: 'AREN',
  },
  {
    value: 'ASNS',
    label: 'ASNS,  Actelis Networks, Inc.',
    name: 'Actelis Networks, Inc.',
    symbol: 'ASNS',
  },
  {
    value: 'AUST',
    label: 'AUST,  Austin Gold Corp.',
    name: 'Austin Gold Corp.',
    symbol: 'AUST',
  },
  {
    value: 'BGXX',
    label: 'BGXX,  Bright Green Corporation',
    name: 'Bright Green Corporation',
    symbol: 'BGXX',
  },
  {
    value: 'BIOSU',
    label: 'BIOSU,  BioPlus Acquisition Corp.',
    name: 'BioPlus Acquisition Corp.',
    symbol: 'BIOSU',
  },
  {
    value: 'BLCO',
    label: 'BLCO,  Bausch + Lomb Corporation',
    name: 'Bausch + Lomb Corporation',
    symbol: 'BLCO',
  },
  {
    value: 'CRECU',
    label: 'CRECU,  Crescera Capital Acquisition Corp.',
    name: 'Crescera Capital Acquisition Corp.',
    symbol: 'CRECU',
  },
  {
    value: 'ENCPU',
    label: 'ENCPU,  Energem Corp.',
    name: 'Energem Corp.',
    symbol: 'ENCPU',
  },
  {
    value: 'HNVR',
    label: 'HNVR,  Hanover Bancorp, Inc.',
    name: 'Hanover Bancorp, Inc.',
    symbol: 'HNVR',
  },
  {
    value: 'MGLD',
    label: 'MGLD,  The Marygold Companies, Inc.',
    name: 'The Marygold Companies, Inc.',
    symbol: 'MGLD',
  },
  {
    value: 'MHUA',
    label: 'MHUA,  Meihua International Medical Technologies Co., Ltd.',
    name: 'Meihua International Medical Technologies Co., Ltd.',
    symbol: 'MHUA',
  },
  {
    value: 'PEPG',
    label: 'PEPG,  PepGen Inc.',
    name: 'PepGen Inc.',
    symbol: 'PEPG',
  },
  {
    value: 'IEMB.MI',
    label: 'IEMB.MI,  iShares J.P. Morgan $ EM Bond UCITS ETF USD (Dist)',
    name: 'iShares J.P. Morgan $ EM Bond UCITS ETF USD (Dist)',
    symbol: 'IEMB.MI',
  },
  {
    value: 'SEML.MI',
    label: 'SEML.MI,  iShares J.P. Morgan EM Local Govt Bond UCITS ETF',
    name: 'iShares J.P. Morgan EM Local Govt Bond UCITS ETF',
    symbol: 'SEML.MI',
  },
  {
    value: 'IHYU.MI',
    label: 'IHYU.MI,  iShares $ High Yield Corp Bond UCITS ETF USD (Dist)',
    name: 'iShares $ High Yield Corp Bond UCITS ETF USD (Dist)',
    symbol: 'IHYU.MI',
  },
  {
    value: 'IHYG.MI',
    label: 'IHYG.MI,  iShares € High Yield Corp Bond UCITS ETF EUR (Dist)',
    name: 'iShares € High Yield Corp Bond UCITS ETF EUR (Dist)',
    symbol: 'IHYG.MI',
  },
  {
    value: 'MLPI.MI',
    label: 'MLPI.MI,  L&G US Energy Infrastructure MLP UCITS ETF',
    name: 'L&G US Energy Infrastructure MLP UCITS ETF',
    symbol: 'MLPI.MI',
  },
  {
    value: 'BCHN.MI',
    label: 'BCHN.MI,  Invesco CoinShares Global Blockchain UCITS ETF',
    name: 'Invesco CoinShares Global Blockchain UCITS ETF',
    symbol: 'BCHN.MI',
  },
  {
    value: 'RBOT.MI',
    label: 'RBOT.MI,  iShares Automation & Robotics UCITS ETF',
    name: 'iShares Automation & Robotics UCITS ETF',
    symbol: 'RBOT.MI',
  },
  {
    value: 'SBIO.MI',
    label: 'SBIO.MI,  Invesco NASDAQ Biotech UCITS ETF',
    name: 'Invesco NASDAQ Biotech UCITS ETF',
    symbol: 'SBIO.MI',
  },
  {
    value: 'SMAFY.MI',
    label: 'SMAFY.MI,  Amundi Index Solutions - Amundi Smart Factory ETF',
    name: 'Amundi Index Solutions - Amundi Smart Factory ETF',
    symbol: 'SMAFY.MI',
  },
  {
    value: 'XDWU.MI',
    label: 'XDWU.MI,  Xtrackers MSCI World Utilities UCITS ETF',
    name: 'Xtrackers MSCI World Utilities UCITS ETF',
    symbol: 'XDWU.MI',
  },
  {
    value: 'ECAR.MI',
    label:
      'ECAR.MI,  iShares Electric Vehicles and Driving Technology UCITS ETF',
    name: 'iShares Electric Vehicles and Driving Technology UCITS ETF',
    symbol: 'ECAR.MI',
  },
  {
    value: 'INFR.MI',
    label: 'INFR.MI,  iShares Global Infrastructure UCITS ETF',
    name: 'iShares Global Infrastructure UCITS ETF',
    symbol: 'INFR.MI',
  },
  {
    value: 'PFRL',
    label: 'PFRL,  PGIM Floating Rate Income ETF',
    name: 'PGIM Floating Rate Income ETF',
    symbol: 'PFRL',
  },
  {
    value: 'LHYFE.PA',
    label: 'LHYFE.PA,  Lhyfe SA',
    name: 'Lhyfe SA',
    symbol: 'LHYFE.PA',
  },
  {
    value: 'SVREW',
    label: 'SVREW,  SaverOne 2014 Ltd',
    name: 'SaverOne 2014 Ltd',
    symbol: 'SVREW',
  },
  {
    value: 'EXX5.MI',
    label: 'EXX5.MI,  iShares Dow Jones U.S. Select Dividend UCITS ETF (DE)',
    name: 'iShares Dow Jones U.S. Select Dividend UCITS ETF (DE)',
    symbol: 'EXX5.MI',
  },
  {
    value: 'INRG.MI',
    label: 'INRG.MI,  iShares Global Clean Energy UCITS ETF USD (Dist)',
    name: 'iShares Global Clean Energy UCITS ETF USD (Dist)',
    symbol: 'INRG.MI',
  },
  {
    value: 'MICS',
    label: 'MICS,  The Singing Machine Company, Inc.',
    name: 'The Singing Machine Company, Inc.',
    symbol: 'MICS',
  },
  {
    value: 'GEHI',
    label: 'GEHI,  Gravitas Education Holdings, Inc.',
    name: 'Gravitas Education Holdings, Inc.',
    symbol: 'GEHI',
  },
  {
    value: 'LSAK',
    label: 'LSAK,  Lesaka Technologies, Inc.',
    name: 'Lesaka Technologies, Inc.',
    symbol: 'LSAK',
  },
  {
    value: 'MSPR',
    label: 'MSPR,  MSP Recovery, Inc.',
    name: 'MSP Recovery, Inc.',
    symbol: 'MSPR',
  },
  {
    value: 'MSPRZ',
    label: 'MSPRZ,  MSP Recovery, Inc.',
    name: 'MSP Recovery, Inc.',
    symbol: 'MSPRZ',
  },
  {
    value: 'NIC',
    label: 'NIC,  Nicolet Bankshares, Inc.',
    name: 'Nicolet Bankshares, Inc.',
    symbol: 'NIC',
  },
  {
    value: 'BNRG',
    label: 'BNRG,  Brenmiller Energy Ltd',
    name: 'Brenmiller Energy Ltd',
    symbol: 'BNRG',
  },
  {
    value: 'LAGNAM.NS',
    label: 'LAGNAM.NS,  Lagnam Spintex Limited',
    name: 'Lagnam Spintex Limited',
    symbol: 'LAGNAM.NS',
  },
  {
    value: 'PANSARI.NS',
    label: 'PANSARI.NS,  Pansari Developers Limited',
    name: 'Pansari Developers Limited',
    symbol: 'PANSARI.NS',
  },
  {
    value: 'AIROLAM.NS',
    label: 'AIROLAM.NS,  Airo Lam Limited',
    name: 'Airo Lam Limited',
    symbol: 'AIROLAM.NS',
  },
  {
    value: 'ARTNIRMAN.NS',
    label: 'ARTNIRMAN.NS,  Art Nirman Limited',
    name: 'Art Nirman Limited',
    symbol: 'ARTNIRMAN.NS',
  },
  {
    value: 'EIFFL.NS',
    label: 'EIFFL.NS,  Euro India Fresh Foods Limited',
    name: 'Euro India Fresh Foods Limited',
    symbol: 'EIFFL.NS',
  },
  {
    value: 'SONAMCLOCK.NS',
    label: 'SONAMCLOCK.NS,  Sonam Clock Limited',
    name: 'Sonam Clock Limited',
    symbol: 'SONAMCLOCK.NS',
  },
  {
    value: 'MITCON.NS',
    label: 'MITCON.NS,  MITCON Consultancy & Engineering Services Limited',
    name: 'MITCON Consultancy & Engineering Services Limited',
    symbol: 'MITCON.NS',
  },
  {
    value: 'PARAS.NS',
    label: 'PARAS.NS,  Paras Defence and Space Technologies Limited',
    name: 'Paras Defence and Space Technologies Limited',
    symbol: 'PARAS.NS',
  },
  {
    value: 'RPPL.NS',
    label: 'RPPL.NS,  Rajshree Polypack Limited',
    name: 'Rajshree Polypack Limited',
    symbol: 'RPPL.NS',
  },
  {
    value: 'VCL.NS',
    label: 'VCL.NS,  Vaxtex Cotfab Limited',
    name: 'Vaxtex Cotfab Limited',
    symbol: 'VCL.NS',
  },
  {
    value: 'LAXMICOT.NS',
    label: 'LAXMICOT.NS,  Laxmi Cotspin Limited',
    name: 'Laxmi Cotspin Limited',
    symbol: 'LAXMICOT.NS',
  },
  {
    value: 'UNIINFO.NS',
    label: 'UNIINFO.NS,  Uniinfo Telecom Services Limited',
    name: 'Uniinfo Telecom Services Limited',
    symbol: 'UNIINFO.NS',
  },
  {
    value: 'UNITEDPOLY.NS',
    label: 'UNITEDPOLY.NS,  United Polyfab Gujarat Limited',
    name: 'United Polyfab Gujarat Limited',
    symbol: 'UNITEDPOLY.NS',
  },
  {
    value: 'PRITI.NS',
    label: 'PRITI.NS,  Priti International Limited',
    name: 'Priti International Limited',
    symbol: 'PRITI.NS',
  },
  {
    value: 'SIKKO.NS',
    label: 'SIKKO.NS,  Sikko Industries Limited',
    name: 'Sikko Industries Limited',
    symbol: 'SIKKO.NS',
  },
  {
    value: 'WFL.NS',
    label: 'WFL.NS,  Wonder Fibromats Limited',
    name: 'Wonder Fibromats Limited',
    symbol: 'WFL.NS',
  },
  {
    value: 'MARSHALL.NS',
    label: 'MARSHALL.NS,  Marshall Machines Limited',
    name: 'Marshall Machines Limited',
    symbol: 'MARSHALL.NS',
  },
  {
    value: 'BHAGCHEM.NS',
    label: 'BHAGCHEM.NS,  Bhagiradha Chemicals & Industries Limited',
    name: 'Bhagiradha Chemicals & Industries Limited',
    symbol: 'BHAGCHEM.NS',
  },
  {
    value: 'BCONCEPTS.NS',
    label: 'BCONCEPTS.NS,  Brand Concepts Limited',
    name: 'Brand Concepts Limited',
    symbol: 'BCONCEPTS.NS',
  },
  {
    value: 'CROWN.NS',
    label: 'CROWN.NS,  Crown Lifters Limited',
    name: 'Crown Lifters Limited',
    symbol: 'CROWN.NS',
  },
  {
    value: 'COASTCORP.NS',
    label: 'COASTCORP.NS,  Coastal Corporation Limited',
    name: 'Coastal Corporation Limited',
    symbol: 'COASTCORP.NS',
  },
  {
    value: 'FOCUS.NS',
    label: 'FOCUS.NS,  Focus Lighting and Fixtures Limited',
    name: 'Focus Lighting and Fixtures Limited',
    symbol: 'FOCUS.NS',
  },
  {
    value: 'HECPROJECT.NS',
    label: 'HECPROJECT.NS,  HEC Infra Projects Limited',
    name: 'HEC Infra Projects Limited',
    symbol: 'HECPROJECT.NS',
  },
  {
    value: 'JETFREIGHT.NS',
    label: 'JETFREIGHT.NS,  Jet Freight Logistics Limited',
    name: 'Jet Freight Logistics Limited',
    symbol: 'JETFREIGHT.NS',
  },
  {
    value: 'AVROIND.NS',
    label: 'AVROIND.NS,  Avro India Limited',
    name: 'Avro India Limited',
    symbol: 'AVROIND.NS',
  },
  {
    value: 'UMAEXPORTS.NS',
    label: 'UMAEXPORTS.NS,  Uma Exports Limited',
    name: 'Uma Exports Limited',
    symbol: 'UMAEXPORTS.NS',
  },
  {
    value: 'DOLATALGO.NS',
    label: 'DOLATALGO.NS,  Dolat Algotech Limited',
    name: 'Dolat Algotech Limited',
    symbol: 'DOLATALGO.NS',
  },
  {
    value: 'STARTECK.NS',
    label: 'STARTECK.NS,  Starteck Finance Limited',
    name: 'Starteck Finance Limited',
    symbol: 'STARTECK.NS',
  },
  {
    value: 'RAINBOW.NS',
    label: "RAINBOW.NS,  Rainbow Children's Medicare Limited",
    name: "Rainbow Children's Medicare Limited",
    symbol: 'RAINBOW.NS',
  },
  {
    value: 'CAMPUS.NS',
    label: 'CAMPUS.NS,  Campus Activewear Limited',
    name: 'Campus Activewear Limited',
    symbol: 'CAMPUS.NS',
  },
  {
    value: 'INDOAMIN.NS',
    label: 'INDOAMIN.NS,  Indo Amines Limited',
    name: 'Indo Amines Limited',
    symbol: 'INDOAMIN.NS',
  },
  {
    value: 'PIXTRANS.NS',
    label: 'PIXTRANS.NS,  PIX Transmissions Limited',
    name: 'PIX Transmissions Limited',
    symbol: 'PIXTRANS.NS',
  },
  {
    value: 'ROTO.NS',
    label: 'ROTO.NS,  Roto Pumps Limited',
    name: 'Roto Pumps Limited',
    symbol: 'ROTO.NS',
  },
  {
    value: 'HARIOMPIPE.NS',
    label: 'HARIOMPIPE.NS,  Hariom Pipe Industries Limited',
    name: 'Hariom Pipe Industries Limited',
    symbol: 'HARIOMPIPE.NS',
  },
  {
    value: 'ELDEHSG.NS',
    label: 'ELDEHSG.NS,  Eldeco Housing and Industries Limited',
    name: 'Eldeco Housing and Industries Limited',
    symbol: 'ELDEHSG.NS',
  },
  {
    value: 'HARDWYN.NS',
    label: 'HARDWYN.NS,  Hardwyn India Limited',
    name: 'Hardwyn India Limited',
    symbol: 'HARDWYN.NS',
  },
  {
    value: 'RITCO.NS',
    label: 'RITCO.NS,  Ritco Logistics Limited',
    name: 'Ritco Logistics Limited',
    symbol: 'RITCO.NS',
  },
  {
    value: 'VERANDA.NS',
    label: 'VERANDA.NS,  Veranda Learning Solutions Limited',
    name: 'Veranda Learning Solutions Limited',
    symbol: 'VERANDA.NS',
  },
  {
    value: 'CHOICEIN.NS',
    label: 'CHOICEIN.NS,  Choice International Limited',
    name: 'Choice International Limited',
    symbol: 'CHOICEIN.NS',
  },
  {
    value: 'OBCL.NS',
    label: 'OBCL.NS,  Orissa Bengal Carrier Limited',
    name: 'Orissa Bengal Carrier Limited',
    symbol: 'OBCL.NS',
  },
  {
    value: 'SHAILY.NS',
    label: 'SHAILY.NS,  Shaily Engineering Plastics Limited',
    name: 'Shaily Engineering Plastics Limited',
    symbol: 'SHAILY.NS',
  },
  {
    value: 'DIL.NS',
    label: 'DIL.NS,  Debock Industries Limited',
    name: 'Debock Industries Limited',
    symbol: 'DIL.NS',
  },
  {
    value: 'CSSPX',
    label: 'CSSPX,  Cohen & Steers Global Realty Shares, Inc.',
    name: 'Cohen & Steers Global Realty Shares, Inc.',
    symbol: 'CSSPX',
  },
  {
    value: 'INDOBORAX.NS',
    label: 'INDOBORAX.NS,  Indo Borax & Chemicals Limited',
    name: 'Indo Borax & Chemicals Limited',
    symbol: 'INDOBORAX.NS',
  },
  {
    value: 'MSUMI.NS',
    label: 'MSUMI.NS,  Motherson Sumi Wiring India Limited',
    name: 'Motherson Sumi Wiring India Limited',
    symbol: 'MSUMI.NS',
  },
  {
    value: 'GMRP&UI.NS',
    label: 'GMRP&UI.NS,  GMR Power And Urban Infra Limited',
    name: 'GMR Power And Urban Infra Limited',
    symbol: 'GMRP&UI.NS',
  },
  {
    value: 'GATEWAY.NS',
    label: 'GATEWAY.NS,  Gateway Distriparks Limited',
    name: 'Gateway Distriparks Limited',
    symbol: 'GATEWAY.NS',
  },
  {
    value: 'RAJRILTD.NS',
    label: 'RAJRILTD.NS,  Raj Rayon Industries Limited',
    name: 'Raj Rayon Industries Limited',
    symbol: 'RAJRILTD.NS',
  },
  {
    value: 'IONEXCHANG.NS',
    label: 'IONEXCHANG.NS,  Ion Exchange (India) Limited',
    name: 'Ion Exchange (India) Limited',
    symbol: 'IONEXCHANG.NS',
  },
  {
    value: 'MEGASTAR.NS',
    label: 'MEGASTAR.NS,  Megastar Foods Limited',
    name: 'Megastar Foods Limited',
    symbol: 'MEGASTAR.NS',
  },
  {
    value: 'GRWRHITECH.NS',
    label: 'GRWRHITECH.NS,  Garware Hi-Tech Films Limited',
    name: 'Garware Hi-Tech Films Limited',
    symbol: 'GRWRHITECH.NS',
  },
  {
    value: 'MALLCOM.NS',
    label: 'MALLCOM.NS,  Mallcom (India) Limited',
    name: 'Mallcom (India) Limited',
    symbol: 'MALLCOM.NS',
  },
  {
    value: 'MHLXMIRU.NS',
    label: 'MHLXMIRU.NS,  Mahalaxmi Rubtech Limited',
    name: 'Mahalaxmi Rubtech Limited',
    symbol: 'MHLXMIRU.NS',
  },
  {
    value: 'MONARCH.NS',
    label: 'MONARCH.NS,  Monarch Networth Capital Limited',
    name: 'Monarch Networth Capital Limited',
    symbol: 'MONARCH.NS',
  },
  {
    value: 'UDAICEMENT.NS',
    label: 'UDAICEMENT.NS,  Udaipur Cement Works Limited',
    name: 'Udaipur Cement Works Limited',
    symbol: 'UDAICEMENT.NS',
  },
  {
    value: 'MICEL.NS',
    label: 'MICEL.NS,  MIC Electronics Limited',
    name: 'MIC Electronics Limited',
    symbol: 'MICEL.NS',
  },
  {
    value: 'SEJALLTD.NS',
    label: 'SEJALLTD.NS,  Sejal Glass Limited',
    name: 'Sejal Glass Limited',
    symbol: 'SEJALLTD.NS',
  },
  {
    value: 'ASIANENE.NS',
    label: 'ASIANENE.NS,  Asian Energy Services Limited',
    name: 'Asian Energy Services Limited',
    symbol: 'ASIANENE.NS',
  },
  {
    value: 'BAJAJHCARE.NS',
    label: 'BAJAJHCARE.NS,  Bajaj HealthCare Limited',
    name: 'Bajaj HealthCare Limited',
    symbol: 'BAJAJHCARE.NS',
  },
  {
    value: 'DHRUV.NS',
    label: 'DHRUV.NS,  Dhruv Consultancy Services Limited',
    name: 'Dhruv Consultancy Services Limited',
    symbol: 'DHRUV.NS',
  },
  {
    value: 'SMLT.NS',
    label: 'SMLT.NS,  Sarthak Metals Limited',
    name: 'Sarthak Metals Limited',
    symbol: 'SMLT.NS',
  },
  {
    value: 'SBC.NS',
    label: 'SBC.NS,  SBC Exports Limited',
    name: 'SBC Exports Limited',
    symbol: 'SBC.NS',
  },
  {
    value: 'STEELCAS.NS',
    label: 'STEELCAS.NS,  Steelcast Limited',
    name: 'Steelcast Limited',
    symbol: 'STEELCAS.NS',
  },
  {
    value: 'IRIS.NS',
    label: 'IRIS.NS,  IRIS Business Services Limited',
    name: 'IRIS Business Services Limited',
    symbol: 'IRIS.NS',
  },
  {
    value: 'MANORG.NS',
    label: 'MANORG.NS,  Mangalam Organics Limited',
    name: 'Mangalam Organics Limited',
    symbol: 'MANORG.NS',
  },
  {
    value: 'KRITI.NS',
    label: 'KRITI.NS,  Kriti Industries (India) Limited',
    name: 'Kriti Industries (India) Limited',
    symbol: 'KRITI.NS',
  },
  {
    value: 'MEDICAMEQ.NS',
    label: 'MEDICAMEQ.NS,  Medicamen Biotech Limited',
    name: 'Medicamen Biotech Limited',
    symbol: 'MEDICAMEQ.NS',
  },
  {
    value: 'SELMC.NS',
    label: 'SELMC.NS,  SEL Manufacturing Company Limited',
    name: 'SEL Manufacturing Company Limited',
    symbol: 'SELMC.NS',
  },
  {
    value: 'KPIGREEN.NS',
    label: 'KPIGREEN.NS,  KPI Green Energy Limited',
    name: 'KPI Green Energy Limited',
    symbol: 'KPIGREEN.NS',
  },
  {
    value: 'RBA.NS',
    label: 'RBA.NS,  Restaurant Brands Asia Limited',
    name: 'Restaurant Brands Asia Limited',
    symbol: 'RBA.NS',
  },
  {
    value: 'WINPRO.NS',
    label: 'WINPRO.NS,  WinPro Industries Limited',
    name: 'WinPro Industries Limited',
    symbol: 'WINPRO.NS',
  },
  {
    value: 'KBCGLOBAL.NS',
    label: 'KBCGLOBAL.NS,  KBC Global Limited',
    name: 'KBC Global Limited',
    symbol: 'KBCGLOBAL.NS',
  },
  {
    value: 'AURUM.NS',
    label: 'AURUM.NS,  Aurum PropTech Limited',
    name: 'Aurum PropTech Limited',
    symbol: 'AURUM.NS',
  },
  {
    value: 'LINC.NS',
    label: 'LINC.NS,  Linc Limited',
    name: 'Linc Limited',
    symbol: 'LINC.NS',
  },
  {
    value: 'YAARI.NS',
    label: 'YAARI.NS,  Yaari Digital Integrated Services Limited',
    name: 'Yaari Digital Integrated Services Limited',
    symbol: 'YAARI.NS',
  },
  {
    value: 'ZFCVINDIA.NS',
    label: 'ZFCVINDIA.NS,  ZF Commercial Vehicle Control Systems India Limited',
    name: 'ZF Commercial Vehicle Control Systems India Limited',
    symbol: 'ZFCVINDIA.NS',
  },
  {
    value: 'SEPC.NS',
    label: 'SEPC.NS,  SEPC Limited',
    name: 'SEPC Limited',
    symbol: 'SEPC.NS',
  },
  {
    value: 'BBOX.NS',
    label: 'BBOX.NS,  Black Box Limited',
    name: 'Black Box Limited',
    symbol: 'BBOX.NS',
  },
  {
    value: 'AGI.NS',
    label: 'AGI.NS,  AGI Greenpac Limited',
    name: 'AGI Greenpac Limited',
    symbol: 'AGI.NS',
  },
  {
    value: 'PCBL.NS',
    label: 'PCBL.NS,  PCBL Limited',
    name: 'PCBL Limited',
    symbol: 'PCBL.NS',
  },
  {
    value: 'MEDICO.NS',
    label: 'MEDICO.NS,  Medico Remedies Limited',
    name: 'Medico Remedies Limited',
    symbol: 'MEDICO.NS',
  },
  {
    value: 'BTMD',
    label: 'BTMD,  biote Corp.',
    name: 'biote Corp.',
    symbol: 'BTMD',
  },
  {
    value: 'BTMDW',
    label: 'BTMDW,  biote Corp.',
    name: 'biote Corp.',
    symbol: 'BTMDW',
  },
  {
    value: 'WEIDY',
    label: 'WEIDY,  Weidai Ltd.',
    name: 'Weidai Ltd.',
    symbol: 'WEIDY',
  },
  {
    value: 'PATI3.SA',
    label: 'PATI3.SA,  Panatlântica S.A.',
    name: 'Panatlântica S.A.',
    symbol: 'PATI3.SA',
  },
  {
    value: 'PATI4.SA',
    label: 'PATI4.SA,  Panatlântica S.A.',
    name: 'Panatlântica S.A.',
    symbol: 'PATI4.SA',
  },
  {
    value: 'BAHI3.SA',
    label: 'BAHI3.SA,  Bahema Educação S.A.',
    name: 'Bahema Educação S.A.',
    symbol: 'BAHI3.SA',
  },
  {
    value: 'BAZA3.SA',
    label: 'BAZA3.SA,  Banco da Amazônia S.A.',
    name: 'Banco da Amazônia S.A.',
    symbol: 'BAZA3.SA',
  },
  {
    value: 'BGIP3.SA',
    label: 'BGIP3.SA,  Banco do Estado de Sergipe S.A.',
    name: 'Banco do Estado de Sergipe S.A.',
    symbol: 'BGIP3.SA',
  },
  {
    value: 'BGIP4.SA',
    label: 'BGIP4.SA,  Banco do Estado de Sergipe S.A.',
    name: 'Banco do Estado de Sergipe S.A.',
    symbol: 'BGIP4.SA',
  },
  {
    value: 'BEES3.SA',
    label: 'BEES3.SA,  Banestes S.A - Banco do Estado do Espírito Santo',
    name: 'Banestes S.A - Banco do Estado do Espírito Santo',
    symbol: 'BEES3.SA',
  },
  {
    value: 'BEES4.SA',
    label: 'BEES4.SA,  Banestes S.A - Banco do Estado do Espírito Santo',
    name: 'Banestes S.A - Banco do Estado do Espírito Santo',
    symbol: 'BEES4.SA',
  },
  {
    value: 'BRSR3.SA',
    label: 'BRSR3.SA,  Banco do Estado do Rio Grande do Sul S.A.',
    name: 'Banco do Estado do Rio Grande do Sul S.A.',
    symbol: 'BRSR3.SA',
  },
  {
    value: 'BRSR5.SA',
    label: 'BRSR5.SA,  Banco do Estado do Rio Grande do Sul S.A.',
    name: 'Banco do Estado do Rio Grande do Sul S.A.',
    symbol: 'BRSR5.SA',
  },
  {
    value: 'BNBR3.SA',
    label: 'BNBR3.SA,  Banco do Nordeste do Brasil S.A.',
    name: 'Banco do Nordeste do Brasil S.A.',
    symbol: 'BNBR3.SA',
  },
  {
    value: 'BMIN3.SA',
    label: 'BMIN3.SA,  Banco Mercantil de Investimentos S.A.',
    name: 'Banco Mercantil de Investimentos S.A.',
    symbol: 'BMIN3.SA',
  },
  {
    value: 'BMIN4.SA',
    label: 'BMIN4.SA,  Banco Mercantil de Investimentos S.A.',
    name: 'Banco Mercantil de Investimentos S.A.',
    symbol: 'BMIN4.SA',
  },
  {
    value: 'BMEB3.SA',
    label: 'BMEB3.SA,  Banco Mercantil do Brasil S.A.',
    name: 'Banco Mercantil do Brasil S.A.',
    symbol: 'BMEB3.SA',
  },
  {
    value: 'BMEB4.SA',
    label: 'BMEB4.SA,  Banco Mercantil do Brasil S.A.',
    name: 'Banco Mercantil do Brasil S.A.',
    symbol: 'BMEB4.SA',
  },
  {
    value: 'BRIV3.SA',
    label: 'BRIV3.SA,  Banco Alfa de Investimento S.A.',
    name: 'Banco Alfa de Investimento S.A.',
    symbol: 'BRIV3.SA',
  },
  {
    value: 'BRIV4.SA',
    label: 'BRIV4.SA,  Banco Alfa de Investimento S.A.',
    name: 'Banco Alfa de Investimento S.A.',
    symbol: 'BRIV4.SA',
  },
  {
    value: 'BDLL3.SA',
    label: 'BDLL3.SA,  Bardella S.A. Indústrias Mecânicas',
    name: 'Bardella S.A. Indústrias Mecânicas',
    symbol: 'BDLL3.SA',
  },
  {
    value: 'BDLL4.SA',
    label: 'BDLL4.SA,  Bardella S.A. Indústrias Mecânicas',
    name: 'Bardella S.A. Indústrias Mecânicas',
    symbol: 'BDLL4.SA',
  },
  {
    value: 'BALM3.SA',
    label: 'BALM3.SA,  Baumer S.A.',
    name: 'Baumer S.A.',
    symbol: 'BALM3.SA',
  },
  {
    value: 'BALM4.SA',
    label: 'BALM4.SA,  Baumer S.A.',
    name: 'Baumer S.A.',
    symbol: 'BALM4.SA',
  },
  {
    value: 'BAUH4.SA',
    label: 'BAUH4.SA,  Excelsior Alimentos S.A.',
    name: 'Excelsior Alimentos S.A.',
    symbol: 'BAUH4.SA',
  },
  {
    value: 'BMKS3.SA',
    label: 'BMKS3.SA,  Bicicletas Monark S.A.',
    name: 'Bicicletas Monark S.A.',
    symbol: 'BMKS3.SA',
  },
  {
    value: 'CAMB3.SA',
    label: 'CAMB3.SA,  Cambuci S.A.',
    name: 'Cambuci S.A.',
    symbol: 'CAMB3.SA',
  },
  {
    value: 'CMIG3.SA',
    label: 'CMIG3.SA,  Companhia Energética de Minas Gerais',
    name: 'Companhia Energética de Minas Gerais',
    symbol: 'CMIG3.SA',
  },
  {
    value: 'CLSC3.SA',
    label: 'CLSC3.SA,  Centrais Elétricas de Santa Catarina S.A.',
    name: 'Centrais Elétricas de Santa Catarina S.A.',
    symbol: 'CLSC3.SA',
  },
  {
    value: 'CLSC4.SA',
    label: 'CLSC4.SA,  Centrais Elétricas de Santa Catarina S.A.',
    name: 'Centrais Elétricas de Santa Catarina S.A.',
    symbol: 'CLSC4.SA',
  },
  {
    value: 'CBEE3.SA',
    label: 'CBEE3.SA,  Ampla Energia e Serviços S.A.',
    name: 'Ampla Energia e Serviços S.A.',
    symbol: 'CBEE3.SA',
  },
  {
    value: 'FESA3.SA',
    label: 'FESA3.SA,  Cia de Ferro Ligas da Bahia - FERBASA',
    name: 'Cia de Ferro Ligas da Bahia - FERBASA',
    symbol: 'FESA3.SA',
  },
  {
    value: 'CEDO3.SA',
    label: 'CEDO3.SA,  Companhia de Fiação e Tecidos Cedro e Cachoeira S.A.',
    name: 'Companhia de Fiação e Tecidos Cedro e Cachoeira S.A.',
    symbol: 'CEDO3.SA',
  },
  {
    value: 'CEDO4.SA',
    label: 'CEDO4.SA,  Companhia de Fiação e Tecidos Cedro e Cachoeira S.A.',
    name: 'Companhia de Fiação e Tecidos Cedro e Cachoeira S.A.',
    symbol: 'CEDO4.SA',
  },
  {
    value: 'CSAB3.SA',
    label: 'CSAB3.SA,  Companhia de Seguros Alianca da Bahia',
    name: 'Companhia de Seguros Alianca da Bahia',
    symbol: 'CSAB3.SA',
  },
  {
    value: 'CSAB4.SA',
    label: 'CSAB4.SA,  Companhia de Seguros Alianca da Bahia',
    name: 'Companhia de Seguros Alianca da Bahia',
    symbol: 'CSAB4.SA',
  },
  {
    value: 'CTNM3.SA',
    label: 'CTNM3.SA,  Companhia de Tecidos Norte de Minas',
    name: 'Companhia de Tecidos Norte de Minas',
    symbol: 'CTNM3.SA',
  },
  {
    value: 'CTNM4.SA',
    label: 'CTNM4.SA,  Companhia de Tecidos Norte de Minas',
    name: 'Companhia de Tecidos Norte de Minas',
    symbol: 'CTNM4.SA',
  },
  {
    value: 'REDE3.SA',
    label: 'REDE3.SA,  Rede Energia Participações S.A.',
    name: 'Rede Energia Participações S.A.',
    symbol: 'REDE3.SA',
  },
  {
    value: 'HBTS5.SA',
    label: 'HBTS5.SA,  Companhia Habitasul de Participações',
    name: 'Companhia Habitasul de Participações',
    symbol: 'HBTS5.SA',
  },
  {
    value: 'CRIV3.SA',
    label:
      'CRIV3.SA,  Financeira Alfa S.A. - Crédito, Financiamento e Investimentos',
    name: 'Financeira Alfa S.A. - Crédito, Financiamento e Investimentos',
    symbol: 'CRIV3.SA',
  },
  {
    value: 'CRIV4.SA',
    label:
      'CRIV4.SA,  Financeira Alfa S.A. - Crédito, Financiamento e Investimentos',
    name: 'Financeira Alfa S.A. - Crédito, Financiamento e Investimentos',
    symbol: 'CRIV4.SA',
  },
  {
    value: 'GGBR3.SA',
    label: 'GGBR3.SA,  Gerdau S.A.',
    name: 'Gerdau S.A.',
    symbol: 'GGBR3.SA',
  },
  {
    value: 'CTSA3.SA',
    label: 'CTSA3.SA,  Companhia Tecidos Santanense Ltda.',
    name: 'Companhia Tecidos Santanense Ltda.',
    symbol: 'CTSA3.SA',
  },
  {
    value: 'CTSA4.SA',
    label: 'CTSA4.SA,  Companhia Tecidos Santanense Ltda.',
    name: 'Companhia Tecidos Santanense Ltda.',
    symbol: 'CTSA4.SA',
  },
  {
    value: 'CTKA3.SA',
    label: 'CTKA3.SA,  Karsten S.A.',
    name: 'Karsten S.A.',
    symbol: 'CTKA3.SA',
  },
  {
    value: 'CTKA4.SA',
    label: 'CTKA4.SA,  Karsten S.A.',
    name: 'Karsten S.A.',
    symbol: 'CTKA4.SA',
  },
  {
    value: 'CGRA3.SA',
    label: 'CGRA3.SA,  Grazziotin S.A.',
    name: 'Grazziotin S.A.',
    symbol: 'CGRA3.SA',
  },
  {
    value: 'ODV',
    label: 'ODV,  Osisko Development Corp.',
    name: 'Osisko Development Corp.',
    symbol: 'ODV',
  },
  {
    value: 'CGRA4.SA',
    label: 'CGRA4.SA,  Grazziotin S.A.',
    name: 'Grazziotin S.A.',
    symbol: 'CGRA4.SA',
  },
  {
    value: 'BRGE11.SA',
    label: 'BRGE11.SA,  Consórcio Alfa de Administração S.A.',
    name: 'Consórcio Alfa de Administração S.A.',
    symbol: 'BRGE11.SA',
  },
  {
    value: 'BRGE12.SA',
    label: 'BRGE12.SA,  Consórcio Alfa de Administração S.A.',
    name: 'Consórcio Alfa de Administração S.A.',
    symbol: 'BRGE12.SA',
  },
  {
    value: 'BRGE3.SA',
    label: 'BRGE3.SA,  Consórcio Alfa de Administração S.A.',
    name: 'Consórcio Alfa de Administração S.A.',
    symbol: 'BRGE3.SA',
  },
  {
    value: 'BRGE6.SA',
    label: 'BRGE6.SA,  Consórcio Alfa de Administração S.A.',
    name: 'Consórcio Alfa de Administração S.A.',
    symbol: 'BRGE6.SA',
  },
  {
    value: 'BRGE8.SA',
    label: 'BRGE8.SA,  Consórcio Alfa de Administração S.A.',
    name: 'Consórcio Alfa de Administração S.A.',
    symbol: 'BRGE8.SA',
  },
  {
    value: 'BRKM3.SA',
    label: 'BRKM3.SA,  Braskem S.A.',
    name: 'Braskem S.A.',
    symbol: 'BRKM3.SA',
  },
  {
    value: 'BRKM6.SA',
    label: 'BRKM6.SA,  Braskem S.A.',
    name: 'Braskem S.A.',
    symbol: 'BRKM6.SA',
  },
  {
    value: 'DOHL3.SA',
    label: 'DOHL3.SA,  Döhler S.A.',
    name: 'Döhler S.A.',
    symbol: 'DOHL3.SA',
  },
  {
    value: 'DOHL4.SA',
    label: 'DOHL4.SA,  Döhler S.A.',
    name: 'Döhler S.A.',
    symbol: 'DOHL4.SA',
  },
  {
    value: 'MNDL3.SA',
    label: 'MNDL3.SA,  Mundial S.A. - Produtos de Consumo',
    name: 'Mundial S.A. - Produtos de Consumo',
    symbol: 'MNDL3.SA',
  },
  {
    value: 'EALT3.SA',
    label: 'EALT3.SA,  Electro Aço Altona S.A.',
    name: 'Electro Aço Altona S.A.',
    symbol: 'EALT3.SA',
  },
  {
    value: 'EALT4.SA',
    label: 'EALT4.SA,  Electro Aço Altona S.A.',
    name: 'Electro Aço Altona S.A.',
    symbol: 'EALT4.SA',
  },
  {
    value: 'KEC.TO',
    label: 'KEC.TO,  Kiwetinohk Energy Corp.',
    name: 'Kiwetinohk Energy Corp.',
    symbol: 'KEC.TO',
  },
  {
    value: 'EUCA3.SA',
    label: 'EUCA3.SA,  Eucatex S.A. Indústria e Comércio',
    name: 'Eucatex S.A. Indústria e Comércio',
    symbol: 'EUCA3.SA',
  },
  {
    value: 'TASA3.SA',
    label: 'TASA3.SA,  Taurus Armas S.A.',
    name: 'Taurus Armas S.A.',
    symbol: 'TASA3.SA',
  },
  {
    value: 'TASA4.SA',
    label: 'TASA4.SA,  Taurus Armas S.A.',
    name: 'Taurus Armas S.A.',
    symbol: 'TASA4.SA',
  },
  {
    value: 'HETA4.SA',
    label: 'HETA4.SA,  Hércules S.A - Fábrica de Talheres',
    name: 'Hércules S.A - Fábrica de Talheres',
    symbol: 'HETA4.SA',
  },
  {
    value: 'HOOT4.SA',
    label: 'HOOT4.SA,  Hotéis Othon S.A.',
    name: 'Hotéis Othon S.A.',
    symbol: 'HOOT4.SA',
  },
  {
    value: 'IGBR3.SA',
    label: 'IGBR3.SA,  IGB Eletronica S.A.',
    name: 'IGB Eletronica S.A.',
    symbol: 'IGBR3.SA',
  },
  {
    value: 'TXRX3.SA',
    label: 'TXRX3.SA,  Têxtil Renauxview S.A.',
    name: 'Têxtil Renauxview S.A.',
    symbol: 'TXRX3.SA',
  },
  {
    value: 'TXRX4.SA',
    label: 'TXRX4.SA,  Têxtil Renauxview S.A.',
    name: 'Têxtil Renauxview S.A.',
    symbol: 'TXRX4.SA',
  },
  {
    value: 'INEP3.SA',
    label: 'INEP3.SA,  Inepar S.A. Indústria e Construções',
    name: 'Inepar S.A. Indústria e Construções',
    symbol: 'INEP3.SA',
  },
  {
    value: 'INEP4.SA',
    label: 'INEP4.SA,  Inepar S.A. Indústria e Construções',
    name: 'Inepar S.A. Indústria e Construções',
    symbol: 'INEP4.SA',
  },
  {
    value: 'JFEN3.SA',
    label: 'JFEN3.SA,  João Fortes Engenharia S.A.',
    name: 'João Fortes Engenharia S.A.',
    symbol: 'JFEN3.SA',
  },
  {
    value: 'LUXM4.SA',
    label: 'LUXM4.SA,  Trevisa Investimentos S.A.',
    name: 'Trevisa Investimentos S.A.',
    symbol: 'LUXM4.SA',
  },
  {
    value: 'MGEL4.SA',
    label: 'MGEL4.SA,  Mangels Industrial S.A.',
    name: 'Mangels Industrial S.A.',
    symbol: 'MGEL4.SA',
  },
  {
    value: 'ESTR4.SA',
    label: 'ESTR4.SA,  Manufatura de Brinquedos Estrela S.A.',
    name: 'Manufatura de Brinquedos Estrela S.A.',
    symbol: 'ESTR4.SA',
  },
  {
    value: 'POMO3.SA',
    label: 'POMO3.SA,  Marcopolo S.A.',
    name: 'Marcopolo S.A.',
    symbol: 'POMO3.SA',
  },
  {
    value: 'MERC3.SA',
    label: 'MERC3.SA,  Mercantil do Brasil Financeira S.A.',
    name: 'Mercantil do Brasil Financeira S.A.',
    symbol: 'MERC3.SA',
  },
  {
    value: 'MERC4.SA',
    label: 'MERC4.SA,  Mercantil do Brasil Financeira S.A.',
    name: 'Mercantil do Brasil Financeira S.A.',
    symbol: 'MERC4.SA',
  },
  {
    value: 'GOAU3.SA',
    label: 'GOAU3.SA,  Metalurgica Gerdau S.A.',
    name: 'Metalurgica Gerdau S.A.',
    symbol: 'GOAU3.SA',
  },
  {
    value: 'IGTI11.SA',
    label: 'IGTI11.SA,  Iguatemi S.A.',
    name: 'Iguatemi S.A.',
    symbol: 'IGTI11.SA',
  },
  {
    value: 'IGTI3.SA',
    label: 'IGTI3.SA,  Iguatemi S.A.',
    name: 'Iguatemi S.A.',
    symbol: 'IGTI3.SA',
  },
  {
    value: 'MTSA3.SA',
    label: 'MTSA3.SA,  METISA Metalúrgica Timboense S.A.',
    name: 'METISA Metalúrgica Timboense S.A.',
    symbol: 'MTSA3.SA',
  },
  {
    value: 'MTSA4.SA',
    label: 'MTSA4.SA,  METISA Metalúrgica Timboense S.A.',
    name: 'METISA Metalúrgica Timboense S.A.',
    symbol: 'MTSA4.SA',
  },
  {
    value: 'MOAR3.SA',
    label: 'MOAR3.SA,  Monteiro Aranha S.A.',
    name: 'Monteiro Aranha S.A.',
    symbol: 'MOAR3.SA',
  },
  {
    value: 'NORD3.SA',
    label: 'NORD3.SA,  Nordon Indústrias Metalúrgicas S/A',
    name: 'Nordon Indústrias Metalúrgicas S/A',
    symbol: 'NORD3.SA',
  },
  {
    value: 'PMAM3.SA',
    label: 'PMAM3.SA,  Paranapanema S.A.',
    name: 'Paranapanema S.A.',
    symbol: 'PMAM3.SA',
  },
  {
    value: 'AHEB3.SA',
    label: 'AHEB3.SA,  São Paulo Turismo S.A.',
    name: 'São Paulo Turismo S.A.',
    symbol: 'AHEB3.SA',
  },
  {
    value: 'PTNT3.SA',
    label: 'PTNT3.SA,  Pettenati S.A. Industria Têxtil',
    name: 'Pettenati S.A. Industria Têxtil',
    symbol: 'PTNT3.SA',
  },
  {
    value: 'PTNT4.SA',
    label: 'PTNT4.SA,  Pettenati S.A. Industria Têxtil',
    name: 'Pettenati S.A. Industria Têxtil',
    symbol: 'PTNT4.SA',
  },
  {
    value: 'RPAD3.SA',
    label: 'RPAD3.SA,  Alfa Holdings S.A.',
    name: 'Alfa Holdings S.A.',
    symbol: 'RPAD3.SA',
  },
  {
    value: 'RPAD5.SA',
    label: 'RPAD5.SA,  Alfa Holdings S.A.',
    name: 'Alfa Holdings S.A.',
    symbol: 'RPAD5.SA',
  },
  {
    value: 'RPAD6.SA',
    label: 'RPAD6.SA,  Alfa Holdings S.A.',
    name: 'Alfa Holdings S.A.',
    symbol: 'RPAD6.SA',
  },
  {
    value: 'RPMG3.SA',
    label: 'RPMG3.SA,  Refinaria de Petróleos de Manguinhos S.A.',
    name: 'Refinaria de Petróleos de Manguinhos S.A.',
    symbol: 'RPMG3.SA',
  },
  {
    value: 'ALPA3.SA',
    label: 'ALPA3.SA,  Alpargatas S.A.',
    name: 'Alpargatas S.A.',
    symbol: 'ALPA3.SA',
  },
  {
    value: 'SLED3.SA',
    label: 'SLED3.SA,  Saraiva Livreiros S.A. - em Recuperação Judicial',
    name: 'Saraiva Livreiros S.A. - em Recuperação Judicial',
    symbol: 'SLED3.SA',
  },
  {
    value: 'SLED4.SA',
    label: 'SLED4.SA,  Saraiva Livreiros S.A. - em Recuperação Judicial',
    name: 'Saraiva Livreiros S.A. - em Recuperação Judicial',
    symbol: 'SLED4.SA',
  },
  {
    value: 'SOND5.SA',
    label: 'SOND5.SA,  Sondotécnica Engenharia de Solos S.A.',
    name: 'Sondotécnica Engenharia de Solos S.A.',
    symbol: 'SOND5.SA',
  },
  {
    value: 'SOND6.SA',
    label: 'SOND6.SA,  Sondotécnica Engenharia de Solos S.A.',
    name: 'Sondotécnica Engenharia de Solos S.A.',
    symbol: 'SOND6.SA',
  },
  {
    value: 'WLMM3.SA',
    label:
      'WLMM3.SA,  WLM Participações e Comércio de Máquinas e Veículos S.A.',
    name: 'WLM Participações e Comércio de Máquinas e Veículos S.A.',
    symbol: 'WLMM3.SA',
  },
  {
    value: 'WLMM4.SA',
    label:
      'WLMM4.SA,  WLM Participações e Comércio de Máquinas e Veículos S.A.',
    name: 'WLM Participações e Comércio de Máquinas e Veículos S.A.',
    symbol: 'WLMM4.SA',
  },
  {
    value: 'TCNO3.SA',
    label: 'TCNO3.SA,  Tecnosolo Engenharia S.A.',
    name: 'Tecnosolo Engenharia S.A.',
    symbol: 'TCNO3.SA',
  },
  {
    value: 'TCNO4.SA',
    label: 'TCNO4.SA,  Tecnosolo Engenharia S.A.',
    name: 'Tecnosolo Engenharia S.A.',
    symbol: 'TCNO4.SA',
  },
  {
    value: 'TEKA4.SA',
    label: 'TEKA4.SA,  Teka Tecelagem Kuehnrich S.A.',
    name: 'Teka Tecelagem Kuehnrich S.A.',
    symbol: 'TEKA4.SA',
  },
  {
    value: 'TKNO4.SA',
    label: 'TKNO4.SA,  Tekno S.A. Indústria e Comércio',
    name: 'Tekno S.A. Indústria e Comércio',
    symbol: 'TKNO4.SA',
  },
  {
    value: 'TELB3.SA',
    label: 'TELB3.SA,  Telecomunicações Brasileiras S.A. - Telebras',
    name: 'Telecomunicações Brasileiras S.A. - Telebras',
    symbol: 'TELB3.SA',
  },
  {
    value: 'TELB4.SA',
    label: 'TELB4.SA,  Telecomunicações Brasileiras S.A. - Telebras',
    name: 'Telecomunicações Brasileiras S.A. - Telebras',
    symbol: 'TELB4.SA',
  },
  {
    value: 'OIBR4.SA',
    label: 'OIBR4.SA,  Oi S.A.',
    name: 'Oi S.A.',
    symbol: 'OIBR4.SA',
  },
  {
    value: 'CRPG5.SA',
    label: 'CRPG5.SA,  Tronox Pigmentos do Brasil S.A.',
    name: 'Tronox Pigmentos do Brasil S.A.',
    symbol: 'CRPG5.SA',
  },
  {
    value: 'CRPG6.SA',
    label: 'CRPG6.SA,  Tronox Pigmentos do Brasil S.A.',
    name: 'Tronox Pigmentos do Brasil S.A.',
    symbol: 'CRPG6.SA',
  },
  {
    value: 'UNIP3.SA',
    label: 'UNIP3.SA,  Unipar Carbocloro S.A.',
    name: 'Unipar Carbocloro S.A.',
    symbol: 'UNIP3.SA',
  },
  {
    value: 'UNIP5.SA',
    label: 'UNIP5.SA,  Unipar Carbocloro S.A.',
    name: 'Unipar Carbocloro S.A.',
    symbol: 'UNIP5.SA',
  },
  {
    value: 'AZEV3.SA',
    label: 'AZEV3.SA,  Azevedo & Travassos S.A.',
    name: 'Azevedo & Travassos S.A.',
    symbol: 'AZEV3.SA',
  },
  {
    value: 'AZEV4.SA',
    label: 'AZEV4.SA,  Azevedo & Travassos S.A.',
    name: 'Azevedo & Travassos S.A.',
    symbol: 'AZEV4.SA',
  },
  {
    value: 'MWET3.SA',
    label: 'MWET3.SA,  Wetzel S.A.',
    name: 'Wetzel S.A.',
    symbol: 'MWET3.SA',
  },
  {
    value: 'MWET4.SA',
    label: 'MWET4.SA,  Wetzel S.A.',
    name: 'Wetzel S.A.',
    symbol: 'MWET4.SA',
  },
  {
    value: 'BOBR4.SA',
    label: 'BOBR4.SA,  Bombril S.A.',
    name: 'Bombril S.A.',
    symbol: 'BOBR4.SA',
  },
  {
    value: 'RCSL3.SA',
    label: 'RCSL3.SA,  Recrusul S/A',
    name: 'Recrusul S/A',
    symbol: 'RCSL3.SA',
  },
  {
    value: 'RCSL4.SA',
    label: 'RCSL4.SA,  Recrusul S/A',
    name: 'Recrusul S/A',
    symbol: 'RCSL4.SA',
  },
  {
    value: 'KLBN3.SA',
    label: 'KLBN3.SA,  Klabin S.A.',
    name: 'Klabin S.A.',
    symbol: 'KLBN3.SA',
  },
  {
    value: 'KLBN4.SA',
    label: 'KLBN4.SA,  Klabin S.A.',
    name: 'Klabin S.A.',
    symbol: 'KLBN4.SA',
  },
  {
    value: 'SNSY3.SA',
    label: 'SNSY3.SA,  Sansuy S.A.',
    name: 'Sansuy S.A.',
    symbol: 'SNSY3.SA',
  },
  {
    value: 'SNSY5.SA',
    label: 'SNSY5.SA,  Sansuy S.A.',
    name: 'Sansuy S.A.',
    symbol: 'SNSY5.SA',
  },
  {
    value: 'JOPA3.SA',
    label: 'JOPA3.SA,  JOSAPAR Joaquim Oliveira S.A. Participações',
    name: 'JOSAPAR Joaquim Oliveira S.A. Participações',
    symbol: 'JOPA3.SA',
  },
  {
    value: 'JOPA4.SA',
    label: 'JOPA4.SA,  JOSAPAR Joaquim Oliveira S.A. Participações',
    name: 'JOSAPAR Joaquim Oliveira S.A. Participações',
    symbol: 'JOPA4.SA',
  },
  {
    value: 'HAGA3.SA',
    label: 'HAGA3.SA,  HAGA S/A Indústria e Comércio',
    name: 'HAGA S/A Indústria e Comércio',
    symbol: 'HAGA3.SA',
  },
  {
    value: 'HAGA4.SA',
    label: 'HAGA4.SA,  HAGA S/A Indústria e Comércio',
    name: 'HAGA S/A Indústria e Comércio',
    symbol: 'HAGA4.SA',
  },
  {
    value: 'RSUL4.SA',
    label: 'RSUL4.SA,  Metalúrgica Riosulense S.A.',
    name: 'Metalúrgica Riosulense S.A.',
    symbol: 'RSUL4.SA',
  },
  {
    value: 'PLAS3.SA',
    label: 'PLAS3.SA,  Plascar Participações Industriais S.A.',
    name: 'Plascar Participações Industriais S.A.',
    symbol: 'PLAS3.SA',
  },
  {
    value: 'MNPR3.SA',
    label: 'MNPR3.SA,  Minupar Participações S.A.',
    name: 'Minupar Participações S.A.',
    symbol: 'MNPR3.SA',
  },
  {
    value: 'SCAR3.SA',
    label: 'SCAR3.SA,  São Carlos Empreendimentos e Participações S.A.',
    name: 'São Carlos Empreendimentos e Participações S.A.',
    symbol: 'SCAR3.SA',
  },
  {
    value: 'MAPT4.SA',
    label: 'MAPT4.SA,  Cemepe Investimentos S.A.',
    name: 'Cemepe Investimentos S.A.',
    symbol: 'MAPT4.SA',
  },
  {
    value: 'RAPT3.SA',
    label: 'RAPT3.SA,  Randon S.A. Implementos e Participações',
    name: 'Randon S.A. Implementos e Participações',
    symbol: 'RAPT3.SA',
  },
  {
    value: 'BSLI3.SA',
    label: 'BSLI3.SA,  BRB - Banco de Brasília S.A.',
    name: 'BRB - Banco de Brasília S.A.',
    symbol: 'BSLI3.SA',
  },
  {
    value: 'BSLI4.SA',
    label: 'BSLI4.SA,  BRB - Banco de Brasília S.A.',
    name: 'BRB - Banco de Brasília S.A.',
    symbol: 'BSLI4.SA',
  },
  {
    value: 'CPLE11.SA',
    label: 'CPLE11.SA,  Companhia Paranaense de Energia - COPEL',
    name: 'Companhia Paranaense de Energia - COPEL',
    symbol: 'CPLE11.SA',
  },
  {
    value: 'CPLE3.SA',
    label: 'CPLE3.SA,  Companhia Paranaense de Energia - COPEL',
    name: 'Companhia Paranaense de Energia - COPEL',
    symbol: 'CPLE3.SA',
  },
  {
    value: 'ACWD.L',
    label: 'ACWD.L,  SPDR MSCI ACWI UCITS ETF',
    name: 'SPDR MSCI ACWI UCITS ETF',
    symbol: 'ACWD.L',
  },
  {
    value: 'ACWL.L',
    label: 'ACWL.L,  Lyxor Msci All Country World Ucits ETF',
    name: 'Lyxor Msci All Country World Ucits ETF',
    symbol: 'ACWL.L',
  },
  {
    value: 'ACWU.L',
    label: 'ACWU.L,  Lyxor Msci All Country World Ucits ETF',
    name: 'Lyxor Msci All Country World Ucits ETF',
    symbol: 'ACWU.L',
  },
  {
    value: 'AEJ.L',
    label:
      'AEJ.L,  MULTI-UNITS LUXEMBOURG - Lyxor MSCI AC Asia Pacific Ex Japan UCITS ETF',
    name: 'MULTI-UNITS LUXEMBOURG - Lyxor MSCI AC Asia Pacific Ex Japan UCITS ETF',
    symbol: 'AEJ.L',
  },
  {
    value: 'AEMD.L',
    label:
      'AEMD.L,  Amundi Index Solutions - Amundi Index MSCI Emerging Markets',
    name: 'Amundi Index Solutions - Amundi Index MSCI Emerging Markets',
    symbol: 'AEMD.L',
  },
  {
    value: 'AEME.L',
    label:
      'AEME.L,  Amundi Index Solutions - Amundi Index MSCI Emerging Markets UCITS ETF DR',
    name: 'Amundi Index Solutions - Amundi Index MSCI Emerging Markets UCITS ETF DR',
    symbol: 'AEME.L',
  },
  {
    value: 'AGHG.L',
    label:
      'AGHG.L,  Amundi Index Solutions - Amundi Index Barclays Global AGG 500M UCITS ETF DR GBP Hedged',
    name: 'Amundi Index Solutions - Amundi Index Barclays Global AGG 500M UCITS ETF DR GBP Hedged',
    symbol: 'AGHG.L',
  },
  {
    value: 'ALAG.L',
    label: 'ALAG.L,  Amundi Index Solutions - Amundi MSCI Em Latin America',
    name: 'Amundi Index Solutions - Amundi MSCI Em Latin America',
    symbol: 'ALAG.L',
  },
  {
    value: 'AMAP.L',
    label: 'AMAP.L,  Saturna Al-Kawthar Global Focused Equity UCITS ETF',
    name: 'Saturna Al-Kawthar Global Focused Equity UCITS ETF',
    symbol: 'AMAP.L',
  },
  {
    value: 'ANXG.L',
    label: 'ANXG.L,  Amundi Index Solutions - Amundi Nasdaq-100',
    name: 'Amundi Index Solutions - Amundi Nasdaq-100',
    symbol: 'ANXG.L',
  },
  {
    value: 'APEX.L',
    label:
      'APEX.L,  MULTI-UNITS LUXEMBOURG - Lyxor MSCI AC Asia Ex Japan UCITS ETF',
    name: 'MULTI-UNITS LUXEMBOURG - Lyxor MSCI AC Asia Ex Japan UCITS ETF',
    symbol: 'APEX.L',
  },
  {
    value: 'AQWA.L',
    label: 'AQWA.L,  Global X Clean Water UCITS ETF',
    name: 'Global X Clean Water UCITS ETF',
    symbol: 'AQWA.L',
  },
  {
    value: 'ASDV.L',
    label: 'ASDV.L,  SPDR S&P Pan Asia Dividend Aristocrats UCITS ETF',
    name: 'SPDR S&P Pan Asia Dividend Aristocrats UCITS ETF',
    symbol: 'ASDV.L',
  },
  {
    value: 'ASHR.L',
    label: 'ASHR.L,  Xtrackers Harvest CSI300 UCITS ETF',
    name: 'Xtrackers Harvest CSI300 UCITS ETF',
    symbol: 'ASHR.L',
  },
  {
    value: 'ASIC.L',
    label: 'ASIC.L,  Lyxor MSCI China ESG Leaders Extra (DR) UCITS ETF',
    name: 'Lyxor MSCI China ESG Leaders Extra (DR) UCITS ETF',
    symbol: 'ASIC.L',
  },
  {
    value: 'AT1D.L',
    label: 'AT1D.L,  Invesco AT1 Capital Bond ETF',
    name: 'Invesco AT1 Capital Bond ETF',
    symbol: 'AT1D.L',
  },
  {
    value: 'AT1S.L',
    label: 'AT1S.L,  Invesco AT1 Capital Bond ETF',
    name: 'Invesco AT1 Capital Bond ETF',
    symbol: 'AT1S.L',
  },
  {
    value: 'AUAD.L',
    label: 'AUAD.L,  UBS (Irl) ETF plc - MSCI Australia UCITS ETF',
    name: 'UBS (Irl) ETF plc - MSCI Australia UCITS ETF',
    symbol: 'AUAD.L',
  },
  {
    value: 'AUCP.L',
    label: 'AUCP.L,  L&G Gold Mining UCITS ETF',
    name: 'L&G Gold Mining UCITS ETF',
    symbol: 'AUCP.L',
  },
  {
    value: 'AUEG.L',
    label: 'AUEG.L,  Amundi Index Solutions - Amundi MSCI Emerging Markets',
    name: 'Amundi Index Solutions - Amundi MSCI Emerging Markets',
    symbol: 'AUEG.L',
  },
  {
    value: 'AUGA.L',
    label:
      'AUGA.L,  UBS (Irl) ETF plc - MSCI Australia UCITS ETF (hedged to GBP) A-acc',
    name: 'UBS (Irl) ETF plc - MSCI Australia UCITS ETF (hedged to GBP) A-acc',
    symbol: 'AUGA.L',
  },
  {
    value: 'AWSR.L',
    label:
      'AWSR.L,  UBS (Irl) ETF plc - MSCI ACWI Socially Responsible UCITS ETF Hedged USD A Acc',
    name: 'UBS (Irl) ETF plc - MSCI ACWI Socially Responsible UCITS ETF Hedged USD A Acc',
    symbol: 'AWSR.L',
  },
  {
    value: 'BATT.L',
    label: 'BATT.L,  L&G Battery Value-Chain UCITS ETF',
    name: 'L&G Battery Value-Chain UCITS ETF',
    symbol: 'BATT.L',
  },
  {
    value: 'BBGE.L',
    label:
      'BBGE.L,  JPMorgan ETFs (Ireland) ICAV BetaBuilders EUR Govt Bond UCITS ETF',
    name: 'JPMorgan ETFs (Ireland) ICAV BetaBuilders EUR Govt Bond UCITS ETF',
    symbol: 'BBGE.L',
  },
  {
    value: 'BBLL.L',
    label: 'BBLL.L,  JPM BetaBuilders US Treasury Bond 0-1 yr UCITS ETF',
    name: 'JPM BetaBuilders US Treasury Bond 0-1 yr UCITS ETF',
    symbol: 'BBLL.L',
  },
  {
    value: 'BBRT.L',
    label:
      'BBRT.L,  JPMorgan ETFs (Ireland) ICAV BetaBuilders US Treasury Bond UCITS ETF',
    name: 'JPMorgan ETFs (Ireland) ICAV BetaBuilders US Treasury Bond UCITS ETF',
    symbol: 'BBRT.L',
  },
  {
    value: 'BBSU.L',
    label:
      'BBSU.L,  JPMorgan ETFs (Ireland) ICAV - BetaBuilders US Equity UCITS ETF',
    name: 'JPMorgan ETFs (Ireland) ICAV - BetaBuilders US Equity UCITS ETF',
    symbol: 'BBSU.L',
  },
  {
    value: 'BBTP.L',
    label:
      'BBTP.L,  JPMorgan ETFs (Ireland) ICAV BetaBuilders US Treasury Bond UCITS ETF',
    name: 'JPMorgan ETFs (Ireland) ICAV BetaBuilders US Treasury Bond UCITS ETF',
    symbol: 'BBTP.L',
  },
  {
    value: 'BBTR.L',
    label:
      'BBTR.L,  JPMorgan ETFs (Ireland) ICAV BetaBuilders US Treasury Bond UCITS ETF',
    name: 'JPMorgan ETFs (Ireland) ICAV BetaBuilders US Treasury Bond UCITS ETF',
    symbol: 'BBTR.L',
  },
  {
    value: 'BCCU.L',
    label:
      'BCCU.L,  UBS (Irl) Fund Solutions plc - Bloomberg Commodity CMCI SF UCITS ETF',
    name: 'UBS (Irl) Fund Solutions plc - Bloomberg Commodity CMCI SF UCITS ETF',
    symbol: 'BCCU.L',
  },
  {
    value: 'BCHN.L',
    label: 'BCHN.L,  Invesco CoinShares Global Blockchain UCITS ETF',
    name: 'Invesco CoinShares Global Blockchain UCITS ETF',
    symbol: 'BCHN.L',
  },
  {
    value: 'BCOG.L',
    label: 'BCOG.L,  L&G All Commodities UCITS ETF',
    name: 'L&G All Commodities UCITS ETF',
    symbol: 'BCOG.L',
  },
  {
    value: 'BIOT.L',
    label: 'BIOT.L,  L&G Pharma Breakthrough UCITS ETF',
    name: 'L&G Pharma Breakthrough UCITS ETF',
    symbol: 'BIOT.L',
  },
  {
    value: 'BKCH.L',
    label: 'BKCH.L,  Global X Blockchain UCITS ETF',
    name: 'Global X Blockchain UCITS ETF',
    symbol: 'BKCH.L',
  },
  {
    value: 'BOTG.L',
    label: 'BOTG.L,  Global X Robotics & Artificial Intelligence UCITS ETF',
    name: 'Global X Robotics & Artificial Intelligence UCITS ETF',
    symbol: 'BOTG.L',
  },
  {
    value: 'BOTZ.L',
    label: 'BOTZ.L,  Global X Robotics & Artificial Intelligence UCITS ETF',
    name: 'Global X Robotics & Artificial Intelligence UCITS ETF',
    symbol: 'BOTZ.L',
  },
  {
    value: 'BRNG.L',
    label: 'BRNG.L,  WisdomTree Brent Crude Oil',
    name: 'WisdomTree Brent Crude Oil',
    symbol: 'BRNG.L',
  },
  {
    value: 'BUG.L',
    label: 'BUG.L,  Global X Cybersecurity UCITS ETF',
    name: 'Global X Cybersecurity UCITS ETF',
    symbol: 'BUG.L',
  },
  {
    value: 'BYBG.L',
    label: 'BYBG.L,  Amundi Index Solutions - Amundi S&P 500 Buyback',
    name: 'Amundi Index Solutions - Amundi S&P 500 Buyback',
    symbol: 'BYBG.L',
  },
  {
    value: 'BYBU.L',
    label: 'BYBU.L,  Amundi Index Solutions - Amundi S&P 500 Buyback',
    name: 'Amundi Index Solutions - Amundi S&P 500 Buyback',
    symbol: 'BYBU.L',
  },
  {
    value: 'CAPE.L',
    label: 'CAPE.L,  Ossiam Shiller Barclays Cape Europe Sector Value TR',
    name: 'Ossiam Shiller Barclays Cape Europe Sector Value TR',
    symbol: 'CAPE.L',
  },
  {
    value: 'CPLE5.SA',
    label: 'CPLE5.SA,  Companhia Paranaense de Energia - COPEL',
    name: 'Companhia Paranaense de Energia - COPEL',
    symbol: 'CPLE5.SA',
  },
  {
    value: 'CASH.L',
    label: 'CASH.L,  L&GE Fund MSCI China A UCITS ETF',
    name: 'L&GE Fund MSCI China A UCITS ETF',
    symbol: 'CASH.L',
  },
  {
    value: 'CAVE.L',
    label: 'CAVE.L,  VanEck Smart Home Active UCITS ETF',
    name: 'VanEck Smart Home Active UCITS ETF',
    symbol: 'CAVE.L',
  },
  {
    value: 'CBE3.L',
    label:
      'CBE3.L,  iShares VII PLC - iShares € Govt Bond 1-3yr UCITS ETF EUR (Acc)',
    name: 'iShares VII PLC - iShares € Govt Bond 1-3yr UCITS ETF EUR (Acc)',
    symbol: 'CBE3.L',
  },
  {
    value: 'CBS5.L',
    label:
      'CBS5.L,  UBS (Lux) Fund Solutions - Bloomberg MSCI US Liquid Corporates 1-5 Year Sustainable UCITS ETF',
    name: 'UBS (Lux) Fund Solutions - Bloomberg MSCI US Liquid Corporates 1-5 Year Sustainable UCITS ETF',
    symbol: 'CBS5.L',
  },
  {
    value: 'CBSE.L',
    label:
      'CBSE.L,  UBS (Lux) Fund Solutions – Bloomberg MSCI Euro Area Liquid Corporates Sustainable UCITS ETF',
    name: 'UBS (Lux) Fund Solutions – Bloomberg MSCI Euro Area Liquid Corporates Sustainable UCITS ETF',
    symbol: 'CBSE.L',
  },
  {
    value: 'CC1G.L',
    label: 'CC1G.L,  Amundi Index Solutions - Amundi MSCI China',
    name: 'Amundi Index Solutions - Amundi MSCI China',
    symbol: 'CC1G.L',
  },
  {
    value: 'CC1U.L',
    label: 'CC1U.L,  Amundi Index Solutions - Amundi MSCI China',
    name: 'Amundi Index Solutions - Amundi MSCI China',
    symbol: 'CC1U.L',
  },
  {
    value: 'CE01.L',
    label:
      'CE01.L,  iShares VII PLC - iShares € Govt Bond 7-10yr UCITS ETF EUR (Acc)',
    name: 'iShares VII PLC - iShares € Govt Bond 7-10yr UCITS ETF EUR (Acc)',
    symbol: 'CE01.L',
  },
  {
    value: 'CE2D.L',
    label: 'CE2D.L,  Amundi Index Solutions - Amundi Index MSCI Europe',
    name: 'Amundi Index Solutions - Amundi Index MSCI Europe',
    symbol: 'CE2D.L',
  },
  {
    value: 'CE71.L',
    label:
      'CE71.L,  iShares VII PLC - iShares € Govt Bond 3-7yr UCITS ETF EUR (Acc)',
    name: 'iShares VII PLC - iShares € Govt Bond 3-7yr UCITS ETF EUR (Acc)',
    symbol: 'CE71.L',
  },
  {
    value: 'CE9G.L',
    label:
      'CE9G.L,  Amundi Index Solutions - Amundi MSCI Eastern Europe Ex Russia',
    name: 'Amundi Index Solutions - Amundi MSCI Eastern Europe Ex Russia',
    symbol: 'CE9G.L',
  },
  {
    value: 'CE9U.L',
    label:
      'CE9U.L,  Amundi Index Solutions - Amundi MSCI Eastern Europe Ex Russia',
    name: 'Amundi Index Solutions - Amundi MSCI Eastern Europe Ex Russia',
    symbol: 'CE9U.L',
  },
  {
    value: 'CEMA.L',
    label:
      'CEMA.L,  iShares VII PLC - iShares MSCI EM Asia UCITS ETF USD (Acc)',
    name: 'iShares VII PLC - iShares MSCI EM Asia UCITS ETF USD (Acc)',
    symbol: 'CEMA.L',
  },
  {
    value: 'CEU1.L',
    label:
      'CEU1.L,  iShares VII PLC -iShares Core MSCI EMU UCITS ETF EUR (Acc)',
    name: 'iShares VII PLC -iShares Core MSCI EMU UCITS ETF EUR (Acc)',
    symbol: 'CEU1.L',
  },
  {
    value: 'CHIN.L',
    label: 'CHIN.L,  ICBC Credit Suisse WisdomTree S&P China 500 UCITS ETF',
    name: 'ICBC Credit Suisse WisdomTree S&P China 500 UCITS ETF',
    symbol: 'CHIN.L',
  },
  {
    value: 'CHTE.L',
    label: 'CHTE.L,  UBS ETF - Solactive China Technology UCITS ETF',
    name: 'UBS ETF - Solactive China Technology UCITS ETF',
    symbol: 'CHTE.L',
  },
  {
    value: 'CI2G.L',
    label: 'CI2G.L,  Amundi Index Solutions - Amundi MSCI India',
    name: 'Amundi Index Solutions - Amundi MSCI India',
    symbol: 'CI2G.L',
  },
  {
    value: 'CI2U.L',
    label: 'CI2U.L,  Amundi Index Solutions - Amundi MSCI India',
    name: 'Amundi Index Solutions - Amundi MSCI India',
    symbol: 'CI2U.L',
  },
  {
    value: 'CJ1G.L',
    label: 'CJ1G.L,  Amundi Index Solutions - Amundi Index MSCI Japan',
    name: 'Amundi Index Solutions - Amundi Index MSCI Japan',
    symbol: 'CJ1G.L',
  },
  {
    value: 'USIM3.SA',
    label: 'USIM3.SA,  Usinas Siderúrgicas de Minas Gerais S.A.',
    name: 'Usinas Siderúrgicas de Minas Gerais S.A.',
    symbol: 'USIM3.SA',
  },
  {
    value: 'CJ1U.L',
    label: 'CJ1U.L,  Amundi Index Solutions - Amundi Index MSCI Japan',
    name: 'Amundi Index Solutions - Amundi Index MSCI Japan',
    symbol: 'CJ1U.L',
  },
  {
    value: 'CLEM.L',
    label: 'CLEM.L,  Lyxor MSCI EM ESG Climate Transition CTB UCITS ETF - Acc',
    name: 'Lyxor MSCI EM ESG Climate Transition CTB UCITS ETF - Acc',
    symbol: 'CLEM.L',
  },
  {
    value: 'CLO.L',
    label: 'CLO.L,  Global X Cloud Computing UCITS ETF',
    name: 'Global X Cloud Computing UCITS ETF',
    symbol: 'CLO.L',
  },
  {
    value: 'CLUS.L',
    label: 'CLUS.L,  Lyxor MSCI USA ESG Climate Transition CTB (DR) UCITS ETF',
    name: 'Lyxor MSCI USA ESG Climate Transition CTB (DR) UCITS ETF',
    symbol: 'CLUS.L',
  },
  {
    value: 'CLWD.L',
    label: 'CLWD.L,  Lyxor Msci World Climate Change (Dr) Ucits ETF',
    name: 'Lyxor Msci World Climate Change (Dr) Ucits ETF',
    symbol: 'CLWD.L',
  },
  {
    value: 'CMXC.L',
    label: 'CMXC.L,  iShares VII PLC - iShares MSCI Mexico Capped UCITS ETF',
    name: 'iShares VII PLC - iShares MSCI Mexico Capped UCITS ETF',
    symbol: 'CMXC.L',
  },
  {
    value: 'CNAA.L',
    label: 'CNAA.L,  Lyxor MSCI China A (DR) UCITS ETF',
    name: 'Lyxor MSCI China A (DR) UCITS ETF',
    symbol: 'CNAA.L',
  },
  {
    value: 'CNAL.L',
    label: 'CNAL.L,  Lyxor MSCI China A (DR) UCITS ETF',
    name: 'Lyxor MSCI China A (DR) UCITS ETF',
    symbol: 'CNAL.L',
  },
  {
    value: 'CNEW.L',
    label: 'CNEW.L,  VanEck New China ESG UCITS ETF',
    name: 'VanEck New China ESG UCITS ETF',
    symbol: 'CNEW.L',
  },
  {
    value: 'CNUA.L',
    label: 'CNUA.L,  UBS (Irl) Fund Solutions plc - MSCI China A SF UCITS ETF',
    name: 'UBS (Irl) Fund Solutions plc - MSCI China A SF UCITS ETF',
    symbol: 'CNUA.L',
  },
  {
    value: 'CODO.L',
    label: 'CODO.L,  WisdomTree AT1 CoCo Bond UCITS ETF - USD Hedged',
    name: 'WisdomTree AT1 CoCo Bond UCITS ETF - USD Hedged',
    symbol: 'CODO.L',
  },
  {
    value: 'COGO.L',
    label: 'COGO.L,  WisdomTree AT1 CoCo Bond UCITS ETF',
    name: 'WisdomTree AT1 CoCo Bond UCITS ETF',
    symbol: 'COGO.L',
  },
  {
    value: 'COPX.L',
    label: 'COPX.L,  Global X Copper Miners UCITS ETF',
    name: 'Global X Copper Miners UCITS ETF',
    symbol: 'COPX.L',
  },
  {
    value: 'CP9G.L',
    label:
      'CP9G.L,  Amundi IndEX Solutions - Amundi IndEX MSCI Pacific EX Japan SRI PAB Fund',
    name: 'Amundi IndEX Solutions - Amundi IndEX MSCI Pacific EX Japan SRI PAB Fund',
    symbol: 'CP9G.L',
  },
  {
    value: 'CP9U.L',
    label:
      'CP9U.L,  Amundi IndEX Solutions - Amundi IndEX MSCI Pacific EX Japan SRI PAB Fund',
    name: 'Amundi IndEX Solutions - Amundi IndEX MSCI Pacific EX Japan SRI PAB Fund',
    symbol: 'CP9U.L',
  },
  {
    value: 'CPXJ.L',
    label:
      'CPXJ.L,  iShares VII PLC - iShares Core MSCI Pacific ex-Japan UCITS ETF',
    name: 'iShares VII PLC - iShares Core MSCI Pacific ex-Japan UCITS ETF',
    symbol: 'CPXJ.L',
  },
  {
    value: 'CRAL.L',
    label:
      'CRAL.L,  Lyxor Bloomberg Equal-weight Commodity ex-Agriculture UCITS ETF',
    name: 'Lyxor Bloomberg Equal-weight Commodity ex-Agriculture UCITS ETF',
    symbol: 'CRAL.L',
  },
  {
    value: 'CRBU.L',
    label:
      'CRBU.L,  MULTI-UNITS LUXEMBOURG - Lyxor Commodities Refinitiv/CoreCommodity CRB TR UCITS ETF',
    name: 'MULTI-UNITS LUXEMBOURG - Lyxor Commodities Refinitiv/CoreCommodity CRB TR UCITS ETF',
    symbol: 'CRBU.L',
  },
  {
    value: 'CRNO.L',
    label:
      'CRNO.L,  MULTI-UNITS LUXEMBOURG - Lyxor Commodities Refinitiv/CoreCommodity CRB EX-Energy TR UCITS ETF',
    name: 'MULTI-UNITS LUXEMBOURG - Lyxor Commodities Refinitiv/CoreCommodity CRB EX-Energy TR UCITS ETF',
    symbol: 'CRNO.L',
  },
  {
    value: 'CRWU.L',
    label:
      'CRWU.L,  Ossiam Risk Weighted Enhanced Commodity Ex Grains TR UCITS ETF 1C(USD)',
    name: 'Ossiam Risk Weighted Enhanced Commodity Ex Grains TR UCITS ETF 1C(USD)',
    symbol: 'CRWU.L',
  },
  {
    value: 'CSJP.L',
    label: 'CSJP.L,  iShares VII PLC - iShares MSCI Japan UCITS ETF USD (Acc)',
    name: 'iShares VII PLC - iShares MSCI Japan UCITS ETF USD (Acc)',
    symbol: 'CSJP.L',
  },
  {
    value: 'CSUS.L',
    label: 'CSUS.L,  iShares VII PLC - iShares MSCI USA UCITS ETF USD (Acc)',
    name: 'iShares VII PLC - iShares MSCI USA UCITS ETF USD (Acc)',
    symbol: 'CSUS.L',
  },
  {
    value: 'CSWU.L',
    label: 'CSWU.L,  Amundi Index Solutions - Amundi MSCI Switzerland',
    name: 'Amundi Index Solutions - Amundi MSCI Switzerland',
    symbol: 'CSWU.L',
  },
  {
    value: 'CTEK.L',
    label: 'CTEK.L,  Global X CleanTech UCITS ETF',
    name: 'Global X CleanTech UCITS ETF',
    symbol: 'CTEK.L',
  },
  {
    value: 'CU2G.L',
    label: 'CU2G.L,  Amundi Index Solutions - Amundi MSCI USA',
    name: 'Amundi Index Solutions - Amundi MSCI USA',
    symbol: 'CU2G.L',
  },
  {
    value: 'CU2U.L',
    label: 'CU2U.L,  Amundi Index Solutions - Amundi MSCI USA',
    name: 'Amundi Index Solutions - Amundi MSCI USA',
    symbol: 'CU2U.L',
  },
  {
    value: 'CU31.L',
    label:
      'CU31.L,  iShares VII PLC - iShares $ Treasury Bond 1-3yr UCITS ETF USD (Acc)',
    name: 'iShares VII PLC - iShares $ Treasury Bond 1-3yr UCITS ETF USD (Acc)',
    symbol: 'CU31.L',
  },
  {
    value: 'CUS1.L',
    label:
      'CUS1.L,  iShares VII PLC - iShares MSCI USA Small Cap ESG Enhanced UCITS ETF USD (Acc)',
    name: 'iShares VII PLC - iShares MSCI USA Small Cap ESG Enhanced UCITS ETF USD (Acc)',
    symbol: 'CUS1.L',
  },
  {
    value: 'CW8G.L',
    label: 'CW8G.L,  Amundi Index Solutions - Amundi MSCI World UCITS ETF',
    name: 'Amundi Index Solutions - Amundi MSCI World UCITS ETF',
    symbol: 'CW8G.L',
  },
  {
    value: 'CW8U.L',
    label: 'CW8U.L,  Amundi Index Solutions - Amundi MSCI World UCITS ETF',
    name: 'Amundi Index Solutions - Amundi MSCI World UCITS ETF',
    symbol: 'CW8U.L',
  },
  {
    value: 'CWEG.L',
    label: 'CWEG.L,  Amundi Index Solutions - Amundi MSCI World Energy',
    name: 'Amundi Index Solutions - Amundi MSCI World Energy',
    symbol: 'CWEG.L',
  },
  {
    value: 'CWEU.L',
    label: 'CWEU.L,  Amundi Index Solutions - Amundi MSCI World Energy',
    name: 'Amundi Index Solutions - Amundi MSCI World Energy',
    symbol: 'CWEU.L',
  },
  {
    value: 'CWFG.L',
    label: 'CWFG.L,  Amundi Index Solutions - Amundi MSCI World Financials',
    name: 'Amundi Index Solutions - Amundi MSCI World Financials',
    symbol: 'CWFG.L',
  },
  {
    value: 'CWFU.L',
    label: 'CWFU.L,  Amundi Index Solutions - Amundi MSCI World Financials',
    name: 'Amundi Index Solutions - Amundi MSCI World Financials',
    symbol: 'CWFU.L',
  },
  {
    value: 'CXAP.L',
    label:
      'CXAP.L,  UBS (Irl) Fund Solutions plc - CMCI Ex-Agriculture SF UCITs ETF',
    name: 'UBS (Irl) Fund Solutions plc - CMCI Ex-Agriculture SF UCITs ETF',
    symbol: 'CXAP.L',
  },
  {
    value: 'CXAU.L',
    label:
      'CXAU.L,  UBS (Irl) Fund Solutions plc - CMCI Ex-Agriculture SF UCITs ETF',
    name: 'UBS (Irl) Fund Solutions plc - CMCI Ex-Agriculture SF UCITs ETF',
    symbol: 'CXAU.L',
  },
  {
    value: 'CYBR.L',
    label:
      'CYBR.L,  Rize UCITS ICAV - Rize Cybersecurity Data Privacy UCITS ETF',
    name: 'Rize UCITS ICAV - Rize Cybersecurity Data Privacy UCITS ETF',
    symbol: 'CYBR.L',
  },
  {
    value: 'DDBG.L',
    label: 'DDBG.L,  iShares $ Development Bank Bonds UCITS ETF (Acc)',
    name: 'iShares $ Development Bank Bonds UCITS ETF (Acc)',
    symbol: 'DDBG.L',
  },
  {
    value: 'DEMD.L',
    label: 'DEMD.L,  WisdomTree Emerging Markets Equity Income UCITS ETF',
    name: 'WisdomTree Emerging Markets Equity Income UCITS ETF',
    symbol: 'DEMD.L',
  },
  {
    value: 'DEMR.L',
    label: 'DEMR.L,  WisdomTree Emerging Markets Equity Income UCITS ETF',
    name: 'WisdomTree Emerging Markets Equity Income UCITS ETF',
    symbol: 'DEMR.L',
  },
  {
    value: 'DEMS.L',
    label: 'DEMS.L,  WisdomTree Emerging Markets Equity Income UCITS ETF',
    name: 'WisdomTree Emerging Markets Equity Income UCITS ETF',
    symbol: 'DEMS.L',
  },
  {
    value: 'DFDU.L',
    label:
      'DFDU.L,  FlexShares Developed Markets High Dividend Climate ESG UCITS ETF',
    name: 'FlexShares Developed Markets High Dividend Climate ESG UCITS ETF',
    symbol: 'DFDU.L',
  },
  {
    value: 'DGRG.L',
    label: 'DGRG.L,  WisdomTree US Quality Dividend Growth UCITS ETF',
    name: 'WisdomTree US Quality Dividend Growth UCITS ETF',
    symbol: 'DGRG.L',
  },
  {
    value: 'DGRP.L',
    label: 'DGRP.L,  WisdomTree US Quality Dividend Growth UCITS ETF',
    name: 'WisdomTree US Quality Dividend Growth UCITS ETF',
    symbol: 'DGRP.L',
  },
  {
    value: 'DGSD.L',
    label: 'DGSD.L,  WisdomTree Emerging Markets SmallCap Dividend UCITS ETF',
    name: 'WisdomTree Emerging Markets SmallCap Dividend UCITS ETF',
    symbol: 'DGSD.L',
  },
  {
    value: 'DHSA.L',
    label: 'DHSA.L,  WisdomTree US Equity Income UCITS ETF - Acc',
    name: 'WisdomTree US Equity Income UCITS ETF - Acc',
    symbol: 'DHSA.L',
  },
  {
    value: 'DHSD.L',
    label: 'DHSD.L,  WisdomTree US Equity Income UCITS ETF',
    name: 'WisdomTree US Equity Income UCITS ETF',
    symbol: 'DHSD.L',
  },
  {
    value: 'DHSG.L',
    label: 'DHSG.L,  WisdomTree US Equity Income UCITS ETF',
    name: 'WisdomTree US Equity Income UCITS ETF',
    symbol: 'DHSG.L',
  },
  {
    value: 'DHSP.L',
    label: 'DHSP.L,  WisdomTree US Equity Income UCITS ETF - Acc',
    name: 'WisdomTree US Equity Income UCITS ETF - Acc',
    symbol: 'DHSP.L',
  },
  {
    value: 'DHYA.L',
    label: 'DHYA.L,  iShares $ High Yield Corp Bond ESG UCITS ETF USD (Acc)',
    name: 'iShares $ High Yield Corp Bond ESG UCITS ETF USD (Acc)',
    symbol: 'DHYA.L',
  },
  {
    value: 'DIGE.L',
    label:
      'DIGE.L,  Lyxor Index Fund - Lyxor MSCI Digital Economy ESG Filtered (DR) UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor MSCI Digital Economy ESG Filtered (DR) UCITS ETF',
    symbol: 'DIGE.L',
  },
  {
    value: 'DIGI.L',
    label: 'DIGI.L,  Digital Infrastructure and Connectivity UCITS ETF',
    name: 'Digital Infrastructure and Connectivity UCITS ETF',
    symbol: 'DIGI.L',
  },
  {
    value: 'DJEU.L',
    label: 'DJEU.L,  Lyxor Dow Jones Industrial Average UCITS ETF',
    name: 'Lyxor Dow Jones Industrial Average UCITS ETF',
    symbol: 'DJEU.L',
  },
  {
    value: 'DLTM.L',
    label: 'DLTM.L,  iShares MSCI EM Latin America UCITS ETF',
    name: 'iShares MSCI EM Latin America UCITS ETF',
    symbol: 'DLTM.L',
  },
  {
    value: 'DPAY.L',
    label:
      'DPAY.L,  Legal & General UCITS ETF Plc - L&G Digital Payments UCITS ETF',
    name: 'Legal & General UCITS ETF Plc - L&G Digital Payments UCITS ETF',
    symbol: 'DPAY.L',
  },
  {
    value: 'DRGN.L',
    label: 'DRGN.L,  L&G ESG China CNY Bond UCITS ETF',
    name: 'L&G ESG China CNY Bond UCITS ETF',
    symbol: 'DRGN.L',
  },
  {
    value: 'DRVE.L',
    label: 'DRVE.L,  Global X Autonomous & Electric Vehicles UCITS ETF',
    name: 'Global X Autonomous & Electric Vehicles UCITS ETF',
    symbol: 'DRVE.L',
  },
  {
    value: 'DRVG.L',
    label: 'DRVG.L,  Global X Autonomous & Electric Vehicles UCITS ETF',
    name: 'Global X Autonomous & Electric Vehicles UCITS ETF',
    symbol: 'DRVG.L',
  },
  {
    value: 'DTEC.L',
    label:
      'DTEC.L,  Lyxor Index Fund - Lyxor MSCI Disruptive Technology ESG Filtered (DR) UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor MSCI Disruptive Technology ESG Filtered (DR) UCITS ETF',
    symbol: 'DTEC.L',
  },
  {
    value: 'DTRE.L',
    label:
      'DTRE.L,  First Trust Alerian Disruptive Technology Real Estate UCITS ETF',
    name: 'First Trust Alerian Disruptive Technology Real Estate UCITS ETF',
    symbol: 'DTRE.L',
  },
  {
    value: 'DXJA.L',
    label: 'DXJA.L,  WisdomTree Japan Equity UCITS ETF',
    name: 'WisdomTree Japan Equity UCITS ETF',
    symbol: 'DXJA.L',
  },
  {
    value: 'DXJG.L',
    label: 'DXJG.L,  WisdomTree Japan Equity UCITS ETF',
    name: 'WisdomTree Japan Equity UCITS ETF',
    symbol: 'DXJG.L',
  },
  {
    value: 'DXJZ.L',
    label: 'DXJZ.L,  WisdomTree Japan Equity UCITS ETF',
    name: 'WisdomTree Japan Equity UCITS ETF',
    symbol: 'DXJZ.L',
  },
  {
    value: 'E127.L',
    label: 'E127.L,  Lyxor MSCI Emerging Markets (LUX) UCITS ETF',
    name: 'Lyxor MSCI Emerging Markets (LUX) UCITS ETF',
    symbol: 'E127.L',
  },
  {
    value: 'EAHG.L',
    label: 'EAHG.L,  Amundi Index Solutions - Amundi Index Euro AGG SRI',
    name: 'Amundi Index Solutions - Amundi Index Euro AGG SRI',
    symbol: 'EAHG.L',
  },
  {
    value: 'EART.L',
    label: 'EART.L,  Lyxor Euro Government Green Bond (DR) UCITS ETF',
    name: 'Lyxor Euro Government Green Bond (DR) UCITS ETF',
    symbol: 'EART.L',
  },
  {
    value: 'EBIZ.L',
    label: 'EBIZ.L,  Global X E-commerce UCITS ETF',
    name: 'Global X E-commerce UCITS ETF',
    symbol: 'EBIZ.L',
  },
  {
    value: 'EBUY.L',
    label:
      'EBUY.L,  Lyxor Index Fund - Lyxor MSCI Digital Economy ESG Filtered (DR) UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor MSCI Digital Economy ESG Filtered (DR) UCITS ETF',
    symbol: 'EBUY.L',
  },
  {
    value: 'ECOM.L',
    label: 'ECOM.L,  L&G Ecommerce Logistics UCITS ETF',
    name: 'L&G Ecommerce Logistics UCITS ETF',
    symbol: 'ECOM.L',
  },
  {
    value: 'ECRP.L',
    label:
      'ECRP.L,  Amundi Index Solutions - Amundi Index Euro Corporate SRI UCITS ETF DR',
    name: 'Amundi Index Solutions - Amundi Index Euro Corporate SRI UCITS ETF DR',
    symbol: 'ECRP.L',
  },
  {
    value: 'EDIV.L',
    label: 'EDIV.L,  Lyxor SG European Quality Income NTR (DR) UCITS ETF',
    name: 'Lyxor SG European Quality Income NTR (DR) UCITS ETF',
    symbol: 'EDIV.L',
  },
  {
    value: 'USIM6.SA',
    label: 'USIM6.SA,  Usinas Siderúrgicas de Minas Gerais S.A.',
    name: 'Usinas Siderúrgicas de Minas Gerais S.A.',
    symbol: 'USIM6.SA',
  },
  {
    value: 'EDVD.L',
    label: 'EDVD.L,  SPDR S&P Emerging Markets Dividend Aristocrats UCITS ETF',
    name: 'SPDR S&P Emerging Markets Dividend Aristocrats UCITS ETF',
    symbol: 'EDVD.L',
  },
  {
    value: 'EEDG.L',
    label: 'EEDG.L,  iShares MSCI USA ESG Enhanced UCITS ETF',
    name: 'iShares MSCI USA ESG Enhanced UCITS ETF',
    symbol: 'EEDG.L',
  },
  {
    value: 'EEIA.L',
    label: 'EEIA.L,  WisdomTree Europe Equity Income UCITS ETF',
    name: 'WisdomTree Europe Equity Income UCITS ETF',
    symbol: 'EEIA.L',
  },
  {
    value: 'EEJG.L',
    label: 'EEJG.L,  iShares MSCI Japan ESG Enhanced UCITS ETF',
    name: 'iShares MSCI Japan ESG Enhanced UCITS ETF',
    symbol: 'EEJG.L',
  },
  {
    value: 'EEWD.L',
    label: 'EEWD.L,  iShares MSCI World ESG Enhanced UCITS ETF',
    name: 'iShares MSCI World ESG Enhanced UCITS ETF',
    symbol: 'EEWD.L',
  },
  {
    value: 'EGOG.L',
    label:
      'EGOG.L,  UBS (Lux) Fund Solutions – J.P. Morgan Global Government ESG Liquid Bond UCITS ETF',
    name: 'UBS (Lux) Fund Solutions – J.P. Morgan Global Government ESG Liquid Bond UCITS ETF',
    symbol: 'EGOG.L',
  },
  {
    value: 'EGOV.L',
    label:
      'EGOV.L,  UBS (Lux) Fund Solutions – J.P. Morgan Global Government ESG Liquid Bond UCITS ETF',
    name: 'UBS (Lux) Fund Solutions – J.P. Morgan Global Government ESG Liquid Bond UCITS ETF',
    symbol: 'EGOV.L',
  },
  {
    value: 'EGRA.L',
    label: 'EGRA.L,  WisdomTree Eurozone Quality Dividend Growth UCITS ETF',
    name: 'WisdomTree Eurozone Quality Dividend Growth UCITS ETF',
    symbol: 'EGRA.L',
  },
  {
    value: 'EGRP.L',
    label: 'EGRP.L,  WisdomTree Eurozone Quality Dividend Growth UCITS ETF',
    name: 'WisdomTree Eurozone Quality Dividend Growth UCITS ETF',
    symbol: 'EGRP.L',
  },
  {
    value: 'EGRW.L',
    label: 'EGRW.L,  WisdomTree Eurozone Quality Dividend Growth UCITS ETF',
    name: 'WisdomTree Eurozone Quality Dividend Growth UCITS ETF',
    symbol: 'EGRW.L',
  },
  {
    value: 'EHYB.L',
    label: 'EHYB.L,  Invesco Euro Corporate Hybrid Bond UCITS ETF',
    name: 'Invesco Euro Corporate Hybrid Bond UCITS ETF',
    symbol: 'EHYB.L',
  },
  {
    value: 'ELCR.L',
    label:
      'ELCR.L,  Lyxor Index Fund - Lyxor MSCI Future Mobility ESG Filtered (DR) UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor MSCI Future Mobility ESG Filtered (DR) UCITS ETF',
    symbol: 'ELCR.L',
  },
  {
    value: 'ELEC.L',
    label: 'ELEC.L,  Electric Vehicle Charging Infrastructure UCITS ETF',
    name: 'Electric Vehicle Charging Infrastructure UCITS ETF',
    symbol: 'ELEC.L',
  },
  {
    value: 'EMAD.L',
    label: 'EMAD.L,  SPDR MSCI EM Asia UCITS ETF',
    name: 'SPDR MSCI EM Asia UCITS ETF',
    symbol: 'EMAD.L',
  },
  {
    value: 'EMAU.L',
    label: 'EMAU.L,  L&G ESG Emerging Markets Corporate Bond (USD) UCITS ETF',
    name: 'L&G ESG Emerging Markets Corporate Bond (USD) UCITS ETF',
    symbol: 'EMAU.L',
  },
  {
    value: 'EMCA.L',
    label:
      'EMCA.L,  iShares V PLC - iShares J.P. Morgan $ EM Corp Bond UCITS ETF',
    name: 'iShares V PLC - iShares J.P. Morgan $ EM Corp Bond UCITS ETF',
    symbol: 'EMCA.L',
  },
  {
    value: 'EMCP.L',
    label:
      'EMCP.L,  iShares V PLC - iShares J.P. Morgan $ EM Corp Bond UCITS ETF',
    name: 'iShares V PLC - iShares J.P. Morgan $ EM Corp Bond UCITS ETF',
    symbol: 'EMCP.L',
  },
  {
    value: 'WHRL3.SA',
    label: 'WHRL3.SA,  Whirlpool S.A.',
    name: 'Whirlpool S.A.',
    symbol: 'WHRL3.SA',
  },
  {
    value: 'EMD5.L',
    label:
      'EMD5.L,  L&G ESG Emerging Markets Government Bond (USD) 0-5 Year UCITS ETF',
    name: 'L&G ESG Emerging Markets Government Bond (USD) 0-5 Year UCITS ETF',
    symbol: 'EMD5.L',
  },
  {
    value: 'EMDD.L',
    label:
      'EMDD.L,  SPDR Bloomberg Barclays Emerging Markets Local Bond UCITS ETF (Dist)',
    name: 'SPDR Bloomberg Barclays Emerging Markets Local Bond UCITS ETF (Dist)',
    symbol: 'EMDD.L',
  },
  {
    value: 'EMDG.L',
    label:
      'EMDG.L,  L&G ESG Emerging Markets Government Bond (USD) 0-5 Year UCITS ETF',
    name: 'L&G ESG Emerging Markets Government Bond (USD) 0-5 Year UCITS ETF',
    symbol: 'EMDG.L',
  },
  {
    value: 'EMDH.L',
    label: 'EMDH.L,  L&G ESG Emerging Markets Corporate Bond (USD) UCITS ETF',
    name: 'L&G ESG Emerging Markets Corporate Bond (USD) UCITS ETF',
    symbol: 'EMDH.L',
  },
  {
    value: 'EMH5.L',
    label: 'EMH5.L,  SPDR ICE BofA 0-5 Year EM USD Government Bond UCITS ETF',
    name: 'SPDR ICE BofA 0-5 Year EM USD Government Bond UCITS ETF',
    symbol: 'EMH5.L',
  },
  {
    value: 'EMHD.L',
    label:
      'EMHD.L,  Invesco FTSE Emerging Markets High Dividend Low Volatility UCITS ETF',
    name: 'Invesco FTSE Emerging Markets High Dividend Low Volatility UCITS ETF',
    symbol: 'EMHD.L',
  },
  {
    value: 'EMID.L',
    label: 'EMID.L,  iShares MSCI Europe Mid Cap UCITS ETF',
    name: 'iShares MSCI Europe Mid Cap UCITS ETF',
    symbol: 'EMID.L',
  },
  {
    value: 'EMIG.L',
    label:
      'EMIG.L,  UBS(Lux)Fund Solutions – J.P. Morgan USD EM IG ESG Diversified Bond UCITS ETF(USD)A-acc',
    name: 'UBS(Lux)Fund Solutions – J.P. Morgan USD EM IG ESG Diversified Bond UCITS ETF(USD)A-acc',
    symbol: 'EMIG.L',
  },
  {
    value: 'EMIL.L',
    label:
      'EMIL.L,  SPDR Bloomberg EM Inflation Linked Local Bond UCITS ETF Dist',
    name: 'SPDR Bloomberg EM Inflation Linked Local Bond UCITS ETF Dist',
    symbol: 'EMIL.L',
  },
  {
    value: 'EMLB.L',
    label:
      'EMLB.L,  PIMCO ETFs plc PIMCO Emerging Markets Advantage Local Bond UCITS ETF',
    name: 'PIMCO ETFs plc PIMCO Emerging Markets Advantage Local Bond UCITS ETF',
    symbol: 'EMLB.L',
  },
  {
    value: 'EMLC.L',
    label: 'EMLC.L,  VanEck J.P. Morgan EM Local Currency Bond UCITS ETF',
    name: 'VanEck J.P. Morgan EM Local Currency Bond UCITS ETF',
    symbol: 'EMLC.L',
  },
  {
    value: 'EMLI.L',
    label:
      'EMLI.L,  PIMCO ETFs plc PIMCO Emerging Markets Advantage Local Bond UCITS ETF',
    name: 'PIMCO ETFs plc PIMCO Emerging Markets Advantage Local Bond UCITS ETF',
    symbol: 'EMLI.L',
  },
  {
    value: 'EMRD.L',
    label: 'EMRD.L,  SPDR MSCI Emerging Markets UCITS ETF',
    name: 'SPDR MSCI Emerging Markets UCITS ETF',
    symbol: 'EMRD.L',
  },
  {
    value: 'EMSA.L',
    label: 'EMSA.L,  iShares J.P. Morgan ESG $ EM Bond UCITS ETF',
    name: 'iShares J.P. Morgan ESG $ EM Bond UCITS ETF',
    symbol: 'EMSA.L',
  },
  {
    value: 'EMSD.L',
    label: 'EMSD.L,  SPDR MSCI Emerging Markets Small Cap UCITS ETF',
    name: 'SPDR MSCI Emerging Markets Small Cap UCITS ETF',
    symbol: 'EMSD.L',
  },
  {
    value: 'EMUU.L',
    label:
      'EMUU.L,  iShares VII PLC - iShares MSCI EMU USD Hedged UCITS ETF (Acc)',
    name: 'iShares VII PLC - iShares MSCI EMU USD Hedged UCITS ETF (Acc)',
    symbol: 'EMUU.L',
  },
  {
    value: 'EMXU.L',
    label:
      'EMXU.L,  Amundi MSCI Emerging Ex China ESG Leaders Select UCITS ETF',
    name: 'Amundi MSCI Emerging Ex China ESG Leaders Select UCITS ETF',
    symbol: 'EMXU.L',
  },
  {
    value: 'ENCG.L',
    label: 'ENCG.L,  L&G Multi-Strategy Enhanced Commodities UCITS ETF',
    name: 'L&G Multi-Strategy Enhanced Commodities UCITS ETF',
    symbol: 'ENCG.L',
  },
  {
    value: 'ENCO.L',
    label: 'ENCO.L,  L&G Multi-Strategy Enhanced Commodities UCITS ETF',
    name: 'L&G Multi-Strategy Enhanced Commodities UCITS ETF',
    symbol: 'ENCO.L',
  },
  {
    value: 'EQQD.L',
    label: 'EQQD.L,  Invesco NASDAQ-100 Swap UCITS ETF',
    name: 'Invesco NASDAQ-100 Swap UCITS ETF',
    symbol: 'EQQD.L',
  },
  {
    value: 'ERNE.L',
    label: 'ERNE.L,  iShares € Ultrashort Bond UCITS ETF',
    name: 'iShares € Ultrashort Bond UCITS ETF',
    symbol: 'ERNE.L',
  },
  {
    value: 'ESDG.L',
    label: 'ESDG.L,  Amundi Index Solutions - Amundi MSCI Europe SRI PAB',
    name: 'Amundi Index Solutions - Amundi MSCI Europe SRI PAB',
    symbol: 'ESDG.L',
  },
  {
    value: 'ESDU.L',
    label: 'ESDU.L,  Amundi Index Solutions - Amundi MSCI Europe SRI PAB',
    name: 'Amundi Index Solutions - Amundi MSCI Europe SRI PAB',
    symbol: 'ESDU.L',
  },
  {
    value: 'ESEM.L',
    label:
      'ESEM.L,  Invesco MSCI Emerging Markets ESG Universal Screened UCITS ETF',
    name: 'Invesco MSCI Emerging Markets ESG Universal Screened UCITS ETF',
    symbol: 'ESEM.L',
  },
  {
    value: 'ESES.L',
    label:
      'ESES.L,  Invesco MSCI Emerging Markets ESG Universal Screened UCITS ETF',
    name: 'Invesco MSCI Emerging Markets ESG Universal Screened UCITS ETF',
    symbol: 'ESES.L',
  },
  {
    value: 'ESGE.L',
    label: 'ESGE.L,  Invesco MSCI Europe ESG Universal Screened UCITS ETF',
    name: 'Invesco MSCI Europe ESG Universal Screened UCITS ETF',
    symbol: 'ESGE.L',
  },
  {
    value: 'ESGG.L',
    label: 'ESGG.L,  Invesco MSCI World ESG Universal Screened UCITS ETF',
    name: 'Invesco MSCI World ESG Universal Screened UCITS ETF',
    symbol: 'ESGG.L',
  },
  {
    value: 'ESGJ.L',
    label:
      'ESGJ.L,  Invesco Markets II plc - Invesco MSCI Japan ESG Universal Screened UCITS ETF',
    name: 'Invesco Markets II plc - Invesco MSCI Japan ESG Universal Screened UCITS ETF',
    symbol: 'ESGJ.L',
  },
  {
    value: 'ESGU.L',
    label: 'ESGU.L,  Invesco MSCI USA ESG Universal Screened UCITS ETF',
    name: 'Invesco MSCI USA ESG Universal Screened UCITS ETF',
    symbol: 'ESGU.L',
  },
  {
    value: 'ESIN.L',
    label: 'ESIN.L,  iShares MSCI Europe Industrials Sector UCITS ETF',
    name: 'iShares MSCI Europe Industrials Sector UCITS ETF',
    symbol: 'ESIN.L',
  },
  {
    value: 'ESIS.L',
    label: 'ESIS.L,  iShares MSCI Europe Consumer Staples Sector UCITS ETF',
    name: 'iShares MSCI Europe Consumer Staples Sector UCITS ETF',
    symbol: 'ESIS.L',
  },
  {
    value: 'ESIT.L',
    label:
      'ESIT.L,  iShares MSCI Europe Information Technology Sector UCITS ETF',
    name: 'iShares MSCI Europe Information Technology Sector UCITS ETF',
    symbol: 'ESIT.L',
  },
  {
    value: 'ESJS.L',
    label:
      'ESJS.L,  Invesco Markets II plc - Invesco MSCI Japan ESG Universal Screened UCITS ETF',
    name: 'Invesco Markets II plc - Invesco MSCI Japan ESG Universal Screened UCITS ETF',
    symbol: 'ESJS.L',
  },
  {
    value: 'ESPS.L',
    label:
      'ESPS.L,  Invesco Markets II plc - Invesco MSCI Pacific Ex Japan ESG Universal Screened UCITS ETF',
    name: 'Invesco Markets II plc - Invesco MSCI Pacific Ex Japan ESG Universal Screened UCITS ETF',
    symbol: 'ESPS.L',
  },
  {
    value: 'ESRG.L',
    label:
      'ESRG.L,  Amundi Index Solutions - Amundi MSCI Europe SRI UCITS ETF DR',
    name: 'Amundi Index Solutions - Amundi MSCI Europe SRI UCITS ETF DR',
    symbol: 'ESRG.L',
  },
  {
    value: 'ESRU.L',
    label:
      'ESRU.L,  Amundi Index Solutions - Amundi MSCI Europe SRI UCITS ETF DR',
    name: 'Amundi Index Solutions - Amundi MSCI Europe SRI UCITS ETF DR',
    symbol: 'ESRU.L',
  },
  {
    value: 'EUCO.L',
    label: 'EUCO.L,  SPDR Bloomberg Euro Corporate Bond UCITS ETF',
    name: 'SPDR Bloomberg Euro Corporate Bond UCITS ETF',
    symbol: 'EUCO.L',
  },
  {
    value: 'EUFM.L',
    label:
      'EUFM.L,  UBS (Lux) Fund Solutions – MSCI EMU Select Factor Mix UCITS ETF',
    name: 'UBS (Lux) Fund Solutions – MSCI EMU Select Factor Mix UCITS ETF',
    symbol: 'EUFM.L',
  },
  {
    value: 'EUHD.L',
    label: 'EUHD.L,  Invesco EURO STOXX High Dividend Low Volatility UCITS ETF',
    name: 'Invesco EURO STOXX High Dividend Low Volatility UCITS ETF',
    symbol: 'EUHD.L',
  },
  {
    value: 'EUPA.L',
    label: 'EUPA.L,  Franklin STOXX Europe 600 Paris Aligned Climate UCITS ETF',
    name: 'Franklin STOXX Europe 600 Paris Aligned Climate UCITS ETF',
    symbol: 'EUPA.L',
  },
  {
    value: 'EXCS.L',
    label: 'EXCS.L,  iShares MSCI EM ex China UCITS ETF',
    name: 'iShares MSCI EM ex China UCITS ETF',
    symbol: 'EXCS.L',
  },
  {
    value: 'FAGB.L',
    label: 'FAGB.L,  Invesco US High Yield Fallen Angels UCITS ETF',
    name: 'Invesco US High Yield Fallen Angels UCITS ETF',
    symbol: 'FAGB.L',
  },
  {
    value: 'FAHY.L',
    label: 'FAHY.L,  Invesco US High Yield Fallen Angels UCITS ETF',
    name: 'Invesco US High Yield Fallen Angels UCITS ETF',
    symbol: 'FAHY.L',
  },
  {
    value: 'FASA.L',
    label:
      'FASA.L,  Invesco Markets II plc - Invesco FTSE All Share ESG Climate UCITS ETF',
    name: 'Invesco Markets II plc - Invesco FTSE All Share ESG Climate UCITS ETF',
    symbol: 'FASA.L',
  },
  {
    value: 'FCBR.L',
    label: 'FCBR.L,  First Trust Nasdaq Cybersecurity UCITS ETF',
    name: 'First Trust Nasdaq Cybersecurity UCITS ETF',
    symbol: 'FCBR.L',
  },
  {
    value: 'FDNU.L',
    label: 'FDNU.L,  First Trust Dow Jones Internet UCITS ETF',
    name: 'First Trust Dow Jones Internet UCITS ETF',
    symbol: 'FDNU.L',
  },
  {
    value: 'FEDF.L',
    label: 'FEDF.L,  Lyxor Fed Funds US Dollar Cash UCITS ETF',
    name: 'Lyxor Fed Funds US Dollar Cash UCITS ETF',
    symbol: 'FEDF.L',
  },
  {
    value: 'FEDG.L',
    label: 'FEDG.L,  Lyxor Fed Funds US Dollar Cash UCITS ETF',
    name: 'Lyxor Fed Funds US Dollar Cash UCITS ETF',
    symbol: 'FEDG.L',
  },
  {
    value: 'FEME.L',
    label: 'FEME.L,  Fidelity Emerging Markets Quality Income UCITS ETF',
    name: 'Fidelity Emerging Markets Quality Income UCITS ETF',
    symbol: 'FEME.L',
  },
  {
    value: 'FEMI.L',
    label: 'FEMI.L,  Fidelity Emerging Markets Quality Income UCITS ETF',
    name: 'Fidelity Emerging Markets Quality Income UCITS ETF',
    symbol: 'FEMI.L',
  },
  {
    value: 'FEMS.L',
    label:
      'FEMS.L,  Fidelity Sustainable Research Enhanced Emerging Markets Equity UCITS ETF',
    name: 'Fidelity Sustainable Research Enhanced Emerging Markets Equity UCITS ETF',
    symbol: 'FEMS.L',
  },
  {
    value: 'FEMU.L',
    label: 'FEMU.L,  First Trust Emerging Markets AlphaDEX UCITS ETF',
    name: 'First Trust Emerging Markets AlphaDEX UCITS ETF',
    symbol: 'FEMU.L',
  },
  {
    value: 'WHRL4.SA',
    label: 'WHRL4.SA,  Whirlpool S.A.',
    name: 'Whirlpool S.A.',
    symbol: 'WHRL4.SA',
  },
  {
    value: 'FEUD.L',
    label: 'FEUD.L,  First Trust Eurozone AlphaDEX UCITS ETF',
    name: 'First Trust Eurozone AlphaDEX UCITS ETF',
    symbol: 'FEUD.L',
  },
  {
    value: 'FEXU.L',
    label: 'FEXU.L,  First Trust US Large Cap Core AlphaDEX UCITS ETF',
    name: 'First Trust US Large Cap Core AlphaDEX UCITS ETF',
    symbol: 'FEXU.L',
  },
  {
    value: 'FGLS.L',
    label:
      'FGLS.L,  Fidelity Sustainable Research Enhanced Global Equity UCITS ETF',
    name: 'Fidelity Sustainable Research Enhanced Global Equity UCITS ETF',
    symbol: 'FGLS.L',
  },
  {
    value: 'FGOV.L',
    label: 'FGOV.L,  First Trust Low Duration Global Government Bond UCITS ETF',
    name: 'First Trust Low Duration Global Government Bond UCITS ETF',
    symbol: 'FGOV.L',
  },
  {
    value: 'FGQI.L',
    label: 'FGQI.L,  Fidelity Global Quality Income ETF',
    name: 'Fidelity Global Quality Income ETF',
    symbol: 'FGQI.L',
  },
  {
    value: 'FING.L',
    label: 'FING.L,  Global X FinTech UCITS ETF',
    name: 'Global X FinTech UCITS ETF',
    symbol: 'FING.L',
  },
  {
    value: 'FINX.L',
    label: 'FINX.L,  Global X FinTech UCITS ETF',
    name: 'Global X FinTech UCITS ETF',
    symbol: 'FINX.L',
  },
  {
    value: 'FJPR.L',
    label:
      'FJPR.L,  Fidelity Sustainable Research Enhanced Japan Equity UCITS ETF',
    name: 'Fidelity Sustainable Research Enhanced Japan Equity UCITS ETF',
    symbol: 'FJPR.L',
  },
  {
    value: 'FKUD.L',
    label: 'FKUD.L,  First Trust United Kingdom AlphaDEX UCITS ETF',
    name: 'First Trust United Kingdom AlphaDEX UCITS ETF',
    symbol: 'FKUD.L',
  },
  {
    value: 'FLES.L',
    label: 'FLES.L,  Franklin Liberty Euro Short Maturity UCITS ETF',
    name: 'Franklin Liberty Euro Short Maturity UCITS ETF',
    symbol: 'FLES.L',
  },
  {
    value: 'FLO5.L',
    label: 'FLO5.L,  iShares $ Floating Rate Bond UCITS ETF',
    name: 'iShares $ Floating Rate Bond UCITS ETF',
    symbol: 'FLO5.L',
  },
  {
    value: 'FLPE.L',
    label: 'FLPE.L,  FlexShares Listed Private Equity UCITS ETF',
    name: 'FlexShares Listed Private Equity UCITS ETF',
    symbol: 'FLPE.L',
  },
  {
    value: 'FLQA.L',
    label: 'FLQA.L,  Franklin LibertyQ AC Asia ex Japan UCITS ETF',
    name: 'Franklin LibertyQ AC Asia ex Japan UCITS ETF',
    symbol: 'FLQA.L',
  },
  {
    value: 'FLRG.L',
    label: 'FLRG.L,  Franklin Liberty Euro Green Bond UCITS ETF',
    name: 'Franklin Liberty Euro Green Bond UCITS ETF',
    symbol: 'FLRG.L',
  },
  {
    value: 'FLXB.L',
    label: 'FLXB.L,  Franklin FTSE Brazil UCITS ETF',
    name: 'Franklin FTSE Brazil UCITS ETF',
    symbol: 'FLXB.L',
  },
  {
    value: 'FLXC.L',
    label: 'FLXC.L,  Franklin FTSE China UCITS ETF',
    name: 'Franklin FTSE China UCITS ETF',
    symbol: 'FLXC.L',
  },
  {
    value: 'FLXI.L',
    label: 'FLXI.L,  Franklin FTSE India UCITS ETF',
    name: 'Franklin FTSE India UCITS ETF',
    symbol: 'FLXI.L',
  },
  {
    value: 'FLXU.L',
    label: 'FLXU.L,  Franklin LibertyQ U.S. Equity UCITS ETF',
    name: 'Franklin LibertyQ U.S. Equity UCITS ETF',
    symbol: 'FLXU.L',
  },
  {
    value: 'FMQP.L',
    label: 'FMQP.L,  FMQQ Next Frontier Internet & Ecommerce ESG-S UCITS ETF',
    name: 'FMQQ Next Frontier Internet & Ecommerce ESG-S UCITS ETF',
    symbol: 'FMQP.L',
  },
  {
    value: 'FOOD.L',
    label: 'FOOD.L,  Rize Sustainable Future of Food UCITS ETF',
    name: 'Rize Sustainable Future of Food UCITS ETF',
    symbol: 'FOOD.L',
  },
  {
    value: 'FPXE.L',
    label: 'FPXE.L,  First Trust IPOX Europe Equity Opportunities UCITS ETF',
    name: 'First Trust IPOX Europe Equity Opportunities UCITS ETF',
    symbol: 'FPXE.L',
  },
  {
    value: 'FPXR.L',
    label:
      'FPXR.L,  Fidelity Sustainable Research Enhanced Pacific ex-Japan Equity UCITS ETF',
    name: 'Fidelity Sustainable Research Enhanced Pacific ex-Japan Equity UCITS ETF',
    symbol: 'FPXR.L',
  },
  {
    value: 'FREM.L',
    label: 'FREM.L,  Franklin LibertyQ Emerging Markets UCITS ETF',
    name: 'Franklin LibertyQ Emerging Markets UCITS ETF',
    symbol: 'FREM.L',
  },
  {
    value: 'FREQ.L',
    label: 'FREQ.L,  Franklin LibertyQ European Equity UCITS ETF',
    name: 'Franklin LibertyQ European Equity UCITS ETF',
    symbol: 'FREQ.L',
  },
  {
    value: 'FRGD.L',
    label: 'FRGD.L,  Franklin LibertyQ Global Dividend UCITS ETF',
    name: 'Franklin LibertyQ Global Dividend UCITS ETF',
    symbol: 'FRGD.L',
  },
  {
    value: 'FRGE.L',
    label: 'FRGE.L,  Franklin LibertyQ Global Equity SRI UCITS ETF',
    name: 'Franklin LibertyQ Global Equity SRI UCITS ETF',
    symbol: 'FRGE.L',
  },
  {
    value: 'FRUE.L',
    label: 'FRUE.L,  Franklin LibertyQ U.S. Equity UCITS ETF',
    name: 'Franklin LibertyQ U.S. Equity UCITS ETF',
    symbol: 'FRUE.L',
  },
  {
    value: 'FRXD.L',
    label: 'FRXD.L,  Franklin LibertyQ European Dividend UCITS ETF',
    name: 'Franklin LibertyQ European Dividend UCITS ETF',
    symbol: 'FRXD.L',
  },
  {
    value: 'FRXE.L',
    label: 'FRXE.L,  Franklin Liberty Euro Short Maturity UCITS ETF',
    name: 'Franklin Liberty Euro Short Maturity UCITS ETF',
    symbol: 'FRXE.L',
  },
  {
    value: 'FT1K.L',
    label: 'FT1K.L,  Amundi MSCI UK IMI SRI UCITS ETF DR - GBP',
    name: 'Amundi MSCI UK IMI SRI UCITS ETF DR - GBP',
    symbol: 'FT1K.L',
  },
  {
    value: 'FTEU.L',
    label: 'FTEU.L,  First Trust Eurozone AlphaDEX UCITS ETF',
    name: 'First Trust Eurozone AlphaDEX UCITS ETF',
    symbol: 'FTEU.L',
  },
  {
    value: 'FUSA.L',
    label: 'FUSA.L,  Fidelity US Quality Income ETF Acc',
    name: 'Fidelity US Quality Income ETF Acc',
    symbol: 'FUSA.L',
  },
  {
    value: 'FUSD.L',
    label: 'FUSD.L,  Fidelity US Quality Income ETF',
    name: 'Fidelity US Quality Income ETF',
    symbol: 'FUSD.L',
  },
  {
    value: 'FUSS.L',
    label:
      'FUSS.L,  Fidelity Sustainable Research Enhanced US Equity UCITS ETF',
    name: 'Fidelity Sustainable Research Enhanced US Equity UCITS ETF',
    symbol: 'FUSS.L',
  },
  {
    value: 'FVUG.L',
    label: 'FVUG.L,  Franklin Liberty Euro Green Bond UCITS ETF',
    name: 'Franklin Liberty Euro Green Bond UCITS ETF',
    symbol: 'FVUG.L',
  },
  {
    value: 'FXGB.L',
    label: 'FXGB.L,  First Trust FactorFX UCITS ETF',
    name: 'First Trust FactorFX UCITS ETF',
    symbol: 'FXGB.L',
  },
  {
    value: 'G500.L',
    label: 'G500.L,  Invesco S&P 500 UCITS ETF',
    name: 'Invesco S&P 500 UCITS ETF',
    symbol: 'G500.L',
  },
  {
    value: 'GAAA.L',
    label: 'GAAA.L,  iShares Global AAA-AA Govt Bond UCITS ETF',
    name: 'iShares Global AAA-AA Govt Bond UCITS ETF',
    symbol: 'GAAA.L',
  },
  {
    value: 'GAGG.L',
    label:
      'GAGG.L,  Amundi Index Solutions - Amundi Index Barclays Global AGG 500M UCITS ETF DR',
    name: 'Amundi Index Solutions - Amundi Index Barclays Global AGG 500M UCITS ETF DR',
    symbol: 'GAGG.L',
  },
  {
    value: 'GBP5.L',
    label: 'GBP5.L,  L&G ESG GBP Corporate Bond 0-5 Year UCITS ETF',
    name: 'L&G ESG GBP Corporate Bond 0-5 Year UCITS ETF',
    symbol: 'GBP5.L',
  },
  {
    value: 'GBPG.L',
    label: 'GBPG.L,  Goldman Sachs Access UK Gilts 1-10 Years UCITS ETF',
    name: 'Goldman Sachs Access UK Gilts 1-10 Years UCITS ETF',
    symbol: 'GBPG.L',
  },
  {
    value: 'GCED.L',
    label:
      'GCED.L,  Invesco Markets II plc - Invesco Global Clean Energy UCITS ETF',
    name: 'Invesco Markets II plc - Invesco Global Clean Energy UCITS ETF',
    symbol: 'GCED.L',
  },
  {
    value: 'GCEX.L',
    label:
      'GCEX.L,  Invesco Markets II plc - Invesco Global Clean Energy UCITS ETF',
    name: 'Invesco Markets II plc - Invesco Global Clean Energy UCITS ETF',
    symbol: 'GCEX.L',
  },
  {
    value: 'GCLE.L',
    label:
      'GCLE.L,  Invesco Markets II plc - Invesco Global Clean Energy UCITS ETF',
    name: 'Invesco Markets II plc - Invesco Global Clean Energy UCITS ETF',
    symbol: 'GCLE.L',
  },
  {
    value: 'GEDV.L',
    label: 'GEDV.L,  SPDR S&P Global Dividend Aristocrats ESG UCITS ETF',
    name: 'SPDR S&P Global Dividend Aristocrats ESG UCITS ETF',
    symbol: 'GEDV.L',
  },
  {
    value: 'GEND.L',
    label:
      'GEND.L,  Lyxor Index Fund - Lyxor Global Gender Equality (DR) UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor Global Gender Equality (DR) UCITS ETF',
    symbol: 'GEND.L',
  },
  {
    value: 'GENY.L',
    label:
      'GENY.L,  Lyxor Index Fund - Lyxor MSCI Millennials ESG Filtered (DR) UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor MSCI Millennials ESG Filtered (DR) UCITS ETF',
    symbol: 'GENY.L',
  },
  {
    value: 'GFGB.L',
    label: 'GFGB.L,  VanEck Global Fallen Angel High Yield Bond UCITS ETF',
    name: 'VanEck Global Fallen Angel High Yield Bond UCITS ETF',
    symbol: 'GFGB.L',
  },
  {
    value: 'GGOV.L',
    label:
      'GGOV.L,  Amundi Index Solutions - Amundi Index J.P. Morgan GBI Global Govies UCITS ETF DR',
    name: 'Amundi Index Solutions - Amundi Index J.P. Morgan GBI Global Govies UCITS ETF DR',
    symbol: 'GGOV.L',
  },
  {
    value: 'GGRA.L',
    label: 'GGRA.L,  WisdomTree Global Quality Dividend Growth UCITS ETF',
    name: 'WisdomTree Global Quality Dividend Growth UCITS ETF',
    symbol: 'GGRA.L',
  },
  {
    value: 'GGRW.L',
    label: 'GGRW.L,  WisdomTree Global Quality Dividend Growth UCITS ETF',
    name: 'WisdomTree Global Quality Dividend Growth UCITS ETF',
    symbol: 'GGRW.L',
  },
  {
    value: 'GILE.L',
    label:
      'GILE.L,  iShares Global Inflation Linked Govt Bond UCITS ETF USD (Acc)',
    name: 'iShares Global Inflation Linked Govt Bond UCITS ETF USD (Acc)',
    symbol: 'GILE.L',
  },
  {
    value: 'GLAD.L',
    label:
      'GLAD.L,  SPDR Bloomberg Global Aggregate Bond USD Hdg UCITS ETF Acc',
    name: 'SPDR Bloomberg Global Aggregate Bond USD Hdg UCITS ETF Acc',
    symbol: 'GLAD.L',
  },
  {
    value: 'GLAG.L',
    label:
      'GLAG.L,  SPDR Bloomberg Global Aggregate Bond USD Hdg UCITS ETF Acc',
    name: 'SPDR Bloomberg Global Aggregate Bond USD Hdg UCITS ETF Acc',
    symbol: 'GLAG.L',
  },
  {
    value: 'GLAU.L',
    label:
      'GLAU.L,  SPDR Bloomberg Global Aggregate Bond USD Hdg UCITS ETF Acc',
    name: 'SPDR Bloomberg Global Aggregate Bond USD Hdg UCITS ETF Acc',
    symbol: 'GLAU.L',
  },
  {
    value: 'GLBL.L',
    label:
      'GLBL.L,  SPDR Bloomberg Global Aggregate Bond USD Hdg UCITS ETF Acc',
    name: 'SPDR Bloomberg Global Aggregate Bond USD Hdg UCITS ETF Acc',
    symbol: 'GLBL.L',
  },
  {
    value: 'GLCB.L',
    label: 'GLCB.L,  SPDR Refinitiv Global Convertible Bond UCITS ETF',
    name: 'SPDR Refinitiv Global Convertible Bond UCITS ETF',
    symbol: 'GLCB.L',
  },
  {
    value: 'GLDA.L',
    label: 'GLDA.L,  Amundi Physical Gold ETC C',
    name: 'Amundi Physical Gold ETC C',
    symbol: 'GLDA.L',
  },
  {
    value: 'GLOW.L',
    label: 'GLOW.L,  SPDR STOXX Global Low Volatility UCITS ETF',
    name: 'SPDR STOXX Global Low Volatility UCITS ETF',
    symbol: 'GLOW.L',
  },
  {
    value: 'GLRA.L',
    label: 'GLRA.L,  SPDR Dow Jones Global Real Estate UCITS ETF',
    name: 'SPDR Dow Jones Global Real Estate UCITS ETF',
    symbol: 'GLRA.L',
  },
  {
    value: 'GLRE.L',
    label: 'GLRE.L,  SPDR Dow Jones Global Real Estate UCITS ETF',
    name: 'SPDR Dow Jones Global Real Estate UCITS ETF',
    symbol: 'GLRE.L',
  },
  {
    value: 'GLTA.L',
    label: 'GLTA.L,  Invesco UK Gilts UCITS ETF',
    name: 'Invesco UK Gilts UCITS ETF',
    symbol: 'GLTA.L',
  },
  {
    value: 'GLUG.L',
    label: 'GLUG.L,  L&G Clean Water UCITS ETF',
    name: 'L&G Clean Water UCITS ETF',
    symbol: 'GLUG.L',
  },
  {
    value: 'GOGB.L',
    label: 'GOGB.L,  VanEck Morningstar Global Wide Moat UCITS ETF',
    name: 'VanEck Morningstar Global Wide Moat UCITS ETF',
    symbol: 'GOGB.L',
  },
  {
    value: 'GOUD.L',
    label: 'GOUD.L,  Lyxor Core Glb Gov Bond UCITS ETF H EUR',
    name: 'Lyxor Core Glb Gov Bond UCITS ETF H EUR',
    symbol: 'GOUD.L',
  },
  {
    value: 'GOVD.L',
    label: 'GOVD.L,  Lyxor Core Glb Gov Bond UCITS ETF H EUR',
    name: 'Lyxor Core Glb Gov Bond UCITS ETF H EUR',
    symbol: 'GOVD.L',
  },
  {
    value: 'GOVG.L',
    label:
      'GOVG.L,  Amundi Index Solutions - Amundi Index J.P. Morgan GBI Global Govies UCITS ETF DR GBP Hedged',
    name: 'Amundi Index Solutions - Amundi Index J.P. Morgan GBI Global Govies UCITS ETF DR GBP Hedged',
    symbol: 'GOVG.L',
  },
  {
    value: 'GOVU.L',
    label:
      'GOVU.L,  Amundi Index Solutions - Amundi Index J.P. Morgan GBI Global Govies UCITS ETF DR',
    name: 'Amundi Index Solutions - Amundi Index J.P. Morgan GBI Global Govies UCITS ETF DR',
    symbol: 'GOVU.L',
  },
  {
    value: 'GXLC.L',
    label:
      'GXLC.L,  SPDR S&P U.S. Communication Services Select Sector UCITS ETF',
    name: 'SPDR S&P U.S. Communication Services Select Sector UCITS ETF',
    symbol: 'GXLC.L',
  },
  {
    value: 'HBRL.L',
    label: 'HBRL.L,  HSBC MSCI Brazil UCITS ETF',
    name: 'HSBC MSCI Brazil UCITS ETF',
    symbol: 'HBRL.L',
  },
  {
    value: 'HCAD.L',
    label: 'HCAD.L,  HSBC MSCI Canada UCITS ETF',
    name: 'HSBC MSCI Canada UCITS ETF',
    symbol: 'HCAD.L',
  },
  {
    value: 'HDLV.L',
    label: 'HDLV.L,  Invesco S&P 500 High Dividend Low Volatility UCITS ETF',
    name: 'Invesco S&P 500 High Dividend Low Volatility UCITS ETF',
    symbol: 'HDLV.L',
  },
  {
    value: 'HEDF.L',
    label: 'HEDF.L,  WisdomTree Europe Equity UCITS ETF',
    name: 'WisdomTree Europe Equity UCITS ETF',
    symbol: 'HEDF.L',
  },
  {
    value: 'HEDG.L',
    label: 'HEDG.L,  WisdomTree Europe Equity UCITS ETF',
    name: 'WisdomTree Europe Equity UCITS ETF',
    symbol: 'HEDG.L',
  },
  {
    value: 'HEDK.L',
    label: 'HEDK.L,  WisdomTree Europe Equity UCITS ETF',
    name: 'WisdomTree Europe Equity UCITS ETF',
    symbol: 'HEDK.L',
  },
  {
    value: 'HEDP.L',
    label: 'HEDP.L,  WisdomTree Europe Equity UCITS ETF - GBP Hedged',
    name: 'WisdomTree Europe Equity UCITS ETF - GBP Hedged',
    symbol: 'HEDP.L',
  },
  {
    value: 'HEDS.L',
    label: 'HEDS.L,  WisdomTree Europe Equity UCITS ETF',
    name: 'WisdomTree Europe Equity UCITS ETF',
    symbol: 'HEDS.L',
  },
  {
    value: 'HIDD.L',
    label: 'HIDD.L,  HSBC MSCI Indonesia UCITS ETF',
    name: 'HSBC MSCI Indonesia UCITS ETF',
    symbol: 'HIDD.L',
  },
  {
    value: 'HKOD.L',
    label: 'HKOD.L,  HSBC MSCI KOREA CAPPED UCITS ETF',
    name: 'HSBC MSCI KOREA CAPPED UCITS ETF',
    symbol: 'HKOD.L',
  },
  {
    value: 'HLQD.L',
    label: 'HLQD.L,  iShares $ Corp Bond Interest Rate Hedged UCITS ETF',
    name: 'iShares $ Corp Bond Interest Rate Hedged UCITS ETF',
    symbol: 'HLQD.L',
  },
  {
    value: 'HMAD.L',
    label: 'HMAD.L,  HSBC MSCI AC FAR EAST ex JAPAN UCITS ETF',
    name: 'HSBC MSCI AC FAR EAST ex JAPAN UCITS ETF',
    symbol: 'HMAD.L',
  },
  {
    value: 'HMCT.L',
    label: 'HMCT.L,  HSBC MSCI CHINA A UCITS ETF',
    name: 'HSBC MSCI CHINA A UCITS ETF',
    symbol: 'HMCT.L',
  },
  {
    value: 'HMED.L',
    label: 'HMED.L,  HSBC MSCI Mexico Capped UCITS ETF',
    name: 'HSBC MSCI Mexico Capped UCITS ETF',
    symbol: 'HMED.L',
  },
  {
    value: 'HMEM.L',
    label: 'HMEM.L,  HSBC MSCI Emerging Markets UCITS ETF',
    name: 'HSBC MSCI Emerging Markets UCITS ETF',
    symbol: 'HMEM.L',
  },
  {
    value: 'HMJD.L',
    label: 'HMJD.L,  HSBC MSCI JAPAN UCITS ETF',
    name: 'HSBC MSCI JAPAN UCITS ETF',
    symbol: 'HMJD.L',
  },
  {
    value: 'HMLD.L',
    label: 'HMLD.L,  HSBC MSCI EM Latin America UCITS ETF',
    name: 'HSBC MSCI EM Latin America UCITS ETF',
    symbol: 'HMLD.L',
  },
  {
    value: 'HMUD.L',
    label: 'HMUD.L,  HSBC MSCI USA UCITS ETF',
    name: 'HSBC MSCI USA UCITS ETF',
    symbol: 'HMUD.L',
  },
  {
    value: 'HMYD.L',
    label: 'HMYD.L,  HSBC MSCI Malaysia UCITS ETF',
    name: 'HSBC MSCI Malaysia UCITS ETF',
    symbol: 'HMYD.L',
  },
  {
    value: 'HPAE.L',
    label:
      'HPAE.L,  HSBC ETFs PLC - HSBC MSCI Europe Climate Paris Aligned UCITS ETF',
    name: 'HSBC ETFs PLC - HSBC MSCI Europe Climate Paris Aligned UCITS ETF',
    symbol: 'HPAE.L',
  },
  {
    value: 'HPAU.L',
    label:
      'HPAU.L,  HSBC ETFs PLC - HSBC MSCI USA Climate Paris Aligned UCITS ETF',
    name: 'HSBC ETFs PLC - HSBC MSCI USA Climate Paris Aligned UCITS ETF',
    symbol: 'HPAU.L',
  },
  {
    value: 'HPAW.L',
    label:
      'HPAW.L,  HSBC ETFs PLC - HSBC MSCI World Climate Paris Aligned UCITS ETF',
    name: 'HSBC ETFs PLC - HSBC MSCI World Climate Paris Aligned UCITS ETF',
    symbol: 'HPAW.L',
  },
  {
    value: 'HPJP.L',
    label: 'HPJP.L,  HSBC MSCI Japan Climate Paris Aligned UCITS ETF',
    name: 'HSBC MSCI Japan Climate Paris Aligned UCITS ETF',
    symbol: 'HPJP.L',
  },
  {
    value: 'HPRD.L',
    label: 'HPRD.L,  HSBC FTSE EPRA NAREIT Developed UCITS ETF',
    name: 'HSBC FTSE EPRA NAREIT Developed UCITS ETF',
    symbol: 'HPRD.L',
  },
  {
    value: 'HSEP.L',
    label: 'HSEP.L,  HSBC Europe Sustainable Equity UCITS ETF',
    name: 'HSBC Europe Sustainable Equity UCITS ETF',
    symbol: 'HSEP.L',
  },
  {
    value: 'HSJP.L',
    label: 'HSJP.L,  HSBC Japan Sustainable Equity UCITS ETF',
    name: 'HSBC Japan Sustainable Equity UCITS ETF',
    symbol: 'HSJP.L',
  },
  {
    value: 'HSPD.L',
    label: 'HSPD.L,  HSBC S&P 500 UCITS ETF',
    name: 'HSBC S&P 500 UCITS ETF',
    symbol: 'HSPD.L',
  },
  {
    value: 'HSUS.L',
    label: 'HSUS.L,  HSBC USA Sustainable Equity UCITS ETF',
    name: 'HSBC USA Sustainable Equity UCITS ETF',
    symbol: 'HSUS.L',
  },
  {
    value: 'HSXJ.L',
    label: 'HSXJ.L,  HSBC Asia Pacific Ex Japan Sustainable Equity UCITS ETF',
    name: 'HSBC Asia Pacific Ex Japan Sustainable Equity UCITS ETF',
    symbol: 'HSXJ.L',
  },
  {
    value: 'HTRD.L',
    label: 'HTRD.L,  HSBC MSCI Turkey UCITS ETF',
    name: 'HSBC MSCI Turkey UCITS ETF',
    symbol: 'HTRD.L',
  },
  {
    value: 'HTWD.L',
    label: 'HTWD.L,  HSBC MSCI Taiwan Capped UCITS ETF',
    name: 'HSBC MSCI Taiwan Capped UCITS ETF',
    symbol: 'HTWD.L',
  },
  {
    value: 'HUDC.L',
    label: 'HUDC.L,  HSBC Bloomberg USD Sustainable Corporate Bond UCITS ETF',
    name: 'HSBC Bloomberg USD Sustainable Corporate Bond UCITS ETF',
    symbol: 'HUDC.L',
  },
  {
    value: 'HWWD.L',
    label: 'HWWD.L,  HSBC Multi Factor Worldwide Equity UCITS ETF',
    name: 'HSBC Multi Factor Worldwide Equity UCITS ETF',
    symbol: 'HWWD.L',
  },
  {
    value: 'HYGN.L',
    label: 'HYGN.L,  Global X Hydrogen UCITS ETF',
    name: 'Global X Hydrogen UCITS ETF',
    symbol: 'HYGN.L',
  },
  {
    value: 'HYGU.L',
    label: 'HYGU.L,  iShares € High Yield Corp Bond UCITS ETF',
    name: 'iShares € High Yield Corp Bond UCITS ETF',
    symbol: 'HYGU.L',
  },
  {
    value: 'HYUS.L',
    label: 'HYUS.L,  iShares Broad $ High Yield Corp Bond UCITS ETF',
    name: 'iShares Broad $ High Yield Corp Bond UCITS ETF',
    symbol: 'HYUS.L',
  },
  {
    value: 'I500.L',
    label: 'I500.L,  iShares S&P 500 Swap UCITS ETF',
    name: 'iShares S&P 500 Swap UCITS ETF',
    symbol: 'I500.L',
  },
  {
    value: 'IAPD.L',
    label: 'IAPD.L,  iShares Asia Pacific Dividend UCITS ETF',
    name: 'iShares Asia Pacific Dividend UCITS ETF',
    symbol: 'IAPD.L',
  },
  {
    value: 'IAUS.L',
    label: 'IAUS.L,  iShares MSCI Australia UCITS ETF',
    name: 'iShares MSCI Australia UCITS ETF',
    symbol: 'IAUS.L',
  },
  {
    value: 'IBTL.L',
    label: 'IBTL.L,  iShares $ Treasury Bond 20+yr UCITS ETF USD (Dist)',
    name: 'iShares $ Treasury Bond 20+yr UCITS ETF USD (Dist)',
    symbol: 'IBTL.L',
  },
  {
    value: 'ICSU.L',
    label:
      'ICSU.L,  iShares V PLC - iShares S&P 500 Consumer Staples Sector UCITS ETF',
    name: 'iShares V PLC - iShares S&P 500 Consumer Staples Sector UCITS ETF',
    symbol: 'ICSU.L',
  },
  {
    value: 'IDAR.L',
    label: 'IDAR.L,  iShares Asia Property Yield UCITS ETF USD (Dist)',
    name: 'iShares Asia Property Yield UCITS ETF USD (Dist)',
    symbol: 'IDAR.L',
  },
  {
    value: 'IDFX.L',
    label: 'IDFX.L,  iShares China Large Cap UCITS ETF',
    name: 'iShares China Large Cap UCITS ETF',
    symbol: 'IDFX.L',
  },
  {
    value: 'IDUP.L',
    label: 'IDUP.L,  iShares US Property Yield UCITS ETF',
    name: 'iShares US Property Yield UCITS ETF',
    symbol: 'IDUP.L',
  },
  {
    value: 'IE15.L',
    label: 'IE15.L,  iShares € Corp Bond 1-5yr UCITS ETF EUR (Dist)',
    name: 'iShares € Corp Bond 1-5yr UCITS ETF EUR (Dist)',
    symbol: 'IE15.L',
  },
  {
    value: 'IEDL.L',
    label: 'IEDL.L,  iShares Edge MSCI Europe Value Factor UCITS ETF',
    name: 'iShares Edge MSCI Europe Value Factor UCITS ETF',
    symbol: 'IEDL.L',
  },
  {
    value: 'IEEU.L',
    label: 'IEEU.L,  iShares Edge MSCI Europe Multifactor UCITS ETF EUR (Acc)',
    name: 'iShares Edge MSCI Europe Multifactor UCITS ETF EUR (Acc)',
    symbol: 'IEEU.L',
  },
  {
    value: 'IEFM.L',
    label: 'IEFM.L,  iShares Edge MSCI Europe Momentum Factor UCITS ETF',
    name: 'iShares Edge MSCI Europe Momentum Factor UCITS ETF',
    symbol: 'IEFM.L',
  },
  {
    value: 'IEMA.L',
    label: 'IEMA.L,  iShares MSCI EM UCITS ETF USD (Acc)',
    name: 'iShares MSCI EM UCITS ETF USD (Acc)',
    symbol: 'IEMA.L',
  },
  {
    value: 'CEBR3.SA',
    label: 'CEBR3.SA,  Companhia Energética de Brasília - CEB',
    name: 'Companhia Energética de Brasília - CEB',
    symbol: 'CEBR3.SA',
  },
  {
    value: 'IEMD.L',
    label: 'IEMD.L,  iShares Edge MSCI Europe Momentum Factor UCITS ETF',
    name: 'iShares Edge MSCI Europe Momentum Factor UCITS ETF',
    symbol: 'IEMD.L',
  },
  {
    value: 'IEML.L',
    label: 'IEML.L,  iShares J.P. Morgan EM Local Govt Bond UCITS ETF',
    name: 'iShares J.P. Morgan EM Local Govt Bond UCITS ETF',
    symbol: 'IEML.L',
  },
  {
    value: 'IEQD.L',
    label: 'IEQD.L,  iShares Edge MSCI Europe Quality Factor UCITS ETF',
    name: 'iShares Edge MSCI Europe Quality Factor UCITS ETF',
    symbol: 'IEQD.L',
  },
  {
    value: 'IESU.L',
    label:
      'IESU.L,  iShares V PLC - iShares S&P 500 Energy Sector UCITS ETF USD (Acc)',
    name: 'iShares V PLC - iShares S&P 500 Energy Sector UCITS ETF USD (Acc)',
    symbol: 'IESU.L',
  },
  {
    value: 'IEVL.L',
    label: 'IEVL.L,  iShares Edge MSCI Europe Value Factor UCITS ETF',
    name: 'iShares Edge MSCI Europe Value Factor UCITS ETF',
    symbol: 'IEVL.L',
  },
  {
    value: 'IFSU.L',
    label: 'IFSU.L,  iShares Edge MSCI USA Multifactor UCITS ETF USD (Acc)',
    name: 'iShares Edge MSCI USA Multifactor UCITS ETF USD (Acc)',
    symbol: 'IFSU.L',
  },
  {
    value: 'IGDA.L',
    label:
      'IGDA.L,  Invesco Markets II plc - Invesco Dow Jones Islamic Global Developed Markets UCITS ETF',
    name: 'Invesco Markets II plc - Invesco Dow Jones Islamic Global Developed Markets UCITS ETF',
    symbol: 'IGDA.L',
  },
  {
    value: 'IGEA.L',
    label:
      'IGEA.L,  iShares Emerging Asia Local Govt Bond UCITS ETF USD (Dist)',
    name: 'iShares Emerging Asia Local Govt Bond UCITS ETF USD (Dist)',
    symbol: 'IGEA.L',
  },
  {
    value: 'IGSU.L',
    label:
      'IGSU.L,  iShares Dow Jones Global Sustainability Screened UCITS ETF',
    name: 'iShares Dow Jones Global Sustainability Screened UCITS ETF',
    symbol: 'IGSU.L',
  },
  {
    value: 'IHYE.L',
    label: 'IHYE.L,  iShares $ High Yield Corp Bond UCITS ETF',
    name: 'iShares $ High Yield Corp Bond UCITS ETF',
    symbol: 'IHYE.L',
  },
  {
    value: 'IJPN.L',
    label: 'IJPN.L,  iShares MSCI Japan UCITS ETF USD (Dist)',
    name: 'iShares MSCI Japan UCITS ETF USD (Dist)',
    symbol: 'IJPN.L',
  },
  {
    value: 'IKSD.L',
    label: 'IKSD.L,  iShares MSCI Saudi Arabia Capped UCITS ETF',
    name: 'iShares MSCI Saudi Arabia Capped UCITS ETF',
    symbol: 'IKSD.L',
  },
  {
    value: 'IMBS.L',
    label:
      'IMBS.L,  iShares US Mortgage Backed Securities UCITS ETF USD (Dist)',
    name: 'iShares US Mortgage Backed Securities UCITS ETF USD (Dist)',
    symbol: 'IMBS.L',
  },
  {
    value: 'IMSU.L',
    label:
      'IMSU.L,  iShares V PLC - iShares S&P 500 Materials Sector UCITS ETF',
    name: 'iShares V PLC - iShares S&P 500 Materials Sector UCITS ETF',
    symbol: 'IMSU.L',
  },
  {
    value: 'INFB.L',
    label: 'INFB.L,  Lyxor US$ 10Y Inflation Expectations UCITS ETF',
    name: 'Lyxor US$ 10Y Inflation Expectations UCITS ETF',
    symbol: 'INFB.L',
  },
  {
    value: 'INFL.L',
    label: 'INFL.L,  Lyxor EUR 2-10Y Inflation Expectations UCITS ETF',
    name: 'Lyxor EUR 2-10Y Inflation Expectations UCITS ETF',
    symbol: 'INFL.L',
  },
  {
    value: 'INFU.L',
    label: 'INFU.L,  Lyxor US$ 10Y Inflation Expectations UCITS ETF',
    name: 'Lyxor US$ 10Y Inflation Expectations UCITS ETF',
    symbol: 'INFU.L',
  },
  {
    value: 'IOGP.L',
    label:
      'IOGP.L,  iShares V PLC - iShares Oil & Gas Exploration & Production UCITS ETF USD (Acc)',
    name: 'iShares V PLC - iShares Oil & Gas Exploration & Production UCITS ETF USD (Acc)',
    symbol: 'IOGP.L',
  },
  {
    value: 'IPRV.L',
    label: 'IPRV.L,  iShares Listed Private Equity UCITS ETF',
    name: 'iShares Listed Private Equity UCITS ETF',
    symbol: 'IPRV.L',
  },
  {
    value: 'IQCT.L',
    label:
      'IQCT.L,  Lyxor Index Fund - Lyxor MSCI Smart Cities ESG Filtered (DR) UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor MSCI Smart Cities ESG Filtered (DR) UCITS ETF',
    symbol: 'IQCT.L',
  },
  {
    value: 'IQCY.L',
    label:
      'IQCY.L,  Lyxor Index Fund - Lyxor MSCI Smart Cities ESG Filtered (DR) UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor MSCI Smart Cities ESG Filtered (DR) UCITS ETF',
    symbol: 'IQCY.L',
  },
  {
    value: 'ISAC.L',
    label: 'ISAC.L,  iShares V PLC - iShares MSCI ACWI UCITS ETF USD (Acc)',
    name: 'iShares V PLC - iShares MSCI ACWI UCITS ETF USD (Acc)',
    symbol: 'ISAC.L',
  },
  {
    value: 'CEBR5.SA',
    label: 'CEBR5.SA,  Companhia Energética de Brasília - CEB',
    name: 'Companhia Energética de Brasília - CEB',
    symbol: 'CEBR5.SA',
  },
  {
    value: 'ISF.L',
    label: 'ISF.L,  iShares Core FTSE 100 UCITS ETF GBP (Dist)',
    name: 'iShares Core FTSE 100 UCITS ETF GBP (Dist)',
    symbol: 'ISF.L',
  },
  {
    value: 'ISFD.L',
    label: 'ISFD.L,  iShares Core FTSE 100 UCITS ETF',
    name: 'iShares Core FTSE 100 UCITS ETF',
    symbol: 'ISFD.L',
  },
  {
    value: 'ISUN.L',
    label: 'ISUN.L,  Invesco Solar Energy UCITS ETF',
    name: 'Invesco Solar Energy UCITS ETF',
    symbol: 'ISUN.L',
  },
  {
    value: 'ITEH.L',
    label:
      'ITEH.L,  iShares V PLC - iShares Italy Govt Bond UCITS ETF EUR (Dist)',
    name: 'iShares V PLC - iShares Italy Govt Bond UCITS ETF EUR (Dist)',
    symbol: 'ITEH.L',
  },
  {
    value: 'IUAE.L',
    label: 'IUAE.L,  iShares US Aggregate Bond UCITS ETF',
    name: 'iShares US Aggregate Bond UCITS ETF',
    symbol: 'IUAE.L',
  },
  {
    value: 'IUAG.L',
    label: 'IUAG.L,  iShares US Aggregate Bond UCITS ETF USD (Dist)',
    name: 'iShares US Aggregate Bond UCITS ETF USD (Dist)',
    symbol: 'IUAG.L',
  },
  {
    value: 'CEBR6.SA',
    label: 'CEBR6.SA,  Companhia Energética de Brasília - CEB',
    name: 'Companhia Energética de Brasília - CEB',
    symbol: 'CEBR6.SA',
  },
  {
    value: 'IUCB.L',
    label: 'IUCB.L,  SPDR Bloomberg 1-10 Year U.S. Corporate Bond UCITS ETF',
    name: 'SPDR Bloomberg 1-10 Year U.S. Corporate Bond UCITS ETF',
    symbol: 'IUCB.L',
  },
  {
    value: 'IUIT.L',
    label:
      'IUIT.L,  iShares V PLC - iShares S&P 500 Information Technology Sector UCITS ETF USD (Acc)',
    name: 'iShares V PLC - iShares S&P 500 Information Technology Sector UCITS ETF USD (Acc)',
    symbol: 'IUIT.L',
  },
  {
    value: 'IUMD.L',
    label: 'IUMD.L,  iShares Edge MSCI USA Momentum Factor UCITS ETF',
    name: 'iShares Edge MSCI USA Momentum Factor UCITS ETF',
    symbol: 'IUMD.L',
  },
  {
    value: 'IUQA.L',
    label: 'IUQA.L,  iShares Edge MSCI USA Quality Factor UCITS ETF USD (Acc)',
    name: 'iShares Edge MSCI USA Quality Factor UCITS ETF USD (Acc)',
    symbol: 'IUQA.L',
  },
  {
    value: 'IUVD.L',
    label: 'IUVD.L,  iShares Edge MSCI USA Value Factor UCITS ETF',
    name: 'iShares Edge MSCI USA Value Factor UCITS ETF',
    symbol: 'IUVD.L',
  },
  {
    value: 'IUVL.L',
    label: 'IUVL.L,  iShares Edge MSCI USA Value Factor UCITS ETF USD (Acc)',
    name: 'iShares Edge MSCI USA Value Factor UCITS ETF USD (Acc)',
    symbol: 'IUVL.L',
  },
  {
    value: 'IWFQ.L',
    label: 'IWFQ.L,  iShares Edge MSCI World Quality Factor UCITS ETF',
    name: 'iShares Edge MSCI World Quality Factor UCITS ETF',
    symbol: 'IWFQ.L',
  },
  {
    value: 'IWSZ.L',
    label: 'IWSZ.L,  iShares Edge MSCI World Size Factor UCITS ETF',
    name: 'iShares Edge MSCI World Size Factor UCITS ETF',
    symbol: 'IWSZ.L',
  },
  {
    value: 'IWVU.L',
    label: 'IWVU.L,  iShares Edge MSCI World Value Factor UCITS ETF',
    name: 'iShares Edge MSCI World Value Factor UCITS ETF',
    symbol: 'IWVU.L',
  },
  {
    value: 'J13E.L',
    label:
      'J13E.L,  JPMorgan ETFs (Ireland) ICAV - BetaBuilders EUR Govt Bond 1-3 yr UCITS ETF',
    name: 'JPMorgan ETFs (Ireland) ICAV - BetaBuilders EUR Govt Bond 1-3 yr UCITS ETF',
    symbol: 'J13E.L',
  },
  {
    value: 'J13U.L',
    label:
      'J13U.L,  JPMorgan ETFs (Ireland) ICAV - BetaBuilders US Treasury Bond 1-3 yr UCITS ETF',
    name: 'JPMorgan ETFs (Ireland) ICAV - BetaBuilders US Treasury Bond 1-3 yr UCITS ETF',
    symbol: 'J13U.L',
  },
  {
    value: 'J15R.L',
    label:
      'J15R.L,  JPMorgan ETFs (Ireland) ICAV - EUR Corporate Bond 1-5 yr Research Enhanced Index (ESG) UCITS ETF',
    name: 'JPMorgan ETFs (Ireland) ICAV - EUR Corporate Bond 1-5 yr Research Enhanced Index (ESG) UCITS ETF',
    symbol: 'J15R.L',
  },
  {
    value: 'JCAG.L',
    label:
      'JCAG.L,  JPMorgan ETFs (Ireland) ICAV - BetaBuilders China Aggregate Bond UCITS ETF',
    name: 'JPMorgan ETFs (Ireland) ICAV - BetaBuilders China Aggregate Bond UCITS ETF',
    symbol: 'JCAG.L',
  },
  {
    value: 'JCST.L',
    label: 'JCST.L,  JPM RMB Ultra-Short Income UCITS ETF',
    name: 'JPM RMB Ultra-Short Income UCITS ETF',
    symbol: 'JCST.L',
  },
  {
    value: 'JEBP.L',
    label:
      'JEBP.L,  JPMorgan ETFs (Ireland) ICAV - EUR Corporate Bond Research Enhanced Index (ESG) UCITS ETF',
    name: 'JPMorgan ETFs (Ireland) ICAV - EUR Corporate Bond Research Enhanced Index (ESG) UCITS ETF',
    symbol: 'JEBP.L',
  },
  {
    value: 'CEEB3.SA',
    label: 'CEEB3.SA,  Companhia de Eletricidade do Estado da Bahia - COELBA',
    name: 'Companhia de Eletricidade do Estado da Bahia - COELBA',
    symbol: 'CEEB3.SA',
  },
  {
    value: 'JGHY.L',
    label:
      'JGHY.L,  JPMorgan ETFs (Ireland) ICAV Global High Yield Corporate Bond Multi-Factor UCITS ETF',
    name: 'JPMorgan ETFs (Ireland) ICAV Global High Yield Corporate Bond Multi-Factor UCITS ETF',
    symbol: 'JGHY.L',
  },
  {
    value: 'JGSA.L',
    label:
      'JGSA.L,  JPMorgan ETFs (Ireland) ICAV - GBP Ultra-Short Income UCITS ETF',
    name: 'JPMorgan ETFs (Ireland) ICAV - GBP Ultra-Short Income UCITS ETF',
    symbol: 'JGSA.L',
  },
  {
    value: 'JHYU.L',
    label:
      'JHYU.L,  JPMorgan ETFs (Ireland) ICAV Global High Yield Corporate Bond Multi-Factor UCITS ETF USD H Acc',
    name: 'JPMorgan ETFs (Ireland) ICAV Global High Yield Corporate Bond Multi-Factor UCITS ETF USD H Acc',
    symbol: 'JHYU.L',
  },
  {
    value: 'JIBG.L',
    label:
      'JIBG.L,  JPMorgan ETFs (Ireland) ICAV - USD Corporate Bond Research Enhanced Index (ESG) UCITS ETF',
    name: 'JPMorgan ETFs (Ireland) ICAV - USD Corporate Bond Research Enhanced Index (ESG) UCITS ETF',
    symbol: 'JIBG.L',
  },
  {
    value: 'JIGB.L',
    label:
      'JIGB.L,  JPMorgan ETFs (Ireland) ICAV - USD Corporate Bond Research Enhanced Index (ESG) UCITS ETF',
    name: 'JPMorgan ETFs (Ireland) ICAV - USD Corporate Bond Research Enhanced Index (ESG) UCITS ETF',
    symbol: 'JIGB.L',
  },
  {
    value: 'JMAB.L',
    label:
      'JMAB.L,  JPMorgan ETFs (Ireland) ICAV - USD Emerging Markets Sovereign Bond UCITS ETF',
    name: 'JPMorgan ETFs (Ireland) ICAV - USD Emerging Markets Sovereign Bond UCITS ETF',
    symbol: 'JMAB.L',
  },
  {
    value: 'JMBA.L',
    label:
      'JMBA.L,  JPMorgan ETFs (Ireland) ICAV - USD Emerging Markets Sovereign Bond UCITS ETF',
    name: 'JPMorgan ETFs (Ireland) ICAV - USD Emerging Markets Sovereign Bond UCITS ETF',
    symbol: 'JMBA.L',
  },
  {
    value: 'JMBE.L',
    label:
      'JMBE.L,  JPMorgan ETFs (Ireland) ICAV - USD Emerging Markets Sovereign Bond UCITS ETF EUR (acc) Hedged',
    name: 'JPMorgan ETFs (Ireland) ICAV - USD Emerging Markets Sovereign Bond UCITS ETF EUR (acc) Hedged',
    symbol: 'JMBE.L',
  },
  {
    value: 'JMBP.L',
    label:
      'JMBP.L,  JPMorgan ETFs (Ireland) ICAV - USD Emerging Markets Sovereign Bond UCITS ETF GBP (dist) Hedged',
    name: 'JPMorgan ETFs (Ireland) ICAV - USD Emerging Markets Sovereign Bond UCITS ETF GBP (dist) Hedged',
    symbol: 'JMBP.L',
  },
  {
    value: 'JPBM.L',
    label:
      'JPBM.L,  JPMorgan ETFs (Ireland) ICAV - USD Emerging Markets Sovereign Bond UCITS ETF',
    name: 'JPMorgan ETFs (Ireland) ICAV - USD Emerging Markets Sovereign Bond UCITS ETF',
    symbol: 'JPBM.L',
  },
  {
    value: 'JPCT.L',
    label:
      'JPCT.L,  JPMorgan ETFs (Ireland) ICAV - Carbon Transition Global Equity UCITS ETF',
    name: 'JPMorgan ETFs (Ireland) ICAV - Carbon Transition Global Equity UCITS ETF',
    symbol: 'JPCT.L',
  },
  {
    value: 'JPEH.L',
    label: 'JPEH.L,  SPDR MSCI Japan UCITS ETF',
    name: 'SPDR MSCI Japan UCITS ETF',
    symbol: 'JPEH.L',
  },
  {
    value: 'JPHU.L',
    label: 'JPHU.L,  Amundi Index Solutions - Amundi JPX-Nikkei 400',
    name: 'Amundi Index Solutions - Amundi JPX-Nikkei 400',
    symbol: 'JPHU.L',
  },
  {
    value: 'JPJP.L',
    label: 'JPJP.L,  SPDR MSCI Japan UCITS ETF',
    name: 'SPDR MSCI Japan UCITS ETF',
    symbol: 'JPJP.L',
  },
  {
    value: 'JPNU.L',
    label: 'JPNU.L,  Lyxor Japan (Topix) (DR) UCITS ETF',
    name: 'Lyxor Japan (Topix) (DR) UCITS ETF',
    symbol: 'JPNU.L',
  },
  {
    value: 'JPNY.L',
    label: 'JPNY.L,  Amundi Index Solutions - Amundi JPX-Nikkei 400',
    name: 'Amundi Index Solutions - Amundi JPX-Nikkei 400',
    symbol: 'JPNY.L',
  },
  {
    value: 'JPSA.L',
    label:
      'JPSA.L,  JPMorgan ETFs (Ireland) ICAV - USD Ultra-Short Income UCITS ETF',
    name: 'JPMorgan ETFs (Ireland) ICAV - USD Ultra-Short Income UCITS ETF',
    symbol: 'JPSA.L',
  },
  {
    value: 'JPST.L',
    label:
      'JPST.L,  JPMorgan ETFs (Ireland) ICAV - USD Ultra-Short Income UCITS ETF',
    name: 'JPMorgan ETFs (Ireland) ICAV - USD Ultra-Short Income UCITS ETF',
    symbol: 'JPST.L',
  },
  {
    value: 'JPX4.L',
    label: 'JPX4.L,  Lyxor MSCI Japan ESG Leaders Extra (DR) UCITS ETF',
    name: 'Lyxor MSCI Japan ESG Leaders Extra (DR) UCITS ETF',
    symbol: 'JPX4.L',
  },
  {
    value: 'JRDE.L',
    label:
      'JRDE.L,  JPMorgan ETFs (Ireland) ICAV - Europe Research Enhanced Index Equity (ESG) UCITS ETF',
    name: 'JPMorgan ETFs (Ireland) ICAV - Europe Research Enhanced Index Equity (ESG) UCITS ETF',
    symbol: 'JRDE.L',
  },
  {
    value: 'JRDG.L',
    label:
      'JRDG.L,  JPMorgan ETFs (Ireland) ICAV - Global Research Enhanced Index Equity (ESG) UCITS ETF',
    name: 'JPMorgan ETFs (Ireland) ICAV - Global Research Enhanced Index Equity (ESG) UCITS ETF',
    symbol: 'JRDG.L',
  },
  {
    value: 'JREA.L',
    label:
      'JREA.L,  JPM AC Asia Pacific ex Japan Research Enhanced Index Equity (ESG) UCITS ETF',
    name: 'JPM AC Asia Pacific ex Japan Research Enhanced Index Equity (ESG) UCITS ETF',
    symbol: 'JREA.L',
  },
  {
    value: 'CEEB5.SA',
    label: 'CEEB5.SA,  Companhia de Eletricidade do Estado da Bahia - COELBA',
    name: 'Companhia de Eletricidade do Estado da Bahia - COELBA',
    symbol: 'CEEB5.SA',
  },
  {
    value: 'JRUD.L',
    label:
      'JRUD.L,  JPMorgan ETFs (Ireland) ICAV - US Research Enhanced Index Equity (ESG) UCITS ETF',
    name: 'JPMorgan ETFs (Ireland) ICAV - US Research Enhanced Index Equity (ESG) UCITS ETF',
    symbol: 'JRUD.L',
  },
  {
    value: 'JRUP.L',
    label:
      'JRUP.L,  JPMorgan ETFs (Ireland) ICAV - USD Corporate Bond Research Enhanced Index (ESG) UCITS ETF',
    name: 'JPMorgan ETFs (Ireland) ICAV - USD Corporate Bond Research Enhanced Index (ESG) UCITS ETF',
    symbol: 'JRUP.L',
  },
  {
    value: 'JSET.L',
    label:
      'JSET.L,  JPMorgan ETFs (Ireland) ICAV - EUR Ultra-Short Income UCITS ETF',
    name: 'JPMorgan ETFs (Ireland) ICAV - EUR Ultra-Short Income UCITS ETF',
    symbol: 'JSET.L',
  },
  {
    value: 'JT13.L',
    label:
      'JT13.L,  UBS ETF - Bloomberg Barclays Japan Treasury 1-3 Year Bond UCITS ETF',
    name: 'UBS ETF - Bloomberg Barclays Japan Treasury 1-3 Year Bond UCITS ETF',
    symbol: 'JT13.L',
  },
  {
    value: 'KLMG.L',
    label: 'KLMG.L,  Lyxor Green Bond (DR) UCITS ETF',
    name: 'Lyxor Green Bond (DR) UCITS ETF',
    symbol: 'KLMG.L',
  },
  {
    value: 'KROP.L',
    label: 'KROP.L,  Global X AgTech & Food Innovation UCITS ETF',
    name: 'Global X AgTech & Food Innovation UCITS ETF',
    symbol: 'KROP.L',
  },
  {
    value: 'KRW.L',
    label: 'KRW.L,  MULTI-UNITS LUXEMBOURG - Lyxor MSCI Korea UCITS ETF',
    name: 'MULTI-UNITS LUXEMBOURG - Lyxor MSCI Korea UCITS ETF',
    symbol: 'KRW.L',
  },
  {
    value: 'KWBE.L',
    label: 'KWBE.L,  KraneShares CSI China Internet UCITS ETF',
    name: 'KraneShares CSI China Internet UCITS ETF',
    symbol: 'KWBE.L',
  },
  {
    value: 'LAUU.L',
    label: 'LAUU.L,  Lyxor Australia (S&P/ASX 200) UCITS ETF',
    name: 'Lyxor Australia (S&P/ASX 200) UCITS ETF',
    symbol: 'LAUU.L',
  },
  {
    value: 'LCAL.L',
    label: 'LCAL.L,  Lyxor MSCI EM Asia Ucits ETF',
    name: 'Lyxor MSCI EM Asia Ucits ETF',
    symbol: 'LCAL.L',
  },
  {
    value: 'LCAS.L',
    label: 'LCAS.L,  Lyxor MSCI EM Asia Ucits ETF',
    name: 'Lyxor MSCI EM Asia Ucits ETF',
    symbol: 'LCAS.L',
  },
  {
    value: 'LCJD.L',
    label: 'LCJD.L,  Lyxor Core MSCI Japan (DR) UCITS ETF',
    name: 'Lyxor Core MSCI Japan (DR) UCITS ETF',
    symbol: 'LCJD.L',
  },
  {
    value: 'ENMT3.SA',
    label: 'ENMT3.SA,  Energisa Mato Grosso - Distribuidora de Energia S/A',
    name: 'Energisa Mato Grosso - Distribuidora de Energia S/A',
    symbol: 'ENMT3.SA',
  },
  {
    value: 'LCUD.L',
    label: 'LCUD.L,  Lyxor Core US Equity (DR) UCITS ETF - Dist',
    name: 'Lyxor Core US Equity (DR) UCITS ETF - Dist',
    symbol: 'LCUD.L',
  },
  {
    value: 'LCWD.L',
    label: 'LCWD.L,  Lyxor Core MSCI World (DR) UCITS ETF',
    name: 'Lyxor Core MSCI World (DR) UCITS ETF',
    symbol: 'LCWD.L',
  },
  {
    value: 'LDEM.L',
    label:
      'LDEM.L,  L&G Quality Equity Dividends ESG Exclusions Emerging Markets UCITS ETF',
    name: 'L&G Quality Equity Dividends ESG Exclusions Emerging Markets UCITS ETF',
    symbol: 'LDEM.L',
  },
  {
    value: 'LDME.L',
    label:
      'LDME.L,  L&G Quality Equity Dividends ESG Exclusions Emerging Markets UCITS ETF',
    name: 'L&G Quality Equity Dividends ESG Exclusions Emerging Markets UCITS ETF',
    symbol: 'LDME.L',
  },
  {
    value: 'LEGR.L',
    label:
      'LEGR.L,  First Trust Indxx Innovative Transaction & Process UCITS ETF',
    name: 'First Trust Indxx Innovative Transaction & Process UCITS ETF',
    symbol: 'LEGR.L',
  },
  {
    value: 'LEMA.L',
    label: 'LEMA.L,  Lyxor MSCI Emerging Markets (LUX) UCITS ETF',
    name: 'Lyxor MSCI Emerging Markets (LUX) UCITS ETF',
    symbol: 'LEMA.L',
  },
  {
    value: 'LEMD.L',
    label: 'LEMD.L,  Lyxor MSCI Emerging Markets UCITS ETF',
    name: 'Lyxor MSCI Emerging Markets UCITS ETF',
    symbol: 'LEMD.L',
  },
  {
    value: 'LERN.L',
    label: 'LERN.L,  Rize Education Tech and Digital Learning UCITS ETF',
    name: 'Rize Education Tech and Digital Learning UCITS ETF',
    symbol: 'LERN.L',
  },
  {
    value: 'LESG.L',
    label: 'LESG.L,  Lyxor MSCI EM ESG Leaders Extra UCITS ETF',
    name: 'Lyxor MSCI EM ESG Leaders Extra UCITS ETF',
    symbol: 'LESG.L',
  },
  {
    value: 'LESU.L',
    label: 'LESU.L,  Lyxor MSCI USA ESG Leaders Extra (DR) UCITS ETF',
    name: 'Lyxor MSCI USA ESG Leaders Extra (DR) UCITS ETF',
    symbol: 'LESU.L',
  },
  {
    value: 'LESW.L',
    label: 'LESW.L,  Lyxor MSCI World ESG Leaders Extra (DR) UCITS ETF',
    name: 'Lyxor MSCI World ESG Leaders Extra (DR) UCITS ETF',
    symbol: 'LESW.L',
  },
  {
    value: 'LGCU.L',
    label: 'LGCU.L,  Invesco LGIM Commodity Composite UCITS ETF',
    name: 'Invesco LGIM Commodity Composite UCITS ETF',
    symbol: 'LGCU.L',
  },
  {
    value: 'LGEU.L',
    label: 'LGEU.L,  L&G Europe ex UK Equity UCITS ETF',
    name: 'L&G Europe ex UK Equity UCITS ETF',
    symbol: 'LGEU.L',
  },
  {
    value: 'LGGL.L',
    label: 'LGGL.L,  L&G Global Equity UCITS ETF',
    name: 'L&G Global Equity UCITS ETF',
    symbol: 'LGGL.L',
  },
  {
    value: 'LGUS.L',
    label: 'LGUS.L,  L&G US Equity UCITS ETF',
    name: 'L&G US Equity UCITS ETF',
    symbol: 'LGUS.L',
  },
  {
    value: 'LITU.L',
    label: 'LITU.L,  Global X Lithium & Battery Tech UCITS ETF',
    name: 'Global X Lithium & Battery Tech UCITS ETF',
    symbol: 'LITU.L',
  },
  {
    value: 'LOUF.L',
    label: 'LOUF.L,  Ossiam US ESG Low Carbon Equity Factors UCITS ETF',
    name: 'Ossiam US ESG Low Carbon Equity Factors UCITS ETF',
    symbol: 'LOUF.L',
  },
  {
    value: 'LQDH.L',
    label:
      'LQDH.L,  iShares $ Corp Bond Interest Rate Hedged UCITS ETF USD (Dist)',
    name: 'iShares $ Corp Bond Interest Rate Hedged UCITS ETF USD (Dist)',
    symbol: 'LQDH.L',
  },
  {
    value: 'LSPU.L',
    label: 'LSPU.L,  Lyxor S&P 500 UCITS ETF',
    name: 'Lyxor S&P 500 UCITS ETF',
    symbol: 'LSPU.L',
  },
  {
    value: 'LUMV.L',
    label: 'LUMV.L,  Ossiam US Minimum Variance ESG NR UCITS ETF',
    name: 'Ossiam US Minimum Variance ESG NR UCITS ETF',
    symbol: 'LUMV.L',
  },
  {
    value: 'LUXU.L',
    label: 'LUXU.L,  Amundi Index Solutions - Amundi S&P Global Luxury',
    name: 'Amundi Index Solutions - Amundi S&P Global Luxury',
    symbol: 'LUXU.L',
  },
  {
    value: 'MCHT.L',
    label:
      'MCHT.L,  Invesco MSCI China Technology All Shares Stock Connect UCITS ETF',
    name: 'Invesco MSCI China Technology All Shares Stock Connect UCITS ETF',
    symbol: 'MCHT.L',
  },
  {
    value: 'MDBG.L',
    label:
      'MDBG.L,  UBS (Lux) Fund Solutions – Sustainable Development Bank Bonds UCITS ETF',
    name: 'UBS (Lux) Fund Solutions – Sustainable Development Bank Bonds UCITS ETF',
    symbol: 'MDBG.L',
  },
  {
    value: 'MDBU.L',
    label:
      'MDBU.L,  UBS(Lux)Fund Solutions – Sustainable Development Bank Bonds UCITS ETF(USD)A-dis',
    name: 'UBS(Lux)Fund Solutions – Sustainable Development Bank Bonds UCITS ETF(USD)A-dis',
    symbol: 'MDBU.L',
  },
  {
    value: 'METR.L',
    label: 'METR.L,  ETC Group Global Metaverse UCITS ETF',
    name: 'ETC Group Global Metaverse UCITS ETF',
    symbol: 'METR.L',
  },
  {
    value: 'MEUS.L',
    label: 'MEUS.L,  Lyxor Core STOXX Europe 600 (DR) - UCITS ETF Acc',
    name: 'Lyxor Core STOXX Europe 600 (DR) - UCITS ETF Acc',
    symbol: 'MEUS.L',
  },
  {
    value: 'ENMT4.SA',
    label: 'ENMT4.SA,  Energisa Mato Grosso - Distribuidora de Energia S/A',
    name: 'Energisa Mato Grosso - Distribuidora de Energia S/A',
    symbol: 'ENMT4.SA',
  },
  {
    value: 'MFDD.L',
    label: 'MFDD.L,  Lyxor MSCI EMU ESG (DR) - UCITS ETF',
    name: 'Lyxor MSCI EMU ESG (DR) - UCITS ETF',
    symbol: 'MFDD.L',
  },
  {
    value: 'MFEX.L',
    label: 'MFEX.L,  Lyxor Core MSCI EMU (DR) UCITS ETF',
    name: 'Lyxor Core MSCI EMU (DR) UCITS ETF',
    symbol: 'MFEX.L',
  },
  {
    value: 'MILL.L',
    label:
      'MILL.L,  Lyxor Index Fund - Lyxor MSCI Millennials ESG Filtered (DR) UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor MSCI Millennials ESG Filtered (DR) UCITS ETF',
    symbol: 'MILL.L',
  },
  {
    value: 'MIVO.L',
    label:
      'MIVO.L,  Amundi Index Solutions - Amundi MSCI Europe Minimum Volatility Factor',
    name: 'Amundi Index Solutions - Amundi MSCI Europe Minimum Volatility Factor',
    symbol: 'MIVO.L',
  },
  {
    value: 'MLPI.L',
    label: 'MLPI.L,  L&G US Energy Infrastructure MLP UCITS ETF',
    name: 'L&G US Energy Infrastructure MLP UCITS ETF',
    symbol: 'MLPI.L',
  },
  {
    value: 'MLPP.L',
    label:
      'MLPP.L,  Invesco Morningstar US Energy Infrastructure MLP UCITS ETF',
    name: 'Invesco Morningstar US Energy Infrastructure MLP UCITS ETF',
    symbol: 'MLPP.L',
  },
  {
    value: 'MLPQ.L',
    label:
      'MLPQ.L,  Invesco Morningstar US Energy Infrastructure MLP UCITS ETF',
    name: 'Invesco Morningstar US Energy Infrastructure MLP UCITS ETF',
    symbol: 'MLPQ.L',
  },
  {
    value: 'MMLP.L',
    label: 'MMLP.L,  Alerian Midstream Energy Dividend UCITS ETF',
    name: 'Alerian Midstream Energy Dividend UCITS ETF',
    symbol: 'MMLP.L',
  },
  {
    value: 'MMS.L',
    label: 'MMS.L,  Lyxor Index Fund - Lyxor MSCI EMU Small Cap UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor MSCI EMU Small Cap UCITS ETF',
    symbol: 'MMS.L',
  },
  {
    value: 'MOBI.L',
    label:
      'MOBI.L,  Lyxor Index Fund - Lyxor MSCI Future Mobility ESG Filtered (DR) UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor MSCI Future Mobility ESG Filtered (DR) UCITS ETF',
    symbol: 'MOBI.L',
  },
  {
    value: 'MSEU.L',
    label: 'MSEU.L,  Lyxor Euro Stoxx 50 (DR) UCITS ETF',
    name: 'Lyxor Euro Stoxx 50 (DR) UCITS ETF',
    symbol: 'MSEU.L',
  },
  {
    value: 'MSRU.L',
    label:
      'MSRU.L,  Amundi Index Solutions - Amundi Index MSCI Emerging Markets SRI PAB',
    name: 'Amundi Index Solutions - Amundi Index MSCI Emerging Markets SRI PAB',
    symbol: 'MSRU.L',
  },
  {
    value: 'MVEU.L',
    label: 'MVEU.L,  iShares Edge MSCI Europe Minimum Volatility UCITS ETF',
    name: 'iShares Edge MSCI Europe Minimum Volatility UCITS ETF',
    symbol: 'MVEU.L',
  },
  {
    value: 'MXJP.L',
    label: 'MXJP.L,  Invesco MSCI Japan UCITS ETF',
    name: 'Invesco MSCI Japan UCITS ETF',
    symbol: 'MXJP.L',
  },
  {
    value: 'MXUD.L',
    label: 'MXUD.L,  Invesco MSCI USA UCITS ETF',
    name: 'Invesco MSCI USA UCITS ETF',
    symbol: 'MXUD.L',
  },
  {
    value: 'N400.L',
    label: 'N400.L,  Invesco JPX-Nikkei 400 UCITS ETF',
    name: 'Invesco JPX-Nikkei 400 UCITS ETF',
    symbol: 'N400.L',
  },
  {
    value: 'NASD.L',
    label: 'NASD.L,  Lyxor Nasdaq-100 Ucits ETF',
    name: 'Lyxor Nasdaq-100 Ucits ETF',
    symbol: 'NASD.L',
  },
  {
    value: 'NDUS.L',
    label: 'NDUS.L,  SPDR MSCI Europe Industrials UCITS ETF',
    name: 'SPDR MSCI Europe Industrials UCITS ETF',
    symbol: 'NDUS.L',
  },
  {
    value: 'NESG.L',
    label: 'NESG.L,  Invesco Markets II plc - Invesco NASDAQ-100 ESG UCITS ETF',
    name: 'Invesco Markets II plc - Invesco NASDAQ-100 ESG UCITS ETF',
    symbol: 'NESG.L',
  },
  {
    value: 'NRAM.L',
    label: 'NRAM.L,  Amundi Index Solutions - Amundi Index MSCI North America',
    name: 'Amundi Index Solutions - Amundi Index MSCI North America',
    symbol: 'NRAM.L',
  },
  {
    value: 'NRGT.L',
    label:
      'NRGT.L,  Wisdomtree Multi Asset Issuer Plc - Energy Transition Metals Fund',
    name: 'Wisdomtree Multi Asset Issuer Plc - Energy Transition Metals Fund',
    symbol: 'NRGT.L',
  },
  {
    value: 'NRJL.L',
    label: 'NRJL.L,  Lyxor New Energy (DR) UCITS ETF Dist',
    name: 'Lyxor New Energy (DR) UCITS ETF Dist',
    symbol: 'NRJL.L',
  },
  {
    value: 'NRJU.L',
    label: 'NRJU.L,  Lyxor New Energy (DR) UCITS ETF Dist',
    name: 'Lyxor New Energy (DR) UCITS ETF Dist',
    symbol: 'NRJU.L',
  },
  {
    value: 'OWLP.L',
    label: 'OWLP.L,  OSSIAM World ESG Machine Learning UCITS ETF',
    name: 'OSSIAM World ESG Machine Learning UCITS ETF',
    symbol: 'OWLP.L',
  },
  {
    value: 'OWLU.L',
    label: 'OWLU.L,  OSSIAM World ESG Machine Learning UCITS ETF',
    name: 'OSSIAM World ESG Machine Learning UCITS ETF',
    symbol: 'OWLU.L',
  },
  {
    value: 'SHUL4.SA',
    label: 'SHUL4.SA,  Schulz S.A.',
    name: 'Schulz S.A.',
    symbol: 'SHUL4.SA',
  },
  {
    value: 'PABG.L',
    label:
      'PABG.L,  Lyxor Net Zero 2050 S&P Eurozone Climate PAB (DR) UCITS ETF-Acc',
    name: 'Lyxor Net Zero 2050 S&P Eurozone Climate PAB (DR) UCITS ETF-Acc',
    symbol: 'PABG.L',
  },
  {
    value: 'PABS.L',
    label:
      'PABS.L,  Lyxor Net Zero 2050 S&P 500 Climate PAB (DR) UCITS ETF - Acc',
    name: 'Lyxor Net Zero 2050 S&P 500 Climate PAB (DR) UCITS ETF - Acc',
    symbol: 'PABS.L',
  },
  {
    value: 'PARI.L',
    label: 'PARI.L,  Franklin STOXX Europe 600 Paris Aligned Climate UCITS ETF',
    name: 'Franklin STOXX Europe 600 Paris Aligned Climate UCITS ETF',
    symbol: 'PARI.L',
  },
  {
    value: 'PAUS.L',
    label: 'PAUS.L,  Invesco MSCI USA ESG Climate Paris Aligned UCITS ETF',
    name: 'Invesco MSCI USA ESG Climate Paris Aligned UCITS ETF',
    symbol: 'PAUS.L',
  },
  {
    value: 'PAXG.L',
    label: 'PAXG.L,  Lyxor MSCI Pacific Ex Japan UCITS ETF',
    name: 'Lyxor MSCI Pacific Ex Japan UCITS ETF',
    symbol: 'PAXG.L',
  },
  {
    value: 'PAXJ.L',
    label: 'PAXJ.L,  Lyxor MSCI Pacific Ex Japan UCITS ETF',
    name: 'Lyxor MSCI Pacific Ex Japan UCITS ETF',
    symbol: 'PAXJ.L',
  },
  {
    value: 'PETZ.L',
    label: 'PETZ.L,  Rize Pet Care UCITS ETF',
    name: 'Rize Pet Care UCITS ETF',
    symbol: 'PETZ.L',
  },
  {
    value: 'PLAN.L',
    label: 'PLAN.L,  Lyxor Corporate Green Bond (DR) UCITS ETF',
    name: 'Lyxor Corporate Green Bond (DR) UCITS ETF',
    symbol: 'PLAN.L',
  },
  {
    value: 'PQVG.L',
    label: 'PQVG.L,  Invesco S&P 500 QVM UCITS ETF',
    name: 'Invesco S&P 500 QVM UCITS ETF',
    symbol: 'PQVG.L',
  },
  {
    value: 'PR1T.L',
    label:
      'PR1T.L,  Amundi Index Solutions - Amundi Prime US Treasury Bond 0-1 Y UCITS ETF DR - USD (C)',
    name: 'Amundi Index Solutions - Amundi Prime US Treasury Bond 0-1 Y UCITS ETF DR - USD (C)',
    symbol: 'PR1T.L',
  },
  {
    value: 'PRAC.L',
    label: 'PRAC.L,  Invesco Preferred Shares UCITS ETF',
    name: 'Invesco Preferred Shares UCITS ETF',
    symbol: 'PRAC.L',
  },
  {
    value: 'PRAM.L',
    label:
      'PRAM.L,  Amundi Index Solutions - Amundi Prime Emerging Markets UCITS ETF',
    name: 'Amundi Index Solutions - Amundi Prime Emerging Markets UCITS ETF',
    symbol: 'PRAM.L',
  },
  {
    value: 'PRAU.L',
    label: 'PRAU.L,  Amundi Index Solutions - Amundi Prime USA',
    name: 'Amundi Index Solutions - Amundi Prime USA',
    symbol: 'PRAU.L',
  },
  {
    value: 'PRFP.L',
    label: 'PRFP.L,  Invesco Preferred Shares UCITS ETF',
    name: 'Invesco Preferred Shares UCITS ETF',
    symbol: 'PRFP.L',
  },
  {
    value: 'PRIE.L',
    label: 'PRIE.L,  Amundi Index Solutions - Amundi Prime Europe',
    name: 'Amundi Index Solutions - Amundi Prime Europe',
    symbol: 'PRIE.L',
  },
  {
    value: 'PRIG.L',
    label: 'PRIG.L,  Amundi Index Solutions - Amundi Prime Global Govies',
    name: 'Amundi Index Solutions - Amundi Prime Global Govies',
    symbol: 'PRIG.L',
  },
  {
    value: 'PRIR.L',
    label:
      'PRIR.L,  Amundi Index Solutions - Amundi Prime Euro Govies UCITS ETF DR',
    name: 'Amundi Index Solutions - Amundi Prime Euro Govies UCITS ETF DR',
    symbol: 'PRIR.L',
  },
  {
    value: 'PRIT.L',
    label:
      'PRIT.L,  Amundi Index Solutions - Amundi Prime US Treasury UCITS ETF DR',
    name: 'Amundi Index Solutions - Amundi Prime US Treasury UCITS ETF DR',
    symbol: 'PRIT.L',
  },
  {
    value: 'PRIU.L',
    label: 'PRIU.L,  Amundi Index Solutions - Amundi Prime USA UCITS ETF DR',
    name: 'Amundi Index Solutions - Amundi Prime USA UCITS ETF DR',
    symbol: 'PRIU.L',
  },
  {
    value: 'PRIZ.L',
    label: 'PRIZ.L,  Amundi Index Solutions - Amundi Prime Eurozone',
    name: 'Amundi Index Solutions - Amundi Prime Eurozone',
    symbol: 'PRIZ.L',
  },
  {
    value: 'PRJU.L',
    label: 'PRJU.L,  Amundi Index Solutions - Amundi Prime Japan',
    name: 'Amundi Index Solutions - Amundi Prime Japan',
    symbol: 'PRJU.L',
  },
  {
    value: 'PRUC.L',
    label: 'PRUC.L,  Amundi Index Solutions - Amundi Prime US Corporates',
    name: 'Amundi Index Solutions - Amundi Prime US Corporates',
    symbol: 'PRUC.L',
  },
  {
    value: 'PRUS.L',
    label: 'PRUS.L,  Invesco FTSE RAFI US 1000 UCITS ETF',
    name: 'Invesco FTSE RAFI US 1000 UCITS ETF',
    symbol: 'PRUS.L',
  },
  {
    value: 'PUIP.L',
    label: 'PUIP.L,  Invesco USD Corporate Bond ESG UCITS ETF',
    name: 'Invesco USD Corporate Bond ESG UCITS ETF',
    symbol: 'PUIP.L',
  },
  {
    value: 'QCLN.L',
    label: 'QCLN.L,  First Trust Nasdaq Clean Edge Green Energy UCITS ETF',
    name: 'First Trust Nasdaq Clean Edge Green Energy UCITS ETF',
    symbol: 'QCLN.L',
  },
  {
    value: 'R2US.L',
    label: 'R2US.L,  SPDR Russell 2000 US Small Cap UCITS ETF',
    name: 'SPDR Russell 2000 US Small Cap UCITS ETF',
    symbol: 'R2US.L',
  },
  {
    value: 'RAYZ.L',
    label: 'RAYZ.L,  Global X Solar UCITS ETF',
    name: 'Global X Solar UCITS ETF',
    symbol: 'RAYZ.L',
  },
  {
    value: 'REMX.L',
    label: 'REMX.L,  VanEck Rare Earth and Strategic Metals UCITS ETF',
    name: 'VanEck Rare Earth and Strategic Metals UCITS ETF',
    symbol: 'REMX.L',
  },
  {
    value: 'RENW.L',
    label: 'RENW.L,  L&G Clean Energy UCITS ETF',
    name: 'L&G Clean Energy UCITS ETF',
    symbol: 'RENW.L',
  },
  {
    value: 'RIOU.L',
    label: 'RIOU.L,  Lyxor MSCI Brazil UCITS ETF',
    name: 'Lyxor MSCI Brazil UCITS ETF',
    symbol: 'RIOU.L',
  },
  {
    value: 'RIUG.L',
    label: 'RIUG.L,  L&G US Equity (Responsible Exclusions) UCITS ETF',
    name: 'L&G US Equity (Responsible Exclusions) UCITS ETF',
    symbol: 'RIUG.L',
  },
  {
    value: 'RNRG.L',
    label: 'RNRG.L,  Global X Renewable Energy Producers UCITS ETF',
    name: 'Global X Renewable Energy Producers UCITS ETF',
    symbol: 'RNRG.L',
  },
  {
    value: 'ROBO.L',
    label: 'ROBO.L,  L&G ROBO Global Robotics and Automation UCITS ETF',
    name: 'L&G ROBO Global Robotics and Automation UCITS ETF',
    symbol: 'ROBO.L',
  },
  {
    value: 'ROLL.L',
    label:
      'ROLL.L,  iShares Bloomberg Enhanced Roll Yield Commodity Swap UCITS ETF',
    name: 'iShares Bloomberg Enhanced Roll Yield Commodity Swap UCITS ETF',
    symbol: 'ROLL.L',
  },
  {
    value: 'RS2U.L',
    label: 'RS2U.L,  Amundi Index Solutions - Amundi Russell 2000',
    name: 'Amundi Index Solutions - Amundi Russell 2000',
    symbol: 'RS2U.L',
  },
  {
    value: 'RUSG.L',
    label: 'RUSG.L,  Lyxor Russell 1000 Growth UCITS ETF',
    name: 'Lyxor Russell 1000 Growth UCITS ETF',
    symbol: 'RUSG.L',
  },
  {
    value: 'S5EG.L',
    label: 'S5EG.L,  UBS (Irl) ETF plc - S&P 500 ESG ELITE UCITS ETF',
    name: 'UBS (Irl) ETF plc - S&P 500 ESG ELITE UCITS ETF',
    symbol: 'S5EG.L',
  },
  {
    value: 'SADA.L',
    label:
      'SADA.L,  Amundi Index Solutions - Amundi Index MSCI EM Asia SRI PAB',
    name: 'Amundi Index Solutions - Amundi Index MSCI EM Asia SRI PAB',
    symbol: 'SADA.L',
  },
  {
    value: 'SASU.L',
    label: 'SASU.L,  iShares MSCI USA ESG Screened UCITS ETF',
    name: 'iShares MSCI USA ESG Screened UCITS ETF',
    symbol: 'SASU.L',
  },
  {
    value: 'SBEM.L',
    label:
      'SBEM.L,  UBS (Lux) Fund Solutions – Bloomberg USD Emerging Markets Sovereign UCITS ETF',
    name: 'UBS (Lux) Fund Solutions – Bloomberg USD Emerging Markets Sovereign UCITS ETF',
    symbol: 'SBEM.L',
  },
  {
    value: 'SBUY.L',
    label: 'SBUY.L,  Invesco Global Buyback Achievers UCITS ETF',
    name: 'Invesco Global Buyback Achievers UCITS ETF',
    symbol: 'SBUY.L',
  },
  {
    value: 'SDHA.L',
    label: 'SDHA.L,  iShares $ Short Duration High Yield Corp Bond UCITS ETF',
    name: 'iShares $ Short Duration High Yield Corp Bond UCITS ETF',
    symbol: 'SDHA.L',
  },
  {
    value: 'SDHG.L',
    label: 'SDHG.L,  iShares $ Short Duration High Yield Corp Bond UCITS ETF',
    name: 'iShares $ Short Duration High Yield Corp Bond UCITS ETF',
    symbol: 'SDHG.L',
  },
  {
    value: 'SDIP.L',
    label: 'SDIP.L,  Global X SuperDividend UCITS ETF',
    name: 'Global X SuperDividend UCITS ETF',
    symbol: 'SDIP.L',
  },
  {
    value: 'SDIU.L',
    label: 'SDIU.L,  Global X SuperDividend UCITS ETF',
    name: 'Global X SuperDividend UCITS ETF',
    symbol: 'SDIU.L',
  },
  {
    value: 'SEMC.L',
    label:
      'SEMC.L,  UBS (Lux) Fund Solutions – J.P. Morgan USD EM Diversified Bond 1-5 UCITS ETF',
    name: 'UBS (Lux) Fund Solutions – J.P. Morgan USD EM Diversified Bond 1-5 UCITS ETF',
    symbol: 'SEMC.L',
  },
  {
    value: 'SEMG.L',
    label: 'SEMG.L,  Lyxor MSCI Taiwan UCITS ETF - Acc-EUR',
    name: 'Lyxor MSCI Taiwan UCITS ETF - Acc-EUR',
    symbol: 'SEMG.L',
  },
  {
    value: 'SEUC.L',
    label: 'SEUC.L,  SPDR Bloomberg 0-3 Year Euro Corporate Bond UCITS ETF',
    name: 'SPDR Bloomberg 0-3 Year Euro Corporate Bond UCITS ETF',
    symbol: 'SEUC.L',
  },
  {
    value: 'SGJP.L',
    label: 'SGJP.L,  iShares MSCI Japan ESG Screened UCITS ETF',
    name: 'iShares MSCI Japan ESG Screened UCITS ETF',
    symbol: 'SGJP.L',
  },
  {
    value: 'SGQD.L',
    label: 'SGQD.L,  Lyxor SG Global Quality Income NTR UCITS ETF',
    name: 'Lyxor SG Global Quality Income NTR UCITS ETF',
    symbol: 'SGQD.L',
  },
  {
    value: 'SGQX.L',
    label: 'SGQX.L,  Lyxor SG Global Quality Income NTR UCITS ETF',
    name: 'Lyxor SG Global Quality Income NTR UCITS ETF',
    symbol: 'SGQX.L',
  },
  {
    value: 'SGWS.L',
    label: 'SGWS.L,  iShares MSCI World SRI UCITS ETF GBP Hedged (Dist)',
    name: 'iShares MSCI World SRI UCITS ETF GBP Hedged (Dist)',
    symbol: 'SGWS.L',
  },
  {
    value: 'SHLG.L',
    label: 'SHLG.L,  iShares Digital Security UCITS ETF',
    name: 'iShares Digital Security UCITS ETF',
    symbol: 'SHLG.L',
  },
  {
    value: 'SHYU.L',
    label: 'SHYU.L,  iShares $ High Yield Corp Bond UCITS ETF USD (Dist)',
    name: 'iShares $ High Yield Corp Bond UCITS ETF USD (Dist)',
    symbol: 'SHYU.L',
  },
  {
    value: 'SILV.L',
    label: 'SILV.L,  Global X Silver Miners UCITS ETF',
    name: 'Global X Silver Miners UCITS ETF',
    symbol: 'SILV.L',
  },
  {
    value: 'SIME.L',
    label: 'SIME.L,  WisdomTree Industrial Metals 1x Daily Short',
    name: 'WisdomTree Industrial Metals 1x Daily Short',
    symbol: 'SIME.L',
  },
  {
    value: 'SJNK.L',
    label:
      'SJNK.L,  SPDR Bloomberg SASB U.S. High Yield Corporate ESG UCITS ETF',
    name: 'SPDR Bloomberg SASB U.S. High Yield Corporate ESG UCITS ETF',
    symbol: 'SJNK.L',
  },
  {
    value: 'SMEA.L',
    label: 'SMEA.L,  iShares Core MSCI Europe UCITS ETF EUR (Acc)',
    name: 'iShares Core MSCI Europe UCITS ETF EUR (Acc)',
    symbol: 'SMEA.L',
  },
  {
    value: 'SMEU.L',
    label: 'SMEU.L,  Invesco MSCI Europe UCITS ETF',
    name: 'Invesco MSCI Europe UCITS ETF',
    symbol: 'SMEU.L',
  },
  {
    value: 'SMRG.L',
    label:
      'SMRG.L,  Amundi Index Solutions - Amundi Index Equity Global Multi Smart Allocation Scientific Beta',
    name: 'Amundi Index Solutions - Amundi Index Equity Global Multi Smart Allocation Scientific Beta',
    symbol: 'SMRG.L',
  },
  {
    value: 'SMRU.L',
    label:
      'SMRU.L,  Amundi Index Solutions - Amundi Index Equity Global Multi Smart Allocation Scientific Beta',
    name: 'Amundi Index Solutions - Amundi Index Equity Global Multi Smart Allocation Scientific Beta',
    symbol: 'SMRU.L',
  },
  {
    value: 'SMUD.L',
    label: 'SMUD.L,  iShares MSCI EMU ESG Screened UCITS ETF',
    name: 'iShares MSCI EMU ESG Screened UCITS ETF',
    symbol: 'SMUD.L',
  },
  {
    value: 'SNSR.L',
    label: 'SNSR.L,  Global X Internet of Things UCITS ETF',
    name: 'Global X Internet of Things UCITS ETF',
    symbol: 'SNSR.L',
  },
  {
    value: 'SP5C.L',
    label: 'SP5C.L,  Lyxor S&P 500 UCITS ETF',
    name: 'Lyxor S&P 500 UCITS ETF',
    symbol: 'SP5C.L',
  },
  {
    value: 'SP5L.L',
    label: 'SP5L.L,  Lyxor S&P 500 UCITS ETF',
    name: 'Lyxor S&P 500 UCITS ETF',
    symbol: 'SP5L.L',
  },
  {
    value: 'SPEH.L',
    label:
      'SPEH.L,  iShares V PLC - iShares Spain Govt Bond UCITS ETF EUR (Dist)',
    name: 'iShares V PLC - iShares Spain Govt Bond UCITS ETF EUR (Dist)',
    symbol: 'SPEH.L',
  },
  {
    value: 'SPLW.L',
    label:
      'SPLW.L,  Invesco Markets II plc - Invesco S&P 500 Low Volatility UCITS ETF',
    name: 'Invesco Markets II plc - Invesco S&P 500 Low Volatility UCITS ETF',
    symbol: 'SPLW.L',
  },
  {
    value: 'SPMD.L',
    label: 'SPMD.L,  iShares Edge S&P 500 Minimum Volatility UCITS ETF',
    name: 'iShares Edge S&P 500 Minimum Volatility UCITS ETF',
    symbol: 'SPMD.L',
  },
  {
    value: 'SPMV.L',
    label: 'SPMV.L,  iShares Edge S&P 500 Minimum Volatility UCITS ETF',
    name: 'iShares Edge S&P 500 Minimum Volatility UCITS ETF',
    symbol: 'SPMV.L',
  },
  {
    value: 'SPUD.L',
    label: 'SPUD.L,  SPDR MSCI USA Climate Paris Aligned UCITS ETF',
    name: 'SPDR MSCI USA Climate Paris Aligned UCITS ETF',
    symbol: 'SPUD.L',
  },
  {
    value: 'SPX5.L',
    label: 'SPX5.L,  SPDR S&P 500 UCITS ETF',
    name: 'SPDR S&P 500 UCITS ETF',
    symbol: 'SPX5.L',
  },
  {
    value: 'SPXD.L',
    label: 'SPXD.L,  Invesco S&P 500 UCITS ETF',
    name: 'Invesco S&P 500 UCITS ETF',
    symbol: 'SPXD.L',
  },
  {
    value: 'SRHE.L',
    label: 'SRHE.L,  Amundi Index Solutions - Amundi Index MSCI EMU SRI PAB',
    name: 'Amundi Index Solutions - Amundi Index MSCI EMU SRI PAB',
    symbol: 'SRHE.L',
  },
  {
    value: 'SRIU.L',
    label:
      'SRIU.L,  UBS (Irl) ETF plc - MSCI USA Socially Responsible UCITS ETF',
    name: 'UBS (Irl) ETF plc - MSCI USA Socially Responsible UCITS ETF',
    symbol: 'SRIU.L',
  },
  {
    value: 'SRWG.L',
    label:
      'SRWG.L,  UBS (Irl) ETF plc – MSCI World Socially Responsible UCITS ETF (hedged to GBP) A-dis',
    name: 'UBS (Irl) ETF plc – MSCI World Socially Responsible UCITS ETF (hedged to GBP) A-dis',
    symbol: 'SRWG.L',
  },
  {
    value: 'STEA.L',
    label:
      'STEA.L,  PIMCO Short-Term High Yield Corporate Bond Index Source UCITS ETF',
    name: 'PIMCO Short-Term High Yield Corporate Bond Index Source UCITS ETF',
    symbol: 'STEA.L',
  },
  {
    value: 'SUAP.L',
    label: 'SUAP.L,  iShares MSCI USA SRI UCITS ETF',
    name: 'iShares MSCI USA SRI UCITS ETF',
    symbol: 'SUAP.L',
  },
  {
    value: 'SUJA.L',
    label: 'SUJA.L,  iShares MSCI Japan SRI UCITS ETF',
    name: 'iShares MSCI Japan SRI UCITS ETF',
    symbol: 'SUJA.L',
  },
  {
    value: 'SUOG.L',
    label: 'SUOG.L,  iShares € Corp Bond ESG UCITS ETF',
    name: 'iShares € Corp Bond ESG UCITS ETF',
    symbol: 'SUOG.L',
  },
  {
    value: 'SUOP.L',
    label: 'SUOP.L,  iShares $ Corp Bond ESG UCITS ETF',
    name: 'iShares $ Corp Bond ESG UCITS ETF',
    symbol: 'SUOP.L',
  },
  {
    value: 'SUSC.L',
    label: 'SUSC.L,  SPDR Bloomberg 0-3 Year U.S. Corporate Bond UCITS ETF',
    name: 'SPDR Bloomberg 0-3 Year U.S. Corporate Bond UCITS ETF',
    symbol: 'SUSC.L',
  },
  {
    value: 'SUSM.L',
    label: 'SUSM.L,  iShares MSCI EM SRI UCITS ETF',
    name: 'iShares MSCI EM SRI UCITS ETF',
    symbol: 'SUSM.L',
  },
  {
    value: 'SUWG.L',
    label: 'SUWG.L,  iShares MSCI World SRI UCITS ETF',
    name: 'iShares MSCI World SRI UCITS ETF',
    symbol: 'SUWG.L',
  },
  {
    value: 'SWIM.L',
    label: 'SWIM.L,  Lyxor Index Fund - Lyxor $ Floating Rate Note UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor $ Floating Rate Note UCITS ETF',
    symbol: 'SWIM.L',
  },
  {
    value: 'SWRD.L',
    label: 'SWRD.L,  SPDR MSCI World UCITS ETF',
    name: 'SPDR MSCI World UCITS ETF',
    symbol: 'SWRD.L',
  },
  {
    value: 'SXLC.L',
    label:
      'SXLC.L,  SPDR S&P U.S. Communication Services Select Sector UCITS ETF',
    name: 'SPDR S&P U.S. Communication Services Select Sector UCITS ETF',
    symbol: 'SXLC.L',
  },
  {
    value: 'T1AP.L',
    label: 'T1AP.L,  Invesco US Treasury Bond 0-1 Year UCITS ETF',
    name: 'Invesco US Treasury Bond 0-1 Year UCITS ETF',
    symbol: 'T1AP.L',
  },
  {
    value: 'T3GB.L',
    label: 'T3GB.L,  Invesco US Treasury Bond 1-3 Year UCITS ETF GBP Hdg Dist',
    name: 'Invesco US Treasury Bond 1-3 Year UCITS ETF GBP Hdg Dist',
    symbol: 'T3GB.L',
  },
  {
    value: 'TAHY.L',
    label:
      'TAHY.L,  Tabula Haitong Asia ex-Japan High Yield Corp USD Bond ESG UCITS ETF',
    name: 'Tabula Haitong Asia ex-Japan High Yield Corp USD Bond ESG UCITS ETF',
    symbol: 'TAHY.L',
  },
  {
    value: 'TCBT.L',
    label: 'TCBT.L,  VanEck iBoxx EUR Corporates UCITS ETF',
    name: 'VanEck iBoxx EUR Corporates UCITS ETF',
    symbol: 'TCBT.L',
  },
  {
    value: 'TCGB.L',
    label: 'TCGB.L,  VanEck iBoxx EUR Corporates UCITS ETF',
    name: 'VanEck iBoxx EUR Corporates UCITS ETF',
    symbol: 'TCGB.L',
  },
  {
    value: 'TDGB.L',
    label:
      'TDGB.L,  VanEck Morningstar Developed Markets Dividend Leaders UCITS ETF',
    name: 'VanEck Morningstar Developed Markets Dividend Leaders UCITS ETF',
    symbol: 'TDGB.L',
  },
  {
    value: 'TDIV.L',
    label:
      'TDIV.L,  VanEck Morningstar Developed Markets Dividend Leaders UCITS ETF',
    name: 'VanEck Morningstar Developed Markets Dividend Leaders UCITS ETF',
    symbol: 'TDIV.L',
  },
  {
    value: 'TEET.L',
    label: 'TEET.L,  VanEck Sustainable European Equal Weight UCITS ETF',
    name: 'VanEck Sustainable European Equal Weight UCITS ETF',
    symbol: 'TEET.L',
  },
  {
    value: 'TEGB.L',
    label: 'TEGB.L,  VanEck Sustainable European Equal Weight UCITS ETF',
    name: 'VanEck Sustainable European Equal Weight UCITS ETF',
    symbol: 'TEGB.L',
  },
  {
    value: 'TELE.L',
    label: 'TELE.L,  SPDR MSCI Europe Communication Services UCITS ETF',
    name: 'SPDR MSCI Europe Communication Services UCITS ETF',
    symbol: 'TELE.L',
  },
  {
    value: 'TGBG.L',
    label: 'TGBG.L,  VanEck iBoxx EUR Sovereign Diversified 1-10 UCITS ETF',
    name: 'VanEck iBoxx EUR Sovereign Diversified 1-10 UCITS ETF',
    symbol: 'TGBG.L',
  },
  {
    value: 'TIGB.L',
    label: 'TIGB.L,  Invesco US Treasury Bond 0-1 Year UCITS ETF',
    name: 'Invesco US Treasury Bond 0-1 Year UCITS ETF',
    symbol: 'TIGB.L',
  },
  {
    value: 'ENGI3.SA',
    label: 'ENGI3.SA,  Energisa S.A.',
    name: 'Energisa S.A.',
    symbol: 'ENGI3.SA',
  },
  {
    value: 'TING.L',
    label: 'TING.L,  Tabula US Enhanced Infla UCITS ETF GBP H',
    name: 'Tabula US Enhanced Infla UCITS ETF GBP H',
    symbol: 'TING.L',
  },
  {
    value: 'TIP5.L',
    label: 'TIP5.L,  iShares $ TIPS 0-5 UCITS ETF USD (Dist)',
    name: 'iShares $ TIPS 0-5 UCITS ETF USD (Dist)',
    symbol: 'TIP5.L',
  },
  {
    value: 'TIPA.L',
    label:
      'TIPA.L,  MULTI-UNITS LUXEMBOURG - Lyxor Core US TIPS (DR) UCITS ETF',
    name: 'MULTI-UNITS LUXEMBOURG - Lyxor Core US TIPS (DR) UCITS ETF',
    symbol: 'TIPA.L',
  },
  {
    value: 'TIPU.L',
    label: 'TIPU.L,  Lyxor Core US TIPS (DR) UCITS ETF',
    name: 'Lyxor Core US TIPS (DR) UCITS ETF',
    symbol: 'TIPU.L',
  },
  {
    value: 'TPHG.L',
    label:
      'TPHG.L,  Amundi Index Solutions - Amundi Japan Topix UCITS ETF-C GBP Hedged',
    name: 'Amundi Index Solutions - Amundi Japan Topix UCITS ETF-C GBP Hedged',
    symbol: 'TPHG.L',
  },
  {
    value: 'TPHU.L',
    label:
      'TPHU.L,  Amundi Index Solutions - Amundi Japan Topix UCITS ETF-C USD Hedged',
    name: 'Amundi Index Solutions - Amundi Japan Topix UCITS ETF-C USD Hedged',
    symbol: 'TPHU.L',
  },
  {
    value: 'TPXG.L',
    label: 'TPXG.L,  Amundi Index Solutions - Amundi Japan Topix',
    name: 'Amundi Index Solutions - Amundi Japan Topix',
    symbol: 'TPXG.L',
  },
  {
    value: 'TPXU.L',
    label: 'TPXU.L,  Amundi Index Solutions - Amundi Japan Topix',
    name: 'Amundi Index Solutions - Amundi Japan Topix',
    symbol: 'TPXU.L',
  },
  {
    value: 'TR7S.L',
    label: 'TR7S.L,  Invesco US Treasury Bond 3-7 Year UCITS ETF',
    name: 'Invesco US Treasury Bond 3-7 Year UCITS ETF',
    symbol: 'TR7S.L',
  },
  {
    value: 'TREG.L',
    label: 'TREG.L,  VanEck Global Real Estate UCITS ETF',
    name: 'VanEck Global Real Estate UCITS ETF',
    symbol: 'TREG.L',
  },
  {
    value: 'TRIA.L',
    label: 'TRIA.L,  Invesco US Treasury Bond 0-1 Year UCITS ETF',
    name: 'Invesco US Treasury Bond 0-1 Year UCITS ETF',
    symbol: 'TRIA.L',
  },
  {
    value: 'TRIS.L',
    label: 'TRIS.L,  Invesco US Treasury Bond 0-1 Year UCITS ETF USD Dist',
    name: 'Invesco US Treasury Bond 0-1 Year UCITS ETF USD Dist',
    symbol: 'TRIS.L',
  },
  {
    value: 'TRS3.L',
    label: 'TRS3.L,  SPDR Bloomberg 1-3 Year U.S. Treasury Bond UCITS ETF',
    name: 'SPDR Bloomberg 1-3 Year U.S. Treasury Bond UCITS ETF',
    symbol: 'TRS3.L',
  },
  {
    value: 'TRS5.L',
    label: 'TRS5.L,  SPDR Bloomberg 3-7 Year U.S. Treasury Bond UCITS ETF',
    name: 'SPDR Bloomberg 3-7 Year U.S. Treasury Bond UCITS ETF',
    symbol: 'TRS5.L',
  },
  {
    value: 'TRSG.L',
    label: 'TRSG.L,  Invesco US Treasury Bond UCITS ETF USD Dist',
    name: 'Invesco US Treasury Bond UCITS ETF USD Dist',
    symbol: 'TRSG.L',
  },
  {
    value: 'TRSX.L',
    label: 'TRSX.L,  SPDR Bloomberg 7-10 Year U.S. Treasury Bond UCITS ETF',
    name: 'SPDR Bloomberg 7-10 Year U.S. Treasury Bond UCITS ETF',
    symbol: 'TRSX.L',
  },
  {
    value: 'TURU.L',
    label: 'TURU.L,  Lyxor MSCI Turkey UCITS ETF',
    name: 'Lyxor MSCI Turkey UCITS ETF',
    symbol: 'TURU.L',
  },
  {
    value: 'U10C.L',
    label: 'U10C.L,  Lyxor US Treasury 10+Y (DR) UCITS ETF',
    name: 'Lyxor US Treasury 10+Y (DR) UCITS ETF',
    symbol: 'U10C.L',
  },
  {
    value: 'U127.L',
    label: 'U127.L,  Lyxor MSCI Emerging Markets (LUX) UCITS ETF',
    name: 'Lyxor MSCI Emerging Markets (LUX) UCITS ETF',
    symbol: 'U127.L',
  },
  {
    value: 'UB00.L',
    label: 'UB00.L,  UBS (Lux) Fund Solutions – EURO STOXX 50 UCITS ETF',
    name: 'UBS (Lux) Fund Solutions – EURO STOXX 50 UCITS ETF',
    symbol: 'UB00.L',
  },
  {
    value: 'UB01.L',
    label: 'UB01.L,  UBS (Lux) Fund Solutions – EURO STOXX 50 UCITS ETF',
    name: 'UBS (Lux) Fund Solutions – EURO STOXX 50 UCITS ETF',
    symbol: 'UB01.L',
  },
  {
    value: 'UB30.L',
    label:
      'UB30.L,  UBS (Lux) Fund Solutions – MSCI Emerging Markets UCITS ETF',
    name: 'UBS (Lux) Fund Solutions – MSCI Emerging Markets UCITS ETF',
    symbol: 'UB30.L',
  },
  {
    value: 'UC14.L',
    label:
      'UC14.L,  UBS (Irl) Fund Solutions plc - CMCI Composite SF UCITS ETF',
    name: 'UBS (Irl) Fund Solutions plc - CMCI Composite SF UCITS ETF',
    symbol: 'UC14.L',
  },
  {
    value: 'UC63.L',
    label:
      'UC63.L,  UBS(Lux)Fund Solutions – MSCI United Kingdom UCITS ETF(GBP)A-dis',
    name: 'UBS(Lux)Fund Solutions – MSCI United Kingdom UCITS ETF(GBP)A-dis',
    symbol: 'UC63.L',
  },
  {
    value: 'UC64.L',
    label: 'UC64.L,  UBS (Lux) Fund Solutions – MSCI United Kingdom UCITS ETF',
    name: 'UBS (Lux) Fund Solutions – MSCI United Kingdom UCITS ETF',
    symbol: 'UC64.L',
  },
  {
    value: 'UC65.L',
    label: 'UC65.L,  UBS (Lux) Fund Solutions – MSCI Japan UCITS ETF',
    name: 'UBS (Lux) Fund Solutions – MSCI Japan UCITS ETF',
    symbol: 'UC65.L',
  },
  {
    value: 'UC68.L',
    label: 'UC68.L,  UBS (Lux) Fund Solutions – MSCI World UCITS ETF',
    name: 'UBS (Lux) Fund Solutions – MSCI World UCITS ETF',
    symbol: 'UC68.L',
  },
  {
    value: 'UC81.L',
    label:
      'UC81.L,  UBS(Lux)Fund Solutions – Bloomberg Barclays US Liquid Corporates 1-5 Year UCITS ETF(USD)A-dis',
    name: 'UBS(Lux)Fund Solutions – Bloomberg Barclays US Liquid Corporates 1-5 Year UCITS ETF(USD)A-dis',
    symbol: 'UC81.L',
  },
  {
    value: 'ENGI4.SA',
    label: 'ENGI4.SA,  Energisa S.A.',
    name: 'Energisa S.A.',
    symbol: 'ENGI4.SA',
  },
  {
    value: 'UC82.L',
    label:
      'UC82.L,  UBS(Lux)Fund Solutions – Bloomberg Barclays US Liquid Corporates 1-5 Year UCITS ETF(hedged GBP)A-dis',
    name: 'UBS(Lux)Fund Solutions – Bloomberg Barclays US Liquid Corporates 1-5 Year UCITS ETF(hedged GBP)A-dis',
    symbol: 'UC82.L',
  },
  {
    value: 'UC84.L',
    label:
      'UC84.L,  UBS(Lux)Fund Solutions – Bloomberg Barclays US Liquid Corporates UCITS ETF(USD)A-dis',
    name: 'UBS(Lux)Fund Solutions – Bloomberg Barclays US Liquid Corporates UCITS ETF(USD)A-dis',
    symbol: 'UC84.L',
  },
  {
    value: 'UC85.L',
    label:
      'UC85.L,  UBS (Lux) Fund Solutions – Bloomberg US Liquid Corporates UCITS ETF',
    name: 'UBS (Lux) Fund Solutions – Bloomberg US Liquid Corporates UCITS ETF',
    symbol: 'UC85.L',
  },
  {
    value: 'UC86.L',
    label:
      'UC86.L,  UBS(Lux)Fund Solutions – Bloomberg Barclays US Liquid Corporates 1-5 Year UCITS ETF(USD)A-dis',
    name: 'UBS(Lux)Fund Solutions – Bloomberg Barclays US Liquid Corporates 1-5 Year UCITS ETF(USD)A-dis',
    symbol: 'UC86.L',
  },
  {
    value: 'UC97.L',
    label:
      'UC97.L,  UBS (Lux) Fund Solutions – Bloomberg MSCI US Liquid Corporates Sustainable UCITS ETF',
    name: 'UBS (Lux) Fund Solutions – Bloomberg MSCI US Liquid Corporates Sustainable UCITS ETF',
    symbol: 'UC97.L',
  },
  {
    value: 'UCRP.L',
    label: 'UCRP.L,  Amundi Index Solutions - Amundi Index US Corp SRI',
    name: 'Amundi Index Solutions - Amundi Index US Corp SRI',
    symbol: 'UCRP.L',
  },
  {
    value: 'UD06.L',
    label:
      'UD06.L,  UBS (Irl) Fund Solutions plc - Bloomberg Commodity CMCI SF UCITS ETF',
    name: 'UBS (Irl) Fund Solutions plc - Bloomberg Commodity CMCI SF UCITS ETF',
    symbol: 'UD06.L',
  },
  {
    value: 'UD07.L',
    label:
      'UD07.L,  UBS (Irl) Fund Solutions plc - Bloomberg Commodity CMCI SF UCITS ETF',
    name: 'UBS (Irl) Fund Solutions plc - Bloomberg Commodity CMCI SF UCITS ETF',
    symbol: 'UD07.L',
  },
  {
    value: 'UD08.L',
    label:
      'UD08.L,  UBS (Irl) Fund Solutions plc - CMCI Ex-Agriculture SF UCITs ETF',
    name: 'UBS (Irl) Fund Solutions plc - CMCI Ex-Agriculture SF UCITs ETF',
    symbol: 'UD08.L',
  },
  {
    value: 'UEDV.L',
    label: 'UEDV.L,  SPDR S&P U.S. Dividend Aristocrats ESG UCITS ETF',
    name: 'SPDR S&P U.S. Dividend Aristocrats ESG UCITS ETF',
    symbol: 'UEDV.L',
  },
  {
    value: 'UGDV.L',
    label: 'UGDV.L,  SPDR S&P U.S. Dividend Aristocrats ESG UCITS ETF',
    name: 'SPDR S&P U.S. Dividend Aristocrats ESG UCITS ETF',
    symbol: 'UGDV.L',
  },
  {
    value: 'UHYC.L',
    label:
      'UHYC.L,  Lyxor Index Fund - Lyxor ESG USD High Yield (DR) UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor ESG USD High Yield (DR) UCITS ETF',
    symbol: 'UHYC.L',
  },
  {
    value: 'UHYD.L',
    label:
      'UHYD.L,  Invesco Markets II plc - Invesco USD High Yield Corporate Bond ESG UCITS ETF',
    name: 'Invesco Markets II plc - Invesco USD High Yield Corporate Bond ESG UCITS ETF',
    symbol: 'UHYD.L',
  },
  {
    value: 'UHYG.L',
    label:
      'UHYG.L,  Lyxor Index Fund - Lyxor ESG USD High Yield (DR) UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor ESG USD High Yield (DR) UCITS ETF',
    symbol: 'UHYG.L',
  },
  {
    value: 'UHYH.L',
    label:
      'UHYH.L,  Invesco Markets II plc - Invesco USD High Yield Corporate Bond ESG UCITS ETF',
    name: 'Invesco Markets II plc - Invesco USD High Yield Corporate Bond ESG UCITS ETF',
    symbol: 'UHYH.L',
  },
  {
    value: 'UHYP.L',
    label:
      'UHYP.L,  Invesco Markets II plc - Invesco USD High Yield Corporate Bond ESG UCITS ETF',
    name: 'Invesco Markets II plc - Invesco USD High Yield Corporate Bond ESG UCITS ETF',
    symbol: 'UHYP.L',
  },
  {
    value: 'UKG5.L',
    label: 'UKG5.L,  L&G UK Gilt 0-5 Year UCITS ETF',
    name: 'L&G UK Gilt 0-5 Year UCITS ETF',
    symbol: 'UKG5.L',
  },
  {
    value: 'UNCU.L',
    label: 'UNCU.L,  First Trust US Equity Income UCITS ETF',
    name: 'First Trust US Equity Income UCITS ETF',
    symbol: 'UNCU.L',
  },
  {
    value: 'UPAD.L',
    label: 'UPAD.L,  iShares S&P 500 Paris-Aligned Climate UCITS ETF',
    name: 'iShares S&P 500 Paris-Aligned Climate UCITS ETF',
    symbol: 'UPAD.L',
  },
  {
    value: 'US10.L',
    label: 'US10.L,  Lyxor US Treasury 10+Y (DR) UCITS ETF Dist',
    name: 'Lyxor US Treasury 10+Y (DR) UCITS ETF Dist',
    symbol: 'US10.L',
  },
  {
    value: 'US13.L',
    label: 'US13.L,  Lyxor US Treasury 1-3Y (DR) UCITS ETF',
    name: 'Lyxor US Treasury 1-3Y (DR) UCITS ETF',
    symbol: 'US13.L',
  },
  {
    value: 'US71.L',
    label: 'US71.L,  Lyxor Core US Treasury 7-10Y (DR) UCITS ETF',
    name: 'Lyxor Core US Treasury 7-10Y (DR) UCITS ETF',
    symbol: 'US71.L',
  },
  {
    value: 'USAU.L',
    label: 'USAU.L,  Lyxor MSCI USA ESG Broad CTB (DR) UCITS ETF',
    name: 'Lyxor MSCI USA ESG Broad CTB (DR) UCITS ETF',
    symbol: 'USAU.L',
  },
  {
    value: 'USCR.L',
    label: 'USCR.L,  SPDR Bloomberg SASB U.S. Corporate ESG UCITS ETF',
    name: 'SPDR Bloomberg SASB U.S. Corporate ESG UCITS ETF',
    symbol: 'USCR.L',
  },
  {
    value: 'USHY.L',
    label:
      'USHY.L,  Lyxor Index Fund - Lyxor ESG USD High Yield (DR) UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor ESG USD High Yield (DR) UCITS ETF',
    symbol: 'USHY.L',
  },
  {
    value: 'USIC.L',
    label:
      'USIC.L,  Lyxor Index Fund - Lyxor ESG USD Corporate Bond (DR) UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor ESG USD Corporate Bond (DR) UCITS ETF',
    symbol: 'USIC.L',
  },
  {
    value: 'USPA.L',
    label: 'USPA.L,  Franklin S&P 500 Paris Aligned Climate UCITS ETF',
    name: 'Franklin S&P 500 Paris Aligned Climate UCITS ETF',
    symbol: 'USPA.L',
  },
  {
    value: 'USRI.L',
    label: 'USRI.L,  Amundi Index Solutions - Amundi MSCI USA SRI UCITS ETF DR',
    name: 'Amundi Index Solutions - Amundi MSCI USA SRI UCITS ETF DR',
    symbol: 'USRI.L',
  },
  {
    value: 'USVL.L',
    label: 'USVL.L,  SPDR MSCI USA Value UCITS ETF',
    name: 'SPDR MSCI USA Value UCITS ETF',
    symbol: 'USVL.L',
  },
  {
    value: 'V3GD.L',
    label:
      'V3GD.L,  Vanguard Funds PLC - Vanguard ESG Global Corporate Bond UCITS ETF',
    name: 'Vanguard Funds PLC - Vanguard ESG Global Corporate Bond UCITS ETF',
    symbol: 'V3GD.L',
  },
  {
    value: 'V3GP.L',
    label:
      'V3GP.L,  Vanguard Funds PLC - Vanguard ESG Global Corporate Bond UCITS ETF GBP Hedged Inc',
    name: 'Vanguard Funds PLC - Vanguard ESG Global Corporate Bond UCITS ETF GBP Hedged Inc',
    symbol: 'V3GP.L',
  },
  {
    value: 'V3GS.L',
    label:
      'V3GS.L,  Vanguard Funds PLC - Vanguard ESG Global Corporate Bond UCITS ETF GBP Hedged Acc',
    name: 'Vanguard Funds PLC - Vanguard ESG Global Corporate Bond UCITS ETF GBP Hedged Acc',
    symbol: 'V3GS.L',
  },
  {
    value: 'V3GU.L',
    label:
      'V3GU.L,  Vanguard Funds PLC - Vanguard ESG Global Corporate Bond UCITS ETF USD Hedged Acc',
    name: 'Vanguard Funds PLC - Vanguard ESG Global Corporate Bond UCITS ETF USD Hedged Acc',
    symbol: 'V3GU.L',
  },
  {
    value: 'VALW.L',
    label: 'VALW.L,  SPDR MSCI World Value UCITS ETF',
    name: 'SPDR MSCI World Value UCITS ETF',
    symbol: 'VALW.L',
  },
  {
    value: 'VAPU.L',
    label: 'VAPU.L,  Vanguard FTSE Developed Asia Pacific ex Japan UCITS ETF',
    name: 'Vanguard FTSE Developed Asia Pacific ex Japan UCITS ETF',
    symbol: 'VAPU.L',
  },
  {
    value: 'VDCA.L',
    label:
      'VDCA.L,  Vanguard USD Corporate 1-3 Year Bond UCITS ETF USD Accuimulation',
    name: 'Vanguard USD Corporate 1-3 Year Bond UCITS ETF USD Accuimulation',
    symbol: 'VDCA.L',
  },
  {
    value: 'VDEA.L',
    label: 'VDEA.L,  Vanguard USD Emerging Markets Government Bond UCITS ETF',
    name: 'Vanguard USD Emerging Markets Government Bond UCITS ETF',
    symbol: 'VDEA.L',
  },
  {
    value: 'VDET.L',
    label: 'VDET.L,  Vanguard USD Emerging Markets Government Bond UCITS ETF',
    name: 'Vanguard USD Emerging Markets Government Bond UCITS ETF',
    symbol: 'VDET.L',
  },
  {
    value: 'VDEV.L',
    label: 'VDEV.L,  Vanguard FTSE Developed World UCITS ETF',
    name: 'Vanguard FTSE Developed World UCITS ETF',
    symbol: 'VDEV.L',
  },
  {
    value: 'VDNR.L',
    label: 'VDNR.L,  Vanguard FTSE North America UCITS ETF',
    name: 'Vanguard FTSE North America UCITS ETF',
    symbol: 'VDNR.L',
  },
  {
    value: 'VDPA.L',
    label: 'VDPA.L,  Vanguard USD Corporate Bond UCITS ETF',
    name: 'Vanguard USD Corporate Bond UCITS ETF',
    symbol: 'VDPA.L',
  },
  {
    value: 'VDPX.L',
    label: 'VDPX.L,  Vanguard FTSE Developed Asia Pacific ex Japan UCITS ETF',
    name: 'Vanguard FTSE Developed Asia Pacific ex Japan UCITS ETF',
    symbol: 'VDPX.L',
  },
  {
    value: 'VDTA.L',
    label: 'VDTA.L,  Vanguard USD Treasury Bond UCITS ETF',
    name: 'Vanguard USD Treasury Bond UCITS ETF',
    symbol: 'VDTA.L',
  },
  {
    value: 'VDTS.L',
    label: 'VDTS.L,  Vanguard USD Treasury Bond UCITS ETF',
    name: 'Vanguard USD Treasury Bond UCITS ETF',
    symbol: 'VDTS.L',
  },
  {
    value: 'VDTY.L',
    label: 'VDTY.L,  Vanguard USD Treasury Bond UCITS ETF',
    name: 'Vanguard USD Treasury Bond UCITS ETF',
    symbol: 'VDTY.L',
  },
  {
    value: 'VDUC.L',
    label: 'VDUC.L,  Vanguard USD Corporate 1-3 year Bond UCITS ETF',
    name: 'Vanguard USD Corporate 1-3 year Bond UCITS ETF',
    symbol: 'VDUC.L',
  },
  {
    value: 'VECA.L',
    label: 'VECA.L,  Vanguard EUR Corporate Bond UCITS ETF',
    name: 'Vanguard EUR Corporate Bond UCITS ETF',
    symbol: 'VECA.L',
  },
  {
    value: 'VERE.L',
    label: 'VERE.L,  Vanguard FTSE Developed Europe ex UK UCITS ETF',
    name: 'Vanguard FTSE Developed Europe ex UK UCITS ETF',
    symbol: 'VERE.L',
  },
  {
    value: 'VETA.L',
    label: 'VETA.L,  Vanguard EUR Eurozone Government Bond UCITS ETF',
    name: 'Vanguard EUR Eurozone Government Bond UCITS ETF',
    symbol: 'VETA.L',
  },
  {
    value: 'ATOM3.SA',
    label: 'ATOM3.SA,  Atom Empreendimentos e Participações S.A.',
    name: 'Atom Empreendimentos e Participações S.A.',
    symbol: 'ATOM3.SA',
  },
  {
    value: 'VEUA.L',
    label: 'VEUA.L,  Vanguard FTSE Developed Europe UCITS ETF',
    name: 'Vanguard FTSE Developed Europe UCITS ETF',
    symbol: 'VEUA.L',
  },
  {
    value: 'VEUD.L',
    label: 'VEUD.L,  Vanguard FTSE Developed Europe UCITS ETF',
    name: 'Vanguard FTSE Developed Europe UCITS ETF',
    symbol: 'VEUD.L',
  },
  {
    value: 'VFEA.L',
    label: 'VFEA.L,  Vanguard FTSE Emerging Markets UCITS ETF',
    name: 'Vanguard FTSE Emerging Markets UCITS ETF',
    symbol: 'VFEA.L',
  },
  {
    value: 'VGPA.L',
    label: 'VGPA.L,  Vanguard USD Corporate Bond UCITS ETF',
    name: 'Vanguard USD Corporate Bond UCITS ETF',
    symbol: 'VGPA.L',
  },
  {
    value: 'VHYA.L',
    label: 'VHYA.L,  Vanguard FTSE All-World High Dividend Yield UCITS ETF',
    name: 'Vanguard FTSE All-World High Dividend Yield UCITS ETF',
    symbol: 'VHYA.L',
  },
  {
    value: 'VJPB.L',
    label: 'VJPB.L,  Vanguard FTSE Japan UCITS ETF',
    name: 'Vanguard FTSE Japan UCITS ETF',
    symbol: 'VJPB.L',
  },
  {
    value: 'VJPU.L',
    label: 'VJPU.L,  Vanguard FTSE Japan UCITS ETF',
    name: 'Vanguard FTSE Japan UCITS ETF',
    symbol: 'VJPU.L',
  },
  {
    value: 'VNRA.L',
    label: 'VNRA.L,  Vanguard FTSE North America UCITS ETF',
    name: 'Vanguard FTSE North America UCITS ETF',
    symbol: 'VNRA.L',
  },
  {
    value: 'VNRG.L',
    label: 'VNRG.L,  Vanguard FTSE North America UCITS ETF',
    name: 'Vanguard FTSE North America UCITS ETF',
    symbol: 'VNRG.L',
  },
  {
    value: 'VPAC.L',
    label: 'VPAC.L,  Invesco Variable Rate Preferred Shares UCITS ETF',
    name: 'Invesco Variable Rate Preferred Shares UCITS ETF',
    symbol: 'VPAC.L',
  },
  {
    value: 'VPN.L',
    label:
      'VPN.L,  Global X Data Center REITs & Digital Infrastructure UCITS ETF',
    name: 'Global X Data Center REITs & Digital Infrastructure UCITS ETF',
    symbol: 'VPN.L',
  },
  {
    value: 'VWCG.L',
    label: 'VWCG.L,  Vanguard FTSE Developed Europe UCITS ETF',
    name: 'Vanguard FTSE Developed Europe UCITS ETF',
    symbol: 'VWCG.L',
  },
  {
    value: 'WATU.L',
    label: 'WATU.L,  Lyxor World Water (DR) UCITS ETF Dist',
    name: 'Lyxor World Water (DR) UCITS ETF Dist',
    symbol: 'WATU.L',
  },
  {
    value: 'WCOA.L',
    label: 'WCOA.L,  WisdomTree Enhanced Commodity UCITS ETF - USD Acc',
    name: 'WisdomTree Enhanced Commodity UCITS ETF - USD Acc',
    symbol: 'WCOA.L',
  },
  {
    value: 'WDSC.L',
    label: 'WDSC.L,  SPDR MSCI World Small Cap UCITS ETF',
    name: 'SPDR MSCI World Small Cap UCITS ETF',
    symbol: 'WDSC.L',
  },
  {
    value: 'WELL.L',
    label:
      'WELL.L,  HAN-GINS Indxx Healthcare Megatrend Equal Weight UCITS ETF',
    name: 'HAN-GINS Indxx Healthcare Megatrend Equal Weight UCITS ETF',
    symbol: 'WELL.L',
  },
  {
    value: 'WIAU.L',
    label: 'WIAU.L,  iShares Fallen Angels High Yield Corp Bond UCITS ETF',
    name: 'iShares Fallen Angels High Yield Corp Bond UCITS ETF',
    symbol: 'WIAU.L',
  },
  {
    value: 'WING.L',
    label: 'WING.L,  iShares Fallen Angels High Yield Corp Bond UCITS ETF',
    name: 'iShares Fallen Angels High Yield Corp Bond UCITS ETF',
    symbol: 'WING.L',
  },
  {
    value: 'WLDD.L',
    label: 'WLDD.L,  Lyxor MSCI World UCITS ETF Dist',
    name: 'Lyxor MSCI World UCITS ETF Dist',
    symbol: 'WLDD.L',
  },
  {
    value: 'WLDU.L',
    label: 'WLDU.L,  Lyxor MSCI World UCITS ETF Monthly Hedged to USD Dist',
    name: 'Lyxor MSCI World UCITS ETF Monthly Hedged to USD Dist',
    symbol: 'WLDU.L',
  },
  {
    value: 'WNDY.L',
    label: 'WNDY.L,  Global X Wind Energy UCITS ETF',
    name: 'Global X Wind Energy UCITS ETF',
    symbol: 'WNDY.L',
  },
  {
    value: 'WNGE.L',
    label: 'WNGE.L,  iShares Fallen Angels High Yield Corp Bond UCITS ETF',
    name: 'iShares Fallen Angels High Yield Corp Bond UCITS ETF',
    symbol: 'WNGE.L',
  },
  {
    value: 'WQDV.L',
    label: 'WQDV.L,  iShares MSCI World Quality Dividend ESG UCITS ETF',
    name: 'iShares MSCI World Quality Dividend ESG UCITS ETF',
    symbol: 'WQDV.L',
  },
  {
    value: 'WRCY.L',
    label: 'WRCY.L,  WisdomTree Recycling Decarbonisation UCITS ETF',
    name: 'WisdomTree Recycling Decarbonisation UCITS ETF',
    symbol: 'WRCY.L',
  },
  {
    value: 'WRDU.L',
    label:
      'WRDU.L,  Amundi Index Solutions - Amundi Index MSCI World UCITS ETF DR',
    name: 'Amundi Index Solutions - Amundi Index MSCI World UCITS ETF DR',
    symbol: 'WRDU.L',
  },
  {
    value: 'WSML.L',
    label: 'WSML.L,  iShares MSCI World Small Cap UCITS ETF',
    name: 'iShares MSCI World Small Cap UCITS ETF',
    symbol: 'WSML.L',
  },
  {
    value: 'WTRE.L',
    label: 'WTRE.L,  WisdomTree New Economy Real Estate UCITS ETF',
    name: 'WisdomTree New Economy Real Estate UCITS ETF',
    symbol: 'WTRE.L',
  },
  {
    value: 'WVAL.L',
    label: 'WVAL.L,  SPDR MSCI World Value UCITS ETF',
    name: 'SPDR MSCI World Value UCITS ETF',
    symbol: 'WVAL.L',
  },
  {
    value: 'WXAG.L',
    label: 'WXAG.L,  WisdomTree Enhanced Commodity ex-Agriculture UCITS ETF',
    name: 'WisdomTree Enhanced Commodity ex-Agriculture UCITS ETF',
    symbol: 'WXAG.L',
  },
  {
    value: 'X7PP.L',
    label: 'X7PP.L,  Invesco STOXX Europe 600 Optimised Banks UCITS ETF',
    name: 'Invesco STOXX Europe 600 Optimised Banks UCITS ETF',
    symbol: 'X7PP.L',
  },
  {
    value: 'XAXD.L',
    label: 'XAXD.L,  Xtrackers MSCI AC Asia ex Japan ESG Swap UCITS ETF',
    name: 'Xtrackers MSCI AC Asia ex Japan ESG Swap UCITS ETF',
    symbol: 'XAXD.L',
  },
  {
    value: 'XBAG.L',
    label: 'XBAG.L,  Xtrackers II ESG Global Aggregate Bond ETF',
    name: 'Xtrackers II ESG Global Aggregate Bond ETF',
    symbol: 'XBAG.L',
  },
  {
    value: 'XBGG.L',
    label:
      'XBGG.L,  Xtrackers II Global Aggregate Bond Swap UCITS ETF 3D - GBP Hedged',
    name: 'Xtrackers II Global Aggregate Bond Swap UCITS ETF 3D - GBP Hedged',
    symbol: 'XBGG.L',
  },
  {
    value: 'XBLC.L',
    label: 'XBLC.L,  Xtrackers II EUR Corporate Bond UCITS ETF',
    name: 'Xtrackers II EUR Corporate Bond UCITS ETF',
    symbol: 'XBLC.L',
  },
  {
    value: 'XCMC.L',
    label: 'XCMC.L,  Xtrackers Bloomberg Commodity Swap UCITS ETF',
    name: 'Xtrackers Bloomberg Commodity Swap UCITS ETF',
    symbol: 'XCMC.L',
  },
  {
    value: 'XCO2.L',
    label: 'XCO2.L,  Lyxor Global Green Bond 1-10Y (DR) UCITS ETF - Acc',
    name: 'Lyxor Global Green Bond 1-10Y (DR) UCITS ETF - Acc',
    symbol: 'XCO2.L',
  },
  {
    value: 'XCS5.L',
    label: 'XCS5.L,  Xtrackers MSCI India Swap UCITS ETF',
    name: 'Xtrackers MSCI India Swap UCITS ETF',
    symbol: 'XCS5.L',
  },
  {
    value: 'XCS6.L',
    label: 'XCS6.L,  Xtrackers MSCI China UCITS ETF',
    name: 'Xtrackers MSCI China UCITS ETF',
    symbol: 'XCS6.L',
  },
  {
    value: 'EPAR3.SA',
    label: 'EPAR3.SA,  Embpar Participacoes S.A.',
    name: 'Embpar Participacoes S.A.',
    symbol: 'EPAR3.SA',
  },
  {
    value: 'XD5D.L',
    label: 'XD5D.L,  Xtrackers MSCI EMU UCITS ETF 1C - USD Hedged',
    name: 'Xtrackers MSCI EMU UCITS ETF 1C - USD Hedged',
    symbol: 'XD5D.L',
  },
  {
    value: 'XD9U.L',
    label: 'XD9U.L,  Xtrackers (IE) Plc - Xtrackers MSCI USA UCITS ETF 1C',
    name: 'Xtrackers (IE) Plc - Xtrackers MSCI USA UCITS ETF 1C',
    symbol: 'XD9U.L',
  },
  {
    value: 'XDEW.L',
    label: 'XDEW.L,  Xtrackers S&P 500 Equal Weight UCITS ETF',
    name: 'Xtrackers S&P 500 Equal Weight UCITS ETF',
    symbol: 'XDEW.L',
  },
  {
    value: 'XDGU.L',
    label:
      'XDGU.L,  Xtrackers (IE) Plc - Xtrackers USD Corporate Bond UCITS ETF 1D',
    name: 'Xtrackers (IE) Plc - Xtrackers USD Corporate Bond UCITS ETF 1D',
    symbol: 'XDGU.L',
  },
  {
    value: 'XDNS.L',
    label:
      'XDNS.L,  Xtrackers (IE) Plc - Xtrackers JPX-Nikkei 400 UCITS ETF 1D',
    name: 'Xtrackers (IE) Plc - Xtrackers JPX-Nikkei 400 UCITS ETF 1D',
    symbol: 'XDNS.L',
  },
  {
    value: 'XDNU.L',
    label: 'XDNU.L,  Xtrackers MSCI Japan ESG Screened UCITS ETF',
    name: 'Xtrackers MSCI Japan ESG Screened UCITS ETF',
    symbol: 'XDNU.L',
  },
  {
    value: 'XDWD.L',
    label: 'XDWD.L,  Xtrackers MSCI World UCITS ETF',
    name: 'Xtrackers MSCI World UCITS ETF',
    symbol: 'XDWD.L',
  },
  {
    value: 'XDWL.L',
    label: 'XDWL.L,  Xtrackers (IE) Plc - Xtrackers MSCI World UCITS ETF 1D',
    name: 'Xtrackers (IE) Plc - Xtrackers MSCI World UCITS ETF 1D',
    symbol: 'XDWL.L',
  },
  {
    value: 'XDWY.L',
    label: 'XDWY.L,  Xtrackers MSCI World ESG Screened UCITS ETF',
    name: 'Xtrackers MSCI World ESG Screened UCITS ETF',
    symbol: 'XDWY.L',
  },
  {
    value: 'XEMD.L',
    label: 'XEMD.L,  Xtrackers MSCI Emerging Markets UCITS ETF',
    name: 'Xtrackers MSCI Emerging Markets UCITS ETF',
    symbol: 'XEMD.L',
  },
  {
    value: 'XEOU.L',
    label: 'XEOU.L,  Xtrackers MSCI Europe UCITS ETF 2C - USD Hedged',
    name: 'Xtrackers MSCI Europe UCITS ETF 2C - USD Hedged',
    symbol: 'XEOU.L',
  },
  {
    value: 'XESJ.L',
    label: 'XESJ.L,  Xtrackers MSCI Japan ESG UCITS ETF',
    name: 'Xtrackers MSCI Japan ESG UCITS ETF',
    symbol: 'XESJ.L',
  },
  {
    value: 'XEWG.L',
    label: 'XEWG.L,  Xtrackers S&P 500 Equal Weight UCITS ETF',
    name: 'Xtrackers S&P 500 Equal Weight UCITS ETF',
    symbol: 'XEWG.L',
  },
  {
    value: 'XFFE.L',
    label: 'XFFE.L,  Xtrackers II USD Overnight Rate Swap UCITS ETF',
    name: 'Xtrackers II USD Overnight Rate Swap UCITS ETF',
    symbol: 'XFFE.L',
  },
  {
    value: 'XG7U.L',
    label: 'XG7U.L,  Xtrackers II Global Inflation-Linked Bond UCITS ETF',
    name: 'Xtrackers II Global Inflation-Linked Bond UCITS ETF',
    symbol: 'XG7U.L',
  },
  {
    value: 'XGBB.L',
    label: 'XGBB.L,  Xtrackers USD Corporate Green Bond UCITS ETF',
    name: 'Xtrackers USD Corporate Green Bond UCITS ETF',
    symbol: 'XGBB.L',
  },
  {
    value: 'XGDD.L',
    label: 'XGDD.L,  Xtrackers Stoxx Global Select Dividend 100 Swap UCITS ETF',
    name: 'Xtrackers Stoxx Global Select Dividend 100 Swap UCITS ETF',
    symbol: 'XGDD.L',
  },
  {
    value: 'XGGB.L',
    label: 'XGGB.L,  Xtrackers II Global Government Bond UCITS ETF',
    name: 'Xtrackers II Global Government Bond UCITS ETF',
    symbol: 'XGGB.L',
  },
  {
    value: 'XGID.L',
    label: 'XGID.L,  Xtrackers S&P Global Infrastructure Swap UCITS ETF',
    name: 'Xtrackers S&P Global Infrastructure Swap UCITS ETF',
    symbol: 'XGID.L',
  },
  {
    value: 'XGSI.L',
    label: 'XGSI.L,  Xtrackers II Global Government Bond UCITS ETF',
    name: 'Xtrackers II Global Government Bond UCITS ETF',
    symbol: 'XGSI.L',
  },
  {
    value: 'XIDD.L',
    label: 'XIDD.L,  Xtrackers MSCI Indonesia Swap UCITS ETF',
    name: 'Xtrackers MSCI Indonesia Swap UCITS ETF',
    symbol: 'XIDD.L',
  },
  {
    value: 'XKSD.L',
    label: 'XKSD.L,  Xtrackers MSCI Korea UCITS ETF',
    name: 'Xtrackers MSCI Korea UCITS ETF',
    symbol: 'XKSD.L',
  },
  {
    value: 'XLBS.L',
    label: 'XLBS.L,  Invesco Materials S&P US Select Sector UCITS ETF',
    name: 'Invesco Materials S&P US Select Sector UCITS ETF',
    symbol: 'XLBS.L',
  },
  {
    value: 'XLCS.L',
    label: 'XLCS.L,  Invesco Communications S&P US Select Sector UCITS ETF',
    name: 'Invesco Communications S&P US Select Sector UCITS ETF',
    symbol: 'XLCS.L',
  },
  {
    value: 'XMAD.L',
    label: 'XMAD.L,  Xtrackers MSCI EM Asia Swap UCITS ETF 1C',
    name: 'Xtrackers MSCI EM Asia Swap UCITS ETF 1C',
    symbol: 'XMAD.L',
  },
  {
    value: 'XMAW.L',
    label: 'XMAW.L,  Xtrackers MSCI AC World ESG Screened UCITS ETF',
    name: 'Xtrackers MSCI AC World ESG Screened UCITS ETF',
    symbol: 'XMAW.L',
  },
  {
    value: 'XMBD.L',
    label: 'XMBD.L,  Xtrackers MSCI Brazil UCITS ETF',
    name: 'Xtrackers MSCI Brazil UCITS ETF',
    symbol: 'XMBD.L',
  },
  {
    value: 'XMED.L',
    label: 'XMED.L,  Xtrackers MSCI Europe UCITS ETF 1C',
    name: 'Xtrackers MSCI Europe UCITS ETF 1C',
    symbol: 'XMED.L',
  },
  {
    value: 'XMLD.L',
    label: 'XMLD.L,  Xtrackers MSCI EM Latin America ESG Swap UCITS ETF',
    name: 'Xtrackers MSCI EM Latin America ESG Swap UCITS ETF',
    symbol: 'XMLD.L',
  },
  {
    value: 'XMMS.L',
    label: 'XMMS.L,  Xtrackers MSCI Emerging Markets UCITS ETF',
    name: 'Xtrackers MSCI Emerging Markets UCITS ETF',
    symbol: 'XMMS.L',
  },
  {
    value: 'XMTD.L',
    label: 'XMTD.L,  Xtrackers MSCI Taiwan UCITS ETF',
    name: 'Xtrackers MSCI Taiwan UCITS ETF',
    symbol: 'XMTD.L',
  },
  {
    value: 'XMUD.L',
    label: 'XMUD.L,  Xtrackers MSCI USA Swap UCITS ETF',
    name: 'Xtrackers MSCI USA Swap UCITS ETF',
    symbol: 'XMUD.L',
  },
  {
    value: 'XNAQ.L',
    label: 'XNAQ.L,  Xtrackers NASDAQ 100 UCITS ETF',
    name: 'Xtrackers NASDAQ 100 UCITS ETF',
    symbol: 'XNAQ.L',
  },
  {
    value: 'XNID.L',
    label: 'XNID.L,  Xtrackers Nifty 50 Swap UCITS ETF',
    name: 'Xtrackers Nifty 50 Swap UCITS ETF',
    symbol: 'XNID.L',
  },
  {
    value: 'XNZE.L',
    label: 'XNZE.L,  Xtrackers EMU Net Zero Pathway Paris Aligned UCITS ETF',
    name: 'Xtrackers EMU Net Zero Pathway Paris Aligned UCITS ETF',
    symbol: 'XNZE.L',
  },
  {
    value: 'XNZW.L',
    label: 'XNZW.L,  Xtrackers World Net Zero Pathway Paris Aligned UCITS ETF',
    name: 'Xtrackers World Net Zero Pathway Paris Aligned UCITS ETF',
    symbol: 'XNZW.L',
  },
  {
    value: 'XPXD.L',
    label: 'XPXD.L,  Xtrackers MSCI Pacific ex Japan ESG Screened UCITS ETF',
    name: 'Xtrackers MSCI Pacific ex Japan ESG Screened UCITS ETF',
    symbol: 'XPXD.L',
  },
  {
    value: 'XQUA.L',
    label:
      'XQUA.L,  Xtrackers (IE) Plc - Xtrackers ESG USD Emerging Markets Bond Quality Weighted UCITS ETF 1D',
    name: 'Xtrackers (IE) Plc - Xtrackers ESG USD Emerging Markets Bond Quality Weighted UCITS ETF 1D',
    symbol: 'XQUA.L',
  },
  {
    value: 'XRMU.L',
    label: 'XRMU.L,  Xtrackers MSCI USA ESG Screened UCITS ETF',
    name: 'Xtrackers MSCI USA ESG Screened UCITS ETF',
    symbol: 'XRMU.L',
  },
  {
    value: 'XSCD.L',
    label: 'XSCD.L,  Xtrackers MSCI USA Consumer Discretionary UCITS ETF',
    name: 'Xtrackers MSCI USA Consumer Discretionary UCITS ETF',
    symbol: 'XSCD.L',
  },
  {
    value: 'XSCS.L',
    label: 'XSCS.L,  Xtrackers MSCI USA Consumer Staples UCITS ETF',
    name: 'Xtrackers MSCI USA Consumer Staples UCITS ETF',
    symbol: 'XSCS.L',
  },
  {
    value: 'XSEN.L',
    label: 'XSEN.L,  Xtrackers MSCI USA Energy UCITS ETF',
    name: 'Xtrackers MSCI USA Energy UCITS ETF',
    symbol: 'XSEN.L',
  },
  {
    value: 'XSFD.L',
    label: 'XSFD.L,  Xtrackers S&P Select Frontier Swap UCITS ETF',
    name: 'Xtrackers S&P Select Frontier Swap UCITS ETF',
    symbol: 'XSFD.L',
  },
  {
    value: 'XT01.L',
    label: 'XT01.L,  Xtrackers US Treasuries Ultrashort Bond UCITS ETF',
    name: 'Xtrackers US Treasuries Ultrashort Bond UCITS ETF',
    symbol: 'XT01.L',
  },
  {
    value: 'XT0D.L',
    label: 'XT0D.L,  Xtrackers US Treasuries Ultrashort Bond UCITS ETF',
    name: 'Xtrackers US Treasuries Ultrashort Bond UCITS ETF',
    symbol: 'XT0D.L',
  },
  {
    value: 'XUCD.L',
    label: 'XUCD.L,  Xtrackers MSCI USA Consumer Discretionary UCITS ETF',
    name: 'Xtrackers MSCI USA Consumer Discretionary UCITS ETF',
    symbol: 'XUCD.L',
  },
  {
    value: 'XUCM.L',
    label: 'XUCM.L,  Xtrackers MSCI USA Communication Services UCITS ETF',
    name: 'Xtrackers MSCI USA Communication Services UCITS ETF',
    symbol: 'XUCM.L',
  },
  {
    value: 'XUCS.L',
    label: 'XUCS.L,  Xtrackers MSCI USA Consumer Staples UCITS ETF',
    name: 'Xtrackers MSCI USA Consumer Staples UCITS ETF',
    symbol: 'XUCS.L',
  },
  {
    value: 'XUEB.L',
    label: 'XUEB.L,  Xtrackers II USD Emerging Markets Bond UCITS ETF',
    name: 'Xtrackers II USD Emerging Markets Bond UCITS ETF',
    symbol: 'XUEB.L',
  },
  {
    value: 'XUEM.L',
    label: 'XUEM.L,  Xtrackers II USD Emerging Markets Bond UCITS ETF',
    name: 'Xtrackers II USD Emerging Markets Bond UCITS ETF',
    symbol: 'XUEM.L',
  },
  {
    value: 'CGAS3.SA',
    label: 'CGAS3.SA,  Companhia de Gás de São Paulo - COMGÁS',
    name: 'Companhia de Gás de São Paulo - COMGÁS',
    symbol: 'CGAS3.SA',
  },
  {
    value: 'XUEN.L',
    label: 'XUEN.L,  Xtrackers MSCI USA Energy UCITS ETF',
    name: 'Xtrackers MSCI USA Energy UCITS ETF',
    symbol: 'XUEN.L',
  },
  {
    value: 'XUFN.L',
    label: 'XUFN.L,  Xtrackers MSCI USA Financials UCITS ETF',
    name: 'Xtrackers MSCI USA Financials UCITS ETF',
    symbol: 'XUFN.L',
  },
  {
    value: 'XUHY.L',
    label:
      'XUHY.L,  Xtrackers (IE) Plc - Xtrackers USD High Yield Corporate Bond UCITS ETF 1D',
    name: 'Xtrackers (IE) Plc - Xtrackers USD High Yield Corporate Bond UCITS ETF 1D',
    symbol: 'XUHY.L',
  },
  {
    value: 'XUIN.L',
    label: 'XUIN.L,  Xtrackers MSCI USA Industrials UCITS ETF',
    name: 'Xtrackers MSCI USA Industrials UCITS ETF',
    symbol: 'XUIN.L',
  },
  {
    value: 'XYLD.L',
    label: 'XYLD.L,  Xtrackers ESG USD Corporate Bond Short Duration UCITS ETF',
    name: 'Xtrackers ESG USD Corporate Bond Short Duration UCITS ETF',
    symbol: 'XYLD.L',
  },
  {
    value: 'XZBD.L',
    label: 'XZBD.L,  Xtrackers ESG USD Corporate Bond UCITS ETF',
    name: 'Xtrackers ESG USD Corporate Bond UCITS ETF',
    symbol: 'XZBD.L',
  },
  {
    value: 'XZBU.L',
    label: 'XZBU.L,  Xtrackers ESG USD Corporate Bond UCITS ETF',
    name: 'Xtrackers ESG USD Corporate Bond UCITS ETF',
    symbol: 'XZBU.L',
  },
  {
    value: 'XZE5.L',
    label:
      'XZE5.L,  Xtrackers II EUR Corporate Bond Short Duration SRI PAB UCITS ETF',
    name: 'Xtrackers II EUR Corporate Bond Short Duration SRI PAB UCITS ETF',
    symbol: 'XZE5.L',
  },
  {
    value: 'XZEM.L',
    label: 'XZEM.L,  Xtrackers MSCI Emerging Markets ESG UCITS ETF',
    name: 'Xtrackers MSCI Emerging Markets ESG UCITS ETF',
    symbol: 'XZEM.L',
  },
  {
    value: 'XZEZ.L',
    label: 'XZEZ.L,  Xtrackers MSCI EMU ESG UCITS ETF',
    name: 'Xtrackers MSCI EMU ESG UCITS ETF',
    symbol: 'XZEZ.L',
  },
  {
    value: 'XZMD.L',
    label: 'XZMD.L,  Xtrackers MSCI USA ESG UCITS ETF',
    name: 'Xtrackers MSCI USA ESG UCITS ETF',
    symbol: 'XZMD.L',
  },
  {
    value: 'XZMU.L',
    label: 'XZMU.L,  Xtrackers MSCI USA ESG UCITS ETF',
    name: 'Xtrackers MSCI USA ESG UCITS ETF',
    symbol: 'XZMU.L',
  },
  {
    value: 'XZW0.L',
    label: 'XZW0.L,  Xtrackers MSCI World ESG UCITS ETF',
    name: 'Xtrackers MSCI World ESG UCITS ETF',
    symbol: 'XZW0.L',
  },
  {
    value: 'XZWG.L',
    label: 'XZWG.L,  Xtrackers II ESG Global Government Bond UCITS ETF',
    name: 'Xtrackers II ESG Global Government Bond UCITS ETF',
    symbol: 'XZWG.L',
  },
  {
    value: 'XZWS.L',
    label: 'XZWS.L,  Xtrackers II ESG Global Government Bond UCITS ETF',
    name: 'Xtrackers II ESG Global Government Bond UCITS ETF',
    symbol: 'XZWS.L',
  },
  {
    value: 'YODA.L',
    label: 'YODA.L,  HANetf ICAV - Procure Space UCITS ETF',
    name: 'HANetf ICAV - Procure Space UCITS ETF',
    symbol: 'YODA.L',
  },
  {
    value: 'CGAS5.SA',
    label: 'CGAS5.SA,  Companhia de Gás de São Paulo - COMGÁS',
    name: 'Companhia de Gás de São Paulo - COMGÁS',
    symbol: 'CGAS5.SA',
  },
  {
    value: 'TOP',
    label: 'TOP,  TOP Financial Group Limited',
    name: 'TOP Financial Group Limited',
    symbol: 'TOP',
  },
  {
    value: 'LIPR3.SA',
    label: 'LIPR3.SA,  Eletrobrás Participações S.A. - Eletropar',
    name: 'Eletrobrás Participações S.A. - Eletropar',
    symbol: 'LIPR3.SA',
  },
  {
    value: 'EQMA3B.SA',
    label: 'EQMA3B.SA,  Equatorial Maranhão Distribuidora de Energia S.A.',
    name: 'Equatorial Maranhão Distribuidora de Energia S.A.',
    symbol: 'EQMA3B.SA',
  },
  {
    value: 'DEXP4.SA',
    label: 'DEXP4.SA,  Dexxos Participações S.A.',
    name: 'Dexxos Participações S.A.',
    symbol: 'DEXP4.SA',
  },
  {
    value: 'EMAE4.SA',
    label: 'EMAE4.SA,  Empresa Metropolitana de Águas e Energia S.A.',
    name: 'Empresa Metropolitana de Águas e Energia S.A.',
    symbol: 'EMAE4.SA',
  },
  {
    value: 'EKTR3.SA',
    label: 'EKTR3.SA,  Elektro Redes S.A.',
    name: 'Elektro Redes S.A.',
    symbol: 'EKTR3.SA',
  },
  {
    value: 'EKTR4.SA',
    label: 'EKTR4.SA,  Elektro Redes S.A.',
    name: 'Elektro Redes S.A.',
    symbol: 'EKTR4.SA',
  },
  {
    value: 'MRSA5B.SA',
    label: 'MRSA5B.SA,  MRS Logística S.A.',
    name: 'MRS Logística S.A.',
    symbol: 'MRSA5B.SA',
  },
  {
    value: 'CSRN3.SA',
    label: 'CSRN3.SA,  Companhia Energética do Rio Grande do Norte - COSERN',
    name: 'Companhia Energética do Rio Grande do Norte - COSERN',
    symbol: 'CSRN3.SA',
  },
  {
    value: 'CSRN5.SA',
    label: 'CSRN5.SA,  Companhia Energética do Rio Grande do Norte - COSERN',
    name: 'Companhia Energética do Rio Grande do Norte - COSERN',
    symbol: 'CSRN5.SA',
  },
  {
    value: 'CSRN6.SA',
    label: 'CSRN6.SA,  Companhia Energética do Rio Grande do Norte - COSERN',
    name: 'Companhia Energética do Rio Grande do Norte - COSERN',
    symbol: 'CSRN6.SA',
  },
  {
    value: 'EQPA3.SA',
    label: 'EQPA3.SA,  Equatorial Pará Distribuidora de Energia S.A.',
    name: 'Equatorial Pará Distribuidora de Energia S.A.',
    symbol: 'EQPA3.SA',
  },
  {
    value: 'EQPA5.SA',
    label: 'EQPA5.SA,  Equatorial Pará Distribuidora de Energia S.A.',
    name: 'Equatorial Pará Distribuidora de Energia S.A.',
    symbol: 'EQPA5.SA',
  },
  {
    value: 'EQPA7.SA',
    label: 'EQPA7.SA,  Equatorial Pará Distribuidora de Energia S.A.',
    name: 'Equatorial Pará Distribuidora de Energia S.A.',
    symbol: 'EQPA7.SA',
  },
  {
    value: 'GEPA3.SA',
    label: 'GEPA3.SA,  Rio Paranapanema Energia S.A.',
    name: 'Rio Paranapanema Energia S.A.',
    symbol: 'GEPA3.SA',
  },
  {
    value: 'GEPA4.SA',
    label: 'GEPA4.SA,  Rio Paranapanema Energia S.A.',
    name: 'Rio Paranapanema Energia S.A.',
    symbol: 'GEPA4.SA',
  },
  {
    value: 'TRPL3.SA',
    label:
      'TRPL3.SA,  CTEEP - Companhia de Transmissão de Energia Elétrica Paulista S.A.',
    name: 'CTEEP - Companhia de Transmissão de Energia Elétrica Paulista S.A.',
    symbol: 'TRPL3.SA',
  },
  {
    value: 'PDTC3.SA',
    label: 'PDTC3.SA,  Padtec Holding S.A.',
    name: 'Padtec Holding S.A.',
    symbol: 'PDTC3.SA',
  },
  {
    value: 'SAPR3.SA',
    label: 'SAPR3.SA,  Companhia de Saneamento do Paraná - SANEPAR',
    name: 'Companhia de Saneamento do Paraná - SANEPAR',
    symbol: 'SAPR3.SA',
  },
  {
    value: 'PEAB3.SA',
    label: 'PEAB3.SA,  Companhia de Participações Aliança da Bahia',
    name: 'Companhia de Participações Aliança da Bahia',
    symbol: 'PEAB3.SA',
  },
  {
    value: 'PEAB4.SA',
    label: 'PEAB4.SA,  Companhia de Participações Aliança da Bahia',
    name: 'Companhia de Participações Aliança da Bahia',
    symbol: 'PEAB4.SA',
  },
  {
    value: 'BRAP3.SA',
    label: 'BRAP3.SA,  Bradespar S.A.',
    name: 'Bradespar S.A.',
    symbol: 'BRAP3.SA',
  },
  {
    value: 'ATMP3.SA',
    label: 'ATMP3.SA,  ATMA Participações S.A.',
    name: 'ATMA Participações S.A.',
    symbol: 'ATMP3.SA',
  },
  {
    value: 'BIOM3.SA',
    label: 'BIOM3.SA,  Biomm S.A.',
    name: 'Biomm S.A.',
    symbol: 'BIOM3.SA',
  },
  {
    value: 'TPIS3.SA',
    label: 'TPIS3.SA,  Triunfo Participações e Investimentos S.A.',
    name: 'Triunfo Participações e Investimentos S.A.',
    symbol: 'TPIS3.SA',
  },
  {
    value: 'ITUB3.SA',
    label: 'ITUB3.SA,  Itaú Unibanco Holding S.A.',
    name: 'Itaú Unibanco Holding S.A.',
    symbol: 'ITUB3.SA',
  },
  {
    value: 'DASA3.SA',
    label: 'DASA3.SA,  Diagnósticos da América S.A.',
    name: 'Diagnósticos da América S.A.',
    symbol: 'DASA3.SA',
  },
  {
    value: 'FRTA3.SA',
    label: 'FRTA3.SA,  Pomifrutas S/A',
    name: 'Pomifrutas S/A',
    symbol: 'FRTA3.SA',
  },
  {
    value: 'LUPA3.SA',
    label: 'LUPA3.SA,  Lupatech S.A.',
    name: 'Lupatech S.A.',
    symbol: 'LUPA3.SA',
  },
  {
    value: 'TAEE3.SA',
    label: 'TAEE3.SA,  Transmissora Aliança de Energia Elétrica S.A.',
    name: 'Transmissora Aliança de Energia Elétrica S.A.',
    symbol: 'TAEE3.SA',
  },
  {
    value: 'GBTG',
    label: 'GBTG,  Global Business Travel Group, Inc.',
    name: 'Global Business Travel Group, Inc.',
    symbol: 'GBTG',
  },
  {
    value: 'TAEE4.SA',
    label: 'TAEE4.SA,  Transmissora Aliança de Energia Elétrica S.A.',
    name: 'Transmissora Aliança de Energia Elétrica S.A.',
    symbol: 'TAEE4.SA',
  },
  {
    value: 'MSSA',
    label: 'MSSA,  Metal Sky Star Acquisition Corporation',
    name: 'Metal Sky Star Acquisition Corporation',
    symbol: 'MSSA',
  },
  {
    value: 'ASCBR',
    label: 'ASCBR,  A SPAC II Acquisition Corporation',
    name: 'A SPAC II Acquisition Corporation',
    symbol: 'ASCBR',
  },
  {
    value: 'DECAW',
    label: 'DECAW,  Denali Capital Acquisition Corp.',
    name: 'Denali Capital Acquisition Corp.',
    symbol: 'DECAW',
  },
  {
    value: 'ASCB',
    label: 'ASCB,  A SPAC II Acquisition Corporation',
    name: 'A SPAC II Acquisition Corporation',
    symbol: 'ASCB',
  },
  {
    value: 'MSSAR',
    label: 'MSSAR,  Metal Sky Star Acquisition Corporation',
    name: 'Metal Sky Star Acquisition Corporation',
    symbol: 'MSSAR',
  },
  {
    value: 'ASCBW',
    label: 'ASCBW,  A SPAC II Acquisition Corporation',
    name: 'A SPAC II Acquisition Corporation',
    symbol: 'ASCBW',
  },
  {
    value: 'MSSAW',
    label: 'MSSAW,  Metal Sky Star Acquisition Corporation',
    name: 'Metal Sky Star Acquisition Corporation',
    symbol: 'MSSAW',
  },
  {
    value: 'DECA',
    label: 'DECA,  Denali Capital Acquisition Corp.',
    name: 'Denali Capital Acquisition Corp.',
    symbol: 'DECA',
  },
  {
    value: 'MSPRW',
    label: 'MSPRW,  MSP Recovery, Inc.',
    name: 'MSP Recovery, Inc.',
    symbol: 'MSPRW',
  },
  {
    value: 'BCAN',
    label: 'BCAN,  BYND Cannasoft Enterprises Inc.',
    name: 'BYND Cannasoft Enterprises Inc.',
    symbol: 'BCAN',
  },
  {
    value: 'SPLPETRO.NS',
    label: 'SPLPETRO.NS,  Supreme Petrochem Limited',
    name: 'Supreme Petrochem Limited',
    symbol: 'SPLPETRO.NS',
  },
  {
    value: 'AETHER.NS',
    label: 'AETHER.NS,  Aether Industries Limited',
    name: 'Aether Industries Limited',
    symbol: 'AETHER.NS',
  },
  {
    value: 'CLRCW',
    label: 'CLRCW,  ClimateRock',
    name: 'ClimateRock',
    symbol: 'CLRCW',
  },
  {
    value: 'CLRC',
    label: 'CLRC,  ClimateRock',
    name: 'ClimateRock',
    symbol: 'CLRC',
  },
  {
    value: 'CLRCR',
    label: 'CLRCR,  ClimateRock',
    name: 'ClimateRock',
    symbol: 'CLRCR',
  },
  {
    value: 'AFARW',
    label: 'AFARW,  Aura FAT Projects Acquisition Corp',
    name: 'Aura FAT Projects Acquisition Corp',
    symbol: 'AFARW',
  },
  {
    value: 'AFAR',
    label: 'AFAR,  Aura FAT Projects Acquisition Corp',
    name: 'Aura FAT Projects Acquisition Corp',
    symbol: 'AFAR',
  },
  {
    value: '300790.SZ',
    label: '300790.SZ,  DongGuan YuTong Optical Technology Co.,Ltd.',
    name: 'DongGuan YuTong Optical Technology Co.,Ltd.',
    symbol: '300790.SZ',
  },
  {
    value: 'QTIP.NE',
    label: 'QTIP.NE,  Mackenzie US TIPS Index ETF (CAD-Hedged)',
    name: 'Mackenzie US TIPS Index ETF (CAD-Hedged)',
    symbol: 'QTIP.NE',
  },
  {
    value: '300280.SZ',
    label: '300280.SZ,  Fujian Zitian Media Technology Co., Ltd.',
    name: 'Fujian Zitian Media Technology Co., Ltd.',
    symbol: '300280.SZ',
  },
  {
    value: '300305.SZ',
    label: '300305.SZ,  Jiangsu Yuxing Film Technology Co., Ltd',
    name: 'Jiangsu Yuxing Film Technology Co., Ltd',
    symbol: '300305.SZ',
  },
  {
    value: 'SSW.JO',
    label: 'SSW.JO,  Sibanye Stillwater Limited',
    name: 'Sibanye Stillwater Limited',
    symbol: 'SSW.JO',
  },
  {
    value: 'NRP.JO',
    label: 'NRP.JO,  NEPI Rockcastle N.V.',
    name: 'NEPI Rockcastle N.V.',
    symbol: 'NRP.JO',
  },
  {
    value: 'OMU.JO',
    label: 'OMU.JO,  Old Mutual Limited',
    name: 'Old Mutual Limited',
    symbol: 'OMU.JO',
  },
  {
    value: 'AIL.JO',
    label: 'AIL.JO,  African Rainbow Capital Investments Limited',
    name: 'African Rainbow Capital Investments Limited',
    symbol: 'AIL.JO',
  },
  {
    value: 'TGA.JO',
    label: 'TGA.JO,  Thungela Resources Limited',
    name: 'Thungela Resources Limited',
    symbol: 'TGA.JO',
  },
  {
    value: 'LHC.JO',
    label: 'LHC.JO,  Life Healthcare Group Holdings Limited',
    name: 'Life Healthcare Group Holdings Limited',
    symbol: 'LHC.JO',
  },
  {
    value: 'BAW.JO',
    label: 'BAW.JO,  Barloworld Limited',
    name: 'Barloworld Limited',
    symbol: 'BAW.JO',
  },
  {
    value: 'HCI.JO',
    label: 'HCI.JO,  Hosken Consolidated Investments Limited',
    name: 'Hosken Consolidated Investments Limited',
    symbol: 'HCI.JO',
  },
  {
    value: 'SPG.JO',
    label: 'SPG.JO,  Super Group Limited',
    name: 'Super Group Limited',
    symbol: 'SPG.JO',
  },
  {
    value: 'THA.JO',
    label: 'THA.JO,  Tharisa plc',
    name: 'Tharisa plc',
    symbol: 'THA.JO',
  },
  {
    value: 'REN.JO',
    label: 'REN.JO,  Renergen Limited',
    name: 'Renergen Limited',
    symbol: 'REN.JO',
  },
  {
    value: 'MRF.JO',
    label: 'MRF.JO,  Merafe Resources Limited',
    name: 'Merafe Resources Limited',
    symbol: 'MRF.JO',
  },
  {
    value: 'CLI.JO',
    label: 'CLI.JO,  Clientèle Limited',
    name: 'Clientèle Limited',
    symbol: 'CLI.JO',
  },
  {
    value: 'GML.JO',
    label: 'GML.JO,  Gemfields Group Limited',
    name: 'Gemfields Group Limited',
    symbol: 'GML.JO',
  },
  {
    value: 'SHG.JO',
    label: 'SHG.JO,  Sea Harvest Group Limited',
    name: 'Sea Harvest Group Limited',
    symbol: 'SHG.JO',
  },
  {
    value: 'CAT.JO',
    label: 'CAT.JO,  Caxton and CTP Publishers and Printers Limited',
    name: 'Caxton and CTP Publishers and Printers Limited',
    symbol: 'CAT.JO',
  },
  {
    value: 'SDO.JO',
    label: 'SDO.JO,  Stadio Holdings Limited',
    name: 'Stadio Holdings Limited',
    symbol: 'SDO.JO',
  },
  {
    value: 'TDH.JO',
    label: 'TDH.JO,  Tradehold Limited',
    name: 'Tradehold Limited',
    symbol: 'TDH.JO',
  },
  {
    value: 'IVT.JO',
    label: 'IVT.JO,  Invicta Holdings Limited',
    name: 'Invicta Holdings Limited',
    symbol: 'IVT.JO',
  },
  {
    value: 'LBR.JO',
    label: 'LBR.JO,  Libstar Holdings Limited',
    name: 'Libstar Holdings Limited',
    symbol: 'LBR.JO',
  },
  {
    value: 'HIL.JO',
    label: 'HIL.JO,  HomeChoice International plc',
    name: 'HomeChoice International plc',
    symbol: 'HIL.JO',
  },
  {
    value: 'PPE.JO',
    label: 'PPE.JO,  Purple Group Limited',
    name: 'Purple Group Limited',
    symbol: 'PPE.JO',
  },
  {
    value: 'ACT.JO',
    label: 'ACT.JO,  AfroCentric Investment Corporation Limited',
    name: 'AfroCentric Investment Corporation Limited',
    symbol: 'ACT.JO',
  },
  {
    value: 'SBP.JO',
    label: 'SBP.JO,  Sabvest Capital Limited',
    name: 'Sabvest Capital Limited',
    symbol: 'SBP.JO',
  },
  {
    value: 'SYG.JO',
    label: 'SYG.JO,  Sygnia Limited',
    name: 'Sygnia Limited',
    symbol: 'SYG.JO',
  },
  {
    value: 'CMH.JO',
    label: 'CMH.JO,  Combined Motor Holdings Limited',
    name: 'Combined Motor Holdings Limited',
    symbol: 'CMH.JO',
  },
  {
    value: 'EMH.JO',
    label: 'EMH.JO,  eMedia Holdings Limited',
    name: 'eMedia Holdings Limited',
    symbol: 'EMH.JO',
  },
  {
    value: 'WEZ.JO',
    label: 'WEZ.JO,  Wesizwe Platinum Limited',
    name: 'Wesizwe Platinum Limited',
    symbol: 'WEZ.JO',
  },
  {
    value: 'MDI.JO',
    label: 'MDI.JO,  Master Drilling Group Limited',
    name: 'Master Drilling Group Limited',
    symbol: 'MDI.JO',
  },
  {
    value: 'CTA.JO',
    label: 'CTA.JO,  Capital Appreciation Limited',
    name: 'Capital Appreciation Limited',
    symbol: 'CTA.JO',
  },
  {
    value: 'ACS.JO',
    label: 'ACS.JO,  Acsion Limited',
    name: 'Acsion Limited',
    symbol: 'ACS.JO',
  },
  {
    value: 'BRN.JO',
    label: 'BRN.JO,  Brimstone Investment Corporation Limited',
    name: 'Brimstone Investment Corporation Limited',
    symbol: 'BRN.JO',
  },
  {
    value: 'HLM.JO',
    label: 'HLM.JO,  Hulamin Limited',
    name: 'Hulamin Limited',
    symbol: 'HLM.JO',
  },
  {
    value: 'TTO.JO',
    label: 'TTO.JO,  Trustco Group Holdings Limited',
    name: 'Trustco Group Holdings Limited',
    symbol: 'TTO.JO',
  },
  {
    value: 'FTH.JO',
    label: 'FTH.JO,  Frontier Transport Holdings Limited',
    name: 'Frontier Transport Holdings Limited',
    symbol: 'FTH.JO',
  },
  {
    value: 'ENX.JO',
    label: 'ENX.JO,  enX Group Limited',
    name: 'enX Group Limited',
    symbol: 'ENX.JO',
  },
  {
    value: 'EPE.JO',
    label: 'EPE.JO,  EPE Capital Partners Ltd',
    name: 'EPE Capital Partners Ltd',
    symbol: 'EPE.JO',
  },
  {
    value: 'BEL.JO',
    label: 'BEL.JO,  Bell Equipment Limited',
    name: 'Bell Equipment Limited',
    symbol: 'BEL.JO',
  },
  {
    value: 'GPL.JO',
    label: 'GPL.JO,  Grand Parade Investments Limited',
    name: 'Grand Parade Investments Limited',
    symbol: 'GPL.JO',
  },
  {
    value: 'SFN.JO',
    label: 'SFN.JO,  Sasfin Holdings Limited',
    name: 'Sasfin Holdings Limited',
    symbol: 'SFN.JO',
  },
  {
    value: 'DNB.JO',
    label: 'DNB.JO,  Deneb Investments Limited',
    name: 'Deneb Investments Limited',
    symbol: 'DNB.JO',
  },
  {
    value: 'MMP.JO',
    label: 'MMP.JO,  Marshall Monteagle PLC',
    name: 'Marshall Monteagle PLC',
    symbol: 'MMP.JO',
  },
  {
    value: 'SNV.JO',
    label: 'SNV.JO,  Santova Limited',
    name: 'Santova Limited',
    symbol: 'SNV.JO',
  },
  {
    value: 'QFH.JO',
    label: 'QFH.JO,  Quantum Foods Holdings Ltd',
    name: 'Quantum Foods Holdings Ltd',
    symbol: 'QFH.JO',
  },
  {
    value: 'CHP.JO',
    label: 'CHP.JO,  Choppies Enterprises Limited',
    name: 'Choppies Enterprises Limited',
    symbol: 'CHP.JO',
  },
  {
    value: 'TRE.JO',
    label: 'TRE.JO,  Trencor Limited',
    name: 'Trencor Limited',
    symbol: 'TRE.JO',
  },
  {
    value: 'YRK.JO',
    label: 'YRK.JO,  York Timber Holdings Limited',
    name: 'York Timber Holdings Limited',
    symbol: 'YRK.JO',
  },
  {
    value: 'PBG.JO',
    label: 'PBG.JO,  PBT Group Limited',
    name: 'PBT Group Limited',
    symbol: 'PBG.JO',
  },
  {
    value: 'ART.JO',
    label: 'ART.JO,  Argent Industrial Limited',
    name: 'Argent Industrial Limited',
    symbol: 'ART.JO',
  },
  {
    value: 'TPC.JO',
    label: 'TPC.JO,  Transpaco Limited',
    name: 'Transpaco Limited',
    symbol: 'TPC.JO',
  },
  {
    value: 'BCF.JO',
    label: 'BCF.JO,  Bowler Metcalf Limited',
    name: 'Bowler Metcalf Limited',
    symbol: 'BCF.JO',
  },
  {
    value: 'TMT.JO',
    label: 'TMT.JO,  Trematon Capital Investments Limited',
    name: 'Trematon Capital Investments Limited',
    symbol: 'TMT.JO',
  },
  {
    value: 'SLG.JO',
    label: 'SLG.JO,  Salungano Group Limited',
    name: 'Salungano Group Limited',
    symbol: 'SLG.JO',
  },
  {
    value: 'NVS.JO',
    label: 'NVS.JO,  Novus Holdings Limited',
    name: 'Novus Holdings Limited',
    symbol: 'NVS.JO',
  },
  {
    value: 'OLG.JO',
    label: 'OLG.JO,  OneLogix Group Limited',
    name: 'OneLogix Group Limited',
    symbol: 'OLG.JO',
  },
  {
    value: 'CKS.JO',
    label: 'CKS.JO,  Crookes Brothers Limited',
    name: 'Crookes Brothers Limited',
    symbol: 'CKS.JO',
  },
  {
    value: 'HUG.JO',
    label: 'HUG.JO,  Huge Group Limited',
    name: 'Huge Group Limited',
    symbol: 'HUG.JO',
  },
  {
    value: 'ADR.JO',
    label: 'ADR.JO,  Adcorp Holdings Limited',
    name: 'Adcorp Holdings Limited',
    symbol: 'ADR.JO',
  },
  {
    value: 'NWL.JO',
    label: 'NWL.JO,  Nu-World Holdings Limited',
    name: 'Nu-World Holdings Limited',
    symbol: 'NWL.JO',
  },
  {
    value: 'VUN.JO',
    label: 'VUN.JO,  Vunani Limited',
    name: 'Vunani Limited',
    symbol: 'VUN.JO',
  },
  {
    value: 'ISB.JO',
    label: 'ISB.JO,  Insimbi Industrial Holdings Limited',
    name: 'Insimbi Industrial Holdings Limited',
    symbol: 'ISB.JO',
  },
  {
    value: 'FGL.JO',
    label: 'FGL.JO,  Finbond Group Limited',
    name: 'Finbond Group Limited',
    symbol: 'FGL.JO',
  },
  {
    value: 'CGR.JO',
    label: 'CGR.JO,  Calgro M3 Holdings Limited',
    name: 'Calgro M3 Holdings Limited',
    symbol: 'CGR.JO',
  },
  {
    value: 'WKF.JO',
    label: 'WKF.JO,  Workforce Holdings Limited',
    name: 'Workforce Holdings Limited',
    symbol: 'WKF.JO',
  },
  {
    value: 'ASC.JO',
    label: 'ASC.JO,  Ascendis Health Limited',
    name: 'Ascendis Health Limited',
    symbol: 'ASC.JO',
  },
  {
    value: 'SOH.JO',
    label: 'SOH.JO,  South Ocean Holdings Limited',
    name: 'South Ocean Holdings Limited',
    symbol: 'SOH.JO',
  },
  {
    value: 'MHB.JO',
    label: 'MHB.JO,  Mahube Infrastructure Limited',
    name: 'Mahube Infrastructure Limited',
    symbol: 'MHB.JO',
  },
  {
    value: 'AEE.JO',
    label: 'AEE.JO,  African Equity Empowerment Investments Limited',
    name: 'African Equity Empowerment Investments Limited',
    symbol: 'AEE.JO',
  },
  {
    value: 'SEB.JO',
    label: 'SEB.JO,  Sebata Holdings Limited',
    name: 'Sebata Holdings Limited',
    symbol: 'SEB.JO',
  },
  {
    value: 'SEP.JO',
    label: 'SEP.JO,  Sephaku Holdings Limited',
    name: 'Sephaku Holdings Limited',
    symbol: 'SEP.JO',
  },
  {
    value: 'ARA.JO',
    label: 'ARA.JO,  Astoria Investments Ltd',
    name: 'Astoria Investments Ltd',
    symbol: 'ARA.JO',
  },
  {
    value: 'AME.JO',
    label: 'AME.JO,  African Media Entertainment Limited',
    name: 'African Media Entertainment Limited',
    symbol: 'AME.JO',
  },
  {
    value: 'TRL.JO',
    label: 'TRL.JO,  Trellidor Holdings Limited',
    name: 'Trellidor Holdings Limited',
    symbol: 'TRL.JO',
  },
  {
    value: 'BIK.JO',
    label: 'BIK.JO,  Brikor Limited',
    name: 'Brikor Limited',
    symbol: 'BIK.JO',
  },
  {
    value: 'RHB.JO',
    label: 'RHB.JO,  RH Bophelo Limited',
    name: 'RH Bophelo Limited',
    symbol: 'RHB.JO',
  },
  {
    value: 'ETO.JO',
    label: 'ETO.JO,  Etion Limited',
    name: 'Etion Limited',
    symbol: 'ETO.JO',
  },
  {
    value: 'PFB.JO',
    label: 'PFB.JO,  Premier Fishing & Brands Limited',
    name: 'Premier Fishing & Brands Limited',
    symbol: 'PFB.JO',
  },
  {
    value: 'ELI.JO',
    label: 'ELI.JO,  Ellies Holdings Limited',
    name: 'Ellies Holdings Limited',
    symbol: 'ELI.JO',
  },
  {
    value: 'ISA.JO',
    label: 'ISA.JO,  ISA Holdings Limited',
    name: 'ISA Holdings Limited',
    symbol: 'ISA.JO',
  },
  {
    value: 'PPR.JO',
    label: 'PPR.JO,  Putprop Limited',
    name: 'Putprop Limited',
    symbol: 'PPR.JO',
  },
  {
    value: 'BUC.JO',
    label: 'BUC.JO,  Buffalo Coal Corp.',
    name: 'Buffalo Coal Corp.',
    symbol: 'BUC.JO',
  },
  {
    value: 'AVL.JO',
    label: 'AVL.JO,  Advanced Health Limited',
    name: 'Advanced Health Limited',
    symbol: 'AVL.JO',
  },
  {
    value: 'CGN.JO',
    label: 'CGN.JO,  Cognition Holdings Limited',
    name: 'Cognition Holdings Limited',
    symbol: 'CGN.JO',
  },
  {
    value: 'GLI.JO',
    label: 'GLI.JO,  Go Life International Limited',
    name: 'Go Life International Limited',
    symbol: 'GLI.JO',
  },
  {
    value: '4SI.JO',
    label: '4SI.JO,  4Sight Holdings Limited',
    name: '4Sight Holdings Limited',
    symbol: '4SI.JO',
  },
  {
    value: 'LAB.JO',
    label: 'LAB.JO,  Labat Africa Limited',
    name: 'Labat Africa Limited',
    symbol: 'LAB.JO',
  },
  {
    value: 'JSC.JO',
    label: 'JSC.JO,  Jasco Electronics Holdings Limited',
    name: 'Jasco Electronics Holdings Limited',
    symbol: 'JSC.JO',
  },
  {
    value: 'PMV.JO',
    label: 'PMV.JO,  Primeserv Group Limited',
    name: 'Primeserv Group Limited',
    symbol: 'PMV.JO',
  },
  {
    value: 'SSK.JO',
    label: 'SSK.JO,  Stefanutti Stocks Holdings Limited',
    name: 'Stefanutti Stocks Holdings Limited',
    symbol: 'SSK.JO',
  },
  {
    value: 'PEV',
    label: 'PEV,  Phoenix Motor Inc.',
    name: 'Phoenix Motor Inc.',
    symbol: 'PEV',
  },
  {
    value: 'RNG.JO',
    label: 'RNG.JO,  Randgold & Exploration Company Limited',
    name: 'Randgold & Exploration Company Limited',
    symbol: 'RNG.JO',
  },
  {
    value: 'TLM.JO',
    label: 'TLM.JO,  TeleMasters Holdings Limited',
    name: 'TeleMasters Holdings Limited',
    symbol: 'TLM.JO',
  },
  {
    value: 'AHL.JO',
    label: 'AHL.JO,  AH-Vest Limited',
    name: 'AH-Vest Limited',
    symbol: 'AHL.JO',
  },
  {
    value: 'NCS.JO',
    label: 'NCS.JO,  Nictus Limited',
    name: 'Nictus Limited',
    symbol: 'NCS.JO',
  },
  {
    value: 'VIS.JO',
    label: 'VIS.JO,  Visual International Holdings Limited',
    name: 'Visual International Holdings Limited',
    symbol: 'VIS.JO',
  },
  {
    value: 'ADW.JO',
    label: 'ADW.JO,  African Dawn Capital Limited',
    name: 'African Dawn Capital Limited',
    symbol: 'ADW.JO',
  },
  {
    value: 'META',
    label: 'META,  Meta Platforms, Inc.',
    name: 'Meta Platforms, Inc.',
    symbol: 'META',
  },
  {
    value: 'UDI',
    label: 'UDI,  USCF ESG Dividend Income Fund',
    name: 'USCF ESG Dividend Income Fund',
    symbol: 'UDI',
  },
  {
    value: 'CNXA',
    label: 'CNXA,  Connexa Sports Technologies Inc.',
    name: 'Connexa Sports Technologies Inc.',
    symbol: 'CNXA',
  },
  {
    value: 'TRFK',
    label: 'TRFK,  Pacer Data and Digital Revolution ETF',
    name: 'Pacer Data and Digital Revolution ETF',
    symbol: 'TRFK',
  },
  {
    value: 'LONZ',
    label: 'LONZ,  PIMCO Senior Loan Active Exchange-Traded Fund',
    name: 'PIMCO Senior Loan Active Exchange-Traded Fund',
    symbol: 'LONZ',
  },
  {
    value: '2347.HK',
    label: '2347.HK,  Yoho Group Holdings Ltd.',
    name: 'Yoho Group Holdings Ltd.',
    symbol: '2347.HK',
  },
  {
    value: '7827.HK',
    label: '7827.HK,  Vision Deal HK Acquisition Corp.',
    name: 'Vision Deal HK Acquisition Corp.',
    symbol: '7827.HK',
  },
  {
    value: 'MOTHERSON.NS',
    label: 'MOTHERSON.NS,  Samvardhana Motherson International Limited',
    name: 'Samvardhana Motherson International Limited',
    symbol: 'MOTHERSON.NS',
  },
  {
    value: '300415.SZ',
    label: '300415.SZ,  Guangdong Yizumi Precision Machinery Co.,Ltd.',
    name: 'Guangdong Yizumi Precision Machinery Co.,Ltd.',
    symbol: '300415.SZ',
  },
  {
    value: '300428.SZ',
    label: '300428.SZ,  Lizhong Sitong Light Alloys Group Co., Ltd.',
    name: 'Lizhong Sitong Light Alloys Group Co., Ltd.',
    symbol: '300428.SZ',
  },
  {
    value: '6778.T',
    label: '6778.T,  Artiza Networks, Inc.',
    name: 'Artiza Networks, Inc.',
    symbol: '6778.T',
  },
  {
    value: '9233.T',
    label: '9233.T,  Asia Air Survey Co., Ltd.',
    name: 'Asia Air Survey Co., Ltd.',
    symbol: '9233.T',
  },
  {
    value: '2185.T',
    label: '2185.T,  CMC Corporation',
    name: 'CMC Corporation',
    symbol: '2185.T',
  },
  {
    value: '1799.T',
    label: '1799.T,  Daiichi Kensetsu Corporation',
    name: 'Daiichi Kensetsu Corporation',
    symbol: '1799.T',
  },
  {
    value: '9906.T',
    label: '9906.T,  Fujii Sangyo Corporation',
    name: 'Fujii Sangyo Corporation',
    symbol: '9906.T',
  },
  {
    value: 'AFRIW',
    label: 'AFRIW,  Forafric Global PLC',
    name: 'Forafric Global PLC',
    symbol: 'AFRIW',
  },
  {
    value: 'AFRI',
    label: 'AFRI,  Forafric Global PLC',
    name: 'Forafric Global PLC',
    symbol: 'AFRI',
  },
  {
    value: '7185.T',
    label: '7185.T,  Hirose Tusyo Inc.',
    name: 'Hirose Tusyo Inc.',
    symbol: '7185.T',
  },
  {
    value: '9964.T',
    label: '9964.T,  Itec Corporation',
    name: 'Itec Corporation',
    symbol: '9964.T',
  },
  {
    value: '9059.T',
    label: '9059.T,  Kanda Holdings Co.,Ltd.',
    name: 'Kanda Holdings Co.,Ltd.',
    symbol: '9059.T',
  },
  {
    value: '6484.T',
    label: '6484.T,  KVK Corporation',
    name: 'KVK Corporation',
    symbol: '6484.T',
  },
  {
    value: '5982.T',
    label: '5982.T,  Maruzen Co., Ltd.',
    name: 'Maruzen Co., Ltd.',
    symbol: '5982.T',
  },
  {
    value: '9845.T',
    label: '9845.T,  Parker Corporation',
    name: 'Parker Corporation',
    symbol: '9845.T',
  },
  {
    value: '4685.T',
    label: '4685.T,  Ryoyu Systems Co., Ltd.',
    name: 'Ryoyu Systems Co., Ltd.',
    symbol: '4685.T',
  },
  {
    value: 'NAAS',
    label: 'NAAS,  NaaS Technology Inc.',
    name: 'NaaS Technology Inc.',
    symbol: 'NAAS',
  },
  {
    value: 'CMRA',
    label: 'CMRA,  Comera Life Sciences Holdings, Inc.',
    name: 'Comera Life Sciences Holdings, Inc.',
    symbol: 'CMRA',
  },
  {
    value: 'STR',
    label: 'STR,  Sitio Royalties Corp.',
    name: 'Sitio Royalties Corp.',
    symbol: 'STR',
  },
  {
    value: '0P0000VHNS.F',
    label:
      '0P0000VHNS.F,  BlackRock Global Funds - World Energy Fund A4 EUR Inc',
    name: 'BlackRock Global Funds - World Energy Fund A4 EUR Inc',
    symbol: '0P0000VHNS.F',
  },
  {
    value: '300969.SZ',
    label: '300969.SZ,  NINGBO HENGSHUAI Co., LTD.',
    name: 'NINGBO HENGSHUAI Co., LTD.',
    symbol: '300969.SZ',
  },
  {
    value: 'TRAC.CN',
    label: 'TRAC.CN,  Traction Uranium Corp.',
    name: 'Traction Uranium Corp.',
    symbol: 'TRAC.CN',
  },
  {
    value: '300745.SZ',
    label: '300745.SZ,  Shinry Technologies Co., Ltd.',
    name: 'Shinry Technologies Co., Ltd.',
    symbol: '300745.SZ',
  },
  {
    value: '300821.SZ',
    label: '300821.SZ,  Shandong Dongyue Organosilicon Materials Co., Ltd.',
    name: 'Shandong Dongyue Organosilicon Materials Co., Ltd.',
    symbol: '300821.SZ',
  },
  {
    value: '300727.SZ',
    label: '300727.SZ,  Ningbo Runhe High-Tech Materials Co., Ltd.',
    name: 'Ningbo Runhe High-Tech Materials Co., Ltd.',
    symbol: '300727.SZ',
  },
  {
    value: 'HSCS',
    label: 'HSCS,  Heart Test Laboratories, Inc.',
    name: 'Heart Test Laboratories, Inc.',
    symbol: 'HSCS',
  },
  {
    value: '6667.HK',
    label: '6667.HK,  Mega Genomics Limited',
    name: 'Mega Genomics Limited',
    symbol: '6667.HK',
  },
  {
    value: 'HSCSW',
    label: 'HSCSW,  Heart Test Laboratories, Inc.',
    name: 'Heart Test Laboratories, Inc.',
    symbol: 'HSCSW',
  },
  {
    value: 'ALFUM.PA',
    label: 'ALFUM.PA,  Fill Up Media S.A.',
    name: 'Fill Up Media S.A.',
    symbol: 'ALFUM.PA',
  },
  {
    value: 'JIRE',
    label: 'JIRE,  JPMorgan International Research Enhanced Equity ETF',
    name: 'JPMorgan International Research Enhanced Equity ETF',
    symbol: 'JIRE',
  },
  {
    value: 'DSGR',
    label: 'DSGR,  Distribution Solutions Group, Inc.',
    name: 'Distribution Solutions Group, Inc.',
    symbol: 'DSGR',
  },
  {
    value: 'SYM',
    label: 'SYM,  Symbotic Inc.',
    name: 'Symbotic Inc.',
    symbol: 'SYM',
  },
  {
    value: 'AIMBU',
    label: 'AIMBU,  Aimfinity Investment Corp. I',
    name: 'Aimfinity Investment Corp. I',
    symbol: 'AIMBU',
  },
  {
    value: 'NEOV',
    label: 'NEOV,  NeoVolta Inc.',
    name: 'NeoVolta Inc.',
    symbol: 'NEOV',
  },
  {
    value: 'SKGRU',
    label: 'SKGRU,  SK Growth Opportunities Corporation',
    name: 'SK Growth Opportunities Corporation',
    symbol: 'SKGRU',
  },
  {
    value: '2372.HK',
    label: '2372.HK,  WEIli Holdings Limited',
    name: 'WEIli Holdings Limited',
    symbol: '2372.HK',
  },
  {
    value: 'GBBK',
    label: 'GBBK,  Global Blockchain Acquisition Corp.',
    name: 'Global Blockchain Acquisition Corp.',
    symbol: 'GBBK',
  },
  {
    value: 'AIMAW',
    label: 'AIMAW,  Aimfinity Investment Corp. I',
    name: 'Aimfinity Investment Corp. I',
    symbol: 'AIMAW',
  },
  {
    value: 'GBBKW',
    label: 'GBBKW,  Global Blockchain Acquisition Corp.',
    name: 'Global Blockchain Acquisition Corp.',
    symbol: 'GBBKW',
  },
  {
    value: 'GBBKR',
    label: 'GBBKR,  Global Blockchain Acquisition Corp.',
    name: 'Global Blockchain Acquisition Corp.',
    symbol: 'GBBKR',
  },
  {
    value: 'ALVO',
    label: 'ALVO,  Alvotech',
    name: 'Alvotech',
    symbol: 'ALVO',
  },
  {
    value: 'ALVOW',
    label: 'ALVOW,  Alvotech',
    name: 'Alvotech',
    symbol: 'ALVOW',
  },
  {
    value: 'FLFVU',
    label: 'FLFVU,  Feutune Light Acquisition Corporation',
    name: 'Feutune Light Acquisition Corporation',
    symbol: 'FLFVU',
  },
  {
    value: 'DIDIY',
    label: 'DIDIY,  DiDi Global Inc.',
    name: 'DiDi Global Inc.',
    symbol: 'DIDIY',
  },
  {
    value: 'IPX',
    label: 'IPX,  IperionX Limited',
    name: 'IperionX Limited',
    symbol: 'IPX',
  },
  {
    value: 'GSUN',
    label: 'GSUN,  Golden Sun Education Group Limited',
    name: 'Golden Sun Education Group Limited',
    symbol: 'GSUN',
  },
  {
    value: '2167.HK',
    label: '2167.HK,  TI Cloud Inc.',
    name: 'TI Cloud Inc.',
    symbol: '2167.HK',
  },
  {
    value: 'IE',
    label: 'IE,  Ivanhoe Electric Inc.',
    name: 'Ivanhoe Electric Inc.',
    symbol: 'IE',
  },
  {
    value: '9955.HK',
    label: '9955.HK,  ClouDr Group Limited',
    name: 'ClouDr Group Limited',
    symbol: '9955.HK',
  },
  {
    value: 'SMR',
    label: 'SMR,  NuScale Power Corporation',
    name: 'NuScale Power Corporation',
    symbol: 'SMR',
  },
  {
    value: 'KSPI.IL',
    label: 'KSPI.IL,  Joint Stock Company Kaspi.kz',
    name: 'Joint Stock Company Kaspi.kz',
    symbol: 'KSPI.IL',
  },
  {
    value: 'PARAA',
    label: 'PARAA,  Paramount Global',
    name: 'Paramount Global',
    symbol: 'PARAA',
  },
  {
    value: 'RENEW',
    label: 'RENEW,  Cartesian Growth Corporation II',
    name: 'Cartesian Growth Corporation II',
    symbol: 'RENEW',
  },
  {
    value: 'YOTAW',
    label: 'YOTAW,  Yotta Acquisition Corporation',
    name: 'Yotta Acquisition Corporation',
    symbol: 'YOTAW',
  },
  {
    value: 'RENE',
    label: 'RENE,  Cartesian Growth Corporation II',
    name: 'Cartesian Growth Corporation II',
    symbol: 'RENE',
  },
  {
    value: 'YOTAR',
    label: 'YOTAR,  Yotta Acquisition Corporation',
    name: 'Yotta Acquisition Corporation',
    symbol: 'YOTAR',
  },
  {
    value: 'YOTA',
    label: 'YOTA,  Yotta Acquisition Corporation',
    name: 'Yotta Acquisition Corporation',
    symbol: 'YOTA',
  },
  {
    value: '2297.HK',
    label: '2297.HK,  Rainmed Medical Limited',
    name: 'Rainmed Medical Limited',
    symbol: '2297.HK',
  },
  {
    value: 'PSNY',
    label: 'PSNY,  Polestar Automotive Holding UK PLC',
    name: 'Polestar Automotive Holding UK PLC',
    symbol: 'PSNY',
  },
  {
    value: 'PSNYW',
    label: 'PSNYW,  Polestar Automotive Holding UK PLC',
    name: 'Polestar Automotive Holding UK PLC',
    symbol: 'PSNYW',
  },
  {
    value: 'MOB',
    label: 'MOB,  Mobilicom Limited',
    name: 'Mobilicom Limited',
    symbol: 'MOB',
  },
  {
    value: '2392.HK',
    label: '2392.HK,  Xuan Wu Cloud Technology Holdings Limited',
    name: 'Xuan Wu Cloud Technology Holdings Limited',
    symbol: '2392.HK',
  },
  {
    value: 'INTR',
    label: 'INTR,  Inter & Co, Inc.',
    name: 'Inter & Co, Inc.',
    symbol: 'INTR',
  },
  {
    value: 'CHEA',
    label: 'CHEA,  Chenghe Acquisition Co.',
    name: 'Chenghe Acquisition Co.',
    symbol: 'CHEA',
  },
  {
    value: 'PGYWW',
    label: 'PGYWW,  Pagaya Technologies Ltd.',
    name: 'Pagaya Technologies Ltd.',
    symbol: 'PGYWW',
  },
  {
    value: 'CHEAW',
    label: 'CHEAW,  Chenghe Acquisition Co.',
    name: 'Chenghe Acquisition Co.',
    symbol: 'CHEAW',
  },
  {
    value: 'PGY',
    label: 'PGY,  Pagaya Technologies Ltd.',
    name: 'Pagaya Technologies Ltd.',
    symbol: 'PGY',
  },
  {
    value: 'ONFO',
    label: 'ONFO,  Onfolio Holdings, Inc.',
    name: 'Onfolio Holdings, Inc.',
    symbol: 'ONFO',
  },
  {
    value: 'ONFOW',
    label: 'ONFOW,  Onfolio Holdings, Inc.',
    name: 'Onfolio Holdings, Inc.',
    symbol: 'ONFOW',
  },
  {
    value: 'IONR',
    label: 'IONR,  ioneer Ltd',
    name: 'ioneer Ltd',
    symbol: 'IONR',
  },
  {
    value: 'KAL',
    label: 'KAL,  Kalera Public Limited Company',
    name: 'Kalera Public Limited Company',
    symbol: 'KAL',
  },
  {
    value: 'AYEU.DE',
    label: 'AYEU.DE,  iShares Smart City Infrastructure UCITS ETF',
    name: 'iShares Smart City Infrastructure UCITS ETF',
    symbol: 'AYEU.DE',
  },
  {
    value: 'IQQI.DE',
    label: 'IQQI.DE,  iShares Global Infrastructure UCITS ETF',
    name: 'iShares Global Infrastructure UCITS ETF',
    symbol: 'IQQI.DE',
  },
  {
    value: 'LWCR.DE',
    label:
      'LWCR.DE,  Amundi MSCI World Climate Transition CTB - UCITS ETF DR - EUR-C',
    name: 'Amundi MSCI World Climate Transition CTB - UCITS ETF DR - EUR-C',
    symbol: 'LWCR.DE',
  },
  {
    value: 'UEEG.DE',
    label: 'UEEG.DE,  iShares $ Development Bank Bonds UCITS ETF (Acc)',
    name: 'iShares $ Development Bank Bonds UCITS ETF (Acc)',
    symbol: 'UEEG.DE',
  },
  {
    value: 'IQQH.DE',
    label: 'IQQH.DE,  iShares Global Clean Energy UCITS ETF USD (Dist)',
    name: 'iShares Global Clean Energy UCITS ETF USD (Dist)',
    symbol: 'IQQH.DE',
  },
  {
    value: 'KALWW',
    label: 'KALWW,  Kalera Public Limited Company',
    name: 'Kalera Public Limited Company',
    symbol: 'KALWW',
  },
  {
    value: 'IMCC.CN',
    label: 'IMCC.CN,  IM Cannabis Corp.',
    name: 'IM Cannabis Corp.',
    symbol: 'IMCC.CN',
  },
  {
    value: 'DME.V',
    label: 'DME.V,  Desert Mountain Energy Corp.',
    name: 'Desert Mountain Energy Corp.',
    symbol: 'DME.V',
  },
  {
    value: 'YPFD.BA',
    label: 'YPFD.BA,  YPF Sociedad Anónima',
    name: 'YPF Sociedad Anónima',
    symbol: 'YPFD.BA',
  },
  {
    value: 'IVCA',
    label: 'IVCA,  Investcorp India Acquisition Corp',
    name: 'Investcorp India Acquisition Corp',
    symbol: 'IVCA',
  },
  {
    value: 'JEPQ',
    label: 'JEPQ,  JPMorgan Nasdaq Equity Premium Income ETF',
    name: 'JPMorgan Nasdaq Equity Premium Income ETF',
    symbol: 'JEPQ',
  },
  {
    value: 'WAT.PA',
    label: 'WAT.PA,  Lyxor World Water (DR) UCITS ETF Dist',
    name: 'Lyxor World Water (DR) UCITS ETF Dist',
    symbol: 'WAT.PA',
  },
  {
    value: 'SNAW.DE',
    label: 'SNAW.DE,  iShares MSCI World ESG Screened UCITS ETF USD (Acc)',
    name: 'iShares MSCI World ESG Screened UCITS ETF USD (Acc)',
    symbol: 'SNAW.DE',
  },
  {
    value: 'F500.DE',
    label: 'F500.DE,  Amundi Index Solutions - Amundi S&P 500 ESG',
    name: 'Amundi Index Solutions - Amundi S&P 500 ESG',
    symbol: 'F500.DE',
  },
  {
    value: 'LCHI.F',
    label: 'LCHI.F,  Lyxor MSCI China ESG Leaders Extra (DR) UCITS ETF',
    name: 'Lyxor MSCI China ESG Leaders Extra (DR) UCITS ETF',
    symbol: 'LCHI.F',
  },
  {
    value: 'USEA',
    label: 'USEA,  United Maritime Corporation',
    name: 'United Maritime Corporation',
    symbol: 'USEA',
  },
  {
    value: 'GROV',
    label: 'GROV,  Grove Collaborative Holdings, Inc.',
    name: 'Grove Collaborative Holdings, Inc.',
    symbol: 'GROV',
  },
  {
    value: 'IVCAW',
    label: 'IVCAW,  Investcorp India Acquisition Corp',
    name: 'Investcorp India Acquisition Corp',
    symbol: 'IVCAW',
  },
  {
    value: 'EHAB',
    label: 'EHAB,  Enhabit, Inc.',
    name: 'Enhabit, Inc.',
    symbol: 'EHAB',
  },
  {
    value: 'IBLC',
    label: 'IBLC,  iShares Blockchain and Tech ETF',
    name: 'iShares Blockchain and Tech ETF',
    symbol: 'IBLC',
  },
  {
    value: 'PSYK',
    label: 'PSYK,  PSYK ETF',
    name: 'PSYK ETF',
    symbol: 'PSYK',
  },
  {
    value: 'NHWK',
    label: 'NHWK,  NightHawk Biosciences, Inc.',
    name: 'NightHawk Biosciences, Inc.',
    symbol: 'NHWK',
  },
  {
    value: 'UVIX',
    label: 'UVIX,  2x Long VIX Futures ETF',
    name: '2x Long VIX Futures ETF',
    symbol: 'UVIX',
  },
  {
    value: 'NXDT',
    label: 'NXDT,  NexPoint Diversified Real Estate Trust',
    name: 'NexPoint Diversified Real Estate Trust',
    symbol: 'NXDT',
  },
  {
    value: 'AIU',
    label: 'AIU,  Meta Data Limited',
    name: 'Meta Data Limited',
    symbol: 'AIU',
  },
  {
    value: 'HOUS',
    label: 'HOUS,  Anywhere Real Estate Inc.',
    name: 'Anywhere Real Estate Inc.',
    symbol: 'HOUS',
  },
  {
    value: 'LCTD',
    label: 'LCTD,  BlackRock World ex U.S. Carbon Transition Readiness ETF',
    name: 'BlackRock World ex U.S. Carbon Transition Readiness ETF',
    symbol: 'LCTD',
  },
  {
    value: 'WKLY',
    label: 'WKLY,  SoFi Weekly Dividend ETF',
    name: 'SoFi Weekly Dividend ETF',
    symbol: 'WKLY',
  },
  {
    value: 'PEGY',
    label: 'PEGY,  Pineapple Energy Inc.',
    name: 'Pineapple Energy Inc.',
    symbol: 'PEGY',
  },
  {
    value: 'FTQI',
    label: 'FTQI,  First Trust Nasdaq BuyWrite Income ETF',
    name: 'First Trust Nasdaq BuyWrite Income ETF',
    symbol: 'FTQI',
  },
  {
    value: 'MFLX',
    label: 'MFLX,  First Trust Flexible Municipal High Income ETF',
    name: 'First Trust Flexible Municipal High Income ETF',
    symbol: 'MFLX',
  },
  {
    value: 'ISHP',
    label: 'ISHP,  First Trust S-Network Global E-Commerce ETF',
    name: 'First Trust S-Network Global E-Commerce ETF',
    symbol: 'ISHP',
  },
  {
    value: 'NZAC',
    label: 'NZAC,  SPDR MSCI ACWI Climate Paris Aligned ETF',
    name: 'SPDR MSCI ACWI Climate Paris Aligned ETF',
    symbol: 'NZAC',
  },
  {
    value: 'HISF',
    label: 'HISF,  First Trust High Income Strategic Focus ETF',
    name: 'First Trust High Income Strategic Focus ETF',
    symbol: 'HISF',
  },
  {
    value: 'FNGG',
    label: 'FNGG,  Direxion Daily Select Large Caps & FANGs Bull 2X Shares',
    name: 'Direxion Daily Select Large Caps & FANGs Bull 2X Shares',
    symbol: 'FNGG',
  },
  {
    value: 'BITQ',
    label: 'BITQ,  Bitwise Crypto Industry Innovators ETF',
    name: 'Bitwise Crypto Industry Innovators ETF',
    symbol: 'BITQ',
  },
  {
    value: 'SARK',
    label: 'SARK,  AXS Short Innovation Daily ETF',
    name: 'AXS Short Innovation Daily ETF',
    symbol: 'SARK',
  },
  {
    value: 'NFTZ',
    label: 'NFTZ,  Defiance Digital Revolution ETF',
    name: 'Defiance Digital Revolution ETF',
    symbol: 'NFTZ',
  },
  {
    value: 'HORIU',
    label: 'HORIU,  Emerging Markets Horizon Corp.',
    name: 'Emerging Markets Horizon Corp.',
    symbol: 'HORIU',
  },
  {
    value: 'QRMI',
    label: 'QRMI,  Global X NASDAQ 100 Risk Managed Income ETF',
    name: 'Global X NASDAQ 100 Risk Managed Income ETF',
    symbol: 'QRMI',
  },
  {
    value: 'XRMI',
    label: 'XRMI,  Global X S&P 500 Risk Managed Income ETF',
    name: 'Global X S&P 500 Risk Managed Income ETF',
    symbol: 'XRMI',
  },
  {
    value: 'RISR',
    label: 'RISR,  FolioBeyond Rising Rates ETF',
    name: 'FolioBeyond Rising Rates ETF',
    symbol: 'RISR',
  },
  {
    value: 'METV',
    label: 'METV,  Roundhill Ball Metaverse ETF',
    name: 'Roundhill Ball Metaverse ETF',
    symbol: 'METV',
  },
  {
    value: 'MSGR',
    label: 'MSGR,  Direxion mRNA ETF',
    name: 'Direxion mRNA ETF',
    symbol: 'MSGR',
  },
  {
    value: 'KEUA',
    label: 'KEUA,  KraneShares European Carbon Allowance Strategy ETF',
    name: 'KraneShares European Carbon Allowance Strategy ETF',
    symbol: 'KEUA',
  },
  {
    value: 'PGRU',
    label: 'PGRU,  PropertyGuru Limited',
    name: 'PropertyGuru Limited',
    symbol: 'PGRU',
  },
  {
    value: 'XELAP',
    label: 'XELAP,  Exela Technologies, Inc.',
    name: 'Exela Technologies, Inc.',
    symbol: 'XELAP',
  },
  {
    value: 'BTHM',
    label: 'BTHM,  Blackrock Future U.S. Themes ETF',
    name: 'Blackrock Future U.S. Themes ETF',
    symbol: 'BTHM',
  },
  {
    value: 'DFIV',
    label: 'DFIV,  Dimensional International Value ETF',
    name: 'Dimensional International Value ETF',
    symbol: 'DFIV',
  },
  {
    value: 'SOGU',
    label: 'SOGU,  AXS Short De-SPAC Daily ETF',
    name: 'AXS Short De-SPAC Daily ETF',
    symbol: 'SOGU',
  },
  {
    value: 'GGR',
    label: 'GGR,  Gogoro Inc.',
    name: 'Gogoro Inc.',
    symbol: 'GGR',
  },
  {
    value: 'WDS',
    label: 'WDS,  Woodside Energy Group Ltd',
    name: 'Woodside Energy Group Ltd',
    symbol: 'WDS',
  },
  {
    value: 'TARK',
    label: 'TARK,  AXS 2X Innovation ETF',
    name: 'AXS 2X Innovation ETF',
    symbol: 'TARK',
  },
  {
    value: 'EGIO',
    label: 'EGIO,  Edgio, Inc.',
    name: 'Edgio, Inc.',
    symbol: 'EGIO',
  },
  {
    value: 'NA',
    label: 'NA,  Nano Labs Ltd',
    name: 'Nano Labs Ltd',
    symbol: 'NA',
  },
  {
    value: 'SEMI',
    label: 'SEMI,  Columbia Seligman Semiconductor & Technology ETF',
    name: 'Columbia Seligman Semiconductor & Technology ETF',
    symbol: 'SEMI',
  },
  {
    value: 'NEWYY',
    label: 'NEWYY,  Puxin Limited',
    name: 'Puxin Limited',
    symbol: 'NEWYY',
  },
  {
    value: 'SYNE3.SA',
    label: 'SYNE3.SA,  SYN prop e tech S.A.',
    name: 'SYN prop e tech S.A.',
    symbol: 'SYNE3.SA',
  },
  {
    value: 'ELV',
    label: 'ELV,  Elevance Health Inc.',
    name: 'Elevance Health Inc.',
    symbol: 'ELV',
  },
  {
    value: '300351.SZ',
    label: '300351.SZ,  Zhejiang Yonggui Electric Equipment Co., Ltd.',
    name: 'Zhejiang Yonggui Electric Equipment Co., Ltd.',
    symbol: '300351.SZ',
  },
  {
    value: '300438.SZ',
    label: '300438.SZ,  Guangzhou Great Power Energy and Technology Co., Ltd',
    name: 'Guangzhou Great Power Energy and Technology Co., Ltd',
    symbol: '300438.SZ',
  },
  {
    value: '300093.SZ',
    label: '300093.SZ,  Gansu Jingang Solar Co., Ltd',
    name: 'Gansu Jingang Solar Co., Ltd',
    symbol: '300093.SZ',
  },
  {
    value: '300586.SZ',
    label: '300586.SZ,  Malion New Materials Co., Ltd.',
    name: 'Malion New Materials Co., Ltd.',
    symbol: '300586.SZ',
  },
  {
    value: '300363.SZ',
    label: '300363.SZ,  Porton Pharma Solutions Ltd.',
    name: 'Porton Pharma Solutions Ltd.',
    symbol: '300363.SZ',
  },
  {
    value: 'FSLD',
    label: 'FSLD,  Fidelity Sustainable Low Duration Bond ETF',
    name: 'Fidelity Sustainable Low Duration Bond ETF',
    symbol: 'FSLD',
  },
  {
    value: 'ILAG',
    label: 'ILAG,  Intelligent Living Application Group Inc.',
    name: 'Intelligent Living Application Group Inc.',
    symbol: 'ILAG',
  },
  {
    value: 'FTDS',
    label: 'FTDS,  First Trust Dividend Strength ETF',
    name: 'First Trust Dividend Strength ETF',
    symbol: 'FTDS',
  },
  {
    value: 'GDIV',
    label: 'GDIV,  Harbor Dividend Growth Leaders ETF',
    name: 'Harbor Dividend Growth Leaders ETF',
    symbol: 'GDIV',
  },
  {
    value: 'GUSA',
    label: 'GUSA,  Goldman Sachs MarketBeta U.S. 1000 Equity ETF',
    name: 'Goldman Sachs MarketBeta U.S. 1000 Equity ETF',
    symbol: 'GUSA',
  },
  {
    value: 'INQQ',
    label: 'INQQ,  India Internet & Ecommerce ETF',
    name: 'India Internet & Ecommerce ETF',
    symbol: 'INQQ',
  },
  {
    value: 'IVEG',
    label: 'IVEG,  iShares Emergent Food and AgTech Multisector ETF',
    name: 'iShares Emergent Food and AgTech Multisector ETF',
    symbol: 'IVEG',
  },
  {
    value: 'IRHG',
    label: 'IRHG,  Global X Interest Rate Hedge ETF',
    name: 'Global X Interest Rate Hedge ETF',
    symbol: 'IRHG',
  },
  {
    value: 'IRVH',
    label: 'IRVH,  Global X Interest Rate Volatility & Inflation Hedge ETF',
    name: 'Global X Interest Rate Volatility & Inflation Hedge ETF',
    symbol: 'IRVH',
  },
  {
    value: 'JCPI',
    label: 'JCPI,  JPMorgan Inflation Managed Bond ETF',
    name: 'JPMorgan Inflation Managed Bond ETF',
    symbol: 'JCPI',
  },
  {
    value: 'JMEE',
    label: 'JMEE,  JPMorgan Market Expansion Enhanced Equity ETF',
    name: 'JPMorgan Market Expansion Enhanced Equity ETF',
    symbol: 'JMEE',
  },
  {
    value: 'JPRE',
    label: 'JPRE,  JPMorgan Realty Income ETF',
    name: 'JPMorgan Realty Income ETF',
    symbol: 'JPRE',
  },
  {
    value: 'KSET',
    label: 'KSET,  KraneShares Global Carbon Offset Strategy ETF',
    name: 'KraneShares Global Carbon Offset Strategy ETF',
    symbol: 'KSET',
  },
  {
    value: 'LQIG',
    label: 'LQIG,  SPDR MarketAxess Investment Grade 400 Corporate Bond ETF',
    name: 'SPDR MarketAxess Investment Grade 400 Corporate Bond ETF',
    symbol: 'LQIG',
  },
  {
    value: 'MBNE',
    label: 'MBNE,  SPDR Nuveen Municipal Bond ESG ETF',
    name: 'SPDR Nuveen Municipal Bond ESG ETF',
    symbol: 'MBNE',
  },
  {
    value: 'MNBD',
    label: 'MNBD,  ALPS Intermediate Municipal Bond ETF',
    name: 'ALPS Intermediate Municipal Bond ETF',
    symbol: 'MNBD',
  },
  {
    value: 'MOOD',
    label: 'MOOD,  Relative Sentiment Tactical Allocation ETF',
    name: 'Relative Sentiment Tactical Allocation ETF',
    symbol: 'MOOD',
  },
  {
    value: 'MPAY',
    label: 'MPAY,  Akros Monthly Payout ETF',
    name: 'Akros Monthly Payout ETF',
    symbol: 'MPAY',
  },
  {
    value: 'NBCC',
    label: 'NBCC,  Neuberger Berman Next Generation Connected Consumer ETF',
    name: 'Neuberger Berman Next Generation Connected Consumer ETF',
    symbol: 'NBCC',
  },
  {
    value: 'NBCT',
    label: 'NBCT,  Neuberger Berman Carbon Transition & Infrastructure ETF',
    name: 'Neuberger Berman Carbon Transition & Infrastructure ETF',
    symbol: 'NBCT',
  },
  {
    value: 'NBDS',
    label: 'NBDS,  Neuberger Berman Disrupters ETF',
    name: 'Neuberger Berman Disrupters ETF',
    symbol: 'NBDS',
  },
  {
    value: 'XEMD',
    label: 'XEMD,  BondBloxx JP Morgan USD Emerging Markets 1-10 Year Bond ETF',
    name: 'BondBloxx JP Morgan USD Emerging Markets 1-10 Year Bond ETF',
    symbol: 'XEMD',
  },
  {
    value: 'GRRR',
    label: 'GRRR,  Gorilla Technology Group Inc.',
    name: 'Gorilla Technology Group Inc.',
    symbol: 'GRRR',
  },
  {
    value: 'ROCI',
    label: 'ROCI,  ROC ETF',
    name: 'ROC ETF',
    symbol: 'ROCI',
  },
  {
    value: 'RPHS',
    label: 'RPHS,  Regents Park Hedged Market Strategy ETF',
    name: 'Regents Park Hedged Market Strategy ETF',
    symbol: 'RPHS',
  },
  {
    value: 'SEIM',
    label: 'SEIM,  SEI Large Cap Momentum Factor ETF',
    name: 'SEI Large Cap Momentum Factor ETF',
    symbol: 'SEIM',
  },
  {
    value: 'SEIQ',
    label: 'SEIQ,  SEI Large Cap Quality Factor ETF',
    name: 'SEI Large Cap Quality Factor ETF',
    symbol: 'SEIQ',
  },
  {
    value: 'SEIV',
    label: 'SEIV,  SEI Large Cap Value Factor ETF',
    name: 'SEI Large Cap Value Factor ETF',
    symbol: 'SEIV',
  },
  {
    value: 'SELV',
    label: 'SELV,  SEI Large Cap Low Volatility Factor ETF',
    name: 'SEI Large Cap Low Volatility Factor ETF',
    symbol: 'SELV',
  },
  {
    value: 'STGF',
    label: 'STGF,  Merk Stagflation ETF',
    name: 'Merk Stagflation ETF',
    symbol: 'STGF',
  },
  {
    value: 'SUPL',
    label: 'SUPL,  ProShares Supply Chain Logistics ETF',
    name: 'ProShares Supply Chain Logistics ETF',
    symbol: 'SUPL',
  },
  {
    value: 'RENW',
    label: 'RENW,  Harbor Energy Transition Strategy ETF',
    name: 'Harbor Energy Transition Strategy ETF',
    symbol: 'RENW',
  },
  {
    value: 'NZUS',
    label: 'NZUS,  SPDR MSCI USA Climate Paris Aligned ETF',
    name: 'SPDR MSCI USA Climate Paris Aligned ETF',
    symbol: 'NZUS',
  },
  {
    value: 'OACP',
    label: 'OACP,  OneAscent Core Plus Bond ETF',
    name: 'OneAscent Core Plus Bond ETF',
    symbol: 'OACP',
  },
  {
    value: 'ORFN',
    label: 'ORFN,  Constrained Capital ESG Orphans ETF',
    name: 'Constrained Capital ESG Orphans ETF',
    symbol: 'ORFN',
  },
  {
    value: 'RHCB',
    label: 'RHCB,  BNY Mellon Responsible Horizons Corporate Bond ETF',
    name: 'BNY Mellon Responsible Horizons Corporate Bond ETF',
    symbol: 'RHCB',
  },
  {
    value: 'SVIX',
    label: 'SVIX,  -1x Short VIX Futures ETF',
    name: '-1x Short VIX Futures ETF',
    symbol: 'SVIX',
  },
  {
    value: 'SWEB',
    label: 'SWEB,  AXS Short China Internet ETF',
    name: 'AXS Short China Internet ETF',
    symbol: 'SWEB',
  },
  {
    value: 'TACK',
    label: 'TACK,  Fairlead Tactical Sector Fund',
    name: 'Fairlead Tactical Sector Fund',
    symbol: 'TACK',
  },
  {
    value: 'TILL',
    label: 'TILL,  Teucrium Agricultural Strategy No K-1 ETF',
    name: 'Teucrium Agricultural Strategy No K-1 ETF',
    symbol: 'TILL',
  },
  {
    value: 'ACIC.L',
    label: 'ACIC.L,  abrdn China Investment Company Limited',
    name: 'abrdn China Investment Company Limited',
    symbol: 'ACIC.L',
  },
  {
    value: 'AEI.L',
    label: 'AEI.L,  abrdn Equity Income Trust plc',
    name: 'abrdn Equity Income Trust plc',
    symbol: 'AEI.L',
  },
  {
    value: 'APEO.L',
    label: 'APEO.L,  abrdn Private Equity Opportunities Trust plc',
    name: 'abrdn Private Equity Opportunities Trust plc',
    symbol: 'APEO.L',
  },
  {
    value: 'AUSC.L',
    label: 'AUSC.L,  abrdn UK Smaller Companies Growth Trust plc',
    name: 'abrdn UK Smaller Companies Growth Trust plc',
    symbol: 'AUSC.L',
  },
  {
    value: 'ACPE.L',
    label: 'ACPE.L,  ACP Energy Plc',
    name: 'ACP Energy Plc',
    symbol: 'ACPE.L',
  },
  {
    value: 'AJAX.L',
    label: 'AJAX.L,  Ajax Resources Plc',
    name: 'Ajax Resources Plc',
    symbol: 'AJAX.L',
  },
  {
    value: 'AEET.L',
    label: 'AEET.L,  Aquila Energy Efficiency Trust Plc',
    name: 'Aquila Energy Efficiency Trust Plc',
    symbol: 'AEET.L',
  },
  {
    value: 'AXL.L',
    label: 'AXL.L,  Arrow Exploration Corp.',
    name: 'Arrow Exploration Corp.',
    symbol: 'AXL.L',
  },
  {
    value: 'ARV.L',
    label: 'ARV.L,  Artemis Resources Limited',
    name: 'Artemis Resources Limited',
    symbol: 'ARV.L',
  },
  {
    value: 'ATOM.L',
    label: 'ATOM.L,  ATOME Energy PLC',
    name: 'ATOME Energy PLC',
    symbol: 'ATOM.L',
  },
  {
    value: 'ARA.L',
    label: 'ARA.L,  Aura Renewable Acquisitions plc',
    name: 'Aura Renewable Acquisitions plc',
    symbol: 'ARA.L',
  },
  {
    value: 'BGCG.L',
    label: 'BGCG.L,  Baillie Gifford China Growth Trust PLC',
    name: 'Baillie Gifford China Growth Trust PLC',
    symbol: 'BGCG.L',
  },
  {
    value: 'BEMO.L',
    label: 'BEMO.L,  Barings Emerging EMEA Opportunities Plc',
    name: 'Barings Emerging EMEA Opportunities Plc',
    symbol: 'BEMO.L',
  },
  {
    value: '2418.HK',
    label: '2418.HK,  Deewin Tianxia Co., Ltd',
    name: 'Deewin Tianxia Co., Ltd',
    symbol: '2418.HK',
  },
  {
    value: 'BHMU.L',
    label: 'BHMU.L,  BH Macro Limited',
    name: 'BH Macro Limited',
    symbol: 'BHMU.L',
  },
  {
    value: 'BPCP.L',
    label: 'BPCP.L,  BioPharma Credit PLC',
    name: 'BioPharma Credit PLC',
    symbol: 'BPCP.L',
  },
  {
    value: 'BRSA.L',
    label: 'BRSA.L,  BlackRock Sustainable American Income Trust plc',
    name: 'BlackRock Sustainable American Income Trust plc',
    symbol: 'BRSA.L',
  },
  {
    value: 'BGHS.L',
    label: 'BGHS.L,  Boussard & Gavaudan Holding Limited',
    name: 'Boussard & Gavaudan Holding Limited',
    symbol: 'BGHS.L',
  },
  {
    value: 'BASC.L',
    label: 'BASC.L,  Brown Advisory US Smaller Companies PLC',
    name: 'Brown Advisory US Smaller Companies PLC',
    symbol: 'BASC.L',
  },
  {
    value: 'CGL.L',
    label: 'CGL.L,  Castelnau Group Limited',
    name: 'Castelnau Group Limited',
    symbol: 'CGL.L',
  },
  {
    value: 'CEL.L',
    label: 'CEL.L,  Celadon Pharmaceuticals Plc',
    name: 'Celadon Pharmaceuticals Plc',
    symbol: 'CEL.L',
  },
  {
    value: 'CCPA.L',
    label: 'CCPA.L,  Celtic plc',
    name: 'Celtic plc',
    symbol: 'CCPA.L',
  },
  {
    value: 'CCPC.L',
    label: 'CCPC.L,  Celtic plc',
    name: 'Celtic plc',
    symbol: 'CCPC.L',
  },
  {
    value: 'CPH2.L',
    label: 'CPH2.L,  Clean Power Hydrogen Plc',
    name: 'Clean Power Hydrogen Plc',
    symbol: 'CPH2.L',
  },
  {
    value: 'CTL.L',
    label: 'CTL.L,  CleanTech Lithium Plc',
    name: 'CleanTech Lithium Plc',
    symbol: 'CTL.L',
  },
  {
    value: 'CSRD.L',
    label: 'CSRD.L,  Cordiant Digital Infrastructure Limited',
    name: 'Cordiant Digital Infrastructure Limited',
    symbol: 'CSRD.L',
  },
  {
    value: 'CUSN.L',
    label: 'CUSN.L,  Cornish Metals Inc.',
    name: 'Cornish Metals Inc.',
    symbol: 'CUSN.L',
  },
  {
    value: '49GP.L',
    label: '49GP.L,  Croda International Plc',
    name: 'Croda International Plc',
    symbol: '49GP.L',
  },
  {
    value: 'DVNO.L',
    label: 'DVNO.L,  Develop North PLC',
    name: 'Develop North PLC',
    symbol: 'DVNO.L',
  },
  {
    value: 'DEVO.L',
    label: 'DEVO.L,  Devolver Digital, Inc.',
    name: 'Devolver Digital, Inc.',
    symbol: 'DEVO.L',
  },
  {
    value: 'DGI.L',
    label: 'DGI.L,  Deregallera Holdings Ltd',
    name: 'Deregallera Holdings Ltd',
    symbol: 'DGI.L',
  },
  {
    value: 'DGI9.L',
    label: 'DGI9.L,  Digital 9 Infrastructure PLC',
    name: 'Digital 9 Infrastructure PLC',
    symbol: 'DGI9.L',
  },
  {
    value: 'D467.L',
    label: 'D467.L,  Downing FOUR VCT plc',
    name: 'Downing FOUR VCT plc',
    symbol: 'D467.L',
  },
  {
    value: 'DORE.L',
    label: 'DORE.L,  Downing Renewables & Infrastructure Trust PLC',
    name: 'Downing Renewables & Infrastructure Trust PLC',
    symbol: 'DORE.L',
  },
  {
    value: 'RNEW.L',
    label: 'RNEW.L,  Ecofin U.S. Renewables Infrastructure Trust PLC',
    name: 'Ecofin U.S. Renewables Infrastructure Trust PLC',
    symbol: 'RNEW.L',
  },
  {
    value: 'EJF0.L',
    label: 'EJF0.L,  EJF Investments Limited',
    name: 'EJF Investments Limited',
    symbol: 'EJF0.L',
  },
  {
    value: 'EJFZ.L',
    label: 'EJFZ.L,  EJF Investments Limited',
    name: 'EJF Investments Limited',
    symbol: 'EJFZ.L',
  },
  {
    value: 'ELEG.L',
    label: 'ELEG.L,  Electric Guitar PLC',
    name: 'Electric Guitar PLC',
    symbol: 'ELEG.L',
  },
  {
    value: 'ENSI.L',
    label: 'ENSI.L,  EnSilica plc',
    name: 'EnSilica plc',
    symbol: 'ENSI.L',
  },
  {
    value: 'ESOZ.L',
    label: 'ESOZ.L,  EPE Special Opportunities Limited',
    name: 'EPE Special Opportunities Limited',
    symbol: 'ESOZ.L',
  },
  {
    value: 'EOT.L',
    label: 'EOT.L,  European Opportunities Trust plc',
    name: 'European Opportunities Trust plc',
    symbol: 'EOT.L',
  },
  {
    value: 'ADF.L',
    label: 'ADF.L,  Facilities by ADF plc',
    name: 'Facilities by ADF plc',
    symbol: 'ADF.L',
  },
  {
    value: 'FA17.L',
    label: 'FA17.L,  Fair Oaks Income Limited',
    name: 'Fair Oaks Income Limited',
    symbol: 'FA17.L',
  },
  {
    value: 'FINS.L',
    label: 'FINS.L,  Financials Acquisition Corp',
    name: 'Financials Acquisition Corp',
    symbol: 'FINS.L',
  },
  {
    value: '53GW.L',
    label: '53GW.L,  Fuller, Smith & Turner P.L.C.',
    name: 'Fuller, Smith & Turner P.L.C.',
    symbol: '53GW.L',
  },
  {
    value: 'FME.L',
    label: 'FME.L,  Future Metals NL',
    name: 'Future Metals NL',
    symbol: 'FME.L',
  },
  {
    value: 'GENF.L',
    label: 'GENF.L,  Genflow Biosciences plc',
    name: 'Genflow Biosciences plc',
    symbol: 'GENF.L',
  },
  {
    value: 'GSP.L',
    label: 'GSP.L,  Gensource Potash Corporation',
    name: 'Gensource Potash Corporation',
    symbol: 'GSP.L',
  },
  {
    value: 'GPL.L',
    label: 'GPL.L,  Graft Polymer (UK) Plc',
    name: 'Graft Polymer (UK) Plc',
    symbol: 'GPL.L',
  },
  {
    value: 'GPE.L',
    label: 'GPE.L,  Great Portland Estates Plc',
    name: 'Great Portland Estates Plc',
    symbol: 'GPE.L',
  },
  {
    value: 'GSC.L',
    label: 'GSC.L,  GS Chain plc',
    name: 'GS Chain plc',
    symbol: 'GSC.L',
  },
  {
    value: 'HANA.L',
    label: 'HANA.L,  Hansa Investment Company Limited',
    name: 'Hansa Investment Company Limited',
    symbol: 'HANA.L',
  },
  {
    value: 'HEIT.L',
    label: 'HEIT.L,  Harmony Energy Income Trust Plc',
    name: 'Harmony Energy Income Trust Plc',
    symbol: 'HEIT.L',
  },
  {
    value: 'HMA1.L',
    label: 'HMA1.L,  Hiro Metaverse Acquisitions I S.A.',
    name: 'Hiro Metaverse Acquisitions I S.A.',
    symbol: 'HMA1.L',
  },
  {
    value: 'HKLB.L',
    label: 'HKLB.L,  Hongkong Land Holdings Limited',
    name: 'Hongkong Land Holdings Limited',
    symbol: 'HKLB.L',
  },
  {
    value: 'HKLJ.L',
    label: 'HKLJ.L,  Hongkong Land Holdings Limited',
    name: 'Hongkong Land Holdings Limited',
    symbol: 'HKLJ.L',
  },
  {
    value: 'HGEN.L',
    label: 'HGEN.L,  HydrogenOne Capital Growth plc',
    name: 'HydrogenOne Capital Growth plc',
    symbol: 'HGEN.L',
  },
  {
    value: 'BIPS.L',
    label: 'BIPS.L,  Invesco Bond Income Plus Limited',
    name: 'Invesco Bond Income Plus Limited',
    symbol: 'BIPS.L',
  },
  {
    value: 'IVPB.L',
    label:
      'IVPB.L,  Invesco Perpetual Select Trust plc - Balanced Risk Allocation Share Portfolio',
    name: 'Invesco Perpetual Select Trust plc - Balanced Risk Allocation Share Portfolio',
    symbol: 'IVPB.L',
  },
  {
    value: 'IVPG.L',
    label:
      'IVPG.L,  Invesco Perpetual Select Trust plc - Global Equity Income Share Portfolio',
    name: 'Invesco Perpetual Select Trust plc - Global Equity Income Share Portfolio',
    symbol: 'IVPG.L',
  },
  {
    value: 'IVPM.L',
    label:
      'IVPM.L,  Invesco Perpetual Select Trust plc - Managed Liquidity Share Portfolio',
    name: 'Invesco Perpetual Select Trust plc - Managed Liquidity Share Portfolio',
    symbol: 'IVPM.L',
  },
  {
    value: 'IX.L',
    label: 'IX.L,  i(x) Net Zero Plc',
    name: 'i(x) Net Zero Plc',
    symbol: 'IX.L',
  },
  {
    value: 'JARB.L',
    label: 'JARB.L,  Jardine Matheson Holdings Limited',
    name: 'Jardine Matheson Holdings Limited',
    symbol: 'JARB.L',
  },
  {
    value: 'JPE.L',
    label: 'JPE.L,  JPMorgan Elect plc ­- Managed Growth',
    name: 'JPMorgan Elect plc ­- Managed Growth',
    symbol: 'JPE.L',
  },
  {
    value: 'JPEC.L',
    label: 'JPEC.L,  JPMorgan Elect plc ­- Managed Cash',
    name: 'JPMorgan Elect plc ­- Managed Cash',
    symbol: 'JPEC.L',
  },
  {
    value: 'JEDT.L',
    label: 'JEDT.L,  JPMorgan European Discovery Trust plc',
    name: 'JPMorgan European Discovery Trust plc',
    symbol: 'JEDT.L',
  },
  {
    value: 'JEGI.L',
    label: 'JEGI.L,  JPMorgan European Growth & Income plc',
    name: 'JPMorgan European Growth & Income plc',
    symbol: 'JEGI.L',
  },
  {
    value: 'JARU.L',
    label: 'JARU.L,  JPMorgan Global Core Real Assets Limited',
    name: 'JPMorgan Global Core Real Assets Limited',
    symbol: 'JARU.L',
  },
  {
    value: 'JSGI.L',
    label: 'JSGI.L,  JPMorgan Japan Small Cap Growth & Income plc',
    name: 'JPMorgan Japan Small Cap Growth & Income plc',
    symbol: 'JSGI.L',
  },
  {
    value: 'KEN.L',
    label: 'KEN.L,  Kendrick Resources PLC',
    name: 'Kendrick Resources PLC',
    symbol: 'KEN.L',
  },
  {
    value: 'KPC.L',
    label: 'KPC.L,  Keystone Positive Change Investment Trust plc',
    name: 'Keystone Positive Change Investment Trust plc',
    symbol: 'KPC.L',
  },
  {
    value: 'BOOK.L',
    label: 'BOOK.L,  Literacy Capital plc',
    name: 'Literacy Capital plc',
    symbol: 'BOOK.L',
  },
  {
    value: 'LLAI.L',
    label: 'LLAI.L,  LungLife AI, Inc.',
    name: 'LungLife AI, Inc.',
    symbol: 'LLAI.L',
  },
  {
    value: 'MXC.L',
    label: 'MXC.L,  MGC Pharmaceuticals Limited',
    name: 'MGC Pharmaceuticals Limited',
    symbol: 'MXC.L',
  },
  {
    value: 'MVCT.L',
    label: 'MVCT.L,  Molten Ventures VCT plc',
    name: 'Molten Ventures VCT plc',
    symbol: 'MVCT.L',
  },
  {
    value: 'MAVT.L',
    label: 'MAVT.L,  Momentum Multi-Asset Value Trust plc',
    name: 'Momentum Multi-Asset Value Trust plc',
    symbol: 'MAVT.L',
  },
  {
    value: 'NBDG.L',
    label: 'NBDG.L,  NB Distressed Debt Investment Fund Limited',
    name: 'NB Distressed Debt Investment Fund Limited',
    symbol: 'NBDG.L',
  },
  {
    value: 'NBDX.L',
    label: 'NBDX.L,  NB Distressed Debt Investment Fund Limited',
    name: 'NB Distressed Debt Investment Fund Limited',
    symbol: 'NBDX.L',
  },
  {
    value: 'NBMI.L',
    label: 'NBMI.L,  NB Global Monthly Income Fund Limited',
    name: 'NB Global Monthly Income Fund Limited',
    symbol: 'NBMI.L',
  },
  {
    value: 'NMT.L',
    label: 'NMT.L,  Neometals Ltd',
    name: 'Neometals Ltd',
    symbol: 'NMT.L',
  },
  {
    value: 'ONDO.L',
    label: 'ONDO.L,  Ondo InsurTech Plc',
    name: 'Ondo InsurTech Plc',
    symbol: 'ONDO.L',
  },
  {
    value: 'PEMB.L',
    label: 'PEMB.L,  Pembroke VCT plc',
    name: 'Pembroke VCT plc',
    symbol: 'PEMB.L',
  },
  {
    value: 'PMGR.L',
    label: 'PMGR.L,  Premier Miton Global Renewables Trust plc',
    name: 'Premier Miton Global Renewables Trust plc',
    symbol: 'PMGR.L',
  },
  {
    value: 'PPHC.L',
    label: 'PPHC.L,  Public Policy Holding Company, Inc.',
    name: 'Public Policy Holding Company, Inc.',
    symbol: 'PPHC.L',
  },
  {
    value: 'PYX.L',
    label: 'PYX.L,  PYX Resources Limited',
    name: 'PYX Resources Limited',
    symbol: 'PYX.L',
  },
  {
    value: 'RCGH.L',
    label: 'RCGH.L,  RC365 Holding plc',
    name: 'RC365 Holding plc',
    symbol: 'RCGH.L',
  },
  {
    value: 'RMII.L',
    label: 'RMII.L,  RM Infrastructure Income PLC',
    name: 'RM Infrastructure Income PLC',
    symbol: 'RMII.L',
  },
  {
    value: 'ROC.L',
    label: 'ROC.L,  Rockpool Acquisitions Plc',
    name: 'Rockpool Acquisitions Plc',
    symbol: 'ROC.L',
  },
  {
    value: 'RKW.L',
    label: 'RKW.L,  Rockwood Strategic Plc',
    name: 'Rockwood Strategic Plc',
    symbol: 'RKW.L',
  },
  {
    value: 'RHM.L',
    label: 'RHM.L,  Round Hill Music Royalty Fund Limited',
    name: 'Round Hill Music Royalty Fund Limited',
    symbol: 'RHM.L',
  },
  {
    value: 'RS1.L',
    label: 'RS1.L,  RS Group plc',
    name: 'RS Group plc',
    symbol: 'RS1.L',
  },
  {
    value: 'SANB.L',
    label: 'SANB.L,  Santander UK plc',
    name: 'Santander UK plc',
    symbol: 'SANB.L',
  },
  {
    value: 'SBO.L',
    label: 'SBO.L,  Schroder British Opportunities Trust PLC',
    name: 'Schroder British Opportunities Trust PLC',
    symbol: 'SBO.L',
  },
  {
    value: 'SOI.L',
    label: 'SOI.L,  Schroder Oriental Income Fund Limited',
    name: 'Schroder Oriental Income Fund Limited',
    symbol: 'SOI.L',
  },
  {
    value: 'SEED.L',
    label: 'SEED.L,  Seed Innovations Limited',
    name: 'Seed Innovations Limited',
    symbol: 'SEED.L',
  },
  {
    value: 'SLFR.L',
    label: 'SLFR.L,  SLF Realisation Fund Limited',
    name: 'SLF Realisation Fund Limited',
    symbol: 'SLFR.L',
  },
  {
    value: 'SLFX.L',
    label: 'SLFX.L,  SLF Realisation Fund Limited',
    name: 'SLF Realisation Fund Limited',
    symbol: 'SLFX.L',
  },
  {
    value: 'SMV.L',
    label: 'SMV.L,  Smoove plc',
    name: 'Smoove plc',
    symbol: 'SMV.L',
  },
  {
    value: 'SVML.L',
    label: 'SVML.L,  Sovereign Metals Limited',
    name: 'Sovereign Metals Limited',
    symbol: 'SVML.L',
  },
  {
    value: 'STG.L',
    label: 'STG.L,  Strip Tinning Holdings plc',
    name: 'Strip Tinning Holdings plc',
    symbol: 'STG.L',
  },
  {
    value: 'ESCT.L',
    label: 'ESCT.L,  The European Smaller Companies Trust PLC',
    name: 'The European Smaller Companies Trust PLC',
    symbol: 'ESCT.L',
  },
  {
    value: 'MNTC.L',
    label: 'MNTC.L,  The Schiehallion Fund Limited',
    name: 'The Schiehallion Fund Limited',
    symbol: 'MNTC.L',
  },
  {
    value: 'TLEI.L',
    label: 'TLEI.L,  ThomasLloyd Energy Impact Trust PLC',
    name: 'ThomasLloyd Energy Impact Trust PLC',
    symbol: 'TLEI.L',
  },
  {
    value: 'TLEP.L',
    label: 'TLEP.L,  ThomasLloyd Energy Impact Trust PLC',
    name: 'ThomasLloyd Energy Impact Trust PLC',
    symbol: 'TLEP.L',
  },
  {
    value: 'THX.L',
    label: 'THX.L,  Thor Explorations Ltd.',
    name: 'Thor Explorations Ltd.',
    symbol: 'THX.L',
  },
  {
    value: 'TIME.L',
    label: 'TIME.L,  Time Finance plc',
    name: 'Time Finance plc',
    symbol: 'TIME.L',
  },
  {
    value: 'TPOB.L',
    label: 'TPOB.L,  Triple Point VCT 2011 plc',
    name: 'Triple Point VCT 2011 plc',
    symbol: 'TPOB.L',
  },
  {
    value: 'HR',
    label: 'HR,  Healthcare Realty Trust Incorporated',
    name: 'Healthcare Realty Trust Incorporated',
    symbol: 'HR',
  },
  {
    value: '403870.KQ',
    label: '403870.KQ,  HPSP Co., Ltd.',
    name: 'HPSP Co., Ltd.',
    symbol: '403870.KQ',
  },
  {
    value: 'UTLH.L',
    label: 'UTLH.L,  UIL Finance Limited',
    name: 'UIL Finance Limited',
    symbol: 'UTLH.L',
  },
  {
    value: 'UTLI.L',
    label: 'UTLI.L,  UIL Finance Limited',
    name: 'UIL Finance Limited',
    symbol: 'UTLI.L',
  },
  {
    value: 'UBG.L',
    label: 'UBG.L,  Unbound Group plc',
    name: 'Unbound Group plc',
    symbol: 'UBG.L',
  },
  {
    value: 'VIP.L',
    label: 'VIP.L,  Value and Indexed Property Income Trust Plc',
    name: 'Value and Indexed Property Income Trust Plc',
    symbol: 'VIP.L',
  },
  {
    value: 'GSEO.L',
    label: 'GSEO.L,  VH Global Sustainable Energy Opportunities plc',
    name: 'VH Global Sustainable Energy Opportunities plc',
    symbol: 'GSEO.L',
  },
  {
    value: 'VID.L',
    label: 'VID.L,  Videndum Plc',
    name: 'Videndum Plc',
    symbol: 'VID.L',
  },
  {
    value: 'WPM.L',
    label: 'WPM.L,  Wheaton Precious Metals Corp.',
    name: 'Wheaton Precious Metals Corp.',
    symbol: 'WPM.L',
  },
  {
    value: 'AUY.L',
    label: 'AUY.L,  Yamana Gold Inc.',
    name: 'Yamana Gold Inc.',
    symbol: 'AUY.L',
  },
  {
    value: '1204.HK',
    label: '1204.HK,  BoardWare Intelligence Technology Limited',
    name: 'BoardWare Intelligence Technology Limited',
    symbol: '1204.HK',
  },
  {
    value: 'VRAX',
    label: 'VRAX,  Virax Biolabs Group Limited',
    name: 'Virax Biolabs Group Limited',
    symbol: 'VRAX',
  },
  {
    value: 'MOND',
    label: 'MOND,  Mondee Holdings, Inc.',
    name: 'Mondee Holdings, Inc.',
    symbol: 'MOND',
  },
  {
    value: 'EVEX',
    label: 'EVEX,  Eve Holding, Inc.',
    name: 'Eve Holding, Inc.',
    symbol: 'EVEX',
  },
  {
    value: 'UAV',
    label: 'UAV,  AdvisorShares Drone Technology ETF',
    name: 'AdvisorShares Drone Technology ETF',
    symbol: 'UAV',
  },
  {
    value: 'WEED',
    label: 'WEED,  Roundhill Cannabis ETF',
    name: 'Roundhill Cannabis ETF',
    symbol: 'WEED',
  },
  {
    value: '2172.HK',
    label: '2172.HK,  MicroPort NeuroTech Limited',
    name: 'MicroPort NeuroTech Limited',
    symbol: '2172.HK',
  },
  {
    value: 'GSK',
    label: 'GSK,  GSK plc',
    name: 'GSK plc',
    symbol: 'GSK',
  },
  {
    value: 'HLN',
    label: 'HLN,  Haleon plc',
    name: 'Haleon plc',
    symbol: 'HLN',
  },
  {
    value: 'GWAV',
    label: 'GWAV,  Greenwave Technology Solutions, Inc.',
    name: 'Greenwave Technology Solutions, Inc.',
    symbol: 'GWAV',
  },
  {
    value: 'BRVO.V',
    label: 'BRVO.V,  Bravo Mining Corp.',
    name: 'Bravo Mining Corp.',
    symbol: 'BRVO.V',
  },
  {
    value: 'DEWI.JK',
    label: 'DEWI.JK,  PT Dewi Shri Farmindo Tbk.',
    name: 'PT Dewi Shri Farmindo Tbk.',
    symbol: 'DEWI.JK',
  },
  {
    value: 'FAZE',
    label: 'FAZE,  FaZe Holdings Inc.',
    name: 'FaZe Holdings Inc.',
    symbol: 'FAZE',
  },
  {
    value: 'VC2.SI',
    label: 'VC2.SI,  Olam Group Limited',
    name: 'Olam Group Limited',
    symbol: 'VC2.SI',
  },
  {
    value: 'PLUR',
    label: 'PLUR,  Pluri Inc.',
    name: 'Pluri Inc.',
    symbol: 'PLUR',
  },
  {
    value: 'XBB',
    label: 'XBB,  BondBloxx BB Rated USD High Yield Corporate Bond ETF',
    name: 'BondBloxx BB Rated USD High Yield Corporate Bond ETF',
    symbol: 'XBB',
  },
  {
    value: 'XCCC',
    label: 'XCCC,  BondBloxx CCC Rated USD High Yield Corporate Bond ETF',
    name: 'BondBloxx CCC Rated USD High Yield Corporate Bond ETF',
    symbol: 'XCCC',
  },
  {
    value: 'SHPP',
    label: 'SHPP,  Pacer Industrials and Logistics ETF',
    name: 'Pacer Industrials and Logistics ETF',
    symbol: 'SHPP',
  },
  {
    value: 'VMAT',
    label:
      'VMAT,  V-Shares MSCI World ESG Materiality and Carbon Transition ETF',
    name: 'V-Shares MSCI World ESG Materiality and Carbon Transition ETF',
    symbol: 'VMAT',
  },
  {
    value: 'GF.SW',
    label: 'GF.SW,  Georg Fischer AG',
    name: 'Georg Fischer AG',
    symbol: 'GF.SW',
  },
  {
    value: 'PTTGC.BK',
    label: 'PTTGC.BK,  PTT Global Chemical Public Company Limited',
    name: 'PTT Global Chemical Public Company Limited',
    symbol: 'PTTGC.BK',
  },
  {
    value: 'TTKOM.IS',
    label: 'TTKOM.IS,  Türk Telekomünikasyon Anonim Sirketi',
    name: 'Türk Telekomünikasyon Anonim Sirketi',
    symbol: 'TTKOM.IS',
  },
  {
    value: 'WIE.VI',
    label: 'WIE.VI,  Wienerberger AG',
    name: 'Wienerberger AG',
    symbol: 'WIE.VI',
  },
  {
    value: 'IRAA',
    label: 'IRAA,  Iris Acquisition Corp',
    name: 'Iris Acquisition Corp',
    symbol: 'IRAA',
  },
  {
    value: 'IRAAU',
    label: 'IRAAU,  Iris Acquisition Corp',
    name: 'Iris Acquisition Corp',
    symbol: 'IRAAU',
  },
  {
    value: 'IRAAW',
    label: 'IRAAW,  Iris Acquisition Corp',
    name: 'Iris Acquisition Corp',
    symbol: 'IRAAW',
  },
  {
    value: 'AGIH',
    label: 'AGIH,  iShares Inflation Hedged U.S. Aggregate Bond ETF',
    name: 'iShares Inflation Hedged U.S. Aggregate Bond ETF',
    symbol: 'AGIH',
  },
  {
    value: 'AGRH',
    label: 'AGRH,  iShares Interest Rate Hedged U.S. Aggregate Bond ETF',
    name: 'iShares Interest Rate Hedged U.S. Aggregate Bond ETF',
    symbol: 'AGRH',
  },
  {
    value: 'MAIA',
    label: 'MAIA,  MAIA Biotechnology, Inc.',
    name: 'MAIA Biotechnology, Inc.',
    symbol: 'MAIA',
  },
  {
    value: 'CLOI',
    label: 'CLOI,  VanEck CLO ETF',
    name: 'VanEck CLO ETF',
    symbol: 'CLOI',
  },
  {
    value: 'SNTI',
    label: 'SNTI,  Senti Biosciences, Inc.',
    name: 'Senti Biosciences, Inc.',
    symbol: 'SNTI',
  },
  {
    value: 'GETY',
    label: 'GETY,  Getty Images Holdings, Inc.',
    name: 'Getty Images Holdings, Inc.',
    symbol: 'GETY',
  },
  {
    value: '600941.SS',
    label: '600941.SS,  China Mobile Limited',
    name: 'China Mobile Limited',
    symbol: '600941.SS',
  },
  {
    value: 'AAB.CO',
    label: 'AAB.CO,  Aalborg Boldspilklub A/S',
    name: 'Aalborg Boldspilklub A/S',
    symbol: 'AAB.CO',
  },
  {
    value: 'AGAT.CO',
    label: 'AGAT.CO,  Agat Ejendomme A/S',
    name: 'Agat Ejendomme A/S',
    symbol: 'AGAT.CO',
  },
  {
    value: 'AOJ-B.CO',
    label: 'AOJ-B.CO,  Brødrene A & O Johansen A/S',
    name: 'Brødrene A & O Johansen A/S',
    symbol: 'AOJ-B.CO',
  },
  {
    value: 'AQP.CO',
    label: 'AQP.CO,  Aquaporin A/S',
    name: 'Aquaporin A/S',
    symbol: 'AQP.CO',
  },
  {
    value: 'ATLA-DKK.CO',
    label: 'ATLA-DKK.CO,  P/F Atlantic Petroleum',
    name: 'P/F Atlantic Petroleum',
    symbol: 'ATLA-DKK.CO',
  },
  {
    value: 'BIF.CO',
    label: 'BIF.CO,  Brøndbyernes IF Fodbold A/S',
    name: 'Brøndbyernes IF Fodbold A/S',
    symbol: 'BIF.CO',
  },
  {
    value: 'BIOPOR.CO',
    label: 'BIOPOR.CO,  BioPorto A/S',
    name: 'BioPorto A/S',
    symbol: 'BIOPOR.CO',
  },
  {
    value: 'BLVIS-A.CO',
    label: 'BLVIS-A.CO,  Blue Vision A/S',
    name: 'Blue Vision A/S',
    symbol: 'BLVIS-A.CO',
  },
  {
    value: 'BNORDIK-CSE.CO',
    label: 'BNORDIK-CSE.CO,  P/F BankNordik',
    name: 'P/F BankNordik',
    symbol: 'BNORDIK-CSE.CO',
  },
  {
    value: 'CEMAT.CO',
    label: 'CEMAT.CO,  Cemat A/S',
    name: 'Cemat A/S',
    symbol: 'CEMAT.CO',
  },
  {
    value: 'CPHCAP-ST.CO',
    label: 'CPHCAP-ST.CO,  Copenhagen Capital A/S',
    name: 'Copenhagen Capital A/S',
    symbol: 'CPHCAP-ST.CO',
  },
  {
    value: 'DANT.CO',
    label: 'DANT.CO,  Dantax A/S',
    name: 'Dantax A/S',
    symbol: 'DANT.CO',
  },
  {
    value: 'DJUR.CO',
    label: 'DJUR.CO,  Djurslands Bank A/S',
    name: 'Djurslands Bank A/S',
    symbol: 'DJUR.CO',
  },
  {
    value: 'EAC.CO',
    label: 'EAC.CO,  EAC Invest A/S',
    name: 'EAC Invest A/S',
    symbol: 'EAC.CO',
  },
  {
    value: 'ESG.CO',
    label: 'ESG.CO,  Ennogie Solar Group A/S',
    name: 'Ennogie Solar Group A/S',
    symbol: 'ESG.CO',
  },
  {
    value: 'FED.CO',
    label: 'FED.CO,  Fast Ejendom Danmark A/S',
    name: 'Fast Ejendom Danmark A/S',
    symbol: 'FED.CO',
  },
  {
    value: 'FFARMS.CO',
    label: 'FFARMS.CO,  FirstFarms A/S',
    name: 'FirstFarms A/S',
    symbol: 'FFARMS.CO',
  },
  {
    value: 'FLUG-B.CO',
    label: 'FLUG-B.CO,  Flügger group A/S',
    name: 'Flügger group A/S',
    symbol: 'FLUG-B.CO',
  },
  {
    value: 'FYNBK.CO',
    label: 'FYNBK.CO,  Fynske Bank A/S',
    name: 'Fynske Bank A/S',
    symbol: 'FYNBK.CO',
  },
  {
    value: 'GABR.CO',
    label: 'GABR.CO,  Gabriel Holding A/S',
    name: 'Gabriel Holding A/S',
    symbol: 'GABR.CO',
  },
  {
    value: 'GERHSP.CO',
    label: 'GERHSP.CO,  German High Street Properties A/S',
    name: 'German High Street Properties A/S',
    symbol: 'GERHSP.CO',
  },
  {
    value: 'GJ.CO',
    label: 'GJ.CO,  Glunz & Jensen Holding A/S',
    name: 'Glunz & Jensen Holding A/S',
    symbol: 'GJ.CO',
  },
  {
    value: 'GREENH.CO',
    label: 'GREENH.CO,  Green Hydrogen Systems A/S',
    name: 'Green Hydrogen Systems A/S',
    symbol: 'GREENH.CO',
  },
  {
    value: 'GRLA.CO',
    label: 'GRLA.CO,  GrønlandsBANKEN A/S',
    name: 'GrønlandsBANKEN A/S',
    symbol: 'GRLA.CO',
  },
  {
    value: 'GYLD-A.CO',
    label: 'GYLD-A.CO,  Gyldendal A/S',
    name: 'Gyldendal A/S',
    symbol: 'GYLD-A.CO',
  },
  {
    value: 'GYLD-B.CO',
    label: 'GYLD-B.CO,  Gyldendal A/S',
    name: 'Gyldendal A/S',
    symbol: 'GYLD-B.CO',
  },
  {
    value: 'HARB-B.CO',
    label: 'HARB-B.CO,  Harboes Bryggeri A/S',
    name: 'Harboes Bryggeri A/S',
    symbol: 'HARB-B.CO',
  },
  {
    value: 'HLUN-A.CO',
    label: 'HLUN-A.CO,  H. Lundbeck A/S',
    name: 'H. Lundbeck A/S',
    symbol: 'HLUN-A.CO',
  },
  {
    value: 'HLUN-B.CO',
    label: 'HLUN-B.CO,  H. Lundbeck A/S',
    name: 'H. Lundbeck A/S',
    symbol: 'HLUN-B.CO',
  },
  {
    value: 'HUSCO.CO',
    label: 'HUSCO.CO,  HusCompagniet A/S',
    name: 'HusCompagniet A/S',
    symbol: 'HUSCO.CO',
  },
  {
    value: 'HVID.CO',
    label: 'HVID.CO,  Hvidbjerg Bank A/S',
    name: 'Hvidbjerg Bank A/S',
    symbol: 'HVID.CO',
  },
  {
    value: 'KBHL.CO',
    label: 'KBHL.CO,  Københavns Lufthavne A/S',
    name: 'Københavns Lufthavne A/S',
    symbol: 'KBHL.CO',
  },
  {
    value: 'KLEE-B.CO',
    label: 'KLEE-B.CO,  Brd. Klee A/S',
    name: 'Brd. Klee A/S',
    symbol: 'KLEE-B.CO',
  },
  {
    value: 'KRE.CO',
    label: 'KRE.CO,  Kreditbanken A/S',
    name: 'Kreditbanken A/S',
    symbol: 'KRE.CO',
  },
  {
    value: 'LASP.CO',
    label: 'LASP.CO,  Lån & Spar Bank A/S',
    name: 'Lån & Spar Bank A/S',
    symbol: 'LASP.CO',
  },
  {
    value: 'LOLB.CO',
    label: 'LOLB.CO,  Lollands Bank A/S',
    name: 'Lollands Bank A/S',
    symbol: 'LOLB.CO',
  },
  {
    value: 'ECBK',
    label: 'ECBK,  ECB Bancorp, Inc.',
    name: 'ECB Bancorp, Inc.',
    symbol: 'ECBK',
  },
  {
    value: 'LUXOR-B.CO',
    label: 'LUXOR-B.CO,  Investeringsselskabet Luxor A/S',
    name: 'Investeringsselskabet Luxor A/S',
    symbol: 'LUXOR-B.CO',
  },
  {
    value: 'MNBA.CO',
    label: 'MNBA.CO,  Møns Bank A/S',
    name: 'Møns Bank A/S',
    symbol: 'MNBA.CO',
  },
  {
    value: 'MTHH.CO',
    label: 'MTHH.CO,  MT Højgaard Holding A/S',
    name: 'MT Højgaard Holding A/S',
    symbol: 'MTHH.CO',
  },
  {
    value: 'NDA-DK.CO',
    label: 'NDA-DK.CO,  Nordea Bank Abp',
    name: 'Nordea Bank Abp',
    symbol: 'NDA-DK.CO',
  },
  {
    value: 'NEWCAP.CO',
    label: 'NEWCAP.CO,  Newcap Holding A/S',
    name: 'Newcap Holding A/S',
    symbol: 'NEWCAP.CO',
  },
  {
    value: 'NORDIC.CO',
    label: 'NORDIC.CO,  Nordic Shipholding A/S',
    name: 'Nordic Shipholding A/S',
    symbol: 'NORDIC.CO',
  },
  {
    value: 'NRDF.CO',
    label: 'NRDF.CO,  Nordfyns Bank A/S',
    name: 'Nordfyns Bank A/S',
    symbol: 'NRDF.CO',
  },
  {
    value: 'NTR-B.CO',
    label: 'NTR-B.CO,  NTR Holding A/S',
    name: 'NTR Holding A/S',
    symbol: 'NTR-B.CO',
  },
  {
    value: 'ORPHA.CO',
    label: 'ORPHA.CO,  Orphazyme A/S',
    name: 'Orphazyme A/S',
    symbol: 'ORPHA.CO',
  },
  {
    value: 'OSSR.CO',
    label: 'OSSR.CO,  Össur hf.',
    name: 'Össur hf.',
    symbol: 'OSSR.CO',
  },
  {
    value: 'PARKEN.CO',
    label: 'PARKEN.CO,  PARKEN Sport & Entertainment A/S',
    name: 'PARKEN Sport & Entertainment A/S',
    symbol: 'PARKEN.CO',
  },
  {
    value: 'PARKST-A.CO',
    label: 'PARKST-A.CO,  Park Street A/S',
    name: 'Park Street A/S',
    symbol: 'PARKST-A.CO',
  },
  {
    value: 'PRIMOF.CO',
    label: 'PRIMOF.CO,  Prime Office A/S',
    name: 'Prime Office A/S',
    symbol: 'PRIMOF.CO',
  },
  {
    value: 'RBLN-B.CO',
    label: 'RBLN-B.CO,  Roblon A/S',
    name: 'Roblon A/S',
    symbol: 'RBLN-B.CO',
  },
  {
    value: 'RIAS-B.CO',
    label: 'RIAS-B.CO,  RIAS A/S',
    name: 'RIAS A/S',
    symbol: 'RIAS-B.CO',
  },
  {
    value: 'ROCK-A.CO',
    label: 'ROCK-A.CO,  Rockwool A/S',
    name: 'Rockwool A/S',
    symbol: 'ROCK-A.CO',
  },
  {
    value: 'ROCK-B.CO',
    label: 'ROCK-B.CO,  Rockwool A/S',
    name: 'Rockwool A/S',
    symbol: 'ROCK-B.CO',
  },
  {
    value: 'ROV.CO',
    label: 'ROV.CO,  Rovsing A/S',
    name: 'Rovsing A/S',
    symbol: 'ROV.CO',
  },
  {
    value: 'RTX.CO',
    label: 'RTX.CO,  RTX A/S',
    name: 'RTX A/S',
    symbol: 'RTX.CO',
  },
  {
    value: 'SANI.CO',
    label: 'SANI.CO,  Sanistål A/S',
    name: 'Sanistål A/S',
    symbol: 'SANI.CO',
  },
  {
    value: 'SAS-DKK.CO',
    label: 'SAS-DKK.CO,  SAS AB (publ)',
    name: 'SAS AB (publ)',
    symbol: 'SAS-DKK.CO',
  },
  {
    value: 'SBS.CO',
    label: 'SBS.CO,  Scandinavian Brake Systems A/S',
    name: 'Scandinavian Brake Systems A/S',
    symbol: 'SBS.CO',
  },
  {
    value: 'SIF.CO',
    label: 'SIF.CO,  Silkeborg IF Invest A/S',
    name: 'Silkeborg IF Invest A/S',
    symbol: 'SIF.CO',
  },
  {
    value: 'SIG.CO',
    label: 'SIG.CO,  Scandinavian Investment Group A/S',
    name: 'Scandinavian Investment Group A/S',
    symbol: 'SIG.CO',
  },
  {
    value: 'SKAKO.CO',
    label: 'SKAKO.CO,  SKAKO A/S',
    name: 'SKAKO A/S',
    symbol: 'SKAKO.CO',
  },
  {
    value: 'SKJE.CO',
    label: 'SKJE.CO,  Skjern Bank A/S',
    name: 'Skjern Bank A/S',
    symbol: 'SKJE.CO',
  },
  {
    value: 'SOLAR-B.CO',
    label: 'SOLAR-B.CO,  Solar A/S',
    name: 'Solar A/S',
    symbol: 'SOLAR-B.CO',
  },
  {
    value: 'SPKSJF.CO',
    label: 'SPKSJF.CO,  Sparekassen Sjælland-Fyn A/S',
    name: 'Sparekassen Sjælland-Fyn A/S',
    symbol: 'SPKSJF.CO',
  },
  {
    value: 'TOTA.CO',
    label: 'TOTA.CO,  Totalbanken A/S',
    name: 'Totalbanken A/S',
    symbol: 'TOTA.CO',
  },
  {
    value: 'TRMD-A.CO',
    label: 'TRMD-A.CO,  TORM plc',
    name: 'TORM plc',
    symbol: 'TRMD-A.CO',
  },
  {
    value: 'UIE.CO',
    label: 'UIE.CO,  UIE Plc',
    name: 'UIE Plc',
    symbol: 'UIE.CO',
  },
  {
    value: 'VJBA.CO',
    label: 'VJBA.CO,  Vestjysk Bank A/S',
    name: 'Vestjysk Bank A/S',
    symbol: 'VJBA.CO',
  },
  {
    value: 'MGAM',
    label: 'MGAM,  Mobile Global Esports Inc.',
    name: 'Mobile Global Esports Inc.',
    symbol: 'MGAM',
  },
  {
    value: 'DIVI',
    label: 'DIVI,  Franklin International Core Dividend Tilt Index ETF',
    name: 'Franklin International Core Dividend Tilt Index ETF',
    symbol: 'DIVI',
  },
  {
    value: 'OIG',
    label: 'OIG,  Orbital Infrastructure Group, Inc.',
    name: 'Orbital Infrastructure Group, Inc.',
    symbol: 'OIG',
  },
  {
    value: 'QNCX',
    label: 'QNCX,  Quince Therapeutics, Inc.',
    name: 'Quince Therapeutics, Inc.',
    symbol: 'QNCX',
  },
  {
    value: 'RBLD',
    label: 'RBLD,  First Trust Alerian U.S. NextGen Infrastructure ETF',
    name: 'First Trust Alerian U.S. NextGen Infrastructure ETF',
    symbol: 'RBLD',
  },
  {
    value: 'UDIV',
    label: 'UDIV,  Franklin U.S. Core Dividend Tilt Index ETF',
    name: 'Franklin U.S. Core Dividend Tilt Index ETF',
    symbol: 'UDIV',
  },
  {
    value: 'HYGI',
    label: 'HYGI,  iShares Inflation Hedged High Yield Bond ETF',
    name: 'iShares Inflation Hedged High Yield Bond ETF',
    symbol: 'HYGI',
  },
  {
    value: 'CPII',
    label: 'CPII,  Ionic Inflation Protection ETF',
    name: 'Ionic Inflation Protection ETF',
    symbol: 'CPII',
  },
  {
    value: 'NSPY',
    label: 'NSPY,  NightShares 500 ETF',
    name: 'NightShares 500 ETF',
    symbol: 'NSPY',
  },
  {
    value: 'USNZ',
    label: 'USNZ,  Xtrackers Net Zero Pathway Paris Aligned US Equity ETF',
    name: 'Xtrackers Net Zero Pathway Paris Aligned US Equity ETF',
    symbol: 'USNZ',
  },
  {
    value: 'IBDX',
    label: 'IBDX,  iShares iBonds Dec 2032 Term Corporate ETF',
    name: 'iShares iBonds Dec 2032 Term Corporate ETF',
    symbol: 'IBDX',
  },
  {
    value: 'EDBL',
    label: 'EDBL,  Edible Garden AG Incorporated',
    name: 'Edible Garden AG Incorporated',
    symbol: 'EDBL',
  },
  {
    value: 'HKD',
    label: 'HKD,  AMTD Digital Inc.',
    name: 'AMTD Digital Inc.',
    symbol: 'HKD',
  },
  {
    value: 'MEGL',
    label: 'MEGL,  Magic Empire Global Limited',
    name: 'Magic Empire Global Limited',
    symbol: 'MEGL',
  },
  {
    value: 'MOBVU',
    label: 'MOBVU,  Mobiv Acquisition Corp',
    name: 'Mobiv Acquisition Corp',
    symbol: 'MOBVU',
  },
  {
    value: 'BRSH',
    label: 'BRSH,  Bruush Oral Care Inc.',
    name: 'Bruush Oral Care Inc.',
    symbol: 'BRSH',
  },
  {
    value: 'CPOP',
    label: 'CPOP,  Pop Culture Group Co., Ltd',
    name: 'Pop Culture Group Co., Ltd',
    symbol: 'CPOP',
  },
  {
    value: '300068.SZ',
    label: '300068.SZ,  ZHEJIANG NARADA POWER SOURCE Co. , Ltd.',
    name: 'ZHEJIANG NARADA POWER SOURCE Co. , Ltd.',
    symbol: '300068.SZ',
  },
  {
    value: 'QCLR',
    label: 'QCLR,  Global X NASDAQ 100 Collar 95-110 ETF',
    name: 'Global X NASDAQ 100 Collar 95-110 ETF',
    symbol: 'QCLR',
  },
  {
    value: 'XCLR',
    label: 'XCLR,  Global X S&P 500 Collar 95-110 ETF',
    name: 'Global X S&P 500 Collar 95-110 ETF',
    symbol: 'XCLR',
  },
  {
    value: 'SMIG',
    label: 'SMIG,  AAM Bahl & Gaynor Small/Mid Cap Income Growth ETF',
    name: 'AAM Bahl & Gaynor Small/Mid Cap Income Growth ETF',
    symbol: 'SMIG',
  },
  {
    value: 'FFND',
    label: 'FFND,  The Future Fund Active ETF',
    name: 'The Future Fund Active ETF',
    symbol: 'FFND',
  },
  {
    value: 'RSPY',
    label: 'RSPY,  Revere Sector Opportunity ETF',
    name: 'Revere Sector Opportunity ETF',
    symbol: 'RSPY',
  },
  {
    value: 'ZECP',
    label: 'ZECP,  Zacks Earnings Consistent Portfolio ETF',
    name: 'Zacks Earnings Consistent Portfolio ETF',
    symbol: 'ZECP',
  },
  {
    value: 'JHMB',
    label: 'JHMB,  John Hancock Mortgage Backed Securities ETF',
    name: 'John Hancock Mortgage Backed Securities ETF',
    symbol: 'JHMB',
  },
  {
    value: 'JRNY',
    label: 'JRNY,  ALPS Global Travel Beneficiaries ETF',
    name: 'ALPS Global Travel Beneficiaries ETF',
    symbol: 'JRNY',
  },
  {
    value: 'CYA',
    label: 'CYA,  Simplify Tail Risk Strategy ETF',
    name: 'Simplify Tail Risk Strategy ETF',
    symbol: 'CYA',
  },
  {
    value: 'PINK',
    label: 'PINK,  Simplify Health Care ETF',
    name: 'Simplify Health Care ETF',
    symbol: 'PINK',
  },
  {
    value: 'AILG',
    label: 'AILG,  Alpha Intelligent - Large Cap Growth ETF',
    name: 'Alpha Intelligent - Large Cap Growth ETF',
    symbol: 'AILG',
  },
  {
    value: 'AILV',
    label: 'AILV,  Alpha Intelligent - Large Cap Value ETF',
    name: 'Alpha Intelligent - Large Cap Value ETF',
    symbol: 'AILV',
  },
  {
    value: 'AQGX',
    label: 'AQGX,  AI Quality Growth ETF',
    name: 'AI Quality Growth ETF',
    symbol: 'AQGX',
  },
  {
    value: 'BKIS',
    label: 'BKIS,  BNY Mellon Sustainable International Equity ETF',
    name: 'BNY Mellon Sustainable International Equity ETF',
    symbol: 'BKIS',
  },
  {
    value: 'BUFT',
    label: 'BUFT,  FT Cboe Vest Buffered Allocation Defensive ETF',
    name: 'FT Cboe Vest Buffered Allocation Defensive ETF',
    symbol: 'BUFT',
  },
  {
    value: 'DAT',
    label: 'DAT,  ProShares Big Data Refiners ETF',
    name: 'ProShares Big Data Refiners ETF',
    symbol: 'DAT',
  },
  {
    value: 'DFCF',
    label: 'DFCF,  Dimensional Core Fixed Income ETF',
    name: 'Dimensional Core Fixed Income ETF',
    symbol: 'DFCF',
  },
  {
    value: 'DFIP',
    label: 'DFIP,  Dimensional Inflation-Protected Securities ETF',
    name: 'Dimensional Inflation-Protected Securities ETF',
    symbol: 'DFIP',
  },
  {
    value: 'AXITA.NS',
    label: 'AXITA.NS,  Axita Cotton Limited',
    name: 'Axita Cotton Limited',
    symbol: 'AXITA.NS',
  },
  {
    value: 'CHEVIOT.NS',
    label: 'CHEVIOT.NS,  Cheviot Company Limited',
    name: 'Cheviot Company Limited',
    symbol: 'CHEVIOT.NS',
  },
  {
    value: 'PATANJALI.NS',
    label: 'PATANJALI.NS,  Patanjali Foods Limited',
    name: 'Patanjali Foods Limited',
    symbol: 'PATANJALI.NS',
  },
  {
    value: 'DELHIVERY.NS',
    label: 'DELHIVERY.NS,  Delhivery Limited',
    name: 'Delhivery Limited',
    symbol: 'DELHIVERY.NS',
  },
  {
    value: 'EMUDHRA.NS',
    label: 'EMUDHRA.NS,  eMudhra Limited',
    name: 'eMudhra Limited',
    symbol: 'EMUDHRA.NS',
  },
  {
    value: 'EQUIPPP.NS',
    label: 'EQUIPPP.NS,  Equippp Social Impact Technologies Limited',
    name: 'Equippp Social Impact Technologies Limited',
    symbol: 'EQUIPPP.NS',
  },
  {
    value: 'ETHOSLTD.NS',
    label: 'ETHOSLTD.NS,  Ethos Limited',
    name: 'Ethos Limited',
    symbol: 'ETHOSLTD.NS',
  },
  {
    value: 'HINDWAREAP.NS',
    label: 'HINDWAREAP.NS,  Hindware Home Innovation Limited',
    name: 'Hindware Home Innovation Limited',
    symbol: 'HINDWAREAP.NS',
  },
  {
    value: 'JWL.NS',
    label: 'JWL.NS,  Jupiter Wagons Limited',
    name: 'Jupiter Wagons Limited',
    symbol: 'JWL.NS',
  },
  {
    value: 'MANORAMA.NS',
    label: 'MANORAMA.NS,  Manorama Industries Limited',
    name: 'Manorama Industries Limited',
    symbol: 'MANORAMA.NS',
  },
  {
    value: 'LICI.NS',
    label: 'LICI.NS,  Life Insurance Corporation of India',
    name: 'Life Insurance Corporation of India',
    symbol: 'LICI.NS',
  },
  {
    value: 'NGLFINE.NS',
    label: 'NGLFINE.NS,  NGL Fine-Chem Limited',
    name: 'NGL Fine-Chem Limited',
    symbol: 'NGLFINE.NS',
  },
  {
    value: 'PRUDENT.NS',
    label: 'PRUDENT.NS,  Prudent Corporate Advisory Services Limited',
    name: 'Prudent Corporate Advisory Services Limited',
    symbol: 'PRUDENT.NS',
  },
  {
    value: 'PARADEEP.NS',
    label: 'PARADEEP.NS,  Paradeep Phosphates Limited',
    name: 'Paradeep Phosphates Limited',
    symbol: 'PARADEEP.NS',
  },
  {
    value: 'VENUSPIPES.NS',
    label: 'VENUSPIPES.NS,  Venus Pipes and Tubes Limited',
    name: 'Venus Pipes and Tubes Limited',
    symbol: 'VENUSPIPES.NS',
  },
  {
    value: 'ETPA',
    label: 'ETPA,  Ecofin Digital Payments Infrastructure Fund',
    name: 'Ecofin Digital Payments Infrastructure Fund',
    symbol: 'ETPA',
  },
  {
    value: 'PFEL',
    label: 'PFEL,  AXS 2X PFE Bull Daily ETF',
    name: 'AXS 2X PFE Bull Daily ETF',
    symbol: 'PFEL',
  },
  {
    value: 'PFES',
    label: 'PFES,  AXS 2X PFE Bear Daily ETF',
    name: 'AXS 2X PFE Bear Daily ETF',
    symbol: 'PFES',
  },
  {
    value: 'NVDS',
    label: 'NVDS,  AXS 1.25X NVDA Bear Daily ETF',
    name: 'AXS 1.25X NVDA Bear Daily ETF',
    symbol: 'NVDS',
  },
  {
    value: 'NKEQ',
    label: 'NKEQ,  AXS 2X NKE Bear Daily ETF',
    name: 'AXS 2X NKE Bear Daily ETF',
    symbol: 'NKEQ',
  },
  {
    value: 'NKEL',
    label: 'NKEL,  AXS 2X NKE Bull Daily ETF',
    name: 'AXS 2X NKE Bull Daily ETF',
    symbol: 'NKEL',
  },
  {
    value: 'BKUI',
    label: 'BKUI,  BNY Mellon Ultra Short Income ETF',
    name: 'BNY Mellon Ultra Short Income ETF',
    symbol: 'BKUI',
  },
  {
    value: 'NDVG',
    label: 'NDVG,  Nuveen Dividend Growth ETF',
    name: 'Nuveen Dividend Growth ETF',
    symbol: 'NDVG',
  },
  {
    value: 'NSCS',
    label: 'NSCS,  Nuveen Small Cap Select ETF',
    name: 'Nuveen Small Cap Select ETF',
    symbol: 'NSCS',
  },
  {
    value: 'TPHE',
    label: 'TPHE,  Timothy Plan High Dividend Stock Enhanced ETF',
    name: 'Timothy Plan High Dividend Stock Enhanced ETF',
    symbol: 'TPHE',
  },
  {
    value: 'TPLE',
    label: 'TPLE,  Timothy Plan US Large/Mid Cap Core Enhanced ETF',
    name: 'Timothy Plan US Large/Mid Cap Core Enhanced ETF',
    symbol: 'TPLE',
  },
  {
    value: 'IDME',
    label: 'IDME,  International Drawdown Managed Equity ETF',
    name: 'International Drawdown Managed Equity ETF',
    symbol: 'IDME',
  },
  {
    value: 'KONG',
    label: 'KONG,  Formidable Fortress ETF',
    name: 'Formidable Fortress ETF',
    symbol: 'KONG',
  },
  {
    value: 'VSPY',
    label: 'VSPY,  VectorShares Min Vol ETF',
    name: 'VectorShares Min Vol ETF',
    symbol: 'VSPY',
  },
  {
    value: 'JOJO',
    label: 'JOJO,  ATAC Credit Rotation ETF',
    name: 'ATAC Credit Rotation ETF',
    symbol: 'JOJO',
  },
  {
    value: 'KOCG',
    label: 'KOCG,  FIS Knights of Columbus Global Belief ETF',
    name: 'FIS Knights of Columbus Global Belief ETF',
    symbol: 'KOCG',
  },
  {
    value: 'AHHX',
    label: 'AHHX,  Adaptive High Income ETF',
    name: 'Adaptive High Income ETF',
    symbol: 'AHHX',
  },
  {
    value: 'AMTR',
    label: 'AMTR,  ETRACS Alerian Midstream Energy Total Return Index ETN',
    name: 'ETRACS Alerian Midstream Energy Total Return Index ETN',
    symbol: 'AMTR',
  },
  {
    value: 'BSJT',
    label: 'BSJT,  Invesco BulletShares 2029 High Yield Corporate Bond ETF',
    name: 'Invesco BulletShares 2029 High Yield Corporate Bond ETF',
    symbol: 'BSJT',
  },
  {
    value: 'BSMV',
    label: 'BSMV,  Invesco BulletShares 2031 Municipal Bond ETF',
    name: 'Invesco BulletShares 2031 Municipal Bond ETF',
    symbol: 'BSMV',
  },
  {
    value: 'BUFG',
    label: 'BUFG,  FT Cboe Vest Buffered Allocation Growth ETF',
    name: 'FT Cboe Vest Buffered Allocation Growth ETF',
    symbol: 'BUFG',
  },
  {
    value: 'CTEX',
    label: 'CTEX,  ProShares S&P Kensho Cleantech ETF',
    name: 'ProShares S&P Kensho Cleantech ETF',
    symbol: 'CTEX',
  },
  {
    value: 'BCAT',
    label: 'BCAT,  BlackRock Capital Allocation Trust',
    name: 'BlackRock Capital Allocation Trust',
    symbol: 'BCAT',
  },
  {
    value: 'VNRT.L',
    label: 'VNRT.L,  Vanguard FTSE North America UCITS ETF',
    name: 'Vanguard FTSE North America UCITS ETF',
    symbol: 'VNRT.L',
  },
  {
    value: 'SAI',
    label: 'SAI,  SAI.TECH Global Corporation',
    name: 'SAI.TECH Global Corporation',
    symbol: 'SAI',
  },
  {
    value: 'RERGX',
    label: 'RERGX,  EuroPacific Growth Fund Cl R-6',
    name: 'EuroPacific Growth Fund Cl R-6',
    symbol: 'RERGX',
  },
  {
    value: 'FDFIX',
    label: 'FDFIX,  Fidelity Flex 500 Index Fund',
    name: 'Fidelity Flex 500 Index Fund',
    symbol: 'FDFIX',
  },
  {
    value: 'FLAPX',
    label: 'FLAPX,  Fidelity Flex Mid Cap Index Fund',
    name: 'Fidelity Flex Mid Cap Index Fund',
    symbol: 'FLAPX',
  },
  {
    value: 'VTSNX',
    label:
      'VTSNX,  Vanguard Total International Stock Index Fund Institutional Shares',
    name: 'Vanguard Total International Stock Index Fund Institutional Shares',
    symbol: 'VTSNX',
  },
  {
    value: 'FLXSX',
    label: 'FLXSX,  Fidelity Flex Small Cap Index Fund',
    name: 'Fidelity Flex Small Cap Index Fund',
    symbol: 'FLXSX',
  },
  {
    value: 'FITFX',
    label: 'FITFX,  Fidelity Flex International Index Fund',
    name: 'Fidelity Flex International Index Fund',
    symbol: 'FITFX',
  },
  {
    value: 'VBTIX',
    label:
      'VBTIX,  Vanguard Bond Index Fund Total Bond Mkt Index Fund Institutional Share',
    name: 'Vanguard Bond Index Fund Total Bond Mkt Index Fund Institutional Share',
    symbol: 'VBTIX',
  },
  {
    value: '4322.SR',
    label: '4322.SR,  Retal Urban Development Company',
    name: 'Retal Urban Development Company',
    symbol: '4322.SR',
  },
  {
    value: '6013.SR',
    label: '6013.SR,  Development Works Food Co. Ltd',
    name: 'Development Works Food Co. Ltd',
    symbol: '6013.SR',
  },
  {
    value: '9516.SR',
    label: '9516.SR,  Natural Gas Distribution Company',
    name: 'Natural Gas Distribution Company',
    symbol: '9516.SR',
  },
  {
    value: '9526.SR',
    label:
      '9526.SR,  Jahez International Company for Information Systems Technology',
    name: 'Jahez International Company for Information Systems Technology',
    symbol: '9526.SR',
  },
  {
    value: '9535.SR',
    label: '9535.SR,  Ladun Investment Company',
    name: 'Ladun Investment Company',
    symbol: '9535.SR',
  },
  {
    value: '9536.SR',
    label: '9536.SR,  Arabian Food & Dairy Factories Company',
    name: 'Arabian Food & Dairy Factories Company',
    symbol: '9536.SR',
  },
  {
    value: '9537.SR',
    label: '9537.SR,  Amwaj International Company',
    name: 'Amwaj International Company',
    symbol: '9537.SR',
  },
  {
    value: 'AGTHX',
    label: 'AGTHX,  The Growth Fund of America Class A Shares',
    name: 'The Growth Fund of America Class A Shares',
    symbol: 'AGTHX',
  },
  {
    value: '9538.SR',
    label: '9538.SR,  Naseej for Technology Co.',
    name: 'Naseej for Technology Co.',
    symbol: '9538.SR',
  },
  {
    value: '9539.SR',
    label: '9539.SR,  Aqaseem Factory for Chemicals and Plastics Co.',
    name: 'Aqaseem Factory for Chemicals and Plastics Co.',
    symbol: '9539.SR',
  },
  {
    value: '9540.SR',
    label: '9540.SR,  National Environmental Recycling Company',
    name: 'National Environmental Recycling Company',
    symbol: '9540.SR',
  },
  {
    value: '9541.SR',
    label: '9541.SR,  Academy Of Learning Company',
    name: 'Academy Of Learning Company',
    symbol: '9541.SR',
  },
  {
    value: '9542.SR',
    label: '9542.SR,  Keir International Co.',
    name: 'Keir International Co.',
    symbol: '9542.SR',
  },
  {
    value: '9544.SR',
    label: '9544.SR,  Future Care Trading Co.',
    name: 'Future Care Trading Co.',
    symbol: '9544.SR',
  },
  {
    value: 'VSCIX',
    label: 'VSCIX,  Vanguard Index Trust Small-Cap Index Fund Instl Shares',
    name: 'Vanguard Index Trust Small-Cap Index Fund Instl Shares',
    symbol: 'VSCIX',
  },
  {
    value: 'FUENX',
    label: 'FUENX,  Fidelity Flex Municipal Income Fund',
    name: 'Fidelity Flex Municipal Income Fund',
    symbol: 'FUENX',
  },
  {
    value: 'MDIZX',
    label: 'MDIZX,  MFS International Diversification Fund - Class R6',
    name: 'MFS International Diversification Fund - Class R6',
    symbol: 'MDIZX',
  },
  {
    value: 'FFLDX',
    label:
      'FFLDX,  Fidelity Freedom Index 2055 Fund - Institutional Premium Class',
    name: 'Fidelity Freedom Index 2055 Fund - Institutional Premium Class',
    symbol: 'FFLDX',
  },
  {
    value: 'PIMIX',
    label: 'PIMIX,  PIMCO Income Fund Insti Class',
    name: 'PIMCO Income Fund Insti Class',
    symbol: 'PIMIX',
  },
  {
    value: 'FSPGX',
    label: 'FSPGX,  Fidelity Large Cap Growth Index Fund',
    name: 'Fidelity Large Cap Growth Index Fund',
    symbol: 'FSPGX',
  },
  {
    value: 'VMCIX',
    label:
      'VMCIX,  Vanguard Index Trust Mid-Cap Index Fund Institutional Shares',
    name: 'Vanguard Index Trust Mid-Cap Index Fund Institutional Shares',
    symbol: 'VMCIX',
  },
  {
    value: 'ODVIX',
    label: 'ODVIX,  Invesco Developing Markets Fund Class R6',
    name: 'Invesco Developing Markets Fund Class R6',
    symbol: 'ODVIX',
  },
  {
    value: 'WFRPX',
    label: 'WFRPX,  Wealthfront Risk Parity Fund Class W',
    name: 'Wealthfront Risk Parity Fund Class W',
    symbol: 'WFRPX',
  },
  {
    value: 'VIEIX',
    label: 'VIEIX,  Vanguard Index Trust Extended Market Index Fd Instl Shs',
    name: 'Vanguard Index Trust Extended Market Index Fd Instl Shs',
    symbol: 'VIEIX',
  },
  {
    value: 'OIEJX',
    label: 'OIEJX,  JPMorgan Equity Income Fund R6',
    name: 'JPMorgan Equity Income Fund R6',
    symbol: 'OIEJX',
  },
  {
    value: 'FFLEX',
    label:
      'FFLEX,  Fidelity Freedom Index 2060 Fund - Institutional Premium Class',
    name: 'Fidelity Freedom Index 2060 Fund - Institutional Premium Class',
    symbol: 'FFLEX',
  },
  {
    value: 'VITSX',
    label:
      'VITSX,  Vanguard Index Trust Total Stock Market Index Fund Institutional Share',
    name: 'Vanguard Index Trust Total Stock Market Index Fund Institutional Share',
    symbol: 'VITSX',
  },
  {
    value: 'FFOPX',
    label:
      'FFOPX,  Fidelity Freedom Index 2050 Fund - Institutional Premium Class',
    name: 'Fidelity Freedom Index 2050 Fund - Institutional Premium Class',
    symbol: 'FFOPX',
  },
  {
    value: 'MEIKX',
    label: 'MEIKX,  MFS Value Fund Class R6',
    name: 'MFS Value Fund Class R6',
    symbol: 'MEIKX',
  },
  {
    value: 'PTRQX',
    label: 'PTRQX,  PGIM Total Return Bond Fund Class R6',
    name: 'PGIM Total Return Bond Fund Class R6',
    symbol: 'PTRQX',
  },
  {
    value: 'BTMKX',
    label: 'BTMKX,  iShares MSCI EAFE International Index Fund - Class K',
    name: 'iShares MSCI EAFE International Index Fund - Class K',
    symbol: 'BTMKX',
  },
  {
    value: 'FDTRX',
    label: 'FDTRX,  Franlin DynaTech Fund Cl R6',
    name: 'Franlin DynaTech Fund Cl R6',
    symbol: 'FDTRX',
  },
  {
    value: 'ABALX',
    label: 'ABALX,  American Balanced Fund, Class A Shs',
    name: 'American Balanced Fund, Class A Shs',
    symbol: 'ABALX',
  },
  {
    value: 'SWAGX',
    label: 'SWAGX,  Schwab U.S. Aggregate Bond Index Fund',
    name: 'Schwab U.S. Aggregate Bond Index Fund',
    symbol: 'SWAGX',
  },
  {
    value: 'ANWPX',
    label: 'ANWPX,  New Perspective Fund, Class A Shares',
    name: 'New Perspective Fund, Class A Shares',
    symbol: 'ANWPX',
  },
  {
    value: 'PTTRX',
    label: 'PTTRX,  PIMCO Funds Total Return Fund Institutional Shares',
    name: 'PIMCO Funds Total Return Fund Institutional Shares',
    symbol: 'PTTRX',
  },
  {
    value: 'FIBUX',
    label: 'FIBUX,  Fidelity Flex U.S. Bond Index Fund',
    name: 'Fidelity Flex U.S. Bond Index Fund',
    symbol: 'FIBUX',
  },
  {
    value: 'FRZA',
    label: 'FRZA,  Forza X1, Inc.',
    name: 'Forza X1, Inc.',
    symbol: 'FRZA',
  },
  {
    value: 'PET',
    label: 'PET,  Wag! Group Co.',
    name: 'Wag! Group Co.',
    symbol: 'PET',
  },
  {
    value: 'BYOB',
    label: 'BYOB,  SoFi Be Your Own Boss ETF',
    name: 'SoFi Be Your Own Boss ETF',
    symbol: 'BYOB',
  },
  {
    value: 'RUFF',
    label: 'RUFF,  Alpha Dog ETF',
    name: 'Alpha Dog ETF',
    symbol: 'RUFF',
  },
  {
    value: 'SAEF',
    label: 'SAEF,  Schwab Ariel ESG ETF',
    name: 'Schwab Ariel ESG ETF',
    symbol: 'SAEF',
  },
  {
    value: 'RULE',
    label: 'RULE,  Adaptive Core ETF',
    name: 'Adaptive Core ETF',
    symbol: 'RULE',
  },
  {
    value: 'SBND',
    label: 'SBND,  Columbia Short Duration Bond ETF',
    name: 'Columbia Short Duration Bond ETF',
    symbol: 'SBND',
  },
  {
    value: 'SCRD',
    label: 'SCRD,  Janus Henderson Sustainable Corporate Bond ETF',
    name: 'Janus Henderson Sustainable Corporate Bond ETF',
    symbol: 'SCRD',
  },
  {
    value: 'SIHY',
    label: 'SIHY,  Harbor Scientific Alpha High-Yield ETF',
    name: 'Harbor Scientific Alpha High-Yield ETF',
    symbol: 'SIHY',
  },
  {
    value: 'SIXO',
    label: 'SIXO,  AllianzIM U.S. Large Cap 6 Month Buffer10 Apr/Oct ETF',
    name: 'AllianzIM U.S. Large Cap 6 Month Buffer10 Apr/Oct ETF',
    symbol: 'SIXO',
  },
  {
    value: 'SMI',
    label: 'SMI,  VanEck HIP Sustainable Muni ETF',
    name: 'VanEck HIP Sustainable Muni ETF',
    symbol: 'SMI',
  },
  {
    value: 'SPC',
    label: 'SPC,  CrossingBridge Pre-Merger SPAC ETF',
    name: 'CrossingBridge Pre-Merger SPAC ETF',
    symbol: 'SPC',
  },
  {
    value: 'SSFI',
    label: 'SSFI,  Day Hagan/Ned Davis Research Smart Sector Fixed Income ETF',
    name: 'Day Hagan/Ned Davis Research Smart Sector Fixed Income ETF',
    symbol: 'SSFI',
  },
  {
    value: 'SSPX',
    label: 'SSPX,  Janus Henderson U.S. Sustainable Equity ETF',
    name: 'Janus Henderson U.S. Sustainable Equity ETF',
    symbol: 'SSPX',
  },
  {
    value: 'SXUS',
    label: 'SXUS,  Janus Henderson International Sustainable Equity ETF',
    name: 'Janus Henderson International Sustainable Equity ETF',
    symbol: 'SXUS',
  },
  {
    value: 'TAGG',
    label: 'TAGG,  T. Rowe Price QM U.S. Bond ETF',
    name: 'T. Rowe Price QM U.S. Bond ETF',
    symbol: 'TAGG',
  },
  {
    value: 'PYPT',
    label: 'PYPT,  AXS 1.5X PYPL Bull Daily ETF',
    name: 'AXS 1.5X PYPL Bull Daily ETF',
    symbol: 'PYPT',
  },
  {
    value: 'TSLQ',
    label: 'TSLQ,  AXS TSLA Bear Daily ETF',
    name: 'AXS TSLA Bear Daily ETF',
    symbol: 'TSLQ',
  },
  {
    value: 'TBUX',
    label: 'TBUX,  T. Rowe Price Ultra Short-Term Bond ETF',
    name: 'T. Rowe Price Ultra Short-Term Bond ETF',
    symbol: 'TBUX',
  },
  {
    value: 'TEMP',
    label: 'TEMP,  JPMorgan Climate Change Solutions ETF',
    name: 'JPMorgan Climate Change Solutions ETF',
    symbol: 'TEMP',
  },
  {
    value: 'TINT',
    label: 'TINT,  ProShares Smart Materials ETF',
    name: 'ProShares Smart Materials ETF',
    symbol: 'TINT',
  },
  {
    value: 'TINY',
    label: 'TINY,  ProShares Nanotechnology ETF',
    name: 'ProShares Nanotechnology ETF',
    symbol: 'TINY',
  },
  {
    value: 'MBOX',
    label: 'MBOX,  Freedom Day Dividend ETF',
    name: 'Freedom Day Dividend ETF',
    symbol: 'MBOX',
  },
  {
    value: 'ATFV',
    label: 'ATFV,  Alger 35 ETF',
    name: 'Alger 35 ETF',
    symbol: 'ATFV',
  },
  {
    value: 'TMFG',
    label: 'TMFG,  Motley Fool Global Opportunities ETF',
    name: 'Motley Fool Global Opportunities ETF',
    symbol: 'TMFG',
  },
  {
    value: 'TMFM',
    label: 'TMFM,  Motley Fool Mid-Cap Growth ETF',
    name: 'Motley Fool Mid-Cap Growth ETF',
    symbol: 'TMFM',
  },
  {
    value: 'TOTR',
    label: 'TOTR,  T. Rowe Price Total Return ETF',
    name: 'T. Rowe Price Total Return ETF',
    symbol: 'TOTR',
  },
  {
    value: 'FORH',
    label: 'FORH,  Formidable ETF',
    name: 'Formidable ETF',
    symbol: 'FORH',
  },
  {
    value: 'UBCB',
    label: 'UBCB,  UBC Algorithmic Fundamentals ETF',
    name: 'UBC Algorithmic Fundamentals ETF',
    symbol: 'UBCB',
  },
  {
    value: 'UMMA',
    label: 'UMMA,  Wahed Dow Jones Islamic World ETF',
    name: 'Wahed Dow Jones Islamic World ETF',
    symbol: 'UMMA',
  },
  {
    value: 'USVT',
    label: 'USVT,  Lyrical US VALUE ETF',
    name: 'Lyrical US VALUE ETF',
    symbol: 'USVT',
  },
  {
    value: 'UVDV',
    label: 'UVDV,  UVA Dividend Value ETF',
    name: 'UVA Dividend Value ETF',
    symbol: 'UVDV',
  },
  {
    value: 'IDAT',
    label: 'IDAT,  iShares Cloud 5G and Tech ETF',
    name: 'iShares Cloud 5G and Tech ETF',
    symbol: 'IDAT',
  },
  {
    value: 'VDNI',
    label: 'VDNI,  V-Shares US Leadership Diversity ETF',
    name: 'V-Shares US Leadership Diversity ETF',
    symbol: 'VDNI',
  },
  {
    value: 'VNAM',
    label: 'VNAM,  Global X MSCI Vietnam ETF',
    name: 'Global X MSCI Vietnam ETF',
    symbol: 'VNAM',
  },
  {
    value: 'WLTG',
    label: 'WLTG,  WealthTrust DBS Long Term Growth ETF',
    name: 'WealthTrust DBS Long Term Growth ETF',
    symbol: 'WLTG',
  },
  {
    value: 'WTAI',
    label: 'WTAI,  WisdomTree Artificial Intelligence and Innovation Fund',
    name: 'WisdomTree Artificial Intelligence and Innovation Fund',
    symbol: 'WTAI',
  },
  {
    value: 'XBJA',
    label: 'XBJA,  Innovator U.S. Equity Accelerated 9 Buffer ETF - January',
    name: 'Innovator U.S. Equity Accelerated 9 Buffer ETF - January',
    symbol: 'XBJA',
  },
  {
    value: 'XBOC',
    label: 'XBOC,  Innovator U.S. Equity Accelerated 9 Buffer ETF - October',
    name: 'Innovator U.S. Equity Accelerated 9 Buffer ETF - October',
    symbol: 'XBOC',
  },
  {
    value: 'XDEC',
    label:
      'XDEC,  FT Cboe Vest U.S. Equity Enhance & Moderate Buffer ETF - December',
    name: 'FT Cboe Vest U.S. Equity Enhance & Moderate Buffer ETF - December',
    symbol: 'XDEC',
  },
  {
    value: 'JIDA',
    label: 'JIDA,  JPMorgan ActiveBuilders International Equity ETF',
    name: 'JPMorgan ActiveBuilders International Equity ETF',
    symbol: 'JIDA',
  },
  {
    value: 'XDJA',
    label: 'XDJA,  Innovator U.S. Equity Accelerated ETF - January',
    name: 'Innovator U.S. Equity Accelerated ETF - January',
    symbol: 'XDJA',
  },
  {
    value: 'JUSA',
    label: 'JUSA,  JPMorgan ActiveBuilders U.S. Large Cap Equity ETF',
    name: 'JPMorgan ActiveBuilders U.S. Large Cap Equity ETF',
    symbol: 'JUSA',
  },
  {
    value: 'XDOC',
    label: 'XDOC,  Innovator U.S. Equity Accelerated ETF - October',
    name: 'Innovator U.S. Equity Accelerated ETF - October',
    symbol: 'XDOC',
  },
  {
    value: 'DC',
    label: 'DC,  Dakota Gold Corp.',
    name: 'Dakota Gold Corp.',
    symbol: 'DC',
  },
  {
    value: 'XJUN',
    label:
      'XJUN,  FT Cboe Vest U.S. Equity Enhance & Moderate Buffer ETF - June',
    name: 'FT Cboe Vest U.S. Equity Enhance & Moderate Buffer ETF - June',
    symbol: 'XJUN',
  },
  {
    value: 'XTJA',
    label: 'XTJA,  Innovator U.S. Equity Accelerated Plus ETF - January',
    name: 'Innovator U.S. Equity Accelerated Plus ETF - January',
    symbol: 'XTJA',
  },
  {
    value: 'AEMB',
    label: 'AEMB,  American Century Emerging Markets Bond ETF',
    name: 'American Century Emerging Markets Bond ETF',
    symbol: 'AEMB',
  },
  {
    value: 'XTOC',
    label: 'XTOC,  Innovator U.S. Equity Accelerated Plus ETF - October',
    name: 'Innovator U.S. Equity Accelerated Plus ETF - October',
    symbol: 'XTOC',
  },
  {
    value: 'ESGY',
    label: 'ESGY,  American Century Sustainable Growth ETF',
    name: 'American Century Sustainable Growth ETF',
    symbol: 'ESGY',
  },
  {
    value: 'LEXI',
    label: 'LEXI,  Alexis Practical Tactical ETF',
    name: 'Alexis Practical Tactical ETF',
    symbol: 'LEXI',
  },
  {
    value: 'YSEP',
    label: 'YSEP,  FT Cboe Vest International Equity Buffer ETF - September',
    name: 'FT Cboe Vest International Equity Buffer ETF - September',
    symbol: 'YSEP',
  },
  {
    value: 'DYLD',
    label: 'DYLD,  LeaderShares Dynamic Yield ETF',
    name: 'LeaderShares Dynamic Yield ETF',
    symbol: 'DYLD',
  },
  {
    value: 'IAUM',
    label: 'IAUM,  iShares Gold Trust Micro',
    name: 'iShares Gold Trust Micro',
    symbol: 'IAUM',
  },
  {
    value: 'ZGEN',
    label: 'ZGEN,  The Gen Z ETF',
    name: 'The Gen Z ETF',
    symbol: 'ZGEN',
  },
  {
    value: 'RSEE',
    label: 'RSEE,  Rareview Systematic Equity ETF',
    name: 'Rareview Systematic Equity ETF',
    symbol: 'RSEE',
  },
  {
    value: 'ITAN',
    label: 'ITAN,  Sparkline Intangible Value ETF',
    name: 'Sparkline Intangible Value ETF',
    symbol: 'ITAN',
  },
  {
    value: 'NETZ',
    label: 'NETZ,  Engine No. 1 Transform Climate ETF',
    name: 'Engine No. 1 Transform Climate ETF',
    symbol: 'NETZ',
  },
  {
    value: 'JRE',
    label: 'JRE,  Janus Henderson U.S. Real Estate ETF',
    name: 'Janus Henderson U.S. Real Estate ETF',
    symbol: 'JRE',
  },
  {
    value: 'WINN',
    label: 'WINN,  Harbor Long-Term Growers ETF',
    name: 'Harbor Long-Term Growers ETF',
    symbol: 'WINN',
  },
  {
    value: 'SPAX',
    label: 'SPAX,  Robinson Alternative Yield Pre-Merger SPAC ETF',
    name: 'Robinson Alternative Yield Pre-Merger SPAC ETF',
    symbol: 'SPAX',
  },
  {
    value: 'AGGH',
    label: 'AGGH,  Simplify Aggregate Bond PLUS Credit Hedge ETF',
    name: 'Simplify Aggregate Bond PLUS Credit Hedge ETF',
    symbol: 'AGGH',
  },
  {
    value: 'VOTE',
    label: 'VOTE,  Engine No. 1 Transform 500 ETF',
    name: 'Engine No. 1 Transform 500 ETF',
    symbol: 'VOTE',
  },
  {
    value: 'BSTP',
    label: 'BSTP,  Innovator Buffer Step-Up Strategy ETF',
    name: 'Innovator Buffer Step-Up Strategy ETF',
    symbol: 'BSTP',
  },
  {
    value: 'GCLN',
    label: 'GCLN,  Goldman Sachs Bloomberg Clean Energy Equity ETF',
    name: 'Goldman Sachs Bloomberg Clean Energy Equity ETF',
    symbol: 'GCLN',
  },
  {
    value: 'PBUG',
    label: 'PBUG,  Pacer iPath Gold Trendpilot ETNs',
    name: 'Pacer iPath Gold Trendpilot ETNs',
    symbol: 'PBUG',
  },
  {
    value: 'WBAT',
    label: 'WBAT,  WisdomTree Battery Value Chain and Innovation Fund',
    name: 'WisdomTree Battery Value Chain and Innovation Fund',
    symbol: 'WBAT',
  },
  {
    value: 'SHUS',
    label: 'SHUS,  Syntax Stratified U.S. Total Market Hedged ETF',
    name: 'Syntax Stratified U.S. Total Market Hedged ETF',
    symbol: 'SHUS',
  },
  {
    value: 'OOTO',
    label: 'OOTO,  Direxion Daily Travel & Vacation Bull 2X Shares',
    name: 'Direxion Daily Travel & Vacation Bull 2X Shares',
    symbol: 'OOTO',
  },
  {
    value: 'XHYD',
    label: 'XHYD,  BondBloxx US High Yield Consumer Non-Cyclicals Sector ETF',
    name: 'BondBloxx US High Yield Consumer Non-Cyclicals Sector ETF',
    symbol: 'XHYD',
  },
  {
    value: 'KGHG',
    label: 'KGHG,  KraneShares Global Carbon Transformation ETF',
    name: 'KraneShares Global Carbon Transformation ETF',
    symbol: 'KGHG',
  },
  {
    value: 'TSPA',
    label: 'TSPA,  T. Rowe Price US Equity Research ETF',
    name: 'T. Rowe Price US Equity Research ETF',
    symbol: 'TSPA',
  },
  {
    value: 'WDNA',
    label: 'WDNA,  WisdomTree BioRevolution Fund',
    name: 'WisdomTree BioRevolution Fund',
    symbol: 'WDNA',
  },
  {
    value: 'BUFB',
    label: 'BUFB,  Innovator Laddered Allocation Buffer ETF',
    name: 'Innovator Laddered Allocation Buffer ETF',
    symbol: 'BUFB',
  },
  {
    value: 'WRND',
    label: 'WRND,  IQ Global Equity R&D Leaders ETF',
    name: 'IQ Global Equity R&D Leaders ETF',
    symbol: 'WRND',
  },
  {
    value: 'PFUT',
    label: 'PFUT,  Putnam Sustainable Future ETF',
    name: 'Putnam Sustainable Future ETF',
    symbol: 'PFUT',
  },
  {
    value: 'PGRO',
    label: 'PGRO,  Putnam Focused Large Cap Growth ETF',
    name: 'Putnam Focused Large Cap Growth ETF',
    symbol: 'PGRO',
  },
  {
    value: 'CGXU',
    label: 'CGXU,  Capital Group International Focus Equity ETF',
    name: 'Capital Group International Focus Equity ETF',
    symbol: 'CGXU',
  },
  {
    value: 'PLDR',
    label: 'PLDR,  Putnam Sustainable Leaders ETF',
    name: 'Putnam Sustainable Leaders ETF',
    symbol: 'PLDR',
  },
  {
    value: 'GEMD',
    label: 'GEMD,  Goldman Sachs Access Emerging Markets USD Bond ETF',
    name: 'Goldman Sachs Access Emerging Markets USD Bond ETF',
    symbol: 'GEMD',
  },
  {
    value: 'NTSE',
    label: 'NTSE,  WisdomTree Emerging Markets Efficient Core Fund',
    name: 'WisdomTree Emerging Markets Efficient Core Fund',
    symbol: 'NTSE',
  },
  {
    value: 'CGDV',
    label: 'CGDV,  Capital Group Dividend Value ETF',
    name: 'Capital Group Dividend Value ETF',
    symbol: 'CGDV',
  },
  {
    value: 'NTSI',
    label: 'NTSI,  WisdomTree International Efficient Core Fund',
    name: 'WisdomTree International Efficient Core Fund',
    symbol: 'NTSI',
  },
  {
    value: 'GLDB',
    label: 'GLDB,  Strategy Shares Gold-Hedged Bond ETF',
    name: 'Strategy Shares Gold-Hedged Bond ETF',
    symbol: 'GLDB',
  },
  {
    value: 'LRND',
    label: 'LRND,  IQ U.S. Large Cap R&D Leaders ETF',
    name: 'IQ U.S. Large Cap R&D Leaders ETF',
    symbol: 'LRND',
  },
  {
    value: 'CGCP',
    label: 'CGCP,  Capital Group Core Plus Income ETF',
    name: 'Capital Group Core Plus Income ETF',
    symbol: 'CGCP',
  },
  {
    value: 'SXQG',
    label: 'SXQG,  ETC 6 Meridian Quality Growth ETF',
    name: 'ETC 6 Meridian Quality Growth ETF',
    symbol: 'SXQG',
  },
  {
    value: 'XHYC',
    label: 'XHYC,  BondBloxx US High Yield Consumer Cyclicals Sector ETF',
    name: 'BondBloxx US High Yield Consumer Cyclicals Sector ETF',
    symbol: 'XHYC',
  },
  {
    value: 'XHYF',
    label: 'XHYF,  BondBloxx US High Yield Financial & REIT Sector ETF',
    name: 'BondBloxx US High Yield Financial & REIT Sector ETF',
    symbol: 'XHYF',
  },
  {
    value: 'AGOX',
    label: 'AGOX,  Adaptive Alpha Opportunities ETF',
    name: 'Adaptive Alpha Opportunities ETF',
    symbol: 'AGOX',
  },
  {
    value: 'CGGO',
    label: 'CGGO,  Capital Group Global Growth Equity ETF',
    name: 'Capital Group Global Growth Equity ETF',
    symbol: 'CGGO',
  },
  {
    value: 'HYIN',
    label: 'HYIN,  WisdomTree Alternative Income Fund',
    name: 'WisdomTree Alternative Income Fund',
    symbol: 'HYIN',
  },
  {
    value: 'CGGR',
    label: 'CGGR,  Capital Group Growth ETF',
    name: 'Capital Group Growth ETF',
    symbol: 'CGGR',
  },
  {
    value: 'HYBL',
    label: 'HYBL,  SPDR Blackstone High Income ETF',
    name: 'SPDR Blackstone High Income ETF',
    symbol: 'HYBL',
  },
  {
    value: 'DJIA',
    label: 'DJIA,  Global X Dow 30 Covered Call ETF',
    name: 'Global X Dow 30 Covered Call ETF',
    symbol: 'DJIA',
  },
  {
    value: 'DFSV',
    label: 'DFSV,  Dimensional US Small Cap Value ETF',
    name: 'Dimensional US Small Cap Value ETF',
    symbol: 'DFSV',
  },
  {
    value: 'PSTP',
    label: 'PSTP,  Innovator Power Buffer Step-Up Strategy ETF',
    name: 'Innovator Power Buffer Step-Up Strategy ETF',
    symbol: 'PSTP',
  },
  {
    value: 'MSTQ',
    label: 'MSTQ,  LHA Market State Tactical Q ETF',
    name: 'LHA Market State Tactical Q ETF',
    symbol: 'MSTQ',
  },
  {
    value: 'XHYI',
    label: 'XHYI,  BondBloxx US High Yield Industrial Sector ETF',
    name: 'BondBloxx US High Yield Industrial Sector ETF',
    symbol: 'XHYI',
  },
  {
    value: 'XHYH',
    label: 'XHYH,  BondBloxx US High Yield Healthcare Sector ETF',
    name: 'BondBloxx US High Yield Healthcare Sector ETF',
    symbol: 'XHYH',
  },
  {
    value: 'XHYE',
    label: 'XHYE,  BondBloxx US High Yield Energy Sector ETF',
    name: 'BondBloxx US High Yield Energy Sector ETF',
    symbol: 'XHYE',
  },
  {
    value: 'DUHP',
    label: 'DUHP,  Dimensional US High Profitability ETF',
    name: 'Dimensional US High Profitability ETF',
    symbol: 'DUHP',
  },
  {
    value: 'EMCR',
    label:
      'EMCR,  Xtrackers Emerging Markets Carbon Reduction and Climate Improvers ETF',
    name: 'Xtrackers Emerging Markets Carbon Reduction and Climate Improvers ETF',
    symbol: 'EMCR',
  },
  {
    value: 'HYRM',
    label: 'HYRM,  Xtrackers Risk Managed USD High Yield Strategy ETF',
    name: 'Xtrackers Risk Managed USD High Yield Strategy ETF',
    symbol: 'HYRM',
  },
  {
    value: 'IBHH',
    label: 'IBHH,  iShares iBonds 2028 Term High Yield and Income ETF',
    name: 'iShares iBonds 2028 Term High Yield and Income ETF',
    symbol: 'IBHH',
  },
  {
    value: 'LETB',
    label: 'LETB,  AdvisorShares Let Bob AI Powered Momentum ETF',
    name: 'AdvisorShares Let Bob AI Powered Momentum ETF',
    symbol: 'LETB',
  },
  {
    value: 'IBHI',
    label: 'IBHI,  iShares iBonds 2029 Term High Yield and Income ETF',
    name: 'iShares iBonds 2029 Term High Yield and Income ETF',
    symbol: 'IBHI',
  },
  {
    value: 'AVSD',
    label: 'AVSD,  Avantis Responsible International Equity ETF',
    name: 'Avantis Responsible International Equity ETF',
    symbol: 'AVSD',
  },
  {
    value: 'ARVR',
    label: 'ARVR,  First Trust Indxx Metaverse ETF',
    name: 'First Trust Indxx Metaverse ETF',
    symbol: 'ARVR',
  },
  {
    value: 'AVSE',
    label: 'AVSE,  Avantis Responsible Emerging Markets Equity ETF',
    name: 'Avantis Responsible Emerging Markets Equity ETF',
    symbol: 'AVSE',
  },
  {
    value: 'BULD',
    label: 'BULD,  Pacer BlueStar Engineering the Future ETF',
    name: 'Pacer BlueStar Engineering the Future ETF',
    symbol: 'BULD',
  },
  {
    value: 'BYRE',
    label: 'BYRE,  Principal Real Estate Active Opportunities ETF',
    name: 'Principal Real Estate Active Opportunities ETF',
    symbol: 'BYRE',
  },
  {
    value: 'CRIT',
    label: 'CRIT,  Optica Rare Earths & Critical Materials ETF',
    name: 'Optica Rare Earths & Critical Materials ETF',
    symbol: 'CRIT',
  },
  {
    value: 'CRYP',
    label: 'CRYP,  AdvisorShares Managed Bitcoin Strategy ETF',
    name: 'AdvisorShares Managed Bitcoin Strategy ETF',
    symbol: 'CRYP',
  },
  {
    value: 'DBND',
    label: 'DBND,  DoubleLine Opportunistic Bond ETF',
    name: 'DoubleLine Opportunistic Bond ETF',
    symbol: 'DBND',
  },
  {
    value: 'DEHP',
    label: 'DEHP,  Dimensional Emerging Markets High Profitability ETF',
    name: 'Dimensional Emerging Markets High Profitability ETF',
    symbol: 'DEHP',
  },
  {
    value: 'DFEM',
    label: 'DFEM,  Dimensional Emerging Markets Core Equity 2 ETF',
    name: 'Dimensional Emerging Markets Core Equity 2 ETF',
    symbol: 'DFEM',
  },
  {
    value: 'DFEV',
    label: 'DFEV,  Dimensional Emerging Markets Value ETF',
    name: 'Dimensional Emerging Markets Value ETF',
    symbol: 'DFEV',
  },
  {
    value: 'DFIC',
    label: 'DFIC,  Dimensional International Core Equity 2 ETF',
    name: 'Dimensional International Core Equity 2 ETF',
    symbol: 'DFIC',
  },
  {
    value: 'DFIS',
    label: 'DFIS,  Dimensional International Small Cap ETF',
    name: 'Dimensional International Small Cap ETF',
    symbol: 'DFIS',
  },
  {
    value: 'DFUV',
    label: 'DFUV,  Dimensional US Marketwide Value ETF',
    name: 'Dimensional US Marketwide Value ETF',
    symbol: 'DFUV',
  },
  {
    value: 'DIHP',
    label: 'DIHP,  Dimensional International High Profitability ETF',
    name: 'Dimensional International High Profitability ETF',
    symbol: 'DIHP',
  },
  {
    value: 'DISV',
    label: 'DISV,  Dimensional International Small Cap Value ETF',
    name: 'Dimensional International Small Cap Value ETF',
    symbol: 'DISV',
  },
  {
    value: 'EKG',
    label: 'EKG,  First Trust Nasdaq Lux Digital Health Solutions ETF',
    name: 'First Trust Nasdaq Lux Digital Health Solutions ETF',
    symbol: 'EKG',
  },
  {
    value: 'EVMT',
    label:
      'EVMT,  Invesco Electric Vehicle Metals Commodity Strategy No K-1 ETF',
    name: 'Invesco Electric Vehicle Metals Commodity Strategy No K-1 ETF',
    symbol: 'EVMT',
  },
  {
    value: 'FDIG',
    label: 'FDIG,  Fidelity Crypto Industry and Digital Payments ETF',
    name: 'Fidelity Crypto Industry and Digital Payments ETF',
    symbol: 'FDIG',
  },
  {
    value: 'FEEM',
    label: 'FEEM,  FlexShares ESG & Climate Emerging Markets Core Index Fund',
    name: 'FlexShares ESG & Climate Emerging Markets Core Index Fund',
    symbol: 'FEEM',
  },
  {
    value: 'FEMA',
    label: 'FEMA,  Procure Disaster Recovery Strategy ETF',
    name: 'Procure Disaster Recovery Strategy ETF',
    symbol: 'FEMA',
  },
  {
    value: 'FIG',
    label: 'FIG,  Simplify Macro Strategy ETF',
    name: 'Simplify Macro Strategy ETF',
    symbol: 'FIG',
  },
  {
    value: 'FMCX',
    label: 'FMCX,  FMC Excelsior Focus Equity ETF',
    name: 'FMC Excelsior Focus Equity ETF',
    symbol: 'FMCX',
  },
  {
    value: 'FMET',
    label: 'FMET,  Fidelity Metaverse ETF',
    name: 'Fidelity Metaverse ETF',
    symbol: 'FMET',
  },
  {
    value: 'FSBD',
    label: 'FSBD,  Fidelity Sustainable Core Plus Bond ETF',
    name: 'Fidelity Sustainable Core Plus Bond ETF',
    symbol: 'FSBD',
  },
  {
    value: 'TUG',
    label: 'TUG,  STF Tactical Growth ETF',
    name: 'STF Tactical Growth ETF',
    symbol: 'TUG',
  },
  {
    value: 'TUGN',
    label: 'TUGN,  STF Tactical Growth & Income ETF',
    name: 'STF Tactical Growth & Income ETF',
    symbol: 'TUGN',
  },
  {
    value: 'BUFQ',
    label: 'BUFQ,  FT Cboe Vest Fund of Nasdaq-100 Buffer ETFs',
    name: 'FT Cboe Vest Fund of Nasdaq-100 Buffer ETFs',
    symbol: 'BUFQ',
  },
  {
    value: 'NIWM',
    label: 'NIWM,  NightShares 2000 ETF',
    name: 'NightShares 2000 ETF',
    symbol: 'NIWM',
  },
  {
    value: 'DFNM',
    label: 'DFNM,  Dimensional National Municipal Bond ETF',
    name: 'Dimensional National Municipal Bond ETF',
    symbol: 'DFNM',
  },
  {
    value: 'DFRA',
    label: 'DFRA,  Donoghue Forlines Yield Enhanced Real Asset ETF',
    name: 'Donoghue Forlines Yield Enhanced Real Asset ETF',
    symbol: 'DFRA',
  },
  {
    value: 'DFSD',
    label: 'DFSD,  Dimensional Short-Duration Fixed Income ETF',
    name: 'Dimensional Short-Duration Fixed Income ETF',
    symbol: 'DFSD',
  },
  {
    value: 'DIVS',
    label: 'DIVS,  SmartETFs Dividend Builder ETF',
    name: 'SmartETFs Dividend Builder ETF',
    symbol: 'DIVS',
  },
  {
    value: 'DMCY',
    label: 'DMCY,  Democracy International Fund',
    name: 'Democracy International Fund',
    symbol: 'DMCY',
  },
  {
    value: 'DSCF',
    label: 'DSCF,  Discipline Fund ETF',
    name: 'Discipline Fund ETF',
    symbol: 'DSCF',
  },
  {
    value: 'EQUL',
    label: 'EQUL,  IQ Engender Equality ETF',
    name: 'IQ Engender Equality ETF',
    symbol: 'EQUL',
  },
  {
    value: 'ESMV',
    label: 'ESMV,  iShares ESG MSCI USA Min Vol Factor ETF',
    name: 'iShares ESG MSCI USA Min Vol Factor ETF',
    symbol: 'ESMV',
  },
  {
    value: 'EVGBC',
    label: 'EVGBC,  Eaton Vance Global Income Builder NextShares',
    name: 'Eaton Vance Global Income Builder NextShares',
    symbol: 'EVGBC',
  },
  {
    value: 'FCSH',
    label: 'FCSH,  Federated Hermes Short Duration Corporate ETF',
    name: 'Federated Hermes Short Duration Corporate ETF',
    symbol: 'FCSH',
  },
  {
    value: 'FEDM',
    label:
      'FEDM,  FlexShares ESG & Climate Developed Markets ex-US Core Index Fund',
    name: 'FlexShares ESG & Climate Developed Markets ex-US Core Index Fund',
    symbol: 'FEDM',
  },
  {
    value: 'FEHY',
    label:
      'FEHY,  FlexShares ESG & Climate High Yield Corporate Core Index Fund',
    name: 'FlexShares ESG & Climate High Yield Corporate Core Index Fund',
    symbol: 'FEHY',
  },
  {
    value: 'FEIG',
    label:
      'FEIG,  FlexShares ESG & Climate Investment Grade Corporate Core Index Fund',
    name: 'FlexShares ESG & Climate Investment Grade Corporate Core Index Fund',
    symbol: 'FEIG',
  },
  {
    value: 'FEUS',
    label: 'FEUS,  FlexShares ESG & Climate US Large Cap Core Index Fund',
    name: 'FlexShares ESG & Climate US Large Cap Core Index Fund',
    symbol: 'FEUS',
  },
  {
    value: 'FHYS',
    label: 'FHYS,  Federated Hermes Short Duration High Yield ETF',
    name: 'Federated Hermes Short Duration High Yield ETF',
    symbol: 'FHYS',
  },
  {
    value: 'FIVR',
    label: 'FIVR,  Strategy Shares Nasdaq 5 Handl Index ETF',
    name: 'Strategy Shares Nasdaq 5 Handl Index ETF',
    symbol: 'FIVR',
  },
  {
    value: 'FLDZ',
    label: 'FLDZ,  RiverNorth Patriot ETF',
    name: 'RiverNorth Patriot ETF',
    symbol: 'FLDZ',
  },
  {
    value: 'FLTN',
    label: 'FLTN,  Rareview Inflation/Deflation ETF',
    name: 'Rareview Inflation/Deflation ETF',
    symbol: 'FLTN',
  },
  {
    value: 'GCIG',
    label: 'GCIG,  Genuine Investors ETF',
    name: 'Genuine Investors ETF',
    symbol: 'GCIG',
  },
  {
    value: 'GDOC',
    label: 'GDOC,  Goldman Sachs Future Health Care Equity ETF',
    name: 'Goldman Sachs Future Health Care Equity ETF',
    symbol: 'GDOC',
  },
  {
    value: 'GFGF',
    label: 'GFGF,  Guru Favorite Stocks ETF',
    name: 'Guru Favorite Stocks ETF',
    symbol: 'GFGF',
  },
  {
    value: 'GHTA',
    label: 'GHTA,  Goose Hollow Tactical Allocation ETF',
    name: 'Goose Hollow Tactical Allocation ETF',
    symbol: 'GHTA',
  },
  {
    value: 'NUGO',
    label: 'NUGO,  Nuveen Growth Opportunities ETF',
    name: 'Nuveen Growth Opportunities ETF',
    symbol: 'NUGO',
  },
  {
    value: 'PSFO',
    label: 'PSFO,  Pacer Swan SOS Flex (October) ETF',
    name: 'Pacer Swan SOS Flex (October) ETF',
    symbol: 'PSFO',
  },
  {
    value: 'PSMO',
    label: 'PSMO,  Pacer Swan SOS Moderate (October) ETF',
    name: 'Pacer Swan SOS Moderate (October) ETF',
    symbol: 'PSMO',
  },
  {
    value: 'QQJG',
    label: 'QQJG,  Invesco ESG NASDAQ Next Gen 100 ETF',
    name: 'Invesco ESG NASDAQ Next Gen 100 ETF',
    symbol: 'QQJG',
  },
  {
    value: 'QQMG',
    label: 'QQMG,  Invesco ESG NASDAQ 100 ETF',
    name: 'Invesco ESG NASDAQ 100 ETF',
    symbol: 'QQMG',
  },
  {
    value: 'QSPT',
    label: 'QSPT,  FT Cboe Vest Nasdaq-100 Buffer ETF – September',
    name: 'FT Cboe Vest Nasdaq-100 Buffer ETF – September',
    symbol: 'QSPT',
  },
  {
    value: 'RAYD',
    label: 'RAYD,  Rayliant Quantitative Developed Market Equity ETF',
    name: 'Rayliant Quantitative Developed Market Equity ETF',
    symbol: 'RAYD',
  },
  {
    value: 'RAYE',
    label: 'RAYE,  Rayliant Quantamental Emerging Market Equity ETF',
    name: 'Rayliant Quantamental Emerging Market Equity ETF',
    symbol: 'RAYE',
  },
  {
    value: 'RHTX',
    label: 'RHTX,  RH Tactical Outlook ETF',
    name: 'RH Tactical Outlook ETF',
    symbol: 'RHTX',
  },
  {
    value: 'RITA',
    label: 'RITA,  ETFB Green SRI REITs ETF',
    name: 'ETFB Green SRI REITs ETF',
    symbol: 'RITA',
  },
  {
    value: 'RRH',
    label: 'RRH,  Advocate Rising Rate Hedge ETF',
    name: 'Advocate Rising Rate Hedge ETF',
    symbol: 'RRH',
  },
  {
    value: 'RSPE',
    label: 'RSPE,  Invesco ESG S&P 500 Equal Weight ETF',
    name: 'Invesco ESG S&P 500 Equal Weight ETF',
    symbol: 'RSPE',
  },
  {
    value: 'MMSB',
    label: 'MMSB,  IQ MacKay Multi-Sector Income ETF',
    name: 'IQ MacKay Multi-Sector Income ETF',
    symbol: 'MMSB',
  },
  {
    value: 'RITM',
    label: 'RITM,  Rithm Capital Corp.',
    name: 'Rithm Capital Corp.',
    symbol: 'RITM',
  },
  {
    value: 'EPOW',
    label: 'EPOW,  Sunrise New Energy Co., Ltd.',
    name: 'Sunrise New Energy Co., Ltd.',
    symbol: 'EPOW',
  },
  {
    value: 'EXI2.DE',
    label: 'EXI2.DE,  iShares Dow Jones Global Titans 50 UCITS ETF (DE)',
    name: 'iShares Dow Jones Global Titans 50 UCITS ETF (DE)',
    symbol: 'EXI2.DE',
  },
  {
    value: 'EXXV.DE',
    label:
      'EXXV.DE,  iShares Dow Jones Eurozone Sustainability Screened UCITS ETF (DE)',
    name: 'iShares Dow Jones Eurozone Sustainability Screened UCITS ETF (DE)',
    symbol: 'EXXV.DE',
  },
  {
    value: 'DJE.MI',
    label: 'DJE.MI,  Lyxor Dow Jones Industrial Average UCITS ETF',
    name: 'Lyxor Dow Jones Industrial Average UCITS ETF',
    symbol: 'DJE.MI',
  },
  {
    value: 'CM9.PA',
    label: 'CM9.PA,  Amundi ETF MSCI World ex EMU UCITS ETF',
    name: 'Amundi ETF MSCI World ex EMU UCITS ETF',
    symbol: 'CM9.PA',
  },
  {
    value: 'EWSA.AS',
    label: 'EWSA.AS,  iShares MSCI World Small Cap ESG Enhanced UCITS ETF',
    name: 'iShares MSCI World Small Cap ESG Enhanced UCITS ETF',
    symbol: 'EWSA.AS',
  },
  {
    value: 'CSINDU.SW',
    label:
      'CSINDU.SW,  iShares VII PLC - iShares Dow Jones Industrial Average UCITS ETF',
    name: 'iShares VII PLC - iShares Dow Jones Industrial Average UCITS ETF',
    symbol: 'CSINDU.SW',
  },
  {
    value: 'IGSG.L',
    label:
      'IGSG.L,  iShares Dow Jones Global Sustainability Screened UCITS ETF',
    name: 'iShares Dow Jones Global Sustainability Screened UCITS ETF',
    symbol: 'IGSG.L',
  },
  {
    value: 'XDWY.DE',
    label: 'XDWY.DE,  Xtrackers MSCI World ESG Screened UCITS ETF',
    name: 'Xtrackers MSCI World ESG Screened UCITS ETF',
    symbol: 'XDWY.DE',
  },
  {
    value: 'EEWG.L',
    label: 'EEWG.L,  iShares MSCI World ESG Enhanced UCITS ETF',
    name: 'iShares MSCI World ESG Enhanced UCITS ETF',
    symbol: 'EEWG.L',
  },
  {
    value: 'XMAW.DE',
    label: 'XMAW.DE,  Xtrackers MSCI AC World ESG Screened UCITS ETF',
    name: 'Xtrackers MSCI AC World ESG Screened UCITS ETF',
    symbol: 'XMAW.DE',
  },
  {
    value: 'XDEB.DE',
    label: 'XDEB.DE,  Xtrackers MSCI World Minimum Volatility UCITS ETF',
    name: 'Xtrackers MSCI World Minimum Volatility UCITS ETF',
    symbol: 'XDEB.DE',
  },
  {
    value: 'XDEM.DE',
    label: 'XDEM.DE,  Xtrackers MSCI World Momentum UCITS ETF',
    name: 'Xtrackers MSCI World Momentum UCITS ETF',
    symbol: 'XDEM.DE',
  },
  {
    value: 'C010.DE',
    label: 'C010.DE,  Lyxor Dow Jones Industrial Average (LUX) UCITS ETF',
    name: 'Lyxor Dow Jones Industrial Average (LUX) UCITS ETF',
    symbol: 'C010.DE',
  },
  {
    value: 'EXI3.F',
    label: 'EXI3.F,  iShares Dow Jones Industrial Average UCITS ETF (DE)',
    name: 'iShares Dow Jones Industrial Average UCITS ETF (DE)',
    symbol: 'EXI3.F',
  },
  {
    value: 'WLDC.MI',
    label: 'WLDC.MI,  Lyxor MSCI World UCITS ETF Acc',
    name: 'Lyxor MSCI World UCITS ETF Acc',
    symbol: 'WLDC.MI',
  },
  {
    value: 'IWRD.L',
    label: 'IWRD.L,  iShares MSCI World UCITS ETF',
    name: 'iShares MSCI World UCITS ETF',
    symbol: 'IWRD.L',
  },
  {
    value: 'IWDA.L',
    label: 'IWDA.L,  iShares Core MSCI World UCITS ETF USD (Acc)',
    name: 'iShares Core MSCI World UCITS ETF USD (Acc)',
    symbol: 'IWDA.L',
  },
  {
    value: 'BTEE.L',
    label: 'BTEE.L,  iShares Nasdaq US Biotechnology UCITS ETF',
    name: 'iShares Nasdaq US Biotechnology UCITS ETF',
    symbol: 'BTEE.L',
  },
  {
    value: 'XDEQ.DE',
    label: 'XDEQ.DE,  Xtrackers MSCI World Quality UCITS ETF',
    name: 'Xtrackers MSCI World Quality UCITS ETF',
    symbol: 'XDEQ.DE',
  },
  {
    value: 'XNAS.F',
    label: 'XNAS.F,  Xtrackers NASDAQ 100 UCITS ETF',
    name: 'Xtrackers NASDAQ 100 UCITS ETF',
    symbol: 'XNAS.F',
  },
  {
    value: 'MVWE.SW',
    label: 'MVWE.SW,  iShares Edge MSCI World Minimum Volatility ESG UCITS ETF',
    name: 'iShares Edge MSCI World Minimum Volatility ESG UCITS ETF',
    symbol: 'MVWE.SW',
  },
  {
    value: 'EXXW.DE',
    label:
      'EXXW.DE,  iShares Dow Jones Asia Pacific Select Dividend 50 UCITS ETF (DE)',
    name: 'iShares Dow Jones Asia Pacific Select Dividend 50 UCITS ETF (DE)',
    symbol: 'EXXW.DE',
  },
  {
    value: 'WLDH.PA',
    label: 'WLDH.PA,  Lyxor MSCI World UCITS ETF',
    name: 'Lyxor MSCI World UCITS ETF',
    symbol: 'WLDH.PA',
  },
  {
    value: 'EDMW.DE',
    label: 'EDMW.DE,  iShares MSCI World ESG Enhanced UCITS ETF',
    name: 'iShares MSCI World ESG Enhanced UCITS ETF',
    symbol: 'EDMW.DE',
  },
  {
    value: 'HEAL.L',
    label: 'HEAL.L,  iShares Healthcare Innovation UCITS ETF',
    name: 'iShares Healthcare Innovation UCITS ETF',
    symbol: 'HEAL.L',
  },
  {
    value: 'X010.DE',
    label: 'X010.DE,  Lyxor MSCI World (LUX) UCITS ETF',
    name: 'Lyxor MSCI World (LUX) UCITS ETF',
    symbol: 'X010.DE',
  },
  {
    value: 'C030.DE',
    label: 'C030.DE,  Lyxor DJ Switzerland Titans 30 (DR) UCITS ETF',
    name: 'Lyxor DJ Switzerland Titans 30 (DR) UCITS ETF',
    symbol: 'C030.DE',
  },
  {
    value: 'MWRD.MI',
    label:
      'MWRD.MI,  Amundi Index Solutions - Amundi Index MSCI World UCITS ETF DR',
    name: 'Amundi Index Solutions - Amundi Index MSCI World UCITS ETF DR',
    symbol: 'MWRD.MI',
  },
  {
    value: 'WESE.PA',
    label: 'WESE.PA,  Lyxor MSCI World ESG Leaders Extra (DR) UCITS ETF',
    name: 'Lyxor MSCI World ESG Leaders Extra (DR) UCITS ETF',
    symbol: 'WESE.PA',
  },
  {
    value: 'EXXU.DE',
    label: 'EXXU.DE,  iShares Dow Jones China Offshore 50 UCITS ETF (DE)',
    name: 'iShares Dow Jones China Offshore 50 UCITS ETF (DE)',
    symbol: 'EXXU.DE',
  },
  {
    value: 'WLD.PA',
    label: 'WLD.PA,  Lyxor MSCI World UCITS ETF Dist',
    name: 'Lyxor MSCI World UCITS ETF Dist',
    symbol: 'WLD.PA',
  },
  {
    value: 'IWDE.SW',
    label:
      'IWDE.SW,  iShares V PLC - iShares MSCI World EUR Hedged UCITS ETF (Acc)',
    name: 'iShares V PLC - iShares MSCI World EUR Hedged UCITS ETF (Acc)',
    symbol: 'IWDE.SW',
  },
  {
    value: 'NQSE.F',
    label: 'NQSE.F,  iShares VII PLC - iShares NASDAQ 100 UCITS ETF',
    name: 'iShares VII PLC - iShares NASDAQ 100 UCITS ETF',
    symbol: 'NQSE.F',
  },
  {
    value: 'IS07.DE',
    label: 'IS07.DE,  iShares Edge MSCI World Multifactor UCITS ETF',
    name: 'iShares Edge MSCI World Multifactor UCITS ETF',
    symbol: 'IS07.DE',
  },
  {
    value: 'LYPE.DE',
    label: 'LYPE.DE,  Lyxor MSCI World Health Care TR UCITS ETF - C-EUR',
    name: 'Lyxor MSCI World Health Care TR UCITS ETF - C-EUR',
    symbol: 'LYPE.DE',
  },
  {
    value: 'CW8.PA',
    label: 'CW8.PA,  Amundi Index Solutions - Amundi MSCI World UCITS ETF',
    name: 'Amundi Index Solutions - Amundi MSCI World UCITS ETF',
    symbol: 'CW8.PA',
  },
  {
    value: 'LCWL.L',
    label: 'LCWL.L,  Lyxor Core MSCI World (DR) UCITS ETF',
    name: 'Lyxor Core MSCI World (DR) UCITS ETF',
    symbol: 'LCWL.L',
  },
  {
    value: 'EXXT.DE',
    label: 'EXXT.DE,  iShares NASDAQ-100 UCITS ETF (DE)',
    name: 'iShares NASDAQ-100 UCITS ETF (DE)',
    symbol: 'EXXT.DE',
  },
  {
    value: 'WLDX.MI',
    label: 'WLDX.MI,  Lyxor MSCI World Ex EMU Leaders Extra UCITS ETF',
    name: 'Lyxor MSCI World Ex EMU Leaders Extra UCITS ETF',
    symbol: 'WLDX.MI',
  },
  {
    value: '10AH.DE',
    label: '10AH.DE,  Amundi Index Solutions - Amundi Index MSCI World',
    name: 'Amundi Index Solutions - Amundi Index MSCI World',
    symbol: '10AH.DE',
  },
  {
    value: 'ANX.PA',
    label: 'ANX.PA,  Amundi Index Solutions - Amundi Nasdaq-100',
    name: 'Amundi Index Solutions - Amundi Nasdaq-100',
    symbol: 'ANX.PA',
  },
  {
    value: 'XZW0.DE',
    label: 'XZW0.DE,  Xtrackers MSCI World ESG UCITS ETF',
    name: 'Xtrackers MSCI World ESG UCITS ETF',
    symbol: 'XZW0.DE',
  },
  {
    value: 'TNOW.PA',
    label: 'TNOW.PA,  Lyxor MSCI World Information Technology TR UCITS ETF',
    name: 'Lyxor MSCI World Information Technology TR UCITS ETF',
    symbol: 'TNOW.PA',
  },
  {
    value: 'HNDX.F',
    label: 'HNDX.F,  Amundi Index Solutions - Amundi Nasdaq-100',
    name: 'Amundi Index Solutions - Amundi Nasdaq-100',
    symbol: 'HNDX.F',
  },
  {
    value: 'LYTNOW.SW',
    label: 'LYTNOW.SW,  Lyxor MSCI World Information Technology TR UCITS ETF',
    name: 'Lyxor MSCI World Information Technology TR UCITS ETF',
    symbol: 'LYTNOW.SW',
  },
  {
    value: 'XZWE.MI',
    label: 'XZWE.MI,  Xtrackers MSCI World ESG UCITS ETF',
    name: 'Xtrackers MSCI World ESG UCITS ETF',
    symbol: 'XZWE.MI',
  },
  {
    value: 'SDWD.L',
    label: 'SDWD.L,  iShares MSCI World ESG Screened UCITS ETF',
    name: 'iShares MSCI World ESG Screened UCITS ETF',
    symbol: 'SDWD.L',
  },
  {
    value: 'PANX.PA',
    label: 'PANX.PA,  Amundi ETF PEA Nasdaq-100 UCITS ETF',
    name: 'Amundi ETF PEA Nasdaq-100 UCITS ETF',
    symbol: 'PANX.PA',
  },
  {
    value: 'OMXS.L',
    label: 'OMXS.L,  iShares OMX Stockholm Capped UCITS ETF',
    name: 'iShares OMX Stockholm Capped UCITS ETF',
    symbol: 'OMXS.L',
  },
  {
    value: 'DPYG.L',
    label: 'DPYG.L,  iShares Developed Markets Property Yield UCITS ETF',
    name: 'iShares Developed Markets Property Yield UCITS ETF',
    symbol: 'DPYG.L',
  },
  {
    value: 'SDJP.L',
    label: 'SDJP.L,  iShares MSCI Japan ESG Screened UCITS ETF',
    name: 'iShares MSCI Japan ESG Screened UCITS ETF',
    symbol: 'SDJP.L',
  },
  {
    value: 'SEDM.L',
    label: 'SEDM.L,  iShares MSCI EM IMI ESG Screened UCITS ETF',
    name: 'iShares MSCI EM IMI ESG Screened UCITS ETF',
    symbol: 'SEDM.L',
  },
  {
    value: 'AYEM.DE',
    label: 'AYEM.DE,  iShares MSCI EM IMI ESG Screened UCITS ETF USD (Acc)',
    name: 'iShares MSCI EM IMI ESG Screened UCITS ETF USD (Acc)',
    symbol: 'AYEM.DE',
  },
  {
    value: 'CEUG.L',
    label: 'CEUG.L,  iShares VII PLC -iShares Core MSCI EMU UCITS ETF',
    name: 'iShares VII PLC -iShares Core MSCI EMU UCITS ETF',
    symbol: 'CEUG.L',
  },
  {
    value: 'EEDM.L',
    label: 'EEDM.L,  iShares MSCI EM ESG Enhanced UCITS ETF',
    name: 'iShares MSCI EM ESG Enhanced UCITS ETF',
    symbol: 'EEDM.L',
  },
  {
    value: 'EMUC.SW',
    label:
      'EMUC.SW,  iShares VII PLC - iShares MSCI EMU CHF Hedged UCITS ETF (Acc)',
    name: 'iShares VII PLC - iShares MSCI EMU CHF Hedged UCITS ETF (Acc)',
    symbol: 'EMUC.SW',
  },
  {
    value: 'MVEM.AS',
    label: 'MVEM.AS,  iShares Edge MSCI EM Minimum Volatility ESG UCITS ETF',
    name: 'iShares Edge MSCI EM Minimum Volatility ESG UCITS ETF',
    symbol: 'MVEM.AS',
  },
  {
    value: 'WOOE.AS',
    label: 'WOOE.AS,  iShares Global Timber & Forestry UCITS ETF',
    name: 'iShares Global Timber & Forestry UCITS ETF',
    symbol: 'WOOE.AS',
  },
  {
    value: 'IPRA.AS',
    label: 'IPRA.AS,  iShares Listed Private Equity UCITS ETF',
    name: 'iShares Listed Private Equity UCITS ETF',
    symbol: 'IPRA.AS',
  },
  {
    value: 'CNYE.MI',
    label: 'CNYE.MI,  iShares MSCI China A UCITS ETF',
    name: 'iShares MSCI China A UCITS ETF',
    symbol: 'CNYE.MI',
  },
  {
    value: 'SAUA.MI',
    label: 'SAUA.MI,  iShares MSCI USA ESG Screened UCITS ETF',
    name: 'iShares MSCI USA ESG Screened UCITS ETF',
    symbol: 'SAUA.MI',
  },
  {
    value: 'EXSG.DE',
    label: 'EXSG.DE,  iShares EURO STOXX Select Dividend 30 UCITS ETF (DE)',
    name: 'iShares EURO STOXX Select Dividend 30 UCITS ETF (DE)',
    symbol: 'EXSG.DE',
  },
  {
    value: 'EXSH.DE',
    label: 'EXSH.DE,  iShares STOXX Europe Select Dividend 30 UCITS ETF (DE)',
    name: 'iShares STOXX Europe Select Dividend 30 UCITS ETF (DE)',
    symbol: 'EXSH.DE',
  },
  {
    value: 'EXSC.F',
    label: 'EXSC.F,  iShares STOXX Europe Large 200 UCITS ETF (DE)',
    name: 'iShares STOXX Europe Large 200 UCITS ETF (DE)',
    symbol: 'EXSC.F',
  },
  {
    value: 'EXV6.DE',
    label: 'EXV6.DE,  iShares STOXX Europe 600 Basic Resources UCITS ETF (DE)',
    name: 'iShares STOXX Europe 600 Basic Resources UCITS ETF (DE)',
    symbol: 'EXV6.DE',
  },
  {
    value: 'EXV8.DE',
    label:
      'EXV8.DE,  iShares STOXX Europe 600 Construction & Materials UCITS ETF (DE)',
    name: 'iShares STOXX Europe 600 Construction & Materials UCITS ETF (DE)',
    symbol: 'EXV8.DE',
  },
  {
    value: 'EXH1.DE',
    label: 'EXH1.DE,  iShares STOXX Europe 600 Oil & Gas UCITS ETF (DE)',
    name: 'iShares STOXX Europe 600 Oil & Gas UCITS ETF (DE)',
    symbol: 'EXH1.DE',
  },
  {
    value: 'EXH7.DE',
    label:
      'EXH7.DE,  iShares STOXX Europe 600 Personal & Household Goods UCITS ETF (DE)',
    name: 'iShares STOXX Europe 600 Personal & Household Goods UCITS ETF (DE)',
    symbol: 'EXH7.DE',
  },
  {
    value: 'EXV3.DE',
    label: 'EXV3.DE,  iShares STOXX Europe 600 Technology UCITS ETF (DE)',
    name: 'iShares STOXX Europe 600 Technology UCITS ETF (DE)',
    symbol: 'EXV3.DE',
  },
  {
    value: 'EXV9.DE',
    label: 'EXV9.DE,  iShares STOXX Europe 600 Travel & Leisure UCITS ETF (DE)',
    name: 'iShares STOXX Europe 600 Travel & Leisure UCITS ETF (DE)',
    symbol: 'EXV9.DE',
  },
  {
    value: 'EXV5.DE',
    label:
      'EXV5.DE,  iShares STOXX Europe 600 Automobiles & Parts UCITS ETF (DE)',
    name: 'iShares STOXX Europe 600 Automobiles & Parts UCITS ETF (DE)',
    symbol: 'EXV5.DE',
  },
  {
    value: 'E960.DE',
    label: 'E960.DE,  Lyxor 1 STOXX Europe 600 ESG (DR) UCITS ETF',
    name: 'Lyxor 1 STOXX Europe 600 ESG (DR) UCITS ETF',
    symbol: 'E960.DE',
  },
  {
    value: 'MKTN.PA',
    label:
      'MKTN.PA,  Amundi ETF iStoxx Europe Multi-Factor Market Neutral UCITS ETF',
    name: 'Amundi ETF iStoxx Europe Multi-Factor Market Neutral UCITS ETF',
    symbol: 'MKTN.PA',
  },
  {
    value: 'DJSC.L',
    label: 'DJSC.L,  iShares EURO STOXX Small UCITS ETF',
    name: 'iShares EURO STOXX Small UCITS ETF',
    symbol: 'DJSC.L',
  },
  {
    value: 'XSX6.L',
    label: 'XSX6.L,  Xtrackers Stoxx Europe 600 UCITS ETF 1C',
    name: 'Xtrackers Stoxx Europe 600 UCITS ETF 1C',
    symbol: 'XSX6.L',
  },
  {
    value: 'MEUD.PA',
    label: 'MEUD.PA,  Lyxor Core STOXX Europe 600 (DR) - UCITS ETF Acc',
    name: 'Lyxor Core STOXX Europe 600 (DR) - UCITS ETF Acc',
    symbol: 'MEUD.PA',
  },
  {
    value: 'MFED.PA',
    label: 'MFED.PA,  Lyxor MSCI EMU ESG (DR) - UCITS ETF',
    name: 'Lyxor MSCI EMU ESG (DR) - UCITS ETF',
    symbol: 'MFED.PA',
  },
  {
    value: 'BNKE.PA',
    label: 'BNKE.PA,  Lyxor Euro Stoxx Banks (Dr) Ucits ETF',
    name: 'Lyxor Euro Stoxx Banks (Dr) Ucits ETF',
    symbol: 'BNKE.PA',
  },
  {
    value: 'AUT.PA',
    label:
      'AUT.PA,  Lyxor Index Fund - Lyxor STOXX Europe 600 Automobiles & Parts UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor STOXX Europe 600 Automobiles & Parts UCITS ETF',
    symbol: 'AUT.PA',
  },
  {
    value: 'CST.MI',
    label:
      'CST.MI,  Lyxor Index Fund - Lyxor STOXX Europe 600 Construction & Materials UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor STOXX Europe 600 Construction & Materials UCITS ETF',
    symbol: 'CST.MI',
  },
  {
    value: 'IND.PA',
    label:
      'IND.PA,  Lyxor Index Fund - Lyxor STOXX Europe 600 Industrial Goods & Services UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor STOXX Europe 600 Industrial Goods & Services UCITS ETF',
    symbol: 'IND.PA',
  },
  {
    value: 'PHG.PA',
    label:
      'PHG.PA,  Lyxor Index Fund - Lyxor STOXX Europe 600 Personal & Household Goods UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor STOXX Europe 600 Personal & Household Goods UCITS ETF',
    symbol: 'PHG.PA',
  },
  {
    value: 'TRV.PA',
    label:
      'TRV.PA,  Lyxor Index Fund - Lyxor STOXX Europe 600 Travel & Leisure UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor STOXX Europe 600 Travel & Leisure UCITS ETF',
    symbol: 'TRV.PA',
  },
  {
    value: 'GOAI.PA',
    label: 'GOAI.PA,  Amundi Stoxx Global Artificial Intelligence',
    name: 'Amundi Stoxx Global Artificial Intelligence',
    symbol: 'GOAI.PA',
  },
  {
    value: 'EXSA.DE',
    label: 'EXSA.DE,  iShares STOXX Europe 600 UCITS ETF (DE)',
    name: 'iShares STOXX Europe 600 UCITS ETF (DE)',
    symbol: 'EXSA.DE',
  },
  {
    value: 'EXW3.DE',
    label: 'EXW3.DE,  iShares STOXX Europe 50 UCITS ETF (DE)',
    name: 'iShares STOXX Europe 50 UCITS ETF (DE)',
    symbol: 'EXW3.DE',
  },
  {
    value: 'EXSD.DE',
    label: 'EXSD.DE,  iShares STOXX Europe Mid 200 UCITS ETF (DE)',
    name: 'iShares STOXX Europe Mid 200 UCITS ETF (DE)',
    symbol: 'EXSD.DE',
  },
  {
    value: 'ISPA.DE',
    label: 'ISPA.DE,  iShares STOXX Global Select Dividend 100 UCITS ETF (DE)',
    name: 'iShares STOXX Global Select Dividend 100 UCITS ETF (DE)',
    symbol: 'ISPA.DE',
  },
  {
    value: 'EXV7.DE',
    label: 'EXV7.DE,  iShares STOXX Europe 600 Chemicals UCITS ETF (DE)',
    name: 'iShares STOXX Europe 600 Chemicals UCITS ETF (DE)',
    symbol: 'EXV7.DE',
  },
  {
    value: 'EXH2.DE',
    label:
      'EXH2.DE,  iShares STOXX Europe 600 Financial Services UCITS ETF (DE)',
    name: 'iShares STOXX Europe 600 Financial Services UCITS ETF (DE)',
    symbol: 'EXH2.DE',
  },
  {
    value: 'EXH8.DE',
    label: 'EXH8.DE,  iShares STOXX Europe 600 Retail UCITS ETF (DE)',
    name: 'iShares STOXX Europe 600 Retail UCITS ETF (DE)',
    symbol: 'EXH8.DE',
  },
  {
    value: 'EXV2.DE',
    label:
      'EXV2.DE,  iShares STOXX Europe 600 Telecommunications UCITS ETF (DE)',
    name: 'iShares STOXX Europe 600 Telecommunications UCITS ETF (DE)',
    symbol: 'EXV2.DE',
  },
  {
    value: 'C5S.PA',
    label: 'C5S.PA,  Amundi ETF Short Euro Stoxx 50 Daily UCITS ETF',
    name: 'Amundi ETF Short Euro Stoxx 50 Daily UCITS ETF',
    symbol: 'C5S.PA',
  },
  {
    value: 'MSEC.SW',
    label: 'MSEC.SW,  Lyxor Euro Stoxx 50 (DR) UCITS ETF',
    name: 'Lyxor Euro Stoxx 50 (DR) UCITS ETF',
    symbol: 'MSEC.SW',
  },
  {
    value: 'EUN1.DE',
    label: 'EUN1.DE,  iShares STOXX Europe 50 UCITS ETF',
    name: 'iShares STOXX Europe 50 UCITS ETF',
    symbol: 'EUN1.DE',
  },
  {
    value: 'DJMC.L',
    label: 'DJMC.L,  iShares EURO STOXX Mid UCITS ETF',
    name: 'iShares EURO STOXX Mid UCITS ETF',
    symbol: 'DJMC.L',
  },
  {
    value: 'CSSX5E.SW',
    label: 'CSSX5E.SW,  iShares VII PLC - iShares Core EURO STOXX 50 UCITS ETF',
    name: 'iShares VII PLC - iShares Core EURO STOXX 50 UCITS ETF',
    symbol: 'CSSX5E.SW',
  },
  {
    value: 'DXET.DE',
    label: 'DXET.DE,  Xtrackers Euro Stoxx 50 UCITS ETF',
    name: 'Xtrackers Euro Stoxx 50 UCITS ETF',
    symbol: 'DXET.DE',
  },
  {
    value: 'MSED.PA',
    label: 'MSED.PA,  Lyxor EURO STOXX 50 (DR)',
    name: 'Lyxor EURO STOXX 50 (DR)',
    symbol: 'MSED.PA',
  },
  {
    value: 'C6E.PA',
    label: 'C6E.PA,  Amundi Index Solutions - Amundi STOXX Europe 600',
    name: 'Amundi Index Solutions - Amundi STOXX Europe 600',
    symbol: 'C6E.PA',
  },
  {
    value: 'C50.PA',
    label:
      'C50.PA,  Amundi Index Solutions - Amundi EURO STOXX 50 UCITS ETF-C EUR',
    name: 'Amundi Index Solutions - Amundi EURO STOXX 50 UCITS ETF-C EUR',
    symbol: 'C50.PA',
  },
  {
    value: 'MEH.PA',
    label:
      'MEH.PA,  Lyxor Index Fund - Lyxor STOXX Europe 600 Real Estate UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor STOXX Europe 600 Real Estate UCITS ETF',
    symbol: 'MEH.PA',
  },
  {
    value: 'SEL.PA',
    label:
      'SEL.PA,  Lyxor Index Fund - Lyxor STOXX Europe Select Dividend 30 UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor STOXX Europe Select Dividend 30 UCITS ETF',
    symbol: 'SEL.PA',
  },
  {
    value: 'LFIN.DE',
    label:
      'LFIN.DE,  Lyxor Index Fund - Lyxor STOXX Europe 600 Financial Services UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor STOXX Europe 600 Financial Services UCITS ETF',
    symbol: 'LFIN.DE',
  },
  {
    value: 'INS.PA',
    label:
      'INS.PA,  Lyxor Index Fund - Lyxor STOXX Europe 600 Insurance UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor STOXX Europe 600 Insurance UCITS ETF',
    symbol: 'INS.PA',
  },
  {
    value: 'RTA.PA',
    label:
      'RTA.PA,  Lyxor Index Fund - Lyxor STOXX Europe 600 Retail UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor STOXX Europe 600 Retail UCITS ETF',
    symbol: 'RTA.PA',
  },
  {
    value: 'EXW1.DE',
    label: 'EXW1.DE,  iShares Core EURO STOXX 50 UCITS ETF (DE)',
    name: 'iShares Core EURO STOXX 50 UCITS ETF (DE)',
    symbol: 'EXW1.DE',
  },
  {
    value: 'EXX1.DE',
    label: 'EXX1.DE,  iShares EURO STOXX Banks 30-15 UCITS ETF (DE)',
    name: 'iShares EURO STOXX Banks 30-15 UCITS ETF (DE)',
    symbol: 'EXX1.DE',
  },
  {
    value: 'EXSE.F',
    label: 'EXSE.F,  iShares STOXX Europe Small 200 UCITS ETF (DE)',
    name: 'iShares STOXX Europe Small 200 UCITS ETF (DE)',
    symbol: 'EXSE.F',
  },
  {
    value: 'EXV1.DE',
    label: 'EXV1.DE,  iShares STOXX Europe 600 Banks UCITS ETF (DE)',
    name: 'iShares STOXX Europe 600 Banks UCITS ETF (DE)',
    symbol: 'EXV1.DE',
  },
  {
    value: 'EXH3.DE',
    label: 'EXH3.DE,  iShares STOXX Europe 600 Food & Beverage UCITS ETF (DE)',
    name: 'iShares STOXX Europe 600 Food & Beverage UCITS ETF (DE)',
    symbol: 'EXH3.DE',
  },
  {
    value: 'EXI5.F',
    label: 'EXI5.F,  iShares STOXX Europe 600 Real Estate UCITS ETF (DE)',
    name: 'iShares STOXX Europe 600 Real Estate UCITS ETF (DE)',
    symbol: 'EXI5.F',
  },
  {
    value: 'MSE.PA',
    label: 'MSE.PA,  Lyxor Euro Stoxx 50 (DR) UCITS ETF Acc',
    name: 'Lyxor Euro Stoxx 50 (DR) UCITS ETF Acc',
    symbol: 'MSE.PA',
  },
  {
    value: 'C5E.PA',
    label: 'C5E.PA,  Amundi ETF Stoxx Europe 50 UCITS ETF',
    name: 'Amundi ETF Stoxx Europe 50 UCITS ETF',
    symbol: 'C5E.PA',
  },
  {
    value: 'MSEX.L',
    label: 'MSEX.L,  Lyxor Euro Stoxx 50 (DR) UCITS ETF',
    name: 'Lyxor Euro Stoxx 50 (DR) UCITS ETF',
    symbol: 'MSEX.L',
  },
  {
    value: 'EUEA.AS',
    label: 'EUEA.AS,  iShares EURO STOXX 50 UCITS ETF',
    name: 'iShares EURO STOXX 50 UCITS ETF',
    symbol: 'EUEA.AS',
  },
  {
    value: 'C051.DE',
    label: 'C051.DE,  Lyxor EURO STOXX Select Dividend 30 UCITS ETF',
    name: 'Lyxor EURO STOXX Select Dividend 30 UCITS ETF',
    symbol: 'C051.DE',
  },
  {
    value: 'CD5.PA',
    label:
      'CD5.PA,  Amundi Index Solutions - Amundi EURO STOXX 50 UCITS ETF-D EUR',
    name: 'Amundi Index Solutions - Amundi EURO STOXX 50 UCITS ETF-D EUR',
    symbol: 'CD5.PA',
  },
  {
    value: 'XSXE.F',
    label: 'XSXE.F,  Xtrackers Stoxx Europe 600 UCITS ETF',
    name: 'Xtrackers Stoxx Europe 600 UCITS ETF',
    symbol: 'XSXE.F',
  },
  {
    value: 'LBRE.DE',
    label:
      'LBRE.DE,  Lyxor Index Fund - Lyxor Stoxx Europe 600 Basic Resources UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor Stoxx Europe 600 Basic Resources UCITS ETF',
    symbol: 'LBRE.DE',
  },
  {
    value: 'MDA.PA',
    label: 'MDA.PA,  Lyxor Index Fund - Lyxor STOXX Europe 600 Media UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor STOXX Europe 600 Media UCITS ETF',
    symbol: 'MDA.PA',
  },
  {
    value: 'PABZ.PA',
    label:
      'PABZ.PA,  Amundi Index Solutions - Amundi Euro iStoxx Climate Paris Aligned Pab',
    name: 'Amundi Index Solutions - Amundi Euro iStoxx Climate Paris Aligned Pab',
    symbol: 'PABZ.PA',
  },
  {
    value: 'EXH4.DE',
    label:
      'EXH4.DE,  iShares STOXX Europe 600 Industrial Goods & Services UCITS ETF (DE)',
    name: 'iShares STOXX Europe 600 Industrial Goods & Services UCITS ETF (DE)',
    symbol: 'EXH4.DE',
  },
  {
    value: 'XD3E.L',
    label: 'XD3E.L,  Xtrackers Euro Stoxx Quality Dividend UCITS ETF',
    name: 'Xtrackers Euro Stoxx Quality Dividend UCITS ETF',
    symbol: 'XD3E.L',
  },
  {
    value: 'C060.DE',
    label: 'C060.DE,  Lyxor STOXX Europe 600 UCITS ETF',
    name: 'Lyxor STOXX Europe 600 UCITS ETF',
    symbol: 'C060.DE',
  },
  {
    value: 'STXH.DE',
    label: 'STXH.DE,  Lyxor Index Fund - Lyxor Core STOXX Europe 600 (DR)',
    name: 'Lyxor Index Fund - Lyxor Core STOXX Europe 600 (DR)',
    symbol: 'STXH.DE',
  },
  {
    value: 'C50U.PA',
    label: 'C50U.PA,  Amundi Index Solutions - Amundi EURO STOXX 50',
    name: 'Amundi Index Solutions - Amundi EURO STOXX 50',
    symbol: 'C50U.PA',
  },
  {
    value: 'CHM.PA',
    label:
      'CHM.PA,  Lyxor Index Fund - Lyxor STOXX Europe 600 Chemicals UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor STOXX Europe 600 Chemicals UCITS ETF',
    symbol: 'CHM.PA',
  },
  {
    value: 'OIL.PA',
    label:
      'OIL.PA,  Lyxor Index Fund - Lyxor Stoxx Europe 600 Oil & Gas UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor Stoxx Europe 600 Oil & Gas UCITS ETF',
    symbol: 'OIL.PA',
  },
  {
    value: 'XGSD.L',
    label: 'XGSD.L,  Xtrackers Stoxx Global Select Dividend 100 Swap UCITS ETF',
    name: 'Xtrackers Stoxx Global Select Dividend 100 Swap UCITS ETF',
    symbol: 'XGSD.L',
  },
  {
    value: 'EXH6.DE',
    label: 'EXH6.DE,  iShares STOXX Europe 600 Media UCITS ETF (DE)',
    name: 'iShares STOXX Europe 600 Media UCITS ETF (DE)',
    symbol: 'EXH6.DE',
  },
  {
    value: 'EXS2.DE',
    label: 'EXS2.DE,  iShares TecDAX UCITS ETF (DE)',
    name: 'iShares TecDAX UCITS ETF (DE)',
    symbol: 'EXS2.DE',
  },
  {
    value: 'E907.F',
    label: 'E907.F,  Lyxor 1 MDAX (DR) UCITS ETF',
    name: 'Lyxor 1 MDAX (DR) UCITS ETF',
    symbol: 'E907.F',
  },
  {
    value: 'E908.DE',
    label: 'E908.DE,  Lyxor 1 TecDAX (DR) UCITS ETF',
    name: 'Lyxor 1 TecDAX (DR) UCITS ETF',
    symbol: 'E908.DE',
  },
  {
    value: 'DBXD.DE',
    label: 'DBXD.DE,  Xtrackers DAX UCITS ETF',
    name: 'Xtrackers DAX UCITS ETF',
    symbol: 'DBXD.DE',
  },
  {
    value: 'C005.DE',
    label: 'C005.DE,  Lyxor SDAX (DR) UCITS ETF',
    name: 'Lyxor SDAX (DR) UCITS ETF',
    symbol: 'C005.DE',
  },
  {
    value: 'XDDX.DE',
    label: 'XDDX.DE,  Xtrackers DAX Income UCITS ETF',
    name: 'Xtrackers DAX Income UCITS ETF',
    symbol: 'XDDX.DE',
  },
  {
    value: 'C007.F',
    label: 'C007.F,  Lyxor MDAX ESG UCITS ETF',
    name: 'Lyxor MDAX ESG UCITS ETF',
    symbol: 'C007.F',
  },
  {
    value: 'EXSB.DE',
    label: 'EXSB.DE,  iShares DivDAX UCITS ETF (DE)',
    name: 'iShares DivDAX UCITS ETF (DE)',
    symbol: 'EXSB.DE',
  },
  {
    value: 'E909.F',
    label: 'E909.F,  Lyxor 1 DAX 50 ESG (DR) UCITS ETF',
    name: 'Lyxor 1 DAX 50 ESG (DR) UCITS ETF',
    symbol: 'E909.F',
  },
  {
    value: 'C001.DE',
    label: 'C001.DE,  Lyxor Core DAX (DR) UCITS ETF',
    name: 'Lyxor Core DAX (DR) UCITS ETF',
    symbol: 'C001.DE',
  },
  {
    value: 'C003.DE',
    label: 'C003.DE,  Lyxor DivDAX (DR) UCITS ETF',
    name: 'Lyxor DivDAX (DR) UCITS ETF',
    symbol: 'C003.DE',
  },
  {
    value: 'XDUD.SW',
    label: 'XDUD.SW,  Xtrackers DAX Income UCITS ETF 2C USD Hedged',
    name: 'Xtrackers DAX Income UCITS ETF 2C USD Hedged',
    symbol: 'XDUD.SW',
  },
  {
    value: 'EXS3.DE',
    label: 'EXS3.DE,  iShares MDAX UCITS ETF (DE)',
    name: 'iShares MDAX UCITS ETF (DE)',
    symbol: 'EXS3.DE',
  },
  {
    value: 'EXID.DE',
    label: 'EXID.DE,  iShares MDAX UCITS ETF (DE)',
    name: 'iShares MDAX UCITS ETF (DE)',
    symbol: 'EXID.DE',
  },
  {
    value: 'E903.F',
    label: 'E903.F,  Lyxor 1 DivDAX (DR) UCITS ETF',
    name: 'Lyxor 1 DivDAX (DR) UCITS ETF',
    symbol: 'E903.F',
  },
  {
    value: 'XESX.L',
    label: 'XESX.L,  Xtrackers Euro Stoxx 50 UCITS ETF',
    name: 'Xtrackers Euro Stoxx 50 UCITS ETF',
    symbol: 'XESX.L',
  },
  {
    value: 'EXSI.F',
    label: 'EXSI.F,  iShares EURO STOXX UCITS ETF (DE)',
    name: 'iShares EURO STOXX UCITS ETF (DE)',
    symbol: 'EXSI.F',
  },
  {
    value: 'EXH9.DE',
    label: 'EXH9.DE,  iShares STOXX Europe 600 Utilities UCITS ETF (DE)',
    name: 'iShares STOXX Europe 600 Utilities UCITS ETF (DE)',
    symbol: 'EXH9.DE',
  },
  {
    value: 'CG1.PA',
    label: 'CG1.PA,  Amundi ETF DAX UCITS ETF DR',
    name: 'Amundi ETF DAX UCITS ETF DR',
    symbol: 'CG1.PA',
  },
  {
    value: 'MFDD.PA',
    label: 'MFDD.PA,  Lyxor MSCI EMU ESG (DR) - UCITS ETF',
    name: 'Lyxor MSCI EMU ESG (DR) - UCITS ETF',
    symbol: 'MFDD.PA',
  },
  {
    value: 'E50EUA.MI',
    label: 'E50EUA.MI,  UBS (Lux) Fund Solutions – EURO STOXX 50 UCITS ETF',
    name: 'UBS (Lux) Fund Solutions – EURO STOXX 50 UCITS ETF',
    symbol: 'E50EUA.MI',
  },
  {
    value: 'XSSX.L',
    label: 'XSSX.L,  Xtrackers Euro Stoxx 50 Short Daily Swap UCITS ETF',
    name: 'Xtrackers Euro Stoxx 50 Short Daily Swap UCITS ETF',
    symbol: 'XSSX.L',
  },
  {
    value: 'EEI.L',
    label: 'EEI.L,  WisdomTree Europe Equity Income UCITS ETF',
    name: 'WisdomTree Europe Equity Income UCITS ETF',
    symbol: 'EEI.L',
  },
  {
    value: 'HEDJ.L',
    label: 'HEDJ.L,  WisdomTree Europe Equity UCITS ETF',
    name: 'WisdomTree Europe Equity UCITS ETF',
    symbol: 'HEDJ.L',
  },
  {
    value: 'GGRG.L',
    label: 'GGRG.L,  WisdomTree Global Quality Dividend Growth UCITS ETF',
    name: 'WisdomTree Global Quality Dividend Growth UCITS ETF',
    symbol: 'GGRG.L',
  },
  {
    value: 'CHIP.L',
    label: 'CHIP.L,  ICBC Credit Suisse WisdomTree S&P China 500 UCITS ETF',
    name: 'ICBC Credit Suisse WisdomTree S&P China 500 UCITS ETF',
    symbol: 'CHIP.L',
  },
  {
    value: 'VOLT.L',
    label: 'VOLT.L,  WisdomTree Battery Solutions UCITS ETF',
    name: 'WisdomTree Battery Solutions UCITS ETF',
    symbol: 'VOLT.L',
  },
  {
    value: 'WCBR.MI',
    label: 'WCBR.MI,  WisdomTree Cybersecurity UCITS ETF',
    name: 'WisdomTree Cybersecurity UCITS ETF',
    symbol: 'WCBR.MI',
  },
  {
    value: 'WRNA.F',
    label: 'WRNA.F,  WisdomTree BioRevolution UCITS ETF',
    name: 'WisdomTree BioRevolution UCITS ETF',
    symbol: 'WRNA.F',
  },
  {
    value: 'XDDA.F',
    label: 'XDDA.F,  Xtrackers DAX UCITS ETF',
    name: 'Xtrackers DAX UCITS ETF',
    symbol: 'XDDA.F',
  },
  {
    value: 'WTRD.F',
    label: 'WTRD.F,  WisdomTree Recycling Decarbonisation UCITS ETF',
    name: 'WisdomTree Recycling Decarbonisation UCITS ETF',
    symbol: 'WTRD.F',
  },
  {
    value: 'IDVA.AS',
    label: 'IDVA.AS,  iShares EURO STOXX Select Dividend 30 UCITS ETF (DE)',
    name: 'iShares EURO STOXX Select Dividend 30 UCITS ETF (DE)',
    symbol: 'IDVA.AS',
  },
  {
    value: 'EXA1.AS',
    label: 'EXA1.AS,  iShares EURO STOXX Banks 30-15 UCITS ETF (DE)',
    name: 'iShares EURO STOXX Banks 30-15 UCITS ETF (DE)',
    symbol: 'EXA1.AS',
  },
  {
    value: 'INRG.L',
    label: 'INRG.L,  iShares Global Clean Energy UCITS ETF USD (Dist)',
    name: 'iShares Global Clean Energy UCITS ETF USD (Dist)',
    symbol: 'INRG.L',
  },
  {
    value: 'ISWD.SW',
    label: 'ISWD.SW,  iShares MSCI World Islamic UCITS ETF',
    name: 'iShares MSCI World Islamic UCITS ETF',
    symbol: 'ISWD.SW',
  },
  {
    value: 'IGWD.L',
    label:
      'IGWD.L,  iShares V PLC - iShares MSCI World GBP Hedged UCITS ETF (Acc)',
    name: 'iShares V PLC - iShares MSCI World GBP Hedged UCITS ETF (Acc)',
    symbol: 'IGWD.L',
  },
  {
    value: 'MINV.L',
    label:
      'MINV.L,  iShares Edge MSCI World Minimum Volatility UCITS ETF USD (Acc)',
    name: 'iShares Edge MSCI World Minimum Volatility UCITS ETF USD (Acc)',
    symbol: 'MINV.L',
  },
  {
    value: 'WQDA.AS',
    label: 'WQDA.AS,  iShares MSCI World Quality Dividend ESG UCITS ETF',
    name: 'iShares MSCI World Quality Dividend ESG UCITS ETF',
    symbol: 'WQDA.AS',
  },
  {
    value: 'XDWI.L',
    label: 'XDWI.L,  Xtrackers MSCI World Industrials UCITS ETF',
    name: 'Xtrackers MSCI World Industrials UCITS ETF',
    symbol: 'XDWI.L',
  },
  {
    value: 'SGWS.SW',
    label: 'SGWS.SW,  iShares MSCI World SRI UCITS ETF GBP Hedged (Dist)',
    name: 'iShares MSCI World SRI UCITS ETF GBP Hedged (Dist)',
    symbol: 'SGWS.SW',
  },
  {
    value: 'LYTELW.SW',
    label: 'LYTELW.SW,  Lyxor MSCI World Communication Services TR UCITS ETF',
    name: 'Lyxor MSCI World Communication Services TR UCITS ETF',
    symbol: 'LYTELW.SW',
  },
  {
    value: 'INRA.AS',
    label: 'INRA.AS,  iShares Global Clean Energy UCITS ETF',
    name: 'iShares Global Clean Energy UCITS ETF',
    symbol: 'INRA.AS',
  },
  {
    value: 'CBUF.F',
    label: 'CBUF.F,  iShares MSCI World Health Care Sector UCITS ETF USD Inc',
    name: 'iShares MSCI World Health Care Sector UCITS ETF USD Inc',
    symbol: 'CBUF.F',
  },
  {
    value: '3SUE.F',
    label: '3SUE.F,  iShares MSCI World Consumer Staples Sector UCITS ETF',
    name: 'iShares MSCI World Consumer Staples Sector UCITS ETF',
    symbol: '3SUE.F',
  },
  {
    value: 'XDWH.L',
    label: 'XDWH.L,  Xtrackers MSCI World Health Care UCITS ETF',
    name: 'Xtrackers MSCI World Health Care UCITS ETF',
    symbol: 'XDWH.L',
  },
  {
    value: 'DBXW.DE',
    label: 'DBXW.DE,  Xtrackers MSCI World Swap UCITS ETF',
    name: 'Xtrackers MSCI World Swap UCITS ETF',
    symbol: 'DBXW.DE',
  },
  {
    value: 'XS8R.L',
    label:
      'XS8R.L,  Xtrackers MSCI Europe Information Technology ESG Screened UCITS ETF',
    name: 'Xtrackers MSCI Europe Information Technology ESG Screened UCITS ETF',
    symbol: 'XS8R.L',
  },
  {
    value: 'LYPA.DE',
    label: 'LYPA.DE,  Lyxor MSCI World Consumer Discretionary TR UCITS ETF',
    name: 'Lyxor MSCI World Consumer Discretionary TR UCITS ETF',
    symbol: 'LYPA.DE',
  },
  {
    value: 'LYPD.DE',
    label: 'LYPD.DE,  Lyxor MSCI World Financials TR UCITS ETF',
    name: 'Lyxor MSCI World Financials TR UCITS ETF',
    symbol: 'LYPD.DE',
  },
  {
    value: 'LYPQ.DE',
    label: 'LYPQ.DE,  Lyxor MSCI World Utilities TR UCITS ETF',
    name: 'Lyxor MSCI World Utilities TR UCITS ETF',
    symbol: 'LYPQ.DE',
  },
  {
    value: 'XWEH.F',
    label: 'XWEH.F,  Xtrackers MSCI World Swap UCITS ETF',
    name: 'Xtrackers MSCI World Swap UCITS ETF',
    symbol: 'XWEH.F',
  },
  {
    value: 'WSRI.PA',
    label:
      'WSRI.PA,  Amundi Index Solutions - Amundi MSCI World SRI UCITS ETF DR',
    name: 'Amundi Index Solutions - Amundi MSCI World SRI UCITS ETF DR',
    symbol: 'WSRI.PA',
  },
  {
    value: 'PABW.PA',
    label:
      'PABW.PA,  Amundi Index Solutions - Amundi MSCI World Climate Paris Aligned Pab',
    name: 'Amundi Index Solutions - Amundi MSCI World Climate Paris Aligned Pab',
    symbol: 'PABW.PA',
  },
  {
    value: 'MWSH.F',
    label: 'MWSH.F,  Amundi Index Solutions - Amundi Index MSCI World SRI PAB',
    name: 'Amundi Index Solutions - Amundi Index MSCI World SRI PAB',
    symbol: 'MWSH.F',
  },
  {
    value: 'XDEV.DE',
    label: 'XDEV.DE,  Xtrackers MSCI World Value UCITS ETF',
    name: 'Xtrackers MSCI World Value UCITS ETF',
    symbol: 'XDEV.DE',
  },
  {
    value: 'XDWF.L',
    label: 'XDWF.L,  Xtrackers MSCI World Financials UCITS ETF',
    name: 'Xtrackers MSCI World Financials UCITS ETF',
    symbol: 'XDWF.L',
  },
  {
    value: 'SUWU.SW',
    label: 'SUWU.SW,  iShares MSCI World SRI UCITS ETF',
    name: 'iShares MSCI World SRI UCITS ETF',
    symbol: 'SUWU.SW',
  },
  {
    value: 'LYCODW.SW',
    label: 'LYCODW.SW,  Lyxor MSCI World Consumer Discretionary TR UCITS ETF',
    name: 'Lyxor MSCI World Consumer Discretionary TR UCITS ETF',
    symbol: 'LYCODW.SW',
  },
  {
    value: 'FINW.L',
    label: 'FINW.L,  Lyxor MSCI World Financials TR UCITS ETF',
    name: 'Lyxor MSCI World Financials TR UCITS ETF',
    symbol: 'FINW.L',
  },
  {
    value: 'LYUTLW.SW',
    label: 'LYUTLW.SW,  Lyxor MSCI World Utilities TR UCITS ETF',
    name: 'Lyxor MSCI World Utilities TR UCITS ETF',
    symbol: 'LYUTLW.SW',
  },
  {
    value: 'XDW0.L',
    label: 'XDW0.L,  Xtrackers MSCI World Energy UCITS ETF',
    name: 'Xtrackers MSCI World Energy UCITS ETF',
    symbol: 'XDW0.L',
  },
  {
    value: 'WPAD.AS',
    label: 'WPAD.AS,  iShares MSCI World Paris-Aligned Climate UCITS ETF',
    name: 'iShares MSCI World Paris-Aligned Climate UCITS ETF',
    symbol: 'WPAD.AS',
  },
  {
    value: 'STAW.MI',
    label: 'STAW.MI,  Lyxor MSCI World Consumer Staples TR UCITS ETF - C-EUR',
    name: 'Lyxor MSCI World Consumer Staples TR UCITS ETF - C-EUR',
    symbol: 'STAW.MI',
  },
  {
    value: 'XDWS.L',
    label: 'XDWS.L,  Xtrackers MSCI World Consumer Staples UCITS ETF',
    name: 'Xtrackers MSCI World Consumer Staples UCITS ETF',
    symbol: 'XDWS.L',
  },
  {
    value: 'SXR0.DE',
    label: 'SXR0.DE,  iShares Edge MSCI World Minimum Volatility UCITS ETF',
    name: 'iShares Edge MSCI World Minimum Volatility UCITS ETF',
    symbol: 'SXR0.DE',
  },
  {
    value: 'LYCOSW.SW',
    label: 'LYCOSW.SW,  Lyxor MSCI World Consumer Staples TR UCITS ETF',
    name: 'Lyxor MSCI World Consumer Staples TR UCITS ETF',
    symbol: 'LYCOSW.SW',
  },
  {
    value: 'LYHLTW.SW',
    label: 'LYHLTW.SW,  Lyxor MSCI World Health Care TR UCITS ETF',
    name: 'Lyxor MSCI World Health Care TR UCITS ETF',
    symbol: 'LYHLTW.SW',
  },
  {
    value: 'XDWC.L',
    label: 'XDWC.L,  Xtrackers MSCI World Consumer Discretionary UCITS ETF',
    name: 'Xtrackers MSCI World Consumer Discretionary UCITS ETF',
    symbol: 'XDWC.L',
  },
  {
    value: 'ENERW.MI',
    label: 'ENERW.MI,  Lyxor MSCI World Energy TR UCITS ETF - C-EUR',
    name: 'Lyxor MSCI World Energy TR UCITS ETF - C-EUR',
    symbol: 'ENERW.MI',
  },
  {
    value: 'INDGW.MI',
    label: 'INDGW.MI,  Lyxor MSCI World Industrials TR UCITS ETF',
    name: 'Lyxor MSCI World Industrials TR UCITS ETF',
    symbol: 'INDGW.MI',
  },
  {
    value: 'XDWU.DE',
    label: 'XDWU.DE,  Xtrackers MSCI World Utilities UCITS ETF',
    name: 'Xtrackers MSCI World Utilities UCITS ETF',
    symbol: 'XDWU.DE',
  },
  {
    value: 'CE8.PA',
    label: 'CE8.PA,  Amundi Index Solutions - Amundi MSCI World Ex Europe',
    name: 'Amundi Index Solutions - Amundi MSCI World Ex Europe',
    symbol: 'CE8.PA',
  },
  {
    value: 'XWTS.DE',
    label: 'XWTS.DE,  Xtrackers MSCI World Communication Services UCITS ETF',
    name: 'Xtrackers MSCI World Communication Services UCITS ETF',
    symbol: 'XWTS.DE',
  },
  {
    value: 'CE8USD.SW',
    label: 'CE8USD.SW,  Amundi Index Solutions - Amundi MSCI World Ex Europe',
    name: 'Amundi Index Solutions - Amundi MSCI World Ex Europe',
    symbol: 'CE8USD.SW',
  },
  {
    value: 'XDWM.DE',
    label: 'XDWM.DE,  Xtrackers MSCI World Materials UCITS ETF',
    name: 'Xtrackers MSCI World Materials UCITS ETF',
    symbol: 'XDWM.DE',
  },
  {
    value: 'CWF.PA',
    label: 'CWF.PA,  Amundi Index Solutions - Amundi MSCI World Financials',
    name: 'Amundi Index Solutions - Amundi MSCI World Financials',
    symbol: 'CWF.PA',
  },
  {
    value: 'XDWT.L',
    label: 'XDWT.L,  Xtrackers MSCI World Information Technology UCITS ETF',
    name: 'Xtrackers MSCI World Information Technology UCITS ETF',
    symbol: 'XDWT.L',
  },
  {
    value: 'LYPH.DE',
    label: 'LYPH.DE,  Lyxor MSCI World Materials TR UCITS ETF - C-EUR',
    name: 'Lyxor MSCI World Materials TR UCITS ETF - C-EUR',
    symbol: 'LYPH.DE',
  },
  {
    value: 'LYMATW.SW',
    label: 'LYMATW.SW,  Lyxor MSCI World Materials TR UCITS ETF',
    name: 'Lyxor MSCI World Materials TR UCITS ETF',
    symbol: 'LYMATW.SW',
  },
  {
    value: 'CWE.PA',
    label: 'CWE.PA,  Amundi Index Solutions - Amundi MSCI World Energy',
    name: 'Amundi Index Solutions - Amundi MSCI World Energy',
    symbol: 'CWE.PA',
  },
  {
    value: 'LYPI.DE',
    label: 'LYPI.DE,  Lyxor MSCI World Communication Services TR UCITS ETF',
    name: 'Lyxor MSCI World Communication Services TR UCITS ETF',
    symbol: 'LYPI.DE',
  },
  {
    value: 'VCAV.F',
    label: 'VCAV.F,  VanEck Smart Home Active UCITS ETF',
    name: 'VanEck Smart Home Active UCITS ETF',
    symbol: 'VCAV.F',
  },
  {
    value: 'WRDEUA.MI',
    label: 'WRDEUA.MI,  UBS (Lux) Fund Solutions – MSCI World UCITS ETF',
    name: 'UBS (Lux) Fund Solutions – MSCI World UCITS ETF',
    symbol: 'WRDEUA.MI',
  },
  {
    value: 'SC0J.F',
    label: 'SC0J.F,  Invesco MSCI World UCITS ETF',
    name: 'Invesco MSCI World UCITS ETF',
    symbol: 'SC0J.F',
  },
  {
    value: 'SC0X.F',
    label: 'SC0X.F,  Invesco STOXX Europe 600 Optimised Technology UCITS ETF',
    name: 'Invesco STOXX Europe 600 Optimised Technology UCITS ETF',
    symbol: 'SC0X.F',
  },
  {
    value: 'UIMM.DE',
    label:
      'UIMM.DE,  UBS (Lux) Fund Solutions – MSCI World Socially Responsible UCITS ETF',
    name: 'UBS (Lux) Fund Solutions – MSCI World Socially Responsible UCITS ETF',
    symbol: 'UIMM.DE',
  },
  {
    value: 'UBU7.DE',
    label: 'UBU7.DE,  UBS (Irl) ETF plc - MSCI World UCITS ETF',
    name: 'UBS (Irl) ETF plc - MSCI World UCITS ETF',
    symbol: 'UBU7.DE',
  },
  {
    value: 'STKX.MI',
    label: 'STKX.MI,  SPDR MSCI Europe Technology UCITS ETF',
    name: 'SPDR MSCI Europe Technology UCITS ETF',
    symbol: 'STKX.MI',
  },
  {
    value: 'GDX.L',
    label: 'GDX.L,  VanEck Gold Miners UCITS ETF',
    name: 'VanEck Gold Miners UCITS ETF',
    symbol: 'GDX.L',
  },
  {
    value: 'GDXJ.L',
    label: 'GDXJ.L,  VanEck Junior Gold Miners UCITS ETF',
    name: 'VanEck Junior Gold Miners UCITS ETF',
    symbol: 'GDXJ.L',
  },
  {
    value: 'SXLK.L',
    label: 'SXLK.L,  SPDR S&P U.S. Technology Select Sector UCITS ETF',
    name: 'SPDR S&P U.S. Technology Select Sector UCITS ETF',
    symbol: 'SXLK.L',
  },
  {
    value: 'MOAT.L',
    label: 'MOAT.L,  VanEck Morningstar US Sustainable Wide Moat UCITS ETF',
    name: 'VanEck Morningstar US Sustainable Wide Moat UCITS ETF',
    symbol: 'MOAT.L',
  },
  {
    value: 'EWRD.PA',
    label: 'EWRD.PA,  BNP Paribas Easy MSCI World SRI S-Series PAB 5% Capped',
    name: 'BNP Paribas Easy MSCI World SRI S-Series PAB 5% Capped',
    symbol: 'EWRD.PA',
  },
  {
    value: 'EMWE.PA',
    label: 'EMWE.PA,  BNP Paribas Easy MSCI World SRI S-Series PAB 5% Capped',
    name: 'BNP Paribas Easy MSCI World SRI S-Series PAB 5% Capped',
    symbol: 'EMWE.PA',
  },
  {
    value: 'WSRUS.SW',
    label:
      'WSRUS.SW,  UBS (Lux) Fund Solutions – MSCI World Socially Responsible UCITS ETF',
    name: 'UBS (Lux) Fund Solutions – MSCI World Socially Responsible UCITS ETF',
    symbol: 'WSRUS.SW',
  },
  {
    value: 'GDIG.L',
    label: 'GDIG.L,  VanEck Global Mining UCITS ETF',
    name: 'VanEck Global Mining UCITS ETF',
    symbol: 'GDIG.L',
  },
  {
    value: 'ELFW.DE',
    label: 'ELFW.DE,  Deka MSCI World UCITS ETF',
    name: 'Deka MSCI World UCITS ETF',
    symbol: 'ELFW.DE',
  },
  {
    value: 'WRDUSW.SW',
    label: 'WRDUSW.SW,  UBS (Irl) ETF plc - MSCI World UCITS ETF',
    name: 'UBS (Irl) ETF plc - MSCI World UCITS ETF',
    symbol: 'WRDUSW.SW',
  },
  {
    value: 'WSRIE.MI',
    label:
      'WSRIE.MI,  UBS (Irl) ETF plc - MSCI World Socially Responsible UCITS ETF',
    name: 'UBS (Irl) ETF plc - MSCI World Socially Responsible UCITS ETF',
    symbol: 'WSRIE.MI',
  },
  {
    value: '4UB9.F',
    label:
      '4UB9.F,  UBS (Irl) ETF plc – MSCI World Socially Responsible UCITS ETF (USD) A-dis',
    name: 'UBS (Irl) ETF plc – MSCI World Socially Responsible UCITS ETF (USD) A-dis',
    symbol: '4UB9.F',
  },
  {
    value: 'CSY9.F',
    label:
      'CSY9.F,  Credit Suisse Index Fund (IE) ETF ICAV - CSIF (IE) MSCI World ESG Leaders Minimum Vol Bl UCITS ETF',
    name: 'Credit Suisse Index Fund (IE) ETF ICAV - CSIF (IE) MSCI World ESG Leaders Minimum Vol Bl UCITS ETF',
    symbol: 'CSY9.F',
  },
  {
    value: 'ICNT.F',
    label:
      'ICNT.F,  Invesco MSCI China Technology All Shares Stock Connect UCITS ETF',
    name: 'Invesco MSCI China Technology All Shares Stock Connect UCITS ETF',
    symbol: 'ICNT.F',
  },
  {
    value: 'GNOM.L',
    label: 'GNOM.L,  Global X Genomics & Biotechnology UCITS ETF',
    name: 'Global X Genomics & Biotechnology UCITS ETF',
    symbol: 'GNOM.L',
  },
  {
    value: 'VTOP.SW',
    label: 'VTOP.SW,  VanEck Crypto Leaders ETN',
    name: 'VanEck Crypto Leaders ETN',
    symbol: 'VTOP.SW',
  },
  {
    value: '2B7D.DE',
    label:
      '2B7D.DE,  iShares V PLC - iShares S&P 500 Consumer Staples Sector UCITS ETF',
    name: 'iShares V PLC - iShares S&P 500 Consumer Staples Sector UCITS ETF',
    symbol: '2B7D.DE',
  },
  {
    value: '2B7A.DE',
    label:
      '2B7A.DE,  iShares V PLC - iShares S&P 500 Utilities Sector UCITS ETF',
    name: 'iShares V PLC - iShares S&P 500 Utilities Sector UCITS ETF',
    symbol: '2B7A.DE',
  },
  {
    value: '2B7C.DE',
    label:
      '2B7C.DE,  iShares V PLC - iShares S&P 500 Industrials Sector UCITS ETF',
    name: 'iShares V PLC - iShares S&P 500 Industrials Sector UCITS ETF',
    symbol: '2B7C.DE',
  },
  {
    value: 'ICDU.L',
    label:
      'ICDU.L,  iShares V PLC - iShares S&P 500 Consumer Discretionary Sector UCITS ETF USD (Acc)',
    name: 'iShares V PLC - iShares S&P 500 Consumer Discretionary Sector UCITS ETF USD (Acc)',
    symbol: 'ICDU.L',
  },
  {
    value: 'IUCM.L',
    label:
      'IUCM.L,  iShares V PLC - iShares S&P 500 Communication Sector UCITS ETF',
    name: 'iShares V PLC - iShares S&P 500 Communication Sector UCITS ETF',
    symbol: 'IUCM.L',
  },
  {
    value: 'SPME.SW',
    label: 'SPME.SW,  iShares Edge S&P 500 Minimum Volatility UCITS ETF',
    name: 'iShares Edge S&P 500 Minimum Volatility UCITS ETF',
    symbol: 'SPME.SW',
  },
  {
    value: '500U.L',
    label: '500U.L,  Amundi Index Solutions - Amundi S&P 500 UCITS ETF C USD',
    name: 'Amundi Index Solutions - Amundi S&P 500 UCITS ETF C USD',
    symbol: '500U.L',
  },
  {
    value: 'IITU.L',
    label:
      'IITU.L,  iShares V PLC - iShares S&P 500 Information Technology Sector UCITS ETF USD (Acc)',
    name: 'iShares V PLC - iShares S&P 500 Information Technology Sector UCITS ETF USD (Acc)',
    symbol: 'IITU.L',
  },
  {
    value: '2B7B.DE',
    label:
      '2B7B.DE,  iShares V PLC - iShares S&P 500 Materials Sector UCITS ETF',
    name: 'iShares V PLC - iShares S&P 500 Materials Sector UCITS ETF',
    symbol: '2B7B.DE',
  },
  {
    value: 'CSSPX.MI',
    label: 'CSSPX.MI,  iShares Core S&P 500 UCITS ETF USD (Acc)',
    name: 'iShares Core S&P 500 UCITS ETF USD (Acc)',
    symbol: 'CSSPX.MI',
  },
  {
    value: 'XDPD.F',
    label: 'XDPD.F,  Xtrackers S&P 500 UCITS ETF',
    name: 'Xtrackers S&P 500 UCITS ETF',
    symbol: 'XDPD.F',
  },
  {
    value: 'XDPE.DE',
    label:
      'XDPE.DE,  Xtrackers (IE) Plc - Xtrackers S&P 500 UCITS ETF 1C - EUR Hedged',
    name: 'Xtrackers (IE) Plc - Xtrackers S&P 500 UCITS ETF 1C - EUR Hedged',
    symbol: 'XDPE.DE',
  },
  {
    value: 'IUHE.AS',
    label:
      'IUHE.AS,  iShares V PLC - iShares S&P 500 Health Care Sector UCITS ETF USD (Acc)',
    name: 'iShares V PLC - iShares S&P 500 Health Care Sector UCITS ETF USD (Acc)',
    symbol: 'IUHE.AS',
  },
  {
    value: 'LYPS.DE',
    label: 'LYPS.DE,  Lyxor S&P 500 UCITS ETF - D-EUR',
    name: 'Lyxor S&P 500 UCITS ETF - D-EUR',
    symbol: 'LYPS.DE',
  },
  {
    value: 'LVO.MI',
    label: 'LVO.MI,  Lyxor S&P 500 VIX Futures Enhanced Roll UCITS ETF',
    name: 'Lyxor S&P 500 VIX Futures Enhanced Roll UCITS ETF',
    symbol: 'LVO.MI',
  },
  {
    value: 'LYSPH.SW',
    label: 'LYSPH.SW,  Lyxor S&P 500 UCITS ETF',
    name: 'Lyxor S&P 500 UCITS ETF',
    symbol: 'LYSPH.SW',
  },
  {
    value: 'S500.MI',
    label: 'S500.MI,  Amundi Index Solutions - Amundi S&P 500 ESG',
    name: 'Amundi Index Solutions - Amundi S&P 500 ESG',
    symbol: 'S500.MI',
  },
  {
    value: 'H1D5.F',
    label: 'H1D5.F,  Amundi Index Solutions - Amundi S&P 500 UCITS ETF',
    name: 'Amundi Index Solutions - Amundi S&P 500 UCITS ETF',
    symbol: 'H1D5.F',
  },
  {
    value: 'IUHC.L',
    label:
      'IUHC.L,  iShares V PLC - iShares S&P 500 Health Care Sector UCITS ETF USD (Acc)',
    name: 'iShares V PLC - iShares S&P 500 Health Care Sector UCITS ETF USD (Acc)',
    symbol: 'IUHC.L',
  },
  {
    value: 'XDPC.SW',
    label: 'XDPC.SW,  Xtrackers S&P 500 UCITS ETF',
    name: 'Xtrackers S&P 500 UCITS ETF',
    symbol: 'XDPC.SW',
  },
  {
    value: 'XDPG.L',
    label:
      'XDPG.L,  Xtrackers (IE) Plc - Xtrackers S&P 500 UCITS ETF 2C - GBP Hedged',
    name: 'Xtrackers (IE) Plc - Xtrackers S&P 500 UCITS ETF 2C - GBP Hedged',
    symbol: 'XDPG.L',
  },
  {
    value: 'XSPU.L',
    label: 'XSPU.L,  Xtrackers S&P 500 Swap UCITS ETF',
    name: 'Xtrackers S&P 500 Swap UCITS ETF',
    symbol: 'XSPU.L',
  },
  {
    value: 'SP5H.PA',
    label: 'SP5H.PA,  Lyxor S&P 500 UCITS ETF - Daily Hedged D-EUR',
    name: 'Lyxor S&P 500 UCITS ETF - Daily Hedged D-EUR',
    symbol: 'SP5H.PA',
  },
  {
    value: '500.PA',
    label: '500.PA,  Amundi Index Solutions - Amundi S&P 500 UCITS ETF',
    name: 'Amundi Index Solutions - Amundi S&P 500 UCITS ETF',
    symbol: '500.PA',
  },
  {
    value: 'IUSE.L',
    label:
      'IUSE.L,  iShares V PLC - iShares S&P 500 EUR Hedged UCITS ETF (Acc)',
    name: 'iShares V PLC - iShares S&P 500 EUR Hedged UCITS ETF (Acc)',
    symbol: 'IUSE.L',
  },
  {
    value: 'S500H.PA',
    label: 'S500H.PA,  Amundi Index Solutions - Amundi S&P 500 ESG',
    name: 'Amundi Index Solutions - Amundi S&P 500 ESG',
    symbol: 'S500H.PA',
  },
  {
    value: 'B500.F',
    label: 'B500.F,  Amundi Index Solutions - Amundi S&P 500 Buyback',
    name: 'Amundi Index Solutions - Amundi S&P 500 Buyback',
    symbol: 'B500.F',
  },
  {
    value: 'UIFS.L',
    label:
      'UIFS.L,  iShares V PLC - iShares S&P 500 Financials Sector UCITS ETF USD (Acc)',
    name: 'iShares V PLC - iShares S&P 500 Financials Sector UCITS ETF USD (Acc)',
    symbol: 'UIFS.L',
  },
  {
    value: 'SP5C.PA',
    label: 'SP5C.PA,  Lyxor S&P 500 UCITS ETF',
    name: 'Lyxor S&P 500 UCITS ETF',
    symbol: 'SP5C.PA',
  },
  {
    value: 'BYBU.PA',
    label: 'BYBU.PA,  Amundi Index Solutions - Amundi S&P 500 Buyback',
    name: 'Amundi Index Solutions - Amundi S&P 500 Buyback',
    symbol: 'BYBU.PA',
  },
  {
    value: 'BSX.PA',
    label: 'BSX.PA,  Lyxor Euro Stoxx 50 Daily (-1x) Inverse UCITS ETF',
    name: 'Lyxor Euro Stoxx 50 Daily (-1x) Inverse UCITS ETF',
    symbol: 'BSX.PA',
  },
  {
    value: 'BXX.PA',
    label: 'BXX.PA,  Lyxor Euro Stoxx 50 Daily (-2x) Inverse UCITS ETF',
    name: 'Lyxor Euro Stoxx 50 Daily (-2x) Inverse UCITS ETF',
    symbol: 'BXX.PA',
  },
  {
    value: 'LVE.PA',
    label: 'LVE.PA,  Lyxor Euro Stoxx 50 Daily (2x) Leveraged UCITS ETF',
    name: 'Lyxor Euro Stoxx 50 Daily (2x) Leveraged UCITS ETF',
    symbol: 'LVE.PA',
  },
  {
    value: 'GSPX.L',
    label: 'GSPX.L,  iShares VII PLC - iShares Core S&P 500 UCITS ETF',
    name: 'iShares VII PLC - iShares Core S&P 500 UCITS ETF',
    symbol: 'GSPX.L',
  },
  {
    value: 'XSPS.L',
    label: 'XSPS.L,  Xtrackers S&P 500 Inverse Daily Swap UCITS ETF',
    name: 'Xtrackers S&P 500 Inverse Daily Swap UCITS ETF',
    symbol: 'XSPS.L',
  },
  {
    value: 'XT2D.L',
    label: 'XT2D.L,  Xtrackers S&P 500 2x Inverse Daily Swap UCITS ETF',
    name: 'Xtrackers S&P 500 2x Inverse Daily Swap UCITS ETF',
    symbol: 'XT2D.L',
  },
  {
    value: 'SPHC.MI',
    label: 'SPHC.MI,  Lyxor S&P 500 UCITS ETF - Daily Hedged C-EUR',
    name: 'Lyxor S&P 500 UCITS ETF - Daily Hedged C-EUR',
    symbol: 'SPHC.MI',
  },
  {
    value: 'EL4B.DE',
    label: 'EL4B.DE,  Deka EURO STOXX 50 UCITS ETF',
    name: 'Deka EURO STOXX 50 UCITS ETF',
    symbol: 'EL4B.DE',
  },
  {
    value: 'SC0D.DE',
    label: 'SC0D.DE,  Invesco EURO STOXX 50 UCITS ETF',
    name: 'Invesco EURO STOXX 50 UCITS ETF',
    symbol: 'SC0D.DE',
  },
  {
    value: 'S6X0.DE',
    label: 'S6X0.DE,  Invesco EURO STOXX 50 UCITS ETF',
    name: 'Invesco EURO STOXX 50 UCITS ETF',
    symbol: 'S6X0.DE',
  },
  {
    value: 'XS2D.L',
    label: 'XS2D.L,  Xtrackers S&P 500 2x Leveraged Daily Swap UCITS ETF',
    name: 'Xtrackers S&P 500 2x Leveraged Daily Swap UCITS ETF',
    symbol: 'XS2D.L',
  },
  {
    value: 'SPY5.DE',
    label: 'SPY5.DE,  SPDR S&P 500 UCITS ETF',
    name: 'SPDR S&P 500 UCITS ETF',
    symbol: 'SPY5.DE',
  },
  {
    value: 'UC13.L',
    label: 'UC13.L,  UBS (Irl) ETF plc - S&P 500 UCITS ETF',
    name: 'UBS (Irl) ETF plc - S&P 500 UCITS ETF',
    symbol: 'UC13.L',
  },
  {
    value: 'SPY1.DE',
    label: 'SPY1.DE,  SPDR S&P 500 Low Volatility UCITS ETF',
    name: 'SPDR S&P 500 Low Volatility UCITS ETF',
    symbol: 'SPY1.DE',
  },
  {
    value: 'ESE.PA',
    label: 'ESE.PA,  BNP Paribas Easy S&P 500 UCITS ETF',
    name: 'BNP Paribas Easy S&P 500 UCITS ETF',
    symbol: 'ESE.PA',
  },
  {
    value: 'E500.F',
    label: 'E500.F,  Invesco S&P 500 UCITS ETF (EUR Hdg)',
    name: 'Invesco S&P 500 UCITS ETF (EUR Hdg)',
    symbol: 'E500.F',
  },
  {
    value: 'ELFA.DE',
    label: 'ELFA.DE,  Deka EURO STOXX 50 ESG Filtered UCITS ETF',
    name: 'Deka EURO STOXX 50 ESG Filtered UCITS ETF',
    symbol: 'ELFA.DE',
  },
  {
    value: 'ETDD.PA',
    label: 'ETDD.PA,  BNP Paribas Easy Euro Stoxx 50 UCITS ETF',
    name: 'BNP Paribas Easy Euro Stoxx 50 UCITS ETF',
    symbol: 'ETDD.PA',
  },
  {
    value: 'ETBB.PA',
    label: 'ETBB.PA,  BNP Paribas Easy Euro Stoxx 50 UCITS ETF EUR C/D',
    name: 'BNP Paribas Easy Euro Stoxx 50 UCITS ETF EUR C/D',
    symbol: 'ETBB.PA',
  },
  {
    value: 'UEQD.DE',
    label:
      'UEQD.DE,  UBS (Irl) ETF plc - S&P 500 UCITS ETF (hedged to EUR) A-acc',
    name: 'UBS (Irl) ETF plc - S&P 500 UCITS ETF (hedged to EUR) A-acc',
    symbol: 'UEQD.DE',
  },
  {
    value: 'ESEH.PA',
    label: 'ESEH.PA,  BNP Paribas Easy S&P 500 UCITS ETF',
    name: 'BNP Paribas Easy S&P 500 UCITS ETF',
    symbol: 'ESEH.PA',
  },
  {
    value: 'PQVM.MI',
    label: 'PQVM.MI,  Invesco S&P 500 QVM UCITS ETF',
    name: 'Invesco S&P 500 QVM UCITS ETF',
    symbol: 'PQVM.MI',
  },
  {
    value: 'VUSA.L',
    label: 'VUSA.L,  Vanguard S&P 500 UCITS ETF',
    name: 'Vanguard S&P 500 UCITS ETF',
    symbol: 'VUSA.L',
  },
  {
    value: '5ESGE.MI',
    label:
      '5ESGE.MI,  UBS (Irl) ETF plc - S&P 500 ESG UCITS ETF Hedged to EUR A Acc',
    name: 'UBS (Irl) ETF plc - S&P 500 ESG UCITS ETF Hedged to EUR A Acc',
    symbol: '5ESGE.MI',
  },
  {
    value: 'S5SD.DE',
    label: 'S5SD.DE,  UBS (Irl) ETF plc - S&P 500 ESG UCITS ETF',
    name: 'UBS (Irl) ETF plc - S&P 500 ESG UCITS ETF',
    symbol: 'S5SD.DE',
  },
  {
    value: 'UET5.F',
    label: 'UET5.F,  UBS (Lux) Fund Solutions – EURO STOXX 50 ESG UCITS ETF',
    name: 'UBS (Lux) Fund Solutions – EURO STOXX 50 ESG UCITS ETF',
    symbol: 'UET5.F',
  },
  {
    value: 'ESDD.PA',
    label: 'ESDD.PA,  BNP Paribas Easy S&P 500 UCITS ETF',
    name: 'BNP Paribas Easy S&P 500 UCITS ETF',
    symbol: 'ESDD.PA',
  },
  {
    value: '500X.AS',
    label: '500X.AS,  SPDR S&P 500 ESG Leaders UCITS ETF',
    name: 'SPDR S&P 500 ESG Leaders UCITS ETF',
    symbol: '500X.AS',
  },
  {
    value: 'SPXE.L',
    label: 'SPXE.L,  Invesco S&P 500 ESG UCITS ETF',
    name: 'Invesco S&P 500 ESG UCITS ETF',
    symbol: 'SPXE.L',
  },
  {
    value: 'SPESGE.SW',
    label:
      'SPESGE.SW,  UBS (Irl) ETF plc - S&P 500 ESG ELITE UCITS ETF (USD) A Acc',
    name: 'UBS (Irl) ETF plc - S&P 500 ESG ELITE UCITS ETF (USD) A Acc',
    symbol: 'SPESGE.SW',
  },
  {
    value: 'AW1B.F',
    label: 'AW1B.F,  UBS (Irl) ETF plc - S&P 500 ESG ELITE UCITS ETF',
    name: 'UBS (Irl) ETF plc - S&P 500 ESG ELITE UCITS ETF',
    symbol: 'AW1B.F',
  },
  {
    value: 'SPEQ.L',
    label: 'SPEQ.L,  Invesco S&P 500 Equal Weight Index ETF Acc',
    name: 'Invesco S&P 500 Equal Weight Index ETF Acc',
    symbol: 'SPEQ.L',
  },
  {
    value: 'WSCSRI.SW',
    label:
      'WSCSRI.SW,  UBS (Irl) ETF plc - UBS (Irl) ETF plc MSCI World Small Cap Socially Responsible UCITS ETF',
    name: 'UBS (Irl) ETF plc - UBS (Irl) ETF plc MSCI World Small Cap Socially Responsible UCITS ETF',
    symbol: 'WSCSRI.SW',
  },
  {
    value: 'PAUD.F',
    label: 'PAUD.F,  Invesco MSCI World ESG Climate Paris Aligned UCITS ETF',
    name: 'Invesco MSCI World ESG Climate Paris Aligned UCITS ETF',
    symbol: 'PAUD.F',
  },
  {
    value: 'EXX7.DE',
    label: 'EXX7.DE,  iShares Nikkei 225 UCITS ETF (DE)',
    name: 'iShares Nikkei 225 UCITS ETF (DE)',
    symbol: 'EXX7.DE',
  },
  {
    value: 'JPN.PA',
    label: 'JPN.PA,  Lyxor Japan (Topix) (DR) UCITS ETF',
    name: 'Lyxor Japan (Topix) (DR) UCITS ETF',
    symbol: 'JPN.PA',
  },
  {
    value: 'LYINR.SW',
    label: 'LYINR.SW,  Lyxor MSCI India UCITS ETF',
    name: 'Lyxor MSCI India UCITS ETF',
    symbol: 'LYINR.SW',
  },
  {
    value: 'LEM.PA',
    label: 'LEM.PA,  Lyxor MSCI Emerging Markets UCITS ETF',
    name: 'Lyxor MSCI Emerging Markets UCITS ETF',
    symbol: 'LEM.PA',
  },
  {
    value: 'CS1.PA',
    label: 'CS1.PA,  Amundi ETF MSCI Spain UCITS ETF',
    name: 'Amundi ETF MSCI Spain UCITS ETF',
    symbol: 'CS1.PA',
  },
  {
    value: 'CB5.PA',
    label: 'CB5.PA,  Amundi ETF MSCI Europe Banks UCITS ETF',
    name: 'Amundi ETF MSCI Europe Banks UCITS ETF',
    symbol: 'CB5.PA',
  },
  {
    value: 'MD4X.DE',
    label: 'MD4X.DE,  Lyxor German Mid-Cap MDAX UCITS ETF',
    name: 'Lyxor German Mid-Cap MDAX UCITS ETF',
    symbol: 'MD4X.DE',
  },
  {
    value: 'ISFA.AS',
    label: 'ISFA.AS,  iShares Core FTSE 100 UCITS ETF GBP (Dist)',
    name: 'iShares Core FTSE 100 UCITS ETF GBP (Dist)',
    symbol: 'ISFA.AS',
  },
  {
    value: 'H2OA.AS',
    label: 'H2OA.AS,  iShares Global Water UCITS ETF',
    name: 'iShares Global Water UCITS ETF',
    symbol: 'H2OA.AS',
  },
  {
    value: 'MPAB.AS',
    label: 'MPAB.AS,  iShares MSCI EMU Paris-Aligned Climate UCITS ETF',
    name: 'iShares MSCI EMU Paris-Aligned Climate UCITS ETF',
    symbol: 'MPAB.AS',
  },
  {
    value: 'CTEC.AS',
    label: 'CTEC.AS,  iShares MSCI China Tech UCITS ETF',
    name: 'iShares MSCI China Tech UCITS ETF',
    symbol: 'CTEC.AS',
  },
  {
    value: 'FXAC.AS',
    label: 'FXAC.AS,  iShares China Large Cap UCITS ETF',
    name: 'iShares China Large Cap UCITS ETF',
    symbol: 'FXAC.AS',
  },
  {
    value: 'FXC.L',
    label: 'FXC.L,  iShares China Large Cap UCITS ETF',
    name: 'iShares China Large Cap UCITS ETF',
    symbol: 'FXC.L',
  },
  {
    value: 'IDVY.SW',
    label: 'IDVY.SW,  iShares Euro Dividend UCITS ETF',
    name: 'iShares Euro Dividend UCITS ETF',
    symbol: 'IDVY.SW',
  },
  {
    value: 'IUKD.SW',
    label: 'IUKD.SW,  iShares UK Dividend UCITS ETF',
    name: 'iShares UK Dividend UCITS ETF',
    symbol: 'IUKD.SW',
  },
  {
    value: 'IPRP.L',
    label: 'IPRP.L,  iShares European Property Yield UCITS ETF',
    name: 'iShares European Property Yield UCITS ETF',
    symbol: 'IPRP.L',
  },
  {
    value: 'IFFF.SW',
    label: 'IFFF.SW,  iShares MSCI AC Far East ex-Japan UCITS ETF',
    name: 'iShares MSCI AC Far East ex-Japan UCITS ETF',
    symbol: 'IFFF.SW',
  },
  {
    value: 'BRIC.SW',
    label: 'BRIC.SW,  iShares BRIC 50 UCITS ETF',
    name: 'iShares BRIC 50 UCITS ETF',
    symbol: 'BRIC.SW',
  },
  {
    value: 'WOOD.L',
    label: 'WOOD.L,  iShares Global Timber & Forestry UCITS ETF',
    name: 'iShares Global Timber & Forestry UCITS ETF',
    symbol: 'WOOD.L',
  },
  {
    value: 'CSPXJ.SW',
    label:
      'CSPXJ.SW,  iShares VII PLC - iShares Core MSCI Pacific ex-Japan UCITS ETF',
    name: 'iShares VII PLC - iShares Core MSCI Pacific ex-Japan UCITS ETF',
    symbol: 'CSPXJ.SW',
  },
  {
    value: 'CSUS.SW',
    label: 'CSUS.SW,  iShares VII PLC - iShares MSCI USA UCITS ETF USD (Acc)',
    name: 'iShares VII PLC - iShares MSCI USA UCITS ETF USD (Acc)',
    symbol: 'CSUS.SW',
  },
  {
    value: 'SRSA.L',
    label: 'SRSA.L,  iShares MSCI South Africa UCITS ETF',
    name: 'iShares MSCI South Africa UCITS ETF',
    symbol: 'SRSA.L',
  },
  {
    value: 'CSUKX.SW',
    label: 'CSUKX.SW,  iShares VII PLC - iShares Core FTSE 100 UCITS ETF',
    name: 'iShares VII PLC - iShares Core FTSE 100 UCITS ETF',
    symbol: 'CSUKX.SW',
  },
  {
    value: 'CSEMU.SW',
    label:
      'CSEMU.SW,  iShares VII PLC -iShares Core MSCI EMU UCITS ETF EUR (Acc)',
    name: 'iShares VII PLC -iShares Core MSCI EMU UCITS ETF EUR (Acc)',
    symbol: 'CSEMU.SW',
  },
  {
    value: 'CSEMAS.SW',
    label:
      'CSEMAS.SW,  iShares VII PLC - iShares MSCI EM Asia UCITS ETF USD (Acc)',
    name: 'iShares VII PLC - iShares MSCI EM Asia UCITS ETF USD (Acc)',
    symbol: 'CSEMAS.SW',
  },
  {
    value: 'CSMXCP.SW',
    label: 'CSMXCP.SW,  iShares VII PLC - iShares MSCI Mexico Capped UCITS ETF',
    name: 'iShares VII PLC - iShares MSCI Mexico Capped UCITS ETF',
    symbol: 'CSMXCP.SW',
  },
  {
    value: 'IEDY.L',
    label: 'IEDY.L,  iShares V PLC - iShares EM Dividend UCITS ETF USD (Dist)',
    name: 'iShares V PLC - iShares EM Dividend UCITS ETF USD (Dist)',
    symbol: 'IEDY.L',
  },
  {
    value: 'SHLD.L',
    label: 'SHLD.L,  iShares Digital Security UCITS ETF',
    name: 'iShares Digital Security UCITS ETF',
    symbol: 'SHLD.L',
  },
  {
    value: 'IPRE.F',
    label: 'IPRE.F,  iShares European Property Yield UCITS ETF',
    name: 'iShares European Property Yield UCITS ETF',
    symbol: 'IPRE.F',
  },
  {
    value: 'XDND.DE',
    label:
      'XDND.DE,  Xtrackers MSCI North America High Dividend Yield UCITS ETF',
    name: 'Xtrackers MSCI North America High Dividend Yield UCITS ETF',
    symbol: 'XDND.DE',
  },
  {
    value: 'EEJD.L',
    label: 'EEJD.L,  iShares MSCI Japan ESG Enhanced UCITS ETF',
    name: 'iShares MSCI Japan ESG Enhanced UCITS ETF',
    symbol: 'EEJD.L',
  },
  {
    value: 'EEDS.L',
    label: 'EEDS.L,  iShares MSCI USA ESG Enhanced UCITS ETF',
    name: 'iShares MSCI USA ESG Enhanced UCITS ETF',
    symbol: 'EEDS.L',
  },
  {
    value: 'CEMG.L',
    label:
      'CEMG.L,  iShares V PLC - iShares MSCI EM Consumer Growth UCITS ETF USD (Acc)',
    name: 'iShares V PLC - iShares MSCI EM Consumer Growth UCITS ETF USD (Acc)',
    symbol: 'CEMG.L',
  },
  {
    value: 'XDEX.L',
    label: 'XDEX.L,  Xtrackers MSCI Emerging Markets ESG Screened UCITS ETF',
    name: 'Xtrackers MSCI Emerging Markets ESG Screened UCITS ETF',
    symbol: 'XDEX.L',
  },
  {
    value: 'UKEL.L',
    label: 'UKEL.L,  iShares MSCI UK IMI ESG Leaders UCITS ETF',
    name: 'iShares MSCI UK IMI ESG Leaders UCITS ETF',
    symbol: 'UKEL.L',
  },
  {
    value: 'PCEL.AS',
    label: 'PCEL.AS,  iShares MSCI Pacific ex-Japan ESG Enhanced UCITS ETF',
    name: 'iShares MSCI Pacific ex-Japan ESG Enhanced UCITS ETF',
    symbol: 'PCEL.AS',
  },
  {
    value: 'EXCH.AS',
    label: 'EXCH.AS,  iShares MSCI EM ex China UCITS ETF',
    name: 'iShares MSCI EM ex China UCITS ETF',
    symbol: 'EXCH.AS',
  },
  {
    value: 'XUCM.F',
    label: 'XUCM.F,  Xtrackers MSCI USA Communication Services UCITS ETF',
    name: 'Xtrackers MSCI USA Communication Services UCITS ETF',
    symbol: 'XUCM.F',
  },
  {
    value: 'IWFM.L',
    label: 'IWFM.L,  iShares Edge MSCI World Momentum Factor UCITS ETF',
    name: 'iShares Edge MSCI World Momentum Factor UCITS ETF',
    symbol: 'IWFM.L',
  },
  {
    value: 'XDNE.DE',
    label: 'XDNE.DE,  Xtrackers MSCI Japan ESG Screened UCITS ETF',
    name: 'Xtrackers MSCI Japan ESG Screened UCITS ETF',
    symbol: 'XDNE.DE',
  },
  {
    value: 'SUES.L',
    label: 'SUES.L,  iShares MSCI EM SRI UCITS ETF',
    name: 'iShares MSCI EM SRI UCITS ETF',
    symbol: 'SUES.L',
  },
  {
    value: 'RBOD.L',
    label: 'RBOD.L,  iShares Automation & Robotics UCITS ETF',
    name: 'iShares Automation & Robotics UCITS ETF',
    symbol: 'RBOD.L',
  },
  {
    value: 'QDVX.F',
    label: 'QDVX.F,  iShares MSCI Europe Quality Dividend ESG UCITS ETF',
    name: 'iShares MSCI Europe Quality Dividend ESG UCITS ETF',
    symbol: 'QDVX.F',
  },
  {
    value: 'RBOT.L',
    label: 'RBOT.L,  iShares Automation & Robotics UCITS ETF',
    name: 'iShares Automation & Robotics UCITS ETF',
    symbol: 'RBOT.L',
  },
  {
    value: 'DBXI.DE',
    label: 'DBXI.DE,  Xtrackers FTSE MIB UCITS ETF',
    name: 'Xtrackers FTSE MIB UCITS ETF',
    symbol: 'DBXI.DE',
  },
  {
    value: 'DBXS.DE',
    label: 'DBXS.DE,  Xtrackers Switzerland UCITS ETF',
    name: 'Xtrackers Switzerland UCITS ETF',
    symbol: 'DBXS.DE',
  },
  {
    value: 'XMCX.L',
    label: 'XMCX.L,  Xtrackers FTSE 250 UCITS ETF',
    name: 'Xtrackers FTSE 250 UCITS ETF',
    symbol: 'XMCX.L',
  },
  {
    value: 'XSPR.L',
    label: 'XSPR.L,  XtrackersMSCI Europe Materials ESG Screened UCITS ETF',
    name: 'XtrackersMSCI Europe Materials ESG Screened UCITS ETF',
    symbol: 'XSPR.L',
  },
  {
    value: 'DXSG.DE',
    label:
      'DXSG.DE,  Xtrackers MSCI Europe Communication Services ESG Screened UCITS ETF',
    name: 'Xtrackers MSCI Europe Communication Services ESG Screened UCITS ETF',
    symbol: 'DXSG.DE',
  },
  {
    value: 'XS3R.L',
    label:
      'XS3R.L,  Xtrackers MSCI Europe Consumer Staples ESG Screened UCITS ETF',
    name: 'Xtrackers MSCI Europe Consumer Staples ESG Screened UCITS ETF',
    symbol: 'XS3R.L',
  },
  {
    value: 'DBX2.F',
    label: 'DBX2.F,  Xtrackers MSCI EM Asia Swap UCITS ETF 1C',
    name: 'Xtrackers MSCI EM Asia Swap UCITS ETF 1C',
    symbol: 'DBX2.F',
  },
  {
    value: 'XMBR.L',
    label: 'XMBR.L,  Xtrackers MSCI Brazil UCITS ETF',
    name: 'Xtrackers MSCI Brazil UCITS ETF',
    symbol: 'XMBR.L',
  },
  {
    value: 'DX2G.DE',
    label: 'DX2G.DE,  Xtrackers CAC 40 UCITS ETF',
    name: 'Xtrackers CAC 40 UCITS ETF',
    symbol: 'DX2G.DE',
  },
  {
    value: 'XPXJ.MI',
    label: 'XPXJ.MI,  Xtrackers MSCI Pacific ex Japan ESG Screened UCITS ETF',
    name: 'Xtrackers MSCI Pacific ex Japan ESG Screened UCITS ETF',
    symbol: 'XPXJ.MI',
  },
  {
    value: 'XFVT.L',
    label: 'XFVT.L,  Xtrackers FTSE Vietnam Swap UCITS ETF',
    name: 'Xtrackers FTSE Vietnam Swap UCITS ETF',
    symbol: 'XFVT.L',
  },
  {
    value: 'XSFR.L',
    label: 'XSFR.L,  Xtrackers S&P Select Frontier Swap UCITS ETF',
    name: 'Xtrackers S&P Select Frontier Swap UCITS ETF',
    symbol: 'XSFR.L',
  },
  {
    value: 'C020.DE',
    label: 'C020.DE,  Lyxor Nikkei 225 UCITS ETF',
    name: 'Lyxor Nikkei 225 UCITS ETF',
    symbol: 'C020.DE',
  },
  {
    value: 'X013.DE',
    label: 'X013.DE,  Lyxor MSCI North America UCITS ETF',
    name: 'Lyxor MSCI North America UCITS ETF',
    symbol: 'X013.DE',
  },
  {
    value: 'X025.DE',
    label: 'X025.DE,  Lyxor MSCI Europe Mid Cap UCITS ETF',
    name: 'Lyxor MSCI Europe Mid Cap UCITS ETF',
    symbol: 'X025.DE',
  },
  {
    value: 'XCAD.L',
    label: 'XCAD.L,  Xtrackers MSCI Canada ESG Screened UCITS ETF',
    name: 'Xtrackers MSCI Canada ESG Screened UCITS ETF',
    symbol: 'XCAD.L',
  },
  {
    value: 'LYPU.DE',
    label: 'LYPU.DE,  Lyxor Australia (S&P/ASX 200) UCITS ETF',
    name: 'Lyxor Australia (S&P/ASX 200) UCITS ETF',
    symbol: 'LYPU.DE',
  },
  {
    value: 'XMAF.L',
    label: 'XMAF.L,  Xtrackers MSCI Africa Top 50 Swap UCITS ETF',
    name: 'Xtrackers MSCI Africa Top 50 Swap UCITS ETF',
    symbol: 'XMAF.L',
  },
  {
    value: 'C029.DE',
    label: 'C029.DE,  Lyxor SPI UCITS ETF',
    name: 'Lyxor SPI UCITS ETF',
    symbol: 'C029.DE',
  },
  {
    value: 'O9A.SI',
    label: 'O9A.SI,  Xtrackers MSCI Singapore UCITS ETF',
    name: 'Xtrackers MSCI Singapore UCITS ETF',
    symbol: 'O9A.SI',
  },
  {
    value: 'XB4A.DE',
    label: 'XB4A.DE,  Xtrackers ATX UCITS ETF',
    name: 'Xtrackers ATX UCITS ETF',
    symbol: 'XB4A.DE',
  },
  {
    value: 'XBAK.F',
    label: 'XBAK.F,  Xtrackers MSCI Pakistan Swap UCITS ETF',
    name: 'Xtrackers MSCI Pakistan Swap UCITS ETF',
    symbol: 'XBAK.F',
  },
  {
    value: 'XDJP.DE',
    label: 'XDJP.DE,  Xtrackers Nikkei 225 UCITS ETF 1D',
    name: 'Xtrackers Nikkei 225 UCITS ETF 1D',
    symbol: 'XDJP.DE',
  },
  {
    value: 'XD5E.L',
    label: 'XD5E.L,  Xtrackers MSCI EMU UCITS ETF',
    name: 'Xtrackers MSCI EMU UCITS ETF',
    symbol: 'XD5E.L',
  },
  {
    value: 'C024.DE',
    label: 'C024.DE,  Lyxor FTSE China A50 UCITS ETF',
    name: 'Lyxor FTSE China A50 UCITS ETF',
    symbol: 'C024.DE',
  },
  {
    value: 'XDUE.SW',
    label: 'XDUE.SW,  Xtrackers MSCI EMU UCITS ETF',
    name: 'Xtrackers MSCI EMU UCITS ETF',
    symbol: 'XDUE.SW',
  },
  {
    value: 'AH50.DE',
    label: 'AH50.DE,  Xtrackers Harvest FTSE China A-H 50 UCITS ETF',
    name: 'Xtrackers Harvest FTSE China A-H 50 UCITS ETF',
    symbol: 'AH50.DE',
  },
  {
    value: 'MIVU.PA',
    label:
      'MIVU.PA,  Amundi Index Solutions - Amundi MSCI USA Minimum Volatility Factor',
    name: 'Amundi Index Solutions - Amundi MSCI USA Minimum Volatility Factor',
    symbol: 'MIVU.PA',
  },
  {
    value: 'CP9.PA',
    label:
      'CP9.PA,  Amundi Index MSCI Pacific ex Japan SRI - UCITS ETF DR - EUR (C)',
    name: 'Amundi Index MSCI Pacific ex Japan SRI - UCITS ETF DR - EUR (C)',
    symbol: 'CP9.PA',
  },
  {
    value: 'JPXU.L',
    label:
      'JPXU.L,  Lyxor MSCI Japan ESG Leaders Extra (DR) UCITS ETF - Daily Hedged to USD Acc',
    name: 'Lyxor MSCI Japan ESG Leaders Extra (DR) UCITS ETF - Daily Hedged to USD Acc',
    symbol: 'JPXU.L',
  },
  {
    value: 'FMI.PA',
    label: 'FMI.PA,  Amundi Index Solutions - Amundi ITALY MIB ESG',
    name: 'Amundi Index Solutions - Amundi ITALY MIB ESG',
    symbol: 'FMI.PA',
  },
  {
    value: 'TPXE.PA',
    label: 'TPXE.PA,  Amundi Index Solutions - Amundi Japan Topix',
    name: 'Amundi Index Solutions - Amundi Japan Topix',
    symbol: 'TPXE.PA',
  },
  {
    value: 'SMRE.PA',
    label:
      'SMRE.PA,  Amundi Index Solutions - Amundi Europe Equity Multi Smart Allocation Scientific Beta',
    name: 'Amundi Index Solutions - Amundi Europe Equity Multi Smart Allocation Scientific Beta',
    symbol: 'SMRE.PA',
  },
  {
    value: 'US1.SW',
    label: 'US1.SW,  Amundi Index Solutions - Amundi US Treasury 1-3',
    name: 'Amundi Index Solutions - Amundi US Treasury 1-3',
    symbol: 'US1.SW',
  },
  {
    value: 'MCEU.PA',
    label:
      'MCEU.PA,  Amundi Index Solutions - Amundi MSCI Europe Momentum Factor',
    name: 'Amundi Index Solutions - Amundi MSCI Europe Momentum Factor',
    symbol: 'MCEU.PA',
  },
  {
    value: 'CI2.PA',
    label: 'CI2.PA,  Amundi Index Solutions - Amundi MSCI India',
    name: 'Amundi Index Solutions - Amundi MSCI India',
    symbol: 'CI2.PA',
  },
  {
    value: 'CE9.PA',
    label:
      'CE9.PA,  Amundi Index Solutions - Amundi MSCI Eastern Europe Ex Russia',
    name: 'Amundi Index Solutions - Amundi MSCI Eastern Europe Ex Russia',
    symbol: 'CE9.PA',
  },
  {
    value: '540J.F',
    label: '540J.F,  Amundi Index Solutions - Amundi MSCI Switzerland',
    name: 'Amundi Index Solutions - Amundi MSCI Switzerland',
    symbol: '540J.F',
  },
  {
    value: 'AEMD.F',
    label:
      'AEMD.F,  Amundi Index Solutions - Amundi Index MSCI Emerging Markets',
    name: 'Amundi Index Solutions - Amundi Index MSCI Emerging Markets',
    symbol: 'AEMD.F',
  },
  {
    value: 'ACWI.PA',
    label: 'ACWI.PA,  Lyxor Msci All Country World Ucits ETF',
    name: 'Lyxor Msci All Country World Ucits ETF',
    symbol: 'ACWI.PA',
  },
  {
    value: 'ROAI.SW',
    label: 'ROAI.SW,  Lyxor MSCI Robotics & AI ESG Filtered UCITS ETF',
    name: 'Lyxor MSCI Robotics & AI ESG Filtered UCITS ETF',
    symbol: 'ROAI.SW',
  },
  {
    value: 'USRI.PA',
    label:
      'USRI.PA,  Amundi Index Solutions - Amundi MSCI USA SRI UCITS ETF DR',
    name: 'Amundi Index Solutions - Amundi MSCI USA SRI UCITS ETF DR',
    symbol: 'USRI.PA',
  },
  {
    value: 'INDO.PA',
    label: 'INDO.PA,  Lyxor MSCI Indonesia UCITS ETF',
    name: 'Lyxor MSCI Indonesia UCITS ETF',
    symbol: 'INDO.PA',
  },
  {
    value: 'TUR.PA',
    label: 'TUR.PA,  Lyxor MSCI Turkey UCITS ETF',
    name: 'Lyxor MSCI Turkey UCITS ETF',
    symbol: 'TUR.PA',
  },
  {
    value: 'SBIU.DE',
    label:
      'SBIU.DE,  Amundi Index Solutions - Amundi MSCI USA ESG Universal Select',
    name: 'Amundi Index Solutions - Amundi MSCI USA ESG Universal Select',
    symbol: 'SBIU.DE',
  },
  {
    value: 'LWCE.PA',
    label:
      'LWCE.PA,  Amundi Index Solutions - Amundi MSCI Europe Climate Transition CTB',
    name: 'Amundi Index Solutions - Amundi MSCI Europe Climate Transition CTB',
    symbol: 'LWCE.PA',
  },
  {
    value: 'XMA1.F',
    label: 'XMA1.F,  Xtrackers MSCI EM Asia ESG Screened Swap UCITS ETF',
    name: 'Xtrackers MSCI EM Asia ESG Screened Swap UCITS ETF',
    symbol: 'XMA1.F',
  },
  {
    value: 'MEU.PA',
    label: 'MEU.PA,  Lyxor MSCI Europe (DR) UCITS ETF',
    name: 'Lyxor MSCI Europe (DR) UCITS ETF',
    symbol: 'MEU.PA',
  },
  {
    value: 'LYLEM.SW',
    label: 'LYLEM.SW,  Lyxor MSCI Emerging Markets UCITS ETF',
    name: 'Lyxor MSCI Emerging Markets UCITS ETF',
    symbol: 'LYLEM.SW',
  },
  {
    value: 'CH5.PA',
    label: 'CH5.PA,  Amundi ETF MSCI Europe Healthcare UCITS ETF',
    name: 'Amundi ETF MSCI Europe Healthcare UCITS ETF',
    symbol: 'CH5.PA',
  },
  {
    value: 'IUME.AS',
    label: 'IUME.AS,  iShares MSCI USA Momentum Factor ESG UCITS ETF',
    name: 'iShares MSCI USA Momentum Factor ESG UCITS ETF',
    symbol: 'IUME.AS',
  },
  {
    value: 'IUVE.AS',
    label: 'IUVE.AS,  iShares MSCI USA Value Factor ESG UCITS ETF',
    name: 'iShares MSCI USA Value Factor ESG UCITS ETF',
    symbol: 'IUVE.AS',
  },
  {
    value: 'IEMM.AS',
    label: 'IEMM.AS,  iShares MSCI EM UCITS ETF USD (Dist)',
    name: 'iShares MSCI EM UCITS ETF USD (Dist)',
    symbol: 'IEMM.AS',
  },
  {
    value: 'IDKO.L',
    label: 'IDKO.L,  iShares MSCI Korea UCITS ETF (Dist)',
    name: 'iShares MSCI Korea UCITS ETF (Dist)',
    symbol: 'IDKO.L',
  },
  {
    value: 'INAA.SW',
    label: 'INAA.SW,  iShares MSCI North America UCITS ETF',
    name: 'iShares MSCI North America UCITS ETF',
    symbol: 'INAA.SW',
  },
  {
    value: 'IMEU.AS',
    label: 'IMEU.AS,  iShares Core MSCI Europe UCITS ETF EUR (Dist)',
    name: 'iShares Core MSCI Europe UCITS ETF EUR (Dist)',
    symbol: 'IMEU.AS',
  },
  {
    value: 'ISDE.L',
    label: 'ISDE.L,  iShares MSCI Emerging Markets Islamic UCITS ETF',
    name: 'iShares MSCI Emerging Markets Islamic UCITS ETF',
    symbol: 'ISDE.L',
  },
  {
    value: 'ISJP.L',
    label: 'ISJP.L,  iShares MSCI Japan Small Cap UCITS ETF USD (Dist)',
    name: 'iShares MSCI Japan Small Cap UCITS ETF USD (Dist)',
    symbol: 'ISJP.L',
  },
  {
    value: 'IJPE.L',
    label:
      'IJPE.L,  iShares V PLC - iShares MSCI Japan EUR Hedged UCITS ETF (Acc)',
    name: 'iShares V PLC - iShares MSCI Japan EUR Hedged UCITS ETF (Acc)',
    symbol: 'IJPE.L',
  },
  {
    value: 'IJPA.AS',
    label: 'IJPA.AS,  iShares Core MSCI Japan IMI UCITS ETF USD (Acc)',
    name: 'iShares Core MSCI Japan IMI UCITS ETF USD (Acc)',
    symbol: 'IJPA.AS',
  },
  {
    value: 'SPOL.L',
    label: 'SPOL.L,  iShares V PLC - iShares MSCI Poland UCITS ETF',
    name: 'iShares V PLC - iShares MSCI Poland UCITS ETF',
    symbol: 'SPOL.L',
  },
  {
    value: 'IPXJ.L',
    label: 'IPXJ.L,  iShares MSCI Pacific ex-Japan UCITS ETF USD (Dist)',
    name: 'iShares MSCI Pacific ex-Japan UCITS ETF USD (Dist)',
    symbol: 'IPXJ.L',
  },
  {
    value: 'CSCA.SW',
    label: 'CSCA.SW,  iShares VII PLC - iShares MSCI Canada UCITS ETF',
    name: 'iShares VII PLC - iShares MSCI Canada UCITS ETF',
    symbol: 'CSCA.SW',
  },
  {
    value: 'IESE.SW',
    label: 'IESE.SW,  iShares MSCI Europe SRI UCITS ETF EUR (Acc)',
    name: 'iShares MSCI Europe SRI UCITS ETF EUR (Acc)',
    symbol: 'IESE.SW',
  },
  {
    value: 'XUIN.F',
    label: 'XUIN.F,  Xtrackers MSCI USA Industrials UCITS ETF',
    name: 'Xtrackers MSCI USA Industrials UCITS ETF',
    symbol: 'XUIN.F',
  },
  {
    value: 'SJPD.AS',
    label: 'SJPD.AS,  iShares Core MSCI Japan IMI UCITS ETF',
    name: 'iShares Core MSCI Japan IMI UCITS ETF',
    symbol: 'SJPD.AS',
  },
  {
    value: 'SEMD.AS',
    label: 'SEMD.AS,  iShares MSCI EM SRI UCITS ETF',
    name: 'iShares MSCI EM SRI UCITS ETF',
    symbol: 'SEMD.AS',
  },
  {
    value: 'XRSM.DE',
    label: 'XRSM.DE,  Xtrackers MSCI USA ESG Screened UCITS ETF',
    name: 'Xtrackers MSCI USA ESG Screened UCITS ETF',
    symbol: 'XRSM.DE',
  },
  {
    value: 'QDIV.L',
    label: 'QDIV.L,  iShares MSCI USA Quality Dividend ESG UCITS ETF',
    name: 'iShares MSCI USA Quality Dividend ESG UCITS ETF',
    symbol: 'QDIV.L',
  },
  {
    value: 'MVEE.F',
    label:
      'MVEE.F,  iShares Edge MSCI Europe Minimum Volatility ESG UCITS ETF EUR (Acc)',
    name: 'iShares Edge MSCI Europe Minimum Volatility ESG UCITS ETF EUR (Acc)',
    symbol: 'MVEE.F',
  },
  {
    value: 'XDNY.F',
    label:
      'XDNY.F,  Xtrackers (IE) Plc - Xtrackers JPX-Nikkei 400 UCITS ETF 1D',
    name: 'Xtrackers (IE) Plc - Xtrackers JPX-Nikkei 400 UCITS ETF 1D',
    symbol: 'XDNY.F',
  },
  {
    value: 'UKRE.L',
    label: 'UKRE.L,  iShares MSCI Target UK Real Estate UCITS ETF GBP (Dist)',
    name: 'iShares MSCI Target UK Real Estate UCITS ETF GBP (Dist)',
    symbol: 'UKRE.L',
  },
  {
    value: 'XMME.L',
    label: 'XMME.L,  Xtrackers MSCI Emerging Markets UCITS ETF',
    name: 'Xtrackers MSCI Emerging Markets UCITS ETF',
    symbol: 'XMME.L',
  },
  {
    value: 'QDVN.F',
    label: 'QDVN.F,  iShares MSCI Japan SRI EUR Hedged UCITS ETF',
    name: 'iShares MSCI Japan SRI EUR Hedged UCITS ETF',
    symbol: 'QDVN.F',
  },
  {
    value: 'XASX.L',
    label: 'XASX.L,  Xtrackers MSCI UK ESG UCITS ETF',
    name: 'Xtrackers MSCI UK ESG UCITS ETF',
    symbol: 'XASX.L',
  },
  {
    value: 'XSER.L',
    label: 'XSER.L,  Xtrackers MSCI Europe Energy ESG Screened UCITS ETF',
    name: 'Xtrackers MSCI Europe Energy ESG Screened UCITS ETF',
    symbol: 'XSER.L',
  },
  {
    value: 'XSNR.L',
    label: 'XSNR.L,  Xtrackers MSCI Europe Industrial ESG Screened UCITS ETF',
    name: 'Xtrackers MSCI Europe Industrial ESG Screened UCITS ETF',
    symbol: 'XSNR.L',
  },
  {
    value: 'XMLA.L',
    label: 'XMLA.L,  Xtrackers MSCI EM Latin America ESG Swap UCITS ETF',
    name: 'Xtrackers MSCI EM Latin America ESG Swap UCITS ETF',
    symbol: 'XMLA.L',
  },
  {
    value: 'XAXJ.L',
    label: 'XAXJ.L,  Xtrackers MSCI AC Asia ex Japan ESG Swap UCITS ETF',
    name: 'Xtrackers MSCI AC Asia ex Japan ESG Swap UCITS ETF',
    symbol: 'XAXJ.L',
  },
  {
    value: 'X014.DE',
    label: 'X014.DE,  Lyxor MSCI Pacific UCITS ETF',
    name: 'Lyxor MSCI Pacific UCITS ETF',
    symbol: 'X014.DE',
  },
  {
    value: 'X026.DE',
    label: 'X026.DE,  Lyxor MSCI Europe Small Cap UCITS ETF',
    name: 'Lyxor MSCI Europe Small Cap UCITS ETF',
    symbol: 'X026.DE',
  },
  {
    value: 'KJ7.SI',
    label: 'KJ7.SI,  Xtrackers MSCI Indonesia Swap UCITS ETF',
    name: 'Xtrackers MSCI Indonesia Swap UCITS ETF',
    symbol: 'KJ7.SI',
  },
  {
    value: 'XCS3.L',
    label: 'XCS3.L,  Xtrackers MSCI Malaysia UCITS ETF',
    name: 'Xtrackers MSCI Malaysia UCITS ETF',
    symbol: 'XCS3.L',
  },
  {
    value: 'NRAM.PA',
    label: 'NRAM.PA,  Amundi Index Solutions - Amundi Index MSCI North America',
    name: 'Amundi Index Solutions - Amundi Index MSCI North America',
    symbol: 'NRAM.PA',
  },
  {
    value: 'CEM.PA',
    label:
      'CEM.PA,  Amundi Index Solutions - Amundi MSCI Europe Low Size Factor',
    name: 'Amundi Index Solutions - Amundi MSCI Europe Low Size Factor',
    symbol: 'CEM.PA',
  },
  {
    value: 'CE9USD.SW',
    label:
      'CE9USD.SW,  Amundi Index Solutions - Amundi MSCI Eastern Europe Ex Russia',
    name: 'Amundi Index Solutions - Amundi MSCI Eastern Europe Ex Russia',
    symbol: 'CE9USD.SW',
  },
  {
    value: 'AASI.PA',
    label: 'AASI.PA,  Amundi Index Solutions - Amundi MSCI Em Asia',
    name: 'Amundi Index Solutions - Amundi MSCI Em Asia',
    symbol: 'AASI.PA',
  },
  {
    value: 'CSWG.L',
    label: 'CSWG.L,  Amundi Index Solutions - Amundi MSCI Switzerland',
    name: 'Amundi Index Solutions - Amundi MSCI Switzerland',
    symbol: 'CSWG.L',
  },
  {
    value: 'EUSRI.PA',
    label:
      'EUSRI.PA,  Amundi Index Solutions - Amundi MSCI Europe SRI UCITS ETF DR',
    name: 'Amundi Index Solutions - Amundi MSCI Europe SRI UCITS ETF DR',
    symbol: 'EUSRI.PA',
  },
  {
    value: 'CHIP.SW',
    label: 'CHIP.SW,  Lyxor MSCI Taiwan UCITS ETF - Acc-EUR',
    name: 'Lyxor MSCI Taiwan UCITS ETF - Acc-EUR',
    symbol: 'CHIP.SW',
  },
  {
    value: 'LYLTM.SW',
    label: 'LYLTM.SW,  Lyxor MSCI EM Latin America UCITS ETF',
    name: 'Lyxor MSCI EM Latin America UCITS ETF',
    symbol: 'LYLTM.SW',
  },
  {
    value: 'SBIE.F',
    label:
      'SBIE.F,  Amundi Index Solutions - Amundi MSCI Europe ESG Universal Select',
    name: 'Amundi Index Solutions - Amundi MSCI Europe ESG Universal Select',
    symbol: 'SBIE.F',
  },
  {
    value: 'USA.PA',
    label: 'USA.PA,  Lyxor MSCI USA ESG Broad CTB (DR) UCITS ETF',
    name: 'Lyxor MSCI USA ESG Broad CTB (DR) UCITS ETF',
    symbol: 'USA.PA',
  },
  {
    value: 'GRE.PA',
    label: 'GRE.PA,  Lyxor MSCI Greece UCITS ETF',
    name: 'Lyxor MSCI Greece UCITS ETF',
    symbol: 'GRE.PA',
  },
  {
    value: 'CD8.PA',
    label: 'CD8.PA,  Amundi ETF MSCI EMU High Dividend UCITS ETF',
    name: 'Amundi ETF MSCI EMU High Dividend UCITS ETF',
    symbol: 'CD8.PA',
  },
  {
    value: 'EPAD.AS',
    label: 'EPAD.AS,  iShares MSCI Europe Paris-Aligned Climate UCITS ETF',
    name: 'iShares MSCI Europe Paris-Aligned Climate UCITS ETF',
    symbol: 'EPAD.AS',
  },
  {
    value: 'IBZL.L',
    label: 'IBZL.L,  iShares MSCI Brazil UCITS ETF USD (Dist)',
    name: 'iShares MSCI Brazil UCITS ETF USD (Dist)',
    symbol: 'IBZL.L',
  },
  {
    value: 'ISUS.L',
    label: 'ISUS.L,  iShares MSCI USA Islamic UCITS ETF',
    name: 'iShares MSCI USA Islamic UCITS ETF',
    symbol: 'ISUS.L',
  },
  {
    value: 'IEMS.L',
    label: 'IEMS.L,  iShares MSCI EM Small Cap UCITS ETF USD (Dist)',
    name: 'iShares MSCI EM Small Cap UCITS ETF USD (Dist)',
    symbol: 'IEMS.L',
  },
  {
    value: 'CSUK.SW',
    label: 'CSUK.SW,  iShares VII PLC - iShares MSCI UK UCITS ETF GBP (Acc)',
    name: 'iShares VII PLC - iShares MSCI UK UCITS ETF GBP (Acc)',
    symbol: 'CSUK.SW',
  },
  {
    value: 'CSJP.SW',
    label: 'CSJP.SW,  iShares VII PLC - iShares MSCI Japan UCITS ETF USD (Acc)',
    name: 'iShares VII PLC - iShares MSCI Japan UCITS ETF USD (Acc)',
    symbol: 'CSJP.SW',
  },
  {
    value: 'CSKR.SW',
    label: 'CSKR.SW,  iShares VII PLC - iShares MSCI Korea UCITS ETF USD (Acc)',
    name: 'iShares VII PLC - iShares MSCI Korea UCITS ETF USD (Acc)',
    symbol: 'CSKR.SW',
  },
  {
    value: 'XUHC.L',
    label: 'XUHC.L,  Xtrackers MSCI USA Health Care UCITS ETF',
    name: 'Xtrackers MSCI USA Health Care UCITS ETF',
    symbol: 'XUHC.L',
  },
  {
    value: 'IUSZ.L',
    label: 'IUSZ.L,  iShares Edge MSCI USA Size Factor UCITS ETF',
    name: 'iShares Edge MSCI USA Size Factor UCITS ETF',
    symbol: 'IUSZ.L',
  },
  {
    value: 'JTKWY',
    label: 'JTKWY,  Just Eat Takeaway.com N.V.',
    name: 'Just Eat Takeaway.com N.V.',
    symbol: 'JTKWY',
  },
  {
    value: 'KKS.F',
    label: 'KKS.F,  Joint Stock Company Kaspi.kz',
    name: 'Joint Stock Company Kaspi.kz',
    symbol: 'KKS.F',
  },
  {
    value: '0XXV.IL',
    label: '0XXV.IL,  Atlas Copco AB',
    name: 'Atlas Copco AB',
    symbol: '0XXV.IL',
  },
  {
    value: 'VIVE.ST',
    label: 'VIVE.ST,  Vivesto AB',
    name: 'Vivesto AB',
    symbol: 'VIVE.ST',
  },
  {
    value: 'NICA.ST',
    label: 'NICA.ST,  Nanologica AB (publ)',
    name: 'Nanologica AB (publ)',
    symbol: 'NICA.ST',
  },
  {
    value: 'UIJ.MU',
    label: 'UIJ.MU,  AS VEF',
    name: 'AS VEF',
    symbol: 'UIJ.MU',
  },
  {
    value: 'UOM.MU',
    label: 'UOM.MU,  AS Latvijas Juras medicinas centrs',
    name: 'AS Latvijas Juras medicinas centrs',
    symbol: 'UOM.MU',
  },
  {
    value: 'ENUA.F',
    label: 'ENUA.F,  Stora Enso Oyj',
    name: 'Stora Enso Oyj',
    symbol: 'ENUA.F',
  },
  {
    value: 'WITH.HE',
    label: 'WITH.HE,  WithSecure Oyj',
    name: 'WithSecure Oyj',
    symbol: 'WITH.HE',
  },
  {
    value: 'ISB.IC',
    label: 'ISB.IC,  Íslandsbanki hf.',
    name: 'Íslandsbanki hf.',
    symbol: 'ISB.IC',
  },
  {
    value: 'BRIM.IC',
    label: 'BRIM.IC,  Brim hf.',
    name: 'Brim hf.',
    symbol: 'BRIM.IC',
  },
  {
    value: 'EIK.IC',
    label: 'EIK.IC,  Eik fasteignafélag hf.',
    name: 'Eik fasteignafélag hf.',
    symbol: 'EIK.IC',
  },
  {
    value: 'EIM.IC',
    label: 'EIM.IC,  Eimskipafélag Íslands hf.',
    name: 'Eimskipafélag Íslands hf.',
    symbol: 'EIM.IC',
  },
  {
    value: 'HAGA.IC',
    label: 'HAGA.IC,  Hagar hf',
    name: 'Hagar hf',
    symbol: 'HAGA.IC',
  },
  {
    value: 'SVN.IC',
    label: 'SVN.IC,  Sildarvinnslan Hf.',
    name: 'Sildarvinnslan Hf.',
    symbol: 'SVN.IC',
  },
  {
    value: 'VIS.IC',
    label: 'VIS.IC,  Vátryggingafélag Íslands hf.',
    name: 'Vátryggingafélag Íslands hf.',
    symbol: 'VIS.IC',
  },
  {
    value: 'NOVA.IC',
    label: 'NOVA.IC,  Nova Klúbburinn hf.',
    name: 'Nova Klúbburinn hf.',
    symbol: 'NOVA.IC',
  },
  {
    value: 'SYN.IC',
    label: 'SYN.IC,  Sýn hf.',
    name: 'Sýn hf.',
    symbol: 'SYN.IC',
  },
  {
    value: '4C.ST',
    label: '4C.ST,  4C Group AB (publ)',
    name: '4C Group AB (publ)',
    symbol: '4C.ST',
  },
  {
    value: 'CX.ST',
    label: 'CX.ST,  CombinedX AB (publ)',
    name: 'CombinedX AB (publ)',
    symbol: 'CX.ST',
  },
  {
    value: 'VIVA.ST',
    label: 'VIVA.ST,  Viva Wine Group AB',
    name: 'Viva Wine Group AB',
    symbol: 'VIVA.ST',
  },
  {
    value: 'RELE.CO',
    label: 'RELE.CO,  Relesys A/S',
    name: 'Relesys A/S',
    symbol: 'RELE.CO',
  },
  {
    value: 'SAME.CO',
    label: 'SAME.CO,  Samesystem A/S',
    name: 'Samesystem A/S',
    symbol: 'SAME.CO',
  },
  {
    value: 'ADTR.ST',
    label: 'ADTR.ST,  Adtraction Group AB',
    name: 'Adtraction Group AB',
    symbol: 'ADTR.ST',
  },
  {
    value: 'BOAT.ST',
    label: 'BOAT.ST,  Nimbus Group AB (Publ)',
    name: 'Nimbus Group AB (Publ)',
    symbol: 'BOAT.ST',
  },
  {
    value: 'BUY.ST',
    label: 'BUY.ST,  Sweden Buyersclub AB',
    name: 'Sweden Buyersclub AB',
    symbol: 'BUY.ST',
  },
  {
    value: 'CASE.ST',
    label: 'CASE.ST,  Case Group AB (publ)',
    name: 'Case Group AB (publ)',
    symbol: 'CASE.ST',
  },
  {
    value: 'CI.ST',
    label: 'CI.ST,  Cell Impact AB (publ)',
    name: 'Cell Impact AB (publ)',
    symbol: 'CI.ST',
  },
  {
    value: 'CMH.ST',
    label: 'CMH.ST,  Chordate Medical Holding AB (publ)',
    name: 'Chordate Medical Holding AB (publ)',
    symbol: 'CMH.ST',
  },
  {
    value: 'DE.ST',
    label: 'DE.ST,  Dala Energi AB (publ)',
    name: 'Dala Energi AB (publ)',
    symbol: 'DE.ST',
  },
  {
    value: 'JOBS.ST',
    label: 'JOBS.ST,  Job Solution Sweden Holding AB (publ)',
    name: 'Job Solution Sweden Holding AB (publ)',
    symbol: 'JOBS.ST',
  },
  {
    value: 'L2S.ST',
    label: 'L2S.ST,  Learning 2 Sleep L2S AB (publ)',
    name: 'Learning 2 Sleep L2S AB (publ)',
    symbol: 'L2S.ST',
  },
  {
    value: 'MOV.ST',
    label: 'MOV.ST,  Move About Group AB (publ)',
    name: 'Move About Group AB (publ)',
    symbol: 'MOV.ST',
  },
  {
    value: 'MTI.ST',
    label: 'MTI.ST,  MTI Investment SE',
    name: 'MTI Investment SE',
    symbol: 'MTI.ST',
  },
  {
    value: 'NEOD.ST',
    label: 'NEOD.ST,  NeoDynamics AB (publ)',
    name: 'NeoDynamics AB (publ)',
    symbol: 'NEOD.ST',
  },
  {
    value: 'NG.ST',
    label: 'NG.ST,  Northgold AB (publ)',
    name: 'Northgold AB (publ)',
    symbol: 'NG.ST',
  },
  {
    value: 'PRO.ST',
    label: 'PRO.ST,  Promimic AB (publ)',
    name: 'Promimic AB (publ)',
    symbol: 'PRO.ST',
  },
  {
    value: 'PURE.ST',
    label: 'PURE.ST,  Purefun Group AB (publ)',
    name: 'Purefun Group AB (publ)',
    symbol: 'PURE.ST',
  },
  {
    value: 'SFTR.ST',
    label: 'SFTR.ST,  Safeture AB (publ)',
    name: 'Safeture AB (publ)',
    symbol: 'SFTR.ST',
  },
  {
    value: 'SNX.ST',
    label: 'SNX.ST,  Premium Snacks Nordic AB (publ)',
    name: 'Premium Snacks Nordic AB (publ)',
    symbol: 'SNX.ST',
  },
  {
    value: 'VH.ST',
    label: 'VH.ST,  Västra Hamnen Corporate Finance AB (publ)',
    name: 'Västra Hamnen Corporate Finance AB (publ)',
    symbol: 'VH.ST',
  },
  {
    value: 'W5.ST',
    label: 'W5.ST,  W5 Solutions AB (publ)',
    name: 'W5 Solutions AB (publ)',
    symbol: 'W5.ST',
  },
  {
    value: 'COPY.CO',
    label: 'COPY.CO,  Copyright Agent A/S',
    name: 'Copyright Agent A/S',
    symbol: 'COPY.CO',
  },
  {
    value: 'DAC.CO',
    label: 'DAC.CO,  Danish Aerospace Company A/S',
    name: 'Danish Aerospace Company A/S',
    symbol: 'DAC.CO',
  },
  {
    value: 'FOM.CO',
    label: 'FOM.CO,  FOM Technologies A/S',
    name: 'FOM Technologies A/S',
    symbol: 'FOM.CO',
  },
  {
    value: 'HOVE.CO',
    label: 'HOVE.CO,  Hove A/S',
    name: 'Hove A/S',
    symbol: 'HOVE.CO',
  },
  {
    value: 'NORD.CO',
    label: 'NORD.CO,  NORD.investments Fondsmæglerselskab A/S',
    name: 'NORD.investments Fondsmæglerselskab A/S',
    symbol: 'NORD.CO',
  },
  {
    value: 'VIRO.CO',
    label: 'VIRO.CO,  ViroGates A/S',
    name: 'ViroGates A/S',
    symbol: 'VIRO.CO',
  },
  {
    value: 'WMA.CO',
    label: 'WMA.CO,  WindowMaster International A/S',
    name: 'WindowMaster International A/S',
    symbol: 'WMA.CO',
  },
  {
    value: 'YOYO.CO',
    label: 'YOYO.CO,  OrderYOYO A/S',
    name: 'OrderYOYO A/S',
    symbol: 'YOYO.CO',
  },
  {
    value: 'ALEX.HE',
    label: 'ALEX.HE,  Alexandria Pankkiiriliike Oyj',
    name: 'Alexandria Pankkiiriliike Oyj',
    symbol: 'ALEX.HE',
  },
  {
    value: 'DWF.HE',
    label: 'DWF.HE,  Digital Workforce Services Oyj',
    name: 'Digital Workforce Services Oyj',
    symbol: 'DWF.HE',
  },
  {
    value: 'LIFA.HE',
    label: 'LIFA.HE,  Lifa Air Oyj',
    name: 'Lifa Air Oyj',
    symbol: 'LIFA.HE',
  },
  {
    value: 'RUSH.HE',
    label: 'RUSH.HE,  Rush Factory Oyj',
    name: 'Rush Factory Oyj',
    symbol: 'RUSH.HE',
  },
  {
    value: 'HAMP.IC',
    label: 'HAMP.IC,  Hampidjan Hf.',
    name: 'Hampidjan Hf.',
    symbol: 'HAMP.IC',
  },
  {
    value: 'KALD.IC',
    label: 'KALD.IC,  Kaldalón hf.',
    name: 'Kaldalón hf.',
    symbol: 'KALD.IC',
  },
  {
    value: 'PLAY.IC',
    label: 'PLAY.IC,  Fly Play hf.',
    name: 'Fly Play hf.',
    symbol: 'PLAY.IC',
  },
  {
    value: 'ONEF.ST',
    label: 'ONEF.ST,  Oneflow AB (publ)',
    name: 'Oneflow AB (publ)',
    symbol: 'ONEF.ST',
  },
  {
    value: 'PACWP',
    label: 'PACWP,  PacWest Bancorp',
    name: 'PacWest Bancorp',
    symbol: 'PACWP',
  },
  {
    value: 'CTA',
    label: 'CTA,  Simplify Managed Futures Strategy ETF',
    name: 'Simplify Managed Futures Strategy ETF',
    symbol: 'CTA',
  },
  {
    value: 'STRY',
    label: 'STRY,  Starry Group Holdings, Inc.',
    name: 'Starry Group Holdings, Inc.',
    symbol: 'STRY',
  },
  {
    value: 'RES.JO',
    label: 'RES.JO,  Resilient REIT Limited',
    name: 'Resilient REIT Limited',
    symbol: 'RES.JO',
  },
  {
    value: 'IWLE.DE',
    label: 'IWLE.DE,  iShares Core MSCI World UCITS ETF',
    name: 'iShares Core MSCI World UCITS ETF',
    symbol: 'IWLE.DE',
  },
  {
    value: 'MVEW.DE',
    label:
      'MVEW.DE,  iShares Edge MSCI World Minimum Volatility ESG UCITS ETF USD (Acc)',
    name: 'iShares Edge MSCI World Minimum Volatility ESG UCITS ETF USD (Acc)',
    symbol: 'MVEW.DE',
  },
  {
    value: 'NQSE.DE',
    label: 'NQSE.DE,  iShares VII PLC - iShares NASDAQ 100 UCITS ETF',
    name: 'iShares VII PLC - iShares NASDAQ 100 UCITS ETF',
    symbol: 'NQSE.DE',
  },
  {
    value: 'SBIW.F',
    label:
      'SBIW.F,  Amundi Index Solutions - Amundi MSCI World ESG Universal Select',
    name: 'Amundi Index Solutions - Amundi MSCI World ESG Universal Select',
    symbol: 'SBIW.F',
  },
  {
    value: 'BUNH.DE',
    label: 'BUNH.DE,  Lyxor Nasdaq-100 Ucits ETF',
    name: 'Lyxor Nasdaq-100 Ucits ETF',
    symbol: 'BUNH.DE',
  },
  {
    value: 'CBUC.F',
    label: 'CBUC.F,  iShares MSCI USA ESG Enhanced UCITS ETF',
    name: 'iShares MSCI USA ESG Enhanced UCITS ETF',
    symbol: 'CBUC.F',
  },
  {
    value: 'UKPH.DE',
    label: 'UKPH.DE,  iShares UK Property UCITS ETF',
    name: 'iShares UK Property UCITS ETF',
    symbol: 'UKPH.DE',
  },
  {
    value: 'XMAE.F',
    label: 'XMAE.F,  Xtrackers MSCI AC World ESG Screened UCITS ETF',
    name: 'Xtrackers MSCI AC World ESG Screened UCITS ETF',
    symbol: 'XMAE.F',
  },
  {
    value: 'TELE.PA',
    label:
      'TELE.PA,  Lyxor Index Fund - Lyxor Stoxx Europe 600 Telecommunications UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor Stoxx Europe 600 Telecommunications UCITS ETF',
    symbol: 'TELE.PA',
  },
  {
    value: 'BNK.PA',
    label:
      'BNK.PA,  Lyxor Index Fund - Lyxor Stoxx Europe 600 Banks UCITS ETF Acc',
    name: 'Lyxor Index Fund - Lyxor Stoxx Europe 600 Banks UCITS ETF Acc',
    symbol: 'BNK.PA',
  },
  {
    value: 'UTI.MI',
    label:
      'UTI.MI,  Lyxor Index Fund - Lyxor Stoxx Europe 600 Utilities UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor Stoxx Europe 600 Utilities UCITS ETF',
    symbol: 'UTI.MI',
  },
  {
    value: '3BAL.MI',
    label: '3BAL.MI,  WisdomTree EURO STOXX Banks 3x Daily Leveraged',
    name: 'WisdomTree EURO STOXX Banks 3x Daily Leveraged',
    symbol: '3BAL.MI',
  },
  {
    value: 'XSXE.DE',
    label: 'XSXE.DE,  Xtrackers Stoxx Europe 600 UCITS ETF',
    name: 'Xtrackers Stoxx Europe 600 UCITS ETF',
    symbol: 'XSXE.DE',
  },
  {
    value: 'LFOD.DE',
    label:
      'LFOD.DE,  Lyxor Index Fund - Lyxor Stoxx Europe 600 Food & Beverage UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor Stoxx Europe 600 Food & Beverage UCITS ETF',
    symbol: 'LFOD.DE',
  },
  {
    value: 'TNO.MI',
    label:
      'TNO.MI,  Lyxor Index Fund - Lyxor Stoxx Europe 600 Technology UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor Stoxx Europe 600 Technology UCITS ETF',
    symbol: 'TNO.MI',
  },
  {
    value: 'C071.DE',
    label:
      'C071.DE,  Lyxor Index Fund - Lyxor STOXX Europe 600 Media UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor STOXX Europe 600 Media UCITS ETF',
    symbol: 'C071.DE',
  },
  {
    value: 'HLT.PA',
    label:
      'HLT.PA,  Lyxor Index Fund - Lyxor Stoxx Europe 600 Healthcare UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor Stoxx Europe 600 Healthcare UCITS ETF',
    symbol: 'HLT.PA',
  },
  {
    value: 'DECD.F',
    label: 'DECD.F,  Amundi DAX 50 ESG',
    name: 'Amundi DAX 50 ESG',
    symbol: 'DECD.F',
  },
  {
    value: 'EXIB.DE',
    label: 'EXIB.DE,  iShares TecDAX UCITS ETF (DE)',
    name: 'iShares TecDAX UCITS ETF (DE)',
    symbol: 'EXIB.DE',
  },
  {
    value: 'E909.DE',
    label: 'E909.DE,  Lyxor 1 DAX 50 ESG (DR) UCITS ETF',
    name: 'Lyxor 1 DAX 50 ESG (DR) UCITS ETF',
    symbol: 'E909.DE',
  },
  {
    value: 'EXIC.F',
    label: 'EXIC.F,  iShares Core DAX UCITS ETF (DE)',
    name: 'iShares Core DAX UCITS ETF (DE)',
    symbol: 'EXIC.F',
  },
  {
    value: 'EXIA.F',
    label: 'EXIA.F,  iShares DAX ESG UCITS ETF (DE)',
    name: 'iShares DAX ESG UCITS ETF (DE)',
    symbol: 'EXIA.F',
  },
  {
    value: 'EXID.F',
    label: 'EXID.F,  iShares MDAX UCITS ETF (DE)',
    name: 'iShares MDAX UCITS ETF (DE)',
    symbol: 'EXID.F',
  },
  {
    value: 'DFE.L',
    label: 'DFE.L,  WisdomTree Europe SmallCap Dividend UCITS ETF',
    name: 'WisdomTree Europe SmallCap Dividend UCITS ETF',
    symbol: 'DFE.L',
  },
  {
    value: 'DHS.L',
    label: 'DHS.L,  WisdomTree US Equity Income UCITS ETF',
    name: 'WisdomTree US Equity Income UCITS ETF',
    symbol: 'DHS.L',
  },
  {
    value: 'DXJF.MI',
    label: 'DXJF.MI,  WisdomTree Japan Equity UCITS ETF - EUR Hedged Acc',
    name: 'WisdomTree Japan Equity UCITS ETF - EUR Hedged Acc',
    symbol: 'DXJF.MI',
  },
  {
    value: 'EGRA.MI',
    label: 'EGRA.MI,  WisdomTree Eurozone Quality Dividend Growth UCITS ETF',
    name: 'WisdomTree Eurozone Quality Dividend Growth UCITS ETF',
    symbol: 'EGRA.MI',
  },
  {
    value: 'DFEP.L',
    label: 'DFEP.L,  WisdomTree Europe SmallCap Dividend UCITS ETF',
    name: 'WisdomTree Europe SmallCap Dividend UCITS ETF',
    symbol: 'DFEP.L',
  },
  {
    value: 'DHSF.MI',
    label: 'DHSF.MI,  WisdomTree US Equity Income UCITS ETF',
    name: 'WisdomTree US Equity Income UCITS ETF',
    symbol: 'DHSF.MI',
  },
  {
    value: 'ETZD.PA',
    label: 'ETZD.PA,  BNP Paribas Easy Stoxx Europe 600 UCITS ETF EUR C/D',
    name: 'BNP Paribas Easy Stoxx Europe 600 UCITS ETF EUR C/D',
    symbol: 'ETZD.PA',
  },
  {
    value: 'FLXP.F',
    label: 'FLXP.F,  Franklin STOXX Europe 600 Paris Aligned Climate UCITS ETF',
    name: 'Franklin STOXX Europe 600 Paris Aligned Climate UCITS ETF',
    symbol: 'FLXP.F',
  },
  {
    value: 'BTCW.SW',
    label: 'BTCW.SW,  WisdomTree Bitcoin',
    name: 'WisdomTree Bitcoin',
    symbol: 'BTCW.SW',
  },
  {
    value: 'ETHW.SW',
    label: 'ETHW.SW,  WisdomTree Ethereum ETC',
    name: 'WisdomTree Ethereum ETC',
    symbol: 'ETHW.SW',
  },
  {
    value: '36BB.F',
    label:
      '36BB.F,  iShares MSCI World Consumer Discretionary Sector UCITS ETF',
    name: 'iShares MSCI World Consumer Discretionary Sector UCITS ETF',
    symbol: '36BB.F',
  },
  {
    value: 'UEEH.F',
    label:
      'UEEH.F,  iShares Edge MSCI World Minimum Volatility UCITS ETF USD (Dist)',
    name: 'iShares Edge MSCI World Minimum Volatility UCITS ETF USD (Dist)',
    symbol: 'UEEH.F',
  },
  {
    value: 'SESW.SW',
    label: 'SESW.SW,  iShares MSCI World SRI UCITS ETF',
    name: 'iShares MSCI World SRI UCITS ETF',
    symbol: 'SESW.SW',
  },
  {
    value: 'MWSH.DE',
    label: 'MWSH.DE,  Amundi Index Solutions - Amundi Index MSCI World SRI PAB',
    name: 'Amundi Index Solutions - Amundi Index MSCI World SRI PAB',
    symbol: 'MWSH.DE',
  },
  {
    value: 'SUWS.L',
    label: 'SUWS.L,  iShares MSCI World SRI UCITS ETF',
    name: 'iShares MSCI World SRI UCITS ETF',
    symbol: 'SUWS.L',
  },
  {
    value: 'XWD1.F',
    label: 'XWD1.F,  Xtrackers MSCI World Swap UCITS ETF',
    name: 'Xtrackers MSCI World Swap UCITS ETF',
    symbol: 'XWD1.F',
  },
  {
    value: '5MVW.F',
    label: '5MVW.F,  iShares MSCI World Energy Sector UCITS ETF',
    name: 'iShares MSCI World Energy Sector UCITS ETF',
    symbol: '5MVW.F',
  },
  {
    value: 'SC0J.DE',
    label: 'SC0J.DE,  Invesco MSCI World UCITS ETF',
    name: 'Invesco MSCI World UCITS ETF',
    symbol: 'SC0J.DE',
  },
  {
    value: 'HMWD.L',
    label: 'HMWD.L,  HSBC MSCI World UCITS ETF',
    name: 'HSBC MSCI World UCITS ETF',
    symbol: 'HMWD.L',
  },
  {
    value: 'EMWE.DE',
    label: 'EMWE.DE,  BNP Paribas Easy MSCI World SRI S-Series PAB 5% Capped',
    name: 'BNP Paribas Easy MSCI World SRI S-Series PAB 5% Capped',
    symbol: 'EMWE.DE',
  },
  {
    value: 'SKYY.L',
    label: 'SKYY.L,  HAN-GINS Cloud Technology Equal Weight UCITS ETF',
    name: 'HAN-GINS Cloud Technology Equal Weight UCITS ETF',
    symbol: 'SKYY.L',
  },
  {
    value: 'SPPW.DE',
    label: 'SPPW.DE,  SPDR MSCI World UCITS ETF',
    name: 'SPDR MSCI World UCITS ETF',
    symbol: 'SPPW.DE',
  },
  {
    value: '4UB1.F',
    label:
      '4UB1.F,  UBS (Irl) ETF plc - MSCI World Socially Responsible UCITS ETF',
    name: 'UBS (Irl) ETF plc - MSCI World Socially Responsible UCITS ETF',
    symbol: '4UB1.F',
  },
  {
    value: 'CQQQ.SW',
    label: 'CQQQ.SW,  UBS ETF - Solactive China Technology UCITS ETF',
    name: 'UBS ETF - Solactive China Technology UCITS ETF',
    symbol: 'CQQQ.SW',
  },
  {
    value: 'CITE.MI',
    label: 'CITE.MI,  UBS ETF - Solactive China Technology UCITS ETF',
    name: 'UBS ETF - Solactive China Technology UCITS ETF',
    symbol: 'CITE.MI',
  },
  {
    value: 'AW10.F',
    label:
      'AW10.F,  UBS (Irl) ETF plc - MSCI World Climate Paris Aligned UCITS ETF',
    name: 'UBS (Irl) ETF plc - MSCI World Climate Paris Aligned UCITS ETF',
    symbol: 'AW10.F',
  },
  {
    value: 'XS5E.DE',
    label: 'XS5E.DE,  Xtrackers S&P 500 Swap UCITS ETF',
    name: 'Xtrackers S&P 500 Swap UCITS ETF',
    symbol: 'XS5E.DE',
  },
  {
    value: '3USS.MI',
    label: '3USS.MI,  WisdomTree S&P 500 3x Daily Short',
    name: 'WisdomTree S&P 500 3x Daily Short',
    symbol: '3USS.MI',
  },
  {
    value: 'XDPD.DE',
    label: 'XDPD.DE,  Xtrackers S&P 500 UCITS ETF',
    name: 'Xtrackers S&P 500 UCITS ETF',
    symbol: 'XDPD.DE',
  },
  {
    value: 'I500.AS',
    label: 'I500.AS,  iShares S&P 500 Swap UCITS ETF',
    name: 'iShares S&P 500 Swap UCITS ETF',
    symbol: 'I500.AS',
  },
  {
    value: 'PABU.L',
    label:
      'PABU.L,  Lyxor Net Zero 2050 S&P 500 Climate PAB (DR) UCITS ETF - Acc',
    name: 'Lyxor Net Zero 2050 S&P 500 Climate PAB (DR) UCITS ETF - Acc',
    symbol: 'PABU.L',
  },
  {
    value: 'UPAB.AS',
    label: 'UPAB.AS,  iShares S&P 500 Paris-Aligned Climate UCITS ETF',
    name: 'iShares S&P 500 Paris-Aligned Climate UCITS ETF',
    symbol: 'UPAB.AS',
  },
  {
    value: 'DSP5.PA',
    label: 'DSP5.PA,  Lyxor S&P 500 Daily (-2x) Inverse UCITS ETF',
    name: 'Lyxor S&P 500 Daily (-2x) Inverse UCITS ETF',
    symbol: 'DSP5.PA',
  },
  {
    value: 'H50E.L',
    label: 'H50E.L,  HSBC EURO STOXX 50 UCITS ETF',
    name: 'HSBC EURO STOXX 50 UCITS ETF',
    symbol: 'H50E.L',
  },
  {
    value: 'SPXE.MI',
    label: 'SPXE.MI,  SPDR S&P 500 UCITS ETF',
    name: 'SPDR S&P 500 UCITS ETF',
    symbol: 'SPXE.MI',
  },
  {
    value: 'SPXD.SW',
    label: 'SPXD.SW,  Invesco S&P 500 UCITS ETF',
    name: 'Invesco S&P 500 UCITS ETF',
    symbol: 'SPXD.SW',
  },
  {
    value: 'SPXS.L',
    label: 'SPXS.L,  Invesco S&P 500 UCITS ETF',
    name: 'Invesco S&P 500 UCITS ETF',
    symbol: 'SPXS.L',
  },
  {
    value: 'VUAA.L',
    label: 'VUAA.L,  Vanguard S&P 500 UCITS ETF',
    name: 'Vanguard S&P 500 UCITS ETF',
    symbol: 'VUAA.L',
  },
  {
    value: 'D6RP.F',
    label: 'D6RP.F,  Deka MSCI World Climate Change ESG UCITS ETF',
    name: 'Deka MSCI World Climate Change ESG UCITS ETF',
    symbol: 'D6RP.F',
  },
  {
    value: 'HPAO.L',
    label:
      'HPAO.L,  HSBC ETFs PLC - HSBC MSCI World Climate Paris Aligned UCITS ETF',
    name: 'HSBC ETFs PLC - HSBC MSCI World Climate Paris Aligned UCITS ETF',
    symbol: 'HPAO.L',
  },
  {
    value: '5ESE.DE',
    label: '5ESE.DE,  Invesco S&P 500 ESG UCITS ETF',
    name: 'Invesco S&P 500 ESG UCITS ETF',
    symbol: '5ESE.DE',
  },
  {
    value: 'SPED.L',
    label:
      'SPED.L,  Invesco Markets II plc - Invesco S&P 500 Equal Weight UCITS ETF',
    name: 'Invesco Markets II plc - Invesco S&P 500 Equal Weight UCITS ETF',
    symbol: 'SPED.L',
  },
  {
    value: 'SPFW.F',
    label: 'SPFW.F,  SPDR MSCI World Climate Paris Aligned UCITS ETF',
    name: 'SPDR MSCI World Climate Paris Aligned UCITS ETF',
    symbol: 'SPFW.F',
  },
  {
    value: 'XSXD.L',
    label: 'XSXD.L,  Xtrackers S&P 500 Swap UCITS ETF',
    name: 'Xtrackers S&P 500 Swap UCITS ETF',
    symbol: 'XSXD.L',
  },
  {
    value: 'IMIB.MI',
    label: 'IMIB.MI,  iShares FTSE MIB UCITS ETF EUR (Dist)',
    name: 'iShares FTSE MIB UCITS ETF EUR (Dist)',
    symbol: 'IMIB.MI',
  },
  {
    value: 'ECAR.L',
    label:
      'ECAR.L,  iShares Electric Vehicles and Driving Technology UCITS ETF',
    name: 'iShares Electric Vehicles and Driving Technology UCITS ETF',
    symbol: 'ECAR.L',
  },
  {
    value: 'XUEK.L',
    label: 'XUEK.L,  Xtrackers S&P Europe ex UK UCITS ETF',
    name: 'Xtrackers S&P Europe ex UK UCITS ETF',
    symbol: 'XUEK.L',
  },
  {
    value: 'XMOV.SW',
    label: 'XMOV.SW,  Xtrackers Future Mobility UCITS ETF',
    name: 'Xtrackers Future Mobility UCITS ETF',
    symbol: 'XMOV.SW',
  },
  {
    value: 'QDVX.DE',
    label: 'QDVX.DE,  iShares MSCI Europe Quality Dividend ESG UCITS ETF',
    name: 'iShares MSCI Europe Quality Dividend ESG UCITS ETF',
    symbol: 'QDVX.DE',
  },
  {
    value: 'PAXJ.PA',
    label: 'PAXJ.PA,  Lyxor MSCI Pacific Ex Japan UCITS ETF',
    name: 'Lyxor MSCI Pacific Ex Japan UCITS ETF',
    symbol: 'PAXJ.PA',
  },
  {
    value: 'AEME.PA',
    label:
      'AEME.PA,  Amundi Index Solutions - Amundi Index MSCI Emerging Markets UCITS ETF DR',
    name: 'Amundi Index Solutions - Amundi Index MSCI Emerging Markets UCITS ETF DR',
    symbol: 'AEME.PA',
  },
  {
    value: 'SBIU.F',
    label:
      'SBIU.F,  Amundi Index Solutions - Amundi MSCI USA ESG Universal Select',
    name: 'Amundi Index Solutions - Amundi MSCI USA ESG Universal Select',
    symbol: 'SBIU.F',
  },
  {
    value: 'SADE.DE',
    label:
      'SADE.DE,  Amundi Index Solutions - Amundi MSCI Europe ESG Leaders Select',
    name: 'Amundi Index Solutions - Amundi MSCI Europe ESG Leaders Select',
    symbol: 'SADE.DE',
  },
  {
    value: 'PABE.PA',
    label:
      'PABE.PA,  Amundi Index Solutions - Amundi MSCI Europe Climate Paris Aligned Pab',
    name: 'Amundi Index Solutions - Amundi MSCI Europe Climate Paris Aligned Pab',
    symbol: 'PABE.PA',
  },
  {
    value: 'JUPI.DE',
    label: 'JUPI.DE,  Amundi Index Solutions - Amundi MSCI Japan ESG Broad CTB',
    name: 'Amundi Index Solutions - Amundi MSCI Japan ESG Broad CTB',
    symbol: 'JUPI.DE',
  },
  {
    value: 'XCTE.F',
    label: 'XCTE.F,  Xtrackers Harvest MSCI China Tech 100 UCITS ETF',
    name: 'Xtrackers Harvest MSCI China Tech 100 UCITS ETF',
    symbol: 'XCTE.F',
  },
  {
    value: 'CBUD.DE',
    label: 'CBUD.DE,  iShares MSCI Europe SRI UCITS ETF',
    name: 'iShares MSCI Europe SRI UCITS ETF',
    symbol: 'CBUD.DE',
  },
  {
    value: 'XMVU.L',
    label: 'XMVU.L,  Xtrackers MSCI USA Minimum Volatility UCITS ETF',
    name: 'Xtrackers MSCI USA Minimum Volatility UCITS ETF',
    symbol: 'XMVU.L',
  },
  {
    value: 'EDMJ.DE',
    label: 'EDMJ.DE,  iShares MSCI Japan ESG Enhanced UCITS ETF',
    name: 'iShares MSCI Japan ESG Enhanced UCITS ETF',
    symbol: 'EDMJ.DE',
  },
  {
    value: 'EDMU.SW',
    label: 'EDMU.SW,  iShares MSCI USA ESG Enhanced UCITS ETF',
    name: 'iShares MSCI USA ESG Enhanced UCITS ETF',
    symbol: 'EDMU.SW',
  },
  {
    value: 'EUPB.AS',
    label: 'EUPB.AS,  iShares MSCI Europe Paris-Aligned Climate UCITS ETF',
    name: 'iShares MSCI Europe Paris-Aligned Climate UCITS ETF',
    symbol: 'EUPB.AS',
  },
  {
    value: 'XZEC.DE',
    label:
      'XZEC.DE,  Xtrackers MSCI Europe Consumer Discretionary ESG Screened UCITS ETF',
    name: 'Xtrackers MSCI Europe Consumer Discretionary ESG Screened UCITS ETF',
    symbol: 'XZEC.DE',
  },
  {
    value: 'LCCN.L',
    label: 'LCCN.L,  Lyxor MSCI China UCITS ETF',
    name: 'Lyxor MSCI China UCITS ETF',
    symbol: 'LCCN.L',
  },
  {
    value: 'SADU.F',
    label:
      'SADU.F,  Amundi Index Solutions - Amundi MSCI USA ESG Leaders Select Ucits ETF DR Cap',
    name: 'Amundi Index Solutions - Amundi MSCI USA ESG Leaders Select Ucits ETF DR Cap',
    symbol: 'SADU.F',
  },
  {
    value: 'JNHD.F',
    label: 'JNHD.F,  Lyxor Core MSCI Japan (DR) UCITS ETF',
    name: 'Lyxor Core MSCI Japan (DR) UCITS ETF',
    symbol: 'JNHD.F',
  },
  {
    value: 'IBC3.F',
    label: 'IBC3.F,  iShares Core MSCI EM IMI UCITS ETF',
    name: 'iShares Core MSCI EM IMI UCITS ETF',
    symbol: 'IBC3.F',
  },
  {
    value: 'XUFB.L',
    label: 'XUFB.L,  Xtrackers MSCI USA Banks UCITS ETF',
    name: 'Xtrackers MSCI USA Banks UCITS ETF',
    symbol: 'XUFB.L',
  },
  {
    value: 'EUMD.L',
    label: 'EUMD.L,  iShares MSCI Europe Mid Cap UCITS ETF',
    name: 'iShares MSCI Europe Mid Cap UCITS ETF',
    symbol: 'EUMD.L',
  },
  {
    value: 'XZEU.DE',
    label: 'XZEU.DE,  Xtrackers MSCI Europe ESG UCITS ETF',
    name: 'Xtrackers MSCI Europe ESG UCITS ETF',
    symbol: 'XZEU.DE',
  },
  {
    value: 'XD9C.SW',
    label: 'XD9C.SW,  Xtrackers MSCI USA UCITS ETF',
    name: 'Xtrackers MSCI USA UCITS ETF',
    symbol: 'XD9C.SW',
  },
  {
    value: 'EMVL.L',
    label: 'EMVL.L,  iShares Edge MSCI EM Value Factor UCITS ETF',
    name: 'iShares Edge MSCI EM Value Factor UCITS ETF',
    symbol: 'EMVL.L',
  },
  {
    value: 'XUTC.L',
    label: 'XUTC.L,  Xtrackers MSCI USA Information Technology UCITS ETF',
    name: 'Xtrackers MSCI USA Information Technology UCITS ETF',
    symbol: 'XUTC.L',
  },
  {
    value: 'ICGA.F',
    label: 'ICGA.F,  iShares MSCI China UCITS ETF',
    name: 'iShares MSCI China UCITS ETF',
    symbol: 'ICGA.F',
  },
  {
    value: 'CEUU.AS',
    label: 'CEUU.AS,  iShares VII PLC -iShares Core MSCI EMU UCITS ETF',
    name: 'iShares VII PLC -iShares Core MSCI EMU UCITS ETF',
    symbol: 'CEUU.AS',
  },
  {
    value: 'IFFI.AS',
    label: 'IFFI.AS,  iShares MSCI AC Far East ex-Japan UCITS ETF',
    name: 'iShares MSCI AC Far East ex-Japan UCITS ETF',
    symbol: 'IFFI.AS',
  },
  {
    value: 'MVLD.AS',
    label:
      'MVLD.AS,  iShares Edge MSCI Europe Minimum Volatility ESG UCITS ETF',
    name: 'iShares Edge MSCI Europe Minimum Volatility ESG UCITS ETF',
    symbol: 'MVLD.AS',
  },
  {
    value: 'ESIC.F',
    label:
      'ESIC.F,  iShares MSCI Europe Consumer Discretionary Sector UCITS ETF',
    name: 'iShares MSCI Europe Consumer Discretionary Sector UCITS ETF',
    symbol: 'ESIC.F',
  },
  {
    value: 'XDNG.L',
    label:
      'XDNG.L,  Xtrackers (IE) Plc - Xtrackers JPX-Nikkei 400 UCITS ETF 2D - GBP Hedged',
    name: 'Xtrackers (IE) Plc - Xtrackers JPX-Nikkei 400 UCITS ETF 2D - GBP Hedged',
    symbol: 'XDNG.L',
  },
  {
    value: 'XGLF.DE',
    label: 'XGLF.DE,  Xtrackers MSCI GCC Select Swap UCITS ETF',
    name: 'Xtrackers MSCI GCC Select Swap UCITS ETF',
    symbol: 'XGLF.DE',
  },
  {
    value: 'IKSA.L',
    label: 'IKSA.L,  iShares MSCI Saudi Arabia Capped UCITS ETF',
    name: 'iShares MSCI Saudi Arabia Capped UCITS ETF',
    symbol: 'IKSA.L',
  },
  {
    value: 'XMEU.L',
    label: 'XMEU.L,  Xtrackers MSCI Europe UCITS ETF 1C',
    name: 'Xtrackers MSCI Europe UCITS ETF 1C',
    symbol: 'XMEU.L',
  },
  {
    value: 'XKS2.L',
    label: 'XKS2.L,  Xtrackers MSCI Korea UCITS ETF',
    name: 'Xtrackers MSCI Korea UCITS ETF',
    symbol: 'XKS2.L',
  },
  {
    value: 'XSDR.L',
    label: 'XSDR.L,  Xtrackers MSCI Europe Health Care ESG Screened UCITS ETF',
    name: 'Xtrackers MSCI Europe Health Care ESG Screened UCITS ETF',
    symbol: 'XSDR.L',
  },
  {
    value: 'XS6R.L',
    label: 'XS6R.L,  Xtrackers MSCI Europe Utilities ESG Screened UCITS ETF',
    name: 'Xtrackers MSCI Europe Utilities ESG Screened UCITS ETF',
    symbol: 'XS6R.L',
  },
  {
    value: 'XMXD.L',
    label:
      'XMXD.L,  Xtrackers MSCI EM Europe, Middle East & Africa ESG Swap UCITS ETF',
    name: 'Xtrackers MSCI EM Europe, Middle East & Africa ESG Swap UCITS ETF',
    symbol: 'XMXD.L',
  },
  {
    value: 'DX2I.DE',
    label: 'DX2I.DE,  Xtrackers MSCI Europe ESG Screened UCITS ETF',
    name: 'Xtrackers MSCI Europe ESG Screened UCITS ETF',
    symbol: 'DX2I.DE',
  },
  {
    value: 'X020.DE',
    label: 'X020.DE,  Lyxor MSCI USA (LUX) UCITS ETF',
    name: 'Lyxor MSCI USA (LUX) UCITS ETF',
    symbol: 'X020.DE',
  },
  {
    value: 'XMEX.L',
    label: 'XMEX.L,  Xtrackers MSCI Mexico UCITS ETF',
    name: 'Xtrackers MSCI Mexico UCITS ETF',
    symbol: 'XMEX.L',
  },
  {
    value: 'D5BL.DE',
    label: 'D5BL.DE,  Xtrackers MSCI Europe Value UCITS ETF',
    name: 'Xtrackers MSCI Europe Value UCITS ETF',
    symbol: 'D5BL.DE',
  },
  {
    value: 'XCS4.L',
    label: 'XCS4.L,  Xtrackers MSCI Thailand UCITS ETF',
    name: 'Xtrackers MSCI Thailand UCITS ETF',
    symbol: 'XCS4.L',
  },
  {
    value: 'XMK9.MI',
    label: 'XMK9.MI,  Xtrackers MSCI Japan UCITS ETF',
    name: 'Xtrackers MSCI Japan UCITS ETF',
    symbol: 'XMK9.MI',
  },
  {
    value: 'XMCJ.SW',
    label: 'XMCJ.SW,  Xtrackers MSCI Japan UCITS ETF',
    name: 'Xtrackers MSCI Japan UCITS ETF',
    symbol: 'XMCJ.SW',
  },
  {
    value: 'XIEE.F',
    label: 'XIEE.F,  Xtrackers MSCI Europe UCITS ETF',
    name: 'Xtrackers MSCI Europe UCITS ETF',
    symbol: 'XIEE.F',
  },
  {
    value: 'C1U.PA',
    label: 'C1U.PA,  Amundi MSCI UK IMI SRI PAB',
    name: 'Amundi MSCI UK IMI SRI PAB',
    symbol: 'C1U.PA',
  },
  {
    value: 'LGWT.DE',
    label: 'LGWT.DE,  Lyxor Index Fund - Lyxor MSCI EMU Growth (DR) UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor MSCI EMU Growth (DR) UCITS ETF',
    symbol: 'LGWT.DE',
  },
  {
    value: 'LWCR.PA',
    label:
      'LWCR.PA,  Amundi MSCI World Climate Transition CTB - UCITS ETF DR - EUR-C',
    name: 'Amundi MSCI World Climate Transition CTB - UCITS ETF DR - EUR-C',
    symbol: 'LWCR.PA',
  },
  {
    value: 'CC1.PA',
    label: 'CC1.PA,  Amundi Index Solutions - Amundi MSCI China',
    name: 'Amundi Index Solutions - Amundi MSCI China',
    symbol: 'CC1.PA',
  },
  {
    value: 'AASG.L',
    label: 'AASG.L,  Amundi Index Solutions - Amundi MSCI Em Asia',
    name: 'Amundi Index Solutions - Amundi MSCI Em Asia',
    symbol: 'AASG.L',
  },
  {
    value: 'ALAT.PA',
    label: 'ALAT.PA,  Amundi Index Solutions - Amundi MSCI Em Latin America',
    name: 'Amundi Index Solutions - Amundi MSCI Em Latin America',
    symbol: 'ALAT.PA',
  },
  {
    value: 'DNRA.DE',
    label: 'DNRA.DE,  Amundi Index Solutions - Amundi Index MSCI North America',
    name: 'Amundi Index Solutions - Amundi Index MSCI North America',
    symbol: 'DNRA.DE',
  },
  {
    value: 'RIO.PA',
    label: 'RIO.PA,  Lyxor MSCI Brazil UCITS ETF',
    name: 'Lyxor MSCI Brazil UCITS ETF',
    symbol: 'RIO.PA',
  },
  {
    value: 'SRHE.DE',
    label: 'SRHE.DE,  Amundi Index Solutions - Amundi Index MSCI EMU SRI PAB',
    name: 'Amundi Index Solutions - Amundi Index MSCI EMU SRI PAB',
    symbol: 'SRHE.DE',
  },
  {
    value: 'SEMI.AS',
    label: 'SEMI.AS,  iShares MSCI Global Semiconductors UCITS ETF',
    name: 'iShares MSCI Global Semiconductors UCITS ETF',
    symbol: 'SEMI.AS',
  },
  {
    value: 'ITWN.MI',
    label: 'ITWN.MI,  iShares MSCI Taiwan UCITS ETF',
    name: 'iShares MSCI Taiwan UCITS ETF',
    symbol: 'ITWN.MI',
  },
  {
    value: 'XMVE.DE',
    label: 'XMVE.DE,  Xtrackers MSCI EMU ESG Screened UCITS ETF 1D',
    name: 'Xtrackers MSCI EMU ESG Screened UCITS ETF 1D',
    symbol: 'XMVE.DE',
  },
  {
    value: 'IUQD.L',
    label: 'IUQD.L,  iShares Edge MSCI USA Quality Factor UCITS ETF',
    name: 'iShares Edge MSCI USA Quality Factor UCITS ETF',
    symbol: 'IUQD.L',
  },
  {
    value: 'XZMU.DE',
    label: 'XZMU.DE,  Xtrackers MSCI USA ESG UCITS ETF',
    name: 'Xtrackers MSCI USA ESG UCITS ETF',
    symbol: 'XZMU.DE',
  },
  {
    value: 'XD9E.DE',
    label: 'XD9E.DE,  Xtrackers MSCI USA UCITS ETF',
    name: 'Xtrackers MSCI USA UCITS ETF',
    symbol: 'XD9E.DE',
  },
  {
    value: 'IFSD.L',
    label: 'IFSD.L,  iShares Edge MSCI Europe Multifactor UCITS ETF',
    name: 'iShares Edge MSCI Europe Multifactor UCITS ETF',
    symbol: 'IFSD.L',
  },
  {
    value: 'EEUD.L',
    label: 'EEUD.L,  iShares MSCI Europe ESG Enhanced UCITS ETF',
    name: 'iShares MSCI Europe ESG Enhanced UCITS ETF',
    symbol: 'EEUD.L',
  },
  {
    value: 'SMUA.AS',
    label: 'SMUA.AS,  iShares MSCI EMU SRI UCITS ETF',
    name: 'iShares MSCI EMU SRI UCITS ETF',
    symbol: 'SMUA.AS',
  },
  {
    value: 'SJPE.AS',
    label: 'SJPE.AS,  iShares Core MSCI Japan IMI UCITS ETF EUR Hedged (Acc)',
    name: 'iShares Core MSCI Japan IMI UCITS ETF EUR Hedged (Acc)',
    symbol: 'SJPE.AS',
  },
  {
    value: 'ICHD.AS',
    label: 'ICHD.AS,  iShares MSCI China UCITS ETF',
    name: 'iShares MSCI China UCITS ETF',
    symbol: 'ICHD.AS',
  },
  {
    value: 'IDSE.AS',
    label: 'IDSE.AS,  iShares MSCI Europe SRI UCITS ETF',
    name: 'iShares MSCI Europe SRI UCITS ETF',
    symbol: 'IDSE.AS',
  },
  {
    value: 'ISFR.L',
    label: 'ISFR.L,  iShares MSCI France UCITS ETF',
    name: 'iShares MSCI France UCITS ETF',
    symbol: 'ISFR.L',
  },
  {
    value: 'DBXJ.DE',
    label: 'DBXJ.DE,  Xtrackers MSCI Japan UCITS ETF 1C',
    name: 'Xtrackers MSCI Japan UCITS ETF 1C',
    symbol: 'DBXJ.DE',
  },
  {
    value: 'XS7R.L',
    label: 'XS7R.L,  Xtrackers MSCI Europe Financials ESG Screened UCITS ETF',
    name: 'Xtrackers MSCI Europe Financials ESG Screened UCITS ETF',
    symbol: 'XS7R.L',
  },
  {
    value: 'DX2J.DE',
    label: 'DX2J.DE,  Xtrackers MSCI Europe Small Cap UCITS ETF',
    name: 'Xtrackers MSCI Europe Small Cap UCITS ETF',
    symbol: 'DX2J.DE',
  },
  {
    value: 'E127.DE',
    label: 'E127.DE,  Lyxor MSCI Emerging Markets (LUX) UCITS ETF',
    name: 'Lyxor MSCI Emerging Markets (LUX) UCITS ETF',
    symbol: 'E127.DE',
  },
  {
    value: 'XMUJ.L',
    label: 'XMUJ.L,  Xtrackers MSCI Japan UCITS ETF',
    name: 'Xtrackers MSCI Japan UCITS ETF',
    symbol: 'XMUJ.L',
  },
  {
    value: 'MMS.PA',
    label: 'MMS.PA,  Lyxor Index Fund - Lyxor MSCI EMU Small Cap UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor MSCI EMU Small Cap UCITS ETF',
    symbol: 'MMS.PA',
  },
  {
    value: 'LWCU.PA',
    label: 'LWCU.PA,  Amundi MSCI World Climate Transition CTB',
    name: 'Amundi MSCI World Climate Transition CTB',
    symbol: 'LWCU.PA',
  },
  {
    value: 'MFE.PA',
    label: 'MFE.PA,  Lyxor Core MSCI EMU (DR) UCITS ETF',
    name: 'Lyxor Core MSCI EMU (DR) UCITS ETF',
    symbol: 'MFE.PA',
  },
  {
    value: 'QCEU.PA',
    label:
      'QCEU.PA,  Amundi Index Solutions - Amundi MSCI Europe Quality Factor',
    name: 'Amundi Index Solutions - Amundi MSCI Europe Quality Factor',
    symbol: 'QCEU.PA',
  },
  {
    value: 'CC1U.PA',
    label: 'CC1U.PA,  Amundi Index Solutions - Amundi MSCI China',
    name: 'Amundi Index Solutions - Amundi MSCI China',
    symbol: 'CC1U.PA',
  },
  {
    value: 'CN1.L',
    label: 'CN1.L,  Amundi Index Solutions - Amundi MSCI Nordic',
    name: 'Amundi Index Solutions - Amundi MSCI Nordic',
    symbol: 'CN1.L',
  },
  {
    value: 'LYMD.DE',
    label: 'LYMD.DE,  Lyxor MSCI India UCITS ETF',
    name: 'Lyxor MSCI India UCITS ETF',
    symbol: 'LYMD.DE',
  },
  {
    value: 'SLMA.DE',
    label: 'SLMA.DE,  iShares MSCI EMU ESG Screened UCITS ETF EUR (Acc)',
    name: 'iShares MSCI EMU ESG Screened UCITS ETF EUR (Acc)',
    symbol: 'SLMA.DE',
  },
  {
    value: 'UFSD.L',
    label: 'UFSD.L,  iShares Edge MSCI USA Multifactor UCITS ETF',
    name: 'iShares Edge MSCI USA Multifactor UCITS ETF',
    symbol: 'UFSD.L',
  },
  {
    value: 'ISED.AS',
    label: 'ISED.AS,  iShares MSCI Europe SRI UCITS ETF',
    name: 'iShares MSCI Europe SRI UCITS ETF',
    symbol: 'ISED.AS',
  },
  {
    value: 'EDM6.DE',
    label: 'EDM6.DE,  iShares MSCI Europe ESG Enhanced UCITS ETF',
    name: 'iShares MSCI Europe ESG Enhanced UCITS ETF',
    symbol: 'EDM6.DE',
  },
  {
    value: 'SMDU.AS',
    label: 'SMDU.AS,  iShares MSCI EMU SRI UCITS ETF',
    name: 'iShares MSCI EMU SRI UCITS ETF',
    symbol: 'SMDU.AS',
  },
  {
    value: 'SRIL.AS',
    label: 'SRIL.AS,  iShares MSCI USA SRI UCITS ETF USD (Dist)',
    name: 'iShares MSCI USA SRI UCITS ETF USD (Dist)',
    symbol: 'SRIL.AS',
  },
  {
    value: 'XMUS.L',
    label: 'XMUS.L,  Xtrackers MSCI USA Swap UCITS ETF',
    name: 'Xtrackers MSCI USA Swap UCITS ETF',
    symbol: 'XMUS.L',
  },
  {
    value: 'LG9.SI',
    label: 'LG9.SI,  Xtrackers MSCI China UCITS ETF',
    name: 'Xtrackers MSCI China UCITS ETF',
    symbol: 'LG9.SI',
  },
  {
    value: 'VAL.PA',
    label: 'VAL.PA,  Lyxor Index Fund - Lyxor MSCI EMU Value (DR) UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor MSCI EMU Value (DR) UCITS ETF',
    symbol: 'VAL.PA',
  },
  {
    value: 'MFEC.PA',
    label: 'MFEC.PA,  Lyxor Core MSCI EMU (DR) UCITS ETF',
    name: 'Lyxor Core MSCI EMU (DR) UCITS ETF',
    symbol: 'MFEC.PA',
  },
  {
    value: 'CD9.PA',
    label:
      'CD9.PA,  Amundi Index Solutions - Amundi MSCI Europe High Dividend Factor',
    name: 'Amundi Index Solutions - Amundi MSCI Europe High Dividend Factor',
    symbol: 'CD9.PA',
  },
  {
    value: 'AEEM.PA',
    label: 'AEEM.PA,  Amundi Index Solutions - Amundi MSCI Emerging Markets',
    name: 'Amundi Index Solutions - Amundi MSCI Emerging Markets',
    symbol: 'AEEM.PA',
  },
  {
    value: 'LCJP.L',
    label: 'LCJP.L,  Lyxor Core MSCI Japan (DR) UCITS ETF',
    name: 'Lyxor Core MSCI Japan (DR) UCITS ETF',
    symbol: 'LCJP.L',
  },
  {
    value: 'ESGE.PA',
    label: 'ESGE.PA,  Lyxor MSCI Europe ESG Leaders (DR) UCITS ETF - Acc',
    name: 'Lyxor MSCI Europe ESG Leaders (DR) UCITS ETF - Acc',
    symbol: 'ESGE.PA',
  },
  {
    value: 'JARI.F',
    label: 'JARI.F,  Amundi Index Solutions - Amundi Index MSCI Japan SRI PAB',
    name: 'Amundi Index Solutions - Amundi Index MSCI Japan SRI PAB',
    symbol: 'JARI.F',
  },
  {
    value: 'CF1.PA',
    label: 'CF1.PA,  Amundi ETF MSCI France UCITS ETF',
    name: 'Amundi ETF MSCI France UCITS ETF',
    symbol: 'CF1.PA',
  },
  {
    value: 'SLMB.DE',
    label: 'SLMB.DE,  iShares MSCI EMU ESG Screened UCITS ETF',
    name: 'iShares MSCI EMU ESG Screened UCITS ETF',
    symbol: 'SLMB.DE',
  },
  {
    value: 'XZMJ.DE',
    label: 'XZMJ.DE,  Xtrackers MSCI Japan ESG UCITS ETF',
    name: 'Xtrackers MSCI Japan ESG UCITS ETF',
    symbol: 'XZMJ.DE',
  },
  {
    value: '3SUR.DE',
    label: '3SUR.DE,  iShares MSCI USA SRI UCITS ETF',
    name: 'iShares MSCI USA SRI UCITS ETF',
    symbol: '3SUR.DE',
  },
  {
    value: 'XD5S.L',
    label: 'XD5S.L,  Xtrackers MSCI EMU UCITS ETF',
    name: 'Xtrackers MSCI EMU UCITS ETF',
    symbol: 'XD5S.L',
  },
  {
    value: 'CJ1.PA',
    label: 'CJ1.PA,  Amundi Index Solutions - Amundi Index MSCI Japan',
    name: 'Amundi Index Solutions - Amundi Index MSCI Japan',
    symbol: 'CJ1.PA',
  },
  {
    value: 'CG9.PA',
    label: 'CG9.PA,  Amundi Index Solutions - Amundi MSCI Europe Growth',
    name: 'Amundi Index Solutions - Amundi MSCI Europe Growth',
    symbol: 'CG9.PA',
  },
  {
    value: 'AUEM.PA',
    label: 'AUEM.PA,  Amundi Index Solutions - Amundi MSCI Emerging Markets',
    name: 'Amundi Index Solutions - Amundi MSCI Emerging Markets',
    symbol: 'AUEM.PA',
  },
  {
    value: '10AI.DE',
    label: '10AI.DE,  Amundi Index Solutions - Amundi Index MSCI Europe',
    name: 'Amundi Index Solutions - Amundi Index MSCI Europe',
    symbol: '10AI.DE',
  },
  {
    value: 'ESGH.PA',
    label:
      'ESGH.PA,  MULTI-UNITS LUXEMBOURG - Lyxor MSCI Europe ESG Leaders (DR) UCITS ETF',
    name: 'MULTI-UNITS LUXEMBOURG - Lyxor MSCI Europe ESG Leaders (DR) UCITS ETF',
    symbol: 'ESGH.PA',
  },
  {
    value: 'CL2.PA',
    label: 'CL2.PA,  Amundi ETF Leveraged MSCI USA Daily UCITS ETF',
    name: 'Amundi ETF Leveraged MSCI USA Daily UCITS ETF',
    symbol: 'CL2.PA',
  },
  {
    value: 'SLMC.DE',
    label: 'SLMC.DE,  iShares MSCI Europe ESG Screened UCITS ETF EUR (Acc)',
    name: 'iShares MSCI Europe ESG Screened UCITS ETF EUR (Acc)',
    symbol: 'SLMC.DE',
  },
  {
    value: 'ESIE.DE',
    label: 'ESIE.DE,  iShares MSCI Europe Energy Sector UCITS ETF',
    name: 'iShares MSCI Europe Energy Sector UCITS ETF',
    symbol: 'ESIE.DE',
  },
  {
    value: 'CV9.PA',
    label: 'CV9.PA,  Amundi Index Solutions - Amundi MSCI Europe Value Factor',
    name: 'Amundi Index Solutions - Amundi MSCI Europe Value Factor',
    symbol: 'CV9.PA',
  },
  {
    value: 'XEOU.SW',
    label: 'XEOU.SW,  Xtrackers MSCI Europe UCITS ETF 2C - USD Hedged',
    name: 'Xtrackers MSCI Europe UCITS ETF 2C - USD Hedged',
    symbol: 'XEOU.SW',
  },
  {
    value: 'SGAS.DE',
    label: 'SGAS.DE,  iShares MSCI USA ESG Screened UCITS ETF',
    name: 'iShares MSCI USA ESG Screened UCITS ETF',
    symbol: 'SGAS.DE',
  },
  {
    value: 'MVEA.DE',
    label: 'MVEA.DE,  iShares Edge MSCI USA Minimum Volatility ESG UCITS ETF',
    name: 'iShares Edge MSCI USA Minimum Volatility ESG UCITS ETF',
    symbol: 'MVEA.DE',
  },
  {
    value: 'CU2.PA',
    label: 'CU2.PA,  Amundi Index Solutions - Amundi MSCI USA UCITS ETF-C EUR',
    name: 'Amundi Index Solutions - Amundi MSCI USA UCITS ETF-C EUR',
    symbol: 'CU2.PA',
  },
  {
    value: 'SDUS.L',
    label: 'SDUS.L,  iShares MSCI USA ESG Screened UCITS ETF',
    name: 'iShares MSCI USA ESG Screened UCITS ETF',
    symbol: 'SDUS.L',
  },
  {
    value: 'SGAJ.DE',
    label: 'SGAJ.DE,  iShares MSCI Japan ESG Screened UCITS ETF',
    name: 'iShares MSCI Japan ESG Screened UCITS ETF',
    symbol: 'SGAJ.DE',
  },
  {
    value: 'USAC.PA',
    label: 'USAC.PA,  Lyxor MSCI USA ESG Broad CTB (DR) UCITS ETF',
    name: 'Lyxor MSCI USA ESG Broad CTB (DR) UCITS ETF',
    symbol: 'USAC.PA',
  },
  {
    value: 'PLEM.PA',
    label: 'PLEM.PA,  Lyxor PEA Emergents (MSCI Emerging Markets) UCITS ETF',
    name: 'Lyxor PEA Emergents (MSCI Emerging Markets) UCITS ETF',
    symbol: 'PLEM.PA',
  },
  {
    value: 'EWLD.PA',
    label: 'EWLD.PA,  Lyxor PEA Monde (MSCI World) UCITS ETF',
    name: 'Lyxor PEA Monde (MSCI World) UCITS ETF',
    symbol: 'EWLD.PA',
  },
  {
    value: 'WMVG.L',
    label:
      'WMVG.L,  iShares Edge MSCI World Minimum Volatility UCITS ETF GBP Hedged (Acc)',
    name: 'iShares Edge MSCI World Minimum Volatility UCITS ETF GBP Hedged (Acc)',
    symbol: 'WMVG.L',
  },
  {
    value: 'UIM4.DE',
    label: 'UIM4.DE,  UBS(Lux)Fund Solutions – MSCI EMU UCITS ETF(EUR)A-dis',
    name: 'UBS(Lux)Fund Solutions – MSCI EMU UCITS ETF(EUR)A-dis',
    symbol: 'UIM4.DE',
  },
  {
    value: 'EL4H.DE',
    label: 'EL4H.DE,  Deka MSCI Europe LC UCITS ETF',
    name: 'Deka MSCI Europe LC UCITS ETF',
    symbol: 'EL4H.DE',
  },
  {
    value: 'EL4J.DE',
    label: 'EL4J.DE,  Deka MSCI Japan LC UCITS ETF',
    name: 'Deka MSCI Japan LC UCITS ETF',
    symbol: 'EL4J.DE',
  },
  {
    value: 'EL4I.DE',
    label: 'EL4I.DE,  Deka MSCI USA LC UCITS ETF',
    name: 'Deka MSCI USA LC UCITS ETF',
    symbol: 'EL4I.DE',
  },
  {
    value: 'MXEU.L',
    label: 'MXEU.L,  Invesco MSCI Europe UCITS ETF',
    name: 'Invesco MSCI Europe UCITS ETF',
    symbol: 'MXEU.L',
  },
  {
    value: 'SC0I.F',
    label: 'SC0I.F,  Invesco MSCI Japan UCITS ETF',
    name: 'Invesco MSCI Japan UCITS ETF',
    symbol: 'SC0I.F',
  },
  {
    value: 'MXUS.L',
    label: 'MXUS.L,  Invesco MSCI USA UCITS ETF',
    name: 'Invesco MSCI USA UCITS ETF',
    symbol: 'MXUS.L',
  },
  {
    value: 'EL43.DE',
    label: 'EL43.DE,  Deka MSCI Europe MC UCITS ETF',
    name: 'Deka MSCI Europe MC UCITS ETF',
    symbol: 'EL43.DE',
  },
  {
    value: 'EL42.DE',
    label: 'EL42.DE,  Deka MSCI Europe UCITS ETF',
    name: 'Deka MSCI Europe UCITS ETF',
    symbol: 'EL42.DE',
  },
  {
    value: 'EL45.DE',
    label: 'EL45.DE,  Deka MSCI Japan Climate Change ESG UCITS ETF',
    name: 'Deka MSCI Japan Climate Change ESG UCITS ETF',
    symbol: 'EL45.DE',
  },
  {
    value: 'EL44.DE',
    label: 'EL44.DE,  Deka MSCI Japan UCITS ETF',
    name: 'Deka MSCI Japan UCITS ETF',
    symbol: 'EL44.DE',
  },
  {
    value: 'EL41.DE',
    label: 'EL41.DE,  Deka MSCI USA MC UCITS ETF',
    name: 'Deka MSCI USA MC UCITS ETF',
    symbol: 'EL41.DE',
  },
  {
    value: 'EL4Z.DE',
    label: 'EL4Z.DE,  Deka MSCI USA UCITS ETF',
    name: 'Deka MSCI USA UCITS ETF',
    symbol: 'EL4Z.DE',
  },
  {
    value: 'UIME.F',
    label: 'UIME.F,  UBS (Lux) Fund Solutions – MSCI EMU Value UCITS ETF',
    name: 'UBS (Lux) Fund Solutions – MSCI EMU Value UCITS ETF',
    symbol: 'UIME.F',
  },
  {
    value: 'UIMA.F',
    label: 'UIMA.F,  UBS (Lux) Fund Solutions – MSCI Europe UCITS ETF',
    name: 'UBS (Lux) Fund Solutions – MSCI Europe UCITS ETF',
    symbol: 'UIMA.F',
  },
  {
    value: 'EL46.DE',
    label: 'EL46.DE,  Deka MSCI China ex A Shares UCITS ETF',
    name: 'Deka MSCI China ex A Shares UCITS ETF',
    symbol: 'EL46.DE',
  },
  {
    value: 'EL40.DE',
    label: 'EL40.DE,  Deka MSCI Emerging Markets UCITS ETF',
    name: 'Deka MSCI Emerging Markets UCITS ETF',
    symbol: 'EL40.DE',
  },
  {
    value: 'HMUS.L',
    label: 'HMUS.L,  HSBC MSCI USA UCITS ETF',
    name: 'HSBC MSCI USA UCITS ETF',
    symbol: 'HMUS.L',
  },
  {
    value: 'HEU.PA',
    label: 'HEU.PA,  HSBC MSCI Europe UCITS ETF',
    name: 'HSBC MSCI Europe UCITS ETF',
    symbol: 'HEU.PA',
  },
  {
    value: 'HMJP.L',
    label: 'HMJP.L,  HSBC MSCI JAPAN UCITS ETF',
    name: 'HSBC MSCI JAPAN UCITS ETF',
    symbol: 'HMJP.L',
  },
  {
    value: 'HMBR.L',
    label: 'HMBR.L,  HSBC MSCI Brazil UCITS ETF',
    name: 'HSBC MSCI Brazil UCITS ETF',
    symbol: 'HMBR.L',
  },
  {
    value: 'SPYI.DE',
    label: 'SPYI.DE,  SPDR MSCI ACWI IMI UCITS ETF',
    name: 'SPDR MSCI ACWI IMI UCITS ETF',
    symbol: 'SPYI.DE',
  },
  {
    value: 'SPYY.DE',
    label: 'SPYY.DE,  SPDR MSCI ACWI UCITS ETF',
    name: 'SPDR MSCI ACWI UCITS ETF',
    symbol: 'SPYY.DE',
  },
  {
    value: 'SPYA.DE',
    label: 'SPYA.DE,  SPDR MSCI EM Asia UCITS ETF',
    name: 'SPDR MSCI EM Asia UCITS ETF',
    symbol: 'SPYA.DE',
  },
  {
    value: 'SPYX.DE',
    label: 'SPYX.DE,  SPDR MSCI Emerging Markets Small Cap UCITS ETF',
    name: 'SPDR MSCI Emerging Markets Small Cap UCITS ETF',
    symbol: 'SPYX.DE',
  },
  {
    value: 'SPYM.DE',
    label: 'SPYM.DE,  SPDR MSCI Emerging Markets UCITS ETF',
    name: 'SPDR MSCI Emerging Markets UCITS ETF',
    symbol: 'SPYM.DE',
  },
  {
    value: 'UIMP.DE',
    label:
      'UIMP.DE,  UBS (Lux) Fund Solutions – MSCI USA Socially Responsible UCITS ETF',
    name: 'UBS (Lux) Fund Solutions – MSCI USA Socially Responsible UCITS ETF',
    symbol: 'UIMP.DE',
  },
  {
    value: 'UEFD.AS',
    label: 'UEFD.AS,  UBS (Lux) Fund Solutions – MSCI EMU Small Cap UCITS ETF',
    name: 'UBS (Lux) Fund Solutions – MSCI EMU Small Cap UCITS ETF',
    symbol: 'UEFD.AS',
  },
  {
    value: 'EMSV.F',
    label: 'EMSV.F,  Invesco MSCI Europe Value UCITS ETF',
    name: 'Invesco MSCI Europe Value UCITS ETF',
    symbol: 'EMSV.F',
  },
  {
    value: 'UC04.L',
    label: 'UC04.L,  UBS (Irl) ETF plc - MSCI USA UCITS ETF (USD) A-dis',
    name: 'UBS (Irl) ETF plc - MSCI USA UCITS ETF (USD) A-dis',
    symbol: 'UC04.L',
  },
  {
    value: 'UC07.L',
    label: 'UC07.L,  UBS (Irl) ETF plc - MSCI USA Value UCITS ETF',
    name: 'UBS (Irl) ETF plc - MSCI USA Value UCITS ETF',
    symbol: 'UC07.L',
  },
  {
    value: 'ZPRE.F',
    label: 'ZPRE.F,  SPDR MSCI EMU UCITS ETF',
    name: 'SPDR MSCI EMU UCITS ETF',
    symbol: 'ZPRE.F',
  },
  {
    value: 'HCAN.L',
    label: 'HCAN.L,  HSBC MSCI Canada UCITS ETF',
    name: 'HSBC MSCI Canada UCITS ETF',
    symbol: 'HCAN.L',
  },
  {
    value: 'HMCD.L',
    label: 'HMCD.L,  HSBC MSCI China UCITS ETF',
    name: 'HSBC MSCI China UCITS ETF',
    symbol: 'HMCD.L',
  },
  {
    value: 'HMEF.L',
    label: 'HMEF.L,  HSBC MSCI Emerging Markets UCITS ETF',
    name: 'HSBC MSCI Emerging Markets UCITS ETF',
    symbol: 'HMEF.L',
  },
  {
    value: 'HIDR.L',
    label: 'HIDR.L,  HSBC MSCI Indonesia UCITS ETF',
    name: 'HSBC MSCI Indonesia UCITS ETF',
    symbol: 'HIDR.L',
  },
  {
    value: 'STR.PA',
    label: 'STR.PA,  SPDR MSCI Europe Consumer Discretionary UCITS ETF',
    name: 'SPDR MSCI Europe Consumer Discretionary UCITS ETF',
    symbol: 'STR.PA',
  },
  {
    value: 'CSTP.L',
    label: 'CSTP.L,  SPDR MSCI Europe Consumer Staples UCITS ETF',
    name: 'SPDR MSCI Europe Consumer Staples UCITS ETF',
    symbol: 'CSTP.L',
  },
  {
    value: 'STNX.SW',
    label: 'STNX.SW,  SPDR MSCI Europe Energy UCITS ETF',
    name: 'SPDR MSCI Europe Energy UCITS ETF',
    symbol: 'STNX.SW',
  },
  {
    value: 'STZX.SW',
    label: 'STZX.SW,  SPDR MSCI Europe Financials UCITS ETF',
    name: 'SPDR MSCI Europe Financials UCITS ETF',
    symbol: 'STZX.SW',
  },
  {
    value: 'STW.PA',
    label: 'STW.PA,  SPDR MSCI Europe Health Care UCITS ETF',
    name: 'SPDR MSCI Europe Health Care UCITS ETF',
    symbol: 'STW.PA',
  },
  {
    value: 'SPYP.DE',
    label: 'SPYP.DE,  SPDR MSCI Europe Materials UCITS ETF',
    name: 'SPDR MSCI Europe Materials UCITS ETF',
    symbol: 'SPYP.DE',
  },
  {
    value: 'ERO.L',
    label: 'ERO.L,  SPDR MSCI Europe UCITS ETF',
    name: 'SPDR MSCI Europe UCITS ETF',
    symbol: 'ERO.L',
  },
  {
    value: 'STUX.SW',
    label: 'STUX.SW,  SPDR MSCI Europe Utilities UCITS ETF',
    name: 'SPDR MSCI Europe Utilities UCITS ETF',
    symbol: 'STUX.SW',
  },
  {
    value: 'EVAL.L',
    label: 'EVAL.L,  SPDR MSCI Europe Value UCITS ETF',
    name: 'SPDR MSCI Europe Value UCITS ETF',
    symbol: 'EVAL.L',
  },
  {
    value: 'USSC.L',
    label: 'USSC.L,  SPDR MSCI USA Small Cap Value Weighted UCITS ETF',
    name: 'SPDR MSCI USA Small Cap Value Weighted UCITS ETF',
    symbol: 'USSC.L',
  },
  {
    value: 'UVAL.L',
    label: 'UVAL.L,  SPDR MSCI USA Value UCITS ETF',
    name: 'SPDR MSCI USA Value UCITS ETF',
    symbol: 'UVAL.L',
  },
  {
    value: 'ELF5.DE',
    label: 'ELF5.DE,  Deka MSCI Europe ex EMU UCITS ETF',
    name: 'Deka MSCI Europe ex EMU UCITS ETF',
    symbol: 'ELF5.DE',
  },
  {
    value: 'JPSR.AS',
    label:
      'JPSR.AS,  UBS (Lux) Fund Solutions – MSCI Japan Socially Responsible UCITS ETF',
    name: 'UBS (Lux) Fund Solutions – MSCI Japan Socially Responsible UCITS ETF',
    symbol: 'JPSR.AS',
  },
  {
    value: 'UC96.L',
    label: 'UC96.L,  UBS (Irl) ETF plc - Factor MSCI USA Prime Value UCITS ETF',
    name: 'UBS (Irl) ETF plc - Factor MSCI USA Prime Value UCITS ETF',
    symbol: 'UC96.L',
  },
  {
    value: 'UC99.L',
    label: 'UC99.L,  UBS (Irl) ETF plc - Factor MSCI USA Quality UCITS ETF',
    name: 'UBS (Irl) ETF plc - Factor MSCI USA Quality UCITS ETF',
    symbol: 'UC99.L',
  },
  {
    value: 'UD03.L',
    label:
      'UD03.L,  UBS (Lux) Fund Solutions – Factor MSCI EMU Prime Value UCITS ETF',
    name: 'UBS (Lux) Fund Solutions – Factor MSCI EMU Prime Value UCITS ETF',
    symbol: 'UD03.L',
  },
  {
    value: 'UIM2.DE',
    label:
      'UIM2.DE,  UBS (Lux) Fund Solutions – Factor MSCI EMU Quality UCITS ETF',
    name: 'UBS (Lux) Fund Solutions – Factor MSCI EMU Quality UCITS ETF',
    symbol: 'UIM2.DE',
  },
  {
    value: 'ZPDW.F',
    label: 'ZPDW.F,  SPDR MSCI Japan UCITS ETF',
    name: 'SPDR MSCI Japan UCITS ETF',
    symbol: 'ZPDW.F',
  },
  {
    value: 'ZPDJ.F',
    label: 'ZPDJ.F,  SPDR MSCI Japan UCITS ETF',
    name: 'SPDR MSCI Japan UCITS ETF',
    symbol: 'ZPDJ.F',
  },
  {
    value: 'EEMK.PA',
    label: 'EEMK.PA,  BNP Paribas Easy MSCI Emerging ESG Filtered Min TE',
    name: 'BNP Paribas Easy MSCI Emerging ESG Filtered Min TE',
    symbol: 'EEMK.PA',
  },
  {
    value: 'EEMU.PA',
    label: 'EEMU.PA,  BNP Paribas Easy MSCI EMU ex CW',
    name: 'BNP Paribas Easy MSCI EMU ex CW',
    symbol: 'EEMU.PA',
  },
  {
    value: 'EEUE.PA',
    label: 'EEUE.PA,  BNPP E MSCI Europe ESG Filtered Min TE',
    name: 'BNPP E MSCI Europe ESG Filtered Min TE',
    symbol: 'EEUE.PA',
  },
  {
    value: 'EESM.PA',
    label:
      'EESM.PA,  BNP Paribas Easy MSCI Europe Small Caps SRI S-Series PAB 5% Capped',
    name: 'BNP Paribas Easy MSCI Europe Small Caps SRI S-Series PAB 5% Capped',
    symbol: 'EESM.PA',
  },
  {
    value: 'ENAM.PA',
    label: 'ENAM.PA,  BNP Paribas Easy MSCI North America ESG Filtered Min TE',
    name: 'BNP Paribas Easy MSCI North America ESG Filtered Min TE',
    symbol: 'ENAM.PA',
  },
  {
    value: 'EPEJ.PA',
    label:
      'EPEJ.PA,  BNP Paribas Easy MSCI Pacific ex Japan ESG Filtered Min TE',
    name: 'BNP Paribas Easy MSCI Pacific ex Japan ESG Filtered Min TE',
    symbol: 'EPEJ.PA',
  },
  {
    value: 'EKLD.PA',
    label:
      'EKLD.PA,  BNP Paribas Easy MSCI USA SRI S-Series 5% Capped UCITS ETF Capitalisation',
    name: 'BNP Paribas Easy MSCI USA SRI S-Series 5% Capped UCITS ETF Capitalisation',
    symbol: 'EKLD.PA',
  },
  {
    value: 'EMSR.PA',
    label:
      'EMSR.PA,  BNP Paribas Easy MSCI Emerging SRI S-Series 5% Capped UCITS ETF Capitalisation',
    name: 'BNP Paribas Easy MSCI Emerging SRI S-Series 5% Capped UCITS ETF Capitalisation',
    symbol: 'EMSR.PA',
  },
  {
    value: 'EJAH.PA',
    label: 'EJAH.PA,  BNPP E MSCI Japan ESG Filtered Min TE',
    name: 'BNPP E MSCI Japan ESG Filtered Min TE',
    symbol: 'EJAH.PA',
  },
  {
    value: 'XU6C.DE',
    label:
      'XU6C.DE,  BNP Paribas Easy MSCI Emerging SRI S-Series 5% Capped UCITS ETF EUR Distribution',
    name: 'BNP Paribas Easy MSCI Emerging SRI S-Series 5% Capped UCITS ETF EUR Distribution',
    symbol: 'XU6C.DE',
  },
  {
    value: 'EKUS.DE',
    label: 'EKUS.DE,  BNP Paribas Easy MSCI USA SRI S-Series PAB 5% Capped',
    name: 'BNP Paribas Easy MSCI USA SRI S-Series PAB 5% Capped',
    symbol: 'EKUS.DE',
  },
  {
    value: 'U1FB.F',
    label:
      'U1FB.F,  UBS(Lux)Fund Solutions – MSCI Japan Socially Responsible UCITS ETF(hedged EUR)A-acc',
    name: 'UBS(Lux)Fund Solutions – MSCI Japan Socially Responsible UCITS ETF(hedged EUR)A-acc',
    symbol: 'U1FB.F',
  },
  {
    value: 'ZSRI.DE',
    label: 'ZSRI.DE,  BNP Paribas Easy MSCI Europe SRI S-Series PAB 5% Capped',
    name: 'BNP Paribas Easy MSCI Europe SRI S-Series PAB 5% Capped',
    symbol: 'ZSRI.DE',
  },
  {
    value: 'SRIJ.PA',
    label: 'SRIJ.PA,  BNP Paribas Easy MSCI Japan SRI S-Series PAB 5% Capped',
    name: 'BNP Paribas Easy MSCI Japan SRI S-Series PAB 5% Capped',
    symbol: 'SRIJ.PA',
  },
  {
    value: 'UIQL.DE',
    label:
      'UIQL.DE,  UBS (Lux) Fund Solutions – Bloomberg MSCI Euro Area Liquid Corporates 1-5 Year Sustainable UCITS ETF',
    name: 'UBS (Lux) Fund Solutions – Bloomberg MSCI Euro Area Liquid Corporates 1-5 Year Sustainable UCITS ETF',
    symbol: 'UIQL.DE',
  },
  {
    value: 'H41K.F',
    label: 'H41K.F,  HSBC MSCI CHINA A UCITS ETF',
    name: 'HSBC MSCI CHINA A UCITS ETF',
    symbol: 'H41K.F',
  },
  {
    value: 'ICFP.DE',
    label:
      'ICFP.DE,  Invesco MSCI Europe ESG Leaders Catholic Principles UCITS ETF',
    name: 'Invesco MSCI Europe ESG Leaders Catholic Principles UCITS ETF',
    symbol: 'ICFP.DE',
  },
  {
    value: 'MXFP.L',
    label: 'MXFP.L,  Invesco MSCI Emerging Markets UCITS ETF',
    name: 'Invesco MSCI Emerging Markets UCITS ETF',
    symbol: 'MXFP.L',
  },
  {
    value: '4UBK.F',
    label:
      '4UBK.F,  UBS (Irl) ETF plc - MSCI USA Socially Responsible UCITS ETF',
    name: 'UBS (Irl) ETF plc - MSCI USA Socially Responsible UCITS ETF',
    symbol: '4UBK.F',
  },
  {
    value: '2282.SR',
    label: '2282.SR,  Naqi Water Company',
    name: 'Naqi Water Company',
    symbol: '2282.SR',
  },
  {
    value: 'TCAP-USD',
    label: 'TCAP-USD,  ',
    name: '',
    symbol: 'TCAP-USD',
  },
  {
    value: '8TRA.ST',
    label: '8TRA.ST,  Traton SE',
    name: 'Traton SE',
    symbol: '8TRA.ST',
  },
  {
    value: 'KSCP',
    label: 'KSCP,  Knightscope, Inc.',
    name: 'Knightscope, Inc.',
    symbol: 'KSCP',
  },
  {
    value: 'CSY8.F',
    label:
      'CSY8.F,  Credit Suisse Index Fund (IE) ETF ICAV - CSIF (IE) MSCI USA Small Cap ESG Leaders Blue UCITS ETF',
    name: 'Credit Suisse Index Fund (IE) ETF ICAV - CSIF (IE) MSCI USA Small Cap ESG Leaders Blue UCITS ETF',
    symbol: 'CSY8.F',
  },
  {
    value: 'USUP.DE',
    label:
      'USUP.DE,  UBS (Lux) Fund Solutions – MSCI Pacific Socially Responsible UCITS ETF',
    name: 'UBS (Lux) Fund Solutions – MSCI Pacific Socially Responsible UCITS ETF',
    symbol: 'USUP.DE',
  },
  {
    value: 'JARH.F',
    label: 'JARH.F,  Amundi Index Solutions - Amundi Index MSCI Japan SRI PAB',
    name: 'Amundi Index Solutions - Amundi Index MSCI Japan SRI PAB',
    symbol: 'JARH.F',
  },
  {
    value: 'XD9D.DE',
    label: 'XD9D.DE,  Xtrackers MSCI USA UCITS ETF',
    name: 'Xtrackers MSCI USA UCITS ETF',
    symbol: 'XD9D.DE',
  },
  {
    value: 'MCHN.L',
    label: 'MCHN.L,  Invesco MSCI China All Shares Connect UCITS ETF',
    name: 'Invesco MSCI China All Shares Connect UCITS ETF',
    symbol: 'MCHN.L',
  },
  {
    value: 'ESGX.DE',
    label:
      'ESGX.DE,  Invesco Markets II plc - Invesco MSCI Europe Ex UK ESG Universal Screened UCITS ETF',
    name: 'Invesco Markets II plc - Invesco MSCI Europe Ex UK ESG Universal Screened UCITS ETF',
    symbol: 'ESGX.DE',
  },
  {
    value: 'GLUX.PA',
    label: 'GLUX.PA,  Amundi Index Solutions - Amundi S&P Global Luxury',
    name: 'Amundi Index Solutions - Amundi S&P Global Luxury',
    symbol: 'GLUX.PA',
  },
  {
    value: 'GPAB.L',
    label:
      'GPAB.L,  Lyxor Net Zero 2050 S&P World Climate PAB (DR) UCITS ETF - Acc',
    name: 'Lyxor Net Zero 2050 S&P World Climate PAB (DR) UCITS ETF - Acc',
    symbol: 'GPAB.L',
  },
  {
    value: 'ISP6.L',
    label: 'ISP6.L,  iShares S&P Small Cap 600 UCITS ETF USD (Dist)',
    name: 'iShares S&P Small Cap 600 UCITS ETF USD (Dist)',
    symbol: 'ISP6.L',
  },
  {
    value: 'IUS2.DE',
    label: 'IUS2.DE,  iShares V PLC - iShares S&P U.S. Banks UCITS ETF',
    name: 'iShares V PLC - iShares S&P U.S. Banks UCITS ETF',
    symbol: 'IUS2.DE',
  },
  {
    value: 'DX2E.DE',
    label: 'DX2E.DE,  Xtrackers S&P Global Infrastructure Swap UCITS ETF',
    name: 'Xtrackers S&P Global Infrastructure Swap UCITS ETF',
    symbol: 'DX2E.DE',
  },
  {
    value: 'LUXU.PA',
    label: 'LUXU.PA,  Amundi Index Solutions - Amundi S&P Global Luxury',
    name: 'Amundi Index Solutions - Amundi S&P Global Luxury',
    symbol: 'LUXU.PA',
  },
  {
    value: 'BNKT.AS',
    label: 'BNKT.AS,  iShares V PLC - iShares S&P U.S. Banks UCITS ETF',
    name: 'iShares V PLC - iShares S&P U.S. Banks UCITS ETF',
    symbol: 'BNKT.AS',
  },
  {
    value: 'X022.DE',
    label: 'X022.DE,  Lyxor S&P MidCap 400 UCITS ETF',
    name: 'Lyxor S&P MidCap 400 UCITS ETF',
    symbol: 'X022.DE',
  },
  {
    value: 'XAUS.L',
    label: 'XAUS.L,  Xtrackers S&P ASX 200 UCITS ETF',
    name: 'Xtrackers S&P ASX 200 UCITS ETF',
    symbol: 'XAUS.L',
  },
  {
    value: 'X023.DE',
    label: 'X023.DE,  Lyxor S&P SmallCap 600 UCITS ETF',
    name: 'Lyxor S&P SmallCap 600 UCITS ETF',
    symbol: 'X023.DE',
  },
  {
    value: 'EPAB.PA',
    label:
      'EPAB.PA,  Lyxor Net Zero 2050 S&P Eurozone Climate PAB (DR) UCITS ETF-Acc',
    name: 'Lyxor Net Zero 2050 S&P Eurozone Climate PAB (DR) UCITS ETF-Acc',
    symbol: 'EPAB.PA',
  },
  {
    value: 'GLDV.L',
    label: 'GLDV.L,  SPDR S&P Global Dividend Aristocrats UCITS ETF',
    name: 'SPDR S&P Global Dividend Aristocrats UCITS ETF',
    symbol: 'GLDV.L',
  },
  {
    value: 'SPYD.DE',
    label: 'SPYD.DE,  SPDR S&P US Dividend Aristocrats UCITS ETF',
    name: 'SPDR S&P US Dividend Aristocrats UCITS ETF',
    symbol: 'SPYD.DE',
  },
  {
    value: 'SPY4.L',
    label: 'SPY4.L,  SPDR S&P 400 US Mid Cap UCITS ETF',
    name: 'SPDR S&P 400 US Mid Cap UCITS ETF',
    symbol: 'SPY4.L',
  },
  {
    value: 'EUDV.L',
    label: 'EUDV.L,  SPDR S&P Euro Dividend Aristocrats UCITS ETF',
    name: 'SPDR S&P Euro Dividend Aristocrats UCITS ETF',
    symbol: 'EUDV.L',
  },
  {
    value: 'UKDV.L',
    label: 'UKDV.L,  SPDR S&P UK Dividend Aristocrats UCITS ETF',
    name: 'SPDR S&P UK Dividend Aristocrats UCITS ETF',
    symbol: 'UKDV.L',
  },
  {
    value: 'PADV.MI',
    label: 'PADV.MI,  SPDR S&P Pan Asia Dividend Aristocrats UCITS ETF',
    name: 'SPDR S&P Pan Asia Dividend Aristocrats UCITS ETF',
    symbol: 'PADV.MI',
  },
  {
    value: 'SXLY.L',
    label:
      'SXLY.L,  SPDR S&P U.S. Consumer Discretionary Select Sector UCITS ETF',
    name: 'SPDR S&P U.S. Consumer Discretionary Select Sector UCITS ETF',
    symbol: 'SXLY.L',
  },
  {
    value: 'SXLP.L',
    label: 'SXLP.L,  SPDR S&P U.S. Consumer Staples Select Sector UCITS ETF',
    name: 'SPDR S&P U.S. Consumer Staples Select Sector UCITS ETF',
    symbol: 'SXLP.L',
  },
  {
    value: 'SXLE.L',
    label: 'SXLE.L,  SPDR S&P U.S. Energy Select Sector UCITS ETF',
    name: 'SPDR S&P U.S. Energy Select Sector UCITS ETF',
    symbol: 'SXLE.L',
  },
  {
    value: 'SXLF.L',
    label: 'SXLF.L,  SPDR S&P U.S. Financials Select Sector UCITS ETF',
    name: 'SPDR S&P U.S. Financials Select Sector UCITS ETF',
    symbol: 'SXLF.L',
  },
  {
    value: 'SXLV.L',
    label: 'SXLV.L,  SPDR S&P U.S. Health Care Select Sector UCITS ETF',
    name: 'SPDR S&P U.S. Health Care Select Sector UCITS ETF',
    symbol: 'SXLV.L',
  },
  {
    value: 'SXLI.L',
    label: 'SXLI.L,  SPDR S&P U.S. Industrials Select Sector UCITS ETF',
    name: 'SPDR S&P U.S. Industrials Select Sector UCITS ETF',
    symbol: 'SXLI.L',
  },
  {
    value: 'SXLB.L',
    label: 'SXLB.L,  SPDR S&P U.S. Materials Select Sector UCITS ETF',
    name: 'SPDR S&P U.S. Materials Select Sector UCITS ETF',
    symbol: 'SXLB.L',
  },
  {
    value: 'SXLU.L',
    label: 'SXLU.L,  SPDR S&P U.S. Utilities Select Sector UCITS ETF',
    name: 'SPDR S&P U.S. Utilities Select Sector UCITS ETF',
    symbol: 'SXLU.L',
  },
  {
    value: 'ZPDK.DE',
    label:
      'ZPDK.DE,  SPDR S&P U.S. Communication Services Select Sector UCITS ETF',
    name: 'SPDR S&P U.S. Communication Services Select Sector UCITS ETF',
    symbol: 'ZPDK.DE',
  },
  {
    value: 'UIW2.F',
    label:
      'UIW2.F,  UBS (Lux) Fund Solutions – MSCI Europe Socially Responsible UCITS ETF',
    name: 'UBS (Lux) Fund Solutions – MSCI Europe Socially Responsible UCITS ETF',
    symbol: 'UIW2.F',
  },
  {
    value: 'USML.L',
    label: 'USML.L,  Invesco S&P SmallCap 600 UCITS ETF',
    name: 'Invesco S&P SmallCap 600 UCITS ETF',
    symbol: 'USML.L',
  },
  {
    value: 'ZPD9.F',
    label: 'ZPD9.F,  SPDR S&P Euro Dividend Aristocrats ESG UCITS ETF',
    name: 'SPDR S&P Euro Dividend Aristocrats ESG UCITS ETF',
    symbol: 'ZPD9.F',
  },
  {
    value: 'ZPD6.F',
    label: 'ZPD6.F,  SPDR S&P U.S. Dividend Aristocrats ESG UCITS ETF',
    name: 'SPDR S&P U.S. Dividend Aristocrats ESG UCITS ETF',
    symbol: 'ZPD6.F',
  },
  {
    value: '7USH.DE',
    label: '7USH.DE,  Amundi Index Solutions - Amundi US Treasury 7-10',
    name: 'Amundi Index Solutions - Amundi US Treasury 7-10',
    symbol: '7USH.DE',
  },
  {
    value: 'ZERP.L',
    label: 'ZERP.L,  HANetf S&P Global Clean Energy Select HANzero UCITS ETF',
    name: 'HANetf S&P Global Clean Energy Select HANzero UCITS ETF',
    symbol: 'ZERP.L',
  },
  {
    value: 'D6RA.DE',
    label: 'D6RA.DE,  Deka MSCI EUR Corporates Climate Change ESG UCITS ETF',
    name: 'Deka MSCI EUR Corporates Climate Change ESG UCITS ETF',
    symbol: 'D6RA.DE',
  },
  {
    value: 'HJAP.F',
    label: 'HJAP.F,  HSBC MSCI Japan Climate Paris Aligned UCITS ETF',
    name: 'HSBC MSCI Japan Climate Paris Aligned UCITS ETF',
    symbol: 'HJAP.F',
  },
  {
    value: 'PAUE.F',
    label: 'PAUE.F,  Invesco MSCI Europe ESG Climate Paris Aligned UCITS ETF',
    name: 'Invesco MSCI Europe ESG Climate Paris Aligned UCITS ETF',
    symbol: 'PAUE.F',
  },
  {
    value: 'PAUJ.F',
    label: 'PAUJ.F,  Invesco MSCI Japan ESG Climate Paris Aligned UCITS ETF',
    name: 'Invesco MSCI Japan ESG Climate Paris Aligned UCITS ETF',
    symbol: 'PAUJ.F',
  },
  {
    value: 'PAUS.F',
    label: 'PAUS.F,  Invesco MSCI USA ESG Climate Paris Aligned UCITS ETF',
    name: 'Invesco MSCI USA ESG Climate Paris Aligned UCITS ETF',
    symbol: 'PAUS.F',
  },
  {
    value: 'EMIS.PA',
    label:
      'EMIS.PA,  BNP Paribas Easy MSCI Emerging SRI S-Series PAB 5% Capped',
    name: 'BNP Paribas Easy MSCI Emerging SRI S-Series PAB 5% Capped',
    symbol: 'EMIS.PA',
  },
  {
    value: 'XRME.F',
    label: 'XRME.F,  Xtrackers MSCI USA ESG Screened UCITS ETF',
    name: 'Xtrackers MSCI USA ESG Screened UCITS ETF',
    symbol: 'XRME.F',
  },
  {
    value: 'CSYU.F',
    label:
      'CSYU.F,  Credit Suisse Index Fund (IE) ETF ICAV - CSIF (IE) MSCI USA Tech 125 ESG Universal Blue UCITS ETF',
    name: 'Credit Suisse Index Fund (IE) ETF ICAV - CSIF (IE) MSCI USA Tech 125 ESG Universal Blue UCITS ETF',
    symbol: 'CSYU.F',
  },
  {
    value: 'SPF5.F',
    label: 'SPF5.F,  SPDR MSCI Europe Climate Paris Aligned UCITS ETF',
    name: 'SPDR MSCI Europe Climate Paris Aligned UCITS ETF',
    symbol: 'SPF5.F',
  },
  {
    value: 'SPF6.F',
    label: 'SPF6.F,  SPDR MSCI Japan Climate Paris Aligned UCITS ETF',
    name: 'SPDR MSCI Japan Climate Paris Aligned UCITS ETF',
    symbol: 'SPF6.F',
  },
  {
    value: '8OUU.DE',
    label: '8OUU.DE,  Amundi Index Solutions - Amundi Global AGG SRI',
    name: 'Amundi Index Solutions - Amundi Global AGG SRI',
    symbol: '8OUU.DE',
  },
  {
    value: '9E0E.DE',
    label: '9E0E.DE,  Amundi Index Solutions - Amundi Index Euro AGG SRI',
    name: 'Amundi Index Solutions - Amundi Index Euro AGG SRI',
    symbol: '9E0E.DE',
  },
  {
    value: 'XCS7.L',
    label: 'XCS7.L,  Xtrackers MSCI China UCITS ETF',
    name: 'Xtrackers MSCI China UCITS ETF',
    symbol: 'XCS7.L',
  },
  {
    value: 'AW1Q.DE',
    label:
      'AW1Q.DE,  UBS (Irl) ETF plc MSCI Japan Climate Paris Aligned UCITS ETF',
    name: 'UBS (Irl) ETF plc MSCI Japan Climate Paris Aligned UCITS ETF',
    symbol: 'AW1Q.DE',
  },
  {
    value: 'GLDU.AS',
    label: 'GLDU.AS,  Lyxor NYSE Arca Gold BUGS (DR) UCITS ETF',
    name: 'Lyxor NYSE Arca Gold BUGS (DR) UCITS ETF',
    symbol: 'GLDU.AS',
  },
  {
    value: 'C4D.PA',
    label: 'C4D.PA,  Amundi Index Solutions - Amundi CAC 40',
    name: 'Amundi Index Solutions - Amundi CAC 40',
    symbol: 'C4D.PA',
  },
  {
    value: 'EXX5.DE',
    label: 'EXX5.DE,  iShares Dow Jones U.S. Select Dividend UCITS ETF (DE)',
    name: 'iShares Dow Jones U.S. Select Dividend UCITS ETF (DE)',
    symbol: 'EXX5.DE',
  },
  {
    value: 'MGT.PA',
    label: 'MGT.PA,  Lyxor DJ Global Titans 50 UCITS ETF',
    name: 'Lyxor DJ Global Titans 50 UCITS ETF',
    symbol: 'MGT.PA',
  },
  {
    value: 'CACM.PA',
    label: 'CACM.PA,  Lyxor CAC Mid 60 (DR) UCITS ETF',
    name: 'Lyxor CAC Mid 60 (DR) UCITS ETF',
    symbol: 'CACM.PA',
  },
  {
    value: 'OPEN.L',
    label: 'OPEN.L,  iShares Refinitiv Inclusion and Diversity UCITS ETF',
    name: 'iShares Refinitiv Inclusion and Diversity UCITS ETF',
    symbol: 'OPEN.L',
  },
  {
    value: 'IPIR.MI',
    label:
      'IPIR.MI,  iShares VII PLC - iShares FTSE Italia Mid-Small Cap UCITS ETF',
    name: 'iShares VII PLC - iShares FTSE Italia Mid-Small Cap UCITS ETF',
    symbol: 'IPIR.MI',
  },
  {
    value: 'LOCK.L',
    label: 'LOCK.L,  iShares Digital Security UCITS ETF',
    name: 'iShares Digital Security UCITS ETF',
    symbol: 'LOCK.L',
  },
  {
    value: 'IWFV.L',
    label: 'IWFV.L,  iShares Edge MSCI World Value Factor UCITS ETF USD (Acc)',
    name: 'iShares Edge MSCI World Value Factor UCITS ETF USD (Acc)',
    symbol: 'IWFV.L',
  },
  {
    value: 'AGED.L',
    label: 'AGED.L,  iShares Ageing Population UCITS ETF',
    name: 'iShares Ageing Population UCITS ETF',
    symbol: 'AGED.L',
  },
  {
    value: 'XDER.L',
    label: 'XDER.L,  Xtrackers FTSE Developed Europe Real Estate UCITS ETF',
    name: 'Xtrackers FTSE Developed Europe Real Estate UCITS ETF',
    symbol: 'XDER.L',
  },
  {
    value: '100H.PA',
    label:
      '100H.PA,  MULTI-UNITS LUXEMBOURG - Lyxor FTSE 100 UCITS ETF - Monthly Hedged to EUR - Acc',
    name: 'MULTI-UNITS LUXEMBOURG - Lyxor FTSE 100 UCITS ETF - Monthly Hedged to EUR - Acc',
    symbol: '100H.PA',
  },
  {
    value: '10AJ.DE',
    label:
      '10AJ.DE,  Amundi Index Solutions - Amundi Index FTSE EPRA NAREIT Global',
    name: 'Amundi Index Solutions - Amundi Index FTSE EPRA NAREIT Global',
    symbol: '10AJ.DE',
  },
  {
    value: 'XX2D.L',
    label: 'XX2D.L,  Xtrackers FTSE China 50 UCITS ETF',
    name: 'Xtrackers FTSE China 50 UCITS ETF',
    symbol: 'XX2D.L',
  },
  {
    value: '100H.L',
    label: '100H.L,  MULTI-UNITS LUXEMBOURG - Lyxor FTSE 100 UCITS ETF',
    name: 'MULTI-UNITS LUXEMBOURG - Lyxor FTSE 100 UCITS ETF',
    symbol: '100H.L',
  },
  {
    value: 'MIDD.L',
    label: 'MIDD.L,  iShares FTSE 250 UCITS ETF',
    name: 'iShares FTSE 250 UCITS ETF',
    symbol: 'MIDD.L',
  },
  {
    value: 'XDUK.L',
    label: 'XDUK.L,  Xtrackers FTSE 100 UCITS ETF',
    name: 'Xtrackers FTSE 100 UCITS ETF',
    symbol: 'XDUK.L',
  },
  {
    value: 'GILS.L',
    label: 'GILS.L,  Lyxor Core UK Government Bond (DR) UCITS ETF',
    name: 'Lyxor Core UK Government Bond (DR) UCITS ETF',
    symbol: 'GILS.L',
  },
  {
    value: 'FTSE.PA',
    label: 'FTSE.PA,  Amundi MSCI UK IMI SRI UCITS ETF DR - GBP',
    name: 'Amundi MSCI UK IMI SRI UCITS ETF DR - GBP',
    symbol: 'FTSE.PA',
  },
  {
    value: 'XUKX.L',
    label: 'XUKX.L,  Xtrackers FTSE 100 Income UCITS ETF',
    name: 'Xtrackers FTSE 100 Income UCITS ETF',
    symbol: 'XUKX.L',
  },
  {
    value: 'DSB.PA',
    label: 'DSB.PA,  Lyxor Bund Daily (-2x) Inverse UCITS ETF',
    name: 'Lyxor Bund Daily (-2x) Inverse UCITS ETF',
    symbol: 'DSB.PA',
  },
  {
    value: 'DSUS.PA',
    label: 'DSUS.PA,  Lyxor 10Y US Treasury Daily (-2x) Inverse UCITS ETF',
    name: 'Lyxor 10Y US Treasury Daily (-2x) Inverse UCITS ETF',
    symbol: 'DSUS.PA',
  },
  {
    value: 'VHYL.L',
    label: 'VHYL.L,  Vanguard FTSE All-World High Dividend Yield UCITS ETF',
    name: 'Vanguard FTSE All-World High Dividend Yield UCITS ETF',
    symbol: 'VHYL.L',
  },
  {
    value: 'LYY8.F',
    label: 'LYY8.F,  MULTI-UNITS LUXEMBOURG - Lyxor DAILY LevDAX UCITS ETF',
    name: 'MULTI-UNITS LUXEMBOURG - Lyxor DAILY LevDAX UCITS ETF',
    symbol: 'LYY8.F',
  },
  {
    value: '5X62.DE',
    label: '5X62.DE,  Lyxor Bund Future Daily (-1x) Inverse UCITS ETF',
    name: 'Lyxor Bund Future Daily (-1x) Inverse UCITS ETF',
    symbol: '5X62.DE',
  },
  {
    value: 'XDCH.SW',
    label: 'XDCH.SW,  Xtrackers DAX ESG Screened UCITS ETF',
    name: 'Xtrackers DAX ESG Screened UCITS ETF',
    symbol: 'XDCH.SW',
  },
  {
    value: 'JPXX.L',
    label:
      'JPXX.L,  Lyxor MSCI Japan ESG Leaders Extra (DR) UCITS ETF - Daily Hedged to GBP Acc',
    name: 'Lyxor MSCI Japan ESG Leaders Extra (DR) UCITS ETF - Daily Hedged to GBP Acc',
    symbol: 'JPXX.L',
  },
  {
    value: 'JPXC.SW',
    label: 'JPXC.SW,  Lyxor MSCI Japan ESG Leaders Extra (DR) UCITS ETF',
    name: 'Lyxor MSCI Japan ESG Leaders Extra (DR) UCITS ETF',
    symbol: 'JPXC.SW',
  },
  {
    value: 'P58.SI',
    label: 'P58.SI,  Lyxor MSCI China ESG Leaders Extra (DR) UCITS ETF',
    name: 'Lyxor MSCI China ESG Leaders Extra (DR) UCITS ETF',
    symbol: 'P58.SI',
  },
  {
    value: 'IM2A.DE',
    label: 'IM2A.DE,  BNP Paribas Easy FTSE EPRA/NAREIT Eurozone Capped',
    name: 'BNP Paribas Easy FTSE EPRA/NAREIT Eurozone Capped',
    symbol: 'IM2A.DE',
  },
  {
    value: '6PSA.DE',
    label: '6PSA.DE,  Invesco FTSE RAFI US 1000 UCITS ETF',
    name: 'Invesco FTSE RAFI US 1000 UCITS ETF',
    symbol: '6PSA.DE',
  },
  {
    value: 'EL4A.DE',
    label: 'EL4A.DE,  Deka DAX UCITS ETF',
    name: 'Deka DAX UCITS ETF',
    symbol: 'EL4A.DE',
  },
  {
    value: 'EL4F.DE',
    label: 'EL4F.DE,  Deka DAX (ausschüttend) UCITS ETF',
    name: 'Deka DAX (ausschüttend) UCITS ETF',
    symbol: 'EL4F.DE',
  },
  {
    value: 'EL4G.DE',
    label: 'EL4G.DE,  Deka EURO STOXX Select Dividend 30 UCITS ETF',
    name: 'Deka EURO STOXX Select Dividend 30 UCITS ETF',
    symbol: 'EL4G.DE',
  },
  {
    value: 'EL4X.DE',
    label: 'EL4X.DE,  Deka DAXplus Maximum Dividend UCITS ETF',
    name: 'Deka DAXplus Maximum Dividend UCITS ETF',
    symbol: 'EL4X.DE',
  },
  {
    value: 'XLDX.L',
    label: 'XLDX.L,  Xtrackers LevDAX Daily Swap UCITS ETF',
    name: 'Xtrackers LevDAX Daily Swap UCITS ETF',
    symbol: 'XLDX.L',
  },
  {
    value: 'ELF0.DE',
    label: 'ELF0.DE,  Deka DAX ex Financials 30 UCITS ETF',
    name: 'Deka DAX ex Financials 30 UCITS ETF',
    symbol: 'ELF0.DE',
  },
  {
    value: 'ELF1.DE',
    label: 'ELF1.DE,  Deka MDAX UCITS ETF',
    name: 'Deka MDAX UCITS ETF',
    symbol: 'ELF1.DE',
  },
  {
    value: 'ELFC.DE',
    label: 'ELFC.DE,  Deka EURO iSTOXX ex Fin Dividend+ UCITS ETF',
    name: 'Deka EURO iSTOXX ex Fin Dividend+ UCITS ETF',
    symbol: 'ELFC.DE',
  },
  {
    value: 'EMHD.PA',
    label:
      'EMHD.PA,  Invesco FTSE Emerging Markets High Dividend Low Volatility UCITS ETF',
    name: 'Invesco FTSE Emerging Markets High Dividend Low Volatility UCITS ETF',
    symbol: 'EMHD.PA',
  },
  {
    value: 'EDEU.PA',
    label: 'EDEU.PA,  BNP Paribas Easy ESG Equity Dividend Europe',
    name: 'BNP Paribas Easy ESG Equity Dividend Europe',
    symbol: 'EDEU.PA',
  },
  {
    value: 'TDIV.AS',
    label:
      'TDIV.AS,  VanEck Morningstar Developed Markets Dividend Leaders UCITS ETF',
    name: 'VanEck Morningstar Developed Markets Dividend Leaders UCITS ETF',
    symbol: 'TDIV.AS',
  },
  {
    value: 'PMLP.L',
    label: 'PMLP.L,  Alerian Midstream Energy Dividend UCITS ETF',
    name: 'Alerian Midstream Energy Dividend UCITS ETF',
    symbol: 'PMLP.L',
  },
  {
    value: 'QDFD.AS',
    label:
      'QDFD.AS,  FlexShares Developed Markets High Dividend Climate ESG UCITS ETF',
    name: 'FlexShares Developed Markets High Dividend Climate ESG UCITS ETF',
    symbol: 'QDFD.AS',
  },
  {
    value: 'LDAP.MI',
    label:
      'LDAP.MI,  L&G Quality Equity Dividends ESG Exclusions Asia Pacific ex-Japan UCITS ETF',
    name: 'L&G Quality Equity Dividends ESG Exclusions Asia Pacific ex-Japan UCITS ETF',
    symbol: 'LDAP.MI',
  },
  {
    value: 'LDEU.L',
    label:
      'LDEU.L,  L&G Quality Equity Dividends ESG Exclusions Europe ex-UK UCITS ETF',
    name: 'L&G Quality Equity Dividends ESG Exclusions Europe ex-UK UCITS ETF',
    symbol: 'LDEU.L',
  },
  {
    value: 'UDIV.F',
    label: 'UDIV.F,  Global X SuperDividend UCITS ETF',
    name: 'Global X SuperDividend UCITS ETF',
    symbol: 'UDIV.F',
  },
  {
    value: 'BEL.BR',
    label: 'BEL.BR,  Lyxor BEL 20 TR (DR) UCITS ETF',
    name: 'Lyxor BEL 20 TR (DR) UCITS ETF',
    symbol: 'BEL.BR',
  },
  {
    value: 'BX4.PA',
    label: 'BX4.PA,  Lyxor CAC 40 Daily (-2x) Inverse UCITS ETF',
    name: 'Lyxor CAC 40 Daily (-2x) Inverse UCITS ETF',
    symbol: 'BX4.PA',
  },
  {
    value: 'SHC.PA',
    label: 'SHC.PA,  Lyxor CAC 40 Daily (-1x) Inverse UCITS ETF',
    name: 'Lyxor CAC 40 Daily (-1x) Inverse UCITS ETF',
    symbol: 'SHC.PA',
  },
  {
    value: 'LVC.PA',
    label: 'LVC.PA,  Lyxor CAC 40 Daily (2x) Leveraged UCITS ETF',
    name: 'Lyxor CAC 40 Daily (2x) Leveraged UCITS ETF',
    symbol: 'LVC.PA',
  },
  {
    value: 'C4S.PA',
    label: 'C4S.PA,  Amundi ETF Short CAC 40 Daily UCITS ETF',
    name: 'Amundi ETF Short CAC 40 Daily UCITS ETF',
    symbol: 'C4S.PA',
  },
  {
    value: 'BTPS.PA',
    label: 'BTPS.PA,  Lyxor BTP Daily (-2x) Inverse UCITS ETF',
    name: 'Lyxor BTP Daily (-2x) Inverse UCITS ETF',
    symbol: 'BTPS.PA',
  },
  {
    value: 'PDJE.PA',
    label: 'PDJE.PA,  Lyxor PEA Dow Jones Industrial Average UCITS ETF',
    name: 'Lyxor PEA Dow Jones Industrial Average UCITS ETF',
    symbol: 'PDJE.PA',
  },
  {
    value: 'PMEH.PA',
    label: 'PMEH.PA,  Lyxor PEA Immobilier Europe (FTSE EPRA/NAREIT) UCITS ETF',
    name: 'Lyxor PEA Immobilier Europe (FTSE EPRA/NAREIT) UCITS ETF',
    symbol: 'PMEH.PA',
  },
  {
    value: 'PAEJ.PA',
    label:
      'PAEJ.PA,  Lyxor PEA Asie Pacifique (MSCI AC Asia Pacific ex Japan) UCITS ETF',
    name: 'Lyxor PEA Asie Pacifique (MSCI AC Asia Pacific ex Japan) UCITS ETF',
    symbol: 'PAEJ.PA',
  },
  {
    value: 'PINR.PA',
    label: 'PINR.PA,  Lyxor PEA Inde (MSCI India) UCITS ETF',
    name: 'Lyxor PEA Inde (MSCI India) UCITS ETF',
    symbol: 'PINR.PA',
  },
  {
    value: 'PKRW.PA',
    label: 'PKRW.PA,  Lyxor PEA Coree (MSCI Korea) UCITS ETF',
    name: 'Lyxor PEA Coree (MSCI Korea) UCITS ETF',
    symbol: 'PKRW.PA',
  },
  {
    value: 'PASI.PA',
    label: 'PASI.PA,  Lyxor PEA Chine (MSCI China) UCITS ETF',
    name: 'Lyxor PEA Chine (MSCI China) UCITS ETF',
    symbol: 'PASI.PA',
  },
  {
    value: 'PUST.PA',
    label: 'PUST.PA,  Lyxor PEA Nasdaq-100 UCITS ETF',
    name: 'Lyxor PEA Nasdaq-100 UCITS ETF',
    symbol: 'PUST.PA',
  },
  {
    value: 'PSP5.PA',
    label: 'PSP5.PA,  Lyxor PEA S&P 500 UCITS ETF',
    name: 'Lyxor PEA S&P 500 UCITS ETF',
    symbol: 'PSP5.PA',
  },
  {
    value: 'PSPH.PA',
    label: 'PSPH.PA,  Lyxor PEA S&P 500 UCITS ETF',
    name: 'Lyxor PEA S&P 500 UCITS ETF',
    symbol: 'PSPH.PA',
  },
  {
    value: 'PCEU.PA',
    label: 'PCEU.PA,  Amundi ETF PEA MSCI Europe UCITS ETF',
    name: 'Amundi ETF PEA MSCI Europe UCITS ETF',
    symbol: 'PCEU.PA',
  },
  {
    value: 'C53D.PA',
    label:
      'C53D.PA,  Amundi ETF Govt Bond Euro Broad Investment Grade 5-7 UCITS ETF',
    name: 'Amundi ETF Govt Bond Euro Broad Investment Grade 5-7 UCITS ETF',
    symbol: 'C53D.PA',
  },
  {
    value: 'JPHG.L',
    label: 'JPHG.L,  Amundi Index Solutions - Amundi JPX-Nikkei 400',
    name: 'Amundi Index Solutions - Amundi JPX-Nikkei 400',
    symbol: 'JPHG.L',
  },
  {
    value: 'PSRM.L',
    label: 'PSRM.L,  Invesco FTSE RAFI Emerging Markets UCITS ETF',
    name: 'Invesco FTSE RAFI Emerging Markets UCITS ETF',
    symbol: 'PSRM.L',
  },
  {
    value: '6PSC.F',
    label: '6PSC.F,  Invesco FTSE RAFI Europe UCITS ETF',
    name: 'Invesco FTSE RAFI Europe UCITS ETF',
    symbol: '6PSC.F',
  },
  {
    value: 'EL4C.DE',
    label: 'EL4C.DE,  Deka STOXX Europe Strong Growth 20 UCITS ETF',
    name: 'Deka STOXX Europe Strong Growth 20 UCITS ETF',
    symbol: 'EL4C.DE',
  },
  {
    value: 'EL4E.DE',
    label: 'EL4E.DE,  Deka STOXX Europe Strong Style Composite 40 UCITS ETF',
    name: 'Deka STOXX Europe Strong Style Composite 40 UCITS ETF',
    symbol: 'EL4E.DE',
  },
  {
    value: 'EL4D.DE',
    label: 'EL4D.DE,  Deka STOXX Europe Strong Value 20 UCITS ETF',
    name: 'Deka STOXX Europe Strong Value 20 UCITS ETF',
    symbol: 'EL4D.DE',
  },
  {
    value: 'SC0C.F',
    label: 'SC0C.F,  Invesco STOXX Europe 600 UCITS ETF',
    name: 'Invesco STOXX Europe 600 UCITS ETF',
    symbol: 'SC0C.F',
  },
  {
    value: 'SC0G.DE',
    label: 'SC0G.DE,  Invesco STOXX Europe Mid 200 UCITS ETF',
    name: 'Invesco STOXX Europe Mid 200 UCITS ETF',
    symbol: 'SC0G.DE',
  },
  {
    value: 'SC0F.DE',
    label: 'SC0F.DE,  Invesco STOXX Europe Small 200 UCITS ETF',
    name: 'Invesco STOXX Europe Small 200 UCITS ETF',
    symbol: 'SC0F.DE',
  },
  {
    value: 'EL4Y.DE',
    label: 'EL4Y.DE,  Deka STOXX Europe 50 UCITS ETF',
    name: 'Deka STOXX Europe 50 UCITS ETF',
    symbol: 'EL4Y.DE',
  },
  {
    value: 'SC0P.F',
    label:
      'SC0P.F,  Invesco STOXX Europe 600 Optimised Automobiles & Parts UCITS ETF',
    name: 'Invesco STOXX Europe 600 Optimised Automobiles & Parts UCITS ETF',
    symbol: 'SC0P.F',
  },
  {
    value: 'SC0U.F',
    label: 'SC0U.F,  Invesco STOXX Europe 600 Optimised Banks UCITS ETF',
    name: 'Invesco STOXX Europe 600 Optimised Banks UCITS ETF',
    symbol: 'SC0U.F',
  },
  {
    value: 'SC0W.DE',
    label:
      'SC0W.DE,  Invesco STOXX Europe 600 Optimised Basic Resources UCITS ETF',
    name: 'Invesco STOXX Europe 600 Optimised Basic Resources UCITS ETF',
    symbol: 'SC0W.DE',
  },
  {
    value: 'SC00.F',
    label: 'SC00.F,  Invesco STOXX Europe 600 Optimised Chemicals UCITS ETF',
    name: 'Invesco STOXX Europe 600 Optimised Chemicals UCITS ETF',
    symbol: 'SC00.F',
  },
  {
    value: 'SC01.F',
    label:
      'SC01.F,  Invesco STOXX Europe 600 Optimised Construction & Materials UCITS ETF',
    name: 'Invesco STOXX Europe 600 Optimised Construction & Materials UCITS ETF',
    symbol: 'SC01.F',
  },
  {
    value: 'SC02.F',
    label:
      'SC02.F,  Invesco STOXX Europe 600 Optimised Financial Services UCITS ETF',
    name: 'Invesco STOXX Europe 600 Optimised Financial Services UCITS ETF',
    symbol: 'SC02.F',
  },
  {
    value: 'SC03.F',
    label:
      'SC03.F,  Invesco STOXX Europe 600 Optimised Food & Beverage UCITS ETF',
    name: 'Invesco STOXX Europe 600 Optimised Food & Beverage UCITS ETF',
    symbol: 'SC03.F',
  },
  {
    value: 'SC0T.F',
    label: 'SC0T.F,  Invesco STOXX Europe 600 Optimised Health Care UCITS ETF',
    name: 'Invesco STOXX Europe 600 Optimised Health Care UCITS ETF',
    symbol: 'SC0T.F',
  },
  {
    value: 'SC0S.F',
    label:
      'SC0S.F,  Invesco STOXX Europe 600 Optimised Industrial Goods & Services UCITS ETF',
    name: 'Invesco STOXX Europe 600 Optimised Industrial Goods & Services UCITS ETF',
    symbol: 'SC0S.F',
  },
  {
    value: 'SC0Y.F',
    label: 'SC0Y.F,  Invesco STOXX Europe 600 Optimised Insurance UCITS ETF',
    name: 'Invesco STOXX Europe 600 Optimised Insurance UCITS ETF',
    symbol: 'SC0Y.F',
  },
  {
    value: 'SC06.F',
    label: 'SC06.F,  Invesco STOXX Europe 600 Optimised Media UCITS ETF',
    name: 'Invesco STOXX Europe 600 Optimised Media UCITS ETF',
    symbol: 'SC06.F',
  },
  {
    value: 'SC0V.F',
    label: 'SC0V.F,  Invesco STOXX Europe 600 Optimised Oil & Gas UCITS ETF',
    name: 'Invesco STOXX Europe 600 Optimised Oil & Gas UCITS ETF',
    symbol: 'SC0V.F',
  },
  {
    value: 'SC04.F',
    label:
      'SC04.F,  Invesco STOXX Europe 600 Optimised Personal & Household Goods UCITS ETF',
    name: 'Invesco STOXX Europe 600 Optimised Personal & Household Goods UCITS ETF',
    symbol: 'SC04.F',
  },
  {
    value: 'SC05.F',
    label: 'SC05.F,  Invesco STOXX Europe 600 Optimised Retail UCITS ETF',
    name: 'Invesco STOXX Europe 600 Optimised Retail UCITS ETF',
    symbol: 'SC05.F',
  },
  {
    value: 'SC0Q.F',
    label:
      'SC0Q.F,  Invesco STOXX Europe 600 Optimised Telecommunications UCITS ETF',
    name: 'Invesco STOXX Europe 600 Optimised Telecommunications UCITS ETF',
    symbol: 'SC0Q.F',
  },
  {
    value: 'SC0R.F',
    label:
      'SC0R.F,  Invesco STOXX Europe 600 Optimised Travel & Leisure UCITS ETF',
    name: 'Invesco STOXX Europe 600 Optimised Travel & Leisure UCITS ETF',
    symbol: 'SC0R.F',
  },
  {
    value: 'SC0Z.DE',
    label: 'SC0Z.DE,  Invesco STOXX Europe 600 Optimised Utilities UCITS ETF',
    name: 'Invesco STOXX Europe 600 Optimised Utilities UCITS ETF',
    symbol: 'SC0Z.DE',
  },
  {
    value: 'HUKX.L',
    label: 'HUKX.L,  HSBC FTSE 100 UCITS ETF',
    name: 'HSBC FTSE 100 UCITS ETF',
    symbol: 'HUKX.L',
  },
  {
    value: 'S7XE.F',
    label: 'S7XE.F,  Invesco EURO STOXX Optimised Banks UCITS ETF',
    name: 'Invesco EURO STOXX Optimised Banks UCITS ETF',
    symbol: 'S7XE.F',
  },
  {
    value: 'SPYF.DE',
    label: 'SPYF.DE,  SPDR FTSE UK All Share UCITS ETF Acc',
    name: 'SPDR FTSE UK All Share UCITS ETF Acc',
    symbol: 'SPYF.DE',
  },
  {
    value: 'ETSZ.DE',
    label: 'ETSZ.DE,  BNP Paribas Easy Stoxx Europe 600 UCITS ETF',
    name: 'BNP Paribas Easy Stoxx Europe 600 UCITS ETF',
    symbol: 'ETSZ.DE',
  },
  {
    value: 'ZPRL.F',
    label: 'ZPRL.F,  SPDR EURO STOXX Low Volatility UCITS ETF',
    name: 'SPDR EURO STOXX Low Volatility UCITS ETF',
    symbol: 'ZPRL.F',
  },
  {
    value: 'PSRW.L',
    label: 'PSRW.L,  Invesco FTSE RAFI All World 3000 UCITS ETF',
    name: 'Invesco FTSE RAFI All World 3000 UCITS ETF',
    symbol: 'PSRW.L',
  },
  {
    value: 'EEP.PA',
    label: 'EEP.PA,  BNP Paribas Easy FTSE EPRA/NAREIT Developed Europe',
    name: 'BNP Paribas Easy FTSE EPRA/NAREIT Developed Europe',
    symbol: 'EEP.PA',
  },
  {
    value: 'VUKE.L',
    label: 'VUKE.L,  Vanguard FTSE 100 UCITS ETF',
    name: 'Vanguard FTSE 100 UCITS ETF',
    symbol: 'VUKE.L',
  },
  {
    value: 'VMID.L',
    label: 'VMID.L,  Vanguard FTSE 250 UCITS ETF',
    name: 'Vanguard FTSE 250 UCITS ETF',
    symbol: 'VMID.L',
  },
  {
    value: 'VAPX.L',
    label: 'VAPX.L,  Vanguard FTSE Developed Asia Pacific ex Japan UCITS ETF',
    name: 'Vanguard FTSE Developed Asia Pacific ex Japan UCITS ETF',
    symbol: 'VAPX.L',
  },
  {
    value: 'VERX.AS',
    label: 'VERX.AS,  Vanguard FTSE Developed Europe ex UK UCITS ETF',
    name: 'Vanguard FTSE Developed Europe ex UK UCITS ETF',
    symbol: 'VERX.AS',
  },
  {
    value: 'VEUR.AS',
    label: 'VEUR.AS,  Vanguard FTSE Developed Europe UCITS ETF',
    name: 'Vanguard FTSE Developed Europe UCITS ETF',
    symbol: 'VEUR.AS',
  },
  {
    value: 'VEVE.L',
    label: 'VEVE.L,  Vanguard FTSE Developed World UCITS ETF',
    name: 'Vanguard FTSE Developed World UCITS ETF',
    symbol: 'VEVE.L',
  },
  {
    value: 'VFEM.L',
    label: 'VFEM.L,  Vanguard FTSE Emerging Markets UCITS ETF',
    name: 'Vanguard FTSE Emerging Markets UCITS ETF',
    symbol: 'VFEM.L',
  },
  {
    value: 'VJPN.SW',
    label: 'VJPN.SW,  Vanguard FTSE Japan UCITS ETF',
    name: 'Vanguard FTSE Japan UCITS ETF',
    symbol: 'VJPN.SW',
  },
  {
    value: 'EEA.PA',
    label: 'EEA.PA,  BNP Paribas Easy FTSE EPRA/NAREIT Eurozone Capped',
    name: 'BNP Paribas Easy FTSE EPRA/NAREIT Eurozone Capped',
    symbol: 'EEA.PA',
  },
  {
    value: 'FTAD.L',
    label: 'FTAD.L,  SPDR FTSE UK All Share UCITS ETF',
    name: 'SPDR FTSE UK All Share UCITS ETF',
    symbol: 'FTAD.L',
  },
  {
    value: 'FLXB.F',
    label: 'FLXB.F,  Franklin FTSE Brazil UCITS ETF',
    name: 'Franklin FTSE Brazil UCITS ETF',
    symbol: 'FLXB.F',
  },
  {
    value: 'FLXC.F',
    label: 'FLXC.F,  Franklin FTSE China UCITS ETF',
    name: 'Franklin FTSE China UCITS ETF',
    symbol: 'FLXC.F',
  },
  {
    value: 'FLXK.L',
    label: 'FLXK.L,  Franklin FTSE Korea UCITS ETF',
    name: 'Franklin FTSE Korea UCITS ETF',
    symbol: 'FLXK.L',
  },
  {
    value: 'VJPA.L',
    label: 'VJPA.L,  Vanguard FTSE Japan UCITS ETF',
    name: 'Vanguard FTSE Japan UCITS ETF',
    symbol: 'VJPA.L',
  },
  {
    value: '600X.AS',
    label: '600X.AS,  SPDR STOXX Europe 600 SRI UCITS ETF',
    name: 'SPDR STOXX Europe 600 SRI UCITS ETF',
    symbol: '600X.AS',
  },
  {
    value: 'VHVE.L',
    label: 'VHVE.L,  Vanguard FTSE Developed World UCITS ETF',
    name: 'Vanguard FTSE Developed World UCITS ETF',
    symbol: 'VHVE.L',
  },
  {
    value: 'VJPE.DE',
    label: 'VJPE.DE,  Vanguard FTSE Japan UCITS ETF EUR Hedged Accumulation',
    name: 'Vanguard FTSE Japan UCITS ETF EUR Hedged Accumulation',
    symbol: 'VJPE.DE',
  },
  {
    value: 'EEEH.PA',
    label: 'EEEH.PA,  BNP Paribas Easy FTSE EPRA/NAREIT Developed Europe',
    name: 'BNP Paribas Easy FTSE EPRA/NAREIT Developed Europe',
    symbol: 'EEEH.PA',
  },
  {
    value: 'PR1E.F',
    label: 'PR1E.F,  Amundi Index Solutions - Amundi Prime Europe',
    name: 'Amundi Index Solutions - Amundi Prime Europe',
    symbol: 'PR1E.F',
  },
  {
    value: 'PRIC.L',
    label: 'PRIC.L,  Amundi Index Solutions - Amundi Prime Euro Corporates',
    name: 'Amundi Index Solutions - Amundi Prime Euro Corporates',
    symbol: 'PRIC.L',
  },
  {
    value: 'PRIP.L',
    label: 'PRIP.L,  Amundi Index Solutions - Amundi Prime US Corporates',
    name: 'Amundi Index Solutions - Amundi Prime US Corporates',
    symbol: 'PRIP.L',
  },
  {
    value: 'JPNH.PA',
    label: 'JPNH.PA,  Lyxor Japan (Topix) (DR) UCITS ETF',
    name: 'Lyxor Japan (Topix) (DR) UCITS ETF',
    symbol: 'JPNH.PA',
  },
  {
    value: 'CACC.PA',
    label: 'CACC.PA,  Lyxor CAC 40 (DR) UCITS ETF',
    name: 'Lyxor CAC 40 (DR) UCITS ETF',
    symbol: 'CACC.PA',
  },
  {
    value: 'IAEX.L',
    label: 'IAEX.L,  iShares AEX UCITS ETF',
    name: 'iShares AEX UCITS ETF',
    symbol: 'IAEX.L',
  },
  {
    value: 'IUSP.AS',
    label: 'IUSP.AS,  iShares US Property Yield UCITS ETF',
    name: 'iShares US Property Yield UCITS ETF',
    symbol: 'IUSP.AS',
  },
  {
    value: 'EXAG.DE',
    label: 'EXAG.DE,  WisdomTree Enhanced Commodity ex-Agriculture UCITS ETF',
    name: 'WisdomTree Enhanced Commodity ex-Agriculture UCITS ETF',
    symbol: 'EXAG.DE',
  },
  {
    value: 'DPYA.L',
    label: 'DPYA.L,  iShares Developed Markets Property Yield UCITS ETF',
    name: 'iShares Developed Markets Property Yield UCITS ETF',
    symbol: 'DPYA.L',
  },
  {
    value: 'ECOP.MI',
    label: 'ECOP.MI,  WisdomTree Copper - EUR Daily Hedged',
    name: 'WisdomTree Copper - EUR Daily Hedged',
    symbol: 'ECOP.MI',
  },
  {
    value: 'ENIK.MI',
    label: 'ENIK.MI,  WisdomTree Nickel - EUR Daily Hedged',
    name: 'WisdomTree Nickel - EUR Daily Hedged',
    symbol: 'ENIK.MI',
  },
  {
    value: 'XCHA.L',
    label: 'XCHA.L,  Xtrackers CSI300 Swap UCITS ETF',
    name: 'Xtrackers CSI300 Swap UCITS ETF',
    symbol: 'XCHA.L',
  },
  {
    value: 'INFG.L',
    label: 'INFG.L,  Lyxor US$ 10Y Inflation Expectations UCITS ETF',
    name: 'Lyxor US$ 10Y Inflation Expectations UCITS ETF',
    symbol: 'INFG.L',
  },
  {
    value: 'GAGU.AS',
    label:
      'GAGU.AS,  Amundi Index Solutions - Amundi Index Barclays Global AGG 500M UCITS ETF DR',
    name: 'Amundi Index Solutions - Amundi Index Barclays Global AGG 500M UCITS ETF DR',
    symbol: 'GAGU.AS',
  },
  {
    value: 'UBBB.MI',
    label: 'UBBB.MI,  Amundi Index Solutions - Amundi Global Corp SRI 1-5Y',
    name: 'Amundi Index Solutions - Amundi Global Corp SRI 1-5Y',
    symbol: 'UBBB.MI',
  },
  {
    value: 'GINF.PA',
    label: 'GINF.PA,  Amundi Index Solutions - Amundi Global Infrastructure',
    name: 'Amundi Index Solutions - Amundi Global Infrastructure',
    symbol: 'GINF.PA',
  },
  {
    value: 'JPXG.L',
    label: 'JPXG.L,  Lyxor MSCI Japan ESG Leaders Extra (DR) UCITS ETF',
    name: 'Lyxor MSCI Japan ESG Leaders Extra (DR) UCITS ETF',
    symbol: 'JPXG.L',
  },
  {
    value: 'JPXH.PA',
    label: 'JPXH.PA,  Lyxor MSCI Japan ESG Leaders Extra (DR) UCITS ETF',
    name: 'Lyxor MSCI Japan ESG Leaders Extra (DR) UCITS ETF',
    symbol: 'JPXH.PA',
  },
  {
    value: 'CC4.PA',
    label: 'CC4.PA,  Amundi Index Solutions - Amundi EURO Corporates {d}',
    name: 'Amundi Index Solutions - Amundi EURO Corporates {d}',
    symbol: 'CC4.PA',
  },
  {
    value: 'C40.PA',
    label: 'C40.PA,  Amundi Index Solutions - Amundi CAC 40',
    name: 'Amundi Index Solutions - Amundi CAC 40',
    symbol: 'C40.PA',
  },
  {
    value: 'CRB.PA',
    label:
      'CRB.PA,  MULTI-UNITS LUXEMBOURG - Lyxor Commodities Refinitiv/CoreCommodity CRB TR UCITS ETF',
    name: 'MULTI-UNITS LUXEMBOURG - Lyxor Commodities Refinitiv/CoreCommodity CRB TR UCITS ETF',
    symbol: 'CRB.PA',
  },
  {
    value: 'XDJE.DE',
    label: 'XDJE.DE,  Xtrackers Nikkei 225 UCITS ETF',
    name: 'Xtrackers Nikkei 225 UCITS ETF',
    symbol: 'XDJE.DE',
  },
  {
    value: 'PRAR.F',
    label: 'PRAR.F,  Amundi Index Solutions - Amundi Prime Euro Govies',
    name: 'Amundi Index Solutions - Amundi Prime Euro Govies',
    symbol: 'PRAR.F',
  },
  {
    value: 'PRUK.L',
    label:
      'PRUK.L,  Amundi Index Solutions - Amundi Prime UK Mid & Small Cap UCITS ETF',
    name: 'Amundi Index Solutions - Amundi Prime UK Mid & Small Cap UCITS ETF',
    symbol: 'PRUK.L',
  },
  {
    value: 'UCRH.F',
    label:
      'UCRH.F,  Amundi Index Solutions - Amundi Index US Corp SRI UCITS ETF DR Hedged EUR Inc',
    name: 'Amundi Index Solutions - Amundi Index US Corp SRI UCITS ETF DR Hedged EUR Inc',
    symbol: 'UCRH.F',
  },
  {
    value: 'HYC.MI',
    label:
      'HYC.MI,  Lyxor Index Fund - Lyxor ESG Euro High Yield (DR) UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor ESG Euro High Yield (DR) UCITS ETF',
    symbol: 'HYC.MI',
  },
  {
    value: 'F702.DE',
    label:
      'F702.DE,  Lyxor Portfolio Strategy Defensiv UCITS ETF (Vermoegensstrategie)',
    name: 'Lyxor Portfolio Strategy Defensiv UCITS ETF (Vermoegensstrategie)',
    symbol: 'F702.DE',
  },
  {
    value: 'XS7W.DE',
    label: 'XS7W.DE,  Xtrackers Portfolio Income UCITS ETF',
    name: 'Xtrackers Portfolio Income UCITS ETF',
    symbol: 'XS7W.DE',
  },
  {
    value: 'ICOM.L',
    label: 'ICOM.L,  iShares Diversified Commodity Swap UCITS ETF',
    name: 'iShares Diversified Commodity Swap UCITS ETF',
    symbol: 'ICOM.L',
  },
  {
    value: 'XRS2.F',
    label: 'XRS2.F,  Xtrackers Russell 2000 UCITS ETF',
    name: 'Xtrackers Russell 2000 UCITS ETF',
    symbol: 'XRS2.F',
  },
  {
    value: 'IMBE.AS',
    label: 'IMBE.AS,  iShares US Mortgage Backed Securities UCITS ETF',
    name: 'iShares US Mortgage Backed Securities UCITS ETF',
    symbol: 'IMBE.AS',
  },
  {
    value: 'PCOM.L',
    label: 'PCOM.L,  WisdomTree Broad Commodities UCITS ETF',
    name: 'WisdomTree Broad Commodities UCITS ETF',
    symbol: 'PCOM.L',
  },
  {
    value: 'MAGR.DE',
    label: 'MAGR.DE,  BlackRock ESG Multi-Asset Growth Portfolio UCITS ETF',
    name: 'BlackRock ESG Multi-Asset Growth Portfolio UCITS ETF',
    symbol: 'MAGR.DE',
  },
  {
    value: 'IAEA.AS',
    label: 'IAEA.AS,  iShares AEX UCITS ETF',
    name: 'iShares AEX UCITS ETF',
    symbol: 'IAEA.AS',
  },
  {
    value: 'IWFS.L',
    label: 'IWFS.L,  iShares Edge MSCI World Size Factor UCITS ETF',
    name: 'iShares Edge MSCI World Size Factor UCITS ETF',
    symbol: 'IWFS.L',
  },
  {
    value: 'IMBA.L',
    label: 'IMBA.L,  iShares US Mortgage Backed Securities UCITS ETF',
    name: 'iShares US Mortgage Backed Securities UCITS ETF',
    symbol: 'IMBA.L',
  },
  {
    value: 'EBUL.MI',
    label: 'EBUL.MI,  WisdomTree Gold - EUR Daily Hedged',
    name: 'WisdomTree Gold - EUR Daily Hedged',
    symbol: 'EBUL.MI',
  },
  {
    value: 'ECOF.MI',
    label: 'ECOF.MI,  WisdomTree Coffee - EUR Daily Hedged',
    name: 'WisdomTree Coffee - EUR Daily Hedged',
    symbol: 'ECOF.MI',
  },
  {
    value: 'ECTN.MI',
    label: 'ECTN.MI,  WisdomTree Cotton - EUR Daily Hedged',
    name: 'WisdomTree Cotton - EUR Daily Hedged',
    symbol: 'ECTN.MI',
  },
  {
    value: 'DXSM.F',
    label:
      'DXSM.F,  Xtrackers Bloomberg Commodity ex-Agriculture & Livestock Swap UCITS ETF 1C EUR Hedged',
    name: 'Xtrackers Bloomberg Commodity ex-Agriculture & Livestock Swap UCITS ETF 1C EUR Hedged',
    symbol: 'DXSM.F',
  },
  {
    value: 'DXS0.DE',
    label: 'DXS0.DE,  Xtrackers SLI UCITS ETF',
    name: 'Xtrackers SLI UCITS ETF',
    symbol: 'DXS0.DE',
  },
  {
    value: 'XDBG.L',
    label:
      'XDBG.L,  Xtrackers Bloomberg Commodity ex-Agriculture & Livestock Swap UCITS ETF',
    name: 'Xtrackers Bloomberg Commodity ex-Agriculture & Livestock Swap UCITS ETF',
    symbol: 'XDBG.L',
  },
  {
    value: 'SGQI.PA',
    label: 'SGQI.PA,  Lyxor SG Global Quality Income NTR UCITS ETF',
    name: 'Lyxor SG Global Quality Income NTR UCITS ETF',
    symbol: 'SGQI.PA',
  },
  {
    value: 'XESD.DE',
    label: 'XESD.DE,  Xtrackers Spain UCITS ETF',
    name: 'Xtrackers Spain UCITS ETF',
    symbol: 'XESD.DE',
  },
  {
    value: 'LGQM.DE',
    label: 'LGQM.DE,  MULTI-UNITS LUXEMBOURG - Lyxor Pan Africa UCITS ETF',
    name: 'MULTI-UNITS LUXEMBOURG - Lyxor Pan Africa UCITS ETF',
    symbol: 'LGQM.DE',
  },
  {
    value: 'ECRP.PA',
    label:
      'ECRP.PA,  Amundi Index Solutions - Amundi Index Euro Corporate SRI UCITS ETF DR',
    name: 'Amundi Index Solutions - Amundi Index Euro Corporate SRI UCITS ETF DR',
    symbol: 'ECRP.PA',
  },
  {
    value: 'SMRT.PA',
    label:
      'SMRT.PA,  Amundi Index Solutions - Amundi Index Equity Global Multi Smart Allocation Scientific Beta',
    name: 'Amundi Index Solutions - Amundi Index Equity Global Multi Smart Allocation Scientific Beta',
    symbol: 'SMRT.PA',
  },
  {
    value: 'JPNE.F',
    label: 'JPNE.F,  Lyxor MSCI Japan ESG Leaders Extra (DR) UCITS ETF',
    name: 'Lyxor MSCI Japan ESG Leaders Extra (DR) UCITS ETF',
    symbol: 'JPNE.F',
  },
  {
    value: 'GOVH.PA',
    label:
      'GOVH.PA,  Amundi Index Solutions - Amundi Index J.P. Morgan GBI Global Govies',
    name: 'Amundi Index Solutions - Amundi Index J.P. Morgan GBI Global Govies',
    symbol: 'GOVH.PA',
  },
  {
    value: 'LCUK.L',
    label: 'LCUK.L,  Lyxor Core UK Equity All Cap (DR) UCITS ETF',
    name: 'Lyxor Core UK Equity All Cap (DR) UCITS ETF',
    symbol: 'LCUK.L',
  },
  {
    value: 'AWAT.PA',
    label: 'AWAT.PA,  Lyxor PEA Eau (MSCI Water) UCITS ETF',
    name: 'Lyxor PEA Eau (MSCI Water) UCITS ETF',
    symbol: 'AWAT.PA',
  },
  {
    value: 'OBLI.PA',
    label: "OBLI.PA,  Lyxor PEA Obligations d'État Euro UCITS ETF",
    name: "Lyxor PEA Obligations d'État Euro UCITS ETF",
    symbol: 'OBLI.PA',
  },
  {
    value: 'CRBH.MI',
    label:
      'CRBH.MI,  MULTI-UNITS LUXEMBOURG - Lyxor Commodities Refinitiv/CoreCommodity CRB TR UCITS ETF',
    name: 'MULTI-UNITS LUXEMBOURG - Lyxor Commodities Refinitiv/CoreCommodity CRB TR UCITS ETF',
    symbol: 'CRBH.MI',
  },
  {
    value: 'CS9.PA',
    label:
      'CS9.PA,  Amundi Index Solutions - Amundi MSCI Europe Ex Switzerland ESG Leaders',
    name: 'Amundi Index Solutions - Amundi MSCI Europe Ex Switzerland ESG Leaders',
    symbol: 'CS9.PA',
  },
  {
    value: 'CEC.PA',
    label: 'CEC.PA,  Lyxor MSCI Eastern Europe ex Russia UCITS ETF Acc',
    name: 'Lyxor MSCI Eastern Europe ex Russia UCITS ETF Acc',
    symbol: 'CEC.PA',
  },
  {
    value: 'AEJ.PA',
    label:
      'AEJ.PA,  MULTI-UNITS LUXEMBOURG - Lyxor MSCI AC Asia Pacific Ex Japan UCITS ETF',
    name: 'MULTI-UNITS LUXEMBOURG - Lyxor MSCI AC Asia Pacific Ex Japan UCITS ETF',
    symbol: 'AEJ.PA',
  },
  {
    value: 'TPXH.PA',
    label:
      'TPXH.PA,  Amundi Index Solutions - Amundi Japan Topix UCITS ETF-C EUR Hedged',
    name: 'Amundi Index Solutions - Amundi Japan Topix UCITS ETF-C EUR Hedged',
    symbol: 'TPXH.PA',
  },
  {
    value: 'EMXC.L',
    label: 'EMXC.L,  Lyxor MSCI Emerging Markets Ex China UCITS ETF',
    name: 'Lyxor MSCI Emerging Markets Ex China UCITS ETF',
    symbol: 'EMXC.L',
  },
  {
    value: 'EXHA.DE',
    label: 'EXHA.DE,  iShares eb.rexx Government Germany UCITS ETF (DE)',
    name: 'iShares eb.rexx Government Germany UCITS ETF (DE)',
    symbol: 'EXHA.DE',
  },
  {
    value: 'PRIW.L',
    label: 'PRIW.L,  Amundi Index Solutions - Amundi Prime Global UCITS ETF DR',
    name: 'Amundi Index Solutions - Amundi Prime Global UCITS ETF DR',
    symbol: 'PRIW.L',
  },
  {
    value: 'SADH.F',
    label:
      'SADH.F,  Amundi Index Solutions - Amundi MSCI USA ESG Leaders Select',
    name: 'Amundi Index Solutions - Amundi MSCI USA ESG Leaders Select',
    symbol: 'SADH.F',
  },
  {
    value: 'EGRI.PA',
    label: 'EGRI.PA,  Amundi Index Solutions - Amundi Index Euro AGG SRI',
    name: 'Amundi Index Solutions - Amundi Index Euro AGG SRI',
    symbol: 'EGRI.PA',
  },
  {
    value: 'EXHB.DE',
    label:
      'EXHB.DE,  iShares eb.rexx Government Germany 1.5-2.5yr UCITS ETF (DE)',
    name: 'iShares eb.rexx Government Germany 1.5-2.5yr UCITS ETF (DE)',
    symbol: 'EXHB.DE',
  },
  {
    value: 'F703.DE',
    label:
      'F703.DE,  Lyxor Portfolio Strategy Offensiv UCITS ETF (Vermoegensstrategie)',
    name: 'Lyxor Portfolio Strategy Offensiv UCITS ETF (Vermoegensstrategie)',
    symbol: 'F703.DE',
  },
  {
    value: 'NRJ.PA',
    label: 'NRJ.PA,  Lyxor New Energy (DR) UCITS ETF Dist',
    name: 'Lyxor New Energy (DR) UCITS ETF Dist',
    symbol: 'NRJ.PA',
  },
  {
    value: 'CI3.PA',
    label: 'CI3.PA,  Amundi ETF Euro Inflation UCITS ETF DR',
    name: 'Amundi ETF Euro Inflation UCITS ETF DR',
    symbol: 'CI3.PA',
  },
  {
    value: 'IASP.L',
    label: 'IASP.L,  iShares Asia Property Yield UCITS ETF USD (Dist)',
    name: 'iShares Asia Property Yield UCITS ETF USD (Dist)',
    symbol: 'IASP.L',
  },
  {
    value: 'IH2O.L',
    label: 'IH2O.L,  iShares Global Water UCITS ETF USD (Dist)',
    name: 'iShares Global Water UCITS ETF USD (Dist)',
    symbol: 'IH2O.L',
  },
  {
    value: 'AYEP.F',
    label: 'AYEP.F,  iShares Asia Property Yield UCITS ETF',
    name: 'iShares Asia Property Yield UCITS ETF',
    symbol: 'AYEP.F',
  },
  {
    value: 'IEFQ.L',
    label: 'IEFQ.L,  iShares Edge MSCI Europe Quality Factor UCITS ETF',
    name: 'iShares Edge MSCI Europe Quality Factor UCITS ETF',
    symbol: 'IEFQ.L',
  },
  {
    value: 'DGIT.L',
    label: 'DGIT.L,  iShares Digitalisation UCITS ETF',
    name: 'iShares Digitalisation UCITS ETF',
    symbol: 'DGIT.L',
  },
  {
    value: 'QDVP.DE',
    label:
      'QDVP.DE,  iShares US Mortgage Backed Securities UCITS ETF USD (Dist)',
    name: 'iShares US Mortgage Backed Securities UCITS ETF USD (Dist)',
    symbol: 'QDVP.DE',
  },
  {
    value: 'SGBS.L',
    label: 'SGBS.L,  WisdomTree Physical Swiss Gold',
    name: 'WisdomTree Physical Swiss Gold',
    symbol: 'SGBS.L',
  },
  {
    value: 'EALU.MI',
    label: 'EALU.MI,  WisdomTree Aluminium - EUR Daily Hedged',
    name: 'WisdomTree Aluminium - EUR Daily Hedged',
    symbol: 'EALU.MI',
  },
  {
    value: 'EBRT.MI',
    label: 'EBRT.MI,  WisdomTree Brent Crude Oil - EUR Daily Hedged',
    name: 'WisdomTree Brent Crude Oil - EUR Daily Hedged',
    symbol: 'EBRT.MI',
  },
  {
    value: 'DBX0.DE',
    label: 'DBX0.DE,  Xtrackers Portfolio UCITS ETF',
    name: 'Xtrackers Portfolio UCITS ETF',
    symbol: 'DBX0.DE',
  },
  {
    value: 'EGOV.PA',
    label:
      'EGOV.PA,  Amundi Index Solutions - Amundi Index JP Morgan EMU Govies',
    name: 'Amundi Index Solutions - Amundi Index JP Morgan EMU Govies',
    symbol: 'EGOV.PA',
  },
  {
    value: 'GAGH.PA',
    label: 'GAGH.PA,  Amundi Index Solutions - Amundi Index Global AGG 500M',
    name: 'Amundi Index Solutions - Amundi Index Global AGG 500M',
    symbol: 'GAGH.PA',
  },
  {
    value: 'PRIJ.L',
    label: 'PRIJ.L,  Amundi Index Solutions - Amundi Prime Japan',
    name: 'Amundi Index Solutions - Amundi Prime Japan',
    symbol: 'PRIJ.L',
  },
  {
    value: 'PRWU.L',
    label: 'PRWU.L,  Amundi Index Solutions - Amundi Prime Global',
    name: 'Amundi Index Solutions - Amundi Prime Global',
    symbol: 'PRWU.L',
  },
  {
    value: 'IWDP.SW',
    label:
      'IWDP.SW,  iShares Developed Markets Property Yield UCITS ETF USD (Dist)',
    name: 'iShares Developed Markets Property Yield UCITS ETF USD (Dist)',
    symbol: 'IWDP.SW',
  },
  {
    value: 'IGLT.MI',
    label: 'IGLT.MI,  iShares Core UK Gilts UCITS ETF GBP (Dist)',
    name: 'iShares Core UK Gilts UCITS ETF GBP (Dist)',
    symbol: 'IGLT.MI',
  },
  {
    value: 'IUKP.L',
    label: 'IUKP.L,  iShares UK Property UCITS ETF',
    name: 'iShares UK Property UCITS ETF',
    symbol: 'IUKP.L',
  },
  {
    value: 'ESVR.MI',
    label: 'ESVR.MI,  WisdomTree Silver - EUR Daily Hedged',
    name: 'WisdomTree Silver - EUR Daily Hedged',
    symbol: 'ESVR.MI',
  },
  {
    value: 'ESUG.MI',
    label: 'ESUG.MI,  WisdomTree Sugar - EUR Daily Hedged',
    name: 'WisdomTree Sugar - EUR Daily Hedged',
    symbol: 'ESUG.MI',
  },
  {
    value: 'C090.DE',
    label:
      'C090.DE,  Lyxor Bloomberg Equal-weight Commodity ex-Agriculture UCITS ETF',
    name: 'Lyxor Bloomberg Equal-weight Commodity ex-Agriculture UCITS ETF',
    symbol: 'C090.DE',
  },
  {
    value: 'TPHC.PA',
    label: 'TPHC.PA,  Amundi Index Solutions - Amundi Japan Topix',
    name: 'Amundi Index Solutions - Amundi Japan Topix',
    symbol: 'TPHC.PA',
  },
  {
    value: 'JPHC.PA',
    label:
      'JPHC.PA,  Amundi Index Solutions - Amundi JPX-Nikkei 400 UCITS ETF-C CHF Hedged',
    name: 'Amundi Index Solutions - Amundi JPX-Nikkei 400 UCITS ETF-C CHF Hedged',
    symbol: 'JPHC.PA',
  },
  {
    value: '10AL.DE',
    label:
      '10AL.DE,  Amundi Index Solutions - Amundi Index JP Morgan EMU Govies',
    name: 'Amundi Index Solutions - Amundi Index JP Morgan EMU Govies',
    symbol: '10AL.DE',
  },
  {
    value: 'PRAJ.F',
    label: 'PRAJ.F,  Amundi Index Solutions - Amundi Prime Japan',
    name: 'Amundi Index Solutions - Amundi Prime Japan',
    symbol: 'PRAJ.F',
  },
  {
    value: 'C3M.PA',
    label:
      'C3M.PA,  Amundi ETF Govies 0-6 Months Euro Investment Grade UCITS ETF DR',
    name: 'Amundi ETF Govies 0-6 Months Euro Investment Grade UCITS ETF DR',
    symbol: 'C3M.PA',
  },
  {
    value: 'INFR.L',
    label: 'INFR.L,  iShares Global Infrastructure UCITS ETF',
    name: 'iShares Global Infrastructure UCITS ETF',
    symbol: 'INFR.L',
  },
  {
    value: 'IEFV.L',
    label: 'IEFV.L,  iShares Edge MSCI Europe Value Factor UCITS ETF',
    name: 'iShares Edge MSCI Europe Value Factor UCITS ETF',
    symbol: 'IEFV.L',
  },
  {
    value: 'XSMC.SW',
    label: 'XSMC.SW,  Xtrackers Switzerland UCITS ETF',
    name: 'Xtrackers Switzerland UCITS ETF',
    symbol: 'XSMC.SW',
  },
  {
    value: 'CBBB.PA',
    label:
      'CBBB.PA,  Amundi Index Solutions - Amundi BBB EURO Corporate Investment Grade ESG',
    name: 'Amundi Index Solutions - Amundi BBB EURO Corporate Investment Grade ESG',
    symbol: 'CBBB.PA',
  },
  {
    value: 'DECR.F',
    label: 'DECR.F,  Amundi Index Solutions - Amundi Index Euro Corporate SRI',
    name: 'Amundi Index Solutions - Amundi Index Euro Corporate SRI',
    symbol: 'DECR.F',
  },
  {
    value: 'PRAU.F',
    label: 'PRAU.F,  Amundi Index Solutions - Amundi Prime USA',
    name: 'Amundi Index Solutions - Amundi Prime USA',
    symbol: 'PRAU.F',
  },
  {
    value: 'XNKY.F',
    label: 'XNKY.F,  Xtrackers Nikkei 225 UCITS ETF',
    name: 'Xtrackers Nikkei 225 UCITS ETF',
    symbol: 'XNKY.F',
  },
  {
    value: 'UMDV.AS',
    label: 'UMDV.AS,  iShares US Medical Devices UCITS ETF',
    name: 'iShares US Medical Devices UCITS ETF',
    symbol: 'UMDV.AS',
  },
  {
    value: 'EWAT.MI',
    label: 'EWAT.MI,  WisdomTree Wheat - EUR Daily Hedged',
    name: 'WisdomTree Wheat - EUR Daily Hedged',
    symbol: 'EWAT.MI',
  },
  {
    value: 'RS2K.PA',
    label: 'RS2K.PA,  Amundi Index Solutions - Amundi Russell 2000',
    name: 'Amundi Index Solutions - Amundi Russell 2000',
    symbol: 'RS2K.PA',
  },
  {
    value: 'EZNC.MI',
    label: 'EZNC.MI,  WisdomTree Zinc - EUR Daily Hedged',
    name: 'WisdomTree Zinc - EUR Daily Hedged',
    symbol: 'EZNC.MI',
  },
  {
    value: 'RS2U.PA',
    label: 'RS2U.PA,  Amundi Index Solutions - Amundi Russell 2000',
    name: 'Amundi Index Solutions - Amundi Russell 2000',
    symbol: 'RS2U.PA',
  },
  {
    value: 'EIMT.MI',
    label: 'EIMT.MI,  WisdomTree Industrial Metals - EUR Daily Hedged',
    name: 'WisdomTree Industrial Metals - EUR Daily Hedged',
    symbol: 'EIMT.MI',
  },
  {
    value: 'JPNK.PA',
    label:
      'JPNK.PA,  Amundi Index Solutions - Amundi JPX-Nikkei 400 UCITS ETF-C EUR',
    name: 'Amundi Index Solutions - Amundi JPX-Nikkei 400 UCITS ETF-C EUR',
    symbol: 'JPNK.PA',
  },
  {
    value: 'GBSE.MI',
    label: 'GBSE.MI,  WisdomTree Physical Gold - EUR Daily Hedged',
    name: 'WisdomTree Physical Gold - EUR Daily Hedged',
    symbol: 'GBSE.MI',
  },
  {
    value: 'C006.F',
    label: 'C006.F,  Lyxor F.A.Z. 100 Index (DR) UCITS ETF',
    name: 'Lyxor F.A.Z. 100 Index (DR) UCITS ETF',
    symbol: 'C006.F',
  },
  {
    value: 'JPHE.PA',
    label:
      'JPHE.PA,  Amundi Index Solutions - Amundi JPX-Nikkei 400 UCITS ETF-C EUR Hedged',
    name: 'Amundi Index Solutions - Amundi JPX-Nikkei 400 UCITS ETF-C EUR Hedged',
    symbol: 'JPHE.PA',
  },
  {
    value: 'IGLS.L',
    label: 'IGLS.L,  iShares UK Gilts 0-5yr UCITS ETF GBP (Dist)',
    name: 'iShares UK Gilts 0-5yr UCITS ETF GBP (Dist)',
    symbol: 'IGLS.L',
  },
  {
    value: 'IBTG.L',
    label: 'IBTG.L,  iShares $ Treasury Bond 1-3yr UCITS ETF',
    name: 'iShares $ Treasury Bond 1-3yr UCITS ETF',
    symbol: 'IBTG.L',
  },
  {
    value: 'XAD5.MI',
    label: 'XAD5.MI,  Xtrackers Physical Gold ETC (EUR)',
    name: 'Xtrackers Physical Gold ETC (EUR)',
    symbol: 'XAD5.MI',
  },
  {
    value: 'XAD6.MI',
    label: 'XAD6.MI,  Xtrackers Physical Silver ETC (EUR)',
    name: 'Xtrackers Physical Silver ETC (EUR)',
    symbol: 'XAD6.MI',
  },
  {
    value: 'EL4Q.DE',
    label:
      'EL4Q.DE,  Deka iBoxx EUR Liquid Sovereign Diversified 10+ UCITS ETF',
    name: 'Deka iBoxx EUR Liquid Sovereign Diversified 10+ UCITS ETF',
    symbol: 'EL4Q.DE',
  },
  {
    value: 'EL4K.DE',
    label:
      'EL4K.DE,  Deka iBoxx EUR Liquid Sovereign Diversified 1-10 UCITS ETF',
    name: 'Deka iBoxx EUR Liquid Sovereign Diversified 1-10 UCITS ETF',
    symbol: 'EL4K.DE',
  },
  {
    value: 'EL4L.DE',
    label:
      'EL4L.DE,  Deka iBoxx EUR Liquid Sovereign Diversified 1-3 UCITS ETF',
    name: 'Deka iBoxx EUR Liquid Sovereign Diversified 1-3 UCITS ETF',
    symbol: 'EL4L.DE',
  },
  {
    value: 'EL4M.DE',
    label:
      'EL4M.DE,  Deka iBoxx EUR Liquid Sovereign Diversified 3-5 UCITS ETF',
    name: 'Deka iBoxx EUR Liquid Sovereign Diversified 3-5 UCITS ETF',
    symbol: 'EL4M.DE',
  },
  {
    value: 'EL4N.DE',
    label:
      'EL4N.DE,  Deka iBoxx EUR Liquid Sovereign Diversified 5-7 UCITS ETF',
    name: 'Deka iBoxx EUR Liquid Sovereign Diversified 5-7 UCITS ETF',
    symbol: 'EL4N.DE',
  },
  {
    value: 'EL4P.DE',
    label:
      'EL4P.DE,  Deka iBoxx EUR Liquid Sovereign Diversified 7-10 UCITS ETF',
    name: 'Deka iBoxx EUR Liquid Sovereign Diversified 7-10 UCITS ETF',
    symbol: 'EL4P.DE',
  },
  {
    value: 'DL2P.L',
    label: 'DL2P.L,  L&G DAX Daily 2x Long UCITS ETF',
    name: 'L&G DAX Daily 2x Long UCITS ETF',
    symbol: 'DL2P.L',
  },
  {
    value: 'EL48.DE',
    label:
      'EL48.DE,  Deka iBoxx € Liquid Germany Covered Diversified UCITS ETF',
    name: 'Deka iBoxx € Liquid Germany Covered Diversified UCITS ETF',
    symbol: 'EL48.DE',
  },
  {
    value: 'EL49.DE',
    label: 'EL49.DE,  Deka iBoxx € Liquid Corporates Diversified UCITS ETF',
    name: 'Deka iBoxx € Liquid Corporates Diversified UCITS ETF',
    symbol: 'EL49.DE',
  },
  {
    value: 'EFQ8.DE',
    label:
      'EFQ8.DE,  Deka iBoxx EUR Liquid Non-Financials Diversified UCITS ETF',
    name: 'Deka iBoxx EUR Liquid Non-Financials Diversified UCITS ETF',
    symbol: 'EFQ8.DE',
  },
  {
    value: 'UC48.L',
    label:
      'UC48.L,  UBS (Irl) Fund Solutions plc - MSCI AC Asia Ex Japan SF UCITS ETF',
    name: 'UBS (Irl) Fund Solutions plc - MSCI AC Asia Ex Japan SF UCITS ETF',
    symbol: 'UC48.L',
  },
  {
    value: 'CRWE.PA',
    label:
      'CRWE.PA,  Ossiam Risk Weighted Enhanced Commodity Ex Grains TR UCITS ETF',
    name: 'Ossiam Risk Weighted Enhanced Commodity Ex Grains TR UCITS ETF',
    symbol: 'CRWE.PA',
  },
  {
    value: 'JBEM.PA',
    label: 'JBEM.PA,  BNP Paribas Easy JPM ESG EMU Government Bond IG',
    name: 'BNP Paribas Easy JPM ESG EMU Government Bond IG',
    symbol: 'JBEM.PA',
  },
  {
    value: 'EUFM.SW',
    label:
      'EUFM.SW,  UBS (Lux) Fund Solutions – MSCI EMU Select Factor Mix UCITS ETF',
    name: 'UBS (Lux) Fund Solutions – MSCI EMU Select Factor Mix UCITS ETF',
    symbol: 'EUFM.SW',
  },
  {
    value: 'TCBT.AS',
    label: 'TCBT.AS,  VanEck iBoxx EUR Corporates UCITS ETF',
    name: 'VanEck iBoxx EUR Corporates UCITS ETF',
    symbol: 'TCBT.AS',
  },
  {
    value: 'TAT.AS',
    label: 'TAT.AS,  VanEck iBoxx EUR Sovereign Capped AAA-AA 1-5 UCITS ETF',
    name: 'VanEck iBoxx EUR Sovereign Capped AAA-AA 1-5 UCITS ETF',
    symbol: 'TAT.AS',
  },
  {
    value: 'TGBT.AS',
    label: 'TGBT.AS,  VanEck iBoxx EUR Sovereign Diversified 1-10 UCITS ETF',
    name: 'VanEck iBoxx EUR Sovereign Diversified 1-10 UCITS ETF',
    symbol: 'TGBT.AS',
  },
  {
    value: 'EL4S.DE',
    label: 'EL4S.DE,  Deka Deutsche Börse EUROGOV Germany 1-3 UCITS ETF',
    name: 'Deka Deutsche Börse EUROGOV Germany 1-3 UCITS ETF',
    symbol: 'EL4S.DE',
  },
  {
    value: 'EL4T.DE',
    label: 'EL4T.DE,  Deka Deutsche Börse EUROGOV Germany 3-5 UCITS ETF',
    name: 'Deka Deutsche Börse EUROGOV Germany 3-5 UCITS ETF',
    symbol: 'EL4T.DE',
  },
  {
    value: 'EL4U.DE',
    label: 'EL4U.DE,  Deka Deutsche Börse EUROGOV Germany 5-10 UCITS ETF',
    name: 'Deka Deutsche Börse EUROGOV Germany 5-10 UCITS ETF',
    symbol: 'EL4U.DE',
  },
  {
    value: 'EL4W.DE',
    label:
      'EL4W.DE,  Deka Deutsche Börse EUROGOV Germany Money Market UCITS ETF',
    name: 'Deka Deutsche Börse EUROGOV Germany Money Market UCITS ETF',
    symbol: 'EL4W.DE',
  },
  {
    value: 'EL4R.DE',
    label: 'EL4R.DE,  Deka Deutsche Börse EUROGOV Germany UCITS ETF',
    name: 'Deka Deutsche Börse EUROGOV Germany UCITS ETF',
    symbol: 'EL4R.DE',
  },
  {
    value: 'AUCO.L',
    label: 'AUCO.L,  L&G Gold Mining UCITS ETF',
    name: 'L&G Gold Mining UCITS ETF',
    symbol: 'AUCO.L',
  },
  {
    value: 'XSD2.L',
    label: 'XSD2.L,  Xtrackers ShortDAX x2 Daily Swap UCITS ETF',
    name: 'Xtrackers ShortDAX x2 Daily Swap UCITS ETF',
    symbol: 'XSD2.L',
  },
  {
    value: 'COMF.L',
    label: 'COMF.L,  L&G Longer Dated All Commodities UCITS ETF',
    name: 'L&G Longer Dated All Commodities UCITS ETF',
    symbol: 'COMF.L',
  },
  {
    value: 'EFQ2.DE',
    label: 'EFQ2.DE,  Deka Deutsche Börse EUROGOV France UCITS ETF',
    name: 'Deka Deutsche Börse EUROGOV France UCITS ETF',
    symbol: 'EFQ2.DE',
  },
  {
    value: 'UEFR.DE',
    label:
      'UEFR.DE,  UBS (Lux) Fund Solutions – Bloomberg Euro Area Liquid Corporates UCITS ETF',
    name: 'UBS (Lux) Fund Solutions – Bloomberg Euro Area Liquid Corporates UCITS ETF',
    symbol: 'UEFR.DE',
  },
  {
    value: 'UEFY.DE',
    label:
      'UEFY.DE,  UBS (Lux) Fund Solutions – SBI Foreign AAA-BBB 1-5 ESG UCITS ETF',
    name: 'UBS (Lux) Fund Solutions – SBI Foreign AAA-BBB 1-5 ESG UCITS ETF',
    symbol: 'UEFY.DE',
  },
  {
    value: 'UEFZ.DE',
    label:
      'UEFZ.DE,  UBS (Lux) Fund Solutions – SBI Foreign AAA-BBB 5-10 ESG UCITS ETF',
    name: 'UBS (Lux) Fund Solutions – SBI Foreign AAA-BBB 5-10 ESG UCITS ETF',
    symbol: 'UEFZ.DE',
  },
  {
    value: 'CBUS.SW',
    label:
      'CBUS.SW,  UBS(Lux)Fund Solutions – Bloomberg Barclays US Liquid Corporates UCITS ETF(USD)A-dis',
    name: 'UBS(Lux)Fund Solutions – Bloomberg Barclays US Liquid Corporates UCITS ETF(USD)A-dis',
    symbol: 'CBUS.SW',
  },
  {
    value: 'UEF7.F',
    label:
      'UEF7.F,  UBS(Lux)Fund Solutions – Bloomberg Barclays US Liquid Corporates 1-5 Year UCITS ETF(USD)A-dis',
    name: 'UBS(Lux)Fund Solutions – Bloomberg Barclays US Liquid Corporates 1-5 Year UCITS ETF(USD)A-dis',
    symbol: 'UEF7.F',
  },
  {
    value: 'ROBO.MI',
    label: 'ROBO.MI,  L&G ROBO Global Robotics and Automation UCITS ETF',
    name: 'L&G ROBO Global Robotics and Automation UCITS ETF',
    symbol: 'ROBO.MI',
  },
  {
    value: 'CAPE.PA',
    label: 'CAPE.PA,  Ossiam Shiller Barclays Cape Europe Sector Value TR',
    name: 'Ossiam Shiller Barclays Cape Europe Sector Value TR',
    symbol: 'CAPE.PA',
  },
  {
    value: 'UC90.L',
    label:
      'UC90.L,  UBS (Irl) Fund Solutions plc - CMCI Composite SF UCITS ETF',
    name: 'UBS (Irl) Fund Solutions plc - CMCI Composite SF UCITS ETF',
    symbol: 'UC90.L',
  },
  {
    value: 'USCP.DE',
    label: 'USCP.DE,  Ossiam Shiller Barclays Cape US Sector Value TR',
    name: 'Ossiam Shiller Barclays Cape US Sector Value TR',
    symbol: 'USCP.DE',
  },
  {
    value: 'ELFB.DE',
    label: 'ELFB.DE,  Deka Oekom Euro Nachhaltigkeit UCITS ETF',
    name: 'Deka Oekom Euro Nachhaltigkeit UCITS ETF',
    symbol: 'ELFB.DE',
  },
  {
    value: 'ISPY.L',
    label: 'ISPY.L,  L&G Cyber Security UCITS ETF',
    name: 'L&G Cyber Security UCITS ETF',
    symbol: 'ISPY.L',
  },
  {
    value: 'ELFD.DE',
    label: 'ELFD.DE,  Deka Eurozone Rendite Plus 1-10 UCITS ETF',
    name: 'Deka Eurozone Rendite Plus 1-10 UCITS ETF',
    symbol: 'ELFD.DE',
  },
  {
    value: 'UEFS.DE',
    label:
      'UEFS.DE,  UBS (Lux) Fund Solutions – Bloomberg USD Emerging Markets Sovereign UCITS ETF',
    name: 'UBS (Lux) Fund Solutions – Bloomberg USD Emerging Markets Sovereign UCITS ETF',
    symbol: 'UEFS.DE',
  },
  {
    value: 'EENG.MI',
    label: 'EENG.MI,  BNP Paribas Easy ECPI Global ESG Infrastructure',
    name: 'BNP Paribas Easy ECPI Global ESG Infrastructure',
    symbol: 'EENG.MI',
  },
  {
    value: 'GSDE.F',
    label: 'GSDE.F,  BNP Paribas Easy Energy & Metals Enhanced Roll',
    name: 'BNP Paribas Easy Energy & Metals Enhanced Roll',
    symbol: 'GSDE.F',
  },
  {
    value: 'FTGG.F',
    label: 'FTGG.F,  First Trust Germany AlphaDEX UCITS ETF',
    name: 'First Trust Germany AlphaDEX UCITS ETF',
    symbol: 'FTGG.F',
  },
  {
    value: 'EVOE.PA',
    label: 'EVOE.PA,  BNP Paribas Easy ESG Equity Low Vol Europe',
    name: 'BNP Paribas Easy ESG Equity Low Vol Europe',
    symbol: 'EVOE.PA',
  },
  {
    value: 'EVOU.PA',
    label: 'EVOU.PA,  BNP Paribas Easy ESG Equity Low Vol US',
    name: 'BNP Paribas Easy ESG Equity Low Vol US',
    symbol: 'EVOU.PA',
  },
  {
    value: 'EQUA.PA',
    label: 'EQUA.PA,  BNP Paribas Easy ESG Equity Quality Europe',
    name: 'BNP Paribas Easy ESG Equity Quality Europe',
    symbol: 'EQUA.PA',
  },
  {
    value: 'EVAE.PA',
    label: 'EVAE.PA,  BNP Paribas Easy ESG Equity Value Europe',
    name: 'BNP Paribas Easy ESG Equity Value Europe',
    symbol: 'EVAE.PA',
  },
  {
    value: 'GMRC.PA',
    label: 'GMRC.PA,  Ossiam Global Multi-Asset Risk-Control ETF',
    name: 'Ossiam Global Multi-Asset Risk-Control ETF',
    symbol: 'GMRC.PA',
  },
  {
    value: 'VLED.PA',
    label: 'VLED.PA,  BNP Paribas Easy ESG Equity Low Vol Europe',
    name: 'BNP Paribas Easy ESG Equity Low Vol Europe',
    symbol: 'VLED.PA',
  },
  {
    value: 'VLUD.PA',
    label: 'VLUD.PA,  BNP Paribas Easy ESG Equity Low Vol US',
    name: 'BNP Paribas Easy ESG Equity Low Vol US',
    symbol: 'VLUD.PA',
  },
  {
    value: 'VLUU.SW',
    label: 'VLUU.SW,  BNP Paribas Easy ESG Equity Low Vol US',
    name: 'BNP Paribas Easy ESG Equity Low Vol US',
    symbol: 'VLUU.SW',
  },
  {
    value: 'MOED.F',
    label: 'MOED.F,  BNP Paribas Easy ESG Equity Momentum Europe',
    name: 'BNP Paribas Easy ESG Equity Momentum Europe',
    symbol: 'MOED.F',
  },
  {
    value: 'QUED.PA',
    label: 'QUED.PA,  BNP Paribas Easy ESG Equity Quality Europe',
    name: 'BNP Paribas Easy ESG Equity Quality Europe',
    symbol: 'QUED.PA',
  },
  {
    value: 'VALD.PA',
    label: 'VALD.PA,  BNP Paribas Easy ESG Equity Value Europe',
    name: 'BNP Paribas Easy ESG Equity Value Europe',
    symbol: 'VALD.PA',
  },
  {
    value: 'FUQA.L',
    label: 'FUQA.L,  Fidelity US Quality Income ETF Acc',
    name: 'Fidelity US Quality Income ETF Acc',
    symbol: 'FUQA.L',
  },
  {
    value: 'FUSD.DE',
    label: 'FUSD.DE,  Fidelity US Quality Income ETF',
    name: 'Fidelity US Quality Income ETF',
    symbol: 'FUSD.DE',
  },
  {
    value: 'ECN.PA',
    label: 'ECN.PA,  BNP Paribas Easy Low Carbon 100 Europe PAB',
    name: 'BNP Paribas Easy Low Carbon 100 Europe PAB',
    symbol: 'ECN.PA',
  },
  {
    value: 'BCFE.DE',
    label:
      'BCFE.DE,  UBS (Irl) Fund Solutions plc - Bloomberg Commodity CMCI SF UCITS ETF (hedged to EUR) A-acc',
    name: 'UBS (Irl) Fund Solutions plc - Bloomberg Commodity CMCI SF UCITS ETF (hedged to EUR) A-acc',
    symbol: 'BCFE.DE',
  },
  {
    value: 'EMEH.F',
    label: 'EMEH.F,  BNP Paribas Easy Energy & Metals Enhanced Roll',
    name: 'BNP Paribas Easy Energy & Metals Enhanced Roll',
    symbol: 'EMEH.F',
  },
  {
    value: 'VGOV.L',
    label: 'VGOV.L,  Vanguard U.K. Gilt UCITS ETF',
    name: 'Vanguard U.K. Gilt UCITS ETF',
    symbol: 'VGOV.L',
  },
  {
    value: 'FYEM.DE',
    label: 'FYEM.DE,  Fidelity Emerging Markets Quality Income UCITS ETF',
    name: 'Fidelity Emerging Markets Quality Income UCITS ETF',
    symbol: 'FYEM.DE',
  },
  {
    value: 'FEQD.L',
    label: 'FEQD.L,  Fidelity Europe Quality Income UCITS ETF',
    name: 'Fidelity Europe Quality Income UCITS ETF',
    symbol: 'FEQD.L',
  },
  {
    value: 'FRC4.DE',
    label:
      'FRC4.DE,  UBS (Lux) Fund Solutions – Bloomberg Euro Inflation Linked 10+ UCITS ETF',
    name: 'UBS (Lux) Fund Solutions – Bloomberg Euro Inflation Linked 10+ UCITS ETF',
    symbol: 'FRC4.DE',
  },
  {
    value: 'FRC3.DE',
    label:
      'FRC3.DE,  UBS (Lux) Fund Solutions – Bloomberg Euro Inflation Linked 1-10 UCITS ETF',
    name: 'UBS (Lux) Fund Solutions – Bloomberg Euro Inflation Linked 1-10 UCITS ETF',
    symbol: 'FRC3.DE',
  },
  {
    value: 'FUSU.F',
    label: 'FUSU.F,  Fidelity US Quality Income ETF',
    name: 'Fidelity US Quality Income ETF',
    symbol: 'FUSU.F',
  },
  {
    value: 'FGEU.F',
    label: 'FGEU.F,  Fidelity Global Quality Income ETF',
    name: 'Fidelity Global Quality Income ETF',
    symbol: 'FGEU.F',
  },
  {
    value: 'MOOC.PA',
    label: 'MOOC.PA,  Ossiam Solactive Moody`s Analytics IG EUR Select Credit',
    name: 'Ossiam Solactive Moody`s Analytics IG EUR Select Credit',
    symbol: 'MOOC.PA',
  },
  {
    value: 'SBTC.SW',
    label: 'SBTC.SW,  21Shares Short Bitcoin ETP',
    name: '21Shares Short Bitcoin ETP',
    symbol: 'SBTC.SW',
  },
  {
    value: 'IEFS.L',
    label: 'IEFS.L,  iShares Edge MSCI Europe Size Factor UCITS ETF',
    name: 'iShares Edge MSCI Europe Size Factor UCITS ETF',
    symbol: 'IEFS.L',
  },
  {
    value: '3EML.MI',
    label: '3EML.MI,  WisdomTree Emerging Markets 3x Daily Leveraged',
    name: 'WisdomTree Emerging Markets 3x Daily Leveraged',
    symbol: '3EML.MI',
  },
  {
    value: 'USE3.MI',
    label: 'USE3.MI,  WisdomTree Short USD Long EUR 3x Daily',
    name: 'WisdomTree Short USD Long EUR 3x Daily',
    symbol: 'USE3.MI',
  },
  {
    value: 'GBUR.L',
    label: 'GBUR.L,  WisdomTree Long EUR Short GBP',
    name: 'WisdomTree Long EUR Short GBP',
    symbol: 'GBUR.L',
  },
  {
    value: 'ECRN.MI',
    label: 'ECRN.MI,  WisdomTree Corn - EUR Daily Hedged',
    name: 'WisdomTree Corn - EUR Daily Hedged',
    symbol: 'ECRN.MI',
  },
  {
    value: 'ECRD.MI',
    label: 'ECRD.MI,  WisdomTree WTI Crude Oil - EUR Daily Hedged',
    name: 'WisdomTree WTI Crude Oil - EUR Daily Hedged',
    symbol: 'ECRD.MI',
  },
  {
    value: 'EFCM.MI',
    label:
      'EFCM.MI,  WisdomTree Broad Commodities Longer Dated - EUR Daily Hedged',
    name: 'WisdomTree Broad Commodities Longer Dated - EUR Daily Hedged',
    symbol: 'EFCM.MI',
  },
  {
    value: 'ESOY.MI',
    label: 'ESOY.MI,  WisdomTree Soybeans - EUR Daily Hedged',
    name: 'WisdomTree Soybeans - EUR Daily Hedged',
    symbol: 'ESOY.MI',
  },
  {
    value: 'ENGS.MI',
    label: 'ENGS.MI,  WisdomTree Natural Gas - EUR Daily Hedged',
    name: 'WisdomTree Natural Gas - EUR Daily Hedged',
    symbol: 'ENGS.MI',
  },
  {
    value: 'XSTR.L',
    label: 'XSTR.L,  Xtrackers II GBP Overnight Rate Swap UCITS ETF',
    name: 'Xtrackers II GBP Overnight Rate Swap UCITS ETF',
    symbol: 'XSTR.L',
  },
  {
    value: 'XLPE.L',
    label: 'XLPE.L,  Xtrackers LPX Private Equity Swap UCITS ETF',
    name: 'Xtrackers LPX Private Equity Swap UCITS ETF',
    symbol: 'XLPE.L',
  },
  {
    value: 'DBZN.DE',
    label: 'DBZN.DE,  Xtrackers Bloomberg Commodity Swap UCITS ETF',
    name: 'Xtrackers Bloomberg Commodity Swap UCITS ETF',
    symbol: 'DBZN.DE',
  },
  {
    value: 'CD91.DE',
    label: 'CD91.DE,  Lyxor NYSE Arca Gold BUGS (DR) UCITS ETF',
    name: 'Lyxor NYSE Arca Gold BUGS (DR) UCITS ETF',
    symbol: 'CD91.DE',
  },
  {
    value: 'CSH2.PA',
    label: 'CSH2.PA,  Lyxor Smart Cash - UCITS ETF C-EUR',
    name: 'Lyxor Smart Cash - UCITS ETF C-EUR',
    symbol: 'CSH2.PA',
  },
  {
    value: 'CSH2.L',
    label: 'CSH2.L,  Lyxor Index Fund - Lyxor Smart Cash',
    name: 'Lyxor Index Fund - Lyxor Smart Cash',
    symbol: 'CSH2.L',
  },
  {
    value: 'SMTC.L',
    label: 'SMTC.L,  Lyxor Smart Overnight Return',
    name: 'Lyxor Smart Overnight Return',
    symbol: 'SMTC.L',
  },
  {
    value: 'US10.PA',
    label: 'US10.PA,  Lyxor US Treasury 10+Y (DR) UCITS ETF Dist',
    name: 'Lyxor US Treasury 10+Y (DR) UCITS ETF Dist',
    symbol: 'US10.PA',
  },
  {
    value: 'GILI.L',
    label:
      'GILI.L,  Lyxor Core UK Government Inflation-Linked Bond (DR) UCITS ETF',
    name: 'Lyxor Core UK Government Inflation-Linked Bond (DR) UCITS ETF',
    symbol: 'GILI.L',
  },
  {
    value: 'EPRA.PA',
    label:
      'EPRA.PA,  Amundi Index Solutions - Amundi Index FTSE EPRA NAREIT Global',
    name: 'Amundi Index Solutions - Amundi Index FTSE EPRA NAREIT Global',
    symbol: 'EPRA.PA',
  },
  {
    value: 'EBBB.PA',
    label: 'EBBB.PA,  Amundi Index Solutions - Amundi Index Euro Corp BBB 1-5',
    name: 'Amundi Index Solutions - Amundi Index Euro Corp BBB 1-5',
    symbol: 'EBBB.PA',
  },
  {
    value: 'EPRE.PA',
    label:
      'EPRE.PA,  Amundi Index Solutions - Amundi FTSE EPRA Europe Real Estate',
    name: 'Amundi Index Solutions - Amundi FTSE EPRA Europe Real Estate',
    symbol: 'EPRE.PA',
  },
  {
    value: '10AM.DE',
    label:
      '10AM.DE,  Amundi Index Solutions - Amundi Index Barclays Global AGG 500M UCITS ETF DR EUR',
    name: 'Amundi Index Solutions - Amundi Index Barclays Global AGG 500M UCITS ETF DR EUR',
    symbol: '10AM.DE',
  },
  {
    value: 'GAHU.PA',
    label: 'GAHU.PA,  Amundi Index Solutions - Amundi Index Global AGG 500M',
    name: 'Amundi Index Solutions - Amundi Index Global AGG 500M',
    symbol: 'GAHU.PA',
  },
  {
    value: 'FLOT.MI',
    label:
      'FLOT.MI,  MULTI UNITS LUXEMBOURG–Lyxor Euro Floating Rate Note UCITS ETF',
    name: 'MULTI UNITS LUXEMBOURG–Lyxor Euro Floating Rate Note UCITS ETF',
    symbol: 'FLOT.MI',
  },
  {
    value: 'CRBN.MI',
    label:
      'CRBN.MI,  MULTI-UNITS LUXEMBOURG - Lyxor Commodities Refinitiv/CoreCommodity CRB EX-Energy TR UCITS ETF',
    name: 'MULTI-UNITS LUXEMBOURG - Lyxor Commodities Refinitiv/CoreCommodity CRB EX-Energy TR UCITS ETF',
    symbol: 'CRBN.MI',
  },
  {
    value: 'LMWE.DE',
    label:
      'LMWE.DE,  Lyxor FTSE Epra/Nareit Global Developed UCITS ETF D EUR Inc',
    name: 'Lyxor FTSE Epra/Nareit Global Developed UCITS ETF D EUR Inc',
    symbol: 'LMWE.DE',
  },
  {
    value: 'ECR3.DE',
    label:
      'ECR3.DE,  Amundi Index Solutions - Amundi Index Euro Corporate Sri 0-3 Y',
    name: 'Amundi Index Solutions - Amundi Index Euro Corporate Sri 0-3 Y',
    symbol: 'ECR3.DE',
  },
  {
    value: 'SMOR.SW',
    label: 'SMOR.SW,  Lyxor Smart Overnight Return',
    name: 'Lyxor Smart Overnight Return',
    symbol: 'SMOR.SW',
  },
  {
    value: 'ECR1.F',
    label: 'ECR1.F,  Amundi Index Solutions Amundi Euro Corp 0-1Y ESG',
    name: 'Amundi Index Solutions Amundi Euro Corp 0-1Y ESG',
    symbol: 'ECR1.F',
  },
  {
    value: 'EXXY.DE',
    label: 'EXXY.DE,  iShares Diversified Commodity Swap UCITS ETF (DE)',
    name: 'iShares Diversified Commodity Swap UCITS ETF (DE)',
    symbol: 'EXXY.DE',
  },
  {
    value: 'F701.DE',
    label: 'F701.DE,  Lyxor Portfolio Strategy UCITS ETF',
    name: 'Lyxor Portfolio Strategy UCITS ETF',
    symbol: 'F701.DE',
  },
  {
    value: 'ISAG.L',
    label: 'ISAG.L,  iShares V PLC - iShares Agribusiness UCITS ETF USD (Acc)',
    name: 'iShares V PLC - iShares Agribusiness UCITS ETF USD (Acc)',
    symbol: 'ISAG.L',
  },
  {
    value: '3ITS.MI',
    label: '3ITS.MI,  WisdomTree FTSE MIB 3x Daily Short',
    name: 'WisdomTree FTSE MIB 3x Daily Short',
    symbol: '3ITS.MI',
  },
  {
    value: 'EMV.L',
    label:
      'EMV.L,  iShares Edge MSCI EM Minimum Volatility UCITS ETF USD (Acc)',
    name: 'iShares Edge MSCI EM Minimum Volatility UCITS ETF USD (Acc)',
    symbol: 'EMV.L',
  },
  {
    value: 'XDN0.L',
    label: 'XDN0.L,  Xtrackers MSCI Nordic UCITS ETF',
    name: 'Xtrackers MSCI Nordic UCITS ETF',
    symbol: 'XDN0.L',
  },
  {
    value: '3BTL.MI',
    label: '3BTL.MI,  WisdomTree BTP 10Y 3x Daily Leveraged',
    name: 'WisdomTree BTP 10Y 3x Daily Leveraged',
    symbol: '3BTL.MI',
  },
  {
    value: 'QQQ3.MI',
    label: 'QQQ3.MI,  WisdomTree NASDAQ 100 3x Daily Leveraged',
    name: 'WisdomTree NASDAQ 100 3x Daily Leveraged',
    symbol: 'QQQ3.MI',
  },
  {
    value: 'EXHE.F',
    label: 'EXHE.F,  iShares Pfandbriefe UCITS ETF (DE)',
    name: 'iShares Pfandbriefe UCITS ETF (DE)',
    symbol: 'EXHE.F',
  },
  {
    value: 'EXXX.DE',
    label: 'EXXX.DE,  iShares ATX UCITS ETF (DE)',
    name: 'iShares ATX UCITS ETF (DE)',
    symbol: 'EXXX.DE',
  },
  {
    value: 'EXX6.DE',
    label:
      'EXX6.DE,  iShares eb.rexx Government Germany 10.5+yr UCITS ETF (DE)',
    name: 'iShares eb.rexx Government Germany 10.5+yr UCITS ETF (DE)',
    symbol: 'EXX6.DE',
  },
  {
    value: 'EXVM.DE',
    label: 'EXVM.DE,  iShares eb.rexxGovernment Germany 0-1yr UCITS ETF (DE)',
    name: 'iShares eb.rexxGovernment Germany 0-1yr UCITS ETF (DE)',
    symbol: 'EXVM.DE',
  },
  {
    value: 'CATL.L',
    label: 'CATL.L,  WisdomTree Live Cattle',
    name: 'WisdomTree Live Cattle',
    symbol: 'CATL.L',
  },
  {
    value: 'NGAS.L',
    label: 'NGAS.L,  WisdomTree Natural Gas',
    name: 'WisdomTree Natural Gas',
    symbol: 'NGAS.L',
  },
  {
    value: 'SOYB.L',
    label: 'SOYB.L,  WisdomTree Soybeans',
    name: 'WisdomTree Soybeans',
    symbol: 'SOYB.L',
  },
  {
    value: 'INXG.L',
    label: 'INXG.L,  iShares £ Index-Linked Gilts UCITS ETF GBP (Dist)',
    name: 'iShares £ Index-Linked Gilts UCITS ETF GBP (Dist)',
    symbol: 'INXG.L',
  },
  {
    value: 'ISFE.L',
    label:
      'ISFE.L,  iShares MSCI AC Far East ex-Japan Small Cap UCITS ETF USD (Dist)',
    name: 'iShares MSCI AC Far East ex-Japan Small Cap UCITS ETF USD (Dist)',
    symbol: 'ISFE.L',
  },
  {
    value: 'IAUP.L',
    label:
      'IAUP.L,  iShares V PLC - iShares Gold Producers UCITS ETF USD (Acc)',
    name: 'iShares V PLC - iShares Gold Producers UCITS ETF USD (Acc)',
    symbol: 'IAUP.L',
  },
  {
    value: 'IMV.L',
    label: 'IMV.L,  iShares Edge MSCI Europe Minimum Volatility UCITS ETF',
    name: 'iShares Edge MSCI Europe Minimum Volatility UCITS ETF',
    symbol: 'IMV.L',
  },
  {
    value: 'IJPC.SW',
    label: 'IJPC.SW,  iShares MSCI Japan CHF Hedged UCITS ETF (Acc)',
    name: 'iShares MSCI Japan CHF Hedged UCITS ETF (Acc)',
    symbol: 'IJPC.SW',
  },
  {
    value: '3HCS.MI',
    label: '3HCS.MI,  WisdomTree Copper 3x Daily Short',
    name: 'WisdomTree Copper 3x Daily Short',
    symbol: '3HCS.MI',
  },
  {
    value: '3ITL.MI',
    label: '3ITL.MI,  WisdomTree FTSE MIB 3x Daily Leveraged',
    name: 'WisdomTree FTSE MIB 3x Daily Leveraged',
    symbol: '3ITL.MI',
  },
  {
    value: 'XDGM.DE',
    label: 'XDGM.DE,  Xtrackers MDAX ESG Screened UCITS ETF',
    name: 'Xtrackers MDAX ESG Screened UCITS ETF',
    symbol: 'XDGM.DE',
  },
  {
    value: 'IJPD.L',
    label: 'IJPD.L,  iShares MSCI Japan USD Hedged UCITS ETF',
    name: 'iShares MSCI Japan USD Hedged UCITS ETF',
    symbol: 'IJPD.L',
  },
  {
    value: 'IUMF.L',
    label: 'IUMF.L,  iShares Edge MSCI USA Momentum Factor UCITS ETF USD (Acc)',
    name: 'iShares Edge MSCI USA Momentum Factor UCITS ETF USD (Acc)',
    symbol: 'IUMF.L',
  },
  {
    value: '0TPE.L',
    label:
      '0TPE.L,  iShares II Public Limited Company - iShares $ TIPS UCITS ETF',
    name: 'iShares II Public Limited Company - iShares $ TIPS UCITS ETF',
    symbol: '0TPE.L',
  },
  {
    value: 'DPYE.L',
    label: 'DPYE.L,  iShares Developed Markets Property Yield UCITS ETF',
    name: 'iShares Developed Markets Property Yield UCITS ETF',
    symbol: 'DPYE.L',
  },
  {
    value: '3TYL.MI',
    label: '3TYL.MI,  WisdomTree US Treasuries 10Y 3x Daily Leveraged',
    name: 'WisdomTree US Treasuries 10Y 3x Daily Leveraged',
    symbol: '3TYL.MI',
  },
  {
    value: '3BUL.MI',
    label: '3BUL.MI,  WisdomTree Bund 10Y 3x Daily Leveraged',
    name: 'WisdomTree Bund 10Y 3x Daily Leveraged',
    symbol: '3BUL.MI',
  },
  {
    value: 'MODR.DE',
    label: 'MODR.DE,  BlackRock ESG Multi-Asset Moderate Portfolio UCITS ETF',
    name: 'BlackRock ESG Multi-Asset Moderate Portfolio UCITS ETF',
    symbol: 'MODR.DE',
  },
  {
    value: 'VIXL.MI',
    label: 'VIXL.MI,  S&P 500 VIX Short-term Futures Index (0930-1600 EST)',
    name: 'S&P 500 VIX Short-term Futures Index (0930-1600 EST)',
    symbol: 'VIXL.MI',
  },
  {
    value: 'XREA.DE',
    label:
      'XREA.DE,  Xtrackers FTSE Developed Europe ex UK Real Estate UCITS ETF',
    name: 'Xtrackers FTSE Developed Europe ex UK Real Estate UCITS ETF',
    symbol: 'XREA.DE',
  },
  {
    value: 'C13.PA',
    label:
      'C13.PA,  Amundi ETF Govt Bond Euro Broad Investment Grade 1-3 UCITS ETF DR',
    name: 'Amundi ETF Govt Bond Euro Broad Investment Grade 1-3 UCITS ETF DR',
    symbol: 'C13.PA',
  },
  {
    value: 'IBCX.L',
    label: 'IBCX.L,  iShares € Corp Bond Large Cap UCITS ETF',
    name: 'iShares € Corp Bond Large Cap UCITS ETF',
    symbol: 'IBCX.L',
  },
  {
    value: 'IBCI.L',
    label: 'IBCI.L,  iShares € Inflation Linked Govt Bond UCITS ETF',
    name: 'iShares € Inflation Linked Govt Bond UCITS ETF',
    symbol: 'IBCI.L',
  },
  {
    value: 'IBGS.SW',
    label: 'IBGS.SW,  iShares € Govt Bond 1-3yr UCITS ETF EUR (Dist)',
    name: 'iShares € Govt Bond 1-3yr UCITS ETF EUR (Dist)',
    symbol: 'IBGS.SW',
  },
  {
    value: 'IBGX.SW',
    label: 'IBGX.SW,  iShares € Govt Bond 3-5yr UCITS ETF',
    name: 'iShares € Govt Bond 3-5yr UCITS ETF',
    symbol: 'IBGX.SW',
  },
  {
    value: 'SEAG.L',
    label: 'SEAG.L,  iShares € Aggregate Bond ESG UCITS ETF EUR (Dist)',
    name: 'iShares € Aggregate Bond ESG UCITS ETF EUR (Dist)',
    symbol: 'SEAG.L',
  },
  {
    value: 'IGLO.L',
    label: 'IGLO.L,  iShares Global Govt Bond UCITS ETF USD (Dist)',
    name: 'iShares Global Govt Bond UCITS ETF USD (Dist)',
    symbol: 'IGLO.L',
  },
  {
    value: 'EEXF.L',
    label: 'EEXF.L,  iShares € Corp Bond ex-Financials UCITS ETF',
    name: 'iShares € Corp Bond ex-Financials UCITS ETF',
    symbol: 'EEXF.L',
  },
  {
    value: 'IS0R.DE',
    label: 'IS0R.DE,  iShares $ High Yield Corp Bond UCITS ETF USD (Dist)',
    name: 'iShares $ High Yield Corp Bond UCITS ETF USD (Dist)',
    symbol: 'IS0R.DE',
  },
  {
    value: 'IGEA.SW',
    label:
      'IGEA.SW,  iShares Emerging Asia Local Govt Bond UCITS ETF USD (Dist)',
    name: 'iShares Emerging Asia Local Govt Bond UCITS ETF USD (Dist)',
    symbol: 'IGEA.SW',
  },
  {
    value: 'IRCP.SW',
    label:
      'IRCP.SW,  iShares V PLC - iShares € Corp Bond Interest Rate Hedged ESG UCITS ETF',
    name: 'iShares V PLC - iShares € Corp Bond Interest Rate Hedged ESG UCITS ETF',
    symbol: 'IRCP.SW',
  },
  {
    value: 'HYLD.L',
    label: 'HYLD.L,  iShares Global High Yield Corp Bond UCITS ETF',
    name: 'iShares Global High Yield Corp Bond UCITS ETF',
    symbol: 'HYLD.L',
  },
  {
    value: 'IAAA.L',
    label: 'IAAA.L,  iShares Global AAA-AA Govt Bond UCITS ETF USD (Dist)',
    name: 'iShares Global AAA-AA Govt Bond UCITS ETF USD (Dist)',
    symbol: 'IAAA.L',
  },
  {
    value: 'IGEM.AS',
    label: 'IGEM.AS,  iShares J.P. Morgan $ EM Investment Grade Bond UCITS ETF',
    name: 'iShares J.P. Morgan $ EM Investment Grade Bond UCITS ETF',
    symbol: 'IGEM.AS',
  },
  {
    value: 'LQDH.SW',
    label:
      'LQDH.SW,  iShares $ Corp Bond Interest Rate Hedged UCITS ETF USD (Dist)',
    name: 'iShares $ Corp Bond Interest Rate Hedged UCITS ETF USD (Dist)',
    symbol: 'LQDH.SW',
  },
  {
    value: 'SDHY.L',
    label: 'SDHY.L,  iShares $ Short Duration High Yield Corp Bond UCITS ETF',
    name: 'iShares $ Short Duration High Yield Corp Bond UCITS ETF',
    symbol: 'SDHY.L',
  },
  {
    value: 'ERN1.L',
    label: 'ERN1.L,  iShares € Ultrashort Bond UCITS ETF',
    name: 'iShares € Ultrashort Bond UCITS ETF',
    symbol: 'ERN1.L',
  },
  {
    value: 'DTLE.L',
    label: 'DTLE.L,  iShares $ Treasury Bond 20+yr UCITS ETF EUR Hedged (Dist)',
    name: 'iShares $ Treasury Bond 20+yr UCITS ETF EUR Hedged (Dist)',
    symbol: 'DTLE.L',
  },
  {
    value: '0GGH.L',
    label:
      '0GGH.L,  iShares III Public Limited Company - iShares Global Aggregate Bond UCITS ETF',
    name: 'iShares III Public Limited Company - iShares Global Aggregate Bond UCITS ETF',
    symbol: '0GGH.L',
  },
  {
    value: 'ICBU.L',
    label: 'ICBU.L,  iShares $ Intermediate Credit Bond UCITS ETF',
    name: 'iShares $ Intermediate Credit Bond UCITS ETF',
    symbol: 'ICBU.L',
  },
  {
    value: 'AGBP.L',
    label: 'AGBP.L,  iShares Core Global Aggregate Bond UCITS ETF',
    name: 'iShares Core Global Aggregate Bond UCITS ETF',
    symbol: 'AGBP.L',
  },
  {
    value: 'EFRN.DE',
    label: 'EFRN.DE,  iShares € Floating Rate Bond ESG UCITS ETF',
    name: 'iShares € Floating Rate Bond ESG UCITS ETF',
    symbol: 'EFRN.DE',
  },
  {
    value: 'IGAA.L',
    label:
      'IGAA.L,  iShares Emerging Asia Local Govt Bond UCITS ETF USD (Dist)',
    name: 'iShares Emerging Asia Local Govt Bond UCITS ETF USD (Dist)',
    symbol: 'IGAA.L',
  },
  {
    value: 'DTLA.L',
    label: 'DTLA.L,  iShares $ Treasury Bond 20+yr UCITS ETF',
    name: 'iShares $ Treasury Bond 20+yr UCITS ETF',
    symbol: 'DTLA.L',
  },
  {
    value: 'OM3M.F',
    label:
      'OM3M.F,  iShares VII PLC - iShares VII PLC - iShares $ Treasury Bond 3-7yr UCITS ETF',
    name: 'iShares VII PLC - iShares VII PLC - iShares $ Treasury Bond 3-7yr UCITS ETF',
    symbol: 'OM3M.F',
  },
  {
    value: '3SUD.F',
    label: '3SUD.F,  iShares J.P. Morgan $ EM Bond UCITS ETF',
    name: 'iShares J.P. Morgan $ EM Bond UCITS ETF',
    symbol: '3SUD.F',
  },
  {
    value: 'UEDD.AS',
    label: 'UEDD.AS,  iShares $ Ultrashort Bond ESG UCITS ETF',
    name: 'iShares $ Ultrashort Bond ESG UCITS ETF',
    symbol: 'UEDD.AS',
  },
  {
    value: 'HYLE.F',
    label: 'HYLE.F,  iShares Global High Yield Corp Bond UCITS ETF',
    name: 'iShares Global High Yield Corp Bond UCITS ETF',
    symbol: 'HYLE.F',
  },
  {
    value: 'CORC.SW',
    label: 'CORC.SW,  iShares Global Corp Bond UCITS ETF',
    name: 'iShares Global Corp Bond UCITS ETF',
    symbol: 'CORC.SW',
  },
  {
    value: 'SGLU.AS',
    label: 'SGLU.AS,  iShares Global Govt Bond UCITS ETF',
    name: 'iShares Global Govt Bond UCITS ETF',
    symbol: 'SGLU.AS',
  },
  {
    value: 'EHYD.AS',
    label: 'EHYD.AS,  iShares € High Yield Corp Bond ESG UCITS ETF',
    name: 'iShares € High Yield Corp Bond ESG UCITS ETF',
    symbol: 'EHYD.AS',
  },
  {
    value: 'IS3V.DE',
    label:
      'IS3V.DE,  iShares Global Inflation Linked Govt Bond UCITS ETF EUR Hedged (Acc)',
    name: 'iShares Global Inflation Linked Govt Bond UCITS ETF EUR Hedged (Acc)',
    symbol: 'IS3V.DE',
  },
  {
    value: 'SNAZ.DE',
    label:
      'SNAZ.DE,  iShares V PLC - iShares J.P. Morgan $ EM Corp Bond UCITS ETF',
    name: 'iShares V PLC - iShares J.P. Morgan $ EM Corp Bond UCITS ETF',
    symbol: 'SNAZ.DE',
  },
  {
    value: 'CGGD.AS',
    label: 'CGGD.AS,  iShares Global Govt Bond Climate UCITS ETF',
    name: 'iShares Global Govt Bond Climate UCITS ETF',
    symbol: 'CGGD.AS',
  },
  {
    value: 'XZBE.F',
    label: 'XZBE.F,  Xtrackers USD Corporate Bond SRI PAB UCITS ETF',
    name: 'Xtrackers USD Corporate Bond SRI PAB UCITS ETF',
    symbol: 'XZBE.F',
  },
  {
    value: 'UEEF.F',
    label:
      'UEEF.F,  iShares $ High Yield Corp Bond ESG UCITS ETF EUR Hedged (Acc)',
    name: 'iShares $ High Yield Corp Bond ESG UCITS ETF EUR Hedged (Acc)',
    symbol: 'UEEF.F',
  },
  {
    value: 'AGUG.AS',
    label: 'AGUG.AS,  iShares Core Global Aggregate Bond UCITS ETF',
    name: 'iShares Core Global Aggregate Bond UCITS ETF',
    symbol: 'AGUG.AS',
  },
  {
    value: 'IEBB.SW',
    label: 'IEBB.SW,  iShares € Corp Bond BBB-BB UCITS ETF EUR (Dist)',
    name: 'iShares € Corp Bond BBB-BB UCITS ETF EUR (Dist)',
    symbol: 'IEBB.SW',
  },
  {
    value: 'RISE.L',
    label: 'RISE.L,  iShares Fallen Angels High Yield Corp Bond UCITS ETF',
    name: 'iShares Fallen Angels High Yield Corp Bond UCITS ETF',
    symbol: 'RISE.L',
  },
  {
    value: 'CNYB.AS',
    label: 'CNYB.AS,  iShares China CNY Bond UCITS ETF',
    name: 'iShares China CNY Bond UCITS ETF',
    symbol: 'CNYB.AS',
  },
  {
    value: 'JPEA.L',
    label: 'JPEA.L,  iShares J.P. Morgan $ EM Bond UCITS ETF',
    name: 'iShares J.P. Morgan $ EM Bond UCITS ETF',
    symbol: 'JPEA.L',
  },
  {
    value: 'IHYA.L',
    label: 'IHYA.L,  iShares $ High Yield Corp Bond UCITS ETF',
    name: 'iShares $ High Yield Corp Bond UCITS ETF',
    symbol: 'IHYA.L',
  },
  {
    value: 'IGLA.L',
    label: 'IGLA.L,  iShares Global Govt Bond UCITS ETF',
    name: 'iShares Global Govt Bond UCITS ETF',
    symbol: 'IGLA.L',
  },
  {
    value: 'AGGU.L',
    label: 'AGGU.L,  iShares Core Global Aggregate Bond UCITS ETF',
    name: 'iShares Core Global Aggregate Bond UCITS ETF',
    symbol: 'AGGU.L',
  },
  {
    value: 'DBXH.DE',
    label: 'DBXH.DE,  Xtrackers II Global Inflation-Linked Bond UCITS ETF',
    name: 'Xtrackers II Global Inflation-Linked Bond UCITS ETF',
    symbol: 'DBXH.DE',
  },
  {
    value: 'DXSW.DE',
    label: 'DXSW.DE,  Xtrackers II iBoxx Germany Covered Bond Swap UCITS ETF',
    name: 'Xtrackers II iBoxx Germany Covered Bond Swap UCITS ETF',
    symbol: 'DXSW.DE',
  },
  {
    value: 'DBZB.DE',
    label:
      'DBZB.DE,  Xtrackers II Global Government Bond UCITS ETF 1C - EUR Hedged',
    name: 'Xtrackers II Global Government Bond UCITS ETF 1C - EUR Hedged',
    symbol: 'DBZB.DE',
  },
  {
    value: 'XBTR.SW',
    label: 'XBTR.SW,  Xtrackers II Germany Government Bond UCITS ETF 1D',
    name: 'Xtrackers II Germany Government Bond UCITS ETF 1D',
    symbol: 'XBTR.SW',
  },
  {
    value: 'XB4F.DE',
    label: 'XB4F.DE,  Xtrackers II ESG EUR Corporate Bond UCITS ETF',
    name: 'Xtrackers II ESG EUR Corporate Bond UCITS ETF',
    symbol: 'XB4F.DE',
  },
  {
    value: 'XCS2.MI',
    label: 'XCS2.MI,  Xtrackers II Australia Government Bond UCITS ETF',
    name: 'Xtrackers II Australia Government Bond UCITS ETF',
    symbol: 'XCS2.MI',
  },
  {
    value: 'XY4P.DE',
    label:
      'XY4P.DE,  Xtrackers II iBoxx Eurozone Government Bond Yield Plus UCITS ETF',
    name: 'Xtrackers II iBoxx Eurozone Government Bond Yield Plus UCITS ETF',
    symbol: 'XY4P.DE',
  },
  {
    value: 'XBOT.MI',
    label: 'XBOT.MI,  Xtrackers II Italy Government Bond 0-1 Swap UCITS ETF',
    name: 'Xtrackers II Italy Government Bond 0-1 Swap UCITS ETF',
    symbol: 'XBOT.MI',
  },
  {
    value: 'XGVD.MI',
    label:
      'XGVD.MI,  Xtrackers II Global Government Bond UCITS ETF 1D - EUR Hedged',
    name: 'Xtrackers II Global Government Bond UCITS ETF 1D - EUR Hedged',
    symbol: 'XGVD.MI',
  },
  {
    value: 'XLIQ.DE',
    label: 'XLIQ.DE,  Xtrackers II EUR Covered Bond Swap UCITS ETF',
    name: 'Xtrackers II EUR Covered Bond Swap UCITS ETF',
    symbol: 'XLIQ.DE',
  },
  {
    value: 'XG7S.DE',
    label: 'XG7S.DE,  Xtrackers II Global Government Bond UCITS ETF',
    name: 'Xtrackers II Global Government Bond UCITS ETF',
    symbol: 'XG7S.DE',
  },
  {
    value: 'XBAG.DE',
    label: 'XBAG.DE,  Xtrackers II ESG Global Aggregate Bond ETF',
    name: 'Xtrackers II ESG Global Aggregate Bond ETF',
    symbol: 'XBAG.DE',
  },
  {
    value: 'XJSE.DE',
    label: 'XJSE.DE,  Xtrackers II Japan Government Bond UCITS ETF',
    name: 'Xtrackers II Japan Government Bond UCITS ETF',
    symbol: 'XJSE.DE',
  },
  {
    value: 'CGB.DE',
    label: 'CGB.DE,  Xtrackers II Harvest China Government Bond UCITS ETF',
    name: 'Xtrackers II Harvest China Government Bond UCITS ETF',
    symbol: 'CGB.DE',
  },
  {
    value: 'USIC.MI',
    label:
      'USIC.MI,  Lyxor Index Fund - Lyxor ESG USD Corporate Bond (DR) UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor ESG USD Corporate Bond (DR) UCITS ETF',
    symbol: 'USIC.MI',
  },
  {
    value: 'LYXC.DE',
    label:
      'LYXC.DE,  MULTI-UNITS LUXEMBOURG - Lyxor Euro Government Bond 5-7Y (DR) UCITS ETF',
    name: 'MULTI-UNITS LUXEMBOURG - Lyxor Euro Government Bond 5-7Y (DR) UCITS ETF',
    symbol: 'LYXC.DE',
  },
  {
    value: 'GGOV.PA',
    label:
      'GGOV.PA,  Amundi Index Solutions - Amundi Index J.P. Morgan GBI Global Govies UCITS ETF DR',
    name: 'Amundi Index Solutions - Amundi Index J.P. Morgan GBI Global Govies UCITS ETF DR',
    symbol: 'GGOV.PA',
  },
  {
    value: 'KLMH.F',
    label: 'KLMH.F,  Lyxor Green Bond (DR) UCITS ETF',
    name: 'Lyxor Green Bond (DR) UCITS ETF',
    symbol: 'KLMH.F',
  },
  {
    value: 'HYS.MI',
    label:
      'HYS.MI,  Lyxor Index Fund - Lyxor BofAML € Short Term High Yield Bond UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor BofAML € Short Term High Yield Bond UCITS ETF',
    symbol: 'HYS.MI',
  },
  {
    value: 'EGV3.F',
    label:
      'EGV3.F,  MULTI-UNITS LUXEMBOURG - Lyxor Euro Government Bond 1-3Y (DR) UCITS ETF',
    name: 'MULTI-UNITS LUXEMBOURG - Lyxor Euro Government Bond 1-3Y (DR) UCITS ETF',
    symbol: 'EGV3.F',
  },
  {
    value: 'EGV5.F',
    label:
      'EGV5.F,  MULTI-UNITS LUXEMBOURG - Lyxor Euro Government Bond 3-5Y (DR) UCITS ETF',
    name: 'MULTI-UNITS LUXEMBOURG - Lyxor Euro Government Bond 3-5Y (DR) UCITS ETF',
    symbol: 'EGV5.F',
  },
  {
    value: 'NADB.F',
    label:
      'NADB.F,  MULTI-UNITS LUXEMBOURG - Lyxor Euro Government Bond 10-15Y (DR) UCITS ETF',
    name: 'MULTI-UNITS LUXEMBOURG - Lyxor Euro Government Bond 10-15Y (DR) UCITS ETF',
    symbol: 'NADB.F',
  },
  {
    value: 'AHYE.PA',
    label: 'AHYE.PA,  Amundi Index Solutions - Amundi EURO High Yield Bond ESG',
    name: 'Amundi Index Solutions - Amundi EURO High Yield Bond ESG',
    symbol: 'AHYE.PA',
  },
  {
    value: 'CB3.PA',
    label:
      'CB3.PA,  Amundi Index Solutions - Amundi Govt Bond Euro Broad Investment Grade',
    name: 'Amundi Index Solutions - Amundi Govt Bond Euro Broad Investment Grade',
    symbol: 'CB3.PA',
  },
  {
    value: 'LCVB.F',
    label:
      'LCVB.F,  Lyxor Index Fund - Lyxor EuroMTS Covered Bond Aggregate UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor EuroMTS Covered Bond Aggregate UCITS ETF',
    symbol: 'LCVB.F',
  },
  {
    value: 'MA13.PA',
    label:
      'MA13.PA,  Lyxor EuroMTS Highest Rated Macro-Weighted Govt Bond 1-3Y (DR) UCITS ETF',
    name: 'Lyxor EuroMTS Highest Rated Macro-Weighted Govt Bond 1-3Y (DR) UCITS ETF',
    symbol: 'MA13.PA',
  },
  {
    value: 'EGV7.F',
    label:
      'EGV7.F,  MULTI-UNITS LUXEMBOURG - Lyxor Euro Government Bond 5-7Y (DR) UCITS ETF',
    name: 'MULTI-UNITS LUXEMBOURG - Lyxor Euro Government Bond 5-7Y (DR) UCITS ETF',
    symbol: 'EGV7.F',
  },
  {
    value: 'JAPB.AS',
    label:
      'JAPB.AS,  MULTI UNITS LUXEMBOURG - Lyxor Core Japanese Government Bond (DR) UCITS ETF',
    name: 'MULTI UNITS LUXEMBOURG - Lyxor Core Japanese Government Bond (DR) UCITS ETF',
    symbol: 'JAPB.AS',
  },
  {
    value: 'C10.PA',
    label:
      'C10.PA,  Amundi ETF Govt Bond Euro Broad Investment Grade 10-15 UCITS ETF',
    name: 'Amundi ETF Govt Bond Euro Broad Investment Grade 10-15 UCITS ETF',
    symbol: 'C10.PA',
  },
  {
    value: 'IBTS.L',
    label: 'IBTS.L,  iShares $ Treasury Bond 1-3yr UCITS ETF USD (Dist)',
    name: 'iShares $ Treasury Bond 1-3yr UCITS ETF USD (Dist)',
    symbol: 'IBTS.L',
  },
  {
    value: 'IBTM.SW',
    label: 'IBTM.SW,  iShares $ Treasury Bond 7-10yr UCITS ETF USD (Dist)',
    name: 'iShares $ Treasury Bond 7-10yr UCITS ETF USD (Dist)',
    symbol: 'IBTM.SW',
  },
  {
    value: 'AGGG.L',
    label: 'AGGG.L,  iShares Core Global Aggregate Bond UCITS ETF',
    name: 'iShares Core Global Aggregate Bond UCITS ETF',
    symbol: 'AGGG.L',
  },
  {
    value: 'IEAC.L',
    label: 'IEAC.L,  iShares Core € Corp Bond UCITS ETF EUR (Dist)',
    name: 'iShares Core € Corp Bond UCITS ETF EUR (Dist)',
    symbol: 'IEAC.L',
  },
  {
    value: 'IS0L.DE',
    label:
      'IS0L.DE,  iShares V PLC - iShares Germany Govt Bond UCITS ETF EUR (Dist)',
    name: 'iShares V PLC - iShares Germany Govt Bond UCITS ETF EUR (Dist)',
    symbol: 'IS0L.DE',
  },
  {
    value: 'SHYG.L',
    label: 'SHYG.L,  iShares € High Yield Corp Bond UCITS ETF EUR (Dist)',
    name: 'iShares € High Yield Corp Bond UCITS ETF EUR (Dist)',
    symbol: 'SHYG.L',
  },
  {
    value: 'EMBC.SW',
    label:
      'EMBC.SW,  iShares J.P. Morgan $ EM Bond CHF Hedged UCITS ETF (Dist)',
    name: 'iShares J.P. Morgan $ EM Bond CHF Hedged UCITS ETF (Dist)',
    symbol: 'EMBC.SW',
  },
  {
    value: 'SDIG.L',
    label: 'SDIG.L,  iShares $ Short Duration Corp Bond UCITS ETF USD (Dist)',
    name: 'iShares $ Short Duration Corp Bond UCITS ETF USD (Dist)',
    symbol: 'SDIG.L',
  },
  {
    value: 'ERND.L',
    label: 'ERND.L,  iShares $ Ultrashort Bond UCITS ETF',
    name: 'iShares $ Ultrashort Bond UCITS ETF',
    symbol: 'ERND.L',
  },
  {
    value: 'DTLC.SW',
    label: 'DTLC.SW,  iShares $ Treasury Bond 20+yr UCITS ETF',
    name: 'iShares $ Treasury Bond 20+yr UCITS ETF',
    symbol: 'DTLC.SW',
  },
  {
    value: 'EMES.L',
    label: 'EMES.L,  iShares J.P. Morgan ESG $ EM Bond UCITS ETF',
    name: 'iShares J.P. Morgan ESG $ EM Bond UCITS ETF',
    symbol: 'EMES.L',
  },
  {
    value: 'FLOA.L',
    label: 'FLOA.L,  iShares $ Floating Rate Bond UCITS ETF',
    name: 'iShares $ Floating Rate Bond UCITS ETF',
    symbol: 'FLOA.L',
  },
  {
    value: 'IBTE.L',
    label: 'IBTE.L,  iShares $ Treasury Bond 1-3yr UCITS ETF',
    name: 'iShares $ Treasury Bond 1-3yr UCITS ETF',
    symbol: 'IBTE.L',
  },
  {
    value: 'XUHY.SW',
    label:
      'XUHY.SW,  Xtrackers (IE) Plc - Xtrackers USD High Yield Corporate Bond UCITS ETF 1D',
    name: 'Xtrackers (IE) Plc - Xtrackers USD High Yield Corporate Bond UCITS ETF 1D',
    symbol: 'XUHY.SW',
  },
  {
    value: 'IEAA.L',
    label: 'IEAA.L,  iShares Core € Corp Bond UCITS ETF',
    name: 'iShares Core € Corp Bond UCITS ETF',
    symbol: 'IEAA.L',
  },
  {
    value: 'EMGA.L',
    label: 'EMGA.L,  iShares J.P. Morgan EM Local Govt Bond UCITS ETF',
    name: 'iShares J.P. Morgan EM Local Govt Bond UCITS ETF',
    symbol: 'EMGA.L',
  },
  {
    value: 'CBUE.F',
    label: 'CBUE.F,  iShares $ Treasury Bond 3-7yr UCITS ETF EUR Hedged (Dist)',
    name: 'iShares $ Treasury Bond 3-7yr UCITS ETF EUR Hedged (Dist)',
    symbol: 'CBUE.F',
  },
  {
    value: '36BA.F',
    label: '36BA.F,  iShares $ Corp Bond ESG UCITS ETF',
    name: 'iShares $ Corp Bond ESG UCITS ETF',
    symbol: '36BA.F',
  },
  {
    value: 'GOVT.AS',
    label: 'GOVT.AS,  iShares $ Treasury Bond UCITS ETF',
    name: 'iShares $ Treasury Bond UCITS ETF',
    symbol: 'GOVT.AS',
  },
  {
    value: 'DHYD.AS',
    label: 'DHYD.AS,  iShares $ High Yield Corp Bond ESG UCITS ETF',
    name: 'iShares $ High Yield Corp Bond ESG UCITS ETF',
    symbol: 'DHYD.AS',
  },
  {
    value: 'CYBA.AS',
    label: 'CYBA.AS,  iShares China CNY Bond UCITS ETF USD Acc',
    name: 'iShares China CNY Bond UCITS ETF USD Acc',
    symbol: 'CYBA.AS',
  },
  {
    value: 'IGAE.AS',
    label: 'IGAE.AS,  iShares J.P. Morgan $ EM Investment Grade Bond UCITS ETF',
    name: 'iShares J.P. Morgan $ EM Investment Grade Bond UCITS ETF',
    symbol: 'IGAE.AS',
  },
  {
    value: 'IX5A.AS',
    label:
      'IX5A.AS,  iShares € Corp Bond ex-Financials 1-5yr ESG UCITS ETF EUR (Dist)',
    name: 'iShares € Corp Bond ex-Financials 1-5yr ESG UCITS ETF EUR (Dist)',
    symbol: 'IX5A.AS',
  },
  {
    value: 'XZBU.F',
    label: 'XZBU.F,  Xtrackers ESG USD Corporate Bond UCITS ETF',
    name: 'Xtrackers ESG USD Corporate Bond UCITS ETF',
    symbol: 'XZBU.F',
  },
  {
    value: 'SECA.DE',
    label: 'SECA.DE,  iShares € Govt Bond Climate UCITS ETF',
    name: 'iShares € Govt Bond Climate UCITS ETF',
    symbol: 'SECA.DE',
  },
  {
    value: 'IDTL.L',
    label: 'IDTL.L,  iShares $ Treasury Bond 20+yr UCITS ETF USD (Dist)',
    name: 'iShares $ Treasury Bond 20+yr UCITS ETF USD (Dist)',
    symbol: 'IDTL.L',
  },
  {
    value: 'XDEP.DE',
    label: 'XDEP.DE,  Xtrackers iBoxx EUR Corporate Bond Yield Plus UCITS ETF',
    name: 'Xtrackers iBoxx EUR Corporate Bond Yield Plus UCITS ETF',
    symbol: 'XDEP.DE',
  },
  {
    value: 'HYLA.L',
    label: 'HYLA.L,  iShares Global High Yield Corp Bond UCITS ETF USD (Acc)',
    name: 'iShares Global High Yield Corp Bond UCITS ETF USD (Acc)',
    symbol: 'HYLA.L',
  },
  {
    value: 'IUAA.L',
    label: 'IUAA.L,  iShares US Aggregate Bond UCITS ETF',
    name: 'iShares US Aggregate Bond UCITS ETF',
    symbol: 'IUAA.L',
  },
  {
    value: 'FLOT.L',
    label: 'FLOT.L,  iShares $ Floating Rate Bond UCITS ETF',
    name: 'iShares $ Floating Rate Bond UCITS ETF',
    symbol: 'FLOT.L',
  },
  {
    value: 'DBXN.DE',
    label: 'DBXN.DE,  Xtrackers II Eurozone Government Bond UCITS ETF',
    name: 'Xtrackers II Eurozone Government Bond UCITS ETF',
    symbol: 'DBXN.DE',
  },
  {
    value: 'DBXK.DE',
    label: 'DBXK.DE,  Xtrackers II Eurozone Inflation-Linked Bond UCITS ETF',
    name: 'Xtrackers II Eurozone Inflation-Linked Bond UCITS ETF',
    symbol: 'DBXK.DE',
  },
  {
    value: 'D5BC.DE',
    label: 'D5BC.DE,  Xtrackers II Germany Government Bond 1-3 UCITS ETF',
    name: 'Xtrackers II Germany Government Bond 1-3 UCITS ETF',
    symbol: 'D5BC.DE',
  },
  {
    value: 'XBAT.DE',
    label: 'XBAT.DE,  Xtrackers II Eurozone AAA Government Bond Swap UCITS ETF',
    name: 'Xtrackers II Eurozone AAA Government Bond Swap UCITS ETF',
    symbol: 'XBAT.DE',
  },
  {
    value: 'X03B.DE',
    label: 'X03B.DE,  Xtrackers II Eurozone Government Bond 1-3 UCITS ETF',
    name: 'Xtrackers II Eurozone Government Bond 1-3 UCITS ETF',
    symbol: 'X03B.DE',
  },
  {
    value: 'XGIU.F',
    label: 'XGIU.F,  Xtrackers II Global Inflation-Linked Bond UCITS ETF',
    name: 'Xtrackers II Global Inflation-Linked Bond UCITS ETF',
    symbol: 'XGIU.F',
  },
  {
    value: 'XBAU.SW',
    label: 'XBAU.SW,  Xtrackers II ESG Global Aggregate Bond ETF',
    name: 'Xtrackers II ESG Global Aggregate Bond ETF',
    symbol: 'XBAU.SW',
  },
  {
    value: 'XGII.DE',
    label: 'XGII.DE,  Xtrackers II Global Inflation-Linked Bond UCITS ETF',
    name: 'Xtrackers II Global Inflation-Linked Bond UCITS ETF',
    symbol: 'XGII.DE',
  },
  {
    value: 'XHY1.DE',
    label:
      'XHY1.DE,  Xtrackers II EUR High Yield Corporate Bond 1-3 Swap UCITS ETF',
    name: 'Xtrackers II EUR High Yield Corporate Bond 1-3 Swap UCITS ETF',
    symbol: 'XHY1.DE',
  },
  {
    value: 'USIG.L',
    label:
      'USIG.L,  Lyxor Index Fund - Lyxor ESG USD Corporate Bond (DR) UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor ESG USD Corporate Bond (DR) UCITS ETF',
    symbol: 'USIG.L',
  },
  {
    value: 'USIH.PA',
    label:
      'USIH.PA,  Lyxor Index Fund - Lyxor ESG USD Corporate Bond (DR) UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor ESG USD Corporate Bond (DR) UCITS ETF',
    symbol: 'USIH.PA',
  },
  {
    value: 'MTD.PA',
    label:
      'MTD.PA,  MULTI-UNITS LUXEMBOURG - Lyxor Euro Government Bond 7-10Y (DR) UCITS ETF',
    name: 'MULTI-UNITS LUXEMBOURG - Lyxor Euro Government Bond 7-10Y (DR) UCITS ETF',
    symbol: 'MTD.PA',
  },
  {
    value: 'USYH.MI',
    label:
      'USYH.MI,  Lyxor Index Fund - Lyxor ESG USD High Yield (DR) UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor ESG USD High Yield (DR) UCITS ETF',
    symbol: 'USYH.MI',
  },
  {
    value: 'MTB.PA',
    label:
      'MTB.PA,  MULTI-UNITS LUXEMBOURG - Lyxor Euro Government Bond 3-5Y (DR) UCITS ETF',
    name: 'MULTI-UNITS LUXEMBOURG - Lyxor Euro Government Bond 3-5Y (DR) UCITS ETF',
    symbol: 'MTB.PA',
  },
  {
    value: 'MTE.PA',
    label:
      'MTE.PA,  MULTI-UNITS LUXEMBOURG - Lyxor Euro Government Bond 10-15Y (DR) UCITS ETF',
    name: 'MULTI-UNITS LUXEMBOURG - Lyxor Euro Government Bond 10-15Y (DR) UCITS ETF',
    symbol: 'MTE.PA',
  },
  {
    value: 'MTIG.MI',
    label:
      'MTIG.MI,  MULTI-UNITS LUXEMBOURG - Lyxor Euro Government Bond (DR) UCITS ETF',
    name: 'MULTI-UNITS LUXEMBOURG - Lyxor Euro Government Bond (DR) UCITS ETF',
    symbol: 'MTIG.MI',
  },
  {
    value: 'X13G.PA',
    label:
      'X13G.PA,  Amundi Index Solutions - Amundi Govt Bond Lowest Rated Euro Investment Grade 1-3',
    name: 'Amundi Index Solutions - Amundi Govt Bond Lowest Rated Euro Investment Grade 1-3',
    symbol: 'X13G.PA',
  },
  {
    value: 'MA35.PA',
    label:
      'MA35.PA,  Lyxor EuroMTS Highest Rated Macro-Weighted Govt Bond 3-5Y (DR) UCITS ETF',
    name: 'Lyxor EuroMTS Highest Rated Macro-Weighted Govt Bond 3-5Y (DR) UCITS ETF',
    symbol: 'MA35.PA',
  },
  {
    value: 'GISE.SW',
    label:
      'GISE.SW,  Lyxor Core Global Inflation-Linked 1-10Y Bond (DR) UCITS ETF',
    name: 'Lyxor Core Global Inflation-Linked 1-10Y Bond (DR) UCITS ETF',
    symbol: 'GISE.SW',
  },
  {
    value: 'C33.PA',
    label:
      'C33.PA,  Amundi ETF Govt Bond Euro Broad Investment Grade 3-5 UCITS ETF',
    name: 'Amundi ETF Govt Bond Euro Broad Investment Grade 3-5 UCITS ETF',
    symbol: 'C33.PA',
  },
  {
    value: 'SLXX.L',
    label: 'SLXX.L,  iShares Core £ Corp Bond UCITS ETF',
    name: 'iShares Core £ Corp Bond UCITS ETF',
    symbol: 'SLXX.L',
  },
  {
    value: 'IBGM.L',
    label: 'IBGM.L,  iShares € Govt Bond 7-10yr UCITS ETF',
    name: 'iShares € Govt Bond 7-10yr UCITS ETF',
    symbol: 'IBGM.L',
  },
  {
    value: 'IGIL.SW',
    label:
      'IGIL.SW,  iShares Global Inflation Linked Govt Bond UCITS ETF USD (Acc)',
    name: 'iShares Global Inflation Linked Govt Bond UCITS ETF USD (Acc)',
    symbol: 'IGIL.SW',
  },
  {
    value: 'IBGE.L',
    label: 'IBGE.L,  iShares € Govt Bond 0-1yr UCITS ETF',
    name: 'iShares € Govt Bond 0-1yr UCITS ETF',
    symbol: 'IBGE.L',
  },
  {
    value: 'IBGY.L',
    label: 'IBGY.L,  iShares € Govt Bond 5-7yr UCITS ETF',
    name: 'iShares € Govt Bond 5-7yr UCITS ETF',
    symbol: 'IBGY.L',
  },
  {
    value: 'CORP.L',
    label: 'CORP.L,  iShares Global Corp Bond UCITS ETF USD (Dist)',
    name: 'iShares Global Corp Bond UCITS ETF USD (Dist)',
    symbol: 'CORP.L',
  },
  {
    value: 'EMBE.L',
    label: 'EMBE.L,  iShares J.P. Morgan $ EM Bond EUR Hedged UCITS ETF (Dist)',
    name: 'iShares J.P. Morgan $ EM Bond EUR Hedged UCITS ETF (Dist)',
    symbol: 'EMBE.L',
  },
  {
    value: 'CRPA.L',
    label: 'CRPA.L,  iShares Global Corp Bond UCITS ETF',
    name: 'iShares Global Corp Bond UCITS ETF',
    symbol: 'CRPA.L',
  },
  {
    value: 'XYLE.DE',
    label:
      'XYLE.DE,  Xtrackers USD Corporate Bond Short Duration SRI PAB UCITS ETF',
    name: 'Xtrackers USD Corporate Bond Short Duration SRI PAB UCITS ETF',
    symbol: 'XYLE.DE',
  },
  {
    value: 'ERNA.L',
    label: 'ERNA.L,  iShares $ Ultrashort Bond UCITS ETF',
    name: 'iShares $ Ultrashort Bond UCITS ETF',
    symbol: 'ERNA.L',
  },
  {
    value: 'IBB1.F',
    label:
      'IBB1.F,  iShares $ Treasury Bond 7-10yr UCITS ETF EUR Hedged (Dist)',
    name: 'iShares $ Treasury Bond 7-10yr UCITS ETF EUR Hedged (Dist)',
    symbol: 'IBB1.F',
  },
  {
    value: 'EHYA.AS',
    label: 'EHYA.AS,  iShares € High Yield Corp Bond ESG UCITS ETF',
    name: 'iShares € High Yield Corp Bond ESG UCITS ETF',
    symbol: 'EHYA.AS',
  },
  {
    value: 'SUOA.AS',
    label: 'SUOA.AS,  iShares $ Corp Bond ESG UCITS ETF USD Acc',
    name: 'iShares $ Corp Bond ESG UCITS ETF USD Acc',
    symbol: 'SUOA.AS',
  },
  {
    value: 'IGLE.AS',
    label: 'IGLE.AS,  iShares Global Govt Bond UCITS ETF',
    name: 'iShares Global Govt Bond UCITS ETF',
    symbol: 'IGLE.AS',
  },
  {
    value: 'CGBI.AS',
    label: 'CGBI.AS,  iShares China CNY Govt Bond UCITS ETF',
    name: 'iShares China CNY Govt Bond UCITS ETF',
    symbol: 'CGBI.AS',
  },
  {
    value: 'IBTA.L',
    label: 'IBTA.L,  iShares $ Treasury Bond 1-3yr UCITS ETF',
    name: 'iShares $ Treasury Bond 1-3yr UCITS ETF',
    symbol: 'IBTA.L',
  },
  {
    value: 'SUSU.L',
    label: 'SUSU.L,  iShares $ Corp Bond 0-3yr ESG UCITS ETF',
    name: 'iShares $ Corp Bond 0-3yr ESG UCITS ETF',
    symbol: 'SUSU.L',
  },
  {
    value: 'DBXP.DE',
    label: 'DBXP.DE,  Xtrackers II Eurozone Government Bond 1-3 UCITS ETF 1C',
    name: 'Xtrackers II Eurozone Government Bond 1-3 UCITS ETF 1C',
    symbol: 'DBXP.DE',
  },
  {
    value: 'D5BG.DE',
    label: 'D5BG.DE,  Xtrackers II EUR Corporate Bond UCITS ETF',
    name: 'Xtrackers II EUR Corporate Bond UCITS ETF',
    symbol: 'D5BG.DE',
  },
  {
    value: 'X03C.DE',
    label: 'X03C.DE,  Xtrackers II Eurozone Government Bond 3-5 UCITS ETF',
    name: 'Xtrackers II Eurozone Government Bond 3-5 UCITS ETF',
    symbol: 'X03C.DE',
  },
  {
    value: 'XYP1.F',
    label:
      'XYP1.F,  Xtrackers II iBoxx Eurozone Government Bond Yield Plus 1-3 UCITS ETF',
    name: 'Xtrackers II iBoxx Eurozone Government Bond Yield Plus 1-3 UCITS ETF',
    symbol: 'XYP1.F',
  },
  {
    value: 'XHYG.DE',
    label: 'XHYG.DE,  Xtrackers II EUR High Yield Corporate Bond UCITS ETF',
    name: 'Xtrackers II EUR High Yield Corporate Bond UCITS ETF',
    symbol: 'XHYG.DE',
  },
  {
    value: 'MTF.PA',
    label:
      'MTF.PA,  MULTI-UNITS LUXEMBOURG - Lyxor Euro Government Bond 15+Y (DR) UCITS ETF',
    name: 'MULTI-UNITS LUXEMBOURG - Lyxor Euro Government Bond 15+Y (DR) UCITS ETF',
    symbol: 'MTF.PA',
  },
  {
    value: 'UIFL.PA',
    label:
      'UIFL.PA,  Amundi Index Solutions - Amundi Index US Gov Inflation-Linked Bond',
    name: 'Amundi Index Solutions - Amundi Index US Gov Inflation-Linked Bond',
    symbol: 'UIFL.PA',
  },
  {
    value: 'AM3A.MI',
    label:
      'AM3A.MI,  Amundi Index Solutions - Amundi Govt Bond Highest Rated Euro Investment Grade',
    name: 'Amundi Index Solutions - Amundi Govt Bond Highest Rated Euro Investment Grade',
    symbol: 'AM3A.MI',
  },
  {
    value: 'MTH.PA',
    label:
      'MTH.PA,  Lyxor Index Fund - Lyxor UltraLongDuratEuroGvtFtseMts25+Ydr UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor UltraLongDuratEuroGvtFtseMts25+Ydr UCITS ETF',
    symbol: 'MTH.PA',
  },
  {
    value: 'CBEF.MI',
    label:
      'CBEF.MI,  Lyxor ESG Euro Corporate Bond Ex Financials (DR) UCITS ETF',
    name: 'Lyxor ESG Euro Corporate Bond Ex Financials (DR) UCITS ETF',
    symbol: 'CBEF.MI',
  },
  {
    value: 'GISG.L',
    label:
      'GISG.L,  Lyxor Core Global Inflation-Linked 1-10Y Bond (DR) UCITS ETF',
    name: 'Lyxor Core Global Inflation-Linked 1-10Y Bond (DR) UCITS ETF',
    symbol: 'GISG.L',
  },
  {
    value: 'GOVG.MI',
    label: 'GOVG.MI,  Lyxor Core Glb Gov Bond UCITS ETF H EUR',
    name: 'Lyxor Core Glb Gov Bond UCITS ETF H EUR',
    symbol: 'GOVG.MI',
  },
  {
    value: 'EXHF.DE',
    label:
      'EXHF.DE,  iShares Euro Government Bond Capped 1.5-10.5yr UCITS ETF (DE)',
    name: 'iShares Euro Government Bond Capped 1.5-10.5yr UCITS ETF (DE)',
    symbol: 'EXHF.DE',
  },
  {
    value: 'C53.PA',
    label:
      'C53.PA,  Amundi ETF Govt Bond EuroMTS Broad Investment Grade 5-7 UCITS ETF DR C',
    name: 'Amundi ETF Govt Bond EuroMTS Broad Investment Grade 5-7 UCITS ETF DR C',
    symbol: 'C53.PA',
  },
  {
    value: 'IBGL.L',
    label: 'IBGL.L,  iShares € Govt Bond 15-30yr UCITS ETF',
    name: 'iShares € Govt Bond 15-30yr UCITS ETF',
    symbol: 'IBGL.L',
  },
  {
    value: 'SEMB.L',
    label: 'SEMB.L,  iShares J.P. Morgan $ EM Bond UCITS ETF USD (Dist)',
    name: 'iShares J.P. Morgan $ EM Bond UCITS ETF USD (Dist)',
    symbol: 'SEMB.L',
  },
  {
    value: 'ICOV.SW',
    label: 'ICOV.SW,  iShares € Covered Bond UCITS ETF',
    name: 'iShares € Covered Bond UCITS ETF',
    symbol: 'ICOV.SW',
  },
  {
    value: 'IBGZ.L',
    label: 'IBGZ.L,  iShares € Govt Bond 10-15yr UCITS ETF EUR (Dist)',
    name: 'iShares € Govt Bond 10-15yr UCITS ETF EUR (Dist)',
    symbol: 'IBGZ.L',
  },
  {
    value: 'IFRB.L',
    label:
      'IFRB.L,  iShares V PLC - iShares France Govt Bond UCITS ETF EUR (Dist)',
    name: 'iShares V PLC - iShares France Govt Bond UCITS ETF EUR (Dist)',
    symbol: 'IFRB.L',
  },
  {
    value: 'EMCH.SW',
    label: 'EMCH.SW,  iShares J.P. Morgan $ EM Bond UCITS ETF',
    name: 'iShares J.P. Morgan $ EM Bond UCITS ETF',
    symbol: 'EMCH.SW',
  },
  {
    value: 'IBTU.L',
    label: 'IBTU.L,  iShares $ Treasury Bond 0-1yr UCITS ETF USD (Dist)',
    name: 'iShares $ Treasury Bond 0-1yr UCITS ETF USD (Dist)',
    symbol: 'IBTU.L',
  },
  {
    value: '36B7.F',
    label: '36B7.F,  iShares Global Corp Bond UCITS ETF',
    name: 'iShares Global Corp Bond UCITS ETF',
    symbol: '36B7.F',
  },
  {
    value: 'DBXQ.DE',
    label: 'DBXQ.DE,  Xtrackers II Eurozone Government Bond 3-5 UCITS ETF 1C',
    name: 'Xtrackers II Eurozone Government Bond 3-5 UCITS ETF 1C',
    symbol: 'DBXQ.DE',
  },
  {
    value: 'DXSU.DE',
    label:
      'DXSU.DE,  Xtrackers II USD Emerging Markets Bond UCITS ETF 1C - EUR Hedged',
    name: 'Xtrackers II USD Emerging Markets Bond UCITS ETF 1C - EUR Hedged',
    symbol: 'DXSU.DE',
  },
  {
    value: 'XEC1.F',
    label: 'XEC1.F,  Xtrackers II EUR Corporate Bond UCITS ETF',
    name: 'Xtrackers II EUR Corporate Bond UCITS ETF',
    symbol: 'XEC1.F',
  },
  {
    value: 'XBAC.SW',
    label:
      'XBAC.SW,  Xtrackers II Global Aggregate Bond Swap UCITS ETF 4C - CHF Hedged',
    name: 'Xtrackers II Global Aggregate Bond Swap UCITS ETF 4C - CHF Hedged',
    symbol: 'XBAC.SW',
  },
  {
    value: 'XHYA.DE',
    label: 'XHYA.DE,  Xtrackers II EUR High Yield Corporate Bond UCITS ETF',
    name: 'Xtrackers II EUR High Yield Corporate Bond UCITS ETF',
    symbol: 'XHYA.DE',
  },
  {
    value: 'CLIM.PA',
    label: 'CLIM.PA,  Lyxor Green Bond (DR) UCITS ETF',
    name: 'Lyxor Green Bond (DR) UCITS ETF',
    symbol: 'CLIM.PA',
  },
  {
    value: 'X1G.PA',
    label:
      'X1G.PA,  Amundi Index Solutions - Amundi Govt Bond Lowest Rated Euromts Investment Grade',
    name: 'Amundi Index Solutions - Amundi Govt Bond Lowest Rated Euromts Investment Grade',
    symbol: 'X1G.PA',
  },
  {
    value: '10AK.F',
    label:
      '10AK.F,  Amundi Index Solutions - Amundi Index J.P. Morgan GBI Global Govies',
    name: 'Amundi Index Solutions - Amundi Index J.P. Morgan GBI Global Govies',
    symbol: '10AK.F',
  },
  {
    value: 'PRAB.F',
    label:
      'PRAB.F,  Amundi Index Solutions - Amundi Prime Euro Govies Bonds 0-1Y',
    name: 'Amundi Index Solutions - Amundi Prime Euro Govies Bonds 0-1Y',
    symbol: 'PRAB.F',
  },
  {
    value: 'C73.PA',
    label:
      'C73.PA,  Amundi ETF Govt Bond Euro Broad Investment Grade 7-10 UCITS ETF DR',
    name: 'Amundi ETF Govt Bond Euro Broad Investment Grade 7-10 UCITS ETF DR',
    symbol: 'C73.PA',
  },
  {
    value: 'SXRP.DE',
    label:
      'SXRP.DE,  iShares VII PLC - iShares € Govt Bond 3-7yr UCITS ETF EUR (Acc)',
    name: 'iShares VII PLC - iShares € Govt Bond 3-7yr UCITS ETF EUR (Acc)',
    symbol: 'SXRP.DE',
  },
  {
    value: 'SEGA.L',
    label: 'SEGA.L,  iShares Core € Govt Bond UCITS ETF EUR (Dist)',
    name: 'iShares Core € Govt Bond UCITS ETF EUR (Dist)',
    symbol: 'SEGA.L',
  },
  {
    value: 'XQUE.MI',
    label:
      'XQUE.MI,  Xtrackers ESG USD Emerging Markets Bond Quality Weighted UCITS ETF',
    name: 'Xtrackers ESG USD Emerging Markets Bond Quality Weighted UCITS ETF',
    symbol: 'XQUE.MI',
  },
  {
    value: 'LQEE.L',
    label: 'LQEE.L,  iShares $ Corp Bond UCITS ETF EUR Hedged (Dist)',
    name: 'iShares $ Corp Bond UCITS ETF EUR Hedged (Dist)',
    symbol: 'LQEE.L',
  },
  {
    value: 'IB01.L',
    label: 'IB01.L,  iShares $ Treasury Bond 0-1yr UCITS ETF USD (Acc)',
    name: 'iShares $ Treasury Bond 0-1yr UCITS ETF USD (Acc)',
    symbol: 'IB01.L',
  },
  {
    value: 'CYBU.AS',
    label: 'CYBU.AS,  iShares China CNY Bond UCITS ETF',
    name: 'iShares China CNY Bond UCITS ETF',
    symbol: 'CYBU.AS',
  },
  {
    value: 'SUOE.L',
    label: 'SUOE.L,  iShares € Corp Bond ESG UCITS ETF',
    name: 'iShares € Corp Bond ESG UCITS ETF',
    symbol: 'SUOE.L',
  },
  {
    value: 'DBXR.F',
    label: 'DBXR.F,  Xtrackers II Eurozone Government Bond 5-7 UCITS ETF',
    name: 'Xtrackers II Eurozone Government Bond 5-7 UCITS ETF',
    symbol: 'DBXR.F',
  },
  {
    value: 'XGSG.L',
    label: 'XGSG.L,  Xtrackers II Global Government Bond UCITS ETF',
    name: 'Xtrackers II Global Government Bond UCITS ETF',
    symbol: 'XGSG.L',
  },
  {
    value: 'XBAE.DE',
    label: 'XBAE.DE,  Xtrackers II ESG Global Aggregate Bond ETF',
    name: 'Xtrackers II ESG Global Aggregate Bond ETF',
    symbol: 'XBAE.DE',
  },
  {
    value: 'AGEB.PA',
    label:
      'AGEB.PA,  Amundi Index Solutions - Amundi Global Emerging Bond Markit IBOXX',
    name: 'Amundi Index Solutions - Amundi Global Emerging Bond Markit IBOXX',
    symbol: 'AGEB.PA',
  },
  {
    value: 'XGBE.DE',
    label: 'XGBE.DE,  Xtrackers EUR Corporate Green Bond UCITS ETF',
    name: 'Xtrackers EUR Corporate Green Bond UCITS ETF',
    symbol: 'XGBE.DE',
  },
  {
    value: 'SXRQ.DE',
    label:
      'SXRQ.DE,  iShares VII PLC - iShares € Govt Bond 7-10yr UCITS ETF EUR (Acc)',
    name: 'iShares VII PLC - iShares € Govt Bond 7-10yr UCITS ETF EUR (Acc)',
    symbol: 'SXRQ.DE',
  },
  {
    value: 'CRPU.L',
    label: 'CRPU.L,  iShares Global Corp Bond UCITS ETF',
    name: 'iShares Global Corp Bond UCITS ETF',
    symbol: 'CRPU.L',
  },
  {
    value: 'SUSS.L',
    label: 'SUSS.L,  iShares € Corp Bond 0-3yr ESG UCITS ETF',
    name: 'iShares € Corp Bond 0-3yr ESG UCITS ETF',
    symbol: 'SUSS.L',
  },
  {
    value: 'EM13.MI',
    label:
      'EM13.MI,  MULTI-UNITS LUXEMBOURG - Lyxor Euro Government Bond 1-3Y (DR) UCITS ETF',
    name: 'MULTI-UNITS LUXEMBOURG - Lyxor Euro Government Bond 1-3Y (DR) UCITS ETF',
    symbol: 'EM13.MI',
  },
  {
    value: 'HIGH.L',
    label: 'HIGH.L,  iShares € High Yield Corp Bond UCITS ETF',
    name: 'iShares € High Yield Corp Bond UCITS ETF',
    symbol: 'HIGH.L',
  },
  {
    value: 'LQDA.L',
    label: 'LQDA.L,  iShares $ Corp Bond UCITS ETF USD (Acc)',
    name: 'iShares $ Corp Bond UCITS ETF USD (Acc)',
    symbol: 'LQDA.L',
  },
  {
    value: 'DBXF.DE',
    label: 'DBXF.DE,  Xtrackers II Eurozone Government Bond 15-30 UCITS ETF',
    name: 'Xtrackers II Eurozone Government Bond 15-30 UCITS ETF',
    symbol: 'DBXF.DE',
  },
  {
    value: 'X03H.SW',
    label:
      'X03H.SW,  Xtrackers II Global Government Bond UCITS ETF 4C - CHF Hedged',
    name: 'Xtrackers II Global Government Bond UCITS ETF 4C - CHF Hedged',
    symbol: 'X03H.SW',
  },
  {
    value: 'XDGU.DE',
    label:
      'XDGU.DE,  Xtrackers (IE) Plc - Xtrackers USD Corporate Bond UCITS ETF 1D',
    name: 'Xtrackers (IE) Plc - Xtrackers USD Corporate Bond UCITS ETF 1D',
    symbol: 'XDGU.DE',
  },
  {
    value: 'DBXG.DE',
    label: 'DBXG.DE,  Xtrackers II Eurozone Government Bond 25+ UCITS ETF',
    name: 'Xtrackers II Eurozone Government Bond 25+ UCITS ETF',
    symbol: 'DBXG.DE',
  },
  {
    value: 'XDGE.DE',
    label: 'XDGE.DE,  Xtrackers USD Corporate Bond UCITS ETF',
    name: 'Xtrackers USD Corporate Bond UCITS ETF',
    symbol: 'XDGE.DE',
  },
  {
    value: 'XGIG.L',
    label: 'XGIG.L,  Xtrackers II Global Inflation-Linked Bond UCITS ETF',
    name: 'Xtrackers II Global Inflation-Linked Bond UCITS ETF',
    symbol: 'XGIG.L',
  },
  {
    value: 'XG7G.SW',
    label: 'XG7G.SW,  Xtrackers II Global Inflation-Linked Bond UCITS ETF',
    name: 'Xtrackers II Global Inflation-Linked Bond UCITS ETF',
    symbol: 'XG7G.SW',
  },
  {
    value: 'X03G.F',
    label: 'X03G.F,  Xtrackers II Germany Government Bond UCITS ETF',
    name: 'Xtrackers II Germany Government Bond UCITS ETF',
    symbol: 'X03G.F',
  },
  {
    value: 'ISXF.L',
    label: 'ISXF.L,  iShares £ Corp Bond ex-Financials UCITS ETF GBP (Dist)',
    name: 'iShares £ Corp Bond ex-Financials UCITS ETF GBP (Dist)',
    symbol: 'ISXF.L',
  },
  {
    value: 'EMHG.L',
    label: 'EMHG.L,  iShares J.P. Morgan $ EM Bond UCITS ETF',
    name: 'iShares J.P. Morgan $ EM Bond UCITS ETF',
    symbol: 'EMHG.L',
  },
  {
    value: 'IUGA.L',
    label: 'IUGA.L,  iShares US Aggregate Bond UCITS ETF',
    name: 'iShares US Aggregate Bond UCITS ETF',
    symbol: 'IUGA.L',
  },
  {
    value: 'IHHG.L',
    label:
      'IHHG.L,  iShares $ High Yield Corp Bond UCITS ETF GBP Hedged (Dist)',
    name: 'iShares $ High Yield Corp Bond UCITS ETF GBP Hedged (Dist)',
    symbol: 'IHHG.L',
  },
  {
    value: 'IEAH.L',
    label: 'IEAH.L,  iShares Core € Corp Bond UCITS ETF',
    name: 'iShares Core € Corp Bond UCITS ETF',
    symbol: 'IEAH.L',
  },
  {
    value: 'WIGG.L',
    label:
      'WIGG.L,  iShares Fallen Angels High Yield Corporate Bond UCITS ETF GBP Hedged (Dist)',
    name: 'iShares Fallen Angels High Yield Corporate Bond UCITS ETF GBP Hedged (Dist)',
    symbol: 'WIGG.L',
  },
  {
    value: 'KV4.SI',
    label: 'KV4.SI,  Xtrackers II Singapore Government Bond UCITS ETF',
    name: 'Xtrackers II Singapore Government Bond UCITS ETF',
    symbol: 'KV4.SI',
  },
  {
    value: 'CLMD.MI',
    label: 'CLMD.MI,  Lyxor Green Bond (DR) UCITS ETF',
    name: 'Lyxor Green Bond (DR) UCITS ETF',
    symbol: 'CLMD.MI',
  },
  {
    value: 'COVR.DE',
    label: 'COVR.DE,  PIMCO ETFs plc PIMCO Covered Bond UCITS ETF',
    name: 'PIMCO ETFs plc PIMCO Covered Bond UCITS ETF',
    symbol: 'COVR.DE',
  },
  {
    value: 'LDCE.DE',
    label: 'LDCE.DE,  PIMCo Low Duration Euro Corporate Bond Source Ucits ETF',
    name: 'PIMCo Low Duration Euro Corporate Bond Source Ucits ETF',
    symbol: 'LDCE.DE',
  },
  {
    value: 'JR15.L',
    label:
      'JR15.L,  JPMorgan ETFs (Ireland) ICAV - EUR Corporate Bond 1-5 yr Research Enhanced Index (ESG) UCITS ETF',
    name: 'JPMorgan ETFs (Ireland) ICAV - EUR Corporate Bond 1-5 yr Research Enhanced Index (ESG) UCITS ETF',
    symbol: 'JR15.L',
  },
  {
    value: 'JREB.L',
    label:
      'JREB.L,  JPMorgan ETFs (Ireland) ICAV - EUR Corporate Bond Research Enhanced Index (ESG) UCITS ETF',
    name: 'JPMorgan ETFs (Ireland) ICAV - EUR Corporate Bond Research Enhanced Index (ESG) UCITS ETF',
    symbol: 'JREB.L',
  },
  {
    value: 'JRUB.L',
    label:
      'JRUB.L,  JPMorgan ETFs (Ireland) ICAV - USD Corporate Bond Research Enhanced Index (ESG) UCITS ETF',
    name: 'JPMorgan ETFs (Ireland) ICAV - USD Corporate Bond Research Enhanced Index (ESG) UCITS ETF',
    symbol: 'JRUB.L',
  },
  {
    value: 'FSMF.L',
    label:
      'FSMF.L,  Fidelity Sustainable Global Corporate Bond Paris-Aligned Multifactor UCITS ETF',
    name: 'Fidelity Sustainable Global Corporate Bond Paris-Aligned Multifactor UCITS ETF',
    symbol: 'FSMF.L',
  },
  {
    value: 'FESD.F',
    label: 'FESD.F,  Fidelity Sustainable USD EM Bond UCITS ETF',
    name: 'Fidelity Sustainable USD EM Bond UCITS ETF',
    symbol: 'FESD.F',
  },
  {
    value: 'DXSV.DE',
    label:
      'DXSV.DE,  Xtrackers II Eurozone Government Bond Short Daily Swap UCITS ETF',
    name: 'Xtrackers II Eurozone Government Bond Short Daily Swap UCITS ETF',
    symbol: 'DXSV.DE',
  },
  {
    value: 'SYBM.DE',
    label:
      'SYBM.DE,  SPDR Bloomberg Barclays Emerging Markets Local Bond UCITS ETF (Dist)',
    name: 'SPDR Bloomberg Barclays Emerging Markets Local Bond UCITS ETF (Dist)',
    symbol: 'SYBM.DE',
  },
  {
    value: 'SYBA.DE',
    label: 'SYBA.DE,  SPDR Bloomberg Euro Aggregate Bond UCITS ETF',
    name: 'SPDR Bloomberg Euro Aggregate Bond UCITS ETF',
    symbol: 'SYBA.DE',
  },
  {
    value: 'EUCO.PA',
    label: 'EUCO.PA,  SPDR Bloomberg Euro Corporate Bond UCITS ETF',
    name: 'SPDR Bloomberg Euro Corporate Bond UCITS ETF',
    symbol: 'EUCO.PA',
  },
  {
    value: 'SYBB.F',
    label: 'SYBB.F,  SPDR Bloomberg Euro Government Bond UCITS ETF Acc',
    name: 'SPDR Bloomberg Euro Government Bond UCITS ETF Acc',
    symbol: 'SYBB.F',
  },
  {
    value: 'USAG.L',
    label: 'USAG.L,  SPDR Bloomberg U.S. Aggregate Bond UCITS ETF',
    name: 'SPDR Bloomberg U.S. Aggregate Bond UCITS ETF',
    symbol: 'USAG.L',
  },
  {
    value: 'TRSY.L',
    label: 'TRSY.L,  SPDR Bloomberg U.S. Treasury Bond UCITS ETF',
    name: 'SPDR Bloomberg U.S. Treasury Bond UCITS ETF',
    symbol: 'TRSY.L',
  },
  {
    value: 'SYB3.DE',
    label: 'SYB3.DE,  SPDR Bloomberg 1-3 Year Euro Government Bond UCITS ETF',
    name: 'SPDR Bloomberg 1-3 Year Euro Government Bond UCITS ETF',
    symbol: 'SYB3.DE',
  },
  {
    value: 'SYBJ.DE',
    label: 'SYBJ.DE,  SPDR Bloomberg Euro High Yield Bond UCITS ETF',
    name: 'SPDR Bloomberg Euro High Yield Bond UCITS ETF',
    symbol: 'SYBJ.DE',
  },
  {
    value: 'UEFF.DE',
    label:
      'UEFF.DE,  UBS(Lux)Fund Solutions – Bloomberg Barclays US 1-3 Year Treasury Bond UCITS ETF(USD)A-dis',
    name: 'UBS(Lux)Fund Solutions – Bloomberg Barclays US 1-3 Year Treasury Bond UCITS ETF(USD)A-dis',
    symbol: 'UEFF.DE',
  },
  {
    value: 'UEFI.DE',
    label:
      'UEFI.DE,  UBS (Lux) Fund Solutions – Bloomberg US 7-10 Year Treasury Bond UCITS ETF',
    name: 'UBS (Lux) Fund Solutions – Bloomberg US 7-10 Year Treasury Bond UCITS ETF',
    symbol: 'UEFI.DE',
  },
  {
    value: 'UKCO.L',
    label: 'UKCO.L,  SPDR Bloomberg Sterling Corporate Bond UCITS ETF',
    name: 'SPDR Bloomberg Sterling Corporate Bond UCITS ETF',
    symbol: 'UKCO.L',
  },
  {
    value: 'SUKC.L',
    label: 'SUKC.L,  SPDR Bloomberg 0-5 Year Sterling Corporate Bond UCITS ETF',
    name: 'SPDR Bloomberg 0-5 Year Sterling Corporate Bond UCITS ETF',
    symbol: 'SUKC.L',
  },
  {
    value: 'GCVB.L',
    label: 'GCVB.L,  SPDR Refinitiv Global Convertible Bond UCITS ETF',
    name: 'SPDR Refinitiv Global Convertible Bond UCITS ETF',
    symbol: 'GCVB.L',
  },
  {
    value: 'SYB4.F',
    label: 'SYB4.F,  SPDR Bloomberg 3-5 Year Euro Government Bond UCITS ETF',
    name: 'SPDR Bloomberg 3-5 Year Euro Government Bond UCITS ETF',
    symbol: 'SYB4.F',
  },
  {
    value: 'SYBN.DE',
    label: 'SYBN.DE,  SPDR Bloomberg 10+ Year U.S. Corporate Bond UCITS ETF',
    name: 'SPDR Bloomberg 10+ Year U.S. Corporate Bond UCITS ETF',
    symbol: 'SYBN.DE',
  },
  {
    value: 'LUTR.L',
    label: 'LUTR.L,  SPDR Bloomberg 10+ Year U.S. Treasury Bond UCITS ETF',
    name: 'SPDR Bloomberg 10+ Year U.S. Treasury Bond UCITS ETF',
    symbol: 'LUTR.L',
  },
  {
    value: 'EUHA.F',
    label:
      'EUHA.F,  PIMCO Euro Short-Term High Yield Corporate Bond UCITS ETF EUR Accumulation',
    name: 'PIMCO Euro Short-Term High Yield Corporate Bond UCITS ETF EUR Accumulation',
    symbol: 'EUHA.F',
  },
  {
    value: 'EUHI.F',
    label:
      'EUHI.F,  PIMCO ETFs plc PIMCO Euro Short-Term High Yield Corporate Bond UCITS ETF',
    name: 'PIMCO ETFs plc PIMCO Euro Short-Term High Yield Corporate Bond UCITS ETF',
    symbol: 'EUHI.F',
  },
  {
    value: 'VECP.SW',
    label: 'VECP.SW,  Vanguard EUR Corporate Bond UCITS ETF',
    name: 'Vanguard EUR Corporate Bond UCITS ETF',
    symbol: 'VECP.SW',
  },
  {
    value: 'VETY.L',
    label: 'VETY.L,  Vanguard EUR Eurozone Government Bond UCITS ETF',
    name: 'Vanguard EUR Eurozone Government Bond UCITS ETF',
    symbol: 'VETY.L',
  },
  {
    value: 'VUCP.DE',
    label: 'VUCP.DE,  Vanguard USD Corporate Bond UCITS ETF',
    name: 'Vanguard USD Corporate Bond UCITS ETF',
    symbol: 'VUCP.DE',
  },
  {
    value: 'VEMT.L',
    label: 'VEMT.L,  Vanguard USD Emerging Markets Government Bond UCITS ETF',
    name: 'Vanguard USD Emerging Markets Government Bond UCITS ETF',
    symbol: 'VEMT.L',
  },
  {
    value: 'VUTY.MI',
    label: 'VUTY.MI,  Vanguard USD Treasury Bond UCITS ETF',
    name: 'Vanguard USD Treasury Bond UCITS ETF',
    symbol: 'VUTY.MI',
  },
  {
    value: 'SEAA.F',
    label:
      'SEAA.F,  UBS (Lux) Fund Solutions – J.P. Morgan USD EM Diversified Bond 1-5 UCITS ETF',
    name: 'UBS (Lux) Fund Solutions – J.P. Morgan USD EM Diversified Bond 1-5 UCITS ETF',
    symbol: 'SEAA.F',
  },
  {
    value: 'PSFE.DE',
    label: 'PSFE.DE,  Invesco Euro IG Corporate Bond ESG UCITS ETF',
    name: 'Invesco Euro IG Corporate Bond ESG UCITS ETF',
    symbol: 'PSFE.DE',
  },
  {
    value: 'SYBZ.DE',
    label:
      'SYBZ.DE,  SPDR Bloomberg Global Aggregate Bond USD Hdg UCITS ETF Acc',
    name: 'SPDR Bloomberg Global Aggregate Bond USD Hdg UCITS ETF Acc',
    symbol: 'SYBZ.DE',
  },
  {
    value: 'SPFE.DE',
    label:
      'SPFE.DE,  SPDR Bloomberg Barclays Global Aggregate Bond UCITS ETF EUR Hedged',
    name: 'SPDR Bloomberg Barclays Global Aggregate Bond UCITS ETF EUR Hedged',
    symbol: 'SPFE.DE',
  },
  {
    value: 'JE13.SW',
    label:
      'JE13.SW,  JPMorgan ETFs (Ireland) ICAV - BetaBuilders EUR Govt Bond 1-3 yr UCITS ETF',
    name: 'JPMorgan ETFs (Ireland) ICAV - BetaBuilders EUR Govt Bond 1-3 yr UCITS ETF',
    symbol: 'JE13.SW',
  },
  {
    value: 'HYGB.L',
    label: 'HYGB.L,  VanEck Emerging Markets High Yield Bond UCITS ETF',
    name: 'VanEck Emerging Markets High Yield Bond UCITS ETF',
    symbol: 'HYGB.L',
  },
  {
    value: 'GFA.L',
    label: 'GFA.L,  VanEck Global Fallen Angel High Yield Bond UCITS ETF',
    name: 'VanEck Global Fallen Angel High Yield Bond UCITS ETF',
    symbol: 'GFA.L',
  },
  {
    value: 'XAT1.SW',
    label: 'XAT1.SW,  Invesco AT1 Capital Bond ETF',
    name: 'Invesco AT1 Capital Bond ETF',
    symbol: 'XAT1.SW',
  },
  {
    value: 'EMDA.SW',
    label:
      'EMDA.SW,  SPDR Bloomberg Emerging Markets Local Bond USD Base CCY Hdg to EUR UCITS ETF Acc',
    name: 'SPDR Bloomberg Emerging Markets Local Bond USD Base CCY Hdg to EUR UCITS ETF Acc',
    symbol: 'EMDA.SW',
  },
  {
    value: 'COBO.MI',
    label: 'COBO.MI,  WisdomTree AT1 CoCo Bond UCITS ETF - EUR Hedged',
    name: 'WisdomTree AT1 CoCo Bond UCITS ETF - EUR Hedged',
    symbol: 'COBO.MI',
  },
  {
    value: 'UEFE.DE',
    label:
      'UEFE.DE,  UBS (Lux) Fund Solutions – J.P. Morgan EM Multi-Factor Enhanced Local Currency Bond UCITS ETF',
    name: 'UBS (Lux) Fund Solutions – J.P. Morgan EM Multi-Factor Enhanced Local Currency Bond UCITS ETF',
    symbol: 'UEFE.DE',
  },
  {
    value: 'MDBU.DE',
    label:
      'MDBU.DE,  UBS(Lux)Fund Solutions – Sustainable Development Bank Bonds UCITS ETF(USD)A-dis',
    name: 'UBS(Lux)Fund Solutions – Sustainable Development Bank Bonds UCITS ETF(USD)A-dis',
    symbol: 'MDBU.DE',
  },
  {
    value: 'ECBI.F',
    label:
      'ECBI.F,  SI UCITS ETF - UC MSCI European Green Bond EUR UCITS ETF Acc EUR',
    name: 'SI UCITS ETF - UC MSCI European Green Bond EUR UCITS ETF Acc EUR',
    symbol: 'ECBI.F',
  },
  {
    value: 'TRE3.L',
    label: 'TRE3.L,  Invesco US Treasury Bond 1-3 Year UCITS ETF',
    name: 'Invesco US Treasury Bond 1-3 Year UCITS ETF',
    symbol: 'TRE3.L',
  },
  {
    value: 'TRE7.L',
    label: 'TRE7.L,  Invesco US Treasury Bond 3-7 Year UCITS ETF',
    name: 'Invesco US Treasury Bond 3-7 Year UCITS ETF',
    symbol: 'TRE7.L',
  },
  {
    value: 'TREX.L',
    label: 'TREX.L,  Invesco US Treasury Bond 7-10 Year UCITS ETF',
    name: 'Invesco US Treasury Bond 7-10 Year UCITS ETF',
    symbol: 'TREX.L',
  },
  {
    value: 'TRES.L',
    label: 'TRES.L,  Invesco US Treasury Bond UCITS ETF USD Dist',
    name: 'Invesco US Treasury Bond UCITS ETF USD Dist',
    symbol: 'TRES.L',
  },
  {
    value: 'MDBE.SW',
    label:
      'MDBE.SW,  UBS (Lux) Fund Solutions – Sustainable Development Bank Bonds UCITS ETF',
    name: 'UBS (Lux) Fund Solutions – Sustainable Development Bank Bonds UCITS ETF',
    symbol: 'MDBE.SW',
  },
  {
    value: 'BBEG.DE',
    label:
      'BBEG.DE,  JPMorgan ETFs (Ireland) ICAV BetaBuilders EUR Govt Bond UCITS ETF',
    name: 'JPMorgan ETFs (Ireland) ICAV BetaBuilders EUR Govt Bond UCITS ETF',
    symbol: 'BBEG.DE',
  },
  {
    value: 'BBTR.DE',
    label:
      'BBTR.DE,  JPMorgan ETFs (Ireland) ICAV BetaBuilders US Treasury Bond UCITS ETF',
    name: 'JPMorgan ETFs (Ireland) ICAV BetaBuilders US Treasury Bond UCITS ETF',
    symbol: 'BBTR.DE',
  },
  {
    value: 'VAGF.F',
    label: 'VAGF.F,  Vanguard Global Aggregate Bond UCITS ETF',
    name: 'Vanguard Global Aggregate Bond UCITS ETF',
    symbol: 'VAGF.F',
  },
  {
    value: 'EMIE.F',
    label:
      'EMIE.F,  UBS (Lux) Fund Solutions – J.P. Morgan USD EM IG ESG Diversified Bond UCITS ETF',
    name: 'UBS (Lux) Fund Solutions – J.P. Morgan USD EM IG ESG Diversified Bond UCITS ETF',
    symbol: 'EMIE.F',
  },
  {
    value: 'EIB3.F',
    label: 'EIB3.F,  Invesco Euro Government Bond 1-3 Year UCITS ETF',
    name: 'Invesco Euro Government Bond 1-3 Year UCITS ETF',
    symbol: 'EIB3.F',
  },
  {
    value: 'EIB5.F',
    label: 'EIB5.F,  Invesco Euro Government Bond 3-5 Year UCITS ETF',
    name: 'Invesco Euro Government Bond 3-5 Year UCITS ETF',
    symbol: 'EIB5.F',
  },
  {
    value: 'EIB7.F',
    label: 'EIB7.F,  Invesco Euro Government Bond 5-7 Year UCITS ETF',
    name: 'Invesco Euro Government Bond 5-7 Year UCITS ETF',
    symbol: 'EIB7.F',
  },
  {
    value: 'EIBX.F',
    label: 'EIBX.F,  Invesco Euro Government Bond 7-10 Year UCITS ETF',
    name: 'Invesco Euro Government Bond 7-10 Year UCITS ETF',
    symbol: 'EIBX.F',
  },
  {
    value: 'EIBB.F',
    label: 'EIBB.F,  Invesco Euro Government Bond UCITS ETF',
    name: 'Invesco Euro Government Bond UCITS ETF',
    symbol: 'EIBB.F',
  },
  {
    value: 'CBND.L',
    label: 'CBND.L,  Goldman Sachs Access China Government Bond UCITS ETF',
    name: 'Goldman Sachs Access China Government Bond UCITS ETF',
    symbol: 'CBND.L',
  },
  {
    value: 'EMDE.MI',
    label:
      'EMDE.MI,  SPDR Bloomberg Emerging Markets Local Bond USD Base CCY Hdg to EUR UCITS ETF Acc',
    name: 'SPDR Bloomberg Emerging Markets Local Bond USD Base CCY Hdg to EUR UCITS ETF Acc',
    symbol: 'EMDE.MI',
  },
  {
    value: 'TREI.L',
    label: 'TREI.L,  Invesco US Treasury Bond 0-1 Year UCITS ETF USD Dist',
    name: 'Invesco US Treasury Bond 0-1 Year UCITS ETF USD Dist',
    symbol: 'TREI.L',
  },
  {
    value: 'T1EU.F',
    label: 'T1EU.F,  Invesco US Treasury Bond 0-1 Year UCITS ETF',
    name: 'Invesco US Treasury Bond 0-1 Year UCITS ETF',
    symbol: 'T1EU.F',
  },
  {
    value: 'HYBB.PA',
    label: 'HYBB.PA,  Lyxor iBoxx EUR Liquid High Yield BB UCITS ETF',
    name: 'Lyxor iBoxx EUR Liquid High Yield BB UCITS ETF',
    symbol: 'HYBB.PA',
  },
  {
    value: 'APX.PA',
    label:
      'APX.PA,  MULTI-UNITS LUXEMBOURG - Lyxor MSCI AC Asia Ex Japan UCITS ETF',
    name: 'MULTI-UNITS LUXEMBOURG - Lyxor MSCI AC Asia Ex Japan UCITS ETF',
    symbol: 'APX.PA',
  },
  {
    value: 'EXHC.DE',
    label:
      'EXHC.DE,  iShares eb.rexx Government Germany 2.5-5.5yr UCITS ETF (DE)',
    name: 'iShares eb.rexx Government Germany 2.5-5.5yr UCITS ETF (DE)',
    symbol: 'EXHC.DE',
  },
  {
    value: 'CU9.PA',
    label: 'CU9.PA,  Amundi ETF MSCI Europe ex EMU UCITS ETF',
    name: 'Amundi ETF MSCI Europe ex EMU UCITS ETF',
    symbol: 'CU9.PA',
  },
  {
    value: 'EXHD.DE',
    label:
      'EXHD.DE,  iShares eb.rexx Government Germany 5.5-10.5yr UCITS ETF (DE)',
    name: 'iShares eb.rexx Government Germany 5.5-10.5yr UCITS ETF (DE)',
    symbol: 'EXHD.DE',
  },
  {
    value: 'DXSQ.DE',
    label: 'DXSQ.DE,  Xtrackers II iTraxx Europe Swap UCITS ETF',
    name: 'Xtrackers II iTraxx Europe Swap UCITS ETF',
    symbol: 'DXSQ.DE',
  },
  {
    value: 'DBXM.DE',
    label: 'DBXM.DE,  Xtrackers II iTraxx Crossover Swap UCITS ETF',
    name: 'Xtrackers II iTraxx Crossover Swap UCITS ETF',
    symbol: 'DBXM.DE',
  },
  {
    value: 'EMBH.MI',
    label:
      'EMBH.MI,  Lyxor Index Fund - Lyxor iBoxx $ Liquid Emerging Markets Sovereign UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor iBoxx $ Liquid Emerging Markets Sovereign UCITS ETF',
    symbol: 'EMBH.MI',
  },
  {
    value: 'P60.SI',
    label:
      'P60.SI,  MULTI-UNITS LUXEMBOURG - Lyxor MSCI AC Asia Pacific Ex Japan UCITS ETF',
    name: 'MULTI-UNITS LUXEMBOURG - Lyxor MSCI AC Asia Pacific Ex Japan UCITS ETF',
    symbol: 'P60.SI',
  },
  {
    value: 'OSX4.DE',
    label: 'OSX4.DE,  Ossiam Europe ESG Machine Learning',
    name: 'Ossiam Europe ESG Machine Learning',
    symbol: 'OSX4.DE',
  },
  {
    value: '5HEE.DE',
    label:
      '5HEE.DE,  Ossiam ESG Low Carbon Shiller Barclays CAPE US Sector UCITS ETF 1A (EUR)',
    name: 'Ossiam ESG Low Carbon Shiller Barclays CAPE US Sector UCITS ETF 1A (EUR)',
    symbol: '5HEE.DE',
  },
  {
    value: '5HED.L',
    label:
      '5HED.L,  Ossiam ESG Low Carbon Shiller Barclays CAPE US Sector UCITS ETF',
    name: 'Ossiam ESG Low Carbon Shiller Barclays CAPE US Sector UCITS ETF',
    symbol: '5HED.L',
  },
  {
    value: 'JREE.SW',
    label:
      'JREE.SW,  JPMorgan ETFs (Ireland) ICAV - Europe Research Enhanced Index Equity (ESG) UCITS ETF',
    name: 'JPMorgan ETFs (Ireland) ICAV - Europe Research Enhanced Index Equity (ESG) UCITS ETF',
    symbol: 'JREE.SW',
  },
  {
    value: 'JREG.L',
    label:
      'JREG.L,  JPMorgan ETFs (Ireland) ICAV - Global Research Enhanced Index Equity UCITS ETF - USD (acc)',
    name: 'JPMorgan ETFs (Ireland) ICAV - Global Research Enhanced Index Equity UCITS ETF - USD (acc)',
    symbol: 'JREG.L',
  },
  {
    value: 'JREU.L',
    label:
      'JREU.L,  JPMorgan ETFs (Ireland) ICAV - US Research Enhanced Index Equity (ESG) UCITS ETF',
    name: 'JPMorgan ETFs (Ireland) ICAV - US Research Enhanced Index Equity (ESG) UCITS ETF',
    symbol: 'JREU.L',
  },
  {
    value: 'JREM.L',
    label:
      'JREM.L,  JPMorgan ETFs (Ireland) ICAV - Global Emerging Markets Research Enhanced Index Equity(ESG) UCITS ETF',
    name: 'JPMorgan ETFs (Ireland) ICAV - Global Emerging Markets Research Enhanced Index Equity(ESG) UCITS ETF',
    symbol: 'JREM.L',
  },
  {
    value: 'OWLE.DE',
    label: 'OWLE.DE,  OSSIAM World ESG Machine Learning UCITS ETF',
    name: 'OSSIAM World ESG Machine Learning UCITS ETF',
    symbol: 'OWLE.DE',
  },
  {
    value: 'OUFE.F',
    label: 'OUFE.F,  Ossiam US ESG Low Carbon Equity Factors UCITS ETF',
    name: 'Ossiam US ESG Low Carbon Equity Factors UCITS ETF',
    symbol: 'OUFE.F',
  },
  {
    value: 'FUSR.L',
    label:
      'FUSR.L,  Fidelity Sustainable Research Enhanced US Equity UCITS ETF',
    name: 'Fidelity Sustainable Research Enhanced US Equity UCITS ETF',
    symbol: 'FUSR.L',
  },
  {
    value: 'FGLR.L',
    label:
      'FGLR.L,  Fidelity Sustainable Research Enhanced Global Equity UCITS ETF',
    name: 'Fidelity Sustainable Research Enhanced Global Equity UCITS ETF',
    symbol: 'FGLR.L',
  },
  {
    value: 'AMAL.L',
    label: 'AMAL.L,  Saturna Al-Kawthar Global Focused Equity UCITS ETF',
    name: 'Saturna Al-Kawthar Global Focused Equity UCITS ETF',
    symbol: 'AMAL.L',
  },
  {
    value: 'EHBA.DE',
    label: 'EHBA.DE,  Invesco Euro Corporate Hybrid Bond UCITS ETF',
    name: 'Invesco Euro Corporate Hybrid Bond UCITS ETF',
    symbol: 'EHBA.DE',
  },
  {
    value: 'EMD5.MI',
    label:
      'EMD5.MI,  L&G ESG Emerging Markets Government Bond (USD) 0-5 Year UCITS ETF',
    name: 'L&G ESG Emerging Markets Government Bond (USD) 0-5 Year UCITS ETF',
    symbol: 'EMD5.MI',
  },
  {
    value: 'DRGN.MI',
    label: 'DRGN.MI,  L&G ESG China CNY Bond UCITS ETF',
    name: 'L&G ESG China CNY Bond UCITS ETF',
    symbol: 'DRGN.MI',
  },
  {
    value: 'TABC.F',
    label: 'TABC.F,  Tabula EUR IG Bond Paris-aligned Climate UCITS ETF (EUR)',
    name: 'Tabula EUR IG Bond Paris-aligned Climate UCITS ETF (EUR)',
    symbol: 'TABC.F',
  },
  {
    value: 'USAB.F',
    label: 'USAB.F,  L&G ESG USD Corporate Bond UCITS ETF',
    name: 'L&G ESG USD Corporate Bond UCITS ETF',
    symbol: 'USAB.F',
  },
  {
    value: 'GBND.MI',
    label: 'GBND.MI,  L&G ESG Green Bond UCITS ETF',
    name: 'L&G ESG Green Bond UCITS ETF',
    symbol: 'GBND.MI',
  },
  {
    value: 'W1TG.F',
    label: 'W1TG.F,  WisdomTree European Union Bond UCITS ETF',
    name: 'WisdomTree European Union Bond UCITS ETF',
    symbol: 'W1TG.F',
  },
  {
    value: 'GEMU.PA',
    label: 'GEMU.PA,  BNP Paribas Easy JPM ESG EMU Government Bond IG 3-5 Y',
    name: 'BNP Paribas Easy JPM ESG EMU Government Bond IG 3-5 Y',
    symbol: 'GEMU.PA',
  },
  {
    value: 'V3GF.F',
    label:
      'V3GF.F,  Vanguard Funds PLC - Vanguard ESG Global Corporate Bond UCITS ETF EUR Hedged Acc',
    name: 'Vanguard Funds PLC - Vanguard ESG Global Corporate Bond UCITS ETF EUR Hedged Acc',
    symbol: 'V3GF.F',
  },
  {
    value: 'V3GE.DE',
    label:
      'V3GE.DE,  Vanguard Funds PLC - Vanguard ESG Global Corporate Bond UCITS ETF',
    name: 'Vanguard Funds PLC - Vanguard ESG Global Corporate Bond UCITS ETF',
    symbol: 'V3GE.DE',
  },
  {
    value: 'CMTX.F',
    label: 'CMTX.F,  Lyxor Core Euro Government Bond',
    name: 'Lyxor Core Euro Government Bond',
    symbol: 'CMTX.F',
  },
  {
    value: 'UIFH.F',
    label:
      'UIFH.F,  Amundi Index Solutions - Amundi Index US Gov Inflation-Linked Bond',
    name: 'Amundi Index Solutions - Amundi Index US Gov Inflation-Linked Bond',
    symbol: 'UIFH.F',
  },
  {
    value: 'DRGE.F',
    label: 'DRGE.F,  L&G ESG China CNY Bond UCITS ETF',
    name: 'L&G ESG China CNY Bond UCITS ETF',
    symbol: 'DRGE.F',
  },
  {
    value: 'XUHE.DE',
    label: 'XUHE.DE,  Xtrackers USD High Yield Corporate Bond UCITS ETF',
    name: 'Xtrackers USD High Yield Corporate Bond UCITS ETF',
    symbol: 'XUHE.DE',
  },
  {
    value: 'HAGG.PA',
    label:
      'HAGG.PA,  HSBC Bloomberg Glabal Sustainable Aggregate 1-3 Year Bond UCITS ETF',
    name: 'HSBC Bloomberg Glabal Sustainable Aggregate 1-3 Year Bond UCITS ETF',
    symbol: 'HAGG.PA',
  },
  {
    value: 'SPF2.DE',
    label: 'SPF2.DE,  SPDR Refinitiv Global Convertible Bond UCITS ETF',
    name: 'SPDR Refinitiv Global Convertible Bond UCITS ETF',
    symbol: 'SPF2.DE',
  },
  {
    value: 'THEP.F',
    label: 'THEP.F,  Tabula EUR HY Bond Paris-Aligned Climate UCITS ETF (EUR)',
    name: 'Tabula EUR HY Bond Paris-Aligned Climate UCITS ETF (EUR)',
    symbol: 'THEP.F',
  },
  {
    value: 'T3RE.F',
    label: 'T3RE.F,  Invesco US Treasury Bond 1-3 Year UCITS ETF',
    name: 'Invesco US Treasury Bond 1-3 Year UCITS ETF',
    symbol: 'T3RE.F',
  },
  {
    value: 'T7EU.DE',
    label: 'T7EU.DE,  Invesco US Treasury Bond 3-7 Year UCITS ETF',
    name: 'Invesco US Treasury Bond 3-7 Year UCITS ETF',
    symbol: 'T7EU.DE',
  },
  {
    value: 'TRFE.DE',
    label: 'TRFE.DE,  Invesco US Treasury Bond UCITS ETF',
    name: 'Invesco US Treasury Bond UCITS ETF',
    symbol: 'TRFE.DE',
  },
  {
    value: 'PEMD.L',
    label: 'PEMD.L,  Invesco Emerging Markets USD Bond UCITS ETF',
    name: 'Invesco Emerging Markets USD Bond UCITS ETF',
    symbol: 'PEMD.L',
  },
  {
    value: 'EH1Y.F',
    label: 'EH1Y.F,  iShares Broad € High Yield Corp Bond UCITS ETF',
    name: 'iShares Broad € High Yield Corp Bond UCITS ETF',
    symbol: 'EH1Y.F',
  },
  {
    value: 'IE1A.DE',
    label: 'IE1A.DE,  iShares € Corp Bond 1-5yr UCITS ETF EUR (Dist)',
    name: 'iShares € Corp Bond 1-5yr UCITS ETF EUR (Dist)',
    symbol: 'IE1A.DE',
  },
  {
    value: 'ERNX.DE',
    label: 'ERNX.DE,  iShares € Ultrashort Bond UCITS ETF',
    name: 'iShares € Ultrashort Bond UCITS ETF',
    symbol: 'ERNX.DE',
  },
  {
    value: 'ENDH.F',
    label:
      'ENDH.F,  L&G ESG Emerging Markets Government Bond (USD) 0-5 Year UCITS ETF',
    name: 'L&G ESG Emerging Markets Government Bond (USD) 0-5 Year UCITS ETF',
    symbol: 'ENDH.F',
  },
  {
    value: 'IEXA.DE',
    label: 'IEXA.DE,  iShares € Corp Bond ex-Financials UCITS ETF',
    name: 'iShares € Corp Bond ex-Financials UCITS ETF',
    symbol: 'IEXA.DE',
  },
  {
    value: 'IE3E.F',
    label: 'IE3E.F,  iShares € Corp Bond 0-3yr ESG UCITS ETF',
    name: 'iShares € Corp Bond 0-3yr ESG UCITS ETF',
    symbol: 'IE3E.F',
  },
  {
    value: 'IPAB.F',
    label: 'IPAB.F,  iShares € Corp Bond ESG Paris-Aligned Climate UCITS ETF',
    name: 'iShares € Corp Bond ESG Paris-Aligned Climate UCITS ETF',
    symbol: 'IPAB.F',
  },
  {
    value: 'DXSZ.DE',
    label: 'DXSZ.DE,  Xtrackers II USD Overnight Rate Swap UCITS ETF',
    name: 'Xtrackers II USD Overnight Rate Swap UCITS ETF',
    symbol: 'DXSZ.DE',
  },
  {
    value: 'DX22.DE',
    label: 'DX22.DE,  Xtrackers II EUR Overnight Rate Swap UCITS ETF',
    name: 'Xtrackers II EUR Overnight Rate Swap UCITS ETF',
    symbol: 'DX22.DE',
  },
  {
    value: 'U10H.MI',
    label:
      'U10H.MI,  Lyxor US Treasury 10+Y (DR) UCITS ETF - Monthly Hedged to EUR - Dist',
    name: 'Lyxor US Treasury 10+Y (DR) UCITS ETF - Monthly Hedged to EUR - Dist',
    symbol: 'U10H.MI',
  },
  {
    value: 'BUOY.L',
    label: 'BUOY.L,  Lyxor Index Fund - Lyxor $ Floating Rate Note UCITS ETF',
    name: 'Lyxor Index Fund - Lyxor $ Floating Rate Note UCITS ETF',
    symbol: 'BUOY.L',
  },
  {
    value: 'US7.SW',
    label: 'US7.SW,  Amundi Index Solutions - Amundi US Treasury 7-10',
    name: 'Amundi Index Solutions - Amundi US Treasury 7-10',
    symbol: 'US7.SW',
  },
  {
    value: 'AFLT.PA',
    label:
      'AFLT.PA,  Amundi Index Solutions - Amundi Floating Rate USD Corporate ESG',
    name: 'Amundi Index Solutions - Amundi Floating Rate USD Corporate ESG',
    symbol: 'AFLT.PA',
  },
  {
    value: 'DBXT.DE',
    label: 'DBXT.DE,  Xtrackers II EUR Overnight Rate Swap UCITS ETF',
    name: 'Xtrackers II EUR Overnight Rate Swap UCITS ETF',
    symbol: 'DBXT.DE',
  },
  {
    value: 'TIPE.MI',
    label: 'TIPE.MI,  Lyxor Core US TIPS (DR) UCITS ETF',
    name: 'Lyxor Core US TIPS (DR) UCITS ETF',
    symbol: 'TIPE.MI',
  },
  {
    value: 'US3.SW',
    label: 'US3.SW,  Amundi Index Solutions - Amundi US Treasury 3-7',
    name: 'Amundi Index Solutions - Amundi US Treasury 3-7',
    symbol: 'US3.SW',
  },
  {
    value: 'AFLE.PA',
    label:
      'AFLE.PA,  Amundi Index Solutions - Amundi Floating Rate USD Corporate ESG',
    name: 'Amundi Index Solutions - Amundi Floating Rate USD Corporate ESG',
    symbol: 'AFLE.PA',
  },
  {
    value: 'ITPG.L',
    label: 'ITPG.L,  iShares $ TIPS UCITS ETF',
    name: 'iShares $ TIPS UCITS ETF',
    symbol: 'ITPG.L',
  },
  {
    value: 'U13G.L',
    label: 'U13G.L,  Lyxor US Treasury 1-3Y (DR) UCITS ETF',
    name: 'Lyxor US Treasury 1-3Y (DR) UCITS ETF',
    symbol: 'U13G.L',
  },
  {
    value: 'AFRN.PA',
    label:
      'AFRN.PA,  Amundi Index Solutions - Amundi Floating Rate EURO Corporate ESG',
    name: 'Amundi Index Solutions - Amundi Floating Rate EURO Corporate ESG',
    symbol: 'AFRN.PA',
  },
  {
    value: 'PRAS.F',
    label: 'PRAS.F,  Amundi Index Solutions - Amundi Prime US Treasury',
    name: 'Amundi Index Solutions - Amundi Prime US Treasury',
    symbol: 'PRAS.F',
  },
  {
    value: 'LQGH.L',
    label: 'LQGH.L,  iShares $ Corp Bond UCITS ETF',
    name: 'iShares $ Corp Bond UCITS ETF',
    symbol: 'LQGH.L',
  },
  {
    value: 'CRHG.L',
    label: 'CRHG.L,  iShares Global Corp Bond UCITS ETF GBP Hedged (Dist)',
    name: 'iShares Global Corp Bond UCITS ETF GBP Hedged (Dist)',
    symbol: 'CRHG.L',
  },
  {
    value: 'IGLH.L',
    label: 'IGLH.L,  iShares Global Govt Bond UCITS ETF',
    name: 'iShares Global Govt Bond UCITS ETF',
    symbol: 'IGLH.L',
  },
  {
    value: 'IGTM.L',
    label: 'IGTM.L,  iShares $ Treasury Bond 7-10yr UCITS ETF USD (Dist)',
    name: 'iShares $ Treasury Bond 7-10yr UCITS ETF USD (Dist)',
    symbol: 'IGTM.L',
  },
  {
    value: 'CBUG.AS',
    label:
      'CBUG.AS,  iShares VII PLC - iShares VII PLC - iShares $ Treasury Bond 3-7yr UCITS ETF',
    name: 'iShares VII PLC - iShares VII PLC - iShares $ Treasury Bond 3-7yr UCITS ETF',
    symbol: 'CBUG.AS',
  },
  {
    value: 'GHYG.L',
    label: 'GHYG.L,  iShares Global High Yield Corp Bond UCITS ETF',
    name: 'iShares Global High Yield Corp Bond UCITS ETF',
    symbol: 'GHYG.L',
  },
  {
    value: 'V20A.F',
    label: 'V20A.F,  Vanguard LifeStrategy 20% Equity UCITS ETF',
    name: 'Vanguard LifeStrategy 20% Equity UCITS ETF',
    symbol: 'V20A.F',
  },
  {
    value: 'V60A.F',
    label:
      'V60A.F,  Vanguard LifeStrategy 60% Equity UCITS ETF (EUR) Accumulating',
    name: 'Vanguard LifeStrategy 60% Equity UCITS ETF (EUR) Accumulating',
    symbol: 'V60A.F',
  },
  {
    value: 'FJPS.L',
    label:
      'FJPS.L,  Fidelity Sustainable Research Enhanced Japan Equity UCITS ETF',
    name: 'Fidelity Sustainable Research Enhanced Japan Equity UCITS ETF',
    symbol: 'FJPS.L',
  },
  {
    value: 'FPXS.L',
    label:
      'FPXS.L,  Fidelity Sustainable Research Enhanced Pacific ex-Japan Equity UCITS ETF',
    name: 'Fidelity Sustainable Research Enhanced Pacific ex-Japan Equity UCITS ETF',
    symbol: 'FPXS.L',
  },
  {
    value: 'F4DE.DE',
    label: 'F4DE.DE,  Ossiam Food for Biodiversity UCITS ETF',
    name: 'Ossiam Food for Biodiversity UCITS ETF',
    symbol: 'F4DE.DE',
  },
  {
    value: '5HEU.F',
    label: '5HEU.F,  Ossiam ESG Shiller Barclays CAPE Europe Sector UCITS ETF',
    name: 'Ossiam ESG Shiller Barclays CAPE Europe Sector UCITS ETF',
    symbol: '5HEU.F',
  },
  {
    value: 'CESG.L',
    label:
      'CESG.L,  FIRST TRUST GLOBAL FUNDS PLC - First Trust Global Capital Strength ESG Leaders UCITS ETF',
    name: 'FIRST TRUST GLOBAL FUNDS PLC - First Trust Global Capital Strength ESG Leaders UCITS ETF',
    symbol: 'CESG.L',
  },
  {
    value: 'JREI.L',
    label:
      'JREI.L,  JPMorgan ETFs (Ireland) ICAV - Japan Research Enhanced Index Equity (ESG) UCITS ETF',
    name: 'JPMorgan ETFs (Ireland) ICAV - Japan Research Enhanced Index Equity (ESG) UCITS ETF',
    symbol: 'JREI.L',
  },
  {
    value: 'JREZ.DE',
    label:
      'JREZ.DE,  JPM Eurozone Research Enhanced Index Equity (ESG) UCITS ETF',
    name: 'JPM Eurozone Research Enhanced Index Equity (ESG) UCITS ETF',
    symbol: 'JREZ.DE',
  },
  {
    value: 'UL3S.MI',
    label: 'UL3S.MI,  WisdomTree US Treasuries 30Y 3x Daily Short',
    name: 'WisdomTree US Treasuries 30Y 3x Daily Short',
    symbol: 'UL3S.MI',
  },
  {
    value: 'SEUR.L',
    label: 'SEUR.L,  WisdomTree Short EUR Long USD',
    name: 'WisdomTree Short EUR Long USD',
    symbol: 'SEUR.L',
  },
  {
    value: 'EUS5.MI',
    label: 'EUS5.MI,  WisdomTree Long USD Short EUR 5x Daily',
    name: 'WisdomTree Long USD Short EUR 5x Daily',
    symbol: 'EUS5.MI',
  },
  {
    value: '3TYS.MI',
    label: '3TYS.MI,  WisdomTree US Treasuries 10Y 3x Daily Short',
    name: 'WisdomTree US Treasuries 10Y 3x Daily Short',
    symbol: '3TYS.MI',
  },
  {
    value: '5BTS.MI',
    label: '5BTS.MI,  WisdomTree BTP 10Y 5x Daily Short',
    name: 'WisdomTree BTP 10Y 5x Daily Short',
    symbol: '5BTS.MI',
  },
  {
    value: '3EMS.MI',
    label: '3EMS.MI,  WisdomTree Emerging Markets 3x Daily Short',
    name: 'WisdomTree Emerging Markets 3x Daily Short',
    symbol: '3EMS.MI',
  },
  {
    value: 'CHEU.MI',
    label: 'CHEU.MI,  WisdomTree Short CHF Long EUR',
    name: 'WisdomTree Short CHF Long EUR',
    symbol: 'CHEU.MI',
  },
  {
    value: 'JPE3.MI',
    label: 'JPE3.MI,  WisdomTree Short JPY Long EUR 3x Daily',
    name: 'WisdomTree Short JPY Long EUR 3x Daily',
    symbol: 'JPE3.MI',
  },
  {
    value: 'LJPY.L',
    label: 'LJPY.L,  WisdomTree Long JPY Short USD',
    name: 'WisdomTree Long JPY Short USD',
    symbol: 'LJPY.L',
  },
  {
    value: 'USE5.MI',
    label: 'USE5.MI,  WisdomTree Short USD Long EUR 5x Daily',
    name: 'WisdomTree Short USD Long EUR 5x Daily',
    symbol: 'USE5.MI',
  },
  {
    value: 'C2U.PA',
    label: 'C2U.PA,  Amundi ETF Short MSCI USA Daily UCITS ETF',
    name: 'Amundi ETF Short MSCI USA Daily UCITS ETF',
    symbol: 'C2U.PA',
  },
  {
    value: '5BUS.MI',
    label: '5BUS.MI,  WisdomTree Bund 10Y 5x Daily Short',
    name: 'WisdomTree Bund 10Y 5x Daily Short',
    symbol: '5BUS.MI',
  },
  {
    value: '3BTS.MI',
    label: '3BTS.MI,  WisdomTree BTP 10Y 3x Daily Short',
    name: 'WisdomTree BTP 10Y 3x Daily Short',
    symbol: '3BTS.MI',
  },
  {
    value: '5TYS.MI',
    label: '5TYS.MI,  WisdomTree US Treasuries 10Y 5x Daily Short',
    name: 'WisdomTree US Treasuries 10Y 5x Daily Short',
    symbol: '5TYS.MI',
  },
  {
    value: 'EUGB.MI',
    label: 'EUGB.MI,  WisdomTree Long GBP Short EUR',
    name: 'WisdomTree Long GBP Short EUR',
    symbol: 'EUGB.MI',
  },
  {
    value: 'EJP3.MI',
    label: 'EJP3.MI,  WisdomTree Long JPY Short EUR 3x Daily',
    name: 'WisdomTree Long JPY Short EUR 3x Daily',
    symbol: 'EJP3.MI',
  },
  {
    value: 'SUP5.L',
    label: 'SUP5.L,  WisdomTree Short EUR Long GBP 5x Daily',
    name: 'WisdomTree Short EUR Long GBP 5x Daily',
    symbol: 'SUP5.L',
  },
  {
    value: 'EUSE.MI',
    label: 'EUSE.MI,  WisdomTree Long SEK Short EUR',
    name: 'WisdomTree Long SEK Short EUR',
    symbol: 'EUSE.MI',
  },
  {
    value: 'EUCH.MI',
    label: 'EUCH.MI,  WisdomTree Long CHF Short EUR',
    name: 'WisdomTree Long CHF Short EUR',
    symbol: 'EUCH.MI',
  },
  {
    value: 'SCNY.L',
    label: 'SCNY.L,  WisdomTree Short CNY Long USD',
    name: 'WisdomTree Short CNY Long USD',
    symbol: 'SCNY.L',
  },
  {
    value: 'PUS5.L',
    label: 'PUS5.L,  WisdomTree Short USD Long GBP 5x Daily',
    name: 'WisdomTree Short USD Long GBP 5x Daily',
    symbol: 'PUS5.L',
  },
  {
    value: 'EUNO.MI',
    label: 'EUNO.MI,  WisdomTree Long NOK Short EUR',
    name: 'WisdomTree Long NOK Short EUR',
    symbol: 'EUNO.MI',
  },
  {
    value: 'GBEU.MI',
    label: 'GBEU.MI,  WisdomTree Short GBP Long EUR',
    name: 'WisdomTree Short GBP Long EUR',
    symbol: 'GBEU.MI',
  },
  {
    value: 'EUAU.MI',
    label: 'EUAU.MI,  WisdomTree Long AUD Short EUR',
    name: 'WisdomTree Long AUD Short EUR',
    symbol: 'EUAU.MI',
  },
  {
    value: 'ECH3.MI',
    label: 'ECH3.MI,  WisdomTree Long CHF Short EUR 3x Daily',
    name: 'WisdomTree Long CHF Short EUR 3x Daily',
    symbol: 'ECH3.MI',
  },
  {
    value: '3UBS.MI',
    label: '3UBS.MI,  WisdomTree Bund 30Y 3x Daily Short',
    name: 'WisdomTree Bund 30Y 3x Daily Short',
    symbol: '3UBS.MI',
  },
  {
    value: 'GBJP.L',
    label: 'GBJP.L,  WisdomTree Long JPY Short GBP',
    name: 'WisdomTree Long JPY Short GBP',
    symbol: 'GBJP.L',
  },
  {
    value: 'DSD.PA',
    label: 'DSD.PA,  Lyxor Daily ShortDAX x2 UCITS ETF',
    name: 'Lyxor Daily ShortDAX x2 UCITS ETF',
    symbol: 'DSD.PA',
  },
  {
    value: 'PJS1.DE',
    label: 'PJS1.DE,  PIMCO ETFs plc PIMCO Euro Short Maturity UCITS ETF',
    name: 'PIMCO ETFs plc PIMCO Euro Short Maturity UCITS ETF',
    symbol: 'PJS1.DE',
  },
  {
    value: 'PJSR.F',
    label: 'PJSR.F,  PIMCO ETFs plc PIMCO Euro Short Maturity UCITS ETF',
    name: 'PIMCO ETFs plc PIMCO Euro Short Maturity UCITS ETF',
    symbol: 'PJSR.F',
  },
  {
    value: 'JEST.MI',
    label:
      'JEST.MI,  JPMorgan ETFs (Ireland) ICAV - EUR Ultra-Short Income UCITS ETF',
    name: 'JPMorgan ETFs (Ireland) ICAV - EUR Ultra-Short Income UCITS ETF',
    symbol: 'JEST.MI',
  },
  {
    value: 'FLES.SW',
    label: 'FLES.SW,  Franklin Liberty Euro Short Maturity UCITS ETF',
    name: 'Franklin Liberty Euro Short Maturity UCITS ETF',
    symbol: 'FLES.SW',
  },
  {
    value: 'M9SD.DE',
    label: 'M9SD.DE,  Market Access NYSE Arca Gold BUGS Index UCITS ETF',
    name: 'Market Access NYSE Arca Gold BUGS Index UCITS ETF',
    symbol: 'M9SD.DE',
  },
  {
    value: 'XSDX.L',
    label: 'XSDX.L,  Xtrackers ShortDAX Daily Swap UCITS ETF',
    name: 'Xtrackers ShortDAX Daily Swap UCITS ETF',
    symbol: 'XSDX.L',
  },
  {
    value: 'DXST.DE',
    label: 'DXST.DE,  Xtrackers II iTraxx Crossover Short Daily Swap UCITS ETF',
    name: 'Xtrackers II iTraxx Crossover Short Daily Swap UCITS ETF',
    symbol: 'DXST.DE',
  },
  {
    value: 'XUKS.L',
    label: 'XUKS.L,  Xtrackers FTSE 100 Short Daily Swap UCITS ETF',
    name: 'Xtrackers FTSE 100 Short Daily Swap UCITS ETF',
    symbol: 'XUKS.L',
  },
  {
    value: 'EL4V.DE',
    label: 'EL4V.DE,  Deka Deutsche Börse EUROGOV Germany 10+ UCITS ETF',
    name: 'Deka Deutsche Börse EUROGOV Germany 10+ UCITS ETF',
    symbol: 'EL4V.DE',
  },
  {
    value: 'TP05.L',
    label: 'TP05.L,  iShares $ TIPS 0-5 UCITS ETF USD (Dist)',
    name: 'iShares $ TIPS 0-5 UCITS ETF USD (Dist)',
    symbol: 'TP05.L',
  },
  {
    value: 'U13H.MI',
    label: 'U13H.MI,  Lyxor US Treasury 1-3Y (DR) UCITS ETF',
    name: 'Lyxor US Treasury 1-3Y (DR) UCITS ETF',
    symbol: 'U13H.MI',
  },
  {
    value: 'IUST.DE',
    label: 'IUST.DE,  iShares $ TIPS UCITS ETF USD (Acc)',
    name: 'iShares $ TIPS UCITS ETF USD (Acc)',
    symbol: 'IUST.DE',
  },
  {
    value: 'XUT3.L',
    label: 'XUT3.L,  Xtrackers II US Treasuries 1-3 UCITS ETF',
    name: 'Xtrackers II US Treasuries 1-3 UCITS ETF',
    symbol: 'XUT3.L',
  },
  {
    value: 'XUTD.L',
    label: 'XUTD.L,  Xtrackers II US Treasuries UCITS ETF 1D',
    name: 'Xtrackers II US Treasuries UCITS ETF 1D',
    symbol: 'XUTD.L',
  },
  {
    value: 'US37.PA',
    label: 'US37.PA,  Lyxor US Treasury 3-7Y (DR) UCITS ETF',
    name: 'Lyxor US Treasury 3-7Y (DR) UCITS ETF',
    symbol: 'US37.PA',
  },
  {
    value: 'TI5G.L',
    label: 'TI5G.L,  iShares $ TIPS 0-5 UCITS ETF',
    name: 'iShares $ TIPS 0-5 UCITS ETF',
    symbol: 'TI5G.L',
  },
  {
    value: 'EQQQ.MI',
    label: 'EQQQ.MI,  Invesco EQQQ NASDAQ-100 UCITS ETF',
    name: 'Invesco EQQQ NASDAQ-100 UCITS ETF',
    symbol: 'EQQQ.MI',
  },
  {
    value: 'PEU.PA',
    label: 'PEU.PA,  Invesco EuroMTS Cash 3 Months UCITS ETF',
    name: 'Invesco EuroMTS Cash 3 Months UCITS ETF',
    symbol: 'PEU.PA',
  },
  {
    value: 'SC0K.DE',
    label: 'SC0K.DE,  Invesco Russell 2000 UCITS ETF',
    name: 'Invesco Russell 2000 UCITS ETF',
    symbol: 'SC0K.DE',
  },
  {
    value: 'SYB5.DE',
    label: 'SYB5.DE,  SPDR Bloomberg 1-5 Year Gilt UCITS ETF',
    name: 'SPDR Bloomberg 1-5 Year Gilt UCITS ETF',
    symbol: 'SYB5.DE',
  },
  {
    value: 'SYBL.DE',
    label: 'SYBL.DE,  SPDR Bloomberg 15+ Year Gilt UCITS ETF',
    name: 'SPDR Bloomberg 15+ Year Gilt UCITS ETF',
    symbol: 'SYBL.DE',
  },
  {
    value: 'GLTY.L',
    label: 'GLTY.L,  SPDR Bloomberg UK Gilt UCITS ETF',
    name: 'SPDR Bloomberg UK Gilt UCITS ETF',
    symbol: 'GLTY.L',
  },
  {
    value: 'SYBK.DE',
    label:
      'SYBK.DE,  SPDR Bloomberg SASB U.S. High Yield Corporate ESG UCITS ETF',
    name: 'SPDR Bloomberg SASB U.S. High Yield Corporate ESG UCITS ETF',
    symbol: 'SYBK.DE',
  },
  {
    value: 'BUYB.L',
    label: 'BUYB.L,  Invesco Global Buyback Achievers UCITS ETF',
    name: 'Invesco Global Buyback Achievers UCITS ETF',
    symbol: 'BUYB.L',
  },
  {
    value: 'ZPRI.DE',
    label:
      'ZPRI.DE,  SPDR Morningstar Multi-Asset Global Infrastructure UCITS ETF',
    name: 'SPDR Morningstar Multi-Asset Global Infrastructure UCITS ETF',
    symbol: 'ZPRI.DE',
  },
  {
    value: 'SYBY.DE',
    label: 'SYBY.DE,  SPDR Bloomberg U.S. TIPS UCITS ETF',
    name: 'SPDR Bloomberg U.S. TIPS UCITS ETF',
    symbol: 'SYBY.DE',
  },
  {
    value: 'EQEU.DE',
    label: 'EQEU.DE,  Invesco EQQQ NASDAQ-100 UCITS ETF (EUR Hdg)',
    name: 'Invesco EQQQ NASDAQ-100 UCITS ETF (EUR Hdg)',
    symbol: 'EQEU.DE',
  },
  {
    value: 'PDSE.F',
    label: 'PDSE.F,  Invesco Preferred Shares UCITS ETF',
    name: 'Invesco Preferred Shares UCITS ETF',
    symbol: 'PDSE.F',
  },
  {
    value: 'M9SV.DE',
    label:
      'M9SV.DE,  Market Access Stoxx China A Minimum Variance Index UCITS ETF',
    name: 'Market Access Stoxx China A Minimum Variance Index UCITS ETF',
    symbol: 'M9SV.DE',
  },
  {
    value: 'VGER.F',
    label: 'VGER.F,  Vanguard Germany All Cap UCITS ETF',
    name: 'Vanguard Germany All Cap UCITS ETF',
    symbol: 'VGER.F',
  },
  {
    value: 'FLEQ.SW',
    label: 'FLEQ.SW,  Franklin LibertyQ European Equity UCITS ETF',
    name: 'Franklin LibertyQ European Equity UCITS ETF',
    symbol: 'FLEQ.SW',
  },
  {
    value: 'EMQQ.L',
    label: 'EMQQ.L,  EMQQ Emerging Markets Internet & Ecommerce UCITS ETF',
    name: 'EMQQ Emerging Markets Internet & Ecommerce UCITS ETF',
    symbol: 'EMQQ.L',
  },
  {
    value: 'ITEK.L',
    label: 'ITEK.L,  HAN-GINS Tech Megatrend Equal Weight UCITS ETF',
    name: 'HAN-GINS Tech Megatrend Equal Weight UCITS ETF',
    symbol: 'ITEK.L',
  },
  {
    value: 'TEET.AS',
    label: 'TEET.AS,  VanEck Sustainable European Equal Weight UCITS ETF',
    name: 'VanEck Sustainable European Equal Weight UCITS ETF',
    symbol: 'TEET.AS',
  },
  {
    value: 'TRET.AS',
    label: 'TRET.AS,  VanEck Global Real Estate UCITS ETF',
    name: 'VanEck Global Real Estate UCITS ETF',
    symbol: 'TRET.AS',
  },
  {
    value: 'TSWE.AS',
    label: 'TSWE.AS,  VanEck Sustainable World Equal Weight UCITS ETF',
    name: 'VanEck Sustainable World Equal Weight UCITS ETF',
    symbol: 'TSWE.AS',
  },
  {
    value: 'LGAG.L',
    label: 'LGAG.L,  L&G Asia Pacific ex Japan Equity UCITS ETF',
    name: 'L&G Asia Pacific ex Japan Equity UCITS ETF',
    symbol: 'LGAG.L',
  },
  {
    value: 'LGJP.L',
    label: 'LGJP.L,  L&G Japan Equity UCITS ETF',
    name: 'L&G Japan Equity UCITS ETF',
    symbol: 'LGJP.L',
  },
  {
    value: 'FEUZ.L',
    label: 'FEUZ.L,  First Trust Eurozone AlphaDEX UCITS ETF',
    name: 'First Trust Eurozone AlphaDEX UCITS ETF',
    symbol: 'FEUZ.L',
  },
  {
    value: 'N400.MI',
    label: 'N400.MI,  Invesco JPX-Nikkei 400 UCITS ETF',
    name: 'Invesco JPX-Nikkei 400 UCITS ETF',
    symbol: 'N400.MI',
  },
  {
    value: 'MLPS.L',
    label:
      'MLPS.L,  Invesco Morningstar US Energy Infrastructure MLP UCITS ETF',
    name: 'Invesco Morningstar US Energy Infrastructure MLP UCITS ETF',
    symbol: 'MLPS.L',
  },
  {
    value: 'EFIW.L',
    label: 'EFIW.L,  Invesco Goldman Sachs Equity Factor Index World UCITS ETF',
    name: 'Invesco Goldman Sachs Equity Factor Index World UCITS ETF',
    symbol: 'EFIW.L',
  },
  {
    value: 'ESPO.L',
    label: 'ESPO.L,  VanEck Video Gaming and eSports UCITS ETF',
    name: 'VanEck Video Gaming and eSports UCITS ETF',
    symbol: 'ESPO.L',
  },
  {
    value: 'JPLG.L',
    label: 'JPLG.L,  JPM Global Equity Multi-Factor UCITS ETF',
    name: 'JPM Global Equity Multi-Factor UCITS ETF',
    symbol: 'JPLG.L',
  },
  {
    value: 'JPUS.L',
    label: 'JPUS.L,  JPM US Equity Multi-Factor UCITS ETF',
    name: 'JPM US Equity Multi-Factor UCITS ETF',
    symbol: 'JPUS.L',
  },
  {
    value: 'ELFG.F',
    label: 'ELFG.F,  Deka Germany 30 UCITS ETF',
    name: 'Deka Germany 30 UCITS ETF',
    symbol: 'ELFG.F',
  },
  {
    value: 'GLGG.L',
    label: 'GLGG.L,  L&G Clean Water UCITS ETF',
    name: 'L&G Clean Water UCITS ETF',
    symbol: 'GLGG.L',
  },
  {
    value: 'DOCG.L',
    label: 'DOCG.L,  L&G Healthcare Breakthrough UCITS ETF',
    name: 'L&G Healthcare Breakthrough UCITS ETF',
    symbol: 'DOCG.L',
  },
  {
    value: 'FTEK.L',
    label: 'FTEK.L,  Invesco KBW NASDAQ Fintech UCITS ETF',
    name: 'Invesco KBW NASDAQ Fintech UCITS ETF',
    symbol: 'FTEK.L',
  },
  {
    value: 'SBIO.L',
    label: 'SBIO.L,  Invesco NASDAQ Biotech UCITS ETF',
    name: 'Invesco NASDAQ Biotech UCITS ETF',
    symbol: 'SBIO.L',
  },
  {
    value: 'CBDX.L',
    label: 'CBDX.L,  The Medical Cannabis and Wellness UCITS ETF',
    name: 'The Medical Cannabis and Wellness UCITS ETF',
    symbol: 'CBDX.L',
  },
  {
    value: 'RIEU.L',
    label: 'RIEU.L,  L&G Europe Equity (Responsible Exclusions) UCITS ETF',
    name: 'L&G Europe Equity (Responsible Exclusions) UCITS ETF',
    symbol: 'RIEU.L',
  },
  {
    value: 'RIUS.L',
    label: 'RIUS.L,  L&G US Equity (Responsible Exclusions) UCITS ETF',
    name: 'L&G US Equity (Responsible Exclusions) UCITS ETF',
    symbol: 'RIUS.L',
  },
  {
    value: 'BLUM.DE',
    label:
      'BLUM.DE,  Rize UCITS ICAV - Rize Medical Cannabis and Life Sciences UCITS ETF',
    name: 'Rize UCITS ICAV - Rize Medical Cannabis and Life Sciences UCITS ETF',
    symbol: 'BLUM.DE',
  },
  {
    value: 'SPMV.PA',
    label: 'SPMV.PA,  Ossiam US Minimum Variance ESG NR UCITS ETF',
    name: 'Ossiam US Minimum Variance ESG NR UCITS ETF',
    symbol: 'SPMV.PA',
  },
  {
    value: 'VVGM.F',
    label: 'VVGM.F,  VanEck Morningstar Global Wide Moat UCITS ETF',
    name: 'VanEck Morningstar Global Wide Moat UCITS ETF',
    symbol: 'VVGM.F',
  },
  {
    value: 'HSEU.MI',
    label: 'HSEU.MI,  HSBC Europe Sustainable Equity UCITS ETF',
    name: 'HSBC Europe Sustainable Equity UCITS ETF',
    symbol: 'HSEU.MI',
  },
  {
    value: 'HSWD.L',
    label: 'HSWD.L,  HSBC Developed World Sustainable Equity UCITS ETF',
    name: 'HSBC Developed World Sustainable Equity UCITS ETF',
    symbol: 'HSWD.L',
  },
  {
    value: 'VGUE.F',
    label: 'VGUE.F,  Vanguard U.K. Gilt UCITS ETF',
    name: 'Vanguard U.K. Gilt UCITS ETF',
    symbol: 'VGUE.F',
  },
  {
    value: 'TCRS.DE',
    label: 'TCRS.DE,  Tabula Global IG Credit Curve Steepener UCITS ETF (EUR)',
    name: 'Tabula Global IG Credit Curve Steepener UCITS ETF (EUR)',
    symbol: 'TCRS.DE',
  },
  {
    value: 'HSEM.L',
    label: 'HSEM.L,  HSBC Emerging Market Sustainable Equity UCITS ETF',
    name: 'HSBC Emerging Market Sustainable Equity UCITS ETF',
    symbol: 'HSEM.L',
  },
  {
    value: 'FSKY.L',
    label: 'FSKY.L,  First Trust Cloud Computing UCITS ETF',
    name: 'First Trust Cloud Computing UCITS ETF',
    symbol: 'FSKY.L',
  },
  {
    value: 'CIBR.L',
    label: 'CIBR.L,  First Trust Nasdaq Cybersecurity UCITS ETF',
    name: 'First Trust Nasdaq Cybersecurity UCITS ETF',
    symbol: 'CIBR.L',
  },
  {
    value: 'SPPR.F',
    label: 'SPPR.F,  SPDR Bloomberg SASB Euro Corporate ESG UCITS ETF',
    name: 'SPDR Bloomberg SASB Euro Corporate ESG UCITS ETF',
    symbol: 'SPPR.F',
  },
  {
    value: 'JPTC.L',
    label:
      'JPTC.L,  JPMorgan ETFs (Ireland) ICAV - Carbon Transition Global Equity UCITS ETF',
    name: 'JPMorgan ETFs (Ireland) ICAV - Carbon Transition Global Equity UCITS ETF',
    symbol: 'JPTC.L',
  },
  {
    value: 'SMH.L',
    label: 'SMH.L,  VanEck Semiconductor UCITS ETF',
    name: 'VanEck Semiconductor UCITS ETF',
    symbol: 'SMH.L',
  },
  {
    value: 'EDOC.L',
    label: 'EDOC.L,  Global X Telemedicine & Digital Health UCITS ETF',
    name: 'Global X Telemedicine & Digital Health UCITS ETF',
    symbol: 'EDOC.L',
  },
  {
    value: 'HTWO.MI',
    label: 'HTWO.MI,  L&G Hydrogen Economy UCITS ETF',
    name: 'L&G Hydrogen Economy UCITS ETF',
    symbol: 'HTWO.MI',
  },
  {
    value: 'UBF6.F',
    label:
      'UBF6.F,  UBS (Irl) Fund Solutions plc - CMCI Commodity Carry Ex-Agriculture SF UCITS ETF',
    name: 'UBS (Irl) Fund Solutions plc - CMCI Commodity Carry Ex-Agriculture SF UCITS ETF',
    symbol: 'UBF6.F',
  },
  {
    value: 'UBF7.F',
    label:
      'UBF7.F,  UBS (Irl) Fund Solutions plc – CMCI Commodity Carry Ex-Agriculture SF UCITS ETF(hedged to EUR) A-acc',
    name: 'UBS (Irl) Fund Solutions plc – CMCI Commodity Carry Ex-Agriculture SF UCITS ETF(hedged to EUR) A-acc',
    symbol: 'UBF7.F',
  },
  {
    value: 'EMBI.PA',
    label:
      'EMBI.PA,  BNP Paribas Easy JPM ESG EMBI Global Diversified Composite',
    name: 'BNP Paribas Easy JPM ESG EMBI Global Diversified Composite',
    symbol: 'EMBI.PA',
  },
  {
    value: 'QVFD.AS',
    label:
      'QVFD.AS,  FlexShares Developed Markets Low Volatility Climate ESG UCITS ETF',
    name: 'FlexShares Developed Markets Low Volatility Climate ESG UCITS ETF',
    symbol: 'QVFD.AS',
  },
  {
    value: 'EQJS.L',
    label: 'EQJS.L,  Invesco NASDAQ Next Generation 100 UCITS ETF',
    name: 'Invesco NASDAQ Next Generation 100 UCITS ETF',
    symbol: 'EQJS.L',
  },
  {
    value: 'V3AL.F',
    label: 'V3AL.F,  Vanguard ESG Global All Cap UCITS ETF',
    name: 'Vanguard ESG Global All Cap UCITS ETF',
    symbol: 'V3AL.F',
  },
  {
    value: 'HDRO.L',
    label: 'HDRO.L,  VanEck Hydrogen Economy UCITS ETF',
    name: 'VanEck Hydrogen Economy UCITS ETF',
    symbol: 'HDRO.L',
  },
  {
    value: 'EQQX.F',
    label: 'EQQX.F,  Invesco NASDAQ-100 Swap UCITS ETF',
    name: 'Invesco NASDAQ-100 Swap UCITS ETF',
    symbol: 'EQQX.F',
  },
  {
    value: 'TINE.MI',
    label: 'TINE.MI,  Tabula Us Enhanced Inflation UCITS ETF',
    name: 'Tabula Us Enhanced Inflation UCITS ETF',
    symbol: 'TINE.MI',
  },
  {
    value: 'TRYP.L',
    label: 'TRYP.L,  HANetf ICAV - Airlines, Hotels, Cruise Lines UCITS ETF',
    name: 'HANetf ICAV - Airlines, Hotels, Cruise Lines UCITS ETF',
    symbol: 'TRYP.L',
  },
  {
    value: 'EGRO.PA',
    label: 'EGRO.PA,  BNP Paribas Easy ESG Growth Europe',
    name: 'BNP Paribas Easy ESG Growth Europe',
    symbol: 'EGRO.PA',
  },
  {
    value: 'JETS.L',
    label: 'JETS.L,  HANetf - U.S. Global Jets UCITS ETF',
    name: 'HANetf - U.S. Global Jets UCITS ETF',
    symbol: 'JETS.L',
  },
  {
    value: 'DGEN.L',
    label: 'DGEN.L,  HANetf ICAV - iClima Smart Energy UCITS ETF',
    name: 'HANetf ICAV - iClima Smart Energy UCITS ETF',
    symbol: 'DGEN.L',
  },
  {
    value: 'LVNG.L',
    label: 'LVNG.L,  Rize Environmental Impact 100 UCITS ETF',
    name: 'Rize Environmental Impact 100 UCITS ETF',
    symbol: 'LVNG.L',
  },
  {
    value: 'SOFT.L',
    label: 'SOFT.L,  Purpose Enterprise Software ESG-S UCITS ETF',
    name: 'Purpose Enterprise Software ESG-S UCITS ETF',
    symbol: 'SOFT.L',
  },
  {
    value: 'S0LR.F',
    label: 'S0LR.F,  Invesco Solar Energy UCITS ETF',
    name: 'Invesco Solar Energy UCITS ETF',
    symbol: 'S0LR.F',
  },
  {
    value: 'EN4C.F',
    label: 'EN4C.F,  L&G Multi-Strategy Enhanced Commodities UCITS ETF',
    name: 'L&G Multi-Strategy Enhanced Commodities UCITS ETF',
    symbol: 'EN4C.F',
  },
  {
    value: 'QVFE.AS',
    label:
      'QVFE.AS,  FlexShares Emerging Markets Low Volatility Climate ESG UCITS ETF',
    name: 'FlexShares Emerging Markets Low Volatility Climate ESG UCITS ETF',
    symbol: 'QVFE.AS',
  },
  {
    value: 'E40.PA',
    label: 'E40.PA,  BNP Paribas Easy CAC 40 ESG UCITS ETF',
    name: 'BNP Paribas Easy CAC 40 ESG UCITS ETF',
    symbol: 'E40.PA',
  },
  {
    value: 'PAVE.L',
    label: 'PAVE.L,  Global X U.S. Infrastructure Development UCITS ETF',
    name: 'Global X U.S. Infrastructure Development UCITS ETF',
    symbol: 'PAVE.L',
  },
  {
    value: 'SNSR.MI',
    label: 'SNSR.MI,  Global X Internet of Things UCITS ETF',
    name: 'Global X Internet of Things UCITS ETF',
    symbol: 'SNSR.MI',
  },
  {
    value: '4COP.F',
    label: '4COP.F,  Global X Copper Miners UCITS ETF',
    name: 'Global X Copper Miners UCITS ETF',
    symbol: '4COP.F',
  },
  {
    value: 'AKWA.F',
    label: 'AKWA.F,  Global X Clean Water UCITS ETF',
    name: 'Global X Clean Water UCITS ETF',
    symbol: 'AKWA.F',
  },
  {
    value: 'LI7U.F',
    label: 'LI7U.F,  Global X Lithium & Battery Tech UCITS ETF',
    name: 'Global X Lithium & Battery Tech UCITS ETF',
    symbol: 'LI7U.F',
  },
  {
    value: 'JPCE.MI',
    label:
      'JPCE.MI,  JPMorgan ETFs (Ireland) ICAV - Carbon Transition Global Equity (CTB) UCITS ETF',
    name: 'JPMorgan ETFs (Ireland) ICAV - Carbon Transition Global Equity (CTB) UCITS ETF',
    symbol: 'JPCE.MI',
  },
  {
    value: 'RA7Z.F',
    label: 'RA7Z.F,  Global X Solar UCITS ETF',
    name: 'Global X Solar UCITS ETF',
    symbol: 'RA7Z.F',
  },
  {
    value: 'KROP.F',
    label: 'KROP.F,  Global X AgTech & Food Innovation UCITS ETF',
    name: 'Global X AgTech & Food Innovation UCITS ETF',
    symbol: 'KROP.F',
  },
  {
    value: 'EQAC.SW',
    label: 'EQAC.SW,  Invesco EQQQ NASDAQ-100 UCITS ETF',
    name: 'Invesco EQQQ NASDAQ-100 UCITS ETF',
    symbol: 'EQAC.SW',
  },
  {
    value: 'XNZW.F',
    label: 'XNZW.F,  Xtrackers World Net Zero Pathway Paris Aligned UCITS ETF',
    name: 'Xtrackers World Net Zero Pathway Paris Aligned UCITS ETF',
    symbol: 'XNZW.F',
  },
  {
    value: 'ASRS.F',
    label: 'ASRS.F,  BNP Paribas Easy ECPI Global ESG Hydrogen Economy',
    name: 'BNP Paribas Easy ECPI Global ESG Hydrogen Economy',
    symbol: 'ASRS.F',
  },
  {
    value: 'SPPQ.F',
    label:
      'SPPQ.F,  SPDR Bloomberg SASB U.S. High Yield Corporate ESG UCITS ETF',
    name: 'SPDR Bloomberg SASB U.S. High Yield Corporate ESG UCITS ETF',
    symbol: 'SPPQ.F',
  },
  {
    value: 'PAWZ.L',
    label: 'PAWZ.L,  Rize Pet Care UCITS ETF',
    name: 'Rize Pet Care UCITS ETF',
    symbol: 'PAWZ.L',
  },
  {
    value: 'CBUL.F',
    label: 'CBUL.F,  iShares $ TIPS 0-5 UCITS ETF',
    name: 'iShares $ TIPS 0-5 UCITS ETF',
    symbol: 'CBUL.F',
  },
  {
    value: 'URNU.F',
    label: 'URNU.F,  Global X Uranium UCITS ETF',
    name: 'Global X Uranium UCITS ETF',
    symbol: 'URNU.F',
  },
  {
    value: 'ELEC.F',
    label: 'ELEC.F,  Electric Vehicle Charging Infrastructure UCITS ETF',
    name: 'Electric Vehicle Charging Infrastructure UCITS ETF',
    symbol: 'ELEC.F',
  },
  {
    value: 'SLVR.F',
    label: 'SLVR.F,  Global X Silver Miners UCITS ETF',
    name: 'Global X Silver Miners UCITS ETF',
    symbol: 'SLVR.F',
  },
  {
    value: 'AETH.SW',
    label: 'AETH.SW,  21Shares Ethereum ETP',
    name: '21Shares Ethereum ETP',
    symbol: 'AETH.SW',
  },
  {
    value: 'ABCH.SW',
    label: 'ABCH.SW,  21Shares Bitcoin Cash ETP',
    name: '21Shares Bitcoin Cash ETP',
    symbol: 'ABCH.SW',
  },
  {
    value: 'ADOT.SW',
    label: 'ADOT.SW,  21Shares Polkadot ETP',
    name: '21Shares Polkadot ETP',
    symbol: 'ADOT.SW',
  },
  {
    value: 'ASOL.SW',
    label: 'ASOL.SW,  21Shares Solana staking ETP',
    name: '21Shares Solana staking ETP',
    symbol: 'ASOL.SW',
  },
  {
    value: 'AVAX.SW',
    label: 'AVAX.SW,  21Shares Avalanche ETP',
    name: '21Shares Avalanche ETP',
    symbol: 'AVAX.SW',
  },
  {
    value: 'ABNB.SW',
    label: 'ABNB.SW,  21Shares Binance BNB ETP',
    name: '21Shares Binance BNB ETP',
    symbol: 'ABNB.SW',
  },
  {
    value: 'TDT.AS',
    label: 'TDT.AS,  VanEck AEX UCITS ETF',
    name: 'VanEck AEX UCITS ETF',
    symbol: 'TDT.AS',
  },
  {
    value: 'TMX.AS',
    label: 'TMX.AS,  VanEck AMX UCITS ETF',
    name: 'VanEck AMX UCITS ETF',
    symbol: 'TMX.AS',
  },
  {
    value: 'REUN',
    label: 'REUN,  Reunion Neuroscience Inc.',
    name: 'Reunion Neuroscience Inc.',
    symbol: 'REUN',
  },
  {
    value: 'ADMR.JK',
    label: 'ADMR.JK,  PT Adaro Minerals Indonesia Tbk',
    name: 'PT Adaro Minerals Indonesia Tbk',
    symbol: 'ADMR.JK',
  },
  {
    value: 'PXMD',
    label: 'PXMD,  PaxMedica, Inc.',
    name: 'PaxMedica, Inc.',
    symbol: 'PXMD',
  },
  {
    value: 'GCT',
    label: 'GCT,  GigaCloud Technology Inc.',
    name: 'GigaCloud Technology Inc.',
    symbol: 'GCT',
  },
  {
    value: '9543.SR',
    label: '9543.SR,  Saudi Networkers Services Company',
    name: 'Saudi Networkers Services Company',
    symbol: '9543.SR',
  },
  {
    value: 'GRFX',
    label: 'GRFX,  Graphex Group Limited',
    name: 'Graphex Group Limited',
    symbol: 'GRFX',
  },
  {
    value: 'STBX',
    label: 'STBX,  Starbox Group Holdings Ltd.',
    name: 'Starbox Group Holdings Ltd.',
    symbol: 'STBX',
  },
  {
    value: 'AKLI',
    label: 'AKLI,  Akili, Inc.',
    name: 'Akili, Inc.',
    symbol: 'AKLI',
  },
  {
    value: 'TGL',
    label: 'TGL,  Treasure Global Inc.',
    name: 'Treasure Global Inc.',
    symbol: 'TGL',
  },
  {
    value: '2315.HK',
    label: '2315.HK,  Biocytogen Pharmaceuticals (Beijing) Co., Ltd.',
    name: 'Biocytogen Pharmaceuticals (Beijing) Co., Ltd.',
    symbol: '2315.HK',
  },
  {
    value: 'UNT.WA',
    label: 'UNT.WA,  Unimot S.A.',
    name: 'Unimot S.A.',
    symbol: 'UNT.WA',
  },
  {
    value: '9547.SR',
    label: '9547.SR,  Rawasi Albina Investment Co.',
    name: 'Rawasi Albina Investment Co.',
    symbol: '9547.SR',
  },
  {
    value: '9545.SR',
    label: '9545.SR,  International Human Resources Company',
    name: 'International Human Resources Company',
    symbol: '9545.SR',
  },
  {
    value: 'HPCO',
    label: 'HPCO,  Hempacco Co., Inc.',
    name: 'Hempacco Co., Inc.',
    symbol: 'HPCO',
  },
  {
    value: '06N.WA',
    label: '06N.WA,  Magna Polonia S.A.',
    name: 'Magna Polonia S.A.',
    symbol: '06N.WA',
  },
  {
    value: '08N.WA',
    label: '08N.WA,  NFI Octava S.A.',
    name: 'NFI Octava S.A.',
    symbol: '08N.WA',
  },
  {
    value: '3RG.WA',
    label: '3RG.WA,  3R Games S.A.',
    name: '3R Games S.A.',
    symbol: '3RG.WA',
  },
  {
    value: 'AAT.WA',
    label: 'AAT.WA,  Alta S.A.',
    name: 'Alta S.A.',
    symbol: 'AAT.WA',
  },
  {
    value: 'APE',
    label: 'APE,  AMC Entertainment Holdings, Inc.',
    name: 'AMC Entertainment Holdings, Inc.',
    symbol: 'APE',
  },
  {
    value: 'ADV.WA',
    label: 'ADV.WA,  Adiuvo Investments S.A.',
    name: 'Adiuvo Investments S.A.',
    symbol: 'ADV.WA',
  },
  {
    value: 'AGT.WA',
    label: 'AGT.WA,  Agroton Public Limited',
    name: 'Agroton Public Limited',
    symbol: 'AGT.WA',
  },
  {
    value: 'ALG.WA',
    label: 'ALG.WA,  All in! Games S.A.',
    name: 'All in! Games S.A.',
    symbol: 'ALG.WA',
  },
  {
    value: 'ALI.WA',
    label: 'ALI.WA,  ALTUS SA',
    name: 'ALTUS SA',
    symbol: 'ALI.WA',
  },
  {
    value: 'APE.WA',
    label: 'APE.WA,  APS Energia SA',
    name: 'APS Energia SA',
    symbol: 'APE.WA',
  },
  {
    value: 'APL.WA',
    label: 'APL.WA,  Ampli S.A.',
    name: 'Ampli S.A.',
    symbol: 'APL.WA',
  },
  {
    value: 'APN.WA',
    label: 'APN.WA,  Aplisens S.A.',
    name: 'Aplisens S.A.',
    symbol: 'APN.WA',
  },
  {
    value: 'ARH.WA',
    label: 'ARH.WA,  Archicom S.A.',
    name: 'Archicom S.A.',
    symbol: 'ARH.WA',
  },
  {
    value: 'ARR.WA',
    label: 'ARR.WA,  Arteria S.A.',
    name: 'Arteria S.A.',
    symbol: 'ARR.WA',
  },
  {
    value: 'AST.WA',
    label: 'AST.WA,  ASTARTA Holding N.V.',
    name: 'ASTARTA Holding N.V.',
    symbol: 'AST.WA',
  },
  {
    value: 'ATG.WA',
    label: 'ATG.WA,  ATM Grupa S.A.',
    name: 'ATM Grupa S.A.',
    symbol: 'ATG.WA',
  },
  {
    value: 'ATL.WA',
    label: 'ATL.WA,  Atlas Estates Limited',
    name: 'Atlas Estates Limited',
    symbol: 'ATL.WA',
  },
  {
    value: 'ATP.WA',
    label: 'ATP.WA,  Atlanta Poland S.A.',
    name: 'Atlanta Poland S.A.',
    symbol: 'ATP.WA',
  },
  {
    value: 'ATR.WA',
    label: 'ATR.WA,  Atrem S.A.',
    name: 'Atrem S.A.',
    symbol: 'ATR.WA',
  },
  {
    value: 'AWM.WA',
    label: 'AWM.WA,  Airway Medix S.A.',
    name: 'Airway Medix S.A.',
    symbol: 'AWM.WA',
  },
  {
    value: 'BAH.WA',
    label: 'BAH.WA,  British Automotive Holding S.A.',
    name: 'British Automotive Holding S.A.',
    symbol: 'BAH.WA',
  },
  {
    value: 'BBD.WA',
    label: 'BBD.WA,  BBI Development S.A.',
    name: 'BBI Development S.A.',
    symbol: 'BBD.WA',
  },
  {
    value: 'BCM.WA',
    label: 'BCM.WA,  Betacom S.A.',
    name: 'Betacom S.A.',
    symbol: 'BCM.WA',
  },
  {
    value: 'BDZ.WA',
    label: 'BDZ.WA,  Elektrocieplownia Bedzin S.A.',
    name: 'Elektrocieplownia Bedzin S.A.',
    symbol: 'BDZ.WA',
  },
  {
    value: 'BIP.WA',
    label: 'BIP.WA,  Bio Planet S.A.',
    name: 'Bio Planet S.A.',
    symbol: 'BIP.WA',
  },
  {
    value: 'BMC.WA',
    label: 'BMC.WA,  Bumech S.A.',
    name: 'Bumech S.A.',
    symbol: 'BMC.WA',
  },
  {
    value: 'BMX.WA',
    label: 'BMX.WA,  BioMaxima S.A.',
    name: 'BioMaxima S.A.',
    symbol: 'BMX.WA',
  },
  {
    value: 'BOW.WA',
    label: 'BOW.WA,  Bowim S.A.',
    name: 'Bowim S.A.',
    symbol: 'BOW.WA',
  },
  {
    value: 'BRA.WA',
    label: 'BRA.WA,  Braster S.A.',
    name: 'Braster S.A.',
    symbol: 'BRA.WA',
  },
  {
    value: 'BRG.WA',
    label: 'BRG.WA,  Berling S.A.',
    name: 'Berling S.A.',
    symbol: 'BRG.WA',
  },
  {
    value: 'BST.WA',
    label: 'BST.WA,  BEST S.A.',
    name: 'BEST S.A.',
    symbol: 'BST.WA',
  },
  {
    value: 'CAV.WA',
    label: 'CAV.WA,  Cavatina Holding Spólka Akcyjna',
    name: 'Cavatina Holding Spólka Akcyjna',
    symbol: 'CAV.WA',
  },
  {
    value: 'CCE.WA',
    label: 'CCE.WA,  Clean&Carbon Energy S.A.',
    name: 'Clean&Carbon Energy S.A.',
    symbol: 'CCE.WA',
  },
  {
    value: 'CDL.WA',
    label: 'CDL.WA,  CDRL S.A.',
    name: 'CDRL S.A.',
    symbol: 'CDL.WA',
  },
  {
    value: 'CEZ.WA',
    label: 'CEZ.WA,  CEZ, a. s.',
    name: 'CEZ, a. s.',
    symbol: 'CEZ.WA',
  },
  {
    value: 'CFI.WA',
    label: 'CFI.WA,  CFI Holding S.A.',
    name: 'CFI Holding S.A.',
    symbol: 'CFI.WA',
  },
  {
    value: 'CLE.WA',
    label: 'CLE.WA,  Coal Energy S.A.',
    name: 'Coal Energy S.A.',
    symbol: 'CLE.WA',
  },
  {
    value: 'CNT.WA',
    label: 'CNT.WA,  Centrum Nowoczesnych Technologii S.A.',
    name: 'Centrum Nowoczesnych Technologii S.A.',
    symbol: 'CNT.WA',
  },
  {
    value: 'CPA.WA',
    label: 'CPA.WA,  Capital Partners S.A.',
    name: 'Capital Partners S.A.',
    symbol: 'CPA.WA',
  },
  {
    value: 'CPD.WA',
    label: 'CPD.WA,  CPD S.A.',
    name: 'CPD S.A.',
    symbol: 'CPD.WA',
  },
  {
    value: 'CPG.WA',
    label: 'CPG.WA,  Capital Park S.A.',
    name: 'Capital Park S.A.',
    symbol: 'CPG.WA',
  },
  {
    value: 'CPR.WA',
    label: 'CPR.WA,  Compremum S.A.',
    name: 'Compremum S.A.',
    symbol: 'CPR.WA',
  },
  {
    value: 'CRI.WA',
    label: 'CRI.WA,  Creotech Instruments S.A.',
    name: 'Creotech Instruments S.A.',
    symbol: 'CRI.WA',
  },
  {
    value: 'CRM.WA',
    label: 'CRM.WA,  PZ Cormay S.A.',
    name: 'PZ Cormay S.A.',
    symbol: 'CRM.WA',
  },
  {
    value: 'CTS.WA',
    label: 'CTS.WA,  City Service SE',
    name: 'City Service SE',
    symbol: 'CTS.WA',
  },
  {
    value: 'CZT.WA',
    label: 'CZT.WA,  Czerwona Torebka Spólka Akcyjna',
    name: 'Czerwona Torebka Spólka Akcyjna',
    symbol: 'CZT.WA',
  },
  {
    value: 'DAD.WA',
    label: 'DAD.WA,  Dadelo S.A.',
    name: 'Dadelo S.A.',
    symbol: 'DAD.WA',
  },
  {
    value: 'DCR.WA',
    label: 'DCR.WA,  Decora S.A.',
    name: 'Decora S.A.',
    symbol: 'DCR.WA',
  },
  {
    value: 'DEK.WA',
    label: 'DEK.WA,  Dekpol S.A.',
    name: 'Dekpol S.A.',
    symbol: 'DEK.WA',
  },
  {
    value: 'DEL.WA',
    label: 'DEL.WA,  Delko S.A.',
    name: 'Delko S.A.',
    symbol: 'DEL.WA',
  },
  {
    value: 'DGA.WA',
    label: 'DGA.WA,  DGA Spólka Akcyjna',
    name: 'DGA Spólka Akcyjna',
    symbol: 'DGA.WA',
  },
  {
    value: 'DIG.WA',
    label: 'DIG.WA,  Digital Network SA',
    name: 'Digital Network SA',
    symbol: 'DIG.WA',
  },
  {
    value: 'DPL.WA',
    label: 'DPL.WA,  Drozapol-Profil S.A.',
    name: 'Drozapol-Profil S.A.',
    symbol: 'DPL.WA',
  },
  {
    value: 'DTR.WA',
    label: 'DTR.WA,  Digitree Group S.A.',
    name: 'Digitree Group S.A.',
    symbol: 'DTR.WA',
  },
  {
    value: 'EAH.WA',
    label: 'EAH.WA,  ESOTIQ & Henderson S.A.',
    name: 'ESOTIQ & Henderson S.A.',
    symbol: 'EAH.WA',
  },
  {
    value: 'EDI.WA',
    label: 'EDI.WA,  ED Invest Spólka Akcyjna',
    name: 'ED Invest Spólka Akcyjna',
    symbol: 'EDI.WA',
  },
  {
    value: 'EEX.WA',
    label: 'EEX.WA,  Eko Export S.A.',
    name: 'Eko Export S.A.',
    symbol: 'EEX.WA',
  },
  {
    value: 'EFK.WA',
    label: 'EFK.WA,  Korporacja Gospodarcza efekt S.A.',
    name: 'Korporacja Gospodarcza efekt S.A.',
    symbol: 'EFK.WA',
  },
  {
    value: 'EHG.WA',
    label: 'EHG.WA,  Eurohold Bulgaria AD',
    name: 'Eurohold Bulgaria AD',
    symbol: 'EHG.WA',
  },
  {
    value: 'EKP.WA',
    label: 'EKP.WA,  Elkop SE',
    name: 'Elkop SE',
    symbol: 'EKP.WA',
  },
  {
    value: 'ELT.WA',
    label: 'ELT.WA,  Elektrotim S.A.',
    name: 'Elektrotim S.A.',
    symbol: 'ELT.WA',
  },
  {
    value: 'ELZ.WA',
    label: 'ELZ.WA,  Zaklady Urzadzen Komputerowych ELZAB S.A.',
    name: 'Zaklady Urzadzen Komputerowych ELZAB S.A.',
    symbol: 'ELZ.WA',
  },
  {
    value: 'EMC.WA',
    label: 'EMC.WA,  EMC Instytut Medyczny SA',
    name: 'EMC Instytut Medyczny SA',
    symbol: 'EMC.WA',
  },
  {
    value: 'ENE.WA',
    label: 'ENE.WA,  Centrum Medyczne ENEL-MED S.A.',
    name: 'Centrum Medyczne ENEL-MED S.A.',
    symbol: 'ENE.WA',
  },
  {
    value: 'ENI.WA',
    label: 'ENI.WA,  Energoinstal S.A.',
    name: 'Energoinstal S.A.',
    symbol: 'ENI.WA',
  },
  {
    value: 'ENP.WA',
    label: 'ENP.WA,  Energoaparatura SA',
    name: 'Energoaparatura SA',
    symbol: 'ENP.WA',
  },
  {
    value: 'ERG.WA',
    label: 'ERG.WA,  ERG Spólka Akcyjna',
    name: 'ERG Spólka Akcyjna',
    symbol: 'ERG.WA',
  },
  {
    value: 'ETL.WA',
    label: 'ETL.WA,  Eurotel S.A.',
    name: 'Eurotel S.A.',
    symbol: 'ETL.WA',
  },
  {
    value: 'EUC.WA',
    label: 'EUC.WA,  Europejskie Centrum Odszkodowan S.A.',
    name: 'Europejskie Centrum Odszkodowan S.A.',
    symbol: 'EUC.WA',
  },
  {
    value: 'FEE.WA',
    label: 'FEE.WA,  Feerum S.A.',
    name: 'Feerum S.A.',
    symbol: 'FEE.WA',
  },
  {
    value: 'FER.WA',
    label: 'FER.WA,  Ferrum S.A.',
    name: 'Ferrum S.A.',
    symbol: 'FER.WA',
  },
  {
    value: 'FFI.WA',
    label: 'FFI.WA,  Fast Finance S.A.',
    name: 'Fast Finance S.A.',
    symbol: 'FFI.WA',
  },
  {
    value: 'FMG.WA',
    label: 'FMG.WA,  ONE S.A.',
    name: 'ONE S.A.',
    symbol: 'FMG.WA',
  },
  {
    value: 'FON.WA',
    label: 'FON.WA,  FON SE',
    name: 'FON SE',
    symbol: 'FON.WA',
  },
  {
    value: 'FSG.WA',
    label:
      'FSG.WA,  Fabryki Sprzetu i Narzedzi Górniczych Grupa Kapitalowa FASING S.A.',
    name: 'Fabryki Sprzetu i Narzedzi Górniczych Grupa Kapitalowa FASING S.A.',
    symbol: 'FSG.WA',
  },
  {
    value: 'GIF.WA',
    label: 'GIF.WA,  Gaming Factory S.A.',
    name: 'Gaming Factory S.A.',
    symbol: 'GIF.WA',
  },
  {
    value: 'GIG.WA',
    label: 'GIG.WA,  Gi Group Poland S.A.',
    name: 'Gi Group Poland S.A.',
    symbol: 'GIG.WA',
  },
  {
    value: 'GKI.WA',
    label: 'GKI.WA,  Grupa Kapitalowa IMMOBILE S.A.',
    name: 'Grupa Kapitalowa IMMOBILE S.A.',
    symbol: 'GKI.WA',
  },
  {
    value: 'GLC.WA',
    label: 'GLC.WA,  Global Cosmed S.A.',
    name: 'Global Cosmed S.A.',
    symbol: 'GLC.WA',
  },
  {
    value: 'GOB.WA',
    label: 'GOB.WA,  Gobarto S.A.',
    name: 'Gobarto S.A.',
    symbol: 'GOB.WA',
  },
  {
    value: 'GOP.WA',
    label: 'GOP.WA,  Games Operators S.A.',
    name: 'Games Operators S.A.',
    symbol: 'GOP.WA',
  },
  {
    value: 'GRN.WA',
    label: 'GRN.WA,  Grodno Spólka Akcyjna',
    name: 'Grodno Spólka Akcyjna',
    symbol: 'GRN.WA',
  },
  {
    value: 'GRX.WA',
    label: 'GRX.WA,  GreenX Metals Limited',
    name: 'GreenX Metals Limited',
    symbol: 'GRX.WA',
  },
  {
    value: 'HDR.WA',
    label: 'HDR.WA,  Przedsiebiorstwo Hydrauliki Silowej HYDROTOR S.A.',
    name: 'Przedsiebiorstwo Hydrauliki Silowej HYDROTOR S.A.',
    symbol: 'HDR.WA',
  },
  {
    value: 'HEL.WA',
    label: 'HEL.WA,  Helio S.A.',
    name: 'Helio S.A.',
    symbol: 'HEL.WA',
  },
  {
    value: 'HMI.WA',
    label: 'HMI.WA,  HM Inwest S.A.',
    name: 'HM Inwest S.A.',
    symbol: 'HMI.WA',
  },
  {
    value: 'HRP.WA',
    label: 'HRP.WA,  Harper Hygienics S.A.',
    name: 'Harper Hygienics S.A.',
    symbol: 'HRP.WA',
  },
  {
    value: 'HRS.WA',
    label: 'HRS.WA,  Herkules S.A.',
    name: 'Herkules S.A.',
    symbol: 'HRS.WA',
  },
  {
    value: 'IBS.WA',
    label: 'IBS.WA,  Noble Financials S.A.',
    name: 'Noble Financials S.A.',
    symbol: 'IBS.WA',
  },
  {
    value: 'ICE.WA',
    label: 'ICE.WA,  Medinice S.A.',
    name: 'Medinice S.A.',
    symbol: 'ICE.WA',
  },
  {
    value: 'IDM.WA',
    label: 'IDM.WA,  IDM S.A.',
    name: 'IDM S.A.',
    symbol: 'IDM.WA',
  },
  {
    value: 'IFR.WA',
    label: 'IFR.WA,  Investment Friends SE',
    name: 'Investment Friends SE',
    symbol: 'IFR.WA',
  },
  {
    value: 'IIA.WA',
    label: 'IIA.WA,  IMMOFINANZ AG',
    name: 'IMMOFINANZ AG',
    symbol: 'IIA.WA',
  },
  {
    value: 'IMC.WA',
    label: 'IMC.WA,  IMC S.A.',
    name: 'IMC S.A.',
    symbol: 'IMC.WA',
  },
  {
    value: 'IMP.WA',
    label: 'IMP.WA,  Imperio Alternatywna Spolka Inwestycyjna S.A.',
    name: 'Imperio Alternatywna Spolka Inwestycyjna S.A.',
    symbol: 'IMP.WA',
  },
  {
    value: 'INC.WA',
    label: 'INC.WA,  INC S.A.',
    name: 'INC S.A.',
    symbol: 'INC.WA',
  },
  {
    value: 'INK.WA',
    label: 'INK.WA,  Instal Kraków S.A.',
    name: 'Instal Kraków S.A.',
    symbol: 'INK.WA',
  },
  {
    value: 'INL.WA',
    label: 'INL.WA,  Introl S.A.',
    name: 'Introl S.A.',
    symbol: 'INL.WA',
  },
  {
    value: 'INP.WA',
    label: 'INP.WA,  Inpro S.A.',
    name: 'Inpro S.A.',
    symbol: 'INP.WA',
  },
  {
    value: 'IPE.WA',
    label: 'IPE.WA,  IPOPEMA Securities S.A.',
    name: 'IPOPEMA Securities S.A.',
    symbol: 'IPE.WA',
  },
  {
    value: 'IPO.WA',
    label: 'IPO.WA,  Intersport Polska S.A.',
    name: 'Intersport Polska S.A.',
    symbol: 'IPO.WA',
  },
  {
    value: 'ITB.WA',
    label: 'ITB.WA,  Interbud-Lublin S.A.',
    name: 'Interbud-Lublin S.A.',
    symbol: 'ITB.WA',
  },
  {
    value: 'ITM.WA',
    label: 'ITM.WA,  Interma Trade S.A.',
    name: 'Interma Trade S.A.',
    symbol: 'ITM.WA',
  },
  {
    value: 'IZB.WA',
    label: 'IZB.WA,  IZOBLOK S.A.',
    name: 'IZOBLOK S.A.',
    symbol: 'IZB.WA',
  },
  {
    value: 'IZO.WA',
    label: 'IZO.WA,  Izolacja Jarocin Spolka Akcyjna',
    name: 'Izolacja Jarocin Spolka Akcyjna',
    symbol: 'IZO.WA',
  },
  {
    value: 'IZS.WA',
    label: 'IZS.WA,  Izostal S.A.',
    name: 'Izostal S.A.',
    symbol: 'IZS.WA',
  },
  {
    value: 'JWW.WA',
    label: 'JWW.WA,  JWW Invest S.A.',
    name: 'JWW Invest S.A.',
    symbol: 'JWW.WA',
  },
  {
    value: 'KCH.WA',
    label: 'KCH.WA,  Krakchemia S.A.',
    name: 'Krakchemia S.A.',
    symbol: 'KCH.WA',
  },
  {
    value: 'KCI.WA',
    label: 'KCI.WA,  KCI Spolka Akcyjna',
    name: 'KCI Spolka Akcyjna',
    symbol: 'KCI.WA',
  },
  {
    value: 'KGL.WA',
    label: 'KGL.WA,  Korporacja KGL S.A.',
    name: 'Korporacja KGL S.A.',
    symbol: 'KGL.WA',
  },
  {
    value: 'KMP.WA',
    label: 'KMP.WA,  Przedsiebiorstwo Produkcyjno - Handlowe KOMPAP S.A.',
    name: 'Przedsiebiorstwo Produkcyjno - Handlowe KOMPAP S.A.',
    symbol: 'KMP.WA',
  },
  {
    value: 'KPD.WA',
    label:
      'KPD.WA,  Koszalinskie Przedsiebiorstwo Przemyslu Drzewnego Spólka Akcyjna',
    name: 'Koszalinskie Przedsiebiorstwo Przemyslu Drzewnego Spólka Akcyjna',
    symbol: 'KPD.WA',
  },
  {
    value: 'KPL.WA',
    label: 'KPL.WA,  Kino Polska TV Spolka Akcyjna',
    name: 'Kino Polska TV Spolka Akcyjna',
    symbol: 'KPL.WA',
  },
  {
    value: 'KRI.WA',
    label: 'KRI.WA,  Kredyt Inkaso S.A.',
    name: 'Kredyt Inkaso S.A.',
    symbol: 'KRI.WA',
  },
  {
    value: 'KRK.WA',
    label: 'KRK.WA,  Krka, d. d.',
    name: 'Krka, d. d.',
    symbol: 'KRK.WA',
  },
  {
    value: 'KSG.WA',
    label: 'KSG.WA,  KSG Agro S.A.',
    name: 'KSG Agro S.A.',
    symbol: 'KSG.WA',
  },
  {
    value: 'KVT.WA',
    label: 'KVT.WA,  Krynica Vitamin S.A.',
    name: 'Krynica Vitamin S.A.',
    symbol: 'KVT.WA',
  },
  {
    value: 'LAB.WA',
    label: 'LAB.WA,  Labo Print S.A.',
    name: 'Labo Print S.A.',
    symbol: 'LAB.WA',
  },
  {
    value: 'LBT.WA',
    label: 'LBT.WA,  Libet S.A.',
    name: 'Libet S.A.',
    symbol: 'LBT.WA',
  },
  {
    value: 'LBW.WA',
    label: 'LBW.WA,  Lubawa S.A.',
    name: 'Lubawa S.A.',
    symbol: 'LBW.WA',
  },
  {
    value: 'LEN.WA',
    label: 'LEN.WA,  Lena Lighting S.A.',
    name: 'Lena Lighting S.A.',
    symbol: 'LEN.WA',
  },
  {
    value: 'LKD.WA',
    label: 'LKD.WA,  Lokum Deweloper S.A.',
    name: 'Lokum Deweloper S.A.',
    symbol: 'LKD.WA',
  },
  {
    value: 'LRQ.WA',
    label: 'LRQ.WA,  Larq S.A.',
    name: 'Larq S.A.',
    symbol: 'LRQ.WA',
  },
  {
    value: 'LTX.WA',
    label: 'LTX.WA,  Lentex S.A.',
    name: 'Lentex S.A.',
    symbol: 'LTX.WA',
  },
  {
    value: 'MAK.WA',
    label: 'MAK.WA,  Makarony Polskie S.A.',
    name: 'Makarony Polskie S.A.',
    symbol: 'MAK.WA',
  },
  {
    value: 'MAN.WA',
    label: 'MAN.WA,  ManyDev Studio SE',
    name: 'ManyDev Studio SE',
    symbol: 'MAN.WA',
  },
  {
    value: 'MBW.WA',
    label: 'MBW.WA,  Marie Brizard Wine & Spirits SA',
    name: 'Marie Brizard Wine & Spirits SA',
    symbol: 'MBW.WA',
  },
  {
    value: 'MCR.WA',
    label: 'MCR.WA,  Mercor S.A.',
    name: 'Mercor S.A.',
    symbol: 'MCR.WA',
  },
  {
    value: 'MDI.WA',
    label: 'MDI.WA,  MDI Energia S.A.',
    name: 'MDI Energia S.A.',
    symbol: 'MDI.WA',
  },
  {
    value: 'MEX.WA',
    label: 'MEX.WA,  Mex Polska S.A.',
    name: 'Mex Polska S.A.',
    symbol: 'MEX.WA',
  },
  {
    value: 'MFO.WA',
    label: 'MFO.WA,  MFO S.A.',
    name: 'MFO S.A.',
    symbol: 'MFO.WA',
  },
  {
    value: 'MIR.WA',
    label: 'MIR.WA,  Miraculum S.A.',
    name: 'Miraculum S.A.',
    symbol: 'MIR.WA',
  },
  {
    value: 'MLK.WA',
    label: 'MLK.WA,  Milkiland Public Company Limited',
    name: 'Milkiland Public Company Limited',
    symbol: 'MLK.WA',
  },
  {
    value: 'MLS.WA',
    label: 'MLS.WA,  ML System S.A.',
    name: 'ML System S.A.',
    symbol: 'MLS.WA',
  },
  {
    value: 'MOC.WA',
    label: 'MOC.WA,  Molecure S.A.',
    name: 'Molecure S.A.',
    symbol: 'MOC.WA',
  },
  {
    value: 'MOJ.WA',
    label: 'MOJ.WA,  MOJ S.A.',
    name: 'MOJ S.A.',
    symbol: 'MOJ.WA',
  },
  {
    value: 'MOL.WA',
    label:
      'MOL.WA,  MOL Magyar Olaj- és Gázipari Nyilvánosan Muködo Részvénytársaság',
    name: 'MOL Magyar Olaj- és Gázipari Nyilvánosan Muködo Részvénytársaság',
    symbol: 'MOL.WA',
  },
  {
    value: 'MON.WA',
    label: 'MON.WA,  Monnari Trade S.A.',
    name: 'Monnari Trade S.A.',
    symbol: 'MON.WA',
  },
  {
    value: 'MSP.WA',
    label: 'MSP.WA,  Mostostal Plock S.A.',
    name: 'Mostostal Plock S.A.',
    symbol: 'MSP.WA',
  },
  {
    value: 'MSW.WA',
    label: 'MSW.WA,  Mostostal Warszawa S.A.',
    name: 'Mostostal Warszawa S.A.',
    symbol: 'MSW.WA',
  },
  {
    value: 'MSZ.WA',
    label: 'MSZ.WA,  Mostostal Zabrze S.A.',
    name: 'Mostostal Zabrze S.A.',
    symbol: 'MSZ.WA',
  },
  {
    value: 'MVP.WA',
    label: 'MVP.WA,  Marvipol Development S.A.',
    name: 'Marvipol Development S.A.',
    symbol: 'MVP.WA',
  },
  {
    value: 'MWT.WA',
    label: 'MWT.WA,  M.W. Trade SA',
    name: 'M.W. Trade SA',
    symbol: 'MWT.WA',
  },
  {
    value: 'MXC.WA',
    label: 'MXC.WA,  Maxcom S.A.',
    name: 'Maxcom S.A.',
    symbol: 'MXC.WA',
  },
  {
    value: 'MZA.WA',
    label: 'MZA.WA,  Muza S.A.',
    name: 'Muza S.A.',
    symbol: 'MZA.WA',
  },
  {
    value: 'NNG.WA',
    label: 'NNG.WA,  NanoGroup S.A.',
    name: 'NanoGroup S.A.',
    symbol: 'NNG.WA',
  },
  {
    value: 'NTU.WA',
    label: 'NTU.WA,  Novaturas AB',
    name: 'Novaturas AB',
    symbol: 'NTU.WA',
  },
  {
    value: 'NVA.WA',
    label: 'NVA.WA,  P.A. Nova S.A.',
    name: 'P.A. Nova S.A.',
    symbol: 'NVA.WA',
  },
  {
    value: 'NVG.WA',
    label: 'NVG.WA,  Novavis Group S.A.',
    name: 'Novavis Group S.A.',
    symbol: 'NVG.WA',
  },
  {
    value: 'NVT.WA',
    label: 'NVT.WA,  Novita S.A.',
    name: 'Novita S.A.',
    symbol: 'NVT.WA',
  },
  {
    value: 'NXG.WA',
    label: 'NXG.WA,  Nexity Global S.A.',
    name: 'Nexity Global S.A.',
    symbol: 'NXG.WA',
  },
  {
    value: 'OBL.WA',
    label: 'OBL.WA,  Orzel Bialy S.A.',
    name: 'Orzel Bialy S.A.',
    symbol: 'OBL.WA',
  },
  {
    value: 'ODL.WA',
    label: 'ODL.WA,  Odlewnie Polskie S.A.',
    name: 'Odlewnie Polskie S.A.',
    symbol: 'ODL.WA',
  },
  {
    value: 'OND.WA',
    label: 'OND.WA,  Onde S.A.',
    name: 'Onde S.A.',
    symbol: 'OND.WA',
  },
  {
    value: 'OPF.WA',
    label: 'OPF.WA,  Open Finance S.A.',
    name: 'Open Finance S.A.',
    symbol: 'OPF.WA',
  },
  {
    value: 'OPG.WA',
    label: 'OPG.WA,  CPI FIM SA',
    name: 'CPI FIM SA',
    symbol: 'OPG.WA',
  },
  {
    value: 'OTM.WA',
    label: 'OTM.WA,  Zaklady Przemyslu Cukierniczego Otmuchów S.A.',
    name: 'Zaklady Przemyslu Cukierniczego Otmuchów S.A.',
    symbol: 'OTM.WA',
  },
  {
    value: 'OTS.WA',
    label: 'OTS.WA,  OT Logistics S.A.',
    name: 'OT Logistics S.A.',
    symbol: 'OTS.WA',
  },
  {
    value: 'OVO.WA',
    label: 'OVO.WA,  Ovostar Union Public Company Limited',
    name: 'Ovostar Union Public Company Limited',
    symbol: 'OVO.WA',
  },
  {
    value: 'PAT.WA',
    label: 'PAT.WA,  Patentus S.A.',
    name: 'Patentus S.A.',
    symbol: 'PAT.WA',
  },
  {
    value: 'PBF.WA',
    label: 'PBF.WA,  PBS Finanse S.A.',
    name: 'PBS Finanse S.A.',
    symbol: 'PBF.WA',
  },
  {
    value: 'PBG.WA',
    label: 'PBG.WA,  PBG S.A.',
    name: 'PBG S.A.',
    symbol: 'PBG.WA',
  },
  {
    value: 'PCE.WA',
    label: 'PCE.WA,  Grupa Azoty Zaklady Chemiczne Police S.A.',
    name: 'Grupa Azoty Zaklady Chemiczne Police S.A.',
    symbol: 'PCE.WA',
  },
  {
    value: 'PCO.WA',
    label: 'PCO.WA,  Pepco Group N.V.',
    name: 'Pepco Group N.V.',
    symbol: 'PCO.WA',
  },
  {
    value: 'PCX.WA',
    label: 'PCX.WA,  PCC Exol S.A.',
    name: 'PCC Exol S.A.',
    symbol: 'PCX.WA',
  },
  {
    value: 'PEN.WA',
    label: 'PEN.WA,  Photon Energy N.V.',
    name: 'Photon Energy N.V.',
    symbol: 'PEN.WA',
  },
  {
    value: 'PGM.WA',
    label: 'PGM.WA,  PMPG Polskie Media SA',
    name: 'PMPG Polskie Media SA',
    symbol: 'PGM.WA',
  },
  {
    value: 'PGV.WA',
    label: 'PGV.WA,  PGF Polska Grupa Fotowoltaiczna SA',
    name: 'PGF Polska Grupa Fotowoltaiczna SA',
    symbol: 'PGV.WA',
  },
  {
    value: 'PHN.WA',
    label: 'PHN.WA,  Polski Holding Nieruchomosci S.A.',
    name: 'Polski Holding Nieruchomosci S.A.',
    symbol: 'PHN.WA',
  },
  {
    value: 'PHR.WA',
    label: 'PHR.WA,  Pharmena S.A.',
    name: 'Pharmena S.A.',
    symbol: 'PHR.WA',
  },
  {
    value: 'PJP.WA',
    label: 'PJP.WA,  PJP Makrum S.A.',
    name: 'PJP Makrum S.A.',
    symbol: 'PJP.WA',
  },
  {
    value: 'PLZ.WA',
    label: 'PLZ.WA,  Plaza Centers N.V.',
    name: 'Plaza Centers N.V.',
    symbol: 'PLZ.WA',
  },
  {
    value: 'PMA.WA',
    label: 'PMA.WA,  Prima Moda S.A.',
    name: 'Prima Moda S.A.',
    symbol: 'PMA.WA',
  },
  {
    value: 'PMP.WA',
    label: 'PMP.WA,  Pamapol S.A.',
    name: 'Pamapol S.A.',
    symbol: 'PMP.WA',
  },
  {
    value: 'PPS.WA',
    label: 'PPS.WA,  Pepees S.A.',
    name: 'Pepees S.A.',
    symbol: 'PPS.WA',
  },
  {
    value: 'PRI.WA',
    label: 'PRI.WA,  Pragma Inkaso S.A.',
    name: 'Pragma Inkaso S.A.',
    symbol: 'PRI.WA',
  },
  {
    value: 'PRM.WA',
    label: 'PRM.WA,  Prochem S.A.',
    name: 'Prochem S.A.',
    symbol: 'PRM.WA',
  },
  {
    value: 'PRT.WA',
    label: 'PRT.WA,  Protektor S.A.',
    name: 'Protektor S.A.',
    symbol: 'PRT.WA',
  },
  {
    value: 'PTG.WA',
    label: 'PTG.WA,  Poltreg S.A.',
    name: 'Poltreg S.A.',
    symbol: 'PTG.WA',
  },
  {
    value: 'PTH.WA',
    label: 'PTH.WA,  Primetech S.A.',
    name: 'Primetech S.A.',
    symbol: 'PTH.WA',
  },
  {
    value: 'PUE.WA',
    label: 'PUE.WA,  ZPUE S.A.',
    name: 'ZPUE S.A.',
    symbol: 'PUE.WA',
  },
  {
    value: 'PUN.WA',
    label: 'PUN.WA,  PunkPirates S.A.',
    name: 'PunkPirates S.A.',
    symbol: 'PUN.WA',
  },
  {
    value: 'PUR.WA',
    label: 'PUR.WA,  Pure Biologics Spólka Akcyjna',
    name: 'Pure Biologics Spólka Akcyjna',
    symbol: 'PUR.WA',
  },
  {
    value: 'PWX.WA',
    label: 'PWX.WA,  Polwax S.A.',
    name: 'Polwax S.A.',
    symbol: 'PWX.WA',
  },
  {
    value: 'QRS.WA',
    label: 'QRS.WA,  Quercus TFI S.A.',
    name: 'Quercus TFI S.A.',
    symbol: 'QRS.WA',
  },
  {
    value: 'RAF.WA',
    label: 'RAF.WA,  Fabryka Obrabiarek RAFAMET S.A.',
    name: 'Fabryka Obrabiarek RAFAMET S.A.',
    symbol: 'RAF.WA',
  },
  {
    value: 'RDN.WA',
    label: 'RDN.WA,  Redan S.A.',
    name: 'Redan S.A.',
    symbol: 'RDN.WA',
  },
  {
    value: 'RES.WA',
    label: 'RES.WA,  Resbud SE',
    name: 'Resbud SE',
    symbol: 'RES.WA',
  },
  {
    value: 'RFK.WA',
    label: 'RFK.WA,  Rafako S.A.',
    name: 'Rafako S.A.',
    symbol: 'RFK.WA',
  },
  {
    value: 'RHD.WA',
    label: 'RHD.WA,  Reinhold Europe AB (publ)',
    name: 'Reinhold Europe AB (publ)',
    symbol: 'RHD.WA',
  },
  {
    value: 'RLP.WA',
    label: 'RLP.WA,  Relpol S.A.',
    name: 'Relpol S.A.',
    symbol: 'RLP.WA',
  },
  {
    value: 'RMK.WA',
    label: 'RMK.WA,  Remak-Energomontaz S.A.',
    name: 'Remak-Energomontaz S.A.',
    symbol: 'RMK.WA',
  },
  {
    value: 'RNC.WA',
    label: 'RNC.WA,  REINO Capital S.A.',
    name: 'REINO Capital S.A.',
    symbol: 'RNC.WA',
  },
  {
    value: 'RNK.WA',
    label: 'RNK.WA,  Rank Progress S.A.',
    name: 'Rank Progress S.A.',
    symbol: 'RNK.WA',
  },
  {
    value: 'RPC.WA',
    label: 'RPC.WA,  Zaklady Magnezytowe ROPCZYCE S.A.',
    name: 'Zaklady Magnezytowe ROPCZYCE S.A.',
    symbol: 'RPC.WA',
  },
  {
    value: 'RWL.WA',
    label: 'RWL.WA,  Rawlplug S.A.',
    name: 'Rawlplug S.A.',
    symbol: 'RWL.WA',
  },
  {
    value: 'SAN.WA',
    label: 'SAN.WA,  Banco Santander, S.A.',
    name: 'Banco Santander, S.A.',
    symbol: 'SAN.WA',
  },
  {
    value: 'SEK.WA',
    label: 'SEK.WA,  Seko S.A.',
    name: 'Seko S.A.',
    symbol: 'SEK.WA',
  },
  {
    value: 'SEL.WA',
    label: 'SEL.WA,  Selena FM S.A.',
    name: 'Selena FM S.A.',
    symbol: 'SEL.WA',
  },
  {
    value: 'SEN.WA',
    label: 'SEN.WA,  Serinus Energy plc',
    name: 'Serinus Energy plc',
    symbol: 'SEN.WA',
  },
  {
    value: 'SFG.WA',
    label: 'SFG.WA,  AS Silvano Fashion Group',
    name: 'AS Silvano Fashion Group',
    symbol: 'SFG.WA',
  },
  {
    value: 'SFS.WA',
    label: 'SFS.WA,  Sfinks Polska S.A.',
    name: 'Sfinks Polska S.A.',
    symbol: 'SFS.WA',
  },
  {
    value: 'SHD.WA',
    label: 'SHD.WA,  Soho Development S.A.',
    name: 'Soho Development S.A.',
    symbol: 'SHD.WA',
  },
  {
    value: 'SHG.WA',
    label: 'SHG.WA,  Starhedge S.A.',
    name: 'Starhedge S.A.',
    symbol: 'SHG.WA',
  },
  {
    value: 'SIM.WA',
    label: 'SIM.WA,  SimFabric S.A.',
    name: 'SimFabric S.A.',
    symbol: 'SIM.WA',
  },
  {
    value: 'SKH.WA',
    label: 'SKH.WA,  Skarbiec Holding S.A.',
    name: 'Skarbiec Holding S.A.',
    symbol: 'SKH.WA',
  },
  {
    value: 'SKL.WA',
    label: 'SKL.WA,  Skyline Investment S.A.',
    name: 'Skyline Investment S.A.',
    symbol: 'SKL.WA',
  },
  {
    value: 'SKT.WA',
    label: 'SKT.WA,  Skotan SA',
    name: 'Skotan SA',
    symbol: 'SKT.WA',
  },
  {
    value: 'SLZ.WA',
    label: 'SLZ.WA,  Sleepz AG',
    name: 'Sleepz AG',
    symbol: 'SLZ.WA',
  },
  {
    value: 'SNT.WA',
    label: 'SNT.WA,  Synektik Spólka Akcyjna',
    name: 'Synektik Spólka Akcyjna',
    symbol: 'SNT.WA',
  },
  {
    value: 'SNW.WA',
    label: 'SNW.WA,  Sanwil Holding Spólka Akcyjna',
    name: 'Sanwil Holding Spólka Akcyjna',
    symbol: 'SNW.WA',
  },
  {
    value: 'SNX.WA',
    label: 'SNX.WA,  Sunex S.A.',
    name: 'Sunex S.A.',
    symbol: 'SNX.WA',
  },
  {
    value: 'SOL.WA',
    label: 'SOL.WA,  Solar Company S.A.',
    name: 'Solar Company S.A.',
    symbol: 'SOL.WA',
  },
  {
    value: 'SON.WA',
    label: 'SON.WA,  Sonel S.A.',
    name: 'Sonel S.A.',
    symbol: 'SON.WA',
  },
  {
    value: 'SPH.WA',
    label: 'SPH.WA,  Sopharma AD',
    name: 'Sopharma AD',
    symbol: 'SPH.WA',
  },
  {
    value: 'SPR.WA',
    label: 'SPR.WA,  Spyrosoft Spólka Akcyjna',
    name: 'Spyrosoft Spólka Akcyjna',
    symbol: 'SPR.WA',
  },
  {
    value: 'STF.WA',
    label: 'STF.WA,  Stalprofil S.A.',
    name: 'Stalprofil S.A.',
    symbol: 'STF.WA',
  },
  {
    value: 'STS.WA',
    label: 'STS.WA,  Satis Group S.A.',
    name: 'Satis Group S.A.',
    symbol: 'STS.WA',
  },
  {
    value: 'SUW.WA',
    label: 'SUW.WA,  Suwary S.A.',
    name: 'Suwary S.A.',
    symbol: 'SUW.WA',
  },
  {
    value: 'SVRS.WA',
    label: 'SVRS.WA,  Silvair, Inc.',
    name: 'Silvair, Inc.',
    symbol: 'SVRS.WA',
  },
  {
    value: 'SWG.WA',
    label: 'SWG.WA,  Seco/Warwick S.A.',
    name: 'Seco/Warwick S.A.',
    symbol: 'SWG.WA',
  },
  {
    value: 'TAR.WA',
    label: 'TAR.WA,  Tarczynski S.A.',
    name: 'Tarczynski S.A.',
    symbol: 'TAR.WA',
  },
  {
    value: 'TLX.WA',
    label: 'TLX.WA,  Talex S.A.',
    name: 'Talex S.A.',
    symbol: 'TLX.WA',
  },
  {
    value: 'TMR.WA',
    label: 'TMR.WA,  Tatry mountain resorts, a.s.',
    name: 'Tatry mountain resorts, a.s.',
    symbol: 'TMR.WA',
  },
  {
    value: 'TOA.WA',
    label: 'TOA.WA,  TOYA S.A.',
    name: 'TOYA S.A.',
    symbol: 'TOA.WA',
  },
  {
    value: 'TOR.WA',
    label: 'TOR.WA,  Torpol S.A.',
    name: 'Torpol S.A.',
    symbol: 'TOR.WA',
  },
  {
    value: 'TOW.WA',
    label: 'TOW.WA,  Tower Investments S.A.',
    name: 'Tower Investments S.A.',
    symbol: 'TOW.WA',
  },
  {
    value: 'TRI.WA',
    label: 'TRI.WA,  Triton Development S.A.',
    name: 'Triton Development S.A.',
    symbol: 'TRI.WA',
  },
  {
    value: 'TRK.WA',
    label: 'TRK.WA,  Trakcja S.A.',
    name: 'Trakcja S.A.',
    symbol: 'TRK.WA',
  },
  {
    value: 'TRN.WA',
    label: 'TRN.WA,  Trans Polonia S.A.',
    name: 'Trans Polonia S.A.',
    symbol: 'TRN.WA',
  },
  {
    value: 'TRR.WA',
    label: 'TRR.WA,  Termo-Rex S.A.',
    name: 'Termo-Rex S.A.',
    symbol: 'TRR.WA',
  },
  {
    value: 'TSG.WA',
    label: 'TSG.WA,  Tesgas S.A.',
    name: 'Tesgas S.A.',
    symbol: 'TSG.WA',
  },
  {
    value: 'UCG.WA',
    label: 'UCG.WA,  UniCredit S.p.A.',
    name: 'UniCredit S.p.A.',
    symbol: 'UCG.WA',
  },
  {
    value: 'ULM.WA',
    label: 'ULM.WA,  ULMA Construccion Polska S.A.',
    name: 'ULMA Construccion Polska S.A.',
    symbol: 'ULM.WA',
  },
  {
    value: 'UNI.WA',
    label: 'UNI.WA,  UNIBEP S.A.',
    name: 'UNIBEP S.A.',
    symbol: 'UNI.WA',
  },
  {
    value: 'VIN.WA',
    label: 'VIN.WA,  Gielda Praw Majatkowych Vindexus Spolka Akcyjna',
    name: 'Gielda Praw Majatkowych Vindexus Spolka Akcyjna',
    symbol: 'VIN.WA',
  },
  {
    value: 'VOT.WA',
    label: 'VOT.WA,  Votum S.A.',
    name: 'Votum S.A.',
    symbol: 'VOT.WA',
  },
  {
    value: 'VOX.WA',
    label: 'VOX.WA,  Voxel S.A.',
    name: 'Voxel S.A.',
    symbol: 'VOX.WA',
  },
  {
    value: 'VTI.WA',
    label: 'VTI.WA,  Venture INC ASI S.A.',
    name: 'Venture INC ASI S.A.',
    symbol: 'VTI.WA',
  },
  {
    value: 'VTL.WA',
    label: 'VTL.WA,  Vistal Gdynia S.A.',
    name: 'Vistal Gdynia S.A.',
    symbol: 'VTL.WA',
  },
  {
    value: 'WAS.WA',
    label: 'WAS.WA,  Wasko S.A.',
    name: 'Wasko S.A.',
    symbol: 'WAS.WA',
  },
  {
    value: 'WIK.WA',
    label: 'WIK.WA,  Wikana S.A.',
    name: 'Wikana S.A.',
    symbol: 'WIK.WA',
  },
  {
    value: 'WIS.WA',
    label: 'WIS.WA,  Baltic Bridge Spólka Akcyjna',
    name: 'Baltic Bridge Spólka Akcyjna',
    symbol: 'WIS.WA',
  },
  {
    value: 'WOJ.WA',
    label: 'WOJ.WA,  Wojas S.A.',
    name: 'Wojas S.A.',
    symbol: 'WOJ.WA',
  },
  {
    value: 'WTN.WA',
    label: 'WTN.WA,  Wittchen S.A.',
    name: 'Wittchen S.A.',
    symbol: 'WTN.WA',
  },
  {
    value: 'XTP.WA',
    label: 'XTP.WA,  XTPL S.A.',
    name: 'XTPL S.A.',
    symbol: 'XTP.WA',
  },
  {
    value: 'ZAP.WA',
    label: 'ZAP.WA,  Zaklady Azotowe Pulawy S.A.',
    name: 'Zaklady Azotowe Pulawy S.A.',
    symbol: 'ZAP.WA',
  },
  {
    value: 'ZMT.WA',
    label: 'ZMT.WA,  Zamet S.A.',
    name: 'Zamet S.A.',
    symbol: 'ZMT.WA',
  },
  {
    value: 'ZRE.WA',
    label: 'ZRE.WA,  Zaklad Budowy Maszyn ZREMB - Chojnice S.A.',
    name: 'Zaklad Budowy Maszyn ZREMB - Chojnice S.A.',
    symbol: 'ZRE.WA',
  },
  {
    value: 'ZUE.WA',
    label: 'ZUE.WA,  ZUE S.A.',
    name: 'ZUE S.A.',
    symbol: 'ZUE.WA',
  },
  {
    value: 'ZUK.WA',
    label: 'ZUK.WA,  Zaklady Urzadzen Kotlowych "Staporków" Spólka Akcyjna',
    name: 'Zaklady Urzadzen Kotlowych "Staporków" Spólka Akcyjna',
    symbol: 'ZUK.WA',
  },
  {
    value: 'ZWC.WA',
    label: 'ZWC.WA,  Grupa Zywiec S.A.',
    name: 'Grupa Zywiec S.A.',
    symbol: 'ZWC.WA',
  },
  {
    value: 'JZ',
    label: 'JZ,  Jianzhi Education Technology Group Company Limited',
    name: 'Jianzhi Education Technology Group Company Limited',
    symbol: 'JZ',
  },
  {
    value: 'QBTS',
    label: 'QBTS,  D-Wave Quantum Inc.',
    name: 'D-Wave Quantum Inc.',
    symbol: 'QBTS',
  },
  {
    value: 'SHPH',
    label: 'SHPH,  Shuttle Pharmaceuticals Holdings, Inc.',
    name: 'Shuttle Pharmaceuticals Holdings, Inc.',
    symbol: 'SHPH',
  },
  {
    value: 'ZFOX',
    label: 'ZFOX,  ZeroFox Holdings, Inc.',
    name: 'ZeroFox Holdings, Inc.',
    symbol: 'ZFOX',
  },
  {
    value: 'FGRIX',
    label: 'FGRIX,  Fidelity Growth and Income Pt',
    name: 'Fidelity Growth and Income Pt',
    symbol: 'FGRIX',
  },
  {
    value: 'ALDLT.PA',
    label: 'ALDLT.PA,  Delta Plus Group',
    name: 'Delta Plus Group',
    symbol: 'ALDLT.PA',
  },
  {
    value: 'AZLCZ',
    label: 'AZLCZ,  Aztec Land and Cattle Company, Limited',
    name: 'Aztec Land and Cattle Company, Limited',
    symbol: 'AZLCZ',
  },
  {
    value: 'JFBR',
    label: "JFBR,  Jeffs' Brands Ltd",
    name: "Jeffs' Brands Ltd",
    symbol: 'JFBR',
  },
  {
    value: 'WLDS',
    label: 'WLDS,  Wearable Devices Ltd.',
    name: 'Wearable Devices Ltd.',
    symbol: 'WLDS',
  },
  {
    value: 'CZG.PR',
    label: 'CZG.PR,  Colt CZ Group SE',
    name: 'Colt CZ Group SE',
    symbol: 'CZG.PR',
  },
  {
    value: 'KOFOL.PR',
    label: 'KOFOL.PR,  Kofola CeskoSlovensko a.s.',
    name: 'Kofola CeskoSlovensko a.s.',
    symbol: 'KOFOL.PR',
  },
  {
    value: 'LNKB',
    label: 'LNKB,  LINKBANCORP, Inc.',
    name: 'LINKBANCORP, Inc.',
    symbol: 'LNKB',
  },
  {
    value: 'WFMIX',
    label: 'WFMIX,  Allspring Special Mid Cap Value Fund Insti Class',
    name: 'Allspring Special Mid Cap Value Fund Insti Class',
    symbol: 'WFMIX',
  },
  {
    value: 'BLLD',
    label: 'BLLD,  JPMorgan Sustainable Infrastructure ETF',
    name: 'JPMorgan Sustainable Infrastructure ETF',
    symbol: 'BLLD',
  },
  {
    value: 'EMCH',
    label: 'EMCH,  Emerge EMPWR Sustainable Emerging Markets Equity ETF',
    name: 'Emerge EMPWR Sustainable Emerging Markets Equity ETF',
    symbol: 'EMCH',
  },
  {
    value: 'PR',
    label: 'PR,  Permian Resources Corporation',
    name: 'Permian Resources Corporation',
    symbol: 'PR',
  },
  {
    value: 'MODG',
    label: 'MODG,  Topgolf Callaway Brands Corp.',
    name: 'Topgolf Callaway Brands Corp.',
    symbol: 'MODG',
  },
  {
    value: 'THRD',
    label: 'THRD,  Third Harmonic Bio, Inc.',
    name: 'Third Harmonic Bio, Inc.',
    symbol: 'THRD',
  },
  {
    value: 'CRBG',
    label: 'CRBG,  Corebridge Financial, Inc.',
    name: 'Corebridge Financial, Inc.',
    symbol: 'CRBG',
  },
  {
    value: 'NXL',
    label: 'NXL,  Nexalin Technology, Inc.',
    name: 'Nexalin Technology, Inc.',
    symbol: 'NXL',
  },
  {
    value: 'IVVD',
    label: 'IVVD,  Invivyd, Inc.',
    name: 'Invivyd, Inc.',
    symbol: 'IVVD',
  },
  {
    value: 'BBKCF',
    label: 'BBKCF,  BIGG Digital Assets Inc.',
    name: 'BIGG Digital Assets Inc.',
    symbol: 'BBKCF',
  },
  {
    value: 'YOU.DE',
    label: 'YOU.DE,  ABOUT YOU Holding AG',
    name: 'ABOUT YOU Holding AG',
    symbol: 'YOU.DE',
  },
  {
    value: 'RUM',
    label: 'RUM,  Rumble Inc.',
    name: 'Rumble Inc.',
    symbol: 'RUM',
  },
  {
    value: 'AMPX',
    label: 'AMPX,  Amprius Technologies, Inc.',
    name: 'Amprius Technologies, Inc.',
    symbol: 'AMPX',
  },
  {
    value: 'GLSTU',
    label: 'GLSTU,  Global Star Acquisition, Inc.',
    name: 'Global Star Acquisition, Inc.',
    symbol: 'GLSTU',
  },
  {
    value: 'YOSH',
    label: 'YOSH,  Yoshiharu Global Co.',
    name: 'Yoshiharu Global Co.',
    symbol: 'YOSH',
  },
  {
    value: '0P00019A9Z.F',
    label: '0P00019A9Z.F,  Pictet TR - Atlas P EUR',
    name: 'Pictet TR - Atlas P EUR',
    symbol: '0P00019A9Z.F',
  },
  {
    value: '0P00006DAQ.F',
    label: '0P00006DAQ.F,  Fidelity Funds - US High Yield Fund A-Acc-EUR',
    name: 'Fidelity Funds - US High Yield Fund A-Acc-EUR',
    symbol: '0P00006DAQ.F',
  },
  {
    value: '0P0000IRFW.F',
    label: '0P0000IRFW.F,  Robeco Sustainable Global Stars Equities D EUR',
    name: 'Robeco Sustainable Global Stars Equities D EUR',
    symbol: '0P0000IRFW.F',
  },
  {
    value: '0P0000PTZO.F',
    label: '0P0000PTZO.F,  Pictet - Global Environmental Opportunities P EUR',
    name: 'Pictet - Global Environmental Opportunities P EUR',
    symbol: '0P0000PTZO.F',
  },
  {
    value: '0P0001DFNR.F',
    label: '0P0001DFNR.F,  M&G (Lux) Emerging Markets Bond Fund A EUR Acc',
    name: 'M&G (Lux) Emerging Markets Bond Fund A EUR Acc',
    symbol: '0P0001DFNR.F',
  },
  {
    value: 'CUR-USD',
    label: 'CUR-USD,  ',
    name: '',
    symbol: 'CUR-USD',
  },
  {
    value: 'VCMDX',
    label: 'VCMDX,  Vanguard Commodity Strategy Fund Admiral Shares',
    name: 'Vanguard Commodity Strategy Fund Admiral Shares',
    symbol: 'VCMDX',
  },
  {
    value: 'JOR.V',
    label: 'JOR.V,  Jourdan Resources Inc.',
    name: 'Jourdan Resources Inc.',
    symbol: 'JOR.V',
  },
  {
    value: 'NEV.V',
    label: 'NEV.V,  Nevada Sunrise Metals Corporation',
    name: 'Nevada Sunrise Metals Corporation',
    symbol: 'NEV.V',
  },
  {
    value: 'MMGR-B.ST',
    label: 'MMGR-B.ST,  Momentum Group AB (publ)',
    name: 'Momentum Group AB (publ)',
    symbol: 'MMGR-B.ST',
  },
  {
    value: 'QOMOU',
    label: 'QOMOU,  Qomolangma Acquisition Corp.',
    name: 'Qomolangma Acquisition Corp.',
    symbol: 'QOMOU',
  },
  {
    value: 'LASE',
    label: 'LASE,  Laser Photonics Corporation',
    name: 'Laser Photonics Corporation',
    symbol: 'LASE',
  },
  {
    value: 'XSEP',
    label:
      'XSEP,  FT Cboe Vest U.S. Equity Enhance & Moderate Buffer ETF - September',
    name: 'FT Cboe Vest U.S. Equity Enhance & Moderate Buffer ETF - September',
    symbol: 'XSEP',
  },
  {
    value: 'XC',
    label: 'XC,  WisdomTree Emerging Markets ex-China Fund',
    name: 'WisdomTree Emerging Markets ex-China Fund',
    symbol: 'XC',
  },
  {
    value: 'SDGS',
    label: 'SDGS,  Newday Sustainable Development Equity ETF',
    name: 'Newday Sustainable Development Equity ETF',
    symbol: 'SDGS',
  },
  {
    value: 'THLV',
    label: 'THLV,  Thor Low Volatility ETF',
    name: 'Thor Low Volatility ETF',
    symbol: 'THLV',
  },
  {
    value: 'TAFI',
    label: 'TAFI,  Ab Tax-Aware Short Duration ETF',
    name: 'Ab Tax-Aware Short Duration ETF',
    symbol: 'TAFI',
  },
  {
    value: 'YEAR',
    label: 'YEAR,  Ab Ultra Short Income ETF',
    name: 'Ab Ultra Short Income ETF',
    symbol: 'YEAR',
  },
  {
    value: 'DEFI',
    label: 'DEFI,  Hashdex Bitcoin Futures ETF',
    name: 'Hashdex Bitcoin Futures ETF',
    symbol: 'DEFI',
  },
  {
    value: 'KDIV',
    label: 'KDIV,  Kraneshares S&P Pan Asia Dividend Aristocrats Index ETF',
    name: 'Kraneshares S&P Pan Asia Dividend Aristocrats Index ETF',
    symbol: 'KDIV',
  },
  {
    value: 'OAEM',
    label: 'OAEM,  OneAscent Emerging Markets ETF',
    name: 'OneAscent Emerging Markets ETF',
    symbol: 'OAEM',
  },
  {
    value: 'OAIM',
    label: 'OAIM,  OneAscent International Equity ETF',
    name: 'OneAscent International Equity ETF',
    symbol: 'OAIM',
  },
  {
    value: 'STRV',
    label: 'STRV,  Strive 500 ETF',
    name: 'Strive 500 ETF',
    symbol: 'STRV',
  },
  {
    value: 'XFIV',
    label:
      'XFIV,  Bondbloxx Bloomberg Five Year Target Duration US Treasury ETF',
    name: 'Bondbloxx Bloomberg Five Year Target Duration US Treasury ETF',
    symbol: 'XFIV',
  },
  {
    value: 'XHLF',
    label:
      'XHLF,  Bondbloxx Bloomberg Six Month Target Duration US Treasury ETF',
    name: 'Bondbloxx Bloomberg Six Month Target Duration US Treasury ETF',
    symbol: 'XHLF',
  },
  {
    value: 'XSVN',
    label:
      'XSVN,  Bondbloxx Bloomberg Seven Year Target Duration US Treasury ETF',
    name: 'Bondbloxx Bloomberg Seven Year Target Duration US Treasury ETF',
    symbol: 'XSVN',
  },
  {
    value: 'XTRE',
    label:
      'XTRE,  Bondbloxx Bloomberg Three Year Target Duration US Treasury ETF',
    name: 'Bondbloxx Bloomberg Three Year Target Duration US Treasury ETF',
    symbol: 'XTRE',
  },
  {
    value: 'XTWO',
    label:
      'XTWO,  Bondbloxx Bloomberg Two Year Target Duration US Treasury ETF',
    name: 'Bondbloxx Bloomberg Two Year Target Duration US Treasury ETF',
    symbol: 'XTWO',
  },
  {
    value: 'XTWY',
    label:
      'XTWY,  Bondbloxx Bloomberg Twenty Year Target Duration US Treasury ETF',
    name: 'Bondbloxx Bloomberg Twenty Year Target Duration US Treasury ETF',
    symbol: 'XTWY',
  },
  {
    value: 'CCSO',
    label: 'CCSO,  Carbon Collective Climate Solutions U.S. Equity ETF',
    name: 'Carbon Collective Climate Solutions U.S. Equity ETF',
    symbol: 'CCSO',
  },
  {
    value: 'BHVN',
    label: 'BHVN,  Biohaven Ltd.',
    name: 'Biohaven Ltd.',
    symbol: 'BHVN',
  },
  {
    value: 'AQUNU',
    label: 'AQUNU,  Aquaron Acquisition Corp.',
    name: 'Aquaron Acquisition Corp.',
    symbol: 'AQUNU',
  },
  {
    value: '2422.HK',
    label: '2422.HK,  Rego Interactive Co., Ltd',
    name: 'Rego Interactive Co., Ltd',
    symbol: '2422.HK',
  },
  {
    value: 'VMLUX',
    label: 'VMLUX,  Vanguard Limited-Term Tax-Exempt Fd Admiral Shs',
    name: 'Vanguard Limited-Term Tax-Exempt Fd Admiral Shs',
    symbol: 'VMLUX',
  },
  {
    value: 'VWLUX',
    label: 'VWLUX,  Vanguard Long-Term Tax-Exempt Fd Admiral Shs',
    name: 'Vanguard Long-Term Tax-Exempt Fd Admiral Shs',
    symbol: 'VWLUX',
  },
  {
    value: 'BIKE.DE',
    label: 'BIKE.DE,  Bike24 Holding AG',
    name: 'Bike24 Holding AG',
    symbol: 'BIKE.DE',
  },
  {
    value: 'GGE',
    label: 'GGE,  Green Giant Inc.',
    name: 'Green Giant Inc.',
    symbol: 'GGE',
  },
  {
    value: 'MII.L',
    label: 'MII.L,  Milton Capital Plc',
    name: 'Milton Capital Plc',
    symbol: 'MII.L',
  },
  {
    value: 'ALPX',
    label: 'ALPX,  Alopexx, Inc.',
    name: 'Alopexx, Inc.',
    symbol: 'ALPX',
  },
  {
    value: 'ACG.L',
    label: 'ACG.L,  ACG Acquisition Company Limited',
    name: 'ACG Acquisition Company Limited',
    symbol: 'ACG.L',
  },
  {
    value: 'VOXR',
    label: 'VOXR,  Vox Royalty Corp.',
    name: 'Vox Royalty Corp.',
    symbol: 'VOXR',
  },
  {
    value: 'MPTI',
    label: 'MPTI,  M-tron Industries, Inc.',
    name: 'M-tron Industries, Inc.',
    symbol: 'MPTI',
  },
  {
    value: 'ELBM',
    label: 'ELBM,  Electra Battery Materials Corporation',
    name: 'Electra Battery Materials Corporation',
    symbol: 'ELBM',
  },
  {
    value: 'NAESX',
    label: 'NAESX,  Vanguard Index Trust Small-Cap Index Fund',
    name: 'Vanguard Index Trust Small-Cap Index Fund',
    symbol: 'NAESX',
  },
  {
    value: 'VBTLX',
    label: 'VBTLX,  Vanguard Total Bond Market Index Fund Admiral Shares',
    name: 'Vanguard Total Bond Market Index Fund Admiral Shares',
    symbol: 'VBTLX',
  },
  {
    value: 'AVES',
    label: 'AVES,  Avantis Emerging Markets Value ETF',
    name: 'Avantis Emerging Markets Value ETF',
    symbol: 'AVES',
  },
  {
    value: 'DKUPL.PA',
    label: 'DKUPL.PA,  ADLPartner SA',
    name: 'ADLPartner SA',
    symbol: 'DKUPL.PA',
  },
  {
    value: 'QQQS',
    label: 'QQQS,  Invesco NASDAQ Future Gen 200 ETF',
    name: 'Invesco NASDAQ Future Gen 200 ETF',
    symbol: 'QQQS',
  },
  {
    value: 'SDSI',
    label: 'SDSI,  American Century Short Duration Strategic Income ETF',
    name: 'American Century Short Duration Strategic Income ETF',
    symbol: 'SDSI',
  },
  {
    value: 'CTM',
    label: 'CTM,  Castellum, Inc.',
    name: 'Castellum, Inc.',
    symbol: 'CTM',
  },
  {
    value: 'MODL',
    label: 'MODL,  Victoryshares Westend U.S. Sector ETF',
    name: 'Victoryshares Westend U.S. Sector ETF',
    symbol: 'MODL',
  },
  {
    value: 'FOXO',
    label: 'FOXO,  FOXO Technologies Inc.',
    name: 'FOXO Technologies Inc.',
    symbol: 'FOXO',
  },
  {
    value: 'BOOZT-DKK.CO',
    label: 'BOOZT-DKK.CO,  Boozt AB (publ)',
    name: 'Boozt AB (publ)',
    symbol: 'BOOZT-DKK.CO',
  },
  {
    value: 'CARL-A.CO',
    label: 'CARL-A.CO,  Carlsberg A/S',
    name: 'Carlsberg A/S',
    symbol: 'CARL-A.CO',
  },
  {
    value: 'AGF-B.CO',
    label: 'AGF-B.CO,  AGF A/S',
    name: 'AGF A/S',
    symbol: 'AGF-B.CO',
  },
  {
    value: 'CPHCAP-PREF.CO',
    label: 'CPHCAP-PREF.CO,  Copenhagen Capital A/S',
    name: 'Copenhagen Capital A/S',
    symbol: 'CPHCAP-PREF.CO',
  },
  {
    value: 'GREENM.CO',
    label: 'GREENM.CO,  GreenMobility A/S',
    name: 'GreenMobility A/S',
    symbol: 'GREENM.CO',
  },
  {
    value: 'STRINV.CO',
    label: 'STRINV.CO,  Strategic Investments A/S',
    name: 'Strategic Investments A/S',
    symbol: 'STRINV.CO',
  },
  {
    value: '001213.SZ',
    label: '001213.SZ,  China Railway Special Cargo Logistics Co., Ltd.',
    name: 'China Railway Special Cargo Logistics Co., Ltd.',
    symbol: '001213.SZ',
  },
  {
    value: '001318.SZ',
    label: '001318.SZ,  Jiangxi Sunshine Dairy Co., Ltd.',
    name: 'Jiangxi Sunshine Dairy Co., Ltd.',
    symbol: '001318.SZ',
  },
  {
    value: 'AXAC',
    label: 'AXAC,  AXIOS Sustainable Growth Acquisition Corporation',
    name: 'AXIOS Sustainable Growth Acquisition Corporation',
    symbol: 'AXAC',
  },
  {
    value: 'BBUC',
    label: 'BBUC,  Brookfield Business Corporation',
    name: 'Brookfield Business Corporation',
    symbol: 'BBUC',
  },
  {
    value: 'BYN',
    label: 'BYN,  Banyan Acquisition Corporation',
    name: 'Banyan Acquisition Corporation',
    symbol: 'BYN',
  },
  {
    value: 'DMA',
    label: 'DMA,  Destra Multi-Alternative Fund',
    name: 'Destra Multi-Alternative Fund',
    symbol: 'DMA',
  },
  {
    value: 'DO',
    label: 'DO,  Diamond Offshore Drilling, Inc.',
    name: 'Diamond Offshore Drilling, Inc.',
    symbol: 'DO',
  },
  {
    value: 'ECAT',
    label: 'ECAT,  BlackRock ESG Capital Allocation Trust',
    name: 'BlackRock ESG Capital Allocation Trust',
    symbol: 'ECAT',
  },
  {
    value: 'EICA',
    label: 'EICA,  Eagle Point Income Company Inc.',
    name: 'Eagle Point Income Company Inc.',
    symbol: 'EICA',
  },
  {
    value: 'GAQ',
    label: 'GAQ,  Generation Asia I Acquisition Limited',
    name: 'Generation Asia I Acquisition Limited',
    symbol: 'GAQ',
  },
  {
    value: 'GUG',
    label: 'GUG,  Guggenheim Active Allocation Fund',
    name: 'Guggenheim Active Allocation Fund',
    symbol: 'GUG',
  },
  {
    value: 'HMA',
    label: 'HMA,  Heartland Media Acquisition Corp.',
    name: 'Heartland Media Acquisition Corp.',
    symbol: 'HMA',
  },
  {
    value: 'ICNC',
    label: 'ICNC,  Iconic Sports Acquisition Corp.',
    name: 'Iconic Sports Acquisition Corp.',
    symbol: 'ICNC',
  },
  {
    value: 'LCW',
    label: 'LCW,  Learn CW Investment Corporation',
    name: 'Learn CW Investment Corporation',
    symbol: 'LCW',
  },
  {
    value: 'LVWR',
    label: 'LVWR,  LiveWire Group, Inc.',
    name: 'LiveWire Group, Inc.',
    symbol: 'LVWR',
  },
  {
    value: 'MBSC',
    label: 'MBSC,  M3-Brigade Acquisition III Corp.',
    name: 'M3-Brigade Acquisition III Corp.',
    symbol: 'MBSC',
  },
  {
    value: 'MEGI',
    label: 'MEGI,  MainStay CBRE Global Infrastructure Megatrends Fund',
    name: 'MainStay CBRE Global Infrastructure Megatrends Fund',
    symbol: 'MEGI',
  },
  {
    value: 'NMAI',
    label: 'NMAI,  Nuveen Multi-Asset Income Fund',
    name: 'Nuveen Multi-Asset Income Fund',
    symbol: 'NMAI',
  },
  {
    value: 'NOTE',
    label: 'NOTE,  FiscalNote Holdings, Inc.',
    name: 'FiscalNote Holdings, Inc.',
    symbol: 'NOTE',
  },
  {
    value: 'NPFD',
    label: 'NPFD,  Nuveen Variable Rate Preferred & Income Fund',
    name: 'Nuveen Variable Rate Preferred & Income Fund',
    symbol: 'NPFD',
  },
  {
    value: 'PAXS',
    label: 'PAXS,  PIMCO Access Income Fund',
    name: 'PIMCO Access Income Fund',
    symbol: 'PAXS',
  },
  {
    value: 'PGSS',
    label: 'PGSS,  Pegasus Digital Mobility Acquisition Corp.',
    name: 'Pegasus Digital Mobility Acquisition Corp.',
    symbol: 'PGSS',
  },
  {
    value: 'PRE',
    label: 'PRE,  Prenetics Global Limited',
    name: 'Prenetics Global Limited',
    symbol: 'PRE',
  },
  {
    value: 'RBCP',
    label: 'RBCP,  RBC Bearings Incorporated',
    name: 'RBC Bearings Incorporated',
    symbol: 'RBCP',
  },
  {
    value: 'RBT',
    label: 'RBT,  Rubicon Technologies, Inc.',
    name: 'Rubicon Technologies, Inc.',
    symbol: 'RBT',
  },
  {
    value: 'RLTY',
    label: 'RLTY,  Cohen & Steers Real Estate Opportunities Income Fund',
    name: 'Cohen & Steers Real Estate Opportunities Income Fund',
    symbol: 'RLTY',
  },
  {
    value: 'RMMZ',
    label: 'RMMZ,  RiverNorth Managed Duration Municipal Income Fund II, Inc.',
    name: 'RiverNorth Managed Duration Municipal Income Fund II, Inc.',
    symbol: 'RMMZ',
  },
  {
    value: '601279.SS',
    label: '601279.SS,  Changchun Engley Automobile Industry Co.,Ltd.',
    name: 'Changchun Engley Automobile Industry Co.,Ltd.',
    symbol: '601279.SS',
  },
  {
    value: '603324.SS',
    label: '603324.SS,  Shanghai Sheng Jian Environment Technology Co., Ltd.',
    name: 'Shanghai Sheng Jian Environment Technology Co., Ltd.',
    symbol: '603324.SS',
  },
  {
    value: '603836.SS',
    label: '603836.SS,  Bondex Supply Chain Management Co., Ltd.',
    name: 'Bondex Supply Chain Management Co., Ltd.',
    symbol: '603836.SS',
  },
  {
    value: '605016.SS',
    label: '605016.SS,  Shandong Bailong Chuangyuan Bio-Tech Co., Ltd.',
    name: 'Shandong Bailong Chuangyuan Bio-Tech Co., Ltd.',
    symbol: '605016.SS',
  },
  {
    value: '605060.SS',
    label: '605060.SS,  Allied Machinery Co., Ltd.',
    name: 'Allied Machinery Co., Ltd.',
    symbol: '605060.SS',
  },
  {
    value: '605080.SS',
    label: '605080.SS,  Zhejiang Natural Outdoor Goods Inc.',
    name: 'Zhejiang Natural Outdoor Goods Inc.',
    symbol: '605080.SS',
  },
  {
    value: '605089.SS',
    label: '605089.SS,  Suzhou Weizhixiang Food Co., Ltd.',
    name: 'Suzhou Weizhixiang Food Co., Ltd.',
    symbol: '605089.SS',
  },
  {
    value: '605090.SS',
    label: '605090.SS,  Jiangxi Jovo Energy Co., Ltd',
    name: 'Jiangxi Jovo Energy Co., Ltd',
    symbol: '605090.SS',
  },
  {
    value: '605098.SS',
    label: '605098.SS,  Shanghai Action Education Technology CO.,LTD.',
    name: 'Shanghai Action Education Technology CO.,LTD.',
    symbol: '605098.SS',
  },
  {
    value: '605122.SS',
    label: '605122.SS,  Chongqing Sifang New Material Co., Ltd.',
    name: 'Chongqing Sifang New Material Co., Ltd.',
    symbol: '605122.SS',
  },
  {
    value: '605133.SS',
    label: '605133.SS,  Jiangsu Rongtai Industry Co., Ltd.',
    name: 'Jiangsu Rongtai Industry Co., Ltd.',
    symbol: '605133.SS',
  },
  {
    value: '605180.SS',
    label: '605180.SS,  ZheJiang HuaSheng Technology Co.,Ltd',
    name: 'ZheJiang HuaSheng Technology Co.,Ltd',
    symbol: '605180.SS',
  },
  {
    value: '605189.SS',
    label: '605189.SS,  Wuhu Fuchun Dye and Weave Co.,Ltd.',
    name: 'Wuhu Fuchun Dye and Weave Co.,Ltd.',
    symbol: '605189.SS',
  },
  {
    value: '605196.SS',
    label: '605196.SS,  Hebei Huatong Wires and Cables Group Co., Ltd.',
    name: 'Hebei Huatong Wires and Cables Group Co., Ltd.',
    symbol: '605196.SS',
  },
  {
    value: '605208.SS',
    label: '605208.SS,  Shanghai Yongmaotai Automotive Technology Co., Ltd.',
    name: 'Shanghai Yongmaotai Automotive Technology Co., Ltd.',
    symbol: '605208.SS',
  },
  {
    value: '605268.SS',
    label: '605268.SS,  Wangli Security & Surveillance Product Co., Ltd',
    name: 'Wangli Security & Surveillance Product Co., Ltd',
    symbol: '605268.SS',
  },
  {
    value: '605286.SS',
    label: '605286.SS,  Jiangsu Tongli Risheng Machinery Co., Ltd.',
    name: 'Jiangsu Tongli Risheng Machinery Co., Ltd.',
    symbol: '605286.SS',
  },
  {
    value: '605289.SS',
    label: '605289.SS,  Shanghai Luoman Lighting Technologies Inc.',
    name: 'Shanghai Luoman Lighting Technologies Inc.',
    symbol: '605289.SS',
  },
  {
    value: '605298.SS',
    label: '605298.SS,  Jiangsu Bide Science and Technology Co., Ltd.',
    name: 'Jiangsu Bide Science and Technology Co., Ltd.',
    symbol: '605298.SS',
  },
  {
    value: '605300.SS',
    label: '605300.SS,  Jiahe Foods Industry Co., Ltd.',
    name: 'Jiahe Foods Industry Co., Ltd.',
    symbol: '605300.SS',
  },
  {
    value: '605303.SS',
    label: '605303.SS,  Hangzhou Landscaping Incorporated',
    name: 'Hangzhou Landscaping Incorporated',
    symbol: '605303.SS',
  },
  {
    value: '605305.SS',
    label: '605305.SS,  Ficont Industry (Beijing) Co., Ltd.',
    name: 'Ficont Industry (Beijing) Co., Ltd.',
    symbol: '605305.SS',
  },
  {
    value: '605319.SS',
    label: '605319.SS,  Wuxi Zhenhua Auto Parts Co., Ltd.',
    name: 'Wuxi Zhenhua Auto Parts Co., Ltd.',
    symbol: '605319.SS',
  },
  {
    value: '605339.SS',
    label: '605339.SS,  Namchow Food Group (Shanghai) Co., Ltd.',
    name: 'Namchow Food Group (Shanghai) Co., Ltd.',
    symbol: '605339.SS',
  },
  {
    value: '605378.SS',
    label: '605378.SS,  Zhejiang MustangBattery Co.,Ltd',
    name: 'Zhejiang MustangBattery Co.,Ltd',
    symbol: '605378.SS',
  },
  {
    value: '605389.SS',
    label: '605389.SS,  Jiangsu Changling Hydraulic Co., Ltd',
    name: 'Jiangsu Changling Hydraulic Co., Ltd',
    symbol: '605389.SS',
  },
  {
    value: '605488.SS',
    label: '605488.SS,  Zhejiang Fulai New Material Co.,Ltd.',
    name: 'Zhejiang Fulai New Material Co.,Ltd.',
    symbol: '605488.SS',
  },
  {
    value: '688063.SS',
    label: '688063.SS,  Pylon Technologies Co., Ltd.',
    name: 'Pylon Technologies Co., Ltd.',
    symbol: '688063.SS',
  },
  {
    value: '688076.SS',
    label: '688076.SS,  Sinopep-Allsino Bio Pharmaceutical Co.,Ltd.',
    name: 'Sinopep-Allsino Bio Pharmaceutical Co.,Ltd.',
    symbol: '688076.SS',
  },
  {
    value: '688079.SS',
    label: '688079.SS,  Hangzhou MDK Opto Electronics Co., Ltd',
    name: 'Hangzhou MDK Opto Electronics Co., Ltd',
    symbol: '688079.SS',
  },
  {
    value: '688092.SS',
    label: '688092.SS,  Hangzhou IECHO Science & Technology Co., Ltd.',
    name: 'Hangzhou IECHO Science & Technology Co., Ltd.',
    symbol: '688092.SS',
  },
  {
    value: '688097.SS',
    label: '688097.SS,  BOZHON Precision Industry Technology Co.,Ltd',
    name: 'BOZHON Precision Industry Technology Co.,Ltd',
    symbol: '688097.SS',
  },
  {
    value: '688109.SS',
    label: '688109.SS,  Hangzhou Pinming Software Co., Ltd',
    name: 'Hangzhou Pinming Software Co., Ltd',
    symbol: '688109.SS',
  },
  {
    value: '688113.SS',
    label: '688113.SS,  Jiangsu Liance Electromechanical Technology Co., Ltd.',
    name: 'Jiangsu Liance Electromechanical Technology Co., Ltd.',
    symbol: '688113.SS',
  },
  {
    value: '688117.SS',
    label: '688117.SS,  ChengDu ShengNuo Biotec Co.,Ltd.',
    name: 'ChengDu ShengNuo Biotec Co.,Ltd.',
    symbol: '688117.SS',
  },
  {
    value: '688131.SS',
    label: '688131.SS,  Shanghai Haoyuan Chemexpress Co., Ltd.',
    name: 'Shanghai Haoyuan Chemexpress Co., Ltd.',
    symbol: '688131.SS',
  },
  {
    value: '688260.SS',
    label: '688260.SS,  Suzhou Gyz Electronic Technology Co.,Ltd',
    name: 'Suzhou Gyz Electronic Technology Co.,Ltd',
    symbol: '688260.SS',
  },
  {
    value: '688315.SS',
    label: '688315.SS,  Novogene Co., Ltd.',
    name: 'Novogene Co., Ltd.',
    symbol: '688315.SS',
  },
  {
    value: '688316.SS',
    label: '688316.SS,  Beijing Qingyun Technology Co., Ltd.',
    name: 'Beijing Qingyun Technology Co., Ltd.',
    symbol: '688316.SS',
  },
  {
    value: '688317.SS',
    label: '688317.SS,  Shanghai ZJ Bio-Tech Co., Ltd.',
    name: 'Shanghai ZJ Bio-Tech Co., Ltd.',
    symbol: '688317.SS',
  },
  {
    value: '688319.SS',
    label: '688319.SS,  Chengdu Olymvax Biopharmaceuticals Inc.',
    name: 'Chengdu Olymvax Biopharmaceuticals Inc.',
    symbol: '688319.SS',
  },
  {
    value: '688323.SS',
    label: '688323.SS,  Rayitek Hi-Tech Film Company Ltd., Shenzhen',
    name: 'Rayitek Hi-Tech Film Company Ltd., Shenzhen',
    symbol: '688323.SS',
  },
  {
    value: '688329.SS',
    label: '688329.SS,  Suzhou Iron Technology CO.,LTD.',
    name: 'Suzhou Iron Technology CO.,LTD.',
    symbol: '688329.SS',
  },
  {
    value: '688355.SS',
    label: '688355.SS,  Suzhou Mingzhi Technology Co., Ltd.',
    name: 'Suzhou Mingzhi Technology Co., Ltd.',
    symbol: '688355.SS',
  },
  {
    value: '688359.SS',
    label: '688359.SS,  Guangzhou Sanfu New Materials Technology Co.,Ltd',
    name: 'Guangzhou Sanfu New Materials Technology Co.,Ltd',
    symbol: '688359.SS',
  },
  {
    value: '688383.SS',
    label: '688383.SS,  Shenzhen Xinyichang Technology Co., Ltd.',
    name: 'Shenzhen Xinyichang Technology Co., Ltd.',
    symbol: '688383.SS',
  },
  {
    value: '688395.SS',
    label: '688395.SS,  Shenzhen Sine Electric Co.,Ltd.',
    name: 'Shenzhen Sine Electric Co.,Ltd.',
    symbol: '688395.SS',
  },
  {
    value: '688456.SS',
    label: '688456.SS,  GRIPM Advanced Materials Co., Ltd.',
    name: 'GRIPM Advanced Materials Co., Ltd.',
    symbol: '688456.SS',
  },
  {
    value: '688468.SS',
    label: '688468.SS,  Chemclin Diagnostics Co., Ltd.',
    name: 'Chemclin Diagnostics Co., Ltd.',
    symbol: '688468.SS',
  },
  {
    value: '688533.SS',
    label: '688533.SS,  Suzhou Sonavox Electronics Co., Ltd.',
    name: 'Suzhou Sonavox Electronics Co., Ltd.',
    symbol: '688533.SS',
  },
  {
    value: '688565.SS',
    label:
      '688565.SS,  Zhejiang Haiyan Power System Resources Environmental Technology Co.,Ltd.',
    name: 'Zhejiang Haiyan Power System Resources Environmental Technology Co.,Ltd.',
    symbol: '688565.SS',
  },
  {
    value: '688575.SS',
    label: '688575.SS,  Shenzhen YHLO Biotech Co., Ltd.',
    name: 'Shenzhen YHLO Biotech Co., Ltd.',
    symbol: '688575.SS',
  },
  {
    value: '688606.SS',
    label: '688606.SS,  Hangzhou Alltest Biotech Co., Ltd.',
    name: 'Hangzhou Alltest Biotech Co., Ltd.',
    symbol: '688606.SS',
  },
  {
    value: '688609.SS',
    label: '688609.SS,  Unionman Technology Co., Ltd.',
    name: 'Unionman Technology Co., Ltd.',
    symbol: '688609.SS',
  },
  {
    value: '688611.SS',
    label: '688611.SS,  Hangzhou Kelin Electric Co., Ltd.',
    name: 'Hangzhou Kelin Electric Co., Ltd.',
    symbol: '688611.SS',
  },
  {
    value: '688613.SS',
    label: '688613.SS,  Allgens Medical Technology CO., LTD.',
    name: 'Allgens Medical Technology CO., LTD.',
    symbol: '688613.SS',
  },
  {
    value: '688616.SS',
    label: '688616.SS,  Hangzhou Xili Intelligent Technology Co.,Ltd',
    name: 'Hangzhou Xili Intelligent Technology Co.,Ltd',
    symbol: '688616.SS',
  },
  {
    value: '688625.SS',
    label: '688625.SS,  GCH Technology Co., Ltd.',
    name: 'GCH Technology Co., Ltd.',
    symbol: '688625.SS',
  },
  {
    value: '688626.SS',
    label: '688626.SS,  Xiangyu Medical Co.,Ltd',
    name: 'Xiangyu Medical Co.,Ltd',
    symbol: '688626.SS',
  },
  {
    value: '688630.SS',
    label: '688630.SS,  Circuit Fabology Microelectronics Equipment Co.,Ltd.',
    name: 'Circuit Fabology Microelectronics Equipment Co.,Ltd.',
    symbol: '688630.SS',
  },
  {
    value: '688633.SS',
    label: '688633.SS,  Nantong Xingqiu Graphite Co.,Ltd.',
    name: 'Nantong Xingqiu Graphite Co.,Ltd.',
    symbol: '688633.SS',
  },
  {
    value: '688636.SS',
    label: '688636.SS,  Chengdu Zhimingda Electronics Co., Ltd.',
    name: 'Chengdu Zhimingda Electronics Co., Ltd.',
    symbol: '688636.SS',
  },
  {
    value: '688639.SS',
    label: '688639.SS,  Anhui Huaheng Biotechnology Co., Ltd.',
    name: 'Anhui Huaheng Biotechnology Co., Ltd.',
    symbol: '688639.SS',
  },
  {
    value: '688655.SS',
    label: '688655.SS,  Shenzhen Xunjiexing Technology Corp. Ltd.',
    name: 'Shenzhen Xunjiexing Technology Corp. Ltd.',
    symbol: '688655.SS',
  },
  {
    value: '688659.SS',
    label:
      '688659.SS,  Anhui Yuanchen Environmental Protection Science&Technology Co.,Ltd.',
    name: 'Anhui Yuanchen Environmental Protection Science&Technology Co.,Ltd.',
    symbol: '688659.SS',
  },
  {
    value: '688660.SS',
    label: '688660.SS,  Shanghai Electric Wind Power Group Co., Ltd.',
    name: 'Shanghai Electric Wind Power Group Co., Ltd.',
    symbol: '688660.SS',
  },
  {
    value: '688661.SS',
    label: '688661.SS,  Suzhou UIGreen Micro&Nano Technologies Co.,Ltd',
    name: 'Suzhou UIGreen Micro&Nano Technologies Co.,Ltd',
    symbol: '688661.SS',
  },
  {
    value: '688662.SS',
    label: '688662.SS,  Guangdong Fuxin Technology Co., Ltd.',
    name: 'Guangdong Fuxin Technology Co., Ltd.',
    symbol: '688662.SS',
  },
  {
    value: '688663.SS',
    label: '688663.SS,  WindSun Science&Technology Co.,Ltd.',
    name: 'WindSun Science&Technology Co.,Ltd.',
    symbol: '688663.SS',
  },
  {
    value: '688667.SS',
    label: '688667.SS,  Wuhan Lincontrol Automotive Electronics Co., Ltd.',
    name: 'Wuhan Lincontrol Automotive Electronics Co., Ltd.',
    symbol: '688667.SS',
  },
  {
    value: '688676.SS',
    label: '688676.SS,  Hainan Jinpan Smart Technology Co., Ltd.',
    name: 'Hainan Jinpan Smart Technology Co., Ltd.',
    symbol: '688676.SS',
  },
  {
    value: '688677.SS',
    label: '688677.SS,  Qingdao NovelBeam Technology Co.,Ltd.',
    name: 'Qingdao NovelBeam Technology Co.,Ltd.',
    symbol: '688677.SS',
  },
  {
    value: '688682.SS',
    label: '688682.SS,  Shanghai Hollywave Electronic System Co., Ltd.',
    name: 'Shanghai Hollywave Electronic System Co., Ltd.',
    symbol: '688682.SS',
  },
  {
    value: '688683.SS',
    label: '688683.SS,  GuangDong Leary New Material Technology Co.,Ltd',
    name: 'GuangDong Leary New Material Technology Co.,Ltd',
    symbol: '688683.SS',
  },
  {
    value: '688685.SS',
    label:
      '688685.SS,  Jiangsu Maixinlin Aviation Science and Technology Corp.',
    name: 'Jiangsu Maixinlin Aviation Science and Technology Corp.',
    symbol: '688685.SS',
  },
  {
    value: '688696.SS',
    label: '688696.SS,  Chengdu Xgimi Technology Co.,Ltd.',
    name: 'Chengdu Xgimi Technology Co.,Ltd.',
    symbol: '688696.SS',
  },
  {
    value: '688699.SS',
    label: '688699.SS,  Shenzhen Sunmoon Microelectronics Co., Ltd',
    name: 'Shenzhen Sunmoon Microelectronics Co., Ltd',
    symbol: '688699.SS',
  },
  {
    value: '688777.SS',
    label: '688777.SS,  Zhejiang Supcon Technology Co., Ltd.',
    name: 'Zhejiang Supcon Technology Co., Ltd.',
    symbol: '688777.SS',
  },
  {
    value: '688799.SS',
    label: '688799.SS,  Hunan Warrant Pharmaceutical Co.,Ltd',
    name: 'Hunan Warrant Pharmaceutical Co.,Ltd',
    symbol: '688799.SS',
  },
  {
    value: 'AAC-UN',
    label: 'AAC-UN,  Ares Acquisition Corporation',
    name: 'Ares Acquisition Corporation',
    symbol: 'AAC-UN',
  },
  {
    value: 'AAQC',
    label: 'AAQC,  Accelerate Acquisition Corp.',
    name: 'Accelerate Acquisition Corp.',
    symbol: 'AAQC',
  },
  {
    value: 'AAQC-UN',
    label: 'AAQC-UN,  Accelerate Acquisition Corp.',
    name: 'Accelerate Acquisition Corp.',
    symbol: 'AAQC-UN',
  },
  {
    value: 'AAWH',
    label: 'AAWH,  Ascend Wellness Holdings, Inc.',
    name: 'Ascend Wellness Holdings, Inc.',
    symbol: 'AAWH',
  },
  {
    value: 'ACAC',
    label: 'ACAC,  Acri Capital Acquisition Corporation',
    name: 'Acri Capital Acquisition Corporation',
    symbol: 'ACAC',
  },
  {
    value: 'ACDI-UN',
    label: 'ACDI-UN,  Ascendant Digital Acquisition Corp. III',
    name: 'Ascendant Digital Acquisition Corp. III',
    symbol: 'ACDI-UN',
  },
  {
    value: 'ACRO-UN',
    label: 'ACRO-UN,  Acropolis Infrastructure Acquisition Corp.',
    name: 'Acropolis Infrastructure Acquisition Corp.',
    symbol: 'ACRO-UN',
  },
  {
    value: 'SKE',
    label: 'SKE,  Skeena Resources Limited',
    name: 'Skeena Resources Limited',
    symbol: 'SKE',
  },
  {
    value: 'ACRU',
    label: 'ACRU,  AmeriCrew Inc.',
    name: 'AmeriCrew Inc.',
    symbol: 'ACRU',
  },
  {
    value: 'TFPM',
    label: 'TFPM,  Triple Flag Precious Metals Corp.',
    name: 'Triple Flag Precious Metals Corp.',
    symbol: 'TFPM',
  },
  {
    value: 'TGR',
    label: 'TGR,  Kimbell Tiger Acquisition Corporation',
    name: 'Kimbell Tiger Acquisition Corporation',
    symbol: 'TGR',
  },
  {
    value: 'ACAX',
    label: 'ACAX,  Alset Capital Acquisition Corp.',
    name: 'Alset Capital Acquisition Corp.',
    symbol: 'ACAX',
  },
  {
    value: 'ACAXU',
    label: 'ACAXU,  Alset Capital Acquisition Corp.',
    name: 'Alset Capital Acquisition Corp.',
    symbol: 'ACAXU',
  },
  {
    value: 'ACNT',
    label: 'ACNT,  Ascent Industries Co.',
    name: 'Ascent Industries Co.',
    symbol: 'ACNT',
  },
  {
    value: 'ADALU',
    label: 'ADALU,  Anthemis Digital Acquisitions I Corp',
    name: 'Anthemis Digital Acquisitions I Corp',
    symbol: 'ADALU',
  },
  {
    value: 'ADRA-UN',
    label: 'ADRA-UN,  Adara Acquisition Corp.',
    name: 'Adara Acquisition Corp.',
    symbol: 'ADRA-UN',
  },
  {
    value: 'ADEA',
    label: 'ADEA,  Adeia Inc.',
    name: 'Adeia Inc.',
    symbol: 'ADEA',
  },
  {
    value: 'ADRT-UN',
    label: 'ADRT-UN,  Ault Disruptive Technologies Corporation',
    name: 'Ault Disruptive Technologies Corporation',
    symbol: 'ADRT-UN',
  },
  {
    value: 'AEAEU',
    label: 'AEAEU,  AltEnergy Acquisition Corp.',
    name: 'AltEnergy Acquisition Corp.',
    symbol: 'AEAEU',
  },
  {
    value: 'AGNCL',
    label: 'AGNCL,  AGNC Investment Corp.',
    name: 'AGNC Investment Corp.',
    symbol: 'AGNCL',
  },
  {
    value: 'AHRNU',
    label: 'AHRNU,  Ahren Acquisition Corp.',
    name: 'Ahren Acquisition Corp.',
    symbol: 'AHRNU',
  },
  {
    value: 'ALORU',
    label: 'ALORU,  ALSP Orchid Acquisition Corporation I',
    name: 'ALSP Orchid Acquisition Corporation I',
    symbol: 'ALORU',
  },
  {
    value: 'ALPS',
    label: 'ALPS,  Alpine Summit Energy Partners, Inc.',
    name: 'Alpine Summit Energy Partners, Inc.',
    symbol: 'ALPS',
  },
  {
    value: 'ALSAU',
    label: 'ALSAU,  Alpha Star Acquisition Corporation',
    name: 'Alpha Star Acquisition Corporation',
    symbol: 'ALSAU',
  },
  {
    value: 'AMV',
    label: 'AMV,  Atlis Motor Vehicles, Inc.',
    name: 'Atlis Motor Vehicles, Inc.',
    symbol: 'AMV',
  },
  {
    value: 'INTS',
    label: 'INTS,  Intensity Therapeutics, Inc.',
    name: 'Intensity Therapeutics, Inc.',
    symbol: 'INTS',
  },
  {
    value: 'AOGO',
    label: 'AOGO,  Arogo Capital Acquisition Corp.',
    name: 'Arogo Capital Acquisition Corp.',
    symbol: 'AOGO',
  },
  {
    value: 'AOGOU',
    label: 'AOGOU,  Arogo Capital Acquisition Corp.',
    name: 'Arogo Capital Acquisition Corp.',
    symbol: 'AOGOU',
  },
  {
    value: 'APXIU',
    label: 'APXIU,  APx Acquisition Corp. I',
    name: 'APx Acquisition Corp. I',
    symbol: 'APXIU',
  },
  {
    value: 'ARCKU',
    label: 'ARCKU,  Arbor Rapha Capital Bioholdings Corp. I',
    name: 'Arbor Rapha Capital Bioholdings Corp. I',
    symbol: 'ARCKU',
  },
  {
    value: 'BACK',
    label: 'BACK,  IMAC Holdings, Inc.',
    name: 'IMAC Holdings, Inc.',
    symbol: 'BACK',
  },
  {
    value: 'AGAC',
    label: 'AGAC,  African Gold Acquisition Corporation',
    name: 'African Gold Acquisition Corporation',
    symbol: 'AGAC',
  },
  {
    value: 'AGAC-UN',
    label: 'AGAC-UN,  African Gold Acquisition Corporation',
    name: 'African Gold Acquisition Corporation',
    symbol: 'AGAC-UN',
  },
  {
    value: 'BLEUU',
    label: 'BLEUU,  bleuacacia ltd',
    name: 'bleuacacia ltd',
    symbol: 'BLEUU',
  },
  {
    value: 'BOCNU',
    label: 'BOCNU,  Blue Ocean Acquisition Corp.',
    name: 'Blue Ocean Acquisition Corp.',
    symbol: 'BOCNU',
  },
  {
    value: 'BPACU',
    label: 'BPACU,  Bullpen Parlay Acquisition Company',
    name: 'Bullpen Parlay Acquisition Company',
    symbol: 'BPACU',
  },
  {
    value: 'AGSS',
    label: 'AGSS,  Ameriguard Security Services, Inc.',
    name: 'Ameriguard Security Services, Inc.',
    symbol: 'AGSS',
  },
  {
    value: 'BWAQU',
    label: 'BWAQU,  Blue World Acquisition Corporation',
    name: 'Blue World Acquisition Corporation',
    symbol: 'BWAQU',
  },
  {
    value: 'BYNO',
    label: 'BYNO,  byNordic Acquisition Corporation',
    name: 'byNordic Acquisition Corporation',
    symbol: 'BYNO',
  },
  {
    value: 'CBRGU',
    label: 'CBRGU,  Chain Bridge I',
    name: 'Chain Bridge I',
    symbol: 'CBRGU',
  },
  {
    value: 'CCTSU',
    label: 'CCTSU,  Cactus Acquisition Corp. 1 Limited',
    name: 'Cactus Acquisition Corp. 1 Limited',
    symbol: 'CCTSU',
  },
  {
    value: 'CFFS',
    label: 'CFFS,  CF Acquisition Corp. VII',
    name: 'CF Acquisition Corp. VII',
    symbol: 'CFFS',
  },
  {
    value: 'CFFSU',
    label: 'CFFSU,  CF Acquisition Corp. VII',
    name: 'CF Acquisition Corp. VII',
    symbol: 'CFFSU',
  },
  {
    value: 'CHG',
    label: 'CHG,  LuxUrban Hotels Inc.',
    name: 'LuxUrban Hotels Inc.',
    symbol: 'CHG',
  },
  {
    value: 'CLIN',
    label: 'CLIN,  Clean Earth Acquisitions Corp.',
    name: 'Clean Earth Acquisitions Corp.',
    symbol: 'CLIN',
  },
  {
    value: 'CMCAU',
    label: 'CMCAU,  Capitalworks Emerging Markets Acquisition Corp',
    name: 'Capitalworks Emerging Markets Acquisition Corp',
    symbol: 'CMCAU',
  },
  {
    value: 'AIXN',
    label: 'AIXN,  AiXin Life International, Inc.',
    name: 'AiXin Life International, Inc.',
    symbol: 'AIXN',
  },
  {
    value: 'COMSP',
    label: 'COMSP,  COMSovereign Holding Corp.',
    name: 'COMSovereign Holding Corp.',
    symbol: 'COMSP',
  },
  {
    value: 'DAOOU',
    label: 'DAOOU,  Crypto 1 Acquisition Corp',
    name: 'Crypto 1 Acquisition Corp',
    symbol: 'DAOOU',
  },
  {
    value: 'DHACU',
    label: 'DHACU,  Digital Health Acquisition Corp.',
    name: 'Digital Health Acquisition Corp.',
    symbol: 'DHACU',
  },
  {
    value: 'EMCGR',
    label: 'EMCGR,  Embrace Change Acquisition Corp.',
    name: 'Embrace Change Acquisition Corp.',
    symbol: 'EMCGR',
  },
  {
    value: 'EMCGW',
    label: 'EMCGW,  Embrace Change Acquisition Corp.',
    name: 'Embrace Change Acquisition Corp.',
    symbol: 'EMCGW',
  },
  {
    value: 'EMLDU',
    label: 'EMLDU,  FTAC Emerald Acquisition Corp.',
    name: 'FTAC Emerald Acquisition Corp.',
    symbol: 'EMLDU',
  },
  {
    value: 'ENERU',
    label: 'ENERU,  Accretion Acquisition Corp.',
    name: 'Accretion Acquisition Corp.',
    symbol: 'ENERU',
  },
  {
    value: 'ESACU',
    label: 'ESACU,  ESGEN Acquisition Corporation',
    name: 'ESGEN Acquisition Corporation',
    symbol: 'ESACU',
  },
  {
    value: 'EVGR',
    label: 'EVGR,  Evergreen Corporation',
    name: 'Evergreen Corporation',
    symbol: 'EVGR',
  },
  {
    value: 'FEXDU',
    label: 'FEXDU,  Fintech Ecosystem Development Corp.',
    name: 'Fintech Ecosystem Development Corp.',
    symbol: 'FEXDU',
  },
  {
    value: 'FGMC',
    label: 'FGMC,  FG Merger Corp.',
    name: 'FG Merger Corp.',
    symbol: 'FGMC',
  },
  {
    value: 'FIACU',
    label: 'FIACU,  Focus Impact Acquisition Corp.',
    name: 'Focus Impact Acquisition Corp.',
    symbol: 'FIACU',
  },
  {
    value: 'ALMIC.PA',
    label: 'ALMIC.PA,  Micropole S.A.',
    name: 'Micropole S.A.',
    symbol: 'ALMIC.PA',
  },
  {
    value: 'FLFV',
    label: 'FLFV,  Feutune Light Acquisition Corporation',
    name: 'Feutune Light Acquisition Corporation',
    symbol: 'FLFV',
  },
  {
    value: 'FNVTU',
    label: 'FNVTU,  Finnovate Acquisition Corp.',
    name: 'Finnovate Acquisition Corp.',
    symbol: 'FNVTU',
  },
  {
    value: 'ALNA.L',
    label: 'ALNA.L,  Alina Holdings Plc',
    name: 'Alina Holdings Plc',
    symbol: 'ALNA.L',
  },
  {
    value: 'FRBNU',
    label: 'FRBNU,  Forbion European Acquisition Corp.',
    name: 'Forbion European Acquisition Corp.',
    symbol: 'FRBNU',
  },
  {
    value: 'FRGT',
    label: 'FRGT,  Freight Technologies, Inc.',
    name: 'Freight Technologies, Inc.',
    symbol: 'FRGT',
  },
  {
    value: 'FRMEP',
    label: 'FRMEP,  First Merchants Corporation',
    name: 'First Merchants Corporation',
    symbol: 'FRMEP',
  },
  {
    value: 'FTIIU',
    label: 'FTIIU,  FutureTech II Acquisition Corp.',
    name: 'FutureTech II Acquisition Corp.',
    symbol: 'FTIIU',
  },
  {
    value: 'GDNRU',
    label: 'GDNRU,  Gardiner Healthcare Acquisitions Corp.',
    name: 'Gardiner Healthcare Acquisitions Corp.',
    symbol: 'GDNRU',
  },
  {
    value: 'GEEXU',
    label: 'GEEXU,  Games & Esports Experience Acquisition Corp.',
    name: 'Games & Esports Experience Acquisition Corp.',
    symbol: 'GEEXU',
  },
  {
    value: 'GENQU',
    label: 'GENQU,  Genesis Unicorn Capital Corp.',
    name: 'Genesis Unicorn Capital Corp.',
    symbol: 'GENQU',
  },
  {
    value: 'GFGDU',
    label: 'GFGDU,  The Growth for Good Acquisition Corporation',
    name: 'The Growth for Good Acquisition Corporation',
    symbol: 'GFGDU',
  },
  {
    value: 'GGAAU',
    label: 'GGAAU,  Genesis Growth Tech Acquisition Corp.',
    name: 'Genesis Growth Tech Acquisition Corp.',
    symbol: 'GGAAU',
  },
  {
    value: 'GLLIU',
    label: 'GLLIU,  Globalink Investment Inc.',
    name: 'Globalink Investment Inc.',
    symbol: 'GLLIU',
  },
  {
    value: 'GSRM',
    label: 'GSRM,  GSR II Meteora Acquisition Corp.',
    name: 'GSR II Meteora Acquisition Corp.',
    symbol: 'GSRM',
  },
  {
    value: 'HAIAU',
    label: 'HAIAU,  Healthcare AI Acquisition Corp.',
    name: 'Healthcare AI Acquisition Corp.',
    symbol: 'HAIAU',
  },
  {
    value: 'AMID',
    label: 'AMID,  Argent Mid Cap ETF',
    name: 'Argent Mid Cap ETF',
    symbol: 'AMID',
  },
  {
    value: 'HMAC',
    label: 'HMAC,  Hainan Manaslu Acquisition Corp.',
    name: 'Hainan Manaslu Acquisition Corp.',
    symbol: 'HMAC',
  },
  {
    value: 'HMACR',
    label: 'HMACR,  Hainan Manaslu Acquisition Corp.',
    name: 'Hainan Manaslu Acquisition Corp.',
    symbol: 'HMACR',
  },
  {
    value: 'AMPI-UN',
    label: 'AMPI-UN,  Advanced Merger Partners, Inc',
    name: 'Advanced Merger Partners, Inc',
    symbol: 'AMPI-UN',
  },
  {
    value: 'HMACU',
    label: 'HMACU,  Hainan Manaslu Acquisition Corp.',
    name: 'Hainan Manaslu Acquisition Corp.',
    symbol: 'HMACU',
  },
  {
    value: 'INTEU',
    label: 'INTEU,  Integral Acquisition Corporation 1',
    name: 'Integral Acquisition Corporation 1',
    symbol: 'INTEU',
  },
  {
    value: 'IOACU',
    label: 'IOACU,  Innovative International Acquisition Corp.',
    name: 'Innovative International Acquisition Corp.',
    symbol: 'IOACU',
  },
  {
    value: 'ANAC-UN',
    label: 'ANAC-UN,  Arctos NorthStar Acquisition Corp.',
    name: 'Arctos NorthStar Acquisition Corp.',
    symbol: 'ANAC-UN',
  },
  {
    value: 'IQMDU',
    label: 'IQMDU,  Intelligent Medicine Acquisition Corp.',
    name: 'Intelligent Medicine Acquisition Corp.',
    symbol: 'IQMDU',
  },
  {
    value: 'IVCBU',
    label: 'IVCBU,  Investcorp Europe Acquisition Corp I',
    name: 'Investcorp Europe Acquisition Corp I',
    symbol: 'IVCBU',
  },
  {
    value: 'IVCPU',
    label: 'IVCPU,  Swiftmerge Acquisition Corp.',
    name: 'Swiftmerge Acquisition Corp.',
    symbol: 'IVCPU',
  },
  {
    value: 'JFBRW',
    label: "JFBRW,  Jeffs' Brands Ltd",
    name: "Jeffs' Brands Ltd",
    symbol: 'JFBRW',
  },
  {
    value: 'APACU',
    label: 'APACU,  StoneBridge Acquisition Corporation',
    name: 'StoneBridge Acquisition Corporation',
    symbol: 'APACU',
  },
  {
    value: 'APCA-UN',
    label: 'APCA-UN,  AP Acquisition Corp.',
    name: 'AP Acquisition Corp.',
    symbol: 'APCA-UN',
  },
  {
    value: 'APGN',
    label: 'APGN,  Apexigen, Inc.',
    name: 'Apexigen, Inc.',
    symbol: 'APGN',
  },
  {
    value: 'APGT',
    label: 'APGT,  Appgate, Inc.',
    name: 'Appgate, Inc.',
    symbol: 'APGT',
  },
  {
    value: 'APHD',
    label: 'APHD,  Apogee 21 Holdings, Inc.',
    name: 'Apogee 21 Holdings, Inc.',
    symbol: 'APHD',
  },
  {
    value: 'APN-UN',
    label: 'APN-UN,  Apeiron Capital Investment Corp.',
    name: 'Apeiron Capital Investment Corp.',
    symbol: 'APN-UN',
  },
  {
    value: 'ARIZU',
    label: 'ARIZU,  Arisz Acquisition Corp.',
    name: 'Arisz Acquisition Corp.',
    symbol: 'ARIZU',
  },
  {
    value: 'ATAQ',
    label: 'ATAQ,  Altimar Acquisition Corp. III',
    name: 'Altimar Acquisition Corp. III',
    symbol: 'ATAQ',
  },
  {
    value: 'ATEK-UN',
    label: 'ATEK-UN,  Athena Technology Acquisition Corp. II',
    name: 'Athena Technology Acquisition Corp. II',
    symbol: 'ATEK-UN',
  },
  {
    value: 'AURX',
    label: 'AURX,  Nuo Therapeutics, Inc.',
    name: 'Nuo Therapeutics, Inc.',
    symbol: 'AURX',
  },
  {
    value: 'AVMR',
    label: 'AVMR,  AVRA Medical Robotics, Inc.',
    name: 'AVRA Medical Robotics, Inc.',
    symbol: 'AVMR',
  },
  {
    value: 'BACA-UN',
    label: 'BACA-UN,  Berenson Acquisition Corp. I',
    name: 'Berenson Acquisition Corp. I',
    symbol: 'BACA-UN',
  },
  {
    value: 'BBLR',
    label: 'BBLR,  Bubblr Inc.',
    name: 'Bubblr Inc.',
    symbol: 'BBLR',
  },
  {
    value: 'BFAC-UN',
    label: 'BFAC-UN,  Battery Future Acquisition Corp.',
    name: 'Battery Future Acquisition Corp.',
    symbol: 'BFAC-UN',
  },
  {
    value: 'BGSX-UN',
    label: 'BGSX-UN,  Build Acquisition Corp.',
    name: 'Build Acquisition Corp.',
    symbol: 'BGSX-UN',
  },
  {
    value: 'BIAF',
    label: 'BIAF,  bioAffinity Technologies, Inc.',
    name: 'bioAffinity Technologies, Inc.',
    symbol: 'BIAF',
  },
  {
    value: 'BITE',
    label: 'BITE,  Bite Acquisition Corp.',
    name: 'Bite Acquisition Corp.',
    symbol: 'BITE',
  },
  {
    value: 'BITE-UN',
    label: 'BITE-UN,  Bite Acquisition Corp.',
    name: 'Bite Acquisition Corp.',
    symbol: 'BITE-UN',
  },
  {
    value: 'BLUA',
    label: 'BLUA,  BlueRiver Acquisition Corp.',
    name: 'BlueRiver Acquisition Corp.',
    symbol: 'BLUA',
  },
  {
    value: 'BLUA-UN',
    label: 'BLUA-UN,  BlueRiver Acquisition Corp.',
    name: 'BlueRiver Acquisition Corp.',
    symbol: 'BLUA-UN',
  },
  {
    value: 'BMAC-UN',
    label: 'BMAC-UN,  Black Mountain Acquisition Corp.',
    name: 'Black Mountain Acquisition Corp.',
    symbol: 'BMAC-UN',
  },
  {
    value: 'BNOW',
    label: 'BNOW,  Boon Industries, Inc.',
    name: 'Boon Industries, Inc.',
    symbol: 'BNOW',
  },
  {
    value: 'BONZ',
    label: 'BONZ,  Bonanza Goldfields Corp.',
    name: 'Bonanza Goldfields Corp.',
    symbol: 'BONZ',
  },
  {
    value: 'BPOPO',
    label: 'BPOPO,  Popular, Inc.',
    name: 'Popular, Inc.',
    symbol: 'BPOPO',
  },
  {
    value: 'BSGA',
    label: 'BSGA,  Blue Safari Group Acquisition Corp.',
    name: 'Blue Safari Group Acquisition Corp.',
    symbol: 'BSGA',
  },
  {
    value: 'BSGAU',
    label: 'BSGAU,  Blue Safari Group Acquisition Corp.',
    name: 'Blue Safari Group Acquisition Corp.',
    symbol: 'BSGAU',
  },
  {
    value: 'BWAC',
    label: 'BWAC,  Better World Acquisition Corp.',
    name: 'Better World Acquisition Corp.',
    symbol: 'BWAC',
  },
  {
    value: 'BYN-UN',
    label: 'BYN-UN,  Banyan Acquisition Corporation',
    name: 'Banyan Acquisition Corporation',
    symbol: 'BYN-UN',
  },
  {
    value: 'CALI3.SA',
    label: 'CALI3.SA,  Construtora Adolpho Lindenberg S.A.',
    name: 'Construtora Adolpho Lindenberg S.A.',
    symbol: 'CALI3.SA',
  },
  {
    value: 'CCJS.L',
    label: 'CCJS.L,  CC Japan Income & Growth Trust plc',
    name: 'CC Japan Income & Growth Trust plc',
    symbol: 'CCJS.L',
  },
  {
    value: 'CCVI',
    label: 'CCVI,  Churchill Capital Corp VI',
    name: 'Churchill Capital Corp VI',
    symbol: 'CCVI',
  },
  {
    value: 'CCVI-UN',
    label: 'CCVI-UN,  Churchill Capital Corp VI',
    name: 'Churchill Capital Corp VI',
    symbol: 'CCVI-UN',
  },
  {
    value: 'CHJI',
    label: 'CHJI,  China Changjiang Mining & New Energy Company, Ltd.',
    name: 'China Changjiang Mining & New Energy Company, Ltd.',
    symbol: 'CHJI',
  },
  {
    value: 'CHMX',
    label: 'CHMX,  NEXT-ChemX Corporation',
    name: 'NEXT-ChemX Corporation',
    symbol: 'CHMX',
  },
  {
    value: 'CHRD',
    label: 'CHRD,  Chord Energy Corporation',
    name: 'Chord Energy Corporation',
    symbol: 'CHRD',
  },
  {
    value: 'CIPI',
    label: 'CIPI,  Correlate Infrastructure Partners Inc.',
    name: 'Correlate Infrastructure Partners Inc.',
    symbol: 'CIPI',
  },
  {
    value: 'CJAX',
    label: 'CJAX,  Barrister Energy, LLC',
    name: 'Barrister Energy, LLC',
    symbol: 'CJAX',
  },
  {
    value: 'CLAA-UN',
    label: 'CLAA-UN,  Colonnade Acquisition Corp. II',
    name: 'Colonnade Acquisition Corp. II',
    symbol: 'CLAA-UN',
  },
  {
    value: 'CLAYU',
    label: 'CLAYU,  Chavant Capital Acquisition Corp.',
    name: 'Chavant Capital Acquisition Corp.',
    symbol: 'CLAYU',
  },
  {
    value: 'CLBR-UN',
    label: 'CLBR-UN,  Colombier Acquisition Corp.',
    name: 'Colombier Acquisition Corp.',
    symbol: 'CLBR-UN',
  },
  {
    value: 'CLIM.L',
    label: 'CLIM.L,  Lyxor Green Bond (DR) UCITS ETF',
    name: 'Lyxor Green Bond (DR) UCITS ETF',
    symbol: 'CLIM.L',
  },
  {
    value: 'CLRD',
    label: 'CLRD,  Clearday, Inc.',
    name: 'Clearday, Inc.',
    symbol: 'CLRD',
  },
  {
    value: 'CMOP.L',
    label: 'CMOP.L,  Invesco Bloomberg Commodity UCITS ETF',
    name: 'Invesco Bloomberg Commodity UCITS ETF',
    symbol: 'CMOP.L',
  },
  {
    value: 'CMRF',
    label: 'CMRF,  CIM Real Estate Finance Trust, Inc.',
    name: 'CIM Real Estate Finance Trust, Inc.',
    symbol: 'CMRF',
  },
  {
    value: 'CNDA-UN',
    label: 'CNDA-UN,  Concord Acquisition Corp II',
    name: 'Concord Acquisition Corp II',
    symbol: 'CNDA-UN',
  },
  {
    value: 'CNDB-UN',
    label: 'CNDB-UN,  Concord Acquisition Corp III',
    name: 'Concord Acquisition Corp III',
    symbol: 'CNDB-UN',
  },
  {
    value: 'CORS-UN',
    label: 'CORS-UN,  Corsair Partnering Corporation',
    name: 'Corsair Partnering Corporation',
    symbol: 'CORS-UN',
  },
  {
    value: 'COVAU',
    label: 'COVAU,  COVA Acquisition Corp.',
    name: 'COVA Acquisition Corp.',
    symbol: 'COVAU',
  },
  {
    value: 'CPAR',
    label: 'CPAR,  Catalyst Partners Acquisition Corp.',
    name: 'Catalyst Partners Acquisition Corp.',
    symbol: 'CPAR',
  },
  {
    value: 'CPTK',
    label: 'CPTK,  Crown Proptech Acquisitions',
    name: 'Crown Proptech Acquisitions',
    symbol: 'CPTK',
  },
  {
    value: 'CPTK-UN',
    label: 'CPTK-UN,  Crown Proptech Acquisitions',
    name: 'Crown Proptech Acquisitions',
    symbol: 'CPTK-UN',
  },
  {
    value: 'CPUH-UN',
    label: 'CPUH-UN,  Compute Health Acquisition Corp.',
    name: 'Compute Health Acquisition Corp.',
    symbol: 'CPUH-UN',
  },
  {
    value: 'CSH',
    label: 'CSH,  Morgan Creek-Exos Active SPAC Arbitrage ETF',
    name: 'Morgan Creek-Exos Active SPAC Arbitrage ETF',
    symbol: 'CSH',
  },
  {
    value: 'CSTA',
    label: 'CSTA,  Constellation Acquisition Corp I',
    name: 'Constellation Acquisition Corp I',
    symbol: 'CSTA',
  },
  {
    value: 'CSTA-UN',
    label: 'CSTA-UN,  Constellation Acquisition Corp I',
    name: 'Constellation Acquisition Corp I',
    symbol: 'CSTA-UN',
  },
  {
    value: 'CVII-UN',
    label: 'CVII-UN,  Churchill Capital Corp VII',
    name: 'Churchill Capital Corp VII',
    symbol: 'CVII-UN',
  },
  {
    value: 'CXAC-UN',
    label: 'CXAC-UN,  C5 Acquisition Corporation',
    name: 'C5 Acquisition Corporation',
    symbol: 'CXAC-UN',
  },
  {
    value: 'DILAU',
    label: 'DILAU,  DILA Capital Acquisition Corp.',
    name: 'DILA Capital Acquisition Corp.',
    symbol: 'DILAU',
  },
  {
    value: 'DKDCU',
    label: 'DKDCU,  Data Knights Acquisition Corp.',
    name: 'Data Knights Acquisition Corp.',
    symbol: 'DKDCU',
  },
  {
    value: 'DMYS-UN',
    label: 'DMYS-UN,  dMY Technology Group, Inc. VI',
    name: 'dMY Technology Group, Inc. VI',
    symbol: 'DMYS-UN',
  },
  {
    value: 'DNAB',
    label: 'DNAB,  Social Capital Suvretta Holdings Corp. II',
    name: 'Social Capital Suvretta Holdings Corp. II',
    symbol: 'DNAB',
  },
  {
    value: 'DNAD',
    label: 'DNAD,  Social Capital Suvretta Holdings Corp. IV',
    name: 'Social Capital Suvretta Holdings Corp. IV',
    symbol: 'DNAD',
  },
  {
    value: 'DNZ',
    label: 'DNZ,  D and Z Media Acquisition Corp.',
    name: 'D and Z Media Acquisition Corp.',
    symbol: 'DNZ',
  },
  {
    value: 'DNZ-UN',
    label: 'DNZ-UN,  D and Z Media Acquisition Corp.',
    name: 'D and Z Media Acquisition Corp.',
    symbol: 'DNZ-UN',
  },
  {
    value: 'DRAYU',
    label: 'DRAYU,  Macondray Capital Acquisition Corp. I',
    name: 'Macondray Capital Acquisition Corp. I',
    symbol: 'DRAYU',
  },
  {
    value: 'DROP',
    label: 'DROP,  Fuse Science, Inc.',
    name: 'Fuse Science, Inc.',
    symbol: 'DROP',
  },
  {
    value: 'DRUM.L',
    label: 'DRUM.L,  Drumz plc',
    name: 'Drumz plc',
    symbol: 'DRUM.L',
  },
  {
    value: 'DSAQ-UN',
    label: 'DSAQ-UN,  Direct Selling Acquisition Corp.',
    name: 'Direct Selling Acquisition Corp.',
    symbol: 'DSAQ-UN',
  },
  {
    value: 'EBBGF',
    label: 'EBBGF,  Enbridge Inc.',
    name: 'Enbridge Inc.',
    symbol: 'EBBGF',
  },
  {
    value: 'ECDD',
    label: 'ECDD,  Ecrid, Inc.',
    name: 'Ecrid, Inc.',
    symbol: 'ECDD',
  },
  {
    value: 'EDGH.L',
    label: 'EDGH.L,  Edge Performance VCT Public Limited Company',
    name: 'Edge Performance VCT Public Limited Company',
    symbol: 'EDGH.L',
  },
  {
    value: 'EOCW-UN',
    label: 'EOCW-UN,  Elliott Opportunity II Corp.',
    name: 'Elliott Opportunity II Corp.',
    symbol: 'EOCW-UN',
  },
  {
    value: 'EP',
    label: 'EP,  Empire Petroleum Corporation',
    name: 'Empire Petroleum Corporation',
    symbol: 'EP',
  },
  {
    value: 'ESM',
    label: 'ESM,  ESM Acquisition Corporation',
    name: 'ESM Acquisition Corporation',
    symbol: 'ESM',
  },
  {
    value: 'ETCG',
    label: 'ETCG,  Grayscale Ethereum Classic Trust (ETC)',
    name: 'Grayscale Ethereum Classic Trust (ETC)',
    symbol: 'ETCG',
  },
  {
    value: 'EUBG',
    label: 'EUBG,  Entrepreneur Universe Bright Group',
    name: 'Entrepreneur Universe Bright Group',
    symbol: 'EUBG',
  },
  {
    value: 'EVE-UN',
    label: 'EVE-UN,  EVe Mobility Acquisition Corp',
    name: 'EVe Mobility Acquisition Corp',
    symbol: 'EVE-UN',
  },
  {
    value: 'EVVL',
    label: 'EVVL,  Evil Empire Designs, Inc.',
    name: 'Evil Empire Designs, Inc.',
    symbol: 'EVVL',
  },
  {
    value: 'EZRG',
    label: 'EZRG,  EZRaider Co.',
    name: 'EZRaider Co.',
    symbol: 'EZRG',
  },
  {
    value: 'FACA',
    label: 'FACA,  Figure Acquisition Corp. I',
    name: 'Figure Acquisition Corp. I',
    symbol: 'FACA',
  },
  {
    value: 'FACA-UN',
    label: 'FACA-UN,  Figure Acquisition Corp. I',
    name: 'Figure Acquisition Corp. I',
    symbol: 'FACA-UN',
  },
  {
    value: 'FACT',
    label: 'FACT,  Freedom Acquisition I Corp.',
    name: 'Freedom Acquisition I Corp.',
    symbol: 'FACT',
  },
  {
    value: 'FACT-UN',
    label: 'FACT-UN,  Freedom Acquisition I Corp.',
    name: 'Freedom Acquisition I Corp.',
    symbol: 'FACT-UN',
  },
  {
    value: 'FATPU',
    label: 'FATPU,  Fat Projects Acquisition Corp',
    name: 'Fat Projects Acquisition Corp',
    symbol: 'FATPU',
  },
  {
    value: 'FCAX',
    label: 'FCAX,  Fortress Capital Acquisition Corp.',
    name: 'Fortress Capital Acquisition Corp.',
    symbol: 'FCAX',
  },
  {
    value: 'FCAX-UN',
    label: 'FCAX-UN,  Fortress Capital Acquisition Corp.',
    name: 'Fortress Capital Acquisition Corp.',
    symbol: 'FCAX-UN',
  },
  {
    value: 'FDIT',
    label: 'FDIT,  Findit, Inc.',
    name: 'Findit, Inc.',
    symbol: 'FDIT',
  },
  {
    value: 'FHN-PB',
    label: 'FHN-PB,  First Horizon Corporation',
    name: 'First Horizon Corporation',
    symbol: 'FHN-PB',
  },
  {
    value: 'FHN-PC',
    label: 'FHN-PC,  First Horizon Corporation',
    name: 'First Horizon Corporation',
    symbol: 'FHN-PC',
  },
  {
    value: 'FIBERWEB.NS',
    label: 'FIBERWEB.NS,  Fiberweb (India) Limited',
    name: 'Fiberweb (India) Limited',
    symbol: 'FIBERWEB.NS',
  },
  {
    value: 'FKST',
    label: 'FKST,  Flowerkist Skin Care and Cosmetics, Inc.',
    name: 'Flowerkist Skin Care and Cosmetics, Inc.',
    symbol: 'FKST',
  },
  {
    value: 'FLME',
    label: 'FLME,  Flame Acquisition Corp.',
    name: 'Flame Acquisition Corp.',
    symbol: 'FLME',
  },
  {
    value: 'FLME-UN',
    label: 'FLME-UN,  Flame Acquisition Corp.',
    name: 'Flame Acquisition Corp.',
    symbol: 'FLME-UN',
  },
  {
    value: 'FLYA-UN',
    label: 'FLYA-UN,  SOAR Technology Acquisition Corp.',
    name: 'SOAR Technology Acquisition Corp.',
    symbol: 'FLYA-UN',
  },
  {
    value: 'FRXB',
    label: 'FRXB,  Forest Road Acquisition Corp. II',
    name: 'Forest Road Acquisition Corp. II',
    symbol: 'FRXB',
  },
  {
    value: 'FRXB-UN',
    label: 'FRXB-UN,  Forest Road Acquisition Corp. II',
    name: 'Forest Road Acquisition Corp. II',
    symbol: 'FRXB-UN',
  },
  {
    value: 'FSNB-UN',
    label: 'FSNB-UN,  Fusion Acquisition Corp. II',
    name: 'Fusion Acquisition Corp. II',
    symbol: 'FSNB-UN',
  },
  {
    value: 'FSSIU',
    label: 'FSSIU,  Fortistar Sustainable Solutions Corp.',
    name: 'Fortistar Sustainable Solutions Corp.',
    symbol: 'FSSIU',
  },
  {
    value: 'FTEV-UN',
    label: 'FTEV-UN,  FinTech Evolution Acquisition Group',
    name: 'FinTech Evolution Acquisition Group',
    symbol: 'FTEV-UN',
  },
  {
    value: 'FVIV',
    label: 'FVIV,  Fortress Value Acquisition Corp. IV',
    name: 'Fortress Value Acquisition Corp. IV',
    symbol: 'FVIV',
  },
  {
    value: 'FVIV-UN',
    label: 'FVIV-UN,  Fortress Value Acquisition Corp. IV',
    name: 'Fortress Value Acquisition Corp. IV',
    symbol: 'FVIV-UN',
  },
  {
    value: 'FZT',
    label: 'FZT,  FAST Acquisition Corp. II',
    name: 'FAST Acquisition Corp. II',
    symbol: 'FZT',
  },
  {
    value: 'FZT-UN',
    label: 'FZT-UN,  FAST Acquisition Corp. II',
    name: 'FAST Acquisition Corp. II',
    symbol: 'FZT-UN',
  },
  {
    value: 'GACQU',
    label: 'GACQU,  Global Consumer Acquisition Corp.',
    name: 'Global Consumer Acquisition Corp.',
    symbol: 'GACQU',
  },
  {
    value: 'GAMC',
    label: 'GAMC,  Golden Arrow Merger Corp.',
    name: 'Golden Arrow Merger Corp.',
    symbol: 'GAMC',
  },
  {
    value: 'GAPA',
    label: 'GAPA,  G&P Acquisition Corp.',
    name: 'G&P Acquisition Corp.',
    symbol: 'GAPA',
  },
  {
    value: 'GAPA-UN',
    label: 'GAPA-UN,  G&P Acquisition Corp.',
    name: 'G&P Acquisition Corp.',
    symbol: 'GAPA-UN',
  },
  {
    value: 'GEVI',
    label: 'GEVI,  General Enterprise Ventures, Inc.',
    name: 'General Enterprise Ventures, Inc.',
    symbol: 'GEVI',
  },
  {
    value: 'GFOR-UN',
    label: 'GFOR-UN,  Graf Acquisition Corp. IV',
    name: 'Graf Acquisition Corp. IV',
    symbol: 'GFOR-UN',
  },
  {
    value: 'GHACU',
    label: 'GHACU,  Gaming & Hospitality Acquisition Corp.',
    name: 'Gaming & Hospitality Acquisition Corp.',
    symbol: 'GHACU',
  },
  {
    value: 'GIA-UN',
    label: 'GIA-UN,  GigCapital5, Inc.',
    name: 'GigCapital5, Inc.',
    symbol: 'GIA-UN',
  },
  {
    value: 'GIPL',
    label: 'GIPL,  Global Innovative Platforms Inc.',
    name: 'Global Innovative Platforms Inc.',
    symbol: 'GIPL',
  },
  {
    value: 'GIW',
    label: 'GIW,  GigInternational1, Inc.',
    name: 'GigInternational1, Inc.',
    symbol: 'GIW',
  },
  {
    value: 'GLTA-UN',
    label: 'GLTA-UN,  Galata Acquisition Corp.',
    name: 'Galata Acquisition Corp.',
    symbol: 'GLTA-UN',
  },
  {
    value: 'GMET',
    label: 'GMET,  VanEck Green Metals ETF',
    name: 'VanEck Green Metals ETF',
    symbol: 'GMET',
  },
  {
    value: 'GOGN-UN',
    label: 'GOGN-UN,  GoGreen Investments Corporation',
    name: 'GoGreen Investments Corporation',
    symbol: 'GOGN-UN',
  },
  {
    value: 'GRBK-PA',
    label: 'GRBK-PA,  Green Brick Partners, Inc.',
    name: 'Green Brick Partners, Inc.',
    symbol: 'GRBK-PA',
  },
  {
    value: 'GRHI',
    label: 'GRHI,  Gold Rock Holdings, Inc.',
    name: 'Gold Rock Holdings, Inc.',
    symbol: 'GRHI',
  },
  {
    value: 'GRIT.L',
    label: 'GRIT.L,  GRIT Investment Trust PLC',
    name: 'GRIT Investment Trust PLC',
    symbol: 'GRIT.L',
  },
  {
    value: 'GSCE',
    label: 'GSCE,  BNPP E Energy & Metals EnhRoll ETF EUR C',
    name: 'BNPP E Energy & Metals EnhRoll ETF EUR C',
    symbol: 'GSCE',
  },
  {
    value: 'GSQB-UN',
    label: 'GSQB-UN,  G Squared Ascend II Inc.',
    name: 'G Squared Ascend II Inc.',
    symbol: 'GSQB-UN',
  },
  {
    value: 'GSQD',
    label: 'GSQD,  G Squared Ascend I Inc.',
    name: 'G Squared Ascend I Inc.',
    symbol: 'GSQD',
  },
  {
    value: 'GSQD-UN',
    label: 'GSQD-UN,  G Squared Ascend I Inc.',
    name: 'G Squared Ascend I Inc.',
    symbol: 'GSQD-UN',
  },
  {
    value: 'HCOM',
    label: 'HCOM,  Hartford Schroders Commodity Strategy ETF',
    name: 'Hartford Schroders Commodity Strategy ETF',
    symbol: 'HCOM',
  },
  {
    value: 'HDIV.L',
    label: 'HDIV.L,  Henderson Diversified Income Trust plc',
    name: 'Henderson Diversified Income Trust plc',
    symbol: 'HDIV.L',
  },
  {
    value: 'HENC',
    label: 'HENC,  Hero Technologies Inc.',
    name: 'Hero Technologies Inc.',
    symbol: 'HENC',
  },
  {
    value: 'HHLA',
    label: 'HHLA,  HH&L Acquisition Co.',
    name: 'HH&L Acquisition Co.',
    symbol: 'HHLA',
  },
  {
    value: 'HHLA-UN',
    label: 'HHLA-UN,  HH&L Acquisition Co.',
    name: 'HH&L Acquisition Co.',
    symbol: 'HHLA-UN',
  },
  {
    value: 'HNRA',
    label: 'HNRA,  HNR Acquisition Corp',
    name: 'HNR Acquisition Corp',
    symbol: 'HNRA',
  },
  {
    value: 'HUGS',
    label: 'HUGS,  USHG Acquisition Corp.',
    name: 'USHG Acquisition Corp.',
    symbol: 'HUGS',
  },
  {
    value: 'HUGS-UN',
    label: 'HUGS-UN,  USHG Acquisition Corp.',
    name: 'USHG Acquisition Corp.',
    symbol: 'HUGS-UN',
  },
  {
    value: 'HWELU',
    label: 'HWELU,  Healthwell Acquisition Corp. I',
    name: 'Healthwell Acquisition Corp. I',
    symbol: 'HWELU',
  },
  {
    value: 'HWTR',
    label: 'HWTR,  HFactor, Inc.',
    name: 'HFactor, Inc.',
    symbol: 'HWTR',
  },
  {
    value: '300332.SZ',
    label: '300332.SZ,  Top Resource Conservation & Environment Corp.',
    name: 'Top Resource Conservation & Environment Corp.',
    symbol: '300332.SZ',
  },
  {
    value: '300081.SZ',
    label: '300081.SZ,  Hengxin Shambala Culture Co.,Ltd.',
    name: 'Hengxin Shambala Culture Co.,Ltd.',
    symbol: '300081.SZ',
  },
  {
    value: '300412.SZ',
    label: '300412.SZ,  Zhejiang Canaan Technology Limited',
    name: 'Zhejiang Canaan Technology Limited',
    symbol: '300412.SZ',
  },
  {
    value: 'IBER',
    label: 'IBER,  Ibere Pharmaceuticals',
    name: 'Ibere Pharmaceuticals',
    symbol: 'IBER',
  },
  {
    value: 'IBER-UN',
    label: 'IBER-UN,  Ibere Pharmaceuticals',
    name: 'Ibere Pharmaceuticals',
    symbol: 'IBER-UN',
  },
  {
    value: '300109.SZ',
    label: '300109.SZ,  Boai NKY Medical Holdings Ltd.',
    name: 'Boai NKY Medical Holdings Ltd.',
    symbol: '300109.SZ',
  },
  {
    value: '300079.SZ',
    label: '300079.SZ,  Sumavision Technologies Co.,Ltd.',
    name: 'Sumavision Technologies Co.,Ltd.',
    symbol: '300079.SZ',
  },
  {
    value: 'ICNC-UN',
    label: 'ICNC-UN,  Iconic Sports Acquisition Corp.',
    name: 'Iconic Sports Acquisition Corp.',
    symbol: 'ICNC-UN',
  },
  {
    value: '300396.SZ',
    label: '300396.SZ,  Dirui Industrial Co.,Ltd.',
    name: 'Dirui Industrial Co.,Ltd.',
    symbol: '300396.SZ',
  },
  {
    value: 'IDR',
    label: 'IDR,  Idaho Strategic Resources, Inc.',
    name: 'Idaho Strategic Resources, Inc.',
    symbol: 'IDR',
  },
  {
    value: 'IFIN-UN',
    label: 'IFIN-UN,  InFinT Acquisition Corporation',
    name: 'InFinT Acquisition Corporation',
    symbol: 'IFIN-UN',
  },
  {
    value: 'IGPK',
    label: 'IGPK,  Integrated Cannabis Solutions, Inc.',
    name: 'Integrated Cannabis Solutions, Inc.',
    symbol: 'IGPK',
  },
  {
    value: 'INAQ-UN',
    label: 'INAQ-UN,  Insight Acquisition Corp.',
    name: 'Insight Acquisition Corp.',
    symbol: 'INAQ-UN',
  },
  {
    value: 'IPOL.L',
    label: 'IPOL.L,  iShares V PLC - iShares MSCI Poland UCITS ETF',
    name: 'iShares V PLC - iShares MSCI Poland UCITS ETF',
    symbol: 'IPOL.L',
  },
  {
    value: 'IPVA',
    label: 'IPVA,  InterPrivate II Acquisition Corp.',
    name: 'InterPrivate II Acquisition Corp.',
    symbol: 'IPVA',
  },
  {
    value: 'IPVA-UN',
    label: 'IPVA-UN,  InterPrivate II Acquisition Corp.',
    name: 'InterPrivate II Acquisition Corp.',
    symbol: 'IPVA-UN',
  },
  {
    value: 'IPVF',
    label: 'IPVF,  InterPrivate III Financial Partners Inc.',
    name: 'InterPrivate III Financial Partners Inc.',
    symbol: 'IPVF',
  },
  {
    value: 'IPVF-UN',
    label: 'IPVF-UN,  InterPrivate III Financial Partners Inc.',
    name: 'InterPrivate III Financial Partners Inc.',
    symbol: 'IPVF-UN',
  },
  {
    value: 'IRME',
    label: 'IRME,  IR-Med Inc.',
    name: 'IR-Med Inc.',
    symbol: 'IRME',
  },
  {
    value: 'IRRX-UN',
    label: 'IRRX-UN,  Integrated Rail and Resources Acquisition Corp.',
    name: 'Integrated Rail and Resources Acquisition Corp.',
    symbol: 'IRRX-UN',
  },
  {
    value: 'ITEP.L',
    label: 'ITEP.L,  HAN-GINS Tech Megatrend Equal Weight UCITS ETF',
    name: 'HAN-GINS Tech Megatrend Equal Weight UCITS ETF',
    symbol: 'ITEP.L',
  },
  {
    value: 'IVBT',
    label: 'IVBT,  Innovation1 Biotech Inc.',
    name: 'Innovation1 Biotech Inc.',
    symbol: 'IVBT',
  },
  {
    value: 'JATT-UN',
    label: 'JATT-UN,  JATT Acquisition Corp',
    name: 'JATT Acquisition Corp',
    symbol: 'JATT-UN',
  },
  {
    value: 'JENGQ',
    label: 'JENGQ,  Just Energy Group Inc.',
    name: 'Just Energy Group Inc.',
    symbol: 'JENGQ',
  },
  {
    value: 'JETMF',
    label: 'JETMF,  Global Crossing Airlines Group Inc.',
    name: 'Global Crossing Airlines Group Inc.',
    symbol: 'JETMF',
  },
  {
    value: 'JGGC',
    label: 'JGGC,  Jaguar Global Growth Corporation I',
    name: 'Jaguar Global Growth Corporation I',
    symbol: 'JGGC',
  },
  {
    value: 'JUGGU',
    label: 'JUGGU,  Jaws Juggernaut Acquisition Corporation',
    name: 'Jaws Juggernaut Acquisition Corporation',
    symbol: 'JUGGU',
  },
  {
    value: 'JUN-UN',
    label: 'JUN-UN,  Juniper II Corp.',
    name: 'Juniper II Corp.',
    symbol: 'JUN-UN',
  },
  {
    value: 'JWSM-UN',
    label: 'JWSM-UN,  Jaws Mustang Acquisition Corporation',
    name: 'Jaws Mustang Acquisition Corporation',
    symbol: 'JWSM-UN',
  },
  {
    value: 'KAHC-UN',
    label: 'KAHC-UN,  KKR Acquisition Holdings I Corp.',
    name: 'KKR Acquisition Holdings I Corp.',
    symbol: 'KAHC-UN',
  },
  {
    value: 'KCGI-UN',
    label: 'KCGI-UN,  Kensington Capital Acquisition Corp. V',
    name: 'Kensington Capital Acquisition Corp. V',
    symbol: 'KCGI-UN',
  },
  {
    value: 'KRFG',
    label: 'KRFG,  King Resources, Inc.',
    name: 'King Resources, Inc.',
    symbol: 'KRFG',
  },
  {
    value: 'KRNLU',
    label: 'KRNLU,  Kernel Group Holdings, Inc.',
    name: 'Kernel Group Holdings, Inc.',
    symbol: 'KRNLU',
  },
  {
    value: 'KSI',
    label: 'KSI,  Kadem Sustainable Impact Corporation',
    name: 'Kadem Sustainable Impact Corporation',
    symbol: 'KSI',
  },
  {
    value: 'KWIK',
    label: 'KWIK,  KwikClick, Inc.',
    name: 'KwikClick, Inc.',
    symbol: 'KWIK',
  },
  {
    value: 'KYCHU',
    label: 'KYCHU,  Keyarch Acquisition Corporation',
    name: 'Keyarch Acquisition Corporation',
    symbol: 'KYCHU',
  },
  {
    value: 'LAAAU',
    label: 'LAAAU,  Lakeshore Acquisition I Corp.',
    name: 'Lakeshore Acquisition I Corp.',
    symbol: 'LAAAU',
  },
  {
    value: 'LATG',
    label: 'LATG,  LatAmGrowth SPAC',
    name: 'LatAmGrowth SPAC',
    symbol: 'LATG',
  },
  {
    value: 'LATGU',
    label: 'LATGU,  LatAmGrowth SPAC',
    name: 'LatAmGrowth SPAC',
    symbol: 'LATGU',
  },
  {
    value: 'LCA',
    label: 'LCA,  Landcadia Holdings IV, Inc.',
    name: 'Landcadia Holdings IV, Inc.',
    symbol: 'LCA',
  },
  {
    value: 'LGSTU',
    label: 'LGSTU,  Semper Paratus Acquisition Corporation',
    name: 'Semper Paratus Acquisition Corporation',
    symbol: 'LGSTU',
  },
  {
    value: 'LGV',
    label: 'LGV,  Longview Acquisition Corp. II',
    name: 'Longview Acquisition Corp. II',
    symbol: 'LGV',
  },
  {
    value: 'LGV-UN',
    label: 'LGV-UN,  Longview Acquisition Corp. II',
    name: 'Longview Acquisition Corp. II',
    symbol: 'LGV-UN',
  },
  {
    value: 'LIBYU',
    label: 'LIBYU,  Liberty Resources Acquisition Corp.',
    name: 'Liberty Resources Acquisition Corp.',
    symbol: 'LIBYU',
  },
  {
    value: 'LIFD',
    label: 'LIFD,  LFTD Partners Inc.',
    name: 'LFTD Partners Inc.',
    symbol: 'LIFD',
  },
  {
    value: 'LIONU',
    label: 'LIONU,  Lionheart III Corp',
    name: 'Lionheart III Corp',
    symbol: 'LIONU',
  },
  {
    value: 'LITTU',
    label: 'LITTU,  Logistics Innovation Technologies Corp.',
    name: 'Logistics Innovation Technologies Corp.',
    symbol: 'LITTU',
  },
  {
    value: 'LOCC-UN',
    label: 'LOCC-UN,  Live Oak Crestview Climate Acquisition Corp.',
    name: 'Live Oak Crestview Climate Acquisition Corp.',
    symbol: 'LOCC-UN',
  },
  {
    value: 'LOKM',
    label: 'LOKM,  Live Oak Mobility Acquisition Corp.',
    name: 'Live Oak Mobility Acquisition Corp.',
    symbol: 'LOKM',
  },
  {
    value: 'LOKM-UN',
    label: 'LOKM-UN,  Live Oak Mobility Acquisition Corp.',
    name: 'Live Oak Mobility Acquisition Corp.',
    symbol: 'LOKM-UN',
  },
  {
    value: 'LSPR',
    label: 'LSPR,  Larkspur Health Acquisition Corp.',
    name: 'Larkspur Health Acquisition Corp.',
    symbol: 'LSPR',
  },
  {
    value: 'LSPRU',
    label: 'LSPRU,  Larkspur Health Acquisition Corp.',
    name: 'Larkspur Health Acquisition Corp.',
    symbol: 'LSPRU',
  },
  {
    value: 'LUCY',
    label: 'LUCY,  Innovative Eyewear, Inc.',
    name: 'Innovative Eyewear, Inc.',
    symbol: 'LUCY',
  },
  {
    value: 'LVACU',
    label: 'LVACU,  LAVA Medtech Acquisition Corp.',
    name: 'LAVA Medtech Acquisition Corp.',
    symbol: 'LVACU',
  },
  {
    value: 'LZGI',
    label: 'LZGI,  LZG International, Inc.',
    name: 'LZG International, Inc.',
    symbol: 'LZGI',
  },
  {
    value: 'MACC-UN',
    label: 'MACC-UN,  Mission Advancement Corp.',
    name: 'Mission Advancement Corp.',
    symbol: 'MACC-UN',
  },
  {
    value: 'MAQC',
    label: 'MAQC,  Maquia Capital Acquisition Corporation',
    name: 'Maquia Capital Acquisition Corporation',
    symbol: 'MAQC',
  },
  {
    value: 'MATV',
    label: 'MATV,  Mativ Holdings, Inc.',
    name: 'Mativ Holdings, Inc.',
    symbol: 'MATV',
  },
  {
    value: 'MBAC',
    label: 'MBAC,  M3-Brigade Acquisition II Corp.',
    name: 'M3-Brigade Acquisition II Corp.',
    symbol: 'MBAC',
  },
  {
    value: 'MBAC-UN',
    label: 'MBAC-UN,  M3-Brigade Acquisition II Corp.',
    name: 'M3-Brigade Acquisition II Corp.',
    symbol: 'MBAC-UN',
  },
  {
    value: 'MBBC',
    label: 'MBBC,  Marathon Bancorp, Inc.',
    name: 'Marathon Bancorp, Inc.',
    symbol: 'MBBC',
  },
  {
    value: 'MBSC-UN',
    label: 'MBSC-UN,  M3-Brigade Acquisition III Corp.',
    name: 'M3-Brigade Acquisition III Corp.',
    symbol: 'MBSC-UN',
  },
  {
    value: 'MCAAU',
    label: 'MCAAU,  Mountain & Co. I Acquisition Corp.',
    name: 'Mountain & Co. I Acquisition Corp.',
    symbol: 'MCAAU',
  },
  {
    value: 'MCACU',
    label: 'MCACU,  Monterey Capital Acquisition Corporation',
    name: 'Monterey Capital Acquisition Corporation',
    symbol: 'MCACU',
  },
  {
    value: 'MDV-PA',
    label: 'MDV-PA,  Modiv Inc.',
    name: 'Modiv Inc.',
    symbol: 'MDV-PA',
  },
  {
    value: 'MFIC',
    label: 'MFIC,  MidCap Financial Investment Corporation',
    name: 'MidCap Financial Investment Corporation',
    symbol: 'MFIC',
  },
  {
    value: 'MIMO-WT',
    label: 'MIMO-WT,  Airspan Networks Holdings Inc.',
    name: 'Airspan Networks Holdings Inc.',
    symbol: 'MIMO-WT',
  },
  {
    value: 'MLAIU',
    label: 'MLAIU,  McLaren Technology Acquisition Corp.',
    name: 'McLaren Technology Acquisition Corp.',
    symbol: 'MLAIU',
  },
  {
    value: 'MLPD.L',
    label:
      'MLPD.L,  Invesco Morningstar US Energy Infrastructure MLP UCITS ETF',
    name: 'Invesco Morningstar US Energy Infrastructure MLP UCITS ETF',
    symbol: 'MLPD.L',
  },
  {
    value: 'MMTRS',
    label: 'MMTRS,  Mills Music Trust',
    name: 'Mills Music Trust',
    symbol: 'MMTRS',
  },
  {
    value: 'MNTN-UN',
    label: 'MNTN-UN,  Everest Consolidator Acquisition Corporation',
    name: 'Everest Consolidator Acquisition Corporation',
    symbol: 'MNTN-UN',
  },
  {
    value: 'MPRAU',
    label: 'MPRAU,  Mercato Partners Acquisition Corporation',
    name: 'Mercato Partners Acquisition Corporation',
    symbol: 'MPRAU',
  },
  {
    value: 'MTAL-UN',
    label: 'MTAL-UN,  Metals Acquisition Corp',
    name: 'Metals Acquisition Corp',
    symbol: 'MTAL-UN',
  },
  {
    value: 'MTPP',
    label: 'MTPP,  Mountain Top Properties, Inc.',
    name: 'Mountain Top Properties, Inc.',
    symbol: 'MTPP',
  },
  {
    value: 'MTVC-UN',
    label: 'MTVC-UN,  Motive Capital Corp II',
    name: 'Motive Capital Corp II',
    symbol: 'MTVC-UN',
  },
  {
    value: 'MURF',
    label: 'MURF,  Murphy Canyon Acquisition Corp.',
    name: 'Murphy Canyon Acquisition Corp.',
    symbol: 'MURF',
  },
  {
    value: 'NBLRF',
    label: 'NBLRF,  Noble Corporation Plc',
    name: 'Noble Corporation Plc',
    symbol: 'NBLRF',
  },
  {
    value: 'NBLTF',
    label: 'NBLTF,  Noble Corporation Plc',
    name: 'Noble Corporation Plc',
    symbol: 'NBLTF',
  },
  {
    value: 'NBLWF',
    label: 'NBLWF,  Noble Corporation Plc',
    name: 'Noble Corporation Plc',
    symbol: 'NBLWF',
  },
  {
    value: 'NCACU',
    label: 'NCACU,  Newcourt Acquisition Corp',
    name: 'Newcourt Acquisition Corp',
    symbol: 'NCACU',
  },
  {
    value: 'NFNT-UN',
    label: 'NFNT-UN,  Infinite Acquisition Corp.',
    name: 'Infinite Acquisition Corp.',
    symbol: 'NFNT-UN',
  },
  {
    value: 'NFYS-UN',
    label: 'NFYS-UN,  Enphys Acquisition Corp.',
    name: 'Enphys Acquisition Corp.',
    symbol: 'NFYS-UN',
  },
  {
    value: 'NGC',
    label: 'NGC,  Northern Genesis Acquisition Corp. III',
    name: 'Northern Genesis Acquisition Corp. III',
    symbol: 'NGC',
  },
  {
    value: 'NHLE',
    label: 'NHLE,  Nhale, Inc.',
    name: 'Nhale, Inc.',
    symbol: 'NHLE',
  },
  {
    value: 'NLSC',
    label: 'NLSC,  Namliong SkyCosmos, Inc.',
    name: 'Namliong SkyCosmos, Inc.',
    symbol: 'NLSC',
  },
  {
    value: 'NNMX',
    label: 'NNMX,  Nanomix Corporation',
    name: 'Nanomix Corporation',
    symbol: 'NNMX',
  },
  {
    value: 'NPABU',
    label: 'NPABU,  New Providence Acquisition Corp. II',
    name: 'New Providence Acquisition Corp. II',
    symbol: 'NPABU',
  },
  {
    value: 'OBTC',
    label: 'OBTC,  Osprey Bitcoin Trust',
    name: 'Osprey Bitcoin Trust',
    symbol: 'OBTC',
  },
  {
    value: 'OHAAU',
    label: 'OHAAU,  OPY Acquisition Corp. I',
    name: 'OPY Acquisition Corp. I',
    symbol: 'OHAAU',
  },
  {
    value: 'OILY',
    label: 'OILY,  Sino American Oil Company',
    name: 'Sino American Oil Company',
    symbol: 'OILY',
  },
  {
    value: 'OLIT',
    label: 'OLIT,  OmniLit Acquisition Corp.',
    name: 'OmniLit Acquisition Corp.',
    symbol: 'OLIT',
  },
  {
    value: 'ONYXU',
    label: 'ONYXU,  Onyx Acquisition Co. I',
    name: 'Onyx Acquisition Co. I',
    symbol: 'ONYXU',
  },
  {
    value: 'OPAL',
    label: 'OPAL,  OPAL Fuels Inc.',
    name: 'OPAL Fuels Inc.',
    symbol: 'OPAL',
  },
  {
    value: 'OSI',
    label: 'OSI,  Osiris Acquisition Corp.',
    name: 'Osiris Acquisition Corp.',
    symbol: 'OSI',
  },
  {
    value: 'OSI-UN',
    label: 'OSI-UN,  Osiris Acquisition Corp.',
    name: 'Osiris Acquisition Corp.',
    symbol: 'OSI-UN',
  },
  {
    value: 'OXH.L',
    label: 'OXH.L,  Oxford Technology 2 Venture Capital Trust plc',
    name: 'Oxford Technology 2 Venture Capital Trust plc',
    symbol: 'OXH.L',
  },
  {
    value: 'PARAP',
    label: 'PARAP,  Paramount Global',
    name: 'Paramount Global',
    symbol: 'PARAP',
  },
  {
    value: 'PCCTU',
    label: 'PCCTU,  Perception Capital Corp. II',
    name: 'Perception Capital Corp. II',
    symbol: 'PCCTU',
  },
  {
    value: 'PCNT',
    label: 'PCNT,  Point of Care Nano-Technology, Inc.',
    name: 'Point of Care Nano-Technology, Inc.',
    symbol: 'PCNT',
  },
  {
    value: 'PDOT',
    label: 'PDOT,  Peridot Acquisition Corp. II',
    name: 'Peridot Acquisition Corp. II',
    symbol: 'PDOT',
  },
  {
    value: 'PDOT-UN',
    label: 'PDOT-UN,  Peridot Acquisition Corp. II',
    name: 'Peridot Acquisition Corp. II',
    symbol: 'PDOT-UN',
  },
  {
    value: 'PEGRU',
    label: 'PEGRU,  Project Energy Reimagined Acquisition Corp.',
    name: 'Project Energy Reimagined Acquisition Corp.',
    symbol: 'PEGRU',
  },
  {
    value: 'PFDR',
    label: 'PFDR,  Pathfinder Acquisition Corporation',
    name: 'Pathfinder Acquisition Corporation',
    symbol: 'PFDR',
  },
  {
    value: 'PGSS-UN',
    label: 'PGSS-UN,  Pegasus Digital Mobility Acquisition Corp.',
    name: 'Pegasus Digital Mobility Acquisition Corp.',
    symbol: 'PGSS-UN',
  },
  {
    value: 'PHYT-UN',
    label: 'PHYT-UN,  Pyrophyte Acquisition Corp.',
    name: 'Pyrophyte Acquisition Corp.',
    symbol: 'PHYT-UN',
  },
  {
    value: 'PLMI',
    label: 'PLMI,  Plum Acquisition Corp. I',
    name: 'Plum Acquisition Corp. I',
    symbol: 'PLMI',
  },
  {
    value: 'PLSH',
    label: 'PLSH,  Panacea Life Sciences Holdings, Inc.',
    name: 'Panacea Life Sciences Holdings, Inc.',
    symbol: 'PLSH',
  },
  {
    value: 'PMGZ.L',
    label: 'PMGZ.L,  PMGR Securities 2025 PLC',
    name: 'PMGR Securities 2025 PLC',
    symbol: 'PMGZ.L',
  },
  {
    value: 'POL',
    label: 'POL,  Polished.com Inc.',
    name: 'Polished.com Inc.',
    symbol: 'POL',
  },
  {
    value: 'POND',
    label: 'POND,  Angel Pond Holdings Corporation',
    name: 'Angel Pond Holdings Corporation',
    symbol: 'POND',
  },
  {
    value: 'POND-UN',
    label: 'POND-UN,  Angel Pond Holdings Corporation',
    name: 'Angel Pond Holdings Corporation',
    symbol: 'POND-UN',
  },
  {
    value: 'PORT-UN',
    label: 'PORT-UN,  Southport Acquisition Corporation',
    name: 'Southport Acquisition Corporation',
    symbol: 'PORT-UN',
  },
  {
    value: 'PRBM-UN',
    label: 'PRBM-UN,  Parabellum Acquisition Corp.',
    name: 'Parabellum Acquisition Corp.',
    symbol: 'PRBM-UN',
  },
  {
    value: 'PRKI',
    label: 'PRKI,  Perk International Inc.',
    name: 'Perk International Inc.',
    symbol: 'PRKI',
  },
  {
    value: 'PRLHU',
    label: 'PRLHU,  Pearl Holdings Acquisition Corp',
    name: 'Pearl Holdings Acquisition Corp',
    symbol: 'PRLHU',
  },
  {
    value: 'PROK',
    label: 'PROK,  ProKidney Corp.',
    name: 'ProKidney Corp.',
    symbol: 'PROK',
  },
  {
    value: 'PRPC',
    label: 'PRPC,  CC Neuberger Principal Holdings III',
    name: 'CC Neuberger Principal Holdings III',
    symbol: 'PRPC',
  },
  {
    value: 'PRPC-UN',
    label: 'PRPC-UN,  CC Neuberger Principal Holdings III',
    name: 'CC Neuberger Principal Holdings III',
    symbol: 'PRPC-UN',
  },
  {
    value: 'PSAG',
    label: 'PSAG,  Property Solutions Acquisition Corp. II',
    name: 'Property Solutions Acquisition Corp. II',
    symbol: 'PSAG',
  },
  {
    value: 'PSGI',
    label: 'PSGI,  Perfect Solutions Group, Inc.',
    name: 'Perfect Solutions Group, Inc.',
    symbol: 'PSGI',
  },
  {
    value: 'PSPC-UN',
    label: 'PSPC-UN,  Post Holdings Partnering Corporation',
    name: 'Post Holdings Partnering Corporation',
    symbol: 'PSPC-UN',
  },
  {
    value: 'PV-UN',
    label: 'PV-UN,  Primavera Capital Acquisition Corporation',
    name: 'Primavera Capital Acquisition Corporation',
    symbol: 'PV-UN',
  },
  {
    value: 'QWTR',
    label: 'QWTR,  Quest Water Global, Inc.',
    name: 'Quest Water Global, Inc.',
    symbol: 'QWTR',
  },
  {
    value: 'RBSH',
    label: 'RBSH,  Rebus Holdings, Inc.',
    name: 'Rebus Holdings, Inc.',
    symbol: 'RBSH',
  },
  {
    value: 'RCACU',
    label: 'RCACU,  Revelstone Capital Acquisition Corp.',
    name: 'Revelstone Capital Acquisition Corp.',
    symbol: 'RCACU',
  },
  {
    value: 'RENEU',
    label: 'RENEU,  Cartesian Growth Corporation II',
    name: 'Cartesian Growth Corporation II',
    symbol: 'RENEU',
  },
  {
    value: 'RESI',
    label: 'RESI,  Kelly Residential & Apartment Real Estate ETF',
    name: 'Kelly Residential & Apartment Real Estate ETF',
    symbol: 'RESI',
  },
  {
    value: 'REVBU',
    label: 'REVBU,  Revelation Biosciences, Inc.',
    name: 'Revelation Biosciences, Inc.',
    symbol: 'REVBU',
  },
  {
    value: 'RIOL.L',
    label: 'RIOL.L,  Lyxor MSCI Brazil UCITS ETF',
    name: 'Lyxor MSCI Brazil UCITS ETF',
    symbol: 'RIOL.L',
  },
  {
    value: 'RJAC-UN',
    label: 'RJAC-UN,  Jackson Acquisition Company',
    name: 'Jackson Acquisition Company',
    symbol: 'RJAC-UN',
  },
  {
    value: 'RKTA',
    label: 'RKTA,  Rocket Internet Growth Opportunities Corp.',
    name: 'Rocket Internet Growth Opportunities Corp.',
    symbol: 'RKTA',
  },
  {
    value: 'RMGCU',
    label: 'RMGCU,  RMG Acquisition Corp. III',
    name: 'RMG Acquisition Corp. III',
    symbol: 'RMGCU',
  },
  {
    value: 'RNWR',
    label: 'RNWR,  808 Renewable Energy Corporation',
    name: '808 Renewable Energy Corporation',
    symbol: 'RNWR',
  },
  {
    value: 'ROCAU',
    label: 'ROCAU,  ROC Energy Acquisition Corp.',
    name: 'ROC Energy Acquisition Corp.',
    symbol: 'ROCAU',
  },
  {
    value: 'ROCLU',
    label: 'ROCLU,  Roth Ch Acquisition V Co.',
    name: 'Roth Ch Acquisition V Co.',
    symbol: 'ROCLU',
  },
  {
    value: 'RONI-UN',
    label: 'RONI-UN,  Rice Acquisition Corp. II',
    name: 'Rice Acquisition Corp. II',
    symbol: 'RONI-UN',
  },
  {
    value: 'ROSEU',
    label: 'ROSEU,  Rose Hill Acquisition Corporation',
    name: 'Rose Hill Acquisition Corporation',
    symbol: 'ROSEU',
  },
  {
    value: 'ROSS',
    label: 'ROSS,  Ross Acquisition Corp II',
    name: 'Ross Acquisition Corp II',
    symbol: 'ROSS',
  },
  {
    value: 'ROSS-UN',
    label: 'ROSS-UN,  Ross Acquisition Corp II',
    name: 'Ross Acquisition Corp II',
    symbol: 'ROSS-UN',
  },
  {
    value: 'RRAC-UN',
    label: 'RRAC-UN,  Rigel Resource Acquisition Corp.',
    name: 'Rigel Resource Acquisition Corp.',
    symbol: 'RRAC-UN',
  },
  {
    value: 'RTGN',
    label: 'RTGN,  RetinalGenix Technologies Inc.',
    name: 'RetinalGenix Technologies Inc.',
    symbol: 'RTGN',
  },
  {
    value: 'RTLPO',
    label: 'RTLPO,  The Necessity Retail REIT, Inc.',
    name: 'The Necessity Retail REIT, Inc.',
    symbol: 'RTLPO',
  },
  {
    value: 'RTLPP',
    label: 'RTLPP,  The Necessity Retail REIT, Inc.',
    name: 'The Necessity Retail REIT, Inc.',
    symbol: 'RTLPP',
  },
  {
    value: 'RVIC',
    label: 'RVIC,  Retail Value Inc.',
    name: 'Retail Value Inc.',
    symbol: 'RVIC',
  },
  {
    value: 'RXMD',
    label: 'RXMD,  Progressive Care, Inc.',
    name: 'Progressive Care, Inc.',
    symbol: 'RXMD',
  },
  {
    value: 'SAGA',
    label: 'SAGA,  Sagaliam Acquisition Corp.',
    name: 'Sagaliam Acquisition Corp.',
    symbol: 'SAGA',
  },
  {
    value: 'SAGAU',
    label: 'SAGAU,  Sagaliam Acquisition Corp.',
    name: 'Sagaliam Acquisition Corp.',
    symbol: 'SAGAU',
  },
  {
    value: 'SATT',
    label: 'SATT,  Sativus Tech Corp.',
    name: 'Sativus Tech Corp.',
    symbol: 'SATT',
  },
  {
    value: 'SBII',
    label: 'SBII,  Sandbridge X2 Corp.',
    name: 'Sandbridge X2 Corp.',
    symbol: 'SBII',
  },
  {
    value: 'SBII-UN',
    label: 'SBII-UN,  Sandbridge X2 Corp.',
    name: 'Sandbridge X2 Corp.',
    symbol: 'SBII-UN',
  },
  {
    value: 'SBSI.L',
    label: 'SBSI.L,  Schroder BSC Social Impact Trust plc',
    name: 'Schroder BSC Social Impact Trust plc',
    symbol: 'SBSI.L',
  },
  {
    value: 'SCTH',
    label: 'SCTH,  SecureTech Innovations, Inc.',
    name: 'SecureTech Innovations, Inc.',
    symbol: 'SCTH',
  },
  {
    value: 'SCUA-UN',
    label: 'SCUA-UN,  Sculptor Acquisition Corp I',
    name: 'Sculptor Acquisition Corp I',
    symbol: 'SCUA-UN',
  },
  {
    value: 'SDACU',
    label: 'SDACU,  Sustainable Development Acquisition I Corp.',
    name: 'Sustainable Development Acquisition I Corp.',
    symbol: 'SDACU',
  },
  {
    value: 'SDRL',
    label: 'SDRL,  Seadrill Limited',
    name: 'Seadrill Limited',
    symbol: 'SDRL',
  },
  {
    value: 'SEDA-UN',
    label: 'SEDA-UN,  SDCL EDGE Acquisition Corporation',
    name: 'SDCL EDGE Acquisition Corporation',
    symbol: 'SEDA-UN',
  },
  {
    value: 'SGHL',
    label: 'SGHL,  Signal Hill Acquisition Corp.',
    name: 'Signal Hill Acquisition Corp.',
    symbol: 'SGHL',
  },
  {
    value: 'SHAP-UN',
    label: 'SHAP-UN,  Spree Acquisition Corp. 1 Limited',
    name: 'Spree Acquisition Corp. 1 Limited',
    symbol: 'SHAP-UN',
  },
  {
    value: 'SHCAU',
    label: 'SHCAU,  Spindletop Health Acquisition Corp.',
    name: 'Spindletop Health Acquisition Corp.',
    symbol: 'SHCAU',
  },
  {
    value: 'SLAC',
    label: 'SLAC,  Social Leverage Acquisition Corp I',
    name: 'Social Leverage Acquisition Corp I',
    symbol: 'SLAC',
  },
  {
    value: 'SLAC-UN',
    label: 'SLAC-UN,  Social Leverage Acquisition Corp I',
    name: 'Social Leverage Acquisition Corp I',
    symbol: 'SLAC-UN',
  },
  {
    value: 'SLGCW',
    label: 'SLGCW,  SomaLogic, Inc.',
    name: 'SomaLogic, Inc.',
    symbol: 'SLGCW',
  },
  {
    value: 'SLNHP',
    label: 'SLNHP,  Soluna Holdings, Inc.',
    name: 'Soluna Holdings, Inc.',
    symbol: 'SLNHP',
  },
  {
    value: 'SMIHU',
    label: 'SMIHU,  Summit Healthcare Acquisition Corp.',
    name: 'Summit Healthcare Acquisition Corp.',
    symbol: 'SMIHU',
  },
  {
    value: 'SMTK',
    label: 'SMTK,  SmartKem, Inc.',
    name: 'SmartKem, Inc.',
    symbol: 'SMTK',
  },
  {
    value: 'SPFX',
    label: 'SPFX,  Standard Premium Finance Holdings, Inc.',
    name: 'Standard Premium Finance Holdings, Inc.',
    symbol: 'SPFX',
  },
  {
    value: 'SPGS',
    label: 'SPGS,  Simon Property Group Acquisition Holdings, Inc.',
    name: 'Simon Property Group Acquisition Holdings, Inc.',
    symbol: 'SPGS',
  },
  {
    value: 'SPGS-UN',
    label: 'SPGS-UN,  Simon Property Group Acquisition Holdings, Inc.',
    name: 'Simon Property Group Acquisition Holdings, Inc.',
    symbol: 'SPGS-UN',
  },
  {
    value: 'SPTY',
    label: 'SPTY,  Specificity, Inc.',
    name: 'Specificity, Inc.',
    symbol: 'SPTY',
  },
  {
    value: 'STET-UN',
    label: 'STET-UN,  ST Energy Transition I Ltd.',
    name: 'ST Energy Transition I Ltd.',
    symbol: 'STET-UN',
  },
  {
    value: 'STKH',
    label: 'STKH,  Steakholder Foods Ltd.',
    name: 'Steakholder Foods Ltd.',
    symbol: 'STKH',
  },
  {
    value: 'STRE',
    label: 'STRE,  Supernova Partners Acquisition Company III, Ltd.',
    name: 'Supernova Partners Acquisition Company III, Ltd.',
    symbol: 'STRE',
  },
  {
    value: 'STRE-UN',
    label: 'STRE-UN,  Supernova Partners Acquisition Company III, Ltd.',
    name: 'Supernova Partners Acquisition Company III, Ltd.',
    symbol: 'STRE-UN',
  },
  {
    value: 'STRG',
    label: 'STRG,  Starguide Group, Inc.',
    name: 'Starguide Group, Inc.',
    symbol: 'STRG',
  },
  {
    value: 'SUAC-UN',
    label: 'SUAC-UN,  ShoulderUp Technology Acquisition Corp.',
    name: 'ShoulderUp Technology Acquisition Corp.',
    symbol: 'SUAC-UN',
  },
  {
    value: 'SVNA',
    label: 'SVNA,  7 Acquisition Corporation',
    name: '7 Acquisition Corporation',
    symbol: 'SVNA',
  },
  {
    value: 'SVNAU',
    label: 'SVNAU,  7 Acquisition Corporation',
    name: '7 Acquisition Corporation',
    symbol: 'SVNAU',
  },
  {
    value: 'SZZLU',
    label: 'SZZLU,  Sizzle Acquisition Corp.',
    name: 'Sizzle Acquisition Corp.',
    symbol: 'SZZLU',
  },
  {
    value: 'TBCPU',
    label: 'TBCPU,  Thunder Bridge Capital Partners III Inc.',
    name: 'Thunder Bridge Capital Partners III Inc.',
    symbol: 'TBCPU',
  },
  {
    value: 'TGAAU',
    label: 'TGAAU,  Target Global Acquisition I Corp.',
    name: 'Target Global Acquisition I Corp.',
    symbol: 'TGAAU',
  },
  {
    value: 'TGR-UN',
    label: 'TGR-UN,  Kimbell Tiger Acquisition Corporation',
    name: 'Kimbell Tiger Acquisition Corporation',
    symbol: 'TGR-UN',
  },
  {
    value: 'TGVCU',
    label: 'TGVCU,  TG Venture Acquisition Corp.',
    name: 'TG Venture Acquisition Corp.',
    symbol: 'TGVCU',
  },
  {
    value: 'THACU',
    label: 'THACU,  Thrive Acquisition Corporation',
    name: 'Thrive Acquisition Corporation',
    symbol: 'THACU',
  },
  {
    value: 'TIME',
    label: 'TIME,  Evolve Active Short Duration Bond Fund Hedged Units',
    name: 'Evolve Active Short Duration Bond Fund Hedged Units',
    symbol: 'TIME',
  },
  {
    value: 'TLF',
    label: 'TLF,  Tandy Leather Factory, Inc.',
    name: 'Tandy Leather Factory, Inc.',
    symbol: 'TLF',
  },
  {
    value: 'TLGA',
    label: 'TLGA,  TLG Acquisition One Corp.',
    name: 'TLG Acquisition One Corp.',
    symbol: 'TLGA',
  },
  {
    value: 'TLGA-UN',
    label: 'TLGA-UN,  TLG Acquisition One Corp.',
    name: 'TLG Acquisition One Corp.',
    symbol: 'TLGA-UN',
  },
  {
    value: 'TLGN',
    label: 'TLGN,  Ever Harvest International Group Inc.',
    name: 'Ever Harvest International Group Inc.',
    symbol: 'TLGN',
  },
  {
    value: 'TMNA',
    label: 'TMNA,  Tingo, Inc.',
    name: 'Tingo, Inc.',
    symbol: 'TMNA',
  },
  {
    value: 'TOACU',
    label: 'TOACU,  Talon 1 Acquisition Corp.',
    name: 'Talon 1 Acquisition Corp.',
    symbol: 'TOACU',
  },
  {
    value: 'TRAQ-UN',
    label: 'TRAQ-UN,  Trine II Acquisition Corp.',
    name: 'Trine II Acquisition Corp.',
    symbol: 'TRAQ-UN',
  },
  {
    value: 'TRCA-UN',
    label: 'TRCA-UN,  Twin Ridge Capital Acquisition Corp.',
    name: 'Twin Ridge Capital Acquisition Corp.',
    symbol: 'TRCA-UN',
  },
  {
    value: 'TRIS-UN',
    label: 'TRIS-UN,  Tristar Acquisition I Corp.',
    name: 'Tristar Acquisition I Corp.',
    symbol: 'TRIS-UN',
  },
  {
    value: 'TRONU',
    label: 'TRONU,  Corner Growth Acquisition Corp. 2',
    name: 'Corner Growth Acquisition Corp. 2',
    symbol: 'TRONU',
  },
  {
    value: 'TSPQ',
    label: 'TSPQ,  TCW Special Purpose Acquisition Corp.',
    name: 'TCW Special Purpose Acquisition Corp.',
    symbol: 'TSPQ',
  },
  {
    value: 'TSPQ-UN',
    label: 'TSPQ-UN,  TCW Special Purpose Acquisition Corp.',
    name: 'TCW Special Purpose Acquisition Corp.',
    symbol: 'TSPQ-UN',
  },
  {
    value: 'TWNI',
    label: 'TWNI,  Tailwind International Acquisition Corp.',
    name: 'Tailwind International Acquisition Corp.',
    symbol: 'TWNI',
  },
  {
    value: 'TWNI-UN',
    label: 'TWNI-UN,  Tailwind International Acquisition Corp.',
    name: 'Tailwind International Acquisition Corp.',
    symbol: 'TWNI-UN',
  },
  {
    value: 'TWOA',
    label: 'TWOA,  two',
    name: 'two',
    symbol: 'TWOA',
  },
  {
    value: 'UGHB',
    label: 'UGHB,  Universal Global Hub Inc.',
    name: 'Universal Global Hub Inc.',
    symbol: 'UGHB',
  },
  {
    value: 'UPTDU',
    label: 'UPTDU,  TradeUP Acquisition Corp.',
    name: 'TradeUP Acquisition Corp.',
    symbol: 'UPTDU',
  },
  {
    value: 'UPYY',
    label: 'UPYY,  UPAY, Inc.',
    name: 'UPAY, Inc.',
    symbol: 'UPYY',
  },
  {
    value: 'USCTU',
    label: 'USCTU,  TKB Critical Technologies 1',
    name: 'TKB Critical Technologies 1',
    symbol: 'USCTU',
  },
  {
    value: 'UTAAU',
    label: 'UTAAU,  UTA Acquisition Corporation',
    name: 'UTA Acquisition Corporation',
    symbol: 'UTAAU',
  },
  {
    value: 'VCXB-UN',
    label: 'VCXB-UN,  10X Capital Venture Acquisition Corp. III',
    name: '10X Capital Venture Acquisition Corp. III',
    symbol: 'VCXB-UN',
  },
  {
    value: 'VHAQ-UN',
    label: 'VHAQ-UN,  Viveon Health Acquisition Corp.',
    name: 'Viveon Health Acquisition Corp.',
    symbol: 'VHAQ-UN',
  },
  {
    value: 'VIEWW',
    label: 'VIEWW,  View, Inc.',
    name: 'View, Inc.',
    symbol: 'VIEWW',
  },
  {
    value: 'VR',
    label: 'VR,  Global X Metaverse ETF',
    name: 'Global X Metaverse ETF',
    symbol: 'VR',
  },
  {
    value: 'VSACU',
    label: 'VSACU,  Vision Sensing Acquisition Corp.',
    name: 'Vision Sensing Acquisition Corp.',
    symbol: 'VSACU',
  },
  {
    value: 'VVX',
    label: 'VVX,  V2X, Inc.',
    name: 'V2X, Inc.',
    symbol: 'VVX',
  },
  {
    value: 'WBSR',
    label: 'WBSR,  Webstar Technology Group, Inc.',
    name: 'Webstar Technology Group, Inc.',
    symbol: 'WBSR',
  },
  {
    value: 'WEL-UN',
    label: 'WEL-UN,  Integrated Wellness Acquisition Corp',
    name: 'Integrated Wellness Acquisition Corp',
    symbol: 'WEL-UN',
  },
  {
    value: 'WETH',
    label: 'WETH,  Wetouch Technology Inc.',
    name: 'Wetouch Technology Inc.',
    symbol: 'WETH',
  },
  {
    value: 'WNNR-UN',
    label: 'WNNR-UN,  Andretti Acquisition Corp.',
    name: 'Andretti Acquisition Corp.',
    symbol: 'WNNR-UN',
  },
  {
    value: 'WPCA',
    label: 'WPCA,  Warburg Pincus Capital Corporation I-A',
    name: 'Warburg Pincus Capital Corporation I-A',
    symbol: 'WPCA',
  },
  {
    value: 'WPCA-UN',
    label: 'WPCA-UN,  Warburg Pincus Capital Corporation I-A',
    name: 'Warburg Pincus Capital Corporation I-A',
    symbol: 'WPCA-UN',
  },
  {
    value: 'WPCB',
    label: 'WPCB,  Warburg Pincus Capital Corporation I-B',
    name: 'Warburg Pincus Capital Corporation I-B',
    symbol: 'WPCB',
  },
  {
    value: 'WPCB-UN',
    label: 'WPCB-UN,  Warburg Pincus Capital Corporation I-B',
    name: 'Warburg Pincus Capital Corporation I-B',
    symbol: 'WPCB-UN',
  },
  {
    value: 'WQGA-UN',
    label: 'WQGA-UN,  World Quantum Growth Acquisition Corp.',
    name: 'World Quantum Growth Acquisition Corp.',
    symbol: 'WQGA-UN',
  },
  {
    value: 'WTMAU',
    label: 'WTMAU,  Welsbach Technology Metals Acquisition Corp.',
    name: 'Welsbach Technology Metals Acquisition Corp.',
    symbol: 'WTMAU',
  },
  {
    value: 'WWACU',
    label: 'WWACU,  Worldwide Webb Acquisition Corp.',
    name: 'Worldwide Webb Acquisition Corp.',
    symbol: 'WWACU',
  },
  {
    value: 'XESP',
    label: 'XESP,  Electronic Servitor Publication Network, Inc.',
    name: 'Electronic Servitor Publication Network, Inc.',
    symbol: 'XESP',
  },
  {
    value: 'XFCI',
    label: 'XFCI,  Xtreme Fighting Championships, Inc.',
    name: 'Xtreme Fighting Championships, Inc.',
    symbol: 'XFCI',
  },
  {
    value: 'XFINU',
    label: 'XFINU,  ExcelFin Acquisition Corp.',
    name: 'ExcelFin Acquisition Corp.',
    symbol: 'XFINU',
  },
  {
    value: 'XPDBU',
    label: 'XPDBU,  Power & Digital Infrastructure Acquisition II Corp.',
    name: 'Power & Digital Infrastructure Acquisition II Corp.',
    symbol: 'XPDBU',
  },
  {
    value: 'YJGJ',
    label: 'YJGJ,  Yijia Group Corp.',
    name: 'Yijia Group Corp.',
    symbol: 'YJGJ',
  },
  {
    value: 'ZINGU',
    label: 'ZINGU,  FTAC Zeus Acquisition Corp.',
    name: 'FTAC Zeus Acquisition Corp.',
    symbol: 'ZINGU',
  },
  {
    value: 'ZPAL.SW',
    label: 'ZPAL.SW,  ZKB Palladium ETF',
    name: 'ZKB Palladium ETF',
    symbol: 'ZPAL.SW',
  },
  {
    value: 'ZT',
    label: 'ZT,  Zimmer Energy Transition Acquisition Corp.',
    name: 'Zimmer Energy Transition Acquisition Corp.',
    symbol: 'ZT',
  },
  {
    value: 'ZTAQU',
    label: 'ZTAQU,  Zimmer Energy Transition Acquisition Corp.',
    name: 'Zimmer Energy Transition Acquisition Corp.',
    symbol: 'ZTAQU',
  },
  {
    value: '6610.HK',
    label: '6610.HK,  Flowing Cloud Technology Ltd',
    name: 'Flowing Cloud Technology Ltd',
    symbol: '6610.HK',
  },
  {
    value: 'MRND',
    label: 'MRND,  IQ U.S. Mid Cap R&D Leaders ETF',
    name: 'IQ U.S. Mid Cap R&D Leaders ETF',
    symbol: 'MRND',
  },
  {
    value: 'AQUNR',
    label: 'AQUNR,  Aquaron Acquisition Corp.',
    name: 'Aquaron Acquisition Corp.',
    symbol: 'AQUNR',
  },
  {
    value: 'AQU',
    label: 'AQU,  Aquaron Acquisition Corp.',
    name: 'Aquaron Acquisition Corp.',
    symbol: 'AQU',
  },
  {
    value: 'SND.L',
    label: 'SND.L,  Sondrel (Holdings) plc',
    name: 'Sondrel (Holdings) plc',
    symbol: 'SND.L',
  },
  {
    value: 'PRME',
    label: 'PRME,  Prime Medicine, Inc.',
    name: 'Prime Medicine, Inc.',
    symbol: 'PRME',
  },
  {
    value: 'PDBA',
    label: 'PDBA,  Invesco Agriculture Commodity Strategy No K-1 ETF',
    name: 'Invesco Agriculture Commodity Strategy No K-1 ETF',
    symbol: 'PDBA',
  },
  {
    value: 'DILRX',
    label: 'DILRX,  DFA - International Large Cap Growth Portfolio',
    name: 'DFA - International Large Cap Growth Portfolio',
    symbol: 'DILRX',
  },
  {
    value: 'COHR',
    label: 'COHR,  Coherent Corp.',
    name: 'Coherent Corp.',
    symbol: 'COHR',
  },
  {
    value: 'RBC',
    label: 'RBC,  RBC Bearings Incorporated',
    name: 'RBC Bearings Incorporated',
    symbol: 'RBC',
  },
  {
    value: 'RTO',
    label: 'RTO,  Rentokil Initial plc',
    name: 'Rentokil Initial plc',
    symbol: 'RTO',
  },
  {
    value: 'IDS.L',
    label: 'IDS.L,  International Distributions Services plc',
    name: 'International Distributions Services plc',
    symbol: 'IDS.L',
  },
  {
    value: 'LIVB',
    label: 'LIVB,  LIV Capital Acquisition Corp. II',
    name: 'LIV Capital Acquisition Corp. II',
    symbol: 'LIVB',
  },
  {
    value: 'BROOK.BK',
    label: 'BROOK.BK,  The Brooker Group Public Company Limited',
    name: 'The Brooker Group Public Company Limited',
    symbol: 'BROOK.BK',
  },
  {
    value: 'MBGYY',
    label: 'MBGYY,  Mercedes-Benz Group AG',
    name: 'Mercedes-Benz Group AG',
    symbol: 'MBGYY',
  },
  {
    value: 'MNK',
    label: 'MNK,  Mallinckrodt plc',
    name: 'Mallinckrodt plc',
    symbol: 'MNK',
  },
  {
    value: 'P911.DE',
    label: 'P911.DE,  Dr. Ing. h.c. F. Porsche AG',
    name: 'Dr. Ing. h.c. F. Porsche AG',
    symbol: 'P911.DE',
  },
  {
    value: '300845.SZ',
    label: '300845.SZ,  Zhengzhou Jiean Hi-Tech Co.,Ltd.',
    name: 'Zhengzhou Jiean Hi-Tech Co.,Ltd.',
    symbol: '300845.SZ',
  },
  {
    value: '300730.SZ',
    label: '300730.SZ,  Hunan Creator Information Technologies CO., LTD.',
    name: 'Hunan Creator Information Technologies CO., LTD.',
    symbol: '300730.SZ',
  },
  {
    value: '300235.SZ',
    label: '300235.SZ,  Shenzhen Kingsun Science & Technology Co.,Ltd',
    name: 'Shenzhen Kingsun Science & Technology Co.,Ltd',
    symbol: '300235.SZ',
  },
  {
    value: '300167.SZ',
    label: '300167.SZ,  Shenzhen Division Co.,Ltd.',
    name: 'Shenzhen Division Co.,Ltd.',
    symbol: '300167.SZ',
  },
  {
    value: '300826.SZ',
    label:
      '300826.SZ,  NanJing Research Institute of Surveying, Mapping & Geotechnical Investigation, Co.Ltd',
    name: 'NanJing Research Institute of Surveying, Mapping & Geotechnical Investigation, Co.Ltd',
    symbol: '300826.SZ',
  },
  {
    value: '300609.SZ',
    label: '300609.SZ,  Winner Technology Co., Inc.',
    name: 'Winner Technology Co., Inc.',
    symbol: '300609.SZ',
  },
  {
    value: '300399.SZ',
    label: '300399.SZ,  Jiangxi Tianli Technology, INC.',
    name: 'Jiangxi Tianli Technology, INC.',
    symbol: '300399.SZ',
  },
  {
    value: '300605.SZ',
    label: '300605.SZ,  Hengfeng Information Technology Co., Ltd.',
    name: 'Hengfeng Information Technology Co., Ltd.',
    symbol: '300605.SZ',
  },
  {
    value: '688191.SS',
    label: '688191.SS,  Zhiyang Innovation Technology Co., Ltd.',
    name: 'Zhiyang Innovation Technology Co., Ltd.',
    symbol: '688191.SS',
  },
  {
    value: '300380.SZ',
    label: '300380.SZ,  Shanghai Amarsoft Information & Technology Co.,Ltd',
    name: 'Shanghai Amarsoft Information & Technology Co.,Ltd',
    symbol: '300380.SZ',
  },
  {
    value: '300682.SZ',
    label: '300682.SZ,  Longshine Technology Group Co., Ltd.',
    name: 'Longshine Technology Group Co., Ltd.',
    symbol: '300682.SZ',
  },
  {
    value: 'ACLN.SW',
    label: 'ACLN.SW,  Accelleron Industries AG',
    name: 'Accelleron Industries AG',
    symbol: 'ACLN.SW',
  },
  {
    value: 'MCSE',
    label: 'MCSE,  Martin Currie Sustainable International Equity ETF',
    name: 'Martin Currie Sustainable International Equity ETF',
    symbol: 'MCSE',
  },
  {
    value: 'SVIIR',
    label: 'SVIIR,  Spring Valley Acquisition Corp. II',
    name: 'Spring Valley Acquisition Corp. II',
    symbol: 'SVIIR',
  },
  {
    value: '2367.HK',
    label: '2367.HK,  Giant Biogene Holding Co., Ltd.',
    name: 'Giant Biogene Holding Co., Ltd.',
    symbol: '2367.HK',
  },
  {
    value: 'SVII',
    label: 'SVII,  Spring Valley Acquisition Corp. II',
    name: 'Spring Valley Acquisition Corp. II',
    symbol: 'SVII',
  },
  {
    value: 'SVIIW',
    label: 'SVIIW,  Spring Valley Acquisition Corp. II',
    name: 'Spring Valley Acquisition Corp. II',
    symbol: 'SVIIW',
  },
  {
    value: 'DDRIL.OL',
    label: 'DDRIL.OL,  Dolphin Drilling AS',
    name: 'Dolphin Drilling AS',
    symbol: 'DDRIL.OL',
  },
  {
    value: 'SLNA',
    label: 'SLNA,  Selina Hospitality PLC',
    name: 'Selina Hospitality PLC',
    symbol: 'SLNA',
  },
  {
    value: 'SLNAW',
    label: 'SLNAW,  Selina Hospitality PLC',
    name: 'Selina Hospitality PLC',
    symbol: 'SLNAW',
  },
  {
    value: 'IMCIW',
    label: 'IMCIW,  Infinite Group, Inc.',
    name: 'Infinite Group, Inc.',
    symbol: 'IMCIW',
  },
  {
    value: '0P00019O2I.F',
    label: '0P00019O2I.F,  Azvalor Blue Chips FI',
    name: 'Azvalor Blue Chips FI',
    symbol: '0P00019O2I.F',
  },
  {
    value: '0P0001F2GG.F',
    label: '0P0001F2GG.F,  Azvalor Managers FI',
    name: 'Azvalor Managers FI',
    symbol: '0P0001F2GG.F',
  },
  {
    value: '0P00016WWL.F',
    label: '0P00016WWL.F,  Azvalor Capital FI',
    name: 'Azvalor Capital FI',
    symbol: '0P00016WWL.F',
  },
  {
    value: '0P00016YQ5.F',
    label: '0P00016YQ5.F,  Azvalor Internacional FI',
    name: 'Azvalor Internacional FI',
    symbol: '0P00016YQ5.F',
  },
  {
    value: '0P00016YQ4.F',
    label: '0P00016YQ4.F,  Azvalor Iberia FI',
    name: 'Azvalor Iberia FI',
    symbol: '0P00016YQ4.F',
  },
  {
    value: 'GDIG.CN',
    label: 'GDIG.CN,  Gold Digger Resources Inc.',
    name: 'Gold Digger Resources Inc.',
    symbol: 'GDIG.CN',
  },
  {
    value: 'BWN.L',
    label: 'BWN.L,  Bowen Fintech Plc',
    name: 'Bowen Fintech Plc',
    symbol: 'BWN.L',
  },
  {
    value: '2420.HK',
    label: '2420.HK,  Zibuyu Group Limited',
    name: 'Zibuyu Group Limited',
    symbol: '2420.HK',
  },
  {
    value: 'KETR.JK',
    label: 'KETR.JK,  PT Ketrosden Triasmitra',
    name: 'PT Ketrosden Triasmitra',
    symbol: 'KETR.JK',
  },
  {
    value: 'KDTN.JK',
    label: 'KDTN.JK,  PT Puri Sentul Permai Tbk',
    name: 'PT Puri Sentul Permai Tbk',
    symbol: 'KDTN.JK',
  },
  {
    value: 'ACDCW',
    label: 'ACDCW,  ProFrac Holding Corp.',
    name: 'ProFrac Holding Corp.',
    symbol: 'ACDCW',
  },
  {
    value: '300095.SZ',
    label: '300095.SZ,  Jiangxi Huawu Brake Co.,Ltd.',
    name: 'Jiangxi Huawu Brake Co.,Ltd.',
    symbol: '300095.SZ',
  },
  {
    value: 'CMNT',
    label: 'CMNT,  China Mulans Nano Technology Corp., Ltd.',
    name: 'China Mulans Nano Technology Corp., Ltd.',
    symbol: 'CMNT',
  },
  {
    value: '300249.SZ',
    label: '300249.SZ,  Yimikang Tech.Group Co., Ltd.',
    name: 'Yimikang Tech.Group Co., Ltd.',
    symbol: '300249.SZ',
  },
  {
    value: 'ARCI.JK',
    label: 'ARCI.JK,  PT Archi Indonesia Tbk',
    name: 'PT Archi Indonesia Tbk',
    symbol: 'ARCI.JK',
  },
  {
    value: 'CHEM.JK',
    label: 'CHEM.JK,  PT Chemstar Indonesia Tbk',
    name: 'PT Chemstar Indonesia Tbk',
    symbol: 'CHEM.JK',
  },
  {
    value: 'CMNT.JK',
    label: 'CMNT.JK,  PT Cemindo Gemilang Tbk',
    name: 'PT Cemindo Gemilang Tbk',
    symbol: 'CMNT.JK',
  },
  {
    value: 'CTBN.JK',
    label: 'CTBN.JK,  PT Citra Tubindo Tbk',
    name: 'PT Citra Tubindo Tbk',
    symbol: 'CTBN.JK',
  },
  {
    value: 'FASW.JK',
    label: 'FASW.JK,  PT Fajar Surya Wisesa Tbk',
    name: 'PT Fajar Surya Wisesa Tbk',
    symbol: 'FASW.JK',
  },
  {
    value: 'KKES.JK',
    label: 'KKES.JK,  PT Kusuma Kemindo Sentosa Tbk',
    name: 'PT Kusuma Kemindo Sentosa Tbk',
    symbol: 'KKES.JK',
  },
  {
    value: 'NICL.JK',
    label: 'NICL.JK,  PT PAM Mineral Tbk',
    name: 'PT PAM Mineral Tbk',
    symbol: 'NICL.JK',
  },
  {
    value: 'NPGF.JK',
    label: 'NPGF.JK,  PT Nusa Palapa Gemilang Tbk',
    name: 'PT Nusa Palapa Gemilang Tbk',
    symbol: 'NPGF.JK',
  },
  {
    value: 'OBMD.JK',
    label: 'OBMD.JK,  PT OBM Drilchem Tbk',
    name: 'PT OBM Drilchem Tbk',
    symbol: 'OBMD.JK',
  },
  {
    value: 'SBMA.JK',
    label: 'SBMA.JK,  PT Surya Biru Murni Acetylene Tbk',
    name: 'PT Surya Biru Murni Acetylene Tbk',
    symbol: 'SBMA.JK',
  },
  {
    value: 'BAUT.JK',
    label: 'BAUT.JK,  PT Mitra Angkasa Sejahtera Tbk',
    name: 'PT Mitra Angkasa Sejahtera Tbk',
    symbol: 'BAUT.JK',
  },
  {
    value: 'BIKE.JK',
    label: 'BIKE.JK,  PT Sepeda Bersama Indonesia Tbk',
    name: 'PT Sepeda Bersama Indonesia Tbk',
    symbol: 'BIKE.JK',
  },
  {
    value: 'DEPO.JK',
    label: 'DEPO.JK,  PT Caturkarda Depo Bangunan Tbk',
    name: 'PT Caturkarda Depo Bangunan Tbk',
    symbol: 'DEPO.JK',
  },
  {
    value: 'DRMA.JK',
    label: 'DRMA.JK,  PT Dharma Polimetal Tbk',
    name: 'PT Dharma Polimetal Tbk',
    symbol: 'DRMA.JK',
  },
  {
    value: 'ENAK.JK',
    label: 'ENAK.JK,  PT Champ Resto Indonesia Tbk',
    name: 'PT Champ Resto Indonesia Tbk',
    symbol: 'ENAK.JK',
  },
  {
    value: 'IDEA.JK',
    label: 'IDEA.JK,  PT Idea Indonesia Akademi Tbk',
    name: 'PT Idea Indonesia Akademi Tbk',
    symbol: 'IDEA.JK',
  },
  {
    value: 'KLIN.JK',
    label: 'KLIN.JK,  PT Klinko Karya Imaji Tbk',
    name: 'PT Klinko Karya Imaji Tbk',
    symbol: 'KLIN.JK',
  },
  {
    value: 'LFLO.JK',
    label: 'LFLO.JK,  PT Imago Mulia Persada Tbk',
    name: 'PT Imago Mulia Persada Tbk',
    symbol: 'LFLO.JK',
  },
  {
    value: 'LUCY.JK',
    label: 'LUCY.JK,  PT Lima Dua Lima Tiga Tbk',
    name: 'PT Lima Dua Lima Tiga Tbk',
    symbol: 'LUCY.JK',
  },
  {
    value: 'MGLV.JK',
    label: 'MGLV.JK,  PT Panca Anugrah Wisesa Tbk',
    name: 'PT Panca Anugrah Wisesa Tbk',
    symbol: 'MGLV.JK',
  },
  {
    value: 'NETV.JK',
    label: 'NETV.JK,  PT Net Visi Media Tbk',
    name: 'PT Net Visi Media Tbk',
    symbol: 'NETV.JK',
  },
  {
    value: 'OLIV.JK',
    label: 'OLIV.JK,  PT Oscar Mitra Sukses Sejahtera Tbk',
    name: 'PT Oscar Mitra Sukses Sejahtera Tbk',
    symbol: 'OLIV.JK',
  },
  {
    value: 'RAFI.JK',
    label: 'RAFI.JK,  PT Sari Kreasi Boga Tbk',
    name: 'PT Sari Kreasi Boga Tbk',
    symbol: 'RAFI.JK',
  },
  {
    value: 'SCNP.JK',
    label: 'SCNP.JK,  PT Selaras Citra Nusantara Perkasa Tbk',
    name: 'PT Selaras Citra Nusantara Perkasa Tbk',
    symbol: 'SCNP.JK',
  },
  {
    value: 'SNLK.JK',
    label: 'SNLK.JK,  PT Sunter Lakeside Hotel Tbk',
    name: 'PT Sunter Lakeside Hotel Tbk',
    symbol: 'SNLK.JK',
  },
  {
    value: 'SOFA.JK',
    label: 'SOFA.JK,  PT Boston Furniture Industries Tbk',
    name: 'PT Boston Furniture Industries Tbk',
    symbol: 'SOFA.JK',
  },
  {
    value: 'TOOL.JK',
    label: 'TOOL.JK,  PT Rohartindo Nusantara Luas Tbk',
    name: 'PT Rohartindo Nusantara Luas Tbk',
    symbol: 'TOOL.JK',
  },
  {
    value: 'AMMS.JK',
    label: 'AMMS.JK,  PT Agung Menjangan Mas Tbk',
    name: 'PT Agung Menjangan Mas Tbk',
    symbol: 'AMMS.JK',
  },
  {
    value: 'ASHA.JK',
    label: 'ASHA.JK,  PT Cilacap Samudera Fishing Industry Tbk',
    name: 'PT Cilacap Samudera Fishing Industry Tbk',
    symbol: 'ASHA.JK',
  },
  {
    value: 'BOBA.JK',
    label: 'BOBA.JK,  PT Formosa Ingredient Factory Tbk',
    name: 'PT Formosa Ingredient Factory Tbk',
    symbol: 'BOBA.JK',
  },
  {
    value: 'BUAH.JK',
    label: 'BUAH.JK,  PT Segar Kumala Indonesia Tbk',
    name: 'PT Segar Kumala Indonesia Tbk',
    symbol: 'BUAH.JK',
  },
  {
    value: 'CMRY.JK',
    label: 'CMRY.JK,  PT Cisarua Mountain Dairy Tbk',
    name: 'PT Cisarua Mountain Dairy Tbk',
    symbol: 'CMRY.JK',
  },
  {
    value: 'CRAB.JK',
    label: 'CRAB.JK,  PT Toba Surimi Industries Tbk',
    name: 'PT Toba Surimi Industries Tbk',
    symbol: 'CRAB.JK',
  },
  {
    value: 'EURO.JK',
    label: 'EURO.JK,  PT Estee Gold Feet Tbk',
    name: 'PT Estee Gold Feet Tbk',
    symbol: 'EURO.JK',
  },
  {
    value: 'FLMC.JK',
    label: 'FLMC.JK,  PT Falmaco Nonwoven Industri Tbk',
    name: 'PT Falmaco Nonwoven Industri Tbk',
    symbol: 'FLMC.JK',
  },
  {
    value: 'GULA.JK',
    label: 'GULA.JK,  PT Aman Agrindo Tbk',
    name: 'PT Aman Agrindo Tbk',
    symbol: 'GULA.JK',
  },
  {
    value: 'IBOS.JK',
    label: 'IBOS.JK,  PT Indo Boga Sukses Tbk',
    name: 'PT Indo Boga Sukses Tbk',
    symbol: 'IBOS.JK',
  },
  {
    value: 'IPPE.JK',
    label: 'IPPE.JK,  PT Indo Pureco Pratama Tbk',
    name: 'PT Indo Pureco Pratama Tbk',
    symbol: 'IPPE.JK',
  },
  {
    value: 'JARR.JK',
    label: 'JARR.JK,  PT Jhonlin Agro Raya Tbk',
    name: 'PT Jhonlin Agro Raya Tbk',
    symbol: 'JARR.JK',
  },
  {
    value: 'NANO.JK',
    label: 'NANO.JK,  PT Nanotech Indonesia Global Tbk',
    name: 'PT Nanotech Indonesia Global Tbk',
    symbol: 'NANO.JK',
  },
  {
    value: 'NASI.JK',
    label: 'NASI.JK,  PT Wahana Inti Makmur Tbk',
    name: 'PT Wahana Inti Makmur Tbk',
    symbol: 'NASI.JK',
  },
  {
    value: 'OILS.JK',
    label: 'OILS.JK,  PT Indo Oil Perkasa Tbk',
    name: 'PT Indo Oil Perkasa Tbk',
    symbol: 'OILS.JK',
  },
  {
    value: 'STAA.JK',
    label: 'STAA.JK,  PT Sumber Tani Agung Resources Tbk',
    name: 'PT Sumber Tani Agung Resources Tbk',
    symbol: 'STAA.JK',
  },
  {
    value: 'TAPG.JK',
    label: 'TAPG.JK,  PT Triputra Agro Persada Tbk',
    name: 'PT Triputra Agro Persada Tbk',
    symbol: 'TAPG.JK',
  },
  {
    value: 'TAYS.JK',
    label: 'TAYS.JK,  PT Jaya Swarasa Agung Tbk',
    name: 'PT Jaya Swarasa Agung Tbk',
    symbol: 'TAYS.JK',
  },
  {
    value: 'TLDN.JK',
    label: 'TLDN.JK,  PT Teladan Prima Agro Tbk',
    name: 'PT Teladan Prima Agro Tbk',
    symbol: 'TLDN.JK',
  },
  {
    value: 'TRGU.JK',
    label: 'TRGU.JK,  PT Cerestar Indonesia Tbk',
    name: 'PT Cerestar Indonesia Tbk',
    symbol: 'TRGU.JK',
  },
  {
    value: 'WMPP.JK',
    label: 'WMPP.JK,  PT Widodo Makmur Perkasa Tbk',
    name: 'PT Widodo Makmur Perkasa Tbk',
    symbol: 'WMPP.JK',
  },
  {
    value: 'BSML.JK',
    label: 'BSML.JK,  PT Bintang Samudera Mandiri Lines Tbk',
    name: 'PT Bintang Samudera Mandiri Lines Tbk',
    symbol: 'BSML.JK',
  },
  {
    value: 'COAL.JK',
    label: 'COAL.JK,  PT Black Diamond Resources Tbk',
    name: 'PT Black Diamond Resources Tbk',
    symbol: 'COAL.JK',
  },
  {
    value: 'GEMS.JK',
    label: 'GEMS.JK,  PT Golden Energy Mines Tbk',
    name: 'PT Golden Energy Mines Tbk',
    symbol: 'GEMS.JK',
  },
  {
    value: 'GTSI.JK',
    label: 'GTSI.JK,  PT GTS Internasional Tbk',
    name: 'PT GTS Internasional Tbk',
    symbol: 'GTSI.JK',
  },
  {
    value: 'MCOL.JK',
    label: 'MCOL.JK,  PT Prima Andalan Mandiri Tbk',
    name: 'PT Prima Andalan Mandiri Tbk',
    symbol: 'MCOL.JK',
  },
  {
    value: 'MITI.JK',
    label: 'MITI.JK,  PT Mitra Investindo Tbk',
    name: 'PT Mitra Investindo Tbk',
    symbol: 'MITI.JK',
  },
  {
    value: 'MTFN.JK',
    label: 'MTFN.JK,  PT Capitalinc Investment Tbk',
    name: 'PT Capitalinc Investment Tbk',
    symbol: 'MTFN.JK',
  },
  {
    value: 'RMKE.JK',
    label: 'RMKE.JK,  PT RMK Energy Tbk',
    name: 'PT RMK Energy Tbk',
    symbol: 'RMKE.JK',
  },
  {
    value: 'SEMA.JK',
    label: 'SEMA.JK,  PT Semacom Integrated Tbk',
    name: 'PT Semacom Integrated Tbk',
    symbol: 'SEMA.JK',
  },
  {
    value: 'BBSI.JK',
    label: 'BBSI.JK,  PT Bank Bisnis Internasional, Tbk',
    name: 'PT Bank Bisnis Internasional, Tbk',
    symbol: 'BBSI.JK',
  },
  {
    value: 'MASB.JK',
    label: 'MASB.JK,  PT Bank Multiarta Sentosa Tbk',
    name: 'PT Bank Multiarta Sentosa Tbk',
    symbol: 'MASB.JK',
  },
  {
    value: 'BMHS.JK',
    label: 'BMHS.JK,  PT Bundamedik Tbk',
    name: 'PT Bundamedik Tbk',
    symbol: 'BMHS.JK',
  },
  {
    value: 'MEDS.JK',
    label: 'MEDS.JK,  PT Hetzer Medical Indonesia Tbk',
    name: 'PT Hetzer Medical Indonesia Tbk',
    symbol: 'MEDS.JK',
  },
  {
    value: 'MTMH.JK',
    label: 'MTMH.JK,  PT Murni Sadar Tbk',
    name: 'PT Murni Sadar Tbk',
    symbol: 'MTMH.JK',
  },
  {
    value: 'RSGK.JK',
    label: 'RSGK.JK,  PT Kedoya Adyaraya Tbk',
    name: 'PT Kedoya Adyaraya Tbk',
    symbol: 'RSGK.JK',
  },
  {
    value: 'SOHO.JK',
    label: 'SOHO.JK,  PT Soho Global Health Tbk',
    name: 'PT Soho Global Health Tbk',
    symbol: 'SOHO.JK',
  },
  {
    value: 'GPSO.JK',
    label: 'GPSO.JK,  PT Geoprima Solusi Tbk',
    name: 'PT Geoprima Solusi Tbk',
    symbol: 'GPSO.JK',
  },
  {
    value: 'KUAS.JK',
    label: 'KUAS.JK,  PT Ace Oldfields Tbk',
    name: 'PT Ace Oldfields Tbk',
    symbol: 'KUAS.JK',
  },
  {
    value: 'LABA.JK',
    label: 'LABA.JK,  PT Ladang Baja Murni Tbk',
    name: 'PT Ladang Baja Murni Tbk',
    symbol: 'LABA.JK',
  },
  {
    value: 'ARKO.JK',
    label: 'ARKO.JK,  PT. Arkora Hydro Tbk',
    name: 'PT. Arkora Hydro Tbk',
    symbol: 'ARKO.JK',
  },
  {
    value: 'CASS.JK',
    label: 'CASS.JK,  PT Cardig Aero Services Tbk',
    name: 'PT Cardig Aero Services Tbk',
    symbol: 'CASS.JK',
  },
  {
    value: 'FIMP.JK',
    label: 'FIMP.JK,  PT Fimperkasa Utama Tbk',
    name: 'PT Fimperkasa Utama Tbk',
    symbol: 'FIMP.JK',
  },
  {
    value: 'GOLD.JK',
    label: 'GOLD.JK,  PT Visi Telekomunikasi Infrastruktur Tbk',
    name: 'PT Visi Telekomunikasi Infrastruktur Tbk',
    symbol: 'GOLD.JK',
  },
  {
    value: 'KRYA.JK',
    label: 'KRYA.JK,  PT Bangun Karya Perkasa Jaya Tbk',
    name: 'PT Bangun Karya Perkasa Jaya Tbk',
    symbol: 'KRYA.JK',
  },
  {
    value: 'MORA.JK',
    label: 'MORA.JK,  PT Mora Telematika Indonesia',
    name: 'PT Mora Telematika Indonesia',
    symbol: 'MORA.JK',
  },
  {
    value: 'SMKM.JK',
    label: 'SMKM.JK,  PT Sumber Mas Konstruksi Tbk',
    name: 'PT Sumber Mas Konstruksi Tbk',
    symbol: 'SMKM.JK',
  },
  {
    value: 'SUPR.JK',
    label: 'SUPR.JK,  PT Solusi Tunas Pratama Tbk',
    name: 'PT Solusi Tunas Pratama Tbk',
    symbol: 'SUPR.JK',
  },
  {
    value: 'ADCP.JK',
    label: 'ADCP.JK,  PT Adhi Commuter Properti',
    name: 'PT Adhi Commuter Properti',
    symbol: 'ADCP.JK',
  },
  {
    value: 'IPAC.JK',
    label: 'IPAC.JK,  PT Era Graharealty Tbk',
    name: 'PT Era Graharealty Tbk',
    symbol: 'IPAC.JK',
  },
  {
    value: 'PLIN.JK',
    label: 'PLIN.JK,  PT Plaza Indonesia Realty Tbk',
    name: 'PT Plaza Indonesia Realty Tbk',
    symbol: 'PLIN.JK',
  },
  {
    value: 'SWID.JK',
    label: 'SWID.JK,  PT Saraswanti Indoland Development Tbk',
    name: 'PT Saraswanti Indoland Development Tbk',
    symbol: 'SWID.JK',
  },
  {
    value: 'TRUE.JK',
    label: 'TRUE.JK,  PT Triniti Dinamik',
    name: 'PT Triniti Dinamik',
    symbol: 'TRUE.JK',
  },
  {
    value: 'WINR.JK',
    label: 'WINR.JK,  PT Winner Nusantara Jaya Tbk',
    name: 'PT Winner Nusantara Jaya Tbk',
    symbol: 'WINR.JK',
  },
  {
    value: 'AXIO.JK',
    label: 'AXIO.JK,  PT Tera Data Indonusa Tbk',
    name: 'PT Tera Data Indonusa Tbk',
    symbol: 'AXIO.JK',
  },
  {
    value: 'WIRG.JK',
    label: 'WIRG.JK,  PT WIR ASIA Tbk',
    name: 'PT WIR ASIA Tbk',
    symbol: 'WIRG.JK',
  },
  {
    value: 'CMPP.JK',
    label: 'CMPP.JK,  PT AirAsia Indonesia Tbk',
    name: 'PT AirAsia Indonesia Tbk',
    symbol: 'CMPP.JK',
  },
  {
    value: 'ELPI.JK',
    label: 'ELPI.JK,  PT Pelayaran Nasional Ekalya Purnamasari Tbk',
    name: 'PT Pelayaran Nasional Ekalya Purnamasari Tbk',
    symbol: 'ELPI.JK',
  },
  {
    value: 'HAIS.JK',
    label: 'HAIS.JK,  PT Hasnur Internasional Shipping Tbk',
    name: 'PT Hasnur Internasional Shipping Tbk',
    symbol: 'HAIS.JK',
  },
  {
    value: 'HATM.JK',
    label: 'HATM.JK,  PT Habco Trans Maritima Tbk',
    name: 'PT Habco Trans Maritima Tbk',
    symbol: 'HATM.JK',
  },
  {
    value: 'RCCC.JK',
    label: 'RCCC.JK,  PT Utama Radar Cahaya Tbk',
    name: 'PT Utama Radar Cahaya Tbk',
    symbol: 'RCCC.JK',
  },
  {
    value: 'PLTNU',
    label: 'PLTNU,  Plutonian Acquisition Corp. Unit',
    name: 'Plutonian Acquisition Corp. Unit',
    symbol: 'PLTNU',
  },
  {
    value: 'GLST',
    label: 'GLST,  Global Star Acquisition, Inc. Class A Common Stock',
    name: 'Global Star Acquisition, Inc. Class A Common Stock',
    symbol: 'GLST',
  },
  {
    value: 'SNAL',
    label: 'SNAL,  Snail, Inc. Class A Common Stock',
    name: 'Snail, Inc. Class A Common Stock',
    symbol: 'SNAL',
  },
  {
    value: 'UNOMINDA.NS',
    label: 'UNOMINDA.NS,  UNO Minda Limited',
    name: 'UNO Minda Limited',
    symbol: 'UNOMINDA.NS',
  },
  {
    value: 'FIAEX',
    label: 'FIAEX,  Fidelity Sustainable Core Plus Bond Fund',
    name: 'Fidelity Sustainable Core Plus Bond Fund',
    symbol: 'FIAEX',
  },
  {
    value: 'FSIKX',
    label: 'FSIKX,  Fidelity Sustainable Intermediate Municipal Income Fund',
    name: 'Fidelity Sustainable Intermediate Municipal Income Fund',
    symbol: 'FSIKX',
  },
  {
    value: 'HBISD',
    label: 'HBISD,  Home Bistro Inc.',
    name: 'Home Bistro Inc.',
    symbol: 'HBISD',
  },
  {
    value: 'FAPGX',
    label: 'FAPGX,  Fidelity Sustainable Low Duration Bond Fund',
    name: 'Fidelity Sustainable Low Duration Bond Fund',
    symbol: 'FAPGX',
  },
  {
    value: 'FSYJX',
    label: 'FSYJX,  Fidelity Sustainable Emerging Markets Equity Fund',
    name: 'Fidelity Sustainable Emerging Markets Equity Fund',
    symbol: 'FSYJX',
  },
  {
    value: 'AFM.V',
    label: 'AFM.V,  Alphamin Resources Corp.',
    name: 'Alphamin Resources Corp.',
    symbol: 'AFM.V',
  },
  {
    value: 'ARIS.TO',
    label: 'ARIS.TO,  Aris Mining Corporation',
    name: 'Aris Mining Corporation',
    symbol: 'ARIS.TO',
  },
  {
    value: 'CCCM.V',
    label: 'CCCM.V,  C3 Metals Inc.',
    name: 'C3 Metals Inc.',
    symbol: 'CCCM.V',
  },
  {
    value: 'GSHR.V',
    label: 'GSHR.V,  Goldshore Resources Inc.',
    name: 'Goldshore Resources Inc.',
    symbol: 'GSHR.V',
  },
  {
    value: 'FWZ.V',
    label: 'FWZ.V,  Fireweed Metals Corp.',
    name: 'Fireweed Metals Corp.',
    symbol: 'FWZ.V',
  },
  {
    value: 'SSVR.V',
    label: 'SSVR.V,  Summa Silver Corp.',
    name: 'Summa Silver Corp.',
    symbol: 'SSVR.V',
  },
  {
    value: 'ACRV',
    label: 'ACRV,  Acrivon Therapeutics, Inc. Common Stock',
    name: 'Acrivon Therapeutics, Inc. Common Stock',
    symbol: 'ACRV',
  },
  {
    value: 'UHALV',
    label: 'UHALV,  AMERCO',
    name: 'AMERCO',
    symbol: 'UHALV',
  },
  {
    value: 'ASPI',
    label: 'ASPI,  ASP Isotopes Inc. Common Stock',
    name: 'ASP Isotopes Inc. Common Stock',
    symbol: 'ASPI',
  },
  {
    value: 'ATAT',
    label: 'ATAT,  Atour Lifestyle Holdings Limited American Depositary Shares',
    name: 'Atour Lifestyle Holdings Limited American Depositary Shares',
    symbol: 'ATAT',
  },
  {
    value: 'STXV',
    label: 'STXV,  Ea Series Trust - Strive 1000 Value ETF',
    name: 'Ea Series Trust - Strive 1000 Value ETF',
    symbol: 'STXV',
  },
  {
    value: 'STXK',
    label: 'STXK,  Ea Series Trust - Strive 2000 ETF',
    name: 'Ea Series Trust - Strive 2000 ETF',
    symbol: 'STXK',
  },
]