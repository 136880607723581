import styles from './NoData.module.scss'

export const NoData = ({ label, fitContent }) => {
  return (
    <div
      className={styles.noDataWrapper}
      style={{ height: fitContent ? 'fit-content' : '100%' }}
    >
      <div className={styles.noDataMessage}>
        {label ? label : 'No Data Available.'}
      </div>
    </div>
  )
}
