import { BasicNewsTable } from './BasicNewsTable'
import { useState, useEffect } from 'react'
import Fuse from 'fuse.js'
import styles from './newsTable.module.scss'
import Select from 'react-select'
import exchange from '../../assets/exchange.svg'
import exchange2 from '../../assets/exchange2.svg'
import { AdvancedNewsTable } from './AdvancedNewsTable'
import { siteFilter } from './constants'

export const NewsTable = ({ data, height, swap, showSymbol, toggleSwap }) => {
  const [searchQuery, setSearchQuery] = useState('')
  const [filteredData, setFilteredData] = useState(data)
  const [selectedSite, setSelectedSite] = useState(null)
  const [sites, setSites] = useState([])

  useEffect(() => {
    const uniqueSites = [...new Set(data.map(item => item.site))].map(site => ({
      value: site,
      label: site,
    }))
    setSites(uniqueSites)
  }, [data])

  const fuse = new Fuse(data, {
    keys: showSymbol
      ? ['title', 'text', 'symbol', 'site']
      : ['title', 'text', 'site'],
    includeScore: true,
    shouldSort: true,
    threshold: 0.4,
  })

  const handleSearch = e => {
    const query = e.target.value
    setSearchQuery(query)

    if (query) {
      const results = fuse.search(query)
      setFilteredData(results.map(res => res.item))
    } else {
      setFilteredData(data)
    }
  }

  const handleSiteChange = site => {
    setSelectedSite(site)
    if (site) {
      setFilteredData(data.filter(item => item.site === site.value))
    } else {
      setFilteredData(data)
    }
  }

  return (
    <div className={styles.newsTableWrapper} style={{ height: height }}>
      <div className={styles.header}>
        <div className={styles.swapWrapper}>
          <img
            src={swap ? exchange : exchange2}
            alt="swap"
            title="Swap news table"
            className={styles.swap}
            onClick={toggleSwap}
          />
        </div>
        <input
          value={searchQuery}
          onChange={handleSearch}
          placeholder="Search news..."
          className={styles.newsTableSearch}
        />
        <Select
          className={styles.siteSelect}
          value={selectedSite}
          onChange={handleSiteChange}
          options={sites}
          styles={siteFilter()}
          placeholder="Filter by source..."
          isClearable
        />
      </div>
      {swap ? (
        <AdvancedNewsTable data={filteredData} showSymbol={showSymbol} />
      ) : (
        <BasicNewsTable data={filteredData} showSymbol={showSymbol} />
      )}
    </div>
  )
}
