import React, { useState, useRef, useEffect } from 'react'
import leftArrow from '../assets/leftArrow.svg'
import rightArrow from '../assets/rightArrow.svg'
import './presetPanel.scss'
import { presetPanelWidth } from '../utils'

export const PresetPanel = ({
  update,
  setCurrentPanel,
  selected,
  setSelected,
}) => {
  const containerRef = useRef(null)

  const [scrollPosition, setScrollPosition] = useState({
    atStart: true,
    atEnd: false,
  })

  const updateScrollPosition = () => {
    const container = containerRef.current
    const atStart = container.scrollLeft === 0
    const atEnd =
      container.scrollLeft + container.clientWidth >=
      container.scrollWidth - presetPanelWidth()

    setScrollPosition({ atStart, atEnd })
  }

  useEffect(() => {
    const container = containerRef.current
    container.addEventListener('scroll', updateScrollPosition)

    return () => {
      container.removeEventListener('scroll', updateScrollPosition)
    }
  }, [])

  const scrollContent = direction => {
    const container = containerRef.current
    const scrollAmount = 100

    if (direction === 'left') {
      container.scrollTo({
        left: container.scrollLeft - scrollAmount,
        behavior: 'smooth',
      })
    } else {
      container.scrollTo({
        left: container.scrollLeft + scrollAmount,
        behavior: 'smooth',
      })
    }
  }

  const handlePresetClick = title => {
    setSelected(title)
    setCurrentPanel(panelConfig[title])
    update(panelConfig[title])
  }

  return (
    <div className="PresetPanel">
      {!scrollPosition.atStart ? (
        <>
          <div className="boxLeft" />
          <button
            className="arrow-button"
            onClick={() => scrollContent('left')}
          >
            <img
              src={leftArrow}
              alt="doubleLeftArrow"
              style={{ width: '20px', height: '20px' }}
            />
          </button>
        </>
      ) : // <div className="arrow-button-gone" />
      null}
      <div className="PresetPanel-content" ref={containerRef}>
        {Object.keys(panelConfig).map(title => {
          return (
            <PresetButton
              key={title}
              title={title}
              isSelected={selected === title}
              onClick={() => handlePresetClick(title)}
            />
          )
        })}
      </div>
      {!scrollPosition.atEnd ? (
        <>
          <div className="boxRight" />
          <button
            className="arrow-button"
            onClick={() => scrollContent('right')}
          >
            <img
              src={rightArrow}
              alt="doubleLeftArrow"
              style={{ width: '20px', height: '20px' }}
            />
          </button>
        </>
      ) : // <div className="arrow-button-gone" />
      null}
    </div>
  )
}

const PresetButton = ({ title, onClick, isSelected }) => {
  return (
    <button
      style={{
        backgroundColor: 'transparent',
        border: isSelected
          ? '2px solid var(--primary-color)'
          : '2px solid var(--background-tertiary)',
        // : '2px solid var(--grey-accent)',
      }}
      className="presetButton"
      onClick={onClick}
    >
      {title}
    </button>
  )
}

const constantColumns = ['image', 'symbol', 'line', 'MktCap', 'Price']

export const panelConfig = {
  Overview: [
    ...constantColumns,
    'companyName',
    'exchangeShortName',
    'sector',
    'currency',
    'Range',
    'country',
    'fullTimeEmployees',
    'industry',
  ],
  Valuation: [
    ...constantColumns,
    'priceEarningsRatioTTM',
    'priceToBookRatioTTM',
    'priceCashFlowRatioTTM',
    'priceToFreeCashFlowsRatioTTM',
    'priceSalesRatioTTM',
    'dividendPerShareTTM',
  ],
  'Quick Financials': [
    ...constantColumns,
    'revenue',
    'grossProfitRatio',
    'netIncome',
    'EBITDA',
    'totalAssets',
    'totalLiabilities',
    'totalStockholdersEquity',
    'freeCashFlow',
  ],
  'Balance Sheet': [
    ...constantColumns,
    'totalCurrentAssets',
    'totalNonCurrentAssets',
    'totalAssets',
    'totalCurrentLiabilities',
    'totalNonCurrentLiabilities',
    'totalLiabilities',
    'totalStockholdersEquity',
  ],
  'Income Statement': [
    ...constantColumns,
    'revenue',
    'grossProfit',
    'operatingExpenses',
    'EBITDA',
    'netIncome',
    'netIncomeRatio',
    'EPS',
  ],
  'Cash Flow': [
    ...constantColumns,
    'netIncome',
    'netCashProvidedByOperatingActivites',
    'netCashUsedForInvestingActivites',
    'netCashUsedProvidedByFinancingActivities',
    'capitalExpenditure',
    'freeCashFlow',
  ],
  // Growth: [
  //   'symbol',
  //   'Price',
  //   'marketCap',
  //   'growthRevenue',
  //   'growthGrossProfitRatio',
  //   'growthEBITDA',
  //   'growthOperatingIncome',
  //   'growthEPS',
  //   'growthNetIncome',
  // ],
  'Asset Management': [
    ...constantColumns,
    'totalCurrentAssets',
    'totalNonCurrentAssets',
    'totalAssets',
    'averageReceivablesTTM',
    'averagePayablesTTM',
    'averageInventoryTTM',
    'fixedAssetTurnoverTTM',
    'assetTurnoverTTM',
  ],

  Liquidity: [
    ...constantColumns,
    'currentRatioTTM',
    'quickRatioTTM',
    'cashConversionCycleTTM',
    'cashAndCashEquivalents',
    'cashAndShortTermInvestments',
  ],

  Profitability: [
    ...constantColumns,
    'grossProfitMarginTTM',
    'operatingProfitMarginTTM',
    'netProfitMarginTTM',
    'returnOnAssetsTTM',
    'returnOnEquityTTM',
    'returnOnCapitalEmployedTTM',
    'roeTTM',
    'roicTTM',
  ],

  'Dividend & Earnings': [
    ...constantColumns,
    'EPS',
    'EPSDiluted',
    'dividendPerShareTTM',
    'dividendYieldPercentageTTM',
    'priceEarningsRatioTTM',
    'priceEarningsToGrowthRatioTTM',
    'earningsYieldTTM',
    'ebtPerEbitTTM',
    'ebitPerRevenueTTM',
    'EBITDARatio',
    'EBITDA',
  ],
  Efficiency: [
    ...constantColumns,
    'operatingCycleTTM',
    'daysSalesOutstandingTTM',
    'daysPayablesOutstandingTTM',
    'daysOfInventoryOutstandingTTM',
    'cashFlowToDebtRatioTTM',
    'cashFlowCoverageRatiosTTM',
    'interestCoverageTTM',
  ],
  'Debt Management': [
    ...constantColumns,
    'totalDebt',
    'netDebt',
    'shortTermDebt',
    'longTermDebt',
    'debtEquityRatioTTM',
    'debtToAssetsTTM',
    'debtToMarketCapTTM',
    'debtToEquityTTM',
    'longTermDebtToCapitalizationTTM',
  ],
  'Valuation Ratios': [
    ...constantColumns,
    'priceToBookRatioTTM',
    'priceSalesRatioTTM',
    'priceCashFlowRatioTTM',
    'priceToFreeCashFlowsRatioTTM',
    'evToSalesTTM',
    'evToOperatingCashFlowTTM',
    'evToFreeCashFlowTTM',
    'enterpriseValueTTM',
  ],
}
