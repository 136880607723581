import styles from './CardWrapper.module.scss'
import { UilPlusSquare } from '@iconscout/react-unicons'
import { UilMinusSquareFull } from '@iconscout/react-unicons'

export const CardWrapper = ({
  height,
  width,
  label,
  content,
  overflow,
  minWidth,
  expandable,
  toggleExpand,
  expand,
  bg,
  endButton,
  noPadding,
}) => {
  return (
    <div
      className={styles.card}
      style={{
        height: height,
        width: width,
        minWidth: minWidth,
        backgroundColor: bg,
      }}
    >
      <div
        className={styles.cardHeader}
        style={{ paddingRight: noPadding && 0 }}
      >
        <div className={styles.cardLabel}>{label}</div>
        <div className={styles.icon} onClick={toggleExpand}>
          {expandable &&
            (expand ? (
              <UilPlusSquare size={20} color={'var(--light-grey-accent)'} />
            ) : (
              <UilMinusSquareFull
                size={20}
                color={'var(--light-grey-accent)'}
              />
            ))}
        </div>
        {endButton && endButton}
      </div>
      <div
        className={styles.cardContent}
        style={{
          overflow: overflow ? 'auto' : 'hidden',
        }}
      >
        {content}
      </div>
    </div>
  )
}
