import React, { useState, useEffect, useRef } from 'react'
import useEarningsTranscript from './useEarningsTranscript'
import Select from 'react-select'
import { getSelectStyles } from '../../utils/getSelectStyles'
import { grabSpeakers, grabParagraphs } from './utils'
import styles from './TranscriptToolBar.module.scss'
import Speak from 'speak-tts'
import { CoreButton } from '../../core/Button/CoreButton'
import pause from './assets/pause.svg'
import play from './assets/play.svg'
import dayjs from 'dayjs'
import Spinner from '../../core/ScrollableContainer/Spinner/spinner'
import { UilPlay, UilPause } from '@iconscout/react-unicons'

export const EarningsTranscript = ({ ticker }) => {
  const {
    years,
    quarters,
    selectedYear,
    selectedQuarter,
    content,
    setSelectedYear,
    setSelectedQuarter,
    yearsLoading,
    quartersLoading,
    contentDateLoading,
    yearsError,
    quartersError,
    contentDateError,
    date,
  } = useEarningsTranscript(ticker)

  const [isSpeaking, setIsSpeaking] = useState(false)
  const speakInstanceRef = useRef(null)

  const handleYearChange = selectedOption => {
    setSelectedYear(selectedOption.value)
  }

  const handleQuarterChange = selectedOption => {
    setSelectedQuarter(selectedOption.value)
  }

  useEffect(() => {
    const speak = new Speak()

    const speakInstance = speakInstanceRef.current

    if (speakInstance) {
      speakInstance.cancel()
    }

    speak.init().then(() => {
      speakInstanceRef.current = speak
    })

    return () => {
      if (speakInstanceRef.current) {
        speakInstanceRef.current.cancel()
        setIsSpeaking(false)
      }
    }
  }, [])

  useEffect(() => {
    if (speakInstanceRef.current && !content) {
      speakInstanceRef.current.cancel()
      setIsSpeaking(false)
    }
  }, [content])

  useEffect(() => {
    return () => {
      if (speakInstanceRef.current) {
        speakInstanceRef.current.cancel()
        setIsSpeaking(false)
      }
    }
  }, [selectedYear, selectedQuarter])

  const yearOptions = years
    ? years.map(year => ({ value: year, label: year }))
    : []
  const quarterOptions = quarters
    ? quarters.map(quarter => ({ value: quarter, label: `Q${quarter}` }))
    : []

  const startSpeaking = () => {
    if (speakInstanceRef.current && paragraphs.length > 0) {
      speakInstanceRef.current.speak({
        text: paragraphs.join('. '),
        queue: false,
      })
      setIsSpeaking(true)
    }
  }

  const pauseSpeaking = () => {
    if (speakInstanceRef.current) {
      speakInstanceRef.current.pause()
      setIsSpeaking(false)
    }
  }

  const stopSpeaking = () => {
    if (speakInstanceRef.current) {
      speakInstanceRef.current.cancel()
      setIsSpeaking(false)
    }
  }

  const resumeSpeaking = () => {
    if (speakInstanceRef.current) {
      speakInstanceRef.current.resume()
      setIsSpeaking(true)
    }
  }

  if (yearsLoading || quartersLoading || contentDateLoading) {
    return <Spinner />
  }

  if (yearsError || quartersError || contentDateError) {
    return <div>Error occurred while fetching data</div>
  }

  let speakers = []
  let paragraphs = []

  if (content) {
    speakers = grabSpeakers(content)
    paragraphs = grabParagraphs(content)
  }

  return (
    <div className={styles.transcriptWrapper}>
      <div className={styles.transcriptToolBarContainer}>
        <div className={styles.transcriptToolBarItemWrapper}>
          <div className={styles.transcriptToolBarItem}>
            <div>{ticker}</div>
          </div>
          <div className={styles.transcriptToolBarItem}>
            <div>{dayjs(date).format('ddd, MMM D, YYYY')}</div>
          </div>
        </div>
        <div className={styles.transcriptToolBarItemWrapper}>
          <div className={styles.buttonWrapper}>
            <div className={styles.transcriptToolBarItemSelect}>
              <Select
                value={yearOptions.find(
                  option => option.value === selectedYear
                )}
                onChange={handleYearChange}
                options={yearOptions}
                styles={getSelectStyles(`fit-content`)}
                isSearchable={false}
              />
            </div>
            <div className={styles.transcriptToolBarItemSelect}>
              <Select
                value={quarterOptions.find(
                  option => option.value === selectedQuarter
                )}
                onChange={handleQuarterChange}
                options={quarterOptions}
                styles={getSelectStyles(`fit-content`)}
                isSearchable={false}
              />
            </div>
          </div>
          <div className={styles.buttonWrapper}>
            <div className={styles.transcriptToolBarItem}>
              <CoreButton
                onClick={isSpeaking ? stopSpeaking : startSpeaking}
                width={'76px'}
                padding={'0px 8px'}
              >
                {isSpeaking ? 'Stop TTS' : 'Start TTS'}
              </CoreButton>
            </div>
            <div className={styles.transcriptToolBarItem}>
              <CoreButton
                onClick={isSpeaking ? pauseSpeaking : resumeSpeaking}
                padding={'0px 8px'}
              >
                {isSpeaking ? 'Pause' : 'Play'}
                {isSpeaking ? (
                  <UilPause size={16} color="var(--white)" />
                ) : (
                  <UilPlay size={16} color="var(--white)" />
                )}
              </CoreButton>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.contentContainerWrapper}>
        <div className={styles.contentContainer}>
          {speakers?.map((entry, i) => {
            return (
              <div key={i}>
                <div className={styles.operatorText}>{`${entry}: `}</div>
                <div
                  className={styles.paragraphText}
                >{`  ${paragraphs[i]}`}</div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
