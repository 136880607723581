//unique
export const ETF_BREAKDOWN = "ETF Breakdown";
export const MARKET_RISK_PREMIUM = "Market Risk Premium";
export const INSTITUTIONAL_STOCK_OWNERSHIP = "Institutional Stock Ownership";
export const COMPANY_PROFILE = "Company Profile";
export const EARNINGS_CALENDAR = "Earnings Calendar";
export const SCREENER = "Screener";
export const COMPARISON_CHARTS = "Comparison Charts";
export const NOTES = "Notes";
export const ESG_SCORE_BREAKDOWN = "ESG Score Breakdown";
export const PRICE_CHARTS = "Price Charts";
export const EARNINGS_TRANSCRIPT = "Earnings Transcript";
export const GEO_REVENUE_SEGMENTS = "Revenue Segment By Geographical Area";
export const PRODUCT_REVENUE_SEGMENTS = "Sales Revenue Breakdown By Segment";
export const COMPANY_QUOTE = "Company Quote";
