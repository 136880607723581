/*
########### DO NOT DO THIS ################
const IN_HOUSE_ORIGIN = 'http://localhost:5000/';

Instead, use the following:
If you want to test against remote APIs you can use one of the following
methods:
const IN_HOUSE_ORIGIN = process.env.REACT_APP_IN_HOUSE_ORIGIN || 'http://localhost:5000/'; // for local testing
const IN_HOUSE_ORIGIN = process.env.REACT_APP_IN_HOUSE_ORIGIN || 'https://stage.outseek.ca/'; // Stage API
const IN_HOUSE_ORIGIN = process.env.REACT_APP_IN_HOUSE_ORIGIN || 'https://outseek.ca/'; // Prod API
*/

// const IN_HOUSE_ORIGIN =
//   process.env.REACT_APP_IN_HOUSE_ORIGIN || 'https://outseek.ca/'

const IN_HOUSE_ORIGIN =
  process.env.REACT_APP_IN_HOUSE_ORIGIN || 'http://localhost:5000/' // for local testing
export const APP_CONFIG = {
  IN_HOUSE_ORIGIN: IN_HOUSE_ORIGIN,
}

export default APP_CONFIG
