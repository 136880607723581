/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import './styles.scss'
import { useTable, useFilters, useExpanded, useRowSelect } from 'react-table'
import { DefaultColumnFilter } from './filters'
import { connect } from 'react-redux'
import { updateTableState, addComponent } from 'redux/actions/app-actions'
import plus from './assets/plus.svg'
import minus from './assets/minus.svg'
import 'styles.scss'
import { BlockTableStyles } from './styles'
import { ANNUAL_PERIOD, QUARTERLY_PERIOD } from 'constants/tableConstants'
import { COMPARISON_CHARTS } from 'constants/uniqueConstants'
import { OutseekPeriodSelects } from 'core/OutseekPeriodSelects/OutseekPeriodSelects'
import {
  AnnualOptions,
  QuarterlyOptions,
} from 'core/OutseekPeriodSelects/constants'
import { ComparisonChartPlottingConfig } from './constants'
import { downloadExcel } from 'react-export-table-to-excel'
import {
  DEFAULT_VIZ_TYPE,
  INITIAL_STATE as INITIAL_COMP_CHART_STATE,
} from '../ComparisonCharts/state/reducer'
const TableToolBar = ({
  handleTogglePeriodLength,
  handleTogglePeriodCount,
  handlePlotOnChart,
  handleExportTable,
  plotRows,
  isView,
}) => {
  const [period, setPeriod] = useState('annual')
  const [selected, setSelected] = useState('annual')

  return (
    <div className="topHeaderToolBar">
      <div
        className="headerTableRow"
        style={{ justifyContent: isView ? 'flex-end' : 'space-between' }}
      >
        <div className="TimeSelect">
          <div
            onClick={() => setSelected('annual')}
            style={{
              background: selected === 'annual' ? 'var(--grey-accent)' : null,
              borderRadius: '4px',
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
            }}
          >
            <div
              className="functionButton"
              onClick={() => {
                handleTogglePeriodLength(ANNUAL_PERIOD)
                setPeriod(ANNUAL_PERIOD)
              }}
            >
              <p title="View annual data" className="text">
                Annual
              </p>
            </div>
          </div>
          <div
            onClick={() => setSelected('quarterly')}
            style={{
              background:
                selected === 'quarterly' ? 'var(--grey-accent)' : null,
              borderRadius: '4px',
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
            }}
          >
            <div
              className="functionButton"
              onClick={() => {
                handleTogglePeriodLength(QUARTERLY_PERIOD)
                setPeriod(QUARTERLY_PERIOD)
              }}
            >
              <p title="View quarterly data" className="text">
                Quarterly
              </p>
            </div>
          </div>
        </div>
        <div className="PlotDataWrapper">
          <OutseekPeriodSelects
            onChange={value => {
              handleTogglePeriodCount(value)
            }}
            options={
              period === ANNUAL_PERIOD ? AnnualOptions : QuarterlyOptions
            }
            width={100}
            placeholder="View..."
            // maxValue={period === ANNUAL_PERIOD ? 30 : 120}
            // minValue={period === ANNUAL_PERIOD ? 1 : 4}
          />
          {plotRows === true && (
            <div
              className="functionButtonPlot"
              onClick={() => handlePlotOnChart()}
            >
              {' '}
              <p title="Plot selected rows on line chart" className="textPlot">
                Plot Rows
              </p>
            </div>
          )}
          <div
            className="functionButtonPlot"
            onClick={() => handleExportTable()}
          >
            {' '}
            <p title="Plot selected rows on line chart" className="textPlot">
              Export
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

function BlockTable({
  root,
  initialState,
  columns,
  type,
  data,
  width,
  height,
  component,
  ticker,
  view,
  fnState,
  ...props
}) {
  const tableRef = useRef(0)
  const tableWrapperRef = useRef(1)

  useEffect(() => {
    tableWrapperRef.current.scrollTo({
      left: 500,
    })
  }, [])

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  )
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    selectedFlatRows,
  } = useTable(
    {
      initialState: {
        ...initialState,
        expanded: data.reduce((prev, current, idx) => {
          return { ...prev, [idx]: true }
        }, {}),
      },
      autoResetExpanded: false,
      columns,
      data,
      defaultColumn,
    },
    useFilters,
    useExpanded,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        {
          id: 'selection',
          Header: '',
          Cell: ({ row }) => (
            <div className="checkboxdiv">
              {view === true ? null : (
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              )}
            </div>
          ),
        },
        ...columns,
      ])
    }
  )

  const [hoveredCol, setHoveredCol] = useState(null)

  useEffect(() => {
    root && props.updateTableState({ ...state, ticker }, root)
  }, [state, ticker])

  const handlePlotOnChart = () => {
    const component = {
      // config: item,
      title: COMPARISON_CHARTS,
      type: COMPARISON_CHARTS,
      spec: { ticker: 'AAPL', state: INITIAL_COMP_CHART_STATE },
      data: [],
    }

    component.spec.ticker = ticker
    component.spec.state.metrics = selectedFlatRows.map(row => {
      component.spec.state.chartTypes[row.original.key] = DEFAULT_VIZ_TYPE
      return {
        ...ComparisonChartPlottingConfig[type],
        APIProperty: row.original.key,
      }
    })
    props.addComponent(component)
  }

  const handleExportTable = () => {
    let header = Object.keys(rows[0].values)
    let formattedData = rows.map(row => {
      return row.values
    })

    downloadExcel({
      fileName: `${ticker}_${type}_Outseek`,
      sheet: 'react-export-table-to-excel',
      tablePayload: {
        header,
        // accept two different data structures
        body: formattedData,
      },
    })
  }

  useEffect(() => {
    const table = tableRef.current
    const tdElements = table.getElementsByTagName('td')

    for (let i = 0; i < tdElements.length; i++) {
      const cell = tdElements[i]
      cell.addEventListener('mouseover', e => {
        const cellIndex = cell.cellIndex

        for (let j = 0; j < tdElements.length; j++) {
          const currentCell = tdElements[j]
          if (currentCell.cellIndex === cellIndex) {
            currentCell.classList.add('highlight')
          }
        }
      })

      cell.addEventListener('mouseout', e => {
        for (let j = 0; j < tdElements.length; j++) {
          tdElements[j].classList.remove('highlight')
        }
      })
    }
  }, [rows])
  return (
    <div
      className="BlockTable"
      style={{
        height: component ? height + 25 : height,
      }}
    >
      <TableToolBar
        {...props}
        handlePlotOnChart={handlePlotOnChart}
        handleExportTable={handleExportTable}
        plotRows={view ? false : true}
        isView={view ? true : false}
      />
      <div
        className="table-div"
        ref={tableWrapperRef}
        style={{
          width: '100%',
          height: height,
          background: 'var(--background-primary)',
          overflowX: 'scroll',
        }}
      >
        <table className="blockTable" ref={tableRef} {...getTableProps()}>
          <thead className="blockTableHead">
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, i) => {
                  if (i === 1) {
                    return (
                      <th
                        className="blockTableHeaders"
                        onMouseEnter={() => setHoveredCol(i)}
                        onMouseLeave={() => setHoveredCol(null)}
                        {...column.getHeaderProps()}
                      >
                        {/* <span> {column.render('Header')} </span> */}
                        {/* <div style={{ width: 150 }}>
                          {column.canFilter ? column.render('Filter') : null}
                        </div> */}
                      </th>
                    )
                  } else if (i === 0) {
                    return (
                      <th
                        className="blockTableHeaders"
                        {...column.getHeaderProps()}
                        onMouseEnter={() => setHoveredCol(i)}
                        onMouseLeave={() => setHoveredCol(null)}
                      >
                        {/* <span> {column.render('Header')} </span> */}
                        {/* <div>
                          {column.canFilter ? column.render('Filter') : null}
                        </div> */}
                      </th>
                    )
                  }
                  return <></>
                })}
              </tr>
            ))}
          </thead>
          <tbody style={{ overflowY: 'scroll' }} {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row)
              const isSubRow = row.depth !== 0
              const isExpanded = row.isExpanded
              const canExpand = row.canExpand
              return (
                <>
                  <tr
                    className="table-row"
                    {...row.getRowProps()}
                    onClick={() => row?.toggleRowExpanded()}
                  >
                    {row.cells.map((cell, j) => {
                      const cellProps = cell.getCellProps()
                      if (j === hoveredCol) {
                        cellProps.style = {
                          ...cellProps.style,
                          backgroundColor: 'lightgrey',
                        }
                      }
                      if (j === 1) {
                        return (
                          <td
                            className="table-cell"
                            onClick={() => row?.toggleRowExpanded()}
                            style={{
                              ...BlockTableStyles._td,
                              backgroundColor: 'var(--background-primary)',
                              paddingLeft: isSubRow && '30px',
                              position: 'sticky',
                              left: view ? 0 : 36,
                              minWidth: '354px',
                              maxWidth: '354px',
                              width: '354px',
                            }}
                            onMouseEnter={() => setHoveredCol(j)}
                            onMouseLeave={() => setHoveredCol(null)}
                            {...cell.getCellProps()}
                          >
                            {(canExpand && !isSubRow && !isExpanded && (
                              <img
                                src={plus}
                                title="Expand row"
                                className="icons"
                                onClick={() => row?.toggleRowExpanded()}
                                alt="expand row"
                              ></img>
                            )) ||
                              (canExpand && !isSubRow && isExpanded && (
                                <img
                                  src={minus}
                                  className="icons"
                                  title="Collapse row"
                                  onClick={() => row?.toggleRowExpanded()}
                                  alt="close row"
                                ></img>
                              )) || (
                                <> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                              )}
                            {cell.render('Cell')}
                          </td>
                        )
                      } else if (j === 2) {
                        return (
                          <td
                            className="table-cell"
                            style={{
                              ...BlockTableStyles._td,
                              backgroundColor: 'var(--background-primary)',
                              position: 'sticky',
                              left: view ? 354 : 390,
                            }}
                            onMouseEnter={() => setHoveredCol(j)}
                            onMouseLeave={() => setHoveredCol(null)}
                            {...cell.getCellProps()}
                          >
                            {cell.render('Cell')}
                          </td>
                        )
                      } else if (j === 0) {
                        return (
                          <td
                            className="table-cell"
                            style={{
                              ...BlockTableStyles._td,
                              backgroundColor: 'var(--background-primary)',
                              paddingLeft: isSubRow && '30px',
                              position: 'sticky',
                              left: 0,
                              padding: 0,
                              display: view && 'none',
                            }}
                            onMouseEnter={() => setHoveredCol(j)}
                            onMouseLeave={() => setHoveredCol(null)}
                            {...cell.getCellProps()}
                          >
                            {cell.render('Cell')}
                          </td>
                        )
                      } else {
                        return (
                          <td
                            className="table-cell"
                            style={{
                              ...BlockTableStyles._td,
                            }}
                            onMouseEnter={() => setHoveredCol(j)}
                            onMouseLeave={() => setHoveredCol(null)}
                            {...cell.getCellProps()}
                          >
                            {cell.value !== 0 ? cell.render('Cell') : '-'}
                          </td>
                        )
                      }
                    })}
                  </tr>
                </>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default connect(null, { updateTableState, addComponent })(BlockTable)

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    )
  }
)
