import styles from './style.module.scss'
import { CardWrapper } from 'core/CardWrapper/CardWrapper'
import Spinner from 'core/ScrollableContainer/Spinner/spinner'
import { dividendColumns } from './columns'
import { Helmet } from 'react-helmet-async'
import { useDividendCalendar } from '../../data/hooks'
import CalendarTable from 'core/Tables/CalendarTable'
import { useCalContext } from 'pages/views/Calendars/CalContext'
import { Calendar } from '../../Componenets/Calendar'
import { Switch } from 'core/Switch/Switch'
import { CalComp } from './CalComp'
import { useState } from 'react'

export const Dividend = () => {
  const { CAL_UI } = useDividendCalendar()
  const { calType, setCalType, options } = useCalContext()
  const [showOnlySP500, setShowOnlySP500] = useState(true)

  const SP500 = CAL_UI?.sp500

  const dividendOfSP500 = CAL_UI?.dividendCal?.filter(item =>
    SP500?.map(sp => sp.symbol).includes(item.symbol)
  )

  const dataToUse = showOnlySP500 ? dividendOfSP500 : CAL_UI?.dividendCal

  return (
    <>
      <Helmet>
        <title>{`Outseek | Dividend`}</title>
        <meta
          name="description"
          content={
            'Dividend calendar provides a list of companies that are expected to release their dividend reports.'
          }
        />
      </Helmet>
      <CardWrapper
        label={'Dividends'}
        height={'100%'}
        width={'100%'}
        endButton={
          <Switch
            options={options}
            selectedOption={calType}
            onOptionChange={setCalType}
            forCardWrapper={true}
          />
        }
        content={
          CAL_UI?.dividendCalIsLoading ? (
            <Spinner />
          ) : calType === 'table' ? (
            <CalendarTable
              data={CAL_UI?.dividendCal}
              columns={dividendColumns}
              overflowXScroll={true}
              filterColumnNames={['impact', 'country']}
            />
          ) : (
            <Calendar
              data={dataToUse}
              component={CalComp}
              filterKeys={['declarationDate']}
              searchKeys={['symbol']}
              additionalProps={
                <div className={styles.dataCheckbox}>
                  <input
                    type="checkbox"
                    checked={showOnlySP500}
                    onChange={() => setShowOnlySP500(!showOnlySP500)}
                    className={styles.checkbox}
                  />
                  <div>Show only SP500</div>
                </div>
              }
            />
          )
        }
      />
    </>
  )
}
