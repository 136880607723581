import http from 'network/httpClient'

const fetchSecData = async ({ queryKey }) => {
  const [, ticker] = queryKey
  const data = await http.get(`papi/data/api/v3/sec_filings/${ticker}?&page=0`)

  return data.data.message
}

export const fetches = {
  fetchSecData,
}
