import { useState } from 'react'

export const ImageError = ({ src, size, fallBack = null }) => {
  const [error, setError] = useState(false)

  const handleError = () => {
    setError(true)
  }

  const dimensions = `${size}px`

  return error ? (
    fallBack
  ) : (
    // eslint-disable-next-line jsx-a11y/alt-text
    <img
      src={src}
      onError={handleError}
      style={{
        height: dimensions,
        width: dimensions,
        padding: 0,
        borderRadius: 2,
      }}
    />
  )
}
