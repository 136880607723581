import React from 'react'

import styles from './Notes.module.scss'

export const Notes = ({ savedNote, handleUpdateState, height }) => {
  return (
    <textarea
      className={styles.postItInput}
      style={{ height: height + 10 }}
      onChange={e => handleUpdateState({ note: e.target.value })}
    >
      {savedNote?.note}
    </textarea>
  )
}
