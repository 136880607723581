import React, { useState } from 'react'
import styles from './fnTable.module.scss'
import { useFinancialStatementsContext } from '../../FinancialStatementsContext'
import MetricInfo from 'core/MetricInfoHover/MetricInfoTooltip'
import { useColumnWidths } from './useColumnsWidths'
import {
  getCellClassNameHeader,
  getColumnId,
  getCellLeftStyle,
  getCellClassNameBody,
} from './utils'

export const Table = ({
  getTableProps,
  rows,
  prepareRow,
  tableRef,
  getTableBodyProps,
  dataInRange,
  canSelectRows,
  selectedRows,
  setSelectedRows,
  tableId,
  columnKeys,
}) => {
  const { state } = useFinancialStatementsContext()
  const { cagrActive } = state.toolbarState || {}
  const [hoveredCol, setHoveredCol] = useState(null)
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null)

  const { first, second, third } = useColumnWidths(tableId, [dataInRange])

  return (
    <div className={styles.tableContainer}>
      <table {...getTableProps()} className={styles.table} ref={tableRef}>
        <thead className={styles.stickyHeader}>
          {rows.slice(0, 1).map((row, index) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()} className={styles.headerRow}>
                {row.cells.map((cell, cellIndex) => (
                  <td
                    onMouseEnter={() => setHoveredCol(cellIndex)}
                    onMouseLeave={() => setHoveredCol(null)}
                    id={getColumnId(cellIndex, tableId)}
                    {...cell.getCellProps()}
                    className={getCellClassNameHeader(cellIndex, cagrActive)}
                    style={{
                      ...getCellLeftStyle(
                        cellIndex,
                        cagrActive,
                        first,
                        second,
                        third
                      ),
                    }}
                  >
                    {cellIndex === 2 && cagrActive ? (
                      <span className={styles.cagrColumnHeader}>CAGR</span>
                    ) : (
                      cell.render('Cell')
                    )}
                  </td>
                ))}
              </tr>
            )
          })}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.slice(1, rows.length).map((row, index) => {
            prepareRow(row)
            const rowInfo = columnKeys.find(
              r => r.accessor === row.original.accessor
            )
            const isTotalRow = rowInfo?.total
            return (
              <tr
                {...row.getRowProps()}
                onMouseEnter={() => setHoveredRowIndex(index)}
                onMouseLeave={() => setHoveredRowIndex(null)}
                className={`${
                  hoveredRowIndex === index ? styles.hoveredRow : ''
                } ${styles.tableRow} 
                ${isTotalRow && styles.totalRow} `}
                onClick={() => {
                  if (canSelectRows) {
                    if (selectedRows.includes(row.original.accessor)) {
                      setSelectedRows(
                        selectedRows.filter(x => x !== row.original.accessor)
                      )
                    } else {
                      setSelectedRows([...selectedRows, row.original.accessor])
                    }
                  }
                }}
                style={{
                  ...(selectedRows.includes(row.original.accessor) && {
                    backgroundColor: 'var(--background-secondary)',
                  }),
                  ...(isTotalRow && {
                    borderBottom: '2px solid var(--background-tertiary)',
                    fontWeight: '500',
                    color: 'var(--white)',
                    height: '46px',
                  }),
                }}
              >
                {row.cells.map((cell, cellIndex) => {
                  const metricKey = row.original.accessor
                  const displayMetric = cell.render('Cell')
                  const isStickyColumn =
                    cellIndex === 0 || cellIndex === 1 || cellIndex === 2
                  const cellClass =
                    isStickyColumn && hoveredRowIndex === index
                      ? styles.highlightedSticky
                      : ''

                  return (
                    <td
                      onMouseEnter={() => setHoveredCol(cellIndex)}
                      onMouseLeave={() => setHoveredCol(null)}
                      {...cell.getCellProps()}
                      className={getCellClassNameBody(
                        cellIndex,
                        cagrActive,
                        cellClass,
                        isTotalRow
                      )}
                      style={{
                        ...getCellLeftStyle(
                          cellIndex,
                          cagrActive,
                          first,
                          second,
                          third
                        ),
                      }}
                    >
                      {cellIndex === 0 ? (
                        <MetricInfo
                          metricKey={metricKey}
                          displayMetric={displayMetric}
                        />
                      ) : (
                        cell.render('Cell')
                      )}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
