export const categories = {
  valuation: 'Valuation',
  growth: 'Growth',
  profitability: 'Profitability',
  financialHealth: 'Financial Health',
  managementEffectiveness: 'Management Effectiveness',
  dividends: 'Dividends',
  efficiency: 'Efficiency',
  balanceSheet: 'Balance Sheet',
  incomeStatement: 'Income Statement',
  cashFlow: 'Cash Flow Statement',
  companyInfo: 'Company Info',
}

export const METRICS_INFO = {
  // Income Statement Metrics
  revenue: {
    title: 'Revenue',
    description: 'Total income from goods sold or services provided.',
    category: categories.incomeStatement,
  },
  costOfRevenue: {
    title: 'Cost of Revenue',
    description:
      'Direct costs attributable to the production of goods sold by a company.',
    category: categories.incomeStatement,
  },
  grossProfit: {
    title: 'Gross Profit',
    description: 'Revenue minus the cost of goods sold.',
    category: categories.incomeStatement,
  },
  grossProfitRatio: {
    title: 'Gross Profit Ratio',
    description:
      'Gross profit as a percentage of revenue, indicating the efficiency of production.',
    category: categories.incomeStatement,
  },
  researchAndDevelopmentExpenses: {
    title: 'R&D Expenses',
    description:
      'Costs associated with the research and development of a company’s goods or services.',
    category: categories.incomeStatement,
  },
  sellingGeneralAndAdministrativeExpenses: {
    title: 'SG&A Expenses',
    description:
      'Combined payroll costs, excluding direct labor, and other expenses related to the company’s operations.',
    category: categories.incomeStatement,
  },
  operatingExpenses: {
    title: 'Operating Expenses',
    description:
      'Expenses required for the day-to-day maintenance and administration of a company.',
    category: categories.incomeStatement,
  },
  costAndExpenses: {
    title: 'Cost and Expenses',
    description:
      'Sum of all costs of revenue, operating expenses, and non-operating expenses.',
    category: categories.incomeStatement,
  },
  interestIncome: {
    title: 'Interest Income',
    description:
      'Income earned from deposit interests or interest on other investments.',
    category: categories.incomeStatement,
  },
  interestExpense: {
    title: 'Interest Expense',
    description: 'Cost incurred on borrowed funds.',
    category: categories.incomeStatement,
  },
  depreciationAndAmortization: {
    title: 'Depreciation & Amortization',
    description:
      'Charge related to the reduction in value of tangible and intangible assets over time.',
    category: categories.incomeStatement,
  },
  ebitda: {
    title: 'EBITDA',
    description:
      'Earnings before interest, taxes, depreciation, and amortization, indicating a company’s financial performance.',
    category: categories.incomeStatement,
  },
  operatingIncome: {
    title: 'Operating Income',
    description:
      'Profit realized from a business’s operations after deducting operating expenses.',
    category: categories.incomeStatement,
  },
  incomeBeforeTax: {
    title: 'Income Before Tax',
    description: 'Net income before income tax expense has been deducted.',
    category: categories.incomeStatement,
  },
  incomeTaxExpense: {
    title: 'Income Tax Expense',
    description: 'Total amount of income tax a company expects to pay.',
    category: categories.incomeStatement,
  },
  netIncome: {
    title: 'Net Income',
    description:
      'Total profit of a company after all expenses have been deducted from revenues.',
    category: categories.incomeStatement,
  },
  eps: {
    title: 'EPS',
    description:
      'Earnings per share, indicating the profitability of a company on a per-share basis.',
    category: categories.incomeStatement,
  },
  epsDiluted: {
    title: 'EPS Diluted',
    description:
      'Diluted earnings per share, factoring in potential shares from conversions or stock options.',
    category: categories.incomeStatement,
  },
  weightedAverageShsOut: {
    title: 'Weighted Average Shares Outstanding',
    description:
      'Average number of shares outstanding during a period, weighted for time.',
    category: categories.incomeStatement,
  },
  weightedAverageShsOutDil: {
    title: 'Weighted Average Shares Outstanding Diluted',
    description:
      'Diluted weighted average shares, including all potential shares from conversions.',
    category: categories.incomeStatement,
  },

  // Balance Sheet Metrics
  cashAndCashEquivalents: {
    title: 'Cash and Cash Equivalents',
    description:
      'Liquid assets including currency, coins, and short-term investments accessible within three months or less.',
    category: categories.balanceSheet,
  },
  shortTermInvestments: {
    title: 'Short-term Investments',
    description:
      'Investments that are expected to be converted into cash within one year.',
    category: categories.balanceSheet,
  },
  cashAndShortTermInvestments: {
    title: 'Cash & Short-term Investments',
    description: 'Sum of cash, cash equivalents, and short-term investments.',
    category: categories.balanceSheet,
  },
  netReceivables: {
    title: 'Net Receivables',
    description:
      'Amounts due to be received from customers for goods or services provided, net of allowances for doubtful accounts.',
    category: categories.balanceSheet,
  },
  inventory: {
    title: 'Inventory',
    description:
      'Value of raw materials, work-in-progress, and finished goods held by a company.',
    category: categories.balanceSheet,
  },
  otherCurrentAssets: {
    title: 'Other Current Assets',
    description:
      'Current assets not fitting into standard categories, like prepaid expenses.',
    category: categories.balanceSheet,
  },
  totalCurrentAssets: {
    title: 'Total Current Assets',
    description:
      'Sum of all assets expected to be converted into cash within one year.',
    category: categories.balanceSheet,
  },
  propertyPlantEquipmentNet: {
    title: 'Property, Plant, & Equipment Net',
    description: 'Value of physical, long-term assets net of depreciation.',
    category: categories.balanceSheet,
  },
  goodwill: {
    title: 'Goodwill',
    description:
      'Excess of purchase price over the fair market value of a company’s identifiable assets and liabilities.',
    category: categories.balanceSheet,
  },
  intangibleAssets: {
    title: 'Intangible Assets',
    description: 'Non-physical assets like patents and trademarks.',
    category: categories.balanceSheet,
  },
  goodwillAndIntangibleAssets: {
    title: 'Goodwill & Intangible Assets',
    description:
      'Sum of goodwill and intangible assets, representing long-term value.',
    category: categories.balanceSheet,
  },
  longTermInvestments: {
    title: 'Long-term Investments',
    description:
      'Investments not expected to be sold within one year, including bonds and stocks.',
    category: categories.balanceSheet,
  },
  taxAssets: {
    title: 'Tax Assets',
    description: 'Assets that can be used to offset future tax liabilities.',
    category: categories.balanceSheet,
  },
  otherNonCurrentAssets: {
    title: 'Other Non-current Assets',
    description:
      'Long-term assets that don’t fit into other categories, like long-term receivables.',
    category: categories.balanceSheet,
  },
  totalNonCurrentAssets: {
    title: 'Total Non-current Assets',
    description:
      'Sum of all assets not expected to be converted into cash within one year.',
    category: categories.balanceSheet,
  },
  otherAssets: {
    title: 'Other Assets',
    description:
      'Assets not classified elsewhere on the balance sheet, often including long-term assets.',
    category: categories.balanceSheet,
  },
  totalAssets: {
    title: 'Total Assets',
    description:
      'Sum of all current and non-current assets owned by a company.',
    category: categories.balanceSheet,
  },
  accountPayables: {
    title: 'Account Payables',
    description:
      'Short-term liabilities to suppliers or creditors for goods and services received.',
    category: categories.balanceSheet,
  },
  shortTermDebt: {
    title: 'Short-term Debt',
    description: 'Debt obligations requiring repayment within one year.',
    category: categories.balanceSheet,
  },
  deferredRevenue: {
    title: 'Deferred Revenue',
    description: 'Income received for goods or services not yet delivered.',
    category: categories.balanceSheet,
  },
  otherCurrentLiabilities: {
    title: 'Other Current Liabilities',
    description:
      'Current liabilities not classified elsewhere, like current lease obligations.',
    category: categories.balanceSheet,
  },
  totalCurrentLiabilities: {
    title: 'Total Current Liabilities',
    description: 'Sum of all short-term obligations due within one year.',
    category: categories.balanceSheet,
  },
  longTermDebt: {
    title: 'Long-term Debt',
    description: 'Debt obligations not due within the next twelve months.',
    category: categories.balanceSheet,
  },
  deferredRevenueNonCurrent: {
    title: 'Deferred Revenue Non-current',
    description:
      'Income received for goods or services to be delivered in future beyond the next twelve months.',
    category: categories.balanceSheet,
  },
  deferredTaxLiabilitiesNonCurrent: {
    title: 'Deferred Tax Liabilities Non-current',
    description:
      'Taxes owed to be paid in the future, related to non-current assets.',
    category: categories.balanceSheet,
  },
  otherNonCurrentLiabilities: {
    title: 'Other Non-current Liabilities',
    description:
      'Long-term liabilities that do not fit into standard categories.',
    category: categories.balanceSheet,
  },
  totalNonCurrentLiabilities: {
    title: 'Total Non-current Liabilities',
    description: 'Sum of all liabilities not due within one year.',
    category: categories.balanceSheet,
  },
  otherLiabilities: {
    title: 'Other Liabilities',
    description:
      'Liabilities not fitting into standard categories, often including long-term obligations not classified elsewhere.',
    category: categories.balanceSheet,
  },
  capitalLeaseObligations: {
    title: 'Capital Lease Obligations',
    description:
      'Long-term lease obligations that are recorded as debt on a company’s balance sheet.',
    category: categories.balanceSheet,
  },
  totalLiabilities: {
    title: 'Total Liabilities',
    description: 'Sum of all current and non-current obligations.',
    category: categories.balanceSheet,
  },
  commonStock: {
    title: 'Common Stock',
    description: 'Value of shares issued representing ownership in a company.',
    category: categories.balanceSheet,
  },
  retainedEarnings: {
    title: 'Retained Earnings',
    description: 'Cumulative net income minus dividends paid to shareholders.',
    category: categories.balanceSheet,
  },
  accumulatedOtherComprehensiveIncomeLoss: {
    title: 'Accumulated Other Comprehensive Income (Loss)',
    description:
      'Total of non-owner changes in equity not included in net income.',
    category: categories.balanceSheet,
  },
  totalStockholdersEquity: {
    title: 'Total Stockholders’ Equity',
    description:
      'Total value of ownership interest in the company, calculated as total assets minus total liabilities.',
    category: categories.balanceSheet,
  },
  othertotalStockholdersEquity: {
    title: 'Other Total Stockholders’ Equity',
    description:
      'Equity not classified under common stock, retained earnings, or accumulated income, representing additional ownership interest.',
    category: categories.balanceSheet,
  },
  totalEquity: {
    title: 'Total Equity',
    description:
      'Total of all stockholders’ equity items, representing the net value of a company.',
    category: categories.balanceSheet,
  },
  totalLiabilitiesAndStockholdersEquity: {
    title: 'Total Liabilities and Stockholders’ Equity',
    description:
      'Sum of all liabilities and shareholders’ equity, representing the financing of a company’s assets.',
    category: categories.balanceSheet,
  },
  minorityInterest: {
    title: 'Minority Interest',
    description:
      'Non-controlling interest in a subsidiary not wholly owned by the parent company, shown within equity.',
    category: categories.balanceSheet,
  },
  totalLiabilitiesAndTotalEquity: {
    title: 'Total Liabilities and Total Equity',
    description:
      'Combined total of all liabilities and equity, showing the financing of a company’s assets.',
    category: categories.balanceSheet,
  },
  totalInvestments: {
    title: 'Total Investments',
    description:
      'Sum of all forms of investments held by the company, both short-term and long-term.',
    category: categories.balanceSheet,
  },
  totalDebt: {
    title: 'Total Debt',
    description: 'Sum of all current and long-term financial obligations.',
    category: categories.balanceSheet,
  },
  netDebt: {
    title: 'Net Debt',
    description:
      'Total debt minus cash and cash equivalents, indicating the company’s actual debt situation.',
    category: categories.balanceSheet,
  },

  // Cash Flow Statement Exclusive Metrics
  deferredIncomeTax: {
    title: 'Deferred Income Tax',
    description:
      'Changes in deferred income tax liabilities and assets, affecting cash flow but not involving actual cash transaction.',
    category: categories.cashFlow,
  },
  stockBasedCompensation: {
    title: 'Stock-Based Compensation',
    description:
      'Expenses related to stock options or other forms of stock-based compensation, a non-cash charge impacting operating cash flow.',
    category: categories.cashFlow,
  },
  changeInWorkingCapital: {
    title: 'Change in Working Capital',
    description:
      'Net change in current assets and liabilities, reflecting the cash used in or provided by day-to-day operations.',
    category: categories.cashFlow,
  },
  netCashProvidedByOperatingActivities: {
    title: 'Net Cash Provided by Operating Activities',
    description:
      'Total cash generated from a company’s core business operations.',
    category: categories.cashFlow,
  },
  investmentsInPropertyPlantAndEquipment: {
    title: 'Investments in Property, Plant, and Equipment',
    description:
      'Capital expenditures for acquiring or upgrading physical assets such as property and equipment.',
    category: categories.cashFlow,
  },
  acquisitionsNet: {
    title: 'Acquisitions, Net',
    description:
      'Net cash used in the purchase of subsidiary companies or business units.',
    category: categories.cashFlow,
  },
  purchasesOfInvestments: {
    title: 'Purchases of Investments',
    description:
      'Cash spent on acquiring investments like stocks, bonds, or other financial assets.',
    category: categories.cashFlow,
  },
  salesMaturitiesOfInvestments: {
    title: 'Sales/Maturities of Investments',
    description:
      'Cash received from selling investments or from the maturity of investments.',
    category: categories.cashFlow,
  },
  otherInvestingActivities: {
    title: 'Other Investing Activities',
    description:
      'Cash flow from investing activities not classified elsewhere, such as loans made to suppliers or received from customers.',
    category: categories.cashFlow,
  },
  netCashUsedForInvestingActivities: {
    title: 'Net Cash Used for Investing Activities',
    description:
      'Total cash used in investing activities, including acquisitions, and purchase of investments.',
    category: categories.cashFlow,
  },
  debtRepayment: {
    title: 'Debt Repayment',
    description:
      'Cash used to repay borrowings, reflecting a company’s financing activities.',
    category: categories.cashFlow,
  },
  commonStockIssued: {
    title: 'Common Stock Issued',
    description: 'Cash received from issuing common stock to investors.',
    category: categories.cashFlow,
  },
  commonStockRepurchased: {
    title: 'Common Stock Repurchased',
    description: 'Cash used to buy back common stock from shareholders.',
    category: categories.cashFlow,
  },
  dividendsPaid: {
    title: 'Dividends Paid',
    description:
      'Cash paid to shareholders as dividends, reflecting a distribution of earnings.',
    category: categories.cashFlow,
  },
  otherFinancingActivities: {
    title: 'Other Financing Activities',
    description:
      'Cash flow from financing activities not classified elsewhere, including payments for financial instruments.',
    category: categories.cashFlow,
  },
  netCashUsedProvidedByFinancingActivities: {
    title: 'Net Cash Used/Provided by Financing Activities',
    description:
      'Total cash inflows and outflows related to financing activities, including debt, equity, and dividend payments.',
    category: categories.cashFlow,
  },
  effectOfForexChangesOnCash: {
    title: 'Effect of Forex Changes on Cash',
    description:
      'Impact of foreign exchange rate changes on cash and cash equivalents held in foreign currencies.',
    category: categories.cashFlow,
  },
  netChangeInCash: {
    title: 'Net Change in Cash',
    description:
      'The overall increase or decrease in cash and cash equivalents during a specific period.',
    category: categories.cashFlow,
  },
  cashAtEndOfPeriod: {
    title: 'Cash at End of Period',
    description:
      'Amount of cash and cash equivalents held by a company at the end of the financial period.',
    category: categories.cashFlow,
  },
  cashAtBeginningOfPeriod: {
    title: 'Cash at Beginning of Period',
    description:
      'Amount of cash and cash equivalents held by a company at the beginning of the financial period.',
    category: categories.cashFlow,
  },
  operatingCashFlow: {
    title: 'Operating Cash Flow',
    description: 'Cash generated from a company’s normal business operations.',
    category: categories.cashFlow,
  },
  capitalExpenditure: {
    title: 'Capital Expenditure',
    description:
      'Funds used by a company to acquire or upgrade physical assets to improve long-term operations.',
    category: categories.cashFlow,
  },
  freeCashFlow: {
    title: 'Free Cash Flow',
    description:
      'Cash a company generates after accounting for cash outflows to support operations and maintain its capital assets.',
    category: categories.cashFlow,
  },

  // Ratios
  currentRatio: {
    title: 'Current Ratio',
    description:
      "Measures a company's ability to pay short-term obligations with short-term assets.",
    category: categories.financialHealth,
  },
  quickRatio: {
    title: 'Quick Ratio',
    description:
      "Indicates a company's capacity to pay its current liabilities without needing to sell inventory.",
    category: categories.financialHealth,
  },
  cashRatio: {
    title: 'Cash Ratio',
    description:
      "Assesses a company's ability to settle short-term liabilities with cash and cash equivalents.",
    category: categories.financialHealth,
  },
  daysOfSalesOutstanding: {
    title: 'Days of Sales Outstanding',
    description:
      'Average number of days a company takes to collect payment after a sale.',
    category: categories.efficiency,
  },
  daysOfInventoryOutstanding: {
    title: 'Days of Inventory Outstanding',
    description:
      'Average time in days that a company holds inventory before selling it.',
    category: categories.efficiency,
  },
  operatingCycle: {
    title: 'Operating Cycle',
    description:
      'Time it takes for a company to purchase inventory, sell it, and collect cash from sales.',
    category: categories.efficiency,
  },
  daysOfPayablesOutstanding: {
    title: 'Days of Payables Outstanding',
    description:
      'Average number of days a company takes to pay its invoices from suppliers.',
    category: categories.efficiency,
  },
  cashConversionCycle: {
    title: 'Cash Conversion Cycle',
    description:
      'Time it takes for a company to convert resource inputs into cash flows.',
    category: categories.efficiency,
  },
  grossProfitMargin: {
    title: 'Gross Profit Margin',
    description: 'Percentage of revenue that exceeds the cost of goods sold.',
    category: categories.profitability,
  },
  operatingProfitMargin: {
    title: 'Operating Profit Margin',
    description:
      'Ratio of operating income to net sales, showing efficiency in controlling production and admin costs.',
    category: categories.profitability,
  },
  pretaxProfitMargin: {
    title: 'Pretax Profit Margin',
    description:
      "Ratio of pretax income to revenue, indicating a company's operational effectiveness before taxes.",
    category: categories.profitability,
  },
  netProfitMargin: {
    title: 'Net Profit Margin',
    description:
      'Percentage of revenue remaining after all expenses, interest, and taxes have been deducted.',
    category: categories.profitability,
  },
  effectiveTaxRate: {
    title: 'Effective Tax Rate',
    description: "Company's average tax rate on pre-tax profits.",
    category: categories.profitability,
  },
  returnOnAssets: {
    title: 'Return on Assets',
    description:
      'Indicator of how profitable a company is relative to its total assets.',
    category: categories.profitability,
  },
  returnOnEquity: {
    title: 'Return on Equity',
    description:
      "Measure of a corporation's profitability by revealing how much profit a company generates with the money shareholders have invested.",
    category: categories.profitability,
  },
  returnOnCapitalEmployed: {
    title: 'Return on Capital Employed',
    description:
      "Assesses a company's efficiency at allocating the capital under its control to profitable investments.",
    category: categories.profitability,
  },
  netIncomePerEBT: {
    title: 'Net Income Per EBT',
    description:
      'Ratio of net income to earnings before taxes, showing the effect of taxes on earnings.',
    category: categories.managementEffectiveness,
  },
  ebtPerEbit: {
    title: 'EBT Per EBIT',
    description:
      'Ratio of earnings before taxes to earnings before interest and taxes, indicating financial expenses impact.',
    category: categories.managementEffectiveness,
  },
  ebitPerRevenue: {
    title: 'EBIT Per Revenue',
    description:
      "Shows the percentage of a company's revenue that has turned into earnings before interest and taxes.",
    category: categories.managementEffectiveness,
  },
  debtRatio: {
    title: 'Debt Ratio',
    description:
      "Ratio of total debt to total assets, indicating the degree of a company's financial leverage.",
    category: categories.financialHealth,
  },
  debtEquityRatio: {
    title: 'Debt to Equity Ratio',
    description:
      "Indicator of a company's financial leverage calculated by dividing its total liabilities by stockholders' equity.",
    category: categories.financialHealth,
  },
  longTermDebtToCapitalization: {
    title: 'Long-Term Debt to Capitalization',
    description:
      "Ratio of long-term debt to the sum of long-term debt and shareholders' equity.",
    category: categories.financialHealth,
  },
  totalDebtToCapitalization: {
    title: 'Total Debt to Capitalization',
    description:
      "Measure of a company's financial leverage that indicates the proportion of debt used in its capital structure.",
    category: categories.financialHealth,
  },
  interestCoverage: {
    title: 'Interest Coverage',
    description:
      "Ratio of a company's earnings before interest and taxes (EBIT) over its interest expense.",
    category: categories.financialHealth,
  },
  cashFlowToDebtRatio: {
    title: 'Cash Flow to Debt Ratio',
    description:
      'Measure of financial durability, calculated by dividing cash flow from operations by total debt.',
    category: categories.financialHealth,
  },
  companyEquityMultiplier: {
    title: 'Company Equity Multiplier',
    description:
      "Measure of a company's financial leverage, calculated as total assets divided by total equity.",
    category: categories.financialHealth,
  },
  receivablesTurnover: {
    title: 'Receivables Turnover',
    description:
      'Annual measure of how efficiently a company collects on its receivables.',
    category: categories.efficiency,
  },
  payablesTurnover: {
    title: 'Payables Turnover',
    description: 'Annual rate at which a company pays off its suppliers.',
    category: categories.efficiency,
  },
  inventoryTurnover: {
    title: 'Inventory Turnover',
    description:
      "Shows how many times a company's inventory is sold and replaced over a period.",
    category: categories.efficiency,
  },
  fixedAssetTurnover: {
    title: 'Fixed Asset Turnover',
    description:
      "Efficiency ratio that indicates how well a company's fixed assets are used to generate sales.",
    category: categories.efficiency,
  },
  assetTurnover: {
    title: 'Asset Turnover',
    description:
      "Ratio that measures the efficiency of a company's use of its assets in generating sales revenue.",
    category: categories.efficiency,
  },
  operatingCashFlowPerShare: {
    title: 'Operating Cash Flow Per Share',
    description:
      'Amount of cash a company generates from its operations, per share.',
    category: categories.valuation,
  },
  freeCashFlowPerShare: {
    title: 'Free Cash Flow Per Share',
    description:
      'Amount of cash a company can generate after laying out the money required to maintain or expand its asset base, per share.',
    category: categories.valuation,
  },
  cashPerShare: {
    title: 'Cash Per Share',
    description:
      'Amount of cash and cash equivalents a company has on a per share basis.',
    category: categories.financialHealth,
  },
  payoutRatio: {
    title: 'Payout Ratio',
    description: 'Percentage of earnings paid to shareholders in dividends.',
    category: categories.dividends,
  },
  operatingCashFlowSalesRatio: {
    title: 'Operating Cash Flow to Sales Ratio',
    description:
      "Measures the proportion of cash generated by a company's normal business operations to its total sales.",
    category: categories.managementEffectiveness,
  },
  freeCashFlowOperatingCashFlowRatio: {
    title: 'Free Cash Flow to Operating Cash Flow Ratio',
    description:
      "Ratio that compares a company's free cash flow to its operating cash flow.",
    category: categories.managementEffectiveness,
  },
  cashFlowCoverageRatios: {
    title: 'Cash Flow Coverage Ratios',
    description:
      'Indicators of the number of times a company can cover its obligations with its cash flow.',
    category: categories.financialHealth,
  },
  shortTermCoverageRatios: {
    title: 'Short-Term Coverage Ratios',
    description:
      "Measures of a company's ability to meet its short-term financial obligations with its liquid assets.",
    category: categories.financialHealth,
  },
  capitalExpenditureCoverageRatio: {
    title: 'Capital Expenditure Coverage Ratio',
    description:
      "Ratio that measures a company's ability to fund its capital expenditures from its operating cash flow.",
    category: categories.managementEffectiveness,
  },
  dividendPaidAndCapexCoverageRatio: {
    title: 'Dividend Paid and Capex Coverage Ratio',
    description:
      "Indicates a company's ability to fund dividends and capital expenditures from its operating cash flow.",
    category: categories.dividends,
  },
  dividendPayoutRatio: {
    title: 'Dividend Payout Ratio',
    description:
      'Portion of earnings distributed to shareholders as dividends.',
    category: categories.dividends,
  },
  priceBookValueRatio: {
    title: 'Price to Book Value Ratio',
    description:
      "Comparison of a firm's market capitalization to its book value.",
    category: categories.valuation,
  },
  priceToBookRatio: {
    title: 'Price to Book Ratio',
    description:
      "Indicates the market's valuation of a company relative to its book value.",
    category: categories.valuation,
  },
  priceToSalesRatio: {
    title: 'Price to Sales Ratio',
    description:
      'Valuation ratio that compares a company’s stock price to its revenues.',
    category: categories.valuation,
  },
  priceEarningsRatio: {
    title: 'Price to Earnings Ratio',
    description:
      'Ratio for valuing a company that measures its current share price relative to its per-share earnings.',
    category: categories.valuation,
  },
  priceToFreeCashFlowsRatio: {
    title: 'Price to Free Cash Flows Ratio',
    description:
      "Valuation metric comparing a company's market cap to its free cash flow.",
    category: categories.valuation,
  },
  priceToOperatingCashFlowsRatio: {
    title: 'Price to Operating Cash Flows Ratio',
    description:
      "Comparison of a company's market value to its cash flow from operations.",
    category: categories.valuation,
  },
  priceCashFlowRatio: {
    title: 'Price Cash Flow Ratio',
    description:
      "Valuation metric comparing a company's stock price to its cash flow per share.",
    category: categories.valuation,
  },
  priceEarningsToGrowthRatio: {
    title: 'Price Earnings to Growth Ratio',
    description:
      "Valuation metric comparing the price/earnings ratio to the growth rate of a company's earnings.",
    category: categories.valuation,
  },
  priceSalesRatio: {
    title: 'Price Sales Ratio',
    description:
      "Valuation metric that compares a company's stock price to its revenue per share.",
    category: categories.valuation,
  },
  dividendYield: {
    title: 'Dividend Yield',
    description:
      'Financial ratio that shows how much a company pays out in dividends each year relative to its stock price.',
    category: categories.dividends,
  },
  enterpriseValueMultiple: {
    title: 'Enterprise Value Multiple',
    description:
      'Valuation measure comparing the value of a company, including debt and excluding cash, to its revenue or EBITDA.',
    category: categories.valuation,
  },
  priceFairValue: {
    title: 'Price Fair Value',
    description:
      "Estimate of a stock's fair value based on expected future cash flows, discounted at the investor's rate of return.",
    category: categories.valuation,
  },

  // Key metrics

  revenuePerShare: {
    title: 'Revenue Per Share',
    description: 'Total revenue divided by the average outstanding shares.',
    category: categories.valuation,
  },
  netIncomePerShare: {
    title: 'Net Income Per Share',
    description:
      'Net income available to shareholders divided by the average outstanding shares.',
    category: categories.valuation,
  },
  bookValuePerShare: {
    title: 'Book Value Per Share',
    description:
      'Total equity minus preferred equity, divided by the average outstanding shares.',
    category: categories.balanceSheet,
  },
  tangibleBookValuePerShare: {
    title: 'Tangible Book Value Per Share',
    description:
      'Total equity minus preferred equity and intangible assets, divided by the average outstanding shares.',
    category: categories.balanceSheet,
  },
  shareholdersEquityPerShare: {
    title: "Shareholders' Equity Per Share",
    description:
      "Total shareholders' equity divided by the average outstanding shares.",
    category: categories.balanceSheet,
  },
  interestDebtPerShare: {
    title: 'Interest Debt Per Share',
    description:
      'Total interest-bearing debt divided by the average outstanding shares.',
    category: categories.financialHealth,
  },
  enterpriseValue: {
    title: 'Enterprise Value',
    description:
      'Total value of a company, including debt, minority interest, and preferred shares, minus total cash and cash equivalents.',
    category: categories.valuation,
  },
  peRatio: {
    title: 'P/E Ratio',
    description: 'Market price per share divided by earnings per share.',
    category: categories.valuation,
  },
  // priceToSalesRatio: {
  //   title: 'Price to Sales Ratio',
  //   description: "Company's stock price divided by its revenue per share.",
  //   category: categories.valuation,
  // },
  pocfratio: {
    title: 'POCF Ratio',
    description:
      "Price to operating cash flow ratio, indicating the market's valuation of a firm's operating cash flow.",
    category: categories.valuation,
  },
  pfcfRatio: {
    title: 'P/FCF Ratio',
    description:
      "Price to free cash flow ratio, reflecting the valuation of a company's free cash flow.",
    category: categories.valuation,
  },
  pbRatio: {
    title: 'P/B Ratio',
    description: 'Market price per share divided by book value per share.',
    category: categories.valuation,
  },
  ptbRatio: {
    title: 'P/TB Ratio',
    description:
      "Price to tangible book value ratio, comparing a firm's market value to its tangible equity.",
    category: categories.valuation,
  },
  evToSales: {
    title: 'EV to Sales',
    description:
      "Enterprise value divided by total revenue, indicating the valuation of a company's sales.",
    category: categories.valuation,
  },
  enterpriseValueOverEBITDA: {
    title: 'Enterprise Value Over EBITDA',
    description:
      "EV/EBITDA ratio, showing the valuation of a company's earnings before interest, taxes, depreciation, and amortization.",
    category: categories.valuation,
  },
  evToOperatingCashFlow: {
    title: 'EV to Operating Cash Flow',
    description: 'Enterprise value divided by cash flow from operations.',
    category: categories.valuation,
  },
  evToFreeCashFlow: {
    title: 'EV to Free Cash Flow',
    description:
      "Enterprise value divided by free cash flow, assessing a company's valuation relative to its free cash flow generation.",
    category: categories.valuation,
  },
  earningsYield: {
    title: 'Earnings Yield',
    description:
      'Earnings per share divided by the market price per share, the inverse of the P/E ratio.',
    category: categories.valuation,
  },
  freeCashFlowYield: {
    title: 'Free Cash Flow Yield',
    description:
      'Free cash flow per share divided by market price per share, indicating the cash profitability relative to share price.',
    category: categories.valuation,
  },
  debtToEquity: {
    title: 'Debt to Equity',
    description:
      "Total liabilities divided by shareholders' equity, indicating the relative proportion of shareholders' equity and debt used to finance a company's assets.",
    category: categories.financialHealth,
  },
  debtToAssets: {
    title: 'Debt to Assets',
    description:
      "Total debt divided by total assets, showing the percentage of a company's assets financed by debt.",
    category: categories.financialHealth,
  },
  netDebtToEBITDA: {
    title: 'Net Debt to EBITDA',
    description:
      "Indicator of a company's ability to pay off its incurred debt, calculated as net debt divided by EBITDA.",
    category: categories.financialHealth,
  },
  incomeQuality: {
    title: 'Income Quality',
    description:
      "Assessment of the consistency and sustainability of a company's income.",
    category: categories.managementEffectiveness,
  },
  salesGeneralAndAdministrativeToRevenue: {
    title: 'SG&A to Revenue',
    description:
      'Ratio of sales, general, and administrative expenses to total revenue.',
    category: categories.efficiency,
  },
  researchAndDdevelopementToRevenue: {
    title: 'R&D to Revenue',
    description:
      "Company's research and development expenses divided by its total revenue.",
    category: categories.efficiency,
  },
  intangiblesToTotalAssets: {
    title: 'Intangibles to Total Assets',
    description: 'Percentage of intangible assets relative to total assets.',
    category: categories.balanceSheet,
  },
  capexToOperatingCashFlow: {
    title: 'CapEx to Operating Cash Flow',
    description: 'Capital expenditures divided by cash flow from operations.',
    category: categories.managementEffectiveness,
  },
  capexToRevenue: {
    title: 'CapEx to Revenue',
    description: 'Ratio of capital expenditures to total revenue.',
    category: categories.efficiency,
  },
  capexToDepreciation: {
    title: 'CapEx to Depreciation',
    description: 'Capital expenditures divided by depreciation expenses.',
    category: categories.efficiency,
  },
  stockBasedCompensationToRevenue: {
    title: 'Stock-Based Compensation to Revenue',
    description: 'Ratio of stock-based compensation to total revenue.',
    category: categories.managementEffectiveness,
  },
  grahamNumber: {
    title: 'Graham Number',
    description:
      'Calculated as the square root of (22.5 * earnings per share * book value per share), estimating the maximum fair price of a stock.',
    category: categories.valuation,
  },
  roic: {
    title: 'ROIC',
    description:
      "Return on invested capital, measuring a company's efficiency at allocating capital under its control to profitable investments.",
    category: categories.managementEffectiveness,
  },
  returnOnTangibleAssets: {
    title: 'Return on Tangible Assets',
    description:
      'Net income divided by tangible assets, indicating profitability relative to physical assets.',
    category: categories.profitability,
  },
  grahamNetNet: {
    title: 'Graham Net-Net',
    description:
      'Value investing metric calculating net current asset value minus total liabilities, to identify deep value stocks.',
    category: categories.valuation,
  },
  workingCapital: {
    title: 'Working Capital',
    description:
      'Current assets minus current liabilities, indicating the short-term liquidity of a company.',
    category: categories.financialHealth,
  },
  tangibleAssetValue: {
    title: 'Tangible Asset Value',
    description:
      'Sum of all tangible assets like cash, inventory, and property, plant, & equipment.',
    category: categories.valuation,
  },
  netCurrentAssetValue: {
    title: 'Net Current Asset Value',
    description:
      'Current assets minus total liabilities, used in value investing.',
    category: categories.valuation,
  },
  investedCapital: {
    title: 'Invested Capital',
    description:
      'Total cash invested in the company over time by both shareholders and debtors.',
    category: categories.balanceSheet,
  },
  averageReceivables: {
    title: 'Average Receivables',
    description:
      'Average amount of trade receivables over a specific period, indicating the efficiency of credit policies.',
    category: categories.balanceSheet,
  },
  averagePayables: {
    title: 'Average Payables',
    description:
      "Average amount of trade payables over a specific period, reflecting a company's payment habits to its suppliers.",
    category: categories.balanceSheet,
  },
  averageInventory: {
    title: 'Average Inventory',
    description:
      'Average value of inventory over a specific period, used in calculating inventory turnover.',
    category: categories.balanceSheet,
  },
  daysSalesOutstanding: {
    title: 'Days Sales Outstanding',
    description:
      'Average number of days a company takes to collect payment after a sale.',
    category: categories.efficiency,
  },
  daysPayablesOutstanding: {
    title: 'Days Payables Outstanding',
    description:
      'Average number of days a company takes to pay its invoices from suppliers.',
    category: categories.efficiency,
  },
  daysOfInventoryOnHand: {
    title: 'Days of Inventory on Hand',
    description:
      'Average time in days that a company holds inventory before selling it.',
    category: categories.efficiency,
  },
  roe: {
    title: 'ROE',
    description:
      'Return on equity, measuring how effectively management is using a company’s assets to create profits.',
    category: categories.profitability,
  },
  capexPerShare: {
    title: 'CapEx Per Share',
    description:
      'Capital expenditures divided by the total number of shares outstanding.',
    category: categories.valuation,
  },
  dividendYielPercentage: {
    title: 'Dividend Yield Percentage',
    description:
      'Represents the dividend return relative to the stock price, useful for comparing income across different stocks.',
    category: categories.dividends,
  },
  pegRatio: {
    title: 'PEG Ratio',
    description:
      "Evaluates a stock's valuation while considering earnings growth, useful for comparing companies with different growth rates.",
    category: categories.valuation,
  },

  // growth

  //growth income
  growthRevenue: {
    title: 'Growth in Revenue',
    description:
      'Year-over-year increase in total income from goods sold or services provided.',
    category: categories.incomeStatement,
  },
  growthCostOfRevenue: {
    title: 'Growth in Cost of Revenue',
    description:
      'Year-over-year increase in direct costs attributable to the production of goods sold.',
    category: categories.incomeStatement,
  },
  growthGrossProfit: {
    title: 'Growth in Gross Profit',
    description:
      'Year-over-year increase in revenue minus the cost of goods sold.',
    category: categories.incomeStatement,
  },
  growthGrossProfitRatio: {
    title: 'Growth in Gross Profit Ratio',
    description:
      'Year-over-year increase in gross profit as a percentage of revenue.',
    category: categories.incomeStatement,
  },
  growthResearchAndDevelopmentExpenses: {
    title: 'Growth in R&D Expenses',
    description:
      'Year-over-year increase in costs associated with research and development.',
    category: categories.incomeStatement,
  },
  growthGeneralAndAdministrativeExpenses: {
    title: 'Growth in SG&A Expenses',
    description:
      'Year-over-year increase in combined payroll and other operational expenses.',
    category: categories.incomeStatement,
  },
  growthSellingAndMarketingExpenses: {
    title: 'Growth in Selling and Marketing Expenses',
    description:
      'Year-over-year increase in expenses related to selling and marketing efforts.',
    category: categories.incomeStatement,
  },
  growthOtherExpenses: {
    title: 'Growth in Other Expenses',
    description:
      'Year-over-year increase in non-operating expenses not categorized elsewhere.',
    category: categories.incomeStatement,
  },
  growthOperatingExpenses: {
    title: 'Growth in Operating Expenses',
    description:
      'Year-over-year increase in expenses for daily business operations and administration.',
    category: categories.incomeStatement,
  },
  growthCostAndExpenses: {
    title: 'Growth in Cost and Expenses',
    description:
      'Year-over-year increase in the sum of all costs of revenue, operating, and non-operating expenses.',
    category: categories.incomeStatement,
  },
  growthInterestExpense: {
    title: 'Growth in Interest Expense',
    description: 'Year-over-year increase in cost incurred on borrowed funds.',
    category: categories.incomeStatement,
  },
  growthDepreciationAndAmortization: {
    title: 'Growth in Depreciation & Amortization',
    description:
      'Year-over-year increase in charges related to asset value reduction over time.',
    category: categories.incomeStatement,
  },
  growthEBITDA: {
    title: 'Growth in EBITDA',
    description:
      'Year-over-year increase in earnings before interest, taxes, depreciation, and amortization.',
    category: categories.incomeStatement,
  },
  growthEBITDARatio: {
    title: 'Growth in EBITDA Ratio',
    description:
      'Year-over-year increase in EBITDA as a percentage of revenue.',
    category: categories.incomeStatement,
  },
  growthOperatingIncome: {
    title: 'Growth in Operating Income',
    description:
      'Year-over-year increase in profit from business operations after deducting operating expenses.',
    category: categories.incomeStatement,
  },
  growthOperatingIncomeRatio: {
    title: 'Growth in Operating Income Ratio',
    description:
      'Year-over-year increase in operating income as a percentage of revenue.',
    category: categories.incomeStatement,
  },
  growthTotalOtherIncomeExpensesNet: {
    title: 'Growth in Total Other Income/Expenses Net',
    description:
      'Year-over-year increase in net amount of non-operating income and expenses.',
    category: categories.incomeStatement,
  },
  growthIncomeBeforeTax: {
    title: 'Growth in Income Before Tax',
    description:
      'Year-over-year increase in net income before income tax expense deduction.',
    category: categories.incomeStatement,
  },
  growthIncomeBeforeTaxRatio: {
    title: 'Growth in Income Before Tax Ratio',
    description:
      'Year-over-year increase in income before tax as a percentage of revenue.',
    category: categories.incomeStatement,
  },
  growthIncomeTaxExpense: {
    title: 'Growth in Income Tax Expense',
    description:
      'Year-over-year increase in the total amount of income tax expected to be paid.',
    category: categories.incomeStatement,
  },
  growthNetIncome: {
    title: 'Growth in Net Income',
    description:
      'Year-over-year increase in total profit after all expenses have been deducted from revenues.',
    category: categories.incomeStatement,
  },
  growthNetIncomeRatio: {
    title: 'Growth in Net Income Ratio',
    description:
      'Year-over-year increase in net income as a percentage of revenue.',
    category: categories.incomeStatement,
  },
  growthEPS: {
    title: 'Growth in EPS',
    description: 'Year-over-year increase in earnings per share.',
    category: categories.incomeStatement,
  },
  growthEPSDiluted: {
    title: 'Growth in EPS Diluted',
    description: 'Year-over-year increase in diluted earnings per share.',
    category: categories.incomeStatement,
  },
  growthWeightedAverageShsOut: {
    title: 'Growth in Weighted Average Shares Outstanding',
    description:
      'Year-over-year increase in the average number of shares outstanding, weighted for time.',
    category: categories.incomeStatement,
  },
  growthWeightedAverageShsOutDil: {
    title: 'Growth in Weighted Average Shares Outstanding Diluted',
    description:
      'Year-over-year increase in diluted weighted average shares, including potential shares from conversions.',
    category: categories.incomeStatement,
  },

  //growth balance sheet

  growthCashAndCashEquivalents: {
    title: 'Growth in Cash and Cash Equivalents',
    description:
      'Year-over-year increase in liquid assets accessible within three months or less.',
    category: categories.balanceSheet,
  },
  growthShortTermInvestments: {
    title: 'Growth in Short-term Investments',
    description:
      'Year-over-year increase in investments expected to be converted into cash within one year.',
    category: categories.balanceSheet,
  },
  growthCashAndShortTermInvestments: {
    title: 'Growth in Cash & Short-term Investments',
    description:
      'Year-over-year increase in the sum of cash, cash equivalents, and short-term investments.',
    category: categories.balanceSheet,
  },
  growthNetReceivables: {
    title: 'Growth in Net Receivables',
    description:
      'Year-over-year increase in amounts due from customers for goods or services provided, net of allowances.',
    category: categories.balanceSheet,
  },
  growthInventory: {
    title: 'Growth in Inventory',
    description:
      'Year-over-year increase in the value of raw materials, work-in-progress, and finished goods.',
    category: categories.balanceSheet,
  },
  growthOtherCurrentAssets: {
    title: 'Growth in Other Current Assets',
    description:
      'Year-over-year increase in current assets not fitting standard categories, like prepaid expenses.',
    category: categories.balanceSheet,
  },
  growthTotalCurrentAssets: {
    title: 'Growth in Total Current Assets',
    description:
      'Year-over-year increase in the sum of all assets expected to be converted into cash within one year.',
    category: categories.balanceSheet,
  },
  growthPropertyPlantEquipmentNet: {
    title: 'Growth in Property, Plant, & Equipment Net',
    description:
      'Year-over-year increase in the value of physical, long-term assets net of depreciation.',
    category: categories.balanceSheet,
  },
  growthGoodwill: {
    title: 'Growth in Goodwill',
    description:
      'Year-over-year increase in excess of purchase price over fair market value of identifiable assets and liabilities.',
    category: categories.balanceSheet,
  },
  growthIntangibleAssets: {
    title: 'Growth in Intangible Assets',
    description:
      'Year-over-year increase in non-physical assets like patents and trademarks.',
    category: categories.balanceSheet,
  },
  growthGoodwillAndIntangibleAssets: {
    title: 'Growth in Goodwill & Intangible Assets',
    description:
      'Year-over-year increase in the sum of goodwill and intangible assets.',
    category: categories.balanceSheet,
  },
  growthLongTermInvestments: {
    title: 'Growth in Long-term Investments',
    description:
      'Year-over-year increase in investments not expected to be sold within one year.',
    category: categories.balanceSheet,
  },
  growthTaxAssets: {
    title: 'Growth in Tax Assets',
    description:
      'Year-over-year increase in assets that can be used to offset future tax liabilities.',
    category: categories.balanceSheet,
  },
  growthOtherNonCurrentAssets: {
    title: 'Growth in Other Non-current Assets',
    description:
      'Year-over-year increase in long-term assets not fitting other categories.',
    category: categories.balanceSheet,
  },
  growthTotalNonCurrentAssets: {
    title: 'Growth in Total Non-current Assets',
    description:
      'Year-over-year increase in the sum of all assets not expected to be converted into cash within one year.',
    category: categories.balanceSheet,
  },
  growthOtherAssets: {
    title: 'Growth in Other Assets',
    description:
      'Year-over-year increase in assets not classified elsewhere, often long-term.',
    category: categories.balanceSheet,
  },
  growthTotalAssets: {
    title: 'Growth in Total Assets',
    description:
      'Year-over-year increase in the sum of all current and non-current assets owned by a company.',
    category: categories.balanceSheet,
  },
  growthAccountPayables: {
    title: 'Growth in Account Payables',
    description:
      'Year-over-year increase in short-term liabilities to suppliers or creditors for goods and services received.',
    category: categories.balanceSheet,
  },
  growthShortTermDebt: {
    title: 'Growth in Short-term Debt',
    description:
      'Year-over-year increase in debt obligations requiring repayment within one year.',
    category: categories.balanceSheet,
  },
  growthTaxPayables: {
    title: 'Growth in Tax Payables',
    description: 'Year-over-year increase in liabilities for taxes owed.',
    category: categories.balanceSheet,
  },
  growthDeferredRevenue: {
    title: 'Growth in Deferred Revenue',
    description:
      'Year-over-year increase in income received for goods or services not yet delivered.',
    category: categories.balanceSheet,
  },
  growthOtherCurrentLiabilities: {
    title: 'Growth in Other Current Liabilities',
    description:
      'Year-over-year increase in current liabilities not classified elsewhere.',
    category: categories.balanceSheet,
  },
  growthTotalCurrentLiabilities: {
    title: 'Growth in Total Current Liabilities',
    description:
      'Year-over-year increase in the sum of all short-term obligations due within one year.',
    category: categories.balanceSheet,
  },
  growthLongTermDebt: {
    title: 'Growth in Long-term Debt',
    description:
      'Year-over-year increase in debt obligations not due within the next twelve months.',
    category: categories.balanceSheet,
  },
  growthDeferredRevenueNonCurrent: {
    title: 'Growth in Deferred Revenue Non-current',
    description:
      'Year-over-year increase in income received for goods or services to be delivered beyond the next twelve months.',
    category: categories.balanceSheet,
  },
  growthDeferredTaxLiabilitiesNonCurrent: {
    title: 'Growth in Deferred Tax Liabilities Non-current',
    description:
      'Year-over-year increase in taxes owed to be paid in the future related to non-current assets.',
    category: categories.balanceSheet,
  },
  growthOtherNonCurrentLiabilities: {
    title: 'Growth in Other Non-current Liabilities',
    description:
      'Year-over-year increase in long-term liabilities not fitting standard categories.',
    category: categories.balanceSheet,
  },
  growthTotalNonCurrentLiabilities: {
    title: 'Growth in Total Non-current Liabilities',
    description:
      'Year-over-year increase in the sum of all liabilities not due within one year.',
    category: categories.balanceSheet,
  },
  growthOtherLiabilities: {
    title: 'Growth in Other Liabilities',
    description:
      'Year-over-year increase in liabilities not fitting into standard categories.',
    category: categories.balanceSheet,
  },
  growthTotalLiabilities: {
    title: 'Growth in Total Liabilities',
    description:
      'Year-over-year increase in the sum of all current and non-current obligations.',
    category: categories.balanceSheet,
  },
  growthCommonStock: {
    title: 'Growth in Common Stock',
    description:
      'Year-over-year increase in the value of shares issued representing ownership in a company.',
    category: categories.balanceSheet,
  },
  growthRetainedEarnings: {
    title: 'Growth in Retained Earnings',
    description:
      'Year-over-year increase in cumulative net income minus dividends paid to shareholders.',
    category: categories.balanceSheet,
  },
  growthAccumulatedOtherComprehensiveIncomeLoss: {
    title: 'Growth in Accumulated Other Comprehensive Income (Loss)',
    description:
      'Year-over-year increase in total of non-owner changes in equity not included in net income.',
    category: categories.balanceSheet,
  },
  growthOthertotalStockholdersEquity: {
    title: 'Growth in Other Total Stockholders’ Equity',
    description:
      'Year-over-year increase in equity not classified under common stock, retained earnings, or accumulated income.',
    category: categories.balanceSheet,
  },
  growthTotalStockholdersEquity: {
    title: 'Growth in Total Stockholders’ Equity',
    description:
      'Year-over-year increase in total value of ownership interest in the company.',
    category: categories.balanceSheet,
  },
  growthTotalLiabilitiesAndStockholdersEquity: {
    title: 'Growth in Total Liabilities and Stockholders’ Equity',
    description:
      'Year-over-year increase in the financing of a company’s assets through liabilities and equity.',
    category: categories.balanceSheet,
  },
  growthTotalInvestments: {
    title: 'Growth in Total Investments',
    description:
      'Year-over-year increase in the sum of all forms of investments held by the company.',
    category: categories.balanceSheet,
  },
  growthTotalDebt: {
    title: 'Growth in Total Debt',
    description:
      'Year-over-year increase in the sum of all current and long-term financial obligations.',
    category: categories.balanceSheet,
  },
  growthNetDebt: {
    title: 'Growth in Net Debt',
    description:
      'Year-over-year increase in total debt minus cash and cash equivalents.',
    category: categories.balanceSheet,
  },

  // growth cash flow statement

  growthDeferredIncomeTax: {
    title: 'Growth in Deferred Income Tax',
    description:
      'Year-over-year change in deferred income tax liabilities and assets, impacting cash flow without actual cash transaction.',
    category: categories.cashFlow,
  },
  growthStockBasedCompensation: {
    title: 'Growth in Stock-Based Compensation',
    description:
      'Year-over-year increase in expenses related to stock-based compensation, affecting operating cash flow.',
    category: categories.cashFlow,
  },
  growthChangeInWorkingCapital: {
    title: 'Growth in Change in Working Capital',
    description:
      'Year-over-year net change in current assets and liabilities, reflecting cash impact from day-to-day operations.',
    category: categories.cashFlow,
  },
  growthNetCashProvidedByOperatingActivities: {
    title: 'Growth in Net Cash Provided by Operating Activities',
    description:
      'Year-over-year increase in total cash generated from core business operations.',
    category: categories.cashFlow,
  },
  growthInvestmentsInPropertyPlantAndEquipment: {
    title: 'Growth in Investments in Property, Plant, and Equipment',
    description:
      'Year-over-year change in capital expenditures for physical assets like property and equipment.',
    category: categories.cashFlow,
  },
  growthAcquisitionsNet: {
    title: 'Growth in Acquisitions, Net',
    description:
      'Year-over-year net change in cash used for the purchase of subsidiary companies or business units.',
    category: categories.cashFlow,
  },
  growthPurchasesOfInvestments: {
    title: 'Growth in Purchases of Investments',
    description:
      'Year-over-year increase in cash spent on acquiring financial assets.',
    category: categories.cashFlow,
  },
  growthSalesMaturitiesOfInvestments: {
    title: 'Growth in Sales/Maturities of Investments',
    description:
      'Year-over-year increase in cash received from selling or maturing investments.',
    category: categories.cashFlow,
  },
  growthOtherInvestingActivities: {
    title: 'Growth in Other Investing Activities',
    description:
      'Year-over-year change in cash flow from investing activities not classified elsewhere.',
    category: categories.cashFlow,
  },
  growthNetCashUsedForInvestingActivities: {
    title: 'Growth in Net Cash Used for Investing Activities',
    description:
      'Year-over-year change in total cash used in investing activities.',
    category: categories.cashFlow,
  },
  growthDebtRepayment: {
    title: 'Growth in Debt Repayment',
    description: 'Year-over-year increase in cash used to repay borrowings.',
    category: categories.cashFlow,
  },
  growthCommonStockIssued: {
    title: 'Growth in Common Stock Issued',
    description:
      'Year-over-year increase in cash received from issuing common stock.',
    category: categories.cashFlow,
  },
  growthCommonStockRepurchased: {
    title: 'Growth in Common Stock Repurchased',
    description:
      'Year-over-year increase in cash used to buy back common stock.',
    category: categories.cashFlow,
  },
  growthDividendsPaid: {
    title: 'Growth in Dividends Paid',
    description:
      'Year-over-year increase in cash paid to shareholders as dividends.',
    category: categories.cashFlow,
  },
  growthOtherFinancingActivities: {
    title: 'Growth in Other Financing Activities',
    description:
      'Year-over-year change in cash flow from financing activities not classified elsewhere.',
    category: categories.cashFlow,
  },
  growthNetCashUsedProvidedByFinancingActivities: {
    title: 'Growth in Net Cash Used/Provided by Financing Activities',
    description:
      'Year-over-year change in total cash inflows and outflows related to financing activities.',
    category: categories.cashFlow,
  },
  growthEffectOfForexChangesOnCash: {
    title: 'Growth in Effect of Forex Changes on Cash',
    description:
      'Year-over-year impact of foreign exchange rate changes on cash and cash equivalents.',
    category: categories.cashFlow,
  },
  growthNetChangeInCash: {
    title: 'Growth in Net Change in Cash',
    description:
      'Year-over-year overall increase or decrease in cash and cash equivalents.',
    category: categories.cashFlow,
  },
  growthCashAtEndOfPeriod: {
    title: 'Growth in Cash at End of Period',
    description:
      'Year-over-year increase in the amount of cash and cash equivalents held at the end of the financial period.',
    category: categories.cashFlow,
  },
  growthCashAtBeginningOfPeriod: {
    title: 'Growth in Cash at Beginning of Period',
    description:
      'Year-over-year increase in the amount of cash and cash equivalents held at the beginning of the financial period.',
    category: categories.cashFlow,
  },
  growthOperatingCashFlow: {
    title: 'Growth in Operating Cash Flow',
    description:
      'Year-over-year increase in cash generated from a company’s normal business operations.',
    category: categories.cashFlow,
  },
  growthCapitalExpenditure: {
    title: 'Growth in Capital Expenditure',
    description:
      'Year-over-year change in funds used by a company to acquire or upgrade physical assets.',
    category: categories.cashFlow,
  },
  growthFreeCashFlow: {
    title: 'Growth in Free Cash Flow',
    description:
      'Year-over-year increase in cash a company generates after accounting for cash outflows to support operations and maintain assets.',
    category: categories.cashFlow,
  },

  // fincnial growth

  revenueGrowth: {
    title: 'Revenue Growth',
    description:
      'Year-over-year percentage increase in total income from goods sold or services provided.',
    category: categories.growth,
  },
  grossProfitGrowth: {
    title: 'Gross Profit Growth',
    description:
      'Year-over-year percentage increase in gross profit, indicating efficiency in production.',
    category: categories.growth,
  },
  ebitgrowth: {
    title: 'EBIT Growth',
    description:
      'Year-over-year percentage increase in earnings before interest and taxes, showing operational profitability.',
    category: categories.growth,
  },
  operatingIncomeGrowth: {
    title: 'Operating Income Growth',
    description:
      'Year-over-year percentage increase in operating income, reflecting earnings from core operations.',
    category: categories.growth,
  },
  netIncomeGrowth: {
    title: 'Net Income Growth',
    description:
      'Year-over-year percentage increase in net income, indicating total profit after all expenses.',
    category: categories.growth,
  },
  epsgrowth: {
    title: 'EPS Growth',
    description:
      'Year-over-year percentage increase in earnings per share, showing profitability on a per-share basis.',
    category: categories.growth,
  },
  epsdilutedGrowth: {
    title: 'EPS Diluted Growth',
    description:
      'Year-over-year percentage increase in diluted earnings per share, factoring in potential shares from conversions.',
    category: categories.growth,
  },
  weightedAverageSharesGrowth: {
    title: 'Weighted Average Shares Growth',
    description:
      'Year-over-year percentage increase in the weighted average number of shares outstanding.',
    category: categories.growth,
  },
  weightedAverageSharesDilutedGrowth: {
    title: 'Weighted Average Shares Diluted Growth',
    description:
      'Year-over-year percentage increase in the diluted weighted average shares, including potential shares.',
    category: categories.growth,
  },
  dividendsperShareGrowth: {
    title: 'Dividends per Share Growth',
    description:
      'Year-over-year percentage increase in dividends paid out per share.',
    category: categories.growth,
  },
  operatingCashFlowGrowth: {
    title: 'Operating Cash Flow Growth',
    description:
      'Year-over-year percentage increase in cash generated from core business operations.',
    category: categories.growth,
  },
  freeCashFlowGrowth: {
    title: 'Free Cash Flow Growth',
    description:
      'Year-over-year increase in cash a company generates after accounting for cash outflows to support operations and maintain assets.',
    category: categories.growth,
  },
  tenYRevenueGrowthPerShare: {
    title: '10-Year Revenue Growth Per Share',
    description:
      'Ten-year compound annual growth rate (CAGR) of revenue per share.',
    category: categories.growth,
  },
  fiveYRevenueGrowthPerShare: {
    title: '5-Year Revenue Growth Per Share',
    description:
      'Five-year compound annual growth rate (CAGR) of revenue per share.',
    category: categories.growth,
  },
  threeYRevenueGrowthPerShare: {
    title: '3-Year Revenue Growth Per Share',
    description:
      'Three-year compound annual growth rate (CAGR) of revenue per share.',
    category: categories.growth,
  },
  tenYOperatingCFGrowthPerShare: {
    title: '10-Year Operating CF Growth Per Share',
    description:
      'Ten-year compound annual growth rate (CAGR) of operating cash flow per share.',
    category: categories.growth,
  },
  fiveYOperatingCFGrowthPerShare: {
    title: '5-Year Operating CF Growth Per Share',
    description:
      'Five-year compound annual growth rate (CAGR) of operating cash flow per share.',
    category: categories.growth,
  },
  threeYOperatingCFGrowthPerShare: {
    title: '3-Year Operating CF Growth Per Share',
    description:
      'Three-year compound annual growth rate (CAGR) of operating cash flow per share.',
    category: categories.growth,
  },
  tenYNetIncomeGrowthPerShare: {
    title: '10-Year Net Income Growth Per Share',
    description:
      'Ten-year compound annual growth rate (CAGR) of net income per share.',
    category: categories.growth,
  },
  fiveYNetIncomeGrowthPerShare: {
    title: '5-Year Net Income Growth Per Share',
    description:
      'Five-year compound annual growth rate (CAGR) of net income per share.',
    category: categories.growth,
  },
  threeYNetIncomeGrowthPerShare: {
    title: '3-Year Net Income Growth Per Share',
    description:
      'Three-year compound annual growth rate (CAGR) of net income per share.',
    category: categories.growth,
  },
  tenYShareholdersEquityGrowthPerShare: {
    title: '10-Year Shareholders Equity Growth Per Share',
    description:
      'Ten-year compound annual growth rate (CAGR) of shareholders equity per share.',
    category: categories.growth,
  },
  fiveYShareholdersEquityGrowthPerShare: {
    title: '5-Year Shareholders Equity Growth Per Share',
    description:
      'Five-year compound annual growth rate (CAGR) of shareholders equity per share.',
    category: categories.growth,
  },
  threeYShareholdersEquityGrowthPerShare: {
    title: '3-Year Shareholders Equity Growth Per Share',
    description:
      'Three-year compound annual growth rate (CAGR) of shareholders equity per share.',
    category: categories.growth,
  },
  tenYDividendperShareGrowthPerShare: {
    title: '10-Year Dividend per Share Growth Per Share',
    description:
      'Ten-year compound annual growth rate (CAGR) of dividend per share.',
    category: categories.growth,
  },
  fiveYDividendperShareGrowthPerShare: {
    title: '5-Year Dividend per Share Growth Per Share',
    description:
      'Five-year compound annual growth rate (CAGR) of dividend per share.',
    category: categories.growth,
  },
  threeYDividendperShareGrowthPerShare: {
    title: '3-Year Dividend per Share Growth Per Share',
    description:
      'Three-year compound annual growth rate (CAGR) of dividend per share.',
    category: categories.growth,
  },
  receivablesGrowth: {
    title: 'Receivables Growth',
    description:
      'Year-over-year percentage increase in amounts due from customers.',
    category: categories.growth,
  },
  inventoryGrowth: {
    title: 'Inventory Growth',
    description:
      'Year-over-year percentage increase in the value of raw materials, work-in-progress, and finished goods.',
    category: categories.growth,
  },
  assetGrowth: {
    title: 'Asset Growth',
    description:
      'Year-over-year percentage increase in total assets owned by a company.',
    category: categories.growth,
  },
  bookValueperShareGrowth: {
    title: 'Book Value per Share Growth',
    description:
      'Year-over-year percentage increase in the value of a company divided by the number of outstanding shares.',
    category: categories.growth,
  },
  debtGrowth: {
    title: 'Debt Growth',
    description:
      'Year-over-year percentage increase in total debt obligations.',
    category: categories.growth,
  },
  rdexpenseGrowth: {
    title: 'R&D Expense Growth',
    description:
      'Year-over-year increase in spending on research and development activities.',
    category: categories.growth,
  },
  sgaexpensesGrowth: {
    title: 'SG&A Expenses Growth',
    description:
      'Year-over-year increase in selling, general, and administrative expenses.',
    category: categories.growth,
  },
}
