import { useMutation, useQuery, useQueryClient } from 'react-query'
import http from '../network/httpClient'

const Screener_URL = 'papi/screener/'

async function createScreenerFn({ body }) {
  let data = await http.post(`${Screener_URL}create`, body)
  return data.data.screener
}

async function readAllWatchlistsFn() {
  let data = await http.get(`${Screener_URL}summary`)
  return data.data
}

async function updateScreenerFn({ screener_id, body }) {
  let data = await http.put(`${Screener_URL}${screener_id}`, body)

  return data.data
}

async function deleteScreenersFn({ screener_id }) {
  return http.delete(`${Screener_URL}${screener_id}`)
}

export const useScreeners = () => {
  // Get QueryClient from the context
  const queryClient = useQueryClient()

  const {
    data: readAll,
    isLoading: isReadAllLoading,
    isError: isReadAllError,
  } = useQuery([Screener_URL], () => readAllWatchlistsFn())

  const {
    error: isCreateError,
    isLoading: isCreateScreenerLoading,
    mutate: createScreener,
  } = useMutation({
    mutationFn: createScreenerFn,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [Screener_URL],
      }),
  })

  const { mutate: updateScreener } = useMutation({
    mutationFn: updateScreenerFn,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [Screener_URL],
      }),
  })

  const {
    error: isDeleteError,
    isLoading: isDeleteScreenerLoading,
    mutate: deleteScreener,
  } = useMutation({
    mutationFn: deleteScreenersFn,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [Screener_URL],
      }),
  })

  const numberOfScreeners = readAll?.item?.Items?.length

  const isLoadings =
    isCreateScreenerLoading || isDeleteScreenerLoading || isReadAllLoading

  const isError = isReadAllError || isCreateError || isDeleteError

  return {
    readAll,
    isScreensLoading: isLoadings,
    isError,
    createScreener,
    deleteScreener,
    updateScreener,
    numberOfScreeners,
  }
}

export default useScreeners
