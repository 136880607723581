import React, { useState, useCallback, useMemo } from 'react'
import {
  useNewsDataSource,
  PostTypes,
  SOURCE_STOCKTWITS,
} from '../hooks/useDataSouce'
import { TotalsTrendWidget, TimeRange } from '../component'
import { QuoteBar } from 'core/QuoteBar/QuoteBar'
import { startCase } from 'lodash'
import { CompanyNewsColumns } from '../columns'
import { CoreBarChart } from 'core/Charts/GenericChart'
import numeral from 'numeral'
import Spinner from 'core/ScrollableContainer/Spinner/spinner.jsx'
import { intervals } from '../constants'
import styles from './NewsView.module.scss'
import sidebarClosed from '../assets/sidebarClosed.svg'
import sidebarOpen from '../assets/sidebarOpen.svg'
import stocktwitslogo from '../assets/stocktwitslogo.webp'
import { NewsTable } from '../component/NewsTable/NewsTable'
import { CardWrapper } from 'core/CardWrapper/CardWrapper'
import { Helmet } from 'react-helmet-async'
import { NoData } from 'core/NoData/NoData'

export const NewsView = () => {
  const {
    SingleStockNewsLoading,
    SingleStockSentimentLoading,
    singleStockNewsData_UI,
    singleStockSentiment_UI,
    setSocialRange,
    setTicker,
    ticker,
    newsRange,
  } = useNewsDataSource()

  const handleChangeSocialRange = useCallback(
    interval => {
      setSocialRange(interval)
    },
    [setSocialRange]
  )

  const handleToggleSideBar = useCallback(() => {
    setExpandSideBar(prev => !prev)
  }, [])

  const handleToggleBottomBar = useCallback(() => {
    setExpandBottomBar(prev => !prev)
  }, [])

  const handleToggleShowNewsTable = useCallback(() => {
    setShowNewsTable(prev => !prev)
  }, [])

  const stats = useMemo(
    () => [
      {
        label: 'News Posts Today:',
        value: singleStockNewsData_UI?.newsArticlesPostedToday ?? 'N/A',
      },
      {
        label: 'Average News Posts Per Day:',
        value:
          numeral(singleStockNewsData_UI?.averageNewsPosts).format('0') ??
          'N/A',
      },
      {
        label: 'Most Frequent Source:',
        value: singleStockNewsData_UI?.mostFrequentNewsSite?.site ?? 'N/A',
      },
      {
        label: 'Most Common Post Time:',
        value: singleStockNewsData_UI?.mostCommonPostTimeOfDay ?? 'N/A',
      },
    ],
    [singleStockNewsData_UI]
  )

  const [expandSideBar, setExpandSideBar] = useState(false)

  const [expandBottomBar, setExpandBottomBar] = useState(true)

  const [showNewsTable, setShowNewsTable] = useState(true)

  return (
    <div className={styles.standardViewContainer}>
      <Helmet>
        <title>{`Outseek | ${ticker} News`}</title>
        <meta
          name="description"
          content={
            'This page gives the user recent news articles along with some social media stats for a given company'
          }
        />
      </Helmet>
      <QuoteBar
        updateTicker={tickerIn => setTicker(tickerIn)}
        ticker={ticker}
      />
      <div className={styles.companyNewsViewComponent}>
        <div
          className={styles.companyNewsViewComponentLeft}
          style={{
            width: expandSideBar ? 'calc(100% - 68px)' : 'calc(100% - 323px)',
          }}
        >
          <CardWrapper
            height={
              expandBottomBar ? 'calc(100vh - 208px)' : 'calc(100vh - 334px)'
            }
            width={'100%'}
            label={`Recent ${ticker} News`}
            content={
              SingleStockNewsLoading ? (
                <Spinner />
              ) : singleStockNewsData_UI?.tableData.length > 0 ? (
                <NewsTable
                  data={singleStockNewsData_UI?.tableData}
                  height={'100%'}
                  swap={showNewsTable}
                  toggleSwap={handleToggleShowNewsTable}
                />
              ) : (
                <NoData />
              )
            }
          />
          <CardWrapper
            height={expandBottomBar ? 72 : 200}
            width={'100%'}
            label={expandBottomBar ? 'News Posts Volume' : 'News Stats'}
            expandable={true}
            toggleExpand={handleToggleBottomBar}
            expand={expandBottomBar}
            content={
              SingleStockNewsLoading ? (
                <Spinner />
              ) : singleStockNewsData_UI?.tableData.length > 0 ? (
                expandBottomBar ? (
                  <div className={styles.bottomBar}>
                    <div className={styles.bottomBarWrapper}>
                      {stats.map((stat, index) => (
                        <React.Fragment key={index}>
                          <div className={styles.bottomBarPairWrapper}>
                            <div className={styles.bottomBarLabel}>
                              {stat.label}
                            </div>
                            <div className={styles.bottomBarValue}>
                              {stat.value}
                            </div>
                          </div>
                          {index < stats.length - 1 && (
                            <div className={styles.bottomBarDivider} />
                          )}
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                ) : (
                  <CoreBarChart
                    data={singleStockNewsData_UI.chartData}
                    metrics={[{ key: 'count', color: 'var(--primary-color)' }]}
                    XAxisKey={'date'}
                    customTickFormatter
                    decimalCount={0}
                    zeroTick={true}
                    xAxisHeight={12}
                    axisWidth={30}
                  />
                )
              ) : (
                <NoData />
              )
            }
          />
        </div>
        <CardWrapper
          height={'calc(100vh - 128px)'}
          width={expandSideBar ? '60px' : '315px'}
          label={'Stocktwits'}
          expandable={true}
          toggleExpand={handleToggleSideBar}
          expand={expandSideBar}
          content={
            expandSideBar ? (
              <div className={styles.collapsedSideBar}>
                <img
                  className={styles.stocktwitsLogo}
                  src={stocktwitslogo}
                  alt={`${ticker}'s Stocktwits Insights`}
                />
                {!SingleStockSentimentLoading &&
                  PostTypes.map(entry => {
                    return (
                      <div className={styles.totalsTrendWidgetWrapper}>
                        <TotalsTrendWidget
                          Label={entry}
                          data={singleStockSentiment_UI?.TotalsTrendData}
                          metrics={[
                            {
                              key: SOURCE_STOCKTWITS + entry,
                              color: 'var(--primary-color)',
                              type: 'stepAfter',
                            },
                          ]}
                          collapsed={true}
                        />
                        <div className={styles.divider} />
                      </div>
                    )
                  })}
                <TimeRange
                  intervals={intervals}
                  onChange={handleChangeSocialRange}
                  vertical={true}
                  fitContent={true}
                  interval={newsRange}
                  gap={4}
                />
              </div>
            ) : (
              <div className={styles.companyNewsViewExpendedChartsWrapper}>
                {SingleStockSentimentLoading ? (
                  <Spinner />
                ) : (
                  <>
                    <TimeRange
                      intervals={intervals}
                      onChange={handleChangeSocialRange}
                      interval={newsRange}
                      fitContent={true}
                    />
                    {PostTypes.map(entry => {
                      return (
                        <TotalsTrendWidget
                          Label={startCase(SOURCE_STOCKTWITS + entry)}
                          data={singleStockSentiment_UI?.TotalsTrendData}
                          metrics={[
                            {
                              key: SOURCE_STOCKTWITS + entry,
                              color: 'var(--primary-color)',
                              type: 'stepAfter',
                            },
                          ]}
                        />
                      )
                    })}
                  </>
                )}
              </div>
            )
          }
        />
      </div>
    </div>
  )
}

export default NewsView
