import numeral from 'numeral'
import _ from 'lodash'
import moment from 'moment'
import TooltipTicker from '../../../core/TickerOverlay/TickerOverlay'
import { startCase } from 'lodash'

const capitalize = e => {
  const lower = e.value.toLowerCase()
  return lower.replace(/(^|\s)[a-z]/g, match => match.toUpperCase())
}

export const Top50TableColumns = [
  {
    Header: 'Investor Name',
    accessor: 'investorName',
    minWidth: 30,
    width: 340,
    maxWidth: 340,
    // filter: 'search',
    Cell: e => (
      <div
        title={e.value}
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {/* {capitalize(e)} */}
        {e.value}
      </div>
    ),
  },
  {
    Header: 'Ownership',
    accessor: 'ownership',
    minWidth: 70,
    width: 100,
    maxWidth: 300,
    Cell: e => (
      <div style={{ maxWidth: '220px' }}>
        {numeral(e.value).format('0.00')} %
      </div>
    ),
  },
  {
    Header: 'Market Value',
    accessor: 'marketValue',
    minWidth: 70,
    width: 100,
    maxWidth: 300,
    Cell: e => (
      <div style={{ maxWidth: '220px' }}>
        {numeral(e.value).format('0.000 a')}{' '}
      </div>
    ),
  },
  {
    Header: '# Of Shares',
    accessor: 'sharesNumber',
    minWidth: 70,
    width: 100,
    maxWidth: 300,
    Cell: e => (
      <div style={{ maxWidth: '220px' }}>
        {numeral(e.value).format('0.000 a')}{' '}
      </div>
    ),
  },
  {
    Header: 'Weight',
    accessor: 'weight',
    minWidth: 70,
    width: 70,
    maxWidth: 300,
    Cell: e => (
      <div style={{ maxWidth: '220px' }}>
        {numeral(e.value).format('0.00 a')}%
      </div>
    ),
  },
  {
    Header: 'First Added',
    accessor: 'firstAdded',
    minWidth: 30,
    width: 100,
    maxWidth: 300,
    Cell: e => {
      const date = () => {
        if (e.value === '2013-12-31') {
          return 'Before 2013'
        } else {
          return moment(e.value).format('ll')
        }
      }
      return <div style={{ maxWidth: '220px' }}>{date()} </div>
    },
  },
]

export const PortfolioHoldingsComposition = [
  {
    Header: 'Symbol',
    accessor: 'symbol',
    minWidth: 30,
    width: 50,
    maxWidth: 300,
    Cell: Cell => {
      if (Cell.value) {
        return <TooltipTicker ticker={Cell.value} />
      } else {
        return '-'
      }
    },
  },
  {
    Header: 'Security Name',
    accessor: 'securityName',
    minWidth: 70,
    width: 260,
    maxWidth: 300,
    Cell: e => <div>{capitalize(e)}</div>,
  },
  {
    Header: 'Weight',
    accessor: 'weight',
    minWidth: 70,
    width: 100,
    maxWidth: 300,
    Cell: e => {
      if (e.row.original.isSoldOut) {
        return <div style={{ color: 'var(--red)' }}>Sold Out</div>
      } else {
        return <div>{numeral(e.value).format('0.00')} % </div>
      }
    },
  },
  {
    Header: 'Mkt. Value',
    accessor: 'marketValue',
    minWidth: 70,
    width: 100,
    maxWidth: 300,
    Cell: e => <div>{numeral(e.value).format('0.000 a')} </div>,
  },
  {
    Header: 'Ownership',
    accessor: 'ownership',
    minWidth: 30,
    width: 80,
    maxWidth: 300,
    Cell: e => <div>{numeral(e.value).format('0.0 a')}% </div>,
  },
  {
    Header: '# Of Shrs',
    accessor: 'sharesNumber',
    minWidth: 70,
    width: 80,
    maxWidth: 300,
    Cell: e => <div>{numeral(e.value).format('0.00 a')} </div>,
  },
  {
    Header: 'Shrs Chg. %',
    accessor: 'changeInSharesNumberPercentage',
    minWidth: 30,
    width: 100,
    maxWidth: 300,
    Cell: e => <div>{numeral(e.value).format('0.0 a')} % </div>,
  },
  {
    Header: 'First Added',
    accessor: 'firstAdded',
    minWidth: 30,
    width: 100,
    maxWidth: 300,
    Cell: e => (
      <div style={{ maxWidth: '220px' }}>{moment(e.value).format('ll')} </div>
    ),
  },
]

export const InstOwnershipColumns = [
  {
    Header: 'Metric',
    accessor: 'metric',
    width: 200,
    Cell: Cell => {
      if (Cell.value === 'putCallRatio') {
        return <span>Put / Call Ratio</span>
      } else if (Cell.value === 'numberOf13Fshares') {
        return <span>Number of 13F Shares</span>
      } else {
        return <span>{_.startCase(Cell.value)}</span>
      }
    },
  },
  {
    Header: 'Value',
    accessor: 'firstValue',
    width: 100,
    Cell: Cell => {
      return <span>{numeral(Cell.value).format('0,0.00')}</span>
    },
  },
]

export const industryColumns = [
  {
    Header: 'Industry',
    accessor: 'industryTitle',
    Cell: Cell => {
      if (Cell.value === null || Cell.value === '') {
        return ''
      } else {
        return <span>{startCase(Cell.value?.toLowerCase())}</span>
      }
    },
  },
  {
    Header: 'Weight',
    accessor: 'weight',
    width: 50,
    Cell: Cell => {
      return <span> {numeral(Cell.value).format('0.00')}%</span>
    },
  },
]
