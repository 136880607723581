import styles from './TOS_PP.module.scss'
import { Helmet } from 'react-helmet-async'
import { NavBar } from 'pages/HomePage/HomePageComponents/NavBar/NavBar'

export const TermsOfService = () => {
  return (
    <>
      <NavBar />
      <div className={styles.backgroundWrapper}>
        <Helmet>
          <title>{`Outseek | Terms Of Use`}</title>
        </Helmet>
        <div className={styles.title}>Outseek Terms of Use</div>
        <div className={styles.lastUpdated}>
          Last updated: February 28th, 2024.
        </div>
        <article className={styles.TOS_PP}>
          <h1>1. OVERVIEW</h1>
          <p>
            These Terms of Use form a legally binding contract between OUTSEEK
            FINANCE INC. ("we" or "us") and the people and companies (“Users” or
            “you”) that access and use our website located at
            https://outseek.ca/ or any related website or mobile platform
            controlled by us (collectively, the "Website"). These Terms of Use
            apply to all services provided through our Website and any other
            software application we operate, and all content, services, and
            products available at or through the Website.
            <br />
            <br />
            It is very important that you read and fully understand these Terms
            of Use before using the Website. By agreeing to these Terms of Use
            and using the Website, you are entering into a legally binding
            contract which will impact your rights.
            <br />
            <br />
            By accessing any part of the Website, you agree to be bound by these
            Terms of Use. You are also confirming that you have read and fully
            understood these Terms of Use. If you do not agree to or understand
            all of these Terms of Use, then you may not access the Website or
            use any services available on the Website. These Terms of Use should
            be read in conjunction with the Privacy Policy and any other rules,
            guidelines, or policies posted on the Website.
          </p>
          <h1>2. SERVICES AVAILABLE ON THE WEBSITE</h1>
          <p>
            Without restriction, we offer the following services through the
            Website:
            <br />
            <br />
            <i>
              Our website provides a financial research and analysis terminal,
              offering users access to financial data, market insights, and
              analytical tools. We also offer subscription-based access to
              premium data and exclusive tools designed for in-depth financial
              analysis.
            </i>
          </p>
          <h1>3. PAYMENT PROCEDURES</h1>
          <p>
            Payments for services or products available on the Website will be
            charged to you in accordance with the policies, procedures, and
            timelines posted on the relevant sections of the Website.
            <br />
            <br />
            You agree to pay the fees applicable to your subscription and any
            other applicable fees, including but not limited to fees relating to
            the processing of transactions under your account (“Fees”).
            <br />
            <br />
            All initial and recurring Fees will be charged to the payment method
            that you authorize for your account. It is your responsibility to
            keep your authorized payment method valid and up to date at all
            times. We may terminate or block access to your account if your
            credit card becomes expired or otherwise invalid at the time any
            Fees become due.
            <br />
            <br />
            For purposes of processing payments, we use the following service
            provider:
            <br />
            <br />
            STRIPE
            <br />
            <br />
            Before using the Website, you must first review and approve the
            terms and conditions governing the use of these third-party payment
            processors, which are available at the following website(s):
            https://www.stripe.com
          </p>
          <h1>4. REFUND POLICIES</h1>
          <p>
            We currently do not offer refunds for any of our services or
            products.
          </p>
          <h1>5. YOUR ACCOUNT</h1>
          <p>
            In order to fully use the Website, you will be required to create an
            account. Information submitted by you in order to create an account
            will be subject to our Privacy Policy, which is available for review
            on the Website, and which you are required to approve before
            creating an account. The Privacy Policy also dictates how to
            terminate your account and how you can have your personal
            identifying information deleted from our servers.
            <br />
            <br />
            The terms governing the features and capabilities of your account
            and the related fees can be found on the Website.
          </p>
          <h1>6. TERMINATION</h1>
          <p>
            You may cancel and terminate your Account at any time in accordance
            with the terms and policies posted on the Website.
            <br />
            <br />
            If at the date of termination of your Account, there are any
            outstanding payments owing by you to us, you will receive one final
            invoice via email. Once that invoice has been paid in full, you will
            not be charged again.
          </p>
          <h1>7. LIMITATION OF LIABILITY</h1>
          <p>
            OUTSEEK FINANCE INC., its affiliates, employees, agents, nor any
            third parties working on our behalf shall be liable for any loss,
            injury, claim, liability, or damages of any kind resulting from your
            use of the Website. This encompasses any direct, indirect,
            incidental, financial, special, consequential, or exemplary damages
            arising from your access to, use of, or inability to use the
            Website, or from your reliance on any information provided on the
            Website, even if advised of the possibility of such damages.
            <br />
            <br />
            The content provided on the Website is for informational purposes
            only and should not be construed as financial or investment advice.
            OUTSEEK FINANCE INC. is not a financial advisor, and the information
            on our Website does not substitute for professional financial
            advice. Always consult with a qualified financial advisor before
            making any investment decisions. By using the Website, you agree
            that you are solely responsible for your own investment research and
            decisions, and you hereby waive the right to claim any damages
            resulting from your reliance on any information obtained from the
            Website.
            <br />
            <br />
            The Website is provided on an “as is” and “as available” basis
            without warranties of any kind, express, implied, or statutory,
            including but not limited to, warranties of merchantability, fitness
            for a particular purpose, or non-infringement. We do not guarantee
            that the Website will be uninterrupted, secure, or error-free.
            <br />
            <br />
          </p>

          <h1>8. AUTHORIZED USE OF INFORMATION AND DATA</h1>
          <p>
            You are granted the right to access and utilize the Information
            strictly in alignment with the stipulations of this Agreement.
            Unauthorized actions, such as the redistribution or alteration of
            the Information, either in entirety or partially, are strictly
            forbidden. You are bound by this Agreement not to disseminate,
            disclose, or make available any portion of the Information’s content
            or essence to any third party, herein referred to as any individual,
            sole proprietorship, corporation, partnership, or any other form of
            organization. You understand and consent that as a prerequisite for
            the ongoing acquisition of Information from OUTSEEK FINANCE INC.,
            its providers, or any of their affiliates or representatives, you
            are prohibited from initiating, endorsing, or facilitating the
            trading of financial securities or investment vehicles (including,
            but not limited to, derivatives, structured products, investment
            funds, exchange-traded funds, or derivatives tied to exchange-traded
            funds) that are priced, yield, or perform based on, are linked to,
            or aim to emulate any index offered by any of OUTSEEK FINANCE INC.'s
            providers or any financial security or investment vehicle associated
            with such an index without a distinct written consent from the
            relevant OUTSEEK FINANCE INC. provider for such activities.
            <br />
            <br /> You are solely accountable for the secrecy of your account
            password and must promptly alert OUTSEEK FINANCE INC. of any actual
            or suspected unauthorized access to your account, or any breach of
            security, including the loss, theft, or unauthorized revelation of
            your username, password, or payment information. You bear full
            responsibility for all actions conducted through your account, which
            includes, but is not limited to, access by any third party whom you
            have permitted to use your username and password. In the event that
            the computer system through which you have accessed the Service is
            sold or transferred to another entity, you guarantee to erase all
            cookies and software files acquired via or during the use of the
            Service.
          </p>

          <h1>9. INDEMNIFICATION</h1>
          <p>
            You agree to indemnify and hold harmless OUTSEEK FINANCE INC.,
            including our officers, directors, shareholders, employees and
            agents, from and against any and all claims and expenses, including
            legal fees and disbursements, which are made against us and arise
            out of your use of the Website, including but not limited to your
            violation of any term of these Terms of Use or any other policy
            posted on the Website.
          </p>
          <h1>10. SECURITY BREACHES</h1>
          <p>
            In order to protect your security, it is your sole responsibility to
            ensure that all usernames and passwords used to access the Website
            are kept secure and confidential.
            <br />
            <br />
            You must immediately notify us of any unauthorized use of your
            account, including the unauthorized use of your password, or any
            other breach of security.
            <br />
            <br />
            We will investigate any breach of security on the Website that we
            determine in our sole discretion to be serious in nature, but we
            will not be held responsible or liable in any manner for breaches of
            security or any unauthorized access to your account however arising.
          </p>
          <h1>11. COMPLIANCE WITH LAWS</h1>
          <p>
            You represent and warrant that:
            <br />
            <br />
            <ol type="i">
              <li>
                You have the authority to bind yourself to these Terms of Use;
              </li>
              <li>
                Your use of the Website will be solely for purposes that are
                permitted by these Terms of Use;
              </li>
              <li>
                Your use of the Website will not infringe or misappropriate the
                confidentiality or intellectual property rights of any User or
                third party; and
              </li>
              <li>
                Your use of the Website will comply with all local, provincial
                and federal laws, rules and regulations, and with all policies
                posted on the Website.
              </li>
            </ol>
            You must only use the Website for your own lawful purposes, in
            accordance with these Terms of Use and any notice, policy or
            condition posted on the Website.
          </p>
          <h1>12. AGE RESTRICTIONS</h1>
          <p>
            Users Must be Over the Age of 18. You represent and confirm that you
            are over the age of 18. We do not target, market, or promote the
            Website to those under 18. We do not permit any User under the age
            of 18 to use the Website.
          </p>
          <h1>13. GOVERNING LAW AND DISPUTE RESOLUTION</h1>
          <p>
            These Terms of Use and any access to or use of the Website shall be
            governed by, and construed in accordance with, the laws in force in
            the Province of Ontario.
            <br />
            <br />
            If any claim, dispute or controversy occurs between OUTSEEK FINANCE
            INC. and a User relating to the interpretation or implementation of
            any of the provisions of these Terms of Use, such dispute shall be
            resolved by private, confidential and binding arbitration. Such
            arbitration shall be conducted by a single arbitrator. The
            arbitrator shall be appointed by agreement of the parties or, in the
            absence of an agreement, such arbitrator shall be appointed by a
            judge upon the application of either the User or OUTSEEK FINANCE
            INC. Arbitration shall be held in the Province of Ontario, unless
            otherwise agreed by the parties. The arbitration procedure to be
            followed shall be agreed by the parties or, in absence of an
            agreement, determined by the arbitrator. The arbitration shall
            proceed in accordance with the provisions of the Arbitration Act,
            1991, SO 1991, c 17. Subject to any right of appeal, the decision
            arrived at by the arbitrator shall be final and binding. Judgment
            upon the award rendered by the arbitrator may be entered in any
            court having jurisdiction.
          </p>
          <h1>14. WAIVER OF CLASS ACTION</h1>
          <p>
            By using the Website, you agree to resolve any claim or dispute
            arising between you and us on an individual basis, rather than
            addressing such claim or dispute as part of a group or class. You
            hereby waive any right you may have to commence or participate in
            any class action lawsuit commenced against OUTSEEK FINANCE INC. or
            its affiliates related to any claim, dispute or controversy arising
            from your use of the Website. Where applicable, you hereby agree to
            opt out of any class proceeding against OUTSEEK FINANCE INC.
            otherwise commenced.
            <br />
            <br />
            The above waiver shall not apply to claims or disputes arising under
            consumer protection legislation or any other claim or dispute where
            a waiver of class action lawsuits is unenforceable at law.
          </p>
          <h1>15. GENERAL TERMS</h1>
          <p>
            <ol type="a" className={styles.listItemNoIndent}>
              <li>
                <h5>AMENDING THESE TERMS OF USE</h5>
                <div className={styles.listItem}>
                  These Terms of Use may be updated and amended from time to
                  time. We reserve the right to change these Terms of Use at any
                  time, and any amended Terms of Use are effective upon posting
                  to the Website. We will make efforts to communicate any
                  changes to these Terms of Use we deem material, in our sole
                  discretion, via email or notifications on the Website. Your
                  continued use of the Website will be deemed to be immediate
                  and unconditional acceptance of any amended Terms of Use,
                  whether or not we deemed the amendments to be material.
                </div>
              </li>
              <li>
                <h5>ASSIGNMENT</h5>
                <div className={styles.listItem}>
                  We may assign or delegate these Terms of Use, in whole or in
                  part, to any person or entity at any time with or without your
                  consent and without prior notice to you. Users may not assign
                  or delegate any rights or obligations under these Terms of
                  Use, without our prior written consent, and any unauthorized
                  assignment and delegation is void.
                </div>
              </li>
              <li>
                <h5> NO WAIVER</h5>
                <div className={styles.listItem}>
                  No waiver of a provision, right or remedy of this Agreement
                  shall operate as a waiver of any other provision, right or
                  remedy or the same provision, right or remedy on a future
                  occasion.
                </div>
              </li>
              <li>
                <h5>NO AGENCY</h5>
                <div className={styles.listItem}>
                  The parties to these Terms of Use are independent contractors
                  and are not partners or agents. OUTSEEK FINANCE INC. has no
                  fiduciary obligations or professional obligations whatsoever
                  to you arising from these Terms of Use or your use of the
                  Website.
                </div>
              </li>
              <li>
                <h5> SEVERABILITY</h5>
                <div className={styles.listItem}>
                  In the event that any provision or part of this Agreement is
                  found to be void or invalid by a court of law, the remaining
                  provisions, or parts thereof, shall be and remain in full
                  force and effect.
                </div>
              </li>
              <li>
                <h5>ENTIRE AGREEMENT</h5>
                <div className={styles.listItem}>
                  These Terms of Use, in conjunction with all policies and
                  guidelines available on the Website (including but not limited
                  to any Privacy Policy), incorporated by reference, constitute
                  the entire agreement between you and OUTSEEK FINANCE INC. and
                  supersede all prior communications, agreements and
                  understandings, written or oral, with respect to the subject
                  matter of these Terms of Use.
                </div>
              </li>
            </ol>
          </p>
          <h1>16. QUESTIONS ON THESE TERMS OF USE</h1>
          <p>
            We welcome you to contact us with any questions on these Terms of
            Use. You can send your questions regarding these Terms of Use to the
            following email address: business@outseek.ca
          </p>

          <h1>17. CONFIRMATION OF AGREEMENT TO TERMS OF USE</h1>
          <p>
            BY PROCEEDING TO USE THE WEBSITE, YOU ACKNOWLEDGE THAT YOU HAVE
            READ, UNDERSTOOD AND AGREE TO BE BOUND BY THESE TERMS OF USE AND ANY
            POLICIES AND NOTICES POSTED ON THE WEBSITE.
          </p>
        </article>
        <a
          className={styles.subTitle}
          href="https://site.financialmodelingprep.com/"
          target="_blank"
          rel="noreferrer"
        >
          Data provided by Financial Modeling Prep
        </a>
      </div>
    </>
  )
}
