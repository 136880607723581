const FinancialRatiosKeyMap = {
  dividendYield: 'Dividend Yield',
  dividendYielPercentage: 'Dividend Yield Percentage',
  peRatio: 'P/E Ratio',
  pegRatio: 'PEG Ratio',
  payoutRatio: 'Payout Ratio',
  currentRatio: 'Current Ratio',
  quickRatio: 'Quick Ratio',
  cashRatio: 'Cash Ratio',
  daysOfSalesOutstanding: 'Days Of Sales Outstanding',
  daysOfInventoryOutstanding: 'Days Of Inventory Outstanding',
  operatingCycle: 'Operating Cycle',
  daysOfPayablesOutstanding: 'Days Of Payables Outstanding',
  cashConversionCycle: 'Cash Conversion Cycle',
  grossProfitMargin: 'Gross Profit Margin',
  operatingProfitMargin: 'Operating Profit Margin',
  pretaxProfitMargin: 'Pre tax Profit Margin',
  netProfitMargin: 'Net Profit Margin',
  effectiveTaxRate: 'Effective Tax Rate',
  returnOnAssets: 'Return OnAssets',
  returnOnEquity: 'Return On Equity',
  returnOnCapitalEmployed: 'Return On Capital Employed',
  netIncomePerEBT: 'Net Income Per EBT',
  ebtPerEbit: 'EBT Per Ebit',
  ebitPerRevenue: 'EBIT Per Revenue',
  debtRatio: 'Debt Ratio',
  debtEquityRatio: 'Debt Equity Ratio',
  longTermDebtToCapitalization: 'Long Term Debt To Capitalization',
  totalDebtToCapitalization: 'Total Debt To Capitalization',
  interestCoverage: 'Interest Coverage',
  cashFlowToDebtRatio: 'Cash Flow To Debt Ratio',
  companyEquityMultiplier: 'Company Equity Multiplier',
  receivablesTurnover: 'Receivables Turnover',
  payablesTurnover: 'Payables Turnover',
  inventoryTurnover: 'Inventory Turnover',
  fixedAssetTurnover: 'FixedAsset Turnover',
  assetTurnover: 'Asset Turnover',
  operatingCashFlowPerShare: 'Operating Cash Flow Per Share',
  freeCashFlowPerShare: 'Free Cash Flow Per Share',
  cashPerShare: 'Cash Per Share',
  operatingCashFlowSalesRatio: 'Operating Cash Flow Sales Ratio',
  freeCashFlowOperatingCashFlowRatio:
    'Free Cash Flow Operating Cash Flow Ratio',
  cashFlowCoverageRatios: 'Cash Flow Coverage Ratios',
  shortTermCoverageRatios: 'Short Term Coverage Ratios',
  capitalExpenditureCoverageRatio: 'Capital Expenditure Coverage Ratio',
  dividendPaidAndCapexCoverageRatio: 'Dividend Paid And Capex Coverage Ratio',
  priceBookValueRatio: 'Price Book Value Ratio',
  priceToBookRatio: 'Price To Book Ratio',
  priceToSalesRatio: 'Price To Sales Ratio',
  priceEarningsRatio: 'Price Earnings Ratio',
  priceToFreeCashFlowsRatio: 'Price To Free Cash Flows Ratio',
  priceToOperatingCashFlowsRatio: 'Price To Operating Cash Flows Ratio',
  priceCashFlowRatio: 'Price Cash Flow Ratio',
  priceEarningsToGrowthRatio: 'Price Earnings To Growth Ratio',
  priceSalesRatio: 'Price Sales Ratio',

  enterpriseValueMultiple: 'Enterprise Value Multiple',
  priceFairValue: 'Price Fair Value',
  dividendPerShare: 'Dividend Per Share',
}
const KeyMetricsKeyMap = {
  revenuePerShare: 'Revenue Per Share',
  netIncomePerShare: 'Net Income Per Share',
  operatingCashFlowPerShare: 'Operating Cash Flow Per Share',
  freeCashFlowPerShare: 'Free Cash Flow Per Share',
  cashPerShare: 'Cash Per Share',
  bookValuePerShare: 'Book Value Per Share',
  tangibleBookValuePerShare: 'Tangible Book Value Per Share',
  shareholdersEquityPerShare: 'Shareholders Equity Per Share',
  interestDebtPerShare: 'Interest Debt Per Share',
  marketCap: 'Market Cap',
  enterpriseValue: 'Enterprise Value',
  peRatio: 'P/E Ratio',
  priceToSalesRatio: 'Price To Sales Ratio',
  pocfratio: 'POCF Ratio',
  pfcfRatio: 'Price To Free Cash Flow Ratio',
  pbRatio: 'Price To Book Ratio',
  ptbRatio: 'Price To Book Ratio',
  evToSales: 'Enterprise Value To Sales Ratio',
  enterpriseValueOverEBITDA: 'Enterprise Value Over EBITDA',
  evToOperatingCashFlow: 'Enterprise Value To Operating Cash Flow',
  evToFreeCashFlow: 'Enterprise Value To Free Cash Flow',
  earningsYield: 'Earnings Yield',
  freeCashFlowYield: 'Free Cash Flow Yield',
  debtToEquity: 'Debt To Equity',
  debtToAssets: 'Debt To Assets',
  netDebtToEBITDA: 'Bet Debt To EBITDA',
  currentRatio: 'Current Ratio',
  interestCoverage: 'Interest Coverage',
  incomeQuality: 'Income Quality',
  dividendYield: 'Dividend Yield',
  dividendYieldPercentage: 'Dividend Yield Percentage',
  payoutRatio: 'Payout Ratio',
  salesGeneralAndAdministrativeToRevenue:
    'Sales General And Administrative To Revenue',
  researchAndDevelopementToRevenue: 'Research And Developement To Revenue',
  intangiblesToTotalAssets: 'Intangibles To Total Assets',
  capexToOperatingCashFlow: 'Capex To Operating Cash Flow',
  capexToRevenue: 'Capex To Revenue',
  capexToDepreciation: 'Capex To Depreciation',
  stockBasedCompensationToRevenue: 'Stock Based Compensation To Revenue',
  grahamNumber: 'Graham Number',
  roic: 'Return On Invested Capital',
  returnOnTangibleAssets: 'Return On Tangible Assets',
  grahamNetNet: 'Graham Net Net',
  workingCapital: 'Working Capital',
  tangibleAssetValue: 'Tangible Asset Value',
  netCurrentAssetValue: 'Net Current Asset Value',
  investedCapital: 'Invested Capital',
  averageReceivables: 'Average Receivables',
  averagePayables: 'Average Payables',
  averageInventory: 'Average Inventory',
  daysSalesOutstanding: 'Days Sales Outstanding',
  daysPayablesOutstanding: 'Days Payables Outstanding',
  daysOfInventoryOnHand: 'Days Of Inventory On Hand',
  receivablesTurnover: 'Receivables Turnover',
  payablesTurnover: 'Payables Turnover',
  inventoryTurnover: 'Inventory Turnover',
  roe: 'Return On Equity',
  capexPerShare: 'Capex Per Share',
}
const GrowthKeyMap = {
  revenueGrowth: 'Revenue Growth',
  grossProfitGrowth: 'Gross Profit Growth',
  ebitgrowth: 'EBIT growth',
  operatingIncomeGrowth: 'Operating Income Growth',
  netIncomeGrowth: 'net Income Growth',
  epsgrowth: 'EPS growth',
  epsdilutedGrowth: 'EPS diluted Growth',
  weightedAverageSharesGrowth: 'Weighted Average Shares Growth',
  weightedAverageSharesDilutedGrowth: 'Weighted Average Shares Diluted Growth',
  dividendsperShareGrowth: 'Dividends per Share Growth',
  operatingCashFlowGrowth: 'Operating Cash Flow Growth',
  freeCashFlowGrowth: 'Free Cash Flow Growth',
  tenYRevenueGrowthPerShare: 'Ten Year Revenue Growth Per Share',
  fiveYRevenueGrowthPerShare: 'Five Year Revenue Growth Per Share',
  threeYRevenueGrowthPerShare: 'Three Year Revenue Growth Per Share',
  tenYOperatingCFGrowthPerShare: 'Ten Year Operating CF Growth Per Share',
  fiveYOperatingCFGrowthPerShare: 'Five Year Operating CF Growth Per Share',
  threeYOperatingCFGrowthPerShare: 'Three Year Operating CF Growth Per Share',
  tenYNetIncomeGrowthPerShare: 'Ten Year Net Income Growth Per Share',
  fiveYNetIncomeGrowthPerShare: 'Five Year Net Income Growth Per Share',
  threeYNetIncomeGrowthPerShare: 'Three Year Net Income Growth Per Share',
  tenYShareholdersEquityGrowthPerShare:
    'Ten Year Shareholders Equity Growth Per Share',
  fiveYShareholdersEquityGrowthPerShare:
    'Five Year Shareholders Equity Growth Per Share',
  threeYShareholdersEquityGrowthPerShare:
    'Three Year Shareholders Equity Growth Per Share',
  tenYDividendperShareGrowthPerShare:
    'Ten Year Dividend Per Share Growth Per Share',
  fiveYDividendperShareGrowthPerShare:
    'Five Year Dividend Per Share Growth Per Share',
  threeYDividendperShareGrowthPerShare:
    'Three Year Dividend Per Share Growth Per Share',
  receivablesGrowth: 'Receivables Growth',
  inventoryGrowth: 'Inventory Growth',
  assetGrowth: 'Asset Growth',
  bookValueperShareGrowth: 'Book Value Per Share Growth',
  debtGrowth: 'Debt Growth',
  rdexpenseGrowth: 'Research And Development Expense Growth',
  sgaexpensesGrowth: 'Selling, General And Administrative Expenses Growth',
}
const BalanceSheetKeyMap = {
  cashAndCashEquivalents: 'Cash And Cash Equivalents',
  shortTermInvestments: 'Short Term Investments',
  cashAndShortTermInvestments: 'Cash And Short Term Investments',
  netReceivables: 'Net Receivables',
  inventory: 'Inventory',
  otherCurrentAssets: 'Other Current Assets',
  totalCurrentAssets: 'Total Current Assets',
  propertyPlantEquipmentNet: 'Property Plant Equipment Net',
  goodwill: 'Goodwill',
  intangibleAssets: 'Intangible Assets',
  goodwillAndIntangibleAssets: 'Goodwill And Intangible Assets',
  longTermInvestments: 'Long Term Investments',
  taxAssets: 'Tax Assets',
  otherNonCurrentAssets: 'Other Non Current Assets',
  totalNonCurrentAssets: 'Total Non Current Assets',
  otherAssets: 'Other Assets',
  totalAssets: 'Total Assets',
  accountPayables: 'Account Payables',
  shortTermDebt: 'Short Term Debt',
  taxPayables: 'Tax Payables',
  otherCurrentLiabilities: 'Other Current Liabilities',
  totalCurrentLiabilities: 'Total Current Liabilities',
  longTermDebt: 'Long Term Debt',
  deferredRevenueNonCurrent: 'Deferred Revenue Non Current',
  deferredTaxLiabilitiesNonCurrent: 'Deferred Tax Liabilities Non Current',
  otherNonCurrentLiabilities: 'Other Non Current Liabilities',
  totalNonCurrentLiabilities: 'Total Non Current Liabilities',
  otherLiabilities: 'Other Liabilities',
  capitalLeaseObligations: 'Capital Lease Obligations',
  totalLiabilities: 'Total Liabilities',
  preferredStock: 'Preferred Stock',
  retainedEarnings: 'Retained Earnings',
  accumulatedOtherComprehensiveIncomeLoss:
    'Accumulated Other Comprehensive Income Loss',
  othertotalStockholdersEquity: 'Other total Stockholders Equity',
  totalStockholdersEquity: 'Total Stockholders Equity',
  totalLiabilitiesAndStockholdersEquity:
    'Total Liabilities And Stockholders Equity',
  minorityInterest: 'Minority Interest',
  totalEquity: 'Total Equity',
  totalLiabilitiesAndTotalEquity: 'Total Liabilities And Total Equity',
  totalInvestments: 'Total Investments',
  totalDebt: 'Total Debt',
  netDebt: 'Net Debt',
}
const CashFlowKeyMap = {
  netIncome: 'Net Income',
  depreciationAndAmortization: 'Depreciation And Amortization',
  deferredIncomeTax: 'Deferred Income Tax',
  stockBasedCompensation: 'Stock Based Compensation',
  changeInWorkingCapital: 'Change In Working Capital',
  accountsReceivables: 'Accounts Receivables',
  inventory: 'Inventory',
  accountsPayables: 'Accounts Payables',
  otherWorkingCapital: 'Other Working Capital',
  otherNonCashItems: 'Other Non Cash Items',
  netCashProvidedByOperatingActivities:
    'Net Cash Provided By Operating Activities',
  investmentsInPropertyPlantAndEquipment:
    'Investments In Property Plant And Equipment',
  acquisitionsNet: 'Acquisitions Net',
  purchasesOfInvestments: 'Purchases Of Investments',
  salesMaturitiesOfInvestments: 'Sales Maturities Of Investments',
  otherInvestingActivites: 'Other Investing Activites',
  netCashUsedForInvestingActivites: 'Net Cash Used For Investing Activites',
  debtRepayment: 'Debt Repayment',
  commonStockIssued: 'Common Stock Issued',
  commonStockRepurchased: 'Common Stock Repurchased',
  dividendsPaid: 'Dividends Paid',
  otherFinancingActivites: 'Other Financing Activites',
  netCashUsedProvidedByFinancingActivities:
    'Net Cash Used Provided By Financing Activities',
  effectOfForexChangesOnCash: 'Effect Of Forex Changes On Cash',
  netChangeInCash: 'Net Change In Cash',
  cashAtEndOfPeriod: 'Cash At End Of Period',
  cashAtBeginningOfPeriod: 'Cash At Beginning Of Period',
  operatingCashFlow: 'Operating Cash Flow',
  capitalExpenditure: 'Capital Expenditure',
  freeCashFlow: 'Free Cash Flow',
}
const IncomeStatementKeyMap = {
  revenue: 'Revenue',
  costOfRevenue: 'Cost Of Revenue',
  grossProfit: 'Gross Profit',
  grossProfitRatio: 'Gross Profit Ratio',
  researchAndDevelopmentExpenses: 'Research And Development Expenses',
  generalAndAdministrativeExpenses: 'General And Administrative Expenses',
  sellingAndMarketingExpenses: 'Selling And Marketing Expenses',
  sellingGeneralAndAdministrativeExpenses:
    'Selling General And Administrative Expenses',
  otherExpenses: 'Other Expenses',
  operatingExpenses: 'Operating Expenses',
  costAndExpenses: 'Cost And Expenses',
  interestIncome: 'Interest Income',
  interestExpense: 'Interest Expense',
  depreciationAndAmortization: 'Depreciation And Amortization',
  ebitda: 'EBITDA',
  ebitdaratio: 'EBITDA Ratio',
  operatingIncome: 'operatingIncome',
  operatingIncomeRatio: 'Operating Income Ratio',
  totalOtherIncomeExpensesNet: 'Total Other Income Expenses Net',
  incomeBeforeTax: 'Income Before Tax',
  incomeBeforeTaxRatio: 'Income Before Tax Ratio',
  incomeTaxExpense: 'Income Tax Expense',
  netIncome: 'Net Income',
  netIncomeRatio: 'Net Income Ratio',
  eps: 'EPS',
  epsdiluted: 'EPS Diluted',
  weightedAverageShsOut: 'Weighted Average Shares Outstanding',
  weightedAverageShsOutDil: 'Weighted Average Shares Outstanding Diluted',
}

export const comparisonChartsKeyMap = {
  FinancialRatiosKeyMap,
  KeyMetricsKeyMap,
  GrowthKeyMap,
  BalanceSheetKeyMap,
  IncomeStatementKeyMap,
  CashFlowKeyMap,
}
