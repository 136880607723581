import TooltipTicker from 'core/TickerOverlay/TickerOverlay'

export const splitColumns = [
  {
    Header: '',
    accessor: 'date',
    width: '0px',
    disableGroupBy: true,
    Cell: Cell => {
      return ''
    },
  },

  {
    Header: 'Symbol',
    accessor: 'symbol',
    width: 'fit-content',
    disableGroupBy: true,
    Search: true,
    Cell: Cell => {
      return <TooltipTicker ticker={Cell.value} />
    },
  },
  {
    Header: 'Numerator',
    accessor: 'numerator',
    width: 'fit-content',
    disableGroupBy: true,
  },
  {
    Header: 'Denominator',
    accessor: 'denominator',
    width: 'fit-content',
    disableGroupBy: true,
  },
]
