import styles from './DataTable.module.scss'

export const DataTable = ({ data }) => {
  return (
    <div className={styles.dataTableWrapper}>
      {data.map((metric, i) => {
        return (
          <div>
            <div className={styles.dataRow}>
              <div>{metric.label}</div>
              <div className={styles.dataValue}>{metric.value}</div>
            </div>
            {/* {i !== data.length - 1 && i !== data.length - 2 && (
              <div className={styles.divider} />
            )} */}
            {i !== data.length - 1 && <div className={styles.divider} />}
          </div>
        )
      })}
    </div>
  )
}
