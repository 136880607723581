export const customStyles = {
  control: () => ({
    backgroundColor: 'var(--secondary-primary)',
    display: 'flex',
    color: 'var(--white)',
    flexDirection: 'row',
    height: 36,
    width: 'fit-content',
    alignItems: 'center',
    fontSize: '14px',
    margin: '0px',
    borderRadius: '4px',
    cursor: 'pointer',
  }),

  indicatorsContainer: provided => ({
    ...provided,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: '8px',
  }),
  placeholder: base => ({
    ...base,
    color: 'var(--light-grey-accent)',
    fontSize: '14px',
    whiteSpace: 'nowrap',
  }),
  singleValue: () => ({
    color: 'var(--light-grey-accent)',
    gridArea: '1/1/2/3',
    marginLeft: '1px',
    marginRight: '1px',
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    fontSize: '14px',
    cursor: 'text',
  }),
  indicatorSeparator: () => ({
    backgroundColor: 'var(--light-grey-accent)',
    alignSelf: 'stretch',
    marginBottom: '8px',
    marginTop: '8px',
    width: '1px',
    boxSizing: 'border-box',
  }),
  menu: () => ({
    position: 'absolute',
    backgroundColor: 'var(--background-primary)',
    borderRadius: '4px',
    border: '2px solid var(--grey-accent)',
    boxShadow:
      '0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 12px hsl(0deg 0% 0% / 10%)',
    marginBottom: '8px',
    marginTop: '8px',
    zIndex: 100,
    boxSizing: 'border-box',
    width: '600px',
  }),
  menuPortal: provided => ({ ...provided, zIndex: 9999 }),
  MenuList: () => ({
    paddingBottom: '4px',
    maxHeight: '100px',
    overflowY: 'auto',
    paddingTop: '4px',
    zIndex: 100,
    position: 'relative',
    boxSizing: 'border-box',
  }),
  option: () => ({
    backgroundColor: 'transparent',
    color: 'var(--off-white)',
    display: 'block',
    fontSize: '13px',
    zIndex: 100,
    padding: '8px 12px',
    width: '',
    cursor: 'pointer',
    boxSizing: 'border-box',
    '&:hover': {
      backgroundColor: 'var(--button-hover)',
    },
  }),
  input: (provided, state) => ({
    ...provided,
    color: 'var(--light-grey-accent)',
    fontSize: '14px',
  }),
}
