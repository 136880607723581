import {
  canAccessFeature,
  PREMIUM_FEATURES,
} from 'pages/Pricing/PriceTable/pricingConfig'
import { useMemo } from 'react'
import useDashboards from './useDashboards'
import { useUserGroup } from './userGroupHook'
import useScreener from './useScreener'
import useWatchlists from './useWatchlist'
import { useSelector } from 'react-redux'
/**
 * Hook for placing the restrictions on the user actions of
 * creating outseek resources. Basically just wrappers to the canAccessFeature
 * but needs to be stateful since we are pulling in user group.
 * @returns map of canCreate functions
 */
export const usePaidFeature = () => {
  const { numberOfWatchlists } = useWatchlists()
  const { numberOfScreeners } = useScreener()
  // const { numberOfDashboards } = useDashboards()
  const { userGroup } = useUserGroup()
  const names = useSelector(state => state.dashboardNames)

  const numberOfDashboards = useMemo(() => {
    return names.length
  }, [names])

  const canCreateWatchlist = useMemo(() => {
    return canAccessFeature(
      userGroup,
      PREMIUM_FEATURES.SAVED_WATCHLISTS,
      numberOfWatchlists
    )
  }, [numberOfWatchlists, userGroup])

  const canCreateScreener = useMemo(() => {
    return canAccessFeature(
      userGroup,
      PREMIUM_FEATURES.SAVED_SCREEN,
      numberOfScreeners
    )
  }, [numberOfScreeners, userGroup])

  const canCreateDashboard = useMemo(() => {
    return canAccessFeature(
      userGroup,
      PREMIUM_FEATURES.SAVED_DASHBOARDS,
      numberOfDashboards
    )
  }, [numberOfDashboards, userGroup])

  return {
    canCreateScreener,
    canCreateWatchlist,
    canCreateDashboard,
  }
}

export default usePaidFeature
