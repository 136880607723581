import React, { useState, useEffect, useRef } from 'react'
import http from 'network/httpClient'
import styles from './styles.module.scss'
import { UilArrowUp, UilTimes } from '@iconscout/react-unicons'
import Spinner from 'core/ScrollableContainer/Spinner/spinner'
import { Switch } from 'core/Switch/Switch'
import CoreButton from 'core/Button/CoreButton'
import { displayFilters } from '../components/SavedScreens/components/utils'

const Message = ({ text, isUser }) => (
  <div
    className={`${styles.message} ${
      isUser ? styles.userMessage : styles.botMessage
    }`}
  >
    {text}
  </div>
)

const Chat = ({ setFilters, filters, aiView, setAiView }) => {
  const [messages, setMessages] = useState(() => {
    const savedMessages = localStorage.getItem('chatMessages')
    return savedMessages ? JSON.parse(savedMessages) : []
  })

  const [input, setInput] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [addToFilters, setAddToFilters] = useState(true)
  const messagesEndRef = useRef(null)

  useEffect(() => {
    trimMessages()
    localStorage.setItem('chatMessages', JSON.stringify(messages))
    scrollToBottom()
  }, [messages])

  useEffect(() => {
    localStorage.setItem('aiView', JSON.stringify(aiView))
  }, [aiView])

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  const trimMessages = () => {
    if (messages.length > 20) {
      const trimmedMessages = messages.slice(-20)
      setMessages(trimmedMessages)
      localStorage.setItem('chatMessages', JSON.stringify(trimmedMessages))
    }
  }

  const fetchMessages = async () => {
    setIsLoading(true)
    const newMessage = { text: input, isUser: true }

    const spinnerMessage = {
      text: 'Loading...',
      isUser: false,
      isSpinner: true,
    }

    setMessages(prev => [...prev, newMessage, spinnerMessage])

    try {
      const response = await http.post('/api/interact-with-assistant', {
        message: input,
      })
      const botMessageText =
        (filters.length > 0 ? 'Updating screen: ' : 'Starting new screen: ') +
        (response.data?.messages?.[0]?.text || 'No response')

      const botMessage = {
        text: botMessageText,
        isUser: false,
      }

      // Strip the backticks and 'json' tags
      const messageData =
        response?.data?.messages?.[0]?.text?.replace(/```json|```/g, '') || '[]'
      let parsedMessages

      try {
        parsedMessages = JSON.parse(messageData)
      } catch (error) {
        console.error('Error parsing JSON:', error)
        parsedMessages = []
      }

      if (!Array.isArray(parsedMessages)) {
        parsedMessages = [parsedMessages]
      }

      const validFilters = parsedMessages.every(filter => filter.column)
      if (!validFilters) {
        setMessages(prev => {
          const updatedMessages = prev.filter(msg => !msg.isSpinner)
          return [
            ...updatedMessages,
            {
              text: 'Error getting screener filters. Please provide screener queries only.',
              isUser: false,
            },
          ]
        })
      } else {
        setMessages(prev => {
          const updatedMessages = prev.filter(msg => !msg.isSpinner)
          return [...updatedMessages, botMessage]
        })

        trimMessages()

        if (addToFilters) {
          const newFilters = filters.concat(parsedMessages)
          setFilters(newFilters)
        } else {
          setFilters(parsedMessages)
        }
      }

      setInput('')
    } catch (error) {
      console.error('Error fetching messages:', error)
      setMessages(prev => prev.filter(msg => !msg.isSpinner))
    } finally {
      setIsLoading(false)
    }
  }

  const handleInputChange = event => setInput(event.target.value)

  const handleSend = event => {
    event.preventDefault()
    if (input.trim()) {
      fetchMessages()
    }
  }

  const handleClearMessages = () => {
    setMessages([])
    setFilters([])
    localStorage.removeItem('chatMessages')
  }

  const options = [
    { label: 'Add to filters', value: true },
    { label: 'New screen on every prompt', value: false },
  ]

  return (
    <div className={styles.chatContainer}>
      <CoreButton
        onClick={() => setAiView(false)}
        className={styles.closeButton}
        width="100%"
      >
        Go back to manual filters
      </CoreButton>
      <Switch
        options={options}
        selectedOption={addToFilters}
        onOptionChange={setAddToFilters}
        center
      />
      <div className={styles.messagesContainer}>
        {messages?.map((msg, index) => {
          if (msg.isSpinner) {
            return (
              <div
                key={index}
                className={`${styles.message} ${styles.botMessage}`}
              >
                <Spinner size={20} />
              </div>
            )
          }
          if (!msg.isUser) {
            const [plainText, jsonText] = msg.text.split('```json')

            let filters = []
            try {
              if (jsonText) {
                filters = JSON.parse(jsonText.replace(/```/g, ''))
                if (!Array.isArray(filters)) {
                  filters = []
                }
              }
            } catch (e) {
              console.error('Error parsing JSON:', e)
              filters = []
            }

            const filterElements = filters.map((filter, index) => (
              <div key={index} className={styles.filter}>
                {displayFilters(filter.type, filter)}
              </div>
            ))

            const text = (
              <div>
                <div>{plainText}</div>
                {filterElements}
              </div>
            )

            return <Message key={index} text={text} isUser={msg.isUser} />
          }
          return <Message key={index} text={msg.text} isUser={msg.isUser} />
        })}
        <div ref={messagesEndRef} />
      </div>
      <form
        onSubmit={handleSend}
        className={styles.messageForm}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            e.preventDefault()
            handleSend(e)
          }
        }}
      >
        <input
          value={input}
          onChange={handleInputChange}
          className={styles.textarea}
          placeholder="Message Outseek Screener"
        />
        <button
          type="submit"
          disabled={isLoading}
          className={styles.sendButton}
        >
          <UilArrowUp size={20} color="var(--light-grey-accent)" />
        </button>
        <button
          type="button"
          className={styles.sendButton}
          onClick={handleClearMessages}
        >
          <UilTimes size={20} color="var(--light-grey-accent)" />
        </button>
      </form>
    </div>
  )
}

export default Chat
