export const CHARTCOLORS2 = [
  '#E970EC',
  '#9870EC',
  '#708BEC',
  '#70CEEC',
  '#73EC70',
  '#D3EC70',
  '#ECB370',
  '#EC7070',
]

export const CHARTCOLORS = [
  '#C16868',
  '#C17D68',
  '#C19868',
  '#C0C168',
  '#9AC168',
  '#6FC168',
  '#68C1A1',
  '#688CC1',
  '#6A68C1',
  '#9A68C1',
  '#C168BE',
]

export const RANDOMCHARTCOLORS = [
  '#C17D68',
  '#6A68C1',
  '#C0C168',
  '#9AC168',
  '#C168BE',
  '#6FC168',
  '#688CC1',
  '#C16868',
  '#C19868',
  '#9A68C1',
  '#C0C168',
  '#68C1A1',
]

let usedColors = []

export const getRandomColor = () => {
  let availableColors = CHARTCOLORS.filter(color => !usedColors.includes(color))
  let color

  if (availableColors.length > 0) {
    color = availableColors[Math.floor(Math.random() * availableColors.length)]
  } else {
    color = CHARTCOLORS[Math.floor(Math.random() * CHARTCOLORS.length)]
    usedColors = []
  }

  usedColors.push(color)
  return color
}
