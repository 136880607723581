import React, { useEffect, useRef, useState } from 'react'
import numeral from 'numeral'
import styles from './scaleDiff.module.scss'

export const ScaleDiff = ({ scaleData, symbol }) => {
  const scaleRef = useRef(null)
  const [containerWidth, setContainerWidth] = useState(0)
  const [markers, setMarkers] = useState([])
  const [hovered, setHovered] = useState(null)

  const resizeObserver = useRef(null)

  const minValue = scaleData.min
  const maxValue = scaleData.max
  const avgValue = scaleData.avg
  const companyValue = scaleData.company

  const height = 70
  const extraSpace = 60

  const separationThreshold = 6

  useEffect(() => {
    const handleResize = entries => {
      for (let entry of entries) {
        setContainerWidth(entry.contentRect.width)
      }
    }

    resizeObserver.current = new ResizeObserver(handleResize)

    if (scaleRef.current) {
      resizeObserver.current.observe(scaleRef.current)
    }

    return () => {
      if (resizeObserver.current && scaleRef.current) {
        resizeObserver.current.unobserve(scaleRef.current)
      }
    }
  }, [])

  const applyTransition = () => {
    const timer = setTimeout(() => {
      document.querySelectorAll(`.${styles.textMarker}`).forEach(el => {
        el.style.opacity = '1'
      })

      document.querySelectorAll(`.${styles.lineMarker}`).forEach(el => {
        el.style.strokeDasharray = '20, 0'
      })
    }, 0)

    return () => {
      clearTimeout(timer)
    }
  }

  useEffect(() => {
    applyTransition()
  }, [markers, avgValue])

  useEffect(() => {
    const width = containerWidth - extraSpace * 2
    const scale = value =>
      ((value - minValue) / (maxValue - minValue)) * width + extraSpace

    let markerData = [
      {
        value: avgValue,
        scaledValue: scale(avgValue),
        label: `Avg: ${numeral(avgValue).format('0.00a')}`,
        color: '#7c53db',
        yPosition: height / 2 - 20,
        labelXPosition: scale(avgValue),
      },
      {
        value: companyValue,
        scaledValue: scale(companyValue),
        label: `${symbol}: ${numeral(companyValue).format('0.00a')}`,
        color: 'var(--primary-color)',
        yPosition: height / 2 + 28,
        labelXPosition: scale(companyValue),
      },
    ]

    const distanceBetweenMarkers = Math.abs(
      markerData[0].scaledValue - markerData[1].scaledValue
    )

    if (distanceBetweenMarkers < separationThreshold) {
      const adjustment = (separationThreshold - distanceBetweenMarkers) / 2
      markerData[0].scaledValue -= adjustment
      markerData[1].scaledValue += adjustment
    }

    setMarkers(markerData)
  }, [avgValue, companyValue, containerWidth, maxValue, minValue, symbol])

  const handleHover = value => {
    setHovered(value)

    const reorderedMarkers = markers.filter(marker => marker.value !== value)
    const hoveredMarker = markers.find(marker => marker.value === value)

    if (hoveredMarker) {
      reorderedMarkers.push(hoveredMarker)
      setMarkers(reorderedMarkers)
    }
  }

  return (
    <div
      ref={scaleRef}
      className={styles.scaleWrapper}
      style={{ height: height }}
    >
      <svg width="100%" height={height}>
        <rect
          x={extraSpace}
          y={height / 2 - 10}
          width={containerWidth - extraSpace * 2}
          height={20}
          rx={4}
          ry={4}
          className={styles.rectScale}
        />
        {markers.map((marker, i) => (
          <React.Fragment key={i}>
            {/* <rect
              x={marker.labelXPosition - labelWidths[i] / 2 - 4}
              y={height / 2 - 30}
              width={labelWidths[i] + 8}
              height={20}
              fill={'black'}
              fillOpacity={0.8}
              stroke="var(--off-white)"
              strokeWidth={1}
              rx={4}
              ry={4}
              key={`${i}-rect`}
            /> */}
            <text
              key={`${i}-text`}
              x={marker.labelXPosition}
              y={marker.yPosition}
              textAnchor="middle"
              fill={marker.color}
              className={styles.textMarker}
              onMouseOver={() => handleHover(marker.value)}
            >
              {marker.label}
            </text>
            <line
              key={`${i}-line`}
              x1={marker.scaledValue}
              y1={height / 2 - 10}
              x2={marker.scaledValue}
              y2={height / 2 + 10}
              stroke={marker.color}
              strokeWidth={4}
              className={styles.lineMarker}
              onMouseOver={() => handleHover(marker.value)}
            />
          </React.Fragment>
        ))}
        <text
          x={extraSpace - 8}
          y={height / 2 + 4}
          textAnchor="end"
          fill="var(--off-white)"
        >
          {numeral(minValue).format('0.0a')}
        </text>
        <text
          x={containerWidth - extraSpace + 8}
          y={height / 2 + 4}
          textAnchor="start"
          fill="var(--off-white)"
        >
          {numeral(maxValue).format('0.0a')}
        </text>
      </svg>
    </div>
  )
}
