import React from 'react'
import '../styles.scss'
import Select from 'react-select'
import { getSelectStyles } from 'utils/getSelectStyles'
import ComparsionChartPill from './ComparisonChartPill'
import { MetricCatalog } from './MetricCatalog'
import { Switch } from 'core/Switch/Switch'
import { axisOptions, labelOptions, dataFrequencyOptions } from '../constants'

export const ToolBar = ({
  lineOptions,
  comparisonChartCallbacks,
  chartState,
  isDropdownVisible,
  setIsDropdownVisible,
}) => {
  const {
    metrics,
    lineType,
    frequency,
    chartTypes,
    showLabels,
    showSingleAxis,
  } = chartState

  const {
    handleToggleSingleAxis,
    handleSetLineType,
    handleChartTypeChange,
    handleDeleteMetric,
    handleLabelToggle,
    handleDataFrequencyChange,
  } = comparisonChartCallbacks

  return (
    <>
      <div className="charts-tool-bar-wrapper">
        <MetricCatalog
          {...{
            comparisonChartCallbacks,
            selectedMetrics: metrics,
            isDropdownVisible,
            setIsDropdownVisible,
          }}
        />
        <div className="charts-tool-bar-wrapper-content">
          <div className="charts-tool-bar-item">
            {/* <div
            className="checkbox-container"
            onClick={handleTogglePercentageChanged}
          >
            <input
              type="checkbox"
              className="checkbox"
              checked={chartState.showPercentageChange}
            />
            <div>Percentage Changed</div>
          </div> */}
            <Select
              options={lineOptions}
              value={lineType}
              onChange={handleSetLineType}
              styles={getSelectStyles(`160px`)}
              isSearchable={false}
            />
          </div>
          <div className="charts-tool-bar-item">
            <Switch
              options={axisOptions}
              selectedOption={showSingleAxis}
              onOptionChange={handleToggleSingleAxis}
            />
            <Switch
              options={labelOptions}
              selectedOption={showLabels}
              onOptionChange={handleLabelToggle}
            />
            <Switch
              options={dataFrequencyOptions}
              selectedOption={frequency}
              onOptionChange={handleDataFrequencyChange}
            />
          </div>
        </div>
      </div>
      <div className="comparison-chart-metrics">
        {chartState.metrics.map((field, i) => {
          return (
            <ComparsionChartPill
              key={field.APIProperty}
              label={field.APIProperty}
              onDelete={() => handleDeleteMetric(field)}
              color={field.color}
              metricChartTypes={chartTypes}
              handleChartTypeChange={handleChartTypeChange}
            />
          )
        })}
      </div>
    </>
  )
}
