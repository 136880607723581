import widgetConfig from '../../constants/widgetConstants'
import BitesizeComponent from '../BitesizeComponent/BitesizeComponent'
const WidgetBlock = ({ root, ticker, type, data, width, height, title }) => {
  const config = widgetConfig[title]

  if (config) {
    if (config.type === 'link') {
      return
    } else if (config.type === 'range') {
      return
    } else {
      return <BitesizeComponent {...config} label={title} ticker={ticker} />
    }
  }
}

export default WidgetBlock
