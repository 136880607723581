import React, { useCallback, useState, useEffect } from 'react'
import { QuoteBar } from 'core/QuoteBar/QuoteBar'
import styles from './styles.module.scss'
import { CardWrapper } from 'core/CardWrapper/CardWrapper'
import { useDataSource } from './data/hooks'
import { NoData } from 'core/NoData/NoData'
import TimeRange from '../../NewsView/component/TimeRange'
import { TickerOverlayChart } from 'core/TickerOverlay/TickerOverlayChart'
import numeral from 'numeral'
import { ETFIntervals } from './constants'
import { BasicTable } from 'core/Tables/BasicTable'
import DonutChart from '../../InstitutionalOwnership/components/DthreePieChart/DonutChart'
import { industryColumns, countryColumns } from './columns'
import Spinner from 'core/ScrollableContainer/Spinner/spinner'
import { TreeMap } from './components/Treemap/Treemap'
import { ETFInfo } from './components/ETFinfo'
import { Helmet } from 'react-helmet-async'
import { Switch } from 'core/Switch/Switch'
import { NotETF } from './components/NotETF/NotETF'

export const ETF = () => {
  const { ETF_UI, handleSetTicker, interval, setInterval, ticker } =
    useDataSource()
  const [donutChart, setDonutChart] = useState(true)

  useEffect(() => {
    setDonutChart(ETF_UI?.shouldBeTreeMap)
  }, [ETF_UI?.shouldBeTreeMap])

  const [hoveredIndustry, setHoveredIndustry] = useState(null)
  const [hoveredCountry, setHoveredCountry] = useState(null)

  const handleIndustryHover = rowData => {
    setHoveredIndustry(rowData.label)
    setHoveredCountry(rowData.label)
  }

  const handleIndustryLeave = () => {
    setHoveredIndustry(null)
    setHoveredCountry(null)
  }

  const isETF = ETF_UI?.outlookData?.profile?.isEtf

  const handleTimeRangeChange = useCallback(
    newInterval => {
      setInterval(newInterval)
    },
    [setInterval]
  )

  const handleSetLabel = useCallback(
    newLabel => {
      setDonutChart(newLabel)
    },
    [setDonutChart]
  )

  const labelOptions = [
    { label: 'Donut Chart', value: true },
    { label: 'TreeMap', value: false },
  ]

  if (isETF !== true) {
    return <NotETF ticker={ticker} handleSetTicker={handleSetTicker} />
  }

  return (
    <div className={styles.etfWrapper}>
      <Helmet title={`Outseek | ${ticker} (ETF) Overview`} />
      <QuoteBar
        updateTicker={tickerIn => handleSetTicker(tickerIn)}
        ticker={ticker}
      />
      <div className={styles.etfViewWrapper}>
        <CardWrapper
          label={`${ticker} ETF Info`}
          height={'200px'}
          width={'100%'}
          content={<ETFInfo data={ETF_UI?.ETFInfo?.[0]} />}
        />
        <div className={styles.visualizations}>
          <div className={styles.topWrapper}>
            <CardWrapper
              label={'Price Chart'}
              height={'calc(50% - 4px)'}
              width={'100%'}
              content={
                <div className={styles.chartTimeWrapper}>
                  <div className={styles.timeRange}>
                    <TimeRange
                      intervals={ETFIntervals}
                      onChange={handleTimeRangeChange}
                      interval={interval}
                      fitContent
                      gap={'8px'}
                      background={'transparent'}
                    />
                  </div>
                  {ETF_UI?.historicalDataIsLoading ? (
                    <Spinner />
                  ) : (
                    <TickerOverlayChart
                      ticker={ticker}
                      data={ETF_UI.historicalData}
                      xAxisKey={'date'}
                      view={true}
                      interval={interval}
                      barDataKey={'volume'}
                      lineDataKey={'close'}
                      YTickFormat={tick => numeral(tick).format('0.00a')}
                      barDomain={[0, dataMax => dataMax * 4.5]}
                    />
                  )}
                </div>
              }
            />
            <CardWrapper
              label={`${ticker} Country Weightings`}
              height={'calc(50% - 4px)'}
              width={'100%'}
              noPadding={true}
              endButton={
                <Switch
                  options={labelOptions}
                  selectedOption={donutChart}
                  onOptionChange={handleSetLabel}
                  forCardWrapper={true}
                />
              }
              content={
                ETF_UI?.ETFCountryWeightingIsLoading ? (
                  <Spinner />
                ) : ETF_UI?.ETFCountryWeighting?.length > 0 ? (
                  <div className={styles.industryChartWrapper}>
                    {donutChart ? (
                      <DonutChart
                        data={ETF_UI?.countryWeightingsTable}
                        hoveredSymbol={hoveredCountry}
                        colorStart={'#398049'}
                        colorEnd={'#6f387d'}
                        showValue={false}
                        scaleIn={false}
                        noLabel={true}
                        normalPie={true}
                        setHoveredSymbol={setHoveredCountry}
                        paddings={0}
                      />
                    ) : (
                      <TreeMap
                        data={ETF_UI?.countryWeightings}
                        percentage={true}
                        startColor={'#4079a8'}
                        endColor={'#a37236'}
                        // whiteLabel={true}
                        setHoveredSymbol={setHoveredCountry}
                        hoveredSymbol={hoveredCountry}
                      />
                    )}
                    <BasicTable
                      overflowXScroll
                      data={ETF_UI?.countryWeightingsTable}
                      columns={countryColumns}
                      onMouseOverRow={handleIndustryHover}
                      onMouseLeaveRow={handleIndustryLeave}
                      hoveredSymbol={hoveredCountry}
                      hoverKey="label"
                      haveKey={true}
                    />
                  </div>
                ) : (
                  <NoData label="No Data Available" />
                )
              }
            />
          </div>
          <div className={styles.bottomWrapper}>
            <CardWrapper
              label={`${ticker} Industry Weightings`}
              height={'100%'}
              width={'100%'}
              content={
                ETF_UI?.ETFInfoIsLoading ? (
                  <Spinner />
                ) : ETF_UI?.ETFInfo?.length > 0 ? (
                  <div className={styles.industryChartWrapper}>
                    <div className={styles.chartWrapper}>
                      <TreeMap
                        data={ETF_UI?.industryWeightings}
                        percentage={true}
                        // startColor={'#863871'}
                        startColor={'#4079a8'}
                        endColor={'#a37236'}
                        // whiteLabel={true}
                        setHoveredSymbol={setHoveredIndustry}
                        hoveredSymbol={hoveredIndustry}
                      />
                    </div>
                    <div className={styles.tableWrapper}>
                      <BasicTable
                        overflowXScroll
                        data={ETF_UI?.industryWeightingsTable}
                        columns={industryColumns}
                        onMouseOverRow={handleIndustryHover}
                        onMouseLeaveRow={handleIndustryLeave}
                        hoveredSymbol={hoveredIndustry}
                        hoverKey="label"
                        haveKey={true}
                      />
                    </div>
                  </div>
                ) : (
                  <NoData label="No Data Available" />
                )
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}
