import React, { useState, useCallback, useMemo } from 'react'
import { useNewsDataSource } from '../hooks/useDataSouce'
import BasicTable from '../../../../core/Tables/BasicTable'
import { sentimentTrendingColumns } from '../columns'
import Spinner from '../../../../core/ScrollableContainer/Spinner/spinner.jsx'
import styles from './TrendingNewsView.module.scss'
import stocktwitslogo from '../assets/stocktwitslogo.webp'
import sidebarClosed from '../assets/sidebarClosed.svg'
import sidebarOpen from '../assets/sidebarOpen.svg'
import { NewsTable } from '../component/NewsTable/NewsTable'
import TooltipTicker from 'core/TickerOverlay/TickerOverlay'
import { CardWrapper } from 'core/CardWrapper/CardWrapper'
import { Helmet } from 'react-helmet-async'

export const TrendingNewsView = () => {
  const { TrendingNews_UI, trendingSocial_UI } = useNewsDataSource()

  const [expandSideBar, setExpandSideBar] = useState(false)

  const [swapNewsFormat, setSwapNewsFormat] = useState(true)

  const handleToggleNewsFormat = useCallback(() => {
    setSwapNewsFormat(prev => !prev)
  }, [])

  const handleToggleExpandSideBar = useCallback(() => {
    setExpandSideBar(prev => !prev)
  }, [])

  const stocktwitsBullishTrendingData = useMemo(() => {
    return trendingSocial_UI.trend
      ? trendingSocial_UI.trend.find(
          widget => widget.source === 'stocktwits' && widget.trend === 'bullish'
        )
      : null
  }, [trendingSocial_UI])

  return (
    <div className={styles.trendingNewsWrapper}>
      <Helmet>
        <title>{`Outseek | Financial News`}</title>
        <meta
          name="description"
          content={
            'This page gives the user recent news articles along with some social media stats for a given company'
          }
        />
      </Helmet>
      <CardWrapper
        label="Trending Market News"
        width={expandSideBar ? 'calc(100% - 90px)' : 'calc(100% - 218px)'}
        height="100%"
        content={
          !TrendingNews_UI ? (
            <Spinner />
          ) : (
            <NewsTable
              data={TrendingNews_UI}
              height={'100%'}
              swap={swapNewsFormat}
              showSymbol={true}
              toggleSwap={handleToggleNewsFormat}
            />
          )
        }
      />
      <CardWrapper
        label="StockTwits Trending"
        width={expandSideBar ? '82px' : '210px'}
        height="100%"
        expand={expandSideBar}
        expandable={true}
        toggleExpand={handleToggleExpandSideBar}
        content={
          expandSideBar ? (
            <div className={styles.rightSideWrapperClosed}>
              <img
                src={stocktwitslogo}
                className={styles.stocktwitsLogo}
                alt="stocktwits Trending"
              />
              <div className={styles.stocktwitsTrendingWrapper}>
                {stocktwitsBullishTrendingData.data
                  .reverse()
                  .map((item, index) => {
                    return (
                      <div key={index} className={styles.trendingNewsRightRow}>
                        <TooltipTicker ticker={item.symbol} />
                      </div>
                    )
                  })}
              </div>
            </div>
          ) : (
            <div className={styles.rightSideWrapper}>
              {!trendingSocial_UI.loading && stocktwitsBullishTrendingData && (
                <BasicTable
                  overflowXScroll
                  columns={sentimentTrendingColumns}
                  data={stocktwitsBullishTrendingData.data.reverse()}
                />
              )}
            </div>
          )
        }
      />
    </div>
  )
}

export default React.memo(TrendingNewsView)
