import numeral from 'numeral'
import moment from 'moment'
import Fuse from 'fuse.js'
import { useQuery } from 'react-query'
import http from '../../../../../network/httpClient'
import { reduceData } from '../../../CompanyOverview/dataSource/queries'
import { useState, useMemo } from 'react'
import { OverviewIntervals } from './utils'

const fetchIndexQuotes = async () => {
  const data = await http.get('papi/data/api/v3/quotes/index?')
  return data.data.message
}

const fetchIndexHistory = async index => {
  const data = await http.get('papi/data/api/v3/historical-price-full/' + index)
  return data.data.message.historical.reverse()
}

export const fetchPriceData = async ({ queryKey }) => {
  const [, ticker, { label, from, to }] = queryKey
  let data

  const time = label => {
    if (label === '5D') {
      return '15min'
    } else if (label === '1D') {
      return '1min'
    } else if (label === '1M') {
      return '30min'
    } else if (label === '3M') {
      return '1hour'
    }
  }

  switch (label) {
    case '1D':
    case '5D':
    case '1M':
    case '3M':
      data = await http.get(
        `papi/data/api/v3/historical-chart/${time(label)}/${ticker}`
      )
      data = data.data.message
      break
    default:
      let startDate = from ? moment(from).format('YYYY-MM-DD') : null
      let endDate = to ? moment(to).format('YYYY-MM-DD') : null
      data = await http.get(
        `papi/data/api/v3/historical-price-full/${ticker}?from=${startDate}&to=${endDate}`
      )
      data = data.data.message.historical
  }

  let startDate = from ? moment(from) : null
  let endDate = to ? moment(to) : null
  let historicalData = data.filter(item => {
    let date = moment(item.date)
    return (
      (!startDate || date.isAfter(startDate)) &&
      (!endDate || date.isBefore(endDate))
    )
  })

  if (from && moment(to).diff(moment(from), 'years') > 10) {
    historicalData = reduceData(historicalData, from, false, true)
  } else if (from && moment(to).diff(moment(from), 'years') >= 3) {
    historicalData = reduceData(historicalData, from, true, false)
  }

  return historicalData.reverse()
}

export const useDataSource = () => {
  const indexList = [
    '^GSPC', // S&P 500
    '^DJI', // Dow Jones Industrial Average
    '^IXIC', // NASDAQ Composite
    '^GSPTSE', // S&P/TSX Composite
    '^RUT', // Russell 2000
    '^FTSE', // FTSE 100
    // '^FCHI', // CAC 40
    // '^GDAXI', // DAX
    // '^STOXX50E', // Euro Stoxx 50
    '^N225', // Nikkei 225
    '^HSI', // Hang Seng
    // '^BSESN', // SENSEX
    // '^AXJO', // ASX 200
    // '^BVSP', // IBOVESPA
    // '^TASI.SR', // Tadawul All Share Index
  ]

  const [selectedIndex, setSelectedIndex] = useState(indexList[0])
  const [interval, setInterval] = useState(OverviewIntervals[2])
  //   const [interval, setInterval] = useState({ from: null, to: null })

  const {
    data: indexData,
    isLoading: indexIsLoading,
    isError: indexIsError,
  } = useQuery('fetchIndexQuotes', fetchIndexQuotes, {
    retry: true,
    retryDelay: attemptIndex => Math.min(1000 * 2 ** attemptIndex, 30000),
  })

  // const { data, isLoading, isError } = useQuery(
  //   ['fetchIndexHistorical', selectedIndex],
  //   () => fetchIndexHistory(selectedIndex),
  //   {
  //     retry: true,
  //     retryDelay: attemptIndex => Math.min(1000 * 2 ** attemptIndex, 30000),
  //   }
  // )

  const { data, isLoading, isError } = useQuery(
    ['fetchIndexHistorical', selectedIndex, interval],
    fetchPriceData,
    {
      retry: true,
      retryDelay: attemptIndex => Math.min(1000 * 2 ** attemptIndex, 30000),
    }
  )

  const Index_UI = useMemo(() => {
    return {
      indexData,
      indexIsLoading,
      indexIsError,
      historicalData: data,
      historicalDataIsLoading: isLoading,
      historicalDataIsError: isError,
    }
  }, [data, indexData, indexIsError, indexIsLoading, isError, isLoading])

  return {
    Index_UI,
    indexList,
    selectedIndex,
    setSelectedIndex,
    interval,
    setInterval,
  }
}
