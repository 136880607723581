import React, { useState, useEffect } from 'react'

export const Clock = () => {
  const [currentTime, setCurrentTime] = useState(new Date())

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date())
    }, 1000)

    return () => clearInterval(timer)
  }, [])

  const timeZoneOptions = {
    hour: 'numeric',
    minute: '2-digit',
    second: '2-digit',
    timeZoneName: 'short',
    hour12: true,
  }

  const formatTimeWithLowercaseMeridiem = date => {
    return date
      .toLocaleTimeString(undefined, timeZoneOptions)
      .replace('AM', 'am')
      .replace('PM', 'pm')
  }

  return <span>{formatTimeWithLowercaseMeridiem(currentTime)}</span>
}
