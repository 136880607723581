import { useState, useEffect } from 'react'
import Modal from '@mui/material/Modal'
import CoreButton from 'core/Button/CoreButton'
import styles from './WatchlistInformationModal.module.scss'
import times from '../../assets/times.svg'
import Box from '@mui/material/Box'

const WatchlistInformationModal = ({
  isOpen,
  handleClose,
  watchlistInfo,
  updateWatchlistInfo,
  watchlists,
}) => {
  const [watchlistName, setWatchlistName] = useState(watchlistInfo.name)
  const [watchlistDescription, setWatchlistDescription] = useState(
    watchlistInfo.description
  )
  const [createWatchlistError, setCreateWatchlistError] = useState(false)

  const handleSaveChanges = () => {
    if (
      watchlistName?.trim()?.length < 2 ||
      watchlistName?.trim()?.length > 25
    ) {
      setCreateWatchlistError(
        'Name must be greater than 2 and less than 25 characters.'
      )
      return
    }

    if (watchlists.some(watchlist => watchlist.name === watchlistName.trim())) {
      setCreateWatchlistError('Watchlist name already exists.')
      return
    }

    updateWatchlistInfo({
      name: watchlistName,
      description: watchlistDescription,
    })
    handleClose()
  }

  return (
    <Modal open={isOpen} onClose={handleClose} disableAutoFocus={true}>
      <Box
        sx={{
          '&:focus': {
            outline: 'none',
          },
        }}
      >
        <div className={styles.watchlistInfoModal}>
          <div className={styles.watchlistInfoModalHeader}>
            <div>Edit Watchlist Information</div>
            <img
              src={times}
              alt="close"
              className={styles.closeModal}
              onClick={handleClose}
            />
          </div>
          <input
            value={watchlistName}
            onChange={e => {
              setWatchlistName(e.target.value)
              setCreateWatchlistError(false)
            }}
            placeholder={watchlistInfo.name}
            className={styles.watchlistInputTitle}
            style={
              createWatchlistError ? { border: '2px solid var(--red)' } : null
            }
          />
          {createWatchlistError && (
            <div className={styles.watchlistError}>{createWatchlistError}</div>
          )}
          <textarea
            value={watchlistDescription}
            onChange={e => setWatchlistDescription(e.target.value)}
            placeholder={
              watchlistInfo.description?.trim()?.length === 0
                ? 'Description (optional)'
                : watchlistInfo.description
            }
            className={styles.watchlistInputDesc}
            style={{ resize: 'none' }}
          />
          <div className={styles.modalConfirmButton}>
            <CoreButton
              color={'var(--primary-color)'}
              onClick={handleSaveChanges}
            >
              Save Changes
            </CoreButton>
          </div>
        </div>
      </Box>
    </Modal>
  )
}

export default WatchlistInformationModal
