import React, { useState } from 'react'
import { displayFilters } from './utils'
import { UilAngleRight, UilAngleDown, UilTimes } from '@iconscout/react-unicons'
import CoreButton from 'core/Button/CoreButton'
import styles from './styles.module.scss'
import { NoData } from 'core/NoData/NoData'

export const AvailableScreens = ({
  screeners,
  deleteAScreener,
  onScreenSelected,
  setOpen,
}) => {
  const [openAccordionId, setOpenAccordionId] = useState(null)

  const toggleAccordion = id => {
    if (openAccordionId === id) {
      setOpenAccordionId(null)
    } else {
      setOpenAccordionId(id)
    }
  }

  const selectScreen = screener => {
    onScreenSelected(screener)
  }

  const detailsButtonProps = screener => {
    return {
      onClick: () => toggleAccordion(screener.screenerId),
      style: { cursor: 'pointer' },
      size: 24,
    }
  }

  return (
    <div className={styles.createScreenWrapper}>
      <div className={styles.modalTitle}>
        <div className={styles.title}>Saved Screens</div>
        <div className={styles.closeButton}>
          <UilTimes
            size={24}
            style={{ cursor: 'pointer' }}
            onClick={() => setOpen(false)}
          />
        </div>
      </div>
      {screeners?.length === 0 ? (
        <NoData label="No Saved Screens" />
      ) : (
        screeners.map(screener => (
          <div key={screener.id} className={styles.screener}>
            <div className={styles.screen}>
              <div className={styles.nameDetails}>
                {openAccordionId === screener.screenerId ? (
                  <UilAngleDown {...detailsButtonProps(screener)} />
                ) : (
                  <UilAngleRight {...detailsButtonProps(screener)} />
                )}
                <div className={styles.title}>{screener.name}</div>
              </div>
              <div className={styles.utilsButtons}>
                <CoreButton
                  onClick={() => selectScreen(screener)}
                  color="var(--primary-color)"
                >
                  Select Screen
                </CoreButton>
                <CoreButton
                  onClick={e => {
                    e.stopPropagation()
                    deleteAScreener(screener.screenerId)
                  }}
                  color="var(--red)"
                >
                  Delete
                </CoreButton>
              </div>
            </div>
            {openAccordionId === screener.screenerId && (
              <div className={styles.detailAccordion}>
                {screener.filters.map(filter => (
                  <div key={filter.id}>
                    <div className={styles.desc}>{screener.description}</div>
                    {displayFilters(filter.type, filter)}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))
      )}
    </div>
  )
}
