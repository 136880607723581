import React from 'react'
import styles from './Tags.module.scss'
import { tagBackgroundPicker } from '../Item/utils'

export const TypeTag = ({ entry, type, longV }) => {
  if (longV === true) {
    return (
      <>
        <div
          className={styles.tagContainer}
          style={{ backgroundColor: `${tagBackgroundPicker(entry?.type)}` }}
          title={entry?.type}
        >
          <img alt="icon" src={entry?.typeIcon}></img>
          <p className={styles.tagText}>{entry?.type} </p>
        </div>
      </>
    )
  } else {
    return (
      <>
        <div
          className={styles.tagContainer}
          style={{ backgroundColor: `${tagBackgroundPicker(entry?.type)}` }}
          title={entry?.type}
        >
          <img alt="icon" src={entry?.typeIcon}></img>
          {/* <p className={styles.tagText}>{entry?.type} </p> */}
        </div>
      </>
    )
  }
}

export const CatagoryTag = ({ entry, type }) => {
  return (
    <>
      <div
        className={styles.tagContainer}
        style={{ backgroundColor: `${tagBackgroundPicker(entry?.type)}` }}
      >
        <img alt="icon" src={entry?.icon}></img>
        <p className={styles.tagText}>{entry?.category} </p>
      </div>
    </>
  )
}
