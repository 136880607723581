//  put the logic for maintaining dashboard server state
import { useCallback, useMemo } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import http from '../network/httpClient'

const Dashboard_URL = 'papi/Dashboard/'

async function createDashboardFn({ body }) {
  let data = await http.post(`${Dashboard_URL}`, body)

  return data.data.message
}

async function readAllDashboardsFn() {
  let data = await http.get(`${Dashboard_URL}summary`)
  return data.data
}

async function updateDashboardFn({ Dashboard_id, body }) {
  let data = await http.put(`${Dashboard_URL}${Dashboard_id}`, body)

  return data.data
}

async function deleteDashboardsFn({ Dashboard_id }) {
  return http.delete(`${Dashboard_URL}/${Dashboard_id}`)
}

export const useDashboards = () => {
  // Get QueryClient from the context
  const queryClient = useQueryClient()

  const {
    data: readAll,
    isLoading: isReadAllDashboardLoading,
    isError: isReadAllDashboardError,
  } = useQuery([Dashboard_URL], () => readAllDashboardsFn())

  const {
    error: isCreateError,
    isLoading: isCreateDashboardLoading,
    mutate: createDashboard,
  } = useMutation({
    mutationFn: createDashboardFn,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [Dashboard_URL],
      }),
  })

  const { mutate: updateDashboard } = useMutation({
    mutationFn: updateDashboardFn,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [Dashboard_URL],
      }),
  })

  const {
    error: isDeleteError,
    isLoading: isDeleteDashboardLoading,
    mutate: deleteDashboard,
  } = useMutation({
    mutationFn: deleteDashboardsFn,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [Dashboard_URL],
      }),
  })

  const appendTickerToDashboard = useCallback(
    (ticker, DashboardId) => {
      const DashboardToAppendTo =
        readAll?.item?.Items.filter(
          Dashboard => Dashboard?.DashboardId === DashboardId
        )?.[0] || {}

      const existingTickersSet = new Set(DashboardToAppendTo.tickers)

      if (existingTickersSet.has(ticker)) {
        return {
          success: false,
          message: `Symbol ${ticker} already exist in Dashboard.`,
        }
      }

      updateDashboard({
        Dashboard_id: DashboardId,
        body: {
          ...DashboardToAppendTo,
          tickers: [...DashboardToAppendTo.tickers, ticker],
        },
      })

      return {
        success: true,
        message: `The ticker ${ticker} was added to the Dashboard.`,
      }
    },
    [readAll?.item?.Items, updateDashboard]
  )

  const numberOfDashboards = useMemo(() => {
    return readAll?.item?.Items?.length
  }, [readAll])

  const isLoading =
    isCreateDashboardLoading ||
    isDeleteDashboardLoading ||
    isReadAllDashboardLoading

  const isError = isReadAllDashboardError || isCreateError || isDeleteError

  return {
    readAllForDashboard: readAll,
    readAll,
    isLoading,
    isError,
    createDashboard,
    deleteDashboard,
    updateDashboard,
    appendTickerToDashboard,
    numberOfDashboards,
  }
}

export default useDashboards
