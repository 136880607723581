import { RedoOutlined } from '@material-ui/icons'

export const StringSelect = {
  control: () => ({
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'row',
    width: '250px',
    fontSize: 'var(--default-text)',
    whiteSpace: 'nowrap',
    color: 'var(--white)',
    cursor: 'pointer',
  }),
  singleValue: () => ({
    gridArea: '1/1/2/3',
    marginLeft: '1px',
    marginRight: '1px',
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    fontSize: 'var(--default-text)',
    color: 'var(--white)',
  }),
  indicatorSeparator: () => ({
    backgroundColor: 'var(--off-white)',
    alignSelf: 'stretch',
    marginBottom: '8px',
    marginTop: '8px',
    width: '1px',
    boxSizing: 'border-box',
    fontSize: 'var(--default-text)',
  }),
  menu: () => ({
    top: '100%',
    backgroundColor: 'var(--background-primary)',
    border: '2px solid var(--grey-accent)',
    borderRadius: '4px',
    boxShadow:
      '0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 12px hsl(0deg 0% 0% / 10%)',
    marginBottom: '8px',
    marginTop: '8px',
    position: 'absolute',
    width: '250px',
    zIndex: '1',
    boxSizing: 'border-box',
    fontSize: 'var(--default-text)',
    color: 'var(--white)',
  }),
  MenuList: () => ({
    maxHeight: '300px',
    overflowY: 'auto',
    paddingBottom: '4px',
    paddingTop: '4px',
    position: 'relative',
    boxSizing: 'border-box',
    color: 'var(--white)',
  }),
  option: () => ({
    backgroundColor: 'transparent',
    color: 'var(--white)',
    cursor: 'pointer',
    display: 'block',
    fontSize: 'var(--default-text)',
    padding: '8px 12px',
    width: '',
    boxSizing: 'border-box',
    '&:hover': {
      backgroundColor: 'var(--button-hover)',
    },
  }),
  placeholder: base => ({
    ...base,
    color: 'var(--light-grey-accent)',
  }),
}

export const CustomFilter = active => {
  return {
    control: () => ({
      backgroundColor: 'transparent',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: '140px',
      fontSize: 'var(--default-text)',
      whiteSpace: 'nowrap',
      color: 'var(--white)',
      cursor: 'pointer',
      border: active
        ? '2px solid var(--primary-color)'
        : '1px solid var(--grey-accent)',
      borderRadius: '4px',
      maxHeight: 24,
      zIndex: 1000000,
    }),
    singleValue: () => ({
      gridArea: '1/1/2/3',
      marginLeft: '1px',
      marginRight: '1px',
      maxWidth: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      fontSize: '12px',
      color: 'var(--white)',
    }),
    indicatorSeparator: () => ({
      backgroundColor: 'var(--off-white)',
      alignSelf: 'stretch',
      height: 12,
      width: '1px',
      boxSizing: 'border-box',
      display: 'none',
    }),
    menu: () => ({
      top: '100%',
      backgroundColor: 'var(--background-primary)',
      border: '2px solid var(--grey-accent)',
      borderRadius: '4px',
      boxShadow:
        '0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 12px hsl(0deg 0% 0% / 10%)',
      marginTop: '2px',
      position: 'absolute',
      width: 'fit-content',
      minWidth: '140px',
      zIndex: 1000000,
      boxSizing: 'border-box',
      fontSize: '12px',
      color: 'var(--white)',
    }),
    MenuList: () => ({
      maxHeight: '300px',
      overflowY: 'auto',
      paddingBottom: '4px',
      paddingTop: '4px',
      position: 'relative',
      boxSizing: 'border-box',
      color: 'var(--white)',
    }),
    option: () => ({
      backgroundColor: 'transparent',
      color: 'var(--off-white)',
      cursor: 'pointer',
      display: 'block',
      fontSize: '12px',
      padding: '3px 12px',
      width: '',
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      '&:hover': {
        backgroundColor: 'var(--button-hover)',
      },
    }),
    input: base => ({
      ...base,
      color: 'var(--white)',
      fontSize: '12px',
    }),
    placeholder: base => ({
      ...base,
      color: 'var(--light-grey-accent)',
    }),
  }
}
