import styles from './style.module.scss'
import { useMergers } from '../../data/hooks'
import BasicTable from 'core/Tables/BasicTable'
import { CardWrapper } from 'core/CardWrapper/CardWrapper'
import Spinner from 'core/ScrollableContainer/Spinner/spinner'
import { mergerColumns } from './columns'
import { Helmet } from 'react-helmet-async'

export const Mergers = () => {
  const { CE_UI } = useMergers()

  return (
    <>
      <Helmet>
        <title>{`Outseek | M&A`}</title>
        <meta
          name="description"
          content={
            'This page provides information about mergers and acquisitions.'
          }
        />
      </Helmet>
      <CardWrapper
        label={'Mergers & Acquisitions'}
        height={'100%'}
        width={'100%'}
        content={
          CE_UI?.MAndAIsLoading ? (
            <Spinner />
          ) : (
            <BasicTable
              data={CE_UI.MAndA}
              columns={mergerColumns}
              overflowXScroll={true}
            />
          )
        }
      />
    </>
  )
}
