import moment from 'moment'
import numeral from 'numeral'
import TooltipTicker from '../../../core/TickerOverlay/TickerOverlay'
import styles from './NewsColumns.module.scss'
import dayjs from 'dayjs'
import exchange from './assets/exchange.svg'

export const CompanyNewsColumns = swap => [
  {
    Header: () => (
      <div className={styles.headerWrapper}>
        <div className={styles.swapWrapper}>
          <img
            src={exchange}
            alt="swap"
            title="Swap news table"
            className={styles.swap}
            onClick={swap}
          />
        </div>
        {/* <span>Stock News</span> */}
      </div>
    ),
    accessor: 'title',
    filter: 'search',
    Cell: Cell => {
      return (
        <>
          <div className={styles.NewsWrapper}>
            <a
              href={Cell?.row?.original?.url}
              target="_blank"
              rel="noreferrer"
              className={styles.thingig}
            >
              {Cell.value}
            </a>
            <div
              style={{ color: 'var(--light-grey-accent)' }}
              href={Cell?.row?.original?.url}
              target="_blank"
              rel="noreferrer"
              className={styles.newsTitle1}
            >
              {Cell?.row?.original?.site}
            </div>
          </div>
        </>
      )
    },
  },
  {
    Header: '',
    accessor: 'publishedDate',
    Cell: Cell => {
      const currentDate = dayjs()
      return (
        <div style={{ color: 'var(--light-grey-accent)' }}>
          {dayjs(Cell.value).isSame(currentDate, 'day')
            ? dayjs(Cell.value).format('h:mm a')
            : dayjs(Cell.value).format('MMM DD, YY')}
        </div>
      )
    },
    width: 10,
  },
]

export const CompanyNewsColumnsOld = [
  {
    Header: 'Source',
    accessor: 'site',
    minWidth: 30,
    width: 160,
    maxWidth: 160,
    Cell: Cell => {
      return (
        <a
          href={Cell?.row?.original?.url}
          target="_blank"
          rel="noreferrer"
          className={styles.newsTitle}
        >
          {Cell.value}
        </a>
      )
    },
  },
  {
    Header: 'Title',
    accessor: 'title',
    filter: 'search',
    Cell: Cell => {
      return (
        <a
          href={Cell?.row?.original?.url}
          target="_blank"
          rel="noreferrer"
          title={Cell?.row?.original?.text}
          className={styles.newsTitle}
        >
          {Cell.value}
          <div
            className={styles.newsText}
            href={Cell?.row?.original?.url}
            target="_blank"
            rel="noreferrer"
          >
            {Cell?.row?.original?.text}
          </div>
        </a>
      )
    },
    maxWidth: 840,
    width: 600,
  },
  {
    Header: 'Date',
    accessor: 'publishedDate',
    Cell: Cell => {
      return <div>{moment(Cell.value).format('lll')}</div>
    },
    minWidth: 30,
    width: 150,
    maxWidth: 150,
  },
]

export const MarketNewsColumns1 = [
  {
    Header: 'Symbol',
    accessor: 'symbol',
    minWidth: 30,
    width: 60,
    maxWidth: 60,
    Cell: Cell => {
      return <TooltipTicker ticker={Cell.value} />
    },
  },
  {
    Header: 'Source',
    accessor: 'site',
    minWidth: 30,
    width: 160,
    maxWidth: 160,
    Cell: Cell => {
      return (
        <a
          href={Cell?.row?.original?.url}
          target="_blank"
          rel="noreferrer"
          className={styles.newsTitle}
        >
          {Cell.value}
        </a>
      )
    },
  },
  {
    Header: 'Title',
    accessor: 'title',
    filter: 'search',
    Cell: Cell => {
      return (
        <a
          href={Cell?.row?.original?.url}
          target="_blank"
          rel="noreferrer"
          title={Cell?.row?.original?.text}
          className={styles.newsTitle}
        >
          {Cell.value}
          <div
            className={styles.newsText}
            href={Cell?.row?.original?.url}
            target="_blank"
            rel="noreferrer"
          >
            {Cell?.row?.original?.text}
          </div>
        </a>
      )
    },
    maxWidth: 840,
    width: 640,
  },
  {
    Header: 'Date',
    accessor: 'publishedDate',
    Cell: Cell => {
      return <div>{moment(Cell.value).format('lll')}</div>
    },
    minWidth: 30,
    width: 150,
    maxWidth: 150,
  },
]

export const MarketNewsColumns = [
  {
    Header: 'Symbol',
    accessor: 'symbol',
    width: 60,
    Cell: Cell => {
      return <TooltipTicker ticker={Cell.value} />
    },
  },
  {
    Header: 'Stock News',
    accessor: 'title',
    filter: 'none',
    Cell: Cell => {
      const currentDate = dayjs()
      return (
        <>
          <div className={styles.NewsWrapper}>
            <a
              href={Cell?.row?.original?.url}
              target="_blank"
              rel="noreferrer"
              className={styles.thingig}
            >
              {Cell.value}
            </a>
            <div
              style={{ color: 'var(--light-grey-accent)' }}
              href={Cell?.row?.original?.url}
              target="_blank"
              rel="noreferrer"
              className={styles.newsTitle1}
            >
              {Cell?.row?.original?.site}
            </div>
            <div style={{ color: 'var(--light-grey-accent)' }}>
              {dayjs(Cell?.row?.original?.publishedDate).isSame(
                currentDate,
                'day'
              )
                ? dayjs(Cell?.row?.original?.publishedDate).format('h:mm a')
                : dayjs(Cell?.row?.original?.publishedDate).format(
                    'MMM DD, YY'
                  )}
            </div>
          </div>
        </>
      )
    },
    width: '100%',
  },
  // {
  //   Header: '',
  //   accessor: 'publishedDate',
  //   Cell: Cell => {
  //     const currentDate = dayjs()
  //     return (
  //       <div style={{ color: 'var(--light-grey-accent)' }}>
  //         {dayjs(Cell.value).isSame(currentDate, 'day')
  //           ? dayjs(Cell.value).format('h:mm a')
  //           : dayjs(Cell.value).format('MMM DD, YY')}
  //       </div>
  //     )
  //   },
  //   width: 70,
  // },
]

export const sentimentChangeColumns = [
  {
    Header: 'Symbol',
    accessor: 'symbol',
    Cell: Cell => {
      return <TooltipTicker ticker={Cell.value} />
    },
  },
  {
    Header: 'Sentiment',
    accessor: 'sentiment',
    Cell: Cell => {
      return <div>{numeral(Cell.value).format('0.0')}</div>
    },
  },
  {
    Header: 'Chg.',
    accessor: 'sentimentChange',
    Cell: Cell => {
      return <div>{numeral(Cell.value).format('0')}</div>
    },
  },
]

export const sentimentTrendingColumns = [
  {
    Header: 'Rank',
    accessor: 'rank',
    width: 40,
  },

  {
    Header: 'Symbol',
    accessor: 'symbol',
    width: 60,
    Cell: Cell => {
      return <TooltipTicker ticker={Cell.value} />
    },
  },
  {
    Header: 'Score',
    accessor: 'sentiment',
    width: 60,
    Cell: Cell => {
      return <div>{numeral(Cell.value).format('0.0')}</div>
    },
  },
]

export const TrendingSocialSentimentSmallTableColumns = [
  {
    Header: 'Symbol',
    accessor: 'symbol',
    Cell: Cell => {
      return <TooltipTicker ticker={Cell.value} />
    },
  },
  {
    Header: 'Rank',
    accessor: 'rank',
  },
  {
    Header: 'Title',
    accessor: 'title',
  },
  {
    Header: 'Link',
    accessor: 'url',
    Cell: ({ value }) => <a href={value}>Link</a>,
  },
]

export const ChangeSocialSentimentSmallTableColumns = [
  {
    Header: 'Symbol',
    accessor: 'symbol',
    Cell: Cell => {
      return <TooltipTicker ticker={Cell.value} />
    },
  },

  {
    Header: 'Rank',
    accessor: 'rank',
  },
  {
    Header: 'Sentiment',
    accessor: 'sentiment',
    Cell: Cell => {
      return <div>{numeral(Cell.value).format('0.0')}</div>
    },
  },
  {
    Header: 'Last',
    accessor: 'lastSentiment',
    Cell: Cell => {
      return <div>{numeral(Cell.value).format('0.0')}</div>
    },
  },
]
