import React, {
  memo,
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from 'react'
import dayjs from 'dayjs'
import http from 'network/httpClient'
import { useQuery } from 'react-query'
import styles from './EarningsCalender.module.scss'

import Spinner from 'core/ScrollableContainer/Spinner/spinner'
import TooltipTicker from 'core/TickerOverlay/TickerOverlay'
import numeral from 'numeral'
import { upperCase } from 'lodash'
import {
  UilSun,
  UilMoon,
  UilArrowCircleLeft,
  UilArrowCircleRight,
} from '@iconscout/react-unicons'
import BasicTable from 'core/Tables/BasicTable'

import { earningsColumns } from './columns'

const fetchEarnings = async ({ queryKey }) => {
  const [, { fromDatePast, toDateFuture }] = queryKey
  const response = await http.get(
    `papi/data/api/v3/earning_calendar?from=${fromDatePast}&to=${toDateFuture}`
  )
  return response?.data?.message
}

const EarningsCalendarWidget = () => {
  const currentDay = dayjs().format('YYYY-MM-DD')
  const [selectedDate, setSelectedDate] = useState(currentDay)
  const [selectedEarnings, setSelectedEarnings] = useState(null)
  const [dayShift, setDayShift] = useState(0)

  const currentDate = dayjs(selectedDate).add(dayShift, 'day')
  const fromDatePast = currentDate.subtract(2, 'day').format('YYYY-MM-DD')
  const toDateFuture = currentDate.add(2, 'day').format('YYYY-MM-DD')

  const { data: earnings, isLoading: loading } = useQuery(
    ['earnings', { fromDatePast, toDateFuture }],
    fetchEarnings,
    {
      refetchOnWindowFocus: false,
    }
  )

  useEffect(() => {
    if (earnings && selectedDate) {
      setSelectedEarnings(earnings?.filter(e => e.date === selectedDate))
    }
  }, [earnings, selectedDate])

  const FilteredData = data =>
    data?.filter(
      earning =>
        earning?.revenueEstimated &&
        earning?.epsEstimated &&
        earning?.symbol?.length < 8
    )

  const [sortedColumn, setSortedColumn] = useState(null)
  const [sortOrder, setSortOrder] = useState('none')

  const changeSelectedDate = useCallback((newDate, daysToAdd = null) => {
    if (daysToAdd !== null) {
      setSelectedDate(dayjs(newDate).add(daysToAdd, 'day').format('YYYY-MM-DD'))
    } else {
      setSelectedDate(newDate)
    }
    setDayShift(0)
  }, [])

  const sortedEarnings = useMemo(() => {
    if (!selectedEarnings) return []
    const earningsToSort = [...(selectedEarnings || [])]

    return earningsToSort?.sort((a, b) => {
      if (sortOrder === 'none') {
        return 0
      }

      let valueA = a[sortedColumn]
      let valueB = b[sortedColumn]

      if (typeof valueA === 'string') {
        valueA = valueA?.toLowerCase()
        valueB = valueB?.toLowerCase()
      }

      const comparison = valueA > valueB ? 1 : -1

      return sortOrder === 'asc' ? comparison : -comparison
    })
  }, [selectedEarnings, sortedColumn, sortOrder])

  const setTodayDate = useCallback(() => {
    setSelectedDate(currentDay)
    setDayShift(0)
  }, [currentDay])

  const displayedDate = dayjs(selectedDate)
    .add(dayShift, 'day')
    .format('YYYY-MM-DD')

  const calendarRef = useRef(null)
  const [numDateWrappers, setNumDateWrappers] = useState(5)
  const [calendarWidth, setCalendarWidth] = useState(0)

  const updateSelectedEarnings = useCallback((earningsData, date) => {
    if (earningsData) {
      setSelectedEarnings(earningsData?.filter(e => e.date === date))
    }
  }, [])

  useEffect(() => {
    updateSelectedEarnings(earnings, selectedDate)
  }, [earnings, selectedDate])

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        const newWidth = entry.contentRect.width ?? 0
        setCalendarWidth(newWidth)
      }
    })

    if (calendarRef.current) {
      resizeObserver.observe(calendarRef.current)
    }

    return () => {
      if (calendarRef.current) {
        resizeObserver.unobserve(calendarRef.current)
      }
    }
  }, [loading])

  useEffect(() => {
    const getNumber = () => {
      const width = calendarWidth
      const newNumDateWrappers = Math.floor(width / (70 + 4))
      return newNumDateWrappers
    }
    setNumDateWrappers(getNumber())
  }, [calendarWidth, loading])

  const generateDateSequence = useCallback(() => {
    return Array?.from({ length: numDateWrappers }, (_, i) =>
      dayjs(selectedDate)
        .add(i + dayShift - Math.floor(numDateWrappers / 2), 'day')
        .format('YYYY-MM-DD')
    )
  }, [numDateWrappers, selectedDate, dayShift])

  const dateSequence = generateDateSequence() ?? []

  return (
    <div className={styles.earningsWrapper}>
      {loading ? (
        <Spinner />
      ) : (
        <div className={styles.earningsTableWrapper}>
          <div className={styles.earningsTitleBar}>
            <span className={styles.titleText}>Earnings Calendar</span>
            <div className={styles.todayButton} onClick={setTodayDate}>
              Select Todays Date
            </div>
          </div>
          <div className={styles.calendarWrapper}>
            <div className={styles.arrowShift}>
              <UilArrowCircleLeft
                onClick={() => changeSelectedDate(selectedDate, -5)}
                color="var(--light-grey-accent)"
              />
            </div>
            <div className={styles.calendar} ref={calendarRef}>
              {dateSequence?.map((day, index) => {
                const filteredEarningsForDay = FilteredData(
                  earnings?.filter(earning => earning?.date === day)
                )
                const earningsCount = filteredEarningsForDay.length
                return (
                  <div
                    key={day}
                    className={styles.dateWrapper}
                    style={{
                      border:
                        displayedDate === day
                          ? '1.5px solid var(--primary-color)'
                          : null,
                    }}
                    onClick={() => changeSelectedDate(day, 0)}
                  >
                    <div className={styles.date}>
                      <span className={styles.numberDate}>
                        {dayjs(day).format('MMM D')}
                      </span>
                      <span className={styles.dayOfWeek}>
                        <span>{dayjs(day).format('ddd')}</span>
                        <div className={styles.dot} />
                        <span>{earningsCount}</span>
                      </span>
                    </div>
                  </div>
                )
              })}
            </div>
            <div className={styles.arrowShift}>
              <UilArrowCircleRight
                onClick={() => changeSelectedDate(selectedDate, 5)}
                color="var(--light-grey-accent"
              />
            </div>
          </div>
          {selectedEarnings && (
            <div className={styles.earningsDetails}>
              <div className={styles.contentWrapper}>
                {FilteredData(selectedEarnings)?.length === 0 ? (
                  <div className={styles.noEarningsData}>
                    No Quality Earnings Data Available.
                  </div>
                ) : (
                  <BasicTable
                    columns={earningsColumns}
                    data={FilteredData(sortedEarnings)}
                    overflowXScroll
                  />
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default memo(EarningsCalendarWidget)
