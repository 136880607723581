import { useTheme } from './ThemeContext'
import { startCase } from 'lodash'
import { useEffect } from 'react'
import styles from './hmpg.module.scss'
import { useMediaQuery } from 'react-responsive'

const TabletQuery = () => {
  const isTablet = useMediaQuery({ maxWidth: 1079 })
  return { isTablet }
}

export const HomePageThemesChange = () => {
  const { theme, toggleTheme, allThemes, applyTheme } = useTheme()

  const { isTablet } = TabletQuery()

  useEffect(() => {
    applyTheme(theme)
  }, [theme])

  const changeTheme = newTheme => {
    if (newTheme !== theme) {
      toggleTheme(newTheme)
    }
  }

  const getThemeColors = allThemes => {
    let themeColors = {}

    for (let themeKey in allThemes) {
      themeColors[themeKey] = Object.values(allThemes?.[themeKey])
    }

    return themeColors
  }

  const themeColors = getThemeColors(allThemes)

  const colorsArray = themeColors?.[theme]?.slice(0, 5)

  const numberOfThemes = isTablet ? 6 : 10

  const themes = Object.keys(allThemes)?.slice(0, numberOfThemes)

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleBar}>
        <div className={styles.title}>
          Customize Outseek's interface to make it yours.
        </div>
        <div className={styles.subtitle}>
          Choose a theme that suits your style, or create your own.
        </div>
      </div>
      <div className={styles.colorsWrapper}>
        <div className={styles.themes}>
          {themes?.map(themeKey => {
            const primaryColor = allThemes?.[theme]?.['--primary-color']
            const mainColor = themeColors?.[themeKey]?.[4]

            return (
              <div
                key={themeKey}
                onClick={() => changeTheme(themeKey)}
                style={{
                  border:
                    theme === themeKey
                      ? `2px solid ${primaryColor}`
                      : '2px solid var(--grey-accent)',
                }}
                className={styles.colorOptionWrapper}
              >
                <div>{startCase(themeKey)}</div>
                <div
                  className={styles.square}
                  style={{ background: mainColor }}
                />
              </div>
            )
          })}
        </div>
        <div className={styles.colorsShowCase}>
          {colorsArray?.map((color, index) => (
            <div
              key={index}
              style={{
                background: color,
                border:
                  color === colorsArray?.[0]
                    ? '1px solid rgba(255, 255, 255, 0.12)'
                    : 'none',
              }}
              className={styles.colorOption}
            />
          ))}
        </div>
      </div>
    </div>
  )
}
