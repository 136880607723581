import React from 'react'
import { useNavigate } from 'react-router'
import styles from './successDisplay.module.scss'

export const SuccessDisplay = () => {
  const navigate = useNavigate()

  const redirectToTerminal = () => {
    navigate('/terminal/marketOverview')
  }

  return (
    <div className={styles.paymentSuccessWrapper}>
      <div>
        Subscription successful.
        <br />
        Thank you for your purchase
      </div>
      <button onClick={redirectToTerminal} className={styles.enterTerminal}>
        Enter Terminal
      </button>
    </div>
  )
}
