export const COLUMNS = [
  {
    accessor: 'image',
    Header: '',
    show: false,
    group: [],
  },
  {
    accessor: 'symbol',
    Header: 'Symbol',
    show: true,
    group: [],
  },
  {
    accessor: 'line',
    Header: 'line',
    show: true,
    group: [],
  },
  ///
  {
    accessor: 'companyName',
    Header: 'Company Name',
    show: false,
    group: ['Overview'],
  },
  {
    accessor: 'MktCap',
    Header: 'Mkt Cap',
    show: false,
    group: [],
  },
  {
    accessor: 'Price',
    Header: 'Price',
    show: false,
    group: ['Overview'],
  },
  {
    accessor: 'Beta',
    Header: 'Beta',
    show: false,
    group: [],
  },
  {
    accessor: 'VolAvg',
    Header: 'Average Volume',
    show: false,
    group: [],
  },
  // {
  //   accessor: 'companyName',
  //   Header: 'Company Name',
  //   show: false,
  //   group: ['Overview'],
  // },
  {
    accessor: 'sector',
    Header: 'Sector',
    show: false,
    group: ['Overview'],
  },
  {
    accessor: 'industry',
    Header: 'Industry',
    show: false,
    group: ['Overview'],
  },
  {
    accessor: 'LastDiv',
    Header: 'Last Dividend',
    show: false,
    group: [],
  },
  {
    accessor: 'Range',
    Header: 'Range',
    show: false,
    group: [],
  },
  {
    accessor: 'Changes',
    Header: 'Changes',
    show: false,
    group: [],
  },
  {
    accessor: 'exchange',
    Header: 'Exchange',
    show: false,
    group: [],
  },
  {
    accessor: 'cik',
    Header: 'Cik',
    show: false,
    group: [],
  },
  {
    accessor: 'isin',
    Header: 'Isin',
    show: false,
    group: [],
  },
  {
    accessor: 'cusip',
    Header: 'Cussip',
    show: false,
    group: [],
  },
  {
    accessor: 'DCF_diff',
    Header: 'DCF Diff.',
    show: false,
    group: [],
  },
  {
    accessor: 'DCF',
    Header: 'DCF',
    show: false,
    group: [],
  },
  {
    accessor: 'ipoDate',
    Header: 'IPO Date',
    show: false,
    group: [],
  },
  {
    accessor: 'isEtf',
    Header: 'Is An ETF',
    show: false,
    group: [],
  },
  {
    accessor: 'isActivelyTrading',
    Header: 'Is Actively Trading',
    show: false,
    group: [],
  },
  {
    accessor: 'isFund',
    Header: 'Is A Fund',
    show: false,
    group: [],
  },
  {
    accessor: 'isAdr',
    Header: 'Is American Depositary Receipt',
    show: false,
    group: [],
  },
  {
    accessor: 'CEO',
    Header: 'CEO',
    show: false,
    group: [],
  },
  {
    accessor: 'country',
    Header: 'Country',
    show: false,
    group: ['Overview'],
  },
  {
    accessor: 'fullTimeEmployees',
    Header: 'Employees',
    show: false,
    group: [],
  },
  {
    accessor: 'currency',
    Header: 'Currency',
    show: false,
    group: [],
  },
  {
    accessor: 'exchangeShortName',
    Header: 'Exchange',
    show: false,
    group: [],
  },
  {
    accessor: 'phone',
    Header: 'Phone Number',
    show: false,
    group: [],
  },
  {
    accessor: 'address',
    Header: 'Company Address',
    show: false,
    group: [],
  },
  {
    accessor: 'city',
    Header: 'City',
    show: false,
    group: [],
  },
  {
    accessor: 'state',
    Header: 'State',
    show: false,
    group: [],
  },
  {
    accessor: 'zip',
    Header: 'Zip',
    show: false,
    group: [],
  },
  {
    accessor: 'piotroskiScore',
    Header: 'Piotroski Score',
    show: false,
    group: [],
  },
  {
    accessor: 'altmanZScore',
    Header: 'Altman Z Score',
    show: false,
    group: [],
  },
  {
    accessor: 'rating',
    Header: 'Rating',
    show: false,
    group: [],
  },
  {
    accessor: 'ratingScore',
    Header: 'Rating Score',
    show: false,
    group: [],
  },
  {
    accessor: 'ratingRecommendation',
    Header: 'Rating Recommendation',
    show: false,
    group: [],
  },
  {
    accessor: 'ratingDetailsDCFScore',
    Header: 'DCF Rating',
    group: [],
  },
  {
    accessor: 'ratingDetailsDCFRecommendation',
    Header: 'DCF Rating Recommendation',
    show: false,
    group: [],
  },
  {
    accessor: 'ratingDetailsROEScore',
    Header: 'ROE Rating',
    show: false,
    group: [],
  },
  {
    accessor: 'ratingDetailsROERecommendation',
    Header: 'ROE Rating Recommendation',
    show: false,
    group: [],
  },
  {
    accessor: 'ratingDetailsROAScore',
    Header: 'ROA Rating',
    show: false,
    group: [],
  },
  {
    accessor: 'ratingDetailsROARecommendation',
    Header: 'ROA Rating Recommendation',
    show: false,
    group: [],
  },
  {
    accessor: 'ratingDetailsDEScore',
    Header: 'Debt/Eq Rating',
    show: false,
    group: [],
  },
  {
    accessor: 'ratingDetailsDERecommendation',
    Header: 'Debt/Eq Rating Recommendation',
    show: false,
    group: [],
  },
  {
    accessor: 'ratingDetailsPEScore',
    Header: 'P/E Rating',
    show: false,
    group: [],
  },
  {
    accessor: 'ratingDetailsPERecommendation',
    Header: 'P/E Rating Recommendation',
    show: false,
    group: [],
  },
  {
    accessor: 'ratingDetailsPBScore',
    Header: 'P/B Rating',
    show: false,
    group: [],
  },
  {
    accessor: 'ratingDetailsPBRecommendation',
    Header: 'P/B Rating Recommendation',
    show: false,
    group: [],
  },
  {
    accessor: 'revenuePerShareTTM',
    Header: 'Revenue Per Share',
    show: false,
    group: [],
  },
  {
    accessor: 'netIncomePerShareTTM',
    Header: 'Net Income Per Share',
    show: false,
    group: [],
  },
  {
    accessor: 'operatingCashFlowPerShareTTM',
    Header: 'Operating Cash Flow Per Share',
    show: false,
    group: [],
  },

  {
    accessor: 'bookValuePerShareTTM',
    Header: 'Book Value Per Share',
    show: false,
    group: [],
  },
  {
    accessor: 'tangibleBookValuePerShareTTM',
    Header: 'Tangible Book Value Per Share',
    show: false,
    group: [],
  },
  {
    accessor: 'shareholdersEquityPerShareTTM',
    Header: 'Shareholders Equity Per Share',
    show: false,
    group: [],
  },
  {
    accessor: 'interestDebtPerShareTTM',
    Header: 'Interest Debt Per Share',
    show: false,
    group: [],
  },
  {
    accessor: 'enterpriseValueTTM',
    Header: 'Enterprise Value',
    show: false,
    group: [],
  },
  {
    accessor: 'evToSalesTTM',
    Header: 'EV/Sales',
    show: false,
    group: [],
  },
  {
    accessor: 'enterpriseValueOverEBITDATTM',
    Header: 'EV Over EBITDA',
    show: false,
    group: [],
  },
  {
    accessor: 'evToOperatingCashFlowTTM',
    Header: 'EV/OCF',
    show: false,
    group: [],
  },
  {
    accessor: 'evToFreeCashFlowTTM',
    Header: 'EV/FCF',
    show: false,
    group: [],
  },
  {
    accessor: 'earningsYieldTTM',
    Header: 'Earnings Yield',
    show: false,
    group: [],
  },
  {
    accessor: 'freeCashFlowYieldTTM',
    Header: 'Free Cash Flow Yield',
    show: false,
    group: [],
  },
  {
    accessor: 'debtToEquityTTM',
    Header: 'Debt/Eq',
    show: false,
    group: ['Debt'],
  },
  {
    accessor: 'debtToAssetsTTM',
    Header: 'Debt/Assets',
    show: false,
    group: ['Debt'],
  },
  {
    accessor: 'netDebtToEBITDATTM',
    Header: 'Net Debt/EBITDA',
    show: false,
    group: ['Debt'],
  },
  {
    accessor: 'currentRatioTTM',
    Header: 'Current Ratio',
    show: false,
    group: [],
  },
  {
    accessor: 'interestCoverageTTM',
    Header: 'Interest Coverage',
    show: false,
    group: [],
  },
  {
    accessor: 'incomeQualityTTM',
    Header: 'Income Quality',
    show: false,
    group: [],
  },
  {
    accessor: 'dividendYieldTTM',
    Header: 'Dividend Yield',
    show: false,
    group: [],
  },
  {
    accessor: 'dividendYieldPercentageTTM',
    Header: 'Div Yield %',
    show: false,
    group: [],
  },

  {
    accessor: 'salesGeneralAndAdministrativeToRevenueTTM',
    Header: 'SG&A/Revenue',
    show: false,
    group: [],
  },
  {
    accessor: 'researchAndDevelopementToRevenueTTM',
    Header: 'R&D/Revenue',
    show: false,
    group: [],
  },
  {
    accessor: 'intangiblesToTotalAssetsTTM',
    Header: 'Intangibles/Assets',
    show: false,
    group: [],
  },
  {
    accessor: 'capexToOperatingCashFlowTTM',
    Header: 'Capex/OCF',
    show: false,
    group: [],
  },
  {
    accessor: 'capexToRevenueTTM',
    Header: 'Capex/Revenue',
    show: false,
    group: [],
  },
  {
    accessor: 'capexToDepreciationTTM',
    Header: 'Capex/Depreciation',
    show: false,
    group: [],
  },
  {
    accessor: 'stockBasedCompensationToRevenueTTM',
    Header: 'Stock Based Compensation/Revenue',
    show: false,
    group: [],
  },
  {
    accessor: 'grahamNumberTTM',
    Header: 'Graham Number',
    show: false,
    group: [],
  },
  {
    accessor: 'roicTTM',
    Header: 'ROIC',
    show: false,
    group: [],
  },
  {
    accessor: 'returnOnTangibleAssetsTTM',
    Header: 'Return On Tangible Assets',
    show: false,
    group: [],
  },
  {
    accessor: 'grahamNetNetTTM',
    Header: 'Graham Net Net',
    show: false,
    group: [],
  },
  {
    accessor: 'workingCapitalTTM',
    Header: 'Working Capital',
    show: false,
    group: [],
  },
  {
    accessor: 'tangibleAssetValueTTM',
    Header: 'Tangible Asset Value',
    show: false,
    group: [],
  },
  {
    accessor: 'netCurrentAssetValueTTM',
    Header: 'Net Current Asset Value',
    show: false,
    group: [],
  },
  {
    accessor: 'investedCapitalTTM',
    Header: 'Invested Capital',
    show: false,
    group: [],
  },
  {
    accessor: 'averageReceivablesTTM',
    Header: 'Average Receivables',
    show: false,
    group: [],
  },
  {
    accessor: 'averagePayablesTTM',
    Header: 'Average Payables',
    show: false,
    group: [],
  },
  {
    accessor: 'averageInventoryTTM',
    Header: 'Average Inventory',
    show: false,
    group: [],
  },
  {
    accessor: 'daysSalesOutstandingTTM',
    Header: 'Days Of Sales Outstand',
    show: false,
    group: [],
  },
  {
    accessor: 'daysPayablesOutstandingTTM',
    Header: 'Days Of Payables Outstand',
    show: false,
    group: [],
  },
  {
    accessor: 'daysOfInventoryOnHandTTM',
    Header: 'Days Of Inventory On Hand',
    show: false,
    group: [],
  },
  {
    accessor: 'roeTTM',
    Header: 'ROE',
    show: false,
    group: [],
  },
  {
    accessor: 'capexPerShareTTM',
    Header: 'Capex Per Share',
    show: false,
    group: [],
  },
  {
    accessor: 'dividendPerShareTTM',
    Header: 'Div Per Share',
    show: false,
    group: [],
  },
  {
    accessor: 'debtToMarketCapTTM',
    Header: 'Debt/Market Cap',
    show: false,
    group: ['Debt'],
  },
  {
    accessor: 'dividendYielTTM',
    Header: 'Div Yield',
    show: false,
    group: [],
  },
  {
    accessor: 'dividendYielPercentageTTM',
    Header: 'Div Yield %',
    show: false,
    group: [],
  },
  {
    accessor: 'payoutRatioTTM',
    Header: 'Payout Ratio',
    show: false,
    group: [],
  },

  {
    accessor: 'quickRatioTTM',
    Header: 'Quick Ratio',
    show: false,
    group: [],
  },
  {
    accessor: 'cashRatioTTM',
    Header: 'Cash Ratio',
    show: false,
    group: [],
  },
  {
    accessor: 'daysOfInventoryOutstandingTTM',
    Header: 'Days Of Inventory Outstand',
    show: false,
    group: [],
  },
  {
    accessor: 'operatingCycleTTM',
    Header: 'Operating Cycle',
    show: false,
    group: [],
  },
  {
    accessor: 'cashConversionCycleTTM',
    Header: 'Cash Conversion Cycle',
    show: false,
    group: [],
  },
  {
    accessor: 'grossProfitMarginTTM',
    Header: 'Gross Profit Margin',
    show: false,
    group: [],
  },
  {
    accessor: 'operatingProfitMarginTTM',
    Header: 'Operating Profit Margin',
    show: false,
    group: [],
  },
  {
    accessor: 'pretaxProfitMarginTTM',
    Header: 'Pre Tax Profit Margin',
    show: false,
    group: [],
  },
  {
    accessor: 'netProfitMarginTTM',
    Header: 'Net Profit Margin',
    show: false,
    group: [],
  },
  {
    accessor: 'effectiveTaxRateTTM',
    Header: 'Effective Tax Rate',
    show: false,
    group: [],
  },
  {
    accessor: 'returnOnAssetsTTM',
    Header: 'ROA',
    show: false,
    group: [],
  },
  {
    accessor: 'returnOnEquityTTM',
    Header: 'ROE',
    show: false,
    group: [],
  },
  {
    accessor: 'returnOnCapitalEmployedTTM',
    Header: 'ROCE',
    show: false,
    group: [],
  },
  {
    accessor: 'netIncomePerEBTTTM',
    Header: 'Net Income Per EBT',
    show: false,
    group: [],
  },
  {
    accessor: 'ebtPerEbitTTM',
    Header: 'EBT per EBIT',
    show: false,
    group: [],
  },
  {
    accessor: 'ebitPerRevenueTTM',
    Header: 'EBIT Per Revenue',
    show: false,
    group: [],
  },
  {
    accessor: 'debtRatioTTM',
    Header: 'Debt Ratio',
    show: false,
    group: [],
  },
  {
    accessor: 'debtEquityRatioTTM',
    Header: 'Debt/Eq',
    show: false,
    group: [],
  },
  {
    accessor: 'longTermDebtToCapitalizationTTM',
    Header: 'LT Debt/Cap',
    show: false,
    group: [],
  },
  {
    accessor: 'totalDebtToCapitalizationTTM',
    Header: 'Debt/Mkt Cap',
    show: false,
    group: [],
  },

  {
    accessor: 'cashFlowToDebtRatioTTM',
    Header: 'CF/Debt',
    show: false,
    group: [],
  },
  {
    accessor: 'companyEquityMultiplierTTM',
    Header: 'Company Equity Multiplier',
    show: false,
    group: [],
  },
  {
    accessor: 'receivablesTurnoverTTM',
    Header: 'Receivables Turnover',
    show: false,
    group: [],
  },
  {
    accessor: 'payablesTurnoverTTM',
    Header: 'Payables Turnover',
    show: false,
    group: [],
  },

  {
    accessor: 'fixedAssetTurnoverTTM',
    Header: 'Fixed Asset Turnover',
    show: false,
    group: [],
  },
  {
    accessor: 'assetTurnoverTTM',
    Header: 'Asset Turnover',
    show: false,
    group: [],
  },
  {
    accessor: 'freeCashFlowPerShareTTM',
    Header: 'FCF Per Share',
    show: false,
    group: [],
  },
  {
    accessor: 'cashPerShareTTM',
    Header: 'Cash Per Share',
    show: false,
    group: [],
  },
  {
    accessor: 'operatingCashFlowSalesRatioTTM',
    Header: 'OCF/Sales',
    show: false,
    group: [],
  },
  {
    accessor: 'freeCashFlowOperatingCashFlowRatioTTM',
    Header: 'FCF/OCF',
    show: false,
    group: [],
  },
  {
    accessor: 'cashFlowCoverageRatiosTTM',
    Header: 'CF Coverage',
    show: false,
    group: [],
  },
  {
    accessor: 'shortTermCoverageRatiosTTM',
    Header: 'Short Term Coverage Ratio',
    show: false,
    group: [],
  },
  {
    accessor: 'capitalExpenditureCoverageRatioTTM',
    Header: 'Capital Expenditure Coverage Ratio',
    show: false,
    group: [],
  },
  {
    accessor: 'dividendPaidAndCapexCoverageRatioTTM',
    Header: 'Div Paid & Capex Coverage Ratio',
    show: false,
    group: [],
  },
  {
    accessor: 'priceBookValueRatioTTM',
    Header: 'P/B Value',
    show: false,
    group: [],
  },
  {
    accessor: 'priceToBookRatioTTM',
    Header: 'P/B',
    show: false,
    group: ['Valuation', 'Price Ratios'],
  },
  {
    accessor: 'priceToSalesRatioTTM',
    Header: 'P/S',
    show: false,
    group: ['Price Ratios'],
  },
  {
    accessor: 'priceEarningsRatio',
    Header: 'Price/Earnings Ratio',
    show: false,
    group: ['Valuation', 'Price Ratios'],
  },
  {
    accessor: 'priceEarningsToGrowthRatio',
    Header: 'Price Earnings/Growth Ratio',
    show: false,
    group: ['Growth', 'Price Ratios'],
  },
  {
    accessor: 'priceToFreeCashFlowsRatioTTM',
    Header: 'P/FCF',
    show: false,
    group: ['Valuation', 'Price Ratios'],
  },
  {
    accessor: 'priceToOperatingCashFlowsRatioTTM',
    Header: 'Price/OCFs Ratio',
    show: false,
    group: [],
  },
  {
    accessor: 'priceCashFlowRatioTTM',
    Header: 'P/CF ',
    show: false,
    group: [],
  },
  {
    accessor: 'priceSalesRatioTTM',
    Header: 'P/S',
    show: false,
    group: ['Price Ratios'],
  },
  {
    accessor: 'enterpriseValueMultipleTTM',
    Header: 'Enterprise Value Multiple',
    show: false,
    group: [],
  },
  {
    accessor: 'priceFairValueTTM',
    Header: 'Price/Fair Value',
    show: false,
    group: ['Price Ratios'],
  },
  {
    accessor: 'cashAndCashEquivalents',
    Header: 'Cash & Cash Equivalents',
    show: false,
    group: [],
  },
  {
    accessor: 'shortTermInvestments',
    Header: 'Short Term Investments',
    show: false,
    group: [],
  },
  {
    accessor: 'cashAndShortTermInvestments',
    Header: 'Cash & Short Term Investments',
    show: false,
    group: [],
  },
  {
    accessor: 'netReceivables',
    Header: 'Net Receivables',
    show: false,
    group: [],
  },

  {
    accessor: 'otherCurrentAssets',
    Header: 'Other Current Assets',
    show: false,
    group: [],
  },
  {
    accessor: 'totalCurrentAssets',
    Header: 'Current Assets',
    show: false,
    group: ['Balance Sheet'],
  },
  {
    accessor: 'propertyPlantEquipmentNet',
    Header: 'Property Plant Equipment Net',
    show: false,
    group: [],
  },
  {
    accessor: 'goodwill',
    Header: 'Goodwill',
    show: false,
    group: [],
  },
  {
    accessor: 'intangibleAssets',
    Header: 'Intangible Assets',
    show: false,
    group: [],
  },
  {
    accessor: 'goodwillAndIntangibleAssets',
    Header: 'Goodwill & Intangible Assets',
    show: false,
    group: [],
  },
  {
    accessor: 'longTermInvestments',
    Header: 'Long Term Investments',
    show: false,
    group: [],
  },
  {
    accessor: 'taxAssets',
    Header: 'Tax Assets',
    show: false,
    group: [],
  },
  {
    accessor: 'otherNonCurrentAssets',
    Header: 'Other Non Current Assets',
    show: false,
    group: [],
  },
  {
    accessor: 'totalNonCurrentAssets',
    Header: 'Non Current Assets',
    show: false,
    group: ['Balance Sheet'],
  },
  {
    accessor: 'otherAssets',
    Header: 'Other Assets',
    show: false,
    group: [],
  },
  {
    accessor: 'totalAssets',
    Header: 'Assets',
    show: false,
    group: ['Quick Financials', 'Balance Sheet'],
  },
  {
    accessor: 'accountPayables',
    Header: 'Account Payables',
    show: false,
    group: [],
  },
  {
    accessor: 'shortTermDebt',
    Header: 'Short Term Debt',
    show: false,
    group: [],
  },
  {
    accessor: 'taxPayables',
    Header: 'Tax Payables',
    show: false,
    group: [],
  },
  {
    accessor: 'deferredRevenue',
    Header: 'Deferred Revenue',
    show: false,
    group: [],
  },
  {
    accessor: 'otherCurrentLiabilities',
    Header: 'Other Current Liabilities',
    show: false,
    group: [],
  },
  {
    accessor: 'totalCurrentLiabilities',
    Header: 'Current Liabilities',
    show: false,
    group: ['Balance Sheet'],
  },
  {
    accessor: 'longTermDebt',
    Header: 'Long Term Debt',
    show: false,
    group: [],
  },
  {
    accessor: 'deferredRevenueNonCurrent',
    Header: 'Deferred Revenue Non Current',
    show: false,
    group: [],
  },
  {
    accessor: 'deferrredTaxLiabilitiesNonCurrent',
    Header: 'Deferred Tax Liabilities Non Current',
    show: false,
    group: [],
  },
  {
    accessor: 'otherNonCurrentLiabilities',
    Header: 'Other Non Current Liabilities',
    show: false,
    group: [],
  },
  {
    accessor: 'totalNonCurrentLiabilities',
    Header: 'Non Current Liabilities',
    show: false,
    group: ['Balance Sheet'],
  },
  {
    accessor: 'otherLiabilities',
    Header: 'Other Liabilities',
    show: false,
    group: [],
  },
  {
    accessor: 'totalLiabilities',
    Header: 'Liabilities',
    show: false,
    group: ['Quick Financials', 'Balance Sheet'],
  },
  {
    accessor: 'commonStock',
    Header: 'Common Stock',
    show: false,
    group: [],
  },
  {
    accessor: 'retainedEarnings',
    Header: 'Retained Earnings',
    show: false,
    group: ['Balance Sheet'],
  },
  {
    accessor: 'accumulatedOtherComprehensiveIncomeLoss',
    Header: 'Accumulated Other Comprehensive Income Loss',
    show: false,
    group: [],
  },
  {
    accessor: 'othertotalStockholdersEquity',
    Header: 'Other Stockholders Equity',
    show: false,
    group: [],
  },
  {
    accessor: 'totalStockholdersEquity',
    Header: 'Stockholders Equity',
    show: false,
    group: ['Quick Financials', 'Balance Sheet'],
  },
  {
    accessor: 'totalLiabilitiesAndStockholdersEquity',
    Header: 'Liabilities & Stockholders Equity',
    show: false,
    group: [],
  },
  {
    accessor: 'totalInvestments',
    Header: 'Investments',
    show: false,
    group: [],
  },
  {
    accessor: 'totalDebt',
    Header: 'Debt',
    show: false,
    group: ['Debt'],
  },
  {
    accessor: 'netDebt',
    Header: 'Net Debt',
    show: false,
    group: [],
  },

  {
    accessor: 'deferredIncomeTax',
    Header: 'Deferred Income Tax',
    show: false,
    group: [],
  },
  {
    accessor: 'stockBasedCompensation',
    Header: 'Stock Based Compensation',
    show: false,
    group: [],
  },
  {
    accessor: 'changeInWorkingCapital',
    Header: 'Change In Working Capital',
    show: false,
    group: [],
  },
  {
    accessor: 'accountsReceivables',
    Header: 'Accounts Receivables',
    show: false,
    group: [],
  },
  {
    accessor: 'inventory',
    Header: 'Inventory',
    show: false,
    group: [],
  },
  {
    accessor: 'accountsPayables',
    Header: 'Accounts Payables',
    show: false,
    group: [],
  },
  {
    accessor: 'otherWorkingCapital',
    Header: 'Other Working Capital',
    show: false,
    group: [],
  },
  {
    accessor: 'otherNonCashItems',
    Header: 'Other Non Cash Items',
    show: false,
    group: [],
  },
  {
    accessor: 'netCashProvidedByOperatingActivites',
    Header: 'Cash Provided By Operating Activities',
    show: false,
    group: [],
  },
  {
    accessor: 'investmentsInPropertyPlantAndEquipment',
    Header: 'Investments In Property Plant & Equipment',
    show: false,
    group: [],
  },
  {
    accessor: 'acquisitionsNet',
    Header: 'Net Acquisitions',
    show: false,
    group: [],
  },
  {
    accessor: 'purchasesOfInvestments',
    Header: 'Purchases Of Investments',
    show: false,
    group: [],
  },
  {
    accessor: 'salesMaturitiesOfInvestments',
    Header: 'Sales Maturities Of Investments',
    show: false,
    group: [],
  },
  {
    accessor: 'otherInvestingActivites',
    Header: 'Other Investing Activities',
    show: false,
    group: [],
  },
  {
    accessor: 'netCashUsedForInvestingActivites',
    Header: 'Cash Used For Investing Activities',
    show: false,
    group: [],
  },
  {
    accessor: 'debtRepayment',
    Header: 'Debt Repayment',
    show: false,
    group: ['Debt'],
  },
  {
    accessor: 'commonStockIssued',
    Header: 'Common Stock Issued',
    show: false,
    group: [],
  },
  {
    accessor: 'commonStockRepurchased',
    Header: 'Common Stock Repurchased',
    show: false,
    group: [],
  },
  {
    accessor: 'dividendsPaid',
    Header: 'Divs Paid',
    show: false,
    group: [],
  },
  {
    accessor: 'otherFinancingActivites',
    Header: 'Other Financing Activities',
    show: false,
    group: [],
  },
  {
    accessor: 'netCashUsedProvidedByFinancingActivities',
    Header: 'Cash Used Provided By Financing Activities',
    show: false,
    group: [],
  },
  {
    accessor: 'effectOfForexChangesOnCash',
    Header: 'Effect Of Forex Changes On Cash',
    show: false,
    group: [],
  },
  {
    accessor: 'netChangeInCash',
    Header: 'Net Change In Cash',
    show: false,
    group: [],
  },
  {
    accessor: 'cashAtEndOfPeriod',
    Header: 'Cash At End Of Period',
    show: false,
    group: [],
  },
  {
    accessor: 'cashAtBeginningOfPeriod',
    Header: 'Cash At Beginning Of Period',
    show: false,
    group: [],
  },
  {
    accessor: 'operatingCashFlow',
    Header: 'Operating Cash Flow',
    show: false,
    group: [],
  },
  {
    accessor: 'capitalExpenditure',
    Header: 'Capital Expenditure',
    show: false,
    group: [],
  },
  {
    accessor: 'freeCashFlow',
    Header: 'Free Cash Flow',
    show: false,
    group: ['Quick Financials', 'Cash Flow Statement'],
  },
  {
    accessor: 'revenue',
    Header: 'Revenue',
    show: false,
    group: ['Quick Financials', 'Income Statement'],
  },
  {
    accessor: 'costOfRevenue',
    Header: 'Cost Of Revenue',
    show: false,
    group: ['Income Statement'],
  },
  {
    accessor: 'grossProfit',
    Header: 'Gross Profit',
    show: false,
    group: ['Income Statement'],
  },
  {
    accessor: 'grossProfitRatio',
    Header: 'Gross Profit Ratio',
    show: false,
    group: [],
  },
  {
    accessor: 'ResearchAndDevelopmentExpenses',
    Header: 'Research & Development Expenses',
    show: false,
    group: [],
  },
  {
    accessor: 'GeneralAndAdministrativeExpenses',
    Header: 'General & Administrative Expenses',
    show: false,
    group: [],
  },
  {
    accessor: 'SellingAndMarketingExpenses',
    Header: 'Selling & Marketing Expenses',
    show: false,
    group: [],
  },
  {
    accessor: 'sellingGeneralAndAdministrativeExpenses',
    Header: 'Selling General & Administrative Expenses',
    show: false,
    group: [],
  },
  {
    accessor: 'otherExpenses',
    Header: 'Other Expenses',
    show: false,
    group: [],
  },
  {
    accessor: 'operatingExpenses',
    Header: 'Operating Expenses',
    show: false,
    group: ['Income Statement'],
  },
  {
    accessor: 'costAndExpenses',
    Header: 'Cost & Expenses',
    show: false,
    group: [],
  },
  {
    accessor: 'interestExpense',
    Header: 'Interest Expense',
    show: false,
    group: [],
  },
  {
    accessor: 'depreciationAndAmortization',
    Header: 'Depreciation & Amortization',
    show: false,
    group: [],
  },
  {
    accessor: 'EBITDA',
    Header: 'EBITDA',
    show: false,
    group: ['Quick Financials', 'Income Statement'],
  },
  {
    accessor: 'EBITDARatiooperatingIncome',
    Header: 'EBITDA Ratio',
    show: false,
    group: [],
  },
  {
    accessor: 'operatingIncome',
    Header: 'Operating Income',
    show: false,
    group: [],
  },
  {
    accessor: 'operatingIncomeRatio',
    Header: 'Operating Income Ratio',
    show: false,
    group: [],
  },
  {
    accessor: 'totalOtherIncomeExpensesNet',
    Header: 'Other Income Expenses Net',
    show: false,
    group: [],
  },
  {
    accessor: 'incomeBeforeTax',
    Header: 'Income Before Tax',
    show: false,
    group: [],
  },
  {
    accessor: 'incomeBeforeTaxRatio',
    Header: 'Income Before Tax Ratio',
    show: false,
    group: [],
  },
  {
    accessor: 'incomeTaxExpense',
    Header: 'Income Tax Expense',
    show: false,
    group: [],
  },
  {
    accessor: 'netIncome',
    Header: 'Net Income',
    show: true,
    group: ['Quick Financials', 'Cash Flow Statement', 'Income Statement'],
  },
  {
    accessor: 'netIncomeRatio',
    Header: 'Net Income Ratio',
    show: false,
    group: [],
  },
  {
    accessor: 'EPS',
    Header: 'EPS',
    show: false,
    group: ['Valuation'],
  },
  {
    accessor: 'EPSDiluted',
    Header: 'EPS Diluted',
    show: false,
    group: [],
  },
  {
    accessor: 'weightedAverageShsOut',
    Header: 'Weighted Averages Shares Outstanding',
    show: false,
    group: [],
  },
  {
    accessor: 'weightedAverageShsOutDil',
    Header: 'Weighted Averages Shares Outstanding Diluted',
    show: false,
    group: [],
  },
  {
    accessor: 'tenYRevenueGrowthPerShare',
    Header: '10 Year Revenue Growth Per Share',
    show: false,
    group: ['10 Year Financial Growth'],
  },
  {
    accessor: 'fiveYRevenueGrowthPerShare',
    Header: '5 Year Revenue Growth Per Share',
    show: false,
    group: ['5 Year Financial Growth'],
  },
  {
    accessor: 'threeYRevenueGrowthPerShare',
    Header: '3 Year Revenue Growth Per Share',
    show: false,
    group: ['3 Year Financial Growth'],
  },
  {
    accessor: 'tenYOperatingCFGrowthPerShare',
    Header: '10 Year Operating Cash Flow Growth Per Share',
    show: false,
    group: ['10 Year Financial Growth'],
  },
  {
    accessor: 'fiveYOperatingCFGrowthPerShare',
    Header: '5 Year Operating Cash Flow Growth Per Share',
    show: false,
    group: ['5 Year Financial Growth'],
  },
  {
    accessor: 'threeYOperatingCFGrowthPerShare',
    Header: '3 Year Operating Cash Flow Growth Per Share',
    show: false,
    group: ['3 Year Financial Growth'],
  },
  {
    accessor: 'tenYNetIncomeGrowthPerShare',
    Header: '10 Year Net Income Growth Per Share',
    show: false,
    group: ['10 Year Financial Growth'],
  },
  {
    accessor: 'fiveYNetIncomeGrowthPerShare',
    Header: '5 Year Net Income Growth Per Share',
    show: false,
    group: ['5 Year Financial Growth'],
  },
  {
    accessor: 'threeYNetIncomeGrowthPerShare',
    Header: '3 Year Net Income Growth Per Share',
    show: false,
    group: ['3 Year Financial Growth'],
  },
  {
    accessor: 'tenYShareholdersEquityGrowthPerShare',
    Header: '10 Year Shareholders Equity Year Growth Per Share',
    show: false,
    group: ['10 Year Financial Growth'],
  },
  {
    accessor: 'fiveYShareholdersEquityGrowthPerShare',
    Header: '5 Year Shareholders Equity Year Growth Per Share',
    show: false,
    group: ['Valuation', '5 Year Financial Growth'],
  },
  {
    accessor: 'threeYShareholdersEquityGrowthPerShare',
    Header: '3 Year Shareholders Equity Year Growth Per Share',
    show: false,
    group: ['3 Year Financial Growth'],
  },
  {
    accessor: 'tenYDividendperShareGrowthPerShare',
    Header: '10 Year Div Per Share Growth Per Share',
    show: false,
    group: ['10 Year Financial Growth'],
  },
  {
    accessor: 'fiveYDividendperShareGrowthPerShare',
    Header: '5 Year Div Per Share Growth Per Share',
    show: false,
    group: ['5 Year Financial Growth'],
  },
  {
    accessor: 'threeYDividendperShareGrowthPerShare',
    Header: '3 Year Div Per Share Growth Per Share',
    show: false,
    group: ['3 Year Financial Growth'],
  },
  {
    accessor: 'growthCashAndCashEquivalents',
    Header: 'Cash & Cash Equivalents Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthShortTermInvestments',
    Header: 'Short Term Investments Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthCashAndShortTermInvestments',
    Header: 'Cash & Short Term Investments Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthNetReceivables',
    Header: 'Net Receivables Growth',
    show: false,
    group: [],
  },

  {
    accessor: 'growthOtherCurrentAssets',
    Header: 'Other Current Assets Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthTotalCurrentAssets',
    Header: 'Current Assets Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthPropertyPlantEquipmentNet',
    Header: 'Property Plant Equipment Net Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthGoodwill',
    Header: 'Goodwill Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthIntangibleAssets',
    Header: 'Intangible Assets Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthGoodwillAndIntangibleAssets',
    Header: 'Goodwill & Intangible Assets Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthLongTermInvestments',
    Header: 'Long Term Investments Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthTaxAssets',
    Header: 'Tax Assets Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthOtherNonCurrentAssets',
    Header: 'Other Non Current Assets Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthTotalNonCurrentAssets',
    Header: 'Non Current Assets Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthOtherAssets',
    Header: 'Other Assets Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthTotalAssets',
    Header: 'Assets Growth',
    show: false,
    group: ['Growth'],
  },
  {
    accessor: 'growthAccountPayables',
    Header: 'Account Payables Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthShortTermDebt',
    Header: 'Short Term Debt Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthTaxPayables',
    Header: 'Tax Payables Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthDeferredRevenue',
    Header: 'Deferred Revenue Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthOtherCurrentLiabilities',
    Header: 'Other Current Liabilities Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthTotalCurrentLiabilities',
    Header: 'Current Liabilities Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthLongTermDebt',
    Header: 'Long Term Debt Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthDeferredRevenueNonCurrent',
    Header: 'Deferred Revenue Non Current Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthDeferrredTaxLiabilitiesNonCurrent',
    Header: 'Deferrred Tax Liabilities Non Current Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthOtherNonCurrentLiabilities',
    Header: 'Other Non Current Liabilities Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthTotalNonCurrentLiabilities',
    Header: 'Non Current Liabilities Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthOtherLiabilities',
    Header: 'Other Liabilities Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthTotalLiabilities',
    Header: 'Liabilities Growth',
    show: false,
    group: ['Growth'],
  },
  {
    accessor: 'growthCommonStock',
    Header: 'Common Stock Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthRetainedEarnings',
    Header: 'Retained Earnings Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthAccumulatedOtherComprehensiveIncomeLoss',
    Header: 'Accumulated Other Comprehensive Income Loss Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthOthertotalStockholdersEquity',
    Header: 'Other Stockholders Equity Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthTotalStockholdersEquity',
    Header: 'Stockholders Equity Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthTotalLiabilitiesAndStockholdersEquity',
    Header: 'Liabilities & Stockholders Equity Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthTotalInvestments',
    Header: 'Investments Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthTotalDebt',
    Header: 'Debt Growth',
    show: false,
    group: ['Debt'],
  },
  {
    accessor: 'growthNetDebt',
    Header: 'Net Debt Growth',
    show: false,
    group: ['Growth', 'Debt'],
  },
  {
    accessor: 'growthNetIncome',
    Header: 'Net Income Growth',
    show: false,
    group: ['Growth'],
  },

  {
    accessor: 'growthStockBasedCompensation',
    Header: 'Stock Based Compensation Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthChangeInWorkingCapital',
    Header: 'Change In Working Capital Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthAccountsReceivables',
    Header: 'Accounts Receivables Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthInventory',
    Header: 'Inventory Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthAccountsPayables',
    Header: 'Accounts Payables Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthOtherWorkingCapital',
    Header: 'Other Working Capital Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthOtherNonCashItems',
    Header: 'Other Non Cash Items Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthNetCashProvidedByOperatingActivites',
    Header: 'Cash Provided By Operating Activites Growth',
    show: false,
    group: ['Cash Flow Statement'],
  },
  {
    accessor: 'growthInvestmentsInPropertyPlantAndEquipment',
    Header: 'Investments In Property Plant & Equipment Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthAcquisitionsNet',
    Header: 'Acquisitions Net Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthPurchasesOfInvestments',
    Header: 'Purchases Of Investments Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthSalesMaturitiesOfInvestments',
    Header: 'Sales Maturities Of Investments Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthOtherInvestingActivites',
    Header: 'Other Investing Activites Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthNetCashUsedForInvestingActivites',
    Header: 'Cash Used For Investing Activites Growth',
    show: false,
    group: ['Cash Flow Statement'],
  },
  {
    accessor: 'growthDebtRepayment',
    Header: 'Debt Repayment Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthCommonStockIssued',
    Header: 'Common Stock Issued Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthCommonStockRepurchased',
    Header: 'Common Stock Repurchased Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthDividendsPaid',
    Header: 'Dividends Paid Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthOtherFinancingActivites',
    Header: 'Other Financing Activites Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthNetCashUsedProvidedByFinancingActivities',
    Header: 'Net Cash Used Provided By Financing Activities Growth',
    show: false,
    group: ['Cash Flow Statement'],
  },
  {
    accessor: 'growthEffectOfForexChangesOnCash',
    Header: 'Effect Of Forex Changes On Cash Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthNetChangeInCash',
    Header: 'Net Change In Cash Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthCashAtEndOfPeriod',
    Header: 'Cash At End Of Period Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthCashAtBeginningOfPeriod',
    Header: 'Cash At Beginning Of Period Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthOperatingCashFlow',
    Header: 'Operating Cash Flow Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthCapitalExpenditure',
    Header: 'Capital Expenditure Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthFreeCashFlow',
    Header: 'Free Cash Flow Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthRevenue',
    Header: 'Revenue Growth',
    show: false,
    group: ['Growth'],
  },
  {
    accessor: 'growthCostOfRevenue',
    Header: 'Cost Of Revenue Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthGrossProfit',
    Header: 'Gross Profit Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthGrossProfitRatio',
    Header: 'Gross Profit Ratio Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthResearchAndDevelopmentExpenses',
    Header: 'Research & Development Expenses Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthGeneralAndAdministrativeExpenses',
    Header: 'General & Administrative Expenses Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthSellingAndMarketingExpenses',
    Header: 'Selling & Marketing Expenses Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthOtherExpenses',
    Header: 'Other Expenses Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthOperatingExpenses',
    Header: 'Operating Expenses Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthCostAndExpenses',
    Header: 'Cost & Expenses Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthInterestExpense',
    Header: 'Interest Expense Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthDepreciationAndAmortization',
    Header: 'Depreciation & Amortization Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthEBITDA',
    Header: 'EBITDA Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthEBITDARatio',
    Header: 'EBITDA Ratio Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthOperatingIncome',
    Header: 'Operating Income Ratio Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthOperatingIncomeRatio',
    Header: 'Operating Income Ratio Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthTotalOtherIncomeExpensesNet',
    Header: 'Other Income Expenses Net Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthIncomeBeforeTax',
    Header: 'Income Before Tax Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthIncomeBeforeTaxRatio',
    Header: 'Income Before Tax Ratio Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthIncomeTaxExpense',
    Header: 'Income Tax Expense Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthNetIncomeRatio',
    Header: 'Net Income Ratio Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthEPS',
    Header: 'EPS Growth',
    show: false,
    group: ['Growth', 'Valuation'],
  },
  {
    accessor: 'growthEPSDiluted',
    Header: 'EPS Diluted Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthWeightedAverageShsOut',
    Header: 'Weighted Average Shares Outstanding Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'growthWeightedAverageShsOutDil',
    Header: 'Weighted Average Shares Outstanding Diluted Growth',
    show: false,
    group: [],
  },
  {
    accessor: 'priceEarningsRatioTTM',
    Header: 'P/E',
    show: false,
    group: [],
  },
]

// Price was last updates on april 25th 2023 || written on May 5th 2023

export const availibleColumns = [
  'currency',
  'Price',
  'Beta',
  'VolAvg',
  'MktCap',
  'LastDiv',
  'Range',
  'Changes',
  'image',
  'line',
  'companyName',
  'exchangeShortName',
  'industry',
  'sector',
  'country',
  'fullTimeEmployees',
  'workingCapitalTTM',
  'weightedAverageShsOutDil',
  'weightedAverageShsOut',
  'totalStockholdersEquity',
  'totalOtherIncomeExpensesNet',
  'totalNonCurrentLiabilities',
  'totalNonCurrentAssets',
  'totalLiabilitiesAndStockholdersEquity',
  'totalLiabilities',
  'totalInvestments',
  'totalDebtToCapitalizationTTM',
  'totalDebt',
  'totalCurrentLiabilities',
  'totalCurrentAssets',
  'totalAssets',
  'taxPayables',
  'taxAssets',
  'tangibleBookValuePerShareTTM',
  'tangibleAssetValueTTM',
  'symbol',
  'stockBasedCompensationToRevenueTTM',
  'stockBasedCompensation',
  'shortTermInvestments',
  'shortTermDebt',
  'shortTermCoverageRatiosTTM',
  'shareholdersEquityPerShareTTM',
  'sellingGeneralAndAdministrativeExpenses',
  'SellingAndMarketingExpenses',
  'salesMaturitiesOfInvestments',
  'salesGeneralAndAdministrativeToRevenueTTM',
  'roicTTM',
  'roeTTM',
  'revenuePerShareTTM',
  'revenue',
  'returnOnTangibleAssetsTTM',
  'returnOnEquityTTM',
  'returnOnCapitalEmployedTTM',
  'returnOnAssetsTTM',
  'retainedEarnings',
  'ResearchAndDevelopmentExpenses',
  'researchAndDevelopementToRevenueTTM',
  'reportedCurrency',
  'quickRatioTTM',
  'purchasesOfInvestments',
  'ptbRatioTTM',
  'propertyPlantEquipmentNet',
  'priceToOperatingCashFlowsRatioTTM',
  'priceToFreeCashFlowsRatioTTM',
  'priceToBookRatioTTM',
  'priceSalesRatioTTM',
  'priceFairValueTTM',
  'priceEarningsToGrowthRatioTTM',
  'priceEarningsRatioTTM',
  'priceCashFlowRatioTTM',
  'priceBookValueRatioTTM',
  'pretaxProfitMarginTTM',
  'preferredStock',
  'pocfratioTTM',
  'pfcfRatioTTM',
  'pegRatioTTM',
  'pbRatioTTM',
  'otherWorkingCapital',
  'othertotalStockholdersEquity',
  'otherNonCurrentLiabilities',
  'otherNonCurrentAssets',
  'otherNonCashItems',
  'otherLiabilities',
  'otherInvestingActivites',
  'otherFinancingActivites',
  'otherExpenses',
  'otherCurrentLiabilities',
  'otherCurrentAssets',
  'otherAssets',
  'operatingProfitMarginTTM',
  'operatingIncomeRatio',
  'operatingIncome',
  'operatingExpenses',
  'operatingCycleTTM',
  'operatingCashFlowSalesRatioTTM',
  'operatingCashFlow',
  'netReceivables',
  'netProfitMarginTTM',
  'netIncomeRatio',
  'netIncomePerShareTTM',
  'netIncomePerEBTTTM',
  'netIncome',
  'netDebtToEBITDATTM',
  'netDebt',
  'netCurrentAssetValueTTM',
  'netChangeInCash',
  'netCashUsedProvidedByFinancingActivities',
  'netCashUsedForInvestingActivites',
  'netCashProvidedByOperatingActivites',
  'marketCapTTM',
  'longTermInvestments',
  'longTermDebtToCapitalizationTTM',
  'longTermDebt',
  'investmentsInPropertyPlantAndEquipment',
  'investedCapitalTTM',
  'interestExpense',
  'interestDebtPerShareTTM',
  'intangiblesToTotalAssetsTTM',
  'intangibleAssets',
  'index',
  'incomeTaxExpense',
  'incomeQualityTTM',
  'incomeBeforeTaxRatio',
  'incomeBeforeTax',
  'grossProfitRatio',
  'grossProfitMarginTTM',
  'grossProfit',
  'grahamNumberTTM',
  'grahamNetNetTTM',
  'goodwillAndIntangibleAssets',
  'goodwill',
  'GeneralAndAdministrativeExpenses',
  'freeCashFlowYieldTTM',
  'freeCashFlowOperatingCashFlowRatioTTM',
  'freeCashFlow',
  'fixedAssetTurnoverTTM',
  'evToSalesTTM',
  'evToOperatingCashFlowTTM',
  'evToFreeCashFlowTTM',
  'EPSDiluted',
  'EPS',
  'enterpriseValueTTM',
  'enterpriseValueOverEBITDATTM',
  'enterpriseValueMultipleTTM',
  'effectOfForexChangesOnCash',
  'effectiveTaxRateTTM',
  'ebtPerEbitTTM',
  'ebitPerRevenueTTM',
  'EBITDARatio',
  'EBITDA',
  'earningsYieldTTM',
  'dividendYielTTM',
  'dividendYielPercentageTTM',
  'dividendYieldPercentageTTM',
  'dividendsPaid',
  'dividendPaidAndCapexCoverageRatioTTM',
  'deferrredTaxLiabilitiesNonCurrent',
  'deferredRevenueNonCurrent',
  'deferredRevenue',
  'deferredIncomeTax',
  'debtToMarketCapTTM',
  'debtToEquityTTM',
  'debtToAssetsTTM',
  'debtRepayment',
  'debtRatioTTM',
  'debtEquityRatioTTM',
  'daysSalesOutstandingTTM',
  'daysPayablesOutstandingTTM',
  'daysOfSalesOutstandingTTM',
  'daysOfPayablesOutstandingTTM',
  'daysOfInventoryOutstandingTTM',
  'daysOfInventoryOnHandTTM',
  'date',
  'costOfRevenue',
  'costAndExpenses',
  'companyEquityMultiplierTTM',
  'commonStockRepurchased',
  'commonStockIssued',
  'commonStock',
  'changeInWorkingCapital',
  'cashRatioTTM',
  'cashFlowToDebtRatioTTM',
  'cashFlowCoverageRatiosTTM',
  'cashConversionCycleTTM',
  'cashAtEndOfPeriod',
  'cashAtBeginningOfPeriod',
  'cashAndShortTermInvestments',
  'cashAndCashEquivalents',
  'capitalExpenditureCoverageRatioTTM',
  'capitalExpenditure',
  'capexToRevenueTTM',
  'capexToOperatingCashFlowTTM',
  'capexToDepreciationTTM',
  'capexPerShareTTM',
  'calendarYear',
  'bookValuePerShareTTM',
  'averageReceivablesTTM',
  'averagePayablesTTM',
  'averageInventoryTTM',
  'assetTurnoverTTM',
  'acquisitionsNet',
  'accumulatedOtherComprehensiveIncomeLoss',
  'accountsReceivables',
  'accountsPayables',
  'accountPayables',
  'acceptedDate',
  'dividendPerShareTTM',
  'currentRatioTTM',
  'interestCoverageTTM',
  'AltmanZScore',
  'BookValuePerShareTTM',
  'CapexPerShareTTM',
  'CapexToDepreciationTTM',
  'CapexToOperatingCashFlowTTM',
  'CapexToRevenueTTM',
  'CashAndCashEquivalents',
  'CashAndShortTermInvestments',
  'CashConversionCycleTTM',
  'CashFlowCoverageRatiosTTM',
  'CashFlowToDebtRatioTTM',
  'CashPerShareTTM',
  'Changes',
  'CommonStock',
  'CommonStockIssued',
  'CommonStockRepurchased',
  'CompanyEquityMultiplierTTM',
  'CurrentRatioTTM',
  'DCF',
  'DCF_diff',
  'DebtEquityRatioTTM',
  'DebtRatioTTM',
  'DebtToAssetsTTM',
  'DebtToEquityTTM',
  'DebtToMarketCapTTM',
  'DeferredIncomeTax',
  'DeferredRevenue',
  'DeferredRevenueNonCurrent',
  'DividendPerShareTTM',
  'DividendPaidAndCapexCoverageRatioTTM',
  'DividendYielTTM',
  'DividendYieldPercentageTTM',
  'EarningsYieldTTM',
  'EBITDA',
  'EBITDARatiooperatingIncome',
  'EffectiveTaxRateTTM',
  'EnterpriseValueMultipleTTM',
  'EnterpriseValueOverEBITDATTM',
  'EnterpriseValueTTM',
  'EPS',
  'EPSDiluted',
  'FreeCashFlow',
  'FreeCashFlowOperatingCashFlowRatioTTM',
  'FreeCashFlowPerShareTTM',
  'FiveYDividendperShareGrowthPerShare',
  'FiveYNetIncomeGrowthPerShare',
  'FiveYOperatingCFGrowthPerShare',
  'FiveYRevenueGrowthPerShare',
  'FixedAssetTurnoverTTM',
  'GrossProfit',
  'GrossProfitMarginTTM',
  'GrossProfitRatio',
  'GrahamNetNetTTM',
  'GrahamNumberTTM',
  'GrowthAccountsPayables',
  'GrowthAccountsReceivables',
  'GrowthAcquisitionsNet',
  'GrowthCashAndCashEquivalents',
  'GrowthCashAndShortTermInvestments',
  'GrowthCashAtBeginningOfPeriod',
  'GrowthCashAtEndOfPeriod',
  'GrowthChangeInWorkingCapital',
  'GrowthCommonStock',
  'GrowthCommonStockIssued',
  'GrowthCommonStockRepurchased',
  'GrowthCostAndExpenses',
  'GrowthCostOfRevenue',
  'GrowthDeferredRevenue',
  'GrowthDeferredRevenueNonCurrent',
  'GrowthDebtRepayment',
  'GrowthDividendsPaid',
  'GrowthEBITDA',
  'GrowthEBITDARatio',
  'GrowthEPS',
  'GrowthEPSDiluted',
  'GrowthEffectOfForexChangesOnCash',
  'GrowthFreeCashFlow',
  'GrowthGrossProfit',
  'GrowthGrossProfitRatio',
  'GrowthIncomeBeforeTax',
  'GrowthIncomeBeforeTaxRatio',
  'GrowthIncomeTaxExpense',
  'GrowthInterestExpense',
  'GrowthInvestmentsInPropertyPlantAndEquipment',
  'GrowthLongTermDebt',
  'GrowthNetCashProvidedByOperatingActivites',
  'GrowthNetCashUsedForInvestingActivites',
  'GrowthNetCashUsedProvidedByFinancingActivities',
  'GrowthNetChangeInCash',
  'GrowthNetDebt',
  'GrowthNetIncome',
  'GrowthOperatingExpenses',
  'GrowthOperatingIncome',
  'GrowthOperatingIncomeRatio',
  'GrowthOtherCurrentAssets',
  'GrowthOtherCurrentLiabilities',
  'GrowthOtherExpenses',
  'GrowthOtherFinancingActivites',
  'GrowthOtherInvestingActivites',
  'GrowthOtherLiabilities',
  'GrowthOtherNonCashItems',
  'GrowthOtherNonCurrent',
]
