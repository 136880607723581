import styles from './TOS_PP.module.scss'
import { Helmet } from 'react-helmet-async'
import { NavBar } from 'pages/HomePage/HomePageComponents/NavBar/NavBar'

export const PrivacyPolicy = () => {
  return (
    <>
      <NavBar />
      <div className={styles.backgroundWrapper}>
        <Helmet>
          <title>{`Outseek | Privacy Policy`}</title>
        </Helmet>
        <div className={styles.title}>Outseek Privacy Policy</div>
        <div className={styles.lastUpdated}>
          Last updated: February 28th, 2024.
        </div>
        <article className={styles.TOS_PP}>
          <h1>1. OVERVIEW</h1>
          <p>
            At OUTSEEK FINANCE INC. (the “Company”, “we” or “us”), we use our
            website and other internet-based tools for the following purpose:
            <br />
            <br />
            <i>
              Our website provides a financial research and analysis terminal,
              offering users access to financial data, market insights, and
              analytical tools. We also offer subscription-based access to
              premium data and exclusive tools designed for in-depth financial
              analysis.
            </i>
            We are required to collect and retain information about the people
            and companies that access and use the website (“Users” or “you”).
            This Policy applies to our privacy practices on all communications
            between the Company and Users, including but not limited to
            communication via:
            <br />
            <br />
            <ol>
              <li>
                the Company website (including the website hosted at
                https://outseek.ca/ and any other web domain used by the Company
                to communicate with users now or in the future);
              </li>
              <li>
                any mobile, computer, or tablet-based application currently
                offered by Company or developed in the future by the Company;
                and
              </li>
              <li>
                all other services provided by the Company, as described on the
                Website (Items 1 to 3 are collectively referred to as the
                "Website").
              </li>
            </ol>
            This Privacy Policy (“Policy”) sets out how we use, protect, collect
            and preserve information submitted by Users, including User
            information gathered by our technology. This Policy should be read
            in conjunction with any Terms of Use or policies available on the
            Website.
            <br />
            <br />
            By accessing or submitting any information through the Website, you
            are agreeing to the terms of this Policy. You are prohibited from
            accessing or using the Website unless you fully understand and agree
            to this Policy and all Terms of Use available for review on the
            Website. If you have any questions about this Policy, please contact
            us by sending an email with your questions to business@outseek.ca
            before accessing or entering any information on the Website.
          </p>

          <h1>2. INFORMATION ABOUT USERS WE COLLECT AND STORE</h1>
          <p>
            When you choose to create an account on the Website, we will collect
            and retain all personal identifying information submitted to us. We
            will take steps to protect the information submitted to us by Users
            and we will not disclose any personal identifying information
            without the consent of Users, unless otherwise set out in this
            Policy. Personal identifying information may include but is not
            necessarily limited to:
            <p>Full name, Email Address and Occupation.</p>
            We will not use personal identifying information to contact you for
            any reason unrelated to your use of the Website or for commercial
            reasons unrelated to the business of the Company. Except with your
            consent or where required by law, we will not provide personal
            identifying information to any third party.
          </p>

          <h1>3. ADDITIONAL DATA COLLECTED</h1>
          <p>
            To learn about your experience as a User and to make periodic
            improvements to the Website and our business, we may collect and
            retain certain information. We will automatically record information
            about your activity on the Website. This may include your Internet
            Protocol (IP) address, operating system, geolocation information,
            locale preferences, identification numbers associated with your
            computers and mobile devices, your mobile carrier, date and time
            stamps associated with transactions, system configuration
            information, metadata, and other electronic interactions with the
            Company and the Website.
          </p>

          <h1>4. PERMITTED USES OF DATA</h1>
          <p>
            We use data collected from the Website for various purposes,
            including to: provide the Website to you and accompanying customer
            support; notify you about updates and changes to the Website;
            prevent and address technical issues with the Website; monitor usage
            of the Website. Without restriction, we may use any information you
            submit through the Website for any purpose we deem appropriate so
            long as it is anonymous and stripped of any and all personal
            identifying information. We may use the data collected from the
            Website, devices, browsers, and any other sources, for its own
            purposes, both related to and unrelated to improving the Website.
          </p>

          <h1>
            5. LEGAL BASIS FOR PROCESSING PERSONAL IDENTIFYING INFORMATION
          </h1>
          <p>
            We may process your personal identifying information for a number of
            reasons, including:
            <br />
            <br />
            <ul>
              <li>performing a contract with you relating to the Website;</li>
              <li>you have provided us consent to do so; </li>
              <li>fulfilling a legal obligation; </li>
              <li>
                fulfilling our legitimate interests, which are not overridden by
                your rights;
              </li>
              <li>for payment processing purposes </li>
            </ul>
          </p>

          <h1>6. ACCESSING, CORRECTING & DELETING YOUR PERSONAL INFORMATION</h1>
          <p>
            If you wish to access or amend your personal information or to
            request that we permanently delete your personal information from
            our servers, please send an email to business@outseek.ca. We will
            ensure your personal information is accessible, amended and/or
            deleted in a reasonable time after receiving that request. Deleting
            such information may require us to terminate any account you may
            have created in its entirety and prevent you from using the Website.
            <br />
            <br />
            So long as your account remains active, we will preserve your
            personal information in accordance with the Policy.
            <br />
            <br />
            You may decline to share certain personal information with us, in
            which case you may not be able to use the Website, either in its
            entirety or certain features.
            <br />
            <br />
            When deleting your information, whether by request or due to
            inactivity, we will use standard electronic means to remove your
            personal information from our files.
            <br />
            <br />
            We will also retain any and all information that we are required to
            retain under any applicable laws for the full duration of time
            required under those laws.
          </p>

          <h1>7. KEEPING YOUR DATA SECURE</h1>
          <p>
            We will store your information in electronic format on
            industry-standard servers that we have selected. Except where
            required to do so by law, we will not store your personal
            information in any physical or paper-based format.
            <br />
            <br />
            The servers we select to store information engage security software
            and generally accepted standards to protect personal information.
            The software used by these servers is intended to prevent
            unauthorized access or improper use.
            <br />
            <br />
            However, no security system is perfect and there is always a risk of
            unauthorized parties accessing your personal information. Therefore,
            we make no guarantees or representations as to the technical or
            legal compliance of the servers we use. We cannot guarantee the
            complete protection and security of your personal information.
            <br />
            <br />
            Our servers may be located outside of Canada, and thus are
            potentially subject to the privacy and security laws in force in
            other countries. If you have concerns about your personal
            information being stored electronically in foreign jurisdictions,
            please contact us before proceeding to use the Website.
          </p>

          <h1>8. SECURITY BREACHES</h1>
          <p>
            In the event the personal information of a User is disclosed as the
            result of any breach of security of the Website or its servers,
            regardless of who is at fault, we will take steps to advise all
            known affected Users within a reasonable timeframe of learning of
            the breach.
          </p>

          <h1>9. THIRD-PARTY APPLICATIONS</h1>
          <p>
            To provide an enhanced experience to Users, including to track
            conversions and analytics, we may engage services and features
            controlled by third-parties. We cannot control or dictate how any
            third-party application you use in conjunction with the Website, now
            or at any point in the future, will treat any personal information
            you transmit through their servers when using the Website. We advise
            you to contact these parties directly to review their privacy
            policies.
            <br />
            <br />
            Without limitation, the Company uses or may use the following
            third-party applications and services in conjunction with the
            Website:
            <p>Google Analytics</p>
            By using any of these third-party applications to interact with the
            Website, we will not be given access to your login credentials, user
            name, password, payment information, or any information you may
            provide directly to these applications.
          </p>

          <h1>10. USE OF WEBSITE BY MINORS</h1>
          <p>
            The Website is not intended to be accessed by persons under the age
            of 18. The Company does not engage in any marketing efforts aimed at
            children or minors.
            <br />
            <br />
            Persons under the age of 18 are prohibited from using the Website in
            any manner or providing any information to the Company. In the event
            the Company is provided with any information, including personal
            identifying information, relating to a minor, that information will
            be immediately deleted without notice.
          </p>

          <h1>11. DISCLOSURE OF PERSONAL INFORMATION</h1>
          <p>
            In certain situations, we may be required to disclose personal data
            in response to lawful requests by public authorities, including to
            meet national security or law enforcement requirements.
            <br />
            <br />
            We may disclose your personal information to third parties:
            <ol>
              <li>
                In the event that we sell or buy any business or assets, in
                which case we may disclose your personal data to the prospective
                seller or buyer of such business or assets.
              </li>
              <li>
                If the Company or its assets are acquired by a third party, in
                which case personal data about Users may be one of the
                transferred assets.
              </li>
              <li>
                If we are under a duty to disclose or share your personal data
                in order to comply with any legal obligation, or in order to
                enforce or apply our Terms of Use; or to protect the rights,
                property, or safety of the Company, our Users, or others. This
                includes exchanging information with other companies and
                organizations for the purposes of fraud protection and credit
                risk reduction.
              </li>
            </ol>
          </p>

          <h1>12. AMENDING THIS POLICY</h1>
          <p>
            This Policy may be updated and amended from time to time. We reserve
            the right to change this Policy at any time, and any amended Policy
            is effective upon posting to the Website. We will make efforts to
            communicate any changes to this Policy we deem material, in our sole
            discretion, via email or notifications on the Website. Your
            continued use of the Website will be deemed acceptance of any
            amended Policy.
          </p>

          <h1>13. CONTACT US</h1>
          <p>
            We encourage you to send us questions and inquiries on this Policy
            and the steps we take to keep your personal information secure.
            Please send us an email: business@outseek.ca
            <br />
            <br />
            We will respond to all inquiries on the Policy within 7 days.
          </p>
        </article>
      </div>
    </>
  )
}
