import React from 'react'
import { Tooltip } from '@mui/material'

export const MenuToolTip = ({
  label,
  renderConfigMapTooltip,
  handleToggleExpanded,
  iconSrc,
}) => {
  return (
    <Tooltip
      title={renderConfigMapTooltip()}
      interactive
      placement="right"
      TransitionProps={{ timeout: 0 }}
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: 'var(--background-secondary)',
            position: 'relative',
            borderRadius: '4px',
            padding: '6px',
            maxWidth: '600px',
            zIndex: 100000000,
          },
        },
      }}
      PopperProps={{
        popperOptions: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -20],
              },
            },
          ],
        },
      }}
    >
      <div
        className="ExpandableMenuItem"
        aria-label={label}
        onClick={handleToggleExpanded}
        title={label}
        style={{ color: 'var(--white)' }}
      >
        <div
          style={{
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
          }}
        >
          {iconSrc && iconSrc}
        </div>
      </div>
    </Tooltip>
  )
}
