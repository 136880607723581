import React from 'react'
import styles from './Desc.module.scss'
export const Desc = ({ entry }) => {
  return (
    <>
      <div className={styles.topContainer}>
        <p className={styles.titleDesc}>{entry.title} </p>
      </div>
      <div className={styles.bottomContainer}>
        <div className={styles.leftPaneDesc}>
          <p className={styles.leftPaneItem}>Type:</p>
          <p className={styles.leftPaneItem}>{entry.type} </p>
          <p className={styles.leftPaneItem}>Category:</p>
          <p className={styles.leftPaneItem}>{entry.category} </p>
        </div>
        <div className={styles.rightPaneDesc}>
          <p className={styles.descParagraph}>{entry.description}</p>{' '}
        </div>
      </div>
    </>
  )
}
