import dayjs from 'dayjs'
import numeral from 'numeral'
import { UilArrowRight } from '@iconscout/react-unicons'

export const splitColumns = [
  {
    Header: 'Date',
    accessor: 'date',
    width: 110,
    Cell: Cell => {
      return <span> {dayjs(Cell.value).format('MMM D, YYYY')}</span>
    },
  },
  {
    Header: 'Ratio',
    accessor: 'denominator',
    width: 70,
    Cell: Cell => {
      const numerator = Cell?.row?.original?.numerator
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {numerator} - for -
          {/* <UilArrowRight size={20} color={'var(--primary-color)'} /> */}
          {Cell.value}
        </div>
      )
    },
  },
]

export const keyExecutivesColumns = [
  {
    Header: 'Employee Count',
    accessor: 'name',
    width: 'fit-content',
  },
  {
    Header: 'Pay',
    accessor: 'pay',
    width: 70,
    Cell: Cell => {
      if (Cell.value === null || Cell.value === 0) return '-'
      return <span>{numeral(Cell.value).format('0.00a')}</span>
    },
  },
  {
    Header: 'Title',
    accessor: 'title',
    width: '100%',
  },
  // {
  //   Header: 'Gender',
  //   accessor: 'gender',
  // },
  // {
  //   Header: 'Year Born',
  //   accessor: 'yearBorn',
  //   width: 'fit-content',
  //   Cell: Cell => {
  //     if (Cell.value === null) return '-'
  //     return <span>{Cell.value}</span>
  //   },
  // },
  // {
  //   Header: 'Title Since',
  //   accessor: 'titleSince',
  // },
]
