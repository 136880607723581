import numeral from 'numeral'
import { rgba } from 'polished'
import React from 'react'

export const economicColumns = [
  {
    Header: 'Time',
    accessor: 'date',
    width: 'fit-content',
    disableGroupBy: true,
    Cell: Cell => <div>{Cell.row.original.time}</div>,
  },
  {
    Header: 'Country',
    accessor: 'country',
    width: 'fit-content',
    disableGroupBy: true,
    Cell: Cell => {
      const value = Cell.value
      const currency = Cell.row.original.currency
      return (
        <div>
          {value} ({currency})
        </div>
      )
    },
  },
  {
    Header: 'Event',
    accessor: 'event',
    width: 'fit-content',
    disableGroupBy: true,
    Search: true,
  },
  {
    Header: 'Previous',
    accessor: 'previous',
    width: 'fit-content',
    disableGroupBy: true,
    Cell: Cell => {
      const value = Cell.value
      const unit = Cell.row.original.unit
      if (value === null || value === undefined || value === '') return '-'

      return (
        <span>
          {numeral(value).format('0,0.000')}
          {unit}
        </span>
      )
    },
  },
  {
    Header: 'Estimate',
    accessor: 'estimate',
    width: 'fit-content',
    disableGroupBy: true,
    Cell: Cell => {
      const value = Cell.value
      const unit = Cell.row.original.unit
      if (value === null || value === undefined || value === '') return '-'

      return (
        <span>
          {numeral(value).format('0,0.000')}

          {unit}
        </span>
      )
    },
  },
  {
    Header: 'Actual',
    accessor: 'actual',
    width: 'fit-content',
    disableGroupBy: true,
    Cell: Cell => {
      const value = Cell.value
      const unit = Cell.row.original.unit
      if (value === null || value === undefined || value === '') return '-'

      return (
        <span>
          {numeral(value).format('0,0.000')}

          {unit}
        </span>
      )
    },
  },
  {
    Header: 'Change',
    accessor: 'change',
    width: 'fit-content',
    disableGroupBy: true,
    Cell: Cell => {
      const change = Cell.value
      const changePercentage = Cell.row.original.changePercentage
      if (change === null || change === undefined || change === '') return '-'
      return (
        <div
          style={{
            color: Cell.row.original.change > 0 ? 'var(--green)' : 'var(--red)',
          }}
        >
          {change} ({numeral(changePercentage).format('0.0')}%)
        </div>
      )
    },
  },
  {
    Header: 'Impact',
    accessor: 'impact',
    width: 'fit-content',
    disableGroupBy: true,
    Cell: Cell => {
      const impact = Cell.value
      if (impact === null || impact === undefined || impact === '') return '-'

      const getColor = impact => {
        if (impact === 'Low') return '#21ad3d'
        if (impact === 'Medium') return '#fff466'
        if (impact === 'High') return '#f84f31'
        return '#ffffff'
      }

      const getBGColor = rgba(getColor(impact), 0.3)

      return (
        <div
          style={{
            backgroundColor: getBGColor,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '4px',
          }}
        >
          <span style={{ color: getColor(impact) }}>{impact}</span>
        </div>
      )
    },
  },
]
