import { useURLSyncedGlobalTicker } from 'pages/views/hooks'
import { fetches } from './queries'
import { useQuery } from 'react-query'
import { useMemo } from 'react'
import { interpretFilingType } from '../utils'

export const useDataSource = () => {
  const { handleSetTicker, ticker } = useURLSyncedGlobalTicker()

  const { data: secFilings, isLoading: secFilingsIsLoading } = useQuery(
    ['sec-filings', ticker],
    fetches.fetchSecData
  )

  const secFilingsData = useMemo(() => {
    return secFilings?.map(filing => ({
      ...filing,
      typeInterpreted: interpretFilingType(filing?.type),
    }))
  }, [secFilings])

  const recentKeyFilings = useMemo(() => {
    const filingTypes = ['10-K', '8-K', '10-Q', '4', 'ARS']
    const filteredFilings = {}

    filingTypes.forEach(type => {
      const mostRecentFiling = secFilingsData
        ?.filter(f => f.type === type)
        .sort((a, b) => new Date(b.filingDate) - new Date(a.filingDate))[0]
      if (mostRecentFiling) {
        filteredFilings[type] = mostRecentFiling
      }
    })

    return filteredFilings
  }, [secFilingsData])

  const statistics = useMemo(() => {
    const oneYearAgo = new Date()
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1)

    const filingsInLastYear =
      secFilingsData?.filter(
        filing => new Date(filing.fillingDate) > oneYearAgo
      ) || []

    const filingTypeCounts = filingsInLastYear.reduce((acc, { type }) => {
      acc[type] = (acc[type] || 0) + 1
      return acc
    }, {})

    const mostCommonFiling = Object.entries(filingTypeCounts).reduce(
      (a, b) => (a[1] > b[1] ? a : b),
      [null, 0]
    )

    const stats = {
      'Number of 8-K filings TTM': filingTypeCounts['8-K'] || 0,
      'Number of form 4 Filings TTM': filingTypeCounts['4'] || 0,
      'Most common filings': mostCommonFiling[0],
      'Most common filing count': mostCommonFiling[1],
    }

    return stats
  }, [secFilingsData])

  const SEC_UI = useMemo(() => {
    return {
      secFilings: secFilingsData,
      recentKeyFilings,
      secFilingsIsLoading,
      statistics,
    }
  }, [secFilingsData, recentKeyFilings, secFilingsIsLoading, statistics])

  return {
    SEC_UI,
    handleSetTicker,
    ticker,
  }
}
