export const interpretFilingType = type => {
  switch (type) {
    case '4':
      return 'Statement of Changes in Beneficial Ownership'
    case 'ARS':
      return 'Annual Report to Shareholders'
    case 'DEFA14A':
    case 'DEF 14A':
    case 'PRE 14A':
      return 'Proxy Statement'
    case '10-K':
      return 'Annual Report'
    case 'DFAN14A':
      return 'Definitive Additional Materials'
    case '13F-HR':
    case '13F-HR/A':
      return 'Quarterly Report Filed by Institutional Managers'
    case 'SC 13G':
    case 'SC 13G/A':
      return 'Statement of Ownership'
    case '3':
    case '3/A':
    case '5':
      return 'Initial Statement of Beneficial Ownership of Securities'
    case '8-K':
    case '8-K/A':
      return 'Current Report'
    case '10-Q':
      return 'Quarterly Report'
    case 'SC TO-I':
    case 'SC TO-I/A':
    case 'SC TO-T':
    case 'SC TO-T/A':
    case 'SC TO-C':
      return 'Tender Offer'
    case '11-K':
      return 'Annual Report of Employee Stock Purchase'
    case '144':
      return 'Form 144: Notice of Proposed Sale of Securities'
    case 'SD':
      return 'Specialized Disclosure Report'
    case 'PX14A6G':
      return 'Proxy Soliciting Material'
    case 'S-3ASR':
      return 'Automatic Shelf Registration Statement'
    case 'S-8':
    case 'S-8 POS':
      return 'Securities Registration Statement'
    case '25-NSE':
      return 'Notice of Removal for Matured Securities'
    case '13F-NT':
      return 'Quarterly Report Filed by Institutional Managers, Notice'
    case 'UPLOAD':
    case 'CORRESP':
      return 'Correspondence'
    case '424B2':
    case '424B3':
    case '424B5':
      return 'Prospectus'
    case 'FWP':
      return 'Free Writing Prospectus'
    case '15-15D':
      return 'Notice of Suspension of Duty to Report'
    case 'NO ACT':
      return 'No Action Letter'
    case 'CERTNYS':
      return 'Certification by the New York Stock Exchange'
    case '8-A12B':
      return 'Registration of Securities'
    case 'SC 13D':
      return 'Statement of Acquisition'
    default:
      return '-'
  }
}
