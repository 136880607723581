import { useQuery } from 'react-query'
import { useMemo } from 'react'
import queries from './queries'
import { useURLSyncedGlobalTicker } from 'pages/views/hooks'

export const useDataSource = () => {
  const { handleSetTicker, ticker } = useURLSyncedGlobalTicker()

  const { data: outlookData, isLoading: outlookDataIsLoading } = useQuery(
    ['outlook-data', ticker],
    queries?.fetchCompanyData
  )

  const { data: ETFInfo, isLoading: ETFInfoIsLoading } = useQuery(
    ['etf-info', ticker],
    queries?.fetchETFInfo
  )

  const { data: ETFHoldingsData, isLoading: ETFHoldingsIsLoading } = useQuery(
    ['etf-holdings', ticker],
    queries?.fetchETFHoldings
  )

  const { data: ETFHolders, isLoading: ETFHoldersIsLoading } = useQuery(
    ['etf-holders', ticker],
    queries?.fetchETFHolders
  )

  const ETFHoldings = useMemo(() => {
    if (ETFHoldingsData) {
      return ETFHoldingsData?.sort((a, b) => b?.pctVal - a?.pctVal)
    }
    return []
  }, [ETFHoldingsData])

  const currentMarketValue = 100
  const threshold = 30

  const pieDataHolders = useMemo(() => {
    if (ETFHolders && currentMarketValue) {
      const topList = ETFHolders.slice(0, threshold).map(value => ({
        value: value?.weightPercentage,
        label: value?.asset,
      }))

      let otherInstitutionalValue = ETFHolders.slice(threshold).reduce(
        (acc, value) => {
          return acc + (value?.weightPercentage || 0)
        },
        0
      )

      const topListReduce = topList.reduce(
        (acc, item) => acc + (item?.value || 0),
        0
      )

      let totalInstitutionalValue = topListReduce + otherInstitutionalValue

      const discrepancy = currentMarketValue - totalInstitutionalValue
      if (discrepancy > 0) {
        otherInstitutionalValue += discrepancy
      }

      const othersAggregate = {
        value: otherInstitutionalValue,
        label: 'Other Holders',
      }

      return [...topList, othersAggregate]
    }
    return []
  }, [ETFHolders, currentMarketValue])

  const pieDataHoldings = useMemo(() => {
    if (ETFHoldings && currentMarketValue) {
      const topList = ETFHoldings.slice(0, threshold).map(value => ({
        value: value?.pctVal,
        label: value?.name,
      }))

      let otherInstitutionalValue = ETFHoldings.slice(threshold).reduce(
        (acc, value) => {
          return acc + (value?.weightPercentage || 0)
        },
        0
      )

      const topListReduce = topList.reduce(
        (acc, item) => acc + (item?.value || 0),
        0
      )

      let totalInstitutionalValue = topListReduce + otherInstitutionalValue

      const discrepancy = currentMarketValue - totalInstitutionalValue
      if (discrepancy > 0) {
        otherInstitutionalValue += discrepancy
      }

      const othersAggregate = {
        value: otherInstitutionalValue,
        label: 'Other Holdings',
      }

      return [...topList, othersAggregate]
    }
    return []
  }, [ETFHoldings, currentMarketValue])

  const TreeMapHoldings = useMemo(() => {
    return {
      name: 'Holdings',
      children: pieDataHoldings,
    }
  }, [pieDataHoldings])

  const TreeMapHolders = useMemo(() => {
    return {
      name: 'Holders',
      children: pieDataHolders,
    }
  }, [pieDataHolders])

  const ETF_UI = useMemo(() => {
    return {
      outlookData,
      outlookDataIsLoading,
      ETFInfo,
      ETFInfoIsLoading,
      ETFHoldings,
      ETFHoldingsIsLoading,
      ETFHolders,
      ETFHoldersIsLoading,
      pieDataHolders,
      pieDataHoldings,
      TreeMapHoldings,
      TreeMapHolders,
    }
  }, [
    outlookData,
    outlookDataIsLoading,
    ETFInfo,
    ETFInfoIsLoading,
    ETFHoldings,
    ETFHoldingsIsLoading,
    ETFHolders,
    ETFHoldersIsLoading,
    pieDataHolders,
    pieDataHoldings,
    TreeMapHoldings,
    TreeMapHolders,
  ])

  return {
    ETF_UI,
    handleSetTicker: handleSetTicker,
    ticker,
  }
}
