import { useState } from 'react'
import styles from './TopNavBar.module.scss'

const ALL = 'All'
const COMPONENTS = 'Components'
const WIDGETS = 'Widgets'
const TOOLS = 'Tools'

const TopNavBar = ({ setSelection }) => {
  const [selectedOption, setSelectedOption] = useState(ALL)
  return (
    <>
      <div className={styles.navBarContainer}>
        <div className={styles.navBar}>
          <div
            className={styles.navItem}
            style={
              selectedOption === ALL
                ? { backgroundColor: 'var(--grey-accent)' }
                : null
            }
            onClick={() => setSelection(0)}
          >
            <div
              className={styles.navItemActive}
              onClick={() => setSelectedOption(ALL)}
            >
              <p className={styles.text}>{ALL}</p>
            </div>
          </div>
          <div
            className={styles.navItem}
            style={
              selectedOption === COMPONENTS
                ? { backgroundColor: 'var(--grey-accent)' }
                : null
            }
            onClick={() => setSelection(1)}
          >
            <div
              className={styles.navItemActive}
              onClick={() => setSelectedOption(COMPONENTS)}
            >
              <p className={styles.text}>{COMPONENTS}</p>
            </div>
          </div>
          <div
            className={styles.navItem}
            style={
              selectedOption === WIDGETS
                ? { backgroundColor: 'var(--grey-accent)' }
                : null
            }
            onClick={() => setSelection(2)}
          >
            <div
              className={styles.navItemActive}
              onClick={() => setSelectedOption(WIDGETS)}
            >
              <p className={styles.text}>{WIDGETS}</p>
            </div>
          </div>
          <div
            className={styles.navItem}
            style={
              selectedOption === TOOLS
                ? { backgroundColor: 'var(--grey-accent)' }
                : null
            }
            onClick={() => setSelection(3)}
          >
            <div
              className={styles.navItemActive}
              onClick={() => setSelectedOption(TOOLS)}
            >
              <p className={styles.text}>{TOOLS}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TopNavBar
