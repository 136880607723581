import styles from '../../styles.module.scss'
import { QuoteBar } from 'core/QuoteBar/QuoteBar'
import { NoData } from 'core/NoData/NoData'
import { Helmet } from 'react-helmet-async'
import TooltipTicker from 'core/TickerOverlay/TickerOverlay'

export const NotETF = ({ ticker, handleSetTicker }) => {
  const popularETFs = [
    'SPY', // SPDR S&P 500 ETF Trust
    'VTI', // Vanguard Total Stock Market ETF
    'QQQ', // Invesco QQQ Trust
    'IVV', // iShares Core S&P 500 ETF
    'VEA', // Vanguard FTSE Developed Markets ETF
    'EFA', // iShares MSCI EAFE ETF
    'VWO', // Vanguard FTSE Emerging Markets ETF
    'GLD', // SPDR Gold Shares ETF
    'VUG', // Vanguard Growth ETF
    'BND', // Vanguard Total Bond Market ETF
    'XLF', // Financial Select Sector SPDR Fund
    'VTV', // Vanguard Value ETF
    'IEMG', // iShares Core MSCI Emerging Markets ETF
    'XLK', // Technology Select Sector SPDR Fund
    'DIA', // SPDR Dow Jones Industrial Average ETF Trust
    'IWF', // iShares Russell 1000 Growth ETF
    'VOO', // Vanguard S&P 500 ETF
    'IWM', // iShares Russell 2000 ETF
  ]

  return (
    <div className={styles.etfWrapper}>
      <QuoteBar
        ticker={ticker}
        updateTicker={tickerIn => handleSetTicker(tickerIn)}
      />
      <Helmet title={`Outseek | ${ticker} is not an ETF`} />
      <div className={styles.motETFWrapper}>
        <NoData
          label={`'${ticker}' is not an ETF. Try some of these popular ETF's.`}
          fitContent={true}
        />
        <div className={styles.popularETFsWrapper}>
          <div className={styles.popularETFs}>
            {popularETFs?.map(item => (
              <div className={styles.tickerCenter}>
                <TooltipTicker
                  ticker={item}
                  customURLDirect={'/terminal/ETFInfo'}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
