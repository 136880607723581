import dayjs from 'dayjs'
import numeral from 'numeral'
import TooltipTicker from 'core/TickerOverlay/TickerOverlay'
import { UilSun, UilMoon } from '@iconscout/react-unicons'

export const earningsColumns = [
  {
    Header: '',
    accessor: 'date',
    width: 'fit-content',
    disableGroupBy: true,
    Cell: Cell => {
      return ''
    },
  },
  {
    Header: 'Symbol',
    accessor: 'symbol',
    width: 'fit-content',
    disableGroupBy: true,
    Search: true,
    Cell: Cell => {
      return <TooltipTicker ticker={Cell.value} />
    },
  },
  {
    Header: 'Time',
    accessor: 'time',
    disableGroupBy: true,
    width: 'fit-content',
    Cell: Cell => {
      const filteredTime =
        Cell.value === 'bmo'
          ? 'Before Market Open'
          : Cell.value === 'amc'
          ? 'After Market Close'
          : '-'
      return (
        <>
          <span>
            {filteredTime}{' '}
            {Cell.value === 'bmo' && (
              <UilSun color="#fff466" size="16" style={{ marginBottom: 3 }} />
            )}
            {Cell.value === 'amc' && (
              <UilMoon color="#729CDA" size="16" style={{ marginBottom: 3 }} />
            )}
          </span>
        </>
      )
    },
  },
  {
    Header: 'EPS',
    accessor: 'eps',
    disableGroupBy: true,
    width: 'fit-content',
    Cell: ({ value }) => {
      if (value === null || value === undefined || value === '') return '-'
      return <span>{numeral(value).format('0.00a')}</span>
    },
  },
  {
    Header: 'EPS Estimated',
    accessor: 'epsEstimated',
    disableGroupBy: true,
    width: 'fit-content',
    Cell: ({ value }) => {
      if (value === null || value === undefined || value === '') return '-'
      return <span>{numeral(value).format('0.00a')}</span>
    },
  },
  {
    Header: 'Revenue',
    accessor: 'revenue',
    disableGroupBy: true,
    width: 'fit-content',
    Cell: ({ value }) => {
      if (value === null || value === undefined || value === '') return '-'
      return <span>{numeral(value).format('0.00a')}</span>
    },
  },
  {
    Header: 'Revenue Estimated',
    accessor: 'revenueEstimated',
    disableGroupBy: true,
    width: 'fit-content',
    Cell: ({ value }) => {
      if (value === null || value === undefined || value === '') return '-'
      return <span>{numeral(value).format('0.00a')}</span>
    },
  },
  {
    Header: 'Fiscal Date Ending',
    accessor: 'fiscalDateEnding',
    disableGroupBy: true,
    width: 'fit-content',
    Cell: Cell => {
      return <span> {dayjs(Cell.value).format('MMM D')}</span>
    },
  },
]
