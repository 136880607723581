import http from '../../../../network/httpClient'

const fetchProfile = async ({ queryKey }) => {
  const [, ticker] = queryKey
  const data = await http.get(`papi/data/api/v3/profile/${ticker}`)
  return data.data.message
}

const fetchAnnualRatios = async ({ queryKey }) => {
  const [, ticker] = queryKey
  const data = await http.get(`papi/data/api/v3/ratios/${ticker}?limit=40&`)
  return data.data.message
}

export const fetches = {
  fetchProfile,
  fetchAnnualRatios,
}
