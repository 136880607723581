import React, { useState } from 'react'
import { useQueries } from 'react-query'
import { BasicTable } from 'core/Tables/BasicTable'
import { MarketMoverColumns } from '../../columns'
import { areQueriesDone } from '../../../NewsView/hooks/useDataSouce'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'core/Styles/TabStyles.scss'
import { TabStyles, movers } from './constants'
import styles from './MarketMover.module.scss'
import http from 'network/httpClient'
import Spinner from 'core/ScrollableContainer/Spinner/spinner'

const MarketMovers = () => {
  const [activeTab, setActiveTab] = useState(movers[0].label)

  const MarketMoversResponse = useQueries(
    movers.map(({ url, label }) => {
      return {
        queryKey: [label],
        queryFn: async () => {
          const data = await http.get(url)
          return data
        },
      }
    })
  )

  const done = areQueriesDone(MarketMoversResponse) === 0

  const handleTabChange = index => {
    setActiveTab(movers[index].label)
  }

  return done ? (
    <div className={styles.marketMoversWrapper}>
      <Tabs
        selectedTabClassName="selected-tab"
        style={TabStyles._Tabs}
        selectedIndex={movers.findIndex(mover => mover.label === activeTab)}
        onSelect={handleTabChange}
      >
        <div className="MarketMoverTabsContainer">
          <TabList style={TabStyles._TabList}>
            {movers.map((mover, index) => (
              <>
                <Tab key={mover.label} className="MarketMoverTabs">
                  {mover.label}
                </Tab>
                {index !== movers.length - 1 &&
                  (activeTab === mover.label ||
                  activeTab === movers[index + 1].label ? (
                    <div className="invisibleTabDivider" />
                  ) : (
                    <div className="TabDivider" />
                  ))}
              </>
            ))}
          </TabList>
        </div>
        {MarketMoversResponse.map((resp, i) => {
          return (
            <TabPanel style={TabStyles._TabPanel} key={movers[i].label}>
              <div className={styles.marketMoverTableWrapper}>
                <BasicTable
                  overflowXScroll
                  columns={MarketMoverColumns}
                  data={resp?.data?.data?.message || []}
                />
              </div>
            </TabPanel>
          )
        })}
      </Tabs>
    </div>
  ) : (
    <div className={styles.marketMoversWrapper}>
      <Spinner />
    </div>
  )
}

export default React.memo(MarketMovers)
