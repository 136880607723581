import numeral from 'numeral'
import _ from 'lodash'
import dayjs from 'dayjs'

export const toPercent = (decimal, fixed = 0) =>
  `${(decimal * 100).toFixed(0)}%`

export const getPercent = (value, total) => {
  const ratio = total > 0 ? value / total : 0

  return toPercent(ratio)
}

export const renderTooltipContent = o => {
  const { payload, label } = o
  const total = payload?.reduce((result, entry) => result + entry.value, 0)

  return (
    <div className="customized-tooltip-content">
      <p className="total">{`${dayjs(label).format(
        'MMM D, YYYY'
      )} (Total: ${numeral(total).format('0.000a')})`}</p>
      <div className="list">
        {payload?.map((entry, index) => (
          <div
            className="listItem"
            key={`item-${index}`}
            style={{ color: entry.color }}
          >
            {`${_.startCase(entry.name)}: ${numeral(entry.value).format(
              '0.000a'
            )} (${getPercent(entry.value, total)})`}
          </div>
        ))}
      </div>
    </div>
  )
}

export const renderLegend = props => {
  const { payload } = props

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        rowGap: '4px',
        columnGap: '12px',
        height: 46,
        width: 603,
        flexWrap: 'wrap',
        lineHeight: '18px',
        marginLeft: '16px',
        justifyContent: 'center',
      }}
    >
      {payload.map((entry, index) => (
        <div
          key={`item-${index}`}
          style={{
            color: entry?.color,
            whiteSpace: 'nowrap',
            fontSize: '13px',
          }}
        >
          {_.startCase(entry.value)}
        </div>
      ))}
    </div>
  )
}
