import styles from './TopBar.module.scss'
import focus from 'dashboard/DashboardTabs/assets/focus.svg'

import save from 'assets/save.svg'
import catalog from 'assets/catalog.svg'

import { saveDashboard } from 'redux/actions/app-actions'
import { useTapActionBar } from 'dashboard/DashboardTabs/hooks'
import { useDispatch } from 'react-redux'

import { useLocation } from 'react-router-dom'
import '../ToolMenu/feedbackstyles.css'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ChangeTheme } from 'theme/themeModal'

import CoreButton from 'core/Button/CoreButton'

export const TopBar = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const isDashboard = location.pathname.includes('/terminal/dashboard')

  const { handleShowComponentCatalog } = useTapActionBar()
  const dispatch = useDispatch()

  const navigateToUpgrade = () => {
    navigate('/terminal/upgrade')
  }

  const name = useSelector(state => state.currentDashboard)

  const toolbarButtons = [
    {
      icon: catalog,
      title: 'Item catalog',
      onClick: handleShowComponentCatalog,
      isVisible: isDashboard,
      label: 'Item Catalog',
    },
    {
      icon: save,
      title: 'Save Dashboard',
      onClick: () => dispatch(saveDashboard()),
      isVisible: isDashboard,
      label: 'Save',
    },
    {
      icon: focus,
      title: 'Toggle full screen',
      onClick: toggleFullScreen,
      isVisible: true,
    },
  ]

  const FeedbackButton = () => {
    const handleFeedbackClick = () => {
      if (window.doorbell) {
        window.doorbell.show()
      } else {
        console.error('Doorbell not loaded')
      }
    }

    return (
      <button onClick={handleFeedbackClick} className={styles.reportButton}>
        Report Issue
      </button>
    )
  }

  return (
    <div className={styles.upperDecker}>
      {isDashboard ? (
        <div className={styles.dashboardLabel}>Current Dashboard: {name}</div>
      ) : (
        <div />
      )}
      <div className={styles.buttonWrappers}>
        <CoreButton onClick={navigateToUpgrade} color={'var(--primary-color)'}>
          Upgrade
        </CoreButton>
        <ChangeTheme />
        <FeedbackButton />
        {toolbarButtons
          .filter(button => button.isVisible)
          .map(({ icon, title, onClick, label }) => (
            <ToolBarActionButton
              key={title}
              icon={icon}
              title={title}
              onClick={onClick}
              label={label}
            />
          ))}
      </div>
    </div>
  )
}

const ToolBarActionButton = ({ onClick, icon, title, label }) => {
  return (
    <div title={title} className={styles.focusDiv} onClick={onClick}>
      <img src={icon} className={styles.focusButton} alt={title} />
      {label && <div className={styles.focusSpan}>{label}</div>}
    </div>
  )
}

export const toggleFullScreen = () => {
  if (!document.fullscreenElement) {
    document.documentElement.requestFullscreen()
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen()
    }
  }
}
