import React from 'react'
import styles from './styles.module.scss'
import { NavBar } from 'pages/HomePage/HomePageComponents/NavBar/NavBar'
import { Footer } from 'pages/HomePage/HomePageComponents/Footer/HomePageFooter'
import { Helmet } from 'react-helmet-async'
import { roadmapItems } from './config'

const Roadmap = () => {
  return (
    <>
      <Helmet>
        <title>Outseek | AI Copilot Roadmap</title>
        <meta
          name="description"
          content="Explore the future developments planned for Outseek AI Copilot."
        />
      </Helmet>
      <NavBar />
      <div className={styles.roadmapContainer}>
        <div className={styles.headerSection}>
          <h1 className={styles.mainHeader}>Outseek Copilot Roadmap</h1>
          <p className={styles.subHeader}>
            Discover the previous and upcoming improvements for Outseek Copilot.
          </p>
        </div>
        <div className={styles.roadmap}>
          {Object.entries(roadmapItems).map(([version, item]) => (
            <div key={version} className={styles.roadmapSection}>
              <div className={styles.header}>
                <div className={styles.title}>{item?.title}</div>
                <div className={styles.date}>{item?.date}</div>
              </div>
              {item?.status && (
                <>
                  <div className={styles.description}>{item?.description}</div>
                  <div className={styles.roadmapDetails}>
                    <div className={styles.categoryWrapper}>
                      <div className={styles.categoryTitle}>Capabilities</div>
                      <ul>
                        {item?.capabilities?.map(capability => (
                          <li key={capability}>{capability}</li>
                        ))}
                      </ul>
                    </div>
                    <div className={styles.categoryWrapper}>
                      <div className={styles.categoryTitle}>Data Knowledge</div>
                      <ul>
                        {item?.dataKnowledge?.map(knowledge => (
                          <li key={knowledge}>{knowledge}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Roadmap
