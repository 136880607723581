import http from 'network/httpClient'

const fetchChartData = async ticker => {
  const response = await http.get(
    `papi/data/api/v3/historical-chart/30min/${ticker}?`
  )
  return response.data.message.reverse()
}

const fetchCompanyData = async ticker => {
  try {
    const url = `papi/data/api/v4/company-outlook?symbol=${ticker}`
    const response = await http.get(url)
    if (response.status === 200) {
      const { profile, metrics, ratios } = response.data.message
      return { profile, metrics, ratios }
    }
  } catch (error) {}
}

const fetchStockPrice = async ticker => {
  try {
    const url = `papi/data/api/v3/quote/${ticker}`
    const response = await http.get(url)
    if (response.status === 200) {
      return response.data.message[0]
    }
  } catch (error) {}
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  fetchChartData,
  fetchCompanyData,
  fetchStockPrice,
}
