import { CardWrapper } from 'core/CardWrapper/CardWrapper'
import Spinner from 'core/ScrollableContainer/Spinner/spinner'
import { Helmet } from 'react-helmet-async'
import CalendarTable from 'core/Tables/CalendarTable'
import { economicColumns } from './columns'
import { useEconomicCalendar } from '../../data/hooks'
import { Calendar } from '../../Componenets/Calendar'
import { EventComponent } from './CalComp'
import { useState } from 'react'
import { Switch } from 'core/Switch/Switch'
import { useCalContext } from 'pages/views/Calendars/CalContext'

export const Economic = () => {
  const { CAL_UI } = useEconomicCalendar()
  const { calType, setCalType, options } = useCalContext()

  return (
    <>
      <Helmet>
        <title>{`Outseek | Economic Calendar`}</title>
        <meta
          name="description"
          content={
            'Economic calendar provides a list of companies that are expected to release their economic reports.'
          }
        />
      </Helmet>
      <CardWrapper
        label={'Global Economic Calendar'}
        height={'100%'}
        width={'100%'}
        endButton={
          <Switch
            options={options}
            selectedOption={calType}
            onOptionChange={setCalType}
            forCardWrapper={true}
          />
        }
        content={
          CAL_UI?.econCalIsLoading ? (
            <Spinner />
          ) : calType === 'table' ? (
            <CalendarTable
              data={CAL_UI?.econCal}
              columns={economicColumns}
              overflowXScroll={true}
              filterColumnNames={['impact', 'country']}
            />
          ) : (
            <Calendar
              data={CAL_UI?.econCal}
              component={EventComponent}
              filterKeys={['country', 'impact']}
              searchKeys={['event']}
            />
          )
        }
      />
    </>
  )
}
