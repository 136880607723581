export const BALANCE_SHEET_BREAKDOWN_CHART = 'Balance Sheet Breakdown Chart'
export const CURRENT_ASSET_BREAKDOWN_CHART = 'Current Assets Breakdown Chart'
export const NON_CURRENT_ASSET_BREAKDOWN_CHART =
  'Non-Current Assets Breakdown Chart'
export const CURRENT_LIABILITIES_BREAKDOWN_CHART =
  'Current Liabilities Breakdown Chart'
export const NON_CURRENT_LIABILITIES_BREAKDOWN_CHART =
  'Non-Current Liabilities Breakdown Chart'
export const OPERATING_EXPENSE_BREAKDOWN_CHART =
  'Operating Expense Breakdown Chart'
