import styles from './calendar.module.scss'
import { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import { siteFilter } from 'pages/views/NewsView/component/NewsTable/constants'
import Select from 'react-select'
import { startCase } from 'lodash'
import { Switch } from 'core/Switch/Switch'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import Input from 'core/Input/Input'
import Fuse from 'fuse.js'
import CoreButton from 'core/Button/CoreButton'

dayjs.extend(weekOfYear)

const getDates = currentDate => {
  let weekStart = dayjs(currentDate).startOf('week')
  let dates = []
  for (let j = 0; j < 7; j++) {
    let day = weekStart.add(j, 'day')
    if (day.day() !== 0 && day.day() !== 6) {
      dates.push(day.toDate())
    }
  }
  return dates
}

export const Calendar = ({
  data,
  component,
  filterKeys,
  searchKeys,
  additionalProps,
  isLogo,
  selectableDates,
}) => {
  const [selectedWeek, setSelectedWeek] = useState('thisWeek')
  const [startDate, setStartDate] = useState(new Date())
  const [dates, setDates] = useState(getDates(startDate))
  const [searchQuery, setSearchQuery] = useState('')
  const [selectedDate, setSelectedDate] = useState(null)

  const fuseOptions = {
    includeScore: true,
    keys: searchKeys,
    threshold: 0.3,
  }

  const [fuse, setFuse] = useState(null)

  useEffect(() => {
    const fuseInstance = new Fuse(data, fuseOptions)
    setFuse(fuseInstance)
  }, [data])

  const [filters, setFilters] = useState({})

  const createFilterOptions = (data, filterKeys) => {
    const optionsMap = filterKeys?.reduce((acc, key) => {
      acc[key] = []
      return acc
    }, {})

    data?.forEach(item => {
      filterKeys?.forEach(key => {
        const value = item?.[key]
        if (
          value &&
          !optionsMap?.[key]?.find(option => option?.value === value)
        ) {
          optionsMap?.[key]?.push({ label: value, value: value })
        }
      })
    })

    return optionsMap
  }

  const filterData = (data, filters, searchQuery) => {
    let filteredData = data
    if (Object.keys(filters).length > 0) {
      filteredData = filteredData.filter(item =>
        Object.entries(filters).every(
          ([key, values]) => values.length === 0 || values.includes(item[key])
        )
      )
    }
    if (searchQuery && fuse) {
      const results = fuse.search(searchQuery)
      filteredData = results.map(result => result.item)
    }

    return filteredData
  }

  const filterOptions = createFilterOptions(data, filterKeys)

  useEffect(() => {
    setDates(getDates(startDate))
  }, [startDate])

  const handleWeekChange = newWeek => {
    setSelectedWeek(newWeek)
    switch (newWeek) {
      case 'lastWeek':
        setStartDate(dayjs().subtract(1, 'week').startOf('week').toDate())
        break
      case 'thisWeek':
        setStartDate(dayjs().startOf('week').toDate())
        break
      case 'nextWeek':
        setStartDate(dayjs().add(1, 'week').startOf('week').toDate())
        break
      default:
        break
    }
  }

  const options = [
    { label: 'Last Week', value: 'lastWeek' },
    { label: 'This Week', value: 'thisWeek' },
    { label: 'Next Week', value: 'nextWeek' },
  ]

  const handleFilterChange = (selectedOption, { name }) => {
    setFilters(prevFilters => {
      if (!selectedOption) {
        const newFilters = { ...prevFilters }
        delete newFilters[name]
        return newFilters
      }

      return {
        ...prevFilters,
        [name]: Array.isArray(selectedOption)
          ? selectedOption.map(option => option.value)
          : [selectedOption.value],
      }
    })
  }

  const handleDateClick = date => {
    setSelectedDate(date)
  }

  const dayEvents = filterData(data, {}, searchQuery).filter(event =>
    dayjs(event.date).isSame(selectedDate, 'day')
  )

  const gridWidth = isLogo ? '100px' : '180px'

  return (
    <div className={styles.CalendarWrapper}>
      <div className={styles.toolBar}>
        <Switch
          options={options}
          selectedOption={selectedWeek}
          onOptionChange={handleWeekChange}
        />
        <input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          className={styles.searchInput}
          onChange={e => setSearchQuery(e.target.value)}
        />
        <div className={styles.filtersWrapper}>
          {filterKeys.map(key => (
            <Select
              key={key}
              name={key}
              options={filterOptions[key]}
              onChange={option => handleFilterChange(option, { name: key })}
              placeholder={`Filter by ${startCase(key)}`}
              isClearable
              styles={siteFilter(true, '180px')}
            />
          ))}
          {additionalProps && additionalProps}
        </div>
      </div>
      {selectedDate ? (
        <div className={styles.fullScreen}>
          <div className={styles.toolBar}>
            <div className={styles.title}>
              {dayjs(selectedDate).format('dddd, MMMM DD, YYYY')}.
            </div>
            <CoreButton onClick={() => setSelectedDate(null)}>
              Back to Calendar
            </CoreButton>
          </div>
          <div
            className={styles.selectedEventGrid}
            style={{
              gridTemplateColumns: `repeat(auto-fill, minmax(${gridWidth}, 1fr))`,
            }}
          >
            {dayEvents.map(event => (
              <div
                key={event.id}
                className={isLogo ? styles.gridEvent : styles.event}
              >
                {component({ event })}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className={styles.calendar}>
          {dates.map(date => (
            <div
              key={date.toISOString()}
              className={styles.dateBlock}
              onClick={() => selectableDates && handleDateClick(date)}
            >
              <div
                className={
                  selectableDates ? styles.date : styles.notSelectableDate
                }
              >
                {dayjs(date).format('ddd, MMM DD')}
              </div>

              <div className={isLogo ? styles.gridEvents : styles.events}>
                {filterData(data, filters, searchQuery)
                  .filter(event => dayjs(event.date).isSame(date, 'day'))
                  .map(event => (
                    <div
                      key={event.id}
                      className={isLogo ? styles.gridEvent : styles.event}
                    >
                      {component({ event })}
                    </div>
                  ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
