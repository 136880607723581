import React, { useMemo, useState } from 'react'
import styles from './styles.module.scss'
import numeral from 'numeral'
import _ from 'lodash'
import { CoreLineChart, CoreBarChart } from 'core/Charts/GenericChart'
import { renameKey, keyFunction } from '../../utils'
import CoreButton from 'core/Button/CoreButton'
import { UilChartBar, UilChartLine } from '@iconscout/react-unicons'
import RangeSlider from 'core/RangeSlider'

const LotsOfChartsWrapper = ({
  data,
  chartType,
  setChartType,
  redGreen,
  setRedGreen,
}) => {
  const removeLockedData = data
    ?.filter(data => data.isLocked === false)
    ?.reverse()

  const allYears = removeLockedData.map(data => data.calendarYear)
  const minYear = useMemo(() => Math.min(...allYears), [allYears])
  const maxYear = useMemo(() => Math.max(...allYears), [allYears])

  const [yearsRange, setYearsRange] = useState([minYear, maxYear])

  const handleSliderChange = values => {
    setYearsRange(values)
  }

  return (
    <div className={styles.lotsOfChartsWrapper}>
      <div className={styles.lotsOfChartsToolBar}>
        <div className={styles.lotsOfChartsToolBarButtons}>
          <CoreButton
            onClick={() => {
              setChartType(chartType === 'line' ? 'bar' : 'line')
            }}
          >
            {chartType === 'line' ? (
              <UilChartBar size={20} color="var(--white)" />
            ) : (
              <UilChartLine size={20} color="var(--white)" />
            )}
          </CoreButton>
          <CoreButton
            onClick={() => setRedGreen(!redGreen)}
            color={redGreen ? 'var(--primary-color)' : 'var(--grey-accent)'}
          >
            Color Change
          </CoreButton>
        </div>
        <div className={styles.rangeContainer}>
          <RangeSlider
            min={minYear}
            max={maxYear}
            data={removeLockedData}
            onChange={handleSliderChange}
            interval={1}
          />
        </div>
      </div>
      {removeLockedData && removeLockedData.length > 0 && (
        <div className={styles.lotsOfCharts}>
          {Object.keys(removeLockedData[0])
            .filter(keyFunction)
            .map((key, i) => {
              const filteredData = removeLockedData.filter(data => {
                const year = new Date(data.date).getFullYear()
                return year >= yearsRange[0] && year <= yearsRange[1]
              })

              const firstDataPoint = filteredData[0]
              const lastDataPoint = filteredData[filteredData.length - 1]

              let chartColor = 'var(--primary-color)'

              if (firstDataPoint && lastDataPoint) {
                if (lastDataPoint[key] > firstDataPoint[key]) {
                  chartColor = 'var(--red)'
                } else {
                  chartColor = 'var(--green)'
                }
              }

              return key ? (
                <div key={i}>
                  <div className={styles.lotsOfChartsHeader}>
                    <div
                      className={styles.lotsOfChartsHeaderTitle}
                      title={renameKey[key] ? renameKey[key] : _.startCase(key)}
                    >
                      {renameKey[key] ? renameKey[key] : _.startCase(key)}:{' '}
                    </div>
                    <div className={styles.lotsOfChartsHeaderValue}>
                      {numeral(data[0][key]).format('0.00a')}
                    </div>
                  </div>
                  <div className={styles.lotsOfChartsChart}>
                    {chartType === 'bar' ? (
                      <CoreBarChart
                        data={filteredData}
                        XAxisKey={'date'}
                        zeroTick={true}
                        onlyYear={true}
                        rightYAxis={true}
                        axisWidth={50}
                        metrics={[
                          {
                            key: key,
                            color: redGreen
                              ? chartColor
                              : 'var(--primary-color)',
                          },
                        ]}
                      />
                    ) : chartType === 'line' ? (
                      <CoreLineChart
                        data={filteredData}
                        showSingleYAxis={'right'}
                        XAxisKey={'date'}
                        axisWidth={50}
                        metrics={[
                          {
                            key: key,
                            color: redGreen
                              ? chartColor
                              : 'var(--primary-color)',
                          },
                        ]}
                        yearOnly={true}
                        decimalCount={0}
                        customTickFormatter
                      />
                    ) : null}
                  </div>
                </div>
              ) : null
            })}
        </div>
      )}
    </div>
  )
}

export default LotsOfChartsWrapper
