import { useQuery } from 'react-query'
import { useMemo, useState } from 'react'
import queries from './queries'
import { useURLSyncedGlobalTicker } from 'pages/views/hooks'
import { ETFIntervals } from '../constants'

export const useDataSource = () => {
  const [interval, setInterval] = useState(ETFIntervals[5])

  const { handleSetTicker, ticker } = useURLSyncedGlobalTicker()

  const { data: historicalData, isLoading: historicalDataIsLoading } = useQuery(
    ['historical-price', ticker, interval],
    queries?.fetchPriceData
  )

  const { data: outlookData, isLoading: outlookDataIsLoading } = useQuery(
    ['outlook-data', ticker],
    queries?.fetchCompanyData
  )

  const { data: ETFInfo, isLoading: ETFInfoIsLoading } = useQuery(
    ['etf-info', ticker],
    queries?.fetchETFInfo
  )

  const { data: ETFCountryWeighting, isLoading: ETFCountryWeightingIsLoading } =
    useQuery(['etf-weighting', ticker], queries?.fetchCountryWeight)

  const industryWeightingsTable = useMemo(() => {
    return ETFInfo?.[0]?.sectorsList
      ?.map(sector => ({
        value: sector?.exposure,
        label: sector?.industry,
      }))
      ?.sort((a, b) => b?.value - a?.value)
  }, [ETFInfo])

  const industryWeightings = useMemo(() => {
    return {
      name: 'industryWeightings',
      children: industryWeightingsTable,
    }
  }, [industryWeightingsTable])

  const countryWeightingsTable = useMemo(() => {
    return ETFCountryWeighting?.map(sector => ({
      value: parseFloat(sector?.weightPercentage),
      label: sector?.country,
    }))?.sort((a, b) => b?.value - a?.value)
  }, [ETFCountryWeighting])

  const countryWeightings = useMemo(() => {
    return {
      name: 'countryWeightings',
      children: countryWeightingsTable,
    }
  }, [countryWeightingsTable])

  const shouldBeTreeMap = useMemo(() => {
    if (countryWeightingsTable?.[0]?.value < 70) {
      return false
    }
    return true
  }, [countryWeightingsTable])

  const ETF_UI = useMemo(() => {
    return {
      historicalData,
      outlookData,
      outlookDataIsLoading,
      ETFInfo,
      ETFInfoIsLoading,
      ETFCountryWeighting,
      ETFCountryWeightingIsLoading,
      industryWeightings,
      countryWeightings,
      historicalDataIsLoading,
      industryWeightingsTable,
      countryWeightingsTable,
      shouldBeTreeMap,
    }
  }, [
    historicalData,
    outlookData,
    outlookDataIsLoading,
    ETFInfo,
    ETFInfoIsLoading,
    ETFCountryWeighting,
    ETFCountryWeightingIsLoading,
    industryWeightings,
    countryWeightings,
    historicalDataIsLoading,
    industryWeightingsTable,
    countryWeightingsTable,
    shouldBeTreeMap,
  ])

  return {
    ETF_UI,
    handleSetTicker: handleSetTicker,
    interval,
    setInterval,
    ticker,
  }
}
