import React from 'react'
import numeral from 'numeral'
import moment from 'moment'
import _ from 'lodash'
import dayjs from 'dayjs'
import {
  AreaChart,
  Area,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  YAxis,
  XAxis,
} from 'recharts'

const renderTooltipContent = o => {
  if (o) {
    const { payload } = o
    const date = payload[0]?.payload?.date
    return (
      <div className="customized-tooltip-content">
        <p className="total">{moment(date).format('lll')} </p>
        <div className="list">
          {payload?.map((entry, index) => (
            <div
              className="listItem"
              key={`item-${index}`}
              style={{ color: entry.color }}
            >
              {_.startCase(entry.name)}: {numeral(entry.value).format('0.00 a')}
            </div>
          ))}
        </div>
      </div>
    )
  }
  return
}

export const SmallChart = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart width={300} height={100} data={data}>
        <CartesianGrid
          style={{ stroke: 'rgba(215, 215, 215, 0.2' }}
          vertical={false}
        />
        <Tooltip content={renderTooltipContent} />
        <YAxis
          tickFormatter={tick => {
            if (tick > 10) {
              return numeral(tick).format('0a')
            } else return numeral(tick).format('0.00 a')
          }}
          width={40}
          domain={['auto', 'auto']}
        />
        <XAxis
          style={{ fontSize: 11 }}
          tickFormatter={tick => {
            return dayjs(tick).format('MMM, YY`')
          }}
          dataKey="date"
          height={14}
          tickLine={{ stroke: 'var(--grey-accent)' }}
          axisLine={{ stroke: 'var(--grey-accent)' }}
          tick={{ stroke: 'var(--grey-accent)', fontWeight: '300' }}
        />
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop
              offset="5%"
              stopColor="var(--primary-color)"
              stopOpacity={0.7}
            />
            <stop
              offset="95%"
              stopColor="var(--primary-color)"
              stopOpacity={0}
            />
          </linearGradient>
        </defs>
        <Area
          type="monotone"
          dataKey="close"
          stroke="var(--primary-color)"
          dot={false}
          fill="url(#colorUv)"
          strokeWidth={2}
        />
      </AreaChart>
    </ResponsiveContainer>
  )
}
