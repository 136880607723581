import '../styles.scss'
import numeral from 'numeral'
import { startCase } from 'lodash'
import times from '../assets/times.svg'
import { ExtractValue } from '../../../core/DataFunctions/ExtractValue'
import { countryCodes } from './MetricCatalog/countryCodes'

export const FilterPill = ({ column, type, config, handleDeleteFilter }) => {
  const BETWEEN = 'between'
  const STRING = 'string'
  const words = ['TTM']

  const renderMinConfig = () => {
    if (config.min === Number.MIN_SAFE_INTEGER) {
      return '-Infinity'
    } else {
      return numeral(config.min).format('0.00a')
    }
  }

  const renderMaxConfig = () => {
    if (config.max === Number.MAX_SAFE_INTEGER) {
      return 'Infinity'
    } else {
      return numeral(config.max).format('0.00a')
    }
  }

  const renderLabel = () => {
    let stringValue = countryCodes[config.keyword]
      ? countryCodes[config.keyword]
      : config.keyword

    if (type === BETWEEN) {
      let minPart =
        config.min === Number.MIN_SAFE_INTEGER ? 'under' : renderMinConfig()

      let maxPart =
        config.max === Number.MAX_SAFE_INTEGER ? 'over' : renderMaxConfig()

      return config.min === Number.MIN_SAFE_INTEGER
        ? `${ExtractValue(startCase(column), words)} ${minPart} ${maxPart}`
        : config.max === Number.MAX_SAFE_INTEGER
        ? `${ExtractValue(startCase(column), words)} ${maxPart} ${minPart}`
        : `${minPart} < ${ExtractValue(startCase(column), words)} < ${maxPart}`
    } else if (type === STRING) {
      return `${startCase(column)} = ${stringValue}`
    }
  }

  return (
    <div className="filter-pill-wrapper">
      <div className="filter-label">{renderLabel()} </div>
      <img
        className="delete-icon"
        src={times}
        onClick={() => handleDeleteFilter(column)}
        alt="delete"
      ></img>
    </div>
  )
}
