import React from 'react'
import styles from '../Watchlist.module.scss'
import BasicTable from 'core/Tables/BasicTable'
import CoreButton from 'core/Button/CoreButton'
import { StatsTable } from './StatsTable/StatsTable'

export const WatchlistQuote = ({
  quoteData,
  quoteColumns,
  profileData,
  overviewColumns,
  selectedWatchlistName,
  updateState,
  showStats,
}) => {
  const toggleStats = () => {
    updateState({ showStats: !showStats })
  }

  return (
    <div className={styles.wrapperContainer}>
      <BasicTable
        data={quoteData}
        columns={quoteColumns}
        overflowXScroll={true}
      />
      <CoreButton onClick={toggleStats} width={'100%'}>
        {showStats ? 'Hide Stats' : 'Show Stats'}
      </CoreButton>
      {showStats && (
        <div
          className={`${styles.statsContainer} ${showStats ? styles.open : ''}`}
        >
          <StatsTable
            data={profileData}
            columns={overviewColumns}
            watchlistName={selectedWatchlistName}
          />
        </div>
      )}
    </div>
  )
}
