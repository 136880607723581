import http from '../../../network/httpClient'
export function getEveryNth(arr, nth) {
  const result = []

  for (let i = 0; i < arr.length; i += nth) {
    result.push(arr[i])
  }

  return result
}
export const fetchTrendingSocialSentimentPerTypePerSource = async (
  source,
  type
) => {
  let data = await http.get(
    'papi/data/api/v4/social-sentiments/trending?type=' +
      type +
      '&source=' +
      source
  )
  return { data: data.data.message.reverse(), source: source, trend: type }
}

export const fetchChangeSocialSentimentPerTypePerSource = async (
  source,
  type
) => {
  let data = await http.get(
    'papi/data/api/v4/social-sentiments/change?type=' +
      type +
      '&source=' +
      source
  )

  return { data: data.data.message, source: source, trend: type }
}
export const fetchTrendingSocialSentiment = async () => {
  let data = await http.get('papi/data/api/v4/social-sentiment/trending')
  return data.data.message
}

export const fetchSingleStockNewsRange = async ticker => {
  let data = await http.get('papi/data/api/v3/stock_news?tickers=' + ticker)

  return data.data.message
}

// hourly
export const fetchSingleStockSentiment = async (ticker, limit, takeNth) => {
  let data = await http.get(
    'papi/data/api/v4/historical/social-sentiment?symbol=' +
      ticker +
      '&limit=' +
      limit
  )
  return data.data.message.reverse()
}

export const fetchTrendingNews = async (from, to) => {
  let data = await http.get(
    'papi/data/api/v3/stock_news?from=' + from + '&to=' + to
  )
  return data.data.message
}

const queries = {
  fetchSingleStockNewsRange,
  fetchSingleStockSentiment,
  fetchTrendingNews,
  fetchTrendingSocialSentiment,
  fetchChangeSocialSentimentPerTypePerSource,
  fetchTrendingSocialSentimentPerTypePerSource,
}
export default queries
