/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react'
import { LineChart, Line, XAxis, ResponsiveContainer, YAxis } from 'recharts'
import { getChartColor } from '../../dashboard/Tables/BlockTableUtils'

export const MiniLineChart = ({ data, dataKey }) => {
  const color = useMemo(() => getChartColor(data, dataKey), [])
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart width={300} height={100} data={data}>
        <XAxis
          dataKey="date"
          hide
          tickLine={{ stroke: 'var(--grey-accent)' }}
          axisLine={{ stroke: 'var(--grey-accent)' }}
          tick={{ stroke: 'var(--grey-accent)' }}
        />
        <YAxis domain={['auto', 'auto']} hide />
        <Line
          type="monotone"
          dataKey={dataKey}
          stroke={color}
          dot={false}
          strokeWidth={1}
        />
      </LineChart>
    </ResponsiveContainer>
  )
}
