import uuid from 'react-uuid'
import { selector } from 'recoil'
import { cloneDeep, findIndex } from 'lodash'

import { activeViews, addView, currentView } from '../atoms/views'
import {
  DISABLE_VIEWS,
  POP_VIEW,
  PUSH_VIEW,
  UPDATE_VIEW_TICKER,
  SWITCH_VIEW_FOCUS,
} from '../actions/viewActions'

export const viewReducer = selector({
  key: uuid(),
  get: ({ get }) => {
    return get(activeViews)
  },
  set: ({ set, get }, { action, payload }) => {
    const activeViewState = cloneDeep(get(activeViews))
    const viewID = payload?.id
    const newView = payload?.view
    switch (action) {
      case POP_VIEW:
        const nextInLine =
          activeViewState[
            findIndex(activeViewState, view => view.id === viewID) - 1
          ]
        if (nextInLine) {
          // if it is the last one then just disable, dont set a currents
          set(addView, true)
          set(currentView, { id: nextInLine.id, view: nextInLine.view })
        }

        set(
          activeViews,
          activeViewState.filter(v => v?.id !== viewID)
        )
        return 

      case PUSH_VIEW:
        if (!activeViewState.filter(v => v.view === newView).length) {
          // make sure no duplicate views in state
          set(addView, true)
          set(currentView, { id: viewID, view: newView })
          set(activeViews, [...activeViewState, payload])
          return
        } else {
          set(addView, true)
          set(currentView, {
            ...activeViewState.filter(v => v.view === newView)[0],
          })
          return
        }

      case UPDATE_VIEW_TICKER:
        return
      case DISABLE_VIEWS:
        set(addView, false)
        return
      case SWITCH_VIEW_FOCUS:
        set(addView, true)
        set(currentView, { ...payload })
        return
      default:
        return
    }
  },
})
